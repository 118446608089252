import apiFetch from '../apiFetch'

export default function (token, name, uoId) {
  const queryParamsArray = [
    name ? `name=${name}` : null,
    uoId ? `uoId=${uoId}` : null
  ]

  const queryParams = queryParamsArray.reduce(
    (result, query, idx) => result + (query ? (result ? `&${query}` : `?${query}`) : '')
    , ''
  )

  return apiFetch({ endPoint: `ProcessingAgencyManagers/ByNameOrUo/${queryParams}`, method: 'GET', body: null, token: token })
}
