import React, { PureComponent } from 'react'
import { reduxForm, Field } from 'redux-form'
import { Row, Button, Col, OverlayTrigger, Glyphicon, Tooltip, Modal } from 'react-bootstrap'
import InputDatePicker from '../commons/form/InputDatePicker'
import SimpleTablePage from '../../containers/commons/general/SimpleTablePage'
import IDocCarPagination from '../commons/iDocCarPagination'

class processRegisterFinder extends PureComponent {
  constructor () {
    super()
    this.state = {
      disableResetButton: true,
      fields: {},
      showModal: false,
      records: []
    }
    this.closeModal = this.closeModal.bind(this)
  }

  componentDidUpdate (nextProps) {
    let disableResetButton = nextProps.pristine
    this.setState({ disableResetButton: disableResetButton })
  }

  getIconClass (field) {
    if (this.props.filters.orderBy.includes(field)) {
      return this.props.filters.orderBy.includes('-') ? 'ico-sort-selected-asc' : 'ico-sort-selected-desc'
    } else {
      return 'ico-sort'
    }
  }

  handleClickReset (e) {
    if (!this.state.disableResetButton) {
      if (e) {
        e.preventDefault()
      }
      this.props.initialize({})
      this.setState({ fields: {}, disableResetButton: true })
    }
  }

  fieldFill (field) {
    this.setState({ fields: { ...this.state.fields, ...field } })
  }

  search (e) {
    this.setState({ toExport: null, notExport: null, checkAll: false })
    this.props.actions.fetchRegisterOrder({ ...this.props.filters, ...this.state.fields })
  }

  onOrderChange (e) {
    const newOrderBy = this.props.filters.orderBy.includes(`%2B${e.currentTarget.dataset.field}`) ? `-${e.currentTarget.dataset.field}` : `%2B${e.currentTarget.dataset.field}`
    this.props.actions.fetchRegisterOrder({ ...this.props.filters, ...this.state.fields, orderBy: newOrderBy })
  }

  onPageChange (page) {
    this.props.actions.fetchRegisterOrder({ ...this.props.filters, ...this.state.fields, page: page })
  }

  formatDate (date) {
    if (date != null) {
      let d = date.split('/')
      return d[1] + '-' + d[0] + '-' + d[2]
    }

    return null
  }

  openDetailRegister (id) {
    this.setState({ records: id.purchaseOrderRecordProcesseds, showModal: true })
  }

  closeModal () {
    this.setState({ showModal: false })
  }

  exportData (e) {
    this.props.actions.fetchRegisterOrderDownload({ ...this.props.filters, ...this.state.fields }, this.props.t('IMPORT_EXPORT_FILE.DATE_INIT') + '$' + this.props.t('IMPORT_EXPORT_FILE.DATE_END') + '$' + this.props.t('IMPORT_EXPORT_FILE.FILE_NAME') + '$' + this.props.t('IMPORT_EXPORT_FILE.REGISTER_COUNT'))
  }

  render () {
    const {
      t, hasSearch, filters: { page }, pages, count, data
    } = this.props

    const tKey = 'IMPORT_EXPORT_FILE.'
    const dateOptions = { day: '2-digit', month: '2-digit', year: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' }

    const styleTd = {
      verticalAlign: 'middle'
    }

    const detailTooltip = (<Tooltip id="detailTooltip">{t(`${tKey}DETAIL`)}</Tooltip>)

    return (
      <div>
        <div className="search-header">
          <div className="title-user">
            <i className="ico-documental-search"/> <h2>{t(`${tKey}IMPORT_ORDER_REGISTER`)}</h2>
          </div>
        </div>
        <Field
          id="dateFrom"
          name='register.dateFrom'
          placeholder={t(`${tKey}DATE_FROM`)}
          controlLabel={t(`${tKey}DATE_FROM`)}
          component={InputDatePicker}
          onInputChange={(val) => this.fieldFill({ dateFrom: this.formatDate(val) })}
        />
        <Field
          id="dateTo"
          name='register.dateTo'
          placeholder={t(`${tKey}DATE_TO`)}
          controlLabel={t(`${tKey}DATE_TO`)}
          component={InputDatePicker}
          onInputChange={(val) => this.fieldFill({ dateTo: this.formatDate(val) })}
        />
        <Row>
          <Col sm={12} className="text-center">
            <hr/>
            <Button
              onClick={(e) => this.search(e)}
              className="btn-standard"
            >
              <i className="ico-search"/>
              {t('SEARCH.ACTIONS.SEARCH_BUTTON')}
            </Button>
            <Button
              onClick={(e) => this.handleClickReset(e)}
              className={'btn-white btn-icon' + (this.state.disableResetButton ? ' btn-cancel' : ' btn-standard')}
              style={{ marginLeft: '10px', fontSize: '12px' }}
            >
              <i className="ico-cancel"/> {t('SEARCH.ACTIONS.RESET_BUTTON')}
            </Button>
            {hasSearch && data && data.length > 0 && (<Button
              onClick={(event) => this.exportData(event)}
              className="btn-standard" style={{ marginLeft: '166px' }}>
              <i className="ico-if_Download_1031520" />
              {t('SEARCH.ACTIONS.EXPORT')}
            </Button>)}
            <br></br>
            <br></br>
          </Col>
        </Row>
        <div>
          {hasSearch ? ([

            <div className="table-wrapper" key="table-wrapper">
              <SimpleTablePage
                columns={[
                  <th key={0} data-field="startDate" onClick={(event) => this.onOrderChange(event)}>
                    {t(`${tKey}DATE_INIT`)}&nbsp;<i className={this.getIconClass('startDate')}/>
                  </th>,
                  <th key={1} data-field="finishDate" onClick={(event) => this.onOrderChange(event)}>
                    {t(`${tKey}DATE_END`)}&nbsp;<i className={this.getIconClass('finishDate')}/>
                  </th>,
                  <th key={2} data-field="fileName" onClick={(event) => this.onOrderChange(event)}>
                    {t(`${tKey}FILE_NAME`)}&nbsp;<i className={this.getIconClass('fileName')}/>
                  </th>,
                  <th key={3} data-field="registerCount" onClick={(event) => this.onOrderChange(event)}>
                    {t(`${tKey}REGISTER_COUNT`)}&nbsp;<i className={this.getIconClass('registerCount')}/>
                  </th>,
                  <th key={4} data-field="detail" >
                    {t(`${tKey}DETAIL`)}&nbsp;
                  </th>
                ]}
                rows={data && data.map((row, idx) => (
                  <tr key={idx}>
                    <td style={styleTd}>{new Date(row.startDate).toLocaleDateString('es-ES', dateOptions)}</td>
                    <td style={styleTd}>{new Date(row.finishDate).toLocaleDateString('es-ES', dateOptions)}</td>
                    <td style={styleTd}>{row.fileName}</td>
                    <td style={styleTd}>{row.purchaseOrderRecordProcesseds ? row.purchaseOrderRecordProcesseds.length : 0}</td>
                    <td>
                      {<a onClick={() => this.openDetailRegister(row)}>
                        <OverlayTrigger placement="right" overlay={detailTooltip}>
                          <Glyphicon glyph="th-list" />
                        </OverlayTrigger>
                      </a>}
                    </td>
                  </tr>
                ))}
              />
            </div>,
            <div className="search-footer" key="search-footer">
              <IDocCarPagination
                id="btn_pag_sales"
                page={page}
                pagesCount={pages}
                totalRows= {count}
                onSelectAction={(page) => this.onPageChange(page)}
              />
            </div>
          ]) : null}
        </div>
        <Modal show={this.state.showModal} onHide={this.closeModal} size="xl" centered ="true" dialogClassName="modal-xl">
          <Modal.Header closeButton>
            <Modal.Title > {t(`${tKey}MODAL_DETAIL_TITLE`)}</Modal.Title>
          </Modal.Header>
          <Modal.Body >
            <div className="table-wrapper" key="table-wrapper">
              <SimpleTablePage
                columns={[
                  <th key={0} data-field="dealerNumber" >
                    {t(`${tKey}DEALERNUMBER`)}&nbsp;
                  </th>,
                  <th key={3} data-field="purhcaseOrderDate" >
                    {t(`${tKey}ORDER_DATE`)}&nbsp;
                  </th>,
                  <th key={1} data-field="processedDate">
                    {t(`${tKey}PROCESSED_DATE`)}&nbsp;
                  </th>,
                  <th key={4} data-field="resultCode" >
                    {t(`${tKey}RESULT_CODE`)}&nbsp;
                  </th>
                ]}
                rows={this.state.records && this.state.records.map((row, idx) => (
                  <tr key={idx}>
                    <td style={styleTd}>{row.dealerNumber}</td>
                    <td style={styleTd}>{new Date(row.purhcaseOrderDate).toLocaleDateString('es-ES', dateOptions)}</td>
                    <td style={styleTd}>{new Date(row.processedDate).toLocaleDateString('es-ES', dateOptions)}</td>
                    <td style={styleTd}>{row.resultCode}</td>
                  </tr>
                ))}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.closeModal}>
              {t(`${tKey}CLOSE`)}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }
}

export default reduxForm({
  form: 'processRegisterFinder'
})(processRegisterFinder)
