import signActionTypes from '../../constants/actions/sign/sign'

export function initialState () {
  return {
    selectedTab: null,
    hasSearch: false,
    filters: {
      type: 0,
      page: 1,
      pageSize: 10,
      orderBy: '%2Bdossier'
    },
    dynamicFilters: {},
    searchFilters: {},
    documents: [],
    pages: 1,
    count: 0
  }
}

function searchDocumentsSuccess (state, { data, filters }) {
  return {
    ...state,
    documents: data.items,
    pages: data.pages,
    count: data.count,
    hasSearch: true,
    filters: {
      type: filters.type,
      pageSize: filters.pageSize,
      page: filters.page,
      orderBy: filters.orderBy
    }
  }
}

function getDocumentsCountSucces (state, { data }) {
  return {
    ...state,
    count: data
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case signActionTypes.SEARCH_DOCUMENTS_SUCCES:
      return searchDocumentsSuccess(state, action)
    case signActionTypes.GET_DOCUMENTS_COUNT_SUCCES:
      return getDocumentsCountSucces(state, action)
    default:
      return state
  }
}
