import { call, put, select, takeLatest } from 'redux-saga/effects'
import { getAuth } from '../../selectors/access/auth'
import getPaperworkTypeSubtypeId from '../../services/paperworkType/getPaperworkTypeSubtypeId'
import { showLoader, hideLoader } from '../../actions/common.js'
import messageModalActions from '../../actions/modals/messageModal'
import { fetchPaperworkTypeSuccess } from '../../actions/combos/combos'
import combosActionTypes from '../../constants/actions/combos/combos'
import { handleError } from '../errors/errorManager'

export function * fetchPaperworkType ({ dossierSubTypeId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const response = yield call(getPaperworkTypeSubtypeId, auth.token, dossierSubTypeId)
    yield put(fetchPaperworkTypeSuccess(response))
  } catch (error) {
    const customHandler = function * () {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.FETCH_COMBO_ERROR'))
    }
    yield call(handleError, { error, customHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchPaperworkType () {
  yield takeLatest(combosActionTypes.FETCH_PAPERWORK_TYPE, fetchPaperworkType)
}
