import React, { PureComponent } from 'react'
import { Row, Clearfix, Button, OverlayTrigger, Glyphicon } from 'react-bootstrap'
import { Field } from 'redux-form'
import InputText from '../commons/form/InputText'
import InputMoney from '../commons/form/InputMoney'
import InputSelect from '../commons/form/InputSelect'
import InputDatePicker from '../commons/form/InputDatePicker'
import { validateDate } from '../../util/validationFunctions'
import SimpleTablePage from '../../containers/commons/general/SimpleTablePage'
import IDocCarPagination from '../commons/iDocCarPagination'
import { Link } from 'react-router-dom'
import { formatMoney } from '../../util/utils'
import settings from '../../setting'
import { clientInformation } from '../../constants/backendIds'

class SearchByChargeForm extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      fields: {},
      fieldsConfiguration: {
      }
    }
  }

  validateDate (value, allValues, props) {
    const result = validateDate(value, props)
    return result === Object(result) ? undefined : result
  }

  exportData () {
    const tKey = 'SEARCH.CATEGORIES.CHARGE.'
    let columnsExcel = []

    columnsExcel.push(this.props.t(`${tKey}DOSSIER_NUMBER`) + '#Number')
    columnsExcel.push(this.props.t(`${tKey}ENTITY`) + '#Entity')
    columnsExcel.push(this.props.t(`${tKey}CLIENT_NUMBER`) + '#ClientNumber')
    columnsExcel.push(this.props.t(`${tKey}SALESMAN`) + '#Salesman')
    columnsExcel.push(this.props.t(`${tKey}AMOUNT`) + '#Amount')
    if (settings.clientFieldValidation === clientInformation.VGR) {
      columnsExcel.push(this.props.t(`${tKey}BANK`) + '#Bank')
    }
    columnsExcel.push(this.props.t(`${tKey}CHARGE_DATE`) + '#ChargeDate')
    columnsExcel.push(this.props.t(`${tKey}DOCUMENT_STATUS`) + '#DocumentStatus')

    this.props.exportToExcel(columnsExcel)
  }

  componentDidMount () {
    this.props.actions.fetchBankCombo()
    this.props.actions.fetchDocumentStatusCombo()
  }

  render () {
    const {
      t, fields, fieldFill, className, classNameSelect, combos, hasSearch, selectedFilters, chargeData, onClickResetSearch, onOrderChange, getIconClass,
      page, pages, count, onPageChange, navigationTooltip, onClickTaskListRow,
      combos: { documentStatusCombo }
    } = this.props
    const tKey = 'SEARCH.CATEGORIES.CHARGE.'
    return (
      <div>
        {!hasSearch ? (
          <Row>
            <Field
              id="clientNameCharge"
              name='charge.name'
              placeholder={t(`${tKey}NAME`)}
              controlLabel={t(`${tKey}NAME`)}
              component={InputText}
              type="text"
              onInputChange={(val) => fieldFill({ clientName: val })}
              customClass={fields.clientName ? className : ''}
            />
            <Field
              id="clientNumberCharge"
              name='charge.clientNumber'
              placeholder={t(`${tKey}CLIENT_NUMBER`)}
              controlLabel={t(`${tKey}CLIENT_NUMBER`)}
              component={InputText}
              type="text"
              onInputChange={(val) => fieldFill({ clientNumber: val })}
              customClass={fields.clientNumber ? className : ''}
            />
            <Field
              id="salesman"
              name='charge.salesman'
              placeholder={t(`${tKey}SALESMAN`)}
              controlLabel={t(`${tKey}SALESMAN`)}
              component={InputSelect}
              multi={true}
              options={combos.salesmanCombo}
              valueKey="id"
              labelKey="value"
              onInputChange={(val) => fieldFill({ salesman: val })}
              customClass={fields.salesman ? classNameSelect : ''}
            />
            <Field
              id="amount"
              name='charge.amount'
              placeholder={t(`${tKey}AMOUNT`)}
              controlLabel={t(`${tKey}AMOUNT`)}
              component={InputMoney}
              type="text"
              onInputChange={(val) => fieldFill({ amount: val })}
              customClass={fields.amount ? className : ''}
            />
            { settings.clientFieldValidation === clientInformation.VGR &&
            <Field
              id="bank"
              name='charge.bank'
              placeholder={t(`${tKey}BANK`)}
              controlLabel={t(`${tKey}BANK`)}
              component={InputSelect}
              options={combos.banksCombo}
              valueKey="id"
              labelKey="value"
              onInputChange={(val) => fieldFill({ bank: val })}
              customClass={fields.bank ? classNameSelect : ''}
            />}
            <Field
              id="chargeDateFrom"
              name='charge.chargeDateFrom'
              placeholder={t(`${tKey}CHARGE_DATE_FROM`)}
              controlLabel={t(`${tKey}CHARGE_DATE_FROM`)}
              component={InputDatePicker}
              validate={[this.validateDate]}
              onInputChange={(val) => fieldFill({ chargeDateFrom: val })}
              customClass={fields.chargeDateFrom ? className : ''}
            />
            <Field
              id="chargeDateTo"
              name='charge.chargeDateTo'
              placeholder={t(`${tKey}CHARGE_DATE_TO`)}
              controlLabel={t(`${tKey}CHARGE_DATE_TO`)}
              component={InputDatePicker}
              validate={[this.validateDate]}
              onInputChange={(val) => fieldFill({ chargeDateTo: val })}
              customClass={fields.chargeDateTo ? className : ''}
            />

            <Field
              id="documentStatus"
              name='charge.documentStatus'
              placeholder={t(`${tKey}DOCUMENT_STATUS`)}
              controlLabel={t(`${tKey}DOCUMENT_STATUS`)}
              valueKey="id"
              labelKey="value"
              component={InputSelect}
              options={documentStatusCombo}
              onInputChange={(val) => fieldFill({ documentStatusId: val })}
            />
          </Row>
        ) : ([
          <Row className="filters-selected" key="filters-selected">
            <ul className="list-inline pull-left">
              {selectedFilters().length === 0 ? <li>{t(`${tKey}NO_SELECTED_FILTERS`)}</li> : (selectedFilters())}
            </ul>
            <Clearfix/>
            <Button
              onClick={(event) => onClickResetSearch(event)}
              bsStyle="default"
              bsSize="small"
              className="pull-left"
              id='modSearchButtonIdCharge'
            >
              {t('SEARCH.ACTIONS.MODIFY_SEARCH')}
              <i className="ico-edit-turquoise" />
            </Button>
            {count > 0 &&
            <Button
              onClick={(event) => this.exportData(event)}
              bsStyle="default"
              bsSize="small"
              id='expSearchButtonIdCharge'>
              {t('SEARCH.ACTIONS.EXPORT')}
              <i className="ico-if_Download_1031520" />
            </Button>
            }
          </Row>,
          <div className="table-wrapper" key="table-wrapper">
            <SimpleTablePage
              columns={[
                <th key={1} data-field="number" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}DOSSIER_NUMBER`)}&nbsp;<i className={getIconClass('number')}/>
                </th>,
                <th key={2} data-field="entity" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}ENTITY`)}&nbsp;<i className={getIconClass('entity')}/>
                </th>,
                <th key={3} data-field="clientNumber" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}CLIENT_NUMBER`)}&nbsp;<i className={getIconClass('organizedUnit')}/>
                </th>,
                <th key={4} data-field="salesman" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}SALESMAN`)}&nbsp;<i className={getIconClass('salesman')}/>
                </th>,
                <th key={5} data-field="amount" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}AMOUNT`)}&nbsp;<i className={getIconClass('amount')}/>
                </th>,
                settings.clientFieldValidation === clientInformation.VGR && (
                  <th key={6} data-field="bank" onClick={(event) => onOrderChange(event)}>
                    {t(`${tKey}BANK`)}&nbsp;<i className={getIconClass('bank')}/>
                  </th>
                ),
                <th key={7} data-field="chargeDate" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}CHARGE_DATE`)}&nbsp;<i className={getIconClass('chargeDate')}/>
                </th>,
                <th key={8} data-field="documentStatus" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}DOCUMENT_STATUS`)}&nbsp;<i className={getIconClass('documentStatus')}/>
                </th>
              ]}
              rows={chargeData && chargeData.map((row, idx) => (
                <tr key={idx}>
                  <td>
                    <Link to={`/dossier${row.urlToNavigateDossier}`}>
                      {row.dossierNumber}
                    </Link>
                  </td>
                  <td>{row.entityName}</td>
                  <td>{row.clientNumber}</td>
                  <td>{this.props.combos.salesmanCombo && this.props.combos.salesmanCombo.filter(salesman => salesman.id === row.salesmanId)[0] && this.props.combos.salesmanCombo.filter(salesman => salesman.id === row.salesmanId)[0].value}</td>
                  <td>{formatMoney(row.amount)}</td>
                  {settings.clientFieldValidation === clientInformation.VGR && (
                    <td>{this.props.combos.banksCombo && this.props.combos.banksCombo.filter(bank => parseInt(bank.id) === row.bankId)[0] && this.props.combos.banksCombo.filter(bank => parseInt(bank.id) === row.bankId)[0].value}</td>
                  )}
                  <td>{row.chargeDate}</td>
                  <td>{row.documentStatus}</td>
                  <td className="navigation">
                    {(row.urlToNavigateDocument || (row.documentId)) &&
                    <OverlayTrigger placement="left" overlay={navigationTooltip}>
                      <Glyphicon
                        glyph="new-window"
                        onClick={onClickTaskListRow.bind(this, row.documentId, row.documentTypeUseId, row.documentParentTypeId, row.objectId, row.organizedUnitId)}
                      />
                    </OverlayTrigger>
                    }
                  </td>
                </tr>
              ))}
            />
          </div>,
          <div className="search-footer" key="search-footer">
            <IDocCarPagination
              id="btn_pag_sales"
              page={page}
              pagesCount={pages}
              totalRows= {count}
              onSelectAction={(page) => onPageChange(page)}
            />
          </div>
        ])}
      </div>
    )
  }
}

export default SearchByChargeForm
