import React, { PureComponent } from 'react'
import { Button, Modal, OverlayTrigger, Row, Table, Tooltip } from 'react-bootstrap'
import InputCheckboxSlider from '../../../commons/form/InputCheckboxSlider'
import SubscriptionModal from './SubscriptionModal'

class UserSubscriptionsModal extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      showEventModal: false,
      eventSubscription: null,
      eventSubscriptions: []
    }
    this.openEventModal = this.openEventModal.bind(this)
    this.closeEventModal = this.closeEventModal.bind(this)
  }

  openEventModal (eventSubscription) {
    this.setState({ showEventModal: true, eventSubscription })
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (prevProps.showModal !== this.props.showModal && this.props.showModal) {
      this.props.actions.fetchEventSubscriptionsFromAdmin(this.props.userId)
      this.props.actions.fetchLowestOrganizedUnits(this.props.userId)
    }
    return null
  }

  componentDidUpdate (prevProps, prevState) {
  }

  componentDidMount () {
  }

  onActiveInputChange (subscriptionId, value) {
    if (value) {
      this.props.actions.enableEventSubscriptionFromAdmin(subscriptionId, this.props.userId)
    } else {
      this.props.actions.disableEventSubscriptionFromAdmin(subscriptionId, this.props.userId)
    }
  }

  getOuColumn (organizedUnits) {
    const ouArray = organizedUnits ? organizedUnits.split(',') : []
    return (<td> {ouArray[0]} {ouArray.length > 1 ? <span className='size10'> (+ {ouArray.length - 1})</span> : '' } </td>)
  }

  closeEventModal () {
    this.setState({ showEventModal: false, eventSubscription: null })
  }

  render () {
    const tKey = 'ADMIN.SUBSCRIPTIONS.'
    const {
      t, userId, defaultLanguageId, eventSubscriptions, showModal,
      combos: { lowestOrganizedUnitsCombo, languages, events },
      actions: { saveEventSubscriptionFromAdmin, removeEventSubscriptionFromAdmin, closeModal, fetchEventsCombo }
    } = this.props
    const editTooltip = (<Tooltip id="editTooltip">{t(`${tKey}EDIT`)}</Tooltip>)
    const deleteTooltip = (<Tooltip id="deleteTooltip">{t(`${tKey}DELETE`)}</Tooltip>)
    const subscribedEvents = eventSubscriptions?.map(x => x.notificationEventId) ?? []
    return (
      <Modal show={showModal} onHide={closeModal} className="eventSubscriptionModal inUserAdmin" backdrop={'static'} >
        <Modal.Header closeButton>
          <Modal.Title>{t(`${tKey}TITLE`)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='userEventSubscriptions'>
            <SubscriptionModal
              actions={{
                saveEventSubscriptionFromAdmin,
                closeModal: this.closeEventModal,
                fetchEventsCombo
              }}
              t={t}
              combos={{ lowestOrganizedUnitsCombo, languages, events }}
              showModal={this.state.showEventModal}
              eventSubscription={this.state.eventSubscription}
              subscribedEvents = {subscribedEvents}
              userId = {userId}
              defaultLanguageId = {defaultLanguageId}
            />
            <Row>
              <Table hover responsive className="editable-table EventSubscriptionTable">
                <thead>
                  <tr>
                    <th key={'ou'}>{t(`${tKey}OU_COL`)}</th>
                    <th key={'name'}>{t(`${tKey}NAME_COL`)}</th>
                    <th key={'description'}>{t(`${tKey}DESCRIPTION_COL`)}</th>
                    <th className='enabledCol' key={'enabled'}>{t(`${tKey}ENABLE_COL`)}</th>
                    <th className='actionsCol' key={'actions'}>{t(`${tKey}ACTIONS_COL`)}</th>
                  </tr>
                </thead>
                <tbody>
                  {eventSubscriptions?.map((subscription, idx) => {
                    return (
                      <tr key={idx} className= { subscription.active ? '' : 'subscriptionDisabled' }>
                        {this.getOuColumn(subscription.organizedUnits)}
                        <td>{subscription.name}</td>
                        <td>{subscription.description}</td>
                        <td className='enabledCol'>
                          <InputCheckboxSlider
                            checked={subscription.active}
                            onInputChange={(value) => this.onActiveInputChange(subscription.subscriptionId, value) }
                          />
                        </td>
                        <td className='actionsCol'>
                          <div>
                            <a onClick={() => this.openEventModal(subscription)}>
                              <OverlayTrigger placement="left" overlay={editTooltip}>
                                <i className="ico-edit-white" />
                              </OverlayTrigger>
                            </a>
                            <a onClick={() => removeEventSubscriptionFromAdmin(subscription.subscriptionId, userId)}>
                              <OverlayTrigger placement="left" overlay={deleteTooltip}>
                                <i className="ico-trash" />
                              </OverlayTrigger>
                            </a>
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </Row>
            <Row>
              <Button className="_Btn accept"
                onClick={() => this.openEventModal()}>
                <i className="iDocIcon-add-plus"></i>
                {t(`${tKey}ADD_BUTTON`)}
              </Button>
            </Row>
          </div>
        </Modal.Body>
        <Modal.Footer className='flexInLine al-C'>
          <Button id="okBtn" name="okBtn" className="_Btn accept" onClick={closeModal}>{t('GLOBAL.BUTTONS.OK')}</Button>
          <Button id="cancelBtn" name="cancelBtn" onClick={closeModal} className="_Btn cancel">{t('GLOBAL.BUTTONS.CANCEL')}</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default UserSubscriptionsModal
