import dossierActionTypes from '../../../constants/actions/dossier/common/validations'

function validateBase (type, validationTypeCode, paramsToValidate, organizedUnitId, resolve, reject, withoutLoader) {
  return {
    type,
    validationTypeCode,
    paramsToValidate,
    organizedUnitId,
    resolve,
    reject,
    withoutLoader
  }
}

export function validate (validationTypeCode, paramsToValidate, organizedUnitId, resolve, reject, withoutLoader) {
  return validateBase(dossierActionTypes.VALIDATE, validationTypeCode, paramsToValidate, organizedUnitId, resolve, reject, withoutLoader)
}

export function validateDNICIF (validationTypeCode, paramsToValidate, organizedUnitId, resolve, reject, withoutLoader) {
  return validateBase(dossierActionTypes.VALIDATE_DNICIF, validationTypeCode, paramsToValidate, organizedUnitId, resolve, reject, withoutLoader)
}

export function validateRepresentative (validationTypeCode, paramsToValidate, organizedUnitId, resolve, reject, withoutLoader) {
  return validateBase(dossierActionTypes.VALIDATE_REPRESENTATIVE_DNI, validationTypeCode, paramsToValidate, organizedUnitId, resolve, reject, withoutLoader)
}
