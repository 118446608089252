import React, { PureComponent } from 'react'
import { reduxForm, Field, formValues } from 'redux-form'
import { Modal, Row, Col, FormGroup, Button } from 'react-bootstrap'
import InputDatePicker from '../../../../commons/form/InputDatePicker'
import TextInput from '../../../../commons/TextInput'
import InputNumber from '../../../../commons/form/InputNumber'
import InputRadio from '../../../../commons/form/InputRadio'
import InputCheckBox from '../../../../commons/form/InputCheckBox'
import InputSelect from '../../../../commons/form/InputSelect'
import moment from 'moment'

class ReservationContractModal extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      birthdateError: false,
      hiredInsuranceChange: false,
      hiredMaintenanceChange: false,
      accessory1Change: false,
      accessory1Value: '',
      accessory2Change: false,
      accessory2Value: '',
      accessory3Change: false,
      accessory3Value: '',
      accessory4Change: false,
      accessory4Value: ''
    }
    this.required = this.required.bind(this)
    this.birthdateValidate = this.birthdateValidate.bind(this)
    this.birthdateChange = this.birthdateChange.bind(this)
  }

  setCodeValue (obj, property, code, cleanExtraProperties = []) {
    if (obj[property] !== null && obj[property] !== undefined) {
      if (obj[property]) {
        obj[property] = `CODE#${code}_SI`
      } else {
        obj[property] = `CODE#${code}_NO`
        cleanExtraProperties.forEach((extraProperty) => {
          obj[extraProperty] = null
        })
      }
    } else {
      obj[property] = `CODE#${code}_NO`
    }
  }

  cleanValues (obj) {
    if (obj.consent !== null || obj.consent !== undefined) {
      if (obj.consent) {
        obj.consentSi = 'Yes'
        obj.consentNo = 'Off'
      } else {
        obj.consentSi = 'Off'
        obj.consentNo = 'Yes'
      }
    }
    if (!obj.accessory1) {
      obj.accessory1 = null
      obj.reference1 = null
      obj.chargeVnClient1 = null
      if (obj.accessory2) {
        obj.accessory2 = null
        obj.reference2 = null
        obj.chargeVnClient2 = null
      }
      if (obj.accessory3) {
        obj.accessory3 = null
        obj.reference3 = null
        obj.chargeVnClient3 = null
      }
      if (obj.accessory4) {
        obj.accessory4 = null
        obj.reference4 = null
        obj.chargeVnClient4 = null
      }
    }
    if (!obj.accessory2) {
      obj.accessory2 = null
      obj.reference2 = null
      obj.chargeVnClient2 = null
      if (obj.accessory3) {
        obj.accessory3 = null
        obj.reference3 = null
        obj.chargeVnClient3 = null
      }
      if (obj.accessory4) {
        obj.accessory4 = null
        obj.reference4 = null
        obj.chargeVnClient4 = null
      }
    }
    if (!obj.accessory3) {
      obj.accessory3 = null
      obj.reference3 = null
      obj.chargeVnClient3 = null
      if (obj.accessory4) {
        obj.accessory4 = null
        obj.reference4 = null
        obj.chargeVnClient4 = null
      }
    }
    if (!obj.accessory4) {
      obj.accessory4 = null
      obj.reference4 = null
      obj.chargeVnClient4 = null
    }
  }

  closeModalActions () {
    this.props.actions.closeReservationContractModal()
    this.props.initialize({})
    this.resetState()
    this.props.reset()
  }

  submitReservationContract (values) {
    const submit = { ...values }
    this.setCodeValue(submit, 'hiredInsurance', 'SEGURO', ['insuranceCompany'])
    this.setCodeValue(submit, 'hiredMaintenance', 'MANTENIMIENTO', ['maintenanceNumber', 'chargeClientVnMaintenance'])
    this.cleanValues(submit)
    this.props.onClose(submit, this.props.printCheckList, this.props.checklistId)
    this.props.actions.closeReservationContractModal()
    this.props.initialize({})
    this.resetState()
    this.props.reset()
  }

  resetState () {
    this.setState({
      birthdateError: false,
      hiredInsuranceChange: false,
      hiredMaintenanceChange: false,
      accessory1Change: false,
      accessory1Value: '',
      accessory2Change: false,
      accessory2Value: '',
      accessory3Change: false,
      accessory3Value: '',
      accessory4Change: false,
      accessory4Value: ''
    })
  }

  hiredInsuranceChange (value) {
    this.setState({ hiredInsuranceChange: value })
  }

  hiredMaintenanceChange (value) {
    this.setState({ hiredMaintenanceChange: value })
  }

  accessory1Change (value) {
    if (value.target.value && value.target.value !== '') {
      this.setState({ accessory1Change: true })
      this.setState({ accessory1Value: value.target.value })
      if (this.state.accessory2Value !== '') this.setState({ accessory2Change: true })
      if (this.state.accessory3Value !== '' && this.state.accessory2Value !== '') this.setState({ accessory3Change: true })
      if (this.state.accessory4Value !== '' && this.state.accessory3Value !== '' && this.state.accessory2Value !== '') this.setState({ accessory4Change: true })
    } else {
      this.setState({ accessory1Value: '' })
      this.setState({ accessory1Change: false })
      this.setState({ accessory2Change: false })
      this.setState({ accessory3Change: false })
      this.setState({ accessory4Change: false })
    }
  }

  accessory2Change (value) {
    if (value.target.value && value.target.value !== '') {
      this.setState({ accessory2Change: true })
      this.setState({ accessory2Value: value.target.value })
      if (this.state.accessory3Value !== '') this.setState({ accessory3Change: true })
      if (this.state.accessory4Value !== '' && this.state.accessory3Value !== '') this.setState({ accessory4Change: true })
    } else {
      this.setState({ accessory2Value: '' })
      this.setState({ accessory2Change: false })
      this.setState({ accessory3Change: false })
      this.setState({ accessory4Change: false })
    }
  }

  accessory3Change (value) {
    if (value.target.value && value.target.value !== '') {
      this.setState({ accessory3Change: true })
      this.setState({ accessory3Value: value.target.value })
      if (this.state.accessory4Value !== '') this.setState({ accessory4Change: true })
    } else {
      this.setState({ accessory3Value: '' })
      this.setState({ accessory3Change: false })
      this.setState({ accessory4Change: false })
    }
  }

  accessory4Change (value) {
    if (value.target.value && value.target.value !== '') {
      this.setState({ accessory4Change: true })
      this.setState({ accessory4Value: value.target.value })
    } else {
      this.setState({ accessory4Value: '' })
      this.setState({ accessory4Change: false })
    }
  }

  birthdateValidate (value) {
    if (!value) {
      return undefined
    }
    if (moment(value, 'DD/MM/YYYY').isAfter(new Date())) {
      return this.props.t('DOSSIER_COMPONENTS.DOCUMENTARY_MANAGEMENT.ADD_OR_EDIT_DOCUMENT.FROM_FORM_TEMPLATE.ERRORBIRTHDATE')
    }
    return undefined
  }

  birthdateChange () {
    this.setState({ birthdateError: true })
  }

  required (value) {
    if (value || value === 0) {
      return undefined
    } else {
      return this.props.t('GLOBAL.FORMS.REQUIRED')
    }
  }

  render () {
    const {
      t, handleSubmit,
      showReservationContractModal,
      combos: { insuranceCompanyCombo }
    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.DOCUMENTARY_MANAGEMENT.ADD_OR_EDIT_DOCUMENT.FROM_FORM_TEMPLATE.'
    return (
      <Modal show={showReservationContractModal} bsSize="large" className="role-modal languages-modal ReservationContractModal" onHide={this.closeModalActions.bind(this)} backdrop={'static'}>
        <form autoComplete="off" onSubmit={handleSubmit(this.submitReservationContract.bind(this))}>
          <Modal.Header closeButton onHide={this.closeModalActions.bind(this)}>
            <Modal.Title>{t(`${tKey}TITLE`)}</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ padding: '20px' }}>
            <FormGroup>
              <Row className="inputs-wrapper flexRow">
                <Col sm={12} className="custom-width-col">
                  <h2>{t(`${tKey}PERSONAL_DATA`)}</h2>
                </Col>
                <Field
                  id="birthdate"
                  name='birthdate'
                  colSm={4}
                  placeholder={t(`${tKey}BIRTHDATE`)}
                  controlLabel={t(`${tKey}BIRTHDATE`)}
                  component={InputDatePicker}
                  valueKey="id"
                  labelKey="value"
                  showError={this.state.birthdateError}
                  validate={[this.birthdateValidate]}
                  onChange={this.birthdateChange}
                />
                <Col sm={8} className="custom-width-col">
                  {t(`${tKey}ACTIVITY`)}
                  <Field
                    id="activity"
                    name='activity'
                    placeholder={t(`${tKey}ACTIVITY`)}
                    component={TextInput}
                    maxLength={60}
                    valueKey="id"
                    labelKey="value"
                    validate={[this.required]}
                  />
                </Col>
                <Col sm={12} className="custom-width-col">
                  <h2>{t(`${tKey}WAY_TO_PAY`)}</h2>
                </Col>
                <Col sm={3} className="custom-width-col">
                  <p className='m-top-10'>{t(`${tKey}RENTING_PAY_MODE`)}</p>
                </Col>
                <Col sm={1} className="custom-width-col">
                  <Field
                    id="rentingPayMode"
                    name='rentingPayMode'
                    colSm={1}
                    placeholder="99"
                    textAlign="center"
                    component={InputNumber}
                    maxLength={2}
                    valueKey="id"
                    labelKey="value"
                  />
                </Col>
                <Col sm={12} className="custom-width-col ">
                  <Field
                    id="appraisalUVRebuIva"
                    key="appraisalUVRebuIva"
                    name="appraisalUVRebuIva"
                    groupName="appraisalUVRebuIva"
                    placeholder={<h2>{t(`${tKey}APPRAISAL_UV`)}</h2>}
                    colSm={10}
                    inline
                    component={InputRadio}
                    items={[
                      { id: 'REBU', label: `${tKey}APPRAISAL_UV_REBU`, value: 'REBU' },
                      { id: 'IVA', label: `${tKey}APPRAISAL_UV_IVA`, value: 'IVA' }
                    ]}
                    t={t}
                    validate={[this.required]}
                  />
                </Col>
                <Col sm={12} className="custom-width-col">
                  <h2>{t(`${tKey}OBSERVATIONS`)}</h2>
                </Col>
                <Col sm={6} className="custom-width-col">
                  <p>{t(`${tKey}ACCESSORY`)} 1</p>
                </Col>
                <Col sm={6} className="custom-width-col">
                  <p>{t(`${tKey}REFERENCE`)}</p>
                </Col>
                <Col sm={6} className="custom-width-col">
                  <Field
                    id="accessory1"
                    name='accessory1'
                    placeholder={t(`${tKey}ACCESSORY`)}
                    controlLabel={t(`${tKey}ACCESSORY`)}
                    component={TextInput}
                    maxLength={30}
                    onChange={(value) => this.accessory1Change(value, formValues)}
                    valueKey="id"
                    labelKey="value"
                  />
                </Col>
                <Col sm={6} className="custom-width-col">
                  <Field
                    id="reference1"
                    name='reference1'
                    readOnly={!this.state.accessory1Change}
                    placeholder={t(`${tKey}REFERENCE`)}
                    controlLabel={t(`${tKey}REFERENCE`)}
                    component={TextInput}
                    maxLength={20}
                    valueKey="id"
                    labelKey="value"
                  />
                </Col>
                <Col sm={12} className="custom-width-col">
                  <Field
                    id="chargeVnClient1"
                    key="chargeVnClient1"
                    name="chargeVnClient1"
                    groupName="chargeVnClient1"
                    disabled={!this.state.accessory1Change}
                    inline
                    component={InputRadio}
                    items={[
                      { id: 'CARGOVN_1', label: `${tKey}CHARGE_VN`, value: 'CODE#CARGOVN_1' },
                      { id: 'CARGOCLIENTE_1', label: `${tKey}CHARGE_CLIENT`, value: 'CODE#CARGOCLIENTE_1' }
                    ]}
                    t={t}
                  />
                </Col>
              </Row>
              <Row className="inputs-wrapper flexRow">
                <Col sm={6} className="custom-width-col">
                  <p>{t(`${tKey}ACCESSORY`)} 2</p>
                </Col>
                <Col sm={6} className="custom-width-col">
                  <p>{t(`${tKey}REFERENCE`)}</p>
                </Col>
                <Col sm={6} className="custom-width-col">
                  <Field
                    id="accessory2"
                    name='accessory2'
                    readOnly={!this.state.accessory1Change}
                    placeholder={t(`${tKey}ACCESSORY`)}
                    controlLabel={t(`${tKey}ACCESSORY`)}
                    component={TextInput}
                    maxLength={30}
                    onChange={(value) => this.accessory2Change(value)}
                    valueKey="id"
                    labelKey="value"
                  />
                </Col>
                <Col sm={6} className="custom-width-col">
                  <Field
                    id="reference2"
                    name='reference2'
                    readOnly={!this.state.accessory2Change}
                    placeholder={t(`${tKey}REFERENCE`)}
                    controlLabel={t(`${tKey}REFERENCE`)}
                    component={TextInput}
                    maxLength={20}
                    valueKey="id"
                    labelKey="value"
                  />
                </Col>
                <Col sm={12} className="custom-width-col">
                  <Field
                    id="chargeVnClient2"
                    key="chargeVnClient2"
                    name="chargeVnClient2"
                    groupName="chargeVnClient2"
                    disabled={!this.state.accessory2Change}
                    inline
                    component={InputRadio}
                    items={[
                      { id: 'CARGOVN_2', label: `${tKey}CHARGE_VN`, value: 'CODE#CARGOVN_2' },
                      { id: 'CARGOCLIENTE_2', label: `${tKey}CHARGE_CLIENT`, value: 'CODE#CARGOCLIENTE_2' }
                    ]}
                    t={t}
                  />
                </Col>
              </Row>
              <Row className="inputs-wrapper flexRow">
                <Col sm={6} className="custom-width-col">
                  <p>{t(`${tKey}ACCESSORY`)} 3</p>
                </Col>
                <Col sm={6} className="custom-width-col">
                  <p>{t(`${tKey}REFERENCE`)}</p>
                </Col>
                <Col sm={6} className="custom-width-col">
                  <Field
                    id="accessory3"
                    name='accessory3'
                    readOnly={!this.state.accessory2Change}
                    placeholder={t(`${tKey}ACCESSORY`)}
                    controlLabel={t(`${tKey}ACCESSORY`)}
                    component={TextInput}
                    maxLength={30}
                    onChange={(value) => this.accessory3Change(value)}
                    valueKey="id"
                    labelKey="value"
                  />
                </Col>
                <Col sm={6} className="custom-width-col">
                  <Field
                    id="reference3"
                    name='reference3'
                    readOnly={!this.state.accessory3Change}
                    placeholder={t(`${tKey}REFERENCE`)}
                    controlLabel={t(`${tKey}REFERENCE`)}
                    component={TextInput}
                    maxLength={20}
                    valueKey="id"
                    labelKey="value"
                  />
                </Col>
                <Col sm={12} className="custom-width-col">
                  <Field
                    id="chargeVnClient3"
                    key="chargeVnClient3"
                    name="chargeVnClient3"
                    groupName="chargeVnClient3"
                    disabled={!this.state.accessory3Change}

                    inline
                    component={InputRadio}
                    items={[
                      { id: 'CARGOVN_3', label: `${tKey}CHARGE_VN`, value: 'CODE#CARGOVN_3' },
                      { id: 'CARGOCLIENTE_3', label: `${tKey}CHARGE_CLIENT`, value: 'CODE#CARGOCLIENTE_3' }
                    ]}
                    t={t}
                  />
                </Col>
              </Row>
              <Row className="inputs-wrapper flexRow">
                <Col sm={6} className="custom-width-col">
                  <p>{t(`${tKey}ACCESSORY`)} 4</p>
                </Col>
                <Col sm={6} className="custom-width-col">
                  <p>{t(`${tKey}REFERENCE`)}</p>
                </Col>
                <Col sm={6} className="custom-width-col">
                  <Field
                    id="accessory4"
                    name='accessory4'
                    readOnly={!this.state.accessory3Change}
                    placeholder={t(`${tKey}ACCESSORY`)}
                    controlLabel={t(`${tKey}ACCESSORY`)}
                    component={TextInput}
                    maxLength={30}
                    onChange={(value) => this.accessory4Change(value)}
                    valueKey="id"
                    labelKey="value"
                  />
                </Col>
                <Col sm={6} className="custom-width-col">
                  <Field
                    id="reference4"
                    name='reference4'
                    readOnly={!this.state.accessory4Change}
                    placeholder={t(`${tKey}REFERENCE`)}
                    controlLabel={t(`${tKey}REFERENCE`)}
                    component={TextInput}
                    maxLength={20}
                    valueKey="id"
                    labelKey="value"
                  />
                </Col>
                <Col sm={12} className="custom-width-col">
                  <Field
                    id="chargeVnClient4"
                    key="chargeVnClient4"
                    name="chargeVnClient4"
                    groupName="chargeVnClient4"
                    disabled={!this.state.accessory4Change}
                    inline
                    component={InputRadio}
                    items={[
                      { id: 'CARGOVN_4', label: `${tKey}CHARGE_VN`, value: 'CODE#CARGOVN_4' },
                      { id: 'CARGOCLIENTE_4', label: `${tKey}CHARGE_CLIENT`, value: 'CODE#CARGOCLIENTE_4' }
                    ]}
                    t={t}
                  />
                </Col>
              </Row>
              <Row className="inputs-wrapper flexRow">
                <Col sm={12} className="custom-width-col">
                  <h2>{t(`${tKey}SECURE_DATA`)}</h2>
                </Col>
                <Col sm={2} className="custom-width-col">
                  <Field
                    id="hiredInsurance"
                    name='hiredInsurance'
                    placeholder={t(`${tKey}HIRED`)}
                    controlLabel={t(`${tKey}HIRED`)}
                    component={InputCheckBox}
                    onInputChange={(value) => this.hiredInsuranceChange(value)}
                    valueKey="id"
                    labelKey="value"
                    customClass="m-top-10"
                  />
                </Col>
                <Col sm={10} className="custom-width-col">
                  <Field
                    colSm={12}
                    id="insuranceCompany"
                    name='insuranceCompany'
                    disabled={!this.state.hiredInsuranceChange}
                    placeholder={t(`${tKey}INSURANCE_COMPANY`)}
                    controlLabel={t(`${tKey}INSURANCE_COMPANY`)}
                    component={InputSelect}
                    options={insuranceCompanyCombo}
                    valueKey="value"
                    labelKey="value"
                  />
                </Col>
                <Col sm={12} className="custom-width-col">
                  <h2>{t(`${tKey}MAINTENANCE_DATA`)}</h2>
                </Col>
                <Col sm={2} className="custom-width-col">
                  <Field
                    id="hiredMaintenance"
                    name='hiredMaintenance'
                    placeholder={t(`${tKey}HIRED`)}
                    controlLabel={t(`${tKey}HIRED`)}
                    component={InputCheckBox}
                    onInputChange={(value) => this.hiredMaintenanceChange(value)}
                    valueKey="id"
                    labelKey="value"
                  />
                </Col>
                <Col sm={3} className="custom-width-col">
                  <p className='m-top-10'>{t(`${tKey}MAINTENANCE_NUMBER`)}</p>
                </Col>
                <Col sm={1} className="custom-width-col">
                  <Field
                    id="maintenanceNumber"
                    name='maintenanceNumber'
                    colSm={1}
                    disabled={!this.state.hiredMaintenanceChange}
                    placeholder="99"
                    textAlign="center"
                    component={InputNumber}
                    maxLength={2}
                    valueKey="id"
                    labelKey="value"
                  />
                </Col>
                <Col sm={6} className="custom-width-col Maintenance">
                  <Field
                    id="chargeClientVnMaintenance"
                    key="chargeClientVnMaintenance"
                    name="chargeClientVnMaintenance"
                    groupName="chargeClientVnMaintenance"
                    disabled={!this.state.hiredMaintenanceChange}
                    colSm={3}
                    inline
                    component={InputRadio}
                    items={[
                      { id: 'MANTENIMIENTO_CLIENTE', label: `${tKey}CHARGE_VN`, value: 'CODE#MANTENIMIENTO_CLIENTE' },
                      { id: 'MANTENIMIENTO_VN', label: `${tKey}CHARGE_CLIENT`, value: 'CODE#MANTENIMIENTO_VN' }
                    ]}
                    t={t}
                  />
                </Col>
                <Col sm={12} className="custom-width-col">
                  <h2>{t(`${tKey}FINANCING`)}</h2>
                </Col>
                <Col sm={3} className="custom-width-col">
                  <p className='m-top-10'>{t(`${tKey}PERMANENCE_MONTHS`)}</p>
                </Col>
                <Col sm={1} className="custom-width-col">
                  <Field
                    id="permanenceMonths"
                    name='permanenceMonths'
                    placeholder="99"
                    textAlign="center"
                    component={InputNumber}
                    maxLength={2}
                    valueKey="id"
                    labelKey="value"
                  />
                </Col>
                <Col sm={12} className="custom-width-col">
                  <h2>{t(`${tKey}CONSENT`)}</h2>
                </Col>
                <Col sm={4} className="custom-width-col">
                  <Field
                    id="consent"
                    name='consent'
                    placeholder={t(`${tKey}ACCEPT`)}
                    controlLabel={t(`${tKey}ACCEPT`)}
                    component={InputCheckBox}
                    valueKey="id"
                    labelKey="value"
                  />
                </Col>
              </Row>
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <Col sm={6} className="btn-wrapper">
              <Button id="role-modal-submit-button" type="submit" className="btn-standard" disabled={!this.props.valid}>
                {t(`${tKey}SAVE_BUTTON`)}
              </Button>
            </Col>
            <Col sm={1} className="btn-wrapper">
              <Button className="btn-danger" type="reset" onClick={this.closeModalActions.bind(this)}>
                {t(`${tKey}CANCEL_BUTTON`)}
              </Button>
            </Col>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

const validate = (values, props) => {
  const errors = {}

  if (!values.birthdate) {
    errors.birthdate = props.t('GLOBAL.FORMS.REQUIRED')
  }
  if (moment(values.birthdate, 'DD/MM/YYYY').isAfter(new Date())) {
    errors.birthdate = props.t('DOSSIER_COMPONENTS.DOCUMENTARY_MANAGEMENT.ADD_OR_EDIT_DOCUMENT.FROM_FORM_TEMPLATE.ERRORBIRTHDATE')
  }

  if (values.accessory1) {
    if (!values.reference1) {
      errors.reference1 = props.t('GLOBAL.FORMS.REQUIRED')
    }
    if (!values.chargeVnClient1) {
      errors.chargeVnClient1 = props.t('GLOBAL.FORMS.REQUIRED')
    }
  }
  if (values.accessory2) {
    if (!values.reference2) {
      errors.reference2 = props.t('GLOBAL.FORMS.REQUIRED')
    }
    if (!values.chargeVnClient2) {
      errors.chargeVnClient2 = props.t('GLOBAL.FORMS.REQUIRED')
    }
  }
  if (values.accessory3) {
    if (!values.reference3) {
      errors.reference3 = props.t('GLOBAL.FORMS.REQUIRED')
    }
    if (!values.chargeVnClient3) {
      errors.chargeVnClient3 = props.t('GLOBAL.FORMS.REQUIRED')
    }
  }
  if (values.accessory4) {
    if (!values.reference4) {
      errors.reference4 = props.t('GLOBAL.FORMS.REQUIRED')
    }
    if (!values.chargeVnClient4) {
      errors.chargeVnClient4 = props.t('GLOBAL.FORMS.REQUIRED')
    }
  }

  if (values.hiredInsurance && !values.insuranceCompany) {
    errors.insuranceCompany = props.t('GLOBAL.FORMS.REQUIRED')
  }

  if (values.hiredMaintenance) {
    if (!values.maintenanceNumber) {
      errors.maintenanceNumber = props.t('GLOBAL.FORMS.REQUIRED')
    }
    if (!values.chargeClientVnMaintenance) {
      errors.chargeClientVnMaintenance = props.t('GLOBAL.FORMS.REQUIRED')
    }
  }
  if (props.financingAmount !== 0 && !values.permanenceMonths) {
    errors.permanenceMonths = props.t('GLOBAL.FORMS.REQUIRED')
  }

  return errors
}

export default reduxForm({
  form: 'ReservationContractModal',
  validate
})(ReservationContractModal)
