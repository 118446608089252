import mastersActionTypes from '../../../constants/actions/masters/masters'

function initialState () {
  return {
    sourceDirectionsList: [],
    directionsUOCount: 0,
    pagesCount: 0,
    filter: {
      page: 1,
      orderBy: '',
      pageSize: 10
    }
  }
}

function fetchDirectionUOMasterSuccess (state, { sourceDirectionsList, filter }) {
  return {
    ...state,
    sourceDirectionsList: sourceDirectionsList.directionUOMastersList,
    directionsUOCount: sourceDirectionsList.directionsUOCount,
    pagesCount: sourceDirectionsList.pagesCount,
    filter
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case mastersActionTypes.FETCH_DIRECTION_UO_MASTER_SUCCESS:
      return fetchDirectionUOMasterSuccess(state, action)
    default:
      return state
  }
}
