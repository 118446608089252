import { put, call, select, takeEvery } from 'redux-saga/effects'
import { getAuth } from '../../selectors/access/auth'
import { getWalletFilter } from '../../selectors/reports/walletReport'
import walletActionTypes from '../../constants/actions/reports/walletReport'
import { fetchWalletReportSuccess } from '../../actions/reports/walletReport'
import { showLoader, hideLoader } from '../../actions/common.js'
import postWalletListService from '../../services/reports/postWalletList'
import { handleError } from '../errors/errorManager'
import { fetchCombosSuccess } from '../../actions/combos/combos'
import getSalesmanUserHierarchy from '../../services/salesman/getSalesmanUserHierarchy'
import getLowestOrganizedUnits from '../../services/organizedUnit/getOrganizedUnits'
import getDownloadWalletList from '../../services/reports/getDownloadWalletList'

export function * fetchWalletList ({ filter }) {
  try {
    yield put(showLoader())
    yield filter = yield filter || select(getWalletFilter)
    const auth = yield select(getAuth)
    const walletList = yield call(postWalletListService, auth.token, filter)
    const salesmanCombo = yield call(getSalesmanUserHierarchy, auth.token)
    const lowestOrganizedUnitsCombo = yield call(getLowestOrganizedUnits, auth.token)
    yield put(fetchCombosSuccess({
      salesmanCombo,
      lowestOrganizedUnitsCombo
    }))
    yield put(fetchWalletReportSuccess(walletList, filter))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchWalletList () {
  yield takeEvery(walletActionTypes.FETCH_WALLET_REPORT, fetchWalletList)
}

export function * downloadWalletList ({ filter }) {
  try {
    yield put(showLoader())
    yield filter = yield filter || select(getWalletFilter)
    const auth = yield select(getAuth)
    yield call(getDownloadWalletList, auth.token, filter)
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDownloadWalletList () {
  yield takeEvery(walletActionTypes.DOWNLOAD_WALLET_REPORT, downloadWalletList)
}
