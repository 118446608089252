import React, { PureComponent } from 'react'
import { Panel, Col, Row, Button, Table } from 'react-bootstrap'
import SmallLoaderPage from '../../../containers/commons/SmallLoaderPage'
import { compareObjects } from '../../../util/utils'
import { getFieldsPermisions } from '../../../util/permisionFunctions'
import { Field } from 'redux-form'
import { permissions } from '../../../constants/backendIds'
import InputDatePicker from '../../commons/form/InputDatePicker'
import BudgetRow from '../stock/BudgetRow'
import InputText from '../../commons/form/InputText'
import { currencySymbol } from '../../../constants/dossier/common/currencies'
import setting from '../../../setting'

class DossierBudget extends PureComponent {
  constructor () {
    super()
    this.state = {
      open: false,
      fieldsConfiguration: {
        confirmationDoneButton: null,
        confirmationDate: null,
        deliveryDate: null,
        budgetRatingTable: null,
        acceptBudgetButton: null,
        approvalDate: null,
        comment: null,
        rejectBudgetButton: null
      }
    }
    this.loadBudget = this.loadBudget.bind(this)
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (this.props.dossier && this.props.dossier.dossierId) {
      if (!compareObjects(prevProps.componentConfiguration || {}, this.props.componentConfiguration, true)) {
        this.initializePermisions(this.props)
      }
      if (this.props.open && !this.state.open) {
        this.setState({ open: true })
        this.loadBudget()
      }
    }
    return prevProps
  }

  componentDidMount () {
    this.initializePermisions(this.props)
  }

  componentDidUpdate () {
    // don't remove use for getsnapshot
  }

  initializePermisions (props) {
    const fieldKeys = Object.keys(this.state.fieldsConfiguration)
    const newState = getFieldsPermisions(props.componentConfiguration.fieldsConfiguration, fieldKeys, props.componentConfiguration.permission)
    this.setState({ fieldsConfiguration: newState.fieldsConfiguration })
  }

  toggleComponent () {
    if (this.state.open) {
      this.props.actions.setActiveSection(false)
    }
    this.setState({ open: !this.state.open })
    if (this.props.isBudgetLoaded === false) {
      return this.loadBudget()
    }
  }

  loadBudget () {
    return new Promise((resolve, reject) => {
      this.props.handleToUpdateComponent(this.props)
      this.props.actions.getDossierBudget(this.props.dossier.dossierId, this.props.isHistorical, this.props.pristine, resolve, reject)
    })
  }

  showServiceObservationModal (show, service) {
    this.setState({ showModal: show, service: service })
  }

  render () {
    const {
      t, readOnlyFields, dossier: { dossierBudget }, actions: { confirmDateWithIdByStage, fetchDossierBudgetRating, deleteDossierBudgetRating, rejectDossierStockVoBudget, getDossierBudget }, dossierId
    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.DOSSIER_BUDGET.'
    const deliveryDateFilled = this.props.dossier.dossierBudget && this.props.dossier.dossierBudget.deliveryDate !== undefined && this.props.dossier.dossierBudget.deliveryDate !== null
    return (
      <div className="dossierBudget-panel dossier-panel">
        <div className="header-panel" onClick={() => this.toggleComponent()}>
          <div className="name-wrapper">
            <i className="ico-i" />
            <h4>{t(`${tKey}TITLE_PANEL`)}</h4>
          </div>
          <i className={this.state.open ? 'ico-arrow-black-up' : 'ico-arrow-black-down'} />
        </div>
        <Panel expanded={this.state.open} onToggle={() => { }}>
          <Panel.Collapse>
            {
              deliveryDateFilled && this.state.fieldsConfiguration.confirmationDoneButton && this.state.fieldsConfiguration.confirmationDoneButton.permission === permissions.editable && !readOnlyFields && (
                <Row>
                  <Col className="col-right">
                    <Button className='btn-save' onClick={() => confirmDateWithIdByStage(dossierId, getDossierBudget)} disabled={this.state.fieldsConfiguration.confirmationDoneButton.permission !== permissions.editable}>
                      {t(`${tKey}DONE_BUTTON`)}
                    </Button>
                  </Col>
                </Row>
              )
            }
            {
              this.state.fieldsConfiguration.acceptBudgetButton && this.state.fieldsConfiguration.acceptBudgetButton.permission === permissions.editable && !readOnlyFields && (
                <Row>
                  <Col className="col-right">
                    <Button className='btn-save' onClick={() => confirmDateWithIdByStage(dossierId, getDossierBudget)} disabled={this.state.fieldsConfiguration.acceptBudgetButton.permission !== permissions.editable}>
                      {t(`${tKey}ACCEPT_BUDGET_BUTTON`)}
                    </Button>
                  </Col>
                </Row>
              )
            }
            {
              this.state.fieldsConfiguration.budgetRatingTable && this.state.fieldsConfiguration.budgetRatingTable.permission !== permissions.hidden && (
                <Table className="editable-table budget-table" hover responsive>
                  <thead>
                    <tr>
                      <th style={{ width: '60%' }}>{t(`${tKey}CONCEPT`)}</th>
                      <th>{t(`${tKey}AMOUNT`)}</th>
                      <th>{t(`${tKey}ACTIONS`)}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      dossierBudget?.items?.map((item, idx) => {
                        return (
                          <BudgetRow
                            t={t}
                            tKey={tKey}
                            budget={item}
                            key={idx}
                            readOnlyFields={this.state.fieldsConfiguration.budgetRatingTable.permission !== permissions.editable || readOnlyFields}
                            deleteDossierBudgetRating={deleteDossierBudgetRating}
                            fetchDossierBudgetRating={fetchDossierBudgetRating}
                          />
                        )
                      })
                    }
                  </tbody>
                  <tfoot>
                    <tr>
                      <td>
                        <Button className='btn-save' onClick={() => fetchDossierBudgetRating()} disabled={this.state.fieldsConfiguration.budgetRatingTable.permission !== permissions.editable}>
                          {t(`${tKey}ADD_BUDGET_BUTTON`)}
                        </Button>
                      </td>
                      <td>
                        <p>{t(`${tKey}TOTAL_AMOUNT`) + ': ' + (dossierBudget?.total || 0) + currencySymbol[setting.currency]}</p>
                      </td>
                      <td></td>
                    </tr>
                  </tfoot>
                </Table>
              )
            }
            <Row className='flexRow'>
              {
                this.state.fieldsConfiguration.deliveryDate && this.state.fieldsConfiguration.deliveryDate.permission !== permissions.hidden && (
                  <Field
                    id="deliveryDate"
                    name="dossierBudget.deliveryDate"
                    colSm={4}
                    controlLabel={t(`${tKey}DELIVERY_DATE`)}
                    placeholder={t(`${tKey}DELIVERY_DATE`)}
                    component={InputDatePicker}
                    disabled={readOnlyFields || this.state.fieldsConfiguration.deliveryDate.permission !== permissions.editable }
                  />
                )
              }
              {
                this.state.fieldsConfiguration.confirmationDate && this.state.fieldsConfiguration.confirmationDate.permission !== permissions.hidden && (
                  <Field
                    id="confirmationDate"
                    name="dossierBudget.confirmationDate"
                    colSm={4}
                    controlLabel={t(`${tKey}CONFIRMATION_DATE`)}
                    placeholder={t(`${tKey}CONFIRMATION_DATE`)}
                    component={InputDatePicker}
                    disabled={readOnlyFields || this.state.fieldsConfiguration.confirmationDate.permission !== permissions.editable }
                  />
                )
              }
              {
                this.state.fieldsConfiguration.approvalDate && this.state.fieldsConfiguration.approvalDate.permission !== permissions.hidden && (
                  <Field
                    id="approvalDate"
                    name="dossierBudget.approvalDate"
                    colSm={4}
                    controlLabel={t(`${tKey}APPROVAL_DATE`)}
                    placeholder={t(`${tKey}APPROVAL_DATE`)}
                    component={InputDatePicker}
                    disabled={readOnlyFields || this.state.fieldsConfiguration.approvalDate.permission !== permissions.editable }
                  />
                )
              }
            </Row>
            <Row className='flexRow'>
              <Col sm={12} className="budget-reject-row">
                {
                  this.state.fieldsConfiguration.comment && this.state.fieldsConfiguration.comment && this.state.fieldsConfiguration.comment.permission !== permissions.hidden && (
                    <Field
                      id="comment"
                      name="dossierBudget.comment"
                      colSm={8}
                      controlLabel={t(`${tKey}COMMENT`)}
                      placeholder={t(`${tKey}COMMENT`)}
                      component={InputText}
                      disabled={readOnlyFields || this.state.fieldsConfiguration.comment.permission !== permissions.editable }
                    />
                  )
                }
                {
                  this.state.fieldsConfiguration.rejectBudgetButton && this.state.fieldsConfiguration.rejectBudgetButton.permission !== permissions.hidden && !readOnlyFields && (
                    <Col sm={4}>
                      <Button className='btn-reject'
                        onClick={() => rejectDossierStockVoBudget(dossierId)}
                        disabled={this.state.fieldsConfiguration.rejectBudgetButton.permission !== permissions.editable || !dossierBudget?.comment}>
                        {t(`${tKey}REJECT_BUDGET_BUTTON`)}
                      </Button>
                    </Col>
                  )
                }
              </Col>
            </Row>
          </Panel.Collapse>
        </Panel>
        <SmallLoaderPage />
      </div>
    )
  }
}
export default DossierBudget
