import apiFetch from '../apiFetch'

export default function (documentTypeUseCode, token, dossierId, dynamicValues) {
  const queryParamsArray = [
    documentTypeUseCode ? `documentTypeUseCode=${documentTypeUseCode}` : 'documentTypeUseCode=null',
    dossierId ? `dossierId=${dossierId}` : null
  ]

  const queryParams = queryParamsArray.reduce(
    (result, query, idx) => result + (query ? (result ? `&${query}` : `?${query}`) : '')
    , ''
  )
  return apiFetch({ endPoint: `Document/GenerateDocumentFromDynamicTemplate${queryParams}`, parseBlobAndAssign: true, method: 'POST', body: dynamicValues, token: token })
}
