import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import SearchByFleetReport from '../../../_v2/components/report/SearchByFleetReport'
import { translate } from 'react-polyglot'
import { fetchEntityTypes, fetchEntitySubTypeCombo, fetchEntityFilterSubTypeCombo, fetchPurchaseLogisticStateCombo, fetchStagesCombo } from '../../../actions/combos/combos'
import { fetchSalesmanCombos } from '../../../actions/management/auxModals/auxModals'
import { fetchFleetReport, fetchFleetReportDownload, setFilters, initializeSearch, resetFilters } from '../../../actions/search/search'
import { openModal } from '../../../actions/common'

export function mapStateToProps (state) {
  return {
    ...state.search,
    combos: {
      UOTreeCombo: state.combos.UOTreeCombo,
      entityTypeCombo: state.combos.entityTypeCombo,
      entitySubTypeCombo: state.combos.entitySubTypeCombo,
      entityFilterSubTypeCombo: state.combos.entityFilterSubTypeCombo,
      salesmanCombo: state.combos.salesmanCombo,
      purchaseStateLogisticCombo: state.combos.purchaseStateLogisticCombo,
      stagesCombo: state.combos.stagesCombo
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchEntityTypes,
      fetchEntitySubTypeCombo,
      fetchEntityFilterSubTypeCombo,
      fetchPurchaseLogisticStateCombo,
      fetchSalesmanCombos,
      fetchFleetReport,
      fetchFleetReportDownload,
      setFilters,
      resetFilters,
      initializeSearch,
      openModal,
      fetchStagesCombo
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(SearchByFleetReport))
