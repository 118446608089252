import React, { PureComponent } from 'react'
import { Col, OverlayTrigger, Row, Table, Tooltip, Button } from 'react-bootstrap'
import { Field, reduxForm } from 'redux-form'
import LegalCompanyModalPage from '../../../containers/masters/LegalCompany/LegalCompanyModalPage'
import InputText from '../../commons/form/InputText'
import IDocCarPagination from '../../commons/iDocCarPagination'
class LegalCompanyMaster extends PureComponent {
  constructor () {
    super()
    this.state = {
      filter: {
        searchCif: null,
        searchDescription: null
      }
    }
  }

  getSortIcon (column = '') {
    switch (this.props.filter.orderBy) {
      case '+' + column:
        return 'ico-sort-selected-asc'
      case '-' + column:
        return 'ico-sort-selected-desc'
      default:
        return 'ico-sort'
    }
  }

  changeOrder (field) {
    switch (this.props.filter.orderBy) {
      case `+${field}`:
        this.props.actions.fetchLegalCompanyList({ ...this.state.filter, ...this.props.filter, orderBy: `-${field}` })
        break
      default:
        this.props.actions.fetchLegalCompanyList({ ...this.state.filter, ...this.props.filter, orderBy: `+${field}` })
        break
    }
  }

  handleCifChange (searchCif) {
    if (this.state.filter.searchCif !== searchCif) {
      this.setState({ filter: { ...this.state.filter, searchCif } })
    }
  }

  handleDescriptionChange (searchDescription) {
    if (this.state.filter.searchDescription !== searchDescription) {
      this.setState({ filter: { ...this.state.filter, searchDescription } })
    }
  }

  searchLegalCompanyList () {
    this.props.actions.fetchLegalCompanyList({ ...this.props.filter, ...this.state.filter, page: 1 })
  }

  resetFilters () {
    this.setState({
      filter: {
        searchCif: null,
        searchDescription: null
      }
    }, () => { this.searchLegalCompanyList() })
  }

  render () {
    const {
      t, legalCompanyList, pagesCount, legalCompanyCount, filter,
      filter: { page },
      actions: { openLegalCompanyModal, deleteLegalCompanyModal, fetchLegalCompanyList }
    } = this.props
    const tKey = 'MASTERS.LEGAL_COMPANY_MASTERS.'
    const editTooltip = (<Tooltip id="editTooltip">{t('MASTERS.EDIT')}</Tooltip>)
    const deleteTooltip = (<Tooltip id="deleteTooltip">{t('MASTERS.DELETE')}</Tooltip>)
    const hasFilters = (this.state.filter.searchCif || this.state.filter.searchDescription)
    return (
      <div className="admin-wrapper">
        <Row className="section-users-header">
          <Col sm={12}>
            <div className="header-panel">
              <h4>{t(`${tKey}LEGAL_COMPANY_TITLE`)}</h4>
            </div>
          </Col>
        </Row>
        <Row>
          <div className="search-form">
            <span className="title-search"><i className="ico-search" /> {t(`${tKey}SEARCH_LEGAL_COMPANY_TITLE`)}</span>
            <Row>
              <Field
                name="filters.cif"
                colSm={2}
                controlLabel={t(`${tKey}CIF`)}
                placeholder={t(`${tKey}CIF`)}
                component={InputText}
                onInputChange={(value) => this.handleCifChange(value)}
              />
              <Field
                name="filters.description"
                colSm={4}
                controlLabel={t(`${tKey}DESCRIPTION`)}
                placeholder={t(`${tKey}DESCRIPTION`)}
                component={InputText}
                onInputChange={(value) => this.handleDescriptionChange(value)}
              />
              <Col sm={6} className="btn-wrapper">
                <Button
                  className={'btn-white btn-icon btn-input btn-white search-button'}
                  onClick={() => this.searchLegalCompanyList()}>
                  <i className="ico-search" />
                  {t('MASTERS.SEARCH_BTN')}
                </Button>
                <Button
                  className={'btn-icon btn-input ' + (hasFilters ? 'btn-standard' : 'btn-cancel')}
                  onClick={() => {
                    this.props.initialize({})
                    this.resetFilters()
                  }}>
                  <i className="ico-cancel" />
                  {t('MASTERS.RESET_BUTTON')}
                </Button>
              </Col>
            </Row>
          </div>
        </Row>
        <LegalCompanyModalPage />
        <Col sm={12}>
          <Table className="editable-table" hover responsive>
            <thead>
              <tr>
                <th
                  onClick={() => { this.changeOrder('legalCompanyId') }}
                >
                  {t('MASTERS.ID')}
                  <i className={this.getSortIcon(t('MASTERS.ID'))} />
                </th>
                <th
                  onClick={() => { this.changeOrder('description') }}
                >
                  {t(`${tKey}DESCRIPTION`)}
                  <i className={this.getSortIcon(t(`${tKey}DESCRIPTION`))} />
                </th>
                <th
                  onClick={() => { this.changeOrder('cif') }}
                >
                  {t(`${tKey}CIF`)}
                  <i className={this.getSortIcon(t(`${tKey}CIF`))} />
                </th>
                <th
                  onClick={() => { this.changeOrder('commercialRegistry') }}
                >
                  {t(`${tKey}COMERCIAL_REGISTRY`)}
                  <i className={this.getSortIcon(t(`${tKey}COMERCIAL_REGISTRY`))} />
                </th>
                <th
                  onClick={() => { this.changeOrder('brand') }}
                >
                  {t(`${tKey}BRAND`)}
                  <i className={this.getSortIcon(t(`${tKey}BRAND`))} />
                </th>
                <th style={{ width: '77px' }}>{t('DOSSIER_COMPONENTS.ACCESSORIES.ACTIONS')}</th>
              </tr>
            </thead>
            <tbody>
              {legalCompanyList && legalCompanyList.map((legal, idx) => (
                <tr key={idx}>
                  <td>{legal.legalCompanyId}</td>
                  <td>{legal.description}</td>
                  <td>{legal.cif}</td>
                  <td>{legal.commercialRegistry}</td>
                  <td>{legal.brand}</td>
                  <td>
                    <a onClick={() => openLegalCompanyModal(legal.legalCompanyId)}>
                      <OverlayTrigger placement="left" overlay={editTooltip}>
                        <i className="ico-edit-white"/>
                      </OverlayTrigger>
                    </a>
                    <a onClick={() => deleteLegalCompanyModal(legal.legalCompanyId)}>
                      <OverlayTrigger placement="left" overlay={deleteTooltip}>
                        <i className="ico-trash"/>
                      </OverlayTrigger>
                    </a>
                  </td>
                </tr>
              ))}
              {(legalCompanyList === null || legalCompanyList === undefined || legalCompanyList.length === 0) &&
            <tr>
              <td colSpan={6}><h2>{t(`${tKey}RESULTS_NOT_FOUND`)}</h2></td>
            </tr>}
              <tr className="button-table-wrapper">
                <td>
                  <Button
                    className="btn-editable-table btn-standard"
                    onClick={() => openLegalCompanyModal()}>
                  + {t('MASTERS.ADD')}
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>
          <div className="section-users-footer">
            <nav key={1} className="text-center">
              <IDocCarPagination
                id="btn_pag_legal_company"
                page={page}
                pagesCount={pagesCount}
                totalRows={legalCompanyCount}
                onSelectAction={(value) => fetchLegalCompanyList({ ...filter, ...this.state.filter, page: value })}
              />
            </nav>
          </div>
        </Col>
      </div>

    )
  }
}

export default reduxForm({
  form: 'listLegalCompanyMaster'
})(LegalCompanyMaster)
