import apiFetch from '../../apiFetch'

export default function (token, dossierIdArray, availabilityDate, fields) {
  return apiFetch({
    endPoint: `dossier/setAvailabilityDate?&availabilityDate=${availabilityDate}&dossierIds=${dossierIdArray.join(',')}`,
    method: 'PUT',
    token: token,
    body: fields
  })
}
