import { call, put, select, takeLatest, takeEvery, all } from 'redux-saga/effects'
import { getAuth } from '../../selectors/access/auth'
import saveFleetDataChanges from '../../services/managementActions/saveDataFleetChanges'
import managementActionTypes from '../../constants/actions/management/management'
import { handleError } from '../errors/errorManager'
import commonActions, { showLoader, hideLoader } from '../../actions/common.js'
import findFleetsinfo from '../../services/dossierFinder/findFleetsinfo'
import {
  fetchFleetsInfoSuccess, fetchPaymentComboSuccess, getClientFleetPermissionsSuccess, getEconomicPlanPermissionsSuccess, simulateEconomicPlanFleetSuccess,
  addCampaignSuccess, fetchFleetsManagementSuccess, fetchEntityFleetSucces, getDossierDetailsFleetSuccess, openOperateFleetModalSuccess, initializeOperationActionsComboSuccess,
  fetchOperateDataFleetModalsPermissionsSuccess, fetchOperatePaperworkFleetPermissionsSuccess, getDocumentFleetListSuccess, fetchSelfSupplyInfoSuccess, openEconomicFleetModalSuccess,
  openAuditAdminModalSuccess, openZipModalSuccess, fetchDocumentTypeUsesInfoSuccess, fetchAuditAdminInfoSuccess, fetchAuditsInfoSuccess, fetchSearchDocumentsInfoSuccess, changeCodeCommissionFleetSuccess, getPaperworksDetailsFleetSuccess,
  getDossierChildSubtypeDetailCountSuccess, getChargesDetailsFleetSuccess, getFleetEconomicSuccess
} from '../../actions/management/management'
import messageModalActions from '../../actions/modals/messageModal'
import postDocumentTypeUseTable from '../../services/documentTypeUse/postDocumentTypeUseTable.js'
import putAuditConfiguration from '../../services/auditConfiguration/auditConfiguration'
import documentConfiguration from '../../services/auditConfiguration/documentConfiguration'
import documentConfigurationFinish from '../../services/auditConfiguration/documentConfigurationFinish'
import deleteAuditConfiguration from '../../services/auditConfiguration/deleteAuditConfigurationDocument'
import getAuditConfigurationDocument from '../../services/auditConfiguration/auditConfigurationDocument'
import assignDossierToFleet from '../../services/managementActions/assignDossierToFleet'
import changeClientDossier from '../../services/managementActions/changeClientDossier'
import createOrUpdateFleet from '../../services/managementActions/createOrUpdateFleet'
import deleteDossierFleet from '../../services/managementActions/deleteDossierFleet'
import deleteFleet from '../../services/managementActions/deleteFleet'
import { yesNoModal } from '../modalsListeners/yesNoModal'
import findFleetsInfoDownload from '../../services/dossierFinder/findFleetsInfoDownload'
import getPaymentMethod from '../../services/paymentMethod/getPaymentMethod'
import putDossierSaleSendToValidationService from '../../services/dossierSale/putDossierSaleSendToValidation'
import putDossierSaleApproveService from '../../services/dossierSale/putDossierSaleApprove'
import putApproveByRole from '../../services/dossierSale/putApproveByRole'
import getValidateDocumentPermission from '../../services/managementActions/getValidateDocumentPermission'
import postUploadDifferentDocumentFleet from '../../services/document/postUploadDifferentDocumentFleet'
import getClientFleetPermissions from '../../services/managementActions/getClientFleetPermissions'

import getIvaTypeByDossierSubType from '../../services/ivaType/getIvaTypeByDossierSubType'
import getIemType from '../../services/iemType/getIemType'
import getExtraSaleTypes from '../../services/extraSale/getExtraSaleTypes'
import getPresentTypes from '../../services/present/getPresentTypes'
import getAssociatedCostsTypes from '../../services/associatedCost/getAssociatedCostsTypes'
import getSuppliedTypes from '../../services/supplied/getSuppliedTypes'
import getEconomicPlanPermissions from '../../services/managementActions/getEconomicPlanPermissions'
import getCampaignType from '../../services/campaignType/getCampaignType'
import getDocumentsTypes from '../../services/document/getDocumentsTypes'
import getDossierSubTypeByTypeAndUo from '../../services/dossierType/getDossierSubTypeByTypeAndUo'
import { dossierTypesId } from '../../constants/dossier/common/dossierTypes'
import postSimulateEconomicPlanFleet from '../../services/fleets/postSimulateEconomicPlanFleet'
import postSaveEconomicPlanFleet from '../../services/fleets/postSaveEconomicPlanFleet'
import { change, initialize } from 'redux-form'
import getShowFrontEconomicPlan from '../../services/organizedUnit/getShowFrontEconomicPlan'
import getDocumentsTypesFleets from '../../services/documentTypeUse/getDocumentsTypesFleets'
import { v4 as uuidv1 } from 'uuid'
import { fetchCombosSuccess } from '../../actions/combos/combos'
import postFindFleetsManagement from '../../services/fleets/postFindFleetsManagement'
import postFindFleetsManagementExport from '../../services/fleets/postFindFleetsManagementExport'
import getFleetModel from '../../services/fleets/getFleetModel'
import getEntitySubType from '../../services/entitySubType/getEntitySubType'
import getEntityFilterSubType from '../../services/entity/getEntityFilterSubType'
import getDossierDetailsFleet from '../../services/fleets/getDossierDetailsFleet'
import deleteDossiersFleet from '../../services/managementActions/deleteDossiersFleet'
import getSalesman from '../../services/salesman/getSalesman'
import getSalesmanLogic from '../../services/salesman/getSalesmanLogic'
import auxModalsFleetsAction from '../../constants/actions/management/auxModals/auxModals'
import getSaleTypesByUo from '../../services/saleType/getSaleTypesByUo'
import { commonCodes, economicCodes, paperworkCodes, errorSelfSupplyErrorCode } from '../../constants/dossier/common/fieldCodePermissions'
import getPaperWorkFleetPermission from '../../services/managementActions/getPaperWorkFleetPermission'
import getShowEconomic from '../../services/managementActions/getShowEconomic'
import { getShowEconomicSuccess } from '../../actions/management/auxModals/auxModals'
import getDocumentsFleetList from '../../services/fleets/getDocumentsFleetList'
import fetchDossierSelfSupplyManagementInfo from '../../services/dossierFinder/fetchDossierSelfSupplyManagementInfo'
import fetchAuditAdminInfoService from '../../services/dossierFinder/fetchAuditAdminInfo'
import fetchSearchDocumentsInfoService from '../../services/dossierFinder/fetchSearchDocumentsInfo'
import fetchDossierSelfSupplyManagementInfoDownload from '../../services/dossierFinder/fetchDossierSelfSupplyManagementInfoDownload'
import putCreateSelfSupplyVO from '../../services/dossierSale/putCreateSelfSupplyVO'
import backEndTagTranslation from '../../constants/backEndTagTransaltion'
import getCanCreateOperateServices from '../../services/managementActions/getCanCreateOperateServices'
import getPaperworksDetailsListFleet from '../../services/fleets/getPaperworksDetailsListFleet'
import getDossierChildSubtypeDetailCount from '../../services/fleets/getDossierChildSubtypeDetailCount'
import getChargesDetailsListFleet from '../../services/fleets/getChargesDetailsListFleet'
import getChargesFleetEconomic from '../../services/fleets/getChargesFleetEconomic'

export function * saveFleetData ({ dossierId, saleType, operationTypes, paymentMethod, resolve, reject }) {
  try {
    yield put(commonActions.showLoader())
    const auth = yield select(getAuth)
    var result = yield call(saveFleetDataChanges, auth.token, { dossierId, saleType, operationTypes, paymentMethod })
    if (resolve) yield call(resolve, result)
  } catch (error) {
    if (reject) {
      yield call(reject)
    } else {
      yield call(handleError, { error })
    }
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchsaveFleetData () {
  yield takeLatest(managementActionTypes.SAVE_FLEET_DATA_CHANGES, saveFleetData)
}

export function * fetchFleetsInfo ({ filters }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const result = yield call(findFleetsinfo, auth.token, filters)
    yield put(fetchFleetsInfoSuccess(result, filters))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchfetchFleetsInfo () {
  yield takeEvery(managementActionTypes.FETCH_FLEETS_INFO, fetchFleetsInfo)
}

export function * fetchAssignDossierToFleet ({ dossierId, fleetId, resolve, reject }) {
  try {
    yield put(commonActions.showLoader())
    const auth = yield select(getAuth)
    var result = yield call(assignDossierToFleet, auth.token, { dossierId, fleetId })
    if (resolve) yield call(resolve, result)
  } catch (error) {
    if (reject) {
      yield call(reject, error)
    } else {
      yield call(handleError, { error })
    }
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchFetchAssignDossierToFleet () {
  yield takeLatest(managementActionTypes.ASIGN_DOSSIER_TO_FLEET, fetchAssignDossierToFleet)
}

export function * fetchChangeClientDossier ({ dossierId, fleetId, resolve, reject }) {
  try {
    yield put(commonActions.showLoader())
    const auth = yield select(getAuth)
    var result = yield call(changeClientDossier, auth.token, { dossierId, fleetId })
    if (resolve) yield call(resolve, result)
  } catch (error) {
    if (reject) {
      yield call(reject, error)
    } else {
      yield call(handleError, { error })
    }
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchFetchChangeClientDossier () {
  yield takeLatest(managementActionTypes.CHANGE_ENTITY_DOSSIER_FROM_FLEET, fetchChangeClientDossier)
}

export function * fetchCreateOrUpdateFleet ({ fleetModel, resolve, reject, fleet }) {
  try {
    const auth = yield select(getAuth)
    if (fleet.numDossiers > 0) {
      const confirmed = yield call(yesNoModal, 'clientFleetModify')
      if (confirmed) {
        yield put(commonActions.showLoader())
        fleetModel.UpdateDossiers = false
        yield call(createOrUpdateFleet, auth.token, fleetModel)

        if (resolve) yield call(resolve, fleet)
      }
      resolve(false)
    } else {
      yield put(commonActions.showLoader())
      var result = yield call(createOrUpdateFleet, auth.token, fleetModel)
      if (resolve) yield call(resolve, result)
    }
  } catch (error) {
    if (reject) {
      yield call(reject, error)
    } else {
      yield call(handleError, { error })
    }
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchFetchCreateOrUpdateFleet () {
  yield takeLatest(managementActionTypes.CREATE_UPDATE_FLEET, fetchCreateOrUpdateFleet)
}

export function * fetchDeleteDossierFleet ({ dossierId, resolve, reject }) {
  try {
    yield put(commonActions.showLoader())
    const auth = yield select(getAuth)
    var result = yield call(deleteDossierFleet, auth.token, { dossierId })
    if (resolve) yield call(resolve, result)
  } catch (error) {
    if (reject) {
      yield call(reject, error)
    } else {
      yield call(handleError, { error })
    }
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchFetchFeleteDossierFleet () {
  yield takeLatest(managementActionTypes.DELETE_DOSSIER_FROM_FLEET, fetchDeleteDossierFleet)
}

export function * fetchDeleteFleet ({ fleetId, resolve, reject }) {
  try {
    let confirmedSaveWarning = yield call(yesNoModal, 'fleetDeleteSaveWarning')
    if (confirmedSaveWarning) {
      yield put(commonActions.showLoader())
      const auth = yield select(getAuth)
      var result = yield call(deleteFleet, auth.token, { fleetId })
      if (resolve) yield call(resolve, result)
    } else {
      if (resolve) yield call(resolve, false)
    }
  } catch (error) {
    if (reject) {
      yield call(reject, error)
    } else {
      yield call(handleError, { error })
    }
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchFetchDeleteFleet () {
  yield takeLatest(managementActionTypes.DELETE_FLEET, fetchDeleteFleet)
}

export function * fetchFleetsInfoDownload ({ filters, columns }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(findFleetsInfoDownload, auth.token, filters, columns)
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchfetchFleetsInfoDownload () {
  yield takeEvery(managementActionTypes.FETCH_FLEETS_INFO_DOWNLOAD, fetchFleetsInfoDownload)
}

export function * fetchPaymentCombo ({ includeDeleted }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const result = yield call(getPaymentMethod, auth.token, includeDeleted)
    yield put(fetchPaymentComboSuccess(result))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchfetchPaymentCombo () {
  yield takeEvery(managementActionTypes.FETCH_PAYMENT_COMBO, fetchPaymentCombo)
}

export function * sendToApproveActionFleet ({ dossierId, resolve, reject }) {
  try {
    yield put(commonActions.showLoader())
    const auth = yield select(getAuth)
    var result = yield call(putDossierSaleSendToValidationService, dossierId, auth.token, Date.now())
    if (resolve) yield call(resolve, result)
  } catch (error) {
    if (reject) {
      yield call(reject, error)
    } else {
      yield call(handleError, { error })
    }
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchsendToApproveActionFleet () {
  yield takeLatest(managementActionTypes.SEND_TO_APPROVE_ACTION_FLEET, sendToApproveActionFleet)
}

export function * approveActionFleet ({ dossierId, resolve, reject }) {
  try {
    yield put(commonActions.showLoader())
    const auth = yield select(getAuth)
    var result = yield call(putDossierSaleApproveService, dossierId, auth.token, Date.now())
    if (resolve) yield call(resolve, result)
  } catch (error) {
    if (reject) {
      yield call(reject, error)
    } else {
      yield call(handleError, { error })
    }
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchapproveActionFleet () {
  yield takeLatest(managementActionTypes.APPROVE_ACTION_FLEET, approveActionFleet)
}

export function * approveRoleActionFleet ({ dossierId, roleId, resolve, reject }) {
  try {
    yield put(commonActions.showLoader())
    const auth = yield select(getAuth)
    var result = yield call(putApproveByRole, dossierId, roleId, auth.token, Date.now())
    if (resolve) yield call(resolve, result)
  } catch (error) {
    if (reject) {
      yield call(reject, error)
    } else {
      yield call(handleError, { error })
    }
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchapproveRoleActionFleet () {
  yield takeLatest(managementActionTypes.APPROVE_ROLE_ACTION_FLEET, approveRoleActionFleet)
}

export function * fetchClientFleetPermissions ({ fleetId, organizedUnitId, dossierSubtypeId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const result = yield call(getClientFleetPermissions, auth.token, { fleetId: fleetId, organizedUnitId: organizedUnitId, dossierSubTypeId: dossierSubtypeId })
    yield put(getClientFleetPermissionsSuccess(result))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchfetchClientFleetPermissions () {
  yield takeEvery(managementActionTypes.GET_CLIENT_FLEET_PERMISSIONS, fetchClientFleetPermissions)
}

export function * fetchCombosEconomicModal ({ organizedUnitId, dossierSubTypeId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const [
      ivaTypeCombo, iemType,
      extraSaleTypeCombo, presentTypeCombo, suppliedTypeCombo, associatedCostTypeCombo, campaignsTypes, documentsTypes, dossierSubTypesComboCampaign
    ] = yield all([
      call(getIvaTypeByDossierSubType, auth.token, dossierSubTypeId),
      call(getIemType, auth.token),
      call(getExtraSaleTypes, organizedUnitId, auth.token),
      call(getPresentTypes, organizedUnitId, auth.token),
      call(getSuppliedTypes, organizedUnitId, auth.token),
      call(getAssociatedCostsTypes, organizedUnitId, auth.token),
      call(getCampaignType, auth.token),
      call(getDocumentsTypes, auth.token, null, null),
      call(getDossierSubTypeByTypeAndUo, dossierTypesId.campaign, organizedUnitId, auth.token)
    ])

    yield put(fetchCombosSuccess({
      ivaTypeCombo,
      iemType,
      extraSaleTypeCombo,
      presentTypeCombo,
      suppliedTypeCombo,
      associatedCostTypeCombo,
      campaignsTypes,
      documentsTypes,
      dossierSubTypesComboCampaign
    }))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchfetchCombosEconomicModal () {
  yield takeEvery(managementActionTypes.FETCH_ECONOMIC_PLAN_COMBOS, fetchCombosEconomicModal)
}

export function * fetchEconomicFleetPermissions ({ dossierSubTypeId, organizedUnitId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    let result = yield call(getEconomicPlanPermissions, auth.token, { dossierSubtypeId: dossierSubTypeId, organizedUnitId: organizedUnitId })

    let per2 = result.tabsConfiguration.find(x => x.code === commonCodes.main).componentsConfiguration.find(x => x.code === economicCodes.campaign)
    result.campaign = per2
    yield put(getEconomicPlanPermissionsSuccess(result))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchfetchEconomicFleetPermissions () {
  yield takeEvery(managementActionTypes.GET_ECONOMIC_PLAN_FLEET_PERMISSIONS, fetchEconomicFleetPermissions)
}

export function * simulateEconomicPlanFleet () {
  try {
    yield put(showLoader())

    const auth = yield select(getAuth)
    let stateDossier = yield select(state => state.management.economicPlanModal)
    let dossierSubtypeId = JSON.parse(JSON.stringify(stateDossier.dossierSubtypeId))
    let organizedUnitId = JSON.parse(JSON.stringify(stateDossier.uoId))
    let stateCampaigns = JSON.parse(JSON.stringify(stateDossier.campaigns))
    let dossierForm = yield select(state => state.form.economicPlanModal.values.dossierEconomicComponent)
    let body = { economicPlan: dossierForm, listCampaignDossierIncome: stateCampaigns }
    let result = yield call(postSimulateEconomicPlanFleet, auth.token, body, dossierSubtypeId, organizedUnitId)
    result.commissionCancellationCodeDescription = stateDossier?.dossierEconomicComponent?.commissionCancellationCodeDescription
    result.commissionCancellationCodeId = stateDossier?.dossierEconomicComponent?.commissionCancellationCodeId
    setUUIDEconomicPlan(result)
    yield put(simulateEconomicPlanFleetSuccess(result, true))
    yield put(change('economicPlanModal', 'dossierEconomicComponent', result))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSimulateEconomicPlanFleet () {
  yield takeEvery(managementActionTypes.SIMULATE_ECONOMIC_PLAN_FLEET, simulateEconomicPlanFleet)
}

export function * addCampaign ({ data }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    let stateDossier = yield select(state => state.management.economicPlanModal)
    let dossierSubtypeId = JSON.parse(JSON.stringify(stateDossier.dossierSubtypeId))
    let organizedUnitId = JSON.parse(JSON.stringify(stateDossier.uoId))
    let stateCampaigns = JSON.parse(JSON.stringify(stateDossier.campaigns))
    let dossierForm = yield select(state => state.form.economicPlanModal.values.dossierEconomicComponent)
    data.id = stateCampaigns.length + 1
    stateCampaigns.push(data)

    let body = { economicPlan: dossierForm ?? stateDossier.dossierEconomicComponent, listCampaignDossierIncome: stateCampaigns }
    let result = yield call(postSimulateEconomicPlanFleet, auth.token, body, dossierSubtypeId, organizedUnitId)
    result.commissionCancellationCodeDescription = stateDossier?.dossierEconomicComponent?.commissionCancellationCodeDescription
    result.commissionCancellationCodeId = stateDossier?.dossierEconomicComponent?.commissionCancellationCodeId
    setUUIDEconomicPlan(result)
    let dataResult = { dossierEconomicComponent: result, campaigns: stateCampaigns }
    yield put(addCampaignSuccess(dataResult))

    yield put(change('economicPlanModal', 'dossierEconomicComponent', result))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchAddCampaign () {
  yield takeEvery(managementActionTypes.ADD_CAMPAIGN_ECONOMIC_PLAN_FLEET, addCampaign)
}

export function * deleteCampaign ({ dossierExtra, dossierExtraType }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    let stateDossier = yield select(state => state.management.economicPlanModal)
    let dossierSubtypeId = JSON.parse(JSON.stringify(stateDossier.dossierSubtypeId))
    let organizedUnitId = JSON.parse(JSON.stringify(stateDossier.uoId))
    let stateCampaigns = JSON.parse(JSON.stringify(stateDossier.campaigns))
    let dossierForm = yield select(state => state.form.economicPlanModal.values.dossierEconomicComponent)

    let campaignsFiltered = stateCampaigns.filter((value) => { return value.id !== dossierExtra.id })
    campaignsFiltered.forEach((x, index) => { x.id = index + 1 })

    let body = { economicPlan: dossierForm, listCampaignDossierIncome: campaignsFiltered }
    let result = yield call(postSimulateEconomicPlanFleet, auth.token, body, dossierSubtypeId, organizedUnitId)
    result.commissionCancellationCodeDescription = stateDossier?.dossierEconomicComponent?.commissionCancellationCodeDescription
    result.commissionCancellationCodeId = stateDossier?.dossierEconomicComponent?.commissionCancellationCodeId
    setUUIDEconomicPlan(result)
    let dataResult = { dossierEconomicComponent: result, campaigns: campaignsFiltered }
    yield put(addCampaignSuccess(dataResult))

    yield put(change('economicPlanModal', 'dossierEconomicComponent', result))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDeleteEconomicCampaign () {
  yield takeEvery(managementActionTypes.DELETE_ECONOMIC_CAMPAIGN, deleteCampaign)
}

export function * openEconomicModal () {
  try {
    let stateDossier = yield select(state => state.management.economicPlanModal)
    let economicState = JSON.parse(JSON.stringify(stateDossier.dossierEconomicComponent))

    yield put(simulateEconomicPlanFleetSuccess(economicState))
    yield put(change('economicPlanModal', 'dossierEconomicComponent', economicState))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchOpenEconomicModal () {
  yield takeEvery(managementActionTypes.OPEN_ECONOMIC_PLAN_MODAL, openEconomicModal)
}

export function * saveEconomicPlanFleet ({ dossierId, resolve, reject, first }) {
  try {
    let confirmed = false

    if (first) {
      confirmed = yield call(yesNoModal, 'saveEconomicFleetAlert')
    } else {
      confirmed = true
    }

    if (confirmed) {
      yield put(showLoader())
      const auth = yield select(getAuth)
      let stateDossier = yield select(state => state.management.economicPlanModal)
      let dossierSubtypeId = JSON.parse(JSON.stringify(stateDossier.dossierSubtypeId))
      let organizedUnitId = JSON.parse(JSON.stringify(stateDossier.uoId))
      let stateCampaigns = JSON.parse(JSON.stringify(stateDossier.campaigns))
      let dossierForm = yield select(state => state.form.economicPlanModal.values.dossierEconomicComponent)
      let body = { economicPlan: dossierForm, listCampaignDossierIncome: stateCampaigns }
      let result = yield call(postSaveEconomicPlanFleet, auth.token, body, dossierSubtypeId, organizedUnitId, dossierId, Date.now())
      if (resolve) yield call(resolve, result)
    }
  } catch (error) {
    if (reject) {
      yield call(reject, error)
    } else {
      yield call(handleError, { error })
    }
  } finally {
    yield put(hideLoader())
  }
}

export function * watchsaveEconomicPlanFleet () {
  yield takeEvery(managementActionTypes.SAVE_ECONOMIC_PLAN_FLEET, saveEconomicPlanFleet)
}

export function * fetchDocumentTypeUsesInfo ({ filters, omit }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const documentTypeUse = yield call(postDocumentTypeUseTable, auth.token, filters, omit)
    if (filters.page > documentTypeUse.pages) {
      filters.page = 1
    }
    yield put(fetchDocumentTypeUsesInfoSuccess(documentTypeUse, filters))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchDocumentTypeUsesInfo () {
  yield takeEvery(managementActionTypes.FETCH_DOCUMENT_TYPE_USES_INFO, fetchDocumentTypeUsesInfo)
}

export function * saveAuditAdmin ({ auditAdmin, resolve, reject }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    let result = yield call(putAuditConfiguration, auth.token, auditAdmin)
    if (resolve) yield call(resolve, result)
    yield put(hideLoader())
  } catch (error) {
    if (reject) {
      yield call(reject, error)
    } else {
      yield call(handleError, { error })
    }
    yield put(hideLoader())
    yield put(messageModalActions.openErrorMessageModal('MASTERS.OU_MASTERS.FETCH_ERROR_MSG', error.json ? error.json.tag : ''))
  }
}

export function * watchSaveAuditAdmin () {
  yield takeEvery(managementActionTypes.SAVE_AUDIT_ADMIN, saveAuditAdmin)
}

export function * documentAudit ({ documentForm, resolve, reject }) {
  try {
    yield put(showLoader())
    let auditConfigurationId = yield select(state => state.management.formData.AuditConfigurationId)
    const auth = yield select(getAuth)
    let result = yield call(documentConfiguration, auth.token, auditConfigurationId, documentForm)
    if (resolve) yield call(resolve, result)
    yield put(hideLoader())
  } catch (error) {
    if (reject) {
      yield call(reject, error)
    } else {
      yield call(handleError, { error })
    }
    yield put(hideLoader())
    yield put(messageModalActions.openErrorMessageModal('MASTERS.OU_MASTERS.FETCH_ERROR_MSG', error.json ? error.json.tag : ''))
  }
}

export function * watchDocumentAudit () {
  yield takeEvery(managementActionTypes.DOCUMENT_AUDIT, documentAudit)
}

export function * documentAuditFinish ({ documentForm, resolve, reject }) {
  try {
    yield put(showLoader())
    let auditConfigurationId = yield select(state => state.management.formData.AuditConfigurationId)
    const auth = yield select(getAuth)
    let result = yield call(documentConfigurationFinish, auth.token, auditConfigurationId, documentForm)
    if (result.item2 === 0) yield call(reject, false)
    if (resolve) yield call(resolve, result)
    yield put(hideLoader())
  } catch (error) {
    if (reject) {
      yield call(reject, error)
    } else {
      yield call(handleError, { error })
    }
    yield put(hideLoader())
    yield put(messageModalActions.openErrorMessageModal('MASTERS.OU_MASTERS.FETCH_ERROR_MSG', error.json ? error.json.tag : ''))
  }
}

export function * watchDocumentAuditFinish () {
  yield takeEvery(managementActionTypes.DOCUMENT_AUDIT_FINISH, documentAuditFinish)
}

export function * deleteAuditAdmin ({ auditAdminId, resolve, reject }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(deleteAuditConfiguration, auth.token, auditAdminId)
    if (resolve) yield call(resolve, true)
    yield put(hideLoader())
  } catch (error) {
    if (reject) {
      yield call(reject, error)
    } else {
      yield call(handleError, { error })
    }
    yield put(hideLoader())
  }
}

export function * watchDeleteAuditAdmin () {
  yield takeEvery(managementActionTypes.DELETE_AUDIT_ADMIN, deleteAuditAdmin)
}

export function * fetchAuditConfigurationDocument ({ auditAdmin, resolve, reject }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    let documents = yield call(getAuditConfigurationDocument, auth.token, auditAdmin)
    if (resolve) yield call(resolve, documents)
  } catch (error) {
    if (reject) {
      yield call(reject, error)
    } else {
      yield call(handleError, { error })
    }
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchAuditConfigurationDocument () {
  yield takeEvery(managementActionTypes.FETCH_AUDIT_CONFIGURATION_DOCUMENTS, fetchAuditConfigurationDocument)
}

export function * fetchShowFrontEconomicPlan ({ organizedUnitId, dossierSubTypeId, resolve, reject }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    let result = yield call(getShowFrontEconomicPlan, auth.token, { organizedUnitId, dossierSubTypeId })

    if (resolve) yield call(resolve, result)
  } catch (error) {
    if (reject) {
      yield call(reject, error)
    } else {
      yield call(handleError, { error })
    }
  } finally {
    yield put(hideLoader())
  }
}

export function * watchfetchShowFrontEconomicPlan () {
  yield takeEvery(managementActionTypes.FETCH_SHOW_FRONT_ECONOMIC_PLAN, fetchShowFrontEconomicPlan)
}

export function * fecthValidateDocumentFleetPermission ({ documentTypeUseId, organizedUnitId, resolve, reject }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const result = yield call(getValidateDocumentPermission, auth.token, { documentTypeUseId: documentTypeUseId, organizedUnitId: organizedUnitId })
    if (resolve) yield call(resolve, result)
  } catch (error) {
    if (reject) {
      yield call(reject)
    } else {
      yield call(handleError, { error })
    }
  } finally {
    yield put(hideLoader())
  }
}

export function * watchfecthValidateDocumentFleetPermission () {
  yield takeEvery(managementActionTypes.FETCH_VALIDATE_DOCUMENT_FLEET, fecthValidateDocumentFleetPermission)
}

export function * createDifferentDocumentFleet ({ file, dossiers, documentTypeUseId, organizedUnitId, validate, resolve, reject }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const result = yield call(postUploadDifferentDocumentFleet, auth.token, file, dossiers, documentTypeUseId, organizedUnitId, validate)
    if (resolve) yield call(resolve, result)
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchCreateDifferentDocumentFleet () {
  yield takeEvery(managementActionTypes.UPLOAD_ZIP_DOCUMENT_FLEET, createDifferentDocumentFleet)
}

export function * getDocumentTypeFleets () {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const documentsTypes = yield call(getDocumentsTypesFleets, auth.token)
    yield put(fetchCombosSuccess({ documentsTypes }))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchgetDocumentTypeFleets () {
  yield takeEvery(managementActionTypes.GET_DOCUMENT_TYPE_FLEETS, getDocumentTypeFleets)
}

export function * findFleetsManagement ({ dynamicFilters, filters }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    let data = { ...dynamicFilters, ...filters }
    let result = yield call(postFindFleetsManagement, auth.token, data)
    if (filters.page > result.pages) {
      filters.page = 1
      data = { ...dynamicFilters, ...filters }
      result = yield call(postFindFleetsManagement, auth.token, data)
    }
    yield put(fetchFleetsManagementSuccess({ data: result, dynamicFilters: dynamicFilters, filters: filters }))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFindFleetsManagement () {
  yield takeEvery(managementActionTypes.FIND_FLEETS_MANAGEMENT, findFleetsManagement)
}

export function * exportFleetsManagement ({ dynamicFilters, filters }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    let data = { ...dynamicFilters, ...filters }
    yield call(postFindFleetsManagementExport, auth.token, data)
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchExportFleetsManagement () {
  yield takeEvery(managementActionTypes.FIND_FLEETS_MANAGEMENT_EXPORT, exportFleetsManagement)
}

export function * fetchFleetModel ({ fleetId, resolve, reject }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const fleet = yield call(getFleetModel, auth.token, { fleetId })

    if (fleet.entityComponent && fleet.entityComponent.entitySubTypeId) {
      const entitySubTypeCombo = yield call(getEntitySubType, auth.token, fleet.entityComponent.entityTypeId)
      const entityFilterSubTypeCombo = yield call(getEntityFilterSubType, auth.token, fleet.entityComponent.entityTypeId, fleet.entityComponent.entitySubTypeId, fleet.organizedUnitId)
      yield put(fetchCombosSuccess({ entitySubTypeCombo, entityFilterSubTypeCombo }))
    }

    yield put(fetchEntityFleetSucces({ entityComponent: fleet.entityComponent, entityChildComponent: fleet.entityChildComponent }))

    if (resolve) yield call(resolve, fleet)
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchfetchFleetModel () {
  yield takeEvery(managementActionTypes.GET_FLEET_MODEL, fetchFleetModel)
}

export function * fetchDossierDetailsFleet ({ fleetId, resolve }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const data = yield call(getDossierDetailsFleet, auth.token, { fleetId })

    yield put(getDossierDetailsFleetSuccess(data))
    if (resolve) yield call(resolve, data)
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}
export function * watchfetchDossierDetailsFleet () {
  yield takeEvery(managementActionTypes.FETCH_DOSSIER_DETAIL_FLEET, fetchDossierDetailsFleet)
}

export function * fetchDeleteDossiersFleet ({ dossierIds, fleetId, resolve }) {
  try {
    yield put(commonActions.showLoader())
    const auth = yield select(getAuth)
    yield call(deleteDossiersFleet, auth.token, { fleetId }, dossierIds)

    const data = yield call(getDossierDetailsFleet, auth.token, { fleetId })
    yield put(getDossierDetailsFleetSuccess(data))

    if (resolve) yield call(resolve)
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchfetchDeleteDossiersFleet () {
  yield takeLatest(managementActionTypes.DELETE_DOSSIERS_FROM_FLEET, fetchDeleteDossiersFleet)
}

export function * openOperateFleetModal ({ fleet }) {
  if (fleet !== null && fleet !== undefined) {
    fleet.organizedUnit = fleet.organizedUnitId
    fleet.dossierSubTypeId = fleet.dossierSubType
    fleet.dossierCount = fleet.numDossiers
    fleet.entityChildComponent = fleet.finalEntity
    yield put(initialize('operateFleetModal', fleet))
  } else {
    yield put(initialize('operateFleetModal', null))
  }
  yield put(openOperateFleetModalSuccess(fleet))
}

export function * watchopenOperateFleetModal () {
  yield takeLatest(managementActionTypes.OPEN_OPERATE_FLEET_MODAL, openOperateFleetModal)
}

export function * initializeOperationActionsCombo ({ actionId, dataId }) {
  yield put(initializeOperationActionsComboSuccess(actionId, dataId))
  yield put(change('operateFleetModal', 'actionId', actionId))
  yield put(change('operateFleetModal', 'dataId', dataId))
  yield put(change('operateFleetModal', 'paperWorkDataId', actionId))
  yield put(change('operateFleetModal', 'paperWorkActionId', dataId))
}

export function * watchInitializeOperationActionsCombo () {
  yield takeLatest(managementActionTypes.INITIALIZE_OPERATION_ACTION_COMBOS, initializeOperationActionsCombo)
}

export function * fetchOperateDataFleetModalsPermissions ({ dossierSubTypeId, organizedUnitId, stageId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    let data = yield call(getEconomicPlanPermissions, auth.token, { dossierSubtypeId: dossierSubTypeId, organizedUnitId: organizedUnitId, stageId: stageId })
    yield put(fetchOperateDataFleetModalsPermissionsSuccess(data.tabsConfiguration.find(x => x.code === commonCodes.main)))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchfetchOperateDataFleetModalsPermissions () {
  yield takeLatest(managementActionTypes.FETCH_OPERATE_DATA_FLEET_MODALS_PERMISSIONS, fetchOperateDataFleetModalsPermissions)
}

export function * fetchSalesManCombos ({ organizedUnitId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const salesmanCombo = yield call(getSalesman, organizedUnitId, auth.token)
    const salesmanComboLogic = yield call(getSalesmanLogic, organizedUnitId, auth.token)
    yield put(fetchCombosSuccess({
      salesmanCombo,
      salesmanComboLogic
    }))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchfetchSalesManCombos () {
  yield takeLatest(auxModalsFleetsAction.FETCH_SALESMAN_COMBOS, fetchSalesManCombos)
}

export function * fetchSalesTypeCombo ({ organizedUnitId, dossierSubTypeId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const saleTypeCombo = yield call(getSaleTypesByUo, organizedUnitId, dossierSubTypeId, auth.token)
    yield put(fetchCombosSuccess({ saleTypeCombo }))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchfetchSalesTypeCombo () {
  yield takeLatest(auxModalsFleetsAction.FETCH_SALES_TYPE_COMBO, fetchSalesTypeCombo)
}

export function * fetchPaperWorkFleetPermissions ({ organizedUnitId, stageId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    let result = yield call(getPaperWorkFleetPermission, auth.token, { organizedUnitId, stageId })

    let per2 = result.tabsConfiguration.find(x => x.code === commonCodes.main).componentsConfiguration.find(x => x.code === paperworkCodes.bpaperwork)

    yield put(fetchOperatePaperworkFleetPermissionsSuccess(per2))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchfetchPaperWorkFleetPermissions () {
  yield takeEvery(managementActionTypes.FETCH_OPERATE_PAPERWORK_FLEET_PERMISSIONS, fetchPaperWorkFleetPermissions)
}

export function * fetchEconomicShow ({ organizedUnitId, dossierSubtypeId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const economicShow = yield call(getShowEconomic, auth.token, { organizedUnitId, dossierSubtypeId })
    yield put(getShowEconomicSuccess(economicShow))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchfetchEconomicShow () {
  yield takeEvery(auxModalsFleetsAction.GET_FLEET_SHOW_ECONOMIC, fetchEconomicShow)
}

export function * fetchDocumentLisFleet ({ fleetId, dossierList }) {
  try {
    yield put(commonActions.showLoader())
    const auth = yield select(getAuth)

    const data = yield call(getDocumentsFleetList, auth.token, { fleetId: fleetId }, dossierList)
    yield put(getDocumentFleetListSuccess(data))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchfetchDocumentLisFleet () {
  yield takeLatest(managementActionTypes.FETCH_DOCUMENT_FLEET_LIST, fetchDocumentLisFleet)
}

export function * fetchGetCanCreateOperateServices ({ organizedUnitId, dossierSubTypeId, resolve }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    let result = yield call(getCanCreateOperateServices, auth.token, { organizedUnitId, dossierSubTypeId })
    if (resolve) yield call(resolve, result)
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchfetchGetCanCreateOperateServices () {
  yield takeEvery(managementActionTypes.FETCH_GET_CAN_CREATE_OPERATE_SERVICES, fetchGetCanCreateOperateServices)
}

export function * openEconomicFleetModal ({ fleet, mode, dossiers }) {
  yield put(openEconomicFleetModalSuccess(fleet, mode, dossiers))
  if (fleet !== null && fleet !== undefined) {
    fleet.organizedUnit = fleet.organizedUnitId
    fleet.dossierSubTypeId = fleet.dossierSubType
    fleet.dossierCount = fleet.numDossiers
    fleet.entityChildComponent = fleet.finalEntity
    yield put(initialize('economicPlanModal', fleet))
  } else {
    yield put(initialize('economicPlanModal', null))
  }
}

export function * watchOpenEconomicFleetModal () {
  yield takeLatest(managementActionTypes.OPEN_ECONOMIC_FLEET_MODAL, openEconomicFleetModal)
}

function setUUIDEconomicPlan (economicPlan) {
  if (economicPlan && economicPlan.associatedCosts) {
    economicPlan.associatedCosts.forEach((a) => { a.indexFront = uuidv1() })
  }

  if (economicPlan && economicPlan.discounts) {
    economicPlan.discounts.forEach((a) => { a.indexFront = uuidv1() })
  }

  if (economicPlan && economicPlan.extraSales) {
    economicPlan.extraSales.forEach((a) => { a.indexFront = uuidv1() })
  }

  if (economicPlan && economicPlan.optionals) {
    economicPlan.optionals.forEach((a) => { a.indexFront = uuidv1() })
  }

  if (economicPlan && economicPlan.presents) {
    economicPlan.presents.forEach((a) => { a.indexFront = uuidv1() })
  }
  if (economicPlan && economicPlan.supplieds) {
    economicPlan.supplieds.forEach((a) => { a.indexFront = uuidv1() })
  }
}

export function * fetchSelfSupplyInfo ({ filters }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const result = yield call(fetchDossierSelfSupplyManagementInfo, auth.token, filters)
    if (filters.page > result.pages) {
      filters.page = 1
    }
    yield put(fetchSelfSupplyInfoSuccess(result, filters))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchSelfSupplyInfo () {
  yield takeEvery(managementActionTypes.FETCH_SELF_SUPPLY_INFO, fetchSelfSupplyInfo)
}
export function * fetchAuditAdminInfo ({ filters }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const result = yield call(fetchAuditAdminInfoService, auth.token, filters)
    if (filters.page > result.pages) {
      filters.page = 1
    }
    yield put(fetchAuditAdminInfoSuccess(result, filters))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchAuditAdminInfo () {
  yield takeEvery(managementActionTypes.FETCH_AUDIT_ADMIN_INFO, fetchAuditAdminInfo)
}

export function * fetchAuditsInfo ({ filters }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const result = yield call(fetchAuditAdminInfoService, auth.token, filters)
    if (filters.page > result.pages) {
      filters.page = 1
    }
    yield put(fetchAuditsInfoSuccess(result, filters))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchAuditsInfo () {
  yield takeEvery(managementActionTypes.FETCH_AUDITS_INFO, fetchAuditsInfo)
}

export function * fetchSearchDocumentsInfo ({ filters }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const result = yield call(fetchSearchDocumentsInfoService, auth.token, filters)
    if (filters.page > result.pages) {
      filters.page = 1
    }
    yield put(fetchSearchDocumentsInfoSuccess(result, filters))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchSearchDocumentsInfo () {
  yield takeEvery(managementActionTypes.FETCH_SEARCH_DOCUMENTS_INFO, fetchSearchDocumentsInfo)
}

export function * fetchSelfSupplyInfoDownload ({ filters, columns }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    filters.page = null
    filters.pageSize = null
    yield call(fetchDossierSelfSupplyManagementInfoDownload, auth.token, filters, columns)
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchSelfSupplyInfoDownload () {
  yield takeEvery(managementActionTypes.FETCH_SELF_SUPPLY_INFO_DOWNLOAD, fetchSelfSupplyInfoDownload)
}

export function * passSelfSupplyVoAction ({ dossierId, organizedUnitId }) {
  try {
    const continueProcess = yield call(yesNoModal, 'initSaleToSelfSupply')
    if (continueProcess) {
      yield put(showLoader())
      const auth = yield select(getAuth)
      let date
      const today = new Date()
      date = today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear()
      date += ' ' + today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds() + ' ' + today.getMilliseconds()
      const dossierResult = yield call(putCreateSelfSupplyVO, auth.token, dossierId, organizedUnitId, date)
      yield put(hideLoader())
      yield call(yesNoModal, 'selfSupplySucces', null, null, null, dossierResult)
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && customHandlerError.json.tag && backEndTagTranslation[customHandlerError.json.tag]) {
        yield put(hideLoader())
        if (customHandlerError.json.tag === errorSelfSupplyErrorCode.purchaseAlreadyExists) {
          yield call(yesNoModal, customHandlerError.json.tag, null, null, backEndTagTranslation[customHandlerError.json.tag], customHandlerError.json.message)
        } else {
          yield call(yesNoModal, backEndTagTranslation[customHandlerError.json.tag])
        }
      } else {
        yield put(hideLoader())
        yield call(yesNoModal, backEndTagTranslation.defaultErrorSelfSupply)
      }
    }
    yield call(handleError, { error, customHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchPassSelfSupplyVoAction () {
  yield takeEvery(managementActionTypes.PASS_SELF_SUPPLY_VO_ACTION, passSelfSupplyVoAction)
}

export function * openAuditAdminModal ({ auditAdmin, formData }) {
  try {
    yield put(showLoader())
    yield put(openAuditAdminModalSuccess(auditAdmin, formData))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchOpenAuditAdminModal () {
  yield takeEvery(managementActionTypes.OPEN_AUDITADMIN_MODAL, openAuditAdminModal)
}

export function * openZipModal ({ number }) {
  try {
    yield put(showLoader())
    yield put(openZipModalSuccess(number))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchOpenZipModal () {
  yield takeEvery(managementActionTypes.OPEN_ZIP_MODAL, openZipModal)
}

export function * fetchCodeCommissionFleet ({ codeCommission }) {
  try {
    yield put(changeCodeCommissionFleetSuccess(codeCommission))
    yield put(messageModalActions.openSuccessMessageModal('MESSAGES.APPLIED_CODE_COMMISSION'))
  } catch (error) {
    const sessionHandler = function * () {
    }
    yield call(handleError, { error, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchCodeCommissionFleet () {
  yield takeEvery(managementActionTypes.CHANGE_CODE_COMMISSION_FLEET, fetchCodeCommissionFleet)
}

export function * fetchPaperworksDetailsFleet ({ fleetId, resolve }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const data = yield call(getPaperworksDetailsListFleet, auth.token, { fleetId })

    yield put(getPaperworksDetailsFleetSuccess(data))
    if (resolve) yield call(resolve, data)
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}
export function * watchFetchPaperworksDetailsFleet () {
  yield takeEvery(managementActionTypes.FETCH_PAPERWORK_FLEET_LIST, fetchPaperworksDetailsFleet)
}

export function * fetchDossierChildSubtypeDetailCount ({ fleetId, dossierSubtypeId, resolve }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const count = yield call(getDossierChildSubtypeDetailCount, auth.token, { fleetId, dossierSubtypeId })

    const data = {}
    data[dossierSubtypeId] = count

    yield put(getDossierChildSubtypeDetailCountSuccess(data))
    if (resolve) yield call(resolve, data)
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}
export function * watchFetchDossierChildSubtypeDetailCount () {
  yield takeEvery(managementActionTypes.FETCH_CHILDS_SUBTYPE_FLEET_COUNT_LIST, fetchDossierChildSubtypeDetailCount)
}

export function * fetchChargesDetailsFleet ({ fleetId, resolve }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const data = yield call(getChargesDetailsListFleet, auth.token, { fleetId })
    const fleetEconomic = yield call(getChargesFleetEconomic, auth.token, { fleetId })

    yield put(getChargesDetailsFleetSuccess(data))
    yield put(getFleetEconomicSuccess(fleetEconomic))
    if (resolve) yield call(resolve, data)
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}
export function * watchFetchChargesDetailsFleet () {
  yield takeEvery(managementActionTypes.FETCH_CHARGE_FLEET_LIST, fetchChargesDetailsFleet)
}
