import landingAccessActions from '../../constants/actions/dossier/common/landingAccess'

export function getDocumentLandingRequest (dossierId, resolve, reject) {
  return {
    type: landingAccessActions.GET_DOCUMENT_LANDING_REQUEST,
    dossierId,
    resolve,
    reject
  }
}

export function getDocumentLandingRequestSuccess (landingAccessInfo) {
  return {
    type: landingAccessActions.GET_DOCUMENT_LANDING_REQUEST_SUCCESS,
    landingAccessInfo
  }
}

export function getAllDocumentLandingRequest (dossierId) {
  return {
    type: landingAccessActions.GET_ALL_AVAILABLE_DOCUMENT_LANDING_REQUEST,
    dossierId
  }
}

export function getAllDocumentLandingRequestSuccess (docList) {
  return {
    type: landingAccessActions.GET_ALL_AVAILABLE_DOCUMENT_LANDING_REQUEST_SUCCESS,
    docList
  }
}

export function sendRequestToClient (dossierId, dossierTypeId, docList) {
  return {
    type: landingAccessActions.SEND_LANDING_REQUEST_TO_CLIENT,
    docList,
    dossierId,
    dossierTypeId
  }
}

export function sendLandingCancelRequest (dossierId, dossierTypeId, resolve, reject) {
  return {
    type: landingAccessActions.SEND_LANDING_CANCEL_REQUEST,
    dossierId,
    dossierTypeId,
    resolve,
    reject
  }
}
