import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import MessageModal from '../../../components/layout/modals/MessageModal'
import MessageModalActions from '../../../actions/modals/messageModal'

export function mapStateToProps (state) {
  return {
    ...state.messageModal
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({ ...MessageModalActions }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(MessageModal))
