import React, { PureComponent } from 'react'
import Select, { components } from 'react-select'
// import 'react-select/dist/react-select.css'
import { Col, ControlLabel, Tooltip, OverlayTrigger, Glyphicon } from 'react-bootstrap'

const MultiValueContainer = ({ children, data, ...props }) => {
  const index = props.selectProps.value ? props.selectProps.value.findIndex(selected => selected === data) : null
  if (index) return null
  let maxToShow = 1
  var length = props.selectProps.value ? props.selectProps.value.length : 0
  let displayChips = React.Children.toArray(children).slice(0, maxToShow)
  let shouldBadgeShow = length > maxToShow
  let displayLength = length - maxToShow
  let valueInDescription = ['operationTypeDMSId']
  let toolTipText = props.selectProps.value ? props.selectProps.value.map((item, index) => {
    if (valueInDescription.includes(props.selectProps.id)) {
      return <React.Fragment key={index}>{item.description}<br /></React.Fragment>
    }
    return <React.Fragment key={index}>{item.value}<br /></React.Fragment>
  }) : []
  const tooltip = <Tooltip id="tooltip-operation">{toolTipText}</Tooltip>
  return (
    <components.MultiValueContainer {...props}>
      {!props.selectProps.inputValue && displayChips}
      {shouldBadgeShow &&
        <>
          <OverlayTrigger overlay={tooltip} placement={'right'}>
            <a>{`+${displayLength}`}</a>
          </OverlayTrigger>
        </>
      }
    </components.MultiValueContainer>
  )
}

class InputSelect extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      defaultValue: null
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
    this.menuOpen = this.menuOpen.bind(this)
    this.menuClose = this.menuClose.bind(this)
  }

  componentDidMount () {
    if (this.props.input.value !== null && this.props.input.value !== undefined && this.props.options && this.props.options.length > 0) {
      Array.isArray(this.props.input.value)
        ? this.setState({ defaultValue: this.props.options.filter(option => this.props.input.value.find(item => item === option[this.props.valueKey])) })
        : this.setState({ defaultValue: this.props.options.filter(option => option[this.props.valueKey] === this.props.input.value) })
    }
  }

  componentDidUpdate () {
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (prevProps.selectedMultipleSign !== this.props.selectedMultipleSign) {
      this.setState({ defaultValue: this.props.options.length > 0 && this.props.options.filter(option => option[this.props.valueKey] === this.props.selectedMultipleSign) })
    } else if (prevProps.defaultValueSign !== this.props.defaultValueSign) {
      this.setState({ defaultValue: this.props.defaultValueSign })
    } else if (!this.props.input.value && this.props.defaultValueEntityType && this.props.input.value !== this.props.defaultValueEntityType) {
      if (this.props.options.length > 0) {
        let defaultValue = this.props.options.filter(option => option[this.props.valueKey] === this.props.defaultValueEntityType)
        if (defaultValue.length > 0) {
          this.setState({ defaultValue: defaultValue[0][this.props.valueKey] })
        }
      }
    } else if (((prevProps.input.value !== this.props.input.value) || (JSON.stringify(prevProps.options) !== JSON.stringify(this.props.options))) && this.props.options) {
      Array.isArray(this.props.input.value)
        ? this.setState({ defaultValue: this.props.options.length > 0 && this.props.options.filter(option => this.props.input.value.find(item => item === option[this.props.valueKey])) })
        : this.setState({ defaultValue: this.props.options.length > 0 && this.props.options.filter(option => option[this.props.valueKey] === this.props.input.value) })
    }
    return null
  }

  handleChange (value, prev) {
    if (!this.props.multi) {
      const promise = new Promise((resolve, reject) => {
        this.props.input.onChange(value ? value[this.props.valueKey] : value)
        resolve()
      })
      promise.then(() => {
        if (this.props.onInputChange) {
          const prevValue = this.props.input.value
          this.props.onInputChange(value ? value[this.props.valueKey] : value, prevValue)
        }
      })
    } else if (this.props.multi) {
      let changedValue
      if (this.props.returnObject) {
        changedValue = value.length ? value : []
      } else {
        changedValue = (value !== null && value !== undefined && value.length) ? value.map((value) => value[this.props.valueKey]).sort() : []
      }
      this.props.input.onChange(changedValue)
      if (this.props.onInputChange) {
        this.props.onInputChange(changedValue)
      }
    }
  }

  handleBlur (e) {
    this.props.input.onBlur(this.props.input.value)
    if (this.props.onInputBlur) {
      this.props.onInputBlur(e, this.props.input.value)
    }
  }

  menuOpen (e) {
    if (this.props && this.props.onMenuOpen) {
      this.props.onMenuOpen(true)
    }
  }

  menuClose (e) {
    if (this.props && this.props.onMenuClose) {
      this.props.onMenuClose(false)
    }
  }

  InfoValueContainer (data) {
    let toolTipText = data ? data.map((item, index) => {
      return <React.Fragment key={index}>{item.value}<br /></React.Fragment>
    }) : []
    const editTooltip = (<Tooltip id="editTooltip">{toolTipText}</Tooltip>)
    return editTooltip
  }

  render () {
    const {
      id, colSm = 0, colWidht = '25', labelKey, valueKey, options, meta, /* input, */ customClass = '', menuContainerStyle = {},
      placeholder, disabled = false, controlLabel, multi = false, paddingLeft, isClearable = true, menuPlacement = 'bottom',
      tooltip, menuIsOpen, onlyVisible
    } = this.props

    const errorMessage = (meta.touched && meta.error && typeof meta.error !== 'boolean') ? meta.error : null
    let optionsValues = options
    if (options && onlyVisible) {
      optionsValues = options.filter(x => x.visible === true)
    }
    return (
      <Col sm={colSm} className={'colWhidht-' + colWidht + ' ' + `${meta.touched && meta.error ? 'has-error' : ''} ${customClass}`} style={paddingLeft}>
        <div className="form-group">
          {controlLabel && <ControlLabel>{controlLabel}</ControlLabel>}
          {disabled && multi && this.state.defaultValue && this.state.defaultValue.length > 1 && (
            <OverlayTrigger placement='right' overlay={this.InfoValueContainer(this.state.defaultValue)}>
              <Glyphicon className='Select-info' glyph="info-sign"/>
            </OverlayTrigger>)}
          {tooltip && (
            <OverlayTrigger placement='right' overlay={tooltip}>
              <Glyphicon className='Select-info' glyph="info-sign"/>
            </OverlayTrigger>)
          }
          <Select
            className="Select-control"
            classNamePrefix={multi && 'Multiple-Select'}
            id={id}
            menuContainerStyle={menuContainerStyle}
            isDisabled={disabled}
            placeholder={placeholder}
            options={optionsValues}
            components={multi && this.state.defaultValue && this.state.defaultValue.length > 0 ? { MultiValueContainer } : null}
            getOptionLabel={(value) => value[labelKey]}
            getOptionValue={(value) => value[valueKey]}
            value={this.state.defaultValue}
            onChange={this.handleChange}
            hideSelectedOptions={!multi && this.state.defaultValue && this.state.defaultValue.length < 2}
            onBlur={this.handleBlur}
            isMulti={multi}
            isClearable={isClearable}
            menuPlacement={menuPlacement}
            menuIsOpen= {menuIsOpen}
            onMenuOpen= {this.menuOpen}
            onMenuClose= {this.menuClose}
          />
          {errorMessage && <span className='help-block text-center'>{errorMessage}</span>}
        </div>
      </Col>
    )
  }
}

export default InputSelect
