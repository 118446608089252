
import React, { PureComponent } from 'react'
import { Field } from 'redux-form'
import { Panel, Tab, Tabs } from 'react-bootstrap'
import InputSelect from '../../../../_v2/components/commons/form/InputSelect'
import InputDatePicker from '../../../../_v2/components/commons/form/InputDatePicker'
import InputMoney from '../../../../_v2/components/commons/form/InputMoney'
import InputPercentage from '../../../../_v2/components/commons/form/InputPercentage'
import InputText from '../../../../_v2/components/commons/form/InputText'
import InputCheckBox from '../../../../_v2/components/commons/form/InputCheckBox'
import { validateCampaignAmount, validateCampaignPercentage, validateCampaignTotalAmount, validateCampaignTotalPercentage, validateDate } from '../../../../util/validationFunctions'
import { permissions } from '../../../../constants/backendIds'
import { getFieldsPermisions } from '../../../../util/permisionFunctions'
import CampaignsPopover from '../../../../components/dossiers/common/CampaignsPopover'
import { campaignField, campaignType } from '../../../../constants/dossier/common/campaignType'
import { dossierSubTypeId as dossierSubTypeIdConstants } from '../../../../constants/dossier/common/dossierSubType'
import { compareObjects, getDateNowMaxDatePicker } from '../../../../util/utils'
import CustomFields from '../../../../components/commons/customFields/CustomFields'
import ComponentTitle from '../../../../_v2/containers/dossiers/commons/ComponentTitlePage'

class CampaignComponent extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      campaignIsActive: null,
      open: false,
      documentIds: true,
      checklistEditable: true,
      cost: 0,
      costConcession: 0,
      additionalAmount: 0,
      fieldsConfiguration: {
        campaignTypeId: {
          permission: null
        },
        approvalDate: {
          permission: null
        },
        amountPercentage: {
          permission: null
        },
        costPercentage: {
          permission: null
        },
        costConcession: {
          permission: null
        },
        costConcessionPercentage: {
          permission: null
        },
        popOverCampaings: {
          permission: null
        },
        amount: {
          permission: null
        },
        cost: {
          permission: null
        },
        documentIds: {
          permission: null
        },
        startDate: {
          permission: null
        },
        endDate: {
          permission: null
        },
        orderDate: {
          permission: null
        },
        amountCampaign: {
          permission: null
        },
        amountBrand: {
          permission: null
        },
        percentageCampaign: {
          permission: null
        },
        percentageBrand: {
          permission: null
        },
        amountRequested: {
          permission: null
        },
        approvedAmount: {
          permission: null
        },
        bonified: {
          permission: null
        },
        observations: {
          permission: null
        },
        campaignDetailsId: {
          permission: null
        },
        amountCost: {
          permission: null
        },
        responsable: {
          permission: null
        },
        billingNumber: {
          permission: null
        },
        billingDate: {
          permission: null
        },
        vehiclePrice: {
          permission: null
        },
        vehicleOptionalsPrice: {
          permission: null
        },
        expirationDate: {
          permission: null
        },
        percentageRequested: {
          permission: null
        },
        percentageApproved: {
          permission: null
        },
        checkInvoiceProform: {
          permission: null
        },
        checkBillingRequested: {
          permission: null
        },
        paymentMethodCampaign: {
          permission: null
        },
        name: {
          permission: null
        },
        additionalAmount: {
          permission: null
        },
        additionalPercentage: {
          permission: null
        }
      }
    }
  }

  handleCampaign (campaignTypeId) {
    this.props.actions.change('dossierCampaignComponent.documentIds', [])
    if (campaignTypeId) {
      this.props.actions.fetchChecklistCampaign(campaignTypeId, this.props.dossier.organizedUnitId)
      this.setState({ documentIds: false })
    } else {
      this.setState({ documentIds: true })
    }
  }

  initializePermisions (props) {
    const fieldKeys = Object.keys(this.state.fieldsConfiguration)
    const newState = getFieldsPermisions(props.componentConfiguration.fieldsConfiguration, fieldKeys, props.componentConfiguration.permission)
    this.setState({ fieldsConfiguration: newState.fieldsConfiguration })
  }

  componentDidMount () {
    if (this.props.componentConfiguration !== null) {
      this.initializePermisions(this.props)
    } else {
      this.setState({ permission: 0 })
    }
    this.setState({
      cost: this.props.dossier.dossierCampaignComponent.cost,
      costConcession: this.props.dossier.dossierCampaignComponent.costConcession,
      additionalAmount: this.props.dossier.dossierCampaignComponent.additionalAmount
    })
  }

  componentDidUpdate () {
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (!this.state.open) {
      this.setState({ open: true })
      if (this.state.fieldsConfiguration.amountPercentage.permission !== permissions.hidden ||
        this.state.fieldsConfiguration.costPercentage.permission !== permissions.hidden ||
        this.state.fieldsConfiguration.costConcessionPercentage.permission !== permissions.hidden ||
        this.state.fieldsConfiguration.additionalPercentage.permission !== permissions.hidden) {
        if (this.props.dossier.dossierCampaignComponent.setActiveTab === 2) {
          this.setState({ tabActiveKey: 2 })
          if (!this.props.isHistorical) {
            this.props.actions.savePercentages(2)
          }
        } else {
          this.setState({ tabActiveKey: 1 })
          if (!this.props.isHistorical) {
            this.props.actions.savePercentages(1)
          }
        }
      }
    }

    if (this.props.dossier.dossierCampaignComponent !== prevProps.dossier.dossierCampaignComponent) {
      this.setState({ documentIds: !this.props.dossier.dossierCampaignComponent.campaignTypeId })
    }

    if (this.props.dossier && this.props.dossier.dossierId && !compareObjects(prevProps.componentConfiguration || {}, this.props.componentConfiguration, true)) {
      this.initializePermisions(this.props)
    }

    return prevProps
  }

  setCost (value) {
    let cost = this.setValue('cost', value)
    this.setState({ cost: cost })
    if (this.state.fieldsConfiguration.amount.permission !== permissions.hidden) {
      this.calculateAmount(cost, this.state.costConcession, this.state.additionalAmount)
    }
  }

  setValue (field, value) {
    let total = 0
    if (this.props.dossier.totalAmountWithOptionals) {
      total = (((this.props.dossier.totalAmountWithOptionals) * value) / 100).toFixed(2)
    }
    this.props.change('dossierCampaignComponent.' + field, total)
    return total
  }

  setPercentage (field, value) {
    let total = 0
    if (this.props.dossier.totalAmountWithOptionals) {
      total = ((value * 100) / (this.props.dossier.totalAmountWithOptionals)).toFixed(2)
    }
    this.props.change('dossierCampaignComponent.' + field, total)
  }

  setCostConcession (value) {
    let costConcession = this.setValue(campaignField.COST_CONCESSION, value)
    this.setState({ costConcession: costConcession })
    if (this.state.fieldsConfiguration.amount.permission !== permissions.hidden) {
      this.calculateAmount(this.state.cost, costConcession, this.state.additionalAmount)
    }
  }

  setAdditionalAmount (value) {
    const additionalAmount = this.setValue(campaignField.ADDITIONAL_AMOUNT, value)
    this.setState({ additionalAmount: additionalAmount })
    if (this.state.fieldsConfiguration.amount.permission !== permissions.hidden) {
      this.calculateAmount(this.state.cost, this.state.costConcession, additionalAmount)
    }
  }

  handleBlurCost (event) {
    if (this.state.fieldsConfiguration.costPercentage.permission !== permissions.hidden) {
      this.setPercentage(campaignField.COST_PERCENTAGE, event)
    }
    if (this.state.fieldsConfiguration.costConcession.permission !== permissions.hidden) {
      this.setState({ cost: event })
      this.calculateAmount(event, this.state.costConcession, this.state.additionalAmount)
    }
  }

  handleBlurCostConcession (event) {
    if (this.state.fieldsConfiguration.costConcession.permission !== permissions.hidden) {
      this.setState({ costConcession: event })
      this.calculateAmount(this.state.cost, event, this.state.additionalAmount)
    }
    if (this.state.fieldsConfiguration.costConcessionPercentage.permission !== permissions.hidden) {
      this.setPercentage(campaignField.COST_CONCESSION_PERCENTAGE, event)
    }
  }

  handleBlurAmount (event) {
    if (this.state.fieldsConfiguration.amountPercentage.permission !== permissions.hidden) {
      this.setPercentage(campaignField.AMOUNT_PERCENTAGE, event)
    }
  }

  handleBlurAdditionalAmount (event) {
    if (this.state.fieldsConfiguration.additionalAmount.permission !== permissions.hidden) {
      this.setState({ additionalAmount: event })
      this.calculateAmount(this.state.cost, this.state.costConcession, event)
    }
    if (this.state.fieldsConfiguration.additionalPercentage.permission !== permissions.hidden) {
      this.setPercentage(campaignField.ADDITIONAL_PERCENTAGE, event)
    }
  }

  handleBlurAmountPercentage (event) {
    if (this.state.fieldsConfiguration.amountPercentage.permission !== permissions.hidden) {
      this.setValue(campaignField.AMOUNT, event)
    }
  }

  handleBlurCostPercentage (event) {
    if (this.state.fieldsConfiguration.costPercentage.permission !== permissions.hidden) {
      this.setCost(event)
    }
  }

  handleBlurCostConcessionPercentage (event) {
    if (this.state.fieldsConfiguration.costConcessionPercentage.permission !== permissions.hidden) {
      this.setCostConcession(event)
    }
  }

  handleBlurAdditionalPercentage (event) {
    if (this.state.fieldsConfiguration.additionalAmount.permission !== permissions.hidden) {
      this.setAdditionalAmount(event)
    }
  }

  calculateAmount (cost, costConcession, additionalAmount) {
    cost = cost || 0
    costConcession = costConcession || 0
    additionalAmount = additionalAmount || 0
    const amount = parseFloat(cost) + parseFloat(costConcession) + parseFloat(additionalAmount)
    this.props.actions.change('dossierCampaignComponent.amount', amount)
    this.handleBlurAmount(amount)
  }

  dateValidation (value, allValues, props) {
    const result = validateDate(value, props)
    return result === Object(result) ? undefined : result
  }

  changeDetails (detailsId, dossierId) {
    if (dossierId !== null) {
      this.props.actions.fetchCampaignDetails(dossierId, detailsId)
      const cam = this.props.combos.campaignDetails && this.props.combos.campaignDetails.find(val => val.id === detailsId)
      if (cam) {
        this.props.actions.fetchCampaignActive(cam.active)
      }
    }
  }

  changeAmount (value) {
    let total = 0
    if (this.props.dossier.totalAmountWithOptionalsPurchase) {
      total = (((this.props.dossier.totalAmountWithOptionalsPurchase) * value) / 100).toFixed(2)
    }
    this.props.actions.change('dossierCampaignComponent.dossierCampaignDetailComponent.amountRequested', total)
  }

  changeAmountApproved (value) {
    let total = 0
    if (this.props.dossier.totalAmountWithOptionalsPurchase) {
      total = (((this.props.dossier.totalAmountWithOptionalsPurchase) * value) / 100).toFixed(2)
    }
    this.props.actions.change('dossierCampaignComponent.dossierCampaignDetailComponent.approvedAmount', total)
  }

  cancelDrag (event) {
    event.preventDefault()
    return false
  }

  validatePercentage (value) {
    if (this.state.tabActiveKey === 2) {
      return validateCampaignPercentage(value, this.props.t)
    } else { return undefined }
  }

  validateAmount (value) {
    if (this.state.tabActiveKey === 1) {
      return validateCampaignAmount(value, this.props.dossier?.totalAmountWithOptionals, this.props.t)
    } else { return undefined }
  }

  validateTotalAmount (value) {
    if (this.state.tabActiveKey === 1) {
      return validateCampaignTotalAmount(value, this.props.dossier?.totalAmountWithOptionals, this.props.t)
    } else { return undefined }
  }

  validateTotalPercentage (value) {
    if (this.state.tabActiveKey === 2) {
      return validateCampaignTotalPercentage(value, this.props.t)
    } else { return undefined }
  }

  toggleComponent () {
    if (this.state.open) {
      this.props.actions.setActiveSection(false)
    }
    this.setState({
      open: !this.state.open
    })
  }

  render () {
    const {
      t, readOnlyFields, campaignOpen, customFields, isHistorical, combos: { paymentMethodCampaignCombo, campaignsTypes, documentsTypes, campaignDetails }, actions: { togglePanel }, dossier: {
        dossierId, dossierCampaignComponent: { checklistEditable, dossierCampaignDetailComponent: { amountFreeOption, percentageFreeOption }, filledBillingDateAndNumberInParent }
        , dossierSubTypeId
      }, showPercentages
    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.'
    return (
      <div className="dossier-Component">
        <div className="header-panel" onClick={togglePanel}>
          <ComponentTitle
            componentCode="CAMPAIGN_COMPONENT"
            customTitle={dossierSubTypeId === dossierSubTypeIdConstants.campanya_ayuda_especial ? t(`${tKey}SPECIAL_HELP_DOSSIER.CAMPAIGN_COMPONENT.TITLE`) : t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.TITLE`)}
            componentOpen={campaignOpen}
          />
        </div>
        <Panel expanded={campaignOpen} onToggle={() => {}}>
          <Panel.Collapse>
            <div className="fieldsContainer" style={{ zIndex: 4 }}>
              {this.state.fieldsConfiguration.name.permission !== permissions.hidden && (
                <Field
                  component={InputText}
                  colWidht={100} // 25, 33, 50, 75, 100 default=25
                  id="name"
                  name="dossierCampaignComponent.name"
                  controlLabel={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.NAME`)}
                  placeholder={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.NAME`)}
                  disabled={readOnlyFields || this.state.fieldsConfiguration.name.permission !== permissions.editable}
                />
              )}
              {this.state.fieldsConfiguration.responsable.permission !== permissions.hidden && (
                <Field
                  component={InputText}
                  colWidht={50} // 25, 33, 50, 75, 100 default=25
                  id="responsable"
                  name="dossierCampaignComponent.responsable"
                  controlLabel={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.RESPONSABLE`)}
                  placeholder={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.RESPONSABLE`)}
                  disabled={true}
                />
              )}
              {this.state.fieldsConfiguration.campaignTypeId.permission !== permissions.hidden && (
                <Field
                  component={InputSelect}
                  colWidht={50} // 25, 33, 50, 75, 100 default=25
                  id="campaignTypeId"
                  name="dossierCampaignComponent.campaignTypeId"
                  controlLabel={dossierSubTypeId === dossierSubTypeIdConstants.campanya_ayuda_especial ? t(`${tKey}SPECIAL_HELP_DOSSIER.CAMPAIGN_COMPONENT.CAMPAIGN_TYPE`) : t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.CAMPAIGN_TYPE`)}
                  placeholder={dossierSubTypeId === dossierSubTypeIdConstants.campanya_ayuda_especial ? t(`${tKey}SPECIAL_HELP_DOSSIER.CAMPAIGN_COMPONENT.CAMPAIGN_TYPE`) : t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.CAMPAIGN_TYPE`)}
                  options={campaignsTypes}
                  valueKey="id"
                  labelKey="value"
                  disabled={readOnlyFields}
                />
              )}
              {this.state.fieldsConfiguration.campaignDetailsId.permission !== permissions.hidden && (
                <Field
                  component={InputSelect}
                  colWidht={50} // 25, 33, 50, 75, 100 default=25
                  id="campaignDetailsId"
                  name="dossierCampaignComponent.campaignDetailsId"
                  controlLabel= {this.props.dossier.dossierSubTypeId === campaignType.SPECIAL ? t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.HELP_TYPE`) : t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.CAMPAIGN_TYPE`) }
                  placeholder= {this.props.dossier.dossierSubTypeId === campaignType.SPECIAL ? t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.HELP_TYPE`) : t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.CAMPAIGN_TYPE`) }
                  valueKey="id"
                  labelKey="value"
                  options={campaignDetails}
                  disabled={readOnlyFields || this.state.fieldsConfiguration.campaignDetailsId.permission === permissions.readOnly}
                  onChange={(event, value) => this.changeDetails(value, dossierId, event)}
                  onlyVisible= {!(readOnlyFields || this.state.fieldsConfiguration.campaignDetailsId.permission === permissions.readOnly)}
                />
              )}
              {this.state.fieldsConfiguration.startDate.permission !== permissions.hidden && (
                <Field
                  component={InputDatePicker}
                  colWidht={50} // 25, 33, 50, 75, 100 default=25
                  maxDate={getDateNowMaxDatePicker()}
                  id="startDate"
                  name="dossierCampaignComponent.dossierCampaignDetailComponent.startDate"
                  colSm={6}
                  controlLabel={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.START_DATE`)}
                  disabled={true}
                />
              )}
              {this.state.fieldsConfiguration.endDate.permission !== permissions.hidden && (
                <Field
                  component={InputDatePicker}
                  colWidht={50} // 25, 33, 50, 75, 100 default=25
                  maxDate={getDateNowMaxDatePicker()}
                  id="endDate"
                  name="dossierCampaignComponent.dossierCampaignDetailComponent.endDate"
                  controlLabel={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.END_DATE`)}
                  disabled={true}
                />
              )}
              {this.state.fieldsConfiguration.orderDate.permission !== permissions.hidden && (
                <Field
                  component={InputDatePicker}
                  colWidht={50} // 25, 33, 50, 75, 100 default=25
                  maxDate={getDateNowMaxDatePicker()}
                  id="orderDate"
                  name="dossierCampaignComponent.dossierCampaignDetailComponent.orderDate"
                  controlLabel={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.ORDER_DATE`)}
                  disabled={true}
                />
              )}
              {this.state.fieldsConfiguration.approvalDate.permission !== permissions.hidden && (
                <Field
                  component={InputDatePicker}
                  colWidht={50} // 25, 33, 50, 75, 100 default=25
                  maxDate={getDateNowMaxDatePicker()}
                  id="ApprovalDate"
                  name="dossierCampaignComponent.approvalDate"
                  controlLabel={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.APPROVAL_DATE`)}
                  validate={[this.dateValidation]}
                  customClass={(readOnlyFields || this.state.fieldsConfiguration.approvalDate.permission === permissions.readOnly) ? 'disabled' : ''}
                  disabled={readOnlyFields || this.state.fieldsConfiguration.approvalDate.permission === permissions.readOnly}
                />
              )}
              {this.state.fieldsConfiguration.amountCampaign.permission !== permissions.hidden && (
                <Field
                  component={InputMoney}
                  colWidht={50} // 25, 33, 50, 75, 100 default=25
                  id="amountCampaign"
                  name="dossierCampaignComponent.dossierCampaignDetailComponent.amountCampaign"
                  type="text"
                  controlLabel={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.AMOUNT_CAMPAIGN`)}
                  placeholder={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.AMOUNT_CAMPAIGN`)}
                  disabled={true}
                />
              )}
              {this.state.fieldsConfiguration.amountBrand.permission !== permissions.hidden && (
                <Field
                  component={InputMoney}
                  colWidht={50} // 25, 33, 50, 75, 100 default=25
                  id="amountBrand"
                  name="dossierCampaignComponent.dossierCampaignDetailComponent.amountBrand"
                  type="text"
                  controlLabel={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.AMOUNT_BRAND`)}
                  placeholder={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.AMOUNT_BRAND`)}
                  disabled={true}
                />
              )}
              {this.state.fieldsConfiguration.percentageCampaign.permission !== permissions.hidden && (
                <Field
                  component={InputPercentage}
                  colWidht={50} // 25, 33, 50, 75, 100 default=25
                  id="percentageCampaign"
                  name="dossierCampaignComponent.dossierCampaignDetailComponent.percentageCampaign"
                  type="text"
                  controlLabel={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.CAMPAIGN_PERCENTAGE`)}
                  placeholder={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.CAMPAIGN_PERCENTAGE`)}
                  disabled={true}
                />)}
              {this.state.fieldsConfiguration.percentageBrand.permission !== permissions.hidden && (
                <Field
                  component={InputPercentage}
                  colWidht={50} // 25, 33, 50, 75, 100 default=25
                  id="percentageBrand"
                  name="dossierCampaignComponent.dossierCampaignDetailComponent.percentageBrand"
                  type="text"
                  controlLabel={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.BRAND_PERCENTAGE`)}
                  placeholder={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.BRAND_PERCENTAGE`)}
                  disabled={true}
                />)}
            </div>
            <div className='fieldsContainer' style={{ zIndex: 3 }}>
              {this.state.fieldsConfiguration.percentageRequested.permission !== permissions.hidden && (
                <Field
                  component={InputPercentage}
                  colWidht={50} // 25, 33, 50, 75, 100 default=25
                  id="percentageCampaignRequested"
                  name="dossierCampaignComponent.dossierCampaignDetailComponent.percentageCampaignRequested"
                  type="text"
                  controlLabel={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.PERCENTAGE_REQUESTED`)}
                  placeholder={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.PERCENTAGE_REQUESTED`)}
                  disabled={readOnlyFields || (!percentageFreeOption || this.state.fieldsConfiguration.percentageRequested.permission === permissions.readOnly)}
                  onInputBlur={(component) => this.changeAmount(component)}
                />)}
              {(this.state.fieldsConfiguration.percentageApproved.permission !== permissions.hidden) && (
                <Field
                  component={InputPercentage}
                  colWidht={50} // 25, 33, 50, 75, 100 default=25
                  id="percentageCampaignApproved"
                  name="dossierCampaignComponent.dossierCampaignDetailComponent.percentageCampaignApproved"
                  type="text"
                  controlLabel={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.PERCENTAGE_APPROVED`)}
                  placeholder={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.PERCENTAGE_APPROVED`)}
                  disabled={readOnlyFields || (!percentageFreeOption || this.state.fieldsConfiguration.percentageApproved.permission === permissions.readOnly)}
                  onInputBlur={(component) => this.changeAmountApproved(component)}
                />)}
            </div>
            <div className='fieldsContainer' style={{ zIndex: 2 }}>
              {this.state.fieldsConfiguration.amountRequested.permission !== permissions.hidden && (
                <Field
                  component={InputMoney}
                  colWidht={50} // 25, 33, 50, 75, 100 default=25
                  id="amountRequested"
                  name="dossierCampaignComponent.dossierCampaignDetailComponent.amountRequested"
                  type="text"
                  controlLabel={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.AMOUNT_FREE`)}
                  placeholder={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.AMOUNT_FREE`)}
                  disabled={readOnlyFields || !amountFreeOption || this.state.fieldsConfiguration.amountRequested.permission === permissions.readOnly}
                />)}
              {this.state.fieldsConfiguration.approvedAmount.permission !== permissions.hidden && (
                <Field
                  component={InputMoney}
                  colWidht={50} // 25, 33, 50, 75, 100 default=25
                  id="approvedAmount"
                  name="dossierCampaignComponent.dossierCampaignDetailComponent.approvedAmount"
                  type="text"
                  controlLabel={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.AMOUNT_APPROVED`)}
                  placeholder={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.AMOUNT_APPROVED`)}
                  disabled={readOnlyFields || !amountFreeOption || this.state.fieldsConfiguration.approvedAmount.permission === permissions.readOnly}
                />)}
            </div>
            <div className='fieldsContainer' style={{ zIndex: 1 }}>
              {this.state.fieldsConfiguration.bonified.permission !== permissions.hidden && (
                <Field
                  component={InputText}
                  colWidht={100} // 25, 33, 50, 75, 100 default=25
                  id="bonified"
                  name="dossierCampaignComponent.dossierCampaignDetailComponent.bonified"
                  componentClass="textarea"
                  controlLabel={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.BONIFIED`)}
                  placeholder={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.BONIFIED`)}
                  disabled={true}
                  resize = "none"
                />)}
              {this.state.fieldsConfiguration.observations.permission !== permissions.hidden && (
                <Field
                  component={InputText}
                  colWidht={100} // 25, 33, 50, 75, 100 default=25
                  id="observations"
                  name="dossierCampaignComponent.dossierCampaignDetailComponent.observations"
                  componentClass="textarea"
                  controlLabel={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.OBSERVATIONS`)}
                  placeholder={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.OBSERVATIONS`)}
                  disabled={true}
                  resize = "none"
                />
              )}
              {this.state.fieldsConfiguration.vehiclePrice.permission !== permissions.hidden && (
                <Field
                  component={InputMoney}
                  colWidht={50} // 25, 33, 50, 75, 100 default=25
                  id="vehiclePrice"
                  name="dossierCampaignComponent.vehiclePrice"
                  controlLabel={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.VEHICLE_PRICE`)}
                  disabled={true}
                />
              )}
              {this.state.fieldsConfiguration.vehicleOptionalsPrice.permission !== permissions.hidden && (
                <Field
                  component={InputMoney}
                  colWidht={50} // 25, 33, 50, 75, 100 default=25
                  id="vehicleOptionalsPrice"
                  name="dossierCampaignComponent.vehicleOptionalsPrice"
                  controlLabel={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.VEHICLE_OPTIONALS_PRICE`)}
                  disabled={true}
                />
              )}
              {this.state.fieldsConfiguration.billingNumber.permission !== permissions.hidden && (
                <Field
                  component={InputText}
                  colWidht={50} // 25, 33, 50, 75, 100 default=25
                  id="BillingNumber"
                  name='dossierCampaignComponent.billingNumber'
                  key='dossierCampaignComponent.billingNumber'
                  controlLabel={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.BILLING_NUMBER`)}
                  placeholder={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.BILLING_NUMBER`)}
                  type="text"
                  disabled={readOnlyFields || this.state.fieldsConfiguration.billingNumber.permission !== permissions.editable}
                />
              )}
              {this.state.fieldsConfiguration.billingDate.permission !== permissions.hidden && (
                <Field
                  component={InputDatePicker}
                  colWidht={50} // 25, 33, 50, 75, 100 default=25
                  maxDate={getDateNowMaxDatePicker()}
                  id="BillingDate"
                  name="dossierCampaignComponent.billingDate"
                  controlLabel={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.BILLING_DATE`)}
                  disabled={readOnlyFields || this.state.fieldsConfiguration.billingDate.permission !== permissions.editable}
                  customClass={(readOnlyFields || this.state.fieldsConfiguration.billingDate.permission !== permissions.editable) ? 'disabled' : ''}
                />
              )}
              <div className="clearfix" />
              {showPercentages ? (
                <Tabs
                  id="1"
                  className={'innerComponentTabs ' + (isHistorical ? 'historical' : '')}
                  defaultActiveKey={1}
                  activeKey={this.state.tabActiveKey}
                  onSelect={tabActiveKey => { this.setState({ tabActiveKey }); this.props.actions.savePercentages(tabActiveKey) }}>
                  <Tab eventKey={1} title={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.INTRODUCE_AMOUNT`)} className="dossier-tab">
                    <div>
                      {this.state.fieldsConfiguration.costConcession.permission !== permissions.hidden && (
                        <Field
                          component={InputMoney}
                          colWidht={50} // 25, 33, 50, 75, 100 default=25
                          id="amount_amount"
                          name="dossierCampaignComponent.amount"
                          type="text"
                          controlLabel={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.AMOUNT`)}
                          placeholder={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.AMOUNT`)}
                          disabled={readOnlyFields || this.state.fieldsConfiguration.costConcession.permission !== permissions.hidden}
                          onInputBlur={(event) => this.handleBlurAmount(event)}
                          validate={(value) => this.validateTotalAmount(value)}
                        />
                      )}
                      {this.state.fieldsConfiguration.amountPercentage.permission !== permissions.hidden && (
                        <Field
                          component={InputPercentage}
                          colWidht={50} // 25, 33, 50, 75, 100 default=25
                          id="amount_amountPercentage"
                          name="dossierCampaignComponent.amountPercentage"
                          type="text"
                          controlLabel={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.AMOUNT_PERCENTAGE`)}
                          placeholder={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.AMOUNT_PERCENTAGE`)}
                          popOver={ this.state.fieldsConfiguration.popOverCampaings.permission !== permissions.hidden ? <CampaignsPopover id="campaignsPopover" name="campaignsPopover" key="campaignsPopover" t={t} text={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.AMOUNT_PERCENTAGE`) } /> : null}
                          disabled={true}
                          validate={(value) => this.validateTotalPercentage(value)}
                        />
                      )}
                      {this.state.fieldsConfiguration.cost.permission !== permissions.hidden && (
                        <Field
                          component={InputMoney}
                          colWidht={50} // 25, 33, 50, 75, 100 default=25
                          id="amount_cost"
                          name="dossierCampaignComponent.cost"
                          type="text"
                          controlLabel={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.COST`)}
                          placeholder={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.COST`)}
                          disabled={readOnlyFields || this.state.fieldsConfiguration.cost.permission !== permissions.editable}
                          onInputBlur={(event) => this.handleBlurCost(event)}
                          validate={ (value) => this.validateAmount(value)}
                        />
                      )}
                      {this.state.fieldsConfiguration.costPercentage.permission !== permissions.hidden && (
                        <Field
                          component={InputPercentage}
                          colWidht={50} // 25, 33, 50, 75, 100 default=25
                          id="amount_costPercentage"
                          name="dossierCampaignComponent.costPercentage"
                          type="text"
                          controlLabel={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.COST_PERCENTAGE`)}
                          placeholder={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.COST_PERCENTAGE`)}
                          disabled={true}
                        />
                      )}
                      {this.state.fieldsConfiguration.costConcession.permission !== permissions.hidden && (
                        <Field
                          component={InputMoney}
                          colWidht={50} // 25, 33, 50, 75, 100 default=25
                          id="amount_costConcession"
                          name="dossierCampaignComponent.costConcession"
                          type="text"
                          controlLabel={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.COST_CONCESSION`)}
                          placeholder={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.COST_CONCESSION`)}
                          disabled={readOnlyFields || this.state.fieldsConfiguration.costConcession.permission !== permissions.editable}
                          onInputBlur={(event) => this.handleBlurCostConcession(event)}
                          validate={(value) => this.validateAmount(value)}
                        />
                      )}
                      {this.state.fieldsConfiguration.costConcessionPercentage.permission !== permissions.hidden && (
                        <Field
                          component={InputPercentage}
                          colWidht={50} // 25, 33, 50, 75, 100 default=25
                          id="amount_costConcessionPercentage"
                          name="dossierCampaignComponent.costConcessionPercentage"
                          type="text"
                          controlLabel={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.COST_CONCESSION_PERCENTAGE`)}
                          placeholder={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.COST_CONCESSION_PERCENTAGE`)}
                          disabled={true}
                        />
                      )}
                    </div>
                  </Tab>
                  <Tab eventKey={2} title={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.INTRODUCE_PERCENTAGE`)} className="dossier-tab">
                    <div>
                      {this.state.fieldsConfiguration.amount.permission !== permissions.hidden && (
                        <Field
                          component={InputMoney}
                          colWidht={50} // 25, 33, 50, 75, 100 default=25
                          id="amount"
                          name="dossierCampaignComponent.amount"
                          type="text"
                          controlLabel={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.AMOUNT`)}
                          placeholder={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.AMOUNT`)}
                          disabled={true}
                        />
                      )}
                      {this.state.fieldsConfiguration.amountPercentage.permission !== permissions.hidden && (
                        <Field
                          component={InputPercentage}
                          colWidht={50} // 25, 33, 50, 75, 100 default=25
                          id="amountPercentage"
                          name="dossierCampaignComponent.amountPercentage"
                          type="text"
                          controlLabel={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.AMOUNT_PERCENTAGE`)}
                          placeholder={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.AMOUNT_PERCENTAGE`)}
                          onInputBlur={(component) => this.handleBlurAmountPercentage(component)}
                          popOver={ this.state.fieldsConfiguration.popOverCampaings.permission !== permissions.hidden ? <CampaignsPopover id="campaignsPopover" name="campaignsPopover" key="campaignsPopover" t={t} text={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.AMOUNT_PERCENTAGE`) } /> : null}
                          disabled={readOnlyFields || this.state.fieldsConfiguration.costConcession.permission !== permissions.hidden}
                        />
                      )}
                      {this.state.fieldsConfiguration.cost.permission !== permissions.hidden && (
                        <Field
                          component={InputMoney}
                          colWidht={50} // 25, 33, 50, 75, 100 default=25
                          id="cost"
                          name="dossierCampaignComponent.cost"
                          type="text"
                          controlLabel={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.COST`)}
                          placeholder={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.COST`)}
                          disabled={true}
                        />
                      )}
                      {this.state.fieldsConfiguration.costPercentage.permission !== permissions.hidden && (
                        <Field
                          component={InputPercentage}
                          colWidht={50} // 25, 33, 50, 75, 100 default=25
                          id="costPercentage"
                          name="dossierCampaignComponent.costPercentage"
                          type="text"
                          controlLabel={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.COST_PERCENTAGE`)}
                          placeholder={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.COST_PERCENTAGE`)}
                          disabled={readOnlyFields || this.state.fieldsConfiguration.costPercentage.permission !== permissions.editable}
                          onInputBlur={(component) => this.handleBlurCostPercentage(component)}
                        />
                      )}
                      {this.state.fieldsConfiguration.costConcession.permission !== permissions.hidden && (
                        <Field
                          component={InputMoney}
                          colWidht={50} // 25, 33, 50, 75, 100 default=25
                          id="costConcession"
                          name="dossierCampaignComponent.costConcession"
                          type="text"
                          controlLabel={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.COST_CONCESSION`)}
                          placeholder={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.COST_CONCESSION`)}
                          disabled={true}
                        />
                      )}
                      {this.state.fieldsConfiguration.costConcessionPercentage.permission !== permissions.hidden && (
                        <Field
                          component={InputPercentage}
                          colWidht={50} // 25, 33, 50, 75, 100 default=25
                          id="costConcessionPercentage"
                          name="dossierCampaignComponent.costConcessionPercentage"
                          type="text"
                          controlLabel={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.COST_CONCESSION_PERCENTAGE`)}
                          placeholder={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.COST_CONCESSION_PERCENTAGE`)}
                          disabled={readOnlyFields || this.state.fieldsConfiguration.costConcessionPercentage.permission !== permissions.editable}
                          onInputBlur={(component) => this.handleBlurCostConcessionPercentage(component)} k
                        />
                      )}
                      {this.state.fieldsConfiguration.additionalAmount.permission !== permissions.hidden && (
                        <Field
                          component={InputMoney}
                          colWidht={50} // 25, 33, 50, 75, 100 default=25
                          id="amount_additionalAmount"
                          name="dossierCampaignComponent.additionalAmount"
                          type="text"
                          controlLabel={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.ADDITIONAL_AMOUNT`)}
                          placeholder={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.ADDITIONAL_AMOUNT`)}
                          disabled={readOnlyFields || this.state.fieldsConfiguration.additionalAmount.permission !== permissions.editable}
                          onInputBlur={(event) => this.handleBlurAdditionalAmount(event)}
                          validate={(value) => this.validateAmount(value)}
                        />
                      )}
                      {this.state.fieldsConfiguration.additionalPercentage.permission !== permissions.hidden && (
                        <Field
                          component={InputPercentage}
                          colWidht={50} // 25, 33, 50, 75, 100 default=25
                          id="amount_additionalPercentage"
                          name="dossierCampaignComponent.additionalPercentage"
                          type="text"
                          controlLabel={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.ADDITIONAL_PERCENTAGE`)}
                          placeholder={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.ADDITIONAL_PERCENTAGE`)}
                          disabled={true}
                        />
                      )}
                    </div>
                  </Tab>
                </Tabs>) : this.state.fieldsConfiguration.amountCost.permission !== permissions.hidden && ([
                this.state.fieldsConfiguration.amount.permission !== permissions.hidden && (
                  <Field
                    component={InputMoney}
                    colWidht={50} // 25, 33, 50, 75, 100 default=25
                    id="amount"
                    key="amount"
                    name="dossierCampaignComponent.amount"
                    type="text"
                    controlLabel={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.AMOUNT`)}
                    placeholder={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.AMOUNT`)}
                    disabled={readOnlyFields || this.state.fieldsConfiguration.amount.permission !== permissions.editable}
                  />),
                this.state.fieldsConfiguration.cost.permission !== permissions.hidden && (
                  <Field
                    component={InputMoney}
                    colWidht={50} // 25, 33, 50, 75, 100 default=25
                    id="cost"
                    key="cost"
                    name="dossierCampaignComponent.cost"
                    type="text"
                    controlLabel={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.COST`)}
                    placeholder={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.COST`)}
                    disabled={readOnlyFields || this.state.fieldsConfiguration.cost.permission !== permissions.editable}
                  />)
              ])}
              {this.state.fieldsConfiguration.documentIds.permission !== permissions.hidden && (
                <Field
                  component={InputSelect}
                  colWidht={50} // 25, 33, 50, 75, 100 default=25
                  id="documentIds"
                  name="dossierCampaignComponent.documentIds"
                  multi
                  controlLabel={t(`${tKey}CAMPAIGNS.CAMPAIGNS_MODAL.CHECKLIST`)}
                  placeholder={t(`${tKey}CAMPAIGNS.CAMPAIGNS_MODAL.CHECKLIST`)}
                  options={documentsTypes}
                  // onInputChange={(value) => this.handleCampaign(value)}
                  valueKey="id"
                  labelKey="value"
                  disabled={!checklistEditable || this.state.fieldsConfiguration.documentIds.permission !== permissions.editable}
                />
              )}
              {this.state.fieldsConfiguration.expirationDate.permission !== permissions.hidden && (
                <Field
                  component={InputDatePicker}
                  colWidht={50} // 25, 33, 50, 75, 100 default=25
                  maxDate={getDateNowMaxDatePicker()}
                  id="expirationDate"
                  name="dossierCampaignComponent.expirationDate"
                  controlLabel={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.EXPIRATION_DATE`)}
                  disabled={readOnlyFields || this.state.fieldsConfiguration.expirationDate.permission !== permissions.editable}
                  customClass={(readOnlyFields || this.state.fieldsConfiguration.expirationDate.permission !== permissions.editable) ? 'disabled' : ''}
                  validate={[this.dateValidation]}
                />
              )}
              {this.state.fieldsConfiguration.paymentMethodCampaign.permission !== permissions.hidden && (
                <Field
                  component={InputSelect}
                  colWidht={50} // 25, 33, 50, 75, 100 default=25
                  id="paymentMethodCampaignId"
                  name="dossierCampaignComponent.paymentMethodCampaignId"
                  controlLabel={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.PAYMENT_METHOD`)}
                  placeholder={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.PAYMENT_METHOD`)}
                  options={paymentMethodCampaignCombo}
                  valueKey="id"
                  labelKey="value"
                  isClearable={false}
                  disabled={readOnlyFields || filledBillingDateAndNumberInParent || this.state.fieldsConfiguration.paymentMethodCampaign.permission !== permissions.editable}
                />
              )}
              <div className="clearfix" />
              {this.state.fieldsConfiguration.checkInvoiceProform.permission !== permissions.hidden && (
                <Field
                  component={InputCheckBox}
                  colWidht={50} // 25, 33, 50, 75, 100 default=25
                  id="checkInvoiceProform"
                  name="dossierCampaignComponent.checkInvoiceProform"
                  controlLabel={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.CHECK_INVOICE_PROFORM`)}
                  placeholder={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.CHECK_INVOICE_PROFORM`)}
                  disabled={this.state.fieldsConfiguration.checkInvoiceProform.permission === permissions.readOnly}
                />
              )}
              <div className="clearfix" />
              {this.state.fieldsConfiguration.checkBillingRequested.permission !== permissions.hidden && (
                <Field
                  component={InputCheckBox}
                  colWidht={50} // 25, 33, 50, 75, 100 default=25
                  id="checkBillingRequested"
                  name="dossierCampaignComponent.checkBillingRequested"
                  controlLabel={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.CHECK_BILLING_REQUESTED`)}
                  placeholder={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.CHECK_BILLING_REQUESTED`)}
                  disabled={true}
                />

              )}
            </div>
            <CustomFields customFields={customFields} readOnlyFields={readOnlyFields} />
          </Panel.Collapse>
        </Panel>
      </div>
    )
  }
}

export default CampaignComponent
