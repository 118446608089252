import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { setWindowDimensions } from '../../../actions/commons/windowDimensions'
import { okResponse } from '../../../actions/modals/customModal'
import { closeModalMassSuccess, resetModalMassUpload } from '../../../actions/masters/masters'
import { checkCanCloseDocumentModal } from '../../../actions/dashboard/dashboard'
import DocumentMassUpload from '../../../components/masters/modalMassUpload/DocumentMassUploadModal'

export function mapStateToProps (state) {
  return {
    ...state.modalMassUpload
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      setWindowDimensions,
      okResponse,
      closeModalMassSuccess,
      checkCanCloseDocumentModal,
      resetModalMassUpload
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(DocumentMassUpload))
