import { call, put, select, takeLatest } from 'redux-saga/effects'
import auditInfoActionTypes from '../../constants/actions/audit/auditInfo'
import { showLoader, hideLoader } from '../../actions/common'
import { getAuth } from '../../selectors/access/auth'
import { fetchAuditInfoSucces } from '../../actions/audit/auditInfo'
import getAuditInfo from '../../services/audit/auditInfo'
import getAuditHistoricalInfo from '../../services/audit/auditHistoricalInfo'
import { handleError } from '../errors/errorManager'

export function * fetchAuditInfo ({ dossierId, historical }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    let auditInfo
    if (historical) {
      auditInfo = yield call(getAuditHistoricalInfo, dossierId, auth)
    } else {
      auditInfo = yield call(getAuditInfo, auth.token, dossierId)
    }
    yield put(fetchAuditInfoSucces(auditInfo, dossierId, historical))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchfetchAuditInfo () {
  yield takeLatest(auditInfoActionTypes.FETCH_AUDIT_INFO, fetchAuditInfo)
}
