export default {
  FETCH_USER_ADMIN: 'FETCH_USER_ADMIN',
  FETCH_USER_ADMIN_SUCCESS: 'FETCH_USER_ADMIN_SUCCESS',
  OPEN_ADD_USER_MODAL: 'OPEN_ADD_USER_MODAL',
  OPEN_ADD_USER_MODAL_SUCCESS: 'OPEN_ADD_USER_MODAL_SUCCESS',
  OPEN_EDIT_USER_MODAL: 'OPEN_EDIT_USER_MODAL',
  OPEN_EDIT_USER_MODAL_SUCCESS: 'OPEN_EDIT_USER_MODAL_SUCCESS',
  CLOSE_EDIT_USER_MODAL: 'CLOSE_EDIT_USER_MODAL',
  SUBMIT_USER: 'SUBMIT_USER',
  DELETE_USER: 'DELETE_USER',
  DELETE_SALESMAN: 'DELETE_SALESMAN',
  RECOVER_USER: 'RECOVER_USER',
  RECOVER_SALESMAN: 'RECOVER_SALESMAN',
  SET_ACTIVE_UO_ROL: 'SET_ACTIVE_UO_ROL',
  ADD_PERMISSION: 'ADD_PERMISSION',
  ADD_UO: 'ADD_UO',
  DELETE_PERMISSION: 'DELETE_PERMISSION',
  DELETE_UO: 'DELETE_UO',
  ADMIN_CHANGE_PASSWORD: 'ADMIN_CHANGE_PASSWORD',
  AUTO_CREATE_USER: 'AUTO_CREATE_USER',
  REGISTRATION_REQUEST_ACCEPT: 'REGISTRATION_REQUEST_ACCEPT',
  REGISTRATION_REQUEST_REJECT: 'REGISTRATION_REQUEST_REJECT',
  EXIST_CIF: 'EXIST_CIF',
  OPEN_SIGN_UPLOAD_MODAL: 'OPEN_SIGN_UPLOAD_MODAL',
  OPEN_SIGN_UPLOAD_MODAL_SUCCESS: 'OPEN_SIGN_UPLOAD_MODAL_SUCCESS',
  CHECK_CAN_CLOSE_SIGN_MODAL: 'CHECK_CAN_CLOSE_SIGN_MODAL',
  CLOSE_SIGN_UPLOAD_MODAL: 'CLOSE_SIGN_UPLOAD_MODAL',
  CLOSE_SIGN_UPLOAD_MODAL_SUCCESS: 'CLOSE_SIGN_UPLOAD_MODAL_SUCCESS',
  UPLOAD_SIGN: 'UPLOAD_SIGN',
  DOWNLOAD_SIGN: 'DOWNLOAD_SIGN',
  UPLOAD_USER_MASS_DOC: 'UPLOAD_USER_MASS_DOC',
  CLEAR_USER_ADMIN_SEARCH_FILTER: 'CLEAR_USER_ADMIN_SEARCH_FILTER'
}
