import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import { Table, Row, Col, Button, Tooltip, OverlayTrigger } from 'react-bootstrap'
import { Field, reduxForm } from 'redux-form'
import InputText from '../../commons/form/InputText'
import { getListOusFromTree } from '../../../util/utils'
import ModalMassUploadPage from '../../../containers/masters/ModalMassUpload/ModalMassUploadPage'
import { loadTemplatesNames } from '../../../constants/backendIds'
import InputSelect from '../../commons/form/InputSelect'
import InputTreeSelect from '../../commons/form/InputTreeSelect'
import IDocCarPagination from '../../commons/iDocCarPagination'
import OrganizedUnitModalPage from '../../../containers/masters/OrganizedUnit/OrganizedUnitModalPage'
class OuLoad extends PureComponent {
  constructor () {
    super()
    this.state = {
      uoId: null,
      uoList: null,
      showModal: false,
      filter: {
        searchUo: 0,
        searchLegalCompany: 0,
        searchName: null,
        searchCode: null
      }
    }
  }

  getSortIcon (column = '') {
    switch (this.props.filter.orderBy) {
      case '+' + column:
        return 'ico-sort-selected-asc'
      case '-' + column:
        return 'ico-sort-selected-desc'
      default:
        return 'ico-sort'
    }
  }

  changeOrder (field) {
    switch (this.props.filter.orderBy) {
      case `+${field}`:
        this.props.actions.fetchOrganizedUnitList({ ...this.state.filter, ...this.props.filter, orderBy: `-${field}` })
        break
      default:
        this.props.actions.fetchOrganizedUnitList({ ...this.state.filter, ...this.props.filter, orderBy: `+${field}` })
        break
    }
  }

  handleUoChange (searchUo) {
    if (this.state.filter.searchUo !== searchUo) {
      this.setState({ filter: { ...this.state.filter, searchUo }, uoId: searchUo })
    }
  }

  handleLegalCompanyChange (searchLegalCompany) {
    if (this.state.filter.searchLegalCompany !== searchLegalCompany) {
      this.setState({ filter: { ...this.state.filter, searchLegalCompany } })
    }
  }

  handleNameChange (searchName) {
    if (this.state.filter.searchName !== searchName) {
      this.setState({ filter: { ...this.state.filter, searchName } })
    }
  }

  handleCodeChange (searchCode) {
    if (this.state.filter.searchCode !== searchCode) {
      this.setState({ filter: { ...this.state.filter, searchCode } })
    }
  }

  searchOrganizedUnitList () {
    this.props.actions.fetchOrganizedUnitList({ ...this.props.filter, ...this.state.filter, page: 1 })
  }

  handleOrganizedUnitChange (uoId) {
    let uoList = getListOusFromTree(this.props.combos.UOTreeCombo, uoId)
    this.setState({ uoId, uoList })
  }

  handleParamConfig (uoId) {
    this.props.actions.setChangeTabFromConfMaster(uoId, true)
  }

  handleRecurrentEntity (uoId) {
    this.props.actions.setChangeTabFromMaster(uoId, true)
  }

  openOuModalLoad () {
    this.setState({ showModal: true })
    this.props.actions.openModalMass('MASTERS.OU_MASTERS.', this.props.actions.uploadUoMassDocument, this.props.actions.fetchOrganizedUnitsTree)
  }

  closeOuModalLoad () {
    this.setState({ showModal: false })
  }

  resetFilters () {
    this.setState({
      filter: {
        searchUo: 0,
        searchLegalCompany: 0,
        searchName: null,
        searchCode: null
      }
    }, () => { this.searchOrganizedUnitList() })
  }

  render () {
    const {
      t, organizedUnitList, organizedUnitCount, pagesCount, filter,
      filter: { page },
      actions: { fetchTemplateFile, fetchOrganizedUnitList, openOrganizedUnitModal },
      combos: { UOTreeCombo, legalCompanyCombo }
    } = this.props
    const tKey = 'MASTERS.OU_MASTERS.'
    const editTooltip = (<Tooltip id="editTooltip">{t('MASTERS.EDIT')}</Tooltip>)
    const recurrentEntityTooltip = (<Tooltip id="recurrentEntityTooltip">{t('ADMIN.RECURRENT_ENTITIES_TITLE')}</Tooltip>)
    const paramConfigTooltip = (<Tooltip id="paramConfigTooltip">{t('MASTERS.ORGANIZED_UNIT_CONFIGURATION')}</Tooltip>)
    const hasFilters = (this.state.filter.searchCode || this.state.filter.searchLegalCompany || this.state.filter.searchName || this.state.filter.searchUo)
    return (
      <Row>
        <Col sm={12}>
          <div className="header-panel">
            <h4>{t(`${tKey}SOURCE_CHANNEL`)}</h4>
          </div>
        </Col>
        <ModalMassUploadPage
          showModal={this.state.showModal}
          closeModal={this.closeOuModalLoad.bind(this)}
        />
        <Col sm={12}>
          <div className="search-form">
            <span className="title-search"><i className="ico-search" /> {t(`${tKey}SOURCE_CHANNEL`)}</span>
            <Row>
              <Field
                name="filters.organizedUnitIdd"
                controlLabel={t(`${tKey}ORGANIZED_UNIT`)}
                placeholder={t(`${tKey}ORGANIZED_UNIT`)}
                component={InputTreeSelect}
                valueKey="value"
                labelKey="label"
                childrenKey="children"
                options={UOTreeCombo}
                colSm={2}
                onInputChange={(value) => this.handleUoChange(value)}
                showSearch={false}
              />
              <Field
                name="filters.code"
                colSm={2}
                controlLabel={t(`${tKey}UOCODE`)}
                placeholder={t(`${tKey}UOCODE`)}
                component={InputText}
                onInputChange={(value) => this.handleCodeChange(value)}
              />
              <Field
                name="filters.name"
                colSm={2}
                controlLabel={t(`${tKey}NAME`)}
                placeholder={t(`${tKey}NAME`)}
                component={InputText}
                onInputChange={(value) => this.handleNameChange(value)}
              />
              <Field
                name="filters.legalCompanyId"
                colSm={2}
                controlLabel={t(`${tKey}LEGAL_COMPANY`)}
                placeholder={t(`${tKey}LEGAL_COMPANY`)}
                options={legalCompanyCombo}
                component={InputSelect}
                valueKey="id"
                labelKey="value"
                searchPlaceholder={t(`${tKey}LEGAL_COMPANY`)}
                onInputChange={(value) => this.handleLegalCompanyChange(value)}
              />
              <Col sm={4} className="btn-wrapper">
                <Button
                  className={'btn-white btn-icon btn-input btn-white search-button'}
                  onClick={() => this.searchOrganizedUnitList()}>
                  <i className="ico-search" />
                  {t('MASTERS.SEARCH_BTN')}
                </Button>
                <Button
                  className={'btn-icon btn-input ' + (hasFilters ? 'btn-standard' : 'btn-cancel')}
                  onClick={() => {
                    this.props.initialize({})
                    this.resetFilters()
                  }}
                >
                  <i className="ico-cancel" />
                  {t('MASTERS.RESET_BUTTON')}
                </Button>
              </Col>
            </Row>
          </div>
        </Col>
        <Col sm={12}>
          <Button
            className="btn-download btn-editable-table btn-standard"
            onClick={() => fetchTemplateFile(loadTemplatesNames.orgUnit)}
          >
            {t('MASTERS.DOWNLOAD')}
          </Button>
        </Col >
        <OrganizedUnitModalPage />
        <Col sm={12}>
          <Table className="editable-table" hover responsive>
            <thead>
              <tr>
                <th
                  onClick={() => { this.changeOrder('organizedUnitId') }}
                >
                  {t('MASTERS.ID')}
                  <i className={this.getSortIcon(t('MASTERS.ID'))} />
                </th>
                <th
                  onClick={() => { this.changeOrder('code') }}
                >
                  {t(`${tKey}UOCODE`)}
                  <i className={this.getSortIcon(t(`${tKey}UOCODE`))} />
                </th>
                <th
                  onClick={() => { this.changeOrder('name') }}
                >
                  {t(`${tKey}NAME`)}
                  <i className={this.getSortIcon(t(`${tKey}NAME`))} />
                </th>
                <th
                  onClick={() => { this.changeOrder('parentOrganizedUnit') }}
                >
                  {t(`${tKey}PARENT_OU`)}
                  <i className={this.getSortIcon(t(`${tKey}PARENT_OU`))} />
                </th>
                <th
                  onClick={() => { this.changeOrder('brand') }}
                >
                  {t(`${tKey}BRAND`)}
                  <i className={this.getSortIcon(t(`${tKey}BRAND`))} />
                </th>
                <th
                  onClick={() => { this.changeOrder('legalCompany') }}
                >
                  {t(`${tKey}LEGAL_COMPANY`)}
                  <i className={this.getSortIcon(t(`${tKey}LEGAL_COMPANY`))} />
                </th>
                <th style={{ width: '120px' }}>{t('DOSSIER_COMPONENTS.ACCESSORIES.ACTIONS')}</th>
              </tr>
            </thead>
            <tbody>
              {organizedUnitList && organizedUnitList.map((ou, idx) => (
                <tr key={idx}>
                  <td>{ou.organizedUnitId}</td>
                  <td>{ou.code}</td>
                  <td>{ou.name}</td>
                  <td>{ou.parentOrganizedUnit?.name}</td>
                  <td>{ou.brand}</td>
                  <td>
                    {
                      ou.legalCompany?.cif && ou.legalCompany?.description
                        ? ou.legalCompany?.cif + ' - ' + ou.legalCompany?.description
                        : ou.legalCompany?.cif ?? ou.legalCompany?.description
                    }
                  </td>
                  <td>
                    <a onClick={() => openOrganizedUnitModal(ou.organizedUnitId)}>
                      <OverlayTrigger placement="left" overlay={editTooltip}>
                        <i className="ico-edit-white"/>
                      </OverlayTrigger>
                    </a>
                    <Link to={'/admin'}>
                      <OverlayTrigger placement="left" overlay={recurrentEntityTooltip}>
                        <i className="ico-user" onClick={() => this.handleRecurrentEntity(ou.parentOrganizedUnitId)}/>
                      </OverlayTrigger>
                    </Link>
                    <Link to={'/masters'}>
                      <OverlayTrigger placement="left" overlay={paramConfigTooltip}>
                        <i className="ico-accessories"
                          style={{ fontSize: '3rem' }}
                          onClick={() => this.handleParamConfig(ou.parentOrganizedUnitId)}/>
                      </OverlayTrigger>
                    </Link>
                  </td>
                </tr>
              ))}
              {(organizedUnitList === null || organizedUnitList === undefined || organizedUnitList.length === 0) &&
            <tr>
              <td colSpan={12}><h2>{t(`${tKey}RESULTS_NOT_FOUND`)}</h2></td>
            </tr>}
              <tr className="button-table-wrapper">
                <td>
                  <Button
                    className="btn-editable-table btn-standard"
                    onClick={() => openOrganizedUnitModal()}>
                  + {t('MASTERS.ADD')}
                  </Button>
                  <Button
                    className="btn-editable-table btn-standard col-2"
                    onClick={this.openOuModalLoad.bind(this)}
                  >
                    + {t(`${tKey}ADD_FROM_TEMPLATE`)}
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>
          <div className="section-users-footer">
            <nav key={1} className="text-center">
              <IDocCarPagination
                id="btn_pag_organized_unit"
                page={page}
                pagesCount={pagesCount}
                totalRows={organizedUnitCount}
                onSelectAction={(value) => fetchOrganizedUnitList({ ...filter, ...this.state.filter, page: value })}
              />
            </nav>
          </div>
        </Col>
      </Row>
    )
  }
}

export default reduxForm({
  form: 'listOrganizedUnit'
})(OuLoad)
