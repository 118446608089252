import React, { PureComponent } from 'react'
import { Row, Col, Checkbox, Button } from 'react-bootstrap'

class Step1 extends PureComponent {
  render () {
    return (
      <div className="step1-summary">
        <Row className="row-border">
          <h4>Documentos de cliente</h4>
          <Col sm={3} className="checkbox-wrapper">
            <Checkbox inline>
              DNI/CIF
            </Checkbox>
            <span className="dot-after"></span>
          </Col>
          <Col sm={3} className="checkbox-wrapper">
            <Checkbox inline>
              Libro fam.
            </Checkbox>
            <span className="dot-after"></span>
          </Col>
          <Col sm={3} className="checkbox-wrapper">
            <Checkbox inline>
              Escrituras
            </Checkbox>
            <span className="dot-after"></span>
          </Col>
        </Row>
        <Row className="row-border">
          <h4>Documentos de vehículo</h4>
          <Col sm={3} className="checkbox-wrapper">
            <Checkbox inline>
              Ficha técnica
            </Checkbox>
            <span className="dot-after"></span>
          </Col>
          <Col sm={3} className="checkbox-wrapper">
            <Checkbox inline>
              Permiso circulación
            </Checkbox>
            <span className="dot-after"></span>
          </Col>
          <Col sm={3} className="checkbox-wrapper">
            <Checkbox inline>
              Imp. matriculación
            </Checkbox>
            <span className="dot-after"></span>
          </Col>
        </Row>
        <Row className="row-border">
          <h4>Otros documentos</h4>
          <Col sm={3} className="checkbox-wrapper">
            <Checkbox inline>
              Albarán trámite
            </Checkbox>
            <span className="dot-after"></span>
          </Col>
        </Row>
        <Row className="row-border">
          <h4>Originales</h4>
          <Col sm={3} className="checkbox-wrapper">
            <Checkbox inline>
              Permiso circulación
            </Checkbox>
            <span className="dot-after"></span>
          </Col>
        </Row>
        <div className="button-wrapper">
          <Button className="btn-standard-white btn-continue">
            <i className="ico-check" /> Enviar trámite
          </Button>
        </div>
      </div>
    )
  }
}

export default Step1
