import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Units from '../../components/approve/Approve'
import { translate } from 'react-polyglot'
import TrackingContainer from '../../containers/tracking/trackingContainer'

export function mapStateToProps (state) {
  return {
    ...state
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
    }, dispatch)
  }
}
var decoratedComponent = TrackingContainer(Units, true)
export default connect(mapStateToProps, mapDispatchToProps)(translate()(decoratedComponent))
