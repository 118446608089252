
import React, { PureComponent } from 'react'
import { Modal, Col, Row, FormGroup, Button } from 'react-bootstrap'
import { reduxForm, Field } from 'redux-form'
import TextInput from '../../commons/form/InputText'

class PwkTypeMasterModal extends PureComponent {
  submitPwkType (values) {
    if (this.props.pwkTypeId) {
      this.props.actions.updatePaperwokTypeMaster(values, this.props.languageList, this.props.pwkTypeId)
    } else {
      this.props.actions.postPwkTypeMasterModal(values, this.props.languageList)
    }
  }

  render () {
    const {
      t, handleSubmit, languageList = [],
      showModal
    } = this.props
    const tKey = 'MASTERS.PWK_TYPES_MASTER.MODAL.'
    return (
      <Modal className="languages-modal" show={showModal} onHide={this.props.actions.adviceClosePaperwokTypeMasterModal} backdrop={'static'}>
        <form autoComplete="off" onSubmit={handleSubmit(this.submitPwkType.bind(this))}>
          <Modal.Header closeButton onHide={this.props.actions.adviceClosePaperwokTypeMasterModal}>
            <Modal.Title>{t(`${tKey}TITLE`)}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="title-column">
              <Col sm={4}>
                <h2>{t('MASTERS.NAME')}</h2>
              </Col>
              <Col sm={4}>
                <h2>{t('MASTERS.DESCRIPTION')}</h2>
              </Col>
              <Col sm={2}>
                <h2>{t('MASTERS.LANGUAGE')}</h2>
              </Col>
            </Row>
            <FormGroup>
              {languageList.map((val, idx) => {
                return (
                  <Row key={idx} className="inputs-wrapper">
                    <Col sm={4} className="custom-width-col">
                      <Field
                        name={`lang${val.languageId}.name`}
                        placeholder={t('MASTERS.NAME')}
                        component={TextInput}
                        maxLength={40}
                      />
                    </Col>
                    <Col sm={4} className="custom-width-col">
                      <Field
                        name={`lang${val.languageId}.description`}
                        placeholder={t('MASTERS.DESCRIPTION')}
                        component={TextInput}
                        maxLength={40}
                      />
                    </Col>
                    <Col sm={2} className="language-column">
                      <p>{`${val.description} (${val.name})`}</p>
                    </Col>
                  </Row>
                )
              })}
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <Col sm={12} className="btn-wrapper">
              <Button
                id="role-modal-submit-button"
                className="btn-standard"
                type="submit"
                disabled={this.props.pristine}>
                {t(`${tKey}SUBMIT_BUTTON`)}
              </Button>
            </Col>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}
const validate = (values, props) => {
  const errors = {}
  props.languageList.map(language => {
    errors[`lang${language.languageId}`] = {}
    if (values[`lang${language.languageId}`]) {
      if (!(values[`lang${language.languageId}`].name) || values[`lang${language.languageId}`].name.length === 0) {
        errors[`lang${language.languageId}`].name = props.t('MASTERS.VALIDATION.NAME_REQUIRED')
      }
      if (!(values[`lang${language.languageId}`].description) || values[`lang${language.languageId}`].description.length === 0) {
        errors[`lang${language.languageId}`].description = props.t('MASTERS.VALIDATION.DESCRIPTION_REQUIRED')
      }
    } else {
      errors[`lang${language.languageId}`].name = props.t('MASTERS.VALIDATION.NAME_REQUIRED')
      errors[`lang${language.languageId}`].description = props.t('MASTERS.VALIDATION.DESCRIPTION_REQUIRED')
    }
  })
  return errors
}

export default reduxForm({
  form: 'editPwkTypeModal',
  validate
})(PwkTypeMasterModal)
