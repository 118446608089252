import React, { PureComponent } from 'react'

class SmallLoader extends PureComponent {
  render () {
    const { componentLoading, background, specificComponentLoading } = this.props
    if ((specificComponentLoading === null || specificComponentLoading === undefined) && componentLoading <= 0) return null
    if (specificComponentLoading && specificComponentLoading <= 0) return null
    return (
      <div style={{
        position: 'relative',
        // zIndex: '9999999',
        textAlign: 'center',
        background: background || '#fafafa',
        width: '100%'
      }}>
        <img src="images/Spinner.gif"/>
      </div>
    )
  }
}

export default SmallLoader
