import { call, put, select, takeLatest } from 'redux-saga/effects'
import { getAuth } from '../../selectors/access/auth'
import getFinancingCompanies from '../../services/financeCompany/getFinanceCompaniesCombo'
import { showLoader, hideLoader } from '../../actions/common'
import messageModalActions from '../../actions/modals/messageModal'
import { fetchCombosSuccess } from '../../actions/combos/combos'
import combosActionTypes from '../../constants/actions/combos/combos'
import { handleError } from '../errors/errorManager'
import getFinancingCompaniesService from '../../services/financeCompany/getFinanceCompanies'

export function * fetchFinancingCompanies () {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const companiesCombo = auth.organizedUnit === null || auth.organizedUnit === undefined ? yield call(getFinancingCompanies, 0, auth.token)
      : yield call(getFinancingCompanies, auth.organizedUnit, auth.token)
    yield put(fetchCombosSuccess({ companiesCombo }))
  } catch (error) {
    const customHandler = function * () {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.FETCH_COMBO_ERROR'))
    }
    yield call(handleError, { error, customHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchFinancingCompanies () {
  yield takeLatest(combosActionTypes.FETCH_COMPANIES, fetchFinancingCompanies)
}

export function * fetchFinancingCompaniesBy ({ organizedUnitId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const financialInstitutionCombo = organizedUnitId === null || organizedUnitId === undefined ? yield call(getFinancingCompanies, 0, auth.token)
      : yield call(getFinancingCompaniesService, organizedUnitId, auth.token, false)
    yield put(fetchCombosSuccess({ financialInstitutionCombo }))
  } catch (error) {
    const customHandler = function * () {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.FETCH_COMBO_ERROR'))
    }
    yield call(handleError, { error, customHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchFinancingCompaniesBy () {
  yield takeLatest(combosActionTypes.FETCH_COMPANIES_BY_OU, fetchFinancingCompaniesBy)
}
