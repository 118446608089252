import React, { PureComponent } from 'react'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'

class BubbleMeter extends PureComponent {
  render () {
    const tooltip = (
      <Tooltip id="tooltip" className="tooltip-with-list">
        <ul className="role-header-tooltip tooltip-list">
          {this.props.listItems.map((val, idx) => idx !== 0 && <li key={idx}>{val}</li>)}
        </ul>
      </Tooltip>
    )
    const { listItems } = this.props
    if (!listItems || listItems.length < 2) return null
    return (
      <OverlayTrigger placement="bottom" overlay={tooltip}>
        <span className="grey-point">+{listItems.length - 1}</span>
      </OverlayTrigger>
    )
  }
}

export default BubbleMeter
