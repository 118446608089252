import { put, call, takeEvery, select } from 'redux-saga/effects'
import { reset } from 'redux-form'
import { getAuth } from '../../../../selectors/access/auth'
import { yesNoModal } from '../../../modalsListeners/yesNoModal'
import documentHistoricalEditionModalActionsTypes from '../../../../constants/actions/dossier/common/documentHistoricalEditionModal'
import { documentEntityType as documentEntityTypeConstant } from '../../../../constants/dossier/common/documentEntityType'
import { typeFields as typeFieldsConstant } from '../../../../constants/dossier/common/typeFields'
import { showLoader, hideLoader } from '../../../../actions/common'
import messageModalActions from '../../../../actions/modals/messageModal'
import { fetchCombosSuccess } from '../../../../actions/combos/combos'
import { fetchInitialNotificationsCounter } from '../../../../actions/notifications/notifications'
import {
  openDocumentCreationModalSuccess,
  openDocumentEditionModalSuccess,
  fetchDocumentSuccess,
  closeAddOrEditDocumentModalSuccess,
  changeId,
  setActivePage
} from '../../../../actions/dossier/common/documentHistoricalEditionModal'
import getDocumentsTypes from '../../../../services/document/getHistoricalDocumentsTypes'
import getDocumentByTypeUse from '../../../../services/document/getDocumentByTypeUse'
import postDocumentService from '../../../../services/document/postHistoricalDocument'
import getDocumentByType from '../../../../services/document/getHistoricalDocumentByType'
import getDocumentByIdService from '../../../../services/document/getHistoricalDocument'
import getDocumentVisor from '../../../../services/document/getDocumentHistoricalVisor'
import getDocumentHistoricalDownload from '../../../../services/document/getDocumentHistoricalDownload'
import updateDocument from '../../../../services/document/updateDocumentHistorical'
import getMetadatoListItems from '../../../../services/document/getMetadatoListItems'
import getDocumentSheet from '../../../../services/documentSheet/getHistoricalDocumentSheet'
import postDocumentSheetFileService from '../../../../services/documentSheet/postHistoricalDocumentSheetFile'
import deleteDocumentSheetService from '../../../../services/documentSheet/deleteHistoricalDocumentSheet'
import getDocumentSheetVisor from '../../../../services/documentSheet/getHistoricalDocumentSheetVisor'
import getHistoricalDocumentSheetDownload from '../../../../services/documentSheet/getHistoricalDocumentSheetDownload'
import { handleError } from '../../../errors/errorManager'
// import {getDossierStateByType, initializeDossier} from '../../commonDossierSagas'
import { changeDocumentManagementChangedPilots } from './managementModalSagas'

function * getDocumentParentRelatedId (documentParentTypeId, dossierId, entityId, vehicleId) {
  switch (documentParentTypeId) {
    case documentEntityTypeConstant.ENTITY:
      return entityId
    case documentEntityTypeConstant.VEHICLE:
      return vehicleId
    case documentEntityTypeConstant.DOSSIER:
      return dossierId
    default:
      return null
  }
}

function * getMetadato (fields, documentId) {
  const auth = yield select(getAuth)
  for (let i = fields.length - 1; i >= 0; i--) {
    if (fields[i].typeField === typeFieldsConstant.list) {
      fields[i].options = yield call(getMetadatoListItems, auth.token, fields[i].fieldId, documentId)
    }
  }
  return fields
}

export function * openDocumentCreationModal ({ dossierId, documentParentTypeId, organizedUnitId, entityId, vehicleId, callback, refreshAfterChange, dossierType }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const documentsTypes = yield call(getDocumentsTypes, auth.token, documentParentTypeId, dossierId)
    yield put(fetchCombosSuccess({ documentsTypes }))
    yield put(openDocumentCreationModalSuccess(documentParentTypeId, organizedUnitId, dossierId, entityId, vehicleId, callback, refreshAfterChange, dossierType))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchOpenDocumentHistoricalCreationModal () {
  yield takeEvery(documentHistoricalEditionModalActionsTypes.OPEN_DOCUMENT_HISTORICAL_CREATION_MODAL, openDocumentCreationModal)
}

export function * openDocumentEditionModal ({ documentId, documentTypeUse, documentParentTypeId, dossierId, entityId, vehicleId, organizedUnitId, checklistId, callback, refreshAfterChange, dossierType, dossierSubType }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const documentTypeUseInfo = yield call(getDocumentByTypeUse, documentTypeUse, false, auth.token)
    const documentParentRelatedId = yield call(getDocumentParentRelatedId, documentParentTypeId, dossierId, entityId, vehicleId)
    const docResult = documentId ? yield call(getDocumentByIdService, auth.token, documentId) : yield call(getDocumentByType, auth.token, documentTypeUse, documentParentRelatedId)
    docResult.fields = yield call(getMetadato, docResult.fields, documentId)
    if (documentId) {
      docResult.sheets = yield call(getDocumentSheet, auth.token, documentId)
    }
    yield put(openDocumentEditionModalSuccess(docResult, documentTypeUseInfo, organizedUnitId, callback, dossierId, entityId, vehicleId, documentParentTypeId, checklistId, refreshAfterChange, dossierType))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchOpenDocumentHistoricalEditionModal () {
  yield takeEvery(documentHistoricalEditionModalActionsTypes.OPEN_DOCUMENT_HISTORICAL_EDITION_MODAL, openDocumentEditionModal)
}

export function * closeAddOrEditDocumentModalCallback () {
  try {
    yield put(showLoader())
    yield put(closeAddOrEditDocumentModalSuccess())
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchCloseAddOrEditDocumentHistoricalModalCallback () {
  yield takeEvery(documentHistoricalEditionModalActionsTypes.CLOSE_ADD_OR_EDIT_DOCUMENT_HISTORICAL_MODAL, closeAddOrEditDocumentModalCallback)
}

export function * onDocumentTypeIdChange ({ documentTypeId, documentTypeUseName }) {
  try {
    if (!documentTypeId) {
      yield put(fetchDocumentSuccess({}))
    } else {
      yield put(showLoader())
      const documentHistoricalEditionModal = yield select(state => state.documentHistoricalEditionModal)
      const documentParentRelatedId = yield call(
        getDocumentParentRelatedId,
        documentHistoricalEditionModal.documentParentTypeId,
        documentHistoricalEditionModal.dossierId,
        documentHistoricalEditionModal.entityId,
        documentHistoricalEditionModal.vehicleId
      )
      const auth = yield select(getAuth)
      const document = yield call(getDocumentByType, auth.token, documentTypeId, documentParentRelatedId)
      yield put(fetchDocumentSuccess(document, documentTypeId, documentTypeUseName))
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchOnDocumentHistoricalTypeIdChange () {
  yield takeEvery(documentHistoricalEditionModalActionsTypes.ON_DOCUMENT_HISTORICAL_TYPE_ID_CHANGE, onDocumentTypeIdChange)
}

export function * createDocument ({ file, checklistId, documentTypeUseId, dossierId }) {
  const documentHistoricalEditionModal = yield select(state => state.documentHistoricalEditionModal)
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const id = yield call(
      getDocumentParentRelatedId,
      documentHistoricalEditionModal.documentParentTypeId,
      documentHistoricalEditionModal.dossierId,
      documentHistoricalEditionModal.entityId,
      documentHistoricalEditionModal.vehicleId
    )
    const response = yield call(
      postDocumentService,
      documentHistoricalEditionModal.organizedUnitId,
      documentTypeUseId,
      auth.token,
      file,
      id,
      checklistId
    )
    yield put(messageModalActions.openSuccessMessageModal('MESSAGES.DOCUMENT_MANAGEMENT.POST_DOCUMENT_SUCCESS'))
    yield call(changeDocumentManagementChangedPilots, documentHistoricalEditionModal.documentParentTypeId)
    yield put(changeId(response.id))
    const document = yield call(getDocumentByIdService, auth.token, response.documentId)
    document.fields = yield call(getMetadato, document.fields, response.documentId)
    yield put(fetchDocumentSuccess(document))
    const entityId = yield select(state => state.documentHistoricalEditionModal.entityId)
    if (documentHistoricalEditionModal.callback) yield call(documentHistoricalEditionModal.callback, entityId)
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchCreateDocumentHistorical () {
  yield takeEvery(documentHistoricalEditionModalActionsTypes.CREATE_DOCUMENT_HISTORICAL, createDocument)
}

export function * saveDocument ({ fields }) {
  const documentHistoricalEditionModal = yield select(state => state.documentHistoricalEditionModal)
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const objectId = yield call(
      getDocumentParentRelatedId,
      documentHistoricalEditionModal.documentParentTypeId,
      documentHistoricalEditionModal.dossierId,
      documentHistoricalEditionModal.entityId,
      documentHistoricalEditionModal.vehicleId
    )
    const infoIncome = {
      documentTypeId: documentHistoricalEditionModal.documentTypeUseId,
      objectId,
      fields
    }
    yield call(updateDocument,
      documentHistoricalEditionModal.docResult.documentId,
      infoIncome,
      auth.token
    )
    yield put(messageModalActions.openSuccessMessageModal('MESSAGES.DOCUMENT_MANAGEMENT.PUT_DOCUMENT_SUCCESS'))
    if (documentHistoricalEditionModal.refreshAfterChange) {
      if (documentHistoricalEditionModal.callback) yield call(documentHistoricalEditionModal.callback, documentHistoricalEditionModal.entityId)
    }
    yield put(closeAddOrEditDocumentModalSuccess())
    yield put(fetchInitialNotificationsCounter())
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSaveDocumentHistorical () {
  yield takeEvery(documentHistoricalEditionModalActionsTypes.SAVE_DOCUMENT_HISTORICAL, saveDocument)
}

export function * toggleFullScreenDocumentModal ({ documentId, isDownload }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    let documentUrl = null
    if (isDownload) {
      documentUrl = yield call(getDocumentHistoricalDownload, documentId, auth.token)
    } else {
      documentUrl = yield call(getDocumentVisor, documentId, auth.token)
    }
    if (documentUrl) {
      window.open(documentUrl.value)
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchToggleFullScreenDocumentHistoricalModal () {
  yield takeEvery(documentHistoricalEditionModalActionsTypes.TOGGLE_FULL_SCREEN_DOCUMENT_HISTORICAL_MODAL, toggleFullScreenDocumentModal)
}

export function * createDocumentSheet ({ file, organizedUnitId }) {
  const documentHistoricalEditionModal = yield select(state => state.documentHistoricalEditionModal)
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(
      postDocumentSheetFileService,
      documentHistoricalEditionModal.docResult.documentId,
      auth.token,
      file
    )
    yield put(messageModalActions.openSuccessMessageModal('MESSAGES.DOCUMENT_MANAGEMENT.POST_DOCUMENT_SHEET_SUCCESS'))
    const document = yield call(getDocumentByIdService, auth.token, documentHistoricalEditionModal.docResult.documentId)
    document.fields = yield call(getMetadato, document.fields, documentHistoricalEditionModal.docResult.documentId)
    document.sheets = yield call(getDocumentSheet, auth.token, documentHistoricalEditionModal.docResult.documentId)
    yield put(fetchDocumentSuccess(document))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchCreateDocumentSheetHistorical () {
  yield takeEvery(documentHistoricalEditionModalActionsTypes.CREATE_DOCUMENT_SHEET_HISTORICAL, createDocumentSheet)
}

export function * deleteDocumentSheet ({ documentId, sheetId, resolve, reject, organizedUnitId }) {
  try {
    const confirmed = yield call(yesNoModal, 'deleteSheet')
    if (confirmed) {
      yield put(showLoader())
      const auth = yield select(getAuth)
      const documentHistoricalEditionModal = yield select(state => state.documentHistoricalEditionModal)
      yield call(
        deleteDocumentSheetService,
        documentId,
        sheetId,
        auth.token
      )
      yield put(messageModalActions.openSuccessMessageModal('MESSAGES.DOCUMENT_MANAGEMENT.DELETE_DOCUMENT_SHEET_SUCCESS'))
      const document = yield call(getDocumentByIdService, auth.token, documentHistoricalEditionModal.docResult.documentId)
      document.fields = yield call(getMetadato, document.fields, documentHistoricalEditionModal.docResult.documentId)
      document.sheets = yield call(getDocumentSheet, auth.token, documentHistoricalEditionModal.docResult.documentId)
      yield put(fetchDocumentSuccess(document))
      if (documentHistoricalEditionModal.activeSheet === sheetId) yield put(setActivePage(0, 1))
      if (resolve) yield call(resolve)
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDeleteDocumentSheetHistorical () {
  yield takeEvery(documentHistoricalEditionModalActionsTypes.DELETE_DOCUMENT_SHEET_HISTORICAL, deleteDocumentSheet)
}

export function * toggleFullScreenDocumentSheetHistoricalModal ({ documentId, sheetId, isDownload }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    if (isDownload) {
      yield call(getHistoricalDocumentSheetDownload, documentId, sheetId, auth.token)
    } else {
      const documentUrl = yield call(getDocumentSheetVisor, documentId, sheetId, auth.token)
      window.open(documentUrl.value)
    }
  } catch (error) {
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('purchase_dossier'))
      yield put(reset('paperwork_dossier'))
      yield put(reset('campaign_dossier'))
    }
    yield call(handleError, { error, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchToggleFullScreenDocumentSheetHistoricalModal () {
  yield takeEvery(documentHistoricalEditionModalActionsTypes.TOGGLE_FULL_SCREEN_DOCUMENT_SHEET_HISTORICAL_MODAL, toggleFullScreenDocumentSheetHistoricalModal)
}
