import apiFetch from '../../apiFetch'

export default function (dossierId, token, lastAccessDate, forceCancel, reason, comment) {
  const queryParamsArray = [
    forceCancel ? `forceCancel=${forceCancel}` : null,
    reason ? `reason=${reason}` : null,
    comment ? `comment=${comment}` : null
  ]
  const queryParams = queryParamsArray.reduce(
    (result, query, idx) => result + (query ? (result ? `&${query}` : `?${query}`) : '')
    , ''
  )
  return apiFetch({ endPoint: `dossier/${dossierId}/cancel${queryParams}`, method: 'PUT', body: null, token: token, lastAccessDate })
}
