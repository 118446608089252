import React, { Component } from 'react'
import { Glyphicon, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { permissions } from '../../../../constants/backendIds'

class TimeLine extends Component {
  constructor (props) {
    super(props)

    this.state = {
      liClass: ''
    }
  }

  UNSAFE_componentWillMount () {
    let liClass = ''
    const stages = this.props.stages || []
    switch (stages.length) {
      default:
      case 4:
        liClass = 'four'
        break
      case 5:
        liClass = 'five'
        break
      case 6:
        liClass = 'six'
        break
      case 7:
        liClass = 'seven'
        break
      case 8:
        liClass = 'eight'
        break
      case 9:
        liClass = 'nine'
        break
    }
    this.setState({ liClass })
  }

  render () {
    const {
      stages = [],
      stagesPermission,
      t
    } = this.props
    if (stages.length === 0 || this.props.show === false) return <div />
    const localStages = stages
    const stageTooltip = (stageTextTooltip) => (
      <Tooltip id="editTooltip">{stageTextTooltip}</Tooltip>
    )
    return (
      <div className="time-line-header">
        {stagesPermission >= permissions.hidden && (
          <ul className={'time-line-popover ' + this.state.liClass}>
            {localStages.map((stage, idx) => {
              return (
                <OverlayTrigger
                  container={this}
                  key={idx}
                  trigger={['hover', 'focus']}
                  placement="top"
                  overlay={stageTooltip(
                    stage.stageDate
                      ? t('DOSSIER_COMPONENTS.HEADER.STAGE_DATE') + ' ' + stage.stageDate
                      : t('DOSSIER_COMPONENTS.HEADER.NOT_STAGE_DATE')
                  )}
                >
                  <li className={`cursorHelp   ${
                            stage.skipped
                              ? 'warning'
                              : stage.isActive
                              ? 'active'
                              : stage.isComplete
                              ? 'complete'
                              : ''
                            }`
                  } key={idx}>
                    {stage.skipped && stage.isComplete ? (
                      <Glyphicon
                        className="status-icon warning-complete"
                        glyph="record"
                      />
                    ) : (
                      <span
                        className={`status-icon ${
                            stage.skipped
                              ? 'warning'
                              : stage.isActive
                              ? 'active'
                              : stage.isComplete
                              ? 'complete'
                              : ''
                            }`
                        }
                      />
                    )}
                    <span className="name-ellipsis">{stage.name}</span>
                  </li>
                </OverlayTrigger>
              )
            })}
          </ul>
        )}
      </div>
    )
  }
}

export default TimeLine
