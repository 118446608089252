import { call, put, select, takeEvery } from 'redux-saga/effects'
import { getAuth } from '../../selectors/access/auth'
import getSameLevelOus from '../../services/organizedUnit/getSameLevelOus'
import commonActions from '../../actions/common'
import messageModalActions from '../../actions/modals/messageModal'
import { fetchCombosSuccess } from '../../actions/combos/combos'
import combosActionTypes from '../../constants/actions/combos/combos'
import { handleError } from '../errors/errorManager'
import { dossierSubTypeId as dossierSubTypeIdList } from '../../constants/dossier/common/dossierSubType'
import { fetchSalesDefaultDestination } from '../../actions/dossier/sales'
import { fetchPurchaseDefaultDestination } from '../../actions/dossier/purchase'

export function * fetchSameLevelOusCombo ({ organizedUnitId, dossierSubTypeId }) {
  try {
    yield put(commonActions.showLoader())
    const auth = yield select(getAuth)
    const combo = yield call(getSameLevelOus, auth.token, organizedUnitId)
    yield put(fetchCombosSuccess({ destinationCombo: combo.destinationList }))
    if (dossierSubTypeId === dossierSubTypeIdList.venta_vn || dossierSubTypeId === dossierSubTypeIdList.venta_vo) {
      yield put(fetchSalesDefaultDestination(combo.DefaultDestinationId ? combo.DefaultDestinationId : organizedUnitId))
    } else if (dossierSubTypeId === dossierSubTypeIdList.compra_vn || dossierSubTypeId === dossierSubTypeIdList.compra_vo) {
      yield put(fetchPurchaseDefaultDestination(combo.DefaultDestinationId ? combo.DefaultDestinationId : organizedUnitId))
    }
  } catch (error) {
    const sessionHandler = function * () {
    }
    const customHandler = function * () {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.FETCH_COMBO_ERROR'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchFetchSameLevelOusCombo () {
  yield takeEvery(combosActionTypes.FETCH_SAME_LEVEL_OUS_COMBO, fetchSameLevelOusCombo)
}
