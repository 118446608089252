import { put, call, select, takeEvery } from 'redux-saga/effects'
import { getAuth } from '../../selectors/access/auth'
import { getRoleFilter } from '../../selectors/admin/roleAdmin'
import postRoleList from '../../services/role/postRoleList'
import getRoleById from '../../services/role/getRoleById'
import putRoleById from '../../services/role/putRoleById'
import deleteRoleById from '../../services/role/deleteRoleById'
import postRole from '../../services/role/postRole'
import getSections from '../../services/section/sectionService'
import { showLoader, hideLoader } from '../../actions/common.js'
import { yesNoModal } from '../modalsListeners/yesNoModal'
import roleActionTypes from '../../constants/actions/admin/roleAdmin'
import { fetchRoleListSuccess, openEditRoleModalSuccess, openAddRoleModalSuccess, closeEditRoleModal } from '../../actions/admin/roleAdmin'
import { handleError } from '../errors/errorManager'
import cloneRoleById from '../../services/role/cloneRoleById'

export function * fetchRoleList ({ filter }) {
  try {
    yield put(showLoader())
    yield filter = yield filter || select(getRoleFilter)
    const auth = yield select(getAuth)
    const roleList = yield call(postRoleList, auth.token, filter)
    yield put(fetchRoleListSuccess(roleList, filter))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchRoleList () {
  yield takeEvery(roleActionTypes.FETCH_ROLE_ADMIN, fetchRoleList)
}

export function * openEditRoleModal ({ roleId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const role = yield call(getRoleById, auth.token, roleId)
    yield put(openEditRoleModalSuccess(role))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchOpenEditRoleModal () {
  yield takeEvery(roleActionTypes.OPEN_EDIT_ROLE_MODAL, openEditRoleModal)
}

export function * openAddRoleModal () {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const sectionPermissions = yield call(getSections, auth.token)
    yield put(openAddRoleModalSuccess(sectionPermissions))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchOpenAddRoleModal () {
  yield takeEvery(roleActionTypes.OPEN_ADD_ROLE_MODAL, openAddRoleModal)
}

export function * submitRole ({ body, roleId, filter }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const filter = yield filter || select(getRoleFilter)
    if (roleId) {
      yield call(putRoleById, auth.token, roleId, body)
    } else {
      yield call(postRole, auth.token, body)
    }
    yield put(closeEditRoleModal())
    const roleList = yield call(postRoleList, auth.token, filter)
    yield put(fetchRoleListSuccess(roleList, filter))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSubmitRole () {
  yield takeEvery(roleActionTypes.SUBMIT_ROLE, submitRole)
}

export function * deleteRole ({ roleId, filter }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const filter = yield filter || select(getRoleFilter)
    const response = yield call(deleteRoleById, auth.token, roleId)
    if (response) {
      yield put(hideLoader())
      const confirmation = yield call(yesNoModal, 'forceDeleteRole', null, null, null, null, [{ id: '##NUM_USERS##', value: response.message }])
      yield put(showLoader())
      if (confirmation) {
        yield call(deleteRoleById, auth.token, roleId, true)
      }
    }
    const roleList = yield call(postRoleList, auth.token, filter)
    yield put(fetchRoleListSuccess(roleList, filter))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDeleteRole () {
  yield takeEvery(roleActionTypes.DELETE_ROLE, deleteRole)
}

export function * cloneRole ({ roleId, filter }) {
  try {
    const auth = yield select(getAuth)
    const confirmation = yield call(yesNoModal, 'cloneRole')
    if (confirmation) {
      yield put(showLoader())
      yield call(cloneRoleById, auth.token, roleId)
    }
    const roleList = yield call(postRoleList, auth.token, filter)
    yield put(fetchRoleListSuccess(roleList, filter))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchCloneRole () {
  yield takeEvery(roleActionTypes.CLONE_ROLE, cloneRole)
}
