
import React, { PureComponent } from 'react'
import { Button, Modal, Col, Row, FormGroup } from 'react-bootstrap'
import { reduxForm, Field } from 'redux-form'
import InputCheckbox from '../../commons/form/InputCheckBox'
import InputText from '../../commons/form/InputText'

class operationTypeMasterModal extends PureComponent {
  constructor () {
    super()
    this.state = { isFleet: false }
  }

  submitOperationType (values) {
    let body = []
    Object.keys(values).map(item => {
      if (typeof values[item] === 'object') {
        body.push(values[item])
      }
    })
    const operationType = {
      operationTypeId: this.props.operationType.operationTypeId,
      operationTypeLocales: body,
      isFleet: values.isFleet,
      code: values.code
    }

    if (this.props.initialValues.operationType.code !== operationType.code || this.props.initialValues.operationType.isFleet !== operationType.isFleet) {
      this.props.actions.openModal('confirmUpdateOperationType', () => this.props.actions.saveOperationType(operationType))
    } else {
      this.props.actions.saveOperationType(operationType)
    }
  }

  getEntityPromise (value) {
    return new Promise((resolve, reject) => {
      return this.props.actions.fetchClientByDni(value, this.props.organizedUnitId, null, resolve, reject)
    })
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (prevProps.showModal !== this.props.showModal && this.props.showModal === true) {
      const operationType = this.props.operationType
      this.props.initialize(prevProps.operationType)
      this.props.languageList.map((language) => {
        this.props.change(`lang${language.languageId}.languageId`, language.languageId)
        const source = operationType && operationType.operationTypeLocales
          ? operationType.operationTypeLocales.find((sourceChannel) => sourceChannel.languageId === language.languageId)
          : null
        this.props.change(`lang${language.languageId}.name`, source?.name)
        this.props.change(`lang${language.languageId}.description`, source?.description)
        this.props.change('code', operationType?.code)
        this.props.change('isFleet', operationType?.isFleet ?? false)
      })
    }

    if (this.props.reactivatable !== prevProps.reactivatable) {
      if (!this.props.operationType.isFleet) {
        this.setState({ isFleet: false })
      } else {
        this.setState({ isFleet: this.props.operationType.isFleet })
      }
    }
    return prevProps
  }

  componentDidUpdate () {
    // don't remove use for getsnapshot
  }

  render () {
    const { t, showModal, handleSubmit, languageList = [] } = this.props
    const tKey = 'MASTERS'
    return (
      <Modal show={showModal} onHide={this.props.actions.closeOperationTypeModal}
        className="modal-operation-type-master languages-modal" backdrop="static">
        <Modal.Header closeButton>
          <i className="ico-users" /><Modal.Title>{t(`${tKey}.OPERATION_TYPE_MASTER.OPERATION_TYPE`)}</Modal.Title>
        </Modal.Header>
        <form autoComplete="off" onSubmit={handleSubmit(this.submitOperationType.bind(this))}>
          <Modal.Body >
            <Row className="title-column">
              <Col sm={4}>
                <h2>{t(`${tKey}.NAME`)}</h2>
              </Col>
              <Col sm={6}>
                <h2>{t(`${tKey}.DESCRIPTION`)}</h2>
              </Col>
              <Col sm={2}>
                <h2>{t(`${tKey}.LANGUAGE`)}</h2>
              </Col>
            </Row>
            <FormGroup>
              {languageList.map((val, idx) => {
                return (
                  <Row key={idx} className="inputs-wrapper">
                    <Col sm={4} className="custom-width-col">
                      <Field
                        name={`lang${val.languageId}.name`}
                        placeholder={t(`${tKey}.NAME`)}
                        component={InputText}
                        maxLength= {50}
                        type="text"
                      />
                    </Col>
                    <Col sm={6} className="custom-width-col">
                      <Field
                        name={`lang${val.languageId}.description`}
                        placeholder={t(`${tKey}.DESCRIPTION`)}
                        component={InputText}
                        maxLength= {50}
                        type="text"
                      />
                    </Col>
                    <Col sm={2} className="language-column">
                      <p>{`${val.description} (${val.name})`}</p>
                    </Col>
                  </Row>
                )
              })}
            </FormGroup>
            <Row className="form-group">
              <Col sm={4} className="col-operation-type-code custom-width-col">
                <Field
                  id="code"
                  name="code"
                  placeholder={t(`${tKey}.OPERATION_TYPE_MASTER.CODE`)}
                  component={InputText}
                  maxLength= {50}
                  type="text"
                />
              </Col>
              <Col sm={6}>
                <Field
                  component={InputCheckbox}
                  name= "isFleet"
                  id = "isFleet"
                  placeholder ={t(`${tKey}.OPERATION_TYPE_MASTER.IS_FLEET`)}
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Col sm={6} className="btn-wrapper" smPush={6}>
              <Button type="submit" className="btn-standard">
                {t(`${tKey}.SAVE`)}
              </Button>
            </Col>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}
const validate = (values, props) => {
  const errors = { }
  props.languageList.map(language => {
    errors[`lang${language.languageId}`] = {}
    if (values[`lang${language.languageId}`]) {
      if (!(values[`lang${language.languageId}`].name)) {
        errors[`lang${language.languageId}`].name = props.t('MASTERS.VALIDATION.NAME_REQUIRED')
      }
      if (!(values[`lang${language.languageId}`].description)) {
        errors[`lang${language.languageId}`].description = props.t('MASTERS.VALIDATION.DESCRIPTION_REQUIRED')
      }
    } else {
      errors[`lang${language.languageId}`].name = props.t('MASTERS.VALIDATION.NAME_REQUIRED')
      errors[`lang${language.languageId}`].description = props.t('MASTERS.VALIDATION.DESCRIPTION_REQUIRED')
    }
  })
  if (!values.code) { errors.code = props.t('MASTERS.VALIDATION.CODE_REQUIRED') }
  return errors
}

export default reduxForm({
  form: 'operationTypeMasterModal',
  validate
})(operationTypeMasterModal)
