import React, { PureComponent } from 'react'
import { Row, Col } from 'react-bootstrap'

class TabPane1 extends PureComponent {
  render () {
    return (
      <Row className="tabs-wrapper">
        <Row>
          <Col sm={4}>
            <div className="text-band">
              <span className="first">Empresa/concesión:</span>
              <span className="second number-modal">Empresa/concesión</span>
            </div>
          </Col>
          <Col sm={4}>
            <div className="text-band">
              <span className="first"> Ubicación:</span>
              <span className="second number-modal">Ubicación</span>
            </div>
          </Col>
          <Col sm={4}>
            <div className="text-band">
              <span className="first">BOX:</span>
              <span className="second number-modal">BOX</span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={4}>
            <div className="text-band">
              <span className="first">Servicio logístico:</span>
              <span className="second number-modal">Servicio logístico</span>
            </div>
          </Col>
        </Row>
      </Row>
    )
  }
}

export default TabPane1
