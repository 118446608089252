import React, { PureComponent } from 'react'
import { Panel, Button } from 'react-bootstrap'
import SmallLoaderPage from '../../../../containers/commons/SmallLoaderPage'
import { compareObjects, getDateNowMaxDatePicker } from '../../../../util/utils'
import { getFieldsPermisions } from '../../../../util/permisionFunctions'
import { Field } from 'redux-form'
import { permissions } from '../../../../constants/backendIds'
import InputDatePicker from '../../../../_v2/components/commons/form/InputDatePicker'
import InputSliderButton from '../../../../_v2/components/commons/form/InputSliderButton'
import ComponentTitle from '../../../../_v2/containers/dossiers/commons/ComponentTitlePage'

class Reception extends PureComponent {
  constructor () {
    super()
    this.state = {
      open: false,
      fieldsConfiguration: {
        prewash: null,
        confirmationDoneButton: null,
        confirmationDate: null
      }
    }
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (this.props.dossier && this.props.dossier.dossierId) {
      if (!compareObjects(prevProps.componentConfiguration || {}, this.props.componentConfiguration, true)) {
        this.initializePermisions(this.props)
      }
      if (this.props.open) {
        this.setState({ open: true })
      }
    }
    return prevProps
  }

  componentDidMount () {
    this.initializePermisions(this.props)
  }

  componentDidUpdate () {
    // don't remove use for getsnapshot
  }

  initializePermisions (props) {
    const fieldKeys = Object.keys(this.state.fieldsConfiguration)
    const newState = getFieldsPermisions(props.componentConfiguration.fieldsConfiguration, fieldKeys, props.componentConfiguration.permission)
    this.setState({ fieldsConfiguration: newState.fieldsConfiguration })
  }

  toggleComponent () {
    if (this.state.open) {
      this.props.actions.setActiveSection(false)
    }
    this.setState({ open: !this.state.open })
  }

  showServiceObservationModal (show, service) {
    this.setState({ showModal: show, service: service })
  }

  render () {
    const {
      t, readOnlyFields, dossier: { reception }, actions: { confirmDateWithIdByStage }, dossierId, formValues, pristine
    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.RECEPTION.'

    return (
      <div className="dossier-Component">
        <div className="header-panel" onClick={() => this.toggleComponent()}>
          <ComponentTitle
            componentCode="RECEPTION"
            componentOpen={this.state.open}
            // extraInfo={'info extra'}
            // extraData={<a className="_Btn accept">texto</a>}
          />
        </div>
        <Panel expanded={this.state.open} onToggle={() => { }}>
          <Panel.Collapse>

            <div className='fieldsContainer '>
              { this.state.fieldsConfiguration.confirmationDoneButton && this.state.fieldsConfiguration.confirmationDoneButton.permission === permissions.editable && !readOnlyFields && !formValues.confirmationDate && (
                <div className="flexInLine al-R">
                  <Button className='_Btn new' onClick={() => confirmDateWithIdByStage(dossierId, null, pristine)} disabled={this.state.fieldsConfiguration.confirmationDoneButton.permission !== permissions.editable}>
                    {t(`${tKey}DONE_BUTTON`)}
                  </Button>
                </div>
              )}
            </div>
            <div className='fieldsContainer'>
              {this.state.fieldsConfiguration.prewash && this.state.fieldsConfiguration.prewash.permission !== permissions.hidden && (
                <Field
                  component={InputSliderButton}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="prewash"
                  name="reception.prewash"
                  colSm={3}
                  controlLabel={t(`${tKey}PREWASH`)}
                  placeholder={t(`${tKey}PREWASH`)}
                  textDescription={t(`${tKey}PREWASH`)}
                  disabled={readOnlyFields || this.state.fieldsConfiguration.prewash.permission === permissions.readOnly }
                  customClass={'three-rc-select'}
                  defaultChoice={reception ? reception.prewash : null}
                />
              )}
              { this.state.fieldsConfiguration.confirmationDate && this.state.fieldsConfiguration.confirmationDate.permission !== permissions.hidden && (
                <Field
                  component={InputDatePicker}
                  colWidht={75} // 25, 33, 50, 75, 100 default=25
                  maxDate={getDateNowMaxDatePicker()}
                  id="confirmationDate"
                  name="reception.confirmationDate"
                  controlLabel={t(`${tKey}CONFIRMATION_DATE`)}
                  placeholder={t(`${tKey}CONFIRMATION_DATE`)}
                  disabled={readOnlyFields || this.state.fieldsConfiguration.confirmationDate.permission === permissions.readOnly }
                  customClass={readOnlyFields || this.state.fieldsConfiguration.confirmationDate.permission === permissions.readOnly ? 'disabled' : ''}
                />
              )}
            </div>
          </Panel.Collapse>
        </Panel>
        <SmallLoaderPage />
      </div>
    )
  }
}
export default Reception
