import apiFetch from '../apiFetch'

export default function (token, { page, pageSize, orderBy, salesmanIndividual, creationDateFrom, creationDateTo, dossierNumberFrom, dossierNumberTo, fleetCode, rentalOrderNumber, deliveryCity, pickPreviousVehicle, salesmanManagerIndividual }) {
  const queryParamsArray = [
    page ? `page=${page}` : null,
    pageSize ? `pageSize=${pageSize}` : null,
    orderBy ? `orderBy=${orderBy}` : null,
    salesmanIndividual ? `salesmanId=${salesmanIndividual}` : null,
    creationDateFrom ? `creationDateFrom=${creationDateFrom}` : null,
    creationDateTo ? `creationDateTo=${creationDateTo}` : null,
    dossierNumberFrom ? `dossierNumberFrom=${dossierNumberFrom}` : null,
    dossierNumberTo ? `dossierNumberTo=${dossierNumberTo}` : null,
    fleetCode ? `fleetCode=${fleetCode}` : null,
    rentalOrderNumber ? `rentalOrderNumber=${rentalOrderNumber}` : null,
    deliveryCity ? `deliveryCity=${deliveryCity}` : null,
    pickPreviousVehicle === 0 || pickPreviousVehicle === 1 ? `pickPreviousVehicle=${pickPreviousVehicle}` : null,
    salesmanManagerIndividual ? `salesmanManager=${salesmanManagerIndividual}` : null
  ]
  const queryParams = queryParamsArray.reduce(
    (result, query, idx) => result + (query ? (result ? `&${query}` : `?${query}`) : '')
    , ''
  )
  return apiFetch({
    endPoint: `DossierFinder/Fleets${queryParams}`,
    method: 'GET',
    body: null,
    token: token
  })
}
