import mastersActionTypes from '../../../constants/actions/masters/masters'

export function fetchDocumentTypeList (filter) {
  return {
    type: mastersActionTypes.FETCH_DOCUMENT_TYPE_LIST,
    filter
  }
}

export function fetchDocumentTypeListSuccess (documentTypeList, filter) {
  return {
    type: mastersActionTypes.FETCH_DOCUMENT_TYPE_LIST_SUCCESS,
    documentTypeList,
    filter
  }
}

export function openDocumentTypeModalSuccess (languageList, documentType) {
  return {
    type: mastersActionTypes.OPEN_DOCUMENT_TYPE_MODAL_SUCCESS,
    languageList,
    documentType
  }
}

export function closeDocumentTypeMasterModal () {
  return {
    type: mastersActionTypes.CLOSE_EDIT_DOCUMENT_TYPE_MODAL
  }
}

export function deleteDocumentType (id) {
  return {
    type: mastersActionTypes.DELETE_DOCUMENT_TYPE,
    id
  }
}

export function openDocumentTypeModal (documentType) {
  return {
    type: mastersActionTypes.OPEN_DOCUMENT_TYPE_MODAL,
    documentType
  }
}

export function submitDocumentType (documentType) {
  return {
    type: mastersActionTypes.SUBMIT_DOCUMENT_TYPE,
    documentType
  }
}
