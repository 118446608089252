import windowDimensionsActionTypes from '../../constants/actions/commons/windowDimensions'

export function setWindowDimensions (width, height) {
  return {
    type: windowDimensionsActionTypes.SET_WINDOW_DIMENSIONS,
    width,
    height
  }
}

export default {
  setWindowDimensions
}
