import { call, put, select, takeEvery } from 'redux-saga/effects'
import { getAuth } from '../../selectors/access/auth'

import auxModalsFleetsAction from '../../constants/actions/management/auxModals/auxModals'
import { handleError } from '../errors/errorManager'
import { showLoader, hideLoader } from '../../actions/common.js'
import { fetchDossiersToAddToFleetSuccess } from '../../actions/management/auxModals/auxModals'
import postFindDossiersToAddFleet from '../../services/fleets/postFindDossiersToAddFleet'
import postAddDossiersToFleet from '../../services/fleets/postAddDossiersToFleet'
import getDossierDetailsFleet from '../../services/fleets/getDossierDetailsFleet'
import { getDossierDetailsFleetSuccess } from '../../actions/management/management'
import { convertStringToDate, convertUTCDateToLocalDate } from '../../util/utils'

export function * fetchDossiersToAddFleet ({ filters }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    let filtersDateFormatted = { ...filters }
    if (filtersDateFormatted.creationDateFrom) {
      filtersDateFormatted.creationDateFrom = convertUTCDateToLocalDate(convertStringToDate(filtersDateFormatted.creationDateFrom))
    }
    if (filtersDateFormatted.creationDateTo) {
      filtersDateFormatted.creationDateTo = convertUTCDateToLocalDate(convertStringToDate(filtersDateFormatted.creationDateTo))
    }

    const result = yield call(postFindDossiersToAddFleet, auth.token, filtersDateFormatted)
    yield put(fetchDossiersToAddToFleetSuccess(result, filters))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchDossiersToAddFleet () {
  yield takeEvery(auxModalsFleetsAction.FETCH_DOSSIERS_TO_ADD_TO_FLEET, fetchDossiersToAddFleet)
}

export function * fetchAddDossiersToFleet ({ dossierId, fleetId, resolve, reject }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const result = yield call(postAddDossiersToFleet, auth.token, { fleetId }, dossierId)

    const data = yield call(getDossierDetailsFleet, auth.token, { fleetId })
    yield put(getDossierDetailsFleetSuccess(data))

    if (resolve) yield call(resolve, result)
  } catch (error) {
    if (reject) {
      yield call(reject, error)
    }
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchAddDossiersToFleet () {
  yield takeEvery(auxModalsFleetsAction.FETCH_ADD_DOSSIER_TO_FLEET, fetchAddDossiersToFleet)
}
