import React, { PureComponent } from 'react'
import { urgencyTypesId } from '../../../../constants/backendIds'
import StageBarSection from './StageBarSection'

class StageBarChart extends PureComponent {
  constructor (props) {
    super(props)
    const percentages = this.heightPercentage(props.stage)
    this.state = {
      low: percentages.lowP,
      medium: percentages.mediumP,
      high: percentages.highP
    }
  }

  heightPercentage (stage) {
    const stageState = [stage.low, stage.medium, stage.high]
    const total = stageState.reduce((prev, current) => prev + current)
    if (total) {
      const percentages = stageState.map(num => Math.round((num / total * 100)))
      const findMin = percentages.filter(num => num < 20 && num !== 0)
      const result = percentages.map(num => {
        if (num === 0) return num
        if (findMin.length === 2) {
          return num < 20 ? 20 : 60
        } else if (findMin.length === 1) {
          return num < 20 ? 20 : Math.round(num * 80 / (100 - findMin[0]))
        } else {
          return num
        }
      })
      return {
        lowP: `${result[0]}%`,
        mediumP: `${result[1]}%`,
        highP: `${result[2]}%`
      }
    } else {
      return {
        lowP: `${stageState[0]}%`,
        mediumP: `${stageState[1]}%`,
        highP: `${stageState[2]}%`
      }
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.stage !== this.props.stage) {
      const percentages = this.heightPercentage(nextProps.stage)
      this.setState({
        low: percentages.lowP,
        medium: percentages.mediumP,
        high: percentages.highP
      })
    }
  }

  render () {
    const {
      idx,
      stage: { name, hasPermission, low, medium, high, stageId },
      history, salesmanId, subTypeId, barWidth, urgencyLowLabel, urgencyMediumLabel, urgencyHighLabel, isLastStage
    } = this.props

    return (
      <div className={`bar-wrapper${hasPermission ? '' : ' disabled-bar'}`}
        style={{ width: barWidth + '%' }}>
        <div className="bar-item">
          <StageBarSection
            salesmanId={salesmanId}
            subTypeId={subTypeId}
            stageId={stageId}
            history={history}
            heightPercentage={this.state.low}
            number={low}
            urgencyLabel={urgencyLowLabel}
            urgencyTypeId={urgencyTypesId.low}
            colorClass={'bar green'}
            isLastStage={isLastStage}
          />
          <StageBarSection
            salesmanId={salesmanId}
            subTypeId={subTypeId}
            stageId={stageId}
            history={history}
            heightPercentage={this.state.medium}
            number={medium}
            urgencyLabel={urgencyMediumLabel}
            urgencyTypeId={urgencyTypesId.medium}
            colorClass={'bar yellow'}
            isLastStage={isLastStage}
          />
          <StageBarSection
            salesmanId={salesmanId}
            subTypeId={subTypeId}
            stageId={stageId}
            history={history}
            heightPercentage={this.state.high}
            number={high}
            urgencyLabel={urgencyHighLabel}
            urgencyTypeId={urgencyTypesId.high}
            colorClass={'bar red'}
            isLastStage={isLastStage}
          />
        </div>
        <p className={idx % 2 === 0 ? 'stage-even' : 'stage-odd'} onClick={() => history.push(`dashboard/${salesmanId || 0}/${0}/${subTypeId}/${stageId}`)}>{name}</p>
        <p className={idx % 2 === 0 ? 'total-even' : 'total-odd'}>({low + medium + high})</p>
      </div>
    )
  }
}

export default StageBarChart
