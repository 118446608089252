import putSendDMS from '../../../../services/charges/dms/putSendDMS'
import { put, call, takeEvery, select } from 'redux-saga/effects'
import { getAuth } from '../../../../selectors/access/auth'
import { yesNoModal } from '../../../modalsListeners/yesNoModal'
import { showLoader, hideLoader } from '../../../../actions/common'
import { handleError } from '../../../errors/errorManager'
import dms from '../../../../constants/actions/dossier/common/dms'
import {
  closeAddOrEditDocumentModalSuccess
} from '../../../../actions/dossier/common/documentEditionModal'

export function * sendDMS ({ dossierId, resolve }) {
  try {
    const auth = yield select(getAuth)
    const confirmed = yield call(yesNoModal, 'sendDMS')
    if (confirmed) {
      yield put(showLoader())
      yield call(putSendDMS, dossierId, auth.token)
      yield put(closeAddOrEditDocumentModalSuccess())
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSendDMS () {
  yield takeEvery(dms.SEND_DMS, sendDMS)
}
