import React, { PureComponent } from 'react'
import { Panel, Row } from 'react-bootstrap'
import InputSelect from '../../../../_v2/components/commons/form/InputSelect'
import InputText from '../../../../_v2/components/commons/form/InputText'
import InputDatePicker from '../../../../_v2/components/commons/form/InputDatePicker'
import { Field } from 'redux-form'
import { permissions } from '../../../../constants/backendIds'
import { getFieldsPermisions } from '../../../../util/permisionFunctions'
import { compareObjects } from '../../../../util/utils'
import CustomFields from '../../../../components/commons/customFields/CustomFields'
import ComponentTitle from '../../../../_v2/containers/dossiers/commons/ComponentTitlePage'

class Brokerage extends PureComponent {
  constructor () {
    super()
    this.state = {
      open: false,
      loaded: false,
      fieldsConfiguration: {
        brokerageContact: null,
        brokerageUo: null,
        brokerageCompanyName: null,
        brokeragePolicyNumber: null,
        brokerageStartDate: null
      },
      showNoContactReason: false
    }
  }

  initializePermisions (props) {
    const fieldKeys = Object.keys(this.state.fieldsConfiguration)
    const newState = getFieldsPermisions(props.componentConfiguration.fieldsConfiguration, fieldKeys, props.componentConfiguration.permission)
    this.setState({ ...this.state, ...newState })
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (this.props.open) {
      this.setState({ open: true })
    }
    if (prevProps.componentConfiguration && this.props.componentConfiguration !== null) {
      if (!compareObjects(prevProps.componentConfiguration || {}, this.props.componentConfiguration, true)) {
        this.initializePermisions(this.props)
      }
    }
    return null
  }

  componentDidMount () {
    if (this.props.componentConfiguration && this.props.componentConfiguration !== null) {
      this.initializePermisions(this.props)
      this.setState({
        showNoContactReason: this.props.dossier.dossierInsuranceCarrierComponent.contact === false
      })
    }
  }

  componentDidUpdate () {
    if (this.state.loaded) {
      this.setState({ loaded: false })
      if (this.props.historical === false) {
        return new Promise((resolve, reject) => {
          this.props.handleToUpdateComponent(this.props)
          return this.props.actions.fetchSalesInsuranceCarrier(this.props.dossierId, resolve, reject, this.props.pristine)
        })
      } else {
        return new Promise((resolve, reject) => {
          this.props.handleToUpdateComponent(this.props)
          return this.props.actions.fetchSalesInsuranceCarrierHistorical(this.props.dossierId, resolve, reject)
        })
      }
    }
  }

  fetchSalesDossierPromiseBrokerage () {
    if (this.state.open) {
      this.props.actions.setActiveSection(false)
    }
    this.setState({
      open: !this.state.open
    })
  }

  getYesNoComboOptions () {
    const tKey = 'SEARCH.CATEGORIES.DOSSIER.'
    return [
      { value: this.props.t(`${tKey}YES`), id: true },
      { value: this.props.t(`${tKey}NO`), id: false }]
  }

  render () {
    const { t, readOnlyFields, customFields, combos: { insuranceCarrierCombo } } = this.props
    const tKey = 'DOSSIER_COMPONENTS.BROKERAGE.'
    return (
      <Row className='dossier-Component'>
        <div className='header-panel' onClick={this.fetchSalesDossierPromiseBrokerage.bind(this)}>
          <ComponentTitle
            componentCode="BROKERAJE"
            componentOpen={this.state.open}
          />
        </div>
        <Panel expanded={this.state.open} onToggle={() => {}}>
          <Panel.Collapse>
            <div className='fieldsContainer'>
              {this.state.fieldsConfiguration?.brokerageContact?.permission !== permissions.hidden && (
                <Field
                  component={InputSelect}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id='contact'
                  name='dossierInsuranceCarrierComponent.contact'
                  controlLabel={t(`${tKey}CONTACT`)}
                  placeholder={t(`${tKey}CONTACT`)}
                  options={this.getYesNoComboOptions()}
                  valueKey="id"
                  labelKey="value"
                  disabled={readOnlyFields}
                  onChange={(el, value) => this.setState({ showNoContactReason: value != null && !value })}
                />
              )}
              { this.state.showNoContactReason && (
                <Field
                  component={InputText}
                  colWidht={75} // 25, 33, 50, 75, 100 default=25
                  id="noContactReason"
                  name="dossierInsuranceCarrierComponent.noContactReason"
                  controlLabel={t(`${tKey}NO_CONTACT_REASON`)}
                  placeholder={t(`${tKey}NO_CONTACT_REASON`)}
                  disabled={readOnlyFields}
                />
              )}
              {this.state.fieldsConfiguration?.brokerageUo?.permission !== permissions.hidden && (
                <Field
                  component={InputSelect}
                  colWidht={100} // 25, 33, 50, 75, 100 default=25
                  id='insuranceCarrierId'
                  name='dossierInsuranceCarrierComponent.insuranceCarrierId'
                  controlLabel={t(`${tKey}INSURANCECARRIER`)}
                  placeholder={t(`${tKey}INSURANCECARRIER`)}
                  options={insuranceCarrierCombo}
                  disabled={readOnlyFields}
                  valueKey='id'
                  labelKey='value'
                />
              )}
              {this.state.fieldsConfiguration?.brokerageCompanyName?.permission !== permissions.hidden && (
                <Field
                  component={InputText}
                  colWidht={33} // 25, 33, 50, 75, 100 default=25
                  id="companyName"
                  key="companyName"
                  name="dossierInsuranceCarrierComponent.companyName"
                  colSm={4}
                  controlLabel={t(`${tKey}COMPANYNAME`)}
                  placeholder={t(`${tKey}COMPANYNAME`)}
                  disabled={readOnlyFields}
                />
              )}
              {this.state.fieldsConfiguration?.brokeragePolicyNumber?.permission !== permissions.hidden && (
                <Field
                  component={InputText}
                  colWidht={33} // 25, 33, 50, 75, 100 default=25
                  id="policyNumber"
                  key="policyNumber"
                  name="dossierInsuranceCarrierComponent.policyNumber"
                  colSm={4}
                  controlLabel={t(`${tKey}POLICYNUMBER`)}
                  placeholder={t(`${tKey}POLICYNUMBER`)}
                  disabled={readOnlyFields}
                />
              )}
              {this.state.fieldsConfiguration?.brokerageStartDate?.permission !== permissions.hidden && (
                <Field
                  component={InputDatePicker}
                  colWidht={33} // 25, 33, 50, 75, 100 default=25
                  id="startDate"
                  key="startDate"
                  name="dossierInsuranceCarrierComponent.startDate"
                  colSm={4}
                  controlLabel={t(`${tKey}STARTDATE`)}
                  placeholder={t(`${tKey}STARTDATE`)}
                  disabled={readOnlyFields}
                />
              )}
            </div>
            <CustomFields customFields={customFields} readOnlyFields={readOnlyFields} />
          </Panel.Collapse>
        </Panel>
      </Row>
    )
  }
}
export default Brokerage
