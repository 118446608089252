import apiFetch from '../apiFetch'

export default function (token, organizedUnitId, phone, email) {
  const queryParamsArray = [
    organizedUnitId ? `organizedUnitId=${organizedUnitId}` : null,
    phone ? `phone=${phone}` : null,
    email ? `email=${email}` : null

  ]
  const queryParams = queryParamsArray.reduce(
    (result, query, idx) => result + (query ? (result ? `&${query}` : `?${query}`) : '')
    , ''
  )
  return apiFetch({ endPoint: `Entity/SearchMultipleEntityDms${queryParams}`, method: 'GET', body: null, token: token })
}
