import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PresentsMasters from '../../../components/masters/presents/PresentsMasters'
import { translate } from 'react-polyglot'
import { openPresentsModal, deletePresentsModal, recoverPresentsModal, fetchPresentsMasters } from '../../../actions/masters/masters'
import { fetchOrganizedUnitsTree } from '../../../actions/combos/combos'

export function mapStateToProps (state) {
  return {
    combos: {
      UOTreeCombo: state.combos.UOTreeCombo
    },
    presentsMastersList: state.entityMasters.presentsMastersList
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      openPresentsModal,
      deletePresentsModal,
      fetchPresentsMasters,
      recoverPresentsModal,
      fetchOrganizedUnitsTree
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(PresentsMasters))
