import React, { PureComponent } from 'react'
import { Nav, NavItem, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import MapSections from '../../util/map-sections'
import settings from '../../setting'
import sidebarConstant from '../../constants/sidebar'

class Sidebar extends PureComponent {
  constructor () {
    super()
    this.state = {
      navItemActive: 1,
      userRegisterCount: 0,
      signCount: 0
    }
    this.onSelect.bind(this)
  }

  componentWillMount () {
    let existPermissionSectionIDocSign = this.props.sections && this.props.sections.find(function (element) {
      return element.code === 'iDocSign'
    })

    if (existPermissionSectionIDocSign) {
      return new Promise((resolve, reject) => {
        this.props.actions.getDocumentsCount(0, resolve, reject)
      }).then((result) => {
        this.setState({ signCount: result })
      })
    }
  }

  onSelect (eventKey, section, isJiraServiceDeskButton) {
    if (!isJiraServiceDeskButton) {
      this.setState({ navItemActive: eventKey })
      this.props.actions.resetFilters()
      this.props.actions.setCurrentSection(section)
    } else {
      window.open(settings.serviceDeskUrl, '_blank')
    }
  }

  render () {
    const { sections = [], stateSignCount } = this.props
    const sectionsTemp = sections.slice()
    const t = this.props.t
    return (
      <Nav bsStyle="pills" stacked activeKey={this.state.navItemActive} className={'nav-side ' + (settings.qaSite ? 'qa' : '') + ' ' + (settings.clientFieldValidation ? settings.clientFieldValidation : '')}>
        {sectionsTemp &&
          sectionsTemp.map((section, idx) => {
            const isJiraServiceDeskButton = section.code === sidebarConstant.jiraServiceDesk
            const isJiraIDocSignButton = section.code === sidebarConstant.iDocSign
            return (
              <OverlayTrigger key={idx} placement="right" overlay={<Tooltip id="tooltipMenu">{t(`${MapSections[section.code].title}`)}</Tooltip>}>
                { !isJiraServiceDeskButton
                  ? <LinkContainer key={idx} to={`/${section.code}`}>
                    <NavItem id={MapSections[section.code].id} className= {isJiraServiceDeskButton ? 'navItem-Sidebar-JiraServiceDesk' : 'navItem-Sidebar'} eventKey={idx} onClick={() => { this.onSelect(idx, section, isJiraServiceDeskButton) }}>
                      <i className={MapSections[section.code].icon}/>
                      <span className="tooltipNavSide">{t(`${MapSections[section.code].title}`)}</span>
                      {isJiraIDocSignButton &&
                      <div className='bubble-msg-wrapper'>
                        <div className={`bubble-msg${stateSignCount > 0 ? '' : ' empty'}`}>
                          <span>{stateSignCount || '0'}</span>
                        </div>
                      </div>
                      }
                    </NavItem>
                  </LinkContainer>
                  : <NavItem id={MapSections[section.code].id} className= {isJiraServiceDeskButton ? 'navItem-Sidebar-JiraServiceDesk' : 'navItem-Sidebar'} eventKey={idx} onClick={() => { this.onSelect(idx, section, isJiraServiceDeskButton) }}>
                    <i className={MapSections[section.code].icon}/>
                    {isJiraIDocSignButton &&
                      <div className='bubble-msg-wrapper'>
                        <div className={`bubble-msg${stateSignCount > 0 ? '' : ' empty'}`}>
                          <span>{stateSignCount || '0'}</span>
                        </div>
                      </div>
                    }
                  </NavItem>
                }
              </OverlayTrigger>
            )
          })
        }
      </Nav>
    )
  }
}

export default Sidebar
