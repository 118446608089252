import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { closeMetadataListMasterModal, submitMetadataList, deleteMetadataListElement } from '../../../actions/masters/metadata/metadata'
import MetadataListMasterModal from '../../../components/masters/metadata/MetadataListMasterModal'
import { getFormValues } from 'redux-form'

export function mapStateToProps (state) {
  const metadataListModal = state.documentTypeMetadata.metadataListModal
  return {
    ...metadataListModal,
    combos: {
      documentTypess: state.combos.documentTypes
    },
    formValues: getFormValues('editMetadataList')(state)
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      submitMetadataList,
      closeMetadataListMasterModal,
      deleteMetadataListElement
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(MetadataListMasterModal))
