export default {
  GET_ALL_AVAILABLE_DOCUMENT_LANDING_REQUEST: 'GET_ALL_AVAILABLE_DOCUMENT_LANDING_REQUEST',
  GET_ALL_AVAILABLE_DOCUMENT_LANDING_REQUEST_SUCCESS: 'GET_ALL_AVAILABLE_DOCUMENT_LANDING_REQUEST_SUCCESS',
  GET_DOCUMENT_LANDING_REQUEST: 'GET_DOCUMENT_LANDING_REQUEST',
  GET_DOCUMENT_LANDING_REQUEST_SUCCESS: 'GET_DOCUMENT_LANDING_REQUEST_SUCCESS',
  SEND_LANDING_REQUEST_TO_CLIENT: 'SEND_LANDING_REQUEST_TO_CLIENT',
  SEND_LANDING_REQUEST_TO_CLIENT_SUCCESS: 'SEND_LANDING_REQUEST_TO_CLIENT_SUCCESS',
  SEND_LANDING_CANCEL_REQUEST: 'SEND_LANDING_CANCEL_REQUEST',
  SEND_LANDING_CANCEL_REQUEST_SUCCESS: 'SEND_LANDING_CANCEL_REQUEST_SUCCESS'

}
