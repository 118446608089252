import React, { PureComponent } from 'react'
import SmallLoaderPage from '../../../containers/commons/SmallLoaderPage'

class Img extends PureComponent {
  constructor () {
    super()
    this.state = {
      defaultSrc: '/images/img-placeholder.png',
      hasError: false
    }
    this.onLoad.bind(this)
    this.onError.bind(this)
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (prevProps.src !== this.props.src) {
      return {
        src: this.props.src,
        isLoading: this.props.isLoading
      }
    }
    return null
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (snapshot) {
      if (!snapshot.isLoading) this.props.actions.showSmallLoader()
      this.setState({ hasError: false })
    }
  }

  onLoad () {
    this.props.actions.hideSmallLoader()
  }

  onError () {
    this.props.actions.hideSmallLoader()
    this.setState({ hasError: true })
  }

  componentDidMount () {
    this.props.actions.showSmallLoader()
  }

  componentWillUnmount () {
    this.props.actions.hideSmallLoader()
  }

  render () {
    const { src, onClick, isLoading, className = '', active = '' } = this.props
    return (
      <div className={`img-container ${className} ${active}`}>
        <img
          className={`${this.state.hasError ? 'has-error' : ''} ${isLoading ? 'hidden' : ''}`}
          src={this.state.hasError ? this.state.defaultSrc : src}
          onClick={onClick}
          onLoad={() => this.onLoad()}
          onError={() => this.onError()}
        />
        <SmallLoaderPage background='none'/>
      </div>
    )
  }
}

export default Img
