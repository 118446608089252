import dossierModalActionTypes from '../../../constants/actions/dossier/dossier'
import commentsActionTypes from '../../../constants/actions/dossier/common/comments'
import auditInfoTypes from '../../../constants/actions/audit/auditInfo'

export function initialState () {
  return {
    dossierId: null,
    dossierSubTypeId: null,
    dossierParentId: null,
    dossierParentSubTypeId: null,
    parentIsHistorical: null,
    organizedUnitId: null,
    entityChangeable: true,
    isMarked: false,
    header: {},
    checkListHeader: [],
    viewConfiguration: {},
    dossierPaperworkComponent: {},
    entityComponent: {},
    entityProviderComponent: {},
    entityChildComponent: {},
    dossierContactComponent: {},
    vehicleComponent: {},
    dossierKm: null,
    comments: [],
    dossierRelatedDossiers: [],
    auditInfo: [],
    lastAccessDate: null,
    unreadComments: null,
    reason: null,
    comment: null,
    cancelCombo: [],
    customFieldsByComponent: [],
    customFieldsValues: [],
    customFields: {}
  }
}

function fetchPaperworkDossierSuccess (state, { dossier, checkListHeader }) {
  return {
    ...state,
    dossierId: dossier.dossierId || state.dossierId,
    dossierSubTypeId: dossier.dossierSubTypeId || state.dossierSubTypeId,
    dossierParentId: dossier.dossierParentId || dossier.dossierParentId === null ? dossier.dossierParentId : state.dossierParentId,
    dossierParentSubTypeId: dossier.dossierParentSubTypeId || state.dossierParentSubTypeId,
    parentIsHistorical: dossier.parentIsHistorical || state.parentIsHistorical,
    isMarked: (dossier.isMarked !== null && dossier.isMarked !== undefined ? dossier.isMarked : state.isMarked),
    organizedUnitId: dossier.organizedUnitId || state.organizedUnitId,
    entityChangeable: dossier.entityChangeable || state.entityChangeable,
    header: dossier.dossierHeaderComponent || state.header,
    checkListHeader: checkListHeader || state.checkListHeader,
    viewConfiguration: dossier.viewConfiguration || state.viewConfiguration,
    dossierPaperworkComponent: dossier.dossierPaperworkComponent || state.dossierPaperworkComponent,
    entityComponent: dossier.entityComponent || state.entityComponent,
    entityProviderComponent: dossier.entityProviderComponent || state.entityProviderComponent,
    entityChildComponent: dossier.entityChildComponent || state.entityChildComponent,
    dossierContactComponent: dossier.dossierContactComponent || state.dossierContactComponent,
    vehicleComponent: {
      ...(dossier.vehicleComponent || state.vehicleComponent),
      previousUsageId: dossier.previousUsageId || state.vehicleComponent.previousUsageId,
      makerNumber: dossier.makerNumber || state.vehicleComponent.makerNumber || (dossier.dossierVehicleComponent ? dossier.dossierVehicleComponent.makerNumber : null) || (dossier.vehicleComponent ? dossier.vehicleComponent.makerNumber : null),
      availabilityDate: dossier.availabilityDate || state.vehicleComponent.availabilityDate || (dossier.dossierVehicleComponent ? dossier.dossierVehicleComponent.availabilityDate : null) || (dossier.vehicleComponent ? dossier.vehicleComponent.availabilityDate : null)
    },
    comments: dossier.dossierCompleteComment || state.comments,
    dossierRelatedDossiers: dossier.dossierRelatedDossiers || state.dossierRelatedDossiers,
    auditInfo: dossier.auditInfo || state.auditInfo,
    lastAccessDate: dossier.lastAccessDate || state.lastAccessDate,
    unreadComments: dossier.unreadComments >= 0 ? dossier.unreadComments : state.unreadComments,
    reason: dossier.reason || state.reason,
    comment: dossier.comment || state.comment,
    cancelCombo: dossier.cancelCombo || state.cancelCombo,
    customFieldsValues: dossier.customFields || state.customFieldsValues
  }
}

function fetchCommentsSuccess (state, { comments }) {
  return {
    ...state,
    comments
  }
}

function fetchDossierRelatedDossiersSuccess (state, { dossierRelatedDossiers }) {
  return {
    ...state,
    dossierRelatedDossiers
  }
}

function fetchAuditInfoSucces (state, { auditInfo }) {
  return {
    ...state,
    auditInfo
  }
}

function fetchCustomFieldsSuccess (state, { value }) {
  return {
    ...state,
    customFieldsByComponent: value
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case dossierModalActionTypes.FETCH_PAPERWORK_DOSSIER_SUCCESS:
      return fetchPaperworkDossierSuccess(state, action)
    case commentsActionTypes.FETCH_PAPERWORK_COMMENTS_SUCCESS:
      return fetchCommentsSuccess(state, action)
    case dossierModalActionTypes.RESTART_PAPERWORK_STATE:
      return initialState()
    case dossierModalActionTypes.FETCH_DOSSIER_RELATED_DOSSIERS_SUCCESS:
      return fetchDossierRelatedDossiersSuccess(state, action)
    case auditInfoTypes.FETCH_AUDIT_INFO_SUCCESS:
      return fetchAuditInfoSucces(state, action)
    case dossierModalActionTypes.FETCH_CUSTOM_FIELDS_SUCCESS:
      return fetchCustomFieldsSuccess(state, action)
    default:
      return state
  }
}
