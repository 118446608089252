import React, { PureComponent } from 'react'
import { Field } from 'redux-form'
import { Panel, Row, Col, Table, Glyphicon, Button, Tooltip, OverlayTrigger } from 'react-bootstrap'
import { required } from '../../../util/validationFunctions'
import { Element } from 'react-scroll'
import InputSelect from '../../commons/form/InputSelect'
import InputMoney from '../../commons/form/InputMoney'
import InputPercentage from '../../commons/form/InputPercentage'
import EditableTable from '../../commons/editableTable/EditableTable'
import { formatMoney, formatPercent } from '../../../util/utils'
import ButtonsSidebar from '../common/ButtonsSidebar'
import { buttonsSidebar } from '../../../constants/dossier/common/buttonsSidebar'
import { DEFAULT_MAX_PERCENTAGE, economicPlanSectionType, NUMBER_OF_DECIMALS, MAX_LENGTH_MONEY, MAX_LENGTH_PERCENTAGE } from '../../../constants/dossier/common/economicPlanSection'
import SmallLoaderPage from '../../../containers/commons/SmallLoaderPage'
import EconomicPlanTotal from './economicPlan/EconomicPlanTotal'
import EconomicPlanAmountWithTAXModal from './economicPlan/EconomicPlanAmountWithTAXModal'
import EconomicPlanProcessingAgencyModal from './economicPlan/EconomicPlanProcessingAgencyModal'
import EconomicPlanAssociatedCostByPercentage from './economicPlan/EconomicPlanAssociatedCostByPercentage'
import EconomicPlanDiscountsModal from './economicPlan/EconomicPlanDiscountsModal'
import { getComponentsPermisions, getFieldsConfiguration } from '../../../util/permisionFunctions'
import { permissions, clientInformation, iemValues, suppliedCodes } from '../../../constants/backendIds'
import CostPopover from '../../search/CostPopover'
import { dossierSubTypeId as dossierSubTypes } from '../../../constants/dossier/common/dossierSubType'
import settings from '../../../setting'
import EconomicPlanReaconditioning from './economicPlan/EconomicPlanReaconditioning'
import { dossierTypes as dossierTypesConstant } from '../../../constants/dossier/common/dossierTypes'
import { icoCurrencyContent } from '../../../constants/dossier/common/currencies'
import { ivaTypeCodes } from '../../../constants/dossier/common/taxes'
import CodeCommisionModalPage from '../../../containers/dossiers/common/CodeCommisionModalPage'

class EconomicPlan extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      taxesOpen: true,
      vehicle_amountOpen: true,
      campaign_discountOpen: false,
      additional_discountOpen: false,
      optionalOpen: false,
      sale_othersOpen: false,
      suppliedOpen: false,
      costs_giftsOpen: false,
      associated_costsOpen: false,
      reaconditioningOpen: false,
      valueIva: props.dossierEconomicComponent.ivaTypeId,
      valueIem: props.dossierEconomicComponent.iemTypeId,
      modelPriceAmount: props.dossierEconomicComponent.modelPriceAmount,
      totalDiscount: null,
      showModalwithTAX: false,
      showModalDiscounts: false,
      showModalProcessingAgency: false,
      showModalCostByPercentage: false,
      componentsConfiguration: {
        totals: null,
        updatedTotals: null,
        taxes: null,
        vehicle_amount: null,
        campaign_discount: null,
        additional_discount: null,
        optional: null,
        sale_others: null,
        supplied: null,
        costs_gifts: null,
        associated_costs: null,
        reaconditioning: null
      },
      fieldsConfiguration: {
        totals: null,
        updatedTotals: null,
        taxes: null,
        vehicle_amount: null,
        campaign_discount: null,
        additional_discount: null,
        optional: null,
        sale_others: null,
        supplied: null,
        costs_gifts: null,
        associated_costs: null,
        reaconditioning: null,
        tin: null,
        insurance: null,
        maintenance: null,
        uacTotalAmount: null
      },
      btnProcessingAgency: null,
      btnDiscounts: null,
      additionalCostId: null,
      additionalCostIndexFront: null,
      ivaPercentage: null,
      ivaAmount: null
    }
    this.openOrCloseDiscountsModal = this.openOrCloseDiscountsModal.bind(this)
  }

  initializePermisions (props) {
    const componentKeys = Object.keys(this.state.componentsConfiguration)
    const newState = getComponentsPermisions(props.tabConfiguration2, props.activeTab, componentKeys)
    const btnProcessingAgency = newState.componentsConfiguration.sale_others.fieldsConfiguration.filter((permission) => permission.code === 'btnProcessingAgency')[0]
    const btnDiscounts = newState.componentsConfiguration.additional_discount.fieldsConfiguration.filter((permission) => permission.code === 'btnDiscounts')[0]

    const tin = newState.componentsConfiguration.totals.fieldsConfiguration.filter((permission) => permission.code === 'tin')[0]
    const maintenance = newState.componentsConfiguration.totals.fieldsConfiguration.filter((permission) => permission.code === 'maintenance')[0]
    const insurance = newState.componentsConfiguration.totals.fieldsConfiguration.filter((permission) => permission.code === 'insurance')[0]

    const totals = getFieldsConfiguration(newState.componentsConfiguration.totals)
    const additional_discount = getFieldsConfiguration(newState.componentsConfiguration.additional_discount)
    const vehicle_amount = getFieldsConfiguration(newState.componentsConfiguration.vehicle_amount)
    const updatedTotals = getFieldsConfiguration(newState.componentsConfiguration.updatedTotals)
    const taxes = getFieldsConfiguration(newState.componentsConfiguration.taxes)
    const campaign_discount = getFieldsConfiguration(newState.componentsConfiguration.campaign_discount)
    const optional = getFieldsConfiguration(newState.componentsConfiguration.optional)
    const sale_others = getFieldsConfiguration(newState.componentsConfiguration.sale_others)
    const supplied = getFieldsConfiguration(newState.componentsConfiguration.supplied)
    const costs_gifts = getFieldsConfiguration(newState.componentsConfiguration.costs_gifts)
    const associated_costs = getFieldsConfiguration(newState.componentsConfiguration.associated_costs)
    const reaconditioning = getFieldsConfiguration(newState.componentsConfiguration.reaconditioning)

    this.setState({
      ...this.state,
      componentsConfiguration: newState.componentsConfiguration,
      btnProcessingAgency: btnProcessingAgency,
      btnDiscounts: btnDiscounts,
      fieldsConfiguration: {
        totals,
        additional_discount,
        updatedTotals,
        taxes,
        vehicle_amount,
        campaign_discount,
        optional,
        sale_others,
        supplied,
        costs_gifts,
        associated_costs,
        reaconditioning,
        tin,
        insurance,
        maintenance
      }
    })
  }

  UNSAFE_componentWillMount () {
    if (this.props.tabConfiguration2 !== null && this.props.tabConfiguration2 !== undefined) {
      this.initializePermisions(this.props)
    }
    this.props.handleToUpdateComponent(this.props)
    if (this.props.dossierType !== dossierTypesConstant.fleetEconomicPlan) {
      this.props.actions.fetchSalesEconomic(this.props.dossierId, this.props.historical, this.props.pristine)
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if ((Number(this.state.modelPriceAmount) !== Number(prevState.modelPriceAmount) ||
      this.state.modelPriceCost !== prevState.modelPriceCost ||
      this.state.transportationAmount !== prevState.transportationAmount ||
      this.state.transportationCost !== prevState.transportationCost ||
      this.state.valueIva !== prevState.valueIva ||
      this.state.valueIem !== prevState.valueIem ||
      this.state.iemPercentage !== prevState.iemPercentage) && this.props.dossierType === dossierTypesConstant.fleetEconomicPlan) {
      this.props.actions.getEconomicDataUpdate()
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    const state = {}

    if (JSON.stringify(nextProps.tabConfiguration2) !== JSON.stringify(this.props.tabConfiguration2) && nextProps.tabConfiguration2 !== null) {
      this.initializePermisions(nextProps)
    }

    if (this.props.dossierEconomicComponent.ivaTypeId !== nextProps.dossierEconomicComponent.ivaTypeId) {
      state.valueIva = nextProps.dossierEconomicComponent.ivaTypeId
      this.setState(state)
    }
    if (this.props.dossierEconomicComponent.iemTypeId !== nextProps.dossierEconomicComponent.iemTypeId) {
      state.valueIem = nextProps.dossierEconomicComponent.iemTypeId
      this.setState(state)
    }
    if (this.props.dossierEconomicComponent.modelPriceAmount !== nextProps.dossierEconomicComponent.modelPriceAmount) {
      this.setState({ modelPriceAmount: nextProps.dossierEconomicComponent.modelPriceAmount })
    }
    if (this.props.dossierEconomicComponent && this.props.dossierEconomicComponent.iemTypeId && this.props.dossierEconomicComponent.iemTypeId === iemValues.other && this.props.dossierEconomicComponent.iemPercentage) {
      this.calculateIem(this.props.dossierEconomicComponent.iemPercentage)
    }

    if (nextProps.activeSection && nextProps.activeSection !== '' && !this.state[nextProps.activeSection + 'Open']) {
      this.setState({ [nextProps.activeSection + 'Open']: true })
    }

    if (this.props.dossierEconomicComponent?.ivaPercentage) {
      this.calculateIva(this.props.dossierEconomicComponent.ivaPercentage)
    }
  }

  openOrCloseModalwithTAX (value) {
    this.setState({ showModalwithTAX: value })
  }

  openOrCloseDiscountsModal (value) {
    this.setState({ showModalDiscounts: value })
  }

  openOrCloseAssociatedCostPercentageModal (id, indexFront) {
    let promise = new Promise((resolve, reject) => {
      this.setState({ additionalCostId: id, additionalCostIndexFront: indexFront })
      resolve()
    })
    promise.then(() => {
      this.setState({ showModalCostByPercentage: !this.state.showModalCostByPercentage })
    })
  }

  checkAmountIVAIEM (value) {
    if (this.state.modelPriceAmount && this.state.valueIva && this.state.valueIem) {
      this.openOrCloseDiscountsModal(value)
    } else {
      this.props.actions.openCommonModal('notIvaIemAmoutFilled')
    }
  }

  openOrCloseModalProcessingAgency (value) {
    if (this.state && this.state.valueIva && this.state.valueIem) {
      this.setState({ showModalProcessingAgency: value })
    } else {
      this.props.actions.openCommonModal('ivaTypeNecessary')
    }
  }

  openOrCloseReaconditioning () {
    this.setState({ reaconditioningOpen: !this.state.reaconditioningOpen })
  }

  handleButtonPrint () {
    this.props.actions.printDocumentaryWarranty()
  }

  getTotalBill (totalOperation, financingBonusAmount) {
    return parseFloat(totalOperation) + parseFloat(financingBonusAmount)
  }

  calculateIem (iemValue) {
    this.setState({ amountIem: (parseFloat(iemValue)), iemPercentage: iemValue })
  }

  calculateIva (ivaValue) {
    this.setState({ ivaAmount: (parseFloat(ivaValue)), ivaPercentage: ivaValue })
  }

  isOtherIvaType (ivaTypeId) {
    if (ivaTypeId) {
      const code = this.props.combos.ivaTypeCombo.find((iva) => iva.id === ivaTypeId).code
      if (code === ivaTypeCodes.OTHERS_SVO) {
        return true
      }
    }
    return false
  }

  openCodeCommisionModal () {
    let organizedUnits = []
    if (this.props.dossierType === dossierTypesConstant.fleetEconomicPlan) {
      organizedUnits = this.props.organizedUnits
    } else {
      organizedUnits = [this.props.organizedUnitId]
    }
    this.props.actions.openCodeCommissionModal(organizedUnits, this.props.dossierEconomicComponent.commissionCancellationCodeId)
  }

  changeCodeCommission (codeCommission) {
    this.props.actions.changeCodeCommission(codeCommission)
  }

  getCommissionCancellationCodeDescription (value) {
    if (value === null || value === '' || value === undefined || value === '-') {
      return this.props.t('MASTERS.CODE_COMMISSION.CODE_COMMISSION_EMPTY')
    } else {
      return value
    }
  }

  render () {
    const {
      t, readOnlyFields, economicTab, isFixed, activeStage, dossierType, combos, panelsPaddingTop, handleToUpdateComponent,
      actions: {
        updateOptional, createOptional, deleteOptional, updateAmountEconomicPlan, change,
        updateDiscount, createDiscount, deleteDiscount, fetchDiscountModal, fetchAssociatedCostByPercentageModal,
        updateSupplied, createSupplied, deleteSupplied, fetchProcessingAgencyModal,
        updateExtraSale, createExtraSale, deleteExtraSale, deletePresent, updatePresent, createPresent, setActiveSection, openCommonModal,
        updateAssociatedCosts, createAssociatedCosts, deleteAssociatedCosts, fetchAmountTaxModal, deleteEconomicCampaign, fetchSalesServices
      },
      dossierEconomicComponent, tabConfiguration, dossierSubTypeId, numberOfDecimals = NUMBER_OF_DECIMALS, codeCommissionSaleTypeShow
    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.'
    let margenComprasPorcentaje = dossierEconomicComponent.purchaseMarginPerc ? parseFloat(dossierEconomicComponent.purchaseMarginPerc) : 0
    let descuentoTotalPorcentaje = dossierEconomicComponent.allDiscountPerc ? parseFloat(dossierEconomicComponent.allDiscountPerc) : 0
    let margenOperacionPorcentaje = dossierEconomicComponent.operationMarginPerc ? parseFloat(dossierEconomicComponent.operationMarginPerc) : 0
    const netMarginPercentage = dossierEconomicComponent.netMarginPerc ? parseFloat(dossierEconomicComponent.netMarginPerc) : 0
    const fixedCommissionPercentage = dossierEconomicComponent.fixedCommissionPerc ? parseFloat(dossierEconomicComponent.fixedCommissionPerc) : 0
    const variableCommissionPercentage = dossierEconomicComponent.variableCommissionPerc ? parseFloat(dossierEconomicComponent.variableCommissionPerc) : 0
    const qualitativeCommissionPercentage = dossierEconomicComponent.qualitativeCommissionPerc ? parseFloat(dossierEconomicComponent.qualitativeCommissionPerc) : 0

    let totalOptional = {
      name: t(`${tKey}ECONOMIC_PLAN.TOTALS`),
      amount: dossierEconomicComponent.optionals ? dossierEconomicComponent.optionals.reduce((acc, val) => acc + (val.amount ?? 0), 0) : '',
      percentage: '',
      cost: dossierEconomicComponent.optionals ? dossierEconomicComponent.optionals.reduce((acc, val) => acc + (val.cost ?? 0), 0) : '',
      supInvoice: '',
      iem: '',
      provider: ''
    }

    let totalCampaign = {
      name: t(`${tKey}ECONOMIC_PLAN.TOTALS`),
      amount: dossierEconomicComponent.campaigns ? dossierEconomicComponent.campaigns.reduce((acc, val) => acc + (val.amount ?? 0), 0) : '',
      percentage: dossierEconomicComponent.campaigns ? dossierEconomicComponent.campaigns.reduce((acc, val) => acc + (val.amountPercentage ?? 0), 0).toFixed(2) : '',
      cost: dossierEconomicComponent.campaigns ? dossierEconomicComponent.campaigns.reduce((acc, val) => acc + (val.cost ?? 0), 0) : '',
      costConcessionaire: dossierEconomicComponent.campaigns ? dossierEconomicComponent.campaigns.reduce((acc, val) => acc + (val.costConcessionaire ?? 0), 0) : '',
      costConcessionPercentage: dossierEconomicComponent.campaigns ? dossierEconomicComponent.campaigns.reduce((acc, val) => acc + (val.costConcessionPercentage ?? 0), 0).toFixed(2) : '',
      supInvoice: '',
      iem: '',
      provider: ''
    }
    let totalDiscount = {
      name: t(`${tKey}ECONOMIC_PLAN.TOTALS`),
      amount: dossierEconomicComponent.discounts ? dossierEconomicComponent.discounts.reduce((acc, val) => acc + (val.amount ?? 0), 0) : '',
      percentage: dossierEconomicComponent.modelPriceAmount && dossierEconomicComponent.discounts ? ((dossierEconomicComponent.discounts.reduce((acc, val) => acc + (val.amount ?? 0), 0) / ((dossierEconomicComponent.modelPriceAmount ?? 0) + (totalOptional.amount ?? 0)) * 100).toFixed(2) + '%') : '',
      cost: dossierEconomicComponent.discounts ? dossierEconomicComponent.discounts.reduce((acc, val) => acc + (val.cost ?? 0), 0) : '',
      supInvoice: '',
      iem: '',
      provider: ''
    }

    let totalExtraSale = {
      name: t(`${tKey}ECONOMIC_PLAN.TOTALS`),
      oculto: '',
      amount: dossierEconomicComponent.extraSales && dossierEconomicComponent.paperworks ? dossierEconomicComponent.extraSales.reduce((acc, val) => acc + (val.amount ?? 0), 0) + dossierEconomicComponent.paperworks.reduce((acc, val) => acc + (val.typeId === economicPlanSectionType.VENTA_OTROS ? (val.amount ?? 0) : 0), 0) : '',
      percentage: '',
      cost: dossierEconomicComponent.extraSales && dossierEconomicComponent.paperworks ? dossierEconomicComponent.extraSales.reduce((acc, val) => acc + (val.cost ?? 0), 0) + dossierEconomicComponent.paperworks.reduce((acc, val) => acc + (val.typeId === economicPlanSectionType.VENTA_OTROS ? (val.cost ?? 0) : 0), 0) : '',
      supInvoice: '',
      iem: '',
      provider: ''
    }

    let totalSuplidos = {
      name: t(`${tKey}ECONOMIC_PLAN.TOTALS`),
      amount: dossierEconomicComponent.supplieds && dossierEconomicComponent.specialHelps && dossierEconomicComponent.paperworks ? dossierEconomicComponent.supplieds.reduce((acc, val) => acc + (val.amount ?? 0), 0) + dossierEconomicComponent.specialHelps.reduce((acc, val) => acc + (val.amount ?? 0), 0) + (dossierEconomicComponent.iemAmount ?? 0) + dossierEconomicComponent.paperworks.reduce((acc, val) => acc + (val.typeId === economicPlanSectionType.SUPLIDOS ? (val.amount ?? 0) : 0), 0) : '',
      percentage: '',
      cost: dossierEconomicComponent.supplieds && dossierEconomicComponent.specialHelps && dossierEconomicComponent.paperworks ? dossierEconomicComponent.supplieds.reduce((acc, val) => acc + (val.cost ?? 0), 0) + dossierEconomicComponent.specialHelps.reduce((acc, val) => acc + (val.cost ?? 0), 0) + (dossierEconomicComponent.iemAmount ?? 0) + dossierEconomicComponent.paperworks.reduce((acc, val) => acc + (val.typeId === economicPlanSectionType.SUPLIDOS ? (val.cost ?? 0) : 0), 0) : '',
      supInvoice: '',
      iem: '',
      provider: ''
    }

    let totalPresent = {
      name: t(`${tKey}ECONOMIC_PLAN.TOTALS`),
      amount: dossierEconomicComponent.presents ? dossierEconomicComponent.presents.reduce((acc, val) => acc + (val.amount ?? 0), 0) : '',
      percentage: '',
      cost: dossierEconomicComponent.presents && dossierEconomicComponent.paperworks ? dossierEconomicComponent.presents.reduce((acc, val) => acc + (val.cost ?? 0), 0) + dossierEconomicComponent.paperworks.reduce((acc, val) => acc + (val.typeId === economicPlanSectionType.COSTE_OBSEQUIOS ? (val.cost ?? 0) : 0), 0) : 0,
      supInvoice: '',
      iem: '',
      provider: ''
    }

    let totalAssociatedCosts = {
      name: t(`${tKey}ECONOMIC_PLAN.TOTALS`),
      amount: dossierEconomicComponent.associatedCosts ? dossierEconomicComponent.associatedCosts.reduce((acc, val) => acc + (val.amount ?? 0), 0) : '',
      percentage: '',
      cost: dossierEconomicComponent.associatedCosts && dossierEconomicComponent.paperworks ? dossierEconomicComponent.associatedCosts.reduce((acc, val) => acc + (val.cost ?? 0), 0) + dossierEconomicComponent.paperworks.reduce((acc, val) => acc + (val.typeId === economicPlanSectionType.ASSOCIATED_COSTS ? (val.cost ?? 0) : 0), 0) : 0,
      supInvoice: '',
      iem: '',
      provider: ''
    }

    let iemSuppied = {
      name: 'IEM',
      amount: dossierEconomicComponent.iemAmount ? dossierEconomicComponent.iemAmount : '',
      percentage: '',
      cost: dossierEconomicComponent.iemAmount ? dossierEconomicComponent.iemAmount : '',
      supInvoice: dossierEconomicComponent.iemData ? dossierEconomicComponent.iemData.supInvoice : '',
      iem: '',
      provider: dossierEconomicComponent.iemData ? dossierEconomicComponent.iemData.provider : ''
    }

    let totalBill = dossierEconomicComponent.billTotalAmount ? dossierEconomicComponent.billTotalAmount : 0

    let totalReaconditioning = {
      name: t(`${tKey}ECONOMIC_PLAN.TOTALS`),
      amount: dossierEconomicComponent.purchasePresents ? dossierEconomicComponent.purchasePresents.reduce((acc, val) => acc + (val.amount ?? 0), 0) : '',
      percentage: '',
      cost: dossierEconomicComponent.purchasePresents ? dossierEconomicComponent.purchasePresents.reduce((acc, val) => acc + (val.cost ?? 0), 0) : 0,
      supInvoice: '',
      iem: '',
      provider: ''
    }
    const ivaType = combos.ivaTypeCombo && combos.ivaTypeCombo.find((iva) => iva.id === dossierEconomicComponent?.ivaTypeId ?? 0)
    let totalTaxesPercentaje = ((dossierEconomicComponent.iemTypeId === iemValues.other ? this.state.iemPercentage : Number(dossierEconomicComponent.iemTypeId ? combos.iemType.find(iem => iem.id === dossierEconomicComponent.iemTypeId).amountValue : 0)) +
    Number((ivaType ? ivaType.code === ivaTypeCodes.OTHERS_SVO ? this.state.ivaPercentage : ((ivaType.amountValue ?? 0) * 100) : 0))) ?? 0

    const showField = (fieldCode, componentCode) => {
      let componentPermissionObj = this.state.fieldsConfiguration[componentCode]
      if (componentPermissionObj[fieldCode]) {
        return componentPermissionObj[fieldCode].permission > permissions.hidden
      }
      return false
    }

    const nonEditableField = (fieldCode, componentCode) => {
      let componentPermissionObj = this.state.fieldsConfiguration[componentCode]
      if (componentPermissionObj[fieldCode]) {
        return componentPermissionObj[fieldCode].permission < permissions.editable
      }
      return true
    }

    const modelPriceAmount = this.props.dossierType !== dossierTypesConstant.fleetEconomicPlan
      ? dossierEconomicComponent.modelPriceAmount
      : this.state.modelPriceAmount

    return (
      <>
        <CodeCommisionModalPage changeCodeCommission={(value) => this.changeCodeCommission(value)}
          change={this.props.actions.change}
          pristine={this.props.pristine}
        ></CodeCommisionModalPage>
        <Row className="sales-panel dossier-panel">
          <EconomicPlanAmountWithTAXModal
            t={t}
            showModal={this.state.showModalwithTAX}
            actions={{
              closeModal: () => this.openOrCloseModalwithTAX(false),
              updateAmountEconomicPlan,
              change,
              fetchAmountTaxModal
            }}
            ivaTypeCombo={combos.ivaTypeCombo}
            valueCombo={this.state.valueIva}
            pristine={this.props.pristine}
            dossierType={this.props.dossierType}
            ivaPercentage = {this.state.ivaPercentage}
          />
          <EconomicPlanDiscountsModal
            t={t}
            showModal={this.state.showModalDiscounts}
            actions={{
              closeModal: this.openOrCloseDiscountsModal,
              createDiscount,
              change,
              openCommonModal,
              fetchDiscountModal
            }}
            ivaTypeCombo={combos.ivaTypeCombo}
            iemTypeCombo={combos.iemType}
            amountIem={this.state.amountIem}
            iemPercentage={this.state.iemPercentage}
            valueIem={this.state.valueIem}
            valueIva={this.state.valueIva}
            ivaTaxableBase={dossierEconomicComponent.ivaTaxableBase}
            totalAmount={totalBill}
            pristine={this.props.pristine}
            dossierType={this.props.dossierType}
          />
          <EconomicPlanProcessingAgencyModal
            t={t}
            showModal={this.state.showModalProcessingAgency}
            actions={{
              closeModal: () => this.openOrCloseModalProcessingAgency(false),
              createExtraSale,
              change,
              fetchProcessingAgencyModal
            }}
            ivaTypeCombo={combos.ivaTypeCombo}
            iemTypeCombo={combos.iemType}
            valueIem={this.state.valueIem}
            valueIva={this.state.valueIva}
            pristine={this.props.pristine}
            amountIem={this.state.amountIem}
            iemPercentage={this.state.iemPercentage}
            dossierType={this.props.dossierType}
          />
          <EconomicPlanAssociatedCostByPercentage
            t={t}
            showModal={this.state.showModalCostByPercentage}
            actions={{
              closeModal: () => this.openOrCloseAssociatedCostPercentageModal(),
              change,
              fetchAssociatedCostByPercentageModal
            }}
            totalAmount={(modelPriceAmount
              ? parseFloat(modelPriceAmount) : 0) +
            (totalOptional.amount ? parseFloat(totalOptional.amount) : 0)}
            additionalCostId={this.state.additionalCostId}
            additionalCostIndexFront={this.state.additionalCostIndexFront}
            associatedCosts={dossierEconomicComponent.associatedCosts}
            dossierType={dossierType}
            pristine={this.props.pristine}
          />
          {this.props.dossierType !== dossierTypesConstant.fleetEconomicPlan && (
            <Col sm={3} className="col-row expediente-menu-izq">
              <ButtonsSidebar
                t={t}
                actions={{ setActiveSection, fetchSalesServices }}
                isFixed={isFixed}
                economicTab={economicTab}
                activeStage={activeStage}
                dossierType={dossierType}
                dossierId={this.props.dossierId}
                paddingTop={panelsPaddingTop}
                tabConfiguration={tabConfiguration}
                handleToUpdateComponent={handleToUpdateComponent}
              />
            </Col>
          )}
          <Col sm={9} className="col-row contenido-paneles-expediente contenido-planteo-economico">
            <Col className="panels-wrapper dossier fixed" style={{ paddingTop: panelsPaddingTop }}>
              {
              // TITLE + TOTALS
              }
              {this.state.componentsConfiguration.totals &&
            this.state.componentsConfiguration.totals.permission > permissions.hidden &&
            this.props.dossierType === dossierTypesConstant.fleetEconomicPlan &&
            (
              <Element name={buttonsSidebar.totals}>
                <div className="dossier-panel" >
                  <div className="header-panel">
                    <div className="name-wrapper">
                      <h4><i className={icoCurrencyContent[settings.currency]} /> {t(`${tKey}ECONOMIC_PLAN.TITLE_PANEL`)}</h4>
                    </div>
                  </div>
                  <Col sm={12} className="totals-economic-plan">
                    <Col sm={codeCommissionSaleTypeShow ? 6 : 12} className="totals-economic-plan-der">
                      <div><span className="total-import-left">{t(`${tKey}TOTALS.TOTAL_BILL`)}</span><span className="total-import-right">{formatMoney(totalBill)}</span></div>
                    </Col>
                    {codeCommissionSaleTypeShow && showField('codeCommissionButton', 'totals') && (
                      <Col sm={6} className="totals-economic-plan-der">
                        <div><span className="total-import-left-double">{this.getCommissionCancellationCodeDescription(dossierEconomicComponent?.commissionCancellationCodeDescription)}</span>
                          <span className="porcentajes-totales" onClick={() => this.openCodeCommisionModal()}> <i className="ico-edit-white pointier"/></span>
                        </div>
                      </Col>)}
                  </Col>
                </div>
              </Element>
            )}
              {this.state.componentsConfiguration.totals &&
            this.state.componentsConfiguration.totals.permission > permissions.hidden &&
            this.props.dossierType !== dossierTypesConstant.fleetEconomicPlan && (
                <Element name={buttonsSidebar.totals}>
                  <div className="dossier-panel" >
                    <div className="header-panel">
                      <div className="name-wrapper">
                        <h4><i className={icoCurrencyContent[settings.currency]} /> {t(`${tKey}ECONOMIC_PLAN.TITLE_PANEL`)}</h4>
                      </div>
                    </div>
                    <Col sm={12} className="totals-economic-plan">
                      <Col sm={6} className="totals-economic-plan-izq">
                        <div><b><span className="total-import-left">{t(`${tKey}TOTALS.OPERATION_TOTAL`)}</span><span id="Financial_SaleTotal" className="total-import-right">{formatMoney(dossierEconomicComponent.operationTotalAmount ?? 0)}</span></b></div>
                        {!!(showField('uacTotalAmount', 'totals')) &&
                      <div><span className="total-import-left">{t(`${tKey}TOTALS.UAC`)}</span><span id="Financial_SecondHandVehicleInReturn" className="total-import-right">{formatMoney(dossierEconomicComponent.uacTotalAmount ?? 0)}</span></div>
                        }
                        <div><span className="total-import-left">{t(`${tKey}TOTALS.COLLECTED`)}</span><span id="Financial_Paid" className="total-import-right">{formatMoney(dossierEconomicComponent.chargesTotalAmount ?? 0)}</span></div>
                        <div><span className="total-import-left">{t(`${tKey}TOTALS.COLLECT_TOTAL`)}</span><span id="Financial_TotalReceivable" className="total-import-right">{formatMoney(dossierEconomicComponent.paymentPending ?? 0)}</span></div>
                        {!!(showField('totalAmountChargeConcession', 'totals')) &&
                      <div><span className="total-import-left">{t(`${tKey}TOTALS.TOTAL_AMOUNT_CHARGE_CONCESSION`)}</span><span id="Financial_SecondHandVehicleInReturn" className="total-import-right">{formatMoney(dossierEconomicComponent.totalAmountChargeConcession ?? 0)}</span></div>
                        }
                        <div><span className="total-import-left">{t(`${tKey}TOTALS.FINANCING_AMOUNT`)}</span><span id="Financial_FinancingAmount" className="total-import-right">{formatMoney(dossierEconomicComponent.financingAmount ?? 0)}</span></div>
                        <div><span className="total-import-left">{t(`${tKey}TOTALS.FINANCING_BONUS_AMOUNT`)}</span><span id="Financial_FinancingBonusAmount" className="total-import-right">{formatMoney(dossierEconomicComponent.financingBonusAmount ?? 0)}</span></div>
                        <div><span className="total-import-left">{t(`${tKey}TOTALS.FINANCING_COMISSION`)}</span><span id="Financial_FinancingComission" className="total-import-right">{formatMoney(dossierEconomicComponent.financingCommission ?? 0)}</span></div>
                        <div><span className="total-import-left">{t(`${tKey}TOTALS.TOTAL_BILL`)}</span><span id="Financial_BillTotal" className="total-import-right">{formatMoney(totalBill)}</span></div>
                      </Col>
                      <Col sm={6} className="totals-economic-plan-der">
                        <div><span className="total-import-left">{t(`${tKey}TOTALS.PURCHASE_MARGIN`)}</span><span id="Financial_PurchaseMargins" className="total-import-right">{formatMoney(dossierEconomicComponent.purchaseMargin ?? 0)}</span><span id="Financial_PurchaseMargins_Percent" className="porcentajes-totales">{margenComprasPorcentaje.toFixed(2) + '%'}</span></div>
                        <div><span className="total-import-left">{t(`${tKey}TOTALS.DTO_TOTAL`)}</span><span id="Financial_TotalDiscount" className="total-import-right">{formatMoney(dossierEconomicComponent.allDiscount ?? 0)}</span><span id="Financial_TotalDiscount_Percent" className="porcentajes-totales">{descuentoTotalPorcentaje.toFixed(2) + '%'}</span></div>
                        {(this.state.componentsConfiguration.reaconditioning && this.state.componentsConfiguration.reaconditioning.permission > permissions.hidden && dossierSubTypeId === dossierSubTypes.venta_vo) &&
                    <div><span className="total-import-left">{t(`${tKey}TOTALS.DTO_TOTAL_REACONDITIONING`)}</span><span id="Financial_modelPriceCost" className="total-import-right">{formatMoney(dossierEconomicComponent.modelPriceCost ?? 0)}</span></div> }
                        <div><span className="total-import-left">{t(`${tKey}TOTALS.OPERATION_MARGIN`)}</span><span id="Financial_ComercialMargin" className="total-import-right">{formatMoney(dossierEconomicComponent.operationMargin ?? 0)}</span><span id="Financial_ComercialMargin_Percent" className="porcentajes-totales">{margenOperacionPorcentaje.toFixed(2) + '%'}</span></div>
                        <div><span className="total-import-left">{t(`${tKey}TOTALS.NET_MARGIN`)}</span><span id="Financial_NetMargin" className="total-import-right">{formatMoney(dossierEconomicComponent.netMargin ?? 0)}</span><span id="Financial_NetMargin_Percent" className="porcentajes-totales">{netMarginPercentage.toFixed(2) + '%'}</span></div>
                        {!!(showField('fixedCommission', 'totals')) &&
                        <div><span className="total-import-left-double">{this.getCommissionCancellationCodeDescription(dossierEconomicComponent?.commissionCancellationCodeDescription)}</span>
                          {!!(showField('codeCommissionButton', 'totals')) && <span className="porcentajes-totales" onClick={() => this.openCodeCommisionModal()}> <i className="ico-edit-white pointier"/></span>}
                        </div>
                        }
                        {!!(showField('fixedCommission', 'totals')) &&
                        <div><span className="total-import-left">{t(`${tKey}TOTALS.FIXED_COMMISSION`)}</span><span id="Totals_FixedCommission" className="total-import-right">{formatMoney(dossierEconomicComponent.fixedCommission ?? 0)}</span><span id="Totals_FixedCommission_Percent" className="porcentajes-totales">{fixedCommissionPercentage.toFixed(2) + '%'}</span></div>
                        }
                        {!!(showField('variableCommission', 'totals')) &&
                        <div><span className="total-import-left">{t(`${tKey}TOTALS.VARIABLE_COMMISSION`)}</span><span id="Totals_VariableCommission" className="total-import-right">{formatMoney(dossierEconomicComponent.variableCommission ?? 0)}</span><span id="Totals_VariableCommission_Percent" className="porcentajes-totales">{variableCommissionPercentage.toFixed(2) + '%'}</span></div>
                        }
                        {!!(showField('variableCommission', 'totals')) &&
                        <div><span className="total-import-left">{t('MASTERS.CODE_COMMISSION.QUALITATIVE_PERCENTAGE')}</span><span id="Totals_CualitativeCommission" className="total-import-right">{formatMoney(dossierEconomicComponent.qualitativeCommission ?? 0)}</span><span id="Totals_VariableCommission_Percent" className="porcentajes-totales">{qualitativeCommissionPercentage.toFixed(2) + '%'}</span></div>
                        }
                        {this.state.fieldsConfiguration.tin && this.state.fieldsConfiguration.totals.tin.permission !== permissions.hidden && (
                          <div><span className="total-import-left">{t(`${tKey}TOTALS.TIN`)}</span><span id="Financial_TIN" className="total-import-right">{formatPercent(dossierEconomicComponent.tin)}</span></div>
                        )}
                        {this.state.fieldsConfiguration.insurance && this.state.fieldsConfiguration.totals.insurance.permission !== permissions.hidden && (
                          <div><span className="total-import-left">{t(`${tKey}TOTALS.INSURANCE`)}</span><span id="Financial_Insurance" className="total-import-right">{dossierEconomicComponent.supplieds?.some(s => s.typeCode === suppliedCodes.INSURANCE) ? t(`${tKey}TOTALS.YES`) : t(`${tKey}TOTALS.NO`)}</span></div>
                        )}
                        {this.state.fieldsConfiguration.maintenance && this.state.fieldsConfiguration.totals.maintenance.permission !== permissions.hidden && (
                          <div><span className="total-import-left">{t(`${tKey}TOTALS.MAINTENANCE`)}</span><span id="Financial_Maintenance" className="total-import-right">{dossierEconomicComponent.supplieds?.some(s => s.typeCode === suppliedCodes.MAINTENANCE) ? t(`${tKey}TOTALS.YES`) : t(`${tKey}TOTALS.NO`)}</span></div>
                        )}
                      </Col>
                    </Col>
                  </div>
                </Element>
              )}

              {this.state.componentsConfiguration.updatedTotals &&
            this.state.componentsConfiguration.updatedTotals.permission > permissions.hidden &&
            this.props.dossierType !== dossierTypesConstant.fleetEconomicPlan &&
            (
              <Element name={buttonsSidebar.updatedTotals}>
                <div className="dossier-panel" >
                  <div className="header-panel">
                    <div className="name-wrapper">
                      <h4><i className={icoCurrencyContent[settings.currency]} /> {t(`${tKey}ECONOMIC_PLAN.TITLE_PANEL`)}</h4>
                    </div>
                  </div>
                  <Col sm={12} className="totals-economic-plan">
                    {!!(showField('subtotalCol', 'updatedTotals')) &&
                    <Col sm={4} className="totals-economic-plan-izq-triple">
                      <div><span className="total-import-right">{t(`${tKey}TOTALS.PFF_OPC`)}</span><span className="total-import-right">{formatMoney(Number(isNaN(dossierEconomicComponent.modelPriceAmount) ? 0 + totalOptional.amount : Number(dossierEconomicComponent.modelPriceAmount) + totalOptional.amount).toFixed(2))}</span></div>
                      <div><span className="total-import-right">{t(`${tKey}TOTALS.DISCOUNTS`)}</span><span className="total-import-right">{formatMoney(Math.abs(Number((Math.abs(totalCampaign.amount) * -1) + (Math.abs(totalDiscount.amount) * -1))))}</span></div>
                      <div><span className="total-import-right">{t(`${tKey}SALE_OTHERS.TITLE_PANEL`)}</span><span className="total-import-right">{formatMoney(Number(totalExtraSale.amount + (isNaN(dossierEconomicComponent.transportationAmount) ? 0 : Number(dossierEconomicComponent.transportationAmount))))}</span></div>
                      <div><span className="total-import-right">{t(`${tKey}TOTALS.TAXABLE_BASE`)}</span><span className="total-import-right">{formatMoney(Number(isNaN(dossierEconomicComponent.ivaTaxableBase) ? 0 : Number(dossierEconomicComponent.ivaTaxableBase)))}</span></div>
                      <div><span className="total-import-right">{t(`${tKey}TAXES.TITLE_PANEL`)}</span><span className="total-import-right">{formatMoney(Number(dossierEconomicComponent.ivaAmount + dossierEconomicComponent.iemAmount))}</span><span className="porcentajes-totales">{Number(totalTaxesPercentaje) + '%'}</span></div>
                      <div><span className="total-import-right">{t(`${tKey}SUPPLIED.TITLE_PANEL`)}</span><span className="total-import-right">{formatMoney(Number(totalSuplidos.amount))}</span></div>
                      <div><b><span className="total-import-right">{t(`${tKey}TOTALS.SUBTOTAL`)}</span><span className="total-import-right">{formatMoney(Number(totalBill))}</span></b></div>
                    </Col>}
                    {!!(showField('totalInvoiceCol', 'updatedTotals')) &&
                    <Col sm={4} className="totals-economic-plan-der-triple">
                      <div><span className="total-import-center">{t(`${tKey}TOTALS.FINANCING_BONUS_AMOUNT`)}</span><span className="total-import-right">{formatMoney(Number(dossierEconomicComponent.financingBonusAmount))}</span></div>
                      <div><span className="total-import-center">{t(`${tKey}TOTALS.FINANCING_COMISSION`)}</span><span id="Financial_FinancingComission" className="total-import-right">{formatMoney(dossierEconomicComponent.financingCommission ?? 0)}</span></div>
                      <div><b><span className="total-import-center">{t(`${tKey}TOTALS.OPERATION_TOTAL`)}</span><span className="total-import-right">{formatMoney(Number(dossierEconomicComponent.operationTotalAmount))}</span></b></div>
                      {!!(showField('uacTotalAmount', 'updatedTotals')) &&
                      <div><span className="total-import-center">{t(`${tKey}TOTALS.PERMUTA`)}</span><span className="total-import-right">{formatMoney(Number(dossierEconomicComponent.uacTotalAmount))}</span></div>
                      }
                      <div><span className="total-import-center">{t(`${tKey}TOTALS.FINANCING_AMOUNT`)}</span><span className="total-import-right">{formatMoney(Number(dossierEconomicComponent.financingAmount))}</span></div>
                      <div><span className="total-import-center">{t(`${tKey}TOTALS.COLLECTED`)}</span><span className="total-import-right">{formatMoney(Number(dossierEconomicComponent.chargesTotalAmount))}</span></div>
                      <div><span className="total-import-center">{t(`${tKey}TOTALS.COLLECT_TOTAL`)}</span><span className="total-import-right">{formatMoney(Number(dossierEconomicComponent.paymentPending))}</span></div>
                    </Col>}
                    {!!(showField('marginsCol', 'updatedTotals')) &&
                    <Col sm={4} className="totals-economic-plan-der">
                      <div><span className="total-import-left-triple">{t(`${tKey}TOTALS.PURCHASE_MARGIN`)}</span><span className="total-import-right">{formatMoney(Number(dossierEconomicComponent.purchaseMargin))}</span><span className="porcentajes-totales">{margenComprasPorcentaje.toFixed(2) + '%'}</span></div>
                      <div><span className="total-import-left-triple">{t(`${tKey}TOTALS.DTO_TOTAL`)}</span><span className="total-import-right">{formatMoney(Number(dossierEconomicComponent.allDiscount))}</span><span className="porcentajes-totales">{descuentoTotalPorcentaje.toFixed(2) + '%'}</span></div>
                      {(this.state.componentsConfiguration.reaconditioning && this.state.componentsConfiguration.reaconditioning.permission > permissions.hidden && dossierSubTypeId === dossierSubTypes.venta_vo) &&
                      <div><span className="total-import-left-triple">{t(`${tKey}TOTALS.DTO_TOTAL_REACONDITIONING`)}</span><span className="total-import-right">{formatMoney(Number(dossierEconomicComponent.modelPriceCost))}</span></div> }
                      <div><span className="total-import-left-triple">{t(`${tKey}TOTALS.OPERATION_MARGIN`)}</span><span className="total-import-right">{formatMoney(Number(dossierEconomicComponent.operationMargin))}</span><span className="porcentajes-totales">{margenOperacionPorcentaje.toFixed(2) + '%'}</span></div>
                      <div><span className="total-import-left-triple">{t(`${tKey}TOTALS.NET_MARGIN`)}</span><span className="total-import-right">{formatMoney(Number(dossierEconomicComponent.netMargin))}</span><span className="porcentajes-totales">{netMarginPercentage.toFixed(2) + '%'}</span></div>
                      {!!(showField('fixedCommission', 'updatedTotals')) &&
                        <div><span className="total-import-left">{t(`${tKey}TOTALS.FIXED_COMMISSION`)}</span><span id="UpdatedTotals_FixedCommission" className="total-import-right">{formatMoney(dossierEconomicComponent.fixedCommission ?? 0)}</span><span id="UpdatedTotals_FixedCommission_Percent" className="porcentajes-totales">{fixedCommissionPercentage.toFixed(2) + '%'}</span></div>
                      }
                      {!!(showField('variableCommission', 'updatedTotals')) &&
                        <div><span className="total-import-left">{t(`${tKey}TOTALS.VARIABLE_COMMISSION`)}</span><span id="UpdatedTotals_VariableCommission" className="total-import-right">{formatMoney(dossierEconomicComponent.variableCommission ?? 0)}</span><span id="UpdatedTotals_VariableCommission_Percent" className="porcentajes-totales">{variableCommissionPercentage.toFixed(2) + '%'}</span></div>
                      }
                      {this.state.fieldsConfiguration.tin && this.state.fieldsConfiguration.totals.tin.permission !== permissions.hidden && (
                        <div><span className="total-import-left">{t(`${tKey}TOTALS.TIN`)}</span><span id="Financial_TIN" className="total-import-right">{formatPercent(dossierEconomicComponent.tin)}</span></div>
                      )}
                      {this.state.fieldsConfiguration.insurance && this.state.fieldsConfiguration.totals.insurance.permission !== permissions.hidden && (
                        <div><span className="total-import-left">{t(`${tKey}TOTALS.INSURANCE`)}</span><span id="Financial_Insurance" className="total-import-right">{dossierEconomicComponent.supplieds?.some(s => s.typeCode === suppliedCodes.INSURANCE) ? t(`${tKey}TOTALS.YES`) : t(`${tKey}TOTALS.NO`)}</span></div>
                      )}
                      {this.state.fieldsConfiguration.maintenance && this.state.fieldsConfiguration.totals.maintenance.permission !== permissions.hidden && (
                        <div><span className="total-import-left">{t(`${tKey}TOTALS.MAINTENANCE`)}</span><span id="Financial_Maintenance" className="total-import-right">{dossierEconomicComponent.supplieds?.some(s => s.typeCode === suppliedCodes.MAINTENANCE) ? t(`${tKey}TOTALS.YES`) : t(`${tKey}TOTALS.NO`)}</span></div>
                      )}
                    </Col>}
                  </Col>
                </div>
              </Element>
            )
              }
              {
                // VEHICLE AMOUNT
              }
              {this.state.componentsConfiguration.vehicle_amount &&
            this.state.componentsConfiguration.vehicle_amount.permission > permissions.hidden &&
            (
              <Element name={buttonsSidebar.vehicle_amount}>
                <div className="dossier-panel">
                  <div className="header-panel" onClick={() => this.setState({ vehicle_amountOpen: !this.state.vehicle_amountOpen })}>
                    <div className="name-wrapper">
                      <i className={this.state.vehicle_amountOpen ? 'ico-arrow-black-up' : 'ico-arrow-black-down'} />
                      <h4>{t(`${tKey}VEHICLE_AMOUNT.TITLE_PANEL`)}</h4>
                    </div>
                  </div>
                  <Panel onToggle={() => {}} expanded={this.state.vehicle_amountOpen}>
                    <Panel.Collapse>
                      <Table hover responsive>
                        <thead>
                          <tr>
                            <th className="text-center"></th>
                            {!!(showField('amountCol', 'vehicle_amount')) &&
                            <th className="text-center">{t(`${tKey}VEHICLE_AMOUNT.VTA_IMPORT`)}</th>}
                            <th className="text-center"></th>
                            {!!(showField('costCol', 'vehicle_amount')) &&
                            <th className="text-center">{t(`${tKey}VEHICLE_AMOUNT.COST`)}</th>}
                            {(settings.clientFieldValidation === clientInformation.VGR && dossierSubTypeId === dossierSubTypes.venta_vo &&
                                this.props.dossierType !== dossierTypesConstant.fleetEconomicPlan) &&
                            !!showField('costCol', 'vehicle_amount') && !!showField('modelPriceCost', 'vehicle_amount') && <th></th> }
                            {!!(showField('fraSupCol', 'vehicle_amount')) &&
                            <th className="text-center">{t(`${tKey}VEHICLE_AMOUNT.FRA_SUP`)}</th>}
                            {!!(showField('iemCol', 'vehicle_amount')) &&
                            <th className="text-center">{t(`${tKey}VEHICLE_AMOUNT.IEM`)}</th>}
                            {!!(showField('providerCol', 'vehicle_amount')) &&
                            <th className="text-center">{t(`${tKey}VEHICLE_AMOUNT.PROVIDER`)}</th>}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <span style={{ float: 'left', marginTop: '12px' }}>{t(`${tKey}VEHICLE_AMOUNT.VEH_PREVIUS`)}</span>
                            </td>
                            {!!showField('amountCol', 'vehicle_amount') &&
                            <td>
                              {!!showField('modelPriceAmount', 'vehicle_amount') &&
                              (
                                <Field
                                  colSm={6}
                                  id="modelPriceAmount"
                                  name="dossierEconomicComponent.modelPriceAmount"
                                  component={InputMoney}
                                  inputType="text"
                                  disabled={readOnlyFields || !!nonEditableField('modelPriceAmount', 'vehicle_amount')}
                                  onInputBlur={(modelPriceAmount) => this.setState({ modelPriceAmount })}
                                  placeholder={formatMoney(dossierEconomicComponent.modelPriceAmount)}
                                  style={{ textAlign: 'right' }}
                                  maxLength={MAX_LENGTH_MONEY}
                                />
                              )}
                            </td>
                            }
                            <td>
                              {this.state.valueIva && !readOnlyFields &&
                                !!showField('btnAddAmountWithTax', 'vehicle_amount')
                                ? (
                                  <Button
                                    className="btn-editable-table btn-standard"
                                    onClick={() => this.openOrCloseModalwithTAX(true)}
                                    disabled={!!nonEditableField('btnAddAmountWithTax', 'vehicle_amount')}>
                                    {t(`${tKey}ECONOMIC_PLAN.ADD_AMOUNT_WITH_TAX`)}
                                  </Button>)
                                : null
                              }
                            </td>
                            {!!showField('costCol', 'vehicle_amount') && <td>
                              {!!showField('modelPriceCost', 'vehicle_amount') &&
                              (
                                <Field
                                  colSm={12}
                                  id="modelPriceCost"
                                  name="dossierEconomicComponent.modelPriceCost"
                                  component={InputMoney}
                                  inputType="number"
                                  disabled={readOnlyFields || !!nonEditableField('modelPriceCost', 'vehicle_amount')}
                                  onInputBlur={(modelPriceCost) => this.setState({ modelPriceCost })}
                                  placeholder={formatMoney(dossierEconomicComponent.modelPriceCost)}
                                  style={{ textAlign: 'right' }}
                                  maxLength={MAX_LENGTH_MONEY}
                                />
                              )}
                            </td>
                            }
                            {(settings.clientFieldValidation === clientInformation.VGR && dossierSubTypeId === dossierSubTypes.venta_vo &&
                              this.props.dossierType !== dossierTypesConstant.fleetEconomicPlan) &&
                            !!showField('costCol', 'vehicle_amount') && !!showField('modelPriceCost', 'vehicle_amount') &&
                              <td>
                                <CostPopover
                                  id="costPopover"
                                  name="dossierEconomicComponent.costPopover"
                                  dossierSubTypeId={dossierSubTypeId}
                                  economic={dossierEconomicComponent}
                                  t={t}>
                                </CostPopover>
                              </td>}
                            {!!showField('fraSupCol', 'vehicle_amount') &&
                            <td></td>}
                            {!!showField('iemCol', 'vehicle_amount') &&
                            <td className="text-center">
                              <span style={{ fontSize: '9px', paddingLeft: '5px' }}><Glyphicon glyph="ok"/></span>
                            </td>
                            }
                            {!!showField('providerCol', 'vehicle_amount') &&
                            <td></td>}
                          </tr>
                          <tr>
                            <td>
                              <span style={{ float: 'left', marginTop: '12px' }}>{t(`${tKey}VEHICLE_AMOUNT.TRANSPORT`)}</span>
                            </td>
                            {!!showField('amountCol', 'vehicle_amount') &&
                            <td>
                              {!!showField('transportationAmount', 'vehicle_amount') &&
                              (
                                <Field
                                  colSm={6}
                                  id="transportationAmount"
                                  name="dossierEconomicComponent.transportationAmount"
                                  component={InputMoney}
                                  inputType="number"
                                  disabled={readOnlyFields || !!nonEditableField('transportationAmount', 'vehicle_amount')}
                                  onInputBlur={(transportationAmount) => this.setState({ transportationAmount })}
                                  placeholder={formatMoney(dossierEconomicComponent.transportationAmount)}
                                  style={{ textAlign: 'right' }}
                                  maxLength={MAX_LENGTH_MONEY}
                                />
                              )}
                            </td>
                            }
                            <td></td>
                            {!!showField('costCol', 'vehicle_amount') &&
                            <td>
                              {!!showField('transportationCost', 'vehicle_amount') &&
                              (
                                <Field
                                  colSm={12}
                                  id="transportationCost"
                                  name="dossierEconomicComponent.transportationCost"
                                  component={InputMoney}
                                  inputType="number"
                                  disabled={readOnlyFields || !!nonEditableField('transportationCost', 'vehicle_amount')}
                                  onInputBlur={(transportationCost) => this.setState({ transportationCost })}
                                  placeholder={formatMoney(dossierEconomicComponent.transportationCost)}
                                  style={{ textAlign: 'right' }}
                                  maxLength={MAX_LENGTH_MONEY}
                                />
                              )}
                            </td>
                            }
                            {(settings.clientFieldValidation === clientInformation.VGR && dossierSubTypeId === dossierSubTypes.venta_vo &&
                                this.props.dossierType !== dossierTypesConstant.fleetEconomicPlan) && <td></td>}
                            {!!showField('fraSupCol', 'vehicle_amount') &&
                            <td></td>}
                            {!!showField('iemCol', 'vehicle_amount') &&
                            <td className="text-center">
                              <span style={{ fontSize: '9px', paddingLeft: '5px' }}><Glyphicon glyph="ok"/></span>
                            </td>
                            }
                            {!!showField('providerCol', 'vehicle_amount') &&
                            <td></td>}
                          </tr>
                        </tbody>
                      </Table>
                    </Panel.Collapse>
                  </Panel>
                  <SmallLoaderPage />
                </div>
              </Element>
            )}
              {
                // OPTIONALS
              }
              {this.state.componentsConfiguration.optional &&
            this.state.componentsConfiguration.optional.permission > permissions.hidden &&
            (
              <Element name={buttonsSidebar.optional}>
                <div className="optionals-panel dossier-panel">
                  <div className="header-panel" onClick={ () => this.setState({ optionalOpen: !this.state.optionalOpen })}>
                    <div className="name-wrapper">
                      <i className={this.state.optionalOpen ? 'ico-arrow-black-up' : 'ico-arrow-black-down'}/>
                      <h4>{t(`${tKey}OPTIONALS.TITLE_PANEL`)}</h4>
                      <EconomicPlanTotal
                        totals={totalOptional}
                        totalText={t(`${tKey}ECONOMIC_PLAN.TOTALS`) + ' ' + t(`${tKey}OPTIONALS.VTA_IMPORT`)}
                        costText={t(`${tKey}ECONOMIC_PLAN.TOTALS`) + ' ' + t(`${tKey}OPTIONALS.COST`)}
                        showCost={!!showField('costCol', 'optional')}
                      />
                    </div>
                  </div>
                  <Panel onToggle={() => {}} expanded={this.state.optionalOpen}>
                    <Panel.Collapse>
                      <Row>
                        <Field responsive hover
                          component={EditableTable}
                          customClass=" -group"
                          t={t}
                          columnsConfig={
                            [
                              { key: 'oculto', onlyShow: true, className: 'maxW40' },
                              {
                                th: t(`${tKey}OPTIONALS.TITLE_PANEL`),
                                key: 'name',
                                showColumn: !!showField('optionalsCol', 'optional'),
                                disabled: !!nonEditableField('optionalsCol', 'optional')
                              },
                              {
                                th: t(`${tKey}OPTIONALS.VTA_IMPORT`),
                                key: 'amount',
                                money: true,
                                validationFunc: required,
                                showColumn: !!showField('amountCol', 'optional'),
                                disabled: !!nonEditableField('amountCol', 'optional'),
                                maxLength: MAX_LENGTH_MONEY
                              },
                              { key: 'percentage', onlyShow: true, inputType: 'number' },
                              {
                                th: t(`${tKey}OPTIONALS.COST`),
                                key: 'cost',
                                money: true,
                                validationFunc: required,
                                showColumn: !!showField('costCol', 'optional'),
                                disabled: !!nonEditableField('costCol', 'optional'),
                                maxLength: MAX_LENGTH_MONEY
                              },
                              {
                                th: t(`${tKey}OPTIONALS.FRA_SUP`),
                                key: 'supInvoice',
                                onlyShow: true,
                                showColumn: !!showField('fraSupCol', 'optional')
                              },
                              {
                                th: t(`${tKey}OPTIONALS.IEM`),
                                key: 'iem',
                                check: true,
                                onlyShow: true,
                                showColumn: !!showField('iemCol', 'optional')
                              },
                              {
                                th: t(`${tKey}OPTIONALS.PROVIDER`),
                                key: 'provider',
                                onlyShow: true,
                                showColumn: !!showField('providerCol', 'optional')
                              }

                            ]
                          }
                          columnsConfigTotales={
                            [
                              { key: 'name', onlyShow: true },
                              { key: 'oculto', showColumn: !!showField('optionalsCol', 'optional') },
                              {
                                th: t(`${tKey}OPTIONALS.VTA_IMPORT`),
                                key: 'amount',
                                inputType:
                                'number',
                                formatFunc: (amount) => formatMoney(amount),
                                showColumn: !!showField('amountCol', 'optional')
                              },
                              { key: 'percentage', onlyShow: true, inputType: 'number' },
                              {
                                th: t(`${tKey}OPTIONALS.COST`),
                                key: 'cost',
                                inputType: 'number',
                                formatFunc: (amount) => formatMoney(amount),
                                showColumn: !!showField('costCol', 'optional')
                              },
                              {
                                th: t(`${tKey}OPTIONALS.FRA_SUP`),
                                key: 'supInvoice',
                                onlyShow: true,
                                showColumn: !!showField('fraSupCol', 'optional')
                              },
                              {
                                th: t(`${tKey}OPTIONALS.IEM`),
                                key: 'iem',
                                onlyShow: true,
                                showColumn: !!showField('iemCol', 'optional')
                              },
                              {
                                th: t(`${tKey}OPTIONALS.PROVIDER`),
                                key: 'provider',
                                onlyShow: true,
                                showColumn: !!showField('providerCol', 'optional')
                              }
                            ]
                          }
                          id="optionals"
                          name="dossierEconomicComponent.optionals"
                          editableObjectsArrayTotales={totalOptional}
                          editableObjectsArray={dossierEconomicComponent.optionals || []}
                          updateAction={updateOptional}
                          createAction={createOptional}
                          deleteAction={deleteOptional}
                          readOnlyFields={readOnlyFields ||
                            this.state.componentsConfiguration.optional.permission < permissions.editable}
                          showDeleteAction= {!!showField('btnDeleteAction', 'optional')}
                          showEditAction= {!!showField('btnEditAction', 'optional')}
                          showAddButton= {!!showField('btnAddOptional', 'optional')}
                          dossierType = {dossierType}
                        />
                      </Row>
                    </Panel.Collapse>
                  </Panel>
                  <SmallLoaderPage/>
                </div>
              </Element>
            )}
              {
                // DTO. CAMPAIGN
              }
              {this.state.componentsConfiguration.campaign_discount &&
            this.state.componentsConfiguration.campaign_discount.permission > permissions.hidden &&
            (
              <Element name={buttonsSidebar.campaign_discount}>
                <div className="dossier-panel">
                  <div className="header-panel" onClick={() => this.setState({ campaign_discountOpen: !this.state.campaign_discountOpen })}>
                    <div className="name-wrapper">
                      <i className={this.state.campaign_discountOpen ? 'ico-arrow-black-up' : 'ico-arrow-black-down'} />
                      <h4>{t(`${tKey}CAMPAIGN_DISCOUNT.TITLE_PANEL`)}</h4>
                      <EconomicPlanTotal
                        totals={totalCampaign}
                        totalText={t(`${tKey}ECONOMIC_PLAN.TOTALS`) + ' ' + t(`${tKey}CAMPAIGN_DISCOUNT.VTA_IMPORT`)}
                        costText={t(`${tKey}ECONOMIC_PLAN.TOTALS`) + ' ' + t(`${tKey}CAMPAIGN_DISCOUNT.COST`)} />
                    </div>
                  </div>
                  <Panel onToggle={() => {}} expanded={this.state.campaign_discountOpen}>
                    <Panel.Collapse>
                      <Row>
                        <EditableTable
                          name="campaign_discount"
                          t={t}
                          columnsConfig={
                            [
                              { key: 'oculto', onlyShow: true, className: 'maxW40' },
                              {
                                th: t(`${tKey}CAMPAIGN_DISCOUNT.TITLE_PANEL`),
                                key: 'name',
                                onlyShow: true,
                                showColumn: !!showField('campaignDiscountCol', 'campaign_discount')
                              },
                              {
                                th: t(`${tKey}CAMPAIGN_DISCOUNT.VTA_IMPORT`),
                                key: 'amount',
                                inputType: 'number',
                                formatFunc: (amount) => formatMoney(amount),
                                showColumn: !!showField('amountCol', 'campaign_discount'),
                                disabled: !!nonEditableField('amountCol', 'campaign_discount')
                              },
                              {
                                th: t(`${tKey}CAMPAIGN_DISCOUNT.PERCENTAGE`),
                                key: 'amountPercentage',
                                formatFunc: (amountPercentage) => formatPercent(amountPercentage?.toFixed(2)),
                                inputType: 'number',
                                showColumn: !!showField('percentageCol', 'campaign_discount')
                              },
                              {
                                th: t(`${tKey}CAMPAIGN_DISCOUNT.COST`),
                                key: 'cost',
                                inputType: 'number',
                                formatFunc: (amount) => formatMoney(amount),
                                showColumn: !!showField('costCol', 'campaign_discount'),
                                disabled: !!nonEditableField('costCol', 'campaign_discount')
                              },
                              {
                                th: t(`${tKey}CAMPAIGN_DISCOUNT.COST_CONCESSIONAIRE`),
                                key: 'costConcessionaire',
                                inputType: 'number',
                                formatFunc: (costConcessionaire) => formatMoney(costConcessionaire),
                                showColumn: !!showField('apConcCol', 'campaign_discount'),
                                disabled: !!nonEditableField('apConcCol', 'campaign_discount')
                              },
                              {
                                th: t(`${tKey}CAMPAIGN_DISCOUNT.PERCENTAGE`),
                                key: 'costConcessionPercentage',
                                formatFunc: (costConcessionPercentage) => formatPercent(costConcessionPercentage?.toFixed(2)),
                                inputType: 'number',
                                showColumn: !!showField('costConcessionPercentageCol', 'campaign_discount')
                              },
                              {
                                th: t(`${tKey}CAMPAIGN_DISCOUNT.FRA_SUP`),
                                key: 'supInvoice',
                                onlyShow: true,
                                showColumn: !!showField('fraSupCol', 'campaign_discount')
                              },
                              {
                                th: t(`${tKey}CAMPAIGN_DISCOUNT.IEM`),
                                key: 'iem',
                                check: true,
                                showColumn: !!showField('iemCol', 'campaign_discount'),
                                disabled: !!nonEditableField('iemCol', 'campaign_discount')
                              },
                              {
                                th: t(`${tKey}CAMPAIGN_DISCOUNT.PROVIDER`),
                                key: 'provider',
                                onlyShow: true,
                                showColumn: !!showField('providerCol', 'campaign_discount')
                              }
                            ]
                          }
                          editableObjectsArray={dossierEconomicComponent.campaigns || []}
                          readOnlyFields={true}
                          showDeleteAction={false}
                          showEditAction={false}
                          showAddButton={false}
                          columnsConfigTotales={
                            [
                              { key: 'name', onlyShow: true, className: 'maxW40' },
                              {
                                key: 'oculto',
                                showColumn: !!showField('campaignDiscountCol', 'campaign_discount')
                              },
                              {
                                th: t(`${tKey}CAMPAIGN_DISCOUNT.VTA_IMPORT`),
                                key: 'amount',
                                inputType: 'number',
                                formatFunc: (amount) => formatMoney(amount),
                                showColumn: !!showField('amountCol', 'campaign_discount')
                              },
                              {
                                th: t(`${tKey}CAMPAIGN_DISCOUNT.PERCENTAGE`),
                                key: 'percentage',
                                formatFunc: (percentage) => formatPercent(percentage),
                                inputType: 'number',
                                showColumn: !!showField('percentageCol', 'campaign_discount')
                              },
                              {
                                th: t(`${tKey}CAMPAIGN_DISCOUNT.COST`),
                                key: 'cost',
                                inputType: 'number',
                                formatFunc: (cost) => formatMoney(cost),
                                showColumn: !!showField('costCol', 'campaign_discount')
                              },
                              {
                                th: t(`${tKey}CAMPAIGN_DISCOUNT.COST_CONCESSIONAIRE`),
                                key: 'costConcessionaire',
                                inputType: 'number',
                                formatFunc: (costConcessionaire) => formatMoney(costConcessionaire),
                                showColumn: !!showField('apConcCol', 'campaign_discount')
                              },
                              {
                                th: t(`${tKey}CAMPAIGN_DISCOUNT.PERCENTAGE`),
                                key: 'costConcessionPercentage',
                                formatFunc: (costConcessionPercentage) => formatPercent(costConcessionPercentage),
                                inputType: 'number',
                                showColumn: !!showField('costConcessionPercentageCol', 'campaign_discount')
                              },
                              {
                                th: t(`${tKey}CAMPAIGN_DISCOUNT.FRA_SUP`),
                                key: 'supInvoice',
                                onlyShow: true,
                                showColumn: !!showField('fraSupCol', 'campaign_discount')
                              },
                              {
                                th: t(`${tKey}CAMPAIGN_DISCOUNT.IEM`),
                                key: 'iem',
                                showColumn: !!showField('iemCol', 'campaign_discount')
                              },
                              {
                                th: t(`${tKey}CAMPAIGN_DISCOUNT.PROVIDER`),
                                key: 'provider',
                                onlyShow: true,
                                showColumn: !!showField('providerCol', 'campaign_discount')
                              }
                            ]
                          }
                          editableObjectsArrayTotales={totalCampaign}
                          optionals={totalOptional ? totalOptional.amount : 0}
                          dossierType={dossierType}
                          deleteAction={deleteEconomicCampaign}
                        />
                      </Row>
                    </Panel.Collapse>
                  </Panel>
                </div>
              </Element>
            )}
              {
              // ADITIONAL DISCOUNT
              }
              {this.state.componentsConfiguration.additional_discount &&
            this.state.componentsConfiguration.additional_discount.permission > permissions.hidden &&
            (
              <Element name={buttonsSidebar.additional_discount}>
                <div className="dossier-panel">
                  <div className="header-panel" onClick={() => this.setState({ additional_discountOpen: !this.state.additional_discountOpen })}>
                    <div className="name-wrapper">
                      <i className={this.state.additional_discountOpen ? 'ico-arrow-black-up' : 'ico-arrow-black-down'} />
                      <h4>{t(`${tKey}ADITIONAL_DISCOUNT.TITLE_PANEL`)}</h4>
                      <EconomicPlanTotal
                        totals={totalDiscount}
                        totalText={t(`${tKey}ECONOMIC_PLAN.TOTALS`) + ' ' + t(`${tKey}ADITIONAL_DISCOUNT.VTA_IMPORT`)}
                        costText={t(`${tKey}ECONOMIC_PLAN.TOTALS`) + ' ' + t(`${tKey}ADITIONAL_DISCOUNT.COST`)} />
                    </div>
                  </div>
                  <Panel onToggle={() => {}} expanded={this.state.additional_discountOpen}>
                    <Panel.Collapse>
                      {!readOnlyFields && this.state.btnDiscounts && this.state.btnDiscounts.permission !== permissions.hidden && (
                        <Col sm={12} style={{ textAlign: 'right' }}>
                          <Button
                            id="btn_calculateDiscount"
                            className="btn-white btn-CalculateDiscount-blue btn-up-right" style={{ fontSize: '11px' }}
                            onClick={() => this.checkAmountIVAIEM(true)}
                            disabled={!this.props.pristine || this.state.btnDiscounts.permission < permissions.editable}>
                            {t(`${tKey}ECONOMIC_PLAN.CALC_DISCOUNT`)}
                          </Button>
                          {
                            !this.props.pristine
                              ? <OverlayTrigger placement="top" overlay={<Tooltip id="printTooltip">{t(`${tKey}ECONOMIC_PLAN.CALC_DISCOUNT_DISABLED`)}</Tooltip>}>
                                <Glyphicon glyph="info-sign" style={{ marginLeft: '3px', position: 'absolute' }}/>
                              </OverlayTrigger> : null
                          }
                        </Col>
                      )}
                      {this.state.fieldsConfiguration.additional_discount.discounts &&
                        this.state.fieldsConfiguration.additional_discount.discounts.permission > permissions.hidden &&
                      (
                        <Field responsive hover
                          component={EditableTable}
                          customClass=" -group"
                          t={t}
                          columnsConfig={
                            [
                              { key: 'oculto', onlyShow: true, className: 'maxW40' },
                              {
                                th: t(`${tKey}ADITIONAL_DISCOUNT.TITLE_PANEL`),
                                key: 'name',
                                showColumn: !!showField('additionalDiscountCol', 'additional_discount'),
                                disabled: !!nonEditableField('additionalDiscountCol', 'additional_discount')
                              },
                              {
                                th: t(`${tKey}ADITIONAL_DISCOUNT.VTA_IMPORT`),
                                key: 'amount',
                                money: true,
                                validationFunc: required,
                                showColumn: !!showField('amountCol', 'additional_discount'),
                                disabled: !!nonEditableField('amountCol', 'additional_discount'),
                                maxLength: MAX_LENGTH_MONEY
                              },
                              {
                                th: t(`${tKey}ADITIONAL_DISCOUNT.PERCENTAGE`),
                                key: 'percentage',
                                onlyShow: true,
                                perc: true,
                                inputType: 'number',
                                vehicleAmount: dossierEconomicComponent.modelPriceAmount,
                                showColumn: !!showField('percentageCol', 'additional_discount')
                              },
                              {
                                th: t(`${tKey}ADITIONAL_DISCOUNT.COST`),
                                key: 'cost',
                                discoint: true,
                                inputType: 'number',
                                onlyShow: true,
                                discounts: true,
                                formatFunc: (amount) => formatMoney(amount),
                                showColumn: !!showField('costCol', 'additional_discount')
                              },
                              {
                                th: t(`${tKey}ADITIONAL_DISCOUNT.FRA_SUP`),
                                key: 'supInvoice',
                                onlyShow: true,
                                showColumn: !!showField('fraSupCol', 'additional_discount')
                              },
                              {
                                th: t(`${tKey}ADITIONAL_DISCOUNT.IEM`),
                                key: 'iem',
                                check: true,
                                onlyShow: true,
                                showColumn: !!showField('iemCol', 'additional_discount')
                              },
                              {
                                th: t(`${tKey}ADITIONAL_DISCOUNT.PROVIDER`),
                                key: 'provider',
                                onlyShow: true,
                                showColumn: !!showField('providerCol', 'additional_discount')
                              }
                            ]
                          }
                          columnsConfigTotales={
                            [
                              { key: 'name', onlyShow: true },
                              {
                                key: 'oculto',
                                showColumn: !!showField('additionalDiscountCol', 'additional_discount')
                              },
                              {
                                th: t(`${tKey}ADITIONAL_DISCOUNT.VTA_IMPORT`),
                                key: 'amount',
                                inputType: 'number',
                                formatFunc: (amount) => formatMoney(amount),
                                showColumn: !!showField('amountCol', 'additional_discount')
                              },
                              {
                                th: t(`${tKey}ADITIONAL_DISCOUNT.PERCENTAGE`),
                                key: 'percentage',
                                onlyShow: true,
                                inputType: 'number',
                                showColumn: !!showField('percentageCol', 'additional_discount')
                              },
                              {
                                th: t(`${tKey}ADITIONAL_DISCOUNT.COST`),
                                key: 'cost',
                                inputType: 'number',
                                onlyShow: true,
                                formatFunc: (amount) => formatMoney(amount),
                                showColumn: !!showField('costCol', 'additional_discount')
                              },
                              {
                                th: t(`${tKey}ADITIONAL_DISCOUNT.FRA_SUP`),
                                key: 'supInvoice',
                                onlyShow: true,
                                showColumn: !!showField('fraSupCol', 'additional_discount')
                              },
                              {
                                th: t(`${tKey}ADITIONAL_DISCOUNT.IEM`),
                                key: 'iem',
                                onlyShow: true,
                                showColumn: !!showField('iemCol', 'additional_discount')
                              },
                              {
                                th: t(`${tKey}ADITIONAL_DISCOUNT.PROVIDER`),
                                key: 'provider',
                                onlyShow: true,
                                showColumn: !!showField('providerCol', 'additional_discount')
                              }
                            ]
                          }
                          id="discounts"
                          name="dossierEconomicComponent.discounts"
                          editableObjectsArrayTotales={totalDiscount}
                          editableObjectsArray={dossierEconomicComponent.discounts || []}
                          updateAction={updateDiscount}
                          createAction={createDiscount}
                          deleteAction={deleteDiscount}
                          readOnlyFields={readOnlyFields ||
                            this.state.fieldsConfiguration.additional_discount.discounts.permission < permissions.editable}
                          optionals={totalOptional ? totalOptional.amount ?? 0 : 0}
                          showDeleteAction= {!!showField('btnDeleteAction', 'additional_discount')}
                          showEditAction= {!!showField('btnEditAction', 'additional_discount')}
                          showAddButton= {!!showField('btnAddDiscount', 'additional_discount')}
                          dossierType = {dossierType}
                        />
                      )}
                    </Panel.Collapse>
                  </Panel>
                </div>
              </Element>
            )}
              {
                // SALE OTHERS - EXTRA SALES
              }
              {this.state.componentsConfiguration.sale_others &&
            this.state.componentsConfiguration.sale_others.permission > permissions.hidden &&
            (
              <Element name={buttonsSidebar.sale_others}>
                <div className="optionals-panel dossier-panel">
                  <div className="header-panel" onClick={() => this.setState({ sale_othersOpen: !this.state.sale_othersOpen })}>
                    <div className="name-wrapper">
                      <i className={this.state.sale_othersOpen ? 'ico-arrow-black-up' : 'ico-arrow-black-down'} />
                      <h4>{t(`${tKey}SALE_OTHERS.TITLE_PANEL`)}</h4>
                      <EconomicPlanTotal
                        totals={totalExtraSale}
                        totalText={t(`${tKey}ECONOMIC_PLAN.TOTALS`) + ' ' + t(`${tKey}SALE_OTHERS.VTA_IMPORT`)}
                        costText={t(`${tKey}ECONOMIC_PLAN.TOTALS`) + ' ' + t(`${tKey}SALE_OTHERS.COST`)}
                        bold = {true}
                        showCost={!!showField('costCol', 'sale_others')}
                      />
                    </div>
                  </div>
                  <Panel onToggle={() => {}} expanded={this.state.sale_othersOpen}>
                    <Panel.Collapse>
                      {!readOnlyFields && this.state.btnProcessingAgency && this.state.btnProcessingAgency.permission !== permissions.hidden && (
                        <Col sm={12} style={{ textAlign: 'right' }}>
                          <Button
                            id="btn_calculateProcessingAgency"
                            className="btn-white btn-CalculateProcessingAgency-blue btn-up-right" style={{ fontSize: '11px' }}
                            onClick={() => this.openOrCloseModalProcessingAgency(true)}
                            disabled={!this.props.pristine || this.state.btnProcessingAgency.permission < permissions.editable}>
                            {t(`${tKey}ECONOMIC_PLAN.CALCULATE_PROCESSING_AGENCY`)}
                          </Button>
                          {
                            !this.props.pristine
                              ? <OverlayTrigger placement="top" overlay={<Tooltip id="printTooltip">{t(`${tKey}ECONOMIC_PLAN.CALC_DISCOUNT_DISABLED`)}</Tooltip>}>
                                <Glyphicon glyph="info-sign" style={{ marginLeft: '3px', position: 'absolute' }}/>
                              </OverlayTrigger> : null
                          }
                        </Col>
                      )}
                      <Row>
                        {this.state.fieldsConfiguration.sale_others.extraSales &&
                        this.state.fieldsConfiguration.sale_others.extraSales.permission > permissions.hidden &&
                      (
                        <Field responsive hover
                          component={EditableTable}
                          customClass=" -group"
                          t={t}
                          columnsConfig={
                            [
                              { key: 'oculto', onlyShow: true, className: 'maxW40' },
                              {
                                th: t(`${tKey}SALE_OTHERS.TITLE_PANEL`),
                                key: 'typeId',
                                isSelectCompuesto: true,
                                selectConfig: { options: combos.extraSaleTypeCombo, valueKey: 'id', labelKey: 'value' },
                                validationFunc: required,
                                className: 'description-table-planteo',
                                keyColumnaAsociada: 'name',
                                showColumn: !!showField('otherSalesCol', 'sale_others'),
                                nonEditable: !!nonEditableField('otherSalesCol', 'sale_others')
                              },
                              { key: 'name', onlyShow: true },
                              {
                                th: t(`${tKey}SALE_OTHERS.VTA_IMPORT`),
                                key: 'amount',
                                money: true,
                                validationFunc: required,
                                showColumn: !!showField('amountCol', 'sale_others'),
                                disabled: !!nonEditableField('amountCol', 'sale_others'),
                                maxLength: MAX_LENGTH_MONEY
                              },
                              { key: 'percentage', onlyShow: true, inputType: 'number' },
                              {
                                th: t(`${tKey}SALE_OTHERS.COST`),
                                key: 'cost',
                                money: true,
                                validationFunc: required,
                                showColumn: !!showField('costCol', 'sale_others'),
                                disabled: !!nonEditableField('costCol', 'sale_others'),
                                maxLength: MAX_LENGTH_MONEY
                              },
                              {
                                th: t(`${tKey}SALE_OTHERS.FRA_SUP`),
                                key: 'supInvoice',
                                marcado: true,
                                showColumn: !!showField('fraSupCol', 'sale_others'),
                                disabled: !!nonEditableField('fraSupCol', 'sale_others')
                              },
                              {
                                th: t(`${tKey}SALE_OTHERS.IEM`),
                                key: 'iem',
                                marcado: true,
                                showColumn: !!showField('iemCol', 'sale_others'),
                                disabled: !!nonEditableField('iemCol', 'sale_others')
                              },
                              {
                                th: t(`${tKey}SALE_OTHERS.PROVIDER`),
                                key: 'provider',
                                showColumn: !!showField('providerCol', 'sale_others'),
                                disabled: !!nonEditableField('providerCol', 'sale_others')
                              }
                            ]
                          }
                          columnsConfigTotales={
                            [
                              { key: 'name', onlyShow: true },
                              {
                                key: 'oculto',
                                showColumn: !!showField('otherSalesCol', 'sale_others')
                              },
                              { key: 'oculto' },
                              {
                                th: t(`${tKey}SALE_OTHERS.VTA_IMPORT`),
                                key: 'amount',
                                inputType: 'number',
                                formatFunc: (amount) => formatMoney(amount),
                                showColumn: !!showField('amountCol', 'sale_others')
                              },
                              { key: 'percentage', onlyShow: true, inputType: 'number' },
                              {
                                th: t(`${tKey}SALE_OTHERS.COST`),
                                key: 'cost',
                                inputType: 'number',
                                formatFunc: (amount) => formatMoney(amount),
                                showColumn: !!showField('costCol', 'sale_others')
                              },
                              {
                                th: t(`${tKey}SALE_OTHERS.FRA_SUP`),
                                key: 'supInvoice',
                                showColumn: !!showField('fraSupCol', 'sale_others')
                              },
                              {
                                th: t(`${tKey}SALE_OTHERS.IEM`),
                                key: 'iem',
                                showColumn: !!showField('iemCol', 'sale_others')
                              },
                              {
                                th: t(`${tKey}SALE_OTHERS.PROVIDER`),
                                key: 'provider',
                                showColumn: !!showField('providerCol', 'sale_others')
                              }
                            ]
                          }
                          columnsConfigPaperwork={
                            [
                              { key: 'oculto', onlyShow: true },
                              {
                                paperwork: true,
                                showColumn: !!showField('otherSalesCol', 'sale_others')
                              },
                              { key: 'oculto' },
                              {
                                th: t(`${tKey}SUPPLIED.VTA_IMPORT`),
                                key: 'amount',
                                inputType: 'number',
                                formatFunc: (amount) => formatMoney(amount),
                                className: 'amount-table-planteo',
                                showColumn: !!showField('amountCol', 'sale_others')
                              },
                              { key: 'percentage', onlyShow: true, className: 'perc-table-planteo', inputType: 'number' },
                              {
                                th: t(`${tKey}SUPPLIED.COST`),
                                key: 'cost',
                                inputType: 'number',
                                formatFunc: (amount) => formatMoney(amount),
                                className: 'cost-table-planteo',
                                showColumn: !!showField('costCol', 'sale_others')
                              },
                              {
                                th: t(`${tKey}SUPPLIED.FRA_SUP`),
                                key: 'supInvoice',
                                isCheckbox: true,
                                className: 'fraSup-table-planteo',
                                showColumn: !!showField('fraSupCol', 'sale_others')
                              },
                              {
                                th: t(`${tKey}SUPPLIED.IEM`),
                                key: 'iem',
                                onlyShow: true,
                                isCheckbox: true,
                                className: 'iem-table-planteo',
                                showColumn: !!showField('iemCol', 'sale_others')
                              },
                              {
                                th: t(`${tKey}SUPPLIED.PROVIDER`),
                                key: 'provider',
                                className: 'provider-table-planteo',
                                showColumn: !!showField('providerCol', 'sale_others')
                              }
                            ]
                          }
                          id="extraSales"
                          name="dossierEconomicComponent.extraSales"
                          editableObjectsArrayTotales={totalExtraSale}
                          editableObjectsArrayPaperworkExtraSale={dossierEconomicComponent.paperworks || []}
                          editableObjectsArray={dossierEconomicComponent.extraSales || []}
                          updateAction={updateExtraSale}
                          createAction={createExtraSale}
                          deleteAction={deleteExtraSale}
                          readOnlyFields={readOnlyFields ||
                            this.state.fieldsConfiguration.sale_others.extraSales.permission < permissions.editable}
                          takeCareEdition={true}
                          showDeleteAction= {!!showField('btnDeleteAction', 'sale_others')}
                          showEditAction= {!!showField('btnEditAction', 'sale_others')}
                          showAddButton= {!!showField('btnAddSaleOthers', 'sale_others')}
                          dossierType={dossierType}
                        />
                      )}
                      </Row>
                    </Panel.Collapse>
                  </Panel>
                  <SmallLoaderPage />
                </div>
              </Element>
            )}
              {
                // TAXES
              }
              {this.state.componentsConfiguration.taxes &&
            this.state.componentsConfiguration.taxes.permission > permissions.hidden &&
            (
              <Element name={buttonsSidebar.taxes}>
                <div className="dossier-panel">
                  <div className="header-panel" onClick={ () => this.setState({ taxesOpen: !this.state.taxesOpen })}>
                    <div className="name-wrapper">
                      <i className={this.state.taxesOpen ? 'ico-arrow-black-up' : 'ico-arrow-black-down'}/>
                      <h4>{t(`${tKey}TAXES.TITLE_PANEL`)}</h4>
                    </div>
                  </div>
                  <Panel onToggle={() => {}} expanded={this.state.taxesOpen}>
                    <Panel.Collapse>
                      <Table hover responsive>
                        <thead>
                          <tr>
                            <th className="maxW40"></th>
                            {!!showField('ivaIemCol', 'taxes') && <th className="text-center" style={{ paddingLeft: '18px' }}></th>}
                            {!!showField('typeCol', 'taxes') && <th className="text-center maxW">{t(`${tKey}TAXES.TYPE`)}</th>}
                            {!!showField('baseAmountCol', 'taxes') && <th className="text-center">{t(`${tKey}TAXES.TAXABLE_BASE`)}</th>}
                            {!!showField('amountCol', 'taxes') && <th className="text-center">{t(`${tKey}TAXES.AMOUNT`)}</th>}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <Col sm={1} style={{ paddingTop: '8px' }}><span>IVA</span></Col>
                            </td>
                            {!!showField('ivaIemCol', 'taxes') &&
                              !!showField('ivaType', 'taxes') &&
                              <td>
                                <Field
                                  colSm={8}
                                  customClass="column-planteo-iem-iva"
                                  id="ivaTypeId"
                                  name="dossierEconomicComponent.ivaTypeId"
                                  component={InputSelect}
                                  options={combos.ivaTypeCombo}
                                  disabled={readOnlyFields || !!nonEditableField('ivaType', 'taxes')}
                                  valueKey="id"
                                  labelKey="value"
                                  onInputChange={(valueIva) => this.setState({ valueIva })}
                                />
                              </td>
                            }
                            {!!showField('typeCol', 'taxes') &&
                            <td className="text-right">
                              { this.isOtherIvaType(this.state.valueIva) ? (
                                <Col sm={5}>
                                  <Field
                                    customClass="iem-percentage"
                                    colSm={5}
                                    id="iemPercentage"
                                    name="dossierEconomicComponent.ivaPercentage"
                                    component={InputPercentage}
                                    decimals={numberOfDecimals}
                                    default={this.state.ivaPercentage}
                                    disabled={false}
                                    maxLength = {3}
                                    onBlur={(ivaPercentage) => { this.calculateIva(ivaPercentage.target.value) }}
                                    acceptNegativeValue = {false}
                                    maxPercentage = {DEFAULT_MAX_PERCENTAGE}
                                  />
                                </Col>) : (
                                <Col sm={5} style={{ width: '100%' }}>
                                  <div className="form-group" style={{ paddingTop: '8px', textAlign: 'center' }}>
                                    <span>
                                      {this.state.valueIva ? formatPercent(combos.ivaTypeCombo.find(x => x.id === this.state.valueIva).amountValue * 100, 0) : ''}
                                    </span>
                                  </div>
                                </Col>
                              )}
                            </td>}
                            {!!showField('baseAmountCol', 'taxes') && <td className="text-right" style={{ paddingRight: '15px' }}>{formatMoney(dossierEconomicComponent.ivaTaxableBase)}</td>}
                            {!!showField('amountCol', 'taxes') && <td className="text-right" style={{ paddingRight: '15px' }}>{formatMoney(dossierEconomicComponent.ivaAmount)}</td>}
                          </tr>
                          {!!showField('ivaIemCol', 'taxes') &&
                           !!showField('iemType', 'taxes') &&
                            <tr>
                              <td>
                                <Col sm={1} style={{ paddingTop: '8px' }}><span>IEM</span></Col>
                              </td>
                              {!!showField('ivaIemCol', 'taxes') &&
                              !!showField('iemType', 'taxes') &&
                              <td>
                                <Field
                                  colSm={8}
                                  customClass="column-planteo-iem-iva"
                                  id="iemTypeId"
                                  name="dossierEconomicComponent.iemTypeId"
                                  component={InputSelect}
                                  options={combos.iemType}
                                  disabled={readOnlyFields ||
                                    this.state.fieldsConfiguration.taxes.iemType.permission < permissions.editable}
                                  valueKey="id"
                                  labelKey="value"
                                  onInputChange={(valueIem) => this.setState({ valueIem })}
                                />
                              </td>
                              }
                              {!!showField('typeCol', 'taxes') &&
                                <td className="text-right">
                                  {this.state.valueIem === iemValues.other ? (
                                    <Col sm={5}>
                                      <Field
                                        customClass="iem-percentage"
                                        colSm={5}
                                        id="iemPercentage"
                                        name="dossierEconomicComponent.iemPercentage"
                                        component={InputPercentage}
                                        decimals={numberOfDecimals}
                                        default={this.state.valueIem.amountIem}
                                        disabled={this.state.valueIem !== iemValues.other}
                                        onBlur={(iemPercentage) => { this.calculateIem(iemPercentage.target.value) }}
                                        maxLength={MAX_LENGTH_PERCENTAGE}
                                      />
                                    </Col>) : (
                                    <Col sm={5} style={{ width: '100%' }}>
                                      <div className="form-group" style={{ paddingTop: '8px', textAlign: 'center' }}>
                                        <span>{this.state.valueIem && combos.iemType.find(iem => iem.id === this.state.valueIem)
                                          ? formatPercent(combos.iemType.find(iem => iem.id === this.state.valueIem).amountValue, numberOfDecimals)
                                          : ''}
                                        </span>
                                      </div>
                                    </Col>
                                  )}
                                </td>
                              }
                              {!!showField('baseAmountCol', 'taxes') && <td className="text-right" style={{ paddingRight: '15px' }}>{formatMoney(dossierEconomicComponent.iemTaxableBase)}</td>}
                              {!!showField('amountCol', 'taxes') && <td className="text-right" style={{ paddingRight: '15px' }}>{formatMoney(dossierEconomicComponent.iemAmount)}</td>}
                            </tr>
                          }
                        </tbody>
                      </Table>
                    </Panel.Collapse>
                  </Panel>
                  <SmallLoaderPage/>
                </div>
              </Element>
            )}
              {
              // SUPPLIED
              }
              {this.state.componentsConfiguration.supplied &&
            this.state.componentsConfiguration.supplied.permission > permissions.hidden &&
            (
              <Element name={buttonsSidebar.supplied}>
                <div className="dossier-panel">
                  <div className="header-panel" onClick={() => this.setState({ suppliedOpen: !this.state.suppliedOpen })}>
                    <div className="name-wrapper">
                      <i className={this.state.suppliedOpen ? 'ico-arrow-black-up' : 'ico-arrow-black-down'} />
                      <h4>{t(`${tKey}SUPPLIED.TITLE_PANEL`)}</h4>
                      <EconomicPlanTotal
                        totals={totalSuplidos}
                        totalText={t(`${tKey}ECONOMIC_PLAN.TOTALS`) + ' ' + t(`${tKey}SUPPLIED.VTA_IMPORT`)}
                        costText={t(`${tKey}ECONOMIC_PLAN.TOTALS`) + ' ' + t(`${tKey}SUPPLIED.COST`)}
                        bold={true}
                        showCost={!!showField('costCol', 'supplied')}
                      />
                    </div>
                  </div>
                  <Panel onToggle={() => {}} expanded={this.state.suppliedOpen}>
                    <Panel.Collapse>
                      <Row>
                        <Field responsive hover
                          component={EditableTable}
                          customClass=" -group"
                          id="supplieds"
                          name="dossierEconomicComponent.supplieds"
                          t={t}
                          columnsConfig={
                            [
                              { key: 'oculto', onlyShow: true, className: 'maxW40' },
                              {
                                th: t(`${tKey}SUPPLIED.TITLE_PANEL`),
                                key: 'typeId',
                                isSelect: true,
                                selectConfig: { options: combos.suppliedTypeCombo, valueKey: 'id', labelKey: 'value' },
                                validationFunc: required,
                                className: 'description-table-planteo',
                                showColumn: !!showField('suppliedCol', 'supplied'),
                                nonEditable: !!nonEditableField('suppliedCol', 'supplied')
                              },
                              {
                                th: t(`${tKey}SUPPLIED.VTA_IMPORT`),
                                key: 'amount',
                                money: true,
                                className: 'amount-table-planteo',
                                validationFunc: required,
                                showColumn: !!showField('amountCol', 'supplied'),
                                disabled: !!nonEditableField('amountCol', 'supplied'),
                                maxLength: MAX_LENGTH_MONEY
                              },
                              { key: 'percentage', onlyShow: true, className: 'perc-table-planteo', inputType: 'number', showColumn: false },
                              {
                                th: t(`${tKey}SUPPLIED.COST`),
                                key: 'cost',
                                inputType: 'number',
                                onlyShow: true,
                                formatFunc: (amount) => formatMoney(amount),
                                className: 'cost-table-planteo',
                                showColumn: !!showField('costCol', 'supplied')
                              },
                              {
                                th: t(`${tKey}SUPPLIED.FRA_SUP`),
                                key: 'supInvoice',
                                isCheckbox: true,
                                className: 'fraSup-table-planteo',
                                showColumn: !!showField('fraSupCol', 'supplied'),
                                disabled: !!nonEditableField('fraSupCol', 'supplied')
                              },
                              {
                                th: t(`${tKey}SUPPLIED.IEM`),
                                key: 'iem',
                                onlyShow: true,
                                className: 'iem-table-planteo',
                                showColumn: !!showField('iemCol', 'supplied')
                              },
                              {
                                th: t(`${tKey}SUPPLIED.PROVIDER`),
                                key: 'provider',
                                className: 'provider-table-planteo',
                                showColumn: !!showField('providerCol', 'supplied'),
                                disabled: !!nonEditableField('providerCol', 'supplied')
                              }
                            ]
                          }
                          columnsConfigPaperwork={
                            [
                              { key: 'oculto', onlyShow: true },
                              {
                                paperwork: true,
                                showColumn: this.state.fieldsConfiguration.supplied.suppliedCol ? this.state.fieldsConfiguration.supplied.suppliedCol.permission > permissions.hidden : false
                              },
                              {
                                th: t(`${tKey}SUPPLIED.VTA_IMPORT`),
                                key: 'amount',
                                inputType: 'number',
                                formatFunc: (amount) => formatMoney(amount),
                                className: 'amount-table-planteo',
                                showColumn: !!showField('amountCol', 'supplied')
                              },
                              { key: 'percentage', onlyShow: true, className: 'perc-table-planteo', inputType: 'number', showColumn: false },
                              {
                                th: t(`${tKey}SUPPLIED.COST`),
                                key: 'cost',
                                inputType: 'number',
                                formatFunc: (amount) => formatMoney(amount),
                                className: 'cost-table-planteo',
                                showColumn: !!showField('costCol', 'supplied')
                              },
                              {
                                th: t(`${tKey}SUPPLIED.FRA_SUP`),
                                key: 'supInvoice',
                                isCheckbox: true,
                                className: 'fraSup-table-planteo',
                                showColumn: !!showField('fraSupCol', 'supplied')
                              },
                              {
                                th: t(`${tKey}SUPPLIED.IEM`),
                                key: 'iem',
                                onlyShow: true,
                                className: 'iem-table-planteo',
                                showColumn: !!showField('iemCol', 'supplied')
                              },
                              {
                                th: t(`${tKey}SUPPLIED.PROVIDER`),
                                key: 'provider',
                                className: 'provider-table-planteo',
                                showColumn: !!showField('providerCol', 'supplied')
                              }
                            ]
                          }
                          columnsConfigSpecialHelp={
                            [
                              { key: 'oculto', onlyShow: true },
                              {
                                specialhelps: true,
                                showColumn: this.state.fieldsConfiguration.supplied.suppliedCol ? this.state.fieldsConfiguration.supplied.suppliedCol.permission > permissions.hidden : false
                              },
                              {
                                th: t(`${tKey}SUPPLIED.VTA_IMPORT`),
                                key: 'amount',
                                inputType: 'number',
                                formatFunc: (amount) => formatMoney(amount),
                                className: 'amount-table-planteo',
                                showColumn: !!showField('amountCol', 'supplied')
                              },
                              { key: 'percentage', onlyShow: true, className: 'perc-table-planteo', inputType: 'number', showColumn: false },
                              {
                                th: t(`${tKey}SUPPLIED.COST`),
                                key: 'cost',
                                inputType: 'number',
                                formatFunc: (amount) => formatMoney(amount),
                                className: 'cost-table-planteo',
                                showColumn: !!showField('costCol', 'supplied')
                              },
                              {
                                th: t(`${tKey}SUPPLIED.FRA_SUP`),
                                key: 'supInvoice',
                                isCheckbox: true,
                                className: 'fraSup-table-planteo',
                                showColumn: !!showField('fraSupCol', 'supplied')
                              },
                              {
                                th: t(`${tKey}SUPPLIED.IEM`),
                                key: 'iem',
                                onlyShow: true,
                                className: 'iem-table-planteo',
                                showColumn: !!showField('iemCol', 'supplied')
                              },
                              {
                                th: t(`${tKey}SUPPLIED.PROVIDER`),
                                key: 'provider',
                                className: 'provider-table-planteo',
                                showColumn: !!showField('providerCol', 'supplied')
                              }
                            ]
                          }
                          columnsConfigTotales={
                            [
                              { key: 'name', onlyShow: true },
                              {
                                key: 'oculto',
                                showColumn: this.state.fieldsConfiguration.supplied.suppliedCol ? this.state.fieldsConfiguration.supplied.suppliedCol.permission > permissions.hidden : false
                              },
                              {
                                th: t(`${tKey}SUPPLIED.VTA_IMPORT`),
                                key: 'amount',
                                inputType: 'number',
                                formatFunc: (amount) => formatMoney(amount),
                                className: 'amount-table-planteo',
                                showColumn: !!showField('amountCol', 'supplied')
                              },
                              { key: 'percentage', onlyShow: true, className: 'perc-table-planteo', inputType: 'number', showColumn: false },
                              {
                                th: t(`${tKey}SUPPLIED.COST`),
                                key: 'cost',
                                inputType: 'number',
                                formatFunc: (amount) => formatMoney(amount),
                                className: 'cost-table-planteo',
                                showColumn: !!showField('costCol', 'supplied')
                              },
                              {
                                th: t(`${tKey}SUPPLIED.FRA_SUP`),
                                key: 'supInvoice',
                                className: 'fraSup-table-planteo',
                                showColumn: !!showField('fraSupCol', 'supplied')
                              },
                              {
                                th: t(`${tKey}SUPPLIED.IEM`),
                                key: 'iem',
                                onlyShow: true,
                                className: 'iem-table-planteo',
                                showColumn: !!showField('iemCol', 'supplied')
                              },
                              {
                                th: t(`${tKey}SUPPLIED.PROVIDER`),
                                key: 'provider',
                                className: 'provider-table-planteo',
                                showColumn: !!showField('providerCol', 'supplied')
                              }
                            ]
                          }
                          columnsConfigIem={
                            [
                              { key: 'oculto', onlyShow: true },
                              {
                                key: 'name',
                                isIem: true,
                                showColumn: this.state.fieldsConfiguration.supplied.suppliedCol ? this.state.fieldsConfiguration.supplied.suppliedCol.permission > permissions.hidden : false
                              },
                              {
                                th: t(`${tKey}SUPPLIED.VTA_IMPORT`),
                                key: 'amount',
                                inputType: 'number',
                                formatFunc: (amount) => formatMoney(amount),
                                className: 'amount-table-planteo',
                                showColumn: !!showField('amountCol', 'supplied')
                              },
                              { key: 'percentage', onlyShow: true, className: 'perc-table-planteo', inputType: 'number', showColumn: false },
                              {
                                th: t(`${tKey}SUPPLIED.COST`),
                                key: 'cost',
                                inputType: 'number',
                                formatFunc: (amount) => formatMoney(amount),
                                className: 'cost-table-planteo',
                                showColumn: !!showField('costCol', 'supplied')
                              },
                              {
                                th: t(`${tKey}SUPPLIED.FRA_SUP`),
                                key: 'supInvoice',
                                isCheckbox: true,
                                className: 'fraSup-table-planteo',
                                showColumn: !!showField('fraSupCol', 'supplied')
                              },
                              {
                                th: t(`${tKey}SUPPLIED.IEM`),
                                key: 'iem',
                                onlyShow: true,
                                className: 'iem-table-planteo',
                                showColumn: !!showField('iemCol', 'supplied')
                              },
                              {
                                th: t(`${tKey}SUPPLIED.PROVIDER`),
                                key: 'provider',
                                className: 'provider-table-planteo',
                                showColumn: !!showField('providerCol', 'supplied')
                              }
                            ]
                          }
                          editableObjectsArrayTotales={totalSuplidos}
                          editableObjectsArrayPaperworkSupplied={dossierEconomicComponent.paperworks || []}
                          editableObjectsArraySecundary={dossierEconomicComponent.specialHelps || []}
                          editableObjectsArray={dossierEconomicComponent.supplieds || []}
                          editableObjectsArrayIemSupplied={this.state.fieldsConfiguration.supplied && this.state.fieldsConfiguration.supplied.iemCol && this.state.fieldsConfiguration.supplied.iemCol.permission > permissions.hidden ? iemSuppied : null}
                          updateAction={updateSupplied}
                          createAction={createSupplied}
                          deleteAction={deleteSupplied}
                          readOnlyFields={readOnlyFields ||
                            this.state.componentsConfiguration.supplied.permission < permissions.editable}
                          showDeleteAction= {!!showField('btnDeleteAction', 'supplied')}
                          showEditAction= {!!showField('btnEditAction', 'supplied')}
                          showAddButton={!!showField('btnAddSupplied', 'supplied')}
                          dossierType={dossierType}
                          deleteEconomicCampaign={deleteEconomicCampaign}
                        />
                      </Row>
                    </Panel.Collapse>
                  </Panel>
                </div>
              </Element>
            )}
              {
              // COST GIFTS - PRESENTS
              }
              {this.state.componentsConfiguration.costs_gifts &&
            this.state.componentsConfiguration.costs_gifts.permission > permissions.hidden &&
            (
              <Element name={buttonsSidebar.costs_gifts} className={buttonsSidebar.costs_gifts}>
                <div className="dossier-panel">
                  <div className="header-panel" onClick={() => this.setState({ costs_giftsOpen: !this.state.costs_giftsOpen })}>
                    <div className="name-wrapper">
                      <i className={this.state.costs_giftsOpen ? 'ico-arrow-black-up' : 'ico-arrow-black-down'} />
                      <h4>{t(`${tKey}COSTS_GIFTS.TITLE_PANEL`)}</h4>
                      <EconomicPlanTotal
                        totals={totalPresent}
                        totalText={t(`${tKey}ECONOMIC_PLAN.TOTALS`) + ' ' + t(`${tKey}COSTS_GIFTS.VTA_IMPORT`)}
                        costText={t(`${tKey}ECONOMIC_PLAN.TOTALS`) + ' ' + t(`${tKey}COSTS_GIFTS.COST`)}
                        hideTotalVta={true}
                        bold={true}
                        showCost={!!showField('costCol', 'costs_gifts')}
                      />
                    </div>
                  </div>
                  <Panel className="table-responsive" onToggle={() => {}} expanded={this.state.costs_giftsOpen}>
                    <Panel.Collapse>
                      <Row>
                        {<br></br>}
                        {this.state.fieldsConfiguration.costs_gifts.presents &&
                          this.state.fieldsConfiguration.costs_gifts.presents.permission > permissions.hidden &&
                        (
                          <Field responsive hover
                            component={EditableTable}
                            customClass=" -group"
                            id="presents"
                            name="dossierEconomicComponent.presents"
                            t={t}
                            columnsConfig={
                              [
                                { key: 'oculto', onlyShow: true, className: 'maxW40' },
                                {
                                  th: t(`${tKey}COSTS_GIFTS.TITLE_PANEL`),
                                  key: 'typeId',
                                  isSelectCompuesto: true,
                                  selectConfig: { options: combos.presentTypeCombo, valueKey: 'id', labelKey: 'value' },
                                  validationFunc: required,
                                  className: 'description-table-planteo',
                                  keyColumnaAsociada: 'name',
                                  showColumn: !!showField('costsPresentsCol', 'costs_gifts'),
                                  nonEditable: !!nonEditableField('costsPresentsCol', 'costs_gifts')
                                },
                                { key: 'name', onlyShow: true, style: { width: '1px' } },
                                { th: t(`${tKey}COSTS_GIFTS.VTA_IMPORT`), key: 'amount', inputType: 'number', formatFunc: (amount) => formatMoney(amount), nonEditable: true, className: 'amount-table-planteo', showColumn: false },
                                { key: 'percentage', onlyShow: true, className: 'perc-table-planteo', inputType: 'number', showColumn: false },
                                {
                                  th: t(`${tKey}COSTS_GIFTS.COST`),
                                  key: 'cost',
                                  money: true,
                                  className: 'cost-table-planteo',
                                  validationFunc: required,
                                  showColumn: !!showField('costCol', 'costs_gifts'),
                                  disabled: !!nonEditableField('costCol', 'costs_gifts'),
                                  maxLength: MAX_LENGTH_MONEY
                                },
                                {
                                  th: t(`${tKey}COSTS_GIFTS.PROVIDER`),
                                  key: 'provider',
                                  className: 'provider-table-planteo',
                                  showColumn: !!showField('providerCol', 'costs_gifts'),
                                  disabled: !!nonEditableField('providerCol', 'costs_gifts')
                                }
                              ]
                            }
                            columnsConfigPaperwork={
                              [
                                { key: 'oculto', onlyShow: true },
                                {
                                  paperwork: true,
                                  showColumn: !!showField('costsPresentsCol', 'costs_gifts')
                                },
                                { th: t(`${tKey}SUPPLIED.VTA_IMPORT`), key: 'amount', inputType: 'number', formatFunc: (amount) => formatMoney(amount), className: 'amount-table-planteo' },
                                { key: 'percentage', onlyShow: true, className: 'perc-table-planteo', inputType: 'number', showColumn: false },
                                {
                                  th: t(`${tKey}SUPPLIED.COST`),
                                  key: 'cost',
                                  inputType: 'number',
                                  formatFunc: (amount) => formatMoney(amount),
                                  className: 'cost-table-planteo',
                                  showColumn: !!showField('costCol', 'costs_gifts')
                                },
                                {
                                  th: t(`${tKey}SUPPLIED.PROVIDER`),
                                  key: 'provider',
                                  className: 'provider-table-planteo',
                                  showColumn: !!showField('providerCol', 'costs_gifts')
                                }
                              ]
                            }
                            columnsConfigTotales={
                              [
                                { key: 'name', onlyShow: true },
                                {
                                  key: 'oculto',
                                  showColumn: !!showField('costsPresentsCol', 'costs_gifts')
                                },
                                { key: 'oculto', onlyShow: true },
                                { th: t(`${tKey}COSTS_GIFTS.VTA_IMPORT`), key: 'amount', inputType: 'number', formatFunc: (amount) => formatMoney(amount), onlyShow: true, className: 'amount-table-planteo', showColumn: false },
                                { key: 'percentage', onlyShow: true, className: 'perc-table-planteo', inputType: 'number', showColumn: false },
                                {
                                  th: t(`${tKey}COSTS_GIFTS.COST`),
                                  key: 'cost',
                                  inputType: 'number',
                                  formatFunc: (amount) => formatMoney(amount),
                                  className: 'cost-table-planteo',
                                  showColumn: !!showField('costCol', 'costs_gifts')
                                },
                                {
                                  th: t(`${tKey}COSTS_GIFTS.PROVIDER`),
                                  key: 'provider',
                                  className: 'provider-table-planteo',
                                  showColumn: !!showField('providerCol', 'costs_gifts')
                                }
                              ]
                            }
                            editableObjectsArrayTotales={totalPresent}
                            editableObjectsArrayPaperworkPresent={dossierEconomicComponent.paperworks || []}
                            editableObjectsArray={dossierEconomicComponent.presents || []}
                            updateAction={updatePresent}
                            createAction={createPresent}
                            deleteAction={deletePresent}
                            readOnlyFields={readOnlyFields ||
                              this.state.fieldsConfiguration.costs_gifts.presents.permission < permissions.editable}
                            showDeleteAction= {!!showField('btnDeleteAction', 'costs_gifts')}
                            showEditAction= {!!showField('btnEditAction', 'costs_gifts')}
                            showAddButton={!!showField('btnAddCostsGifts', 'costs_gifts')}
                            dossierType={dossierType}
                          />
                        )}
                      </Row>
                    </Panel.Collapse>
                  </Panel>
                </div>
              </Element>
            )}
              {
              // ASSOCIATED COST
              }
              {this.state.componentsConfiguration.associated_costs &&
              this.state.componentsConfiguration.associated_costs.permission > permissions.hidden &&
            (
              <Element name={buttonsSidebar.associated_costs} className={buttonsSidebar.associated_costs}>
                <div className="dossier-panel">
                  <div className="header-panel" onClick={ () => this.setState({ associated_costsOpen: !this.state.associated_costsOpen })}>
                    <div className="name-wrapper">
                      <i className={this.state.associated_costsOpen ? 'ico-arrow-black-up' : 'ico-arrow-black-down'}/>
                      <h4>{t(`${tKey}ASSOCIATED_COSTS.TITLE_PANEL`)}</h4>
                      <EconomicPlanTotal
                        totals={totalAssociatedCosts}
                        totalText={t(`${tKey}ECONOMIC_PLAN.TOTALS`) + ' ' + t(`${tKey}ASSOCIATED_COSTS.VTA_IMPORT`)}
                        costText={t(`${tKey}ECONOMIC_PLAN.TOTALS`) + ' ' + t(`${tKey}COSTS_GIFTS.COST`)}
                        hideTotalVta={true}
                        bold={true}
                        showCost={!!showField('costCol', 'associated_costs')}
                      />
                    </div>
                  </div>
                  <Panel className="table-responsive" onToggle={() => {}} expanded={this.state.associated_costsOpen}>
                    <Panel.Collapse>
                      <Row>
                        <Field responsive hover
                          component={EditableTable}
                          customClass=" -group"
                          id="associatedCosts"
                          name="dossierEconomicComponent.associatedCosts"
                          t={t}
                          columnsConfig={
                            [
                              { key: 'oculto', onlyShow: true, className: 'maxW40' },
                              {
                                th: t(`${tKey}ASSOCIATED_COSTS.TITLE_PANEL`),
                                key: 'typeId',
                                isSelectCompuesto: true,
                                selectConfig: { options: combos.associatedCostTypeCombo, valueKey: 'id', labelKey: 'value', menuPlacement: 'auto' },
                                validationFunc: required,
                                className: 'description-table-planteo',
                                keyColumnaAsociada: 'name',
                                showColumn: !!showField('associatedCostsCol', 'associated_costs'),
                                nonEditable: !!nonEditableField('associatedCostsCol', 'associated_costs')
                              },
                              { key: 'name', onlyShow: true, style: { width: '1px' } },
                              { th: t(`${tKey}ASSOCIATED_COSTS.VTA_IMPORT`), key: 'amount', inputType: 'number', formatFunc: (amount) => formatMoney(amount), onlyShow: true, className: 'amount-table-planteo', showColumn: false },
                              { key: 'percentage', onlyShow: true, className: 'perc-table-planteo', inputType: 'number', showColumn: false },
                              {
                                th: t(`${tKey}ASSOCIATED_COSTS.COST`),
                                key: 'cost',
                                money: true,
                                className: 'cost-table-planteo',
                                validationFunc: required,
                                showColumn: !!showField('costCol', 'associated_costs'),
                                disabled: !!nonEditableField('costCol', 'associated_costs'),
                                maxLength: MAX_LENGTH_MONEY
                              },
                              { key: 'costByPercentage', onlyShow: true, button: true, className: 'provider-table-planteo', openOrCloseAssociatedCostPercentageModal: this.openOrCloseAssociatedCostPercentageModal.bind(this) },
                              {
                                th: t(`${tKey}ASSOCIATED_COSTS.PROVIDER`),
                                key: 'provider',
                                className: 'provider-table-planteo',
                                showColumn: !!showField('providerCol', 'associated_costs'),
                                disabled: !!nonEditableField('providerCol', 'associated_costs')
                              }
                            ]
                          }
                          columnsConfigPaperwork={
                            [
                              { key: 'oculto', onlyShow: true },
                              {
                                paperwork: true,
                                showColumn: !!showField('associatedCostsCol', 'associated_costs')
                              },
                              { key: 'oculto' },
                              { th: t(`${tKey}ASSOCIATED_COSTS.VTA_IMPORT`), key: 'amount', inputType: 'number', formatFunc: (amount) => formatMoney(amount), className: 'amount-table-planteo', showColumn: false },
                              { key: 'percentage', onlyShow: true, className: 'perc-table-planteo', inputType: 'number', showColumn: false },
                              {
                                th: t(`${tKey}ASSOCIATED_COSTS.COST`),
                                key: 'cost',
                                inputType: 'number',
                                formatFunc: (amount) => formatMoney(amount),
                                className: 'cost-table-planteo',
                                showColumn: !!showField('costCol', 'associated_costs')
                              },
                              { key: 'costByPercentage' },
                              {
                                th: t(`${tKey}ASSOCIATED_COSTS.PROVIDER`),
                                key: 'provider',
                                className: 'provider-table-planteo',
                                showColumn: !!showField('providerCol', 'associated_costs')
                              }
                            ]
                          }
                          columnsConfigTotales={
                            [
                              { key: 'name', onlyShow: true },
                              {
                                key: 'oculto',
                                showColumn: !!showField('associatedCostsCol', 'associated_costs')
                              },
                              { key: 'oculto' },
                              { th: t(`${tKey}ASSOCIATED_COSTS.VTA_IMPORT`), key: 'amount', inputType: 'number', formatFunc: (amount) => formatMoney(amount), nonEditable: true, className: 'amount-table-planteo', showColumn: false },
                              { key: 'percentage', onlyShow: true, className: 'perc-table-planteo', inputType: 'number', showColumn: false },
                              {
                                th: t(`${tKey}ASSOCIATED_COSTS.COST`),
                                key: 'cost',
                                inputType: 'number',
                                formatFunc: (amount) => formatMoney(amount),
                                className: 'cost-table-planteo',
                                showColumn: !!showField('costCol', 'associated_costs')
                              },
                              { key: 'oculto' },
                              {
                                th: t(`${tKey}ASSOCIATED_COSTS.PROVIDER`),
                                key: 'provider',
                                className: 'provider-table-planteo',
                                showColumn: !!showField('providerCol', 'associated_costs')
                              }
                            ]
                          }
                          editableObjectsArrayTotales={totalAssociatedCosts}
                          editableObjectsArrayPaperworkAssociatedCost={dossierEconomicComponent.paperworks || []}
                          editableObjectsArray={dossierEconomicComponent.associatedCosts || []}
                          updateAction={updateAssociatedCosts}
                          createAction={createAssociatedCosts}
                          deleteAction={deleteAssociatedCosts}
                          readOnlyFields={readOnlyFields ||
                            this.state.componentsConfiguration.associated_costs.permission < permissions.editable}
                          showDeleteAction= {!!showField('btnDeleteAction', 'associated_costs')}
                          showEditAction= {!!showField('btnEditAction', 'associated_costs')}
                          showAddButton={!!showField('btnAddAssociatedCosts', 'associated_costs')}
                          dossierType={dossierType}

                        />
                      </Row>
                    </Panel.Collapse>
                  </Panel>
                </div>
              </Element>
            )}
              {this.state.componentsConfiguration.reaconditioning && this.state.componentsConfiguration.reaconditioning.permission > permissions.hidden && dossierSubTypeId === dossierSubTypes.venta_vo && (
                <EconomicPlanReaconditioning
                  t={t}
                  dossierEconomicComponent = {dossierEconomicComponent}
                  combos = {combos}
                  reaconditioningOpen = { this.state.reaconditioningOpen }
                  openOrCloseReaconditioning = {this.openOrCloseReaconditioning.bind(this)}
                  totalReaconditioning = {totalReaconditioning}
                  fieldsConfiguration = {this.state.fieldsConfiguration}
                ></EconomicPlanReaconditioning>)}
            </Col>
          </Col>
        </Row>
      </>
    )
  }
}
export default (EconomicPlan)
