import React, { PureComponent } from 'react'
import { Row } from 'react-bootstrap'
import { Field } from 'redux-form'
import InputCheckBox from '../../../commons/form/InputCheckBox'
import InputSelect from '../../../commons/form/InputSelect'
import InputText from '../../../commons/form/InputText'
import { entityTypesId, permissions } from '../../../../constants/backendIds'
import { dossierTypes as dossierTypesConstant } from '../../../../constants/dossier/common/dossierTypes'
import NormalizedAddress from '../../../dossiers/common/client/NormalizedAddress'

class FinalClient extends PureComponent {
  constructor (props) {
    super()
    this.state = {
      combo: 0
    }
  }

  getEntityNameLabel () {
    const tKey = 'DOSSIER_COMPONENTS.ENTITY.'
    switch (this.props.dossier.entityComponent?.entityTypeId) {
      case entityTypesId.privateEntity:
        return this.props.t(`${tKey}NAME`)
      default:
        return this.props.t(`${tKey}REGISTERED_NAME`)
    }
  }

  getEntityTypeLabel () {
    const tKey = 'DOSSIER_COMPONENTS.ENTITY.'
    switch (this.props.dossierType) {
      case dossierTypesConstant.sales:
        return this.props.t(`${tKey}CLIENT_TYPE`)
      case dossierTypesConstant.purchase:
        return this.props.t(`${tKey}VENDOR_TYPE`)
      default:
        return this.props.t(`${tKey}ENTITY_TYPE`)
    }
  }

  getNumberLabel () {
    const tKey = 'DOSSIER_COMPONENTS.ENTITY.'
    switch (this.props.dossierType) {
      case dossierTypesConstant.sales:
        return this.props.t(`${tKey}MAIN_CLIENT_NUMBER`)
      case dossierTypesConstant.purchase:
        return this.props.t(`${tKey}MAIN_VENDOR_NUMBER`)
      default:
        return this.props.t(`${tKey}MAIN_ENTITY_NUMBER`)
    }
  }

  longSchema () {
    const {
      t,
      fieldsConfiguration,
      combos: {
        entityTypeCombo, channelCombo, segmentationCombo, iaeTypeCombo
      },
      dossier: { entityChildComponent },
      actions: {
        fetchEntityCountryTypeCombo,
        fetchEntityStateTypeCombo,
        fetchEntityCityTypeCombo,
        fetchEntityTownTypeCombo,
        fetchEntityColonyTypeCombo,
        fetchEntityCPTypeCombo,
        fetchClientDataAddress
      }
    } = this.props

    const tKey = 'DOSSIER_COMPONENTS.ENTITY.'
    return (
      <Row className='flexRow'>
        {fieldsConfiguration.freeLanceChild && fieldsConfiguration.freeLanceChild.permission !== permissions.hidden && entityChildComponent.entityTypeId === entityTypesId.privateEntity && (
          <Field
            colSm={12}
            id="freeLance"
            name="entityChildComponent.freeLance"
            component={InputCheckBox}
            placeholder={t(`${tKey}FREELANCE`)}
            disabled={true}
          />
        )}
        {fieldsConfiguration.entityTypeIdChild.permission !==
          permissions.hidden && (
          <Field
            colSm={4}
            id='entityTypeIdChild'
            name='entityChildComponent.entityTypeId'
            controlLabel={this.getEntityTypeLabel()}
            placeholder={this.getEntityTypeLabel()}
            component={InputSelect}
            options={entityTypeCombo}
            valueKey='id'
            labelKey='value'
            disabled={true}
          />
        )}
        {fieldsConfiguration.dniCifChild.permission !==
          permissions.hidden && (
          <Field
            id='dniCifChild'
            name='entityChildComponent.dniCif'
            disabled={true}
            colSm={4}
            controlLabel={t(`${tKey}DNI`)}
            placeholder={t(`${tKey}DNI`)}
            component={InputText}
          />
        )}
        {entityChildComponent.entityTypeId !==
          entityTypesId.recurrentEntity && [
          fieldsConfiguration.clientNumberChild.permission !==
            permissions.hidden && (
            <Field
              id='clientNumberChild'
              name='entityChildComponent.clientNumber'
              key='entityChildComponent.clientNumber'
              colSm={4}
              controlLabel={this.getNumberLabel()}
              placeholder={this.getNumberLabel()}
              component={InputText}
              disabled={true}
              forceControlLabelInOneLine={true}
            />
          ),
          <div key='clearfix_clientNumber' className='clearfix' />
        ]}
        {(entityChildComponent.entityTypeId === entityTypesId.privateEntity ||
          entityChildComponent.entityTypeId === entityTypesId.businessEntity) &&
          fieldsConfiguration.nameChild.permission !==
            permissions.hidden && (
          <Field
            id='nameChild'
            name='entityChildComponent.name'
            colSm={4}
            controlLabel={this.getEntityNameLabel()}
            placeholder={this.getEntityNameLabel()}
            component={InputText}
            disabled={true}
          />
        )}
        {entityChildComponent.entityTypeId === entityTypesId.privateEntity && [
          fieldsConfiguration.surname1Child.permission !==
            permissions.hidden && (
            <Field
              id='surname1Child'
              name='entityChildComponent.surname1'
              key='entityChildComponent.surname1'
              colSm={4}
              controlLabel={t(`${tKey}SURNAME1`)}
              placeholder={t(`${tKey}SURNAME1`)}
              component={InputText}
              disabled={true}
            />
          ),
          fieldsConfiguration.surname2Child.permission !==
            permissions.hidden && (
            <Field
              id='surname2Child'
              name='entityChildComponent.surname2'
              key='entityChildComponent.surname2'
              colSm={4}
              controlLabel={t(`${tKey}SURNAME2`)}
              placeholder={t(`${tKey}SURNAME2`)}
              component={InputText}
              disabled={true}
            />
          ),
          <div key='clearfix_surname2' className='clearfix' />
        ]}
        {entityChildComponent.entityTypeId === entityTypesId.businessEntity && [
          fieldsConfiguration.representativeDniChild.permission !==
            permissions.hidden && (
            <Field
              id='representativeDniChild'
              name='entityChildComponent.representativeDni'
              key='entityChildComponent.representativeDni'
              colSm={4}
              controlLabel={t(`${tKey}REPRESENTATIVE_DNI`)}
              placeholder={t(`${tKey}REPRESENTATIVE_DNI`)}
              component={InputText}
              disabled={true}
            />
          ),
          fieldsConfiguration.representativeNameChild.permission !==
            permissions.hidden && (
            <Field
              id='representativeNameChild'
              name='entityChildComponent.representativeName'
              key='entityChildComponent.representativeName'
              colSm={4}
              controlLabel={t(`${tKey}REPRESENTATIVE_NAME`)}
              placeholder={t(`${tKey}REPRESENTATIVE_NAME`)}
              component={InputText}
              disabled={true}
            />
          ),
          <div key='clearfix_representativeName' className='clearfix' />,
          fieldsConfiguration.representativeSurname1Child
            .permission !== permissions.hidden && (
            <Field
              id='representativeSurname1Child'
              name='entityChildComponent.representativeSurname1'
              key='entityChildComponent.representativeSurname1'
              colSm={4}
              controlLabel={t(`${tKey}REPRESENTATIVE_SURNAME1`)}
              placeholder={t(`${tKey}REPRESENTATIVE_SURNAME1`)}
              component={InputText}
              disabled={true}
              forceControlLabelInOneLine={true}
            />
          ),
          fieldsConfiguration.representativeSurname2Child
            .permission !== permissions.hidden && (
            <Field
              id='representativeSurname2Child'
              name='entityChildComponent.representativeSurname2'
              key='entityChildComponent.representativeSurname2'
              colSm={4}
              controlLabel={t(`${tKey}REPRESENTATIVE_SURNAME2`)}
              placeholder={t(`${tKey}REPRESENTATIVE_SURNAME2`)}
              component={InputText}
              disabled={true}
              forceControlLabelInOneLine={true}
            />
          )
        ]}
        {entityChildComponent.entityTypeId !==
          entityTypesId.recurrentEntity && [
          fieldsConfiguration.phoneChild.permission !==
            permissions.hidden && (
            <Field
              id='phoneChild'
              name='entityChildComponent.phone'
              key='entityChildComponent.phone'
              colSm={4}
              controlLabel={t(`${tKey}PHONE`)}
              placeholder={t(`${tKey}PHONE`)}
              component={InputText}
              disabled={true}
            />
          ),
          entityChildComponent.entityTypeId !==
            entityTypesId.businessEntity && (
            <div key='clearfix_phone' className='clearfix' />
          ),
          fieldsConfiguration.mobilePhoneChild.permission !==
            permissions.hidden && (
            <Field
              id='mobilePhoneChild'
              name='entityChildComponent.mobilePhone'
              key='entityChildComponent.mobilePhone'
              colSm={4}
              controlLabel={t(`${tKey}MOBILE`)}
              placeholder={t(`${tKey}MOBILE`)}
              component={InputText}
              disabled={true}
            />
          ),
          entityChildComponent.entityTypeId ===
            entityTypesId.businessEntity && (
            <div key='clearfix_mobilePhone' className='clearfix' />
          ),
          fieldsConfiguration.emailChild.permission !==
            permissions.hidden && (
            <Field
              id='emailChild'
              name='entityChildComponent.email'
              key='entityChildComponent.email'
              colSm={4}
              controlLabel={t(`${tKey}EMAIL`)}
              placeholder={t(`${tKey}EMAIL`)}
              component={InputText}
              disabled={true}
            />
          ),
          <NormalizedAddress
            t={t}
            fieldsConfiguration={{
              countryCode: fieldsConfiguration.countryCodeChild,
              stateCode: fieldsConfiguration.stateCodeChild,
              cityCode: fieldsConfiguration.cityCodeChild,
              townCode: fieldsConfiguration.townCodeChild,
              colonyCode: fieldsConfiguration.colonyCodeChild,
              cpCode: fieldsConfiguration.cpCodeChild
            }}
            readOnlyFields={true}
            allInformationDisabled={true}
            entityComponent= {this.props.dossier.entityChildComponent}
            entityTypeName= "entityChildComponent"
            actions={{
              fetchEntityCountryTypeCombo,
              fetchEntityStateTypeCombo,
              fetchEntityCityTypeCombo,
              fetchEntityTownTypeCombo,
              fetchEntityColonyTypeCombo,
              fetchEntityCPTypeCombo,
              fetchClientDataAddress
            }}
          />,
          fieldsConfiguration.addressChild &&
          fieldsConfiguration.addressChild.permission !== permissions.hidden && (
            <Field
              id="addressChild"
              name="entityChildComponent.address"
              key="entityChildComponent.address"
              colSm={8}
              controlLabel={t(`${tKey}ADDRESS`)}
              placeholder={t(`${tKey}ADDRESS`)}
              component={InputText}
              disabled={true}
            />
          ),
          fieldsConfiguration.externalNumberChild &&
          fieldsConfiguration.externalNumberChild.permission !==
            permissions.hidden && (
            <Field
              id="externalNumber"
              name="entityChildComponent.externalNumber"
              key="entityChildComponent.externalNumber"
              colSm={4}
              controlLabel={t(`${tKey}EXTERNAL_NUMBER`)}
              placeholder={t(`${tKey}EXTERNAL_NUMBER`)}
              component={InputText}
              disabled={true}
              maxLength="20"
            />
          ),
          fieldsConfiguration.startStreetChild &&
          fieldsConfiguration.startStreetChild.permission !==
            permissions.hidden && (
            <Field
              id="startStreet"
              name="entityChildComponent.startStreet"
              key="entityChildComponent.startStreet"
              colSm={8}
              controlLabel={t(`${tKey}START_STREET`)}
              placeholder={t(`${tKey}START_STREET`)}
              component={InputText}
              disabled={true}
              maxLength="200"
            />
          ),
          fieldsConfiguration.internalNumberChild &&
          fieldsConfiguration.internalNumberChild.permission !==
            permissions.hidden && (
            <Field
              id="internalNumber"
              name="entityChildComponent.internalNumber"
              key="entityChildComponent.internalNumber"
              colSm={4}
              controlLabel={t(`${tKey}INTERNAL_NUMBER`)}
              placeholder={t(`${tKey}INTERNAL_NUMBER`)}
              component={InputText}
              disabled={true}
              maxLength="20"
            />
          ),
          fieldsConfiguration.enndStreetChild &&
          fieldsConfiguration.enndStreetChild.permission !== permissions.hidden && (
            <Field
              id="enndStreet"
              name="entityChildComponent.enndStreet"
              key="entityChildComponent.enndStreet"
              colSm={8}
              controlLabel={t(`${tKey}ENND_STREET`)}
              placeholder={t(`${tKey}ENND_STREET`)}
              component={InputText}
              disabled={true}
              maxLength="200"
            />
          ),
          fieldsConfiguration.sourceChannelIdChild.permission !==
            permissions.hidden && (
            <Field
              colSm={4}
              id='sourceChannelIdChild'
              name='entityChildComponent.sourceChannelId'
              key='entityChildComponent.sourceChannelId'
              controlLabel={t(`${tKey}SOURCE_CHANNEL`)}
              placeholder={t(`${tKey}SOURCE_CHANNEL`)}
              component={InputSelect}
              options={channelCombo}
              valueKey='id'
              labelKey='value'
              disabled={true}
              customClass='hidden'
            />
          ),
          entityChildComponent.entityTypeId !==
            entityTypesId.businessEntity && (
            <div key='clearfix_sourceChannelId' className='clearfix' />
          ),
          fieldsConfiguration.segmentationIdChild.permission !==
            permissions.hidden && (
            <Field
              colSm={4}
              id='segmentationIdChild'
              name='entityChildComponent.segmentationId'
              key='entityChildComponent.segmentationId'
              controlLabel={t(`${tKey}SEGMENTATION`)}
              placeholder={t(`${tKey}SEGMENTATION`)}
              component={InputSelect}
              options={segmentationCombo}
              valueKey='id'
              labelKey='value'
              disabled={true}
              customClass='hidden'
            />
          ),
          fieldsConfiguration.iaeTypeIdChild.permission !==
            permissions.hidden &&
            entityChildComponent.entityTypeId ===
              entityTypesId.businessEntity && (
            <Field
              colSm={4}
              id='iaeTypeIdChild'
              name='entityChildComponent.iaeTypeId'
              key='entityChildComponent.iaeTypeId'
              controlLabel={t(`${tKey}IAE_TYPE`)}
              placeholder={t(`${tKey}IAE_TYPE`)}
              component={InputSelect}
              options={iaeTypeCombo}
              valueKey='id'
              labelKey='value'
              disabled={true}
            />
          )
        ]}
        {this.props.dossierType === dossierTypesConstant.paperwork &&
        fieldsConfiguration.differentPaperworkAddressChild
          .permission !== permissions.hidden ? (
            <Field
              colSm={4}
              id='differentPaperworkAddressChild'
              name='dossierPaperworkComponent.dossierPaperworkAddressComponent.differentPaperworkAddress'
              component={InputCheckBox}
              placeholder={t(`${tKey}PAPERWORK_ADDRESS_DIFFERENT`)}
              disabled={true}
              onInputChange={(value) =>
                this.setState({
                  isDifferentPaperworkAddress: value,
                  tabActiveKey: 1
                })
              }
            />
          ) : null}
        {fieldsConfiguration.rfcChild &&
          fieldsConfiguration.rfcChild.permission !==
            permissions.hidden && (
          <Field
            id='rfcChild'
            name='entityChildComponent.rfc'
            disabled={true}
            colSm={4}
            controlLabel={t(`${tKey}RFC`)}
            placeholder={t(`${tKey}RFC`)}
            component={InputText}
          />
        )}
      </Row>
    )
  }

  shortSchema () {
    const {
      t,
      fieldsConfiguration,
      combos: {
        entityTypeCombo,
        channelCombo,
        segmentationCombo,
        iaeTypeCombo
      },
      dossier: {
        entityChildComponent
      }
    } = this.props

    const tKey = 'DOSSIER_COMPONENTS.ENTITY.'
    return (
      <Row className='flexRow'>
        {fieldsConfiguration.freeLanceChild && fieldsConfiguration.freeLanceChild.permission !== permissions.hidden && entityChildComponent.entityTypeId === entityTypesId.privateEntity && (
          <Field
            colSm={12}
            id="freeLance"
            name="entityChildComponent.freeLance"
            component={InputCheckBox}
            placeholder={t(`${tKey}FREELANCE`)}
            disabled={true}
          />
        )}
        {fieldsConfiguration.entityTypeIdChild.permission !==
          permissions.hidden && (
          <Field
            colSm={4}
            id='entityTypeIdChild'
            name='entityChildComponent.entityTypeId'
            controlLabel={this.getEntityTypeLabel()}
            placeholder={this.getEntityTypeLabel()}
            component={InputSelect}
            options={entityTypeCombo}
            valueKey='id'
            labelKey='value'
            disabled={true}
          />
        )}
        {fieldsConfiguration.dniCifChild.permission !==
          permissions.hidden && (
          <Field
            id='dniCifChild'
            name='entityChildComponent.dniCif'
            disabled={true}
            colSm={4}
            controlLabel={t(`${tKey}DNI`)}
            placeholder={t(`${tKey}DNI`)}
            component={InputText}
          />
        )}
        {entityChildComponent.entityTypeId !==
          entityTypesId.recurrentEntity && [
          fieldsConfiguration.clientNumberChild.permission !==
            permissions.hidden && (
            <Field
              id='clientNumberChild'
              name='entityChildComponent.clientNumber'
              key='entityChildComponent.clientNumber'
              colSm={4}
              controlLabel={this.getNumberLabel()}
              placeholder={this.getNumberLabel()}
              component={InputText}
              disabled={true}
              forceControlLabelInOneLine={true}
            />
          ),
          <div key='clearfix_clientNumber' className='clearfix' />
        ]}
        {(entityChildComponent.entityTypeId === entityTypesId.privateEntity ||
          entityChildComponent.entityTypeId === entityTypesId.businessEntity) &&
          fieldsConfiguration.nameChild.permission !==
            permissions.hidden && (
          <Field
            id='nameChild'
            name='entityChildComponent.name'
            colSm={4}
            controlLabel={this.getEntityNameLabel()}
            placeholder={this.getEntityNameLabel()}
            component={InputText}
            disabled={true}
          />
        )}
        {entityChildComponent.entityTypeId === entityTypesId.privateEntity && [
          fieldsConfiguration.surname1Child.permission !==
            permissions.hidden && (
            <Field
              id='surname1Child'
              name='entityChildComponent.surname1'
              key='entityChildComponent.surname1'
              colSm={4}
              controlLabel={t(`${tKey}SURNAME1`)}
              placeholder={t(`${tKey}SURNAME1`)}
              component={InputText}
              disabled={true}
            />
          ),
          fieldsConfiguration.surname2Child.permission !==
            permissions.hidden && (
            <Field
              id='surname2Child'
              name='entityChildComponent.surname2'
              key='entityChildComponent.surname2'
              colSm={4}
              controlLabel={t(`${tKey}SURNAME2`)}
              placeholder={t(`${tKey}SURNAME2`)}
              component={InputText}
              disabled={true}
            />
          ),
          <div key='clearfix_surname2' className='clearfix' />
        ]}
        {entityChildComponent.entityTypeId === entityTypesId.businessEntity && [
          fieldsConfiguration.representativeDniChild.permission !==
            permissions.hidden && (
            <Field
              id='representativeDniChild'
              name='entityChildComponent.representativeDni'
              key='entityChildComponent.representativeDni'
              colSm={4}
              controlLabel={t(`${tKey}REPRESENTATIVE_DNI`)}
              placeholder={t(`${tKey}REPRESENTATIVE_DNI`)}
              component={InputText}
              disabled={true}
            />
          ),
          fieldsConfiguration.representativeNameChild.permission !==
            permissions.hidden && (
            <Field
              id='representativeNameChild'
              name='entityChildComponent.representativeName'
              key='entityChildComponent.representativeName'
              colSm={4}
              controlLabel={t(`${tKey}REPRESENTATIVE_NAME`)}
              placeholder={t(`${tKey}REPRESENTATIVE_NAME`)}
              component={InputText}
              disabled={true}
            />
          ),
          <div key='clearfix_representativeName' className='clearfix' />,
          fieldsConfiguration.representativeSurname1Child
            .permission !== permissions.hidden && (
            <Field
              id='representativeSurname1Child'
              name='entityChildComponent.representativeSurname1'
              key='entityChildComponent.representativeSurname1'
              colSm={4}
              controlLabel={t(`${tKey}REPRESENTATIVE_SURNAME1`)}
              placeholder={t(`${tKey}REPRESENTATIVE_SURNAME1`)}
              component={InputText}
              disabled={true}
              forceControlLabelInOneLine={true}
            />
          ),
          fieldsConfiguration.representativeSurname2Child
            .permission !== permissions.hidden && (
            <Field
              id='representativeSurname2Child'
              name='entityChildComponent.representativeSurname2'
              key='entityChildComponent.representativeSurname2'
              colSm={4}
              controlLabel={t(`${tKey}REPRESENTATIVE_SURNAME2`)}
              placeholder={t(`${tKey}REPRESENTATIVE_SURNAME2`)}
              component={InputText}
              disabled={true}
              forceControlLabelInOneLine={true}
            />
          )
        ]}
        {entityChildComponent.entityTypeId !==
          entityTypesId.recurrentEntity && [
          fieldsConfiguration.addressChild.permission !==
            permissions.hidden && (
            <Field
              id='addressChild'
              name='entityChildComponent.address'
              key='entityChildComponent.address'
              colSm={4}
              controlLabel={t(`${tKey}ADDRESS`)}
              placeholder={t(`${tKey}ADDRESS`)}
              component={InputText}
              disabled={true}
            />
          ),
          entityChildComponent.entityTypeId ===
            entityTypesId.businessEntity && (
            <div key='clearfix_address' className='clearfix' />
          ),
          fieldsConfiguration.cityChild.permission !==
            permissions.hidden && (
            <Field
              id='cityChild'
              name='entityChildComponent.city'
              key='entityChildComponent.city'
              colSm={4}
              controlLabel={t(`${tKey}CITY`)}
              placeholder={t(`${tKey}CITY`)}
              component={InputText}
              disabled={true}
            />
          ),
          fieldsConfiguration.stateChild.permission !==
            permissions.hidden && (
            <Field
              id='stateChild'
              name='entityChildComponent.state'
              key='entityChildComponent.state'
              colSm={4}
              controlLabel={t(`${tKey}STATE`)}
              placeholder={t(`${tKey}STATE`)}
              component={InputText}
              disabled={true}
            />
          ),
          entityChildComponent.entityTypeId !==
            entityTypesId.businessEntity && (
            <div key='clearfix_state' className='clearfix' />
          ),
          fieldsConfiguration.cpChild.permission !==
            permissions.hidden && (
            <Field
              id='cpChild'
              name='entityChildComponent.cp'
              key='entityChildComponent.cp'
              colSm={4}
              controlLabel={t(`${tKey}CP`)}
              placeholder={t(`${tKey}CP`)}
              component={InputText}
              disabled={true}
            />
          ),
          entityChildComponent.entityTypeId ===
            entityTypesId.businessEntity && (
            <div key='clearfix_cp' className='clearfix' />
          ),
          fieldsConfiguration.countryChild.permission !==
            permissions.hidden && (
            <Field
              id='countryChild'
              name='entityChildComponent.country'
              key='entityChildComponent.country'
              colSm={4}
              controlLabel={t(`${tKey}COUNTRY`)}
              placeholder={t(`${tKey}COUNTRY`)}
              component={InputText}
              disabled={true}
            />
          ),
          fieldsConfiguration.phoneChild.permission !==
            permissions.hidden && (
            <Field
              id='phoneChild'
              name='entityChildComponent.phone'
              key='entityChildComponent.phone'
              colSm={4}
              controlLabel={t(`${tKey}PHONE`)}
              placeholder={t(`${tKey}PHONE`)}
              component={InputText}
              disabled={true}
            />
          ),
          entityChildComponent.entityTypeId !==
            entityTypesId.businessEntity && (
            <div key='clearfix_phone' className='clearfix' />
          ),
          fieldsConfiguration.mobilePhoneChild.permission !==
            permissions.hidden && (
            <Field
              id='mobilePhoneChild'
              name='entityChildComponent.mobilePhone'
              key='entityChildComponent.mobilePhone'
              colSm={4}
              controlLabel={t(`${tKey}MOBILE`)}
              placeholder={t(`${tKey}MOBILE`)}
              component={InputText}
              disabled={true}
            />
          ),
          entityChildComponent.entityTypeId ===
            entityTypesId.businessEntity && (
            <div key='clearfix_mobilePhone' className='clearfix' />
          ),
          fieldsConfiguration.emailChild.permission !==
            permissions.hidden && (
            <Field
              id='emailChild'
              name='entityChildComponent.email'
              key='entityChildComponent.email'
              colSm={4}
              controlLabel={t(`${tKey}EMAIL`)}
              placeholder={t(`${tKey}EMAIL`)}
              component={InputText}
              disabled={true}
            />
          ),
          fieldsConfiguration.sourceChannelIdChild.permission !==
            permissions.hidden && (
            <Field
              colSm={4}
              id='sourceChannelIdChild'
              name='entityChildComponent.sourceChannelId'
              key='entityChildComponent.sourceChannelId'
              controlLabel={t(`${tKey}SOURCE_CHANNEL`)}
              placeholder={t(`${tKey}SOURCE_CHANNEL`)}
              component={InputSelect}
              options={channelCombo}
              valueKey='id'
              labelKey='value'
              disabled={true}
              customClass='hidden'
            />
          ),
          entityChildComponent.entityTypeId !==
            entityTypesId.businessEntity && (
            <div key='clearfix_sourceChannelId' className='clearfix' />
          ),
          fieldsConfiguration.segmentationIdChild.permission !==
            permissions.hidden && (
            <Field
              colSm={4}
              id='segmentationIdChild'
              name='entityChildComponent.segmentationId'
              key='entityChildComponent.segmentationId'
              controlLabel={t(`${tKey}SEGMENTATION`)}
              placeholder={t(`${tKey}SEGMENTATION`)}
              component={InputSelect}
              options={segmentationCombo}
              valueKey='id'
              labelKey='value'
              disabled={true}
              customClass='hidden'
            />
          ),
          fieldsConfiguration.iaeTypeIdChild.permission !==
            permissions.hidden &&
            entityChildComponent.entityTypeId ===
              entityTypesId.businessEntity && (
            <Field
              colSm={4}
              id='iaeTypeIdChild'
              name='entityChildComponent.iaeTypeId'
              key='entityChildComponent.iaeTypeId'
              controlLabel={t(`${tKey}IAE_TYPE`)}
              placeholder={t(`${tKey}IAE_TYPE`)}
              component={InputSelect}
              options={iaeTypeCombo}
              valueKey='id'
              labelKey='value'
              disabled={true}
            />
          )
        ]}
        {this.props.dossierType === dossierTypesConstant.paperwork &&
        fieldsConfiguration.differentPaperworkAddressChild
          .permission !== permissions.hidden ? (
            <Field
              colSm={4}
              id='differentPaperworkAddressChild'
              name='dossierPaperworkComponent.dossierPaperworkAddressComponent.differentPaperworkAddress'
              component={InputCheckBox}
              placeholder={t(`${tKey}PAPERWORK_ADDRESS_DIFFERENT`)}
              disabled={true}
              onInputChange={(value) =>
                this.setState({
                  isDifferentPaperworkAddress: value,
                  tabActiveKey: 1
                })
              }
            />
          ) : null}
        {fieldsConfiguration.rfcChild &&
          fieldsConfiguration.rfcChild.permission !==
            permissions.hidden && (
          <Field
            id='rfcChild'
            name='entityChildComponent.rfc'
            disabled={true}
            colSm={4}
            controlLabel={t(`${tKey}RFC`)}
            placeholder={t(`${tKey}RFC`)}
            component={InputText}
          />
        )}
      </Row>
    )
  }

  validateSchema () {
    const {
      fieldsConfiguration
    } = this.props
    return (
      (fieldsConfiguration.startStreetChild && fieldsConfiguration.startStreetChild.permission !== permissions.hidden) ||
      (fieldsConfiguration.enndStreetChild && fieldsConfiguration.enndStreetChild.permission !== permissions.hidden) ||
      (fieldsConfiguration.externalNumberChild && fieldsConfiguration.externalNumberChild.permission !== permissions.hidden) ||
      (fieldsConfiguration.internalNumberChild && fieldsConfiguration.internalNumberChild.permission !== permissions.hidden) ||
      (fieldsConfiguration.countryCodeChild && fieldsConfiguration.countryCodeChild.permission !== permissions.hidden) ||
      (fieldsConfiguration.stateCodeChild && fieldsConfiguration.stateCodeChild.permission !== permissions.hidden) ||
      (fieldsConfiguration.cityCodeChild && fieldsConfiguration.cityCodeChild.permission !== permissions.hidden) ||
      (fieldsConfiguration.townCodeChild && fieldsConfiguration.townCodeChild.permission !== permissions.hidden) ||
      (fieldsConfiguration.colonyCodeChild && fieldsConfiguration.colonyCodeChild.permission !== permissions.hidden) ||
      (fieldsConfiguration.cpCodeChild && fieldsConfiguration.cpCodeChild.permission !== permissions.hidden)
    )
  }

  render () {
    return (
      <div>
        { !this.validateSchema() ? (
          this.shortSchema()
        ) : (
          this.longSchema()
        ) }
      </div>
    )
  }
}

export default FinalClient
