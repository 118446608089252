import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { FormGroup, FormControl, Col, Button, ControlLabel } from 'react-bootstrap'
import { change as cancelChanges } from 'redux-form'
import { translate } from 'react-polyglot'

class TextInput extends PureComponent {
  constructor () {
    super()
    this.state = {
      isEditing: false,
      text: ''
    }
  }

  render () {
    const {
      t, id, label, input, placeholder, type, editable, generatePassword, readOnly, maxLength,
      meta: { error, touched, form }
    } = this.props
    return (
      <FormGroup className="forms-with-label">
        <Col sm={3} className="label-wrapper">
          <ControlLabel>{label}</ControlLabel>
        </Col>
        <Col sm={9} className={(this.state.isEditing ? 'editing ' : '') + (error && touched ? ' has-error' : '')}>
          <Col sm={11}>
            <FormControl
              disabled={(editable && !this.state.isEditing) || readOnly}
              id={id}
              type={type}
              placeholder={placeholder}
              {...input}
              maxLength={maxLength}
            />
            <div className="save-button-wrapper">
              {((editable && this.state.isEditing) || !editable) && generatePassword &&
                    <Button id="btn_generate_password" className="btn-generate-pass">{t('USER_MANAGEMENT.MODAL.GENERATE')}</Button>
              }
              {editable && this.state.isEditing &&
                    <Button className="btn-save-edit" onClick={() => {
                      this.setState({ isEditing: false, text: input.value })
                    }}>
                      <i className="ico-check" />
                    </Button>
              }
              {this.state.isEditing &&
                    <Button className="btn-cancel-edit" onClick={() => {
                      this.props.cancelChanges(form, input.name, this.state.text)
                      return this.setState({ isEditing: false })
                    }}>
                      <i className="ico-cancel" />
                    </Button>
              }
            </div>
          </Col>
          {error && touched &&
          <span className='help-block text-center'>{error}</span>
          }
        </Col>
        {editable &&
        <div className="edit-button-wrapper">
          {!this.state.isEditing &&
          <Button
            className="btn-edit"
            onClick={() => { this.setState({ isEditing: true, text: input.value }) }}
          >
            <i className="ico-edit-white" />
          </Button>
          }
        </div>
        }
      </FormGroup>
    )
  }
}
function mapDispatchToProps (dispatch) {
  return bindActionCreators({ cancelChanges }, dispatch)
}
export default connect(null, mapDispatchToProps)(translate()(TextInput))
