import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import EconomicPlanModal from '../../../../_v2/components/management/Modals/EconomicPlanModal'
import { translate } from 'react-polyglot'
import { fetchEntityFilterSubTypeCombo, fetchEntitySubTypeCombo } from '../../../../actions/combos/combos'
import { closeEconomicPlanModal, fetchEconomicPlanCombos, getEconomicPlanPermissions, simulateEconomicPlanFleet, addCampaign, saveEconomicPlanFleet, deleteEconomicCampaign, setSimulatedFlag, changeCodeCommissionFleet } from '../../../../actions/management/management'
import { openModal, openModal as openCommonModal, yesNoModal } from '../../../../actions/common'
import dossierSalesActions from '../../../../actions/dossier/sales'
import commentsActions from '../../../../actions/dossier/common/comments'
import buttonsSidebarActions from '../../../../actions/dossier/common/buttonsSidebar'
import scrollerActions from '../../../../actions/commons/scroller'
import { setEconomicPlanFleetProcess } from '../../../../actions/unattendedProcess/unattendedProcess'
import { openCodeCommissionModal } from '../../../../actions/dossier/common/codeCommisionModal'
import { openErrorMessageModal } from '../../../../actions/modals/messageModal'

export function mapStateToProps (state) {
  return {
    ...state.management.economicPlanModal,
    combos: state.combos
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchEntityFilterSubTypeCombo,
      openModal,
      fetchEntitySubTypeCombo,
      closeEconomicPlanModal,
      fetchEconomicPlanCombos,
      updateOptional: (dossierExtra, resolve, reject, dossierExtraType) => dossierSalesActions.updateExtra(dossierExtra, resolve, reject, dossierExtraType),
      createOptional: (dossierExtra, resolve, reject, dossierExtraType) => dossierSalesActions.createExtra(dossierExtra, resolve, reject, dossierExtraType),
      deleteOptional: (dossierExtra, dossierExtraType) => dossierSalesActions.deleteExtra(dossierExtra, dossierExtraType),
      ...dossierSalesActions,
      ...commentsActions,
      ...buttonsSidebarActions,
      ...scrollerActions,
      openCommonModal,
      yesNoModal,
      getEconomicPlanPermissions,
      simulateEconomicPlanFleet,
      addCampaign,
      saveEconomicPlanFleet,
      deleteEconomicCampaign,
      setSimulatedFlag,
      setEconomicPlanFleetProcess,
      openCodeCommissionModal,
      changeCodeCommissionFleet,
      openErrorMessageModal
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(EconomicPlanModal))
