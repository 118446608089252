import lastAccessDossier from '../../../constants/actions/dossier/common/lastAccess'

export function registerLastAccess (dossierId, isHistorical) {
  return {
    type: lastAccessDossier.REGISTER_LAST_ACCESS,
    dossierId,
    isHistorical
  }
}

export default {
  registerLastAccess
}
