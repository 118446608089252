import apiFetch from '../apiFetch'
export default function (token, conditionDocumentalCampaigId) {
  const queryParamsArray = [
    conditionDocumentalCampaigId ? `conditionDocumentalCampaigId=${conditionDocumentalCampaigId}` : null
  ]

  const queryParams = queryParamsArray.reduce(
    (result, query, idx) => result + (query ? (result ? `&${query}` : `?${query}`) : '')
    , ''
  )
  return apiFetch({ endPoint: `ConditionDocumentalCampaign/DeleteCondition${queryParams}`, method: 'DELETE', body: null, token: token })
}
