import React, { PureComponent } from 'react'
import { Col, Row } from 'react-bootstrap'
import { reduxForm, Field } from 'redux-form'
import InputSelect from '../../../commons/form/InputSelect'

class Tab1 extends PureComponent {
  render () {
    return (
      <Col sm={12} className="summary-tab1">
        <Row className="upper-content">
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Gestor:</span>
              <span className="second">Nombre del gestor</span>
            </div>
          </Col>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Empresa:</span>
              <span className="second">Empresa</span>
            </div>
          </Col>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Ubicación/Sucursal:</span>
              <span className="second">Ubicación/Sucursal</span>
            </div>
          </Col>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Tipo trámite:</span>
              <span className="second">Tipo trámite</span>
            </div>
          </Col>
        </Row>
        <Row className="upper-content">
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Compra VN/VO:</span>
              <span className="second">Compra VN/VO</span>
            </div>
          </Col>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Fecha/Hora pedido:</span>
              <span className="second">Fecha/Hora</span>
            </div>
          </Col>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Nº expediente:</span>
              <span className="second">Nº expediente</span>
            </div>
          </Col>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Nº expediente relacionado:</span>
              <span className="second">Nº expediente relacionado</span>
            </div>
          </Col>
        </Row>
        <Row className="upper-content">
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Estado del expediente:</span>
              <span className="second">Estado del expediente</span>
            </div>
          </Col>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Estado funcional:</span>
              <span className="second">Estado funcional</span>
            </div>
          </Col>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Estado documental:</span>
              <span className="second">Estado documental</span>
            </div>
          </Col>
        </Row>
        <Row className="upper-content">
          <Field
            controlLabel='Tramitador: *'
            colSm={4}
            id='agentId'
            name='agentId'
            placeholder='Tramitador'
            labelKey='agentId'
            valueKey='agentId'
            component={InputSelect}
          />
          <Field
            controlLabel='Gestoría:'
            colSm={4}
            id='agentId'
            name='agentId'
            placeholder='Elegir gestoría'
            labelKey='agentId'
            valueKey='agentId'
            component={InputSelect}
          />
        </Row>
      </Col>
    )
  }
}

export default reduxForm({ form: 'newForm' })(Tab1)
