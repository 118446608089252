import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import FuelModal from '../../../components/masters/Fuel/FuelMasterModal'
import { closeEditFuelModal, submitFuel } from '../../../actions/masters/masters'

export function mapStateToProps (state) {
  return {
    showFuelModal: state.entityMasters.showFuelModal,
    languageList: state.entityMasters.languageList,
    fuelTypeLanguages: state.entityMasters.fuelFields.fuelTypeLanguages || [],
    fuelId: state.entityMasters.fuelId,
    combos: {
      UOTreeCombo: state.combos.UOTreeCombo
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      closeEditFuelModal,
      submitFuel
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(FuelModal))
