import { bindActionCreators } from 'redux'
import { change } from 'redux-form'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { closeProcessingManagerModal, createProcessingManager, updateProcessingManager, fetchProcessingsAgencyManager, setProcessingAgencyManagerModalFilter } from '../../../actions/masters/processingManager/processingManager'
import ProcessingManagerMasterModal from '../../../components/masters/processingManager/ProcessingManagerMasterModal'

export function mapStateToProps (state) {
  return {
    ...state.processingManager.processingManagerModal
  }
}
export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      closeProcessingManagerModal,
      createProcessingManager,
      updateProcessingManager,
      fetchProcessingsAgencyManager,
      setProcessingAgencyManagerModalFilter,
      change
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(ProcessingManagerMasterModal))
