import powerbiActionTypes from '../../constants/actions/reports/powerbiReport'

function initialState () {
  return {}
}

function fetchPowerbiConfigSuccess (state, { data }) {
  return {
    ...state,
    ...data
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case powerbiActionTypes.FETCH_POWERBI_CONFIG_SUCCESS:
      return fetchPowerbiConfigSuccess(state, action)
    default:
      return state
  }
}
