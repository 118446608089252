import React, { PureComponent } from 'react'
import { Panel, Button, Table } from 'react-bootstrap'
import SmallLoaderPage from '../../../../containers/commons/SmallLoaderPage'
import { compareObjects } from '../../../../util/utils'
import { getFieldsPermisions } from '../../../../util/permisionFunctions'
import { Field } from 'redux-form'
import { permissions } from '../../../../constants/backendIds'
import BudgetRow from '../../../../_v2/components/dossiers/stock/BudgetRow'
import { currencySymbol } from '../../../../constants/dossier/common/currencies'
import setting from '../../../../setting'
import InputText from '../../../../_v2/components/commons/form/InputText'
import InputDatePicker from '../../../../_v2/components/commons/form/InputDatePicker'
import ComponentTitle from '../../../containers/dossiers/commons/ComponentTitlePage'

class DossierBudget extends PureComponent {
  constructor () {
    super()
    this.state = {
      open: false,
      fieldsConfiguration: {
        confirmationDoneButton: null,
        confirmationDate: null,
        deliveryDate: null,
        budgetRatingTable: null,
        acceptBudgetButton: null,
        approvalDate: null,
        comment: null,
        rejectBudgetButton: null
      }
    }
    this.loadBudget = this.loadBudget.bind(this)
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (this.props.dossier && this.props.dossier.dossierId) {
      if (!compareObjects(prevProps.componentConfiguration || {}, this.props.componentConfiguration, true)) {
        this.initializePermisions(this.props)
      }
      if (this.props.open && !this.state.open) {
        this.setState({ open: true })
        this.loadBudget()
      }
    }
    return prevProps
  }

  componentDidMount () {
    this.initializePermisions(this.props)
  }

  componentDidUpdate () {
    // don't remove use for getsnapshot
  }

  initializePermisions (props) {
    const fieldKeys = Object.keys(this.state.fieldsConfiguration)
    const newState = getFieldsPermisions(props.componentConfiguration.fieldsConfiguration, fieldKeys, props.componentConfiguration.permission)
    this.setState({ fieldsConfiguration: newState.fieldsConfiguration })
  }

  toggleComponent () {
    if (this.state.open) {
      this.props.actions.setActiveSection(false)
    }
    this.setState({ open: !this.state.open })
    if (this.props.isBudgetLoaded === false) {
      return this.loadBudget()
    }
  }

  loadBudget () {
    return new Promise((resolve, reject) => {
      this.props.handleToUpdateComponent(this.props)
      this.props.actions.getDossierBudget(this.props.dossier.dossierId, this.props.isHistorical, this.props.pristine, resolve, reject)
    })
  }

  showServiceObservationModal (show, service) {
    this.setState({ showModal: show, service: service })
  }

  render () {
    const {
      t, readOnlyFields, dossier: { dossierBudget }, actions: { confirmDateWithIdByStage, fetchDossierBudgetRating, deleteDossierBudgetRating, rejectDossierStockVoBudget, getDossierBudget }, dossierId, historical
    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.DOSSIER_BUDGET.'
    const deliveryDateFilled = this.props.dossier.dossierBudget && this.props.dossier.dossierBudget.deliveryDate !== undefined && this.props.dossier.dossierBudget.deliveryDate !== null
    return (
      <div className="dossier-Component">
        <div className="header-panel" onClick={() => this.toggleComponent()}>
          <ComponentTitle
            componentCode="DOSSIER_BUDGET"
            componentOpen={this.state.open}
            // extraInfo={'info extra'}
            // extraData={<a className="_Btn accept">texto</a>}
          />
        </div>
        <Panel expanded={this.state.open} onToggle={() => { }}>
          <Panel.Collapse>

            {!historical && <div className='flexInLine al-R'>
              { deliveryDateFilled && this.state.fieldsConfiguration.confirmationDoneButton && this.state.fieldsConfiguration.confirmationDoneButton.permission === permissions.editable && !readOnlyFields && (
                <Button className='_Btn new' onClick={() => confirmDateWithIdByStage(dossierId, getDossierBudget)} disabled={this.state.fieldsConfiguration.confirmationDoneButton.permission !== permissions.editable}>
                  {t(`${tKey}DONE_BUTTON`)}
                </Button>
              )}
              { this.state.fieldsConfiguration.acceptBudgetButton && this.state.fieldsConfiguration.acceptBudgetButton.permission === permissions.editable && !readOnlyFields && (
                <Button className='_Btn new' onClick={() => confirmDateWithIdByStage(dossierId, getDossierBudget)} disabled={this.state.fieldsConfiguration.acceptBudgetButton.permission !== permissions.editable}>
                  {t(`${tKey}ACCEPT_BUDGET_BUTTON`)}
                </Button>
              )}
            </div>}
            {this.state.fieldsConfiguration.budgetRatingTable && this.state.fieldsConfiguration.budgetRatingTable.permission !== permissions.hidden && (
              <div className="fieldsContainer">
                <div className="wrapper _nf">
                  <Table className="editable-table budget-table" hover responsive>
                    <thead>
                      <tr>
                        <th style={{ width: '60%' }}>{t(`${tKey}CONCEPT`)}</th>
                        <th>{t(`${tKey}AMOUNT`)}</th>
                        <th className='actions'>{t(`${tKey}ACTIONS`)}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        dossierBudget?.items?.map((item, idx) => {
                          return (
                            <BudgetRow
                              t={t}
                              tKey={tKey}
                              budget={item}
                              key={idx}
                              readOnlyFields={this.state.fieldsConfiguration.budgetRatingTable.permission !== permissions.editable || readOnlyFields}
                              deleteDossierBudgetRating={deleteDossierBudgetRating}
                              fetchDossierBudgetRating={fetchDossierBudgetRating}
                            />
                          )
                        })
                      }
                    </tbody>
                    <tfoot>
                      <tr>
                        <td>
                          {!historical && <Button className='_Btn new' onClick={() => fetchDossierBudgetRating()} disabled={this.state.fieldsConfiguration.budgetRatingTable.permission !== permissions.editable}>
                            {t(`${tKey}ADD_BUDGET_BUTTON`)}
                          </Button>}
                        </td>
                        <td>
                          <p>{t(`${tKey}TOTAL_AMOUNT`) + ': ' + (dossierBudget?.total || 0) + currencySymbol[setting.currency]}</p>
                        </td>
                        <td></td>
                      </tr>
                    </tfoot>
                  </Table>
                </div>
              </div>
            )}
            <div className="fieldsContainer">
              {this.state.fieldsConfiguration.deliveryDate && this.state.fieldsConfiguration.deliveryDate.permission !== permissions.hidden && (
                <Field
                  component={InputDatePicker}
                  colWidht={33} // 25, 33, 50, 75, 100 default=25

                  id="deliveryDate"
                  name="dossierBudget.deliveryDate"
                  controlLabel={t(`${tKey}DELIVERY_DATE`)}
                  placeholder={t(`${tKey}DELIVERY_DATE`)}
                  disabled={readOnlyFields || this.state.fieldsConfiguration.deliveryDate.permission !== permissions.editable }
                  customClass={readOnlyFields || this.state.fieldsConfiguration.deliveryDate.permission !== permissions.editable ? 'disabled' : ''}
                />
              )
              }
              {this.state.fieldsConfiguration.confirmationDate && this.state.fieldsConfiguration.confirmationDate.permission !== permissions.hidden && (
                <Field
                  component={InputDatePicker}
                  colWidht={33} // 25, 33, 50, 75, 100 default=25

                  id="confirmationDate"
                  name="dossierBudget.confirmationDate"
                  controlLabel={t(`${tKey}CONFIRMATION_DATE`)}
                  placeholder={t(`${tKey}CONFIRMATION_DATE`)}
                  disabled={readOnlyFields || this.state.fieldsConfiguration.confirmationDate.permission !== permissions.editable }
                  customClass={readOnlyFields || this.state.fieldsConfiguration.confirmationDate.permission !== permissions.editable ? 'disabled' : ''}
                />
              )
              }
              {this.state.fieldsConfiguration.approvalDate && this.state.fieldsConfiguration.approvalDate.permission !== permissions.hidden && (
                <Field
                  component={InputDatePicker}
                  colWidht={33} // 25, 33, 50, 75, 100 default=25

                  id="approvalDate"
                  name="dossierBudget.approvalDate"
                  controlLabel={t(`${tKey}APPROVAL_DATE`)}
                  placeholder={t(`${tKey}APPROVAL_DATE`)}
                  disabled={readOnlyFields || this.state.fieldsConfiguration.approvalDate.permission !== permissions.editable }
                  customClass={readOnlyFields || this.state.fieldsConfiguration.approvalDate.permission !== permissions.editable ? 'disabled' : ''}
                />
              )
              }
            </div>
            <div className='fieldsContainer'>
              {this.state.fieldsConfiguration.comment && this.state.fieldsConfiguration.comment && this.state.fieldsConfiguration.comment.permission !== permissions.hidden && (
                <Field
                  component={InputText}
                  colWidht={100} // 25, 33, 50, 75, 100 default=25
                  id="comment"
                  name="dossierBudget.comment"
                  controlLabel={t(`${tKey}COMMENT`)}
                  placeholder={t(`${tKey}COMMENT`)}
                  disabled={readOnlyFields || this.state.fieldsConfiguration.comment.permission !== permissions.editable }
                />
              )}
            </div>
            {this.state.fieldsConfiguration.rejectBudgetButton && this.state.fieldsConfiguration.rejectBudgetButton.permission !== permissions.hidden && !readOnlyFields && (
              <div className='flexInLine al-R'>
                <Button className='_Btn cancel'
                  onClick={() => rejectDossierStockVoBudget(dossierId)}
                  disabled={this.state.fieldsConfiguration.rejectBudgetButton.permission !== permissions.editable || !dossierBudget?.comment}>
                  {t(`${tKey}REJECT_BUDGET_BUTTON`)}
                </Button>
              </div>
            )}
          </Panel.Collapse>
        </Panel>
        <SmallLoaderPage />
      </div>
    )
  }
}
export default DossierBudget
