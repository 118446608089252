/* eslint-disable no-unused-vars */
import { put, call, select, takeEvery, all } from 'redux-saga/effects'
import { reset, initialize, change } from 'redux-form'
import { replace } from 'connected-react-router'
import { permissions } from '../../../constants/backendIds'
import { buttonsHeader, buttonsHeaderPermisionsIdReverse } from '../../../constants/dossier/common/buttonsHeader'
import dossierActionsTypes from '../../../constants/actions/dossier/dossier'
import { chassisLength } from '../../../constants/dossier/common/vehicle'
import { showLoader, hideLoader } from '../../../actions/common'
import messageModalActions from '../../../actions/modals/messageModal'
import { refreshDocumentManagementModal } from '../../../actions/dossier/common/documentManagementModal'
import { setActiveTab } from '../../../actions/dossier/common/buttonsHeader'
import { fetchStockDossierSuccess, deleteDossierRatingSuccess, addDossierRatingSuccess, updateDossierBudgetRatingModal, getDossierPhotoSuccess } from '../../../actions/dossier/stockDossier'
import { fetchCombosSuccess } from '../../../actions/combos/combos'
import { getAuth } from '../../../selectors/access/auth'
import { getActiveTab } from '../../../selectors/dossier/common/buttonsHeader'
import { getDossierRelatedDossiersConf } from '../../../selectors/dossier/common/dossierRelatedDossiers'
import getStockHistoricalDossier from '../../../services/stockDossier/get/getStockHistoricalDossier'
import getStockDossier from '../../../services/stockDossier/get/getStockDossier'
import delDossierRating from '../../../services/stockDossier/delete/deleteDossierRating'
import postDossierRating from '../../../services/stockDossier/post/postDossierRating'
import putDossierRating from '../../../services/stockDossier/put/putDossierRating'
import getDossierRating from '../../../services/stockDossier/get/getDossierRating'
import getDossierPhoto from '../../../services/stockDossier/get/getDossierPhoto'
import getDossierPhotoHistorical from '../../../services/stockDossier/get/getDossierPhotoHistorical'
import getStageCheckListStock from '../../../services/dossier/get/getStageCheckList'
import getDossierRelatedDossiers from '../../../services/dossier/get/getDossierRelatedDossiers'
import getDossierHistoricalRelatedDossiers from '../../../services/dossier/get/getDossierHistoricalRelatedDossiers'
import getPurchaseWithVehicleById from '../../../services/dossier/get/getPurchaseWithVehicleById'
import getPurchaseHistoricalWithVehicleById from '../../../services/dossier/get/getPurchaseHistoricalWithVehicleById'
import getPreviousUsage from '../../../services/previousUsage/getPreviousUsage'
import getBrands from '../../../services/brand/getBrands'
import getFuels from '../../../services/fuel/getFuels'
import getFamily from '../../../services/family/getFamily'
import getStages from '../../../services/stage/getStagesByDossierSubtype'
import { handleError } from '../../errors/errorManager'
import { switchFavoriteSuccess } from '../../../actions/dossier/common/statusHeader'
// Vehicle
import getModelCombo from '../../../services/model/getModelCombo'
import getCarlineCombo from '../../../services/carline/getCarlineCombo'
import getCatalogueCombo from '../../../services/catalogue/getCatalogueCombo'
import getColorCombo from '../../../services/color/getColorCombo'
import getTapestryCombo from '../../../services/tapestry/getTapestryCombo'
import getCatalogueModel from '../../../services/catalogueModel/getCatalogueModel'
// DossierBudget
import getDossierBudgetComponent from '../../../services/stockDossier/get/getDossierBudgetComponent'
import getDossierBudgetComponentHistorical from '../../../services/stockDossier/get/getDossierBudgetComponentHistorical'
import { yesNoModal } from '../../modalsListeners/yesNoModal'
import { initializeDossier } from '../commonDossierSagas'
import { dossierTypes as dossierTypesConstant } from '../../../constants/dossier/common/dossierTypes'
import getRepairshopOrganizedUnit from '../../../services/repairshop/getRepairshopOrganizedUnit'
import { valuesBooleanSlider } from '../../../constants/dossier/common/keyTabs'
import getDossierBudgetRating from '../../../services/stockDossier/get/getDossierBudgetRating'
import getDestinationCraneCombo from '../../../services/destinationCrane/getDestinationCraneCombo'

export function * fetchStockDossier ({ dossierId, resolve, reject, historical = false }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const dossierRelatedDossiersConf = yield select(getDossierRelatedDossiersConf)
    let dossier = {}
    let checkListHeader = []
    let dossierRelatedDossiers = []
    const auditInfo = []
    const stockDossierState = yield select(state => state.stockDossier)

    if (historical) {
      dossier = yield call(getStockHistoricalDossier, dossierId, auth.token)
      dossierRelatedDossiers = yield call(getDossierHistoricalRelatedDossiers, dossierId, dossierRelatedDossiersConf.orderby, auth.token)
      dossier.dossierPhotosComponent = yield call(getDossierPhotoHistorical, dossierId, auth.token)
    } else {
      dossier = yield call(getStockDossier, dossierId, auth.token)
      checkListHeader = yield call(getStageCheckListStock, dossierId, auth.token)
      dossierRelatedDossiers = yield call(getDossierRelatedDossiers, dossierId, dossierRelatedDossiersConf.orderby, auth.token)
      dossier.dossierRatingComponent = yield call(getDossierRating, dossierId, auth.token)
      dossier.dossierPhotosComponent = yield call(getDossierPhoto, dossierId, auth.token)
    }

    let dossierStockComponent = { ...dossier.dossierStockComponent }

    if (dossierStockComponent) {
      dossierStockComponent.indicatePhotos = valuesBooleanSlider(dossierStockComponent.indicatePhotos)
      dossierStockComponent.publishPhotos = valuesBooleanSlider(dossierStockComponent.publishPhotos)
      dossierStockComponent.fastline = valuesBooleanSlider(dossierStockComponent.fastline)
    }
    let reception = dossier.dossierReceptionComponent ? JSON.parse(JSON.stringify(dossier.dossierReceptionComponent)) : null
    if (reception && reception !== {}) {
      reception.prewash = reception.prewash === false ? 0 : reception.prewash === true ? 2 : 1
      dossier.dossierReceptionComponent.prewash = reception.prewash
      yield put(change('stock_dossier', 'reception', reception))
    }

    if (dossier.dossierPhotosComponent && dossier.dossierPhotosComponent !== {}) {
      dossier.dossierPhotosComponent.vehicleReserved = dossier.dossierPhotosComponent.vehicleReserved === false ? 0 : dossier.dossierPhotosComponent.vehicleReserved === true ? 2 : 1
      yield put(change('stock_dossier', 'dossierPhotosComponent', dossier.dossierPhotosComponent))
    }

    const [
      brandCombo,
      fuelCombo,
      previousUsageCombo,
      familyCombo,
      modelCombo,
      stagesCombo,
      repairshopCombo,
      destinationCraneCombo
    ] = yield all([
      call(getBrands, auth.token, dossier.dossierSubTypeId, dossier.organizedUnitId),
      call(getFuels, auth.token, historical),
      call(getPreviousUsage, dossier.organizedUnitId, auth.token),
      call(getFamily, auth.token),
      call(getModelCombo, auth.token),
      call(getStages, auth.token, dossier.dossierSubTypeId, true),
      call(getRepairshopOrganizedUnit, auth.token, dossier.organizedUnitId, dossier.dossierStockComponent.repairshopId),
      call(getDestinationCraneCombo, auth.token, dossier.organizedUnitId)
    ])

    let vehicleComponent = stockDossierState.vehicleComponent
    vehicleComponent = { ...vehicleComponent, ...dossier.vehicleComponent }
    if (vehicleComponent.vinChassis) {
      if (vehicleComponent.vinChassis.length === chassisLength.total) {
        vehicleComponent.vinChassisLeft = vehicleComponent.vinChassis.substring(0, chassisLength.left)
        vehicleComponent.vinChassisRight = vehicleComponent.vinChassis.substring(chassisLength.left, chassisLength.total)
      } else {
        vehicleComponent.vinChassisRight = vehicleComponent.vinChassis
      }
    }

    if (stockDossierState.vehicleComponent.updated === true) {
      vehicleComponent = yield call(getPurchaseWithVehicleById, dossierId, auth.token)
      vehicleComponent.updated = true
    }
    yield put(fetchStockDossierSuccess({ ...dossier, dossierStockComponent, dossierRelatedDossiers, auditInfo, vehicleComponent }, checkListHeader))

    yield put(switchFavoriteSuccess(dossier.dossierHeaderComponent.favorite))

    yield put(fetchCombosSuccess({
      brandCombo,
      fuelCombo,
      previousUsageCombo,
      familyCombo,
      modelCombo,
      stagesCombo,
      repairshopCombo,
      destinationCraneCombo
    }))

    const currentActiveTab = yield select(getActiveTab)
    let nextActiveTab = dossier.viewConfiguration.tabsConfiguration.filter((tabConf) => tabConf.permission > permissions.hidden)
    nextActiveTab = nextActiveTab.length > 0 ? nextActiveTab[0] : currentActiveTab
    if (nextActiveTab !== currentActiveTab) yield put(setActiveTab(buttonsHeaderPermisionsIdReverse[nextActiveTab.code]))

    if (!historical && nextActiveTab === buttonsHeader.gestor_documental) {
      yield put(refreshDocumentManagementModal(dossierId))
    }

    if (resolve) yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.status === 301) {
        yield put(replace(`/dossier${customHandlerError.json}`))
      } else {
        yield put(messageModalActions.openErrorMessageModal('MESSAGES.STOCK_DOSSIER.ERROR_GET_DOSSIER', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
      if (reject) yield call(reject, customHandlerError)
    }
    const sessionHandler = function * () {
      yield put(reset('stock_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchStockDossier () {
  yield takeEvery(dossierActionsTypes.FETCH_STOCK_DOSSIER, fetchStockDossier)
}

export function * refreshStockDossierFromDocumentModal (documentEditionalModalState) {
  try {
    yield put(showLoader())
    const stockDossier = yield select(state => state.stockDossier)
    const auth = yield select(getAuth)
    const dossierId = stockDossier.dossierId
    const [
      dossier,
      checkListHeader
    ] = yield all([
      call(getStockDossier, dossierId, auth.token),
      call(getStageCheckListStock, dossierId, auth.token)
    ])

    yield put(fetchStockDossierSuccess({ ...dossier }, checkListHeader))

    if (!documentEditionalModalState && !documentEditionalModalState.dirty) {
      const updateStockDossier = yield select(state => state.stockDossier)
      yield put(initialize('stock_dossier', { ...updateStockDossier }))
    }
    yield put(hideLoader())
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.STOCK_DOSSIER.ERROR_GET_DOSSIER', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('stock_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * fetchStockDossierVehicle ({ dossierId, resolve, reject, pristine }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const stockDossier = yield select(state => state.stockDossier)
    const vehicleComponent = yield call(getPurchaseWithVehicleById, dossierId, auth.token)
    vehicleComponent.updated = true

    yield put(change('stock_dossier', 'vehicleComponent', vehicleComponent))
    yield put(fetchStockDossierSuccess({ vehicleComponent: vehicleComponent }))
    if (pristine === true || pristine === undefined) {
      yield put(initialize('stock_dossier', { ...stockDossier, vehicleComponent: vehicleComponent }))
    }

    if (resolve) yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.STOCK_DOSSIER.ERROR_GET_DOSSIER', customHandlerError.json ? customHandlerError.json.tag : ''))
      if (reject) yield call(reject, customHandlerError)
    }
    const sessionHandler = function * () {
      yield put(reset('stock_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchStockVehicle () {
  yield takeEvery(dossierActionsTypes.FETCH_STOCK_DOSSIER_VEHICLE, fetchStockDossierVehicle)
}

export function * fetchStockDossierVehicleHistorical ({ dossierId, resolve, reject }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const vehicleComponent = yield call(getPurchaseHistoricalWithVehicleById, dossierId, auth.token)
    yield put(change('stock_dossier', 'vehicleComponent', vehicleComponent.vehicleId ? vehicleComponent : {}))
    yield put(fetchStockDossierSuccess({ vehicleComponent }))
    let carlineCombo = []; let catalogueCombo = []; let colorCombo = []; let tapestryCombo = []

    if (vehicleComponent.brandId) {
      carlineCombo = yield call(getCarlineCombo, auth.token, vehicleComponent.brandId)
    }
    if (vehicleComponent.carlineId) {
      catalogueCombo = yield call(getCatalogueCombo, auth.token, vehicleComponent.carlineId)
    }
    if (vehicleComponent.modelId && vehicleComponent.catalogueId) {
      colorCombo = yield call(getColorCombo, auth.token, vehicleComponent.catalogueId, vehicleComponent.modelId)
      tapestryCombo = yield call(getTapestryCombo, auth.token, vehicleComponent.catalogueId, vehicleComponent.modelId)
      var catalogueModel = yield call(getCatalogueModel, auth.token, vehicleComponent.catalogueId, vehicleComponent.modelId)
      vehicleComponent.model = catalogueModel.description || ''
      vehicleComponent.catalogueModelId = catalogueModel.catalogueModelId || ''
    }

    yield put(fetchCombosSuccess({ carlineCombo, catalogueCombo, colorCombo, tapestryCombo }))
    if (resolve) yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.STOCK_DOSSIER.ERROR_GET_DOSSIER', customHandlerError.json ? customHandlerError.json.tag : ''))
      if (reject) yield call(reject, customHandlerError)
    }
    const sessionHandler = function * () {
      yield put(reset('stock_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchStockDossierVehicleHistorical () {
  yield takeEvery(dossierActionsTypes.FETCH_STOCK_DOSSIER_VEHICLE_HISTORICAL, fetchStockDossierVehicleHistorical)
}

export function * getDossierBudget ({ dossierId, isHistorical, pristine, resolve, reject }) {
  const stockDossier = yield select(state => state.stockDossier)
  let dossierBudget = {}
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    if (isHistorical) {
      dossierBudget = yield call(getDossierBudgetComponentHistorical, dossierId, auth.token)
    } else {
      dossierBudget = yield call(getDossierBudgetComponent, dossierId, auth.token)
    }
    yield put(change('stock_dossier', 'dossierBudget', dossierBudget))
    yield put(fetchStockDossierSuccess({ ...stockDossier, dossierBudget: dossierBudget }))
    if (pristine === true || pristine === undefined) {
      yield put(initialize('stock_dossier', { ...stockDossier, dossierBudget: dossierBudget }))
    }
    if (resolve) yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          yield call(initializeDossier, dossierTypesConstant.stock, stockDossier)
        }
        if (reject) yield call(reject, customHandlerError)
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('stock_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * deleteDossierRating ({ ratingId, resolve, reject }) {
  try {
    const confirm = yield call(yesNoModal, 'deleteGeneric')
    let dossierRatingComponent = null
    if (confirm) {
      yield put(showLoader())
      const auth = yield select(getAuth)
      yield call(delDossierRating, ratingId, auth.token)
      const stockDossier = yield select(state => state.stockDossier)
      const dossierRatingComponent = yield call(getDossierRating, stockDossier.dossierId, auth.token)
      yield put(deleteDossierRatingSuccess({ dossierRatingComponent }))
      yield put(initialize('stock_dossier', { ...stockDossier }))
    }
    return resolve(dossierRatingComponent)
  } catch (error) {
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDeleteDossierRating () {
  yield takeEvery(dossierActionsTypes.DELETE_DOSSIER_RATING, deleteDossierRating)
}

export function * addDossierRating ({ ratingId, dossierRatingRatingId, resolve, reject }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const stockDossier = yield select(state => state.stockDossier)
    if (dossierRatingRatingId !== null) {
      yield call(putDossierRating, dossierRatingRatingId, { ratingId: ratingId }, auth.token)
    } else {
      yield call(postDossierRating, stockDossier.dossierId, { ratingId: ratingId }, auth.token)
    }
    const dossierRatingComponent = yield call(getDossierRating, stockDossier.dossierId, auth.token)
    yield put(addDossierRatingSuccess({ dossierRatingComponent }))
    yield put(initialize('stock_dossier', { ...stockDossier }))
    return resolve(dossierRatingComponent)
  } catch (error) {
  } finally {
    yield put(hideLoader())
  }
}

export function * watchGetDossierBudget () {
  yield takeEvery(dossierActionsTypes.STOCK_DOSSIER_GET_BUDGET, getDossierBudget)
}

export function * watchAddDossierRating () {
  yield takeEvery(dossierActionsTypes.ADD_DOSSIER_RATING, addDossierRating)
}

export function * fetchDossierBudgetRating ({ dossierBudgetRatingId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield put(updateDossierBudgetRatingModal(dossierBudgetRatingId))
    if (dossierBudgetRatingId) {
      const budgetRating = yield call(getDossierBudgetRating, dossierBudgetRatingId, auth.token)
      yield put(initialize('budgetRatingModal', budgetRating))
    } else {
      yield put(initialize('budgetRatingModal', {}))
    }
  } catch (error) {
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchDossierBudgetRating () {
  yield takeEvery(dossierActionsTypes.FETCH_DOSSIER_BUDGET_RATING, fetchDossierBudgetRating)
}

export function * getDossierPhotos ({ dossierId, resolve, reject }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const stockDossier = yield select(state => state.stockDossier)
    const dossierPhotosComponent = yield call(getDossierPhoto, dossierId, auth.token)
    yield put(getDossierPhotoSuccess({ dossierPhotosComponent }))
    yield put(initialize('stock_dossier', { ...stockDossier }))
    return resolve(dossierPhotosComponent)
  } catch (error) {
  } finally {
    yield put(hideLoader())
  }
}

export function * watchGetDossierPhoto () {
  yield takeEvery(dossierActionsTypes.GET_DOSSIER_PHOTO, getDossierPhotos)
}
