import React, { PureComponent } from 'react'
import { Button, Modal, Col, Row, Form } from 'react-bootstrap'
import { reduxForm, Field } from 'redux-form'
import InputText from '../../../components/commons/form/InputText'
import InputSelect from '../../../components/commons/form/InputSelect'
import { getFieldsPermisions } from '../../../util/permisionFunctions'
import { dossierTypes as dossierTypesConstant } from '../../../constants/dossier/common/dossierTypes'

class CancelReasonModal extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      reason: null,
      comment: null
    }
  }

  initializePermisions (props) {
    if (props && props.componentConfiguration && props.componentConfiguration.fieldsConfiguration) {
      let fieldKeys = Object.keys(this.state.fieldsConfiguration)
      const newState = getFieldsPermisions(props.componentConfiguration.fieldsConfiguration, fieldKeys, props.componentConfiguration.permission)
      this.setState({ ...this.state, ...newState })
    }
  }

  fetchCancelCombo () {
    if (this.props.actions.fetchCancelCombo) {
      this.props.actions.fetchCancelCombo()
      this.setState({ combos: this.props.combos })
    }
  }

  componentWillMount (prevProps) {
    if (this.props.actions.fetchCancelCombo) {
      this.initializePermisions(this.props)
      this.props.actions.fetchCancelCombo()
      return this.props
    }
  }

  componentDidUpdate () {}

  rejectActionPromise (values) {
    return new Promise((resolve, reject, pristine) => {
      return this.props.actions.rejectAction(values, resolve, reject, pristine)
    })
  }

  rejectAction (values) {
    if ((values.reason || values.comment)) {
      if ((values.reason === 7 && values.comment) || values.reason !== 7) {
        this.rejectActionPromise(values).then(() => this.props.actions.closeModal())
      }
    }
  }

  reasonField (t) {
    return (
      this.props?.type === dossierTypesConstant.sales &&
      <Field
        colSm={12}
        controlLabel={t('DOSSIER_COMPONENTS.CANCEL_REASON_MODAL.REASON')}
        name="reason"
        id="reason"
        value="reason"
        placeholder={t('DOSSIER_COMPONENTS.CANCEL_REASON_MODAL.REASON')}
        component={InputSelect}
        valueKey="id"
        labelKey="value"
        options={this.props.combos}
        isClerable= {false}
        multi={false}
        onInputChange={(value) => this.setState({ reason: value })}
        customClass='dashboard-select'
      />
    )
  }

  render () {
    const { t, handleSubmit, showModal, actions: { closeModal } } = this.props
    return (
      <Modal className="add-user-modal" show={showModal} onHide={closeModal} backdrop={'static'}>
        <Form autoComplete="off" onSubmit={handleSubmit(this.rejectAction.bind(this))}>
          <Modal.Header closeButton>
            <Modal.Title>{t('DOSSIER_COMPONENTS.CANCEL_REASON_MODAL.TITTLE')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              {this.reasonField(t)}
              <Field
                colSm={12}
                componentClass="textarea"
                id="comment"
                name="comment"
                valueKey="id"
                labelKey="value"
                controlLabel= {t('DOSSIER_COMPONENTS.CANCEL_REASON_MODAL.COMMENT')}
                placeholder={t('DOSSIER_COMPONENTS.CANCEL_REASON_MODAL.COMMENT')}
                component={InputText}
                onInputChange={(value) => this.setState({ comment: value })}
                minLength={4}
                maxLength={256}
              />
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Col sm={7} className="btn-wrapper">
              <Button id="btn_save" type="submit">{t('DOSSIER_COMPONENTS.CANCEL_REASON_MODAL.DELETE_DOSSIER')}</Button>
            </Col>
          </Modal.Footer>
        </Form>
      </Modal>
    )
  }
}

const validate = (values, props) => {
  const errors = {}
  if (props?.type === dossierTypesConstant.sales) {
    if (!values.reason) {
      errors.reason = props.t('DOSSIER_COMPONENTS.CANCEL_REASON_MODAL.VALIDATION.REASON_REQUIRED')
    }
    if (values.reason === 7 && values.comment === undefined) {
      errors.comment = props.t('DOSSIER_COMPONENTS.CANCEL_REASON_MODAL.VALIDATION.SECTION_REQUIRED')
    } else if (values.comment !== undefined && !/^[a-zA-ZñÑ\s]{4}.*/.test(values.comment)) {
      errors.comment = props.t('DOSSIER_COMPONENTS.CANCEL_REASON_MODAL.VALIDATION.INCORRECT_PATTERN')
    }
  } else {
    if (values.comment === undefined) {
      errors.comment = props.t('DOSSIER_COMPONENTS.CANCEL_REASON_MODAL.VALIDATION.SECTION_REQUIRED')
    } else if (values.comment !== undefined && !/^[a-zA-ZñÑ\s]{4}.*/.test(values.comment)) {
      errors.comment = props.t('DOSSIER_COMPONENTS.CANCEL_REASON_MODAL.VALIDATION.INCORRECT_PATTERN')
    }
  }

  return errors
}
export default reduxForm({
  form: 'cancel',
  validate
})(CancelReasonModal)
