import React, { PureComponent } from 'react'
import { Button, Modal, Col, Form } from 'react-bootstrap'
import { reduxForm, Field } from 'redux-form'
import InputSelect from '../../../components/commons/form/InputSelect'

class ChangeUoSelectModal extends PureComponent {
  constructor (props) {
    super()
    this.state = {
      openModal: true,
      organizedUnitId: null,
      salesmanId: null,
      organizedUnits: [],
      dossierId: null,
      disabledButtonSend: 1
    }
  }

  componentDidUpdate () {
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (this.props.showModal && prevProps.showModal !== this.props.showModal) {
      this.props.actions.fetchLowestOrganizedUnits()
    }
    return prevProps
  }

  removeActualUo (uos) {
    let newUos = []
    if (uos !== undefined) {
      uos.forEach(element => {
        if (element.id !== this.props.organizedUnitId) {
          newUos.push(element)
        }
      })
    }
    return newUos
  }

  selectSalesman (values) {
    this.setState({ salesmanId: values })
    if (values != null) {
      this.setState({ disabledButtonSend: 1 })
    } else {
      this.setState({ disabledButtonSend: 0 })
    }
  }

  selectUo (values) {
    if (values) {
      this.setState({ organizedUnitId: values.organizedUnitId })
      this.props.actions.getChangeUo(this.props.dossierId, values.organizedUnitId)
      if (this.props.dossierType === 'sales') {
        this.props.actions.getSalesmanChangeUo(values.organizedUnitId)
        this.setState({ disabledButtonSend: 0 })
      }
    }
  }

  hide () {
    this.setState({
      openModal: true,
      organizedUnitId: null,
      salesmanId: null,
      organizedUnits: [],
      dossierId: null,
      disabledButtonSend: 1
    })

    this.props.actions.closeChangeUoSelectModal()
  }

  sendChangeUo () {
    this.props.actions.postUpdateChangeUo(this.state.organizedUnitId, this.props.changesDossierUo, this.state.salesmanId)
  }

  render () {
    const {
      t, handleSubmit,
      showModal, organicedUnits, changesDossierUo, dossierType, salesmanChange
    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.CHANGE_UO.'
    return (
      <Modal show={showModal} onHide={this.hide.bind(this)}>
        <Form autoComplete="off" onSubmit={handleSubmit(this.selectUo.bind(this))}>
          <Modal.Header closeButton>
            <Modal.Title>{t(`${tKey}TITLE`)}</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ minHeight: '130px', paddingBottom: '11%' }}>
            {!this.state.organizedUnitId && <Field
              colSm={12}
              id='organizedUnitId'
              name='organizedUnitId'
              placeholder={t(tKey + 'ORGANIZED_UNIT')}
              controlLabel={t(tKey + 'NEW_ORGANIZED_UNIT')}
              component={InputSelect}
              options={this.removeActualUo(organicedUnits)}
              valueKey="id"
              labelKey="value"
            />}
            {changesDossierUo && this.state.organizedUnitId &&
            <div>
              <div>{t(tKey + 'DOSSIER_TO_MODIFY')} :</div><br></br>
              <div><ul> {changesDossierUo.map((dossier) =>
                <li key={dossier.dossierId}>
                  <div>
                    {t(tKey + 'DOSSIER_NUMBER')} : { dossier.dossierNumber }<br></br>
                    {dossier.deletedValues.length > 0 &&
                   <div>
                     {t(tKey + 'ELEMENTS_TO_DELETE')} :
                     <ul> {dossier.deletedValues.map((val, index) => <li key={dossier.dossierId + index}>{t(tKey + val.toUpperCase())}</li>)}</ul><br></br>
                   </div>
                    }
                  </div>
                </li>
              )} </ul></div>
            </div>
            }
            {dossierType === 'sales' && changesDossierUo && this.state.organizedUnitId &&
            <div>
              <Field
                colSm={12}
                id='salesmanId'
                name='salesmanId'
                placeholder={t(tKey + 'SALESMAN')}
                controlLabel={t(tKey + 'SELECT_SALESMAN')}
                component={InputSelect}
                options={salesmanChange}
                valueKey="id"
                labelKey="value"
                onInputChange={(value) => this.selectSalesman(value)}
              />
            </div>
            }
          </Modal.Body>
          <Modal.Footer>
            <Col sm={6} className="btn-wrapper">
              {!this.state.organizedUnitId && <Button id="btn_save" className="btn-standard" type="submit" disabled={this.state.disabledButton === 0 || this.state.disabledButton === null}>{t(`${tKey}OK_BTN`)}</Button>}
              {changesDossierUo && this.state.organizedUnitId && <Button id="btn_save" className="btn-standard" disabled={this.state.disabledButtonSend === 0 || this.state.disabledButtonSend === null} onClick={() => this.sendChangeUo()}>{t(`${tKey}SAVE_CHANGE`)}</Button>}
            </Col>
          </Modal.Footer>
        </Form>
      </Modal>
    )
  }
}

const validate = (values, props) => {
  const error = {}
  if (values == null || values.organizedUnitId == null) {
    error.organizedUnitId = props.t('DOSSIER_COMPONENTS.CHANGE_UO.UO_REQUIRED')
  }
  return error
}

export default reduxForm({
  form: 'chage_uo_select_modal',
  validate
})(ChangeUoSelectModal)
