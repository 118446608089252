import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { initializeSearch, resetFilters } from '../../../actions/search/search'
import { openModal, yesNoModal } from '../../../actions/common'
import { saveAuditAdmin, closeZipModal, fetchAuditConfigurationDocument } from '../../../actions/management/management'
import ZipModal from '../../../components/management/AuxModals/ZipModal'
import { createMassiveDownload } from '../../../actions/search/documentalSearch/documentalSearchForm'

export function mapStateToProps (state) {
  const zipModal = state.management.zipModal
  return {
    showModal: zipModal.showModal,
    zipModal: zipModal,
    ...state.management
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      createMassiveDownload,
      initializeSearch,
      resetFilters,
      openModal,
      yesNoModal,
      closeZipModal,
      saveAuditAdmin,
      fetchAuditConfigurationDocument
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(ZipModal))
