import React, { PureComponent } from 'react'
// import { OverlayTrigger, Tooltip } from 'react-bootstrap'
// import { Link } from 'react-router-dom'

class ComponentTitle extends PureComponent {
  constructor () {
    super()
    this.state = {
      target: this,
      icon: '',
      tkeyname: ''
      // aviableLink: false
    }
  }

  render () {
    const {
      t,
      componentCode,
      componentOpen,
      customTitle,
      extraInfo,
      extraData
    } = this.props

    switch (componentCode) {
      case 'SALES':
      { this.icon = 'ico-sales'
        this.tkeyname = 'DOSSIER_COMPONENTS.SALES.SALES'
        break }
      case 'FLEETS':
      { this.icon = 'iDocIcon-file-fleets'
        this.tkeyname = 'DOSSIER_COMPONENTS.FLEET.TITLE_PANEL'
        break }
      case 'CLIENT':
      { this.icon = 'iDocIcon-user-user-menu'
        this.tkeyname = customTitle
        break }
      case 'VEHICLE':
      { this.icon = 'iDocIcon-file-vehicle'
        this.tkeyname = 'DOSSIER_COMPONENTS.VEHICLE.TITLE'
        break }
      case 'VEHICLE_ORDER_PANEL':
      { this.icon = 'iDocIcon-description'
        this.tkeyname = 'DOSSIER_COMPONENTS.VEHICLE.ORDER_TITLE'
        break }
      case 'VEHICLE_SEARCH_VEHICLE':
      { this.icon = 'iDocIcon-search'
        this.tkeyname = 'DOSSIER_COMPONENTS.VEHICLE.SEARCH_VEHICLE'
        break }
      case 'CAMPAIGNS':
      { this.icon = 'iDocIcon-savings-campaign'
        this.tkeyname = 'DOSSIER_COMPONENTS.CAMPAIGNS.TITLE_PANEL'
        break }
      case 'UAC':
      { this.icon = 'iDocIcon-Organizational-Unit'
        this.tkeyname = 'DOSSIER_COMPONENTS.UAC.TITLE_PANEL'
        break }
      case 'BROKERAJE':
      { this.icon = 'iDocIcon-corredurias'
        this.tkeyname = 'DOSSIER_COMPONENTS.BROKERAGE.TITLE_PANEL'
        break }
      case 'FINANCE':
      { this.icon = 'iDocIcon-coins-finance'
        this.tkeyname = 'DOSSIER_COMPONENTS.FINANCE.TITLE_PANEL'
        break }
      case 'PAPERWORK':
      { this.icon = 'iDocIcon-list-check-formalities'
        this.tkeyname = 'DOSSIER_COMPONENTS.PAPERWORK.TITLE_PANEL'
        break }
      case 'CHARGES':
      { this.icon = 'iDocIcon-wallet-collect'
        this.tkeyname = 'DOSSIER_COMPONENTS.CHARGES.TITLE_PANEL'
        break }
      case 'SERVICES':
      { this.icon = 'iDocIcon-build-services'
        this.tkeyname = 'DOSSIER_COMPONENTS.SALE_SERVICES.TITLE_PANEL'
        break }
      case 'OTHERS':
      { this.icon = 'iDocIcon-others'
        this.tkeyname = 'DOSSIER_COMPONENTS.OTHER_DATA.TITLE_PANEL'
        break }
      case 'DELIVERY':
      { this.icon = 'iDocIcon-car-rental-delivery'
        this.tkeyname = customTitle
        break }
      // ECONOMIC PLAN
      case 'VEHICLE_AMOUNT':
      { this.icon = 'iDocIcon-amount'
        this.tkeyname = 'DOSSIER_COMPONENTS.VEHICLE_AMOUNT.TITLE_PANEL'
        break }
      case 'OPTIONALS':
      { this.icon = 'iDocIcon-optionals'
        this.tkeyname = 'DOSSIER_COMPONENTS.OPTIONALS.TITLE_PANEL'
        break }
      case 'DTO-CAMPAIGN':
      { this.icon = 'iDocIcon-Discount'
        this.tkeyname = 'DOSSIER_COMPONENTS.CAMPAIGN_DISCOUNT.TITLE_PANEL'
        break }
      case 'ADITIONAL-DISCOUNT':
      { this.icon = 'iDocIcon-Discount-aditional'
        this.tkeyname = 'DOSSIER_COMPONENTS.ADITIONAL_DISCOUNT.TITLE_PANEL'
        break }
      case 'SALE-OTHERS':
      { this.icon = 'iDocIcon-sold-others'
        this.tkeyname = 'DOSSIER_COMPONENTS.SALE_OTHERS.TITLE_PANEL'
        break }
      case 'TAXES':
      { this.icon = 'iDocIcon-tax'
        this.tkeyname = 'DOSSIER_COMPONENTS.TAXES.TITLE_PANEL'
        break }
      case 'SUPPLIED':
      { this.icon = 'iDocIcon-suply'
        this.tkeyname = 'DOSSIER_COMPONENTS.SUPPLIED.TITLE_PANEL'
        break }
      case 'PRESENTS':
      { this.icon = 'iDocIcon-gifts-solid'
        this.tkeyname = 'DOSSIER_COMPONENTS.COSTS_GIFTS.TITLE_PANEL'
        break }
      case 'ASSOCIATED-COST':
      { this.icon = 'iDocIcon-associated-costs'
        this.tkeyname = 'DOSSIER_COMPONENTS.ASSOCIATED_COSTS.TITLE_PANEL'
        break }
      // PURCHASE
      case 'PURCHASE_COMPONENT':
      { this.icon = 'ico-car-files'
        this.tkeyname = 'DOSSIER_COMPONENTS.PURCHASE_DOSSIER.PURCHASE_COMPONENT.TITLE'
        break }
      case 'PURCHASE_ECONOMIC_PLAN':
      { this.icon = 'ico-car-files'
        this.tkeyname = 'DOSSIER_COMPONENTS.PURCHASE_DOSSIER.PURCHASE_COMPONENT.TITLE'
        break }
      case 'PURCHASE_REACONDITIONING':
      { this.icon = 'ico-car-files'
        this.tkeyname = 'DOSSIER_COMPONENTS.REACONDITIONING.TITLE_PANEL'
        break }
      //
      case 'CAMPAIGN_COMPONENT':
      { this.icon = 'iDocIcon-savings-campaign'
        this.tkeyname = customTitle
        break }
      //
      case 'PAPERWORK_DOSSIER':
      { this.icon = 'iDocIcon-list-check-formalities'
        this.tkeyname = 'DOSSIER_COMPONENTS.PAPERWORK_DOSSIER.PAPERWORK_COMPONENT.TITLE'
        break }
      case 'SERVICES_COMPONENT':
      { this.icon = 'iDocIcon-build-services'
        this.tkeyname = 'DOSSIER_COMPONENTS.SERVICE_DOSSIER.SERVICE_COMPONENT.TITLE'
        break }
      // stock
      case 'ENTRY':
      { this.icon = 'iDocIcon-add-document-entry'
        this.tkeyname = 'DOSSIER_COMPONENTS.ENTRY.TITLE_PANEL'
        break }
      case 'RECEPTION':
      { this.icon = 'iDocIcon-reception'
        this.tkeyname = 'DOSSIER_COMPONENTS.RECEPTION.TITLE_PANEL'
        break }
      case 'DOSSIER_RATING':
      { this.icon = 'iDocIcon-rating'
        this.tkeyname = 'DOSSIER_COMPONENTS.DOSSIER_RATING.TITLE_PANEL'
        break }
      case 'DOSSIER_BUDGET':
      { this.icon = 'iDocIcon-budget'
        this.tkeyname = 'DOSSIER_COMPONENTS.DOSSIER_BUDGET.TITLE_PANEL'
        break }
      case 'RECONDITIONING':
      { this.icon = 'iDocIcon-reconditioning'
        this.tkeyname = 'DOSSIER_COMPONENTS.RECONDITIONING.TITLE_PANEL'
        break }
      case 'CLEANING_COMPONENT':
      { this.icon = 'iDocIcon-cleaning'
        this.tkeyname = 'DOSSIER_COMPONENTS.CLEANING_COMPONENT.TITLE_PANEL'
        break }
      case 'CHECKLIST_COMPONENT':
      { this.icon = 'iDocIcon-list-check-formalities'
        this.tkeyname = 'DOSSIER_COMPONENTS.CHECKLIST_COMPONENT.TITLE_PANEL'
        break }
      case 'PHOTOGRAPHS':
      { this.icon = 'iDocIcon-photographs'
        this.tkeyname = 'DOSSIER_COMPONENTS.PHOTOGRAPHS.TITLE_PANEL'
        break }
      case 'CRANE':
      { this.icon = 'iDocIcon-crane'
        this.tkeyname = 'DOSSIER_COMPONENTS.CRANE.TITLE_PANEL'
        break }
      case 'PRICE_CHANGE':
      { this.icon = 'iDocIcon-financial-analysis-euro'
        this.tkeyname = 'DOSSIER_COMPONENTS.PRICE_CHANGE.TITLE_PANEL'
        break }
      case 'BOOKING_AND_DELIVERY':
      { this.icon = 'iDocIcon-car-rental-delivery'
        this.tkeyname = 'DOSSIER_COMPONENTS.BOOKING_AND_DELIVERY.TITLE_PANEL'
        break }
      /// ////
      case 'REGISTRATION_DELIVERY':
      { this.icon = 'iDocIcon-car-rental-delivery'
        this.tkeyname = 'DOSSIER_COMPONENTS.SALES.REGISTRATION_DELIVERY'
        break }
      default:
      {
        this.icon = 'iDocIcon-'
        this.tkeyTooltip = 'NO TEXT'
        break }
    }
    return (
      <>
        <div className='header-panel-left'>
          <div className="name-wrapper">
            <i className={'componentIcon ' + this.icon} />
            <h4>{customTitle === undefined ? (t(this.tkeyname)) : this.tkeyname }</h4>
            <div className='extraInfo'>{/** Extra info here **/}
              <p>{extraInfo}</p>
            </div>
          </div>
          <div className='extraActions'> {/** Extra buttons & actions here **/}
            {extraData}
          </div>
        </div>
        {componentOpen !== undefined && (
          <i className={componentOpen ? 'ico-arrow-black-up' : 'ico-arrow-black-down'} />
        )
        }

      </>
    )
  }
}

export default ComponentTitle
undefined
