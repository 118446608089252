import { put, call, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { reset } from 'redux-form'
import dossierActionsTypes from '../../../constants/actions/dossier/dossier'
import { dossierTypes as dossierTypesConstant } from '../../../constants/dossier/common/dossierTypes'
import { showLoader, hideLoader } from '../../../actions/common'
import messageModalActions from '../../../actions/modals/messageModal'
import { yesNoModal } from '../../modalsListeners/yesNoModal'
import { getAuth } from '../../../selectors/access/auth'
import putCampaignRejectService from '../../../services/dossierCampaign/putCampaignReject'
import putCampaignApprove from '../../../services/dossierCampaign/putCampaignApprove'
import putCampaignSend from '../../../services/dossierCampaign/putCampaignSend'
import putCampaignDeny from '../../../services/dossierCampaign/putCampaignDeny'
import { handleError } from '../../errors/errorManager'
import { initializeDossier } from '../commonDossierSagas'
import putAnulateCampaign from '../../../services/dossierCampaign/putAnulateCampaign'

import putCampaignValidate from '../../../services/dossierCampaign/putCampaignValidate'
import putCampaignDenie from '../../../services/dossierCampaign/putCampaignDenie'
import putCampaignReturn from '../../../services/dossierCampaign/putCampaignReturn'

export function * campaignReject ({ comment, resolve, reject, pristine }) {
  try {
    if (!pristine) {
      const confirmedSaveWarning = yield call(yesNoModal, 'commonDossierSaveWarning')
      if (confirmedSaveWarning) {
        const auth = yield select(getAuth)
        const campaignDossierState = yield select(state => state.campaignDossier)
        const confirmed = yield call(yesNoModal, 'campaignReject')
        if (confirmed) {
          yield put(showLoader())
          yield call(putCampaignRejectService, campaignDossierState.dossierId, comment, auth.token, campaignDossierState.lastAccessDate)
          yield call(initializeDossier, dossierTypesConstant.campaign, campaignDossierState)
        }
      }
    } else {
      const auth = yield select(getAuth)
      const campaignDossierState = yield select(state => state.campaignDossier)
      const confirmed = yield call(yesNoModal, 'campaignReject')
      if (confirmed) {
        yield put(showLoader())
        yield call(putCampaignRejectService, campaignDossierState.dossierId, comment, auth.token, campaignDossierState.lastAccessDate)
        yield call(initializeDossier, dossierTypesConstant.campaign, campaignDossierState)
      }
    }
    if (resolve) yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          const campaignDossierState = yield select(state => state.campaignDossier)
          yield call(initializeDossier, dossierTypesConstant.campaign, campaignDossierState)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
      if (reject) yield call(reject, customHandlerError)
    }
    const sessionHandler = function * () {
      yield put(reset('campaign_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchCampaignReject () {
  yield takeEvery(dossierActionsTypes.CAMPAIGN_REJECT, campaignReject)
}

export function * campaignApprove (pristine) {
  try {
    const dossierCampaignDetailComponent = yield select(state => state.campaignDossier.dossierCampaignComponent.dossierCampaignDetailComponent)
    if (dossierCampaignDetailComponent.approvedAmount === 0) {
      yield call(yesNoModal, 'notApprovedAmount')
    }
    if (!pristine.pristine) {
      const confirmedSaveWarning = yield call(yesNoModal, 'commonDossierSaveWarning')
      if (confirmedSaveWarning) {
        const auth = yield select(getAuth)
        const campaignDossierState = yield select(state => state.campaignDossier)
        const confirmed = yield call(yesNoModal, 'campaignApprove')
        if (confirmed) {
          yield put(showLoader())
          yield call(putCampaignApprove, campaignDossierState.dossierId, auth.token, campaignDossierState.lastAccessDate)
          yield call(initializeDossier, dossierTypesConstant.campaign, campaignDossierState)
        }
      }
    } else {
      const auth = yield select(getAuth)
      const campaignDossierState = yield select(state => state.campaignDossier)
      const confirmed = yield call(yesNoModal, 'campaignApprove')
      if (confirmed) {
        yield put(showLoader())
        yield call(putCampaignApprove, campaignDossierState.dossierId, auth.token, campaignDossierState.lastAccessDate)
        yield call(initializeDossier, dossierTypesConstant.campaign, campaignDossierState)
      }
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          const campaignDossierState = yield select(state => state.campaignDossier)
          yield call(initializeDossier, dossierTypesConstant.campaign, campaignDossierState)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('campaign_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchCampaignApprove () {
  yield takeLatest(dossierActionsTypes.CAMPAIGN_APPROVE, campaignApprove)
}

export function * campaignSend (pristine) {
  try {
    if (!pristine.pristine) {
      const confirmedSaveWarning = yield call(yesNoModal, 'commonDossierSaveWarning')
      if (confirmedSaveWarning) {
        const auth = yield select(getAuth)
        const campaignDossierState = yield select(state => state.campaignDossier)
        const confirmed = yield call(yesNoModal, 'campaignSend')
        if (confirmed) {
          yield put(showLoader())
          yield call(putCampaignSend, campaignDossierState.dossierId, auth.token, campaignDossierState.lastAccessDate)
          yield call(initializeDossier, dossierTypesConstant.campaign, campaignDossierState)
        }
      }
    } else {
      const auth = yield select(getAuth)
      const campaignDossierState = yield select(state => state.campaignDossier)
      const confirmed = yield call(yesNoModal, 'campaignSend')
      if (confirmed) {
        yield put(showLoader())
        yield call(putCampaignSend, campaignDossierState.dossierId, auth.token, campaignDossierState.lastAccessDate)
        yield call(initializeDossier, dossierTypesConstant.campaign, campaignDossierState)
      }
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          const campaignDossierState = yield select(state => state.campaignDossier)
          yield call(initializeDossier, dossierTypesConstant.campaign, campaignDossierState)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('campaign_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchCampaignSend () {
  yield takeEvery(dossierActionsTypes.CAMPAIGN_SEND, campaignSend)
}

export function * campaignDeny () {
  try {
    const auth = yield select(getAuth)
    const campaignDossierState = yield select(state => state.campaignDossier)
    const confirmed = yield call(yesNoModal, 'campaignDeny')
    if (confirmed) {
      yield put(showLoader())
      yield call(putCampaignDeny, campaignDossierState.dossierId, auth.token, campaignDossierState.lastAccessDate)
      yield call(initializeDossier, dossierTypesConstant.campaign, campaignDossierState)
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          const campaignDossierState = yield select(state => state.campaignDossier)
          yield call(initializeDossier, dossierTypesConstant.campaign, campaignDossierState)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('campaign_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchCampaignDeny () {
  yield takeEvery(dossierActionsTypes.CAMPAIGN_DENY, campaignDeny)
}

export function * campaignWarningSave ({ reset, router, nextLocation, pristine }) {
  if (!pristine) {
    const confirmedSaveWarning = yield call(yesNoModal, 'commonDossierSaveWarning')
    if (confirmedSaveWarning) {
      const promise = new Promise((resolve, reject) => {
        reset('campaign_dossier')
        resolve()
      })
      promise.then(() => {
        router.replace(nextLocation)
      })
    }
  }
}

export function * watchCampaignWarningSave () {
  yield takeEvery(dossierActionsTypes.CAMPAIGN_WARNING_SAVE, campaignWarningSave)
}

export function * anulateCampaign ({ dossierId }) {
  try {
    const confirmedSAnulate = yield call(yesNoModal, 'commonDossierAnulate')
    if (confirmedSAnulate) {
      yield put(showLoader())
      const campaignDossierState = yield select(state => state.campaignDossier)
      const auth = yield select(getAuth)
      yield call(putAnulateCampaign, auth.token, dossierId, campaignDossierState.lastAccessDate)
      yield call(initializeDossier, dossierTypesConstant.campaign, campaignDossierState)
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.CAMPAIGN_DOSSIER.ERROR_ANULATE_CAMPAIGN_DOSSIER', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('campaign_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchAnulateCampaign () {
  yield takeEvery(dossierActionsTypes.ANULATE_CAMPAIGN, anulateCampaign)
}

export function * campaignvalidate () {
  try {
    const auth = yield select(getAuth)
    const campaignDossierState = yield select(state => state.campaignDossier)
    const confirmed = yield call(yesNoModal, 'campaignValidate')
    if (confirmed) {
      yield put(showLoader())
      yield call(putCampaignValidate, campaignDossierState.dossierId, auth.token, campaignDossierState.lastAccessDate)
      yield call(initializeDossier, dossierTypesConstant.campaign, campaignDossierState)
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('campaign_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchCampaignValidate () {
  yield takeEvery(dossierActionsTypes.VALIDATE_CAMPAIGN, campaignvalidate)
}

export function * campaignDenie ({ dossierId, rejectReason, resolve, reject }) {
  try {
    const auth = yield select(getAuth)
    const campaignDossierState = yield select(state => state.campaignDossier)
    const confirmed = yield call(yesNoModal, 'campaignDenied')
    if (confirmed) {
      yield put(showLoader())
      yield call(putCampaignDenie, campaignDossierState.dossierId, auth.token, campaignDossierState.lastAccessDate, rejectReason.text)
      yield call(initializeDossier, dossierTypesConstant.campaign, campaignDossierState)
    }
    if (resolve) yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      if (reject) yield call(reject, customHandlerError)
    }
    const sessionHandler = function * () {
      yield put(reset('campaign_dossier'))
    }

    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchCampaignDenie () {
  yield takeEvery(dossierActionsTypes.DENIE_CAMPAIGN, campaignDenie)
}

export function * campaignReturn () {
  try {
    const auth = yield select(getAuth)
    const campaignDossierState = yield select(state => state.campaignDossier)
    const confirmed = yield call(yesNoModal, 'campaignReturn')
    if (confirmed) {
      yield put(showLoader())
      yield call(putCampaignReturn, campaignDossierState.dossierId, auth.token, campaignDossierState.lastAccessDate)
      yield call(initializeDossier, dossierTypesConstant.campaign, campaignDossierState)
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('campaign_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchCampaignReturn () {
  yield takeEvery(dossierActionsTypes.RETURN_CAMPAIGN, campaignReturn)
}
