import { put, call, takeEvery, select } from 'redux-saga/effects'
import { getAuth } from '../../../selectors/access/auth'
import changeUoActionTypes from '../../../constants/actions/dossier/common/changeUo'
import { getChangeUoSuccess, getSalesmanChangeUoSuccess } from '../../../actions/dossier/common/changeUo'
import { showLoader, hideLoader } from '../../../actions/common'
import getChangeUoDossier from '../../../services/dossier/get/getChangeUoDossier'
import getSalesmanLogic from '../../../services/salesman/getSalesmanLogic'
import postChangeUoDossier from '../../../services/dossier/post/postChangeUoDossier'
import { handleError } from '../../errors/errorManager'
import messageModalActions from '../../../actions/modals/messageModal'

export function * getChangeUo ({ dossierId, organizedUnitId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    let changesDossierUo = yield call(getChangeUoDossier, dossierId, organizedUnitId, auth.token)
    yield put(getChangeUoSuccess({ changesDossierUo }))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchGetChangeUo () {
  yield takeEvery(changeUoActionTypes.GET_CHANGES_UO, getChangeUo)
}

export function * getSalesmanChangeUo ({ organizedUnitId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    let salesman = yield call(getSalesmanLogic, organizedUnitId, auth.token)
    yield put(getSalesmanChangeUoSuccess({ salesman }))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchgetSalesmanChangeUo () {
  yield takeEvery(changeUoActionTypes.GET_SALESMAN_CHANGE, getSalesmanChangeUo)
}

export function * postChageUoDossier ({ organizedUnitId, changesUO, salesmanId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    let result = yield call(postChangeUoDossier, organizedUnitId, salesmanId, changesUO, auth.token)
    if (result) {
      window.location.reload()
      yield put(messageModalActions.openSuccessMessageModal('MESSAGES.CHANGE_UO_SUCCES'))
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchPostChageUoDossier () {
  yield takeEvery(changeUoActionTypes.POST_UPDATE_CHAGE_UO, postChageUoDossier)
}
