import { put, call, select, takeEvery } from 'redux-saga/effects'
import { showLoader, hideLoader } from '../../../../actions/common.js'
import mastersActionTypes from '../../../../constants/actions/masters/masters'
import { getAuth } from '../../../../selectors/access/auth'
import { handleError } from '../../../errors/errorManager'
import { getProcessingAgencyDocTypeUsesFilter } from '../../../../selectors/masters/processingAgency'

import { fetchProcessingAgencyDocTypeUsesListSuccess, showProcessingAgencyDocTypeUsesModalSuccess } from '../../../../actions/masters/processingAgency/processingAgencyIntegrations/processingAgencyDocTypeUses'
import postProcessingAgencyDocTypeUsesListService from '../../../../services/processingAgency/processingAgencyIntegrations/postProcessingAgencyDocTypeUsesList'
import postProcessingAgencyDocTypeUsesService from '../../../../services/processingAgency/processingAgencyIntegrations/postProcessingAgencyDocTypeUses'
import putProcessingAgencyDocTypeUsesService from '../../../../services/processingAgency/processingAgencyIntegrations/putProcessingAgencyDocTypeUses'
import deleteProcessingAgencyDocTypeUsesService from '../../../../services/processingAgency/processingAgencyIntegrations/deleteProcessingAgencyDocTypeUses'
import { yesNoModal } from '../../../modalsListeners/yesNoModal'

export function * fetchProcessingAgencyDocTypeUsesList ({ filter, processingAgencyId, typeId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield filter = yield filter || select(getProcessingAgencyDocTypeUsesFilter)
    const processingAgencyDocTypeUsesList = yield call(postProcessingAgencyDocTypeUsesListService, auth.token, processingAgencyId, filter, typeId)
    yield put(fetchProcessingAgencyDocTypeUsesListSuccess(processingAgencyDocTypeUsesList, filter))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchProcessingAgencyDocTypeUsesList () {
  yield takeEvery(mastersActionTypes.FETCH_PROCESSING_AGENCY_DOC_TYPE_USES_LIST, fetchProcessingAgencyDocTypeUsesList)
}

export function * showProcessingAgencyDocTypeUsesModal ({ show, processingAgencyDocTypeUse, processingAgencyId, isReturnDocument }) {
  try {
    yield put(showLoader())
    yield put(showProcessingAgencyDocTypeUsesModalSuccess(processingAgencyId, show, processingAgencyDocTypeUse, isReturnDocument))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchShowProcessingAgencyDocTypeUsesModal () {
  yield takeEvery(mastersActionTypes.SHOW_PROCESSING_AGENCY_DOC_TYPE_USES_MODAL, showProcessingAgencyDocTypeUsesModal)
}

export function * submitProcessingAgencyDocTypeUsesModal ({ processingAgencyDocTypeUse, processingAgencyId }) {
  try {
    const auth = yield select(getAuth)
    if (processingAgencyDocTypeUse.ProcessingAgencyDocTypeUseId) {
      const confirmed = yield call(yesNoModal, 'editProcessingAgencyDocTypeUses')
      if (confirmed) {
        yield put(showLoader())
        yield call(putProcessingAgencyDocTypeUsesService, auth.token, processingAgencyId, processingAgencyDocTypeUse.ProcessingAgencyDocTypeUseId, processingAgencyDocTypeUse)
      }
    } else {
      yield put(showLoader())
      yield call(postProcessingAgencyDocTypeUsesService, auth.token, processingAgencyId, processingAgencyDocTypeUse)
    }
    yield put(showProcessingAgencyDocTypeUsesModalSuccess(false))
    const filter = yield filter || select(getProcessingAgencyDocTypeUsesFilter)
    const processingAgencyDocTypeUsesList = yield call(postProcessingAgencyDocTypeUsesListService, auth.token, processingAgencyId, filter, processingAgencyDocTypeUse.IsReturnDocument)
    yield put(fetchProcessingAgencyDocTypeUsesListSuccess(processingAgencyDocTypeUsesList, filter))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSubmitProcessingAgencyDocTypeUsesModal () {
  yield takeEvery(mastersActionTypes.SUBMIT_PROCESSING_AGENCY_DOC_TYPE_USES, submitProcessingAgencyDocTypeUsesModal)
}

export function * deleteProcessingAgencyDocTypeUses ({ processingAgencyDocTypeUsesId, processingAgencyId, isReturnDocument }) {
  try {
    const confirmed = yield call(yesNoModal, 'deleteProcessingAgencyDocTypeUses')
    if (confirmed) {
      yield put(showLoader())
      const auth = yield select(getAuth)
      yield call(deleteProcessingAgencyDocTypeUsesService, auth.token, processingAgencyId, processingAgencyDocTypeUsesId)
      const filter = yield filter || select(getProcessingAgencyDocTypeUsesFilter)
      const processingAgencyDocTypeUsesList = yield call(postProcessingAgencyDocTypeUsesListService, auth.token, processingAgencyId, filter, isReturnDocument)
      yield put(fetchProcessingAgencyDocTypeUsesListSuccess(processingAgencyDocTypeUsesList, filter))
      yield put(showProcessingAgencyDocTypeUsesModalSuccess(processingAgencyId, false))
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDeleteProcessingAgencyDocTypeUses () {
  yield takeEvery(mastersActionTypes.DELETE_PROCESSING_AGENCY_DOC_TYPE_USES, deleteProcessingAgencyDocTypeUses)
}
