import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { Form, Glyphicon, Col, Button, Clearfix } from 'react-bootstrap'
import InputSelect from '../../commons/form/InputSelect'
import InputDatePicker from '../../commons/form/InputDatePicker'
import { validateDate } from '../../../util/validationFunctions'

class DocumentalSearchRestrictedForm extends Component {
  constructor (props) {
    super()
    this.state = {
    }
  }

  componentDidMount () {
    this.props.actions.fetchDocumentRestrictedCombo()
  }

  submitDocumentalSearchForm (values) {
    if (!values) {
      return this.props.actions.openModal('mustFillFilters')
    }
    this.props.actions.findFilterDocuments({ ...values, ...this.props.filters })
  }

  dateValidation (value, allValues, props) {
    const result = validateDate(value, props)
    return result === Object(result) ? undefined : result
  }

  render () {
    const {
      t, handleSubmit, pristine, initialize, fields, fieldFill, resetFields,
      combos: {
        documentTypesRestrictedCombo,
        searchDossierSubtypesCombo,
        lowestOrganizedUnitsCombo
      }
      // tabPermission,
    } = this.props

    const tKey = 'DOCUMENT_SEARCH.'
    const className = 'filled'
    const classNameSelect = 'filledSelect'

    return (
      <Form autoComplete="off" className="filters" onSubmit={handleSubmit(this.submitDocumentalSearchForm.bind(this))}>
        <div className="general-form-search">
          <h4><Glyphicon glyph="filter" /> {t(`${tKey}GENERAL_FILTERS`)}</h4>
          <Field
            colSm={3}
            id="documentTypeId"
            name="documentTypeId"
            controlLabel={t(`${tKey}DOCUMENT_TYPE`)}
            placeholder={t(`${tKey}DOCUMENT_TYPE`)}
            component={InputSelect}
            options={documentTypesRestrictedCombo}
            valueKey="id"
            labelKey="value"
            onInputChange={ (val) => { fieldFill({ documentTypeId: val }) } }
            customClass={fields.documentTypeId ? classNameSelect : ''}
          />
          <Field
            colSm={3}
            id="organizedUnitId"
            name="organizedUnitId"
            controlLabel={t(`${tKey}UO`)}
            placeholder={t(`${tKey}UO`)}
            component={InputSelect}
            options={lowestOrganizedUnitsCombo}
            valueKey="id"
            labelKey="value"
            onInputChange={ (val) => { fieldFill({ organizedUnitId: val }) } }
            customClass={fields.organizedUnitId ? classNameSelect : ''}
          />
          <Field
            colSm={3}
            id="dossierSubtype"
            name="dossierSubTypeId"
            controlLabel={t(`${tKey}DOSSIER_SUBTYPE`)}
            placeholder={t(`${tKey}DOSSIER_SUBTYPE`)}
            component={InputSelect}
            options={searchDossierSubtypesCombo}
            valueKey="id"
            labelKey="value"
            onInputChange={ (val) => { fieldFill({ dossierSubtype: val }) } }
            customClass={fields.dossierSubtype ? classNameSelect : ''}
          />
          <Clearfix/>
          <Field
            colSm={3}
            id="dossierCreationDateFrom"
            name="creationDateFrom"
            controlLabel={t(`${tKey}CREATION_DATE_DOCUMENT_FROM`)}
            placeholder={t(`${tKey}SINCE`)}
            component={InputDatePicker}
            validate={[this.dateValidation]}
            onInputChange={(val) => fieldFill({ dossierCreationDateFrom: val })}
            customClass={fields.dossierCreationDateFrom ? className : ''}
          />
          <Field
            colSm={3}
            id="dossierCreationDateTo"
            name="creationDateTo"
            controlLabel={t(`${tKey}CREATION_DATE_DOCUMENT_TO`)}
            placeholder={t(`${tKey}UNTIL`)}
            component={InputDatePicker}
            validate={[this.dateValidation]}
            onInputChange={(val) => fieldFill({ dossierCreationDateTo: val })}
            customClass={fields.dossierCreationDateTo ? className : ''}
          />
          <Field
            colSm={3}
            id="dossierValidationDateFrom"
            name="validationDateFrom"
            controlLabel={t(`${tKey}VALIDATION_DATE_DOCUMENT_FROM`)}
            placeholder={t(`${tKey}SINCE`)}
            component={InputDatePicker}
            validate={[this.dateValidation]}
            onInputChange={(val) => fieldFill({ dossierValidationDateFrom: val })}
            customClass={fields.dossierValidationDateFrom ? className : ''}
          />
          <Field
            colSm={3}
            id="dossierValidationDateTo"
            name="validationDateTo"
            controlLabel={t(`${tKey}VALIDATION_DATE_DOCUMENT_TO`)}
            placeholder={t(`${tKey}UNTIL`)}
            component={InputDatePicker}
            validate={[this.dateValidation]}
            onInputChange={(val) => fieldFill({ dossierValidationDateTo: val })}
            customClass={fields.dossierValidationDateTo ? className : ''}
          />
          <Clearfix/>
          <Field
            colSm={3}
            id="creationDossierDateFrom"
            name="creationDossierDateFrom"
            controlLabel={t(`${tKey}CREATION_DATE_DOSSIER_FROM`)}
            placeholder={t(`${tKey}SINCE`)}
            component={InputDatePicker}
            validate={[this.dateValidation]}
            onInputChange={(val) => fieldFill({ creationDossierDateFrom: val })}
            customClass={fields.creationDossierDateFrom ? className : ''}
          />
          <Field
            colSm={3}
            id="creationDossierDateTo"
            name="creationDossierDateTo"
            controlLabel={t(`${tKey}CREATION_DATE_DOSSIER_TO`)}
            placeholder={t(`${tKey}UNTIL`)}
            component={InputDatePicker}
            validate={[this.dateValidation]}
            onInputChange={(val) => fieldFill({ creationDossierDateTo: val })}
            customClass={fields.creationDossierDateTo ? className : ''}
          />
          <Clearfix/>
        </div>
        <Col sm={12} className="filters-footer">
          <hr/>
          {this.props.formValues !== undefined ? (
            (!this.props.formValues.documentTypeId && !this.props.formValues.organizedUnitId && !this.props.formValues.dossierSubtype && !this.props.formValues.dossierCreationDateFrom && !this.props.formValues.dossierCreationDateTo &&
                !this.props.formValues.dossierValidationDateFrom && !this.props.formValues.dossierValidationDateTo && !this.props.formValues.creationDossierDateFrom && !this.props.formValues.creationDossierDateTo) ? (
                <Button bsStyle="primary" className={'btn-white btn-icon btn-cancel'}><Glyphicon glyph="search" /> {t(`${tKey}ACTIONS.SEARCH_BUTTON`)}</Button>
              ) : (
                <Button bsStyle="primary" className={'btn-white btn-icon' + (pristine ? ' btn-cancel' : ' btn-standard')} type="submit"><Glyphicon glyph="search" /> {t(`${tKey}ACTIONS.SEARCH_BUTTON`)}</Button>
              )
          ) : (
            <Button bsStyle="primary" className={'btn-white btn-icon btn-cancel'}><Glyphicon glyph="search" /> {t(`${tKey}ACTIONS.SEARCH_BUTTON`)}</Button>
          )
          }
          <Button
            className={'btn-white btn-icon' + (pristine ? ' btn-cancel' : ' btn-standard')}
            onClick={(e) => {
              if (!pristine) {
                initialize({})
                resetFields()
              }
            }}
          >
            <i className="ico-cancel"/> {t(`${tKey}ACTIONS.RESET_BUTTON`)}
          </Button>
        </Col>
      </Form>
    )
  }
}

const validate = (values, props) => {
  const error = {}
  return error
}

export default reduxForm({
  form: 'documentalSearchRestrictedForm',
  destroyOnUnmount: false,
  validate
})(DocumentalSearchRestrictedForm)
