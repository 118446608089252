import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import StockReport from '../../../components/report/stock/StockReport'
import { fetchStockList, downloadStockList } from '../../../actions/reports/stockReport'
import { fetchBrands } from '../../../actions/combos/combos'

export function mapStateToProps (state) {
  return {
    ...state.stockReport,
    combos: {
      brandCombo: state.combos.brandCombo,
      lowestOrganizedUnitsCombo: state.combos.lowestOrganizedUnitsCombo
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchStockList,
      fetchBrands,
      downloadStockList
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(StockReport))
