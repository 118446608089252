import { call, put, select, takeLatest } from 'redux-saga/effects'
import { getAuth } from '../../selectors/access/auth'
import getDocumentsAllTypesService from '../../services/document/getDocumentAllTypes'
import { showLoader, hideLoader } from '../../actions/common'
import messageModalActions from '../../actions/modals/messageModal'
import { fetchCombosSuccess } from '../../actions/combos/combos'
import combosActionTypes from '../../constants/actions/combos/combos'
import { handleError } from '../errors/errorManager'

export function * fetchDocumentsAllTypes ({ documentEntityType = null }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const documentsTypes = yield call(getDocumentsAllTypesService, auth.token, documentEntityType)
    yield put(fetchCombosSuccess({ documentsTypes }))
  } catch (error) {
    const customHandler = function * () {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.FETCH_COMBO_ERROR'))
    }
    yield call(handleError, { error, customHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchDocumentsAllTypes () {
  yield takeLatest(combosActionTypes.FETCH_DOCUMENT_ALL_TYPES, fetchDocumentsAllTypes)
}
