import { put, all, call, select, takeEvery, spawn } from 'redux-saga/effects'
import { getAuth } from '../../selectors/access/auth'
import { change, reset, initialize } from 'redux-form'
import getSourceChannelComplete from '../../services/sourcechannel/getChannelsComplete'
import getSourceChannelById from '../../services/sourcechannel/getChannelsCompleteById'
import putSourceChannel from '../../services/sourcechannel/putChannel'
import postSourceChannel from '../../services/sourcechannel/postChannel'
import postExtraSaleTypeService from '../../services/extraSaleType/postExtraSaleType'
import putExtraSaleTypeService from '../../services/extraSaleType/putExtraSaleType'
import recoverExtraSaleTypeService from '../../services/extraSaleType/recoverExtraSaleType'
import deleteSourceChannel from '../../services/sourcechannel/deleteChannel'
import deleteExtraSaleTypeService from '../../services/extraSaleType/deleteExtraSaleType'
import getLanguageListComboService from '../../services/language/getLanguageList'
import getExtraSaleTypeService from '../../services/extraSaleType/getExtraSaleType'
import getSuppliedTypeService from '../../services/suppliedType/getSuppliedType'
import putSuppliedTypeService from '../../services/suppliedType/putSuppliedType'
import postSuppliedTypeService from '../../services/suppliedType/postSuppliedType'
import deleteSuppliedTypeService from '../../services/suppliedType/deleteSuppliedType'
import recoverSuppliedTypeService from '../../services/suppliedType/recoverSuppliedType'
import getPresentsTypeService from '../../services/presentsType/getPresentsType'
import getPresentsTypeById from '../../services/presentsType/getPresentsTypeById'
import getAssociatedCostsTypeService from '../../services/associatedCost/getAssociatedCostsType'
import getAssociatedCostsTypeById from '../../services/associatedCost/getAssociatedCostsTypeById'
import putPresentsTypeService from '../../services/presentsType/putPresentsType'
import postPresentsTypeService from '../../services/presentsType/postPresentsType'
import putAssociatedCostsTypeService from '../../services/associatedCost/putAssociatedCostsType'
import postAssociatedCostsTypeService from '../../services/associatedCost/postAssociatedCostsType'
import deletePresentsTypeService from '../../services/presentsType/deletePresentsType'
import deleteAssociatedCostsTypeService from '../../services/associatedCost/deleteAssociatedCostsType'
import recoverPresentsTypeService from '../../services/presentsType/recoverPresentsType'
import recoverAssociatedCostsTypeService from '../../services/associatedCost/recoverAssociatedCostType'
import { showLoader, hideLoader, openModal } from '../../actions/common.js'
import mastersActionTypes from '../../constants/actions/masters/masters'
import getExtraSaleTypeById from '../../services/extraSaleType/getExtraSaleTypeById'
import getSuppliedTypeById from '../../services/suppliedType/getSuppliedTypeById'
import {
  closeSourceChannelModal, fetchEntityMastersSuccess,
  openSourceChannelModalSuccess, fetchExtraSalesMastersSuccess, openExtraSaleModalSuccess,
  closeExtraSaleModal, fetchSuppliedMastersSuccess, openSuppliedModalSuccess, closeSuppliedModal,
  fetchPresentsMastersSuccess, openPresentsModalSuccess, closePresentsModal, fetchProcessingAgencyListSuccess, openEditProcessingAgencyModalSuccess, fetchProcessingAgencyQuotaListSuccess,
  fetchFuelListSuccess, openEditFuelModalSuccess, openAddFuelModalSuccess, closeEditFuelModal,
  closeEditProcessingAgencyModal, openAddProcessingAgencyModalSuccess, openAssociatedCostsModalSuccess, fetchAssociatedCostsMastersSuccess, closeAssociatedCostsModal, fetchPurchasePresentTypeMastersSuccess, fetchFinancingTypeMastersSuccess, closeFinancingTypeModal, openFinancingTypeModalSuccess, openPurchasePresentTypeModalSuccess, fetchFinancingTypeListSuccess,
  fetchPurchasePresentTypeListSuccess,
  fetchPaymentMethodListSuccess, openAddPaymentMethodModalSuccess, closeEditPaymentMethodModal, openEditPaymentMethodModalSuccess,
  fetchFinancingListSuccess, openAddFinancingModalSuccess, openEditfinancingModalSuccess, closeEditFinancingModal, closeEditProcessingAgencyAssignationModal, updateProcessingAgencyAssignation, closePurchasePresentTypeModal,
  fetchCampaignListSuccess, closeCampaignMasterModal, fetchCampaignGroupListSuccess, closeCampaignGroupMasterModal, fetchCampaignTypeListSuccess, closeCampaignTypeMasterModal, openEditCampaignGroupModalSuccess, openAddCampaignGroupModalSuccess,
  openEditCampaignTypeModalSuccess, openAddCampaignTypeModalSuccess, fetchCountryCampaignDetailListSuccess, openModalMassSuccess,
  fetchStyleTaskListSuccess, closeStyleTaskMasterModal, openEditStyleTaskModalSuccess, openEditfinancingModalUoSuccess, saveUoFinancingCompanySucces, fetchFinancingProductCompanySuccess
  , fetchUploadTest, fetchDossierStageListSuccess, openEditDossierStageModalSuccess, closeEditDossierStageModal, showEditProcessingAgencyIntegrationModalSuccess, fetchcodeCommissionMastersSuccess, openCodeCommissionModalSuccess, closeCodeCommissionModal
} from '../../actions/masters/masters'
import { handleError } from '../errors/errorManager'
import postProcessingAgencyList from '../../services/processingAgency/postProcessingAgencyList'
import postProcessingAgencyQuotaList from '../../services/processingAgencyQuota/postProcessingAgencyQuotaList'
import deleteProcessingAgencyQuotaService from '../../services/processingAgencyQuota/deleteProcessingAgencyQuotaService'
import postProcessingAgencyQuota from '../../services/processingAgencyQuota/postProcessingAgencyQuota'
import postPaymentMethodList from '../../services/paymentMethod/postPaymentMethodList'
import recoverPaymentMethodService from '../../services/paymentMethod/recoverPaymentMethodService'
import postFinancing from '../../services/financeCompany/postFinancing'
import putFinancing from '../../services/financeCompany/putFinancing'
import postProcessingAgency from '../../services/processingAgency/postProcessingAgency'
import getProcessingAgencyById from '../../services/processingAgency/getProcessingAgencyById'
import getFinancingById from '../../services/financeCompany/getFinancingById'
import putProcessingAgency from '../../services/processingAgency/putProcessingAgency'
import postPaymentMethod from '../../services/paymentMethod/postPaymentMethod'
import getPaymentMethodById from '../../services/paymentMethod/getPaymentMethodById'
import putPaymentMethod from '../../services/paymentMethod/putPaymentMethod'
import deleteLogicProcessingAgencyService from '../../services/processingAgency/deleteLogicProcessingAgencyService'
import recoverLogicProcessingAgencyService from '../../services/processingAgency/recoverLogicProcessingAgencyService'
import deletePaymentMethodService from '../../services/paymentMethod/deletePaymentMethodService'
import { yesNoModal } from '../modalsListeners/yesNoModal'
import { getProcessingAgencyFilter, getProcessingAgencyAssignationFilter } from '../../selectors/masters/processingAgency'
import { getCampaignFilter, getCampaignGroupFilter, getCampaignTypeFilter } from '../../selectors/masters/campaign'
import { getStyleTaskFilter } from '../../selectors/masters/styleTask'
import { getFuelFilter } from '../../selectors/masters/fuel'
import postFuelList from '../../services/fuel/postFuelList'
import getFuelById from '../../services/fuel/getFulById'
import postFuel from '../../services/fuel/postFuel'
import putFuel from '../../services/fuel/putFul'
import deleteFuelService from '../../services/fuel/deleteFuel'
import recoverFuelService from '../../services/fuel/recoverFuel'
import { getDossierStageFilter } from '../../selectors/masters/dossierStage'
import postDossierStageList from '../../services/dossierStage/postDossierStageList'
import getFinancingTypeService from '../../services/financingType/getFinancinTypesList'
import postFinancingTypeService from '../../services/financingType/postFinancingType'
import getFinancingTypeById from '../../services/financingType/getFinancingTypeById'
import putFinancingTypeService from '../../services/financingType/putFinancingType'
import deleteFinancingType from '../../services/financingType/deleteFinancingType'
import recoverFinancingType from '../../services/financingType/recoverFinancingType'
import { getFinancingTypeFilter } from '../../selectors/masters/financingType'
import { getPurchasePresentTypeFilter } from '../../selectors/masters/purchasePresentType'
import postFinancingTypeList from '../../services/financingType/postFinancingTypeList'
import postPurchasePresentTypeList from '../../services/purchasePresentType/postPurchasePresentTypeList'
import deletePurchasePresentType from '../../services/purchasePresentType/deletePurchasePresentType'
import getPurchasePresentTypeById from '../../services/purchasePresentType/getPurchasePresentTypeById'
import recoverPurchasePresentType from '../../services/purchasePresentType/recoverPurchasePresentType'
import postPurchasePresentType from '../../services/purchasePresentType/postPurchasePresentType'
import putPurchasePresentType from '../../services/purchasePresentType/putPurchasePresentType'
import { getPaymentMethodFilter } from '../../selectors/masters/paymentMethod'
import deleteFinancingService from '../../services/financeCompany/deleteFinancingService'
import putRecoverFinancing from '../../services/financeCompany/putRecoverFinancing'
import postProcessingFinancing from '../../services/financeCompany/postProcessingFinancing'
import { getProcessingFinancingFilter } from '../../selectors/masters/processingFinancing'
import { v4 as uuidv1 } from 'uuid'
import postCampaignDetailsList from '../../services/campaignType/postCampaignDetailsList'
import postCampaignGroupList from '../../services/campaignType/postCampaignGroupList'
import postCampaignTypeList from '../../services/campaignType/postCampaignTypeList'
import postCampaignDetail from '../../services/campaignType/postCampaignDetail'
import deleteCampaignDetailService from '../../services/campaignType/deleteCampaignDetail'
import recoverCampaignDetailService from '../../services/campaignType/recoverCampaignDetail'
import deleteCampaignGroupService from '../../services/campaignType/deleteCampaignGroup'
import recoverCampaignGroupService from '../../services/campaignType/recoverCampaignGroup'
import recoverCampaignTypeService from '../../services/campaignType/recoverCampaignType'
import postCampaignGroup from '../../services/campaignType/postCampaignGroup'
import postCampaignTypeService from '../../services/campaignType/postCampaignType'
import deleteCampaignTypeService from '../../services/campaignType/deleteCampaignType'
import getCampaignDetailsCountries from '../../services/campaignDetails/getCampaignDetailsCountries'
import delCampaignDetailsCountry from '../../services/campaignDetails/delCampaignDetailsCountry'
import postCampaignDetailsCountry from '../../services/campaignDetails/postCampaignDetailsCountry'
import postStyleTaskList from '../../services/styleTask/postStyleTaskList'
import postStyleTaskService from '../../services/styleTask/postStyleTask'
import deleteStyleTaskService from '../../services/styleTask/deleteStyleTask'
import getOrdersCombo from '../../services/styleTask/getOrdersCombo'
import getCampaignType from '../../services/campaignType/getCampaignType'
import { fetchCombosSuccess } from '../../actions/combos/combos'
import postAddUosToCompany from '../../services/financeCompany/postAddUosToCompany'
import deleteCompantyFromUo from '../../services/financeCompany/deleteCompantyFromUo'
import recoverCompanyFromUo from '../../services/financeCompany/recoverCompanyFromUo'
import findCompaniesWithUO from '../../services/financeCompany/findCompaniesWithUO'
import messageModalActions from '../../actions/modals/messageModal'
import postProcAgDocumentUploadMass from '../../services/processingAgency/postProcAgDocumentUploadMass'
import getDossierStageById from '../../services/dossierStage/getDossierStageById'
import putDossierStage from '../../services/dossierStage/putDossierStage'
import putUpdateUrgency from '../../services/dossierStage/putUpdateUrgency'
import putProcessingAgencyCommunicationServiceId from '../../services/processingAgency/putProcessingAgencyCommunicationServiceId'
import getCodeCommissionMaster from '../../services/codeCommission/getCodeCommissionMaster'
import deleteCodeCommissionMaster from '../../services/codeCommission/deleteCodeCommissionMaster'
import postCodeCommissionMaster from '../../services/codeCommission/postCodeCommissionMaster'
import putCodeCommissionMaster from '../../services/codeCommission/putCodeCommissionMaster'

export function * fetchEntityMasters () {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const sourceChannel = yield call(getSourceChannelComplete, auth.token)
    yield put(fetchEntityMastersSuccess(sourceChannel))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchEntityMasters () {
  yield takeEvery(mastersActionTypes.FETCH_ENTITY_MASTERS, fetchEntityMasters)
}

export function * openSourceChannelModal ({ sourceChannelId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const languageList = yield call(getLanguageListComboService, auth.token)
    let sourceChannelsFields = []
    if (sourceChannelId) {
      sourceChannelsFields = yield call(getSourceChannelById, auth.token, sourceChannelId)
    }
    yield put(openSourceChannelModalSuccess(languageList, sourceChannelsFields, sourceChannelId))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchOpenSourceChannelModal () {
  yield takeEvery(mastersActionTypes.OPEN_SOURCE_CHANNEL_MODAL, openSourceChannelModal)
}

export function * saveSourceChannelModal ({ sourceChannelValues, sourceChannelId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    if (sourceChannelId) {
      yield call(putSourceChannel, auth.token, sourceChannelId, sourceChannelValues)
    } else {
      yield call(postSourceChannel, auth.token, sourceChannelValues)
    }
    const sourceChannel = yield call(getSourceChannelComplete, auth.token)
    yield put(fetchEntityMastersSuccess(sourceChannel))
    yield put(closeSourceChannelModal())
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSaveSourceChannelModal () {
  yield takeEvery(mastersActionTypes.SAVE_SOURCE_CHANNEL, saveSourceChannelModal)
}

export function * deleteSourceChannelModal ({ sourceChannelId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(deleteSourceChannel, auth.token, sourceChannelId)
    const sourceChannel = yield call(getSourceChannelComplete, auth.token)
    yield put(fetchEntityMastersSuccess(sourceChannel))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDeleteSourceChannelModal () {
  yield takeEvery(mastersActionTypes.DELETE_SOURCE_CHANNEL, deleteSourceChannelModal)
}

export function * fetchExtraSalesMasters ({ lowestOrganizedUnitId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const extraSalesMastersList = yield call(getExtraSaleTypeService, lowestOrganizedUnitId, auth.token)
    yield put(fetchExtraSalesMastersSuccess(extraSalesMastersList))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchExtraSalesMasters () {
  yield takeEvery(mastersActionTypes.FETCH_EXTRA_SALES_MASTERS, fetchExtraSalesMasters)
}

export function * openExtraSaleModal ({ uoId, extraSaleId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const languageList = yield call(getLanguageListComboService, auth.token)
    let extraSaleFields = []
    if (extraSaleId) {
      extraSaleFields = yield call(getExtraSaleTypeById, auth.token, extraSaleId)
    }
    yield put(openExtraSaleModalSuccess(languageList, extraSaleFields, extraSaleId))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchOpenExtraSaleModal () {
  yield takeEvery(mastersActionTypes.OPEN_EXTRA_SALE_MODAL, openExtraSaleModal)
}

export function * saveExtraSaleModal ({ uoId, extraSaleValues, extraSaleId }) {
  try {
    if (uoId === undefined || uoId === null) {
      yield call(yesNoModal, 'uoNotExpecified')
    } else {
      yield put(showLoader())
      const auth = yield select(getAuth)
      if (extraSaleId) {
        yield call(putExtraSaleTypeService, extraSaleId, extraSaleValues, uoId, auth.token)
      } else {
        yield call(postExtraSaleTypeService, extraSaleValues, uoId, auth.token)
      }
      const extraSalesMastersList = yield call(getExtraSaleTypeService, uoId, auth.token)
      yield put(fetchExtraSalesMastersSuccess(extraSalesMastersList))
      yield put(closeExtraSaleModal())
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSaveExtraSaleModal () {
  yield takeEvery(mastersActionTypes.SAVE_EXTRA_SALE, saveExtraSaleModal)
}

export function * deleteExtraSaleModal ({ uoId, extraSaleId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(deleteExtraSaleTypeService, auth.token, extraSaleId)
    const extraSalesMastersList = yield call(getExtraSaleTypeService, uoId, auth.token)
    yield put(fetchExtraSalesMastersSuccess(extraSalesMastersList))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDeleteExtraSaleModal () {
  yield takeEvery(mastersActionTypes.DELETE_EXTRA_SALE_MASTERS, deleteExtraSaleModal)
}

export function * fetchSuppliedMasters ({ lowestOrganizedUnitId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const suppliedMastersList = yield call(getSuppliedTypeService, lowestOrganizedUnitId, auth.token)
    yield put(fetchSuppliedMastersSuccess(suppliedMastersList))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchSuppliedMasters () {
  yield takeEvery(mastersActionTypes.FETCH_SUPPLIED_MASTERS, fetchSuppliedMasters)
}

export function * openSuppliedModal ({ uoId, suppliedId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const languageList = yield call(getLanguageListComboService, auth.token)
    let suppliedFields = []
    if (suppliedId) {
      suppliedFields = yield call(getSuppliedTypeById, auth.token, suppliedId)
    }
    yield put(openSuppliedModalSuccess(languageList, suppliedFields, suppliedId))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchOpenSuppliedModal () {
  yield takeEvery(mastersActionTypes.OPEN_SUPPLIED_MODAL, openSuppliedModal)
}

export function * saveSuppliedModal ({ uoId, suppliedValues, suppliedId }) {
  try {
    if (uoId === undefined || uoId === null) {
      yield call(yesNoModal, 'uoNotExpecified')
    } else {
      yield put(showLoader())
      const auth = yield select(getAuth)
      if (suppliedId) {
        yield call(putSuppliedTypeService, suppliedId, suppliedValues, uoId, auth.token)
      } else {
        yield call(postSuppliedTypeService, suppliedValues, uoId, auth.token)
      }
      const suppliedMastersList = yield call(getSuppliedTypeService, uoId, auth.token)
      yield put(fetchSuppliedMastersSuccess(suppliedMastersList))
      yield put(closeSuppliedModal())
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSaveSuppliedModal () {
  yield takeEvery(mastersActionTypes.SAVE_SUPPLIED_MASTER, saveSuppliedModal)
}

export function * deleteSuppliedModal ({ uoId, suppliedId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(deleteSuppliedTypeService, auth.token, suppliedId)
    const suppliedMastersList = yield call(getSuppliedTypeService, uoId, auth.token)
    yield put(fetchSuppliedMastersSuccess(suppliedMastersList))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDeleteSuppliedModal () {
  yield takeEvery(mastersActionTypes.DELETE_SUPPLIED_MASTER, deleteSuppliedModal)
}

export function * fetchPresentsMasters ({ lowestOrganizedUnitId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const presentsMastersList = yield call(getPresentsTypeService, lowestOrganizedUnitId, auth.token)
    yield put(fetchPresentsMastersSuccess(presentsMastersList))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchPresentsMasters () {
  yield takeEvery(mastersActionTypes.FETCH_PRESENTS_MASTERS, fetchPresentsMasters)
}

export function * openPresentsModal ({ uoId, presentsId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const languageList = yield call(getLanguageListComboService, auth.token)
    let presentsFields = []
    if (presentsId) {
      presentsFields = yield call(getPresentsTypeById, auth.token, presentsId)
    }
    yield put(openPresentsModalSuccess(languageList, presentsFields, presentsId))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchOpenPresentsModal () {
  yield takeEvery(mastersActionTypes.OPEN_PRESENTS_MODAL, openPresentsModal)
}

export function * savePresentsModal ({ uoId, presentsValues, presentsId }) {
  try {
    if (uoId === undefined || uoId === null) {
      yield call(yesNoModal, 'uoNotExpecified')
    } else {
      yield put(showLoader())
      const auth = yield select(getAuth)
      if (presentsId) {
        yield call(putPresentsTypeService, presentsId, presentsValues, uoId, auth.token)
      } else {
        yield call(postPresentsTypeService, presentsValues, uoId, auth.token)
      }
      const presentsMastersList = yield call(getPresentsTypeService, uoId, auth.token)
      yield put(fetchPresentsMastersSuccess(presentsMastersList))
      yield put(closePresentsModal())
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSavePresentsModal () {
  yield takeEvery(mastersActionTypes.SAVE_PRESENTS, savePresentsModal)
}

export function * deletePresentsModal ({ uoId, presentsId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(deletePresentsTypeService, auth.token, presentsId)
    const presentsMastersList = yield call(getPresentsTypeService, uoId, auth.token)
    yield put(fetchPresentsMastersSuccess(presentsMastersList))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDeletePresentsModal () {
  yield takeEvery(mastersActionTypes.DELETE_PRESENTS, deletePresentsModal)
}

export function * recoverPresentsModal ({ uoId, presentsId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(recoverPresentsTypeService, auth.token, presentsId)
    const presentsMastersList = yield call(getPresentsTypeService, uoId, auth.token)
    yield put(fetchPresentsMastersSuccess(presentsMastersList))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchRecoverPresentsModal () {
  yield takeEvery(mastersActionTypes.RECOVER_PRESENTS, recoverPresentsModal)
}

export function * recoverSuppliedModal ({ uoId, suppliedId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(recoverSuppliedTypeService, auth.token, suppliedId)
    const suppliedMastersList = yield call(getSuppliedTypeService, uoId, auth.token)
    yield put(fetchSuppliedMastersSuccess(suppliedMastersList))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchRecoverSuppliedModal () {
  yield takeEvery(mastersActionTypes.RECOVER_SUPPLIED_MASTER, recoverSuppliedModal)
}

export function * recoverExtraSaleModal ({ uoId, extraSaleId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(recoverExtraSaleTypeService, auth.token, extraSaleId)
    const extraSaleMastersList = yield call(getExtraSaleTypeService, uoId, auth.token)
    yield put(fetchExtraSalesMastersSuccess(extraSaleMastersList))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchRecoverExtraSaleModal () {
  yield takeEvery(mastersActionTypes.RECOVER_EXTRA_SALE, recoverExtraSaleModal)
}

export function * fetchProcessingAgencyList ({ filter }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield filter = yield filter || select(getProcessingAgencyFilter)
    const agencyList = yield call(postProcessingAgencyList, auth.token, filter)
    yield put(fetchProcessingAgencyListSuccess(agencyList, filter))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchProcessingAgencyList () {
  yield takeEvery(mastersActionTypes.FETCH_PROCESSING_AGENCY, fetchProcessingAgencyList)
}

export function * fetchProcessingAgencyQuotaList ({ filter }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield filter = yield filter || select(getProcessingAgencyAssignationFilter)
    const quotaList = filter && filter.searchUO && filter.searchUO.length > 0 ? yield call(postProcessingAgencyQuotaList, auth.token, filter) : []
    yield put(fetchProcessingAgencyQuotaListSuccess(quotaList, filter))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchProcessingAgencyQuotaList () {
  yield takeEvery(mastersActionTypes.FETCH_PROCESSING_AGENCY_QUOTA, fetchProcessingAgencyQuotaList)
}

export function * openEditProcessingAgencyModal ({ processingagencyId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const agency = yield call(getProcessingAgencyById, auth.token, processingagencyId)
    yield put(openEditProcessingAgencyModalSuccess(agency))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchOpenEditProcessingAgencyModal () {
  yield takeEvery(mastersActionTypes.OPEN_EDIT_PROCESSING_AGENCY_MODAL, openEditProcessingAgencyModal)
}

export function * openAddProcessingAgencyModal () {
  try {
    yield put(showLoader())
    yield put(openAddProcessingAgencyModalSuccess())
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchOpenAddProcessingAgencyModal () {
  yield takeEvery(mastersActionTypes.OPEN_ADD_PROCESSING_AGENCY_MODAL, openAddProcessingAgencyModal)
}

export function * submitProcessingAgency ({ body, agency }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const filter = yield filter || select(getProcessingAgencyFilter)
    if (agency) {
      yield call(putProcessingAgency, body, agency.processingAgencyId, auth.token)
    } else {
      yield call(postProcessingAgency, body, auth.token)
    }
    yield put(closeEditProcessingAgencyModal())
    const agencyList = yield call(postProcessingAgencyList, auth.token, filter)
    yield put(fetchProcessingAgencyListSuccess(agencyList, filter))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSubmitProcessingAgency () {
  yield takeEvery(mastersActionTypes.SUBMIT_PROCESSING_AGENCY, submitProcessingAgency)
}

export function * submitCampaignDetail ({ campaign }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const filter = yield filter || select(getCampaignFilter)
    yield call(postCampaignDetail, auth.token, campaign)
    yield put(closeCampaignMasterModal())
    yield call(fetchCampaignList, filter)
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSubmitCampaignDetail () {
  yield takeEvery(mastersActionTypes.SUBMIT_CAMPAIGN_DETAIL, submitCampaignDetail)
}

export function * deleteCampaignDetail ({ campaignId }) {
  try {
    const confirmed = yield call(yesNoModal, 'deleteCampaignDetail')
    if (confirmed) {
      yield put(showLoader())
      const auth = yield select(getAuth)
      const filter = yield filter || select(getCampaignFilter)
      yield call(deleteCampaignDetailService, auth.token, campaignId)
      yield call(fetchCampaignList, filter)
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDeleteCampaignDetail () {
  yield takeEvery(mastersActionTypes.DELETE_CAMPAIGN_DETAIL, deleteCampaignDetail)
}

export function * deleteCampaignGroup ({ groupId }) {
  try {
    const confirmed = yield call(yesNoModal, 'deleteCampaignGroup')
    if (confirmed) {
      yield put(showLoader())
      const auth = yield select(getAuth)
      const filter = yield filter || select(getCampaignGroupFilter)
      yield call(deleteCampaignGroupService, auth.token, groupId)
      yield call(fetchCampaignGroupList, filter)
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDeleteCampaignGroup () {
  yield takeEvery(mastersActionTypes.DELETE_CAMPAIGN_GROUP, deleteCampaignGroup)
}

export function * recoverCampaignGroup ({ groupId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const filter = yield filter || select(getCampaignGroupFilter)
    yield call(recoverCampaignGroupService, auth.token, groupId)
    yield call(fetchCampaignGroupList, filter)
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchRecoverCampaignGroup () {
  yield takeEvery(mastersActionTypes.RECOVER_CAMPAIGN_GROUP, recoverCampaignGroup)
}

export function * deleteCampaignType ({ typeId }) {
  try {
    const confirmed = yield call(yesNoModal, 'deleteCampaignType')
    if (confirmed) {
      yield put(showLoader())
      const auth = yield select(getAuth)
      const filter = yield filter || select(getCampaignTypeFilter)
      yield call(deleteCampaignTypeService, auth.token, typeId)
      yield call(fetchCampaignTypeList, filter)
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDeleteCampaignType () {
  yield takeEvery(mastersActionTypes.DELETE_CAMPAIGN_TYPE, deleteCampaignType)
}

export function * recoverCampaignType ({ typeId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const filter = yield filter || select(getCampaignTypeFilter)
    yield call(recoverCampaignTypeService, auth.token, typeId)
    yield call(fetchCampaignTypeList, filter)
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchRecoverCampaignType () {
  yield takeEvery(mastersActionTypes.RECOVER_CAMPAIGN_TYPE, recoverCampaignType)
}

export function * submitCampaignGroup ({ campaign }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const filter = yield filter || select(getCampaignFilter)
    yield call(postCampaignGroup, auth.token, campaign)
    yield put(closeCampaignGroupMasterModal())
    yield call(fetchCampaignGroupList, filter)
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSubmitCampaignGroup () {
  yield takeEvery(mastersActionTypes.SUBMIT_CAMPAIGN_GROUP, submitCampaignGroup)
}

export function * submitCampaignType ({ campaign }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const filter = yield filter || select(getCampaignTypeFilter)
    yield call(postCampaignTypeService, auth.token, campaign)
    yield put(closeCampaignTypeMasterModal())
    yield call(fetchCampaignTypeList, filter)
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSubmitCampaignType () {
  yield takeEvery(mastersActionTypes.SUBMIT_CAMPAIGN_TYPE, submitCampaignType)
}

export function * recoverCampaignDetail ({ campaignId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const filter = yield filter || select(getCampaignFilter)
    yield call(recoverCampaignDetailService, auth.token, campaignId)
    yield call(fetchCampaignList, filter)
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchRecoverCampaignDetail () {
  yield takeEvery(mastersActionTypes.RECOVER_CAMPAIGN_DETAIL, recoverCampaignDetail)
}

export function * submitProcessingAgencyAssignation ({ quotaList }) {
  try {
    let confirmed = true
    let noquotas = false
    if (quotaList) {
      if (quotaList.processingAgencyQuotas && quotaList.processingAgencyQuotas.length === 0) {
        confirmed = yield call(yesNoModal, 'deleteQuota')
        noquotas = true
        quotaList = {
          ...quotaList,
          previousEndDate: quotaList.endDate,
          previousStartDate: quotaList.startDate
        }
      }
      yield put(showLoader())
      if (confirmed) {
        const filter = yield filter || select(getProcessingAgencyAssignationFilter)
        const auth = yield select(getAuth)
        noquotas ? yield call(deleteProcessingAgencyQuotaService, quotaList, auth.token) : yield call(postProcessingAgencyQuota, quotaList, auth.token)
        yield call(fetchProcessingAgencyQuotaList, filter)
        yield put(closeEditProcessingAgencyAssignationModal())
      }
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSubmitProcessingAgencyAssignation () {
  yield takeEvery(mastersActionTypes.SUBMIT_PROCESSING_AGENCY_QUOTA, submitProcessingAgencyAssignation)
}

export function * editProcessingAgencyAssignation ({ quota, resolve, reject }) {
  try {
    yield put(showLoader())
    let stateQuota = yield select(state => state.processingAgencyAssignation.processingAgencyAssignationModal.quota)
    let initialquota = JSON.parse(JSON.stringify(stateQuota))
    if (!quota.quota) {
      quota.quota = 1
    }
    quota.quota = Math.abs(quota.quota)
    if (initialquota.processingAgencyQuotas) {
      let index = quota.quotaId && quota.quotaId !== -1 ? initialquota.processingAgencyQuotas.findIndex(q => q.quotaId === quota.quotaId)
        : initialquota.processingAgencyQuotas.findIndex(q => q.idx === quota.idx)
      initialquota.processingAgencyQuotas[index] = quota
    }
    yield put(updateProcessingAgencyAssignation(initialquota))
    yield call(resolve)
  } catch (error) {
    yield call(handleError, { error })
    yield call(reject, error)
  } finally {
    yield put(hideLoader())
  }
}

export function * watchEditProcessingAgencyAssignation () {
  yield takeEvery(mastersActionTypes.EDIT_PROCESSING_AGENCY_QUOTA, editProcessingAgencyAssignation)
}

export function * deleteProcessingAgencyAssignation ({ quota }) {
  try {
    yield put(showLoader())
    let stateQuota = yield select(state => state.processingAgencyAssignation.processingAgencyAssignationModal.quota)
    let initialquota = JSON.parse(JSON.stringify(stateQuota))
    if (initialquota.processingAgencyQuotas) {
      let index = quota.quotaId && quota.quotaId !== -1 ? initialquota.processingAgencyQuotas.findIndex(q => q.quotaId === quota.quotaId)
        : initialquota.processingAgencyQuotas.findIndex(q => q.idx === quota.idx)
      initialquota.processingAgencyQuotas.splice(index, 1)
    }
    yield put(updateProcessingAgencyAssignation(initialquota))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDeleteProcessingAgencyAssignation () {
  yield takeEvery(mastersActionTypes.DELETE_PROCESSING_AGENCY_QUOTA, deleteProcessingAgencyAssignation)
}

export function * deleteProcessingAgencyQuota ({ quota }) {
  try {
    const confirmed = yield call(yesNoModal, 'deleteQuota')
    yield put(showLoader())
    if (confirmed) {
      quota = {
        ...quota,
        previousEndDate: quota.endDate,
        previousStartDate: quota.startDate
      }
      const filter = yield filter || select(getProcessingAgencyAssignationFilter)
      const auth = yield select(getAuth)
      yield call(deleteProcessingAgencyQuotaService, quota, auth.token)
      yield call(fetchProcessingAgencyQuotaList, filter)
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDeleteProcessingAgencyQuota () {
  yield takeEvery(mastersActionTypes.DELETE_PROCESSING_AGENCY_MASTER_QUOTA, deleteProcessingAgencyQuota)
}

export function * addProcessingAgencyAssignation ({ quota, resolve, reject }) {
  try {
    yield put(showLoader())
    let stateQuota = yield select(state => state.processingAgencyAssignation.processingAgencyAssignationModal.quota)
    if (!quota.quota) {
      quota.quota = 1
    }
    quota.quota = Math.abs(quota.quota)
    let initialquota = JSON.parse(JSON.stringify(stateQuota))
    if (!initialquota.processingAgencyQuotas) {
      initialquota.processingAgencyQuotas = []
    }
    quota.idx = uuidv1()
    quota.quotaId = -1
    initialquota.processingAgencyQuotas.push(quota)
    yield put(updateProcessingAgencyAssignation(initialquota))
    yield call(resolve)
  } catch (error) {
    yield call(reject, error)
  } finally {
    yield put(hideLoader())
  }
}

export function * watchAddProcessingAgencyAssignation () {
  yield takeEvery(mastersActionTypes.ADD_PROCESSING_AGENCY_QUOTA, addProcessingAgencyAssignation)
}

export function * deleteProcessingAgency ({ processingAgencyId }) {
  try {
    const confirmed = yield call(yesNoModal, 'deleteAgency')
    if (confirmed) {
      yield put(showLoader())
      const auth = yield select(getAuth)
      const filter = yield filter || select(getProcessingAgencyFilter)
      yield call(deleteLogicProcessingAgencyService, processingAgencyId, auth.token)
      const agencyList = yield call(postProcessingAgencyList, auth.token, filter)
      yield put(fetchProcessingAgencyListSuccess(agencyList, filter))
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDeleteProcessingAgency () {
  yield takeEvery(mastersActionTypes.DELETE_PROCESSING_AGENCY, deleteProcessingAgency)
}

export function * recoverProcessingAgency ({ processingAgencyId }) {
  try {
    const confirmed = yield call(yesNoModal, 'recoverAgency')
    if (confirmed) {
      yield put(showLoader())
      const auth = yield select(getAuth)
      const filter = yield filter || select(getProcessingAgencyFilter)
      yield call(recoverLogicProcessingAgencyService, processingAgencyId, auth.token)
      const agencyList = yield call(postProcessingAgencyList, auth.token, filter)
      yield put(fetchProcessingAgencyListSuccess(agencyList, filter))
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchRecoverProcessingAgency () {
  yield takeEvery(mastersActionTypes.RECOVER_PROCESSING_AGENCY, recoverProcessingAgency)
}

export function * openAssociatedCostsModal ({ uoId, associatedCostsId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const languageList = yield call(getLanguageListComboService, auth.token)
    let associatedCostsFields = []
    if (associatedCostsId) {
      associatedCostsFields = yield call(getAssociatedCostsTypeById, auth.token, associatedCostsId)
    }
    yield put(openAssociatedCostsModalSuccess(languageList, associatedCostsFields, associatedCostsId))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchOpenAssociatedCostsModal () {
  yield takeEvery(mastersActionTypes.OPEN_ASSOCIATED_COSTS_MODAL, openAssociatedCostsModal)
}

export function * deleteAssociatedCostsModal ({ uoId, associatedCostsId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(deleteAssociatedCostsTypeService, auth.token, associatedCostsId)
    const associatedCostsMastersList = yield call(getAssociatedCostsTypeService, uoId, auth.token)
    yield put(fetchAssociatedCostsMastersSuccess(associatedCostsMastersList))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDeleteAssociatedCostsModal () {
  yield takeEvery(mastersActionTypes.DELETE_ASSOCIATED_COSTS, deleteAssociatedCostsModal)
}

export function * fetchAssociatedCostsMasters ({ lowestOrganizedUnitId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const associatedCostsMastersList = yield call(getAssociatedCostsTypeService, lowestOrganizedUnitId, auth.token)
    yield put(fetchAssociatedCostsMastersSuccess(associatedCostsMastersList))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchAssociatedCostsMasters () {
  yield takeEvery(mastersActionTypes.FETCH_ASSOCIATED_COSTS_MASTERS, fetchAssociatedCostsMasters)
}

export function * recoverAssociatedCostsModal ({ uoId, associatedCostsId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(recoverAssociatedCostsTypeService, auth.token, associatedCostsId)
    const associatedCostsMastersList = yield call(getAssociatedCostsTypeService, uoId, auth.token)
    yield put(fetchAssociatedCostsMastersSuccess(associatedCostsMastersList))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchRecoverAssociatedCostsModal () {
  yield takeEvery(mastersActionTypes.RECOVER_ASSOCIATED_COSTS, recoverAssociatedCostsModal)
}

export function * saveAssociatedCostsModal ({ uoId, associatedCostsValues, associatedCostsId }) {
  try {
    if (uoId === undefined || uoId === null) {
      yield call(yesNoModal, 'uoNotExpecified')
    } else {
      yield put(showLoader())
      const auth = yield select(getAuth)
      if (associatedCostsId) {
        yield call(putAssociatedCostsTypeService, associatedCostsId, associatedCostsValues, uoId, auth.token)
      } else {
        yield call(postAssociatedCostsTypeService, associatedCostsValues, uoId, auth.token)
      }
      const associatedCostsMastersList = yield call(getAssociatedCostsTypeService, uoId, auth.token)
      yield put(fetchAssociatedCostsMastersSuccess(associatedCostsMastersList))
      yield put(closeAssociatedCostsModal())
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSaveAssociatedCostsModal () {
  yield takeEvery(mastersActionTypes.SAVE_ASSOCIATED_COSTS, saveAssociatedCostsModal)
}

export function * fetchFuelList ({ filter }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield filter = yield filter || select(getFuelFilter)
    const fuelList = yield call(postFuelList, auth.token, filter)
    yield put(fetchFuelListSuccess(fuelList, filter))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * fetchDossierStageList ({ filter }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield filter = yield filter || select(getDossierStageFilter)
    const dossierStageList = yield call(postDossierStageList, auth.token, filter)
    yield put(fetchDossierStageListSuccess(dossierStageList, filter))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}
export function * watchFetchDossierStageList () {
  yield takeEvery(mastersActionTypes.FETCH_DOSSIER_STAGES, fetchDossierStageList)
}

export function * submitDossierStage ({ body, dossierStageId }) {
  try {
    if (Object.values(body.warnings).some((x) => x === true)) {
      const confirmed = yield call(yesNoModal, 'updateDossierStage')
      if (!confirmed) {
        return
      }
    }
    yield put(showLoader())
    const auth = yield select(getAuth)
    const filter = yield filter || select(getDossierStageFilter)
    if (dossierStageId) {
      yield call(putDossierStage, body, dossierStageId, auth.token)
      if (body.warnings.percentToWarn || body.warnings.maxDays) {
        // fire and forget
        yield spawn(putUpdateUrgency, auth.token)
      }
    }
    yield put(closeEditDossierStageModal())
    const dossierStageList = yield call(postDossierStageList, auth.token, filter)
    yield put(fetchDossierStageListSuccess(dossierStageList, filter))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSubmitDossierStage () {
  yield takeEvery(mastersActionTypes.SUBMIT_DOSSIER_STAGE, submitDossierStage)
}

export function * fetchFinancingTypeMasters ({ lowestOrganizedUnitId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const financingTypeMastersList = yield call(getFinancingTypeService, lowestOrganizedUnitId, auth.token)
    yield put(fetchFinancingTypeMastersSuccess(financingTypeMastersList))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * fetchPurchasePresentTypeMasters ({ lowestOrganizedUnitId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const purchasePresentTypeMastersList = yield call(getFinancingTypeService, lowestOrganizedUnitId, auth.token)
    yield put(fetchPurchasePresentTypeMastersSuccess(purchasePresentTypeMastersList))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchPurchasePresentTypeMasters () {
  yield takeEvery(mastersActionTypes.FETCH_PURCHASE_PRESENT_TYPE_MASTERS, fetchPurchasePresentTypeMasters)
}

export function * fetchPaymentMethodList ({ filter }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield filter = yield filter || select(getPaymentMethodFilter)
    const paymentList = yield call(postPaymentMethodList, auth.token, filter)
    yield put(fetchPaymentMethodListSuccess(paymentList, filter))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}
export function * fetchFinancingList ({ filter }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield filter = yield filter || select(getProcessingFinancingFilter)
    const financingList = yield call(postProcessingFinancing, auth.token, filter)
    yield put(fetchFinancingListSuccess(financingList, filter))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchFuelList () {
  yield takeEvery(mastersActionTypes.FETCH_FUEL, fetchFuelList)
}

export function * openEditFuelModal ({ fuelId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const languageList = yield call(getLanguageListComboService, auth.token)
    let fuelFields = []
    if (fuelId) {
      fuelFields = yield call(getFuelById, auth.token, fuelId)
    }

    let lang = {}
    languageList.map((l) => {
      let found = fuelFields && fuelFields.fuelTypeLanguages ? fuelFields.fuelTypeLanguages.find(f => f.languageId === l.languageId) : null
      lang['lang' + l.languageId] = found || { languageId: l.languageId, name: '', description: '' }
    })

    yield put(initialize('editFuelModal', lang))
    yield put(openEditFuelModalSuccess(languageList, fuelFields, fuelId))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * openEditDossierStageModal ({ dossierStageId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const languageList = yield call(getLanguageListComboService, auth.token)
    let dossierStageFields = []
    if (dossierStageId) {
      dossierStageFields = yield call(getDossierStageById, auth.token, dossierStageId)
    }
    yield put(openEditDossierStageModalSuccess(languageList, dossierStageFields, dossierStageId))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchOpenEditDossierStageModal () {
  yield takeEvery(mastersActionTypes.OPEN_EDIT_DOSSIER_STAGES_MODAL, openEditDossierStageModal)
}

export function * watchFetchFinancingTypeMasters () {
  yield takeEvery(mastersActionTypes.FETCH_FINANCING_TYPE_MASTERS, fetchFinancingTypeMasters)
}

export function * saveFinancingTypeModal ({ financingTypeValues, financingTypeId, companyId, financingTypeProductId }) {
  try {
    const filter = yield filter || select(getFinancingTypeFilter)
    const auth = yield select(getAuth)
    if (financingTypeId) {
      const confirmed = yield call(yesNoModal, 'updateFinancingType')
      yield put(showLoader())
      if (confirmed) {
        yield call(putFinancingTypeService, financingTypeId, financingTypeValues, companyId, financingTypeProductId, auth.token)
      }
    } else {
      yield put(showLoader())
      yield call(postFinancingTypeService, financingTypeValues, companyId, financingTypeProductId, auth.token)
    }
    var fetchFilter = filter
    fetchFilter.companyId = companyId
    const financeList = yield call(postFinancingTypeList, auth.token, fetchFilter)
    yield put(fetchFinancingProductCompanySuccess(financeList, filter))
    yield put(closeFinancingTypeModal())
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * savePurchasePresentTypeModal ({ uoId, purchasePresentTypeValues, purchasePresentTypeId }) {
  try {
    if (uoId === undefined || uoId === null) {
      yield call(yesNoModal, 'uoNotExpecified')
    } else {
      yield put(showLoader())
      const filter = yield filter || select(getPurchasePresentTypeFilter)
      const auth = yield select(getAuth)
      if (purchasePresentTypeId) {
        yield call(putPurchasePresentType, purchasePresentTypeId, purchasePresentTypeValues, uoId, auth.token)
      } else {
        yield call(postPurchasePresentType, purchasePresentTypeValues, uoId, auth.token)
      }
      const purchasePresentList = yield call(postPurchasePresentTypeList, auth.token, filter)
      yield put(fetchPurchasePresentTypeListSuccess(purchasePresentList, filter))
      yield put(closePurchasePresentTypeModal())
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSavePurchasePresentTypeModal () {
  yield takeEvery(mastersActionTypes.SAVE_PURCHASE_PRESENT_TYPE, savePurchasePresentTypeModal)
}

export function * watchOpenEditFuelModal () {
  yield takeEvery(mastersActionTypes.OPEN_EDIT_FUEL_MODAL, openEditFuelModal)
}

export function * openAddFuelModal () {
  try {
    yield put(showLoader())
    yield put(openAddFuelModalSuccess())
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}
export function * watchFetchPaymentMethod () {
  yield takeEvery(mastersActionTypes.FETCH_PAYMENT_METHOD, fetchPaymentMethodList)
}

export function * deletePaymentMethod ({ paymentMethodId }) {
  try {
    const confirmed = yield call(yesNoModal, 'deletePaymentMethod')
    if (confirmed) {
      yield put(showLoader())
      const auth = yield select(getAuth)
      const filter = yield filter || select(getPaymentMethodFilter)
      yield call(deletePaymentMethodService, paymentMethodId, auth.token)
      const paymentList = yield call(postPaymentMethodList, auth.token, filter)
      yield put(fetchPaymentMethodListSuccess(paymentList, filter))
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}
export function * watchFetchFinancingList () {
  yield takeEvery(mastersActionTypes.FETCH_FINANCING, fetchFinancingList)
}
export function * openAddFinancingModal () {
  try {
    yield put(showLoader())
    yield put(openAddFinancingModalSuccess())
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDeletePaymentMethod () {
  yield takeEvery(mastersActionTypes.DELETE_PAYMENT_METHOD, deletePaymentMethod)
}
export function * recoverPaymentMethod ({ paymentMethodId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const filter = yield filter || select(getPaymentMethodFilter)
    yield call(recoverPaymentMethodService, paymentMethodId, auth.token)
    const paymentList = yield call(postPaymentMethodList, auth.token, filter)
    yield put(fetchPaymentMethodListSuccess(paymentList, filter))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchOpenAddFuelModal () {
  yield takeEvery(mastersActionTypes.OPEN_ADD_FUEL_MODAL, openAddFuelModal)
}

export function * submitFuel ({ body, fuel }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const filter = yield filter || select(getFuelFilter)
    if (fuel) {
      yield call(putFuel, body, fuel, auth.token)
    } else {
      yield call(postFuel, body, auth.token)
    }
    yield put(closeEditFuelModal())
    const fuelList = yield call(postFuelList, auth.token, filter)
    yield put(fetchFuelListSuccess(fuelList, filter))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}
export function * watchSaveFinancingTypeModal () {
  yield takeEvery(mastersActionTypes.SAVE_FINANCING_TYPE, saveFinancingTypeModal)
}

export function * openFinancingTypeModal ({ financingTypeId, financing, financingTypeProduct }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const languageList = yield call(getLanguageListComboService, auth.token)
    let financingTypeFields = []
    if (financingTypeId) {
      financingTypeFields = yield call(getFinancingTypeById, auth.token, financingTypeId)
    }
    yield put(openFinancingTypeModalSuccess(languageList, financingTypeFields, financingTypeId, financing, financingTypeProduct))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * openPurchasePresentTypeModal ({ uoId, purchasePresentTypeId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const languageList = yield call(getLanguageListComboService, auth.token)
    let purchasePresentTypeFields = []
    if (purchasePresentTypeId) {
      purchasePresentTypeFields = yield call(getPurchasePresentTypeById, auth.token, purchasePresentTypeId)
      for (const language in languageList) {
        yield put(change('editPurchasePresentType', `lang${languageList[language].languageId}.languageId`, languageList[language].languageId))
        const source = purchasePresentTypeFields.purchasePresentTypeLanguage.find((sourceChannel) => sourceChannel.languageId === languageList[language].languageId)
        yield put(change('editPurchasePresentType', `lang${languageList[language].languageId}.name`, source ? source.name : ''))
        yield put(change('editPurchasePresentType', `lang${languageList[language].languageId}.description`, source ? source.description : ''))
        yield put(change('editPurchasePresentType', 'organizedUnitId', purchasePresentTypeFields.organizedUnitId ? purchasePresentTypeFields.organizedUnitId.toString() : ''))
      }

      yield put(openPurchasePresentTypeModalSuccess(languageList, purchasePresentTypeFields, purchasePresentTypeId))
    } else {
      for (const language in languageList) {
        yield put(change('editPurchasePresentType', `lang${languageList[language].languageId}.languageId`, languageList[language].languageId))
        yield put(change('editPurchasePresentType', `lang${languageList[language].languageId}.name`, ''))
        yield put(change('editPurchasePresentType', `lang${languageList[language].languageId}.description`, ''))
        yield put(change('editPurchasePresentType', 'organizedUnitId', ''))
      }
      yield put(openPurchasePresentTypeModalSuccess(languageList, purchasePresentTypeFields, purchasePresentTypeId))
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchOpenPurchasePresentTypeModal () {
  yield takeEvery(mastersActionTypes.OPEN_PURCHASE_PRESENT_TYPE_MODAL, openPurchasePresentTypeModal)
}

export function * openCampaignGroupModal ({ campaignGroup }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const languageList = yield call(getLanguageListComboService, auth.token)
    if (campaignGroup) {
      for (const language in languageList) {
        yield put(change('editCampaignGroup', `lang${languageList[language].languageId}.languageId`, languageList[language].languageId))
        let source = campaignGroup.campaignGroupLanguage.find((sourceChannel) => sourceChannel.languageId === languageList[language].languageId)
        yield put(change('editCampaignGroup', `lang${languageList[language].languageId}.name`, source ? source.name : ''))
        yield put(change('editCampaignGroup', `lang${languageList[language].languageId}.description`, source ? source.description : ''))
      }
      yield put(change('editCampaignGroup', 'campaignClassId', campaignGroup && campaignGroup.campaignClassId ? campaignGroup.campaignClassId : ''))
      yield put(change('editCampaignGroup', 'groupCode', campaignGroup && campaignGroup.groupCode ? campaignGroup.groupCode : ''))
      yield put(change('editCampaignGroup', 'campaignGroupRoles', campaignGroup && campaignGroup.campaignGroupRoles ? campaignGroup.campaignGroupRoles : []))
      yield put(openEditCampaignGroupModalSuccess(languageList, campaignGroup.campaignGroupId))
    } else {
      for (const language in languageList) {
        yield put(change('editCampaignGroup', `lang${languageList[language].languageId}.languageId`, languageList[language].languageId))
        yield put(change('editCampaignGroup', `lang${languageList[language].languageId}.name`, ''))
        yield put(change('editCampaignGroup', `lang${languageList[language].languageId}.description`, ''))
      }
      yield put(change('editCampaignGroup', 'campaignClassId', null))
      yield put(change('editCampaignGroup', 'groupCode', null))
      yield put(change('editCampaignGroup', 'campaignGroupRoles', []))
      yield put(openAddCampaignGroupModalSuccess(languageList, null))
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchOpenCampaignGroupModal () {
  yield takeEvery(mastersActionTypes.OPEN_CAMPAIGN_GROUP_MODAL, openCampaignGroupModal)
}

export function * openCampaignTypeModal ({ campaignType }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const languageList = yield call(getLanguageListComboService, auth.token)
    if (campaignType) {
      for (const language in languageList) {
        yield put(change('editCampaignType', `lang${languageList[language].languageId}.languageId`, languageList[language].languageId))
        let source = campaignType.campaignTypeLanguage.find((sourceChannel) => sourceChannel.languageId === languageList[language].languageId)
        yield put(change('editCampaignType', `lang${languageList[language].languageId}.name`, source ? source.name : ''))
        yield put(change('editCampaignType', `lang${languageList[language].languageId}.description`, source ? source.description : ''))
      }
      yield put(change('editCampaignType', 'campaignGroupId', campaignType && campaignType.campaignGroupId ? campaignType.campaignGroupId : null))
      yield put(change('editCampaignType', 'typeCode', campaignType && campaignType.typeCode ? campaignType.typeCode : null))
      yield put(openEditCampaignTypeModalSuccess(languageList, campaignType.campaignTypeId))
    } else {
      for (const language in languageList) {
        yield put(change('editCampaignType', `lang${languageList[language].languageId}.languageId`, languageList[language].languageId))
        yield put(change('editCampaignType', `lang${languageList[language].languageId}.name`, ''))
        yield put(change('editCampaignType', `lang${languageList[language].languageId}.description`, ''))
      }
      yield put(change('editCampaignType', 'campaignGroupId', null))
      yield put(change('editCampaignType', 'typeCode', null))
      yield put(openAddCampaignTypeModalSuccess(languageList, null))
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchOpenCampaignTypeModal () {
  yield takeEvery(mastersActionTypes.OPEN_CAMPAIGN_TYPE_MODAL, openCampaignTypeModal)
}

export function * watchOpenAddFinancingModal () {
  yield takeEvery(mastersActionTypes.OPEN_ADD_FINANCING_MODAL, openAddFinancingModal)
}

export function * openEditFinancingModal ({ financingId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const financing = yield call(getFinancingById, auth.token, financingId)
    yield put(openEditfinancingModalSuccess(financing))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchRecoverPaymentMethod () {
  yield takeEvery(mastersActionTypes.RECOVER_PAYMENT_METHOD, recoverPaymentMethod)
}

export function * openEditPaymentMethodModal ({ paymentMethodId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const languageList = yield call(getLanguageListComboService, auth.token)
    let paymentMethodFields = []
    if (paymentMethodId) {
      paymentMethodFields = yield call(getPaymentMethodById, auth.token, paymentMethodId)
    }
    yield put(openEditPaymentMethodModalSuccess(languageList, paymentMethodFields, paymentMethodId))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSubmitFuel () {
  yield takeEvery(mastersActionTypes.SUBMIT_FUEL, submitFuel)
}

export function * deleteFuel ({ fuelId }) {
  try {
    const confirmed = yield call(yesNoModal, 'deleteFuel')
    if (confirmed) {
      yield put(showLoader())
      const auth = yield select(getAuth)
      const filter = yield filter || select(getFuelFilter)
      yield call(deleteFuelService, fuelId, auth.token)
      const fuelList = yield call(postFuelList, auth.token, filter)
      yield put(fetchFuelListSuccess(fuelList, filter))
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}
export function * watchOpenFinancingTypeModal () {
  yield takeEvery(mastersActionTypes.OPEN_FINANCING_TYPE_MODAL, openFinancingTypeModal)
}

export function * deleteFinancingTypeModal ({ financingTypeId, companyId }) {
  try {
    const confirmed = yield call(yesNoModal, 'deleteFinancingTypeModal')
    if (confirmed) {
      yield put(showLoader())
      const auth = yield select(getAuth)
      const filter = yield filter || select(getFinancingTypeFilter)
      yield call(deleteFinancingType, auth.token, financingTypeId, companyId)
      var fetchFilter = filter
      fetchFilter.companyId = companyId
      const financeList = yield call(postFinancingTypeList, auth.token, fetchFilter)
      yield put(fetchFinancingProductCompanySuccess(financeList, filter))
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * deletePurchasePresentTypeModal ({ uoId, purchasePresentTypeId }) {
  try {
    const confirmed = yield call(yesNoModal, 'deletePurchasePresentTypeModal')
    if (confirmed) {
      yield put(showLoader())
      const auth = yield select(getAuth)
      const filter = yield filter || select(getPurchasePresentTypeFilter)
      yield call(deletePurchasePresentType, auth.token, purchasePresentTypeId)
      const purchasePresentList = yield call(postPurchasePresentTypeList, auth.token, filter)
      yield put(fetchPurchasePresentTypeListSuccess(purchasePresentList, filter))
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDeletePurchasePresentTypeModal () {
  yield takeEvery(mastersActionTypes.DELETE_PURCHASE_PRESENT_TYPE_MASTERS, deletePurchasePresentTypeModal)
}

export function * watchDeleteFuel () {
  yield takeEvery(mastersActionTypes.DELETE_FUEL, deleteFuel)
}

export function * recoverFuel ({ fuelId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const filter = yield filter || select(getFuelFilter)
    yield call(recoverFuelService, fuelId, auth.token)
    const fuelList = yield call(postFuelList, auth.token, filter)
    yield put(fetchFuelListSuccess(fuelList, filter))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchOpenEditFinancingModal () {
  yield takeEvery(mastersActionTypes.OPEN_EDIT_FINANCING_MODAL, openEditFinancingModal)
}

export function * openEditPurchasePresentModal ({ financingId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const financing = yield call(getFinancingById, auth.token, financingId)
    yield put(openEditfinancingModalSuccess(financing))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * deleteFinancing ({ financingId }) {
  try {
    const confirmed = yield call(yesNoModal, 'deleteFinancing')
    if (confirmed) {
      yield put(showLoader())
      const auth = yield select(getAuth)
      const filter = yield filter || select(getProcessingFinancingFilter)
      yield call(deleteFinancingService, financingId, auth.token)
      const financingList = yield call(postProcessingFinancing, auth.token, filter)
      yield put(fetchFinancingListSuccess(financingList, filter))
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDeleteFinancingTypeModal () {
  yield takeEvery(mastersActionTypes.DELETE_FINANCING_TYPE_MASTERS, deleteFinancingTypeModal)
}

export function * recoverFinancingTypeModal ({ financingTypeId, companyId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const filter = yield filter || select(getFinancingTypeFilter)
    yield call(recoverFinancingType, auth.token, financingTypeId, companyId)
    var fetchFilter = filter
    fetchFilter.companyId = companyId
    const financeList = yield call(postFinancingTypeList, auth.token, fetchFilter)
    yield put(fetchFinancingProductCompanySuccess(financeList, filter))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * recoverPurchasePresentTypeModal ({ uoId, purchasePresentTypeId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const filter = yield filter || select(getPurchasePresentTypeFilter)
    yield call(recoverPurchasePresentType, auth.token, purchasePresentTypeId)
    const purchasePresentList = yield call(postPurchasePresentTypeList, auth.token, filter)
    yield put(fetchPurchasePresentTypeListSuccess(purchasePresentList, filter))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchRecoverPurchasePresentTypeModal () {
  yield takeEvery(mastersActionTypes.RECOVER_PURCHASE_PRESENT_TYPE, recoverPurchasePresentTypeModal)
}

export function * watchOpenEditPaymentMethodModal () {
  yield takeEvery(mastersActionTypes.OPEN_EDIT_PAYMENT_METHOD_MODAL, openEditPaymentMethodModal)
}

export function * openAddPaymentMethodModal () {
  try {
    const auth = yield select(getAuth)
    const languageList = yield call(getLanguageListComboService, auth.token)
    yield put(showLoader())
    yield put(openAddPaymentMethodModalSuccess(languageList))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}
export function * watchDeleteFinancing () {
  yield takeEvery(mastersActionTypes.DELETE_FINANCING, deleteFinancing)
}

export function * submitFinancing ({ body, financing }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const filter = yield filter || select(getProcessingFinancingFilter)
    if (financing) {
      yield call(putFinancing, body, financing.financingCompanyId, auth.token)
    } else {
      yield call(postFinancing, body, auth.token)
    }
    yield put(closeEditFinancingModal())
    const financingList = yield call(postProcessingFinancing, auth.token, filter)
    yield put(fetchFinancingListSuccess(financingList, filter))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchRecoverFinancingTypeModal () {
  yield takeEvery(mastersActionTypes.RECOVER_FINANCING_TYPE, recoverFinancingTypeModal)
}

export function * fetchPurchasePresentTypeList ({ filter }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield filter = yield filter || select(getPurchasePresentTypeFilter)
    const PurchasePresentList = yield call(postPurchasePresentTypeList, auth.token, filter)
    yield put(fetchPurchasePresentTypeListSuccess(PurchasePresentList, filter))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchPurchasePresentTypeList () {
  yield takeEvery(mastersActionTypes.FETCH_PURCHASE_PRESENT_TYPE_LIST, fetchPurchasePresentTypeList)
}

export function * fetchCampaignList ({ filter }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield filter = yield filter || select(getCampaignFilter)
    const campaignList = yield call(postCampaignDetailsList, auth.token, filter)
    yield put(fetchCampaignListSuccess(campaignList, filter))

    const [campaignsTypes] = yield all([
      call(getCampaignType, auth.token)
    ])
    yield put(fetchCombosSuccess({ campaignsTypes: campaignsTypes }))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchCampaignList () {
  yield takeEvery(mastersActionTypes.FETCH_CAMPAIGN_LIST, fetchCampaignList)
}

export function * fetchCampaignGroupList ({ filter }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield filter = yield filter || select(getCampaignGroupFilter)
    const campaignGroupList = yield call(postCampaignGroupList, auth.token, filter)
    yield put(fetchCampaignGroupListSuccess(campaignGroupList, filter))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchCampaignGroupList () {
  yield takeEvery(mastersActionTypes.FETCH_CAMPAIGN_GROUP_LIST, fetchCampaignGroupList)
}

export function * fetchCampaignTypeList ({ filter }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield filter = yield filter || select(getCampaignTypeFilter)
    const campaignTypeList = yield call(postCampaignTypeList, auth.token, filter)
    yield put(fetchCampaignTypeListSuccess(campaignTypeList, filter))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchCampaignTypeList () {
  yield takeEvery(mastersActionTypes.FETCH_CAMPAIGN_TYPE_LIST, fetchCampaignTypeList)
}

export function * fetchFinancingTypeList ({ filter }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield filter = yield filter || select(getFinancingTypeFilter)
    const financeList = yield call(postFinancingTypeList, auth.token, filter)
    yield put(fetchFinancingTypeListSuccess(financeList, filter))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchOpenAddPaymentMethodModal () {
  yield takeEvery(mastersActionTypes.OPEN_ADD_PAYMENT_METHOD_MODAL, openAddPaymentMethodModal)
}

export function * submitPaymentMethod ({ body, paymentMethodId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const filter = yield filter || select(getPaymentMethodFilter)
    if (paymentMethodId) {
      yield call(putPaymentMethod, body, paymentMethodId, auth.token)
    } else {
      yield call(postPaymentMethod, body, auth.token)
    }
    yield put(closeEditPaymentMethodModal())
    const paymentList = yield call(postPaymentMethodList, auth.token, filter)
    yield put(fetchPaymentMethodListSuccess(paymentList, filter))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}
export function * watchSubmitFinancing () {
  yield takeEvery(mastersActionTypes.SUBMIT_FINANCING, submitFinancing)
}

export function * recoverFinancing ({ financingId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const filter = yield filter || select(getProcessingFinancingFilter)
    yield call(putRecoverFinancing, financingId, auth.token)
    const financingList = yield call(postProcessingFinancing, auth.token, filter)
    yield put(fetchFinancingListSuccess(financingList, filter))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchRecoverFuel () {
  yield takeEvery(mastersActionTypes.RECOVER_FUEL, recoverFuel)
}
export function * watchFetchFinancingTypeList () {
  yield takeEvery(mastersActionTypes.FETCH_FINANCING_TYPE_LIST, fetchFinancingTypeList)
}
export function * watchSubmitPaymentMethod () {
  yield takeEvery(mastersActionTypes.SUBMIT_PAYMENT_METHOD, submitPaymentMethod)
}
export function * watchRecoverFinancing () {
  yield takeEvery(mastersActionTypes.RECOVER_FINANCING, recoverFinancing)
}

export function * fetchCountryCampaignDetailList ({ campaignDetailId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const campaignCountryList = yield call(getCampaignDetailsCountries, auth.token, campaignDetailId)
    yield put(fetchCountryCampaignDetailListSuccess(campaignCountryList, campaignDetailId))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchfetchCountryCampaignDetailList () {
  yield takeEvery(mastersActionTypes.FETCH_COUNTRY_CAMPAIGN_DETAIL_LIST, fetchCountryCampaignDetailList)
}

export function * deleteCountryCampaignDetail ({ campaignDetailsCountryId, campaignDetailId }) {
  try {
    const confirmed = yield call(yesNoModal, 'deleteCampaignCountryDetail')
    if (confirmed) {
      yield put(showLoader())
      const auth = yield select(getAuth)
      const result = yield call(delCampaignDetailsCountry, auth.token, campaignDetailsCountryId)
      if (result) {
        yield call(fetchCountryCampaignDetailList, { campaignDetailId })
      }
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchdeleteCountryCampaignDetail () {
  yield takeEvery(mastersActionTypes.DELETE_COUNTRY_CAMPAIGN_DETAIL, deleteCountryCampaignDetail)
}

export function * setCountryCampaignDetail ({ campaignDetailsCountry, campaignDetailId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const result = yield call(postCampaignDetailsCountry, auth.token, campaignDetailsCountry)
    if (result !== null) {
      yield call(fetchCountryCampaignDetailList, { campaignDetailId })
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchsetCountryCampaignDetail () {
  yield takeEvery(mastersActionTypes.SET_COUNTRY_CAMPAIGN_DETAIL, setCountryCampaignDetail)
}

export function * deleteStyleTask ({ taskId }) {
  try {
    const confirmed = yield call(yesNoModal, 'deleteStyleTask')
    if (confirmed) {
      yield put(showLoader())
      const auth = yield select(getAuth)
      const filter = yield filter || select(getStyleTaskFilter)
      yield call(deleteStyleTaskService, auth.token, taskId)
      yield call(fetchStyleTaskList, filter)
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDeleteStyleTask () {
  yield takeEvery(mastersActionTypes.DELETE_STYLE_TASK, deleteStyleTask)
}

export function * openStyleTaskModal ({ styleTask }) {
  try {
    yield put(showLoader())
    if (styleTask) {
      yield put(change('editStyleTask', 'orderId', styleTask && styleTask.order ? styleTask.order : null))
      yield put(change('editStyleTask', 'styleTaskComboId', styleTask && styleTask.styleTaskCssId ? styleTask.styleTaskCssId : null))
      yield put(openEditStyleTaskModalSuccess(styleTask.styleTaskId, styleTask.styleTaskCssId))
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchOpenStyleTaskModal () {
  yield takeEvery(mastersActionTypes.OPEN_STYLE_TASK_MODAL, openStyleTaskModal)
}

export function * fetchStyleTaskList ({ filter }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield filter = yield filter || select(getStyleTaskFilter)
    const styleTaskList = yield call(postStyleTaskList, auth.token, filter)
    yield put(fetchStyleTaskListSuccess(styleTaskList, filter))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchStyleTaskList () {
  yield takeEvery(mastersActionTypes.FETCH_STYLE_TASK_LIST, fetchStyleTaskList)
}

export function * submitStyleTask ({ task, resolve }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const filter = yield filter || select(getStyleTaskFilter)
    yield call(postStyleTaskService, auth.token, task)
    yield put(closeStyleTaskMasterModal())
    yield call(fetchStyleTaskList, filter)
    if (resolve) resolve(true)
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSubmitStyleTask () {
  yield takeEvery(mastersActionTypes.SUBMIT_STYLE_TASK, submitStyleTask)
}

export function * getOrdersStyleTask ({ resolve, reject }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const comboOrders = yield call(getOrdersCombo, auth.token)
    if (resolve) resolve(comboOrders)
  } catch (error) {
    yield call(handleError, { error })
    reject()
  } finally {
    yield put(hideLoader())
  }
}

export function * watchGetOrdersStyleTask () {
  yield takeEvery(mastersActionTypes.STYLE_TASK_ORDERS, getOrdersStyleTask)
}

export function * openEditFinancingModalUo ({ financing, page, pageSize, orderBy }) {
  try {
    if (page == null || page === undefined) {
      page = 1
    }
    if (pageSize == null || pageSize === undefined) {
      pageSize = 10
    }

    if (orderBy === undefined) {
      orderBy = null
    }
    yield put(showLoader())
    const auth = yield select(getAuth)
    const uoList = yield call(findCompaniesWithUO, auth.token, financing.financingCompanyId, page, pageSize, orderBy)
    yield put(openEditfinancingModalUoSuccess(financing, uoList, page, pageSize, orderBy))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchOpenEditFinancingModalUo () {
  yield takeEvery(mastersActionTypes.OPEN_EDIT_FINANCING_MODAL_UO, openEditFinancingModalUo)
}

export function * saveUoFinancingCompany ({ organizedUnitId, financing }) {
  try {
    var page = 1
    var pageSize = 10
    var orderBy = null
    yield put(showLoader())
    const auth = yield select(getAuth)
    const uoAddeds = yield call(postAddUosToCompany, auth.token, organizedUnitId, financing.financingCompanyId)

    if (uoAddeds !== null && uoAddeds.length > 0) {
      var strAdd = '\n'
      uoAddeds.forEach(element => {
        strAdd += element.name + '\n'
      })
      yield put(openModal('insertUoCompany', null, null, null, null, [{ id: '##RELATIONS##', value: strAdd }], null))
    } else {
      yield put(openModal('insertUoCompanyNot', null, null, null, null, null, null))
    }

    yield put(saveUoFinancingCompanySucces(uoAddeds))
    const uoList = yield call(findCompaniesWithUO, auth.token, financing.financingCompanyId, page, pageSize, orderBy)
    yield put(openEditfinancingModalUoSuccess(financing, uoList, page, pageSize, orderBy))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchsaveUoFinancingCompany () {
  yield takeEvery(mastersActionTypes.SAVE_UO_FINANCING_COMPANY, saveUoFinancingCompany)
}

export function * deleteUoFinancingCompany ({ organizedUnitId, financing }) {
  try {
    const confirmed = yield call(yesNoModal, 'deleteUoCompany')
    if (confirmed) {
      var page = 1
      var pageSize = 10
      var orderBy = null
      yield put(showLoader())
      const auth = yield select(getAuth)
      var ok = yield call(deleteCompantyFromUo, auth.token, organizedUnitId, financing.financingCompanyId)
      if (ok) {
        const uoList = yield call(findCompaniesWithUO, auth.token, financing.financingCompanyId, page, pageSize, orderBy)
        yield put(openEditfinancingModalUoSuccess(financing, uoList, page, pageSize, orderBy))
      }
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchdeleteUoFinancingCompany () {
  yield takeEvery(mastersActionTypes.DELETE_UO_FINANCING_COMPANY, deleteUoFinancingCompany)
}

export function * recoverUoFinancingCompany ({ organizedUnitId, financing }) {
  try {
    yield put(showLoader())
    var page = 1
    var pageSize = 10
    var orderBy = null
    const auth = yield select(getAuth)
    var ok = yield call(recoverCompanyFromUo, auth.token, organizedUnitId, financing.financingCompanyId)
    if (ok) {
      const uoList = yield call(findCompaniesWithUO, auth.token, financing.financingCompanyId, page, pageSize, orderBy)
      yield put(openEditfinancingModalUoSuccess(financing, uoList, page, pageSize, orderBy))
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchRecoverUoFinancingCompany () {
  yield takeEvery(mastersActionTypes.RECOVER_UO_FINANCING_COMPANY, recoverUoFinancingCompany)
}

export function * fetchFinancingProductCompany ({ companyId, filter }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    var fetchFilter = filter
    fetchFilter.companyId = companyId
    const financeList = yield call(postFinancingTypeList, auth.token, fetchFilter)
    yield put(fetchFinancingProductCompanySuccess(financeList, filter))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchfetchFinancingProductCompany () {
  yield takeEvery(mastersActionTypes.FETCH_FINANCING_PRODUCT_COMPANY, fetchFinancingProductCompany)
}

export function * openMassModal ({ tkey, massAction, callback }) {
  try {
    yield put(showLoader())
    yield put(openModalMassSuccess(tkey, massAction, callback))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchopenMassModal () {
  yield takeEvery(mastersActionTypes.OPEN_MASS_MODAL, openMassModal)
}

export function * uploadProcAgMassDocument ({ file, code, callback, resolve, t }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    let modalState = yield select(state => state.modalMassUpload)
    let response = yield call(postProcAgDocumentUploadMass, code, auth.token, file)
    let confirmation = modalState.code && modalState.code === response.token
    let succes = response.totalRecords === response.correct
    let errors = []
    if (response.procAgErrors && response.procAgErrors.length > 0) {
      response.procAgErrors.map((er, i) => {
        let prcError = {}
        prcError.errorHeader = er.rowNumber ? er.rowNumber : er.procAgcName
        prcError.errorMessage = er.errorMessage ? t('ERRORS.BACKEND_TAG_TRANSLATION.' + er.errorMessage)
          : er.columErrors && er.columErrors.length > 0 ? er.columErrors.map((ms, i) => { return t('ERRORS.BACKEND_TAG_TRANSLATION.' + ms) + '. ' }) : ''
        errors.push(prcError)
      })
    }
    yield put(fetchUploadTest(response.procAgCreated, errors, succes, succes ? response.token : null, response.StorageConfigError))
    if (succes && confirmation && callback) yield call(callback)
    if (resolve) yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MASTERS.OU_MASTERS.FETCH_ERROR_MSG', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('DocumentMassUpload'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchPrcAgLoadMassDocument () {
  yield takeEvery(mastersActionTypes.UPLOAD_PROC_AG_MASS_DOCUMENT, uploadProcAgMassDocument)
}

export function * showEditProcessingAgencyIntegrationModal ({ processingagencyId, show }) {
  try {
    if (!processingagencyId) {
      yield put(showEditProcessingAgencyIntegrationModalSuccess(show))
    } else {
      yield put(showLoader())
      const auth = yield select(getAuth)
      const agency = yield call(getProcessingAgencyById, auth.token, processingagencyId)
      yield put(showEditProcessingAgencyIntegrationModalSuccess(show, agency))
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchShowEditProcessingAgencyIntegrationModal () {
  yield takeEvery(mastersActionTypes.OPEN_EDIT_PROCESSING_AGENCY_INTEGRATION_MODAL, showEditProcessingAgencyIntegrationModal)
}

export function * updateAgencyCommunicationServiceId ({ processingAgencyId, processAgencyCommunicationServiceId }) {
  try {
    const confirmed = yield call(yesNoModal, 'updateAgencyCommunicationServiceId')
    if (confirmed) {
      yield put(showLoader())
      const auth = yield select(getAuth)
      yield call(putProcessingAgencyCommunicationServiceId, auth.token, processingAgencyId, processAgencyCommunicationServiceId)
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchUpdateAgencyCommunicationServiceId () {
  yield takeEvery(mastersActionTypes.UPDATE_PROCESSING_AGENCY_INTEGRATION_COMMUNICATION_SERVICE_ID, updateAgencyCommunicationServiceId)
}

export function * fetchCodeCommisionList ({ organizedUnitId, page, pageSize, orderBy, orderDirection }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)

    const codeCommisionMastersList = yield call(getCodeCommissionMaster, auth.token, organizedUnitId, page, pageSize, orderBy, orderDirection)
    yield put(fetchcodeCommissionMastersSuccess(codeCommisionMastersList, { page: page }))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchfetchCodeCommisionList () {
  yield takeEvery(mastersActionTypes.FETCH_CODE_COMMISSION_MASTER, fetchCodeCommisionList)
}

export function * deleteCodeCommission ({ commissionCancellationCodeId, uoId }) {
  try {
    const confirmed = yield call(yesNoModal, 'htmlDefaultAcceptCancel', null, null, '<p>#T#MESSAGES.DELETE_CODE_COMMISSION#T#</br>#T#MESSAGES.WANT_CONTINUE#T#<p>')
    if (confirmed) {
      yield put(showLoader())
      const auth = yield select(getAuth)
      yield call(deleteCodeCommissionMaster, auth.token, commissionCancellationCodeId)
      yield call(fetchCodeCommisionList, { organizedUnitId: uoId, page: 1, pageSize: 10, orderBy: 'organizedUnitName', orderDirection: 'ASC' })
      yield put(messageModalActions.openSuccessMessageModal('MESSAGES.DELETED_CODE_COMMISSION'))
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDeleteCodeCommission () {
  yield takeEvery(mastersActionTypes.DELETE_CODE_COMMISSION, deleteCodeCommission)
}

export function * openCodeCommissionModal ({ codeCommission }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const languageList = yield call(getLanguageListComboService, auth.token)
    yield put(initialize('codeCommissionMasterModal', codeCommission))
    if (codeCommission) {
      for (const language in languageList) {
        yield put(change('codeCommissionMasterModal', `lang${languageList[language].languageId}.languageId`, languageList[language].languageId))
        let source = codeCommission.commissionCancellationCodeLanguage.find((sourceChannel) => sourceChannel.languageId === languageList[language].languageId)
        yield put(change('codeCommissionMasterModal', `lang${languageList[language].languageId}.description`, source ? source.description : ''))
      }
      yield put(openCodeCommissionModalSuccess(languageList, codeCommission))
    } else {
      for (const language in languageList) {
        yield put(change('codeCommissionMasterModal', `lang${languageList[language].languageId}.languageId`, languageList[language].languageId))
        yield put(change('codeCommissionMasterModal', `lang${languageList[language].languageId}.description`, ''))
      }
      yield put(openCodeCommissionModalSuccess(languageList, null))
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchOpenCodeCommissionModal () {
  yield takeEvery(mastersActionTypes.OPEN_CODE_COMMISSION_MODAL, openCodeCommissionModal)
}

export function * createOrUpdateCodeCommission ({ codeCommission, uoId }) {
  try {
    if (codeCommission.commissionCancellationCodeId === null || codeCommission.commissionCancellationCodeId === undefined) {
      const confirmed = yield call(yesNoModal, 'htmlDefaultAcceptCancel', null, null, '<p>#T#MESSAGES.CRETE_CODE_COMMISSION#T#</br>#T#MESSAGES.WANT_CONTINUE#T#<p>')
      if (confirmed) {
        yield put(showLoader())
        const auth = yield select(getAuth)
        yield call(postCodeCommissionMaster, auth.token, codeCommission)
        yield call(fetchCodeCommisionList, { organizedUnitId: uoId, page: 1, pageSize: 10, orderBy: 'organizedUnitName', orderDirection: 'ASC' })
        yield put(messageModalActions.openSuccessMessageModal('MESSAGES.CREATED_CODE_COMMISSION'))
      }
    } else {
      const confirmed = yield call(yesNoModal, 'htmlDefaultAcceptCancel', null, null, '<p>#T#MESSAGES.UPDATE_CODE_COMMISSION#T#</br>#T#MESSAGES.WANT_CONTINUE#T#<p>')
      if (confirmed) {
        yield put(showLoader())
        const auth = yield select(getAuth)
        yield call(putCodeCommissionMaster, auth.token, codeCommission)
        yield call(fetchCodeCommisionList, { organizedUnitId: uoId, page: 1, pageSize: 10, orderBy: 'organizedUnitName', orderDirection: 'ASC' })
        yield put(messageModalActions.openSuccessMessageModal('MESSAGES.UPDATED_CODE_COMMISSION'))
      }
    }
    yield put(closeCodeCommissionModal())
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}
export function * watchCreateOrUpdateCodeCommission () {
  yield takeEvery(mastersActionTypes.CREATE_OR_UPDATE_CODE_COMMISSION, createOrUpdateCodeCommission)
}
