import { call, put, select, takeLatest } from 'redux-saga/effects'
import { getAuth } from '../../selectors/access/auth'
import commonActions from '../../actions/common'
import messageModalActions from '../../actions/modals/messageModal'
import { fetchMassiveDownloadStatusComboSuccess } from '../../actions/combos/combos'
import combosActionTypes from '../../constants/actions/combos/combos'
import { handleError } from '../errors/errorManager'
import getAllMassiveDownloadStatus from '../../services/document/getAllMassiveDownloadStatus'

export function * fetchMassiveDownloadStatus () {
  try {
    yield put(commonActions.showLoader())
    const auth = yield select(getAuth)
    const massiveDownloadStatusList = yield call(getAllMassiveDownloadStatus, auth.token)
    yield put(fetchMassiveDownloadStatusComboSuccess(massiveDownloadStatusList))
  } catch (error) {
    const customHandler = function * () {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.FETCH_COMBO_ERROR'))
    }
    yield call(handleError, { error, customHandler })
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchFetchMassiveDownloadStatus () {
  yield takeLatest(combosActionTypes.FETCH_MASSIVE_DOWNLOAD_STATUS, fetchMassiveDownloadStatus)
}
