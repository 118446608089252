import apiFetch from '../apiFetch'

export default function (code, token, file) {
  const queryParamsArray = [
    code ? `code=${code}` : null
  ]
  const formData = new FormData()
  formData.append(file.name, file)
  const queryParams = queryParamsArray.reduce(
    (result, query, idx) => result + (query ? (result ? `&${query}` : `?${query}`) : '')
    , ''
  )
  return apiFetch({ endPoint: `LoadData/User${queryParams}`, method: 'POST', body: formData, token: token, fileBody: true })
}
