import React, { PureComponent } from 'react'
import { Field } from 'redux-form'
import { Panel } from 'react-bootstrap'
import InputMoney from '../../../../_v2/components/commons/form/InputMoney'
import InputCheckBox from '../../../../_v2/components/commons/form/InputCheckBox'
import InputSelect from '../../../../_v2/components/commons/form/InputSelect'
import InputDatePicker from '../../../../_v2/components/commons/form/InputDatePicker'
import { validateDate } from '../../../../util/validationFunctions'
import { compareObjects, getDateNowMaxDatePicker } from '../../../../util/utils'
import { getFieldsPermisions } from '../../../../util/permisionFunctions'
import { permissions, paperworkTypeId } from '../../../../constants/backendIds'
import { economicPlanSectionType, MAX_LENGTH_MONEY } from '../../../../constants/dossier/common/economicPlanSection'
import CustomFields from '../../../../components/commons/customFields/CustomFields'
import ComponentTitle from '../../../../_v2/containers/dossiers/commons/ComponentTitlePage'

class PaperworkComponent extends PureComponent {
  constructor (props) {
    super()
    this.state = {
      economicTarget: props.dossierPaperworkComponent.economicTarget || null,
      fieldsConfiguration: {
        paperworkTypeId: null,
        processingAgencyId: null,
        cost: null,
        amount: null,
        paperworkDeliveryDate: null,
        paperworkReceiptDate: null,
        economicTarget: null,
        hasSupInvoice: null,
        hasIem: null,
        toBeReviewed: null,
        serviceDestination: null,
        vehicleType: null,
        toBeNotify: null,
        operationTypeIds: null,
        operationTotalAmount: null,
        ivaTaxableBase: null,
        processingAgencyManagerId: null
      },
      showToBeNotify: null
    }
  }

  initializePermisions (props) {
    const fieldKeys = Object.keys(this.state.fieldsConfiguration)
    const newState = getFieldsPermisions(props.componentConfiguration.fieldsConfiguration, fieldKeys, props.componentConfiguration.permission)
    this.setState({ ...this.state, ...newState })
  }

  UNSAFE_componentWillMount () {
    this.initializePermisions(this.props)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.dossierId && !compareObjects(this.props.componentConfiguration || {}, nextProps.componentConfiguration, true)) {
      this.initializePermisions(nextProps)
    }
  }

  setpaperworkTypeId (value) {
    if (value === paperworkTypeId.registration) {
      this.setState({ showToBeNotify: true })
    } else {
      this.setState({ showToBeNotify: false })
    }
  }

  componentDidMount () {
    this.initializePermisions(this.props)
    if (this.props.paperworkTypeId === paperworkTypeId.registration) {
      this.setState({ showToBeNotify: true })
    }
  }

  validateDate (value, allValues, props) {
    const result = validateDate(value, props)
    return result === Object(result) ? undefined : result
  }

  chargeProcessingAgencyManagersCombo (processingAgencyId) {
    this.props.actions.fetchProcessingAgencyManagersCombo(processingAgencyId ?? 0)
    if (!processingAgencyId) {
      this.props.dossierPaperworkComponent.processingAgencyManagerId = null
    }
  }

  render () {
    const {
      t, paperworkOpen, dossierPaperworkComponent, dossierParentId, readOnlyFields, customFields,
      combos: { paperworkTypes, processingAgency, serviceDestinationCombo, vehicletypeCombo, operationTypeCombo },
      actions: { togglePanel }
    } = this.props

    const processingAgencyManagersCombo = this.props.combos.processingAgencyManagersCombo?.filter(x => !x.isDeleted ||
      x.processingAgencyManagerId === dossierPaperworkComponent?.processingAgencyManagerId) ?? []
    const tKey = 'DOSSIER_COMPONENTS.PAPERWORK_DOSSIER.PAPERWORK_COMPONENT.'
    let economicTargetTypes = this.props.combos.economicTargetTypes
    economicTargetTypes = economicTargetTypes.map((economicTargetType) => {
      return { ...economicTargetType, value: t(`${tKey}ECONOMIC_PLAN_SECTION_TYPES.${economicTargetType.value}`) }
    })
    return (
      <div className="dossier-Component">
        {!this.props.isFleetModal && (
          <div className="header-panel" onClick={togglePanel}>
            <ComponentTitle
              componentCode="PAPERWORK_DOSSIER"
              componentOpen={paperworkOpen}
              // extraInfo={'info extra'}
              // extraData={<a className="_Btn accept">texto</a>}
            />
          </div>
        )}
        <Panel expanded={paperworkOpen} onToggle={() => {}}>
          <Panel.Collapse>
            <div className='fieldsContainer'>
              {this.state.fieldsConfiguration.paperworkTypeId.permission !== permissions.hidden && (
                <Field
                  component={InputSelect}
                  colWidht={33} // 25, 33, 50, 75, 100 default=25
                  id="paperworkTypeId"
                  name="dossierPaperworkComponent.paperworkTypeId"
                  controlLabel={t(`${tKey}PAPERWORK_TYPE`)}
                  placeholder={t(`${tKey}PAPERWORK_TYPE`)}
                  options={paperworkTypes}
                  valueKey="id"
                  labelKey="value"
                  disabled={this.state.fieldsConfiguration.paperworkTypeId.permission !== permissions.editable || readOnlyFields}
                  onChange={(value) => this.setpaperworkTypeId(value)}
                />
              )}
              {this.state.fieldsConfiguration.processingAgencyId.permission !== permissions.hidden && (
                <Field
                  component={InputSelect}
                  colWidht={33} // 25, 33, 50, 75, 100 default=25
                  id="processingAgencyId"
                  name="dossierPaperworkComponent.processingAgencyId"
                  controlLabel={t(`${tKey}PROCESSING_AGENCY`)}
                  placeholder={t(`${tKey}PROCESSING_AGENCY`)}
                  options={processingAgency}
                  valueKey="id"
                  labelKey="value"
                  disabled={this.state.fieldsConfiguration.processingAgencyId.permission !== permissions.editable || readOnlyFields}
                  onChange = {(value) => this.chargeProcessingAgencyManagersCombo(value)}
                />
              )}
              {this.state.fieldsConfiguration.processingAgencyManagerId.permission !== permissions.hidden && (
                <Field
                  component={InputSelect}
                  colWidht={33} // 25, 33, 50, 75, 100 default=25
                  id="processingAgencyManagerId"
                  name="dossierPaperworkComponent.processingAgencyManagerId"
                  controlLabel={t(`${tKey}PROCESSING_AGENCY_MANAGER`)}
                  placeholder={t(`${tKey}PROCESSING_AGENCY_MANAGER`)}
                  options={processingAgencyManagersCombo}
                  valueKey="processingAgencyManagerId"
                  labelKey="name"
                  disabled={this.state.fieldsConfiguration.processingAgencyManagerId.permission !== permissions.editable || readOnlyFields}
                />
              )}
              {this.state.fieldsConfiguration.operationTotalAmount.permission !== permissions.hidden && (
                <Field
                  component={InputMoney}
                  colWidht={33} // 25, 33, 50, 75, 100 default=25
                  id="operationTotalAmount"
                  name="dossierPaperworkComponent.operationTotalAmount"
                  type="text"
                  controlLabel={t(`${tKey}OPERATION_TOTAL_AMOUNT`)}
                  placeholder={t(`${tKey}OPERATION_TOTAL_AMOUNT`)}
                  disabled={this.state.fieldsConfiguration.operationTotalAmount.permission !== permissions.editable }
                />
              )}
              {this.state.fieldsConfiguration.ivaTaxableBase.permission !== permissions.hidden && (
                <Field
                  component={InputMoney}
                  colWidht={33} // 25, 33, 50, 75, 100 default=25
                  id="ivaTaxableBase"
                  name="dossierPaperworkComponent.ivaTaxableBase"
                  type="text"
                  controlLabel={t(`${tKey}IVA_TAXABLE_BASE`)}
                  placeholder={t(`${tKey}IVA_TAXABLE_BASE`)}
                  disabled={this.state.fieldsConfiguration.ivaTaxableBase.permission !== permissions.editable }
                />
              )}
              {this.state.fieldsConfiguration.cost.permission !== permissions.hidden && dossierPaperworkComponent.showEconomicPlan === true && (
                <Field
                  component={InputMoney}
                  colWidht={33} // 25, 33, 50, 75, 100 default=25
                  id="cost"
                  name="dossierPaperworkComponent.cost"
                  type="text"
                  controlLabel={t(`${tKey}COST`)}
                  placeholder={t(`${tKey}COST`)}
                  disabled={this.state.fieldsConfiguration.cost.permission !== permissions.editable || readOnlyFields}
                  maxLength={MAX_LENGTH_MONEY}
                />
              )}
              {this.state.fieldsConfiguration.amount.permission !== permissions.hidden && (
                <Field
                  component={InputMoney}
                  colWidht={33} // 25, 33, 50, 75, 100 default=25
                  id="amount"
                  name="dossierPaperworkComponent.amount"
                  type="text"
                  controlLabel={t(`${tKey}AMOUNT`)}
                  placeholder={t(`${tKey}AMOUNT`)}
                  disabled={this.state.fieldsConfiguration.amount.permission !== permissions.editable || readOnlyFields}
                  maxLength={MAX_LENGTH_MONEY}
                />
              )}
              {this.state.fieldsConfiguration.paperworkDeliveryDate.permission !== permissions.hidden && (
                <Field
                  component={InputDatePicker}
                  colWidht={33} // 25, 33, 50, 75, 100 default=25
                  maxDate={getDateNowMaxDatePicker()}
                  id="paperworkDeliveryDate"
                  name="dossierPaperworkComponent.paperworkDeliveryDate"
                  controlLabel={t(`${tKey}DELIVERY_DATE`)}
                  validate={[this.validateDate]}
                  disabled={this.state.fieldsConfiguration.paperworkDeliveryDate.permission !== permissions.editable || readOnlyFields}
                />
              )}
              {this.state.fieldsConfiguration.paperworkReceiptDate.permission !== permissions.hidden && (
                <Field
                  component={InputDatePicker}
                  colWidht={33} // 25, 33, 50, 75, 100 default=25
                  maxDate={getDateNowMaxDatePicker()}
                  id="paperworkReceiptDate"
                  name="dossierPaperworkComponent.paperworkReceiptDate"
                  controlLabel={t(`${tKey}RECEIPT_DATE`)}
                  validate={[this.validateDate]}
                  disabled={this.state.fieldsConfiguration.paperworkReceiptDate.permission !== permissions.editable || readOnlyFields}
                />
              )}
              {dossierPaperworkComponent.showEconomicPlan === true &&
              this.state.fieldsConfiguration.economicTarget.permission !== permissions.hidden && dossierParentId && (
                <Field
                  component={InputSelect}
                  colWidht={33} // 25, 33, 50, 75, 100 default=25
                  id="economicTarget"
                  name="dossierPaperworkComponent.economicTarget"
                  controlLabel={t(`${tKey}ECONOMIC_TARGET`)}
                  placeholder={t(`${tKey}ECONOMIC_TARGET`)}
                  options={economicTargetTypes}
                  valueKey="id"
                  labelKey="value"
                  disabled={this.state.fieldsConfiguration.economicTarget.permission !== permissions.editable || readOnlyFields}
                  onInputChange={(val) => { this.setState({ economicTarget: val }) }}
                />
              )}
              {this.state.fieldsConfiguration.serviceDestination.permission !== permissions.hidden && (
                <Field
                  component={InputSelect}
                  colWidht={33} // 25, 33, 50, 75, 100 default=25
                  id="serviceDestinationId"
                  name="dossierPaperworkComponent.serviceDestinationId"
                  controlLabel={t(`${tKey}SERVICE_DESTINATION`)}
                  placeholder={t(`${tKey}SERVICE_DESTINATION`)}
                  options={serviceDestinationCombo}
                  valueKey="id"
                  labelKey="value"
                  disabled={this.state.fieldsConfiguration.serviceDestination.permission !== permissions.editable || readOnlyFields}
                  menuContainerStyle={{ zIndex: 999 }}
                />)}
              {this.state.fieldsConfiguration.vehicleType.permission !== permissions.hidden && (
                <Field
                  component={InputSelect}
                  colWidht={33} // 25, 33, 50, 75, 100 default=25
                  id="vehicleTypeId"
                  name="dossierPaperworkComponent.vehicleTypeId"
                  controlLabel={t(`${tKey}VEHICLE_TYPE`)}
                  placeholder={t(`${tKey}VEHICLE_TYPE`)}
                  options={vehicletypeCombo}
                  valueKey="id"
                  labelKey="value"
                  disabled={this.state.fieldsConfiguration.vehicleType.permission !== permissions.editable || readOnlyFields}
                  menuContainerStyle={{ zIndex: 999 }}
                  blurInputOnSelect= {false}
                />)}
              { this.state.fieldsConfiguration.operationTypeIds.permission !== permissions.hidden && (
                <Field
                  component={InputSelect}
                  colWidht={33} // 25, 33, 50, 75, 100 default=25
                  id="operationTypeIds"
                  name="dossierPaperworkComponent.operationTypeIds"
                  controlLabel={t(`${tKey}OPERATION_TYPE`)}
                  placeholder={t(`${tKey}OPERATION_TYPE`)}
                  valueKey="id"
                  labelKey="value"
                  options={operationTypeCombo}
                  disabled={this.state.fieldsConfiguration.operationTypeIds.permission !== permissions.editable || readOnlyFields}
                  multi
                  menuContainerStyle={{ zIndex: 999 }}
                  blurInputOnSelect= {false}
                />)}
            </div>
            {this.state.fieldsConfiguration.toBeNotify.permission !== permissions.hidden && this.state.showToBeNotify && (
              <div className="dossier-Component">
                <Field
                  component={InputCheckBox}
                  colWidht={33} // 25, 33, 50, 75, 100 default=25
                  id="toBeNotify"
                  name="dossierPaperworkComponent.toBeNotify"
                  disabled={this.state.fieldsConfiguration.toBeNotify.permission !== permissions.editable || readOnlyFields}
                  placeholder={t(`${tKey}TO_BE_NOTIFY`)}
                />
              </div>
            )}
            {
              dossierPaperworkComponent.showEconomicPlan === true ? (
                <div className="dossier-Component">
                  { this.state.fieldsConfiguration.hasSupInvoice.permission !== permissions.hidden && (this.state.economicTarget === economicPlanSectionType.VENTA_OTROS ||
                  this.state.economicTarget === economicPlanSectionType.SUPLIDOS) && (
                    <Field
                      component={InputCheckBox}
                      colWidht={33} // 25, 33, 50, 75, 100 default=25
                      id="hasSupInvoice"
                      name="dossierPaperworkComponent.hasSupInvoice"
                      placeholder={t(`${tKey}SUPPLEMENTARY_INVOICE`)}
                      disabled={this.state.fieldsConfiguration.hasSupInvoice.permission !== permissions.editable || readOnlyFields}
                    />
                  )}
                  { this.state.fieldsConfiguration.hasIem.permission !== permissions.hidden && this.state.economicTarget === economicPlanSectionType.VENTA_OTROS && (
                    <Field
                      component={InputCheckBox}
                      colWidht={33} // 25, 33, 50, 75, 100 default=25
                      id="hasIem"
                      name="dossierPaperworkComponent.hasIem"
                      placeholder={t(`${tKey}IEM`)}
                      disabled={this.state.fieldsConfiguration.hasIem.permission !== permissions.editable || readOnlyFields}
                    />
                  )}
                  {this.state.fieldsConfiguration.toBeReviewed.permission !== permissions.hidden && (
                    <Field
                      component={InputCheckBox}
                      colWidht={33} // 25, 33, 50, 75, 100 default=25
                      id="toBeReviewed"
                      name="dossierPaperworkComponent.toBeReviewed"
                      disabled={this.state.fieldsConfiguration.toBeReviewed.permission !== permissions.editable || readOnlyFields}
                      placeholder={t(`${tKey}TO_BE_REVIEWED`)}
                    />
                  )}
                </div>
              ) : null
            }
            <CustomFields customFields={customFields} readOnlyFields={readOnlyFields} />
          </Panel.Collapse>
        </Panel>
      </div>
    )
  }
}

export default PaperworkComponent
