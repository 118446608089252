import React, { PureComponent } from 'react'
import { reduxForm } from 'redux-form'
import { Col, Form, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Element } from 'react-scroll'
import { compareObjects, getDocumentaryPermission } from '../../../../util/utils'
import {
  validateEntity, validateDelivery, validateContact, isEntityEmpty, isEntityChildEmpty, isContactEmpty, isSvnInQuadisWithFleetsAndRenting,
  isRequiredEndCustomerAndContactVGR, validateDossierEconomic, getFieldErrors, validateFinancingModal, isSvnWithFleets,
  checkCurrencyValidation, isVisibleFleetCode, validateRegex, customValidations
} from '../../../../util/validationFunctions'
import { getComponentsPermisions } from '../../../../util/permisionFunctions'
import StatusHeaderPage from '../../../../containers/dossiers/common/StatusHeaderPage'
import ButtonsHeader from '../../../../_v2/components/dossiers/common/ButtonsHeader'
import ButtonsSidebar from '../../../../_v2/components/dossiers/common/ButtonsSidebar'

import ReassignReplacePurchaseModalPage from '../../../../containers/dossiers/common/ReassignReplacePurchaseModalPage'
import { dossierTypes as dossierTypesConstant } from '../../../../constants/dossier/common/dossierTypes'
import { dossierSubTypeId as dossierSubTypeIdConstants } from '../../../../constants/dossier/common/dossierSubType'
import { buttonsHeader, buttonsHeaderPermisionsIdReverse } from '../../../../constants/dossier/common/buttonsHeader'
import { buttonsSidebar } from '../../../../constants/dossier/common/buttonsSidebar'
import { permissions, clientInformation/*, urgencyTypesClass */ } from '../../../../constants/backendIds'

import DocumentaryManagementModalPage from '../../../../containers/dossiers/common/DocumentaryManagementModalPage'
import RejectCommentModal from '../../../../components/dossiers/common/RejectCommentModal'
import CampaignsModal from '../../../../components/dossiers/sales/campaings/CampaignsModal'
import CampaignsConfigureModal from '../../../../components/dossiers/sales/campaings/CampaignsConfigureModal'
import CampaignSearchDocumentsModal from '../../../../components/dossiers/common/DownloadDocumentsModal'

import SmallLoaderPage from '../../../../containers/commons/SmallLoaderPage'

import SaleRequestModal from '../../../../components/dossiers/sales/SaleRequestModal'
import SaleFleetModal from '../../../../components/dossiers/sales/SaleFleetModal'
import SendDocumentsSignModal from '../../../../components/dossiers/common/documentaryManagement/SendDocumentsSignModal'
import TrackingContainer from '../../../../containers/tracking/trackingContainer'
import { TrackedPages } from '../../../../constants/tracking/TrackedPages'

import setting from '../../../../setting'

import { saleTypeCodeEnum } from '../../../../constants/dossier/sale/saleType'
import SelfSupplyFinderModalPage from '../../../../containers/dossiers/sales/SelfSupply/SelfSupplyFinderModalPage'
import { buttonsPermissions } from '../../../../constants/dossier/common/buttonsPermissions'
import ChargeQuiterModalPage from '../../../../containers/dossiers/common/ChargeQuiterModalPage'
import DocumentDataCDAModalPage from '../../../../containers/dossiers/common/DocumentDataCDAModalPage'
import {
  asyncValidateEntityDni, getFieldsValidated
} from '../../../../util/validationFunctionEntityDniAsync'
import { validationSalesPromisesOrder } from '../../../../constants/validatedFields/backValidationPromisesOrder'
import promisesStatus from '../../../../constants/promisesStatus'
import UACModalPage from '../../../../components/dossiers/common/uac/UACModalPage'
/// ////////////////////////////////////////////////////////////
import DossierHeaderPage from '../../../containers/dossiers/commons/dossierHeaderPage'
import DossierTimeLineSectionPage from '../../../containers/dossiers/commons/DossierTimeLineSectionPage'
import DossierSummary from '../common/DossierSummary'
/// ////_V2 componets ////////////////////////////////////////////////////////
import SalesComponent from '../../../../_v2/components/dossiers/sales/SalesComponent'
import Fleet from '../../../../_v2/components/dossiers/sales/Fleet'
import Client from '../../../../_v2/components/dossiers/common/Client'
import Vehicle from '../../../../_v2/components/dossiers/common/Vehicle'
import SelfSupply from '../../../../_v2/components/dossiers/sales/SelfSupply'
import Campaigns from '../../../../_v2/components/dossiers/sales/Campaigns'
import UAC from '../../../../_v2/components/dossiers/common/uac/UAC'
import Brokerage from '../../../../_v2/components/dossiers/sales/Brokerage'
import Finance from '../../../../_v2/components/dossiers/sales/Finance'
import PaperworkTablePage from '../../../../_v2/containers/dossiers/commons/PaperworkComponentPage'
import Charges from '../../../../_v2/components/dossiers/common/Charges'
import Services from '../../../../_v2/components/dossiers/common/Services'
import OtherData from '../../../../_v2/components/dossiers/common/OtherData'
import Delivery from '../../../../_v2/components/dossiers/common/Delivery'
/// ////////////
import DocumentaryManagementPage from '../../../../_v2/containers/dossiers/commons/DocumentaryManagementPage'
import EconomicPlan from '../../../../_v2/components/dossiers/sales/EconomicPlan'
import RelatedDossiers from '../common/RelatedDossiers'
import AuditInfo from '../../../../_v2/components/dossiers/common/audit/AuditInfo'
import CommentComponent from '../../../../_v2/components/dossiers/common/comment/CommentComponent'

class Sales extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      salesOpen: true,
      modalOpen: true,
      isVehicleLoaded: false,
      isClientLoaded: false,
      isFinanceLoaded: false,
      isBrokerageLoaded: false,
      isDeliveryLoaded: false,
      isEconomicLoaded: false,
      showRejectCommentModal: false,
      showUACModal: false,
      showConfigureCampaign: false,
      UACSelected: {},
      showCampaignModal: false,
      showCampaignModalConfigure: false,
      showSendDocumentsModal: false,
      showCampaignSearchDocumentsModal: false,
      showSaleRequestModal: true,
      showFleetsModalSelection: true,
      campaignSelected: {},
      accessoriesOpen: false,
      optionalsOpen: false,
      isOtherDataLoaded: false,
      isFleetLoaded: false,
      noRecoverableCostsOpen: false,
      isSaved: false,
      activeTabConfiguration: null,
      headerTabConfiguration: null,
      componentsConfiguration: {
        bsale: null,
        entity: null,
        vehicle: null,
        campaign: null,
        uac: null,
        finance: null,
        brokerage: null,
        paperwork: null,
        charge: null,
        other_data: null,
        delivery: null,
        services: null,
        fleet: null,
        self_supply: null
      },
      reassignReplacePurchaseModalConfig: null,
      isSelfSupplyLoaded: false,
      reason: null,
      comment: null,
      disableExternalOrderId: false,
      submitDispatched: false,
      showRejectCommentModalTransfer: false,
      dossierDataGroupDOSSOpen: true,
      dossierDataGroupQrOpen: true,
      dossierDataGroupDOCOpen: true,
      dossierDataGroupENTITYOpen: true,
      dossierDataGroupVEHICLEOpen: true,
      dossierBodyLeftClose: false,
      showTimelineInTab: false
    }
    this.goBack = this.goBack.bind(this)
    this.handleSubmitForm = this.handleSubmitForm.bind(this)
  }

  toggleShowTimelineInTab () {
    const showTimelineInTab = !this.state.showTimelineInTab

    if (this.props.activeTab === buttonsHeader.time_line) {
      let nextActiveTab = this.props.dossier.viewConfiguration.tabsConfiguration.filter((tabConf) => tabConf.permission > permissions.hidden)
      this.props.actions.setActiveTab(buttonsHeaderPermisionsIdReverse[nextActiveTab[0].code])
    }
    this.setState({ showTimelineInTab })
  }

  toggleRejectCommentModal (showRejectCommentModal) {
    this.setState({ showRejectCommentModal })
  }

  toggleRejectCommentTransfer (showRejectCommentModalTransfer) {
    this.setState({ showRejectCommentModalTransfer })
  }

  toggleUACModal (showUACModal, UACUnit) {
    const UACSelected = UACUnit || {}
    this.setState({ showUACModal, UACSelected })
  }

  toggleCampaignModal (showCampaignModal, campaign) {
    const campaignSelected = campaign || {}
    this.setState({ showCampaignModal, campaignSelected })
  }

  toggleConfigureCampaignModal (showCampaignModalConfigure) {
    this.setState({ showCampaignModalConfigure })
  }

  toggleSendDocumentsModal (showSendDocumentsModal) {
    this.setState({ showSendDocumentsModal })
  }

  toggleCampaignSearchDocumentsModal (showCampaignSearchDocumentsModal) {
    this.setState({ showCampaignSearchDocumentsModal })
  }

  toggleSaleRequestModal (showSaleRequestModal) {
    this.setState({ showSaleRequestModal })
  }

  toggleSaleFleetModal (showFleetsModalSelection) {
    this.setState({ showFleetsModalSelection })
  }

  handleSubmitForm (resolve, reject) {
    let skipGeneralMsg = false
    if (this.props.formErrors) {
      if (this.props.formErrors.salesComponent && Object.keys(this.props.formErrors.salesComponent).length > 0) {
        this.props.actions.setScrollTo(buttonsSidebar.bsale, null, 'dossierData')
        this.props.actions.setActiveSection(buttonsSidebar.bsale)
        this.props.actions.openErrorMessageModal('DOSSIER_COMPONENTS.VALIDATIONS.DOSSIER_DATA_MANDATORY')
        skipGeneralMsg = true
      } else if (this.props.formErrors.entityComponent && Object.keys(this.props.formErrors.entityComponent).length > 0 ||
        this.props.formErrors.entityChildComponent && Object.keys(this.props.formErrors.entityChildComponent).length > 0 ||
        this.props.formErrors.dossierContactComponent && Object.keys(this.props.formErrors.dossierContactComponent).length > 0) {
        this.props.actions.setScrollTo(buttonsSidebar.entity, null, 'dossierData')
        this.props.actions.setActiveSection(buttonsSidebar.entity)
        skipGeneralMsg = true
        if ((this.props.formErrors.entityComponent && Object.keys(this.props.formErrors.entityComponent).length > 0)) {
          this.props.actions.openErrorMessageModal('DOSSIER_COMPONENTS.VALIDATIONS.CLIENT_DATA_MANDATORY')
        } else if ((this.props.formErrors.entityChildComponent && Object.keys(this.props.formErrors.entityChildComponent).length > 0) || (this.props.formErrors.dossierContactComponent && Object.keys(this.props.formErrors.dossierContactComponent).length > 0)) {
          this.props.actions.openErrorMessageModal('DOSSIER_COMPONENTS.VALIDATIONS.FINAL_CLIENT_OR_CONTACT_MANDATORY')
        }
      } else if (this.props.formErrors.dossierInsuranceCarrierComponent &&
        Object.keys(this.props.formErrors.dossierInsuranceCarrierComponent).length > 0) {
        this.props.actions.setScrollTo(buttonsSidebar.brokerage, null, 'dossierData')
        this.props.actions.setActiveSection(buttonsSidebar.brokerage)
      } else if (this.props.formErrors.dossierFinancingComponent && Object.keys(this.props.formErrors.dossierFinancingComponent).length > 0) {
        this.props.actions.setScrollTo(buttonsSidebar.finance, null, 'dossierData')
        this.props.actions.setActiveSection(buttonsSidebar.finance)
      } else if (this.props.formErrors.dossierCompleteDeliveryComponent && Object.keys(this.props.formErrors.dossierCompleteDeliveryComponent.dossierDeliveryComponent).length > 0) {
        this.props.actions.setScrollTo(buttonsSidebar.delivery, null, 'dossierData')
        this.props.actions.setActiveSection(buttonsSidebar.delivery)
      } else if (this.props.formErrors.vehicleComponent && Object.keys(this.props.formErrors.vehicleComponent).length > 0) {
        this.props.actions.setScrollTo(buttonsSidebar.vehicle, null, 'dossierData')
        this.props.actions.setActiveSection(buttonsSidebar.vehicle)
      } else if (this.props.formErrors.dossierSelfSupplyComponent && Object.keys(this.props.formErrors.dossierSelfSupplyComponent).length > 0) {
        this.props.actions.setScrollTo(buttonsSidebar.self_supply, null, 'dossierData')
        this.props.actions.setActiveSection(buttonsSidebar.self_supply)
      } else if (this.props.formErrors.dossierCompleteOtherDataComponent &&
        Object.keys(this.props.formErrors.dossierCompleteOtherDataComponent).length > 0) {
        this.props.actions.setScrollTo(buttonsSidebar.other_data, null, 'dossierData')
        this.props.actions.setActiveSection(buttonsSidebar.other_data)
        this.props.actions.openErrorMessageModal('DOSSIER_COMPONENTS.VALIDATIONS.OTHER_DATA_MANDATORY')
        skipGeneralMsg = true
      }
      if (this.props.isInvalid && !skipGeneralMsg) {
        this.props.actions.openErrorMessageModal('DOSSIER_COMPONENTS.VALIDATIONS.GENERAL_MANDATORY')
      }
    }
    this.props.handleSubmit((values) => {
      if (values.salesComponent && values.salesComponent.multipleChoice === true && values.salesComponent.maintenanceInclude === true) {
        this.props.actions.openCommonModal('incompatibleSelection')
      } else if (!skipGeneralMsg) {
        this.submitSalesDossier(values, resolve, reject)
      }
    })()

    this.setState({ submitDispatched: true })
  }

  submitSalesDossier (values, resolve, reject) {
    this.props.actions.saveSalesDossier(values, resolve, reject)
  }

  fetchSalesDossierPromise (dossierId) {
    return new Promise((resolve, reject) => {
      return this.props.actions.fetchSalesDossier(dossierId, resolve, reject)
    })
  }

  routerWillLeave (nextLocation) {
    let pristine = true
    if (this.props.dossier.viewConfiguration.buttonsPermission && this.props.dossier.viewConfiguration.buttonsPermission.includes('sale_save')) {
      pristine = this.props.pristine
    }
    this.props.actions.saleWarningSave(this.props.reset, this.props.history, nextLocation, pristine)
    return pristine
  }

  initializePermisions (props) {
    const componentKeys = Object.keys(this.state.componentsConfiguration)
    const newState = getComponentsPermisions(props.dossier.viewConfiguration.tabsConfiguration, props.activeTab, componentKeys)
    this.setState({ ...this.state, ...newState, reassignReplacePurchaseModalConfig: (newState.componentsConfiguration?.bsale ?? this.state.reassignReplacePurchaseModalConfig) })
  }

  componentDidMount () {
    this.props.actions.restartSalesState()
    this.fetchSalesDossierPromise(this.props.match.params.dossierId).then(() => {
      const form = {
        ...this.props.dossier,
        entityComponent: this.props.dossier.entityComponent.entityId ? this.props.dossier.entityComponent : undefined,
        entityChildComponent: this.props.dossier.entityChildComponent.entityId ? this.props.dossier.entityChildComponent : undefined
      }
      this.props.initialize(form)
      this.initializePermisions(this.props)
      this.props.actions.fetchAllReasons(this.props.dossier.dossierSubTypeId)
      this.props.actions.fetchCustomFields(this.props.dossier.dossierSubTypeId)
      this.props.actions.registerLastAccess(this.props.match.params.dossierId, false)
      this.props.actions.fetchSalesDossierDependants(this.props.match.params.dossierId)
      this.props.actions.fetchHomologationClassificationCombo()
      this.props.actions.fetchHomologationProducerCombo()
      this.props.actions.fetchIaeCombo()
      this.props.actions.fetchStockStatusCombo()
      this.props.actions.fetchSameLevelOusCombo(this.props.dossier.organizedUnitId, this.props.dossier.dossierSubTypeId)

      this.props.trackVisitPage({
        documentTitle: TrackedPages.SALE,
        organizedUnitId: this.props.dossier.organizedUnitId,
        href: window.location.href
      })
    })
    this.unregisterLeaveHook = this.props.history.block(this.routerWillLeave.bind(this))

    this.setState({ showTimelineInTab: this.props.auth.showChecklistAsTab ?? false })
  }

  getSnapshotBeforeUpdate (prevProps) {
    if ((this.props.showManagementModal && !prevProps.showManagementModal) || this.props.match.params.dossierId !== prevProps.match.params.dossierId) {
      if (this.props.match.params.dossierId !== prevProps.match.params.dossierId) {
        this.props.actions.restartSalesState()
        this.props.actions.setScrollTo(buttonsSidebar.bsale)
        this.props.actions.setActiveSection(buttonsSidebar.bsale)
        this.props.actions.setActiveTab(buttonsHeader.datos_expediente)
        this.props.actions.closeAddOrEditDocumentModal()
      }
      this.fetchSalesDossierPromise(this.props.match.params.dossierId).then(() => {
        const form = { ...this.props.dossier }
        if (!this.props.dossier.entityComponent.entityId) delete form.entityComponent
        this.props.initialize(form)
        this.initializePermisions(this.props)
        this.props.actions.fetchSalesDossierDependants(this.props.match.params.dossierId)
      })
    }
    if (this.props.dossier && this.props.dossier.viewConfiguration && this.props.dossier.viewConfiguration.tabsConfiguration && !compareObjects(prevProps.dossier.viewConfiguration.tabsConfiguration || {}, this.props.dossier.viewConfiguration.tabsConfiguration, true)) {
      this.initializePermisions(this.props)
    }
    if (prevProps.dossier.dossierId && prevProps.activeTab !== this.props.activeTab) {
      this.initializePermisions(this.props)
    }
    if (this.props.dossier && this.props.dossier.comments && this.props.activeTab !== prevProps.activeTab && prevProps.activeTab === buttonsHeader.observaciones) {
      this.props.actions.getComments(this.props.dossier.dossierId, 'salesDossier')
    }
    if (this.props.dossier && this.props.dossier.organizedUnitId && this.props.dossier.organizedUnitId !== prevProps.dossier.organizedUnitId) {
      this.props.actions.fecthOrganizedUnitsByDossierUo(this.props.dossier.organizedUnitId)
    }

    if ((this.props.dossier && this.props.dossier.dossierId && prevProps.dossier && (prevProps.dossier.dossierId === null || prevProps.dossier.dossierId === undefined)) ||
      (this.props.dossier && this.props.dossier.dossierSelfSupplyComponent && this.props.dossier.dossierSelfSupplyComponent.removedComponent)) {
      this.setState({ isSelfSupplyLoaded: false })
    }

    return prevProps
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (this.props.dossier.dossierId && this.props.statusHeaderHeight && this.props.activeSection) {
      switch (this.props.activeSection) {
        case buttonsSidebar.bsale:
          this.setState({ salesOpen: true })
          break
      }
    }
    if (!this.props.autoScroll && this.props.activeSection !== buttonsSidebar.bsale && this.props.activeSection !== false) {
      this.props.actions.setScrollTo(this.props.activeSection)
    }
    if (this.props.formErrors && this.state.submitDispatched && !this.props.dossier.submitSucced) {
      let errores = getFieldErrors(this.props.formErrors)
      if (errores.length > 0 && this.props.actions.updateErrorModel) {
        this.props.actions.updateErrorModel('salesDossier', errores)
      }
    }

    const firstCustomFieldsLoad = prevProps.dossier.customFieldsByComponent?.length < this.props.dossier.customFieldsByComponent?.length
    if (firstCustomFieldsLoad) {
      let customFields = {}
      if (this.props.dossier.customFieldsValues) {
        this.props.dossier.customFieldsValues.map(x => {
          customFields[x.key] = x.value
        })
      }
      const form = {
        ...this.props.dossier,
        customFields: customFields
      }
      this.props.initialize(form)
    }
  }

  goBack () {
    this.props.actions.setActiveTab(buttonsHeader.datos_expediente)
    this.props.history.goBack()
  }

  componentWillUnmount () {
    this.props.actions.setActiveSection(buttonsSidebar.bsale)
    this.props.actions.setScrollTo(null)
    this.props.actions.resetButtonsHeader()
    this.unregisterLeaveHook
    this.props.actions.closeAddOrEditDocumentModal()
    this.props.actions.restartSalesState()
  }

  handleToUpdateComponent (component) {
    if (component.isVehicleLoaded === false || buttonsSidebar.vehicle === component) this.setState({ isVehicleLoaded: true })
    if (component.isClientLoaded === false || buttonsSidebar.entity === component) this.setState({ isClientLoaded: true })
    if (component.isFinanceLoaded === false || buttonsSidebar.finance === component) this.setState({ isFinanceLoaded: true })
    if (component.isBrokerageLoaded === false || buttonsSidebar.brokerage === component) this.setState({ isBrokerageLoaded: true })
    if (component.isDeliveryLoaded === false || buttonsSidebar.delivery === component) this.setState({ isDeliveryLoaded: true })
    if (component.isOtherDataLoaded === false || buttonsSidebar.other_data === component) this.setState({ isOtherDataLoaded: true })
    if (component.isEconomicLoaded === false) this.setState({ isEconomicLoaded: true })
    if (component.isFleetLoaded === false || buttonsSidebar.fleet === component) this.setState({ isFleetLoaded: true })
    if (component.isSelfSupplyLoaded === false || buttonsSidebar.self_supply === component) this.setState({ isSelfSupplyLoaded: true })
    this.props.actions.setScrollTo(component)
  }

  updateComponentAfterDeleteAndRestart () {
    this.setState({
      isClientLoaded: false,
      isVehicleLoaded: false,
      isFinanceLoaded: false,
      isBrokerageLoaded: false,
      isDeliveryLoaded: false,
      isOtherDataLoaded: false,
      isEconomicLoaded: false,
      isFleetLoaded: false,
      isSelfSupplyLoaded: false
    })
    this.props.actions.setActiveSection(buttonsSidebar.bsale)
    this.props.actions.setScrollTo(null)
  }

  updateFleetSelected (fleet) {
    this.props.actions.fetchFleetComponent(fleet)
  }

  handleToUpdateSelfSupply () {
    this.setState({ isSelfSupplyLoaded: true })
  }

  togglePanel () {
    if (this.state.salesOpen) {
      this.props.actions.setActiveSection(false)
    }
    this.setState({ salesOpen: !this.state.salesOpen })
  }

  toogleHidedossierBodyLeft () {
    this.setState({ dossierBodyLeftClose: !this.state.dossierBodyLeftClose })
  }

  render () {
    const {
      t, dossier, combos = {}, change, pristine, vehicleProps, editedCampaign, uacModalForm, activeTab, buttonsHeaderHeight, activeSection,
      statusHeaderHeight, windowWidth, reset, formValues, documentExport, reason, comment, dispatch,
      dossier: {
        dossierId, dossierSubTypeId, header, entityChangeable, checkListHeader, comments, campaigns, uac, paperWorks, charges,
        dossierServiceRelateds, organizedUnitId, purchasesDossierId, dossierEconomicComponent, dossierCompleteDeliveryCheckListItems,
        dossierDeliveryCheckListPurchase, entityComponent, showAlertSendPending, viewConfiguration, dossierRelatedDossiers, auditInfo, order,
        isPreCancelled, campaignSubtype, totalAmountWithOptionals, isMarked, externalPaperwork, unreadComments, defaultDestination,
        viewConfiguration: { buttonsPermission = [], saleConfiguration },
        vehicleComponent: { vehicleId }
      },
      actions: {
        saleApprove, saleApprovePaperwork, saleReject, saleSkipCharge, saleSkipDocument, saleApproveByRole, saleApproveFleet, salePayRenting,
        saleSkipPaperwork, saleSkipDelivery, saleSendApprove, saleTransferRequest, saleTransferRequestApproved, openDocumentModal, updateDependantsSaleDossierAndHeader, putCancelDossier,
        undoCancelDossier, confirmCancelDossier, printDocumentaryChecklist, fetchOperationType, fetchFleetOperationTypeForAutoselect, postPaymentSignalStatusSuccess,
        updateLastAccessDate, setEntityTypeId, setEntitySubTypeId, setEntityId, setEntityValidationDisable, fetchClientByDni,
        fetchClientFromDocument, openDocumentEditionModal, fetchClientByDniEntityChild, searchVehicle, fetchOrder, setSearchDealerNumber,
        setSearchVinChassis, setSearchLicensePlate, setSearchMakerNumber, setSimpleInputTextValidation, resetSearchFields,
        storeDossier, updateDiscount, createDiscount, deleteDiscount, fetchDiscountModal, fetchAssociatedCostByPercentageModal, updateExtraSale, createExtraSale,
        deleteExtraSale, fetchProcessingAgencyModal, deletePresent, updatePresent, createPresent, fetchAmountTaxModal, updateOptional,
        createOptional, deleteOptional, updateComment, createComment, deleteComment, fetchUACUnitEdition, deleteUACUnit,
        fetchCampaignEdition, deleteCampaign, updateCampaign, createCampaign, updateSupplied,
        createSupplied, deleteSupplied, openCommonModal, fetchAndOpenReplacePurchaseModal, printDeliveryChecklist, refreshAuditInfo,
        setActiveTab, setButtonsHeaderHeight, resetButtonsHeader, setActiveSection, setScrollerOptions, clearVehicle, fetchReasons,
        saveOrder, fetchEntitySubTypeCombo, fetchEntityFilterSubTypeCombo, setEntityChildTypeId, fetchVoCompaniesCombo, submitSelectVoCompany,
        fetchAllDocuments, saveSelectedDocuments, clearEntity, clearEntityChild, saleApproveVoucher, fetchComboOrigin, fetchComboDestination,
        fetchSalesVehicle, fetchSalesClient, fetchSalesFinancing, fetchSalesInsuranceCarrier, removeFinance, cleanFinance, fetchSalesDelivery, openProcessingAgencyModal,
        fetchSalesOtherData, fetchSalesEconomic, updateAssociatedCosts, createAssociatedCosts, deleteAssociatedCosts,
        configureCampaign, saveconfigureCampaign, hasOptionals, searchCampaignByCode, openDocumentsToSendSign, sendDocumentToSign,
        documentsToSendSignGetAll, signTypes, skipItv, approveMargin, fetchAuditDetails, fetchAuditDetailsModal, fetchDocumentsGroupedAuditDetails, fetchDocumentsGroupedAuditDetailsModal, fetchLowestOrganizedUnits,
        getChangeUo, getSalesmanChangeUo, postUpdateChangeUo, fecthOrganizedUnitsByDossierUo, fecthFamily, printChecklistVO, markCommentAsRead,
        fetchSalesServices, fetchDossierSubTypes, saveSalesService, printCheckList, fetchDefaultEntity,
        fetchNotValidDatesService, openOrCloseServiceAddModal, fetchFleetsCombo, saveFleetCode, printDocumentaryWarranty, fetchFleet,
        fetchSalesSelfSupply, openSelfSupplyModal, putCancelDossierPurchaseSelfSupply, openChargeModalDealer, fetchFinancingType, getFinancingTypeProductByFinancingType,
        printSummarySales, printDeliveryCertificates, printDeliveryChecklistSale, clearClientFields, updateClientNoEmployee, createUpdateDossierToshiko, fetchCancelCombo,
        fetchClientByRfc, checkPrivateRfcFormat, foreignPassportWarning, checkDocTypeBefChangeCurrency, fetchEntityCountryTypeCombo, fetchEntityStateTypeCombo,
        fetchEntityCityTypeCombo, fetchEntityTownTypeCombo, fetchEntityColonyTypeCombo, fetchEntityCPTypeCombo, saleSendBproDms, fetchCommercialSocietyTypeCombo,
        fetchActivityBpro, fetchClientByPhoneEmail, requestInvoiceToDMS, requestDeliveryToDMS, requestOutboundToDMS, openCDAModal, validate, stopAsyncValidation,
        printCompleteSummarySales,
        fetchClientDataAddress, transferReject, openCodeCommissionModal, changeCodeCommission, fetchFilteredRelatedDossier
      }
    } = this.props

    if (!dossierId) return null

    const tKey = 'DOSSIER_COMPONENTS.'
    const activeStage = checkListHeader.find(stage => stage.isActive)
    const panelsPaddingTop = statusHeaderHeight + buttonsHeaderHeight - 82 // 82px es el paddingTop definido en el layout.
    const saveSalePermission = buttonsPermission.includes(buttonsPermissions.sale_save)
    const exclusiveSaleSavePermission = buttonsPermission.includes(buttonsPermissions.exclusive_sale_save)
    const hasSavePermission = saveSalePermission || exclusiveSaleSavePermission
    const printDeliveryChecklistPermission = buttonsPermission.includes(buttonsPermissions.sale_print_delivery_checklist)
    const printSummarySalePermission = setting.clientFieldValidation === clientInformation.FERPER ? true : buttonsPermission.includes(buttonsPermissions.print_summary_sale)
    const showCampaignPercentages = saleConfiguration?.showCampaignPercentages ?? false
    const showEconomicPlan = saleConfiguration?.showEconomicPlan ?? false

    return (
      <>
        <div class="dossierGrid">
          <div class="dossierGridPart-header">
            <DossierHeaderPage
              headerData={header}
              stages={checkListHeader}
              dossierType={dossierTypesConstant.sales}
              dossierSubType={dossierSubTypeId}
              dossierId={dossier.dossierId}
              activeTab={activeTab}
              showEconomicPlan={showEconomicPlan}
              headerTabConfiguration={this.state.headerTabConfiguration}
              pristine={pristine}
              goBack={this.goBack}
              readOnlyFields={isMarked}
              editableDoc={viewConfiguration ? getDocumentaryPermission(viewConfiguration) : true}
              isSelfSupplyLoaded={this.state.isSelfSupplyLoaded}
              showTimelineInTab={this.state.showTimelineInTab}
              toggleShowTimelineInTab = {() => this.toggleShowTimelineInTab()}
              timeLinePosition = {this.state.showTimelineInTab}
              handleToUpdateSelfSupply = {() => this.handleToUpdateSelfSupply()}
              entityComponent = {entityComponent}
              vehicleComponent = {dossier.vehicleComponent}
            />
          </div>
          <div class="dossierGridPart-body">

            <div className={'dossierBody-left ' + (this.state.dossierBodyLeftClose ? 'close' : '') }>

              <DossierSummary
                t = {t}
                dossier={dossier}
                header={header}
                dossierType={dossierTypesConstant.sales}
                dossierSubType={dossierSubTypeId}
                brandCombo = {combos.brandCombo}
                entityTypeCombo = {combos.entityTypeCombo}
                dossierRelatedDossiers = {dossierRelatedDossiers}
              >
              </DossierSummary>
            </div>
            <div className='dossierBody-center'>
              <OverlayTrigger placement="right" overlay={<Tooltip id="editProductTooltip">{`${t((this.state.dossierBodyLeftClose ? 'GLOBAL.TABLES.SHOW_INNER_MENU' : 'GLOBAL.TABLES.HIDE_INNER_MENU'))}`}</Tooltip>}>
                <a onClick={() => this.toogleHidedossierBodyLeft()} className={'closeDossierInfo ' + (this.state.dossierBodyLeftClose ? '' : 'closeInnerMenu')}><i className={this.state.dossierBodyLeftClose ? 'iDocIcon-Open-inner-menu' : 'iDocIcon-Close-inner-menu'}></i></a>
              </OverlayTrigger>
            </div>
            <div className='dossierBody-right'>
              {!this.state.showTimelineInTab && <div className='dossierGridPart-topTimeLine'>
                <DossierTimeLineSectionPage
                  showTitlePage={false}
                  headerData={header}
                  stages={checkListHeader}
                  dossierType={dossierTypesConstant.sales}
                  dossierSubType={dossierSubTypeId}
                  dossierId={dossier.dossierId}
                  activeTab={activeTab}
                  showEconomicPlan={showEconomicPlan}
                  headerTabConfiguration={this.state.headerTabConfiguration}
                  pristine={pristine}
                  goBack={this.goBack}
                  readOnlyFields={isMarked}
                  editableDoc={viewConfiguration ? getDocumentaryPermission(viewConfiguration) : true}
                  isSelfSupplyLoaded={this.state.isSelfSupplyLoaded}
                  handleToUpdateSelfSupply = {() => this.handleToUpdateSelfSupply()}
                />
              </div>}
              <div class="dossierGridPart-sectionsMenu">
                <ButtonsHeader
                  t={t}
                  pristine={pristine}
                  reset={reset}
                  combos={combos.purchaseTypeCombo}
                  cancelCombo={combos.cancelCombo}
                  lowestOrganizedUnitsCombo = {combos.organizedUnitsLegalCif}
                  isEconomicLoaded = {this.state.isEconomicLoaded}
                  handleToUpdateComponent = {this.handleToUpdateComponent.bind(this)}
                  historical={false}
                  changeUo = {this.props.changeUo}
                  readOnlyFields={isMarked}
                  reason={reason}
                  comment={comment}
                  type={dossierTypesConstant.sales}
                  actions={{
                    saleApprove,
                    saleApproveFleet,
                    saleApprovePaperwork,
                    saleReject: () => this.toggleRejectCommentModal(true),
                    saleSkipCharge,
                    saleSkipDocument,
                    saleSkipPaperwork,
                    saleSkipDelivery,
                    saleSendApprove,
                    saleTransferRequest,
                    saleTransferRequestApproved,
                    storeDossier,
                    saleCancel: (values) => putCancelDossier(dossierId, pristine, reset),
                    undoCancelDossier: () => undoCancelDossier(dossierId, pristine, reset),
                    confirmCancelDossier: () => confirmCancelDossier(dossierId, pristine, reset),
                    openDocumentModal: () => openDocumentModal(dossierId, dossierTypesConstant.sales, pristine),
                    fetchAndOpenReplacePurchaseModal,
                    setActiveTab,
                    setButtonsHeaderHeight,
                    resetButtonsHeader,
                    setScrollerOptions,
                    submitForm: this.handleSubmitForm.bind(this),
                    saleApproveByRole,
                    fetchVoCompaniesCombo,
                    submitSelectVoCompany,
                    saleApproveVoucher,
                    salePayRenting,
                    fetchSalesVehicle,
                    fetchSalesClient,
                    fetchSalesFinancing,
                    fetchSalesInsuranceCarrier,
                    fetchSalesDelivery,
                    configureCampaign,
                    openConfigureCampaignEdition: (campaign) => this.toggleConfigureCampaignModal(true, campaign),
                    openSendDocumentsToSignModal: (document) => this.toggleSendDocumentsModal(true, document),
                    fetchSalesOtherData,
                    fetchSalesEconomic,
                    openDocumentsToSendSign,
                    skipItv,
                    approveMargin,
                    fetchLowestOrganizedUnits,
                    getChangeUo,
                    getSalesmanChangeUo,
                    postUpdateChangeUo,
                    fecthOrganizedUnitsByDossierUo,
                    fetchFleet,
                    openChargeModalDealer,
                    createUpdateDossierToshiko,
                    putCancelDossier,
                    fetchCancelCombo: fetchCancelCombo,
                    saleSendBproDms,
                    requestInvoiceToDMS,
                    requestDeliveryToDMS,
                    requestOutboundToDMS,
                    printCompleteSummarySales,
                    transferReject: () => this.toggleRejectCommentTransfer(true)
                  }}
                  dossierId={this.props.match.params.dossierId}
                  viewConfiguration={viewConfiguration}
                  dossierType={dossierTypesConstant.sales}
                  activeTab={activeTab}
                  marginTop={statusHeaderHeight}
                  windowWidth={windowWidth}
                  showEconomicPlan={showEconomicPlan}
                  commentUnreadCount={ unreadComments || comments.filter((comment) => { return !comment.read }).length}
                  organizedUnitId={organizedUnitId}
                  vehicleCompConfig={this.state.componentsConfiguration.vehicle}
                  dossierSubTypes={dossierSubTypeId}
                  showTimelineInTab={this.state.showTimelineInTab}
                />
              </div>
              <div class="dossierGridPart-ComponentSection">
                <Form autoComplete="off">
                  {// TIME LINE
                    activeTab === buttonsHeader.time_line && (
                      <div className="dossierGridPart-ComponentSection-Inner">
                        <div className="ComponentSection-InnerData">
                          <DossierTimeLineSectionPage
                            showTitlePage={true}
                            headerData={header}
                            stages={checkListHeader}
                            dossierType={dossierTypesConstant.sales}
                            dossierSubType={dossierSubTypeId}
                            dossierId={dossier.dossierId}
                            activeTab={activeTab}
                            showEconomicPlan={showEconomicPlan}
                            headerTabConfiguration={this.state.headerTabConfiguration}
                            pristine={pristine}
                            goBack={this.goBack}
                            readOnlyFields={isMarked}
                            editableDoc={viewConfiguration ? getDocumentaryPermission(viewConfiguration) : true}
                            isSelfSupplyLoaded={this.state.isSelfSupplyLoaded}
                            handleToUpdateSelfSupply = {() => this.handleToUpdateSelfSupply()}
                          />
                        </div>
                      </div>
                    )}
                  {
                    activeTab === buttonsHeader.datos_expediente && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden && (
                      <div className="_nd dossierGridPart-ComponentSection-Inner" >
                        <div className="ComponentSection-InnerMennu">
                          <ButtonsSidebar
                            t={t}
                            historical={false}
                            handleToUpdateComponent = {this.handleToUpdateComponent.bind(this)}
                            isVehicleLoaded = {this.state.isVehicleLoaded}
                            isClientLoaded = {this.state.isClientLoaded}
                            isBrokerageLoaded = {this.state.isBrokerageLoaded}
                            isFinanceLoaded = {this.state.isFinanceLoaded}
                            isDeliveryLoaded = {this.state.isDeliveryLoaded}
                            isOtherDataLoaded = {this.state.isOtherDataLoaded}
                            isFleetLoaded = {this.state.isFleetLoaded}
                            isSelfSupplyLoaded={this.state.isSelfSupplyLoaded}
                            pristine={pristine}
                            dossierType={dossierTypesConstant.sales}
                            dossierSubTypes={dossierSubTypeId}
                            activeStage={activeStage}
                            marginTop={statusHeaderHeight}
                            buttonsHeaderHeight={buttonsHeaderHeight}
                            tabConfiguration={this.state.activeTabConfiguration}
                            dossierId={dossierId}
                            dossier={dossier}
                            actions={{
                              setActiveSection,
                              fetchSalesVehicle,
                              fetchSalesClient,
                              fetchSalesFinancing,
                              fetchSalesDelivery,
                              fetchSalesOtherData,
                              fetchSalesServices,
                              fetchFleet,
                              fetchSalesSelfSupply,
                              fetchSalesInsuranceCarrier
                            }}
                          />
                        </div>
                        <div className="ComponentSection-InnerData" id="dossierData" >

                          {this.state.componentsConfiguration.bsale && this.state.componentsConfiguration.bsale.permission > permissions.hidden && (
                            <Element style={{ zIndex: 14 }} name={buttonsSidebar.bsale} id="bsale">
                              <SalesComponent
                                t={t}
                                dossier={dossier}
                                salesOpen={this.state.salesOpen}
                                combos={combos}
                                actions={{
                                  change,
                                  fetchOperationType,
                                  fetchFleetOperationTypeForAutoselect,
                                  printDocumentaryChecklist,
                                  openDocumentEditionModal,
                                  postPaymentSignalStatusSuccess,
                                  updateLastAccessDate,
                                  setActiveTab,
                                  togglePanel: () => this.togglePanel(),
                                  setActiveSection,
                                  printSummarySales,
                                  checkDocTypeBefChangeCurrency,
                                  openCDAModal
                                }}
                                printSummarySalePermission={printSummarySalePermission}
                                readOnlyFields={!saveSalePermission || isPreCancelled || isMarked}
                                dossierType={dossierTypesConstant.sales}
                                open={activeSection === buttonsSidebar.bsale}
                                componentConfiguration={this.state.componentsConfiguration.bsale}
                                editableDoc={viewConfiguration ? getDocumentaryPermission(viewConfiguration) : true}
                                fleetSelected ={(dossier.dossierFleetComponent.fleetId !== null && dossier.dossierFleetComponent.fleetId !== undefined)}
                                disableExternalOrderId={saleConfiguration.disableExternalOrderId}
                                pristine={pristine}
                                customFields={this.props.dossier.customFieldsByComponent.find(x => x.componentCode === buttonsSidebar.bsale)?.customFields}
                              />
                            </Element>
                          )}
                          {this.state.componentsConfiguration.fleet && this.state.componentsConfiguration.fleet.permission > permissions.hidden && (
                            <Element style={{ zIndex: 13 }} name={buttonsSidebar.fleet} id="fleet">
                              <Fleet
                                t={t}
                                dossierId={dossierId}
                                pristine={pristine}
                                handleToUpdateComponent = {this.handleToUpdateComponent.bind(this)}
                                combos={combos}
                                readOnlyFields={!saveSalePermission || isPreCancelled || isMarked}
                                open={activeSection === buttonsSidebar.fleet}
                                componentConfiguration={this.state.componentsConfiguration.fleet}
                                dossier={dossier}
                                actions={{ fetchFleet, setActiveSection }}
                                updateFleetSelected ={ this.updateFleetSelected.bind(this)}
                                openErrorMessageModal = {this.props.actions.openErrorMessageModal}
                                isFleetLoaded = {this.state.isFleetLoaded}
                                change = {change}
                                formValues = {this.props.formValues}
                                customFields={this.props.dossier.customFieldsByComponent.find(x => x.componentCode === buttonsSidebar.fleet)?.customFields}
                              />
                            </Element>
                          )}
                          {this.state.componentsConfiguration.entity && this.state.componentsConfiguration.entity.permission > permissions.hidden && (
                            <Element style={{ zIndex: 12 }} name={buttonsSidebar.entity} id="entity">
                              <Client
                                t={t}
                                handleToUpdateComponent= {this.handleToUpdateComponent.bind(this)}
                                isClientLoaded = {this.state.isClientLoaded}
                                pristine={pristine}
                                dossier={dossier}
                                combos={combos}
                                entityChangeable={entityChangeable}
                                dossierType={dossierTypesConstant.sales}
                                actions={{
                                  change,
                                  fetchClientByDni,
                                  fetchClientByDniEntityChild,
                                  fetchClientFromDocument,
                                  setEntityTypeId,
                                  setEntitySubTypeId,
                                  setEntityId,
                                  fetchEntitySubTypeCombo,
                                  fetchEntityFilterSubTypeCombo,
                                  setEntityChildTypeId,
                                  clearEntity,
                                  clearEntityChild,
                                  fetchSalesClient,
                                  setEntityValidationDisable,
                                  setActiveSection,
                                  clearClientFields,
                                  fetchClientByRfc,
                                  checkPrivateRfcFormat,
                                  foreignPassportWarning,
                                  updateClientNoEmployee,
                                  fetchCommercialSocietyTypeCombo,
                                  fetchEntityCountryTypeCombo,
                                  fetchEntityStateTypeCombo,
                                  fetchEntityCityTypeCombo,
                                  fetchEntityTownTypeCombo,
                                  fetchEntityColonyTypeCombo,
                                  fetchEntityCPTypeCombo,
                                  openCommonModal,
                                  fetchClientByPhoneEmail,
                                  fetchActivityBpro,
                                  validate,
                                  dispatch,
                                  fetchClientDataAddress,
                                  asyncValidate,
                                  stopAsyncValidation: (value) => stopAsyncValidation('salesDossier', value)
                                }}
                                readOnlyFields={!saveSalePermission || isPreCancelled || isMarked || (dossier.dossierFleetComponent.fleetId !== null && dossier.dossierFleetComponent.fleetId !== undefined) || dossier.dossierFleetComponent.updated}
                                open={activeSection === buttonsSidebar.entity}
                                componentConfiguration={this.state.componentsConfiguration.entity}
                                updateComponentAfterDeleteAndRestart={this.updateComponentAfterDeleteAndRestart.bind(this)}
                                disableDeleteButton ={(dossier.dossierFleetComponent.fleetId !== null && dossier.dossierFleetComponent.fleetId !== undefined) || dossier.dossierFleetComponent.updated}
                                change={change}
                                customFields={this.props.dossier.customFieldsByComponent.find(x => x.componentCode === buttonsSidebar.entity)?.customFields}
                                formValues={formValues}
                              />
                            </Element>
                          )}
                          {this.state.componentsConfiguration.vehicle && this.state.componentsConfiguration.vehicle.permission > permissions.hidden && (
                            <Element style={{ zIndex: 11 }} name={buttonsSidebar.vehicle}>
                              <Vehicle
                                handleToUpdateComponent = {this.handleToUpdateComponent.bind(this)}
                                isVehicleLoaded = {this.state.isVehicleLoaded}
                                pristine={pristine}
                                parentDossierType={{
                                  isPaperwork: false,
                                  isSales: true,
                                  isPurchase: false,
                                  isCampaign: false,
                                  isRegistrationDelivery: false
                                }}
                                t={t}
                                dossierId={dossierId}
                                searchedVehicleProps={vehicleProps}
                                orderId={dossier.orderId}
                                order={order}
                                dossierParentId={purchasesDossierId}
                                dossierWithVehicle={!!vehicleId}
                                combos={combos}
                                actions={{
                                  searchVehicle,
                                  setSearchDealerNumber,
                                  setSearchVinChassis,
                                  setSearchLicensePlate,
                                  setSearchMakerNumber,
                                  setSimpleInputTextValidation,
                                  resetSearchFields,
                                  fetchOrder,
                                  clearVehicle,
                                  fetchComboDestination,
                                  fetchComboOrigin,
                                  fetchSalesVehicle,
                                  fecthFamily,
                                  setActiveSection
                                }}
                                readOnlyFields={!saveSalePermission || isPreCancelled || isMarked}
                                open={activeSection === buttonsSidebar.vehicle}
                                reset={reset}
                                dossierType={dossierTypesConstant.sales}
                                dossierSubType={dossierSubTypeId}
                                formValues={formValues ? formValues.vehicleComponent : {}}
                                componentConfiguration={this.state.componentsConfiguration.vehicle}
                                organizedUnitId={dossier.organizedUnitId}
                                purchaseOrderExetendedMode={saleConfiguration && dossierSubTypeId === dossierSubTypeIdConstants.venta_vn ? saleConfiguration.purchaseOrderExetendedMode : null}
                                codeVehicleConfiguration={saleConfiguration ? saleConfiguration.showCodecombos : false}
                                comercialCode={this.props.dossier.vehicleComponent.comercialCode}
                                customFields={this.props.dossier.customFieldsByComponent.find(x => x.componentCode === buttonsSidebar.vehicle)?.customFields}
                              />
                            </Element>
                          )}
                          { this.state.componentsConfiguration.self_supply && this.state.componentsConfiguration.self_supply.permission > permissions.hidden && (
                            <Element style={{ zIndex: 10 }} name={buttonsSidebar.self_supply}>
                              <SelfSupply
                                t={t}
                                actions={ { fetchSalesSelfSupply, openSelfSupplyModal, putCancelDossierPurchaseSelfSupply, change, setActiveSection }}
                                combos={combos}
                                readOnlyFields={!hasSavePermission || isPreCancelled || isMarked}
                                handleToUpdateComponent={this.handleToUpdateComponent.bind(this)}
                                isSelfSupplyLoaded={this.state.isSelfSupplyLoaded}
                                open={activeSection === buttonsSidebar.self_supply}
                                componentConfiguration={this.state.componentsConfiguration.self_supply}
                                dossier={dossier}
                                pristine={pristine}
                                historical={false}
                                reset={reset}
                                openErrorMessageModal = {this.props.actions.openErrorMessageModal}
                                customFields={this.props.dossier.customFieldsByComponent.find(x => x.componentCode === buttonsSidebar.self_supply)?.customFields}
                              />
                            </Element>
                          )}
                          {this.state.componentsConfiguration.campaign && this.state.componentsConfiguration.campaign.permission > permissions.hidden && (
                            <Element style={{ zIndex: 9 }} name={buttonsSidebar.campaign}>
                              <Campaigns
                                t={t}
                                campaigns={campaigns}
                                actions={{
                                  openCampaignEdition: (campaign) => this.toggleCampaignModal(true, campaign),
                                  openSearchDocumentsModal: () => this.toggleCampaignSearchDocumentsModal(true),
                                  fetchCampaignEdition,
                                  deleteCampaign,
                                  setActiveSection
                                }}
                                readOnlyFields={!saveSalePermission || isPreCancelled || isMarked}
                                open={activeSection === buttonsSidebar.campaign}
                                stageId = {activeStage ? activeStage.stageId : null}
                                componentConfiguration={this.state.componentsConfiguration.campaign}
                                dossier={dossier}
                                customFields={this.props.dossier.customFieldsByComponent.find(x => x.componentCode === buttonsSidebar.campaign)?.customFields}
                              />
                            </Element>
                          )}
                          {this.state.componentsConfiguration.uac && this.state.componentsConfiguration.uac.permission > permissions.hidden && (
                            <Element style={{ zIndex: 8 }} name={buttonsSidebar.uac}>
                              <UAC
                                t={t}
                                uac={uac}
                                actions={{
                                  openUACEdition: (UACUnit) => this.toggleUACModal(true, UACUnit),
                                  fetchUACUnitEdition,
                                  deleteUACUnit,
                                  setActiveSection
                                }}
                                readOnlyFields={!saveSalePermission || isPreCancelled || isMarked}
                                open={activeSection === buttonsSidebar.uac}
                                componentConfiguration={this.state.componentsConfiguration.uac}
                                dossierType={ dossierTypesConstant.sales }
                                customFields={this.props.dossier.customFieldsByComponent.find(x => x.componentCode === buttonsSidebar.uac)?.customFields}
                              />
                            </Element>
                          )}
                          {this.state.componentsConfiguration.brokerage && this.state.componentsConfiguration.brokerage.permission > permissions.hidden && (
                            <Element style={{ zIndex: 7 }} name={buttonsSidebar.brokerage}>
                              <Brokerage
                                t={t}
                                pristine={pristine}
                                handleToUpdateComponent = {this.handleToUpdateComponent.bind(this)}
                                isBrokerageLoaded = {this.state.isBrokerageLoaded}
                                historical={false}
                                dossierId={dossierId}
                                combos={combos}
                                readOnlyFields={!saveSalePermission || isPreCancelled || isMarked || this.state.componentsConfiguration.brokerage.permission === permissions.readOnly}
                                open={activeSection === buttonsSidebar.brokerage}
                                componentConfiguration={this.state.componentsConfiguration.brokerage}
                                dossier={dossier}
                                actions={{
                                  change,
                                  fetchSalesInsuranceCarrier,
                                  openCommonModal,
                                  setActiveSection
                                }}
                                customFields={this.props.dossier.customFieldsByComponent.find(x => x.componentCode === buttonsSidebar.brokerage)?.customFields}
                              />
                            </Element>
                          )}
                          {this.state.componentsConfiguration.finance && this.state.componentsConfiguration.finance.permission > permissions.hidden && (
                            <Element style={{ zIndex: 6 }} name={buttonsSidebar.finance}>
                              <Finance
                                t={t}
                                pristine={pristine}
                                handleToUpdateComponent = {this.handleToUpdateComponent.bind(this)}
                                isFinanceLoaded = {this.state.isFinanceLoaded}
                                historical={false}
                                dossierId={dossierId}
                                combos={combos}
                                readOnlyFields={!saveSalePermission || isPreCancelled || isMarked}
                                open={activeSection === buttonsSidebar.finance}
                                componentConfiguration={this.state.componentsConfiguration.finance}
                                dossier={dossier}
                                financingTypeProduct = {this.props.financingTypeProduct}
                                redate = {this.props.dossier && this.props.dossier.dossierFinancingComponent ? this.props.dossier.dossierFinancingComponent.expectedRepurchaseDate : null}
                                actions={{
                                  change,
                                  fetchSalesFinancing,
                                  removeFinance,
                                  cleanFinance,
                                  fetchFinancingType,
                                  getFinancingTypeProductByFinancingType,
                                  openCommonModal,
                                  setActiveSection
                                }}
                                customFields={this.props.dossier.customFieldsByComponent.find(x => x.componentCode === buttonsSidebar.finance)?.customFields}
                                showEconomicPlan={showEconomicPlan}
                              />
                            </Element>
                          )}
                          {this.state.componentsConfiguration.paperwork && this.state.componentsConfiguration.paperwork.permission > permissions.hidden && (
                            <Element style={{ zIndex: 5 }} name={buttonsSidebar.paperwork}>
                              <PaperworkTablePage
                                organizedUnitId={organizedUnitId}
                                dossierParentId={dossierId}
                                updateDossierAction={updateDependantsSaleDossierAndHeader}
                                showAlertSendPending={showAlertSendPending}
                                paperwork={paperWorks}
                                readOnlyFields={!saveSalePermission || isPreCancelled || isMarked}
                                dossierType={dossierTypesConstant.sales}
                                open={activeSection === buttonsSidebar.paperwork}
                                showEconomicPlan={showEconomicPlan}
                                componentConfiguration={this.state.componentsConfiguration.paperwork}
                                externalPaperwork = {externalPaperwork}
                                customFields={this.props.dossier.customFieldsByComponent.find(x => x.componentCode === buttonsSidebar.paperwork)?.customFields}
                              />
                            </Element>
                          )}
                          {this.state.componentsConfiguration.charge && this.state.componentsConfiguration.charge.permission > permissions.hidden && (
                            <Element style={{ zIndex: 4 }} name={buttonsSidebar.charge} id="charge">
                              <Charges
                                t={t}
                                dossier={dossier}
                                charges={charges}
                                actions={{
                                  openDocumentEditionModal,
                                  updateDependantsSaleDossierAndHeader,
                                  fetchSalesEconomic,
                                  printCheckList,
                                  postPaymentSignalStatusSuccess,
                                  setActiveSection
                                }}
                                dossierType={dossierTypesConstant.sales}
                                readOnlyFields={!saveSalePermission || isMarked}
                                isPreCancelled={isPreCancelled}
                                open={activeSection === buttonsSidebar.charge}
                                componentConfiguration={this.state.componentsConfiguration.charge}
                                historical = {false}
                                isEconomicLoaded = {this.state.isEconomicLoaded}
                                handleToUpdateComponent = {this.handleToUpdateComponent.bind(this)}
                                pristine={pristine}
                                customFields={this.props.dossier.customFieldsByComponent.find(x => x.componentCode === buttonsSidebar.charge)?.customFields}
                                fleetId = {dossier.dossierFleetComponent?.fleetId}
                              />
                            </Element>
                          )}
                          {this.state.componentsConfiguration.services && this.state.componentsConfiguration.services.permission > permissions.hidden && (
                            <Element style={{ zIndex: 3 }} name={buttonsSidebar.services}>
                              <Services
                                t={t}
                                dossier={dossier}
                                combos={combos}
                                showAddModal={dossierServiceRelateds.showAddModal}
                                datesNotValid={dossierServiceRelateds.datesNotValid}
                                services={dossierServiceRelateds.rows}
                                subTypesAllowedToCreate={dossierServiceRelateds.subTypesToCreate}
                                canAdd={dossierServiceRelateds.canAdd}
                                dossierType={dossierTypesConstant.sales}
                                readOnlyFields={!saveSalePermission || isMarked}
                                isPreCancelled={isPreCancelled}
                                open={activeSection === buttonsSidebar.services}
                                componentConfiguration={this.state.componentsConfiguration.services}
                                historical = {false}
                                handleToUpdateComponent = {this.handleToUpdateComponent.bind(this)}
                                pristine={pristine}
                                actions={{
                                  fetchSalesServices,
                                  fetchDossierSubTypes,
                                  fetchEntitySubTypeCombo,
                                  fetchEntityFilterSubTypeCombo,
                                  saveSalesService,
                                  fetchDefaultEntity,
                                  fetchNotValidDatesService,
                                  openOrCloseServiceAddModal,
                                  setActiveSection
                                }}
                                defaultDestination={defaultDestination}
                                customFields={this.props.dossier.customFieldsByComponent.find(x => x.componentCode === buttonsSidebar.services)?.customFields}
                              />
                            </Element>
                          )}
                          {this.state.componentsConfiguration.other_data && this.state.componentsConfiguration.other_data.permission > permissions.hidden && (
                            <Element style={{ zIndex: 2 }} name={buttonsSidebar.other_data}>
                              <OtherData
                                t={t}
                                actions={{ fetchSalesOtherData, setActiveSection }}
                                dossierId={dossierId}
                                pristine={pristine}
                                handleToUpdateComponent = {this.handleToUpdateComponent.bind(this)}
                                isOtherDataLoaded = {this.state.isOtherDataLoaded}
                                dossierType={dossierTypesConstant.sales}
                                combos={combos}
                                readOnlyFields={!saveSalePermission || isPreCancelled || isMarked}
                                showEconomicPlan={showEconomicPlan}
                                open={activeSection === buttonsSidebar.other_data}
                                componentConfiguration={this.state.componentsConfiguration.other_data}
                                customFields={this.props.dossier.customFieldsByComponent.find(x => x.componentCode === buttonsSidebar.other_data)?.customFields}
                              />
                            </Element>
                          )}
                          {this.state.componentsConfiguration.delivery && this.state.componentsConfiguration.delivery.permission > permissions.hidden && (
                            <Element style={{ zIndex: 1 }} name={buttonsSidebar.delivery}>
                              <Delivery
                                t={t}
                                handleToUpdateComponent = {this.handleToUpdateComponent.bind(this)}
                                isDeliveryLoaded = {this.state.isDeliveryLoaded}
                                dossier={dossier}
                                pristine={pristine}
                                isSalesDossier={true}
                                dossierId={dossierId}
                                dossierCompleteDeliveryCheckListItems={dossierCompleteDeliveryCheckListItems}
                                dossierDeliveryCheckListPurchase={dossierDeliveryCheckListPurchase}
                                actions={{
                                  fetchSalesDelivery,
                                  printDeliveryChecklist: (dossierId, isSalesDossier) => {
                                    new Promise((resolve, reject) => {
                                      if (!this.props.pristine) this.handleSubmitForm(resolve, reject)
                                      else resolve()
                                    }).then(() => {
                                      printDeliveryChecklist(dossierId, isSalesDossier)
                                    })
                                  },
                                  printChecklistVO,
                                  setActiveSection,
                                  printDeliveryCertificates,
                                  printDeliveryChecklistSale
                                }}
                                readOnlyFields={!saveSalePermission || isPreCancelled || isMarked}
                                open={activeSection === buttonsSidebar.delivery}
                                dossierSubTypeId={dossierSubTypeId}
                                printDeliveryChecklistPermission={printDeliveryChecklistPermission}
                                componentConfiguration={this.state.componentsConfiguration.delivery}
                                combos={combos}
                                customFields={this.props.dossier.customFieldsByComponent.find(x => x.componentCode === buttonsSidebar.delivery)?.customFields}
                              />
                            </Element>
                          )}

                        </div>
                      </div>
                    )}
                  {
                    activeTab === buttonsHeader.gestor_documental && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden && (
                      <div className="dossierGridPart-ComponentSection-Inner sales-panel gestorDocumental">
                        <div className="ComponentSection-InnerData">
                          <DocumentaryManagementPage
                            dossier={dossier}
                            dossierType={dossierTypesConstant.sales}
                            formState={this.props.pristine}
                            tabConfiguration={this.state.activeTabConfiguration}
                            configuration={this.state.activeTabConfiguration}
                            readOnlyFields={!saveSalePermission || isPreCancelled || isMarked}
                            open={activeSection === buttonsSidebar.fleet}
                            propsComponent={this.props}
                            formName={'salesDossier'}
                          />
                        </div>
                      </div>
                    )}
                  {
                    activeTab === buttonsHeader.planteo_economico && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden && (
                      <div className="dossierGridPart-ComponentSection-Inner">
                        <EconomicPlan
                          t={t}
                          pristine={pristine}
                          activeTab={activeTab}
                          dossierEconomicComponent={dossierEconomicComponent}
                          actions={{
                            updateDiscount,
                            createDiscount,
                            deleteDiscount,
                            updateOptional,
                            createOptional,
                            deleteOptional,
                            updateComment,
                            createComment,
                            deleteComment,
                            fetchCampaignEdition,
                            deleteCampaign,
                            updateCampaign,
                            createCampaign,
                            updateSupplied,
                            createSupplied,
                            deleteSupplied,
                            updateExtraSale,
                            createExtraSale,
                            deleteExtraSale,
                            deletePresent,
                            updatePresent,
                            createPresent,
                            setActiveSection,
                            openProcessingAgencyModal,
                            change,
                            openCommonModal,
                            fetchSalesEconomic,
                            updateAssociatedCosts,
                            createAssociatedCosts,
                            deleteAssociatedCosts,
                            fetchDiscountModal,
                            fetchAssociatedCostByPercentageModal,
                            fetchProcessingAgencyModal,
                            fetchAmountTaxModal,
                            printDocumentaryWarranty,
                            openCodeCommissionModal,
                            changeCodeCommission
                          }}
                          readOnlyFields={!saveSalePermission || isPreCancelled || isMarked}
                          economicTab={true}
                          dossierType={dossierTypesConstant.sales}
                          activeStage={activeStage}
                          combos={combos}
                          panelsPaddingTop={panelsPaddingTop}
                          tabConfiguration={this.state.activeTabConfiguration}
                          tabConfiguration2={dossier.viewConfiguration.tabsConfiguration}
                          historical = {false}
                          isEconomicLoaded = {this.state.isEconomicLoaded}
                          handleToUpdateComponent = {this.handleToUpdateComponent.bind(this)}
                          dossierId={dossierId}
                          activeSection={activeSection}
                          dossierSubTypeId={dossierSubTypeId}
                          numberOfDecimals = {saleConfiguration?.numberOfDecimals}
                          organizedUnitId={organizedUnitId}
                        />
                      </div>
                    )}
                  {
                    activeTab === buttonsHeader.expedientes_relacionados && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden && (
                      <div className="dossierGridPart-ComponentSection-Inner related-dossiers-table">
                        <div className="ComponentSection-InnerData">
                          <RelatedDossiers
                            t={t}
                            relatedDossiersOrderBy = {this.props.relatedDossiersOrderBy}
                            panelsPaddingTop = {panelsPaddingTop}
                            history = {this.props.history}
                            dossierRelatedDossiers = {dossierRelatedDossiers}
                            dossierId= {dossierId}
                            actions={{ fetchFilteredRelatedDossier }}
                          />
                        </div>
                      </div>
                    )}
                  {
                    activeTab === buttonsHeader.actividad_expediente && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden && (
                      <div className="dossierGridPart-ComponentSection-Inner auditInfo">
                        <div sm={12} className="ComponentSection-InnerData">
                          <AuditInfo
                            auditInfo={auditInfo}
                            dossierType={dossierTypesConstant.sales}
                            actions={{ refreshAuditInfo, fetchAuditDetails, fetchAuditDetailsModal, fetchDocumentsGroupedAuditDetails, fetchDocumentsGroupedAuditDetailsModal }}
                            dossier={dossier}
                            historical={false}
                            t={t}
                          />
                        </div>
                      </div>
                    )}
                  {
                    activeTab === buttonsHeader.observaciones && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden && (
                      <div className="dossierGridPart-ComponentSection-Inner commentComponent">
                        <div className="ComponentSection-InnerData">
                          <Col sm={6} className="col-row hide">
                            <h4><i className="ico-bocadillo" onClick={() => { this.setState({ modalOpen: !this.state.modalOpen }) }}/> {t(`${tKey}COMMENTS.TITLE_PANEL`)} titulo</h4>
                          </Col>

                          <CommentComponent
                            comments={comments}
                            actions={{ updateComment, createComment, deleteComment, markCommentAsRead }}
                            readOnlyFields={!saveSalePermission || isMarked}
                            dossierType="salesDossier"
                            t={t}
                            tKey={tKey}
                            combos={{ allReasonCombo: this.props.combos.allReasonCombo }}
                            openComponent={activeTab === buttonsHeader.observaciones}
                            dossierId={this.props.dossier.dossierId}
                          />
                          <SmallLoaderPage/>
                        </div>
                      </div>
                    )}
                </Form>
              </div>
            </div>
          </div>
          <div className='modalsContainer'>
            <RejectCommentModal
              t={t}
              showModal={this.state.showRejectCommentModal}
              reasonCombo={this.props.combos.reasonCombo}
              dossierSubTypeId={dossierSubTypeId}
              actions={{
                rejectAction: (comment, resolve, reject) => saleReject(comment, resolve, reject, pristine),
                closeModal: () => this.toggleRejectCommentModal(false),
                fetchReasons: fetchReasons
              }}
            />
            <RejectCommentModal
              t={t}
              showModal={this.state.showRejectCommentModalTransfer}
              reasonCombo={[]}
              dossierSubTypeId={dossierSubTypeId}
              actions={{
                rejectAction: (comment, resolve, reject) => transferReject(comment, resolve, reject, pristine),
                closeModal: () => this.toggleRejectCommentTransfer(false),
                fetchReasons: null
              }}
            />
            <ReassignReplacePurchaseModalPage
              showCreatePurchaseButton={buttonsPermission.includes('sale_create_purch')}
              isVo={dossierSubTypeId === dossierSubTypeIdConstants.venta_vo}
              organizedUnitId={dossier.organizedUnitId}
              makerNumber={this.props && this.props.dossier && this.props.dossier.order && this.props.dossier.order.makerNumber}
              openCommonModal={openCommonModal}
              purchaseTypeId={this.props && this.props.dossier && this.props.dossier.order && this.props.dossier.order.purchaseTypeId}
              componentConfiguration={this.state.reassignReplacePurchaseModalConfig}
            />
            <DocumentaryManagementModalPage
              dossier={dossier}
              dossierType={dossierTypesConstant.sales}
              readOnlyFields={isMarked}
            />
            <UACModalPage
              t={t}
              showModal={this.state.showUACModal}
              uacUnit={this.state.UACSelected}
              combos={combos}
              dossier={dossier}
              organizedUnitId={organizedUnitId}
              saleClientDniCif={entityComponent.dniCif}
              uacModalForm={uacModalForm}
              componentConfiguration={this.state.componentsConfiguration.uac}
              dossierType = { dossierTypesConstant.sales}
              formValues={formValues}
            />
            <CampaignsModal
              t={t}
              editedCampaign={editedCampaign}
              showModal={this.state.showCampaignModal}
              campaignSelected={this.state.campaignSelected}
              vehicleAmount={totalAmountWithOptionals}
              combos={combos}
              componentConfiguration={this.state.componentsConfiguration.campaign}
              actions={{
                closeModal: () => this.toggleCampaignModal(false),
                updateCampaign,
                createCampaign
              }}
              showPercentages = {showCampaignPercentages && showEconomicPlan}
              secondClass = 'campaignModal'
            />
            <CampaignsConfigureModal
              t={t}
              campaignSubtype={campaignSubtype}
              dossierId={this.props.match.params.dossierId}
              showModal={this.state.showCampaignModalConfigure}
              componentConfiguration={this.state.componentsConfiguration.campaign}
              campaigns={campaigns}
              actions={{
                closeModal: () => this.toggleConfigureCampaignModal(false),
                saveconfigureCampaign,
                hasOptionals,
                searchCampaignByCode
              }}
            />
            <CampaignSearchDocumentsModal
              t={t}
              showModal={this.state.showCampaignSearchDocumentsModal}
              combos={combos}
              entityId = {dossier.entityComponent.entityId}
              vehicleId = {dossier.vehicleComponent.vehicleId}
              dossierType={dossierTypesConstant.sales}
              dossierId={dossier.dossierId}
              documents={documentExport}
              showOnlyValidateDocumentsCheck={true}
              actions={{
                closeModal: () => this.toggleCampaignSearchDocumentsModal(false),
                saveSelectedDocuments,
                fetchAllDocuments
              }}
            />
            <SendDocumentsSignModal
              t={t}
              dossierNumber={dossier.header.number}
              dossierId={dossier.dossierId}
              showModal={this.state.showSendDocumentsModal}
              actions={{
                closeModal: () => this.toggleSendDocumentsModal(false),
                sendDocumentToSign,
                documentsToSendSignGetAll,
                signTypes
              }}
            />
            {saleConfiguration && !isMarked && saleConfiguration.showPurchaseOrderCompleteDataForm && dossierSubTypeId === dossierSubTypeIdConstants.venta_vn && (
              <SaleRequestModal
                t={t}
                combos={combos}
                showSaleRequestModal={this.state.showSaleRequestModal}
                dossierId={dossier.dossierId}
                order={order}
                actions={{
                  closeSaleRequestModal: () => this.toggleSaleRequestModal(false),
                  saveOrder
                }}
                dossierType = { dossierTypesConstant.sales }
              />)}
            {saleConfiguration && saleConfiguration.showFleetsModalSelection && (
              <SaleFleetModal
                t={t}
                fleetsCombo={combos.fleetsCombo}
                showFleetsModalSelection={this.state.showFleetsModalSelection}
                dossierId={dossier.dossierId}
                actions={{
                  closeSaleFleetModal: () => this.toggleSaleFleetModal(false),
                  fetchFleetsCombo,
                  saveFleetCode
                }}
                dossierType = { dossierTypesConstant.sales }
              />)}
            <SelfSupplyFinderModalPage dossier={dossier}></SelfSupplyFinderModalPage>
            <ChargeQuiterModalPage resetSale={this.componentDidMount.bind(this)}></ChargeQuiterModalPage>
            <DocumentDataCDAModalPage></DocumentDataCDAModalPage>
          </div>
        </div>

        <div className="dossier-sales-container hide">
          <Col sm={12} className="add-dossier-wrapper dossier deLaV2">
            <StatusHeaderPage
              headerData={header}
              stages={checkListHeader}
              dossierType={dossierTypesConstant.sales}
              dossierSubType={dossierSubTypeId}
              dossierId={dossier.dossierId}
              activeTab={activeTab}
              showEconomicPlan={showEconomicPlan}
              headerTabConfiguration={this.state.headerTabConfiguration}
              pristine={pristine}
              goBack={this.goBack}
              readOnlyFields={isMarked}
              editableDoc={viewConfiguration ? getDocumentaryPermission(viewConfiguration) : true}
              isSelfSupplyLoaded={this.state.isSelfSupplyLoaded}
              handleToUpdateSelfSupply = {() => this.handleToUpdateSelfSupply()}
            />
          </Col>
        </div>
      </>
    )
  }
}

const validate = (values, props) => {
  const error = {}
  if (values.salesComponent) {
    error.salesComponent = {}
    if (values.salesComponent.repurchaseDate && values.salesComponent.orderDate) {
      const initDate = values.salesComponent.orderDate.split('/')
      const endDate = values.salesComponent.repurchaseDate.split('/')
      if (
        (initDate[2] > endDate[2]) ||
        (initDate[2] === endDate[2] && initDate[1] > endDate[1]) ||
        (initDate[2] === endDate[2] && initDate[1] === endDate[1] && initDate[0] > endDate[0])
      ) {
        error.salesComponent.repurchaseDate = props.t('DOSSIER_COMPONENTS.VALIDATIONS.REPURCHASE_VALIDATION')
      }
    }
    if (!values.salesComponent.salesmanId && !values.salesComponent.salesmanIdManager) {
      error.salesComponent.salesmanId = props.t('DOSSIER_COMPONENTS.VALIDATIONS.SALESMAN_REQUIRED')
    }

    if (!values.salesComponent.rentalOrderNumber && isSvnInQuadisWithFleetsAndRenting(props)) {
      error.salesComponent.rentalOrderNumber = props.t('DOSSIER_COMPONENTS.VALIDATIONS.MANDATORY_FIELD')
    }
    if (!values.salesComponent.fleetCode && isSvnWithFleets(props) && isVisibleFleetCode(props)) {
      error.salesComponent.fleetCode = props.t('DOSSIER_COMPONENTS.VALIDATIONS.MANDATORY_FIELD')
    }
    if (checkCurrencyValidation(values, props)) {
      error.salesComponent.currencyId = props.t('DOSSIER_COMPONENTS.VALIDATIONS.MANDATORY_FIELD')
    }
    if (setting.clientFieldValidation === clientInformation.BUSS && !values.salesComponent.operationTypeDMSId) {
      error.salesComponent.operationTypeDMSId = props.t('DOSSIER_COMPONENTS.VALIDATIONS.MANDATORY_FIELD')
    }
  }
  if (values.entityComponent && values.entityComponent.updated && !isEntityEmpty(values.entityComponent, props.registeredFields)) {
    error.entityComponent = validateEntity(values.entityComponent, props)
    const contactHasData = values.dossierContactComponent ? !isContactEmpty(values.dossierContactComponent, props.registeredFields) : false
    const entityChildHasData = values.entityChildComponent ? !isEntityChildEmpty(values.entityChildComponent, props.registeredFields) : false
    var isRequiredEntityData = false

    if (setting.clientFieldValidation === clientInformation.VGR) {
      isRequiredEntityData = isRequiredEndCustomerAndContactVGR(props, values)
    }

    if (isRequiredEntityData || entityChildHasData || values.salesComponent.clientNotEmployeeCheck) {
      error.entityChildComponent = validateEntity(values.entityChildComponent, props, null, true)
    }

    if (isRequiredEntityData || contactHasData) {
      error.dossierContactComponent = validateContact(values.dossierContactComponent, props)
    }

    const hasEntityChildErrors = error.entityChildComponent ? Object.keys(error.entityChildComponent).length > 0 : false
    const hasContactErrors = error.dossierContactComponent ? Object.keys(error.dossierContactComponent).length > 0 : false

    if (isRequiredEntityData && (entityChildHasData || contactHasData)) {
      if (hasEntityChildErrors && !entityChildHasData && contactHasData) {
        error.entityChildComponent = {}
      } else if (hasContactErrors && !contactHasData && entityChildHasData) {
        error.dossierContactComponent = {}
      }
    }
  }

  if (values.dossierInsuranceCarrierComponent) {
    if (values.dossierInsuranceCarrierComponent.contact === false &&
      !values.dossierInsuranceCarrierComponent.noContactReason) {
      error.dossierInsuranceCarrierComponent = {}
      error.dossierInsuranceCarrierComponent.noContactReason = props.t('DOSSIER_COMPONENTS.VALIDATIONS.MANDATORY_FIELD')
    }
  }

  let dossierFinancingComponent = validateFinancingModal(values, props)
  if (Object.keys(dossierFinancingComponent).length > 0) {
    error.dossierFinancingComponent = dossierFinancingComponent
  }

  if (values.dossierCompleteDeliveryComponent && values.dossierCompleteDeliveryComponent.dossierDeliveryComponent) {
    let dossierDeliveryComponent = {}
    dossierDeliveryComponent = validateDelivery(values.dossierCompleteDeliveryComponent.dossierDeliveryComponent, props, props.dossier.dossierSubTypeId)
    if (Object.keys(dossierDeliveryComponent).length > 0) {
      error.dossierCompleteDeliveryComponent = { dossierDeliveryComponent }
    }
  }

  error.dossierEconomicComponent = validateDossierEconomic(values.dossierEconomicComponent, props.t)

  if (values.dossierSelfSupplyComponent && values.dossierSelfSupplyComponent.updated && Object.keys(values.dossierSelfSupplyComponent).length > 1) {
    const saleTypeId = props.values.salesComponent ? props.values.salesComponent.saleTypeId : null
    const saleTypeCode = saleTypeId ? props.combos.saleTypeCombo.find((value) => { return value.id === saleTypeId })?.code : null
    if (saleTypeCode && (saleTypeCode === saleTypeCodeEnum.SELFSUPPLY_VN || saleTypeCode === saleTypeCodeEnum.SELFSUPPLY_VO)) {
      const selfSupply = values.dossierSelfSupplyComponent
      let selfSupplyErrors = {}
      if (!selfSupply.departmentId) {
        selfSupplyErrors.departmentId = props.t('DOSSIER_COMPONENTS.VALIDATIONS.DEPARTMENT_MANDATORY')
      }
      if (!selfSupply.organizedUnitId) {
        selfSupplyErrors.organizedUnitId = props.t('DOSSIER_COMPONENTS.VALIDATIONS.CONCESSIONAIRE_MANDATORY')
      }
      if (Object.keys(selfSupplyErrors).length > 0) {
        error.dossierSelfSupplyComponent = selfSupplyErrors
      }
    }
  }

  error.customFields = {}
  if (values.customFields && Object.keys(values.customFields).length > 0 && props.dossier.customFieldsByComponent) {
    error.customFields = {}
    let resultCustomFields = {}
    let allCustomFields = []
    props.dossier.customFieldsByComponent.map(x => x.customFields).map(x => {
      allCustomFields = allCustomFields.concat(x)
    })
    Object.keys(values.customFields).forEach(key => {
      const foundCustomField = allCustomFields.find(x => x.code === key)
      if (foundCustomField && foundCustomField.validationFunction) {
        if (foundCustomField.validationFunction && foundCustomField.validationFunction.indexOf('regEx-') !== -1) {
          resultCustomFields = validateRegex(foundCustomField.validationFunction, foundCustomField.customFieldLocales[0].name, values.customFields[key], props)
        } else {
          resultCustomFields = customValidations(foundCustomField.validationFunction, values.customFields[key], props)
        }
        error.customFields[key] = resultCustomFields === Object(resultCustomFields) ? undefined : resultCustomFields
      }
    })
  }

  return error
}

const asyncValidate = (values, dispatch, props, field, returnError) => {
  let promises = []
  if (values.entityComponent && values.entityComponent.updated) {
    promises = promises.concat(asyncValidateEntityDni(values.entityComponent, dispatch, props, props.dossier?.organizedUnitId))
    promises = promises.concat(asyncValidateEntityDni(values.entityChildComponent, dispatch, props, props.dossier?.organizedUnitId))
  }

  return Promise.allSettled(promises).then(result => {
    let error = {}
    for (let index = 0; index < result.length; index++) {
      const element = result[index]
      if (element.status === promisesStatus.FULFILLED) {
        switch (index) {
          case validationSalesPromisesOrder.ENTITY_COMPONENT:
            error = { ...error, entityComponent: element.value }
            break
          case validationSalesPromisesOrder.ENTITY_CHILD_COMPONENT:
            error = { ...error, entityChildComponent: element.value }
            break
          default:
            break
        }
      }
    }
    if (Object.keys(error).length > 0 && !returnError) throw Object.assign(new Error(), error)
    if (returnError) return error
  })
}

var decoratedComponent = TrackingContainer(Sales, false)

export default reduxForm({
  form: 'salesDossier',
  validate,
  asyncValidate,
  asyncBlurFields: [...getFieldsValidated('entityComponent'), ...getFieldsValidated('entityChildComponent')]
})(decoratedComponent)
