import deleteRatingService from '../../../services/rating/deleteRating'
import postRating from '../../../services/rating/postRating'
import postRatingList from '../../../services/rating/postRatingList'
import putRating from '../../../services/rating/putRating'
import { fetchRatingListSuccess, openRatingModalSuccess, closeRatingMasterModal } from '../../../actions/masters/rating/rating'
import { getAuth } from '../../../selectors/access/auth'
import { getRatingFilter } from '../../../selectors/masters/rating'
import { handleError } from '../../errors/errorManager'
import { put, call, select, takeEvery } from 'redux-saga/effects'
import { showLoader, hideLoader } from '../../../actions/common.js'
import { yesNoModal } from '../../modalsListeners/yesNoModal'
import mastersActionTypes from '../../../constants/actions/masters/masters'
import { setChangeTabFromConfMasterSuccess } from '../../../actions/masters/masters'

export function * deleteRating (ratingId) {
  try {
    const confirmed = yield call(yesNoModal, 'deleteRating')
    if (confirmed) {
      yield put(showLoader())
      const auth = yield select(getAuth)
      const filter = yield filter || select(getRatingFilter)
      yield call(deleteRatingService, auth.token, ratingId)
      yield call(fetchRatingList, filter)
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDeleteRating () {
  yield takeEvery(mastersActionTypes.DELETE_RATING, deleteRating)
}

export function * submitRating ({ rating }) {
  try {
    const auth = yield select(getAuth)
    const filter = yield filter || select(getRatingFilter)
    if (!rating.ratingId) {
      yield put(showLoader())
      yield call(postRating, auth.token, rating)
    } else {
      yield put(showLoader())
      yield call(putRating, auth.token, rating)
    }

    yield put(closeRatingMasterModal())
    yield call(fetchRatingList, filter)
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSubmitRating () {
  yield takeEvery(mastersActionTypes.SUBMIT_RATING, submitRating)
}

export function * fetchRatingList ({ filter }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield filter = yield filter || select(getRatingFilter)
    const ratingList = yield call(postRatingList, auth.token, filter)
    yield put(fetchRatingListSuccess(ratingList, filter))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchRatingList () {
  yield takeEvery(mastersActionTypes.FETCH_RATING_LIST, fetchRatingList)
}

export function * openRatingModal ({ rating }) {
  try {
    yield put(showLoader())
    yield put(openRatingModalSuccess(rating))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchOpenRatingModal () {
  yield takeEvery(mastersActionTypes.OPEN_RATING_MODAL, openRatingModal)
}

export function * setChangeTabFromConfMaster ({ ouIdFromMaster, changeTabFromMaster }) {
  yield put(setChangeTabFromConfMasterSuccess(ouIdFromMaster, changeTabFromMaster))
}

export function * watchSetChangeTabFromConfMaster () {
  yield takeEvery(mastersActionTypes.SET_CHANGE_TAB_FROM_CONF_MASTER, setChangeTabFromConfMaster)
}
