import apiFetch from '../apiFetch'

export default function (token) {
  return apiFetch({
    endPoint: 'CampaignDetails/ExternalCode',
    method: 'GET',
    body: null,
    token: token
  })
}
