import downloadActionTypes from '../../constants/actions/search/download'

export function initialState () {
  return {
    download: null,
    downloadId: null,
    downloadModal: {
      download: null,
      downloadId: null,
      showModal: false
    }
  }
}

function openDownloadModalSuccess (state, { download, downloadId }) {
  const initial = initialState()
  return {
    ...state,
    downloadId,
    downloadModal: {
      ...initial.downloadModal,
      download: download,
      downloadId: downloadId,
      showModal: true
    }
  }
}

export function closeDownloadModal (state) {
  return {
    ...state,
    downloadModal: initialState().downloadModal
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case downloadActionTypes.OPEN_DOWNLOAD_MODAL_SUCCESS:
      return openDownloadModalSuccess(state, action)
    case downloadActionTypes.CLOSE_DOWNLOAD_MODAL:
      return closeDownloadModal(state, action)
    default:
      return state
  }
}
