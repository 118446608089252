import React, { PureComponent } from 'react'
import { Table } from 'react-bootstrap'
// import DossierListRowItem from './DossierListRowItem'
import DossierListRowItemComplete from '../../../../_v2/components/dashboard/dossiersList/DossierListRowItemComplete'
import settings from '../../../../setting'
import { dashboardSalesmanColumnDossierSubType, clientInformation } from '../../../../constants/backendIds'

class DossierListTable extends PureComponent {
  onClickColumn (columnCode) {
    let columnCodeOrder = encodeURIComponent('+' + columnCode)
    if (columnCodeOrder === this.props.orderBy) {
      columnCodeOrder = '-' + columnCode
    }

    const params = this.props.params
    this.props.history.push(`/dashboard/${params.salesmanId}/${params.urgencyTypeId}/${params.subTypeId}/${params.stageId}/${this.props.page}/${columnCodeOrder}`)
    this.props.fetchDossiersCompleteList(
      this.props.organizedUnit,
      params.salesmanId,
      params.urgencyTypeId,
      params.subTypeId,
      params.stageId,
      this.props.page,
      columnCodeOrder
    )
  }

  orderByIcon (orderBy, columnName) {
    return (
      <span>
        &nbsp;
        <i className={(() => {
          let clase = 'ico-sort'
          if (orderBy === encodeURIComponent('+' + columnName)) {
            clase += '-selected-asc'
          } else if (orderBy === '-' + columnName) {
            clase += '-selected-desc'
          }
          return clase
        })()} />
      </span>
    )
  }

  render () {
    const { t, dossierList, /* navigateTo, */ dossierSubType, orderBy } = this.props
    const tKey = 'DASHBOARD.DOSSIERS_LIST.TABLE.'

    return (
      <Table responsive>
        <thead>
          <tr>
            <th onClick={this.onClickColumn.bind(this, 'urgencytype')}>
              {this.orderByIcon(orderBy, 'urgencytype')}
            </th>
            <th onClick={this.onClickColumn.bind(this, 'dossier')}>{t(`${tKey}DOSSIER`)}{this.orderByIcon(orderBy, 'dossier')}</th>
            {
              dashboardSalesmanColumnDossierSubType.includes(parseInt(dossierSubType)) && (
                <th onClick={this.onClickColumn.bind(this, 'salesman')}>{t(`${tKey}SALESMAN`)}{this.orderByIcon(orderBy, 'salesman')}</th>
              )
            }
            {
              dashboardSalesmanColumnDossierSubType.includes(parseInt(dossierSubType)) && settings.clientFieldValidation === clientInformation.QUADIS && (
                <th onClick={this.onClickColumn.bind(this, 'salesmanIdManager')}>{t(`${tKey}SALESMAN_MANAGER`)}{this.orderByIcon(orderBy, 'salesmanIdManager')}</th>
              )
            }
            <th onClick={this.onClickColumn.bind(this, 'organizedunit')}>{t(`${tKey}OU`)}{this.orderByIcon(orderBy, 'organizedunit')}</th>
            <th onClick={this.onClickColumn.bind(this, 'entity')}>{t(`${tKey}ENTITY`)}{this.orderByIcon(orderBy, 'entity')}</th>
            <th>{t(`${tKey}VEHICLE`)}</th>
            {
              settings.clientFieldValidation === 'vgr' ? [
                (<th onClick={this.onClickColumn.bind(this, 'productionweek')} key='productionweek'>{t(`${tKey}PRODUCTION_WEEK`)}{this.orderByIcon(orderBy, 'productionweek')}</th>),
                (<th onClick={this.onClickColumn.bind(this, 'deliverydate')} key='deliverydate'>{t(`${tKey}DELIVERY_DATE`)}{this.orderByIcon(orderBy, 'deliverydate')}</th>)
              ] : (<th onClick={this.onClickColumn.bind(this, 'lastchange')}>{t(`${tKey}LAST_MODIFICATION_DATE`)}{this.orderByIcon(orderBy, 'lastchange')}</th>)
            }
          </tr>
        </thead>
        <tbody>
          {
            dossierList && dossierList.map((dossier, idx) =>
              <DossierListRowItemComplete
                key={idx}
                dossier={dossier}
                // navigateTo={navigateTo}
                idr={idx}
                t={t}
                dossierSubType={dossierSubType}
                orderBy={this.props.orderBy}
              />
            )
          }
        </tbody>
      </Table>
    )
  }
}

export default DossierListTable
