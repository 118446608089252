import React, { PureComponent } from 'react'
import BubbleMeter from '../../../commons/BubbleMeter'
import { formatMoney } from '../../../../util/utils'
import { Link } from 'react-router-dom'

class PaperworkRow extends PureComponent {
  render () {
    const {
      paperwork: {
        urlToNavigate, number, type, stage, date, cost, processingAgency, documents
      }
    } = this.props
    return (
      <tr>
        <td>
          {
            urlToNavigate ? (<Link to={`/dossier${urlToNavigate}`}>{number}</Link>)
              : number
          }
        </td>
        <td>{type}</td>
        <td>{stage}</td>
        <td>{date}</td>
        <td className="text-right">{formatMoney(cost)}</td>
        <td>{processingAgency}</td>
        {documents && documents.length ? <td><span >{documents[0].name} </span><BubbleMeter listItems={documents.map(document => document.name)} /></td> : <td></td>}
        <td>
        </td>
        <td/>
      </tr>
    )
  }
}
export default PaperworkRow
