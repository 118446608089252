/* eslint-disable indent */
import React, { PureComponent } from 'react'
import { Field } from 'redux-form'
import { Panel, Row } from 'react-bootstrap'
import InputText from '../../commons/form/InputText'
import InputNatural from '../../commons/form/InputNatural'
import InputSelect from '../../commons/form/InputSelect'
import InputDatePicker from '../../commons/form/InputDatePicker'
import InputTimePicker from '../../commons/form/InputTimePicker'
import InputCheckBox from '../../commons/form/InputCheckBox'
import { dossierSubTypeId as dossierSubTypeIdConstants } from '../../../constants/dossier/common/dossierSubType'
import { validateDate } from '../../../util/validationFunctions'
import { getFieldsPermisions } from '../../../util/permisionFunctions'
import { permissions, saleTypes } from '../../../constants/backendIds'
import CustomFields from '../../commons/customFields/CustomFields'

class PurchaseComponent extends PureComponent {
  constructor () {
    super()
    this.state = {
      fieldsConfiguration: {
        purchaseTypeId: null,
        dealerNumber: null,
        makerNumber: null,
        realDeliveryAddress: null,
        date: null,
        hour: null,
        lastITVDate: null,
        Km: null,
        availabilityDate: null,
        vehicleLocation: null,
        deliveryCity: null,
        tuneUpDestinationId: null,
        isPartOfPayment: null,
        cancelDomainReservationNotRequired: null,
        notTrafficReport: null,
        stockStatusId: null,
        uacDestinationId: null,
        purchaseSaleEntityId: null,
        purchaseSource: null,
        responsableName: null,
        termicWindow: null,
        orderDate: null,
        purchaseOrderTypeId: null,
        validImc: null,
        purchaseState: null,
        checkNotContractPurchaseVehicle: null,
        notPurchaseSelfBilling: null,
        doubleTransferCheck: null,
        salesmanIdLock: null,
        additionalInvoice: null,
        rescueLeasing: null,
        requestVT: null
      },
      rescueLeasing: false,
      additionalInvoiceDisabled: true
    }
    this.dateValidation = this.dateValidation.bind(this)
  }

  initializePermisions (props) {
    const fieldKeys = Object.keys(this.state.fieldsConfiguration)
    const newState = getFieldsPermisions(props.componentConfiguration.fieldsConfiguration, fieldKeys, props.componentConfiguration.permission)
    this.setState({ fieldsConfiguration: newState.fieldsConfiguration })
  }

  UNSAFE_componentWillMount () {
    this.initializePermisions(this.props)
  }

  componentDidMount () {
    this.setState({ puscharseSaleEntityVisible: this.props?.dossier?.dossierPurchaseComponent?.uacDestinationId > 1 })
  }

  dateValidation (value, allValues, props) {
    const result = validateDate(value, props)
    return result === Object(result) ? undefined : result
  }

  render () {
    const {
      t, purchaseOpen, showNewVehicleFields, customFields, dossierSubType, hasParent, showPurchaseContract,
      combos: { purchaseTypeCombo, stockStatusCombo, purchaseOrderTypeCombo, salesmanCombo, saleTypeAllCombo, puscharseSaleEntitys },
      actions: { togglePanel }
    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.'
    return (
      <Row className="paperwork-panel dossier-panel">
        <div className="header-panel" onClick={togglePanel}>
          <div className="name-wrapper">
            <i className="ico-car-files"/>
            <h4>{t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.TITLE`)}</h4>
          </div>
          <i className="ico-arrow-black-down"/>
        </div>
        <Panel expanded={purchaseOpen} onToggle={() => {}}>
          <Panel.Collapse>
          <Row>
              {this.state.fieldsConfiguration.doubleTransferCheck &&
                this.state.fieldsConfiguration.doubleTransferCheck.permission !== permissions.hidden &&
                (<Field
                  colSm={4}
                  id="doubleTransferCheck"
                  name="dossierPurchaseComponent.doubleTransferCheck"
                  controlLabel={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.DOUBLE_TRANSFER`)}
                  placeholder={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.DOUBLE_TRANSFER`)}
                  valueKey="id"
                  labelKey="value"
                  component={InputCheckBox}
                  disabled={true}
              />)}
          </Row>
          <Row>
          {this.state.fieldsConfiguration.salesmanIdLock && this.state.fieldsConfiguration.salesmanIdLock.permission !== permissions.hidden && (
                <Field
                  colSm={4}
                  id="salesmanId"
                  name="dossierPurchaseComponent.salesmanId"
                  controlLabel={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.SALESMAN`)}
                  placeholder={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.SALESMAN`)}
                  valueKey="id"
                  labelKey="value"
                  component={InputSelect}
                  options={salesmanCombo}
                  disabled={true}
                />
              )}
            {this.state.fieldsConfiguration.purchaseTypeId && this.state.fieldsConfiguration.purchaseTypeId.permission !== permissions.hidden && (<Field
              colSm={4}
              id="purchaseTypeId"
              name="dossierPurchaseComponent.purchaseTypeId"
              controlLabel={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.PURCHASE_TYPE`)}
              placeholder={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.PURCHASE_TYPE`)}
              valueKey="id"
              labelKey="value"
              component={InputSelect}
              options={purchaseTypeCombo}
              disabled={true}
            />)}
            {this.state.fieldsConfiguration.dealerNumber && this.state.fieldsConfiguration.dealerNumber.permission !== permissions.hidden && (<Field
              colSm={4}
              id="dealerNumber"
              name="dossierPurchaseComponent.dealerNumber"
              controlLabel={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.DEALER_NUMBER`)}
              placeholder={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.DEALER_NUMBER`)}
              component={InputText}
              disabled={true}
            />)}
            {
              showNewVehicleFields && this.state.fieldsConfiguration.makerNumber && this.state.fieldsConfiguration.makerNumber.permission !== permissions.hidden
                ? <Field
                  colSm={4}
                  id="purchaseMakerNumber"
                  name="dossierPurchaseComponent.makerNumber"
                  controlLabel={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.MAKER_NUMBER`)}
                  placeholder={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.MAKER_NUMBER`)}
                  component={InputText}
                  disabled={true}
                /> : null
            }
            {dossierSubType !== dossierSubTypeIdConstants.compra_vn &&
              this.state.fieldsConfiguration.vehicleLocation && this.state.fieldsConfiguration.vehicleLocation.permission !== permissions.hidden && (<Field
              colSm={4}
              id="vehicleLocation"
              name="dossierPurchaseComponent.vehicleLocation"
              controlLabel={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.VEHICLE_LOCATION`)}
              placeholder={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.VEHICLE_LOCATION`)}
              component={InputText}
              disabled={true}
            />
            )}
            {this.state.fieldsConfiguration.realDeliveryAddress && this.state.fieldsConfiguration.realDeliveryAddress.permission !== permissions.hidden && (<Field
              colSm={4}
              id="realDeliveryAddress"
              name="dossierCompleteDeliveryComponent.dossierDeliveryComponent.realDeliveryAddress"
              controlLabel={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.ADDRESS`)}
              placeholder={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.ADDRESS`)}
              component={InputText}
              disabled={true}
            />)}
            {this.state.fieldsConfiguration.date && this.state.fieldsConfiguration.date.permission !== permissions.hidden && (<Field
              colSm={4}
              id="date"
              name="dossierCompleteDeliveryComponent.dossierDeliveryComponent.realDeliveryDate"
              controlLabel={t(`${tKey}DELIVERY.DATE_RECIVE`)}
              component={InputDatePicker}
              validate={[this.dateValidation]}
              disabled={true}
            />)}
            {this.state.fieldsConfiguration.hour && this.state.fieldsConfiguration.hour.permission !== permissions.hidden && (<Field
              colSm={4}
              id="hour"
              name="dossierCompleteDeliveryComponent.dossierDeliveryComponent.realDeliveryTime"
              controlLabel={t(`${tKey}DELIVERY.HOUR_RECIVE`)}
              placeholder={t(`${tKey}DELIVERY.HOUR_RECIVE`)}
              component={InputTimePicker}
              disabled={true}
            />)}
            {dossierSubType !== dossierSubTypeIdConstants.compra_vn &&
              this.state.fieldsConfiguration.lastITVDate && this.state.fieldsConfiguration.lastITVDate.permission !== permissions.hidden && (<Field
              colSm={4}
              id="lastITVDate"
              key="dossierCompleteDeliveryComponent.dossierDeliveryComponent.lastITVDate"
              name="dossierCompleteDeliveryComponent.dossierDeliveryComponent.lastITVDate"
              controlLabel={t(`${tKey}DELIVERY.LAST_ITV_DATE`)}
              disabled={true}
              component={InputDatePicker}
              validate={[this.dateValidation]}
            />)}
            {dossierSubType !== dossierSubTypeIdConstants.compra_vn &&
              this.state.fieldsConfiguration.Km && this.state.fieldsConfiguration.Km.permission !== permissions.hidden && (<Field
              colSm={4}
              id="Km"
              key="dossierCompleteDeliveryComponent.dossierDeliveryComponent.km"
              name="dossierCompleteDeliveryComponent.dossierDeliveryComponent.km"
              controlLabel={t(`${tKey}DELIVERY.KM`)}
              placeholder={t(`${tKey}DELIVERY.KM`)}
              component={InputNatural}
              disabled={true}
              textAlign="left"
            />)}
            {this.state.fieldsConfiguration.availabilityDate && this.state.fieldsConfiguration.availabilityDate.permission !== permissions.hidden && (<Field
              colSm={4}
              id="availabilityDate"
              name="dossierPurchaseComponent.availabilityDate"
              controlLabel={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.AVAILABILITY_DATE`)}
              component={InputDatePicker}
              validate={[this.dateValidation]}
              disabled={true}
            />)}
            {this.state.fieldsConfiguration.orderDate && this.state.fieldsConfiguration.orderDate.permission !== permissions.hidden && (<Field
                colSm={4}
                id="orderDate"
                name="dossierPurchaseComponent.orderDate"
                controlLabel={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.ORDER_DATE`)}
                component={InputDatePicker}
                validate={[this.dateValidation]}
                disabled={true}
                />)}
              {this.state.fieldsConfiguration.purchaseOrderTypeId && this.state.fieldsConfiguration.purchaseOrderTypeId.permission !== permissions.hidden && (<Field
                colSm={4}
                id="purchaseOrderTypeId"
                name="dossierPurchaseComponent.purchaseOrderTypeId"
                controlLabel={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.PURCHASE_ORDER_TYPE`)}
                valueKey="id"
                labelKey="value"
                component={InputSelect}
                options={purchaseOrderTypeCombo}
                disabled={true}
                />)}
            {dossierSubType === dossierSubTypeIdConstants.compra_vn &&
              this.state.fieldsConfiguration.vehicleLocation && this.state.fieldsConfiguration.vehicleLocation.permission !== permissions.hidden && (<Field
              colSm={4}
              id="vehicleLocation"
              name="dossierPurchaseComponent.vehicleLocation"
              controlLabel={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.VEHICLE_LOCATION`)}
              placeholder={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.VEHICLE_LOCATION`)}
              component={InputText}
              disabled={true}
            />
            )}
            {this.state.fieldsConfiguration.deliveryCity && this.state.fieldsConfiguration.deliveryCity.permission !== permissions.hidden && (<Field
              id="deliveryCity"
              name="dossierCompleteDeliveryComponent.dossierDeliveryComponent.deliveryCity"
              colSm={4}
              controlLabel={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.CITY_DELIVERY`)}
              placeholder={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.CITY_DELIVERY`)}
              component={InputText}
              disabled={true}
            />)}
            {this.state.fieldsConfiguration.stockStatusId && this.state.fieldsConfiguration.stockStatusId.permission !== permissions.hidden && (<Field
              colSm={4}
              id="stockStatusId"
              name="dossierPurchaseComponent.stockStatusId"
              controlLabel={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.STOCK_STATUS`)}
              placeholder={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.STOCK_STATUS`)}
              valueKey="id"
              labelKey="value"
              component={InputSelect}
              options={stockStatusCombo}
              disabled={true}
            />)}
             {this.state.fieldsConfiguration.purchaseSource && this.state.fieldsConfiguration.purchaseSource.permission !== permissions.hidden && (<Field
                colSm={4}
                id="purchaseSource"
                name="dossierPurchaseComponent.purchaseSource"
                controlLabel={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.PURCHASE_SOURCE`)}
                placeholder={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.PURCHASE_SOURCE`)}
                valueKey="id"
                labelKey="value"
                component={InputText}
                disabled={true}
              />)}
            {this.state.fieldsConfiguration.responsableName && this.state.fieldsConfiguration.responsableName.permission !== permissions.hidden && (<Field
                colSm={4}
                id="responsableName"
                name="dossierPurchaseComponent.responsableName"
                controlLabel={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.RESPONSABLE`)}
                placeholder={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.RESPONSABLE`)}
                component={InputText}
                disabled={true}
              />)}
              {this.state.fieldsConfiguration.purchaseState && this.state.fieldsConfiguration.purchaseState.permission !== permissions.hidden && (<Field
                colSm={4}
                id="purchaseState"
                name="dossierPurchaseComponent.purchaseState"
                controlLabel={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.PURCHASE_STATE`)}
                placeholder={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.PURCHASE_STATE`)}
                component={InputText}
                disabled={true}
                />)}
              {this.state.fieldsConfiguration.termicWindow && this.state.fieldsConfiguration.termicWindow.permission !== permissions.hidden && (
                <Field
                component={InputSelect}
                as="select"
                name="dossierPurchaseComponent.termicWindow"
                controlLabel={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.TERMIC_WINDOW`)}
                placeholder={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.TERMIC_WINDOW`)}
                options={[{ id: true, value: t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.YES`) },
                  { id: false, value: t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.NO`) }]}
                valueKey="id"
                labelKey="value"
                multiple={false}
                htmlFor="termicWindow"
                disabled={true}
              />
              )}
              {this.state.fieldsConfiguration.uacDestinationId && this.state.fieldsConfiguration.uacDestinationId.permission !== permissions.hidden && (
                <Field
                colSm={4}
                id="uacDestinationId"
                name="dossierPurchaseComponent.uacDestinationId"
                controlLabel={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.DESTINATION`)}
                placeholder={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.DESTINATION`)}
                valueKey="id"
                labelKey="value"
                onChange={(val) => this.uacDestinationOnChange(val)}
                component={InputSelect}
                options={saleTypeAllCombo && saleTypeAllCombo.filter(x => [saleTypes.PRIVATE, saleTypes.SALE, saleTypes.SALE_CREDIT].includes(x.id))}
                disabled={true}
              />)}
              {this.state.puscharseSaleEntityVisible && this.state.fieldsConfiguration.purchaseSaleEntityId && this.state.fieldsConfiguration.purchaseSaleEntityId.permission !== permissions.hidden && (<Field
                colSm={4}
                id="purchaseSaleEntityId"
                name="dossierPurchaseComponent.purchaseSaleEntityId"
                controlLabel={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.BUYING_SELLING_ENTITY`)}
                placeholder={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.BUYING_SELLING_ENTITY`)}
                valueKey="id"
                labelKey="value"
                component={InputSelect}
                options={puscharseSaleEntitys }
                disabled={true}
              />)}
          </Row>
          <Row>
            {hasParent && this.state.fieldsConfiguration.isPartOfPayment && this.state.fieldsConfiguration.isPartOfPayment.permission !== permissions.hidden && (<Field
              colSm={4}
              id="isPartOfPayment"
              name="dossierPurchaseComponent.isPartOfPayment"
              placeholder={t('DOSSIER_COMPONENTS.UAC.MODAL.VEHICLE.IS_PART_OF_PAYMENT')}
              inline
              component={InputCheckBox}
              disabled={true}
            />)}
            {dossierSubType === dossierSubTypeIdConstants.compra_vo && hasParent &&
              this.state.fieldsConfiguration.cancelDomainReservationNotRequired && this.state.fieldsConfiguration.cancelDomainReservationNotRequired.permission !== permissions.hidden && (<Field
              colSm={4}
              id="cancelDomainReservationNotRequired"
              name="dossierPurchaseComponent.cancelDomainReservationNotRequired"
              controlLabel={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.CANCEL_DOSSIER_RESERVATION_NOT_REQUIRED`)}
              placeholder={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.CANCEL_DOSSIER_RESERVATION_NOT_REQUIRED`)}
              inline
              component={InputCheckBox}
              disabled={true}
            />
            )}
              {this.state.fieldsConfiguration.rescueLeasing && this.state.fieldsConfiguration.rescueLeasing.permission !== permissions.hidden && (<Field
              colSm={4}
              id="rescueLeasing"
              name="dossierPurchaseComponent.rescueLeasing"
              placeholder={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.RESCUE_LEASING`)}
              inline
              component={InputCheckBox}
              disabled={true}
            />)}
            {this.state.fieldsConfiguration.additionalInvoice && this.state.fieldsConfiguration.additionalInvoice.permission !== permissions.hidden && (<Field
                  colSm={4}
                  id="additionalInvoice"
                  name="dossierPurchaseComponent.additionalInvoice"
                  placeholder={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.ADDITIONAL_INVOICE`)}
                  inline
                  component={InputCheckBox}
                  disabled={true}
                />)}
            {this.state.fieldsConfiguration.notTrafficReport.permission !== permissions.hidden && (<Field
              colSm={4}
              id="notTrafficReport"
              name="dossierPurchaseComponent.notTrafficReport"
              placeholder={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.NOT_TRAFFIC_REPORT`)}
              inline
              component={InputCheckBox}
              disabled={true}
            />)}

            {this.state.fieldsConfiguration.validImc && this.state.fieldsConfiguration.validImc.permission !== permissions.hidden && (<Field
              colSm={4}
              id="validImc"
              name="dossierPurchaseComponent.validImc"
              placeholder={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.NOT_VALID_IMC`)}
              inline
              component={InputCheckBox}
              disabled={true}
            />)}
             {this.state.fieldsConfiguration.checkNotContractPurchaseVehicle && this.state.fieldsConfiguration.checkNotContractPurchaseVehicle.permission !== permissions.hidden &&
              dossierSubType === dossierSubTypeIdConstants.compra_vo && showPurchaseContract && (<Field
                  colSm={4}
                  id="notContractPurchaseVehicle"
                  name="dossierPurchaseComponent.notContractPurchaseVehicle"
                  placeholder={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.NOT_CONTRACT_PURCHASE_VEHICLE`)}
                  inline
                  component={InputCheckBox}
                  disabled={true}
                />)}
               {this.state.fieldsConfiguration.notPurchaseSelfBilling && this.state.fieldsConfiguration.notPurchaseSelfBilling.permission !== permissions.hidden &&
                  dossierSubType === dossierSubTypeIdConstants.compra_vo && (<Field
                  colSm={4}
                  id="notPurchaseSelfBilling"
                  name="dossierPurchaseComponent.notPurchaseSelfBilling"
                  placeholder={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.NOT_PURCHASE_SELF_BILLING`)}
                  inline
                  component={InputCheckBox}
                  disabled={true}
                />)}
                {this.state.fieldsConfiguration.requestVT && this.state.fieldsConfiguration.requestVT.permission !== permissions.hidden && (
                <Field
                  colSm={4}
                  id="requestVT"
                  name="dossierPurchaseComponent.requestVT"
                  placeholder={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.REQUEST_VT`)}
                  inline
                  component={InputCheckBox}
                  disabled={true}
                />)}
          </Row>
          <CustomFields customFields={customFields} readOnlyFields={true} />
          </Panel.Collapse>
        </Panel>
      </Row>
    )
  }
}

export default PurchaseComponent
