import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import SearchByFleetReport from '../../../components/search/SearchByFleetReport'
import { translate } from 'react-polyglot'
import { fetchEntityTypes, fetchEntitySubTypeCombo, fetchEntityFilterSubTypeCombo, fetchPurchaseLogisticStateCombo, fetchStagesCombo } from '../../../actions/combos/combos'

export function mapStateToProps (state) {
  return {
    combos: {
      UOTreeCombo: state.combos.UOTreeCombo,
      entityTypeCombo: state.combos.entityTypeCombo,
      entitySubTypeCombo: state.combos.entitySubTypeCombo,
      entityFilterSubTypeCombo: state.combos.entityFilterSubTypeCombo,
      salesmanCombo: state.combos.salesmanCombo,
      purchaseStateLogisticCombo: state.combos.purchaseStateLogisticCombo,
      stagesCombo: state.combos.stagesCombo
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchEntityTypes,
      fetchEntitySubTypeCombo,
      fetchEntityFilterSubTypeCombo,
      fetchPurchaseLogisticStateCombo,
      fetchStagesCombo
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(SearchByFleetReport))
