import React, { PureComponent } from 'react'
import { Link, Redirect } from 'react-router-dom'
import { Field, reduxForm } from 'redux-form'
import { Row, Col, Image, Button, Alert } from 'react-bootstrap'
import InputText from '../commons/form/InputText'
import InputPassword from '../commons/form/InputPassword'
import Access from '../Access'
import settings from '../../settings/settings'

const validate = (values, { t }) => {
  const errors = {}
  if (!values.emailAddress) {
    errors.emailAddress = t('GLOBAL.FORMS.REQUIRED')
  } else if (!/^[A-Z0-9._%+-Ñ]+@[A-Z0-9._%+-Ñ]+\.[A-Z]{2,4}$/i.test(values.emailAddress)) {
    errors.emailAddress = t('GLOBAL.FORMS.INVALID_EMAIL_ADDRESS')
  }
  if (!values.password) errors.password = t('GLOBAL.FORMS.REQUIRED')
  return errors
}

class Login extends PureComponent {
  constructor (props) {
    super(props)
    this.onSubmit = this.onSubmit.bind(this)
  }

  onSubmit (values) {
    return new Promise((resolve, reject) => {
      this.props.actions.fetchAuth(values, resolve, reject, this.props.t)
    })
  }

  render () {
    const { t, handleSubmit, error, invalid, submitting, /* actions: { fetchAuth }, */ submitSucceeded, sections, nonUpdatedSalesControl } = this.props
    const redirection = this.props.location.hash
    if (submitSucceeded && sections.length > 0) {
      if (redirection) {
        return <Redirect to={redirection.substring(1)} />
      } else {
        if (!nonUpdatedSalesControl) {
          return <Redirect to={'/' + sections[0].code} />
        } else {
          return <Redirect to={'/nonUpdatedDossiers'} />
        }
      }
    }
    return (
      <Access>
        <form className="auth" onSubmit={handleSubmit(this.onSubmit)}>
          <div className="company-wrapper">
            <h1 className="company-logo">
              <Image src="images/logo_kia.png" />
            </h1>
            <h2 className="company-name">
              iDocCar
            </h2>
          </div>
          <hr />
          <h3 className="title-modal">{t('LOGIN.LOGIN')}</h3>
          <Row>
            <Field
              colSm={10}
              colSmOffset={1}
              id='emailAddress'
              name='emailAddress'
              placeholder={t('LOGIN.EMAIL')}
              controlLabel={t('LOGIN.EMAIL')}
              component={InputText}
            />
            <Field
              t={t}
              colSm={10}
              colSmOffset={1}
              id='password'
              name='password'
              placeholder={t('LOGIN.PASSWORD')}
              controlLabel={t('LOGIN.PASSWORD')}
              component={InputPassword}
            />
            <Col sm={10} smOffset={1} >
              <Link id='lnk_remember' to={'/remember-password'} className="remember-password">
                {t('LOGIN.FORGOT_PASSWORD')}
              </Link>
            </Col>
            <Button
              id='btn_login'
              type='submit'
              bsStyle='primary'
              className='btn-wide-lg btn-auth'
              name='btnLogin'
              disabled={invalid || submitting}
            >
              {t('LOGIN.LOG_IN')}
            </Button>
            {error &&
              <Col smOffset={1} sm={10}>
                <Alert bsStyle='danger' role='alert'>
                  <p>{error}</p>
                </Alert>
              </Col>
            }
          </Row>
          <div className="poweredByWrapper">
            <Image src="images/poweredby.png" />
            <p className="login-version">v{settings.version}</p>
          </div>
        </form>
      </Access>
    )
  }
}

export default reduxForm({
  form: 'login',
  validate
})(Login)
