import React, { PureComponent } from 'react'
import { Row, Col, Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { conditionNoDocumentalTypes, functionToPassTypeView } from '../../constants/backendIds'

class TimeLine extends PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      liClass: ''
    }
  }

  UNSAFE_componentWillMount () {
    this.setLiClass(this.props)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (this.props.stages.length !== nextProps.stages.length) this.setLiClass(nextProps)
  }

  setLiClass (props) {
    let liClass = ''
    const stages = props.stages || []
    switch (stages.length) {
      default:
      case 4:
        liClass = 'four'
        break
      case 5:
        liClass = 'five'
        break
      case 6:
        liClass = 'six'
        break
      case 7:
        liClass = 'seven'
        break
      case 8:
        liClass = 'eight'
        break
      case 9:
        liClass = 'nine'
        break
    }
    this.setState({ liClass })
  }

  tooltip (textTooltip) {
    return (
      <Tooltip id="editTooltip">{textTooltip}</Tooltip>
    )
  }

  renderList (title, elements, isConditionDocumental, activeStage, conditionNoDocumentalType = null) {
    const tKey = 'CONDITIONS_MAINTENANCE.TIMELINE.'
    return (
      <Col sm={4}>
        <p>{this.props.t(tKey + title)}:</p>
        <ul>
          {elements.map((element, idx) => {
            let name = ''
            if (isConditionDocumental) {
              var docType = this.props.documentsTypes.find((documentType) => documentType.id === element.documentTypeUseId)
              if (docType) {
                name = docType.value
              } else {
                name = element.name
              }
            } else {
              name = element.name
            }
            return (
              <li key={idx} id={title + '_' + idx}>
                <OverlayTrigger
                  trigger={['hover', 'focus']}
                  placement="top"
                  overlay={this.tooltip(this.props.t(`${tKey}EDIT`))}
                  onClick={() => this.props.actions.openEditConditionsModal(isConditionDocumental, isConditionDocumental ? element.conditionDocumentalId : element.conditionNoDocumentalId, conditionNoDocumentalType)}
                >
                  <Button className="btn-icon-only"><i className="ico-edit-white"></i></Button>
                </OverlayTrigger>
                {element.typeView !== functionToPassTypeView.limited && <OverlayTrigger
                  trigger={['hover', 'focus']}
                  placement="top"
                  overlay={this.tooltip(this.props.t(`${tKey}DELETE`))}
                  onClick={() => this.props.actions.deleteCondition(isConditionDocumental, isConditionDocumental ? element.conditionDocumentalId : element.conditionNoDocumentalId, activeStage)}
                >
                  <Button className="btn-icon-only"><i className="ico-trash"></i></Button>
                </OverlayTrigger>}
                <span className="nombre-datos-timeline">{name}</span>
              </li>
            )
          })}
        </ul>
        <Button className="btn-standard" onClick={() => this.props.actions.openAddConditionsModal(isConditionDocumental, activeStage, conditionNoDocumentalType)}>+ {this.props.t(`${tKey}ADD`)}</Button>
      </Col>
    )
  }

  render () {
    const {
      stages = [], activeStage, docs, fields, others,
      actions: { setActiveStage }
    } = this.props
    if (stages.length === 0 || this.props.show === false) return (<div />)
    return (
      <div className="time-line-header">
        <ul className={'time-line-popover ' + this.state.liClass}>
          {stages.map((stage, idx) => (
            <OverlayTrigger key={idx} trigger={['hover', 'focus']} placement="top" overlay={this.tooltip(stage.value)} onClick={(e) => setActiveStage(stage.id)}>
              <li key={idx}>
                <span className={`status-icon ${stage.id === activeStage ? 'active' : ''}`} />
                <span className='name-ellipsis'>{stage.value}</span>
              </li>
            </OverlayTrigger>
          ))}
        </ul>
        {activeStage &&
          <Row className="time-line-list">
            {docs && this.renderList('POPOVER.DOCUMENTS', docs, true, activeStage)}
            {fields && this.renderList('POPOVER.FIELDS', fields, false, activeStage, conditionNoDocumentalTypes.data)}
            {others && this.renderList('POPOVER.OTHERS', others, false, activeStage, conditionNoDocumentalTypes.other)}
          </Row>}
      </div>
    )
  }
}

export default TimeLine
