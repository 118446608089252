import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { closeUserProcessingAgencyAddModal, fetchUserListForProcessingAgency, assingUserProcessingAgency, getUsersProcessingAgency } from '../../../actions/masters/masters'
import ProcessingAgencyUsersAddModal from '../../../components/masters/processing/ProcessingAgencyUsersAddModal'

export function mapStateToProps (state) {
  return {
    processingAngencyUsersModal: state.processingAgency.processingAngencyUsersModal,
    ...state.processingAgency.processingAngencyUsersAddModal
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      closeUserProcessingAgencyAddModal,
      fetchUserListForProcessingAgency,
      assingUserProcessingAgency,
      getUsersProcessingAgency
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(ProcessingAgencyUsersAddModal))
