import React, { PureComponent } from 'react'
import { reduxForm } from 'redux-form'
import { Row, Col, Table } from 'react-bootstrap'
import { Element } from 'react-scroll'
import { dossierTypes as dossierTypesConstant } from '../../../constants/dossier/common/dossierTypes'
import { compareObjects, getDocumentaryPermission } from '../../../util/utils'
import { validateInteger, validateEntity, validateVehicle, validatePaperworkAddress, validateContact, isEntityEmpty, isEntityChildEmpty, isContactEmpty, isRequiredEndCustomerAndContactVGR, isRequiredEndCustomerAndContact, validateRegex, customValidations } from '../../../util/validationFunctions'
import { getComponentsPermisions } from '../../../util/permisionFunctions'
import DocumentaryManagementModalPage from '../../../containers/dossiers/common/DocumentaryManagementModalPage'
import StatusHeaderPage from '../../../containers/dossiers/common/StatusHeaderPage'
import ButtonsHeader from '../common/ButtonsHeader'
import ButtonsSidebar from '../common/ButtonsSidebar'
import Client from '../common/Client'
import Vehicle from '../common/Vehicle'
import PaperworkComponent from './PaperworkComponent'
import RejectCommentModal from '../common/RejectCommentModal'
import { buttonsHeader } from '../../../constants/dossier/common/buttonsHeader'
import { buttonsSidebar } from '../../../constants/dossier/common/buttonsSidebar'
import { permissions, clientInformation } from '../../../constants/backendIds'
import DocumentaryManagementPage from '../../../containers/dossiers/common/DocumentaryManagementPage'
import AuditInfo from '../common/audit/AuditInfo'
import RelatedDossierList from '../../commons/RelatedDossierList'
import CommentComponent from '../common/comment/CommentComponent'
import TrackingContainer from '../../../containers/tracking/trackingContainer'
import { TrackedPages } from '../../../constants/tracking/TrackedPages'
import setting from '../../../setting'
import {
  asyncValidateEntityDni, getFieldsValidated
} from '../../../util/validationFunctionEntityDniAsync'
import { validationPaperworkPromisesOrder } from '../../../constants/validatedFields/backValidationPromisesOrder'
import promisesStatus from '../../../constants/promisesStatus'

class Paperwork extends PureComponent {
  constructor () {
    super()
    this.state = {
      modalOpen: true,
      showRejectCommentModal: false,
      paperworkOpen: true,
      isVehicleLoaded: false,
      isClientLoaded: false,
      activeTabConfiguration: null,
      headerTabConfiguration: null,
      componentsConfiguration: {
        bpaperwork: null,
        entity: null,
        vehicle: null
      },
      reason: null,
      comment: null
    }
    this.goBack = this.goBack.bind(this)
  }

  goBack () {
    this.props.actions.setActiveTab(buttonsHeader.datos_expediente)
    this.props.history.goBack()
  }

  toggleRejectCommentModal (showRejectCommentModal) {
    this.setState({ showRejectCommentModal })
  }

  submitPaperworkDossier (values) {
    this.props.actions.savePaperworkDossier(values)
  }

  fetchPaperworkDossierPromise (dossierId) {
    return new Promise((resolve, reject) => {
      return this.props.actions.fetchPaperworkDossier(dossierId, resolve, reject)
    })
  }

  onClickRelatedDossierColumn (columnCode) {
    let columnCodeOrder = '+' + columnCode
    if (columnCodeOrder === this.props.relatedDossiersOrderBy) {
      columnCodeOrder = '-' + columnCode
    }
    this.props.actions.fetchFilteredRelatedDossier(this.props.dossier.dossierId, columnCodeOrder)
  }

  getRelatedDossierColumnIcon (columnCode) {
    if ('+' + columnCode === this.props.relatedDossiersOrderBy) {
      return 'ico-sort-selected-asc'
    } else if ('-' + columnCode === this.props.relatedDossiersOrderBy) {
      return 'ico-sort-selected-desc'
    } else {
      return 'ico-sort'
    }
  }

  routerWillLeave (nextLocation) {
    let pristine = true
    if (this.props.dossier.viewConfiguration.buttonsPermission && this.props.dossier.viewConfiguration.buttonsPermission.includes('paperwork_save')) {
      pristine = this.props.pristine
    }
    this.props.actions.paperworkWarningSave(this.props.reset, this.props.history, nextLocation, pristine)
    return pristine
  }

  initializePermisions (props) {
    const componentKeys = Object.keys(this.state.componentsConfiguration)
    const newState = getComponentsPermisions(props.dossier.viewConfiguration.tabsConfiguration, props.activeTab, componentKeys)
    this.setState({ ...this.state, ...newState })
  }

  UNSAFE_componentWillMount () {
    this.props.actions.restartPaperworkState()
    this.fetchPaperworkDossierPromise(this.props.match.params.dossierId).then(() => {
      this.props.actions.fetchCustomFields(this.props.dossier.dossierSubTypeId)
      this.props.actions.registerLastAccess(this.props.match.params.dossierId, false)
      const form = {
        ...this.props.dossier,
        entityComponent: this.props.dossier.entityComponent.entityId ? this.props.dossier.entityComponent : undefined
      }
      if (!this.props.dossier.entityComponent.entityId) delete form.entityComponent
      if (!this.props.dossier.vehicleComponent.vehicleId) delete form.vehicleComponent
      this.props.initialize(form)
      this.initializePermisions(this.props)
      this.props.actions.fetchAllReasons(this.props.dossier.dossierSubTypeId)

      this.props.actions.fetchVehicleType()

      this.props.trackVisitPage({
        documentTitle: TrackedPages.PAPERWORK,
        organizedUnitId: this.props.dossier.organizedUnitId,
        href: window.location.href
      })
    })
    this.unregisterLeaveHook = this.props.history.block(this.routerWillLeave.bind(this))
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if ((this.props.showManagementModal && !nextProps.showManagementModal) || this.props.match.params.dossierId !== nextProps.match.params.dossierId) {
      if (this.props.match.params.dossierId !== nextProps.match.params.dossierId) {
        this.props.actions.restartPaperworkState()
        this.props.actions.setScrollTo(buttonsSidebar.bpaperwork)
        this.props.actions.setActiveSection(buttonsSidebar.bpaperwork)
        this.props.actions.setActiveTab(buttonsHeader.datos_expediente)
        this.props.actions.closeAddOrEditDocumentModal()
        this.updateComponentAfterDeleteAndRestart()
      }
      this.fetchPaperworkDossierPromise(nextProps.match.params.dossierId).then(() => {
        const form = { ...this.props.dossier }
        if (!this.props.dossier.entityComponent.entityId) delete form.entityComponent
        if (!this.props.dossier.vehicleComponent.vehicleId) delete form.vehicleComponent
        this.props.initialize(form)
        this.initializePermisions(this.props)
      })
    }
    if (nextProps.dossier.dossierId && !compareObjects(this.props.dossier.viewConfiguration.tabsConfiguration || {}, nextProps.dossier.viewConfiguration.tabsConfiguration, true)) {
      this.initializePermisions(nextProps)
    }
    if (nextProps.dossier.dossierId && nextProps.activeTab !== this.props.activeTab) {
      this.initializePermisions(nextProps)
    }
    if (nextProps.dossier && nextProps.dossier.comments && this.props.activeTab !== nextProps.activeTab && nextProps.activeTab === buttonsHeader.observaciones) {
      this.props.actions.getComments(nextProps.dossier.dossierId, 'paperworkDossier')
    }
    if (nextProps.dossier && nextProps.dossier.organizedUnitId && nextProps.dossier.organizedUnitId !== this.props.dossier.organizedUnitId) {
      this.props.actions.fecthOrganizedUnitsByDossierUo(nextProps.dossier.organizedUnitId)
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.dossier.dossierId && this.props.statusHeaderHeight && this.props.activeSection) {
      switch (this.props.activeSection) {
        case buttonsSidebar.bpaperwork:
          this.setState({ paperworkOpen: true })
          break
      }
    }

    const firstCustomFieldsLoad = prevProps.dossier.customFieldsByComponent?.length < this.props.dossier.customFieldsByComponent?.length
    if (firstCustomFieldsLoad) {
      let customFields = {}
      if (this.props.dossier.customFieldsValues) {
        this.props.dossier.customFieldsValues.map(x => {
          customFields[x.key] = x.value
        })
      }
      const form = {
        ...this.props.dossier,
        customFields: customFields
      }
      this.props.initialize(form)
    }
  }

  componentWillUnmount () {
    this.props.actions.setActiveSection(buttonsSidebar.bpaperwork)
    this.props.actions.setScrollTo(null)
    this.props.actions.resetButtonsHeader()
    this.unregisterLeaveHook
    this.props.actions.closeAddOrEditDocumentModal()
    this.props.actions.restartPaperworkState()
  }

  handleToUpdateComponent (component) {
    if (component.isVehicleLoaded === false || buttonsSidebar.vehicle === component) this.setState({ isVehicleLoaded: true })
    if (component.isClientLoaded === false || buttonsSidebar.entity === component) this.setState({ isClientLoaded: true })
    if (component.isEconomicLoaded === false) this.setState({ isEconomicLoaded: true })
    this.props.actions.setScrollTo(component)
  }

  updateComponentAfterDeleteAndRestart () {
    this.setState({
      isClientLoaded: false,
      isVehicleLoaded: false,
      isEconomicLoaded: false
    })
    this.props.actions.setActiveSection(buttonsSidebar.bpurchase)
    this.props.actions.setScrollTo(null)
  }

  componentDidMount () {
    this.props.actions.fetchCommercialSocietyTypeCombo()
  }

  handleSubmitForm () {
    if (this.props.formErrors) {
      let skipGeneralMsg = false
      if (this.props.formErrors.entityComponent && Object.keys(this.props.formErrors.entityComponent).length > 0 ||
        this.props.formErrors.entityChildComponent && Object.keys(this.props.formErrors.entityChildComponent).length > 0 ||
        this.props.formErrors.dossierContactComponent && Object.keys(this.props.formErrors.dossierContactComponent).length > 0) {
        this.props.actions.setScrollTo(buttonsSidebar.entity)
        this.props.actions.setActiveSection(buttonsSidebar.entity)
        if ((this.props.formErrors.entityComponent && Object.keys(this.props.formErrors.entityComponent).length > 0)) {
          this.props.actions.openErrorMessageModal('DOSSIER_COMPONENTS.VALIDATIONS.CLIENT_DATA_MANDATORY')
          skipGeneralMsg = true
        } else if (this.props.formErrors.dossierContactComponent !== {} || this.props.formErrors.entityChildComponent !== {}) {
          this.props.actions.openErrorMessageModal('DOSSIER_COMPONENTS.VALIDATIONS.FINAL_CLIENT_OR_CONTACT_MANDATORY')
          skipGeneralMsg = true
        }
      } else if (this.props.formErrors.vehicleComponent && Object.keys(this.props.formErrors.vehicleComponent).length > 0) {
        this.props.actions.setScrollTo(buttonsSidebar.vehicle)
        this.props.actions.setActiveSection(buttonsSidebar.vehicle)
      } else if (this.props.formErrors.dossierPaperworkComponent && Object.keys(this.props.formErrors.dossierPaperworkComponent).length > 0) {
        this.props.actions.setScrollTo(buttonsSidebar.bpaperwork)
        this.props.actions.setActiveSection(buttonsSidebar.bpaperwork)
      }
      if (this.props.isInvalid && !skipGeneralMsg) {
        this.props.actions.openErrorMessageModal('DOSSIER_COMPONENTS.VALIDATIONS.GENERAL_MANDATORY')
      }
    }
    this.props.handleSubmit((values) => this.submitPaperworkDossier(values))()
  }

  togglePanel () {
    if (this.state.paperworkOpen) {
      this.props.actions.setActiveSection(false)
    }
    this.setState({ paperworkOpen: !this.state.paperworkOpen })
  }

  render () {
    const {
      t, /* handleSubmit, */ combos, dossier, change, pristine, vehicleProps, activeTab, buttonsHeaderHeight, activeSection, statusHeaderHeight, windowWidth, /* isInvalid, formErrors, */ reset, formValues, reason, comment, dispatch,
      dossier: {
        dossierId, header, checkListHeader, comments, dossierParentId, dossierParentSubTypeId, unreadComments,
        viewConfiguration, viewConfiguration: { buttonsPermission = [] },
        vehicleComponent: { vehicleId }, entityChangeable, dossierRelatedDossiers, auditInfo, dossierPaperworkComponent,
        dossierSubTypeId, isMarked
      },
      actions: {
        updateComment, createComment, deleteComment, fetchClientByDni, searchVehicle, setSearchDealerNumber, fetchClientByDniEntityChild,
        setSearchVinChassis, setSearchLicensePlate, setSimpleInputTextValidation,
        fetchEntitySubTypeCombo, fetchEntityFilterSubTypeCombo, paperworkReject, putCancelDossier, paperworkGenDelivery,
        openDocumentModal, openDocumentEditionModal, fetchClientFromDocument, setEntityProviderId, fetchProviderFromDocument, storeDossier, resetSearchFields,
        setActiveTab, setButtonsHeaderHeight, resetButtonsHeader, setActiveSection, /* setScrollTo, */ setScrollerOptions, /* openErrorMessageModal, */
        setEntityTypeId, setEntitySubTypeId, setEntityId, clearVehicle, fetchReasons, setEntityChildTypeId, setEntityChildSubTypeId, setEntityProviderTypeId, setEntityProviderSubTypeId,
        fetchVoCompaniesCombo, submitSelectVoCompany, clearEntity, clearEntityChild, fetchComboOrigin, fetchComboDestination,
        refreshAuditInfo, fetchPaperworkClient, fetchPaperworkVehicle, resendToProcessingAgency, fetchAuditDetails, fetchAuditDetailsModal, fetchDocumentsGroupedAuditDetails, fetchDocumentsGroupedAuditDetailsModal,
        fetchLowestOrganizedUnits, fecthOrganizedUnitsByDossierUo, setEntityValidationDisable,
        getChangeUo,
        getSalesmanChangeUo,
        postUpdateChangeUo,
        fecthFamily,
        markCommentAsRead,
        paperworkNotify,
        paperworkTransferCompleted,
        clearClientFields,
        fetchCancelCombo,
        fetchClientByRfc,
        checkPrivateRfcFormat,
        foreignPassportWarning,
        fetchCommercialSocietyTypeCombo,
        fetchEntityCountryTypeCombo, fetchEntityStateTypeCombo,
        fetchEntityCityTypeCombo, fetchEntityTownTypeCombo,
        fetchEntityColonyTypeCombo, fetchEntityCPTypeCombo,
        openCommonModal, fetchActivityBpro,
        fetchClientByPhoneEmail, validate, stopAsyncValidation,
        fetchClientDataAddress, fetchProcessingAgencyManagersCombo
      }
    } = this.props

    if (!dossierId) return null

    const tKey = 'DOSSIER_COMPONENTS.'
    const activeStage = checkListHeader.find(stage => stage.isActive)
    const savePaperworkPermission = buttonsPermission.includes('paperwork_save')
    const panelsPaddingTop = statusHeaderHeight + buttonsHeaderHeight - 82 // 82px es el paddingTop definido en el layout.

    return (
      <div className="dossier-paperwork-container">
        <DocumentaryManagementModalPage
          showRelatedDocuments={false}
          dossier={dossier}
          dossierType={dossierSubTypeId}
          readOnlyFields={isMarked}
        />
        <RejectCommentModal
          t={t}
          showModal={this.state.showRejectCommentModal}
          dossierSubTypeId={dossierTypesConstant.paperwork}
          reasonCombo={this.props.combos.reasonCombo}
          actions={{
            rejectAction: (comment, resolve, reject) => paperworkReject(comment, resolve, reject, pristine),
            closeModal: () => this.toggleRejectCommentModal(false),
            fetchReasons: fetchReasons
          }}
        />
        <Col sm={12} className="add-dossier-wrapper dossier">
          <StatusHeaderPage
            headerData={header}
            stages={checkListHeader}
            dossierType={dossierTypesConstant.paperwork}
            dossierId={dossier.dossierId}
            activeTab={activeTab}
            headerTabConfiguration={this.state.headerTabConfiguration}
            pristine={pristine}
            goBack={this.goBack}
            editableDoc={viewConfiguration ? getDocumentaryPermission(viewConfiguration) : true}
            readOnlyFields={isMarked}
          />
        </Col>
        <form autoComplete="off">
          <div className="row-header-buttons">
            <div className="buttons-header-box">
              <ButtonsHeader
                t={t}
                dossierId={dossierId}
                pristine={pristine}
                reset={reset}
                combos={combos.purchaseTypeCombo}
                lowestOrganizedUnitsCombo = {combos.organizedUnitsLegalCif}
                lastAccessDate={dossier.lastAccessDate}
                paperworkTypeId={dossier.dossierPaperworkComponent.paperworkTypeId}
                cancelCombo={combos.cancelCombo}
                reason={reason}
                comment={comment}
                actions={{
                  storeDossier,
                  paperworkReject: () => this.toggleRejectCommentModal(true),
                  paperworkCancel: () => putCancelDossier(dossierId, pristine, reset),
                  paperworkGenDelivery,
                  openDocumentModal: () => openDocumentModal(dossierId, dossierTypesConstant.paperwork, pristine),
                  setActiveTab,
                  setButtonsHeaderHeight,
                  resetButtonsHeader,
                  setScrollerOptions,
                  fetchVoCompaniesCombo,
                  submitSelectVoCompany,
                  resendToProcessingAgency,
                  submitForm: this.handleSubmitForm.bind(this),
                  fetchLowestOrganizedUnits,
                  getChangeUo,
                  getSalesmanChangeUo,
                  postUpdateChangeUo,
                  fecthOrganizedUnitsByDossierUo,
                  paperworkNotify,
                  paperworkTransferCompleted,
                  putCancelDossier,
                  fetchCancelCombo: fetchCancelCombo
                }}
                viewConfiguration={viewConfiguration}
                dossierType={dossierTypesConstant.paperwork}
                activeTab={activeTab}
                marginTop={statusHeaderHeight}
                windowWidth={windowWidth}
                commentUnreadCount={ unreadComments || comments.filter((comment) => { return !comment.read }).length}
                changeUo = {this.props.changeUo}
                organizedUnitId={dossier.organizedUnitId}
                readOnlyFields={isMarked}
                type={dossierTypesConstant.paperwork}
              />
            </div>
          </div>
          {
            activeTab === buttonsHeader.datos_expediente && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden && (
              <Row className="sales-panel dossier-panel">
                <Col sm={3} className="col-row expediente-menu-izq">
                  <ButtonsSidebar
                    t={t}
                    historical={false}
                    handleToUpdateComponent = {this.handleToUpdateComponent.bind(this)}
                    isVehicleLoaded = {this.state.isVehicleLoaded}
                    isClientLoaded = {this.state.isClientLoaded}
                    actions={{ setActiveSection, fetchPaperworkClient, fetchPaperworkVehicle }}
                    dossierType={dossierTypesConstant.paperwork}
                    activeStage={activeStage}
                    marginTop={statusHeaderHeight}
                    buttonsHeaderHeight={buttonsHeaderHeight}
                    tabConfiguration={this.state.activeTabConfiguration}
                    dossierId={dossierId}
                    dossier={dossier}
                    pristine={pristine}
                  />
                </Col>
                <Col sm={9} className="col-row contenido-paneles-expediente">
                  <Col className="panels-wrapper dossier fixed" style={{ paddingTop: panelsPaddingTop }}>
                    {this.state.componentsConfiguration.bpaperwork && this.state.componentsConfiguration.bpaperwork.permission > permissions.hidden && (
                      <Element name={buttonsSidebar.bpaperwork}>
                        <PaperworkComponent
                          t={t}
                          paperworkOpen={this.state.paperworkOpen}
                          combos={combos}
                          actions={{
                            togglePanel: () => this.togglePanel(),
                            setActiveSection,
                            fetchProcessingAgencyManagersCombo
                          }}
                          dossierPaperworkComponent={dossierPaperworkComponent}
                          paperworkTypeId={dossier.dossierPaperworkComponent.paperworkTypeId}
                          dossierParentId={dossierParentId}
                          componentConfiguration={this.state.componentsConfiguration.bpaperwork}
                          readOnlyFields={isMarked}
                          customFields={this.props.dossier.customFieldsByComponent.find(x => x.componentCode === buttonsSidebar.bpaperwork)?.customFields}
                        />
                      </Element>
                    )}
                    {this.state.componentsConfiguration.entity && this.state.componentsConfiguration.entity.permission > permissions.hidden && (
                      <Element name={buttonsSidebar.entity}>
                        <Client
                          t={t}
                          dossier={dossier}
                          combos={combos}
                          entityChangeable={entityChangeable}
                          dossierType={dossierTypesConstant.paperwork}
                          actions={{
                            change,
                            fetchClientByDni,
                            fetchClientByDniEntityChild,
                            fetchEntitySubTypeCombo,
                            fetchEntityFilterSubTypeCombo,
                            openDocumentEditionModal,
                            fetchClientFromDocument,
                            setEntityTypeId,
                            setEntitySubTypeId,
                            setEntityId,
                            setEntityChildTypeId,
                            setEntityChildSubTypeId,
                            clearEntity,
                            clearEntityChild,
                            fetchPaperworkClient,
                            setEntityValidationDisable,
                            setActiveSection,
                            fetchClientByRfc,
                            setEntityProviderTypeId,
                            setEntityProviderSubTypeId,
                            clearClientFields,
                            fetchProviderFromDocument,
                            setEntityProviderId,
                            checkPrivateRfcFormat,
                            foreignPassportWarning,
                            fetchCommercialSocietyTypeCombo,
                            fetchEntityCountryTypeCombo,
                            fetchEntityStateTypeCombo,
                            fetchEntityCityTypeCombo,
                            fetchEntityTownTypeCombo,
                            fetchEntityColonyTypeCombo,
                            fetchEntityCPTypeCombo,
                            openCommonModal,
                            fetchClientByPhoneEmail,
                            fetchActivityBpro,
                            validate,
                            dispatch,
                            asyncValidate,
                            stopAsyncValidation: (value) => stopAsyncValidation('paperwork_dossier', value),
                            fetchClientDataAddress
                          }}
                          readOnlyFields={!savePaperworkPermission || isMarked}
                          handleToUpdateComponent= {this.handleToUpdateComponent.bind(this)}
                          isClientLoaded = {this.state.isClientLoaded}
                          pristine={pristine}
                          open={activeSection === buttonsSidebar.entity}
                          componentConfiguration={this.state.componentsConfiguration.entity}
                          updateComponentAfterDeleteAndRestart={this.updateComponentAfterDeleteAndRestart.bind(this)}
                          change={change}
                          customFields={this.props.dossier.customFieldsByComponent.find(x => x.componentCode === buttonsSidebar.entity)?.customFields}
                          formValues={formValues}
                        />
                      </Element>
                    )}
                    {this.state.componentsConfiguration.vehicle && this.state.componentsConfiguration.vehicle.permission > permissions.hidden && (
                      <Element name={buttonsSidebar.vehicle}>
                        <Vehicle
                          handleToUpdateComponent = {this.handleToUpdateComponent.bind(this)}
                          isVehicleLoaded = {this.state.isVehicleLoaded}
                          pristine={pristine}
                          dossierType={dossierTypesConstant.paperwork}
                          parentDossierType={{
                            isPaperwork: true,
                            isSales: false,
                            isPurchase: false,
                            isCampaign: false,
                            isRegistrationDelivery: false
                          }}
                          t={t}
                          dossierId={dossierId}
                          searchedVehicleProps={vehicleProps}
                          dossierParentId={dossierParentId}
                          dossierParentSubTypeId={dossierParentSubTypeId}
                          dossierWithVehicle={!!vehicleId}
                          combos={combos}
                          actions={{
                            searchVehicle,
                            setSearchDealerNumber,
                            setSearchVinChassis,
                            setSearchLicensePlate,
                            resetSearchFields,
                            setSimpleInputTextValidation,
                            clearVehicle,
                            fetchComboOrigin,
                            fetchComboDestination,
                            fetchPaperworkVehicle,
                            fecthFamily,
                            setActiveSection
                          }}
                          readOnlyFields={!savePaperworkPermission || isMarked}
                          open={activeSection === buttonsSidebar.vehicle}
                          dossierSubType={dossierSubTypeId}
                          formValues={formValues ? formValues.vehicleComponent : {}}
                          componentConfiguration={this.state.componentsConfiguration.vehicle}
                          organizedUnitId={dossier.organizedUnitId}
                          customFields={this.props.dossier.customFieldsByComponent.find(x => x.componentCode === buttonsSidebar.vehicle)?.customFields}
                        />
                      </Element>
                    )}
                  </Col>
                </Col>
              </Row>
            )}
          {
            activeTab === buttonsHeader.gestor_documental && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden && (
              <Row className="sales-panel dossier-panel">
                <Col sm={12} className="col-row" style={{ paddingTop: panelsPaddingTop }}>
                  <DocumentaryManagementPage
                    dossier={dossier}
                    dossierType={dossierTypesConstant.paperwork}
                    formState={this.props.pristine}
                    configuration={this.state.activeTabConfiguration}
                    readOnlyFields={isMarked}
                  />
                </Col>
              </Row>
            )}
          {
            activeTab === buttonsHeader.expedientes_relacionados && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden && (
              <Row className="sales-panel dossier-panel panel-expedientes-relacionados">
                <Col sm={12} className="col-row" style={{ paddingTop: panelsPaddingTop }}>
                  <Col sm={6} className="col-row">
                    <h4><i className="ico-relacion"/> {t(`${tKey}RELATED_DOSSIER.RELATED_DOSSIER`)}</h4>
                  </Col>
                  <Col sm={12} className="col-row">
                    <Table hover responsive className="simple-table dashboard-tasks-table" id="related-dossiers-table">
                      <thead>
                        <tr>
                          <th onClick={this.onClickRelatedDossierColumn.bind(this, 'urgencyType')}>
                            <i className={this.getRelatedDossierColumnIcon('urgencyType')}/>
                          </th>
                          <th onClick={this.onClickRelatedDossierColumn.bind(this, 'number')}>
                            {t(`${tKey}RELATED_DOSSIER.DOSSIER_NUMBER`)} <i className={this.getRelatedDossierColumnIcon('number')}/>
                          </th>
                          <th onClick={this.onClickRelatedDossierColumn.bind(this, 'organizedUnit')}>
                            {t(`${tKey}RELATED_DOSSIER.UO`)} <i className={this.getRelatedDossierColumnIcon('organizedUnit')}/>
                          </th>
                          <th onClick={this.onClickRelatedDossierColumn.bind(this, 'dossierSubType')}>
                            {t(`${tKey}RELATED_DOSSIER.SUBTYPE`)} <i className={this.getRelatedDossierColumnIcon('dossierSubType')}/>
                          </th>
                          <th onClick={this.onClickRelatedDossierColumn.bind(this, 'stage')}>
                            {t(`${tKey}RELATED_DOSSIER.STATE`)} <i className={this.getRelatedDossierColumnIcon('stage')}/>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <RelatedDossierList history={this.props.history} dossierRelatedDossiers={dossierRelatedDossiers} />
                      </tbody>
                    </Table>
                  </Col>
                </Col>
              </Row>
            )}
          {
            activeTab === buttonsHeader.actividad_expediente && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden && (
              <Row className="sales-panel dossier-panel panel-actividad-expediente">
                <Col sm={12} className="col-row" style={{ paddingTop: panelsPaddingTop }}>
                  <AuditInfo
                    auditInfo={auditInfo}
                    dossierType={dossierTypesConstant.sales}
                    actions={{ refreshAuditInfo, fetchAuditDetails, fetchAuditDetailsModal, fetchDocumentsGroupedAuditDetails, fetchDocumentsGroupedAuditDetailsModal }}
                    dossier={dossier}
                    t = {t}
                    readOnlyFields={isMarked}
                  />
                </Col>
              </Row>
            )
          }
          {
            activeTab === buttonsHeader.observaciones && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden && (
              <Row className="sales-panel dossier-panel panel-observaciones-expediente">
                <Col sm={12} className="col-row" style={{ paddingTop: panelsPaddingTop }}>
                  <Col sm={6} className="col-row">
                    <h4><i className="ico-bocadillo" onClick={() => { this.setState({ modalOpen: !this.state.modalOpen }) }}/> {t(`${tKey}COMMENTS.TITLE_PANEL`)}</h4>
                  </Col>
                  <Col sm={12} className="col-row">
                    <CommentComponent
                      comments={comments}
                      actions={{ updateComment, createComment, deleteComment, markCommentAsRead }}
                      readOnlyFields={this.state.activeTabConfiguration.permission !== permissions.editable || isMarked}
                      dossierType="paperworkDossier"
                      t={t}
                      tKey={tKey}
                      combos={{ allReasonCombo: this.props.combos.allReasonCombo }}
                      openComponent={activeTab === buttonsHeader.observaciones}
                      dossierId={this.props.dossier.dossierId}
                    />
                  </Col>
                </Col>
              </Row>
            )}
        </form>
      </div>
    )
  }
}

const validate = (values, props) => {
  const error = {}
  if (values.dossierPaperworkComponent && values.dossierPaperworkComponent.cost) {
    const auxError = validateInteger(values.dossierPaperworkComponent.cost, props)
    if (Object.keys(auxError).length > 0) {
      error.dossierPaperworkComponent = { cost: auxError }
    }
  }
  if (values.vehicleComponent) {
    error.vehicleComponent = validateVehicle(values.vehicleComponent, props)
  }
  if (values.entityComponent && values.entityComponent.updated && !isEntityEmpty(values.entityComponent, props.registeredFields)) {
    error.entityComponent = validateEntity(values.entityComponent, props)
    const contactHasData = values.dossierContactComponent ? !isContactEmpty(values.dossierContactComponent, props.registeredFields) : false
    const entityChildHasData = values.entityChildComponent ? !isEntityChildEmpty(values.entityChildComponent, props.registeredFields) : false
    const isRequiredEntityData = setting.clientFieldValidation === clientInformation.VGR
      ? isRequiredEndCustomerAndContactVGR(props, values)
      : isRequiredEndCustomerAndContact(props)

    if (isRequiredEntityData || entityChildHasData) {
      error.entityChildComponent = validateEntity(values.entityChildComponent, props, null, true)
    }

    if (isRequiredEntityData || contactHasData) {
      error.dossierContactComponent = validateContact(values.dossierContactComponent, props)
    }

    const hasEntityChildErrors = error.entityChildComponent ? Object.keys(error.entityChildComponent).length > 0 : false
    const hasContactErrors = error.dossierContactComponent ? Object.keys(error.dossierContactComponent).length > 0 : false

    if (isRequiredEntityData && (entityChildHasData || contactHasData)) {
      if (hasEntityChildErrors && !entityChildHasData && contactHasData) {
        error.entityChildComponent = {}
      } else if (hasContactErrors && !contactHasData && entityChildHasData) {
        error.dossierContactComponent = {}
      }
    }
  }
  if (values.dossierPaperworkComponent && values.dossierPaperworkComponent.dossierPaperworkAddressComponent) {
    if (!error.dossierPaperworkComponent) {
      error.dossierPaperworkComponent = {}
    }
    error.dossierPaperworkComponent.dossierPaperworkAddressComponent = validatePaperworkAddress(values.dossierPaperworkComponent.dossierPaperworkAddressComponent, props)
  }

  error.customFields = {}
  if (values.customFields && Object.keys(values.customFields).length > 0 && props.dossier.customFieldsByComponent) {
    error.customFields = {}
    let resultCustomFields = {}
    let allCustomFields = []
    props.dossier.customFieldsByComponent.map(x => x.customFields).map(x => {
      allCustomFields = allCustomFields.concat(x)
    })
    Object.keys(values.customFields).forEach(key => {
      const foundCustomField = allCustomFields.find(x => x.code === key)
      if (foundCustomField && foundCustomField.validationFunction) {
        if (foundCustomField.validationFunction && foundCustomField.validationFunction.indexOf('regEx-') !== -1) {
          resultCustomFields = validateRegex(foundCustomField.validationFunction, foundCustomField.customFieldLocales[0].name, values.customFields[key], props)
        } else {
          resultCustomFields = customValidations(foundCustomField.validationFunction, values.customFields[key], props)
        }
        error.customFields[key] = resultCustomFields === Object(resultCustomFields) ? undefined : resultCustomFields
      }
    })
  }

  return error
}

const asyncValidate = (values, dispatch, props, field, returnError) => {
  let promises = []
  if (values.entityComponent && values.entityComponent.updated) {
    promises = promises.concat(asyncValidateEntityDni(values.entityComponent, dispatch, props, props.dossier?.organizedUnitId))
    promises = promises.concat(asyncValidateEntityDni(values.entityChildComponent, dispatch, props, props.dossier?.organizedUnitId))
  }

  return Promise.allSettled(promises).then(result => {
    let error = {}
    for (let index = 0; index < result.length; index++) {
      const element = result[index]
      if (element.status === promisesStatus.FULFILLED) {
        switch (index) {
          case validationPaperworkPromisesOrder.ENTITY_COMPONENT:
            error = { ...error, entityComponent: element.value }
            break
          case validationPaperworkPromisesOrder.ENTITY_CHILD_COMPONENT:
            error = { ...error, entityChildComponent: element.value }
            break
          default:
            break
        }
      }
    }
    if (Object.keys(error).length > 0 && !returnError) throw Object.assign(new Error(), error)
    if (returnError) return error
  })
}

var decoratedComponent = TrackingContainer(Paperwork, false)

export default reduxForm({
  form: 'paperwork_dossier',
  validate,
  asyncValidate,
  asyncBlurFields: [...getFieldsValidated('entityComponent'), ...getFieldsValidated('entityChildComponent')]
})(decoratedComponent)
