
import React, { PureComponent } from 'react'
import { Button, Modal, Col, Row, FormGroup } from 'react-bootstrap'
import { reduxForm, Field } from 'redux-form'
import TextInput from '../commons/TextInput'
import InputCheckbox from '../commons/form/InputCheckBox'
import InputSelect from '../commons/form/InputSelect'

class FinancingTypeModal extends PureComponent {
  constructor () {
    super()
    this.state = { reactivatable: false }
  }

  submitFinancingType (values) {
    const body = []
    Object.keys(values).map(item => {
      body.push(values[item])
    })

    this.props.actions.saveFinancingTypeModal(body, this.props.financingTypeId, this.props.financing.financingCompanyId, values.financingTypeProductId)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.showFinancingTypeModal !== this.props.showFinancingTypeModal && nextProps.showFinancingTypeModal === true) {
      let init = {}
      this.props.initialize({ ...init })
    }
    if (this.props.financingTypeLanguage !== nextProps.financingTypeLanguage && nextProps.financingTypeId !== this.props.financingTypeId || this.props.showFinancingTypeModal !== nextProps.showFinancingTypeModal) {
      nextProps.languageList.map((language) => {
        this.props.change(`lang${language.languageId}.languageId`, language.languageId)
        const source = nextProps.financingTypeLanguage.find((sourceChannel) => sourceChannel.languageId === language.languageId)
        this.props.change(`lang${language.languageId}.name`, source ? source.name : '')
        this.props.change(`lang${language.languageId}.description`, source ? source.description : '')
        this.props.change('organizedUnitId', nextProps.organizedUnitId ? nextProps.organizedUnitId.toString() : '')
        this.props.change('reactivatable', nextProps.reactivatable ? nextProps.reactivatable : false)
        this.props.change('repurchaseAgreement', nextProps.repurchaseAgreement ? nextProps.repurchaseAgreement : false)
        this.props.change('financingTypeProductId', nextProps.financingTypeProductId ? nextProps.financingTypeProductId : null)
      })
    }

    if (this.props.financingTypeId !== nextProps.financingTypeId || nextProps.financingTypeId == null || nextProps.reactivatable === undefined) {
      this.setState({ reactivatable: undefined })
    }
    if (this.props.reactivatable !== nextProps.reactivatable) {
      if (nextProps.reactivatable === null || nextProps.reactivatable === undefined) {
        this.setState({ reactivatable: false })
      } else {
        this.setState({ reactivatable: nextProps.reactivatable })
      }
    }
  }

  checkReactivatable (val) {
    this.setState({ reactivatable: val })
  }

  handleFinancingTypeProduct (event) {

  }

  blurhandleFinancingTypeProduct (value) {
    var type = this.props.financingTypeProducts.find(x => x.financingTypeProductId === value)

    if (type !== null && type !== undefined) {
      this.props.change('repurchaseAgreement', type.reactivatable)
      this.props.change('reactivatable', type.repurchaseAgreement)
    } else {
      this.props.change('repurchaseAgreement', false)
      this.props.change('reactivatable', false)
    }
  }

  componentDidMount () {
    this.props.actions.getAllFinancingTypeProduct(false)
  }

  render () {
    const { t, showFinancingTypeModal, handleSubmit, languageList = [], financing, financingTypeProducts = [] } = this.props
    return (
      <Modal show={showFinancingTypeModal} onHide={this.props.actions.closeFinancingTypeModal} className="languages-modal">
        <Modal.Header closeButton>
          <i className="ico-users" /><Modal.Title>{t('MASTERS.FINANCING_TYPE.FINANCING_TYPE')} - {financing?.name}</Modal.Title>
        </Modal.Header>
        <form autoComplete="off" onSubmit={handleSubmit(this.submitFinancingType.bind(this))}>
          <Modal.Body>
            <Row className="title-column">
              <Col sm={4}>
                <h2>{t('MASTERS.NAME')}</h2>
              </Col>
              <Col sm={6}>
                <h2>{t('MASTERS.DESCRIPTION')}</h2>
              </Col>
              <Col sm={2}>
                <h2>{t('MASTERS.LANGUAGE')}</h2>
              </Col>
            </Row>
            <FormGroup>
              {languageList.map((val, idx) => {
                return (
                  <Row key={idx} className="inputs-wrapper">
                    <Col sm={4} className="custom-width-col">
                      <Field
                        name={`lang${val.languageId}.name`}
                        placeholder={t('MASTERS.NAME')}
                        component={TextInput}
                      />
                    </Col>
                    <Col sm={6} className="custom-width-col">
                      <Field
                        name={`lang${val.languageId}.description`}
                        placeholder={t('MASTERS.DESCRIPTION')}
                        component={TextInput}
                      />
                    </Col>
                    <Col sm={2} className="language-column">
                      <p>{`${val.description} (${val.name})`}</p>
                    </Col>
                  </Row>
                )
              })}
            </FormGroup>
            <h2>{t('MASTERS.FINANCING_TYPE_PRODUCT.FINANCING_TYPE_PRODUCT')}</h2>
            <Row>
              <Col sm={4}>
                <Field
                  name="financingTypeProductId"
                  placeholder={t('MASTERS.FINANCING_TYPE_PRODUCT.FINANCING_TYPE_PRODUCT')}
                  valueKey="financingTypeProductId"
                  labelKey="name"
                  colSm={0}
                  component={InputSelect}
                  options={financingTypeProducts}
                  onBlur = {(value) => this.handleFinancingTypeProduct(value)}
                  onChange ={(value) => this.blurhandleFinancingTypeProduct(value)}
                />
              </Col>
              <Col sm={8}>
                <Field
                  component={InputCheckbox}
                  name={'reactivatable'}
                  id={'reactivatable'}
                  placeholder ={t('MASTERS.FINANCING_TYPE.REACTIVATABLE_INSTORED')}
                  disabled={true}
                />
                <Field
                  component={InputCheckbox}
                  name={'repurchaseAgreement'}
                  id={'repurchaseAgreement'}
                  placeholder ={t('MASTERS.FINANCING_TYPE_PRODUCT.REPURCHASE')}
                  disabled={true}
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Col sm={6} className="btn-wrapper" smPush={6}>
              <Button type="submit" className="btn-standard">
                {t('MASTERS.SAVE')}
              </Button>
            </Col>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}
const validate = (values, props) => {
  const errors = { }
  props.languageList.map(language => {
    errors[`lang${language.languageId}`] = {}
    if (values[`lang${language.languageId}`]) {
      if (!(values[`lang${language.languageId}`].name)) {
        errors[`lang${language.languageId}`].name = props.t('MASTERS.VALIDATION.NAME_REQUIRED')
      }
      if (!(values[`lang${language.languageId}`].description)) {
        errors[`lang${language.languageId}`].description = props.t('MASTERS.VALIDATION.DESCRIPTION_REQUIRED')
      }
    } else {
      errors[`lang${language.languageId}`].name = props.t('MASTERS.VALIDATION.NAME_REQUIRED')
      errors[`lang${language.languageId}`].description = props.t('MASTERS.VALIDATION.DESCRIPTION_REQUIRED')
    }
  })

  if (values) {
    if (!values.financingTypeProductId) {
      errors.financingTypeProductId = props.t('MASTERS.VALIDATION.PRODUCT_TYPE_REQUIRED')
    }
  }

  return errors
}

export default reduxForm({
  form: 'editFinancingType',
  validate
})(FinancingTypeModal)
