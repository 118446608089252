
import React, { PureComponent } from 'react'
import { Button, Modal, Col, Row } from 'react-bootstrap'
import { reduxForm } from 'redux-form'
import EconomicPlan from '../dossiers/sales/EconomicPlan'
import { dossierTypes as dossierTypesConstant } from '../../constants/dossier/common/dossierTypes'
import { getComponentsPermisions } from '../../util/permisionFunctions'
import { buttonsHeader } from '../../constants/dossier/common/buttonsHeader'
import CampaignsModal from '../dossiers/sales/campaings/CampaignsModal'
import { validateDossierEconomic } from '../../util/validationFunctions'
import { formatMoney } from '../../util/utils'
import { saleTypeCodeEnum } from '../../constants/dossier/sale/saleType'
class EconomicPlanModal extends PureComponent {
  constructor () {
    super()
    this.state = {
      disableClient: true,
      sidebarConfiguration: null,
      showCampaignModal: false,
      campaignSelected: {},
      total: 0
    }
  }

  sidebarPermissions () {
    const componentKeys = Object.keys({
      bsale: null,
      entity: null,
      vehicle: null,
      campaign: null,
      uac: null,
      finance: null,
      paperwork: null,
      charge: null,
      other_data: null,
      delivery: null,
      services: null,
      fleet: null,
      self_supply: null
    })
    const newState = getComponentsPermisions(this.props.economicFleetPermissions, buttonsHeader.planteo_economico, componentKeys)
    this.setState({ sidebarConfiguration: newState.activeTabConfiguration })
  }

  componentDidUpdate () {

  }

  getSnapshotBeforeUpdate (prevProps) {
    if (this.props.uoId && this.props.dossierSubtypeId && (prevProps.uoId !== this.props.uoId || prevProps.dossierSubtypeId !== this.props.dossierSubtypeId)) {
      this.props.actions.fetchEconomicPlanCombos(this.props.uoId, this.props.dossierSubtypeId)
      this.props.actions.getEconomicPlanPermissions(this.props.dossierSubtypeId, this.props.uoId)
    }

    if (this.props.dossierIds && JSON.stringify(prevProps.dossierIds) !== JSON.stringify(this.props.dossierIds)) {
      this.setState({
        disableClient: true,
        sidebarConfiguration: null,
        showCampaignModal: false,
        campaignSelected: {}
      })
    }
    return null
  }

  dossiersIsFleet () {
    let dossiers = this.props.dossierData
    let isFleet = false
    this.props.dossiers.forEach(x => {
      let dossier = dossiers.find(d => d.dossierId === x)
      if (dossier && dossier.fleetCode !== null && dossier.fleetCode !== undefined) {
        isFleet = true
      }
    })
    return isFleet
  }

  simulate (values) {
    this.props.actions.simulateEconomicPlanFleet()
  }

  handleToUpdateComponent (component) {

  }

  toggleCampaignModal (value) {
    const priceModel = !isNaN(this.props?.dossierEconomicComponent?.modelPriceAmount) ? this.props.dossierEconomicComponent.modelPriceAmount : 0
    if (!value || (value && priceModel > 0)) {
      this.setState({ showCampaignModal: value })
    } else {
      this.props.actions.openErrorMessageModal('DOSSIER_COMPONENTS.VALIDATIONS.REQUIRED_VEHICLE_PRICE')
    }
  }

  saveEconomicPlan (values, mode) {
    this.setEconomicForCalculate(values.dossierEconomicComponent, this.props.dossierEconomicComponent)
    if (mode === 'save' && this.props.dossierEconomicComponent.simulated) {
      this.updateEconomic()
    } else {
      this.simulate()
    }
  }

  setEconomicForCalculate (values, dossierEconomicComponent) {
    values.associatedCosts = dossierEconomicComponent.associatedCosts
    values.discounts = dossierEconomicComponent.discounts
    values.extraSales = dossierEconomicComponent.extraSales
    values.optionals = dossierEconomicComponent.optionals
    values.presents = dossierEconomicComponent.presents
    values.supplieds = dossierEconomicComponent.supplieds
    return values
  }

  createCampaign (values) {
    this.props.actions.addCampaign(values)
    this.toggleCampaignModal(false)
  }

  updateEconomic () {
    let dossierList = this.props.dossierIds.map(x => { return x.dossierId })
    this.props.actions.setEconomicPlanFleetProcess(dossierList, () => { this.props.actions.closeEconomicPlanModal(); this.props.fetchFleets(this.props.fleetsFilter) })
  }

  getEconomicDataUpdate () {
    this.props.actions.setSimulatedFlag(false)
  }

  checkKeyDown (e) {
    if (e.key === 'Enter') e.preventDefault()
  }

  getCodeComissionSaleType () {
    const { dossierIds } = this.props
    if (dossierIds === null || dossierIds === undefined || dossierIds.length === 0) {
      return false
    }

    for (let i = 0; i < dossierIds.length; i++) {
      let saleCode = dossierIds[i].saleCode
      if (saleCode !== saleTypeCodeEnum.AGENCY_MODEL_FLEET_WAGEN && saleCode !== saleTypeCodeEnum.AGENCY_MODEL_FLEET_SEAT && saleCode !== saleTypeCodeEnum.AGENCY_MODEL_PART_WAGEN && saleCode !== saleTypeCodeEnum.AGENCY_MODEL_PART_SEAT) {
        return false
      }
    }

    return true
  }

  render () {
    const {
      t,
      showModal, handleSubmit, combos, dossierEconomicComponent, change, economicFleetPermissions, editedCampaign, campaignPermissions, dossierIds,
      actions: {
        updateDiscount,
        createDiscount,
        deleteDiscount,
        updateOptional,
        createOptional,
        deleteOptional,
        updateComment,
        createComment,
        deleteComment,
        fetchCampaignEdition,
        updateCampaign,
        createCampaign,
        updateSupplied,
        createSupplied,
        deleteSupplied,
        updateExtraSale,
        createExtraSale,
        deleteExtraSale,
        deletePresent,
        updatePresent,
        createPresent,
        setActiveSection,
        openProcessingAgencyModal,
        openCommonModal,
        fetchSalesEconomic,
        updateAssociatedCosts,
        createAssociatedCosts,
        deleteAssociatedCosts,
        fetchDiscountModal,
        fetchAssociatedCostByPercentageModal,
        fetchProcessingAgencyModal,
        fetchAmountTaxModal,
        printDocumentaryWarranty,
        deleteEconomicCampaign,
        openCodeCommissionModal,
        changeCodeCommissionFleet
      }
    } = this.props
    const tKey = 'MANAGEMENT.FLEETS_TAB.'
    const title = this.props.create ? t(`${tKey}TITLE_ADD_FLEET`) : t(`${tKey}TITLE_EDIT_FLEET`)

    let combosCampaing = JSON.parse(JSON.stringify(combos))
    combosCampaing.dossierSubTypesCombo = combosCampaing.dossierSubTypesComboCampaign

    let totalAmountWithOptionals = (!isNaN(dossierEconomicComponent.modelPriceAmount) ? dossierEconomicComponent.modelPriceAmount : 0) + (!isNaN(dossierEconomicComponent.optionalTotalAmount) ? dossierEconomicComponent.optionalTotalAmount : 0)

    let totalBill = dossierEconomicComponent.billTotalAmount ? dossierEconomicComponent.billTotalAmount : 0

    let organizedUnits = dossierIds ? dossierIds.map(x => x.organizedUnitId) : []

    return (
      <>
        <CampaignsModal
          t={t}
          editedCampaign= {editedCampaign}
          showModal={this.state.showCampaignModal}
          campaignSelected={this.state.campaignSelected}
          vehicleAmount={totalAmountWithOptionals}
          combos={combosCampaing}
          componentConfiguration={campaignPermissions}
          actions={{
            closeModal: () => this.toggleCampaignModal(false),
            updateCampaign,
            createCampaign: (values) => this.createCampaign(values)
          }}
          secondClass = 'secondModal'
        />
        <Modal className="role-modal languages-modal fleetEconomic-modal" show={showModal} onHide={this.props.actions.closeEconomicPlanModal} backdrop={'static'}>
          <form autoComplete="off" onSubmit={handleSubmit((values) => this.saveEconomicPlan(values, 'save'))} onKeyPress={(e) => this.checkKeyDown(e)}>
            <Modal.Header closeButton onHide={this.props.actions.closeEconomicPlanModal}>
              <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>

              <EconomicPlan
                t={t}
                pristine={true}
                activeTab={buttonsHeader.planteo_economico}
                dossierEconomicComponent={dossierEconomicComponent}
                actions={{
                  updateDiscount,
                  createDiscount,
                  deleteDiscount,
                  updateOptional,
                  createOptional,
                  deleteOptional,
                  updateComment,
                  createComment,
                  deleteComment,
                  fetchCampaignEdition,
                  deleteEconomicCampaign,
                  updateCampaign,
                  createCampaign,
                  updateSupplied,
                  createSupplied,
                  deleteSupplied,
                  updateExtraSale,
                  createExtraSale,
                  deleteExtraSale,
                  deletePresent,
                  updatePresent,
                  createPresent,
                  setActiveSection,
                  openProcessingAgencyModal,
                  change,
                  openCommonModal,
                  fetchSalesEconomic,
                  updateAssociatedCosts,
                  createAssociatedCosts,
                  deleteAssociatedCosts,
                  fetchDiscountModal,
                  fetchAssociatedCostByPercentageModal,
                  fetchProcessingAgencyModal,
                  fetchAmountTaxModal,
                  printDocumentaryWarranty,
                  getEconomicDataUpdate: () => this.getEconomicDataUpdate(),
                  openCodeCommissionModal,
                  changeCodeCommission: changeCodeCommissionFleet
                }}
                readOnlyFields={false}
                economicTab={true}
                isFixed={true}
                dossierType={dossierTypesConstant.fleetEconomicPlan}
                activeStage={null}
                combos={combos}
                panelsPaddingTop={1}
                tabConfiguration={this.state.sidebarConfiguration}
                tabConfiguration2={economicFleetPermissions}
                historical = {false}
                isEconomicLoaded = {false}
                handleToUpdateComponent = {this.handleToUpdateComponent.bind(this)}
                dossierId={-1}
                activeSection={this.props.activeSection}
                dossierSubTypeId={this.props.dossierSubtypeId}
                codeCommissionSaleTypeShow = {this.getCodeComissionSaleType()}
                organizedUnits={organizedUnits}
              />
            </Modal.Body>
            <Modal.Footer>
              <Row className='row-calculate'>
                <Col sm={2}>
                  <Button
                    className="btn-standard"
                    onClick={() => this.toggleCampaignModal(true)} >
                    {t(`${tKey}ADD_CAMPAIGN`)}
                  </Button>
                </Col>
                <Col sm={2}>
                  <Button
                    className="btn-standard"
                    onClick={handleSubmit((values) => this.saveEconomicPlan(values, 'simulate'))}>
                    {t(`${tKey}SIMULATE`)}
                  </Button>
                </Col>
                <Col sm={4} className="col-total">
                  <div><span className="total-import-left">{t('DOSSIER_COMPONENTS.TOTALS.TOTAL_BILL')}</span><span className="total-import-right span-bill">{formatMoney(totalBill)}</span></div>
                </Col>
              </Row>
              <Row className='row-save'>
                <Col sm={6} className='btn-wrapper'>
                  <Button
                    className="btn-standard"
                    type="submit"
                    disabled={!this.props.dossierEconomicComponent.simulated}>
                    {t(`${tKey}SAVE`)}
                  </Button>
                </Col>
                <Col sm={6} className="col-save">
                  <Button
                    id="role-modal-submit-button"
                    className="btn-standard"
                    onClick = {this.props.actions.closeEconomicPlanModal}>
                    {t(`${tKey}CANCEL`)}
                  </Button>
                </Col>

              </Row>

            </Modal.Footer>
          </form>
        </Modal>
      </>
    )
  }
}
const validate = (values, { t }, props) => {
  let errors = {}

  errors.dossierEconomicComponent = validateDossierEconomic(values.dossierEconomicComponent, t)
  return errors
}

export default reduxForm({
  form: 'economicPlanModal',
  validate

})(EconomicPlanModal)
