export default {
  FINANCING: 'DOSSIER_COMPONENTS.FINANCE.TITLE_PANEL',
  TYPEFINANCING: 'DOSSIER_COMPONENTS.FINANCE.FINANCE_TYPE',
  FINANCINGTYPEID: 'DOSSIER_COMPONENTS.FINANCE.FINANCE_TYPE',
  FINANCINGAMOUNT: 'DOSSIER_COMPONENTS.FINANCE.AMOUNT',
  FINANCINGBONUSAMOUNT: 'DOSSIER_COMPONENTS.FINANCE.BONUS_AMOUNT',
  FINANCINGCOMMISSION: 'DOSSIER_COMPONENTS.FINANCE.FINANCING_COMISSION',
  FINANCINGCOMPANYID: 'DOSSIER_COMPONENTS.FINANCE.COMPANY',
  FINANCINGFIRSTINSTALMENTDATE: 'DOSSIER_COMPONENTS.FINANCE.FIRST_INSTALMENT_DATE',
  FINANCINGINSTALMENTS: 'DOSSIER_COMPONENTS.FINANCE.INSTALMENTS',
  FINANCINGKM: 'DOSSIER_COMPONENTS.FINANCE.KM',
  FINANCINGSTATUSID: 'DOSSIER_COMPONENTS.FINANCE.FINANCING_STATUS',
  TIN: 'DOSSIER_COMPONENTS.FINANCE.TIN',
  DELIVERY: 'DOSSIER_COMPONENTS.DELIVERY.TITLE_PANEL',
  RECEPTION: 'DOSSIER_COMPONENTS.DELIVERY.RECIVE_TITLE_PANEL',
  REALDELIVERYADDRESS: 'DOSSIER_COMPONENTS.DELIVERY.ADDRESS',
  DELIVERYCITY: 'DOSSIER_COMPONENTS.DELIVERY.DELIVERY_CITY',
  REALDELIVERYDATETIME: 'DOSSIER_COMPONENTS.DELIVERY.DATE_DELIVERY',
  KM: 'DOSSIER_COMPONENTS.DELIVERY.KM',
  REALDELIVERYLOCATIONID: 'DOSSIER_COMPONENTS.DELIVERY.REAL_DELIVERY_LOCATION_ID',
  LASTITVDATE: 'DOSSIER_COMPONENTS.DELIVERY.LAST_ITV_DATE',
  CONTACT: 'DOSSIER_COMPONENTS.ENTITY.CONTACT',
  CONTACTNAME: 'DOSSIER_COMPONENTS.ENTITY.NAME',
  CONTACTSURNAME1: 'DOSSIER_COMPONENTS.ENTITY.SURNAME1',
  CONTACTSURNAME2: 'DOSSIER_COMPONENTS.ENTITY.SURNAME1',
  CONTACTEMAIL: 'DOSSIER_COMPONENTS.ENTITY.EMAIL',
  CONTACTPHONE: 'DOSSIER_COMPONENTS.ENTITY.PHONE',
  CONTACTMOBILEPHONE: 'DOSSIER_COMPONENTS.ENTITY.MOBILE',
  CONTACTADDRESS: 'DOSSIER_COMPONENTS.ENTITY.ADDRESS',
  CONTACTCITY: 'DOSSIER_COMPONENTS.ENTITY.CITY',
  CONTACTSTATE: 'DOSSIER_COMPONENTS.ENTITY.STATE',
  CONTACTCOUNTRY: 'DOSSIER_COMPONENTS.ENTITY.COUNTRY',
  CONTACTCP: 'DOSSIER_COMPONENTS.ENTITY.CP',
  ENTITY: 'DOSSIER_COMPONENTS.ENTITY.CLIENT',
  VENDOR: 'DOSSIER_COMPONENTS.ENTITY.VENDOR',
  ENTITYID: 'DOSSIER_COMPONENTS.ENTITY.ENTITYID',
  ENTITYTYPEID: 'DOSSIER_COMPONENTS.ENTITY.ENTITY_TYPE',
  DNICIF: 'DOSSIER_COMPONENTS.ENTITY.DNI',
  NAME: 'DOSSIER_COMPONENTS.ENTITY.NAME',
  SURNAME1: 'DOSSIER_COMPONENTS.ENTITY.SURNAME1',
  SURNAME2: 'DOSSIER_COMPONENTS.ENTITY.SURNAME2',
  PHONE: 'DOSSIER_COMPONENTS.ENTITY.PHONE',
  MOBILEPHONE: 'DOSSIER_COMPONENTS.ENTITY.MOBILE',
  EMAIL: 'DOSSIER_COMPONENTS.ENTITY.EMAIL',
  CONTACTCOUNTRYCODE: 'DOSSIER_COMPONENTS.ENTITY.COUNTRY',
  CONTACTSTATECODE: 'DOSSIER_COMPONENTS.ENTITY.STATE',
  CONTACTCITYCODE: 'DOSSIER_COMPONENTS.ENTITY.CITY',
  CONTACTTOWNCODE: 'DOSSIER_COMPONENTS.ENTITY.TOWN',
  CONTACTCOLONYCODE: 'DOSSIER_COMPONENTS.ENTITY.COLONY',
  CONTACTPOSTALCODECODE: 'DOSSIER_COMPONENTS.ENTITY.CP',
  CONTACTSTARTSTREET: 'DOSSIER_COMPONENTS.ENTITY.START_STREET',
  CONTACTENNDSTREET: 'DOSSIER_COMPONENTS.ENTITY.ENND_STREET',
  CONTACTINTERNALNUMBER: 'DOSSIER_COMPONENTS.ENTITY.INTERNAL_NUMBER',
  CONTACTEXTERNALNUMBER: 'DOSSIER_COMPONENTS.ENTITY.EXTERNAL_NUMBER',
  ADDRESS: 'DOSSIER_COMPONENTS.ENTITY.ADDRESS',
  CITY: 'DOSSIER_COMPONENTS.ENTITY.CITY',
  CP: 'DOSSIER_COMPONENTS.ENTITY.CP',
  STATE: 'DOSSIER_COMPONENTS.ENTITY.STATE',
  COUNTRY: 'DOSSIER_COMPONENTS.ENTITY.COUNTRY',
  REPRESENTATIVEDNI: 'DOSSIER_COMPONENTS.ENTITY.REPRESENTATIVE_DNI',
  REPRESENTATIVENAME: 'DOSSIER_COMPONENTS.ENTITY.REPRESENTATIVE_NAME',
  REPRESENTATIVESURNAME1: 'DOSSIER_COMPONENTS.ENTITY.REPRESENTATIVE_SURNAME1',
  REPRESENTATIVESURNAME2: 'DOSSIER_COMPONENTS.ENTITY.REPRESENTATIVE_SURNAME2',
  COUNTRYCODE: 'DOSSIER_COMPONENTS.ENTITY.COUNTRY',
  STATECODE: 'DOSSIER_COMPONENTS.ENTITY.STATE',
  CITYCODE: 'DOSSIER_COMPONENTS.ENTITY.CITY',
  TOWNCODE: 'DOSSIER_COMPONENTS.ENTITY.TOWN',
  COLONYCODE: 'DOSSIER_COMPONENTS.ENTITY.COLONY',
  POSTALCODECODE: 'DOSSIER_COMPONENTS.ENTITY.CP',
  STARTSTREET: 'DOSSIER_COMPONENTS.ENTITY.START_STREET',
  ENNDSTREET: 'DOSSIER_COMPONENTS.ENTITY.ENND_STREET',
  INTERNALNUMBER: 'DOSSIER_COMPONENTS.ENTITY.INTERNAL_NUMBER',
  EXTERNALNUMBER: 'DOSSIER_COMPONENTS.ENTITY.EXTERNAL_NUMBER',
  CLIENTNUMBER: 'DOSSIER_COMPONENTS.ENTITY.MAIN_CLIENT_NUMBER',
  SEGMENTATIONID: 'DOSSIER_COMPONENTS.ENTITY.SEGMENTATION',
  IAETYPEID: 'DOSSIER_COMPONENTS.ENTITY.IAE_TYPE',
  ENTITY_VENDOR: 'DOSSIER_COMPONENTS.ENTITY.ENTITY_VENDOR',
  ENTITY_BUYER: 'DOSSIER_COMPONENTS.ENTITY.ENTITY_BUYER',
  VEHICLE: 'DOSSIER_COMPONENTS.VEHICLE.TITLE',
  VEHICLEID: 'DOSSIER_COMPONENTS.VEHICLE.VEHICLEID',
  FUELID: 'DOSSIER_COMPONENTS.VEHICLE.FUEL',
  LICENSEPLATE: 'DOSSIER_COMPONENTS.VEHICLE.LICENSE_PLATE',
  BRANDID: 'DOSSIER_COMPONENTS.VEHICLE.BRAND',
  MODEL: 'DOSSIER_COMPONENTS.VEHICLE.MODEL',
  VINCHASSIS: 'DOSSIER_COMPONENTS.VEHICLE.CHASSIS',
  COMERCIALCODE: 'DOSSIER_COMPONENTS.VEHICLE.COMMERCIAL_CODE',
  TAPESTRY: 'DOSSIER_COMPONENTS.VEHICLE.TAPESTRY',
  COLOR: 'DOSSIER_COMPONENTS.VEHICLE.COLOR',
  EXPIRATIONITVDATE: 'DOSSIER_COMPONENTS.VEHICLE.EXPIRATION_ITV_DATE',
  ORIGINID: 'DOSSIER_COMPONENTS.VEHICLE.ORIGIN_CLASIFICATION',
  DESTINATIONID: 'DOSSIER_COMPONENTS.VEHICLE.DESTINATION_CLASIFICATION',
  FIRSTLICENSEPLATEDATE: 'DOSSIER_COMPONENTS.VEHICLE.FIRST_LPLATE_DATE',
  VEHICLETYPEID: 'DOSSIER_COMPONENTS.OTHER_DATA.VEHICLE_TYPE',
  OTHERDATA: 'DOSSIER_COMPONENTS.OTHER_DATA.TITLE_PANEL',
  SALE: 'DOSSIER_COMPONENTS.SALES.SALES',
  SALETYPEID: 'DOSSIER_COMPONENTS.SALES.SALES_TYPE',
  OPERATIONTYPEIDS: 'DOSSIER_COMPONENTS.SALES.OPERATION_TYPE',
  SALESMANID: 'DOSSIER_COMPONENTS.SALES.SALESMAN',
  SALESMANIDMANAGER: 'DOSSIER_COMPONENTS.SALES.SALESMAN_MANAGER',
  ADDITIONALINFOIDS: 'DOSSIER_COMPONENTS.SALES.ADDITIONAL_INFO',
  ORDERDATE: 'DOSSIER_COMPONENTS.SALES.ORDER_DATE',
  EXPECTEDDELIVERYDATE: 'DOSSIER_COMPONENTS.SALES.EXPECTED_DELIVERY_DATE',
  PAYMENTMETHODID: 'DOSSIER_COMPONENTS.SALES.PAYMENT_FORM',
  SIGNALPAYNOTREQUIRED: 'DOSSIER_COMPONENTS.SALES.SIGNAL_PAY_NOT_REQUIRED',
  LOPDNOTREQUIRED: 'DOSSIER_COMPONENTS.SALES.LOPD_NOT_REQUIRED',
  PRODUCTIONWEEK: 'DOSSIER_COMPONENTS.SALES.PRODUCTION_WEEK',
  MONTHSGUARANTEEEXTENSION: 'DOSSIER_COMPONENTS.SALES.MONTH_GUARANTEE_EXTENSION',
  PURCHASE: 'DOSSIER_COMPONENTS.PURCHASE_DOSSIER.PURCHASE_COMPONENT.TITLE',
  MAKERNUMBER: 'DOSSIER_COMPONENTS.PURCHASE_DOSSIER.PURCHASE_COMPONENT.MAKER_NUMBER',
  DEALERNUMBER: 'DOSSIER_COMPONENTS.PURCHASE_DOSSIER.PURCHASE_COMPONENT.DEALER_NUMBER',
  PURCHASETYPEID: 'DOSSIER_COMPONENTS.PURCHASE_DOSSIER.PURCHASE_COMPONENT.PURCHASE_TYPE',
  UACDESTINATIONID: 'DOSSIER_COMPONENTS.PURCHASE_DOSSIER.PURCHASE_COMPONENT.DESTINATION',
  TERMICWINDOW: 'DOSSIER_COMPONENTS.PURCHASE_DOSSIER.PURCHASE_COMPONENT.TERMIC_WINDOW',
  PURCHASESALEENTITYID: 'DOSSIER_COMPONENTS.PURCHASE_DOSSIER.PURCHASE_COMPONENT.BUYING_SELLING_ENTITY',
  AVAILABILITYDATE: 'DOSSIER_COMPONENTS.PURCHASE_DOSSIER.PURCHASE_COMPONENT.AVAILABILITY_DATE',
  VEHICLELOCATION: 'DOSSIER_COMPONENTS.PURCHASE_DOSSIER.PURCHASE_COMPONENT.VEHICLE_LOCATION',
  ISPARTOFPAYMENT: 'DOSSIER_COMPONENTS.UAC.PAYMENT_INCLUDED',
  NOTTRAFFICREPORT: 'DOSSIER_COMPONENTS.PURCHASE_DOSSIER.PURCHASE_COMPONENT.NOT_TRAFFIC_REPORT',
  PURCHASESOURCE: 'DOSSIER_COMPONENTS.PURCHASE_DOSSIER.PURCHASE_COMPONENT.PURCHASE_SOURCE',
  DOSSIERKM: 'DOSSIER_COMPONENTS.PURCHASE_DOSSIER.PURCHASE_COMPONENT.DOSSIER_KM',
  PAPERWORK: 'DOSSIER_COMPONENTS.PAPERWORK_DOSSIER.PAPERWORK_COMPONENT.TITLE',
  PAPERWORKTYPEID: 'DOSSIER_COMPONENTS.PAPERWORK_DOSSIER.PAPERWORK_COMPONENT.PAPERWORK_TYPE',
  PROCESSINGAGENCYID: 'DOSSIER_COMPONENTS.PAPERWORK_DOSSIER.PAPERWORK_COMPONENT.PROCESSING_AGENCY',
  PWKCOST: 'DOSSIER_COMPONENTS.PAPERWORK_DOSSIER.PAPERWORK_COMPONENT.COST',
  PWKAMOUNT: 'DOSSIER_COMPONENTS.PAPERWORK_DOSSIER.PAPERWORK_COMPONENT.AMOUNT',
  PAPERWORKDELIVERYDATE: 'DOSSIER_COMPONENTS.PAPERWORK_DOSSIER.PAPERWORK_COMPONENT.DELIVERY_DATE',
  PAPERWORKRECEIPTDATE: 'DOSSIER_COMPONENTS.PAPERWORK_DOSSIER.PAPERWORK_COMPONENT.RECEIPT_DATE',
  TOBEREVIEWED: 'DOSSIER_COMPONENTS.PAPERWORK_DOSSIER.PAPERWORK_COMPONENT.TO_BE_REVIEWED',
  CAMPAIGN: 'DOSSIER_COMPONENTS.CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.TITLE',
  CAMPAIGNTYPEID: 'DOSSIER_COMPONENTS.CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.CAMPAIGN_TYPE',
  APPROVALDATE: 'DOSSIER_COMPONENTS.CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.APPROVAL_DATE',
  EXPIRATIONDATE: 'DOSSIER_COMPONENTS.CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.EXPIRATION_DATE',
  CMPCOST: 'DOSSIER_COMPONENTS.CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.COST',
  COSTCONCESSION: 'DOSSIER_COMPONENTS.CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.COST_CONCESSION',
  CMPAMOUNT: 'DOSSIER_COMPONENTS.CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.AMOUNT',
  BRANDREFERENCE: 'DOSSIER_COMPONENTS.VEHICLE.BRAND_REFERENCE',
  SALEECONOMIC: 'DOSSIER_COMPONENTS.ECONOMIC_PLAN.TITLE_PANEL',
  IVATYPEID: 'DOSSIER_COMPONENTS.OTHER_DATA.IVA_TYPE_ID',
  IEMTYPEID: 'DOSSIER_COMPONENTS.ECONOMIC_PLAN.MODAL_PROCESSING_AGENCY.SELECTED_IEM',
  MODELPRICEAMOUNT: 'DOSSIER_COMPONENTS.ECONOMIC_PLAN.MODEL_PRICE_AMOUNT',
  MODELPRICECOST: 'DOSSIER_COMPONENTS.VEHICLE_AMOUNT.MODEL_PRICE_COST',
  AMOUNT: 'DOSSIER_COMPONENTS.VEHICLE_AMOUNT.AMOUNT',
  PURCHASEECONOMIC: 'DOSSIER_COMPONENTS.PURCHASE_ECONOMIC.TITLE',
  TRANSPORTATIONAMOUNT: 'DOSSIER_COMPONENTS.VEHICLE_AMOUNT.TRANSPORT',
  TRANSPORTATIONCOST: 'DOSSIER_COMPONENTS.VEHICLE_AMOUNT.TRANSPORT_COST',
  REQUIREDDATE: 'DOSSIER_COMPONENTS.SERVICE_DOSSIER.SERVICE_COMPONENT.REQUIRED_DATE',
  CONFIRMEDDATE: 'DOSSIER_COMPONENTS.SERVICE_DOSSIER.SERVICE_COMPONENT.CONFIRMED_DATE',
  PLACE: 'DOSSIER_COMPONENTS.SERVICE_DOSSIER.SERVICE_COMPONENT.PLACE',
  AVAILABITYDATE: 'DOSSIER_COMPONENTS.SERVICE_DOSSIER.SERVICE_COMPONENT.AVAILABITY_DATE',
  ORNUMBER: 'DOSSIER_COMPONENTS.SERVICE_DOSSIER.SERVICE_COMPONENT.ORNUMBER',
  ORDERDATESERVICE: 'DOSSIER_COMPONENTS.SERVICE_DOSSIER.SERVICE_COMPONENT.ORDER_DATE',
  BILLINGNUMBER: 'DOSSIER_COMPONENTS.SERVICE_DOSSIER.SERVICE_COMPONENT.BILLING_NUMBER',
  BILLINGDATE: 'DOSSIER_COMPONENTS.SERVICE_DOSSIER.SERVICE_COMPONENT.BILLING_DATE',
  SERVICEAMOUNT: 'DOSSIER_COMPONENTS.SERVICE_DOSSIER.SERVICE_COMPONENT.SERVICE_AMOUNT',
  SALEOBSERVATIONS: 'DOSSIER_COMPONENTS.SERVICE_DOSSIER.SERVICE_COMPONENT.SALE_OBSERVATIONS',
  PROVIDEROBSERVATIONS: 'DOSSIER_COMPONENTS.SERVICE_DOSSIER.SERVICE_COMPONENT.PROVIDER_OBSERVATIONS',
  SERVICE: 'DOSSIER_COMPONENTS.SERVICE_DOSSIER.SERVICE_COMPONENT.TITLE',
  ENTITYNAME: 'DOSSIER_COMPONENTS.SERVICE_DOSSIER.SERVICE_COMPONENT.ENTITY',
  CANCELDOMAINRESERVATIONNOTREQUIRED: 'DOSSIER_COMPONENTS.PURCHASE_DOSSIER.PURCHASE_COMPONENT.CANCEL_DOSSIER_RESERVATION_NOT_REQUIRED',
  FLEETID: 'DOSSIER_COMPONENTS.FLEET.FLEETID',
  FLEET: 'DOSSIER_COMPONENTS.FLEET.FLEET',
  FLEETASSIGNMENTDATE: 'DOSSIER_COMPONENTS.FLEET.FLEET_ASSIGNMENT_DATE',
  PAYMENTMETHODPURCHASEID: 'DOSSIER_COMPONENTS.OTHER_DATA.PAYMENT_METHOD',
  ADDITIONALCLAUSES: 'DOSSIER_COMPONENTS.OTHER_DATA.ADDITIONAL_CLAUSES',
  LATERUSEID: 'DOSSIER_COMPONENTS.OTHER_DATA.LATER_USE',
  PREVIOUSUSAGEID: 'DOSSIER_COMPONENTS.UAC.MODAL.VEHICLE.PREVIOUS_USAGE_ID',
  ACTIVITYCLIENTID: 'DOSSIER_COMPONENTS.OTHER_DATA.ACTIVITY_CLIENT',
  SELFSUPPLY: 'DOSSIER_COMPONENTS.SELFSUPPLY.TITLE_PANEL',
  ORGANIZEDUNITID: 'DOSSIER_COMPONENTS.SELFSUPPLY.CONCESSIONAIRE',
  DEPARTMENTID: 'DOSSIER_COMPONENTS.SELFSUPPLY.DEPARTMENT',
  COMMENTS: 'DOSSIER_COMPONENTS.SELFSUPPLY.COMMENTS',
  EXCHANGEVEHICLE: 'DOSSIER_COMPONENTS.SELFSUPPLY.EXCHANGE_VEHICLE',
  DEMOCOUNT: 'DOSSIER_COMPONENTS.SELFSUPPLY.DEMO_COUNT',
  GOAL: 'DOSSIER_COMPONENTS.SELFSUPPLY.GOAL',
  SALESPRICE: 'DOSSIER_COMPONENTS.SELFSUPPLY.SALES_PRICE',
  MONTHS: 'DOSSIER_COMPONENTS.SELFSUPPLY.MONTHS',
  KILOMETERS: 'DOSSIER_COMPONENTS.SELFSUPPLY.KILOMETERS',
  DEPRECIATIONAMOUNT: 'DOSSIER_COMPONENTS.SELFSUPPLY.DEPRECIATION_AMOUNT',
  DEPRECIATIONPERCENTAGE: 'DOSSIER_COMPONENTS.SELFSUPPLY.DEPRECIATION_PERCENTAGE',
  INPUTPRICEBASEDONSALESPRICE: 'DOSSIER_COMPONENTS.SELFSUPPLY.INPUT_PRICE_BASED_ON_SALES_PRICE',
  ESTIMATEDSALESPRICE: 'DOSSIER_COMPONENTS.SELFSUPPLY.ESTIMATED_SALES_PRICE',
  AVERAGEMARGIN: 'DOSSIER_COMPONENTS.SELFSUPPLY.AVERAGE_MARGIN',
  INPUTPRICEBASEDONESTIMATEDSALESPRICE: 'DOSSIER_COMPONENTS.SELFSUPPLY.INPUT_PRICE_BASED_ON_ESTIMATED_SALES_PRICE',
  NOTCONTRACTPURCHASEVEHICLE: 'DOSSIER_COMPONENTS.PURCHASE_DOSSIER.PURCHASE_COMPONENT.NOT_CONTRACT_PURCHASE_VEHICLE',
  EXTERNALPAPERWORK: 'DOSSIER_COMPONENTS.PAPERWORK.EXTERNAL_PAPERWORK',
  FAMILY: 'DOSSIER_COMPONENTS.VEHICLE.FAMILY',
  REFINANCE: 'DOSSIER_COMPONENTS.FINANCE.TITLE_PANEL_REFINANCE',
  RENOVATION: 'DOSSIER_COMPONENTS.FINANCE.RENOVATION',
  ENTRY: 'DOSSIER_COMPONENTS.FINANCE.ENTRY',
  RESIDUALVALUE: 'DOSSIER_COMPONENTS.FINANCE.RESIDUAL_VALUE',
  OPENINGCOMMISSION: 'DOSSIER_COMPONENTS.FINANCE.OPENING_COMMISSION',
  MONTHLYFEE: 'DOSSIER_COMPONENTS.FINANCE.MONTHLY_FEE',
  APR: 'DOSSIER_COMPONENTS.FINANCE.APR',
  MAINTENANCEINCLUDED: 'DOSSIER_COMPONENTS.FINANCE.MAINTENANCE_INCLUDED',
  INSURANCEINCLUDED: 'DOSSIER_COMPONENTS.FINANCE.INSURANCE_INCLUDED',
  LASTDNICIF: 'DOSSIER_COMPONENTS.FINANCE.LAST_DNI_CIF',
  FINANCINGTYPEPRODUCTID: 'DOSSIER_COMPONENTS.FINANCE.FINANCING_TYPE_PRODUCT',
  DOSSIERECONOMIC: 'DOSSIER_COMPONENTS.ECONOMIC_PLAN.DOSSIERECONOMIC',
  OPTIONALSAMOUNT: 'DOSSIER_COMPONENTS.ECONOMIC_PLAN.OPTIONALS_AMOUNT',
  SAMEENTITY: 'DOSSIER_COMPONENTS.UAC.MODAL.UAC_CLIENT.CLIENT_IS_SAME',
  AMORTIZATION: 'DOSSIER_COMPONENTS.SERVICE_DOSSIER.SERVICE_COMPONENT.AMORTIZATION',
  RECONDITIONING: 'DOSSIER_COMPONENTS.ECONOMIC_PLAN.POPOVER_PRICE_COST.RECONDITIONING',
  ORIGINALPRICE: 'DOSSIER_COMPONENTS.ECONOMIC_PLAN.POPOVER_PRICE_COST.ORIGINAL_PRICE',
  EXTERNALORDERID: 'DOSSIER_COMPONENTS.SALES.EXTERNAL_ORDER_ID',
  STARTDATE: 'DOSSIER_COMPONENTS.BROKERAGE.STARTDATE',
  POLICYNUMBER: 'DOSSIER_COMPONENTS.BROKERAGE.POLICYNUMBER',
  COMPANYNAME: 'DOSSIER_COMPONENTS.BROKERAGE.COMPANYNAME',
  INSURANCECARRIERID: 'DOSSIER_COMPONENTS.BROKERAGE.INSURANCECARRIER',
  INSURANCECARRIERAUDITDETAILS: 'DOSSIER_COMPONENTS.BROKERAGE.TITLE_PANEL',
  ENTITYCHILD: 'DOSSIER_COMPONENTS.ENTITY.FINAL_CLIENT',
  RENTALORDERNUMBER: 'DOSSIER_COMPONENTS.SALES.RENTALORDER_NUMBER',
  SELFSUPPLYRELATEDDOSSIERS: 'DOSSIER_COMPONENTS.SELFSUPPLY.SELFSUPPLY_RELATED_DOSSIERS',
  CURRENCYID: 'DOSSIER_COMPONENTS.SALES.CURRENCY_ID',
  REPORTABLELLD: 'DOSSIER_COMPONENTS.SALES.REPORTABLE_LLD',
  INSURANCESTARTDATE: 'DOSSIER_COMPONENTS.DELIVERY.INSURANCE_START_DATE',
  OPERATIONTYPEDMSID: 'DOSSIER_COMPONENTS.SALES.OPERATION_TYPE_DMS',
  ACTIVITYBPROID: 'DOSSIER_COMPONENTS.ENTITY.ACTIVITY_BPRO',
  BIRTHDAYDATE: 'DOSSIER_COMPONENTS.ENTITY.BIRTHDAY_DATE',
  CONSTITUTIONACT: 'DOSSIER_COMPONENTS.ENTITY.CONSTITUTION_ACT',
  CONSTITUTIONDATE: 'DOSSIER_COMPONENTS.ENTITY.CONSTITUTION_DATE',
  RFC: 'DOSSIER_COMPONENTS.ENTITY.RFC',
  STOCKVO: 'DOSSIER_COMPONENTS.ENTRY.TITLE_PANEL',
  REPAIRSHOPID: 'DOSSIER_COMPONENTS.ENTRY.REPAIRSHOP',
  INITIALSTAGEID: 'DOSSIER_COMPONENTS.ENTRY.INITIAL_STAGE',
  INDICATEPHOTOS: 'DOSSIER_COMPONENTS.ENTRY.INDICATE_PHOTOS',
  PUBLISHPHOTOS: 'DOSSIER_COMPONENTS.ENTRY.PUBLISH_VEHICLE',
  REQUESTSTAGEID: 'DOSSIER_COMPONENTS.ENTRY.REQUEST_STAGE',
  FASTLINE: 'DOSSIER_COMPONENTS.ENTRY.FASTLINE',
  DOSSIERRECEPTION: 'DOSSIER_COMPONENTS.RECEPTION.TITLE_PANEL',
  PREWASH: 'DOSSIER_COMPONENTS.RECEPTION.PREWASH',
  NOTLOJACK: 'DOSSIER_COMPONENTS.SALES.NOT_LOJACK',
  CHECKAUTOWARRANTY: 'DOSSIER_COMPONENTS.SALES.AUTOWARRANTY',
  RESCUELEASING: 'DOSSIER_COMPONENTS.UAC.MODAL.UAC_CLIENT.RESCUE_LEASING',
  ADDITIONALINVOICE: 'DOSSIER_COMPONENTS.UAC.MODAL.UAC_CLIENT.ADDITIONAL_INVOICE',
  DELIVERYDATE: 'DOSSIER_COMPONENTS.DOSSIER_BUDGET.DELIVERY_DATE',
  COMMENT: 'DOSSIER_COMPONENTS.DOSSIER_BUDGET.COMMENT',
  CUSTOMFIELDSAUDITDETAILS: 'DOSSIER_COMPONENTS.CUSTOM_FIELDS.TITLE_PANEL',
  FREELANCE: 'DOSSIER_COMPONENTS.ENTITY.FREELANCE',
  CRANE: 'DOSSIER_COMPONENTS.CRANE.TITLE_PANEL',
  NEEDCRANE: 'DOSSIER_COMPONENTS.CRANE.NEED_CRANE',
  DESTINATIONCRANEID: 'DOSSIER_COMPONENTS.CRANE.SEND_TO',
  BOOKINGANDDELIVERY: 'DOSSIER_COMPONENTS.BOOKING_AND_DELIVERY.TITLE_PANEL',
  BOOKINGDATE: 'DOSSIER_COMPONENTS.BOOKING_AND_DELIVERY.BOOKING_DATE',
  ANTICIPATEDDATE: 'DOSSIER_COMPONENTS.BOOKING_AND_DELIVERY.ANTICIPATED_DATE',
  DOSSIERPRICECHANGE: 'DOSSIER_COMPONENTS.PRICE_CHANGE.TITLE_PANEL',
  LASTPRICECHANGEDATE: 'DOSSIER_COMPONENTS.PRICE_CHANGE.LAST_PRICE_CHANGE',
  DOSSIERPHOTOS: 'DOSSIER_COMPONENTS.PHOTOGRAPHS.TITLE_PANEL',
  RESERVEDVEHICLE: 'DOSSIER_COMPONENTS.PHOTOGRAPHS.RERSERVED_VEHICLE',
  MARITALSTATUSID: 'DOSSIER_COMPONENTS.ENTITY.MARITAL_STATUS',
  CAMPAIGNSBLOCKED: 'DOSSIER_COMPONENTS.CAMPAIGNS.CAMPAIGNS_BLOCKED',
  EXTERNALDELIVERY: 'DOSSIER_COMPONENTS.DELIVERY.EXTERNAL_DELIVERY',
  PURCHASESTATE: 'DOSSIER_COMPONENTS.PURCHASE_DOSSIER.PURCHASE_COMPONENT.PURCHASE_STATE',
  CUSTOMERNEEDDATE: 'DOSSIER_COMPONENTS.SALES.CUSTOMER_NEED_DATE',
  SHIPPINGORDERDATE: 'DOSSIER_COMPONENTS.SALES.SHIPPING_ORDER_DATE',
  CAMPAIGNOUTPUTDATE: 'DOSSIER_COMPONENTS.SALES.CAMPAIGN_OUTPUT_DATE',
  DESTINATIONARRIVALDATE: 'DOSSIER_COMPONENTS.SALES.DESTINATION_ARRIVAL_DATE',
  BRANDDISCHARGEDATE: 'DOSSIER_COMPONENTS.SALES.BRAND_DISCHARGE_DATE',
  COMMISSIONCANCELLATIONCODEID: 'MASTERS.CODE_COMMISSION.CODE_COMMISSION',
  ADDITIONALAMOUNT: 'DOSSIER_COMPONENTS.CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.ADDITIONAL_AMOUNT',
  ADDITIONALPERCENTAGE: 'DOSSIER_COMPONENTS.CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.ADDITIONAL_PERCENTAGE'
}
