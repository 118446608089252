import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import CampaignModalDocument from '../../../components/masters/campaign/CampaingModalDocument'
import { fetchConditionDocumentalCampaign, deleteConditionDocumentalCampaign, getDocumentsConditionDocumentalcampaign, addConditionDocumentalCampaign } from '../../../actions/conditionsMaintenance/conditionsMaintenance'
import { closeEditDocumentalConditionModalSuccess, openEditDocumentalConditionModalEditSuccess } from '../../../actions/masters/masters'

export function mapStateToProps (state) {
  const campaignModal = state.campaign.campaignModal
  return {
    showModal: campaignModal.showModalDocuments,
    campaign: campaignModal.campaign,
    languageSelected: campaignModal.languageSelected,
    ...state.conditionsMaintenance
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchConditionDocumentalCampaign,
      deleteConditionDocumentalCampaign,
      closeEditDocumentalConditionModalSuccess,
      getDocumentsConditionDocumentalcampaign,
      addConditionDocumentalCampaign,
      openEditDocumentalConditionModalEditSuccess
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(CampaignModalDocument))
