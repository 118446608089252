import React, { PureComponent } from 'react'
import { Form, Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Field, reduxForm, reset/*, resetSection */ } from 'redux-form'
import { validateDate, validateDates } from '../../../util/validationFunctions'
import SimpleTablePage from '../../../containers/commons/general/SimpleTablePage'
import IDocCarPagination from '../../../components/commons/iDocCarPagination'
import { Link } from 'react-router-dom'
import { formatMoney, fromCamelToUnderscore, getDateNowMaxDatePicker } from '../../../util/utils'
import settings from '../../../setting'
import { clientInformation, backEndIdDocumentStatus } from '../../../constants/backendIds'
import MapSectionsManagement from '../../../_v2/util/map-sections-management'
import InputText from '../../components/commons/form/InputText'
import InputMoney from '../../components/commons/form/InputMoney'
import InputSelect from '../../components/commons/form/InputSelect'
import InputDatePicker from '../../components/commons/form/InputDatePicker'

class SearchByChargeForm extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      fields: {
      },
      fieldsConfiguration: {
      },
      filterValues: {},
      maxiTable: false,
      hideFilters: false
    }
  }

  validateDate (value, allValues, props) {
    const result = validateDate(value, props)
    return result === Object(result) ? undefined : result
  }

  exportData () {
    const tKey = 'SEARCH.CATEGORIES.CHARGE.'
    let columnsExcel = []

    columnsExcel.push(this.props.t(`${tKey}DOSSIER_NUMBER`) + '#Number')
    columnsExcel.push(this.props.t(`${tKey}ENTITY`) + '#Entity')
    columnsExcel.push(this.props.t(`${tKey}CLIENT_NUMBER`) + '#ClientNumber')
    columnsExcel.push(this.props.t(`${tKey}SALESMAN`) + '#Salesman')
    columnsExcel.push(this.props.t(`${tKey}AMOUNT`) + '#Amount')
    if (settings.clientFieldValidation === clientInformation.VGR) {
      columnsExcel.push(this.props.t(`${tKey}BANK`) + '#Bank')
    }
    columnsExcel.push(this.props.t(`${tKey}CHARGE_DATE`) + '#ChargeDate')
    columnsExcel.push(this.props.t(`${tKey}DOCUMENT_STATUS`) + '#DocumentStatus')

    this.exportToExcel(columnsExcel)
  }

  componentDidMount () {
    this.props.actions.fetchBankCombo()
    this.props.actions.fetchDocumentStatusCombo()
    if (this.state && (!settings.dontFilterCharges)) {
      this.props.change('charge.documentStatus', backEndIdDocumentStatus.INCORPORATED)
    }
  }

  extractMultipleCombo (combo, filters, item, filter) {
    const values = combo.filter(object => {
      return filters[item][filter] ? filters[item][filter].some(selectedComboValue => object.id === selectedComboValue) : null
    })

    let value = values && values.map((combovalue, index) => combovalue.value)
      .join(', ')
    return value
  }

  selectedFilters () {
    const filters = this.props.dynamicFilters
    const tKey = 'SEARCH.CATEGORIES.'
    return Object.keys(filters)
      .map((item) => {
        let text = item
        let value = null
        const list = []

        Object.keys(filters[item]).map((filter, index) => {
          text = this.props.t(`${tKey}${item.toUpperCase()}.${fromCamelToUnderscore(filter).toUpperCase()}`)
          switch (filter) {
            case 'bank': {
              const bank = this.props.combos.banksCombo.find(b => b.id === filters[item][filter])
              value = bank && bank.value
              break }
            case 'documentStatus': {
              const documentStatus = this.props.combos.documentStatusCombo.find(b => b.id === filters[item][filter])
              value = documentStatus && documentStatus.value
              break }
            case 'salesman': {
              value = this.extractMultipleCombo(this.props.combos.salesmanCombo, filters, item, filter)
              break }
            case 'amount': { value = formatMoney(filters[item][filter])
              break }
            default:
            { value = filters[item][filter]
              break }
          }

          if (value) {
            if (filter !== 'entityChildSelected') {
              list.push(<li key={index}>{text}: <strong>{value}</strong>;</li>)
            } else {
              list.push(<li key={index}> <strong>{text}</strong>;</li>)
            }
          }
        })
        return list
      })
  }

  submitSearchForm (values) {
    var allEmptyFieldOrNoValues = this.standardValidations(values)
    if (allEmptyFieldOrNoValues) {
      return this.props.actions.openModal('mustFillFilters')
    }
    this.props.actions.fetchCharges({ ...values.charge, ...this.props.filters })

    this.setState({ hideFilters: true, dossierIdToArray: [], dynamicFilters: values, filterValues: { ...values.charge, ...this.props.filters } })
    this.props.actions.setFilters(values)
  }

  onClickTaskListRow (documentId, documentTypeId, documentParentTypeId, objectId, organizedUnitId) {
    const callback = () => {
      if (this.props.filters) {
        this.props.actions.fetchCharges(this.state.filterValues)
      }
    }

    this.props.actions.openDocumentEditionModal(documentId, documentTypeId, documentParentTypeId, objectId, objectId, objectId,
      organizedUnitId, null, callback, true, '', '')
  }

  getIconClass (field) {
    if (this.props.filters.orderBy.includes(field)) {
      return this.props.filters.orderBy.includes('-') ? 'ico-sort-selected-asc' : 'ico-sort-selected-desc'
    } else {
      return 'ico-sort'
    }
  }

  onOrderChange (e) {
    const newOrderBy = this.props.filters.orderBy.includes(`%2B${e.currentTarget.dataset.field}`) ? `-${e.currentTarget.dataset.field}` : `%2B${e.currentTarget.dataset.field}`
    this.setState({ filterValues: { ...this.state.filterValues, orderBy: newOrderBy } })
    this.props.actions.fetchCharges({ ...this.state.filterValues, orderBy: newOrderBy })
  }

  onPageChange (page) {
    this.setState({ filterValues: { ...this.state.filterValues, page: page } })
    this.props.actions.fetchCharges({ ...this.state.filterValues, page })
  }

  exportToExcel (data) {
    let cols = ''
    data.forEach(element => {
      cols += element + '$'
    })
    cols = cols.substring(0, cols.length - 1)
    let values = this.props.formValues
    this.props.actions.fetchChargesDownload({ ...values.charge, ...this.props.filters }, cols)
  }

  standardValidations (values) {
    return !values.charge || !Object.keys(values.charge).filter(item => values.charge[item] !== null).length
  }

  onClickResetSearch (e) {
    if (e !== undefined) {
      e.preventDefault()
    }

    this.props.actions.resetFilters()
    this.props.actions.resetManagementFleetsFilter()
    this.setState({ checkAll: false, dossierIdToArray: [], dossierData: [] })
  }

  handleClickReset (e) {
    if (e !== undefined) {
      e.preventDefault()
    }

    this.props.dispatch(reset('searchCharge'))
    this.state.dossierIdToArray = []
    this.setState({ onlyFleets: true, checkAll: false, dossierIdToArray: [], dossierData: [], fields: [] })
  }

  toogleHideFilters () {
    this.setState({ hideFilters: !this.state.hideFilters })
  }

  toogleMaxiTable () {
    this.setState({ maxiTable: !this.state.maxiTable })
  }

  showFilter (hasSearch) {
    return !hasSearch || !this.state.hideFilters
  }

  toggleFilterClass (hasSearch) {
    if (!hasSearch) {
      return 'iDocIcon-filter-hidden'
    }
    if (this.state.hideFilters) {
      return 'iDocIcon-filter-off'
    }
    return 'iDocIcon-filter'
  }

  hideFiltersBtn (hasResults) {
    if (!hasResults) {
      return 'hide'
    }
    return ''
  }

  render () {
    const {
      t, fields, fieldFill, className, classNameSelect, combos, hasSearch, chargeData,
      pages, count,
      handleSubmit, // From search
      filters: { page },
      combos: { documentStatusCombo }
    } = this.props
    const secMapManagement = MapSectionsManagement.find(function (s) { return s.page === 'searchCharge' })
    const tKey = 'SEARCH.CATEGORIES.CHARGE.'
    const hasResults = chargeData && chargeData.length > 0

    return (<>
      <div className="inner-results-panel">
        <div className="section-results-header">
          <div className="section-header-title">
            <OverlayTrigger placement="right" overlay={<Tooltip id="editProductTooltip">{`${t((this.props.hideMenu ? 'GLOBAL.TABLES.SHOW_INNER_MENU' : 'GLOBAL.TABLES.HIDE_INNER_MENU'))}`}</Tooltip>}>
              <a onClick={() => this.props.toogleInnerMenu()} className={this.props.hideMenu ? 'collapseBtn' : 'collapseBtn closeInnerMenu'}><i className={this.props.hideMenu ? 'iDocIcon-Open-inner-menu' : 'iDocIcon-Close-inner-menu'}></i></a>
            </OverlayTrigger>
            <h4>{t(`${secMapManagement.title}`)}
              <OverlayTrigger placement="left" overlay={<Tooltip id="editProductTooltip">{`${t((this.state.hideFilters ? 'GLOBAL.TABLES.SHOW_FILTERS' : 'GLOBAL.TABLES.HIDE_FILTERS'))}`}</Tooltip>}>
                <a onClick={() => this.toogleHideFilters()} className={'toogleShowFilters ' + this.hideFiltersBtn(hasResults)}><i className={this.toggleFilterClass(hasResults)}></i></a>
              </OverlayTrigger>
            </h4>
          </div>
          <Form autoComplete="off" onSubmit={handleSubmit((values) => this.submitSearchForm(values))} className="filters">
            <div className={'search-form ' + (this.showFilter(hasResults) ? '' : 'hideFilters ')}>
              <Field
                component={InputText}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                id="clientNameCharge"
                name='charge.name'
                placeholder={t(`${tKey}NAME`)}
                controlLabel={t(`${tKey}NAME`)}
                type="text"
                onInputChange={(val) => fieldFill({ clientName: val })}
                customClass={fields.clientName ? className : ''}
              />
              <Field
                component={InputText}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                id="clientNumberCharge"
                name='charge.clientNumber'
                placeholder={t(`${tKey}CLIENT_NUMBER`)}
                controlLabel={t(`${tKey}CLIENT_NUMBER`)}
                type="text"
                onInputChange={(val) => fieldFill({ clientNumber: val })}
                customClass={fields.clientNumber ? className : ''}
              />
              <Field
                component={InputSelect}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                id="salesman"
                name='charge.salesman'
                placeholder={t(`${tKey}SALESMAN`)}
                controlLabel={t(`${tKey}SALESMAN`)}
                multi={true}
                options={combos.salesmanCombo}
                valueKey="id"
                labelKey="value"
                onInputChange={(val) => fieldFill({ salesman: val })}
                customClass={fields.salesman ? classNameSelect : ''}
              />
              <Field
                component={InputMoney}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                id="amount"
                name='charge.amount'
                placeholder={t(`${tKey}AMOUNT`)}
                controlLabel={t(`${tKey}AMOUNT`)}
                type="text"
                onInputChange={(val) => fieldFill({ amount: val })}
                customClass={fields.amount ? className : ''}
              />
              { settings.clientFieldValidation === clientInformation.VGR &&
                <Field
                  component={InputSelect}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="bank"
                  name='charge.bank'
                  placeholder={t(`${tKey}BANK`)}
                  controlLabel={t(`${tKey}BANK`)}
                  options={combos.banksCombo}
                  valueKey="id"
                  labelKey="value"
                  onInputChange={(val) => fieldFill({ bank: val })}
                  customClass={fields.bank ? classNameSelect : ''}
                />
              }
              <Field
                component={InputDatePicker}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                id="chargeDateFrom"
                name='charge.chargeDateFrom'
                placeholder={t(`${tKey}CHARGE_DATE_FROM`)}
                controlLabel={t(`${tKey}CHARGE_DATE_FROM`)}
                validate={[this.validateDate]}
                onInputChange={(val) => fieldFill({ chargeDateFrom: val })}
                customClass={fields.chargeDateFrom ? className : ''}
                maxDate={getDateNowMaxDatePicker()}
              />
              <Field
                component={InputDatePicker}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                id="chargeDateTo"
                name='charge.chargeDateTo'
                placeholder={t(`${tKey}CHARGE_DATE_TO`)}
                controlLabel={t(`${tKey}CHARGE_DATE_TO`)}
                validate={[this.validateDate]}
                onInputChange={(val) => fieldFill({ chargeDateTo: val })}
                customClass={fields.chargeDateTo ? className : ''}
                maxDate={getDateNowMaxDatePicker()}
              />
              <Field
                component={InputSelect}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                id="documentStatus"
                name='charge.documentStatus'
                placeholder={t(`${tKey}DOCUMENT_STATUS`)}
                controlLabel={t(`${tKey}DOCUMENT_STATUS`)}
                valueKey="id"
                labelKey="value"
                options={documentStatusCombo}
                onInputChange={(val) => fieldFill({ documentStatusId: val })}
              />
              <div className='search-form-actions flexInLine al-R'>
                <Button id="btn_search"
                  type="submit"
                  className="_Btn accept stk">
                  <i className="iDocIcon-search"/>
                  {t('SEARCH.ACTIONS.SEARCH_BUTTON')}
                </Button>
                <Button
                  id="btn_reset"
                  onClick={(e) => this.handleClickReset(e)}
                  className={'_Btn' + (this.props.pristine ? ' accept' : ' accept')}
                  disabled={this.props.pristine}>
                  <i className="ico-cancel"/> {t('SEARCH.ACTIONS.RESET_BUTTON')}
                </Button>
              </div>
            </div>
          </Form>
        </div>
        <div className="section-results-body">
          <div className={'searchResoultsContainer' + (this.state.maxiTable ? ' maxiTable' : '')}>
            {hasSearch && ([
              <div className="table-wrapper" key="table-wrapper">
                <ul className="list-inline pull-left">
                  {this.selectedFilters().length === 0 ? <li>{t(`${tKey}NO_SELECTED_FILTERS`)}</li> : (this.selectedFilters())}
                </ul>
                {hasResults && [

                  <div className='flexInLine al-R'>
                    <OverlayTrigger placement="left" overlay={<Tooltip id="editProductTooltip">{`${t('GLOBAL.TABLES.' + (this.state.maxiTable ? 'MINIMIZE_TABLE' : 'MAXIMIZE_TABLE'))}`}</Tooltip>}>
                      <a onClick={() => this.toogleMaxiTable()} className='_Btn accept stk o-icn maximizeBtn'><i className={(this.state.maxiTable ? 'iDocIcon-Minimize' : 'iDocIcon-Maximize')}></i></a>
                    </OverlayTrigger>
                    <OverlayTrigger placement="left" overlay={<Tooltip id="editProductTooltip">{t('SEARCH.ACTIONS.EXPORT')}</Tooltip>}>
                      <a onClick={(event) => this.exportData(event)} className='_Btn new o-icn maximizeBtn'><i className="iDocIcon-file-excel"></i></a>
                    </OverlayTrigger>
                  </div>,

                  <div className="table-responsive">
                    <SimpleTablePage
                      columns={[
                        <th key={1} data-field="number" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}DOSSIER_NUMBER`)}&nbsp;<i className={this.getIconClass('number')}/>
                        </th>,
                        <th key={2} data-field="entity" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}ENTITY`)}&nbsp;<i className={this.getIconClass('entity')}/>
                        </th>,
                        <th key={3} data-field="clientNumber" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}CLIENT_NUMBER`)}&nbsp;<i className={this.getIconClass('organizedUnit')}/>
                        </th>,
                        <th key={4} data-field="salesman" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}SALESMAN`)}&nbsp;<i className={this.getIconClass('salesman')}/>
                        </th>,
                        <th key={5} data-field="amount" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}AMOUNT`)}&nbsp;<i className={this.getIconClass('amount')}/>
                        </th>,
                        settings.clientFieldValidation === clientInformation.VGR && (
                          <th key={6} data-field="bank" onClick={(event) => this.onOrderChange(event)}>
                            {t(`${tKey}BANK`)}&nbsp;<i className={this.getIconClass('bank')}/>
                          </th>
                        ),
                        <th key={7} data-field="chargeDate" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}CHARGE_DATE`)}&nbsp;<i className={this.getIconClass('chargeDate')}/>
                        </th>,
                        <th key={8} data-field="documentStatus" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}DOCUMENT_STATUS`)}&nbsp;<i className={this.getIconClass('documentStatus')}/>
                        </th>,
                        <th key={9} data-field="documentStatus" className='navigation '>
                          {t('MANAGEMENT.FLEETS_TAB.ACTIONS')}
                        </th>
                      ]}
                      rows={chargeData && chargeData.map((row, idx) => (
                        <tr key={idx}>
                          <td>
                            <Link to={`/dossier${row.urlToNavigateDossier}`}>
                              {row.dossierNumber}
                            </Link>
                          </td>
                          <td>{row.entityName}</td>
                          <td>{row.clientNumber}</td>
                          <td>{this.props.combos.salesmanCombo && this.props.combos.salesmanCombo.filter(salesman => salesman.id === row.salesmanId)[0] && this.props.combos.salesmanCombo.filter(salesman => salesman.id === row.salesmanId)[0].value}</td>
                          <td>{formatMoney(row.amount)}</td>
                          {settings.clientFieldValidation === clientInformation.VGR && (
                            <td>{this.props.combos.banksCombo && this.props.combos.banksCombo.filter(bank => parseInt(bank.id) === row.bankId)[0] && this.props.combos.banksCombo.filter(bank => parseInt(bank.id) === row.bankId)[0].value}</td>
                          )}
                          <td>{row.chargeDate}</td>
                          <td>{row.documentStatus}</td>
                          <td className="navigation">
                            {(row.urlToNavigateDocument || (row.documentId)) &&
                          <OverlayTrigger placement="left" overlay={<Tooltip id="urgenciaTooltip">{t('SEARCH.CATEGORIES.TOOLTIP.GO_TO')}</Tooltip>}>
                            <a class="_Btn accept stk o-icn" onClick={() => this.onClickTaskListRow(row.documentId, row.documentTypeUseId, row.documentParentTypeId, row.objectId, row.organizedUnitId)}><i class="iDocIcon-open-in-new-black"></i></a>
                          </OverlayTrigger>
                            }
                          </td>
                        </tr>
                      ))}
                    />
                  </div>,
                  <div className="search-footer" key="search-footer">
                    <IDocCarPagination
                      id="btn_pag_sales"
                      page={page}
                      pagesCount={pages}
                      totalRows= {count}
                      onSelectAction={(page) => this.onPageChange(page)}
                    />
                  </div>]}
                {!hasResults && <p className="no_results">{t('GLOBAL.TABLES.NO_RESULTS')}</p>}
              </div>
            ])}
          </div>
        </div>
      </div>
      <div>
      </div>
    </>
    )
  }
}

const validate = (values, { t }) => {
  const errors = {}
  if (values) {
    if (values.dossier && values.dossier.chassis && values.dossier.chassis.length < settings.chassisMinCharacter) {
      errors.dossier = {}
      errors.dossier.chassis = t('SEARCH.CATEGORIES.ERRORS.VINCHASIS').replace('##MIN_CHARACTERS##', settings.chassisMinCharacter)
    }
    if (values.vehicle && values.vehicle.chassis && values.vehicle.chassis.length < settings.chassisMinCharacter) {
      errors.vehicle = {}
      errors.vehicle.chassis = t('SEARCH.CATEGORIES.ERRORS.VINCHASIS').replace('##MIN_CHARACTERS##', settings.chassisMinCharacter)
    }

    if (values.dossier) {
      errors.dossier = {}
      if (values.dossier && values.dossier.creationDateFrom && values.dossier.creationDateTo) {
        errors.dossier.creationDateTo = validateDates(t, values.dossier.creationDateFrom, values.dossier.creationDateTo)
      }
      if (values.dossier && values.dossier.orderDateFrom && values.dossier.orderDateTo) {
        errors.dossier.orderDateTo = validateDates(t, values.dossier.orderDateFrom, values.dossier.orderDateTo)
      }
      if (values.dossier && values.dossier.availabilityDateFrom && values.dossier.availabilityDateTo) {
        errors.dossier.availabilityDateTo = validateDates(t, values.dossier.availabilityDateFrom, values.dossier.availabilityDateTo)
      }
      if (values.dossier && values.dossier.vehicleLicensePlateDateFrom && values.dossier.vehicleLicensePlateDateTo) {
        errors.dossier.vehicleLicensePlateDateTo = validateDates(t, values.dossier.vehicleLicensePlateDateFrom, values.dossier.vehicleLicensePlateDateTo)
      }
    }

    if (values.charge && values.charge.chargeDateFrom && values.charge.chargeDateTo) {
      errors.charge = {}
      errors.charge.chargeDateTo = validateDates(t, values.charge.chargeDateFrom, values.charge.chargeDateTo)
    }
  }
  return errors
}

export default reduxForm({
  form: 'SearchByCharge',
  validate
})(SearchByChargeForm)
