import apiFetch from '../apiFetch'

export default function (token, { orderBy, number, approvalDateFrom, approvalDateTo, organizedUnit }, { checkAll, toExport, notExport }) {
  const queryParamsArray = [
    orderBy ? `orderBy=${orderBy}` : null,
    number ? `number=${number}` : null,
    approvalDateFrom ? `approvalDateFrom=${approvalDateFrom}` : null,
    approvalDateTo ? `approvalDateTo=${approvalDateTo}` : null,
    organizedUnit ? `organizedUnitId=${organizedUnit}` : null,
    checkAll ? `checkAll=${checkAll}` : null,
    toExport ? `toExport=${toExport.join('$')}` : null,
    notExport ? `notExport=${notExport.join('$')}` : null
  ]
  const queryParams = queryParamsArray.reduce(
    (result, query, idx) => result + (query ? (result ? `&${query}` : `?${query}`) : '')
    , ''
  )
  return apiFetch({
    endPoint: `DossierFinder/CampaignExportFinderExportFileDownload${queryParams}`,
    method: 'GET',
    body: null,
    token: token,
    parseBlobAndAssign: true
  })
}
