export default {
  OPEN_DOCUMENT_HISTORICAL_CREATION_MODAL: 'OPEN_DOCUMENT_HISTORICAL_CREATION_MODAL',
  OPEN_DOCUMENT_HISTORICAL_CREATION_MODAL_SUCCESS: 'OPEN_DOCUMENT_HISTORICAL_CREATION_MODAL_SUCCESS',
  OPEN_DOCUMENT_HISTORICAL_EDITION_MODAL: 'OPEN_DOCUMENT_HISTORICAL_EDITION_MODAL',
  OPEN_DOCUMENT_HISTORICAL_EDITION_MODAL_SUCCESS: 'OPEN_DOCUMENT_HISTORICAL_EDITION_MODAL_SUCCESS',
  FETCH_ADD_OR_EDIT_DOCUMENT_HISTORICAL: 'FETCH_ADD_OR_EDIT_DOCUMENT_HISTORICAL',
  ON_DOCUMENT_HISTORICAL_TYPE_ID_CHANGE: 'ON_DOCUMENT_HISTORICAL_TYPE_ID_CHANGE',
  FETCH_ADD_OR_EDIT_DOCUMENT_HISTORICAL_SUCCESS: 'FETCH_ADD_OR_EDIT_DOCUMENT_HISTORICAL_SUCCESS',
  CLOSE_ADD_OR_EDIT_DOCUMENT_HISTORICAL_MODAL: 'CLOSE_ADD_OR_EDIT_DOCUMENT_HISTORICAL_MODAL',
  CLOSE_ADD_OR_EDIT_DOCUMENT_HISTORICAL_MODAL_SUCCESS: 'CLOSE_ADD_OR_EDIT_DOCUMENT_HISTORICAL_MODAL_SUCCESS',
  FETCH_DOCUMENT_HISTORICAL: 'FETCH_DOCUMENT_HISTORICAL',
  FETCH_DOCUMENT_HISTORICAL_SUCCESS: 'FETCH_DOCUMENT_HISTORICAL_SUCCESS',
  UPLOAD_DOCUMENT_HISTORICAL: 'UPLOAD_DOCUMENT_HISTORICAL',
  UPLOAD_NEW_VERSION_HISTORICAL: 'UPLOAD_NEW_VERSION_HISTORICAL',
  CREATE_DOCUMENT_HISTORICAL: 'CREATE_DOCUMENT_HISTORICAL',
  TOGGLE_FULL_SCREEN_DOCUMENT_HISTORICAL_MODAL: 'TOGGLE_FULL_SCREEN_DOCUMENT_HISTORICAL_MODAL',
  TOGGLE_FULL_SCREEN_DOCUMENT_HISTORICAL_MODAL_SUCCESS: 'TOGGLE_FULL_SCREEN_DOCUMENT_HISTORICAL_MODAL_SUCCESS',
  REJECT_DOCUMENT_HISTORICAL: 'REJECT_DOCUMENT_HISTORICAL',
  VALIDATE_DOCUMENT_HISTORICAL: 'VALIDATE_DOCUMENT_HISTORICAL',
  SAVE_DOCUMENT_HISTORICAL: 'SAVE_DOCUMENT_HISTORICAL',
  COPY_META_TO_ENTITY_VALUES: 'COPY_META_TO_ENTITY_VALUES',
  CHANGE_ID_HISTORICAL: 'CHANGE_ID_HISTORICAL',
  RECOVER_DOCUMENT_HISTORICAL: 'RECOVER_DOCUMENT_HISTORICAL',
  CREATE_DOCUMENT_SHEET_HISTORICAL: 'CREATE_DOCUMENT_SHEET_HISTORICAL',
  DELETE_DOCUMENT_SHEET_HISTORICAL: 'DELETE_DOCUMENT_SHEET_HISTORICAL',
  SET_ACTIVE_PAGE_HISTORICAL: 'SET_ACTIVE_PAGE_HISTORICAL',
  TOGGLE_FULL_SCREEN_DOCUMENT_SHEET_HISTORICAL_MODAL: 'TOGGLE_FULL_SCREEN_DOCUMENT_SHEET_HISTORICAL_MODAL'

}
