import mastersActionTypes from '../../../constants/actions/masters/masters'

function initialState () {
  return {
    signatureConfigList: [],
    signatureConfigCount: 0,
    pagesCount: 0,
    filter: {
      page: 1,
      orderBy: '',
      pageSize: 10
    },
    signatureConfigModal: {
      signatureConfig: null,
      showModal: false
    },
    signatureEntityModal: {
      signatureEntity: null,
      showModal: false
    }
  }
}

function fetchSignatureConfigListSuccess (state, { signatureConfigList, filter }) {
  return {
    ...state,
    signatureConfigList: signatureConfigList.signatureConfigurationList,
    signatureConfigCount: signatureConfigList.signatureConfigurationCount,
    pagesCount: signatureConfigList.pagesCount,
    filter
  }
}

function openSignatureConfigurationModalSuccess (state, { signatureConfig }) {
  const initial = initialState()
  return {
    ...state,
    signatureConfigModal: {
      ...initial.signatureConfigModal,
      signatureConfig: signatureConfig,
      showModal: true
    }
  }
}

function closeSignatureConfigurationModalSuccess (state) {
  return {
    ...state,
    signatureConfigModal: {
      ...initialState().signatureConfigModal
    }
  }
}

function openSignatureEntityModalSuccess (state, { signatureEntity }) {
  const initial = initialState()
  return {
    ...state,
    signatureEntityModal: {
      ...initial.signatureEntityModal,
      signatureEntity,
      showModal: true
    }
  }
}

function closeSignatureEntityModalSuccess (state) {
  return {
    ...state,
    signatureEntityModal: {
      ...initialState().signatureEntityModal
    }
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case mastersActionTypes.FETCH_SIGNATURE_CONFIG_SUCCESS:
      return fetchSignatureConfigListSuccess(state, action)
    case mastersActionTypes.OPEN_EDIT_SIGNATURE_CONFIG_MODAL_SUCCESS:
      return openSignatureConfigurationModalSuccess(state, action)
    case mastersActionTypes.CLOSE_EDIT_SIGNATURE_CONFIG_MODAL:
      return closeSignatureConfigurationModalSuccess(state, action)
    case mastersActionTypes.OPEN_EDIT_SIGNATURE_ENTITY_MODAL_SUCCESS:
      return openSignatureEntityModalSuccess(state, action)
    case mastersActionTypes.CLOSE_EDIT_SIGNATURE_ENTITY_MODAL:
      return closeSignatureEntityModalSuccess(state, action)
    default:
      return state
  }
}
