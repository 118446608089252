import { call, put, select, takeLatest } from 'redux-saga/effects'
import { change } from 'redux-form'
import { getAuth } from '../../selectors/access/auth'
import { showLoader, hideLoader } from '../../actions/common'
import messageModalActions from '../../actions/modals/messageModal'
import { fetchFleetSuccess } from '../../actions/combos/combos'
import combosActionTypes from '../../constants/actions/combos/combos'
import { handleError } from '../errors/errorManager'
import getFleets from '../../services/fleets/getFleets'
import { fetchSalesDossierSuccess } from '../../actions/dossier/sales'

export function * fetchFleet ({ organizedUnitId, includeDeleted, dossierSubTypeId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const fleets = yield call(getFleets, auth.token, { organizedUnitId, includeDeleted, dossierSubTypeId })
    yield put(fetchFleetSuccess(fleets))
  } catch (error) {
    const customHandler = function * () {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.FETCH_COMBO_ERROR'))
    }
    yield call(handleError, { error, customHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchfetchFleet () {
  yield takeLatest(combosActionTypes.FETCH_FLEET, fetchFleet)
}

export function * fetchFleetComponent ({ fleet }) {
  try {
    let state = JSON.parse(JSON.stringify(yield select(state => state)))
    let dossierFleetComponent = Object.assign({}, state.salesDossier.dossierFleetComponent)
    if (fleet === null || fleet === false) {
      dossierFleetComponent = {}
      if (fleet === false) {
        dossierFleetComponent.updated = false
      } else {
        if (state.form.salesDossier.initial.dossierFleetComponent.fleetId === undefined || state.form.salesDossier.initial.dossierFleetComponent.fleetId === null) {
          dossierFleetComponent.updated = false
        } else {
          dossierFleetComponent.updated = true
        }
      }
    } else {
      if (state.form.salesDossier.initial.dossierFleetComponent.fleetId === fleet.fleetId) {
        dossierFleetComponent = state.form.salesDossier.initial.dossierFleetComponent
      } else {
        dossierFleetComponent.updated = true
        dossierFleetComponent.fleetId = fleet.fleetId
        dossierFleetComponent.description = fleet.description
        dossierFleetComponent.fleetCode = fleet.fleetCode
        dossierFleetComponent.observations = fleet.observations
        dossierFleetComponent.fleetAssignmentDate = fleet.fleetAssignmentDate
        dossierFleetComponent.entityId = fleet.entityId
        dossierFleetComponent.organizedUnitID = fleet.organizedUnitID
      }
    }
    yield put(fetchSalesDossierSuccess({ dossierFleetComponent: { ...dossierFleetComponent } }))
    yield put(change('salesDossier', 'dossierFleetComponent', dossierFleetComponent))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchfetchFleetComponent () {
  yield takeLatest(combosActionTypes.FETCH_FLEET_COMPONENT, fetchFleetComponent)
}
