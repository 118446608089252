import auditInfoTypes from '../../constants/actions/audit/auditInfo'

export function refreshAuditInfo (dossierId, historical) {
  return {
    type: auditInfoTypes.REFRESH_AUDIT_INFO,
    dossierId,
    historical
  }
}
export function fetchAuditInfoSucces (auditInfo, dossierId, historical) {
  return {
    type: auditInfoTypes.FETCH_AUDIT_INFO_SUCCESS,
    auditInfo,
    dossierId,
    historical
  }
}

export function refreshAuditFleetInfo (fleetId) {
  return {
    type: auditInfoTypes.REFRESH_AUDIT_FLEET_INFO,
    fleetId
  }
}

export function fetchAuditFleetInfoSucces (auditInfo, fleetId) {
  return {
    type: auditInfoTypes.FETCH_AUDIT_FLEET_INFO_SUCCESS,
    auditInfo,
    fleetId
  }
}

export function fetchAuditDetails (dossierId, historical, idx, organizedUnitId, dossierSubTypeId, resolve) {
  return {
    type: auditInfoTypes.FETCH_AUDIT_DETAILS,
    dossierId,
    historical,
    idx,
    organizedUnitId,
    dossierSubTypeId,
    resolve
  }
}

export function fetchDocumentsGroupedAuditDetails (dossierId, historical, idx, resolve) {
  return {
    type: auditInfoTypes.FETCH_GROUPED_DOCUMENTS_AUDIT_DETAILS,
    dossierId,
    historical,
    idx,
    resolve
  }
}

export function fetchAuditDetailsModal (info) {
  return {
    type: auditInfoTypes.FETCH_AUDIT_DETAILS_MODAL,
    info
  }
}

export function fetchDocumentsGroupedAuditDetailsModal (documentTypeUsesNames) {
  return {
    type: auditInfoTypes.FETCH_GROUPED_DOCUMENTS_AUDIT_DETAILS_MODAL,
    documentTypeUsesNames
  }
}

export function auditFleetInfoDownload (fleetId) {
  return {
    type: auditInfoTypes.AUDIT_FLEET_INFO_DOWNLOAD,
    fleetId
  }
}

export default {
  refreshAuditInfo, fetchAuditInfoSucces, fetchAuditDetails, fetchAuditDetailsModal
}
