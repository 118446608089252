import documentaryManagementModalActionTypes from '../../../constants/actions/dossier/common/documentEditionModal'
import { documentEntityType as documentEntityTypeConstant } from '../../../constants/dossier/common/documentEntityType'

export function initialState () {
  return {
    showDocumentEditionModal: false,
    refreshAfterChange: false,
    dossierType: '',
    dossierSubType: '',
    documentParentTypeId: null,
    documentTypeUseName: '',
    documentTypeUseId: null,
    organizedUnitId: null,
    callback: null,
    dossierId: null,
    entityId: null,
    entityProviderId: null,
    vehicleId: null,
    checklistId: null,
    readOnlyFields: null,
    docResult: {
      documentId: null,
      typeView: null,
      documentStatusId: null,
      thumbnail: '',
      documentTypeId: null,
      fields: [],
      comment: '',
      rejectReason: '',
      fullScreenDocument: '',
      incorporationDate: '',
      sheets: [],
      numPages: null,
      isActiveAutoChargeAmountToWords: false
    },
    showFullScreenDocumentModalUrl: '',
    activeSheet: 0,
    activePage: 1,
    lastAccessDate: null,
    dirty: false,
    fleetsVisor: false,
    isRecurrent: false,
    editable: true,
    multipleSignCancel: null,
    cancelMessage: null,
    fleetMode: null,
    dossieFleetIds: null,
    entityIds: null,
    vehicleIds: null,
    fleetDocumentDetails: null,
    fleetId: null,
    refreshPurchasePresents: false,
    referenceType: null,
    referenceId: null,
    templateFormCode: false,
    fleetChargeId: null
  }
}

export function openDocumentCreationModalSuccess (state, { documentParentTypeId, organizedUnitId, dossierId, entityId, vehicleId, callback, refreshAfterChange, dossierType, dirty, isRecurrent, entityProviderId }) {
  return {
    ...state,
    showDocumentEditionModal: true,
    documentParentTypeId,
    organizedUnitId,
    dossierId,
    entityId: entityId,
    vehicleId,
    callback,
    refreshAfterChange,
    dossierType,
    activeSheet: 0,
    activePage: 1,
    isRecurrent,
    dirty,
    entityProviderId: entityProviderId
  }
}

export function openDocumentEditionModalSuccess (state, { docResult, documentTypeUseInfo, organizedUnitId, callback = null, dossierId, entityId, vehicleId, documentParentTypeId, checklistId, refreshAfterChange, dossierType, dossierSubType, lastAccessDate, dirty, isRecurrent, editable, readOnlyFields = false, refreshPurchasePresents, referenceType, referenceId }) {
  const initialDocResult = initialState().docResult
  return {
    ...state,
    showDocumentEditionModal: true,
    organizedUnitId,
    documentTypeUseName: documentTypeUseInfo.value,
    documentTypeUseId: documentTypeUseInfo.id,
    showGenerateButtons: documentTypeUseInfo.showGenerateButtons,
    onlyTemplateUpload: documentTypeUseInfo.onlyTemplateUpload,
    docResult: { ...initialDocResult, ...docResult },
    callback,
    dossierId,
    entityId: entityId,
    vehicleId,
    documentParentTypeId,
    checklistId,
    refreshAfterChange,
    dossierType,
    dossierSubType,
    activeSheet: 0,
    activePage: 1,
    lastAccessDate,
    isRecurrent,
    dirty,
    editable,
    readOnlyFields,
    refreshPurchasePresents,
    referenceType,
    referenceId,
    templateFormCode: documentTypeUseInfo.templateFormCode
  }
}

export function refreshDocResulSuccess (state, { docResult }) {
  const initialDocResult = initialState().docResult
  return {
    ...state,
    docResult: { ...initialDocResult, ...docResult }
  }
}

export function closeAddOrEditDocumentModal (state, { refreshAfterChange }) {
  return {
    ...state,
    refreshAfterChange
  }
}

export function closeAddOrEditDocumentModalSuccess (state) {
  return initialState()
}

export function uploadNewVersion (state) {
  const initialDocResult = initialState().docResult
  return {
    ...state,
    docResult: {
      ...initialDocResult,
      buttonsPermission: state.docResult.buttonsPermission,
      typeView: state.docResult.typeView,
      fields: state.docResult.fields,
      data: state.docResult.data,
      typeObject: state.docResult.typeObject,
      documentCode: state.docResult.documentCode,
      documentTypeId: state.docResult.documentTypeId
    }
  }
}

export function changeId (state, { id }) {
  switch (state.documentParentTypeId) {
    case documentEntityTypeConstant.ENTITY:
      return { ...state, entityId: id }
    case documentEntityTypeConstant.VEHICLE:
      return { ...state, vehicleId: id }
    default:
      return state
  }
}

export function fetchDocumentSuccess (state, { docResult, documentTypeUseInfo, documentTypeId, documentTypeUseName }) {
  const initialDocResult = initialState().docResult
  return {
    ...state,
    documentTypeUseId: documentTypeId || state.documentTypeUseId,
    documentTypeUseName: documentTypeUseName || state.documentTypeUseName,
    showGenerateButtons: (documentTypeUseInfo != null) ? documentTypeUseInfo.showGenerateButtons : false,
    onlyTemplateUpload: (documentTypeUseInfo != null) ? documentTypeUseInfo.onlyTemplateUpload : false,
    templateFormCode: (documentTypeUseInfo != null) ? documentTypeUseInfo.templateFormCode : false,
    docResult: { ...initialDocResult, ...docResult }
  }
}

export function updateMetaLoading (state, { metaLoading }) {
  return {
    ...state,
    metaLoading
  }
}

export function recoverDocument (state, { documentId, isRecurrent }) {
  return {
    ...state,
    documentId,
    isRecurrent
  }
}

export function setActivePage (state, { sheetId, pageId }) {
  return {
    ...state,
    activeSheet: sheetId,
    activePage: pageId
  }
}

export function updateLastAccessDate (state, { lastAccessDate }) {
  return {
    ...state,
    lastAccessDate: lastAccessDate
  }
}

export function openDocumentCreationModalFleetsSuccess (state, { organizedUnitId, dossierIds, callback, refreshAfterChange }) {
  return {
    ...state,
    showDocumentEditionModal: true,
    organizedUnitId,
    dossierIds,
    callback,
    refreshAfterChange,
    documentParentTypeId: 3,
    activeSheet: 0,
    activePage: 1,
    fleetsVisor: true
  }
}

export function fetchDocumentFleetsSuccess (state, { docResult, documentTypeId, documentTypeUseName, dossierIds }) {
  const initialDocResult = initialState().docResult
  return {
    ...state,
    documentTypeUseId: documentTypeId || state.documentTypeUseId,
    documentTypeUseName: documentTypeUseName || state.documentTypeUseName,
    docResult: { ...initialDocResult, ...docResult },
    dossierIds: dossierIds || state.dossierIds
  }
}

export function accepMultipleSignCancel (state) {
  return {
    ...state,
    multipleSignCancel: true
  }
}

export function rejectMultipleSignCancel (state) {
  return {
    ...state,
    multipleSignCancel: false
  }
}

export function cancelSignMessageSuccess (state, { message }) {
  return {
    ...state,
    cancelMessage: message
  }
}

export function openDocumentEditionModalSuccessFleets (state, { mode, docResult, documentTypeUseInfo, organizedUnitId, callback = null, dossierIds, entityIds, vehicleIds, fleetDocumentDetails, documentParentTypeId, checklistId, refreshAfterChange, dossierType, dossierSubType, lastAccessDate, dirty, isRecurrent, editable, readOnlyFields = false, fleetId, fleetChargeId }) {
  const initialDocResult = initialState().docResult
  return {
    ...state,
    showDocumentEditionModal: true,
    organizedUnitId,
    documentTypeUseName: documentTypeUseInfo.value,
    documentTypeUseId: documentTypeUseInfo.id,
    docResult: { ...initialDocResult, ...docResult },
    callback,
    dossierId: null,
    entityId: entityIds,
    vehicleId: vehicleIds,
    documentParentTypeId,
    checklistId,
    refreshAfterChange,
    dossierType,
    dossierSubType,
    activeSheet: 0,
    activePage: 1,
    lastAccessDate,
    isRecurrent,
    dirty,
    editable,
    readOnlyFields,
    fleetMode: mode,
    dossieFleetIds: dossierIds,
    entityIds,
    vehicleIds,
    fleetDocumentDetails,
    fleetId,
    fleetChargeId
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case documentaryManagementModalActionTypes.OPEN_DOCUMENT_CREATION_MODAL_SUCCESS:
      return openDocumentCreationModalSuccess(state, action)
    case documentaryManagementModalActionTypes.OPEN_DOCUMENT_EDITION_MODAL_SUCCESS:
      return openDocumentEditionModalSuccess(state, action)
    case documentaryManagementModalActionTypes.CLOSE_ADD_OR_EDIT_DOCUMENT_MODAL:
      return closeAddOrEditDocumentModal(state, action)
    case documentaryManagementModalActionTypes.CLOSE_ADD_OR_EDIT_DOCUMENT_MODAL_SUCCESS:
      return closeAddOrEditDocumentModalSuccess(state, action)
    case documentaryManagementModalActionTypes.UPLOAD_NEW_VERSION:
      return uploadNewVersion(state, action)
    case documentaryManagementModalActionTypes.FETCH_DOCUMENT_SUCCESS:
      return fetchDocumentSuccess(state, action)
    case documentaryManagementModalActionTypes.CHANGE_ID:
      return changeId(state, action)
    case documentaryManagementModalActionTypes.RECOVER_DOCUMENT:
      return recoverDocument(state, action)
    case documentaryManagementModalActionTypes.SET_ACTIVE_PAGE:
      return setActivePage(state, action)
    case documentaryManagementModalActionTypes.DOCUMENT_MODAL_UPDATE_LAST_ACCESS_DATE:
      return updateLastAccessDate(state, action)
    case documentaryManagementModalActionTypes.OPEN_DOCUMENT_CREATION_MODAL_FLEETS_SUCCESS:
      return openDocumentCreationModalFleetsSuccess(state, action)
    case documentaryManagementModalActionTypes.FETCH_DOCUMENT_FLEETS_SUCCESS:
      return fetchDocumentFleetsSuccess(state, action)
    case documentaryManagementModalActionTypes.LOADING_METADATA:
      return updateMetaLoading(state, action)
    case documentaryManagementModalActionTypes.ACCEPT_MULTIPLE_SIGN_CANCEL:
      return accepMultipleSignCancel(state, action)
    case documentaryManagementModalActionTypes.REJECT_MULTIPLE_SIGN_CANCEL:
      return rejectMultipleSignCancel(state, action)
    case documentaryManagementModalActionTypes.REFRESH_DOC_RESULT_SUCCESS:
      return refreshDocResulSuccess(state, action)
    case documentaryManagementModalActionTypes.CANCEL_SIGN_MESSAGE_SUCCESS:
      return cancelSignMessageSuccess(state, action)
    case documentaryManagementModalActionTypes.OPEN_DOCUMENT_EDITION_MODAL_SUCCESS_FEETS:
      return openDocumentEditionModalSuccessFleets(state, action)
    default:
      return state
  }
}
