import React, { PureComponent } from 'react'
import { Modal, Col, Row, FormGroup, Button } from 'react-bootstrap'
import InputText from '../../../commons/form/InputText'
import InputDatePicker from '../../../commons/form/InputDatePicker'
import InputSelect from '../../../commons/form/InputSelect'
import ReactDayPickerComponent from '../../../commons/ReactDatePickerComponent'
import { dossierTypes, entityTypesId, dossierSubTypes, clientInformation } from '../../../../constants/backendIds'
import { reduxForm, Field } from 'redux-form'
import { formatDate } from '../../../../util/utils'
import settings from '../../../../setting'
import { compareDate } from '../../../../util/validationFunctions'

class ServiceAddModal extends PureComponent {
  constructor () {
    super()
    this.state = {
      disabledEntityId: null,
      disabledEntitySubTypeId: null,
      loaded: false,
      selectedSubType: null,
      selectedDate: null,
      selectedMonth: null,
      orderDate: null,
      minDate: null,
      disabledRequiredDate: false
    }
    this.onDateChange = this.onDateChange.bind(this)
  }

  componentDidUpdate (prevProps) {
    if (this.props.showModal !== prevProps.showModal && this.props.showModal === true) {
      this.props.initialize(null)
      this.setState({ loaded: false, disabledEntityId: true, disabledEntitySubTypeId: false, selectedDate: null, selectedSubType: null, selectedMonth: null })
      this.props.actions.fetchDossierSubTypes(this.props.ouId, dossierTypes.service, this.props.subTypesAllowedToCreate)
      this.props.actions.fetchEntitySubTypeCombo(entityTypesId.recurrentService)
    }
    if (settings.clientFieldValidation === clientInformation.VGR && this.state.loaded === false &&
      this.props.showModal === true && ((this.props.formValues && !this.props.formValues?.orderDate) || !this.state.orderDate || !prevProps.showModal)) {
      if (new Date().getHours() >= settings.orderDateLimitHour) {
        let tomorrowDate = new Date()
        tomorrowDate.setDate(tomorrowDate.getDate() + 1)
        var dd = tomorrowDate.getDate().toString().padStart(2, '0')
        var mm = String(tomorrowDate.getMonth() + 1).padStart(2, '0')
        var yyyy = tomorrowDate.getFullYear()
        this.props.change('orderDate', `${dd}/${mm}/${yyyy}`)
        this.setState({ orderDate: `${dd}/${mm}/${yyyy}`, minDate: tomorrowDate.toISOString() })
      } else {
        let today = new Date()
        var todayDd = today.getDate().toString().padStart(2, '0')
        var todayMm = String(today.getMonth() + 1).padStart(2, '0')
        var todaYyyy = today.getFullYear()
        this.props.change('orderDate', `${todayDd}/${todayMm}/${todaYyyy}`)
        this.setState({ orderDate: `${todayDd}/${todayMm}/${todaYyyy}`, minDate: today.toISOString() })
      }
    }
    if (this.props.showModal === true && this.props.combos.dossierSubTypesCombo && this.props.combos.dossierSubTypesCombo.length === 1 && this.state.loaded === false) {
      this.props.change('dossierSubTypeId', this.props.combos.dossierSubTypesCombo[0].id)
      this.setState({
        loaded: true,
        selectedSubType: this.props.combos.dossierSubTypesCombo[0].id,
        disabledRequiredDate: this.props.dossierConstriction.listSubTypesWithOrderDateByDefault.includes(this.props.combos.dossierSubTypesCombo[0].id)
      }, () => {
        this.handleEntitySubType()
        this.handleDefaultDate()
        this.handleDefaultEntity()
        this.handleValidDates()
        if (this.props.destinationAvailable) {
          this.props.change('destinationId', this.props.defaultDestination)
        }
      })
    }
    if (this.props.isPurchase) {
      this.props.change('dossierSubTypeId', dossierSubTypes.service_pap)
      if (this.state.selectedSubType === null) {
        this.handleSubType(dossierSubTypes.service_pap)
      }
    }
  }

  handleDefaultEntity () {
    if (this.props.dossierConstriction.listSubTypesWithDefaultEntity.includes(this.state.selectedSubType)) {
      this.props.actions.fetchDefaultEntity(this.state.selectedSubType, this.props.ouId)
      this.setState({ disabledEntityId: false })
    } else {
      this.setState({ disabledEntityId: true })
    }
  }

  handleDefaultDate () {
    if ((this.props.dossierConstriction.listSubTypesWithOrderDateTodayNoEditable.includes(this.state.selectedSubType) ||
    this.props.dossierConstriction.listSubTypesWithOrderDateByDefault.includes(this.state.selectedSubType)) && settings.clientFieldValidation !== clientInformation.VGR) {
      this.props.change('orderDate', formatDate())
    }
  }

  componentWillUnmount () {
    this.props.reset()
  }

  dateSelected (value) {
    if (value) {
      this.setState({ selectedDate: value })
    }
  }

  submitService (values) {
    if (values) {
      if (this.props.isFleet) {
        const body = {
          ...values,
          dossierParentId: null,
          organizedUnitParentId: null,
          requiredDate: values.requiredDate
        }
        let data = { dossierList: this.props.dossierList, dossierServiceIncome: body }
        this.props.actions.setOperateDataServiceFleetProcess(data, this.props.fleet.fleetId, () => this.props.actions.openOrCloseAddServiceModal(false))
      } else {
        const body = {
          ...values,
          dossierParentId: this.props.dossierId,
          organizedUnitParentId: this.props.ouId,
          requiredDate: values.requiredDate
        }
        this.props.actions.saveSalesService(body, this.props.isPurchase ?? false)
      }
    }
  }

  setSelectedMonth (value) {
    this.setState({ selectedMonth: formatDate(value) }, () => {
      this.handleValidDates()
    })
  }

  setSelectedDate (value) {
    const date = value ? formatDate(value) : null
    this.setState({ selectedDate: date })
    this.props.change('requiredDate', date)
  }

  handleValidDates () {
    if (this.props.dossierConstriction.listSubTypesWithRequiredDatesInvalids.includes(this.state.selectedSubType)) {
      this.props.actions.fetchNotValidDatesService(this.props.ouId, this.state.selectedSubType, this.state.selectedMonth || formatDate())
    }
  }

  handleEntitySubType (values) {
    this.props.change('entityId', null)
    if (values) {
      this.setState({ disabledEntityId: false })
      this.props.actions.fetchEntityFilterSubTypeCombo(entityTypesId.recurrentService, values, this.props.ouId)
    } else {
      this.setState({ disabledEntityId: true })
    }
  }

  handleSubType (value) {
    this.props.change('entityId', null)
    this.props.change('entitySubTypeId', null)
    this.props.change('requiredDate', null)
    this.setState({ selectedMonth: null, selectedDate: null })
    if (value) {
      this.setState({ selectedSubType: value }, () => {
        this.handleDefaultEntity()
        this.handleDefaultDate()
        this.handleValidDates()
        this.setState({ disabledEntitySubTypeId: false, disabledRequiredDate: this.props.dossierConstriction.listSubTypesWithOrderDateByDefault.includes(value) })
      })
    } else {
      this.setState({ disabledEntitySubTypeId: true, disabledRequiredDate: false })
    }
  }

  onDateChange (value) {
    this.setState({ orderDate: value })
  }

  render () {
    const { handleSubmit, showModal, isPurchase, actions: { openOrCloseAddServiceModal }, combos: { dossierSubTypesCombo, entitySubTypeCombo, entityFilterSubTypeCombo, destinationCombo }, t, destinationAvailable } = this.props
    const tKey = 'DOSSIER_COMPONENTS.SALE_SERVICES.ADD_SERVICE_MODAL.'
    let className = !this.props.isFleet ? 'role-modal languages-modal' : 'fleet-created-select-modal languages-modal'
    return (
      <Modal className={className} show={showModal} onHide={openOrCloseAddServiceModal}>
        <form autoComplete="off" onSubmit={handleSubmit(this.submitService.bind(this))}>
          <Modal.Header closeButton onHide={openOrCloseAddServiceModal}>
            <Modal.Title> {t(`${tKey}TITLE`)} </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <Row className="inputs-wrapper flexRow">
                <Col sm={4} className="custom-width-col">
                  <h2>{t(`${tKey}SERVICE_SUBTYPE`)}</h2>
                  <Field
                    name="dossierSubTypeId"
                    placeholder={t(`${tKey}SERVICE_SUBTYPE`)}
                    valueKey="id"
                    labelKey="value"
                    component={InputSelect}
                    options={dossierSubTypesCombo}
                    colSm={0}
                    onChange={(value) => this.handleSubType(value)}
                    disabled={isPurchase}
                  />
                </Col>
                <Col sm={4} className="custom-width-col">
                  <h2>{t(`${tKey}ENTITY_TYPE`)} </h2>
                  <Field
                    name="entitySubTypeId"
                    placeholder={t(`${tKey}ENTITY_TYPE`)}
                    valueKey="id"
                    labelKey="value"
                    component={InputSelect}
                    options={entitySubTypeCombo}
                    onChange={(values) => this.handleEntitySubType(values)}
                    disabled={this.props.dossierConstriction.listSubTypesWithBlockedEntity.includes(this.state.selectedSubType) || this.state.disabledEntitySubTypeId }
                    colSm={0}
                  />
                </Col>
                <Col sm={4} className="custom-width-col">
                  <h2>{t(`${tKey}ENTITY`)}</h2>
                  <Field
                    name="entityId"
                    placeholder={t(`${tKey}ENTITY`)}
                    valueKey="id"
                    labelKey="value"
                    options={entityFilterSubTypeCombo}
                    component={InputSelect}
                    disabled={this.state.disabledEntityId || this.props.dossierConstriction.listSubTypesWithBlockedEntity.includes(this.state.selectedSubType) || this.state.disabledEntitySubTypeId}
                    colSm={0}
                  />
                </Col>
                {
                  destinationAvailable
                    ? <Col sm={4} className="custom-width-col">
                      <h2>{t(`${tKey}DESTINY`)}</h2>
                      <Field
                        name="destinationId"
                        placeholder={t(`${tKey}DESTINY`)}
                        valueKey="id"
                        labelKey="value"
                        options={destinationCombo}
                        component={InputSelect}
                        colSm={0}
                      />
                    </Col> : null
                }
                <Col sm={4} className="custom-width-col">
                  <h2>{t(`${tKey}ORDER_DATE`)}</h2>
                  <Field
                    name="orderDate"
                    placeholder={t(`${tKey}ORDER_DATE`)}
                    component={InputDatePicker}
                    colSm={0}
                    disabled={this.props.dossierConstriction.listSubTypesWithOrderDateTodayNoEditable.includes(this.state.selectedSubType)}
                    onInputChange={this.onDateChange}
                    minDate={this.state.minDate}
                  />
                </Col>
                <Col sm={4} className="custom-width-col">
                  <h2>{t(`${tKey}REQUIRED_DATE`)}</h2>
                  {this.props.dossierConstriction.listSubTypesWithRequiredDatesInvalids.includes(this.state.selectedSubType) ? ((
                    <Field
                      name="requiredDate"
                      placeholder={t(`${tKey}REQUIRED_DATE`)}
                      component={ReactDayPickerComponent}
                      setSelectedMonth={(value) => this.setSelectedMonth(value)}
                      setSelectedDate={(value) => this.setSelectedDate(value)}
                      colSm={0}
                      disabledDates={this.props.datesNotValid || []}
                      onSelect={(value) => this.dateSelected(value)}
                    />
                  )) : ((
                    <Field
                      name="requiredDate"
                      placeholder={t(`${tKey}REQUIRED_DATE`)}
                      component={InputDatePicker}
                      colSm={0}
                      disabled={this.state.disabledRequiredDate}
                    />
                  ))}
                </Col>
                <Col sm={12} className="custom-width-col">
                  <h2>{t(`${tKey}SALE_OBSERVATIONS`)}</h2>
                  <Field
                    name="saleObservations"
                    placeholder={t(`${tKey}SALE_OBSERVATIONS`)}
                    component={InputText}
                    customClass="textarea-noresize"
                    componentClass="textarea"
                    colSm={0}
                  />
                </Col>
              </Row>
            </FormGroup>
            {this.props.isFleet && this.props.dossierConstriction.listSubTypesWithRequiredDatesInvalids.includes(this.state.selectedSubType) && (
              t(`${tKey}UNATTENDED_PROCESS_ASIGN_REQUIRED_DATE`)
            )}
          </Modal.Body>
          <Modal.Footer>
            {!this.props.isFleet && (
              <Col sm={12} className="btn-wrapper">
                <Button
                  id="btn_add_service_modal"
                  className="btn-standard"
                  type="submit"
                  disabled={this.props.pristine || this.state.disabledEntityId}
                >
                  {t(`${tKey}SUBMIT_BUTTON`)}
                </Button>
              </Col>
            )}
            {this.props.isFleet && (
              <Col sm={12} className='fleet-created-moda-btn-exit'>
                <Button
                  id="btn-save-services-fleet-modal"
                  className="btn-standard"
                  type='submit'
                  disabled={this.props.pristine}>
                  {t('MANAGEMENT.FLEETS_TAB.AUX_MODALS.SAVE')}
                </Button>
                <Button
                  id='btn-cancel-services-fleet-modal'
                  className="btn-exit"
                  onClick={openOrCloseAddServiceModal}>
                  {t('MANAGEMENT.FLEETS_TAB.AUX_MODALS.EXIT')}
                </Button>
              </Col>
            )}
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default reduxForm({
  form: 'serviceAddModal',
  validate: (values, props) => {
    const error = { agency: {} }
    const tKey = 'DOSSIER_COMPONENTS.SALE_SERVICES.ADD_SERVICE_MODAL.VALIDATIONS.'
    if (!values.dossierSubTypeId) {
      error.dossierSubTypeId = props.t(`${tKey}SUBTYPE`)
    }
    if (!values.entitySubTypeId) {
      error.entitySubTypeId = props.t(`${tKey}ENTITY_TYPE`)
    }
    if (!values.entityId) {
      error.entityId = props.t(`${tKey}ENTITY`)
    }
    if (props.destinationAvailable && !values.destinationId) {
      error.destinationId = props.t(`${tKey}DESTINY`)
    }
    if (values.dossierSubTypeId && props.dossierConstriction.listSubTypesWithMandatoryObservations.includes(values.dossierSubTypeId)) {
      if (!values.saleObservations) {
        error.saleObservations = props.t(`${tKey}SALE_OBSERVATIONS`)
      }
    }
    if (!values.requiredDate && values.dossierSubTypeId === dossierSubTypes.deliveryPreparation) {
      error.requiredDate = props.t(`${tKey}REQUIRED_DATE`)
    }
    if (values.orderDate && settings.clientFieldValidation === clientInformation.VGR) {
      if (new Date().getHours() >= settings.orderDateLimitHour) {
        let tomorrowDate = new Date()
        tomorrowDate.setDate(tomorrowDate.getDate() + 1)
        var dd = tomorrowDate.getDate()
        var mm = String(tomorrowDate.getMonth() + 1).padStart(2, '0')
        var yyyy = tomorrowDate.getFullYear()
        const retCompareDates = compareDate(values.orderDate, `${dd}/${mm}/${yyyy}`)
        if (retCompareDates.secondDateIsAfter) {
          error.orderDate = props.t('DOSSIER_COMPONENTS.VALIDATIONS.AVAILABILITY_DATE_MUST_BE_EQUAL_OR_GREATER_TOMORROW')
        }
      } else {
        let today = new Date()
        var todayDd = today.getDate()
        var todayMm = String(today.getMonth() + 1).padStart(2, '0')
        var todaYyyy = today.getFullYear()
        const retCompareDates = compareDate(values.orderDate, `${todayDd}/${todayMm}/${todaYyyy}`)
        if (retCompareDates.secondDateIsAfter) {
          error.orderDate = props.t('DOSSIER_COMPONENTS.VALIDATIONS.AVAILABILITY_DATE_MUST_BE_EQUAL_OR_GREATER_TODAY')
        }
      }
    }
    return Object.keys(error).length > 0 ? error : {}
  }
})(ServiceAddModal)
