import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { getFormValues, getFormSyncErrors } from 'redux-form'
import profileActions from '../../actions/profile/profile'
import { openErrorMessageModal } from '../../actions/modals/messageModal'
import UserEventSubscriptions from '../../components/profile/UserEventSubscriptions'
import UserEventSubscriptions_v2 from '../../_v2/components/profile/UserEventSubscriptions'
import { fetchEventsCombo, fetchLowestOrganizedUnits } from '../../actions/combos/combos'
import TrackingContainer from '../../containers/tracking/trackingContainer'
import { getStorageValue } from '../../storage/storage'

export function mapStateToProps (state) {
  return {
    ...state.profile,
    ...state.auth,
    formValues: getFormValues('eventSubscriptionModal')(state),
    formErrors: getFormSyncErrors('eventSubscriptionModal')(state),
    combos: {
      UOTreeCombo: state.combos.UOTreeCombo,
      languages: state.profile.languages,
      events: state.combos.events,
      lowestOrganizedUnitsCombo: state.combos.lowestOrganizedUnitsCombo
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      ...profileActions,
      openErrorMessageModal,
      fetchEventsCombo,
      fetchLowestOrganizedUnits
    }, dispatch)
  }
}

let version = parseInt(getStorageValue('interfaceVersion'))
var decoratedComponent = null
if (version === 2) {
  decoratedComponent = TrackingContainer(UserEventSubscriptions_v2, true)
} else {
  decoratedComponent = TrackingContainer(UserEventSubscriptions, true)
}
export default connect(mapStateToProps, mapDispatchToProps)(translate()(decoratedComponent))
