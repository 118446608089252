import apiFetch from '../apiFetch'

export default function (token, taskId, orderby, urgencyType, organizedUnit) {
  let optionals = ''
  if (urgencyType) {
    optionals += `&urgencyType=${urgencyType}`
  }
  if (organizedUnit) {
    optionals += `&organizedUnit=${organizedUnit}`
  }

  return apiFetch({
    endPoint: `taskDashboard/${taskId}/ExportExcel?orderby=${orderby}${optionals}`,
    parseBlobAndAssign: true,
    method: 'GET',
    body: null,
    token: token
  })
}
