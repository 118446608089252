import mastersActionTypes from '../../../constants/actions/masters/masters'

export function fetchSignatureConfigList (filter) {
  return {
    type: mastersActionTypes.FETCH_SIGNATURE_CONFIG,
    filter
  }
}

export function fetchSignatureConfigListSuccess (signatureConfigList, filter) {
  return {
    type: mastersActionTypes.FETCH_SIGNATURE_CONFIG_SUCCESS,
    filter,
    signatureConfigList
  }
}

export function openEditSignatureConfigModal (signatureConfig) {
  return {
    type: mastersActionTypes.OPEN_EDIT_SIGNATURE_CONFIG_MODAL,
    signatureConfig
  }
}

export function openEditSignatureConfigModalSuccess (signatureConfig) {
  return {
    type: mastersActionTypes.OPEN_EDIT_SIGNATURE_CONFIG_MODAL_SUCCESS,
    signatureConfig
  }
}

export function submitSignatureConfig (signatureConfig) {
  return {
    type: mastersActionTypes.SUBMIT_SIGNATURE_CONFIG,
    signatureConfig
  }
}

export function closeSignatureConfigurationMasterModal () {
  return {
    type: mastersActionTypes.CLOSE_EDIT_SIGNATURE_CONFIG_MODAL
  }
}

export function deleteSignatureConfig (signatureConfigId) {
  return {
    type: mastersActionTypes.DELETE_SIGNATURE_CONFIG,
    signatureConfigId
  }
}

export function openEditSignatureEntityModal (signatureEntity) {
  return {
    type: mastersActionTypes.OPEN_EDIT_SIGNATURE_ENTITY_MODAL,
    signatureEntity
  }
}

export function openEditSignatureEntityModalSuccess (signatureEntity) {
  return {
    type: mastersActionTypes.OPEN_EDIT_SIGNATURE_ENTITY_MODAL_SUCCESS,
    signatureEntity
  }
}

export function closeSignatureEntityMasterModal () {
  return {
    type: mastersActionTypes.CLOSE_EDIT_SIGNATURE_ENTITY_MODAL
  }
}

export function submitSignatureEntity (signatureEntity) {
  return {
    type: mastersActionTypes.SUBMIT_SIGNATURE_ENTITY,
    signatureEntity
  }
}

export function deleteSignatureEntity (signatureEntity) {
  return {
    type: mastersActionTypes.DELETE_SIGNATURE_ENTITY,
    signatureEntity
  }
}
