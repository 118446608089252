import React, { PureComponent } from 'react'
import { Panel, Col, Table, Button } from 'react-bootstrap'
import InputText from '../../../../_v2/components/commons/form/InputText'
import InputNatural from '../../../../_v2/components/commons/form/InputNatural'
import InputSelect from '../../../../_v2/components/commons/form/InputSelect'
import InputDatePicker from '../../../../_v2/components/commons/form/InputDatePicker'
import InputTimePicker from '../../../../_v2/components/commons/form/InputTimePicker'
import InputCheckBox from '../../../../_v2/components/commons/form/InputCheckBox'
import { getFieldsPermisions } from '../../../../util/permisionFunctions'
import { Field } from 'redux-form'
import DeliveryChecklistRow from '../../../../components/dossiers/sales/DeliveryCheckList/DeliveryChecklistRow'
import { permissions, clientInformation } from '../../../../constants/backendIds'
import { dossierSubTypeId as dossierSubTypeIdConstants } from '../../../../constants/dossier/common/dossierSubType'
import { validateDate } from '../../../../util/validationFunctions'
import settings from '../../../../setting'
import ChecklistDeliveryVoModal from '../../../../components/dossiers/common/ChecklistDeliveryVoModal'
import CustomFields from '../../../../components/commons/customFields/CustomFields'
import ComponentTitle from '../../../../_v2/containers/dossiers/commons/ComponentTitlePage'

class Delivery extends PureComponent {
  constructor () {
    super()
    this.state = {
      open: false,
      fieldsConfiguration: {
        tuneUpDestinationId: null,
        differentDeliveryPerson: null,
        deliveryCity: null,
        km: null,
        externalDelivery: null,
        realDeliveryDate: null,
        realDeliveryTime: null,
        lastITVDate: null,
        realDeliveryAddress: null,
        realDeliveryLocationId: null,
        deliveryPAP: null,
        printCheckListVo: null,
        printChecklist: null,
        printDeliveryNoteChecklistVO: null,
        btnPrintDeliveryCertificates: null,
        observations: null,
        insuranceStartDate: null
      },
      showChecklistModalVo: false
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.open) {
      this.setState({ open: true })
    }
  }

  UNSAFE_componentWillMount () {
    this.initializePermisions(this.props)
  }

  componentDidUpdate (prevProps) {
    if (prevProps.componentConfiguration && this.props.componentConfiguration && (prevProps.componentConfiguration !== this.props.componentConfiguration)) {
      this.initializePermisions(this.props)
    }
  }

  initializePermisions (props) {
    const fieldKeys = Object.keys(this.state.fieldsConfiguration)
    const newState = getFieldsPermisions(props.componentConfiguration.fieldsConfiguration, fieldKeys, props.componentConfiguration.permission)
    this.setState({ ...this.state, ...newState })
  }

  fetchSalesDossierPromiseDelivery () {
    if (this.state.open) {
      this.props.actions.setActiveSection(false)
    }
    this.setState({
      open: !this.state.open
    })
    if (this.props.isDeliveryLoaded === false) {
      return new Promise((resolve, reject) => {
        this.props.handleToUpdateComponent(this.props)
        if (this.props.dossierSubTypeId !== dossierSubTypeIdConstants.registration_delivery || this.props.dossierSubTypeId !== dossierSubTypeIdConstants.model_agency) {
          return this.props.actions.fetchSalesDelivery(this.props.dossierId, resolve, reject, this.props.pristine)
        } else {
          return this.props.actions.fetchRegistrationDeliveryDelivery(this.props.dossierId, resolve, reject, this.props.pristine)
        }
      })
    }
  }

  dateValidation (value, allValues, props) {
    const result = validateDate(value, props)
    return result === Object(result) ? undefined : result
  }

  openModalprintChecklistVO () {
    this.setState({ showChecklistModalVo: !this.state.showChecklistModalVo })
  }

  render () {
    const {
      t, readOnlyFields,
      dossierCompleteDeliveryCheckListItems = [],
      dossierDeliveryCheckListPurchase = [],
      deliveryLocationCombo = [],
      isSalesDossier,
      isRegistrationDeliveryDossier,
      dossierId,
      dossierSubTypeId,
      componentConfiguration,
      printDeliveryChecklistPermission, customFields,
      actions: { printDeliveryChecklist, printChecklistVO, printDeliveryCertificates, printDeliveryChecklistSale },
      combos: { tuneUpDestinationCombo }
    } = this.props
    const isVN = dossierSubTypeId === dossierSubTypeIdConstants.compra_vn || dossierSubTypeId === dossierSubTypeIdConstants.venta_vn || dossierSubTypeId === dossierSubTypeIdConstants.registration_delivery || dossierSubTypeId === dossierSubTypeIdConstants.model_agency
    const tKey = 'DOSSIER_COMPONENTS.DELIVERY.'

    const useDeliveryNoteChecklistVO = this.state.fieldsConfiguration.printDeliveryNoteChecklistVO != null &&
      this.state.fieldsConfiguration.printDeliveryNoteChecklistVO.permission > permissions.hidden
    return (
      <>
        <ChecklistDeliveryVoModal
          showModal = {this.state.showChecklistModalVo}
          closeModalAction={this.openModalprintChecklistVO.bind(this)}
          t={t}
          dossierId = {dossierId}
          isSalesDossier={isSalesDossier || isRegistrationDeliveryDossier}
          printDeliveryChecklistSale = {printDeliveryChecklistSale}
          dossierSubTypeId ={dossierSubTypeId}
          useDeliveryNoteChecklistVO = {useDeliveryNoteChecklistVO}
        ></ChecklistDeliveryVoModal>
        <div className="dossier-Component">
          {!this.props.isFleet && (
            <div className="header-panel" onClick={this.fetchSalesDossierPromiseDelivery.bind(this)}>
              <ComponentTitle
                componentCode="DELIVERY"
                componentOpen={this.state.open}
                customTitle={isSalesDossier || isRegistrationDeliveryDossier ? t(`${tKey}TITLE_PANEL`) : t(`${tKey}RECIVE_TITLE_PANEL`)}
              />
            </div>)}
          <Panel expanded={this.state.open} onToggle={() => {}}>
            <Panel.Collapse>
              <div className="fieldsContainer">
                { this.state.fieldsConfiguration.externalDelivery.permission !== permissions.hidden && (
                  <Field
                    component={InputCheckBox}
                    colWidht={100} // 25, 33, 50, 75, 100 default=25
                    id="externalDelivery"
                    name="dossierCompleteDeliveryComponent.dossierDeliveryComponent.externalDelivery"
                    placeholder={t(`${tKey}EXTERNAL_DELIVERY`)}
                    disabled={readOnlyFields || componentConfiguration.permission !== permissions.editable}
                  />
                )}
                { this.state.fieldsConfiguration.realDeliveryDate.permission !== permissions.hidden && (
                  <Field
                    component={InputDatePicker}
                    colWidht={33} // 25, 33, 50, 75, 100 default=25
                    id="date"
                    name="dossierCompleteDeliveryComponent.dossierDeliveryComponent.realDeliveryDate"
                    controlLabel={!isSalesDossier && !isRegistrationDeliveryDossier ? (t(`${tKey}DATE_RECIVE`)) : (t(`${tKey}DATE_DELIVERY`))}
                    validate={[this.dateValidation]}
                    disabled={readOnlyFields || componentConfiguration.permission !== permissions.editable}
                  />
                )}
                { this.state.fieldsConfiguration.realDeliveryTime.permission !== permissions.hidden && (
                  <Field
                    component={InputTimePicker}
                    colWidht={33} // 25, 33, 50, 75, 100 default=25
                    id="hour"
                    name="dossierCompleteDeliveryComponent.dossierDeliveryComponent.realDeliveryTime"
                    controlLabel={!isSalesDossier && !isRegistrationDeliveryDossier ? (t(`${tKey}HOUR_RECIVE`)) : (t(`${tKey}HOUR_DELIVERY`))}
                    placeholder={!isSalesDossier && !isRegistrationDeliveryDossier ? (t(`${tKey}HOUR_RECIVE`)) : (t(`${tKey}HOUR_DELIVERY`))}
                    disabled={readOnlyFields || componentConfiguration.permission !== permissions.editable}
                  />
                )}
                { this.state.fieldsConfiguration.lastITVDate.permission !== permissions.hidden && !isVN && (
                  <Field
                    component={InputDatePicker}
                    colWidht={33} // 25, 33, 50, 75, 100 default=25
                    id="lastITVDate"
                    name="dossierCompleteDeliveryComponent.dossierDeliveryComponent.lastITVDate"
                    key="dossierCompleteDeliveryComponent.dossierDeliveryComponent.lastITVDate"
                    controlLabel={t(`${tKey}LAST_ITV_DATE`)}
                    disabled={readOnlyFields || componentConfiguration.permission !== permissions.editable}
                    validate={[this.dateValidation]}
                  />
                )}
                {this.state.fieldsConfiguration.km.permission !== permissions.hidden && (
                  <Field
                    component={InputNatural}
                    colWidht={33} // 25, 33, 50, 75, 100 default=25
                    id="Km"
                    name="dossierCompleteDeliveryComponent.dossierDeliveryComponent.km"
                    controlLabel={t(`${tKey}KM`)}
                    placeholder={t(`${tKey}KM`)}
                    disabled={readOnlyFields || componentConfiguration.permission !== permissions.editable}
                    textAlign="left"
                    maxLength={9}
                  />
                )}
                {this.state.fieldsConfiguration.deliveryCity.permission !== permissions.hidden && (
                  <Field
                    component={InputText}
                    colWidht={33} // 25, 33, 50, 75, 100 default=25
                    id="deliveryCity"
                    name="dossierCompleteDeliveryComponent.dossierDeliveryComponent.deliveryCity"
                    colSm={!isVN ? (4) : (3)}
                    controlLabel={t(`${tKey}DELIVERY_CITY`)}
                    placeholder={t(`${tKey}DELIVERY_CITY`)}
                    disabled={readOnlyFields || componentConfiguration.permission !== permissions.editable}
                  />
                )}
                {(dossierSubTypeId === dossierSubTypeIdConstants.venta_vo) && this.state.fieldsConfiguration.tuneUpDestinationId.permission !== permissions.hidden && (
                  <Field
                    component={InputSelect}
                    colWidht={33} // 25, 33, 50, 75, 100 default=25
                    id="tuneUpDestinationId"
                    name="dossierCompleteDeliveryComponent.dossierDeliveryComponent.tuneUpDestinationId"
                    controlLabel={t(`${tKey}TUNE_UP_DESTINATION`)}
                    placeholder={t(`${tKey}TUNE_UP_DESTINATION`)}
                    valueKey="id"
                    labelKey="value"
                    options={tuneUpDestinationCombo}
                    disabled={readOnlyFields || componentConfiguration.permission !== permissions.editable}
                  />
                )}
                {isSalesDossier && this.state.fieldsConfiguration.insuranceStartDate.permission !== permissions.hidden && (
                  <Field
                    component={InputDatePicker}
                    colWidht={33} // 25, 33, 50, 75, 100 default=25
                    id="date"
                    name="dossierCompleteDeliveryComponent.dossierDeliveryComponent.insuranceStartDate"
                    colSm={4}
                    controlLabel={t(`${tKey}INSURANCE_START_DATE`)}
                    placeholder={t(`${tKey}INSURANCE_START_DATE`)}
                    validate={[this.dateValidation]}
                    disabled={readOnlyFields || this.state.fieldsConfiguration.insuranceStartDate.permission !== permissions.editable}/>
                )}
                {isSalesDossier || isRegistrationDeliveryDossier ? this.state.fieldsConfiguration.realDeliveryAddress.permission !== permissions.hidden && (
                  <Field
                    component={InputText}
                    colWidht={100} // 25, 33, 50, 75, 100 default=25
                    id="deliveryAddress"
                    name="dossierCompleteDeliveryComponent.dossierDeliveryComponent.realDeliveryAddress"
                    componentClass="textarea"
                    controlLabel={t(`${tKey}ADDRESS`)}
                    placeholder={t(`${tKey}ADDRESS`)}
                    disabled={readOnlyFields || componentConfiguration.permission !== permissions.editable}
                    maxLength={256}
                    customClass={settings.clientFieldValidation === clientInformation.DEMO ? 'text-address-short' : 'text-address' }
                  />
                )
                  : this.state.fieldsConfiguration.realDeliveryLocationId.permission !== permissions.hidden && (
                    <Field
                      component={InputSelect}
                      colWidht={33} // 25, 33, 50, 75, 100 default=25
                      id="deliveryLocationId"
                      name="dossierCompleteDeliveryComponent.dossierDeliveryComponent.realDeliveryLocationId"
                      controlLabel={t(`${tKey}ADDRESS`)}
                      placeholder={t(`${tKey}ADDRESS`)}
                      options={deliveryLocationCombo}
                      valueKey="id"
                      labelKey="value"
                      disabled={readOnlyFields || componentConfiguration.permission !== permissions.editable}
                    />)
                }
                {this.state.fieldsConfiguration.observations.permission !== permissions.hidden && (
                  <Field
                    component={InputText}
                    colWidht={100} // 25, 33, 50, 75, 100 default=25
                    id="observations"
                    name="dossierCompleteDeliveryComponent.dossierDeliveryComponent.observations"
                    componentClass="textarea"
                    controlLabel={t(`${tKey}OBSERVATIONS`)}
                    placeholder={t(`${tKey}OBSERVATIONS`)}
                    disabled={readOnlyFields || componentConfiguration.permission !== permissions.editable}
                    maxLength={512}
                  />
                )}
                {this.state.fieldsConfiguration.differentDeliveryPerson.permission !== permissions.hidden && (
                  <Field
                    component={InputCheckBox}
                    colWidht={33} // 25, 33, 50, 75, 100 default=25
                    id="differentDeliveryPerson"
                    name="dossierCompleteDeliveryComponent.dossierDeliveryComponent.differentDeliveryPerson"
                    placeholder={t(`${tKey}DIFFERENT_DELIVERY_PERSON`)}
                    disabled={readOnlyFields || componentConfiguration.permission !== permissions.editable}
                  />
                )}
                {(this.state.fieldsConfiguration.deliveryPAP.permission !== permissions.hidden && settings.clientFieldValidation === clientInformation.VGR) && (
                  <Field
                    component={InputCheckBox}
                    colWidht={33} // 25, 33, 50, 75, 100 default=25
                    id="pap"
                    name="dossierCompleteDeliveryComponent.dossierDeliveryComponent.pap"
                    placeholder={t(`${tKey}PAP`)}
                    disabled={readOnlyFields || componentConfiguration.permission !== permissions.editable}
                  />
                )}
                <div className='flexInLine '>
                  {!readOnlyFields && printDeliveryChecklistPermission && this.state.fieldsConfiguration.printChecklist.permission !== permissions.hidden && !this.props.isFleet && (
                    <Button
                      className="_Btn accept"
                      id="print-button"
                      onClick={() => settings.clientFieldValidation === clientInformation.TOYOTA || useDeliveryNoteChecklistVO ? this.openModalprintChecklistVO() : printDeliveryChecklist(dossierId, isSalesDossier || isRegistrationDeliveryDossier)}
                      disabled ={componentConfiguration.permission !== permissions.editable} >
                      <i className="iDocIcon-print-multifunction-printer" />
                      <span>{t(`${tKey}PRINT`)}</span>
                    </Button>
                  )}
                  {dossierSubTypeId === dossierSubTypeIdConstants.venta_vo && this.state.fieldsConfiguration.printCheckListVo.permission !== permissions.hidden && !this.props.isFleet && (
                    <Button
                      className="_Btn accept"
                      id="print-button"
                      onClick={() => (settings.clientFieldValidation === clientInformation.TOYOTA || useDeliveryNoteChecklistVO) ? this.openModalprintChecklistVO() : printChecklistVO(dossierId, isSalesDossier || isRegistrationDeliveryDossier)}
                      disabled ={componentConfiguration.permission !== permissions.editable}>
                      <i className="iDocIcon-print-multifunction-printer" />
                      <span>{t(`${tKey}PRINT_CHECKLIST_VO`)}</span>
                    </Button>
                  )}
                  {this.state.fieldsConfiguration.btnPrintDeliveryCertificates.permission !== permissions.hidden && !this.props.isFleet && (
                    <Button className="_Btn accept"
                      onClick={() => printDeliveryCertificates(dossierId)}
                      disabled={componentConfiguration.permission !== permissions.editable}>
                      <i className="iDocIcon-print-multifunction-printer" />
                      <span>{t(`${tKey}PRINT_DELIVERY_CERTIFICATES`)}</span>
                    </Button>
                  )}
                </div>

                {
                  !!dossierCompleteDeliveryCheckListItems.length && (
                    <Col sm={12} className="col-row hidden">
                      <Table bordered className="table-standard">
                        <thead>
                          <tr>
                            <th>{t(`${tKey}NAME`)}</th>
                            <th>{t(`${tKey}TYPE`)}</th>
                            <th>{t(`${tKey}DELIVERABLE`)}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            dossierCompleteDeliveryCheckListItems.map((checkListItem, idx) => (
                              <DeliveryChecklistRow
                                checkListItem={checkListItem}
                                key={idx}
                              />
                            ))
                          }
                        </tbody>
                      </Table>
                    </Col>
                  )
                }
                {
                  dossierDeliveryCheckListPurchase.map((purchase, idx) => (
                    <Col sm={12} className="col-row hidden" key={idx}>
                      {(isSalesDossier || isRegistrationDeliveryDossier) && <p>{purchase.dealerNumber}</p>}
                      <Table bordered className="table-standard">
                        <thead>
                          <tr>
                            <th>{t(`${tKey}NAME`)}</th>
                            <th>{t(`${tKey}TYPE`)}</th>
                            <th>{t(`${tKey}DELIVERABLE`)}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            purchase.deliveryCheckList.map((checkListItem, idx) => {
                              return (
                                <DeliveryChecklistRow
                                  checkListItem={checkListItem}
                                  key={idx}
                                />
                              )
                            })
                          }
                        </tbody>
                      </Table>
                    </Col>
                  ))
                }
                <CustomFields customFields={customFields} readOnlyFields={readOnlyFields} />
              </div>
            </Panel.Collapse>
          </Panel>
        </div>
      </>
    )
  }
}
export default Delivery
