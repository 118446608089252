import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import SearchByVehicleForm from '../../../components/search/SearchByVehicleForm'
import { translate } from 'react-polyglot'

import { resetFilters } from '../../../actions/search/search'
import {
  fetchBrands,
  fetchCarlineCombo, fetchCatalogueCombo, fetchModelCombo, fetchColorCombo, fetchTapestryCombo,
  resetCarlineCombo, resetCatalogueCombo, resetColorCombo, resetTapestryCombo
} from '../../../actions/combos/combos'
import vehicleActions from '../../../actions/dossier/common/vehicle'
import { fetchPurchaseCatalogueModel } from '../../../actions/dossier/purchase'
export function mapStateToProps (state) {
  return {
    combos: state.combos
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchBrands,
      resetFilters,
      fetchCarlineCombo,
      fetchCatalogueCombo,
      fetchModelCombo,
      fetchColorCombo,
      fetchTapestryCombo,
      resetCarlineCombo,
      resetCatalogueCombo,
      resetColorCombo,
      resetTapestryCombo,
      fetchPurchaseCatalogueModel,
      ...vehicleActions

    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(SearchByVehicleForm))
