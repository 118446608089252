import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import DocumentalSearch from '../../../components/search/documentalSearch/DocumentalSearch'
import { translate } from 'react-polyglot'
import { fetchDossiers, fetchEntities, fetchVehicles } from '../../../actions/search/search'
import { fetchDossierSubTypes, fetchSearchDossierSubType, fetchDocumentStatusCombo, fetchDocumentRestrictedCombo, fetchEntityFilterSubTypeCombo, fetchMassiveDownloadStatusCombo } from '../../../actions/combos/combos'
import { documentalSearchTabChange, resetFilters, fetchDocumentSearchList, initializeSearch, setFilters, findFilterDocuments, downloadDocumentList, massiveDownloadDocumentList, createMassiveDownload } from '../../../actions/search/documentalSearch/documentalSearchForm'
import { openDocumentEditionModal } from '../../../actions/dossier/common/documentEditionModal'
import { openDocumentEditionModal as openDocumentEditionModalHistorical } from '../../../actions/dossier/common/documentHistoricalEditionModal'
import { openModal, setCurrentSection } from '../../../actions/common'
// import { withRouter } from 'react-router-dom'
import TrackingContainer from '../../../containers/tracking/trackingContainer'

export function mapStateToProps (state) {
  return {
    ...state.documentalSearch,
    sectionsGeneral: state.auth.sections,
    combos: {
      brandCombo: state.combos.brandCombo,
      dossierSubtypesCombo: state.combos.dossierSubTypesCombo,
      stagesCombo: state.combos.stagesCombo,
      lowestOrganizedUnitsCombo: state.combos.lowestOrganizedUnitsCombo,
      salesmanCombo: state.combos.salesmanCombo,
      entityTypeCombo: state.combos.entityTypeCombo,
      entitySubTypeCombo: state.combos.entitySubTypeCombo,
      entityFilterSubTypeCombo: state.combos.entityFilterSubTypeCombo,
      searchDossierSubtypesCombo: state.combos.searchDossierSubtypesCombo,
      documentsTypesCombo: state.combos.documentsTypesCombo,
      documentStatusCombo: state.combos.documentStatusCombo,
      entityTypesCombo: state.combos.entityTypesCombo,
      documentsTypesRestrictedCombo: state.combos.documentsTypesRestrictedCombo
    },
    section: state.common.section
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      initializeSearch,
      fetchDossierSubTypes,
      documentalSearchTabChange,
      fetchDossiers,
      fetchEntities,
      fetchVehicles,
      resetFilters,
      fetchDocumentSearchList,
      fetchSearchDossierSubType,
      openDocumentEditionModal,
      fetchEntityFilterSubTypeCombo,
      openDocumentEditionModalHistorical,
      findFilterDocuments,
      setFilters,
      fetchDocumentStatusCombo,
      downloadDocumentList,
      openModal,
      fetchDocumentRestrictedCombo,
      setCurrentSection,
      massiveDownloadDocumentList,
      createMassiveDownload,
      fetchMassiveDownloadStatusCombo
    }, dispatch)
  }
}
var decoratedComponent = TrackingContainer(DocumentalSearch, true)
export default connect(mapStateToProps, mapDispatchToProps)(translate()(decoratedComponent))
