import { put, call, takeEvery, select, all } from 'redux-saga/effects'
import { reset } from 'redux-form'
import { getAuth } from '../../../selectors/access/auth'
import { yesNoModal } from '../../modalsListeners/yesNoModal'
import { fetchSalesDossierSuccess, fetchSalesDossierDependants, fetchAllDocumentsSuccess, fetchAllDocumentHistoricalsSuccess } from '../../../actions/dossier/sales'
import dossierActionsTypes from '../../../constants/actions/dossier/dossier'
import { dossierTypes as dossierTypesConstant, dossierTypesId } from '../../../constants/dossier/common/dossierTypes'
import messageModalActions from '../../../actions/modals/messageModal'
import { showLoader, hideLoader } from '../../../actions/common'
import { fetchCombosSuccess } from '../../../actions/combos/combos'
import getCampaignType from '../../../services/campaignType/getCampaignType'
import getDocumentsTypes from '../../../services/document/getDocumentsTypes'
import deleteCampaignService from '../../../services/dossier/delete/deleteCampaign'
import getSaleCampaignByIdService from '../../../services/dossier/get/getSaleCampaignById'
import putDossierCampaign from '../../../services/dossier/put/putDossierCampaign'
import postDossierCampaign from '../../../services/dossier/post/postDossierCampaign'
import getDossierSubTypeByTypeAndUo from '../../../services/dossierType/getDossierSubTypeByTypeAndUo'
import getSaleHeaderAndButtons from '../../../services/dossier/get/getSaleHeaderAndButtons'
import getStageCheckList from '../../../services/dossier/get/getStageCheckList'
import { handleError } from '../../errors/errorManager'
import { initializeDossier } from '../commonDossierSagas'
import postDocumentsUploadedByDossierId from '../../../services/document/postDocumentsUploadedByDossierId'
import postDocumentHistoricalsUploadedByDossierId from '../../../services/document/postDocumentHistoricalsUploadedByDossierId'
import downloadDocuments from '../../../services/document/getDownloadDocuments'

export function * fetchCampaignEdition ({ campaignId, openCampaignEditionFn }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const salesDossier = yield select(state => state.salesDossier)
    const dossierId = salesDossier.dossierId
    const organizedUnitId = salesDossier.organizedUnitId
    if ((salesDossier.modelPriceAmount ?? 0) <= 0) {
      yield put(messageModalActions.openErrorMessageModal('DOSSIER_COMPONENTS.VALIDATIONS.REQUIRED_VEHICLE_PRICE'))
    } else {
      const [campaignsTypes, documentsTypes, dossierSubTypesCombo] = yield all([
        call(getCampaignType, auth.token), call(getDocumentsTypes, auth.token, null, dossierId), call(getDossierSubTypeByTypeAndUo, dossierTypesId.campaign, organizedUnitId, auth.token)
      ])
      yield put(fetchCombosSuccess({ campaignsTypes, documentsTypes, dossierSubTypesCombo }))
      if (campaignId) {
        const campaign = yield call(getSaleCampaignByIdService, campaignId, auth.token)
        yield call(openCampaignEditionFn, campaign)
      } else {
        yield call(openCampaignEditionFn)
      }
    }
  } catch (error) {
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchCampaignEdition () {
  yield takeEvery(dossierActionsTypes.FETCH_CAMPAIGN_EDITION, fetchCampaignEdition)
}

export function * deleteCampaign ({ campaignId }) {
  try {
    const salesDossier = yield select(state => state.salesDossier)
    const showAlertSendPending = salesDossier.showAlertSendPending
    const dossierId = salesDossier.dossierId
    const auth = yield select(getAuth)
    if (showAlertSendPending) {
      const confirmed = yield call(yesNoModal, 'editDossierAlert')
      if (confirmed) {
        yield put(showLoader())
        yield call(deleteCampaignService, campaignId, auth.token, salesDossier.lastAccessDate)
        yield put(fetchSalesDossierDependants(dossierId))
      }
    } else {
      yield put(showLoader())
      yield call(deleteCampaignService, campaignId, auth.token, salesDossier.lastAccessDate)
      yield put(fetchSalesDossierDependants(dossierId))
      const checkListHeader = yield call(getStageCheckList, dossierId, auth.token)
      const saleDossier = { checkListHeader }
      yield put(fetchSalesDossierSuccess(saleDossier))
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          const salesDossierState = yield select(state => state.salesDossier)
          yield call(initializeDossier, dossierTypesConstant.sales, salesDossierState)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDeleteCampaign () {
  yield takeEvery(dossierActionsTypes.DELETE_CAMPAIGN, deleteCampaign)
}

export function * updateCampaign ({ campaign, closeModalFunc }) {
  try {
    const salesDossier = yield select(state => state.salesDossier)
    const showAlertSendPending = salesDossier.showAlertSendPending
    const dossierId = salesDossier.dossierId
    const auth = yield select(getAuth)
    if (showAlertSendPending) {
      const confirmed = yield call(yesNoModal, 'editDossierAlert')
      if (confirmed) {
        yield put(showLoader())
        yield call(putDossierCampaign, campaign.campaignId, { ...campaign, dossierId: campaign.campaignId, dossierParentId: salesDossier.dossierId }, auth.token, salesDossier.lastAccessDate)
        yield put(fetchSalesDossierDependants(dossierId))
        yield call(closeModalFunc)
        yield put(hideLoader())
      }
    } else {
      yield put(showLoader())
      yield call(putDossierCampaign, campaign.campaignId, { ...campaign, dossierId: campaign.campaignId, dossierParentId: salesDossier.dossierId }, auth.token, salesDossier.lastAccessDate)
      yield put(fetchSalesDossierDependants(dossierId))
      const checkListHeader = yield call(getStageCheckList, dossierId, auth.token)
      const saleDossier = { checkListHeader }
      yield put(fetchSalesDossierSuccess(saleDossier))
      yield call(closeModalFunc)
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          yield call(closeModalFunc)
          const salesDossierState = yield select(state => state.salesDossier)
          yield call(initializeDossier, dossierTypesConstant.sales, salesDossierState)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchUpdateCampaign () {
  yield takeEvery(dossierActionsTypes.UPDATE_CAMPAIGN, updateCampaign)
}

export function * createCampaign ({ campaign, closeModalFunc }) {
  try {
    const salesDossier = yield select(state => state.salesDossier)
    const showAlertSendPending = salesDossier.showAlertSendPending
    const dossierId = salesDossier.dossierId
    const auth = yield select(getAuth)
    let sales
    if (showAlertSendPending) {
      const confirmed = yield call(yesNoModal, 'editDossierAlert')
      if (confirmed) {
        yield put(showLoader())
        yield call(postDossierCampaign, { ...campaign, dossierParentId: dossierId }, auth.token, salesDossier.lastAccessDate)
        yield put(messageModalActions.openSuccessMessageModal('MESSAGES.POST_TABLE_ITEM_SUCCESS'))
        const headerAndButtons = yield call(getSaleHeaderAndButtons, auth.token, salesDossier.dossierId)
        const checkListHeader = yield call(getStageCheckList, salesDossier.dossierId, auth.token)
        sales = { ...headerAndButtons }
        sales = { ...sales, checkListHeader }
        yield put(fetchSalesDossierSuccess(sales))
        yield put(fetchSalesDossierDependants(dossierId))
        yield call(closeModalFunc)
        yield put(hideLoader())
      }
    } else {
      yield put(showLoader())
      yield call(postDossierCampaign, { ...campaign, dossierParentId: dossierId }, auth.token, salesDossier.lastAccessDate)
      yield put(messageModalActions.openSuccessMessageModal('MESSAGES.POST_TABLE_ITEM_SUCCESS'))
      const headerAndButtons = yield call(getSaleHeaderAndButtons, auth.token, salesDossier.dossierId)
      const checkListHeader = yield call(getStageCheckList, salesDossier.dossierId, auth.token)
      sales = { ...headerAndButtons }
      sales = { ...sales, checkListHeader }
      yield put(fetchSalesDossierSuccess(sales))
      yield put(fetchSalesDossierDependants(dossierId))
      yield call(closeModalFunc)
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          yield call(closeModalFunc)
          const salesDossierState = yield select(state => state.salesDossier)
          yield call(initializeDossier, dossierTypesConstant.sales, salesDossierState)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('MESSAGES.POST_TABLE_ITEM_ERROR', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchCreateCampaign () {
  yield takeEvery(dossierActionsTypes.CREATE_CAMPAIGN, createCampaign)
}
export function * openSearchDocumentsModal ({ openCampaignDocumentFn }) {
  try {
    yield call(openCampaignDocumentFn)
  } catch (error) {
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchOpenSearchDocumentsModal () {
  yield takeEvery(dossierActionsTypes.OPEN_SEARCH_DOCUMENTS_MODAL, openSearchDocumentsModal)
}

export function * fetchAllDocuments ({ filter }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const dossierId = yield select(state => state.salesDossier.dossierId)
    const documents = yield call(postDocumentsUploadedByDossierId, dossierId, auth.token, filter)
    yield put(fetchAllDocumentsSuccess(documents))
  } catch (error) {
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchAllDocuments () {
  yield takeEvery(dossierActionsTypes.FETCH_DOCUMENTS_DOSSIERID, fetchAllDocuments)
}

export function * fetchAllDocumentHistoricals ({ filter, dossierId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const documents = yield call(postDocumentHistoricalsUploadedByDossierId, dossierId, auth.token, filter)
    yield put(fetchAllDocumentHistoricalsSuccess(documents))
  } catch (error) {
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchAllDocumentHistoricals () {
  yield takeEvery(dossierActionsTypes.FETCH_DOCUMENT_HISTORICALS_DOSSIERID, fetchAllDocumentHistoricals)
}

export function * saveSelectedDocuments ({ selectedDocuments, selectedDocumentHistorical }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(downloadDocuments, selectedDocuments, selectedDocumentHistorical, auth.token)
  } catch (error) {
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('registrationDeliveryDossier'))
    }
    yield call(handleError, { error, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSaveSelectedDocuments () {
  yield takeEvery(dossierActionsTypes.SAVE_SELECTED_DOCUMENTS, saveSelectedDocuments)
}
