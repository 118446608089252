import { months } from '../constants/backendIds'

export function validatePrivateRfcFormat (value) {
  if (value) {
    var exp = /^[ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789]{13}$/i
    var str = value.toString().toUpperCase()
    return exp.test(str)
  } else {
    return false
  }
}

export function validatePrivateCURPFormat (value) {
  if (value) {
    var exp = /^[ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789]{18}$/i
    var str = value.toString().toUpperCase()
    return exp.test(str)
  } else {
    return false
  }
}

export function validatePrivateCURP (value) {
  if (value) {
    var exp = /^[ABCDEFGHIJKLMNOPQRSTUVWXYZ]{4}[0-9]{6}[HM]{1}[ABCDEFGHIJKLMNOPQRSTUVWXYZ]{5}[ABCDEFGHIJKLMNOPQRSTUVWXYZ 0-9]{1}[0-9]{1}$/i
    var str = value.toString().toUpperCase()
    return exp.test(str)
  } else {
    return false
  }
}

export function validateBusinessRfcFormat (value) {
  if (value) {
    var exp = /^[ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789]{12}$/i
    var str = value.toString().toUpperCase()
    return exp.test(str)
  } else {
    return false
  }
}

export function validatePrivateRfc (value) {
  if (value) {
    var exp = /^[ABCDEFGHIJKLMNOPQRSTUVWXYZ]{4}[0-9]{6}[ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789]{3}$/i
    var str = value.toString().toUpperCase()
    return exp.test(str)
  } else {
    return false
  }
}

export function validateBusinessRfc (value) {
  if (value) {
    var exp = /^[ABCDEFGHIJKLMNOPQRSTUVWXYZ]{3}[0-9]{6}[ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789]{3}$/i
    var str = value.toString().toUpperCase()
    return exp.test(str)
  } else {
    return false
  }
}

export function validatePrivateRfcDate (value) {
  if (value) {
    let date = value.slice(4, 10)
    let year = parseInt(date.slice(0, 2))
    let month = parseInt(date.slice(2, 4))
    let day = parseInt(date.slice(4))
    let checkRangeDate = year > 0 && month > 0 && month <= 12 && day > 0 && day <= months[month]
    return (month === 2 && day === 29) ? isLeapYear1900(year) : checkRangeDate
  } else {
    return false
  }
}

export function validateBusinessRfcDate (value) {
  if (value) {
    let date = value.slice(3, 9)
    let year = parseInt(date.slice(0, 2))
    let month = parseInt(date.slice(2, 4))
    let day = parseInt(date.slice(4))
    let checkRangeDate = year > 0 && month > 0 && month <= 12 && day > 0 && day <= months[month]
    return (month === 2 && day === 29) ? isLeapYear1900(year) : checkRangeDate
  } else {
    return false
  }
}

export function isLeapYear1900 (value) {
  let year = 1900 + value
  return ((year % 4) === 0 && ((year % 100) !== 0 || (year % 400) === 0)) || isLeapYear2000(value)
}

export function isLeapYear2000 (value) {
  let year = 2000 + value
  return new Date().getFullYear() >= year && (year % 4) === 0 && ((year % 100) !== 0 || (year % 400) === 0)
}

export function onlySpaces (str) {
  return str.trim().length === 0
}
