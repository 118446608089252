import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { closeStyleTaskMasterModal, submitStyleTask, getOrdersStyleTask } from '../../../actions/masters/masters'
import StyleTaskMasterModal from '../../../components/masters/styleTask/StyleTaskMasterModal'

export function mapStateToProps (state) {
  const styleTaskModal = state.styleTask.styleTaskModal
  const styleTaskList = state.styleTask.styleTaskList
  return {
    showModal: styleTaskModal.showModal,
    styleTaskId: styleTaskModal.taskId,
    styleTask: styleTaskList.find(b => b.styleTaskId === styleTaskModal.taskId),
    comboId: styleTaskModal.comboId,
    styleTaskComboId: styleTaskModal.comboId
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      submitStyleTask,
      closeStyleTaskMasterModal,
      getOrdersStyleTask
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(StyleTaskMasterModal))
