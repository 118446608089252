import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { stopAsyncValidation } from 'redux-form'
import { translate } from 'react-polyglot'
import clientActions from '../../../../actions/dossier/common/client'
import UACModal from './UACModal'
import { openModal as openCommonModal } from '../../../../actions/common'
import { closeUacModal } from '../../../../actions/management/management'
import uacActions from '../../../../actions/dossier/common/uac'
import combosActions from '../../../../actions/combos/combos'
import dossierSalesActions from '../../../../actions/dossier/sales'
import { validate } from '../../../../actions/dossier/common/validations'

export function mapStateToProps (state) {
  return {
    ...state.management.uacModal,
    entityFilterSubTypeCombo: state.combos.entityFilterSubTypeCombo,
    entitySubTypeCombo: state.combos.entitySubTypeCombo,
    combos: state.combos
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      ...clientActions,
      ...uacActions,
      ...dossierSalesActions,
      ...combosActions,
      openCommonModal,
      stopAsyncValidation,
      closeModal: closeUacModal,
      validate

    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(UACModal))
