import apiFetch from '../apiFetch'

export default function (token) {
  // return {
  //   maxNumberOfOtherAttributeCharsContainedInPassword: 4,
  //   minNumberOfCharactersInPassword: 8,
  //   minNumberOfCriteria: 3
  // }
  return apiFetch({ endPoint: 'passwordValidationParams', method: 'GET', body: null, token: token })
}
