/* eslint-disable no-unused-vars */
import { put, call, select, takeEvery, all } from 'redux-saga/effects'
import { reset, initialize, change } from 'redux-form'
import { replace } from 'connected-react-router'
import { permissions } from '../../../constants/backendIds'
import { buttonsHeader, buttonsHeaderPermisionsIdReverse } from '../../../constants/dossier/common/buttonsHeader'
import dossierActionsTypes from '../../../constants/actions/dossier/dossier'
import { chassisLength } from '../../../constants/dossier/common/vehicle'
import { showLoader, hideLoader } from '../../../actions/common'
import messageModalActions from '../../../actions/modals/messageModal'
import { refreshDocumentManagementModal } from '../../../actions/dossier/common/documentManagementModal'
import { setActiveTab } from '../../../actions/dossier/common/buttonsHeader'
import { fetchServiceDossierSuccess } from '../../../actions/dossier/serviceDossier'
import { fetchCombosSuccess } from '../../../actions/combos/combos'
import { getAuth } from '../../../selectors/access/auth'
import { getActiveTab } from '../../../selectors/dossier/common/buttonsHeader'
import { getDossierRelatedDossiersConf } from '../../../selectors/dossier/common/dossierRelatedDossiers'
import getServiceHistoricalDossier from '../../../services/serviceDossier/get/getServiceHistoricalDossier'
import getServiceDossier from '../../../services/serviceDossier/get/getServiceDossier'
import getStageCheckListService from '../../../services/dossier/get/getStageCheckList'
import getDossierRelatedDossiers from '../../../services/dossier/get/getDossierRelatedDossiers'
import getDossierHistoricalRelatedDossiers from '../../../services/dossier/get/getDossierHistoricalRelatedDossiers'
import getPurchaseWithVehicleById from '../../../services/dossier/get/getPurchaseWithVehicleById'
import getPurchaseHistoricalWithVehicleById from '../../../services/dossier/get/getPurchaseHistoricalWithVehicleById'
import getPreviousUsage from '../../../services/previousUsage/getPreviousUsage'
import getBrands from '../../../services/brand/getBrands'
import getFuels from '../../../services/fuel/getFuels'
import getFamily from '../../../services/family/getFamily'
import { handleError } from '../../errors/errorManager'
import { fetchEntitySubTypeCombo } from '../../combos/fetchEntitySubTypeCombo'
import { fetchEntityFilterSubTypeCombo } from '../../combos/fetchEntityFilterSubTypeCombo'
import { switchFavoriteSuccess } from '../../../actions/dossier/common/statusHeader'
// Vehicle
import getModelCombo from '../../../services/model/getModelCombo'
import getCarlineCombo from '../../../services/carline/getCarlineCombo'
import getCatalogueCombo from '../../../services/catalogue/getCatalogueCombo'
import getColorCombo from '../../../services/color/getColorCombo'
import getTapestryCombo from '../../../services/tapestry/getTapestryCombo'
import getCatalogueModel from '../../../services/catalogueModel/getCatalogueModel'

export function * fetchServiceDossier ({ dossierId, resolve, reject, historical = false }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const dossierRelatedDossiersConf = yield select(getDossierRelatedDossiersConf)
    let dossier = {}
    let checkListHeader = []
    let dossierRelatedDossiers = []
    const auditInfo = []
    const serviceDossierState = yield select(state => state.serviceDossier)

    if (historical) {
      dossier = yield call(getServiceHistoricalDossier, dossierId, auth.token)
      dossierRelatedDossiers = yield call(getDossierHistoricalRelatedDossiers, dossierId, dossierRelatedDossiersConf.orderby, auth.token)
    } else {
      dossier = yield call(getServiceDossier, dossierId, auth.token)
      checkListHeader = yield call(getStageCheckListService, dossierId, auth.token)
      dossierRelatedDossiers = yield call(getDossierRelatedDossiers, dossierId, dossierRelatedDossiersConf.orderby, auth.token)
    }

    let dossierServiceComponent = { ...dossier.dossierServiceComponent }

    const [
      brandCombo,
      fuelCombo,
      previousUsageCombo,
      familyCombo,
      modelCombo
    ] = yield all([
      call(getBrands, auth.token, dossier.dossierSubTypeId, dossier.organizedUnitId),
      call(getFuels, auth.token, historical),
      call(getPreviousUsage, dossier.organizedUnitId, auth.token),
      call(getFamily, auth.token),
      call(getModelCombo, auth.token)
    ])

    let vehicleComponent = serviceDossierState.vehicleComponent
    vehicleComponent = { ...vehicleComponent, ...dossier.vehicleComponent }
    if (vehicleComponent.vinChassis) {
      if (vehicleComponent.vinChassis.length === chassisLength.total) {
        vehicleComponent.vinChassisLeft = vehicleComponent.vinChassis.substring(0, chassisLength.left)
        vehicleComponent.vinChassisRight = vehicleComponent.vinChassis.substring(chassisLength.left, chassisLength.total)
      } else {
        vehicleComponent.vinChassisRight = vehicleComponent.vinChassis
      }
    }

    yield call(fetchEntitySubTypeCombo, { entityTypeId: 5 })
    if (dossier.dossierServiceComponent.entitySubTypeId) {
      yield call(fetchEntityFilterSubTypeCombo, { entityTypeId: 5, entitySubTypeId: dossier.dossierServiceComponent.entitySubTypeId, organizedUnitId: dossier.organizedUnitId })
    }

    if (serviceDossierState.vehicleComponent.updated === true) {
      vehicleComponent = yield call(getPurchaseWithVehicleById, dossierId, auth.token)
      vehicleComponent.updated = true
    }

    yield put(fetchServiceDossierSuccess({ ...dossier, dossierServiceComponent, dossierRelatedDossiers, auditInfo, vehicleComponent }, checkListHeader))

    yield put(switchFavoriteSuccess(dossier.dossierHeaderComponent.favorite))

    yield put(fetchCombosSuccess({
      brandCombo,
      fuelCombo,
      previousUsageCombo,
      familyCombo,
      modelCombo
    }))

    const currentActiveTab = yield select(getActiveTab)
    let nextActiveTab = dossier.viewConfiguration.tabsConfiguration.filter((tabConf) => tabConf.permission > permissions.hidden)
    nextActiveTab = nextActiveTab.length > 0 ? nextActiveTab[0] : currentActiveTab
    if (nextActiveTab !== currentActiveTab) yield put(setActiveTab(buttonsHeaderPermisionsIdReverse[nextActiveTab.code]))

    if (!historical && nextActiveTab === buttonsHeader.gestor_documental) {
      yield put(refreshDocumentManagementModal(dossierId))
    }

    if (resolve) yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.status === 301) {
        yield put(replace(`/dossier${customHandlerError.json}`))
      } else {
        yield put(messageModalActions.openErrorMessageModal('MESSAGES.SERVICE_DOSSIER.ERROR_GET_DOSSIER', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
      if (reject) yield call(reject, customHandlerError)
    }
    const sessionHandler = function * () {
      yield put(reset('service_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchServiceDossier () {
  yield takeEvery(dossierActionsTypes.FETCH_SERVICE_DOSSIER, fetchServiceDossier)
}

export function * refreshServiceDossierFromDocumentModal (documentEditionalModalState) {
  try {
    yield put(showLoader())
    const serviceDossier = yield select(state => state.serviceDossier)
    const auth = yield select(getAuth)
    const dossierId = serviceDossier.dossierId
    const [
      dossier,
      checkListHeader
    ] = yield all([
      call(getServiceDossier, dossierId, auth.token),
      call(getStageCheckListService, dossierId, auth.token)
    ])

    yield put(fetchServiceDossierSuccess({ ...dossier }, checkListHeader))

    if (!documentEditionalModalState && !documentEditionalModalState.dirty) {
      const updateServiceDossier = yield select(state => state.serviceDossier)
      yield put(initialize('service_dossier', { ...updateServiceDossier }))
    }
    yield put(hideLoader())
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.SERVICE_DOSSIER.ERROR_GET_DOSSIER', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('service_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * fetchServiceDossierVehicle ({ dossierId, resolve, reject, pristine }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const serviceDossier = yield select(state => state.serviceDossier)
    const vehicleComponent = yield call(getPurchaseWithVehicleById, dossierId, auth.token)
    vehicleComponent.updated = true

    yield put(change('service_dossier', 'vehicleComponent', vehicleComponent))
    yield put(fetchServiceDossierSuccess({ vehicleComponent: vehicleComponent }))
    if (pristine === true || pristine === undefined) {
      yield put(initialize('service_dossier', { ...serviceDossier, vehicleComponent: vehicleComponent }))
    }

    if (resolve) yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.SERVICE_DOSSIER.ERROR_GET_DOSSIER', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('service_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchServiceVehicle () {
  yield takeEvery(dossierActionsTypes.FETCH_SERVICE_DOSSIER_VEHICLE, fetchServiceDossierVehicle)
}

export function * fetchServiceDossierVehicleHistorical ({ dossierId, resolve, reject }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const vehicleComponent = yield call(getPurchaseHistoricalWithVehicleById, dossierId, auth.token)
    yield put(change('service_dossier', 'vehicleComponent', vehicleComponent.vehicleId ? vehicleComponent : {}))
    yield put(fetchServiceDossierSuccess({ vehicleComponent }))
    let carlineCombo = []; let catalogueCombo = []; let colorCombo = []; let tapestryCombo = []

    if (vehicleComponent.brandId) {
      carlineCombo = yield call(getCarlineCombo, auth.token, vehicleComponent.brandId)
    }
    if (vehicleComponent.carlineId) {
      catalogueCombo = yield call(getCatalogueCombo, auth.token, vehicleComponent.carlineId)
    }
    if (vehicleComponent.modelId && vehicleComponent.catalogueId) {
      colorCombo = yield call(getColorCombo, auth.token, vehicleComponent.catalogueId, vehicleComponent.modelId)
      tapestryCombo = yield call(getTapestryCombo, auth.token, vehicleComponent.catalogueId, vehicleComponent.modelId)
      var catalogueModel = yield call(getCatalogueModel, auth.token, vehicleComponent.catalogueId, vehicleComponent.modelId)
      vehicleComponent.model = catalogueModel.description || ''
      vehicleComponent.catalogueModelId = catalogueModel.catalogueModelId || ''
    }

    yield put(fetchCombosSuccess({ carlineCombo, catalogueCombo, colorCombo, tapestryCombo }))
    if (resolve) yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.SERVICE_DOSSIER.ERROR_GET_DOSSIER', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('service_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchServiceDossierVehicleHistorical () {
  yield takeEvery(dossierActionsTypes.FETCH_SERVICE_DOSSIER_VEHICLE_HISTORICAL, fetchServiceDossierVehicleHistorical)
}
