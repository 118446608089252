import React, { PureComponent } from 'react'
import { Panel, Table, Button } from 'react-bootstrap'
import SmallLoaderPage from '../../../../containers/commons/SmallLoaderPage'
import { compareObjects } from '../../../../util/utils'
import { getFieldsPermisions } from '../../../../util/permisionFunctions'
import ServiceRow from '../../../../_v2/components/dossiers/common/services/ServiceRow'
import ServiceObservationsModal from '../../../../components/dossiers/common/services/ServiceObservationsModal'
import { dossierSubTypes } from '../../../../constants/backendIds'
import ServiceAddModal from '../../../../components/dossiers/common/services/ServiceAddModal'
import CustomFields from '../../../../components/commons/customFields/CustomFields'
import ComponentTitle from '../../../../_v2/containers/dossiers/commons/ComponentTitlePage'

class Services extends PureComponent {
  constructor () {
    super()
    this.state = {
      open: false,
      showModal: false,
      service: null,
      showAddModal: false,
      fieldsConfiguration: { },
      destinationAvailable: false
    }
    this.listSubTypesWithDefaultEntity = [dossierSubTypes.service_pap, dossierSubTypes.deliveryPreparation]
    this.listSubTypesWithBlockedEntity = [dossierSubTypes.deliveryPreparation]
    this.listSubTypesWithOrderDateByDefault = [dossierSubTypes.service_pap]
    this.listSubTypesWithOrderDateTodayNoEditable = [dossierSubTypes.deliveryPreparation]
    this.listSubTypesWithMandatoryObservations = [dossierSubTypes.deliveryPreparation]
    this.listSubTypesWithRequiredDatesInvalids = [dossierSubTypes.deliveryPreparation]
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (this.props.dossier && this.props.dossier.dossierId) {
      if (!compareObjects(prevProps.componentConfiguration || {}, this.props.componentConfiguration, true)) {
        this.initializePermisions(this.props)
      }
      if (this.props.open) {
        this.setState({ open: true })
      }
      if (this.props.subTypesAllowedToCreate && this.props.subTypesAllowedToCreate !== prevProps.subTypesAllowedToCreate && Array.isArray(this.props.subTypesAllowedToCreate)) {
        this.setState({ destinationAvailable: this.props.subTypesAllowedToCreate.includes(dossierSubTypes.service_pap) })
      }
    }
    return prevProps
  }

  componentDidMount () {
    this.initializePermisions(this.props)
  }

  componentDidUpdate () {
    // don't remove use for getsnapshot
  }

  initializePermisions (props) {
    const fieldKeys = Object.keys(this.state.fieldsConfiguration)
    const newState = getFieldsPermisions(props.componentConfiguration.fieldsConfiguration, fieldKeys, props.componentConfiguration.permission)
    this.setState({ fieldsConfiguration: newState.fieldsConfiguration })
  }

  toggleComponent () {
    if (!this.state.open) {
      this.props.actions.fetchSalesServices(this.props.dossier.dossierId, this.props.historical)
    } else {
      this.props.actions.setActiveSection(false)
    }
    this.setState({ open: !this.state.open })
  }

  showServiceObservationModal (show, service) {
    this.setState({ showModal: show, service: service })
  }

  render () {
    const {
      t, readOnlyFields, dossierType, services = [], canAdd, isPurchase, defaultDestination, customFields,
      dossier: { dossierId, organizedUnitId }, combos, actions: { fetchDossierSubTypes, fetchEntityFilterSubTypeCombo, fetchEntitySubTypeCombo, saveSalesService, fetchDefaultEntity, fetchNotValidDatesService, openOrCloseServiceAddModal }
    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.SALE_SERVICES.'
    return (
      <div className="dossier-Component">
        <ServiceObservationsModal
          t={t}
          showModal={this.state.showModal}
          service={this.state.service}
          actions={{ closeServiceObservationModal: () => this.showServiceObservationModal(false, null) }}
        />
        <ServiceAddModal
          t={t}
          showModal={this.props.showAddModal}
          isPurchase = {isPurchase}
          ouId={organizedUnitId}
          subTypesAllowedToCreate={this.props.subTypesAllowedToCreate}
          dossierId={dossierId}
          datesNotValid={this.props.datesNotValid || []}
          actions={{
            openOrCloseAddServiceModal: () => openOrCloseServiceAddModal(false),
            fetchDossierSubTypes,
            fetchEntityFilterSubTypeCombo,
            fetchEntitySubTypeCombo,
            saveSalesService,
            fetchDefaultEntity,
            fetchNotValidDatesService
          }}
          dossierConstriction= {{
            listSubTypesWithOrderDateTodayNoEditable: this.listSubTypesWithOrderDateTodayNoEditable || [],
            listSubTypesWithDefaultEntity: this.listSubTypesWithDefaultEntity || [],
            listSubTypesWithBlockedEntity: this.listSubTypesWithBlockedEntity || [],
            listSubTypesWithRequiredDatesInvalids: this.listSubTypesWithRequiredDatesInvalids || [],
            listSubTypesWithOrderDateByDefault: this.listSubTypesWithOrderDateByDefault || [],
            listSubTypesWithMandatoryObservations: this.listSubTypesWithMandatoryObservations || []
          }}
          combos= {combos}
          defaultDestination={defaultDestination}
          destinationAvailable={this.state.destinationAvailable}
        />
        <div className="header-panel" onClick={() => this.toggleComponent()}>
          <ComponentTitle
            componentCode="SERVICES"
            componentOpen={this.state.open}
          />
        </div>
        <Panel expanded={this.state.open} onToggle={() => { }}>
          <Panel.Collapse>
            <div className="fieldsContainer">
              <div className='wrapper _nf'>
                <Table className="editable-table" hover responsive>
                  <thead>
                    <tr>
                      <th>{t(`${tKey}DOSSIER_NUMBER`)}</th>
                      <th>{t(`${tKey}SERVICE_SUBTYPE`)}</th>
                      <th>{t(`${tKey}DOSSIER_STAGE`)}</th>
                      <th>{t(`${tKey}ENTITY`)}</th>
                      <th>{t(`${tKey}DESTINY`)}</th>
                      <th>{t(`${tKey}ORDER_DATE`)}</th>
                      <th>{t(`${tKey}REQUIRED_DATE`)}</th>
                      <th>{t(`${tKey}AVAILABILITY_DATE`)}</th>
                      <th>{t(`${tKey}CONFIRMED_DATE`)}</th>
                      <th className='actions'>{t(`${tKey}OBSERVATIONS`)}</th>
                      {isPurchase === undefined && (
                        <th>{t(`${tKey}PURCHASE_SERVICE`)}</th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {
                      services.map((service, idx) => {
                        return (
                          <ServiceRow
                            t={t}
                            tKey={tKey}
                            service={service}
                            key={idx}
                            dossierId={dossierId}
                            organizedUnitId={organizedUnitId}
                            actions={ {
                              showServiceObservationModal: () => this.showServiceObservationModal(true, service)
                            } }
                            dossierType={dossierType}
                            readOnlyFields={readOnlyFields}
                            destinationAvailable={this.state.destinationAvailable}
                            isPurchase={isPurchase}
                          />
                        )
                      })
                    }
                  </tbody>
                  <div className='flexInLine al-L'>
                    {!readOnlyFields && canAdd &&
                      <Button
                        id="btn_add_service"
                        className="_Btn new"
                        onClick={() => openOrCloseServiceAddModal()}>
                        <i className="iDocIcon-add-plus"></i>
                        {t(`${tKey}ADD_BTN`)}
                      </Button>
                    }
                  </div>
                </Table>
              </div>
            </div>
            <CustomFields customFields={customFields} readOnlyFields={readOnlyFields} />
          </Panel.Collapse>
        </Panel>
        <SmallLoaderPage />
      </div>
    )
  }
}
export default Services
