import userActionTypes from '../../constants/actions/admin/userAdmin'
import subscriptionActionTypes from '../../constants/actions/admin/subscriptionsAdmin'
import { addUO, deleteUO } from '../../actions/admin/userAdmin'

function initialState () {
  return {
    userList: [],
    pagesCount: 0,
    usersCount: 0,
    filter: {
      page: 1,
      orderBy: '',
      isDeleted: null,
      pageSize: 10
    },
    userAdminModal: {
      userId: null,
      userName: '',
      uoRoles: [],
      uoSalesman: [],
      activeUoRolId: null,
      showModal: false,
      showModalUpload: false
    },
    eventSubscriptions: []
  }
}

function fetchUserAdminSuccess (state, { userList, filter }) {
  return {
    ...state,
    userList: userList.userList,
    usersCount: userList.usersCount,
    pagesCount: userList.pagesCount,
    filter
  }
}

function openEditModalSuccess (state, { user }) {
  return {
    ...state,
    userAdminModal: {
      ...state.userAdminModal,
      ...user,
      showModal: true
    }
  }
}

function openAddModalSuccess (state, { uoRoles, uoSalesman }) {
  const initial = initialState()
  return {
    ...state,
    userAdminModal: {
      ...initial.userAdminModal,
      showModal: true,
      uoRoles,
      uoSalesman
    }
  }
}

function closeEditModalSuccess (state) {
  return {
    ...state,
    userAdminModal: {
      ...initialState().userAdminModal
    }
  }
}

function setActiveUoRol (state, { activeUoRolId }) {
  return {
    ...state,
    userAdminModal: {
      ...state.userAdminModal,
      activeUoRolId
    }
  }
}

function addPermission (state, { permission }) {
  const uoRoles = state.userAdminModal.uoRoles.map(uoRol => {
    if (uoRol.organizedUnitId === state.userAdminModal.activeUoRolId) {
      const newPermission = uoRol.notOwnedRoles.find(notOwnedRol => notOwnedRol.roleId === permission)
      uoRol.notOwnedRoles = uoRol.notOwnedRoles.filter(notOwnedRol => notOwnedRol.roleId !== permission)
      uoRol.ownedRoles.push(newPermission)
    }
    return uoRol
  })
  return {
    ...state,
    userAdminModal: {
      ...state.userAdminModal,
      uoRoles
    }
  }
}

function deletePermission (state, { permission }) {
  const uoRoles = state.userAdminModal.uoRoles.map(uoRol => {
    if (uoRol.organizedUnitId === state.userAdminModal.activeUoRolId) {
      uoRol.ownedRoles = uoRol.ownedRoles.filter(ownedRol => ownedRol.roleId !== permission.roleId)
      uoRol.notOwnedRoles.push(permission)
    }
    return uoRol
  })
  return {
    ...state,
    userAdminModal: {
      ...state.userAdminModal,
      uoRoles
    }
  }
}

export function openUploadSignModalSuccess (state) {
  return {
    ...state,
    userAdminModal: {
      ...state.userAdminModal,
      showModalUpload: true,
      showModal: true
    }
  }
}

function closeUploadSignModalSuccess (state) {
  return {
    ...state,
    userAdminModal: {
      ...state.userAdminModal,
      showModal: true,
      showModalUpload: false
    }
  }
}
function UploadModalSuccess (state) {
  return {
    ...state
  }
}

function clearUserAdminSearchFilter (state) {
  return {
    ...initialState()
  }
}

function fetchUserSubscriptionsFromAdminSuccess (state, { eventSubscriptions }) {
  return {
    ...state,
    eventSubscriptions
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case userActionTypes.FETCH_USER_ADMIN_SUCCESS:
      return fetchUserAdminSuccess(state, action)
    case userActionTypes.OPEN_EDIT_USER_MODAL_SUCCESS:
      return openEditModalSuccess(state, action)
    case userActionTypes.OPEN_ADD_USER_MODAL_SUCCESS:
      return openAddModalSuccess(state, action)
    case userActionTypes.CLOSE_EDIT_USER_MODAL:
      return closeEditModalSuccess(state, action)
    case userActionTypes.SET_ACTIVE_UO_ROL:
      return setActiveUoRol(state, action)
    case userActionTypes.ADD_PERMISSION:
      return addPermission(state, action)
    case userActionTypes.ADD_UO:
      return addUO(state, action)
    case userActionTypes.DELETE_UO:
      return deleteUO(state, action)
    case userActionTypes.DELETE_PERMISSION:
      return deletePermission(state, action)
    case userActionTypes.OPEN_SIGN_UPLOAD_MODAL_SUCCESS:
      return openUploadSignModalSuccess(state, action)
    case userActionTypes.CLOSE_SIGN_UPLOAD_MODAL_SUCCESS:
      return closeUploadSignModalSuccess(state, action)
    case userActionTypes.UPLOAD_SIGN:
      return UploadModalSuccess(state, action)
    case userActionTypes.CLEAR_USER_ADMIN_SEARCH_FILTER:
      return clearUserAdminSearchFilter(state, action)
    case subscriptionActionTypes.FETCH_EVENT_SUBSCRIPTIONS_FROM_ADMIN_SUCCESS:
      return fetchUserSubscriptionsFromAdminSuccess(state, action)
    default:
      return state
  }
}
