import React, { PureComponent } from 'react'
import { Button, Modal, Col, Form, Row } from 'react-bootstrap'
import { Field, reduxForm } from 'redux-form'
import InputText from '../../commons/form/InputText'
import InputSelect from '../../commons/form/InputSelect'
import InputMoney from '../../commons/form/InputMoney'

class DossierBudgetRatingModal extends PureComponent {
  submit (values) {
    this.props.actions.addEditDossierBudgetRating(values)
    /* new Promise((resolve, reject) => {
      this.props.actions.saveFleetCode(values.fleetNumberCombo || values.fleetNumber, resolve)
    }).then(() => {
      this.props.actions.closeSaleFleetModal()
    }) */
  }

  componentDidMount () {
    // this.props.actions.fetchRatingCombo()
    this.props.actions.fetchRatingComboByOrganizedUnit(this.props.organizedUnitId)
  }

  render () {
    const {
      t, handleSubmit, showModal, combos: { ratingComboByOrganizedUnit }, dossierBudgetRatingId, formValues, actions: { fetchDossierBudgetRating }
    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.DOSSIER_BUDGET.DOSSIER_BUDGET_RATING_MODAL.'
    return (
      <Modal className="dossier-budget-rating-modal" show={showModal} onHide={() => fetchDossierBudgetRating(null)}>
        <Form autoComplete="off" onSubmit={handleSubmit(this.submit.bind(this))}>
          <Modal.Header closeButton>
            <Modal.Title>{t(`${tKey}TITLE`)}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className='tittle-section flexRow'>
              <span>{t(`${tKey}ADD_RATING`)}</span>
            </Row>
            <Row className="flexRow">
              <Field
                colSm={6}
                id="ratingId"
                name="ratingId"
                controlLabel={t(`${tKey}SELECT_RATING`)}
                placeholder={t(`${tKey}SELECT_RATING`)}
                valueKey="id"
                labelKey="value"
                options={ratingComboByOrganizedUnit}
                component={InputSelect}
                disabled={ formValues && (formValues.freeRating || formValues.amount > 0) }
              />
            </Row>
            <Row className='tittle-section flexRow'>
              <span>{t(`${tKey}ADD_CUSTOM_RATING`)}</span>
            </Row>
            <Row>
              <Field
                colSm={6}
                id="freeRating"
                name="freeRating"
                controlLabel={t(`${tKey}FREE_RATING`)}
                placeholder={t(`${tKey}FREE_RATING`)}
                disabled={formValues && formValues.ratingId}
                component={InputText}/>
              <Field
                colSm={6}
                controlLabel={t(`${tKey}AMOUNT`)}
                placeholder={t(`${tKey}AMOUNT`)}
                id="amount"
                name="amount"
                component={InputMoney}
                disabled={formValues && formValues.ratingId}
                textAlign="right"
                paddingLeft={{ paddingLeft: '0px' }}/>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Col sm={12} className="btn-wrapper">
              <Button id="btn_save" className="btn-standard" type="submit" disabled={!formValues || Object.keys(formValues).length === 0 ||
                    (!formValues.ratingId && (!formValues.freeRating || !formValues.amount))}>
                {
                  dossierBudgetRatingId ? t(`${tKey}EDIT`) : t(`${tKey}ADD`)
                }
              </Button>
            </Col>
          </Modal.Footer>
        </Form>
      </Modal>
    )
  }
}

export default reduxForm({
  form: 'budgetRatingModal',
  validate: (values, props) => {
    let errors = {}
    if (values) {
      if (!values.ratingId) {
        if (values.freeRating && !values.amount) {
          errors.amount = props.t('DOSSIER_COMPONENTS.DOSSIER_BUDGET.DOSSIER_BUDGET_RATING_MODAL.VALIDATIONS.AMOUNT_MANDATORY')
        }
        if (!values.freeRating && values.amount) {
          errors.freeRating = props.t('DOSSIER_COMPONENTS.DOSSIER_BUDGET.DOSSIER_BUDGET_RATING_MODAL.VALIDATIONS.FREE_RATING_MANDATORY')
        }
      }
    }
    return errors
  }
})(DossierBudgetRatingModal)
