import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import ApprovalActionModal from '../../components/management/ApprovalActionModal'
import { translate } from 'react-polyglot'
import { closeApprovalActionModal } from '../../actions/masters/masters'
import { openModal } from '../../actions/common'
import { sendToApproveActionFleet, approveActionFleet, approveRoleActionFleet } from '../../actions/management/management'
import { setApproveActionProcess } from '../../actions/unattendedProcess/unattendedProcess'
export function mapStateToProps (state) {
  return {
    ...state.management.approvalActionModal
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      closeApprovalActionModal,
      openModal,
      sendToApproveActionFleet,
      approveActionFleet,
      approveRoleActionFleet,
      setApproveActionProcess
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(ApprovalActionModal))
