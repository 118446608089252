import { call, put, all, takeEvery, select } from 'redux-saga/effects'
import { reset } from 'redux-form'
import dossierActionsTypes from '../../../constants/actions/dossier/dossier'
import { getAuth } from '../../../selectors/access/auth'
import getDossierSaleHeader from '../../../services/dossierSale/getDossierSaleHeader'
import getStageCheckList from '../../../services/dossier/get/getStageCheckList'
import getDocumentaryCheckList from '../../../services/document/getDocumentaryCheckList'
import { fetchRegistrationDeliveryDossierSuccess } from '../../../actions/dossier/registrationDelivery'
import { showLoader, hideLoader } from '../../../actions/common'
import { fetchDependantsRegistrationDeliveryDossier } from '../registrationDelivery/fetchRegistrationDeliveryDossier'
import { handleError } from '../../errors/errorManager'

export function * updateDependantsRegistrationDeliveryDossierAndHeader ({ dossierId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const [dossierComplete, checkListHeader, registrationDeliveryDocumentaryCheckList] = yield all([
      call(getDossierSaleHeader, dossierId, auth.token),
      call(getStageCheckList, dossierId, auth.token),
      call(getDocumentaryCheckList, auth.token, dossierId)
    ])
    yield put(fetchRegistrationDeliveryDossierSuccess({ ...dossierComplete, checkListHeader, registrationDeliveryDocumentaryCheckList }))
    yield call(fetchDependantsRegistrationDeliveryDossier, { dossierId })
  } catch (error) {
    const sessionHandler = function * () {
      yield put(reset('registrationDeliveryDossier'))
    }
    yield call(handleError, { error, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchUpdateDependantsRegistrationDeliveryDossierAndHeader () {
  yield takeEvery(dossierActionsTypes.UPDATE_DEPENDANTS_REGISTRATION_DELIVERY_DOSSIER_AND_HEADER, updateDependantsRegistrationDeliveryDossierAndHeader)
}
