import React, { PureComponent } from 'react'
import { Modal, Button, Checkbox, Table } from 'react-bootstrap'
import HotFolderReport from '../dossiers/common/documentaryManagement/HotFolderReport'
import { Field, reduxForm } from 'redux-form'
import InputText from './form/InputText'
import SimpleInputText from '../commons/simpleForm/SimpleInputText'

class CustomModal extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      value: '',
      open: false,
      componentsValues: {},
      providerObservations: '',
      orNumber: null
    }
    this.toLogin = this.toLogin.bind(this)
  }

  componentDidUpdate (prevProps) {
    if (!prevProps.showModal && this.props.showModal) {
      this.setState({ value: '', providerObservations: '', orNumber: '' })
    }
  }

  toLogin () {
    // this.props.history.push('/login')
    this.props.actions.okResponse()
  }

  onSubmit (values) {
    this.props.actions.okResponse()
  }

  handleSave (value) {
    this.props.actions.valueResponse(this.state.providerObservations)
  }

  schemaYesNo () {
    const { t, actions: { okResponse, nOkResponse } } = this.props
    return ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => okResponse()}>{t('GLOBAL.BUTTONS.YES')}</Button>,

      <Button id="btn_no_modal" className="btn-standard-grey" key="2" onClick={() => { nOkResponse() }}>{t('GLOBAL.BUTTONS.NO')}</Button>])
  }

  schemaOkCancel () {
    const { t, actions: { okResponse, nOkResponse } } = this.props
    return ([<div className='flexInLine al-C' ><Button id="btn_yes_modal" className="_Btn accept" key="1" onClick={() => okResponse()}>{t('GLOBAL.BUTTONS.OK')}</Button>
      <Button id="btn_no_modal" className="_Btn cancel" key="2" onClick={() => { nOkResponse() }}>{t('GLOBAL.BUTTONS.CANCEL')}</Button></div>])
  }

  schemaAcceptCancelORNumber () {
    const { t, actions: { valueResponse, nOkResponse } } = this.props
    return ([<div className="buttons-center-wrapper"><Button id="btn_confirm_modal" className="btn-standard " key="1" onClick={() => valueResponse(this.state.orNumber)}>{t('GLOBAL.BUTTONS.CONFIRM')}</Button>
      <Button className="btn-danger" key="2" onClick={() => nOkResponse()}>{t('GLOBAL.BUTTONS.CANCEL')}</Button></div>])
  }

  replaceTextWithTranslation (text) {
    const regex = /#T#([^#]+)#T#/g

    return text.replace(regex, (match, key) => this.props.t(key))
  }

  render () {
    const { t, showModal, replaceMessageArgs, modalType, actions: { closeModal, okResponse, nOkResponse }, okAction, cancelAction, replaceSubMessageArgs } = this.props
    if (!modalType) return null
    let message = ''
    let subMessage = ''
    let components = (<div />)
    let onHide = closeModal
    let buttons = ([<Button key="1" onClick={() => closeModal()}>Close</Button>])
    let cancelMethod = () => { closeModal() }
    switch (modalType) {
      case 'deleteEventSubscription':
        message = t('MESSAGES.DELETE_EVENT_SUBSCRIPTION')
        subMessage = t('MESSAGES.DELETE_EVENT_SUBSCRIPTION_SUBMESSAGE')
        buttons = ([<Button id="btn_yes_modal" className="_Btn accept" key="1" onClick={() => { okResponse() } }>{t('GLOBAL.BUTTONS.OK')}</Button>,
          <Button id="btn_no_modal" className="_Btn cancel" key="2" onClick={() => { nOkResponse() } }>{t('GLOBAL.BUTTONS.CANCEL')}</Button>])
        onHide = () => { }
        break
      case 'saveDossierAndExecute':
        message = t('MESSAGES.SAVE_AND_EXECUTE')
        buttons = ([<Button id="btn_yes_modal" className="btn-Accept" key="1" onClick={() => { okResponse() } }>{t('GLOBAL.BUTTONS.CONTINUE')}</Button>,
          <Button id="btn_no_modal" className="btn-exit" key="2" onClick={() => { nOkResponse() }}>{t('GLOBAL.BUTTONS.RETURN')}</Button>])
        onHide = () => { }
        break
      case 'deleteGeneric':
        message = t('MESSAGES.DELETE_GENERIC')
        buttons = ([<Button id="btn_yes_modal" className="btn-Accept" key="1" onClick={() => { okResponse() } }>{t('GLOBAL.BUTTONS.CONTINUE')}</Button>,
          <Button id="btn_no_modal" className="btn-exit" key="2" onClick={() => { nOkResponse() }}>{t('GLOBAL.BUTTONS.RETURN')}</Button>])
        onHide = () => { }
        break
      case 'entityLoadSuccessfully':
        message = t('MESSAGES.ENTITY_LOAD_SUCCESSFULLY')
        buttons = ([<Button id="btn_yes_modal" className="btn-Accept" key="1" onClick={() => { okAction(); closeModal() } }>{t('GLOBAL.BUTTONS.OK')}</Button>])
        onHide = () => { }
        break
      case 'clientNotExistsSearchByPhoneMail':
        message = t('MESSAGES.CLIENT_NOT_EXISTS_SEARCH_BY_PHONE_MAIL')
        buttons = ([<Button id="btn_yes_modal" className="btn-Accept" key="1" onClick={() => { okAction(); closeModal() } }>{t('GLOBAL.BUTTONS.CONFIRM')}</Button>,
          <Button id="btn_no_modal" className="btn-exit" key="2" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.CANCEL')}</Button>])
        onHide = () => { }
        break
      case 'entityExistsOnDataBase':
        message = t('MESSAGES.EXISTS_ON_DATABASE')
        buttons = ([<Button id="btn_yes_modal" className="btn-Accept" key="1" onClick={() => { okAction(); closeModal() } }>{t('GLOBAL.BUTTONS.YES')}</Button>,
          <Button id="btn_no_modal" className="btn-exit" key="2" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.NO')}</Button>])
        onHide = () => { }
        break
      case 'recoverCustomField':
        message = t('MESSAGES.CUSTOM_FIELD_MASTER.RECOVER_CUSTOM_FIELD')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'deleteCustomField':
        message = t('MESSAGES.CUSTOM_FIELD_MASTER.DELETE_CUSTOM_FIELD')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'confirmLogitics':
        message = t('MESSAGES.CONFIRM_LOGISTICS')
        replaceMessageArgs.forEach((val) => {
          message = message.replace(val.id, val.value)
        })
        buttons = ([<Button id="btn_yes_modal" className="btn-Accept" key="1" onClick={() => { okAction(); closeModal() } }>{t('GLOBAL.BUTTONS.YES')}</Button>,
          <Button id="btn_no_modal" className="btn-exit" key="2" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.NO')}</Button>])
        onHide = () => { }
        break
      case 'updateDocumentsSearchDocumental':
        message = t('MESSAGES.UPDATE_DOCUMENTS_SEARCH_DOCUMENTAL')
        replaceMessageArgs.forEach((val) => {
          message = message.replace(val.id, val.value)
        })
        buttons = ([<Button id="btn_yes_modal" className="btn-standard" key="1" onClick={() => { okAction(); closeModal() } }>{t('GLOBAL.BUTTONS.OK')}</Button>,
          <Button id="btn_no_modal" className="btn-standard-grey" key="2" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.CANCEL')}</Button>])
        onHide = () => { }
        break
      case 'availabiliteDateSaved':
        message = t('MESSAGES.AVAILABILITY_DATE_CHANGED')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal() }>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => { }
        break
      case 'notSelectDocuments':
        message = t('MESSAGES.NOT_SELECT_DOCUMENT')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal() }>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => { }
        break
      case 'notPossibleEdit':
        message = t('MESSAGES.NOT_POSIBLE_EDIT')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal() }>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => { }
        break
      case 'noChange':
        message = t('MESSAGES.NO_CHANGE')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal() }>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => { }
        break
      case 'requiredData':
        message = t('MESSAGES.REQUIRED_DATA')
        replaceMessageArgs.forEach((val) => {
          message = message.replace(val.id, val.value)
        })
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal() }>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => { }
        break
      case 'confirmSaveAudit':
        message = t('MESSAGES.CONFIRM_SAVE_AUDIT')
        replaceMessageArgs.forEach((val) => {
          message = message.replace(val.id, val.value)
        })
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal() }>{t('GLOBAL.BUTTONS.OK')}</Button>])
        onHide = () => { }
        modalStyle = 'custom-modal AuditFile'
        break
      case 'confirmSaveAvailabilityDate':
        message = t('MESSAGES.CONFIRM_AVAILABILITY_DATE')
        replaceMessageArgs.forEach((val) => {
          message = message.replace(val.id, val.value)
        })
        buttons = ([<Button id="btn_yes_modal" className="btn-Accept" key="1" onClick={() => okResponse()}>{t('GLOBAL.BUTTONS.YES')}</Button>,
          <Button id="btn_no_modal" className="btn-exit" key="2" onClick={() => nOkResponse()}>{t('GLOBAL.BUTTONS.NO')}</Button>])
        break
      case 'mustFillFiltersPapFinder':
        message = t('MESSAGES.MUST_FILL_FILTERS_PAP_FINDER')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => { }
        break
      case 'paperworkDossierReject':
        message = t('MESSAGES.PAPERWORK_DOSSIER.REJECT_PAPERWORK')
        buttons = this.schemaYesNo()
        break
      case 'purchaseDossierReject':
        message = t('MESSAGES.PURCHASE_DOSSIER.REJECT_PAPERWORK')
        buttons = this.schemaYesNo()
        break
      case 'campaignDossierReject':
        message = t('MESSAGES.CAMPAIGN_DOSSIER.REJECT_CAMPAIGN')
        buttons = this.schemaYesNo()
        break
      case 'paperworkGenDelivery':
        // message = t('MESSAGES.PAPERWORK_DOSSIER.GEN_DELIVERY_PAPERWORK')
        message = t('MESSAGES.PAPERWORK_DOSSIER.SEND_TO_AGENCY_PAPERWORK')
        buttons = this.schemaYesNo()
        break
      case 'paperworkTransferCompleted':
        message = t('MESSAGES.PAPERWORK_DOSSIER.TRANSFER_COMPLETED')
        buttons = this.schemaYesNo()
        break
      case 'uacClientIsSaleClient':
        message = t('MESSAGES.UAC_CLIENT_IS_SALE_CLIENT')
        buttons = (<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.OK')}</Button>)
        break
      case 'saleDossierReplace':
        message = t('MESSAGES.SALES_DOSSIER.REPLACE')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'commonDossierCancel':
        message = t('MESSAGES.COMMON_HEADER_BUTTONS.CANCEL')
        subMessage = t('MESSAGES.COMMON_HEADER_BUTTONS.ADVISE')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'commonDossierSaveWarning':
        message = t('MESSAGES.COMMON_HEADER_BUTTONS.SAVE_WARNING')
        // subMessage = t('MESSAGES.COMMON_HEADER_BUTTONS.SAVE_WARNING')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'commonDossierSaveAceptWarning':
        message = t('MESSAGES.COMMON_HEADER_BUTTONS.SAVE_ACEPT_WARNING')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'purchaseDossierChangeEntity':
        message = t('MESSAGES.PURCHASE_DOSSIER.CHANGE_ENTITY')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'purchaseDossierSendApprove':
        message = t('MESSAGES.PURCHASE_DOSSIER.SEND_APPROVE')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'purchaseApproveManager':
        message = t('MESSAGES.PURCHASE_DOSSIER.APPROVE_MANAGER')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'purchaseApproveSalesManagerVo':
        message = t('MESSAGES.PURCHASE_DOSSIER.APPROVE_SALES_MANAGER_VO')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'commonEntitySaveWarning':
        message = t('MESSAGES.COMMON_HEADER_BUTTONS.SAVE_ENTITY_WARNING')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'deleteFinanceComponentWarning':
        message = t('MESSAGES.COMMON_HEADER_BUTTONS.DELETE_FINANCE_WARNING')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'changeDossierSubType':
        message = t('MESSAGES.COMMON_HEADER_BUTTONS.CHANGE_DOSSIER_SUBTYPE')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'campaignSend':
        message = t('MESSAGES.CAMPAIGN_DOSSIER.SEND_APPROVE')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'campaignApprove':
        message = t('MESSAGES.CAMPAIGN_DOSSIER.APPROVE')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'campaignCancel':
        message = t('MESSAGES.CAMPAIGN_DOSSIER.CANCEL')
        subMessage = t('MESSAGES.COMMON_HEADER_BUTTONS.ADVISE')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'campaignReject':
        message = t('MESSAGES.CAMPAIGN_DOSSIER.REJECT_CAMPAIGN')
        buttons = this.schemaYesNo()
        break
      case 'saleDossierSendApprove':
        message = t('MESSAGES.SALES_DOSSIER.SEND_APPROVE')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'saleDossierTransferRequest':
        message = t('MESSAGES.SALES_DOSSIER.TRANSFER_REQUEST')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'saleDossierTransferRequestApprove':
        message = t('MESSAGES.SALES_DOSSIER.TRANSFER_REQUEST_APPROVE')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'purchaseDossierTransferRequest':
        message = t('MESSAGES.PURCHASE_DOSSIER.TRANSFER_REQUEST')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'purchaseDossierTransferRequestApproved':
        message = t('MESSAGES.PURCHASE_DOSSIER.TRANSFER_REQUEST_APPROVE')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'dossierDoubleTransferConfirmation':
        message = t('MESSAGES.DOUBLE_TRANSFER')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'saleDossierApproveByRole':
        message = t('MESSAGES.SALES_DOSSIER.APPROVE')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'saleDossierSkipDelivery':
        message = t('MESSAGES.SALES_DOSSIER.SKIP_DELIVERY')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'saleDossierSkipPaperwork':
        message = t('MESSAGES.SALES_DOSSIER.SKIP_PAPERWORK')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'saleDossierSkipDocument':
        message = t('MESSAGES.SALES_DOSSIER.SKIP_DOCUMENT')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'saleDossierSkipCharge':
        message = t('MESSAGES.SALES_DOSSIER.SKIP_CHARGE')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'saleDossierReject':
        message = t('MESSAGES.SALES_DOSSIER.REJECT')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'dossierUndoCancel':
        message = t('MESSAGES.SALES_DOSSIER.UNDO_CANCEL')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'dossierConfirmCancel':
        message = t('MESSAGES.SALES_DOSSIER.CONFIRM_CANCEL')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'storeDossier':
        message = t('MESSAGES.COMMON_HEADER_BUTTONS.STORE')
        subMessage = t('MESSAGES.COMMON_HEADER_BUTTONS.ADVISE')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'purchaseDossierApprove':
        message = t('MESSAGES.PURCHASE_DOSSIER.APPROVE')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'saleDossierApprove':
        message = t('MESSAGES.SALES_DOSSIER.APPROVE')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'saleDossierApproveFleet':
        message = t('MESSAGES.SALES_DOSSIER.APPROVE_FLEET')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'saleDossierApprovePaperwork':
        message = t('MESSAGES.SALES_DOSSIER.APPROVE_PAPERWORK')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'editDossierAlert':
        message = t('MESSAGES.EDIT_DOSSIER_ALERT')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'deletePaperworkAlert':
        message = t('MESSAGES.DELETE_PAPERWORK_ALERT')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'cancelDossier':
        message = t('MESSAGES.CANCEL_NEW_DOSSIER')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'deleteUser':
        message = t('MESSAGES.DELETE_USER')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'deleteAgency':
        message = t('MESSAGES.DELETE_PROCESSING_AGENCY')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'recoverAgency':
        message = t('MESSAGES.RECOVER_PROCESSING_AGENCY')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'deleteCampaignDetail':
        message = t('MESSAGES.DELETE_CAMPAING_DETAIL')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'deleteCampaignGroup':
        message = t('MESSAGES.DELETE_CAMPAING_GROUP')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'deleteCampaignType':
        message = t('MESSAGES.DELETE_CAMPAING_TYPE')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'deleteQuota':
        message = t('MESSAGES.DELETE_PROCESSING_AGENCY')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'deletePaymentMethod':
        message = t('MESSAGES.DELETE_PAYMENT_METHOD')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'deleteFinancing':
        message = t('MESSAGES.DELETE_FINANCING')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'changeUserPassword':
        message = t('MESSAGES.CHANGE_USER_PASSWORD')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'newVehicleOnDossier':
        message = t('MESSAGES.NEW_VEHICLE_ON_DOSSIER')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'createVehicleConfirmation':
        message = t('MESSAGES.CREATE_NEW_VEHICLE_CONFIRMATION')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'forceDeleteRole':
        message = t('MESSAGES.ADMIN.ROLE.FORCE_DELETE_ROLE')
        replaceMessageArgs.forEach((val) => {
          message = message.replace(val.id, val.value)
        })
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'manageDocumentaryModalAlert':
        message = t('MESSAGES.MANAGE_DOCUMENTARY_MODAL_ALERT')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'deleteUnit':
        message = t('MESSAGES.DELETE_UNIT')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'unitWithActiveUsers':
        message = t('MESSAGES.ACTIVE_USERS_UNIT')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => okResponse()}>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => { }
        break
      case 'deleteRole':
        message = t('MESSAGES.DELETE_ROLE')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'existentClient':
        message = t('MESSAGES.EXISTENT_CLIENT')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => okResponse()}>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => { }
        break
      case 'dossierWithChanges':
        message = 'Antes de acceder a la gestión documental debe guardar sus cambios'
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => okResponse()}>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => { }
        break
      case 'existentVehicle':
        message = t('MESSAGES.EXISTENT_VEHICLE')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => okResponse()}>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => { }
        break
      case 'vehicleSaleNotFound':
        message = t('MESSAGES.VEHICLE_SALE_NOT_FOUND')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => { }
        break
      case 'notIvaIemAmoutFilled':
        message = t('MESSAGES.NOT_IVA_IEM_AMOUNT_FILLED')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => { }
        break
      case 'invalidAmount':
        message = t('MESSAGES.AMOUNT_CANT_BE_HIGHER')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => { }
        break
      case 'vehicleNotFound':
        message = t('MESSAGES.VEHICLE_NOT_FOUND')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => { }
        break
      case 'vehicleNotFoundCreate':
        message = t('MESSAGES.VEHICLE_NOT_FOUND_CREATE')
        buttons = ([
          <Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => okResponse()}>{t('GLOBAL.BUTTONS.OK')}</Button>,
          <Button id="btn_no_modal" className="btn-standard-grey" key="2" onClick={() => nOkResponse()}>{t('GLOBAL.BUTTONS.CANCEL')}</Button>
        ])
        onHide = () => { }
        break
      case 'VehicleVinChassisDuplicated':
        message = t('MESSAGES.VEHICLE_DUPLICATED_NEW_DOSSIER')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => { }
        break
      case 'entityAlert':
        message = t('MESSAGES.ENTITY_ALERT')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => { }
        break
      case 'entityAlertNoPermission':
        message = t('MESSAGES.ENTITY_ALERT_NO_PERMISSION')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => { }
        break
      case 'MustFillEntity':
        message = t('MESSAGES.MUST_FILL_ENTITY')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => { }
        break
      case 'vehicleAlert':
        message = t('MESSAGES.VEHICLE_ALERT')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => { }
        break
      case 'notData':
        message = t('MESSAGES.NOT_DATA_COMBO')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => { }
        break
      case 'mustFillDataQuota':
        message = t('MESSAGES.MUST_FILLED_DATA_QUOTA')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => { }
        break
      case 'incompatibleSelection':
        message = t('MESSAGES.INCOMPATIBLE_SELECTION')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => { }
        break
      case 'mustFillFilters':
        message = t('MESSAGES.MUST_FILL_FILTERS')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => { }
        break
      case 'mustFillEntityType':
        message = t('MESSAGES.MUST_FILL_FILTERS_ENTITY_TYPE')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => { }
        break
      case 'mustFillFiltersVehicleDate':
        message = t('MESSAGES.MUST_FILL_FILTERS_ENTITY_TYPE_VEHICLE_DATE')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => { }
        break
      case 'arrayFull':
        message = t('MESSAGES.ARRAY_DOSSIER_FULL')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => { }
        break
      case 'mustFillFiltersDossierSimple':
        message = t('MESSAGES.MUST_FILL_FILTERS_DOSSIER_SIMPLE')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => { }
        break
      case 'mustSelectAnyDocument':
        message = t('MESSAGES.MUST_SELECT_ANY_DOCUMENT')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => {}
        break
      case 'mustFillFiltersClient':
        message = t('MESSAGES.MUST_FILL_FILTERS_CLIENT')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => { }
        break
      case 'mustFillFiltersVehicle':
        message = t('MESSAGES.MUST_FILL_FILTERS_VEHICLE')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => { }
        break
      case 'mustFillFiltersDossier':
        message = t('MESSAGES.MUST_FILL_FILTERS_DOSSIER')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => { }
        break
      case 'mustFillUac':
        message = t('MESSAGES.MUST_FILL_UAC')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => { }
        break
      case 'cancelDocument':
        message = t('MESSAGES.CANCEL_DOCUMENT')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'deleteSheet':
        message = t('MESSAGES.DELETE_SHEET')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'sendMailIssue':
        message = t('MESSAGES.SEND_MAIL_ISSUE')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'DossierOutOfDate':
        message = t('MESSAGES.DOSSIER_OUT_OF_DATE')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => okResponse()}>{t('GLOBAL.BUTTONS.RELOAD')}</Button>,
          <Button id="btn_no_modal" className="btn-standard-grey" key="2" onClick={() => nOkResponse()}>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => { }
        break
      case 'tokenExpired':
        message = t('MESSAGES.SESSION_EXPIRED')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={this.toLogin}>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => { }
        break
      case 'deactivatedUser':
        message = t('MESSAGES.DEACTIVATED_USER')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={this.toLogin}>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => { }
        break
      case 'deleteTemplate':
        message = t('MESSAGES.DELETE_TEMPLATE')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'deleteTemplateField':
        message = t('MESSAGES.DELETE_TEMPLATE_FIELD')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'hotFolderLoadFiles':
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => { }
        components = <HotFolderReport documentList={replaceMessageArgs} t={t} />
        break
      case 'hotFolderShowPath':
        message = t('MESSAGES.HOTFOLDER.USER_PATH') + ' ' + replaceMessageArgs
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        break
      case 'deleteConditionDocumental':
        message = t('MESSAGES.DELETE_CONDITION_DOCUMENTAL')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'deleteConditionNoDocumental':
        message = t('MESSAGES.DELETE_CONDITION_NO_DOCUMENTAL')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'selectVOCompanyConfirmation':
        message = t('MESSAGES.COMMON_HEADER_BUTTONS.SELECT_VO_COMPANY_WARNING')
        buttons = this.schemaYesNo()
        break
      case 'confirmExcelDocument':
        message = t('MESSAGES.CONFIRM_EXCEL_DOCUMENT')
        replaceMessageArgs.forEach((val) => {
          message = message.replace(val.id, val.value)
        })
        buttons = (<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => okResponse()}>{t('GLOBAL.BUTTONS.OK')}</Button>)
        break
      case 'commonEntityClearWarning':
        message = t('MESSAGES.COMMON_HEADER_BUTTONS.CLEAR_ENTITY_WARNING')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'confirmClientFleets':
        message = t('MESSAGES.COMMON_HEADER_BUTTONS.CLIENT_FLEETS_WARNING')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'clientFleetModify':
        message = t('MESSAGES.COMMON_HEADER_BUTTONS.CLIENT_FLEETS_MODIFY')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'commonVehicleClearWarning':
        message = t('MESSAGES.COMMON_HEADER_BUTTONS.CLEAR_VEHICLE_WARNING')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'commonVehicleClearWarningWithUnsaveChanges':
        message = t('MESSAGES.COMMON_HEADER_BUTTONS.CLEAR_VEHICLE_DOSSIER_WARNING')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'commonEntityChildClearWarning':
        message = t('MESSAGES.COMMON_HEADER_BUTTONS.CLEAR_ENTITY_CHILD_WARNING')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'saleDossierApproveVoucher':
        message = t('MESSAGES.SALES_DOSSIER.APPROVE_VOUCHER')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'vehicleNecessary':
        message = t('MESSAGES.SALES_DOSSIER.VEHICLE_NECESSARY')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => { }
        break
      case 'ivaTypeNecessary':
        message = t('MESSAGES.SALES_DOSSIER.OPEN_PROCESSING_AGENCY_MODAL')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => { }
        break
      case 'differentMakerNumberSalePurchase':
        message = t('MESSAGES.SALES_DOSSIER.DIFFERENT_MAKERNUMBER_SALE_PURCHASE')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'dossiersWithDocument':
        var commaFollowedByNoSpacesRegex = /,(?=\S)/ig
        message = t('MESSAGES.DOSSIERS_WITH_DOCUMENT')
        replaceMessageArgs.forEach((val) => {
          message = message.replace(val.id, val.value).replaceAll(commaFollowedByNoSpacesRegex, ', ')
        })
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => okResponse()}>{t('GLOBAL.BUTTONS.OK')}</Button>,
          <Button id="btn_no_modal" className="btn-standard-grey" key="2" onClick={() => nOkResponse()}>{t('GLOBAL.BUTTONS.CANCEL')}</Button>])
        onHide = () => {}
        break
      case 'anyDossierMustBeSelected':
        message = t('MESSAGES.ANY_DOSSIER_MUST_BE_SELECTED')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => {}
        break
      case 'modalCampaignOptionals':
        message = t('DOSSIER_COMPONENTS.CAMPAIGNS.CAMPAIGNS_MODAL_CONFIGURE.HAS_OPTIONALS')
        buttons = this.schemaYesNo()
        onHide = () => {}
        break
      case 'modalCampaignNoApplied':
        message = t('DOSSIER_COMPONENTS.CAMPAIGNS.CAMPAIGNS_MODAL_CONFIGURE.IS_NOT_APPLIED')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => okResponse()}>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => {}
        break
      case 'vehicleNotFoundNewDossier':
        message = t('MESSAGES.VEHICLE_NOT_FOUND_NEW_DOSSIER')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => {}
        break
      case 'travelNotFound':
        message = t('MESSAGES.TRAVEL_NOT_FOUND')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => {}
        break
      case 'dateTravelNotFound':
        message = t('MESSAGES.DATE_TRAVEL_NOT_FOUND')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => {}
        break
      case 'cpTravelNotFound':
        message = t('MESSAGES.CP_TRAVEL_NOT_FOUND')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => {}
        break
      case 'truckTravelNotFound':
        message = t('MESSAGES.TRUCK_TRAVEL_NOT_FOUND')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => {}
        break
      case 'multipleTravelsFound':
        message = t('MESSAGES.MULTIPLE_TRAVELS_FOUND')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => {}
        break
      case 'vehicleInTravelNotFound':
        message = t('MESSAGES.VEHICLE_IN_TRAVEL_NOT_FOUND')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => {}
        break
      case 'auditDetails':
        message = t('MESSAGES.AUDIT.AUDIT_DETAILS')
        replaceMessageArgs.forEach((val) => {
          message = message.replace(val.id, val.value)
        })
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => okResponse()}>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => {}
        break
      case 'recurrentEntityFound':
        message = t('MESSAGES.RECURRENT_ENTITY_FOUND')
        buttons = this.schemaYesNo()
        onHide = () => {}
        break
      case 'dossierExists':
        message = t('MESSAGES.DOSSIER_EXISTS')
        buttons = this.schemaYesNo()
        onHide = () => {}
        break
      case 'deleteFuel':
        message = t('MESSAGES.DELETE_FUEL')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'deleteFinancingTypeModal':
        message = t('MESSAGES.DELETE_FINANCING_TYPE')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'deletePurchasePresentTypeModal':
        message = t('MESSAGES.DELETE_PURCHASE_PRESENT_TYPE')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'uoNotExpecified':
        message = t('MESSAGES.UO_NOT_EXPECIFIED')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => okResponse()}>{t('GLOBAL.BUTTONS.OK')}</Button>])
        onHide = () => { }
        break
      case 'openDocumentModal':
        message = t('MESSAGES.OPEN_DOCVUMENTR_MODAL')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => okResponse()}>{t('GLOBAL.BUTTONS.OK')}</Button>])
        onHide = () => {}
        break
      case 'skipItvModal':
        message = t('MESSAGES.SKIPE_ITV_MODAL')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => okResponse()}>{t('GLOBAL.BUTTONS.OK')}</Button>,
          <Button id="btn_no_modal" className="btn-standard-grey" key="2" onClick={() => nOkResponse()}>{t('GLOBAL.BUTTONS.CANCEL')}</Button>])
        onHide = () => {}
        break
      case 'approveMargin':
        message = t('MESSAGES.APPROVE_MARGIN')
        buttons = this.schemaYesNo()
        onHide = () => {}
        break
      case 'signStatusPending':
        message = t('MESSAGES.SIGN_STATUS_PENDING')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.OK')}</Button>])
        onHide = () => {}
        break
      case 'commonDossierAnulate':
        message = t('MESSAGES.COMMON_HEADER_BUTTONS.ANULATE_CAMPAIGN')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'previousUsageUAC':
        message = t('MESSAGES.PAY_PER_DRIVE_MODAL')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => okResponse()}>{t('GLOBAL.BUTTONS.OK')}</Button>,
          <Button id="btn_no_modal" className="btn-standard-grey" key="2" onClick={() => nOkResponse()}>{t('GLOBAL.BUTTONS.CANCEL')}</Button>])
        onHide = () => { }
        break
      case 'entityWillBeReplaced':
        message = t('MESSAGES.ENTITY_WILL_BE_REPLACED')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => okResponse()}>{t('GLOBAL.BUTTONS.OK')}</Button>,
          <Button id="btn_no_modal" className="btn-standard-grey" key="2" onClick={() => nOkResponse()}>{t('GLOBAL.BUTTONS.CANCEL')}</Button>])
        onHide = () => { }
        break
      case 'confirmUpdateOperationType':
        message = t('MESSAGES.CONFIRM_UPDATE_OPERATION_TYPE')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => { okAction(); closeModal() } }>{t('GLOBAL.BUTTONS.YES')}</Button>,
          <Button id="btn_no_modal" className="btn-standard-grey" key="2" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.NO')}</Button>])
        onHide = () => {}
        break
      case 'deleteOperationType':
        message = t('MESSAGES.CONFIRM_DELETE_OPERATION_TYPE')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => { okAction(); closeModal() } }>{t('GLOBAL.BUTTONS.YES')}</Button>,
          <Button id="btn_no_modal" className="btn-standard-grey" key="2" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.NO')}</Button>])
        onHide = () => {}
        break
      case 'recoverOperationType':
        message = t('MESSAGES.CONFIRM_RECOVERY_OPERATION_TYPE')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => { okAction(); closeModal() } }>{t('GLOBAL.BUTTONS.YES')}</Button>,
          <Button id="btn_no_modal" className="btn-standard-grey" key="2" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.NO')}</Button>])
        onHide = () => {}
        break
      case 'operationTypeAssociatedToDossiers':
        message = t('MESSAGES.OPERATION_TYPE_ASSOCIATED')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => { }
        break
      case 'deleteFaq':
        message = t('MESSAGES.DELETE_FAQ_MESSAGE')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'deleteFaqSection':
        message = t('MESSAGES.DELETE_FAQSECTION_MESSAGE')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'purchaseCampaignProcces':
        message = t('MESSAGES.PROCCES_PURCHASE_FILES')
        replaceMessageArgs.forEach((val) => {
          message = message.replace(val.id, val.value)
        })
        buttons = (<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.OK')}</Button>)
        onHide = () => { }
        break
      case 'campaignNotActive':
        message = t('MESSAGES.CAMPAIGN_NOT_ACTIVE')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => okResponse()}>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => { }
        break
      case 'campaignValidate':
        message = t('MESSAGES.CAMPAIGN_DOSSIER.VALIDATE')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'campaignDenied':
        message = t('MESSAGES.CAMPAIGN_DOSSIER.DENIED')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'campaignReturn':
        message = t('MESSAGES.CAMPAIGN_DOSSIER.RETURN')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'canNotConfigureCampaigns':
        message = t('MESSAGES.ERROR_CONFIG_CAM')
        subMessage = this.props.subMessage !== null ? (!this.props.subMessage.entity ? t('MESSAGES.ENTITY_DATA') : '') + (!this.props.subMessage.orderDate ? t('MESSAGES.ORDER_DATE') : '') +
          (!this.props.subMessage.saleType ? t('MESSAGES.SALE_TYPE') : '') + (!this.props.subMessage.vehicle ? t('MESSAGES.VEHICLE_DATA') : '') : ''
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => { }
        break
      case 'deleteCampaignCountryDetail':
        message = t('MESSAGES.DELETE_CAMPAING_COUNTRY_DETAIL')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'notApprovedAmount':
        message = t('MESSAGES.NOT_APPROVED_AMOUNT')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => { }
        break
      case 'ConfirmDeleteDocuments':
        message = t('MESSAGES.CONFIRM_DELETE_DOCUMENTS')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'paperworkSendDataVehicleSima':
        message = t('MESSAGES.PAPERWORK_DOSSIER.SEND_DATA_VEHICLE_SIMA')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'deleteStyleTask':
        message = t('MESSAGES.DELETE_STYLE_TASK')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'serviceProviderApprove':
        message = t('MESSAGES.SERVICE_DOSSIER.APPROVE_CONFIRMATION')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard" key="1" onClick={() => okResponse()}>{t('GLOBAL.BUTTONS.YES')}</Button>,
          <Button id="btn_no_modal" className="btn-standard-grey" key="2" onClick={() => nOkResponse()}>{t('GLOBAL.BUTTONS.NO')}</Button>])
        onHide = () => { }
        break
      case 'serviceResolveIssue':
        message = t('MESSAGES.SERVICE_DOSSIER.RESOLVE_SERVICE_ISSUE')
        buttons = this.schemaYesNo()
        break
      case 'serviceRequestSpareParts':
        message = t('MESSAGES.SERVICE_DOSSIER.REQUEST_SERVICE_SPARE_PARTS')
        buttons = this.schemaYesNo()
        break
      case 'serviceRequestSparePartsORNumberField':
        components = <div className="form-fields-wrapper"><SimpleInputText
          id="orNumber"
          name="orNumber"
          placeholder={t('DOSSIER_COMPONENTS.SERVICE_DOSSIER.SERVICE_COMPONENT.ORNUMBER')}
          controlLabel={t('DOSSIER_COMPONENTS.SERVICE_DOSSIER.SERVICE_COMPONENT.ORNUMBER')}
          component={InputText}
          value={this.state.orNumber}
          onInputChange={orNumber => this.setState({ orNumber: orNumber })}
          colSm={0}
        /></div>
        message = t('MESSAGES.SERVICE_DOSSIER.REQUEST_SERVICE_SPARE_PARTS')
        buttons = this.schemaAcceptCancelORNumber()
        break
      case 'serviceCompleteSpareParts':
        message = t('MESSAGES.SERVICE_DOSSIER.COMPLETE_SERVICE_SPARE_PARTS')
        buttons = this.schemaYesNo()
        break
      case 'serviceCompleteDeliveryPreparation':
        message = t('MESSAGES.SERVICE_DOSSIER.COMPLETE_SERVICE_DELIVERY_PREPARATION')
        buttons = this.schemaYesNo()
        break
      case 'ppkOrderAlreadyExists':
        message = t('MESSAGES.PPK_ORDER_ALREADY_EXISTS')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'actionFleetDataChange':

        if (replaceMessageArgs[0].value === 1) {
          message = t('MESSAGES.ACTION_FLEET_DATA_CHANGE_ONE')
        } else {
          message = t('MESSAGES.ACTION_FLEET_DATA_CHANGE')
        }
        replaceMessageArgs.forEach((val) => {
          message = message.replace(val.id, val.value)
        })

        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => { okAction(); closeModal() } }>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => { }
        break
      case 'actionFleetDataChangePosStage':
        message = t('MESSAGES.ACTION_FLEET_DATA_CHANGE_POS_STAGE')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => { okAction(); closeModal() } }>{t('GLOBAL.BUTTONS.YES')}</Button>,
          <Button id="btn_no_modal" className="btn-standard-grey" key="2" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.NO')}</Button>])
        onHide = () => {}
        break
      case 'actionFleetDataChangeNoSubType':
        message = t('MESSAGES.ACTION_FLEET_DATA_CHANGE_NO_SUBTYPE')
        buttons = (<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.OK')}</Button>)
        break
      case 'actionFleetDataChangeNoStage':
        message = t('MESSAGES.ACTION_FLEET_DATA_CHANGE_NO_STAGE')
        buttons = (<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.OK')}</Button>)
        break
      case 'actionAdminFleetExpOperationTypes':
        message = t('MESSAGES.ACTION_FLEET_ADMIN_EXP_OPERATION_TYPES')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => { okAction(); closeModal() } }>{t('GLOBAL.BUTTONS.YES')}</Button>,
          <Button id="btn_no_modal" className="btn-standard-grey" key="2" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.NO')}</Button>])
        onHide = () => {}
        break
      case 'actionAdminFleetExpNoFleet':
        message = t('MESSAGES.ACTION_ADMIN_FLEET_NO_FLEET')
        buttons = (<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.OK')}</Button>)
        break
      case 'actionAdminFleetExpYetFleet':
        message = t('MESSAGES.ACTION_FLEET_ADMIN_EXP_YET_FLEET')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => { okAction(); closeModal() } }>{t('GLOBAL.BUTTONS.YES')}</Button>,
          <Button id="btn_no_modal" className="btn-standard-grey" key="2" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.NO')}</Button>])
        onHide = () => {}
        break
      case 'actionAdminFleetExp':
        if (replaceMessageArgs[0].value === 1) {
          message = t('MESSAGES.ACTION_ADMIN_FLEET_EXP_ONE')
        } else {
          message = t('MESSAGES.ACTION_ADMIN_FLEET_EXP')
        }

        replaceMessageArgs.forEach((val) => {
          message = message.replace(val.id, val.value)
        })
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => { okAction(); closeModal() } }>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => { }
        break
      case 'actionAdminFleetExpFleetRepeated':
        message = t('MESSAGES.ACTION_ADMIN_FLEET_FLEET_REPEATED')
        buttons = (<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.OK')}</Button>)
        break
      case 'actionAdminFleetExpFleetError':
        if (replaceMessageArgs) {
          switch (replaceMessageArgs) {
            case 'codeFleetRepeated':
              message = t('MESSAGES.ACTION_ADMIN_FLEET_FLEET_REPEATED')
              break
            default:
              message = t('MESSAGES.ACTION_ADMIN_FLEET_FLEET_ERROR')
          }
        } else {
          message = t('MESSAGES.ACTION_ADMIN_FLEET_FLEET_ERROR')
        }
        buttons = (<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.OK')}</Button>)
        break
      case 'actionDeleteFleetInfo':
        message = t('MESSAGES.ACTION_DELETE_FLEET_DOSSIERS')
        buttons = (<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.OK')}</Button>)
        break
      case 'fleetDeleteSaveWarning':
        message = t('MESSAGES.ACTION_DELETE_FLEET_WARNING')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'actionChangeDataFleet':
        message = t('MESSAGES.ACTION_CHANGE_FLEET')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => { okAction(); closeModal() } }>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => { }
        break
      case 'documentDataLostSendSign':
        message = t('MESSAGES.DOC_DATA_LOST_SEND_SIGN')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'insertUoCompany':
        if (replaceMessageArgs[0].value === 0) {
          message = t('MESSAGES.INSERT_UO_COMPANY_NO_NEW')
        } else {
          message = t('MESSAGES.INSERT_UO_COMPANY_NEW')
        }

        replaceMessageArgs.forEach((val) => {
          message = message.replace(val.id, val.value)
        })
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => { closeModal() } }>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => { }
        break
      case 'insertUoCompanyNot':
        message = t('MESSAGES.INSERT_UO_COMPANY_NO_NEW')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => { closeModal() } }>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => { }
        break
      case 'deleteUoCompany':
        message = t('MESSAGES.DELETE_UO_COMPANY_NO_NEW')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'deleteFinancingTypeProduct':
        message = t('MESSAGES.DELETE_FINANCING_TYPE_PRODUCT')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'deleteFinancingTypeProductInUse':
        message = t('MESSAGES.DELETE_FINANCING_TYPE_PRODUCT_IN_USE')
        replaceMessageArgs.forEach((val) => {
          message = message.replace(val.id, val.value)
        })
        buttons = (<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.OK')}</Button>)
        onHide = () => { }
        break
      case 'updateFinancingType':
        message = t('MESSAGES.UPDATE_FINANCING_TYPE')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'updateDossierStage':
        message = t('MESSAGES.UPDATE_DOSSIER_STAGE')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'actionFinanceChange':

        message = t('MESSAGES.CHANGE_FINANCING_VALUE')

        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => { okAction(); closeModal() } }>{t('GLOBAL.BUTTONS.YES')}</Button>,
          <Button id="btn_no_modal" className="btn-standard-grey" key="2" onClick={() => { cancelAction(); closeModal() } }>{t('GLOBAL.BUTTONS.NO')}</Button>])
        onHide = () => { }
        break
      case 'mustFillFiltersReportCommercial':
        message = t('MESSAGES.MUST_FILL_FILTERS_REPORT_COMMERCIAL')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => { }
        break
      case 'entityEmailRequired':
        message = t('MESSAGES.ENTITY_EMAIL_REQUIRED')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => { }
        break
      case 'providerEmailRequired':
        message = t('MESSAGES.PROVIDER_EMAIL_REQUIRED')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => { }
        break
      case 'sendLandingRequest':
        message = t('MESSAGES.LANDING_REQUEST_SUCCESS')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => { }
        break
      case 'cancelLandingRequest':
        message = t('MESSAGES.LANDING_REQUEST_CANCEL_SUCCESS')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => { }
        break
      case 'landingCancelAllRequest':
        message = t('MESSAGES.CONFIRM_CANCEL_LANDING_ACCESS')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'dossierListExportReducedSuccess':
        message = t('MESSAGES.DOSSIER_LIST_EXPORT_REDUCED_SUCCESS')
        replaceMessageArgs.forEach((val) => {
          message = message.replace(val.id, val.value)
        })
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => { closeModal() } }>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => { }
        break
      case 'vehicleListExportReducedSuccess':
        message = t('MESSAGES.VEHICLE_LIST_EXPORT_REDUCED_SUCCESS')
        replaceMessageArgs.forEach((val) => {
          message = message.replace(val.id, val.value)
        })
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => { closeModal() } }>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => { }
        break
      case 'entityListExportReducedSuccess':
        message = t('MESSAGES.ENTITY_LIST_EXPORT_REDUCED_SUCCESS')
        replaceMessageArgs.forEach((val) => {
          message = message.replace(val.id, val.value)
        })
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => { closeModal() } }>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => { }
        break
      case 'openRefinanceModal':
        message = t('MESSAGES.OPEN_REFINANCE_MODAL')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => { okAction(); closeModal() } }>{t('GLOBAL.BUTTONS.YES')}</Button>,
          <Button id="btn_no_modal" className="btn-standard-grey" key="2" onClick={() => { closeModal() } }>{t('GLOBAL.BUTTONS.NO')}</Button>])
        onHide = () => { }
        break
      case 'deleteReFinancing':
        message = t('MESSAGES.DELETE_RE_FINANCING')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'multipleSignCancel':
        message = t('MESSAGES.MULTIPLE_SIGN_CANCEL_WARNING')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => { okResponse(); closeModal() }}>{t('GLOBAL.BUTTONS.YES')}</Button>,
          <Button id="btn_no_modal" className="btn-standard-grey" key="2" onClick={() => { nOkResponse(); closeModal() }}>{t('GLOBAL.BUTTONS.NO')}</Button>])
        onHide = () => {}
        break
      case 'maximumDownloadDocumentsExceeded':
        message = t('MESSAGES.MAXIMUM_DOWNLOAD_DOCUMENTS_EXCEEDED')
        replaceMessageArgs.forEach((val) => {
          message = message.replace(val.id, val.value)
        })
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => {}
        break
      case 'maximumDownloadsCreatedExceeded':
        message = t('MESSAGES.MAXIMUM_DOWNLOADS_CREATE_EXCEEDED')
        replaceMessageArgs.forEach((val) => {
          message = message.replace(val.id, val.value)
        })
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => {}
        break
      case 'massiveDownloadConfirmation':
        message = t('MESSAGES.MASSIVE_DOWNLOAD_CONFIRMATION')
        replaceMessageArgs.forEach((val) => {
          message = message.replace(val.id, val.value)
        })
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => { okResponse(); closeModal() }}>{t('GLOBAL.BUTTONS.YES')}</Button>,
          <Button id="btn_no_modal" className="btn-standard-grey" key="2" onClick={() => { nOkResponse(); closeModal() }}>{t('GLOBAL.BUTTONS.NO')}</Button>])
        onHide = () => {}
        break
      case 'codeFleetCreateParams':
        message = t('MESSAGES.CODE_FLEET_CREATE_PARAMS')
        buttons = (<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.OK')}</Button>)
        break
      case 'approvalFleetNoStage':
        message = t('MESSAGES.APPROVAL_FLEET_NO_STAGE')
        buttons = (<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.OK')}</Button>)
        break
      case 'approvalFleetPosStage':
        message = t('MESSAGES.APPROVAL_FLEET_POS_STAGE')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => { okAction(); closeModal() } }>{t('GLOBAL.BUTTONS.YES')}</Button>,
          <Button id="btn_no_modal" className="btn-standard-grey" key="2" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.NO')}</Button>])
        onHide = () => {}
        break
      case 'deleteDocumentsAudit':
        message = t('MESSAGES.DELETE_DOCUMENTS_AUDITS')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => { okAction(); closeModal() } }>{t('GLOBAL.BUTTONS.YES')}</Button>,
          <Button id="btn_no_modal" className="btn-standard-grey" key="2" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.NO')}</Button>])
        onHide = () => {}
        break
      case 'confirmAuditFinish':
        message = t('MESSAGES.CORRECT_AUDIT_FINISH')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal() }>{t('GLOBAL.BUTTONS.OK')}</Button>])
        onHide = () => {}
        break
      case 'confirmAuditFile':
        message = t('MESSAGES.CONFIRM_AUDIT_FILE')
        buttons = ([<Button id="btn_yes_modal" className="_Btn accept" key="1" onClick={() => { okAction(); closeModal() } }>{t('GLOBAL.BUTTONS.OK')}</Button>,
          <Button id="btn_no_modal" className="_Btn cancel" key="2" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.CANCEL')}</Button>])
        onHide = () => {}
        break
      case 'confirmDeleteAudit':
        message = t('MESSAGES.CONFIRM_DELETE_AUDIT')
        replaceMessageArgs.forEach((val) => {
          message = message.replace(val.id, val.value)
        })
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal() }>{t('GLOBAL.BUTTONS.OK')}</Button>])
        onHide = () => { }
        break
      case 'correctAudit':
        message = t('MESSAGES.CORRECT_AUDIT')
        replaceMessageArgs.forEach((val) => {
          message = message.replace(val.id, val.value)
        })
        buttons = ([<Button id="btn_yes_modal" className="_Btn accept" key="1" onClick={() => { okAction(); closeModal() } }>{t('GLOBAL.BUTTONS.OK')}</Button>,
          <Button id="btn_no_modal" className="_Btn cancel" key="2" onClick={() => { closeModal() } }>{t('GLOBAL.BUTTONS.CANCEL')}</Button>])
        onHide = () => { }
        break
      case 'fileNotValid':
        message = t('MESSAGES.FILE_NOT_VALID')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal() }>{t('GLOBAL.BUTTONS.OK')}</Button>])
        onHide = () => { }
        break
      case 'fileIsEmpty':
        message = t('MESSAGES.FILE_IS_EMPTY')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal() }>{t('GLOBAL.BUTTONS.OK')}</Button>])
        onHide = () => { }
        break
      case 'fileFormatError':
        message = t('MESSAGES.FILE_FORMAT_ERROR')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal() }>{t('GLOBAL.BUTTONS.OK')}</Button>])
        onHide = () => { }
        break
      case 'fileNotFormatValid':
        message = t('MESSAGES.FILE_NOT_FORMAT_VALID')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal() }>{t('GLOBAL.BUTTONS.OK')}</Button>])
        onHide = () => { }
        break
      case 'deleteAudit':
        message = t('MESSAGES.DELETE_CONFIRM')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => { okAction(); closeModal() } }>{t('GLOBAL.BUTTONS.YES')}</Button>,
          <Button id="btn_no_modal" className="btn-standard-grey" key="2" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.NO')}</Button>])
        onHide = () => {}
        break
      case 'actionDocumentsUploadNoVinChassis':
        message = t('MESSAGES.ACTION_DOCUMENTS_UPLOAD_NO_VINCHASSIS')
        buttons = (<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.OK')}</Button>)
        break
      case 'actionDifferentUploadDocumentFleet':
        message = t('MESSAGES.ACTION_ADMIN_FLEET_EXP')

        replaceMessageArgs.forEach((val) => {
          message = message.replace(val.id, val.value)
        })
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => { okAction(); closeModal() } }>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => { }
        break
      case 'actionEconomicPlanPosStage':
        message = t('MESSAGES.ACTION_FLEET_ECONOMIC_PLAN_POS_STAGE')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => { okAction(); closeModal() } }>{t('GLOBAL.BUTTONS.YES')}</Button>,
          <Button id="btn_no_modal" className="btn-standard-grey" key="2" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.NO')}</Button>])
        onHide = () => {}
        break
      case 'actionEconomicPlanNoStage':
        message = t('MESSAGES.ACTION_ECONOMIC_PLAN_NO_STAGE')
        buttons = (<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.OK')}</Button>)
        break
      case 'actionEconomicPlanNotShow':
        message = t('MESSAGES.ACTION_ECONOMIC_NOT_SHOW')
        buttons = (<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.OK')}</Button>)
        break
      case 'errorStoreDossier':
        message = t('MESSAGES.ERROR_STORE_DOSSIER') + this.props.message
        buttons = (<Button id="btn_no_modal" className="btn-standard-grey" key="2" onClick={() => nOkResponse()}>{t('GLOBAL.BUTTONS.CLOSE')}</Button>)
        onHide = () => { }
        break
      case 'confirmRelatedStoreDossier':
        message = t('MESSAGES.CONFIRM_RELATED_STORE_DOSSIER') + this.props.message
        buttons = (<Button id="btn_no_modal" className="btn-standard-grey" key="2" onClick={() => nOkResponse()}>{t('GLOBAL.BUTTONS.CLOSE')}</Button>)
        onHide = () => { }
        break
      case 'saveEconomicFleetAlert':
        message = t('MESSAGES.SAVE_ECONOMIC_FLEET_ALERT')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'deleteDocumentType':
        message = t('MESSAGES.DELETE_DOCUMENT_TYPE')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'deleteRating':
        message = t('MESSAGES.DELETE_RATING')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'updateOrganizedUnitConfiguration':
        message = t('MESSAGES.UPDATE_ORGANIZED_UNIT_CONFIGURATION')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'deleteRepairshop':
        message = t('MESSAGES.DELETE_REPAIRSHOP')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'deleteMaritalStatus':
        message = t('MESSAGES.DELETE_MARITAL_STATUS')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'updateMasterData':
        message = t('MESSAGES.UPDATE_MASTER_DATA_WARNING')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'deleteDocumentVisualization':
        message = t('MESSAGES.DELETE_DOCUMENT_VISUALIZATION')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'deleteDocumentTypeUse':
        message = t('MESSAGES.DELETE_DOCUMENT_TYPE_USE')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'editDocumentVisualization':
      case 'editProcessingAgencyOrganizedUnitConfig':
      case 'deleteProcessingAgencyOrganizedUnitConfig':
      case 'deleteProcessingAgencyRegistrationTypes':
      case 'editProcessingAgencyRegistrationTypes':
      case 'deleteProcessingAgencyServiceDestinations':
      case 'editProcessingAgencyServiceDestinations':
      case 'deleteProcessingAgencyDocTypeUses':
      case 'editProcessingAgencyDocTypeUses':
        message = t('MESSAGES.POSSIBLE_APP_ERROR')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'updateAgencyCommunicationServiceId':
        message = t('MESSAGES.ACTIVE_INTEGRATION_INMEDIATELY')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'updateDocumentTypeUse':
        message = t('MESSAGES.UPDATE_DOCUMENT_TYPE_USE')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'deleteOrganizedUnitSaleOperation':
        message = t('MESSAGES.DELETE_ORGANIZED_UNIT_SALE_OPERATION')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'addOrganizedUnitSaleOperation':
        message = t('MESSAGES.ADD_ORGANIZED_UNIT_SALE_OPERATION')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'updateOrganizedUnitSaleOperation':
        message = t('MESSAGES.UPDATE_ORGANIZED_UNIT_SALE_OPERATION')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'changeSignatureDocumentConfiguration':
        message = t('MESSAGES.CHANGE_SIGNATURE_CONFIGURATION')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'deleteDocumentTypeMetadata':
        message = t('MESSAGES.DELETE_DOCUMENT_TYPE_METADATA')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'editDocumentTypeMetadata':
        message = t('MESSAGES.POSSIBLE_APP_ERROR')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'warningPrivateRfcFormat':
        message = t('MESSAGES.WARNING_PRIVATE_RFC_FORMAT')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => { okResponse(); closeModal() } }>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => { }
        break
      case 'errorForeignRfcFormat':
        message = t('MESSAGES.ERROR_FOREIGN_RFC_FORMAT')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => { okResponse(); closeModal() } }>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => { }
        break
      case 'passportWarning':
        message = t('MESSAGES.PASSPORT_WARNING')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => { okResponse(); closeModal() } }>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => { }
        break
      case 'unattendedProcessCreated':
        message = t('MESSAGES.UNATTENDED_PROCESS_CREATED')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => {
          if (typeof okAction === 'function') {
            okAction()
          }
          closeModal()
        }}>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => {}
        break
      case 'unattendedProcessDateWhenFailed':
        message = t('MESSAGES.UNATTENDED_PROCESS_DATE_WHEN_FAILED')
        buttons = (<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.OK')}</Button>)
        break
      case 'addReceiptWarning':
        message = t('MESSAGES.ADD_RECEIPT_WARNING')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => okResponse()}>{t('GLOBAL.BUTTONS.OK')}</Button>,
          <Button id="btn_no_modal" className="btn-standard-grey" key="2" onClick={() => nOkResponse()}>{t('GLOBAL.BUTTONS.CANCEL')}</Button>])
        break
      case 'addReceiptConfirmed':
        message = t('MESSAGES.ADD_RECEIPT_CONFIRMED')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.OK')}</Button>])
        break
      case 'directionUOAlert':
        message = t('MESSAGES.SAVE_DIRECTION_UO_ALERT')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'directionUODeleteAlert':
        message = t('MESSAGES.DELETE_DIRECTION_UO_ALERT')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'legalCompanyAlert':
        message = t('MESSAGES.SAVE_LEGAL_COMPANY_ALERT')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'legalCompanyDeleteAlert':
        message = t('MESSAGES.DELETE_LEGAL_COMPANY_ALERT')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'observations':
        subMessage = replaceMessageArgs
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => { closeModal() } }>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => { }
        break
      case 'organizedUnitAlert':
        message = t('MESSAGES.SAVE_ORGANIZED_UNIT_ALERT')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'fleetModified':
        message = t('MESSAGES.FLEET_MODIFIED_OK')
        replaceMessageArgs.forEach((val) => {
          message = message.replace(val.id, val.value)
        })
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => { okAction(); closeModal() } }>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => { }
        break
      case 'notChangesToSave':
        message = t('MESSAGES.NOT_CHANGES_TO_SAVE')
        buttons = (<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.OK')}</Button>)
        break
      case 'deleteDossierFromFleet':
        message = t('MESSAGES.DELETE_DOSSIER_FROM_FLEET')
        replaceMessageArgs.forEach((val) => {
          message = message.replace(val.id, val.value)
        })
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => { okAction(); closeModal() } }>{t('GLOBAL.BUTTONS.YES')}</Button>,
          <Button id="btn_no_modal" className="btn-standard-grey" key="2" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.NO')}</Button>])
        onHide = () => { }
        break
      case 'saveSetOperateDataSaleFleet':
        message = t('MESSAGES.SAVE_SET_OPERATE_DATA_SALE_FLEET')
        replaceMessageArgs.forEach((val) => {
          message = message.replace(val.id, val.value)
        })
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'notDistinctUoAndStage':
        message = t('MESSAGES.NOT_DISTINCT_UO_AND_STAGE')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => { closeModal() } }>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => {}
        break
      case 'sendDMS':
        message = t('MESSAGES.SEND_DMS_CONTINUE')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'saveSetOperateDataContactFleet':
        message = t('MESSAGES.SAVE_SET_OPERATE_CONTACT_SALE_FLEET')
        replaceMessageArgs.forEach((val) => {
          message = message.replace(val.id, val.value)
        })
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'notDistinctStage':
        message = t('MESSAGES.NOT_DISTINCT_STAGE')
        buttons = ([<Button id="btn_yes_modal" className="btn-Accept btn btn-default " key="1" onClick={() => { closeModal() } }>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => {}
        break
      case 'saveSetOperateDataPaperWorkFleet':
        message = t('MESSAGES.SAVE_SET_OPERATE_PAPERWORK_SALE_FLEET')
        replaceMessageArgs.forEach((val) => {
          message = message.replace(val.id, val.value)
        })
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'saveSetOperateDataPaperWorkDeleteFleet':
        message = t('MESSAGES.SAVE_SET_OPERATE_PAPERWORK_DELETE_SALE_FLEET')
        replaceMessageArgs.forEach((val) => {
          message = message.replace(val.id, val.value)
        })
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'saveSetOperateDataFinancingFleet':
        message = t('MESSAGES.SAVE_SET_OPERATE_DATA_FINANCING_FLEET')
        replaceMessageArgs.forEach((val) => {
          message = message.replace(val.id, val.value)
        })
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'saveSetOperateDataChargeFleet':
        message = t('MESSAGES.SAVE_SET_OPERATE_DATA_CHARGE_FLEET')
        replaceMessageArgs.forEach((val) => {
          message = message.replace(val.id, val.value)
        })
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'setOperateActionSendToApprovalProcessConfirm':
        message = t('MESSAGES.SET_OPERATE_ACTION_SEND_TO_APPROVAL_PROCESS_CONFIRMATION')
        replaceMessageArgs.forEach((val) => {
          message = message.replace(val.id, val.value)
        })
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => { okAction(); closeModal() } }>{t('GLOBAL.BUTTONS.YES')}</Button>,
          <Button id="btn_no_modal" className="btn-standard-grey" key="2" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.NO')}</Button>])
        onHide = () => { }
        break
      case 'setOperateActionApproveProcessConfirm':
        message = t('MESSAGES.SET_OPERATE_ACTION_APPROVE_PROCESS_CONFIRMATION')
        replaceMessageArgs.forEach((val) => {
          message = message.replace(val.id, val.value)
        })
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => { okAction(); closeModal() } }>{t('GLOBAL.BUTTONS.YES')}</Button>,
          <Button id="btn_no_modal" className="btn-standard-grey" key="2" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.NO')}</Button>])
        onHide = () => { }
        break
      case 'setOperateActionRejectProcessConfirm':
        message = t('MESSAGES.SET_OPERATE_ACTION_REJECT_PROCESS_CONFIRMATION')
        replaceMessageArgs.forEach((val) => {
          message = message.replace(val.id, val.value)
        })
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => { okAction(); closeModal() } }>{t('GLOBAL.BUTTONS.YES')}</Button>,
          <Button id="btn_no_modal" className="btn-standard-grey" key="2" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.NO')}</Button>])
        onHide = () => { }
        break
      case 'setOperateActionApprovePaperworkProcessConfirm':
        message = t('MESSAGES.SET_OPERATE_ACTION_APPROVE_PAPERWORK_PROCESS_CONFIRMATION')
        replaceMessageArgs.forEach((val) => {
          message = message.replace(val.id, val.value)
        })
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => { okAction(); closeModal() } }>{t('GLOBAL.BUTTONS.YES')}</Button>,
          <Button id="btn_no_modal" className="btn-standard-grey" key="2" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.NO')}</Button>])
        onHide = () => { }
        break
      case 'setOperateActionDeleteProcessConfirm':
        message = t('MESSAGES.SET_OPERATE_ACTION_DELETE_PROCESS_CONFIRMATION')
        replaceMessageArgs.forEach((val) => {
          message = message.replace(val.id, val.value)
        })
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => { okAction(); closeModal() } }>{t('GLOBAL.BUTTONS.YES')}</Button>,
          <Button id="btn_no_modal" className="btn-standard-grey" key="2" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.NO')}</Button>])
        onHide = () => { }
        break
      case 'saveSetDocumentFleetProcess':
        message = t('MESSAGES.SAVE_SET_FLEET_PROCESS_NUM_DOSSIERS')
        replaceMessageArgs.forEach((val) => {
          message = message.replace(val.id, val.value)
        })
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'documentFleetMoreThanOneEntity':
        message = t('MESSAGES.DOCUMENT_FLEET_MORE_THAN_ONE_ENTITY')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => { closeModal() } }>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => {}
        break
      case 'documentFleetMoreThanOneVehicle':
        message = t('MESSAGES.DOCUMENT_FLEET_MORE_THAN_ONE_VEHICLE')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => { closeModal() } }>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => {}
        break
      case 'noDocumentFleetAction':
        message = t('MESSAGES.NO_DOCUMENT_FLEET_ACTION')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => { closeModal() } }>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => {}
        break
      case 'documentFleetNotSameStatus':
        message = t('MESSAGES.DOCUMENT_FLEET_NOT_SAME_STATUS')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => { closeModal() } }>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => {}
        break
      case 'documentFleetActionPartial':
        message = t('MESSAGES.DOCUMENT_FLEET_ACTION_PARTIAL')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => { okAction(); closeModal() } }>{t('GLOBAL.BUTTONS.YES')}</Button>,
          <Button id="btn_no_modal" className="btn-standard-grey" key="2" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.NO')}</Button>])
        onHide = () => {}
        break
      case 'commonCurrencyChangeWarning':
        message = t('MESSAGES.CHARGE_VALIDATED')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'saleDossiersNotInLastStage':
        message = t('MESSAGES.DOSSIERS_NOT_IN_LAST_STAGE')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => { }
        break
      case 'setOperateActionStoreProcessConfirm':
        message = t('MESSAGES.SET_OPERATE_ACTION_STORE_PROCESS_CONFIRMATION')
        replaceMessageArgs.forEach((val) => {
          message = message.replace(val.id, val.value)
        })
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => { okAction(); closeModal() } }>{t('GLOBAL.BUTTONS.YES')}</Button>,
          <Button id="btn_no_modal" className="btn-standard-grey" key="2" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.NO')}</Button>])
        onHide = () => { }
        break
      case 'saveFleetsDossiersAlert':
        message = t('MESSAGES.SAVE_FLEETS_DOSSIERS_ALERT')
        replaceMessageArgs.forEach((val) => {
          message = message.replace(val.id, val.value)
        })
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'documentFleetMoreThanOneUo':
        message = t('MESSAGES.DOCUMENT_FLEET_MORE_THAN_ONE_UO')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => { closeModal() } }>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => {}
        break
      case 'documentFleetNotSigned':
        message = t('MESSAGES.DOCUMENT_FLEET_NOT_SIGN')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => { closeModal() } }>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => {}
        break
      case 'noDocumentFleetSignAction':
        message = t('MESSAGES.NO_DOCUMENT_FLEET_SIGN_ACTION')
        replaceMessageArgs.forEach((val) => {
          message = message.replace(val.id, val.value)
        })
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => { closeModal() } }>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => {}
        break
      case 'saveSetOperateDataDeliveryFleet':
        message = t('MESSAGES.SAVE_SET_OPERATE_DELIVERY_SALE_FLEET')
        replaceMessageArgs.forEach((val) => {
          message = message.replace(val.id, val.value)
        })
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'saveSetOperateDataServiceFleet':
        message = t('MESSAGES.SAVE_SET_OPERATE_SERVICE_SALE_FLEET')
        replaceMessageArgs.forEach((val) => {
          message = message.replace(val.id, val.value)
        })
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'notServiceExpedientesAvailable':
        message = t('MESSAGES.NOT_SERVICE_EXPEDIENTS_AVAILABLE')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => { closeModal() } }>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => {}
        break
      case 'setDeleteFleetProcess':
        message = t('MESSAGES.SET_MAINTENANCE_FLEET_DELETE_PROCESS_CONFIRMATION')
        replaceMessageArgs.forEach((val) => {
          message = message.replace(val.id, val.value)
        })
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => { okAction(!!this.state.componentsValues.deleteDossiers); closeModal() } }>{t('GLOBAL.BUTTONS.YES')}</Button>,
          <Button id="btn_no_modal" className="btn-standard-grey" key="2" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.NO')}</Button>])
        onHide = () => { }
        components = (
          <div>
            <Checkbox
              id={'deleteDossiersCheckBoxk'}
              inline={true}
              onChange={(event) => {
                this.setState({ componentsValues: { deleteDossiers: event.target.checked } })
              }}
            >
              {t('MESSAGES.SET_MAINTENANCE_FLEET_DELETE_PROCESS_CONFIRMATION_DELETE_DOSSIERS_CHECK')}
            </Checkbox>
          </div>
        )
        break
      case 'setOperateActionPrintDeliveryNoteProcessConfirm':
        message = t('MESSAGES.SET_OPERATE_ACTION_PRINT_DELIVERY_NOTE_PROCESS_CONFIRMATION')
        replaceMessageArgs.forEach((val) => {
          message = message.replace(val.id, val.value)
        })
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => { okAction(); closeModal() } }>{t('GLOBAL.BUTTONS.YES')}</Button>,
          <Button id="btn_no_modal" className="btn-standard-grey" key="2" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.NO')}</Button>])
        onHide = () => { }
        break
      case 'notAllDossiersInSameStage':
        message = t('MESSAGES.DOSSIERS_HAVE_DIFFERENT_STAGES')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => { }
        break
      case 'setOperateActionSendToAgencyProcessConfirm':
        message = t('MESSAGES.SET_OPERATE_ACTION_SEND_TO_AGENCY_PROCESS_CONFIRMATION')
        replaceMessageArgs.forEach((val) => {
          message = message.replace(val.id, val.value)
        })
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => { okAction(); closeModal() } }>{t('GLOBAL.BUTTONS.YES')}</Button>,
          <Button id="btn_no_modal" className="btn-standard-grey" key="2" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.NO')}</Button>])
        onHide = () => { }
        break
      case 'confirmUpdateSaleType':
        message = t('MESSAGES.UPDATE_SALE_TYPE')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'deleteSaleTypeModal':
        message = t('MESSAGES.DELETE_SALE_TYPE')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'recoverSaleTypeModal':
        message = t('MESSAGES.RECOVER_SALE_TYPE')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'adviceClosePaperwokTypeMasterModal':
        message = t('MESSAGES.ADVICE_CLOSE_PWK_EDIT_MODAL')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'noAllEconomicDosiersOnFleet':
        message = t('MESSAGES.NO_ALL_ECONOMIC_DOSIERS_ON_FLEET')
        replaceMessageArgs.forEach((val) => {
          message = message.replace(val.id, val.value)
        })
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => { okAction(); closeModal() } }>{t('GLOBAL.BUTTONS.YES')}</Button>,
          <Button id="btn_no_modal" className="btn-standard-grey" key="2" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.NO')}</Button>])
        onHide = () => {}
        break
      case 'noDossiersOnFleet':
        message = t('MESSAGES.NO_DOSSIERS_ON_FLEET')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => { closeModal() } }>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => {}
        break
      case 'noEconomicDossiersOnFleet':
        message = t('MESSAGES.NO_ECONOMIC_DOSSIERS_ON_FLEET')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => { closeModal() } }>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => {}
        break
      case 'noEconomicStageDossiersOnFleet':
        message = t('MESSAGES.NO_ECONOMIC_STAGE_DOSSIERS_ON_FLEET')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => { closeModal() } }>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => {}
        break
      case 'documentFleetActionPartialReason':
        message = t('MESSAGES.DOCUMENT_FLEET_ACTION_PARTIAL_REASON')
        replaceMessageArgs.forEach((val) => {
          message = message.replace(val.id, val.value)
        })
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => { okAction(); closeModal() } }>{t('GLOBAL.BUTTONS.YES')}</Button>,
          <Button id="btn_no_modal" className="btn-standard-grey" key="2" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.NO')}</Button>])
        onHide = () => {}
        break
      case 'noDocumentFleetActionReason':
        message = t('MESSAGES.NO_DOCUMENT_FLEET_ACTION_REASON')
        replaceMessageArgs.forEach((val) => {
          message = message.replace(val.id, val.value)
        })
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => { closeModal() } }>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => {}
        break
      case 'putProviderObservations':
        message = t('MESSAGES.SERVICE_DOSSIER.PUT_PROVIDER_OBSERVATIONS')
        components = (<Field
          id="providerObservations"
          name="providerObservations"
          placeholder={t('DOSSIER_COMPONENTS.SERVICE_DOSSIER.SERVICE_COMPONENT.ISSUE_MODAL.PROVIDER_OBSERVATIONS')}
          component={InputText}
          customClass="textarea-noresize"
          componentClass="textarea"
          value=""
          onChange={(el, value) => this.setState({ providerObservations: value })}
          colSm={0}
        />)
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" disabled={!this.state.providerObservations} onClick={() => this.handleSave()}>{t('GLOBAL.BUTTONS.SAVE')}</Button>,
          <Button id="btn_no_modal" className="btn-standard-grey" key="2" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.CANCEL')}</Button>])
        onHide = () => { }
        break
      case 'createOrganizedUnitAlert':
        message = t('MESSAGES.CREATE_ORGANIZED_UNIT_ALERT')
        replaceMessageArgs.forEach((val) => {
          message = message.replace(val.id, val.value)
        })
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'loadSepomexFileProperly':
        message = t('MESSAGES.LOAD_SEPOMEX_FILE_PROPERLY')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.OK')}</Button>])
        break
      case 'deleteUserProcessingAgency':
        message = t('MESSAGES.DELETE_USER_PROCESSING_AGENCY')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'saleSendToBproDms':
        message = t('MESSAGES.SALES_DOSSIER.SALE_SEND_TO_BPRODMS')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'saleRequestInvoiceToDMS':
        message = t('MESSAGES.SALES_DOSSIER.REQUEST_INVOICE_TO_DMS')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'saleRequestDeliveryToDMS':
        message = t('MESSAGES.SALES_DOSSIER.REQUEST_DELIVERY_TO_DMS')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'saleRequestOutboundToDMS':
        message = t('MESSAGES.SALES_DOSSIER.REQUEST_OUTBOUND_TO_DMS')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'sentToDMSSuccessfully':
        message = t('ERRORS.BACKEND_TAG_TRANSLATION.SENT_TO_DMS_SUCCESSFULLY')
        replaceMessageArgs.forEach((val) => {
          message = message.replace(val.id, val.value)
        })
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => { }
        break
      case 'errorSentToDMS':
        message = t('ERRORS.BACKEND_TAG_TRANSLATION.ERROR_SENT_TO_DMS')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => { closeModal() }}>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => { }
        break
      case 'sentToStepsDMSSuccessfully':
        message = t('ERRORS.BACKEND_TAG_TRANSLATION.SENT_TO_STEPS_DMS_SUCCESSFULLY')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => { closeModal() }}>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => { }
        break
      /// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      case 'initSaleToSelfSupply':
        message = t('MESSAGES.CONTINUE_SELF_SUPPLY_PROCESS')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => okResponse()}>{t('GLOBAL.BUTTONS.YES')}</Button>,
          <Button id="btn_no_modal" className="btn-standard" key="2" onClick={() => nOkResponse()}>{t('GLOBAL.BUTTONS.NO')} </Button>])
        onHide = () => { }
        break
      /// //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
      case 'selfSupplySucces':
        message = t('MESSAGES.SELF_SUPPLY_PROCESS_SUCCES')
        subMessage = this.props.subMessage ? this.props.subMessage : ''
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => okResponse()}>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => { }
        break
      case 'purchaseAlreadyExists':
        message = t('MESSAGES.PURCHASE_ALREADY_EXISTS')
        subMessage = this.props.subMessage ? this.props.subMessage : ''
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => okResponse()}>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => { }
        break
      case 'nonValidFile':
        message = t('MESSAGES.NON_VALID_FILE')
        replaceMessageArgs.forEach((val) => {
          message = message.replace(val.id, val.value)
        })
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => { okResponse(); closeModal() } }>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => { }
        break
      case 'confirmForceCancelDocument':
        message = t('MESSAGES.CONFIRM_FORCE_CANCEL_DOC')
        replaceMessageArgs.forEach((val) => {
          message = message.replace(val.id, val.value)
        })
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'cloneRole':
        message = t('MESSAGES.CLONE_ROLE')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'orNumberSavedSusccesfully':
        message = t('MESSAGES.ORNUMBER_SAVED_SUSSECSSFULLY')
        buttons = ([<div className='buttons-center-wrapper'><Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => okResponse()}>{t('GLOBAL.BUTTONS.CONFIRM')}</Button></div>])
        onHide = () => { }
        break
      case 'failedToDownload':
        message = t('ERRORS.BACKEND_TAG_TRANSLATION.ERROR_FAILED_TO_DOWNLOAD')
        replaceMessageArgs.forEach((val) => {
          message = message.replace(val.id, val.value)
        })
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => { }
        break
      case 'maximumSizeExceeded':
        message = t('ERRORS.BACKEND_TAG_TRANSLATION.ERROR_MAXIMUN_FILE_SIZE_EXCEEDED')
        replaceMessageArgs.forEach((val) => {
          message = message.replace(val.id, val.value)
        })
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => { }
        break
      case 'rescueLeasingAlert':
        message = t('MESSAGES.RESCUE_LEASING_ALERT')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => okResponse()}>{t('GLOBAL.BUTTONS.OK')}</Button>,
          <Button id="btn_no_modal" className="btn-standard-grey" key="2" onClick={() => nOkResponse()}>{t('GLOBAL.BUTTONS.CANCEL')}</Button>])
        onHide = () => { }
        break
      case 'additionalInvoiceAlert':
        message = t('MESSAGES.ADDITIONAL_INVOICE_ALERT')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => okResponse()}>{t('GLOBAL.BUTTONS.OK')}</Button>,
          <Button id="btn_no_modal" className="btn-standard-grey" key="2" onClick={() => nOkResponse()}>{t('GLOBAL.BUTTONS.CANCEL')}</Button>])
        onHide = () => { }
        break
      case 'missingDocumentsAlert':
        message = t('MESSAGES.MISSING_DOCUMENTS_ALERT')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => { okAction(); closeModal() }}>{t('GLOBAL.BUTTONS.OK')}</Button>,
          <Button id="btn_no_modal" className="btn-standard-grey" key="2" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.CANCEL')}</Button>])
        onHide = () => { }
        break
      case 'setValidationDisableModal':
        message = t('MESSAGES.SET_VALIDATION_DISABLE_MODAL')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => { okAction(); closeModal() } }>{t('GLOBAL.BUTTONS.YES')}</Button>,
          <Button id="btn_no_modal" className="btn-standard-grey" key="2" onClick={() => { cancelAction(); closeModal() } }>{t('GLOBAL.BUTTONS.NO')}</Button>])
        onHide = () => { }
        break
      case 'auditDetailsDocumentsGrouped':
        message = (<h4>{t('MESSAGES.AUDIT.AUDIT_DETAILS_GROUPED_DOCUMENTS_TITLE') + ' ' + replaceMessageArgs.item2}</h4>)
        components = (
          <Table hover responsive className="simple-table massive-download-table">
            <thead>
              <th>{t('MESSAGES.AUDIT.AUDIT_DETAILS_GROUPED_DOCUMENTS_SUBTITLE')}</th>
            </thead>
            <tbody>
              {
                replaceMessageArgs.item1.map((x, idx) => {
                  return (<tr key={idx}>
                    <td>{x}</td>
                  </tr>)
                })
              }
            </tbody>
          </Table>
        )
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => {}
        break
      case 'groupDocumentsSuccess':
        components = (
          <div>
            <div>
              {(t('DOSSIER_COMPONENTS.DOCUMENTARY_MANAGEMENT.GROUP_DOCUMENTS_CREATE_MODAL.CONFIRMATION')).split('#')[0]}
            </div>
            <h4>
              {replaceMessageArgs}
            </h4>
            <div>
              {(t('DOSSIER_COMPONENTS.DOCUMENTARY_MANAGEMENT.GROUP_DOCUMENTS_CREATE_MODAL.CONFIRMATION')).split('#')[t('DOSSIER_COMPONENTS.DOCUMENTARY_MANAGEMENT.GROUP_DOCUMENTS_CREATE_MODAL.CONFIRMATION').split('#').length - 1]}
            </div>
          </div>
        )
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal() }>{t('GLOBAL.BUTTONS.OK')}</Button>])
        onHide = () => { }
        break
      case 'changePurchaseTypeNotSaleable':
        message = t('MESSAGES.CHANGE_PURCHASE_TYPE_NOT_SALEABLE')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => { okAction(); closeModal() } }>{t('GLOBAL.BUTTONS.YES')}</Button>,
          <Button id="btn_no_modal" className="btn-standard-grey" key="2" onClick={() => { cancelAction(); closeModal() } }>{t('GLOBAL.BUTTONS.NO')}</Button>])
        onHide = () => { }
        break
      case 'notChangePurchaseTypeNotSaleable':
        message = t('MESSAGES.NOT_CHANGE_PURCHASE_TYPE_NOT_SALEABLE')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => { cancelAction(); closeModal() } }>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => { }
        break
      case 'loadSepomexFileProcessCreated':
        message = t('MESSAGES.LOAD_SEPOMEX_FILE_PROCESS_CREATED')
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => { okAction(); closeModal() } }>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => {}
        break
      case 'confirmationModal':
        if (cancelAction !== null && cancelAction !== undefined) {
          cancelMethod = () => { cancelAction(); closeModal() }
        }
        message = t(`MESSAGES.${replaceMessageArgs}`)
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => { okAction(); closeModal() } }>{t('GLOBAL.BUTTONS.YES')}</Button>,
          <Button id="btn_no_modal" className="btn-standard-grey" key="2" onClick={() => { cancelMethod() } }>{t('GLOBAL.BUTTONS.NO')}</Button>])
        onHide = () => { }
        break
      case 'yesNoModal':
        message = t(`MESSAGES.${this.props.message}`)
        buttons = this.schemaYesNo()
        onHide = () => {}
        break
      case 'unattendedProcessFileExpired':
        message = t('MESSAGES.UNNATENDED_PROCESS_FILE_EXPIRED')
        replaceMessageArgs.forEach((val) => {
          message = message.replace(val.id, val.value)
        })
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => {}
        break
      case 'deletecodeCommission':
        message = t('MESSAGES.DELETE_CODE_COMMISSION') + '</br>' + t('MESSAGES.WANT_CONTINUE')
        buttons = this.schemaOkCancel()
        onHide = () => { }
        break
      case 'noDeleteDossierFromFleetForCharges':
        message = t('MESSAGES.NO_DELETE_DOSSIER_FROM_FLEET_FOR_CHARGES')
        replaceMessageArgs.forEach((val, index) => {
          subMessage += val.value
          if (index < replaceMessageArgs.length - 1) {
            subMessage += ', '
          }
        })

        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.OK')}</Button>])
        onHide = () => { }
        break
      case 'htmlDefaultClose':
      case 'htmlDefaultOk':
      case 'htmlDefaultOkCancel':
      case 'htmlDefaultYesNo':
      case 'htmlDefaultAcceptCancel':
        message = this.replaceTextWithTranslation(this.props.message)

        if (replaceMessageArgs) {
          replaceMessageArgs.forEach((val) => {
            message = message.replace(val.id, val.value)
          })
        }

        subMessage = this.props.subMessage ? this.replaceTextWithTranslation(this.props.subMessage) : ''
        if (replaceSubMessageArgs) {
          replaceSubMessageArgs.forEach((val) => {
            subMessage = subMessage.replace(val.id, val.value)
          })
        }
        switch (this.props.modalType) {
          case 'htmlDefaultClose':
            buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
            onHide = () => { }
            break
          case 'htmlDefaultOk':
            buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.OK')}</Button>])
            onHide = () => { }
            break
          case 'htmlDefaultOkCancel':
            buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => okResponse()}>{t('GLOBAL.BUTTONS.OK')}</Button>,
              <Button id="btn_no_modal" className="btn-standard-grey" key="2" onClick={() => nOkResponse()}>{t('GLOBAL.BUTTONS.CANCEL')}</Button>])
            onHide = () => { }
            break
          case 'htmlDefaultYesNo':
            buttons = this.schemaYesNo()
            onHide = () => {}
            break
          case 'htmlDefaultAcceptCancel':
            buttons = this.schemaOkCancel()
            onHide = () => {}
            break
        }

        break
      case /^mustFillMinFilters(\d+)$/.test(modalType) && modalType:
      {
        const minFilters = parseInt(modalType.match(/^mustFillMinFilters(\d+)$/)[1], 10)
        message = t('MESSAGES.MUST_FILL_MIN_FILTERS')
        message = message.replace('##MIN_LENGTH_STRING##', minFilters)
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => { }
        break
      }
      default:
        message = t(`MESSAGES.${modalType}`)
        buttons = ([<Button id="btn_yes_modal" className="btn-standard " key="1" onClick={() => closeModal()}>{t('GLOBAL.BUTTONS.CLOSE')}</Button>])
        onHide = () => { }
        break
    }
    let modalStyle = 'custom-modal'

    switch (this.props.modalType) {
      case 'auditDetails':
        modalStyle = 'custom-modal-audit bitacora'
        break
      case 'actionAdminFleetExp':
        modalStyle = 'custom-modal-fleet'
        break
      case 'observations':
        modalStyle = 'custom-modal fleetObservations'
        break
      case 'initSaleToSelfSupply':
        modalStyle = 'custom-modal-self-supply selfsupply'
        break
      case 'purchaseAlreadyExists':
        modalStyle = 'custom-modal-self-supply selfsupply'
        break
      case 'auditDetailsDocumentsGrouped':
        modalStyle = 'massive-download-modal'
        break
      default:
        modalStyle = 'custom-modal'
        break
      case 'confirmUpdateSaleType':
        message = t('MESSAGES.UPDATE_SALE_TYPE')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'deleteSaleTypeModal':
        message = t('MESSAGES.DELETE_SALE_TYPE')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'recoverSaleTypeModal':
        message = t('MESSAGES.RECOVER_SALE_TYPE')
        buttons = this.schemaYesNo()
        onHide = () => { }
        break
      case 'unattendedProcessFileExpired':
        modalStyle = 'custom-modal fileName'
        break
      case 'confirmAuditFile':
        modalStyle = 'custom-modal AuditFile'
        break
      case 'confirmAuditFinish':
        modalStyle = 'custom-modal AuditFile'
        break
      case 'correctAudit':
        modalStyle = 'custom-modal AuditFile'
        break
      case 'confirmSaveAudit':
        modalStyle = 'custom-modal AuditFile'
        break
      case 'deleteEventSubscription':
        modalStyle = 'custom-modal eventSubscriptions'
    }

    return (
      <Modal show={showModal} onHide={onHide} className={'_nf ' + modalStyle} restoreFocus={false} backdrop={'static'}>
        {this.props.modalType === 'observations' && (
          <Modal.Header closeButton onHide={() => closeModal()}>
            <Modal.Title> {t('GLOBAL.OBSERVATIONS')}</Modal.Title>
          </Modal.Header>
        )}
        <Modal.Body>
          {(this.props.modalType === 'confirmExcelDocument' || this.props.modalType === 'auditDetails' || this.props.modalType === 'purchaseCampaignProcces' || this.props.modalType === 'actionAdminFleetExp' || this.props.modalType === 'insertUoCompany' || this.props.modalType === 'deleteFinancingTypeProductInUse') ? message.split('\n').map((item, key) => {
            return <span key={key}>{item}<br /></span>
          }) : (this.props.modalType.startsWith('htmlDefault') ? <div dangerouslySetInnerHTML={{ __html: message }} /> : message)}
          {!this.props.modalType.startsWith('htmlDefault') ? (
            <p id="modalMessage" className={this.props.modalType === 'selfSupplySucces' || this.props.modalType === 'purchaseAlreadyExists' ? 'result-self-supply' : 'subtitleText'}>
              {subMessage}
            </p>
          ) : <div key="div1" dangerouslySetInnerHTML={{ __html: subMessage }} />}
          {components}
          <div key="div2" className={ this.props.modalType === 'confirmSaveAvailabilityDate' ? 'buttons-wrapper-papManager' : this.props.modalType === 'initSaleToSelfSupply' || this.props.modalType === 'purchaseAlreadyExists' ? 'self-supply-btn-exit col-sm-12' : 'buttons-wrapper' } >
            {buttons}
          </div>
        </Modal.Body>
      </Modal>
    )
  }
}
export default reduxForm({
  form: 'customModal',
  validate: (values, props) => {
    const error = { agency: {} }
    const tKey = 'DOSSIER_COMPONENTS.SERVICE_DOSSIER.SERVICE_COMPONENT.ISSUE_MODAL.VALIDATIONS.'
    if (!values.providerObservations) {
      error.providerObservations = props.t(`${tKey}PROVIDER_OBSERVATIONS`)
    }
    return Object.keys(error).length > 0 ? error : {}
  }
})(CustomModal)
