import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import CodeCommisionModal from '../../../components/dossiers/common/CodeCommisionModal'
import { closeCodeCommissionModal } from '../../../actions/dossier/common/codeCommisionModal'

export function mapStateToProps (state, ownProps) {
  return {
    ...state.codeCommisionModal
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      closeCodeCommissionModal
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(CodeCommisionModal))
