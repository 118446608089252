import apiFetch from '../apiFetch'

export default function (token, catalogueId, modelId, includeDeleted = false) {
  const queryParamsArray = [
    `includeDeleted=${includeDeleted}`,
    catalogueId ? `catalogueId=${catalogueId}` : null,
    modelId ? `modelId=${modelId}` : null
  ]

  const queryParams = queryParamsArray.reduce(
    (result, query, idx) => result + (query ? (result ? `&${query}` : `?${query}`) : '')
    , ''
  )

  return apiFetch({ endPoint: 'tapestry' + queryParams, method: 'GET', body: null, token: token })
}
