import React, { PureComponent } from 'react'
import { Modal, Row, Col, Table, Button, Tooltip, OverlayTrigger, FormGroup } from 'react-bootstrap'
import { reduxForm, Field } from 'redux-form'
import InputTreeSelect from '../../commons/form/InputTreeSelect'
import IDocCarPagination from '../../commons/iDocCarPagination'
class FinancingMasterModalUo extends PureComponent {
  constructor () {
    super()
    this.state = {
    }
  }

  submitFinancing (values) {
    this.props.actions.saveUoFinancingCompany(values.organizedUnitId, this.props.financing)
  }

  fetchFinancingList (value) {
    this.props.actions.openEditFinancingModalUo(this.props.financing, value, this.props.filter.pageSize, this.props.filter.orderBy)
  }

  getSortIcon (column = '') {
    switch (this.props.filter.orderBy) {
      case '+' + column:
        return 'ico-sort-selected-asc'
      case '-' + column:
        return 'ico-sort-selected-desc'
      default:
        return 'ico-sort'
    }
  }

  changeOrder (field) {
    switch (this.props.filter.orderBy) {
      case `+${field}`:
        this.props.actions.openEditFinancingModalUo(this.props.financing, this.props.page, this.props.filter.pageSize, `-${field}`)
        break
      case `-${field}`:
        this.props.actions.openEditFinancingModalUo(this.props.financing, this.props.page, this.props.filter.pageSize, `+${field}`)
        break
      default:
        this.props.actions.openEditFinancingModalUo(this.props.financing, this.props.page, this.props.filter.pageSize, `-${field}`)
        break
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.showModal !== this.props.showModal && nextProps.showModal === true) {
      this.props.initialize({ ...nextProps.financing })
    }
  }

  cleanUoCOmbo () {
    this.props.reset()
  }

  render () {
    const {
      t, handleSubmit,
      showModal, uoList, uoListCount, pagesCount, financing
      , combos: { UOTreeCombo }, actions: { deleteUoFinancingCompany, recoverUoFinancingCompany, closeEditFinancingModalUo }, filter: { page }
    } = this.props
    const tKey = 'MASTERS.FINANCING_MASTERS.MODAL_UO.'
    const deleteTooltip = (<Tooltip id="deleteTooltip">{t(`${tKey}DELETE`)}</Tooltip>)
    const recoverTooltip = (<Tooltip id="recoverTooltip">{t(`${tKey}RECOVER`)}</Tooltip>)
    const finName = financing && financing.name
    return (
      <Modal className="role-modal languages-modal" show={showModal} onHide={closeEditFinancingModalUo}>
        <form autoComplete="off" onSubmit={handleSubmit(this.submitFinancing.bind(this))}>
          <Modal.Header closeButton onHide={closeEditFinancingModalUo}>
            <Modal.Title>{t(`${tKey}TITLE`)} - {finName}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <Table className="editable-table" hover responsive>
                <thead>
                  <tr>
                    <th onClick={() => { this.changeOrder('name') }}>
                      {t(`${tKey}UO_NAME`)}
                      <i className={this.getSortIcon('name')} />
                    </th>
                    <th style={{ width: '177px' }}>{t('DOSSIER_COMPONENTS.ACCESSORIES.ACTIONS')}</th>
                  </tr>
                </thead>
                <tbody>
                  {uoList && uoList.map((uo, idx) => {
                    return (
                      <tr key={idx} className={(uo.isDeleted ? 'is-deleted' : '')}>
                        <td>{uo.organizedUnit && uo.organizedUnit.name}</td>
                        <td>
                          {!uo.isDeleted ? (
                            <div>
                              <a id="deleteUoFinancingCompany" onClick={() => deleteUoFinancingCompany(uo.organizedUnitId, this.props.financing)}>
                                <OverlayTrigger placement="left" overlay={deleteTooltip}>
                                  <i className="ico-trash"/>
                                </OverlayTrigger>
                              </a>
                            </div>
                          ) : (<a onClick={() => recoverUoFinancingCompany(uo.organizedUnitId, this.props.financing)}>
                            <OverlayTrigger placement="left" overlay={recoverTooltip}>
                              <i className="glyphicon glyphicon-repeat" />
                            </OverlayTrigger>
                          </a>)}
                        </td>
                      </tr>)
                  })
                  }
                  {(uoList === null || uoList === undefined || uoList.length === 0) &&
                <tr>
                  <td colSpan={4}><h2>{t(`${tKey}RESULTS_NOT_FOUND`)}</h2></td>
                </tr>}
                </tbody>
              </Table>
              <div className="section-users-footer">
                <nav key={1} className="text-center">
                  <IDocCarPagination
                    id="btn_pag_Uos"
                    page={page}
                    pagesCount={pagesCount}
                    totalRows={uoListCount}
                    onSelectAction={(value) => this.fetchFinancingList(value)}
                  />
                </nav>
              </div>
              <Row key={2} className="inputs-wrapper">
                <Col sm={4} className="custom-width-col">
                  <h2>{t(`${tKey}ORGANIZED_UNIT`)}</h2>
                  <Field
                    name="organizedUnitId"
                    placeholder={t(`${tKey}ORGANIZED_UNIT`)}
                    component={InputTreeSelect}
                    multi={true}
                    valueKey="value"
                    labelKey="label"
                    childrenKey="children"
                    colSm={0}
                    options={UOTreeCombo}
                    id="organizedUnitId"
                  />
                </Col>

                <Col sm={1} className="custom-width-col">
                  <a id="cleanUoCOmbo" className="a-trash" onClick={() => this.cleanUoCOmbo()}>
                    <OverlayTrigger placement="left" overlay={deleteTooltip}>
                      <i className="ico-trash trash-ico-uo"/>
                    </OverlayTrigger>
                  </a>
                </Col>
              </Row>
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <Col sm={12} className="btn-wrapper">
              <Button
                id="role-modal-submit-button"
                className="btn-standard"
                type="submit"
                disabled={this.props.pristine}>
                {t(`${tKey}SUBMIT_BUTTON`)}
              </Button>
            </Col>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default reduxForm({
  form: 'financingMasterModalUo'
})(FinancingMasterModalUo)
