export const ThemeValues = [
  {
    themeId: 0,
    description: 'light'
  },
  {
    themeId: 1,
    description: 'dark'
  }
]
