import React, { PureComponent } from 'react'
import { Modal, Button, Table, Checkbox, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Field, reduxForm } from 'redux-form'
import { translateDocumentEntityType } from '../../../../constants/dossier/common/documentEntityType'
import { translateDocumentStatus, documentStatusClass, documentStatus } from '../../../../constants/dossier/common/documentStatus'
import InputCheckbox from '../../../commons/form/InputCheckBox'

class DocumentaryMassiveDownloadModal extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      documentsIds: [],
      documentsSelected: [],
      documentList: [],
      onlyValidated: false,
      allSelected: false,
      order: {
        columnName: null,
        direction: 'asc'
      }
    }
  }

  componentDidUpdate () {

  }

  getSnapshotBeforeUpdate (prevProps) {
    if (prevProps.showModal !== this.props.showModal && this.props.showModal) {
      this.setState({
        documentsSelected: [],
        allSelected: false,
        onlyValidated: false,
        documentList: this.props.documentList,
        documentsIds: this.props.documentList.map((d) => { return d.documentId })
      })
    }
    return this.props
  }

  sendRequest () {
    if (this.state.documentsSelected.length > 0) {
      new Promise((resolve, reject) => {
        let aliveDocuments = this.props.documentList.filter(d => d.isHistorical === false && this.state.documentsSelected.includes(d.documentId)).map((d) => { return d.documentId }) ?? []
        let historicalDocuments = this.props.documentList.filter(d => d.isHistorical === true && this.state.documentsSelected.includes(d.documentId)).map((d) => { return d.documentId }) ?? []
        this.props.actions.downloadDocumentsSelected(this.props.dossierId, this.props.isHistorical, aliveDocuments, historicalDocuments, resolve, reject)
      }).then(() => {
        this.props.closeModal()
      })
    }
  }

  orderTable (columnName) {
    let documents = this.state.documentList.sort(function (a, b) {
      if (columnName === 'validationDate') {
        const fechaInicial = a.validationDate ? a.validationDate.split(' ') : ['-']
        const separarA = fechaInicial.map((dia) => dia[0] === '-' ? ('01,01,2000,00,00') : (fechaInicial[0].split('/').join(',') + ',' + fechaInicial[1].split(':').join(',')))
        const fechaA = separarA.map((datos) => datos.split(','))
        const fechaFinal = b.validationDate ? b.validationDate.split(' ') : ['-']
        const separarB = fechaFinal.map((dia) => dia[0] === '-' ? ('01,01,2000,00,00') : (fechaFinal[0].split('/').join(',') + ',' + fechaFinal[1].split(':').join(',')))
        const fechaB = separarB.map((datos) => datos.split(','))
        const fecha1 = new Date(fechaA[0][2], fechaA[0][1], fechaA[0][0], fechaA[0][3], fechaA[0][4])
        const fecha2 = new Date(fechaB[0][2], fechaB[0][1], fechaB[0][0], fechaB[0][3], fechaB[0][4])
        return fecha2 - fecha1
      } else if (columnName === 'incorporationDate') {
        const fechaInicial = a.incorporationDate ? a.incorporationDate.split(' ') : ['-']
        const separarA = fechaInicial.map((dia) => dia[0] === '-' ? ('01,01,2000,00,00') : (fechaInicial[0].split('/').join(',') + ',' + fechaInicial[1].split(':').join(',')))
        const fechaA = separarA.map((datos) => datos.split(','))
        const fechaFinal = b.incorporationDate ? b.incorporationDate.split(' ') : ['-']
        const separarB = fechaFinal.map((dia) => dia[0] === '-' ? ('01,01,2000,00,00') : (fechaFinal[0].split('/').join(',') + ',' + fechaFinal[1].split(':').join(',')))
        const fechaB = separarB.map((datos) => datos.split(','))
        const fecha1 = new Date(fechaA[0][2], fechaA[0][1], fechaA[0][0], fechaA[0][3], fechaA[0][4])
        const fecha2 = new Date(fechaB[0][2], fechaB[0][1], fechaB[0][0], fechaB[0][3], fechaB[0][4])
        return fecha2 - fecha1
      } else if (a[columnName] && a[columnName].localeCompare) {
        return a[columnName].localeCompare(b[columnName])
      } else {
        return (isNaN(a[columnName]) ? 0 : a[columnName]) - (isNaN(b[columnName]) ? 0 : b[columnName])
      }
    })
    if (this.state.order.direction === 'asc') {
      documents.reverse()
    }
    this.setState({ documentList: documents })
  }

  changeDirection (columnName) {
    this.setState({
      order: {
        columnName: columnName,
        direction: this.state.order.direction === 'asc' ? 'desc' : 'asc'
      }
    }, this.orderTable(columnName))
  }

  changeOrderIcon (name) {
    let clase = 'ico-sort'
    if (this.state.order.direction === 'asc' && this.state.order.columnName === name) {
      clase += '-selected-asc'
    } else if (this.state.order.direction === 'desc' && this.state.order.columnName === name) {
      clase += '-selected-desc'
    }
    return clase
  }

  selectAllDocuments () {
    if (this.state.allSelected) {
      this.setState({ documentsSelected: [], allSelected: false })
    } else {
      this.setState({ documentsSelected: this.state.documentsIds, allSelected: true })
    }
  }

  onlyValidated () {
    if (this.state.onlyValidated) {
      this.setState({
        onlyValidated: false,
        documentList: this.props.documentList,
        documentsIds: this.props.documentList.map((d) => { return d.documentId })
      })
    } else {
      var documents = this.props.documentList.filter(d => d.documentStatus === documentStatus.VALIDATED)

      this.setState({
        onlyValidated: true,
        documentList: documents,
        documentsIds: documents.map((d) => { return d.documentId })
      })
    }

    this.setState({ allSelected: false, documentsSelected: [] })
  }

  addDocumentIdToArray (documentId) {
    if (!this.state.documentsSelected.includes(documentId)) {
      this.setState({ documentsSelected: [...this.state.documentsSelected, documentId] })
      if (this.state.documentsIds.length === (this.state.documentsSelected.length + 1)) {
        this.setState({ allSelected: true })
      }
    } else {
      this.setState({ documentsSelected: this.state.documentsSelected.filter(d => d !== documentId) })
      if (this.state.allSelected) {
        this.setState({ allSelected: false })
      }
    }
  }

  render () {
    let {
      t, showModal, closeModal
    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.DOCUMENTARY_MANAGEMENT.'

    return (
      <Modal show={showModal} className="massive-download-modal" onHide={() => closeModal()} backdrop={'static'}>

        <Modal.Header closeButton onHide={() => closeModal()}>
          <h3>{t(`${tKey}MASSIVE_DOWNLOAD_MODAL.TITLE`)}</h3>
        </Modal.Header>

        <Modal.Body>
          <h4>{t(`${tKey}MASSIVE_DOWNLOAD_MODAL.SUBTITLE`)}</h4>
          <Checkbox
            onChange={() => this.onlyValidated()}
            checked={this.state.onlyValidated}>
            {t(`${tKey}MASSIVE_DOWNLOAD_MODAL.SHOW_ONLY_VALIDATES`)}
          </Checkbox>
          <Table hover responsive className="simple-table massive-download-table">
            <thead>
              <tr>
                <th onClick={() => [this.changeDirection('documentStatus')]}><span>{t(`${tKey + 'TABLE.STATUS'}`)}<i className={(() => [this.changeOrderIcon('documentStatus')])()}/></span></th>
                <th onClick={() => [this.changeDirection('documentName')]}><span>{t(`${tKey + 'TABLE.TYPES'}`)}<i className={(() => [this.changeOrderIcon('documentName')])()}/></span></th>
                <th onClick={() => [this.changeDirection('documentTypeEntityId')]}><span>{t(`${tKey + 'TABLE.CATEGORY'}`)}<i className={(() => [this.changeOrderIcon('documentEntityType')])()}/></span></th>
                <th onClick={() => [this.changeDirection('validationDate')]}><span>{t(`${tKey + 'TABLE.VALIDATION_DATE'}`)}<i className={(() => [this.changeOrderIcon('validationDate')])()}/></span></th>
                <th onClick={() => [this.changeDirection('incorporationDate')]}><span>{t(`${tKey + 'TABLE.ASSIGNMENT_DATE'}`)}<i className={(() => [this.changeOrderIcon('incorporationDate')])()}/></span></th>
                <th><span>{t(`${tKey}MASSIVE_DOWNLOAD_MODAL.SELECT_ALL`)}</span><Checkbox
                  onChange={() => this.selectAllDocuments()}
                  checked={this.state.allSelected}>

                </Checkbox></th>
              </tr>
            </thead>
            <tbody>
              {
                this.state.documentList && this.state.documentList.map((doc, idx) => {
                  return (<tr key={idx}>
                    <td className="text-left">
                      <span>
                        <OverlayTrigger placement="right" overlay={(<Tooltip id="statusTooltip">{doc.documentStatus && t(`${tKey}DOCUMENTARY_MANAGEMENT.${translateDocumentStatus[doc.documentStatus]}`)}</Tooltip>)}>
                          <i id="documentStatus" className={'ico-document-' + documentStatusClass[translateDocumentStatus[doc.documentStatus]]}/>
                        </OverlayTrigger>
                      </span>
                    </td>
                    <td>{doc.documentName}</td>
                    <td><span className="file-text">{(t(`${tKey}${translateDocumentEntityType[doc.documentTypeEntityId] ?? doc.documentTypeEntityId}`))}</span></td>
                    <td><span className="file-text">{doc.validationDate}</span></td>
                    <td><span className="file-text">{doc.incorporationDate}</span></td>
                    <td>
                      {
                        <Field
                          colSm={2}
                          component={InputCheckbox}
                          name={'downloadCheck' + doc.documentId}
                          inline
                          customClass="checkbox-inline-search"
                          onChange={() => this.addDocumentIdToArray(doc.documentId)}
                          checked= {this.state.documentsSelected.includes(doc.documentId)}
                        />
                      }
                    </td>
                  </tr>)
                })
              }
            </tbody>
          </Table>
        </Modal.Body>

        <Modal.Footer>
          <Button className="btn-save" onClick={() => this.sendRequest()} disabled={this.state.documentsSelected.length === 0}>{t(`${tKey + 'MASSIVE_DOWNLOAD_MODAL.DOWNLOAD'}`)}</Button>
          <Button className="btn-cancel" onClick={() => closeModal()}>{t(`${tKey + 'MASSIVE_DOWNLOAD_MODAL.CANCEL'}`)}</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default reduxForm({ form: 'documentaryMassiveDownloadModal' })(DocumentaryMassiveDownloadModal)
