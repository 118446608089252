import React, { PureComponent } from 'react'
import { Row, Table, Col } from 'react-bootstrap'
class TabPane8 extends PureComponent {
  render () {
    return (
      <Row className="trafficPane">
        <Col sm={12}>
          <Table responsive className="table-tabPane">
            <thead>
              <tr>
                <th>Exp. trámite</th>
                <th>Gestoría cliente</th>
                <th>Trámite</th>
                <th>Fecha trámite</th>
                <th>Estado exp.</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </Table>
          <div className="no-traffic-data">
            <i className="ico-not-allowed"></i>
            <h4>Sin trámites tráfico</h4>
            <span>De momento no hay ninguna información sobre trámites tráfico</span>
          </div>
        </Col>
      </Row>
    )
  }
}

export default TabPane8
