import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import DocumentaryManagementModal from '../../../components/dossiers/common/documentaryManagement/DocumentaryManagementModal'
import { closeDocumentModalCallbacks, cancelDocument, refreshDocumentManagementModal, addDependant, printCheckList, setCurrentTabCounterValues } from '../../../actions/dossier/common/documentManagementModal'
import { openDocumentCreationModal, openDocumentEditionModal } from '../../../actions/dossier/common/documentEditionModal'
import { openDocumentCreationModal as openDocumentCreationModalHistorical, openDocumentEditionModal as openDocumentEditionModalHistorical } from '../../../actions/dossier/common/documentHistoricalEditionModal'
import { translate } from 'react-polyglot'
import { openModal } from '../../../actions/common'

export function mapStateToProps (state) {
  return {
    ...state.documentaryManagementModal,
    showDocumentEditionModal: state.documentEditionModal.showDocumentEditionModal
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      closeDocumentModalCallbacks,
      openDocumentCreationModal,
      openDocumentEditionModal,
      cancelDocument,
      addDependant,
      refreshDocumentManagementModal,
      printCheckList,
      setCurrentTabCounterValues,
      openModal,
      openDocumentCreationModalHistorical,
      openDocumentEditionModalHistorical
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(DocumentaryManagementModal))
