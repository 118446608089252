import React, { PureComponent } from 'react'
import { reduxForm, Field } from 'redux-form'
import { Button, Modal, Col, Row, Form } from 'react-bootstrap'
import InputText from '../../../commons/form/InputText'
import InputMoney from '../../../commons/form/InputMoney'
import InputCheckBox from '../../../commons/form/InputCheckBox'
import InputSelect from '../../../commons/form/InputSelect'

class PaperworkEditionModal extends PureComponent {
  submitPaperwork (values) {
    const submitValues = {
      paperworkTypeId: values.paperworkTypeId,
      cost: values.cost,
      processingAgencyId: values.processingAgencyId,
      comment: values.comment,
      toBeReviewed: values.toBeReviewed
    }
    if (values.paperworkId) {
      this.props.actions.updatePaperwork(submitValues, values.paperworkId, this.props.dossierParentId, this.props.actions.updateDossierAction, this.props.showAlertSendPending)
    } else {
      this.props.actions.createPaperwork(submitValues, this.props.dossierParentId, this.props.actions.updateDossierAction, this.props.showAlertSendPending)
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (this.props.showModal && !nextProps.showModal) {
      this.props.initialize({})
    }
  }

  render () {
    const {
      t, showModal, handleSubmit, isEditionModal,
      actions: { closePaperworkEditionModal },
      combos: { processingAgency, paperworkTypes }
    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.PAPERWORK.MODAL.'
    return (
      <Modal show={showModal} onHide={() => closePaperworkEditionModal()}>
        <Form autoComplete="off" onSubmit={handleSubmit(this.submitPaperwork.bind(this))}>
          <Modal.Header closeButton>
            <Modal.Title>{t(`${tKey}TITLE`)}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Field
                colSm={6}
                id="paperworkTypeId"
                name="paperworkTypeId"
                controlLabel={t(`${tKey}TYPE`)}
                placeholder={t(`${tKey}TYPE`)}
                component={InputSelect}
                options={paperworkTypes}
                disabled={isEditionModal}
                valueKey="id"
                labelKey="value"
              />
              <Field
                colSm={6}
                id="processingAgencyId"
                name="processingAgencyId"
                controlLabel={t(`${tKey}PROCESSING_AGENCY`)}
                placeholder={t(`${tKey}PROCESSING_AGENCY`)}
                component={InputSelect}
                options={processingAgency}
                valueKey="id"
                labelKey="value"
              />
            </Row>
            <Row>
              <Field
                id="cost"
                name="cost"
                type="text"
                colSm={6}
                controlLabel={t(`${tKey}COST`)}
                placeholder={t(`${tKey}COST`)}
                component={InputMoney}
              />
              <Field
                id="comment"
                name="comment"
                colSm={6}
                controlLabel={t(`${tKey}COMMENTS`)}
                placeholder={t(`${tKey}COMMENTS`)}
                component={InputText}
              />
            </Row>
            <Row>
              <Field
                id="toBeReviewed"
                name="toBeReviewed"
                colSm={6}
                inline
                placeholder={t(`${tKey}TO_BE_REVIEWED`)}
                component={InputCheckBox}
              />
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Col sm={6} className="btn-wrapper">
              <Button id="btn_save" className="btn-standard" type="submit">{t(`${tKey}SAVE_BTN`)}</Button>
            </Col>
          </Modal.Footer>
        </Form>
      </Modal>
    )
  }
}

export default reduxForm({
  form: 'paperwork_edition_modal',
  validate: (values, props) => {
    const error = {}
    if (!values.paperworkTypeId) error.paperworkTypeId = 'Debe seleccionar un tipo de trámite'
    if (!values.processingAgencyId) error.processingAgencyId = 'Debe seleccionar gestoria'
    return error
  }
})(PaperworkEditionModal)
