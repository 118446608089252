
import React, { PureComponent } from 'react'
import { Button, Modal, Col, Row, FormGroup } from 'react-bootstrap'
import { reduxForm, Field } from 'redux-form'
import TextInput from '../commons/TextInput'
import InputTreeSelect from '../commons/form/InputTreeSelect'

class PurchasePresentTypeModal extends PureComponent {
  submitPurchasePresentType (values) {
    let organizedUnitId = null
    const body = []
    Object.keys(values).map(item => {
      body.push(values[item])
      if (typeof values[item] !== 'object') {
        organizedUnitId = values[item]
      }
    })
    this.props.actions.savePurchasePresentTypeModal(organizedUnitId, body, this.props.purchasePresentTypeId)
  }

  render () {
    const { t, showPurchasePresentTypeModal, handleSubmit, languageList = [], combos: { UOTreeCombo } } = this.props
    return (
      <Modal show={showPurchasePresentTypeModal} onHide={this.props.actions.closePurchasePresentTypeModal} className="languages-modal">
        <Modal.Header closeButton>
          <i className="ico-users" /><Modal.Title>{t('MASTERS.PURCHASE_PRESENT_TYPE_MASTERS.ADD_PURCHASE_PRESENT')}</Modal.Title>
        </Modal.Header>
        <form autoComplete="off" onSubmit={handleSubmit(this.submitPurchasePresentType.bind(this))}>
          <Modal.Body>
            <h2>{t('MASTERS.PURCHASE_PRESENT_TYPE_MASTERS.ORGANIZED_UNIT')}</h2>
            <Field
              name="organizedUnitId"
              placeholder={t('MASTERS.PURCHASE_PRESENT_TYPE_MASTERS.ORGANIZED_UNIT')}
              component={InputTreeSelect}
              multi={false}
              allowClear={false}
              valueKey="value"
              labelKey="label"
              childrenKey="children"
              colSm={0}
              options={UOTreeCombo}
            />
            <Row className="title-column">
              <Col sm={4}>
                <h2>{t('MASTERS.NAME')}</h2>
              </Col>
              <Col sm={6}>
                <h2>{t('MASTERS.DESCRIPTION')}</h2>
              </Col>
              <Col sm={2}>
                <h2>{t('MASTERS.LANGUAGE')}</h2>
              </Col>
            </Row>
            <FormGroup>
              {languageList.map((val, idx) => {
                return (
                  <Row key={idx} className="inputs-wrapper">
                    <Col sm={4} className="custom-width-col">
                      <Field
                        name={`lang${val.languageId}.name`}
                        placeholder={t('MASTERS.NAME')}
                        component={TextInput}
                      />
                    </Col>
                    <Col sm={6} className="custom-width-col">
                      <Field
                        name={`lang${val.languageId}.description`}
                        placeholder={t('MASTERS.DESCRIPTION')}
                        component={TextInput}
                      />
                    </Col>
                    <Col sm={2} className="language-column">
                      <p>{`${val.description} (${val.name})`}</p>
                    </Col>
                  </Row>
                )
              })}
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <Col sm={6} className="btn-wrapper" smPush={6}>
              <Button type="submit" className="btn-standard">
                {t('MASTERS.SAVE')}
              </Button>
            </Col>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}
const validate = (values, props) => {
  const errors = {}
  props.languageList.map(language => {
    errors[`lang${language.languageId}`] = {}
    if (values[`lang${language.languageId}`] !== undefined) {
      if (!(values[`lang${language.languageId}`].name)) {
        errors[`lang${language.languageId}`].name = props.t('MASTERS.VALIDATION.NAME_REQUIRED')
      }
      if (!(values[`lang${language.languageId}`].description)) {
        errors[`lang${language.languageId}`].description = props.t('MASTERS.VALIDATION.DESCRIPTION_REQUIRED')
      }
    } else {
      errors[`lang${language.languageId}`].name = props.t('MASTERS.VALIDATION.NAME_REQUIRED')
      errors[`lang${language.languageId}`].description = props.t('MASTERS.VALIDATION.DESCRIPTION_REQUIRED')
    }
  })
  return errors
}
export default reduxForm({
  form: 'editPurchasePresentType',
  validate
})(PurchasePresentTypeModal)
