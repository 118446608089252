import React, { PureComponent } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Field, reduxForm } from 'redux-form'
import { Col, Glyphicon, FormControl, FormGroup, InputGroup, Image, Button, Alert } from 'react-bootstrap'
import Access from '../Access'

const validate = (values, { t }) => {
  const errors = {}
  if (!values.emailAddress) {
    errors.emailAddress = t('GLOBAL.FORMS.REQUIRED')
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.emailAddress)) {
    errors.emailAddress = t('GLOBAL.FORMS.INVALID_EMAIL_ADDRESS')
  }
  return errors
}

const renderField = ({ id, actions, icon, name, input, placeholder, type, meta: { error, touched } }) => (
  <FormGroup className={'inputs-auth clearfix ' + (error && touched ? 'has-error' : '')}>
    <Col smOffset={1} sm={10}>
      <InputGroup className="input-group-icon">
        <InputGroup.Addon>
          <Glyphicon bsClass='iconsprite' glyph={icon} />
        </InputGroup.Addon>
        <FormControl id={id} type={type} placeholder={placeholder} {...input} />
      </InputGroup>
      {error && touched &&
        <span className='help-block text-center'>{error}</span>
      }
    </Col>
  </FormGroup>
)

class RememberPassword extends PureComponent {
  render () {
    const { t, handleSubmit, error, emailSended, invalid, submitting, actions: { rememberPassword } } = this.props

    const onSubmit = (values) => {
      return new Promise((resolve, reject) => {
        rememberPassword(values, resolve, reject)
      })
    }
    return (
      <Access>
        <form className="auth" onSubmit={handleSubmit(onSubmit)}>
          <div className="company-wrapper">
            <h1 className="company-logo">
              <Image src="images/logo_kia.png" />
            </h1>
            <h2 className="company-name">
              iDocCar
            </h2>
          </div>
          <hr />
          <fieldset>
            <h3 className="title-modal">{t('REMIND_PASSWORD.REMIND_PASSWORD')}</h3>
            <Field
              id='email_remember'
              name='emailAddress'
              icon='form-input-email'
              placeholder={t('REMIND_PASSWORD.INSERT_EMAIL')}
              component={renderField}
            />
            <div className="text-center btn-auth">
              <LinkContainer to="/login">
                <Button
                  id='btn_back'
                  type='button'
                  bsStyle='warning'
                  className='btn-prev'
                  name='btn-prev'
                >{t('REMIND_PASSWORD.BACK')}</Button>
              </LinkContainer>
              <Button
                id='btn_send'
                type='submit'
                bsStyle='primary'
                className='btn-wide-sm btn-remember-auth'
                name='btn-remember-auth'
                disabled={invalid || submitting}
              >{t('REMIND_PASSWORD.SEND')}</Button>
            </div>
            {emailSended &&
              (<div id="sendMail">
                <Col smOffset={1} sm={10}>
                  <Alert bsStyle='warning' role='warning'>
                    <strong>{t('REMIND_PASSWORD.EMAIL_SENDED')}</strong>
                  </Alert>
                </Col>
              </div>
              )}
            {error &&
              <Col smOffset={1} sm={10}>
                <Alert bsStyle='danger' role='alert'>
                  <strong>{t('ERRORS.SERVER_ERROR')}</strong>
                  <p>{error}</p>
                </Alert>
              </Col>
            }
          </fieldset>
          <div className="poweredByWrapper">
            <Image src="images/poweredby.png" />
          </div>
        </form>
      </Access>
    )
  }
}

export default reduxForm({
  form: 'rememberPassword',
  validate
})(RememberPassword)
