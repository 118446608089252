
import React, { PureComponent } from 'react'
import { Button, Modal, Col, Row, FormGroup } from 'react-bootstrap'
import { Field, reduxForm } from 'redux-form'
import InputText from '../../commons/form/InputText'

class LegalCompanyModal extends PureComponent {
  submitLegalCompany (values) {
    this.props.actions.saveLegalCompanyModal(values, this.props.legalCompanyFields.legalCompanyId)
  }

  render () {
    const { t, showLegalCompanyModal, handleSubmit } = this.props
    const tKey = 'MASTERS.LEGAL_COMPANY_MASTERS.'
    return (
      <Modal show={showLegalCompanyModal} onHide={this.props.actions.closeLegalCompanyModal} className="languages-modal" backdrop={'static'}>
        <Modal.Header closeButton>
          <i className="ico-users" /><Modal.Title>{t(`${tKey}LEGAL_COMPANY_TITLE`)}</Modal.Title>
        </Modal.Header>
        <form autoComplete="off" onSubmit={ handleSubmit((values) => this.submitLegalCompany(values))}>
          <Modal.Body>
            <FormGroup>
              <Row className="inputs-wrapper flexRow">
                <Col sm={4} className="custom-width-col">
                  <h2>{t(`${tKey}CIF`)}</h2>
                  <Field
                    id="cif"
                    name="cif"
                    placeholder={t(`${tKey}CIF`)}
                    component={InputText}
                  />
                </Col>
                <Col sm={8} className="custom-width-col">
                  <h2>{t(`${tKey}DESCRIPTION`)}</h2>
                  <Field
                    id="description"
                    name="description"
                    placeholder={t(`${tKey}DESCRIPTION`)}
                    component={InputText}
                  />
                </Col>
                <Col sm={4} className="custom-width-col">
                  <h2>{t(`${tKey}COMERCIAL_REGISTRY`)}</h2>
                  <Field
                    id="commercialRegistry"
                    name="commercialRegistry"
                    placeholder={t(`${tKey}COMERCIAL_REGISTRY`)}
                    component={InputText}
                  />
                </Col>
                <Col sm={4} className="custom-width-col">
                  <h2>{t(`${tKey}BRAND`)}</h2>
                  <Field
                    id="brand"
                    name="brand"
                    placeholder={t(`${tKey}BRAND`)}
                    component={InputText}
                  />
                </Col>
                <Col sm={4} className="custom-width-col">
                  <h2>{t(`${tKey}COUNTRY`)}</h2>
                  <Field
                    id="country"
                    name="country"
                    placeholder={t(`${tKey}COUNTRY`)}
                    component={InputText}
                  />
                </Col>
                <Col sm={4} className="custom-width-col">
                  <h2>{t(`${tKey}STATE`)}</h2>
                  <Field
                    id="state"
                    name="state"
                    placeholder={t(`${tKey}STATE`)}
                    component={InputText}
                  />
                </Col>
                <Col sm={4} className="custom-width-col">
                  <h2>{t(`${tKey}CITY`)}</h2>
                  <Field
                    id="city"
                    name="city"
                    placeholder={t(`${tKey}CITY`)}
                    component={InputText}
                  />
                </Col>
                <Col sm={4} className="custom-width-col">
                  <h2>{t(`${tKey}CP`)}</h2>
                  <Field
                    id="cp"
                    name="cp"
                    placeholder={t(`${tKey}CP`)}
                    component={InputText}
                  />
                </Col>
                <Col sm={6} className="custom-width-col">
                  <h2>{t(`${tKey}ADDRESS`)}</h2>
                  <Field
                    id="address"
                    name="address"
                    placeholder={t(`${tKey}ADDRESS`)}
                    component={InputText}
                  />
                </Col>
                <Col sm={6} className="custom-width-col">
                  <h2>{t(`${tKey}ADDRESSES`)}</h2>
                  <Field
                    id="addresses"
                    name="addresses"
                    placeholder={t(`${tKey}ADDRESSES`)}
                    component={InputText}
                  />
                </Col>
                <Col sm={4} className="custom-width-col">
                  <h2>{t(`${tKey}ACCOUNTS`)}</h2>
                  <Field
                    id="accounts"
                    name="accounts"
                    placeholder={t(`${tKey}ACCOUNTS`)}
                    component={InputText}
                  />
                </Col>
                <Col sm={4} className="custom-width-col">
                  <h2>{t(`${tKey}REPRESENTATIVE_NAME`)}</h2>
                  <Field
                    id="representativeName"
                    name="representativeName"
                    placeholder={t(`${tKey}REPRESENTATIVE_NAME`)}
                    component={InputText}
                  />
                </Col>
                <Col sm={4} className="custom-width-col">
                  <h2>{t(`${tKey}REPRESENTATIVE_DNI`)}</h2>
                  <Field
                    id="representativeDNI"
                    name="representativeDNI"
                    placeholder={t(`${tKey}REPRESENTATIVE_DNI`)}
                    component={InputText}
                  />
                </Col>
              </Row>
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <Col sm={6} className="btn-wrapper" smPush={6}>
              <Button type="submit" className="btn-standard" disabled={this.props.pristine}>
                {t('MASTERS.SAVE')}
              </Button>
            </Col>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

const validate = (values, props) => {
  const errors = {}
  if (values) {
    const tKey = 'MASTERS.LEGAL_COMPANY_MASTERS.VALIDATION.'
    if (!values.cif) {
      errors.cif = props.t(`${tKey}CIF`)
    }
    if (!values.description || values.description.length > 100) {
      errors.description = props.t(`${tKey}DESCRIPTION`)
    }
    if (!values.commercialRegistry) {
      errors.commercialRegistry = props.t(`${tKey}COMERCIAL_REGISTRY`)
    }
    if (!values.brand) {
      errors.brand = props.t(`${tKey}BRAND`)
    }
    if (!values.country) {
      errors.country = props.t(`${tKey}COUNTRY`)
    }
    if (!values.city) {
      errors.city = props.t(`${tKey}CITY`)
    }
    if (!values.state) {
      errors.state = props.t(`${tKey}STATE`)
    }
    if (!values.cp) {
      errors.cp = props.t(`${tKey}CP`)
    }
  }

  return errors
}

export default reduxForm({
  form: 'editLegalCompany',
  validate
})(LegalCompanyModal)
