import React, { PureComponent } from 'react'
import { Field } from 'redux-form'
import { Panel, Row, Col, Button } from 'react-bootstrap'
import InputText from '../../commons/form/InputText'
import InputNatural from '../../commons/form/InputNatural'
import InputSelect from '../../commons/form/InputSelect'
import InputDatePicker from '../../commons/form/InputDatePicker'
import InputTimePicker from '../../commons/form/InputTimePicker'
import InputCheckBox from '../../commons/form/InputCheckBox'
import { dossierSubTypeId as dossierSubTypeIdConstants } from '../../../constants/dossier/common/dossierSubType'
import { validateDate } from '../../../util/validationFunctions'
import settings from '../../../setting'
import { getFieldsPermisions } from '../../../util/permisionFunctions'
import { permissions, saleTypes } from '../../../constants/backendIds'
import { compareObjects } from '../../../util/utils'
import { purchaseTypesVo } from '../../../constants/dossier/purchase/purchaseTypes'
import CustomFields from '../../commons/customFields/CustomFields'
import { dossierStagesBySubtype } from '../../../constants/dossier/common/dossierStages'

class PurchaseComponent extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      fieldsConfiguration: {
        doubleTransferCheck: null,
        purchaseTypeId: null,
        dealerNumber: null,
        makerNumber: null,
        realDeliveryAddress: null,
        date: null,
        hour: null,
        lastITVDate: null,
        Km: null,
        availabilityDate: null,
        vehicleLocation: null,
        deliveryCity: null,
        isPartOfPayment: null,
        cancelDomainReservationNotRequired: null,
        notTrafficReport: null,
        validImc: null,
        stockStatusId: null,
        purchaseSource: null,
        responsableName: null,
        uacDestinationId: null,
        purchaseSaleEntityId: null,
        billingNumber: null,
        billingDate: null,
        purchaseOrderTypeId: null,
        orderDate: null,
        purchaseState: null,
        checkNotContractPurchaseVehicle: null,
        notPurchaseSelfBilling: null,
        salesmanIdLock: null,
        additionalInvoice: null,
        rescueLeasing: null,
        termicWindow: null,
        requestVT: null
      },
      showPurchaseContract: false,
      rescueLeasing: false,
      additionalInvoiceDisabled: true,
      puscharseSaleEntityVisible: false
    }
    this.dealerNumberValidate = this.dealerNumberValidate.bind(this)
    this.handleChangeComboPurchase = this.handleChangeComboPurchase.bind(this)
  }

  initializePermisions (props) {
    const fieldKeys = Object.keys(this.state.fieldsConfiguration)
    const newState = getFieldsPermisions(props.componentConfiguration.fieldsConfiguration, fieldKeys, props.componentConfiguration.permission)
    this.setState({ fieldsConfiguration: newState.fieldsConfiguration })
  }

  componentDidMount () {
    this.initializePermisions(this.props)
    if (this.props.purchaseTypeId && this.props.purchaseTypeId === purchaseTypesVo.scrapping) {
      this.setState({ showPurchaseContract: true })
    }
    if (this.props.dossierSubType === dossierSubTypeIdConstants.compra_vo) {
      this.setState({ additionalInvoiceDisabled: !this.props.dossier?.dossierPurchaseComponent?.rescueLeasing })
    }
    this.setState({ puscharseSaleEntityVisible: this.props?.dossier?.dossierPurchaseComponent?.uacDestinationId > 1 })
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (prevProps.open) {
      this.setState({ open: true })
    }

    if (this.props.dossierId && !compareObjects(prevProps.componentConfiguration || {}, this.props.componentConfiguration, true)) {
      this.initializePermisions(this.props)
    }

    return null
  }

  componentDidUpdate () {
  }

  dateValidation (value, allValues, props) {
    const result = validateDate(value, props)
    return result === Object(result) ? undefined : result
  }

  dealerNumberValidate (value) {
    return (value && value !== '' && settings.dealerNumberRegex) ? (value.match(settings.dealerNumberRegex) ? undefined : this.props.t('GLOBAL.FORMS.INVALID_STRING')) : undefined
  }

  checkPurchaseType (value) {
    if (value === purchaseTypesVo.scrapping) {
      this.setState({ showPurchaseContract: true })
    } else {
      this.setState({ showPurchaseContract: false })
    }
  }

  handleChangeComboPurchase (value) {
    if (value !== null && this.props.combos.purchaseTypeCombo.find(x => x.id === value).isNotSaleable && this.props.dossier.dossierPurchaseComponent.saleStageId) {
      var cancelAction = () => { this.props.change('dossierPurchaseComponent.purchaseTypeId', this.props.dossier.dossierPurchaseComponent.purchaseTypeId) }
      var okAction = () => {
        this.props.actions.checkPurchaseTypeAndSetDefaultEntity(value, this.props.organizedUnitId)
        this.checkPurchaseType(value)
      }

      if (this.props.dossier.dossierPurchaseComponent.saleStageId > (this.props.dossierSubType === dossierSubTypeIdConstants.compra_vo ? dossierStagesBySubtype.saleVo.documentation : dossierStagesBySubtype.saleVn.documentation)) {
        this.props.actions.openCommonModal('notChangePurchaseTypeNotSaleable', null, cancelAction, null, null, null, null)
      } else {
        this.props.actions.openCommonModal('changePurchaseTypeNotSaleable', okAction, cancelAction, null, null, null, null)
      }
    } else {
      this.props.actions.checkPurchaseTypeAndSetDefaultEntity(value, this.props.organizedUnitId)
      this.checkPurchaseType(value)
    }
  }

  changeBoolRescueLeasing (value) {
    this.setState({ additionalInvoiceDisabled: !value })
    if (value === false) {
      this.props.change('dossierPurchaseComponent.additionalInvoice', false)
    }
  }

  changeBoolAdditionalInvoice (value) {
    this.setState({ additionalInvoice: value })
  }

  uacDestinationOnChange (val) {
    this.setState({ puscharseSaleEntityVisible: (val !== saleTypes.PRIVATE && val !== null) })
  }

  render () {
    const {
      t, purchaseOpen, readOnlyFields, showNewVehicleFields, customFields, dossierSubType, dossierId, printDeliveryChecklistPermission, hasParent,
      combos: { purchaseTypeCombo, stockStatusCombo, saleTypeAllCombo, purchaseOrderTypeCombo, salesmanCombo, puscharseSaleEntitys },
      actions: { printDeliveryChecklist, synchroniseAvailabilityDate, togglePanel }
    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.'

    const termicWindowOptions = [{ id: true, value: t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.YES`) },
      { id: false, value: t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.NO`) }]

    return (
      <div className="paperwork-panel dossier-panel">
        <div className="header-panel" onClick={togglePanel}>
          <div className="name-wrapper">
            <i className="ico-car-files"></i>
            <h4>{t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.TITLE`)}</h4>
          </div>
          <i className={purchaseOpen ? 'ico-arrow-black-up' : 'ico-arrow-black-down'} />
        </div>
        <Panel expanded={purchaseOpen} onToggle={() => {}}>
          <Panel.Collapse>
            <Row>
              {this.state.fieldsConfiguration.doubleTransferCheck &&
                this.state.fieldsConfiguration.doubleTransferCheck.permission !== permissions.hidden &&
                (<Field
                  colSm={4}
                  id="doubleTransferCheck"
                  name="dossierPurchaseComponent.doubleTransferCheck"
                  controlLabel={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.DOUBLE_TRANSFER`)}
                  placeholder={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.DOUBLE_TRANSFER`)}
                  valueKey="id"
                  labelKey="value"
                  component={InputCheckBox}
                  disabled={readOnlyFields || this.state.fieldsConfiguration.doubleTransferCheck.permission !== permissions.editable}
                />)}
            </Row>
            <Row className='flexRow'>
              {this.state.fieldsConfiguration.salesmanIdLock && this.state.fieldsConfiguration.salesmanIdLock.permission !== permissions.hidden && (
                <Field
                  colSm={4}
                  id="salesmanId"
                  name="dossierPurchaseComponent.salesmanId"
                  controlLabel={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.SALESMAN`)}
                  placeholder={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.SALESMAN`)}
                  valueKey="id"
                  labelKey="value"
                  component={InputSelect}
                  options={salesmanCombo}
                  disabled={readOnlyFields || (this.state.fieldsConfiguration.salesmanIdLock && this.state.fieldsConfiguration.salesmanIdLock.permission !== permissions.editable)}
                />
              )}
              {this.state.fieldsConfiguration.purchaseTypeId && this.state.fieldsConfiguration.purchaseTypeId.permission !== permissions.hidden && (<Field
                colSm={4}
                id="purchaseTypeId"
                name="dossierPurchaseComponent.purchaseTypeId"
                controlLabel={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.PURCHASE_TYPE`)}
                placeholder={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.PURCHASE_TYPE`)}
                valueKey="id"
                labelKey="value"
                component={InputSelect}
                options={purchaseTypeCombo}
                disabled={readOnlyFields || this.state.fieldsConfiguration.purchaseTypeId.permission !== permissions.editable}
                // onInputChange={(value) => this.props.actions.checkPurchaseTypeAndSetDefaultEntity(value, this.props.organizedUnitId) && this.checkPurchaseType(value)}
                onInputChange={(value) => this.handleChangeComboPurchase(value)}

              />)}
              {this.state.fieldsConfiguration.dealerNumber && this.state.fieldsConfiguration.dealerNumber.permission !== permissions.hidden && (<Field
                colSm={4}
                id="dealerNumber"
                name="dossierPurchaseComponent.dealerNumber"
                controlLabel={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.DEALER_NUMBER`)}
                placeholder={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.DEALER_NUMBER`)}
                component={InputText}
                maxLength={settings.dealerMaxLength}
                validate={[this.dealerNumberValidate]}
                disabled={readOnlyFields || this.state.fieldsConfiguration.dealerNumber.permission !== permissions.editable}
              />)}
              {
                showNewVehicleFields
                  ? this.state.fieldsConfiguration.makerNumber && this.state.fieldsConfiguration.makerNumber.permission !== permissions.hidden && (<Field
                    colSm={4}
                    id="purchaseMakerNumber"
                    name="dossierPurchaseComponent.makerNumber"
                    controlLabel={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.MAKER_NUMBER`)}
                    placeholder={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.MAKER_NUMBER`)}
                    component={InputText}
                    disabled={readOnlyFields || this.state.fieldsConfiguration.makerNumber.permission !== permissions.editable}
                  />) : null
              }
              {dossierSubType !== dossierSubTypeIdConstants.compra_vn &&
              this.state.fieldsConfiguration.vehicleLocation && this.state.fieldsConfiguration.vehicleLocation.permission !== permissions.hidden && (<Field
                colSm={4}
                id="vehicleLocation"
                name="dossierPurchaseComponent.vehicleLocation"
                controlLabel={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.VEHICLE_LOCATION`)}
                placeholder={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.VEHICLE_LOCATION`)}
                component={InputText}
                disabled={readOnlyFields || this.state.fieldsConfiguration.vehicleLocation.permission !== permissions.editable}
              />
              )}
              {this.state.fieldsConfiguration.realDeliveryAddress && this.state.fieldsConfiguration.realDeliveryAddress.permission !== permissions.hidden && (<Field
                colSm={4}
                id="realDeliveryAddress"
                name="dossierCompleteDeliveryComponent.dossierDeliveryComponent.realDeliveryAddress"
                controlLabel={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.ADDRESS`)}
                placeholder={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.ADDRESS`)}
                component={InputText}
                disabled={readOnlyFields || this.state.fieldsConfiguration.realDeliveryAddress.permission !== permissions.editable}
              />)}
              {this.state.fieldsConfiguration.date && this.state.fieldsConfiguration.date.permission !== permissions.hidden && (<Field
                colSm={4}
                id="realDeliveryDate"
                name="dossierCompleteDeliveryComponent.dossierDeliveryComponent.realDeliveryDate"
                controlLabel={t(`${tKey}DELIVERY.DATE_RECIVE`)}
                component={InputDatePicker}
                validate={[this.dateValidation]}
                disabled={readOnlyFields || this.state.fieldsConfiguration.date.permission !== permissions.editable}
              />)}
              {this.state.fieldsConfiguration.hour && this.state.fieldsConfiguration.hour.permission !== permissions.hidden && (<Field
                colSm={4}
                id="realDeliveryTime"
                name="dossierCompleteDeliveryComponent.dossierDeliveryComponent.realDeliveryTime"
                controlLabel={t(`${tKey}DELIVERY.HOUR_RECIVE`)}
                placeholder={t(`${tKey}DELIVERY.HOUR_RECIVE`)}
                component={InputTimePicker}
                disabled={readOnlyFields || this.state.fieldsConfiguration.hour.permission !== permissions.editable}
              />)}
              {dossierSubType !== dossierSubTypeIdConstants.compra_vn &&
              this.state.fieldsConfiguration.lastITVDate && this.state.fieldsConfiguration.lastITVDate.permission !== permissions.hidden && (<Field
                colSm={4}
                id="lastITVDate"
                key="dossierCompleteDeliveryComponent.dossierDeliveryComponent.lastITVDate"
                name="dossierCompleteDeliveryComponent.dossierDeliveryComponent.lastITVDate"
                controlLabel={t(`${tKey}DELIVERY.LAST_ITV_DATE`)}
                component={InputDatePicker}
                disabled={readOnlyFields || this.state.fieldsConfiguration.lastITVDate.permission !== permissions.editable}
                validate={[this.dateValidation]}
              />
              )}
              {dossierSubType !== dossierSubTypeIdConstants.compra_vn &&
              this.state.fieldsConfiguration.Km && this.state.fieldsConfiguration.Km.permission !== permissions.hidden && (<Field
                colSm={4}
                id="Km"
                key="dossierCompleteDeliveryComponent.dossierDeliveryComponent.km"
                name="dossierCompleteDeliveryComponent.dossierDeliveryComponent.km"
                controlLabel={t(`${tKey}DELIVERY.KM`)}
                placeholder={t(`${tKey}DELIVERY.KM`)}
                textAlign="left"
                component={InputNatural}
                disabled={readOnlyFields || this.state.fieldsConfiguration.Km.permission !== permissions.editable}
              />)}
              {this.state.fieldsConfiguration.availabilityDate && this.state.fieldsConfiguration.availabilityDate.permission !== permissions.hidden && (<Field
                colSm={4}
                id="availabilityDate"
                name="dossierPurchaseComponent.availabilityDate"
                controlLabel={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.AVAILABILITY_DATE`)}
                component={InputDatePicker}
                onInputChange={synchroniseAvailabilityDate}
                disabled={readOnlyFields || this.state.fieldsConfiguration.availabilityDate.permission !== permissions.editable}
                validate={[this.dateValidation]}
              />)}
              {this.state.fieldsConfiguration.orderDate && this.state.fieldsConfiguration.orderDate.permission !== permissions.hidden && (<Field
                colSm={4}
                id="orderDate"
                name="dossierPurchaseComponent.orderDate"
                controlLabel={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.ORDER_DATE`)}
                component={InputDatePicker}
                onInputChange={synchroniseAvailabilityDate}
                validate={[this.dateValidation]}
                disabled={readOnlyFields || this.state.fieldsConfiguration.orderDate.permission !== permissions.editable}
              />)}
              {this.state.fieldsConfiguration.purchaseOrderTypeId && this.state.fieldsConfiguration.purchaseOrderTypeId.permission !== permissions.hidden && (<Field
                colSm={4}
                id="purchaseOrderTypeId"
                name="dossierPurchaseComponent.purchaseOrderTypeId"
                controlLabel={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.PURCHASE_ORDER_TYPE`)}
                valueKey="id"
                labelKey="value"
                component={InputSelect}
                options={purchaseOrderTypeCombo}
                disabled={readOnlyFields || this.state.fieldsConfiguration.purchaseOrderTypeId.permission !== permissions.editable}
              />)}
              {dossierSubType === dossierSubTypeIdConstants.compra_vn &&
              this.state.fieldsConfiguration.vehicleLocation && this.state.fieldsConfiguration.vehicleLocation.permission !== permissions.hidden && (<Field
                colSm={4}
                id="vehicleLocation"
                name="dossierPurchaseComponent.vehicleLocation"
                controlLabel={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.VEHICLE_LOCATION`)}
                placeholder={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.VEHICLE_LOCATION`)}
                component={InputText}
                disabled={readOnlyFields || this.state.fieldsConfiguration.vehicleLocation.permission !== permissions.editable}
              />
              )}
              {this.state.fieldsConfiguration.deliveryCity && this.state.fieldsConfiguration.deliveryCity.permission !== permissions.hidden && (<Field
                colSm={4}
                id="deliveryCity"
                name="dossierCompleteDeliveryComponent.dossierDeliveryComponent.deliveryCity"
                controlLabel={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.CITY_DELIVERY`)}
                placeholder={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.CITY_DELIVERY`)}
                component={InputText}
                disabled={readOnlyFields || this.state.fieldsConfiguration.deliveryCity.permission !== permissions.editable}
              />)}
              {this.state.fieldsConfiguration.stockStatusId && this.state.fieldsConfiguration.stockStatusId.permission !== permissions.hidden && (<Field
                colSm={4}
                id="stockStatusId"
                name="dossierPurchaseComponent.stockStatusId"
                controlLabel={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.STOCK_STATUS`)}
                placeholder={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.STOCK_STATUS`)}
                valueKey="id"
                labelKey="value"
                component={InputSelect}
                options={stockStatusCombo}
                disabled={readOnlyFields || this.state.fieldsConfiguration.stockStatusId.permission !== permissions.editable}
              />)}
              {this.state.fieldsConfiguration.purchaseSource && this.state.fieldsConfiguration.purchaseSource.permission !== permissions.hidden && (<Field
                colSm={4}
                id="purchaseSource"
                name="dossierPurchaseComponent.purchaseSource"
                controlLabel={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.PURCHASE_SOURCE`)}
                placeholder={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.PURCHASE_SOURCE`)}
                valueKey="id"
                labelKey="value"
                component={InputText}
                disabled={readOnlyFields || this.state.fieldsConfiguration.purchaseSource.permission !== permissions.editable}
              />)}
              {this.state.fieldsConfiguration.responsableName && this.state.fieldsConfiguration.responsableName.permission !== permissions.hidden && (<Field
                colSm={4}
                id="responsableName"
                name="dossierPurchaseComponent.responsableName"
                controlLabel={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.RESPONSABLE`)}
                placeholder={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.RESPONSABLE`)}
                component={InputText}
                disabled={true}
              />)}
              {this.state.fieldsConfiguration.termicWindow && this.state.fieldsConfiguration.termicWindow.permission !== permissions.hidden && (
                <Field
                  colSm={4}
                  id="termicWindow"
                  component={InputSelect}
                  name="dossierPurchaseComponent.termicWindow"
                  controlLabel={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.TERMIC_WINDOW`)}
                  placeholder={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.TERMIC_WINDOW`)}
                  options={termicWindowOptions}
                  valueKey="id"
                  labelKey="value"
                  disabled={readOnlyFields || this.state.fieldsConfiguration.termicWindow.permission !== permissions.editable}
                />
              )}
              {this.state.fieldsConfiguration.purchaseState && this.state.fieldsConfiguration.purchaseState.permission !== permissions.hidden && (<Field
                colSm={4}
                id="purchaseState"
                name="dossierPurchaseComponent.purchaseState"
                controlLabel={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.PURCHASE_STATE`)}
                placeholder={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.PURCHASE_STATE`)}
                component={InputText}
                disabled={true}
              />)}
              {this.state.fieldsConfiguration.uacDestinationId && this.state.fieldsConfiguration.uacDestinationId.permission !== permissions.hidden && (<Field
                colSm={4}
                id="uacDestinationId"
                name="dossierPurchaseComponent.uacDestinationId"
                controlLabel={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.DESTINATION`)}
                placeholder={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.DESTINATION`)}
                valueKey="id"
                labelKey="value"
                onChange={(val) => this.uacDestinationOnChange(val)}
                component={InputSelect}
                options={saleTypeAllCombo && saleTypeAllCombo.filter(x => [saleTypes.PRIVATE, saleTypes.SALE, saleTypes.SALE_CREDIT].includes(x.id))}
                disabled={readOnlyFields || this.state.fieldsConfiguration.purchaseSource.permission !== permissions.editable}
              />)}
              {this.state.puscharseSaleEntityVisible && this.state.fieldsConfiguration.purchaseSaleEntityId && this.state.fieldsConfiguration.purchaseSaleEntityId.permission !== permissions.hidden && (<Field
                colSm={4}
                id="purchaseSaleEntityId"
                name="dossierPurchaseComponent.purchaseSaleEntityId"
                controlLabel={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.BUYING_SELLING_ENTITY`)}
                placeholder={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.BUYING_SELLING_ENTITY`)}
                valueKey="id"
                labelKey="value"
                component={InputSelect}
                options={puscharseSaleEntitys }
                disabled={readOnlyFields || this.state.fieldsConfiguration.purchaseSource.permission !== permissions.editable}
              />)}
            </Row>
            <Row>
              <Col>
                {hasParent && this.state.fieldsConfiguration.isPartOfPayment && this.state.fieldsConfiguration.isPartOfPayment.permission !== permissions.hidden && (<Field
                  colSm={4}
                  id="isPartOfPayment"
                  name="dossierPurchaseComponent.isPartOfPayment"
                  placeholder={t('DOSSIER_COMPONENTS.UAC.MODAL.VEHICLE.IS_PART_OF_PAYMENT')}
                  inline
                  component={InputCheckBox}
                  disabled={readOnlyFields || this.state.fieldsConfiguration.isPartOfPayment.permission !== permissions.editable}
                />)}
                {dossierSubType === dossierSubTypeIdConstants.compra_vo && hasParent &&
                this.state.fieldsConfiguration.cancelDomainReservationNotRequired && this.state.fieldsConfiguration.cancelDomainReservationNotRequired.permission !== permissions.hidden && (<Field
                  colSm={4}
                  id="cancelDomainReservationNotRequired"
                  name="dossierPurchaseComponent.cancelDomainReservationNotRequired"
                  controlLabel={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.CANCEL_DOSSIER_RESERVATION_NOT_REQUIRED`)}
                  placeholder={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.CANCEL_DOSSIER_RESERVATION_NOT_REQUIRED`)}
                  inline
                  component={InputCheckBox}
                  disabled={readOnlyFields || this.state.fieldsConfiguration.cancelDomainReservationNotRequired.permission !== permissions.editable}
                />
                )}
                {this.state.fieldsConfiguration.rescueLeasing && this.state.fieldsConfiguration.rescueLeasing.permission !== permissions.hidden && (<Field
                  colSm={4}
                  id="rescueLeasing"
                  name="dossierPurchaseComponent.rescueLeasing"
                  placeholder={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.RESCUE_LEASING`)}
                  onInputChange={value => this.changeBoolRescueLeasing(value)}
                  inline
                  component={InputCheckBox}
                  disabled={readOnlyFields || this.state.fieldsConfiguration.rescueLeasing.permission !== permissions.editable}
                />)}
                {this.state.fieldsConfiguration.additionalInvoice && this.state.fieldsConfiguration.additionalInvoice.permission !== permissions.hidden && (<Field
                  colSm={4}
                  id="additionalInvoice"
                  name="dossierPurchaseComponent.additionalInvoice"
                  placeholder={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.ADDITIONAL_INVOICE`)}
                  onInputChange={value => this.changeBoolAdditionalInvoice(value)}
                  inline
                  component={InputCheckBox}
                  disabled={readOnlyFields || this.state.fieldsConfiguration.additionalInvoice.permission !== permissions.editable || this.state.additionalInvoiceDisabled}
                />)}
                {this.state.fieldsConfiguration.notTrafficReport && this.state.fieldsConfiguration.notTrafficReport.permission !== permissions.hidden && (<Field
                  colSm={4}
                  id="notTrafficReport"
                  name="dossierPurchaseComponent.notTrafficReport"
                  placeholder={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.NOT_TRAFFIC_REPORT`)}
                  inline
                  component={InputCheckBox}
                  disabled={readOnlyFields || this.state.fieldsConfiguration.notTrafficReport.permission !== permissions.editable}
                />)}
                {this.state.fieldsConfiguration.validImc && this.state.fieldsConfiguration.validImc.permission !== permissions.hidden && (<Field
                  colSm={4}
                  id="validImc"
                  name="dossierPurchaseComponent.validImc"
                  placeholder={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.NOT_VALID_IMC`)}
                  inline
                  component={InputCheckBox}
                  disabled={readOnlyFields || this.state.fieldsConfiguration.validImc.permission !== permissions.editable}
                />)}
                {this.state.fieldsConfiguration.checkNotContractPurchaseVehicle && this.state.fieldsConfiguration.checkNotContractPurchaseVehicle.permission !== permissions.hidden &&
                  dossierSubType === dossierSubTypeIdConstants.compra_vo && this.state.showPurchaseContract && (<Field
                  colSm={4}
                  id="notContractPurchaseVehicle"
                  name="dossierPurchaseComponent.notContractPurchaseVehicle"
                  placeholder={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.NOT_CONTRACT_PURCHASE_VEHICLE`)}
                  inline
                  component={InputCheckBox}
                  disabled={readOnlyFields || this.state.fieldsConfiguration.checkNotContractPurchaseVehicle.permission === permissions.readOnly}
                />)}
                {this.state.fieldsConfiguration.notPurchaseSelfBilling && this.state.fieldsConfiguration.notPurchaseSelfBilling.permission !== permissions.hidden &&
                  dossierSubType === dossierSubTypeIdConstants.compra_vo && (<Field
                  colSm={4}
                  id="notPurchaseSelfBilling"
                  name="dossierPurchaseComponent.notPurchaseSelfBilling"
                  placeholder={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.NOT_PURCHASE_SELF_BILLING`)}
                  inline
                  component={InputCheckBox}
                  disabled={readOnlyFields || this.state.fieldsConfiguration.notPurchaseSelfBilling.permission === permissions.readOnly}
                />)}
                {this.state.fieldsConfiguration.requestVT && this.state.fieldsConfiguration.requestVT.permission !== permissions.hidden && (
                  <Field
                    colSm={4}
                    id="requestVT"
                    name="dossierPurchaseComponent.requestVT"
                    placeholder={t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.REQUEST_VT`)}
                    inline
                    component={InputCheckBox}
                    disabled={true}
                  />
                )}
              </Col>
            </Row>
            <CustomFields customFields={customFields} readOnlyFields={readOnlyFields} />
            <Row>
              <Col>
                {
                  (dossierSubType !== dossierSubTypeIdConstants.compra_vn && printDeliveryChecklistPermission) ? (
                    <Col sm={4}>
                      <Button className="btn-standard button-imprimir-albaran"
                        style={{ width: '100%', margin: '41px 0px 0px 0px', whiteSpace: 'initial' }}
                        onClick={() => printDeliveryChecklist(dossierId, false)}>
                        <i className="ico-print"/>
                        <span>{t(`${tKey}PURCHASE_DOSSIER.PURCHASE_COMPONENT.PRINT`)}</span>
                      </Button>
                    </Col>
                  ) : null
                }
              </Col>
            </Row>
          </Panel.Collapse>
        </Panel>
      </div>
    )
  }
}

export default PurchaseComponent
