import React, { PureComponent } from 'react'
import { Row, Col } from 'react-bootstrap'

class MainClientManagemente extends PureComponent {
  render () {
    return (
      <Col sm={12}>
        <Row>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Nº identificador:</span>
              <span className="second">Nº identificador</span>
            </div>
          </Col>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Tipo entidad:</span>
              <span className="second">Tipo entidad</span>
            </div>
          </Col>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">CIF/NIF:</span>
              <span className="second">CIF/NIF</span>
            </div>
          </Col>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Nombre/razón social cliente:</span>
              <span className="second">Nombre, Apellido1, Apellido2</span>
            </div>
          </Col>
        </Row>
        <Row>
        </Row>
        <Row>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Nombre/razón social cliente:</span>
              <span className="second">Nombre, Apellido1, Apellido2</span>
            </div>
          </Col>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Segmentación:</span>
              <span className="second">Segmentación</span>
            </div>
          </Col>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Forma contacto:</span>
              <span className="second">Forma contacto</span>
            </div>
          </Col>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Horario contacto:</span>
              <span className="second">Horario contacto</span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Domicilio cliente:</span>
              <span className="second">Domicilio</span>
              <span className="second">Domicilio</span>
              <span className="second">Domicilio</span>
            </div>
          </Col>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Teléfono:</span>
              <span className="second">Teléfono</span>
            </div>
          </Col>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Teléfono móvil:</span>
              <span className="second">Teléfono móvil</span>
            </div>
          </Col>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Email:</span>
              <span className="second">Email</span>
            </div>
          </Col>
        </Row>
      </Col>
    )
  }
}

export default MainClientManagemente
