import mastersActionTypes from '../../../../constants/actions/masters/masters'

export function fetchProcessingAgencyRegistrationTypesList (processingAgencyId, filter) {
  return {
    type: mastersActionTypes.FETCH_PROCESSING_AGENCY_REGISTRATION_TYPES_LIST,
    filter,
    processingAgencyId
  }
}

export function fetchProcessingAgencyRegistrationTypesListSuccess (processingAgencyRegistrationTypesList, filter) {
  return {
    type: mastersActionTypes.FETCH_PROCESSING_AGENCY_REGISTRATION_TYPES_LIST_SUCCESS,
    processingAgencyRegistrationTypesList,
    filter
  }
}

export function showProcessingAgencyRegistrationTypesModal (processingAgencyId, show, processingAgencyRegistrationType) {
  return {
    type: mastersActionTypes.SHOW_PROCESSING_AGENCY_REGISTRATION_TYPES_MODAL,
    show,
    processingAgencyRegistrationType,
    processingAgencyId
  }
}

export function showProcessingAgencyRegistrationTypesModalSuccess (processingAgencyId, show, processingAgencyRegistrationType) {
  return {
    type: mastersActionTypes.SHOW_PROCESSING_AGENCY_REGISTRATION_TYPES_MODAL_SUCCESS,
    show,
    processingAgencyRegistrationType,
    processingAgencyId
  }
}

export function submitProcessingAgencyRegistrationTypes (processingAgencyId, processingAgencyRegistrationType) {
  return {
    type: mastersActionTypes.SUBMIT_PROCESSING_AGENCY_REGISTRATION_TYPES,
    processingAgencyRegistrationType,
    processingAgencyId
  }
}

export function deleteProcessingAgencyRegistrationTypes (processingAgencyId, processingAgencyRegistrationTypesId) {
  return {
    type: mastersActionTypes.DELETE_PROCESSING_AGENCY_REGISTRATION_TYPES,
    processingAgencyRegistrationTypesId,
    processingAgencyId
  }
}
