import dossierActionTypes from '../../constants/actions/dossier/dossier'
import { refreshAuditInfo, fetchAuditInfoSucces, fetchAuditDetails, fetchAuditDetailsModal, fetchDocumentsGroupedAuditDetails, fetchDocumentsGroupedAuditDetailsModal } from '../../actions/audit/auditInfo'

export function fetchServiceDossier (dossierId, resolve, reject, historical) {
  return {
    type: dossierActionTypes.FETCH_SERVICE_DOSSIER,
    dossierId,
    resolve,
    reject,
    historical
  }
}

export function fetchServiceDossierSuccess (dossier, checkListHeader) {
  return {
    type: dossierActionTypes.FETCH_SERVICE_DOSSIER_SUCCESS,
    dossier,
    checkListHeader
  }
}

export function restartServiceDossierState () {
  return {
    type: dossierActionTypes.RESTART_SERVICE_DOSSIER_STATE
  }
}

export function saveServiceDossier (serviceDossier) {
  return {
    type: dossierActionTypes.SAVE_SERVICE_DOSSIER,
    serviceDossier
  }
}

export function saveIssueService (issue) {
  return {
    type: dossierActionTypes.SAVE_ISSUE_SERVICE,
    issue
  }
}

export function openOrCloseIssueModal (openClose) {
  return {
    type: dossierActionTypes.OPEN_OR_CLOSE_ISSUE_MODAL,
    openClose
  }
}

export function serviceApproveByRole (pristine, approvalRoleCode) {
  return {
    type: dossierActionTypes.APPROVE_SERVICE_DOSSIER,
    pristine,
    approvalRoleCode
  }
}

export function serviceDossierWarningSave (reset, router, nextLocation, pristine) {
  return {
    type: dossierActionTypes.SERVICE_DOSSIER_WARNING_SAVE,
    reset,
    router,
    nextLocation,
    pristine
  }
}

export function fetchDossierRelatedDossiersSuccess (dossierRelatedDossiers) {
  return {
    type: dossierActionTypes.FETCH_DOSSIER_RELATED_DOSSIERS_SUCCESS,
    dossierRelatedDossiers
  }
}

export function fetchServiceDossierVehicle (dossierId, resolve, reject, pristine) {
  return {
    type: dossierActionTypes.FETCH_SERVICE_DOSSIER_VEHICLE,
    dossierId,
    resolve,
    reject,
    pristine
  }
}

export function fetchServiceDossierVehicleHistorical (dossierId, resolve, reject) {
  return {
    type: dossierActionTypes.FETCH_SERVICE_DOSSIER_VEHICLE_HISTORICAL,
    dossierId,
    resolve,
    reject
  }
}

export function serviceDossierApprove (dossierId, reset) {
  return {
    type: dossierActionTypes.SERVICE_DOSSIER_APPROVE,
    dossierId,
    reset
  }
}

export function serviceResolveIssue (pristine) {
  return {
    type: dossierActionTypes.RESOLVE_SERVICE_ISSUE,
    pristine
  }
}

export function serviceRequestSpareParts (pristine, dossierId, resolve) {
  return {
    type: dossierActionTypes.REQUEST_SERVICE_SPARE_PARTS,
    pristine,
    dossierId,
    resolve
  }
}

export function serviceCompleteSpareParts (pristine, dossierId, resolve) {
  return {
    type: dossierActionTypes.COMPLETE_SERVICE_SPARE_PARTS,
    pristine,
    dossierId,
    resolve
  }
}

export function serviceCompleteDeliveryPreparation (pristine, dossierId, resolve) {
  return {
    type: dossierActionTypes.COMPLETE_SERVICE_DELIVERY_PREPARATION,
    pristine,
    dossierId,
    resolve
  }
}

export function saveServicesAvailabilityDate (dossierIdArray, availabilityDate, resolve, reject, fields) {
  return {
    type: dossierActionTypes.SAVE_SERVICES_AVAILABILITY_DATE,
    dossierIdArray,
    availabilityDate,
    resolve,
    reject,
    fields
  }
}

export function saveServicesConfirmationLogitics (dossierIdArray, resolve) {
  return {
    type: dossierActionTypes.SAVE_SERVICES_CONFIRMATION_LOGISTICS,
    dossierIdArray,
    resolve
  }
}

export function fetchServiceDefaultDestination (value) {
  return {
    type: dossierActionTypes.FETCH_SERVICE_DEFAULT_DESTINATION,
    value
  }
}

export function openVehicleFinderModal (openClose) {
  return {
    type: dossierActionTypes.OPEN_VEHICLE_FINDER_MODAL,
    openClose
  }
}

export default {
  fetchServiceDossier,
  fetchServiceDossierSuccess,
  restartServiceDossierState,
  saveServiceDossier,
  saveIssueService,
  openOrCloseIssueModal,
  serviceDossierWarningSave,
  fetchDossierRelatedDossiersSuccess,
  refreshAuditInfo,
  fetchAuditInfoSucces,
  fetchAuditDetails,
  fetchAuditDetailsModal,
  fetchServiceDossierVehicle,
  fetchServiceDossierVehicleHistorical,
  serviceDossierApprove,
  serviceApproveByRole,
  serviceResolveIssue,
  serviceRequestSpareParts,
  serviceCompleteSpareParts,
  serviceCompleteDeliveryPreparation,
  saveServicesAvailabilityDate,
  fetchServiceDefaultDestination,
  fetchDocumentsGroupedAuditDetails,
  fetchDocumentsGroupedAuditDetailsModal,
  openVehicleFinderModal
}
