import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import HistoricalFleetModal from '../../components/management/HistoricalFleetModal'
import { translate } from 'react-polyglot'
import {
  showHistoricalFleetModal
} from '../../actions/management/management'
import {
  refreshAuditFleetInfo, auditFleetInfoDownload
} from '../../actions/audit/auditInfo'
export function mapStateToProps (state) {
  return {
    ...state.management.historicalFleetModal
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      showHistoricalFleetModal,
      refreshAuditFleetInfo,
      auditFleetInfoDownload
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(HistoricalFleetModal))
