import React, { PureComponent } from 'react'
import { Modal, Tab, Tabs, Form, Col, Button, Row, Tooltip, OverlayTrigger, Glyphicon } from 'react-bootstrap'
import { reduxForm, Field } from 'redux-form'
import VehiclesListModal from './VehiclesListModal'
import InputText from '../../../commons/form/InputText'
import InputMoney from '../../../commons/form/InputMoney'
import InputNatural from '../../../commons/form/InputNatural'
import InputDatePicker from '../../../commons/form/InputDatePicker'
import InputCheckBox from '../../../commons/form/InputCheckBox'
import InputSelect from '../../../commons/form/InputSelect'
import { entityTypesId, permissions, clientInformation, validationTypes } from '../../../../constants/backendIds'
import { validateDate, validateEntity, validateVinChassis, validateLicensePlate as validateLicensePlateFunciton } from '../../../../util/validationFunctions'
import { multipleNormalize, toUpperCase, trimAll } from '../../../../util/normalizeFunctions'
import settings from '../../../../setting'
import { getFieldsPermisions } from '../../../../util/permisionFunctions'
import UACAppraisalWithTAXModal from './UACAppraisalWithTAXModal'
import { previousUsagesCode } from '../../../../constants/dossier/purchase/previousUsages'
import { purchaseTypesVo } from '../../../../constants/dossier/purchase/purchaseTypes'
import InputNumber from '../../../commons/form/InputNumber'
import { modelLength } from '../../../../constants/dossier/common/vehicle'
import commonRegExp from '../../../../util/commonRegExp'
import {
  asyncValidateEntityDni, getFieldsValidated
} from '../../../../util/validationFunctionEntityDniAsync'
import { validationUACModalPromisesOrder } from '../../../../constants/validatedFields/backValidationPromisesOrder'
import promisesStatus from '../../../../constants/promisesStatus'
import { dossierTypes as dossierTypesConstant } from '../../../../constants/dossier/common/dossierTypes'
import ClientData from '../clientData/ClientData'

class UACModal extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      entityFromParentDossier: true,
      showVehiclesListModal: false,
      vehiclesList: [],
      entityTypeId: null,
      changedBeforeSelectVehicle: false,
      showModalwithTAX: false,
      valueIva: null,
      allInformationDisabled: true,
      entityComponent: null,
      entitySubtypeId: null,
      dniDisabled: true,
      disabledValidateLicensePlate: false,
      showPurchaseContract: false,
      fieldsConfiguration: {
        uacDealerNumber: {
          permission: null
        },
        stockStatusId: {
          permission: null
        },
        expirationItvDate: {
          permission: null
        },
        checkNotContractPurchaseVehicle: {
          permission: null
        },
        rescueLeasing: {
          permission: null
        },
        additionalInvoice: {
          permission: null
        },
        entityFromParentDossier: null,
        entityTypeId: null,
        entitySubTypeId: null,
        entityId: null,
        dniCif: null,
        clientNumber: null,
        name: null,
        surname1: null,
        surname2: null,
        representativeDni: null,
        representativeName: null,
        representativeSurname1: null,
        representativeSurname2: null,
        address: null,
        city: null,
        state: null,
        cp: null,
        country: null,
        phone: null,
        mobilePhone: null,
        email: null,
        sourceChannelId: null,
        segmentationId: null,
        parentEntityId: null,
        iaeTypeId: null,
        dniCifValidationDisable: null,
        representativeDniCifValidationDisable: null,
        rfc: null,
        brandReference: null,
        unitReferenceNumber: null,
        comercialCode: null,
        modelUacNumeric: null,
        modelUac: null,
        freeLance: null,
        maritalcmb: null,
        startStreet: null,
        enndStreet: null,
        internalNumber: null,
        externalNumber: null,
        countryCode: null,
        stateCode: null,
        townCode: null,
        cityCode: null,
        colonyCode: null,
        cpCode: null,
        commercialSocietyTypeId: null,
        activityBproId: null,
        birthdayDate: null,
        constitutionAct: null,
        constitutionDate: null
      },
      rfcNoEditable: true,
      additionalInvoice: false,
      rescueLeasing: false,
      additionalInvoiceDisabled: true,
      freeLance: false
    }
    this.validateLicensePlate = this.validateLicensePlate.bind(this)
    this.validationDate = this.validationDate.bind(this)
    this.handleDNI = this.handleDNI.bind(this)
    this.getEntityRfc = this.getEntityRfc.bind(this)
  }

  initializePermisions (props) {
    const fieldKeys = Object.keys(this.state.fieldsConfiguration)
    const newState = getFieldsPermisions(props.componentConfiguration.fieldsConfiguration, fieldKeys, props.componentConfiguration.permission)
    this.setState({ fieldsConfiguration: newState.fieldsConfiguration })
  }

  UNSAFE_componentWillMount () {
    this.setState({ permission: 0 })
  }

  componentDidMount () {
    this.setState({ allMaritalStatus: this.props.dossier?.entityComponent?.maritalStatusId !== null })
  }

  resetVehicleOnFilterChange () {
    this.setState({ changedBeforeSelectVehicle: true })
    this.props.change('uacVehicleComplete.vehicleId', null)
  }

  toggleVehiclesListModal (showVehiclesListModal, vehiclesList = []) {
    this.setState({ showVehiclesListModal, vehiclesList })
  }

  setEntityFromParentDossier (entityFromParentDossier) {
    entityFromParentDossier && this.props.change('entityComponent', null)
    this.setState({
      entityFromParentDossier,
      entityComponent: {},
      entityTypeId: null,
      entityId: null,
      entitySubTypeId: null,
      allInformationDisabled: true,
      dniCifValidationDisable: null,
      representativeDniCifValidationDisable: null,
      dniCif: null,
      dniDisabled: true
    })
  }

  submitUAC (values) {
    var payPerDriveAlert = false
    const previousUsageId = this.props.uacModalForm.values.uacVehicleComplete.previousUsageId
    const previousUsageCode = previousUsageId ? this.props.combos.previousUsageCombo.find((value) => { return value.id === previousUsageId }).code : null
    if (settings.clientFieldValidation === 'toyo' && !(previousUsageCode && previousUsageCode === previousUsagesCode.payPerDrive)) {
      payPerDriveAlert = true
    }
    const subValues = { ...values, isPartOfPayment: !!values.isPartOfPayment, entityFromParentDossier: !!values.entityFromParentDossier, payPerDriveAlert: payPerDriveAlert, organizedUnitId: this.props.dossier.organizedUnitId }
    if (values.purchaseId) {
      this.props.actions.updateUAC(subValues, this.props.actions.closeModal, this.toggleVehiclesListModal.bind(this), this.props.dossierType)
    } else {
      this.props.actions.createUAC(subValues, this.props.actions.closeModal, this.toggleVehiclesListModal.bind(this), !this.state.changedBeforeSelectVehicle ? values.uacVehicleComplete.vehicleId : null, this.props.dossierType)
    }
    this.setState({ changedBeforeSelectVehicle: false })
  }

  getEntityPromise (value) {
    return new Promise((resolve, reject) => {
      return this.props.actions.fetchClientByDni(value, this.props.organizedUnitId, null, resolve, reject)
    })
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    const newState = {}
    if (nextProps.showModal && !this.props.showModal) {
      if (Object.keys(nextProps.uacUnit).length === 0) {
        nextProps.initialize({ entityFromParentDossier: true, isPartOfPayment: true, moveToVOCompany: true })
      } else {
        if (nextProps.uacUnit && !nextProps.uacUnit.entityFromParentDossier) {
          newState.entityFromParentDossier = false
        }
        if (nextProps.uacUnit.uacEntityOwner && this.state.entityTypeId !== nextProps.uacUnit.uacEntityOwner.entityTypeId) {
          newState.entityTypeId = (nextProps.uacUnit.uacEntityOwner && nextProps.uacUnit.uacEntityOwner.entityTypeId) ? nextProps.uacUnit.uacEntityOwner.entityTypeId : null
        }
        this.setState(newState)
      }
    } else if (!nextProps.showModal && this.props.showModal) {
      nextProps.initialize({})
      this.setState({
        entityFromParentDossier: true,
        entityComponent: {},
        entityTypeId: null,
        entityId: null,
        entitySubTypeId: null,
        allInformationDisabled: true,
        dniCifValidationDisable: null,
        representativeDniCifValidationDisable: null,
        dniCif: null,
        dniDisabled: true,
        showPurchaseContract: false
      })
    }
    if (this.props.submitFailed === false && nextProps.submitFailed === true) {
      this.showValidationAlert(nextProps)
    }

    if (nextProps.componentConfiguration && JSON.parse(JSON.stringify(this.props.componentConfiguration)) !== JSON.parse(JSON.stringify(nextProps.componentConfiguration))) {
      this.initializePermisions(nextProps)
    }
  }

  showValidationAlert (props) {
    if (props.submitFailed && props.uacModalForm.syncErrors) {
      if (!props.uacModalForm.syncErrors.entityComponent || (props.uacModalForm.syncErrors.entityComponent && Object.keys(props.uacModalForm.syncErrors.entityComponent).length > 0)) {
        this.props.actions.openCommonModal('mustFillUac')
      }
    }
  }

  openOrCloseModalwithTAX (value) {
    this.setState({ showModalwithTAX: value })
  }

  setAmoutWithoutTax (value) {
    this.props.change('amount', value)
  }

  setTest (value) {
    this.props.change('UACamountWithoutTax', value)
  }

  validationDate (value, allValues, props) {
    const result = validateDate(value, props)
    return result === Object(result) ? undefined : result
  }

  validateLicensePlate (value) {
    return validateLicensePlateFunciton(value, this.props.formValues && this.props.formValues.vehicleComponent.validateLicensePlateCountry || false, this.props.t('GLOBAL.FORMS.INVALID_STRING'))
  }

  getEntityNameLabel () {
    const tKey = 'DOSSIER_COMPONENTS.ENTITY.'
    switch (this.state.entityTypeId) {
      case entityTypesId.privateEntity:
        return this.props.t(`${tKey}NAME`)
      default:
        return this.props.t(`${tKey}REGISTERED_NAME`)
    }
  }

  handleDNI (dnicif, entityTypeId, disableValidation, representativeValidation = false, freeLance = false) {
    if (disableValidation) {
      if (!representativeValidation) {
        this.setState({ allInformationDisabled: dnicif === '' })
      }
    } else {
      if (representativeValidation) {
        this.setState({ representativeDni: dnicif })
      } else {
        let callbackFunction = dnicif === '' ? () => this.props.actions.clearClientFields(entityTypeId, this.props.dossierType) : () => true
        this.setState({ allInformationDisabled: true, dniCif: dnicif }, callbackFunction)
        new Promise((resolve, reject) => {
          if (entityTypeId === entityTypesId.privateEntity) {
            if (freeLance) {
              return this.props.actions.validate(validationTypes.FREELANCE_IDENTIFIER, { DniCif: dnicif }, this.props.dossier.organizedUnitId, resolve, reject, true)
            } else {
              return this.props.actions.validate(validationTypes.INDIVIDUAL_IDENTIFIER, { Dni: dnicif }, this.props.dossier.organizedUnitId, resolve, reject, true)
            }
          } else {
            return this.props.actions.validate(validationTypes.COMPANY_IDENTIFIER, { Cif: dnicif }, this.props.dossier.organizedUnitId, resolve, reject, true)
          }
        }).then(() => {
          this.setState({ allInformationDisabled: dnicif === '', dniCif: dnicif })
        }).catch(() => {})
      }
    }
  }

  setValidationDisable (dniCifValidationDisable, representativeDniCifValidationDisable, freeLance) {
    this.setState({ uacEntityOwner: { ...this.state.uacEntityOwner, representativeDniCifValidationDisable: !!representativeDniCifValidationDisable, dniCifValidationDisable: !!dniCifValidationDisable } })

    if (dniCifValidationDisable !== null && dniCifValidationDisable !== undefined) {
      this.handleDNI(this.state.dniCif, this.state.entityTypeId, dniCifValidationDisable, false, freeLance)
    }
    if (representativeDniCifValidationDisable !== null && representativeDniCifValidationDisable !== undefined) {
      this.handleDNI(this.state.representativeDni, this.state.entityTypeId, representativeDniCifValidationDisable, true, freeLance)
    }
  }

  onRecurrentTypeIdChanges (entitySubTypeId) {
    this.setState({ entitySubTypeId })
    if (entitySubTypeId) {
      this.props.actions.fetchEntityFilterSubTypeCombo(this.state.entityTypeId, entitySubTypeId, this.props.dossier.organizedUnitId)
    }
  }

  onRecurrentEntityIdChanges (entityId) {
    if (entityId) {
      this.props.actions.fetchClientUacModal(entityId)
    }
  }

  changeBoolLicensePlate (value) {
    this.setState({ disabledValidateLicensePlate: value })
  }

  changeBoolRescueLeasing (value) {
    this.setState({ rescueLeasing: value, additionalInvoiceDisabled: !value })
    if (value === false) {
      this.setState({ additionalInvoice: false })
      this.props.change('additionalInvoice', false)
    }
  }

  changeBoolAdditionalInvoice (value) {
    this.setState({ additionalInvoice: value })
  }

  checkPurchaseType (value) {
    if (value === purchaseTypesVo.scrapping) {
      this.setState({ showPurchaseContract: true })
    } else {
      this.setState({ showPurchaseContract: false })
    }
  }

  getEntityRfc (value) {
    const rfc = value
    if (value) {
      new Promise((resolve, reject) => {
        return this.props.actions.checkPrivateRfcFormat(value, resolve)
      }).then((confirm) => {
        if (confirm) {
          this.props.actions.fetchClientByRfc(rfc, this.props.dossier.organizedUnitId, null)
        } else {
          this.props.change('uacEntityOwner.rfc', null)
        }
      })
    }
  }

  setFreelance (value) {
    this.setState({ freeLance: value })
    this.handleDNI(this.state.dniCif, this.state.entityTypeId, this.state.uacEntityOwner?.dniCifValidationDisable, false, value)
    asyncValidate({ uacEntityOwner: { dniCif: this.state.dniCif, dniCifValidationDisable: this.state.uacEntityOwner?.dniCifValidationDisable, entityTypeId: this.state.entityTypeId, freeLance: value } }, this.props.actions.dispatch, this.props, 'dniCif', true).then((result) => {
      this.props.actions.stopAsyncValidation({ ...result })
    })
  }

  updateComponentAfterDeleteAndRestart () {

  }

  render () {
    const {
      t, handleSubmit, showModal, entityComponent,
      actions: {
        closeModal,
        chooseUACSuggestion
      },
      combos: {
        purchaseTypeCombo, brandCombo, fuelCombo, previousUsageCombo, stockStatusCombo, uacIvaTpyes
      },
      dossierType
    } = this.props

    const tKey = 'DOSSIER_COMPONENTS.UAC.MODAL.'
    const editTooltip = (<Tooltip id="editTooltip">{t('DOSSIER_COMPONENTS.VEHICLE.TOOLTIP_VALIDATE_LICENSE_PLATE')}</Tooltip>)
    return (
      <Modal className="uac-modal" show={showModal} onHide={() => closeModal()}>
        <VehiclesListModal
          t={t}
          showModal={this.state.showVehiclesListModal}
          vehicles={this.state.vehiclesList}
          actions={{
            closeModal: (userClose) => {
              this.toggleVehiclesListModal(false)
              if (userClose) {
                this.resetVehicleOnFilterChange()
              }
            },
            chooseUACSuggestion: (purchaseId, vehicle, closeSuggestionModalFunc) => chooseUACSuggestion(purchaseId, vehicle, closeSuggestionModalFunc, closeModal, dossierType)
          }}
        />
        <Form autoComplete="off" onSubmit={handleSubmit(this.submitUAC.bind(this))} noValidate>
          <Modal.Header closeButton>
            <Modal.Title>{t(`${tKey}TITLE`)}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Tabs id="uac" defaultActiveKey={1}>
              <Tab tabClassName="uac-vehicle-tab" eventKey={1} title={t(`${tKey}VEHICLE.TITLE`)} >
                <Row className="btn-modal-wrapper">
                  <Col sm={12}>
                    <Field
                      id="entityFromParentDossierUac"
                      name="entityFromParentDossier"
                      placeholder={t(`${tKey}UAC_CLIENT.CLIENT_IS_SAME`)}
                      component={InputCheckBox}
                      onInputChange={value => this.setEntityFromParentDossier(value)}
                      colSm={12}
                      inline
                    />
                  </Col>
                  <Col sm={12}>
                    <Field
                      id="isPartOfPaymentUac"
                      name="isPartOfPayment"
                      placeholder={t(`${tKey}VEHICLE.IS_PART_OF_PAYMENT`)}
                      component={InputCheckBox}
                      colSm={12}
                      inline
                    />
                  </Col>
                  <Col sm={12}>
                    <Field
                      customClass="disable-license-plate-checkbox"
                      id="disabledValidateLicensePlateUac"
                      name="uacVehicleComplete.disabledValidateLicensePlate"
                      placeholder={t(`${tKey}VEHICLE.DISABLED_VALIDATE_LICENSE_PLATE`)}
                      component={InputCheckBox}
                      inline
                      onChange= {(value) => (this.changeBoolLicensePlate(value))}
                    />
                    <OverlayTrigger overlay={editTooltip}>
                      <Glyphicon className="disable-license-plate-icon" glyph="info-sign"/>
                    </OverlayTrigger>
                  </Col>
                  {this.state.fieldsConfiguration.rescueLeasing.permission !== permissions.hidden && (
                    <Col sm={12}>
                      <Field
                        id="rescueLeasing"
                        name="rescueLeasing"
                        placeholder={t(`${tKey}UAC_CLIENT.RESCUE_LEASING`)}
                        component={InputCheckBox}
                        onInputChange={value => this.changeBoolRescueLeasing(value)}
                        colSm={12}
                        inline
                      />
                    </Col>)}
                  {this.state.fieldsConfiguration.additionalInvoice.permission !== permissions.hidden && (
                    <Col sm={12}>
                      <Field
                        id="additionalInvoice"
                        name="additionalInvoice"
                        placeholder={t(`${tKey}UAC_CLIENT.ADDITIONAL_INVOICE`)}
                        component={InputCheckBox}
                        onInputChange={value => this.changeBoolAdditionalInvoice(value)}
                        colSm={12}
                        disabled = {this.state.additionalInvoiceDisabled}
                        inline
                      />
                    </Col>)}
                  {this.state.fieldsConfiguration.checkNotContractPurchaseVehicle.permission !== permissions.hidden && this.state.showPurchaseContract && settings.clientFieldValidation === clientInformation.QUADIS && (
                    <Col sm={12}>
                      <Field
                        id="notContractPurchaseVehicleUac"
                        name="uacVehicleComplete.notContractPurchaseVehicle"
                        placeholder={t(`${tKey}NOT_CONTRACT_PURCHASE_VEHICLE`)}
                        inline
                        component={InputCheckBox}
                        disabled={this.state.fieldsConfiguration.checkNotContractPurchaseVehicle.permission !== permissions.editable}
                      /></Col>)}
                </Row>
                <Row>
                  <Field
                    colSm={3}
                    id="purchaseTypeIdUac"
                    name="purchaseTypeId"
                    controlLabel={t(`${tKey}VEHICLE.PURCHASE_TYPE`)}
                    placeholder={t(`${tKey}VEHICLE.PURCHASE_TYPE`)}
                    component={InputSelect}
                    options={purchaseTypeCombo}
                    valueKey="id"
                    labelKey="value"
                    onInputChange={(value) => this.checkPurchaseType(value)}
                  />
                  <Field
                    id="vinChassisUac"
                    name="uacVehicleComplete.vinChassis"
                    colSm={3}
                    controlLabel={t(`${tKey}VEHICLE.VIN_CHASSIS`)}
                    placeholder={t(`${tKey}VEHICLE.VIN_CHASSIS`)}
                    component={InputText}
                    minLength={17}
                    maxLength={17}
                    normalize={value => multipleNormalize(value, [toUpperCase, trimAll])}
                    onChange={(value) => this.resetVehicleOnFilterChange()}
                  />
                  <Field
                    id="licensePlateUac"
                    name="uacVehicleComplete.licensePlate"
                    colSm={3}
                    controlLabel={t(`${tKey}VEHICLE.LICENCE_PLATE`)}
                    placeholder={t(`${tKey}VEHICLE.LICENCE_PLATE`)}
                    component={InputText}
                    validate={this.state.disabledValidateLicensePlate === false || this.state.disabledValidateLicensePlate === null ? [this.validateLicensePlate] : null }
                    normalize={value => multipleNormalize(value, [toUpperCase, trimAll])}
                    onChange={(value) => this.resetVehicleOnFilterChange()}
                  />
                  <Field
                    id="licensePlateDateUac"
                    name="uacVehicleComplete.licensePlateDate"
                    colSm={3}
                    controlLabel={t(`${tKey}VEHICLE.LICENSE_PLATE`)}
                    component={InputDatePicker}
                    validate={[this.validationDate]}
                  />
                </Row>
                <Row>
                  <Field
                    id="brandUac"
                    name="uacVehicleComplete.brandId"
                    colSm={3}
                    controlLabel={t(`${tKey}VEHICLE.BRAND`)}
                    placeholder={t(`${tKey}VEHICLE.BRAND`)}
                    component={InputSelect}
                    options={brandCombo}
                    valueKey="id"
                    labelKey="value"
                  />
                  {this.state.fieldsConfiguration.brandReference && this.state.fieldsConfiguration.brandReference.permission !== permissions.hidden && (
                    <Field
                      id="brandReference"
                      name="uacVehicleComplete.brandReference"
                      colSm={3}
                      controlLabel={t(`${tKey}VEHICLE.CARLINE`)}
                      placeholder={t(`${tKey}VEHICLE.CARLINE`)}
                      component={InputText}
                    />)}
                  {this.state.fieldsConfiguration.unitReferenceNumber && this.state.fieldsConfiguration.unitReferenceNumber.permission !== permissions.hidden && (
                    <Field
                      id="unitReferenceNumber"
                      name="uacVehicleComplete.unitReferenceNumber"
                      colSm={3}
                      controlLabel={t(`${tKey}VEHICLE.CATALOG`)}
                      placeholder={t(`${tKey}VEHICLE.CATALOG`)}
                      component={InputText}
                    />)}
                  {this.state.fieldsConfiguration.modelUacNumeric && this.state.fieldsConfiguration.modelUacNumeric.permission !== permissions.hidden && (
                    <Field
                      id="modelUacNumeric"
                      name="uacVehicleComplete.modelYear"
                      colSm={3}
                      controlLabel={t(`${tKey}VEHICLE.MODEL_YEAR`)}
                      placeholder={t(`${tKey}VEHICLE.MODEL_YEAR`)}
                      component={InputNumber}
                      maxLength={modelLength.maxLength}
                    />)}
                </Row>
                <Row>

                  {this.state.fieldsConfiguration.modelUac && this.state.fieldsConfiguration.modelUac.permission !== permissions.hidden && (
                    <Field
                      id="modelUac"
                      name="uacVehicleComplete.model"
                      colSm={3}
                      controlLabel={t(`${tKey}VEHICLE.MODEL`)}
                      placeholder={t(`${tKey}VEHICLE.MODEL`)}
                      component={InputText}
                    />)}

                  <Field
                    id="colorUac"
                    name="uacVehicleComplete.color"
                    colSm={3}
                    controlLabel={t(`${tKey}VEHICLE.COLOR`)}
                    placeholder={t(`${tKey}VEHICLE.COLOR`)}
                    component={InputText}
                  />
                  <Field
                    id="tapestryUac"
                    name="uacVehicleComplete.tapestry"
                    colSm={3}
                    controlLabel={t(`${tKey}VEHICLE.TAPESTRY`)}
                    placeholder={t(`${tKey}VEHICLE.TAPESTRY`)}
                    component={InputText}
                  />
                  {this.state.fieldsConfiguration.comercialCode && this.state.fieldsConfiguration.comercialCode.permission !== permissions.hidden && (
                    <Field
                      id="comercialCode"
                      name="uacVehicleComplete.comercialCode"
                      colSm={3}
                      controlLabel={t(`${tKey}VEHICLE.MODEL_VERSION`)}
                      placeholder={t(`${tKey}VEHICLE.MODEL_VERSION`)}
                      component={InputText}
                    />)}
                  <Field
                    colSm={3}
                    id="fuelIdUac"
                    name="uacVehicleComplete.fuelId"
                    controlLabel={t(`${tKey}VEHICLE.FUEL_ID`)}
                    placeholder={t(`${tKey}VEHICLE.FUEL_ID`)}
                    component={InputSelect}
                    options={fuelCombo}
                    valueKey="id"
                    labelKey="value"
                  />
                </Row>
                <Row>
                  <Field
                    id="availabilityDateUac"
                    name="uacVehicleComplete.availabilityDate"
                    colSm={3}
                    controlLabel={t(`${tKey}VEHICLE.AVAILABILITY_DATE`)}
                    component={InputDatePicker}
                    validate={[this.validationDate]}
                  />
                  <Field
                    id="kmUac"
                    name="uacVehicleComplete.km"
                    colSm={3}
                    controlLabel={t(`${tKey}VEHICLE.KM`)}
                    placeholder={t(`${tKey}VEHICLE.KM`)}
                    component={InputNatural}
                    textAlign="left"
                  />
                  {this.state.fieldsConfiguration.uacDealerNumber.permission !== permissions.hidden && (<Field
                    id="dealerNumberUac"
                    name="uacVehicleComplete.dealerNumber"
                    colSm={3}
                    controlLabel={t(`${tKey}VEHICLE.DEALER_NUMBER`)}
                    placeholder={t(`${tKey}VEHICLE.DEALER_NUMBER`)}
                    component={InputText}
                    maxLength={settings.dealerMaxLength}
                  />)}
                  <Field
                    id="amountUac"
                    name="amount"
                    colSm={3}
                    controlLabel={t(`${tKey}VEHICLE.APPRAISAL_VALUE`)}
                    placeholder={t(`${tKey}VEHICLE.APPRAISAL_VALUE`)}
                    component={InputMoney}
                  />
                </Row>
                <Row>
                  <Field
                    colSm={3}
                    id="previousUsageIdUac"
                    name="uacVehicleComplete.previousUsageId"
                    controlLabel={t(`${tKey}VEHICLE.PREVIOUS_USAGE_ID`)}
                    placeholder={t(`${tKey}VEHICLE.PREVIOUS_USAGE_ID`)}
                    component={InputSelect}
                    options={previousUsageCombo}
                    valueKey="id"
                    labelKey="value"
                  />
                  {
                  /*
                  <Field
                    id="factoryNumber"
                    name="uacVehicleComplete.factoryNumber"
                    colSm={3}
                    controlLabel={t(`${tKey}VEHICLE.FACTORY_NUMBER`)}
                    placeholder={t(`${tKey}VEHICLE.FACTORY_NUMBER`)}
                    component={InputText}
                  />
                  <Field
                    id="commissionNumber"
                    name="uacVehicleComplete.commissionNumber"
                    colSm={3}
                    controlLabel={t(`${tKey}VEHICLE.COMMISSION_NUMBER`)}
                    placeholder={t(`${tKey}VEHICLE.COMMISSION_NUMBER`)}
                    component={InputText}
                  />
                  */
                  }
                  {this.state.fieldsConfiguration.stockStatusId && this.state.fieldsConfiguration.stockStatusId.permission !== permissions.hidden && (<Field
                    colSm={3}
                    id="stockStatusIdUac"
                    name="stockStatusId"
                    controlLabel={t(`${tKey}VEHICLE.STOCK_STATUS`)}
                    placeholder={t(`${tKey}VEHICLE.STOCK_STATUS`)}
                    valueKey="id"
                    labelKey="value"
                    component={InputSelect}
                    options={stockStatusCombo}
                  />)}
                  {this.state.fieldsConfiguration.expirationItvDate && this.state.fieldsConfiguration.expirationItvDate.permission !== permissions.hidden && settings.clientFieldValidation === clientInformation.VGR && (<Field
                    id="expirationItvDateUac"
                    name="uacVehicleComplete.expirationItvDate"
                    colSm={3}
                    controlLabel={t(`${tKey}EXPIRATION_ITV_DATE`)}
                    component={InputDatePicker}
                    validate={[this.validationDate]}
                  />)}
                </Row>
                {this.state.fieldsConfiguration.expirationItvDate && this.state.fieldsConfiguration.expirationItvDate.permission !== permissions.hidden && settings.clientFieldValidation === clientInformation.VGR && (<Row>
                  <Field
                    colSm={3}
                    customClass="column-planteo-iem-iva"
                    id="ivaTypeIdUac"
                    name="ivaTypeId"
                    controlLabel='IVA'
                    component={InputSelect}
                    options={uacIvaTpyes}
                    valueKey="id"
                    labelKey="value"
                    onInputChange={(valueIva) => this.setState({ valueIva })}
                  />
                  <div className="col-sm-3">
                    <div className="form-group">
                      { this.state.valueIva ? (
                        <Button
                          className="btn-standard btn-iva"
                          onClick={() => this.openOrCloseModalwithTAX(true)}>
                          {t('DOSSIER_COMPONENTS.ECONOMIC_PLAN.ADD_AMOUNT_WITH_TAX')}
                        </Button>)
                        : null
                      }
                    </div>
                  </div>
                  <UACAppraisalWithTAXModal
                    t={t}
                    showModal={this.state.showModalwithTAX}
                    actions={{
                      closeModal: () => this.openOrCloseModalwithTAX(false),
                      setAmoutWithoutTax: (value) => this.setAmoutWithoutTax(value),
                      setTest: (value) => this.setTest(value),
                      change: (field, value) => this.props.change(field, value)
                    }}
                    ivaTypeCombo={uacIvaTpyes}
                    valueCombo={this.state.valueIva}
                  />
                </Row>)}
              </Tab>
              {!this.state.entityFromParentDossier && (
                <Tab eventKey={2} title={t(`${tKey}UAC_CLIENT.TITLE`)} tabClassName="uac-user-tab">
                  <Row>

                    <ClientData
                      actions={{
                        ...this.props.actions,

                        asyncValidate,
                        stopAsyncValidation: (value) => this.props.actions.stopAsyncValidation('uacModal', value)
                      }}
                      combos= {this.props.combos}
                      fieldsConfiguration={this.state.fieldsConfiguration}
                      dossier= {{ entityComponent, organizedUnitId: this.props.dossier.organizedUnitId }}
                      readOnlyFields={false}
                      t={t}
                      dossierType = {dossierTypesConstant.uacModal}
                      isRecurrentEntityType = {(entityComponent && entityComponent.entityTypeId ? entityComponent.entityTypeId === entityTypesId.recurrentEntity : false)}
                      entityChangeable= {entityComponent && entityComponent.entityTypeId}
                      formValues={this.props.uacModalForm?.values}
                    />
                  </Row>
                </Tab>
              )}
            </Tabs>
          </Modal.Body>
          <Modal.Footer>
            <Col sm={6} className="btn-wrapper">
              <Button id="btn_save_uac" className="btn-standard" type="submit" onClick={() => this.showValidationAlert(this.props)}>{t(`${tKey}SAVE_BTN`)}</Button>
            </Col>
          </Modal.Footer>
        </Form>
      </Modal>
    )
  }
}
function validateUacModal (values, props) {
  const errors = {}
  const tKey = 'DOSSIER_COMPONENTS.UAC.MODAL.VALIDATION.'
  if (!values.entityFromParentDossier && (!values.entityComponent || !values.entityComponent.entityTypeId)) {
    errors.entityComponent = { entityTypeId: props.t(`${tKey}FILL_ENTITY`) }
  }
  if (values.entityComponent && values.entityComponent.entityTypeId) {
    errors.entityComponent = validateEntity(values.entityComponent, props)
  }
  if (!values.uacVehicleComplete || (!values.uacVehicleComplete.vinChassis && !values.uacVehicleComplete.licensePlate)) {
    errors.uacVehicleComplete = { vinChassis: props.t(`${tKey}FILL_VEHICLE`), licensePlate: props.t(`${tKey}FILL_VEHICLE`) }
  }
  if (values.uacVehicleComplete && values.uacVehicleComplete.vinChassis) {
    let validationVinChassis = validateVinChassis(values.uacVehicleComplete.vinChassis, props)
    if (!(Object.keys(validationVinChassis).length === 0 && validationVinChassis.constructor === Object)) {
      errors.uacVehicleComplete = { vinChassis: validationVinChassis }
    }
  }
  if (!values.purchaseTypeId) {
    errors.purchaseTypeId = props.t(`${tKey}FILL_PURCHASE_TYPE`)
  }
  if (settings.clientFieldValidation === 'vgr' && !values.amount) {
    errors.amount = props.t(`${tKey}FILL_AMOUNT`)
  }
  if (settings.clientFieldValidation === 'vgr' && (!values.uacVehicleComplete || !values.uacVehicleComplete.expirationItvDate)) {
    errors.uacVehicleComplete = { expirationItvDate: props.t('DOSSIER_COMPONENTS.UAC.MODAL.VALIDATION.FILL_ITV_EXP_DATE') }
  }

  if (settings.clientFieldValidation === clientInformation.BUSS) {
    if (!values.uacVehicleComplete || !values.uacVehicleComplete.brandId) {
      errors.uacVehicleComplete = { ...errors.uacVehicleComplete, brandId: props.t('DOSSIER_COMPONENTS.VALIDATIONS.VEHICLE_BRAND_MANDATORY') }
    }
    if (!values.uacVehicleComplete || !values.uacVehicleComplete.brandReference) {
      errors.uacVehicleComplete = { ...errors.uacVehicleComplete, brandReference: props.t('DOSSIER_COMPONENTS.VALIDATIONS.MANDATORY_FIELD') }
    }
    if (!values.uacVehicleComplete || !values.uacVehicleComplete.unitReferenceNumber) {
      errors.uacVehicleComplete = { ...errors.uacVehicleComplete, unitReferenceNumber: props.t('DOSSIER_COMPONENTS.VALIDATIONS.MANDATORY_FIELD') }
    }
    if (!values.uacVehicleComplete || !values.uacVehicleComplete.model) {
      errors.uacVehicleComplete = { ...errors.uacVehicleComplete, model: props.t('DOSSIER_COMPONENTS.VALIDATIONS.MANDATORY_FIELD') }
    }
    if (!values.uacVehicleComplete || !values.uacVehicleComplete.color) {
      errors.uacVehicleComplete = { ...errors.uacVehicleComplete, color: props.t('DOSSIER_COMPONENTS.VALIDATIONS.MANDATORY_FIELD') }
    }
    if (!values.uacVehicleComplete ||
      !values.uacVehicleComplete.modelYear ||
      !values.uacVehicleComplete.modelYear.match(commonRegExp.is4DigitYear)) {
      var errorMessage = values.uacVehicleComplete?.modelYear?.length > 0 &&
        !values.uacVehicleComplete.modelYear.match(commonRegExp.is4DigitYear)
        ? props.t('GLOBAL.FORMS.INVALID_STRING')
        : props.t('DOSSIER_COMPONENTS.VALIDATIONS.VEHICLE_MODEL_YEAR_MANDATORY')

      errors.uacVehicleComplete = { ...errors.uacVehicleComplete, modelYear: errorMessage }
    }
  }

  return errors
}

const asyncValidate = (values, dispatch, props, field, returnError) => {
  let promises = []
  if (values.entityComponent) {
    promises = promises.concat(asyncValidateEntityDni(values.entityComponent, dispatch, props, props.dossier?.organizedUnitId))
  }

  return Promise.allSettled(promises).then(result => {
    let error = {}
    for (let index = 0; index < result.length; index++) {
      const element = result[index]
      if (element.status === promisesStatus.FULFILLED) {
        switch (index) {
          case validationUACModalPromisesOrder.UAC_ENTITY_OWNER:
            error = { ...error, entityComponent: element.value }
            break
          default:
            break
        }
      }
    }
    if (Object.keys(error).length > 0 && !returnError) throw Object.assign(new Error(), error)
    if (returnError) return error
  })
}

export default reduxForm({
  form: 'uacModal',
  validate: validateUacModal,
  asyncValidate,
  asyncBlurFields: [...getFieldsValidated('entityComponent')]
})(UACModal)
