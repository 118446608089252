import { call, put, select, takeLatest } from 'redux-saga/effects'
import { getAuth } from '../../selectors/access/auth'
import { showLoader, hideLoader } from '../../actions/common'
import combosActionTypes from '../../constants/actions/combos/combos'
import { fetchCombosSuccess } from '../../actions/combos/combos'
import { handleError } from '../errors/errorManager'
import getPaymentMethod from '../../services/paymentMethod/getPaymentMethod'
import { dossierSubTypeId } from '../../constants/dossier/common/dossierSubType'

export function * watchFetchPaymentMethodCombo () {
  yield takeLatest(combosActionTypes.FETCH_PAYMENT_METHOD_COMBO, fetchPaymentMethodCombo)
}
export function * fetchPaymentMethodCombo ({ dossierSubtypeId }) {
  try {
    let paymentMethodCombo = []
    yield put(showLoader())
    if (dossierSubtypeId && (dossierSubtypeId === dossierSubTypeId.venta_vn || dossierSubtypeId === dossierSubTypeId.venta_vo)) {
      const auth = yield select(getAuth)
      paymentMethodCombo = yield call(getPaymentMethod, auth.token, false)
    }
    yield put(fetchCombosSuccess({ paymentMethodCombo }))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}
