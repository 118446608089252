import React, { PureComponent } from 'react'
import { reduxForm, Field } from 'redux-form'
import { Row, Col, Table, Button, Tooltip, OverlayTrigger } from 'react-bootstrap'
import IDocCarPagination from '../../commons/iDocCarPagination'
import OrganizedUnitConfigurationModalPage from '../../../containers/masters/OrganizedUnitConfiguration/OrganizedUnitConfigurationModalPage'
import InputSelect from '../../commons/form/InputSelect'

class OrganizedUnitConfigurationMaster extends PureComponent {
  constructor () {
    super()
    this.state = {
      filter: {
        organizedUnitId: null,
        configParameterId: null
      }
    }
  }

  componentDidUpdate () {
    if (this.props.ouIdFromMaster != null) {
      const form = {
        filters: {
          organizedUnitId: this.props.ouIdFromMaster
        }
      }
      this.props.initialize(form)
      this.handleorganizedUnitComboChange(this.props.ouIdFromMaster)
      this.props.actions.setChangeTabFromConfMaster(null, false)
    }
  }

  getSortIcon (column = '') {
    switch (this.props.filter.orderBy) {
      case '+' + column:
        return 'ico-sort-selected-asc'
      case '-' + column:
        return 'ico-sort-selected-desc'
      default:
        return 'ico-sort'
    }
  }

  changeOrder (field) {
    switch (this.props.filter.orderBy) {
      case `+${field}`:
        this.props.actions.fetchOrganizedUnitConfigurationList({ ...this.state.filter, ...this.props.filter, orderBy: `-${field}` })
        break
      default:
        this.props.actions.fetchOrganizedUnitConfigurationList({ ...this.state.filter, ...this.props.filter, orderBy: `+${field}` })
        break
    }
  }

  handleConfigParameterChange (configParameterId) {
    if (this.state.filter.configParameterId !== configParameterId) {
      this.setState({ filter: { ...this.state.filter, configParameterId } })
    }
  }

  handleorganizedUnitComboChange (organizedUnitId) {
    if (this.state.filter.organizedUnitId !== organizedUnitId) {
      this.setState({ filter: { ...this.state.filter, organizedUnitId } })
    }
  }

  search () {
    this.props.actions.fetchOrganizedUnitConfigurationList({ ...this.props.filter, ...this.state.filter, page: 1 })
  }

  resetFilters () {
    this.setState({
      filter: {
        organizedUnitId: null,
        configParameterId: null
      }
    }, () => { this.search() })
  }

  render () {
    const {
      t, organizedUnitConfigurationList, pagesCount, organizedUnitConfigurationsCount, filter,
      combos: { organizedUnitsAllCombo, configParameterAllCombo },
      filter: { page },
      actions: { openOrganizedUnitConfigurationModal, fetchOrganizedUnitConfigurationList, deleteOrganizedUnitConfiguration }
    } = this.props
    const tKey = 'MASTERS.ORGANIZED_UNIT_CONFIGURATION_MASTERS.'
    const editTooltip = (<Tooltip id="editTooltip">{t(`${tKey}EDIT`)}</Tooltip>)
    const deleteTooltip = (<Tooltip id="deleteTooltip">{t(`${tKey}DELETE`)}</Tooltip>)
    const hasFilters = (this.state.filter.configParameterId || this.state.filter.organizedUnitId)
    return (
      <div className="admin-wrapper">
        <OrganizedUnitConfigurationModalPage/>
        <Row className="section-users-header">
          <Col sm={12}>
            <div className="header-panel">
              <h4>{t(`${tKey}ORGANIZED_UNIT_CONFIGURATION`)}</h4>
            </div>
          </Col>
        </Row>
        <Row>
          <div className="search-form">
            <span className="title-search"><i className="ico-search" /> {t(`${tKey}SEARCH_TITLE`)}</span>
            <Row>
              <Field
                name="filters.configParameterId"
                colSm={3}
                controlLabel={t(`${tKey}CONFIG_PARAMETER`)}
                placeholder={t(`${tKey}CONFIG_PARAMETER`)}
                component={InputSelect}
                valueKey="id"
                labelKey="value"
                options={configParameterAllCombo}
                onInputChange={(value) => this.handleConfigParameterChange(value)}
              />
              <Field
                name="filters.organizedUnitId"
                colSm={3}
                controlLabel={t(`${tKey}OU`)}
                placeholder={t(`${tKey}OU`)}
                component={InputSelect}
                valueKey="id"
                labelKey="value"
                options={organizedUnitsAllCombo}
                onInputChange={(value) => this.handleorganizedUnitComboChange(value)}
              />
              <Col sm={6} className="btn-wrapper">
                <p></p>
                <Button
                  className={'btn-white btn-icon btn-input btn-white search-button'}
                  style={{ width: '100%' }}
                  onClick={() => this.search()}>
                  <i className="ico-search" />
                  {t(`${tKey}SEARCH_BTN`)}
                </Button>
                <Button
                  className={'btn-icon btn-input ' + (hasFilters ? 'btn-standard' : 'btn-cancel')}
                  onClick={() => {
                    this.props.initialize({})
                    this.resetFilters()
                  }}>
                  <i className="ico-cancel" />
                  {t('MASTERS.RESET_BUTTON')}
                </Button>
              </Col>
            </Row>
          </div>
        </Row>
        <Col sm={12} className="col-row  section-users-body">
          <Table className="editable-table" hover responsive>
            <thead>
              <tr>
                <th onClick={() => { this.changeOrder('documentTypeId') }}>
                  {t(`${tKey}OU`)}
                  <i className={this.getSortIcon('documentTypeId')} />
                </th>
                <th onClick={() => { this.changeOrder('organizedUnitConfiguration.code') }}>
                  {t(`${tKey}VALUE`)}
                  <i className={this.getSortIcon('organizedUnitConfiguration.code')} />
                </th>
                <th style={{ width: '77px' }}>{t('DOSSIER_COMPONENTS.ACCESSORIES.ACTIONS')}</th>
              </tr>
            </thead>
            <tbody>
              {organizedUnitConfigurationList && organizedUnitConfigurationList.map((organizedUnitConfiguration, idx) => {
                return (
                  <tr key={idx} className={organizedUnitConfiguration.isDeleted ? 'is-deleted' : ''}>
                    <td>{organizedUnitsAllCombo && organizedUnitsAllCombo.find(x => x.id === organizedUnitConfiguration?.organizedUnitId) && organizedUnitsAllCombo.find(x => x.id === organizedUnitConfiguration?.organizedUnitId).value}</td>
                    <td>{(!organizedUnitConfiguration.configParameter.type.includes('List')) ? organizedUnitConfiguration.value : organizedUnitConfiguration.value.join(',')}</td>
                    <td>
                      {!organizedUnitConfiguration.isDeleted && (
                        <div>
                          <a onClick={() => openOrganizedUnitConfigurationModal(organizedUnitConfiguration)}>
                            <OverlayTrigger placement="left" overlay={editTooltip}>
                              <i className="ico-edit-white" />
                            </OverlayTrigger>
                          </a>
                          <a onClick={() => deleteOrganizedUnitConfiguration(organizedUnitConfiguration.organizedUnitConfigurationId)}>
                            <OverlayTrigger placement="left" overlay={deleteTooltip}>
                              <i className="ico-trash" />
                            </OverlayTrigger>
                          </a>
                        </div>
                      )}
                    </td>
                  </tr>)
              })
              }
              {(organizedUnitConfigurationList === null || organizedUnitConfigurationList === undefined || organizedUnitConfigurationList.length === 0) &&
            <tr>
              <td colSpan={10}><h2>{t(`${tKey}RESULTS_NOT_FOUND`)}</h2></td>
            </tr>}
              <tr className="button-table-wrapper">
                <td>
                  <Button
                    className="btn-editable-table btn-standard"
                    onClick={() => openOrganizedUnitConfigurationModal()}>
                  + {t(`${tKey}ADD`)}
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>
          <div className="section-users-footer">
            <nav key={1} className="text-center">
              <IDocCarPagination
                id="btn_pag_sales"
                page={page}
                pagesCount={pagesCount}
                totalRows={organizedUnitConfigurationsCount}
                onSelectAction={(value) => fetchOrganizedUnitConfigurationList({ ...filter, ...this.state.filter, page: value })}
              />
            </nav>
          </div>
        </Col>
      </div>
    )
  }
}

export default reduxForm({
  form: 'listDocumentType'
})(OrganizedUnitConfigurationMaster)
