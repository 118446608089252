export default {
  SAVE_FLEET_DATA_CHANGES: 'SAVE_FLEET_DATA_CHANGES',
  SAVE_FLEET_DATA_CHANGES_SUCCESS: 'SAVE_FLEET_DATA_CHANGES_SUCCESS',
  FETCH_FLEETS_INFO: 'FETCH_FLEETS_INFO',
  FETCH_FLEETS_INFO_SUCCESS: 'FETCH_FLEETS_INFO_SUCCESS',
  RESET_FILTERS_FLEET_INFO: 'RESET_FILTERS_FLEET_INFO',
  OPEN_DATA_ACTION_FLEET_FINDER: 'OPEN_DATA_ACTION_FLEET_FINDER',
  CLOSE_DATA_ACTION_FLEET_FINDER: 'CLOSE_DATA_ACTION_FLEET_FINDER',
  ASIGN_DOSSIER_TO_FLEET: 'ASIGN_DOSSIER_TO_FLEET',
  CREATE_UPDATE_FLEET: 'CREATE_UPDATE_FLEET',
  DELETE_DOSSIER_FROM_FLEET: 'DELETE_DOSSIER_FROM_FLEET',
  DELETE_FLEET: 'DELETE_FLEET',
  EDIT_FLEET: 'EDIT_FLEET',
  CHANGE_ENTITY_DOSSIER_FROM_FLEET: 'CHANGE_ENTITY_DOSSIER_FROM_FLEET',
  OPEN_NEW_FLEET_MODAL: 'OPEN_NEW_FLEET_MODAL',
  CLOSE_NEW_FLEET_MODAL: 'CLOSE_NEW_FLEET_MODAL',
  CREATE_NEW_FLEET: 'CREATE_NEW_FLEET',
  SAVE_UPDATE_FLEET: 'SAVE_UPDATE_FLEET',
  FETCH_FLEETS_INFO_DOWNLOAD: 'FETCH_FLEETS_INFO_DOWNLOAD',
  FETCH_PAYMENT_COMBO: 'FETCH_PAYMENT_COMBO',
  FETCH_PAYMENT_COMBO_SUCCESS: 'FETCH_PAYMENT_COMBO_SUCCESS',
  CHANGE_DNICIF_DATA: 'CHANGE_DNICIF_DATA',
  GET_CLIENT_FLEET_PERMISSIONS: 'GET_CLIENT_FLEET_PERMISSIONS',
  GET_CLIENT_FLEET_PERMISSIONS_SUCCESS: 'GET_CLIENT_FLEET_PERMISSIONS_SUCCESS',
  FETCH_ENTITY_FLEET_SUCCES: 'FETCH_ENTITY_FLEET_SUCCES',
  FETCH_ENTITY_FLEET: 'FETCH_ENTITY_FLEET',
  CHECK_CHANGE_ENTITY_DOSSIER: 'CHECK_CHANGE_ENTITY_DOSSIER',
  CHECK_CHANGE_ENTITY_DOSSIER_SUCCESS: 'CHECK_CHANGE_ENTITY_DOSSIER_SUCCESS',
  SEND_TO_APPROVE_ACTION_FLEET: 'SEND_TO_APPROVE_ACTION_FLEET',
  APPROVE_ACTION_FLEET: 'APPROVE_ACTION_FLEET',
  APPROVE_ROLE_ACTION_FLEET: 'APPROVE_ROLE_ACTION_FLEET',
  OPEN_UPLOAD_DOCUMENTS_FLEET_MODAL: 'OPEN_UPLOAD_DOCUMENTS_FLEET_MODAL',
  CLOSE_UPLOAD_DOCUMENTS_FLEET_MODAL: 'CLOSE_UPLOAD_DOCUMENTS_FLEET_MODAL',
  FETCH_VALIDATE_DOCUMENT_FLEET: 'FETCH_VALIDATE_DOCUMENT_FLEET',
  UPLOAD_ZIP_DOCUMENT_FLEET: 'UPLOAD_ZIP_DOCUMENT_FLEET',
  GET_DOCUMENT_TYPE_FLEETS: 'GET_DOCUMENT_TYPE_FLEETS',
  OPEN_ECONOMIC_PLAN_MODAL: 'OPEN_ECONOMIC_PLAN_MODAL',
  CLOSE_ECONOMIC_PLAN_MODAL: 'CLOSE_ECONOMIC_PLAN_MODAL',
  FETCH_ECONOMIC_PLAN_COMBOS: 'FETCH_ECONOMIC_PLAN_COMBOS',
  UPATE_ECONOMIC_PLAN_FLEET: 'UPATE_ECONOMIC_PLAN_FLEET',
  GET_ECONOMIC_PLAN_FLEET_PERMISSIONS: 'GET_ECONOMIC_PLAN_FLEET_PERMISSIONS',
  GET_ECONOMIC_PLAN_FLEET_PERMISSIONS_SUCCESS: 'GET_ECONOMIC_PLAN_FLEET_PERMISSIONS_SUCCESS',
  SIMULATE_ECONOMIC_PLAN_FLEET: 'SIMULATE_ECONOMIC_PLAN_FLEET',
  SIMULATE_ECONOMIC_PLAN_FLEET_SUCCESS: 'SIMULATE_ECONOMIC_PLAN_FLEET_SUCCESS',
  ADD_CAMPAIGN_ECONOMIC_PLAN_FLEET: 'ADD_CAMPAIGN_ECONOMIC_PLAN_FLEET',
  ADD_CAMPAIGN_ECONOMIC_PLAN_FLEET_SUCCESS: 'ADD_CAMPAIGN_ECONOMIC_PLAN_FLEET_SUCCESS',
  SAVE_ECONOMIC_PLAN_FLEET: 'SAVE_ECONOMIC_PLAN_FLEET',
  SAVE_ECONOMIC_PLAN_FLEET_SUCCESS: 'SAVE_ECONOMIC_PLAN_FLEET_SUCCESS',
  FETCH_SHOW_FRONT_ECONOMIC_PLAN: 'FETCH_SHOW_FRONT_ECONOMIC_PLAN',
  DELETE_ECONOMIC_CAMPAIGN: 'DELETE_ECONOMIC_CAMPAIGN',
  SET_SIMULATED_FLAG: 'SET_SIMULATED_FLAG',
  FIND_FLEETS_MANAGEMENT: 'FIND_FLEETS_MANAGEMENT',
  FIND_FLEETS_MANAGEMENT_SUCCESS: 'FIND_FLEETS_MANAGEMENT_SUCCESS',
  RESET_FLEETS_MANAGEMENT_FILTERS: 'RESET_FLEETS_MANAGEMENT_FILTERS',
  MODIFY_FLEETS_MANAGEMENT_FILTERS: 'MODIFY_FLEETS_MANAGEMENT_FILTERS',
  FIND_FLEETS_MANAGEMENT_EXPORT: 'FIND_FLEETS_MANAGEMENT_EXPORT',
  OPEN_CREATE_EDIT_FLEET_MODAL: 'OPEN_CREATE_EDIT_FLEET_MODAL',
  FETCH_ENTITY_CHILD_FLEET_SUCCES: 'FETCH_ENTITY_CHILD_FLEET_SUCCES',
  CLOSE_CREATE_EDIT_FLEET_MODAL: 'CLOSE_CREATE_EDIT_FLEET_MODAL',
  GET_FLEET_MODEL: 'GET_FLEET_MODEL',
  GET_FLEET_MODEL_SUCCESS: 'GET_FLEET_MODEL_SUCCESS',
  OPEN_EDIT_FLEET_MODAL: 'OPEN_EDIT_FLEET_MODAL',
  CLOSE_EDIT_FLEET_MODAL: 'CLOSE_EDIT_FLEET_MODAL',
  FETCH_DOSSIER_DETAIL_FLEET: 'FETCH_DOSSIER_DETAIL_FLEET',
  FETCH_DOSSIER_DETAIL_FLEET_SUCCESS: 'FETCH_DOSSIER_DETAIL_FLEET_SUCCESS',
  DELETE_DOSSIERS_FROM_FLEET: 'DELETE_DOSSIERS_FROM_FLEET',
  OPEN_OPERATE_FLEET_MODAL: 'OPEN_OPERATE_FLEET_MODAL',
  OPEN_OPERATE_FLEET_MODAL_SUCCESS: 'OPEN_OPERATE_FLEET_MODAL_SUCCESS',
  CLOSE_OPERATE_FLEET_MODAL: 'CLOSE_OPERATE_FLEET_MODAL',
  FETCH_OPERATE_ACTIONS_FLEET: 'FETCH_OPERATE_ACTIONS_FLEET',
  FETCH_OPERATE_ACTIONS_FLEET_SUCCESS: 'FETCH_OPERATE_ACTIONS_FLEET_SUCCESS',
  FETCH_OPERATE_DATA_FLEET_MODALS_PERMISSIONS: 'FETCH_OPERATE_DATA_FLEET_MODALS_PERMISSIONS',
  FETCH_OPERATE_DATA_FLEET_MODALS_PERMISSIONS_SUCCESS: 'FETCH_OPERATE_DATA_FLEET_MODALS_PERMISSIONS_SUCCESS',
  INITIALIZE_OPERATION_ACTION_COMBOS: 'INITIALIZE_OPERATION_ACTION_COMBOS',
  INITIALIZE_OPERATION_ACTION_COMBOS_SUCCESS: 'INITIALIZE_OPERATION_ACTION_COMBOS_SUCCESS',
  FETCH_OPERATE_PAPERWORK_FLEET_PERMISSIONS: 'FETCH_OPERATE_PAPERWORK_FLEET_PERMISSIONS',
  FETCH_OPERATE_PAPERWORK_FLEET_PERMISSIONS_SUCCESS: 'FETCH_OPERATE_PAPERWORK_FLEET_PERMISSIONS_SUCCESS',
  FETCH_DOCUMENT_FLEET_LIST: 'FETCH_DOCUMENT_FLEET_LIST',
  FETCH_DOCUMENT_FLEET_LIST_SUCCESS: 'FETCH_DOCUMENT_FLEET_LIST_SUCCESS',
  OPEN_DOCUMENT_FLEET_MODAL: 'OPEN_DOCUMENT_FLEET_MODAL',
  CLOSE_DOCUMENT_FLEET_MODAL: 'CLOSE_DOCUMENT_FLEET_MODAL',
  RESET_FILTERS_SELF_SUPPLY_INFO: 'RESET_FILTERS_SELF_SUPPLY_INFO',
  FETCH_SELF_SUPPLY_INFO: 'FETCH_SELF_SUPPLY_INFO',
  FETCH_SELF_SUPPLY_INFO_SUCCES: 'FETCH_SELF_SUPPLY_INFO_SUCCES',
  FETCH_SELF_SUPPLY_INFO_DOWNLOAD: 'FETCH_SELF_SUPPLY_INFO_DOWNLOAD',
  PASS_SELF_SUPPLY_VO_ACTION: 'PASS_SELF_SUPPLY_VO_ACTION',
  FETCH_GET_CAN_CREATE_OPERATE_SERVICES: 'FETCH_GET_CAN_CREATE_OPERATE_SERVICES',
  OPEN_ECONOMIC_FLEET_MODAL: 'OPEN_ECONOMIC_FLEET_MODAL',
  OPEN_ECONOMIC_FLEET_MODAL_SUCCESS: 'OPEN_ECONOMIC_FLEET_MODAL_SUCCESS',
  CLOSE_ECONOMIC_FLEET_MODAL: 'CLOSE_ECONOMIC_FLEET_MODAL',
  OPEN_CLOSE_HISTORICAL_FLEET_MODAL: 'OPEN_CLOSE_HISTORICAL_FLEET_MODAL',
  FETCH_ENTITY_UAC_MODAL_SUCCES: 'FETCH_ENTITY_UAC_MODAL_SUCCES',
  OPEN_UAC_MODAL: 'OPEN_UAC_MODAL',
  CLOSE_UAC_MODAL: 'CLOSE_UAC_MODAL',
  TAB_CHANGE: 'TAB_CHANGE',
  FETCH_AUDIT_ADMIN_INFO: 'FETCH_AUDIT_ADMIN_INFO',
  FETCH_AUDIT_ADMIN_INFO_SUCCESS: 'FETCH_AUDIT_ADMIN_INFO_SUCCESS',
  OPEN_AUDITADMIN_MODAL: 'OPEN_AUDITADMIN_MODAL',
  OPEN_AUDITADMIN_MODAL_SUCCESS: 'OPEN_AUDITADMIN_MODAL_SUCCESS',
  OPEN_ADD_AUDITADMIN_MODAL_SUCCESS: 'OPEN_ADD_AUDITADMIN_MODAL_SUCCESS',
  SAVE_AUDIT_ADMIN: 'SAVE_AUDIT_ADMIN',
  FETCH_DOCUMENT_TYPE_USES_INFO: 'FETCH_DOCUMENT_TYPE_USES_INFO',
  FETCH_DOCUMENT_TYPE_USES_INFO_SUCCESS: 'FETCH_DOCUMENT_TYPE_USES_INFO_SUCCESS',
  CLOSE_AUDIT_ADMIN_MODAL: 'CLOSE_AUDIT_ADMIN_MODAL',
  RESET_FILTERS_AUDIT_ADMIN_INFO: 'RESET_FILTERS_AUDIT_ADMIN_INFO',
  FETCH_AUDIT_CONFIGURATION_DOCUMENTS: 'FETCH_AUDIT_CONFIGURATION_DOCUMENTS',
  DELETE_AUDIT_ADMIN: 'DELETE_AUDIT_ADMIN',
  FETCH_AUDITS_INFO: 'FETCH_AUDITS_INFO',
  RESET_FILTERS_AUDITS_INFO: 'RESET_FILTERS_AUDITS_INFO',
  FETCH_AUDITS_INFO_SUCCESS: 'FETCH_AUDITS_INFO_SUCCESS',
  DOCUMENT_AUDIT: 'DOCUMENT_AUDIT',
  DOCUMENT_AUDIT_FINISH: 'DOCUMENT_AUDIT_FINISH',
  FETCH_SEARCH_DOCUMENTS_INFO: 'FETCH_SEARCH_DOCUMENTS_INFO',
  FETCH_SEARCH_DOCUMENTS_INFO_SUCCESS: 'FETCH_SEARCH_DOCUMENTS_INFO_SUCCESS',
  OPEN_ZIP_MODAL_SUCCESS: 'OPEN_ZIP_MODAL_SUCCESS',
  OPEN_ZIP_MODAL: 'OPEN_ZIP_MODAL',
  CLOSE_ZIP_MODAL: 'CLOSE_ZIP_MODAL',
  RESET_FILTERS_SEARCH_DOCUMENT: 'RESET_FILTERS_SEARCH_DOCUMENT',
  CHANGE_CODE_COMMISSION_FLEET: 'CHANGE_CODE_COMMISSION_FLEET',
  CHANGE_CODE_COMMISSION_FLEET_SUCCESS: 'CHANGE_CODE_COMMISSION_FLEET_SUCCESS',
  FETCH_PAPERWORK_FLEET_LIST: 'FETCH_PAPERWORK_FLEET_LIST',
  FETCH_PAPERWORK_FLEET_LIST_SUCCESS: 'FETCH_PAPERWORK_FLEET_LIST_SUCCESS',
  FETCH_PAPERWORK_FLEET_COUNT_LIST: 'FETCH_PAPERWORK_FLEET_COUNT_LIST',
  FETCH_PAPERWORK_FLEET_COUNT_LIST_SUCCESS: 'FETCH_PAPERWORK_FLEET_COUNT_LIST_SUCCESS',
  FETCH_CHILDS_SUBTYPE_FLEET_COUNT_LIST: 'FETCH_CHILDS_SUBTYPE_FLEET_COUNT_LIST',
  FETCH_CHILDS_SUBTYPE_FLEET_COUNT_LIST_SUCCESS: 'FETCH_CHILDS_SUBTYPE_FLEET_COUNT_LIST_SUCCESS',
  FETCH_CHARGE_FLEET_LIST: 'FETCH_CHARGE_FLEET_LIST',
  FETCH_CHARGE_FLEET_LIST_SUCCESS: 'FETCH_CHARGE_FLEET_LIST_SUCCESS',
  FETCH_FLEET_ECONOMIC_SUCCESS: 'FETCH_FLEET_ECONOMIC_SUCCESS',
  MANAGEMENT_SECTION_CHANGE_SUCCESS: 'MANAGEMENT_SECTION_CHANGE_SUCCESS'
}
