import React, { Component } from 'react'
import { Form, OverlayTrigger, Button, Tooltip } from 'react-bootstrap'
import { Field, reduxForm, reset } from 'redux-form'
import InputText from '../commons/form/InputText'
import InputSelect from '../../../_v2/components/commons/form/InputSelect'
import InputDatePicker from '../../../_v2/components/commons/form/InputDatePicker'
import InputCheckbox from '../../../_v2/components/commons/form/InputCheckBox'
import SimpleTablePage from '../../../containers/commons/general/SimpleTablePage'
import IDocCarPagination from '../../../components/commons/iDocCarPagination'
import { Link } from 'react-router-dom'
import EntityPopover from '../../../_v2/components/search/EntityPopover'
import { clientInformation } from '../../../constants/backendIds'
import settings from '../../../setting'
import { fromCamelToUnderscore, getDateNowMaxDatePicker } from '../../../util/utils'
import { validateDate, validateDates } from '../../../util/validationFunctions'
import MapSectionsManagement from '../../../_v2/util/map-sections-management'
import { showFilter, hideFiltersBtn, toggleFilterClass, getIconClass } from '../../../_v2/util/management/ManagementUtils'

class SearchByFleetsForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      fields: {},
      dossierIdToArray: [],
      maxiTable: false,
      hideFilters: false
    }
  }

  validateDate (value, allValues, props) {
    const result = validateDate(value, props)
    return result === Object(result) ? undefined : result
  }

  submitSearchForm (values) {
    if (this.standardValidations(values)) {
      return this.props.actions.openModal('mustFillFilters')
    }

    this.props.actions.fetchFleets({ ...values.dossier, ...this.props.filters })

    this.setState({ dossierIdToArray: [], hideFilters: true, dynamicFilters: values, filterValues: { ...values.dossier, ...this.props.filters } })

    this.props.actions.setFilters(values)
  }

  uploadDocuments () {
    if (this.state.dossierIdToArray && this.state.dossierIdToArray.length > 0) {
      this.props.actions.openDocumentCreationModalFleets(this.state.dossierIdToArray, this.state.organizedUnitId)
    } else {
      this.props.actions.openModal('anyDossierMustBeSelected')
    }
  }

  standardValidations (values) {
    return !values.dossier || !Object.keys(values.dossier).filter(item => values.dossier[item] !== null).length
  }

  handleClickReset (e) {
    if (e !== undefined) {
      e.preventDefault()
    }

    this.props.dispatch(reset('SearchByFleetsForm'))
    this.state.dossierIdToArray = []
    this.setState({ onlyFleets: true, checkAll: false, dossierIdToArray: [], dossierData: [], fields: [] })
  }

  onPageChange (page) {
    this.setState({ filterValues: { ...this.state.filterValues, page: page } })
    this.props.actions.fetchFleets({ ...this.state.filterValues, page })
  }

  onOrderChange (e) {
    const newOrderBy = this.props.filters.orderBy.includes(`%2B${e.currentTarget.dataset.field}`) ? `-${e.currentTarget.dataset.field}` : `%2B${e.currentTarget.dataset.field}`
    this.setState({ filterValues: { ...this.state.filterValues, orderBy: newOrderBy } })
    this.props.actions.fetchFleets({ ...this.state.filterValues, orderBy: newOrderBy })
  }

  exportToExcel (data) {
    let cols = ''
    data.forEach(element => {
      cols += element + '$'
    })
    cols = cols.substring(0, cols.length - 1)
    let values = this.props.formValues
    this.props.actions.fetchFleetsDownload({ ...values.dossier, ...this.props.filters }, cols)
  }

  exportData () {
    const tKey = 'SEARCH.CATEGORIES.DOSSIER.'
    let columnsExcel = []

    columnsExcel.push(this.props.t(`${tKey}FLEET_CODE`) + '#FleetCode')
    columnsExcel.push(this.props.t(`${tKey}RENTAL_ORDER_NUMBER`) + '#RentalOrderNumber')
    columnsExcel.push(this.props.t(`${tKey}DOSSIER_NUMBER`) + '#Number')
    columnsExcel.push(this.props.t(`${tKey}CHASSIS`) + '#Chassis')
    columnsExcel.push(this.props.t(`${tKey}MODEL_SHORT`) + '#Model')
    columnsExcel.push(this.props.t('SEARCH.CATEGORIES.ENTITY.BY_ENTITY') + '#Entity')
    columnsExcel.push(this.props.t(`${tKey}DOSSIER_SUBTYPE`) + '#SubType')
    columnsExcel.push(this.props.t(`${tKey}DELIVERY_CITY`) + '#DeliveryCity')
    columnsExcel.push(this.props.t(`${tKey}STAGE`) + '#Stage')
    columnsExcel.push(this.props.t(`${tKey}SALESMAN_MANAGER`) + '#SalesManagerName')

    this.exportToExcel(columnsExcel)
  }

  extractValueComboYesNo (value) {
    if (value === true || value === 1) return this.props.t('SEARCH.CATEGORIES.DOSSIER.YES')
    if (value === false || value === 0) return this.props.t('SEARCH.CATEGORIES.DOSSIER.NO')
  }

  extractCombo (combo, filters, item, filter) {
    const selected = combo.find(object => object.id === filters[item][filter])
    let value = selected && selected.value
    return value
  }

  selectedFilters () {
    const filters = this.props.dynamicFilters
    const tKey = 'SEARCH.CATEGORIES.'
    return Object.keys(filters)
      .map((item) => {
        let text = item
        let value = null
        const list = []
        Object.keys(filters[item]).map((filter, index) => {
          text = this.props.t(`${tKey}${item.toUpperCase()}.${fromCamelToUnderscore(filter).toUpperCase()}`)
          switch (filter) {
            case 'salesmanIndividual':
            {
              value = this.extractCombo(this.props.combos.salesmanCombo, filters, item, filter)
              break
            }
            case 'salesmanManagerIndividual':
            {
              value = this.extractCombo(this.props.combos.salesmanCombo, filters, item, filter)
              break
            }
            case 'pickPreviousVehicle': {
              value = this.extractValueComboYesNo(filters[item][filter])
              break
            }
            case 'dateTo':
            case 'dateFrom': {
              const dateType = this.getDateTypeOptions().find(x => x.id === filters[item].dateType)?.value ?? ''
              text = text.replace('#DATETYPE#', dateType)
              value = filters[item][filter]
              break
            }
            default:
            { value = filters[item][filter]
              break }
          }

          if (value) {
            if (filter !== 'entityChildSelected') {
              list.push(<li key={index}>{text}: <strong>{value}</strong>;</li>)
            } else {
              list.push(<li key={index}> <strong>{text}</strong>;</li>)
            }
          }
        })
        return list
      })
  }

  toogleHideFilters () {
    this.setState({ hideFilters: !this.state.hideFilters })
  }

  toogleMaxiTable () {
    this.setState({ maxiTable: !this.state.maxiTable })
  }

  addDossierIdToArray (dossierId) {
    const arrayContainDossier = this.state.dossierIdToArray.includes(dossierId)
    if (!arrayContainDossier) {
      this.setState({ dossierIdToArray: [...this.state.dossierIdToArray, dossierId] })
    }
    const array = this.state.dossierIdToArray
    if (arrayContainDossier) {
      array.splice(array.findIndex(d => d === dossierId), 1)
      this.setState({ dossierIdToArray: array })
    }
  }

  render () {
    const {
      t, combos: { salesmanCombo }, fields, fieldFill, className, classNameSelect, hasSearch,
      pages, count, dossierData, vehicleData,
      showButtonUploadDocument,
      handleSubmit, filters: { page } // From search,s
    } = this.props
    const secMapManagement = MapSectionsManagement.find(function (s) { return s.page === 'searchByFleets' })
    const hasResults = dossierData && dossierData.length > 0
    const tKey = 'SEARCH.CATEGORIES.DOSSIER.'
    let yesNoCombo = this.props.combos.activeInactiveCombo
    yesNoCombo = yesNoCombo.map((activeInactiveCombo) => {
      return { ...activeInactiveCombo, value: t(`COMBOS.YES_NO.${activeInactiveCombo.value}`) }
    })
    return (
      <div className="inner-results-panel">
        <div className="section-results-header">
          <div className="section-header-title">
            <OverlayTrigger placement="right" overlay={<Tooltip id="editProductTooltip">{`${t((this.props.hideMenu ? 'GLOBAL.TABLES.SHOW_INNER_MENU' : 'GLOBAL.TABLES.HIDE_INNER_MENU'))}`}</Tooltip>}>
              <a onClick={() => this.props.toogleInnerMenu()}s className={this.props.hideMenu ? 'collapseBtn' : 'collapseBtn closeInnerMenu'}><i className={this.props.hideMenu ? 'iDocIcon-Open-inner-menu' : 'iDocIcon-Close-inner-menu'}></i></a>
            </OverlayTrigger>
            <h4>{t(`${secMapManagement.title}`)}
              <OverlayTrigger placement="left" overlay={<Tooltip id="editProductTooltip">{`${t((this.props.hideFilters ? 'GLOBAL.TABLES.SHOW_FILTERS' : 'GLOBAL.TABLES.HIDE_FILTERS'))}`}</Tooltip>}>
                <a onClick={() => this.toogleHideFilters()} className={'toogleShowFilters ' + hideFiltersBtn(hasResults)}><i className={toggleFilterClass(hasResults, this.state.hideFilters)}></i></a>
              </OverlayTrigger>
            </h4>
          </div>
          <Form autoComplete="off" onSubmit={handleSubmit((values) => this.submitSearchForm(values))} className="filters ">
            <div className={'search-form ' + (showFilter(hasResults, this.state.hideFilters) ? '' : 'hideFilters ')}>
              <Field
                component={InputSelect}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                id="salesmanIndividual"
                name='dossier.salesmanIndividual'
                placeholder={t(`${tKey}SALESMAN_INDIVIDUAL`)}
                controlLabel={t(`${tKey}SALESMAN_INDIVIDUAL`)}
                options={salesmanCombo}
                valueKey="id"
                labelKey="value"
                onInputChange={(val) => fieldFill({ salesmanIndividual: val })}
                customClass={fields.salesmanIndividual ? classNameSelect : ''}
              />
              <Field
                component={InputSelect}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                id="salesmanManagerIndividual"
                name='dossier.salesmanManagerIndividual'
                placeholder={t(`${tKey}SALESMAN_MANAGER_INDIVIDUAL`)}
                controlLabel={t(`${tKey}SALESMAN_MANAGER_INDIVIDUAL`)}
                options={salesmanCombo}
                valueKey="id"
                labelKey="value"
                onInputChange={(val) => fieldFill({ salesmanManagerIndividual: val })}
                customClass={fields.salesmanManagerIndividual ? className : ''}
              />
              <Field
                component={InputText}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                id="dossierNumberFrom"
                name='dossier.dossierNumberFrom'
                placeholder={t(`${tKey}DOSSIER_NUMBER_FROM`)}
                controlLabel={t(`${tKey}DOSSIER_NUMBER_FROM`)}
                type="text"
                onInputChange={(val) => fieldFill({ dossierNumberFrom: val })}
                customClass={fields.dossierNumberFrom ? className : ''}
              />
              <Field
                component={InputText}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                id="dossierNumberTo"
                name='dossier.dossierNumberTo'
                placeholder={t(`${tKey}DOSSIER_NUMBER_TO`)}
                controlLabel={t(`${tKey}DOSSIER_NUMBER_TO`)}
                type="text"
                onInputChange={(val) => fieldFill({ dossierNumberTo: val })}
                customClass={fields.dossierNumberTo ? className : ''}
              />
              <Field
                component={InputDatePicker}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                id="creationDateFrom"
                name='dossier.creationDateFrom'
                placeholder={t(`${tKey}CREATION_DATE_FROM`)}
                controlLabel={t(`${tKey}CREATION_DATE_FROM`)}
                validate={[this.validateDate]}
                onInputChange={(val) => fieldFill({ creationDateTo: val })}
                customClass={fields.creationDateTo ? className : ''}
                maxDate={getDateNowMaxDatePicker()}
              />
              <Field
                component={InputDatePicker}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                id="creationDateTo"
                name='dossier.creationDateTo'
                placeholder={t(`${tKey}CREATION_DATE_TO`)}
                controlLabel={t(`${tKey}CREATION_DATE_TO`)}
                validate={[this.validateDate]}
                onInputChange={(val) => fieldFill({ creationDateFrom: val })}
                customClass={fields.creationDateFrom ? className : ''}
                maxDate={getDateNowMaxDatePicker()}
              />
              <Field
                component={InputText}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                id="fleetCode"
                name='dossier.fleetCode'
                placeholder={t(`${tKey}FLEET_CODE`)}
                controlLabel={t(`${tKey}FLEET_CODE`)}
                onInputChange={(val) => fieldFill({ fleetCode: val })}
                customClass={fields.fleetCode ? className : ''}
              />
              <Field
                component={InputText}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                id="rentalOrderNumber"
                name='dossier.rentalOrderNumber'
                placeholder={t(`${tKey}RENTAL_ORDER_NUMBER`)}
                controlLabel={t(`${tKey}RENTAL_ORDER_NUMBER`)}
                onInputChange={(val) => fieldFill({ rentalOrderNumber: val })}
                customClass={fields.rentalOrderNumber ? className : ''}
              />
              <Field
                component={InputText}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                id="deliveryCity"
                name='dossier.deliveryCity'
                placeholder={t(`${tKey}DELIVERY_CITY`)}
                controlLabel={t(`${tKey}DELIVERY_CITY`)}
                onInputChange={(val) => fieldFill({ deliveryCity: val })}
                customClass={fields.deliveryCity ? className : ''}
              />
              {settings.clientFieldValidation !== clientInformation.VGR &&
                <Field
                  component={InputSelect}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="pickPreviousVehicle"
                  name='dossier.pickPreviousVehicle'
                  placeholder={t(`${tKey}PICK_PREVIOUS_VEHICLE`)}
                  controlLabel={t(`${tKey}PICK_PREVIOUS_VEHICLE`)}
                  options={yesNoCombo}
                  valueKey="id"
                  labelKey="value"
                  onInputChange={(val) => fieldFill({ pickPreviousVehicle: val })}
                  customClass={fields.pickPreviousVehicle ? className : ''}
                />
              }
              <div className='search-form-actions flexInLine al-R'>
                <Button id="btn_search"
                  type="submit"
                  className="_Btn accept stk">
                  <i className="iDocIcon-search"/>
                  {t('SEARCH.ACTIONS.SEARCH_BUTTON')}
                </Button>
                <Button
                  id="btn_reset"
                  onClick={(e) => this.handleClickReset(e)}
                  className={'_Btn' + (this.props.pristine ? ' accept' : ' accept')}
                  disabled={this.props.pristine}
                >
                  <i className="iDocIcon-clear-decline"/> {t('SEARCH.ACTIONS.RESET_BUTTON')}
                </Button>
              </div>
            </div>
          </Form>
        </div>
        <div className="section-results-body">
          <div className={'searchResoultsContainer' + (this.state.maxiTable ? ' maxiTable' : '')}>
            {hasSearch &&
              <div className="table-wrapper" key="table-wrapper">
                <ul className="list-inline pull-left">
                  {this.selectedFilters().length === 0 ? <li>{t(`${tKey}NO_SELECTED_FILTERS`)}</li> : (this.selectedFilters())}
                </ul>
                {/* table options, maximize, etc.... */}

                {hasResults &&
                  <div className='flexInLine al-R'>
                    <div className='actionsField'>
                    </div>
                    <OverlayTrigger placement="left" overlay={<Tooltip id="editProductTooltip">{`${t((this.state.maxiTable ? 'GLOBAL.TABLES.MINIMIZE_TABLE' : 'GLOBAL.TABLES.MAXIMIZE_TABLE'))}`}</Tooltip>}>
                      <a onClick={() => this.toogleMaxiTable()} className='_Btn accept stk o-icn maximizeBtn'><i className={(this.state.maxiTable ? 'iDocIcon-Minimize' : 'iDocIcon-Maximize')}></i></a>
                    </OverlayTrigger>
                    <OverlayTrigger placement="left" overlay={<Tooltip id="editProductTooltip">{t('SEARCH.ACTIONS.EXPORT')}</Tooltip>}>
                      <a onClick={(event) => this.exportData(event)} className='_Btn new o-icn exportToExcel'><i className="iDocIcon-file-excel"></i></a>
                    </OverlayTrigger>
                  </div>}
                {/* table */}
                {hasResults &&
                  <div className="table-responsive">
                    <SimpleTablePage
                      columns={[
                        <th key={0} data-field="fleetCode" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}FLEET_CODE`)}&nbsp;<i className={getIconClass('fleetCode', this.props.filters)}></i>
                        </th>,
                        <th key={1} data-field="rentalOrderNumber" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}RENTAL_ORDER_NUMBER`)}&nbsp;<i className={getIconClass('rentalOrderNumber', this.props.filters)}></i>
                        </th>,
                        <th key={2} data-field="number" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}DOSSIER_NUMBER`)}&nbsp;<i className={getIconClass('number', this.props.filters)}/>
                        </th>,
                        <th key={3} data-field="vinChassis" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}CHASSIS`)}&nbsp;<i className={getIconClass('vinChassis', this.props.filters)}></i>
                        </th>,
                        <th key={4} data-field="model" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}MODEL_SHORT`)}&nbsp;<i className={getIconClass('model', this.props.filters)}/>
                        </th>,
                        <th key={5} data-field="entity" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}ENTITY`)}&nbsp;<i className={getIconClass('entity', this.props.filters)}/>
                        </th>,
                        <th key={7} data-field="dossierSubType" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}DOSSIER_SUBTYPE`)}&nbsp;<i className={getIconClass('dossierSubType', this.props.filters)}/>
                        </th>,
                        <th key={6} data-field="deliveryCity" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}DELIVERY_CITY`)}&nbsp;<i className={getIconClass('deliveryCity', this.props.filters)}/>
                        </th>,
                        <th key={8} data-field="stage" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}STAGE`)}&nbsp;<i className={getIconClass('stage', this.props.filters)}/>
                        </th>,
                        <th key={9} data-field="salesmanManager" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}SALESMAN_MANAGER`)}&nbsp;<i className={getIconClass('salesmanManager', this.props.filters)}/>
                        </th>,
                        <th key={10} data-field="check"></th>
                      ]}
                      rows={dossierData && dossierData.map((row, idx) => (
                        <tr key={idx}>
                          <td>
                            {row.fleetCode}
                          </td>
                          <td>
                            {row.rentalOrderNumber}
                          </td>
                          <td>
                            <Link to={`/dossier${row.urlToNavigate}`}>
                              {row.number}
                            </Link>
                          </td>
                          <td>
                            {row.vehicle.vehicleVinChassis}
                          </td>
                          <td>
                            {row.vehicle.vehicleModel}
                          </td>
                          <td className="see-more">
                            <EntityPopover
                              t={t}
                              entity={row.entity}
                              half={(vehicleData.length || 1) / (idx + 1)}
                              idx={idx}
                              id={'popover-entity' + idx}
                              urgencyType={row.urgencyType}
                            />
                          </td>
                          <td>{row.dossierSubType}</td>
                          <td>{row.deliveryCity}</td>
                          <td>{row.stage}</td>
                          <td>{row.salesManagerName}</td>
                          <td>{
                            <Field
                              component={InputCheckbox}
                              name={'selectedCheck_' + row.dossierId}
                              disabled = {!showButtonUploadDocument}
                              onChange={() => { this.addDossierIdToArray(row.dossierId); this.setState({ organizedUnitId: row.organizedUnitId }) }}
                            />}
                          </td>
                        </tr>
                      ))}
                    />
                  </div>
                }
                {showButtonUploadDocument === true &&
                  <div key="search-footer-actions" className="search-footer-actions">
                    <Button
                      onClick={() => this.uploadDocuments() }
                      // bsStyle="default"
                      // bsSize="small"
                      className="_Btn new"
                      id='uploadSearchButtonIdFleets'
                      disabled={this.state.dossierIdToArray.length <= 0 }
                    >
                      <i className="iDocIcon-file-document" />
                      {t('SEARCH.CATEGORIES.DOSSIER.UPLOAD_DOCUMENT')}
                    </Button>
                  </div>
                }
                {hasResults &&
                <div className="search-footer" key="search-footer">
                  <IDocCarPagination
                    id="btn_pag_sales"
                    page={page}
                    pagesCount={pages}
                    totalRows= {count}
                    onSelectAction={(page) => this.onPageChange(page)}
                  />
                </div>
                }

                {!hasResults && <p className='no_results'>{t('GLOBAL.TABLES.NO_RESULTS')}</p>}
              </div>
            }
          </div>
        </div>
      </div>
    )
  }
}

const validate = (values, { t }) => {
  const errors = {}
  if (values) {
    if (values.dossier && values.dossier.chassis && values.dossier.chassis.length < settings.chassisMinCharacter) {
      errors.dossier = {}
      errors.dossier.chassis = t('SEARCH.CATEGORIES.ERRORS.VINCHASIS').replace('##MIN_CHARACTERS##', settings.chassisMinCharacter)
    }
    if (values.vehicle && values.vehicle.chassis && values.vehicle.chassis.length < settings.chassisMinCharacter) {
      errors.vehicle = {}
      errors.vehicle.chassis = t('SEARCH.CATEGORIES.ERRORS.VINCHASIS').replace('##MIN_CHARACTERS##', settings.chassisMinCharacter)
    }

    if (values.dossier) {
      errors.dossier = {}
      if (values.dossier && values.dossier.creationDateFrom && values.dossier.creationDateTo) {
        errors.dossier.creationDateTo = validateDates(t, values.dossier.creationDateFrom, values.dossier.creationDateTo)
      }
      if (values.dossier && values.dossier.orderDateFrom && values.dossier.orderDateTo) {
        errors.dossier.orderDateTo = validateDates(t, values.dossier.orderDateFrom, values.dossier.orderDateTo)
      }
      if (values.dossier && values.dossier.availabilityDateFrom && values.dossier.availabilityDateTo) {
        errors.dossier.availabilityDateTo = validateDates(t, values.dossier.availabilityDateFrom, values.dossier.availabilityDateTo)
      }
      if (values.dossier && values.dossier.vehicleLicensePlateDateFrom && values.dossier.vehicleLicensePlateDateTo) {
        errors.dossier.vehicleLicensePlateDateTo = validateDates(t, values.dossier.vehicleLicensePlateDateFrom, values.dossier.vehicleLicensePlateDateTo)
      }
    }

    if (values.servicePreparationDelivery && values.servicePreparationDelivery.requiredDateFrom && values.servicePreparationDelivery.requiredDateTo) {
      errors.servicePreparationDelivery = {}
      errors.servicePreparationDelivery.requiredDateTo = validateDates(t, values.servicePreparationDelivery.requiredDateFrom, values.servicePreparationDelivery.requiredDateTo)
    }

    if (values.charge && values.charge.chargeDateFrom && values.charge.chargeDateTo) {
      errors.charge = {}
      errors.charge.chargeDateTo = validateDates(t, values.charge.chargeDateFrom, values.charge.chargeDateTo)
    }
  }
  return errors
}

export default reduxForm({
  form: 'SearchByFleetsForm',
  validate
})(SearchByFleetsForm)
