import { put, call, select, takeEvery } from 'redux-saga/effects'
import { getAuth } from '../../selectors/access/auth'
import { showLoader, hideLoader } from '../../actions/common'
import { fetchDashboardSuccess, openUploadDocumentFleetModalSuccess, closeUploadDocumentFleetModalSuccess, fetchCreateDossierButtonSuccess, fetchDashboardButtonsSuccess, fetchDossierFavoritesSuccess, fetchDossierLastAccessedSuccess, fetchTaskFavoritesSuccess } from '../../actions/dashboard/dashboard'
import { fetchCombosSuccess } from '../../actions/combos/combos'
import dashboardActionTypes from '../../constants/actions/dashboard/dashboard'
import getSalesmanUserHierarchy from '../../services/salesman/getSalesmanUserHierarchy'
import getDashboard from '../../services/dossier/get/getDashboard'
import getCanCreateDossier from '../../services/dossier/get/getCanCreateDossier'
import getUserDashboardCreateButtons from '../../services/user/getUserDashboardCreateButtons'
import { handleError } from '../errors/errorManager'
import { yesNoModal } from '../modalsListeners/yesNoModal'
import postUploadDocumentFileService from '../../services/dashboard/postUploadFile'
import postUploadFileGeneric from '../../services/dashboard/postUploadFileGeneric'
import getDossierFavorites from '../../services/dashboard/getDossierFavorites'
import getDossierLastAccessed from '../../services/dashboard/getDossierLastAccessed'
import getTaskFavorites from '../../services/dashboard/getTaskFavorites'
import { getDashboardFavoriteTasks, getDashboardFavoriteDossiers, getDashboardLastAccessedDossiers } from '../../selectors/dashboard/dashboard'
import messageModalActions from '../../actions/modals/messageModal'
import putTaskFavorite from '../../services/favorites/putTaskFavorite'
import deleteTaskFavorite from '../../services/favorites/deleteTaskFavorite'
import putDossierFavorite from '../../services/favorites/putDossierFavorite'
import deleteDossierFavorite from '../../services/favorites/deleteDossierFavorite'

export function * fetchDashboard ({ organizedUnit, salesmanId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    organizedUnit = organizedUnit !== '0' ? organizedUnit : null
    const completeSalesmanCombo = yield call(getSalesmanUserHierarchy, auth.token, organizedUnit)
    salesmanId = salesmanId && salesmanId.length > 0 ? salesmanId : null
    const response = yield call(getDashboard, auth.token, organizedUnit, salesmanId)
    yield put(fetchCombosSuccess({
      completeSalesmanCombo
    }))
    yield put(fetchDashboardSuccess(response, salesmanId))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * openUploadDocumentFleetModal ({ organizedUnit, brand, isBuyAndSell, code }) {
  try {
    yield put(showLoader())
    yield put(openUploadDocumentFleetModalSuccess(organizedUnit, brand, isBuyAndSell, code))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchOpenUploadDocumentFleetModal () {
  yield takeEvery(dashboardActionTypes.OPEN_DOCUMENT_UPLOAD_MODAL, openUploadDocumentFleetModal)
}

export function * watchFetchDashboard () {
  yield takeEvery(dashboardActionTypes.FETCH_DASHBOARD, fetchDashboard)
}

export function * checkCanCloseDocumentModal ({ showAdvise, resolve }) {
  if (showAdvise) {
    const acceptCloseModal = yield call(yesNoModal, 'commonDossierSaveWarning')
    if (resolve) resolve(acceptCloseModal)
  } else {
    resolve(true)
  }
}

export function * watchCheckCanCloseUploadDocumentFleetModal () {
  yield takeEvery(dashboardActionTypes.CHECK_CAN_CLOSE_UPLOAD_MODAL, checkCanCloseDocumentModal)
}

export function * closeUploadDocumentFleetModal () {
  yield put(closeUploadDocumentFleetModalSuccess())
}

export function * watchCloseUploadDocumentFleetModal () {
  yield takeEvery(dashboardActionTypes.CLOSE_DOCUMENT_UPLOAD_MODAL, closeUploadDocumentFleetModal)
}

export function * uploadDocumentFleet ({ file, brand, resolve, isBuyAndSell, code }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    let response
    if (code) {
      response = yield call(
        postUploadFileGeneric,
        auth.token,
        file,
        code
      )
    } else {
      response = yield call(
        postUploadDocumentFileService,
        auth.token,
        file,
        brand,
        isBuyAndSell
      )
    }
    if (response) {
      yield put(hideLoader())
      const confirmation = yield call(yesNoModal, 'confirmExcelDocument', null, null, null, null, [{ id: '##NUM_DOSSIERS##', value: response.totalRecords }, { id: '##NUM_CORRECT##', value: response.correct }, { id: '##NUM_INCORRECT##', value: response.incorrect }, { id: '##ERRORS##', value: response.errors }])
      yield put(showLoader())
      if (confirmation) {
        yield put(closeUploadDocumentFleetModalSuccess())
        resolve(true)
      }
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDashboardUploadDocumentFleet () {
  yield takeEvery(dashboardActionTypes.UPLOAD_DOCUMENT_FLEET, uploadDocumentFleet)
}

export function * fetchCreateDossierButton ({ organizedUnit, salesmanId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const response = yield call(getCanCreateDossier, auth.token, organizedUnit)

    yield put(fetchCreateDossierButtonSuccess(response))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchCreateDossierButton () {
  yield takeEvery(dashboardActionTypes.FETCH_CREATE_DOSSIER_BUTTON, fetchCreateDossierButton)
}

export function * fetchDashboardCreateButtons () {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const response = yield call(getUserDashboardCreateButtons, auth.token)
    yield put(fetchDashboardButtonsSuccess(response))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchDashboardCreateButtons () {
  yield takeEvery(dashboardActionTypes.FETCH_CREATE_BUTTONS, fetchDashboardCreateButtons)
}

export function * fetchDossierFavorites () {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const response = yield call(getDossierFavorites, auth.token)
    yield put(fetchDossierFavoritesSuccess(response))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchDossierFavorites () {
  yield takeEvery(dashboardActionTypes.FETCH_DOSSIER_FAVORITES, fetchDossierFavorites)
}

export function * fetchDossierLastAccessed () {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const response = yield call(getDossierLastAccessed, auth.token)
    yield put(fetchDossierLastAccessedSuccess(response))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchDossierLastAccessed () {
  yield takeEvery(dashboardActionTypes.FETCH_DOSSIER_LAST_ACCESSED, fetchDossierLastAccessed)
}

export function * fetchTaskFavorites ({ organizedUnit }) {
  try {
    yield put(showLoader())
    organizedUnit = organizedUnit !== '0' ? organizedUnit : null
    const auth = yield select(getAuth)
    const response = yield call(getTaskFavorites, auth.token, organizedUnit)
    yield put(fetchTaskFavoritesSuccess(response))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchTaskFavorites () {
  yield takeEvery(dashboardActionTypes.FETCH_TASK_FAVORITES, fetchTaskFavorites)
}

export function * switchCardTaskFavorite ({ taskId, favoriteStatus }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    if (favoriteStatus) {
      yield call(deleteTaskFavorite, auth.token, taskId)
      yield put(messageModalActions.openSuccessMessageModal('MESSAGES.DELETE_TASK_FAVORITE_SUCCESS'))
    } else {
      yield call(putTaskFavorite, auth.token, taskId)
      yield put(messageModalActions.openSuccessMessageModal('MESSAGES.ADD_TASK_FAVORITE_SUCCESS'))
    }

    let dashboardTasks = JSON.parse(JSON.stringify(yield select(getDashboardFavoriteTasks)))
    dashboardTasks.tasks.forEach((taskItem) => {
      if (taskItem.taskId === taskId) {
        taskItem.isFavorite = !favoriteStatus
      }
    })
    yield put(fetchTaskFavoritesSuccess(dashboardTasks))

    yield put(hideLoader())
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSwitchCardTaskFavorite () {
  yield takeEvery(dashboardActionTypes.SWITCH_CARD_TASK_FAVORITE, switchCardTaskFavorite)
}

export function * switchCardDossierFavorite ({ dossierId, isHistorical, favoriteStatus }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    if (favoriteStatus) {
      yield call(deleteDossierFavorite, auth.token, dossierId, isHistorical)
      yield put(messageModalActions.openSuccessMessageModal('MESSAGES.DELETE_FAVORITE_SUCCESS'))
    } else {
      yield call(putDossierFavorite, auth.token, dossierId, isHistorical)
      yield put(messageModalActions.openSuccessMessageModal('MESSAGES.ADD_FAVORITE_SUCCESS'))
    }

    let favoriteDossiers = JSON.parse(JSON.stringify(yield select(getDashboardFavoriteDossiers)))
    let lastAccessedDossiers = JSON.parse(JSON.stringify(yield select(getDashboardLastAccessedDossiers)))
    favoriteDossiers.favorites.forEach((favorite) => {
      if (favorite.dossierId === dossierId && favorite.isHistorical === isHistorical) {
        favorite.isFavorite = !favoriteStatus
      }
    })
    lastAccessedDossiers.dossiers.forEach((dossier) => {
      if (dossier.dossierId === dossierId && dossier.isHistorical === isHistorical) {
        dossier.isFavorite = !favoriteStatus
      }
    })
    yield put(fetchDossierFavoritesSuccess(favoriteDossiers))
    yield put(fetchDossierLastAccessedSuccess(lastAccessedDossiers))

    yield put(hideLoader())
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSwitchCardDossierFavorite () {
  yield takeEvery(dashboardActionTypes.SWITCH_CARD_DOSSIER_FAVORITE, switchCardDossierFavorite)
}
