import mastersActionTypes from '../../../constants/actions/masters/masters'

function initialState () {
  return {
    paymentMethodList: [],
    languageList: [],
    paymentCount: 0,
    pagesCount: 0,
    paymentMethodId: null,
    paymentMethodFields: [],
    filter: {
      page: 1,
      orderBy: '',
      pageSize: 10
    },
    paymentMethodModal: {
      payment: null,
      showModal: false
    }
  }
}

function fetchPaymentMethodListSuccess (state, { paymentList, filter }) {
  return {
    ...state,
    paymentMethodList: paymentList.paymentMethodList,
    paymentCount: paymentList.paymentCount,
    pagesCount: paymentList.pagesCount,
    filter
  }
}

function openAddModalSuccess (state, { languageList }) {
  const initial = initialState()
  return {
    ...state,
    languageList,
    paymentMethodModal: {
      ...initial.paymentMethodModal,
      showModal: true
    }
  }
}

function openEditPaymentMethodModalSuccess (state, { languageList, paymentMethodFields, paymentMethodId }) {
  return {
    ...state,
    languageList,
    paymentMethodFields,
    paymentMethodId,
    paymentMethodModal: {
      showModal: true
    }
  }
}

function closeEditPaymentMethodModal (state) {
  const initial = initialState()
  return {
    ...state,
    paymentMethodId: null,
    languageList: [],
    paymentMethodModal: {
      ...initial.paymentMethodModal,
      showModal: false
    },
    paymentMethodFields: []
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case mastersActionTypes.FETCH_PAYMENT_METHOD_SUCCESS:
      return fetchPaymentMethodListSuccess(state, action)
    case mastersActionTypes.OPEN_EDIT_PAYMENT_METHOD_MODAL_SUCCESS:
      return openEditPaymentMethodModalSuccess(state, action)
    case mastersActionTypes.OPEN_ADD_PAYMENT_METHOD_MODAL_SUCCESS:
      return openAddModalSuccess(state, action)
    case mastersActionTypes.CLOSE_EDIT_PAYMENT_METHOD_MODAL:
      return closeEditPaymentMethodModal(state, action)
    default:
      return state
  }
}
