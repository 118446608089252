import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { submitProcessingAgency, showEditProcessingAgencyIntegrationModal } from '../../../../actions/masters/masters'
import ProcessingAgencyIntegrationDocumentsMasterModal from '../../../../components/masters/processing/processingAgencyIntegrations/ProcessingAgencyIntegrationDocumentsMasterModal'
import { fetchProcessingAgencyDocTypeUsesList } from '../../../../actions/masters/processingAgency/processingAgencyIntegrations/processingAgencyDocTypeUses'
import {
  fetchDocumentTypeUseCombo, fetchPaperworkType
} from '../../../../actions/combos/combos'

export function mapStateToProps (state) {
  const processingAgencyModal = state.processingAgency.processingAgencyIntegrationModal
  return {
    showModal: processingAgencyModal.showModal,
    docTypeUses: processingAgencyModal.docTypeUses,
    agency: processingAgencyModal.agency
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      submitProcessingAgency,
      showEditProcessingAgencyIntegrationModal,
      fetchProcessingAgencyDocTypeUsesList,
      fetchDocumentTypeUseCombo,
      fetchPaperworkType
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(ProcessingAgencyIntegrationDocumentsMasterModal))
