import apiFetch from '../apiFetch'

export default function (token, brandId, includeDeleted = false) {
  const queryParamsArray = [
    `includeDeleted=${includeDeleted}`,
    brandId ? `brandId=${brandId}` : null
  ]

  const queryParams = queryParamsArray.reduce(
    (result, query, idx) => result + (query ? (result ? `&${query}` : `?${query}`) : '')
    , ''
  )

  return apiFetch({ endPoint: 'carline' + queryParams, method: 'GET', body: null, token: token })
}
