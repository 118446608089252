import React, { PureComponent } from 'react'
import { Modal, Col, Table, Button, Tooltip, OverlayTrigger, FormGroup } from 'react-bootstrap'
import { reduxForm, Field } from 'redux-form'
import IDocCarPagination from '../../commons/iDocCarPagination'
import InputCheckbox from '../../commons/form/InputCheckBox'
import FinancingTypeModalPage from '../../../containers/masters/FinancingTypeModalPage'
class FinancingMasterModalProduct extends PureComponent {
  constructor () {
    super()
    this.state = {
    }
  }

  fetchProductList (value) {
    this.props.actions.fetchFinancingProductCompany(this.props.financing.financingCompanyId, { ...this.props.filter, page: value })
  }

  getSortIcon (column = '') {
    switch (this.props.filter.orderBy) {
      case '+' + column:
        return 'ico-sort-selected-asc'
      case '-' + column:
        return 'ico-sort-selected-desc'
      default:
        return 'ico-sort'
    }
  }

  changeOrder (field) {
    switch (this.props.filter.orderBy) {
      case `+${field}`:
        this.props.actions.fetchFinancingProductCompany(this.props.financing.financingCompanyId, { ...this.props.filter, orderBy: `-${field}` })
        break
      case `-${field}`:
        this.props.actions.fetchFinancingProductCompany(this.props.financing.financingCompanyId, { ...this.props.filter, orderBy: `+${field}` })
        break
      default:
        this.props.actions.fetchFinancingProductCompany(this.props.financing.financingCompanyId, { ...this.props.filter, orderBy: `-${field}` })
        break
    }
  }

  componentDidUpdate () {
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (this.props.financing != null && this.props.financing.financingCompanyId !== prevProps.financing?.financingCompanyId) {
      this.props.actions.fetchFinancingProductCompany(this.props.financing.financingCompanyId, this.props.filter)
    }
    return null
  }

  cleanUoCOmbo () {
    this.props.reset()
  }

  render () {
    const {
      t,
      showModal, productList, productListCount, pagesCount, financing
      , actions: { deleteFinancingTypeModal, closeEditFinancingModalProduct, recoverFinancingTypeModal, openFinancingTypeModal }, filter: { page }
    } = this.props
    const tKey = 'MASTERS.FINANCING_MASTERS.MODAL_PRODUCT.'
    const deleteTooltip = (<Tooltip id="deleteTooltip">{t(`${tKey}DELETE`)}</Tooltip>)
    const editTooltip = (<Tooltip id="editTooltip">{t(`${tKey}EDIT`)}</Tooltip>)
    const recoverTooltip = (<Tooltip id="recoverTooltip">{t(`${tKey}RECOVER`)}</Tooltip>)
    const finName = financing?.name
    return (
      <>
        <FinancingTypeModalPage/>
        <Modal className="role-modal languages-modal" show={showModal} onHide={closeEditFinancingModalProduct}>
          <form autoComplete="off" >
            <Modal.Header closeButton onHide={closeEditFinancingModalProduct}>
              <Modal.Title>{t(`${tKey}TITLE`)} - {finName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <FormGroup>
                <Table className="editable-table" hover responsive>
                  <thead>
                    <tr>
                      <th onClick={() => { this.changeOrder('name') }}>
                        {t(`${tKey}NAME`)}
                        <i className={this.getSortIcon('name')} />
                      </th>
                      <th onClick={() => { this.changeOrder('description') }}>
                        {t(`${tKey}DESCRIPTION`)}
                        <i className={this.getSortIcon('description')} />
                      </th>
                      <th onClick={() => { this.changeOrder('financingTypeProduct') }}>
                        {t('MASTERS.FINANCING_TYPE_PRODUCT.FINANCING_TYPE_PRODUCT')}
                        <i className={this.getSortIcon('financingTypeProduct')} />
                      </th>
                      <th onClick={() => { this.changeOrder('reactivable') }}>
                        {t(`${tKey}REACTIVABLE`)}
                        <i className={this.getSortIcon('reactivable')} />
                      </th>
                      <th onClick={() => { this.changeOrder('repurchaseAgreement') }}>
                        {t('MASTERS.FINANCING_TYPE_PRODUCT.REPURCHASE')}
                        <i className={this.getSortIcon('repurchaseAgreement')} />
                      </th>
                      <th style={{ width: '177px' }}>{t('DOSSIER_COMPONENTS.ACCESSORIES.ACTIONS')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {productList && productList.map((product, idx) => {
                      return (
                        <tr key={idx} className={(product.isDeleted ? 'is-deleted' : '')}>
                          <td className={(product.isDeleted ? 'is-deleted' : '')}>{product.name}</td>
                          <td className={(product.isDeleted ? 'is-deleted' : '')}>{product.description}</td>
                          <td className={(product.isDeleted ? 'is-deleted' : '')}>{product.financingTypeProduct}</td>
                          <td className={(product.isDeleted ? 'is-deleted' : '')}>
                            {
                              <Field
                                component={InputCheckbox}
                                name={'selectedCheck_' + product.financingTypeId}
                                checked = {product.reactivatable}
                                disabled= {true}
                                id={'selectedCheck_' + product.financingTypeId}
                              />}
                          </td>
                          <td className={(product.isDeleted ? 'is-deleted' : '')}>
                            {
                              <Field
                                component={InputCheckbox}
                                name={'selectedCheckRP_' + product.financingTypeId}
                                checked = {product.repurchaseAgreement}
                                disabled= {true}
                                id={'selectedCheckRP_' + product.financingTypeId}
                              />}
                          </td>
                          <td className={(product.isDeleted ? 'is-deleted' : '')}>
                            {!product.isDeleted ? (
                              <div>
                                <a id="editProductFinancingCompany" onClick={() => openFinancingTypeModal(product.financingTypeId, this.props.financing, product.financingTypeId)}>
                                  <OverlayTrigger placement="left" overlay={editTooltip}>
                                    <i className="ico-edit-white"/>
                                  </OverlayTrigger>
                                </a>
                                <a id="deleteProductFinancingCompany" onClick={() => deleteFinancingTypeModal(product.financingTypeId, this.props.financing.financingCompanyId)}>
                                  <OverlayTrigger placement="left" overlay={deleteTooltip}>
                                    <i className="ico-trash"/>
                                  </OverlayTrigger>
                                </a>
                              </div>
                            ) : (<a onClick={() => recoverFinancingTypeModal(product.financingTypeId, this.props.financing.financingCompanyId)}>
                              <OverlayTrigger placement="left" overlay={recoverTooltip}>
                                <i className="glyphicon glyphicon-repeat" />
                              </OverlayTrigger>
                            </a>)}
                          </td>
                        </tr>)
                    })
                    }
                    {(productList === null || productList === undefined || productList.length === 0) &&
                <tr>
                  <td colSpan={12}><h2>{t(`${tKey}RESULTS_NOT_FOUND`)}</h2></td>
                </tr>}
                  </tbody>
                </Table>
                <div className="section-users-footer">
                  <nav key={1} className="text-center">
                    <IDocCarPagination
                      id="btn_pag_Products"
                      page={page}
                      pagesCount={pagesCount}
                      totalRows={productListCount}
                      onSelectAction={(value) => this.fetchProductList(value)}
                    />
                  </nav>
                </div>
              </FormGroup>
            </Modal.Body>
            <Modal.Footer>
              <Col sm={12} className="btn-wrapper">
                <Button
                  id="role-modal-submit-button"
                  className="btn-standard"
                  type="button"
                  onClick={() => openFinancingTypeModal(null, this.props.financing, null)}>
                + {t(`${tKey}ADD`)}
                </Button>
              </Col>
            </Modal.Footer>
          </form>
        </Modal>
      </>
    )
  }
}

export default reduxForm({
  form: 'financingMasterModalProduct'
})(FinancingMasterModalProduct)
