import faqActionTypes from '../../constants/actions/faq/faq'

export function fetchSectionList () {
  return {
    type: faqActionTypes.FETCH_SECTION_LIST
  }
}

export function fetchSectionListSuccess (sections) {
  return {
    type: faqActionTypes.FETCH_SECTION_LIST_SUCCESS,
    sections
  }
}

export function fetchQuestion (questionId) {
  return {
    type: faqActionTypes.FETCH_QUESTION,
    questionId
  }
}

export function fetchQuestionSuccess (question) {
  return {
    type: faqActionTypes.FETCH_QUESTION_SUCCESS,
    question
  }
}

export function fetchSectionQuestions (sectionId) {
  return {
    type: faqActionTypes.FETCH_SECTION_QUESTION,
    sectionId
  }
}

export function fetchSectionQuestionsSuccess (questions) {
  return {
    type: faqActionTypes.FETCH_SECTION_QUESTION_SUCCESS,
    questions
  }
}

export function fetchQuestionsBySearch (search) {
  return {
    type: faqActionTypes.FETCH_QUESTIONS_BY_SEARCH,
    search
  }
}

export function fetchQuestionsBySearchSuccess (questions) {
  return {
    type: faqActionTypes.FETCH_QUESTIONS_BY_SEARCH_SUCCESS,
    questions
  }
}

export function voteQuestion (faqId, isPositive) {
  return {
    type: faqActionTypes.VOTE_QUESTION,
    faqId,
    isPositive
  }
}

export default {
  fetchSectionList,
  fetchSectionListSuccess,
  fetchQuestion,
  fetchQuestionSuccess,
  fetchSectionQuestions,
  fetchSectionQuestionsSuccess,
  fetchQuestionsBySearch,
  fetchQuestionsBySearchSuccess,
  voteQuestion
}
