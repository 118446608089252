import React, { PureComponent } from 'react'
import { Button, Modal, Form } from 'react-bootstrap'
import { Field, reduxForm } from 'redux-form'
import InputDatePicker from '../../commons/form/InputDatePicker'

class SetAvailabilityDateModal extends PureComponent {
  constructor () {
    super()
    this.state = {
      invalidDate: true
    }
  }

  submitAvailabilityDate (values) {
    this.props.saveServicesAvailabilityDate(values.availabilityDate)
  }

  getSnapshotBeforeUpdate () {
    return null
  }

  componentDidUpdate (prevProps) {
    if (prevProps.showModal !== this.props.showModal && this.props.showModal) {
      this.props.reset()
    }
  }

  handleDateChange (value) {
    this.setState({ invalidDate: !value })
  }

  componentWillUnmount () {
    this.props.reset()
  }

  render () {
    const {
      t, handleSubmit, showModal, closeModal
    } = this.props
    const tKey = 'SEARCH.CATEGORIES.PAP_SERVICES_MANAGER.'

    return (
      <Modal show={showModal}
        backdrop="static"
        keyboard={false}
        className="setAvailabilityDateModal"
        onHide={closeModal}>
        <Form autoComplete="off" onSubmit={handleSubmit(this.submitAvailabilityDate.bind(this))}>
          <Modal.Header closeButton={false} className="availabilityDateHeaderModal">
            <Modal.Title>{t(`${tKey}AVAILABILITY_DATE`)}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="availabilityDateBodyModal">
            <Field
              className="availabilityDateOnModal"
              colSm={12}
              id='availabilityDate'
              name= 'availabilityDate'
              placeholder={t(`${tKey}DATE`)}
              controlLabel={t(`${tKey}DATE`)}
              component={InputDatePicker}
              onChange={(value) => this.handleDateChange(value)}
              disabled={this.props.disabledDate}
            />
          </Modal.Body>
          <Modal.Footer className="availabilityDateFooterModal">
            <Button
              id = "acceptAndCloseModal"
              className="btn-Select"
              type="submit"
              bsSize = "sm"
              disabled = {this.props.pristine || this.state.invalidDate}>
              {t(`${tKey}APPLY`)}
            </Button>
            <Button
              id="closeModal"
              className="btn-exit"
              bsSize = "sm"
              onClick = {closeModal}>
              {t(`${tKey}CANCEL`)}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

    )
  }
}
export default reduxForm({
  form: 'SetAvailabilityDateModal'
})(SetAvailabilityDateModal)
