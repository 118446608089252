import { put, call, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { getAuth } from '../../selectors/access/auth'
import { getTemplateFilter } from '../../selectors/templates/templates'
import getTemplateListService from '../../services/templates/getTemplateList'
import putActiveTemplateService from '../../services/templates/putActiveTemplate'
import postDuplicateTemplateService from '../../services/templates/postDuplicateTemplate'
import deleteTemplateService from '../../services/templates/deleteTemplate'
import getTemplateService from '../../services/templates/getTemplate'
import getTemplateFile from '../../services/templates/getTemplateFile'
import postTemplateService from '../../services/templates/postTemplate'
import putTemplateService from '../../services/templates/putTemplate'
import postTemplateFieldService from '../../services/templates/postTemplateField'
import putTemplateFieldService from '../../services/templates/putTemplateField'
import deleteTemplateFieldService from '../../services/templates/deleteTemplateField'
import { showLoader, hideLoader } from '../../actions/common.js'
import messageModalActions from '../../actions/modals/messageModal'
import templatesActionTypes from '../../constants/actions/templates/templates'
import { fetchTemplateListSuccess, openEditTemplateModalSuccess, closeEditTemplateModalSuccess } from '../../actions/templates/templates'
import { handleError } from '../errors/errorManager'
import { yesNoModal } from '../modalsListeners/yesNoModal'
import { fetchTemplateFields } from '../combos/fetchTemplateTypes'
import downloadTemplateService from '../../services/templates/donwloadTemplate'

export function * fetchTemplateList ({ filter }) {
  try {
    yield put(showLoader())
    yield filter = yield filter || select(getTemplateFilter)
    const { templateTypeId } = filter
    const auth = yield select(getAuth)
    let templateList = []
    if (templateTypeId) {
      templateList = yield call(getTemplateListService, auth.token, templateTypeId)
    }
    yield put(fetchTemplateListSuccess(templateList, filter))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchTemplateList () {
  yield takeLatest(templatesActionTypes.FETCH_TEMPLATE_LIST, fetchTemplateList)
}

export function * setActiveTemplate ({ templateId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(putActiveTemplateService, auth.token, templateId)
    const filter = yield select(getTemplateFilter)
    let templateList = []
    if (filter.templateTypeId) {
      templateList = yield call(getTemplateListService, auth.token, filter.templateTypeId)
    }
    yield put(fetchTemplateListSuccess(templateList, filter))
    yield put(messageModalActions.openSuccessMessageModal('MESSAGES.TEMPLATE_SET_ACTIVE'))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSetActiveTemplate () {
  yield takeLatest(templatesActionTypes.SET_ACTIVE_TEMPLATE, setActiveTemplate)
}

export function * duplicateTemplate ({ templateId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(postDuplicateTemplateService, auth.token, templateId)
    const filter = yield select(getTemplateFilter)
    let templateList = []
    if (filter.templateTypeId) {
      templateList = yield call(getTemplateListService, auth.token, filter.templateTypeId)
    }
    yield put(fetchTemplateListSuccess(templateList, filter))
    yield put(messageModalActions.openSuccessMessageModal('MESSAGES.TEMPLATE_DUPLICATED'))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDuplicateTemplate () {
  yield takeLatest(templatesActionTypes.DUPLICATE_TEMPLATE, duplicateTemplate)
}

export function * deleteTemplate ({ templateId }) {
  try {
    const confirmed = yield call(yesNoModal, 'deleteTemplate')
    if (confirmed) {
      yield put(showLoader())
      const auth = yield select(getAuth)
      yield call(deleteTemplateService, auth.token, templateId)
      const filter = yield select(getTemplateFilter)
      let templateList = []
      if (filter.templateTypeId) {
        templateList = yield call(getTemplateListService, auth.token, filter.templateTypeId)
      }
      yield put(fetchTemplateListSuccess(templateList, filter))
      yield put(messageModalActions.openSuccessMessageModal('MESSAGES.TEMPLATE_DELETED'))
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDeleteTemplate () {
  yield takeLatest(templatesActionTypes.DELETE_TEMPLATE, deleteTemplate)
}

export function * openAddTemplateModal ({ templateTypeId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const newTemplate = yield call(postTemplateService, auth.token, templateTypeId)
    const templateData = yield call(getTemplateService, auth.token, newTemplate.id)
    yield put(openEditTemplateModalSuccess(newTemplate.id, templateData, true))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchOpenAddTemplateModal () {
  yield takeEvery(templatesActionTypes.OPEN_ADD_TEMPLATE_MODAL, openAddTemplateModal)
}

export function * openEditTemplateModal ({ templateId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const templateData = yield call(getTemplateService, auth.token, templateId)
    const templateFieldLoadedCombos = []
    if (templateData.fields && templateData.fields.length) {
      for (let i = 0; i < templateData.fields.length; i++) {
        if (templateFieldLoadedCombos.indexOf(templateData.fields[i].typeField) === -1) {
          yield templateFieldLoadedCombos.push(templateData.fields[i].typeField)
          yield call(fetchTemplateFields, { fieldTypeId: templateData.fields[i].typeField })
        }
      }
    }
    yield put(openEditTemplateModalSuccess(templateId, templateData))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchOpenEditTemplateModal () {
  yield takeEvery(templatesActionTypes.OPEN_EDIT_TEMPLATE_MODAL, openEditTemplateModal)
}

export function * submitTemplate ({ body }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(putTemplateService, auth.token, body)
    const templateData = yield call(getTemplateService, auth.token, body.templateId)
    yield put(openEditTemplateModalSuccess(body.templateId, templateData))
    yield put(messageModalActions.openSuccessMessageModal('MESSAGES.TEMPLATE_UPDATED'))
    const filter = yield select(getTemplateFilter)
    const { templateTypeId } = filter
    let templateList = []
    if (templateTypeId) {
      templateList = yield call(getTemplateListService, auth.token, templateTypeId)
    }
    yield put(fetchTemplateListSuccess(templateList, filter))
    yield put(closeEditTemplateModalSuccess())
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSubmitTemplate () {
  yield takeEvery(templatesActionTypes.SUBMIT_TEMPLATE, submitTemplate)
}

export function * closeEditTemplateModal ({ body }) {
  try {
    yield put(showLoader())
    const filter = yield select(getTemplateFilter)
    const { templateTypeId } = filter
    const auth = yield select(getAuth)
    const templateData = yield call(getTemplateService, auth.token, body.templateId)
    if (templateData.versionName === undefined) {
      yield call(deleteTemplateService, auth.token, templateData.templateId)
    }
    let templateList = []
    if (templateTypeId) {
      templateList = yield call(getTemplateListService, auth.token, templateTypeId)
    }
    yield put(fetchTemplateListSuccess(templateList, filter))
    yield put(closeEditTemplateModalSuccess())
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchCloseEditTemplateModal () {
  yield takeLatest(templatesActionTypes.CLOSE_EDIT_TEMPLATE_MODAL, closeEditTemplateModal)
}

export function * createTemplateField ({ templateId, templateFieldData, resolve, reject }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(postTemplateFieldService, auth.token, templateId, templateFieldData)
    if (resolve) yield call(resolve)
    const templateData = yield call(getTemplateService, auth.token, templateId)
    yield put(openEditTemplateModalSuccess(templateId, templateData))
    yield put(messageModalActions.openSuccessMessageModal('MESSAGES.TEMPLATE_FIELD_CREATED'))
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      yield call(reject, customHandlerError)
    }
    yield call(handleError, { error, customHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchCreateTemplateField () {
  yield takeLatest(templatesActionTypes.CREATE_TEMPLATE_FIELD, createTemplateField)
}

export function * updateTemplateField ({ templateId, templateFieldData, resolve, reject }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(putTemplateFieldService, auth.token, templateId, templateFieldData)
    if (resolve) yield call(resolve)
    const templateData = yield call(getTemplateService, auth.token, templateId)
    yield put(openEditTemplateModalSuccess(templateId, templateData))
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      yield call(reject, customHandlerError)
    }
    yield call(handleError, { error, customHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchUpdateTemplateField () {
  yield takeLatest(templatesActionTypes.UPDATE_TEMPLATE_FIELD, updateTemplateField)
}

export function * deleteTemplateField ({ templateId, templateFieldData }) {
  try {
    const confirmed = yield call(yesNoModal, 'deleteTemplateField')
    if (confirmed) {
      yield put(showLoader())
      const auth = yield select(getAuth)
      yield call(deleteTemplateFieldService, auth.token, templateId, templateFieldData.templateFieldId)
      const templateData = yield call(getTemplateService, auth.token, templateId)
      yield put(openEditTemplateModalSuccess(templateId, templateData))
      yield put(messageModalActions.openSuccessMessageModal('MESSAGES.TEMPLATE_FIELD_DELETED'))
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDeleteTemplateField () {
  yield takeLatest(templatesActionTypes.DELETE_TEMPLATE_FIELD, deleteTemplateField)
}

export function * fetchTemplateFile ({ templateId, languageId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(getTemplateFile, auth.token, templateId, languageId)
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchTemplateFile () {
  yield takeEvery(templatesActionTypes.FETCH_TEMPLATE_FILE, fetchTemplateFile)
}

export function * downloadTemplate ({ templateId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(downloadTemplateService, auth.token, templateId)
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDownloadTemplate () {
  yield takeEvery(templatesActionTypes.DONWLOAD_TEMPLATE_FILE, downloadTemplate)
}
