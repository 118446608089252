import React, { PureComponent } from 'react'
import { Row, Table, Button } from 'react-bootstrap'
import { Field, reduxForm } from 'redux-form'
import SelectInput from '../../../../commons/SelectInput'

class TabPane2 extends PureComponent {
  render () {
    return (
      <Row className="discount-tabpane">
        <div className="button-wrapper">
          <Button className="btn-standard-white">
            <i className="ico-plus" />Nuevo descuento
          </Button>
          <Button className="btn-standard-white">
            <i className="ico-check" /> Aprobar dirección
          </Button>
        </div>
        <Table responsive className="table-tabPane">
          <thead>
            <tr>
              <th>Nombres de descuento</th>
              <th>Descuento</th>
              <th>Importe</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Descuentos Marca</td>
              <td className="select-input-full-width">
                <Field
                  name='discounts'
                  placeholder='Descuentos'
                  component={SelectInput}
                  labelKey='discounts'
                  valueKey='discounts'
                />
              </td>
              <td>0,0</td>
              <td>
                <i className="ico-edit-white"></i>
                <i className="ico-trash"></i>
              </td>
            </tr>
            <tr>
              <td>Atención comercial</td>
              <td className="select-input-full-width">
                <Field
                  name='discounts'
                  placeholder='Descuentos'
                  component={SelectInput}
                  labelKey='discounts'
                  valueKey='discounts'
                />
              </td>
              <td>0,0</td>
              <td>
                <i className="ico-edit-white"></i>
                <i className="ico-trash"></i>
              </td>
            </tr>
            <tr>
              <td>Descuento fidelización</td>
              <td className="select-input-full-width">
                <Field
                  name='discounts'
                  placeholder='Descuentos'
                  component={SelectInput}
                  labelKey='discounts'
                  valueKey='discounts'
                />
              </td>
              <td>0,0</td>
              <td>
                <i className="ico-edit-white"></i>
                <i className="ico-trash"></i>
              </td>
            </tr>
            <tr>
              <td>Descuento Flota</td>
              <td className="select-input-full-width">
                <Field
                  name='discounts'
                  placeholder='Descuentos'
                  component={SelectInput}
                  labelKey='discounts'
                  valueKey='discounts'
                />
              </td>
              <td>0,0</td>
              <td>
                <i className="ico-edit-white"></i>
                <i className="ico-trash"></i>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>Sobre valoración</td>
              <td colSpan={2}>0,0</td>
            </tr>
            <tr>
              <td colSpan={2}><span>Total descuentos</span></td>
              <td colSpan={2}><span>0,0%</span></td>
            </tr>
          </tbody>
        </Table>
      </Row>
    )
  }
}

export default reduxForm({ form: 'newForm' })(TabPane2)
