import React, { PureComponent } from 'react'
import { reduxForm, Field } from 'redux-form'
import { Row, Table, Form, Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import InputSelect from '../commons/form/InputSelect'
import IDocCarPagination from '../commons/iDocCarPagination'
import TemplatesModalPage from '../../containers/templates/TemplatesModalPage'

class Templates extends PureComponent {
  constructor () {
    super()
    this.state = {
      templateTypeId: null
    }
  }

  onTemplateTypeChange (templateTypeId) {
    if (templateTypeId !== this.state.templateTypeId) {
      this.setState({ templateTypeId })
      this.props.actions.fetchTemplateList({ ...this.props.filter, templateTypeId })
    }
  }

  UNSAFE_componentWillMount () {
    this.props.actions.fetchTemplateTypes()
  }

  componentWillUnmount () {
    this.setState({ templateTypeId: null })
    this.props.actions.resetTemplate()
  }

  render () {
    const {
      t, templateList, pagesCount, filter, templatesCount = 0,
      filter: { page },
      combos: { templateTypesCombo },
      actions: { fetchTemplateList, setActiveTemplate, duplicateTemplate, deleteTemplate, openAddTemplateModal, openEditTemplateModal }
    } = this.props
    const tKey = 'TEMPLATES.'
    const setActiveTooltip = (<Tooltip id="setActiveTooltip">{t(`${tKey}TABLE.SET_ACTIVE`)}</Tooltip>)
    const editTooltip = (<Tooltip id="editTooltip">{t(`${tKey}TABLE.EDIT`)}</Tooltip>)
    const duplicateTooltip = (<Tooltip id="duplicateTooltip">{t(`${tKey}TABLE.DUPLICATE`)}</Tooltip>)
    const deleteTooltip = (<Tooltip id="deleteTooltip">{t(`${tKey}TABLE.DELETE`)}</Tooltip>)
    return (
      <div className="search-wrapper templates-wrapper">
        <TemplatesModalPage />
        <div className="search-header">
          <div className="title-user">
            <i className="ico-print"/> <h2>{t(`${tKey}TITLE`)}</h2>
          </div>
        </div>
        <div className="filters-search-wrapper">
          <Form autoComplete="off" className="filters">
            <Row>
              <Field
                id="templateTypeId"
                name='templateTypeId'
                placeholder={t(`${tKey}TEMPLATE_TYPE`)}
                controlLabel={t(`${tKey}TEMPLATE_TYPE`)}
                component={InputSelect}
                onInputChange={this.onTemplateTypeChange.bind(this)}
                options={templateTypesCombo}
                valueKey="id"
                labelKey="value"
              />
            </Row>
          </Form>
        </div>
        {(templateList && templateList.length > 0) || this.state.templateTypeId ? [
          <div className="table-wrapper" key="table-wrapper">
            <Table className="editable-table" hover responsive>
              <thead>
                <tr>
                  <th>{t(`${tKey}TABLE.DESCRIPTION`)}</th>
                  <th>{t(`${tKey}TABLE.LAST_MODIFICATION_DATE`)}</th>
                  <th className="text-center">{t(`${tKey}TABLE.ACTIVE`)}</th>
                  <th className="text-center" style={{ width: '120px' }}>{t(`${tKey}TABLE.ACTIONS`)}</th>
                </tr>
              </thead>
              <tbody>
                {templateList && templateList.length > 0 && templateList.map((template, idx) => {
                  return (
                    <tr key={template.versionId}>
                      <td>{template.versionName}</td>
                      <td>{template.lastUpdateDate}</td>
                      <td className="text-center">{template.isCurrent && (<i className="ico-check static-icon"/>)}</td>
                      <td className="text-right">
                        {!template.isCurrent && (
                          <a onClick={() => setActiveTemplate(template.versionId)}>
                            <OverlayTrigger placement="left" overlay={setActiveTooltip}>
                              <i className="ico-check"/>
                            </OverlayTrigger>
                          </a>
                        )}
                        <a onClick={() => openEditTemplateModal(template.versionId)}>
                          <OverlayTrigger placement="left" overlay={editTooltip}>
                            <i className="ico-edit-white"/>
                          </OverlayTrigger>
                        </a>
                        <a onClick={() => duplicateTemplate(template.versionId)}>
                          <OverlayTrigger placement="left" overlay={duplicateTooltip}>
                            <i className="ico-docs"/>
                          </OverlayTrigger>
                        </a>
                        {!template.isCurrent && (
                          <a onClick={() => deleteTemplate(template.versionId)}>
                            <OverlayTrigger placement="left" overlay={deleteTooltip}>
                              <i className="ico-trash"/>
                            </OverlayTrigger>
                          </a>
                        )}
                      </td>
                    </tr>
                  )
                })}
                <tr className="button-table-wrapper">
                  <td>
                    <Button
                      className="btn-editable-table btn-standard"
                      onClick={() => openAddTemplateModal(this.state.templateTypeId) }>
                    + {t(`${tKey}TABLE.ADD`)}
                    </Button>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>,
          <div className="section-users-footer" key="section-users-footer">
            <nav key={1} className="text-center">
              <IDocCarPagination
                id="btn_pag_sales"
                page={page}
                pagesCount={pagesCount}
                totalRows={templatesCount}
                onSelectAction={(value) => fetchTemplateList({ ...filter, page: value })}
              />
            </nav>
          </div>
        ] : (
          <h4>
            {t(`${tKey}TABLE.TEMPLATE_TYPE_REQUIRED`)}
          </h4>
        )}
      </div>
    )
  }
}

export default reduxForm({
  form: 'templatesSearch'
})(Templates)
