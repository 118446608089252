import castellano from '../../translations/castellano.json'
import castellanoMx from '../../translations/castellanoMx.json'
import catalan from '../../translations/catalan.json'
import ingles from '../../translations/ingles.json'
import portugues from '../../translations/portugues.json'
import italiano from '../../translations/italiano.json'
import frances from '../../translations/frances.json'

export default function ({ languageId = 1, localeName = 'es-ES' }) {
  let messages = {}
  localStorage.setItem('locale', languageId)
  localStorage.setItem('localeName', localeName)
  const locale = localStorage.locale
  switch (locale) {
    case '7':
      messages = frances
      break
    case '6':
      messages = italiano
      break
    case '5':
      messages = castellanoMx
      break
    case '4':
      messages = portugues
      break
    case '3':
      messages = ingles
      break
    case '2':
      messages = catalan
      break
    case '1':
    default:
      messages = castellano
      break
  }
  return { messages, locale }
}
