import React, { PureComponent } from 'react'
import { urgencyTypesClass } from '../../constants/backendIds'
import { Link } from 'react-router-dom'

class RelatedDossierList extends PureComponent {
  render () {
    if (this.props.dossierRelatedDossiers.length > 0) {
      return (
        this.props.dossierRelatedDossiers.map((relatedDossier, idx) => {
          return (
            <tr key={idx}>
              <td>
                <span className={'color-point ' + urgencyTypesClass[relatedDossier.urgencyType]}></span>
              </td>
              <td>
                {
                  relatedDossier.urlToNavigate ? (
                    <Link to={`/dossier${relatedDossier.urlToNavigate}`}>
                      {relatedDossier.number}
                    </Link>
                  ) : relatedDossier.number
                }
              </td>
              <td>{relatedDossier.organizedUnit}</td>
              <td>{relatedDossier.dossierSubType}</td>
              <td>{relatedDossier.stage}</td>
            </tr>
          )
        })
      )
    } else {
      return null
    }
  }
}

export default RelatedDossierList
