import { call, put, select, takeLatest } from 'redux-saga/effects'
import { getAuth } from '../../selectors/access/auth'
import getTemplateTypesService from '../../services/templates/getTemplateTypes'
import getTemplateFieldsService from '../../services/templates/getTemplateFields'
import { showLoader, hideLoader } from '../../actions/common'
import messageModalActions from '../../actions/modals/messageModal'
import { fetchCombosSuccess } from '../../actions/combos/combos'
import combosActionTypes from '../../constants/actions/combos/combos'
import { handleError } from '../errors/errorManager'

export function * fetchTemplateTypes () {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const templateTypesCombo = yield call(getTemplateTypesService, auth.token)
    yield put(fetchCombosSuccess({ templateTypesCombo }))
  } catch (error) {
    const customHandler = function * () {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.FETCH_COMBO_ERROR'))
    }
    yield call(handleError, { error, customHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchTemplateTypes () {
  yield takeLatest(combosActionTypes.FETCH_TEMPLATE_TYPES, fetchTemplateTypes)
}

export function * fetchTemplateFields ({ fieldTypeId, resolve, reject }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const templateFieldCombos = JSON.parse(JSON.stringify(yield select(state => state.combos.templateFieldCombos)))
    templateFieldCombos[fieldTypeId] = []
    if (fieldTypeId) templateFieldCombos[fieldTypeId] = yield call(getTemplateFieldsService, auth.token, fieldTypeId)
    yield put(fetchCombosSuccess({ templateFieldCombos }))
    if (resolve) yield resolve()
  } catch (error) {
    const customHandler = function * () {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.FETCH_COMBO_ERROR'))
      if (reject) yield reject()
    }
    yield call(handleError, { error, customHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchTemplateFields () {
  yield takeLatest(combosActionTypes.FETCH_TEMPLATE_FIELDS, fetchTemplateFields)
}
