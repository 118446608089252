
import React, { PureComponent } from 'react'
import { Button, Modal, Col, Row, FormGroup } from 'react-bootstrap'
import { reduxForm, Field } from 'redux-form'
import TextInput from '../commons/TextInput'

class PresentsModal extends PureComponent {
  submitPresents (values) {
    Object.keys(values).map(item => {
      if (values[item].languageId === undefined || values[item].languageId === null && (values[item].description !== null || values[item].name !== null)) {
        values[item].languageId = item.slice(-1)
      }
    })
    const body = []
    Object.keys(values).map(item => {
      body.push(values[item])
    })
    this.props.actions.savePresentsModal(this.props.uoId, body, this.props.presentsId)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (this.props.presentTypeLanguages !== nextProps.presentTypeLanguages &&
      nextProps.presentTypeLanguages.length > 0) {
      nextProps.languageList.map((language) => {
        this.props.change(`lang${language.languageId}.languageId`, language.languageId)
        const source = nextProps.presentTypeLanguages.find((sourceChannel) => sourceChannel.languageId === language.languageId)
        this.props.change(`lang${language.languageId}.name`, source ? source.name : '')
        this.props.change(`lang${language.languageId}.description`, source ? source.description : '')
      })
    }
  }

  render () {
    const { t, showPresentsModal, handleSubmit, languageList = [] } = this.props
    return (
      <Modal show={showPresentsModal} onHide={this.props.actions.closePresentsModal} className="languages-modal">
        <Modal.Header closeButton>
          <i className="ico-users" /><Modal.Title>{t('MASTERS.PRESENTS_MASTERS.PRESENTS')}</Modal.Title>
        </Modal.Header>
        <form autoComplete="off" onSubmit={handleSubmit(this.submitPresents.bind(this))}>
          <Modal.Body>
            <Row className="title-column">
              <Col sm={4}>
                <h2>{t('MASTERS.NAME')}</h2>
              </Col>
              <Col sm={6}>
                <h2>{t('MASTERS.DESCRIPTION')}</h2>
              </Col>
              <Col sm={2}>
                <h2>{t('MASTERS.LANGUAGE')}</h2>
              </Col>
            </Row>
            <FormGroup>
              {languageList.map((val, idx) => {
                return (
                  <Row key={idx} className="inputs-wrapper">
                    <Col sm={4} className="custom-width-col">
                      <Field
                        name={`lang${val.languageId}.name`}
                        placeholder={t('MASTERS.NAME')}
                        component={TextInput}
                      />
                    </Col>
                    <Col sm={6} className="custom-width-col">
                      <Field
                        name={`lang${val.languageId}.description`}
                        placeholder={t('MASTERS.DESCRIPTION')}
                        component={TextInput}
                      />
                    </Col>
                    <Col sm={2} className="language-column">
                      <p>{`${val.description} (${val.name})`}</p>
                    </Col>
                  </Row>
                )
              })}
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <Col sm={6} className="btn-wrapper" smPush={6}>
              <Button type="submit" className="btn-standard">
                {t('MASTERS.SAVE')}
              </Button>
            </Col>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

const validate = (values, props) => {
  const errors = {}
  props.languageList.map(language => {
    errors[`lang${language.languageId}`] = {}
    if (values[`lang${language.languageId}`]) {
      if (!(values[`lang${language.languageId}`].name)) {
        errors[`lang${language.languageId}`].name = props.t('MASTERS.VALIDATION.NAME_REQUIRED')
      }
      if (!(values[`lang${language.languageId}`].description)) {
        errors[`lang${language.languageId}`].description = props.t('MASTERS.VALIDATION.DESCRIPTION_REQUIRED')
      }
    } else {
      errors[`lang${language.languageId}`].name = props.t('MASTERS.VALIDATION.NAME_REQUIRED')
      errors[`lang${language.languageId}`].description = props.t('MASTERS.VALIDATION.DESCRIPTION_REQUIRED')
    }
  })
  return errors
}

export default reduxForm({
  form: 'editPresents',
  validate
})(PresentsModal)
