import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import SearchByProcessingAgencyForm from '../../components/search/SearchByProcessingAgencyForm'
import { fetchProcessingAgencies, fetchProcessingAgenciesDownload, setFilters, resetFilters, setHideFilters } from '../../../actions/search/search'
import { translate } from 'react-polyglot'
import { openModal } from '../../../actions/common'

export function mapStateToProps (state) {
  return {
    ...state.search,
    // filters: state.search.dynamicFilters.processingAgency, // esto seguramente habrá que quitarlo
    combos: {
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchProcessingAgencies,
      fetchProcessingAgenciesDownload,
      setFilters,
      resetFilters,
      setHideFilters,
      openModal
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(SearchByProcessingAgencyForm))
