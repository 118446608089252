import React, { PureComponent } from 'react'
import { Row, Col } from 'react-bootstrap'

class Tab2 extends PureComponent {
  render () {
    return (
      <Col sm={12}>
        <Row>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Compra VN/VO:</span>
              <span className="second">Compra VN/VO</span>
            </div>
          </Col>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Tipo pedido compra:</span>
              <span className="second">Tipo pedido compra</span>
            </div>
          </Col>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Orden de producción:</span>
              <span className="second">Orden de producción</span>
            </div>
          </Col>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Fecha producción:</span>
              <span className="second">Fecha producción</span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">V.O.N/W.O.M:</span>
              <span className="second">V.O.N/W.O.M</span>
            </div>
          </Col>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Fecha prevista factura:</span>
              <span className="second">Fecha prevista factura</span>
            </div>
          </Col>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Fecha/hora entrega:</span>
              <span className="second">Fecha/hora entrega</span>
            </div>
          </Col>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">BOX:</span>
              <span className="second">BOX</span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Ubicación entrega:</span>
              <span className="second">Ubicación entrega</span>
            </div>
          </Col>
        </Row>
      </Col>
    )
  }
}

export default Tab2
