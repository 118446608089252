const tKey = 'DOSSIER_COMPONENTS.DELIVERY.CHECKLIST_MODAL.'

export const yesNoOptions = [
  { id: 'yes', label: 'GLOBAL.BUTTONS.YES', value: true },
  { id: 'no', label: 'GLOBAL.BUTTONS.NO', value: false }
]

export const telMailOptions = [
  { id: 'yes', label: `${tKey}TELEPHONE_SERVICE`, value: true },
  { id: 'no', label: `${tKey}VIA_MAIL`, value: false }
]

export const expNotApOptions = [
  { id: 'yes', label: `${tKey}EXPLAINED`, value: true },
  { id: 'no', label: `${tKey}DOES_NOT_APPLY`, value: false }
]

export const expNotProOptions = [
  { id: 'yes', label: `${tKey}EXPLAINED`, value: true },
  { id: 'no', label: `${tKey}NOT_APPLICABLE`, value: false }
]

export const oriProvOptions = [
  { id: 'yes', label: `${tKey}ORIGINALS`, value: true },
  { id: 'no', label: `${tKey}PROVISIONAL_TRAFFIC`, value: false }
]

export const oriCopyOptions = [
  { id: 'yes', label: `${tKey}ORIGINAL`, value: true },
  { id: 'no', label: `${tKey}COPY`, value: false }
]

export const penNotApOptions = [
  { id: 'yes', label: `${tKey}ACTIVATION_PENDING`, value: true },
  { id: 'no', label: `${tKey}DOES_NOT_APPLY`, value: false }
]

export const yesNoApOptions = [
  { id: 'yes', label: 'GLOBAL.BUTTONS.YES', value: true },
  { id: 'no', label: `${tKey}DOES_NOT_APPLY`, value: false }
]

export const firstSecondOptions = [
  { id: 'yes', label: `${tKey}FIRST`, value: true },
  { id: 'no', label: `${tKey}SECOND`, value: false }
]
