import React, { PureComponent } from 'react'
import { Modal, Col, Row, Button } from 'react-bootstrap'
import { reduxForm, Field } from 'redux-form'
import InputSelect from '../../commons/form/InputSelect'
import InputText from '../../commons/form/InputText'
import InputNumber from '../../commons/form/InputNumber'
import { isJsonString } from '../../../util/utils'

class SignatureEntityMasterModal extends PureComponent {
  constructor () {
    super()
    this.state = {}
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevProps.showModal !== this.props.showModal && this.props.showModal === true) {
      this.props.initialize(this.props.signatureEntity)
    }
  }

  submitSignatureEntity (values) {
    const signatureEntity = {
      order: values.order,
      signTypeId: values.signTypeId,
      signEntityTypeId: values.signEntityTypeId,
      coordinatePages: values.coordinatePages,
      signEntityId: this.props.signatureEntity?.signEntityId,
      signDocumentConfigurationId: this.props.signatureEntity?.signDocumentConfigurationId
    }
    this.props.actions.submitSignatureEntity(signatureEntity)
  }

  render () {
    const {
      t, handleSubmit,
      showModal,
      combos: { signTypeCombo, signEntityTypeCombo },
      actions: {
        closeSignatureEntityMasterModal
      }
    } = this.props
    const tKey = 'MASTERS.SIGNATURE_CONFIG_MASTERS.MODAL.'

    return (
      <Modal
        className="role-modal languages-modal"
        show={showModal}
        onHide={closeSignatureEntityMasterModal}
        backdrop={'static'}>
        <form autoComplete="off"
          onSubmit={handleSubmit(this.submitSignatureEntity.bind(this))}>
          <Modal.Header
            closeButton
            onHide={closeSignatureEntityMasterModal}
          >
            <Modal.Title>{t(`${tKey}SIGN_ENTITY`)}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="inputs-wrapper">
              <Row className="modal-input-wrapper" >
                <Col sm={4} className="custom-width-col">
                  <h2>{t(`${tKey}ORDER`)}</h2>
                  <Field
                    id="order"
                    name="order"
                    placeholder={t(`${tKey}ORDER`)}
                    valueKey="id"
                    labelKey="value"
                    component={InputNumber}
                    colSm={0}
                  />
                </Col>
                <Col sm={4} className="custom-width-col">
                  <h2>{t(`${tKey}SIGNATURE_TYPE`)}</h2>
                  <Field
                    name="signTypeId"
                    placeholder={t(`${tKey}SIGNATURE_TYPE`)}
                    valueKey="id"
                    labelKey="value"
                    component={InputSelect}
                    colSm={0}
                    options={signTypeCombo}
                  />
                </Col>
                <Col sm={4} className="custom-width-col">
                  <h2>{t(`${tKey}SIGNATURE_ENTITY_TYPE`)}</h2>
                  <Field
                    name="signEntityTypeId"
                    placeholder={t(`${tKey}SIGNATURE_ENTITY_TYPE`)}
                    valueKey="id"
                    labelKey="value"
                    component={InputSelect}
                    colSm={0}
                    options={signEntityTypeCombo}
                  />
                </Col>
              </Row>
              <Row className="modal-input-wrapper">
                <Col sm={8} className="custom-width-col">
                  <h2>{t(`${tKey}ADVANCED_OPTIONS`)}</h2>
                  <Field
                    id="coordinatePages"
                    name="coordinatePages"
                    placeholder={t(`${tKey}ADVANCED_OPTIONS`)}
                    component={InputText}
                    colSm={0}
                  />
                </Col>
              </Row>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Col sm={12} className="btn-wrapper">
              <Button
                id="role-modal-submit-button"
                className="btn-danger"
                type="reset"
                onClick={closeSignatureEntityMasterModal}
              >
                {t(`${tKey}CANCEL_BUTTON`)}
              </Button>
              <Button
                id="role-modal-submit-button"
                className="btn-standard"
                type="submit"
                disabled={this.props.pristine}>
                {t(`${tKey}SUBMIT_BUTTON`)}
              </Button>
            </Col>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default reduxForm({
  form: 'editSignatureEntity',
  validate: (values, props) => {
    let error = {}
    const tKey = 'MASTERS.SIGNATURE_CONFIG_MASTERS.VALIDATION.'
    if (!values.coordinatePages) {
      error.coordinatePages = props.t(`${tKey}ADVANCED_OPTIONS_REQUIRED`)
    }
    if (values.coordinatePages && !isJsonString(values.coordinatePages)) {
      error.coordinatePages = props.t(`${tKey}ADVANCED_OPTIONS`)
    }
    if (!values.order) {
      error.order = props.t(`${tKey}ORDER`)
    }
    if (!values.signEntityTypeId) {
      error.signEntityTypeId = props.t(`${tKey}SIGN_ENTITY_TYPE`)
    }

    return Object.keys(error).length > 0 ? error : {}
  }
})(SignatureEntityMasterModal)
