import { call, put, select, takeLatest } from 'redux-saga/effects'
import { getAuth } from '../../selectors/access/auth'
import getDeliveryLocation from '../../services/deliveryLocation/getDeliveryLocations'
import { showLoader, hideLoader } from '../../actions/common'
import messageModalActions from '../../actions/modals/messageModal'
import { fetchDeliveryLocationSuccess } from '../../actions/combos/combos'
import combosActionTypes from '../../constants/actions/combos/combos'
import { handleError } from '../errors/errorManager'

export function * fetchDeliveryLocations ({ organizedUnitId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const combo = yield call(getDeliveryLocation, organizedUnitId, auth.token)
    yield put(fetchDeliveryLocationSuccess(combo))
  } catch (error) {
    const customHandler = function * () {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.FETCH_COMBO_ERROR'))
    }
    yield call(handleError, { error, customHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchDeliveryLocations () {
  yield takeLatest(combosActionTypes.FETCH_DELIVERY_LOCATION, fetchDeliveryLocations)
}
