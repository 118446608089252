import { put, call, select, takeEvery } from 'redux-saga/effects'
import { showLoader, hideLoader } from '../../actions/common.js'
import { fetchUsageReportSuccess, fetchUsageInfoSuccess } from '../../actions/reports/usageReport.js'
import usageActionTypes from '../../constants/actions/reports/usageReport.js'
import { handleError } from '../errors/errorManager.js'
import { getAuth } from '../../selectors/access/auth.js'
import { getUsageFilter } from '../../selectors/reports/usageReport.js'
import postUsageListService from '../../services/reports/postUsageList.js'
import getDownloadUsageList from '../../services/reports/getDownloadUsageList.js'
import postUsageDetailService from '../../services/reports/postUsageDetail.js'
import getDownloadUsageDetail from '../../services/reports/getDownloadUsageDetail.js'

export function * fetchUsageList ({ filter }) {
  try {
    yield put(showLoader())
    yield filter = yield filter || select(getUsageFilter)
    const auth = yield select(getAuth)
    const usageList = yield call(postUsageListService, auth.token, filter)
    yield put(fetchUsageReportSuccess(usageList, filter))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchUsageList () {
  yield takeEvery(usageActionTypes.FETCH_USAGE_REPORT, fetchUsageList)
}

export function * downloadUsageList ({ filter }) {
  try {
    yield put(showLoader())
    yield filter = yield filter || select(getUsageFilter)
    const auth = yield select(getAuth)
    yield call(getDownloadUsageList, auth.token, filter)
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDownloadUsageList () {
  yield takeEvery(usageActionTypes.DOWNLOAD_USAGE_REPORT, downloadUsageList)
}

export function * fetchUsageInfo ({ filter }) {
  try {
    yield put(showLoader())
    yield filter = yield filter || select(getUsageFilter)
    const auth = yield select(getAuth)
    const usageDetail = yield call(postUsageDetailService, auth.token, filter)
    yield put(fetchUsageInfoSuccess(usageDetail, filter))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchUsageInfo () {
  yield takeEvery(usageActionTypes.FETCH_USAGE_INFO, fetchUsageInfo)
}

export function * downloadUsageInfo ({ filter }) {
  try {
    yield put(showLoader())
    yield filter = yield filter || select(getUsageFilter)
    const auth = yield select(getAuth)
    yield call(getDownloadUsageDetail, auth.token, filter)
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDownloadUsageInfo () {
  yield takeEvery(usageActionTypes.DOWNLOAD_USAGE_INFO, downloadUsageInfo)
}
