import React, { PureComponent } from 'react'
import { Button, Panel, Row } from 'react-bootstrap'
import InputSelect from '../../commons/form/InputSelect'
import InputText from '../../commons/form/InputText'
import InputNatural from '../../commons/form/InputNatural'
import InputMoney from '../../commons/form/InputMoney'
import InputDatePicker from '../../commons/form/InputDatePicker'
import InputPercentage from '../../commons/form/InputPercentage'
import { Field } from 'redux-form'
import { compareObjects } from '../../../util/utils'
import { getFieldsPermisions } from '../../../util/permisionFunctions'
import { permissions } from '../../../constants/backendIds'
import { financingRadioOptions } from '../../../constants/dossier/sale/financingComponent'
import InputRadio from '../../commons/form/InputRadio'
import CustomFields from '../../commons/customFields/CustomFields'

class ReFinance extends PureComponent {
  constructor () {
    super()
    this.state = {
      open: false,
      fieldsConfiguration: {
        reactivateFinancing: null,
        companyId: null,
        typeId: null,
        amount: null,
        instalments: null,
        firstInstalmentDate: null,
        km: null,
        financingStatusId: null,
        tin: null,
        bonusAmount: null,
        extendedFinancing: null,
        entry: null,
        residualValue: null,
        openingCommission: null,
        monthlyFee: null,
        apr: null,
        lastDNICIF: null,
        renovation: null,
        maintenanceIncluded: null,
        insuranceIncluded: null,
        reactivateButton: null
      },
      dataLoaded: false,
      lastDNICIFDisabled: true,
      loaded: false,
      typeProductChange: false,
      typeId: null
    }
  }

  initializePermisions (props) {
    const fieldKeys = Object.keys(this.state.fieldsConfiguration)
    const newState = getFieldsPermisions(props.componentConfiguration.fieldsConfiguration, fieldKeys, props.componentConfiguration.permission)
    this.setState({ ...this.state, ...newState })
  }

  componentDidMount () {
    this.initializePermisions(this.props)
    if (this.props.dossier.dossierReFinancingComponent && this.props.dossier.dossierReFinancingComponent.companyId) {
      this.props.actions.fetchFinancingType(this.props.dossier.dossierReFinancingComponent.companyId, 'refinance')
    }
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (this.props.open) {
      this.setState({ open: true })
    }

    if (prevProps.dossier.dossierReFinancingComponent && this.props.dossier && this.props.dossier.dossierReFinancingComponent && (prevProps.dossier.dossierReFinancingComponent.companyId !== this.props.dossier.dossierReFinancingComponent.companyId)) {
      this.props.actions.fetchFinancingType(this.props.dossier.dossierReFinancingComponent.companyId, 'refinance')
    }

    if (this.props.isFinanceLoaded === false && this.state.open) {
      this.setState({ loaded: true })
    }
    if (this.props.componentConfiguration && this.props.componentConfiguration !== null) {
      if (this.props.dossierId && !compareObjects(prevProps.componentConfiguration || {}, this.props.componentConfiguration, true)) {
        this.initializePermisions(this.props)
      }
    }
    if (this.props.dossier && !compareObjects(prevProps.dossier.dossierReFinancingComponent || {}, this.props.dossier.dossierReFinancingComponent, true)) {
      this.setState({ typeId: this.props.dossier.dossierReFinancingComponent.typeId || false })
    }
    return null
  }

  componentDidUpdate () {
  }

  fetchSalesDossierPromiseFinance () {
    if (this.state.open) {
      this.props.actions.setActiveSection(false)
    }
    this.setState({
      open: !this.state.open
    })
  }

  render () {
    const { t, readOnlyFields, customFields, combos: { financialInstitutionCombo, financingStatusCombo, financingTypeComboReFinance } } = this.props
    const tKey = 'DOSSIER_COMPONENTS.FINANCE.'
    const reactivateButton = this.props.dossier.dossierReFinancingComponent && this.props.dossier.dossierReFinancingComponent.reActivatedFinancingId
      ? 'DOSSIER_COMPONENTS.FINANCE.MODIFY' : 'DOSSIER_COMPONENTS.FINANCE.REACTIVATE'
    return (
      <Row className="finance-panel dossier-panel">

        <div className="header-panel" onClick={this.fetchSalesDossierPromiseFinance.bind(this)}>
          <div className="name-wrapper">
            <i className="ico-finance" />
            <h4>{t(`${tKey}TITLE_PANEL_REFINANCE`)}</h4>
          </div>
          <i className={this.state.open ? 'ico-arrow-black-up' : 'ico-arrow-black-down'} />
        </div>
        <Panel expanded={this.state.open} onToggle={() => {}}>
          <Panel.Collapse>
            <Row>
              {this.state.fieldsConfiguration.companyId && this.state.fieldsConfiguration.companyId.permission !== permissions.hidden && (<Field
                colSm={3}
                id="companyId"
                name="dossierReFinancingComponent.companyId"
                controlLabel={t(`${tKey}COMPANY`)}
                placeholder={t(`${tKey}COMPANY`)}
                component={InputSelect}
                options={financialInstitutionCombo}
                valueKey="id"
                labelKey="value"
                disabled={readOnlyFields || this.state.fieldsConfiguration.companyId.permission !== permissions.editable}
              />)}
              {this.state.fieldsConfiguration.typeId && this.state.fieldsConfiguration.typeId.permission !== permissions.hidden && (<Field
                colSm={3}
                id="typeId"
                name="dossierReFinancingComponent.typeId"
                controlLabel={t(`${tKey}FINANCE_TYPE`)}
                placeholder={t(`${tKey}FINANCE_TYPE`)}
                component={InputSelect}
                options={financingTypeComboReFinance}
                valueKey="id"
                labelKey="value"
                disabled={readOnlyFields || this.state.fieldsConfiguration.typeId.permission !== permissions.editable}
              />)}
              {this.state.fieldsConfiguration.extendedFinancing && this.state.fieldsConfiguration.extendedFinancing.permission !== permissions.hidden && (
                <Field
                  id="refinancingTypeProduct"
                  key="refinancingTypeProduct"
                  name="dossierReFinancingComponent.financingTypeProductIdName"
                  colSm={3}
                  controlLabel={t(`${tKey}FINANCING_TYPE_PRODUCT`)}
                  placeholder={t(`${tKey}FINANCING_TYPE_PRODUCT`)}
                  component={InputText}
                  disabled={true}
                />
              )}
              {this.state.fieldsConfiguration.amount && this.state.fieldsConfiguration.amount.permission !== permissions.hidden && (<Field
                id="amount"
                name="dossierReFinancingComponent.amount"
                colSm={3}
                controlLabel={t(`${tKey}AMOUNT`)}
                placeholder={t(`${tKey}AMOUNT`)}
                component={InputMoney}
                disabled={readOnlyFields || this.state.fieldsConfiguration.amount.permission !== permissions.editable}
              />)}
              {this.state.fieldsConfiguration.instalments && this.state.fieldsConfiguration.instalments.permission !== permissions.hidden && (<Field
                id="instalments"
                name="dossierReFinancingComponent.instalments"
                colSm={3}
                controlLabel={t(`${tKey}INSTALMENTS`)}
                placeholder={t(`${tKey}INSTALMENTS`)}
                component={InputNatural}
                disabled={readOnlyFields || this.state.fieldsConfiguration.instalments.permission !== permissions.editable}
              />)}
              {this.state.fieldsConfiguration.firstInstalmentDate && this.state.fieldsConfiguration.firstInstalmentDate.permission !== permissions.hidden && (<Field
                id="firstInstalmentDate"
                name="dossierReFinancingComponent.firstInstalmentDate"
                colSm={3}
                controlLabel={t(`${tKey}FIRST_INSTALMENT_DATE`)}
                component={InputDatePicker}
                disabled={readOnlyFields || this.state.fieldsConfiguration.firstInstalmentDate.permission !== permissions.editable}
              />)}
              {this.state.fieldsConfiguration.km && this.state.fieldsConfiguration.km.permission !== permissions.hidden && (<Field
                id="km"
                name="dossierReFinancingComponent.km"
                colSm={3}
                controlLabel={t(`${tKey}KM`)}
                placeholder={t(`${tKey}KM`)}
                component={InputNatural}
                disabled={readOnlyFields || this.state.fieldsConfiguration.km.permission !== permissions.editable}
                textAlign="left"
              />)}
              {this.state.fieldsConfiguration.tin && this.state.fieldsConfiguration.tin.permission !== permissions.hidden && (<Field
                id="tin"
                name="dossierReFinancingComponent.tin"
                colSm={3}
                controlLabel={t(`${tKey}TIN`)}
                placeholder={t(`${tKey}TIN`)}
                component={InputPercentage}
                disabled={readOnlyFields || this.state.fieldsConfiguration.tin.permission !== permissions.editable}
                valueKey="id"
                labelKey="value"
              />)}
              {this.state.fieldsConfiguration.financingStatusId && this.state.fieldsConfiguration.financingStatusId.permission !== permissions.hidden && (<Field
                id="financingStatusId"
                name="dossierReFinancingComponent.financingStatusId"
                colSm={3}
                controlLabel={t(`${tKey}FINANCING_STATUS`)}
                placeholder={t(`${tKey}FINANCING_STATUS`)}
                component={InputSelect}
                disabled={readOnlyFields || this.state.fieldsConfiguration.financingStatusId.permission !== permissions.editable}
                options={financingStatusCombo}
                valueKey="id"
                labelKey="value"
              />)}
              {this.state.fieldsConfiguration.bonusAmount && this.state.fieldsConfiguration.bonusAmount.permission !== permissions.hidden && (<Field
                id="bonusAmount"
                name="dossierReFinancingComponent.bonusAmount"
                colSm={3}
                controlLabel={t(`${tKey}BONUS_AMOUNT`)}
                placeholder={t(`${tKey}BONUS_AMOUNT`)}
                component={InputMoney}
                disabled={readOnlyFields || this.state.fieldsConfiguration.bonusAmount.permission !== permissions.editable}
                valueKey="id"
                labelKey="value"
              />)}
              {this.state.fieldsConfiguration.extendedFinancing && this.state.fieldsConfiguration.extendedFinancing.permission !== permissions.hidden && ([<Field
                id="entry"
                key="reentry"
                name="dossierReFinancingComponent.entry"
                colSm={3}
                controlLabel={t(`${tKey}ENTRY`)}
                placeholder={t(`${tKey}ENTRY`)}
                component={InputMoney}
                disabled={readOnlyFields || (this.state.fieldsConfiguration.extendedFinancing && this.state.fieldsConfiguration.extendedFinancing.permission !== permissions.editable)}
              />,
              <Field
                id="residualValue"
                key="reresidualValue"
                name="dossierReFinancingComponent.residualValue"
                colSm={3}
                controlLabel={t(`${tKey}RESIDUAL_VALUE`)}
                placeholder={t(`${tKey}RESIDUAL_VALUE`)}
                component={InputMoney}
                disabled={readOnlyFields || (this.state.fieldsConfiguration.extendedFinancing && this.state.fieldsConfiguration.extendedFinancing.permission !== permissions.editable)}
              />,
              <Field
                id="openingCommission"
                key="reopeningCommission"
                name="dossierReFinancingComponent.openingCommission"
                colSm={3}
                controlLabel={t(`${tKey}OPENING_COMMISSION`)}
                placeholder={t(`${tKey}OPENING_COMMISSION`)}
                component={InputMoney}
                disabled={readOnlyFields || (this.state.fieldsConfiguration.extendedFinancing && this.state.fieldsConfiguration.extendedFinancing.permission !== permissions.editable)}
              />,
              <Field
                id="monthlyFee"
                key="remonthlyFee"
                name="dossierReFinancingComponent.monthlyFee"
                colSm={3}
                controlLabel={t(`${tKey}MONTHLY_FEE`)}
                placeholder={t(`${tKey}MONTHLY_FEE`)}
                component={InputMoney}
                disabled={readOnlyFields || (this.state.fieldsConfiguration.extendedFinancing && this.state.fieldsConfiguration.extendedFinancing.permission !== permissions.editable)}
              />,
              <Field
                id="apr"
                key="reapr"
                name="dossierReFinancingComponent.apr"
                colSm={3}
                controlLabel={t(`${tKey}APR`)}
                placeholder={t(`${tKey}APR`)}
                component={InputPercentage}
                disabled={readOnlyFields || (this.state.fieldsConfiguration.extendedFinancing && this.state.fieldsConfiguration.extendedFinancing.permission !== permissions.editable)}
              />,
              <Field
                id="lastDNICIF"
                key="relastDNICIF"
                name="dossierReFinancingComponent.lastDNICIF"
                colSm={3}
                controlLabel={t(`${tKey}LAST_DNI_CIF`)}
                placeholder={t(`${tKey}LAST_DNI_CIF`)}
                component={InputText}
                disabled={readOnlyFields || this.state.lastDNICIFDisabled || (this.state.fieldsConfiguration.extendedFinancing && this.state.fieldsConfiguration.extendedFinancing.permission !== permissions.editable)}
              />,
              this.state.fieldsConfiguration.reactivateButton && this.state.fieldsConfiguration.reactivateButton.permission !== permissions.hidden && (
                <div key="reactivateButtondiv" className="col-sm-3">
                  <br/><br/>
                  <Button key="reactivateButton" className="btn-standard btn btn-default" onClick={() => this.props.openRefinanceModal() }
                    disabled = {!(this.state.fieldsConfiguration.reactivateButton && this.state.fieldsConfiguration.reactivateButton.permission === permissions.editable)}>
                    {t(reactivateButton)}
                  </Button></div>),

              <div className="clearfix" key="div"/>,
              <Field
                id="renovation"
                key="rerenovation"
                name="dossierReFinancingComponent.renovation"
                groupName="dossierReFinancingComponent.renovation"
                colSm={3}
                inline
                placeholder={t(`${tKey}RENOVATION`)}
                component={InputRadio}
                disabled={readOnlyFields || (this.state.fieldsConfiguration.extendedFinancing && this.state.fieldsConfiguration.extendedFinancing.permission !== permissions.editable)}
                items={financingRadioOptions}
                t={t}
              />,
              <Field
                id="maintenanceIncluded"
                key="remaintenanceIncluded"
                name="dossierReFinancingComponent.maintenanceIncluded"
                groupName="dossierReFinancingComponent.maintenanceIncluded"
                colSm={3}
                inline
                placeholder={t(`${tKey}MAINTENANCE_INCLUDED`)}
                component={InputRadio}
                disabled={readOnlyFields || (this.state.fieldsConfiguration.extendedFinancing && this.state.fieldsConfiguration.extendedFinancing.permission !== permissions.editable)}
                items={financingRadioOptions}
                t={t}
              />,
              <Field
                id="insuranceIncluded"
                key="reinsuranceIncluded"
                name="dossierReFinancingComponent.insuranceIncluded"
                groupName="dossierReFinancingComponent.insuranceIncluded"
                colSm={3}
                inline
                placeholder={t(`${tKey}INSURANCE_INCLUDED`)}
                component={InputRadio}
                disabled={readOnlyFields || (this.state.fieldsConfiguration.extendedFinancing && this.state.fieldsConfiguration.extendedFinancing.permission !== permissions.editable)}
                items={financingRadioOptions}
                t={t}
              />
              ])}
            </Row>
            <CustomFields customFields={customFields} readOnlyFields={true} />
          </Panel.Collapse>
        </Panel>
      </Row>

    )
  }
}
export default ReFinance
