import paperworkComponentActionTypes from '../../../constants/actions/dossier/common/paperworksTable'

export function fetchPaperworkEditionModal (organizedUnitId, dossierParentId, paperworkId) {
  return {
    type: paperworkComponentActionTypes.FETCH_PAPERWORK_EDITION_MODAL,
    organizedUnitId,
    dossierParentId,
    paperworkId
  }
}

export function fetchPaperworkEditionModalSuccess (isEditionModal) {
  return {
    type: paperworkComponentActionTypes.FETCH_PAPERWORK_EDITION_MODAL_SUCCESS,
    isEditionModal
  }
}

export function deletePaperwork (paperworkId, dossierParentId, updateDossierAction, showAlertSendPending) {
  return {
    type: paperworkComponentActionTypes.DELETE_PAPERWORK,
    paperworkId,
    dossierParentId,
    updateDossierAction,
    showAlertSendPending
  }
}

export function updatePaperwork (paperwork, paperworkId, dossierParentId, updateDossierAction, showAlertSendPending) {
  return {
    type: paperworkComponentActionTypes.UPDATE_PAPERWORK,
    paperwork,
    paperworkId,
    dossierParentId,
    updateDossierAction,
    showAlertSendPending
  }
}

export function createPaperwork (paperwork, parentDossierId, updateDossierAction, showAlertSendPending) {
  return {
    type: paperworkComponentActionTypes.CREATE_PAPERWORK,
    paperwork,
    parentDossierId,
    updateDossierAction,
    showAlertSendPending
  }
}

export function printPaperwork (paperworkId, dossierType) {
  return {
    type: paperworkComponentActionTypes.PRINT_PAPERWORK,
    paperworkId,
    dossierType
  }
}

export function closePaperworkEditionModal () {
  return {
    type: paperworkComponentActionTypes.CLOSE_PAPERWORK_EDITION_MODAL
  }
}

export function generateFileToSign (paperworkId, signTypeId, dossierType, updateDossierAction, dossierParentId) {
  return {
    type: paperworkComponentActionTypes.GENERATE_FILE_TO_SIGN,
    paperworkId,
    signTypeId,
    dossierType,
    updateDossierAction,
    dossierParentId
  }
}

export function checkOrderStatus (paperworkId, resolve, reject) {
  return {
    type: paperworkComponentActionTypes.CHECK_ORDER_STATUS,
    paperworkId,
    resolve,
    reject
  }
}

export default {
  fetchPaperworkEditionModal,
  fetchPaperworkEditionModalSuccess,
  updatePaperwork,
  createPaperwork,
  deletePaperwork,
  printPaperwork,
  closePaperworkEditionModal,
  generateFileToSign,
  checkOrderStatus
}
