import mastersActionTypes from '../../../constants/actions/masters/masters'

function initialState () {
  return {
    documentTypeMetadataList: [],
    documentTypeMetadataListCount: 0,
    pagesCount: 0,
    filter: {
      page: 1,
      orderBy: '',
      pageSize: 10
    },
    documentTypeMetadataModal: {
      documentTypeMetadata: null,
      showModal: false
    },
    metadataListModal: {
      metadata: null,
      metadataList: [],
      showModal: false,
      metadataListCount: 0,
      pagesCount: 0,
      filter: {
        page: 1,
        orderBy: '',
        pageSize: 10
      }
    }
  }
}

function fetchDocumentTypeMetadataListSuccess (state, { documentTypeMetadataList, filter }) {
  return {
    ...state,
    documentTypeMetadataList: documentTypeMetadataList.documentTypeMetadata,
    documentTypesMetadataCount: documentTypeMetadataList.documentTypeMetadataCount,
    pagesCount: documentTypeMetadataList.pagesCount,
    languageSelected: documentTypeMetadataList.languageSelected,
    filter
  }
}

function openModalSuccess (state, { languageList, documentTypeMetadata, documentTypeId }) {
  const initial = initialState()
  return {
    ...state,
    documentTypeMetadataModal: {
      ...initial.documentTypeMetadataModal,
      documentTypeMetadata,
      documentTypeId,
      languageList,
      showModal: true
    }
  }
}

function closeEditModalSuccess (state) {
  return {
    ...state,
    documentTypeMetadataModal: {
      ...initialState().documentTypeMetadataModal
    }
  }
}

function openMetadataModalSuccess (state, { languageList, metadata, metadataList }) {
  const initial = initialState()
  return {
    ...state,
    metadataListModal: {
      ...initial.metadataListModal,
      ...metadataList,
      languageList,
      metadata,
      showModal: true
    }
  }
}

function closeMetadataListModalSuccess (state) {
  return {
    ...state,
    metadataListModal: {
      ...initialState().metadataListModal
    }
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case mastersActionTypes.FETCH_DOCUMENT_TYPE_METADATA_LIST_SUCCESS:
      return fetchDocumentTypeMetadataListSuccess(state, action)
    case mastersActionTypes.OPEN_DOCUMENT_TYPE_METADATA_MODAL_SUCCESS:
      return openModalSuccess(state, action)
    case mastersActionTypes.OPEN_METADATA_LIST_MODAL_SUCCESS:
      return openMetadataModalSuccess(state, action)
    case mastersActionTypes.CLOSE_EDIT_DOCUMENT_TYPE_METADATA_MODAL:
      return closeEditModalSuccess(state, action)
    case mastersActionTypes.CLOSE_METADATA_LIST_MODAL:
      return closeMetadataListModalSuccess(state, action)
    default:
      return state
  }
}
