import React, { PureComponent } from 'react'
import { Modal, Col, Row, FormGroup, Button, Tooltip, Table, OverlayTrigger } from 'react-bootstrap'
import IDocCarPagination from '../../commons/iDocCarPagination'
import TextInput from '../../commons/TextInput'
import { getObjectWithLocales } from '../../../util/masters/masters'
import { faCheck, faCancel } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { reduxForm, Field } from 'redux-form'

class MetadataListMasterModal extends PureComponent {
  constructor () {
    super()
    this.state = {
      selectedMetadatoListId: -1,
      showForm: false
    }
  }

  setFormForEdit (metadataListElement, languages, showForm = false) {
    const metadataListWithLocales = getObjectWithLocales(metadataListElement, metadataListElement.metadatoListLocales, languages)
    this.setState({ selectedMetadatoListId: metadataListElement?.metadatoListId ?? -1, showForm })
    this.props.initialize(metadataListWithLocales)
  }

  submitMetadataListFromRow () {
    this.submitMetadataList(this.props.formValues)
  }

  submitMetadataList (values) {
    let languages = []
    Object.keys(values).map(item => {
      if (!!values[item] && typeof values[item] === 'object') {
        languages.push(values[item])
      }
    })

    const metadataList = {
      metadatoListId: values.metadatoListId,
      metadatoId: values.metadatoId,
      metadatoListLocales: languages
    }

    this.props.actions.submitMetadataList(metadataList, this.props.metadata)
    this.setFormForEdit({ metadatoId: this.props.metadata.metadatoId }, this.props.languageList)
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevProps.showModal !== this.props.showModal && this.props.showModal === true) {
      this.setState({ selectedMetadatoListId: -1, showForm: false })
      const initialMetadataList = getObjectWithLocales({ metadatoId: this.props.metadata.metadatoId }, [], this.props.languageList)
      this.props.initialize(initialMetadataList)
    }
  }

  render () {
    const {
      t, handleSubmit, metadataList, languageSelected, metadataListCount, pagesCount, metadata,
      showModal, languageList,
      actions: { deleteMetadataListElement },
      filter: { page }
    } = this.props
    const tKey = 'MASTERS.METADATA_LIST_MASTERS.MODAL.'
    const editTooltip = (<Tooltip id="editTooltip">{t(`${tKey}EDIT`)}</Tooltip>)
    const deleteTooltip = (<Tooltip id="deleteTooltip">{t(`${tKey}DELETE`)}</Tooltip>)
    const cancelTooltip = (<Tooltip id="editTooltip">{t(`${tKey}CANCEL`)}</Tooltip>)
    const confirmTooltip = (<Tooltip id="editTooltip">{t(`${tKey}CONFIRM`)}</Tooltip>)
    return (
      <Modal className="role-modal languages-modal" show={showModal} onHide={this.props.actions.closeMetadataListMasterModal} backdrop={'static'}>
        <form autoComplete="off" onSubmit={handleSubmit(this.submitMetadataList.bind(this))}>
          <Modal.Header closeButton onHide={this.props.actions.closeMetadataListMasterModal}>
            <Modal.Title>{t(`${tKey}TITLE`)}</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ paddingLeft: '20px', paddingRight: '20px' }}>
            <h2>{t(`${tKey}METADATA_LIST`)}</h2>
            <Table className="editable-table" hover responsive>
              <thead>
                <tr>
                  <th onClick={() => { this.changeOrder('documentTypeId') }}>
                    {t(`${tKey}NAME`)}
                  </th>
                  <th onClick={() => { this.changeOrder('documentTypeId') }}>
                    {t(`${tKey}DESCRIPTION`)}
                  </th>
                  <th style={{ width: '77px' }}>{t('DOSSIER_COMPONENTS.ACCESSORIES.ACTIONS')}</th>
                </tr>
              </thead>
              <tbody>
                {metadataList && metadataList.map((metadataListElement, idx) => {
                  return (
                    <tr key={idx} className={metadataListElement.metadatoListId === this.state.selectedMetadatoListId ? 'metadata-row selected-row' : 'metadata-row'}>
                      <td>{metadataListElement.metadatoListLocales?.find(x => x.languageId === languageSelected)?.name || ''}</td>
                      <td>{metadataListElement.metadatoListLocales?.find(x => x.languageId === languageSelected)?.description || ''}</td>
                      {this.state.selectedMetadatoListId !== metadataListElement.metadatoListId && !metadataListElement.isDeleted && (
                        <td>
                          <a onClick={() => this.setFormForEdit(metadataListElement, languageList, true)}>
                            <OverlayTrigger placement="left" overlay={editTooltip}>
                              <i className="ico-edit-white" />
                            </OverlayTrigger>
                          </a>
                          <a onClick={() => deleteMetadataListElement(metadataListElement.metadatoListId, metadata)}>
                            <OverlayTrigger placement="left" overlay={deleteTooltip}>
                              <i className="ico-trash" />
                            </OverlayTrigger>
                          </a>
                        </td>
                      )}
                      {this.state.selectedMetadatoListId === metadataListElement.metadatoListId && !metadataListElement.isDeleted && (
                        <td>
                          <a onClick={() => this.submitMetadataListFromRow()}>
                            <OverlayTrigger placement="left" overlay={confirmTooltip}>
                              <FontAwesomeIcon className="ico-check" icon={faCheck} />
                            </OverlayTrigger>
                          </a>
                          <a onClick={() => this.setFormForEdit({ metadatoId: metadata.metadatoId }, languageList)}>
                            <OverlayTrigger placement="left" overlay={cancelTooltip}>
                              <FontAwesomeIcon className="ico-cancel" icon={faCancel} />
                            </OverlayTrigger>
                          </a>
                        </td>
                      )}
                    </tr>)
                })
                }
                {(metadataList === null || metadataList === undefined || metadataList.length === 0) &&
              <tr>
                <td colSpan={10}><h2>{t(`${tKey}RESULTS_NOT_FOUND`)}</h2></td>
              </tr>}
                <tr className="button-table-wrapper">
                  <td>
                    <Button
                      className="btn-editable-table btn-standard"
                      onClick={() => this.setFormForEdit({ metadatoId: metadata.metadatoId }, languageList, true)}>
                    + {t(`${tKey}ADD`)}
                    </Button>
                  </td>
                </tr>
              </tbody>
            </Table>
            <div className="section-users-footer">
              <nav key={1} className="text-center">
                <IDocCarPagination
                  id="btn_pag_sales"
                  page={page}
                  pagesCount={pagesCount}
                  totalRows={metadataListCount}
                  onSelectAction={(value) => true}
                />
              </nav>
            </div>
            <div className="clearfix" />
            {this.state.showForm && <FormGroup>
              {languageList && languageList.map((val, idx) => {
                return (
                  <Row key={idx} className="inputs-wrapper">
                    <Col sm={10} className="custom-width-col">
                      <h2>{t('MASTERS.NAME')}</h2>
                      <Field
                        name={`lang${val.languageId}.name`}
                        placeholder={t('MASTERS.NAME')}
                        component={TextInput}
                        maxLength={50}
                      />
                    </Col>
                    <Col sm={2} className="language-column">
                      <p>{`${val.description} (${val.name})`}</p>
                    </Col>
                    <Col sm={10} className="custom-width-col">
                      <h2>{t('MASTERS.DESCRIPTION')}</h2>
                      <Field
                        name={`lang${val.languageId}.description`}
                        placeholder={t('MASTERS.DESCRIPTION')}
                        component={TextInput}
                        maxLength={50}
                      />
                    </Col>
                    <Col sm={2} className="language-column">
                      <p>{`${val.description} (${val.name})`}</p>
                    </Col>
                  </Row>
                )
              })}
              <Row className="form-buttons">
                <Col sm={2} className="custom-width-col">
                  <Button
                    id="role-modal-submit-button"
                    className="btn-standard"
                    type="submit">
                    {t(`${tKey}SUBMIT_BUTTON`)}
                  </Button>
                </Col>
                <Col sm={2} className="custom-width-col">
                  <Button
                    id="form-modal-submit-button"
                    className="btn-standard"
                    type="submit"
                    onClick={() => this.setFormForEdit({ metadatoId: metadata.metadatoId }, languageList)}>
                    {t(`${tKey}DISCARD`)}
                  </Button>
                </Col>
              </Row>
            </FormGroup>}
          </Modal.Body>
          <Modal.Footer>
            <Col sm={12} className="btn-wrapper">
              <Button
                id="metadatalist-modal-cancel-button"
                className="btn-standard"
                onClick={this.props.actions.closeMetadataListMasterModal}>
                {t(`${tKey}CLOSE`)}
              </Button>
            </Col>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default reduxForm({
  form: 'editMetadataList',
  validate: (values, props) => {
    let error = {}

    const tKey = 'MASTERS.METADATA_LIST_MASTERS.VALIDATION.'

    props.languageList && props.languageList.forEach((element) => {
      let valueLangName = values['lang' + element.languageId].name
      let valueLangDesc = values['lang' + element.languageId].description
      let errorLang = {
        name: null,
        description: null
      }
      if (!valueLangName) {
        errorLang.name = props.t(`${tKey}NAME`)
      } else if (valueLangName.length > 50) {
        errorLang.name = props.t(`${tKey}NAME_LENGTH`)
      }
      if (!valueLangDesc) {
        errorLang.description = props.t(`${tKey}DESCRIPTION`)
      } else if (valueLangDesc.length > 50) {
        errorLang.description = props.t(`${tKey}DESCRIPTION_LENGTH`)
      }
      error['lang' + element.languageId] = errorLang
    })

    return Object.keys(error).length > 0 ? error : {}
  }
})(MetadataListMasterModal)
