import React, { PureComponent } from 'react'
import { Row, Col, Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Field, reduxForm } from 'redux-form'
import InputPassword from '../commons/form/InputPassword'
import InputText from '../commons/form/InputText'
import InputPasswordStrengthIndicator from '../commons/form/InputPasswordStrengthIndicator'
import InputSelect from '../commons/form/InputSelect'
import InputTreeSelect from '../commons/form/InputTreeSelect'
import validatePassword from '../access/validation/validatePassword'
import SimpleTablePage from '../../containers/commons/general/SimpleTablePage'
import { permissions } from '../../constants/backendIds'
import { getSectionPermissions } from '../../util/permisionFunctions'
import { filterSalesmanByUOSelected } from '../../util/utils'
import { sectionPermissions } from '../../constants/dossier/common/buttonsHeader'
import { validatePhone } from '../../util/validationFunctions'
import InputCheckBox from '../commons/form/InputCheckBox'
import Promise from 'promise-polyfill'

class UserData extends PureComponent {
  constructor (props) {
    super(props)
    this.state = { rechargeDevices: false, phoneEditable: false }
  }

  onSubmit (values) {
    return new Promise((resolve, reject) => {
      this.props.actions.updateUserProfile(values, this.props.t, resolve, reject)
    }).then(() => {
      this.setState({ phoneEditable: false })
    })
  }

  updateActiveDevice (device) {
    if (!device.activate && this.devicesActives() >= this.props.maxInstallations) {
      this.props.actions.openErrorMessageModal('PROFILE.MAX_INSTALLS_REACHED')
    } else {
      device.activate = !device.activate
      this.props.actions.updateDeviceInstall(device)
      this.setState({ rechargeDevices: !this.state.rechargeDevices })
    }
  }

  initializePermisions (props) {
    const section = props.sections.filter((tabConf) => tabConf.code === sectionPermissions.dashboard)
    if (section.length > 0 && section[0] != null) {
      const newState = getSectionPermissions(section[0])
      this.setState({ sectionTabsConfiguration: newState })
    }
  }

  componentDidMount () {
    this.initializePermisions(this.props)
    this.props.actions.fetchUserProfile()
    this.props.actions.fetchDeviceInstalls(this.props.userId)
    this.props.actions.fetchMaxDeviceInstalls(this.props.userId)
  }

  devicesActives () {
    let count = 0
    this.props.deviceInstalls.map(dev => { if (dev.activate) { count++ } })
    return count
  }

  handleUO (organizedUnitId) {
    if (organizedUnitId !== this.props.organizedUnitId) {
      return new Promise((resolve, reject) => {
        this.props.actions.fetchComboSalesmanByUO(organizedUnitId, resolve, reject)
      }).then(() => {
        organizedUnitId && organizedUnitId !== '' ? this.props.change('salesmansSelectedId', filterSalesmanByUOSelected(this.props.combos.SalesmanCombo, this.props.formValues.salesmansSelectedId))
          : this.props.change('salesmansSelectedId', [])
      })
    }
  }

  editPhone (editPhone) {
    if (editPhone) {
      this.setState({ phoneEditable: true })
    } else {
      this.setState({ phoneEditable: false })
    }
  }

  render () {
    const tKey = 'PROFILE.'
    const editTooltip = (<Tooltip id="editTooltip">{ this.props.t(`${tKey}EDIT_PHONE`)}</Tooltip>)
    const closeTooltip = (<Tooltip id="editTooltip">{ this.props.t(`${tKey}CLOSE_PHONE`)}</Tooltip>)
    const { t, handleSubmit, data, languages, passwordPolicy, formValues, formErrors, combos: { UOTreeCombo, SalesmanCombo }, deviceInstalls, maxInstallations } = this.props
    if (!data || !languages) return null
    return (
      <div className='profile-user'>
        <div className='profile-header'>
          <div className='title-user'>
            <i className='ico-user' /> <h2>{t(tKey + 'TITLE')}</h2>
          </div>
        </div>
        <form autoComplete="off" onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <Row className="profile-data-wrapper">
            <Col sm={5}>
              <div className="field inline-field">
                <label>{t(tKey + 'NAME')}:</label>
                <span>{data.name}</span>
              </div>
              <div className="field inline-field">
                <label>{t(tKey + 'SURNAMES')}:</label>
                <span>{data.surnames}</span>
              </div>
              <div className="field inline-field">
                <label>{t(tKey + 'EMAIL_ADDRESS')}:</label>
                <span>{data.emailAddress}</span>
              </div>
              {this.state.phoneEditable
                ? <div><label>{t(tKey + 'PHONE')}:</label> &nbsp;
                  <a onClick={() => this.editPhone(false)}>
                    <OverlayTrigger placement="right" overlay={closeTooltip}>
                      <i className="ico-cerrar" />
                    </OverlayTrigger></a>
                  <Field
                    t={t}
                    colSm={12}
                    id='phone'
                    name='phone'
                    placeholder={t(tKey + 'NEW_PHONE')}
                    component={InputText}
                    autoComplete="phone"
                  /></div>
                : <div className="field inline-field">
                  <label>{t(tKey + 'PHONE')}:</label>
                  <span>{data.phone ? data.phone + ' ' : '---'}</span>
                  <a onClick={() => this.editPhone(true)}>
                    <OverlayTrigger placement="right" overlay={editTooltip}>
                      <i className="ico-edit-white" />
                    </OverlayTrigger>
                  </a>
                </div>}
              <div className="field inline-field">
                <label>{t(tKey + 'UNIT_NAMES')}:</label>
                <ul>
                  {data.uoRoles.map(uo => {
                    return (
                      <li key={uo.organizedUnitId}>
                        {uo.name}
                        {uo.ownedRoles.length > 0 && (
                          <ul>
                            {uo.ownedRoles.map(rol => {
                              return <li key={rol.roleId}>{rol.description}</li>
                            })}
                          </ul>
                        )}
                      </li>
                    )
                  })}
                </ul>
              </div>
              <div className="field inline-field">
                <label>{t(tKey + 'DEPARTMENT')}:</label>
                <span>{data.department}</span>
              </div>
              <div className="field inline-field">
                <label>{t(tKey + 'POSITION')}:</label>
                <span>{data.position}</span>
              </div>
              {data.code && (<div className="field inline-field">
                <label>{t(tKey + 'CODE')}:</label>
                <span>{data.code}</span>
              </div>)}
              {this.state.sectionTabsConfiguration && this.state.sectionTabsConfiguration.profileDetails && this.state.sectionTabsConfiguration.profileDetails.permission > permissions.hidden && deviceInstalls && (
                <div>
                  <label>{t(tKey + 'DEVICE_INSTALLS')}{' (' + this.devicesActives() + '/' + maxInstallations + ')'}:</label>
                  <SimpleTablePage
                    columns={[
                      <th key={0} data-field="deviceName" >{t(tKey + 'DEVICE')}</th>,
                      <th key={1} data-field="activate" >{t(tKey + 'ACTIVE')}</th>,
                      <th key={2} data-field="activate" >{t(tKey + 'ACTIVATE/DEACTIVATE')}</th>
                    ]}
                    rows={deviceInstalls.map((row, idx) => (
                      <tr key={idx}>
                        <td>{row.deviceName}</td>
                        <td>{row.activate ? t('GLOBAL.BUTTONS.YES') : t('GLOBAL.BUTTONS.NO')}</td>
                        <td> <Button
                          onClick={(event) => this.updateActiveDevice(deviceInstalls[idx])}
                          bsStyle="default"
                          bsSize="small"
                          className="edit-search-button pull-left"
                        >{row.activate ? t('PROFILE.DEACTIVATE') : t('PROFILE.ACTIVATE') }
                        </Button>
                        </td>
                        <td>{row.activate}</td>
                      </tr>
                    ))}>
                  </SimpleTablePage>

                </div>)}
              {this.state.sectionTabsConfiguration && this.state.sectionTabsConfiguration.profileDetails && this.state.sectionTabsConfiguration.profileDetails.permission > permissions.hidden && (
                <div>
                  <label>{t(tKey + 'DOWNLOAD_APP')}</label>
                  <div>
                    <a href="https://play.google.com/store/apps/details?id=com.idoccar.scan" target="_blank"><img src="images/android.png" alt="" className="googlePlay"/></a>
                    <a href="https://apps.apple.com/es/app/idoccar-scan/id1512145256" target="_blank"><img src="images/mac.png" alt="" className="appStore"/></a>
                  </div>
                </div>
              )}
            </Col>
            <Col sm={6} smOffset={1}>
              <Row>
                <Field
                  t={t}
                  colSm={12}
                  id='oldPassword'
                  name='oldPassword'
                  placeholder={t(tKey + 'OLD_PASSWORD')}
                  controlLabel={t(tKey + 'OLD_PASSWORD')}
                  component={InputPassword}
                  autoComplete="old-Password"
                />
                <Field
                  t={t}
                  colSm={12}
                  id='newPassword'
                  name='newPassword'
                  placeholder={t(tKey + 'NEW_PASSWORD')}
                  controlLabel={t(tKey + 'NEW_PASSWORD')}
                  component={InputPassword}
                  autoComplete="new-Password"
                />
                <InputPasswordStrengthIndicator
                  t={t}
                  colSm={12}
                  passwordPolicy={passwordPolicy}
                  passwordValue={formValues && formValues.newPassword ? formValues.newPassword : ''}
                  passwordError={formErrors && formErrors.newPassword ? formErrors.newPassword : false}
                />
                <Field
                  t={t}
                  colSm={12}
                  id='pwdLoginRepeat'
                  name='pwdLoginRepeat'
                  placeholder={t(tKey + 'REPEAT_PASSWORD')}
                  controlLabel={t(tKey + 'REPEAT_PASSWORD')}
                  component={InputPassword}
                  autoComplete="pwd-LoginRepeat"
                />
                <Field
                  colSm={12}
                  id='languageId'
                  name='languageId'
                  placeholder={t(tKey + 'LANGUAGE')}
                  controlLabel={t(tKey + 'LANGUAGE')}
                  component={InputSelect}
                  options={languages}
                  valueKey="languageId"
                  labelKey="description"
                />
                <Field
                  colSm={12}
                  name="organizedUnit"
                  id="organizedUnit"
                  placeholder={t('ADMIN.USERS.UO')}
                  controlLabel={t('ADMIN.USERS.UO')}
                  component={InputTreeSelect}
                  multi={true}
                  valueKey="value"
                  labelKey="label"
                  childrenKey="children"
                  options={UOTreeCombo}
                  onInputChange={(organizedUnitId) => this.handleUO(organizedUnitId)}
                />
                {this.state.sectionTabsConfiguration && (this.state.sectionTabsConfiguration.salesmansSelected === undefined || (this.state.sectionTabsConfiguration.salesmansSelected.permission > permissions.hidden)) && (
                  <Field
                    colSm={12}
                    id="salesmansSelectedId"
                    name="salesmansSelectedId"
                    placeholder={t(tKey + 'SELECTED_SALESMAN')}
                    controlLabel={t(tKey + 'SELECTED_SALESMAN')}
                    component={InputSelect}
                    multi={true}
                    valueKey="id"
                    labelKey="value"
                    options={SalesmanCombo}
                  />)}
                {this.state.sectionTabsConfiguration && this.state.sectionTabsConfiguration.responsableSalesman && this.state.sectionTabsConfiguration.responsableSalesman.permission > permissions.hidden && (
                  <Field
                    colSm={12}
                    id="responsableSalesmansId"
                    name="responsableSalesmansId"
                    placeholder={t(tKey + 'RESPONSABLE_SALESMAN')}
                    controlLabel={t(tKey + 'RESPONSABLE_SALESMAN')}
                    component={InputSelect}
                    multi={true}
                    valueKey="id"
                    labelKey="value"
                    options={SalesmanCombo}
                  />)}
                {this.state.sectionTabsConfiguration && this.state.sectionTabsConfiguration.responsableNotifications && this.state.sectionTabsConfiguration.responsableNotifications.permission > permissions.hidden && (
                  <Field
                    colSm={4}
                    id="responsableNotifications"
                    name="responsableNotifications"
                    placeholder={t(`${tKey}RECEIVE_SELLER_NOTIFICATIONS`)}
                    inline
                    component={InputCheckBox}
                  />)}
                <Col sm={12}><br></br></Col>
                <Col sm={12}>
                  <div className="field inline-field">
                    <label>{t(tKey + 'LAST_LOGIN')}:</label>
                    <span>{data.lastLoginDate}</span>
                  </div>
                </Col>
                <Col sm={12}>
                  <Button type="submit" className="btn-white btn-icon btn-save">
                    <i className="ico-folder-open"/> <span>{t(tKey + 'SAVE')}</span>
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </form>
      </div>
    )
  }
}

export default reduxForm({
  form: 'updateProfile',
  validate: (values, props) => {
    const tKey = 'PROFILE.'
    let errors = {}
    if (values.newPassword || values.pwdLoginRepeat) {
      errors = validatePassword(values, props)
      if (!values.oldPassword) {
        errors.oldPassword = props.t(tKey + 'OLD_PASSWORD_REQUIRED')
      }
    }
    if (!values.languageId) {
      errors.languageId = props.t(tKey + 'LANGUAGE_REQUIRED')
    }
    if (values.phone && validatePhone(values, props) === false) {
      errors.phone = props.t(tKey + 'PHONE_NUMBER')
    }
    return errors
  }
})(UserData)
