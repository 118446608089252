import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { closeCampaignMasterModal, submitCampaignDetail } from '../../../actions/masters/masters'
import { fetchCampaignTypeByGroup, fetchCampaignClass, fetchCampaignGroup, fecthFamily, fetchExternalCode } from '../../../actions/combos/combos'
import CampaingMasterModal from '../../../components/masters/campaign/CampaingMasterModal'

export function mapStateToProps (state) {
  const campaignModal = state.campaign.campaignModal
  return {
    showModal: campaignModal.showModal,
    campaign: campaignModal.campaign,
    nonEditable: campaignModal.nonEditable,
    combos: {
      campaignType: state.combos.campaignTypeTest,
      campaignClass: state.combos.campaignClass,
      campaignGroup: state.combos.campaignGroup,
      familyCombo: state.combos.familyCombo,
      externalCode: state.combos.externalCode
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      submitCampaignDetail,
      fetchCampaignGroup,
      fetchCampaignClass,
      fetchCampaignTypeByGroup,
      closeCampaignMasterModal,
      fecthFamily,
      fetchExternalCode
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(CampaingMasterModal))
