import React, { PureComponent } from 'react'
import { Button, OverlayTrigger, Row, Table, Tooltip } from 'react-bootstrap'
import InputCheckboxSlider from '../commons/form/InputCheckboxSlider'
import EventSubscriptionModal from './EventSubscriptionModal'

class UserEventSubscriptions extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      showEventModal: false,
      eventSubscription: null,
      eventSubscriptions: []
    }
    this.openEventModal = this.openEventModal.bind(this)
    this.closeEventModal = this.closeEventModal.bind(this)
  }

  openEventModal (eventSubscription) {
    this.setState({ showEventModal: true, eventSubscription })
  }

  getSnapshotBeforeUpdate (prevProps) {
    return null
  }

  componentDidUpdate (prevProps, prevState) {
  }

  componentDidMount () {
    this.props.actions.fetchLowestOrganizedUnits()
  }

  onActiveInputChange (subscriptionId, value) {
    if (value) {
      this.props.actions.enableEventSubscription(subscriptionId)
    } else {
      this.props.actions.disableEventSubscription(subscriptionId)
    }
  }

  getOuColumn (organizedUnits) {
    const ouArray = organizedUnits ? organizedUnits.split(',') : []
    return (<td> {ouArray[0]} {ouArray.length > 1 ? <span className='size10'> (+ {ouArray.length - 1})</span> : '' } </td>)
  }

  closeEventModal () {
    this.setState({ showEventModal: false, eventSubscription: null })
  }

  render () {
    const tKey = 'PROFILE.NOTIFICATIONS_TAB.'
    const { t, userId, languageId, eventSubscriptions, actions: { saveEventSubscription, removeEventSubscription, fetchEventsCombo }, combos } = this.props
    const editTooltip = (<Tooltip id="editTooltip">{t(`${tKey}EDIT`)}</Tooltip>)
    const deleteTooltip = (<Tooltip id="deleteTooltip">{t(`${tKey}DELETE`)}</Tooltip>)
    const subscribedEvents = eventSubscriptions?.map(x => x.notificationEventId) ?? []
    return (
      <div className='userEventSubscriptions'>
        <EventSubscriptionModal
          actions={{
            saveEventSubscription,
            closeModal: this.closeEventModal,
            fetchEventsCombo
          }}
          t={t}
          combos={combos}
          showModal={this.state.showEventModal}
          eventSubscription={this.state.eventSubscription}
          subscribedEvents = {subscribedEvents}
          userId = {userId}
          defaultLanguageId = {languageId}
        />
        <Row>
          <Table hover responsive className="editable-table EventSubscriptionTable">
            <thead>
              <tr>
                <th key={'ou'}>{t(`${tKey}OU_COL`)}</th>
                <th key={'name'}>{t(`${tKey}NAME_COL`)}</th>
                <th key={'description'}>{t(`${tKey}DESCRIPTION_COL`)}</th>
                <th className='enabledCol' key={'enabled'}>{t(`${tKey}ENABLE_COL`)}</th>
                <th className='actionsCol' key={'actions'}>{t(`${tKey}ACTIONS_COL`)}</th>
              </tr>
            </thead>
            <tbody>
              {eventSubscriptions?.map((subscription, idx) => {
                return (
                  <tr key={idx} className= { subscription.active ? '' : 'subscriptionDisabled' }>
                    {this.getOuColumn(subscription.organizedUnits)}
                    <td>{subscription.name}</td>
                    <td>{subscription.description}</td>
                    <td className='enabledCol'>
                      <InputCheckboxSlider
                        checked={subscription.active}
                        onInputChange={(value) => this.onActiveInputChange(subscription.subscriptionId, value) }
                      />
                    </td>
                    <td className='actionsCol'>
                      <div>
                        <a onClick={() => this.openEventModal(subscription)}>
                          <OverlayTrigger placement="left" overlay={editTooltip}>
                            <i className="ico-edit-white" />
                          </OverlayTrigger>
                        </a>
                        <a onClick={() => removeEventSubscription(subscription.subscriptionId)}>
                          <OverlayTrigger placement="left" overlay={deleteTooltip}>
                            <i className="ico-trash" />
                          </OverlayTrigger>
                        </a>
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </Row>
        <Row>
          <Button className="_Btn accept"
            onClick={() => this.openEventModal()}>
            <i className="iDocIcon-add-plus"></i>
            {t(`${tKey}ADD_BUTTON`)}
          </Button>
        </Row>
      </div>
    )
  }
}

export default UserEventSubscriptions
