import buyActionTypes from '../../constants/actions/reports/buyReport'

function initialState () {
  return {
    buyList: [],
    pagesCount: 0,
    buyCount: 0,
    filter: {
      page: 1,
      orderBy: '',
      isDeleted: null,
      pageSize: 10
    }
  }
}

function fetchBuyReportSuccess (state, { buyList, filter }) {
  return {
    ...state,
    buyList: buyList.buyList,
    buyCount: buyList.buyCount,
    pagesCount: buyList.pagesCount,
    filter
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case buyActionTypes.FETCH_BUY_REPORT_SUCCESS:
      return fetchBuyReportSuccess(state, action)
    default:
      return state
  }
}
