export const TrackingActions = {
  OPEN_DOCUMENT_MODAL: 'open_document_modal',
  CREATE_DOCUMENT: 'create_document',
  CREATE_DOCUMENT_SHEET: 'create_document_sheet',
  DELETE_SHEET: 'delete_document_sheet',
  VALIDATE_DOCUMENT: 'validate_document',
  CANCEL_DOCUMENT: 'cancel_document',
  SIGN_REQUEST: 'sign_request',
  SIGN_CANCEL: 'sign_cancel',

  SAVE_DOCUMENT: 'save_document',
  REJECT_DOCUMENT: 'reject_document',

  LOAD_DASHBOADTASK: 'load_dashboadtask',

  SAVE_DOSSIER: 'save_dossier',
  CANCEL_DOSSIER: 'cancel_dossier',
  STORE_DOSSIER: 'store_dossier',
  OPEN_SIGN_MODAL: 'open_sign_modal',

  REQUEST_LANDING_MODAL: 'request_landing_modal'
}
