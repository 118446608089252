
import React, { PureComponent } from 'react'
import { Modal, Col, Row, FormGroup, Button } from 'react-bootstrap'
import { reduxForm, Field } from 'redux-form'
import InputText from '../../commons/form/InputText'
import InputMoney from '../../commons/form/InputMoney'
import InputPercentage from '../../commons/form/InputPercentage'
import InputCheckBox from '../../commons/form/InputCheckBox'
import InputDatePicker from '../../commons/form/InputDatePicker'
import InputSelect from '../../commons/form/InputSelect'

class CampaignMasterModal extends PureComponent {
  constructor () {
    super()
    this.state = {
      campaingGroupDisabled: true,
      campaingTypeDisabled: true,
      amountDisabled: false,
      amountPercentageDisabled: false,
      amount: false,
      amountBrand: false,
      amountPercentage: false,
      amountBrandPercentage: false,
      checkboxAmount: false,
      checkboxPercentage: false
    }
  }

  submitCampaign (values) {
    const body = {
      ...values
    }
    this.props.actions.submitCampaignDetail(body)
  }

  componentDidMount () {
    this.props.actions.fetchCampaignClass()
    this.props.actions.fecthFamily()
    this.props.actions.fetchExternalCode()
  }

  componentDidUpdate (prevProps) {
    if (this.props.showModal !== prevProps.showModal && this.props.showModal === true) {
      this.setState({ amountDisabled: false, amountPercentageDisabled: false })
      this.props.initialize(this.props.campaign)
      if (this.props.campaign) {
        this.checkComboBoxFree(this.props)
        if (this.props.campaign.amountCampaign || this.props.campaign.amountBrand) {
          this.setState({ amountPercentageDisabled: true, amount: true, amountBrand: true })
        }
        if (this.props.campaign.percentageCampaign || this.props.campaign.percentageBrand) {
          this.setState({ amountDisabled: true, amountPercentage: true, amountBrandPercentage: true })
        }
        if (this.props.campaign.campaignClassId) {
          this.props.actions.fetchCampaignGroup(this.props.campaign.campaignClassId)
        }
        if (this.props.campaign.amountFree || this.props.campaign.percentageFree) {
          this.setState({ amountDisabled: true, amountPercentageDisabled: true })
        }
        if (this.props.campaign.campaignGroupId) {
          this.props.actions.fetchCampaignTypeByGroup(this.props.campaign.campaignGroupId)
        }
      }
    }
  }

  handleCampaignClass (value) {
    if (value) {
      this.setState({ campaingGroupDisabled: false })
      this.props.actions.fetchCampaignGroup(value)
    } else {
      this.props.change('campaignGroupId', null)
      this.setState({ campaingGroupDisabled: true })
    }
  }

  handleCampaignGroup (value) {
    if (value) {
      this.setState({ campaingTypeDisabled: false })
      this.props.actions.fetchCampaignTypeByGroup(value)
    } else {
      this.props.change('campaignTypeId', null)
      this.setState({ campaingTypeDisabled: true })
    }
  }

  handleAmountFree (value) {
    this.setState({ amountPercentage: false, amountBrandPercentage: false, amount: false, amountBrand: false })
    if (value) {
      this.setState({ amountDisabled: true, amountPercentageDisabled: true, checkboxPercentage: true })
      this.props.change('amountCampaign', null)
      this.props.change('percentageCampaign', null)
      this.props.change('percentageBrand', null)
      this.props.change('amountBrand', null)
    } else {
      this.setState({ amountDisabled: false, amountPercentageDisabled: false, checkboxPercentage: false })
    }
  }

  handlePercentageFree (value) {
    this.setState({ amountPercentage: false, amountBrandPercentage: false, amount: false, amountBrand: false })
    if (value) {
      this.setState({ amountDisabled: true, amountPercentageDisabled: true, checkboxAmount: true })
      this.props.change('amountCampaign', null)
      this.props.change('percentageCampaign', null)
      this.props.change('percentageBrand', null)
      this.props.change('amountBrand', null)
    } else {
      this.setState({ amountDisabled: false, amountPercentageDisabled: false, checkboxAmount: false })
    }
  }

  checkComboBoxFree (props) {
    if (props.campaign.amountFree) {
      this.setState({ checkboxAmount: false, checkboxPercentage: true })
    } else if (props.campaign.percentageFree) {
      this.setState({ checkboxAmount: true, checkboxPercentage: false })
    }
  }

  handleAmount (value, field) {
    if (value) {
      this.setState({ amountPercentageDisabled: true, [field]: true })
    } else {
      this.setState({ [field]: false }, () => {
        if (!this.state.amount && !this.state.amountBrand) {
          this.setState({ amountPercentageDisabled: false })
        }
      })
    }
  }

  handleAmountPercentage (value, field) {
    if (value) {
      this.setState({ amountDisabled: true, [field]: true })
    } else {
      this.setState({ [field]: false }, () => {
        if (!this.state.amountPercentage && !this.state.amountBrandPercentage) {
          this.setState({ amountDisabled: false })
        }
      })
    }
  }

  render () {
    const {
      t, handleSubmit,
      showModal, nonEditable,
      combos: { campaignGroup, campaignClass, campaignType, familyCombo, externalCode }
    } = this.props
    const tKey = 'MASTERS.CAMPAIGN_DETAILS_MASTERS.MODAL.'
    return (
      <Modal className="role-modal languages-modal" show={showModal} onHide={this.props.actions.closeCampaignMasterModal}>
        <form autoComplete="off" onSubmit={handleSubmit(this.submitCampaign.bind(this))}>
          <Modal.Header closeButton onHide={this.props.actions.closeCampaignMasterModal}>
            <Modal.Title>{t(`${tKey}TITLE`)}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <Row className="inputs-wrapper">
                <Col sm={4} className="custom-width-col">
                  <h2>{t(`${tKey}CLASS`)}</h2>
                  <Field
                    name="campaignClassId"
                    placeholder={t(`${tKey}CLASS`)}
                    valueKey="id"
                    labelKey="value"
                    component={InputSelect}
                    colSm={0}
                    options={campaignClass}
                    onChange={(value) => this.handleCampaignClass(value)}
                    disabled={nonEditable}
                  />
                </Col>
                <Col sm={4} className="custom-width-col">
                  <h2>{t(`${tKey}GROUP`)}</h2>
                  <Field
                    name="campaignGroupId"
                    placeholder={t(`${tKey}GROUP`)}
                    valueKey="id"
                    labelKey="value"
                    colSm={0}
                    component={InputSelect}
                    options={campaignGroup}
                    disabled={this.state.campaingGroupDisabled || nonEditable}
                    onChange={(value) => this.handleCampaignGroup(value)}
                  />
                </Col>
                <Col sm={4} className="custom-width-col">
                  <h2>{t(`${tKey}TYPE`)}</h2>
                  <Field
                    name="campaignTypeId"
                    placeholder={t(`${tKey}TYPE`)}
                    valueKey="id"
                    labelKey="value"
                    colSm={0}
                    component={InputSelect}
                    options={campaignType}
                    disabled={this.state.campaingTypeDisabled || nonEditable}
                  />
                </Col>

                <div className="clearfix" />
                <Col sm={4} className="custom-width-col">
                  <h2>{t(`${tKey}CODE`)}</h2>
                  <Field
                    name="campaignDetailsCode"
                    placeholder={t(`${tKey}CODE`)}
                    component={InputText}
                    disabled={true}
                  />
                </Col>
                <Col sm={8} className="custom-width-col">
                  <h2>{t(`${tKey}DESCRIPTION`)}</h2>
                  <Field
                    name="description"
                    placeholder={t(`${tKey}DESCRIPTION`)}
                    component={InputText}
                  />
                </Col>

                <div className="clearfix" />
                <Col sm={4} className="custom-width-col">
                  <h2>{t(`${tKey}STARTDATE`)}</h2>
                  <Field
                    name="startDate"
                    placeholder={t(`${tKey}STARTDATE`)}
                    component={InputDatePicker}
                  />
                </Col>
                <Col sm={4} className="custom-width-col">
                  <h2>{t(`${tKey}ENDDATE`)}</h2>
                  <Field
                    name="endDate"
                    placeholder={t(`${tKey}ENDDATE`)}
                    component={InputDatePicker}
                  />
                </Col>
                <div className="clearfix"/>
                <Col sm={4} className="custom-width-col">
                  <h2>{t(`${tKey}AMOUNT_CAMPAIGN`)}</h2>
                  <Field
                    name="amountCampaign"
                    placeholder={t(`${tKey}AMOUNT_CAMPAIGN`)}
                    component={InputMoney}
                    disabled={this.state.amountDisabled}
                    onChange={(value) => this.handleAmount(value.nativeEvent.data, 'amount') }
                  />
                </Col>
                <Col sm={4} className="custom-width-col">
                  <h2>{t(`${tKey}PERCENTAGE_CAMPAIGN`)}</h2>
                  <Field
                    name="percentageCampaign"
                    placeholder={t(`${tKey}PERCENTAGE_CAMPAIGN`)}
                    component={InputPercentage}
                    disabled={this.state.amountPercentageDisabled}
                    onChange={(value) => this.handleAmountPercentage(value.nativeEvent.data, 'amountPercentage') }
                  />
                </Col>
                <Col sm={4} className="custom-width-col">
                  <h2>{t(`${tKey}EXTERNALCODE`)}</h2>
                  <Field
                    name="externalCode"
                    component={InputSelect}
                    options={externalCode}
                    valueKey="campaignExternalCodeId"
                    labelKey="code"
                    placeholder={t(`${tKey}EXTERNALCODE`)}
                    colSm={0}
                  />
                </Col>

                <div className="clearfix" />
                <Col sm={4} className="custom-width-col">
                  <h2>{t(`${tKey}AMOUNT_BRAND`)}</h2>
                  <Field
                    name="amountBrand"
                    placeholder={t(`${tKey}AMOUNT_BRAND`)}
                    component={InputMoney}
                    disabled={this.state.amountDisabled}
                    onChange={(value) => this.handleAmount(value.nativeEvent.data, 'amountBrand') }
                  />
                </Col>

                <Col sm={4} className="custom-width-col">
                  <h2>{t(`${tKey}PERCENTAGE_BRAND`)}</h2>
                  <Field
                    name="percentageBrand"
                    placeholder={t(`${tKey}PERCENTAGE_BRAND`)}
                    component={InputPercentage}
                    disabled={this.state.amountPercentageDisabled}
                    onChange={(value) => this.handleAmountPercentage(value.nativeEvent.data, 'amountBrandPercentage') }
                  />
                </Col>
                <Col sm={4} className="custom-width-col">
                  <h2>{t(`${tKey}GAMA`)}</h2>
                  <Field
                    name="gama"
                    component={InputSelect}
                    options={familyCombo}
                    valueKey="familyId"
                    labelKey="name"
                    placeholder={t(`${tKey}GAMA`)}
                    colSm={0}
                  />
                </Col>

                <div className="clearfix" />
                <Col sm={12} className="custom-width-col">
                  <h2>{t(`${tKey}BONIFIED`)}</h2>
                  <Field
                    name="bonified"
                    placeholder={t(`${tKey}BONIFIED`)}
                    component={InputText}
                  />
                </Col>

                <div className="clearfix" />
                <Col sm={12} className="custom-width-col">
                  <h2>{t(`${tKey}OBSERVATIONS`)}</h2>
                  <Field
                    name="observations"
                    placeholder={t(`${tKey}OBSERVATIONS`)}
                    component={InputText}
                  />
                </Col>

                <div className="clearfix"/>
                <Col sm={3} className="custom-width-col">
                  <h2>{t(`${tKey}APPROVAL_REQUIRED`)}</h2>
                  <Field
                    name="approvalRequired"
                    placeholder={t(`${tKey}APPROVAL_REQUIRED`)}
                    component={InputCheckBox}
                  />
                </Col>
                <Col sm={3} className="custom-width-col">
                  <h2>{t(`${tKey}FREE_AMOUNT`)}</h2>
                  <Field
                    name="amountFree"
                    placeholder={t(`${tKey}FREE_AMOUNT`)}
                    component={InputCheckBox}
                    disabled = {this.state.checkboxAmount}
                    onChange={(value) => this.handleAmountFree(value)}
                  />
                </Col>
                <Col sm={3} className="custom-width-col">
                  <h2>{t(`${tKey}FREE_PERCENTAGE`)}</h2>
                  <Field
                    name="percentageFree"
                    placeholder={t(`${tKey}FREE_PERCENTAGE`)}
                    component={InputCheckBox}
                    disabled = {this.state.checkboxPercentage}
                    onChange={(value) => this.handlePercentageFree(value)}
                  />
                </Col>
                <Col sm={3} className="custom-width-col">
                  <h2>{t(`${tKey}ACTIVE`)}</h2>
                  <Field
                    name="active"
                    placeholder={t(`${tKey}ACTIVE`)}
                    component={InputCheckBox}
                  />
                </Col>
                <Col sm={3} className="custom-width-col">
                  <h2>{t(`${tKey}VISIBLE`)}</h2>
                  <Field
                    name="visible"
                    placeholder={t(`${tKey}VISIBLE`)}
                    component={InputCheckBox}
                  />
                </Col>
              </Row>
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <Col sm={12} className="btn-wrapper">
              <Button
                id="role-modal-submit-button"
                className="btn-standard"
                type="submit"
                disabled={this.props.pristine}>
                {t(`${tKey}SUBMIT_BUTTON`)}
              </Button>
            </Col>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default reduxForm({
  form: 'editCampaign',
  validate: (values, props) => {
    const error = { agency: {} }
    if (values) {
      const tKey = 'MASTERS.CAMPAIGN_DETAILS_MASTERS.VALIDATION.'
      if (!values.campaignClassId) {
        error.campaignClassId = props.t(`${tKey}CLASS`)
      }
      if (!values.campaignGroupId || values.campaignGroupId === null || values.campaignGroupId === -1) {
        error.campaignGroupId = props.t(`${tKey}GROUP`)
      }
      if (!values.campaignTypeId || values.campaignTypeId === null || values.campaignTypeId === -1) {
        error.campaignTypeId = props.t(`${tKey}TYPE`)
      }
      if (!values.startDate) {
        error.startDate = props.t(`${tKey}STARTDATE`)
      }
      if (!values.endDate) {
        error.endDate = props.t(`${tKey}ENDDATE`)
      }
      if (!values.amountFree && !values.percentageFree) {
        if (!values.amountCampaign && !values.amountCampaign && !values.percentageCampaign && !values.percentageBrand) {
          error.percentageCampaign = props.t(`${tKey}PERCENTAGE_CAMPAIGN`)
          error.amountCampaign = props.t(`${tKey}AMOUNT_CAMPAIGN`)
          error.amountBrand = props.t(`${tKey}AMOUNT_BRAND`)
          error.percentageBrand = props.t(`${tKey}PERCENTAGE_BRAND`)
        }
        if (values.amountCampaign || values.amountCampaign) {
          if (!values.amountCampaign) {
            error.amountCampaign = props.t(`${tKey}AMOUNT_CAMPAIGN`)
          }
          if (values.amountCampaign < 0) {
            error.amountCampaign = props.t(`${tKey}AMOUNT_CAMPAIGN_ZERO`)
          }
          if (!values.amountBrand || values.amountCampaign < 0) {
            error.amountBrand = props.t(`${tKey}AMOUNT_BRAND`)
          }
          if (values.amountBrand < 0) {
            error.amountBrand = props.t(`${tKey}AMOUNT_BRAND_ZERO`)
          }
          if (parseFloat(values.amountBrand) > parseFloat(values.amountCampaign)) {
            error.amountBrand = props.t(`${tKey}AMOUNT_BIGGER_THAN_CAMPAIGN`)
          }
        } else {
          if (!values.percentageCampaign) {
            error.percentageCampaign = props.t(`${tKey}PERCENTAGE_CAMPAIGN`)
          }
          if (!values.percentageBrand) {
            error.percentageBrand = props.t(`${tKey}PERCENTAGE_BRAND`)
          }
          if (values.percentageCampaign > 100 || values.percentageCampaign < 0) {
            error.percentageCampaign = props.t(`${tKey}PERCENTAGE_CAMPAIGN_VALUE`)
          }
          if (values.percentageBrand > 100 || values.percentageBrand < 0) {
            error.percentageBrand = props.t(`${tKey}PERCENTAGE_BRAND_VALUE`)
          }
          if (parseFloat(values.percentageBrand) > parseFloat(values.percentageCampaign)) {
            error.percentageBrand = props.t(`${tKey}PERCENTAGE_BIGGER_THAN_CAMPAIGN`)
          }
        }
      }
      if (!values.bonified) {
        error.bonified = props.t(`${tKey}BONIFIED`)
      }
      if (!values.gama) {
        error.gama = props.t(`${tKey}GAMA`)
      }
      if (!values.description) {
        error.description = props.t(`${tKey}DESCRIPTION`)
      }
      if (!values.externalCode) {
        error.externalCode = props.t(`${tKey}EXTERNALCODE`)
      }
    }
    return Object.keys(error).length > 0 ? error : {}
  }
})(CampaignMasterModal)
