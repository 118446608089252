import React, { PureComponent } from 'react'
// import { Row } from 'react-bootstrap'
import { Field } from 'redux-form'
import InputText from '../../../../../_v2/components/commons/form/InputText'
import { permissions } from '../../../../../constants/backendIds'
import NormalizedAddress from '../../../../../components/dossiers/common/client/NormalizedAddress'

class Contact extends PureComponent {
  constructor (props) {
    super()
    this.state = {
      alert: true,
      allInformationDisabled: true,
      disableCountry: false,
      disableState: false,
      disableCity: false,
      disableTown: false,
      disableColony: false,
      disableCP: false
    }
  }

  disableEntityAddressCombos () {
    this.setState({
      disableCountry: !!this.props.dossier.entityComponent.stateCode,
      disableState: !!this.props.dossier.entityComponent.cityCode,
      disableCity: !!this.props.dossier.entityComponent.townCode,
      disableTown: !!this.props.dossier.entityComponent.colonyCode,
      disableColony: !!this.props.dossier.entityComponent.postalCodeCode,
      disableCP: !this.props.dossier.entityComponent.colonyCode
    })
  }

  handleEntityCountryChange (code) {
    if (code) {
      this.props.actions.fetchEntityStateTypeCombo(this.props.combos.entityCountryTypeCombo?.find(x => x.code === code)?.id)
      this.setState({ disableCountry: true, disableState: false })
    }
  }

  handleEntityStateChange (code) {
    if (code) {
      this.props.actions.fetchEntityCityTypeCombo(this.props.combos.entityStateTypeCombo?.find(x => x.code === code)?.id)
      this.setState({ disableState: true, disableCity: false })
    } else {
      this.setState({ disableCountry: false, disableState: true })
    }
  }

  handleEntityCityChange (code) {
    if (code) {
      this.props.actions.fetchEntityTownTypeCombo(this.props.combos.entityCityTypeCombo?.find(x => x.code === code)?.id)
      this.setState({ disableCity: true, disableTown: false })
    } else {
      this.setState({ disableState: false, disableCity: true })
    }
  }

  handleEntityTownChange (code) {
    if (code) {
      this.props.actions.fetchEntityColonyTypeCombo(this.props.combos.entityTownTypeCombo?.find(x => x.code === code)?.id)
      this.setState({ disableTown: true, disableColony: false })
    } else {
      this.setState({ disableCity: false, disableTown: true })
    }
  }

  handleEntityColonyChange (code) {
    if (code) {
      this.props.actions.fetchEntityCPTypeCombo(this.props.combos.entityColonyTypeCombo?.find(x => x.code === code)?.id)
      this.setState({ disableColony: true, disableCP: false })
    } else {
      this.setState({ disableTown: false, disableColony: true })
    }
  }

  handleEntityCPChange (code) {
    if (!code) {
      this.setState({ disableColony: false, disableCP: true })
    }
  }

  validateSchema () {
    const {
      fieldsConfiguration
    } = this.props
    return (
      (fieldsConfiguration.contactStartStreet && fieldsConfiguration.contactStartStreet.permission !== permissions.hidden) ||
     (fieldsConfiguration.contactEnndStreet && fieldsConfiguration.contactEnndStreet.permission !== permissions.hidden) ||
     (fieldsConfiguration.contactExternalNumber && fieldsConfiguration.contactExternalNumber.permission !== permissions.hidden) ||
     (fieldsConfiguration.contactInternalNumber && fieldsConfiguration.contactInternalNumber.permission !== permissions.hidden) ||
     (fieldsConfiguration.contactCountryCode && fieldsConfiguration.contactCountryCode.permission !== permissions.hidden) ||
     (fieldsConfiguration.contactStateCode && fieldsConfiguration.contactStateCode.permission !== permissions.hidden) ||
     (fieldsConfiguration.contactCityCode && fieldsConfiguration.contactCityCode.permission !== permissions.hidden) ||
     (fieldsConfiguration.contactTownCode && fieldsConfiguration.contactTownCode.permission !== permissions.hidden) ||
     (fieldsConfiguration.contactColonyCode && fieldsConfiguration.contactColonyCode.permission !== permissions.hidden) ||
     (fieldsConfiguration.contactCpCode && fieldsConfiguration.contactCpCode.permission !== permissions.hidden)
    )
  }

  shortSchema () {
    const {
      t,
      fieldsConfiguration,
      readOnlyFields,
      disabledByNotSelectedEntityTypeId
    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.ENTITY.'
    return (
      <>
        {fieldsConfiguration.contactName &&
            fieldsConfiguration.contactName.permission !==
              permissions.hidden && (
          <Field
            component={InputText}
            colWidht={33} // 25, 33, 50, 75, 100 default=25
            id="contactName"
            name="dossierContactComponent.contactName"
            key="dossierContactComponent.contactName"
            controlLabel={t(`${tKey}CONTACT_NAME`)}
            placeholder={t(`${tKey}CONTACT_NAME`)}
            disabled={
              readOnlyFields ||
                  fieldsConfiguration.contactName.permission !==
                    permissions.editable ||
                  disabledByNotSelectedEntityTypeId
            }
          />
        )}
        {fieldsConfiguration.contactSurname1 &&
            fieldsConfiguration.contactSurname1.permission !==
              permissions.hidden && (
          <Field
            component={InputText}
            colWidht={33} // 25, 33, 50, 75, 100 default=25
            id="contactSurname1"
            name="dossierContactComponent.contactSurname1"
            key="dossierContactComponent.contactSurname1"
            controlLabel={t(`${tKey}CONTACT_SURNAME1`)}
            placeholder={t(`${tKey}CONTACT_SURNAME1`)}
            disabled={
              readOnlyFields ||
                  fieldsConfiguration.contactSurname1.permission !==
                    permissions.editable ||
                  disabledByNotSelectedEntityTypeId
            }
          />
        )}
        {fieldsConfiguration.contactSurname2 &&
            fieldsConfiguration.contactSurname2.permission !==
              permissions.hidden && (
          <Field
            component={InputText}
            colWidht={33} // 25, 33, 50, 75, 100 default=25
            id="contactSurname2"
            name="dossierContactComponent.contactSurname2"
            key="dossierContactComponent.contactSurname2"
            controlLabel={t(`${tKey}CONTACT_SURNAME2`)}
            placeholder={t(`${tKey}CONTACT_SURNAME2`)}
            disabled={
              readOnlyFields ||
                  fieldsConfiguration.contactSurname2.permission !==
                    permissions.editable ||
                  disabledByNotSelectedEntityTypeId
            }
          />
        )}
        {fieldsConfiguration.contactEmail &&
            fieldsConfiguration.contactEmail.permission !==
              permissions.hidden && (
          <Field
            component={InputText}
            colWidht={33} // 25, 33, 50, 75, 100 default=25
            id="contactEmail"
            name="dossierContactComponent.contactEmail"
            key="dossierContactComponent.contactEmail"
            controlLabel={t(`${tKey}EMAIL`)}
            placeholder={t(`${tKey}EMAIL`)}
            disabled={
              readOnlyFields ||
                  fieldsConfiguration.contactEmail.permission !==
                    permissions.editable ||
                  disabledByNotSelectedEntityTypeId
            }
          />
        )}
        {fieldsConfiguration.contactPhone &&
            fieldsConfiguration.contactPhone.permission !==
              permissions.hidden && (
          <Field
            component={InputText}
            colWidht={33} // 25, 33, 50, 75, 100 default=25
            id="contactPhone"
            name="dossierContactComponent.contactPhone"
            key="dossierContactComponent.contactPhone"
            controlLabel={t(`${tKey}PHONE`)}
            placeholder={t(`${tKey}PHONE`)}
            disabled={
              readOnlyFields ||
                  fieldsConfiguration.contactPhone.permission !==
                    permissions.editable ||
                  disabledByNotSelectedEntityTypeId
            }
          />
        )}
        {fieldsConfiguration.contactMobilePhone &&
            fieldsConfiguration.contactMobilePhone.permission !==
              permissions.hidden && (
          <Field
            component={InputText}
            colWidht={33} // 25, 33, 50, 75, 100 default=25
            id="contactMobilePhone"
            name="dossierContactComponent.contactMobilePhone"
            key="dossierContactComponent.contactMobilePhone"
            controlLabel={t(`${tKey}MOBILE`)}
            placeholder={t(`${tKey}MOBILE`)}
            disabled={
              readOnlyFields ||
                  fieldsConfiguration.contactMobilePhone
                    .permission !== permissions.editable ||
                  disabledByNotSelectedEntityTypeId
            }
          />
        )}
        {fieldsConfiguration.contactAddress &&
            fieldsConfiguration.contactAddress.permission !==
              permissions.hidden && (
          <Field
            component={InputText}
            colWidht={33} // 25, 33, 50, 75, 100 default=25
            id="contactAddress"
            name="dossierContactComponent.contactAddress"
            key="dossierContactComponent.contactAddress"
            controlLabel={t(`${tKey}ADDRESS`)}
            placeholder={t(`${tKey}ADDRESS`)}
            disabled={
              readOnlyFields ||
                  fieldsConfiguration.contactAddress.permission !==
                    permissions.editable ||
                  disabledByNotSelectedEntityTypeId
            }
          />
        )}
        {fieldsConfiguration.contactCity &&
            fieldsConfiguration.contactCity.permission !==
              permissions.hidden && (
          <Field
            component={InputText}
            colWidht={33} // 25, 33, 50, 75, 100 default=25
            id="contactCity"
            name="dossierContactComponent.contactCity"
            key="dossierContactComponent.contactCity"
            controlLabel={t(`${tKey}CITY`)}
            placeholder={t(`${tKey}CITY`)}
            disabled={
              readOnlyFields ||
                  fieldsConfiguration.contactCity.permission !==
                    permissions.editable ||
                  disabledByNotSelectedEntityTypeId
            }
          />
        )}
        {fieldsConfiguration.contactState &&
            fieldsConfiguration.contactState.permission !==
              permissions.hidden && (
          <Field
            component={InputText}
            colWidht={33} // 25, 33, 50, 75, 100 default=25
            id="contactState"
            name="dossierContactComponent.contactState"
            key="dossierContactComponent.contactState"
            controlLabel={t(`${tKey}STATE`)}
            placeholder={t(`${tKey}STATE`)}
            disabled={
              readOnlyFields ||
                  fieldsConfiguration.contactState.permission !==
                    permissions.editable ||
                  disabledByNotSelectedEntityTypeId
            }
          />
        )}
        {fieldsConfiguration.contactCp &&
            fieldsConfiguration.contactCp.permission !==
              permissions.hidden && (
          <Field
            component={InputText}
            colWidht={33} // 25, 33, 50, 75, 100 default=25
            id="contactCp"
            name="dossierContactComponent.contactCp"
            key="dossierContactComponent.contactCp"
            controlLabel={t(`${tKey}CP`)}
            placeholder={t(`${tKey}CP`)}
            disabled={
              readOnlyFields ||
                  fieldsConfiguration.contactCp.permission !==
                    permissions.editable ||
                  disabledByNotSelectedEntityTypeId
            }
          />
        )}
        {fieldsConfiguration.contactCountry &&
            fieldsConfiguration.contactCountry.permission !==
              permissions.hidden && (
          <Field
            component={InputText}
            colWidht={33} // 25, 33, 50, 75, 100 default=25
            id="contactCountry"
            name="dossierContactComponent.contactCountry"
            key="dossierContactComponent.contactCountry"
            controlLabel={t(`${tKey}COUNTRY`)}
            placeholder={t(`${tKey}COUNTRY`)}
            disabled={
              readOnlyFields ||
                  fieldsConfiguration.contactCountry.permission !==
                    permissions.editable ||
                  disabledByNotSelectedEntityTypeId
            }
          />
        )}
      </>
    )
  }

  longSchema () {
    const {
      t,
      fieldsConfiguration,
      readOnlyFields,
      disabledByNotSelectedEntityTypeId,
      actions: {
        fetchEntityCountryTypeCombo,
        fetchEntityStateTypeCombo,
        fetchEntityCityTypeCombo,
        fetchEntityTownTypeCombo,
        fetchEntityColonyTypeCombo,
        fetchEntityCPTypeCombo,
        fetchClientDataAddress
      }
    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.ENTITY.'
    return (
      <>
        {fieldsConfiguration.contactName &&
          fieldsConfiguration.contactName.permission !==
            permissions.hidden && (
          <Field
            component={InputText}
            colWidht={33} // 25, 33, 50, 75, 100 default=25
            id="contactName"
            name="dossierContactComponent.contactName"
            key="dossierContactComponent.contactName"
            controlLabel={t(`${tKey}CONTACT_NAME`)}
            placeholder={t(`${tKey}CONTACT_NAME`)}
            disabled={
              readOnlyFields ||
                fieldsConfiguration.contactName.permission !==
                  permissions.editable ||
                disabledByNotSelectedEntityTypeId
            }
          />
        )}
        {fieldsConfiguration.contactSurname1 &&
          fieldsConfiguration.contactSurname1.permission !==
            permissions.hidden && (
          <Field
            component={InputText}
            colWidht={33} // 25, 33, 50, 75, 100 default=25
            id="contactSurname1"
            name="dossierContactComponent.contactSurname1"
            key="dossierContactComponent.contactSurname1"
            controlLabel={t(`${tKey}CONTACT_SURNAME1`)}
            placeholder={t(`${tKey}CONTACT_SURNAME1`)}
            disabled={
              readOnlyFields ||
                fieldsConfiguration.contactSurname1.permission !==
                  permissions.editable ||
                disabledByNotSelectedEntityTypeId
            }
          />
        )}
        {fieldsConfiguration.contactSurname2 &&
          fieldsConfiguration.contactSurname2.permission !==
            permissions.hidden && (
          <Field
            component={InputText}
            colWidht={33} // 25, 33, 50, 75, 100 default=25
            id="contactSurname2"
            name="dossierContactComponent.contactSurname2"
            key="dossierContactComponent.contactSurname2"
            controlLabel={t(`${tKey}CONTACT_SURNAME2`)}
            placeholder={t(`${tKey}CONTACT_SURNAME2`)}
            disabled={
              readOnlyFields ||
                fieldsConfiguration.contactSurname2.permission !==
                  permissions.editable ||
                disabledByNotSelectedEntityTypeId
            }
          />
        )}
        {fieldsConfiguration.contactEmail &&
          fieldsConfiguration.contactEmail.permission !==
            permissions.hidden && (
          <Field
            component={InputText}
            colWidht={33} // 25, 33, 50, 75, 100 default=25
            id="contactEmail"
            name="dossierContactComponent.contactEmail"
            key="dossierContactComponent.contactEmail"
            controlLabel={t(`${tKey}EMAIL`)}
            placeholder={t(`${tKey}EMAIL`)}
            disabled={
              readOnlyFields ||
                fieldsConfiguration.contactEmail.permission !==
                  permissions.editable ||
                disabledByNotSelectedEntityTypeId
            }
          />
        )}
        {fieldsConfiguration.contactPhone &&
          fieldsConfiguration.contactPhone.permission !==
            permissions.hidden && (
          <Field
            component={InputText}
            colWidht={33} // 25, 33, 50, 75, 100 default=25
            id="contactPhone"
            name="dossierContactComponent.contactPhone"
            key="dossierContactComponent.contactPhone"
            controlLabel={t(`${tKey}PHONE`)}
            placeholder={t(`${tKey}PHONE`)}
            disabled={
              readOnlyFields ||
                fieldsConfiguration.contactPhone.permission !==
                  permissions.editable ||
                disabledByNotSelectedEntityTypeId
            }
          />
        )}
        {fieldsConfiguration.contactMobilePhone &&
          fieldsConfiguration.contactMobilePhone.permission !==
            permissions.hidden && (
          <Field
            component={InputText}
            colWidht={33} // 25, 33, 50, 75, 100 default=25
            id="contactMobilePhone"
            name="dossierContactComponent.contactMobilePhone"
            key="dossierContactComponent.contactMobilePhone"
            controlLabel={t(`${tKey}MOBILE`)}
            placeholder={t(`${tKey}MOBILE`)}
            disabled={
              readOnlyFields ||
                fieldsConfiguration.contactMobilePhone
                  .permission !== permissions.editable ||
                disabledByNotSelectedEntityTypeId
            }
          />
        )}
        <NormalizedAddress
          t={t}
          fieldsConfiguration={{
            countryCode: fieldsConfiguration.contactCountryCode,
            stateCode: fieldsConfiguration.contactStateCode,
            cityCode: fieldsConfiguration.contactCityCode,
            townCode: fieldsConfiguration.contactTownCode,
            colonyCode: fieldsConfiguration.contactColonyCode,
            cpCode: fieldsConfiguration.contactCpCode
          }}
          readOnlyFields={readOnlyFields}
          allInformationDisabled={disabledByNotSelectedEntityTypeId}
          entityComponent= {this.props.dossier.dossierContactComponent}
          entityTypeName= "dossierContactComponent"
          actions={{
            fetchEntityCountryTypeCombo,
            fetchEntityStateTypeCombo,
            fetchEntityCityTypeCombo,
            fetchEntityTownTypeCombo,
            fetchEntityColonyTypeCombo,
            fetchEntityCPTypeCombo,
            fetchClientDataAddress
          }}
        />

        {fieldsConfiguration.contactAddress &&
          fieldsConfiguration.contactAddress.permission !==
            permissions.hidden && (
          <Field
            component={InputText}
            colWidht={75} // 25, 33, 50, 75, 100 default=25
            id="contactAddress"
            name="dossierContactComponent.contactAddress"
            key="dossierContactComponent.contactAddress"
            controlLabel={t(`${tKey}ADDRESS`)}
            placeholder={t(`${tKey}ADDRESS`)}
            disabled={
              readOnlyFields ||
                fieldsConfiguration.contactAddress.permission !==
                  permissions.editable ||
                disabledByNotSelectedEntityTypeId
            }
          />
        )}
        {fieldsConfiguration.contactExternalNumber && fieldsConfiguration.contactExternalNumber.permission !== permissions.hidden && (
          <Field
            component={InputText}
            colWidht={25} // 25, 33, 50, 75, 100 default=25
            id="externalNumber"
            name="dossierContactComponent.externalNumber"
            key="dossierContactComponent.externalNumber"
            disabled={
              readOnlyFields ||
                fieldsConfiguration.contactExternalNumber.permission !==
                  permissions.editable ||
                disabledByNotSelectedEntityTypeId
            }
            controlLabel={t(`${tKey}EXTERNAL_NUMBER`)}
            placeholder={t(`${tKey}EXTERNAL_NUMBER`)}
            maxLength="20"
          />
        )}
        {fieldsConfiguration.contactStartStreet && fieldsConfiguration.contactStartStreet.permission !== permissions.hidden && (
          <Field
            component={InputText}
            colWidht={75} // 25, 33, 50, 75, 100 default=25
            id="startStreet"
            name="dossierContactComponent.startStreet"
            key="dossierContactComponent.startStreet"
            disabled={
              readOnlyFields ||
                fieldsConfiguration.contactStartStreet.permission !==
                  permissions.editable ||
                disabledByNotSelectedEntityTypeId
            }
            controlLabel={t(`${tKey}START_STREET`)}
            placeholder={t(`${tKey}START_STREET`)}
            maxLength="200"
          />
        )}
        {fieldsConfiguration.contactInternalNumber && fieldsConfiguration.contactInternalNumber.permission !== permissions.hidden && (
          <Field
            component={InputText}
            colWidht={25} // 25, 33, 50, 75, 100 default=25
            id="internalNumber"
            name="dossierContactComponent.internalNumber"
            key="dossierContactComponent.internalNumber"
            disabled={
              readOnlyFields ||
                fieldsConfiguration.contactInternalNumber.permission !==
                  permissions.editable ||
                disabledByNotSelectedEntityTypeId
            }
            controlLabel={t(`${tKey}INTERNAL_NUMBER`)}
            placeholder={t(`${tKey}INTERNAL_NUMBER`)}
            maxLength="200"
          />
        )}
        {fieldsConfiguration.contactEnndStreet && fieldsConfiguration.contactEnndStreet.permission !== permissions.hidden && (
          <Field
            component={InputText}
            colWidht={75} // 25, 33, 50, 75, 100 default=25
            id="enndStreet"
            name="dossierContactComponent.enndStreet"
            key="dossierContactComponent.enndStreet"
            disabled={
              readOnlyFields ||
                fieldsConfiguration.contactEnndStreet.permission !==
                  permissions.editable ||
                disabledByNotSelectedEntityTypeId
            }
            controlLabel={t(`${tKey}ENND_STREET`)}
            placeholder={t(`${tKey}ENND_STREET`)}
            maxLength="20"
          />
        )}
      </>
    )
  }

  render () {
    return (
      <div>
        { !this.validateSchema() ? (
          this.shortSchema()
        ) : (
          this.longSchema()
        ) }
      </div>
    )
  }
}

export default Contact
