import React, { PureComponent } from 'react'
import { reduxForm, Field } from 'redux-form'
import { Row, Col, Table, Button, Clearfix } from 'react-bootstrap'
import IDocCarPagination from '../../commons/iDocCarPagination'
import InputText from '../../commons/form/InputText'
import InputDatePicker from '../../commons/form/InputDatePicker'
import InputSelect from '../../commons/form/InputSelect'
import { formatMoney } from '../../../util/utils'
import moment from 'moment'

class BuyReport extends PureComponent {
  constructor () {
    super()
    this.state = {
      filter: {
        searchSociety: null,
        searchBrand: null,
        searchModel: null,
        searchDateOrderInitial: null,
        searchDateOrderEnd: null
      }
    }
  }

  searchBuy () {
    this.props.actions.fetchBuyList({ ...this.props.filter, ...this.state.filter, page: 1 })
  }

  downloadBuy () {
    this.props.actions.downloadBuyList({ ...this.props.filter, ...this.state.filter })
  }

  componentDidMount () {
    this.props.actions.fetchBrands()
    this.searchBuy()
  }

  handleSocietyChange (searchSociety) {
    if (this.state.filter.searchSociety !== searchSociety) {
      this.setState({ filter: { ...this.state.filter, searchSociety: searchSociety } })
    }
  }

  handleBrandChange (searchBrand) {
    if (this.state.filter.searchBrand !== searchBrand) {
      this.setState({ filter: { ...this.state.filter, searchBrand: searchBrand } })
    }
  }

  handleModelChange (searchModel) {
    if (this.state.filter.searchModel !== searchModel) {
      this.setState({ filter: { ...this.state.filter, searchModel: searchModel } })
    }
  }

  handleDateOrderInitialChange (searchDateOrderInitial) {
    if (this.state.filter.searchDateOrderInitial !== searchDateOrderInitial && searchDateOrderInitial !== null) {
      const [dd, mm, yyyy] = searchDateOrderInitial.split('/')
      this.setState({ filter: { ...this.state.filter, searchDateOrderInitial: new Date(`${yyyy}-${mm}-${dd}T00:00:01.000Z`) } })
    } else {
      this.setState({ filter: { ...this.state.filter, searchDateOrderInitial: null } })
    }
  }

  handleDateOrderEndChange (searchDateOrderEnd) {
    if (this.state.filter.searchDateOrderEnd !== searchDateOrderEnd && searchDateOrderEnd !== null) {
      const [dd, mm, yyyy] = searchDateOrderEnd.split('/')
      this.setState({ filter: { ...this.state.filter, searchDateOrderEnd: new Date(`${yyyy}-${mm}-${dd}T23:59:59.000Z`) } })
    } else {
      this.setState({ filter: { ...this.state.filter, searchDateOrderEnd: null } })
    }
  }

  render () {
    const {
      t, buyList, pagesCount, filter, buyCount,
      filter: { page },
      actions: { fetchBuyList },
      combos: { brandCombo, lowestOrganizedUnitsCombo }
    } = this.props
    const tKey = 'REPORTS.'
    return (
      <div className="admin-wrapper">
        <Row className="section-users-header">
          <Col sm={12}>
            <div className="header-panel">
              <h4>{t(`${tKey}BUY.BUY_TITLE`)}</h4>
            </div>
          </Col>
        </Row>
        <Row>
          <div className="search-form">
            <span className="title-search"><i className="ico-search" /> {t(`${tKey}BUY.SEARCH_TITLE`)}</span>
            <Row>
              <Field
                colSm={3}
                id="society"
                name="filters.society"
                controlLabel={t(`${tKey}BUY.SOCIETY`)}
                placeholder={t(`${tKey}BUY.SOCIETY`)}
                component={InputSelect}
                options={lowestOrganizedUnitsCombo}
                valueKey="id"
                labelKey="value"
                onInputChange={(value) => this.handleSocietyChange(value)}
              />
              <Field
                colSm={3}
                id="vehicleBrand"
                name="filters.vehicleBrand"
                controlLabel={t(`${tKey}BUY.BRAND`)}
                placeholder={t(`${tKey}BUY.BRAND`)}
                component={InputSelect}
                valueKey="id"
                labelKey="value"
                options={brandCombo}
                onInputChange={(value) => this.handleBrandChange(value)}
              />
              <Field
                id="model"
                name="filters.model"
                colSm={3}
                controlLabel={t(`${tKey}BUY.MODEL`)}
                placeholder={t(`${tKey}BUY.MODEL`)}
                component={InputText}
                onInputChange={(value) => this.handleModelChange(value)}
              />
              <Field
                id="initialdate"
                name="filters.initialdate"
                colSm={3}
                controlLabel={t(`${tKey}BUY.INITIAL_ORDER_DATE`)}
                placeholder={t(`${tKey}BUY.INITIAL_ORDER_DATE`)}
                component={InputDatePicker}
                onInputChange={(value) => this.handleDateOrderInitialChange(value)}
              />
              <Clearfix/>
              <Field
                id="enddate"
                name="filters.enddate"
                colSm={3}
                controlLabel={t(`${tKey}BUY.END_ORDER_DATE`)}
                placeholder={t(`${tKey}BUY.END_ORDER_DATE`)}
                component={InputDatePicker}
                onInputChange={(value) => this.handleDateOrderEndChange(value)}
              />
              <Col sm={3} className="btn-wrapper">
                <Button
                  className={'btn-white btn-icon btn-input btn-white search-button'}
                  style={{ width: '100%' }}
                  onClick={() => this.searchBuy()}>
                  <i className="ico-search" />
                  {t(`${tKey}SEARCH`)}
                </Button>
              </Col>
            </Row>
          </div>
        </Row>
        <Col>
          <Button
            className={'btn-white btn-icon btn-input btn-white'}
            style={{ float: 'right' }}
            onClick={() => this.downloadBuy()}>
            {t(`${tKey}DOWNLOAD`)}
          </Button>
        </Col>
        <Col sm={12} className="col-row  section-users-body">
          <Table className="editable-table admin-users" hover responsive>
            <thead>
              <tr>
                <th>
                  {t(`${tKey}BUY.DOSSIER`)}&nbsp;
                </th>
                <th>
                  {t(`${tKey}BUY.SOCIETY`)}&nbsp;
                </th>
                <th>
                  {t(`${tKey}BUY.BRAND`)}&nbsp;
                </th>
                <th>
                  {t(`${tKey}BUY.ORDER_DATE`)}&nbsp;
                </th>
                <th>
                  {t(`${tKey}BUY.BUY_DATE`)}&nbsp;
                </th>
                <th>
                  {t(`${tKey}BUY.ARRIVAL_DATE`)}&nbsp;
                </th>
                <th>
                  {t(`${tKey}BUY.LOCATION`)}&nbsp;
                </th>
                <th>
                  {t(`${tKey}BUY.CLIENT`)}&nbsp;
                </th>
                <th>
                  {t(`${tKey}BUY.SALESMAN`)}&nbsp;
                </th>
                <th>
                  {t(`${tKey}BUY.MODEL`)}&nbsp;
                </th>
                <th>
                  {t(`${tKey}BUY.VINCHASSIS`)}&nbsp;
                </th>
                <th>
                  {t(`${tKey}BUY.PURCHASE_AMOUNT`)}&nbsp;
                </th>
                <th>
                  {t(`${tKey}BUY.TRANSPORT`)}&nbsp;
                </th>
                <th>
                  {t(`${tKey}BUY.TRANSPORT_COST`)}&nbsp;
                </th>
                <th>
                  {t(`${tKey}BUY.BUY_BILL`)}&nbsp;
                </th>
              </tr>
            </thead>
            <tbody>
              {buyList && buyList.map((buy, idx) => {
                return (
                  <tr key={idx} className = "">
                    <td>{buy.dossierNumber}</td>
                    <td>{buy.organizedUnitName}</td>
                    <td>{buy.brandName}</td>
                    <td>{moment(buy.creationDate).format('DD/MM/YYYY')}</td>
                    <td>{moment(buy.billingDate).format('DD/MM/YYYY')}</td>
                    <td>{moment(buy.realDeliveryDateTime).format('DD/MM/YYYY')}</td>
                    <td>{buy.deliveryCity}</td>
                    <td>{buy.entityName}</td>
                    <td>{buy.salesmanName}</td>
                    <td>{buy.model}</td>
                    <td>{buy.vinChassis}</td>
                    <td>{formatMoney(buy.amount)}</td>
                    <td>{formatMoney(buy.transportationAmount)}</td>
                    <td>{formatMoney(buy.transportationCost)}</td>
                    <td>{buy.billingNumber}</td>
                  </tr>)
              })
              }
              {(buyList === null || buyList === undefined || buyList.length === 0) &&
            <tr>
              <td colSpan={15}><h2>{t(`${tKey}RESULTS_NOT_FOUND`)}</h2></td>
            </tr>}
            </tbody>
          </Table>
          <div className="section-users-footer">
            <nav key={1} className="text-center">
              <IDocCarPagination
                id="btn_pag_sales"
                page={page}
                pagesCount={pagesCount}
                totalRows={buyCount}
                onSelectAction={(value) => fetchBuyList({ ...filter, ...this.state.filter, page: value })}
              />
            </nav>
          </div>
        </Col>
      </div>
    )
  }
}

export default reduxForm({
  form: 'listBuy'
})(BuyReport)
