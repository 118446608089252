import React, { PureComponent } from 'react'
import { Row, Button, Form, DropdownButton, Glyphicon } from 'react-bootstrap'
import { Field, reduxForm, reset } from 'redux-form'
import InputText from '../commons/form/InputText'
import { fromCamelToUnderscore, convertStringToDate, convertUTCDateToLocalDate } from '../../util/utils'
import SimpleTablePage from '../../containers/commons/general/SimpleTablePage'
import IDocCarPagination from '../commons/iDocCarPagination'
import InputSelect from '../commons/form/InputSelect'
import InputDatePicker from '../commons/form/InputDatePicker'
import { validateDate } from '../../util/validationFunctions'
import { dossierTypesId } from '../../constants/dossier/common/dossierTypes'
import CreateNewFleetModalPage from '../../containers/management/CreateNewFleetModalPage'
import HistoricalFleetModalPage from '../../containers/management/HistoricalFleetModalPage'
import FleetCreatedSelectChargeModalPage from '../../containers/management/auxModals/FleetCreatedSelectChargeModalPage'
import { permissions } from '../../constants/backendIds'
import { getSectionPermissions, getFieldsSectionPermissions } from '../../util/permisionFunctions'
import { sectionPermissions } from '../../constants/dossier/common/buttonsHeader'
import AddDossiersToFleetModalPage from '../../containers/management/auxModals/AddDossiersToFleetModalPage'
import OperateFleetModalPage from '../../containers/management/OperateFleetModalPage'
import DocumentsFleetModalPage from '../../containers/management/DocumentsFleetModalPage'
import { replaceableFields } from '../../constants/dossier/common/replaceableFields'
import EconomicFleetModalPage from '../../containers/management/EconomicFleetModalPage'
import { dossierSubTypeId } from '../../constants/dossier/common/dossierSubType'
import { dossierStagesBySubtype } from '../../constants/dossier/common/dossierStages'
import ChargeDossiersModalPage from '../../containers/management/auxModals/ChargeDossiersModalPage'
import InputTreeSelect from '../commons/form/InputTreeSelect'

class FleetsManager extends PureComponent {
  constructor () {
    super()
    this.state = {
      fields: [],
      filters: [],
      dynamicFilters: [],
      disableResetButton: false,
      displayBar: 'none',
      progress: 0,
      progressFail: 0,
      errors: [],
      maxBar: 0,
      showModalObservations: false,
      observations: '',
      showModalCreate: false,
      showModalOperate: false,
      fieldsConfiguration: {
        createFleet: { permission: null },
        editFleet: { permission: null },
        operateFleet: { permission: null },
        economicFleet: { permission: null },
        documentsFleet: { permission: null },
        viewFleet: { permission: null },
        deleteFleet: { permission: null },
        codeCommission: { permission: null }
      }
    }
  }

  initializePermisions (props) {
    const section = props.sectionsGeneral.filter((tabConf) => tabConf.code === sectionPermissions.management)
    if (section.length > 0 && section[0] != null) {
      const newState = getSectionPermissions(section[0])
      let permissionState = this.state.sectionTabsConfiguration
      permissionState = { ...permissionState, ...newState }
      if (permissionState.fleet_management_section && permissionState.fleet_management_section.sectionFieldsConfiguration) {
        const fieldKeys = Object.keys(this.state.fieldsConfiguration)
        const permission = getFieldsSectionPermissions(permissionState.fleet_management_section.sectionFieldsConfiguration, fieldKeys, permissions.hidden)
        this.setState({ fieldsConfiguration: permission.fieldsConfiguration })
      }
    }
  }

  componentDidMount () {
    this.initializePermisions(this.props)
    this.props.actions.initializeSearch()
    this.props.actions.fetchDossierSubTypes(null, dossierTypesId.sales)
    this.props.actions.fetchOrganizedUnitsAcceptClientsCombo()
    this.props.actions.fetchOrganizedUnitsCombo()
  }

  submitSearchForm () {
    this.fetchTable()
  }

  fieldFill (field) {
    this.setState({ fields: { ...this.state.fields, ...field } })
  }

  selectedFilters () {
    const filters = this.state.dynamicFilters
    const tKey = 'MANAGEMENT.FLEETS_TAB.'
    return Object.keys(filters)
      .map((item, index) => {
        let text = item
        let value = null
        const list = []
        text = this.props.t(`${tKey}${fromCamelToUnderscore(item).toUpperCase()}`)
        value = filters[item]
        if (value) {
          list.push(<li key={index}>{text}: <strong>{value}</strong></li>)
        }
        return list
      })
  }

  onClickResetSearch (e) {
    if (e !== undefined) {
      e.preventDefault()
    }

    this.props.actions.modifyFleetManagementFilters()
  }

  handleClickReset (e) {
    if (e !== undefined) {
      e.preventDefault()
    }
    this.clearFields()
    this.props.actions.resetFleetManagementFilters()
    this.props.dispatch(reset('fleetsManager'))
  }

  exportData () {
    const tKey = 'MANAGEMENT.FLEETS_TAB.'
    let columnsExcel = []

    columnsExcel.push(this.props.t(`${tKey}CODE`))
    columnsExcel.push(this.props.t(`${tKey}DESCRIPTION`))
    columnsExcel.push(this.props.t(`${tKey}ORGANIZEDUNIT`))
    columnsExcel.push(this.props.t(`${tKey}DOSSIER_SUBTYPE`))
    columnsExcel.push(this.props.t(`${tKey}CLIENT`))
    columnsExcel.push(this.props.t(`${tKey}CREATION_DATE`))
    columnsExcel.push(this.props.t(`${tKey}NUM_DOSSIERS`))

    let cols = columnsExcel.join('$')

    let data = { userId: null, languageId: null, ...this.props.dynamicFilters, Columns: cols }
    this.props.actions.exportFleetsManagement(data, this.props.filters)
  }

  onOrderChange (e) {
    const newOrderBy = this.props.filters.orderBy.includes(`+${e.currentTarget.dataset.field}`) ? `-${e.currentTarget.dataset.field}` : `+${e.currentTarget.dataset.field}`
    this.fetchTable({ ...this.props.filters, orderBy: newOrderBy })
  }

  onPageChange (page) {
    this.fetchTable({ ...this.props.filters, page })
  }

  getIconClass (field) {
    if (this.props.filters.orderBy.includes(field)) {
      return this.props.filters.orderBy.includes('-') ? 'ico-sort-selected-asc' : 'ico-sort-selected-desc'
    } else {
      return 'ico-sort'
    }
  }

  haveValues (fields) {
    for (let key in fields) {
      if (fields[key] !== null && fields[key] !== undefined && fields[key] !== '') {
        return true
      }
    }
    return false
  }

  fetchTable (filters) {
    let filtersToSend = filters || this.props.filters
    if (!this.haveValues(this.state.fields)) {
      this.props.actions.openModal('mustFillFilters')
    } else {
      let data = { userId: null, languageId: null, ...this.state.fields }
      data = this.setDataDates(data)
      this.props.actions.fetchFleetsManagement(data, filtersToSend)
    }
  }

  setDataDates (data) {
    if (data.creationDateFrom) {
      data.creationDateFrom = convertUTCDateToLocalDate(convertStringToDate(data.creationDateFrom))
    }
    if (data.creationDateTo) {
      data.creationDateTo = convertUTCDateToLocalDate(convertStringToDate(data.creationDateTo))
    }
    if (data.renewalDateFrom) {
      data.renewalDateFrom = convertUTCDateToLocalDate(convertStringToDate(data.renewalDateFrom))
    }
    if (data.renewalDateTo) {
      data.renewalDateTo = convertUTCDateToLocalDate(convertStringToDate(data.renewalDateTo))
    }
    return data
  }

  clearFields () {
    this.setState({ fields: {} })
  }

  validateDate (value, allValues, props) {
    const result = validateDate(value, props)
    return result === Object(result) ? undefined : result
  }

  openObservations (row) {
    this.props.actions.openModal('observations', null, null, null, null, row.observations)
  }

  closeObservationsModal () {
    this.setState({ showModalObservations: false, observations: '' })
  }

  createNewFleet () {
    this.setState({ showModalCreate: true })
    this.props.actions.openCreateEditFleetModal(true)
  }

  historical (row) {
    new Promise((resolve) => {
      this.props.actions.getFleetModel(row.fleetId, resolve)
    }).then((response) => {
      this.setState({ showModalCreate: true })
      this.props.actions.showHistoricalFleetModal(true, response)
    })
  }

  editFleet (row) {
    new Promise((resolve) => {
      this.props.actions.getFleetModel(row.fleetId, resolve)
    }).then((response) => {
      this.setState({ showModalCreate: true })
      this.props.actions.openCreateEditFleetModal(false, response)
    })
  }

  viewFleet (row) {
    new Promise((resolve) => {
      this.props.actions.getFleetModel(row.fleetId, resolve)
    }).then((response) => {
      this.setState({ showModalCreate: true })
      this.props.actions.openCreateEditFleetModal(false, response, true)
    })
  }

  operations (row) {
    new Promise((resolve) => {
      this.props.actions.getFleetModel(row.fleetId, resolve)
    }).then((response) => {
      this.setState({ showModalOperate: true })
      this.props.actions.openOperateFleetModal(response)
    })
  }

  financialAnalysis (row) {
    if (row.dossiers && row.dossiers > 0) {
      new Promise((resolve) => {
        this.props.actions.getDossierDetailsFleet(row.fleetId, resolve)
      }).then((response) => {
        let dossierFleet = response
        let dossierShowEconomic = this.checkShowEconomicDossiers(response)
        if (dossierShowEconomic && dossierShowEconomic.length > 0) {
          let dossierStageEconomic = this.checkStageForEconomicAnalysis(dossierShowEconomic, row.dossierSubtypeId)
          if (dossierStageEconomic && dossierStageEconomic.length > 0) {
            new Promise((resolve) => {
              this.props.actions.getFleetModel(row.fleetId, resolve)
            }).then((response) => {
              if (dossierStageEconomic.length < dossierFleet.length) {
                this.props.actions.openModal('noAllEconomicDosiersOnFleet', () => this.props.actions.openEconomicFleetModal(response, false, dossierStageEconomic), null, null, null, [{ id: '##NUM_OK##', value: dossierStageEconomic.length }, { id: '##NUM_TOTAL##', value: dossierFleet.length }], null)
              } else {
                this.props.actions.openEconomicFleetModal(response, false, dossierStageEconomic)
              }
            })
          } else {
            this.props.actions.openModal('noEconomicStageDossiersOnFleet')
          }
        } else {
          this.props.actions.openModal('noEconomicDossiersOnFleet')
        }
      })
    } else {
      this.props.actions.openModal('noDossiersOnFleet')
    }
  }

  documents (row) {
    new Promise((resolve) => {
      this.props.actions.getFleetModel(row.fleetId, resolve)
    }).then((response) => {
      this.setState({ showModalDocument: true })
      this.props.actions.openDocumentFleetModal(response, false, [])
    })
  }

  openDocumentsExterntal (fleet, dossiers) {
    this.props.actions.openDocumentFleetModal(fleet, true, dossiers)
  }

  deleteFleet (row) {
    this.props.actions.openModal('setDeleteFleetProcess', (deleteDossiers) => { this.props.actions.setDeleteFleetProcess(row.fleetId, deleteDossiers) }, null, null, null, [{ id: replaceableFields.fleetCode, value: row.fleetCode }])
  }

  checkShowEconomicDossiers (dossiers) {
    let showEconomicDossiers = []
    dossiers.forEach(dossier => {
      if (dossier.showEconomic) {
        showEconomicDossiers.push(dossier)
      }
    })
    return showEconomicDossiers
  }

  checkStageForEconomicAnalysis (dossiersArray, dossierSubtypeId) {
    let dossiers = []

    dossiersArray.forEach(item => {
      if (dossierSubtypeId === dossierSubTypeId.venta_vn) {
        if (item.stageId < dossierStagesBySubtype.saleVn.documentation) {
          dossiers.push(item)
        }
      }
      if (dossierSubtypeId === dossierSubTypeId.venta_vo) {
        if (item.stageId < dossierStagesBySubtype.saleVo.documentation) {
          dossiers.push(item)
        }
      }
    })

    return dossiers
  }

  render () {
    const {
      t, className, classNameSelect, count, fleetsData, filters: { page, pages }, handleSubmit, hasSearch, combos: { dossierSubTypesCombo, UOTreeCombo }
    } = this.props
    const tKey = 'MANAGEMENT.FLEETS_TAB.'

    return (
      <>
        <div className="search-header">
          <div className="title-user">
            <i className="ico-mantenimiento-condiciones" />
            <h2>{t(`${tKey}FLEETS_MANAGEMENT`)}</h2>

          </div>
          {this.state.fieldsConfiguration.createFleet && this.state.fieldsConfiguration.createFleet.permission === permissions.editable && (
            <div>
              <Button id="btn_new_fleet"
                className="btn-standard btn-newFleet"
                onClick={(event) => this.createNewFleet(event)}>
                <Glyphicon glyph="plus"/>
                {' ' + t(`${tKey}CREATE_NEW_FLEET`)}
              </Button>
            </div>)}
        </div>
        <Form autoComplete="off" onSubmit={handleSubmit((values) => this.submitSearchForm(values))} className="filters">
          <div>
            <>
              <Row>

                <Field
                  id="fleetCode"
                  name='fleetCode'
                  placeholder={t(`${tKey}CODE`)}
                  controlLabel={t(`${tKey}CODE`)}
                  component={InputText}
                  type="text"
                  onInputChange={(val) => this.fieldFill({ fleetCode: val })}
                  customClass={this.state.fields.fleetCode ? className : ''}
                />
                <Field
                  id="organizedUnit"
                  name='organizedUnit'
                  placeholder={t(`${tKey}ORGANIZED_UNIT`)}
                  controlLabel={t(`${tKey}ORGANIZED_UNIT`)}
                  onInputChange={(val) => this.fieldFill({ organizedUnits: val })}
                  customClass={this.state.fields.organizedUnits ? className : ''}
                  component={InputTreeSelect}
                  multi={true}
                  valueKey="value"
                  labelKey="label"
                  childrenKey="children"
                  options={UOTreeCombo}
                />
                <Field
                  id="dossierSubtypeId"
                  name='dossierSubtypeId'
                  placeholder={t(`${tKey}DOSSIER_SUBTYPE`)}
                  controlLabel={t(`${tKey}DOSSIER_SUBTYPE`)}
                  component={InputSelect}
                  options={dossierSubTypesCombo}
                  valueKey="id"
                  labelKey="value"
                  onInputChange={ (val) => { this.fieldFill({ dossierSubtypeId: val }) } }
                  customClass={this.state.fields.dossierSubtypeId ? classNameSelect : ''}

                />
                <Field
                  id="client"
                  name='client'
                  placeholder={t(`${tKey}CLIENT`)}
                  controlLabel={t(`${tKey}CLIENT`)}
                  component={InputText}
                  onInputChange={(val) => this.fieldFill({ client: val })}
                  customClass={ 'col-sm-6'}

                />

                <Field
                  id="finalClient"
                  name='finalClient'
                  placeholder={t(`${tKey}FINAL_CLIENT`)}
                  controlLabel={t(`${tKey}FINAL_CLIENT`)}
                  component={InputText}
                  onInputChange={(val) => this.fieldFill({ finalClient: val })}
                  customClass={ 'col-sm-6'}

                />
                <Field
                  id="creationDateFrom"
                  name='creationDateFrom'
                  placeholder={t(`${tKey}CREATION_DATE_FROM`)}
                  controlLabel={t(`${tKey}CREATION_DATE_FROM`)}
                  component={InputDatePicker}
                  validate={[this.validateDate]}
                  onInputChange={(val) => this.fieldFill({ creationDateFrom: val })}
                  customClass={this.state.fields.creationDatefrom ? className : ''}
                />
                <Field
                  id="creationDateTo"
                  name='creationDateTo'
                  placeholder={t(`${tKey}CREATION_DATE_TO`)}
                  controlLabel={t(`${tKey}CREATION_DATE_TO`)}
                  component={InputDatePicker}
                  validate={[this.validateDate]}
                  onInputChange={(val) => this.fieldFill({ creationDateTo: val })}
                  customClass={this.state.fields.creationDateTo ? className : ''}
                />
                <Field
                  id="renewalDateFrom"
                  name='renewalDateFrom'
                  placeholder={t(`${tKey}RENEWAL_DATE_FROM`)}
                  controlLabel={t(`${tKey}RENEWAL_DATE_FROM`)}
                  component={InputDatePicker}
                  validate={[this.validateDate]}
                  onInputChange={(val) => this.fieldFill({ renewalDateFrom: val })}
                  customClass={this.state.fields.renewalDateFrom ? className : ''}
                />
                <Field
                  id="renewalDateTo"
                  name='renewalDateTo'
                  placeholder={t(`${tKey}RENEWAL_DATE_TO`)}
                  controlLabel={t(`${tKey}RENEWAL_DATE_TO`)}
                  component={InputDatePicker}
                  validate={[this.validateDate]}
                  onInputChange={(val) => this.fieldFill({ renewalDateTo: val })}
                  customClass={this.state.fields.renewalDateTo ? className : ''}
                />
              </Row>
              <Row className='buttons-row'>
                <Button
                  id="btn_search"
                  type="submit"
                  className="btn-standard"
                >
                  <i className="ico-search"/>
                  {t('SEARCH.ACTIONS.SEARCH_BUTTON')}
                </Button>
                <Button
                  id="btn_reset"
                  onClick={(e) => this.handleClickReset(e)}
                  className={'btn-white btn-icon' + (this.props.pristine ? ' btn-cancel' : ' btn-standard')}
                  disabled={this.props.pristine}
                >
                  <i className="ico-cancel"/> {t('SEARCH.ACTIONS.RESET_BUTTON')}
                </Button>
                {hasSearch && count > 0 &&
                <Button id="btn_export"
                  className="btn-standard"
                  onClick={(event) => this.exportData(event)}>
                  {t('SEARCH.ACTIONS.EXPORT')}
                  <i className="ico-exportar ico-if_Download_1031520"/>
                </Button>}
              </Row>
            </>
            {hasSearch && ([
              <div className="table-wrapper fleets" key="table-wrapper">
                <SimpleTablePage
                  columns={[
                    <th key={0} data-field="fleetCode" onClick={(event) => this.onOrderChange(event)}>
                      {t(`${tKey}CODE`)}&nbsp;<i className={this.getIconClass('fleetCode')}></i>
                    </th>,
                    <th key={1} data-field="description" onClick={(event) => this.onOrderChange(event)}>
                      {t(`${tKey}DESCRIPTION`)}&nbsp;<i className={this.getIconClass('description')}></i>
                    </th>,
                    <th key={2} data-field="organizedUnit" onClick={(event) => this.onOrderChange(event)}>
                      {t(`${tKey}ORGANIZEDUNIT`)}&nbsp;<i className={this.getIconClass('organizedUnit')}></i>
                    </th>,
                    <th key={3} data-field="clientName" onClick={(event) => this.onOrderChange(event)}>
                      {t(`${tKey}CLIENT`)}&nbsp;<i className={this.getIconClass('clientName')}></i>
                    </th>,
                    <th key={4} data-field="dossierSubtype" onClick={(event) => this.onOrderChange(event)}>
                      {t(`${tKey}DOSSIER_SUBTYPE`)}&nbsp;<i className={this.getIconClass('dossierSubtype')}></i>
                    </th>,
                    <th key={5} data-field="creationDate" onClick={(event) => this.onOrderChange(event)}>
                      {t(`${tKey}CREATION_DATE`)}&nbsp;<i className={this.getIconClass('creationDate')}></i>
                    </th>,
                    <th key={6} data-field="dossiers" onClick={(event) => this.onOrderChange(event)}>
                      {t(`${tKey}NUM_DOSSIERS`)}&nbsp;<i className={this.getIconClass('dossiers')}/>
                    </th>,
                    <th key={7} data-field="actions">{t(`${tKey}ACTIONS`)}&nbsp;</th>
                  ]}
                  rows={fleetsData && fleetsData.map((row, idx) => (
                    <tr key={idx}>
                      <td>
                        {row.fleetCode}
                      </td>
                      <td>
                        {row.description}
                      </td>
                      <td>
                        {row.organizedUnit}
                      </td>
                      <td>
                        {row.clientName}
                      </td>
                      <td>
                        {row.dossierSubtype}
                      </td>
                      <td>
                        {row.creationDate}
                      </td>
                      <td>
                        {row.dossiers}
                      </td>
                      <td className="actionsMenu">
                        <DropdownButton
                          as={'ButtonGroup'}
                          key={1}
                          drop={'start'}
                          className={'btn-default-drop'}
                          title={<i className="glyphicon glyphicon-option-vertical"/>}
                          id="dropdown-button-drop-start"
                        >
                          <li><Button id="btn_observations"
                            className="btn-actions"
                            onClick={() => this.openObservations(row)}>
                            {t(`${tKey}OBSERVATIONS`)}
                            <Glyphicon className='icon-action blue' glyph="comment"/>
                          </Button></li>
                          <li><Button id="btn_historical"
                            className="btn-actions"
                            onClick={() => this.historical(row)}>
                            {t(`${tKey}HISTORICAL`)}
                            <Glyphicon className='icon-action blue' glyph="time"/>
                          </Button></li>
                          {this.state.fieldsConfiguration.viewFleet && this.state.fieldsConfiguration.viewFleet.permission === permissions.editable && (
                            <li><Button id="btn_edit"
                              className="btn-actions"
                              onClick={() => this.viewFleet(row)}>
                              {t(`${tKey}VIEW`)}
                              <Glyphicon className='icon-action blue' glyph="eye-open"/>
                            </Button></li>)}
                          {this.state.fieldsConfiguration.editFleet && this.state.fieldsConfiguration.editFleet.permission === permissions.editable && (
                            <li><Button id="btn_edit"
                              className="btn-actions"
                              onClick={() => this.editFleet(row)}>
                              {t(`${tKey}EDIT`)}
                              <Glyphicon className='icon-action blue' glyph="pencil"/>
                            </Button></li>)}
                          {this.state.fieldsConfiguration.operateFleet && this.state.fieldsConfiguration.operateFleet.permission === permissions.editable && (
                            <li>
                              <Button id="btn_operations"
                                className="btn-actions"
                                onClick={() => this.operations(row)}>
                                {t(`${tKey}OPERATE`)}
                                <Glyphicon className='icon-action blue' glyph="wrench"/>
                              </Button></li>)}
                          {this.state.fieldsConfiguration.economicFleet && this.state.fieldsConfiguration.economicFleet.permission === permissions.editable && (
                            <li><Button id="btn_financialAnalysis"
                              className="btn-actions"
                              onClick={() => this.financialAnalysis(row)}>
                              {t(`${tKey}FINANCIAL_ANALYSIS`)}
                              <Glyphicon className='icon-action blue' glyph="euro"/>
                            </Button></li>)}
                          {this.state.fieldsConfiguration.documentsFleet && this.state.fieldsConfiguration.documentsFleet.permission === permissions.editable && (
                            <li><Button id="btn_documents"
                              className="btn-actions"
                              onClick={() => this.documents(row)}>
                              {t(`${tKey}DOCUMENTS`)}
                              <Glyphicon className='icon-action blue' glyph="duplicate"/>
                            </Button></li>)}
                          {this.state.fieldsConfiguration.deleteFleet && this.state.fieldsConfiguration.deleteFleet.permission === permissions.editable && (
                            <li><Button id="btn_delete"
                              className="btn-actions"
                              onClick={() => this.deleteFleet(row)}>
                              {t(`${tKey}DELETE`)}
                              <Glyphicon className='icon-action red' glyph="trash" />
                            </Button></li>)}
                        </DropdownButton>
                      </td>
                    </tr>
                  ))}
                />
              </div>,
              <div className="search-footer" key="search-footer">
                <IDocCarPagination
                  id="btn_pag_sales"
                  page={page}
                  pagesCount={pages}
                  totalRows= {count}
                  onSelectAction={(page) => this.onPageChange(page)}
                />
              </div>
            ])}
          </div>
        </Form>
        {(this.state.showModalCreate || this.state.showModalOperate) && (
          <>
            <FleetCreatedSelectChargeModalPage fetchFleets={() => this.haveValues(this.state.fields) ? this.fetchTable() : null}></FleetCreatedSelectChargeModalPage>
            <AddDossiersToFleetModalPage fetchFleets={() => this.haveValues(this.state.fields) ? this.fetchTable() : null}></AddDossiersToFleetModalPage>
            <HistoricalFleetModalPage></HistoricalFleetModalPage>
            <ChargeDossiersModalPage fieldsConfiguration={this.state.fieldsConfiguration}> </ChargeDossiersModalPage>
          </>
        )}
        <CreateNewFleetModalPage fetchFleets={() => this.haveValues(this.state.fields) ? this.fetchTable() : null} fieldsConfiguration={this.state.fieldsConfiguration}></CreateNewFleetModalPage>
        <OperateFleetModalPage fetchFleets={() => this.haveValues(this.state.fields) ? this.fetchTable() : null} fieldsConfiguration={this.state.fieldsConfiguration} openDocumentsExterntal={this.openDocumentsExterntal} checkStageForEconomicAnalysis ={this.checkStageForEconomicAnalysis} checkShowEconomicDossiers={this.checkShowEconomicDossiers}></OperateFleetModalPage>
        <DocumentsFleetModalPage fetchFleets={() => this.haveValues(this.state.fields) ? this.fetchTable() : null} fieldsConfiguration={this.state.fieldsConfiguration} ></DocumentsFleetModalPage>
        <EconomicFleetModalPage fetchFleets={() => this.haveValues(this.state.fields) ? this.fetchTable() : null} fieldsConfiguration={this.state.fieldsConfiguration} checkStageForEconomicAnalysis ={this.checkStageForEconomicAnalysis}></EconomicFleetModalPage>
      </>
    )
  }
}

const validate = (values, { t }, props) => {
  const errors = {}

  if (values && values.creationDateFrom && values.creationDateTo) {
    let dateTo = convertStringToDate(values.creationDateTo)
    let dateFrom = convertStringToDate(values.creationDateFrom)
    if (dateFrom > dateTo) {
      errors.creationDateTo = t('GLOBAL.FORMS.DATE_TO_NOT_LESS_THAN_DATE_FROM')
    }
  }

  if (values && values.renewalDateFrom && values.renewalDateTo) {
    let dateTo = convertStringToDate(values.renewalDateTo)
    let dateFrom = convertStringToDate(values.renewalDateFrom)
    if (dateFrom > dateTo) {
      errors.renewalDateTo = t('GLOBAL.FORMS.DATE_TO_NOT_LESS_THAN_DATE_FROM')
    }
  }

  return errors
}

export default reduxForm({
  form: 'fleetsManager',
  validate,
  destroyOnUnmount: false
})(FleetsManager)
