export default {
  FETCH_STAGES_CONDITIONS: 'FETCH_STAGES_CONDITIONS',
  FETCH_STAGES_CONDITIONS_SUCCESS: 'FETCH_STAGES_CONDITIONS_SUCCESS',
  SET_ACTIVE_STAGE: 'SET_ACTIVE_STAGE',
  SET_ACTIVE_STAGE_SUCCESS: 'SET_ACTIVE_STAGE_SUCCESS',
  OPEN_ADD_CONDITIONS_MODAL: 'OPEN_ADD_CONDITIONS_MODAL',
  OPEN_ADD_CONDITIONS_MODAL_SUCCESS: 'OPEN_ADD_CONDITIONS_MODAL_SUCCESS',
  OPEN_EDIT_CONDITIONS_MODAL: 'OPEN_EDIT_CONDITIONS_MODAL',
  OPEN_EDIT_CONDITIONS_MODAL_SUCCESS: 'OPEN_EDIT_CONDITIONS_MODAL_SUCCESS',
  SAVE_CONDITIONS_MODAL: 'SAVE_CONDITIONS_MODAL',
  CLOSE_ADD_OR_EDIT_CONDITIONS_MODAL: 'CLOSE_ADD_OR_EDIT_CONDITIONS_MODAL',
  CLOSE_ADD_OR_EDIT_CONDITIONS_MODAL_SUCCESS: 'CLOSE_ADD_OR_EDIT_CONDITIONS_MODAL_SUCCESS',
  DELETE_CONDITION: 'DELETE_CONDITION',
  UPDATE_CONDITION_MAINTENANCE_FILTER: 'UPDATE_CONDITION_MAINTENANCE_FILTER',
  FETCH_CONDITION_DOCUMENTAL_CAMPAIGN: 'FETCH_CONDITION_DOCUMENTAL_CAMPAIGN',
  FETCH_CONDITION_DOCUMENTAL_CAMPAIGN_SUCCESS: 'FETCH_CONDITION_DOCUMENTAL_CAMPAIGN_SUCCESS',
  DELETE_CONDITION_DOCUMENTAL_CAMPAIGN: 'DELETE_CONDITION_DOCUMENTAL_CAMPAIGN',
  GET_DOCUMENTS_CONDITION_DOCUMENTAL_CAMPAIGN: 'GET_DOCUMENTS_CONDITION_DOCUMENTAL_CAMPAIGN',
  GET_DOCUMENTS_CONDITION_DOCUMENTAL_CAMPAIGN_SUCCESS: 'GET_DOCUMENTS_CONDITION_DOCUMENTAL_CAMPAIGN_SUCCESS',
  ADD_CONDITION_DOCUMENTAL_CAMPAIGN: 'ADD_CONDITION_DOCUMENTAL_CAMPAIGN',
  GET_FUNTIONTOADD_DOCUMENTAL_CAMPAIGN: 'GET_FUNTIONTOADD_DOCUMENTAL_CAMPAIGN',
  GET_FUNTIONTOADD_DOCUMENTAL_CAMPAIGN_SUCCES: 'GET_FUNTIONTOADD_DOCUMENTAL_CAMPAIGN_SUCCES',
  DELETE_UO_CONDITION_DOCUMENTAL_CAMPAIGN: 'DELETE_UO_CONDITION_DOCUMENTAL_CAMPAIGN'

}
