import React, { PureComponent } from 'react'
import { Col, Row, Form, Button, Table } from 'react-bootstrap'
import { reduxForm } from 'redux-form'
import settings from '../../../setting'
import DropZone from 'react-dropzone'
const averageCharacter = 'c'

class AuditFileModal extends PureComponent {
  constructor () {
    super()
    this.state = {
      documentName: '',
      documentTitle: averageCharacter,
      showTitle: false,
      files: [],
      showRejectCommentModal: false,
      hasNewDocument: false,
      showFileUploader: true,
      hasInvalidFile: false,
      showDocumentCarrusel: false,
      isDocumentCarruselEndReached: false,
      showPageCarrusel: true,
      autoClosePageCarrusel: false,
      documentLoaded: false,
      zoomProps: {
        width: 0,
        height: 0,
        zoomWidth: 0,
        scale: 0.9,
        img: '',
        offset: { vertical: 0, horizontal: 10 },
        zoomStyle: 'box-shadow: 0px 2px 5px rgba(0,0,0,0.3)',
        zoomLensStyle: 'background-color: rgba(0,0,0,0.3); cursor: crosshair;'
      },
      hasDropzoneImg: false,
      parametroFecha: Date.now(),
      dropzoneActive: false,
      dropzonePanelActive: false,
      documentNames: []
    }

    this.onDrop = this.onDrop.bind(this)

    this.documentTitleElementRef = null
    this.setDocumentTitleElementRef = element => {
      this.documentTitleElementRef = element
    }
  }

  onDrop (fileToUpload, rejectedFiles) {
    const EMPTY_EXCEL = 6598
    let bool = true
    fileToUpload.forEach((file) => {
      if (file.size === EMPTY_EXCEL) {
        this.props.actions.openModal('fileIsEmpty')
        bool = false
      }
    })
    rejectedFiles.forEach((re) => {
      if (re.file.size === EMPTY_EXCEL) {
        this.props.actions.openModal('fileIsEmpty')
        bool = false
      }
      if (re.file.name.split('.').pop() !== 'xlsx' && re.file.name.split('.').pop() !== 'xls') {
        this.props.actions.openModal('fileNotValid')
        bool = false
      }
    })
    if (bool) {
      return new Promise((resolve, reject) => {
        this.props.actions.documentAudit(fileToUpload, resolve, reject)
      }).then((result) => {
        this.props.actions.openModal(
          'correctAudit',
          () => {
            return new Promise((resolve, reject) => {
              this.props.actions.documentAuditFinish(fileToUpload, resolve, reject)
            }).then(() => {
              this.props.actions.openModal('confirmAuditFinish')
              if (fileToUpload.length === 0 && rejectedFiles.length > 0) {
                this.setState({ hasInvalidFile: true })
              } else {
                this.props.acceptZip ? this.setState({ files: [fileToUpload], hasNewDocument: true, hasInvalidFile: false }) : this.setState({ files: [fileToUpload[0]], hasNewDocument: true, hasInvalidFile: false })
                if (fileToUpload[0]) {
                  this.setState({
                    documentLoaded: false,
                    showFileUploader: false
                  })
                  if (!this.props.acceptZip) {
                    this.setState({ documentName: fileToUpload[0].name })
                  } else {
                    let documentNames = []
                    fileToUpload.map((fil, ind) => {
                      documentNames.push(fil.name)
                    })
                    this.setState({ documentNames })
                  }
                }
              }
            })
          },
          null,
          null,
          null,
          [
            { id: '##NUMBER_READ##', value: result.totalRecords },
            { id: '##NUMBER_VALID##', value: result.validRecords }
          ],
          null
        )
      })
    }
  }

  onDragEnter (panel = false) {
    this.setState(panel ? { dropzonePanelActive: true } : { dropzoneActive: true })
  }

  onDragLeave (panel = false) {
    this.setState(panel ? { dropzonePanelActive: false } : { dropzoneActive: false })
  }

  getDocumentTitle (props) {
    const tKey = 'DASHBOARD.UPLOAD_MANAGEMENT.UPLOAD_DOCUMENT_FLEET.'
    return (props.docResult.documentId ? props.t(`${tKey}EDIT`) : props.t(`${tKey}ADD`)) + (props.documentTypeUseName ? (' - ' + props.documentTypeUseName) : '')
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (prevProps.showModal && !this.props.showModal) {
      this.setState({
        documentTitle: averageCharacter,
        showTitle: false,
        files: [],
        dropzoneActive: false,
        dropzonePanelActive: false
      })
    }

    return this.props
  }

  componentDidUpdate () {
  }

  componentDidMount () {
    this.setState({
      zoomProps: {
        ...this.state.zoomProps,
        img: `${settings.url}/Document/GetImage?token=${this.props.token}&fecha=${this.state.parametroFecha}`
      }
    })
  }

  render () {
    const { t, handleSubmit, items, errors, succes, showSuccesMessage, showMessageError, docErrors } = this.props
    const maxSizeFilesDropzone = 10 * 1024 * 1024
    const tkey = 'MANAGEMENT.ADMIN_AUDIT.MODAL.'
    return (
      <Row>
        <Col sm={9} className="input-dropzone-wrapper">
          <p className='infoTypeDoc'><strong>{t(`${tkey}SELECT_OPERATION`)}</strong><span>  {t(`${tkey}FORMATS`)}</span></p>
        </Col>
        <Col sm={12} className="input-dropzone-wrapper">
          <Row className="form-group">
            {
              <Col sm={12} className={'document-edition-modal-middle-side'}>
                <div className={'input-dropzone'} style={{ height: '100%' }}>
                  {
                    <DropZone
                      id="dropZone"
                      multiple={this.props.acceptZip ? this.props.acceptZip : false}
                      onDrop={(acceptedFiles, rejectedFiles) => this.onDrop(acceptedFiles, rejectedFiles)}
                      disabled={false}
                      accept=".xls,.xlsx"
                      maxSize={maxSizeFilesDropzone}
                      style={{ height: '100%' }}
                      className={this.state.dropzoneActive ? 'active' : ''}
                      onDragEnter={() => this.onDragEnter(false)}
                      onDragLeave={() => this.onDragLeave(false)}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          {this.state.documentName !== '' || this.state.documentNames.length > 0 ? (
                            <div className="text-wrapper">
                              {t(`${tkey}FILE_SELECT`)}: {this.state.documentName !== '' ? this.state.documentName
                                : this.state.documentNames.map((nam, i) => {
                                  return nam + ', \n'
                                })}
                            </div>
                          ) : (
                            <div className="text-wrapper modal-subir-fichero-inicio">
                              <div>
                                <i className='ico-cloud' />
                                <h2 key="dropZone-title">{t(`${tkey}FILE`)}</h2>
                                <p key="dropZone-subtitle">{t(`${tkey}FILE_SIZE`)}</p>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </DropZone>
                  }
                </div>
              </Col>
            }
          </Row>
          {items && items.length > 0 && (<Row>{t(`${tkey}TITTLE_SUCCES`)}</Row>)}
          {items && items.length > 0 &&
                (<Table className="editable-table " hover responsive>
                  <thead>
                    <tr>
                      {Object.keys(items[0]).map((k, ind) => {
                        return (<th key={ind}>{t(tkey + k.toUpperCase())}</th>)
                      })
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {items.map((it, i) => {
                      return (
                        <tr key={i}>
                          {Object.keys(it).map((key, ind) => {
                            return typeof (it[key]) === 'boolean' ? <td key={ind}><i className="ico-check" /></td> : (<td key={ind}>{it[key]}</td>)
                          })}
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>)
          }

          {errors && errors.length > 0 && (<Row>{t(`${tkey}TITTLE_ERROR`)}</Row>)}
          {errors && errors.length > 0 &&
                (<Table className="editable-table auditDocsTable" hover responsive>
                  <thead>
                    <tr>
                      {Object.keys(errors[0]).map((k, ind) => {
                        return (<th key={ind}>{t(tkey + k.toUpperCase())}</th>)
                      })
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {errors.map((er, i) => {
                      return (
                        <tr key={i} className='is-deleted'>
                          {Object.keys(er).map((key, ind) => {
                            return (<td key={ind}>{er[key]}</td>)
                          })}
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>)
          }
          {docErrors && docErrors.length > 0 && (<Row>{t(`${tkey}TITTLE_ERROR`)}</Row>)}
          {docErrors && docErrors.length > 0 &&
                (<Table className="editable-table auditDocsTable" hover responsive>
                  <thead>
                    <tr>
                      {Object.keys(docErrors[0]).map((k, ind) => {
                        return (<th key={ind}>{t(tkey + k.toUpperCase())}</th>)
                      })
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {docErrors.map((er, i) => {
                      return (
                        <tr key={i} className='is-deleted'>
                          {Object.keys(er).map((key, ind) => {
                            return (<td key={ind}>{er[key]}</td>)
                          })}
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>)
          }
          {!showSuccesMessage && succes &&
                (<Button
                  id="btn_validate"
                  key="btn_validate"
                  className="btn-validate-white"
                  style={{ marginRight: '10px' }}
                  onClick={handleSubmit(values => {
                    this.submitDocument({ ...values, validateAction: false })
                  })}
                ><i className="ico-check" /> {t(`${tkey}UPLOAD_BTN`)}</Button>
                )
          }
          {showSuccesMessage &&
                (<Row>{t(`${tkey}SUCCES_MESSAGE`)}</Row>)
          }
          {showMessageError &&
                (<Row>{t(tkey + showMessageError)}</Row>)
          }
        </Col>
        <Col sm={6} className="document-edition-modal-form">
          <Form autoComplete="off" style={{ height: '100%' }} onSubmit={handleSubmit(values => {
            this.submitDocument(values)
          })}>
          </Form>
        </Col>
      </Row>
    )
  }
}

export default reduxForm({
  form: 'AuditFileModal',
  destroyOnUnmount: false
})(AuditFileModal)
