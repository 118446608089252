import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import SuppliedModal from '../../components/masters/SuppliedModal'
import { translate } from 'react-polyglot'
import { closeSuppliedModal, saveSuppliedModal } from '../../actions/masters/masters'

export function mapStateToProps (state) {
  return {
    showSuppliedModal: state.entityMasters.showSuppliedModal,
    languageList: state.entityMasters.languageList,
    suppliedTypeLanguages: state.entityMasters.suppliedFields.suppliedTypeLanguages || [],
    suppliedId: state.entityMasters.suppliedId
    /* ,
    languageList: [
      {languageId: 1, name: 'Castellano', description: 'es-ES'},
      {languageId: 2, name: 'Catalán', description: 'es-CA'}
    ] */
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      closeSuppliedModal,
      saveSuppliedModal
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(SuppliedModal))
