import React, { PureComponent } from 'react'
import { reduxForm, Field } from 'redux-form'
import { Row, Col, Table, Button, Clearfix } from 'react-bootstrap'
import IDocCarPagination from '../../commons/iDocCarPagination'
import InputText from '../../commons/form/InputText'
import InputDatePicker from '../../commons/form/InputDatePicker'
import InputSelect from '../../commons/form/InputSelect'
import moment from 'moment'
import { formatMoney } from '../../../util/utils'

class StockReport extends PureComponent {
  constructor () {
    super()
    this.state = {
      stockVehicleTypeCombo: [{ id: 3, value: 'Vehiculo Nuevo' }, { id: 4, value: 'Vehiculo Ocasión' }],
      stockFacturaTypeCombo: [{ id: 1, value: 'Si' }, { id: 2, value: 'No' }],
      filter: {
        searchSociety: null,
        searchBrand: null,
        searchModel: null,
        searchDateBuyInitial: null,
        searchDateBuyEnd: null,
        searchDateArrivalInitial: null,
        searchDateArrivalEnd: null,
        searchVehicle: 0,
        searchBill: 0
      }
    }
  }

  searchStock () {
    this.props.actions.fetchStockList({ ...this.props.filter, ...this.state.filter, page: 1 })
  }

  downloadStock () {
    this.props.actions.downloadStockList({ ...this.props.filter, ...this.state.filter, page: 1 })
  }

  componentDidMount () {
    this.props.actions.fetchBrands()
    this.searchStock()
  }

  handleSocietyChange (searchSociety) {
    if (this.state.filter.searchSociety !== searchSociety) {
      this.setState({ filter: { ...this.state.filter, searchSociety: searchSociety } })
    }
  }

  handleBrandChange (searchBrand) {
    if (this.state.filter.searchBrand !== searchBrand) {
      this.setState({ filter: { ...this.state.filter, searchBrand: searchBrand } })
    }
  }

  handleModelChange (searchModel) {
    if (this.state.filter.searchModel !== searchModel) {
      this.setState({ filter: { ...this.state.filter, searchModel: searchModel } })
    }
  }

  handleDateBuyInitialChange (searchDateBuyInitial) {
    if (this.state.filter.searchDateBuyInitial !== searchDateBuyInitial && searchDateBuyInitial != null) {
      const [dd, mm, yyyy] = searchDateBuyInitial.split('/')
      this.setState({ filter: { ...this.state.filter, searchDateBuyInitial: new Date(`${yyyy}-${mm}-${dd}T00:00:01.000Z`) } })
    } else {
      this.setState({ filter: { ...this.state.filter, searchDateBuyInitial: null } })
    }
  }

  handleDateBuyEndChange (searchDateBuyEnd) {
    if (this.state.filter.searchDateBuyEnd !== searchDateBuyEnd && searchDateBuyEnd != null) {
      const [dd, mm, yyyy] = searchDateBuyEnd.split('/')
      this.setState({ filter: { ...this.state.filter, searchDateBuyEnd: new Date(`${yyyy}-${mm}-${dd}T23:59:59.000Z`) } })
    } else {
      this.setState({ filter: { ...this.state.filter, searchDateBuyEnd: null } })
    }
  }

  handleDateArrivalInitialChange (searchDateArrivalInitial) {
    if (this.state.filter.searchDateArrivalInitial !== searchDateArrivalInitial && searchDateArrivalInitial != null) {
      const [dd, mm, yyyy] = searchDateArrivalInitial.split('/')
      this.setState({ filter: { ...this.state.filter, searchDateArrivalInitial: new Date(`${yyyy}-${mm}-${dd}T00:00:01.000Z`) } })
    } else {
      this.setState({ filter: { ...this.state.filter, searchDateArrivalInitial: null } })
    }
  }

  handleDateArrivalEndChange (searchDateArrivalEnd) {
    if (this.state.filter.searchDateArrivalEnd !== searchDateArrivalEnd && searchDateArrivalEnd != null) {
      const [dd, mm, yyyy] = searchDateArrivalEnd.split('/')
      this.setState({ filter: { ...this.state.filter, searchDateArrivalEnd: new Date(`${yyyy}-${mm}-${dd}T23:59:59.000Z`) } })
    } else {
      this.setState({ filter: { ...this.state.filter, searchDateArrivalEnd: null } })
    }
  }

  handleVechicleChange (searchVehicle) {
    if (this.state.filter.searchVehicle !== searchVehicle) {
      this.setState({ filter: { ...this.state.filter, searchVehicle: searchVehicle } })
    }
  }

  handleBillChange (searchBill) {
    if (this.state.filter.searchBill !== searchBill) {
      this.setState({ filter: { ...this.state.filter, searchBill: searchBill } })
    }
  }

  render () {
    const {
      t, stockList, pagesCount, filter, stockCount,
      filter: { page },
      actions: { fetchStockList },
      combos: { brandCombo, lowestOrganizedUnitsCombo }
    } = this.props
    const tKey = 'REPORTS.'
    return (
      <div className="admin-wrapper">
        <Row className="section-users-header">
          <Col sm={12}>
            <div className="header-panel">
              <h4>{t(`${tKey}STOCK.STOCK_TITLE`)}</h4>
            </div>
          </Col>
        </Row>
        <Row>
          <div className="search-form">
            <span className="title-search"><i className="ico-search" /> {t(`${tKey}STOCK.SEARCH_TITLE`)}</span>
            <Row>
              <Field
                colSm={3}
                id="society"
                name="filters.society"
                controlLabel={t(`${tKey}WALLET.SOCIETY`)}
                placeholder={t(`${tKey}WALLET.SOCIETY`)}
                component={InputSelect}
                options={lowestOrganizedUnitsCombo}
                valueKey="id"
                labelKey="value"
                onInputChange={(value) => this.handleSocietyChange(value)}
              />
              <Field
                colSm={3}
                id="vehicleBrand"
                name="filters.vehicleBrand"
                controlLabel={t(`${tKey}WALLET.BRAND`)}
                placeholder={t(`${tKey}WALLET.BRAND`)}
                component={InputSelect}
                valueKey="id"
                labelKey="value"
                options={brandCombo}
                onInputChange={(value) => this.handleBrandChange(value)}
              />
              <Field
                id="model"
                name="filters.model"
                colSm={3}
                controlLabel={t(`${tKey}STOCK.MODEL`)}
                placeholder={t(`${tKey}STOCK.MODEL`)}
                component={InputText}
                onInputChange={(value) => this.handleModelChange(value)}
              />
              <Field
                id="dateorderinitial"
                name="filters.dateorderinitial"
                colSm={3}
                controlLabel={t(`${tKey}STOCK.BUY_DATE_FROM`)}
                placeholder={t(`${tKey}STOCK.BUY_DATE_FROM`)}
                component={InputDatePicker}
                onInputChange={(value) => this.handleDateBuyInitialChange(value)}
              />
              <Clearfix/>
              <Field
                id="dateorderend"
                name="filters.dateorderend"
                colSm={3}
                controlLabel={t(`${tKey}STOCK.BUY_DATE_TO`)}
                placeholder={t(`${tKey}STOCK.BUY_DATE_TO`)}
                component={InputDatePicker}
                onInputChange={(value) => this.handleDateBuyEndChange(value)}
              />
              <Field
                id="datearrivalinitial"
                name="filters.datearrivalinitial"
                colSm={3}
                controlLabel={t(`${tKey}STOCK.ARRIVAL_DATE_FROM`)}
                placeholder={t(`${tKey}STOCK.ARRIVAL_DATE_FROM`)}
                component={InputDatePicker}
                onInputChange={(value) => this.handleDateArrivalInitialChange(value)}
              />
              <Field
                id="datearrivalend"
                name="filters.datearrivalend"
                colSm={3}
                controlLabel={t(`${tKey}STOCK.ARRIVAL_DATE_TO`)}
                placeholder={t(`${tKey}STOCK.ARRIVAL_DATE_TO`)}
                component={InputDatePicker}
                onInputChange={(value) => this.handleDateArrivalEndChange(value)}
              />
              <Field
                name="filters.vechicle"
                controlLabel={t(`${tKey}STOCK.TYPE_VEHICLE`)}
                placeholder={t(`${tKey}STOCK.TYPE_VEHICLE`)}
                component={InputSelect}
                multi={false}
                valueKey="id"
                labelKey="value"
                childrenKey="children"
                options={this.state.stockVehicleTypeCombo}
                colSm={3}
                onInputChange={(value) => this.handleVechicleChange(value)}
              />
              <Clearfix/>
              <Field
                name="filters.bill"
                controlLabel={t(`${tKey}STOCK.BUY_BILL`)}
                placeholder={t(`${tKey}STOCK.BUY_BILL`)}
                component={InputSelect}
                multi={false}
                valueKey="id"
                labelKey="value"
                childrenKey="children"
                options={this.state.stockFacturaTypeCombo}
                colSm={3}
                onInputChange={(value) => this.handleBillChange(value)}
              />
              <Col sm={3} className="btn-wrapper">
                <Button
                  className={'btn-white btn-icon btn-input btn-white search-button'}
                  style={{ width: '100%' }}
                  onClick={() => this.searchStock()}>
                  <i className="ico-search" />
                  {t(`${tKey}SEARCH`)}
                </Button>
              </Col>
            </Row>
          </div>
        </Row>
        <Col>
          <Button
            className={'btn-white btn-icon btn-input btn-white'}
            style={{ float: 'right' }}
            onClick={() => this.downloadStock()}>
            {t(`${tKey}DOWNLOAD`)}
          </Button>
        </Col>
        <Col sm={12} className="col-row  section-users-body">
          <Table className="editable-table admin-users" hover responsive>
            <thead>
              <tr>
                <th>
                  {t(`${tKey}STOCK.DOSSIER`)}&nbsp;
                </th>
                <th>
                  {t(`${tKey}STOCK.ORDER_DATE`)}&nbsp;
                </th>
                <th>
                  {t(`${tKey}STOCK.BUY_DATE`)}&nbsp;
                </th>
                <th>
                  {t(`${tKey}STOCK.TYPE_BUY`)}&nbsp;
                </th>
                <th>
                  {t(`${tKey}STOCK.ARRIVAL_DATE`)}&nbsp;
                </th>
                <th>
                  {t(`${tKey}STOCK.LOCATION`)}&nbsp;
                </th>
                <th>
                  {t(`${tKey}STOCK.CLIENT`)}&nbsp;
                </th>
                <th>
                  {t(`${tKey}STOCK.SALESMAN`)}&nbsp;
                </th>
                <th>
                  {t(`${tKey}STOCK.BRAND`)}&nbsp;
                </th>
                <th>
                  {t(`${tKey}STOCK.MODEL`)}&nbsp;
                </th>
                <th>
                  {t(`${tKey}STOCK.COLOR`)}&nbsp;
                </th>
                <th>
                  {t(`${tKey}STOCK.VINCHASSIS`)}&nbsp;
                </th>
                <th>
                  {t(`${tKey}STOCK.PURCHASE_AMOUNT`)}&nbsp;
                </th>
                <th>
                  {t(`${tKey}STOCK.IVA`)}&nbsp;
                </th>
                <th>
                  {t(`${tKey}STOCK.PROVIDER`)}&nbsp;
                </th>
              </tr>
            </thead>
            <tbody>
              {stockList && stockList.map((stock, idx) => {
                return (
                  <tr key={idx} className = "">
                    <td>{stock.dossierNumber}</td>
                    <td>{moment(stock.creationDate).format('DD/MM/YYYY')}</td>
                    <td>{moment(stock.billingDate).format('DD/MM/YYYY')}</td>
                    <td>{stock.purchaseType != null && stock.purchaseType.name}</td>
                    <td>{moment(stock.realDeliveryDateTime).format('DD/MM/YYYY')}</td>
                    <td>{stock.deliveryCity}</td>
                    <td>{stock.entityName}</td>
                    <td>{stock.salesmanName}</td>
                    <td>{stock.brandName}</td>
                    <td>{stock.model}</td>
                    <td>{stock.color}</td>
                    <td>{stock.vinChassis}</td>
                    <td>{formatMoney(stock.amount)}</td>
                    <td>{stock.ivaType != null && stock.ivaType.name}</td>
                    <td>{stock.buyEntityName != null && stock.buyEntityName}</td>
                  </tr>)
              })
              }
              {(stockList === null || stockList === undefined || stockList.length === 0) &&
            <tr>
              <td colSpan={15}><h2>{t(`${tKey}RESULTS_NOT_FOUND`)}</h2></td>
            </tr>}
            </tbody>
          </Table>
          <div className="section-users-footer">
            <nav key={1} className="text-center">
              <IDocCarPagination
                id="btn_pag_sales"
                page={page}
                pagesCount={pagesCount}
                totalRows={stockCount}
                onSelectAction={(value) => fetchStockList({ ...filter, ...this.state.filter, page: value })}
              />
            </nav>
          </div>
        </Col>
      </div>
    )
  }
}

export default reduxForm({
  form: 'listStock'
})(StockReport)
