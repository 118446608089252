import sha256 from 'sha256'
import encryptPasswordBetter from './encryptPasswordBetter'
import setting from '../../setting'

export default function (password, user) {
  if (!setting.authenticationWithSalt) {
    return sha256(password)
  }
  if (setting.authenticationWithSalt) {
    return encryptPasswordBetter(password, user)
  }
}
