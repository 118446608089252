import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { showSmallLoader, hideSmallLoader } from '../../actions/common.js'
import SmallLoader from '../../components/commons/SmallLoader.js'

export function mapStateToProps (state) {
  return {
    componentLoading: state.common.componentLoading
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      showSmallLoader,
      hideSmallLoader
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SmallLoader)
