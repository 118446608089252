import React from 'react'
import ReactDOM from 'react-dom'
// import { syncHistoryWithStore } from 'react-router-redux'
import { Provider } from 'react-redux'
import configureStore, { hashHistory } from './store/configureStore'
import createRoutes from './routes'
import castellano from './translations/castellano.json'
import castellanoMx from './translations/castellanoMx.json'
import catalan from './translations/catalan.json'
import ingles from './translations/ingles.json'
import portugues from './translations/portugues.json'
import italiano from './translations/italiano.json'
import LocaleAppPage from './containers/LocaleAppPage'
import { changeLocale } from './actions/common'
import { ConnectedRouter } from 'connected-react-router'
import '../assets/scss/style.scss'
import instance from './tracker.js'
import { MatomoProvider } from '@datapunt/matomo-tracker-react'
import frances from './translations/frances.json'

let messages = {}
const locale = localStorage.locale

switch (locale) {
  case '7':
    messages = frances
    break
  case '6':
    messages = italiano
    break
  case '5':
    messages = castellanoMx
    break
  case '4':
    messages = portugues
    break
  case '3':
    messages = ingles
    break
  case '2':
    messages = catalan
    break
  case '1':
  default:
    messages = castellano
    break
}

const store = configureStore()
store.dispatch(changeLocale({ locale: locale, messages: messages }))
const routes = createRoutes(store)
// const history = syncHistoryWithStore(hashHistory, store)

// dashboard, management
ReactDOM.render(

  <MatomoProvider value={instance}>
    <Provider store={store}>
      <ConnectedRouter history={hashHistory}>
        <LocaleAppPage routes={routes} />
      </ConnectedRouter>
    </Provider>
  </MatomoProvider>
  ,
  document.getElementById('root')
)
