import React, { PureComponent } from 'react'
import { Col, Row, OverlayTrigger, Button, Glyphicon, Tooltip } from 'react-bootstrap'
import { permissions } from '../../../../constants/backendIds'
import { compareObjects } from '../../../../util/utils'
import { getHeaderPermisions } from '../../../../util/permisionFunctions'
import { GoBackTooltip } from '../../../commons/Tooltips'
import { Link } from 'react-router-dom'
import settings from '../../../../setting'

class StatusHeader extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      componentsConfiguration: {
        summary: {
          code: 'summary',
          permission: permissions.hidden
        },
        stages: {
          code: 'stages',
          permission: permissions.hidden
        },
        checklist: {
          code: 'checklist',
          permission: permissions.hidden
        }
      }
    }
  }

  initializePermisions (props) {
    const newState = getHeaderPermisions(props.headerTabConfiguration)
    this.setState({ ...this.state, componentsConfiguration: { ...newState } })
  }

  calcStatusHeaderHeight () {
    const header = document.querySelector('.navbar-headerPage') || {}
    const statusSidebar = document.querySelector('#dossier-header .dossier-header') || {}
    return header.clientHeight + statusSidebar.clientHeight + 1
  }

  UNSAFE_componentWillMount () {
    if (this.props.headerTabConfiguration) this.initializePermisions(this.props)
  }

  componentDidMount () {
    this.props.actions.setStatusHeaderHeight(this.calcStatusHeaderHeight())
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.headerTabConfiguration && !compareObjects(this.props.headerTabConfiguration || {}, nextProps.headerTabConfiguration, true)) {
      this.initializePermisions(nextProps)
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.windowWidth !== this.props.windowWidth || prevProps.loadedDossier !== this.props.loadedDossier) {
      this.props.actions.setStatusHeaderHeight(this.calcStatusHeaderHeight())
    }
  }

  componentWillUnmount () {
    this.props.actions.resetStatusHeader()
  }

  handleToUpdateVehicle () {
    this.setState({ isVehicleLoaded: true })
  }

  handleToUpdateClient () {
    this.setState({ isClientLoaded: true })
  }

  switchFavorite (currentFavoriteStatus) {
    this.props.actions.switchFavorite(currentFavoriteStatus, this.props.dossierId, true)
  }

  render () {
    const { t, headerData, showChassis, tooltipChassis, vinChassis, licensePlate, showCreator, creatorName, favorite } = this.props
    const tKey = 'DOSSIER_COMPONENTS.HEADER.'
    const tooltipCreationDateDealerNumber = (
      <Tooltip id="tooltipCreationDateDealerNumber">
        {headerData.dealerNumber !== undefined && (t(`${tKey}DEALER_NUMBER`) + ' / ')}
        {t(`${tKey}CREATION_DATE`)}
      </Tooltip>
    )
    const tooltipRentalOderNumber = (
      <Tooltip id="tooltipRentalOderNumber">
        {headerData.rentalOrderNumber !== undefined && (t(`${tKey}RENTALORDER_NUMBER`))}
      </Tooltip>
    )
    const tooltipCreator = (
      <Tooltip id="tooltipCreator">
        {creatorName !== undefined && (t(`${tKey}CREATOR`))}
      </Tooltip>
    )
    const FavoriteTooltip = (
      <Tooltip id="FavoriteTooltip">
        {favorite === true ? (t(`${tKey}FAVORITES.UNMARK`)) : (t(`${tKey}FAVORITES.MARK`))}
      </Tooltip>
    )

    return (
      <Row id="dossier-header">
        <Col sm={12}>
          <div className="dossier-header fixed">
            {this.state.componentsConfiguration.summary && this.state.componentsConfiguration.summary.permission > permissions.hidden && (
              <Row>
                <Col sm={3}>
                  <div className="text-band sdfsdfsdfasdf">
                    <span className="numero">
                      {settings.enableFavoritesAndRecents &&
                      <OverlayTrigger container={document.getElementById('Favorite')} placement="bottom" overlay={FavoriteTooltip}>
                        <a id='Favorite' className='Favorite' onClick={() => this.switchFavorite(favorite)}><i className={favorite ? 'iDocIcon-favorite' : 'iDocIcon-unfavorite'}></i></a>
                      </OverlayTrigger>
                      }
                      {settings.enableFavoritesAndRecents && headerData.number}
                    </span>

                    <span className="second numero_expediente">
                      <div className="symbol">
                        <Button className="btn-go-back" id="btn_back" onClick={() => this.props.goBack()}>
                          <OverlayTrigger container={this} placement="bottom" overlay={GoBackTooltip({ t: t })}>
                            <Glyphicon glyph="arrow-left"/>
                          </OverlayTrigger>
                        </Button>
                      </div>
                      <div className="info">
                        {!settings.enableFavoritesAndRecents &&
                          <span className="numero">{headerData.number}</span>
                        }
                        <OverlayTrigger placement="bottom" overlay={tooltipCreationDateDealerNumber} >
                          <div className="fecha">{headerData.dealerNumber === undefined ? '' : headerData.dealerNumber + ' / '}{headerData.creationDate}</div>
                        </OverlayTrigger>
                        <span className="fecha">{headerData.salesman}</span>
                        <OverlayTrigger placement="bottom" overlay={tooltipRentalOderNumber} >
                          <span className="fecha">{headerData.rentalOrderNumber}</span>
                        </OverlayTrigger>
                        {showChassis && (<OverlayTrigger placement="bottom" overlay={tooltipChassis} >
                          <span className="fecha">{vinChassis} {licensePlate}</span>
                        </OverlayTrigger>
                        )}
                        {showCreator && (<OverlayTrigger placement="bottom" overlay={tooltipCreator} >
                          <span className="fecha">{creatorName}</span>
                        </OverlayTrigger>
                        )}
                      </div>
                    </span>
                  </div>
                </Col>
                <Col sm={2}>
                  <div className="text-band">
                    <span className="first">{t(`${tKey}UO`)}:</span>
                    <span className="second" id="organizedUnit">{headerData.organizedUnit}</span>
                  </div>
                </Col>
                <Col sm={2}>
                  <div className="text-band">
                    <span className="first">{t(`${tKey}DOSSIER_SUBTYPE`)}:</span>
                    <span className="second" id="dossierSubType">{headerData.dossierSubType}</span>
                  </div>
                </Col>
                <Col sm={2}>
                  <div className="text-band">
                    <span className="first">{t(`${tKey}FUNCTIONAL_STATUS`)}:</span>
                    <span className="second" id="stage">{headerData.stage}</span>
                  </div>
                </Col>
                <Col sm={2}>
                  {headerData.dossierParentTitle &&
                    <div className="text-band">
                      <span className="first">{headerData.dossierParentTitle}</span>
                      {
                        headerData.dossierParentUrlToNavigate ? (
                          <Link to={`/dossier${headerData.dossierParentUrlToNavigate}`}>{headerData.dossierParentNumber}</Link>
                        ) : (
                          <span className="second">{headerData.dossierParentNumber}</span>
                        )
                      }
                    </div>
                  }
                </Col>
                <Col sm={2}>
                </Col>
              </Row>
            )}
          </div>
        </Col>
      </Row>
    )
  }
}

export default StatusHeader
