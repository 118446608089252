// Reac/Redux
import { put, call, select, takeEvery } from 'redux-saga/effects'

// Others
import { getAuth } from '../../../selectors/access/auth'
import { getFaqMasterFilter } from '../../../selectors/masters/faqMaster'
import { handleError } from '../../errors/errorManager'
import faqMasterActionTypes from '../../../constants/actions/masters/faq/faqMaster'
import { yesNoModal } from '../../modalsListeners/yesNoModal'

// Actions
import { showLoader, hideLoader } from '../../../actions/common.js'
import { fetchFaqListSuccess, openFaqModalSuccess, closeFaqModal, loadFaqSectionComboFilter, loadFaqSectionComboModal } from '../../../actions/masters/faq/faqMaster'

// Services
import putFaqService from '../../../services/faq/putFaq'
import postFaqService from '../../../services/faq/postFaq'
import postFaqListService from '../../../services/faq/postFaqList'
import deleteFaqService from '../../../services/faq/deleteFaq'
import getLanguageListComboService from '../../../services/language/getLanguageList'
import getFaqMasterByIdService from '../../../services/faq/getFaqMasterById'
import recoverFaqService from '../../../services/faq/recoverFaq'
import getFaqSectionComboService from '../../../services/faqSection/getFaqSectionCombo'
import getFaqSectionService from '../../../services/faqSection/getFaqSection'

export function * fetchFaqList ({ filter }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield filter = yield filter || select(getFaqMasterFilter)
    const faqList = yield call(postFaqListService, auth.token, filter)
    yield put(fetchFaqListSuccess(faqList, filter))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchFaqList () {
  yield takeEvery(faqMasterActionTypes.FETCH_FAQ_LIST, fetchFaqList)
}

export function * deleteFaq ({ faqId }) {
  try {
    const confirmed = yield call(yesNoModal, 'deleteFaq')
    if (confirmed) {
      yield put(showLoader())
      const auth = yield select(getAuth)
      const filter = yield filter || select(getFaqMasterFilter)
      yield call(deleteFaqService, auth.token, faqId)
      const faqList = yield call(postFaqListService, auth.token, filter)
      yield put(fetchFaqListSuccess(faqList, filter))
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDeleteFaq () {
  yield takeEvery(faqMasterActionTypes.DELETE_FAQ, deleteFaq)
}

export function * openFaqModal ({ faqId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const languageList = yield call(getLanguageListComboService, auth.token)
    let faqMaster = []
    let organizedUnitId = null
    if (faqId) {
      faqMaster = yield call(getFaqMasterByIdService, auth.token, faqId)
      let faqSection = yield call(getFaqSectionService, auth.token, faqMaster.faqSectionId)
      organizedUnitId = faqSection.organizedUnitId
    }
    const faqSectionCombo = yield call(getFaqSectionComboService, organizedUnitId, auth.token)
    yield put(openFaqModalSuccess(languageList, faqMaster, faqSectionCombo, organizedUnitId))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchOpenFaqModal () {
  yield takeEvery(faqMasterActionTypes.OPEN_FAQ_MODAL, openFaqModal)
}

export function * recoverFaqModal ({ faqId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const filter = yield filter || select(getFaqMasterFilter)
    yield call(recoverFaqService, auth.token, faqId)
    const faqList = yield call(postFaqListService, auth.token, filter)
    yield put(fetchFaqListSuccess(faqList, filter))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchRecoverFaqModal () {
  yield takeEvery(faqMasterActionTypes.RECOVER_FAQ, recoverFaqModal)
}

export function * saveFaqModal ({ faqSectionId, faqValues, faqId, rating }) {
  try {
    yield put(showLoader())
    const filter = yield filter || select(getFaqMasterFilter)
    const auth = yield select(getAuth)
    if (faqId) {
      yield call(putFaqService, faqId, faqSectionId, rating, faqValues, auth.token)
    } else {
      yield call(postFaqService, faqSectionId, rating, faqValues, auth.token)
    }
    const faqList = yield call(postFaqListService, auth.token, filter)
    yield put(fetchFaqListSuccess(faqList, filter))
    yield put(closeFaqModal())
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSaveFaqModal () {
  yield takeEvery(faqMasterActionTypes.SAVE_FAQ, saveFaqModal)
}

export function * fetchFaqSectionCombo ({ organizedUnitId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const faqSectionCombo = yield call(getFaqSectionComboService, organizedUnitId, auth.token)
    yield put(loadFaqSectionComboFilter(faqSectionCombo))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchFaqSectionCombo () {
  yield takeEvery(faqMasterActionTypes.FETCH_FAQ_SECTION_COMBO, fetchFaqSectionCombo)
}

export function * fetchFaqSectionComboModal ({ organizedUnitId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const faqSectionCombo = yield call(getFaqSectionComboService, organizedUnitId, auth.token)
    yield put(loadFaqSectionComboModal(faqSectionCombo))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchFaqSectionComboModal () {
  yield takeEvery(faqMasterActionTypes.FETCH_FAQ_SECTION_COMBO_MODAL, fetchFaqSectionComboModal)
}
