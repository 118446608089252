import { call, put, select, takeLatest } from 'redux-saga/effects'
import { getAuth } from '../../selectors/access/auth'
import getDossierTypeByUo from '../../services/dossierType/getDossierTypeByUo'
import getDossierTypeAll from '../../services/dossierType/getDossierTypeAll'
import commonActions from '../../actions/common'
import messageModalActions from '../../actions/modals/messageModal'
import { fetchDossierTypesSuccess } from '../../actions/combos/combos'
import combosActionTypes from '../../constants/actions/combos/combos'
import { handleError } from '../errors/errorManager'

export function * fetchDossierTypes ({ ouId }) {
  try {
    yield put(commonActions.showLoader())
    const auth = yield select(getAuth)
    let response = []
    if (ouId) {
      response = yield call(getDossierTypeByUo, ouId, auth.token)
    } else {
      response = yield call(getDossierTypeAll, auth.token)
    }
    yield put(fetchDossierTypesSuccess(response))
  } catch (error) {
    const customHandler = function * () {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.FETCH_COMBO_ERROR'))
    }
    yield call(handleError, { error, customHandler })
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchFetchDossierTypes () {
  yield takeLatest(combosActionTypes.FETCH_DOSSIER_TYPES, fetchDossierTypes)
}
