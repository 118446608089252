import React, { PureComponent } from 'react'
import { Row, Col } from 'react-bootstrap'

class Tab1 extends PureComponent {
  render () {
    return (
      <Col sm={12} className="tab1-wrapper">
        <Row>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Gestor:</span>
              <span className="second number-modal">Nombre del gestor</span>
            </div>
          </Col>
          <Col sm={3}>
            <div className="text-band">
              <span className="first"> Fecha/hora pedido:</span>
              <span className="second number-modal">Fecha/hora</span>
            </div>
          </Col>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Nº Expediente:</span>
              <span className="second number-modal">Nº Expediente</span>
            </div>
          </Col>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Nº Expediente relacionado:</span>
              <span className="second number-modal">Nº Expediente relacionado</span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Estado del expediente:</span>
              <span className="second number-modal">Estado del expediente</span>
            </div>
          </Col>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Estado funcional:</span>
              <span className="second number-modal">Estado funcional</span>
            </div>
          </Col>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Estado documental:</span>
              <span className="second number-modal">Estado documental</span>
            </div>
          </Col>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Tipo de movimiento:</span>
              <span className="second number-modal">Tipo de movimiento</span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Proveedor del transporte:</span>
              <span className="second number-modal">Proveedor del transporte</span>
            </div>
          </Col>
        </Row>
      </Col>
    )
  }
}

export default Tab1
