import apiFetch from '../../apiFetch'

export default function (token, uoId, salesmanId) {
  const queryParamsArray = [
    uoId ? `organizedUnit=${uoId}` : null,
    salesmanId ? `salesman=${salesmanId.toString()}` : null
  ]
  const queryParams = queryParamsArray.reduce(
    (result, query, idx) => result + (query ? (result ? `&${query}` : `?${query}`) : '')
    , ''
  )
  return apiFetch({ endPoint: `dossier/dashboard${queryParams}`, method: 'GET', body: null, token: token })
}
