import changeUoActionTypes from '../../../constants/actions/dossier/common/changeUo'

export function getChangeUo (dossierId, organizedUnitId) {
  return {
    type: changeUoActionTypes.GET_CHANGES_UO,
    dossierId,
    organizedUnitId
  }
}
export function getChangeUoSuccess ({ changesDossierUo }) {
  return {
    type: changeUoActionTypes.GET_CHANGES_UO_SUCCESS,
    changesDossierUo
  }
}

export function getSalesmanChangeUo (organizedUnitId) {
  return {
    type: changeUoActionTypes.GET_SALESMAN_CHANGE,
    organizedUnitId
  }
}
export function getSalesmanChangeUoSuccess ({ salesman }) {
  return {
    type: changeUoActionTypes.GET_SALESMAN_CHANGE_SUCCESS,
    salesman
  }
}

export function postUpdateChangeUo (organizedUnitId, changesUO, salesmanId) {
  return {
    type: changeUoActionTypes.POST_UPDATE_CHAGE_UO,
    organizedUnitId,
    changesUO,
    salesmanId
  }
}

export default {
  getChangeUo,
  getChangeUoSuccess,
  getSalesmanChangeUo,
  getSalesmanChangeUoSuccess,
  postUpdateChangeUo
}
