import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import ProcessingAgencyIntegrationDocTypeUsesMasterModal from '../../../../components/masters/processing/processingAgencyIntegrations/ProcessingAgencyIntegrationDocTypeUsesMasterModal'
import { showProcessingAgencyDocTypeUsesModal, submitProcessingAgencyDocTypeUses } from '../../../../actions/masters/processingAgency/processingAgencyIntegrations/processingAgencyDocTypeUses'

export function mapStateToProps (state) {
  const docTypeUsesModal = state.processingAgency?.processingAgencyIntegrationModal?.docTypeUses?.docTypeUsesModal
  return {
    agency: state.processingAgency.processingAgencyIntegrationModal.agency,
    showModal: docTypeUsesModal?.showModal,
    processingAgencyDocTypeUse: docTypeUsesModal?.processingAgencyDocTypeUse,
    languageList: docTypeUsesModal?.languageList,
    isReturnDocument: docTypeUsesModal?.isReturnDocument,
    combos: {
      documentTypeUseCombo: state.combos.documentTypeUseCombo,
      paperworkTypes: state.combos.paperworkTypes
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      showProcessingAgencyDocTypeUsesModal,
      submitProcessingAgencyDocTypeUses
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(ProcessingAgencyIntegrationDocTypeUsesMasterModal))
