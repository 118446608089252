import { buttonsHeaderPermisionsId, buttonsHeaderPermisions } from '../constants/dossier/common/buttonsHeader'
import { permissions } from '../constants/backendIds'
export function getComponentsPermisions (tabsConfiguration = [], activeTab, componentKeys) {
  const permisions = {}
  let componentConfiguration

  permisions.activeTabConfiguration = tabsConfiguration.filter((tabConf) => tabConf.code === buttonsHeaderPermisionsId[activeTab])
  permisions.activeTabConfiguration = permisions.activeTabConfiguration.length > 0 ? permisions.activeTabConfiguration[0] : null

  permisions.headerTabConfiguration = tabsConfiguration.filter((tabConf) => tabConf.code === buttonsHeaderPermisions.header)
  permisions.headerTabConfiguration = permisions.headerTabConfiguration.length > 0 ? permisions.headerTabConfiguration[0] : null

  if (permisions.activeTabConfiguration) {
    permisions.componentsConfiguration = {}
    componentKeys.forEach((componentKey) => {
      componentConfiguration = permisions.activeTabConfiguration.componentsConfiguration.filter((componentConf) => componentConf.code === componentKey)
      componentConfiguration = componentConfiguration.length > 0 ? componentConfiguration[0] : null
      permisions.componentsConfiguration[componentKey] = componentConfiguration
    })
  }
  return permisions
}

export function getFieldsPermisions (fieldsConfiguration, fieldKeys, componentPermision) {
  const permisions = {
    fieldsConfiguration: {}
  }
  let fieldConfiguration
  fieldKeys.forEach((fieldKey) => {
    fieldConfiguration = fieldsConfiguration.filter((fieldConf) => fieldConf.code === fieldKey)
    permisions.fieldsConfiguration[fieldKey] = fieldConfiguration.length > 0 ? fieldConfiguration[0] : { code: fieldKey, permission: Array.isArray(componentPermision) ? componentPermision : permissions.hidden }
  })

  return permisions
}

export function getFieldsSectionPermissions (fieldsConfiguration, fieldKeys, componentPermision) {
  const permisions = {
    fieldsConfiguration: {}
  }
  let fieldConfiguration
  fieldKeys.forEach((fieldKey) => {
    fieldConfiguration = fieldsConfiguration[fieldKey]
    permisions.fieldsConfiguration[fieldKey] = fieldConfiguration || { code: fieldKey, permission: componentPermision }
  })
  return permisions
}

export function getHeaderPermisions (headerTabConfiguration) {
  const permisions = {
    summary: headerTabConfiguration.permission,
    stages: headerTabConfiguration.permission,
    checklist: headerTabConfiguration.permission,
    qrcode: headerTabConfiguration.qrcode
  }
  if (headerTabConfiguration && headerTabConfiguration.componentsConfiguration && headerTabConfiguration.componentsConfiguration.length > 0) {
    headerTabConfiguration.componentsConfiguration.forEach((componentKey) => {
      permisions[componentKey.code] = { code: componentKey.code, permission: componentKey.permission }
    })
  }
  return permisions
}

export function getSectionPermissions (section) {
  const sectionTabConfiguration = {}
  section.sectionTabsConfiguration.forEach((tab) => {
    const tabPermission = { code: tab.code, permission: tab.permission, sectionFieldsConfiguration: [] }
    if (tab.sectionFieldsConfiguration) {
      tab.sectionFieldsConfiguration.forEach((field) => {
        tabPermission.sectionFieldsConfiguration[field.code] = field
      })
    }
    sectionTabConfiguration[tab.code] = tabPermission
  })
  return sectionTabConfiguration
}

export function getFieldsConfiguration (fields, fieldKeys, componentPermision) {
  const permisions = {
  }
  if (fields && fields.fieldsConfiguration) {
    fields.fieldsConfiguration.forEach((field) => {
      permisions[field.code] = field
    })
  }
  return permisions
}
