import usageActionTypes from '../../constants/actions/reports/usageReport'

export function fetchUsageList (filter) {
  return {
    type: usageActionTypes.FETCH_USAGE_REPORT,
    filter
  }
}

export function fetchUsageReportSuccess (usageList, filter) {
  return {
    type: usageActionTypes.FETCH_USAGE_REPORT_SUCCESS,
    usageList,
    filter
  }
}

export function downloadUsageList (filter) {
  return {
    type: usageActionTypes.DOWNLOAD_USAGE_REPORT,
    filter
  }
}

export function fetchUsageInfo (filter) {
  return {
    type: usageActionTypes.FETCH_USAGE_INFO,
    filter
  }
}

export function downloadUsageInfo (filter) {
  return {
    type: usageActionTypes.DOWNLOAD_USAGE_INFO,
    filter
  }
}

export function fetchUsageInfoSuccess (usageDetail, filter) {
  return {
    type: usageActionTypes.FETCH_USAGE_INFO_SUCCESS,
    usageDetail,
    filter
  }
}

export function clearSearch () {
  return {
    type: usageActionTypes.CLEAR_SEARCH
  }
}

export function clearDynamicFilters () {
  return {
    type: usageActionTypes.CLEAR_DYNAMIC_FILTERS
  }
}

export function clearSearchResultList () {
  return {
    type: usageActionTypes.CLEAR_SEARCH_RESULT_LIST
  }
}
