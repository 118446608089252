import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { fetchDossierFavorites, fetchDossierLastAccessed, switchCardDossierFavorite } from '../../actions/dashboard/dashboard'
import ExtraBox from '../../components/dashboard/extra/extraBox'
import { translate } from 'react-polyglot'
import TrackingContainer from '../tracking/trackingContainer'
// import { push as navigateTo } from 'react-router-redux'

export function mapStateToProps (state) {
  return {
    ...state.dashboard
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchDossierFavorites,
      fetchDossierLastAccessed,
      switchCardDossierFavorite
    }, dispatch)
  }
}
var decoratedComponent = TrackingContainer(ExtraBox)
export default connect(mapStateToProps, mapDispatchToProps)(translate()(decoratedComponent))
