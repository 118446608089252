import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { closeUserProcessingAgencyModal, deleteUserProcessingAgency, chargeUsersProcessingAgencyModal, getUsersProcessingAgency, openUserProcessingAgencyAddModal } from '../../../actions/masters/masters'
import ProcessingAngencyUsersModal from '../../../components/masters/processing/ProcessingAngencyUsersModal'

export function mapStateToProps (state) {
  return {
    ...state.processingAgency.processingAngencyUsersModal
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      closeUserProcessingAgencyModal,
      deleteUserProcessingAgency,
      chargeUsersProcessingAgencyModal,
      getUsersProcessingAgency,
      openUserProcessingAgencyAddModal

    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(ProcessingAngencyUsersModal))
