import React, { PureComponent } from 'react'
import { Popover, Overlay, Button, Glyphicon } from 'react-bootstrap'
import DossierListPopoverEditionModal from './DossierListPopoverEditionModal'

class VehiclePopoverEditionModal extends PureComponent {
  constructor () {
    super()
    this.state = {
      showPopover: false,
      target: this
    }
  }

  handleClick (e) {
    e.stopPropagation()
    this.setState({ target: e.target, showPopover: true })
  }

  render () {
    const {
      t,
      idx,
      half,
      id,
      lives,
      stored,
      dossier,
      vehicle = {}
    } = this.props
    const tKey = 'SEARCH.CATEGORIES.'
    if (dossier === true && Object.keys(vehicle).length === 0) return null
    else if (dossier === true) {
      return (
        <div className="subcabecera">
          <i className="subcabecera-ico ico-car"/>
          <span className="info-entidad-documento">
            {vehicle.vehicleVinChassis ? vehicle.vehicleVinChassis : vehicle.vehicleModel}
          </span>
          {Object.keys(vehicle).length > 0 && (
            <Button bsSize="xsmall" className="icon-only icon-plus-subcabecera" onClick={this.handleClick.bind(this)}><Glyphicon glyph="plus"/></Button>
          )}
          <span className="espacio-subcabecera"></span>
          <Overlay
            show={this.state.showPopover}
            target={this.state.target}
            onHide={() => this.setState({ showPopover: false })}
            rootClose
            placement={(idx >= half) ? 'top' : 'bottom'}
          >
            <Popover id={id} className="vehicle-popover-container-doc" key="popover">
              {vehicle.vehicleBrand &&
              <div className="popover-item">
                <label>{t(`${tKey}VEHICLE.BRAND`)}: </label><br/>
                {vehicle.vehicleBrand}
              </div>
              }
              {vehicle.carline &&
              <div className="popover-item">
                <label>{t(`${tKey}VEHICLE.CARLINE`)}: </label><br/>
                {vehicle.carline}
              </div>
              }
              {vehicle.catalogue &&
              <div className="popover-item">
                <label>{t(`${tKey}VEHICLE.CATALOGUE`)}: </label><br/>
                {vehicle.catalogue}
              </div>
              }
              {vehicle.modelYear &&
              <div className="popover-item">
                <label>{t(`${tKey}VEHICLE.MODEL_YEAR`)}: </label><br/>
                {vehicle.modelYear}
              </div>
              }
              {vehicle.vehicleModel &&
              <div className="popover-item">
                <label>{t(`${tKey}VEHICLE.MODEL`)}: </label><br/>
                {vehicle.vehicleModel}
              </div>
              }
              {vehicle.vehicleVinChassis &&
              <div className="popover-item">
                <label>{t(`${tKey}VEHICLE.CHASSIS`)}: </label><br/>
                {vehicle.vehicleVinChassis}
              </div>
              }
              {vehicle.vehicleLicensePlate &&
              <div className="popover-item">
                <label>{t(`${tKey}VEHICLE.LICENSE_PLATE`)}: </label><br/>
                {vehicle.vehicleLicensePlate}
              </div>
              }
              {vehicle.comercialCode &&
              <div className="popover-item">
                <label>{t(`${tKey}VEHICLE.COMMERCIAL_CODE`)}: </label><br/>
                {vehicle.comercialCode}
              </div>
              }
              {vehicle.licensePlateDate &&
              <div className="popover-item">
                <label>{t(`${tKey}VEHICLE.FIRST_LPLATE_DATE`)}: </label><br/>
                {vehicle.licensePlateDate}
              </div>
              }
              {vehicle.color &&
              <div className="popover-item">
                <label>{t(`${tKey}VEHICLE.COLOR`)}: </label><br/>
                {vehicle.color}
              </div>
              }
              {vehicle.tapestry &&
              <div className="popover-item">
                <label>{t(`${tKey}VEHICLE.TAPESTRY`)}: </label><br/>
                {vehicle.tapestry}
              </div>
              }
            </Popover>
          </Overlay>
        </div>
      )
    } else {
      return (
        <div className="subcabecera" key="subcabecera1">
          <i className="subcabecera-ico ico-car"/>
          {vehicle.vehicleBrand && (
            <span className="info-entidad-documento">
              {vehicle.vehicleBrand}
            </span>
          )}
          {vehicle.vehicleModel && (
            <span className="info-entidad-documento">
              {vehicle.vehicleModel}
            </span>
          )}
          {vehicle.vehicleVinChassis && (
            <span className="info-entidad-documento">
              {vehicle.vehicleVinChassis}
            </span>
          )}
          {Object.keys(vehicle).length > 0 && (
            <Button bsSize="xsmall" className="icon-only icon-plus-subcabecera" onClick={this.handleClick.bind(this)}>
              <Glyphicon glyph="plus"/>
            </Button>
          )}
          <span className="espacio-subcabecera"></span>
          <Overlay
            show={this.state.showPopover}
            target={this.state.target}
            onHide={() => this.setState({ showPopover: false })}
            rootClose
            placement={(idx >= half) ? 'top' : 'bottom'}
          >
            <Popover id={id} className="vehicle-popover-container-doc" key="popover">
              {vehicle.comercialCode &&
              <div className="popover-item">
                <label>{t(`${tKey}VEHICLE.COMMERCIAL_CODE`)}: </label><br/>
                {vehicle.comercialCode}
              </div>
              }
              {vehicle.licensePlateDate &&
              <div className="popover-item">
                <label>{t(`${tKey}VEHICLE.FIRST_LPLATE_DATE`)}: </label><br/>
                {vehicle.licensePlateDate}
              </div>
              }
              {vehicle.color &&
              <div className="popover-item">
                <label>{t(`${tKey}VEHICLE.COLOR`)}: </label><br/>
                {vehicle.color}
              </div>
              }
            </Popover>
          </Overlay>
          {vehicle.vehicleLicensePlate && (
            <span className="info-entidad-documento">
              {vehicle.vehicleLicensePlate}
            </span>
          )}
          <DossierListPopoverEditionModal
            t={t}
            idx="exp-asociados1"
            key="exp-asociados1"
            id={'popover-dossier-list'}
            alive={lives}
            stored={stored}
          />
        </div>
      )
    }
  }
}

export default VehiclePopoverEditionModal
