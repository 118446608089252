
import React, { PureComponent } from 'react'
import { Row, Button, Modal, Col, ProgressBar } from 'react-bootstrap'
import { reduxForm, Field } from 'redux-form'
import InputText from '../../../../components/commons/form/InputText'
import { entityTypesId } from '../../../../constants/backendIds'
import { dossierTypes, dossierTypes as dossierTypesConstant } from '../../../../constants/dossier/common/dossierTypes'
import Client from '../../../../components/dossiers/common/Client'
import { validateEntity } from '../../../../util/validationFunctions'
import { dossierSubTypeId } from '../../../../constants/dossier/common/dossierSubType'
import {
  asyncValidateEntityDni, getFieldsValidated
} from '../../../../util/validationFunctionEntityDniAsync'
import { validationAddNewFleerModalPromisesOrder } from '../../../../constants/validatedFields/backValidationPromisesOrder'
import promisesStatus from '../../../../constants/promisesStatus'

class AddNewFleetModal extends PureComponent {
  constructor () {
    super()
    this.state = {
      displayBar: 'none',
      maxBar: 0,
      progressFail: 0,
      progress: 0,
      errors: [],
      disableClient: true
    }
  }

  componentDidUpdate () {
  }

  componentDidMount () {
    this.props.actions.fetchIaeCombo()
    this.props.actions.fetchCommercialSocietyTypeCombo()
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (prevProps.showModal !== this.props.showModal) {
      this.props.actions.clearEntity(-1, dossierSubTypeId.venta_vn)
    }

    if (this.props.dossiers && JSON.stringify(prevProps.dossiers) !== JSON.stringify(this.props.dossiers)) {
      this.setState({
        displayBar: 'none',
        maxBar: 0,
        progressFail: 0,
        progress: 0,
        errors: []
      })
      this.props.change('fleetCode', null)
      this.props.change('description', null)
      this.props.change('observations', null)
      this.setState({ disableClient: true })
    }
    if (this.props.fleet && JSON.stringify(prevProps.fleet) !== JSON.stringify(this.props.fleet)) {
      this.props.change('fleetCode', this.props.fleet.fleetCode)
      this.props.change('description', this.props.fleet.description)
      this.props.change('observations', this.props.fleet.observations)
      this.setState({ disableClient: false })

      this.props.actions.getClientFleetPermissions(this.props.fleet.fleetId, null)
      this.props.actions.fetchFleetsEntity(this.props.fleet.fleetId, this.props.organizedUnitId)
      if (!this.props.create) {
        this.props.actions.checkChangeEntityDossier(this.props.fleet.fleetId, null)
      }
    }
    if (this.props.organizedUnitId && this.props.organizedUnitId !== prevProps.organizedUnitId && this.props.create) {
      this.props.actions.getClientFleetPermissions(null, this.props.organizedUnitId)
    }

    return null
  }

  createOrUpdateFleet (values) {
    let fleet = {}
    fleet.FleetCode = values.fleetCode
    fleet.Description = values.description
    fleet.Observations = values.observations
    fleet.EntityComponent = values.entityComponent
    fleet.OrganizedUnitId = this.props.organizedUnitId

    if (this.props.create) {
      if (this.dossiersIsFleet()) {
        this.props.actions.openModal('actionAdminFleetExpYetFleet', () => this.createFleet(fleet))
      } else {
        this.createFleet(fleet)
      }
    } else {
      this.updateFleet(fleet)
    }
  }

  dossiersIsFleet () {
    let dossiers = this.props.dossierData
    let isFleet = false
    this.props.dossiers.forEach(x => {
      let dossier = dossiers.find(d => d.dossierId === x)
      if (dossier && dossier.fleetCode !== null && dossier.fleetCode !== undefined) {
        isFleet = true
      }
    })
    return isFleet
  }

  createFleet (fleetModel) {
    new Promise((resolve, reject) => {
      this.props.actions.createUpdateFleet(fleetModel, resolve, reject, this.props.fleet)
    }).then((response) => {
      this.assignDossierFleet(response.fleetId)
    }).catch((err) => {
      if (err.json && err.json.tag && err.json.tag === 'codeFleetRepeated') {
        this.props.actions.openModal('actionAdminFleetExpFleetRepeated')
      } else if (err.json && err.json.tag && err.json.tag === 'codeFleetCreateParams') {
        this.props.actions.openModal('codeFleetCreateParams')
      } else {
        this.props.actions.openModal('actionAdminFleetExpFleetError')
      }
    })
  }

  assignDossierFleet (fleetId) {
    this.props.actions.setActionExpFleetProcess(this.props.dossiers.join('$'), 1, fleetId, () => { this.props.actions.closeNewFleetModal(); this.props.actions.closeActionFleetFinder(); this.props.fetchFleets(this.props.fleetsFilter) })
  }

  launchNextassignDossierFleet (index, fleetId) {
    if (this.props.dossiers[index]) {
      new Promise((resolve, reject) => {
        return this.props.actions.asignDossierToFleet(this.props.dossiers[index], fleetId, resolve, reject)
      }).then((response) => {
        this.setState({ progress: this.state.progress + 1 })
        this.launchNextassignDossierFleet(index + 1, fleetId)
      }).catch((error) => {
        this.setState({ errors: [...this.state.errors, error.json.message] })
        this.setState({ progressFail: this.state.progressFail + 1 })
        this.launchNextassignDossierFleet(index + 1, fleetId)
      })
    } else {
      let errors = ''
      this.state.errors.forEach(x => {
        var errormessage = this.props.t('MESSAGES.ACTION_FLEET_ENTITY_ERROR')
        var ob = JSON.parse(x)
        for (var key in ob) {
          errormessage = errormessage.replace('##' + key + '##', ob[key])
        }
        errors = errors + '\n' + errormessage
      })
      this.props.actions.openModal('actionAdminFleetExp', () => { this.props.actions.closeNewFleetModal(); this.props.actions.closeActionFleetFinder(); this.props.fetchFleets(this.props.fleetsFilter) }, null, null, null, [{ id: '##NUM_OK##', value: this.state.progress }, { id: '##NUM_TOTAL##', value: this.props.dossiers.length }, { id: '##ERRORS##', value: errors }], null)
    }
  }

  updateFleet (fleetModel) {
    new Promise((resolve, reject) => {
      fleetModel.FleetId = this.props.fleet.fleetId
      this.props.actions.createUpdateFleet(fleetModel, resolve, reject, this.props.fleet)
    }).then((response) => {
      if (this.props.fleet.dossiers > 0) {
        this.assignDossierFleetEntity()
      } else {
        this.props.actions.openModal('actionChangeDataFleet', () => { this.props.actions.closeNewFleetModal(); this.props.actions.fetchFleetsInfo(this.props.fleetFinderFilter) }, null, null, null, null, null)
      }
    }).catch((err) => {
      if (err.json && err.json.tag && err.json.tag === 'codeFleetRepeated') {
        this.props.actions.openModal('actionAdminFleetExpFleetRepeated')
      } else if (err.json && err.json.tag && err.json.tag === 'codeFleetCreateParams') {
        this.props.actions.openModal('codeFleetCreateParams')
      } else {
        this.props.actions.openModal('actionAdminFleetExpFleetError')
      }
    })
  }

  assignDossierFleetEntity () {
    this.props.actions.setActionExpFleetProcess(this.props.fleet.dossierInfo.map(d => d.dossierId).join('$'), 3, this.props.fleet.fleetId, () => { this.props.actions.closeNewFleetModal(); this.props.actions.fetchFleetsInfo(this.props.fleetFinderFilter) })
  }

  launchNextassignDossierFleetEntity (index) {
    if (this.props.fleet.dossierInfo[index]) {
      new Promise((resolve, reject) => {
        return this.props.actions.changeEntityDossierFromFleet(this.props.fleet.dossierInfo[index].dossierId, this.props.fleet.fleetId, resolve, reject)
      }).then((response) => {
        this.setState({ progress: this.state.progress + 1 })
        this.launchNextassignDossierFleetEntity(index + 1)
      }).catch((error) => {
        this.setState({ errors: [...this.state.errors, error.json.message] })
        this.setState({ progressFail: this.state.progressFail + 1 })
        this.launchNextassignDossierFleetEntity(index + 1)
      })
    } else {
      let errors = ''
      this.state.errors.forEach(x => {
        errors = errors + '\n' + x
      })
      this.props.actions.openModal('actionAdminFleetExp', () => { this.props.actions.closeNewFleetModal(); this.props.actions.fetchFleetsInfo(this.props.fleetFinderFilter) }, null, null, null, [{ id: '##NUM_OK##', value: this.state.progress }, { id: '##NUM_TOTAL##', value: this.props.fleet.dossiers }, { id: '##ERRORS##', value: errors }], null)
    }
  }

  onRecurrentTypeIdChanges (entitySubTypeId) {
    this.props.change('dniCif', null)
    this.props.change('entityId', null)
    if (entitySubTypeId) {
      this.props.actions.fetchEntityFilterSubTypeCombo(entityTypesId.recurrentEntity, entitySubTypeId, this.props.organizedUnitId)
      this.setState({ disableClient: false })
    }
  }

  onRecurrentEntityIdChanges (entityId) {
    if (entityId) {
      this.props.actions.fetchClientFromDocument(entityId, dossierTypes.fleetFinder)
    } else {
      this.props.change('dniCif', null)
    }
  }

  updateComponentAfterDeleteAndRestart () {

  }

  render () {
    const {
      t, change,
      showModal, handleSubmit, combos, entityComponent, entityChildComponent, dispatch, formValues,
      actions: {
        fetchClientByDni,
        fetchClientByDniEntityChild,
        fetchClientFromDocument,
        setEntityTypeId,
        setEntitySubTypeId,
        setEntityId,
        fetchEntitySubTypeCombo,
        fetchEntityFilterSubTypeCombo,
        fetchEntityMaritalStatusCombo,
        setEntityChildTypeId,
        clearEntity,
        clearEntityChild,
        fetchSalesClient,
        setEntityValidationDisable,
        setActiveSection,
        fetchCommercialSocietyTypeCombo,
        fetchEntityCountryTypeCombo,
        fetchEntityStateTypeCombo,
        fetchEntityCityTypeCombo,
        fetchEntityTownTypeCombo,
        fetchEntityColonyTypeCombo,
        fetchEntityCPTypeCombo,
        openModal,
        fetchActivityBpro,
        validate,
        stopAsyncValidation,
        resetValues,
        fetchClientDataAddress
      }
    } = this.props
    const tKey = 'MANAGEMENT.FLEETS_TAB.'
    let title = (this.props.create ? t(`${tKey}TITLE_ADD_FLEET`) : t(`${tKey}TITLE_EDIT_FLEET`))
    if (this.props.fleet?.organizedUnit !== undefined) {
      title = title + ' - UO : ' + this.props.fleet?.organizedUnit
    } else if (this.props.dossierData?.length > 0) {
      title = title + ' - UO : ' + this.props.dossierData[0].organizedUnit
    }

    let dossier = {
      isPreCancelled: false,
      entityComponent: entityComponent,
      entityProviderComponent: entityComponent,
      dossierParentId: null,
      parentEntityId: null,
      entityChildComponent: entityChildComponent,
      entityTypeIdDefault: null,
      salesComponent: null,
      entityFromParentDossier: null,
      dossierSubTypeId: dossierSubTypeId.venta_vn,
      organizedUnitId: this.props.organizedUnitId,
      dossierId: -1
    }

    return (
      <Modal className="_nf AddNewFleetModal" show={showModal} onHide={this.props.actions.closeNewFleetModal}>
        <form autoComplete="off" onSubmit={handleSubmit((values) => this.createOrUpdateFleet(values))}>
          <Modal.Header closeButton onHide={this.props.actions.closeNewFleetModal}>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="inputs-wrapper-fleet">
              <Col >
                <Field
                  id="fleetCode"
                  name='fleetCode'
                  placeholder={t(`${tKey}FLEET`)}
                  controlLabel={t(`${tKey}FLEET`)}
                  component={InputText}
                  type="text"
                  maxLength={20}
                  customClass = 'col-check'
                />
              </Col>

              <Col>
                <Field
                  id="description"
                  name='description'
                  placeholder={t(`${tKey}DESCRIPTION`)}
                  controlLabel={t(`${tKey}DESCRIPTION`)}
                  component={InputText}
                  type="text"
                  maxLength={100}
                  customClass = 'col-check'
                />
              </Col>
            </Row>

            <Row className="inputs-wrapper-fleet">
              <Col>
                <Field
                  id="observations"
                  name='observations'
                  placeholder={t(`${tKey}OBSERVATIONS`)}
                  controlLabel={t(`${tKey}OBSERVATIONS`)}
                  component={InputText}
                  componentClass="textarea"
                  customClass = 'col-action'
                  resize= 'none'
                />
              </Col>
            </Row>
            <div className='client-div'>
              <Client
                t={t}
                isClientLoaded = {true}
                dossier={dossier}
                combos={combos}
                entityChangeable={this.props.changeEntity}
                dossierType={dossierTypesConstant.addNewFleetModal}
                actions={{
                  change,
                  fetchClientByDni,
                  fetchClientByDniEntityChild,
                  fetchClientFromDocument,
                  setEntityTypeId,
                  setEntitySubTypeId,
                  setEntityId,
                  fetchEntitySubTypeCombo,
                  fetchEntityFilterSubTypeCombo,
                  setEntityChildTypeId,
                  clearEntity,
                  clearEntityChild,
                  fetchSalesClient,
                  setEntityValidationDisable,
                  setActiveSection,
                  fetchCommercialSocietyTypeCombo,
                  fetchEntityCountryTypeCombo,
                  fetchEntityStateTypeCombo,
                  fetchEntityCityTypeCombo,
                  fetchEntityTownTypeCombo,
                  fetchEntityColonyTypeCombo,
                  fetchEntityCPTypeCombo,
                  fetchEntityMaritalStatusCombo,
                  openCommonModal: openModal,
                  fetchActivityBpro,
                  validate,
                  dispatch,
                  resetValues,
                  asyncValidate,
                  stopAsyncValidation: (value) => stopAsyncValidation('addNewFleetModal', value),
                  fetchClientDataAddress
                }}
                readOnlyFields={!this.props.changeEntity}
                open={true}
                componentConfiguration={this.props.clientFleetPermissions}
                disableDeleteButton ={!this.props.changeEntity}
                handleToUpdateComponent= {null}
                updateComponentAfterDeleteAndRestart = {() => this.updateComponentAfterDeleteAndRestart()}
                formValues={formValues}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <ProgressBar style={{ display: this.state.displayBar }}>
              <ProgressBar max={this.state.maxBar} now={this.state.progress} label={`${this.state.progress}/${this.state.maxBar}`} striped variant="success" key={1} />
              <ProgressBar max={this.state.maxBar} now={this.state.progressFail} label={`${this.state.progressFail}/${this.state.maxBar}`} striped variant="danger" style={{ backgroundColor: '#d44b4b' }} key={2} />
            </ProgressBar>
            <Col sm={6}>
              <Button
                className="btn-standard"
                type="submit">
                {t(`${tKey}ACCEPT`)}
              </Button>
            </Col>
            <Col sm={6} className="btn-wrapper">
              <Button
                id="role-modal-submit-button"
                className="btn-standard"
                onClick = {this.props.actions.closeNewFleetModal}>
                {t(`${tKey}CANCEL`)}
              </Button>
            </Col>
          </Modal.Footer>
        </form>
      </Modal>

    )
  }
}
const validate = (values, props) => {
  const errors = {}

  if (!values || !values.fleetCode) {
    errors.fleetCode = props.t('GLOBAL.FORMS.REQUIRED')
  }
  if (!values || !values.description) {
    errors.description = props.t('GLOBAL.FORMS.REQUIRED')
  }

  errors.entityComponent = validateEntity(values.entityComponent, props)

  return errors
}

const asyncValidate = (values, dispatch, props, field, returnError) => {
  let promises = []
  if (values.entityComponent) {
    promises = promises.concat(asyncValidateEntityDni(values.entityComponent, dispatch, props, props.organizedUnitId))
  }

  return Promise.allSettled(promises).then(result => {
    let error = {}
    for (let index = 0; index < result.length; index++) {
      const element = result[index]
      if (element.status === promisesStatus.FULFILLED) {
        switch (index) {
          case validationAddNewFleerModalPromisesOrder.ENTITY_COMPONENT:
            error = { ...error, entityComponent: element.value }
            break
          default:
            break
        }
      }
    }
    if (Object.keys(error).length > 0 && !returnError) throw Object.assign(new Error(), error)
    if (returnError) return error
  })
}

export default reduxForm({
  form: 'addNewFleetModal',
  validate,
  asyncValidate,
  asyncBlurFields: [...getFieldsValidated('entityComponent')]
})(AddNewFleetModal)
