import React, { PureComponent } from 'react'
import { Button, Modal, Col, Row, FormGroup } from 'react-bootstrap'
import { reduxForm, Field } from 'redux-form'
import TextInput from '../../commons/TextInput'
import InputNumber from '../../commons/form/InputNumber'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

class DuplicateSaleTypeModal extends PureComponent {
  constructor () {
    super()
    this.state = {
      dossierSubTypeChange: false
    }
    this.required = this.required.bind(this)
  }

  submitSaleType (values) {
    const SaleTypeLanguages = []
    Object.keys(values).map(item => {
      if (item.includes('lang')) {
        values[item].languageId = item.split('lang')[1]
        SaleTypeLanguages.push(values[item])
      }
    })
    var saleType = {}
    saleType.saleTypeId = this.props.saleTypeId
    saleType.code = values.code
    saleType.dossierSubTypeId = values.dossierSubTypeId
    saleType.SaleTypeLanguages = SaleTypeLanguages
    saleType.order = values.order

    this.props.actions.saveSaleTypeModal(saleType, null, true)
    this.props.actions.closeDuplicateSaleTypeModal()
  }

  componentDidUpdate (nextProps) {
    if (nextProps.showModal !== this.props.showModal && this.props.showModal === true) {
      this.props.initialize({ ...this.props })
      this.setState({ dossierSubTypeChange: false })
    }
    if (this.props.saleTypeLanguage !== nextProps.saleTypeLanguage && nextProps.saleTypeId !== this.props.saleTypeId || this.props.showModal !== nextProps.showModal) {
      this.props.initialize(nextProps.saleType)
      nextProps.languageList.map((language) => {
        this.props.change(`lang${language.languageId}.languageId`, language.languageId)
        const source = nextProps.saleTypeLanguage ? nextProps.saleTypeLanguage.find((sourceChannel) => sourceChannel.languageId === language.languageId) : null
        this.props.change(`lang${language.languageId}.name`, source ? source.name : '')
        this.props.change(`lang${language.languageId}.description`, source ? source.description : '')
      })
    }
  }

  required (value) {
    if (value || value === 0) {
      return undefined
    } else {
      return this.props.t('GLOBAL.FORMS.REQUIRED')
    }
  }

  render () {
    const {
      t, showModal, handleSubmit, languageList = []
    } = this.props
    const tKey = 'MASTERS.SALE_TYPE.'
    return (
      <Modal show={showModal} onHide={this.props.actions.closeDuplicateSaleTypeModal} className="languages-modal">
        <Modal.Header closeButton>
          <i className="ico-uo" /><Modal.Title>{t(`${tKey}TITLE`)}</Modal.Title>
        </Modal.Header>
        <form autoComplete="off" onSubmit={handleSubmit(this.submitSaleType.bind(this))}>
          <Modal.Body>
            <Row>
              <Col sm={8} className="custom-width-col">
                <h2>{t(`${tKey}CODE`)}</h2>
                <Field
                  name={'code'}
                  placeholder={t(`${tKey}CODE`)}
                  component={TextInput}
                  maxLength={50}
                  validate={this.required}
                />
              </Col>
              <Col sm={4} className="custom-width-col">
                <h2>{t(`${tKey}ORDER`)}</h2>
                <Field
                  name={'order'}
                  placeholder={t(`${tKey}ORDER`)}
                  component={InputNumber}
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <Row>
                  <FontAwesomeIcon icon={faExclamationTriangle} className="icon-warning" />
                  {t(`${tKey}DUPLICATE_WARNING`)}
                </Row>
                <ul className="dashed">
                  <li>{t(`${tKey}DUPLICATE_VIEWS`)}</li>
                  <li>{t(`${tKey}DUPLICATE_CONDITIONS`)}</li>
                  <li>{t(`${tKey}DUPLICATE_OPERATION_TYPES_BY_UO`)}</li>
                </ul>
              </Col>
            </Row>
            <div className="clearfix" />
            <Row>
              <Row className="title-column">
                <Col sm={4}>
                  <h2>{t('MASTERS.NAME')}</h2>
                </Col>
                <Col sm={6}>
                  <h2>{t('MASTERS.DESCRIPTION')}</h2>
                </Col>
                <Col sm={2}>
                  <h2>{t('MASTERS.LANGUAGE')}</h2>
                </Col>
              </Row>
              <FormGroup>
                {languageList.map((val, idx) => {
                  return (
                    <Row key={idx} className="inputs-wrapper">
                      <Col sm={4} className="custom-width-col">
                        <Field
                          name={`lang${val.languageId}.name`}
                          placeholder={t('MASTERS.NAME')}
                          component={TextInput}
                          maxLength={50}
                        />
                      </Col>
                      <Col sm={6} className="custom-width-col">
                        <Field
                          name={`lang${val.languageId}.description`}
                          placeholder={t('MASTERS.DESCRIPTION')}
                          component={TextInput}
                          maxLength={50}
                        />
                      </Col>
                      <Col sm={2} className="language-column">
                        <p>{`${val.description} (${val.name})`}</p>
                      </Col>
                    </Row>
                  )
                })}
              </FormGroup>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Col sm={12} className="btn-wrapper">
              <Button type="submit" className="btn-standard">
                {t('MASTERS.SAVE')}
              </Button>
            </Col>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}
const validate = (values, props) => {
  const errors = { }
  props.languageList.map(language => {
    errors[`lang${language.languageId}`] = {}
    if (values[`lang${language.languageId}`]) {
      if (!(values[`lang${language.languageId}`].name)) {
        errors[`lang${language.languageId}`].name = props.t('MASTERS.VALIDATION.NAME_REQUIRED')
      }
      if (!(values[`lang${language.languageId}`].description)) {
        errors[`lang${language.languageId}`].description = props.t('MASTERS.VALIDATION.DESCRIPTION_REQUIRED')
      }
    } else {
      errors[`lang${language.languageId}`].description = props.t('MASTERS.VALIDATION.DESCRIPTION_REQUIRED')
    }
  })
  return errors
}

export default reduxForm({
  form: 'duplicateSaleTypeModal',
  validate
})(DuplicateSaleTypeModal)
