import settings from '../../setting'
import { clientInformation } from '../../constants/backendIds'
export const contactRequiredFields = (values, props, entityTypeId) => {
  const fields = {}
  fields.contactName = 'DOSSIER_COMPONENTS.VALIDATIONS.ENTITY_NAME_MANDATORY'
  fields.contactSurname1 = 'DOSSIER_COMPONENTS.VALIDATIONS.ENTITY_SURNAME1_MANDATORY'
  fields.contactAddress = 'DOSSIER_COMPONENTS.VALIDATIONS.ENTITY_ADDRESS_MANDATORY'
  if (settings.clientFieldValidation === clientInformation.BUSS) {
    if (!values.countryCode) {
      fields.countryCode = 'DOSSIER_COMPONENTS.VALIDATIONS.ENTITY_COUNTRY_MANDATORY'
    }
    if (!values.stateCode) {
      fields.stateCode = 'DOSSIER_COMPONENTS.VALIDATIONS.ENTITY_STATE_MANDATORY'
    }
    if (!values.cityCode) {
      fields.cityCode = 'DOSSIER_COMPONENTS.VALIDATIONS.ENTITY_CITY_MANDATORY'
    }
    if (!values.townCode) {
      fields.townCode = 'DOSSIER_COMPONENTS.VALIDATIONS.ENTITY_TOWN_MANDATORY'
    }
    if (!values.colonyCode) {
      fields.colonyCode = 'DOSSIER_COMPONENTS.VALIDATIONS.ENTITY_COLONY_MANDATORY'
    }
    if (!values.postalCodeCode) {
      fields.postalCodeCode = 'DOSSIER_COMPONENTS.VALIDATIONS.ENTITY_CP_MANDATORY'
    }
  }
  if (settings.clientFieldValidation !== clientInformation.BUSS) {
    if (!values.contactState) {
      fields.contactState = 'DOSSIER_COMPONENTS.VALIDATIONS.ENTITY_STATE_MANDATORY'
    }
    if (settings.clientFieldValidation !== clientInformation.EUROMOTORS) {
      if (!values.contactCp) {
        fields.contactCp = 'DOSSIER_COMPONENTS.VALIDATIONS.ENTITY_CP_MANDATORY'
      }
    }
    if (!values.contactCity) {
      fields.contactCity = 'DOSSIER_COMPONENTS.VALIDATIONS.ENTITY_CITY_MANDATORY'
    }
  }
  if (!values || values && !values.contactMobilePhone) fields.contactMobilePhone = 'DOSSIER_COMPONENTS.VALIDATIONS.ENTITY_MOBILEPHONE_MANDATORY'
  if (!values || values && !values.contactEmail) fields.contactEmail = 'DOSSIER_COMPONENTS.VALIDATIONS.ENTITY_EMAIL_MANDATORY'
  return fields
}
