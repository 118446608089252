import apiFetch from '../apiFetch'

export default function (token, finderParams) {
  var body = {}
  body.organizedUnits = finderParams.organizedUnits.split(',').map(Number)
  body.brands = finderParams.brands
  body.dateFrom = finderParams.dateFrom ?? null
  body.dateTo = finderParams.dateTo ?? null
  return apiFetch({
    endPoint: 'report/FindCommercialReport',
    method: 'POST',
    body: body,
    token: token
  })
}
