import { put, call, takeEvery, select } from 'redux-saga/effects'
import { initialize, reset } from 'redux-form'
import { getAuth } from '../../../selectors/access/auth'
import { yesNoModal } from '../../modalsListeners/yesNoModal'
import paperworksTableActionsTypes from '../../../constants/actions/dossier/common/paperworksTable'
import { signStatus } from '../../../constants/backendIds'
import { fetchPaperworkEditionModalSuccess, closePaperworkEditionModal } from '../../../actions/dossier/common/paperworksTable'
import messageModalActions from '../../../actions/modals/messageModal'
import { showLoader, hideLoader } from '../../../actions/common'
import { fetchCombosSuccess } from '../../../actions/combos/combos'
import getProcessingAgency from '../../../services/processingAgency/getProcessingAgency'
import getPaperworkTypes from '../../../services/paperworkType/getPaperworkTypes'
import getPaperworkTypeSubtypeId from '../../../services/paperworkType/getPaperworkTypeSubtypeId'
import getPaperworkById from '../../../services/dossier/get/getPaperworkById'
import deletePaperworkService from '../../../services/dossier/delete/deletePaperwork'
import putPaperworkService from '../../../services/dossier/put/putPaperworkModal'
import postPaperworkService from '../../../services/dossier/post/postPaperwork'
import getPaperworkFileService from '../../../services/dossierPaperwork/get/getPaperworkFile'
import { handleError } from '../../errors/errorManager'
import { getDossierStateById, getDossierStateByType, initializeDossier } from '../commonDossierSagas'
import generateFileToSignService from '../../../services/dossierPaperwork/get/generateFileToSign'
import getOrderStatusService from '../../../services/dossierPaperwork/get/getOrderStatus'
import { refreshSalesDossierFromDocumentModal } from '../../../sagas/dossier/fetchSalesDossier'
import { dossierTypes as dossierTypesConstant } from '../../../constants/dossier/common/dossierTypes'

export function * fetchPaperworkEditionModal ({ organizedUnitId, dossierParentId, paperworkId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const processingAgency = yield call(getProcessingAgency, organizedUnitId, paperworkId, auth.token)
    yield put(fetchCombosSuccess({ processingAgency }))
    if (paperworkId) {
      const paperwork = yield call(getPaperworkById, paperworkId, auth.token)
      // paperwork.sameEntity = !paperwork.sameEntity
      yield put(initialize('paperwork_edition_modal', paperwork))
      const paperworkTypes = yield call(getPaperworkTypes, auth.token, paperworkId, false)
      yield put(fetchCombosSuccess({ paperworkTypes }))
    } else {
      const dossierSubTypeId = 7
      const paperworkTypes = yield call(getPaperworkTypeSubtypeId, auth.token, dossierSubTypeId, false)
      yield put(fetchCombosSuccess({ paperworkTypes }))
    }
    yield put(fetchPaperworkEditionModalSuccess(!!paperworkId))
  } catch (error) {
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('purchase_dossier'))
      yield put(reset('paperwork_dossier'))
      yield put(reset('campaign_dossier'))
    }
    yield call(handleError, { error, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchPaperworkEditionModal () {
  yield takeEvery(paperworksTableActionsTypes.FETCH_PAPERWORK_EDITION_MODAL, fetchPaperworkEditionModal)
}

export function * deletePaperwork ({ paperworkId, dossierParentId, updateDossierAction, showAlertSendPending }) {
  const dossierState = yield call(getDossierStateById, dossierParentId)
  try {
    const auth = yield select(getAuth)
    const confirmed = yield call(yesNoModal, 'deletePaperworkAlert')
    if (confirmed) {
      yield put(showLoader())
      yield call(deletePaperworkService, paperworkId, auth.token, dossierState.dossier.lastAccessDate)
      yield put(updateDossierAction(dossierParentId))
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          yield call(initializeDossier, dossierState.type, dossierState.dossier)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('purchase_dossier'))
      yield put(reset('paperwork_dossier'))
      yield put(reset('campaign_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDeletePaperwork () {
  yield takeEvery(paperworksTableActionsTypes.DELETE_PAPERWORK, deletePaperwork)
}

export function * updatePaperwork ({ paperwork, paperworkId, dossierParentId, updateDossierAction, showAlertSendPending }) {
  const dossierState = yield call(getDossierStateById, dossierParentId)
  try {
    const auth = yield select(getAuth)
    if (showAlertSendPending) {
      const confirmed = yield call(yesNoModal, 'editDossierAlert')
      if (confirmed) {
        yield put(showLoader())
        yield call(putPaperworkService, paperworkId, paperwork, auth.token, dossierState.dossier.lastAccessDate)
        yield put(closePaperworkEditionModal())
        yield put(updateDossierAction(dossierParentId))
      }
    } else {
      yield put(showLoader())
      yield call(putPaperworkService, paperworkId, paperwork, auth.token, dossierState.dossier.lastAccessDate)
      yield put(closePaperworkEditionModal())
      yield put(updateDossierAction(dossierParentId))
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          yield call(initializeDossier, dossierState.type, dossierState.dossier)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('purchase_dossier'))
      yield put(reset('paperwork_dossier'))
      yield put(reset('campaign_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchUpdatePaperwork () {
  yield takeEvery(paperworksTableActionsTypes.UPDATE_PAPERWORK, updatePaperwork)
}

export function * createPaperwork ({ paperwork, parentDossierId, updateDossierAction, showAlertSendPending }) {
  const dossierState = yield call(getDossierStateById, parentDossierId)
  try {
    const auth = yield select(getAuth)
    if (showAlertSendPending) {
      const confirmed = yield call(yesNoModal, 'editDossierAlert')
      if (confirmed) {
        yield put(showLoader())
        yield call(postPaperworkService, parentDossierId, paperwork, auth.token, dossierState.dossier.lastAccessDate)
        yield put(messageModalActions.openSuccessMessageModal('MESSAGES.POST_TABLE_ITEM_SUCCESS'))
        yield put(closePaperworkEditionModal())
        yield put(updateDossierAction(parentDossierId))
      }
    } else {
      yield put(showLoader())
      yield call(postPaperworkService, parentDossierId, paperwork, auth.token, dossierState.dossier.lastAccessDate)
      yield put(messageModalActions.openSuccessMessageModal('MESSAGES.POST_TABLE_ITEM_SUCCESS'))
      yield put(closePaperworkEditionModal())
      yield put(updateDossierAction(parentDossierId))
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          yield call(initializeDossier, dossierState.type, dossierState.dossier)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('MESSAGES.POST_TABLE_ITEM_ERROR', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('purchase_dossier'))
      yield put(reset('paperwork_dossier'))
      yield put(reset('campaign_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchCreatePaperwork () {
  yield takeEvery(paperworksTableActionsTypes.CREATE_PAPERWORK, createPaperwork)
}

export function * printPaperwork ({ paperworkId, dossierType }) {
  const dossierState = yield call(getDossierStateByType, dossierType)
  try {
    const auth = yield select(getAuth)
    yield call(getPaperworkFileService, paperworkId, auth.token, dossierState.lastAccessDate)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          yield call(initializeDossier, dossierType, dossierState)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('purchase_dossier'))
      yield put(reset('paperwork_dossier'))
      yield put(reset('campaign_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  }
}

export function * watchPrintPaperwork () {
  yield takeEvery(paperworksTableActionsTypes.PRINT_PAPERWORK, printPaperwork)
}

export function * generateFileToSign ({ paperworkId, signTypeId, dossierType, updateDossierAction, dossierParentId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(generateFileToSignService, paperworkId, signTypeId, auth.token)
    if (dossierType === dossierTypesConstant.sales) {
      yield call(refreshSalesDossierFromDocumentModal)
    }
    yield put(messageModalActions.openSuccessMessageModal('MESSAGES.GENERATE_FILE_TO_SIGN_SUCCESS'))
    yield put(updateDossierAction(dossierParentId))
  } catch (error) {
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('purchase_dossier'))
      yield put(reset('paperwork_dossier'))
      yield put(reset('campaign_dossier'))
    }
    yield call(handleError, { error, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchGenerateFileToSign () {
  yield takeEvery(paperworksTableActionsTypes.GENERATE_FILE_TO_SIGN, generateFileToSign)
}

export function * checkOrderStatus ({ paperworkId, resolve, reject }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    let info = yield call(getOrderStatusService, paperworkId, auth.token)
    let returnInfo = {
      continue: true,
      info: null
    }
    if (info) {
      yield put(hideLoader())
      if (info.signStatusId === null || info.signStatusId === undefined || info.signStatusId === signStatus.Available || info.signStatusId === signStatus.Cancelled) {
        const confirmed = yield call(yesNoModal, 'ppkOrderAlreadyExists')
        if (confirmed) {
          returnInfo.continue = true
          returnInfo.info = info
        } else {
          returnInfo.continue = false
          returnInfo.info = info
        }
      } else {
        returnInfo.continue = false
        returnInfo.info = null
      }
    } else {
      returnInfo.continue = true
      returnInfo.info = null
    }
    if (resolve) resolve(returnInfo)
  } catch (error) {
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('purchase_dossier'))
      yield put(reset('paperwork_dossier'))
      yield put(reset('campaign_dossier'))
    }
    yield call(handleError, { error, sessionHandler })
    if (reject) yield call(reject)
  } finally {
    yield put(hideLoader())
  }
}

export function * watchCheckOrderStatus () {
  yield takeEvery(paperworksTableActionsTypes.CHECK_ORDER_STATUS, checkOrderStatus)
}
