import React, { PureComponent } from 'react'
import SimpleInputMoney from '../simpleForm/SimpleInputMoney'
import { formatMoney } from '../../../util/utils'

class EditableMoneyCeil extends PureComponent {
  render () {
    const { onInputChange, input, inputValue, stateValue, editing, nonEditable = false, id, hasError = false, total, disabled, maxLength = null } = this.props
    return (
      <td>
        { editing && !nonEditable
          ? <SimpleInputMoney
            id={id}
            colSm={12}
            value={inputValue}
            onInputChange={onInputChange}
            error={hasError}
            input={input}
            disabled={disabled}
            maxLength={maxLength}
          />
          : nonEditable === true && stateValue === undefined && editing !== false ? (
            <span></span>) : (
            <span style={{ float: 'right', fontWeight: total === true ? 'bold' : 'normal' }}>{formatMoney(stateValue)}</span>
          )
        }
      </td>
    )
  }
}
export default EditableMoneyCeil
