import mastersActionTypes from '../../../constants/actions/masters/masters'

function initialState () {
  return {
    campaignTypeList: [],
    campaignTypeCount: 0,
    pagesCount: 0,
    languageList: [],
    filter: {
      page: 1,
      orderBy: '',
      pageSize: 10
    },
    campaignTypeModal: {
      campaignId: null,
      showModal: false
    }
  }
}

function fetchcampaignTypeListSuccess (state, { campaignTypeList, filter }) {
  return {
    ...state,
    campaignTypeList: campaignTypeList.campaignTypeList,
    campaignTypeCount: campaignTypeList.campaignTypeCount,
    pagesCount: campaignTypeList.pagesCount,
    languageSelected: campaignTypeList.languageSelected,
    filter
  }
}

function openEditcampaignTypeModalSuccess (state, { languageList, campaignId }) {
  const initial = initialState()
  return {
    ...state,
    campaignTypeModal: {
      ...initial.campaignTypeModal,
      languageList,
      campaignId,
      nonEditableType: false,
      nonEditable: true,
      showModal: true
    }
  }
}

function openAddModalSuccess (state, { languageList, campaignId }) {
  const initial = initialState()
  return {
    ...state,
    campaignTypeModal: {
      ...initial.campaignTypeModal,
      languageList,
      campaignId: null,
      nonEditable: false,
      showModal: true
    }
  }
}

function closeEditModalSuccess (state) {
  return {
    ...state,
    campaignTypeModal: {
      ...initialState().campaignTypeModal
    }
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case mastersActionTypes.FETCH_CAMPAIGN_TYPE_LIST_SUCCESS:
      return fetchcampaignTypeListSuccess(state, action)
    case mastersActionTypes.OPEN_EDIT_CAMPAIGN_TYPE_MODAL_SUCCESS:
      return openEditcampaignTypeModalSuccess(state, action)
    case mastersActionTypes.OPEN_ADD_CAMPAIGN_TYPE_MODAL_SUCCESS:
      return openAddModalSuccess(state, action)
    case mastersActionTypes.CLOSE_EDIT_CAMPAIGN_TYPE_MODAL:
      return closeEditModalSuccess(state, action)
    default:
      return state
  }
}
