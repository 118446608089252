import { put, call, select, takeEvery } from 'redux-saga/effects'
import { showLoader, hideLoader } from '../../../actions/common.js'
import mastersActionTypes from '../../../constants/actions/masters/masters'
import { getAuth } from '../../../selectors/access/auth'
import { handleError } from '../../errors/errorManager'
import { fetchCombosSuccess } from '../../../actions/combos/combos'
import getProcessingAgencyCommunicationServices from '../../../services/processingAgencyCommunicationServices/getProcessingAgencyCommunicationServices'

export function * fetchProcessingAgencyCommunicationServices () {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const processingAgencyCommunicationServices = yield call(getProcessingAgencyCommunicationServices, auth.token)
    yield put(fetchCombosSuccess({ processingAgencyCommunicationServices: processingAgencyCommunicationServices }))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchProcessingAgencyCommunicationServices () {
  yield takeEvery(mastersActionTypes.FETCH_PROCESSING_AGENCY_COMMUNICATION_SERVICES, fetchProcessingAgencyCommunicationServices)
}
