import React, { PureComponent } from 'react'
import { Row, Col } from 'react-bootstrap'
import { Field, reduxForm } from 'redux-form'
import TextInput from '../../../commons/TextInput'
import InputText from '../../../commons/form/InputText'
import InputCheckBox from '../../../commons/form/InputCheckBox'

class TabPane2 extends PureComponent {
  render () {
    return (
      <Row className="discount-tabpane">
        <Row>
          <Col sm={4} className="select-input-full-width">
            <Field
              id='company'
              name='company'
              placeholder='Empresa'
              label='Empresa'
              component={TextInput}
            />
          </Col>
          <Col sm={4} className="select-input-full-width">
            <Field
              id='place'
              name='place'
              placeholder='Ubicación'
              label='Ubicación'
              component={TextInput}
            />
          </Col>
          <Col sm={4} className="select-input-full-width">
            <Field
              id='box'
              name='box'
              placeholder='BOX'
              label='Box'
              component={TextInput}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={4} className="select-input-full-width">
            <Field
              id='dateLogistics'
              name='dateLogistics'
              placeholder='Fecha servicio logístico'
              label='Fecha servicio logístico'
              component={TextInput}
            />
          </Col>
          <Col sm={4} className="select-input-full-width">
            <p className="checkbox-label">Daños</p>
            <Field
              name='damages'
              id='damages'
              controlLabel='Daños'
              component={InputCheckBox}
              placeholder='Daños'
              inline={true}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={8} className="select-input-full-width observations">
            <Field
              id='comments'
              name='comments'
              placeholder='Observaciones'
              controlLabel='Observaciones'
              componentClass='textarea'
              component={InputText}
            />
          </Col>
        </Row>
      </Row>
    )
  }
}

export default reduxForm({ form: 'newForm' })(TabPane2)
