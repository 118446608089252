import { call, put, select, takeLatest } from 'redux-saga/effects'
import { getAuth } from '../../selectors/access/auth'
import { showLoader, hideLoader } from '../../actions/common.js'
import messageModalActions from '../../actions/modals/messageModal'
import { fetchTuneUpDestinationSuccess } from '../../actions/combos/combos'
import combosActionTypes from '../../constants/actions/combos/combos'
import { handleError } from '../errors/errorManager'
import getTuneUpDestination from '../../services/tuneUpDestination/getTuneUpDestination'

export function * fetchTuneUpDestination () {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const response = yield call(getTuneUpDestination, auth.token)
    yield put(fetchTuneUpDestinationSuccess(response))
  } catch (error) {
    const customHandler = function * () {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.FETCH_COMBO_ERROR'))
    }
    yield call(handleError, { error, customHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchTuneUpDestination () {
  yield takeLatest(combosActionTypes.FETCH_TUNE_UP_DESTINATION, fetchTuneUpDestination)
}
