import apiFetch from '../apiFetch'

export default function (purchasePresentTypeId, purchasePresentTypeValues, uoId, token) {
  const body = {
    organizedUnitId: uoId,
    purchasePresentTypeId: purchasePresentTypeId,
    isDeleted: false,
    purchasePresentTypeLanguage: purchasePresentTypeValues
  }
  return apiFetch({ endPoint: `/PurchasePresentType/Put/${purchasePresentTypeId}`, method: 'PUT', body: body, token: token })
}
