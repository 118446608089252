import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { closeEditRoleModal, submitRole } from '../../../actions/admin/roleAdmin'
import { fetchLanguagesCombo } from '../../../actions/combos/combos'
import RoleAdminModal from '../../../components/admin/role/RoleAdminModal'
import { translate } from 'react-polyglot'

export function mapStateToProps (state) {
  return {
    ...state.roleAdmin.roleAdminModal,
    languagesCombo: state.combos.languagesCombo
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      closeEditRoleModal,
      fetchLanguagesCombo,
      submitRole
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(RoleAdminModal))
