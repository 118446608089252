import mastersActionTypes from '../../../constants/actions/masters/masters'

function initialState () {
  return {
    quotaList: [],
    quotasCount: 0,
    pagesCount: 0,
    filter: {
      page: 1,
      orderBy: '',
      pageSize: 10,
      searchUO: []
    },
    processingAgencyAssignationModal: {
      quota: null,
      showModal: false
    }
  }
}

function fetchProcessingAgencyQuotaListSuccess (state, { quotaList, filter }) {
  return {
    ...state,
    quotaList: quotaList,
    quotasCount: quotaList.quotasCount,
    pagesCount: quotaList.pagesCount,
    filter
  }
}

function openEditProcessingAgencyAssignationModalSuccess (state, { quota, id }) {
  const initial = initialState()
  return {
    ...state,
    processingAgencyAssignationModal: {
      ...initial.processingAgencyAssignationModal,
      quota: {
        ...quota,
        idx: id
      },
      showModal: true
    }
  }
}

function updateProcessingAgencyAssignation (state, { initialquota }) {
  const initial = initialState()
  return {
    ...state,
    processingAgencyAssignationModal: {
      ...initial.processingAgencyAssignationModal,
      quota: initialquota,
      showModal: true
    }
  }
}

function openAddProcessingAgencyAssignationModalSuccess (state, { OUSelected }) {
  const initial = initialState()
  return {
    ...state,
    processingAgencyAssignationModal: {
      ...initial.processingAgencyAssignationModal,
      showModal: true,
      quota: {
        organizedUnitId: OUSelected
      }
    }
  }
}

function closeEditModalSuccess (state) {
  return {
    ...state,
    processingAgencyAssignationModal: {
      ...initialState().processingAgencyAssignationModal
    }
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case mastersActionTypes.FETCH_PROCESSING_AGENCY_QUOTA_SUCCESS:
      return fetchProcessingAgencyQuotaListSuccess(state, action)
    case mastersActionTypes.OPEN_EDIT_PROCESSING_AGENCY_QUOTA_MODAL_SUCCESS:
      return openEditProcessingAgencyAssignationModalSuccess(state, action)
    case mastersActionTypes.OPEN_ADD_PROCESSING_AGENCY_QUOTA_MODAL_SUCCESS:
      return openAddProcessingAgencyAssignationModalSuccess(state, action)
    case mastersActionTypes.CLOSE_EDIT_PROCESSING_AGENCY_QUOTA_MODAL:
      return closeEditModalSuccess(state, action)
    case mastersActionTypes.UPDATE_PROCESSING_AGENCY_QUOTA:
      return updateProcessingAgencyAssignation(state, action)
    default:
      return state
  }
}
