import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PurchasePresentTypeMasters from '../../../components/masters/purchasePresentType/PurchasePresentTypeMasters'
import { translate } from 'react-polyglot'
import { openPurchasePresentTypeModal, deletePurchasePresentTypeModal, fetchPurchasePresentTypeMasters, recoverPurchasePresentTypeModal, fetchPurchasePresentTypeList } from '../../../actions/masters/masters'
import { fetchOrganizedUnitsTree } from '../../../actions/combos/combos'

export function mapStateToProps (state) {
  return {
    ...state.purchasePresentType,
    combos: {
      UOTreeCombo: state.combos.UOTreeCombo
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      openPurchasePresentTypeModal,
      deletePurchasePresentTypeModal,
      fetchPurchasePresentTypeMasters,
      recoverPurchasePresentTypeModal,
      fetchOrganizedUnitsTree,
      fetchPurchasePresentTypeList
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(PurchasePresentTypeMasters))
