import React, { PureComponent } from 'react'
import { Row } from 'react-bootstrap'
class Tab1 extends PureComponent {
  render () {
    return (
      <Row className="tabPane-container">
        <ul className="list-document-uploaded">
          <li id="img_open_doc" className="document-uploaded">
            <i className="ico-cancel" />
            <i id="documentStatus" className='document-pending ico-document-pending' />
          </li>
          <li id="img_open_doc" className="document-uploaded">
            <i className="ico-cancel" />
            <i id="documentStatus" className='document-pending ico-document-pending' />
          </li>
          <li id="img_open_doc" className="document-uploaded">
            <i className="ico-cancel" />
            <i id="documentStatus" className='document-pending ico-document-pending' />
          </li>
          <li id="img_open_doc" className="document-uploaded">
            <i className="ico-cancel" />
            <i id="documentStatus" className='document-pending ico-document-pending' />
          </li>
          <li id="img_open_doc" className="document-uploaded">
            <i className="ico-cancel" />
            <i id="documentStatus" className='document-pending ico-document-pending' />
          </li>
          <li id="img_open_doc" className="document-uploaded">
            <i className="ico-cancel" />
            <i id="documentStatus" className='document-pending ico-document-pending' />
          </li>
        </ul>
      </Row>
    )
  }
}

export default Tab1
