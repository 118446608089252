
import { getAuth } from '../../../selectors/access/auth'
import { handleError } from '../../errors/errorManager'
import { put, call, select, takeEvery } from 'redux-saga/effects'
import { showLoader, hideLoader } from '../../../actions/common.js'
import mastersActionTypes from '../../../constants/actions/masters/masters'
import { closeDirectionUOModal, fetchDirectionUOMasterSuccess, openDirectionUOModalSuccess } from '../../../actions/masters/masters'
import getAllDirectionsUO from '../../../services/directionUO/getAllDirectionsUO'
import getDirectionUOById from '../../../services/directionUO/getDirectionUOById'
import putDirectionUO from '../../../services/directionUO/putDirectionUO'
import postDirectionUO from '../../../services/directionUO/postDirectionUO'
import deleteDirectionUO from '../../../services/directionUO/deleteDirectionUO'
import { yesNoModal } from '../../modalsListeners/yesNoModal'
import { getProcessingDirectionUOFilter } from '../../../selectors/masters/directionUO'
import { initialize } from 'redux-form'

export function * fetchDirectionsUOMasterList ({ filter }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield filter = yield filter || select(getProcessingDirectionUOFilter)
    const directionsList = yield call(getAllDirectionsUO, auth.token, filter)
    yield put(fetchDirectionUOMasterSuccess(directionsList, filter))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}
export function * watchFetchDirectionsUOMasterList () {
  yield takeEvery(mastersActionTypes.FETCH_DIRECTION_UO_MASTER, fetchDirectionsUOMasterList)
}

export function * openDirectionUOModal ({ directionUOId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    let directionUOFields = []
    if (directionUOId) {
      directionUOFields = yield call(getDirectionUOById, auth.token, directionUOId)
    }
    yield put(openDirectionUOModalSuccess(directionUOFields, directionUOId))
    if (directionUOId) {
      directionUOFields = yield call(getDirectionUOById, auth.token, directionUOId)
      yield put(initialize('editDirectionUO', directionUOFields))
    } else {
      yield put(initialize('editDirectionUO'))
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchOpenDirectionUOModal () {
  yield takeEvery(mastersActionTypes.OPEN_DIRECTION_UO_MODAL, openDirectionUOModal)
}

export function * saveDirectionUOModal ({ directionUOValues, directionUOId }) {
  try {
    const isUpdate = (directionUOId != null)
    const auth = yield select(getAuth)
    let updateList = true
    if (isUpdate) {
      const confirmed = yield call(yesNoModal, 'directionUOAlert')
      if (confirmed) {
        yield put(showLoader())
        yield call(putDirectionUO, auth.token, directionUOValues)
      } else {
        updateList = false
      }
    } else {
      yield put(showLoader())
      yield call(postDirectionUO, auth.token, directionUOValues)
    }
    if (updateList) {
      const filter = yield filter || select(getProcessingDirectionUOFilter)
      const directionsList = yield call(getAllDirectionsUO, auth.token, filter)
      yield put(fetchDirectionUOMasterSuccess(directionsList, filter))
    }
    yield put(closeDirectionUOModal())
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSaveDirectionUOModal () {
  yield takeEvery(mastersActionTypes.SAVE_DIRECTION_UO, saveDirectionUOModal)
}

export function * deleteDirectionUOModal ({ directionUOId }) {
  const toDelete = yield call(yesNoModal, 'directionUODeleteAlert')
  if (toDelete) {
    try {
      yield put(showLoader())
      const auth = yield select(getAuth)
      yield call(deleteDirectionUO, auth.token, directionUOId)
      const filter = yield filter || select(getProcessingDirectionUOFilter)
      const directionsList = yield call(getAllDirectionsUO, auth.token, filter)
      yield put(fetchDirectionUOMasterSuccess(directionsList, filter))
    } catch (error) {
      yield call(handleError, { error })
    } finally {
      yield put(hideLoader())
    }
  }
}

export function * watchDeleteDirectionUOModal () {
  yield takeEvery(mastersActionTypes.DELETE_DIRECTION_UO, deleteDirectionUOModal)
}
