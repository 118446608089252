export const saleTypeCodeEnum = {
  PARTICULAR: 'private',
  EMPRESA: 'business',
  AUTONOMO: 'freelance',
  DESGUACE: 'scrapping',
  COMPRAVENTA_CONCESIONARIO: 'concessionaire',
  QUADIS: 'quadis',
  FLOTAS: 'fleets',
  EXPORTACIONES: 'exports',
  SELFSUPPLY_VN: 'selfSupplyVN',
  SELFSUPPLY_VO: 'selfSupplyVO',
  AGENCY_MODEL_FLEET_WAGEN: 'AGENCY_MODEL_FLEET_WAGEN',
  AGENCY_MODEL_FLEET_SEAT: 'AGENCY_MODEL_FLEET_SEAT',
  AGENCY_MODEL_PART_WAGEN: 'AGENCY_MODEL_PART_WAGEN',
  AGENCY_MODEL_PART_SEAT: 'AGENCY_MODEL_PART_SEAT'

}
