import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getFormValues } from 'redux-form'
import DocumentalSearchForm from '../../components/search/DocumentalSearchForm'
import { fetchDocumentalSearchFormCombos, findFilterDocuments, resetFilters, setFilters, initializeSearch, fetchDocumentSearchList, createMassiveDownload, massiveDownloadDocumentList } from '../../../actions/search/documentalSearch/documentalSearchForm'
import { fetchDocumentStatusCombo, fetchDossierSubTypes, fetchStagesCombo, fetchLowestOrganizedUnits, fetchSearchDossierSubType, fetchEntitySubTypeCombo, fetchEntityFilterSubTypeCombo } from '../../../actions/combos/combos'
import { setHideFilters } from '../../../actions/search/search'
import { translate } from 'react-polyglot'
import { openDocumentEditionModal as openDocumentEditionModalHistorical } from '../../../actions/dossier/common/documentHistoricalEditionModal'
import { openDocumentEditionModal } from '../../../actions/dossier/common/documentEditionModal'
import { openModal } from '../../../actions/common'

export function mapStateToProps (state, props) {
  return {
    ...state.documentalSearch,
    combos: {
      documentEntityTypeCombo: state.combos.documentEntityTypeCombo,
      entitySubTypeCombo: state.combos.entitySubTypeCombo,
      documentStatusCombo: state.combos.documentStatusCombo,
      documentsTypesCombo: state.combos.documentsTypesCombo,
      entityTypesCombo: state.combos.entityTypesCombo,
      dossierSubtypesCombo: state.combos.dossierSubTypesCombo,
      entityFilterSubTypeCombo: state.combos.entityFilterSubTypeCombo,
      stagesCombo: state.combos.stagesCombo,
      lowestOrganizedUnitsCombo: state.combos.lowestOrganizedUnitsCombo,
      salesmanCombo: state.combos.salesmanCombo,
      brandCombo: state.combos.brandCombo,
      searchDossierSubtypesCombo: state.combos.searchDossierSubtypesCombo
    },
    formValues: getFormValues('documentalSearchForm')(state)
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchDocumentalSearchFormCombos,
      findFilterDocuments,
      fetchDossierSubTypes,
      fetchStagesCombo,
      fetchEntityFilterSubTypeCombo,
      fetchLowestOrganizedUnits,
      resetFilters,
      openModal,
      fetchSearchDossierSubType,
      setFilters,
      initializeSearch,
      fetchEntitySubTypeCombo,
      fetchDocumentStatusCombo,
      fetchDocumentSearchList,
      openDocumentEditionModal,
      openDocumentEditionModalHistorical,
      createMassiveDownload,
      massiveDownloadDocumentList,
      setHideFilters
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(DocumentalSearchForm))
