import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { closeCampaignGroupMasterModal, submitCampaignGroup } from '../../../actions/masters/masters'
import { fetchCampaignClass } from '../../../actions/combos/combos'
import CampaingGroupMasterModal from '../../../components/masters/campaignGroup/CampaingGroupMasterModal'

export function mapStateToProps (state) {
  const campaignGroupModal = state.campaignGroup.campaignGroupModal
  return {
    showModal: campaignGroupModal.showModal,
    campaignId: campaignGroupModal.campaignId,
    languageList: campaignGroupModal.languageList,
    nonEditableGroup: campaignGroupModal.nonEditableGroup,
    nonEditable: campaignGroupModal.nonEditable,
    combos: {
      campaignClass: state.combos.campaignClass,
      roleCombo: state.combos.roleCombo
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      submitCampaignGroup,
      fetchCampaignClass,
      closeCampaignGroupMasterModal
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(CampaingGroupMasterModal))
