import { put, call, select, takeEvery } from 'redux-saga/effects'
import { getAuth } from '../../selectors/access/auth'
import { getCommon } from '../../selectors/common/common'
import getLanguageListervice from '../../services/language/getLanguageList'
import { fetchLanguagesAvailableSuccess, showLoader, hideLoader } from '../../actions/common.js'
import commonActionTypes from '../../constants/actions/common'
import { handleError } from '../errors/errorManager'

export function * fetchLanguagesAvailable () {
  try {
    yield put(showLoader())
    const common = yield select(getCommon)
    if (!common.languageAvailable) {
      const auth = yield select(getAuth)
      const languageAvailable = yield call(getLanguageListervice, auth.token)
      yield put(fetchLanguagesAvailableSuccess(languageAvailable))
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchLanguagesAvailable () {
  yield takeEvery(commonActionTypes.FETCH_LANGUAGES_AVAILABLE, fetchLanguagesAvailable)
}
