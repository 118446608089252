import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { fetchDocumentTypeMetadataList, deleteDocumentTypeMetadata, openDocumentTypeMetadataModal, openMetadataListModal } from '../../../actions/masters/metadata/metadata'
import MetadataMaster from '../../../components/masters/metadata/MetadataMaster'

export function mapStateToProps (state) {
  return {
    ...state.documentTypeMetadata,
    combos: {
      documentTypes: state.combos.documentTypes
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      deleteDocumentTypeMetadata,
      openDocumentTypeMetadataModal,
      openMetadataListModal,
      fetchDocumentTypeMetadataList
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(MetadataMaster))
