import React, { PureComponent } from 'react'
import { Popover, Overlay, Button, Glyphicon } from 'react-bootstrap'
import DossierListPopoverEditionModal from './DossierListPopoverEditionModal'

class EntityPopoverEditionModal extends PureComponent {
  constructor () {
    super()
    this.state = {
      showPopover: false,
      target: this
    }
  }

  handleClick (e) {
    e.stopPropagation()
    this.setState({ target: e.target, showPopover: true })
  }

  render () {
    const {
      t,
      idx,
      half,
      id,
      lives,
      stored,
      dossier,
      entity = {}
    } = this.props
    const tKey = 'SEARCH.CATEGORIES.'
    return (
      <div className="subcabecera">
        <i className="subcabecera-ico ico-user"/>
        <span className="info-entidad-documento">
          {entity.entityName}
        </span>
        {Object.keys(entity).length > 0 &&
          <Button bsSize="xsmall" className="icon-only icon-plus-subcabecera" onClick={this.handleClick.bind(this)}><Glyphicon glyph="plus"/></Button>
        }
        <span className="espacio-subcabecera"></span>
        {
          dossier === true ? (
            <Overlay
              show={this.state.showPopover}
              target={this.state.target}
              onHide={() => this.setState({ showPopover: false })}
              rootClose
              placement={(idx >= half) ? 'top' : 'bottom'}
              key="overlay-entity"
            >
              <Popover id={id} className="entity-popover-container-doc" key="popover">
                {entity.entityName &&
                <div className="popover-item" key="nombre">
                  <label>{t(`${tKey}ENTITY.NAME`)}: </label><br/>
                  {entity.entityName}
                </div>
                }
                {entity.clientNumber &&
                <div className="popover-item" key="numero">
                  <label>{t(`${tKey}ENTITY.MAIN_ENTITY_NUMBER`)}:</label><br/>
                  {entity.clientNumber}
                </div>
                }
                {entity.entityCifNif &&
                <div className="popover-item" key="dni">
                  <label>{t(`${tKey}ENTITY.DNI_CIF`)}:</label><br/>
                  {entity.entityCifNif}
                </div>
                }
                {entity.entityAddress &&
                <div className="popover-item" key="direccion">
                  <label>{t(`${tKey}ENTITY.ADDRESS`)}:</label><br/>
                  {entity.entityAddress}
                </div>
                }
                {entity.entityPhone &&
                <div className="popover-item" key="telefono">
                  <label>{t(`${tKey}ENTITY.PHONE`)}: </label><br/>
                  {entity.entityPhone}
                </div>
                }
                {entity.entityMail &&
                <div className="popover-item" key="email">
                  <label>{t(`${tKey}ENTITY.EMAIL`)}: </label><br/>
                  {entity.entityMail}
                </div>
                }
                {entity.entityType &&
                <div className="popover-item" key="subtype">
                  <label>{t(`${tKey}ENTITY.ENTITY_SUB_TYPE_ID`)}: </label><br/>
                  {entity.entityType}
                </div>
                }
              </Popover>
            </Overlay>
          ) : ([
            <Overlay
              show={this.state.showPopover}
              target={this.state.target}
              onHide={() => this.setState({ showPopover: false })}
              rootClose
              placement={(idx >= half) ? 'top' : 'bottom'}
              key="overlay-entity"
            >
              <Popover id={id} className="entity-popover-container-doc" key="popover">
                {entity.clientNumber &&
                <div className="popover-item" key="numero">
                  <label>{t(`${tKey}ENTITY.MAIN_ENTITY_NUMBER`)}:</label><br/>
                  {entity.clientNumber}
                </div>
                }
                {entity.entityAddress &&
                <div className="popover-item" key="direccion">
                  <label>{t(`${tKey}ENTITY.ADDRESS`)}:</label><br/>
                  {entity.entityAddress}
                </div>
                }
                {entity.entityPhone &&
                <div className="popover-item" key="telefono">
                  <label>{t(`${tKey}ENTITY.PHONE`)}: </label><br/>
                  {entity.entityPhone}
                </div>
                }
                {entity.entityMail &&
                <div className="popover-item" key="email">
                  <label>{t(`${tKey}ENTITY.EMAIL`)}: </label><br/>
                  {entity.entityMail}
                </div>
                }
                {entity.entityType &&
                <div className="popover-item" key="subtype">
                  <label>{t(`${tKey}ENTITY.ENTITY_SUB_TYPE_ID`)}: </label><br/>
                  {entity.entityType}
                </div>
                }
              </Popover>
            </Overlay>,
            <span className="info-entidad-documento" key="dni">{entity.entityCifNif}</span>
          ])
        }
        {dossier !== true && (
          <DossierListPopoverEditionModal
            t={t}
            key="exp-asociados1"
            idx="exp-asociados1"
            id={'popover-dossier-list'}
            alive={lives}
            stored={stored}
          />
        )}
      </div>
    )
  }
}

export default EntityPopoverEditionModal
