import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PaperworksTable from '../../../components/dossierHistorical/common/paperworksTable/PaperworksTable'
import paperworkComponentActions from '../../../actions/dossier/common/paperworksTable'
import { setActiveSection } from '../../../actions/dossier/common/buttonsSidebar'
// import { push } from 'react-router-redux'
import { translate } from 'react-polyglot'

export function mapStateToProps (state) {
  return {
    ...state.paperworkTable,
    combos: {
      paperworkTypes: state.combos.paperworkTypes,
      processingAgency: state.combos.processingAgency
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      ...paperworkComponentActions,
      setActiveSection
      // navigateTo: push
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(PaperworksTable))
