import faqMasterActionTypes from '../../../constants/actions/masters/faq/faqMaster'

function initialState () {
  return {
    faqList: [],
    faqCount: 0,
    pagesCount: 0,
    filter: {
      page: 1,
      orderBy: '',
      pageSize: 10
    },
    faqModal: {
      showModal: false,
      faqFields: [],
      languages: [],
      faqSectionCombo: []
    },
    faqSectionCombo: []
  }
}

function fetchFaqListSuccess (state, { faqMasterList, filter }) {
  return {
    ...state,
    faqList: faqMasterList.faqMasterList,
    faqCount: faqMasterList.faqCount,
    pagesCount: faqMasterList.pagesCount,
    filter
  }
}

function openFaqModalSuccess (state, { languageList, faqMaster, faqSectionCombo, organizedUnitId }) {
  return {
    ...state,
    faqModal: {
      showModal: true,
      faqFields: {
        faqTranslations: faqMaster.translationsFaq,
        faqId: faqMaster.faqId,
        faqSectionId: faqMaster.faqSectionId,
        rating: faqMaster.rating,
        organizedUnitId: organizedUnitId
      },
      languages: languageList,
      faqSectionCombo: faqSectionCombo
    }
  }
}
function closeFaqModal (state) {
  return {
    ...state,
    faqModal: {
      showModal: false,
      faqFields: [],
      languages: [],
      faqSectionCombo: []
    }
  }
}

function loadFaqSectionComboFilter (state, { faqSectionsCombo }) {
  return {
    ...state,
    faqSectionCombo: faqSectionsCombo
  }
}

function loadFaqSectionComboModel (state, { faqSectionsCombo }) {
  return {
    ...state,
    faqModal: {
      ...state.faqModal,
      faqSectionCombo: faqSectionsCombo
    }
  }
}

function openFaqMaster (state) {
  return {
    ...state,
    ...initialState().filter
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case faqMasterActionTypes.FETCH_FAQ_SUCCESS_LIST:
      return fetchFaqListSuccess(state, action)
    case faqMasterActionTypes.OPEN_FAQ_MODAL_SUCCESS:
      return openFaqModalSuccess(state, action)
    case faqMasterActionTypes.CLOSE_FAQ_MODAL:
      return closeFaqModal(state, action)
    case faqMasterActionTypes.LOAD_FAQ_SECTION_COMBO_FILTER:
      return loadFaqSectionComboFilter(state, action)
    case faqMasterActionTypes.LOAD_FAQ_SECTION_COMBO_MODAL:
      return loadFaqSectionComboModel(state, action)
    case faqMasterActionTypes.OPEN_FAQ_MASTER:
      return openFaqMaster(state, action)
    default:
      return state
  }
}
