import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import Scroller from '../../components/commons/Scroller'
import scrollerActions from '../../actions/commons/scroller'

export function mapStateToProps (state) {
  return {
    ...state.scroller
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      ...scrollerActions
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(Scroller))
