import { call, put, select, takeLatest } from 'redux-saga/effects'
import { getAuth } from '../../selectors/access/auth'
import { setNotificationsCounter, fetchDocumentNotificationsSuccess, fetchDossierNotificationsSuccess, markDossierNotificationAsReadSuccess } from '../../actions/notifications/notifications'
import notificationsActionTypes from '../../constants/actions/notifications/notifications'
import messageModalActions from '../../actions/modals/messageModal'
import getNotificationsCounterService from '../../services/notifications/getNotificationsCounter'
import getNotificationDocument from '../../services/notificationDocument/getNotificationDocument'
import getNotificationDossier from '../../services/notificationDossier/getNotificationDossier'
import putNotificationDossierRead from '../../services/notificationDossier/putNotificationDossierRead'
import { showLoader, hideLoader } from '../../actions/common.js'
import { handleError } from '../errors/errorManager'

function * fetchInitialNotificationsCounter () {
  try {
    const auth = yield select(getAuth)
    const notificationCount = yield call(getNotificationsCounterService, auth.token)
    yield put(setNotificationsCounter(notificationCount))
  } catch (error) {
    yield call(handleError, { error })
  }
}

export function * watchFetchInitialNotificationsCounter () {
  yield takeLatest(notificationsActionTypes.FETCH_INITIAL_NOTIFICATIONS_COUNTER, fetchInitialNotificationsCounter)
}

function * fetchDocumentNotifications () {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const documentNotifications = yield call(getNotificationDocument, auth.token)
    if (documentNotifications.length) {
      yield put(fetchDocumentNotificationsSuccess(documentNotifications))
      yield put(setNotificationsCounter({ documents: documentNotifications.length }))
    } else {
      yield put(messageModalActions.openSuccessMessageModal('NOTIFICATIONS.NOTIFICATIONS_DOCUMENT_EMPTY_MESSAGE'))
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchDocumentNotifications () {
  yield takeLatest(notificationsActionTypes.FETCH_DOCUMENT_NOTIFICATIONS, fetchDocumentNotifications)
}

function * fetchDossierNotifications () {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const dossierNotifications = yield call(getNotificationDossier, auth.token)
    if (dossierNotifications.length) {
      yield put(fetchDossierNotificationsSuccess(dossierNotifications))
      yield put(setNotificationsCounter({ dossiers: dossierNotifications.length }))
    } else {
      yield put(messageModalActions.openSuccessMessageModal('NOTIFICATIONS.NOTIFICATIONS_DOSSIER_EMPTY_MESSAGE'))
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchDossierNotifications () {
  yield takeLatest(notificationsActionTypes.FETCH_DOSSIER_NOTIFICATIONS, fetchDossierNotifications)
}

function * markDossierNotificationAsRead ({ notificationId }) {
  try {
    const auth = yield select(getAuth)
    yield call(putNotificationDossierRead, notificationId, auth.token)
    yield put(markDossierNotificationAsReadSuccess())
  } catch (error) {
    yield call(handleError, { error })
  }
}

export function * watchMarkDossierNotificationAsRead () {
  yield takeLatest(notificationsActionTypes.MARK_DOSSIER_NOTIFICATION_AS_READ, markDossierNotificationAsRead)
}
