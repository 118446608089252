import Settings from './settings'

export default {
  ...Settings,
  urlHotFolder: 'https://hotfolder.idoccar.com/api/',
  webSocketUrl: 'wss://qa.idoccar.com:8010',
  notificationRefresh: 60000,
  showSignDocumentButton: false,
  acceptFileFormats: 'application/pdf, .jpg, .png, .jpeg, .gif, .docx, .doc, .xls, .xlsx, .ppt, .pptx, .msg, .tiff',
  qaSite: true,
  dealerMaxLength: 6,
  dealerNumberRegex: null,
  clientFieldValidation: 'demo',
  autoLoadDashboard: true,
  validateLicensePlate: true,
  maxTagCount: 2,
  maxTagTextLength: 10,
  matomoSiteId: '17',
  authenticationWithSalt: true,
  enableFavoritesAndRecents: true,
  enableTaskFollowUp: true,
  dashboardVersion: 2,
  usersManualUrl: 'https://formacion.idoccar.com',
  usersManualEnvironment: 'qa',
  environmentName: 'qa'
}
