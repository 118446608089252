import React, { PureComponent } from 'react'
import { Field } from 'redux-form'
import { Panel, Row } from 'react-bootstrap'
import InputMoney from '../../commons/form/InputMoney'
import InputCheckBox from '../../commons/form/InputCheckBox'
import InputSelect from '../../commons/form/InputSelect'
import InputDatePicker from '../../commons/form/InputDatePicker'
import { validateDate } from '../../../util/validationFunctions'
import { compareObjects } from '../../../util/utils'
import { getFieldsPermisions } from '../../../util/permisionFunctions'
import { permissions } from '../../../constants/backendIds'
import { economicPlanSectionType } from '../../../constants/dossier/common/economicPlanSection'
import CustomFields from '../../commons/customFields/CustomFields'

class PaperworkComponent extends PureComponent {
  constructor (props) {
    super()
    this.state = {
      economicTarget: props.dossierPaperworkComponent.economicTarget || null,
      fieldsConfiguration: {
        paperworkTypeId: null,
        processingAgencyId: null,
        amount: null,
        paperworkDeliveryDate: null,
        paperworkReceiptDate: null,
        economicTarget: null,
        hasSupInvoice: null,
        hasIem: null,
        serviceDestination: null,
        vehicleType: null,
        cost: null,
        toBeReviewed: null,
        operationTypeIds: null,
        operationTotalAmount: null,
        ivaTaxableBase: null,
        processingAgencyManagerId: null
      }
    }
  }

  initializePermisions (props) {
    const fieldKeys = Object.keys(this.state.fieldsConfiguration)
    const newState = getFieldsPermisions(props.componentConfiguration.fieldsConfiguration, fieldKeys, props.componentConfiguration.permission)
    this.setState({ ...this.state, ...newState })
  }

  UNSAFE_componentWillMount () {
    this.initializePermisions(this.props)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.dossierId && !compareObjects(this.props.componentConfiguration || {}, nextProps.componentConfiguration, true)) {
      this.initializePermisions(nextProps)
    }
  }

  dateValidation (value, allValues, props) {
    const result = validateDate(value, props)
    return result === Object(result) ? undefined : result
  }

  render () {
    const {
      t, paperworkOpen, dossierPaperworkComponent, dossierParentId, customFields,
      combos: { paperworkTypes, processingAgency, serviceDestinationCombo, vehicletypeCombo, operationTypeCombo, processingAgencyManagersCombo },
      actions: { togglePanel }
    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.PAPERWORK_DOSSIER.PAPERWORK_COMPONENT.'

    let economicTargetTypes = this.props.combos.economicTargetTypes
    economicTargetTypes = economicTargetTypes.map((economicTargetType) => {
      return { ...economicTargetType, value: t(`${tKey}ECONOMIC_PLAN_SECTION_TYPES.${economicTargetType.value}`) }
    })

    return (
      <div className="paperwork-panel dossier-panel">
        <div className="header-panel" onClick={togglePanel}>
          <div className="name-wrapper">
            <i className="ico-car-files" />
            <h4>{t(`${tKey}TITLE`)}</h4>
          </div>
          <i className="ico-arrow-black-down" />
        </div>
        <Panel expanded={paperworkOpen} onToggle={() => {}}>
          <Panel.Collapse>
            <Row>
              {this.state.fieldsConfiguration.paperworkTypeId.permission !== permissions.hidden && (
                <Field
                  colSm={6}
                  id="paperworkTypeId"
                  name="dossierPaperworkComponent.paperworkTypeId"
                  controlLabel={t(`${tKey}PAPERWORK_TYPE`)}
                  placeholder={t(`${tKey}PAPERWORK_TYPE`)}
                  component={InputSelect}
                  options={paperworkTypes}
                  valueKey="id"
                  labelKey="value"
                  disabled={true}
                />
              )}
              {this.state.fieldsConfiguration.processingAgencyId.permission !== permissions.hidden && (
                <Field
                  colSm={6}
                  id="processingAgencyId"
                  name="dossierPaperworkComponent.processingAgencyId"
                  controlLabel={t(`${tKey}PROCESSING_AGENCY`)}
                  placeholder={t(`${tKey}PROCESSING_AGENCY`)}
                  component={InputSelect}
                  options={processingAgency}
                  valueKey="id"
                  labelKey="value"
                  disabled={true}
                />
              )}
              {this.state.fieldsConfiguration.processingAgencyManagerId.permission !== permissions.hidden && (
                <Field
                  colSm={6}
                  id="processingAgencyManagerId"
                  name="dossierPaperworkComponent.processingAgencyManagerId"
                  controlLabel={t(`${tKey}PROCESSING_AGENCY_MANAGER`)}
                  placeholder={t(`${tKey}PROCESSING_AGENCY_MANAGER`)}
                  component={InputSelect}
                  options={processingAgencyManagersCombo}
                  valueKey="processingAgencyManagerId"
                  labelKey="name"
                  disabled={true}
                />
              )}
              {this.state.fieldsConfiguration.operationTotalAmount.permission !== permissions.hidden && dossierPaperworkComponent.showEconomicPlan === true && (
                <Field
                  id="operationTotalAmount"
                  name="dossierPaperworkComponent.operationTotalAmount"
                  type="text"
                  colSm={6}
                  controlLabel={t(`${tKey}OPERATION_TOTAL_AMOUNT`)}
                  placeholder={t(`${tKey}OPERATION_TOTAL_AMOUNT`)}
                  component={InputMoney}
                  disabled={true}
                />
              )}
              {this.state.fieldsConfiguration.ivaTaxableBase.permission !== permissions.hidden && dossierPaperworkComponent.showEconomicPlan === true && (
                <Field
                  id="ivaTaxableBase"
                  name="dossierPaperworkComponent.ivaTaxableBase"
                  type="text"
                  colSm={6}
                  controlLabel={t(`${tKey}IVA_TAXABLE_BASE`)}
                  placeholder={t(`${tKey}IVA_TAXABLE_BASE`)}
                  component={InputMoney}
                  disabled={true}
                />
              )}
              {this.state.fieldsConfiguration.cost.permission !== permissions.hidden && dossierPaperworkComponent.showEconomicPlan === true && (
                <Field
                  id="cost"
                  name="dossierPaperworkComponent.cost"
                  type="text"
                  colSm={6}
                  controlLabel={t(`${tKey}COST`)}
                  placeholder={t(`${tKey}COST`)}
                  component={InputMoney}
                  disabled={true}
                />
              )}
              {this.state.fieldsConfiguration.amount.permission !== permissions.hidden && (
                <Field
                  id="amount"
                  name="dossierPaperworkComponent.amount"
                  type="text"
                  colSm={6}
                  controlLabel={t(`${tKey}AMOUNT`)}
                  placeholder={t(`${tKey}AMOUNT`)}
                  component={InputMoney}
                  disabled={true}
                />
              )}
              {this.state.fieldsConfiguration.paperworkDeliveryDate.permission !== permissions.hidden && (
                <Field
                  id="paperworkDeliveryDate"
                  name="dossierPaperworkComponent.paperworkDeliveryDate"
                  colSm={6}
                  controlLabel={t(`${tKey}DELIVERY_DATE`)}
                  component={InputDatePicker}
                  validate={[this.dateValidation]}
                  disabled={true}
                />
              )}
              {this.state.fieldsConfiguration.paperworkReceiptDate.permission !== permissions.hidden && (
                <Field
                  id="paperworkReceiptDate"
                  name="dossierPaperworkComponent.paperworkReceiptDate"
                  colSm={6}
                  controlLabel={t(`${tKey}RECEIPT_DATE`)}
                  component={InputDatePicker}
                  validate={[this.dateValidation]}
                  disabled={true}
                />
              )}
              {this.state.fieldsConfiguration.economicTarget.permission !== permissions.hidden && dossierParentId && (
                <Field
                  colSm={6}
                  id="economicTarget"
                  name="dossierPaperworkComponent.economicTarget"
                  controlLabel={t(`${tKey}ECONOMIC_TARGET`)}
                  placeholder={t(`${tKey}ECONOMIC_TARGET`)}
                  component={InputSelect}
                  options={economicTargetTypes}
                  valueKey="id"
                  labelKey="value"
                  disabled={true}
                />
              )}
              {this.state.fieldsConfiguration.serviceDestination.permission !== permissions.hidden && (
                <Field
                  colSm={6}
                  id="serviceDestinationId"
                  name="dossierPaperworkComponent.serviceDestinationId"
                  controlLabel={t(`${tKey}SERVICE_DESTINATION`)}
                  placeholder={t(`${tKey}SERVICE_DESTINATION`)}
                  component={InputSelect}
                  options={serviceDestinationCombo}
                  valueKey="id"
                  labelKey="value"
                  disabled={true}
                />)}
              {this.state.fieldsConfiguration.vehicleType.permission !== permissions.hidden && (
                <Field
                  colSm={6}
                  id="vehicleTypeId"
                  name="dossierPaperworkComponent.vehicleTypeId"
                  controlLabel={t(`${tKey}VEHICLE_TYPE`)}
                  placeholder={t(`${tKey}VEHICLE_TYPE`)}
                  component={InputSelect}
                  options={vehicletypeCombo}
                  valueKey="id"
                  labelKey="value"
                  disabled={true}
                />)}
              {this.state.fieldsConfiguration.operationTypeIds.permission !== permissions.hidden && (
                <Field
                  colSm={6}
                  id="operationTypeIds"
                  name="dossierPaperworkComponent.operationTypeIds"
                  controlLabel={t(`${tKey}OPERATION_TYPE`)}
                  placeholder={t(`${tKey}OPERATION_TYPE`)}
                  valueKey="id"
                  labelKey="value"
                  component={InputSelect}
                  options={operationTypeCombo}
                  disabled={true}
                  multi
                  menuContainerStyle={{ zIndex: 999 }}
                  blurInputOnSelect={false}
                />)}
            </Row>
            {
              dossierPaperworkComponent.showEconomicPlan === true ? (
                <Row>
                  {this.state.fieldsConfiguration.hasSupInvoice.permission !== permissions.hidden && (this.state.economicTarget === economicPlanSectionType.VENTA_OTROS ||
                    this.state.economicTarget === economicPlanSectionType.SUPLIDOS) && (
                    <Field
                      id="hasSupInvoice"
                      name="dossierPaperworkComponent.hasSupInvoice"
                      colSm={12}
                      inline
                      placeholder={t(`${tKey}SUPPLEMENTARY_INVOICE`)}
                      component={InputCheckBox}
                      disabled={true}
                    />
                  )}
                  {this.state.fieldsConfiguration.hasIem.permission !== permissions.hidden && this.state.economicTarget === economicPlanSectionType.VENTA_OTROS && (
                    <Field
                      id="hasItem"
                      name="dossierPaperworkComponent.hasIem"
                      colSm={12}
                      inline
                      placeholder={t(`${tKey}IEM`)}
                      component={InputCheckBox}
                      disabled={true}
                    />
                  )}
                  {this.state.fieldsConfiguration.toBeReviewed.permission !== permissions.hidden && (
                    <Field
                      id="toBeReviewed"
                      name="dossierPaperworkComponent.toBeReviewed"
                      colSm={6}
                      inline
                      disabled={true}
                      placeholder={t(`${tKey}TO_BE_REVIEWED`)}
                      component={InputCheckBox}
                    />
                  )}
                </Row>
              ) : null
            }
            <CustomFields customFields={customFields} readOnlyFields={true} />
          </Panel.Collapse>
        </Panel>
      </div>
    )
  }
}

export default PaperworkComponent
