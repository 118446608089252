import React, { PureComponent } from 'react'
import { Button, OverlayTrigger, Tooltip, Form } from 'react-bootstrap'
import { Field, reduxForm, reset } from 'redux-form'
import InputSelect from '../commons/form/InputSelect'
import InputText from '../commons/form/InputText'
import InputDatePicker from '../commons/form/InputDatePicker'
import InputTreeSelect from '../commons/form/InputTreeSelect'
import { validateDate, validateDates, validateMinimalStringLength } from '../../../util/validationFunctions'
import InputNumber from '../../../_v2/components/commons/form/InputNumber'
import DossierPopover from '../../components/search/DossierPopover'
import EntityPopover from '../../components/search/EntityPopover'
import VehiclePopover from '../../components/search/VehiclePopover'
import SimpleTablePage from '../../../containers/commons/general/SimpleTablePage'
import IDocCarPagination from '../../../components/commons/iDocCarPagination'
import FinderFleetReportDatesPopover from '../../../components/search/FinderFleetReportDatesPopover'
import { dossierSubTypeId } from '../../../constants/dossier/common/dossierSubType'
import { entityTypesId } from '../../../constants/backendIds'
import MapSectionsReport from '../../../_v2/util/map-sections-report'
import settings from '../../../setting'
import { fromCamelToUnderscore, getDateNowMaxDatePicker } from '../../../util/utils'
import { showFilter, hideFiltersBtn, toggleFilterClass } from '../../../_v2/util/management/ManagementUtils'

class SearchByFleetReport extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      fields: {},
      objective: 0,
      selectedEntityType: null,
      selectedEntityChildType: null
    }
  }

  componentDidMount () {
    this.props.actions.initializeSearch()

    if (this.props.combos.purchaseStateLogisticCombo?.length === 0) {
      this.props.actions.fetchPurchaseLogisticStateCombo()
    }

    if (this.props.dynamicFilters?.fleetReport !== null && this.props.dynamicFilters?.fleetReport !== undefined) {
      if (this.props.dynamicFilters.fleetReport.entityTypeId !== null && this.props.dynamicFilters.fleetReport.entityTypeId !== undefined) {
        this.onEntityTypeChange(this.props.dynamicFilters.fleetReport.entityTypeId)
        if (this.props.dynamicFilters.fleetReport.entityTypeId === entityTypesId.recurrentEntity) {
          this.setState({ selectedEntitySubType: this.props.dynamicFilters.fleetReport.entitySubTypeId })
          this.props.actions.fetchEntityFilterSubTypeCombo(this.props.dynamicFilters.fleetReport.entityTypeId, this.props.dynamicFilters.fleetReport.entitySubTypeId)
        }
      }
    }
  }

  validateDate (value, allValues, props) {
    const result = validateDate(value, props)
    return result === Object(result) ? undefined : result
  }

  exportData () {
    var tKey = 'SEARCH.CATEGORIES.FLEETREPORT.EXCEL.'
    let columnsExcel = []
    columnsExcel.push('' + '#Empty')
    columnsExcel.push(this.props.t(`${tKey}ISAGENCY`) + '#IsAgency')
    columnsExcel.push(this.props.t(`${tKey}ORDERDATE`) + '#OrderDate')
    columnsExcel.push(this.props.t('SEARCH.CATEGORIES.FLEETREPORT.MAKER_NUMBER') + '#MakerNumber')
    columnsExcel.push(this.props.t(`${tKey}RENTALORDERNUMBER`) + '#RentalOrderNumber')
    columnsExcel.push(this.props.t(`${tKey}NUMBER`) + '#Number')
    columnsExcel.push(this.props.t('SEARCH.CATEGORIES.FLEETREPORT.DOSSIER_STAGES') + '#Stage')
    columnsExcel.push(this.props.t('SEARCH.CATEGORIES.FLEETREPORT.SALESMAN') + '#SalesMan')
    columnsExcel.push(this.props.t(`${tKey}CLIENT`) + '#Client')
    columnsExcel.push(this.props.t(`${tKey}FINALCLIENT`) + '#FinalClient')
    columnsExcel.push(this.props.t(`${tKey}VEHICLEBRAND`) + '#VehicleBrand')
    columnsExcel.push(this.props.t(`${tKey}ORGANIZEDUNIT`) + '#OrganizedUnit')
    columnsExcel.push(this.props.t('SEARCH.CATEGORIES.VEHICLE.COMMERCIAL_CODE') + '#VehicleCommercialCode')
    columnsExcel.push(this.props.t(`${tKey}VEHICLEMODEL`) + '#VehicleModel')
    columnsExcel.push(this.props.t(`${tKey}VEHICLECOLOR`) + '#VehicleColor')
    columnsExcel.push(this.props.t(`${tKey}PURCHASESTATE`) + '#PurchaseState')
    columnsExcel.push(this.props.t(`${tKey}PRODUCTIONWEEK`) + '#ProductionWeek')
    columnsExcel.push(this.props.t(`${tKey}VEHICLEVINCHASSIS`) + '#VehicleVinChassis')
    columnsExcel.push(this.props.t(`${tKey}VEHICLELICENSEPLATE`) + '#VehicleLicensePlate')
    columnsExcel.push(this.props.t(`${tKey}DELIVERYCITY`) + '#DeliveryCity')
    columnsExcel.push(this.props.t(`${tKey}REALDELIVERYADDRESS`) + '#RealDeliveryAddress')
    columnsExcel.push(this.props.t(`${tKey}CUSTOMERNEEDDATE`) + '#CustomerNeedDate')
    columnsExcel.push(this.props.t(`${tKey}SHIPPINGORDERDATE`) + '#ShippingOrderDate')
    columnsExcel.push(this.props.t(`${tKey}CAMPAIGNOUTPUTDATE`) + '#CampaignOutputDate')
    columnsExcel.push(this.props.t(`${tKey}DESTINATIONARRIVALDATE`) + '#DestinationArrivalDate')
    columnsExcel.push(this.props.t(`${tKey}BRANDDISCHARGEDATE`) + '#BrandDischargeDate')

    this.props.actions.fetchFleetReportDownload({ ...columnsExcel, ...this.state.filterValues })
  }

  getYesNoComboOptions () {
    const tKey = 'SEARCH.CATEGORIES.DOSSIER.'
    return [
      { value: this.props.t(`${tKey}YES`), id: true },
      { value: this.props.t(`${tKey}NO`), id: false }]
  }

  handleClickReset (e) {
    if (e !== undefined) {
      e.preventDefault()
    }

    this.props.dispatch(reset('fleetReport'))
    this.state.dossierIdToArray = []
    this.setState({ onlyFleets: true, checkAll: false, dossierIdToArray: [], dossierData: [], fields: [] })
  }

  onEntityTypeChange (entityTypeId) {
    this.setState({ selectedEntityType: entityTypeId })
    if (entityTypeId === entityTypesId.recurrentEntity) {
      this.props.change('fleetReport.dniCif', null)
      this.props.change('fleetReport.entityName', null)
      this.props.actions.fetchEntitySubTypeCombo(entityTypeId)
    } else {
      this.props.change('fleetReport.entitySubTypeId', null)
      this.props.change('fleetReport.entityId', null)
    }
  }

  onEntitySubTypeChange (entitySubTypeId) {
    this.setState({ selectedEntitySubType: entitySubTypeId })
    if (entitySubTypeId) this.props.actions.fetchEntityFilterSubTypeCombo(this.state.selectedEntityType, entitySubTypeId)
  }

  getEntityNameLabel () {
    const tKey = 'SEARCH.CATEGORIES.ENTITY.'
    switch (this.state.selectedEntityType) {
      case entityTypesId.businessEntity:
        return this.props.t(`${tKey}REGISTERED_NAME`)
      default:
        return this.props.t(`${tKey}NAME`)
    }
  }

  onEntityChildTypeChange (entityChildTypeId) {
    this.setState({ selectedEntityChildType: entityChildTypeId })
    if (entityChildTypeId === entityTypesId.recurrentEntity) {
      this.props.change('fleetReport.dniCifChild', null)
      this.props.change('fleetReport.entityChildName', null)
      this.props.actions.fetchEntitySubTypeCombo(entityChildTypeId)
    } else {
      this.props.change('fleetReport.entityChildSubTypeId', null)
      this.props.change('fleetReport.entityChildId', null)
    }
  }

  onEntityChildSubTypeChange (entityChildSubTypeId) {
    this.setState({ selectedEntityChildSubType: entityChildSubTypeId })
    if (entityChildSubTypeId) this.props.actions.fetchEntityFilterSubTypeCombo(this.state.selectedEntityChildType, entityChildSubTypeId)
  }

  getEntityChildNameLabel () {
    const tKey = 'SEARCH.CATEGORIES.FLEETREPORT.'
    switch (this.state.selectedEntityChildType) {
      case entityTypesId.businessEntity:
        return this.props.t(`${tKey}REGISTERED_NAME_CHILD`)
      default:
        return this.props.t(`${tKey}ENTITY_NAME_CHILD`)
    }
  }

  removeRecurrentEntityTypeFromCombo (entityTypeCombo) {
    var entityTypeComboNoRecurrent = []
    for (const entity of entityTypeCombo) {
      if (entity.id !== entityTypesId.recurrentEntity) {
        entityTypeComboNoRecurrent.push(entity)
      }
    }
    return entityTypeComboNoRecurrent
  }

  submitSearchForm (values) {
    var allEmptyFieldOrNoValues = this.standardValidations(values)
    if (allEmptyFieldOrNoValues) {
      return this.props.actions.openModal('mustFillMinFilters2')
    }

    this.props.actions.fetchFleetReport({ ...values.fleetReport, ...this.props.filters })

    this.setState({ hideFilters: true, dossierIdToArray: [], dynamicFilters: values, filterValues: { ...values.fleetReport, ...this.props.filters } })

    this.props.actions.setFilters(values)
  }

  onOrderChange (e) {
    const newOrderBy = this.props.filters.orderBy.includes(`%2B${e.currentTarget.dataset.field}`) ? `-${e.currentTarget.dataset.field}` : `%2B${e.currentTarget.dataset.field}`
    this.setState({ filterValues: { ...this.state.filterValues, orderBy: newOrderBy } })
    this.props.actions.fetchFleetReport({ ...this.state.filterValues, orderBy: newOrderBy })
  }

  onPageChange (page) {
    this.props.actions.fetchFleetReport({ ...this.state.filterValues, page })
  }

  standardValidations (values) {
    var result = false
    const formValues = values.fleetReport

    const minFilters = 2
    const valuesCount = formValues
      ? Object.keys(formValues).filter(item => formValues[item] !== null).length
      : 0
    result = !(valuesCount >= minFilters)

    return result
  }

  extractMultipleComboStringArray (combo, filters, item) {
    let array = filters[item] ? filters[item].split(',').map(Number) : null
    const values = combo.filter(object => {
      return array ? array.some(selectedComboValue => object.id === selectedComboValue) : null
    })

    let value = values && this.formatMultipleValues(values)
    return value
  }

  formatMultipleValues (multiplecombovalues) {
    return multiplecombovalues
      .map((combovalue, index) => combovalue.value || combovalue.name)
      .join(', ')
  }

  treeComboToArray (combo) {
    var values = []
    combo.map((item, index) => {
      values.push({ id: item.value, value: item.label })
      if (item.children && item.children.length > 0) {
        values = values.concat(this.treeComboToArray(item.children))
      }
    })
    return values
  }

  extractCombo (combo, filters, item) {
    const selected = combo.find(object => object.id === filters[item])
    let value = selected && selected.value
    return value
  }

  extractMultipleCombo (combo, filters, item) {
    const values = combo.filter(object => {
      return filters[item] ? filters[item].some(selectedComboValue => object.id === selectedComboValue) : null
    })
    let value = values && this.formatMultipleValues(values)
    return value
  }

  extractValueComboYesNo (filters, item) {
    if (item === true || item === 1) return this.props.t('SEARCH.CATEGORIES.DOSSIER.YES')
    if (item === false || item === 0) return this.props.t('SEARCH.CATEGORIES.DOSSIER.NO')
  }

  selectedFilters () {
    const filters = this.props.dynamicFilters.fleetReport
    const tKey = 'SEARCH.CATEGORIES.FLEETREPORT.'
    return Object.keys(filters)
      .map((item, index) => {
        let text = item
        let value = null
        const list = []

        text = this.props.t(`${tKey}${fromCamelToUnderscore(item).toUpperCase()}`)
        switch (item) {
          case 'entityTypeId':
          case 'entityChildTypeId':
          {
            const entityTypeId = this.props.combos.entityTypeCombo.find(entityType => entityType.id === filters[item])
            value = entityTypeId && entityTypeId.value
            break
          }
          case 'entitySubTypeId':
          case 'entityChildSubTypeId':
          {
            const entitySubTypeId = this.props.combos.entitySubTypeCombo.find(entitySubType => entitySubType.id === filters[item])
            value = entitySubTypeId && entitySubTypeId.value
            break
          }
          case 'entityId':
          case 'entityChildId':
          {
            const entityId = this.props.combos.entityFilterSubTypeCombo.find(entity => entity.id === filters[item])
            value = entityId && entityId.value
            break
          }
          case 'salesmanIndividual':
          {
            value = this.extractCombo(this.props.combos.salesmanCombo, filters, item)
            break
          }
          case 'organizedUnits':
          {
            value = this.extractMultipleComboStringArray(this.treeComboToArray(this.props.combos.UOTreeCombo), filters, item)
            break
          }
          case 'dossierStages':
          {
            const stage = this.extractMultipleCombo(this.props.combos.brandCombo, filters, item)
            value = stage && stage.value
            break
          }
          case 'licensePlateFilled': {
            value = this.extractValueComboYesNo(filters[item])
            break
          }
          case 'isAgencyModel': {
            value = this.extractValueComboYesNo(filters[item])
            break
          }
          default:
          {
            value = filters[item]
            break
          }
        }

        if (value) {
          list.push(<li key={index}>{text}: <strong>{value}</strong>;</li>)
        }

        return list
      })
  }

  toogleHideFilters () {
    this.setState({ hideFilters: !this.state.hideFilters })
  }

  toogleMaxiTable () {
    this.setState({ maxiTable: !this.state.maxiTable })
  }

  render () {
    const {
      t, combos: { UOTreeCombo, entitySubTypeCombo, entityTypeCombo, entityFilterSubTypeCombo, salesmanCombo, purchaseStateLogisticCombo, stagesCombo },
      fields, fieldFill, className, classNameSelect, hasSearch, fleetReportData, getIconClass,
      page, pages, count,
      handleSubmit // From search
    } = this.props
    const tKey = 'SEARCH.CATEGORIES.FLEETREPORT.'
    const dKey = 'GLOBAL.TABLES.'
    const secMapReport = MapSectionsReport.find(function (s) { return s.page === 'fleetReportPage' })
    const tKeyGen = 'SEARCH.CATEGORIES.DOSSIER.'
    const hasResults = fleetReportData && fleetReportData.length > 0

    return (
      <div className="inner-results-panel">
        <div className="section-results-header">
          <div className="section-header-title">
            <OverlayTrigger placement="right" overlay={<Tooltip id="editProductTooltip">{`${t((this.props.hideMenu ? 'GLOBAL.TABLES.SHOW_INNER_MENU' : 'GLOBAL.TABLES.HIDE_INNER_MENU'))}`}</Tooltip>}>
              <a onClick={() => this.props.toogleInnerMenu()} className={this.props.hideMenu ? 'collapseBtn' : 'collapseBtn closeInnerMenu'}><i className={this.props.hideMenu ? 'iDocIcon-Open-inner-menu' : 'iDocIcon-Close-inner-menu'}></i></a>
            </OverlayTrigger>
            <h4>{t(`${secMapReport.title}`)}
              <OverlayTrigger placement="left" overlay={<Tooltip id="editProductTooltip">{`${t((this.state.hideFilters ? 'GLOBAL.TABLES.SHOW_FILTERS' : 'GLOBAL.TABLES.HIDE_FILTERS'))}`}</Tooltip>}>
                <a onClick={() => this.toogleHideFilters()} className={'toogleShowFilters ' + hideFiltersBtn(hasResults)}><i className={toggleFilterClass(hasResults, this.state.hideFilters)}></i></a>
              </OverlayTrigger>
            </h4>
          </div>

          <Form autoComplete="off" onSubmit={handleSubmit((values) => this.submitSearchForm(values))} className="filters">
            <div className={'search-form ' + (showFilter(hasResults, this.state.hideFilters) ? '' : 'hideFilters ')}>
              <Field
                component={InputDatePicker}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                maxDate={getDateNowMaxDatePicker()}
                id="orderDateFrom"
                name='fleetReport.orderDateFrom'
                placeholder={t(`${tKey}ORDER_DATE_FROM`)}
                controlLabel={t(`${tKey}ORDER_DATE_FROM`)}
                validate={[this.validateDate]}
                onInputChange={(val) => fieldFill({ orderDateFrom: val })}
                customClass={fields.orderDateFrom ? className : ''}
              />
              <Field
                component={InputDatePicker}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                maxDate={getDateNowMaxDatePicker()}
                id="orderDateTo"
                name='fleetReport.orderDateTo'
                placeholder={t(`${tKey}ORDER_DATE_TO`)}
                controlLabel={t(`${tKey}ORDER_DATE_TO`)}
                validate={[this.validateDate]}
                onInputChange={(val) => fieldFill({ orderDateTo: val })}
                customClass={fields.orderDateTo ? className : ''}
              />
              <Field
                component={InputText}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                id="searchMakerNumber"
                name='fleetReport.makerNumber'
                placeholder={t(`${tKey}MAKER_NUMBER`)}
                controlLabel={t(`${tKey}MAKER_NUMBER`)}
                type="text"
                onInputChange={(val) => fieldFill({ makerNumber: val })}
                customClass={fields.makerNumber ? className : ''}
              />
              <Field
                component={InputText}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                id="rentalOrderIdNumber"
                name='fleetReport.rentalOrderNumber'
                placeholder={t(`${tKey}RENTAL_ORDER_NUMBER`)}
                controlLabel={t(`${tKey}RENTAL_ORDER_NUMBER`)}
                type="text"
                onInputChange={(val) => fieldFill({ rentalOrderIdNumber: val })}
                customClass={fields.rentalOrderIdNumber ? className : ''}
              />
              <Field
                component={InputSelect}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                id="entityTypeId"
                name='fleetReport.entityTypeId'
                placeholder={t('SEARCH.CATEGORIES.ENTITY.ENTITY_TYPE_ID')}
                controlLabel={t('SEARCH.CATEGORIES.ENTITY.ENTITY_TYPE_ID')}
                options={entityTypeCombo}
                valueKey="id"
                labelKey="value"
                onInputChange={ (val) => { this.onEntityTypeChange(val); fieldFill({ entityTypeId: val }) } }
                customClass={fields.entityTypeId ? classNameSelect : ''}
              />
              {(this.state.selectedEntityType === entityTypesId.recurrentEntity) && [
                <Field
                  component={InputSelect}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="entitySubTypeId"
                  name="fleetReport.entitySubTypeId"
                  key="fleetReport.entitySubTypeId"
                  controlLabel={t('SEARCH.CATEGORIES.ENTITY.ENTITY_SUB_TYPE_ID')}
                  placeholder={t('SEARCH.CATEGORIES.ENTITY.ENTITY_SUB_TYPE_ID')}
                  options={entitySubTypeCombo}
                  valueKey="id"
                  labelKey="value"
                  onInputChange={ (val) => { this.onEntitySubTypeChange(val); fieldFill({ entitySubTypeId: val }) } }
                  customClass={fields.entitySubTypeId ? classNameSelect : ''}
                />,
                <Field
                  component={InputSelect}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="entityId"
                  name="fleetReport.entityId"
                  key="fleetReport.entityId"
                  controlLabel={t('SEARCH.CATEGORIES.ENTITY.ENTITY_ID')}
                  placeholder={t('SEARCH.CATEGORIES.ENTITY.ENTITY_ID')}
                  options={entityFilterSubTypeCombo}
                  valueKey="id"
                  labelKey="value"
                  disabled={!this.state.selectedEntitySubType}
                  onInputChange={ (val) => fieldFill({ entityId: val }) }
                  customClass={fields.entityId ? classNameSelect : ''}
                />
              ]}
              {(this.state.selectedEntityType !== entityTypesId.recurrentEntity) && [
                <Field
                  component={InputText}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="dniCif"
                  name='fleetReport.dniCif'
                  key='fleetReport.dniCif'
                  placeholder={t('SEARCH.CATEGORIES.ENTITY.DNI_CIF')}
                  controlLabel={t('SEARCH.CATEGORIES.ENTITY.DNI_CIF')}
                  type="text"
                  onInputChange={(val) => fieldFill({ dniCif: val })}
                  customClass={fields.dniCif ? className : ''}
                />,
                <Field
                  component={InputText}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="entityName"
                  name='fleetReport.entityName'
                  key='fleetReport.entityName'
                  placeholder={this.getEntityNameLabel()}
                  controlLabel={this.getEntityNameLabel()}
                  type="text"
                  onInputChange={(val) => fieldFill({ clientName: val })}
                  customClass={fields.clientName ? className : ''}
                />
              ]}
              <Field
                component={InputSelect}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                id="entityChildTypeId"
                name='fleetReport.entityChildTypeId'
                placeholder={t('SEARCH.CATEGORIES.ENTITY.ENTITY_CHILD_TYPE_ID')}
                controlLabel={t('SEARCH.CATEGORIES.ENTITY.ENTITY_CHILD_TYPE_ID')}
                options={this.removeRecurrentEntityTypeFromCombo(
                  entityTypeCombo
                )}
                valueKey="id"
                labelKey="value"
                onInputChange={ (val) => { this.onEntityChildTypeChange(val); fieldFill({ entityChildTypeId: val }) } }
                customClass={fields.entityChildTypeId ? classNameSelect : ''}
              />
              {(this.state.selectedEntityChildType === entityTypesId.recurrentEntity) && [
                <Field
                  component={InputSelect}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="entityChildSubTypeId"
                  name="fleetReport.entityChildSubTypeId"
                  key="fleetReport.entityChildSubTypeId"
                  controlLabel={t('SEARCH.CATEGORIES.ENTITY.ENTITY_CHILD_SUB_TYPE_ID')}
                  placeholder={t('SEARCH.CATEGORIES.ENTITY.ENTITY_CHILD_SUB_TYPE_ID')}
                  options={entitySubTypeCombo}
                  valueKey="id"
                  labelKey="value"
                  onInputChange={ (val) => { this.onEntityChildSubTypeChange(val); fieldFill({ entityChildSubTypeId: val }) } }
                  customClass={fields.entityChildSubTypeId ? classNameSelect : ''}
                />,
                <Field
                  component={InputSelect}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="entityChildId"
                  name="fleetReport.entityChildId"
                  key="fleetReport.entityChildId"
                  controlLabel={t(`${tKey}ENTITY_CHILD_ID`)}
                  placeholder={t(`${tKey}ENTITY_CHILD_ID`)}
                  options={entityFilterSubTypeCombo}
                  valueKey="id"
                  labelKey="value"
                  disabled={!this.state.selectedEntitySubType}
                  onInputChange={ (val) => fieldFill({ entityChildId: val }) }
                  customClass={fields.entityChildId ? classNameSelect : ''}
                />
              ]}
              {(this.state.selectedEntityChildType !== entityTypesId.recurrentEntity) && [
                <Field
                  component={InputText}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="dniCifChild"
                  name='fleetReport.dniCifChild'
                  key='fleetReport.dniCifChild'
                  placeholder={t('SEARCH.CATEGORIES.ENTITY.DNI_CIF_CHILD')}
                  controlLabel={t('SEARCH.CATEGORIES.ENTITY.DNI_CIF_CHILD')}
                  type="text"
                  onInputChange={(val) => fieldFill({ dniCifChild: val })}
                  customClass={fields.dniCifChild ? className : ''}
                />,
                <Field
                  component={InputText}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="entityChildName"
                  name='fleetReport.entityChildName'
                  key='fleetReport.entityChildName'
                  placeholder={this.getEntityChildNameLabel()}
                  controlLabel={this.getEntityChildNameLabel()}
                  type="text"
                  onInputChange={(val) => fieldFill({ clientChildName: val })}
                  customClass={fields.clientChildName ? className : ''}
                />
              ]}
              <Field
                component={InputText}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                id="commercial_code"
                name='fleetReport.commercialCode'
                placeholder={t('SEARCH.CATEGORIES.VEHICLE.COMMERCIAL_CODE')}
                controlLabel={t('SEARCH.CATEGORIES.VEHICLE.COMMERCIAL_CODE')}
                type="text"
                onInputChange={(val) => fieldFill({ commercial_code: val })}
                customClass={fields.commercial_code ? className : ''}
              />
              <Field
                component={InputTreeSelect}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                id="organizedUnits"
                name="fleetReport.organizedUnits"
                placeholder={t(`${tKey}ORGANIZED_UNIT`)}
                controlLabel={t(`${tKey}ORGANIZED_UNIT`)}
                multi={true}
                valueKey="value"
                labelKey="label"
                childrenKey="children"
                options={UOTreeCombo}
                onInputChange={(val) => { fieldFill({ organizedUnits: val }) } }
                customClass={fields.organizedUnits ? classNameSelect : ''}
              />
              <Field
                component={InputSelect}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                id="salesman"
                name='fleetReport.salesman'
                placeholder={t(`${tKey}SALESMAN`)}
                controlLabel={t(`${tKey}SALESMAN`)}
                options={salesmanCombo}
                multi={true}
                valueKey="id"
                labelKey="value"
                onInputChange={(val) => fieldFill({ salesman: val })}
                customClass={fields.salesman ? classNameSelect : ''}
              />
              <Field
                component={InputSelect}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                id="purchaseState"
                name='fleetReport.purchaseState'
                placeholder={t(`${tKey}PURCHASE_STATE`)}
                controlLabel={t(`${tKey}PURCHASE_STATE`)}
                valueKey="value"
                labelKey="value"
                options={purchaseStateLogisticCombo}
                onInputChange={(val) => fieldFill({ purchaseState: val })}
                customClass={fields.purchaseState ? className : ''}
              />
              <Field
                component={InputNumber}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                id="productionWeek"
                name='fleetReport.productionWeek'
                controlLabel={t(`${tKey}PRODUCTION_WEEK`)}
                placeholder="0"
                onInputChange={(val) => fieldFill({ productionWeek: val })}
                customClass={fields.productionWeek ? className : ''}
              />
              <Field
                component={InputText}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                id="deliveryCity"
                name='fleetReport.deliveryCity'
                placeholder={t(`${tKey}DELIVERY_CITY`)}
                controlLabel={t(`${tKey}DELIVERY_CITY`)}
                type="text"
                onInputChange={(val) => fieldFill({ deliveryCity: val })}
                customClass={fields.deliveryCity ? className : ''}
              />
              <Field
                component={InputSelect}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                id="dossierStages"
                name='fleetReport.dossierStages'
                placeholder={t(`${tKey}DOSSIER_STAGES`)}
                controlLabel={t(`${tKey}DOSSIER_STAGES`)}
                multi
                menuContainerStyle={{ zIndex: 999 }}
                options={stagesCombo}
                valueKey="id"
                labelKey="value"
                disabled={stagesCombo.length === 0}
                onInputChange={(val) => fieldFill({ dossierStages: val })}
                customClass={fields.dossierStages ? classNameSelect : ''}
              />
              <Field
                component={InputSelect}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                id="licensePlateFilled"
                name='fleetReport.licensePlateFilled'
                placeholder={t(`${tKey}LICENSE_PLATE_FILLED`)}
                controlLabel={t(`${tKey}LICENSE_PLATE_FILLED`)}
                options={this.getYesNoComboOptions()}
                multi={false}
                valueKey="id"
                labelKey="value"
                onInputChange={(val) => fieldFill({ licensePlateFilled: val })}
                customClass={fields.licensePlateFilled ? classNameSelect : ''}
              />
              <Field
                component={InputSelect}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                id="isAgencyModel"
                name='fleetReport.isAgencyModel'
                placeholder={t(`${tKey}IS_AGENCY_MODEL`)}
                controlLabel={t(`${tKey}IS_AGENCY_MODEL`)}
                options={this.getYesNoComboOptions()}
                multi={false}
                valueKey="id"
                labelKey="value"
                onInputChange={(val) => fieldFill({ isAgencyModel: val })}
                customClass={fields.isAgencyModel ? classNameSelect : ''}
              />
              <Field
                component={InputDatePicker}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                maxDate={getDateNowMaxDatePicker()}
                id="brandDischargeDateFrom"
                name='fleetReport.brandDischargeDateFrom'
                placeholder={t(`${tKey}BRAND_DISCHARGE_DATE_FROM`)}
                controlLabel={t(`${tKey}BRAND_DISCHARGE_DATE_FROM`)}
                validate={[this.validateDate]}
                onInputChange={(val) => fieldFill({ brandDischargeDateFrom: val })}
                customClass={fields.brandDischargeDateFrom ? className : ''}
              />
              <Field
                component={InputDatePicker}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                maxDate={getDateNowMaxDatePicker()}
                id="brandDischargeDateTo"
                name='fleetReport.brandDischargeDateTo'
                placeholder={t(`${tKey}BRAND_DISCHARGE_DATE_TO`)}
                controlLabel={t(`${tKey}BRAND_DISCHARGE_DATE_TO`)}
                validate={[this.validateDate]}
                onInputChange={(val) => fieldFill({ brandDischargeDateTo: val })}
                customClass={fields.brandDischargeDateTo ? className : ''}
              />
              <Field
                component={InputDatePicker}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                maxDate={getDateNowMaxDatePicker()}
                id="customerNeedDateFrom"
                name='fleetReport.customerNeedDateFrom'
                placeholder={t(`${tKey}CUSTOMER_NEED_DATE_FROM`)}
                controlLabel={t(`${tKey}CUSTOMER_NEED_DATE_FROM`)}
                validate={[this.validateDate]}
                onInputChange={(val) => fieldFill({ customerNeedDateFrom: val })}
                customClass={fields.customerNeedDateFrom ? className : ''}
              />
              <Field
                component={InputDatePicker}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                maxDate={getDateNowMaxDatePicker()}
                id="customerNeedDateTo"
                name='fleetReport.customerNeedDateTo'
                placeholder={t(`${tKey}CUSTOMER_NEED_DATE_TO`)}
                controlLabel={t(`${tKey}CUSTOMER_NEED_DATE_TO`)}
                validate={[this.validateDate]}
                onInputChange={(val) => fieldFill({ customerNeedDateTo: val })}
                customClass={fields.customerNeedDateTo ? className : ''}
              />
              <Field
                component={InputDatePicker}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                maxDate={getDateNowMaxDatePicker()}
                id="shippingOrderDateFrom"
                name='fleetReport.shippingOrderDateFrom'
                placeholder={t(`${tKey}SHIPPING_ORDER_DATE_FROM`)}
                controlLabel={t(`${tKey}SHIPPING_ORDER_DATE_FROM`)}
                validate={[this.validateDate]}
                onInputChange={(val) => fieldFill({ shippingOrderDateFrom: val })}
                customClass={fields.shippingOrderDateFrom ? className : ''}
              />
              <Field
                component={InputDatePicker}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                maxDate={getDateNowMaxDatePicker()}
                id="shippingOrderDateTo"
                name='fleetReport.shippingOrderDateTo'
                placeholder={t(`${tKey}SHIPPING_ORDER_DATE_TO`)}
                controlLabel={t(`${tKey}SHIPPING_ORDER_DATE_TO`)}
                validate={[this.validateDate]}
                onInputChange={(val) => fieldFill({ shippingOrderDateTo: val })}
                customClass={fields.shippingOrderDateTo ? className : ''}
              />
              <Field
                component={InputDatePicker}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                maxDate={getDateNowMaxDatePicker()}
                id="campaignOutputDateFrom"
                name='fleetReport.campaignOutputDateFrom'
                placeholder={t(`${tKey}CAMPAIGN_OUTPUT_DATE_FROM`)}
                controlLabel={t(`${tKey}CAMPAIGN_OUTPUT_DATE_FROM`)}
                validate={[this.validateDate]}
                onInputChange={(val) => fieldFill({ campaignOutputDateFrom: val })}
                customClass={fields.campaignOutputDateFrom ? className : ''}
              />
              <Field
                component={InputDatePicker}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                maxDate={getDateNowMaxDatePicker()}
                id="campaignOutputDateTo"
                name='fleetReport.campaignOutputDateTo'
                placeholder={t(`${tKey}CAMPAIGN_OUTPUT_DATE_TO`)}
                controlLabel={t(`${tKey}CAMPAIGN_OUTPUT_DATE_TO`)}
                validate={[this.validateDate]}
                onInputChange={(val) => fieldFill({ campaignOutputDateTo: val })}
                customClass={fields.campaignOutputDateTo ? className : ''}
              />
              <Field
                component={InputDatePicker}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                maxDate={getDateNowMaxDatePicker()}
                id="destinationArrivalDateFrom"
                name='fleetReport.destinationArrivalDateFrom'
                placeholder={t(`${tKey}DESTINATION_ARRIVAL_DATE_FROM`)}
                controlLabel={t(`${tKey}DESTINATION_ARRIVAL_DATE_FROM`)}
                validate={[this.validateDate]}
                onInputChange={(val) => fieldFill({ destinationArrivalDateFrom: val })}
                customClass={fields.destinationArrivalDateFrom ? className : ''}
              />
              <Field
                component={InputDatePicker}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                maxDate={getDateNowMaxDatePicker()}
                id="destinationArrivalDateTo"
                name='fleetReport.destinationArrivalDateTo'
                placeholder={t(`${tKey}DESTINATION_ARRIVAL_DATE_TO`)}
                controlLabel={t(`${tKey}DESTINATION_ARRIVAL_DATE_TO`)}
                validate={[this.validateDate]}
                onInputChange={(val) => fieldFill({ destinationArrivalDateTo: val })}
                customClass={fields.destinationArrivalDateTo ? className : ''}
              />
              <div className='search-form-actions flexInLine al-R'>
                <Button id="btn_search"
                  type="submit"
                  className="_Btn accept stk">
                  <i className="iDocIcon-search"/>
                  {t('SEARCH.ACTIONS.SEARCH_BUTTON')}
                </Button>
                <Button
                  id="btn_reset"
                  onClick={(e) => this.handleClickReset(e)}
                  className={'_Btn' + (this.props.pristine ? ' accept' : ' accept')}
                  disabled={this.props.pristine}>
                  <i className="iDocIcon-clear-decline"/>
                  {t('SEARCH.ACTIONS.RESET_BUTTON')}
                </Button>
              </div>
            </div>
          </Form>
        </div>
        <div className="section-results-body">
          <div className={'searchResoultsContainer' + (this.state.maxiTable ? ' maxiTable' : '')}>
            {hasSearch && ([
              <div className="table-wrapper fleets" key="table-wrapper">
                <ul className="list-inline pull-left">
                  {this.selectedFilters().length === 0 ? <li>{t(`${tKeyGen}NO_SELECTED_FILTERS`)}</li> : (this.selectedFilters())}
                </ul>
                {/* table options, maximize, etc.... */}
                {hasResults &&
                  <div className='flexInLine al-R'>
                    <OverlayTrigger placement="left" overlay={<Tooltip id="editProductTooltip">{`${t(dKey + (this.state.maxiTable ? 'MINIMIZE_TABLE' : 'MAXIMIZE_TABLE'))}`}</Tooltip>}>
                      <a onClick={() => this.toogleMaxiTable()} className='_Btn accept stk o-icn maximizeBtn'><i className={(this.state.maxiTable ? 'iDocIcon-Minimize' : 'iDocIcon-Maximize')}></i></a>
                    </OverlayTrigger>
                    <OverlayTrigger placement="left" overlay={<Tooltip id="editProductTooltip">{t('SEARCH.ACTIONS.EXPORT')}</Tooltip>}>
                      <a onClick={(event) => this.exportData(event)} className='_Btn new o-icn exportToExcel'><i className="iDocIcon-file-excel"></i></a>
                    </OverlayTrigger>
                  </div>
                }
                {/* table */}
                { hasResults &&
                <div className="table-responsive">
                  <SimpleTablePage
                    columns={[
                      <th key={0} data-field="isAgencyModel" onClick={(event) => this.onOrderChange(event)}>
                        {t(`${tKey}M_A`)}&nbsp;<i className={getIconClass('isAgencyModel', this.props.filters)}/>
                      </th>,
                      <th key={1} data-field="orderDate" onClick={(event) => this.onOrderChange(event)}>
                        {t(`${tKey}ORDER_DATE`)}&nbsp;<i className={getIconClass('orderDate', this.props.filters)}/>
                      </th>,
                      <th key={2} data-field="makerNumber" onClick={(event) => this.onOrderChange(event)}>
                        {t(`${tKey}MAKER_NUMBER`)}&nbsp;<i className={getIconClass('makerNumber', this.props.filters)}/>
                      </th>,
                      <th key={3} data-field="rentalOrderNumber" onClick={(event) => this.onOrderChange(event)}>
                        {t(`${tKey}RENTAL_ORDER_NUMBER`)}&nbsp;<i className={getIconClass('rentalOrderNumber', this.props.filters)}/>
                      </th>,
                      <th key={15} data-field="number" onClick={(event) => this.onOrderChange(event)}>
                        {t(`${tKey}NUMBER`)}&nbsp;<i className={getIconClass('number', this.props.filters)}/>
                      </th>,
                      <th key={4} data-field="organizedUnit" onClick={(event) => this.onOrderChange(event)}>
                        {t(`${tKey}ORGANIZED_UNIT`)}&nbsp;<i className={getIconClass('organizedUnit', this.props.filters)}/>
                      </th>,
                      <th key={5} data-field="salesMan" onClick={(event) => this.onOrderChange(event)}>
                        {t(`${tKey}SALESMAN`)}&nbsp;<i className={getIconClass('salesMan', this.props.filters)}/>
                      </th>,
                      <th key={6} data-field="entity" onClick={(event) => this.onOrderChange(event)}>
                        {t(`${tKey}CLIENT`)}
                      </th>,
                      <th key={8} data-field="finalEntity" onClick={(event) => this.onOrderChange(event)}>
                        {t(`${tKey}FINAL_CLIENT`)}
                      </th>,
                      <th key={9} data-field="vehicle" onClick={(event) => this.onOrderChange(event)}>
                        {t(`${tKey}VEHICLE`)}
                      </th>,
                      <th key={10} data-field="purchaseState" onClick={(event) => this.onOrderChange(event)}>
                        {t(`${tKey}PURCHASE_STATE`)}&nbsp;<i className={getIconClass('purchaseState', this.props.filters)}/>
                      </th>,
                      <th key={11} data-field="productionWeek" onClick={(event) => this.onOrderChange(event)}>
                        {t(`${tKey}PRODUCTION_WEEK`)}&nbsp;<i className={getIconClass('productionWeek', this.props.filters)}/>
                      </th>,
                      <th key={12} data-field="deliveryCity" onClick={(event) => this.onOrderChange(event)}>
                        {t(`${tKey}DELIVERY_CITY`)}&nbsp;<i className={getIconClass('deliveryCity', this.props.filters)}/>
                      </th>,
                      <th key={13} data-field="realDeliveryAddress" onClick={(event) => this.onOrderChange(event)}>
                        {t(`${tKey}REAL_DELIVERY_ADDRESS`)}&nbsp;<i className={getIconClass('realDeliveryAddress', this.props.filters)}/>
                      </th>,
                      <th key={14} data-field="brandDischargeDate" onClick={(event) => this.onOrderChange(event)}>
                        {t(`${tKey}BRAND_DISCHARGE_DATE`)}&nbsp;<i className={getIconClass('brandDischargeDate', this.props.filters)}/>
                      </th>
                    ]}
                    rows={fleetReportData && fleetReportData.map((row, idx) => (
                      <tr key={idx}>
                        <td>
                          {row.isAgencyModel ? <i className="iDocIcon-sign-out-user-menu _green"></i> : null}
                        </td>
                        <td>
                          {row.orderDate}
                        </td>
                        <td>
                          {row.makerNumber}
                        </td>
                        <td>
                          {row.rentalOrderNumber}
                        </td>
                        <td className="see-more">
                          <DossierPopover
                            t={t}
                            dossier={row}
                            half={(fleetReportData?.length || 1) / (idx + 1)}
                            idx={idx}
                            id={'popover-dossier' + idx}
                          />
                        </td>
                        <td>{row.organizedUnit}</td>
                        <td>{row.salesMan}</td>
                        <td className="see-more">
                          <EntityPopover
                            t={t}
                            entity={row.entity}
                            half={(fleetReportData?.length || 1) / (idx + 1)}
                            idx={idx}
                            id={'popover-entity' + idx}
                            urgencyType={row.urgencyType}
                          />
                        </td>
                        <td className="see-more">
                          <EntityPopover
                            t={t}
                            entity={row.finalEntity}
                            half={(fleetReportData?.length || 1) / (idx + 1)}
                            idx={idx}
                            id={'popover-final-entity' + idx}
                            urgencyType={row.urgencyType}
                          />
                        </td>
                        <td className="see-more">
                          <VehiclePopover
                            t={t}
                            vehicle={row.vehicle}
                            half={(fleetReportData?.length || 1) / (idx + 1)}
                            idx={idx}
                            id={'popover-vehicle' + idx}
                            isHeader = {true}
                            dossierSubTypeId={dossierSubTypeId.venta_vn}
                          />
                        </td>
                        <td>{row.purchaseState}</td>
                        <td>{row.productionWeek}</td>
                        <td>{row.deliveryCity}</td>
                        <td>{row.realDeliveryAddress}</td>
                        <td className="see-more">
                          <FinderFleetReportDatesPopover
                            t={t}
                            dates={row.finderFleetReportDates}
                            half={(fleetReportData?.length || 1) / (idx + 1)}
                            idx={idx}
                            id={'popover-finderFleetReportDates' + idx}
                            isHeader = {true}
                          />
                        </td>
                      </tr>
                    ))}
                  />
                </div>}
                <div className="search-footer" key="search-footer">
                  <IDocCarPagination
                    id="btn_pag_sales"
                    page={page}
                    pagesCount={pages}
                    totalRows= {count}
                    onSelectAction={(page) => this.onPageChange(page)}
                  />
                </div>
              </div>
            ])}
          </div>
        </div>
      </div>
    )
  }
}

const validateFleetReportValues = (values, t) => {
  let errors = {}
  if (values) {
    errors.orderDateTo = validateDates(t, values.orderDateFrom, values.orderDateTo)
    errors.brandDischargeDateTo = validateDates(t, values.brandDischargeDateFrom, values.brandDischargeDateTo)
    errors.customerNeedDateTo = validateDates(t, values.customerNeedDateFrom, values.customerNeedDateTo)
    errors.shippingOrderDateTo = validateDates(t, values.shippingOrderDateFrom, values.shippingOrderDateTo)
    errors.campaignOutputDateTo = validateDates(t, values.campaignOutputDateFrom, values.campaignOutputDateTo)
    errors.destinationArrivalDateTo = validateDates(t, values.destinationArrivalDateFrom, values.destinationArrivalDateTo)
    errors.makerNumber = validateMinimalStringLength(t, values.makerNumber, 3)
    errors.rentalOrderNumber = validateMinimalStringLength(t, values.rentalOrderNumber, 3)
    errors.dniCif = validateMinimalStringLength(t, values.dniCif, 3)
    errors.entityName = validateMinimalStringLength(t, values.entityName, 3)
    errors.dniCifChild = validateMinimalStringLength(t, values.dniCifChild, 3)
    errors.entityChildName = validateMinimalStringLength(t, values.entityChildName, 3)
    errors.commercialCode = validateMinimalStringLength(t, values.commercialCode, 3)
    errors.deliveryCity = validateMinimalStringLength(t, values.deliveryCity, 3)
  }

  return errors
}

const validate = (values, { t }) => {
  const errors = {}
  if (values) {
    if (values.dossier && values.dossier.chassis && values.dossier.chassis.length < settings.chassisMinCharacter) {
      errors.dossier = {}
      errors.dossier.chassis = t('SEARCH.CATEGORIES.ERRORS.VINCHASIS').replace('##MIN_CHARACTERS##', settings.chassisMinCharacter)
    }
    if (values.vehicle && values.vehicle.chassis && values.vehicle.chassis.length < settings.chassisMinCharacter) {
      errors.vehicle = {}
      errors.vehicle.chassis = t('SEARCH.CATEGORIES.ERRORS.VINCHASIS').replace('##MIN_CHARACTERS##', settings.chassisMinCharacter)
    }

    if (values.dossier) {
      errors.dossier = {}
      if (values.dossier && values.dossier.creationDateFrom && values.dossier.creationDateTo) {
        errors.dossier.creationDateTo = validateDates(t, values.dossier.creationDateFrom, values.dossier.creationDateTo)
      }
      if (values.dossier && values.dossier.orderDateFrom && values.dossier.orderDateTo) {
        errors.dossier.orderDateTo = validateDates(t, values.dossier.orderDateFrom, values.dossier.orderDateTo)
      }
      if (values.dossier && values.dossier.availabilityDateFrom && values.dossier.availabilityDateTo) {
        errors.dossier.availabilityDateTo = validateDates(t, values.dossier.availabilityDateFrom, values.dossier.availabilityDateTo)
      }
      if (values.dossier && values.dossier.vehicleLicensePlateDateFrom && values.dossier.vehicleLicensePlateDateTo) {
        errors.dossier.vehicleLicensePlateDateTo = validateDates(t, values.dossier.vehicleLicensePlateDateFrom, values.dossier.vehicleLicensePlateDateTo)
      }
    }

    if (values.fleetReport) {
      errors.fleetReport = validateFleetReportValues(values.fleetReport, t)
    }
  }

  return errors
}

export default reduxForm({
  form: 'fleetReport',
  validate
})(SearchByFleetReport)
