import reportActionTypes from '../../constants/actions/reports/report'

function initialState () {
  return {
    reportTypeCode: null
  }
}

function fetchReportTypeCode (state, { reportTypeCode }) {
  return {
    ...state,
    reportTypeCode
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case reportActionTypes.FETCH_REPORT_TYPE_CODE_SUCCESS:
      return fetchReportTypeCode(state, action)
    default:
      return state
  }
}
