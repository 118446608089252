import apiFetch from '../apiFetch'

export default function ({ dossierId, historicalsId, saleParentId }, token) {
  const queryParamsArray = [
    dossierId ? `DossierId=${dossierId}` : null,
    historicalsId ? `historicalsId=${historicalsId}` : null,
    saleParentId ? `saleParentId=${saleParentId}` : null
  ]
  const queryParams = queryParamsArray.reduce(
    (result, query, idx) => result + (query ? (result ? `&${query}` : `?${query}`) : '')
    , ''
  )
  return apiFetch({ endPoint: `dossier/Purchase/SelfSupply${queryParams}`, method: 'PUT', token: token })
}
