import React, { PureComponent } from 'react'
import { Field } from 'redux-form'
import { Row, Clearfix, Button } from 'react-bootstrap'
import InputText from '../commons/form/InputText'
import InputSelect from '../commons/form/InputSelect'
import { entityTypesId } from '../../constants/backendIds'
import SimpleTablePage from '../../containers/commons/general/SimpleTablePage'
import IDocCarPagination from '../commons/iDocCarPagination'
import DossierListPopover from './DossierListPopover'
import InputCheckbox from '../commons/form/InputCheckBox'

class SearchByEntityForm extends PureComponent {
  constructor (props) {
    super()
    this.state = {
      selectedEntityType: props.filters && props.filters.entityTypeId,
      selectedEntitySubType: props.filters && props.filters.entitySubTypeId
    }
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (this.props.formValues) {
      if (
        !prevProps.formValues || (prevProps.formValues.entityTypeId && !this.props.formValues) ||
    prevProps.formValues.entityTypeId !== this.props.formValues.entityTypeId
      ) {
        this.onEntityTypeChange(this.props.formValues.entityTypeId)
      }
      if (
        !prevProps.formValues || (prevProps.formValues.entitySubTypeId && !this.props.formValues) ||
        prevProps.formValues.entitySubTypeId !== this.props.formValues.entitySubTypeId
      ) {
        this.onEntitySubTypeChange(this.props.formValues.entitySubTypeId)
      }
    }
    return null
  }

  componentDidUpdate () {
    // getSnapshotBeforeUpdate() should be used with componentDidUpdate()
  }

  componentDidMount () {
    if (this.props.formValues.entityTypeId) {
      this.onEntityTypeChange(this.props.formValues.entityTypeId)
    }
  }

  getEntityNameLabel () {
    const tKey = 'SEARCH.CATEGORIES.ENTITY.'
    switch (this.state.selectedEntityType) {
      case entityTypesId.privateEntity:
        return this.props.t(`${tKey}NAME`)
      default:
        return this.props.t(`${tKey}REGISTERED_NAME`)
    }
  }

  onEntityTypeChange (entityTypeId) {
    this.setState({ selectedEntityType: entityTypeId })

    if (entityTypeId === entityTypesId.recurrentEntity) {
      this.props.actions.fetchEntitySubTypeCombo(entityTypeId)
      this.props.change('entity.dniCif', null)
      this.props.change('entity.name', null)
      this.props.change('entity.surname1', null)
      this.props.change('entity.surname2', null)
      this.props.change('entity.representativeDni', null)
      this.props.change('entity.representativeName', null)
      this.props.change('entity.representativeSurname1', null)
      this.props.change('entity.representativeSurname2', null)
      this.props.change('entity.email', null)
      this.props.change('entity.phone', null)
      this.props.change('entity.address', null)
      this.props.change('entity.entityChildSelected', null)
    } else {
      this.props.change('entity.entitySubTypeId', null)
      this.props.change('entity.entityId', null)
      if (entityTypeId !== entityTypesId.privateEntity) {
        this.props.change('entity.surname1', null)
        this.props.change('entity.surname2', null)
      } else if (entityTypeId !== entityTypesId.businessEntity) {
        this.props.change('entity.representativeDni', null)
        this.props.change('entity.representativeName', null)
        this.props.change('entity.representativeSurname1', null)
        this.props.change('entity.representativeSurname2', null)
      }
    }
  }

  onEntitySubTypeChange (entitySubTypeId) {
    this.setState({ selectedEntitySubType: entitySubTypeId })
    if (entitySubTypeId) this.props.actions.fetchEntityFilterSubTypeCombo(this.state.selectedEntityType, entitySubTypeId)
  }

  exportData () {
    const tKey = 'SEARCH.CATEGORIES.ENTITY.'
    let columnsExcel = []
    columnsExcel.push(this.props.t(`${tKey}NAME`) + '#Name')
    columnsExcel.push(this.props.t(`${tKey}DNI_CIF`) + '#DniCif')
    columnsExcel.push(this.props.t(`${tKey}EMAIL`) + '#Email')
    columnsExcel.push(this.props.t(`${tKey}PHONE`) + '#Phone')
    columnsExcel.push(this.props.t(`${tKey}ADDRESS`) + '#Address')
    columnsExcel.push(this.props.t('SEARCH.CATEGORIES.VEHICLE.AMOUNT_RELATED_DOSSIERS') + '#LivesStoreds')
    if (this.props.formValues.entityTypeId !== entityTypesId.privateEntity) {
      columnsExcel.push(this.props.t(`${tKey}REPRESENTATIVE_NAME`) + '#RepresentantiveName')
      columnsExcel.push(this.props.t(`${tKey}REPRESENTATIVE_DNI`) + '#RepresentativeDni')
    }
    columnsExcel.push(this.props.t(`${tKey}ENTITY_TYPE_ID`) + '#EntityType')
    this.props.exportToExcel(columnsExcel)
  }

  render () {
    const {
      t, combos: { entityTypeCombo, entitySubTypeCombo, entityFilterSubTypeCombo }, fields, fieldFill, className, classNameSelect,
      hasSearch, entityData, vehicleData, selectedFilters, onClickResetSearch, page, pages, count, onPageChange, onOrderChange, getIconClass
    } = this.props
    const tKey = 'SEARCH.CATEGORIES.ENTITY.'
    return (
      <div>
        {!hasSearch ? (
          <Row>
            <Field
              colSm={3}
              id="entityTypeId"
              name='entity.entityTypeId'
              placeholder={t(`${tKey}ENTITY_TYPE_ID`)}
              controlLabel={t(`${tKey}ENTITY_TYPE_ID`)}
              component={InputSelect}
              options={entityTypeCombo}
              valueKey="id"
              labelKey="value"
              onInputChange={ (val) => { this.onEntityTypeChange(val); fieldFill({ entityTypeId: val }) } }
              customClass={fields.entityTypeId ? classNameSelect : ''}
            />
            {(this.state.selectedEntityType === entityTypesId.recurrentEntity) && [
              <Field
                colSm={3}
                id="entitySubTypeId"
                name="entity.entitySubTypeId"
                key="entity.entitySubTypeId"
                controlLabel={t(`${tKey}ENTITY_SUB_TYPE_ID`)}
                placeholder={t(`${tKey}ENTITY_SUB_TYPE_ID`)}
                component={InputSelect}
                options={entitySubTypeCombo}
                valueKey="id"
                labelKey="value"
                onInputChange={ (val) => { this.onEntitySubTypeChange.bind(this); fieldFill({ entitySubTypeId: val }) } }
                customClass={fields.entitySubTypeId ? classNameSelect : ''}
              />,
              <Field
                colSm={3}
                id="entityId"
                name="entity.entityId"
                key="entity.entityId"
                controlLabel={t(`${tKey}ENTITY_ID`)}
                placeholder={t(`${tKey}ENTITY_ID`)}
                component={InputSelect}
                options={entityFilterSubTypeCombo}
                valueKey="id"
                labelKey="value"
                disabled={!this.state.selectedEntitySubType}
                onInputChange={ (val) => fieldFill({ entityId: val }) }
                customClass={fields.entityId ? classNameSelect : ''}
              />
            ]}
            {(this.state.selectedEntityType === entityTypesId.privateEntity || this.state.selectedEntityType === entityTypesId.businessEntity) && [
              <Field
                colSm={3}
                id="idNumber"
                name='entity.dniCif'
                key='entity.dniCif'
                placeholder={t(`${tKey}DNI_CIF`)}
                controlLabel={t(`${tKey}DNI_CIF`)}
                component={InputText}
                type="text"
                onInputChange={(val) => fieldFill({ idNumber: val })}
                customClass={fields.idNumber ? className : ''}
              />,
              <Field
                colSm={3}
                id="clientNameEntity"
                name='entity.name'
                key='entity.name'
                placeholder={this.getEntityNameLabel()}
                controlLabel={this.getEntityNameLabel()}
                component={InputText}
                type="text"
                onInputChange={(val) => fieldFill({ clientName: val })}
                customClass={fields.clientName ? className : ''}
              />
            ]}
            {this.state.selectedEntityType === entityTypesId.privateEntity && [
              <Field
                id="clientSurname1"
                name='entity.surname1'
                key='entity.surname1'
                placeholder={t(`${tKey}SURNAME1`)}
                controlLabel={t(`${tKey}SURNAME1`)}
                component={InputText}
                type="text"
                onInputChange={(val) => fieldFill({ clientSurname1: val })}
                customClass={fields.clientSurname1 ? className : ''}
              />,
              <Clearfix key="clearfix_surname1"/>,
              <Field
                id="clientSurname2"
                name='entity.surname2'
                key='entity.surname2'
                placeholder={t(`${tKey}SURNAME2`)}
                controlLabel={t(`${tKey}SURNAME2`)}
                component={InputText}
                type="text"
                onInputChange={(val) => fieldFill({ clientSurname2: val })}
                customClass={fields.clientSurname2 ? className : ''}
              />
            ]}
            {this.state.selectedEntityType === entityTypesId.businessEntity && [
              <Field
                id="representativeID"
                name='entity.representativeDni'
                key='entity.representativeDni'
                placeholder={t(`${tKey}REPRESENTATIVE_DNI`)}
                controlLabel={t(`${tKey}REPRESENTATIVE_DNI`)}
                component={InputText}
                type="text"
                onInputChange={(val) => fieldFill({ representativeID: val })}
                customClass={fields.representativeID ? className : ''}
              />,
              <Clearfix key="clearfix_representativeDni"/>,
              <Field
                id="representativeName"
                name='entity.representativeName'
                key='entity.representativeName'
                placeholder={t(`${tKey}REPRESENTATIVE_NAME`)}
                controlLabel={t(`${tKey}REPRESENTATIVE_NAME`)}
                component={InputText}
                type="text"
                onInputChange={(val) => fieldFill({ representativeName: val })}
                customClass={fields.representativeName ? className : ''}
              />,
              <Field
                id="representativeSurname1"
                name='entity.representativeSurname1'
                key='entity.representativeSurname1'
                placeholder={t(`${tKey}REPRESENTATIVE_SURNAME1`)}
                controlLabel={t(`${tKey}REPRESENTATIVE_SURNAME1`)}
                component={InputText}
                type="text"
                onInputChange={(val) => fieldFill({ representativeSurname1: val })}
                customClass={fields.representativeSurname1 ? className : ''}
              />,
              <Field
                id="representativeSurname2"
                name='entity.representativeSurname2'
                key='entity.representativeSurname2'
                placeholder={t(`${tKey}REPRESENTATIVE_SURNAME2`)}
                controlLabel={t(`${tKey}REPRESENTATIVE_SURNAME2`)}
                component={InputText}
                type="text"
                forceControlLabelInOneLine={true}
                onInputChange={(val) => fieldFill({ representativeSurname2: val })}
                customClass={fields.representativeSurname2 ? className : ''}
              />
            ]}
            {(this.state.selectedEntityType !== entityTypesId.recurrentEntity) && [
              <Field
                id="emailEntity"
                name='entity.email'
                key='entity.email'
                placeholder={t(`${tKey}EMAIL`)}
                controlLabel={t(`${tKey}EMAIL`)}
                component={InputText}
                type="text"
                onInputChange={(val) => fieldFill({ email: val })}
                customClass={fields.email ? className : ''}
              />,
              this.state.selectedEntityType === entityTypesId.businessEntity && (
                <Clearfix key="clearfix_email"/>
              ),
              <Field
                id="phone"
                name='entity.phone'
                key='entity.phone'
                placeholder={t(`${tKey}PHONE`)}
                controlLabel={t(`${tKey}PHONE`)}
                component={InputText}
                type="text"
                onInputChange={(val) => fieldFill({ phone: val })}
                customClass={fields.phone ? className : ''}
              />,
              <Field
                id="location"
                name='entity.address'
                key='entity.address'
                placeholder={t(`${tKey}ADDRESS`)}
                controlLabel={t(`${tKey}ADDRESS`)}
                component={InputText}
                type="text"
                onInputChange={(val) => fieldFill({ location: val })}
                customClass={fields.location ? className : ''}
              />]}
            {(this.state.selectedEntityType === entityTypesId.businessEntity || this.state.selectedEntityType === entityTypesId.privateEntity) && [
              <Clearfix key="clearfix_entityChildSelected"/>,
              <Field
                id="entityChildSelected"
                name='entity.entityChildSelected'
                key='entity.entityChildSelected'
                placeholder={t(`${tKey}ENTITY_CHILD_SELECTED`)}
                controlLabel={t(`${tKey}ENTITY_CHILD_SELECTED`)}
                inline
                component={InputCheckbox}
                customClass={fields.entityChildSelected ? className : ''}
              />
            ]}
          </Row>
        ) : ([
          <Row className="filters-selected" key="filters-selected">
            <ul className="list-inline pull-left">
              {selectedFilters().length === 0 ? <li>{t(`${tKey}NO_SELECTED_FILTERS`)}</li> : (selectedFilters())}
            </ul>
            <Clearfix/>
            <Button
              onClick={(event) => onClickResetSearch(event)}
              bsStyle="default"
              bsSize="small"
              className="pull-left"
              id='modSearchButtonIdEntity'
            >
              {t('SEARCH.ACTIONS.MODIFY_SEARCH')}
              <i className="ico-edit-turquoise" />
            </Button>
            {count > 0 &&
            <Button
              onClick={(event) => this.exportData(event)}
              bsStyle="default"
              bsSize="small"
              id='expSearchButtonIdEntity'>
              {t('SEARCH.ACTIONS.EXPORT')}
              <i className="ico-if_Download_1031520" />

            </Button>
            }
          </Row>,
          <div className="table-wrapper" key="table-wrapper">
            <SimpleTablePage
              columns={[
                <th key={0} data-field="entityName" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}NAME`)}&nbsp;<i className={getIconClass('entityName')}/>
                </th>,
                <th key={1} data-field="entityCifNif" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}DNI_CIF`)}&nbsp;<i className={getIconClass('entityCifNif')}/>
                </th>,
                <th key={2} data-field="entityMail" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}EMAIL`)}&nbsp;<i className={getIconClass('entityMail')}/>
                </th>,
                <th key={3} data-field="entityPhone" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}PHONE`)}&nbsp;<i className={getIconClass('entityPhone')}/>
                </th>,
                <th key={4} data-field="entityAddress" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}ADDRESS`)}&nbsp;<i className={getIconClass('entityAddress')}/>
                </th>,
                <th key={5}>{t('SEARCH.CATEGORIES.VEHICLE.AMOUNT_RELATED_DOSSIERS')}</th>,
                this.state.selectedEntityType !== entityTypesId.privateEntity && (
                  <th key={6} data-field="legalEntityName" onClick={(event) => onOrderChange(event)}>
                    {t(`${tKey}REPRESENTATIVE_NAME`)}&nbsp;<i className={getIconClass('legalEntityName')}/>
                  </th>),
                this.state.selectedEntityType !== entityTypesId.privateEntity && (
                  <th key={7} data-field="legalEntityCifNif" onClick={(event) => onOrderChange(event)}>
                    {t(`${tKey}REPRESENTATIVE_DNI`)}&nbsp;<i className={getIconClass('legalEntityCifNif')}/>
                  </th>),
                <th key={8} data-field="entityType" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}ENTITY_TYPE_ID`)}&nbsp;<i className={getIconClass('entityType')}/>
                </th>
              ]}
              rows={entityData && entityData.map((row, idx) => (
                <tr key={idx}>
                  <td>{
                    (row.entityName && row.entitySurname1 && row.entitySurname2) ? row.entityName + ' ' + row.entitySurname1 + ' ' + row.entitySurname2
                      : (row.entityName && row.entitySurname1) ? row.entityName + ' ' + row.entitySurname1 : row.entityName ? row.entityName : ''
                  }</td>
                  <td>{row.entityCifNif ? row.entityCifNif : ''}</td>
                  <td>{row.entityMail ? row.entityMail : ''}</td>
                  <td>{row.entityPhone ? row.entityPhone : ''}</td>
                  <td>{row.entityAddress ? row.entityAddress : ''}</td>
                  <td className="see-more">
                    <DossierListPopover
                      t={t}
                      half={(vehicleData.length || 1) / (idx + 1)}
                      idx={idx}
                      id={'popover-dossier-list' + idx}
                      alive={row.lives}
                      stored={row.stored}
                      subtypesCombo={this.props.subtypesCombo}
                    />
                  </td>
                  {this.state.selectedEntityType !== entityTypesId.privateEntity && (
                    <td>{row.representativeName ? row.representativeName + ' ' : '' + row.representativeSurname1 ? row.representativeSurname1 : ''}</td>)}
                  {this.state.selectedEntityType !== entityTypesId.privateEntity && (
                    <td>{row.representativeDni ? row.representativeDni : ''}</td>)}
                  <td>{row.entityType ? row.entityType : ''}</td>
                </tr>
              ))}
            />
          </div>,
          <div className="search-footer" key="search-footer">
            <IDocCarPagination
              id="btn_pag_sales"
              page={page}
              pagesCount={pages}
              totalRows= {count}
              onSelectAction={(page) => onPageChange(page)}
            />
          </div>
        ])}
      </div>
    )
  }
}

export default SearchByEntityForm
