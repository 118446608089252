import { put, call, select, takeLatest } from 'redux-saga/effects'
import { getAuth } from '../../selectors/access/auth'
import { showLoader, hideLoader } from '../../actions/common'
import messageModalActions from '../../actions/modals/messageModal'
import { yesNoModal } from '../modalsListeners/yesNoModal'
import unitActionTypes from '../../constants/actions/unit/unit'
import deleteUnitService from '../../services/unit/deleteUnit'
import getUnits from '../../services/unit/getUnitList'
import { fetchUnitSuccess, updateFilter } from '../../actions/unit/unit'
import { handleError } from '../errors/errorManager'

export function * deleteUnit ({ unit: { unitId } }) {
  try {
    const confirmation = yield call(yesNoModal, 'deleteUnit')
    yield put(showLoader())
    if (confirmation) {
      const auth = yield select(getAuth)
      const response = yield call(deleteUnitService, unitId, auth.token)
      if (response && response.tag === 'deleteUnitError') {
        yield put(hideLoader())
        yield call(yesNoModal, 'unitWithActiveUsers')
      } else {
        const filter = {
          company: null,
          unitName: '',
          page: 1,
          pageSize: 2,
          orderBy: 'name'
        }
        const response = yield call(getUnits, {
          token: auth.token,
          companyId: filter.company ? filter.company.companyId : null,
          name: filter.unitName || null,
          orderBy: filter.orderBy || null,
          page: filter.page || null,
          pageSize: filter.pageSize || null
        })
        yield put(fetchUnitSuccess(response))
        yield put(updateFilter(filter))
      }
    }
  } catch (error) {
    const customHandler = function * () {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.UNIT_DELETED_FAILED'))
    }
    yield call(handleError, { error, customHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDeleteUnit () {
  yield takeLatest(unitActionTypes.DELETE_UNIT, deleteUnit)
}
