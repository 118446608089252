import { put, call, select, takeEvery } from 'redux-saga/effects'
import { fetchCombosSuccess } from '../../../../actions/combos/combos'
import getEntityCountryTypeCombo from '../../../../services/dossier/get/getEntityCountryTypeCombo'
import getEntityStateTypeCombo from '../../../../services/dossier/get/getEntityStateTypeCombo'
import getEntityCityTypeCombo from '../../../../services/dossier/get/getEntityCityTypeCombo'
import getEntityTownTypeCombo from '../../../../services/dossier/get/getEntityTownTypeCombo'
import getEntityColonyTypeCombo from '../../../../services/dossier/get/getEntityColonyTypeCombo'
import getEntityCPTypeCombo from '../../../../services/dossier/get/getEntityCPTypeCombo'
import { getAuth } from '../../../../selectors/access/auth'
import combosActionTypes from '../../../../constants/actions/combos/combos'
import messageModalActions from '../../../../actions/modals/messageModal'
import { handleError } from '../../../errors/errorManager'
import { hideLoader, showLoader } from '../../../../actions/common'

export function * fetchClientDataAddress ({ entity, auth, resolve }) {
  yield put(showLoader())
  let entityCountryTypeCombo = []
  let entityStateTypeCombo = []
  let entityCityTypeCombo = []
  let entityTownTypeCombo = []
  let entityColonyTypeCombo = []
  let entityCPTypeCombo = []
  try {
    if (!auth) {
      auth = yield select(getAuth)
    }

    entityCountryTypeCombo = yield call(getEntityCountryTypeCombo, auth.token)
    if (entity.countryCode) {
      entityStateTypeCombo = yield call(getEntityStateTypeCombo, auth.token, entityCountryTypeCombo?.find(x => x.code === entity.countryCode)?.id)
    }

    if (entity.stateCode) {
      entityCityTypeCombo = yield call(getEntityCityTypeCombo, auth.token, entityStateTypeCombo?.find(x => x.code === entity.stateCode)?.id)
    }

    if (entity.cityCode) {
      entityTownTypeCombo = yield call(getEntityTownTypeCombo, auth.token, entityCityTypeCombo?.find(x => x.code === entity.cityCode)?.id)
    }

    if (entity.townCode) {
      entityColonyTypeCombo = yield call(getEntityColonyTypeCombo, auth.token, entityTownTypeCombo?.find(x => x.code === entity.townCode)?.id)
    }

    if (entity.colonyCode) {
      entityCPTypeCombo = yield call(getEntityCPTypeCombo, auth.token, entityColonyTypeCombo?.find(x => x.code === entity.colonyCode)?.id)
    }

    if (resolve) {
      resolve({ entityCountryTypeCombo, entityStateTypeCombo, entityCityTypeCombo, entityTownTypeCombo, entityColonyTypeCombo, entityCPTypeCombo })
    } else {
      yield put(fetchCombosSuccess({ entityCountryTypeCombo, entityStateTypeCombo, entityCityTypeCombo, entityTownTypeCombo, entityColonyTypeCombo, entityCPTypeCombo }))
    }
  } catch (error) {
    const customHandler = function * () {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.FETCH_COMBO_ERROR'))
    }
    yield call(handleError, { error, customHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchClientDataAddress () {
  yield takeEvery(combosActionTypes.FETCH_CLIENT_DATA_ADDRESS, fetchClientDataAddress)
}
