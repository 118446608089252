import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { closeMetadataMasterModal, submitDocumentTypeMetadata } from '../../../actions/masters/metadata/metadata'
import MetadataMasterModal from '../../../components/masters/metadata/MetadataMasterModal'

export function mapStateToProps (state) {
  const documentTypeMetadataModal = state.documentTypeMetadata.documentTypeMetadataModal
  return {
    showModal: documentTypeMetadataModal.showModal,
    documentTypeMetadata: documentTypeMetadataModal.documentTypeMetadata,
    documentTypeId: documentTypeMetadataModal.documentTypeId,
    languageList: documentTypeMetadataModal.languageList,
    combos: {
      documentTypess: state.combos.documentTypes
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      submitDocumentTypeMetadata,
      closeMetadataMasterModal
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(MetadataMasterModal))
