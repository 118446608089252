import apiFetch from '../apiFetch'

export default function (token, finderParams) {
  finderParams.stageId = finderParams.stageId.toString()
  return apiFetch({
    endPoint: 'DossierFinder/DossierServicePreparationDownload',
    method: 'POST',
    body: finderParams,
    token: token,
    parseBlobAndAssign: true
  })
}
