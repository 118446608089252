
import React, { PureComponent } from 'react'
import { reduxForm } from 'redux-form'
import { Row, Col, Table, Button, Tooltip, OverlayTrigger } from 'react-bootstrap'
import IDocCarPagination from '../../../commons/iDocCarPagination'
import ProcessingAgencyIntegrationDocTypeUsesMasterModalPage from '../../../../containers/masters/ProcessingAgency/processingAgencyIntegrations/ProcessingAgencyIntegrationDocTypeUsesMasterModalPage'

class ProcessingAgencyIntegrationDocTypeUsesMaster extends PureComponent {
  constructor () {
    super()
    this.state = {
      initialized: false,
      filter: {
      }
    }
  }

  getSortIcon (column = '') {
    switch (this.props.filter.orderBy) {
      case '+' + column:
        return 'ico-sort-selected-asc'
      case '-' + column:
        return 'ico-sort-selected-desc'
      default:
        return 'ico-sort'
    }
  }

  componentDidMount () {
  }

  changeOrder (field) {
    switch (this.props.filter.orderBy) {
      case `+${field}`:
        this.props.actions.fetchProcessingAgencyDocTypeUsesList(this.props.agency.processingAgencyId, { ...this.state.filter, ...this.props.filter, orderBy: `-${field}` }, this.props.isReturnDocument)
        break
      default:
        this.props.actions.fetchProcessingAgencyDocTypeUsesList(this.props.agency.processingAgencyId, { ...this.state.filter, ...this.props.filter, orderBy: `+${field}` }, this.props.isReturnDocument)
        break
    }
  }

  render () {
    const {
      t, processingAgencyDocTypeUses, pagesCount, filter, processingAgencyDocTypeUsesCount,
      filter: { page },
      actions: { showProcessingAgencyDocTypeUsesModal },
      isReturnDocument
    } = this.props
    const tKey = 'MASTERS.PROCESSING_AGENCY_MASTERS.DOC_TYPE_USES.'
    const editTooltip = (<Tooltip id="editTooltip">{t(`${tKey}EDIT`)}</Tooltip>)
    const deleteTooltip = (<Tooltip id="deleteTooltip">{t(`${tKey}DELETE`)}</Tooltip>)
    return (
      <Col className="admin-wrapper masters-process-agency-padding">
        <ProcessingAgencyIntegrationDocTypeUsesMasterModalPage isReturnDocument={this.props.isReturnDocument}/>
        <Row className="section-users-header">
          <Col sm={12}>
            <div className="header-panel">
              <h4>{t(`${tKey}DOC_TYPE_USE`)}</h4>
            </div>
          </Col>
        </Row>
        <Table className="editable-table" hover responsive>
          <thead>
            <tr>
              <th onClick={() => { this.changeOrder('documentTypeUseLocale') }}>
                {t(`${tKey}DOC_TYPE_USE`)}
                <i className={this.getSortIcon('documentTypeUseLocale')} />
              </th>
              <th onClick={() => { this.changeOrder('documentCode') }}>
                {t(`${tKey}PROCESSING_AGENCY_CODE`)}
                <i className={this.getSortIcon('documentCode')} />
              </th>
              <th onClick={() => { this.changeOrder('paperworkTypeLocale') }}>
                {t(`${tKey}PAPERWORK_TYPE`)}
                <i className={this.getSortIcon('paperworkTypeLocale')} />
              </th>
              <th style={{ width: '77px' }}>{t('DOSSIER_COMPONENTS.ACCESSORIES.ACTIONS')}</th>
            </tr>
          </thead>
          <tbody>
            {processingAgencyDocTypeUses && processingAgencyDocTypeUses.map((processingAgencyDocTypeUse, idx) => {
              return (
                <tr key={idx} className={processingAgencyDocTypeUse.isDeleted ? 'is-deleted' : ''}>
                  <td>{processingAgencyDocTypeUse.documentTypeUseLocale}</td>
                  <td>{processingAgencyDocTypeUse.documentCode}</td>
                  <td>{processingAgencyDocTypeUse.paperworkTypeLocale}</td>
                  <td>
                    {!processingAgencyDocTypeUse.isDeleted && (
                      <div>
                        <a onClick={() => showProcessingAgencyDocTypeUsesModal(this.props.agency.processingAgencyId, true, processingAgencyDocTypeUse, processingAgencyDocTypeUse.isReturnDocument)}>
                          <OverlayTrigger placement="left" overlay={editTooltip}>
                            <i className="ico-edit-white" />
                          </OverlayTrigger>
                        </a>
                        <a onClick={() => this.props.actions.deleteProcessingAgencyDocTypeUses(this.props.agency.processingAgencyId, processingAgencyDocTypeUse.processingAgencyDocTypeUseId, processingAgencyDocTypeUse.isReturnDocument)}>
                          <OverlayTrigger placement="left" overlay={deleteTooltip}>
                            <i className="ico-trash" />
                          </OverlayTrigger>
                        </a>
                      </div>
                    )}
                  </td>
                </tr>)
            })
            }
            {(processingAgencyDocTypeUses === null || processingAgencyDocTypeUses === undefined || processingAgencyDocTypeUses.length === 0) &&
          <tr>
            <td colSpan={10}><h2>{t(`${tKey}RESULTS_NOT_FOUND`)}</h2></td>
          </tr>}
            <tr className="button-table-wrapper">
              <td>
                <Button
                  className="btn-editable-table btn-standard"
                  onClick={() => showProcessingAgencyDocTypeUsesModal(this.props.agency.processingAgencyId, true, null, isReturnDocument)}
                >
                  {t(`${tKey}ADD`)}
                </Button>
              </td>
            </tr>
          </tbody>
        </Table>
        <div className="section-users-footer">
          <nav key={1} className="text-center">
            <IDocCarPagination
              id="btn_pag_sales"
              page={page}
              pagesCount={pagesCount}
              totalRows={processingAgencyDocTypeUsesCount}
              onSelectAction={(value) => this.props.actions.fetchProcessingAgencyDocTypeUsesList(this.props.agency.processingAgencyId, { ...filter, ...this.state.filter, page: value }, this.props.isReturnDocument)}
            />
          </nav>
        </div>
      </Col>
    )
  }
}

export default reduxForm({
  form: 'editProcessingAgencyIntegrationDocTypeUsesMaster',
  validate: (values, props) => {
  }
})(ProcessingAgencyIntegrationDocTypeUsesMaster)
