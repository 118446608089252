import React, { PureComponent } from 'react'
import { reduxForm, Field } from 'redux-form'
import { Modal, Row, Col, FormGroup, Button, Glyphicon, Tooltip, OverlayTrigger } from 'react-bootstrap'
import InputSelect from '../commons/form/InputSelect'
import InputNatural from '../commons/form/InputNatural'
import TextInput from '../commons/TextInput'
import InputCheckBox from '../commons/form/InputCheckBox'
import { conditionActionConst, conditionTypes, showFieldType, functionToPassTypeView } from '../../constants/backendIds'

class ConditionsMaintenanceModal extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      hasIco: false,
      nonEditableField: true,
      selectedShowFieldType: -1,
      functionToPassDescription: null,
      functionToAddDescription: null,
      functionToPassCombo: null
    }
    this.required = this.required.bind(this)
  }

  handleFunctionToAddChange (functionToAdd, isInit) {
    const selectedFunctionToAdd = this.props.combos.functionToAddCombo.find(fToAdd => fToAdd.functionName === functionToAdd)
    this.setState({
      selectedShowFieldType: selectedFunctionToAdd ? selectedFunctionToAdd.showFieldType : -1,
      functionToAddDescription: selectedFunctionToAdd ? selectedFunctionToAdd.description : null
    })
    if (!isInit) {
      this.props.change('saleTypeList', null)
      this.props.change('operationTypeList', null)
      this.props.change('purchaseTypeList', null)
      this.props.change('paperworkTypeList', null)
      this.props.change('additionalInfoList', null)
      this.props.change('additionalInfoList', null)
      this.props.change('paymentMethodList', null)
      this.props.change('maritalStatusList', null)
      this.props.change('brandList', null)
      this.props.change('fuelList', null)
    }
  }

  handleFunctionToPassChange (functionToPass) {
    const selectedFunctionToPass = this.props.combos.functionToPassCombo.find(fToPass => fToPass.functionName === functionToPass)
    this.setState({
      functionToPassDescription: selectedFunctionToPass ? selectedFunctionToPass.description : null,
      typeView: selectedFunctionToPass ? selectedFunctionToPass.typeView : functionToPassTypeView.total
    })
  }

  UNSAFE_componentWillMount () {
    this.props.actions.fetchLanguagesCombo()
    this.props.actions.fetchDocumentTypeUseCombo()
    this.props.actions.fetchDocumentStatusCombo()
    this.props.actions.fetchOrganizedUnitsCombo()
    this.props.actions.fetchOperationType(null, null, true)
    this.props.actions.fetchFunctionToPass()
    this.props.actions.fetchPaymentCombo()
    this.props.actions.fetchEntityMaritalStatusCombo()
    this.props.actions.fetchBrands(null, null, true)
    this.props.actions.fetchFuelCombo(true)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (!nextProps.conditionsMaintenanceModal.showModal && this.props.conditionsMaintenanceModal.showModal !== nextProps.conditionsMaintenanceModal.showModal) {
      this.props.initialize({})
      this.props.reset()
      this.setState({
        hasIco: false,
        nonEditableField: true,
        selectedShowFieldType: -1,
        functionToPassDescription: null,
        functionToAddDescription: null
      })
    }

    if (nextProps.conditionsMaintenanceModal.showModal && this.props.conditionsMaintenanceModal.showModal !== nextProps.conditionsMaintenanceModal.showModal) {
      new Promise((resolve, reject) => {
        this.props.actions.fetchFunctionToAdd(nextProps.conditionsMaintenanceModal && !nextProps.conditionsMaintenanceModal.isConditionDocumental ? conditionTypes.conditionNoDocumental : conditionTypes.conditionDocumental, resolve)
      }).then(() => {
        if (nextProps.conditionsMaintenanceModal.data) {
          const conditionData = nextProps.conditionsMaintenanceModal.data
          if (nextProps.conditionsMaintenanceModal.isConditionDocumental) {
            this.props.initialize(
              {
                ...conditionData
              })
          } else {
            const conditionLanguages = nextProps.combos.languagesCombo.reduce((val, nextVal) => {
              const condition = (conditionData.conditionNoDocumentalLanguages.find(condition => nextVal.languageId === condition.languageId)) || {}
              val[`lang${nextVal.languageId}`] = {
                name: condition.name,
                description: condition.description,
                languageId: nextVal.languageId
              }
              return val
            }, {})
            this.props.initialize(
              {
                ...conditionData,
                ...conditionLanguages
              })
          }
          this.handleFunctionToAddChange(conditionData.functionToAdd, true)
          this.handleFunctionToPassChange(conditionData.functionToPass)

          let conditionTypeView = functionToPassTypeView.total

          if (conditionData.functionToPass) {
            const functionToPass = this.props.combos.functionToPassCombo.find(fToPass => fToPass.functionName === conditionData.functionToPass)
            if (functionToPass) {
              conditionTypeView = functionToPass.typeView
            }
          }
          this.setState({ functionToPassCombo: this.props.combos.functionToPassCombo.filter(f => f.typeView <= conditionTypeView), typeView: conditionTypeView })
        } else {
          this.setState({ functionToPassCombo: this.props.combos.functionToPassCombo.filter(f => f.typeView <= functionToPassTypeView.total), typeView: functionToPassTypeView.total })
          const conditionLanguages = nextProps.combos.languagesCombo.reduce((val, nextVal) => {
            val[`lang${nextVal.languageId}`] = {
              name: '',
              description: '',
              languageId: nextVal.languageId
            }
            return val
          }, {})
          this.props.initialize({ ...conditionLanguages })
        }
      })
    }
  }

  submitCondition (values) {
    let body = values
    if (!body.dossierStageId) {
      body = { ...body, dossierStageId: this.props.conditionsMaintenanceModal.activeStage }
    }

    if (!this.props.conditionsMaintenanceModal.isConditionDocumental) {
      if (!body.conditionNoDocumentalType) {
        body = { ...body, conditionNoDocumentalType: this.props.conditionsMaintenanceModal.conditionNoDocumentalType }
      }

      const conditionNoDocumentalLanguages = []
      Object.keys(body).map(item => {
        if (item.indexOf('lang') >= 0) {
          conditionNoDocumentalLanguages.push({ ...body[item] })
          delete body[item]
        }
      })
      if (body.blockerChangerStage === undefined || body.blockerChangerStage === null) {
        body.blockerChangerStage = false
      }
      if (body.conditionAction === undefined || body.conditionAction === null) {
        body.conditionAction = conditionActionConst.noAction
      }
      body = { ...body, conditionNoDocumentalLanguages }
    }
    this.props.actions.saveConditionsModal(this.props.conditionsMaintenanceModal.isConditionDocumental, this.props.conditionsMaintenanceModal.conditionId, body)
  }

  functionToAddPassRenderLabel (tooltipId, labelText, tooltipText) {
    const tooltip = (
      <Tooltip id={tooltipId}>
        <p>{tooltipText}</p>
      </Tooltip>
    )
    return (
      <div>
        {labelText}
        {tooltipText && tooltipText !== '' && <OverlayTrigger placement={tooltipText.length > 150 ? 'bottom' : 'top'} overlay={tooltip}>
          <Glyphicon glyph="info-sign" className="tooltip-function"></Glyphicon>
        </OverlayTrigger>}
      </div>)
  }

  required (value) {
    if (value || value === 0) {
      return undefined
    } else {
      return this.props.t('GLOBAL.FORMS.REQUIRED')
    }
  }

  render () {
    const {
      t, handleSubmit,
      pristine, submitting,
      conditionsMaintenanceModal: { showModal, isConditionDocumental, data },
      combos: {
        UOCombo, saleTypeCombo, purchaseTypeCombo, paperworkTypes, operationTypeModalCombo,
        functionToAddCombo, languagesCombo, documentStatusCombo, documentTypeUseCombo, dossierDestinationCombo,
        additionalInfoCombo, marginTypesCombo, maritalStatusCombo, brandCombo, fuelCombo
      }, paymentMethodCombo,
      actions: { closeAddOrEditConditionsModal }
    } = this.props

    if (!showModal) return null

    const filteredBrandCombo = brandCombo?.filter(brand => data?.brandList?.includes(brand.id) || brand.active)
    const filteredFuelCombo = fuelCombo?.filter(fuel => data?.fuelList?.includes(fuel.id) || fuel.active)

    const tKey = 'CONDITIONS_MAINTENANCE.MODAL.'
    return (
      <Modal show={showModal} bsSize="large" className="languages-modal conditions-maintenance-modal" onHide={closeAddOrEditConditionsModal}>
        <form autoComplete="off" onSubmit={handleSubmit(this.submitCondition.bind(this))}>
          <Modal.Header closeButton onHide={closeAddOrEditConditionsModal} className="modal-header-container" >
            <Modal.Title>{t(`${tKey}TITLE`)}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {isConditionDocumental ? [
              <h5 key="title" className="modal-subtitle">{t(`${tKey}DOCUMENTAL_CONDITION_ZONE.TITLE`)}</h5>,
              <Row key="form" >
                <Field
                  colSm={6}
                  id="documentTypeUseId"
                  name='documentTypeUseId'
                  placeholder={t(`${tKey}DOCUMENTAL_CONDITION_ZONE.USE_TYPE`)}
                  controlLabel={t(`${tKey}DOCUMENTAL_CONDITION_ZONE.USE_TYPE`)}
                  component={InputSelect}
                  options={documentTypeUseCombo}
                  valueKey="id"
                  labelKey="value"
                  validate={[this.required]}
                />
                <Field
                  colSm={6}
                  id="documentStatusFinalId"
                  name='documentStatusFinalId'
                  placeholder={t(`${tKey}DOCUMENTAL_CONDITION_ZONE.DOCUMENT_STATUS`)}
                  controlLabel={t(`${tKey}DOCUMENTAL_CONDITION_ZONE.DOCUMENT_STATUS`)}
                  component={InputSelect}
                  options={documentStatusCombo}
                  valueKey="id"
                  labelKey="value"
                  validate={[this.required]}
                />
              </Row>
            ] : [
              <h5 key="title" className="modal-subtitle">{t(`${tKey}DATA_CONDITION_ZONE.TITLE`)}</h5>,
              <Row key="header-language-form" className="title-column">
                <Col sm={4}>
                  <h2>{t('MASTERS.NAME')}</h2>
                </Col>
                <Col sm={6}>
                  <h2>{t('MASTERS.DESCRIPTION')}</h2>
                </Col>
                <Col sm={2}>
                  <h2>{t('MASTERS.LANGUAGE')}</h2>
                </Col>
              </Row>,
              <FormGroup key="body-language-form">
                {languagesCombo.map((val, idx) => {
                  return (
                    <Row key={idx} className="inputs-wrapper">
                      <Col sm={4} className="custom-width-col">
                        <Field
                          name={`lang${val.languageId}.name`}
                          placeholder={t(`${tKey}DATA_CONDITION_ZONE.NAME`)}
                          component={TextInput}
                          validate={[this.required]}
                        />
                      </Col>
                      <Col sm={6} className="custom-width-col">
                        <Field
                          name={`lang${val.languageId}.description`}
                          placeholder={t(`${tKey}DATA_CONDITION_ZONE.DESCRIPTION`)}
                          component={TextInput}
                          validate={[this.required]}
                        />
                      </Col>
                      <Col sm={2} className="language-column">
                        <p>{`${val.description} (${val.name})`}</p>
                      </Col>
                    </Row>
                  )
                })}
              </FormGroup>,
              <Row key="form">
                <Field
                  colSm={6}
                  id="conditionAction"
                  name='conditionAction'
                  placeholder={t(`${tKey}DATA_CONDITION_ZONE.DOSSIER_DESTINATION`)}
                  controlLabel={t(`${tKey}DATA_CONDITION_ZONE.DOSSIER_DESTINATION`)}
                  component={InputSelect}
                  options={dossierDestinationCombo}
                  valueKey="id"
                  labelKey="value"
                  validate={[this.required]}
                />
                <Col sm={6}>
                  <Field
                    id="changerStageLevel"
                    name="changerStageLevel"
                    controlLabel={t(`${tKey}DATA_CONDITION_ZONE.BLOCKER_LEVEL_STAGE`)}
                    placeholder="0"
                    component={InputNatural}
                    colSm={12}
                    disabled={this.state.typeView > functionToPassTypeView.total}
                  />
                </Col>
              </Row>
            ]}
            <Row>
              {isConditionDocumental && <Field
                id="changerStageLevel"
                name="changerStageLevel"
                controlLabel={t(`${tKey}DATA_CONDITION_ZONE.BLOCKER_LEVEL_STAGE`)}
                placeholder="0"
                component={InputNatural}
                colSm={6}
                disabled={this.state.typeView > functionToPassTypeView.total}
              />}
              <Field
                colSm={6}
                id="functionToAdd"
                name='functionToAdd'
                placeholder={t(`${tKey}DATA_CONDITION_ZONE.FUNCTION_TO_ADD`)}
                controlLabel={this.functionToAddPassRenderLabel('tooltipToAdd', t(`${tKey}DATA_CONDITION_ZONE.FUNCTION_TO_ADD`), this.state.functionToAddDescription)}
                component={InputSelect}
                options={functionToAddCombo}
                valueKey="functionName"
                labelKey="name"
                onInputChange={(value) => this.handleFunctionToAddChange(value)}
                disabled={this.state.typeView > functionToPassTypeView.total}
              />
              {!isConditionDocumental && <Field
                colSm={6}
                id="functionToPass"
                name='functionToPass'
                placeholder={t(`${tKey}DATA_CONDITION_ZONE.FUNCTION_TO_PASS`)}
                controlLabel={this.functionToAddPassRenderLabel('tooltipToPass', t(`${tKey}DATA_CONDITION_ZONE.FUNCTION_TO_PASS`), this.state.functionToPassDescription)}
                component={InputSelect}
                options={this.state.functionToPassCombo}
                valueKey="functionName"
                labelKey="name"
                validate={[this.required]}
                onInputChange={(value) => this.handleFunctionToPassChange(value)}
                disabled={this.state.typeView > functionToPassTypeView.total}
              />}
              <Field
                id="organizedUnitId"
                name="organizedUnitId"
                key="organizedUnitId"
                controlLabel={t(`${tKey}UO`)}
                placeholder={t(`${tKey}UO`)}
                component={InputSelect}
                multi={true}
                valueKey="id"
                labelKey="value"
                options={UOCombo}
                colSm={6}
                disabled={this.state.typeView > functionToPassTypeView.total}
              />
              <Col sm={12} className="col-row input-group-row mBottom">
                <Field
                  id="preCanceled"
                  name="preCanceled"
                  placeholder={t(`${tKey}PRECANCELLED`)}
                  component={InputCheckBox}
                  colSm={6}
                  disabled={this.state.typeView > functionToPassTypeView.total}
                />
                <Field
                  id="excludeInExternalDossier "
                  name="excludeInExternalDossier"
                  placeholder={t(`${tKey}EXCLUDE_IN_EXTERNAL_DOSSIER`)}
                  component={InputCheckBox}
                  colSm={6}
                  disabled={this.state.typeView > functionToPassTypeView.total}
                />
              </Col>
            </Row>
            <Row>
              {this.state.selectedShowFieldType === showFieldType.additionalInfoField ? ([
                <Field
                  colSm={4}
                  id="saleTypeList"
                  name="saleTypeList"
                  key="saleTypeList"
                  controlLabel={t(`${tKey}DATA_CONDITION_ZONE.SALES_TYPE`)}
                  placeholder={t(`${tKey}DATA_CONDITION_ZONE.SALES_TYPE`)}
                  component={InputSelect}
                  options={saleTypeCombo}
                  valueKey="id"
                  labelKey="value"
                  multi
                  disabled={this.state.typeView > functionToPassTypeView.total}
                />,
                <Field
                  colSm={4}
                  id="additionalInfoList"
                  name="additionalInfoList"
                  key="additionalInfoList"
                  controlLabel={t(`${tKey}ADDITIONAL_INFO`)}
                  placeholder={t(`${tKey}ADDITIONAL_INFO`)}
                  component={InputSelect}
                  options={additionalInfoCombo}
                  valueKey="id"
                  labelKey="value"
                  multi
                  disabled={this.state.typeView > functionToPassTypeView.total}
                />
              ]) : this.state.selectedShowFieldType === showFieldType.salePaperworkField ? ([
                <Field
                  colSm={4}
                  id="saleTypeList"
                  name="saleTypeList"
                  key="saleTypeList"
                  controlLabel={t(`${tKey}DATA_CONDITION_ZONE.SALES_TYPE`)}
                  placeholder={t(`${tKey}DATA_CONDITION_ZONE.SALES_TYPE`)}
                  component={InputSelect}
                  options={saleTypeCombo}
                  valueKey="id"
                  labelKey="value"
                  multi
                  disabled={this.state.typeView > functionToPassTypeView.total}
                />,
                <Field
                  colSm={4}
                  id="paperworkTypeList"
                  name="paperworkTypeList"
                  key="paperworkTypeList"
                  controlLabel={t(`${tKey}DATA_CONDITION_ZONE.PAPERWORK_TYPE`)}
                  placeholder={t(`${tKey}DATA_CONDITION_ZONE.PAPERWORK_TYPE`)}
                  component={InputSelect}
                  options={paperworkTypes}
                  valueKey="id"
                  labelKey="value"
                  multi
                  disabled={this.state.typeView > functionToPassTypeView.total}
                />
              ]) : this.state.selectedShowFieldType === showFieldType.saleField ? ([
                <Field
                  colSm={4}
                  id="saleTypeList"
                  name="saleTypeList"
                  key="saleTypeList"
                  controlLabel={t(`${tKey}DATA_CONDITION_ZONE.SALES_TYPE`)}
                  placeholder={t(`${tKey}DATA_CONDITION_ZONE.SALES_TYPE`)}
                  component={InputSelect}
                  options={saleTypeCombo}
                  valueKey="id"
                  labelKey="value"
                  multi
                  disabled={this.state.typeView > functionToPassTypeView.total}
                />,
                <Field
                  colSm={4}
                  id="operationTypeList"
                  name="operationTypeList"
                  key="operationTypeList"
                  controlLabel={t(`${tKey}DATA_CONDITION_ZONE.OPERATION_TYPE`)}
                  placeholder={t(`${tKey}DATA_CONDITION_ZONE.OPERATION_TYPE`)}
                  component={InputSelect}
                  options={operationTypeModalCombo}
                  valueKey="id"
                  labelKey="value"
                  multi
                  disabled={!operationTypeModalCombo || operationTypeModalCombo.length === 0 || this.state.typeView > functionToPassTypeView.total}
                />
              ]) : this.state.selectedShowFieldType === showFieldType.purchaseField ? (
                <Field
                  colSm={4}
                  id="purchaseTypeList"
                  name="purchaseTypeList"
                  key="purchaseTypeList"
                  controlLabel={t(`${tKey}DATA_CONDITION_ZONE.PURCHASE_TYPE`)}
                  placeholder={t(`${tKey}DATA_CONDITION_ZONE.PURCHASE_TYPE`)}
                  component={InputSelect}
                  options={purchaseTypeCombo}
                  valueKey="id"
                  labelKey="value"
                  multi
                  disabled={this.state.typeView > functionToPassTypeView.total}
                />
              ) : this.state.selectedShowFieldType === showFieldType.paperworkField ? (
                <Field
                  colSm={4}
                  id="paperworkTypeList"
                  name="paperworkTypeList"
                  key="paperworkTypeList"
                  controlLabel={t(`${tKey}DATA_CONDITION_ZONE.PAPERWORK_TYPE`)}
                  placeholder={t(`${tKey}DATA_CONDITION_ZONE.PAPERWORK_TYPE`)}
                  component={InputSelect}
                  options={paperworkTypes}
                  valueKey="id"
                  labelKey="value"
                  multi
                  disabled={this.state.typeView > functionToPassTypeView.total}
                />
              ) : this.state.selectedShowFieldType === showFieldType.marginField ? (
                <Field
                  colSm={4}
                  id="marginTypeList"
                  name="marginTypeList"
                  key="marginTypeList"
                  controlLabel={t(`${tKey}DATA_CONDITION_ZONE.MARGIN_TYPE`)}
                  placeholder={t(`${tKey}DATA_CONDITION_ZONE.MARGIN_TYPE`)}
                  component={InputSelect}
                  options={marginTypesCombo}
                  valueKey="id"
                  labelKey="value"
                  multi
                  disabled={this.state.typeView > functionToPassTypeView.total}
                />
              ) : this.state.selectedShowFieldType === showFieldType.paymentMethodField ? ([
                <Field
                  colSm={4}
                  id="saleTypeList"
                  name="saleTypeList"
                  key="saleTypeList"
                  controlLabel={t(`${tKey}DATA_CONDITION_ZONE.SALES_TYPE`)}
                  placeholder={t(`${tKey}DATA_CONDITION_ZONE.SALES_TYPE`)}
                  component={InputSelect}
                  options={saleTypeCombo}
                  valueKey="id"
                  labelKey="value"
                  multi
                  disabled={this.state.typeView > functionToPassTypeView.total}
                />,
                <Field
                  colSm={4}
                  id="operationTypeList"
                  name="operationTypeList"
                  key="operationTypeList"
                  controlLabel={t(`${tKey}DATA_CONDITION_ZONE.OPERATION_TYPE`)}
                  placeholder={t(`${tKey}DATA_CONDITION_ZONE.OPERATION_TYPE`)}
                  component={InputSelect}
                  options={operationTypeModalCombo}
                  valueKey="id"
                  labelKey="value"
                  multi
                  disabled={!operationTypeModalCombo || operationTypeModalCombo.length === 0 || this.state.typeView > functionToPassTypeView.total}
                />,
                <Field
                  colSm={3}
                  id="paymentMethodList"
                  name="paymentMethodList"
                  key="paymentMethodList"
                  controlLabel={t(`${tKey}DATA_CONDITION_ZONE.PAYMENTMETHOD_TYPE`)}
                  placeholder={t(`${tKey}DATA_CONDITION_ZONE.PAYMENTMETHOD_TYPE`)}
                  component={InputSelect}
                  options={paymentMethodCombo}
                  valueKey="id"
                  labelKey="value"
                  multi
                  disabled={this.state.typeView > functionToPassTypeView.total}
                />
              ]) : this.state.selectedShowFieldType === showFieldType.paperworkPurchaseType && ([
                <Field
                  colSm={4}
                  id="purchaseTypeList"
                  name="purchaseTypeList"
                  key="purchaseTypeList"
                  controlLabel={t(`${tKey}DATA_CONDITION_ZONE.PURCHASE_TYPE`)}
                  placeholder={t(`${tKey}DATA_CONDITION_ZONE.PURCHASE_TYPE`)}
                  component={InputSelect}
                  options={purchaseTypeCombo}
                  valueKey="id"
                  labelKey="value"
                  multi
                  disabled={this.state.typeView > functionToPassTypeView.total}
                />,
                <Field
                  colSm={4}
                  id="paperworkTypeList"
                  name="paperworkTypeList"
                  key="paperworkTypeList"
                  controlLabel={t(`${tKey}DATA_CONDITION_ZONE.PAPERWORK_TYPE`)}
                  placeholder={t(`${tKey}DATA_CONDITION_ZONE.PAPERWORK_TYPE`)}
                  component={InputSelect}
                  options={paperworkTypes}
                  valueKey="id"
                  labelKey="value"
                  multi
                  disabled={this.state.typeView > functionToPassTypeView.total}
                />
              ])}
              {this.state.selectedShowFieldType === showFieldType.maritalStatus &&
                <Field
                  colSm={4}
                  id="maritalStatusList"
                  name="maritalStatusList"
                  key="maritalStatusList"
                  controlLabel={t(`${tKey}DATA_CONDITION_ZONE.MARITALSTATUS_TYPE`)}
                  placeholder={t(`${tKey}DATA_CONDITION_ZONE.MARITALSTATUS_TYPE`)}
                  component={InputSelect}
                  options={maritalStatusCombo}
                  valueKey="id"
                  labelKey="value"
                  multi
                  disabled={this.state.typeView > functionToPassTypeView.total}
                />
              }
              {this.state.selectedShowFieldType === showFieldType.brandAndFuel && ([
                <Field
                  colSm={4}
                  id="brandList"
                  name="brandList"
                  key="brandList"
                  controlLabel={t(`${tKey}DATA_CONDITION_ZONE.BRAND`)}
                  placeholder={t(`${tKey}DATA_CONDITION_ZONE.BRAND`)}
                  component={InputSelect}
                  options={filteredBrandCombo}
                  valueKey="id"
                  labelKey="value"
                  multi
                  disabled={this.state.typeView > functionToPassTypeView.total}
                />,
                <Field
                  colSm={4}
                  id="fuelList"
                  name="fuelList"
                  key="fuelList"
                  controlLabel={t(`${tKey}DATA_CONDITION_ZONE.FUEL`)}
                  placeholder={t(`${tKey}DATA_CONDITION_ZONE.FUEL`)}
                  component={InputSelect}
                  options={filteredFuelCombo}
                  valueKey="id"
                  labelKey="value"
                  multi
                  disabled={this.state.typeView > functionToPassTypeView.total}
                />])
              }
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <div className="btn-wrapper">
              <Button id="btn_save" className="btn-standard" type="submit" disabled={pristine || submitting}>
                <span>{t(`${tKey}SAVE_BUTTON`)}</span>
              </Button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default reduxForm({
  form: 'conditionsMaintenanceModal'
})(ConditionsMaintenanceModal)
