import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import SearchByDossierForm from '../../components/search/SearchByDossierForm'
import { translate } from 'react-polyglot'
import {
  fetchDossierSubTypes, fetchStagesCombo, fetchLowestOrganizedUnits, fetchSearchDossierSubType, fetchUserCombo,
  fetchOperationType, fetchSaleTypeBySubtype, fetchPurchaseType, fetchStockStatusCombo, fetchPaymentMethodCombo,
  fetchOperationTypeDMS
} from '../../../actions/combos/combos'
import { fetchDossiers, fetchDossiersDownload, setFilters, setHideFilters } from '../../../actions/search/search'
import { openDownloadModal } from '../../../actions/search/download'
import { openModal } from '../../../actions/common'

export function mapStateToProps (state) {
  return {
    ...state.search,
    combos: {
      dossierSubtypesCombo: state.combos.dossierSubTypesCombo,
      stagesCombo: state.combos.stagesCombo,
      lowestOrganizedUnitsCombo: state.combos.lowestOrganizedUnitsCombo,
      salesmanCombo: state.combos.salesmanCombo,
      searchDossierSubtypesCombo: state.combos.searchDossierSubtypesCombo,
      usersCombo: state.combos.usersCombo,
      purchaseTypeCombo: state.combos.purchaseTypeCombo,
      saleTypeCombo: state.combos.saleTypeCombo,
      operationTypeCombo: state.combos.operationTypeCombo,
      stockStatusCombo: state.combos.stockStatusCombo,
      paymentMethodCombo: state.combos.paymentMethodCombo,
      operationTypeDMSCombo: state.combos.operationTypeDMSCombo
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchDossierSubTypes,
      fetchStagesCombo,
      fetchLowestOrganizedUnits,
      fetchSearchDossierSubType,
      fetchUserCombo,
      fetchOperationType,
      fetchSaleTypeBySubtype,
      fetchPurchaseType,
      fetchStockStatusCombo,
      fetchPaymentMethodCombo,
      fetchOperationTypeDMS,
      openDownloadModal,
      openModal,
      setFilters,
      fetchDossiers,
      fetchDossiersDownload,
      setHideFilters
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(SearchByDossierForm))
