import { put, call, select, takeEvery } from 'redux-saga/effects'
import { showLoader, hideLoader } from '../../../../actions/common.js'
import mastersActionTypes from '../../../../constants/actions/masters/masters'
import { getAuth } from '../../../../selectors/access/auth'
import { handleError } from '../../../errors/errorManager'
import { getProcessingAgencyRegistrationTypesFilter } from '../../../../selectors/masters/processingAgency'

import { fetchProcessingAgencyRegistrationTypesListSuccess, showProcessingAgencyRegistrationTypesModalSuccess } from '../../../../actions/masters/processingAgency/processingAgencyIntegrations/processingAgencyRegistrationTypes'
import postProcessingAgencyRegistrationTypesListService from '../../../../services/processingAgency/processingAgencyIntegrations/postProcessingAgencyRegistrationTypesList'
import postProcessingAgencyRegistrationTypesService from '../../../../services/processingAgency/processingAgencyIntegrations/postProcessingAgencyRegistrationTypes'
import putProcessingAgencyRegistrationTypesService from '../../../../services/processingAgency/processingAgencyIntegrations/putProcessingAgencyRegistrationTypes'
import deleteProcessingAgencyRegistrationTypesService from '../../../../services/processingAgency/processingAgencyIntegrations/deleteProcessingAgencyRegistrationTypes'
import { yesNoModal } from '../../../modalsListeners/yesNoModal'

export function * fetchProcessingAgencyRegistrationTypesList ({ filter, processingAgencyId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield filter = yield filter || select(getProcessingAgencyRegistrationTypesFilter)
    const processingAgencyRegistrationTypesList = yield call(postProcessingAgencyRegistrationTypesListService, auth.token, processingAgencyId, filter)
    yield put(fetchProcessingAgencyRegistrationTypesListSuccess(processingAgencyRegistrationTypesList, filter))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchProcessingAgencyRegistrationTypesList () {
  yield takeEvery(mastersActionTypes.FETCH_PROCESSING_AGENCY_REGISTRATION_TYPES_LIST, fetchProcessingAgencyRegistrationTypesList)
}

export function * showProcessingAgencyRegistrationTypesModal ({ show, processingAgencyRegistrationType, processingAgencyId }) {
  try {
    yield put(showLoader())
    yield put(showProcessingAgencyRegistrationTypesModalSuccess(processingAgencyId, show, processingAgencyRegistrationType))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchShowProcessingAgencyRegistrationTypesModal () {
  yield takeEvery(mastersActionTypes.SHOW_PROCESSING_AGENCY_REGISTRATION_TYPES_MODAL, showProcessingAgencyRegistrationTypesModal)
}

export function * submitProcessingAgencyRegistrationTypesModal ({ processingAgencyRegistrationType, processingAgencyId }) {
  try {
    const auth = yield select(getAuth)
    if (processingAgencyRegistrationType.ProcessingAgencyRegistrationTypeId) {
      const confirmed = yield call(yesNoModal, 'editProcessingAgencyRegistrationTypes')
      if (confirmed) {
        yield put(showLoader())
        yield call(putProcessingAgencyRegistrationTypesService, auth.token, processingAgencyId, processingAgencyRegistrationType.ProcessingAgencyRegistrationTypeId, processingAgencyRegistrationType)
      }
    } else {
      yield put(showLoader())
      yield call(postProcessingAgencyRegistrationTypesService, auth.token, processingAgencyId, processingAgencyRegistrationType)
    }
    yield put(showProcessingAgencyRegistrationTypesModalSuccess(false))
    const filter = yield filter || select(getProcessingAgencyRegistrationTypesFilter)
    const processingAgencyRegistrationTypesList = yield call(postProcessingAgencyRegistrationTypesListService, auth.token, processingAgencyId, filter)
    yield put(fetchProcessingAgencyRegistrationTypesListSuccess(processingAgencyRegistrationTypesList, filter))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSubmitProcessingAgencyRegistrationTypesModal () {
  yield takeEvery(mastersActionTypes.SUBMIT_PROCESSING_AGENCY_REGISTRATION_TYPES, submitProcessingAgencyRegistrationTypesModal)
}

export function * deleteProcessingAgencyRegistrationTypes ({ processingAgencyRegistrationTypesId, processingAgencyId }) {
  try {
    const confirmed = yield call(yesNoModal, 'deleteProcessingAgencyRegistrationTypes')
    if (confirmed) {
      yield put(showLoader())
      const auth = yield select(getAuth)
      yield call(deleteProcessingAgencyRegistrationTypesService, auth.token, processingAgencyId, processingAgencyRegistrationTypesId)
      const filter = yield filter || select(getProcessingAgencyRegistrationTypesFilter)
      const processingAgencyRegistrationTypesList = yield call(postProcessingAgencyRegistrationTypesListService, auth.token, processingAgencyId, filter)
      yield put(fetchProcessingAgencyRegistrationTypesListSuccess(processingAgencyRegistrationTypesList, filter))
      yield put(showProcessingAgencyRegistrationTypesModalSuccess(processingAgencyId, false))
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDeleteProcessingAgencyRegistrationTypes () {
  yield takeEvery(mastersActionTypes.DELETE_PROCESSING_AGENCY_REGISTRATION_TYPES, deleteProcessingAgencyRegistrationTypes)
}
