import SimpleInputText from '../../../commons/simpleForm/SimpleInputText'
import React, { Component } from 'react'
import InputText from '../../../commons/form/InputText'
import { Button, Modal, Col, Row } from 'react-bootstrap'
import { isEmail, validatePhone } from '../../../../util/validationFunctions'
import SimpleTablePage from '../../../../containers/commons/general/SimpleTablePage'

class SearchClientByPhoneMailModal extends Component {
  constructor (props) {
    super()
    this.state = {
      phone: '',
      email: '',
      data: [],
      search: false
    }
  }

  closeModal () {
    this.resetFilters()
    this.props.actions.closeModal()
  }

  fetchEntity (dniCif) {
    new Promise((resolve, reject) => {
      return this.props.actions.fetchClientByDni(dniCif, this.props.organizationUnitId, this.props.dossierType, resolve, reject)
    }).then(() => {
      this.props.actions.openCommonModal('entityLoadSuccessfully', () => this.closeModal(), null, null, null, null)
    })
  }

  handleClickLoadEntity (dniCif, fromDDBB) {
    if (fromDDBB) {
      this.props.actions.openCommonModal('entityExistsOnDataBase', () => this.fetchEntity(dniCif), null, null, null, null)
    } else {
      this.fetchEntity(dniCif)
    }
  }

  resetFilters () {
    this.setState({ phone: '', email: '', search: false, data: [] })
  }

  getClients () {
    if (!this.state.email && !this.state.phone) {
      return
    }
    if (this.state.phone && !validatePhone(this.state, null)) {
      return
    }
    if (this.state.email && !isEmail(this.state.email)) {
      return
    }
    new Promise((resolve, reject) => {
      return this.props.actions.fetchClientByPhoneEmail(this.props.organizationUnitId, this.state.phone, this.state.email, resolve)
    }).then((result) => {
      this.setState({ data: result, search: !!result })
    })
  }

  validate () {
    const error = { email: '', phone: '' }
    if (!this.state.email && !this.state.phone) {
      error.email = error.phone = this.props.t('DOSSIER_COMPONENTS.LOAD_ENTITY_MODAL.VALIDATION.FILL_FIELD')
    }
    if (this.state.email && !isEmail(this.state.email)) {
      error.email = this.props.t('DOSSIER_COMPONENTS.LOAD_ENTITY_MODAL.VALIDATION.VALID_EMAIL')
    }
    if (this.state.phone && !validatePhone(this.state, null)) {
      error.phone = this.props.t('DOSSIER_COMPONENTS.LOAD_ENTITY_MODAL.VALIDATION.VALID_PHONE')
    }
    return error
  }

  render () {
    const { t, showModal, actions: { closeModal } } = this.props

    const tKey = 'DOSSIER_COMPONENTS.LOAD_ENTITY_MODAL.'
    const hasFilters = this.state.email || this.setState.phone
    return (
      <Modal className="add-user-modal" show={showModal} onHide={() => {
        this.resetFilters()
        closeModal()
      }}>
        <Modal.Header closeButton>
          <Modal.Title>{t(`${tKey}TITLE`)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <SimpleInputText
              id="phone"
              name="phone"
              component={InputText}
              colSm={6}
              controlLabel={t(`${tKey}PHONE`)}
              onInputChange={phone => this.setState({ phone }) }
              value={this.state.phone}
              error={this.validate().phone}
              disabled={this.state.search}/>

            <SimpleInputText
              id="email"
              name="email"
              component={InputText}
              colSm={6}
              controlLabel={t(`${tKey}EMAIL`)}
              onInputChange={email => this.setState({ email })}
              value={this.state.email}
              error={this.validate().email}
              disabled={this.state.search}/>

          </Row>
          <Row>
            <Col sm={4} className="btn-wrapper btn-center">
              <Button id="btn_save" className="btn-standard" onClick={() => this.getClients()} >
                {t(`${tKey}SEARCH_BUTTON`)}
              </Button>
            </Col>
            <Col sm={4} className="btn-wrapper btn-center">
              <Button onClick={(e) => { if (hasFilters) this.resetFilters() }}
                className={'btn-white btn-icon' + (hasFilters ? ' btn-standard' : ' btn-cancel')}
                id="btn_reset"
              >
                <i className="ico-cancel" /> {t(`${tKey}RESET_BUTTON`)}
              </Button>
            </Col>
          </Row>
          <Row>
            {this.state.search &&
                <SimpleTablePage
                  columns={[
                    <th key={0} data-field="dni">
                      {t(`${tKey}TABLE.DNI`)}
                    </th>,
                    <th key={1} data-field="rfc">
                      {t(`${tKey}TABLE.RFC`)}
                    </th>,
                    <th key={2} data-field="nameSurname">
                      {t(`${tKey}TABLE.NAME_SURNAME`)}
                    </th>,
                    <th key={3} data-field="phone">
                      {t(`${tKey}TABLE.PHONE`)}
                    </th>,
                    <th key={4} data-field="email">
                      {t(`${tKey}TABLE.EMAIL`)}
                    </th>,
                    <th key={5} data-field="actions">
                      {t(`${tKey}TABLE.ACTIONS`)}
                    </th>
                  ]}
                  rows={this.state.data.map((row, idx) => (
                    <tr className={'green'} key={idx}>
                      <td>{ <span style={{ color: row.existsInDDBB ? 'green' : 'grey' }}>{row.dniCif}</span>}</td>
                      <td>{ row.rfc}</td>
                      <td>{ row.name + ', ' + row.surname1 || '' + ' ' + row.surname2 || ''}</td>
                      <td>{ row.phone}</td>
                      <td>{ row.email}</td>
                      <td>{ <Button
                        onClick={() => (this.handleClickLoadEntity(row.dniCif, row.existsInDDBB))}
                        bsStyle="default"
                        bsSize="small"
                      >
                        <i className="ico-if_Download_1031520"/>
                      </Button> }</td>
                    </tr>
                  ))}
                />
            }
          </Row>

        </Modal.Body>
      </Modal>
    )
  }
}
export default SearchClientByPhoneMailModal
