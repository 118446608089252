import dossiersListActionTypes from '../../../constants/actions/dashboard/dossiersList/dossiersList'

export function initialState () {
  return {
    dossiersData: {},
    page: 1,
    urgencyTypeId: null,
    orderBy: null
  }
}

function fetchDossiersListSuccess (state, { dossiersData, urgencyTypeId, page, orderBy }) {
  return {
    ...state,
    dossiersData,
    urgencyTypeId,
    page,
    orderBy
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case dossiersListActionTypes.FETCH_DOSSIERS_LIST_SUCCESS:
      return fetchDossiersListSuccess(state, action)
    default:
      return state
  }
}
