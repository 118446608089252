import React, { PureComponent } from 'react'
import { Modal, Col, Table, Button } from 'react-bootstrap'
import { reduxForm } from 'redux-form'
import IDocCarPagination from '../../commons/iDocCarPagination'
import moment from 'moment'
import { Link } from 'react-router-dom'

class UsageReportModal extends PureComponent {
  constructor () {
    super()
    this.state = {
      page: 1,
      orderBy: null
    }
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (prevProps.showModal !== this.props.showModal) {
      this.setState({ page: 1, orderBy: null })
    }
    return null
  }

  componentDidUpdate () {
  }

  fetchUsageInfo (value) {
    this.setState({ page: value })
    this.props.actions.fetchUsageInfo({ ...this.props.filter, ...this.props.modalInfo.filter, page: value })
  }

  downloadUsageInfo () {
    const columns = [
      this.props.t('REPORTS.USAGE.MODAL.NUMBER'),
      this.props.t('REPORTS.USAGE.MODAL.DATE'),
      this.props.t('REPORTS.USAGE.MODAL.CONCESSIONARY'),
      this.props.t('REPORTS.USAGE.MODAL.ORGANIZED_UNIT'),
      this.props.t('REPORTS.USAGE.MODAL.USER'),
      this.props.t('REPORTS.USAGE.MODAL.DOSSIER')
    ]
    this.props.actions.downloadUsageInfo({ ...this.props.filter, ...this.props.modalInfo.filter, columnNames: columns })
  }

  getIconClass (field) {
    if (this.props.modalInfo.filter && this.props.modalInfo.filter.orderBy.includes(field)) {
      return this.props.modalInfo.filter.orderBy.includes('-') ? 'ico-sort-selected-asc' : 'ico-sort-selected-desc'
    } else {
      return 'ico-sort'
    }
  }

  onOrderChange (e) {
    if (this.props.modalInfo.filter) {
      const newOrderBy = this.props.modalInfo.filter.orderBy.includes(`+${e.currentTarget.dataset.field}`) ? `-${e.currentTarget.dataset.field}` : `+${e.currentTarget.dataset.field}`
      this.props.actions.fetchUsageInfo({ ...this.props.filter, ...this.props.modalInfo.filter, orderBy: newOrderBy })
    }
  }

  render () {
    const {
      t, showModal,
      actions: { handleHide },
      modalInfo: { usageCount, pagesCount, usageDetailList }
    } = this.props
    const tKey = 'REPORTS.USAGE.MODAL.'
    const subtitle = t(`${tKey}SUB_TITLE`).replace('##AMOUNT##', usageCount)
    const title = usageDetailList && usageDetailList.length > 0 && usageDetailList[0].usageLogCodeName

    return (
      <Modal backdrop="static" className="massive-modal reports-modal" show={showModal} onHide={handleHide}>
        <form autoComplete="off" >
          <Modal.Header closeButton onHide={handleHide}>
            <Modal.Title>{title} <span style={{ color: 'black', fontSize: '10px' }}>{subtitle}</span> </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Col>
              <Button
                className={'btn-white btn-icon btn-input btn-white'}
                style={{ float: 'right', marginBottom: '10px' }}
                onClick={() => this.downloadUsageInfo()}>
                {t('REPORTS.DOWNLOAD')}
              </Button>
            </Col>
            <Col>
              <Table className="editable-table usage-report-modal" hover responsive>
                <thead>
                  <tr>
                    <th key={0} data-field="number" onClick={(event) => this.onOrderChange(event)}>
                      {t(`${tKey}NUMBER`)}&nbsp;<i className={this.getIconClass('number')}/>
                    </th>
                    <th key={1} data-field="date" onClick={(event) => this.onOrderChange(event)}>
                      {t(`${tKey}DATE`)}&nbsp;<i className={this.getIconClass('date')}/>
                    </th>
                    <th key={2} data-field="concessionary" onClick={(event) => this.onOrderChange(event)}>
                      {t(`${tKey}CONCESSIONARY`)}&nbsp;<i className={this.getIconClass('concessionary')}/>
                    </th>
                    <th key={3} data-field="organizedUnit" onClick={(event) => this.onOrderChange(event)}>
                      {t(`${tKey}ORGANIZED_UNIT`)}&nbsp;<i className={this.getIconClass('organizedUnit')}/>
                    </th>
                    <th key={4} data-field="user" onClick={(event) => this.onOrderChange(event)}>
                      {t(`${tKey}USER`)}&nbsp;<i className={this.getIconClass('user')}/>
                    </th>
                    <th key={5} data-field="dossier" onClick={(event) => this.onOrderChange(event)}>
                      {t(`${tKey}DOSSIER`)}&nbsp;<i className={this.getIconClass('dossier')}/>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {usageDetailList && usageDetailList.map((usage, idx) => {
                    return (
                      <tr key={idx} className = "">
                        <td>{usage.usageLogId}</td>
                        <td>{moment(usage.actionDateTime).format('DD/MM/YYYY hh:mm:ss')}</td>
                        <td>{usage.concessionaire}</td>
                        <td>{usage.organizedUnitName}</td>
                        <td>{usage.userName}</td>
                        <td>
                          <Link to={`/dossier${usage.urlToNavigate}`}>
                            {usage.dossierNumber}
                          </Link>
                        </td>
                      </tr>
                    )
                  })
                  }
                  {(usageDetailList === null || usageDetailList === undefined || usageDetailList.length === 0) &&
                  <tr>
                    <td colSpan={15}><h2>{t(`${tKey}RESULTS_NOT_FOUND`)}</h2></td>
                  </tr>}
                </tbody>
              </Table>
              <div className="section-users-footer">
                <nav key={1} className="text-center">
                  <IDocCarPagination
                    id="btn_pag_sales"
                    page={this.state.page}
                    pagesCount={pagesCount}
                    totalRows={usageCount}
                    onSelectAction={(value) => this.fetchUsageInfo(value)}
                  />
                </nav>
              </div>
            </Col>
          </Modal.Body>
          <Modal.Footer>
            <Col sm={12} className="btn-wrapper btn-cent">
              <Button
                id="role-modal-submit-button"
                className="btn-standard"
                onClick={handleHide}>
                {t(`${tKey}CLOSE`)}
              </Button>
            </Col>
          </Modal.Footer>
        </form>
      </Modal>

    )
  }
}

export default reduxForm({
  form: 'usageReportModal'
})(UsageReportModal)
