import deliveryActionTypes from '../../../constants/actions/dossier/common/delivery'

export function printDeliveryChecklist (dossierId, isSalesDossier) {
  return {
    type: deliveryActionTypes.PRINT_DELIVERY_CHECKLIST,
    dossierId,
    isSalesDossier
  }
}

export function printChecklistVO (dossierId) {
  return {
    type: deliveryActionTypes.PRINT_CHECKLIST_VO,
    dossierId
  }
}

export function printDeliveryChecklistSale (dossierId, deliveryCheckListSale) {
  return {
    type: deliveryActionTypes.PRINT_DELIVERY_CHECKLIST_SALE,
    dossierId,
    deliveryCheckListSale
  }
}

export function printDeliveryCertificates (dossierId) {
  return {
    type: deliveryActionTypes.PRINT_DELIVERY_CERTIFICATES,
    dossierId
  }
}

export default {
  printDeliveryChecklist,
  printChecklistVO,
  printDeliveryCertificates,
  printDeliveryChecklistSale
}
