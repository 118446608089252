import apiFetch from '../apiFetch'

export default function (dossierId, externalOrderId, ouId, action, token) {
  return apiFetch({
    endPoint: 'Dossier/CreateUpdateDossierToshiko',
    method: 'POST',
    body: {
      ouId: ouId,
      dossierId: dossierId,
      contract: externalOrderId,
      action: action
    },
    token: token
  })
}
