
import { getAuth } from '../../../selectors/access/auth'
import { handleError } from '../../errors/errorManager'
import { put, call, select, takeEvery } from 'redux-saga/effects'
import { showLoader, hideLoader } from '../../../actions/common.js'
import mastersActionTypes from '../../../constants/actions/masters/masters'
import { closeOrganizedUnitModal, fetchOrganizedUnitListSuccess, openOrganizedUnitModalSuccess } from '../../../actions/masters/masters'
import { yesNoModal } from '../../modalsListeners/yesNoModal'
import { initialize } from 'redux-form'
import getAllOrganizedUnitsFilter from '../../../services/organizedUnit/getAllOrganizedUnitsFilter'
import getOrganizedUnitById from '../../../services/organizedUnit/getOrganizedUnitById'
import postOrganizedUnit from '../../../services/organizedUnit/postOrganizedUnit'
import putOrganizedUnit from '../../../services/organizedUnit/putOrganizedUnit'
import { getOrganizedUnitFilter } from '../../../selectors/masters/organizedUnit'
import getOrganizedUnitTree from '../../../services/organizedUnit/getOrganizedUnitTree'
import { fetchOrganizedUnitsTreeSuccess } from '../../../actions/combos/combos'
export function * fetchOrganizedUnitList ({ filter }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield filter = yield filter || select(getOrganizedUnitFilter)
    const organizedUnitList = yield call(getAllOrganizedUnitsFilter, auth.token, filter)
    yield put(fetchOrganizedUnitListSuccess(organizedUnitList, filter))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}
export function * watchFetchOrganizedUnitList () {
  yield takeEvery(mastersActionTypes.FETCH_ORGANIZED_UNIT_LIST, fetchOrganizedUnitList)
}

export function * openOrganizedUnitModal ({ organizedUnitId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    let organizedUnitFields = []
    if (organizedUnitId) {
      organizedUnitFields = yield call(getOrganizedUnitById, auth.token, organizedUnitId)
    }
    yield put(openOrganizedUnitModalSuccess(organizedUnitFields, organizedUnitId))
    if (organizedUnitId) {
      yield put(initialize('editOrganizedUnit', organizedUnitFields))
    } else {
      yield put(initialize('editOrganizedUnit'))
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchOpenOrganizedUnitModal () {
  yield takeEvery(mastersActionTypes.OPEN_ORGANIZED_UNIT_MODAL, openOrganizedUnitModal)
}

export function * saveOrganizedUnitModal ({ organizedUnitValues, organizedUnitId }) {
  try {
    const isUpdate = (organizedUnitId != null)
    const auth = yield select(getAuth)
    let updateList = true
    if (isUpdate) {
      const confirmed = yield call(yesNoModal, 'organizedUnitAlert')
      if (confirmed) {
        yield put(showLoader())
        yield call(putOrganizedUnit, auth.token, organizedUnitValues)
      } else {
        updateList = false
      }
    } else {
      const confirmed = yield call(yesNoModal, 'createOrganizedUnitAlert',
        null, null, null, null, [{ id: '##NAME##', value: organizedUnitValues.name }], null)
      if (confirmed) {
        yield put(showLoader())
        yield call(postOrganizedUnit, auth.token, organizedUnitValues)
      } else {
        updateList = false
      }
    }
    if (updateList) {
      const filter = yield filter || select(getOrganizedUnitFilter)
      const organizedUnitList = yield call(getAllOrganizedUnitsFilter, auth.token, filter)
      yield put(fetchOrganizedUnitListSuccess(organizedUnitList, filter))
      const UOTreeCombo = yield call(getOrganizedUnitTree, auth.token)
      yield put(fetchOrganizedUnitsTreeSuccess(UOTreeCombo))
    }
    yield put(closeOrganizedUnitModal())
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSaveOrganizedUnitModal () {
  yield takeEvery(mastersActionTypes.SAVE_ORGANIZED_UNIT, saveOrganizedUnitModal)
}
