import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import ChargeQuiterModal from '../../../components/dossiers/common/ChargeQuiterModal'
import { translate } from 'react-polyglot'
import { closeChargeModalDealer, getVehicleDataQuiter, sendVehicleDataQuiter } from '../../../actions/dossier/common/chargeQuiter'
export function mapStateToProps (state) {
  return {
    ...state.chargeQuiter
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      closeChargeModalDealer,
      getVehicleDataQuiter,
      sendVehicleDataQuiter
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(ChargeQuiterModal))
