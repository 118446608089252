import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { openEditProcessingAgencyAssignationModalSuccess, openAddProcessingAgencyAssignationModalSuccess, fetchProcessingAgencyQuotaList, deleteProcessingAgencyQuota } from '../../../actions/masters/masters'
import ProcessingAgencyAssignationMaster from '../../../components/masters/processingAgencyAssignation/ProcessingAgencyAssignationMaster'

export function mapStateToProps (state) {
  return {
    ...state.processingAgencyAssignation,
    combos: {
      UOTreeCombo: state.combos.UOTreeCombo
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      openEditProcessingAgencyAssignationModalSuccess,
      openAddProcessingAgencyAssignationModalSuccess,
      fetchProcessingAgencyQuotaList,
      deleteProcessingAgencyQuota
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(ProcessingAgencyAssignationMaster))
