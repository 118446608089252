import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { fetchTaskFavorites, switchCardTaskFavorite } from '../../actions/dashboard/dashboard'
import ExtraBoxTasks from '../../components/dashboard/extra/extraBoxTasks'
import { translate } from 'react-polyglot'
import TrackingContainer from '../tracking/trackingContainer'
// import { push as navigateTo } from 'react-router-redux'

export function mapStateToProps (state) {
  return {
    ...state.dashboard
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchTaskFavorites,
      switchCardTaskFavorite
    }, dispatch)
  }
}
var decoratedComponent = TrackingContainer(ExtraBoxTasks)
export default connect(mapStateToProps, mapDispatchToProps)(translate()(decoratedComponent))
