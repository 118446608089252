import { dossierSubType as dossierSubTypeConstant } from '../../constants/dossier/common/dossierSubType'
import { dossierTypes as dossierTypesConstant } from '../../constants/dossier/common/dossierTypes'

export function getSubTypePapeworkRelatedDossier (dossierRelatedDossiers) {
  if (dossierRelatedDossiers && dossierRelatedDossiers.length) {
    const paperworkRelatedDossier = dossierRelatedDossiers[0]
    return paperworkRelatedDossier.dossierSubType
  }
}

export function getTitleEntityProvider (dossierType, t, dossierRelatedDossiers) {
  const tKey = 'DOSSIER_COMPONENTS.ENTITY.'
  switch (dossierType) {
    case dossierTypesConstant.sales:
      return t(`${tKey}CLIENT`)
    case dossierTypesConstant.purchase:
      return t(`${tKey}VENDOR`)
    case dossierTypesConstant.paperwork:
      switch (getSubTypePapeworkRelatedDossier(dossierRelatedDossiers)) {
        case dossierSubTypeConstant.compra_vo:
          return t(`${tKey}CLIENT`)
        case dossierSubTypeConstant.venta_vo:
          return t(`${tKey}VENDOR`)
        default:
          return t(`${tKey}PANEL_TITLE`)
      }
    default:
      return t(`${tKey}PANEL_TITLE`)
  }
}

export function getRemoveEntityProvider (dossierType, t, dossierRelatedDossiers) {
  const tKey = 'DOSSIER_COMPONENTS.ENTITY.'
  switch (dossierType) {
    case dossierTypesConstant.sales:
      return t(`${tKey}CLEAR_CLIENT`)
    case dossierTypesConstant.purchase:
      return t(`${tKey}CLEAR_VENDOR`)
    case dossierTypesConstant.paperwork:
      switch (this.getSubTypePapeworkRelatedDossier(dossierRelatedDossiers)) {
        case dossierSubTypeConstant.compra_vo:
          return t(`${tKey}CLEAR_CLIENT`)
        case dossierSubTypeConstant.venta_vo:
          return t(`${tKey}CLEAR_VENDOR`)
      }
  }
  return t(`${tKey}CLEAR_CLIENT`)
}
