import React, { PureComponent } from 'react'
import { Row, Clearfix, Button, Table } from 'react-bootstrap'
import { Field } from 'redux-form'
import InputSelect from '../commons/form/InputSelect'
import InputDatePicker from '../commons/form/InputDatePicker'
import InputTreeSelect from '../commons/form/InputTreeSelect'
import { validateDate } from '../../util/validationFunctions'
import InputNumber from '../commons/form/InputNumber'
import CommercialReportTableModalPage from '../../containers/search/reportSearch/CommercialReportTableModalPage'
import { toDayString } from '../../util/utils'

class SearchCommercialReport extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      fields: {},
      objective: 0
    }
  }

  validateDate (value, allValues, props) {
    const result = validateDate(value, props)
    return result === Object(result) ? undefined : result
  }

  exportData () {
    var tKey = 'SEARCH.CATEGORIES.REPORT.'
    var body = {}

    var cells = []
    var values = []

    cells.push(this.props.t(`${tKey}PENDING_CANCELLATION`))
    values.push(this.props.reportData.pendingCancellation.length)

    cells.push(this.props.t(`${tKey}REGISTERED_PENDING_CANCELLATION`))
    values.push(this.props.reportData.registeredPendingCancellation.length)

    cells.push(this.props.t(`${tKey}INVOICED_PENDING_PLATE`))
    values.push(this.props.reportData.invoicedPendingPlate.length)

    cells.push(this.props.t(`${tKey}INVOICE_PENDING`))
    values.push(this.props.reportData.invoicePending.length)

    cells.push(this.props.t(`${tKey}DOSSIER_COLLECTION_STAGE`))
    values.push(this.props.reportData.dossierCollectionStage.length)

    cells.push(this.props.t(`${tKey}TOTAL_CLOSE`))
    values.push(this.props.reportData.totalClose.length)

    cells.push(this.props.t(`${tKey}OBJECTIVE`))
    values.push(this.state.objective ?? 0)

    cells.push(this.props.t(`${tKey}PENDING`))
    values.push((this.state.objective ?? 0) - this.props.reportData.totalClose.length)

    var today = toDayString()

    var dateFrom = this.props.fields.dateFrom !== null && this.props.fields.dateFrom !== undefined ? this.props.fields.dateFrom : today
    var dateTo = this.props.fields.dateTo !== null && this.props.fields.dateTo !== undefined ? this.props.fields.dateTo : this.props.t(`${tKey}NOW`)

    var title = this.props.t(`${tKey}TABLE_TITLE`) + ' ' + dateFrom + ' ' + this.props.t(`${tKey}TO`) + ' ' + dateTo

    body.cells = cells
    body.values = values
    body.title = title

    this.props.actions.fetchReportCommercialDownload(body)
  }

  fillObjective (value) {
    if (value) {
      this.setState({ objective: parseInt(value) })
    } else {
      this.setState({ objective: 0 })
    }
  }

  viewTable (val, title) {
    var filters = {
      page: 1,
      orderBy: '+dossier',
      pageSize: 10,
      dossierIds: val,
      title: title
    }
    this.props.actions.fetchReportCommercialTable(filters)
  }

  render () {
    const {
      t, combos: { UOTreeCombo, brandCombo }, fields, fieldFill, className, classNameSelect, hasSearch, reportData, selectedFilters,
      onClickResetSearch
    } = this.props
    const tKey = 'SEARCH.CATEGORIES.REPORT.'

    var today = toDayString()

    return (
      <div>
        <CommercialReportTableModalPage/>
        {!hasSearch ? (
          <> <h5>{t(`${tKey}FILTERS`)}</h5>
            <Row>
              <Field
                id="OrganizedUnits"
                name="reportComercial.organizedUnits"
                placeholder={t(`${tKey}ORGANIZED_UNIT`)}
                controlLabel={t(`${tKey}ORGANIZED_UNIT`)}
                component={InputTreeSelect}
                multi={true}
                valueKey="value"
                labelKey="label"
                childrenKey="children"
                options={UOTreeCombo}
                onInputChange={(val) => { fieldFill({ organizedUnits: val }) } }
                customClass={fields.organizedUnits ? classNameSelect : ''}
                colSm={3} />
              <Field
                colSm={3}
                id="brands"
                name='reportComercial.brands'
                placeholder={t(`${tKey}BRAND`)}
                controlLabel={t(`${tKey}BRAND`)}
                component={InputSelect}
                options={brandCombo}
                valueKey="id"
                labelKey="value"
                onInputChange={(val) => { fieldFill({ brands: val }) } }
                customClass={fields.brands ? classNameSelect : ''}
                multi={true} />
              <Field
                id="dateFrom"
                name='reportComercial.dateFrom'
                placeholder={t(`${tKey}DATE_FROM`)}
                controlLabel={t(`${tKey}DATE_FROM`)}
                component={InputDatePicker}
                validate={[this.validateDate]}
                onInputChange={(val) => fieldFill({ dateFrom: val })}
                customClass={fields.creationDateFrom ? className : ''} />
              <Field
                id="dateTo"
                name='reportComercial.dateTo'
                placeholder={t(`${tKey}DATE_TO`)}
                controlLabel={t(`${tKey}DATE_TO`)}
                component={InputDatePicker}
                validate={[this.validateDate]}
                onInputChange={(val) => fieldFill({ dateTo: val })}
                customClass={fields.creationDateTo ? className : ''} />
            </Row>
            <h5>{t(`${tKey}REPORT_DATA`)}</h5>
            <Row>
              <Field
                colSm={3}
                name="objective"
                controlLabel={t(`${tKey}OBJECTIVE`)}
                placeholder={t(`${tKey}OBJECTIVE`)}
                valueKey="id"
                labelKey="value"
                component={InputNumber}
                onChange={(event, value) => this.fillObjective(value)}>
              </Field>
            </Row></>
        ) : ([
          <Row className="filters-selected" key="filters-selected">
            <ul className="list-inline pull-left">
              {selectedFilters().length === 0 ? <li>{t(`${tKey}NO_SELECTED_FILTERS`)}</li> : (selectedFilters())}
            </ul>
            <Clearfix />
            <Button
              onClick={(event) => onClickResetSearch(event)}
              bsStyle="default"
              bsSize="small"
              className="pull-left"
              id='modSearchButtonIdComReport'
            >
              {t('SEARCH.ACTIONS.MODIFY_SEARCH')}
              <i className="ico-edit-turquoise" />
            </Button>
            <Button
              onClick={(event) => this.exportData(event)}
              bsStyle="default"
              bsSize="small"
              id='expSearchButtonIdComReport'>
              {t('SEARCH.ACTIONS.EXPORT')}
              <i className="ico-if_Download_1031520" />
            </Button>
          </Row>,
          <div className="table-wrapper" key="table-wrapper">
            <Table className="editable-table table-report" hover responsive>
              <thead>
                <tr className='table-report-thead'>
                  <th key={0} className='table-report-tdHead'>
                    {t(`${tKey}TABLE_TITLE`)} {this.props.fields.dateFrom ?? today} {t(`${tKey}TO`)} {this.props.fields.dateTo ?? t(`${tKey}NOW`)}
                  </th>
                  <th key={1} />
                </tr>
              </thead>
              {reportData && reportData.pendingCancellation && (
                <tbody>
                  <tr key={'rep_' + 1} className='table-report-td-PD' onClick={() => this.viewTable(reportData.pendingCancellation, t(`${tKey}PENDING_CANCELLATION`))}>
                    <td>{t(`${tKey}PENDING_CANCELLATION`)}</td>
                    <td>{reportData.pendingCancellation.length}</td>
                  </tr>
                  <tr key={'rep_' + 2} className='table-report-td-RPC' onClick={() => this.viewTable(reportData.registeredPendingCancellation, t(`${tKey}REGISTERED_PENDING_CANCELLATION`))}>
                    <td>{t(`${tKey}REGISTERED_PENDING_CANCELLATION`)}</td>
                    <td>{reportData.registeredPendingCancellation.length}</td>
                  </tr>
                  <tr key={'rep_' + 3} className='table-report-td-IPP' onClick={() => this.viewTable(reportData.invoicedPendingPlate, t(`${tKey}INVOICED_PENDING_PLATE`))}>
                    <td>{t(`${tKey}INVOICED_PENDING_PLATE`)}</td>
                    <td>{reportData.invoicedPendingPlate.length}</td>
                  </tr>
                  <tr key={'rep_' + 4} className='table-report-td-IP' onClick={() => this.viewTable(reportData.invoicePending, t(`${tKey}INVOICE_PENDING`))}>
                    <td>{t(`${tKey}INVOICE_PENDING`)}</td>
                    <td>{reportData.invoicePending.length}</td>
                  </tr>
                  <tr key={'rep_' + 5} className='table-report-td-DCS' onClick={() => this.viewTable(reportData.dossierCollectionStage, t(`${tKey}DOSSIER_COLLECTION_STAGE`))}>
                    <td>{t(`${tKey}DOSSIER_COLLECTION_STAGE`)}</td>
                    <td>{reportData.dossierCollectionStage.length}</td>
                  </tr>
                  <tr key={'rep_' + 6} className='table-report-td-TC' onClick={() => this.viewTable(reportData.totalClose, t(`${tKey}TOTAL_CLOSE`))}>
                    <td>{t(`${tKey}TOTAL_CLOSE`)}</td>
                    <td>{reportData.totalClose.length}</td>
                  </tr>
                  <tr key={'rep_' + 7} className='table-report-td-OB' >
                    <td className='table-report-td-OB'>{t(`${tKey}OBJECTIVE`)}</td>
                    <td className='table-report-td-OB'>{this.state.objective ?? 0}</td>
                  </tr>
                  <tr key={'rep_' + 8} className='table-report-td-PEN'>
                    <td className='table-report-td-PEN'>{t(`${tKey}PENDING`)}</td>
                    <td className='table-report-td-PEN'>{(this.state.objective ?? 0) - reportData.totalClose.length }</td>
                  </tr>
                </tbody>
              )}
            </Table>
          </div>
        ])}
      </div>
    )
  }
}

export default SearchCommercialReport
