import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Units from '../../components/units/Units'
import { translate } from 'react-polyglot'
import * as unitActions from '../../actions/unit/unit'
import TrackingContainer from '../../containers/tracking/trackingContainer'

export function mapStateToProps (state) {
  return {
    ...state.company,
    ...state.unit
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      ...unitActions
    }, dispatch)
  }
}
var decoratedComponent = TrackingContainer(Units, true)
export default connect(mapStateToProps, mapDispatchToProps)(translate()(decoratedComponent))
