import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { fetchPaperworkType, fetchServiceDestinationTypes, fetchVehicleType, fetchOperationType, fetchProcessingAgencyManagersCombo } from '../../../actions/combos/combos'
import { fetchOperatePaperworkFleetPermissions } from '../../../actions/management/management'
import { openModal } from '../../../actions/common'
import DataPaperWorkFleetModal from '../../../components/management/AuxModals/DataPaperWorkFleetModal'
import { closeDataPaperWorkFleetModal, getShowEconomic } from '../../../actions/management/auxModals/auxModals'
import { fetchProcessingAgency } from '../../../actions/dossier/paperwork'
import { setOperateDataPaperWorkFleetProcess } from '../../../actions/unattendedProcess/unattendedProcess'

export function mapStateToProps (state) {
  return {
    ...state.managementAuxModals.dataPaperWorkFleetModal,
    combos: state.combos
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      openModal,
      fetchOperatePaperworkFleetPermissions,
      closeDataPaperWorkFleetModal,
      getShowEconomic,
      fetchPaperworkType,
      fetchServiceDestinationTypes,
      fetchVehicleType,
      fetchProcessingAgency,
      setOperateDataPaperWorkFleetProcess,
      fetchOperationType,
      fetchProcessingAgencyManagersCombo
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(DataPaperWorkFleetModal))
