import React, { PureComponent } from 'react'
import SimpleSelect from '../simpleForm/SimpleSelect'

class EditableSelectCeil extends PureComponent {
  render () {
    const {
      input, onInputChange, inputValue, stateValue, editing, nonEditable = false, id, hasError = false,
      selectConfig: { options, valueKey, labelKey, placeholder, menuPlacement, disabled }
    } = this.props
    return (
      <td>
        <SimpleSelect
          id={id}
          colSm={12}
          value={editing && !nonEditable ? inputValue : stateValue}
          onInputChange={onInputChange}
          labelKey={labelKey}
          options={options}
          valueKey={valueKey}
          disabled={!editing || nonEditable || disabled}
          hasError={hasError}
          placeholder={placeholder}
          input={input}
          menuPlacement = {menuPlacement}
        />
      </td>
    )
  }
}
export default EditableSelectCeil
