
import React, { PureComponent } from 'react'
import { Modal, Col, Row, FormGroup, Button } from 'react-bootstrap'
import { reduxForm, Field } from 'redux-form'
import InputText from '../../commons/form/InputText'
import InputSelect from '../../commons/form/InputSelect'
import InputNumber from '../../commons/form/InputNumber'
import { getObjectWithLocales } from '../../../util/masters/masters'
import regEx from '../../../constants/regEx'

class CustomFieldModal extends PureComponent {
  constructor () {
    super()
    this.state = {
      showOrderError: false
    }
  }

  initializeModal (customField) {
    this.props.initialize(customField)
    this.setState({ showOrderError: false })
  }

  componentDidUpdate (prevProps) {
    if (prevProps.showModal !== this.props.showModal && this.props.showModal === true) {
      const customField = getObjectWithLocales(this.props.customField, this.props.customField?.customFieldLocales ?? [], this.props.languageList)
      this.props.initialize(customField)
      this.setState({ showOrderError: false })
    }
  }

  submitCustomField (values) {
    let customFieldLocales = []
    Object.keys(values).map(item => {
      if (!!values[item] && item.match(regEx.languageFieldName)) {
        customFieldLocales.push(values[item])
      }
    })

    const customField = {
      dossierSubTypeId: this.props.customField?.dossierSubTypeId,
      customFieldId: this.props.customField?.customFieldId,
      code: values.code,
      customFieldTypeId: values.customFieldTypeId,
      viewComponentId: values.viewComponentId || null,
      validationFunction: values.validationFunction || null,
      order: values.order,
      customFieldLocales: customFieldLocales
    }
    this.props.actions.submitCustomField(customField)
  }

  handleOrderBlur (value) {
    if (value && value === '0' && value.toString().trim() !== '') {
      this.setState({ showOrderError: false })
    } else {
      this.setState({ showOrderError: true })
    }
  }

  render () {
    const {
      t, handleSubmit, languageList, showModal,
      combos: { customFieldTypes, viewComponents, dossierSubtypes }
    } = this.props
    const editMode = !!(this.props.customField?.customFieldId)
    const tKey = 'MASTERS.CUSTOM_FIELDS.MODAL.'
    return (
      <Modal className="modalCustomFieldMaster languages-modal" show={showModal} onHide={this.props.actions.closeCustomFieldModal} backdrop="static">
        <form autoComplete="off" onSubmit={handleSubmit(this.submitCustomField.bind(this))}>
          <Modal.Header closeButton onHide={this.props.actions.closeCustomFieldModal}>
            <Modal.Title>{t(`${tKey}TITLE`)}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="inputs-wrapper flexRow">
              <Col sm={6} className="custom-width-col">
                <h2>{t(`${tKey}CODE`)}</h2>
                <Field
                  name="code"
                  placeholder={t(`${tKey}CODE`)}
                  component={InputText}
                  disabled={editMode}
                  maxLength={20}
                  colSm={0}
                />
              </Col>
              <Col sm={6} className="custom-width-col">
                <h2>{t(`${tKey}TYPE`)}</h2>
                <Field
                  name="customFieldTypeId"
                  placeholder={t(`${tKey}TYPE`)}
                  component={InputSelect}
                  valueKey="id"
                  labelKey="value"
                  options={customFieldTypes}
                  disabled={editMode}
                  colSm={0}
                />
              </Col>
              <Col sm={6} className="custom-width-col">
                <h2>{t(`${tKey}DOSSIER_SUBTYPE`)}</h2>
                <Field
                  name="dossierSubTypeId"
                  placeholder={t(`${tKey}DOSSIER_SUBTYPE`)}
                  component={InputSelect}
                  valueKey="id"
                  labelKey="value"
                  options={dossierSubtypes}
                  colSm={0}
                  disabled={true}
                />
              </Col>
              <Col sm={6} className="custom-width-col">
                <h2>{t(`${tKey}COMPONENT`)}</h2>
                <Field
                  name="viewComponentId"
                  placeholder={t(`${tKey}COMPONENT`)}
                  component={InputSelect}
                  valueKey="id"
                  labelKey="value"
                  options={viewComponents}
                  colSm={0}
                />
              </Col>
              <Col sm={10} className="custom-width-col">
                <h2>{t(`${tKey}VALIDATION_FUNCTION`)}</h2>
                <Field
                  name="validationFunction"
                  placeholder={t(`${tKey}VALIDATION_FUNCTION`)}
                  component={InputText}
                  maxLength={50}
                  colSm={0}
                />
              </Col>
              <Col sm={2} className="custom-width-col">
                <h2>{t(`${tKey}ORDER`)}</h2>
                <Field
                  name="order"
                  placeholder={t(`${tKey}ORDER`)}
                  component={InputNumber}
                  onHandleBlur = {(value) => this.handleOrderBlur(value)}
                  showError={this.state.showOrderError}
                  maxLength={4}
                />
              </Col>
            </Row>
            <Row className="title-column">
              <Col sm={4}>
                <h2>{t('MASTERS.NAME')}</h2>
              </Col>
              <Col sm={6}>
                <h2>{t('MASTERS.DESCRIPTION')}</h2>
              </Col>
              <Col sm={2}>
                <h2>{t('MASTERS.LANGUAGE')}</h2>
              </Col>
            </Row>
            <FormGroup>
              {languageList && languageList.map((val, idx) => {
                return (
                  <Row key={idx} className="inputs-wrapper">
                    <Col sm={4} className="custom-width-col">
                      <Field
                        name={`lang${val.languageId}.name`}
                        placeholder={t('MASTERS.NAME')}
                        component={InputText}
                        maxLength={50}
                      />
                    </Col>
                    <Col sm={6} className="custom-width-col">
                      <Field
                        name={`lang${val.languageId}.description`}
                        placeholder={t('MASTERS.DESCRIPTION')}
                        component={InputText}
                        maxLength={50}
                      />
                    </Col>
                    <Col sm={2} className="language-column">
                      <p>{`${val.description} (${val.name})`}</p>
                    </Col>
                  </Row>
                )
              })}
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <Col sm={12} className="btn-wrapper">
              <Button
                id="role-modal-submit-button"
                className="btn-standard"
                type="submit"
                disabled={this.props.pristine}>
                {t(`${tKey}SUBMIT_BUTTON`)}
              </Button>
            </Col>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

const validate = (values, props) => {
  const requiredErrorMessage = props.t('GLOBAL.FORMS.REQUIRED')
  const errors = { }
  props.languageList?.map(language => {
    errors[`lang${language.languageId}`] = {}
    if (values[`lang${language.languageId}`]) {
      if (!(values[`lang${language.languageId}`].name)) {
        errors[`lang${language.languageId}`].name = requiredErrorMessage
      }
      if (!(values[`lang${language.languageId}`].description)) {
        errors[`lang${language.languageId}`].description = requiredErrorMessage
      }
    } else {
      errors[`lang${language.languageId}`].name = requiredErrorMessage
      errors[`lang${language.languageId}`].description = requiredErrorMessage
    }
  })
  if (!values.code) { errors.code = requiredErrorMessage }
  if (!values.customFieldTypeId) { errors.customFieldTypeId = requiredErrorMessage }
  if (!values.order || values.order === '0' || values.order.toString().trim() === '') { errors.order = requiredErrorMessage }
  return errors
}

export default reduxForm({
  form: 'customFieldModal',
  validate,
  destroyOnUnmount: false
})(CustomFieldModal)
