import React, { PureComponent } from 'react'
import { Panel, Col, Tab, Tabs, Row } from 'react-bootstrap'
import InputCheckBox from '../../commons/form/InputCheckBox'
import { Field } from 'redux-form'
import { compareObjects } from '../../../util/utils'
import { getFieldsPermisions } from '../../../util/permisionFunctions'
import { entityTypesId, permissions, clientInformation } from '../../../constants/backendIds'
import { dossierTypes as dossierTypesConstant } from '../../../constants/dossier/common/dossierTypes'
import { dossierSubTypeId as dossierSubTypeIdConstant } from '../../../constants/dossier/common/dossierSubType'
import PaperworkAddress from '../../dossiers/common/paperworkAddress/PaperworkAddress'
import setting from '../../../setting'
import ClientData from './clientData/ClientData'
import FinalClient from './finalClient/FinalClient'
import Contact from './contact/Contact'
import CustomFields from '../../commons/customFields/CustomFields'

class Client extends PureComponent {
  constructor () {
    super()
    this.state = {
      open: false,
      fieldsConfiguration: {
        entityTypeId: null,
        entitySubTypeId: null,
        entityId: null,
        dniCif: null,
        clientNumber: null,
        name: null,
        surname1: null,
        surname2: null,
        representativeDni: null,
        representativeName: null,
        representativeSurname1: null,
        representativeSurname2: null,
        address: null,
        city: null,
        state: null,
        cp: null,
        country: null,
        phone: null,
        mobilePhone: null,
        email: null,
        sourceChannelId: null,
        segmentationId: null,
        contactName: null,
        contactSurname1: null,
        maritalcmb: null,
        contactSurname2: null,
        contactEmail: null,
        contactPhone: null,
        contactMobilePhone: null,
        contactAddress: null,
        contactCity: null,
        contactState: null,
        contactCp: null,
        contactCountry: null,
        parentEntityId: null,
        differentPaperworkAddress: null,
        paperworkAddressAddress: null,
        paperworkAddressCity: null,
        paperworkAddressState: null,
        paperworkAddressCp: null,
        paperworkAddressCountry: null,
        entityChildSubTypeId: null,
        entityChildId: null,
        entityTypeIdChild: null,
        dniCifChild: null,
        clientNumberChild: null,
        nameChild: null,
        surname1Child: null,
        surname2Child: null,
        representativeDniChild: null,
        representativeNameChild: null,
        representativeSurname1Child: null,
        representativeSurname2Child: null,
        addressChild: null,
        cityChild: null,
        stateChild: null,
        cpChild: null,
        countryChild: null,
        phoneChild: null,
        mobilePhoneChild: null,
        emailChild: null,
        sourceChannelIdChild: null,
        segmentationIdChild: null,
        differentPaperworkAddressChild: null,
        iaeTypeId: null,
        iaeTypeIdChild: null,
        name2: null,
        entityFromParentDossier: null,
        rfc: null,
        rfcChild: null,
        commercialSocietyTypeId: null,
        clientNotEmployee: null,
        entityProviderId: null,
        startStreet: null,
        contactStartStreet: null,
        startStreetChild: null,
        enndStreet: null,
        contactEnndStreet: null,
        enndStreetChild: null,
        internalNumber: null,
        contactInternalNumber: null,
        internalNumberChild: null,
        externalNumber: null,
        contactExternalNumber: null,
        externalNumberChild: null,
        countryCode: null,
        contactCountryCode: null,
        countryCodeChild: null,
        stateCode: null,
        contactStateCode: null,
        stateCodeChild: null,
        townCode: null,
        contactTownCode: null,
        townCodeChild: null,
        cityCode: null,
        contactCityCode: null,
        cityCodeChild: null,
        colonyCode: null,
        contactColonyCode: null,
        colonyCodeChild: null,
        cpCode: null,
        contactCpCode: null,
        cpCodeChild: null,
        activityBproId: null,
        birthdayDate: null,
        constitutionAct: null,
        constitutionDate: null,
        freeLance: null,
        freeLanceChild: null
      }
    }
  }

  getTitle () {
    const tKey = 'DOSSIER_COMPONENTS.ENTITY.'
    switch (this.props.dossierType) {
      case dossierTypesConstant.sales:
        return this.props.t(`${tKey}CLIENT`)
      case dossierTypesConstant.purchase:
        return this.props.t(`${tKey}VENDOR`)
      default:
        return this.props.t(`${tKey}PANEL_TITLE`)
    }
  }

  initializePermisions (props) {
    const fieldKeys = Object.keys(this.state.fieldsConfiguration)
    const newState = getFieldsPermisions(props.componentConfiguration.fieldsConfiguration, fieldKeys, props.componentConfiguration.permission)
    this.setState({ ...this.state, ...newState })
  }

  componentWillMount () {
    this.initializePermisions(this.props)

    if (this.props.dossier.entityComponent.parentEntityId) {
      this.props.actions.fetchEntitiesByTypeByParent(entityTypesId.claimClient, this.props.dossier.entityComponent.parentEntityId)
    }
  }

  componentDidUpdate (nextProps) {
    if (!this.props.open && nextProps.open) {
      this.setState({ open: true })
    }
    if (nextProps.dossierId && !compareObjects(this.props.componentConfiguration || {}, nextProps.componentConfiguration, true)) {
      this.initializePermisions(nextProps)
    }
    if (this.state.isDifferentPaperworkAddress === undefined && nextProps.dossier && nextProps.dossier.dossierPaperworkComponent &&
        nextProps.dossier.dossierPaperworkComponent.dossierPaperworkAddressComponent &&
        nextProps.dossier.dossierPaperworkComponent.dossierPaperworkAddressComponent.differentPaperworkAddress !== this.state.isDifferentPaperworkAddress) {
      this.setState({ isDifferentPaperworkAddress: nextProps.dossier.dossierPaperworkComponent.dossierPaperworkAddressComponent.differentPaperworkAddress })
    }
  }

  fetchSalesDossierPromiseClient () {
    if (this.state.open) {
      this.props.actions.setActiveSection(false)
    }
    this.setState({
      open: !this.state.open
    })
    if (this.props.isClientLoaded === false) {
      return new Promise((resolve, reject) => {
        this.props.handleToUpdateComponent(this.props)
        if (this.props.dossierType === dossierTypesConstant.sales) {
          return this.props.actions.fetchSalesClientHistorical(this.props.dossier.dossierId, resolve, reject)
        } else if (this.props.dossierType === dossierTypesConstant.purchase) {
          return this.props.actions.fetchPurchaseClientHistorical(this.props.dossier.dossierId, resolve, reject)
        } else if (this.props.dossierType === dossierTypesConstant.paperwork) {
          return this.props.actions.fetchPaperworkClientHistorical(this.props.dossier.dossierId, resolve, reject)
        } else if (this.props.dossierType === dossierTypesConstant.campaign) {
          return this.props.actions.fetchCampaignClientHistorical(this.props.dossier.dossierId, resolve, reject)
        } else if (this.props.dossierType === dossierTypesConstant.registrationDelivery) {
          return this.props.actions.fetchRegistrationDeliveryClientHistorical(this.props.dossier.dossierId, resolve, reject)
        }
      })
    }
  }

  render () {
    const {
      t, customFields, dossierType,
      combos: {
        entityTypeCombo, channelCombo, maritalStatusCombo, segmentationCombo, entitySubTypeCombo, entityFilterSubTypeCombo, iaeTypeCombo, CSTCombo,
        activityBproCombo
      },
      dossier: { entityComponent, entityChildComponent, dossierContactComponent, dossierParentId, parentEntityId, dossierSubTypeId, entityFromParentDossier },
      actions: {
        fetchEntityCountryTypeCombo, fetchEntityStateTypeCombo, fetchEntityCityTypeCombo, fetchEntityTownTypeCombo, fetchEntityColonyTypeCombo,
        fetchEntityCPTypeCombo, openCommonModal, fetchActivityBpro,
        fetchClientDataAddress
      }
    } = this.props
    const isParticularEntityType = entityComponent.entityTypeId === entityTypesId.privateEntity
    const tKey = 'DOSSIER_COMPONENTS.ENTITY.'
    return (
      <div className="client-panel dossier-panel">
        <div className="header-panel" onClick={this.fetchSalesDossierPromiseClient.bind(this)}>
          <div className="name-wrapper">
            <i className="ico-user"/>
            <h4>{this.getTitle()}</h4>
          </div>
          <i className={this.state.open ? 'ico-arrow-black-up' : 'ico-arrow-black-down'}/>
        </div>
        <Panel expanded={this.state.open} onToggle={() => {}} >
          <Panel.Collapse>
            <Row className='sameEntityCheck'>
              {
                this.state.fieldsConfiguration.entityFromParentDossier && this.state.fieldsConfiguration.entityFromParentDossier.permission !== permissions.hidden &&
                ((dossierParentId !== null && dossierParentId !== undefined && parentEntityId !== undefined && parentEntityId !== null) ||
                  (entityFromParentDossier && dossierSubTypeId !== dossierSubTypeIdConstant.venta_vn && dossierSubTypeId !== dossierSubTypeIdConstant.venta_vo)) &&
                (
                  <Col sm={12}>
                    <Field
                      colSm={0}
                      id="entityFromParentDossier"
                      name="entityFromParentDossier"
                      placeholder={t('DOSSIER_COMPONENTS.UAC.MODAL.UAC_CLIENT.CLIENT_IS_SAME')}
                      component={InputCheckBox}
                      onChange={(event, value) => this.setEntityFromParent(value, event)}
                      disabled={true}
                      inline
                    />
                  </Col>
                )
              }
            </Row>
            <Tabs id="1" defaultActiveKey={1}>
              {
                <Tab eventKey={1} title={this.getTitle()} className="dossier-tab">
                  <ClientData
                    fieldsConfiguration={this.state.fieldsConfiguration}
                    actions={{
                      fetchEntityCountryTypeCombo,
                      fetchEntityStateTypeCombo,
                      fetchEntityCityTypeCombo,
                      fetchEntityTownTypeCombo,
                      fetchEntityColonyTypeCombo,
                      fetchEntityCPTypeCombo,
                      openCommonModal,
                      fetchActivityBpro,
                      fetchClientDataAddress
                    }}
                    combos= {{
                      entityTypeCombo,
                      channelCombo,
                      maritalStatusCombo,
                      segmentationCombo,
                      entitySubTypeCombo,
                      entityFilterSubTypeCombo,
                      iaeTypeCombo,
                      CSTCombo,
                      activityBproCombo
                    }}
                    isParticularEntityType = {isParticularEntityType}
                    dossier= {{ entityComponent }}
                    dossierType = { dossierType }
                    t={t}
                  />
                </Tab>
              }
              {
                (entityComponent.entityTypeId === entityTypesId.recurrentEntity &&
                  this.props.dossierType === dossierTypesConstant.purchase &&
                  !setting.clientFieldValidation === clientInformation.QUADIS) ||
                  this.props.dossierType === dossierTypesConstant.addNewFleetModal ? null : (
                    <Tab eventKey={2} title={t(`${tKey}CONTACT`)} className="dossier-tab">
                      <Contact
                        fieldsConfiguration={this.state.fieldsConfiguration}
                        t={t}
                        actions={{
                          fetchEntityCountryTypeCombo,
                          fetchEntityStateTypeCombo,
                          fetchEntityCityTypeCombo,
                          fetchEntityTownTypeCombo,
                          fetchEntityColonyTypeCombo,
                          fetchEntityCPTypeCombo,
                          openCommonModal,
                          fetchClientDataAddress
                        }}
                        dossier= {{ dossierContactComponent }}
                      />
                    </Tab>
                  )
              }
              {
                entityComponent.entityTypeId !== entityTypesId.recurrentEntity || this.props.dossierType === dossierTypesConstant.purchase ? null : (
                  <Tab eventKey={3} title={t(`${tKey}FINAL_CLIENT`)} className="dossier-tab">
                    <FinalClient
                      fieldsConfiguration={this.state.fieldsConfiguration}
                      t={t}
                      dossier= {{ entityChildComponent }}
                      dossierType= {dossierType}
                      actions={{
                        fetchEntityCountryTypeCombo,
                        fetchEntityStateTypeCombo,
                        fetchEntityCityTypeCombo,
                        fetchEntityTownTypeCombo,
                        fetchEntityColonyTypeCombo,
                        fetchEntityCPTypeCombo,
                        openCommonModal,
                        fetchClientDataAddress
                      }}
                      combos= {{
                        entityTypeCombo,
                        iaeTypeCombo

                      }}
                    />
                  </Tab>
                )
              }
              { this.props.dossierType === dossierTypesConstant.paperwork && this.state.isDifferentPaperworkAddress && (
                <Tab eventKey={3} title={t(`${tKey}PAPERWORK_ADDRESS`)} className="dossier-tab">
                  <PaperworkAddress
                    fieldsConfiguration={this.state.fieldsConfiguration}
                    readOnlyFields={true}
                    t={t}
                    formInitRoute="dossierPaperworkComponent."
                  />
                </Tab>
              )}
            </Tabs>
            <CustomFields customFields={customFields} readOnlyFields={true} />
          </Panel.Collapse>
        </Panel>
      </div>
    )
  }
}
export default Client
