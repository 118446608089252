import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import authActions from '../../actions/auth'
import RememberPassword from '../../components/access/RememberPassword'
import { translate } from 'react-polyglot'
import TrackingContainer from '../../containers/tracking/trackingContainer'

export function mapStateToProps (state) {
  return {
    ...state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators(authActions, dispatch)
  }
}

var decoratedComponent = TrackingContainer(RememberPassword, true)
export default connect(mapStateToProps, mapDispatchToProps)(translate()(decoratedComponent))
