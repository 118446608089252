import roleActionTypes from '../../constants/actions/admin/roleAdmin'

function initialState () {
  return {
    roleList: [],
    pagesCount: 0,
    rolesCount: 0,
    filter: {
      page: 1,
      orderBy: '',
      isDeleted: null,
      pageSize: 10
    },
    roleAdminModal: {
      roleId: null,
      showModal: false,
      roleName: '',
      roleLanguages: [],
      sectionPermissions: [],
      permissions: [],
      ownedPermissions: []
    }
  }
}

function fetchRoleAdminSuccess (state, { roleList, filter }) {
  return {
    ...state,
    roleList: roleList.roleList,
    rolesCount: roleList.rolesCount,
    pagesCount: roleList.pagesCount,
    filter
  }
}

function openEditModalSuccess (state, { role }) {
  return {
    ...state,
    roleAdminModal: {
      ...state.roleAdminModal,
      ...role,
      showModal: true
    }
  }
}

function openAddModalSuccess (state, { sectionPermissions }) {
  const initial = initialState()
  return {
    ...state,
    roleAdminModal: {
      ...initial.roleAdminModal,
      showModal: true,
      sectionPermissions
    }
  }
}

function closeEditModalSuccess (state) {
  return {
    ...state,
    roleAdminModal: {
      ...initialState().roleAdminModal
    }
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case roleActionTypes.FETCH_ROLE_ADMIN_SUCCESS:
      return fetchRoleAdminSuccess(state, action)
    case roleActionTypes.OPEN_EDIT_ROLE_MODAL_SUCCESS:
      return openEditModalSuccess(state, action)
    case roleActionTypes.OPEN_ADD_ROLE_MODAL_SUCCESS:
      return openAddModalSuccess(state, action)
    case roleActionTypes.CLOSE_EDIT_ROLE_MODAL:
      return closeEditModalSuccess(state, action)
    default:
      return state
  }
}
