import React, { PureComponent } from 'react'
import { Table } from 'react-bootstrap'
import PropTypes from 'prop-types'

class SimpleTable extends PureComponent {
  render () {
    const { t, columns = [], rows = [], salesMan, dossierId } = this.props
    if (columns.length === 0) {
      return <div></div>
    }
    return (
      <Table className="simple-table">
        <thead>
          <tr>{columns}</tr>
        </thead>
        <tbody>
          {salesMan
            ? (
              <tr className="no-results"><td colSpan={columns.length}>{t('GLOBAL.TABLES.ASSIGNED_VEHICLE')}<strong>{salesMan}</strong>{t('GLOBAL.TABLES.ASSIGNED_DOSSIER')}<strong>{dossierId}</strong></td></tr>
            )
            : (rows?.length > 0
              ? rows
              : (
                <tr className="no-results"><td colSpan={columns.length}>{t('GLOBAL.TABLES.NO_RESULTS')}</td></tr>
              ))
          }
        </tbody>
      </Table>
    )
  }
}

SimpleTable.propTypes = {
  t: PropTypes.func.isRequired,
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array
}

export default SimpleTable
