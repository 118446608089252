import React, { PureComponent } from 'react'
import { Button, Modal, Col, Form, Row } from 'react-bootstrap'
import { reduxForm } from 'redux-form'
import SaleRequestFields from '../common/saleRequestFields/SaleRequestFields'

class SaleRequestModal extends PureComponent {
  constructor () {
    super()
    this.state = {
      purchaseType: null
    }
  }

  submit (values) {
    new Promise((resolve, reject) => {
      this.props.actions.saveOrder({ ...this.props.order, ...values }, this.props.dossierId, true, this.props.dossierType, resolve)
    }).then(() => {
      this.props.actions.closeSaleRequestModal()
    })
  }

  render () {
    const {
      t, handleSubmit, readOnlyFields, showSaleRequestModal,
      actions: { closeSaleRequestModal }, combos: { entityTransferCombo, purchaseTypeVnCombo }
    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.SALE_REQUEST_MODAL.'
    return (
      <Modal className="add-user-modal sale-req-modal" show={showSaleRequestModal} onHide={closeSaleRequestModal}>
        <Form autoComplete="off" onSubmit={handleSubmit(this.submit.bind(this))}>
          <Modal.Header closeButton>
            <Modal.Title>{t(`${tKey}TITLE`)}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <SaleRequestFields
                t={t}
                readOnlyFields={readOnlyFields}
                purchaseTypeVnCombo={purchaseTypeVnCombo}
                entityTransferCombo={entityTransferCombo}
                validateRequired={true}
              />
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Col sm={12} className="btn-wrapper">
              <Button id="btn_save" className="btn-standard" type="submit">{t('GLOBAL.BUTTONS.SAVE')}</Button>
            </Col>
          </Modal.Footer>
        </Form>
      </Modal>
    )
  }
}

export default reduxForm({
  form: 'saleRequestModal',
  validate: (values, { t }) => {
    const errors = {}

    return errors
  }
})(SaleRequestModal)
