import React, { PureComponent } from 'react'
import { Button, Panel, Row } from 'react-bootstrap'
import InputSelect from '../../../../_v2/components/commons/form/InputSelect'
import InputText from '../../../../_v2/components/commons/form/InputText'
import InputNatural from '../../../../components/commons/form/InputNatural'
import InputMoney from '../../../../_v2/components/commons/form/InputMoney'
import InputDatePicker from '../../../../_v2/components/commons/form/InputDatePicker'
import InputPercentage from '../../../../_v2/components/commons/form/InputPercentage'
import { Field } from 'redux-form'
import { validateDate } from '../../../../util/validationFunctions'
import { compareObjects } from '../../../../util/utils'
import { getFieldsPermisions } from '../../../../util/permisionFunctions'
import { permissions, clientInformation } from '../../../../constants/backendIds'
import { financingRadioOptions } from '../../../../constants/dossier/sale/financingComponent'
import InputRadio from '../../../../components/commons/form/InputRadio'
import setting from '../../../../setting'
import InputCheckBox from '../../../../components/commons/form/InputCheckBox'
import CustomFields from '../../../../components/commons/customFields/CustomFields'
import ComponentTitle from '../../../../_v2/containers/dossiers/commons/ComponentTitlePage'

class Finance extends PureComponent {
  constructor () {
    super()
    this.state = {
      open: false,
      fieldsConfiguration: {
        companyId: null,
        typeId: null,
        amount: null,
        instalments: null,
        firstInstalmentDate: null,
        km: null,
        financingStatusId: null,
        tin: null,
        bonusAmount: null,
        extendedFinancing: null,
        entry: null,
        residualValue: null,
        openingCommission: null,
        monthlyFee: null,
        apr: null,
        lastDNICIF: null,
        renovation: null,
        maintenanceIncluded: null,
        insuranceIncluded: null,
        cleanFinance: null
      },
      dataLoaded: false,
      lastDNICIFDisabled: true,
      loaded: false,
      typeProductChange: false,
      displayRepurchaseDate: false,
      typeId: null
    }
  }

  initializePermisions (props) {
    const fieldKeys = Object.keys(this.state.fieldsConfiguration)
    const newState = getFieldsPermisions(props.componentConfiguration.fieldsConfiguration, fieldKeys, props.componentConfiguration.permission)
    this.setState({ ...this.state, ...newState })
  }

  UNSAFE_componentWillMount () {
    this.initializePermisions(this.props)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.open) {
      this.setState({ open: true })
    }

    if (nextProps.isFinanceLoaded === false && this.state.open) {
      this.setState({ loaded: true })
    }

    if (nextProps.dossierId && !compareObjects(this.props.componentConfiguration || {}, nextProps.componentConfiguration, true)) {
      this.initializePermisions(nextProps)
    }
    if (nextProps.dossier && !compareObjects(this.props.dossier.dossierFinancingComponent || {}, nextProps.dossier.dossierFinancingComponent, true)) {
      this.setState({ displayRepurchaseDate: nextProps.dossier.dossierFinancingComponent.repurchaseAgreement || false })
      this.setState({ typeId: nextProps.dossier.dossierFinancingComponent.typeId || null })
    }

    if (nextProps.financingTypeProduct && (!compareObjects(this.props.financingTypeProduct || {}, nextProps.financingTypeProduct, true) && this.state.typeProductChange)) {
      if (this.state.typeProductChange) {
        this.props.actions.change('dossierFinancingComponent.financingTypeProductId', nextProps.financingTypeProduct.financingTypeProductId)
        this.props.actions.change('dossierFinancingComponent.reactivatable', nextProps.financingTypeProduct.reactivatable)
        this.props.actions.change('dossierFinancingComponent.repurchaseAgreement', nextProps.financingTypeProduct.repurchaseAgreement)
        this.props.actions.change('dossierFinancingComponent.financingTypeProductIdName', nextProps.financingTypeProduct.name)
        this.setState({ displayRepurchaseDate: nextProps.financingTypeProduct.repurchaseAgreement, typeProductChange: false })
      }
    }
    if (this.props.financingTypeProduct !== null && nextProps.financingTypeProduct == null) {
      this.props.actions.change('financingTypeProduct', null)
      this.props.actions.change('dossierFinancingComponent.financingTypeProductId', null)
      this.props.actions.change('dossierFinancingComponent.reactivatable', false)
      this.props.actions.change('dossierFinancingComponent.repurchaseAgreement', false)
      this.props.actions.change('dossierFinancingComponent.financingTypeProductIdName', null)
    }
  }

  componentDidUpdate () {
    if (this.state.loaded) {
      this.setState({ loaded: false })
      if (this.props.historical === false) {
        return new Promise((resolve, reject) => {
          this.props.handleToUpdateComponent(this.props)
          return this.props.actions.fetchSalesFinancing(this.props.dossierId, resolve, reject, this.props.pristine)
        })
      } else {
        return new Promise((resolve, reject) => {
          this.props.handleToUpdateComponent(this.props)
          return this.props.actions.fetchSalesFinancingHistorical(this.props.dossierId, resolve, reject)
        })
      }
    }
    if (!this.state.dataLoaded) {
      if (Object.keys(this.props.dossier.dossierFinancingComponent).length > 1) {
        this.setState({ dataLoaded: true })
        if (this.props.dossier.dossierFinancingComponent.renovation === 'true') {
          this.setState({ lastDNICIFDisabled: false })
        }
      }
    }
  }

  handleRenovationChange (value) {
    this.setState({ lastDNICIFDisabled: value !== 'true' })
    if (value !== 'true') {
      this.props.actions.change('dossierFinancingComponent.lastDNICIF', undefined)
    }
  }

  fetchSalesDossierPromiseFinance () {
    if (this.state.open) {
      this.props.actions.setActiveSection(false)
    }
    this.setState({
      open: !this.state.open
    })
    if (this.props.dossierId !== -1) {
      if (this.props.isFinanceLoaded === false) {
        if (this.props.historical === false) {
          return new Promise((resolve, reject) => {
            this.props.handleToUpdateComponent(this.props)
            return this.props.actions.fetchSalesFinancing(this.props.dossierId, resolve, reject, this.props.pristine)
          })
        } else {
          return new Promise((resolve, reject) => {
            this.props.handleToUpdateComponent(this.props)
            return this.props.actions.fetchSalesFinancingHistorical(this.props.dossierId, resolve, reject)
          })
        }
      }
    }
  }

  removeFinance () {
    new Promise((resolve, reject) => {
      this.props.actions.removeFinance(resolve)
    }).then((deleteFinance) => {
      if (deleteFinance) {
        this.handleRenovationChange('false')
      }
    })
  }

  cleanFinance () {
    new Promise((resolve, reject) => {
      this.props.actions.cleanFinance(resolve)
    }).then((deleteFinance) => {
      if (deleteFinance) {
        this.handleRenovationChange('false')
      }
    })
  }

  dateValidation (value, allValues, props) {
    const result = validateDate(value, props)
    return result === Object(result) ? undefined : result
  }

  changeFinancingCompany (val) {
    this.props.actions.change('dossierFinancingComponent.typeId', null)
    this.props.actions.fetchFinancingType(val)
  }

  changeFinancingProdut (val) {
    if (!this.state.typeId) {
      this.props.actions.change('dossierFinancingComponent.typeId', val)
      this.setState({ typeId: val })
      this.props.actions.getFinancingTypeProductByFinancingType(val)
      this.setState({ typeProductChange: true })
    } else {
      this.props.actions.openCommonModal('actionFinanceChange',
        () => {
          this.props.actions.change('dossierFinancingComponent.typeId', val)
          this.setState({ typeId: val })
          this.props.actions.getFinancingTypeProductByFinancingType(val)
          this.setState({ typeProductChange: true })
        },
        () => {
          this.props.actions.change('dossierFinancingComponent.typeId', this.state.typeId)
          this.setState({ typeId: this.state.typeId })
          this.props.actions.getFinancingTypeProductByFinancingType(this.state.typeId)
        },
        null,
        null,
        null,
        null)
    }
  }

  handleChangerepurchaseAgreement (value) {
    this.props.actions.openCommonModal('actionFinanceChange',
      () => {
        this.props.actions.change('dossierFinancingComponent.repurchaseAgreement', value)
        this.setState({ displayRepurchaseDate: value })
      },
      () => {
        this.props.actions.change('dossierFinancingComponent.repurchaseAgreement', !value)
        this.setState({ displayRepurchaseDate: !value })
      }, null, null, null, null)
  }

  handleChangeReactivatable (value) {
    this.props.actions.openCommonModal('actionFinanceChange', () => { this.props.actions.change('dossierFinancingComponent.reactivatable', value) }, () => { this.props.actions.change('dossierFinancingComponent.reactivatable', !value) }, null, null, null, null)
  }

  render () {
    const {
      t, readOnlyFields, customFields, combos: { financialInstitutionCombo, financingTypeCombo, financingStatusCombo },
      dossier: { dossierFinancingComponent }, financingTypeProduct, redate, showEconomicPlan
    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.FINANCE.'
    const showRemoveButton = !readOnlyFields && dossierFinancingComponent && dossierFinancingComponent.updated && Object.keys(dossierFinancingComponent).length > 1

    const displayRepurchaseDate = this.state.displayRepurchaseDate
      ? this.state.displayRepurchaseDate
      : dossierFinancingComponent.repurchaseAgreement

    return (
      <Row className="dossier-Component">
        {!this.props.isFleet && <div className="header-panel" onClick={this.fetchSalesDossierPromiseFinance.bind(this)}>
          <ComponentTitle
            componentCode="FINANCE"
            componentOpen={this.state.open}
          />
        </div> }
        <Panel expanded={this.state.open} onToggle={() => {}}>
          <Panel.Collapse>
            <div className="fieldsContainer">
              <div className='flexInLine al-R'>
                { setting.clientFieldValidation === clientInformation.QUADIS && showRemoveButton &&
                  <Button className="_Btn cancel stk" onClick={() => this.removeFinance() } disabled={readOnlyFields || this.state.fieldsConfiguration.cleanFinance.permission !== permissions.editable}>
                    <i className="iDocIcon-delete-forever"></i>
                    {t(`${tKey}DELETE_FINANCE`)}
                  </Button>
                }
                { setting.clientFieldValidation === clientInformation.QUADIS && !showRemoveButton &&
                  <Button className="_Btn accent stk" onClick={() => this.cleanFinance()} disabled={readOnlyFields || this.state.fieldsConfiguration.cleanFinance.permission !== permissions.editable}>
                    <i className="iDocIcon-clear-decline"></i>
                    {t(`${tKey}CLEAN_FINANCE`)}
                  </Button>
                }
              </div>
              <div className="separate"></div>
              <Row className={'finance'}>
                {this.state.fieldsConfiguration.companyId.permission !== permissions.hidden && (<Field
                  colSm={3}
                  id="companyId"
                  name="dossierFinancingComponent.companyId"
                  controlLabel={t(`${tKey}COMPANY`)}
                  placeholder={t(`${tKey}COMPANY`)}
                  component={InputSelect}
                  options={financialInstitutionCombo}
                  valueKey="id"
                  labelKey="value"
                  disabled={readOnlyFields || this.state.fieldsConfiguration.companyId.permission !== permissions.editable}
                  onChange={(val) => this.changeFinancingCompany(val)}
                />)}
                {this.state.fieldsConfiguration.typeId.permission !== permissions.hidden && (<Field
                  colSm={3}
                  id="typeId"
                  name="dossierFinancingComponent.typeId"
                  controlLabel={t(`${tKey}FINANCE_TYPE`)}
                  placeholder={t(`${tKey}FINANCE_TYPE`)}
                  component={InputSelect}
                  options={financingTypeCombo}
                  valueKey="id"
                  labelKey="value"
                  disabled={readOnlyFields || this.state.fieldsConfiguration.typeId.permission !== permissions.editable}
                  onChange={(val) => this.changeFinancingProdut(val)}
                />)}
                {this.state.fieldsConfiguration.extendedFinancing.permission !== permissions.hidden && (
                  <Field
                    id="financingTypeProduct"
                    key="financingTypeProduct"
                    name="dossierFinancingComponent.financingTypeProductIdName"
                    colSm={3}
                    controlLabel={t(`${tKey}FINANCING_TYPE_PRODUCT`)}
                    placeholder={t(`${tKey}FINANCING_TYPE_PRODUCT`)}
                    component={InputText}
                    disabled={true}
                  />
                )}
                {this.state.fieldsConfiguration.amount.permission !== permissions.hidden && (<Field
                  id="amount"
                  name="dossierFinancingComponent.amount"
                  colSm={3}
                  controlLabel={t(`${tKey}AMOUNT`)}
                  placeholder={t(`${tKey}AMOUNT`)}
                  component={InputMoney}
                  disabled={readOnlyFields || this.state.fieldsConfiguration.amount.permission !== permissions.editable}
                />)}
                {this.state.fieldsConfiguration.instalments.permission !== permissions.hidden && (<Field
                  id="instalments"
                  name="dossierFinancingComponent.instalments"
                  colSm={3}
                  controlLabel={t(`${tKey}INSTALMENTS`)}
                  placeholder={t(`${tKey}INSTALMENTS`)}
                  component={InputNatural}
                  disabled={readOnlyFields || this.state.fieldsConfiguration.instalments.permission !== permissions.editable}
                />)}
                {this.state.fieldsConfiguration.firstInstalmentDate.permission !== permissions.hidden && (<Field
                  id="firstInstalmentDate"
                  name="dossierFinancingComponent.firstInstalmentDate"
                  colSm={3}
                  controlLabel={t(`${tKey}FIRST_INSTALMENT_DATE`)}
                  component={InputDatePicker}
                  validate={[this.dateValidation]}
                  disabled={readOnlyFields || this.state.fieldsConfiguration.firstInstalmentDate.permission !== permissions.editable}
                />)}
                {this.state.fieldsConfiguration.km.permission !== permissions.hidden && (<Field
                  id="km"
                  name="dossierFinancingComponent.km"
                  colSm={3}
                  controlLabel={t(`${tKey}KM`)}
                  placeholder={t(`${tKey}KM`)}
                  component={InputNatural}
                  disabled={readOnlyFields || this.state.fieldsConfiguration.km.permission !== permissions.editable}
                  textAlign="left"
                />)}
                {this.state.fieldsConfiguration.tin.permission !== permissions.hidden && (<Field
                  id="tin"
                  name="dossierFinancingComponent.tin"
                  colSm={3}
                  controlLabel={t(`${tKey}TIN`)}
                  placeholder={t(`${tKey}TIN`)}
                  component={InputPercentage}
                  disabled={readOnlyFields || this.state.fieldsConfiguration.tin.permission !== permissions.editable}
                  valueKey="id"
                  labelKey="value"
                />)}
                {this.state.fieldsConfiguration.financingStatusId.permission !== permissions.hidden && (<Field
                  id="financingStatusId"
                  name="dossierFinancingComponent.financingStatusId"
                  colSm={3}
                  controlLabel={t(`${tKey}FINANCING_STATUS`)}
                  placeholder={t(`${tKey}FINANCING_STATUS`)}
                  component={InputSelect}
                  disabled={readOnlyFields || this.state.fieldsConfiguration.financingStatusId.permission !== permissions.editable}
                  options={financingStatusCombo}
                  valueKey="id"
                  labelKey="value"
                />)}
                {this.state.fieldsConfiguration.bonusAmount.permission !== permissions.hidden && (<Field
                  id="bonusAmount"
                  name="dossierFinancingComponent.bonusAmount"
                  colSm={3}
                  controlLabel={t(`${tKey}BONUS_AMOUNT`)}
                  placeholder={t(`${tKey}BONUS_AMOUNT`)}
                  component={InputMoney}
                  disabled={readOnlyFields || this.state.fieldsConfiguration.bonusAmount.permission !== permissions.editable}
                  valueKey="id"
                  labelKey="value"
                />)}
                {showEconomicPlan === true && (<Field
                  id="financingCommission"
                  name="dossierFinancingComponent.financingCommission"
                  colSm={3}
                  controlLabel={t(`${tKey}FINANCING_COMISSION`)}
                  placeholder={t(`${tKey}FINANCING_COMISSION`)}
                  component={InputMoney}
                  disabled={readOnlyFields}
                  valueKey="id"
                  labelKey="value"
                />)}
                {this.state.fieldsConfiguration.extendedFinancing.permission !== permissions.hidden && ([<Field
                  id="entry"
                  key="entry"
                  name="dossierFinancingComponent.entry"
                  colSm={3}
                  controlLabel={t(`${tKey}ENTRY`)}
                  placeholder={t(`${tKey}ENTRY`)}
                  component={InputMoney}
                  disabled={readOnlyFields || this.state.fieldsConfiguration.extendedFinancing.permission !== permissions.editable}
                />,
                <Field
                  id="residualValue"
                  key="residualValue"
                  name="dossierFinancingComponent.residualValue"
                  colSm={3}
                  controlLabel={t(`${tKey}RESIDUAL_VALUE`)}
                  placeholder={t(`${tKey}RESIDUAL_VALUE`)}
                  component={InputMoney}
                  disabled={readOnlyFields || this.state.fieldsConfiguration.extendedFinancing.permission !== permissions.editable}
                />,
                <Field
                  id="openingCommission"
                  key="openingCommission"
                  name="dossierFinancingComponent.openingCommission"
                  colSm={3}
                  controlLabel={t(`${tKey}OPENING_COMMISSION`)}
                  placeholder={t(`${tKey}OPENING_COMMISSION`)}
                  component={InputMoney}
                  disabled={readOnlyFields || this.state.fieldsConfiguration.extendedFinancing.permission !== permissions.editable}
                />,
                <Field
                  id="monthlyFee"
                  key="monthlyFee"
                  name="dossierFinancingComponent.monthlyFee"
                  colSm={3}
                  controlLabel={t(`${tKey}MONTHLY_FEE`)}
                  placeholder={t(`${tKey}MONTHLY_FEE`)}
                  component={InputMoney}
                  disabled={readOnlyFields || this.state.fieldsConfiguration.extendedFinancing.permission !== permissions.editable}
                />,
                <Field
                  id="apr"
                  key="apr"
                  name="dossierFinancingComponent.apr"
                  colSm={3}
                  controlLabel={t(`${tKey}APR`)}
                  placeholder={t(`${tKey}APR`)}
                  component={InputPercentage}
                  disabled={readOnlyFields || this.state.fieldsConfiguration.extendedFinancing.permission !== permissions.editable}
                />,
                <Field
                  id="lastDNICIF"
                  key="lastDNICIF"
                  name="dossierFinancingComponent.lastDNICIF"
                  colSm={3}
                  controlLabel={t(`${tKey}LAST_DNI_CIF`)}
                  placeholder={t(`${tKey}LAST_DNI_CIF`)}
                  component={InputText}
                  disabled={readOnlyFields || this.state.lastDNICIFDisabled || this.state.fieldsConfiguration.extendedFinancing.permission !== permissions.editable}
                />,
                financingTypeProduct != null && displayRepurchaseDate && (<Field
                  key="redate"
                  name="redate"
                  colSm={3}
                  controlLabel={t(`${tKey}EXPECTED_REPURCHASE_DATE`)}
                  component={InputDatePicker}
                  disabled={true}
                  input = {{ value: redate || null }}
                />),
                <Field
                  id="renovation"
                  key="renovation"
                  name="dossierFinancingComponent.renovation"
                  groupName="dossierFinancingComponent.renovation"
                  colSm={3}
                  inline
                  placeholder={t(`${tKey}RENOVATION`)}
                  component={InputRadio}
                  disabled={readOnlyFields || this.state.fieldsConfiguration.extendedFinancing.permission !== permissions.editable}
                  onChange={(value) => this.handleRenovationChange(value)}
                  items={financingRadioOptions}
                  t={t}
                />,
                <Field
                  id="maintenanceIncluded"
                  key="maintenanceIncluded"
                  name="dossierFinancingComponent.maintenanceIncluded"
                  groupName="dossierFinancingComponent.maintenanceIncluded"
                  colSm={3}
                  inline
                  placeholder={t(`${tKey}MAINTENANCE_INCLUDED`)}
                  component={InputRadio}
                  disabled={readOnlyFields || this.state.fieldsConfiguration.extendedFinancing.permission !== permissions.editable}
                  items={financingRadioOptions}
                  t={t}
                />,
                <Field
                  id="insuranceIncluded"
                  key="insuranceIncluded"
                  name="dossierFinancingComponent.insuranceIncluded"
                  groupName="dossierFinancingComponent.insuranceIncluded"
                  colSm={3}
                  inline
                  placeholder={t(`${tKey}INSURANCE_INCLUDED`)}
                  component={InputRadio}
                  disabled={readOnlyFields || this.state.fieldsConfiguration.extendedFinancing.permission !== permissions.editable}
                  items={financingRadioOptions}
                  t={t}
                />,
                <Field
                  colSm={4}
                  id="reactivatable"
                  key="reactivatable"
                  name="dossierFinancingComponent.reactivatable"
                  placeholder={t(`${tKey}REACTIVATABLE`)}
                  inline
                  component={InputCheckBox}
                  disabled={financingTypeProduct == null || financingTypeProduct.reactivatable || readOnlyFields}
                  onChange = {(value) => this.handleChangeReactivatable(value)}
                />,
                <Field
                  colSm={4}
                  id="repurchaseAgreement"
                  key="repurchaseAgreement"
                  name="dossierFinancingComponent.repurchaseAgreement"
                  placeholder={t(`${tKey}REPURCHASE_AGREEMENT`)}
                  inline
                  component={InputCheckBox}
                  disabled={financingTypeProduct == null || financingTypeProduct.repurchaseAgreement || readOnlyFields}
                  onChange = {(value) => this.handleChangerepurchaseAgreement(value)}
                />
                ])}
              </Row>
              <CustomFields customFields={customFields} readOnlyFields={readOnlyFields} />
            </div>
          </Panel.Collapse>
        </Panel>
      </Row>

    )
  }
}
export default Finance
