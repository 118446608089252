import React, { PureComponent } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import BubbleMeter from '../../../../../components/commons/BubbleMeter'
import { formatMoney } from '../../../../../util/utils'
import { Link } from 'react-router-dom'

class CampaignsRow extends PureComponent {
  render () {
    const {
      tKey,
      t,
      fetchCampaignEdition,
      openCampaignEdition,
      readOnlyFields,
      deleteCampaign,
      campaign: {
        campaignId, number, name, type, amount, cost, stage, documents, canBeEditable, urlToNavigate, costConcessionaire, amountPercentage, costPercentage, costConcessionPercentage
      }
    } = this.props
    const editTooltip = (<Tooltip id="editTooltip">{t(`${tKey}EDIT_BTN`)}</Tooltip>)
    const deleteTooltip = (<Tooltip id="deleteTooltip">{t(`${tKey}DELETE_BTN`)}</Tooltip>)
    return (
      <tr>
        <td>{urlToNavigate ? (<Link to={`/dossier${urlToNavigate}`}>{number}</Link>) : number}</td>
        <td>{name}</td>
        <td>{type}</td>
        <td className="text-left">{ amount ? formatMoney(amount) : amountPercentage ? amountPercentage + '%' : formatMoney(0)} </td>
        <td className="text-left">{ cost ? formatMoney(cost) : costPercentage ? costPercentage + '%' : formatMoney(0)}</td>
        <td className="text-left">{ costConcessionaire ? formatMoney(costConcessionaire) : costConcessionPercentage ? costConcessionPercentage + '%' : formatMoney(0)}</td>
        <td>{stage}</td>
        { documents && documents.length ? <td><span >{documents[0].name} </span><BubbleMeter listItems={documents.map(document => document.name)} /></td> : <td></td>}
        { canBeEditable && !readOnlyFields
          ? <td className='actions'>
            <div>
              <OverlayTrigger placement="right" overlay={editTooltip}>
                <a className='_Btn accept stk o-icn noLine' onClick={() => fetchCampaignEdition(campaignId, openCampaignEdition)}>
                  <i className="iDocIcon-edit"></i>
                </a>
              </OverlayTrigger>
              <OverlayTrigger placement="left" overlay={deleteTooltip}>
                <a className='_Btn cancel stk o-icn noLine' onClick={() => deleteCampaign(campaignId)}>
                  <i className="iDocIcon-delete-forever"></i>
                </a>
              </OverlayTrigger>
            </div>
          </td>
          : <td/>
        }
      </tr>
    )
  }
}
export default CampaignsRow
