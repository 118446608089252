export const buttonsSidebar = {
  bsale: 'bsale',
  vehicle: 'vehicle',
  entity: 'entity',
  accessory: 'accessory',
  no_recover: 'no_recover',
  campaign: 'campaign',
  uac: 'uac',
  service: 'service',
  finance: 'finance',
  brokerage: 'brokerage',
  paperwork: 'paperwork',
  charge: 'charge',
  services: 'services',
  economic: 'economic',
  delivery: 'delivery',
  other_data: 'other_data',
  comment: 'comment',
  bpaperwork: 'bpaperwork',
  bpurchase: 'bpurchase',
  bcampaign: 'bcampaign',
  discount: 'discount',
  bclose: 'bclose',
  fleet: 'fleet',
  refinance: 'refinance',
  // ECONOMIC PLAN NAVIGABLE MENU
  totals: 'totals',
  updatedTotals: 'updatedTotals',
  taxes: 'taxes',
  vehicle_amount: 'vehicle_amount',
  campaign_discount: 'campaign_discount',
  additional_discount: 'additional_discount',
  optional: 'optional',
  sale_others: 'sale_others',
  supplied: 'supplied',
  costs_gifts: 'costs_gifts',
  associated_costs: 'associated_costs',
  reaconditioning: 'reaconditioning',
  bservice: 'bservice',
  self_supply: 'self_supply',
  entry: 'entry',
  reception: 'reception',
  dossierBudget: 'dossierBudget',
  price_change: 'price_change',
  dossier_rating: 'dossier_rating',
  cleaning: 'cleaning',
  checklist: 'checklist',
  reconditioning: 'reconditioning',
  crane: 'crane',
  photographs: 'photographs',
  booking_and_delivery: 'booking_and_delivery'
}

export const buttonsSidebarId = {
  1: 'bsale',
  2: 'vehicle',
  3: 'entity',
  4: 'accessory',
  5: 'optional',
  6: 'no_recover',
  7: 'bcampaign',
  8: 'uac',
  9: 'service',
  10: 'finance',
  11: 'paperwork',
  12: 'charge',
  13: 'economic',
  14: 'delivery',
  15: 'comment',
  16: 'bpaperwork',
  17: 'bpurchase',
  18: 'campaign',
  19: 'discount',
  20: 'bdamageclaim',
  21: 'damages',
  22: 'bclose',
  23: 'bservice',
  24: 'fleet',
  25: 'self_supply',
  26: 'refinance',
  27: 'other_data',
  28: 'brokerage',
  29: 'entry',
  31: 'reception',
  33: 'dossierBudget',
  38: 'price_change',
  32: 'dossier_rating',
  34: 'cleaning',
  35: 'reconditioning',
  36: 'checklist',
  39: 'crane',
  37: 'photographs',
  40: 'booking_and_delivery'
}

export const buttonsSidebarGetId = {
  bsale: 1,
  vehicle: 2,
  entity: 3,
  accessory: 4,
  optional: 5,
  no_recover: 6,
  campaign: 7,
  uac: 8,
  service: 9,
  finance: 10,
  paperwork: 11,
  charge: 12,
  economic: 13,
  delivery: 14,
  comment: 15,
  bpaperwork: 16,
  bpurchase: 17,
  bcampaign: 18,
  discount: 19,
  bdamageclaim: 20,
  damages: 21,
  bclose: 22,
  bservice: 23,
  fleet: 24,
  self_supply: 25,
  refinance: 26,
  other_data: 27,
  brokerage: 28,
  entry: 29,
  stock: 30,
  reception: 31,
  dossierBudget: 33,
  price_change: 38,
  dossier_rating: 32,
  cleaning: 34,
  reconditioning: 35,
  checklist: 36,
  crane: 39,
  photographs: 37,
  booking_and_delivery: 40
}
