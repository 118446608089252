import React, { PureComponent } from 'react'
import { Modal, Col, Button } from 'react-bootstrap'

class FaqModal extends PureComponent {
  constructor () {
    super()
    this.state = {
      positiveIco: 'glyphicon glyphicon-thumbs-up',
      negativeIco: 'glyphicon glyphicon-thumbs-down',
      voted: false
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps && nextProps.questionId && (!this.props || !this.props.questionId || this.props.questionId !== nextProps.questionId)) {
      this.setState({ positiveIco: 'glyphicon glyphicon-thumbs-up', negativeIco: 'glyphicon glyphicon-thumbs-down', voted: false })
      this.props.actions.fetchQuestion(nextProps.questionId)
    }
  }

  vote (isPositive) {
    if (!this.state.voted) {
      if (isPositive) {
        this.setState({ positiveIco: this.state.positiveIco + ' voted votedUp', negativeIco: this.state.negativeIco + ' voted', voted: true })
      } else {
        this.setState({ negativeIco: this.state.negativeIco + ' voted votedDown', positiveIco: this.state.positiveIco + ' voted', voted: true })
      }
      this.props.actions.voteQuestion(this.props.question.faqId, isPositive)
    }
  }

  render () {
    const {
      t, showModal, question, closeModal
    } = this.props

    const tKey = 'FAQ.MODAL.'
    return (
      <Modal show={showModal} bsSize="large">
        <Modal.Header closeButton onHide={closeModal} >
          <Modal.Title>
            {question && question.title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div dangerouslySetInnerHTML={{ __html: question && question.description.replace('<br/>', '\n') }} />
        </Modal.Body>
        <Modal.Footer>
          <div className="faq-assessment">
            <p className="faq-assessment-text">
              {t(`${tKey}ASSESSMENT_QUESTION`)}
            </p>
            <i className={this.state.positiveIco} onClick={() => { this.vote(true) }}/>
            <i className={this.state.negativeIco} onClick={() => { this.vote(false) }}/>
          </div>
          <Col sm={6} className="btn-wrapper">
            <Button className="btn-standard btn-modal" onClick={() => { closeModal() }}>
              {t(`${tKey}CLOSE_BTN`)}
            </Button>
          </Col>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default FaqModal
