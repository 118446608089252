import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { closeModal } from '../../actions/common.js'
import { nOkResponse, okResponse, valueResponse } from '../../actions/modals/customModal'
import CustomModal from '../../components/commons/CustomModal'
import { translate } from 'react-polyglot'
// import { withRouter } from 'react-router-dom'

export function mapStateToProps (state) {
  return {
    ...state.common.modal
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      closeModal,
      nOkResponse,
      okResponse,
      valueResponse
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(CustomModal))
