import apiFetch from '../apiFetch'

export default function (token, { page, pageSize, orderBy, massiveDownloadStatesId, creationDateTo, creationDateFrom, emailUser, fileName }) {
  const queryParamsArray = [
    page ? `page=${page}` : null,
    pageSize ? `pageSize=${pageSize}` : null,
    orderBy ? `orderBy=${orderBy}` : 'orderBy=null',
    massiveDownloadStatesId ? `massiveDownloadStatesId=${massiveDownloadStatesId}` : null,
    creationDateTo ? `creationDateTo=${creationDateTo}` : null,
    creationDateFrom ? `creationDateFrom=${creationDateFrom}` : null,
    emailUser ? `emailUser=${emailUser}` : null,
    fileName ? `fileName=${fileName}` : null
  ]
  const queryParams = queryParamsArray.reduce(
    (result, query, idx) => result + (query ? (result ? `&${query}` : `?${query}`) : '')
    , ''
  )
  return apiFetch({
    endPoint: `MassiveDownloadFinder${queryParams}`,
    method: 'GET',
    body: null,
    token: token
  })
}
