import React, { PureComponent } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import EntityPopover from '../../search/EntityPopover'
import DossierPopover from '../../search/DossierPopover'
import settings from '../../../setting'
import VehiclePopover from '../../search/VehiclePopover'
import { dashboardSalesmanColumnDossierSubType, urgencyTypesClass, urgencyTypesName, clientInformation } from '../../../constants/backendIds'

class DossierListRowItemComplete extends PureComponent {
  render () {
    const {
      t,
      dossier: {
        entity,
        vehicle,
        dossier,
        organizedUnit,
        salesman,
        salesmanManager,
        dossierNew,
        lastUpdateDate,
        entityChild,
        contact
      },
      dossierSubType,
      idr
    } = this.props
    const urgenciaTooltip = (
      <Tooltip id="urgenciaTooltip">
        {t('DASHBOARD.DOSSIERS_LIST.TABLE.URGENCY') + ' '}
        <span className="nombreUrgenciaTooltip">{t('DASHBOARD.DOSSIERS_LIST.TABLE.URGENCY_TYPES.' + urgencyTypesName[dossier.urgencyType])}</span>
      </Tooltip>
    )
    return (
      <tr>
        <td className="urgency">
          {
            dossierNew && (
              <span className="new-dossier">{t('DASHBOARD.DOSSIERS_LIST.TABLE.NEW')}</span>
            )
          }
          <OverlayTrigger placement="right" overlay={urgenciaTooltip}>
            <span className={'color-point ' + urgencyTypesClass[dossier.urgencyType]}/>
          </OverlayTrigger>
        </td>
        <td className={'dossier see-more'}>
          <DossierPopover
            idx={idr}
            t={t}
            id={'popover-dossier' + idr}
            dossier={dossier}
          />
        </td>
        {
          dashboardSalesmanColumnDossierSubType.includes(parseInt(dossierSubType)) && (
            <td>{salesman}</td>
          )
        }
        {
          dashboardSalesmanColumnDossierSubType.includes(parseInt(dossierSubType)) && settings.clientFieldValidation === clientInformation.QUADIS && (
            <td>{salesmanManager}</td>
          )
        }
        <td>
          {organizedUnit}
        </td>
        <td className={'entity see-more'}>
          {
            entity && entity.entityName && entity.entityName !== '' && (
              <EntityPopover
                idx={idr}
                t={t}
                id={'popover-entity' + idr}
                entity={entity}
                entityChild={entityChild}
                contact={contact}
              />
            )
          }
        </td>
        <td className={'vehicle see-more'}>
          <VehiclePopover
            idx={idr}
            t={t}
            id={'popover-vehicle' + idr}
            vehicle={vehicle}
            dossierSubTypeId={dossierSubType && parseInt(dossierSubType)}
          />
        </td>
        {
          settings.clientFieldValidation === 'vgr' ? [
            (<td key='productionWeek'>{dossier.productionWeek}</td>),
            (<td key='deliverydate'>{dossier.deliveryDate}</td>)
          ] : [
            (<td key ='lastUpdateDate'>{lastUpdateDate}</td>)
          ]
        }
      </tr>
    )
  }
}

export default DossierListRowItemComplete
