import React, { PureComponent } from 'react'
import { Modal, Col, Row, Form, Button, OverlayTrigger, Tooltip, Table } from 'react-bootstrap'
import { reduxForm } from 'redux-form'
import settings from '../../../setting'
import DropZone from 'react-dropzone'
const averageCharacter = 'c'

class DocumentMassUpload extends PureComponent {
  constructor () {
    super()
    this.state = {
      documentName: '',
      documentTitle: averageCharacter,
      showTitle: false,
      files: [],
      showRejectCommentModal: false,
      hasNewDocument: false,
      showFileUploader: true,
      hasInvalidFile: false,
      showDocumentCarrusel: false,
      autoCloseDocumentCarrusel: false,
      isDocumentCarruselEndReached: false,
      showPageCarrusel: true,
      autoClosePageCarrusel: false,
      documentLoaded: false,
      zoomProps: {
        width: 0,
        height: 0,
        zoomWidth: 0,
        scale: 0.9,
        img: '',
        offset: { vertical: 0, horizontal: 10 },
        zoomStyle: 'box-shadow: 0px 2px 5px rgba(0,0,0,0.3)',
        zoomLensStyle: 'background-color: rgba(0,0,0,0.3); cursor: crosshair;'
      },
      hasDropzoneImg: false,
      parametroFecha: Date.now(),
      dropzoneActive: false,
      dropzonePanelActive: false,
      documentNames: []
    }

    this.onDrop = this.onDrop.bind(this)

    this.cancelDocument.bind(this)

    this.documentTitleElementRef = null
    this.buttonUpload = false
    this.buttonReject = false
    this.setDocumentTitleElementRef = element => {
      this.documentTitleElementRef = element
    }
  }

  cancelDocument () {
    this.closeModal(true)
  }

  closeModal (canShowAdvise = true) {
    new Promise((resolve, reject) => {
      return this.props.actions.checkCanCloseDocumentModal(canShowAdvise && this.state.documentLoaded, resolve)
    }).then((result) => {
      if (result) {
        this.props.closeCommonModal && this.props.actions.okResponse()
        this.props.actions.closeModalMassSuccess()
        this.setState({
          documentTitle: averageCharacter,
          showTitle: false,
          files: [],
          hasNewDocument: false,
          showFileUploader: true,
          hasInvalidFile: false,
          zoomProps: { ...this.state.zoomProps, width: 0, height: 0, zoomWidth: 0, img: '' },
          hasDropzoneImg: false,
          documentLoaded: false,
          dropzoneActive: false,
          dropzonePanelActive: false,
          documentName: '',
          documentNames: []
        })
        this.buttonUpload = false
        this.buttonReject = false
        this.props.initialize({})
        this.props.closeModal()
      }
    })
  }

  onDrop (fileToUpload, rejectedFiles) {
    if (fileToUpload.length === 0 && rejectedFiles.length > 0) {
      this.setState({ hasInvalidFile: true })
    } else {
      if (this.state.files.length > 0) this.props.actions.resetModalMassUpload()
      this.props.acceptZip ? this.setState({ files: [fileToUpload], hasNewDocument: true, hasInvalidFile: false }) : this.setState({ files: [fileToUpload[0]], hasNewDocument: true, hasInvalidFile: false })
      if (fileToUpload[0]) {
        this.buttonUpload = true
        this.buttonReject = true
        this.setState({
          documentLoaded: false,
          showFileUploader: false
        })
        if (!this.props.acceptZip) {
          this.setState({ documentName: fileToUpload[0].name })
        } else {
          let documentNames = []
          fileToUpload.map((fil, ind) => {
            documentNames.push(fil.name)
          })
          this.setState({ documentNames })
        }
      }
    }
  }

  onDragEnter (panel = false) {
    this.setState(panel ? { dropzonePanelActive: true } : { dropzoneActive: true })
  }

  onDragLeave (panel = false) {
    this.setState(panel ? { dropzonePanelActive: false } : { dropzoneActive: false })
  }

  submitDocument (canShowAdvise = true) {
    new Promise((resolve, reject) => {
      let nfiles = this.state.files.length
      let lastFile = this.state.files[nfiles - 1]
      return this.props.acceptZip ? this.props.massAction(this.state.files, this.props.code, this.props.callback, resolve, this.props.t) : this.props.massAction(lastFile, this.props.code, this.props.callback, resolve, this.props.t)
    }).then((result) => {
      if (result) {
        this.setState({
          documentTitle: averageCharacter,
          showTitle: false,
          files: [],
          hasNewDocument: false,
          showFileUploader: true,
          hasInvalidFile: false,
          zoomProps: { ...this.state.zoomProps, width: 0, height: 0, zoomWidth: 0, img: '' },
          hasDropzoneImg: false,
          documentLoaded: false,
          dropzoneActive: false,
          dropzonePanelActive: false
        })
        this.buttonUpload = false
        this.buttonReject = false
        this.props.initialize({})
      }
    })
  }

  getDocumentTitle (props) {
    const tKey = 'DASHBOARD.UPLOAD_MANAGEMENT.UPLOAD_DOCUMENT_FLEET.'
    return (props.docResult.documentId ? props.t(`${tKey}EDIT`) : props.t(`${tKey}ADD`)) + (props.documentTypeUseName ? (' - ' + props.documentTypeUseName) : '')
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (prevProps.showModal && !this.props.showModal) {
      this.setState({
        documentTitle: averageCharacter,
        showTitle: false,
        files: [],
        dropzoneActive: false,
        dropzonePanelActive: false
      })
    }

    return this.props
  }

  componentDidUpdate () {
  }

  componentDidMount () {
    this.setState({
      zoomProps: {
        ...this.state.zoomProps,
        img: `${settings.url}/Document/GetImage?token=${this.props.token}&fecha=${this.state.parametroFecha}`
      }
    })
  }

  render () {
    const { t, showModal, handleSubmit, tkey, items, errors, succes, showSuccesMessage, showMessageError, docErrors } = this.props
    const acceptFilesDropzone = settings.acceptFileFormats
    const maxSizeFilesDropzone = 10240000
    const documentTitleTooltip = (<Tooltip id="documentTitleTooltip">{t(`${tkey}UPLOAD_DOCUMENT`)}</Tooltip>)
    return (
      <Modal show={showModal} bsSize="large" onHide={() => this.closeModal()} backdrop={'static'}>
        <Modal.Header closeButton >
          <Modal.Title>
            <span className="document-title-ellipsis">
              <OverlayTrigger placement="left" overlay={documentTitleTooltip}>
                <span id="documentTitle" ref={this.setDocumentTitleElementRef} className={'document-title'}>{t(`${tkey}UPLOAD_DOCUMENT`)}</span>
              </OverlayTrigger>
              <br />
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={12} className="input-dropzone-wrapper">
              <Row className="form-group">
                {
                  <Col sm={12} className={'document-edition-modal-middle-side'}>
                    <div className={'input-dropzone'} style={{ height: '100%' }}>
                      {
                        <DropZone
                          id="dropZone"
                          multiple={this.props.acceptZip ? this.props.acceptZip : false}
                          onDrop={(acceptedFiles, rejectedFiles) => this.onDrop(acceptedFiles, rejectedFiles)}
                          disabled={false}
                          accept={this.props.acceptZip ? acceptFilesDropzone + ', .zip' : acceptFilesDropzone}
                          maxSize={maxSizeFilesDropzone}
                          style={{ height: '100%' }}
                          className={this.state.dropzoneActive ? 'active' : ''}
                          onDragEnter={() => this.onDragEnter(false)}
                          onDragLeave={() => this.onDragLeave(false)}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()}>
                              <input {...getInputProps()} />
                              {this.state.documentName !== '' || this.state.documentNames.length > 0 ? (
                                <div className="text-wrapper">
                                  {t(`${tkey}SELECTED_FILES`)}: {this.state.documentName !== '' ? this.state.documentName
                                    : this.state.documentNames.map((nam, i) => {
                                      return nam + ', \n'
                                    })}
                                </div>
                              ) : (
                                <div className="text-wrapper modal-subir-fichero-inicio">
                                  <div>
                                    <i className='ico-cloud' />
                                    <h2 key="dropZone-title">{t(`${tkey}DROP_FILES`)}</h2>
                                    <p key="dropZone-subtitle">{t(`${tkey}SELECT_FILES`)}</p>
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                        </DropZone>
                      }
                    </div>
                  </Col>
                }
              </Row>
              {
                !this.state.showFileUploader && (
                  <Row className="form-group">
                    <Col sm={12} className="document-edition-modal-middle-side" >
                      <div className="btn-wrapper">
                        {
                          (this.buttonUpload || this.buttonReject) && !showSuccesMessage ? [
                            this.buttonUpload && !succes && (<Button
                              id="btn_validate"
                              key="btn_validate"
                              className="btn-validate-white"
                              style={{ marginRight: '10px' }}
                              onClick={handleSubmit(values => {
                                this.submitDocument({ ...values, validateAction: false })
                              })}
                              disabled={succes}
                            ><i className="ico-check" /> {t(`${tkey}TEST`)}</Button>),
                            this.buttonReject && (
                              <Button
                                id="btn_reject"
                                key="btn_reject"
                                className="btn-reject-white"
                                style={{ marginRight: '10px' }}
                                onClick={() => this.closeModal(true)}
                              ><i className="ico-cancel" /> {t(`${tkey}REJECT_BTN`)}</Button>
                            )
                          ] : null
                        }
                      </div>
                    </Col>
                  </Row>
                )
              }
              {items && items.length > 0 && (<Row>{t(`${tkey}TITTLE_SUCCES`)}</Row>)}
              {items && items.length > 0 &&
                (<Table className="editable-table" hover responsive>
                  <thead>
                    <tr>
                      {Object.keys(items[0]).map((k, ind) => {
                        return (<th key={ind}>{t(tkey + k.toUpperCase())}</th>)
                      })
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {items.map((it, i) => {
                      return (
                        <tr key={i}>
                          {Object.keys(it).map((key, ind) => {
                            return typeof (it[key]) === 'boolean' ? <td key={ind}><i className="ico-check" /></td> : (<td key={ind}>{it[key]}</td>)
                          })}
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>)
              }

              {errors && errors.length > 0 && (<Row>{t(`${tkey}TITTLE_ERROR`)}</Row>)}
              {errors && errors.length > 0 &&
                (<Table className="editable-table" hover responsive>
                  <thead>
                    <tr>
                      {Object.keys(errors[0]).map((k, ind) => {
                        return (<th key={ind}>{t(tkey + k.toUpperCase())}</th>)
                      })
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {errors.map((er, i) => {
                      return (
                        <tr key={i} className='is-deleted'>
                          {Object.keys(er).map((key, ind) => {
                            return (<td key={ind}>{er[key]}</td>)
                          })}
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>)
              }
              {docErrors && docErrors.length > 0 && (<Row>{t(`${tkey}TITTLE_ERROR`)}</Row>)}
              {docErrors && docErrors.length > 0 &&
                (<Table className="editable-table" hover responsive>
                  <thead>
                    <tr>
                      {Object.keys(docErrors[0]).map((k, ind) => {
                        return (<th key={ind}>{t(tkey + k.toUpperCase())}</th>)
                      })
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {docErrors.map((er, i) => {
                      return (
                        <tr key={i} className='is-deleted'>
                          {Object.keys(er).map((key, ind) => {
                            return (<td key={ind}>{er[key]}</td>)
                          })}
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>)
              }
              {!showSuccesMessage && succes &&
                (<Button
                  id="btn_validate"
                  key="btn_validate"
                  className="btn-validate-white"
                  style={{ marginRight: '10px' }}
                  onClick={handleSubmit(values => {
                    this.submitDocument({ ...values, validateAction: false })
                  })}
                ><i className="ico-check" /> {t(`${tkey}UPLOAD_BTN`)}</Button>
                )
              }
              {showSuccesMessage &&
                (<Row>{t(`${tkey}SUCCES_MESSAGE`)}</Row>)
              }
              {showMessageError &&
                (<Row>{t(tkey + showMessageError)}</Row>)
              }
            </Col>
            <Col sm={6} className="document-edition-modal-form">
              <Form autoComplete="off" style={{ height: '100%' }} onSubmit={handleSubmit(values => {
                this.submitDocument(values)
              })}>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    )
  }
}

export default reduxForm({
  form: 'DocumentMassUpload',
  destroyOnUnmount: false
})(DocumentMassUpload)
