import React, { PureComponent } from 'react'
import { OverlayTrigger, Tooltip, DropdownButton } from 'react-bootstrap'
import { urgencyTypesId, urgencyTypesClass, permissions } from '../../../../constants/backendIds'
import { /* compareArrays, */ compareObjects } from '../../../../util/utils'
import { getHeaderPermisions } from '../../../../util/permisionFunctions'
import { GoBackTooltip } from '../../../../components/commons/Tooltips'
// import TimeLine from '../../../../components/dossiers/common/statusHeader/TimeLine'
// import StageHeader from '../../../../components/dossiers/common/statusHeader/StageHeader'
import settings from '../../../../setting'

class StatusHeader extends PureComponent {
  constructor () {
    super()
    this.state = {
      isVehicleLoaded: false,
      isClientLoaded: false,
      isFinanceLoaded: false,
      isDeliveryLoaded: false,
      isEconomicLoaded: false,
      isSelfSupplyLoaded: false,
      componentCode: 1,
      componentsConfiguration: {
        summary: {
          code: 'summary',
          permission: permissions.hidden
        },
        stages: {
          code: 'stages',
          permission: permissions.hidden
        },
        checklist: {
          code: 'checklist',
          permission: permissions.hidden
        },
        qrcode: {
          code: 'qrcode',
          permission: permissions.hidden
        }
      }
    }
  }

  initializePermisions (props) {
    const newState = getHeaderPermisions(props.headerTabConfiguration)
    this.setState({ ...this.state, componentsConfiguration: { ...newState } })
  }

  handleClick () {
    this.props.actions.toggleTimeline()
  }

  calcStatusHeaderHeight () {
    const header = document.querySelector('.navbar-headerPage') || {}
    const statusSidebar = document.querySelector('#dossier-header .dossier-header') || {}
    return header.clientHeight + statusSidebar.clientHeight + 1
  }

  UNSAFE_componentWillMount () {
    if (this.props.headerTabConfiguration) this.initializePermisions(this.props)
  }

  componentDidMount () {
    this.props.actions.setStatusHeaderHeight(this.calcStatusHeaderHeight())
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.headerTabConfiguration && !compareObjects(this.props.headerTabConfiguration || {}, nextProps.headerTabConfiguration, true)) {
      this.initializePermisions(nextProps)
    }
  }

  componentDidUpdate (prevProps) {
    /* if (prevProps.isFolded !== this.props.isFolded || prevProps.windowWidth !== this.props.windowWidth || !compareArrays(prevProps.stages, this.props.stages, true)) {
      this.props.actions.setStatusHeaderHeight(this.calcStatusHeaderHeight())
    } */
    this.props.actions.setStatusHeaderHeight(this.calcStatusHeaderHeight())
  }

  componentWillUnmount () {
    this.props.actions.resetStatusHeader()
  }

  handleToUpdateVehicle () {
    this.setState({ isVehicleLoaded: true })
  }

  handleToUpdateClient () {
    this.setState({ isClientLoaded: true })
  }

  handleToUpdateFinance () {
    this.setState({ isFinanceLoaded: true })
  }

  handleToUpdateDelivery () {
    this.setState({ isDeliveryLoaded: true })
  }

  handleToUpdateEconomic () {
    this.setState({ isEconomicLoaded: true })
  }

  switchFavorite (currentFavoriteStatus) {
    this.props.actions.switchFavorite(currentFavoriteStatus, this.props.dossierId, this.props.isHistorical)
  }

  getDossierTitle () {
    if (this.props.headerData.dealerNumber) {
      return (
        <div className='element'>
          <OverlayTrigger placement="left" overlay={<Tooltip id="dealerNumber">{this.props.t('DOSSIER_COMPONENTS.PURCHASE_DOSSIER.PURCHASE_COMPONENT.DEALER_NUMBER')}</Tooltip>}>
            <i className={'dossierMainInfoIcon ' + 'iDocIcon-receipt-sale'} />
          </OverlayTrigger>
          <span>{this.props.headerData.dealerNumber}</span>
        </div>
      )
    }
    return (
      <div className='element'>
        <OverlayTrigger placement="left" overlay={<Tooltip id="expNunmber">{this.props.t('DOSSIER_COMPONENTS.SALE_SERVICES.DOSSIER_NUMBER')}</Tooltip>}>
          <i className={'dossierMainInfoIcon ' + 'iDocIcon-file-folder'} />
        </OverlayTrigger>
        <span>{this.props.headerData.number}</span>
      </div>
    )
  }

  getEntityTitle () {
    let entityDni = ''
    let entityName = ''
    if (this.props.entityComponent) {
      if (this.props.entityComponent.dniCif) {
        entityDni = this.props.entityComponent.dniCif
      }
      if (this.props.entityComponent.name) {
        entityName = this.props.entityComponent.name
      }
      if (this.props.entityComponent.surname) {
        entityName += ' ' + this.props.entityComponent.surname
      }
    }
    if (entityDni === '' && entityName === '') {
      return ''
    } else {
      return (
        <div className='element entity'>
          <OverlayTrigger placement="left" overlay={<Tooltip id="expNunmber">{this.props.t('DOSSIER_COMPONENTS.SALE_SERVICES.ENTITY')}</Tooltip>}>
            <i className={'dossierMainInfoIcon ' + 'iDocIcon-user-no-image-user-menu'} />
          </OverlayTrigger>
          <div className='entityName'>
            <span>{entityName}</span>
            <span>{entityDni}</span>
          </div>

        </div>
      )
    }
  }

  getVehicleTitle () {
    if (this.props.vehicleComponent) {
      if (this.props.vehicleComponent.licensePlate) {
        return (
          <div className='element'>
            <OverlayTrigger placement="left" overlay={<Tooltip id="expNunmber">{this.props.t('REPORTS.RECEIPT.LICENSEPLATE')}</Tooltip>}>
              <i className={'dossierMainInfoIcon ' + 'iDocIcon-plate'} />
            </OverlayTrigger>
            <span>{this.props.vehicleComponent.licensePlate}</span>
          </div>
        )
      }
      if (this.props.vehicleComponent.vinChassis) {
        return (
          <div className='element'>
            <OverlayTrigger placement="left" overlay={<Tooltip id="expNunmber">{this.props.t('REPORTS.RECEIPT.VINCHASSIS')}</Tooltip>}>
              <i className={'dossierMainInfoIcon ' + 'iDocIcon-abc-vin'} />
            </OverlayTrigger>
            <span>{this.props.vehicleComponent.vinChassis}</span>
          </div>
        )
      }
    }
    return ''
  }

  render () {
    const {
      t, headerData, favorite, timeLinePosition, isHistorical
    } = this.props

    const tKey = 'DOSSIER_COMPONENTS.HEADER.'
    const tooltipUrgency = (
      <Tooltip container={this} id="tooltipUrgency">
        {headerData.urgencyType === urgencyTypesId.high && t(`${tKey}URGENCY_TYPES.EXPIRED`)}
        {headerData.urgencyType === urgencyTypesId.medium && t(`${tKey}URGENCY_TYPES.ABOUT_TO_EXPIRE`)}
        {headerData.urgencyType === urgencyTypesId.low && t(`${tKey}URGENCY_TYPES.ON_TIME`)}
        &nbsp;
        {headerData.urgencyType === urgencyTypesId.high ? t(`${tKey}FROM`) : t(`${tKey}TO`)}
        &nbsp;
        {headerData.urgencyDate}
      </Tooltip>
    )

    const FavoriteTooltip = (
      <Tooltip id="FavoriteTooltip">
        {favorite === true ? (t(`${tKey}FAVORITES.UNMARK`)) : (t(`${tKey}FAVORITES.MARK`))}
      </Tooltip>
    )
    const TimeLinePositionTooltip = (
      <Tooltip id="FavoriteTooltip">
        {timeLinePosition === true ? (t(`${tKey}SHOW_TOP`)) : (t(`${tKey}SHOW_IN_TAB`))}
      </Tooltip>
    )

    switch (this.state.componentCode) {
      case 1:
      { this.showInfoType = [this.getDossierTitle(), this.getEntityTitle(), this.getVehicleTitle()]
        break }
      case 2:
      { this.showInfoType = this.getEntityTitle()
        break }
      case 3:
      { this.showInfoType = this.getVehicleTitle()
        break }
      default:
      { this.showInfoType = this.getDossierTitle()
        break }
    }

    return (
      <div className='dossierHeader'>
        <div className='dossierHeader-info'>
          <div className='goBack'>
            <OverlayTrigger container={document.getElementById('symb')} placement="bottom" overlay={GoBackTooltip({ t: t })}>
              <a className="_Btn accept stk o-icn" onClick={() => this.props.goBack()}><i className="iDocIcon-arrow-left-back"></i></a>
            </OverlayTrigger>
          </div>
          <div className='dossierUrgency'>
            <OverlayTrigger placement="right" overlay={tooltipUrgency} >
              <span id="colorUrgency" className={'cursorHelp colorUrgency ' + urgencyTypesClass[headerData.urgencyType]}/>
            </OverlayTrigger>
          </div>
          <div className='favotieMark'>
            {settings.enableFavoritesAndRecents &&
              <OverlayTrigger placement="bottom" overlay={FavoriteTooltip}>
                <a id='Favorite' className='_Btn accept stk o-icn noLine' onClick={() => this.switchFavorite(favorite)}><i className={favorite ? 'iDocIcon-favorite' : 'iDocIcon-unfavorite'}></i></a>
              </OverlayTrigger>
            }
          </div>
          <div className={'dossierSecondaryInfo' + (isHistorical ? ' historical' : '')}>
            <span className='uo'>{headerData.organizedUnit}</span>
            <div className='dossierStatus'>
              <span>{headerData.dossierSubType}</span> | <span>{headerData.stage}</span>
            </div>
          </div>

          <div className='dossierMainInfo'><>{this.showInfoType}</></div>

        </div>
        <div className='dossierHeader-actions'>
          {!isHistorical && <OverlayTrigger placement="bottom" overlay={TimeLinePositionTooltip}>
            <a className="_Btn accept o-icn" onClick={() => this.props.toggleShowTimelineInTab()}><i className={timeLinePosition ? 'iDocIcon-time-line-top' : 'iDocIcon-time-line-tab'}></i></a>
          </OverlayTrigger>}
          <DropdownButton
            as={'ButtonGroup'}
            key={1}
            drop={'start'}
            className={'_Btn accept stk o-icn noLine hide'}
            title={<i class="iDocIcon-actions-menu-more"></i> }
            id="dropdown-button-drop-start">
            <li><a id="btn_observations" className="" >Nombre del boton<i class="iDocIcon-file-document"></i></a></li>
            <li><a id="btn_observations" className="" >Nombre del boton<i class="iDocIcon-file-document"></i></a></li>
            <li><a id="btn_observations" className="" >Nombre del boton<i class="iDocIcon-file-document"></i></a></li>
            <li><a id="btn_observations" className="" >Nombre del boton<i class="iDocIcon-file-document"></i></a></li>
            <li><a id="btn_observations" className="" >Nombre del boton<i class="iDocIcon-file-document"></i></a></li>
            <li><a id="btn_observations" className="" >Nombre del boton<i class="iDocIcon-file-document"></i></a></li>
            <a className="" id="confirmCancel">
                Cancel
              <i class="iDocIcon-file-document"></i>
            </a>
          </DropdownButton>

        </div>
      </div>
    )
  }
}

export default StatusHeader
