import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { isInvalid, getFormSyncErrors, getFormValues, stopAsyncValidation } from 'redux-form'
import { translate } from 'react-polyglot'
// import { push } from 'react-router-redux'
import PaperworkComponent from '../../../components/dossiers/paperwork/Paperwork'
import PaperworkComponent_v2 from '../../../_v2/components/dossiers/paperwork/Paperwork'
import messageModalActions from '../../../actions/modals/messageModal'
import dossierPaperworkActions from '../../../actions/dossier/paperwork'
import commentsActions from '../../../actions/dossier/common/comments'
import clientActions from '../../../actions/dossier/common/client'
import vehicleActions from '../../../actions/dossier/common/vehicle'
import commonHeaderActions from '../../../actions/dossier/common/commonHeader'
import { storeDossier } from '../../../actions/dossier/commonDossierActions'
import {
  fetchEntitySubTypeCombo, fetchCancelCombo, fetchEntityFilterSubTypeCombo, fetchReasons, fetchAllReasons, fetchCommercialSocietyTypeCombo,
  fetchEntityCountryTypeCombo, fetchEntityStateTypeCombo, fetchEntityCityTypeCombo, fetchEntityTownTypeCombo, fetchEntityColonyTypeCombo, fetchEntityCPTypeCombo,
  fetchVoCompaniesCombo, fetchServiceDestinationTypes, fetchLowestOrganizedUnits, fetchVehicleType, fecthOrganizedUnitsByDossierUo, fecthFamily, fetchActivityBpro,
  fetchClientDataAddress, fetchProcessingAgencyManagersCombo
} from '../../../actions/combos/combos'
import { openModal as openCommonModal } from '../../../actions/common'
import { openDocumentManagementModal as openDocumentModal } from '../../../actions/dossier/common/documentManagementModal'
import { openDocumentEditionModal, closeAddOrEditDocumentModal } from '../../../actions/dossier/common/documentEditionModal'
import scrollerActions from '../../../actions/commons/scroller'
import buttonsHeaderActions from '../../../actions/dossier/common/buttonsHeader'
import buttonsSidebarActions from '../../../actions/dossier/common/buttonsSidebar'
import { fetchFilteredRelatedDossier } from '../../../actions/dossier/common/dossierRelatedDossiers'
import { getChangeUo, getSalesmanChangeUo, postUpdateChangeUo } from '../../../actions/dossier/common/changeUo'
import { checkPrivateRfcFormat, foreignPassportWarning } from '../../../actions/dossier/sales'
import {
  fetchCustomFields
} from '../../../actions/dossier/customFields'
import { validate } from '../../../actions/dossier/common/validations'
import { registerLastAccess } from '../../../actions/dossier/common/lastAccessDossier'
import { getStorageValue } from '../../../storage/storage'
import TrackingContainer from '../../tracking/trackingContainer'

export function mapStateToProps (state) {
  return {
    vehicleProps: state.vehicle,
    dossier: state.paperworkDossier,
    combos: state.combos,
    showManagementModal: state.documentaryManagementModal.showModal,
    windowWidth: state.windowDimensions.width,
    statusHeaderHeight: state.statusHeader.height,
    activeTab: state.buttonsHeader.activeTab,
    buttonsHeaderHeight: state.buttonsHeader.height,
    activeSection: state.buttonsSidebar.activeSection,
    relatedDossiersOrderBy: state.dossierRelatedDossiers.orderby,
    isInvalid: isInvalid('paperwork_dossier')(state),
    formErrors: getFormSyncErrors('paperwork_dossier')(state),
    formValues: getFormValues('paperwork_dossier')(state),
    changeUo: state.changeUo,
    comment: state.comment,
    reason: state.reason,
    cancelCombo: state.combos.cancelCombo,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      ...dossierPaperworkActions,
      ...commentsActions,
      ...clientActions,
      ...vehicleActions,
      ...commonHeaderActions,
      ...buttonsHeaderActions,
      ...buttonsSidebarActions,
      ...scrollerActions,
      storeDossier,
      openDocumentEditionModal,
      closeAddOrEditDocumentModal,
      openCommonModal,
      fetchEntitySubTypeCombo,
      fetchEntityFilterSubTypeCombo,
      openDocumentModal,
      fetchFilteredRelatedDossier,
      // navigateTo: push,
      ...messageModalActions,
      fetchReasons,
      fetchAllReasons,
      fetchVoCompaniesCombo,
      fetchServiceDestinationTypes,
      fetchLowestOrganizedUnits,
      getChangeUo,
      getSalesmanChangeUo,
      postUpdateChangeUo,
      fetchVehicleType,
      fecthOrganizedUnitsByDossierUo,
      fecthFamily,
      fetchCancelCombo,
      checkPrivateRfcFormat,
      foreignPassportWarning,
      fetchCommercialSocietyTypeCombo,
      fetchEntityCountryTypeCombo,
      fetchEntityStateTypeCombo,
      fetchEntityCityTypeCombo,
      fetchEntityTownTypeCombo,
      fetchEntityColonyTypeCombo,
      fetchEntityCPTypeCombo,
      fetchActivityBpro,
      fetchCustomFields,
      validate,
      stopAsyncValidation,
      fetchClientDataAddress,
      fetchProcessingAgencyManagersCombo,
      registerLastAccess
    }, dispatch)
  }
}
let version = parseInt(getStorageValue('interfaceVersion'))
var decoratedComponent = null
if (version === 2) {
  decoratedComponent = TrackingContainer(PaperworkComponent_v2, true)
} else {
  decoratedComponent = TrackingContainer(PaperworkComponent, true)
}
export default connect(mapStateToProps, mapDispatchToProps)(translate()(decoratedComponent))
