import apiFetch from '../apiFetch'

export default function (token, filters, columns) {
  let finderParams = { ...filters, columns }
  return apiFetch({
    endPoint: 'DossierFinder/FleetsDossierDownload',
    method: 'POST',
    body: finderParams,
    token: token,
    parseBlobAndAssign: true
  })
}
