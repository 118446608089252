import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import FinancingTypeModal from '../../components/masters/FinancingTypeModal'
import { translate } from 'react-polyglot'
import { closeFinancingTypeModal, saveFinancingTypeModal, getAllFinancingTypeProduct } from '../../actions/masters/masters'

export function mapStateToProps (state) {
  return {
    showFinancingTypeModal: state.entityMasters.showFinancingTypeModal,
    languageList: state.entityMasters.languageList,
    financingTypeLanguage: state.entityMasters.financingTypeFields.financingTypeLanguage || [],
    financingTypeId: state.entityMasters.financingTypeId,
    organizedUnitId: state.entityMasters.financingTypeFields.organizedUnitId,
    financing: state.entityMasters.financing,
    reactivatable: state.entityMasters.financingTypeFields.reactivatable,
    financingTypeProductId: state.entityMasters.financingTypeFields.financingTypeProductId,
    repurchaseAgreement: state.entityMasters.financingTypeFields.repurchaseAgreement,
    financingTypeProducts: state.financingTypeProduct.typeProductsCombo,
    insuranceIncluded: state.financingTypeProduct.insuranceIncluded,
    maintenanceIncluded: state.financingTypeProduct.maintenanceIncluded,
    renovation: state.financingTypeProduct.renovation
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      closeFinancingTypeModal,
      saveFinancingTypeModal,
      getAllFinancingTypeProduct
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(FinancingTypeModal))
