import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { closeSignatureEntityMasterModal, submitSignatureEntity }
  from '../../../actions/masters/signatureConfiguration/signatureConfiguration'
import SignatureEntityMasterModal from '../../../components/masters/signatureConfig/SignatureEntityMasterModal'

export function mapStateToProps (state) {
  const signatureEntityModal = state.signatureConfig.signatureEntityModal
  return {
    showModal: signatureEntityModal.showModal,
    signatureEntity: signatureEntityModal.signatureEntity,
    combos: {
      signEntityTypeCombo: state.combos.signEntityTypeCombo,
      signTypeCombo: state.combos.signTypeCombo
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      submitSignatureEntity,
      closeSignatureEntityMasterModal
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(SignatureEntityMasterModal))
