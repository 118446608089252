import { put, call, select, takeEvery } from 'redux-saga/effects'
import { getAuth } from '../../../selectors/access/auth'
import { initialize, reset } from 'redux-form'
import { yesNoModal } from '../../modalsListeners/yesNoModal'
import dossierActionsTypes from '../../../constants/actions/dossier/dossier'
import { entityRequiredFields } from '../../../constants/validatedFields/entity'
import { dossierTypes as dossierTypesConstant } from '../../../constants/dossier/common/dossierTypes'
import { fetchSalesDossierSuccess, updateResultSubmit } from '../../../actions/dossier/sales'
import messageModalActions from '../../../actions/modals/messageModal'
import getEntityFilterSubType from '../../../services/entity/getEntityFilterSubType'
import putDossierSaleService from '../../../services/dossierSale/putDossierSale'
import putDossierPerformDoubleTransferFromSaleService from '../../../services/dossier/put/putDossierPerformDoubleTransferFromSale'
import { showLoader, hideLoader } from '../../../actions/common'
import { fetchCombosSuccess } from '../../../actions/combos/combos'
import { handleError } from '../../errors/errorManager'
import { compareObjects, getCurrentDate } from '../../../util/utils'
import { initializeDossier } from '../commonDossierSagas'

export function * saveSalesDossier ({ salesDossier, resolve, reject }) {
  let subDossier, auth, salesDossierState, lastAccesDate
  try {
    auth = yield select(getAuth)
    salesDossierState = yield select(state => state.salesDossier)
    let chargebackAmount = null
    const formValues = yield select(state => state.form.salesDossier.values)
    lastAccesDate = salesDossierState.lastAccessDate
    if (salesDossierState.salesComponent && formValues.salesComponent &&
        salesDossierState.salesComponent.doubleTransferCheck !== formValues.salesComponent.doubleTransferCheck &&
        formValues.salesComponent.doubleTransferCheck) {
      let confirmedDoubleTransfer = yield call(yesNoModal, 'dossierDoubleTransferConfirmation')
      formValues.salesComponent.doubleTransferCheck = confirmedDoubleTransfer
      if (confirmedDoubleTransfer) {
        yield put(showLoader())
        yield call(putDossierPerformDoubleTransferFromSaleService,
          salesDossier.dossierId,
          auth.token,
          lastAccesDate)

        lastAccesDate = getCurrentDate()
        yield put(hideLoader())
      } else {
        return
      }
    }
    if (formValues.dossierEconomicComponent && formValues.dossierEconomicComponent.chargebackAmount) {
      chargebackAmount = yield select(state => state.form.salesDossier.values.dossierEconomicComponent.chargebackAmount)
      if (!salesDossier.dossierEconomicComponent.updated && chargebackAmount !== salesDossierState.dossierEconomicComponent.chargebackAmount) {
        salesDossier.dossierEconomicComponent.updated = true
      }
    }
    const myEntityRequiredFields = entityRequiredFields(salesDossier.entityComponent ? salesDossier.entityComponent.entityTypeId : null)
    let willBeAnyImportantFieldUpdated = false
    let confirmedSaveWarning = true

    for (const prop in myEntityRequiredFields) {
      if (salesDossierState.entityComponent.entityId && salesDossier.entityComponent[prop] !== salesDossierState.entityComponent[prop]) {
        willBeAnyImportantFieldUpdated = true
        break
      }
    }
    if (willBeAnyImportantFieldUpdated || (salesDossierState.entityComponent && salesDossierState.entityComponent.previousEntity && salesDossierState.entityComponent.previousEntity.entityId &&
        salesDossier.entityComponent && salesDossier.entityComponent.entityId && salesDossier.entityComponent.entityId !== salesDossierState.entityComponent.previousEntity.entityId)) {
      confirmedSaveWarning = yield call(yesNoModal, 'commonEntitySaveWarning')
    }

    if (!compareObjects(salesDossierState.dossierCompleteOtherDataComponent || {}, salesDossier.dossierCompleteOtherDataComponent, true)) {
      salesDossier.dossierCompleteOtherDataComponent.updated = true
    }

    let dossierPaperworkComponent = salesDossier.dossierPaperworkComponent
    if (!compareObjects(salesDossierState.dossierPaperworkComponent || {}, salesDossier.dossierPaperworkComponent, true)) {
      dossierPaperworkComponent.updated = true
    }

    if (salesDossier.dossierContactComponent !== salesDossierState.dossierContactComponent) {
      salesDossier.dossierContactComponent.updated = true
    }

    if (confirmedSaveWarning) {
      yield put(showLoader())

      let dossierFinancingComponent = null
      if (salesDossier.dossierFinancingComponent && salesDossier.dossierFinancingComponent.updated &&
        salesDossier.dossierFinancingComponent && Object.keys(salesDossier.dossierFinancingComponent).length > 1) {
        dossierFinancingComponent = salesDossier.dossierFinancingComponent
      }
      subDossier = {
        dossierSaleComponent: salesDossier.salesComponent,
        dossierEntityComponent: salesDossier.entityComponent,
        dossierEntityChildComponent: salesDossier.entityChildComponent,
        dossierContactComponent: salesDossier.dossierContactComponent,
        dossierParentId: salesDossier.vehicleComponent.dossierId ? salesDossier.vehicleComponent.dossierId : salesDossier.purchasesDossierId,
        parentIsHistorical: salesDossierState.parentIsHistorical,
        dossierVehicleComponent: salesDossier.vehicleComponent,
        dossierFinancingComponent: dossierFinancingComponent,
        dossierDeliveryComponent: salesDossier.dossierCompleteDeliveryComponent.dossierDeliveryComponent,
        dossierSaleEconomicPlanComponent: {
          ...salesDossier.dossierEconomicComponent,
          discounts: salesDossierState.dossierEconomicComponent.discounts,
          extraSales: salesDossierState.dossierEconomicComponent.extraSales,
          optionals: salesDossierState.dossierEconomicComponent.optionals,
          presents: salesDossierState.dossierEconomicComponent.presents,
          supplieds: salesDossierState.dossierEconomicComponent.supplieds,
          associatedCosts: salesDossierState.dossierEconomicComponent.associatedCosts,
          chargebackAmount: chargebackAmount
        },
        dossierCompleteOtherDataComponent: salesDossier.dossierCompleteOtherDataComponent,
        dossierFleetComponent: salesDossier.dossierFleetComponent,
        dossierSelfSupplyComponent: salesDossier.dossierSelfSupplyComponent,
        dossierPaperworkComponent: dossierPaperworkComponent,
        dossierInsuranceCarrierComponent: salesDossier.dossierInsuranceCarrierComponent,
        customFields: salesDossier?.customFields ? Object.keys(salesDossier.customFields).map(x => { return { key: x, value: salesDossier.customFields[x] } }) : []
      }

      if (salesDossier?.customFields) {
        salesDossierState.customFields = {}
        Object.keys(salesDossier.customFields).map(x => {
          salesDossierState.customFields[x] = salesDossier.customFields[x]
        })
      }
      yield call(putDossierSaleService, salesDossier.dossierId, subDossier, auth.token, lastAccesDate)
      yield put(messageModalActions.openSuccessMessageModal('MESSAGES.PUT_DOSSIER_SUCCESS'))
      yield call(initializeDossier, dossierTypesConstant.sales, salesDossierState)
    } else {
      if (salesDossier?.customFields) {
        salesDossierState.customFields = {}
        Object.keys(salesDossier.customFields).map(x => {
          salesDossierState.customFields[x] = salesDossier.customFields[x]
        })
      }
      if (salesDossierState.entityComponent.previousEntity.entitySubTypeId) {
        const entityFilterSubTypeCombo = yield call(getEntityFilterSubType, auth.token, salesDossierState.entityComponent.previousEntity.entityTypeId, salesDossierState.entityComponent.previousEntity.entitySubTypeId, salesDossierState.organizedUnitId)
        yield put(fetchCombosSuccess({ entityFilterSubTypeCombo }))
      }
      yield put(fetchSalesDossierSuccess({ entityComponent: { ...salesDossierState.entityComponent.previousEntity, previousEntity: salesDossierState.entityComponent.previousEntity } }))
      yield put(initialize('salesDossier', { ...salesDossier, entityComponent: salesDossierState.entityComponent.previousEntity, customFields: salesDossierState.customFields }))
    }
    yield put(updateResultSubmit(true))
    if (resolve) yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(updateResultSubmit(false))
      if (customHandlerError.json && customHandlerError.json.message && customHandlerError.json.message.includes('confirmationRequiredToDeleteDocuments')) {
        yield put(hideLoader())
        const confirm = yield call(yesNoModal, 'ConfirmDeleteDocuments')
        if (confirm) {
          yield put(showLoader())
          yield call(putDossierSaleService, salesDossier.dossierId, { ...subDossier, ConfirmDeletePwkDocuments: true }, auth.token, lastAccesDate)
          yield put(messageModalActions.openSuccessMessageModal('MESSAGES.PUT_DOSSIER_SUCCESS'))
          yield call(initializeDossier, dossierTypesConstant.sales, salesDossierState)
          if (resolve) yield call(resolve)
        }
      } else {
        if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
          yield put(hideLoader())
          const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
          if (confirmedReloadWarning) {
            const salesDossierState = yield select(state => state.salesDossier)
            yield call(initializeDossier, dossierTypesConstant.sales, salesDossierState)
          }
        } else {
          yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
        }
        if (reject) yield call(reject, customHandlerError)
      }
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSaveSalesDossier () {
  yield takeEvery(dossierActionsTypes.SAVE_SALES_DOSSIER, saveSalesDossier)
}
