import React, { PureComponent } from 'react'
import { FormGroup, FormControl, Col, ControlLabel } from 'react-bootstrap'

class SimpleInputText extends PureComponent {
  constructor (props) {
    super(props)
    this.refer
  }

  handleChange (e) {
    if (this.props.input && this.props.input.onChange) {
      this.props.input.onChange(e)
    }
    if (this.props.onInputChange) {
      this.props.needRef ? this.props.onInputChange(e.target.value, this.refer, this.refer.selectionStart) : this.props.onInputChange(e.target.value)
    }
  }

  render () {
    const {
      value,
      maxLength,
      componentClass = '',
      customClass,
      disabled,
      onInputChange,
      type = 'text',
      controlLabel = '',
      colWidht = '25',
      colSm = 0,
      error = '',
      id,
      placeholder = '',
      forceControlLabelInOneLine = false,
      paddingLeft,
      normalize = null
    } = this.props
    return (
      <Col sm={colSm} style={paddingLeft} className={'colWhidht-' + colWidht + ' ' + customClass}>
        <FormGroup
          className="forms-with-label"
          validationState={error ? 'error' : null}
        >
          {
          // controlLabel && <ControlLabel>{controlLabel}</ControlLabel>
            controlLabel && <ControlLabel className={forceControlLabelInOneLine ? 'one-line ellipsis' : ''}>{controlLabel}</ControlLabel>
          }
          <FormControl
            id={id}
            value={normalize ? normalize(value) : value}
            className={componentClass + (type === 'text' ? ' text-left' : ' text-right')}
            onChange={(e) => onInputChange(e.target.value)}
            onChange={this.handleChange.bind(this)}
            placeholder={placeholder}
            type={type}
            disabled={disabled}
            maxLength={maxLength}
            inputRef={ref => { this.refer = ref }}
          />
          {error && <span className='help-block text-center'>{error}</span>}
        </FormGroup>
      </Col>
    )
  }
}

export default SimpleInputText
