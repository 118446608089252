import apiFetch from '../../apiFetch'

export default function (paperworkId, paperwork, token, lastAccessDate) {
  return apiFetch({
    endPoint: `dossier/paperwork/${paperworkId}`,
    method: 'PUT',
    body: paperwork,
    token: token,
    lastAccessDate
  })
}
