import React, { PureComponent } from 'react'
import { Modal, Table, Button } from 'react-bootstrap'

class ModalNewDossiers extends PureComponent {
  render () {
    return (
      <Modal className="new-dossier-pending" show={false}>
        <Modal.Header closeButton>
          <Modal.Title>Altas de expediente en trámite</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table responsive>
            <tbody>
              <tr>
                <td>
                  <div className="text-band">
                    <span className="first">Nº</span>
                    <span className="second number-modal">01</span>
                  </div>
                </td>
                <td>
                  <div className="text-band">
                    <span className="first">Guardado</span>
                    <span className="second">10.02.2017 - 13:39:23</span>
                  </div>
                  <div className="text-band">
                    <span className="first">Marca:</span>
                    <span className="second">Marca</span>
                  </div>
                </td>
                <td>
                  <div className="text-band">
                    <span className="first">Nombre del cliente:</span>
                    <span className="second">Nombre del cliente</span>
                  </div>
                  <div className="text-band">
                    <span className="first">Modelo:</span>
                    <span className="second ">Modelo</span>
                  </div>
                </td>
                <td>
                  <div className="text-band">
                    <span className="first">Acabao en:</span>
                    <span className="number-circle">3</span>
                    <span className="second">Descuentos</span>
                  </div>
                </td>
                <td>
                  <Button className="btn-standard btn-modal-new-dossier"><span>Continuar</span><i className="ico-arrow-right-sm"></i></Button>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="text-band">
                    <span className="first">Nº</span>
                    <span className="second number-modal">02</span>
                  </div>
                </td>
                <td>
                  <div className="text-band">
                    <span className="first">Guardado</span>
                    <span className="second">10.02.2017 - 13:39:23</span>
                  </div>
                  <div className="text-band">
                    <span className="first">Marca:</span>
                    <span className="second">Marca</span>
                  </div>
                </td>
                <td>
                  <div className="text-band">
                    <span className="first">Nombre del cliente:</span>
                    <span className="second">Nombre del cliente</span>
                  </div>
                  <div className="text-band">
                    <span className="first">Modelo:</span>
                    <span className="second">Modelo</span>
                  </div>
                </td>
                <td>
                  <div className="text-band">
                    <span className="first">Acabao en:</span>
                    <span className="number-circle">8</span>
                    <span className="second">Descuentos</span>
                  </div>
                </td>
                <td>
                  <Button className="btn-standard btn-modal-new-dossier"><span>Continuar</span><i className="ico-arrow-right-sm"></i></Button>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="text-band">
                    <span className="first">Nº</span>
                    <span className="second number-modal">03</span>
                  </div>
                </td>
                <td>
                  <div className="text-band">
                    <span className="first">Guardado</span>
                    <span className="second">10.02.2017 - 13:39:23</span>
                  </div>
                  <div className="text-band">
                    <span className="first">Marca:</span>
                    <span className="second">Marca</span>
                  </div>
                </td>
                <td>
                  <div className="text-band">
                    <span className="first">Nombre del cliente:</span>
                    <span className="second">Nombre del cliente</span>
                  </div>
                  <div className="text-band">
                    <span className="first">Modelo:</span>
                    <span className="second">Modelo</span>
                  </div>
                </td>
                <td>
                  <div className="text-band">
                    <span className="first">Acabao en:</span>
                    <span className="number-circle">8</span>
                    <span className="second">Descuentos</span>
                  </div>
                </td>
                <td>
                  <Button className="btn-standard btn-modal-new-dossier"><span>Continuar</span><i className="ico-arrow-right-sm"></i></Button>
                </td>
              </tr>
              <tr>
                <td>
                  <div className="text-band">
                    <span className="first">Nº</span>
                    <span className="second number-modal">04</span>
                  </div>
                </td>
                <td>
                  <div className="text-band">
                    <span className="first">Guardado</span>
                    <span className="second">10.02.2017 - 13:39:23</span>
                  </div>
                  <div className="text-band">
                    <span className="first">Marca:</span>
                    <span className="second">Marca</span>
                  </div>
                </td>
                <td>
                  <div className="text-band">
                    <span className="first">Nombre del cliente:</span>
                    <span className="second">Nombre del cliente</span>
                  </div>
                  <div className="text-band">
                    <span className="first">Modelo:</span>
                    <span className="second">Modelo</span>
                  </div>
                </td>
                <td>
                  <div className="text-band">
                    <span className="first">Acabao en:</span>
                    <span className="number-circle">8</span>
                    <span className="second">Descuentos</span>
                  </div>
                </td>
                <td>
                  <Button className="btn-standard btn-modal-new-dossier"><span>Continuar</span><i className="ico-arrow-right-sm"></i></Button>
                </td>
              </tr>
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    )
  }
}
export default ModalNewDossiers
