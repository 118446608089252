import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import ChargeDetailsFleetModal from '../../../components/management/AuxModals/ChargeDetailsFleetModal'
import { closeChargeDetailsModal, openChargeDetailsModalSuccess } from '../../../actions/management/auxModals/auxModals'
import { openDocumentEditionModalFleet } from '../../../actions/dossier/common/documentEditionModal'
export function mapStateToProps (state) {
  return {
    ...state.managementAuxModals.chargeDetailsModal
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      closeChargeDetailsModal,
      openChargeDetailsModalSuccess,
      openDocumentEditionModalFleet
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(ChargeDetailsFleetModal))
