import apiFetch from '../apiFetch'

export default function (token, { page, pageSize, orderBy }) {
  const queryParamsArray = [
    page ? `page=${page}` : null,
    pageSize ? `pageSize=${pageSize}` : null,
    orderBy ? `orderBy=${orderBy}` : null
  ]
  const queryParams = queryParamsArray.reduce(
    (result, query, idx) => result + (query ? (result ? `&${query}` : `?${query}`) : '')
    , ''
  )

  return apiFetch({
    endPoint: `DossierFinder/PlanPapLogistics${queryParams}`,
    method: 'GET',
    body: null,
    token: token
  })
}
