import apiFetch from '../apiFetch'

export default function (token, organizedUnitId, page, pageSize, orderBy, orderDirection) {
  const queryParamsArray = [
    organizedUnitId ? `organizedUnitId=${organizedUnitId}` : null,
    page ? `page=${page}` : null,
    pageSize ? `pageSize=${pageSize}` : null,
    orderBy ? `orderBy=${orderBy}` : null,
    orderDirection ? `orderDirection=${orderDirection}` : null
  ]

  const queryParams = queryParamsArray.reduce(
    (result, query, idx) => result + (query ? (result ? `&${query}` : `?${query}`) : '')
    , ''
  )

  return apiFetch({ endPoint: 'commissionCancellation/GetForMaster' + queryParams, method: 'GET', body: null, token: token })
}
