import apiFetch from '../apiFetch'

export default function (documentId, dossierId, token, lastAccessDate) {
  return apiFetch({
    endPoint: `document/${documentId}/addDependent?dossierId=${dossierId}`,
    method: 'POST',
    body: null,
    token: token,
    lastAccessDate
  })
}
