import React, { PureComponent } from 'react'
import { reduxForm, Field } from 'redux-form'
import { Row, Col, Table, Button, Tooltip, OverlayTrigger } from 'react-bootstrap'
import IDocCarPagination from '../../commons/iDocCarPagination'
import ProcessingAgencyOrganizedUnitConfigMasterModalPage from '../../../containers/masters/ProcessingAgencyOrganizedUnitConfig/ProcessingAgencyOrganizedUnitConfigMasterModalPage'
import InputSelect from '../../commons/form/InputSelect'

class ProcessingAgencyOrganizedUnitConfigMaster extends PureComponent {
  constructor () {
    super()
    this.state = {
      filter: {
      }
    }
  }

  getSortIcon (column = '') {
    switch (this.props.filter.orderBy) {
      case '+' + column:
        return 'ico-sort-selected-asc'
      case '-' + column:
        return 'ico-sort-selected-desc'
      default:
        return 'ico-sort'
    }
  }

  changeOrder (field) {
    switch (this.props.filter.orderBy) {
      case `+${field}`:
        this.props.actions.fetchProcessingAgencyOrganizedUnitConfigList({ ...this.state.filter, ...this.props.filter, orderBy: `-${field}` })
        break
      default:
        this.props.actions.fetchProcessingAgencyOrganizedUnitConfigList({ ...this.state.filter, ...this.props.filter, orderBy: `+${field}` })
        break
    }
  }

  handleProcessAgencyCommunicationServiceIdChange (value) {
    this.setState({ filter: { ...this.state.filter, processAgencyCommunicationServiceId: value } })
    if (value) {
      this.props.actions.fetchProcessingAgencyOrganizedUnitConfigList({
        ...this.state.filter,
        ...this.props.filter,
        processAgencyCommunicationServiceId: value
      })
    }
  }

  render () {
    const {
      t, processingAgencyOrganizedUnitConfigs, pagesCount, filter, processingAgencyOrganizedUnitConfigsCount,
      filter: { page },
      actions: { showProcessingAgencyOrganizedUnitConfigModal },
      combos: { organizedUnitsAllCombo, processingAgencyCommunicationServices }
    } = this.props
    const tKey = 'MASTERS.PROCESSING_AGENCY_ORGANIZED_UNIT_CONFIG.'
    const editTooltip = (<Tooltip id="editTooltip">{t(`${tKey}EDIT`)}</Tooltip>)
    const deleteTooltip = (<Tooltip id="deleteTooltip">{t(`${tKey}DELETE`)}</Tooltip>)
    return (
      <div className="admin-wrapper">
        <ProcessingAgencyOrganizedUnitConfigMasterModalPage processAgencyCommunicationServiceId={this.state.filter.processAgencyCommunicationServiceId} OUsAssigned={processingAgencyOrganizedUnitConfigs.map(x => x.organizedUnitId)}/>
        <Row className="section-users-header">
          <Col sm={12}>
            <div className="header-panel">
              <h4>{t(`${tKey}PROCESSING_AGENCY_ORGANIZED_UNIT_CONFIG`)}</h4>
            </div>
          </Col>
        </Row>
        <Row>
          <div className="search-form">
            <span className="title-search"><i className="ico-search" /> {t(`${tKey}SEARCH_TITLE`)}</span>
            <Row>
              <Field
                name="filters.processAgencyCommunicationServiceId"
                colSm={4}
                controlLabel={t(`${tKey}COMMUNICATION_SERVICE`)}
                placeholder={t(`${tKey}COMMUNICATION_SERVICE`)}
                component={InputSelect}
                valueKey="id"
                labelKey="value"
                options={processingAgencyCommunicationServices}
                onInputChange={(value) => this.handleProcessAgencyCommunicationServiceIdChange(value)}
              />
            </Row>
          </div>
        </Row>
        <Col sm={12} className="col-row  section-users-body">
          {<Table className="editable-table" hover responsive>
            <thead>
              <tr>
                <th onClick={() => { this.changeOrder('organizedUnitId') }}>
                  {t(`${tKey}ORGANIZED_UNIT`)}
                  <i className={this.getSortIcon('organizedUnitId')} />
                </th>
                <th onClick={() => { this.changeOrder('user') }}>
                  {t(`${tKey}USER`)}
                  <i className={this.getSortIcon('user')} />
                </th>
                <th style={{ width: '77px' }}>{t('DOSSIER_COMPONENTS.ACCESSORIES.ACTIONS')}</th>
              </tr>
            </thead>
            <tbody>
              {this.state.filter?.processAgencyCommunicationServiceId &&
              processingAgencyOrganizedUnitConfigs &&
              processingAgencyOrganizedUnitConfigs.map((processingAgencyOrganizedUnitConfig, idx) => {
                return (
                  <tr key={idx} className={processingAgencyOrganizedUnitConfig.isDeleted ? 'is-deleted' : ''}>
                    <td>{ organizedUnitsAllCombo.find(x => x.id === processingAgencyOrganizedUnitConfig.organizedUnitId).value }</td>
                    <td>{processingAgencyOrganizedUnitConfig.user}</td>
                    <td>
                      {!processingAgencyOrganizedUnitConfig.isDeleted && (
                        <div>
                          <a onClick={() => showProcessingAgencyOrganizedUnitConfigModal(true, processingAgencyOrganizedUnitConfig)}>
                            <OverlayTrigger placement="right" overlay={editTooltip}>
                              <i className="ico-edit-white" />
                            </OverlayTrigger>
                          </a>
                          <a onClick={() => this.props.actions.deleteProcessingAgencyOrganizedUnitConfig(processingAgencyOrganizedUnitConfig.processingAgencyOrganizedUnitConfigId, this.state.filter.processAgencyCommunicationServiceId)}>
                            <OverlayTrigger placement="right" overlay={deleteTooltip}>
                              <i className="ico-trash" />
                            </OverlayTrigger>
                          </a>
                        </div>
                      )}
                    </td>
                  </tr>)
              })
              }
              {this.state.filter?.processAgencyCommunicationServiceId &&
              (processingAgencyOrganizedUnitConfigs === null ||
              processingAgencyOrganizedUnitConfigs === undefined ||
              processingAgencyOrganizedUnitConfigs.length === 0) &&
              <tr>
                <td colSpan={10}><h2>{t(`${tKey}RESULTS_NOT_FOUND`)}</h2></td>
              </tr>
              }
              <tr className="button-table-wrapper">
                <td>
                  <Button
                    className="btn-editable-table btn-standard"
                    onClick={() => showProcessingAgencyOrganizedUnitConfigModal(true)}
                    disabled={!this.state.filter?.processAgencyCommunicationServiceId}
                  >
                    {t(`${tKey}ADD`)}
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>}
          <div className="section-users-footer">
            <nav key={1} className="text-center">
              <IDocCarPagination
                id="btn_pag_sales"
                page={page}
                pagesCount={pagesCount}
                totalRows={processingAgencyOrganizedUnitConfigsCount}
                onSelectAction={(value) => this.props.actions.fetchProcessingAgencyOrganizedUnitConfigList({ ...filter, ...this.state.filter, page: value })}
              />
            </nav>
          </div>
        </Col>
      </div>
    )
  }
}

export default reduxForm({
  form: 'listProcessingAgencyOrganizedUnitConfig'
})(ProcessingAgencyOrganizedUnitConfigMaster)
