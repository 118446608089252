import React, { PureComponent } from 'react'
import { Row, Col } from 'react-bootstrap'
import { reduxForm, Field } from 'redux-form'
import InputText from '../../../commons/form/InputText'
import InputSelect from '../../../commons/form/InputSelect'

class Tab2 extends PureComponent {
  render () {
    return (
      <Col sm={12}>
        <Row>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Tipo entidad:</span>
              <span className="second">Tipo entidad</span>
            </div>
          </Col>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">DNI/CIF:</span>
              <span className="second">DNI/CIF</span>
            </div>
          </Col>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Nombre/Razón social proveedor:</span>
              <span className="second">Nombre/Razón social proveedor</span>
            </div>
          </Col>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Nombre apoderado SL jurídica:</span>
              <span className="second">Nombre apoderado SL jurídica</span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Domicilio:</span>
              <span className="second">Dirección</span>
              <span className="second">Dirección</span>
              <span className="second">Dirección</span>
            </div>
          </Col>
          <Field
            colSm={3}
            name='phone'
            id='phone'
            placeholder='Teléfono'
            component={InputText}
            controlLabel='Teléfono'
            rightIcon='ico-phone'
          />
          <Field
            colSm={3}
            name='mobile'
            id='mobile'
            placeholder='Móvil'
            component={InputText}
            controlLabel='Móvil'
            rightIcon='ico-mobile'
          />
          <Field
            colSm={3}
            type='email'
            name='email'
            id='email'
            placeholder='Email'
            component={InputText}
            controlLabel='Email'
            rightIcon='ico-email-black'
          />
        </Row>
        <Row>
          <Field
            colSm={3}
            controlLabel='Horario contacto'
            id='contactSchedule'
            name='contactSchedule'
            placeholder='Horario contacto'
            labelKey='contactSchedule'
            valueKey='contactSchedule'
            component={InputSelect}
          />
          <Field
            colSm={3}
            controlLabel='Forma contacto'
            id='contactType'
            name='contactType'
            placeholder='Forma contacto'
            labelKey='contactType'
            valueKey='contactType'
            component={InputSelect}
          />
        </Row>
      </Col>
    )
  }
}

export default reduxForm({ form: 'newForm' })(Tab2)
