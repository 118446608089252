import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import FinancingTypeProductModal from '../../../components/masters/financingTypeProduct/FinancingTypeProductModal'
import { translate } from 'react-polyglot'
import { closeEditFinancingTypeModalProduct, saveFinancingTypeProductModal } from '../../../actions/masters/masters'

export function mapStateToProps (state) {
  return {
    ...state.financingTypeProduct.financingTypeModalProduct,
    fields: state.financingTypeProduct.fields
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      closeEditFinancingTypeModalProduct,
      saveFinancingTypeProductModal
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(FinancingTypeProductModal))
