import React, { PureComponent } from 'react'
import { /* Nav, NavItem, */ OverlayTrigger, Tooltip } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import MapSections from '../../util/map-sections_v2'
import settings from '../../../setting'
import sidebarConstant from '../../../constants/sidebar'

class Sidebar_v2 extends PureComponent {
  constructor () {
    super()
    this.state = {
      navItemActive: 1,
      userRegisterCount: 0,
      signCount: 0,
      showFullMenu: true,
      mobileMenuIsClose: true,
      breakResponsiveWidth: 767
    }
    this.onSelect.bind(this)
  }

  componentWillMount () {
    let existPermissionSectionIDocSign = this.props.sections && this.props.sections.find(function (element) {
      return element.code === 'iDocSign'
    })

    if (existPermissionSectionIDocSign) {
      return new Promise((resolve, reject) => {
        this.props.actions.getDocumentsCount(0, resolve, reject)
      }).then((result) => {
        this.setState({ signCount: result })
      })
    }
  }

  onSelect (eventKey, section, isJiraServiceDeskButton) {
    if (!isJiraServiceDeskButton) {
      this.setState({ navItemActive: eventKey })
      this.props.actions.resetFilters()
      this.props.actions.setCurrentSection(section)
    } else {
      window.open(settings.serviceDeskUrl, '_blank')
    }
  }

  toogleFullMenu () {
    // this.setState({ showFullMenu: !this.state.showFullMenu })
    this.props.openMenu()
  }

  toogleMobileMenu () {
    if (window.innerWidth <= this.state.breakResponsiveWidth) {
      this.setState({ mobileMenuIsClose: !this.state.mobileMenuIsClose })
    }
  }

  render () {
    const { sections = [], stateSignCount, openSideBar } = this.props
    const sectionsTemp = sections.slice()

    const openMenu = (<Tooltip className={(openSideBar ? ' fullMenu' : '')} id="editProductTooltip">Abrir/Cerrar Menú</Tooltip>)
    const t = this.props.t

    return (
      <>
        <ul className='menu-expander'>
          <li onClick={() => this.toogleMobileMenu()}><i className="iDocIcon-table-view" /></li>
        </ul>
        <ul bsStyle="pills" stacked activeKey={this.state.navItemActive} className={(this.state.mobileMenuIsClose ? '' : 'mobileMenuOpen ') + 'newLeftNav' + ' ' + (settings.clientFieldValidation ? settings.clientFieldValidation : '') + (openSideBar ? ' fullMenu' : '')}>
          <li onClick={() => this.toogleMobileMenu()} className='navItem-Sidebar closeMobileMenu'><i className='iDocIcon-clear-decline'></i></li>
          {sectionsTemp &&
            sectionsTemp.filter(s => MapSections[s.code] !== undefined).map((section, idx) => {
              const isJiraServiceDeskButton = section.code === sidebarConstant.jiraServiceDesk
              const isJiraIDocSignButton = section.code === sidebarConstant.iDocSign
              return (
                <OverlayTrigger key={idx} placement="right" overlay={<Tooltip className={(openSideBar ? ' fullMenu' : '')} id="tooltipMenu">{t(`${MapSections[section.code].title}`)}</Tooltip>}>
                  {!isJiraServiceDeskButton
                    ? <LinkContainer key={idx} to={`/${section.code}`}>
                      <li id={MapSections[section.code].id} className='navItem-Sidebar' eventKey={idx} onClick={() => { this.onSelect(idx, section, isJiraServiceDeskButton) + this.toogleMobileMenu() } }>
                        <i className={MapSections[section.code].icon} /> <p>{t(`${MapSections[section.code].title}`)}</p>
                        {isJiraIDocSignButton &&
                          <div className='bubble-msg-wrapper'>
                            <div className={`bubble-msg${stateSignCount > 0 ? '' : ' empty'}`}>
                              <span>{stateSignCount || '0'}</span>
                            </div>
                          </div>}
                      </li>
                    </LinkContainer>
                    : <li id={MapSections[section.code].id} className='navItem-Sidebar' eventKey={idx} onClick={() => { this.onSelect(idx, section, isJiraServiceDeskButton) + this.toogleMobileMenu() } }>
                      <i className={MapSections[section.code].icon} />
                      {isJiraIDocSignButton &&
                        <div className='bubble-msg-wrapper'>
                          <div className={`bubble-msg${stateSignCount > 0 ? '' : ' empty'}`}>
                            <span>{stateSignCount || '0'}</span>
                          </div>
                        </div>}
                    </li>}
                </OverlayTrigger>
              )
            })}
          <OverlayTrigger placement="right" overlay={openMenu}>
            <li className='navItem-Sidebar uncollapseLeftNav' onClick={() => this.toogleFullMenu()}>
              <i className='iDocIcon-arrow-alt-circle-right-uncollapse'></i><p>Cerrar menú</p>
            </li>
          </OverlayTrigger>
        </ul></>
    )
  }
}

export default Sidebar_v2
