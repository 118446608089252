import apiFetch from '../apiFetch'

export default function (presentsId, presentsValues, uoId, token) {
  const body = {
    organizedUnitId: uoId,
    presentsTypeId: presentsId,
    isDeleted: false,
    presentTypeLanguages: presentsValues
  }
  return apiFetch({ endPoint: `PresentType/${presentsId}`, method: 'PUT', body: body, token: token })
}
