import apiFetch from '../../apiFetch'

export default function (dossierId, token) {
  return apiFetch({ endPoint: `dossier/${dossierId}/StageCheckList`, method: 'GET', token: token })
  // return [
  //   {
  //     name: 'Inicial',
  //     isActive: false,
  //     docs: [],
  //     fields: [],
  //     others: []
  //   },
  //   {
  //     name: 'Provisional',
  //     isActive: true,
  //     docs: [
  //       { name: 'DNI', isComplete: false },
  //       { name: 'Certificado de Minusvalía', isComplete: true }
  //     ],
  //     fields: [
  //       { name: 'field 1', isComplete: true },
  //       { name: 'field 2', isComplete: true },
  //       { name: 'field 3', isComplete: false }
  //     ],
  //     others: [
  //       { name: 'Enviar aprobación', isComplete: false }
  //     ]
  //   },
  //   { name: 'Etapa 3', isActive: false, docs: [], fields: [], others: [] },
  //   { name: 'Etapa 4', isActive: false, docs: [], fields: [], others: [] },
  //   { name: 'Etapa 5', isActive: false, docs: [], fields: [], others: [] },
  //   { name: 'Etapa 6', isActive: false, docs: [], fields: [], others: [] },
  //   { name: 'Etapa 7', isActive: false, docs: [], fields: [], others: [] },
  //   { name: 'Etapa 8', isActive: false, docs: [], fields: [], others: [] },
  //   { name: 'Etapa 9', isActive: false, docs: [], fields: [], others: [] }
  // ]
}
