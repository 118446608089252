import dossierActionTypes from '../../../constants/actions/dossier/common/dossierRelatedDossiers'

export function fetchFilteredRelatedDossier (dossierId, orderby, historical = false) {
  return {
    type: dossierActionTypes.FETCH_DOSSIER_RELATED_DOSSIERS,
    dossierId,
    orderby,
    historical
  }
}
