import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import UploadDocumentFleetModal from '../../../components/dashboard/Modals/DocumentUploadModal'
import { setWindowDimensions } from '../../../../actions/commons/windowDimensions'
import { checkCanCloseDocumentModal, closeUploadDocumentFleetModal, uploadDocumentFleet } from '../../../../actions/dashboard/dashboard'
import { okResponse } from '../../../../actions/modals/customModal'

export function mapStateToProps (state) {
  const dashboardModal = state.dashboard.dashboardModal
  const commonModal = state.common.modal
  return {
    showModal: dashboardModal.showModal,
    brand: dashboardModal.brand,
    windowWidth: state.windowDimensions.width,
    windowHeight: state.windowDimensions.height,
    token: state.auth.token,
    isBuyAndSell: dashboardModal.isBuyAndSell,
    closeCommonModal: commonModal.showModal,
    code: dashboardModal.code
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      setWindowDimensions,
      checkCanCloseDocumentModal,
      closeUploadDocumentFleetModal,
      uploadDocumentFleet,
      okResponse
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(UploadDocumentFleetModal))
