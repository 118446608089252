export const buttonsHeader = {
  time_line: 0,
  datos_expediente: 1,
  gestor_documental: 2,
  planteo_economico: 3,
  expedientes_relacionados: 4,
  actividad_expediente: 5,
  observaciones: 6,
  cabecera: 7
}

export const buttonsHeaderPermisionsId = {
  1: 'main',
  2: 'doc_manager',
  3: 'economic_plan',
  4: 'related',
  5: 'activity',
  6: 'comments',
  7: 'header'
}

export const buttonsHeaderPermisionsIdReverse = {
  main: 1,
  doc_manager: 2,
  economic_plan: 3,
  related: 4,
  activity: 5,
  comments: 6,
  header: 7
}

export const buttonsHeaderPermisions = {
  main: 'main',
  doc_manager: 'doc_manager',
  economic_plan: 'economic_plan',
  related: 'related',
  activity: 'activity',
  comments: 'comments',
  header: 'header'
}
export const sectionPermissions = {
  dashboard: 'dashboard',
  dashboard_task: 'dashboard-task',
  search: 'search',
  documental_search: 'documental-search',
  masters: 'masters',
  admin: 'admin',
  templates: 'templates',
  conditions: 'conditions',
  jiraServiceDesk: 'jiraServiceDesk',
  importExport: 'importExportFile',
  management: 'management',
  reports: 'reports'
}
