import repairshopMasterActionTypes from '../../../constants/actions/masters/repairshop/repairshopMaster'

export function fetchRepairshopList (filter) {
  return {
    type: repairshopMasterActionTypes.FETCH_REPAIRSHOP_LIST,
    filter
  }
}

export function fetchRepairshopListSuccess (repairshopList, filter) {
  return {
    type: repairshopMasterActionTypes.FETCH_REPAIRSHOP_LIST_SUCCESS,
    repairshopList,
    filter
  }
}

export function deleteRepairshop (repairshopId) {
  return {
    type: repairshopMasterActionTypes.DELETE_REPAIRSHOP,
    repairshopId
  }
}

export function openRepairshopModal (repairshop) {
  return {
    type: repairshopMasterActionTypes.OPEN_REPAIRSHOP_MODAL,
    repairshop
  }
}

export function openRepairshopModalSuccess (repairshop) {
  return {
    type: repairshopMasterActionTypes.OPEN_ADD_REPAIRSHOP_MODAL_SUCCESS,
    repairshop
  }
}

export function assingUserRepairshop (userId, repairshopId, resolve) {
  return {
    type: repairshopMasterActionTypes.ASSING_USER_REPAIRSHOP,
    userId,
    repairshopId,
    resolve
  }
}

export function assingUserRepairshopSuccess (userId, repairshopId, resolve) {
  return {
    type: repairshopMasterActionTypes.ASSING_USER_REPAIRSHOP_SUCCESS,
    userId,
    repairshopId,
    resolve
  }
}

export function deleteUserRepairshop (userRepairshopId) {
  return {
    type: repairshopMasterActionTypes.DELETE_USER_REPAIRSHOP,
    userRepairshopId
  }
}

export function deleteUserRepairshopSuccess (userRepairshopId) {
  return {
    type: repairshopMasterActionTypes.DELETE_USER_REPAIRSHOP_SUCCESS,
    userRepairshopId
  }
}

export function openRepairshopUsersModal (userRepairshop, repairshopId) {
  return {
    type: repairshopMasterActionTypes.OPEN_REPAIRSHOP_USERS_MODAL,
    userRepairshop,
    repairshopId
  }
}

export function openRepairshopUsersModalSuccess (userRepairshop, repairshopId) {
  return {
    type: repairshopMasterActionTypes.OPEN_ADD_REPAIRSHOP_USERS_MODAL_SUCCESS,
    userRepairshop,
    repairshopId
  }
}

export function openEditRepairshopModal (repairshop) {
  return {
    type: repairshopMasterActionTypes.OPEN_EDIT_REPAIRSHOP_MODAL,
    repairshop
  }
}

export function openEditRepairshopModalSuccess (repairshop) {
  return {
    type: repairshopMasterActionTypes.OPEN_EDIT_REPAIRSHOP_MODAL_SUCCESS,
    repairshop
  }
}

export function closeRepairshopMasterModal () {
  return {
    type: repairshopMasterActionTypes.CLOSE_EDIT_REPAIRSHOP_MODAL
  }
}

export function submitRepairshop (repairshop) {
  return {
    type: repairshopMasterActionTypes.SUBMIT_REPAIRSHOP,
    repairshop
  }
}

export function fetchRepairshopUsersList (repairshopId) {
  return {
    type: repairshopMasterActionTypes.FETCH_REPAIRSHOP_USERS_LIST,
    repairshopId
  }
}

export function fetchRepairshopUsersListSuccess (userList) {
  return {
    type: repairshopMasterActionTypes.FETCH_REPAIRSHOP_USERS_LIST_SUCCESS,
    userList
  }
}
