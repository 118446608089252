import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import ConditionsMaintenance from '../../components/conditionsMaintenance/ConditionsMaintenance'
import {
  fetchDossierTypes, fetchDossierSubTypes, fetchSaleTypeBySubtype, fetchPurchaseType,
  fetchCampaignType, fetchPaperworkType, fetchOperationType, fetchOrganizedUnitsTree,
  fetchDocumentsAllTypes, fetchStagesCombo, fetchAdditionalInfoCombo, fetchMarginTypesCombo
} from '../../actions/combos/combos'
import { setActiveStage, openAddConditionsModal, openEditConditionsModal, deleteCondition, updateConditionsMaintenanceFilter } from '../../actions/conditionsMaintenance/conditionsMaintenance'
import TrackingContainer from '../../containers/tracking/trackingContainer'

export function mapStateToProps (state) {
  return {
    ...state.conditionsMaintenance,
    combos: state.combos
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchDossierTypes,
      fetchDossierSubTypes,
      fetchSaleTypeBySubtype,
      fetchPurchaseType,
      fetchPaperworkType,
      fetchCampaignType,
      fetchOperationType,
      fetchOrganizedUnitsTree,
      fetchStagesCombo,
      fetchDocumentsAllTypes,
      setActiveStage,
      openAddConditionsModal,
      openEditConditionsModal,
      deleteCondition,
      updateConditionsMaintenanceFilter,
      fetchAdditionalInfoCombo,
      fetchMarginTypesCombo
    }, dispatch)
  }
}
var decoratedComponent = TrackingContainer(ConditionsMaintenance, true)
export default connect(mapStateToProps, mapDispatchToProps)(translate()(decoratedComponent))
