import unattendedProcess from '../../constants/actions/unattendedProcess/unattendedProcess'

export function setDossierFleetDataChangeProcess (dossierList, saleType, operationTypes, paymentMethod, actionOK) {
  return {
    type: unattendedProcess.SET_DOSSIER_FLEET_DATA_CHANGE_PROCESS,
    dossierList,
    saleType,
    operationTypes,
    paymentMethod,
    actionOK
  }
}

export function setActionExpFleetProcess (dossierList, mode, fleetId, actionOK) {
  return {
    type: unattendedProcess.SET_ACTION_EXP_FLET_PROCESS,
    dossierList,
    mode,
    fleetId,
    actionOK
  }
}

export function setApproveActionProcess (dossierList, mode, roleId, actionOK) {
  return {
    type: unattendedProcess.SET_APPROVE_FLEET_ACTION,
    dossierList,
    mode,
    roleId,
    actionOK
  }
}

export function findUnattendedProcess (filter) {
  return {
    type: unattendedProcess.FIND_UNATTENDED_PROCESS,
    filter
  }
}

export function findUnattendedProcessSuccess (data, filter) {
  return {
    type: unattendedProcess.FIND_UNATTENDED_PROCESS_SUCCESS,
    data,
    filter
  }
}

export function exportUnattendedProcess (filter) {
  return {
    type: unattendedProcess.EXPORT_UNATTENDED_PROCESS_SUCCESS,
    filter
  }
}

export function openUnattendedProcessDetailModal (process) {
  return {
    type: unattendedProcess.OPEN_UNATTENDED_PROCESS_DETAIL_MODAL,
    process
  }
}

export function closeUnattendedProcessDetailModal () {
  return {
    type: unattendedProcess.CLOSE_UNATTENDED_PROCESS_DETAIL_MODAL
  }
}

export function findDossierNumbersFromId (dossiersIdList, dossierSubTypes) {
  return {
    type: unattendedProcess.FIND_DOSSIER_NUMBERS_UNATTENDED_PROCESS,
    dossiersIdList,
    dossierSubTypes
  }
}

export function findDossierNumbersFromIdSuccess (dossierNumbers) {
  return {
    type: unattendedProcess.FIND_DOSSIER_NUMBERS_UNATTENDED_PROCESS_SUCCES,
    dossierNumbers
  }
}

export function setUploadZipDocumentFleetProcess (file, dossiers, documentTypeUseId, organizedUnitId, validate, actionOK) {
  return {
    type: unattendedProcess.SET_UPLOAD_ZIP_DOCUMENT_FLEET_PROCESS,
    file,
    dossiers,
    documentTypeUseId,
    organizedUnitId,
    validate,
    actionOK
  }
}

export function setEconomicPlanFleetProcess (dossierList, actionOK) {
  return {
    type: unattendedProcess.SET_ECONOMIC_PLAN_FLEET_PROCESS,
    dossierList,
    actionOK
  }
}

export function setOperateDataSaleFleetProcess (dossierList, componentConfiguration, saleComponent, vehicleTypeId, fleetId, actionOK) {
  return {
    type: unattendedProcess.SET_OPERATE_DATA_SALE_FLEET_PROCESS,
    dossierList,
    componentConfiguration,
    saleComponent,
    vehicleTypeId,
    fleetId,
    actionOK
  }
}
export function setOperateDataContactFleetProcess (data, fleetId, organizedUnitId, actionOK) {
  return {
    type: unattendedProcess.SET_OPERATE_DATA_CONTACT_FLEET_PROCESS,
    data,
    fleetId,
    organizedUnitId,
    actionOK
  }
}

export function setOperateDataPaperWorkFleetProcess (data, fleetId, actionOK) {
  return {
    type: unattendedProcess.SET_OPERATE_DATA_PAPERWORK_FLEET_PROCESS,
    data,
    fleetId,
    actionOK
  }
}

export function setOperateDataFinancingFleetProcess (dossierList, dossierFinancingComponent, fleetId, actionOK) {
  return {
    type: unattendedProcess.SET_OPERATE_DATA_FINANCING_FLEET_PROCESS,
    dossierList,
    dossierFinancingComponent,
    fleetId,
    actionOK
  }
}

export function setOperateDataChargeFleetProcess (documentId, organizedUnitId, dossierList, fields, comment, fleetId, actionOK) {
  return {
    type: unattendedProcess.SET_OPERATE_DATA_CHARGE_FLEET_PROCESS,
    dossierList,
    organizedUnitId,
    documentId,
    fields,
    comment,
    fleetId,
    actionOK
  }
}

export function setOperateActionSendToApprovalProcess (dossiers, fleetId, actionOK) {
  return {
    type: unattendedProcess.SET_OPERATE_ACTION_SEND_TO_APPROVAL_PROCESS,
    dossiers,
    fleetId,
    actionOK
  }
}

export function setOperateActionApproveProcess (dossiers, fleetId, actionOK) {
  return {
    type: unattendedProcess.SET_OPERATE_ACTION_APPROVE_PROCESS,
    dossiers,
    fleetId,
    actionOK
  }
}

export function setOperateActionRejectProcess (rejectReasonDto, dossiers, fleetId, actionOK, fleetChargeId = null) {
  return {
    type: unattendedProcess.SET_OPERATE_ACTION_REJECT_PROCESS,
    rejectReasonDto,
    fleetId,
    dossiers,
    actionOK,
    fleetChargeId
  }
}

export function setOperateActionApprovePaperworkProcess (dossiers, fleetId, actionOK) {
  return {
    type: unattendedProcess.SET_OPERATE_ACTION_APPROVE_PAPERWORK_PROCESS,
    fleetId,
    dossiers,
    actionOK
  }
}

export function setOperateActionSendToAgencyProcess (dossiers, fleetId, actionOK) {
  return {
    type: unattendedProcess.SET_OPERATE_ACTION_SEND_TO_AGENCY_PROCESS,
    fleetId,
    dossiers,
    actionOK
  }
}

export function setOperateActionDeleteProcess (comment, dossiers, fleetId, actionOK) {
  return {
    type: unattendedProcess.SET_OPERATE_ACTION_DELETE_PROCESS,
    comment,
    fleetId,
    dossiers,
    actionOK
  }
}

export function createIncorporateDocumentFleetProcess (file, documentTypeUseId, infoIncome, fleetDocumentDetails, fleetId, actionOK) {
  return {
    type: unattendedProcess.SET_INCORPORATE_DOCUMENT_FLEET_PROCESS,
    file,
    documentTypeUseId,
    infoIncome,
    fleetDocumentDetails,
    fleetId,
    actionOK
  }
}

export function createValidateDocumentFleetProcess (fields, comment, isRecurrent, fleetDocumentDetails, fleetId, actionOK) {
  return {
    type: unattendedProcess.SET_VALIDATE_DOCUMENT_FLEET_PROCESS,
    fields,
    comment,
    isRecurrent,
    fleetDocumentDetails,
    fleetId,
    actionOK
  }
}

export function createReEditDocumentFleetProcess (fleetDocumentDetails, fleetId, actionOK) {
  return {
    type: unattendedProcess.SET_REEDIT_DOCUMENT_FLEET_PROCESS,
    fleetDocumentDetails,
    fleetId,
    actionOK
  }
}

export function createCancelDocumentFleetProcess (fleetDocumentDetails, fleetId, actionOK) {
  return {
    type: unattendedProcess.SET_CANCEL_DOCUMENT_FLEET_PROCESS,
    fleetDocumentDetails,
    fleetId,
    actionOK
  }
}

export function setDeleteFleetProcess (fleetId, deleteDossiers) {
  return {
    type: unattendedProcess.SET_DELETE_FLEET_PROCESS,
    deleteDossiers,
    fleetId
  }
}

export function setOperateActionPrintDeliveryNoteProcess (dossiers, fleetId, actionOK) {
  return {
    type: unattendedProcess.SET_OPERATE_ACTION_PRINT_DELIVERY_NOTE_PROCESS,
    fleetId,
    dossiers,
    actionOK
  }
}

export function unattendedProcessDownloadDeliveryNotes (unattendedProcessId) {
  return {
    type: unattendedProcess.UNATTENDED_PROCESS_DOWNLOAD_DELIVERY_NOTES,
    unattendedProcessId
  }
}

export function setOperateActionStoreProcess (dossiers, fleetId, actionOK) {
  return {
    type: unattendedProcess.SET_OPERATE_ACTION_STORE_PROCESS,
    fleetId,
    dossiers,
    actionOK
  }
}

export function setFleetChargeDossiersProcess (dossierValues, fleetId, fleetCode, dossierSubType, file, actionOK, t) {
  return {
    type: unattendedProcess.SET_FLEET_CHARGE_DOSSIERS_PROCESS,
    dossierValues,
    fleetId,
    fleetCode,
    dossierSubType,
    file,
    t,
    actionOK
  }
}

export function createRequestSignDocumentFleetProcess (fleetDocumentDetails, fleetId, signType, actionOK) {
  return {
    type: unattendedProcess.SET_REQUEST_SIGN_DOCUMENT_FLEET_PROCESS,
    fleetDocumentDetails,
    fleetId,
    signType,
    actionOK
  }
}

export function setOperateDataDeliveryFleetProcess (data, fleetId, actionOK) {
  return {
    type: unattendedProcess.SET_OPERATE_DATA_DELIVERY_FLEET_PROCESS,
    data,
    fleetId,
    actionOK
  }
}

export function setOperateDataServiceFleetProcess (data, fleetId, actionOK) {
  return {
    type: unattendedProcess.SET_OPERATE_DATA_SERVICE_FLEET_PROCESS,
    data,
    fleetId,
    actionOK
  }
}

export function createCancelSignDocumentFleetProcess (fleetDocumentDetails, fleetId, reason, actionOK) {
  return {
    type: unattendedProcess.SET_CANCEL_SIGN_DOCUMENT_FLEET_PROCESS,
    fleetDocumentDetails,
    fleetId,
    reason,
    actionOK
  }
}

export function createRejectDocumentFleetProcess (fleetDocumentDetails, fleetId, reason, actionOK) {
  return {
    type: unattendedProcess.SET_REJECT_DOCUMENT_FLEET_PROCESS,
    fleetDocumentDetails,
    fleetId,
    reason,
    actionOK
  }
}

export function createUpdateDocumentFleetChargeProcess (fields, comment, isRecurrent, fleetDocumentDetails, fleetId, actionOK) {
  return {
    type: unattendedProcess.SET_UPDATE_CHARGE_DOSSIERS_PROCESS,
    fields,
    comment,
    isRecurrent,
    fleetDocumentDetails,
    fleetId,
    actionOK
  }
}

export function createRecoverDocumentFleetChargeProcess (fleetId, fleetChargeId, actionOK) {
  return {
    type: unattendedProcess.SET_RECOVER_CHARGE_DOSSIERS_PROCESS,
    fleetId,
    fleetChargeId,
    actionOK
  }
}

export default {
  setDossierFleetDataChangeProcess,
  setActionExpFleetProcess,
  setApproveActionProcess,
  findUnattendedProcess,
  findUnattendedProcessSuccess,
  exportUnattendedProcess,
  openUnattendedProcessDetailModal,
  closeUnattendedProcessDetailModal,
  findDossierNumbersFromId,
  findDossierNumbersFromIdSuccess,
  setUploadZipDocumentFleetProcess,
  setEconomicPlanFleetProcess,
  setOperateDataSaleFleetProcess,
  setOperateDataContactFleetProcess,
  setOperateDataPaperWorkFleetProcess,
  setOperateDataFinancingFleetProcess,
  setOperateDataChargeFleetProcess,
  setOperateActionSendToApprovalProcess,
  setOperateActionApproveProcess,
  setOperateActionRejectProcess,
  setOperateActionApprovePaperworkProcess,
  setOperateActionSendToAgencyProcess,
  setOperateActionDeleteProcess,
  createIncorporateDocumentFleetProcess,
  createValidateDocumentFleetProcess,
  createReEditDocumentFleetProcess,
  createCancelDocumentFleetProcess,
  setOperateActionStoreProcess,
  createRequestSignDocumentFleetProcess,
  setOperateDataDeliveryFleetProcess,
  setOperateDataServiceFleetProcess,
  unattendedProcessDownloadDeliveryNotes,
  createCancelSignDocumentFleetProcess,
  createRejectDocumentFleetProcess,
  createUpdateDocumentFleetChargeProcess,
  createRecoverDocumentFleetChargeProcess
}
