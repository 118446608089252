import apiFetch from '../apiFetch'

export default function (token, commissionCancellationCodeId) {
  const queryParamsArray = [
    `commissionCancellationCodeId=${commissionCancellationCodeId}`
  ]
  const queryParams = queryParamsArray.reduce(
    (result, query, idx) => result + (query ? (result ? `&${query}` : `?${query}`) : '')
    , ''
  )
  return apiFetch({ endPoint: `CommissionCancellation/Delete${queryParams}`, method: 'DELETE', body: null, token: token })
}
