import React, { PureComponent } from 'react'
import { Table, Row } from 'react-bootstrap'
class Tab1 extends PureComponent {
  render () {
    return (
      <Row className="tabPane-container">
        <Table responsive className="table-tabPane">
          <thead>
            <tr>
              <th>Concepto</th>
              <th>%</th>
              <th>Importe{'}'}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>PFF Vehículo</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Portes</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Descuento</td>
              <td>0,0%</td>
              <td></td>
            </tr>
            <tr>
              <td>Pintura metalizada</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Opciones Fab</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Accesorios</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Preentrega</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td><span>Base Imp</span></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </Table>
        <div className="text-band comission-label">
          <span className="first">Comisión</span>
          <span className="second">Comisión</span>
        </div>
      </Row>
    )
  }
}

export default Tab1
