import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import headerActions from '../../actions/header/header'
import PrivateRoute from '../../routes/PrivateRoute'

export function mapStateToProps (state) {
  return {
    ...state.PrivateRoute
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      ...headerActions
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(PrivateRoute))
