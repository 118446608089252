import mastersActionTypes from '../../../constants/actions/masters/masters'

export function fetchDocumentTypeUseListSuccess (documentTypeUseList, filter) {
  return {
    type: mastersActionTypes.FETCH_DOCUMENT_TYPE_USE_LIST_SUCCESS,
    documentTypeUseList,
    filter
  }
}
export function deleteDocumentTypeUse (documentTypeUseId) {
  return {
    type: mastersActionTypes.DELETE_DOCUMENT_TYPE_USE,
    documentTypeUseId
  }
}

export function openDocumentTypeUseModal (documentTypeUse) {
  return {
    type: mastersActionTypes.OPEN_DOCUMENT_TYPE_USE_MODAL,
    documentTypeUse
  }
}

export function openAddDocumentTypeUseModalSuccess (languageList, documentTypeUse) {
  return {
    type: mastersActionTypes.OPEN_ADD_DOCUMENT_TYPE_USE_MODAL_SUCCESS,
    languageList,
    documentTypeUse
  }
}

export function openEditDocumentTypeUseModalSuccess (languageList, documentTypeUseId) {
  return {
    type: mastersActionTypes.OPEN_EDIT_DOCUMENT_TYPE_USE_MODAL_SUCCESS,
    languageList,
    documentTypeUseId
  }
}

export function closeDocumentTypeUseMasterModal () {
  return {
    type: mastersActionTypes.CLOSE_EDIT_DOCUMENT_TYPE_USE_MODAL
  }
}

export function submitDocumentTypeUse (documentTypeUse, hasChanges) {
  return {
    type: mastersActionTypes.SUBMIT_DOCUMENT_TYPE_USE,
    documentTypeUse,
    hasChanges
  }
}

export function fetchDocumentTypeUseList (filter) {
  return {
    type: mastersActionTypes.FETCH_DOCUMENT_TYPE_USE_LIST,
    filter
  }
}

export function setDocumentDocumentOnlyTemplateUpload (isOnlyTemplateUpload) {
  return {
    type: mastersActionTypes.SET_DOCUMENT_TYPE_USE_ONLY_TEMPLATE_UPLOAD,
    isOnlyTemplateUpload
  }
}
