import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import {
  fetchOperationType, fetchLanguagesCombo, fetchDocumentTypeUseCombo, fetchDocumentStatusCombo, fetchOrganizedUnitsCombo,
  fetchFunctionToAdd, fetchFunctionToPass, fetchEntityMaritalStatusCombo, fetchFuelCombo, fetchBrands
} from '../../actions/combos/combos'
import { saveConditionsModal, closeAddOrEditConditionsModal } from '../../actions/conditionsMaintenance/conditionsMaintenance'
import ConditionsMaintenanceModal from '../../components/conditionsMaintenance/ConditionsMaintenanceModal'
import { fetchPaymentCombo } from '../../actions/management/management'

export function mapStateToProps (state) {
  return {
    conditionsMaintenanceModal: state.conditionsMaintenance.conditionsMaintenanceModal,
    combos: state.combos,
    paymentMethodCombo: state.management.paymentCombo
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchLanguagesCombo,
      fetchOperationType,
      fetchDocumentTypeUseCombo,
      fetchDocumentStatusCombo,
      saveConditionsModal,
      closeAddOrEditConditionsModal,
      fetchOrganizedUnitsCombo,
      fetchFunctionToAdd,
      fetchFunctionToPass,
      fetchPaymentCombo,
      fetchEntityMaritalStatusCombo,
      fetchFuelCombo,
      fetchBrands
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(ConditionsMaintenanceModal))
