import React, { PureComponent } from 'react'
import { reduxForm } from 'redux-form'
import { Row, Col, Table, Tooltip, OverlayTrigger, Button } from 'react-bootstrap'
import IDocCarPagination from '../../commons/iDocCarPagination'
import SignatureConfigModalPage from '../../../containers/masters/SignatureConfig/SignatureConfigModalPage'

class SignatureConfig extends PureComponent {
  constructor () {
    super()
    this.state = {
      filter: null
    }
  }

  getSortIcon (column = '') {
    switch (this.props.filter.orderBy) {
      case '+' + column:
        return 'ico-sort-selected-asc'
      case '-' + column:
        return 'ico-sort-selected-desc'
      default:
        return 'ico-sort'
    }
  }

  changeOrder (field) {
    switch (this.props.filter.orderBy) {
      case `+${field}`:
        this.props.actions.fetchSignatureConfigList({ ...this.state.filter, ...this.props.filter, orderBy: `-${field}` })
        break
      default:
        this.props.actions.fetchSignatureConfigList({ ...this.state.filter, ...this.props.filter, orderBy: `+${field}` })
        break
    }
  }

  render () {
    const {
      t,
      signatureConfigList,
      pagesCount, signatureConfigCount, filter,
      filter: { page },
      actions: {
        openEditSignatureConfigModal,
        deleteSignatureConfig
      }
    } = this.props

    const tKey = 'MASTERS.'
    const editTooltip = (<Tooltip id="editTooltip">{t(`${tKey}EDIT`)}</Tooltip>)
    const deleteTooltip = (<Tooltip id="deleteTooltip">{t(`${tKey}DELETE`)}</Tooltip>)

    return (
      <div className="admin-wrapper">
        <SignatureConfigModalPage/>
        <Row className="section-users-header">
          <Col sm={12}>
            <div className="header-panel">
              <h4>{t(`${tKey}SIGNATURE_CONFIG_MASTERS.SIGNATURE_CONFIG_TITLE`)}</h4>
            </div>
          </Col>
        </Row>
        <Col sm={12} className="col-row  section-users-body">
          <Table className="editable-table" hover responsive>
            <thead>
              <tr>
                <th onClick={() => { this.changeOrder('documentTypeUse') }}>
                  {t(`${tKey}SIGNATURE_CONFIG_MASTERS.DOCUMENT_TYPE_USE`)}
                  <i className={this.getSortIcon('documentTypeUse')} />
                </th>
                <th onClick={() => { this.changeOrder('code') }}>
                  {t(`${tKey}SIGNATURE_CONFIG_MASTERS.CONFIG_CODE`)}
                  <i className={this.getSortIcon('code')} />
                </th>
                <th style={{ width: '77px' }}>{t('DOSSIER_COMPONENTS.ACCESSORIES.ACTIONS')}</th>
              </tr>
            </thead>
            <tbody>
              {signatureConfigList && signatureConfigList.map((signatureConfig, idx) => (
                <tr key={idx}>
                  <td>{signatureConfig.documentTypeUse}</td>
                  <td>{signatureConfig.code}</td>
                  <td>
                    <div>
                      <a onClick={() => openEditSignatureConfigModal(signatureConfig)}>
                        <OverlayTrigger placement="left" overlay={editTooltip}>
                          <i className="ico-edit-white" />
                        </OverlayTrigger>
                      </a>
                      <a onClick={() => deleteSignatureConfig(signatureConfig.signDocumentConfigurationId)}>
                        <OverlayTrigger placement="left" overlay={deleteTooltip}>
                          <i className="ico-trash" />
                        </OverlayTrigger>
                      </a>
                    </div>
                  </td>
                </tr>
              ))
              }
              {(signatureConfigList === null || signatureConfigList === undefined || signatureConfigList.length === 0) &&
                <tr>
                  <td colSpan={7}><h2>{t(`${tKey}SIGNATURE_CONFIG_MASTERS.RESULTS_NOT_FOUND`)}</h2></td>
                </tr>}
              <tr className="button-table-wrapper">
                <td>
                  <Button
                    className="btn-editable-table btn-standard"
                    onClick={() => openEditSignatureConfigModal()}
                  >
                    + {t('MASTERS.ADD')}
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>
          <div className="section-users-footer">
            <nav key={1} className="text-center">
              <IDocCarPagination
                id="btn_pag_sales"
                page={page}
                pagesCount={pagesCount}
                totalRows={signatureConfigCount}
                onSelectAction={(value) =>
                  this.props.actions.fetchSignatureConfigList({ ...filter, ...this.state.filter, page: value })
                }
              />
            </nav>
          </div>
        </Col>
      </div>
    )
  }
}

export default reduxForm({
  form: 'listSignatureConfig'
})(SignatureConfig)
