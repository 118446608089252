import React, { PureComponent } from 'react'
import { Field, reduxForm, reset } from 'redux-form'
import { Button, OverlayTrigger, Tooltip, Form } from 'react-bootstrap'
import InputText from '../../../components/commons/form/InputText'
import SimpleTablePage from '../../../containers/commons/general/SimpleTablePage'
import IDocCarPagination from '../../../components/commons/iDocCarPagination'
import { multipleNormalize, toUpperCase, trimAll } from '../../../util/normalizeFunctions'
import EntityPopover from '../../components/search/EntityPopover'
import VehiclePopover from '../../components/search/VehiclePopover'
import DossierPopover from '../../components/search/DossierPopover'
import MapSectionsSearch from '../../../_v2/util/map-sections-search'
import { showFilter, hideFiltersBtn, toggleFilterClass, getIconClass } from '../../../_v2/util/management/ManagementUtils'
import { fromCamelToUnderscore } from '../../../util/utils'
// import settings from '../../../setting'

class SearchByProcessingAgencyForm extends PureComponent {
  constructor (props) {
    super()
    this.state = {
    }
  }

  componentDidMount () {
    for (const dynamicFilter in this.props.dynamicFilters) {
      this.props.change(dynamicFilter, this.props.dynamicFilters[dynamicFilter])
    }
  }

  exportData () {
    const tKey = 'SEARCH.CATEGORIES.PROCESSING_AGENCY.'
    let columnsExcel = []
    columnsExcel.push(this.props.t(`${tKey}DOSSIER`) + '#Dossier')
    columnsExcel.push(this.props.t(`${tKey}ENTITY`) + '#Entity')
    columnsExcel.push(this.props.t(`${tKey}VEHICLE`) + '#Vehicle')
    columnsExcel.push(this.props.t(`${tKey}STAGE`) + '#Stage')
    this.exportToExcel(columnsExcel)
  }

  toogleHideFilters () {
    this.props.actions.setHideFilters(!this.props.hideFilters)
  }

  toogleMaxiTable () {
    this.setState({ maxiTable: !this.state.maxiTable })
  }

  handleClickReset (e) {
    if (e !== undefined) {
      e.preventDefault()
    }

    this.props.dispatch(reset('SearchByProcessingAgencyForm'))
    this.state.dossierIdToArray = []
    this.setState({ onlyFleets: true, checkAll: false, dossierIdToArray: [], entityData: [], fields: [] })
  }

  submitSearchForm (values) {
    var allEmptyFieldOrNoValues = this.standardValidations(values)
    if (allEmptyFieldOrNoValues) {
      return this.props.actions.openModal('mustFillFilters')
    }

    this.props.actions.fetchProcessingAgencies({ ...values.processing_Agency, ...this.props.filters })
    this.setState({ dossierIdToArray: [], dynamicFilters: values, filterValues: { ...values.entity, ...this.props.filters } })

    this.props.actions.setFilters(values)
  }

  exportToExcel (data) {
    let cols = ''
    data.forEach(element => {
      cols += element + '$'
    })
    cols = cols.substring(0, cols.length - 1)
    let values = this.props.dynamicFilters
    this.props.actions.fetchProcessingAgenciesDownload({ ...values.processing_Agency, ...this.props.filters }, cols)
  }

  onPageChange (page) {
    this.setState({ filterValues: { ...this.state.filterValues, page: page } })
    this.props.actions.fetchProcessingAgencies({ ...this.state.filterValues, page })
  }

  onOrderChange (e) {
    const newOrderBy = this.props.filters.orderBy.includes(`%2B${e.currentTarget.dataset.field}`) ? `-${e.currentTarget.dataset.field}` : `%2B${e.currentTarget.dataset.field}`
    this.setState({ filterValues: { ...this.state.filterValues, orderBy: newOrderBy } })
    this.props.actions.fetchProcessingAgencies({ ...this.state.filterValues, orderBy: newOrderBy })
  }

  standardValidations (values) {
    var result = false
    const formValues = values.processing_Agency

    result = !formValues || !Object.keys(formValues).filter(item => formValues[item] !== null &&
         Array.isArray(formValues[item]) ? formValues[item].length > 0 : true).length

    return result
  }

  selectedFilters () {
    const filters = this.props.dynamicFilters
    const tKey = 'SEARCH.CATEGORIES.'
    return Object.keys(filters)
      .map((item) => {
        let text = item
        let value = null
        const list = []
        Object.keys(filters[item]).map((filter, index) => {
          text = this.props.t(`${tKey}${item.toUpperCase()}.${fromCamelToUnderscore(filter).toUpperCase()}`)
          value = filters[item][filter]

          if (value) {
            list.push(<li key={index}>{text}: <strong>{value}</strong>;</li>)
          }
        })
        return list
      })
  }

  render () {
    const {
      t, fields, fieldFill, className,
      hasSearch, processingAgenciesData, page, pages, count, handleSubmit, hideFilters
    } = this.props
    const tKey = 'SEARCH.CATEGORIES.PROCESSING_AGENCY.'
    const secMapSearch = MapSectionsSearch.find(function (s) { return s.page === 'processingAgencyPage' })
    const hasResults = processingAgenciesData && processingAgenciesData.length > 0
    return (
      <div className="inner-results-panel">
        <div className="section-results-header">
          <div className="section-header-title">
            <OverlayTrigger placement="right" overlay={<Tooltip id="editProductTooltip">{`${t((this.props.hideMenu ? 'GLOBAL.TABLES.SHOW_INNER_MENU' : 'GLOBAL.TABLES.HIDE_INNER_MENU'))}`}</Tooltip>}>
              <a onClick={() => this.props.toogleInnerMenu()}s className={this.props.hideMenu ? 'collapseBtn' : 'collapseBtn closeInnerMenu'}><i className={this.props.hideMenu ? 'iDocIcon-Open-inner-menu' : 'iDocIcon-Close-inner-menu'}></i></a>
            </OverlayTrigger>
            <h4>{t(`${secMapSearch.title}`)}
              <OverlayTrigger placement="left" overlay={<Tooltip id="editProductTooltip">{`${t((hideFilters ? 'GLOBAL.TABLES.SHOW_FILTERS' : 'GLOBAL.TABLES.HIDE_FILTERS'))}`}</Tooltip>}>
                <a onClick={() => this.toogleHideFilters()} className={'toogleShowFilters ' + hideFiltersBtn(hasResults)}><i className={toggleFilterClass(hasResults, hideFilters)}></i></a>
              </OverlayTrigger>
            </h4>
          </div>
          <Form autoComplete="off" onSubmit={handleSubmit((values) => this.submitSearchForm(values))} className="filters ">
            <div className={'search-form ' + (showFilter(hasResults, hideFilters) ? '' : 'hideFilters ')}>
              <Field
                component={InputText}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                id="chassisVehicle"
                name='processing_Agency.chassis'
                placeholder={t(`${tKey}CHASSIS`)}
                controlLabel={t(`${tKey}CHASSIS`)}
                type="text"
                maxLength={17}
                normalize={value => multipleNormalize(value, [toUpperCase, trimAll])}
                onInputChange={(val) => fieldFill({ chassis: val })}
                customClass={fields.chassis ? className : ''}
              />
              <Field
                component={InputText}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                id="licence_plateVehicle"
                name='processing_Agency.licensePlate'
                placeholder={t(`${tKey}LICENSE_PLATE`)}
                controlLabel={t(`${tKey}LICENSE_PLATE`)}
                type="text"
                normalize={value => multipleNormalize(value, [toUpperCase, trimAll])}
                onInputChange={(val) => fieldFill({ licence_plate: val })}
                customClass={fields.licence_plate ? className : ''}
              />
              <Field
                component={InputText}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                id="idNumber"
                name='processing_Agency.dniCif'
                key='processing_Agency.dniCif'
                placeholder={t(`${tKey}DNI_CIF`)}
                controlLabel={t(`${tKey}DNI_CIF`)}
                type="text"
                onInputChange={(val) => fieldFill({ DNI_CIF: val })}
                customClass={fields.idNumber ? className : ''}
              />
              <Field
                component={InputText}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                id="clientNameEntity"
                name='processing_Agency.name'
                key='processing_Agency.name'
                placeholder={t(`${tKey}NAME`)}
                controlLabel={t(`${tKey}NAME`)}
                type="text"
                onInputChange={(val) => fieldFill({ clientName: val })}
                customClass={fields.clientName ? className : ''}
              />
              <Field
                component={InputText}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                id="clientSurname1"
                name='processing_Agency.surname1'
                key='processing_Agency.surname1'
                placeholder={t(`${tKey}SURNAME1`)}
                controlLabel={t(`${tKey}SURNAME1`)}
                type="text"
                onInputChange={(val) => fieldFill({ clientSurname1: val })}
                customClass={fields.clientSurname1 ? className : ''}
              />
              <Field
                component={InputText}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                id="clientSurname2"
                name='processing_Agency.surname2'
                key='processing_Agency.surname2'
                placeholder={t(`${tKey}SURNAME2`)}
                controlLabel={t(`${tKey}SURNAME2`)}
                type="text"
                onInputChange={(val) => fieldFill({ clientSurname2: val })}
                customClass={fields.clientSurname2 ? className : ''}
              />
              <div className='search-form-actions flexInLine al-R'>
                <Button id="btn_search"
                  type="submit"
                  className="_Btn accept stk">
                  <i className="iDocIcon-search"/>
                  {t('SEARCH.ACTIONS.SEARCH_BUTTON')}
                </Button>
                <Button
                  id="btn_reset"
                  onClick={(e) => this.handleClickReset(e)}
                  className={'_Btn' + (this.props.pristine ? ' accept' : ' accept')}
                  disabled={this.props.pristine}>
                  <i className="iDocIcon-clear-decline"/>
                  {t('SEARCH.ACTIONS.RESET_BUTTON')}
                </Button>
              </div>
            </div>
          </Form>
        </div>
        <div className="section-results-body">
          <div className={'searchResoultsContainer' + (this.state.maxiTable ? ' maxiTable' : '')}>
            {hasSearch && ([
              <div className="table-wrapper" key="table-wrapper">
                <ul className="list-inline pull-left">
                  {this.selectedFilters().length === 0 ? <li>{t(`${tKey}NO_SELECTED_FILTERS`)}</li> : (this.selectedFilters())}
                </ul>
                { hasResults &&
                  <div className='flexInLine al-R'>
                    <div className='actionsField'>
                    </div>
                    <OverlayTrigger placement="left" overlay={<Tooltip id="editProductTooltip">{`${t((this.state.maxiTable ? 'GLOBAL.TABLES.MINIMIZE_TABLE' : 'GLOBAL.TABLES.MAXIMIZE_TABLE'))}`}</Tooltip>}>
                      <a onClick={() => this.toogleMaxiTable()} className='_Btn accept stk o-icn maximizeBtn'><i className={(this.state.maxiTable ? 'iDocIcon-Minimize' : 'iDocIcon-Maximize')}></i></a>
                    </OverlayTrigger>
                    <OverlayTrigger placement="left" overlay={<Tooltip id="editProductTooltip">{t('SEARCH.ACTIONS.EXPORT')}</Tooltip>}>
                      <a onClick={(event) => this.exportData(event)} className='_Btn new o-icn exportToExcel'><i className="iDocIcon-file-excel"></i></a>
                    </OverlayTrigger>
                  </div>
                }
                {/* table */}
                { hasResults &&
                  <div className="table-responsive">
                    <SimpleTablePage
                      columns={[
                        <th key={1} data-field="number" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}DOSSIER_NUMBER`)}&nbsp;<i className={getIconClass('number', this.props.filters)}/>
                        </th>,
                        <th key={2} data-field="entity" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}BY_ENTITY`)}&nbsp;<i className={getIconClass('entity', this.props.filters)}/>
                        </th>,
                        <th key={3} data-field="vehicle" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}BY_VEHICLE`)}&nbsp;<i className={getIconClass('vehicle', this.props.filters)}/></th>,
                        <th key={4} data-field="stage" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}STAGE`)}&nbsp;<i className={getIconClass('stage', this.props.filters)}/>
                        </th>
                      ]}
                      rows={processingAgenciesData && processingAgenciesData.map((row, idx) => (
                        <tr key={idx}>
                          <td className="see-more">
                            <DossierPopover
                              idx={idx}
                              t={t}
                              id={'popover-dossier' + idx}
                              dossier={row.dossier}
                              urgencyType={row.urgencyType}
                            />
                          </td>
                          <td className="see-more">
                            <EntityPopover
                              t={t}
                              entity={row.entity}
                              half={(processingAgenciesData.length || 1) / (idx + 1)}
                              idx={idx}
                              id={'popover-entity' + idx}
                              urgencyType={row.urgencyType}
                            />
                          </td>
                          <td className="see-more">
                            <VehiclePopover
                              t={t}
                              vehicle={row.vehicle}
                              half={(processingAgenciesData.length || 1) / (idx + 1)}
                              idx={idx}
                              id={'popover-vehicle' + idx}
                              isHeader = {true}
                              dossierSubTypeId={row.dossierParentSubtypeId}
                            />
                          </td>
                          <td>{row.stage}</td>
                        </tr>
                      ))}
                    />
                  </div>
                }
                <div className="search-footer" key="search-footer">
                  {hasResults ? (
                    <IDocCarPagination
                      id="btn_pag_sales"
                      page={page}
                      pagesCount={pages}
                      totalRows= {count}
                      onSelectAction={(page) => this.onPageChange(page)}
                    />) : <p className='no_results'>{t('GLOBAL.TABLES.NO_RESULTS')}</p>}
                </div>
              </div>
            ])}
          </div>
        </div>
      </div>
    )
  }
}

const validate = () => {
}

export default reduxForm({
  form: 'SearchByProcessingAgencyForm',
  validate
})(SearchByProcessingAgencyForm)
