import apiFetch from '../apiFetch'

export default function (token, signatureConfigId) {
  return apiFetch({
    endPoint: `SignDocumentConfiguration/${signatureConfigId}`,
    method: 'DELETE',
    body: null,
    token: token
  })
}
