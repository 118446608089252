import { call, put, select, takeLatest } from 'redux-saga/effects'
import { showLoader, hideLoader } from '../../actions/common'
import { getAuth } from '../../selectors/access/auth'
import { getDashboardTask } from '../../selectors/dashboard/dashboardTask'
import dashboardTaskActionTypes from '../../constants/actions/dashboard/dashboardTask'
import putTaskFavorite from '../../services/favorites/putTaskFavorite'
import deleteTaskFavorite from '../../services/favorites/deleteTaskFavorite'
import { fetchDashboardTaskSuccess } from '../../actions/dashboard/dashboardTask'
import messageModalActions from '../../actions/modals/messageModal'
import { handleError } from '../errors/errorManager'

export function * switchTaskFavorite ({ favoriteStatus, taskId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    if (favoriteStatus) {
      yield call(deleteTaskFavorite, auth.token, taskId)
      yield put(messageModalActions.openSuccessMessageModal('MESSAGES.DELETE_TASK_FAVORITE_SUCCESS'))
    } else {
      yield call(putTaskFavorite, auth.token, taskId)
      yield put(messageModalActions.openSuccessMessageModal('MESSAGES.ADD_TASK_FAVORITE_SUCCESS'))
    }
    // const tasks = yield select(getDashboardTask)
    let tasks = Object.assign({}, yield select(getDashboardTask))
    tasks.taskList.forEach((task) => {
      if (task.taskId === taskId) {
        task.isFavorite = !favoriteStatus
      }
    })

    yield put(fetchDashboardTaskSuccess(tasks))
    yield put(hideLoader())
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSwitchTaskFavorite () {
  yield takeLatest(dashboardTaskActionTypes.SWITCH_TASK_FAVORITE, switchTaskFavorite)
}
