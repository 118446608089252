import apiFetch from '../apiFetch'

export default function (token, dossierId, isHistorical) {
  const body = {
    DossierId: dossierId,
    IsHistorical: isHistorical
  }
  return apiFetch({
    endPoint: 'Favorites',
    method: 'PUT',
    body: body,
    token: token
  })
}
