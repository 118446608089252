import React, { PureComponent } from 'react'
import { Modal, Row, Col, Table, Button } from 'react-bootstrap'
import IDocCarPagination from '../../commons/iDocCarPagination'
import { reduxForm, Field } from 'redux-form'
import InputText from '../../commons/form/InputText'
import InputCheckbox from '../../commons/form/InputCheckBox'

class ProcessingManagerMasterModal extends PureComponent {
  constructor () {
    super()
    this.state = {
      selectAllAgency: false
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevProps.showModal !== this.props.showModal && this.props.showModal === true) {
      if (this.props.processingAgencys === null || this.props.processingAgencys === undefined || this.props.processingAgencys.length === 0) {
        this.props.actions.fetchProcessingsAgencyManager()
      }
      this.props.initialize({})
      this.setState({ selectAllAgency: false })
      this.props.change('name', this.props.processingManager?.name ? this.props.processingManager?.name : '')
      this.markCheckAgencys()
    }
  }

  markCheckAgencys () {
    if (this.props.processingManager && this.props.processingManager.processingAgencyIds && this.props.processingManager.processingAgencyIds.length > 0) {
      this.props.processingManager.processingAgencyIds.forEach(element => {
        this.props.change('agencys.selectedAgency' + element, true)
      })
    }
  }

  paginateAndSortArray (processingAgencys, page, pageSize, orderBy) {
    page = parseInt(page) || 1
    pageSize = parseInt(pageSize) || 10
    const propToSort = orderBy || 'organizedUnitName'

    const clonedArray = [...processingAgencys]

    const prop = propToSort.replace(/^[-+]/, '')
    const order = propToSort.startsWith('-') ? 'desc' : 'asc'

    clonedArray.sort((a, b) => {
      const valorA = a[prop].toUpperCase()
      const valorB = b[prop].toUpperCase()

      const compare = order === 'asc' ? 1 : -1

      if (valorA < valorB) {
        return -1 * compare
      }
      if (valorA > valorB) {
        return 1 * compare
      }

      return 0
    })

    const startIndex = (page - 1) * pageSize
    const endIndex = startIndex + pageSize
    const paginatedArray = clonedArray.slice(startIndex, endIndex)

    return paginatedArray
  }

  createOrUpdateProcessingManager (values) {
    const agencysKeys = Object.keys(values.agencys ?? [])
    const selectedAgencys = agencysKeys.filter(key => values.agencys[key])
    const processingAgencyIdsSelected = selectedAgencys.map(key => parseInt(key.replace('selectedAgency', '')))

    const processingManager = {
      processingAgencyManagerId: this.props.processingManager?.processingAgencyManagerId || 0,
      name: values.name,
      isDeleted: this.props.processingManager?.isDeleted || false,
      processingAgencyIds: processingAgencyIdsSelected ?? []
    }

    if (this.props.processingManager && this.props.processingManager.processingAgencyManagerId && this.props.processingManager.processingAgencyManagerId !== 0) {
      this.props.actions.updateProcessingManager(processingManager)
    } else {
      this.props.actions.createProcessingManager(processingManager)
    }
  }

  getSortIcon (column = '') {
    switch (this.props.filter.orderBy) {
      case '+' + column:
        return 'ico-sort-selected-asc'
      case '-' + column:
        return 'ico-sort-selected-desc'
      default:
        return 'ico-sort'
    }
  }

  changeOrder (field) {
    switch (this.props.filter.orderBy) {
      case `+${field}`:
        this.props.actions.setProcessingAgencyManagerModalFilter({
          orderBy: `-${field}`
        })
        break
      default:
        this.props.actions.setProcessingAgencyManagerModalFilter({
          orderBy: `+${field}`
        })
        break
    }
  }

  selectAllAgency () {
    const value = !this.state.selectAllAgency

    this.props.processingAgencys.forEach(element => {
      this.props.change('agencys.selectedAgency' + element.processingAgencyId, value)
    })

    this.setState({ selectAllAgency: value })
  }

  searchTable (value) {
    this.props.actions.setProcessingAgencyManagerModalFilter({
      page: value
    })
  }

  selectAgency (value) {
    if (!value) {
      this.setState({ selectAllAgency: false })
    }
  }

  render () {
    const {
      t, handleSubmit,
      showModal, processingManager, filter: { page, pageSize, orderBy }, pagesCount, itemCount, processingAgencys, actions: { closeProcessingManagerModal }
    } = this.props
    const tKey = 'MASTERS.PROCESSING_MANAGER.'

    let processingAgencysSorted = this.paginateAndSortArray(processingAgencys || [], page, pageSize, orderBy)
    return (
      <Modal className="role-modal languages-modal" show={showModal} onHide={closeProcessingManagerModal} backdrop={'static'}>
        <form autoComplete="off" onSubmit={handleSubmit(this.createOrUpdateProcessingManager.bind(this))} onReset={this.handleFormReset}>
          <div className='div-modal-custom-master'>
            <Modal.Header closeButton onHide={closeProcessingManagerModal}>
              <Modal.Title>{processingManager ? t(`${tKey}EDIT_MANATER`) : t(`${tKey}NEW_MANAGER`) }</Modal.Title>
            </Modal.Header>
            <Modal.Body>

              <Row className="inputs-wrapper-no-border">
                <Col sm={12} className="custom-width-col">
                  <Field
                    id="name"
                    name="name"
                    colSm={3}
                    placeholder={t(`${tKey}NAME_MANAGER`)}
                    component={InputText}
                    maxLength={40}
                  />
                </Col>
              </Row>
              <Row className="inputs-wrapper-no-border">
                <Table className="editable-table" hover responsive>
                  <thead>
                    <tr>
                      <th className='td40per' onClick={() => { this.changeOrder('organizedUnitName') }}>
                        {t(`${tKey}UO`)}
                        <i className={this.getSortIcon('organizedUnitName')} />
                      </th>
                      <th onClick={() => { this.changeOrder('name') }}>
                        {t(`${tKey}PROCESSING_AGENCY`)}
                        <i className={this.getSortIcon('name')} />
                      </th>
                      <th className='td77px'
                        data-field="selectAllDossiers" >
                        <Field
                          id='chkSelectAll'
                          component={InputCheckbox}
                          name='selectAllDossiers'
                          onChange={(value) => this.selectAllAgency(value)}
                          placeholder = {t('DOCUMENT_SEARCH.ACTIONS.SELECT_ALL')}
                          inline
                          checked = { this.state.selectAllAgency }
                        />
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {processingAgencysSorted && processingAgencysSorted.map((processingAgency, idx) => {
                      return (
                        <tr key={idx}>
                          <td>{processingAgency.organizedUnitName}</td>
                          <td>{processingAgency.name}</td>
                          <td>{<Field
                            component={InputCheckbox}
                            name={'agencys.selectedAgency' + processingAgency.processingAgencyId}
                            onChange={(value) => this.selectAgency(value)}
                          />}
                          </td>
                        </tr>)
                    })
                    }
                    {(processingAgencysSorted === null || processingAgencysSorted === undefined || processingAgencysSorted.length === 0) &&
                <tr>
                  <td colSpan={10}><h2>{t(`${tKey}RESULTS_NOT_FOUND`)}</h2></td>
                </tr>}
                  </tbody>
                </Table>
              </Row>
              <div className="section-users-footer">
                <nav key={1} className="text-center">
                  <IDocCarPagination
                    id="btn_pag_processingManagerMasterModal"
                    page={page}
                    pagesCount={pagesCount}
                    totalRows={itemCount}
                    onSelectAction={(value) => this.searchTable(value)}
                  />
                </nav>
              </div>

            </Modal.Body>
            <Modal.Footer>
              <div className='flexInLine al-C'>
                <Col className="btn-wrapper">
                  <Button
                    id="processingManagerMasterModal-modal-submit-button"
                    className="_Btn accept"
                    type="submit"
                    disabled={this.props.pristine}>
                    {t('MASTERS.SAVE')}
                  </Button>
                </Col>
                <Col className="btn-wrapper">
                  <Button
                    id="processingManagerMasterModal-modal-cancel-button"
                    className="_Btn cancel"
                    type="reset"
                    onClick={closeProcessingManagerModal}>
                    {t('MASTERS.CANCEL')}
                  </Button>
                </Col>
              </div>
            </Modal.Footer>
          </div>
        </form>
      </Modal>
    )
  }
}

export default reduxForm({
  form: 'processingManagerMasterModal',
  validate: (values, props) => {
    let error = {}
    if (values.name === undefined || values.name === null || values.name === '') {
      error.name = props.t('DOSSIER_COMPONENTS.VALIDATIONS.ENTITY_NAME_MANDATORY')
    } else if (values.name.length < 3) {
      error.name = props.t('DOSSIER_COMPONENTS.VALIDATIONS.MIN_3_CHARACTER')
    }

    return Object.keys(error).length > 0 ? error : {}
  }
})(ProcessingManagerMasterModal)
