import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { setActiveUoRol, addPermission, deletePermission, closeEditUserModal, submitUser, adminChangePassword, addUO, deleteUO, deleteSalesman, recoverSalesman, openUploadSignModal, downloadSignUser } from '../../../actions/admin/userAdmin'
import { fetchDeviceInstalls, fetchMaxDeviceInstalls } from '../../../actions/idocmobile/deviceInstall'
import { fetchLanguagesCombo } from '../../../actions/combos/combos'
import UserAdminModal from '../../../components/admin/user/UserAdminModal'
import authActions from '../../../actions/auth'

export function mapStateToProps (state) {
  const userAdminModal = state.userAdmin.userAdminModal
  return {
    userId: userAdminModal.userId,
    userName: userAdminModal.userName,
    emailAddress: userAdminModal.emailAddress,
    uoRoles: userAdminModal.uoRoles,
    activeUoId: userAdminModal.activeUoId,
    uoSalesman: userAdminModal.uoSalesman,
    // activeUoRolNotOwnedRoles: userAdminModal.activeUoRolId ? userAdminModal.uoRoles.find(uoRol => uoRol.organizedUnitId === userAdminModal.activeUoRolId).notOwnedRoles : [],
    // activeUoRolOwnedRoles: userAdminModal.activeUoRolId ? userAdminModal.uoRoles.find(uoRol => uoRol.organizedUnitId === userAdminModal.activeUoRolId).ownedRoles : [],
    activeUoRolId: userAdminModal.activeUoRolId,
    showModal: userAdminModal.showModal,
    combos: {
      languagesCombo: state.combos.languagesCombo,
      UOTreeCombo: state.combos.UOTreeCombo
    },
    ...state.deviceInstalls,
    sections: state.auth.sections,
    passwordPolicy: state.auth.passwordPolicy
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchPasswordPolicy: authActions.fetchPasswordPolicy,
      setActiveUoRol,
      addPermission,
      addUO,
      deleteUO,
      deletePermission,
      closeEditUserModal,
      fetchLanguagesCombo,
      submitUser,
      deleteSalesman,
      recoverSalesman,
      adminChangePassword,
      fetchDeviceInstalls,
      fetchMaxDeviceInstalls,
      openUploadSignModal,
      downloadSignUser
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(UserAdminModal))
