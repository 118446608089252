import React, { PureComponent } from 'react'
import { Field, reduxForm, reset } from 'redux-form'
import { OverlayTrigger, Button, Tooltip, Form } from 'react-bootstrap'
import InputText from '../commons/form/InputText'
import { multipleNormalize, toUpperCase, trimAll } from '../../../util/normalizeFunctions'
import SimpleTablePage from '../../../containers/commons/general/SimpleTablePage'
import DossierListPopover from './DossierListPopover'
import IDocCarPagination from '../../../components/commons/iDocCarPagination'
import { permissions } from '../../../constants/backendIds'
import settings from '../../../setting'
import MapSectionsSearch from '../../../_v2/util/map-sections-search'
import { showFilter, hideFiltersBtn, toggleFilterClass, getIconClass } from '../../../_v2/util/management/ManagementUtils'
import { fromCamelToUnderscore } from '../../../util/utils'

class SearchByVehicleForm extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      fieldsConfiguration: {
        findVehicleBydealerNumber: null
      }
    }
  }

  exportData () {
    const tKey = 'SEARCH.CATEGORIES.VEHICLE.'
    let columnsExcel = []
    columnsExcel.push(this.props.t(`${tKey}BRAND`) + '#Brand')
    columnsExcel.push(this.props.t(`${tKey}MODEL`) + '#Model')
    columnsExcel.push(this.props.t(`${tKey}CHASSIS`) + '#VinChassis')
    columnsExcel.push(this.props.t(`${tKey}LICENSE_PLATE`) + '#LicensePlate')
    columnsExcel.push(this.props.t(`${tKey}COMMERCIAL_CODE`) + '#ComercialCode')
    columnsExcel.push(this.props.t(`${tKey}FIRST_LPLATE_DATE_FROM`) + '#LicenseDate')
    if (this.state.fieldsConfiguration.findVehicleBydealerNumber > permissions.hidden) {
      columnsExcel.push(this.props.t(`${tKey}DEALER_NUMBER`) + '#DealerNumber')
    }
    columnsExcel.push(this.props.t(`${tKey}AMOUNT_RELATED_DOSSIERS`) + '#LivesStoreds')
    columnsExcel.push(this.props.t(`${tKey}STATE`) + '#Active')
    this.exportToExcel(columnsExcel)
  }

  exportToExcel (data) {
    let cols = ''
    data.forEach(element => {
      cols += element + '$'
    })
    cols = cols.substring(0, cols.length - 1)
    let values = this.props.dynamicFilters
    this.props.actions.fetchVehiclesSimpleDownload({ ...values.vehicle, ...this.props.filters }, cols)
  }

  componentDidMount () {
    for (const dynamicFilter in this.props.dynamicFilters) {
      this.props.change(dynamicFilter, this.props.dynamicFilters[dynamicFilter])
    }

    let permission = {
      findVehicleBydealerNumber: 0
    }

    if (this.props.permissions && this.props.permissions.findVehicleBydealerNumber) {
      permission.findVehicleBydealerNumber = this.props.permissions.findVehicleBydealerNumber.permission
    }

    this.setState({ fieldsConfiguration: permission })
  }

  handleClickReset (e) {
    if (e !== undefined) {
      e.preventDefault()
    }

    this.props.dispatch(reset('SearchByVehicleForm'))
    this.state.dossierIdToArray = []
    this.setState({ onlyFleets: true, checkAll: false, dossierIdToArray: [], vehicleData: [], fields: [] })
  }

  standardValidations (values) {
    var result = false
    const formValues = values.vehicle

    result = !formValues || !Object.keys(formValues).filter(item => formValues[item] !== null &&
         Array.isArray(formValues[item]) ? formValues[item].length > 0 : true).length

    return result
  }

  submitSearchForm (values) {
    var allEmptyFieldOrNoValues = this.standardValidations(values)
    if (allEmptyFieldOrNoValues) {
      return this.props.actions.openModal('mustFillFilters')
    }

    this.props.actions.fetchVehiclesSimple({ ...values.vehicle, ...this.props.filters })
    this.setState({ dossierIdToArray: [], hideFilters: true, dynamicFilters: values, filterValues: { ...values.vehicle, ...this.props.filters } })

    this.props.actions.setFilters(values)
  }

  onPageChange (page) {
    this.setState({ filterValues: { ...this.state.filterValues, page: page } })
    this.props.actions.fetchVehiclesSimple({ ...this.state.filterValues, page })
  }

  onOrderChange (e) {
    const newOrderBy = this.props.filters.orderBy.includes(`%2B${e.currentTarget.dataset.field}`) ? `-${e.currentTarget.dataset.field}` : `%2B${e.currentTarget.dataset.field}`
    this.setState({ filterValues: { ...this.state.filterValues, orderBy: newOrderBy } })
    this.props.actions.fetchVehiclesSimple({ ...this.state.filterValues, orderBy: newOrderBy })
  }

  toogleHideFilters () {
    this.props.actions.setHideFilters(!this.props.hideFilters)
  }

  toogleMaxiTable () {
    this.setState({ maxiTable: !this.state.maxiTable })
  }

  selectedFilters () {
    const filters = this.props.dynamicFilters
    const tKey = 'SEARCH.CATEGORIES.'
    return Object.keys(filters)
      .map((item) => {
        let text = item
        let value = null
        const list = []
        Object.keys(filters[item]).map((filter, index) => {
          text = this.props.t(`${tKey}${item.toUpperCase()}.${fromCamelToUnderscore(filter).toUpperCase()}`)
          value = filters[item][filter]

          if (value) {
            list.push(<li key={index}>{text}: <strong>{value}</strong>;</li>)
          }
        })
        return list
      })
  }

  render () {
    const {
      t, fields, fieldFill, className, hasSearch, vehicleData, stateTooltip, activeTooltip, inActiveTooltip,
      pages, count, hideFilters,
      handleSubmit, filters: { page } // From search
    } = this.props
    const tKey = 'SEARCH.CATEGORIES.VEHICLE.'
    const secMapSearch = MapSectionsSearch.find(function (s) { return s.page === 'vehicleSimplePage' })
    const hasResults = vehicleData && vehicleData.length > 0
    return (
      <div className="inner-results-panel">
        <div className="section-results-header">
          <div className="section-header-title">
            <OverlayTrigger placement="right" overlay={<Tooltip id="editProductTooltip">{`${t((this.props.hideMenu ? 'GLOBAL.TABLES.SHOW_INNER_MENU' : 'GLOBAL.TABLES.HIDE_INNER_MENU'))}`}</Tooltip>}>
              <a onClick={() => this.props.toogleInnerMenu()}s className={this.props.hideMenu ? 'collapseBtn' : 'collapseBtn closeInnerMenu'}><i className={this.props.hideMenu ? 'iDocIcon-Open-inner-menu' : 'iDocIcon-Close-inner-menu'}></i></a>
            </OverlayTrigger>
            <h4>{t(`${secMapSearch.title}`)}
              <OverlayTrigger placement="left" overlay={<Tooltip id="editProductTooltip">{`${t((hideFilters ? 'GLOBAL.TABLES.SHOW_FILTERS' : 'GLOBAL.TABLES.HIDE_FILTERS'))}`}</Tooltip>}>
                <a onClick={() => this.toogleHideFilters()} className={'toogleShowFilters ' + hideFiltersBtn(hasResults)}><i className={toggleFilterClass(hasResults, this.state.hideFilters)}></i></a>
              </OverlayTrigger>
            </h4>
          </div>
          <Form autoComplete="off" onSubmit={handleSubmit((values) => this.submitSearchForm(values))} className="filters ">
            <div className={'search-form ' + (showFilter(hasResults, hideFilters) ? '' : 'hideFilters ')}>
              <Field
                component={InputText}
                colWidht={33} // 25, 33, 50, 75, 100 default=25
                id="chassisVehicleSimple"
                name='vehicle.chassis'
                placeholder={t(`${tKey}CHASSIS`)}
                controlLabel={t(`${tKey}CHASSIS`)}
                type="text"
                maxLength={17}
                normalize={value => multipleNormalize(value, [toUpperCase, trimAll])}
                onInputChange={(val) => fieldFill({ chassis: val })}
                customClass={fields.chassis ? className : ''}
              />
              <Field
                component={InputText}
                colWidht={33} // 25, 33, 50, 75, 100 default=25
                id="licence_plateSimple"
                name='vehicle.licensePlate'
                placeholder={t(`${tKey}LICENSE_PLATE`)}
                controlLabel={t(`${tKey}LICENSE_PLATE`)}
                type="text"
                normalize={value => multipleNormalize(value, [toUpperCase, trimAll])}
                onInputChange={(val) => fieldFill({ licence_plate: val })}
                customClass={fields.licence_plate ? className : ''}
              />
              { this.state.fieldsConfiguration.findVehicleBydealerNumber > permissions.hidden &&
                <Field
                  component={InputText}
                  colWidht={33} // 25, 33, 50, 75, 100 default=25
                  id="dealerNumberSimple"
                  name='vehicle.dealerNumber'
                  placeholder={t(`${tKey}DEALER_NUMBER`)}
                  controlLabel={t(`${tKey}DEALER_NUMBER`)}
                  type="text"
                  onInputChange={(val) => fieldFill({ dealerNumber: val })}
                  customClass={fields.dealerNumber ? className : ''}
                  normalize={value => multipleNormalize(value, [trimAll])}
                />
              }
              <div className='search-form-actions flexInLine al-R'>
                <Button id="btn_search"
                  type="submit"
                  className="_Btn accept stk">
                  <i className="iDocIcon-search"/>
                  {t('SEARCH.ACTIONS.SEARCH_BUTTON')}
                </Button>
                <Button
                  id="btn_reset"
                  onClick={(e) => this.handleClickReset(e)}
                  className={'_Btn' + (this.props.pristine ? ' accept' : ' accept')}
                  disabled={this.props.pristine}>
                  <i className="iDocIcon-clear-decline"/>
                  {t('SEARCH.ACTIONS.RESET_BUTTON')}
                </Button>
              </div>
            </div>
          </Form>
        </div>
        <div className="section-results-body">
          <div className={'searchResoultsContainer' + (this.state.maxiTable ? ' maxiTable' : '')}>
            {hasSearch && ([
              <div className="table-wrapper" key="table-wrapper">
                <ul className="list-inline pull-left">
                  {this.selectedFilters().length === 0 ? <li>{t(`${tKey}NO_SELECTED_FILTERS`)}</li> : (this.selectedFilters())}
                </ul>
                { hasResults &&
                  <div className='flexInLine al-R'>
                    <div className='actionsField'>
                    </div>
                    <OverlayTrigger placement="left" overlay={<Tooltip id="editProductTooltip">{`${t((this.state.maxiTable ? 'GLOBAL.TABLES.MINIMIZE_TABLE' : 'GLOBAL.TABLES.MAXIMIZE_TABLE'))}`}</Tooltip>}>
                      <a onClick={() => this.toogleMaxiTable()} className='_Btn accept stk o-icn maximizeBtn'><i className={(this.state.maxiTable ? 'iDocIcon-Minimize' : 'iDocIcon-Maximize')}></i></a>
                    </OverlayTrigger>
                    <OverlayTrigger placement="left" overlay={<Tooltip id="editProductTooltip">{t('SEARCH.ACTIONS.EXPORT')}</Tooltip>}>
                      <a onClick={(event) => this.exportData(event)} className='_Btn new o-icn exportToExcel'><i className="iDocIcon-file-excel"></i></a>
                    </OverlayTrigger>
                  </div>
                }
                {/* table */}
                { hasResults &&
                  <div className="table-responsive">
                    <SimpleTablePage
                      columns={[
                        <th key={0} data-field="vehicleBrand" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}BRAND`)}&nbsp;<i className={getIconClass('vehicleBrand', this.props.filters)}/>
                        </th>,
                        <th key={1} data-field="vehicleModel" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}MODEL`)}&nbsp;<i className={getIconClass('vehicleModel', this.props.filters)}/>
                        </th>,
                        <th key={2} data-field="vehicleVinChassis" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}CHASSIS`)}&nbsp;<i className={getIconClass('vehicleVinChassis', this.props.filters)}/>
                        </th>,
                        <th key={3} data-field="vehicleLicensePlate" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}LICENSE_PLATE`)}&nbsp;<i className={getIconClass('vehicleLicensePlate', this.props.filters)}/>
                        </th>,
                        <th key={4} data-field="comercialCode" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}COMMERCIAL_CODE`)}&nbsp;<i className={getIconClass('comercialCode', this.props.filters)} />
                        </th>,
                        <th key={5} data-field="licensePlateDate" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}FIRST_LPLATE_DATE`)}&nbsp;<i className={getIconClass('licensePlateDate', this.props.filters)} />
                        </th>,
                        (this.state.fieldsConfiguration.findVehicleBydealerNumber > permissions.hidden &&
                      <th key={6} data-field="dealerNumber" onClick={(event) => this.onOrderChange(event)}>
                        {t(`${tKey}DEALER_NUMBER`)}&nbsp;<i className={getIconClass('dealerNumber', this.props.filters)} />
                      </th>),
                        <th key={7}>{t(`${tKey}AMOUNT_RELATED_DOSSIERS`)}</th>,
                        <OverlayTrigger key={8} trigger={['hover', 'focus']} placement="top" overlay={stateTooltip}>
                          <th data-field="active" onClick={(event) => this.onOrderChange(event)}>
                            {t(`${tKey}STATE`)}&nbsp;<i className={getIconClass('active', this.props.filters)}/>
                          </th>
                        </OverlayTrigger>
                      ]}
                      rows={vehicleData.map((row, idx) => (
                        <tr key={idx}>
                          <td>{row.vehicleBrand}</td>
                          <td>{row.vehicleModel}</td>
                          <td>{row.vehicleVinChassis}</td>
                          <td>{row.vehicleLicensePlate}</td>
                          <td>{row.comercialCode}</td>
                          <td>{row.licensePlateDate}</td>
                          {this.state.fieldsConfiguration.findVehicleBydealerNumber > permissions.hidden &&
                      <td>{row.dealerNumber}</td>
                          }
                          <td className="see-more">
                            <DossierListPopover
                              t={t}
                              half={(vehicleData.length || 1) / (idx + 1)}
                              idx={idx}
                              id={'popover-dossier-list' + idx}
                              alive={row.lives}
                              stored={row.stored}
                              subtypesCombo={this.props.subtypesCombo}
                            />
                          </td>
                          <td>
                            {
                              row.active ? (
                                <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={activeTooltip}>
                                  <span className={'color-point green'}/>
                                </OverlayTrigger>)
                                : (<OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={inActiveTooltip}>
                                  <span className={'color-point red'}/>
                                </OverlayTrigger>)
                            }
                          </td>
                        </tr>
                      ))}
                    />
                  </div>
                }
                <div className="search-footer" key="search-footer">
                  {hasResults ? (
                    <IDocCarPagination
                      id="btn_pag_sales"
                      page={page}
                      pagesCount={pages}
                      totalRows= {count}
                      onSelectAction={(page) => this.onPageChange(page)}
                    />) : <p className='no_results'>{t('GLOBAL.TABLES.NO_RESULTS')}</p>}
                </div>
              </div>
            ])}
          </div>
        </div>
      </div>
    )
  }
}

const validate = (values, { t }) => {
  const errors = {}
  if (values) {
    if (values.dossier && values.dossier.chassis && values.dossier.chassis.length < settings.chassisMinCharacter) {
      errors.dossier = {}
      errors.dossier.chassis = t('SEARCH.CATEGORIES.ERRORS.VINCHASIS').replace('##MIN_CHARACTERS##', settings.chassisMinCharacter)
    }
    if (values.vehicle && values.vehicle.chassis && values.vehicle.chassis.length < settings.chassisMinCharacter) {
      errors.vehicle = {}
      errors.vehicle.chassis = t('SEARCH.CATEGORIES.ERRORS.VINCHASIS').replace('##MIN_CHARACTERS##', settings.chassisMinCharacter)
    }
  }

  return errors
}

export default reduxForm({
  form: 'SearchByVehicleForm',
  validate
})(SearchByVehicleForm)
