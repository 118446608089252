import React, { PureComponent } from 'react'
import { Col, ButtonGroup, Button, FormGroup, ControlLabel } from 'react-bootstrap'

class InputButtonsSelect extends PureComponent {
  constructor () {
    super()
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange (value) {
    this.props.input.onChange(value)
    if (this.props.onInputChange) {
      this.props.onInputChange(value)
    }
  }

  render () {
    const {
      id, name, colSm = 3, options, labelKey, valueKey, input, meta, customClass = '', showError = false,
      disabled = false, controlLabel, paddingLeft
    } = this.props
    const errorMessage = (meta.touched && meta.error && typeof meta.error !== 'boolean') ? meta.error : null
    return (
      <Col sm={colSm} className={`${meta.touched && meta.error ? 'has-error' : ''} ${customClass}`} style={paddingLeft}>
        <FormGroup
          validationState={(showError || meta.touched) && meta.error ? 'error' : null}
        >
          {controlLabel && <ControlLabel>{controlLabel}</ControlLabel>}
          {options && options.length > 0 && (
            <ButtonGroup
              id={id}
              name={name}
              bsSize="small"
              className="input-button-select"
            >
              {options.map((option, idx) => {
                return (
                  <Button
                    key={idx}
                    disabled={disabled}
                    onClick={(e) => { this.handleChange(option[valueKey]) }}
                    active={input.value === option[valueKey]}
                  >
                    {option[labelKey]}
                  </Button>
                )
              })}
            </ButtonGroup>
          )}
          {errorMessage && <span className='help-block text-center'>{errorMessage}</span>}
        </FormGroup>
      </Col>
    )
  }
}

export default InputButtonsSelect
