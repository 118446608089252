import React, { PureComponent } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { urgencyTypesId } from '../../../constants/backendIds'

class FavoritesCard extends PureComponent {
  markTask (dossierId, isHistorical, favoriteStatus) {
    this.props.switchCardDossierFavorite(dossierId, isHistorical, favoriteStatus)
  }

  checkNotNullOrEmptyValue (value) {
    return value && value.trim() !== ''
  }

  render () {
    const { t } = this.props
    const FavoriteTooltip = (
      <Tooltip id="FavoriteTooltip">
        {t('DASHBOARD.FAVORITES.MARK_UNMARK_FAVORITES')}
      </Tooltip>
    )
    const CommentsTooltip = (
      <Tooltip id="FavoriteTooltip">
        {t('DASHBOARD.FAVORITES.COMMENTS')}
      </Tooltip>
    )

    let urgencyTypeId = ''
    if (this.props.data) {
      switch (this.props.data.urgency) {
        case urgencyTypesId.high:
          urgencyTypeId = 'high'
          break
        case urgencyTypesId.medium:
          urgencyTypeId = 'medium'
          break
        case urgencyTypesId.low:
          urgencyTypeId = 'low'
          break
      }
    }

    return (
      <div className='content-Favorite'>
        <OverlayTrigger container={this} placement="bottom" overlay={FavoriteTooltip}>
          <div onClick={() => this.markTask(this.props.data?.dossierId, this.props.data?.isHistorical, this.props.data?.isFavorite)} className={'marker' + (this.props.data?.isFavorite === true ? '' : ' disabled')} ><i className='iDocIcon-favorite'></i></div>
        </OverlayTrigger>

        <div className={'priority ' + urgencyTypeId}></div>
        <div className='header'>
          <a href={'#/dossier' + this.props.data?.url}>{this.props.data?.number}</a>
          <div className='stage'>
            <span>{this.props.data?.dossierSubtype} </span>
            -
            <span><b> {this.props.data?.dossierStage}</b></span>
          </div>
          <div className='salesManBolck'>
            <span className='salesMan'><b>{this.props.data?.salesman}</b></span>
            <span className='salesUo'>{this.props.data?.organizedUnit}</span>
          </div>
        </div>
        <div className='content'>
          <p className=''>{ this.checkNotNullOrEmptyValue(this.props.data?.clientName) || this.checkNotNullOrEmptyValue(this.props.data?.clientSurname) ? <i className="iDocIcon-user-user-menu"></i> : '' }<b>{this.props.data?.clientName}</b> {this.props.data?.clientSurname}</p>
          <p className='clampLine'>{ this.checkNotNullOrEmptyValue(this.props.data?.model) ? <i className="iDocIcon-file-vehicle"></i> : ''} <b>{this.props.data?.model}</b></p>
          <p className=''>{ this.checkNotNullOrEmptyValue(this.props.data?.vin) ? <i className="iDocIcon-plate"></i> : ''}<span>{this.props.data?.vin}</span></p>
        </div>
        <div className='footer'>
          <p>{this.props.data?.lastUpdateDate}</p>
          <OverlayTrigger container={this} placement="bottom" overlay={CommentsTooltip}>
            <i className={'ico-bocadillo ' + (this.props.data?.unreadComments > 0 ? '' : 'notComments')}>
              <div className='bubble-msg'><span>{this.props.data?.unreadComments}</span></div>
            </i>
          </OverlayTrigger>

        </div>
      </div>
    )
  }
}
export default FavoritesCard
