import React, { Component } from 'react'
// import { HashRouter as Router } from 'react-router-dom'
import { HashRouter } from 'react-router-dom'
import { I18n } from 'react-polyglot'
import { LastLocationProvider } from 'react-router-last-location'

export default class LocaleApp extends Component {
  render () {
    const { routes, /* history, */ locale, messages } = this.props
    return (
      <I18n locale={locale} messages={messages}>
        <HashRouter>
          <LastLocationProvider>
            {routes}
          </LastLocationProvider>
        </HashRouter>
      </I18n>
    )
  }
}
