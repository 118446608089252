import apiFetch from '../apiFetch'

export default function (campaignId, token, lastAccessDate, rejectReason) {
  const queryParamsArray = [
    rejectReason ? `rejectReason=${rejectReason}` : null
  ]
  const queryParams = queryParamsArray.reduce(
    (result, query, idx) => result + (query ? (result ? `&${query}` : `?${query}`) : '')
    , ''
  )

  return apiFetch({ endPoint: `dossier/campaign/${campaignId}/Denied${queryParams}`, method: 'PUT', body: null, token: token, lastAccessDate })
}
