
import React, { PureComponent } from 'react'
import { Field, reduxForm } from 'redux-form'
import { Col, Button, Form, Glyphicon, Clearfix, OverlayTrigger, Tooltip, Checkbox } from 'react-bootstrap'
import InputSelect from '../commons/form/InputSelect'
import InputText from '../commons/form/InputText'
import InputDatePicker from '../commons/form/InputDatePicker'
import { documentEntityType, translateDocumentEntityType } from '../../../constants/dossier/common/documentEntityType'
import EntityPopover from '../../../_v2/components/search/EntityPopover'
import VehiclePopover from '../../../_v2/components/search/VehiclePopover'
import DossierListPopover from '../../../_v2/components/search/DossierListPopover'
import DossierPopover from '../../../_v2/components/search/DossierPopover'
import { entityTypesId, permissions } from '../../../constants/backendIds'
import { validateDate } from '../../../util/validationFunctions'
import { multipleNormalize, toUpperCase, trimAll } from '../../../util/normalizeFunctions'
import settings from '../../../setting'
import { translateDocumentStatus, documentStatusClass, documentStatus } from '../../../constants/dossier/common/documentStatus'
import InputCheckbox from '../commons/form/InputCheckBox'
import SimpleTablePage from '../../../containers/commons/general/SimpleTablePage'
import { getIconClass, showFilter, hideFiltersBtn, toggleFilterClass/*, getIconClass */ } from '../../../_v2/util/management/ManagementUtils'
import IDocCarPagination from '../../../components/commons/iDocCarPagination'
import { convertStringToDate, getDateNowMaxDatePicker } from '../../../util/utils'
import MapSectionsSearch from '../../../_v2/util/map-sections-search'
// import { getFieldsSectionPermissions } from '../../../util/permisionFunctions'
import MassiveDownloadCreateModal from '../../../components/search/documentalSearch/MassiveDownloadCreateModal'

class DocumentalSearchForm extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      documentEntityTypeId: props.dynamicFilters.documentEntityTypeId,
      disableTypeCombo: true,
      disableSubTypeCombo: true,
      ouIdSelected: null,
      vehicleInfo: true,
      electedEntityType: props.filters && props.filters.entityTypeId,
      selectedEntitySubType: props.filters && props.filters.entitySubTypeId,
      hideFilters: false,
      fieldsConfigurationMassiveDownload: {
        massive_download_email: {
          permission: null
        },
        permission: null
      },
      documentsSelected: [],
      fields: {}
    }
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (this.props.formValues) {
      if (
        !prevProps.formValues || (prevProps.formValues.documentEntityTypeId && !this.props.formValues.documentEntityTypeId) ||
    prevProps.formValues.documentEntityTypeId !== this.props.formValues.documentEntityTypeId
      ) {
        this.onDocumentEntityTypeChanges(this.props.formValues.documentEntityTypeId)
      }
      if (
        !prevProps.formValues || (prevProps.formValues.entityTypeId && !this.props.formValues.entityTypeId) ||
      prevProps.formValues.entityTypeId !== this.props.formValues.entityTypeId
      ) {
        this.onEntityTypeChange(this.props.formValues.entityTypeId)
      }
    }

    if (prevProps.isSearching !== this.props.isSearching) {
      this.setState({ documentsSelected: [], allSelected: false })
    }

    return null
  }

  componentDidUpdate () {
    // getSnapshotBeforeUpdate() should be used with componentDidUpdate()
  }

  componentDidMount () {
    for (const dynamicFilter in this.props.dynamicFilters) {
      this.props.change(dynamicFilter, this.props.dynamicFilters[dynamicFilter])
    }

    if (this.props.formValues === undefined) {
      this.props.actions.resetFilters()
      this.props.initialize({})
      // this.setState({ fields: {}, documentsSelected: [] })

      this.props.actions.initializeSearch()
      this.props.actions.fetchDocumentalSearchFormCombos(this.state.documentEntityTypeId)
      this.props.actions.fetchDocumentStatusCombo()
    } else {
      if (!this.props.formValues.documentTypeId && this.props.formValues.documentEntityTypeId) {
        this.onDocumentEntityTypeChanges(this.props.formValues.documentEntityTypeId)
      }

      if (this.props.formValues.documentEntityTypeId === documentEntityType.ENTITY && this.props.formValues.entityTypeId) {
        this.onEntityTypeChange(this.props.formValues.entityTypeId)
      }
    }
  }

  getEntityNameLabel () {
    const tKey = 'DOCUMENT_SEARCH.'
    switch (this.state.selectedEntityType) {
      case entityTypesId.privateEntity:
        return this.props.t(`${tKey}NAME`)
      default:
        return this.props.t(`${tKey}REGISTERED_NAME`)
    }
  }

  dateValidation (value, allValues, props) {
    const result = validateDate(value, props)
    return result === Object(result) ? undefined : result
  }

  onDocumentEntityTypeChanges (documentEntityTypeId) {
    if (documentEntityTypeId === null) {
      this.props.actions.fetchDocumentalSearchFormCombos(this.state.documentEntityTypeId)
    }
    if (documentEntityTypeId === documentEntityType.ENTITY || documentEntityTypeId === null) {
      this.props.change('documentTypeId', null)
      /* vehiculo */
      this.props.change('vehicleBrandId', null)
      this.props.change('comercialCode', null)
      this.props.change('licensePlateDateFrom', null)
      this.props.change('vehicleLicensePlate', null)
      this.props.change('licensePlateDateTo', null)
      this.props.change('vehicleModel', null)
      this.props.change('vehicleVinChasis', null)
      /* dossier */
      this.props.change('number', null)
      this.props.change('organizedUnitId', null)
      this.props.change('dossierSubTypeId', null)
      this.props.change('dossierStageId', null)
      this.props.change('orderDateFrom', null)
      this.props.change('orderDateTo', null)
      this.props.change('creationDossierDateTo', null)
      this.props.change('creationDossierDateFrom', null)
      this.props.change('dealerNumber', null)
    }
    if (documentEntityTypeId === documentEntityType.VEHICLE || documentEntityTypeId === null) {
      this.props.change('documentTypeId', null)
      /* cliente */
      this.onEntityTypeChange(null)
      this.props.change('entityTypeId', null)
      this.props.change('entityCifNif', null)
      this.props.change('entityName', null)
      this.props.change('entitySurname1', null)
      this.props.change('entitySurname2', null)
      this.props.change('representativeDni', null)
      this.props.change('representativeName', null)
      this.props.change('representativeSurname1', null)
      this.props.change('representativeSurname2', null)
      this.props.change('entityMail', null)
      this.props.change('entityPhone', null)
      this.props.change('entityAddress', null)
      /* dossier */
      this.props.change('number', null)
      this.props.change('organizedUnitId', null)
      this.props.change('dossierSubTypeId', null)
      this.props.change('dossierStageId', null)
      this.props.change('orderDateFrom', null)
      this.props.change('orderDateTo', null)
      this.props.change('creationDossierDateTo', null)
      this.props.change('creationDossierDateFrom', null)
      this.props.change('dealerNumber', null)
      this.props.change('load', null)
    }
    if (documentEntityTypeId === documentEntityType.DOSSIER || documentEntityTypeId === null) {
      this.props.change('documentTypeId', null)
      /* cliente */
      this.onEntityTypeChange(null)
      this.props.change('entityTypeId', null)
      this.props.change('entityCifNif', null)
      this.props.change('entityName', null)
      this.props.change('entitySurname1', null)
      this.props.change('entitySurname2', null)
      this.props.change('representativeDni', null)
      this.props.change('representativeName', null)
      this.props.change('representativeSurname1', null)
      this.props.change('representativeSurname2', null)
      this.props.change('entityMail', null)
      this.props.change('entityPhone', null)
      this.props.change('entityAddress', null)
      /* vehiculo */
      this.props.change('vehicleBrandId', null)
      this.props.change('comercialCode', null)
      this.props.change('licensePlateDateFrom', null)
      this.props.change('vehicleLicensePlate', null)
      this.props.change('licensePlateDateTo', null)
      this.props.change('vehicleModel', null)
      this.props.change('vehicleVinChasis', null)
    }
    this.setState({ documentEntityTypeId })
    if (documentEntityTypeId !== null) {
      this.props.actions.fetchDocumentalSearchFormCombos(documentEntityTypeId)
    }
  }

  onEntitySubTypeChange (entitySubTypeId) {
    this.setState({ selectedEntitySubType: entitySubTypeId })
    if (entitySubTypeId) this.props.actions.fetchEntityFilterSubTypeCombo(this.state.selectedEntityType, entitySubTypeId)
  }

  onEntityTypeChange (entityTypeId) {
    this.setState({ selectedEntityType: entityTypeId })

    if (entityTypeId === entityTypesId.recurrentEntity) {
      this.props.actions.fetchEntitySubTypeCombo(entityTypeId)
      this.props.change('entityCifNif', null)
      this.props.change('entityName', null)
      this.props.change('entitySurname1', null)
      this.props.change('entitySurname2', null)
      this.props.change('representativeDni', null)
      this.props.change('representativeName', null)
      this.props.change('representativeSurname1', null)
      this.props.change('representativeSurname2', null)
      this.props.change('entityEmail', null)
      this.props.change('entityPhone', null)
      this.props.change('entityAddress', null)
    } else {
      this.props.change('entitySubTypeId', null)
      this.props.change('entityId', null)
      if (entityTypeId !== entityTypesId.privateEntity) {
        this.props.change('entitySurname1', null)
        this.props.change('entitySurname2', null)
      } else if (entityTypeId !== entityTypesId.businessEntity) {
        this.props.change('representativeDni', null)
        this.props.change('representativeName', null)
        this.props.change('representativeSurname1', null)
        this.props.change('representativeSurname2', null)
      }
    }
  }

  submitDocumentalSearchForm (values) {
    if ((values.documentEntityTypeId === documentEntityType.ENTITY) && (!values.entityName && !values.entityCifNif && !values.entityMail && !values.entityPhone && !values.entityAddress && !values.representativeName && !values.representativeDni && !values.entityTypeId)) {
      return this.props.actions.openModal('mustFillFiltersClient')
    }
    if ((values.documentEntityTypeId === documentEntityType.VEHICLE) && (!values.vehicleBrandId && !values.vehicleModel && !values.vehicleVinChasis && !values.vehicleLicensePlate &&
      !values.licensePlateDateFrom && !values.licensePlateDateTo && !values.comercialCode)) {
      return this.props.actions.openModal('mustFillFiltersVehicle')
    }
    if ((values.documentEntityTypeId === documentEntityType.DOSSIER) && (!values.number && !values.organizedUnitId && !values.dossierSubTypeId && !values.orderDateFrom && !values.orderDateTo && !values.dealerNumber && !values.load && !values.creationDossierDateFrom && !values.creationDossierDateTo)) {
      return this.props.actions.openModal('mustFillFiltersDossier')
    }
    this.props.actions.findFilterDocuments({ ...values, ...this.props.filters })
    this.props.actions.setHideFilters(true)
  }

  onOrderChange (e) {
    let newOrderBy = ''
    if (this.props.filters.orderBy && this.props.filters.orderBy.includes(`%2B${e.currentTarget.dataset.field}`)) {
      newOrderBy = `-${e.currentTarget.dataset.field}`
    } else {
      newOrderBy = `%2B${e.currentTarget.dataset.field}`
    }
    this.props.actions.findFilterDocuments({ items: this.props.documents, ...this.props.pages, ...this.props.count, ...this.props.dynamicFilters, orderBy: newOrderBy })
  }

  handleOrganizedUnitChange (ouId) {
    this.props.change('dossierTypes', null)
    this.props.change('dossierSubTypes', null)
    if (ouId === null) {
      this.setState({ disableTypeCombo: true, disableSubTypeCombo: true, ouIdSelected: ouId })
    } else {
      this.setState({ disableTypeCombo: false, disableSubTypeCombo: true, ouIdSelected: ouId })
      this.props.actions.fetchDossierTypes(ouId)
    }
  }

  handleDossierTypeChange (dossierTypeId) {
    this.props.change('dossierSubTypes', null)
    if (dossierTypeId === null) {
      this.setState({ disableSubTypeCombo: true })
    } else {
      this.setState({ disableSubTypeCombo: false })
      this.props.actions.fetchDossierSubTypes(this.state.ouIdSelected, dossierTypeId)
    }
  }

  onDossierSubtypeChange (dossierSubtypeId) {
    this.props.change('dossierStageId', null)
    this.props.actions.fetchStagesCombo(dossierSubtypeId)
  }

  onDossierStageChange (value) {
    if (!value || value.length === 0) {
      this.props.change('dossierStageId', null)
    }
  }

  selectedFilters () {
    const filters = this.props.dynamicFilters
    const tKey = 'DOCUMENT_SEARCH.'
    let value = []
    let valueDocumentType
    let valueStatus
    let valuEntityType
    let valuEntityTypeId
    let valuEntitySubTypeId
    let valueVehicleBrand
    let valueUO
    let valueEntityId
    let valueDossierSubtype
    let valueStage
    const t = this.props.t
    if (filters.documentTypeId) {
      const documentsTypes = this.props.combos.documentsTypesCombo.find(documentType => documentType.id === filters.documentTypeId)
      valueDocumentType = documentsTypes ? documentsTypes.value : null
    }
    if (filters.entityId) {
      const entityId = this.props.combos.entityFilterSubTypeCombo.find(entity => entity.id === filters.entityId)
      valueEntityId = entityId.value
    }
    if (filters.documentStatusId) {
      const documentsStatus = this.props.combos.documentStatusCombo.find(status => status.id === filters.documentStatusId)
      valueStatus = documentsStatus.value
    }
    if (filters.entityTypeId) {
      const entityTypeId = this.props.combos.entityTypesCombo.find(typeId => typeId.id === filters.entityTypeId)
      valuEntityTypeId = entityTypeId.value
      valuEntityType = entityTypeId.value
      valuEntityTypeId = entityTypeId.id
    }
    if (filters.entitySubTypeId) {
      const entitySubTypeId = this.props.combos.entitySubTypeCombo.find(typeId => typeId.id === filters.entitySubTypeId)
      valuEntitySubTypeId = entitySubTypeId.value
    }
    if (filters.vehicleBrandId) {
      const vehicleBrandId = this.props.combos.brandCombo.find(vehicleBrand => vehicleBrand.id === filters.vehicleBrandId)
      valueVehicleBrand = vehicleBrandId.value
    }
    if (filters.organizedUnitId) {
      const organizedUnitId = this.props.combos.lowestOrganizedUnitsCombo.find(uo => uo.id === filters.organizedUnitId)
      valueUO = organizedUnitId.value
    }
    if (filters.dossierSubTypeId) {
      const dossierSubTypeId = this.props.combos.searchDossierSubtypesCombo.find(subTypeId => subTypeId.id === filters.dossierSubTypeId)
      valueDossierSubtype = dossierSubTypeId.value
    }
    if (filters.dossierStageId) {
      valueStage = filters.dossierStageId.map(value => this.props.combos.stagesCombo.find(stage => stage.id === value).value).join(', ')
    }

    if (filters.documentEntityTypeId === documentEntityType.ENTITY) {
      value = [
        <li key={1}>{t(`${tKey}ENTITY_TYPE`)}: <strong>{t(`${tKey}COMBOS.DOCUMENT_ENTITY_TYPE.${translateDocumentEntityType[1]}`)}</strong>;</li>,
        filters.documentTypeId ? (
          <li key={2}>{t(`${tKey}DOCUMENT_TYPE`)}: <strong>{valueDocumentType}</strong>;</li>
        ) : null,
        filters.documentStatusId ? (
          <li key={3}>{t(`${tKey}STATE`)}: <strong>{valueStatus}</strong>;</li>
        ) : null,
        (filters.creationDateFrom || filters.creationDateTo) ? (
          <li key={4}>
            {t(`${tKey}CREATION_DATE`)}: <strong>{
              filters.creationDateFrom && filters.creationDateTo ? (filters.creationDateFrom + ' - ' + filters.creationDateTo)
                : !filters.creationDateTo ? filters.creationDateFrom
                  : filters.creationDateTo
            }
            </strong>;</li>
        ) : null,
        (filters.validationDateFrom || filters.validationDateTo) ? (
          <li key={5}>
            {t(`${tKey}VALIDATION_DATE`)}: <strong>{
              filters.validationDateFrom && filters.validationDateTo ? (filters.validationDateFrom + ' - ' + filters.validationDateTo)
                : !filters.validationDateTo ? filters.validationDateFrom
                  : filters.validationDateTo
            }
            </strong>;</li>
        ) : null,
        filters.entityName && valuEntityTypeId !== entityTypesId.businessEntity ? (
          <li key={6}>{t(`${tKey}NAME`)}: <strong>{filters.entityName}</strong>;</li>
        ) : null,
        filters.entityName && valuEntityTypeId === entityTypesId.businessEntity ? (
          <li key={20}>{t(`${tKey}REGISTERED_NAME`)}: <strong>{filters.entityName}</strong>;</li>
        ) : null,
        filters.entitySurname1 ? (
          <li key={7}>{t(`${tKey}SURNAME1`)}: <strong>{filters.entitySurname1}</strong>;</li>
        ) : null,
        filters.entitySurname2 ? (
          <li key={8}>{t(`${tKey}SURNAME2`)}: <strong>{filters.entitySurname2}</strong>;</li>
        ) : null,
        filters.entityCifNif ? (
          <li key={9}>{t(`${tKey}DNI`)}: <strong>{filters.entityCifNif}</strong>;</li>
        ) : null,
        filters.entityMail ? (
          <li key={10}>{t(`${tKey}EMAIL`)}: <strong>{filters.entityMail}</strong>;</li>
        ) : null,
        filters.entityPhone ? (
          <li key={11}>{t(`${tKey}TELEPHONE`)}: <strong>{filters.entityPhone}</strong>;</li>
        ) : null,
        filters.entityAddress ? (
          <li key={12}>{t(`${tKey}DIRECCTION`)}: <strong>{filters.entityAddress}</strong>;</li>
        ) : null,
        filters.representativeName ? (
          <li key={13}>{t(`${tKey}LEGAL_REPRESENTATIVE_NAME`)}: <strong>{filters.representativeName}</strong>;</li>
        ) : null,
        filters.representativeSurname1 ? (
          <li key={14}>{t(`${tKey}LEGAL_REPRESENTATIVE_SURNAME1`)}: <strong>{filters.representativeSurname1}</strong>;</li>
        ) : null,
        filters.representativeSurname2 ? (
          <li key={15}>{t(`${tKey}LEGAL_REPRESENTATIVE_SURNAME2`)}: <strong>{filters.representativeSurname2}</strong>;</li>
        ) : null,
        filters.representativeDni ? (
          <li key={16}>{t(`${tKey}LEGAL_REPRESENTATIVE_DNI`)}: <strong>{filters.representativeDni}</strong>;</li>
        ) : null,
        filters.entityTypeId ? (
          <li key={17}>{t(`${tKey}CLIENT_TYPE`)}: <strong>{valuEntityType}</strong>;</li>
        ) : null,
        filters.entitySubTypeId ? (
          <li key={18}>{t(`${tKey}ENTITY_SUB_TYPE_ID`)}: <strong>{valuEntitySubTypeId}</strong>;</li>
        ) : null,
        filters.entityId ? (
          <li key={19}>{t(`${tKey}ENTITY_ID`)}: <strong>{valueEntityId}</strong>;</li>
        ) : null
      ]
    } else if (filters.documentEntityTypeId === documentEntityType.VEHICLE) {
      value = [
        <li key={1}>{t(`${tKey}ENTITY_TYPE`)}: <strong>{t(`${tKey}COMBOS.DOCUMENT_ENTITY_TYPE.${translateDocumentEntityType[2]}`)}</strong>;</li>,
        filters.documentTypeId ? (
          <li key={2}>{t(`${tKey}DOCUMENT_TYPE`)}: <strong>{valueDocumentType}</strong>;</li>
        ) : null,
        filters.documentStatusId ? (
          <li key={3}>{t(`${tKey}STATE`)}: <strong>{valueStatus}</strong>;</li>
        ) : null,
        (filters.creationDateFrom || filters.creationDateTo) ? (
          <li key={4}>
            {t(`${tKey}CREATION_DATE`)}: <strong>{
              filters.creationDateFrom && filters.creationDateTo ? (filters.creationDateFrom + ' - ' + filters.creationDateTo)
                : !filters.creationDateTo ? filters.creationDateFrom
                  : filters.creationDateTo
            }
            </strong>;</li>
        ) : null,
        (filters.validationDateFrom || filters.validationDateTo) ? (
          <li key={5}>
            {t(`${tKey}VALIDATION_DATE`)}: <strong>{
              filters.validationDateFrom && filters.validationDateTo ? (filters.validationDateFrom + ' - ' + filters.validationDateTo)
                : !filters.validationDateTo ? filters.validationDateFrom
                  : filters.validationDateTo
            }
            </strong>;</li>
        ) : null,
        filters.vehicleBrandId ? (
          <li key={6}>{t(`${tKey}BRAND`)}: <strong>{valueVehicleBrand}</strong>;</li>
        ) : null,
        filters.vehicleModel ? (
          <li key={7}>{t(`${tKey}MODEL`)}: <strong>{filters.vehicleModel}</strong>;</li>
        ) : null,
        filters.vehicleVinChasis ? (
          <li key={8}>{t(`${tKey}CHASSIS`)}: <strong>{filters.vehicleVinChasis}</strong>;</li>
        ) : null,
        filters.vehicleLicensePlate ? (
          <li key={9}>{t(`${tKey}LICENSE_PLATE`)}: <strong>{filters.vehicleLicensePlate}</strong>;</li>
        ) : null,
        filters.comercialCode ? (
          <li key={10}>{t(`${tKey}COMMERCIAL_CODE`)}: <strong>{filters.comercialCode}</strong>;</li>
        ) : null,
        (filters.licensePlateDateFrom || filters.licensePlateDateTo) ? (
          <li key={11}>
            {t(`${tKey}FIRST_LPLATE_DATE`)}: <strong>{
              filters.licensePlateDateFrom && filters.licensePlateDateTo ? (filters.licensePlateDateFrom + ' - ' + filters.licensePlateDateTo)
                : !filters.licensePlateDateTo ? filters.licensePlateDateFrom
                  : filters.licensePlateDateTo
            }
            </strong>;</li>
        ) : null
      ]
    } else if (filters.documentEntityTypeId === documentEntityType.DOSSIER) {
      value = [
        <li key={1}>{t(`${tKey}ENTITY_TYPE`)}: <strong>{t(`${tKey}COMBOS.DOCUMENT_ENTITY_TYPE.${translateDocumentEntityType[3]}`)}</strong>;</li>,
        filters.documentTypeId ? (
          <li key={2}>{t(`${tKey}DOCUMENT_TYPE`)}: <strong>{valueDocumentType}</strong>;</li>
        ) : null,
        filters.documentStatusId ? (
          <li key={3}>{t(`${tKey}STATE`)}: <strong>{valueStatus}</strong>;</li>
        ) : null,
        (filters.creationDateFrom || filters.creationDateTo) ? (
          <li key={4}>
            {t(`${tKey}CREATION_DATE`)}: <strong>{
              filters.creationDateFrom && filters.creationDateTo ? (filters.creationDateFrom + ' - ' + filters.creationDateTo)
                : !filters.creationDateTo ? filters.creationDateFrom
                  : filters.creationDateTo
            }
            </strong>;</li>
        ) : null,
        (filters.validationDateFrom || filters.validationDateTo) ? (
          <li key={5}>
            {t(`${tKey}VALIDATION_DATE`)}: <strong>{
              filters.validationDateFrom && filters.validationDateTo ? (filters.validationDateFrom + ' - ' + filters.validationDateTo)
                : !filters.validationDateTo ? filters.validationDateFrom
                  : filters.validationDateTo
            }
            </strong>;</li>
        ) : null,
        filters.vehicleBrandId ? (
          <li key={6}>{t(`${tKey}BRAND`)}: <strong>{valueVehicleBrand}</strong>;</li>
        ) : null,
        filters.number ? (
          <li key={7}>{t(`${tKey}DOSSIER_NUMBER`)}: <strong>{filters.number}</strong>;</li>
        ) : null,
        filters.organizedUnitId ? (
          <li key={8}>{t(`${tKey}UO`)}: <strong>{valueUO}</strong>;</li>
        ) : null,
        filters.dossierSubTypeId ? (
          <li key={9}>{t(`${tKey}DOSSIER_SUBTYPE`)}: <strong>{valueDossierSubtype}</strong>;</li>
        ) : null,
        filters.dossierStageId ? (
          <li key={10}>{t(`${tKey}STAGE`)}: <strong>{valueStage}</strong>;</li>
        ) : null,
        (filters.orderDateFrom || filters.orderDateTo) ? (
          <li key={11}>
            {t(`${tKey}ORDER_DATE`)}: <strong>{
              filters.orderDateFrom && filters.orderDateTo ? (filters.orderDateFrom + ' - ' + filters.orderDateTo)
                : !filters.orderDateTo ? filters.orderDateFrom
                  : filters.orderDateTo
            }
            </strong>;</li>
        ) : null,
        (filters.creationDossierDateFrom || filters.creationDossierDateTo) ? (
          <li key={12}>
            {t(`${tKey}CREATION_DATE`)}: <strong>{
              filters.creationDossierDateFrom && filters.creationDossierDateTo ? (filters.creationDossierDateFrom + ' - ' + filters.creationDossierDateTo)
                : !filters.creationDossierDateTo ? filters.creationDossierDateFrom
                  : filters.creationDossierDateTo
            }
            </strong>;</li>
        ) : null,
        filters.dealerNumber ? (
          <li key={13}>{t(`${tKey}DEALER_NUMBER`)}: <strong>{filters.dealerNumber}</strong>;</li>
        ) : null,
        filters.searchHistorical ? (
          <li key={15}>{t(`${tKey}SEARCH_HISTORICAL`)}: <strong>{filters.searchHistorical}</strong>;</li>
        ) : null
      ]
    } else {
      value = [
        filters.documentTypeId ? (
          <li key={2}>{t(`${tKey}DOCUMENT_TYPE`)}: <strong>{valueDocumentType}</strong>;</li>
        ) : null,
        filters.documentStatusId ? (
          <li key={3}>{t(`${tKey}STATE`)}: <strong>{valueStatus}</strong>;</li>
        ) : null,
        (filters.creationDateFrom || filters.creationDateTo) ? (
          <li key={4}>
            {t(`${tKey}CREATION_DATE`)}: <strong>{
              filters.creationDateFrom && filters.creationDateTo ? (filters.creationDateFrom + ' - ' + filters.creationDateTo)
                : !filters.creationDateTo ? filters.creationDateFrom
                  : filters.creationDateTo
            }
            </strong>;</li>
        ) : null,
        (filters.validationDateFrom || filters.validationDateTo) ? (
          <li key={5}>
            {t(`${tKey}VALIDATION_DATE`)}: <strong>{
              filters.validationDateFrom && filters.validationDateTo ? (filters.validationDateFrom + ' - ' + filters.validationDateTo)
                : !filters.validationDateTo ? filters.validationDateFrom
                  : filters.validationDateTo
            }
            </strong>;</li>
        ) : null
      ]
    }
    return value
  }

  returnArray (item) {
    let arrayRetur = []
    arrayRetur.push(item)
    return arrayRetur
  }

  toogleHideFilters () {
    this.props.actions.setHideFilters(!this.props.hideFilters)
  }

  toogleMaxiTable () {
    this.setState({ maxiTable: !this.state.maxiTable })
  }

  addDocumentIdToArray (documentId) {
    if (!this.state.documentsSelected.includes(documentId)) {
      this.setState({ documentsSelected: [...this.state.documentsSelected, documentId] })
      if (this.props.documentsIds.length === (this.state.documentsSelected.length + 1)) {
        this.setState({ allSelected: true })
      }
    } else {
      this.setState({ documentsSelected: this.state.documentsSelected.filter(d => d !== documentId) })
      if (this.state.allSelected) {
        this.setState({ allSelected: false })
      }
    }
  }

  onClickResetFilters (e) {
    if (e) {
      e.preventDefault()
    }
    this.props.initialize({})
    this.props.actions.resetFilters()
    this.setState({ fields: {}, documentsSelected: [] })
  }

  selectAllDocuments () {
    let documents = []
    if (this.state.allSelected) {
      this.setState({ documentsSelected: documents, allSelected: false })
    } else {
      this.setState({ documentsSelected: this.props.documentsIds, allSelected: true })
    }
  }

  massiveDownloadDocuments () {
    if (this.state.documentsSelected && this.state.documentsSelected.length > 0) {
      const promise = new Promise((resolve, reject) => {
        this.props.actions.massiveDownloadDocumentList(this.state.documentsSelected, resolve, reject)
      })
      promise.then((result) => {
        if (result) {
          this.setState({ showRejectCommentModal: true })
        }
      })
    } else {
      this.props.actions.openModal('mustSelectAnyDocument')
    }
  }

  render () {
    const {
      t, handleSubmit, initialize, pristine, pages, count, documents, dynamicFilters, isSearching, sectionConfigurationMassiveDownloads, hideFilters,
      filters: { page },
      actions: { fetchDocumentSearchList, createMassiveDownload },
      combos: {
        documentsTypesCombo,
        entityTypesCombo,
        searchDossierSubtypesCombo,
        stagesCombo,
        lowestOrganizedUnitsCombo,
        brandCombo,
        documentStatusCombo,
        entitySubTypeCombo,
        entityFilterSubTypeCombo
      },
      fields,
      fieldFill,
      resetFields
    } = this.props

    const tKey = 'DOCUMENT_SEARCH.'
    const className = 'filled'
    const classNameSelect = 'filledSelect'
    const tooltipValidate = (<Tooltip id="tooltipValidate">{t(`${tKey}TABLE.STATUS.VALIDATED`)}</Tooltip>)
    const tooltipPending = (<Tooltip id="tooltipPending">{t(`${tKey}TABLE.STATUS.PENDING`)}</Tooltip>)
    const tooltipRejected = (<Tooltip id="tooltipRejected">{t(`${tKey}TABLE.STATUS.REJECTED`)}</Tooltip>)
    const tooltipRemoved = (<Tooltip id="tooltipRemoved">{t(`${tKey}TABLE.STATUS.REMOVED`)}</Tooltip>)
    const tooltipExpired = (<Tooltip id="tooltipExpired">{t(`${tKey}TABLE.STATUS.EXPIRED`)}</Tooltip>)
    const navigationTooltip = (<Tooltip id="navigationTooltip">{t(`${tKey}TABLE.TOOLTIP.GO_TO`)}</Tooltip>)
    const secMapSearch = MapSectionsSearch.find(function (s) { return s.page === 'documentalSearchPage' })
    const hasResults = documents && documents.length > 0

    const massiveDownloads = sectionConfigurationMassiveDownloads
    let documentEntityTypeCombo = this.props.combos.documentEntityTypeCombo
    documentEntityTypeCombo = documentEntityTypeCombo.map((documentEntityType) => {
      return { ...documentEntityType, value: t(`${tKey}COMBOS.DOCUMENT_ENTITY_TYPE.${documentEntityType.value}`) }
    })

    return (
      <div className="inner-results-panel">
        <div className="section-results-header">
          <div className="section-header-title">
            <OverlayTrigger placement="right" overlay={<Tooltip id="editProductTooltip">{`${t((this.props.hideMenu ? 'GLOBAL.TABLES.SHOW_INNER_MENU' : 'GLOBAL.TABLES.HIDE_INNER_MENU'))}`}</Tooltip>}>
              <a onClick={() => this.props.toogleInnerMenu()}s className={this.props.hideMenu ? 'collapseBtn' : 'collapseBtn closeInnerMenu'}><i className={this.props.hideMenu ? 'iDocIcon-Open-inner-menu' : 'iDocIcon-Close-inner-menu'}></i></a>
            </OverlayTrigger>
            <h4>{t(`${secMapSearch.title}`)}
              <OverlayTrigger placement="left" overlay={<Tooltip id="editProductTooltip">{`${t((hideFilters ? 'GLOBAL.TABLES.SHOW_FILTERS' : 'GLOBAL.TABLES.HIDE_FILTERS'))}`}</Tooltip>}>
                <a onClick={() => this.toogleHideFilters()} className={'toogleShowFilters ' + hideFiltersBtn(hasResults) }><i className={toggleFilterClass(hasResults, hideFilters)}></i></a>
              </OverlayTrigger>
            </h4>
          </div>
          <MassiveDownloadCreateModal
            t={t}
            showModal={this.state.showRejectCommentModal}
            reasonCombo={this.props.combos.reasonCombo}
            documentIds={this.state.documentsSelected}
            actions={{
              createMassiveDownload,
              closeModal: () => this.closeMassiveDownloadModal()
            }}
          />
          <Form autoComplete="off" className="filters" onSubmit={handleSubmit(this.submitDocumentalSearchForm.bind(this))}>
            <div className={'search-form ' + (showFilter(hasResults, hideFilters) ? 'hola' : 'hideFilters ') }>
              <Field
                component={InputSelect}
                colWidht={50} // 25, 33, 50, 75, 100 default=25
                id="documentEntityTypeId"
                name="documentEntityTypeId"
                controlLabel={t(`${tKey}SEARCH_DOCUMENTS_IN`)}
                placeholder={t(`${tKey}SEARCH_DOCUMENTS_INPUT`)}
                options={documentEntityTypeCombo}
                valueKey="id"
                labelKey="value"
                onInputChange={(val) => { this.onDocumentEntityTypeChanges.bind(this); fieldFill({ documentEntityTypeId: val }) }}
                customClass={fields.documentEntityTypeId ? classNameSelect : ''}
              />
              <Field
                component={InputSelect}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                id="documentTypeId"
                name="documentTypeId"
                controlLabel={t(`${tKey}DOCUMENT_TYPE`)}
                placeholder={t(`${tKey}DOCUMENT_TYPE`)}
                options={documentsTypesCombo}
                valueKey="id"
                labelKey="value"
                onInputChange={(val) => { fieldFill({ documentTypeId: val }) }}
                customClass={fields.documentTypeId ? classNameSelect : ''}
              />
              <Field
                component={InputSelect}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                id="documentStatusId"
                name="documentStatusId"
                controlLabel={t(`${tKey}STATE`)}
                placeholder={t(`${tKey}STATE`)}
                options={documentStatusCombo}
                valueKey="id"
                labelKey="value"
                onInputChange={(val) => { fieldFill({ documentStatusId: val }) }}
                customClass={fields.documentStatusId ? classNameSelect : ''}
              />
              <Field
                component={InputDatePicker}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                maxDate={getDateNowMaxDatePicker()}
                id="dossierCreationDateFrom"
                name="creationDateFrom"
                controlLabel={t(`${tKey}CREATION_DATE_FROM`)}
                placeholder={t(`${tKey}SINCE`)}
                validate={[this.dateValidation]}
                onInputChange={(val) => fieldFill({ dossierCreationDateFrom: val })}
                customClass={fields.dossierCreationDateFrom ? className : ''}
              />
              <Field
                component={InputDatePicker}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                maxDate={getDateNowMaxDatePicker()}
                id="dossierCreationDateTo"
                name="creationDateTo"
                controlLabel={t(`${tKey}CREATION_DATE_TO`)}
                validate={[this.dateValidation]}
                onInputChange={(val) => fieldFill({ dossierCreationDateTo: val })}
                customClass={fields.dossierCreationDateTo ? className : ''}
              />
              <Field
                component={InputDatePicker}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                maxDate={getDateNowMaxDatePicker()}
                id="dossierValidationDateFrom"
                name="validationDateFrom"
                controlLabel={t(`${tKey}VALIDATION_DATE_FROM`)}
                placeholder={t(`${tKey}SINCE`)}
                validate={[this.dateValidation]}
                onInputChange={(val) => fieldFill({ dossierValidationDateFrom: val })}
                customClass={fields.dossierValidationDateFrom ? className : ''}
              />
              <Field
                component={InputDatePicker}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                maxDate={getDateNowMaxDatePicker()}
                id="dossierValidationDateTo"
                name="validationDateTo"
                controlLabel={t(`${tKey}VALIDATION_DATE_TO`)}
                validate={[this.dateValidation]}
                onInputChange={(val) => fieldFill({ dossierValidationDateTo: val })}
                customClass={fields.dossierValidationDateTo ? className : ''}
              />
              {
                (this.props.formValues === undefined || Object.keys(this.props.formValues).length === 0 || (
                  !this.props.formValues.documentEntityTypeId && !this.props.formValues.documentTypeId &&
                  !this.props.formValues.documentStatusId && !this.props.formValues.creationDateFrom &&
                  !this.props.formValues.creationDateTo && !this.props.formValues.validationDateFrom &&
                  !this.props.formValues.validationDateTo
                )) && (
                  <span className="info-general-filer"><i className="iDocIcon-warning"></i><p>{t(`${tKey}SOME_GENERAL_FILTER`)}</p></span>
                )
              }
              {/** ******** VEHICLE ************************************************************************************/}
              { this.state.documentEntityTypeId === documentEntityType.VEHICLE && (<>
                <div className="section-header-title subSection">
                  <i className="collapseBtn subSection iDocIcon-file-vehicle"></i>
                  <h4>{t(`${tKey}VEHICLE_FILTERS`)}</h4>
                </div>
                <Field
                  component={InputSelect}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="vehicleBrand"
                  name="vehicleBrandId"
                  controlLabel={t(`${tKey}BRAND`)}
                  placeholder={t(`${tKey}BRAND`)}
                  options={brandCombo}
                  valueKey="id"
                  labelKey="value"
                  onInputChange={(val) => { fieldFill({ vehicleBrand: val }) }}
                  customClass={fields.vehicleBrand ? classNameSelect : ''}
                />
                <Field
                  component={InputText}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="vehicleModel"
                  name="vehicleModel"
                  controlLabel={t(`${tKey}MODEL`)}
                  placeholder={t(`${tKey}MODEL`)}
                  onInputChange={(val) => fieldFill({ vehicleModel: val })}
                  customClass={fields.vehicleModel ? className : ''}
                />
                <Field
                  component={InputText}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="vehicleChassisNumber"
                  name="vehicleVinChasis"
                  controlLabel={t(`${tKey}CHASSIS`)}
                  placeholder={t(`${tKey}CHASSIS`)}
                  maxLength={17}
                  normalize={value => multipleNormalize(value, [toUpperCase, trimAll])}
                  onInputChange={(val) => fieldFill({ vehicleChassisNumber: val })}
                  customClass={fields.vehicleChassisNumber ? className : ''}
                />
                <Field
                  component={InputText}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="vehicleNumberPlate"
                  name="vehicleLicensePlate"
                  controlLabel={t(`${tKey}LICENSE_PLATE`)}
                  placeholder={t(`${tKey}LICENSE_PLATE`)}
                  normalize={value => multipleNormalize(value, [toUpperCase, trimAll])}
                  onInputChange={(val) => fieldFill({ vehicleNumberPlate: val })}
                  customClass={fields.vehicleNumberPlate ? className : ''}
                />
                <Field
                  component={InputText}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="comercialCode"
                  name="comercialCode"
                  controlLabel={t(`${tKey}COMMERCIAL_CODE`)}
                  placeholder={t(`${tKey}COMMERCIAL_CODE`)}
                  onInputChange={(val) => fieldFill({ comercialCode: val })}
                  customClass={fields.comercialCode ? className : ''}
                />
                <Field
                  component={InputDatePicker}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  maxDate={getDateNowMaxDatePicker()}
                  id="licensePlateDateFrom"
                  name="licensePlateDateFrom"
                  controlLabel={t(`${tKey}FIRST_LPLATE_DATE_FROM`)}
                  placeholder={t(`${tKey}SINCE`)}
                  validate={[this.dateValidation]}
                  // forceControlLabelInOneLine={true}
                  onInputChange={(val) => fieldFill({ licensePlateDateFrom: val })}
                  customClass={fields.licensePlateDateFrom ? className : ''}
                />
                <Field
                  component={InputDatePicker}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  maxDate={getDateNowMaxDatePicker()}
                  id="licensePlateDateTo"
                  name="licensePlateDateTo"
                  controlLabel={t(`${tKey}FIRST_LPLATE_DATE_TO`)}
                  validate={[this.dateValidation]}
                  onInputChange={(val) => fieldFill({ licensePlateDateTo: val })}
                  customClass={fields.licensePlateDateTo ? className : ''}
                />
                {
                  (this.props.formValues === undefined || Object.keys(this.props.formValues).length === 0 || (
                    !this.props.formValues.vehicleBrandId && !this.props.formValues.comercialCode &&
                    !this.props.formValues.licensePlateDateFrom && !this.props.formValues.vehicleLicensePlate &&
                    !this.props.formValues.licensePlateDateTo && !this.props.formValues.vehicleModel &&
                    !this.props.formValues.vehicleVinChasis
                  )) && (
                    <span className="info-general-filer"><i className="iDocIcon-warning"></i><p>{t(`${tKey}SOME_GENERAL_FILTER`)}</p></span>
                  )
                }
              </>)}
              {/** ******** ENTITY ************************************************************************************/}
              { this.state.documentEntityTypeId === documentEntityType.ENTITY && (<>
                <div className="section-header-title subSection">
                  <i className="collapseBtn subSection iDocIcon-user-user-menu"></i>
                  <h4>{t(`${tKey}CLIENT_FILTERS`)}</h4>
                </div>
                <Field
                  component={InputSelect}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="entityTypeId"
                  name="entityTypeId"
                  controlLabel={t(`${tKey}CLIENT_TYPE`)}
                  placeholder={t(`${tKey}CLIENT_TYPE`)}
                  options={entityTypesCombo}
                  valueKey="id"
                  labelKey="value"
                  onInputChange={(val) => { this.onEntityTypeChange(val); fieldFill({ entityTypeId: val }) }}
                  customClass={fields.entityTypeId ? classNameSelect : ''}
                />
                {(this.state.selectedEntityType === entityTypesId.recurrentEntity) && [
                  <Field
                    component={InputSelect}
                    colWidht={25} // 25, 33, 50, 75, 100 default=25
                    id="entitySubTypeId"
                    name="entitySubTypeId"
                    key="entitySubTypeId"
                    controlLabel={t(`${tKey}ENTITY_SUB_TYPE_ID`)}
                    placeholder={t(`${tKey}ENTITY_SUB_TYPE_ID`)}
                    options={entitySubTypeCombo}
                    valueKey="id"
                    labelKey="value"
                    onInputChange={(val) => { this.onEntitySubTypeChange(val); fieldFill({ entitySubTypeId: val }) }}
                    customClass={fields.entitySubTypeId ? className : ''}
                  />,
                  <Field
                    component={InputSelect}
                    colWidht={25} // 25, 33, 50, 75, 100 default=25
                    id="entityId"
                    name="entityId"
                    key="entityId"
                    controlLabel={t(`${tKey}ENTITY_ID`)}
                    placeholder={t(`${tKey}ENTITY_ID`)}
                    options={entityFilterSubTypeCombo}
                    valueKey="id"
                    labelKey="value"
                    disabled={!this.state.selectedEntitySubType}
                    onInputChange={(val) => fieldFill({ entityId: val })}
                    customClass={!this.state.selectedEntitySubType ? 'disabled' : ''}
                  />
                ]}
                {(this.state.selectedEntityType === entityTypesId.privateEntity || this.state.selectedEntityType === entityTypesId.businessEntity) && [
                  <Field
                    component={InputText}
                    colWidht={25} // 25, 33, 50, 75, 100 default=25
                    id="entityCifNif"
                    name="entityCifNif"
                    key="entityCifNif"
                    controlLabel={t(`${tKey}DNI`)}
                    placeholder={t(`${tKey}DNI`)}
                    onInputChange={(val) => fieldFill({ entityCifNif: val })}
                    customClass={fields.entityCifNif ? className : ''}
                  />,
                  <Field
                    component={InputText}
                    colWidht={25} // 25, 33, 50, 75, 100 default=25
                    id="entityName"
                    name="entityName"
                    key="entityName"
                    controlLabel={this.getEntityNameLabel()}
                    placeholder={this.getEntityNameLabel()}
                    onInputChange={(val) => fieldFill({ entityName: val })}
                    customClass={fields.entityName ? className : ''}
                  />
                ]}
                {this.state.selectedEntityType === entityTypesId.privateEntity && [
                  <Field
                    component={InputText}
                    colWidht={25} // 25, 33, 50, 75, 100 default=25
                    id="entitySurname1"
                    name="entitySurname1"
                    key="entitySurname1"
                    controlLabel={t(`${tKey}SURNAME1`)}
                    placeholder={t(`${tKey}SURNAME1`)}
                    onInputChange={(val) => fieldFill({ entitySurname1: val })}
                    customClass={fields.entitySurname1 ? className : ''}
                  />,
                  <Field
                    component={InputText}
                    colWidht={25} // 25, 33, 50, 75, 100 default=25
                    id="entitySurname2"
                    name="entitySurname2"
                    key="entitySurname2"
                    controlLabel={t(`${tKey}SURNAME2`)}
                    placeholder={t(`${tKey}SURNAME2`)}
                    onInputChange={(val) => fieldFill({ entitySurname2: val })}
                    customClass={fields.entitySurname2 ? className : ''}
                  />
                ]}
                {this.state.selectedEntityType === entityTypesId.businessEntity && [
                  <Field
                    component={InputText}
                    colWidht={25} // 25, 33, 50, 75, 100 default=25
                    id="representativeDni"
                    name="representativeDni"
                    key="representativeDni"
                    controlLabel={t(`${tKey}LEGAL_REPRESENTATIVE_DNI`)}
                    placeholder={t(`${tKey}LEGAL_REPRESENTATIVE_DNI`)}
                    onInputChange={(val) => fieldFill({ representativeDni: val })}
                    customClass={fields.representativeDni ? className : ''}
                  />,
                  <Field
                    component={InputText}
                    colWidht={25} // 25, 33, 50, 75, 100 default=25
                    id="representativeName"
                    name="representativeName"
                    key="representativeName"
                    controlLabel={t(`${tKey}LEGAL_REPRESENTATIVE_NAME`)}
                    placeholder={t(`${tKey}LEGAL_REPRESENTATIVE_NAME`)}
                    // forceControlLabelInOneLine={true}
                    onInputChange={(val) => fieldFill({ representativeName: val })}
                    customClass={fields.representativeName ? className : ''}
                  />,
                  <Field
                    component={InputText}
                    colWidht={25} // 25, 33, 50, 75, 100 default=25
                    id="representativeSurname1"
                    name="representativeSurname1"
                    key="representativeSurname1"
                    controlLabel={t(`${tKey}LEGAL_REPRESENTATIVE_SURNAME1`)}
                    placeholder={t(`${tKey}LEGAL_REPRESENTATIVE_SURNAME1`)}
                    // forceControlLabelInOneLine={true}
                    onInputChange={(val) => fieldFill({ representativeSurname1: val })}
                    customClass={fields.representativeSurname1 ? className : ''}
                  />,
                  <Field
                    component={InputText}
                    colWidht={25} // 25, 33, 50, 75, 100 default=25
                    id="representativeSurname2"
                    name="representativeSurname2"
                    key="representativeSurname2"
                    controlLabel={t(`${tKey}LEGAL_REPRESENTATIVE_SURNAME2`)}
                    placeholder={t(`${tKey}LEGAL_REPRESENTATIVE_SURNAME2`)}
                    // forceControlLabelInOneLine={true}
                    onInputChange={(val) => fieldFill({ representativeSurname2: val })}
                    customClass={fields.representativeSurname2 ? className : ''}
                  />
                ]}
                {(this.state.selectedEntityType !== entityTypesId.recurrentEntity) && [
                  <Field
                    component={InputText}
                    colWidht={25} // 25, 33, 50, 75, 100 default=25
                    id="entityMail"
                    name="entityMail"
                    key="entityMail"
                    controlLabel={t(`${tKey}EMAIL`)}
                    placeholder={t(`${tKey}EMAIL`)}
                    onInputChange={(val) => fieldFill({ entityMail: val })}
                    customClass={fields.entityMail ? className : ''}
                  />,
                  this.state.selectedEntityType === entityTypesId.businessEntity && <Clearfix key="clearfix_entityMail" />,
                  <Field
                    component={InputText}
                    colWidht={25} // 25, 33, 50, 75, 100 default=25
                    id="entityPhone"
                    name="entityPhone"
                    key="entityPhone"
                    controlLabel={t(`${tKey}TELEPHONE`)}
                    placeholder={t(`${tKey}TELEPHONE`)}
                    onInputChange={(val) => fieldFill({ entityPhone: val })}
                    customClass={fields.entityPhone ? className : ''}
                  />,
                  <Field
                    component={InputText}
                    colWidht={25} // 25, 33, 50, 75, 100 default=25
                    id="entityAddress"
                    name="entityAddress"
                    key="entityAddress"
                    controlLabel={t(`${tKey}DIRECCTION`)}
                    placeholder={t(`${tKey}DIRECCTION`)}
                    onInputChange={(val) => fieldFill({ entityAddress: val })}
                    customClass={fields.entityAddress ? className : ''}
                  />
                ]}
                {
                  (this.props.formValues === undefined || Object.keys(this.props.formValues).length === 0 || (
                    !this.props.formValues.entityTypeId && !this.props.formValues.entityCifNif &&
                        !this.props.formValues.entityName && !this.props.formValues.entitySurname1 &&
                        !this.props.formValues.entitySurname2 && !this.props.formValues.representativeDni &&
                        !this.props.formValues.representativeName && !this.props.formValues.representativeSurname1 &&
                        !this.props.formValues.representativeSurname2 && !this.props.formValues.entityMail &&
                        !this.props.formValues.entityPhone && !this.props.formValues.entityAddress
                  )) && (
                    <span className="info-general-filer"><i className="iDocIcon-warning"></i><p>{t(`${tKey}SOME_GENERAL_FILTER`)}</p></span>
                  )}
              </>)}
              {/** ******** DOSSIER ************************************************************************************/}
              { this.state.documentEntityTypeId === documentEntityType.DOSSIER && (<>
                <div className="section-header-title subSection">
                  <i className="collapseBtn subSection iDocIcon-file-folder"></i>
                  <h4>{t(`${tKey}DOSSIER_FILTERS`)}</h4>
                </div>
                <Field
                  component={InputText}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="number"
                  name="number"
                  controlLabel={t(`${tKey}DOSSIER_NUMBER`)}
                  placeholder={t(`${tKey}DOSSIER_NUMBER`)}
                  onInputChange={(val) => fieldFill({ number: val })}
                  customClass={fields.number ? className : ''}
                />
                <Field
                  component={InputSelect}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="organizedUnitId"
                  name="organizedUnitId"
                  controlLabel={t(`${tKey}UO`)}
                  placeholder={t(`${tKey}UO`)}
                  options={lowestOrganizedUnitsCombo}
                  valueKey="id"
                  labelKey="value"
                  onInputChange={(val) => { fieldFill({ organizedUnitId: val }) }}
                  customClass={fields.organizedUnitId ? classNameSelect : ''}
                />
                <Field
                  component={InputSelect}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="dossierSubtype"
                  name="dossierSubTypeId"
                  controlLabel={t(`${tKey}DOSSIER_SUBTYPE`)}
                  placeholder={t(`${tKey}DOSSIER_SUBTYPE`)}
                  options={searchDossierSubtypesCombo}
                  valueKey="id"
                  labelKey="value"
                  onInputChange={(val) => { this.onDossierSubtypeChange(val); fieldFill({ dossierSubtype: val }) }}
                  customClass={fields.dossierSubtype ? classNameSelect : ''}
                />
                <Field
                  component={InputSelect}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="dossierStage"
                  name="dossierStageId"
                  controlLabel={t(`${tKey}STAGE`)}
                  placeholder={t(`${tKey}STAGE`)}
                  multi
                  menuContainerStyle={{ zIndex: 999 }}
                  options={stagesCombo}
                  valueKey="id"
                  labelKey="value"
                  disabled={stagesCombo.length === 0}
                  onInputChange={(val) => { fieldFill({ dossierStage: val }); this.onDossierStageChange(val) }}
                  customClass={stagesCombo.length === 0 ? 'disabled' : ''}
                />
                <Field
                  component={InputDatePicker}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  maxDate={getDateNowMaxDatePicker()}
                  id="orderDateFrom"
                  name="orderDateFrom"
                  controlLabel={t(`${tKey}ORDER_DATE_FROM`)}
                  placeholder={t(`${tKey}SINCE`)}
                  validate={[this.dateValidation]}
                  onInputChange={(val) => fieldFill({ orderDateFrom: val })}
                  customClass={fields.orderDateFrom ? className : ''}
                />
                <Field
                  component={InputDatePicker}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  maxDate={getDateNowMaxDatePicker()}
                  id="orderDateTo"
                  name="orderDateTo"
                  controlLabel={t(`${tKey}ORDER_DATE_TO`)}
                  validate={[this.dateValidation]}
                  onInputChange={(val) => fieldFill({ orderDateTo: val })}
                  customClass={fields.orderDateTo ? className : 'date-margin '}
                />
                <Field
                  component={InputDatePicker}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  maxDate={getDateNowMaxDatePicker()}
                  id="creationDossierDateFrom"
                  name="creationDossierDateFrom"
                  controlLabel={t(`${tKey}CREATION_DATE_FROM`)}
                  placeholder={t(`${tKey}SINCE`)}
                  validate={[this.dateValidation]}
                  onInputChange={(val) => fieldFill({ creationDossierDateFrom: val })}
                  customClass={fields.creationDossierDateFrom ? className : ''}
                />
                <Field
                  component={InputDatePicker}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  maxDate={getDateNowMaxDatePicker()}
                  id="creationDossierDateTo"
                  name="creationDossierDateTo"
                  controlLabel={t(`${tKey}CREATION_DATE_TO`)}
                  validate={[this.dateValidation]}
                  onInputChange={(val) => fieldFill({ creationDossierDateTo: val })}
                  customClass={fields.creationDossierDateTo ? className : 'date-margin '}
                />
                <Field
                  component={InputText}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="dealerNumber"
                  name="dealerNumber"
                  controlLabel={t(`${tKey}DEALER_NUMBER`)}
                  placeholder={t(`${tKey}DEALER_NUMBER`)}
                  maxLength={settings.dealerMaxLength}
                  onInputChange={(val) => fieldFill({ dealerNumber: val })}
                  customClass={fields.dealerNumber ? className : ''}
                />
                {
                  !this.props.formValues || (!this.props.formValues.number && !this.props.formValues.organizedUnitId && !this.props.formValues.dossierSubTypeId && !this.props.formValues.orderDateFrom && !this.props.formValues.orderDateTo &&
                          !this.props.formValues.creationDossierDateFrom && !this.props.formValues.creationDossierDateTo && !this.props.formValues.dealerNumber && !this.props.formValues.load) && (
                    <span className="info-general-filer"><i className="iDocIcon-warning"></i><p>{t(`${tKey}SOME_GENERAL_FILTER`)}</p></span>
                  )
                }
              </>)}
              <div className='search-form-actions flexInLine al-R'>
                <Button id="btn_search"
                  type="submit"
                  className="_Btn accept stk">
                  <i className="iDocIcon-search"/>
                  {t('SEARCH.ACTIONS.SEARCH_BUTTON')}
                </Button>
                <Button
                  id="btn_reset"
                  onClick={(e) => this.onClickResetFilters(e)}
                  className={'_Btn' + (this.props.pristine ? ' accept' : ' accept')}
                  disabled={this.props.pristine}>
                  <i className="iDocIcon-clear-decline"/>
                  {t('SEARCH.ACTIONS.RESET_BUTTON')}
                </Button>
              </div>
            </div>
            {/* //////// Parte antigua, oculto por css //////////////////////////////////////////////////////////////////////////////////////// */}
            <Col sm={12} className="filters-footer hide">
              <hr />
              {
                this.props.formValues !== undefined ? (
                  (!this.props.formValues.documentEntityTypeId && !this.props.formValues.documentTypeId && !this.props.formValues.documentStatusId && !this.props.formValues.creationDateFrom && !this.props.formValues.creationDateTo && !this.props.formValues.validationDateFrom && !this.props.formValues.validationDateTo) ? (
                    <Button bsStyle="primary m-right"
                      className={'btn-white btn-icon btn-cancel'}>
                      <Glyphicon glyph="search" />
                      {t(`${tKey}ACTIONS.SEARCH_BUTTON`)}
                    </Button>
                  ) : (
                    <Button bsStyle="primary m-right" className={'btn-white btn-icon' + (pristine ? ' btn-cancel' : ' btn-standard')} type="submit"><Glyphicon glyph="search" /> {t(`${tKey}ACTIONS.SEARCH_BUTTON`)}</Button>
                  )
                ) : (
                  <Button bsStyle="primary m-right" className={'btn-white btn-icon btn-cancel'}><Glyphicon glyph="search" /> {t(`${tKey}ACTIONS.SEARCH_BUTTON`)}</Button>
                )
              }
              <Button
                onClick={(e) => {
                  if (!pristine) {
                    initialize({})
                    resetFields()
                  }
                }}
                className={'btn-white btn-icon' + (pristine ? ' btn-cancel' : ' btn-standard')}
              >
                <i className="ico-cancel" /> {t(`${tKey}ACTIONS.RESET_BUTTON`)}
              </Button>
            </Col>
            {/* //////// Parte antigua, oculto por css //////////////////////////////////////////////////////////////////////////////////////// */}
          </Form>
        </div>
        <div className="section-results-body">
          <div className={'searchResoultsContainer' + (this.state.maxiTable ? ' maxiTable' : '')}>
            {isSearching && ([
              <div className="table-wrapper" key="table-wrapper">
                <ul className="list-inline pull-left">
                  {this.selectedFilters().length === 0 ? <li>{t(`${tKey}NO_SELECTED_FILTERS`)}</li> : (this.selectedFilters())}
                </ul>
                { hasResults &&
                  <div className='flexInLine al-R'>
                    <OverlayTrigger placement="left" overlay={<Tooltip id="editProductTooltip">{`${t((this.state.maxiTable ? 'GLOBAL.TABLES.MINIMIZE_TABLE' : 'GLOBAL.TABLES.MAXIMIZE_TABLE'))}`}</Tooltip>}>
                      <a onClick={() => this.toogleMaxiTable()} className='_Btn accept stk o-icn maximizeBtn'><i className={(this.state.maxiTable ? 'iDocIcon-Minimize' : 'iDocIcon-Maximize')}></i></a>
                    </OverlayTrigger>
                    { massiveDownloads && massiveDownloads.permission > permissions.hidden && documents && documents.length > 0 &&
                      <OverlayTrigger placement="left" overlay={<Tooltip id="editProductTooltip">{t(`${tKey}ACTIONS.DOWNLOAD_SELECTED`)}</Tooltip>}>
                        <a onClick={() => this.massiveDownloadDocuments()} className='_Btn new o-icn exportToExcel'><i className="iDocIcon-download"></i></a>
                      </OverlayTrigger>
                    }
                    { massiveDownloads && massiveDownloads.permission > permissions.hidden && documents && documents.length > 0 &&
                      <Checkbox
                        className="pull-right"
                        onChange={() => this.selectAllDocuments()}
                        checked={this.state.allSelected}>
                        {t(`${tKey}ACTIONS.SELECT_ALL`)}
                      </Checkbox>
                    }
                  </div>
                }
                {/* table */}
                { hasResults &&
                  <div className="table-responsive">
                    {this.props.showPartialRecordsWarning &&
                      <span className="info-general-filer warning"><i className="iDocIcon-warning"></i> {t(`${tKey}RESULTS_EXCEED_MAX`)}</span>
                    }
                    { dynamicFilters.documentEntityTypeId === 1 && (
                      <SimpleTablePage
                        columns={(massiveDownloads && massiveDownloads.permission > permissions.hidden) ? ([
                          <th key={0} data-field="entity" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.CLIENT.TITLE`)} <i className={getIconClass('entity', this.props.filters)}/></th>,
                          <th key={1} data-field="documentType" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.CLIENT.DOCUMENT_TYPE`)} <i className={getIconClass('documentType', this.props.filters)}/></th>,
                          <th key={2} data-field="status" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.CLIENT.STATE`)} <i className={getIconClass('status', this.props.filters)}/></th>,
                          <th key={3} data-field="creationDate" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.CLIENT.CREATION_DATE`)} <i className={getIconClass('creationDate', this.props.filters)}/></th>,
                          <th key={4} data-field="validationDate" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.CLIENT.VALIDATION_DATE`)} <i className={getIconClass('validationDate', this.props.filters)}/></th>,
                          <th key={5}>{t(`${tKey}TABLE.CLIENT.AMOUNT_RELATED_DOSSIERS`)}</th>,
                          <th key={6}>{t(`${tKey}SELECT_DOWNLOAD`)}</th>,
                          <th key={7}></th>
                        ]) : ([
                          <th key={0} data-field="entity" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.CLIENT.TITLE`)} <i className={getIconClass('entity', this.props.filters)}/></th>,
                          <th key={1} data-field="documentType" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.CLIENT.DOCUMENT_TYPE`)} <i className={getIconClass('documentType', this.props.filters)}/></th>,
                          <th key={2} data-field="status" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.CLIENT.STATE`)} <i className={getIconClass('status', this.props.filters)}/></th>,
                          <th key={3} data-field="creationDate" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.CLIENT.CREATION_DATE`)} <i className={getIconClass('creationDate', this.props.filters)}/></th>,
                          <th key={4} data-field="validationDate" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.CLIENT.VALIDATION_DATE`)} <i className={getIconClass('validationDate', this.props.filters)}/></th>,
                          <th key={5}>{t(`${tKey}TABLE.CLIENT.AMOUNT_RELATED_DOSSIERS`)}</th>,
                          <th key={6}></th>
                        ])}
                        rows={documents.map((row, idx) => (
                          <tr key={idx}>
                            <td className="see-more">
                              <EntityPopover
                                t={t}
                                entity={row.entity}
                                half={(documents.length || 1) / (idx + 1)}
                                idx={idx}
                                id={'popover-entity' + idx}
                              />
                            </td>
                            <td>{row.documentType}</td>
                            <td style={{ paddingLeft: '20px' }}>
                              <span>
                                <OverlayTrigger placement="left" overlay={row.status === documentStatus.VALIDATED ? (tooltipValidate) : row.status === documentStatus.PENDING ? (tooltipPending) : row.status === documentStatus.REJECTED ? (tooltipRejected) : row.status === documentStatus.REMOVED ? (tooltipRemoved) : row.status === documentStatus.EXPIRED ? (tooltipExpired) : null}>
                                  <i id="documentStatus" className={'ico-' + documentStatusClass[translateDocumentStatus[row.status]]}/>
                                </OverlayTrigger>
                              </span>
                            </td>
                            <td>{row.creationDate}</td>
                            <td>{row.validationDate}</td>
                            <td className="see-more">
                              <DossierListPopover
                                t={t}
                                half={(documents.length || 1) / (idx + 1)}
                                idx={idx}
                                id={'popover-dossier-list' + idx}
                                alive={row.lives}
                                stored={row.stored}
                              />
                            </td>
                            {massiveDownloads && massiveDownloads.permission > permissions.hidden &&
                              <td>
                                {
                                  <Field
                                    colSm={2}
                                    component={InputCheckbox}navigationTooltip
                                    name={'downloadCheck' + row.documentId}
                                    inline
                                    customClass="checkbox-inline-search"
                                    onChange={() => this.addDocumentIdToArray(row.documentId)}
                                    checked= {this.state.documentsSelected.includes(row.documentId)}
                                  />
                                }
                              </td>
                            }
                            <td key={idx} className="navigation">
                              {row.documentId && (
                                <OverlayTrigger placement="left" overlay={navigationTooltip}>
                                  <a className="_Btn accept stk o-icn noLine"
                                    onClick={() => row.isHistorical === false ? (
                                      this.props.actions.openDocumentEditionModal(row.documentId,
                                        row.documentTypeId,
                                        row.documentParentTypeId,
                                        row.dossierId,
                                        row.entityId,
                                        row.vehicleId,
                                        row.organizedUnitId,
                                        null,
                                        () => fetchDocumentSearchList({
                                          items: documents, pages, count, ...this.props.dynamicFilters, page
                                        }),
                                        true,
                                        '',
                                        '', null, null, null, null,
                                        false)
                                    ) : (
                                      this.props.actions.openDocumentEditionModalHistorical(row.documentId, row.documentTypeId, row.documentParentTypeId, row.dossierId, row.entityId, row.vehicleId)
                                    )
                                    }>
                                    <i class="iDocIcon-open-in-new-black"></i>
                                  </a>
                                </OverlayTrigger>
                              )}
                            </td>
                          </tr>
                        ))}
                      />
                    )}
                    { dynamicFilters.documentEntityTypeId === 2 && (
                      <SimpleTablePage
                        columns={(massiveDownloads && massiveDownloads.permission > permissions.hidden) ? ([
                          <th key={0} data-field="vehicle" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.VEHICLE.TITLE`)} <i className={getIconClass('vehicle', this.props.filters)}/></th>,
                          <th key={1} data-field="documentType" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.VEHICLE.DOCUMENT_TYPE`)} <i className={getIconClass('documentType', this.props.filters)}/></th>,
                          <th key={2} data-field="status" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.VEHICLE.STATE`)} <i className={getIconClass('status', this.props.filters)}/></th>,
                          <th key={3} data-field="creationDate" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.VEHICLE.FIRST_REGISTRATION_DATE`)} <i className={getIconClass('creationDate', this.props.filters)}/></th>,
                          <th key={4} data-field="validationDate" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.VEHICLE.VALIDATION_DATE`)} <i className={getIconClass('validationDate', this.props.filters)}/></th>,
                          <th key={5}>{t(`${tKey}TABLE.VEHICLE.AMOUNT_RELATED_DOSSIERS`)}</th>,
                          <th key={6}>{t(`${tKey}SELECT_DOWNLOAD`)}</th>,
                          <th key={7}></th>
                        ]) : ([
                          <th key={0} data-field="vehicle" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.VEHICLE.TITLE`)} <i className={getIconClass('vehicle', this.props.filters)}/></th>,
                          <th key={1} data-field="documentType" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.VEHICLE.DOCUMENT_TYPE`)} <i className={getIconClass('documentType', this.props.filters)}/></th>,
                          <th key={2} data-field="status" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.VEHICLE.STATE`)} <i className={getIconClass('status', this.props.filters)}/></th>,
                          <th key={3} data-field="creationDate" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.VEHICLE.FIRST_REGISTRATION_DATE`)} <i className={getIconClass('creationDate', this.props.filters)}/></th>,
                          <th key={4} data-field="validationDate" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.VEHICLE.VALIDATION_DATE`)} <i className={getIconClass('validationDate', this.props.filters)}/></th>,
                          <th key={5}>{t(`${tKey}TABLE.VEHICLE.AMOUNT_RELATED_DOSSIERS`)}</th>,
                          <th key={6}></th>
                        ])}
                        rows={documents.map((row, idx) => (
                          <tr key={idx}>
                            <td className="see-more">
                              <VehiclePopover
                                t={t}
                                vehicle={row.vehicle}
                                half={(documents.length || 1) / (idx + 1)}
                                idx={idx}
                                id={'popover-vehicle' + idx}
                              />
                            </td>
                            <td>{row.documentType}</td>
                            <td style={{ paddingLeft: '20px' }}>
                              <span>
                                <OverlayTrigger placement="right" overlay={row.status === documentStatus.VALIDATED ? (tooltipValidate) : row.status === documentStatus.PENDING ? (tooltipPending) : row.status === documentStatus.REJECTED ? (tooltipRejected) : row.status === documentStatus.REMOVED ? (tooltipRemoved) : null}>
                                  <i id="documentStatus" className={'ico-' + documentStatusClass[translateDocumentStatus[row.status]]}/>
                                </OverlayTrigger>
                              </span>
                            </td>
                            <td>{row.creationDate}</td>
                            <td>{row.validationDate}</td>
                            <td className="see-more">
                              <DossierListPopover
                                t={t}
                                half={(documents.length || 1) / (idx + 1)}
                                idx={idx}
                                id={'popover-dossier-list' + idx}
                                alive={row.lives}
                                stored={row.stored}
                              />
                            </td>
                            {massiveDownloads && massiveDownloads.permission > permissions.hidden &&
                              <td>
                                {
                                  <Field
                                    colSm={2}
                                    component={InputCheckbox}
                                    name={'downloadCheck' + row.documentId}
                                    inline
                                    customClass="checkbox-inline-search"
                                    onChange={() => this.addDocumentIdToArray(row.documentId)}
                                    checked= {this.state.documentsSelected.includes(row.documentId)}
                                  />
                                }
                              </td>
                            }
                            <td key={idx} className="navigation">
                              {row.documentId && (
                                <OverlayTrigger placement="left" overlay={navigationTooltip}>
                                  <a className="_Btn accept stk o-icn noLine"
                                    onClick={() => row.isHistorical === false ? (
                                      this.props.actions.openDocumentEditionModal(row.documentId,
                                        row.documentTypeId,
                                        row.documentParentTypeId,
                                        row.dossierId,
                                        row.entityId,
                                        row.vehicleId,
                                        row.organizedUnitId,
                                        null,
                                        () => fetchDocumentSearchList({
                                          items: documents, pages, count, ...this.props.dynamicFilters, page
                                        }),
                                        true,
                                        '',
                                        '', null, null, null, null,
                                        false)
                                    ) : (
                                      this.props.actions.openDocumentEditionModalHistorical(row.documentId, row.documentTypeId, row.documentParentTypeId, row.dossierId, row.entityId, row.vehicleId)
                                    )
                                    }>
                                    <i class="iDocIcon-open-in-new-black"></i>
                                  </a>
                                </OverlayTrigger>
                              )}
                            </td>
                          </tr>
                        ))}
                      />
                    )}
                    { dynamicFilters.documentEntityTypeId === 3 && (
                      <SimpleTablePage
                        columns={(massiveDownloads && massiveDownloads.permission > permissions.hidden) ? ([
                          <th key={0} data-field="documentType" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.DOCUMENT_TYPE`)} <i className={getIconClass('documentType', this.props.filters)}/></th>,
                          <th key={1} data-field="dossier" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.DOSSIER`)} <i className={getIconClass('dossier', this.props.filters)}/></th>,
                          <th key={2} data-field="entity" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.ENTITY`)} <i className={getIconClass('entity', this.props.filters)}/></th>,
                          <th key={3} data-field="vehicle" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.VEHICLE`)} <i className={getIconClass('vehicle', this.props.filters)}/></th>,
                          <th key={4} data-field="status" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.STATE`)} <i className={getIconClass('status', this.props.filters)}/></th>,
                          <th key={5} data-field="creationDate" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.CREATION_DATE`)} <i className={getIconClass('creationDate', this.props.filters)}/></th>,
                          <th key={6} data-field="validationDate" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.VALIDATION_DATE`)} <i className={getIconClass('validationDate', this.props.filters)}/></th>,
                          <th key={7}>{t(`${tKey}SELECT_DOWNLOAD`)}</th>,
                          <th key={8}></th>
                        ]) : ([
                          <th key={0} data-field="documentType" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.DOCUMENT_TYPE`)} <i className={getIconClass('documentType', this.props.filters)}/></th>,
                          <th key={1} data-field="dossier" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.DOSSIER`)} <i className={getIconClass('dossier', this.props.filters)}/></th>,
                          <th key={2} data-field="entity" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.ENTITY`)} <i className={getIconClass('entity', this.props.filters)}/></th>,
                          <th key={3} data-field="vehicle" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.VEHICLE`)} <i className={getIconClass('vehicle', this.props.filters)}/></th>,
                          <th key={4} data-field="status" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.STATE`)} <i className={getIconClass('status', this.props.filters)}/></th>,
                          <th key={5} data-field="creationDate" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.CREATION_DATE`)} <i className={getIconClass('creationDate', this.props.filters)}/></th>,
                          <th key={6} data-field="validationDate" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.VALIDATION_DATE`)} <i className={getIconClass('validationDate', this.props.filters)}/></th>,
                          <th key={7}></th>
                        ])}
                        rows={documents.map((row, idx) => (
                          <tr key={idx}>
                            <td>{row.documentType}</td>
                            <td className="see-more">
                              <DossierPopover
                                t={t}
                                dossier={row.dossier}
                                half={(documents.length || 1) / (idx + 1)}
                                idx={idx}
                                id={'popover-dossier' + idx}
                              />
                            </td>
                            <td className="see-more">
                              <EntityPopover
                                t={t}
                                entity={row.entity}
                                half={(documents.length || 1) / (idx + 1)}
                                idx={idx}
                                id={'popover-entity' + idx}
                              />
                            </td>
                            <td className="see-more">
                              <VehiclePopover
                                t={t}
                                vehicle={row.vehicle}
                                half={(documents.length || 1) / (idx + 1)}
                                idx={idx}
                                id={'popover-vehicle' + idx}
                              />
                            </td>
                            <td style={{ paddingLeft: '20px' }}>
                              <span>
                                <OverlayTrigger placement="left" overlay={row.status === documentStatus.VALIDATED ? (tooltipValidate) : row.status === documentStatus.PENDING ? (tooltipPending) : row.status === documentStatus.REJECTED ? (tooltipRejected) : row.status === documentStatus.REMOVED ? (tooltipRemoved) : null}>
                                  <i id="documentStatus" className={'ico-' + documentStatusClass[translateDocumentStatus[row.status]]}/>
                                </OverlayTrigger>
                              </span>
                            </td>
                            <td>{row.creationDate}</td>
                            <td>{row.validationDate}</td>
                            {massiveDownloads && massiveDownloads.permission > permissions.hidden &&
                              <td>
                                {
                                  <Field
                                    colSm={2}
                                    component={InputCheckbox}
                                    name={'downloadCheck' + row.documentId}
                                    inline
                                    customClass="checkbox-inline-search"
                                    onChange={() => this.addDocumentIdToArray(row.documentId)}
                                    checked= {this.state.documentsSelected.includes(row.documentId)}
                                  />
                                }
                              </td>
                            }
                            <td key={idx} className="navigation">
                              {row.documentId && (
                                <OverlayTrigger placement="left" overlay={navigationTooltip}>
                                  <a className="_Btn accept stk o-icn noLine"
                                    onClick={() => row.isHistorical === false ? (
                                      this.props.actions.openDocumentEditionModal(row.documentId,
                                        row.documentTypeId,
                                        row.documentParentTypeId,
                                        row.dossierId,
                                        row.entityId,
                                        row.vehicleId,
                                        row.organizedUnitId,
                                        null,
                                        () => fetchDocumentSearchList({
                                          items: documents, pages, count, ...this.props.dynamicFilters, page
                                        }),
                                        true,
                                        '',
                                        '', null, null, null, null,
                                        false)
                                    ) : (
                                      this.props.actions.openDocumentEditionModalHistorical(row.documentId, row.documentTypeId, row.documentParentTypeId, row.dossierId, row.entityId, row.vehicleId)
                                    )
                                    }>
                                    <i class="iDocIcon-open-in-new-black"></i>
                                  </a>
                                </OverlayTrigger>
                              )}
                            </td>
                          </tr>
                        ))}
                      />
                    )}
                    { dynamicFilters.documentEntityTypeId !== 1 && dynamicFilters.documentEntityTypeId !== 2 && dynamicFilters.documentEntityTypeId !== 3 && (
                      dynamicFilters.documentTypeId ? (
                        <SimpleTablePage
                          columns={(massiveDownloads && massiveDownloads.permission > permissions.hidden) ? ([
                            <th key={0} data-field="documentType" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.DOCUMENT_TYPE`)} <i className={getIconClass('documentType', this.props.filters)}/></th>,
                            <th key={1} data-field="dossier" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.DOSSIER`)} <i className={getIconClass('dossier', this.props.filters)}/></th>,
                            <th key={2} data-field="entity" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.ENTITY`)} <i className={getIconClass('entity', this.props.filters)}/></th>,
                            <th key={3} data-field="vehicle" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.VEHICLE`)} <i className={getIconClass('vehicle', this.props.filters)}/></th>,
                            <th key={4} data-field="status" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.STATE`)} <i className={getIconClass('status', this.props.filters)}/></th>,
                            <th key={5} data-field="creationDate" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.CREATION_DATE`)} <i className={getIconClass('creationDate', this.props.filters)}/></th>,
                            <th key={6} data-field="validationDate" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.VALIDATION_DATE`)} <i className={getIconClass('validationDate', this.props.filters)}/></th>,
                            <th key={7}>{t(`${tKey}TABLE.CLIENT.AMOUNT_RELATED_DOSSIERS`)}</th>,
                            <th key={8}>{t(`${tKey}SELECT_DOWNLOAD`)}</th>,
                            <th key={9}></th>
                          ]) : ([
                            <th key={0} data-field="documentType" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.DOCUMENT_TYPE`)} <i className={getIconClass('documentType', this.props.filters)}/></th>,
                            <th key={1} data-field="dossier" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.DOSSIER`)} <i className={getIconClass('dossier', this.props.filters)}/></th>,
                            <th key={2} data-field="entity" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.ENTITY`)} <i className={getIconClass('entity', this.props.filters)}/></th>,
                            <th key={3} data-field="vehicle" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.VEHICLE`)} <i className={getIconClass('vehicle', this.props.filters)}/></th>,
                            <th key={4} data-field="status" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.STATE`)} <i className={getIconClass('status', this.props.filters)}/></th>,
                            <th key={5} data-field="creationDate" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.CREATION_DATE`)} <i className={getIconClass('creationDate', this.props.filters)}/></th>,
                            <th key={6} data-field="validationDate" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.VALIDATION_DATE`)} <i className={getIconClass('validationDate', this.props.filters)}/></th>,
                            <th key={7}>{t(`${tKey}TABLE.CLIENT.AMOUNT_RELATED_DOSSIERS`)}</th>,
                            <th key={8}></th>
                          ])}
                          rows={documents.map((row, idx) => (
                            <tr key={idx}>
                              <td>{row.documentType}</td>
                              <td className="see-more">
                                <DossierPopover
                                  t={t}
                                  dossier={row.dossier}
                                  half={(documents.length || 1) / (idx + 1)}
                                  idx={idx}
                                  id={'popover-dossier' + idx}
                                />
                              </td>
                              <td className="see-more">
                                <EntityPopover
                                  t={t}
                                  entity={row.entity}
                                  half={(documents.length || 1) / (idx + 1)}
                                  idx={idx}
                                  id={'popover-entity' + idx}
                                />
                              </td>
                              <td className="see-more">
                                <VehiclePopover
                                  t={t}
                                  vehicle={row.vehicle}
                                  half={(documents.length || 1) / (idx + 1)}
                                  idx={idx}
                                  id={'popover-vehicle' + idx}
                                />
                              </td>
                              <td style={{ paddingLeft: '20px' }}>
                                <span>
                                  <OverlayTrigger placement="left" overlay={row.status === documentStatus.VALIDATED ? (tooltipValidate) : row.status === documentStatus.PENDING ? (tooltipPending) : row.status === documentStatus.REJECTED ? (tooltipRejected) : row.status === documentStatus.REMOVED ? (tooltipRemoved) : null}>
                                    <i id="documentStatus" className={'ico-' + documentStatusClass[translateDocumentStatus[row.status]]}/>
                                  </OverlayTrigger>
                                </span>
                              </td>
                              <td>{row.creationDate}</td>
                              <td>{row.validationDate}</td>
                              <td className="see-more">
                                <DossierListPopover
                                  t={t}
                                  half={(documents.length || 1) / (idx + 1)}
                                  idx={idx}
                                  id={'popover-dossier-list' + idx}
                                  alive={row.lives ? row.lives : row.isHistorical ? [] : this.returnArray(row.dossier)}
                                  stored={row.stored ? row.stored : row.isHistorical ? this.returnArray(row.dossier) : []}
                                />
                              </td>
                              { massiveDownloads && massiveDownloads.permission > permissions.hidden &&
                                <td>
                                  {
                                    <Field
                                      colSm={0}
                                      component={InputCheckbox}
                                      name={'downloadCheck' + row.documentId}
                                      inline
                                      customClass="checkbox-inline-search"
                                      onChange={() => this.addDocumentIdToArray(row.documentId)}
                                      checked= {this.state.documentsSelected.includes(row.documentId)}
                                    />
                                  }
                                </td>
                              }
                              <td key={idx} className="navigation">
                                {row.documentId && (
                                  <OverlayTrigger placement="left" overlay={navigationTooltip}>
                                    <a className="_Btn accept stk o-icn noLine"
                                      onClick={() => row.isHistorical === false ? (
                                        this.props.actions.openDocumentEditionModal(row.documentId,
                                          row.documentTypeId,
                                          row.documentParentTypeId,
                                          row.dossierId,
                                          row.entityId,
                                          row.vehicleId,
                                          row.organizedUnitId,
                                          null,
                                          () => fetchDocumentSearchList({
                                            items: documents, pages, count, ...this.props.dynamicFilters, page
                                          }),
                                          true,
                                          '',
                                          '', null, null, null, null,
                                          false)
                                      ) : (
                                        this.props.actions.openDocumentEditionModalHistorical(row.documentId, row.documentTypeId, row.documentParentTypeId, row.dossierId, row.entityId, row.vehicleId)
                                      )
                                      }>
                                      <i class="iDocIcon-open-in-new-black"></i>
                                    </a>
                                  </OverlayTrigger>
                                )}
                              </td>
                            </tr>
                          ))}
                        />
                      ) : (
                        <SimpleTablePage
                          columns={(massiveDownloads && massiveDownloads.permission > permissions.hidden) ? ([
                            <th key={0} data-field="documentType" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOCUMENT_TYPE`)} <i className={getIconClass('documentType', this.props.filters)}/></th>,
                            <th key={1} data-field="status" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.STATUS.TITLE`)} <i className={getIconClass('status', this.props.filters)}/></th>,
                            <th key={2} data-field="validationDate" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.VALIDATION_DATE`)} <i className={getIconClass('validationDate', this.props.filters)}/></th>,
                            <th key={3} data-field="creationDate" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.CREATION_DATE`)} <i className={getIconClass('creationDate', this.props.filters)}/></th>,
                            <th key={4}>{t(`${tKey}SELECT_DOWNLOAD`)}</th>,
                            <th key={5}></th>
                          ]) : ([
                            <th key={0} data-field="documentType" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOCUMENT_TYPE`)} <i className={getIconClass('documentType', this.props.filters)}/></th>,
                            <th key={1} data-field="status" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.STATUS.TITLE`)} <i className={getIconClass('status', this.props.filters)}/></th>,
                            <th key={2} data-field="validationDate" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.VALIDATION_DATE`)} <i className={getIconClass('validationDate', this.props.filters)}/></th>,
                            <th key={3} data-field="creationDate" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.CREATION_DATE`)} <i className={getIconClass('creationDate', this.props.filters)}/></th>,
                            <th key={4}></th>
                          ])}
                          rows={documents.map((row, idx) => (
                            <tr key={idx}>
                              <td>{row.documentType}</td>
                              <td style={{ paddingLeft: '20px' }}>
                                <span>
                                  <OverlayTrigger placement="right" overlay={row.status === documentStatus.VALIDATED ? (tooltipValidate) : row.status === documentStatus.PENDING ? (tooltipPending) : row.status === documentStatus.REJECTED ? (tooltipRejected) : row.status === documentStatus.REMOVED ? (tooltipRemoved) : null}>
                                    <i id="documentStatus" className={'ico-' + documentStatusClass[translateDocumentStatus[row.status]]}/>
                                  </OverlayTrigger>
                                </span>
                              </td>
                              <td>{row.validationDate}</td>
                              <td>{row.creationDate}</td>
                              {massiveDownloads && massiveDownloads.permission > permissions.hidden &&
                                <td>
                                  {
                                    <Field
                                      colSm={0}
                                      component={InputCheckbox}
                                      name={'downloadCheck' + row.documentId}
                                      inline
                                      customClass="checkbox-inline-search"
                                      onChange={() => this.addDocumentIdToArray(row.documentId)}
                                      checked= {this.state.documentsSelected.includes(row.documentId)}
                                    />
                                  }
                                </td>
                              }
                              <td key={idx} className="navigation">
                                {row.documentId && (
                                  <OverlayTrigger placement="left" overlay={navigationTooltip}>
                                    <a className="_Btn accept stk o-icn noLine"
                                      onClick={() => row.isHistorical === false ? (
                                        this.props.actions.openDocumentEditionModal(row.documentId,
                                          row.documentTypeId,
                                          row.documentParentTypeId,
                                          row.dossierId,
                                          row.entityId,
                                          row.vehicleId,
                                          row.organizedUnitId,
                                          null,
                                          () => fetchDocumentSearchList({
                                            items: documents, pages, count, ...this.props.dynamicFilters, page
                                          }),
                                          true,
                                          '',
                                          '', null, null, null, null,
                                          false)
                                      ) : (
                                        this.props.actions.openDocumentEditionModalHistorical(row.documentId, row.documentTypeId, row.documentParentTypeId, row.dossierId, row.entityId, row.vehicleId)
                                      )
                                      }>
                                      <i class="iDocIcon-open-in-new-black"></i>
                                    </a>
                                  </OverlayTrigger>
                                )}
                              </td>
                            </tr>
                          ))}
                        />
                      )
                    )}
                  </div>
                }
                <div className="search-footer">
                  {hasResults ? (
                    <IDocCarPagination
                      id="btn_pag_sales"
                      page={page}
                      pagesCount={pages}
                      totalRows= {count}
                      onSelectAction={(page) => fetchDocumentSearchList({ items: documents, pages, count, ...this.props.dynamicFilters, page })}/>
                  ) : <p className='no_results'>{t('GLOBAL.TABLES.NO_RESULTS')}</p>
                  }
                </div>
              </div>
            ])}
            {/* //////// Parte antigua, oculto por css //////////////////////////////////////////////////////////////////////////////////////// */}
            { isSearching && (
              <div className="result-document-search hide">
                <ul className="list-inline pull-left">
                  {
                    this.selectedFilters()
                  }
                </ul>
                <Clearfix/>
                <Button
                  onClick={(event) => this.onClickResetFilters(event)}
                  bsStyle="default"
                  bsSize="small"
                  className="edit-search-button pull-left"
                >{t(`${tKey}ACTIONS.MODIFY_SEARCH`)} <i className="ico-edit-white" style={{ marginLeft: '3px', fontSize: '14px' }}/>
                </Button>
                {massiveDownloads && massiveDownloads.permission > permissions.hidden && documents && documents.length > 0 &&
                    <Button
                      onClick={() => this.massiveDownloadDocuments()}
                      bsStyle="default"
                      bsSize="small"
                      className="edit-search-button pull-left"
                    >
                      {t(`${tKey}ACTIONS.DOWNLOAD_SELECTED`)}
                      <i className="glyphicon glyphicon-cloud-download" style={{ marginLeft: '3px', fontSize: '14px' }}/>
                    </Button>
                }
                {massiveDownloads && massiveDownloads.permission > permissions.hidden && documents && documents.length > 0 &&
                    <Checkbox
                      className="pull-right"
                      onChange={() => this.selectAllDocuments()}
                      checked={this.state.allSelected}>
                      {t(`${tKey}ACTIONS.SELECT_ALL`)}
                    </Checkbox>
                }
                { this.props.showPartialRecordsWarning &&
                    <span className="info-general-filer warning"><i className="iDocIcon-warning"></i> {t(`${tKey}RESULTS_EXCEED_MAX`)}</span>
                }
                { dynamicFilters.documentEntityTypeId === 1 && (
                  <SimpleTablePage
                    columns={(massiveDownloads && massiveDownloads.permission > permissions.hidden) ? ([
                      <th key={0} data-field="entity" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.CLIENT.TITLE`)} <i className={getIconClass('entity', this.props.filters)}/></th>,
                      <th key={1} data-field="documentType" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.CLIENT.DOCUMENT_TYPE`)} <i className={getIconClass('documentType', this.props.filters)}/></th>,
                      <th key={2} data-field="status" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.CLIENT.STATE`)} <i className={getIconClass('status', this.props.filters)}/></th>,
                      <th key={3} data-field="creationDate" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.CLIENT.CREATION_DATE`)} <i className={getIconClass('creationDate', this.props.filters)}/></th>,
                      <th key={4} data-field="validationDate" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.CLIENT.VALIDATION_DATE`)} <i className={getIconClass('validationDate', this.props.filters)}/></th>,
                      <th key={5}>{t(`${tKey}TABLE.CLIENT.AMOUNT_RELATED_DOSSIERS`)}</th>,
                      <th key={6}>{t(`${tKey}SELECT_DOWNLOAD`)}</th>,
                      <th key={7}></th>
                    ]) : ([
                      <th key={0} data-field="entity" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.CLIENT.TITLE`)} <i className={getIconClass('entity', this.props.filters)}/></th>,
                      <th key={1} data-field="documentType" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.CLIENT.DOCUMENT_TYPE`)} <i className={getIconClass('documentType', this.props.filters)}/></th>,
                      <th key={2} data-field="status" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.CLIENT.STATE`)} <i className={getIconClass('status', this.props.filters)}/></th>,
                      <th key={3} data-field="creationDate" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.CLIENT.CREATION_DATE`)} <i className={getIconClass('creationDate', this.props.filters)}/></th>,
                      <th key={4} data-field="validationDate" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.CLIENT.VALIDATION_DATE`)} <i className={getIconClass('validationDate', this.props.filters)}/></th>,
                      <th key={5}>{t(`${tKey}TABLE.CLIENT.AMOUNT_RELATED_DOSSIERS`)}</th>,
                      <th key={6}></th>
                    ])}
                    rows={documents.map((row, idx) => (
                      <tr key={idx}>
                        <td className="see-more">
                          <EntityPopover
                            t={t}
                            entity={row.entity}
                            half={(documents.length || 1) / (idx + 1)}
                            idx={idx}
                            id={'popover-entity' + idx}
                          />
                        </td>
                        <td>{row.documentType}</td>
                        <td style={{ paddingLeft: '20px' }}>
                          <span>
                            <OverlayTrigger placement="left" overlay={row.status === documentStatus.VALIDATED ? (tooltipValidate) : row.status === documentStatus.PENDING ? (tooltipPending) : row.status === documentStatus.REJECTED ? (tooltipRejected) : row.status === documentStatus.REMOVED ? (tooltipRemoved) : row.status === documentStatus.EXPIRED ? (tooltipExpired) : null}>
                              <i id="documentStatus" className={'ico-' + documentStatusClass[translateDocumentStatus[row.status]]}/>
                            </OverlayTrigger>
                          </span>
                        </td>
                        <td>{row.creationDate}</td>
                        <td>{row.validationDate}</td>
                        <td className="see-more">
                          <DossierListPopover
                            t={t}
                            half={(documents.length || 1) / (idx + 1)}
                            idx={idx}
                            id={'popover-dossier-list' + idx}
                            alive={row.lives}
                            stored={row.stored}
                          />
                        </td>
                        {massiveDownloads && massiveDownloads.permission > permissions.hidden &&
                <td>
                  {
                    <Field
                      colSm={2}
                      component={InputCheckbox}navigationTooltip
                      name={'downloadCheck' + row.documentId}
                      inline
                      customClass="checkbox-inline-search"
                      onChange={() => this.addDocumentIdToArray(row.documentId)}
                      checked= {this.state.documentsSelected.includes(row.documentId)}
                    />
                  }
                </td>}
                        <td key={idx} className="navigation">
                          {row.documentId && (
                            <OverlayTrigger placement="left" overlay={navigationTooltip}>
                              <Glyphicon
                                glyph="new-window"
                                onClick={() => row.isHistorical === false ? (
                                  this.props.actions.openDocumentEditionModal(row.documentId,
                                    row.documentTypeId,
                                    row.documentParentTypeId,
                                    row.dossierId,
                                    row.entityId,
                                    row.vehicleId,
                                    row.organizedUnitId,
                                    null,
                                    () => fetchDocumentSearchList({
                                      items: documents, pages, count, ...this.props.dynamicFilters, page
                                    }),
                                    true,
                                    '',
                                    '', null, null, null, null,
                                    false)
                                ) : (
                                  this.props.actions.openDocumentEditionModalHistorical(row.documentId, row.documentTypeId, row.documentParentTypeId, row.dossierId, row.entityId, row.vehicleId)
                                )
                                }
                              />
                            </OverlayTrigger>
                          )}
                        </td>
                      </tr>
                    ))
                    }
                  />
                )
                }
                {
                  dynamicFilters.documentEntityTypeId === 2 && (
                    <SimpleTablePage
                      columns={(massiveDownloads && massiveDownloads.permission > permissions.hidden) ? ([
                        <th key={0} data-field="vehicle" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.VEHICLE.TITLE`)} <i className={getIconClass('vehicle', this.props.filters)}/></th>,
                        <th key={1} data-field="documentType" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.VEHICLE.DOCUMENT_TYPE`)} <i className={getIconClass('documentType', this.props.filters)}/></th>,
                        <th key={2} data-field="status" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.VEHICLE.STATE`)} <i className={getIconClass('status', this.props.filters)}/></th>,
                        <th key={3} data-field="creationDate" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.VEHICLE.FIRST_REGISTRATION_DATE`)} <i className={getIconClass('creationDate', this.props.filters)}/></th>,
                        <th key={4} data-field="validationDate" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.VEHICLE.VALIDATION_DATE`)} <i className={getIconClass('validationDate', this.props.filters)}/></th>,
                        <th key={5}>{t(`${tKey}TABLE.VEHICLE.AMOUNT_RELATED_DOSSIERS`)}</th>,
                        <th key={6}>{t(`${tKey}SELECT_DOWNLOAD`)}</th>,
                        <th key={7}></th>
                      ]) : ([
                        <th key={0} data-field="vehicle" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.VEHICLE.TITLE`)} <i className={getIconClass('vehicle', this.props.filters)}/></th>,
                        <th key={1} data-field="documentType" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.VEHICLE.DOCUMENT_TYPE`)} <i className={getIconClass('documentType', this.props.filters)}/></th>,
                        <th key={2} data-field="status" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.VEHICLE.STATE`)} <i className={getIconClass('status', this.props.filters)}/></th>,
                        <th key={3} data-field="creationDate" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.VEHICLE.FIRST_REGISTRATION_DATE`)} <i className={getIconClass('creationDate', this.props.filters)}/></th>,
                        <th key={4} data-field="validationDate" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.VEHICLE.VALIDATION_DATE`)} <i className={getIconClass('validationDate', this.props.filters)}/></th>,
                        <th key={5}>{t(`${tKey}TABLE.VEHICLE.AMOUNT_RELATED_DOSSIERS`)}</th>,
                        <th key={6}></th>
                      ])}
                      rows={documents.map((row, idx) => (
                        <tr key={idx}>
                          <td className="see-more">
                            <VehiclePopover
                              t={t}
                              vehicle={row.vehicle}
                              half={(documents.length || 1) / (idx + 1)}
                              idx={idx}
                              id={'popover-vehicle' + idx}
                            />
                          </td>
                          <td>{row.documentType}</td>
                          <td style={{ paddingLeft: '20px' }}>
                            <span>
                              <OverlayTrigger placement="right" overlay={row.status === documentStatus.VALIDATED ? (tooltipValidate) : row.status === documentStatus.PENDING ? (tooltipPending) : row.status === documentStatus.REJECTED ? (tooltipRejected) : row.status === documentStatus.REMOVED ? (tooltipRemoved) : null}>
                                <i id="documentStatus" className={'ico-' + documentStatusClass[translateDocumentStatus[row.status]]}/>
                              </OverlayTrigger>
                            </span>
                          </td>
                          <td>{row.creationDate}</td>
                          <td>{row.validationDate}</td>
                          <td className="see-more">
                            <DossierListPopover
                              t={t}
                              half={(documents.length || 1) / (idx + 1)}
                              idx={idx}
                              id={'popover-dossier-list' + idx}
                              alive={row.lives}
                              stored={row.stored}
                            />
                          </td>
                          {massiveDownloads && massiveDownloads.permission > permissions.hidden &&
                <td>
                  {
                    <Field
                      colSm={2}
                      component={InputCheckbox}
                      name={'downloadCheck' + row.documentId}
                      inline
                      customClass="checkbox-inline-search"
                      onChange={() => this.addDocumentIdToArray(row.documentId)}
                      checked= {this.state.documentsSelected.includes(row.documentId)}
                    />
                  }
                </td>}
                          <td key={idx} className="navigation">
                            {row.documentId && (
                              <OverlayTrigger placement="left" overlay={navigationTooltip}>
                                <Glyphicon
                                  glyph="new-window"
                                  onClick={() =>
                                    row.isHistorical === false ? (
                                      this.props.actions.openDocumentEditionModal(row.documentId,
                                        row.documentTypeId,
                                        row.documentParentTypeId,
                                        row.dossierId,
                                        row.entityId,
                                        row.vehicleId,
                                        row.organizedUnitId,
                                        null,
                                        () => fetchDocumentSearchList({
                                          items: documents, pages, count, ...this.props.dynamicFilters, page
                                        }),
                                        true,
                                        '',
                                        '', null, null, null, null,
                                        false)
                                    ) : (
                                      this.props.actions.openDocumentEditionModalHistorical(row.documentId, row.documentTypeId, row.documentParentTypeId, row.dossierId, row.entityId, row.vehicleId)
                                    )
                                  }
                                />
                              </OverlayTrigger>
                            )}
                          </td>
                        </tr>
                      ))}
                    />
                  )
                }
                {
                  dynamicFilters.documentEntityTypeId === 3 && (
                    <SimpleTablePage
                      columns={(massiveDownloads && massiveDownloads.permission > permissions.hidden) ? ([
                        <th key={0} data-field="documentType" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.DOCUMENT_TYPE`)} <i className={getIconClass('documentType', this.props.filters)}/></th>,
                        <th key={1} data-field="dossier" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.DOSSIER`)} <i className={getIconClass('dossier', this.props.filters)}/></th>,
                        <th key={2} data-field="entity" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.ENTITY`)} <i className={getIconClass('entity', this.props.filters)}/></th>,
                        <th key={3} data-field="vehicle" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.VEHICLE`)} <i className={getIconClass('vehicle', this.props.filters)}/></th>,
                        <th key={4} data-field="status" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.STATE`)} <i className={getIconClass('status', this.props.filters)}/></th>,
                        <th key={5} data-field="creationDate" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.CREATION_DATE`)} <i className={getIconClass('creationDate', this.props.filters)}/></th>,
                        <th key={6} data-field="validationDate" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.VALIDATION_DATE`)} <i className={getIconClass('validationDate', this.props.filters)}/></th>,
                        <th key={7}>{t(`${tKey}SELECT_DOWNLOAD`)}</th>,
                        <th key={8}></th>
                      ]) : ([
                        <th key={0} data-field="documentType" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.DOCUMENT_TYPE`)} <i className={getIconClass('documentType', this.props.filters)}/></th>,
                        <th key={1} data-field="dossier" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.DOSSIER`)} <i className={getIconClass('dossier', this.props.filters)}/></th>,
                        <th key={2} data-field="entity" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.ENTITY`)} <i className={getIconClass('entity', this.props.filters)}/></th>,
                        <th key={3} data-field="vehicle" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.VEHICLE`)} <i className={getIconClass('vehicle', this.props.filters)}/></th>,
                        <th key={4} data-field="status" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.STATE`)} <i className={getIconClass('status', this.props.filters)}/></th>,
                        <th key={5} data-field="creationDate" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.CREATION_DATE`)} <i className={getIconClass('creationDate', this.props.filters)}/></th>,
                        <th key={6} data-field="validationDate" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.VALIDATION_DATE`)} <i className={getIconClass('validationDate', this.props.filters)}/></th>,
                        <th key={7}></th>
                      ])}
                      rows={documents.map((row, idx) => (
                        <tr key={idx}>
                          <td>{row.documentType}</td>
                          <td className="see-more">
                            <DossierPopover
                              t={t}
                              dossier={row.dossier}
                              half={(documents.length || 1) / (idx + 1)}
                              idx={idx}
                              id={'popover-dossier' + idx}
                            />
                          </td>
                          <td className="see-more">
                            <EntityPopover
                              t={t}
                              entity={row.entity}
                              half={(documents.length || 1) / (idx + 1)}
                              idx={idx}
                              id={'popover-entity' + idx}
                            />
                          </td>
                          <td className="see-more">
                            <VehiclePopover
                              t={t}
                              vehicle={row.vehicle}
                              half={(documents.length || 1) / (idx + 1)}
                              idx={idx}
                              id={'popover-vehicle' + idx}
                            />
                          </td>
                          <td style={{ paddingLeft: '20px' }}>
                            <span>
                              <OverlayTrigger placement="left" overlay={row.status === documentStatus.VALIDATED ? (tooltipValidate) : row.status === documentStatus.PENDING ? (tooltipPending) : row.status === documentStatus.REJECTED ? (tooltipRejected) : row.status === documentStatus.REMOVED ? (tooltipRemoved) : null}>
                                <i id="documentStatus" className={'ico-' + documentStatusClass[translateDocumentStatus[row.status]]}/>
                              </OverlayTrigger>
                            </span>
                          </td>
                          <td>{row.creationDate}</td>
                          <td>{row.validationDate}</td>
                          {massiveDownloads && massiveDownloads.permission > permissions.hidden &&
                <td>
                  {
                    <Field
                      colSm={2}
                      component={InputCheckbox}
                      name={'downloadCheck' + row.documentId}
                      inline
                      customClass="checkbox-inline-search"
                      onChange={() => this.addDocumentIdToArray(row.documentId)}
                      checked= {this.state.documentsSelected.includes(row.documentId)}
                    />
                  }
                </td>}
                          <td key={idx} className="navigation">
                            {row.documentId && (
                              <OverlayTrigger placement="left" overlay={navigationTooltip}>
                                <Glyphicon
                                  glyph="new-window"
                                  onClick={() =>
                                    row.isHistorical === false ? (
                                      this.props.actions.openDocumentEditionModal(
                                        row.documentId,
                                        row.documentTypeId,
                                        row.documentParentTypeId,
                                        row.dossierId,
                                        row.entityId,
                                        row.vehicleId,
                                        row.organizedUnitId,
                                        null,
                                        () => fetchDocumentSearchList({ items: documents, pages, count, ...this.props.dynamicFilters, page }),
                                        true,
                                        '',
                                        '', null, null, null, null, false, row.isMarked)
                                    ) : (
                                      this.props.actions.openDocumentEditionModalHistorical(row.documentId, row.documentTypeId, row.documentParentTypeId, row.dossierId, row.entityId, row.vehicleId)
                                    )
                                  }
                                />
                              </OverlayTrigger>
                            )}
                          </td>
                        </tr>
                      ))}
                    />
                  )
                }
                {
                  dynamicFilters.documentEntityTypeId !== 1 && dynamicFilters.documentEntityTypeId !== 2 && dynamicFilters.documentEntityTypeId !== 3 && (
                    dynamicFilters.documentTypeId ? (
                      <SimpleTablePage
                        columns={(massiveDownloads && massiveDownloads.permission > permissions.hidden) ? ([
                          <th key={0} data-field="documentType" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.DOCUMENT_TYPE`)} <i className={getIconClass('documentType', this.props.filters)}/></th>,
                          <th key={1} data-field="dossier" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.DOSSIER`)} <i className={getIconClass('dossier', this.props.filters)}/></th>,
                          <th key={2} data-field="entity" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.ENTITY`)} <i className={getIconClass('entity', this.props.filters)}/></th>,
                          <th key={3} data-field="vehicle" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.VEHICLE`)} <i className={getIconClass('vehicle', this.props.filters)}/></th>,
                          <th key={4} data-field="status" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.STATE`)} <i className={getIconClass('status', this.props.filters)}/></th>,
                          <th key={5} data-field="creationDate" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.CREATION_DATE`)} <i className={getIconClass('creationDate', this.props.filters)}/></th>,
                          <th key={6} data-field="validationDate" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.VALIDATION_DATE`)} <i className={getIconClass('validationDate', this.props.filters)}/></th>,
                          <th key={7}>{t(`${tKey}TABLE.CLIENT.AMOUNT_RELATED_DOSSIERS`)}</th>,
                          <th key={8}>{t(`${tKey}SELECT_DOWNLOAD`)}</th>,
                          <th key={9}></th>
                        ]) : ([
                          <th key={0} data-field="documentType" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.DOCUMENT_TYPE`)} <i className={getIconClass('documentType', this.props.filters)}/></th>,
                          <th key={1} data-field="dossier" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.DOSSIER`)} <i className={getIconClass('dossier', this.props.filters)}/></th>,
                          <th key={2} data-field="entity" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.ENTITY`)} <i className={getIconClass('entity', this.props.filters)}/></th>,
                          <th key={3} data-field="vehicle" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.VEHICLE`)} <i className={getIconClass('vehicle', this.props.filters)}/></th>,
                          <th key={4} data-field="status" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.STATE`)} <i className={getIconClass('status', this.props.filters)}/></th>,
                          <th key={5} data-field="creationDate" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.CREATION_DATE`)} <i className={getIconClass('creationDate', this.props.filters)}/></th>,
                          <th key={6} data-field="validationDate" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.VALIDATION_DATE`)} <i className={getIconClass('validationDate', this.props.filters)}/></th>,
                          <th key={7}>{t(`${tKey}TABLE.CLIENT.AMOUNT_RELATED_DOSSIERS`)}</th>,
                          <th key={8}></th>
                        ])}
                        rows={documents.map((row, idx) => (
                          <tr key={idx}>
                            <td>{row.documentType}</td>
                            <td className="see-more">
                              <DossierPopover
                                t={t}
                                dossier={row.dossier}
                                half={(documents.length || 1) / (idx + 1)}
                                idx={idx}
                                id={'popover-dossier' + idx}
                              />
                            </td>
                            <td className="see-more">
                              <EntityPopover
                                t={t}
                                entity={row.entity}
                                half={(documents.length || 1) / (idx + 1)}
                                idx={idx}
                                id={'popover-entity' + idx}
                              />
                            </td>
                            <td className="see-more">
                              <VehiclePopover
                                t={t}
                                vehicle={row.vehicle}
                                half={(documents.length || 1) / (idx + 1)}
                                idx={idx}
                                id={'popover-vehicle' + idx}
                              />
                            </td>
                            <td style={{ paddingLeft: '20px' }}>
                              <span>
                                <OverlayTrigger placement="left" overlay={row.status === documentStatus.VALIDATED ? (tooltipValidate) : row.status === documentStatus.PENDING ? (tooltipPending) : row.status === documentStatus.REJECTED ? (tooltipRejected) : row.status === documentStatus.REMOVED ? (tooltipRemoved) : null}>
                                  <i id="documentStatus" className={'ico-' + documentStatusClass[translateDocumentStatus[row.status]]}/>
                                </OverlayTrigger>
                              </span>
                            </td>
                            <td>{row.creationDate}</td>
                            <td>{row.validationDate}</td>
                            <td className="see-more">
                              <DossierListPopover
                                t={t}
                                half={(documents.length || 1) / (idx + 1)}
                                idx={idx}
                                id={'popover-dossier-list' + idx}
                                alive={row.lives ? row.lives : row.isHistorical ? [] : this.returnArray(row.dossier)}
                                stored={row.stored ? row.stored : row.isHistorical ? this.returnArray(row.dossier) : []}
                              />
                            </td>
                            {massiveDownloads && massiveDownloads.permission > permissions.hidden &&
                  <td>
                    {
                      <Field
                        colSm={0}
                        component={InputCheckbox}
                        name={'downloadCheck' + row.documentId}
                        inline
                        customClass="checkbox-inline-search"
                        onChange={() => this.addDocumentIdToArray(row.documentId)}
                        checked= {this.state.documentsSelected.includes(row.documentId)}
                      />
                    }
                  </td>}
                            <td key={idx} className="navigation">
                              {row.documentId && (
                                <OverlayTrigger placement="left" overlay={navigationTooltip}>
                                  <Glyphicon
                                    glyph="new-window"
                                    onClick={() =>
                                      row.isHistorical === false ? (
                                        this.props.actions.openDocumentEditionModal(row.documentId,
                                          row.documentTypeId,
                                          row.documentParentTypeId,
                                          row.dossierId,
                                          row.entityId,
                                          row.vehicleId,
                                          row.organizedUnitId,
                                          null,
                                          () => fetchDocumentSearchList({
                                            items: documents, pages, count, ...this.props.dynamicFilters, page
                                          }),
                                          true,
                                          '',
                                          '',
                                          null, null, null, null,
                                          false)
                                      ) : (
                                        this.props.actions.openDocumentEditionModalHistorical(row.documentId, row.documentTypeId, row.documentParentTypeId, row.dossierId, row.entityId, row.vehicleId)
                                      )
                                    }
                                  />
                                </OverlayTrigger>
                              )}
                            </td>
                          </tr>
                        ))}
                      />
                    ) : (
                      <SimpleTablePage
                        columns={(massiveDownloads && massiveDownloads.permission > permissions.hidden) ? ([
                          <th key={0} data-field="documentType" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOCUMENT_TYPE`)} <i className={getIconClass('documentType', this.props.filters)}/></th>,
                          <th key={1} data-field="status" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.STATUS.TITLE`)} <i className={getIconClass('status', this.props.filters)}/></th>,
                          <th key={2} data-field="validationDate" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.VALIDATION_DATE`)} <i className={getIconClass('validationDate', this.props.filters)}/></th>,
                          <th key={3} data-field="creationDate" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.CREATION_DATE`)} <i className={getIconClass('creationDate', this.props.filters)}/></th>,
                          <th key={4}>{t(`${tKey}SELECT_DOWNLOAD`)}</th>,
                          <th key={5}></th>
                        ]) : ([
                          <th key={0} data-field="documentType" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOCUMENT_TYPE`)} <i className={getIconClass('documentType', this.props.filters)}/></th>,
                          <th key={1} data-field="status" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.STATUS.TITLE`)} <i className={getIconClass('status', this.props.filters)}/></th>,
                          <th key={2} data-field="validationDate" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.VALIDATION_DATE`)} <i className={getIconClass('validationDate', this.props.filters)}/></th>,
                          <th key={3} data-field="creationDate" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.CREATION_DATE`)} <i className={getIconClass('creationDate', this.props.filters)}/></th>,
                          <th key={4}></th>
                        ])}
                        rows={documents.map((row, idx) => (
                          <tr key={idx}>
                            <td>{row.documentType}</td>
                            <td style={{ paddingLeft: '20px' }}>
                              <span>
                                <OverlayTrigger placement="right" overlay={row.status === documentStatus.VALIDATED ? (tooltipValidate) : row.status === documentStatus.PENDING ? (tooltipPending) : row.status === documentStatus.REJECTED ? (tooltipRejected) : row.status === documentStatus.REMOVED ? (tooltipRemoved) : null}>
                                  <i id="documentStatus" className={'ico-' + documentStatusClass[translateDocumentStatus[row.status]]}/>
                                </OverlayTrigger>
                              </span>
                            </td>
                            <td>{row.validationDate}</td>
                            <td>{row.creationDate}</td>
                            {massiveDownloads && massiveDownloads.permission > permissions.hidden &&
                  <td>
                    {
                      <Field
                        colSm={0}
                        component={InputCheckbox}
                        name={'downloadCheck' + row.documentId}
                        inline
                        customClass="checkbox-inline-search"
                        onChange={() => this.addDocumentIdToArray(row.documentId)}
                        checked= {this.state.documentsSelected.includes(row.documentId)}
                      />
                    }
                  </td>}
                            <td key={idx} className="navigation">
                              {row.documentId && (
                                <OverlayTrigger placement="left" overlay={navigationTooltip}>
                                  <Glyphicon
                                    glyph="new-window"
                                    onClick={() =>
                                      row.isHistorical === false ? (
                                        this.props.actions.openDocumentEditionModal(row.documentId,
                                          row.documentTypeId,
                                          row.documentParentTypeId,
                                          row.dossierId,
                                          row.entityId,
                                          row.vehicleId,
                                          row.organizedUnitId,
                                          null,
                                          () => fetchDocumentSearchList({ items: documents, pages, count, ...this.props.dynamicFilters, page }),
                                          true,
                                          '',
                                          '', null, null, null, null,
                                          false)
                                      ) : (
                                        this.props.actions.openDocumentEditionModalHistorical(row.documentId, row.documentTypeId, row.documentParentTypeId, row.dossierId, row.entityId, row.vehicleId)
                                      )
                                    }
                                  />
                                </OverlayTrigger>
                              )}
                            </td>
                          </tr>
                        ))}
                      />
                    )
                  )
                }
                <div className="search-footer">
                  <IDocCarPagination
                    id="btn_pag_sales"
                    page={page}
                    pagesCount={pages}
                    totalRows= {count}
                    onSelectAction={(page) => fetchDocumentSearchList({ items: documents, pages, count, ...this.props.dynamicFilters, page })}
                  />
                </div>
              </div>
            )}
            {/* //////// Parte antigua, oculto por css //////////////////////////////////////////////////////////////////////////////////////// */}
          </div>
        </div>
      </div>
    )
  }
}

const validate = (values, { t }, props) => {
  const error = {}
  if (values && values.creationDateFrom && values.creationDateTo) {
    let dateTo = convertStringToDate(values.creationDateTo)
    let dateFrom = convertStringToDate(values.creationDateFrom)
    if (dateFrom > dateTo) {
      error.creationDateTo = t('GLOBAL.FORMS.DATE_TO_NOT_LESS_THAN_DATE_FROM')
    }
  }
  if (values && values.validationDateFrom && values.validationDateTo) {
    let dateTo = convertStringToDate(values.validationDateTo)
    let dateFrom = convertStringToDate(values.validationDateFrom)
    if (dateFrom > dateTo) {
      error.validationDateTo = t('GLOBAL.FORMS.DATE_TO_NOT_LESS_THAN_DATE_FROM')
    }
  }

  if (values && values.orderDateFrom && values.orderDateTo) {
    let dateTo = convertStringToDate(values.orderDateTo)
    let dateFrom = convertStringToDate(values.orderDateFrom)
    if (dateFrom > dateTo) {
      error.orderDateTo = t('GLOBAL.FORMS.DATE_TO_NOT_LESS_THAN_DATE_FROM')
    }
  }

  if (values && values.creationDossierDateFrom && values.creationDossierDateTo) {
    let dateTo = convertStringToDate(values.creationDossierDateTo)
    let dateFrom = convertStringToDate(values.creationDossierDateFrom)
    if (dateFrom > dateTo) {
      error.creationDossierDateTo = t('GLOBAL.FORMS.DATE_TO_NOT_LESS_THAN_DATE_FROM')
    }
  }

  if (values && values.licensePlateDateFrom && values.licensePlateDateTo) {
    let dateTo = convertStringToDate(values.licensePlateDateTo)
    let dateFrom = convertStringToDate(values.licensePlateDateFrom)
    if (dateFrom > dateTo) {
      error.licensePlateDateTo = t('GLOBAL.FORMS.DATE_TO_NOT_LESS_THAN_DATE_FROM')
    }
  }
  return error
}

export default reduxForm({
  form: 'documentalSearchForm',
  destroyOnUnmount: false,
  validate
})(DocumentalSearchForm)
