import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import DestinationCraneMaster from '../../../components/masters/destinationCrane/DestinationCraneMaster'
import { translate } from 'react-polyglot'
import { fetchDestinationCraneMaster, fetchDestinationCraneItem, deleteDestinationCraneItem } from '../../../actions/masters/masters'
import { fetchOrganizedUnitsTree } from '../../../actions/combos/combos'

export function mapStateToProps (state) {
  return {
    ...state.destinationCraneMaster,
    combos: {
      UOTreeCombo: state.combos.UOTreeCombo
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchDestinationCraneMaster,
      fetchOrganizedUnitsTree,
      fetchDestinationCraneItem,
      deleteDestinationCraneItem
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(DestinationCraneMaster))
