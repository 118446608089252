import React, { PureComponent } from 'react'
import { Panel } from 'react-bootstrap'
import { Field } from 'redux-form'
import { permissions } from '../../../../constants/backendIds'
import { getFieldsPermisions } from '../../../../util/permisionFunctions'
import { compareObjects } from '../../../../util/utils'
import InputCheckbox from '../../../../_v2/components/commons/form/InputCheckBox'
import InputDatePicker from '../../../../_v2/components/commons/form/InputDatePicker'
import ComponentTitle from '../../../../_v2/containers/dossiers/commons/ComponentTitlePage'

export default class PriceChange extends PureComponent {
  constructor () {
    super()
    this.state = {
      open: false,
      fieldsConfiguration: {
        updatedPrice: null,
        lastPriceChangeDate: null
      }
    }
  }

  initializePermisions (props) {
    const fieldKeys = Object.keys(this.state.fieldsConfiguration)
    const newState = getFieldsPermisions(props.componentConfiguration.fieldsConfiguration, fieldKeys, props.componentConfiguration.permission)
    this.setState({ fieldsConfiguration: newState.fieldsConfiguration })
  }

  toggleComponent () {
    if (this.state.open) {
      this.props.actions.setActiveSection(false)
    }
    this.setState({ open: !this.state.open })
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (this.props.dossier && this.props.dossier.dossierId) {
      if (!compareObjects(prevProps.componentConfiguration || {}, this.props.componentConfiguration, true)) {
        this.initializePermisions(this.props)
      }
      if (!prevProps.open && this.props.open) {
        this.setState({ open: true })
      }
    }
    return prevProps
  }

  componentDidUpdate () {
    // don't remove use for getsnapshot
  }

  componentDidMount () {
    this.initializePermisions(this.props)
  }

  render () {
    const {
      t, readOnlyFields, dossier: { priceChange }
    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.PRICE_CHANGE.'

    return (
      <div className="dossier-Component">
        <div className="header-panel" onClick={() => this.toggleComponent()}>
          <ComponentTitle
            componentCode="PRICE_CHANGE"
            componentOpen={this.state.open}
            // extraInfo={'info extra'}
            // extraData={<a className="_Btn accept">texto</a>}
          />
        </div>
        <Panel expanded={this.state.open} onToggle={() => {}}>
          <Panel.Collapse>
            <div className='fieldsContainer'>
              {this.state.fieldsConfiguration.updatedPrice && this.state.fieldsConfiguration.updatedPrice.permission !== permissions.hidden && (
                <Field
                  component={InputCheckbox}
                  colWidht={100} // 25, 33, 50, 75, 100 default=25
                  id="updatedPrice"
                  name="priceChange.updatedPrice"
                  placeholder={t(`${tKey}UPDATED_PRICE_INIT`) + priceChange.thresholdDays + t(`${tKey}UPDATED_PRICE_FINAL`)}
                  disabled={readOnlyFields || this.state.fieldsConfiguration.updatedPrice.permission === permissions.readOnly }
                />
              )}
              {this.state.fieldsConfiguration.lastPriceChangeDate && this.state.fieldsConfiguration.lastPriceChangeDate.permission !== permissions.hidden && (

                <Field
                  component={InputDatePicker}
                  colWidht={33} // 25, 33, 50, 75, 100 default=25
                  id="lastPriceChangeDate"
                  name="priceChange.lastPriceChangeDate"
                  controlLabel={t(`${tKey}LAST_PRICE_CHANGE`)}
                  placeholder={t(`${tKey}LAST_PRICE_CHANGE`)}
                  disabled={readOnlyFields || this.state.fieldsConfiguration.lastPriceChangeDate.permission === permissions.readOnly }
                  customClass={readOnlyFields || this.state.fieldsConfiguration.lastPriceChangeDate.permission === permissions.readOnly ? 'disabled' : ''}
                />
              )}
            </div>
          </Panel.Collapse>
        </Panel>
      </div>
    )
  }
}
