import React, { PureComponent } from 'react'
import { Modal, Button } from 'react-bootstrap'
import QRCode from 'qrcode.react'

class ModalQrLogin extends PureComponent {
  constructor () {
    super()
    this.state = {
      interval: null
    }
  }

  generateQrContent () {
    return this.props.qrContentEncrypted || ''
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (this.props && nextProps && this.props.showModal !== undefined && nextProps.showModal && this.props.showModal !== nextProps.showModal) {
      this.props.encryptQrContent()
    }
  }

  render () {
    const {
      t, showModal
    } = this.props
    const tKey = 'HEADER.QR_MODAL.'

    return (
      <Modal className="custom-modal qr_modal" show={showModal} onHide={this.props.hideModal}>
        <Modal.Header closeButton>
          <Modal.Title className={'title center'}>
            {t(`${tKey}TITLE`)}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <QRCode
            className={'qr_code center'}
            value={this.generateQrContent()}
            size={256}
            bgColor={'#ffffff'}
            fgColor={'#000000'}
            level={'L'}
            includeMargin={false}
            renderAs={'svg'}
            imageSettings={{
              src: 'images/poweredby.png',
              x: null,
              y: null,
              height: 25,
              width: 68,
              excavate: true
            }}
          />
        </Modal.Body>
        <Modal.Footer className='flexInLine al-C'>
          <Button onClick={this.props.hideModal} className={'_Btn accept'}>
            {t(`${tKey}CLOSE`)}
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}
export default ModalQrLogin
