import { Field, reduxForm } from 'redux-form'
import React, { PureComponent } from 'react'
import InputCheckboxSlider from '../commons/form/InputCheckboxSlider'
import { Button, Modal, Row, Form } from 'react-bootstrap'
import InputSelect from '../commons/form/InputSelect'

class EventSubscriptionModal extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      organizedUnitIds: [],
      notificationEventId: null
    }
    this.onHandleOrganizedUnitsChange = this.onHandleOrganizedUnitsChange.bind(this)
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (prevProps.showModal !== this.props.showModal && this.props.showModal) {
      const subscription = this.props.eventSubscription
      const OusArray = (subscription?.organizedUnitIds?.split(',')?.map(Number)) ?? []
      this.props.initialize({
        ...subscription,
        languageId: (subscription?.languageId ?? this.props.defaultLanguageId),
        active: subscription?.active ?? true,
        organizedUnitIds: OusArray
      })
      this.setState({ organizedUnitIds: subscription?.organizedUnitIds, notificationEventId: subscription?.notificationEventId })
      this.props.actions.fetchEventsCombo(null, OusArray)
    }
    return null
  }

  componentDidUpdate (prevProps, prevState) {
  }

  saveEventSubscription (values) {
    new Promise((resolve, reject) => {
      return this.props.actions.saveEventSubscription(this.props.eventSubscription?.subscriptionId, values, null, resolve, reject)
    }).then(() => {
      this.props.actions.closeModal()
    })
  }

  getComboValuesAsArray (organizedUnitIds) {
    if (!organizedUnitIds) { return [] }
    return organizedUnitIds.split(',')?.map(Number)
  }

  findChilds (ouTreeCombo, ouId) {
    if (!ouId) return
    let childrenTree = []
    if (Array.isArray(ouTreeCombo)) {
      ouTreeCombo.forEach((ou) => {
        if (childrenTree.length === 0) {
          if (Number.parseInt(ou.value) === Number.parseInt(ouId)) {
            childrenTree.push(ou)
          } else if (ou.children) {
            const value = this.findChilds(ou.children, ouId)
            if (value.length > 0) {
              childrenTree = value
            }
          }
        }
      })
    }
    return childrenTree
  }

  handleUOTreeComboOrganizedUnitChange (values) {
    const previousValues = this.getComboValuesAsArray(this.state.organizedUnitIds)
    const currentValues = this.getComboValuesAsArray(values)
    const checked = previousValues.length < currentValues.length
    let value = currentValues.at(-1)
    if (!checked) {
      value = previousValues?.find((x) => !currentValues.includes(x))
    }
    const branch = this.findChilds(this.props.combos.UOTreeCombo, value)
    let organizedUnits = currentValues
    if (branch) {
      const childrenIds = branch.flatMap(flatten)
      childrenIds?.shift()
      if (checked) {
        organizedUnits = currentValues.concat(childrenIds)
      } else {
        organizedUnits = currentValues.filter((x) => !childrenIds.includes(x))
      }
    }
    const organizedUnitsJoinString = organizedUnits.join(',')
    this.setState({ organizedUnitIds: organizedUnitsJoinString })
    this.props.change('organizedUnitIds', organizedUnitsJoinString ?? '')
  }

  getFilteredComo () {
    return this.props.combos.events?.filter(x => x.notificationEventId === this.state.notificationEventId ||
      !this.props.subscribedEvents?.some(y => y === x.notificationEventId)) ?? []
  }

  onHandleOrganizedUnitsChange (value) {
    this.setState({ organizedUnitIds: value })
    this.props.actions.fetchEventsCombo(null, value?.map(Number))
  }

  render () {
    const tKey = 'PROFILE.NOTIFICATIONS_TAB.EVENT_MODAL.'
    const {
      t, handleSubmit, showModal,
      combos: { lowestOrganizedUnitsCombo, languages },
      actions: { closeModal }
    } = this.props
    return (
      <Modal show={showModal} onHide={closeModal} className="eventSubscriptionModal" backdrop={'static'} >
        <Modal.Header closeButton>
          <Modal.Title>{t(`${tKey}TITLE`)}</Modal.Title>
        </Modal.Header>
        <Form autoComplete="off" onSubmit={handleSubmit(this.saveEventSubscription.bind(this))}>
          <Modal.Body>
            <Row className='flexRow'>
              <Field
                name="organizedUnitIds"
                id="organizedUnitIds"
                placeholder={t(`${tKey}OU`)}
                controlLabel={t(`${tKey}OU`)}
                multi={true}
                colSm={12}
                onInputChange={this.onHandleOrganizedUnitsChange}
                component={InputSelect}
                value = {this.state.organizedUnitIds}
                options={lowestOrganizedUnitsCombo}
                valueKey="id"
                labelKey="value"
              />
            </Row>
            <Row className='flexRow'>
              <Field
                id="notificationEventId"
                name="notificationEventId"
                placeholder={t(`${tKey}EVENT`)}
                controlLabel={t(tKey + 'EVENT')}
                component={InputSelect}
                options={this.getFilteredComo()}
                valueKey="notificationEventId"
                labelKey="name"
                colSm={12}
                disabled= {(this.state.organizedUnitIds?.length ?? 0) === 0}
              />
            </Row>
            <Row className='flexRow'>
              <Field
                id="languageId"
                name="languageId"
                placeholder={t(tKey + 'LANGUAGE')}
                controlLabel={t(tKey + 'LANGUAGE')}
                component={InputSelect}
                options={languages}
                valueKey="languageId"
                labelKey="description"
                colSm={12}
              />
            </Row>
            <Row className='flexRow'>
              <Field
                id="active"
                name="active"
                placeholder={t(`${tKey}ENABLE_ACTION`)}
                component={InputCheckboxSlider}
                colSm={12}
              />
            </Row>
          </Modal.Body>
          <Modal.Footer className='flexInLine al-C'>
            <Button type="submit" className="_Btn accept" disabled= {this.props.pristine}>{t('GLOBAL.BUTTONS.OK')}</Button>
            <Button id="cancelBtn" name="cancelBtn" onClick={closeModal} className="_Btn cancel">{t('GLOBAL.BUTTONS.CANCEL')}</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    )
  }
}

const validate = (values, props) => {
  const error = { }

  if (!values.organizedUnitIds || values.organizedUnitIds.length === 0) {
    error.organizedUnitIds = props.t('GLOBAL.FORMS.REQUIRED')
  }
  if (!values.notificationEventId) {
    error.notificationEventId = props.t('GLOBAL.FORMS.REQUIRED')
  }
  if (!values.languageId) {
    error.languageId = props.t('GLOBAL.FORMS.REQUIRED')
  }

  return Object.keys(error).length > 0 ? error : {}
}

const flatten = (item) => {
  let result = []
  result.push(item.value)
  const children = item.children
  if (children && Array.isArray(children)) {
    const resultAux = children.flatMap(flatten)
    result = result.concat(resultAux)
  }

  return [...new Set(result)]
}

export default reduxForm({
  form: 'eventSubscriptionModal',
  destroyOnUnmount: true,
  validate
})(EventSubscriptionModal)
