import React, { PureComponent } from 'react'
import { reduxForm, Field } from 'redux-form'
import { Row, Col, Table, Glyphicon, Button, Tooltip, OverlayTrigger } from 'react-bootstrap'
import FinancingModal from '../../../containers/masters/Financing/FinancingModalPage'
import IDocCarPagination from '../../commons/iDocCarPagination'
import InputText from '../../commons/form/InputText'
import SimpleTreeSelect from '../../commons/simpleForm/SimpleTreeSelect'
import FinancingMoldalUo from '../../../containers/masters/Financing/FinancingMasterModalUoPage'
import FinancingMoldalProduct from '../../../containers/masters/Financing/FinancingMasterModalProductPage'

class Financing extends PureComponent {
  constructor () {
    super()
    this.state = {
      filter: {
        searchUO: null,
        searchName: null,
        searchDescription: null
      }
    }
  }

  getSortIcon (column = '') {
    switch (this.props.filter.orderBy) {
      case '+' + column:
        return 'ico-sort-selected-asc'
      case '-' + column:
        return 'ico-sort-selected-desc'
      default:
        return 'ico-sort'
    }
  }

  changeOrder (field) {
    switch (this.props.filter.orderBy) {
      case `+${field}`:
        this.props.actions.fetchFinancingList({ ...this.state.filter, ...this.props.filter, orderBy: `-${field}` })
        break
      default:
        this.props.actions.fetchFinancingList({ ...this.state.filter, ...this.props.filter, orderBy: `+${field}` })
        break
    }
  }

  handleOrganizedUnitChange (searchUO) {
    if (this.state.filter.searchUO !== searchUO) {
      this.setState({ filter: { ...this.state.filter, searchUO } })
    }
  }

  handleNameChange (searchName) {
    if (this.state.filter.searchName !== searchName) {
      this.setState({ filter: { ...this.state.filter, searchName } })
    }
  }

  handleDescriptionChange (searchDescription) {
    if (this.state.filter.searchDescription !== searchDescription) {
      this.setState({ filter: { ...this.state.filter, searchDescription } })
    }
  }

  searchFinancing () {
    this.props.actions.fetchFinancingList({ ...this.props.filter, ...this.state.filter, page: 1 })
  }

  resetFilters () {
    this.setState({
      filter: {
        searchUO: '',
        searchName: '',
        searchDescription: ''
      }
    }, () => { this.searchFinancing() })
  }

  render () {
    const {
      t, financingList, pagesCount, financingCount, filter,
      filter: { page },
      combos: { UOTreeCombo },
      actions: { openEditFinancingModal, openAddFinancingModal, fetchFinancingList, deleteFinancing, recoverFinancing, openEditFinancingModalUo, openEditFinancingModalProduct }
    } = this.props
    const tKey = 'MASTERS.'
    const editTooltip = (<Tooltip id="editTooltip">{t(`${tKey}EDIT`)}</Tooltip>)
    const deleteTooltip = (<Tooltip id="deleteTooltip">{t(`${tKey}DELETE`)}</Tooltip>)
    const recoverTooltip = (<Tooltip id="recoverTooltip">{t(`${tKey}RECOVER`)}</Tooltip>)
    const editUOTooltip = (<Tooltip id="editUOTooltip">{t(`${tKey}FINANCING_MASTERS.EDIT_ORGANIZED_UNIT`)}</Tooltip>)
    const editProductTooltip = (<Tooltip id="editProductTooltip">{t(`${tKey}FINANCING_MASTERS.EDIT_PRODUCT_TYPE`)}</Tooltip>)
    const hasFilters = (this.state.filter.searchUO || this.state.filter.searchName || this.state.filter.searchDescription)
    return (
      <div className="admin-wrapper">
        <FinancingModal/>
        <FinancingMoldalUo />
        <FinancingMoldalProduct/>
        <Row className="section-users-header">
          <Col sm={12}>
            <div className="header-panel">
              <h4>{t(`${tKey}FINANCING`)}</h4>
            </div>
          </Col>
        </Row>
        <Row>
          <div className="search-form">
            <span className="title-search"><i className="ico-search" /> {t(`${tKey}FINANCING_MASTERS.SEARCH_TITLE`)}</span>
            <Row>
              <SimpleTreeSelect
                colSm={2}
                controlLabel={t(`${tKey}FINANCING_MASTERS.ORGANIZED_UNIT`)}
                placeholder={t(`${tKey}FINANCING_MASTERS.ORGANIZED_UNIT`)}
                options={UOTreeCombo}
                searchPlaceholder={t(`${tKey}FINANCING_MASTERS.ORGANIZED_UNIT`)}
                value={this.state.filter.searchUO ? this.state.filter.searchUO.toString() : null}
                onInputChange={(value) => this.handleOrganizedUnitChange(value)}
              />
              <Field
                name="filters.name"
                colSm={2}
                controlLabel={t(`${tKey}FINANCING_MASTERS.NAME`)}
                placeholder={t(`${tKey}FINANCING_MASTERS.NAME`)}
                component={InputText}
                onInputChange={(value) => this.handleNameChange(value)}
              />
              <Field
                name="filters.description"
                colSm={2}
                controlLabel={t(`${tKey}DESCRIPTION`)}
                placeholder={t(`${tKey}DESCRIPTION`)}
                component={InputText}
                onInputChange={(value) => this.handleDescriptionChange(value)}
              />
              <Col sm={5} className="btn-wrapper">
                <Button
                  className={'btn-white btn-icon btn-input btn-white search-button financing-master-button'}
                  onClick={() => this.searchFinancing()}>
                  <i className="ico-search" />
                  {t(`${tKey}SEARCH_BTN`)}
                </Button>
                <Button
                  className={'btn-icon btn-input' + (hasFilters ? ' btn-standard' : ' btn-cancel')}
                  onClick={() => {
                    this.props.initialize({})
                    this.resetFilters()
                  }}
                >
                  <i className="ico-cancel" />
                  {t(`${tKey}RESET_BUTTON`)}
                </Button>
              </Col>
            </Row>
          </div>
        </Row>
        <Col sm={12} className="col-row  section-users-body">
          <Table className="editable-table" hover responsive>
            <thead>
              <tr>
                <th onClick={() => { this.changeOrder('name') }}>
                  {t(`${tKey}FINANCING_MASTERS.NAME`)}
                  <i className={this.getSortIcon('name')} />
                </th>
                <th onClick={() => { this.changeOrder('description') }}>
                  {t(`${tKey}DESCRIPTION`)}
                  <i className={this.getSortIcon('description')} />
                </th>
                <th className="actions-fiancing-master" >{t('DOSSIER_COMPONENTS.ACCESSORIES.ACTIONS')}</th>
              </tr>
            </thead>
            <tbody>
              {financingList && financingList.map((financing, idx) => {
                return (
                  <tr key={idx} className={(financing.isDeleted ? 'is-deleted' : '')}>
                    <td>{financing.name}</td>
                    <td>{financing.description}</td>
                    <td>
                      {!financing.isDeleted ? (
                        <div>
                          <a onClick={() => openEditFinancingModalUo(financing)} className="uo-ico-financing-master">
                            <OverlayTrigger placement="left" overlay={editUOTooltip}>
                              <i className="ico-uo"/>
                            </OverlayTrigger>
                          </a>
                          <a onClick={() => openEditFinancingModalProduct(financing)}>
                            <OverlayTrigger placement="left" overlay={editProductTooltip}>
                              <i className="glyphicon glyphicon-th" />
                            </OverlayTrigger>
                          </a>
                          <a onClick={() => openEditFinancingModal(financing.financingCompanyId)}>
                            <OverlayTrigger placement="left" overlay={editTooltip}>
                              <i className="ico-edit-white"/>
                            </OverlayTrigger>
                          </a>
                          <a onClick={() => deleteFinancing(financing.financingCompanyId)}>
                            <OverlayTrigger placement="left" overlay={deleteTooltip}>
                              <i className="ico-trash"/>
                            </OverlayTrigger>
                          </a>
                        </div>
                      ) : (
                        <a onClick={() => recoverFinancing(financing.financingCompanyId, { ...filter, ...this.state.filter })}>
                          <OverlayTrigger placement="left" overlay={recoverTooltip}>
                            <Glyphicon glyph="repeat"/>
                          </OverlayTrigger>
                        </a>
                      )}
                    </td>
                  </tr>)
              })
              }
              {(financingList === null || financingList === undefined || financingList.length === 0) &&
            <tr>
              <td colSpan={4}><h2>{t(`${tKey}FINANCING_MASTERS.RESULTS_NOT_FOUND`)}</h2></td>
            </tr>}
              <tr className="button-table-wrapper">
                <td>
                  <Button
                    className="btn-editable-table btn-standard"
                    onClick={() => openAddFinancingModal()}>
                  + {t('MASTERS.ADD')}
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>
          <div className="section-users-footer">
            <nav key={1} className="text-center">
              <IDocCarPagination
                id="btn_pag_sales"
                page={page}
                pagesCount={pagesCount}
                totalRows={financingCount}
                onSelectAction={(value) => fetchFinancingList({ ...filter, ...this.state.filter, page: value })}
              />
            </nav>
          </div>
        </Col>
      </div>
    )
  }
}

export default reduxForm({
  form: 'listFinancing'
})(Financing)
