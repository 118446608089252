import { call, put, select, takeEvery } from 'redux-saga/effects'
import { reset } from 'redux-form'
import { getAuth } from '../../selectors/access/auth'
import commonActions from '../../actions/common'
import messageModalActions from '../../actions/modals/messageModal'
import { fetchCombosSuccess } from '../../actions/combos/combos'
import combosActionTypes from '../../constants/actions/combos/combos'
import { handleError } from '../errors/errorManager'
import getSelfSupplyOrganizedUnites from '../../services/selfSupply/getSelfSupplyOrganizedUnites'

export function * fetchSelfSupplyOrganizedUnites () {
  try {
    yield put(commonActions.showLoader())
    const auth = yield select(getAuth)
    let selfSupplyOrganizedUnitCombo = []
    selfSupplyOrganizedUnitCombo = yield call(getSelfSupplyOrganizedUnites, auth.token)
    yield put(fetchCombosSuccess({ selfSupplyOrganizedUnitCombo }))
  } catch (error) {
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    const customHandler = function * () {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.FETCH_COMBO_ERROR'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchFetchSelfSupplyOrganizedUnites () {
  yield takeEvery(combosActionTypes.FETCH_SELF_SUPPLY_ORGANIZED_UNITS, fetchSelfSupplyOrganizedUnites)
}
