import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { fetchTemplateFields } from '../../actions/combos/combos'
import { submitTemplate, closeEditTemplateModal, createTemplateField, updateTemplateField, deleteTemplateField, fetchTemplateFile, downloadTemplate } from '../../actions/templates/templates'
import TemplatesModal from '../../components/templates/TemplatesModal'

export function mapStateToProps (state) {
  return {
    templateModal: state.templates.templateModal,
    combos: {
      templateLogoPositionCombo: state.combos.templateLogoPositionCombo,
      templateDataTypeCombo: state.combos.templateDataTypeCombo,
      templateFieldCombos: state.combos.templateFieldCombos
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchTemplateFields,
      submitTemplate,
      closeEditTemplateModal,
      createTemplateField,
      updateTemplateField,
      deleteTemplateField,
      fetchTemplateFile,
      downloadTemplate
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(TemplatesModal))
