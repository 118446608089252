import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import SearchByEntityForm from '../../components/search/SearchByEntityForm'
import { translate } from 'react-polyglot'
import { fetchEntityTypes, fetchEntitySubTypeCombo, fetchEntityFilterSubTypeCombo } from '../../../actions/combos/combos'
import { fetchEntities, fetchEntitiesDownload, setFilters, resetFilters, setHideFilters } from '../../../actions/search/search'
import { openDownloadModal } from '../../../actions/search/download'
import { openModal } from '../../../actions/common'

export function mapStateToProps (state) {
  return {
    ...state.search,
    combos: {
      entityTypeCombo: state.combos.entityTypeCombo,
      entitySubTypeCombo: state.combos.entitySubTypeCombo,
      entityFilterSubTypeCombo: state.combos.entityFilterSubTypeCombo
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchEntityTypes,
      fetchEntitySubTypeCombo,
      fetchEntityFilterSubTypeCombo,
      fetchEntities,
      fetchEntitiesDownload,
      setFilters,
      resetFilters,
      openDownloadModal,
      openModal,
      setHideFilters
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(SearchByEntityForm))
