import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { fetchFilteredDashboardTask, fetchDashboardTask, switchTaskFavorite } from '../../actions/dashboard/dashboardTask'
import DashboardTask from '../../components/dashboard/DashboardTask'
import DashboardTask_v2 from '../../_v2/components/dashboard/DashboardTask'
import { translate } from 'react-polyglot'
import ComponentVersionSelector from '../../components/ComponentVersionSelector'

// import { push as navigateTo } from 'react-router-redux'

export function mapStateToProps (state) {
  return {
    ...state.dashboardTask,
    organizedUnit: state.auth.organizedUnit,
    v1Component: DashboardTask,
    v2Component: DashboardTask_v2
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchFilteredDashboardTask,
      fetchDashboardTask,
      switchTaskFavorite/* ,
      navigateTo */
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(ComponentVersionSelector))
