import signActionsType from '../../constants/actions/sign/sign'

export function searchDocuments (filters) {
  return {
    type: signActionsType.SEARCH_DOCUMENTS,
    filters
  }
}

export function getDocumentsCount (status, resolve, reject) {
  return {
    type: signActionsType.GET_DOCUMENTS_COUNT,
    status,
    resolve,
    reject
  }
}

export function searchDocumentsSuccess (data, filters) {
  return {
    type: signActionsType.SEARCH_DOCUMENTS_SUCCES,
    data,
    filters
  }
}

export function getDocumentsCountSucces (data) {
  return {
    type: signActionsType.GET_DOCUMENTS_COUNT_SUCCES,
    data
  }
}
