import mastersActionTypes from '../../../constants/actions/masters/masters'

function initialState () {
  return {
    repairshopList: [],
    repairshopsListCount: 0,
    pagesCount: 0,
    values: [],
    filter: {
      page: 1,
      orderBy: '',
      pageSize: 10
    },
    changeTabFromMaster: false,
    ouIdFromMaster: null,
    userList: [],
    repairshop: null,
    repairshopId: null,
    repairshopModal: {
      repairshop: null,
      repairshopId: null,
      showModal: false,
      userList: [],
      usersCount: 0,
      pagination: {
        page: 1,
        pageSize: 10,
        pagesCount: 0
      },
      filters: {
        orderBy: '+completName',
        page: 1,
        pages: 1,
        maxRows: 10,
        count: 0
      }
    }
  }
}

function fetchRepairshopListSuccess (state, { repairshopList, filter }) {
  return {
    ...state,
    repairshopList: repairshopList.repairshopList,
    repairshopListCount: repairshopList.repairshopListCount,
    pagesCount: repairshopList.pagesCount,
    filter
  }
}

function openEditRepairshopModalSuccess (state, { repairshop }) {
  const initial = initialState()
  return {
    ...state,
    repairshopModal: {
      ...initial.repairshopModal,
      repairshop: repairshop,
      showModal: true
    }
  }
}

function openAddModalSuccess (state, { repairshop }) {
  const initial = initialState()
  return {
    ...state,
    repairshopModal: {
      ...initial.repairshopModal,
      repairshop: repairshop,
      nonEditable: false,
      showModal: true
    }
  }
}

function openAddUserModalSuccess (state, { userRepairshop, repairshopId }) {
  const initial = initialState()
  return {
    ...state,
    repairshopId,
    repairshopModal: {
      ...initial.repairshopModal,
      repairshop: userRepairshop,
      repairshopId: repairshopId,
      showModal: true,
      userList: userRepairshop,
      filters: {
        orderBy: '+completName',
        page: 1,
        pages: 1,
        maxRows: 10,
        count: 0
      }
    }
  }
}

function closeEditModalSuccess (state) {
  return {
    ...state,
    repairshopModal: {
      ...initialState().repairshopModal
    }
  }
}

function setChangeTabFromConfMasterSuccess (state, { ouIdFromMaster, changeTabFromMaster }) {
  return {
    ...state,
    ouIdFromMaster,
    changeTabFromMaster
  }
}

export function fetchUserListForProcessingAgencySuccess (state, { userList, filter }) {
  return {
    ...state,
    repairshop: {
      ...state.repairshop,
      userList: userList.userList,
      filter: filter,
      pagination: {
        page: filter.page,
        pageSize: filter.pageSize,
        pagesCount: userList.pagesCount
      },
      usersCount: userList.usersCount
    }
  }
}

export function assingUserRepairshop (state, { userId, repairshopId, resolve }) {
  return {
    ...state,
    userId,
    repairshopId,
    resolve
  }
}

export function deleteUserRepairshop (state, { userRepairshopId }) {
  return {
    ...state,
    userRepairshopId
  }
}

export function fetchRepairshopUsersList (state, { repairshopId }) {
  return {
    ...state,
    repairshopId
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case mastersActionTypes.FETCH_REPAIRSHOP_LIST_SUCCESS:
      return fetchRepairshopListSuccess(state, action)
    case mastersActionTypes.OPEN_EDIT_REPAIRSHOP_MODAL_SUCCESS:
      return openEditRepairshopModalSuccess(state, action)
    case mastersActionTypes.OPEN_ADD_REPAIRSHOP_MODAL_SUCCESS:
      return openAddModalSuccess(state, action)
    case mastersActionTypes.OPEN_ADD_REPAIRSHOP_USERS_MODAL_SUCCESS:
      return openAddUserModalSuccess(state, action)
    case mastersActionTypes.CLOSE_EDIT_REPAIRSHOP_MODAL:
      return closeEditModalSuccess(state, action)
    case mastersActionTypes.SET_CHANGE_TAB_FROM_CONF_MASTER_SUCCESS:
      return setChangeTabFromConfMasterSuccess(state, action)
    case mastersActionTypes.FETCH_USERS_LIST_FOR_PROCESSING_AGENCY_SUCCESS:
      return fetchUserListForProcessingAgencySuccess(state, action)
    case mastersActionTypes.ASSING_USER_REPAIRSHOP_SUCCESS:
      return assingUserRepairshop(state, action)
    case mastersActionTypes.DELETE_USER_REPAIRSHOP_SUCCESS:
      return deleteUserRepairshop(state, action)
    case mastersActionTypes.FETCH_REPAIRSHOP_USERS_LIST_SUCCESS:
      return fetchRepairshopUsersList(state, action)
    default:
      return state
  }
}
