import apiFetch from '../apiFetch'

export default function (token, dossierId, documentTypeUseId, formValues) {
  return apiFetch({
    endPoint: `Document/FromTemplateByReferenceTypePost?documentTypeUseId=${documentTypeUseId}&dossierId=${dossierId}`,
    method: 'POST',
    body: formValues,
    token: token,
    parseBlobAndAssign: true
  })
}
