import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { initializeSearch, resetFilters } from '../../../actions/search/search'
import { openModal, yesNoModal } from '../../../actions/common'
import { documentAudit, documentAuditFinish, saveAuditAdmin, fetchAuditAdminInfo } from '../../../actions/management/management'
import AuditFileModal from '../../../components/management/AuxModals/AuditFileModal'

export function mapStateToProps (state) {
  const auditAdminModal = state.management.auditAdminModal
  return {
    showModal: auditAdminModal.showModal,
    auditAdmin: auditAdminModal.auditAdmin,
    ...state.management,
    ...state.formData
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      documentAudit,
      documentAuditFinish,
      initializeSearch,
      resetFilters,
      openModal,
      yesNoModal,
      fetchAuditAdminInfo,
      saveAuditAdmin
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(AuditFileModal))
