import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { closeDirectionUOModal, saveDirectionUOModal } from '../../actions/masters/masters'
import DirectionOUModal from '../../components/masters/DirectionOUModal'

export function mapStateToProps (state) {
  return {
    ...state.entityMasters
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      closeDirectionUOModal,
      saveDirectionUOModal
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(DirectionOUModal))
