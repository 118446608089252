import React, { PureComponent } from 'react'
import { Panel, Button, Table, Tooltip, OverlayTrigger } from 'react-bootstrap'
import InputDatePicker from '../../../../_v2/components/commons/form/InputDatePicker'
import { Field } from 'redux-form'
import { getFieldsPermisions } from '../../../../util/permisionFunctions'
import { compareObjects } from '../../../../util/utils'
import moment from 'moment'
import InputSliderButton from '../../../../_v2/components/commons/form/InputSliderButton'
import { dossierSubTypes, permissions } from '../../../../constants/backendIds'
import { documentEntityType } from '../../../../constants/dossier/common/documentEntityType'
import { documenTypeUseCodes } from '../../../../constants/document/documentTypeUse'
import ComponentTitle from '../../../../_v2/containers/dossiers/commons/ComponentTitlePage'

class Photographs extends PureComponent {
  constructor () {
    super()
    this.state = {
      open: false,
      fieldsConfiguration: {
        validateButton: null,
        updateButton: null,
        publishButton: null,
        vehicleReserved: null,
        confirmationDate: null,
        acceptanceDate: null,
        publishDate: null
      },
      dossierPhotosComponent: null,
      visibleSkip: false
    }
  }

  initializePermisions (props) {
    const fieldKeys = Object.keys(this.state.fieldsConfiguration)
    const newState = getFieldsPermisions(props.componentConfiguration.fieldsConfiguration, fieldKeys, props.componentConfiguration.permission)
    this.setState({ fieldsConfiguration: newState.fieldsConfiguration })
  }

  componentDidUpdate (prevProps) {
    if (this.props.dossier && this.props.dossier.dossierId) {
      if (!compareObjects(prevProps.componentConfiguration || {}, this.props.componentConfiguration, true)) {
        this.initializePermisions(this.props)
      }
      if (this.props.open) {
        this.setState({ open: true })
      }
    }
    return prevProps
  }

  componentDidMount () {
    if (this.props.componentConfiguration && this.props.componentConfiguration !== null) {
      this.initializePermisions(this.props)
    }
    this.setState({ visibleSkip: this.props.dossier?.dossierPhotosComponent?.vehicleReserved === 2 })
  }

  permissionButon () {
    return !this.props.historical && isNaN((moment(this.props.dossier?.dossierPhotosComponent?.confirmationDate, 'DD/MM/YYYY').toDate())) && this.state.fieldsConfiguration?.confirmationDoneButton && this.state.fieldsConfiguration.confirmationDoneButton.permission !== permissions.hidden
  }

  fetchSalesDossierPromisePhotographs () {
    if (this.state.open) {
      this.props.actions.setActiveSection(false)
    }
    this.setState({
      open: !this.state.open
    })
  }

  cancelPhoto (documentId, dossierId) {
    this.props.actions.cancelDocument(16, documentId, null, dossierId, null, null)
  }

  hasPhotos () {
    return this.props.dossier?.dossierPhotosComponent?.photosComponent !== undefined && this.props.dossier?.dossierPhotosComponent?.photosComponent !== null && this.props.dossier?.dossierPhotosComponent?.photosComponent?.length > 0
  }

  table () {
    const { t, dossier } = this.props
    const tKey = 'DOSSIER_COMPONENTS.PHOTOGRAPHS.'
    const editTooltip = (<Tooltip id="editTooltip">{t(`${tKey}EDIT`)}</Tooltip>)
    const deleteTooltip = (<Tooltip id="deleteTooltip">{t(`${tKey}DELETE`)}</Tooltip>)

    return (
      dossier?.dossierPhotosComponent?.photosComponent?.map((photos, idx) => {
        return (
          <tr key={idx}>
            <td>{photos.name}</td>
            <td>{photos.description}</td>
            <td className='actions'>
              <div className='content'>
                <OverlayTrigger placement="left" overlay={editTooltip}>
                  <a className="_Btn accept stk o-icn noLine" onClick={() => this.editPhoto(photos)}>
                    <i className="iDocIcon-edit"/>
                  </a>
                </OverlayTrigger>
                <OverlayTrigger placement="left" overlay={deleteTooltip}>
                  <a className="_Btn cancel stk o-icn noLine" onClick={() => this.cancelPhoto(photos.documentId, dossier.dossierId)}>
                    <i className="iDocIcon-delete-forever"/>
                  </a>
                </OverlayTrigger>
              </div>
            </td>
          </tr>
        )
      }))
  }

  editPhoto (photos) {
    this.props.actions.openDocumentEditionModal(photos.documentId, null, documentEntityType.DOSSIER, this.props.dossier.dossierId, null, null, this.props.dossier.organizedUnitId, null, null, null, dossierSubTypes.stock, null, null, null, null, false, this.updatePhotos(), null, null, null, null, null, null, null, documenTypeUseCodes.FOTOS_DOCS_STOCK)
  }

  handleOpenDocumentCreationModal () {
    this.props.actions.openDocumentEditionModal(null, null, documentEntityType.DOSSIER, this.props.dossier.dossierId, null, null, this.props.dossier.organizedUnitId, null, null, null, dossierSubTypes.stock, null, null, null, null, false, true, null, null, null, null, null, null, null, documenTypeUseCodes.FOTOS_DOCS_STOCK)
  }

  ChangeInputSlider (value) {
    this.setState({ visibleSkip: value === 2 })
  }

  updatePhotos () {
    return this.state.fieldsConfiguration.updateButton && this.state.fieldsConfiguration.updateButton.permission !== permissions.hidden && !this.props.isHistorical && !this.props?.dossier?.dossierPhotosComponent?.isConfirmed
  }

  render () {
    const { t, dossier, isHistorical } = this.props
    const tKey = 'DOSSIER_COMPONENTS.PHOTOGRAPHS.'
    return (
      <div className='dossier-Component'>
        <div className='header-panel' onClick={this.fetchSalesDossierPromisePhotographs.bind(this)}>
          <ComponentTitle
            componentCode="PHOTOGRAPHS"
            componentOpen={this.state.open}
            // extraInfo={'info extra'}
            // extraData={<a className="_Btn accept">texto</a>}
          />
        </div>
        <Panel expanded={this.state.open} onToggle={() => {}}>
          <Panel.Collapse>
            {this.state.visibleSkip && !dossier?.dossierPhotosComponent?.isPusblish && !isHistorical && (
              <div className='flexInLine al-R'>
                <Button className='_Btn new' onClick={() => this.props.actions.skipDossierPhotos(dossier.dossierId)}>
                  {t(`${tKey}BTN_SKIP_PHOTOS`)}
                </Button>
              </div>
            )}
            <div className='fieldsContainer'>
              {this.state.fieldsConfiguration.vehicleReserved && this.state.fieldsConfiguration.vehicleReserved.permission !== permissions.hidden && (
                <Field
                  component={InputSliderButton}
                  colWidht={33} // 25, 33, 50, 75, 100 default=25
                  id="vehicleReserved"
                  name="dossierPhotosComponent.vehicleReserved"
                  controlLabel={t(`${tKey}RERSERVED_VEHICLE`)}
                  placeholder={t(`${tKey}RERSERVED_VEHICLE`)}
                  textDescription={t(`${tKey}RERSERVED_VEHICLE`)}
                  customClass={'three-rc-select'}
                  defaultChoice={dossier?.dossierPhotosComponent?.vehicleReserved ? dossier?.dossierPhotosComponent?.vehicleReserved : null}
                  onChange={(value) => this.ChangeInputSlider(value)}
                  disabled={true}
                />
              )}
            </div>
            <div className='fieldsContainer'>
              {this.state.fieldsConfiguration.publishDate && this.state.fieldsConfiguration.publishDate.permission !== permissions.hidden && (
                <Field
                  component={InputDatePicker}
                  colWidht={33} // 25, 33, 50, 75, 100 default=25
                  id="publishDate"
                  name="dossierPhotosComponent.publishDate"
                  controlLabel={t(`${tKey}PUBLISH_DATE`)}
                  placeholder={t(`${tKey}PUBLISH_DATE`)}
                  disabled={true}
                  customClass={'disabled'}
                />
              )}
              {this.state.fieldsConfiguration.confirmationDate && this.state.fieldsConfiguration.confirmationDate.permission !== permissions.hidden && (
                <Field
                  component={InputDatePicker}
                  colWidht={33} // 25, 33, 50, 75, 100 default=25
                  id="confirmationDate"
                  name="dossierPhotosComponent.confirmationDate"
                  controlLabel={t(`${tKey}CONFIRM_DATE`)}
                  placeholder={t(`${tKey}CONFIRM_DATE`)}
                  disabled={true}
                  customClass={'disabled'}
                />
              )}
              {this.state.fieldsConfiguration.acceptanceDate && this.state.fieldsConfiguration.acceptanceDate.permission !== permissions.hidden && (
                <Field
                  component={InputDatePicker}
                  colWidht={33} // 25, 33, 50, 75, 100 default=25
                  id="acceptanceDate"
                  name="dossierPhotosComponent.acceptanceDate"
                  controlLabel={t(`${tKey}ACEPT_DATE`)}
                  placeholder={t(`${tKey}ACEPT_DATE`)}
                  disabled={true}
                  customClass={'disabled'}
                />
              )}
            </div>
            <div className='fieldsContainer'>
              <div className="wrapper _nf">
                <Table className="editable-table" hover>
                  <thead>
                    <tr>
                      <th key={'concept'}>{t(`${tKey}NAME_FILE`)}</th>
                      <th key={'import'} style={{ width: '80%' }}>{t(`${tKey}DESCRIPTION`)}</th>
                      <th className='actions' key={'actions'}>{t(`${tKey}ACTIONS`)}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.table()}
                  </tbody>
                </Table>
              </div>
            </div>
            <div className='flexInLine al-J'>
              { this.updatePhotos() && (
                <Button className='_Btn accept'
                  onClick={() => this.handleOpenDocumentCreationModal()}
                  disabled={this.props?.dossier?.dossierPhotosComponent?.isConfirmed || this.state.fieldsConfiguration.updateButton.permission !== permissions.editable}>
                  {t(`${tKey}BTN_LOAD_PHOTO`)}
                </Button>
              )}
              { this.state.fieldsConfiguration.updateButton && this.state.fieldsConfiguration.updateButton.permission !== permissions.hidden && !isHistorical && (
                <Button className='_Btn accept'
                  onClick={() => this.props.actions.confirmPhotos(dossier.dossierId, 'CONFIRM_PHOTO', true)}
                  disabled={this.props?.dossier?.dossierPhotosComponent?.isConfirmed || this.state.fieldsConfiguration.updateButton.permission !== permissions.editable || !this.hasPhotos()}>
                  {t(`${tKey}BTN_CONFIRM_PHOTO`)}
                </Button>
              )}
              {this.state.fieldsConfiguration.validateButton && this.state.fieldsConfiguration.validateButton.permission !== permissions.hidden && !isHistorical && (
                <Button className='_Btn accept'
                  onClick={() => this.props.actions.confirmPhotos(dossier.dossierId, 'ACEPT_PHOTO', true)}
                  disabled={this.props?.dossier?.dossierPhotosComponent?.isAccepted || !this.props?.dossier?.dossierPhotosComponent?.isConfirmed}>
                  {t(`${tKey}BTN_ACEPT_PHOTO`)}
                </Button>
              )}
              {this.state.fieldsConfiguration.validateButton && this.state.fieldsConfiguration.validateButton.permission !== permissions.hidden && !isHistorical && (
                <Button className='_Btn cancel'
                  onClick={() => this.props.actions.confirmPhotos(dossier.dossierId, 'ACEPT_PHOTO', false)}
                  disabled={this.props?.dossier?.dossierPhotosComponent?.isAccepted || !this.props?.dossier?.dossierPhotosComponent?.isConfirmed}>
                  {t(`${tKey}BTN_REJECT_PHOTO`)}
                </Button>
              )}
              {this.state.fieldsConfiguration.publishButton && this.state.fieldsConfiguration.publishButton.permission !== permissions.hidden && !isHistorical && (
                <Button className='_Btn accept'
                  onClick={() => this.props.actions.confirmPhotos(dossier.dossierId, 'PUBLISH_PHOTO', true)}
                  disabled={!this.props?.dossier?.dossierPhotosComponent?.isAccepted || this.props?.dossier?.dossierPhotosComponent?.isPusblish}>
                  {t(`${tKey}PUBLISH_PHOTO`)}
                </Button>
              )}
              {this.state.fieldsConfiguration.publishButton && this.state.fieldsConfiguration.publishButton.permission !== permissions.hidden && !isHistorical && (
                <Button className='_Btn cancel'
                  onClick={() => this.props.actions.confirmPhotos(dossier.dossierId, 'PUBLISH_PHOTO', false)}
                  disabled={!this.props?.dossier?.dossierPhotosComponent?.isAccepted || this.props?.dossier?.dossierPhotosComponent?.isPusblish}>
                  {t(`${tKey}BTN_REJECT_PUBLISH`)}
                </Button>
              )}
            </div>
          </Panel.Collapse>
        </Panel>
      </div>
    )
  }
}
export default Photographs
