export default {
  GET_COMMENTS: 'GET_COMMENTS',
  UPDATE_COMMENT: 'UPDATE_COMMENT',
  CREATE_COMMENT: 'CREATE_COMMENT',
  DELETE_COMMENT: 'DELETE_COMMENT',
  FETCH_SALES_COMMENTS_SUCCESS: 'FETCH_SALES_COMMENTS_SUCCESS',
  FETCH_PAPERWORK_COMMENTS_SUCCESS: 'FETCH_PAPERWORK_COMMENTS_SUCCESS',
  FETCH_PURCHASE_COMMENTS_SUCCESS: 'FETCH_PURCHASE_COMMENTS_SUCCESS',
  FETCH_CAMPAIGN_COMMENTS_SUCCESS: 'FETCH_CAMPAIGN_COMMENTS_SUCCESS',
  MARK_COMMENT_AS_READ: 'MARK_COMMENT_AS_READ',
  FETCH_CLOSURE_REPORT_COMMENTS_SUCCESS: 'FETCH_CLOSURE_REPORT_COMMENTS_SUCCESS',
  FETCH_SERVICE_COMMENTS_SUCCESS: 'FETCH_SERVICE_COMMENTS_SUCCESS',
  FETCH_REGISTRATION_DELIVERY_COMMENTS_SUCCESS: 'FETCH_REGISTRATION_DELIVERY_COMMENTS_SUCCESS',
  FETCH_STOCK_COMMENTS_SUCCESS: 'FETCH_STOCK_COMMENTS_SUCCESS'
}
