import downloadActionTypes from '../../constants/actions/search/download'

export function openDownloadModal (download) {
  return {
    type: downloadActionTypes.OPEN_DOWNLOAD_MODAL,
    download
  }
}

export function openDownloadModalSuccess (download) {
  return {
    type: downloadActionTypes.OPEN_DOWNLOAD_MODAL_SUCCESS,
    download
  }
}

export function closeDownloadModal () {
  return {
    type: downloadActionTypes.CLOSE_DOWNLOAD_MODAL
  }
}

export function postBulkDownload (body, resolve, reject) {
  return {
    type: downloadActionTypes.POST_BULK_DOWNLOAD,
    body,
    resolve,
    reject
  }
}
