import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import UnattendedProcessDetailModal from '../../components/management/UnattendedProcessDetailModal'
import { translate } from 'react-polyglot'

import { closeUnattendedProcessDetailModal, findDossierNumbersFromId, unattendedProcessDownloadDeliveryNotes } from '../../actions/unattendedProcess/unattendedProcess'

export function mapStateToProps (state) {
  return {
    ...state.unattendedProcess.unattendedProcessDetailModal
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      closeUnattendedProcessDetailModal,
      findDossierNumbersFromId,
      unattendedProcessDownloadDeliveryNotes
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(UnattendedProcessDetailModal))
