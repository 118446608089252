import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import ProcessingAgencyOrganizedUnitConfigMasterModal from '../../../components/masters/processingAgencyOrganizedUnitConfig/ProcessingAgencyOrganizedUnitConfigMasterModal'
import { showProcessingAgencyOrganizedUnitConfigModal, submitProcessingAgencyOrganizedUnitConfig } from '../../../actions/masters/processingAgencyOrganizedUnitConfig/processingAgencyOrganizedUnitConfig'

export function mapStateToProps (state) {
  const processingAgencyOrganizedUnitConfigModal = state.processingAgencyOrganizedUnitConfig.processingAgencyOrganizedUnitConfigModal
  return {
    showModal: processingAgencyOrganizedUnitConfigModal.showModal,
    processingAgencyOrganizedUnitConfig: processingAgencyOrganizedUnitConfigModal.processingAgencyOrganizedUnitConfig,
    languageList: processingAgencyOrganizedUnitConfigModal.languageList,
    combos: {
      organizedUnitsAllCombo: state.combos.organizedUnitsAllCombo
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      showProcessingAgencyOrganizedUnitConfigModal,
      submitProcessingAgencyOrganizedUnitConfig
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(ProcessingAgencyOrganizedUnitConfigMasterModal))
