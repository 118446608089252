import React, { PureComponent } from 'react'
import { Popover, Overlay, Button, Glyphicon } from 'react-bootstrap'
import { dossierSubTypeId as dossierSubTypes } from '../../constants/dossier/common/dossierSubType'

class VehiclePopover extends PureComponent {
  constructor () {
    super()
    this.state = {
      showPopover: false,
      target: this
    }
  }

  handleClick (e) {
    e.stopPropagation()
    this.setState({ target: e.target, showPopover: true })
  }

  getHeaderText (isHeader) {
    const vehicle = this.props.vehicle
    const dossierSubTypeId = this.props.dossierSubTypeId
    let headerTextReturn = vehicle.vehicleVinChassis || vehicle.vehicleModel || vehicle.vehicleBrand || vehicle.vehicleLicensePlate
    const licensePlateFirst = vehicle.vehicleLicensePlate || vehicle.vehicleVinChassis || vehicle.vehicleModel || vehicle.vehicleBrand

    if (dossierSubTypeId === dossierSubTypes.venta_vo || dossierSubTypeId === dossierSubTypes.compra_vo || dossierSubTypeId === dossierSubTypes.tramite ||
      dossierSubTypeId === dossierSubTypes.stock_vo) {
      headerTextReturn = licensePlateFirst
    } else {
      if (!dossierSubTypeId) {
        if (isHeader && vehicle.vehicleLicensePlate && vehicle.vehicleVinChassis) {
          return vehicle.vehicleLicensePlate + ' (' + vehicle.vehicleVinChassis + ')'
        } else {
          headerTextReturn = licensePlateFirst
        }
      }
    }

    return isHeader && headerTextReturn.length > 20 ? headerTextReturn.substring(0, 15) + '...' : headerTextReturn
  }

  render () {
    const {
      t,
      idx,
      // half,
      id,
      isHeader,
      vehicle = {}
    } = this.props
    const tKey = 'SEARCH.CATEGORIES.'
    const renderComponent = vehicle.vehicleVinChassis || vehicle.vehicleModel || vehicle.vehicleBrand || vehicle.vehicleLicensePlate
    return (
      <div>
        {renderComponent &&
          <Button bsSize="xsmall" className="icon-only" onClick={this.handleClick.bind(this)} id={'vehicle_popOver_button_' + idx}><Glyphicon glyph="plus"/></Button>
        }
        <div className="text-popover">
          { renderComponent && this.getHeaderText(isHeader) }
        </div>
        <Overlay
          container={this}
          show={this.state.showPopover}
          target={this.state.target}
          onHide={() => this.setState({ showPopover: false })}
          rootClose
          placement={'bottom'}
        >
          <Popover id={id} className="document-count-popover-container">
            {vehicle.vehicleVinChassis &&
            <div className="popover-item">
              <label>{t(`${tKey}VEHICLE.CHASSIS`)}: </label><br/>
              {vehicle.vehicleVinChassis}
            </div>
            }
            {vehicle.carline &&
            <div className="popover-item">
              <label>{t(`${tKey}VEHICLE.CARLINE`)}: </label><br/>
              {vehicle.carline}
            </div>
            }
            {vehicle.catalogue &&
            <div className="popover-item">
              <label>{t(`${tKey}VEHICLE.CATALOGUE`)}: </label><br/>
              {vehicle.catalogue}
            </div>
            }
            {vehicle.modelYear &&
            <div className="popover-item">
              <label>{t(`${tKey}VEHICLE.MODEL_YEAR`)}: </label><br/>
              {vehicle.modelYear}
            </div>
            }
            {vehicle.vehicleModel &&
            <div className="popover-item">
              <label>{t(`${tKey}VEHICLE.MODEL`)}: </label><br/>
              {vehicle.vehicleModel}
            </div>
            }
            {vehicle.vehicleBrand &&
            <div className="popover-item">
              <label>{t(`${tKey}VEHICLE.BRAND`)}: </label><br/>
              {vehicle.vehicleBrand}
            </div>
            }
            {vehicle.vehicleLicensePlate &&
            <div className="popover-item">
              <label>{t(`${tKey}VEHICLE.LICENSE_PLATE`)}: </label><br/>
              {vehicle.vehicleLicensePlate}
            </div>
            }
            {vehicle.comercialCode &&
            <div className="popover-item">
              <label>{t(`${tKey}VEHICLE.COMMERCIAL_CODE`)}: </label><br/>
              {vehicle.comercialCode}
            </div>
            }
            {vehicle.licensePlateDate &&
            <div className="popover-item">
              <label>{t(`${tKey}VEHICLE.FIRST_LPLATE_DATE`)}: </label><br/>
              {vehicle.licensePlateDate}
            </div>
            }
            {vehicle.color &&
            <div className="popover-item">
              <label>{t(`${tKey}VEHICLE.COLOR`)}: </label><br/>
              {vehicle.color}
            </div>
            }
            {vehicle.tapestry &&
              <div className="popover-item">
                <label>{t(`${tKey}VEHICLE.TAPESTRY`)}: </label><br/>
                {vehicle.tapestry}
              </div>
            }
          </Popover>
        </Overlay>
      </div>
    )
  }
}

export default VehiclePopover
