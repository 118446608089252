import apiFetch from '../../apiFetch'

export default function (token, stateId) {
  const queryParamsArray = [
    `stateId=${stateId}`
  ]
  const queryParams = queryParamsArray.reduce(
    (result, query, idx) => result + (query ? (result ? `&${query}` : `?${query}`) : '')
    , ''
  )
  return apiFetch({ endPoint: `Entity/GetAllCities${queryParams}`, method: 'GET', token: token })
}
