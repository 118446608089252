import apiFetch from '../../apiFetch'

export default function (token, dossierId, vehicle) {
  const queryParamsArray = [
    vehicle.vinChassis ? `vinChassis=${vehicle.vinChassis}` : null,
    vehicle.licensePlate ? `licensePlate=${vehicle.licensePlate}` : null
  ]
  const queryParams = queryParamsArray.reduce(
    (result, query, idx) => result + (query ? (result ? `&${query}` : `?${query}`) : '')
    , ''
  )
  return apiFetch({
    endPoint: `Dossier/Service/${dossierId}/FindVehicle${queryParams}`,
    method: 'GET',
    body: null,
    token: token
  })
}
