import React, { PureComponent } from 'react'
import { Row, Col, Button, Tab, Tabs } from 'react-bootstrap'
import ModalChangeManager from './modalChangeManager'
import Tab1 from './Tabs/Tab1'
import Tab2 from './Tabs/Tab2'
import Tab3 from './Tabs/Tab3'
import Tab4 from './Tabs/Tab4'
import Step1 from './Step1/Step1'

class salesDossier extends PureComponent {
  render () {
    return (
      <Col sm={12} className="manage-dossier-wrapper dossier">
        <Row className="time-line-button">
          <Col sm={7} className="time-line">
            <div className="step-wrapper">
              <div className="step active">
                <span className="step-number">1</span>
                <span className="step-name">Propuesta venta</span>
              </div>
            </div>
            <div className="step-wrapper">
              <div className="step">
                <span className="step-number">2</span>
                <span className="step-name">Matriculación</span>
              </div>
            </div>
            <div className="step-wrapper">
              <div className="step">
                <span className="step-number">3</span>
                <span className="step-name">Entrega</span>
              </div>
            </div>
            <div className="step-wrapper">
              <div className="step">
                <span className="step-number">4</span>
                <span className="step-name">Entrega</span>
              </div>
            </div>
          </Col>
          <Col sm={5} className="text-right btn-timeline no-pdd">
            <Button className="btn-standard btn-continue">
              Cambiar gestor
            </Button>
            <Button className="btn-standard btn-continue">
              Documentación
            </Button>
          </Col>
        </Row>
        <Row className="band-user">
          <Col sm={3} className="band-icon-item">
            <i className="ico-user" />
            <div className="text-band">
              <span className="first">Nombre del gestor:</span>
              <span className="second">Nombre del gestor</span>
            </div>
          </Col>
          <Col sm={3} className="band-icon-item">
            <i className="ico-user" />
            <div className="text-band">
              <span className="first">Nombre del cliente:</span>
              <span className="second">Nombre del cliente</span>
            </div>
          </Col>
          <Col sm={3} className="band-icon-item">
            <i className="ico-car" />
            <div className="text-band">
              <span className="first">Marca:</span>
              <span className="second">Marca</span>
            </div>
          </Col>
          <Col sm={3} className="band-icon-item">
            <i className="ico-car" />
            <div className="text-band">
              <span className="first">Modelo:</span>
              <span className="second">Modelo</span>
            </div>
          </Col>
        </Row>
        <Row className="tabs-wrapper manage-dossier">
          <Tabs id="manage-dossier-tabs" defaultActiveKey={1}>
            <Tab eventKey={1} title='Expediente'>
              <Tab1/>
            </Tab>
            <Tab eventKey={2} title='Tipo de venta/operación'>
              <Tab2/>
            </Tab>
            <Tab eventKey={3} title='Cliente'>
              <Tab3/>
            </Tab>
            <Tab eventKey={4} title='Vehículo'>
              <Tab4/>
            </Tab>
          </Tabs>
          <div className="draft-wrapper">
            <i className="ico-edit-white"></i>
            <span>Borrador</span>
          </div>
        </Row>
        <Step1/>
        <ModalChangeManager />
      </Col>
    )
  }
}

export default salesDossier
