import React, { PureComponent } from 'react'
import { Field } from 'redux-form'
import { Row, Button, Clearfix } from 'react-bootstrap'
import InputText from '../commons/form/InputText'
import InputCheckbox from '../commons/form/InputCheckBox'
import { multipleNormalize, toUpperCase, trimAll } from '../../util/normalizeFunctions'
import { urgencyTypesClass } from '../../constants/backendIds'
import SimpleTablePage from '../../containers/commons/general/SimpleTablePage'
import IDocCarPagination from '../commons/iDocCarPagination'
import { Link } from 'react-router-dom'
/* import InputDatePicker from '../commons/form/InputDatePicker'
import {validateDate} from '../../util/validationFunctions'
import InputSelect from '../commons/form/InputSelect'
import settings from '../../setting' */

class SearchBySelfSupplyForm extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      fields: {},
      disableCheck: false
    }
  }

  exportData () {
    const tKey = 'SEARCH.CATEGORIES.DOSSIER.'
    let columnsExcel = []
    columnsExcel.push(this.props.t(`${tKey}URGENCY`) + '#Urgency')
    columnsExcel.push(this.props.t(`${tKey}DOSSIER_NUMBER`) + '#Number')
    columnsExcel.push(this.props.t(`${tKey}LICENSE_PLATE`) + '#LicensePlate')
    columnsExcel.push(this.props.t('SEARCH.CATEGORIES.VEHICLE.CHASSIS') + '#VinChassis')
    columnsExcel.push(this.props.t(`${tKey}CREATION_DATE`) + '#CreationDate')

    this.props.exportToExcel(columnsExcel)
  }

  render () {
    const {
      t, fields, fieldFill, className, hasSearch, selfSupplyData, onOrderChange, arrayToStringSelfSupply,
      selectedFilters, onClickResetSearch, getIconClass, addDossierIdToArrayMaxLenght, dossierIdToArray,
      page, pages, count, onPageChange
    } = this.props
    const tKey = 'SEARCH.CATEGORIES.DOSSIER.'
    return (
      <div>
        {!hasSearch ? (
          <Row>
            <Field
              id="numberSelfSupply"
              name='selfSupply.dossierNumber'
              placeholder={t(`${tKey}DOSSIER_NUMBER`)}
              controlLabel={t(`${tKey}DOSSIER_NUMBER`)}
              component={InputText}
              type="text"
              onInputChange={(val) => fieldFill({ number: val })}
              customClass={fields.number ? className : ''}
            />
            <Field
              colSm={3}
              id="chassisSelfSupply"
              name='selfSupply.chassis'
              placeholder={t('SEARCH.CATEGORIES.VEHICLE.CHASSIS')}
              controlLabel={t('SEARCH.CATEGORIES.VEHICLE.CHASSIS')}
              component={InputText}
              type="text"
              maxLength={17}
              normalize={value => multipleNormalize(value, [toUpperCase, trimAll])}
              onInputChange={(val) => fieldFill({ chassis: val })}
              customClass={fields.chassis ? className : ''}
            />
            <Field
              colSm={3}
              id="licence_plateSelfSupply"
              name='selfSupply.licensePlate'
              placeholder={t(`${tKey}LICENSE_PLATE`)}
              controlLabel={t(`${tKey}LICENSE_PLATE`)}
              component={InputText}
              type="text"
              normalize={value => multipleNormalize(value, [toUpperCase, trimAll])}
              onInputChange={(val) => fieldFill({ licence_plate: val })}
              customClass={fields.licence_plate ? className : ''}
            />
          </Row>
        ) : ([
          <Row className="filters-selected" key="filters-selected">
            <ul className="list-inline pull-left">
              {selectedFilters().length === 0 ? <li>{t(`${tKey}NO_SELECTED_FILTERS`)}</li> : (selectedFilters())}
            </ul>
            <Clearfix/>
            <Button
              onClick={(event) => onClickResetSearch(event)}
              bsStyle="default"
              bsSize="small"
              className="pull-left"
              id='modSearchButtonIdSelfSupply'
            >
              {t('SEARCH.ACTIONS.MODIFY_SEARCH')}
              <i className="ico-edit-turquoise" />
            </Button>
            {count > 0 &&
            <Button
              onClick={(event) => this.exportData(event)}
              bsStyle="default"
              bsSize="small"
              id='expSearchButtonIdSelfSupply'>
              {t('SEARCH.ACTIONS.EXPORT')}
              <i className="ico-if_Download_1031520" />
            </Button>
            }
          </Row>,
          <div className="table-wrapper" key="table-wrapper">
            <Button
              onClick={() => arrayToStringSelfSupply() }
              className="button create purchase">
              <span>{t(`${tKey}SALE_SELF_SUPPLY`)}</span>
            </Button>
            <SimpleTablePage
              columns={[
                <th key={0} />,
                <th key={1} data-field="number" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}DOSSIER_NUMBER`)}&nbsp;<i className={getIconClass('number')}/>
                </th>,
                <th key={2} data-field="vinChassis" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}LICENSE_PLATE`)}&nbsp;<i className={getIconClass('vehicleLicensePlate')}/>
                </th>,
                <th key={3} data-field="vinChasis" onClick={(event) => onOrderChange(event)}>
                  {t('SEARCH.CATEGORIES.VEHICLE.CHASSIS')}&nbsp;<i className={getIconClass('vehicleVinChassis')}/>
                </th>,
                <th key={4} data-field="creationDate" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}CREATION_DATE`)}&nbsp;<i className={getIconClass('creationDate')}/>
                </th>,
                <th key={5} data-field="check" onClick={(event) => onOrderChange(event)}>
                  {'Crear compra VO'}
                </th>
              ]}
              rows={selfSupplyData && selfSupplyData.map((row, idx) => (
                <tr key = {idx}>
                  <td>
                    <span className={`color-point ${urgencyTypesClass[row.urgencyType] || 'grey'}`}/>
                  </td>
                  <td> <Link to={`/dossier${row.urlToNavigate}`}>
                    {row.number}
                  </Link> </td>
                  <td>{row.vehicle.vehicleLicensePlate}</td>
                  <td>{row.vehicle.vehicleVinChassis}</td>
                  <td>{row.creationDate}</td>
                  <td>{<Field
                    component={InputCheckbox}
                    name={'purchaseCheck' + row.dossierId}
                    onChange={() => addDossierIdToArrayMaxLenght(row.dossierId)}
                    disabled={!dossierIdToArray.includes(row.dossierId) && dossierIdToArray.length > 4}
                  />}</td>
                </tr>
              )
              )
              }
            />
          </div>,
          <div className="search-footer" key="search-footer">
            <IDocCarPagination
              id="btn_pag_sales"
              page={page}
              pagesCount={pages}
              totalRows= {count}
              onSelectAction={(page) => onPageChange(page)}
            />
          </div>
        ])}
      </div>
    )
  }
}

export default SearchBySelfSupplyForm
