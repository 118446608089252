import settingLocal from './settings/setting-local'
import settingDev from './settings/setting-dev'
import settingDevv2 from './settings/setting-devv2'
import settingFront from './settings/setting-front'
import settingInt from './settings/setting-int'
import settingTest from './settings/setting-test'
import settingLocalMock from './settings/setting-local-mock'
import settingPro from './settings/setting-pro'
import settingDemo from './settings/setting-demo'
import settingQa from './settings/setting-qa'
import settingUx from './settings/setting-ux'
import settingQaVgr from './settings/setting-prevgr'
import settingPreVgrBcn from './settings/setting-prevgr_bcn'
import settingPreVgr from './settings/setting-qavgr'
import settingQaVgrBcn from './settings/setting-qavgr_bcn'
import settingVgr from './settings/setting-vgr'
import settingVgrBcn from './settings/setting-vgr_bcn'
import settingTestToyota from './settings/setting-test-toyota'
import settingTestVgrs from './settings/setting-test-vgrs'
import settingQaToyota from './settings/setting-qatoyota'
import settingToyota from './settings/setting-toyota'
import settingPreDemo from './settings/setting-pre_demo'
import settingQaDemo from './settings/setting-qa_demo'
import settingPreToyota from './settings/setting-pretoyota'
import settingTestRefactor from './settings/setting-test_refactor'
import settingIntelisis from './settings/setting-intelisis'
import settingQaIntelisis from './settings/setting-qa_intelisis'
import settingPreIntelisis from './settings/setting-pre_intelisis'
import settingTestIntelisis from './settings/setting-test-intelisis'
import settingkia from './settings/setting-kia'
import settingPrekia from './settings/setting-pre_kia'
import settingQakia from './settings/setting-qa_kia'
import settingTestkia from './settings/setting-test-kia'
import settingCupra from './settings/setting-cupra'
import settingPreCupra from './settings/setting-pre_cupra'
import settingQaCupra from './settings/setting-qa_cupra'
import settingTestCupra from './settings/setting-test-cupra'

let setting
if (process.argv.includes('--test') || process.argv.includes('--mocks')) {
  setting = settingTest
} else if ((process.argv.includes('--localMock'))) {
  setting = settingLocalMock
} else if ((process.argv.includes('--local'))) {
  setting = settingLocal
} else if (process.argv.includes('--dev')) {
  setting = settingDev
} else if (process.argv.includes('--devv2')) {
  setting = settingDevv2
} else if (process.argv.includes('--front')) {
  setting = settingFront
} else if (process.argv.includes('--int')) {
  setting = settingInt
} else if (process.argv.includes('--qa')) {
  setting = settingQa
} else if (process.argv.includes('--ux')) {
  setting = settingUx
} else if (process.argv.includes('--qa_toyota')) {
  setting = settingQaToyota
} else if (process.argv.includes('--pro')) {
  setting = settingPro
} else if (process.argv.includes('--demo')) {
  setting = settingDemo
} else if (process.argv.includes('--qa_vgr')) {
  setting = settingQaVgr
} else if (process.argv.includes('--qa_vgr_bcn')) {
  setting = settingQaVgrBcn
} else if (process.argv.includes('--pre_vgr')) {
  setting = settingPreVgr
} else if (process.argv.includes('--pre_vgr_bcn')) {
  setting = settingPreVgrBcn
} else if (process.argv.includes('--vgr')) {
  setting = settingVgr
} else if (process.argv.includes('--vgr_bcn')) {
  setting = settingVgrBcn
} else if (process.argv.includes('--test-toyota')) {
  setting = settingTestToyota
} else if (process.argv.includes('--toyota')) {
  setting = settingToyota
} else if (process.argv.includes('--test-vgrs')) {
  setting = settingTestVgrs
} else if (process.argv.includes('--pre_toyota')) {
  setting = settingPreToyota
} else if (process.argv.includes('--pre_demo')) {
  setting = settingPreDemo
} else if (process.argv.includes('--qa_demo')) {
  setting = settingQaDemo
} else if (process.argv.includes('--test_refactor')) {
  setting = settingTestRefactor
} else if (process.argv.includes('--intelisis')) {
  setting = settingIntelisis
} else if (process.argv.includes('--qa_intelisis')) {
  setting = settingQaIntelisis
} else if (process.argv.includes('--pre_intelisis')) {
  setting = settingPreIntelisis
} else if (process.argv.includes('--test-intelisis')) {
  setting = settingTestIntelisis
} else if (process.argv.includes('--kia')) {
  setting = settingkia
} else if (process.argv.includes('--qa_kia')) {
  setting = settingQakia
} else if (process.argv.includes('--pre_kia')) {
  setting = settingPrekia
} else if (process.argv.includes('--test-kia')) {
  setting = settingTestkia
} else if (process.argv.includes('--cupra')) {
  setting = settingCupra
} else if (process.argv.includes('--pre_cupra')) {
  setting = settingPreCupra
} else if (process.argv.includes('--qa_cupra')) {
  setting = settingQaCupra
} else if (process.argv.includes('--test-cupra')) {
  setting = settingTestCupra
}

export default setting
