import React, { PureComponent } from 'react'
import { Popover, Overlay, Button, Glyphicon } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { urgencyTypesClass } from '../../../constants/backendIds.js'
import settings from '../../../setting.js'
import PopoverItem from '../../containers/search/PopoverItemPage.js'

class DossierPopover extends PureComponent {
  constructor () {
    super()
    this.state = {
      showPopover: false,
      target: this
    }
  }

  handleClick (e) {
    e.stopPropagation()
    this.setState({ target: e.target, showPopover: true })
  }

  render () {
    const {
      t,
      id,
      urgencyType,
      dossier = {}
    } = this.props
    const tKey = 'SEARCH.CATEGORIES.'
    return (
      <div>
        {Object.keys(dossier).length > 0 &&
          <Button bsSize="xsmall" className="icon-only" onClick={this.handleClick.bind(this)}><Glyphicon glyph="plus" /></Button>
        }
        {dossier.urlToNavigate ? (
          <Link to={'/dossier' + dossier.urlToNavigate}>
            <span>{dossier.number}</span>
          </Link>
        ) : (
          <span>{dossier.number}</span>
        )}
        <Overlay
          container={this}
          show={this.state.showPopover}
          target={this.state.target}
          onHide={() => this.setState({ showPopover: false })}
          rootClose
          placement={'bottom'}
        >
          <Popover id={id} className="_nf-popover">
            {dossier.number &&
              <PopoverItem itemType={'doss_01'} urgency={urgencyTypesClass[urgencyType]} tooltip={t(`${tKey}DOSSIER.DOSSIER_NUMBER`)} data={dossier.number}></PopoverItem>
            }
            {dossier.subtype && (
              <PopoverItem itemType={'doss_02'} tooltip={t(`${tKey}DOSSIER.DOSSIER_SUBTYPE`)} data={dossier.subtype}></PopoverItem>
            )}
            {dossier.stage && (
              <PopoverItem itemType={'doss_03'} tooltip={t(`${tKey}DOSSIER.STAGE`)} data={dossier.stage}></PopoverItem>
            )}
            {dossier.organizedUnit && (
              <PopoverItem itemType={'doss_04'} tooltip={t(`${tKey}DOSSIER.ORGANIZED_UNIT`)} data={dossier.organizedUnit}></PopoverItem>
            )}
            {dossier.salesman && (
              <PopoverItem itemType={'doss_05'} tooltip={t(`${tKey}DOSSIER.SALESMAN`)} data={dossier.salesman}></PopoverItem>
            )}
            {dossier.lastUpdateDate && (
              <PopoverItem itemType={'doss_06'} tooltip={t(`${tKey}DOSSIER.LAST_CHANGE_DATE`)} data={dossier.lastUpdateDate}></PopoverItem>
            )}
            {(settings.clientFieldValidation === 'vgr' || settings.clientFieldValidation === 'vgr_bcn' || settings.clientFieldValidation === 'qa_vgr' || settings.clientFieldValidation === 'qa_vgr_bcn') && dossier.subtype === t('SEARCH.PAPERWORK') && dossier.dossierParentId && (
              <PopoverItem itemType={'doss_07'} tooltip={t('SEARCH.DEPENDENCE_OF')} data={dossier.dossierParentSubType}></PopoverItem>
            )}
            { ((settings.clientFieldValidation === 'vgr' || settings.clientFieldValidation === 'vgr_bcn' || settings.clientFieldValidation === 'qa_vgr' || settings.clientFieldValidation === 'qa_vgr_bcn')) && dossier.subtype === t('SEARCH.PAPERWORK') && dossier.dossierParentId == null && (
              <PopoverItem itemType={'doss_08'} data={t('SEARCH.NOT_DEPENDENCE')}></PopoverItem>
            )}
            {dossier.deliveryCity && (
              <PopoverItem itemType={'doss_09'} data={dossier.deliveryCity}></PopoverItem>
            )}
          </Popover>
        </Overlay>
      </div>
    )
  }
}

export default DossierPopover
