import { takeEvery, select, call, put } from 'redux-saga/effects'
import { change, reset } from 'redux-form'
import dossierActionsTypes from '../../../constants/actions/dossier/dossier'
import settingsEntityPurchase from '../../../settingPurchaseEntity.json'
import { getAuth } from '../../../selectors/access/auth'
import getEntityByDni from '../../../services/entity/getEntityByDni'
import { fetchEntitySubTypeCombo } from '../../../sagas/combos/fetchEntitySubTypeCombo'
import { fetchEntityFilterSubTypeCombo } from '../../../sagas/combos/fetchEntityFilterSubTypeCombo'
import { fetchPurchaseDossierSuccess } from '../../../actions/dossier/purchase'
import { handleError } from '../../errors/errorManager'

export function * checkPurchaseTypeAndSetDefaultEntity ({ typeId, ouId }) {
  try {
    if (settingsEntityPurchase) {
      let foundConfig = settingsEntityPurchase.find(s => s.purchaseTypeId === typeId)
      if (foundConfig) {
        const auth = yield select(getAuth)
        let entity = yield call(getEntityByDni, foundConfig.DniCif, ouId, auth.token)
        yield call(fetchEntitySubTypeCombo, { entityTypeId: entity.entityTypeId })
        yield call(fetchEntityFilterSubTypeCombo, { entityTypeId: entity.entityTypeId, entitySubTypeId: entity.entitySubTypeId, organizedUnitId: ouId })
        const purchaseDossierEntity = yield select(state => state.purchaseDossier.entityComponent)
        entity.updated = true
        entity.autoLoadedEntity = true
        yield put(fetchPurchaseDossierSuccess({ entityComponent: { ...entity, previousEntity: purchaseDossierEntity.previousEntity } }))
        yield put(change('purchase_dossier', 'entityComponent', entity))
      }
    }
  } catch (error) {
    const sessionHandler = function * () {
      yield put(reset('purchase_dossier'))
    }
    yield call(handleError, { error, sessionHandler })
  }
}

export function * watchCheckPurchaseTypeAndSetDefaultEntity () {
  yield takeEvery(dossierActionsTypes.CHECK_PUCHASE_TYPE_SET_DEFAULT_ENTITY, checkPurchaseTypeAndSetDefaultEntity)
}
