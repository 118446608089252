import React, { PureComponent } from 'react'
import { Tabs, Tab, Table, Tooltip, OverlayTrigger, Button } from 'react-bootstrap'
import { urgencyTypesIdToUrgencyTabsId, urgencyTabsIdToUrgencyTypesId } from '../../../constants/backendIds'
import IDocCarPagination from '../../commons/iDocCarPagination'
import TaskListRow from './TaskListRow'

class TasksList extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      urgency: 0
    }
  }

  onSelectUrgencyTaskListTab (eventKey) {
    this.props.actions.fetchFilteredDashboardTask(
      this.props.activeTaskDescription,
      this.props.urgencyType,
      this.props.activeTaskId,
      this.props.orderby,
      1, // page to move when change task list tab
      this.props.pageSize,
      urgencyTabsIdToUrgencyTypesId[eventKey]
    )
    this.setState({ urgency: urgencyTabsIdToUrgencyTypesId[eventKey] })
  }

  onClickTaskListColumn (columnCode) {
    let columnCodeOrder = encodeURIComponent('+' + columnCode)
    if (columnCodeOrder === this.props.orderby) {
      columnCodeOrder = '-' + columnCode
    }
    this.props.actions.fetchFilteredDashboardTask(
      this.props.activeTaskDescription,
      this.props.urgencyType,
      this.props.activeTaskId,
      columnCodeOrder,
      this.props.page,
      this.props.pageSize,
      this.props.urgencyTypeTask
    )
  }

  selectTotalDossiersByUrgency (urgency, activeTaskValues) {
    let total = 0
    switch (urgency[0] || urgency) {
      case 0:
        if (activeTaskValues && activeTaskValues.total) {
          total = activeTaskValues.total
        }
        break
      case 2:
        if (activeTaskValues && activeTaskValues.totalHigh) {
          total = activeTaskValues.totalHigh
        }
        break
      case 3:
        if (activeTaskValues && activeTaskValues.totalMedium) {
          total = activeTaskValues.totalMedium
        }
        break
      case 4:
        if (activeTaskValues && activeTaskValues.totalLow) {
          total = activeTaskValues.totalLow
        }
        break
    }
    return total
  }

  exportTaskToExcel () {
    let totalDossiers = this.selectTotalDossiersByUrgency(this.props.urgencyTypeTask, this.props.activeTaskValues)
    this.props.actions.getFilteredTaskToExcel(this.props.activeTaskId, '', 1, totalDossiers, this.state.urgency)
  }

  render () {
    const {
      t,
      // tasks,
      history,
      urgencyType,
      activeTaskId,
      activeTaskDescription,
      activeTaskValues,
      orderby,
      page,
      pageSize,
      urgencyTypeTask,
      actions: { fetchFilteredDashboardTask, openDocumentEditionModal, closeAddOrEditDocumentModal, /* navigateTo, */ setActiveTab, setActiveSection, setScrollTo }
    } = this.props
    let totalDossiers = this.selectTotalDossiersByUrgency(urgencyTypeTask, activeTaskValues)
    const tKey = 'DASHBOARD_TASK.TASK_LIST.'
    if (!activeTaskId) {
      return (
        <div className="dashboard-tasks-panel-right">
          <h2 className="dashboard-tasks-title">{t(`${tKey}SELECT_TASK_MESSAGE`)}</h2>
        </div>
      )
    } else {
      return (
        <div className="dashboard-tasks-panel-right">
          <h2 className="dashboard-tasks-title">{activeTaskDescription}</h2>
          {totalDossiers > 0 &&
          <div className="text-right">
            <Button
              onClick={() => this.exportTaskToExcel()}
              bsStyle="default"
              bsSize="small">
              {t('SEARCH.ACTIONS.EXPORT_EXCEL')}
              <i className="ico-if_Download_1031520" />
            </Button>
          </div>}
          <div id="dashboard-tasks-table-wrapper" className="table-wrapper">
            {activeTaskValues.total !== null && (
              <div className="table-header">
                <Tabs
                  activeKey={urgencyTypesIdToUrgencyTabsId[urgencyTypeTask]}
                  onSelect={this.onSelectUrgencyTaskListTab.bind(this)}
                  id="groups-tabs"
                >
                  <Tab eventKey={0} title={`${t(`${tKey}URGENCY_TYPES.TOTAL`)} (${activeTaskValues.total})`}>
                  </Tab>
                  <Tab eventKey={1} title={`${t(`${tKey}URGENCY_TYPES.HIGH`)} (${activeTaskValues.totalHigh})`}>
                  </Tab>
                  <Tab eventKey={2} title={`${t(`${tKey}URGENCY_TYPES.MEDIUM`)} (${activeTaskValues.totalMedium})`}>
                  </Tab>
                  <Tab eventKey={3} title={`${t(`${tKey}URGENCY_TYPES.LOW`)} (${activeTaskValues.totalLow})`}>
                  </Tab>
                </Tabs>
              </div>
            )}
            <div className="table-body">
              <Table hover responsive>
                <thead>
                  <tr>
                    {
                      activeTaskValues.columns.map(column => {
                        const urgenciaTooltip = <Tooltip id="urgenciaTooltip">{t(`${tKey}TABLE.TOOLTIP.ORDER_BY_URGENCY`)}</Tooltip>
                        return (
                          <th
                            key={column.columnCode}
                            onClick={this.onClickTaskListColumn.bind(this, column.columnCode)}
                            className={column.columnType === 9 ? 'td-money' : ''}
                          >
                            {column.columnType !== 1 ? column.columnDescription : ''}&nbsp;
                            {
                              column.columnType === 1
                                ? (<OverlayTrigger placement="right" overlay={urgenciaTooltip}>
                                  <i className={(() => {
                                    let clase = 'ico-sort'
                                    if (orderby === '+' + column.columnCode) {
                                      clase += '-selected-asc'
                                    } else if (orderby === '-' + column.columnCode) {
                                      clase += '-selected-desc'
                                    }
                                    return clase
                                  })()} />
                                </OverlayTrigger>)
                                : (<i className={(() => {
                                  let clase = 'ico-sort'
                                  if (orderby === '+' + column.columnCode) {
                                    clase += '-selected-asc'
                                  } else if (orderby === '-' + column.columnCode) {
                                    clase += '-selected-desc'
                                  }
                                  return clase
                                })()} />)
                            }
                          </th>
                        )
                      })
                    }
                    <th key="showDetail"></th>
                  </tr>
                </thead>
                <tbody>
                  {activeTaskValues.rows &&
                    activeTaskValues.rows.map((row, idr) => {
                      const isLastPage = activeTaskValues.pagesCount === page
                      const currentPageSize = (isLastPage ? activeTaskValues.total % pageSize : pageSize)
                      return (
                        <TaskListRow
                          key={idr}
                          history={history}
                          idr={idr}
                          columns={activeTaskValues.columns}
                          row={row}
                          pageSize={currentPageSize}
                          navigationType={activeTaskValues.navigationType}
                          actions={{
                            openDocumentEditionModal,
                            closeAddOrEditDocumentModal,
                            /* navigateTo, */
                            setActiveTab,
                            setActiveSection,
                            fetchFilteredDashboardTask: () => fetchFilteredDashboardTask(activeTaskDescription, urgencyType, activeTaskId, orderby, page, pageSize, urgencyTypeTask),
                            setScrollTo
                          }}
                          t={t}
                        />
                      )
                    })
                  }
                </tbody>
              </Table>
            </div>
            {
              totalDossiers > 0 && activeTaskValues.pagesCount > 0 && (
                <div className="table-footer">
                  {
                    <IDocCarPagination
                      id="btn_pag_sales"
                      page={page}
                      pagesCount={activeTaskValues.pagesCount}
                      totalRows={totalDossiers}
                      label={t(`${tKey}TABLE.OF`)}
                      onSelectAction={(newPage) => fetchFilteredDashboardTask(activeTaskDescription, urgencyType, activeTaskId, orderby, newPage, pageSize, urgencyTypeTask)}
                    />
                  }
                </div>
              )
            }
          </div>
        </div>
      )
    }
  }
}

export default TasksList
