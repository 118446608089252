import React, { PureComponent } from 'react'
import { translateDocumentStatus, documentStatusClass, checklistStatusClass } from '../../../../../constants/dossier/common/documentStatus'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { documentEntityType as documentEntityTypeConstants } from '../../../../../constants/dossier/common/documentEntityType'
import Promise from 'promise-polyfill'

class DocumentThumbnail extends PureComponent {
  render () {
    const {
      readOnly,
      t,
      dossierId,
      dossierType,
      documentEntityType,
      objectId,
      readOnlyFields,
      documentFile: {
        documentId, checklistId, checklistStatus, documentStatus, showAddButton, addTypeDescription,
        showPrintButton, showCancelButton, documentTypeUse, iconUrl = 'generic-document.jpg'
      },
      actions: { openDocWarning, openDocumentEditionModalWithChecklistId, cancelDocument, addDependant, printCheckList, openModal }
    } = this.props
    // TODO print service for documents
    // TODO image from url key in documentFile
    const tKey = 'DOSSIER_COMPONENTS.DOCUMENTARY_MANAGEMENT.'
    const statusTooltip = (<Tooltip id="statusTooltip">{documentStatus && t(`${tKey}${translateDocumentStatus[documentStatus]}`)}</Tooltip>)
    const cancelTooltip = (<Tooltip id="cancelTooltip">{t(`${tKey}CANCEL`)}</Tooltip>)
    const printTooltip = (<Tooltip id="printTooltip">{t(`${tKey}PRINT`)}</Tooltip>)
    const dependantTooltip = (<Tooltip id="dependantTooltip">{t(`${tKey}ADD_DEPENDANT`) + addTypeDescription}</Tooltip>)
    return (
      <div
        style={{ borderColor: checklistStatusClass[checklistStatus] }}
        onClick={() => {
          if (readOnly) return
          if (this.props.formState !== undefined && this.props.formState === false && openDocWarning) {
            new Promise((resolve, reject) => {
              openDocWarning(resolve, reject)
            }).then(() => {
              if (objectId) {
                openDocumentEditionModalWithChecklistId(documentId, documentTypeUse, checklistId)
              } else {
                const alert = documentEntityType === documentEntityTypeConstants.ENTITY ? 'entityAlert' : 'vehicleAlert'
                openModal(alert)
              }
            })
          } else {
            if (objectId) {
              openDocumentEditionModalWithChecklistId(documentId, documentTypeUse, checklistId)
            } else {
              const alert = documentEntityType === documentEntityTypeConstants.ENTITY ? 'entityAlert' : 'vehicleAlert'
              openModal(alert)
            }
          }
        }} className="document-uploaded-modal"
      >
        <img src={`/images/document_types/${iconUrl}`}/>
        {!readOnlyFields && showCancelButton
          ? (<OverlayTrigger placement="right" overlay={cancelTooltip}>
            <i className="ico-cancel" onClick={(e) => {
              e.stopPropagation()
              cancelDocument(dossierType, documentId, documentEntityType, dossierId)
            }}/>
          </OverlayTrigger>
          ) : null
        }
        <span className="document-modal">
          <OverlayTrigger placement="right" overlay={statusTooltip}>
            <i id="documentStatus" className={'ico-document-' + documentStatusClass[translateDocumentStatus[documentStatus]]}/>
          </OverlayTrigger>
          {!readOnlyFields && showPrintButton && (<OverlayTrigger placement="right" overlay={printTooltip}><i onClick={(e) => {
            e.stopPropagation()
            checklistId && printCheckList(checklistId)
          }} className="ico-print"/></OverlayTrigger>)}
          {!readOnlyFields && showAddButton && (<OverlayTrigger placement="right" overlay={dependantTooltip}><i onClick={(e) => {
            e.stopPropagation()
            addDependant(documentId, documentEntityType)
          }} className="ico-document-plus"/></OverlayTrigger>)}
        </span>
      </div>
    )
  }
}

export default DocumentThumbnail
