import React, { Component } from 'react'
import { Field, reduxForm, reset } from 'redux-form'
import { Form, Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import InputDatePicker from '../commons/form/InputDatePicker'
import { validateDate } from '../../../util/validationFunctions'
import SimpleTablePage from '../../../containers/commons/general/SimpleTablePage'
import InputText from '../commons/form/InputText'
import InputSelect from '../commons/form/InputSelect'
import { permissions, massiveDownloadStates } from '../../../constants/backendIds'
import IDocCarPagination from '../../../components/commons/iDocCarPagination'
import { getFieldsSectionPermissions } from '../../../util/permisionFunctions'
import { convertStringToDate, getDateNowMaxDatePicker } from '../../../util/utils'
import { showFilter, hideFiltersBtn, toggleFilterClass/*, getIconClass */ } from '../../../_v2/util/management/ManagementUtils'
import MapSectionsSearch from '../../../_v2/util/map-sections-search'

class DocumentalSearchMassiveDownloadForm extends Component {
  constructor (props) {
    super()
    this.state = {
      page: 1,
      fieldsConfiguration: {
        massive_download_email: {
          permission: null
        }
      }
    }
  }

  initializePermisions (props) {
    if (props.sectionConfiguration) {
      const fieldKeys = Object.keys(this.state.fieldsConfiguration)
      const newState = getFieldsSectionPermissions(props.sectionConfiguration.sectionFieldsConfiguration, fieldKeys, props.sectionConfiguration.permission)
      this.setState({ fieldsConfiguration: newState.fieldsConfiguration })
    }
  }

  submitDocumentalSearchForm (values) {
    this.props.actions.findFilterMassiveDownload({ ...values, ...this.props.filters, page: this.state.page }, (!this.state.fieldsConfiguration.massive_download_email || this.state.fieldsConfiguration.massive_download_email.permission === permissions.hidden))
  }

  componentDidMount () {
    for (const dynamicFilter in this.props.dynamicFilters) {
      this.props.change(dynamicFilter, this.props.dynamicFilters[dynamicFilter])
    }

    this.initializePermisions(this.props)
    this.props.actions.fetchMassiveDownloadStatusCombo()
    this.props.actions.findFilterMassiveDownload({ ...this.props.filters, page: this.state.page }, (!this.state.fieldsConfiguration.massive_download_email || this.state.fieldsConfiguration.massive_download_email.permission === permissions.hidden))
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (prevProps.selectedTabDocument !== this.props.selectedTabDocument) {
      this.props.initialize({})
      const values = []
      this.props.actions.findFilterMassiveDownload({ ...values, ...this.props.filters, page: this.state.page }, (!this.state.fieldsConfiguration.massive_download_email || this.state.fieldsConfiguration.massive_download_email.permission === permissions.hidden))
    }
    if (prevProps.sectionConfiguration !== this.props.sectionConfiguration) {
      this.initializePermisions(this.props)
    }

    return null
  }

  componentDidUpdate () {
    // getSnapshotBeforeUpdate() should be used with componentDidUpdate()
  }

  dateValidation (value, allValues, props) {
    const result = validateDate(value, props)
    return result === Object(result) ? undefined : result
  }

  onOrderChange (e) {
    let newOrderBy = ''
    if (this.props.filters.orderBy && this.props.filters.orderBy.includes(`%2B${e.currentTarget.dataset.field}`)) {
      newOrderBy = `-${e.currentTarget.dataset.field}`
    } else {
      newOrderBy = `%2B${e.currentTarget.dataset.field}`
    }
    this.props.actions.findFilterMassiveDownload({ ...this.props.pages, ...this.props.count, ...this.props.dynamicFilters, orderBy: newOrderBy }, (!this.state.fieldsConfiguration.massive_download_email || this.state.fieldsConfiguration.massive_download_email.permission === permissions.hidden))
  }

  getIconClass (field) {
    if (this.props.filters.orderBy && this.props.filters.orderBy.includes(field)) {
      if (this.props.filters.orderBy.includes('-')) {
        return 'ico-sort-selected-asc'
      } else {
        return 'ico-sort-selected-desc'
      }
    } else {
      return 'ico-sort'
    }
  }

  downloadFile (massiveDownloadId) {
    this.props.actions.downloadMassiveFile(massiveDownloadId)
  }

  cancelFile (massiveDownloadId) {
    new Promise((resolve, reject) => {
      this.props.actions.cancelMassiveFile(massiveDownloadId, resolve)
    }).then((result) => {
      this.props.actions.findFilterMassiveDownload({ ...this.props.filters, page: this.state.page }, (!this.state.fieldsConfiguration.massive_download_email || this.state.fieldsConfiguration.massive_download_email.permission === permissions.hidden))
    })
  }

  toogleHideFilters () {
    this.props.actions.setHideFilters(!this.props.hideFilters)
  }

  toogleMaxiTable () {
    this.setState({ maxiTable: !this.state.maxiTable })
  }

  handleClickReset (e) {
    if (e !== undefined) {
      e.preventDefault()
    }

    this.props.dispatch(reset('documentalSearchMassiveDownloadForm'))
    this.setState({ onlyFleets: true, fields: [] })
  }

  render () {
    const {
      t, handleSubmit, massiveDownloadStatusCombo, massiveDowloadItems, pages, count, hideFilters,
      filters: { page }
    } = this.props
    const tKey = 'DOCUMENT_SEARCH.MASSIVE_DOWNLOAD.'
    const emailPermission = this.state.fieldsConfiguration.massive_download_email.permission
    const secMapSearch = MapSectionsSearch.find(function (s) { return s.page === 'massiveDownloadPage' })
    const hasResults = massiveDowloadItems && massiveDowloadItems.length > 0
    return (
      <div className="inner-results-panel">
        <div className="section-results-header">
          <div className="section-header-title">
            <OverlayTrigger placement="right" overlay={<Tooltip id="editProductTooltip">{`${t((this.props.hideMenu ? 'GLOBAL.TABLES.SHOW_INNER_MENU' : 'GLOBAL.TABLES.HIDE_INNER_MENU'))}`}</Tooltip>}>
              <a onClick={() => this.props.toogleInnerMenu()}s className={this.props.hideMenu ? 'collapseBtn' : 'collapseBtn closeInnerMenu'}><i className={this.props.hideMenu ? 'iDocIcon-Open-inner-menu' : 'iDocIcon-Close-inner-menu'}></i></a>
            </OverlayTrigger>
            <h4>{t(`${secMapSearch.title}`)}
              <OverlayTrigger placement="left" overlay={<Tooltip id="editProductTooltip">{`${t((hideFilters ? 'GLOBAL.TABLES.SHOW_FILTERS' : 'GLOBAL.TABLES.HIDE_FILTERS'))}`}</Tooltip>}>
                <a onClick={() => this.toogleHideFilters()} className={'toogleShowFilters ' + hideFiltersBtn(hasResults) }><i className={toggleFilterClass(hasResults, hideFilters)}></i></a>
              </OverlayTrigger>
            </h4>
          </div>
          <Form autoComplete="off" className="filters" onSubmit={handleSubmit(this.submitDocumentalSearchForm.bind(this))}>
            <div className={'search-form ' + (showFilter(hasResults, hideFilters) ? '' : 'hideFilters ') }>
              {/* <h4><Glyphicon glyph="filter" /> {t(`${tKey}TITLE_FINDER`)}</h4> */}
              <Field
                component={InputDatePicker}
                colWidht={33} // 25, 33, 50, 75, 100 default=25
                maxDate={getDateNowMaxDatePicker()}
                id="creationDateFrom"
                name="creationDateFrom"
                controlLabel={t(`${tKey}CREATION_DATE_FROM`)}
                placeholder={t(`${tKey}SINCE`)}
                validate={[this.dateValidation]}
              />
              <Field
                component={InputDatePicker}
                colWidht={33} // 25, 33, 50, 75, 100 default=25
                maxDate={getDateNowMaxDatePicker()}
                id="creationDateTo"
                name="creationDateTo"
                controlLabel={t(`${tKey}CREATION_DATE_TO`)}
                placeholder={t(`${tKey}UNTIL`)}
                validate={[this.dateValidation]}
              />
              <Field
                component={InputSelect}
                colWidht={33} // 25, 33, 50, 75, 100 default=25
                id="massiveDownloadStatesId"
                name="massiveDownloadStatesId"
                controlLabel={t(`${tKey}STATES`)}
                placeholder={t(`${tKey}STATES`)}
                multi={true}
                options={massiveDownloadStatusCombo}
                valueKey="id"
                labelKey="value"
              />
              <Field
                component={InputText}
                colWidht={33} // 25, 33, 50, 75, 100 default=25
                id="fileName"
                name="fileName"
                controlLabel={t(`${tKey}FILE_NAME`)}
                placeholder={t(`${tKey}FILE_NAME`)}
              />
              <Field
                component={InputText}
                colWidht={33} // 25, 33, 50, 75, 100 default=25
                id="emailUser"
                name="emailUser"
                controlLabel={t(`${tKey}EMAIL_USER`)}
                placeholder={t(`${tKey}EMAIL_USER`)}
                disabled = {!emailPermission || emailPermission.permission === permissions.hidden}
                customClass={!emailPermission || emailPermission.permission === permissions.hidden ? 'disabled' : ''}
              />
              <div className='search-form-actions flexInLine al-R'>
                <Button id="btn_search"
                  type="submit"
                  className="_Btn accept stk">
                  <i className="iDocIcon-search"/>
                  {t('SEARCH.ACTIONS.SEARCH_BUTTON')}
                </Button>
                <Button
                  id="btn_reset"
                  onClick={(e) => this.handleClickReset(e)}
                  className={'_Btn' + (this.props.pristine ? ' accept' : ' accept')}
                  disabled={this.props.pristine}>
                  <i className="iDocIcon-clear-decline"/>
                  {t('SEARCH.ACTIONS.RESET_BUTTON')}
                </Button>
              </div>
            </div>
          </Form>
        </div>
        <div className="section-results-body">
          <div className={'searchResoultsContainer' + (this.state.maxiTable ? ' maxiTable' : '')}>
            <div className="table-wrapper" key="table-wrapper">
              { hasResults &&
                <div className='flexInLine al-R'>
                  <OverlayTrigger placement="left" overlay={<Tooltip id="editProductTooltip">{`${t((this.state.maxiTable ? 'GLOBAL.TABLES.MINIMIZE_TABLE' : 'GLOBAL.TABLES.MAXIMIZE_TABLE'))}`}</Tooltip>}>
                    <a onClick={() => this.toogleMaxiTable()} className='_Btn accept stk o-icn maximizeBtn'><i className={(this.state.maxiTable ? 'iDocIcon-Minimize' : 'iDocIcon-Maximize')}></i></a>
                  </OverlayTrigger>
                  <OverlayTrigger placement="left" overlay={<Tooltip id="editProductTooltip">{t('SEARCH.ACTIONS.EXPORT')}</Tooltip>}>
                    <a onClick={(event) => this.exportData(event)} className='_Btn new o-icn exportToExcel'><i className="iDocIcon-file-excel"></i></a>
                  </OverlayTrigger>
                  {/* table */}
                </div>
              }
              {massiveDowloadItems && hasResults &&
                <div className="table-responsive">
                  <SimpleTablePage
                    columns={[
                      <th key={0} data-field="number" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}NUMBER`)} <i className={this.getIconClass('number')}/></th>,
                      <th key={1} data-field="fileName" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}FILE_NAME`)} <i className={this.getIconClass('requestedBy')}/></th>,
                      <th key={2} data-field="requestedBy" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}REQUESTED_BY`)} <i className={this.getIconClass('requestedBy')}/></th>,
                      <th key={3} data-field="creationDate" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}CREATION_DATE`)} <i className={this.getIconClass('creationDate')}/></th>,
                      <th key={4} data-field="expirationDate" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}EXPIRATION_DATE`)} <i className={this.getIconClass('expirationDate')}/></th>,
                      <th key={5} data-field="state" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}STATE`)} <i className={this.getIconClass('state')}/></th>,
                      <th key={6} style={{ width: '30%' }} data-field="observations" >{t(`${tKey}OBSERVATIONS`)} </th>,
                      <th key={7} className="actions" ></th>
                    ]}
                    rows={massiveDowloadItems.map((row, idx) => (
                      <tr key={idx}>
                        <td>{row.massiveDownloadId}</td>
                        <td>{row.fileName}</td>
                        <td>{row.requestUserEmail}</td>
                        <td>{row.requestDate}</td>
                        <td>{row.expirationDate}</td>
                        {row.hasError ? <td className={'has_error'}>{row.statusName}</td> : <td>{row.statusName}</td>}
                        {row.hasError ? <td className={'has_error'}>{row.observations}</td> : <td>{row.observations}</td>}
                        <td className="actions">
                          <div className='content'>
                            {// row.statusId !== massiveDownloadStates.READY &&
                              <OverlayTrigger placement="left" overlay={<Tooltip id="tooltipRemoved">{t(`${tKey}DOWNLOAD`)}</Tooltip>}>
                                <Button className={'_Btn stk o-icn noLine ' + (row.statusId !== massiveDownloadStates.READY ? 'accept' : 'cancel disabled')}
                                  onClick={(event) => this.downloadFile(row.massiveDownloadId)}
                                  disabled={row.statusId !== massiveDownloadStates.READY}>
                                  <i className='iDocIcon-cloud-download-alt-solid'></i>
                                </Button>
                              </OverlayTrigger>
                            }
                            <OverlayTrigger placement="left" overlay={<Tooltip id="tooltipRemoved">{t(`${tKey}REMOVE`)}</Tooltip>}>
                              <Button className="_Btn cancel stk o-icn noLine"
                                onClick={(event) => this.cancelFile(row.massiveDownloadId)}
                                disabled={row.statusId !== massiveDownloadStates.READY && row.statusId !== massiveDownloadStates.PENDING}>
                                <i className="ico-cancel"/>
                              </Button>
                            </OverlayTrigger>
                          </div>
                        </td>
                      </tr>
                    ))}
                  />
                </div>
              }
              <div className="search-footer">
                {hasResults ? (
                  <IDocCarPagination
                    id="btn_pag_sales"
                    page={page}
                    pagesCount={pages}
                    totalRows= {count}
                    onSelectAction={(page) => this.props.actions.findFilterMassiveDownload({ items: massiveDowloadItems, pages, count, ...this.props.dynamicFilters, page })}/>
                ) : <p className='no_results'>{t('GLOBAL.TABLES.NO_RESULTS')}</p>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const validate = (values, { t }, props) => {
  const error = {}

  if (values && values.creationDateFrom && values.creationDateTo) {
    let dateTo = convertStringToDate(values.creationDateTo)
    let dateFrom = convertStringToDate(values.creationDateFrom)
    if (dateFrom > dateTo) {
      error.creationDateTo = t('GLOBAL.FORMS.DATE_TO_NOT_LESS_THAN_DATE_FROM')
    }
  }
  return error
}

export default reduxForm({
  form: 'documentalSearchMassiveDownloadForm',
  validate
})(DocumentalSearchMassiveDownloadForm)
