export const unattendedProcessType = {
  UPLOAD_DOCUMENT_FLEET: 1,
  ACTION_SALE_DATA: 2,
  ACTION_EXP_FLEET: 3,
  APPROVAL_ACTION_FLEET: 4,
  FINANCIAL_ANALYSIS_SECTION: 5,
  UPLOAD_DIFERENT_DOCUMENT_SECTION: 6,
  OPERATE_ACTION_SEND_TO_APPROVAL: 7,
  OPERATE_ACTION_APPROVE: 8,
  OPERATE_DATA_SALE_FLEET: 9,
  OPERATE_ACTION_APPROVE_PAPERWORK: 10,
  OPERATE_ACTION_SEND_TO_AGENCY: 11,
  OPERATE_ACTION_REJECT: 13,
  OPERATE_DATA_PAPERWORK_FLEET: 14,
  OPERATE_ACTION_STORE: 15,
  OPERATE_ACTION_DELETE: 16,
  OPERATE_DATA_FINANCING_FLEET: 17,
  INCORPORATE_DOCUMENT_FLEET: 18,
  VALIDATE_DOCUMENT_FLEET: 20,
  REEDIT_DOCUMENT_FLEET: 21,
  CANCEL_DOCUMENT_FLEET: 22,
  REQUEST_SIGN_FLEET_DOCUMENT: 23,
  OPERATE_DATA_DELIVERY_FLEET: 24,
  OPERATE_DATA_SERVICE_FLEET: 25,
  FLEET_LOAD_DOSSIERS_FROM_EXCEL: 26,
  OPERATE_ACTION_PRINT_DELIVERY_NOTE: 27,
  CANCEL_SIGN_FLEET_DOCUMENT: 30,
  REJECT_FLEET_DOCUMENT: 31,
  DELETE_FLEET: 28,
  IMPORT_SEPOMEX: 32,
  MOVE_FABRIC_INVOICE_PROCESS: 33,
  AUDIT: 34,
  DOWNLOAD_MASIVE: 35
}
