import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import ComponentTitle from '../../../../_v2/components/dossiers/common/ComponentTitle'

export function mapStateToProps (state) {
  return {
    ...state.PrivateRoute
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({

    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(ComponentTitle))
