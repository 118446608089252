import { call, put, select, takeLatest } from 'redux-saga/effects'
import { getAuth } from '../../selectors/access/auth'
import getCampaignType from '../../services/campaignType/getCampaignType'
import getCampaignTypeByGroup from '../../services/campaignType/getCampaignTypeByGroup'
import getCampaignClass from '../../services/campaignType/getCampaignClass'
import getCampaignGroup from '../../services/campaignType/getCampaignGroup'
import { showLoader, hideLoader } from '../../actions/common.js'
import messageModalActions from '../../actions/modals/messageModal'
import { fetchCampaignTypeSuccess, fetchCampaignTypeByGroupSuccess, fetchCampaignClassSuccess, fetchCampaignGroupSuccess } from '../../actions/combos/combos'
import combosActionTypes from '../../constants/actions/combos/combos'
import { handleError } from '../errors/errorManager'

export function * fetchCampaignType ({ ouId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const response = yield call(getCampaignType, auth.token)
    yield put(fetchCampaignTypeSuccess(response))
  } catch (error) {
    const customHandler = function * () {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.FETCH_COMBO_ERROR'))
    }
    yield call(handleError, { error, customHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchCampaignType () {
  yield takeLatest(combosActionTypes.FETCH_CAMPAIGN_TYPE, fetchCampaignType)
}

export function * fetchCampaignTypeByGroup ({ groupId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const response = yield call(getCampaignTypeByGroup, auth.token, groupId)
    yield put(fetchCampaignTypeByGroupSuccess(response))
  } catch (error) {
    const customHandler = function * () {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.FETCH_COMBO_ERROR'))
    }
    yield call(handleError, { error, customHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchCampaignTypeByGroup () {
  yield takeLatest(combosActionTypes.FETCH_CAMPAIGN_TYPE_BY_GROUP, fetchCampaignTypeByGroup)
}

export function * fetchCampaignClass () {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const response = yield call(getCampaignClass, auth.token)
    yield put(fetchCampaignClassSuccess(response))
  } catch (error) {
    const customHandler = function * () {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.FETCH_COMBO_ERROR'))
    }
    yield call(handleError, { error, customHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchCampaignClass () {
  yield takeLatest(combosActionTypes.FETCH_CAMPAIGN_CLASS, fetchCampaignClass)
}

export function * fetchCampaignGroup ({ classId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const response = yield call(getCampaignGroup, auth.token, classId)
    yield put(fetchCampaignGroupSuccess(response))
  } catch (error) {
    const customHandler = function * () {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.FETCH_COMBO_ERROR'))
    }
    yield call(handleError, { error, customHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchCampaignGroup () {
  yield takeLatest(combosActionTypes.FETCH_CAMPAIGN_GROUP, fetchCampaignGroup)
}
