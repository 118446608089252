import buttonsHeaderActionTypes from '../../../constants/actions/dossier/common/buttonsHeader'

export function initialState () {
  return {
    activeTab: 1,
    height: 0
  }
}

function setActiveTabSuccess (state, { activeTab }) {
  return {
    ...state,
    activeTab
  }
}

function setButtonsHeaderHeight (state, { height }) {
  return {
    ...state,
    height
  }
}

function resetButtonsHeader (state) {
  return initialState()
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case buttonsHeaderActionTypes.SET_ACTIVE_TAB:
      return setActiveTabSuccess(state, action)
    case buttonsHeaderActionTypes.SET_BUTTONS_HEADER_HEIGHT:
      return setButtonsHeaderHeight(state, action)
    case buttonsHeaderActionTypes.RESET_BUTTONS_HEADER:
      return resetButtonsHeader(state, action)
    default:
      return state
  }
}
