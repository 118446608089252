import { push } from 'connected-react-router'
import { initialize } from 'redux-form'
import { call, put, select, takeEvery } from 'redux-saga/effects'
import { hideLoader, showLoader } from '../../../actions/common'
import { fetchNonUpdatedDossiersSuccess, closeNonUpdatedDossiers, openNonUpdatedDossiers } from '../../../actions/dossier/common/nonUpdatedDossiers'
import nonUpdatedDossiers from '../../../constants/actions/dossier/common/nonUpdatedDossiers'
import { getAuth } from '../../../selectors/access/auth'
import putDelayNonUpdatedDossierAlert from '../../../services/dossier/put/putDelayNonUpdatedDossierAlert'
import findNonUpdatedDossiersTable from '../../../services/dossierFinder/findNonUpdatedDossiersTable'
import { handleError } from '../../errors/errorManager'

export function * fetchNonUpdatedDossiers ({ filters }, resolve, reject) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    if (auth.nonUpdatedSalesControl) {
      const result = yield call(findNonUpdatedDossiersTable, auth.token, filters)
      if (!(result?.items) || result.items.length === 0) {
        yield put(closeNonUpdatedDossiers())
        yield put(push('/dashboard'))
      } else {
        yield put(fetchNonUpdatedDossiersSuccess(result, filters))
        yield put(openNonUpdatedDossiers())
        yield put(initialize('nonUpdatedDossiers', {}))
      }
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchNonUpdatedDossiers () {
  yield takeEvery(nonUpdatedDossiers.FETCH_NON_UPDATED_DOSSIERS, fetchNonUpdatedDossiers)
}

export function * delayNonUpdatedDossierAlert ({ dossierId, notifyData, resolve, reject }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(putDelayNonUpdatedDossierAlert, auth.token, dossierId, notifyData)
    if (resolve) resolve()
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDelayNonUpdatedDossierAlert () {
  yield takeEvery(nonUpdatedDossiers.DELAY_NON_UPDATED_DOSSIER, delayNonUpdatedDossierAlert)
}
