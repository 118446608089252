import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { fetchTemplateTypes } from '../../actions/combos/combos'
import { fetchTemplateList, setActiveTemplate, duplicateTemplate, deleteTemplate, openAddTemplateModal, openEditTemplateModal, resetTemplate } from '../../actions/templates/templates'
import Templates from '../../components/templates/Templates'
import TrackingContainer from '../../containers/tracking/trackingContainer'

export function mapStateToProps (state) {
  return {
    ...state.templates,
    combos: {
      templateTypesCombo: state.combos.templateTypesCombo
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchTemplateTypes,
      fetchTemplateList,
      setActiveTemplate,
      duplicateTemplate,
      deleteTemplate,
      openAddTemplateModal,
      openEditTemplateModal,
      resetTemplate
    }, dispatch)
  }
}
var decoratedComponent = TrackingContainer(Templates, true)
export default connect(mapStateToProps, mapDispatchToProps)(translate()(decoratedComponent))
