import React, { PureComponent } from 'react'
import { OverlayTrigger, Overlay, Popover, Tooltip, Button, Glyphicon } from 'react-bootstrap'
import { translateDocumentStatus, documentStatusClass } from '../../../constants/dossier/common/documentStatus.js'
import PopoverItem from '../../containers/search/PopoverItemPage.js'
class DocumentPopover extends PureComponent {
  constructor () {
    super()
    this.state = {
      showPopover: false,
      target: this
    }
  }

  handleClick (e) {
    e.stopPropagation()
    this.setState({ target: e.target, showPopover: true })
  }

  render () {
    const {
      t,
      id,
      document = {}
    } = this.props
    const statusTooltipTKey = 'DOSSIER_COMPONENTS.DOCUMENTARY_MANAGEMENT.'
    const statusTooltip = (<Tooltip id="statusTooltip">{document.status && t(`${statusTooltipTKey}${translateDocumentStatus[document.status]}`)}</Tooltip>)
    return (
      <div>
        {Object.keys(document).length > 0 &&
          <Button bsSize="xsmall" className="icon-only" onClick={this.handleClick.bind(this)}><Glyphicon glyph="plus"/></Button>
        }
        <OverlayTrigger placement="right" overlay={statusTooltip}>
          <i id="documentStatus" className={'ico-document-' + documentStatusClass[translateDocumentStatus[document.status]]}/>
        </OverlayTrigger>
        {document.description}
        <Overlay
          container={this}
          placement='bottom'
          show={this.state.showPopover}
          target={this.state.target}
          onHide={() => this.setState({ showPopover: false })}
          rootClose
        >
          <Popover id={id} className="_nf-popover">
            {document.creationDate &&
            <PopoverItem itemType={'doc_01'} data={document.creationDate}></PopoverItem>
            }
            {document.creationUser &&
            <PopoverItem itemType={'doc_02'} data={document.creationUser}></PopoverItem>
            }
            {document.documentTypeUse &&
            <PopoverItem itemType={'doc_03'} data={document.documentTypeUse}></PopoverItem>
            }
          </Popover>
        </Overlay>
      </div>
    )
  }
}

export default DocumentPopover
