import maritalStatusMasterActionTypes from '../../../constants/actions/masters/maritalStatus/maritalStatusMaster'

export function fetchMaritalStatusList (filter) {
  return {
    type: maritalStatusMasterActionTypes.FETCH_MARITALSTATUS_LIST,
    filter
  }
}

export function fetchMaritalStatusListSuccess (maritalStatusList, filter) {
  return {
    type: maritalStatusMasterActionTypes.FETCH_MARITALSTATUS_LIST_SUCCESS,
    maritalStatusList,
    filter
  }
}

export function deleteMaritalStatus (maritalStatusId) {
  return {
    type: maritalStatusMasterActionTypes.DELETE_MARITALSTATUS,
    maritalStatusId
  }
}

export function openMaritalStatusModal (maritalStatus) {
  return {
    type: maritalStatusMasterActionTypes.OPEN_MARITALSTATUS_MODAL,
    maritalStatus
  }
}

export function openMaritalStatusModalSuccess (maritalStatus, languageList) {
  return {
    type: maritalStatusMasterActionTypes.OPEN_ADD_MARITALSTATUS_MODAL_SUCCESS,
    maritalStatus,
    languageList
  }
}

export function openEditMaritalStatusModal (maritalStatus) {
  return {
    type: maritalStatusMasterActionTypes.OPEN_EDIT_MARITALSTATUS_MODAL,
    maritalStatus
  }
}

export function openEditMaritalStatusModalSuccess (maritalStatus, languageList) {
  return {
    type: maritalStatusMasterActionTypes.OPEN_EDIT_MARITALSTATUS_MODAL_SUCCESS,
    maritalStatus,
    languageList
  }
}

export function closeMaritalStatusMasterModal () {
  return {
    type: maritalStatusMasterActionTypes.CLOSE_EDIT_MARITALSTATUS_MODAL
  }
}

export function submitMaritalStatus (maritalStatus) {
  return {
    type: maritalStatusMasterActionTypes.SUBMIT_MARITALSTATUS,
    maritalStatus
  }
}
