import React, { PureComponent } from 'react'
import { Button, Modal, Col } from 'react-bootstrap'
import { reduxForm } from 'redux-form'
import Finance from '../../dossiers/sales/Finance'
import { validateFinancingModal } from '../../../util/validationFunctions'

class DataFinancingFleetModal extends PureComponent {
  constructor () {
    super()
    this.state = {}
  }

  saveData (values) {
    let dossierList = this.props.dossiers.map(x => { return x.dossierId })
    this.props.actions.setOperateDataFinancingFleetProcess(dossierList, values.dossierFinancingComponent, this.props.fleet.fleetId, () => this.props.actions.closeDataFinancingFleetModal())
  }

  componentDidUpdate () {}

  getSnapshotBeforeUpdate (prevProps) {
    if (this.props.showModal && !prevProps.showModal) {
      this.props.initialize()
      this.props.actions.fetchOperateDataFleetModalsPermissions(this.props.fleet.dossierSubType, this.props.organizedUnitId, this.props.stageId)
    }
    return null
  }

  render () {
    const {
      t,
      showModal, handleSubmit, pristine, actions: {
        fetchSalesFinancing, removeFinance, cleanFinance, fetchFinancingType,
        getFinancingTypeProductByFinancingType, openCommonModal, setActiveSection, closeDataFinancingFleetModal
      }, change, componentConfiguration, dossier, financingTypeProduct
    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.FINANCE.'
    // const saveSalePermission = buttonsPermission.includes(buttonsPermissions.sale_save)

    if (showModal && componentConfiguration) {
      return (
        <Modal className="fleet-created-select-modal languages-modal" show={showModal} onHide={closeDataFinancingFleetModal} backdrop={'static'}>
          <form className='formDataSalesFleet' autoComplete="off" onSubmit={handleSubmit((values) => this.saveData(values))}>
            <Modal.Header closeButton onHide={this.props.actions.closeActionDataModal}>
              <Modal.Title>  <i className="ico-sales" />{t(`${tKey}TITLE_PANEL`)}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Finance
                t={t}
                pristine={pristine}
                handleToUpdateComponent = {() => false}
                isFinanceLoaded = {this.state.isFinanceLoaded}
                historical={false}
                dossierId={this.props.dossier.dossierId}
                combos={this.props.combos}
                open={true}
                componentConfiguration={componentConfiguration}
                dossier={dossier}
                financingTypeProduct = {financingTypeProduct}
                redate = {false}
                actions={{
                  change,
                  fetchSalesFinancing,
                  removeFinance,
                  cleanFinance,
                  fetchFinancingType,
                  getFinancingTypeProductByFinancingType,
                  openCommonModal,
                  setActiveSection
                }}
                isFleet={true}
              />
            </Modal.Body>
            <Modal.Footer>
              <Col sm={12} className='fleet-created-moda-btn-exit'>
                <Button
                  id="btn_search"
                  className="btn-standard"
                  type='submit'
                  disabled={this.props.pristine}>
                  {t('MANAGEMENT.FLEETS_TAB.AUX_MODALS.SAVE')}
                </Button>
                <Button
                  id='btn-close-fleet-created-select-charge-modal'
                  className="btn-exit"
                  onClick={() => closeDataFinancingFleetModal()}>
                  {t('MANAGEMENT.FLEETS_TAB.AUX_MODALS.EXIT')}
                </Button>
              </Col>
            </Modal.Footer>
          </form>
        </Modal>

      )
    } else {
      return null
    }
  }
}

const validate = (values, props) => {
  let errors = {}
  const dossierFinancingComponent = validateFinancingModal(values, props)
  if (Object.keys(dossierFinancingComponent).length > 0) {
    errors.dossierFinancingComponent = dossierFinancingComponent
  }
  return errors
}

export default reduxForm({
  form: 'dataFinancingFleetModal',
  validate
})(DataFinancingFleetModal)
