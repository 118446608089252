import React, { PureComponent } from 'react'
import { Col, Row, Panel, Form, Table } from 'react-bootstrap'
import { Element } from 'react-scroll'
import StatusHeaderPage from '../../../containers/dossierHistorical/common/StatusHeaderPage'
import ButtonsHeader from '../../dossiers/common/ButtonsHeader'
import ButtonsSidebar from '../../dossiers/common/ButtonsSidebar'
import EditableTable from '../../commons/editableTable/EditableTable'
import SalesComponent from './SalesComponent'
import { dossierTypes as dossierTypesConstant } from '../../../constants/dossier/common/dossierTypes'
import { compareObjects } from '../../../util/utils'
import { getComponentsPermisions } from '../../../util/permisionFunctions'
import { buttonsHeader } from '../../../constants/dossier/common/buttonsHeader'
import { buttonsSidebar } from '../../../constants/dossier/common/buttonsSidebar'
import { urgencyTypesClass, permissions } from '../../../constants/backendIds'
import Client from '../common/Client'
import Vehicle from '../common/Vehicle'
import DocumentaryManagementPage from '../../../containers/dossierHistorical/common/DocumentaryManagementPage'
import RejectCommentModal from '../common/RejectCommentModal'
import UAC from '../common/UAC'
import PaperworkTablePage from '../../../containers/dossierHistorical/common/PaperworkComponentPage'
import Delivery from '../common/Delivery'
import { reduxForm } from 'redux-form'
import SmallLoaderPage from '../../../containers/commons/SmallLoaderPage'
import AuditInfo from '../../dossiers/common/audit/AuditInfo'
import { Link } from 'react-router-dom'
import TrackingContainer from '../../../containers/tracking/trackingContainer'
import { TrackedPages } from '../../../constants/tracking/TrackedPages'
import Charges from '../sale/Charges'

class RegistrationDeliveryHistorical extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      modalOpen: true,
      isVehicleLoaded: false,
      isDeliveryLoaded: false,
      isFinanceLoaded: false,
      isClientLoaded: false,
      showRejectCommentModal: false,
      accessoriesOpen: false,
      optionalsOpen: false,
      noRecoverableCostsOpen: false,
      discountsOpen: false,
      servicesOpen: false,
      activeTabConfiguration: null,
      headerTabConfiguration: null,
      isOtherDataLoaded: false,
      isEconomicLoaded: false,
      componentsConfiguration: {
        bsale: null,
        entity: null,
        vehicle: null,
        campaign: null,
        uac: null,
        finance: null,
        paperwork: null,
        charge: null,
        other_data: null,
        delivery: null
      }
    }
    this.goBack = this.goBack.bind(this)
  }

  goBack () {
    this.props.actions.setActiveTab(buttonsHeader.datos_expediente)
    this.props.history.goBack()
  }

  toggleRejectCommentModal (showRejectCommentModal) {
    this.setState({ showRejectCommentModal })
  }

  onClickRelatedDossierColumn (columnCode) {
    let columnCodeOrder = '+' + columnCode
    if (columnCodeOrder === this.props.relatedDossiersOrderBy) {
      columnCodeOrder = '-' + columnCode
    }
    this.props.actions.fetchFilteredRelatedDossier(this.props.dossier.dossierId, columnCodeOrder, true)
  }

  getRelatedDossierColumnIcon (columnCode) {
    if ('+' + columnCode === this.props.relatedDossiersOrderBy) {
      return 'ico-sort-selected-asc'
    } else if ('-' + columnCode === this.props.relatedDossiersOrderBy) {
      return 'ico-sort-selected-desc'
    } else {
      return 'ico-sort'
    }
  }

  submitRegistrationDeliveryDossier (values) {
    this.props.actions.saveRegistrationDeliveryDossier(values)
  }

  fetchRegistrationDeliveryDossierPromise (dossierId) {
    return new Promise((resolve, reject) => {
      return this.props.actions.fetchRegistrationDeliveryDossier(dossierId, resolve, reject, true)
    })
  }

  initializePermisions (props) {
    const componentKeys = Object.keys(this.state.componentsConfiguration)
    const newState = getComponentsPermisions(props.dossier.viewConfiguration.tabsConfiguration, props.activeTab, componentKeys)
    this.setState({ ...this.state, ...newState })
  }

  componentDidMount () {
    this.props.actions.fetchCommercialSocietyTypeCombo()
    this.props.actions.restartRegistrationDeliveryState()
    this.fetchRegistrationDeliveryDossierPromise(this.props.match.params.dossierId).then(() => {
      const form = {
        ...this.props.dossier,
        entityComponent: this.props.dossier.entityComponent.entityId ? this.props.dossier.entityComponent : undefined
      }
      this.props.initialize(form)
      this.initializePermisions(this.props)
      this.props.actions.fetchRegistrationDeliveryDossierDependants(this.props.match.params.dossierId, true)
      this.props.actions.fetchCustomFields(this.props.dossier.dossierSubTypeId)
      this.props.actions.registerLastAccess(this.props.match.params.dossierId, true)
      this.props.trackVisitPage({
        documentTitle: TrackedPages.REGISTRATION_DELIVERY_HISTORICAL,
        organizedUnitId: this.props.dossier.organizedUnitId,
        href: window.location.href
      })
    })
  }

  componentDidUpdate (prevProps) {
    if ((this.props.showManagementModal && !prevProps.showManagementModal) || this.props.match.params.dossierId !== prevProps.match.params.dossierId) {
      if (this.props.match.params.dossierId !== prevProps.match.params.dossierId) {
        this.props.actions.restartRegistrationDeliveryState()
        this.props.actions.setScrollTo(buttonsSidebar.bsale)
        this.props.actions.setActiveSection(buttonsSidebar.bsale)
        this.props.actions.setActiveTab(buttonsHeader.datos_expediente)
        this.props.actions.closeAddOrEditDocumentModal()
      }
      this.fetchRegistrationDeliveryDossierPromise(this.props.match.params.dossierId).then(() => {
        const form = { ...this.props.dossier }
        if (!this.props.dossier.entityComponent.entityId) delete form.entityComponent
        this.props.initialize(form)
        this.initializePermisions(this.props)
        this.props.actions.fetchRegistrationDeliveryDossierDependants(this.props.match.params.dossierId, true)
      })
    }

    if ((this.props.dossier && this.props.dossier.viewConfiguration && this.props.dossier.viewConfiguration.tabsConfiguration &&
      !compareObjects(prevProps.dossier.viewConfiguration.tabsConfiguration || {}, this.props.dossier.viewConfiguration.tabsConfiguration || {}, true)) ||
      (prevProps.dossier.dossierId && prevProps.activeTab !== this.props.activeTab)) {
      this.initializePermisions(this.props)
    }

    if (this.props.dossier.dossierId && this.props.statusHeaderHeight && this.props.activeSection) {
      switch (this.props.activeSection) {
        case buttonsSidebar.accessory:
          this.setState({ accessoriesOpen: true })
          break
        case buttonsSidebar.optional:
          this.setState({ optionalsOpen: true })
          break
        case buttonsSidebar.no_recover:
          this.setState({ noRecoverableCostsOpen: true })
          break
        case buttonsSidebar.discount:
          this.setState({ discountsOpen: true })
          break
        case buttonsSidebar.service:
          this.setState({ servicesOpen: true })
          break
      }
    }

    const firstCustomFieldsLoad = prevProps.dossier.customFieldsByComponent?.length < this.props.dossier.customFieldsByComponent?.length
    if (firstCustomFieldsLoad) {
      let customFields = {}
      if (this.props.dossier.customFieldsValues) {
        this.props.dossier.customFieldsValues.map(x => {
          customFields[x.key] = x.value
        })
      }
      const form = {
        ...this.props.dossier,
        customFields: customFields,
        entityComponent: this.props.dossier.entityComponent.entityId ? this.props.dossier.entityComponent : undefined
      }
      this.props.initialize(form)
    }
  }

  componentWillUnmount () {
    this.props.actions.setActiveSection(buttonsSidebar.bsale)
    this.props.actions.setScrollTo(null)
    this.props.actions.resetButtonsHeader()
    this.props.actions.closeAddOrEditDocumentModal()
    this.props.actions.restartRegistrationDeliveryState()
  }

  handleToUpdateComponent (component) {
    if (component.isVehicleLoaded === false || buttonsSidebar.vehicle === component) this.setState({ isVehicleLoaded: true })
    if (component.isClientLoaded === false || buttonsSidebar.entity === component) this.setState({ isClientLoaded: true })
    if (component.isFinanceLoaded === false || buttonsSidebar.finance === component) this.setState({ isFinanceLoaded: true })
    if (component.isDeliveryLoaded === false || buttonsSidebar.delivery === component) this.setState({ isDeliveryLoaded: true })
    if (component.isOtherDataLoaded === false || buttonsSidebar.other_data === component) this.setState({ isOtherDataLoaded: true })
    if (component.isEconomicLoaded === false) this.setState({ isEconomicLoaded: true })
    this.props.actions.setScrollTo(component)
  }

  togglePanel () {
    if (this.state.registrationDeliveryOpen) {
      this.props.actions.setActiveSection(false)
    }
    this.setState({ registrationDeliveryOpen: !this.state.registrationDeliveryOpen })
  }

  render () {
    const {
      t, handleSubmit, dossier, combos = {}, change, pristine, vehicleProps, activeTab, buttonsHeaderHeight, activeSection, statusHeaderHeight, windowWidth, documentExport,
      dossier: {
        dossierId, dossierSubTypeId, header, entityChangeable,
        checkListHeader, comments, uac, paperWorks, organizedUnitId, purchasesDossierId,
        dossierCompleteDeliveryCheckListItems, dossierDeliveryCheckListPurchase, showAlertSendPending,
        viewConfiguration, viewConfiguration: { buttonsPermission = [] },
        vehicleComponent: { vehicleId }, dossierRelatedDossiers, auditInfo, order, charges, isPreCancelled
      },
      actions: {
        openDocumentModal,
        updateDependantsSaleDossierAndHeader,
        fetchOperationType,
        postPaymentSignalStatusSuccess,
        openDocumentEditionModal,
        fetchOrder,
        fetchRegistrationDeliveryDossierDependants,
        setActiveTab, setButtonsHeaderHeight, resetButtonsHeader,
        setActiveSection,
        setScrollerOptions,
        /* navigateTo, */
        fetchComboOrigin,
        fetchComboDestination,
        refreshAuditInfo,
        fetchAuditDetails,
        fetchAuditDetailsModal,
        fetchDocumentsGroupedAuditDetails,
        fetchDocumentsGroupedAuditDetailsModal,
        fetchRegistrationDeliveryDeliveryHistorical,
        fetchRegistrationDeliveryFinancingHistorical,
        fetchRegistrationDeliveryVehicleHistorical,
        fetchRegistrationDeliveryClientHistorical,
        saveSelectedDocuments,
        fetchRegistrationDeliveryEconomic,
        fetchEntityCountryTypeCombo, fetchEntityStateTypeCombo,
        fetchEntityCityTypeCombo, fetchEntityTownTypeCombo,
        fetchEntityColonyTypeCombo, fetchEntityCPTypeCombo, openCommonModal,
        fetchCommercialSocietyTypeCombo,
        fetchActivityBpro,
        fetchClientDataAddress
      }
    } = this.props

    if (!dossierId) return null

    const saveRegistrationDeliveryPermission = buttonsPermission.includes('registrationDelivery_save')
    const tKey = 'DOSSIER_COMPONENTS.'
    const panelsPaddingTop = statusHeaderHeight + buttonsHeaderHeight - (statusHeaderHeight + buttonsHeaderHeight - 82) // 82px es el paddingTop definido en el layout.

    return (
      <div className="dossier-sales-container">
        <Col sm={12} className="add-dossier-wrapper dossier">
          <StatusHeaderPage
            headerData={header}
            stages={checkListHeader}
            dossierType={dossierTypesConstant.registrationDelivery}
            headerTabConfiguration={this.state.headerTabConfiguration}
            goBack={this.goBack}
            dossierId={dossier.dossierId}
          />
        </Col>
        <RejectCommentModal
          t={t}
          showModal={this.state.showRejectCommentModal}
          actions={{
            closeModal: () => this.toggleRejectCommentModal(false)
          }}
        />
        <Form autoComplete="off" onSubmit={handleSubmit(this.submitRegistrationDeliveryDossier.bind(this))}>
          <div className="row-header-buttons">
            <div className="buttons-header-box">
              <ButtonsHeader
                t={t}
                pristine={pristine}
                actions={{
                  openDocumentModal: () => openDocumentModal(dossierId, dossierTypesConstant.registrationDelivery, pristine, 1, true),
                  setActiveTab,
                  setButtonsHeaderHeight,
                  resetButtonsHeader,
                  setScrollerOptions
                }}
                dossierId={this.props.match.params.dossierId}
                viewConfiguration={viewConfiguration}
                dossierType={dossierTypesConstant.registrationDelivery}
                activeTab={activeTab}
                marginTop={statusHeaderHeight + buttonsHeaderHeight}
                windowWidth={windowWidth}
                historical={true}
              />
            </div>
          </div>
          {
            activeTab === buttonsHeader.datos_expediente && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden && (
              <Row className="sales-panel dossier-panel">
                <Col sm={3} className="col-row expediente-menu-izq" style={{ paddingTop: panelsPaddingTop }}>
                  <ButtonsSidebar
                    t={t}
                    dossierId={dossierId}
                    historical={true}
                    isClientLoaded={this.state.isClientLoaded}
                    isFinanceLoaded={this.state.isFinanceLoaded}
                    isDeliveryLoaded={this.state.isDeliveryLoaded}
                    isVehicleLoaded={this.state.isVehicleLoaded}
                    isOtherDataLoaded={this.state.isOtherDataLoaded}
                    isEconomicLoaded={this.state.isEconomicLoaded}
                    dossierType={dossierTypesConstant.registrationDelivery}
                    dossierSubTypes={dossierSubTypeId}
                    marginTop={statusHeaderHeight + buttonsHeaderHeight}
                    buttonsHeaderHeight={buttonsHeaderHeight}
                    tabConfiguration={this.state.activeTabConfiguration}
                    handleToUpdateComponent={this.handleToUpdateComponent.bind(this)}
                    actions={{ setActiveSection, fetchRegistrationDeliveryDeliveryHistorical, fetchRegistrationDeliveryFinancingHistorical, fetchRegistrationDeliveryVehicleHistorical, fetchRegistrationDeliveryClientHistorical }}
                    pristine={pristine}
                  />
                </Col>
                <Col sm={9} className="col-row contenido-paneles-expediente">
                  <Col className="panels-wrapper dossier fixed" style={{ paddingTop: panelsPaddingTop }}>
                    {this.state.componentsConfiguration.bsale && this.state.componentsConfiguration.bsale.permission > permissions.hidden && (
                      <Element name={buttonsSidebar.bsale}>
                        <SalesComponent
                          t={t}
                          dossier={dossier}
                          combos={combos}
                          actions={{
                            change,
                            fetchOperationType,
                            openDocumentEditionModal,
                            postPaymentSignalStatusSuccess,
                            openDocumentModal: (defaultTab) => openDocumentModal(dossierId, dossierTypesConstant.registrationDelivery, pristine, defaultTab),
                            togglePanel: () => this.togglePanel(),
                            setActiveSection
                          }}
                          readOnlyFields={!saveRegistrationDeliveryPermission}
                          dossierType={dossierTypesConstant.registrationDelivery}
                          open={activeSection === buttonsSidebar.bsale}
                          componentConfiguration={this.state.componentsConfiguration.bsale}
                          customFields={this.props.dossier.customFieldsByComponent.find(x => x.componentCode === buttonsSidebar.bsale)?.customFields}
                        />
                      </Element>
                    )}
                    {this.state.componentsConfiguration.entity && this.state.componentsConfiguration.entity.permission > permissions.hidden && (
                      <Element name={buttonsSidebar.entity}>
                        <Client
                          t={t}
                          handleToUpdateComponent={this.handleToUpdateComponent.bind(this)}
                          isClientLoaded={this.state.isClientLoaded}
                          dossier={dossier}
                          pristine={pristine}
                          combos={combos}
                          entityChangeable={entityChangeable}
                          dossierType={dossierTypesConstant.registrationDelivery}
                          actions={{
                            change,
                            openDocumentEditionModal,
                            fetchRegistrationDeliveryClientHistorical,
                            setActiveSection,
                            fetchEntityCountryTypeCombo,
                            fetchEntityStateTypeCombo,
                            fetchEntityCityTypeCombo,
                            fetchEntityTownTypeCombo,
                            fetchEntityColonyTypeCombo,
                            fetchEntityCPTypeCombo,
                            openCommonModal,
                            fetchCommercialSocietyTypeCombo,
                            fetchActivityBpro,
                            fetchClientDataAddress
                          }}
                          readOnlyFields={!saveRegistrationDeliveryPermission}
                          open={activeSection === buttonsSidebar.entity}
                          componentConfiguration={this.state.componentsConfiguration.entity}
                          customFields={this.props.dossier.customFieldsByComponent.find(x => x.componentCode === buttonsSidebar.entity)?.customFields}
                        />
                      </Element>
                    )}
                    {this.state.componentsConfiguration.vehicle && this.state.componentsConfiguration.vehicle.permission > permissions.hidden && (
                      <Element name={buttonsSidebar.vehicle}>
                        <Vehicle
                          parentDossierType={{
                            isPaperwork: false,
                            isSales: false,
                            isPurchase: false,
                            isRegistrationDelivery: true
                          }}
                          handleToUpdateComponent={this.handleToUpdateComponent.bind(this)}
                          isVehicleLoaded={this.state.isVehicleLoaded}
                          dossierId={dossierId}
                          t={t}
                          vehicleProps={vehicleProps}
                          orderId={dossier.orderId}
                          order={order}
                          dossierParentId={purchasesDossierId}
                          dossierWithVehicle={!!vehicleId}
                          combos={combos}
                          actions={{
                            fetchOrder,
                            fetchComboOrigin,
                            fetchComboDestination,
                            fetchRegistrationDeliveryVehicleHistorical,
                            setActiveSection
                          }}
                          readOnlyFields={!saveRegistrationDeliveryPermission}
                          open={activeSection === buttonsSidebar.vehicle}
                          dossierType={dossierTypesConstant.registrationDelivery}
                          dossierSubType={dossierSubTypeId}
                          componentConfiguration={this.state.componentsConfiguration.vehicle}
                          // codeVehicleConfiguration={registrationDeliveryConfiguration.showCodecombos}
                          organizedUnitId={dossier.organizedUnitId}
                          comercialCode={this.props.dossier.vehicleComponent.comercialCode}
                          customFields={this.props.dossier.customFieldsByComponent.find(x => x.componentCode === buttonsSidebar.vehicle)?.customFields}
                        />
                      </Element>
                    )}
                    {this.state.componentsConfiguration.uac && this.state.componentsConfiguration.uac.permission > permissions.hidden && (
                      <Element name={buttonsSidebar.uac}>
                        <UAC
                          t={t}
                          uac={uac}
                          actions={{ setActiveSection }}
                          readOnlyFields={true}
                          open={activeSection === buttonsSidebar.uac}
                          componentConfiguration={this.state.componentsConfiguration.uac}
                          dossierType = { dossierTypesConstant.registrationDelivery }
                          customFields={this.props.dossier.customFieldsByComponent.find(x => x.componentCode === buttonsSidebar.uac)?.customFields}
                        />
                      </Element>
                    )}
                    {this.state.componentsConfiguration.paperwork && this.state.componentsConfiguration.paperwork.permission > permissions.hidden && (
                      <Element name={buttonsSidebar.paperwork}>
                        <PaperworkTablePage
                          organizedUnitId={organizedUnitId}
                          dossierParentId={dossierId}
                          updateDossierAction={fetchRegistrationDeliveryDossierDependants}
                          showAlertSendPending={showAlertSendPending}
                          paperwork={paperWorks}
                          dossierType={dossierTypesConstant.registrationDelivery}
                          readOnlyFields={true}
                          isHistorical={true}
                          open={activeSection === buttonsSidebar.paperwork}
                          componentConfiguration={this.state.componentsConfiguration.paperwork}
                          customFields={this.props.dossier.customFieldsByComponent.find(x => x.componentCode === buttonsSidebar.paperwork)?.customFields}
                        />
                      </Element>
                    )}
                    {this.state.componentsConfiguration.charge && this.state.componentsConfiguration.charge.permission > permissions.hidden && (
                      <Element name={buttonsSidebar.charge}>
                        <Charges
                          t={t}
                          dossier={dossier}
                          charges={charges}
                          actions={{
                            openDocumentEditionModal,
                            updateDependantsSaleDossierAndHeader,
                            fetchSalesEconomic: fetchRegistrationDeliveryEconomic,
                            setActiveSection
                          }}
                          dossierType={dossierTypesConstant.registrationDelivery}
                          readOnlyFields={true}
                          isPreCancelled={isPreCancelled}
                          open={activeSection === buttonsSidebar.charge}
                          componentConfiguration={this.state.componentsConfiguration.charge}
                          dossierId={dossierId}
                          pristine={pristine}
                          historical={true}
                          isEconomicLoaded={this.state.isEconomicLoaded}
                          handleToUpdateComponent={this.handleToUpdateComponent.bind(this)}
                          customFields={this.props.dossier.customFieldsByComponent.find(x => x.componentCode === buttonsSidebar.charge)?.customFields}
                        />
                      </Element>
                    )}
                    {this.state.componentsConfiguration.delivery && this.state.componentsConfiguration.delivery.permission > permissions.hidden && (
                      <Element name={buttonsSidebar.delivery}>
                        <Delivery
                          t={t}
                          handleToUpdateComponent={this.handleToUpdateComponent.bind(this)}
                          isDeliveryLoaded={this.state.isDeliveryLoaded}
                          actions={{
                            fetchRegistrationDeliveryDeliveryHistorical,
                            setActiveSection
                          }}
                          dossierId={dossierId}
                          pristine={pristine}
                          isRegistrationDeliveryDossier={true}
                          dossierCompleteDeliveryCheckListItems={dossierCompleteDeliveryCheckListItems}
                          dossierDeliveryCheckListPurchase={dossierDeliveryCheckListPurchase}
                          readOnlyFields={true}
                          open={activeSection === buttonsSidebar.delivery}
                          dossierSubTypeId={dossierSubTypeId}
                          componentConfiguration={this.state.componentsConfiguration.delivery}
                          combos={combos}
                          customFields={this.props.dossier.customFieldsByComponent.find(x => x.componentCode === buttonsSidebar.delivery)?.customFields}
                        />
                      </Element>
                    )}
                  </Col>
                </Col>
              </Row>
            )
          }
          {
            activeTab === buttonsHeader.gestor_documental && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden && (
              <Row className="sales-panel dossier-panel">
                <Col sm={12} className="col-row" style={{ paddingTop: panelsPaddingTop }}>
                  <DocumentaryManagementPage
                    dossier={dossier}
                    dossierType={dossierTypesConstant.registrationDelivery}
                    saveSelectedDocuments={saveSelectedDocuments}
                    documentExport={documentExport}
                    combos={combos}
                    isHistorical={true}
                    tabConfiguration={this.state.activeTabConfiguration}
                    downloadButtonPermission={this.state.activeTabConfiguration.componentsConfiguration.downloadDocumentsButton}
                  />
                </Col>
              </Row>
            )}
          {
            activeTab === buttonsHeader.expedientes_relacionados && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden && (
              <Row className="sales-panel dossier-panel panel-expedientes-relacionados">
                <Col sm={12} className="col-row" style={{ paddingTop: panelsPaddingTop }}>
                  <Col sm={6} className="col-row">
                    <h4><i className="ico-relacion" /> {t(`${tKey}RELATED_DOSSIER.RELATED_DOSSIER`)}</h4>
                  </Col>
                  <Col sm={12} className="col-row">
                    <Table hover responsive className="simple-table dashboard-tasks-table" id="related-dossiers-table">
                      <thead>
                        <tr>
                          <th onClick={this.onClickRelatedDossierColumn.bind(this, 'urgencyType')}>
                            <i className={this.getRelatedDossierColumnIcon('urgencyType')} />
                          </th>
                          <th onClick={this.onClickRelatedDossierColumn.bind(this, 'number')}>
                            {t(`${tKey}RELATED_DOSSIER.DOSSIER_NUMBER`)} <i className={this.getRelatedDossierColumnIcon('number')} />
                          </th>
                          <th onClick={this.onClickRelatedDossierColumn.bind(this, 'organizedUnit')}>
                            {t(`${tKey}RELATED_DOSSIER.UO`)} <i className={this.getRelatedDossierColumnIcon('organizedUnit')} />
                          </th>
                          <th onClick={this.onClickRelatedDossierColumn.bind(this, 'dossierSubType')}>
                            {t(`${tKey}RELATED_DOSSIER.SUBTYPE`)} <i className={this.getRelatedDossierColumnIcon('dossierSubType')} />
                          </th>
                          <th onClick={this.onClickRelatedDossierColumn.bind(this, 'stage')}>
                            {t(`${tKey}RELATED_DOSSIER.STATE`)} <i className={this.getRelatedDossierColumnIcon('stage')} />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          dossierRelatedDossiers.map((relatedDossier, idx) => {
                            return (
                              <tr key={idx}>
                                <td>
                                  <span className={'color-point ' + urgencyTypesClass[relatedDossier.urgencyType]}></span>
                                </td>
                                <td>
                                  {
                                    relatedDossier.urlToNavigate ? (
                                      <Link to={`/dossier${relatedDossier.urlToNavigate}`}>
                                        {relatedDossier.number}
                                      </Link>
                                    ) : relatedDossier.number
                                  }
                                </td>
                                <td>{relatedDossier.organizedUnit}</td>
                                <td>{relatedDossier.dossierSubType}</td>
                                <td>{relatedDossier.stage}</td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </Table>
                  </Col>
                </Col>
              </Row>
            )}
          {
            activeTab === buttonsHeader.actividad_expediente && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden && (
              <Row className="sales-panel dossier-panel panel-actividad-expediente">
                <Col sm={12} className="col-row" style={{ paddingTop: panelsPaddingTop }}>
                  <AuditInfo
                    auditInfo={auditInfo}
                    dossierType={dossierTypesConstant.registrationDelivery}
                    actions={{ refreshAuditInfo, fetchAuditDetails, fetchAuditDetailsModal, fetchDocumentsGroupedAuditDetails, fetchDocumentsGroupedAuditDetailsModal }}
                    dossier={dossier}
                    historical={true}
                    t={t}
                  />
                </Col>
              </Row>
            )
          }
          {
            activeTab === buttonsHeader.observaciones && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden && (
              <Row className="sales-panel dossier-panel panel-observaciones-expediente">
                <Col sm={12} className="col-row" style={{ paddingTop: panelsPaddingTop }}>
                  <Col sm={6} className="col-row">
                    <h4><i className="ico-bocadillo" onClick={() => { this.setState({ modalOpen: !this.state.modalOpen }) }}/> {t(`${tKey}COMMENTS.TITLE_PANEL`)}</h4>
                  </Col>
                  <Col sm={12} className="col-row">
                    <Panel expanded={this.state.modalOpen} onToggle={() => {}}>
                      <Panel.Collapse>
                        <Row>
                          <EditableTable
                            t={t}
                            columnsConfig={
                              [
                                { th: t(`${tKey}COMMENTS.USER_NAME`), key: 'userName', nonEditable: true },
                                { th: t(`${tKey}COMMENTS.COMMENT_TYPE`), key: 'commentType', nonEditable: true },
                                { th: t(`${tKey}COMMENTS.TEXT`), key: 'text', style: { width: '30%' } },
                                { th: t(`${tKey}COMMENTS.CREATION_DATE`), key: 'creationDate', nonEditable: true }
                              ]
                            }
                            dossierType="registrationDeliveryDossier"
                            editableObjectsArray={comments}
                            readOnlyFields={true}
                          />
                        </Row>
                      </Panel.Collapse>
                    </Panel>
                    <SmallLoaderPage />
                  </Col>
                </Col>
              </Row>
            )}
        </Form>
      </div>
    )
  }
}

var decoratedComponent = TrackingContainer(RegistrationDeliveryHistorical, false)

export default reduxForm({
  form: 'registrationDeliveryDossier'
})(decoratedComponent)
