import React, { PureComponent } from 'react'
import { Col, Row, Panel, Button } from 'react-bootstrap'
import { Field } from 'redux-form'
import InputSliderButton from '../../../components/commons/form/InputSliderButton'
import { permissions } from '../../../constants/backendIds'
import { getFieldsPermisions } from '../../../util/permisionFunctions'
import InputSelect from '../../../components/commons/form/InputSelect'
import { compareObjects } from '../../../util/utils'
import { stockVoStages } from '../../../constants/dossier/stockVo/stockVoStages'

export default class Entry extends PureComponent {
  constructor () {
    super()
    this.state = {
      open: false,
      repairShopLoaded: false,
      fieldsConfiguration: {
        repairshopId: null,
        initialStageId: null,
        indicatePhotos: null,
        publishPhotos: null,
        applicationStageId: null,
        fastline: null,
        start: null
      }
    }
  }

  initializePermisions (props) {
    const fieldKeys = Object.keys(this.state.fieldsConfiguration)
    const newState = getFieldsPermisions(props.componentConfiguration.fieldsConfiguration, fieldKeys, props.componentConfiguration.permission)
    this.setState({ fieldsConfiguration: newState.fieldsConfiguration })
  }

  toggleComponent () {
    if (this.state.open) {
      this.props.actions.setActiveSection(false)
    }
    this.setState({ open: !this.state.open })
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (this.props.dossier && this.props.dossier.dossierId) {
      if (!compareObjects(prevProps.componentConfiguration || {}, this.props.componentConfiguration, true)) {
        this.initializePermisions(this.props)
      }
      if (this.props.open) {
        this.setState({ open: true })
      }
    }

    if (this.state.open && (this.props.dossier?.dossierStockComponent?.repairshopId === null || this.props.dossier?.dossierStockComponent?.repairshopId === undefined) &&
     !this.state.repairShopLoaded && this.props.combos.repairshopCombo.length > 0) {
      this.autoSelectRepairShop()
      this.setState({ repairShopLoaded: true })
    }

    return prevProps
  }

  autoSelectRepairShop () {
    if (this.props.combos.repairshopCombo.length === 1) {
      this.props.change('dossierStockComponent.repairshopId', this.props.combos.repairshopCombo[0].id)
    }
  }

  componentDidUpdate () {
    // don't remove use for getsnapshot
  }

  componentDidMount () {
    this.initializePermisions(this.props)
    this.autoSelectRepairShop()
  }

  updateIndicatePhotos (value, key) {
    if (value === 0) {
      this.props.actions.changeRelationEntryValues(value, key)
    }
  }

  updatePublishVehicle (value, key) {
    if (value === 2) {
      this.props.actions.changeRelationEntryValues(value, key)
    }
  }

  render () {
    const {
      t, readOnlyFields, dossierId, dossier: { dossierStockComponent },
      actions: { confirmDateWithIdByStage },
      combos: { repairshopCombo, stagesCombo }
    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.ENTRY.'

    let filteredStagesCombo = stagesCombo.filter(value => {
      return value.id !== stockVoStages.entry
    })

    return (
      <Row className="entry-panel dossier-panel">
        <div className="header-panel" onClick={() => this.toggleComponent()}>
          <div className="name-wrapper">
            <i className="ico-i" />
            <h4>{t(`${tKey}TITLE_PANEL`)}</h4>
          </div>
          <i className={this.state.open ? 'ico-arrow-black-up' : 'ico-arrow-black-down'} />
        </div>
        <Panel expanded={this.state.open} onToggle={() => {}}>
          <Panel.Collapse>
            {
              this.state.fieldsConfiguration.start && this.state.fieldsConfiguration.start.permission === permissions.editable && (
                <Row>
                  <Col className="col-right">
                    <Button className='btn-save' onClick={() => confirmDateWithIdByStage(dossierId)} >
                      {t(`${tKey}INIT_BUTTON`)}
                    </Button>
                  </Col>
                </Row>
              )
            }
            <Row>
              {this.state.fieldsConfiguration.repairshopId && this.state.fieldsConfiguration.repairshopId.permission !== permissions.hidden && (
                <Field
                  colSm={6}
                  id="repairshopId"
                  name="dossierStockComponent.repairshopId"
                  controlLabel={t(`${tKey}REPAIRSHOP`)}
                  placeholder={t(`${tKey}REPAIRSHOP`)}
                  component={InputSelect}
                  options={repairshopCombo}
                  valueKey="id"
                  labelKey="value"
                  disabled={readOnlyFields || this.state.fieldsConfiguration.repairshopId.permission !== permissions.editable}/>
              )}

              {this.state.fieldsConfiguration.initialStageId && this.state.fieldsConfiguration.initialStageId.permission !== permissions.hidden && (
                <Field
                  colSm={6}
                  id="initialStageId"
                  name="dossierStockComponent.initialStageId"
                  controlLabel={t(`${tKey}INITIAL_STAGE`)}
                  placeholder={t(`${tKey}INITIAL_STAGE`)}
                  component={InputSelect}
                  options={filteredStagesCombo}
                  valueKey="id"
                  labelKey="value"
                  disabled={readOnlyFields || this.state.fieldsConfiguration.initialStageId.permission !== permissions.editable}/>
              )}
            </Row>
            <Row className="form-group flexRow">
              {this.state.fieldsConfiguration.indicatePhotos && this.state.fieldsConfiguration.indicatePhotos.permission !== permissions.hidden && (
                <Field
                  id="indicatePhotos"
                  name="dossierStockComponent.indicatePhotos"
                  colSm={3}
                  controlLabel={t(`${tKey}INDICATE_PHOTOS`)}
                  placeholder={t(`${tKey}INDICATE_PHOTOS`)}
                  component={InputSliderButton}
                  textDescription={t(`${tKey}INDICATE_PHOTOS`)}
                  disabled={readOnlyFields || this.state.fieldsConfiguration.indicatePhotos.permission === permissions.readOnly }
                  customClass={'three-rc-select'}
                  defaultChoice={dossierStockComponent ? dossierStockComponent.indicatePhotos : null}
                  onInputChange={(value) => this.updatePublishVehicle(value, 'publishPhotos')}
                />
              )}
              {this.state.fieldsConfiguration.publishPhotos && this.state.fieldsConfiguration.publishPhotos.permission !== permissions.hidden && (
                <Field
                  id="publishPhotos"
                  name="dossierStockComponent.publishPhotos"
                  colSm={3}
                  controlLabel={t(`${tKey}PUBLISH_VEHICLE`)}
                  placeholder={t(`${tKey}PUBLISH_VEHICLE`)}
                  component={InputSliderButton}
                  textDescription={t(`${tKey}PUBLISH_VEHICLE`)}
                  disabled={readOnlyFields || this.state.fieldsConfiguration.publishPhotos.permission === permissions.readOnly }
                  customClass={'three-rc-select'}
                  defaultChoice={dossierStockComponent ? dossierStockComponent.publishPhotos : null}
                  onInputChange={(value) => this.updateIndicatePhotos(value, 'indicatePhotos')}
                />
              )}
              {this.state.fieldsConfiguration.applicationStageId && this.state.fieldsConfiguration.applicationStageId.permission !== permissions.hidden && (
                <Field
                  colSm={6}
                  id="applicationStageId"
                  name="dossierStockComponent.applicationStageId"
                  controlLabel={t(`${tKey}REQUEST_STAGE`)}
                  placeholder={t(`${tKey}REQUEST_STAGE`)}
                  component={InputSelect}
                  options={filteredStagesCombo}
                  valueKey="id"
                  labelKey="value"
                  disabled={readOnlyFields || this.state.fieldsConfiguration.applicationStageId.permission !== permissions.editable}/>
              )}
            </Row>
            <Row>
              {this.state.fieldsConfiguration.fastline && this.state.fieldsConfiguration.fastline.permission !== permissions.hidden && (
                <Field
                  id="fastline"
                  name="dossierStockComponent.fastline"
                  colSm={3}
                  controlLabel={t(`${tKey}FASTLINE`)}
                  placeholder={t(`${tKey}FASTLINE`)}
                  component={InputSliderButton}
                  textDescription={t(`${tKey}FASTLINE`)}
                  disabled={readOnlyFields || this.state.fieldsConfiguration.fastline.permission === permissions.readOnly }
                  customClass={'three-rc-select'}
                  defaultChoice={dossierStockComponent ? dossierStockComponent.fastline : null}
                />
              )}
            </Row>
          </Panel.Collapse>
        </Panel>
      </Row>
    )
  }
}
