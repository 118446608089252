import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import StoreCancelModal from '../../../components/dossiers/common/StoreCancelModal'
import { putCancelDossier } from '../../../actions/dossier/common/commonHeader'
import { storeDossier } from '../../../actions/dossier/commonDossierActions'

export function mapStateToProps (state) {
  return {
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      putCancelDossier,
      storeDossier
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(StoreCancelModal))
