import documentDataCDA from '../../../constants/actions/dossier/common/documentDataCDAModal'

export function openCDAModal (dossierId, dossierType, organizedUnitId, lastAccessDate) {
  return {
    type: documentDataCDA.OPEN_CDA_MODAL,
    dossierId,
    dossierType,
    organizedUnitId,
    lastAccessDate
  }
}

export function closeCDAModal () {
  return {
    type: documentDataCDA.CLOSE_CDA_MODAL
  }
}
