import React, { PureComponent } from 'react'
import { Button, Modal, ProgressBar } from 'react-bootstrap'
import { reduxForm } from 'redux-form'
import { dossierStagesBySubtype } from '../../../../constants/dossier/common/dossierStages'
import { dossierSubTypeId } from '../../../../constants/dossier/common/dossierSubType'
import { approvalRoleCode, approvalType } from '../../../../constants/dossier/sale/approvalRoleCode'
import { getSectionPermissions, getFieldsSectionPermissions } from '../../../../util/permisionFunctions'
import { sectionPermissions } from '../../../../constants/dossier/common/buttonsHeader'
import { permissions } from '../../../../constants/backendIds'

class ApprovalActionModal extends PureComponent {
  constructor () {
    super()
    this.state = {
      displayBar: 'none',
      maxBar: 0,
      progressFail: 0,
      progress: 0,
      errors: [],
      fieldsConfiguration: {
        massive_approval_button: { permission: null },
        massive_jv_approval_in_charge_button: { permission: null },
        massive_office_aproval_in_charge_button: { permission: null },
        massive_send_to_approval_button: { permission: null },
        massive_manager_approval_button: { permission: null },
        massive_fleet_approval_button: { permission: null },
        authorize_management_fleet_button: { permission: null },
        authorize_finance_fleet_button: { permission: null }
      }
    }
  }

  initializePermisions (props) {
    const section = props.sectionsGeneral.filter((tabConf) => tabConf.code === sectionPermissions.management)
    if (section.length > 0 && section[0] != null) {
      const newState = getSectionPermissions(section[0])
      let permissionState = this.state.sectionTabsConfiguration
      permissionState = { ...permissionState, ...newState }
      if (permissionState.fleets && permissionState.fleets.sectionFieldsConfiguration) {
        const fieldKeys = Object.keys(this.state.fieldsConfiguration)
        const permission = getFieldsSectionPermissions(permissionState.fleets.sectionFieldsConfiguration, fieldKeys, permissions.hidden)
        this.setState({ fieldsConfiguration: permission.fieldsConfiguration })
      }
    }
  }

  componentDidMount () {
    this.initializePermisions(this.props)
  }

  componentDidUpdate () {
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (prevProps.showModal !== this.props.showModal) {
      this.setState({
        displayBar: 'none',
        maxBar: 0,
        progressFail: 0,
        progress: 0,
        errors: []
      })
    }
    return null
  }

  massiveSendToApproval () {
    this.getDossiersByStageRoleApproval(this.props.dossiers, approvalType.SEND_TO_APPROVAL)
  }

  massiveApproval () {
    this.getDossiersByStageRoleApproval(this.props.dossiers, approvalType.APPROVE)
  }

  massiveJVApprovalInCharge () {
    this.getDossiersByStageRoleApproval(this.props.dossiers, approvalType.ROLE_APPROVE, approvalRoleCode.SALES_MANAGER)
  }

  massiveOfficeAprovalInCharge () {
    this.getDossiersByStageRoleApproval(this.props.dossiers, approvalType.ROLE_APPROVE, approvalRoleCode.ADMINISTRATION)
  }

  massiveManagerApproval () {
    this.getDossiersByStageRoleApproval(this.props.dossiers, approvalType.ROLE_APPROVE, approvalRoleCode.MANAGER)
  }

  massiveFleetApproval () {
    this.getDossiersByStageRoleApproval(this.props.dossiers, approvalType.ROLE_APPROVE, approvalRoleCode.FLEETS)
  }

  massiveAuthorizeManagementFleet () {
    this.getDossiersByStageRoleApproval(this.props.dossiers, approvalType.ROLE_AUTHORIZE_MANAGEMENT, approvalRoleCode.FLEETS)
  }

  massiveAuthorizeFinanceFleet () {
    this.getDossiersByStageRoleApproval(this.props.dossiers, approvalType.ROLE_AUTHORIZE_FINANCE, approvalRoleCode.FLEETS)
  }

  getDossiersByStageRoleApproval (dossiersArray, type, roleId) {
    let dossiers = []
    if (dossiersArray) {
      dossiersArray.forEach(item => {
        if (item.dossierSubtypeId === dossierSubTypeId.venta_vn) {
          switch (type) {
            case approvalType.SEND_TO_APPROVAL:
              if (item.stageId === dossierStagesBySubtype.saleVn.provisional) {
                dossiers.push(item.dossierId)
              }
              break
            case approvalType.APPROVE:
              if (item.stageId === dossierStagesBySubtype.saleVn.approval) {
                dossiers.push(item.dossierId)
              }
              break
            case approvalType.ROLE_APPROVE:
              if (item.stageId === dossierStagesBySubtype.saleVn.payment) {
                dossiers.push(item.dossierId)
              }
              break
            case approvalType.ROLE_AUTHORIZE_MANAGEMENT || approvalType.ROLE_AUTHORIZE_FINANCE:
              if (item.stageId === dossierStagesBySubtype.saleVn.documentation) {
                dossiers.push(item.dossierId)
              }
              break
            default:
              dossiers.push(item.dossierId)
              break
          }
        }

        if (item.dossierSubtypeId === dossierSubTypeId.venta_vo) {
          switch (type) {
            case approvalType.SEND_TO_APPROVAL:
              if (item.stageId === dossierStagesBySubtype.saleVo.provisional) {
                dossiers.push(item.dossierId)
              }
              break
            case approvalType.APPROVE:
              if (item.stageId === dossierStagesBySubtype.saleVo.approval) {
                dossiers.push(item.dossierId)
              }
              break
            case approvalType.ROLE_APPROVE:
              if (item.stageId === dossierStagesBySubtype.saleVo.payment) {
                dossiers.push(item.dossierId)
              }
              break
            case approvalType.ROLE_AUTHORIZE_MANAGEMENT:
            case approvalType.ROLE_AUTHORIZE_FINANCE:
              if (item.stageId === dossierStagesBySubtype.saleVn.documentation) {
                dossiers.push(item.dossierId)
              }
              break
            default:
              break
          }
        }
      })
    }

    switch (type) {
      case approvalType.SEND_TO_APPROVAL:

        if (dossiers.length === 0) {
          this.props.actions.openModal('approvalFleetNoStage')
        } else if (dossiers.length < dossiersArray.length) {
          this.props.actions.openModal('approvalFleetPosStage', () => this.sendToapprovalDossierFleet(dossiers))
        } else {
          this.sendToapprovalDossierFleet(dossiers)
        }
        break
      case approvalType.APPROVE:

        if (dossiers.length === 0) {
          this.props.actions.openModal('approvalFleetNoStage')
        } else if (dossiers.length < dossiersArray.length) {
          this.props.actions.openModal('approvalFleetPosStage', () => this.sendApproveDossierFleet(dossiers))
        } else {
          this.sendApproveDossierFleet(dossiers)
        }
        break
      case approvalType.ROLE_APPROVE:

        if (dossiers.length === 0) {
          this.props.actions.openModal('approvalFleetNoStage')
        } else if (dossiers.length < dossiersArray.length) {
          this.props.actions.openModal('approvalFleetPosStage', () => this.sendRoleApproveDossierFleet(dossiers, roleId))
        } else {
          this.sendRoleApproveDossierFleet(dossiers, roleId)
        }
        break
      case approvalType.ROLE_AUTHORIZE_MANAGEMENT:
        if (dossiers.length === 0) {
          this.props.actions.openModal('approvalFleetNoStage')
        } else if (dossiers.length < dossiersArray.length) {
          this.props.actions.openModal('approvalFleetPosStage', () => this.sendRoleApproveDossierFleet(dossiers, roleId, approvalType.ROLE_AUTHORIZE_MANAGEMENT))
        } else {
          this.sendRoleApproveDossierFleet(dossiers, roleId, approvalType.ROLE_AUTHORIZE_MANAGEMENT)
        }
        break
      case approvalType.ROLE_AUTHORIZE_FINANCE:
        if (dossiers.length === 0) {
          this.props.actions.openModal('approvalFleetNoStage')
        } else if (dossiers.length < dossiersArray.length) {
          this.props.actions.openModal('approvalFleetPosStage', () => this.sendRoleApproveDossierFleet(dossiers, roleId, approvalType.ROLE_AUTHORIZE_FINANCE))
        } else {
          this.sendRoleApproveDossierFleet(dossiers, roleId, approvalType.ROLE_AUTHORIZE_FINANCE)
        }
        break
    }
  }

  sendToapprovalDossierFleet (dossiers) {
    this.props.actions.setApproveActionProcess(dossiers.join('$'), 1, null, () => { this.props.actions.closeApprovalActionModal(); this.props.fetchFleets(this.props.fleetsFilter) })

    // this.setState({ displayBar: '', maxBar: dossiers.length })
    // var index = 0
    // new Promise((resolve, reject) => {
    //   return this.props.actions.sendToApproveActionFleet(dossiers[index], resolve, reject)
    // }).then((response) => {
    //   this.setState({ progress: this.state.progress + 1 })
    //   this.launchsendToapprovalDossierFleet(index + 1, dossiers)
    // }).catch((error) => {
    //   this.setState({ errors: [...this.state.errors, error.json] })
    //   this.setState({ progressFail: this.state.progressFail + 1 })
    //   this.launchsendToapprovalDossierFleet(index + 1, dossiers)
    // })
  }

  launchsendToapprovalDossierFleet (index, dossiers) {
    if (dossiers[index]) {
      new Promise((resolve, reject) => {
        return this.props.actions.sendToApproveActionFleet(dossiers[index], resolve, reject)
      }).then((response) => {
        this.setState({ progress: this.state.progress + 1 })
        this.launchsendToapprovalDossierFleet(index + 1, dossiers)
      }).catch((error) => {
        this.setState({ errors: [...this.state.errors, error.json] })
        this.setState({ progressFail: this.state.progressFail + 1 })
        this.launchsendToapprovalDossierFleet(index + 1, dossiers)
      })
    } else {
      let errors = this.getErrorsMessageFleet(this.state.errors, 1, 13, approvalType.SEND_TO_APPROVAL)
      this.props.actions.openModal('actionAdminFleetExp', () => { this.props.actions.closeApprovalActionModal(); this.props.fetchFleets(this.props.fleetsFilter) }, null, null, null, [{ id: '##NUM_OK##', value: this.state.progress }, { id: '##NUM_TOTAL##', value: dossiers.length }, { id: '##ERRORS##', value: errors }], null)
    }
  }

  getErrorsMessageFleet (errorsArray, mode, indexSubstring, type) {
    let errors = ''
    errorsArray.forEach(x => {
      var errormessage = this.props.t('MESSAGES.APPROVAL_FLEET_ERROR')
      switch (type) {
        case approvalType.SEND_TO_APPROVAL:
          errormessage = this.props.t('MESSAGES.APPROVAL_FLEET_ERROR')
          break
        case approvalType.APPROVE:
        case approvalType.ROLE_APPROVE:
          errormessage = this.props.t('MESSAGES.APPROVE_FLEET_ERROR')
          break
      }

      let dossierid
      switch (mode) {
        case 1:
          dossierid = x.message.substring(indexSubstring, x.message.indexOf(']'))
          break
        case 2:
          var splitError = x.tag.split('/')
          dossierid = splitError[0]
          break
      }
      var number = this.props.dossiers.find(d => d.dossierId === Number(dossierid)).number
      errormessage = errormessage.replace('##Number##', number)
      switch (mode) {
        case 1:
          errormessage = errormessage.replace('##Error##', this.props.t('MANAGEMENT.FLEETS_TAB.ERRORS.' + x.tag))
          break
        case 2:
          errormessage = errormessage.replace('##Error##', this.props.t('MANAGEMENT.FLEETS_TAB.ERRORS.' + splitError[1]))
          break
      }
      errors = errors + '\n' + errormessage
    })
    return errors
  }

  sendApproveDossierFleet (dossiers) {
    this.props.actions.setApproveActionProcess(dossiers.join('$'), 2, null, () => { this.props.actions.closeApprovalActionModal(); this.props.fetchFleets(this.props.fleetsFilter) })
  }

  launchsendsendApproveDossierFleet (index, dossiers) {
    if (dossiers[index]) {
      new Promise((resolve, reject) => {
        return this.props.actions.approveActionFleet(dossiers[index], resolve, reject)
      }).then((response) => {
        this.setState({ progress: this.state.progress + 1 })
        this.launchsendsendApproveDossierFleet(index + 1, dossiers)
      }).catch((error) => {
        this.setState({ errors: [...this.state.errors, error.json] })
        this.setState({ progressFail: this.state.progressFail + 1 })
        this.launchsendsendApproveDossierFleet(index + 1, dossiers)
      })
    } else {
      let errors = this.getErrorsMessageFleet(this.state.errors, 2, 0, approvalType.APPROVE)
      this.props.actions.openModal('actionAdminFleetExp', () => { this.props.actions.closeApprovalActionModal(); this.props.fetchFleets(this.props.fleetsFilter) }, null, null, null, [{ id: '##NUM_OK##', value: this.state.progress }, { id: '##NUM_TOTAL##', value: dossiers.length }, { id: '##ERRORS##', value: errors }], null)
    }
  }

  sendRoleApproveDossierFleet (dossiers, roleid, type = 3) {
    this.props.actions.setApproveActionProcess(dossiers.join('$'), type, roleid, () => { this.props.actions.closeApprovalActionModal(); this.props.fetchFleets(this.props.fleetsFilter) })

    // this.setState({ displayBar: '', maxBar: dossiers.length })
    // var index = 0
    // new Promise((resolve, reject) => {
    //   return this.props.actions.approveRoleActionFleet(dossiers[index], roleid, resolve, reject)
    // }).then((response) => {
    //   this.setState({ progress: this.state.progress + 1 })
    //   this.launchsendsendRoleApproveDossierFleet(index + 1, dossiers, roleid)
    // }).catch((error) => {
    //   this.setState({ errors: [...this.state.errors, error.json] })
    //   this.setState({ progressFail: this.state.progressFail + 1 })
    //   this.launchsendsendRoleApproveDossierFleet(index + 1, dossiers, roleid)
    // })
  }

  launchsendsendRoleApproveDossierFleet (index, dossiers, roleid) {
    if (dossiers[index]) {
      new Promise((resolve, reject) => {
        return this.props.actions.approveRoleActionFleet(dossiers[index], roleid, resolve, reject)
      }).then((response) => {
        this.setState({ progress: this.state.progress + 1 })
        this.launchsendsendRoleApproveDossierFleet(index + 1, dossiers, roleid)
      }).catch((error) => {
        this.setState({ errors: [...this.state.errors, error.json] })
        this.setState({ progressFail: this.state.progressFail + 1 })
        this.launchsendsendRoleApproveDossierFleet(index + 1, dossiers, roleid)
      })
    } else {
      let errors = this.getErrorsMessageFleet(this.state.errors, 1, 13, approvalType.ROLE_APPROVE)
      this.props.actions.openModal('actionAdminFleetExp', () => { this.props.actions.closeApprovalActionModal(); this.props.fetchFleets(this.props.fleetsFilter) }, null, null, null, [{ id: '##NUM_OK##', value: this.state.progress }, { id: '##NUM_TOTAL##', value: dossiers.length }, { id: '##ERRORS##', value: errors }], null)
    }
  }

  render () {
    const {
      t,
      showModal
    } = this.props
    const tKey = 'MANAGEMENT.FLEETS_TAB.'
    return (
      <Modal backdrop="static" className="_nf approvalActionModal centerAling" show={showModal} onHide={this.props.actions.closeApprovalActionModal}>
        <form autoComplete="off" >
          <Modal.Header closeButton onHide={this.props.actions.closeApprovalActionModal}>
            <Modal.Title>{t(`${tKey}MASSIVE_APPROVAL`)}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='search-form'>
              {this.state.fieldsConfiguration.massive_send_to_approval_button && this.state.fieldsConfiguration.massive_send_to_approval_button.permission === permissions.editable &&
                <Button id="massive_send_to_approval_button" className="_Btn fWidth accept" onClick = {() => this.massiveSendToApproval()}>{t(`${tKey}MASSIVE_SEND_TO_APPROVAL`)}</Button>
              }
              {this.state.fieldsConfiguration.massive_approval_button && this.state.fieldsConfiguration.massive_approval_button.permission === permissions.editable &&
                <Button id="massive_approval_button" className="_Btn fWidth accept" onClick = {() => this.massiveApproval()}>{t(`${tKey}MASSIVE_APPROVAL`)}</Button>
              }
              {this.state.fieldsConfiguration.massive_jv_approval_in_charge_button && this.state.fieldsConfiguration.massive_jv_approval_in_charge_button.permission === permissions.editable &&
                <Button id="massive_jv_approval_in_charge_button" className="_Btn fWidth accept" onClick = {() => this.massiveJVApprovalInCharge()}>{t(`${tKey}MASSIVE_JV_APPROVAL_IN_CHARGE`)}</Button>
              }
              {this.state.fieldsConfiguration.massive_office_aproval_in_charge_button && this.state.fieldsConfiguration.massive_office_aproval_in_charge_button.permission === permissions.editable &&
                <Button id="massive_office_aproval_in_charge_button" className="_Btn fWidth accept" onClick = {() => this.massiveOfficeAprovalInCharge()}>{t(`${tKey}MASSIVE_OFFICE_APROVAL_IN_CHARGE`)}</Button>
              }

              {this.state.fieldsConfiguration.massive_manager_approval_button && this.state.fieldsConfiguration.massive_manager_approval_button.permission === permissions.editable &&
                <Button id="massive_manager_approval_button" className="_Btn fWidth accept" onClick = {() => this.massiveManagerApproval()}>{t(`${tKey}MASSIVE_MANAGER_APPROVE`)}</Button>
              }
              {this.state.fieldsConfiguration.massive_fleet_approval_button && this.state.fieldsConfiguration.massive_fleet_approval_button.permission === permissions.editable &&
                <Button id="massive_fleet_approval_button" className="_Btn fWidth accept" onClick = {() => this.massiveFleetApproval()}>{t(`${tKey}MASSIVE_FLEET_APPROVE`)}</Button>
              }
              {this.state.fieldsConfiguration.authorize_management_fleet_button && this.state.fieldsConfiguration.authorize_management_fleet_button.permission === permissions.editable &&
                <Button id="authorize_management_fleet_button" className="_Btn fWidth new" onClick = {() => this.massiveAuthorizeManagementFleet()}>{t(`${tKey}MASSIVE_AUTHORIZE_MANAGEMENET_FLEET`)}</Button>
              }
              {this.state.fieldsConfiguration.authorize_finance_fleet_button && this.state.fieldsConfiguration.authorize_finance_fleet_button.permission === permissions.editable &&
                <Button id="authorize_finance_fleet_button" className="_Btn fWidth new" onClick = {() => this.massiveAuthorizeFinanceFleet()}>{t(`${tKey}MASSIVE_AUTHORIZE_FINANCE_FLEET`)}</Button>
              }
            </div>
          </Modal.Body>
          <Modal.Footer>
            <ProgressBar style={{ display: this.state.displayBar }}>
              <ProgressBar max={this.state.maxBar} now={this.state.progress} label={`${this.state.progress}/${this.state.maxBar}`} striped variant="success" key={1} />
              <ProgressBar max={this.state.maxBar} now={this.state.progressFail} label={`${this.state.progressFail}/${this.state.maxBar}`} striped variant="danger" style={{ backgroundColor: '#d44b4b' }} key={2} />
            </ProgressBar>
            <div className='modalFooterContent'>
              <Button id="role-modal-submit-button" className="_Btn cancel" onClick = {this.props.actions.closeApprovalActionModal}>{t(`${tKey}CANCEL`)}</Button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>

    )
  }
}

export default reduxForm({
  form: 'approvalActionModal'
})(ApprovalActionModal)
