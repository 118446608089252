import { entityTypesId, clientInformation } from '../backendIds'
import settings from '../../setting'
import { dossierSubTypeId as dossierSubTypeIdConstant } from '../dossier/common/dossierSubType'

export const entityRequiredFields = (entityTypeId, dossierSubTypeId, rfcValidations = false, isChildComponent = false) => {
  const fields = {}
  if (entityTypeId !== entityTypesId.recurrentEntity) {
    fields.entityTypeId = 'DOSSIER_COMPONENTS.VALIDATIONS.ENTITY_TYPE_MANDATORY'
    fields.dniCif = 'DOSSIER_COMPONENTS.VALIDATIONS.ENTITY_DNI_MANDATORY'
    fields.address = 'DOSSIER_COMPONENTS.VALIDATIONS.ENTITY_ADDRESS_MANDATORY'
    if (settings.clientFieldValidation === clientInformation.BUSS) {
      if (!fields.countryCode) {
        fields.countryCode = 'DOSSIER_COMPONENTS.VALIDATIONS.ENTITY_COUNTRY_MANDATORY'
      }
      if (!fields.stateCode) {
        fields.stateCode = 'DOSSIER_COMPONENTS.VALIDATIONS.ENTITY_STATE_MANDATORY'
      }
      if (!fields.cityCode) {
        fields.cityCode = 'DOSSIER_COMPONENTS.VALIDATIONS.ENTITY_CITY_MANDATORY'
      }
      if (!fields.townCode) {
        fields.townCode = 'DOSSIER_COMPONENTS.VALIDATIONS.ENTITY_TOWN_MANDATORY'
      }
      if (!fields.colonyCode) {
        fields.colonyCode = 'DOSSIER_COMPONENTS.VALIDATIONS.ENTITY_COLONY_MANDATORY'
      }
      if (!fields.postalCodeCode) {
        fields.postalCodeCode = 'DOSSIER_COMPONENTS.VALIDATIONS.ENTITY_CP_MANDATORY'
      }

      if (!isChildComponent) {
        if (!fields.activityBproId) {
          fields.activityBproId = 'DOSSIER_COMPONENTS.VALIDATIONS.MANDATORY_FIELD'
        }

        if (entityTypeId === entityTypesId.privateEntity) {
          if (!fields.birthdayDate) {
            fields.birthdayDate = 'DOSSIER_COMPONENTS.VALIDATIONS.MANDATORY_FIELD'
          }
        }

        if (entityTypeId === entityTypesId.businessEntity) {
          if (!fields.constitutionAct) {
            fields.constitutionAct = 'DOSSIER_COMPONENTS.VALIDATIONS.MANDATORY_FIELD'
          }
          if (!fields.constitutionDate) {
            fields.constitutionDate = 'DOSSIER_COMPONENTS.VALIDATIONS.MANDATORY_FIELD'
          }
        }
      }
    }
    if (settings.clientFieldValidation !== clientInformation.BUSS) {
      fields.state = 'DOSSIER_COMPONENTS.VALIDATIONS.ENTITY_STATE_MANDATORY'
      fields.city = 'DOSSIER_COMPONENTS.VALIDATIONS.ENTITY_CITY_MANDATORY'
      if (settings.clientFieldValidation !== clientInformation.EUROMOTORS) {
        fields.cp = 'DOSSIER_COMPONENTS.VALIDATIONS.ENTITY_CP_MANDATORY'
      }
    }
    if (entityTypeId === entityTypesId.privateEntity) {
      fields.name = 'DOSSIER_COMPONENTS.VALIDATIONS.ENTITY_NAME_MANDATORY'
      fields.surname1 = 'DOSSIER_COMPONENTS.VALIDATIONS.ENTITY_SURNAME1_MANDATORY'
    } else if (entityTypeId === entityTypesId.businessEntity) {
      fields.name = 'DOSSIER_COMPONENTS.VALIDATIONS.ENTITY_REGISTERED_NAME_MANDATORY'
      if (settings.clientFieldValidation !== clientInformation.VGR && settings.clientFieldValidation !== clientInformation.DAG) {
        fields.representativeDni = 'DOSSIER_COMPONENTS.VALIDATIONS.ENTITY_REPRESENTATIVE_DNI_MANDATORY'
        fields.representativeName = 'DOSSIER_COMPONENTS.VALIDATIONS.ENTITY_REPRESENTATIVE_NAME_MANDATORY'
        fields.representativeSurname1 = 'DOSSIER_COMPONENTS.VALIDATIONS.ENTITY_REPRESENTATIVE_SURNAME1_MANDATORY'
      }
    }
    if ((settings.clientFieldValidation === clientInformation.TOYOTA ||
      settings.clientFieldValidation === clientInformation.QUADIS) &&
      dossierSubTypeId && (dossierSubTypeId === dossierSubTypeIdConstant.venta_vn ||
        dossierSubTypeId === dossierSubTypeIdConstant.venta_vo)) {
      fields.email = 'DOSSIER_COMPONENTS.VALIDATIONS.ENTITY_EMAIL_MANDATORY'
      fields.mobilePhone = 'DOSSIER_COMPONENTS.VALIDATIONS.ENTITY_MOBILEPHONE_MANDATORY'
    }
    if (settings.clientFieldValidation === clientInformation.QUADIS &&
      dossierSubTypeId && (dossierSubTypeId === dossierSubTypeIdConstant.compra_vo) &&
      (entityTypeId === entityTypesId.privateEntity || entityTypeId === entityTypesId.businessEntity)) {
      fields.email = 'DOSSIER_COMPONENTS.VALIDATIONS.ENTITY_EMAIL_MANDATORY'
      fields.mobilePhone = 'DOSSIER_COMPONENTS.VALIDATIONS.ENTITY_MOBILEPHONE_MANDATORY'
    }
    if (rfcValidations) {
      fields.rfc = 'DOSSIER_COMPONENTS.VALIDATIONS.ENTITY_RFC_MANDATORY'
    }
  } else {
    fields.entitySubTypeId = 'DOSSIER_COMPONENTS.VALIDATIONS.ENTITY_RECURRENT_SUBTYPE_ID_MANDATORY'
    if (entityTypeId === entityTypesId.recurrentEntity) fields.entityId = 'DOSSIER_COMPONENTS.VALIDATIONS.ENTITY_RECURRENT_ID_MANDATORY'
  }
  if (settings.clientFieldValidation === clientInformation.BUSS && entityTypeId === entityTypesId.businessEntity) {
    fields.commercialSocietyTypeId = 'ADMIN.RECURRENT_ENTITIES.VALIDATION.COMMERCIAL_SOCIETY_REQUIRED'
  }

  if (entityTypeId === entityTypesId.privateEntity) {
    if (settings.clientFieldValidation === clientInformation.EUROMOTORS) {
      if (!fields.maritalStatusId) {
        fields.maritalStatusId = 'DOSSIER_COMPONENTS.VALIDATIONS.MANDATORY_FIELD'
      }
    }
  }
  return fields
}
