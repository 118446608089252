import React, { PureComponent } from 'react'
import { Col, Panel, Row, Tab, Tabs, Button, Tooltip, OverlayTrigger, Glyphicon } from 'react-bootstrap'
import InputCheckBox from '../../commons/form/InputCheckBox'
import { Field } from 'redux-form'
import { compareObjects } from '../../../util/utils'
import { getFieldsPermisions } from '../../../util/permisionFunctions'
import { clientInformation, entityTypesId, permissions } from '../../../constants/backendIds'
import { dossierTypes as dossierTypesConstant } from '../../../constants/dossier/common/dossierTypes'
import { saleTypeCodeEnum as saleTypeCodes } from '../../../constants/dossier/sale/saleType'
import PaperworkAddress from './paperworkAddress/PaperworkAddress'
import ClientData from './clientData/ClientData'
import Contact from './contact/Contact'
import FinalClient from './finalClient/FinalClient'
import { dossierSubTypeId as dossierSubTypeIdConstant, dossierSubType as dossierSubTypeConstant } from '../../../constants/dossier/common/dossierSubType'
import setting from '../../../setting'
import EntityProvider from './EntityProvider'
import { clientTabs } from '../../../constants/dossier/common/keyTabs'
import CustomFields from '../../commons/customFields/CustomFields'
import { getTitleEntityProvider, getSubTypePapeworkRelatedDossier } from '../../../util/entity/entityUtils'

class Client extends PureComponent {
  constructor (props) {
    super()
    this.state = {
      open: false,
      dniDisabled: true,
      allInformationDisabled: true,
      tabActiveKey: clientTabs.entity,
      fieldsConfiguration: {
        entityFromParentDossier: null,
        entityTypeId: null,
        entitySubTypeId: null,
        entityId: null,
        dniCif: null,
        clientNumber: null,
        name: null,
        surname1: null,
        surname2: null,
        representativeDni: null,
        representativeName: null,
        representativeSurname1: null,
        representativeSurname2: null,
        address: null,
        maritalcmb: null,
        city: null,
        state: null,
        cp: null,
        country: null,
        phone: null,
        mobilePhone: null,
        email: null,
        sourceChannelId: null,
        segmentationId: null,
        contactName: null,
        contactSurname1: null,
        contactSurname2: null,
        contactEmail: null,
        contactPhone: null,
        contactMobilePhone: null,
        contactAddress: null,
        contactCity: null,
        contactState: null,
        contactCp: null,
        contactCountry: null,
        parentEntityId: null,
        differentPaperworkAddress: null,
        paperworkAddressAddress: null,
        paperworkAddressCity: null,
        paperworkAddressState: null,
        paperworkAddressCp: null,
        paperworkAddressCountry: null,
        entityChildSubTypeId: null,
        entityChildId: null,
        clearEntity: null,
        clearEntityChild: null,
        entityTypeIdChild: null,
        dniCifChild: null,
        clientNumberChild: null,
        nameChild: null,
        surname1Child: null,
        surname2Child: null,
        representativeDniChild: null,
        representativeNameChild: null,
        representativeSurname1Child: null,
        representativeSurname2Child: null,
        addressChild: null,
        cityChild: null,
        stateChild: null,
        cpChild: null,
        countryChild: null,
        phoneChild: null,
        mobilePhoneChild: null,
        emailChild: null,
        sourceChannelIdChild: null,
        segmentationIdChild: null,
        differentPaperworkAddressChild: null,
        iaeTypeId: null,
        iaeTypeIdChild: null,
        name2: null,
        dniCifValidationDisable: null,
        representativeDniCifValidationDisable: null,
        dniCifValidationDisableChild: null,
        representativeDniCifValidationDisableChild: null,
        rfc: null,
        rfcChild: null,
        clientNotEmployee: null,
        entityProviderId: null,
        commercialSocietyTypeId: null,
        startStreet: null,
        contactStartStreet: null,
        startStreetChild: null,
        enndStreet: null,
        contactEnndStreet: null,
        enndStreetChild: null,
        internalNumber: null,
        contactInternalNumber: null,
        internalNumberChild: null,
        externalNumber: null,
        contactExternalNumber: null,
        externalNumberChild: null,
        countryCode: null,
        contactCountryCode: null,
        countryCodeChild: null,
        stateCode: null,
        contactStateCode: null,
        stateCodeChild: null,
        townCode: null,
        contactTownCode: null,
        townCodeChild: null,
        cityCode: null,
        contactCityCode: null,
        cityCodeChild: null,
        colonyCode: null,
        contactColonyCode: null,
        colonyCodeChild: null,
        cpCode: null,
        contactCpCode: null,
        cpCodeChild: null,
        activityBproId: null,
        birthdayDate: null,
        constitutionAct: null,
        constitutionDate: null,
        freeLance: null,
        freeLanceChild: null
      },
      dniCif: null,
      errorDniCif: null,
      disabledEntityType: false,
      openEntityType: false,
      errorRfc: null,
      rfc: null,
      rfcNoEditable: false,
      fleetsEditClient: false
    }
  }

  setEntityFromParent (value) {
    this.props.actions.fetchClientFromParent(value, this.props.dossierType, this.props.dossier.parentEntityId, this.props.pristine)
  }

  setNewEntity (isFromParent) {
    if (isFromParent) {
      this.props.actions.clearEntityChild(this.props.dossier.dossierId, this.props.dossier.dossierSubTypeId)
    } else {
      this.props.actions.clearEntity(this.props.dossier.dossierId,
        this.props.dossier.dossierSubTypeId,
        isFromParent)
    }
    this.props.updateComponentAfterDeleteAndRestart()
    this.setState({ allInformationDisabled: true })
  }

  getSubTypePapeworkRelatedDossier () {
    if (this.props.dossier.dossierRelatedDossiers && this.props.dossier.dossierRelatedDossiers.length) {
      const paperworkRelatedDossier = this.props.dossier.dossierRelatedDossiers[0]
      return paperworkRelatedDossier.dossierSubType
    }
  }

  getTitle () {
    const tKey = 'DOSSIER_COMPONENTS.ENTITY.'
    const infoBlockClient = <Tooltip id="infoBlockClient">{this.props.t('MANAGEMENT.FLEETS_TAB.CLIENT_BLOQUED')}</Tooltip>
    switch (this.props.dossierType) {
      case dossierTypesConstant.sales:
        return this.props.t(`${tKey}CLIENT`)
      case dossierTypesConstant.purchase:
        return this.props.t(`${tKey}VENDOR`)
      case dossierTypesConstant.addNewFleetModal:
        return <div> {this.props.t(`${tKey}CLIENT`)} {this.props.readOnlyFields && <OverlayTrigger placement="right" overlay={infoBlockClient}
          colSm={1}>
          <Glyphicon glyph="info-sign"/>
        </OverlayTrigger>} </div>
      default:
        return this.props.t(`${tKey}PANEL_TITLE`)
    }
  }

  getTitleEntityClient () {
    const tKey = 'DOSSIER_COMPONENTS.ENTITY.'
    const infoBlockClient = <Tooltip id="infoBlockClient">{this.props.t('MANAGEMENT.FLEETS_TAB.CLIENT_BLOQUED')}</Tooltip>
    switch (this.props.dossierType) {
      case dossierTypesConstant.sales:
        return this.props.t(`${tKey}CLIENT`)
      case dossierTypesConstant.purchase:
        return this.props.t(`${tKey}VENDOR`)
      case dossierTypesConstant.addNewFleetModal:
        return <div> {this.props.t(`${tKey}CLIENT`)} {this.props.readOnlyFields && <OverlayTrigger placement="right" overlay={infoBlockClient}
          colSm={1}>
          <Glyphicon glyph="info-sign"/>
        </OverlayTrigger>} </div>
      case dossierTypesConstant.paperwork:
        if (setting.clientFieldValidation !== clientInformation.QUADIS) {
          return this.props.t(`${tKey}PANEL_TITLE`)
        }
        switch (getSubTypePapeworkRelatedDossier(this.props.dossier.dossierRelatedDossiers)) {
          case dossierSubTypeConstant.compra_vo:
            return this.props.t(`${tKey}VENDOR`)
          case dossierSubTypeConstant.venta_vo:
            return this.props.t(`${tKey}CLIENT`)
          default:
            return this.props.t(`${tKey}PANEL_TITLE`)
        }
      default:
        return this.props.t(`${tKey}PANEL_TITLE`)
    }
  }

  initializePermisions (props) {
    const fieldKeys = Object.keys(this.state.fieldsConfiguration)
    const newState = getFieldsPermisions(props.componentConfiguration.fieldsConfiguration, fieldKeys, props.componentConfiguration.permission)
    this.setState({ ...this.state, ...newState })
  }

  componentDidMount () {
    this.initializePermisions(this.props)
    this.props.actions.fetchCommercialSocietyTypeCombo()
    if (this.props.dossier.entityComponent.parentEntityId) {
      this.props.actions.fetchEntitiesByTypeByParent(entityTypesId.claimClient, this.props.dossier.entityComponent.parentEntityId)
    }
    if (this.props.isEditFleet) {
      this.setState({ tabActiveKey: clientTabs.contact })
    }
  }

  shouldOpenByFleetModal () {
    if (this.props.dossierType === dossierTypesConstant.addNewFleetModal || this.props.dossierType === dossierTypesConstant.createNewFleetModal || this.props.dossierType === dossierTypesConstant.dataContactFleetModal) {
      return true
    }
    return false
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({ open: true })
    }
    if (this.props.componentConfiguration && !compareObjects(prevProps.componentConfiguration || {}, this.props.componentConfiguration, true)) {
      this.initializePermisions(this.props)
    }

    if (this.state.isDifferentPaperworkAddress === undefined && this.props.dossier && this.props.dossier.dossierPaperworkComponent &&
      this.props.dossier.dossierPaperworkComponent.dossierPaperworkAddressComponent &&
      this.props.dossier.dossierPaperworkComponent.dossierPaperworkAddressComponent.differentPaperworkAddress !== this.state.isDifferentPaperworkAddress) {
      this.setState({ isDifferentPaperworkAddress: this.props.dossier.dossierPaperworkComponent.dossierPaperworkAddressComponent.differentPaperworkAddress })
    }
    if ((this.props.dossier.entityComponent && (this.props.dossier.entityComponent.entityId || this.props.dossier.entityComponent.bproCharged) && this.state.allInformationDisabled) ||
      (this.props.dossier.entityComponent && this.props.dossier.entityComponent.dniCifValidationDisable && this.state.dniCif !== null && this.state.dniCif !== '')) {
      this.setState({ allInformationDisabled: false })
    }
    if ((prevProps.dossier.entityComponent && prevProps.dossier.entityComponent.entityId &&
      (!this.props.dossier.entityComponent || !this.props.dossier.entityComponent.entityId) && !this.state.allInformationDisabled) ||
      (this.props.dossier.entityComponent && !this.props.dossier.entityComponent.dniCifValidationDisable && (this.state.dniCif !== null && this.state.dniCif !== '' &&
      (this.state.errorDniCif === 'dniNotValid' || this.state.errorDniCif === 'dniNotFormat' || this.state.errorDniCif === 'cifNotValid' || this.state.errorDniCif === 'cifNotFormat')))) {
      this.setState({ allInformationDisabled: true })
    }
    if (this.shouldOpenByFleetModal()) {
      this.setState({ open: true })
    }

    return null
  }

  componentDidUpdate () {
    if (this.props.dossier && this.props.dossier.entityComponent && this.props.dossier.entityComponent.entityId && this.state.disabledEntityType === false) {
      document.getElementById('1-tab-1').focus()
      this.setState({ openEntityType: false, disabledEntityType: true })
    } else if (this.props.dossier && this.props.dossier.entityComponent && !this.props.dossier.entityComponent.entityId && this.state.disabledEntityType) {
      this.setState({ disabledEntityType: false, dniDisabled: true })
    }
  }

  fetchSalesDossierPromiseClient () {
    if (this.state.open) {
      this.props.actions.setActiveSection(false)
    }
    this.setState({
      open: !this.state.open
    })
    if (this.props.isClientLoaded === false && this.props.dossier && (!this.props.dossier.entityComponent.entityTypeId || !this.props.dossier.entityComponent.autoLoadedEntity)) {
      return new Promise((resolve, reject) => {
        this.props.handleToUpdateComponent(this.props)
        if (this.props.dossierType === dossierTypesConstant.sales) {
          return this.props.actions.fetchSalesClient(this.props.dossier.dossierId, resolve, reject, this.props.pristine)
        } else if (this.props.dossierType === dossierTypesConstant.purchase) {
          return this.props.actions.fetchPurchaseClient(this.props.dossier.dossierId, resolve, reject, this.props.pristine)
        } else if (this.props.dossierType === dossierTypesConstant.paperwork) {
          return this.props.actions.fetchPaperworkClient(this.props.dossier.dossierId, resolve, reject, false, this.props.pristine)
        } else if (this.props.dossierType === dossierTypesConstant.campaign) {
          return this.props.actions.fetchCampaignClient(this.props.dossier.dossierId, resolve, reject, this.props.pristine)
        } else if (this.props.dossierType === dossierTypesConstant.registrationDelivery) {
          return this.props.actions.fetchRegistrationDeliveryClient(this.props.dossier.dossierId, resolve, reject, this.props.pristine)
        }
      })
    }
  }

  reset () {
    new Promise((resolve, reject) => {
      this.props.actions.resetValues(resolve, reject)
    }).then((result) => {
      if (result) {
        this.setState({ fleetsEditClient: true })
      }
    })
  }

  render () {
    const {
      t, entityChangeable, readOnlyFields, disableDeleteButton, isEditFleet, dossierType, dniDisabled, allInformationDisabled, rfcNoEditable, dossierRelatedDossiers, customFields, formValues,
      combos: { entityTypeCombo, channelCombo, maritalStatusCombo, segmentationCombo, entitySubTypeCombo, entityFilterSubTypeCombo, iaeTypeCombo, CSTCombo, activityBproCombo },
      dossier: {
        isPreCancelled, entityComponent, dossierContactComponent, dossierParentId, parentEntityId, entityChildComponent, entityTypeIdDefault,
        salesComponent, entityFromParentDossier, dossierSubTypeId
      },
      actions: {
        updateClientNoEmployee, setEntityValidationDisable, setEntityChildTypeId, fetchEntityCountryTypeCombo, fetchEntityStateTypeCombo,
        fetchEntityFilterSubTypeCombo, setEntityId, fetchEntityCityTypeCombo, fetchEntityTownTypeCombo, fetchEntityColonyTypeCombo,
        fetchEntityCPTypeCombo, fetchEntitySubTypeCombo, fetchEntityMaritalStatusCombo, openCommonModal, setEntityTypeId, setEntitySubTypeId, fetchClientFromDocument,
        change, checkPrivateRfcFormat, fetchClientByRfc, fetchClientByDni, clearClientFields, foreignPassportWarning, fetchClientByDniEntityChild, fetchActivityBpro,
        fetchClientByPhoneEmail, validate, dispatch, asyncValidate, stopAsyncValidation, fetchClientDataAddress, clearEntityChild
      }
    } = this.props
    const saleTypeCode = salesComponent && salesComponent.saleTypeCode
    const tKey = 'DOSSIER_COMPONENTS.ENTITY.'
    let disabledByNotSelectedEntityTypeId = (!this.props.dossier.entityComponent || !this.props.dossier.entityComponent.entityTypeId) && !entityTypeIdDefault
    let disabledByNotSelectedEntityChildTypeId = !this.props.dossier.entityChildComponent || !this.props.dossier.entityChildComponent.entityTypeId
    const isRecurrentEntityType = (entityComponent && entityComponent.entityTypeId ? entityComponent.entityTypeId === entityTypesId.recurrentEntity
      : entityTypeIdDefault ? entityTypeIdDefault === entityTypesId.recurrentEntity : false)
    const isParticularEntityType = (entityComponent && entityComponent.entityTypeId ? entityComponent.entityTypeId === entityTypesId.privateEntity
      : entityTypeIdDefault ? entityTypeIdDefault === entityTypesId.privateEntity : false)
    const organizedUnitId = this.props.dossier.organizedUnitId
    return (
      <div className="client-panel dossier-panel">
        {(this.props.dossierType !== dossierTypesConstant.createNewFleetModal && this.props.dossierType !== dossierTypesConstant.dataContactFleetModal) && (
          <div className="header-panel" onClick={this.fetchSalesDossierPromiseClient.bind(this)}>
            {(this.props.dossierType !== dossierTypesConstant.addNewFleetModal) &&
          <>
            <div className="name-wrapper">
              <i className="ico-user" />
              <h4>{this.getTitle()}</h4>
            </div>

            <i className={this.state.open ? 'ico-arrow-black-up' : 'ico-arrow-black-down'} />
          </>
            }
          </div>
        )}
        <Panel expanded={this.state.open} onToggle={() => {}}>
          <Panel.Collapse>
            {
              this.state.fieldsConfiguration.entityFromParentDossier && this.state.fieldsConfiguration.entityFromParentDossier.permission !== permissions.hidden &&
              ((dossierParentId !== null && dossierParentId !== undefined && parentEntityId !== undefined && parentEntityId !== null) ||
              (entityFromParentDossier && dossierSubTypeId !== dossierSubTypeIdConstant.venta_vn && dossierSubTypeId !== dossierSubTypeIdConstant.venta_vo)) &&
              (
                <Col sm={12}>
                  <Field
                    colSm={0}
                    id="entityFromParentDossier"
                    name="entityFromParentDossier"
                    placeholder={t('DOSSIER_COMPONENTS.UAC.MODAL.UAC_CLIENT.CLIENT_IS_SAME')}
                    component={InputCheckBox}
                    onChange={(event, value) => this.setEntityFromParent(value, event)}
                    disabled={readOnlyFields || this.state.fieldsConfiguration.entityFromParentDossier.permission !== permissions.editable}
                    inline
                  />
                </Col>
              )
            }
            {
              this.state.fieldsConfiguration.clientNotEmployee && this.state.fieldsConfiguration.clientNotEmployee.permission !== permissions.hidden &&
              (
                <Col sm={12}>
                  <Field
                    colSm={0}
                    id="clientNotEmployeeCheck"
                    name="salesComponent.clientNotEmployeeCheck"
                    placeholder={t(`${tKey}CLIENT_NOT_EMPLOYEE`)}
                    component={InputCheckBox}
                    inline
                    onChange={(value) => updateClientNoEmployee(value)}
                    disabled={readOnlyFields || this.state.fieldsConfiguration.clientNotEmployee.permission !== permissions.editable} />

                </Col>
              )
            }
            <Row className="btns_clearEntities">
              {!isPreCancelled && entityChildComponent && entityChildComponent.entityId && this.state.fieldsConfiguration.clearEntityChild && this.state.fieldsConfiguration.clearEntityChild.permission !== permissions.hidden && !disableDeleteButton &&
              <Col className="btn_clearEntity" style={{ textAlign: 'right' }}>
                <Button
                  disabled={this.state.fieldsConfiguration.clearEntityChild.permission !== permissions.editable}
                  id="btn_clearEntityChild"
                  className={(entityChildComponent.entityId) ? ' btn-standard' : ' btn-cancel'}
                  style={{ fontSize: '11px' }}
                  onClick={() => {
                    if (entityChildComponent.entityId) { this.setNewEntity(true) }
                  }}>
                  <i className="ico-cancel"/>{t('DOSSIER_COMPONENTS.ENTITY.CLEAR_ENTITY_CHILD')}
                </Button>
              </Col>
              }
              {!isPreCancelled && entityComponent && entityChangeable && entityComponent.entityId && this.state.fieldsConfiguration.clearEntity && this.state.fieldsConfiguration.clearEntity.permission === permissions.editable && !disableDeleteButton &&
              (!parentEntityId || parentEntityId !== entityComponent.entityId) && (!saleTypeCode || (saleTypeCode !== saleTypeCodes.SELFSUPPLY_VN && saleTypeCode !== saleTypeCodes.SELFSUPPLY_VO)) &&
              <Col className="btn_clearEntity" style={{ textAlign: 'right' }}>
                <Button
                  disabled={this.state.fieldsConfiguration.clearEntity.permission !== permissions.editable || this.props.dossier.isMarked}
                  id="btn_clearEntity" className={(entityComponent.entityId) ? ' btn-standard' : ' btn-cancel'}
                  style={{ fontSize: '11px' }}
                  onClick={() => {
                    if (entityComponent.entityId) {
                      this.setNewEntity(false)
                      this.state.tabActiveKey !== clientTabs.entity && this.setState({ tabActiveKey: clientTabs.entity })
                    }
                  }}>
                  <i className="ico-cancel"/>{t('DOSSIER_COMPONENTS.ENTITY.CLEAR_ENTITY')}
                </Button>
              </Col>
              }
              {/* Comment because is repeated but not sure if is necessary
               { (entityComponent && entityComponent.entityId && !this.state.fleetsEditClient && this.props.dossierType === dossierTypesConstant.createNewFleetModal && !readOnlyFields) &&
              <Col className="btn_clearEntity" style={{ textAlign: 'right' }} onClick={() => { this.reset() }}>
                <Button id="btn_clearEntity"
                  className='btn-standard'
                  style={{ fontSize: '11px' }} ><i className="ico-cancel"
                  />
                  {t('DOSSIER_COMPONENTS.ENTITY.CLEAR_ENTITY')}
                </Button>
              </Col>
              } */}
            </Row>
            <Tabs
              id="1"
              defaultActiveKey={this.props.isEditFleet ? clientTabs.contact : clientTabs.entity}
              activeKey={this.state.tabActiveKey}
              onSelect={tabActiveKey => this.setState({ tabActiveKey })}>
              { this.props.isEditFleet ? null : (
                <Tab eventKey={clientTabs.entity} title={this.getTitleEntityClient()} className="dossier-tab">
                  <ClientData
                    actions={{
                      setEntityValidationDisable,
                      fetchEntityCountryTypeCombo,
                      fetchEntityStateTypeCombo,
                      fetchEntityCityTypeCombo,
                      fetchEntityTownTypeCombo,
                      fetchEntityColonyTypeCombo,
                      fetchEntityCPTypeCombo,
                      fetchEntitySubTypeCombo,
                      fetchEntityMaritalStatusCombo,
                      openCommonModal,
                      setEntityTypeId,
                      setEntitySubTypeId,
                      fetchEntityFilterSubTypeCombo,
                      setEntityId,
                      fetchClientFromDocument,
                      change,
                      checkPrivateRfcFormat,
                      fetchClientByRfc,
                      fetchClientByDni,
                      clearClientFields,
                      foreignPassportWarning,
                      fetchClientByPhoneEmail,
                      fetchActivityBpro,
                      validate,
                      dispatch,
                      asyncValidate,
                      stopAsyncValidation,
                      fetchClientDataAddress,
                      clearEntityChild
                    }}
                    combos= {{
                      entityTypeCombo,
                      channelCombo,
                      maritalStatusCombo,
                      segmentationCombo,
                      entitySubTypeCombo,
                      entityFilterSubTypeCombo,
                      iaeTypeCombo,
                      CSTCombo,
                      activityBproCombo
                    }}
                    fieldsConfiguration={this.state.fieldsConfiguration}
                    dossier= {{ entityComponent, entityTypeIdDefault, organizedUnitId }}
                    readOnlyFields={this.state.fleetsEditClient === true ? !this.state.fleetsEditClient : readOnlyFields}
                    t={t}
                    isRecurrentEntityType = {isRecurrentEntityType}
                    isParticularEntityType = {isParticularEntityType}
                    disabledByNotSelectedEntityTypeId = {disabledByNotSelectedEntityTypeId}
                    entityChangeable={entityChangeable}
                    isEditFleet = {isEditFleet}
                    dossierType = {dossierType}
                    dniDisabled = {dniDisabled}
                    allInformationDisabled = {allInformationDisabled}
                    rfcNoEditable = {rfcNoEditable}
                    formValues={formValues}
                  />
                </Tab>
              )}
              {
                (isRecurrentEntityType &&
                  this.props.dossierType === dossierTypesConstant.purchase &&
                  !setting.clientFieldValidation === clientInformation.QUADIS) ||
                  this.props.dossierType === dossierTypesConstant.addNewFleetModal ? null : (
                    <Tab eventKey={clientTabs.contact} title={t(`${tKey}CONTACT`)} className="dossier-tab">
                      <Contact
                        fieldsConfiguration={this.state.fieldsConfiguration}
                        disabledByNotSelectedEntityTypeId = {disabledByNotSelectedEntityTypeId}
                        readOnlyFields={this.state.fleetsEditClient === true ? !this.state.fleetsEditClient : readOnlyFields}
                        t={t}
                        actions={{
                          setEntityValidationDisable,
                          fetchEntityCountryTypeCombo,
                          fetchEntityStateTypeCombo,
                          fetchEntityCityTypeCombo,
                          fetchEntityTownTypeCombo,
                          fetchEntityColonyTypeCombo,
                          fetchEntityCPTypeCombo,
                          openCommonModal,
                          fetchClientDataAddress
                        }}
                        dossier= {{ dossierContactComponent, entityTypeIdDefault }}
                        formValues={formValues}
                      />
                    </Tab>
                  )
              }
              {
                (!(salesComponent?.clientNotEmployeeCheck || isRecurrentEntityType) || this.props.dossierType === dossierTypesConstant.purchase || this.props.dossierType === dossierTypesConstant.addNewFleetModal) ? null : (
                  <Tab eventKey={clientTabs.finalEntity} title={t(`${tKey}FINAL_CLIENT`)} className="dossier-tab">
                    <FinalClient
                      fieldsConfiguration={this.state.fieldsConfiguration}
                      readOnlyFields={this.state.fleetsEditClient === true ? !this.state.fleetsEditClient : readOnlyFields}
                      disabledByNotSelectedEntityChildTypeId = {disabledByNotSelectedEntityChildTypeId}
                      t={t}
                      dossier= {{ entityChildComponent, salesComponent, dossierRelatedDossiers, organizedUnitId }}
                      dossierType = {this.props.dossierType}
                      actions={{
                        setEntityChildTypeId,
                        setEntityValidationDisable,
                        fetchEntityCountryTypeCombo,
                        fetchEntityStateTypeCombo,
                        fetchEntityCityTypeCombo,
                        fetchEntityTownTypeCombo,
                        fetchEntityColonyTypeCombo,
                        fetchEntityCPTypeCombo,
                        fetchClientByDniEntityChild,
                        openCommonModal,
                        checkPrivateRfcFormat,
                        fetchClientByRfc,
                        change,
                        fetchEntitySubTypeCombo,
                        validate,
                        dispatch,
                        asyncValidate,
                        stopAsyncValidation,
                        fetchClientDataAddress
                      }}
                      combos= {{
                        entityTypeCombo,
                        iaeTypeCombo
                      }}
                      isRecurrentEntityType = {isRecurrentEntityType}
                      formValues={formValues}
                    />
                  </Tab>
                )
              }
              {this.props.dossierType === dossierTypesConstant.paperwork && this.state.isDifferentPaperworkAddress && (
                <Tab eventKey={4} title={t(`${tKey}PAPERWORK_ADDRESS`)} className="dossier-tab">
                  <PaperworkAddress
                    fieldsConfiguration={this.state.fieldsConfiguration}
                    readOnlyFields={readOnlyFields}
                    t={t}
                    formInitRoute="dossierPaperworkComponent."
                  />
                </Tab>
              )}
              {(this.props.dossierType === dossierTypesConstant.paperwork) &&
                this.state.fieldsConfiguration.entityProviderId && this.state.fieldsConfiguration.entityProviderId.permission !== permissions.hidden && (
                <Tab eventKey={5} title={getTitleEntityProvider(dossierType, t, dossierRelatedDossiers)} className="dossier-tab">
                  <EntityProvider
                    t={t}
                    dossier={this.props.dossier}
                    combos={this.props.combos}
                    entityChangeable={entityChangeable}
                    dossierType={dossierTypesConstant.paperwork}
                    actions={this.props.actions}
                    handleToUpdateComponent= {this.handleToUpdateComponent}
                    isClientLoaded = {this.props.isClientLoaded}
                    pristine={this.props.pristine}
                    open={this.props.open}
                    componentConfiguration={this.props.componentConfiguration}
                    readOnlyFields={this.props.readOnlyFields}
                    updateComponentAfterDeleteAndRestart={this.props.updateComponentAfterDeleteAndRestart}
                  />
                </Tab>
              )}
            </Tabs>
            <CustomFields customFields={customFields} readOnlyFields={readOnlyFields} />
          </Panel.Collapse>
        </Panel>
      </div>
    )
  }
}
export default Client
