import apiFetch from '../apiFetch'

export default function (token, includeDeleted = false) {
  const queryParamsArray = [
    includeDeleted ? `includeDeleted=${includeDeleted}` : null
  ]
  const queryParams = queryParamsArray.reduce(
    (result, query, idx) => result + (query ? (result ? `&${query}` : `?${query}`) : '')
    , ''
  )
  return apiFetch({
    endPoint: `Fleets/GetFleets${queryParams}`,
    method: 'GET',
    body: null,
    token: token
  })
}
