
import React, { PureComponent } from 'react'
import { reduxForm, Field } from 'redux-form'
import { Modal, Col, Button, Row, FormGroup, Form } from 'react-bootstrap'
import InputText from '../../commons/form/InputText'

class ZipModal extends PureComponent {
  constructor () {
    super()
    this.state = {
    }
  }

  componentDidMount () {}

  componentDidUpdate (prevProps, prevState) {
  }

  cancel () {
    this.props.actions.closeZipModal()
  }

  save (values) {
    new Promise((resolve, reject) => {
      this.props.actions.createMassiveDownload(this.props.zipModal?.number, values.name, values.observations, resolve)
    }).then((result) => {
      this.props.actions.closeZipModal()
    })
  }

  render () {
    const {
      t, showModal, handleSubmit
    } = this.props
    const tKey = 'MANAGEMENT.SEARCH_DOCUMENTAL.MODAL.'
    return (
      <>
        <Modal className="rr-purchase-modal cdaModal" show={showModal} onHide={() => this.cancel()} backdrop={'static'} >
          <Modal.Header closeButton>
            <Modal.Title>{t(`${tKey + 'TITLE'}`)}</Modal.Title>
          </Modal.Header>
          <Form autoComplete="off" onSubmit={handleSubmit(this.save.bind(this))}>
            <Modal.Body>
              <FormGroup>
                <Row className="inputs-wrapper">
                  <Field
                    colSm={12}
                    id="name"
                    name="name"
                    controlLabel={t(`${tKey}NAME`)}
                    placeholder={t(`${tKey}NAME`)}
                    component={InputText}
                  />
                  <Col sm={12}>
                    <Field
                      id="observations"
                      name="observations"
                      colSm={12}
                      componentClass="textarea"
                      controlLabel={t(`${tKey}OBSERVATIONS`)}
                      placeholder={t(`${tKey}OBSERVATIONS`)}
                      component={InputText}
                      maxLength={512}
                    />
                  </Col>
                </Row>
              </FormGroup>
            </Modal.Body>
            <Modal.Footer>
              <Col sm={6}>
                <Button
                  className="btn-standard"
                  type="submit">
                  {t(`${tKey}ACEPT`)}
                </Button>
              </Col>
              <Col sm={6} className="btn-wrapper">
                <Button
                  id="role-modal-submit-button"
                  className="btn-danger"
                  onClick = { () => { this.cancel() }}>
                  {t(`${tKey}CANCEL`)}
                </Button>
              </Col>
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    )
  }
}

export default reduxForm({
  form: 'ZipModal',
  shouldValidate: () => true,
  validate: (values, props) => {
    const errors = {}
    if (!values.name || values.name === undefined) {
      errors.name = props.t('DOSSIER_COMPONENTS.VALIDATIONS.MANDATORY_FIELD')
      return errors
    }
  }
})(ZipModal)
