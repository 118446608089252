import { put, call, select, takeEvery } from 'redux-saga/effects'
import { change, reset } from 'redux-form'
import { getAuth } from '../../selectors/access/auth'
import { getRecurrentEntityFilter } from '../../selectors/admin/recurrentEntityAdmin'
import recurrentEntityActionTypes from '../../constants/actions/admin/recurrentEntityAdmin'
import { fetchRecurrentEntityAdminSuccess, openAddRecurrentEntityModalSuccess, openEditRecurrentEntityModalSuccess, closeEditRecurrentEntityModal, fetchDocumentListAdminSuccess, fetchEntitySubTypesListSuccess, setChangeTabFromMasterSuccess, getUserRecurrentServiceEntitySuccess } from '../../actions/admin/recurrentEntityAdmin'
import { showLoader, hideLoader } from '../../actions/common.js'
import postEntitiesListService from '../../services/entity/postEntitiesList'
import getEntityService from '../../services/entity/getEntityById'
import putEntityService from '../../services/entity/putEntity'
import postEntityService from '../../services/entity/postEntity'
import deleteEntityService from '../../services/entity/deleteEntity'
import postRecEntityDocumentUploadMass from '../../services/entity/postRecEntityDocumentUploadMass'
import getDocumentsByEntityId from '../../services/document/getDocumentsByEntityId'
import { yesNoModal } from '../modalsListeners/yesNoModal'
import { handleError } from '../errors/errorManager'
import getEntitySubTypesList from '../../services/entitySubType/getEntitySubTypesList'
import { fetchUploadTestWithDocs } from '../../actions/masters/masters'
import messageModalActions from '../../actions/modals/messageModal'
import getUserRecurrentServiceEntity from '../../services/entity/getUserRecurrentServiceEntity'
import postUserRecurrentServiceEntity from '../../services/entity/postUserRecurrentServiceEntity'
import deleteUserRecurrentServiceEntity from '../../services/entity/deleteUserRecurrentServiceEntity'
export function * fetchEntityList ({ filter }) {
  try {
    yield put(showLoader())
    yield filter = yield filter || select(getRecurrentEntityFilter)
    const auth = yield select(getAuth)
    const recurrentEntityList = yield call(postEntitiesListService, auth.token, filter)
    yield put(fetchRecurrentEntityAdminSuccess(recurrentEntityList, filter))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchEntitiesList () {
  yield takeEvery(recurrentEntityActionTypes.FETCH_RECURRENT_ENTITY_ADMIN, fetchEntityList)
}

export function * openAddEntityModal () {
  try {
    yield put(showLoader())
    yield put(openAddRecurrentEntityModalSuccess())
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchOpenAddEntityModal () {
  yield takeEvery(recurrentEntityActionTypes.OPEN_ADD_RECURRENT_ENTITY_MODAL, openAddEntityModal)
}

export function * openEditEntityModal ({ entityId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const entity = yield call(getEntityService, entityId, auth.token)
    yield put(change('editRecurrentEntity', 'recurrentEntity', entity))
    yield put(openEditRecurrentEntityModalSuccess(entity))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchOpenEditEntityModal () {
  yield takeEvery(recurrentEntityActionTypes.OPEN_EDIT_RECURRENT_ENTITY_MODAL, openEditEntityModal)
}

export function * submitEntity ({ entityId, body, filter }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const filter = yield filter || select(getRecurrentEntityFilter)
    if (entityId) {
      yield call(putEntityService, entityId, body, auth.token)
    } else {
      yield call(postEntityService, body, auth.token)
    }
    yield put(closeEditRecurrentEntityModal())
    const entityList = yield call(postEntitiesListService, auth.token, filter)
    yield put(fetchRecurrentEntityAdminSuccess(entityList, filter))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSubmitEntity () {
  yield takeEvery(recurrentEntityActionTypes.SUBMIT_RECURRENT_ENTITY, submitEntity)
}

export function * deleteEntity ({ entityId, filter }) {
  try {
    const confirmed = yield call(yesNoModal, 'deleteEntity')
    if (confirmed) {
      yield put(showLoader())
      const auth = yield select(getAuth)
      const filter = yield filter || select(getRecurrentEntityFilter)
      yield call(deleteEntityService, entityId, auth.token)
      const entityList = yield call(postEntitiesListService, auth.token, filter)
      yield put(fetchRecurrentEntityAdminSuccess(entityList, filter))
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDeleteEntity () {
  yield takeEvery(recurrentEntityActionTypes.DELETE_RECURRENT_ENTITY, deleteEntity)
}

export function * fetchDocumentList ({ entityId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const documents = yield call(getDocumentsByEntityId, entityId, auth.token)
    yield put(fetchDocumentListAdminSuccess(documents, entityId))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchDocumentList () {
  yield takeEvery(recurrentEntityActionTypes.FETCH_DOCUMENT_LIST_ADMIN, fetchDocumentList)
}

export function * fetchEntitySubTypesList () {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const recurrentEntitySubTypesList = yield call(getEntitySubTypesList, auth.token)
    yield put(fetchEntitySubTypesListSuccess(recurrentEntitySubTypesList))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchEntitySubTypesList () {
  yield takeEvery(recurrentEntityActionTypes.FETCH_ENTITY_SUB_TYPES_LIST, fetchEntitySubTypesList)
}

export function * uploadRecEntMassDocument ({ files, code, callback, resolve, t }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    let modalState = JSON.parse(JSON.stringify(yield select(state => state.modalMassUpload)))
    let response = yield call(postRecEntityDocumentUploadMass, code, auth.token, files)
    let confirmation = modalState.code && modalState.code === response.token
    let succes = response.totalRecords === response.correct && (!response.docListErrors || response.docListErrors.length === 0)
    let errors = []
    let docErrors = []
    if (response.recEntErrors && response.recEntErrors.length > 0) {
      response.recEntErrors.map((er, i) => {
        let recEntError = {}
        recEntError.errorHeader = er.rowNumber ? er.rowNumber : er.recEntName
        recEntError.errorMessage = er.errorMessage ? t('ERRORS.BACKEND_TAG_TRANSLATION.' + er.errorMessage)
          : er.columErrors && er.columErrors.length > 0 ? er.columErrors.map((ms, i) => { return t('ERRORS.BACKEND_TAG_TRANSLATION.' + ms) + '. ' }) : ''
        errors.push(recEntError)
      })
    }
    if (response.docListErrors && response.docListErrors.length > 0) {
      response.docListErrors.map((er, i) => {
        let docEr = {}
        docEr.errorHeader = er.docName
        docEr.errorMessage = t('ERRORS.BACKEND_TAG_TRANSLATION.' + er.docError)
        docErrors.push(docEr)
      })
    }
    yield put(fetchUploadTestWithDocs(response.recEntCreated, errors, succes, succes ? response.token : null, response.StorageConfigError, docErrors))
    if (succes && confirmation && callback) yield call(callback)
    if (resolve) yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MASTERS.OU_MASTERS.FETCH_ERROR_MSG', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('DocumentMassUpload'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchRecEntLoadMassDocument () {
  yield takeEvery(recurrentEntityActionTypes.UPLOAD_REC_ENTITY_MASS_DOCUMENT, uploadRecEntMassDocument)
}

export function * setChangeTabFromMaster ({ ouIdFromMaster, changeTabFromMaster }) {
  if (ouIdFromMaster) {
    yield put(change('listRecurrentEntity', 'filters.organizedUnit', ouIdFromMaster.toString()))
  }
  yield put(setChangeTabFromMasterSuccess(ouIdFromMaster, changeTabFromMaster))
}

export function * watchSetChangeTabFromMaster () {
  yield takeEvery(recurrentEntityActionTypes.SET_CHANGE_TAB_FROM_MASTER, setChangeTabFromMaster)
}

export function * fetchUserRecurrentServiceEntity ({ entityId, orderBy }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const userList = yield call(getUserRecurrentServiceEntity, entityId, orderBy, auth.token)
    yield put(getUserRecurrentServiceEntitySuccess(entityId, orderBy, userList))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchUserRecurrentServiceEntity () {
  yield takeEvery(recurrentEntityActionTypes.GET_USER_RECURRENT_SERVICE, fetchUserRecurrentServiceEntity)
}

export function * saveUserRecurrentServiceEntity ({ entityId, userId, resolve }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(postUserRecurrentServiceEntity, entityId, userId, auth.token)
    if (resolve) yield call(resolve)
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSaveUserRecurrentServiceEntity () {
  yield takeEvery(recurrentEntityActionTypes.SET_USER_RECURRENT_SERVICE, saveUserRecurrentServiceEntity)
}

export function * delUserRecurrentServiceEntity ({ userRecurrentServiceEntityId, resolve }) {
  try {
    const confirmed = yield call(yesNoModal, 'yesNoModal', null, null, 'DELETE_RECURRENT_SERVICE_ENTITY')
    if (confirmed) {
      yield put(showLoader())
      const auth = yield select(getAuth)
      yield call(deleteUserRecurrentServiceEntity, userRecurrentServiceEntityId, auth.token)
      if (resolve) yield call(resolve)
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDeleteUserRecurrentServiceEntity () {
  yield takeEvery(recurrentEntityActionTypes.DELETE_USER_RECURRENT_SERVICE, delUserRecurrentServiceEntity)
}
