import { call, put, select, takeEvery } from 'redux-saga/effects'
import { reset, change } from 'redux-form'
import { getAuth } from '../../selectors/access/auth'
import getOperationsTypes from '../../services/saleType/getOperationType'
import getAllOperationTypes from '../../services/operationType/getAllOperationTypes'
import getOperationTypesByOu from '../../services/operationType/getOperationTypesByOu'
import getFleetOperationTypeAutoSelect from '../../services/operationType/getFleetOperationTypeAutoSelect'
import commonActions from '../../actions/common'
import messageModalActions from '../../actions/modals/messageModal'
import { fetchOperationTypeSuccess, fetchOperationTypeModalSuccess } from '../../actions/combos/combos'
import { fetchFleetOperationTypeForAutoselectSuccess } from '../../actions/dossier/sales'
import combosActionTypes from '../../constants/actions/combos/combos'
import { handleError } from '../errors/errorManager'

export function * fetchOperationType ({ saleTypeId, ouId, isModal, isFleet }) {
  try {
    yield put(commonActions.showLoader())
    const auth = yield select(getAuth)
    let operationsTypes = []

    if (isModal) {
      operationsTypes = yield call(getAllOperationTypes, auth.token)
      yield put(fetchOperationTypeModalSuccess(operationsTypes))
    } else {
      if (saleTypeId) {
        operationsTypes = yield call(getOperationsTypes, saleTypeId, ouId, auth.token, false, null)
      } else if (ouId && isFleet) {
        operationsTypes = yield call(getOperationTypesByOu, auth.token, ouId, true)
      }
      yield put(fetchOperationTypeSuccess(operationsTypes))
    }
  } catch (error) {
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    const customHandler = function * () {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.FETCH_COMBO_ERROR'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchFetchOperationType () {
  yield takeEvery(combosActionTypes.FETCH_OPERATION_TYPE, fetchOperationType)
}

export function * fetchFleetOperationTypeForAutoselect ({ organizedUnitId }) {
  try {
    const auth = yield select(getAuth)
    let fleetOperationTypeId = yield call(getFleetOperationTypeAutoSelect, organizedUnitId, auth.token)
    if (fleetOperationTypeId) {
      yield put(fetchFleetOperationTypeForAutoselectSuccess(fleetOperationTypeId))

      const salesDossier = JSON.parse(JSON.stringify(yield select(state => state.salesDossier)))
      let operationTypeIds = salesDossier.salesComponent.operationTypeIds
      yield put(change('salesDossier', 'salesComponent.operationTypeIds', operationTypeIds))
    }
  } catch (error) {
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    const customHandler = function * () {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.FETCH_COMBO_ERROR'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchFleetOperationTypeForAutoselect () {
  yield takeEvery(combosActionTypes.FETCH_FLEET_OPERATION_TYPE_FOR_AUTOSELECT, fetchFleetOperationTypeForAutoselect)
}
