import React, { PureComponent } from 'react'
import { Button, Modal, Col, Table, OverlayTrigger, Tooltip, Glyphicon } from 'react-bootstrap'

class VehiclesListModal extends PureComponent {
  render () {
    const { t, showModal, vehicles = [], actions: { closeModal, chooseUACSuggestion } } = this.props
    const tKey = 'DOSSIER_COMPONENTS.UAC.MODAL.VEHICLE.SUGGESTION_LIST_MODAL.'
    const navigationTooltip = (<Tooltip id="urgenciaTooltip">{t(`${tKey}CHOSE_BUTTON`)}</Tooltip>)
    return (
      <Modal className="uac-assign-modal" show={showModal} onHide={() => closeModal()}>
        <Modal.Header>
          <p>{t(`${tKey}TITLE`)}</p>
        </Modal.Header>
        <Modal.Body>
          <div className="tables-add-dossier">
            <Table>
              <thead>
                <tr>
                  <th>{t(`${tKey}DOSSIER_NUMBER`)}</th>
                  <th>{t(`${tKey}DEALER_NUMBER`)}</th>
                  <th>{t(`${tKey}BRAND`)}</th>
                  <th>{t(`${tKey}MODEL`)}</th>
                  <th>{t(`${tKey}PLATE_NUMBER`)}</th>
                  <th>{t(`${tKey}CHASSIS`)}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {
                  vehicles.map((vehicle, idx) => {
                    return (
                      <tr key={idx}>
                        <td>{vehicle.number}</td>
                        <td>{vehicle.dealerNumber}</td>
                        <td>{vehicle.vehicleBrand}</td>
                        <td>{vehicle.vehicleModel}</td>
                        <td>{vehicle.licensePlate}</td>
                        <td>{vehicle.vinChassis}</td>
                        <td>
                          <OverlayTrigger placement="left" overlay={navigationTooltip}>
                            <Glyphicon
                              glyph="new-window"
                              onClick={() => chooseUACSuggestion(vehicle.purchaseId, vehicle, closeModal)} />
                          </OverlayTrigger>
                        </td>
                      </tr>
                    )
                  })
                }
              </tbody>
            </Table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Col sm={6} className="btn-wrapper">
            <Button className="btn-standard-disabled" onClick={() => closeModal(true)}>{t(`${tKey}CLOSE_BUTTON`)}</Button>
          </Col>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default VehiclesListModal
