import apiFetch from '../apiFetch'

export default function (token, dossierId, referenceType, referenceId) {
  return apiFetch({
    endPoint: `Document/FromTemplateByReferenceType?referenceType=${referenceType}&referenceId=${referenceId}&dossierId=${dossierId}`,
    method: 'GET',
    token: token,
    parseBlobAndAssign: true
  })
}
