import mastersActionTypes from '../../../constants/actions/masters/masters'

export function fetchDocumentTypeMetadataList ({ filter, documentTypeId }) {
  return {
    type: mastersActionTypes.FETCH_DOCUMENT_TYPE_METADATA_LIST,
    filter,
    documentTypeId
  }
}

export function fetchDocumentTypeMetadataListSuccess (documentTypeMetadataList, filter) {
  return {
    type: mastersActionTypes.FETCH_DOCUMENT_TYPE_METADATA_LIST_SUCCESS,
    documentTypeMetadataList,
    filter
  }
}

export function deleteDocumentTypeMetadata (id, documentTypeId) {
  return {
    type: mastersActionTypes.DELETE_DOCUMENT_TYPE_METADATA,
    id,
    documentTypeId
  }
}

export function openDocumentTypeMetadataModal (documentTypeMetadata, documentTypeId) {
  return {
    type: mastersActionTypes.OPEN_DOCUMENT_TYPE_METADATA_MODAL,
    documentTypeMetadata,
    documentTypeId
  }
}

export function openDocumentTypeMetadataModalSuccess (languageList, documentTypeMetadata, documentTypeId) {
  return {
    type: mastersActionTypes.OPEN_DOCUMENT_TYPE_METADATA_MODAL_SUCCESS,
    languageList,
    documentTypeMetadata,
    documentTypeId
  }
}

export function closeMetadataMasterModal () {
  return {
    type: mastersActionTypes.CLOSE_EDIT_DOCUMENT_TYPE_METADATA_MODAL
  }
}

export function submitDocumentTypeMetadata (documentTypeMetadata, isEditedCriticalProperty) {
  return {
    type: mastersActionTypes.SUBMIT_DOCUMENT_TYPE_METADATA,
    documentTypeMetadata,
    isEditedCriticalProperty
  }
}

export function openMetadataListModal (documentTypeMetadata) {
  return {
    type: mastersActionTypes.OPEN_METADATA_LIST_MODAL,
    documentTypeMetadata
  }
}

export function openMetadataListModalSuccess (languageList, metadata, metadataList) {
  return {
    type: mastersActionTypes.OPEN_METADATA_LIST_MODAL_SUCCESS,
    languageList,
    metadata,
    metadataList
  }
}

export function closeMetadataListMasterModal () {
  return {
    type: mastersActionTypes.CLOSE_METADATA_LIST_MODAL
  }
}

export function deleteMetadataListElement (id, documentTypeMetadata) {
  return {
    type: mastersActionTypes.DELETE_METADATA_LIST_ELEMENT,
    id,
    documentTypeMetadata
  }
}

export function submitMetadataList (metadataListElement, metadata) {
  return {
    type: mastersActionTypes.SUBMIT_METADATA_LIST,
    metadataListElement,
    metadata
  }
}
