import apiFetch from '../apiFetch'
export default function (organizedUnitId, token, includeDeleted = false, selectedValue) {
  const queryParamsArray = [
    organizedUnitId ? `organizedUnitId=${organizedUnitId}` : null,
    `includeDeleted=${includeDeleted}`,
    `selectedValue=${selectedValue}`
  ]
  const queryParams = queryParamsArray.reduce(
    (result, query, idx) => result + (query ? (result ? `&${query}` : `?${query}`) : '')
    , ''
  )
  return apiFetch({ endPoint: 'financeCompany/' + queryParams, method: 'GET', body: null, token: token })
}
