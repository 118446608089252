import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { closeEditFinancingModalUo, saveUoFinancingCompany, deleteUoFinancingCompany, recoverUoFinancingCompany, openEditFinancingModalUo } from '../../../actions/masters/masters'
import FinancingMasterModalUo from '../../../components/masters/financing/FinancingMasterModalUo'
import { openModal, yesNoModal } from '../../../actions/common'
export function mapStateToProps (state) {
  return {
    ...state.financing.financingModalUo,
    combos: {
      UOTreeCombo: state.combos.UOTreeCombo
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      closeEditFinancingModalUo,
      saveUoFinancingCompany,
      openModal,
      deleteUoFinancingCompany,
      recoverUoFinancingCompany,
      yesNoModal,
      openEditFinancingModalUo
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(FinancingMasterModalUo))
