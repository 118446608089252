import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Tasks from '../../components/tasks/Tasks'
import { translate } from 'react-polyglot'
// import { withRouter } from 'react-router-dom'
import TrackingContainer from '../../containers/tracking/trackingContainer'

export function mapStateToProps (state) {
  return {
    ...state
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
    }, dispatch)
  }
}

var decoratedComponent = TrackingContainer(Tasks, true)
export default connect(mapStateToProps, mapDispatchToProps)(translate()(decoratedComponent))
