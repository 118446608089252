import apiFetch from '../../apiFetch'

export default function (isVo, dealerNumber, vinChassis, brand, model, color, makerNumber, availabilityDateFrom, availabilityDateTo, token, organizedUnit,
  carlineId, catalogueId, modelId, catalogueModelId, colorId, tapestryId, carline, catalogue, modelYear
) {
  const queryParamsArray = [
    `organizedUnitId=${organizedUnit}`,
    isVo ? 'isVo=true' : null,
    dealerNumber ? `dealerNumber=${dealerNumber}` : null,
    vinChassis ? `vinChassis=${vinChassis}` : null,
    brand ? `brand=${brand}` : null,
    model ? `model=${model}` : null,
    color ? `color=${color}` : null,
    makerNumber ? `makerNumber=${makerNumber}` : null,
    availabilityDateFrom ? `availabilityDateFrom=${availabilityDateFrom}` : null,
    availabilityDateTo ? `availabilityDateTo=${availabilityDateTo}` : null,
    carlineId ? `carlineId=${carlineId}` : null,
    catalogueId ? `catalogueId=${catalogueId}` : null,
    modelId ? `modelId=${modelId}` : null,
    catalogueModelId ? `catalogueModelId=${catalogueModelId}` : null,
    colorId ? `colorId=${colorId}` : null,
    tapestryId ? `tapestryId=${tapestryId}` : null,
    carline ? `carline=${carline}` : null,
    catalogue ? `catalogue=${catalogue}` : null,
    modelYear ? `modelYear=${modelYear}` : null
  ]
  const queryParams = queryParamsArray.reduce(
    (result, query, idx) => result + (query ? (result ? `&${query}` : `?${query}`) : '')
    , ''
  )
  return apiFetch({ endPoint: `dossier/purchase${queryParams}`, method: 'GET', token: token })
}
