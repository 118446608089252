import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import ReceiptReport from '../../../components/report/receipt/ReceiptReport'
import { fetchReceiptList, downloadReceiptList } from '../../../actions/reports/receiptReport'
import { fetchBrands } from '../../../actions/combos/combos'

export function mapStateToProps (state) {
  return {
    ...state.receiptReport,
    combos: {
      brandCombo: state.combos.brandCombo,
      salesmanCombo: state.combos.salesmanCombo,
      lowestOrganizedUnitsCombo: state.combos.lowestOrganizedUnitsCombo
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchReceiptList,
      fetchBrands,
      downloadReceiptList
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(ReceiptReport))
