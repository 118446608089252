import { put, call, select, takeEvery } from 'redux-saga/effects'
import { showLoader, hideLoader } from '../../../actions/common.js'
import mastersActionTypes from '../../../constants/actions/masters/masters'
import { getAuth } from '../../../selectors/access/auth'
import { handleError } from '../../errors/errorManager'
import { yesNoModal } from '../../modalsListeners/yesNoModal'
import { getCustomFieldFilter } from '../../../selectors/masters/customField'
import {
  fetchCustomFieldListSuccess
  , openCustomFieldModalSuccess
  , closeCustomFieldModal
} from '../../../actions/masters/customField/customField'
import getLanguageListComboService from '../../../services/language/getLanguageList'
import postCustomFieldList from '../../../services/customField/postCustomFieldList'
import postCustomFieldService from '../../../services/customField/postCustomField'
import putCustomFieldService from '../../../services/customField/putCustomField'
import deleteCustomFieldService from '../../../services/customField/deleteCustomField'
import recoverCustomFieldService from '../../../services/customField/recoverCustomField'
import getViewComponentsByDossierSubtype from '../../../services/viewComponents/getViewComponentsByDossierSubtype.js'

export function * deleteCustomField ({ customFieldId }) {
  try {
    const confirmed = yield call(yesNoModal, 'deleteCustomField')
    if (confirmed) {
      yield put(showLoader())
      const auth = yield select(getAuth)
      const filter = yield filter || select(getCustomFieldFilter)
      yield call(deleteCustomFieldService, auth.token, customFieldId)
      yield call(fetchCustomFieldList, filter)
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDeleteCustomField () {
  yield takeEvery(mastersActionTypes.DELETE_CUSTOM_FIELD, deleteCustomField)
}

export function * recoverCustomField ({ id }) {
  try {
    const confirmed = yield call(yesNoModal, 'recoverCustomField')
    if (confirmed) {
      yield put(showLoader())
      const auth = yield select(getAuth)
      const filter = yield filter || select(getCustomFieldFilter)
      yield call(recoverCustomFieldService, auth.token, id)
      yield call(fetchCustomFieldList, filter)
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchRecoverCustomField () {
  yield takeEvery(mastersActionTypes.RECOVER_CUSTOM_FIELD, recoverCustomField)
}

export function * submitCustomField ({ customField }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const filter = yield filter || select(getCustomFieldFilter)

    if (!customField.customFieldId) {
      yield call(postCustomFieldService, auth.token, customField)
    } else {
      yield call(putCustomFieldService, auth.token, customField)
    }

    yield put(closeCustomFieldModal())
    yield call(fetchCustomFieldList, filter)
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSubmitCustomField () {
  yield takeEvery(mastersActionTypes.SUBMIT_CUSTOM_FIELD, submitCustomField)
}

export function * fetchCustomFieldList ({ filter }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield filter = yield filter || select(getCustomFieldFilter)
    const customFieldList = yield call(postCustomFieldList, auth.token, filter)
    yield put(fetchCustomFieldListSuccess(customFieldList, filter))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchCustomFieldList () {
  yield takeEvery(mastersActionTypes.FETCH_CUSTOM_FIELD_LIST, fetchCustomFieldList)
}

export function * openCustomFieldModal ({ customField, dossierSubTypeId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const languageList = yield call(getLanguageListComboService, auth.token)
    const viewComponents = yield call(getViewComponentsByDossierSubtype, dossierSubTypeId, auth.token)

    if (!customField) {
      customField = { dossierSubTypeId }
    }

    for (const language in languageList) {
      customField[`lang${languageList[language].languageId}`] = {}
      customField[`lang${languageList[language].languageId}`].languageId = languageList[language].languageId
      let source = customField ? customField.documentTypeLocales?.find((sourceChannel) => sourceChannel.languageId === languageList[language].languageId) : null
      customField[`lang${languageList[language].languageId}`].name = source ? source.name : ''
      customField[`lang${languageList[language].languageId}`].description = source ? source.description : ''
    }

    yield put(openCustomFieldModalSuccess(languageList, customField, viewComponents))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchOpenCustomFieldModal () {
  yield takeEvery(mastersActionTypes.OPEN_CUSTOM_FIELD_MODAL, openCustomFieldModal)
}
