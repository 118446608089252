import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import SearchByFleetsForm from '../../../components/search/SearchByFleetsForm'
import { translate } from 'react-polyglot'

export function mapStateToProps (state) {
  return {
    combos: {
      salesmanCombo: state.combos.salesmanCombo,
      activeInactiveCombo: state.combos.activeInactiveCombo
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(SearchByFleetsForm))
