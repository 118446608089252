import documentHistoricalEditionModalActionTypes from '../../../constants/actions/dossier/common/documentHistoricalEditionModal'
import { documentEntityType as documentEntityTypeConstant } from '../../../constants/dossier/common/documentEntityType'

export function initialState () {
  return {
    showDocumentEditionModal: false,
    refreshAfterChange: false,
    dossierType: '',
    dossierSubType: '',
    documentParentTypeId: null,
    documentTypeUseName: '',
    documentTypeUseId: null,
    organizedUnitId: null,
    callback: null,
    dossierId: null,
    entityId: null,
    vehicleId: null,
    checklistId: null,
    docResult: {
      documentId: null,
      typeView: null,
      documentStatusId: null,
      thumbnail: '',
      documentTypeId: null,
      fields: [],
      rejectReason: '',
      fullScreenDocument: '',
      incorporationDate: '',
      sheets: [],
      numPages: null
    },
    showFullScreenDocumentModalUrl: '',
    activeSheet: 0,
    activePage: 1
  }
}

export function openDocumentCreationModalSuccess (state, { documentParentTypeId, organizedUnitId, dossierId, entityId, vehicleId, callback, refreshAfterChange, dossierType }) {
  return {
    ...state,
    showDocumentEditionModal: true,
    documentParentTypeId,
    organizedUnitId,
    dossierId,
    entityId,
    vehicleId,
    callback,
    refreshAfterChange,
    dossierType,
    activeSheet: 0,
    activePage: 1
  }
}

export function openDocumentEditionModalSuccess (state, { docResult, documentTypeUseInfo, organizedUnitId, callback = null, dossierId, entityId, vehicleId, documentParentTypeId, checklistId, refreshAfterChange, dossierType, dossierSubType }) {
  const initialDocResult = initialState().docResult
  return {
    ...state,
    showDocumentEditionModal: true,
    organizedUnitId,
    documentTypeUseName: documentTypeUseInfo.value,
    documentTypeUseId: documentTypeUseInfo.id,
    docResult: { ...initialDocResult, ...docResult },
    callback,
    dossierId,
    entityId,
    vehicleId,
    documentParentTypeId,
    checklistId,
    refreshAfterChange,
    dossierType,
    dossierSubType,
    activeSheet: 0,
    activePage: 1
  }
}

export function closeAddOrEditDocumentModal (state, { refreshAfterChange }) {
  return {
    ...state,
    refreshAfterChange
  }
}

export function closeAddOrEditDocumentModalSuccess (state) {
  return initialState()
}

export function uploadNewVersion (state) {
  const initialDocResult = initialState().docResult
  return {
    ...state,
    docResult: {
      ...initialDocResult,
      typeView: state.docResult.typeView,
      fields: state.docResult.fields
    }
  }
}

export function changeId (state, { id }) {
  switch (state.documentParentTypeId) {
    case documentEntityTypeConstant.ENTITY:
      return { ...state, entityId: id }
    case documentEntityTypeConstant.VEHICLE:
      return { ...state, vehicleId: id }
    default:
      return state
  }
}

export function fetchDocumentSuccess (state, { docResult, documentTypeId, documentTypeUseName }) {
  const initialDocResult = initialState().docResult
  return {
    ...state,
    documentTypeUseId: documentTypeId || state.documentTypeUseId,
    documentTypeUseName: documentTypeUseName || state.documentTypeUseName,
    docResult: { ...initialDocResult, ...docResult }
  }
}

export function recoverDocument (state, { documentId }) {
  return {
    ...state,
    documentId
  }
}

export function setActivePage (state, { sheetId, pageId }) {
  return {
    ...state,
    activeSheet: sheetId,
    activePage: pageId
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case documentHistoricalEditionModalActionTypes.OPEN_DOCUMENT_HISTORICAL_CREATION_MODAL_SUCCESS:
      return openDocumentCreationModalSuccess(state, action)
    case documentHistoricalEditionModalActionTypes.OPEN_DOCUMENT_HISTORICAL_EDITION_MODAL_SUCCESS:
      return openDocumentEditionModalSuccess(state, action)
    case documentHistoricalEditionModalActionTypes.CLOSE_ADD_OR_EDIT_DOCUMENT_HISTORICAL_MODAL:
      return closeAddOrEditDocumentModal(state, action)
    case documentHistoricalEditionModalActionTypes.CLOSE_ADD_OR_EDIT_DOCUMENT_HISTORICAL_MODAL_SUCCESS:
      return closeAddOrEditDocumentModalSuccess(state, action)
    case documentHistoricalEditionModalActionTypes.UPLOAD_NEW_VERSION_HISTORICAL:
      return uploadNewVersion(state, action)
    case documentHistoricalEditionModalActionTypes.FETCH_DOCUMENT_HISTORICAL_SUCCESS:
      return fetchDocumentSuccess(state, action)
    case documentHistoricalEditionModalActionTypes.CHANGE_ID_HISTORICAL:
      return changeId(state, action)
    case documentHistoricalEditionModalActionTypes.RECOVER_DOCUMENT_HISTORICAL:
      return recoverDocument(state, action)
    case documentHistoricalEditionModalActionTypes.SET_ACTIVE_PAGE_HISTORICAL:
      return setActivePage(state, action)
    default:
      return state
  }
}
