
import React, { PureComponent } from 'react'
import { Button, Modal, Col, Row } from 'react-bootstrap'
import { reduxForm, Field } from 'redux-form'
import InputCheckbox from '../../commons/form/InputCheckBox'
import InputText from '../../commons/form/InputText'

class SignTypeModal extends PureComponent {
  submitSignType (values) {
    var signType = {}
    signType.signTypeId = this.props.signType.signTypeId
    signType.code = values.code
    signType.available = values.available

    this.props.actions.submitSignType(signType)
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevProps.showModal !== this.props.showModal && this.props.showModal === true) {
      this.props.initialize(this.props.signType)
    }
  }

  render () {
    const {
      t,
      showModal,
      handleSubmit
    } = this.props
    const tKey = 'MASTERS.SIGNATURE_TYPE_MASTERS.MODAL.'

    return (
      <Modal show={showModal} onHide={this.props.actions.closeSignTypeModal} className="languages-modal">
        <Modal.Header closeButton>
          <i className="ico-uo" /><Modal.Title>{t(`${tKey}TITLE`)}</Modal.Title>
        </Modal.Header>
        <form autoComplete="off" onSubmit={handleSubmit(this.submitSignType.bind(this))}>
          <Modal.Body>
            <h2>{t(`${tKey}CODE`)}</h2>
            <Row>
              <Col sm={6} className="custom-width-col">
                <Field
                  name={'code'}
                  placeholder={t(`${tKey}CODE`)}
                  component={InputText}
                  maxLength={50}
                  disabled={true}
                />
              </Col>
              <Col>
                <Field
                  component={InputCheckbox}
                  controlLabel={t(`${tKey}AVAILABLE`)}
                  placeholder={t(`${tKey}AVAILABLE`)}
                  name={'available'}
                  id={'available'}
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Col sm={12} className="btn-wrapper">
              <Button
                type="submit"
                className="btn-standard"
                disabled={this.props.pristine}>
                {t('MASTERS.SAVE')}
              </Button>
            </Col>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default reduxForm({
  form: 'signTypeModal'
})(SignTypeModal)
