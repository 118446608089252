import apiFetch from '../apiFetch'

export default function (token, saleId, organizedUnitId, lastAccessDate) {
  const queryParamsArray = [
    saleId ? `saleId=${saleId}` : null,
    organizedUnitId ? `organizedUnitId=${organizedUnitId}` : null
  ]
  const queryParams = queryParamsArray.reduce(
    (result, query, idx) => result + (query ? (result ? `&${query}` : `?${query}`) : '')
    , ''
  )

  return apiFetch({ endPoint: 'Dossier/Sale/CreateSelfSupplyVO' + queryParams, method: 'PUT', body: null, token: token, lastAccessDate })
}
