import apiFetch from '../apiFetch'

export default function (checkListId, token, formValues) {
  return apiFetch({
    endPoint: `checkList/generate?checkListId=${checkListId}`,
    method: 'POST',
    body: formValues,
    token: token,
    parseBlobAndAssign: true
  })
}
