import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import WalletReport from '../../../components/report/wallet/WalletReport'
import { fetchWalletList, downloadWalletList } from '../../../actions/reports/walletReport'
import { fetchBrands, fetchVehicleType } from '../../../actions/combos/combos'

export function mapStateToProps (state) {
  return {
    ...state.walletReport,
    combos: {
      brandCombo: state.combos.brandCombo,
      vehicletypeCombo: state.combos.vehicletypeCombo,
      salesmanCombo: state.combos.salesmanCombo,
      lowestOrganizedUnitsCombo: state.combos.lowestOrganizedUnitsCombo
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchWalletList,
      fetchBrands,
      fetchVehicleType,
      downloadWalletList
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(WalletReport))
