import React, { PureComponent } from 'react'
import { reduxForm, Field } from 'redux-form'
import { Row, Col, Table, Button, Tooltip, OverlayTrigger } from 'react-bootstrap'
import IDocCarPagination from '../../commons/iDocCarPagination'
import InputSelect from '../../commons/form/InputSelect'
import InputText from '../../commons/form/InputText'
import { mastersTabIndex } from '../../../constants/components/mastersTabIndex'
import ProcessingManagerMasterPageModal from '../../../containers/masters/ProcessingManager/ProcessingManagerMasterPageModal'

class ProcessingManagerMaster extends PureComponent {
  constructor () {
    super()
    this.state = {
      filter: {
        organizedUnitId: null,
        name: null
      }
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (this.props.eventKey && prevProps.eventKey !== this.props.eventKey && this.props.eventKey === mastersTabIndex.processingManagerMasterPage) {
      this.props.initialize({})
      this.resetFilters()
    }

    if (this.props.ouIdFromMaster != null) {
      const form = {
        filters: {
          organizedUnitId: this.props.ouIdFromMaster
        }
      }
      this.props.initialize(form)
      this.handleOrganizedUnitComboChange(this.props.ouIdFromMaster)
      this.props.actions.setChangeTabFromConfMaster(null, false)
    }
  }

  getSortIcon (column = '') {
    switch (this.props.filter.orderBy) {
      case '+' + column:
        return 'ico-sort-selected-asc'
      case '-' + column:
        return 'ico-sort-selected-desc'
      default:
        return 'ico-sort'
    }
  }

  changeOrder (field) {
    switch (this.props.filter.orderBy) {
      case `+${field}`:
        this.props.actions.setProcessingAgencyManagerFilter({
          orderBy: `-${field}`
        })
        break
      default:
        this.props.actions.setProcessingAgencyManagerFilter({
          orderBy: `+${field}`
        })
        break
    }
  }

  handleNameChange (name) {
    if (this.state.filter.name !== name) {
      this.setState({ filter: { ...this.state.filter, name } })
      this.props.actions.setProcessingAgencyManagerFilter({ name: name })
    }
  }

  handleOrganizedUnitComboChange (organizedUnitId) {
    if (this.state.filter.organizedUnitId !== organizedUnitId) {
      this.setState({ filter: { ...this.state.filter, organizedUnitId } })
      this.props.actions.setProcessingAgencyManagerFilter({ uoId: organizedUnitId })
    }
  }

  search () {
    this.props.actions.fetchProcessingManagersByNameOrUo(this.state.filter.name, this.state.filter.organizedUnitId)
  }

  searchTable (value) {
    this.props.actions.setProcessingAgencyManagerFilter({
      page: value
    })
  }

  editProcessingManager (processingManager) {
    this.props.actions.showProcessingManagerModal(processingManager)
  }

  createProcessingManager () {
    this.props.actions.showProcessingManagerModal(null)
  }

  deleteProcessingManager (processingManager) {
    this.props.actions.deleteProcessingManager(processingManager.processingAgencyManagerId)
  }

  recoverProcessingManager (processingManager) {
    this.props.actions.recoverProcessingManagerModal(processingManager.processingAgencyManagerId)
  }

  paginateAndSortArray (processingManagers, page, pageSize, orderBy) {
    page = parseInt(page) || 1
    pageSize = parseInt(pageSize) || 10

    const clonedArray = [...processingManagers]
    const propToSort = orderBy || 'name'

    if (propToSort.includes('processingAgencyIds')) {
      clonedArray.sort((a, b) => {
        const propToSort = orderBy || 'name'

        if (propToSort.startsWith('-')) {
          return b.processingAgencyIds.length - a.processingAgencyIds.length
        } else {
          return a.processingAgencyIds.length - b.processingAgencyIds.length
        }
      })
    } else {
      const prop = propToSort.replace(/^[-+]/, '')
      const order = propToSort.startsWith('-') ? 'desc' : 'asc'
      clonedArray.sort((a, b) => {
        const valorA = a[prop].toUpperCase()
        const valorB = b[prop].toUpperCase()

        const compare = order === 'asc' ? 1 : -1

        if (valorA < valorB) {
          return -1 * compare
        }
        if (valorA > valorB) {
          return 1 * compare
        }

        return 0
      })
    }

    const startIndex = (page - 1) * pageSize
    const endIndex = startIndex + pageSize
    const paginatedArray = clonedArray.slice(startIndex, endIndex)

    return paginatedArray
  }

  resetFilters () {
    this.setState({
      filter: {
        organizedUnitId: null,
        name: null
      }
    }, () => {
      this.props.actions.setProcessingAgencyManagerFilter({
        page: 1,
        orderBy: '',
        pageSize: 10,
        name: '',
        uoId: null
      })
      this.search()
    })
  }

  render () {
    const {
      t, pagesCount, itemCount, processingManagers,
      combos: { organizedUnitsAllCombo },
      filter: { page, pageSize, orderBy }
    } = this.props
    const tKey = 'MASTERS.PROCESSING_MANAGER.'
    const editTooltip = (<Tooltip id="editTooltip">{t('MASTERS.EDIT')}</Tooltip>)
    const deleteTooltip = (<Tooltip placement='left' id="deleteTooltip">{t('MASTERS.DELETE')}</Tooltip>)
    const recoverTooltip = (<Tooltip placement='left' id="deleteTooltip">{t('MASTERS.RECOVER')}</Tooltip>)
    const hasFilters = (this.state.filter.name || this.state.filter.organizedUnitId)

    let processingManagersSorted = this.paginateAndSortArray(processingManagers || [], page, pageSize, orderBy)
    return (
      <>
        <ProcessingManagerMasterPageModal></ProcessingManagerMasterPageModal>
        <div className="admin-wrapper">

          <Row className="section-users-header">
            <Col sm={12}>
              <div className="header-panel">
                <h4>{t(`${tKey}TITLE`)}</h4>
              </div>
            </Col>
          </Row>
          <Row>
            <div className="search-form">
              <span className="title-search"><i className="ico-search" /> {t('MASTERS.SEARCH_BTN')}</span>
              <Row>
                <Field
                  name="filter.name"
                  colSm={3}
                  controlLabel={t('MASTERS.NAME')}
                  placeholder={t('MASTERS.NAME')}
                  component={InputText}
                  onInputChange={(value) => this.handleNameChange(value)}
                />
                <Field
                  name="filter.ouId"
                  colSm={3}
                  controlLabel={t(`${tKey}UO`)}
                  placeholder={t(`${tKey}UO`)}
                  component={InputSelect}
                  valueKey="id"
                  labelKey="value"
                  options={organizedUnitsAllCombo}
                  onInputChange={(value) => this.handleOrganizedUnitComboChange(value)}
                />
                <Col sm={6} className="btn-wrapper">
                  <p></p>
                  <Button
                    id={'btn_search'}
                    className={'btn-white btn-icon btn-input btn-white search-button finance-type-button'}
                    onClick={() => this.search()}>
                    <i className="ico-search" />
                    {t('MASTERS.SEARCH_BTN')}

                  </Button>
                  <Button
                    id={'btn_reset'}
                    className={'btn-icon btn-input ' + (hasFilters ? 'btn-standard' : 'btn-cancel')}
                    onClick={() => {
                      this.props.initialize({})
                      this.resetFilters()
                    }}
                    disabled={!hasFilters}>
                    <i className="ico-cancel" />
                    {t('MASTERS.RESET_BUTTON')}

                  </Button>
                </Col>
              </Row>
            </div>
          </Row>
          <Col sm={12} className="col-row  section-users-body">
            <Table className="editable-table" hover responsive>
              <thead>
                <tr>
                  <th className='td80per' onClick={() => { this.changeOrder('name') }}>
                    {t('MASTERS.NAME')}
                    <i className={this.getSortIcon('name')} />
                  </th>
                  <th onClick={() => { this.changeOrder('processingAgencyIds') }}>
                    {t(`${tKey}ASSOCIATED_PROCESSING_AGENCY`)}
                    <i className={this.getSortIcon('processingAgencyIds')} />
                  </th>
                  <th className='td77px'>{t('DOSSIER_COMPONENTS.ACCESSORIES.ACTIONS')}</th>
                </tr>
              </thead>
              <tbody>
                {processingManagersSorted && processingManagersSorted.map((processingManger, idx) => {
                  return (
                    <tr key={idx} className={processingManger.isDeleted ? 'is-deleted' : ''}>
                      <td>{processingManger.name}</td>
                      <td>{processingManger?.processingAgencyIds?.length || 0}</td>
                      <td>
                        {!processingManger.isDeleted && (
                          <div>
                            <a onClick={() => this.editProcessingManager(processingManger)}>
                              <OverlayTrigger placement="left" overlay={editTooltip}>
                                <i className="ico-edit-white" />
                              </OverlayTrigger>
                            </a>
                            <a onClick={() => this.deleteProcessingManager(processingManger)}>
                              <OverlayTrigger placement="left" overlay={deleteTooltip}>
                                <i className="ico-trash" />
                              </OverlayTrigger>
                            </a>
                          </div>
                        )}
                        {processingManger.isDeleted && (
                          <div>
                            <a onClick={() => this.recoverProcessingManager(processingManger)}>
                              <OverlayTrigger placement="right" overlay={recoverTooltip}>
                                <i className="glyphicon glyphicon-repeat" />
                              </OverlayTrigger>
                            </a>
                          </div>
                        )}
                      </td>
                    </tr>)
                })
                }
                {(processingManagersSorted === null || processingManagersSorted === undefined || processingManagersSorted.length === 0) &&
            <tr>
              <td colSpan={10}><h2>{t(`${tKey}RESULTS_NOT_FOUND`)}</h2></td>
            </tr>}
                <tr className="button-table-wrapper">
                  <td>
                    <Button
                      id={'btn_add'}
                      className="btn-editable-table btn-standard"
                      onClick={() => this.createProcessingManager()}>
                  + {t('MASTERS.ADD')}
                    </Button>
                  </td>
                </tr>
              </tbody>
            </Table>
            <div className="section-users-footer">
              <nav key={1} className="text-center">
                <IDocCarPagination
                  id="btn_pag_sales"
                  page={page}
                  pagesCount={pagesCount}
                  totalRows={itemCount}
                  onSelectAction={(value) => this.searchTable(value)}
                />
              </nav>
            </div>
          </Col>
        </div>
      </>
    )
  }
}

export default reduxForm({
  form: 'processingManagerMaster'
})(ProcessingManagerMaster)
