import React, { PureComponent } from 'react'
import { Field, reduxForm, reset } from 'redux-form'
import { Button, OverlayTrigger, Tooltip, Form } from 'react-bootstrap'
import InputText from '../commons/form/InputText'
import InputSelect from '../commons/form/InputSelect'
import { entityTypesId } from '../../../constants/backendIds'
import SimpleTablePage from '../../../containers/commons/general/SimpleTablePage'
import IDocCarPagination from '../../../components/commons/iDocCarPagination'
import DossierListPopover from './DossierListPopover'
import InputCheckbox from '../commons/form/InputCheckBox'
import { showFilter, hideFiltersBtn, toggleFilterClass, getIconClass } from '../../../_v2/util/management/ManagementUtils'
import MapSectionsSearch from '../../../_v2/util/map-sections-search'
import { fromCamelToUnderscore } from '../../../util/utils'

class SearchByEntityForm extends PureComponent {
  constructor (props) {
    super()
    this.state = {
      fields: {},
      selectedEntityType: props.filters && props.filters.entityTypeId,
      selectedEntitySubType: props.filters && props.filters.entitySubTypeId
    }
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (this.props.formValues) {
      if (
        !prevProps.formValues || (prevProps.formValues.entityTypeId && !this.props.formValues) ||
    prevProps.formValues.entityTypeId !== this.props.formValues.entityTypeId
      ) {
        this.onEntityTypeChange(this.props.formValues.entityTypeId)
      }
      if (
        !prevProps.formValues || (prevProps.formValues.entitySubTypeId && !this.props.formValues) ||
        prevProps.formValues.entitySubTypeId !== this.props.formValues.entitySubTypeId
      ) {
        this.onEntitySubTypeChange(this.props.formValues.entitySubTypeId)
      }
    }
    return null
  }

  componentDidUpdate () {
    // getSnapshotBeforeUpdate() should be used with componentDidUpdate()
  }

  componentDidMount () {
    for (const dynamicFilter in this.props.dynamicFilters) {
      this.props.change(dynamicFilter, this.props.dynamicFilters[dynamicFilter])
    }
    if (this.props.formValues.entityTypeId) {
      this.onEntityTypeChange(this.props.formValues.entityTypeId)
    }
  }

  handleClickReset (e) {
    if (e !== undefined) {
      e.preventDefault()
    }

    this.props.dispatch(reset('SearchByEntityForm'))
    this.state.dossierIdToArray = []
    this.setState({ onlyFleets: true, checkAll: false, dossierIdToArray: [], entityData: [], fields: [] })
  }

  submitSearchForm (values) {
    // const config = { id: searchType.ENTITY, formValuesName: 'entity', fetchAction: (value) => this.props.actions.fetchEntities({ ...value, ...this.props.filters }), emptyFilterModal: 'mustFillEntityType', customValidation: (values) => { return !values.entity || !values.entity.entityTypeId } }

    if (!values.entity || !values.entity.entityTypeId) {
      return this.props.actions.openModal('mustFillEntityType')
    }

    this.props.actions.fetchEntities({ ...values.entity, ...this.props.filters })
    this.setState({ dossierIdToArray: [], dynamicFilters: values, filterValues: { ...values.entity, ...this.props.filters } })

    this.props.actions.setFilters(values)
  }

  getEntityNameLabel () {
    const tKey = 'SEARCH.CATEGORIES.ENTITY.'
    switch (this.state.selectedEntityType) {
      case entityTypesId.privateEntity:
        return this.props.t(`${tKey}NAME`)
      default:
        return this.props.t(`${tKey}REGISTERED_NAME`)
    }
  }

  onEntityTypeChange (entityTypeId) {
    this.setState({ selectedEntityType: entityTypeId })

    if (entityTypeId === entityTypesId.recurrentEntity) {
      this.props.actions.fetchEntitySubTypeCombo(entityTypeId)
      this.props.change('entity.dniCif', null)
      this.props.change('entity.name', null)
      this.props.change('entity.surname1', null)
      this.props.change('entity.surname2', null)
      this.props.change('entity.representativeDni', null)
      this.props.change('entity.representativeName', null)
      this.props.change('entity.representativeSurname1', null)
      this.props.change('entity.representativeSurname2', null)
      this.props.change('entity.email', null)
      this.props.change('entity.phone', null)
      this.props.change('entity.address', null)
      this.props.change('entity.entityChildSelected', null)
    } else {
      this.props.change('entity.entitySubTypeId', null)
      this.props.change('entity.entityId', null)
      if (entityTypeId !== entityTypesId.privateEntity) {
        this.props.change('entity.surname1', null)
        this.props.change('entity.surname2', null)
      } else if (entityTypeId !== entityTypesId.businessEntity) {
        this.props.change('entity.representativeDni', null)
        this.props.change('entity.representativeName', null)
        this.props.change('entity.representativeSurname1', null)
        this.props.change('entity.representativeSurname2', null)
      }
    }
  }

  onEntitySubTypeChange (entitySubTypeId) {
    this.setState({ selectedEntitySubType: entitySubTypeId })
    if (entitySubTypeId) this.props.actions.fetchEntityFilterSubTypeCombo(this.state.selectedEntityType, entitySubTypeId)
  }

  exportData () {
    const tKey = 'SEARCH.CATEGORIES.ENTITY.'
    let columnsExcel = []
    columnsExcel.push(this.props.t(`${tKey}NAME`) + '#Name')
    columnsExcel.push(this.props.t(`${tKey}DNI_CIF`) + '#DniCif')
    columnsExcel.push(this.props.t(`${tKey}EMAIL`) + '#Email')
    columnsExcel.push(this.props.t(`${tKey}PHONE`) + '#Phone')
    columnsExcel.push(this.props.t(`${tKey}ADDRESS`) + '#Address')
    columnsExcel.push(this.props.t('SEARCH.CATEGORIES.VEHICLE.AMOUNT_RELATED_DOSSIERS') + '#LivesStoreds')
    if (this.props.formValues.entityTypeId !== entityTypesId.privateEntity) {
      columnsExcel.push(this.props.t(`${tKey}REPRESENTATIVE_NAME`) + '#RepresentantiveName')
      columnsExcel.push(this.props.t(`${tKey}REPRESENTATIVE_DNI`) + '#RepresentativeDni')
    }
    columnsExcel.push(this.props.t(`${tKey}ENTITY_TYPE_ID`) + '#EntityType')
    this.exportToExcel(columnsExcel)
  }

  exportToExcel (data) {
    let cols = ''
    data.forEach(element => {
      cols += element + '$'
    })
    cols = cols.substring(0, cols.length - 1)
    let values = this.props.dynamicFilters
    this.props.actions.fetchEntitiesDownload({ ...values.entity, ...this.props.filters }, cols)
  }

  onPageChange (page) {
    this.setState({ filterValues: { ...this.state.filterValues, page: page } })
    this.props.actions.fetchEntities({ ...this.state.filterValues, page })
  }

  onOrderChange (e) {
    const newOrderBy = this.props.filters.orderBy.includes(`%2B${e.currentTarget.dataset.field}`) ? `-${e.currentTarget.dataset.field}` : `%2B${e.currentTarget.dataset.field}`
    this.setState({ filterValues: { ...this.state.filterValues, orderBy: newOrderBy } })
    this.props.actions.fetchEntities({ ...this.state.filterValues, orderBy: newOrderBy })
  }

  toogleHideFilters () {
    this.props.actions.setHideFilters(!this.props.hideFilters)
  }

  toogleMaxiTable () {
    this.setState({ maxiTable: !this.state.maxiTable })
  }

  selectedFilters () {
    const filters = this.props.dynamicFilters
    const tKey = 'SEARCH.CATEGORIES.'
    return Object.keys(filters)
      .map((item) => {
        let text = item
        let value = null
        const list = []
        Object.keys(filters[item]).map((filter, index) => {
          text = this.props.t(`${tKey}${item.toUpperCase()}.${fromCamelToUnderscore(filter).toUpperCase()}`)
          switch (filter) {
            case 'dossierSubtype':
            { const selectedSubtype = this.props.combos.searchDossierSubtypesCombo.find(subtype => subtype.id === filters[item][filter])
              value = selectedSubtype && selectedSubtype.value
              break }
            case 'salesman':
            {
              value = this.extractMultipleCombo(this.props.combos.salesmanCombo, filters, item, filter)
              break
            }
            case 'salesmanManager':
            {
              value = this.extractMultipleCombo(this.props.combos.salesmanCombo, filters, item, filter)
              break
            }
            case 'organizedUnit':
            { const organizedUnit = this.props.combos.lowestOrganizedUnitsCombo.find(ou => ou.id === filters[item][filter])
              value = organizedUnit && organizedUnit.value
              break }
            case 'stage':
            {
              value = this.extractMultipleCombo(this.props.combos.stagesCombo, filters, item, filter)
              break }
            case 'userId':
            { const user = this.props.combos.usersCombo.find(b => b.id === filters[item][filter])
              value = user && user.value
              break }
            case 'creatorId':
            { const user = this.props.combos.usersCombo.find(b => b.id === filters[item][filter])
              value = user && user.value
              break }
            case 'saleTypeId':
            { const saleType = this.props.combos.saleTypeCombo.find(ou => ou.id === filters[item][filter])
              value = saleType && saleType.value
              break }
            case 'purchaseTypeId':
            { const purchaseType = this.props.combos.purchaseTypeCombo.find(ou => ou.id === filters[item][filter])
              value = purchaseType && purchaseType.value
              break }
            case 'operationTypeId':
            { const operationType = this.props.combos.operationTypeCombo.find(ou => ou.id === filters[item][filter])
              value = operationType && operationType.value
              break }
            case 'operationTypeDMSId':
            {
              let operationType = ''
              filters[item][filter].forEach(id => {
                operationType = this.props.combos.operationTypeDMSCombo.find(ou => ou.id === id)
                value = (value || '') + (operationType && (operationType.description + ', '))
              })
              if (value) {
                value = value.substring(0, value.length - 2)
              }
              break }
            case 'stockStatusId':
            { const stockStatus = this.props.combos.stockStatusCombo.find(b => b.id === filters[item][filter])
              value = stockStatus && stockStatus.value
              break }
            case 'proformInvoiceEmited': {
              value = this.extractValueComboYesNo(filters[item][filter])
              break
            }
            case 'isDenied': {
              value = this.extractValueComboYesNo(filters[item][filter])
              break
            }
            case 'paymentMethod':
            {
              value = this.extractMultipleCombo(this.props.combos.paymentMethodCombo, filters, item, filter)
              break
            }
            default:
            { value = filters[item][filter]
              break }
          }

          if (value) {
            list.push(<li key={index}>{text}: <strong>{value}</strong>;</li>)
          }
        })
        return list
      })
  }

  render () {
    const {
      t, combos: { entityTypeCombo, entitySubTypeCombo, entityFilterSubTypeCombo }, fields, fieldFill, className, classNameSelect,
      hasSearch, entityData, vehicleData, page, pages, count, hideFilters,
      handleSubmit // From search
    } = this.props
    const tKey = 'SEARCH.CATEGORIES.ENTITY.'
    const secMapSearch = MapSectionsSearch.find(function (s) { return s.page === 'entityPage' })
    const hasResults = entityData && entityData.length > 0
    return (
      <div className="inner-results-panel">
        <div className="section-results-header">
          <div className="section-header-title">
            <OverlayTrigger placement="right" overlay={<Tooltip id="editProductTooltip">{`${t((this.props.hideMenu ? 'GLOBAL.TABLES.SHOW_INNER_MENU' : 'GLOBAL.TABLES.HIDE_INNER_MENU'))}`}</Tooltip>}>
              <a onClick={() => this.props.toogleInnerMenu()}s className={this.props.hideMenu ? 'collapseBtn' : 'collapseBtn closeInnerMenu'}><i className={this.props.hideMenu ? 'iDocIcon-Open-inner-menu' : 'iDocIcon-Close-inner-menu'}></i></a>
            </OverlayTrigger>
            <h4>{t(`${secMapSearch.title}`)}
              <OverlayTrigger placement="left" overlay={<Tooltip id="editProductTooltip">{`${t((hideFilters ? 'GLOBAL.TABLES.SHOW_FILTERS' : 'GLOBAL.TABLES.HIDE_FILTERS'))}`}</Tooltip>}>
                <a onClick={() => this.toogleHideFilters()} className={'toogleShowFilters ' + hideFiltersBtn(hasResults)}><i className={toggleFilterClass(hasResults, hideFilters)}></i></a>
              </OverlayTrigger>
            </h4>
          </div>
          <Form autoComplete="off" onSubmit={handleSubmit((values) => this.submitSearchForm(values))} className="filters ">
            <div className={'search-form ' + (showFilter(hasResults, hideFilters) ? '' : 'hideFilters ')}>
              <Field
                component={InputSelect}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                id="entityTypeId"
                name='entity.entityTypeId'
                placeholder={t(`${tKey}ENTITY_TYPE_ID`)}
                controlLabel={t(`${tKey}ENTITY_TYPE_ID`)}
                options={entityTypeCombo}
                valueKey="id"
                labelKey="value"
                onInputChange={ (val) => { this.onEntityTypeChange(val); fieldFill({ entityTypeId: val }) } }
                customClass={fields.entityTypeId ? classNameSelect : ''}
              />
              {(this.state.selectedEntityType === entityTypesId.recurrentEntity) && [
                <Field
                  component={InputSelect}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="entitySubTypeId"
                  name="entity.entitySubTypeId"
                  key="entity.entitySubTypeId"
                  controlLabel={t(`${tKey}ENTITY_SUB_TYPE_ID`)}
                  placeholder={t(`${tKey}ENTITY_SUB_TYPE_ID`)}
                  options={entitySubTypeCombo}
                  valueKey="id"
                  labelKey="value"
                  onInputChange={ (val) => { this.onEntitySubTypeChange.bind(this); fieldFill({ entitySubTypeId: val }) } }
                  customClass={fields.entitySubTypeId ? classNameSelect : ''}
                />,
                <Field
                  component={InputSelect}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="entityId"
                  name="entity.entityId"
                  key="entity.entityId"
                  controlLabel={t(`${tKey}ENTITY_ID`)}
                  placeholder={t(`${tKey}ENTITY_ID`)}
                  options={entityFilterSubTypeCombo}
                  valueKey="id"
                  labelKey="value"
                  disabled={!this.state.selectedEntitySubType}
                  onInputChange={ (val) => fieldFill({ entityId: val }) }
                  customClass={fields.entityId ? classNameSelect : ''}
                />
              ]}
              {(this.state.selectedEntityType === entityTypesId.privateEntity || this.state.selectedEntityType === entityTypesId.businessEntity) && [
                <Field
                  component={InputText}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="idNumber"
                  name='entity.dniCif'
                  key='entity.dniCif'
                  placeholder={t(`${tKey}DNI_CIF`)}
                  controlLabel={t(`${tKey}DNI_CIF`)}
                  type="text"
                  onInputChange={(val) => fieldFill({ idNumber: val })}
                  customClass={fields.idNumber ? className : ''}
                />,
                <Field
                  component={InputText}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="clientNameEntity"
                  name='entity.name'
                  key='entity.name'
                  placeholder={this.getEntityNameLabel()}
                  controlLabel={this.getEntityNameLabel()}
                  type="text"
                  onInputChange={(val) => fieldFill({ clientName: val })}
                  customClass={fields.clientName ? className : ''}
                />
              ]}
              {this.state.selectedEntityType === entityTypesId.privateEntity && [
                <Field
                  component={InputText}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="clientSurname1"
                  name='entity.surname1'
                  key='entity.surname1'
                  placeholder={t(`${tKey}SURNAME1`)}
                  controlLabel={t(`${tKey}SURNAME1`)}
                  type="text"
                  onInputChange={(val) => fieldFill({ clientSurname1: val })}
                  customClass={fields.clientSurname1 ? className : ''}
                />,
                <Field
                  component={InputText}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="clientSurname2"
                  name='entity.surname2'
                  key='entity.surname2'
                  placeholder={t(`${tKey}SURNAME2`)}
                  controlLabel={t(`${tKey}SURNAME2`)}
                  type="text"
                  onInputChange={(val) => fieldFill({ clientSurname2: val })}
                  customClass={fields.clientSurname2 ? className : ''}
                />
              ]}
              {this.state.selectedEntityType === entityTypesId.businessEntity && [
                <Field
                  component={InputText}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="representativeID"
                  name='entity.representativeDni'
                  key='entity.representativeDni'
                  placeholder={t(`${tKey}REPRESENTATIVE_DNI`)}
                  controlLabel={t(`${tKey}REPRESENTATIVE_DNI`)}
                  type="text"
                  onInputChange={(val) => fieldFill({ representativeID: val })}
                  customClass={fields.representativeID ? className : ''}
                />,
                <Field
                  component={InputText}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="representativeName"
                  name='entity.representativeName'
                  key='entity.representativeName'
                  placeholder={t(`${tKey}REPRESENTATIVE_NAME`)}
                  controlLabel={t(`${tKey}REPRESENTATIVE_NAME`)}
                  type="text"
                  onInputChange={(val) => fieldFill({ representativeName: val })}
                  customClass={fields.representativeName ? className : ''}
                />,
                <Field
                  component={InputText}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="representativeSurname1"
                  name='entity.representativeSurname1'
                  key='entity.representativeSurname1'
                  placeholder={t(`${tKey}REPRESENTATIVE_SURNAME1`)}
                  controlLabel={t(`${tKey}REPRESENTATIVE_SURNAME1`)}
                  type="text"
                  onInputChange={(val) => fieldFill({ representativeSurname1: val })}
                  customClass={fields.representativeSurname1 ? className : ''}
                />,
                <Field
                  component={InputText}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="representativeSurname2"
                  name='entity.representativeSurname2'
                  key='entity.representativeSurname2'
                  placeholder={t(`${tKey}REPRESENTATIVE_SURNAME2`)}
                  controlLabel={t(`${tKey}REPRESENTATIVE_SURNAME2`)}
                  type="text"
                  // forceControlLabelInOneLine={true}
                  onInputChange={(val) => fieldFill({ representativeSurname2: val })}
                  customClass={fields.representativeSurname2 ? className : ''}
                />
              ]}
              {(this.state.selectedEntityType !== entityTypesId.recurrentEntity) && [
                <Field
                  component={InputText}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="emailEntity"
                  name='entity.email'
                  key='entity.email'
                  placeholder={t(`${tKey}EMAIL`)}
                  controlLabel={t(`${tKey}EMAIL`)}
                  type="text"
                  onInputChange={(val) => fieldFill({ email: val })}
                  customClass={fields.email ? className : ''}
                />,
                <Field
                  component={InputText}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="phone"
                  name='entity.phone'
                  key='entity.phone'
                  placeholder={t(`${tKey}PHONE`)}
                  controlLabel={t(`${tKey}PHONE`)}
                  type="text"
                  onInputChange={(val) => fieldFill({ phone: val })}
                  customClass={fields.phone ? className : ''}
                />,
                <Field
                  component={InputText}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="location"
                  name='entity.address'
                  key='entity.address'
                  placeholder={t(`${tKey}ADDRESS`)}
                  controlLabel={t(`${tKey}ADDRESS`)}
                  type="text"
                  onInputChange={(val) => fieldFill({ location: val })}
                  customClass={fields.location ? className : ''}
                />]}
              {(this.state.selectedEntityType === entityTypesId.businessEntity || this.state.selectedEntityType === entityTypesId.privateEntity) && [
                <Field
                  component={InputCheckbox}
                  colWidht={100} // 25, 33, 50, 75, 100 default=25
                  id="entityChildSelected"
                  name='entity.entityChildSelected'
                  key='entity.entityChildSelected'
                  placeholder={t(`${tKey}ENTITY_CHILD_SELECTED`)}
                  controlLabel={t(`${tKey}ENTITY_CHILD_SELECTED`)}
                  customClass={fields.entityChildSelected ? className : ''}
                />
              ]}
              <div className='search-form-actions flexInLine al-R'>
                <Button id="btn_search"
                  type="submit"
                  className="_Btn accept stk">
                  <i className="iDocIcon-search"/>
                  {t('SEARCH.ACTIONS.SEARCH_BUTTON')}
                </Button>
                <Button
                  id="btn_reset"
                  onClick={(e) => this.handleClickReset(e)}
                  className={'_Btn' + (this.props.pristine ? ' accept' : ' accept')}
                  disabled={this.props.pristine}>
                  <i className="iDocIcon-clear-decline"/>
                  {t('SEARCH.ACTIONS.RESET_BUTTON')}
                </Button>
              </div>
            </div>
          </Form>
        </div>
        <div className="section-results-body">
          <div className={'searchResoultsContainer' + (this.state.maxiTable ? ' maxiTable' : '')}>
            {hasSearch && ([
              <div className="table-wrapper" key="table-wrapper">
                <ul className="list-inline pull-left">
                  {this.selectedFilters().length === 0 ? <li>{t(`${tKey}NO_SELECTED_FILTERS`)}</li> : (this.selectedFilters())}
                </ul>
                { hasResults &&
                  <div className='flexInLine al-R'>
                    <div className='actionsField'>
                    </div>
                    <OverlayTrigger placement="left" overlay={<Tooltip id="editProductTooltip">{`${t((this.state.maxiTable ? 'GLOBAL.TABLES.MINIMIZE_TABLE' : 'GLOBAL.TABLES.MAXIMIZE_TABLE'))}`}</Tooltip>}>
                      <a onClick={() => this.toogleMaxiTable()} className='_Btn accept stk o-icn maximizeBtn'><i className={(this.state.maxiTable ? 'iDocIcon-Minimize' : 'iDocIcon-Maximize')}></i></a>
                    </OverlayTrigger>
                    <OverlayTrigger placement="left" overlay={<Tooltip id="editProductTooltip">{t('SEARCH.ACTIONS.EXPORT')}</Tooltip>}>
                      <a onClick={(event) => this.exportData(event)} className='_Btn new o-icn exportToExcel'><i className="iDocIcon-file-excel"></i></a>
                    </OverlayTrigger>
                  </div>
                }
                {/* table */}
                { hasResults &&
                  <div className="table-responsive">
                    <SimpleTablePage
                      columns={[
                        <th key={0} data-field="entityName" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}NAME`)}&nbsp;<i className={getIconClass('entityName', this.props.filters)}/>
                        </th>,
                        <th key={1} data-field="entityCifNif" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}DNI_CIF`)}&nbsp;<i className={getIconClass('entityCifNif', this.props.filters)}/>
                        </th>,
                        <th key={2} data-field="entityMail" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}EMAIL`)}&nbsp;<i className={getIconClass('entityMail', this.props.filters)}/>
                        </th>,
                        <th key={3} data-field="entityPhone" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}PHONE`)}&nbsp;<i className={getIconClass('entityPhone', this.props.filters)}/>
                        </th>,
                        <th key={4} data-field="entityAddress" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}ADDRESS`)}&nbsp;<i className={getIconClass('entityAddress', this.props.filters)}/>
                        </th>,
                        <th key={5}>{t('SEARCH.CATEGORIES.VEHICLE.AMOUNT_RELATED_DOSSIERS')}</th>,
                        this.state.selectedEntityType !== entityTypesId.privateEntity && (
                          <th key={6} data-field="legalEntityName" onClick={(event) => this.onOrderChange(event)}>
                            {t(`${tKey}REPRESENTATIVE_NAME`)}&nbsp;<i className={getIconClass('legalEntityName', this.props.filters)}/>
                          </th>),
                        this.state.selectedEntityType !== entityTypesId.privateEntity && (
                          <th key={7} data-field="legalEntityCifNif" onClick={(event) => this.onOrderChange(event)}>
                            {t(`${tKey}REPRESENTATIVE_DNI`)}&nbsp;<i className={getIconClass('legalEntityCifNif', this.props.filters)}/>
                          </th>),
                        <th key={8} data-field="entityType" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}ENTITY_TYPE_ID`)}&nbsp;<i className={getIconClass('entityType', this.props.filters)}/>
                        </th>
                      ]}
                      rows={entityData && entityData.map((row, idx) => (
                        <tr key={idx}>
                          <td>{
                            (row.entityName && row.entitySurname1 && row.entitySurname2) ? row.entityName + ' ' + row.entitySurname1 + ' ' + row.entitySurname2
                              : (row.entityName && row.entitySurname1) ? row.entityName + ' ' + row.entitySurname1 : row.entityName ? row.entityName : ''
                          }</td>
                          <td>{row.entityCifNif ? row.entityCifNif : ''}</td>
                          <td>{row.entityMail ? row.entityMail : ''}</td>
                          <td>{row.entityPhone ? row.entityPhone : ''}</td>
                          <td>{row.entityAddress ? row.entityAddress : ''}</td>
                          <td className="see-more">
                            <DossierListPopover
                              t={t}
                              half={(vehicleData.length || 1) / (idx + 1)}
                              idx={idx}
                              id={'popover-dossier-list' + idx}
                              alive={row.lives}
                              stored={row.stored}
                              subtypesCombo={this.props.subtypesCombo}
                            />
                          </td>
                          {this.state.selectedEntityType !== entityTypesId.privateEntity && (
                            <td>{row.representativeName ? row.representativeName + ' ' : '' + row.representativeSurname1 ? row.representativeSurname1 : ''}</td>)}
                          {this.state.selectedEntityType !== entityTypesId.privateEntity && (
                            <td>{row.representativeDni ? row.representativeDni : ''}</td>)}
                          <td>{row.entityType ? row.entityType : ''}</td>
                        </tr>
                      ))}
                    />
                  </div>
                }
                <div className="search-footer" key="search-footer">
                  {hasResults ? (
                    <IDocCarPagination
                      id="btn_pag_sales"
                      page={page}
                      pagesCount={pages}
                      totalRows= {count}
                      onSelectAction={(page) => this.onPageChange(page)}
                    />) : <p className='no_results'>{t('GLOBAL.TABLES.NO_RESULTS')}</p>
                  }
                </div>
              </div>
            ])}
          </div>
        </div>
      </div>
    )
  }
}

const validate = () => {
  return {}
}

export default reduxForm({
  form: 'SearchByEntityForm',
  validate
})(SearchByEntityForm)
