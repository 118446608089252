import React, { PureComponent } from 'react'
import { Button, Modal, Col, Form, Row } from 'react-bootstrap'
import { Field, reduxForm } from 'redux-form'
import InputText from '../../commons/form/InputText'
import InputSelect from '../../commons/form/InputSelect'

class SaleFleetsModal extends PureComponent {
  submit (values) {
    new Promise((resolve, reject) => {
      this.props.actions.saveFleetCode(values.fleetNumberCombo || values.fleetNumber, resolve)
    }).then(() => {
      this.props.actions.closeSaleFleetModal()
    })
  }

  componentDidMount () {
    this.props.actions.fetchFleetsCombo()
  }

  render () {
    const {
      t, handleSubmit, showFleetsModalSelection, fleetsCombo
    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.SALE_FLEET_MODAL.'
    return (
      <Modal className="add-user-modal" show={showFleetsModalSelection}>
        <Form autoComplete="off" onSubmit={handleSubmit(this.submit.bind(this))}>
          <Modal.Header>
            <Modal.Title>{t(`${tKey}TITLE`)}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="inputs-wrapper">
              <Field
                colSm={6}
                id="fleetNumberCombo"
                name="fleetNumberCombo"
                controlLabel={t(`${tKey}SELECTING_FLEET`)}
                placeholder={t(`${tKey}SELECTING_FLEET`)}
                valueKey="id"
                labelKey="value"
                options={fleetsCombo}
                component={InputSelect}
              />
              <Field
                colSm={6}
                id="fleetNumber"
                name="fleetNumber"
                controlLabel={t(`${tKey}ENTER_FLEET`)}
                placeholder={t(`${tKey}ENTER_FLEET`)}
                component={InputText}/>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Col sm={6} className="btn-wrapper">
              <Button id="btn_save" className="btn-standard" type="submit">{t('GLOBAL.BUTTONS.SAVE')}</Button>
            </Col>
          </Modal.Footer>
        </Form>
      </Modal>
    )
  }
}

export default reduxForm({
  form: 'saleFleetModal',
  validate: (values, props) => {
    let errors = {}
    if ((!values.fleetNumber && !values.fleetNumberCombo) || (values.fleetNumber && values.fleetNumberCombo)) {
      errors.fleetNumber = props.t('DOSSIER_COMPONENTS.SALE_FLEET_MODAL.VALIDATIONS.FILL_ONE')
      errors.fleetNumberCombo = props.t('DOSSIER_COMPONENTS.SALE_FLEET_MODAL.VALIDATIONS.FILL_ONE')
    }
    return errors
  }
})(SaleFleetsModal)
