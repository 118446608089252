import React, { PureComponent } from 'react'
import { reduxForm, Field, reset } from 'redux-form'
import { Row, Form, Button, Col, ProgressBar, Tooltip, OverlayTrigger } from 'react-bootstrap'
import { validateDate } from '../../../util/validationFunctions'
import SimpleTablePage from '../../../containers/commons/general/SimpleTablePage'
import IDocCarPagination from '../../../components/commons/iDocCarPagination'
import { formatDate, getDateNowMaxDatePicker, fromCamelToUnderscore } from '../../../util/utils'
import moment from 'moment'
import ZipModalPage from '../../containers/management/Modals/ZipModalPage'
/// //////////////////////////////////////////////////////////
import MapSectionsManagement from '../../../_v2/util/map-sections-management'
import InputSelect from '../../../_v2/components/commons/form/InputSelect'
import InputCheckBox from '../../../_v2/components/commons/form/InputCheckBox'
import InputDatePicker from '../../../_v2/components/commons/form/InputDatePicker'
import InputText from '../../../_v2/components/commons/form/InputText'
import InputTreeSelect from '../../../_v2/components/commons/form/InputTreeSelect'
import { showFilter, hideFiltersBtn, toggleFilterClass, getIconClass } from '../../../_v2/util/management/ManagementUtils'

class SearchDocumental extends PureComponent {
  constructor () {
    super()
    this.state = {
      fields: [],
      filters: [],
      dynamicFilters: [],
      disableResetButton: false,
      displayBar: 'none',
      progress: 0,
      progressFail: 0,
      errors: [],
      checkboxes: {},
      maxBar: 0,
      type: false,
      visible: true,
      organizedUnitIds: null,
      selectAll: false
    }
  }

  componentDidMount () {
    this.props.actions.fetchAuditCodes()
    this.props.actions.fetchDocumentTypeUseCombo()
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props._reduxForm.zipModal.showModal === false && prevProps._reduxForm.zipModal.showModal === true) {
      this.setState({ visible: false })
    }
    if (prevProps.items !== this.props.items && this.state?.selectAll === true) {
      const checkboxes = this.props?.items?.reduce(
        (checkboxes, document) => ({
          ...checkboxes,
          [document.documentId]: true
        }),
        {}
      )
      this.setState({ checkboxes })
    }
  }

  submitSearchForm (values) {
    if (values.auditConfigurationId) {
      this.setState({ filters: values })
      let filters = values
      let propFilters = this.props.filters
      propFilters.orderBy = '+Number'
      this.props.actions.fetchSearchDocumentsInfo({ ...filters, ...propFilters })
      this.setState({ dynamicFilters: filters, selectAll: false, checkboxes: {}, hideFilters: true })
    } else {
      this.props.validate()
    }
  }

  fieldFill (field) {
    this.setState({ fields: { ...this.state.fields, ...field } })
  }

  onDossierTypeChange (dossierTypesId) {
    this.props.actions.fetchDossierSubTypes(null, dossierTypesId)
  }

  dateValidation (value, allValues, props) {
    const result = validateDate(value, props)
    return result === Object(result) ? undefined : result
  }

  clearFields () {
    this.setState({ fields: {} })
  }

  handleClickReset (e) {
    if (e !== undefined) {
      e.preventDefault()
    }
    this.clearFields()
    this.props.actions.resetSearchDocumentsInfo()
    this.props.dispatch(reset('searchAuditsForm'))
    this.setState({ dynamicFilters: [] })
  }

  onOrderChange (e) {
    const newOrderBy = this.props.filters.orderBy.includes(`+${e.currentTarget.dataset.field}`) ? `-${e.currentTarget.dataset.field}` : `+${e.currentTarget.dataset.field}`
    this.fetchTable({ ...this.props.filters, orderBy: newOrderBy })
  }

  onPageChange (page) {
    this.fetchTable({ ...this.props.filters, page })
  }

  fetchTable (propFilters) {
    let filters = this.state.dynamicFilters
    this.props.actions.fetchSearchDocumentsInfo({ ...filters, ...propFilters })
    this.setState({ dynamicFilters: filters })
  }

  viewDocument (data) {
    if (data.dossierId) {
      this.props.actions.openDocumentEditionModal(data.documentId, data.documentTypeUseId, data.documentTypeEntityId, data.dossierId, data.entityId, data.vehicleId, data.organizedUnitId, null, null, null, null, null, null, null, null, false, null)
    }
    if (data.dossierHistoricalId) {
      this.props.actions.openDocumentEditionModalHistorical(data.documentId, data.documentTypeUseId, null, data.dossierId, data.entityId, data.vehicleId, data.organizedUnitId, null, null, null, null)
    }
  }

  showModalExport () {
    this.setState({
      visible: true
    }, () => {
      let trueCheckboxesString = Object.keys(this.state.checkboxes)
        .filter(id => this.state.checkboxes[id])
        .join(',')

      if (this.state.selectAll) {
        trueCheckboxesString = this.props.documentIds.join(',')
      }

      this.props.actions.openZipModal(trueCheckboxesString)
    })
  }

  setValues (val) {
    this.setState({ organizedUnitIds: val })
  }

  onCheckAll (e) {
    const checkboxes = this.props.items.reduce(
      (checkboxes, document) => ({
        ...checkboxes,
        [document.documentId]: e
      }),
      {}
    )

    this.setState({
      selectAll: e,
      checkboxes
    })
  }

  onCheckOne (e, id) {
    this.setState(prevState => ({
      checkboxes: { ...prevState.checkboxes, [id]: e },
      selectAll: false
    }))
  }

  downloadDoc () {
    var selectedDocCount = Object.values(this.state.checkboxes).filter(value => value).length
    if (this.state.selectAll) {
      selectedDocCount = this.props.documentIds.length
    }
    this.props.actions.openModal('updateDocumentsSearchDocumental', () => this.showModalExport(), null, null, null, [{ id: '##NUMBER##', value: selectedDocCount }])
  }

  toogleHideFilters () {
    this.setState({ hideFilters: !this.state.hideFilters })
  }

  toogleMaxiTable () {
    this.setState({ maxiTable: !this.state.maxiTable })
  }

  selectedFilters () {
    const filters = this.state.filters
    const tKey = 'MANAGEMENT.ADMIN_AUDIT.'
    return Object.keys(filters)
      .filter(item => { return item !== 'page' && item !== 'pageSize' && item !== 'orderBy' })
      .map((item, index) => {
        let text = item
        let value = null
        const list = []

        switch (item) {
          case 'dossierTypeId': {
            const dossierType = this.props.combos.dossierTypesCombo.find(dt => dt.id === filters[item])
            value = dossierType && dossierType.value
            text = this.props.t(`${tKey}DOSSIER_TYPE`)
            break
          }
          case 'dossierSubTypeId': {
            const dossierSubtype = this.props.combos.dossierSubTypesCombo.find(dt => dt.id === filters[item])
            value = dossierSubtype && dossierSubtype.value
            text = this.props.t(`${tKey}DOSSIER_SUBTYPE`)
            break
          }
          case 'creationDateFrom': {
            value = filters[item]
            text = this.props.t(`${tKey}VALIDATED_DATE_FROM`)
            break
          }
          case 'creationDateTo': {
            value = filters[item]
            text = this.props.t(`${tKey}VALIDATED_DATE_TO`)
            break
          }
          case 'auditCode': {
            value = filters[item]
            text = this.props.t(`${tKey}CODE`)
            break
          }
          case 'auditConfigurationTypeId': {
            const configType = this.props.combos.auditConfigurationType.find(dt => dt.id === filters[item])
            value = configType && configType.value
            text = this.props.t(`${tKey}AUDIT_TYPE`)
            break
          }
          case 'auditConfigurationId': {
            const configType = this.props.combos.auditCodes.find(dt => dt.id === filters[item])
            value = configType && configType.value
            text = this.props.t(`${tKey}CODE`)
            break
          }
          default: {
            value = filters[item]
            text = this.props.t(`${tKey}${fromCamelToUnderscore(item).toUpperCase()}`)
            break
          }
        }

        if (value) {
          list.push(<li key={index}>{text}: <strong>{value}</strong>;</li>)
        }
        return list
      })
  }

  render () {
    const {
      t, handleSubmit, className, hasSearch, count, items,
      filters: { page, pages },
      combos: {
        auditCodes,
        UOTreeCombo,
        documentTypeUseCombo
      }
    } = this.props
    const tKey = 'MANAGEMENT.SEARCH_DOCUMENTAL.'
    const dKey = 'GLOBAL.TABLES.'
    const secMapManagement = MapSectionsManagement.find(function (s) { return s.page === 'searchDocumentalPage' })
    const hasResults = items && items.length > 0

    return (
      <>
        { this.state.visible &&
          <ZipModalPage/>
        }
        <div className="inner-results-panel">
          <div className="section-results-header">
            <div className="section-header-title">
              <OverlayTrigger placement="right" overlay={<Tooltip id="editProductTooltip">{`${t('GLOBAL.TABLES.' + (this.props.hideMenu ? 'SHOW_INNER_MENU' : 'HIDE_INNER_MENU'))}`}</Tooltip>}>
                <a onClick={() => this.props.toogleInnerMenu()} className={this.props.hideMenu ? 'collapseBtn' : 'collapseBtn closeInnerMenu'}><i className={this.props.hideMenu ? 'iDocIcon-Open-inner-menu' : 'iDocIcon-Close-inner-menu'}></i></a>
              </OverlayTrigger>
              <h4>{t(`${secMapManagement.title}`)}
                <OverlayTrigger placement="left" overlay={<Tooltip id="editProductTooltip">{`${t((this.state.hideFilters ? 'GLOBAL.TABLES.SHOW_FILTERS' : 'GLOBAL.TABLES.HIDE_FILTERS'))}`}</Tooltip>}>
                  <a onClick={() => this.toogleHideFilters()} className={'toogleShowFilters ' + hideFiltersBtn(hasResults)}><i className={toggleFilterClass(hasResults, this.state.hideFilters)}></i></a>
                </OverlayTrigger>
              </h4>
            </div>
            <Form autoComplete="off" onSubmit={handleSubmit((values) => this.submitSearchForm(values))} className="filters" >
              <div className={'search-form ' + (showFilter(hasResults, this.state.hideFilters) ? '' : 'hideFilters ')}>
                <Field
                  component={InputSelect}
                  colWidht={33} // 25, 33, 50, 75, 100 default=25
                  id="auditConfigurationId"
                  name='auditConfigurationId'
                  placeholder={t(`${tKey}CODE`)}
                  controlLabel={t(`${tKey}CODE`)}
                  options={auditCodes}
                  valueKey="id"
                  labelKey="value"
                  customClass={this.state.fields.dossierSubtypeId ? className : ''}
                />
                <Field
                  component={InputTreeSelect}
                  colWidht={33} // 25, 33, 50, 75, 100 default=25
                  id="organizedUnitIds"
                  name="organizedUnitIds"
                  placeholder={t(`${tKey}OU`)}
                  controlLabel={t(`${tKey}OU`)}
                  multi={true}
                  valueKey="value"
                  labelKey="label"
                  childrenKey="children"
                  options={UOTreeCombo}
                  onChange={(val) => this.setValues(val)}
                  colSm={3} />
                <Field
                  component={InputText}
                  colWidht={33} // 25, 33, 50, 75, 100 default=25
                  id="dossierNumber"
                  name="dossierNumber"
                  controlLabel={t(`${tKey}NUM_DOSSIER`)}
                  placeholder={t(`${tKey}NUM_DOSSIER`)}
                />
                <Field
                  component={InputText}
                  colWidht={33} // 25, 33, 50, 75, 100 default=25
                  id="vin"
                  name="vin"
                  controlLabel={t(`${tKey}VIN`)}
                  placeholder={t(`${tKey}VIN`)}
                  minLength={7}
                  maxLength={17}
                />
                <Field
                  component={InputText}
                  colWidht={33} // 25, 33, 50, 75, 100 default=25
                  colSm={3}
                  id="licensePlate"
                  name="licensePlate"
                  controlLabel={t(`${tKey}LICENSE`)}
                  placeholder={t(`${tKey}LICENSE`)}
                />
                <Field
                  component={InputText}
                  colWidht={33} // 25, 33, 50, 75, 100 default=25
                  colSm={3}
                  id="makerNumber"
                  name="makerNumber"
                  controlLabel={t(`${tKey}ODER`)}
                  placeholder={t(`${tKey}ODER`)}
                />
                <Field
                  component={InputDatePicker}
                  colWidht={50} // 25, 33, 50, 75, 100 default=25
                  maxDate={getDateNowMaxDatePicker()}
                  id="creationDateFrom"
                  name='creationDateFrom'
                  placeholder={t(`${tKey}CREATION_DATE_FROM`)}
                  controlLabel={t(`${tKey}CREATION_DATE_FROM`)}
                  validate={[this.dateValidation]}
                  onInputChange={(val) => this.fieldFill({ creationDateFrom: val })}
                  customClass={this.state.fields.creationDatefrom ? className : ''}
                />
                <Field
                  component={InputDatePicker}
                  colWidht={50} // 25, 33, 50, 75, 100 default=25
                  maxDate={getDateNowMaxDatePicker()}
                  id="creationDateTo"
                  name='creationDateTo'
                  placeholder={t(`${tKey}CREATION_DATE_TO`)}
                  controlLabel={t(`${tKey}CREATION_DATE_TO`)}
                  validate={[this.dateValidation]}
                  onInputChange={(val) => this.fieldFill({ creationDateTo: val })}
                  customClass={this.state.fields.creationDateTo ? className : ''}
                />
                <div className='search-form-actions flexInLine al-R'>
                  <Button id="btn_search"
                    type="submit"
                    className="_Btn accept stk">
                    <i className="iDocIcon-search"/>
                    {t('SEARCH.ACTIONS.SEARCH_BUTTON')}
                  </Button>
                  <Button
                    id="btn_reset"
                    onClick={(e) => this.handleClickReset(e)}
                    className={'_Btn' + (this.props.pristine ? ' accept' : ' accept')}
                    disabled={this.props.pristine}>
                    <i className="iDocIcon-clear-decline"/>
                    {t('SEARCH.ACTIONS.RESET_BUTTON')}
                  </Button>
                </div>
              </div>
            </Form>
          </div>
          <div className="section-results-body">
            <div className={'searchResoultsContainer' + (this.state.maxiTable ? ' maxiTable' : '')}>
              {hasSearch && ([
                <div className="table-wrapper fleets" key="table-wrapper">
                  <ul className="list-inline pull-left">
                    {this.selectedFilters().length === 0 ? <li>{t(`${tKey}NO_SELECTED_FILTERS`)}</li> : (this.selectedFilters())}
                  </ul>
                  {/* table options, maximize, etc.... */}
                  {hasResults &&
                  <div className='flexInLine al-R'>
                    <OverlayTrigger placement="left" overlay={<Tooltip id="editProductTooltip">{`${t(dKey + (this.state.maxiTable ? 'MINIMIZE_TABLE' : 'MAXIMIZE_TABLE'))}`}</Tooltip>}>
                      <a onClick={() => this.toogleMaxiTable()} className='_Btn accept stk o-icn maximizeBtn'><i className={(this.state.maxiTable ? 'iDocIcon-Minimize' : 'iDocIcon-Maximize')}></i></a>
                    </OverlayTrigger>
                    <OverlayTrigger placement="left" overlay={<Tooltip id="editProductTooltip">{t('SEARCH.ACTIONS.EXPORT')}</Tooltip>}>
                      <a onClick={(event) => this.downloadDoc(event)} className='_Btn new o-icn exportToExcel'><i className="iDocIcon-file-excel"></i></a>
                    </OverlayTrigger>
                  </div>}
                  {/* table */}
                  {hasResults ? [
                    <div className="table-responsive">
                      <SimpleTablePage
                        columns={[
                          <th key={1} data-field="documentType">
                            {t(`${tKey}DOCUMENT_TYPE`)}
                          </th>,
                          <th key={2} data-field="Number" onClick={(event) => this.onOrderChange(event)}>
                            {t(`${tKey}DOSSIER`)}&nbsp;<i className={getIconClass('number', this.props.filters)}/>
                          </th>,
                          <th key={3} data-field="MakerNumber" onClick={(event) => this.onOrderChange(event)}>
                            {t(`${tKey}ODER`)}&nbsp;<i className={getIconClass('makerNumber', this.props.filters)}/>
                          </th>,
                          <th key={4} data-field="LicensePlate" onClick={(event) => this.onOrderChange(event)}>
                            {t(`${tKey}LICENSE`)}&nbsp;<i className={getIconClass('licensePlate', this.props.filters)}/>
                          </th>,
                          <th key={5} data-field="CreationDate" onClick={(event) => this.onOrderChange(event)}>
                            {t(`${tKey}CREATION_DATE`)}&nbsp;<i className={getIconClass('creationDate', this.props.filters)}/>
                          </th>,
                          <th key={6} data-field="check" >
                            <Field
                              component={InputCheckBox}
                              name={'selectedCheck_massive'}
                              onChange={(e) => this.onCheckAll(e)}
                              checked={this.state.selectAll}
                            />
                          </th>,
                          <th key={7} data-field="actions">{t(`${tKey}ACTIONS`)}&nbsp;</th>
                        ]}
                        rows={items && items.map((row, idx) => (
                          <tr key={idx}>
                            <td>{documentTypeUseCombo?.find(d => d.id === row.documentTypeUseId)?.value}</td>
                            <td>{row.number}</td>
                            <td>{row.makerNumber}</td>
                            <td>{row.licensePlate}</td>
                            <td>{(row.creationDate) ? formatDate(row.creationDate) : '-'}</td>
                            <td>
                              <Field
                                component={InputCheckBox}
                                name={'selectedCheck_' + row.documentId}
                                id={'selectedCheck_' + row.documentId}
                                checked={
                                  this.state.checkboxes[row.documentId] || false
                                }
                                onChange={(event) => this.onCheckOne(event, row.documentId)} />
                            </td>
                            <td className="actionsMenu see-more">
                              <OverlayTrigger placement="left" overlay={<Tooltip id="stepTooltip">{t('DASHBOARD_TASK.TASK_LIST.TABLE.TOOLTIP.GO_TO')}</Tooltip>}>
                                <a onClick={() => this.viewDocument(row)} className='_Btn accept stk o-icn noLine'>
                                  <i className="iDocIcon-open-in-new-black" />
                                </a>
                              </OverlayTrigger>
                            </td>
                          </tr>
                        ))}
                      />
                    </div>,
                    <div className="search-footer" key="search-footer">
                      <IDocCarPagination
                        id="btn_pag_sales"
                        page={page}
                        pagesCount={pages}
                        totalRows= {count}
                        onSelectAction={(page) => this.onPageChange(page)}
                      />
                    </div>
                  ] : <p className="no_results">{t('GLOBAL.TABLES.NO_RESULTS')}</p>}
                </div>
              ])}
              <ProgressBar style={{ display: this.state.displayBar }}>
                <ProgressBar max={this.state.maxBar} now={this.state.progress} label={`${this.state.progress}/${this.state.maxBar}`} striped variant="success" key={1} />
                <ProgressBar max={this.state.maxBar} now={this.state.progressFail} label={`${this.state.progressFail}/${this.state.maxBar}`} striped variant="danger" style={{ backgroundColor: '#d44b4b' }} key={2} />
              </ProgressBar>
            </div>
          </div>
        </div>
        <Form autoComplete="off" onSubmit={handleSubmit((values) => this.submitSearchForm(values))} className="filters hide" >
          <div>
            <>
              <Row>
                <Col sm={12}>
                  <Field
                    id="auditConfigurationId"
                    name='auditConfigurationId'
                    placeholder={t(`${tKey}CODE`)}
                    controlLabel={t(`${tKey}CODE`)}
                    component={InputSelect}
                    options={auditCodes}
                    valueKey="id"
                    labelKey="value"
                    customClass={this.state.fields.dossierSubtypeId ? className : ''}
                  />
                  <Field
                    id="organizedUnitIds"
                    name="organizedUnitIds"
                    placeholder={t(`${tKey}OU`)}
                    controlLabel={t(`${tKey}OU`)}
                    component={InputTreeSelect}
                    multi={true}
                    valueKey="value"
                    labelKey="label"
                    childrenKey="children"
                    options={UOTreeCombo}
                    onChange={(val) => this.setValues(val)}
                    colSm={3} />
                  <Field
                    colSm={3}
                    id="dossierNumber"
                    name="dossierNumber"
                    controlLabel={t(`${tKey}NUM_DOSSIER`)}
                    placeholder={t(`${tKey}NUM_DOSSIER`)}
                    component={InputText}
                  />
                  <Field
                    colSm={3}
                    id="vin"
                    name="vin"
                    controlLabel={t(`${tKey}VIN`)}
                    placeholder={t(`${tKey}VIN`)}
                    minLength={7}
                    maxLength={17}
                    component={InputText}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <Field
                    colSm={3}
                    id="licensePlate"
                    name="licensePlate"
                    controlLabel={t(`${tKey}LICENSE`)}
                    placeholder={t(`${tKey}LICENSE`)}
                    component={InputText}
                  />
                  <Field
                    colSm={3}
                    id="makerNumber"
                    name="makerNumber"
                    controlLabel={t(`${tKey}ODER`)}
                    placeholder={t(`${tKey}ODER`)}
                    component={InputText}
                  />
                  <Field
                    id="creationDateFrom"
                    name='creationDateFrom'
                    placeholder={t(`${tKey}CREATION_DATE_FROM`)}
                    controlLabel={t(`${tKey}CREATION_DATE_FROM`)}
                    component={InputDatePicker}
                    validate={[this.dateValidation]}
                    onInputChange={(val) => this.fieldFill({ creationDateFrom: val })}
                    customClass={this.state.fields.creationDatefrom ? className : ''}
                  />
                  <Field
                    id="creationDateTo"
                    name='creationDateTo'
                    placeholder={t(`${tKey}CREATION_DATE_TO`)}
                    controlLabel={t(`${tKey}CREATION_DATE_TO`)}
                    component={InputDatePicker}
                    validate={[this.dateValidation]}
                    onInputChange={(val) => this.fieldFill({ creationDateTo: val })}
                    customClass={this.state.fields.creationDateTo ? className : ''}
                  />
                  <div key="clearfix_1" className="clearfix" />
                </Col>
              </Row>
              <Row className='buttons-row'>
                <Col sm={12} className="text-center">
                  <hr/>
                  <Button
                    id="btn_search"
                    type="submit"
                    className="btn-standard"
                  >
                    <i className="ico-search"/>
                    {t('SEARCH.ACTIONS.SEARCH_BUTTON')}
                  </Button>
                  <Button
                    id="btn_reset"
                    onClick={(e) => this.handleClickReset(e)}
                    className={'btn-white btn-icon' + (this.props.pristine && !hasSearch ? ' btn-cancel' : ' btn-standard')}
                    disabled={this.props.pristine && !hasSearch}
                  >
                    <i className="ico-cancel"/> {t('SEARCH.ACTIONS.RESET_BUTTON')}
                  </Button>
                </Col>
              </Row>
            </>
            {
              hasSearch && ([
                <div className="table-wrapper" key="table-wrapper">
                  {hasSearch && count > 0 && Object.values(this.state.checkboxes).filter(value => value).length > 0 &&
                <Row>
                  <Button
                    onClick={() => { this.downloadDoc() }}
                    bsStyle="default"
                    bsSize="small"
                    className="button-search-left">
                    {t(`${tKey}DOWNLOAD_BTN`)}
                    <i className="ico-if_Download_1031520"/>
                  </Button>
                </Row>
                  }
                  <SimpleTablePage
                    columns={[
                      <th key={1} data-field="documentType">
                        {t(`${tKey}DOCUMENT_TYPE`)}
                      </th>,
                      <th key={2} data-field="Number" onClick={(event) => this.onOrderChange(event)}>
                        {t(`${tKey}DOSSIER`)}&nbsp;<i className={getIconClass('number', this.props.filters)}/>
                      </th>,
                      <th key={3} data-field="MakerNumber" onClick={(event) => this.onOrderChange(event)}>
                        {t(`${tKey}ODER`)}&nbsp;<i className={getIconClass('makerNumber', this.props.filters)}/>
                      </th>,
                      <th key={4} data-field="LicensePlate" onClick={(event) => this.onOrderChange(event)}>
                        {t(`${tKey}LICENSE`)}&nbsp;<i className={getIconClass('licensePlate', this.props.filters)}/>
                      </th>,
                      <th key={5} data-field="CreationDate" onClick={(event) => this.onOrderChange(event)}>
                        {t(`${tKey}CREATION_DATE`)}&nbsp;<i className={getIconClass('creationDate', this.props.filters)}/>
                      </th>,
                      <th key={6} data-field="check" >
                        <Field
                          component={InputCheckBox}
                          name={'selectedCheck_massive'}
                          onChange={(e) => this.onCheckAll(e)}
                          checked={this.state.selectAll}
                        />
                      </th>,
                      <th key={7} data-field="actions">{t(`${tKey}ACTIONS`)}&nbsp;</th>
                    ]}
                    rows={items && items.map((row, idx) => (
                      <tr key={idx}>
                        <td>{documentTypeUseCombo?.find(d => d.id === row.documentTypeUseId)?.value}</td>
                        <td>{row.number}</td>
                        <td>{row.makerNumber}</td>
                        <td>{row.licensePlate}</td>
                        <td>{(row.creationDate) ? formatDate(row.creationDate) : '-'}</td>
                        <td>
                          <Field
                            component={InputCheckBox}
                            name={'selectedCheck_' + row.documentId}
                            id={'selectedCheck_' + row.documentId}
                            checked={
                              this.state.checkboxes[row.documentId] || false
                            }
                            onChange={(event) => this.onCheckOne(event, row.documentId)} />
                        </td>
                        <td className="actionsMenu">
                          <i className="iDocIcon-open-in-new-black blue" onClick={() => this.viewDocument(row)}></i>
                        </td>
                      </tr>
                    ))}
                  />
                </div>,
                <div className="search-footer" key="search-footer">
                  <IDocCarPagination
                    id="btn_pag_sales"
                    page={page}
                    pagesCount={pages}
                    totalRows= {count}
                    onSelectAction={(page) => this.onPageChange(page)}
                  />
                </div>
              ])
            }
            <ProgressBar style={{ display: this.state.displayBar }}>
              <ProgressBar max={this.state.maxBar} now={this.state.progress} label={`${this.state.progress}/${this.state.maxBar}`} striped variant="success" key={1} />
              <ProgressBar max={this.state.maxBar} now={this.state.progressFail} label={`${this.state.progressFail}/${this.state.maxBar}`} striped variant="danger" style={{ backgroundColor: '#d44b4b' }} key={2} />
            </ProgressBar>
          </div>
        </Form>
      </>
    )
  }
}

export default reduxForm({
  form: 'searchAuditsForm',
  validate: (values, props) => {
    const errors = {}
    if (!values.auditConfigurationId || values.auditConfigurationId === undefined) {
      errors.auditConfigurationId = props.t('DOSSIER_COMPONENTS.VALIDATIONS.MANDATORY_FIELD')
      return errors
    }
    if (values.creationDateTo !== undefined) {
      const format = 'D-M-YYYY'
      const fromDate = moment(values.creationDateFrom, format)
      const toDate = moment(values.creationDateTo, format)
      if (toDate.isBefore(fromDate)) {
        errors.creationDateTo = props.t('DOSSIER_COMPONENTS.VALIDATIONS.DATE_VALIDATION')
        return errors
      }
    }
  }
})(SearchDocumental)
