import scrollerActionTypes from '../../constants/actions/commons/scroller'

export function setScrollTo (element, options, containerId) {
  return {
    type: scrollerActionTypes.SET_SCROLL_TO,
    element,
    options,
    containerId
  }
}

export function setScrollerOptions (options) {
  return {
    type: scrollerActionTypes.SET_SCROLLER_OPTIONS,
    options
  }
}

export default {
  setScrollTo,
  setScrollerOptions
}
