
import React, { PureComponent } from 'react'
import { Modal, Col, Row, Button } from 'react-bootstrap'
import { reduxForm, Field } from 'redux-form'
import InputSelect from '../../../commons/form/InputSelect'
import InputNumber from '../../../commons/form/InputNumber'
class ProcessingAgencyIntegrationRegistrationTypesMasterModal extends PureComponent {
  constructor () {
    super()
    this.state = {}
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevProps.showModal !== this.props.showModal && this.props.showModal === true) {
      this.props.initialize(this.props.processingAgencyRegistrationType)
    }
  }

  submitProcessingAgencyRegistrationType (values) {
    const processingAgencyRegistrationType = {
      ProcessingAgencyRegistrationTypeId: this.props.processingAgencyRegistrationType?.processingAgencyRegistrationTypeId,
      ProcessingAgencyId: this.props.agency.processingAgencyId,
      DossierOperationTypeId: values.dossierOperationTypeId,
      RegistrationTypeId: values.registrationTypeId
    }

    this.props.actions.submitProcessingAgencyRegistrationTypes(this.props.agency.processingAgencyId, processingAgencyRegistrationType)
  }

  render () {
    const {
      t, handleSubmit,
      showModal
    } = this.props
    const tKey = 'MASTERS.PROCESSING_AGENCY_MASTERS.REGISTRATION_TYPES.'
    return (
      <Modal className="role-modal languages-modal integrations-modal-padding" show={showModal} onHide={() => this.props.actions.showProcessingAgencyRegistrationTypesModal(null, false)} backdrop={'static'}>
        <form autoComplete="off" onSubmit={handleSubmit(this.submitProcessingAgencyRegistrationType.bind(this))}>
          <Modal.Header closeButton onHide={() => this.props.actions.showProcessingAgencyRegistrationTypesModal(null, false)}>
            <Modal.Title>{t(`${tKey}REGISTRATION_TYPES`)}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="inputs-wrapper">
              <Col sm={6} className="custom-width-col">
                <h2>{t(`${tKey}OPERATION_TYPE`)}</h2>
                <Field
                  name="dossierOperationTypeId"
                  placeholder={t(`${tKey}OPERATION_TYPE`)}
                  component={InputSelect}
                  options={this.props.combos.operationTypeAllCombo}
                  valueKey="id"
                  labelKey="value"
                  colSm={0}
                />
              </Col>
              <Col sm={6}>
                <h2>{t(`${tKey}REGISTRATION_TYPE`)}</h2>
                <Field
                  name="registrationTypeId"
                  placeholder={t(`${tKey}REGISTRATION_TYPE`)}
                  component={InputNumber}
                  required={[true]}
                  maxLength={50}
                  colSm={0}
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Col sm={12} className="btn-wrapper">
              <Button
                id="role-modal-submit-button"
                className="btn-standard"
                type="submit"
                disabled={this.props.pristine}>
                {t(`${tKey}MODAL.SUBMIT_BUTTON`)}
              </Button>
            </Col>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default reduxForm({
  form: 'editProcessingAgencyRegistrationTypeModal',
  validate: (values, props) => {
    let error = {}
    const tKey = 'MASTERS.PROCESSING_AGENCY_MASTERS.DOC_TYPE_USES.VALIDATION.'
    if (!values.dossierOperationTypeId) {
      error.dossierOperationTypeId = props.t(`${tKey}VALUE_REQUIRED`)
    }
    if (!values.registrationTypeId) {
      error.registrationTypeId = props.t(`${tKey}VALUE_REQUIRED`)
    }

    return Object.keys(error).length > 0 ? error : {}
  }
})(ProcessingAgencyIntegrationRegistrationTypesMasterModal)
