import apiFetch from '../apiFetch'

export default function (token, dossierId, templateTypeId) {
  return apiFetch({
    endPoint: `dossier/Sale/${dossierId}/PrintDeliveryCertificates/${templateTypeId}`,
    method: 'GET',
    parseBlobAndAssign: true,
    token: token
  })
}
