import dossiersListActionTypes from '../../../constants/actions/dashboard/dossiersList/dossiersList'

export function fetchDossiersList (organizedUnit, salesmanId, urgencyTypeId, subTypeId, stageId, page) {
  return {
    type: dossiersListActionTypes.FETCH_DOSSIERS_LIST,
    organizedUnit,
    salesmanId,
    urgencyTypeId,
    subTypeId,
    stageId,
    page
  }
}

export function fetchDossiersListSuccess (dossiersData, urgencyTypeId, page, orderBy) {
  return {
    type: dossiersListActionTypes.FETCH_DOSSIERS_LIST_SUCCESS,
    dossiersData,
    urgencyTypeId,
    page,
    orderBy
  }
}

export function fetchDossiersCompleteList (organizedUnit, salesmanId, urgencyTypeId, subTypeId, stageId, page, orderBy) {
  return {
    type: dossiersListActionTypes.FETCH_DOSSIERS_COMPLETE_LIST,
    organizedUnit,
    salesmanId,
    urgencyTypeId,
    subTypeId,
    stageId,
    page,
    orderBy
  }
}

export function exporToExcelDossiersList (organizedUnit, salesmanId, urgencyTypeId, urgencyName, subTypeId, stageId, page, orderBy, pageSize, cols, maxDossierExport, showMaxDossierExportMessage) {
  return {
    type: dossiersListActionTypes.FETCH_EXPORT_COMPLETE_LIST,
    organizedUnit,
    salesmanId,
    urgencyTypeId,
    urgencyName,
    subTypeId,
    stageId,
    page,
    orderBy,
    pageSize,
    cols,
    maxDossierExport,
    showMaxDossierExportMessage
  }
}

export default {
  fetchDossiersList,
  fetchDossiersCompleteList,
  exporToExcelDossiersList
}
