/* eslint-disable no-unused-vars */
import { put, call, select, takeEvery } from 'redux-saga/effects'
import { reset, change } from 'redux-form'
// selectors
import { getAuth } from '../../../selectors/access/auth'
// constants
import dossierActionsTypes from '../../../constants/actions/dossier/dossier'
import { dossierTypes as dossierTypesConstant } from '../../../constants/dossier/common/dossierTypes'
import { buttonsSidebar } from '../../../constants/dossier/common/buttonsSidebar'
// actions
import { showLoader, hideLoader, openModal } from '../../../actions/common'
import messageModalActions from '../../../actions/modals/messageModal'
import { fetchStockDossierSuccess, getDossierBudget, updateDossierBudgetRatingModal } from '../../../actions/dossier/stockDossier'
// stocks
import putStockDossierStock from '../../../services/stockDossier/put/putStockDossier'
import stockVoDossierConfirmDate from '../../../services/stockDossier/put/stockVoDossierConfirmDate'
import stockVoConfirmPhotoDateStock from '../../../services/stockDossier/put/stockVoConfirmPhotoDateStock'
import addEditDossierBudgetRatingPut from '../../../services/stockDossier/put/addEditDossierBudgetRating'
import deleteDossierBudgetRatingService from '../../../services/stockDossier/delete/deleteDossierBudgetRating'
import stockDossierVoRejectBudget from '../../../services/stockDossier/put/stockDossierVoRejectBudget'
// sagas
import { yesNoModal } from '../../modalsListeners/yesNoModal'
import { handleError } from '../../errors/errorManager'
import { initializeDossier } from '../commonDossierSagas'
import { compareObjects } from '../../../util/utils'
import { sliderBooleanValues } from '../../../constants/dossier/common/keyTabs'
import putActivateCrane from '../../../services/stockDossier/put/putActivateCrane'
import putDeactivateCrane from '../../../services/stockDossier/put/putDeactivateCrane'
import putDossierCraneConfirmDate from '../../../services/stockDossier/put/putDossierCraneConfirmDate'
import stockVoSkipDossierPhotos from '../../../services/stockDossier/put/stockVoSkipDossierPhotos'

export function * saveStockDossier ({ stockDossier }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const stockDossierState = yield select(state => state.stockDossier)

    if (!compareObjects(stockDossierState.dossierStockComponent || {}, stockDossier.dossierStockComponent, true)) {
      stockDossier.dossierStockComponent.updated = true
    }
    if (stockDossierState.dossierStockComponent) {
      stockDossier.dossierStockComponent.indicatePhotos = sliderBooleanValues[stockDossier.dossierStockComponent.indicatePhotos]
      stockDossier.dossierStockComponent.publishPhotos = sliderBooleanValues[stockDossier.dossierStockComponent.publishPhotos]
      stockDossier.dossierStockComponent.fastline = sliderBooleanValues[stockDossier.dossierStockComponent.fastline]
    }

    if (stockDossier.reception) {
      stockDossier.reception.prewash = sliderBooleanValues[stockDossier.reception.prewash]
      if (!compareObjects(stockDossierState.reception || {}, stockDossier.reception, true)) {
        stockDossier.reception.updated = true
      }
    }
    if (stockDossier.dossierBudget) {
      if (stockDossier.dossierBudget.comment !== stockDossierState.dossierBudget.comment || stockDossier.dossierBudget.deliveryDate !== stockDossierState.dossierBudget.deliveryDate) {
        yield put(fetchStockDossierSuccess({ ...stockDossierState, dossierBudget: stockDossier.dossierBudget }))
        stockDossier.dossierBudget.updated = true
      }
    }

    if (stockDossier.crane) {
      if (stockDossier.crane.destinationCraneId !== stockDossierState.crane.destinationCraneId) {
        stockDossier.crane.updated = true
      }
    }

    if (stockDossier.dossierPhotosComponent) {
      if (!compareObjects(stockDossierState.dossierPhotosComponent || {}, stockDossier.dossierPhotosComponent, true)) {
        yield put(fetchStockDossierSuccess({ ...stockDossierState, dossierPhotosComponent: stockDossier.dossierPhotosComponent }))
        stockDossier.dossierPhotosComponent.updated = true
        stockDossier.dossierPhotosComponent.vehicleReserved = sliderBooleanValues[stockDossier.dossierPhotosComponent.vehicleReserved]
      }
    }

    const subDossier = {
      dossierParentId: stockDossierState.dossierParentId,
      parentIsHistorical: stockDossierState.parentIsHistorical,
      dossierStockComponent: stockDossier.dossierStockComponent,
      dossierVehicleComponent: JSON.stringify(stockDossier.vehicleComponent) === '{}' ? null : stockDossier.vehicleComponent,
      dossierReceptionComponent: JSON.stringify(stockDossier.reception) === '{}' ? null : stockDossier.reception,
      dossierBudgetComponent: JSON.stringify(stockDossier.dossierBudget) === '{}' ? null : stockDossier.dossierBudget,
      dossierCraneComponent: JSON.stringify(stockDossier.crane) === '{}' ? null : stockDossier.crane,
      DossierPhotosComponent: JSON.stringify(stockDossier.dossierPhotosComponent) === '{}' ? null : stockDossier.dossierPhotosComponent
    }

    if (stockDossier.vehicleComponent.updated === true) {
      subDossier.parentIsHistorical = stockDossier.vehicleComponent.parentIsHistorical
      subDossier.dossierParentId = stockDossier.vehicleComponent.dossierId
    }

    yield call(putStockDossierStock, stockDossier.dossierId, subDossier, auth.token, stockDossierState.lastAccessDate)
    yield put(messageModalActions.openSuccessMessageModal('MESSAGES.PUT_DOSSIER_SUCCESS'))
    yield call(initializeDossier, dossierTypesConstant.stock, stockDossier)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          yield call(initializeDossier, dossierTypesConstant.stock, stockDossier)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('stock_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSaveStockDossier () {
  yield takeEvery(dossierActionsTypes.SAVE_STOCK_DOSSIER, saveStockDossier)
}

export function * confirmDateWithIdByStage ({ dossierId, callback, pristine }) {
  const stockDossierState = yield select(state => state.stockDossier)
  if (pristine !== undefined && !pristine) {
    const acceptCloseModal = yield call(yesNoModal, 'commonDossierSaveWarning')
    if (!acceptCloseModal) {
      return
    }
  }
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(stockVoDossierConfirmDate, dossierId, auth.token, stockDossierState.lastAccessDate)
    yield put(messageModalActions.openSuccessMessageModal('MESSAGES.PUT_DOSSIER_SUCCESS'))
    yield call(initializeDossier, dossierTypesConstant.stock, stockDossierState)
    if (callback) yield call(callback, dossierId)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          yield call(initializeDossier, dossierTypesConstant.stock, stockDossierState)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('stock_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchConfirmDateWithIdByStage () {
  yield takeEvery(dossierActionsTypes.STOCK_DOSSIER_VO_CONFIRM_DATE, confirmDateWithIdByStage)
}

export function * confirmPhotos ({ dossierId, typeButton, confirm, callback }) {
  const stockDossierState = yield select(state => state.stockDossier)
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(stockVoConfirmPhotoDateStock, dossierId, typeButton, confirm, auth.token, stockDossierState.lastAccessDate)
    yield put(messageModalActions.openSuccessMessageModal('MESSAGES.PUT_DOSSIER_SUCCESS'))
    yield call(initializeDossier, dossierTypesConstant.stock, stockDossierState)
    if (callback) yield call(callback, dossierId)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          yield call(initializeDossier, dossierTypesConstant.stock, stockDossierState)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('stock_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchConfirmPhotos () {
  yield takeEvery(dossierActionsTypes.STOCK_DOSSIER_VO_CONFIRM_PHOTO, confirmPhotos)
}

export function * addEditDossierBudgetRating ({ values }) {
  const stockDossierState = yield select(state => state.stockDossier)
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    let budgetRating = {}
    budgetRating.dossierBudgetId = stockDossierState.dossierId
    budgetRating.dossierBudgetRatingId = values.dossierBudgetRatingId
    if (values.ratingId) {
      budgetRating.ratingId = values.ratingId
    } else if (values.freeRating && values.amount) {
      budgetRating.freeRating = values.freeRating
      budgetRating.amount = values.amount
    }
    yield call(addEditDossierBudgetRatingPut, stockDossierState.dossierId, budgetRating, auth.token, stockDossierState.lastAccessDate)
    yield put(getDossierBudget(stockDossierState.dossierId, false))
    yield put(reset('budgetRatingModal'))
    yield put(updateDossierBudgetRatingModal())
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          yield call(initializeDossier, dossierTypesConstant.stock, stockDossierState)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('stock_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchAddEditDossierBudgetRating () {
  yield takeEvery(dossierActionsTypes.ADD_EDIT_DOSSIER_BUDGET_RATING, addEditDossierBudgetRating)
}

export function * deleteDossierBudgetRating ({ dossierBudgetRatingId }) {
  const stockDossierState = yield select(state => state.stockDossier)
  try {
    const confirm = yield call(yesNoModal, 'deleteGeneric')
    if (confirm) {
      yield put(showLoader())
      const auth = yield select(getAuth)
      yield call(deleteDossierBudgetRatingService, dossierBudgetRatingId, auth.token, stockDossierState.lastAccessDate)
      yield put(getDossierBudget(stockDossierState.dossierId, false))
      yield put(reset('budgetRatingModal'))
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          yield call(initializeDossier, dossierTypesConstant.stock, stockDossierState)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('stock_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDeleteDossierBudgetRating () {
  yield takeEvery(dossierActionsTypes.DELETE_DOSSIER_BUDGET_RATING, deleteDossierBudgetRating)
}

export function * rejectDossierStockVoBudget ({ id }) {
  const stockDossierState = yield select(state => state.stockDossier)
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(stockDossierVoRejectBudget, id, auth.token, stockDossierState.lastAccessDate)
    yield put(messageModalActions.openSuccessMessageModal('MESSAGES.PUT_DOSSIER_SUCCESS'))
    yield call(initializeDossier, dossierTypesConstant.stock, stockDossierState)
    yield put(getDossierBudget(stockDossierState.dossierId, false))
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          yield call(initializeDossier, dossierTypesConstant.stock, stockDossierState)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('stock_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchRejectDossierStockVoBudget () {
  yield takeEvery(dossierActionsTypes.STOCK_DOSSIER_VO_REJECT_BUDGET, rejectDossierStockVoBudget)
}

export function * changeRelationEntryValues ({ value, key }) {
  try {
    let dossierStockComponent = JSON.parse(JSON.stringify(yield select(state => state.form.stock_dossier.values.dossierStockComponent)))
    dossierStockComponent[key] = value
    yield put(change('stock_dossier', 'dossierStockComponent', dossierStockComponent))
  } catch (er) {
  }
}

export function * watchChangeRelationEntryValues () {
  yield takeEvery(dossierActionsTypes.STOCK_DOSSIER_VO_CHANGE_ENTRY_RELATION, changeRelationEntryValues)
}

export function * activateDossierCrane ({ dossierId, pristine }) {
  const stockDossierState = yield select(state => state.stockDossier)
  let stock_dossier = JSON.parse(JSON.stringify(yield select(state => state.form.stock_dossier)))

  try {
    const auth = yield select(getAuth)
    if (!pristine) {
      const confirm = yield call(yesNoModal, 'saveDossierAndExecute')
      if (confirm) {
        yield put(showLoader())
        yield call(putActivateCrane, dossierId, auth.token)
        yield call(initializeDossier, dossierTypesConstant.stock, stockDossierState)
        yield put(messageModalActions.openSuccessMessageModal('MESSAGES.PUT_DOSSIER_SUCCESS'))
      } else {
        stockDossierState.crane.needCrane = false
        stock_dossier.values.crane.needCrane = false
        yield put(change('stock_dossier', 'crane', stock_dossier.values.crane))
      }
    } else {
      yield put(showLoader())
      yield call(putActivateCrane, dossierId, auth.token)
      yield call(initializeDossier, dossierTypesConstant.stock, stockDossierState)
      yield put(messageModalActions.openSuccessMessageModal('MESSAGES.PUT_DOSSIER_SUCCESS'))
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('stock_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchActivateDossierCrane () {
  yield takeEvery(dossierActionsTypes.STOCK_DOSSIER_VO_ACTIVATE_CRANE, activateDossierCrane)
}

export function * deactivateDossierCrane ({ dossierId, pristine }) {
  const stockDossierState = yield select(state => state.stockDossier)
  let stock_dossier = JSON.parse(JSON.stringify(yield select(state => state.form.stock_dossier)))

  try {
    const auth = yield select(getAuth)
    if (!pristine) {
      const confirm = yield call(yesNoModal, 'saveDossierAndExecute')
      if (confirm) {
        yield put(showLoader())
        yield call(putDeactivateCrane, dossierId, auth.token)
        yield call(initializeDossier, dossierTypesConstant.stock, stockDossierState)
        yield put(messageModalActions.openSuccessMessageModal('MESSAGES.PUT_DOSSIER_SUCCESS'))
      } else {
        stockDossierState.crane.needCrane = true
        stock_dossier.values.crane.needCrane = true
        yield put(change('stock_dossier', 'crane', stock_dossier.values.crane))
      }
    } else {
      yield put(showLoader())
      yield call(putDeactivateCrane, dossierId, auth.token)
      yield call(initializeDossier, dossierTypesConstant.stock, stockDossierState)
      yield put(messageModalActions.openSuccessMessageModal('MESSAGES.PUT_DOSSIER_SUCCESS'))
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('stock_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDeactivateDossierCrane () {
  yield takeEvery(dossierActionsTypes.STOCK_DOSSIER_VO_DEACTIVATE_CRANE, deactivateDossierCrane)
}

export function * dossierCraneConfirmDate ({ dossierId }) {
  const stockDossierState = yield select(state => state.stockDossier)
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(putDossierCraneConfirmDate, dossierId, auth.token, stockDossierState.lastAccessDate)
    yield put(messageModalActions.openSuccessMessageModal('MESSAGES.PUT_DOSSIER_SUCCESS'))
    yield call(initializeDossier, dossierTypesConstant.stock, stockDossierState)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          yield call(initializeDossier, dossierTypesConstant.stock, stockDossierState)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('stock_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDossierCraneConfirmDate () {
  yield takeEvery(dossierActionsTypes.STOCK_DOSSIER_DOSSIER_CRANE_CONFIRM_DATE, dossierCraneConfirmDate)
}

export function * skipDossierPhotos ({ dossierId }) {
  const stockDossierState = yield select(state => state.stockDossier)
  try {
    const auth = yield select(getAuth)
    yield call(stockVoSkipDossierPhotos, dossierId, auth.token, stockDossierState.lastAccessDate)
    yield put(messageModalActions.openSuccessMessageModal('MESSAGES.PUT_DOSSIER_SUCCESS'))
    yield call(initializeDossier, dossierTypesConstant.stock, stockDossierState)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          yield call(initializeDossier, dossierTypesConstant.stock, stockDossierState)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('stock_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSkipDossierPhotos () {
  yield takeEvery(dossierActionsTypes.SKIP_DOSSIER_PHOTOS, skipDossierPhotos)
}
