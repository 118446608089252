import apiFetch from '../apiFetch'

export default function (token, data, dossierSubtypeId, organizedUnitId, dossierId, lastAccessDate) {
  const queryParamsArray = [
    dossierSubtypeId ? `dossierSubtypeId=${dossierSubtypeId}` : null,
    organizedUnitId ? `organizedUnitId=${organizedUnitId}` : null,
    dossierId ? `dossierId=${dossierId}` : null
  ]
  const queryParams = queryParamsArray.reduce(
    (result, query, idx) => result + (query ? (result ? `&${query}` : `?${query}`) : '')
    , ''
  )

  return apiFetch({ endPoint: `Fleets/SaveEconomicPlan${queryParams}`, method: 'POST', body: data, token: token, lastAccessDate })
}
