import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Report from '../../components/report/Report'
import Report_v2 from '../../_v2/components/report/Report'
import { translate } from 'react-polyglot'
import { fetchWalletList } from '../../actions/reports/walletReport'
import { fetchBuyList } from '../../actions/reports/buyReport'
import { fetchStockList } from '../../actions/reports/stockReport'
import { fetchSaleList } from '../../actions/reports/saleReport'
import { fetchReceiptList } from '../../actions/reports/receiptReport'
import TrackingContainer from '../../containers/tracking/trackingContainer'
import { fetchPowerbiConfig, powerBiRefreshSession } from '../../actions/reports/powerbiReport'
import { fetchReportTypeCode } from '../../actions/reports/report'
import { getStorageValue } from '../../storage/storage'
import { resetFilters } from '../../actions/search/search'

export function mapStateToProps (state) {
  return {
    ...state.auth,
    ...state.report,
    ...state.search,
    ...state.usageReport,
    sectionsGeneral: state.auth.sections,
    powerbiConfig: state.powerbiReport
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchWalletList,
      fetchBuyList,
      fetchStockList,
      fetchSaleList,
      fetchReceiptList,
      fetchPowerbiConfig,
      fetchReportTypeCode,
      powerBiRefreshSession,
      resetFilters
    }, dispatch)
  }
}

let version = parseInt(getStorageValue('interfaceVersion'))
var decoratedComponent = null
if (version === 2) {
  decoratedComponent = TrackingContainer(Report_v2, true)
} else {
  decoratedComponent = TrackingContainer(Report, true)
}
export default connect(mapStateToProps, mapDispatchToProps)(translate()(decoratedComponent))
