export default {
  OPEN_DOCUMENT_CREATION_MODAL: 'OPEN_DOCUMENT_CREATION_MODAL',
  OPEN_DOCUMENT_CREATION_MODAL_SUCCESS: 'OPEN_DOCUMENT_CREATION_MODAL_SUCCESS',
  OPEN_DOCUMENT_EDITION_MODAL: 'OPEN_DOCUMENT_EDITION_MODAL',
  OPEN_DOCUMENT_EDITION_MODAL_SUCCESS: 'OPEN_DOCUMENT_EDITION_MODAL_SUCCESS',
  FETCH_ADD_OR_EDIT_DOCUMENT: 'FETCH_ADD_OR_EDIT_DOCUMENT',
  ON_DOCUMENT_TYPE_ID_CHANGE: 'ON_DOCUMENT_TYPE_ID_CHANGE',
  FETCH_ADD_OR_EDIT_DOCUMENT_SUCCESS: 'FETCH_ADD_OR_EDIT_DOCUMENT_SUCCESS',
  CLOSE_ADD_OR_EDIT_DOCUMENT_MODAL: 'CLOSE_ADD_OR_EDIT_DOCUMENT_MODAL',
  CLOSE_ADD_OR_EDIT_DOCUMENT_MODAL_SUCCESS: 'CLOSE_ADD_OR_EDIT_DOCUMENT_MODAL_SUCCESS',
  FETCH_DOCUMENT: 'FETCH_DOCUMENT',
  FETCH_DOCUMENT_SUCCESS: 'FETCH_DOCUMENT_SUCCESS',
  UPLOAD_DOCUMENT: 'UPLOAD_DOCUMENT',
  UPLOAD_NEW_VERSION: 'UPLOAD_NEW_VERSION',
  CREATE_DOCUMENT: 'CREATE_DOCUMENT',
  TOGGLE_FULL_SCREEN_DOCUMENT_MODAL: 'TOGGLE_FULL_SCREEN_DOCUMENT_MODAL',
  TOGGLE_FULL_SCREEN_DOCUMENT_MODAL_SUCCESS: 'TOGGLE_FULL_SCREEN_DOCUMENT_MODAL_SUCCESS',
  REJECT_DOCUMENT: 'REJECT_DOCUMENT',
  VALIDATE_DOCUMENT: 'VALIDATE_DOCUMENT',
  SAVE_DOCUMENT: 'SAVE_DOCUMENT',
  COPY_META_TO_ENTITY_VALUES: 'COPY_META_TO_ENTITY_VALUES',
  CHANGE_ID: 'CHANGE_ID',
  RECOVER_DOCUMENT: 'RECOVER_DOCUMENT',
  CREATE_DOCUMENT_SHEET: 'CREATE_DOCUMENT_SHEET',
  DELETE_DOCUMENT_SHEET: 'DELETE_DOCUMENT_SHEET',
  SET_ACTIVE_PAGE: 'SET_ACTIVE_PAGE',
  TOGGLE_FULL_SCREEN_DOCUMENT_SHEET_MODAL: 'TOGGLE_FULL_SCREEN_DOCUMENT_SHEET_MODAL',
  CHECK_CAN_CLOSE_DOCUMENT_MODAL: 'CHECK_CAN_CLOSE_DOCUMENT_MODAL',
  DOCUMENT_MODAL_UPDATE_LAST_ACCESS_DATE: 'DOCUMENT_MODAL_UPDATE_LAST_ACCESS_DATE',
  OPEN_DOCUMENT_CREATION_MODAL_FLEETS: 'OPEN_DOCUMENT_CREATION_MODAL_FLEETS',
  OPEN_DOCUMENT_CREATION_MODAL_FLEETS_SUCCESS: 'OPEN_DOCUMENT_CREATION_MODAL_FLEETS_SUCCESS',
  ON_DOCUMENT_TYPE_ID_CHANGE_FLEETS: 'ON_DOCUMENT_TYPE_ID_CHANGE_FLEETS',
  FETCH_DOCUMENT_FLEETS_SUCCESS: 'FETCH_DOCUMENT_FLEETS_SUCCESS',
  CREATE_DOCUMENT_FLEETS: 'CREATE_DOCUMENT_FLEETS',
  REQUEST_SIGN: 'REQUEST_SIGN',
  CANCEL_SIGN: 'CANCEL_SIGN',
  CHECK_DEPENDENCE_METADATO: 'CHECK_DEPENDENCE_METADATO',
  SIGN_TYPES: 'SIGN_TYPES',
  EXTRACT_METADATA: 'EXTRACT_METADATA',
  LOADING_METADATA: 'LOADING_METADATA',
  ACCEPT_MULTIPLE_SIGN_CANCEL: 'ACCEPT_MULTIPLE_SIGN_CANCEL',
  REJECT_MULTIPLE_SIGN_CANCEL: 'REJECT_MULTIPLE_SIGN_CANCEL',
  REFRESH_DOC_RESULT: 'REFRESH_DOC_RESULT',
  REFRESH_DOC_RESULT_SUCCESS: 'REFRESH_DOC_RESULT_SUCCESS',
  CANCEL_SIGN_MESSAGE: 'CANCEL_SIGN_MESSAGE',
  CANCEL_SIGN_MESSAGE_SUCCESS: 'CANCEL_SIGN_MESSAGE_SUCCESS',
  GET_CANCEL_DOC_LIST: 'GET_CANCEL_DOC_LIST',
  OPEN_DOCUMENT_EDITION_MODAL_FLEETS: 'OPEN_DOCUMENT_EDITION_MODAL_FEETS',
  OPEN_DOCUMENT_EDITION_MODAL_SUCCESS_FEETS: 'OPEN_DOCUMENT_EDITION_MODAL_SUCCESS_FEETS',
  SEAL_STAMP_DOCUMENT: 'SEAL_STAMP_DOCUMENT',
  CREATE_SIGN_DOCUMENT_DYNAMIC_FROM_TEMPLATE: 'CREATE_SIGN_DOCUMENT_DYNAMIC_FROM_TEMPLATE',
  GENERATE_DOCUMENT_DYNAMIC_FROM_TEMPLATE: 'GENERATE_DOCUMENT_DYNAMIC_FROM_TEMPLATE'
}
