export default {
  FETCH_DASHBOARD: 'FETCH_DASHBOARD',
  FETCH_FILTERED_DASHBOARD: 'FETCH_FILTERED_DASHBOARD',
  FETCH_DASHBOARD_SUCCESS: 'FETCH_DASHBOARD_SUCCESS',
  OPEN_DOCUMENT_UPLOAD_MODAL: 'OPEN_DOCUMENT_UPLOAD_MODAL',
  OPEN_DOCUMENT_UPLOAD_MODAL_SUCCESS: 'OPEN_DOCUMENT_UPLOAD_MODAL_SUCCESS',
  CLOSE_DOCUMENT_UPLOAD_MODAL: 'CLOSE_DOCUMENT_UPLOAD_MODAL',
  CLOSE_DOCUMENT_UPLOAD_MODAL_SUCCESS: 'CLOSE_DOCUMENT_UPLOAD_MODAL_SUCCESS',
  SET_FIRST_LOAD: 'SET_FIRST_LOAD',
  SUBMIT_DOCUMENT_SUCCESS: 'SUBMIT_DOCUMENT_SUCCESS',
  CHECK_CAN_CLOSE_UPLOAD_MODAL: 'CHECK_CAN_CLOSE_UPLOAD_MODAL',
  UPLOAD_DOCUMENT_FLEET: 'UPLOAD_DOCUMENT_FLEET',
  FETCH_CREATE_DOSSIER_BUTTON: 'FETCH_CREATE_DOSSIER_BUTTON',
  FETCH_CREATE_DOSSIER_BUTTON_SUCCESS: 'FETCH_CREATE_DOSSIER_BUTTON_SUCCESS',
  SET_SALESMAN_ID: 'SET_SALESMAN_ID',
  FETCH_CREATE_BUTTONS: 'FETCH_CREATE_BUTTONS',
  FETCH_CREATE_BUTTONS_SUCCESS: 'FETCH_CREATE_BUTTONS_SUCCESS',
  FETCH_DOSSIER_FAVORITES: 'FETCH_DOSSIER_FAVORITES',
  FETCH_DOSSIER_FAVORITES_SUCCESS: 'FETCH_DOSSIER_FAVORITES_SUCCESS',
  FETCH_DOSSIER_LAST_ACCESSED: 'FETCH_DOSSIER_LAST_ACCESSED',
  FETCH_DOSSIER_LAST_ACCESSED_SUCCESS: 'FETCH_DOSSIER_LAST_ACCESSED_SUCCESS',
  FETCH_TASK_FAVORITES: 'FETCH_TASK_FAVORITES',
  FETCH_TASK_FAVORITES_SUCCESS: 'FETCH_TASK_FAVORITES_SUCCESS',
  SWITCH_CARD_TASK_FAVORITE: 'SWITCH_CARD_TASK_FAVORITE',
  SWITCH_CARD_DOSSIER_FAVORITE: 'SWITCH_CARD_DOSSIER_FAVORITE'
}
