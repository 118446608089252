import React, { PureComponent } from 'react'
import { Row, Tab, Tabs, Col, Checkbox } from 'react-bootstrap'
import ClientsTab1 from './TabPane11Tabs/Clients/Tab1'
import ClientsTab2 from './TabPane11Tabs/Clients/Tab2'
import VehiclesTab1 from './TabPane11Tabs/Vehicles/Tab1'
import VehiclesTab2 from './TabPane11Tabs/Vehicles/Tab2'
import UaCTab1 from './TabPane11Tabs/UaC/Tab1'
import UaCTab2 from './TabPane11Tabs/UaC/Tab2'

class TabPane11 extends PureComponent {
  render () {
    return (
      <Col sm={12} className="documents-management-tabPane">
        <Row className="clients row-border">
          <h4>Clientes</h4>
          <Row className="tabs-wrapper">
            <Tabs id='clients-tabs' defaultActiveKey={1} className="sub-tabs margin-left-subtabs">
              <Tab eventKey={1} title="Cliente propuesta">
                <ClientsTab1/>
              </Tab>
              <Tab eventKey={2} title="Cliente matriculación">
                <ClientsTab2/>
              </Tab>
            </Tabs>
          </Row>
        </Row>
        <Row className="row-border">
          <h4>Tipo venta</h4>
          <Row className="no-mrg">
            <Col sm={4} className="checkbox-point">
              <Checkbox inline>Libro de familia</Checkbox><span className="point green"></span>
            </Col>
            <Col sm={4} className="checkbox-point">
              <Checkbox inline>CIF empresa</Checkbox><span className="point green"></span>
            </Col>
            <Col sm={4} className="checkbox-point">
              <Checkbox inline>IAE Autónomos</Checkbox><span className="point green"></span>
            </Col>
          </Row>
          <Row className="no-mrg">
            <Col sm={4} className="checkbox-point">
              <Checkbox inline>Cert. minusválido</Checkbox><span className="point green"></span>
            </Col>
            <Col sm={4} className="checkbox-point">
              <Checkbox inline>CIF administrador</Checkbox><span className="point green"></span>
            </Col>
          </Row>
          <Row className="no-mrg">
            <Col sm={4} className="checkbox-point">
              <Checkbox inline>Cert.cuerpo dipl.</Checkbox><span className="point green"></span>
            </Col>
            <Col sm={4} className="checkbox-point">
              <Checkbox inline>Escrituras</Checkbox><span className="point green"></span>
            </Col>
          </Row>
        </Row>
        <Row className="row-border">
          <h4>Financiación</h4>
          <div className="checkbox-point">
            <Checkbox inline> Nómina </Checkbox>
            <span className="point green"></span>
          </div>
          <div className="checkbox-point">
            <Checkbox inline> Domic. bancaria </Checkbox>
            <span className="point green"></span>
          </div>
          <div className="checkbox-point">
            <Checkbox inline> Opción 3 </Checkbox>
            <span className="point green"></span>
          </div>
        </Row>
        <Row className="row-border">
          <h4>Vehículo</h4>
          <Tabs id='vehicles-tabs' defaultActiveKey={1} className="sub-tabs margin-left-subtabs">
            <Tab eventKey={1} title="Cliente propuesta">
              <VehiclesTab1/>
            </Tab>
            <Tab eventKey={2} title="Cliente matriculación">
              <VehiclesTab2/>
            </Tab>
          </Tabs>
        </Row>
        <Row className="row-border">
          <h4>UaC</h4>
          <Tabs id='uac-tabs' defaultActiveKey={1} className="sub-tabs margin-left-subtabs">
            <Tab eventKey={1} title="Cliente propuesta">
              <UaCTab1/>
            </Tab>
            <Tab eventKey={2} title="Cliente matriculación">
              <UaCTab2/>
            </Tab>
          </Tabs>
        </Row>
      </Col>
    )
  }
}

export default TabPane11
