export default {
  CLOSE_EDIT_ORGANIZED_UNIT_CONFIGURATION_MODAL: 'CLOSE_EDIT_ORGANIZED_UNIT_CONFIGURATION_MODAL',
  DELETE_ORGANIZED_UNIT_CONFIGURATION: 'DELETE_ORGANIZED_UNIT_CONFIGURATION',
  FETCH_ORGANIZED_UNIT_CONFIGURATION_LIST: 'FETCH_ORGANIZED_UNIT_CONFIGURATION_LIST',
  FETCH_ORGANIZED_UNIT_CONFIGURATION_LIST_SUCCESS: 'FETCH_ORGANIZED_UNIT_CONFIGURATION_LIST_SUCCESS',
  OPEN_ADD_ORGANIZED_UNIT_CONFIGURATION_MODAL_SUCCESS: 'OPEN_ADD_ORGANIZED_UNIT_CONFIGURATION_MODAL_SUCCESS',
  OPEN_EDIT_ORGANIZED_UNIT_CONFIGURATION_MODAL_SUCCESS: 'OPEN_EDIT_ORGANIZED_UNIT_CONFIGURATION_MODAL_SUCCESS',
  OPEN_ORGANIZED_UNIT_CONFIGURATION_MODAL: 'OPEN_ORGANIZED_UNIT_CONFIGURATION_MODAL',
  SUBMIT_ORGANIZED_UNIT_CONFIGURATION: 'SUBMIT_ORGANIZED_UNIT_CONFIGURATION'
}
