/* eslint-disable no-unused-vars */
import React, { PureComponent } from 'react'
import { Row, Button, Modal, Col, Form, Table, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { reduxForm, Field } from 'redux-form'
import InputSelect from '../../commons/form/InputSelect'
import { dossierStageStoredAndCancelled } from '../../../constants/backendIds'
import { dossierSubTypeId } from '../../../constants/dossier/common/dossierSubType'
import InputTreeSelect from '../../commons/form/InputTreeSelect'
class CampaingModalDocumentEdit extends PureComponent {
  constructor () {
    super()
    this.state = {
      ConditionDoc: null,
      error: null
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.document !== null && this.props.document !== prevProps.document) {
      const form = {
        organizedUnitId: ''
      }
      this.props.initialize(form)
      this.setState({ ConditionDoc: this.props.document, error: null })
      if (this.props.document.campaignDetailsId !== undefined) {
        this.props.actions.fetchStagesCombo(dossierSubTypeId.campanya)
      } else {
        this.props.actions.fetchStagesCombo(dossierSubTypeId.campanya_ayuda_especial)
      }
    }
  }

  componentDidMount () {
    this.props.actions.getFunctionsToAddDocumentalCampaign()
  }

  editDocument (values) {
    if (this.validateStage()) {
      this.props.actions.addConditionDocumentalCampaign({ ...this.state.ConditionDoc, addedUos: values.organizedUnitId })
    }
  }

  cleanUoCOmbo () {
    this.props.reset()
  }

  handleVerification (value) {
    this.setState({ ConditionDoc: { ...this.state.ConditionDoc, verificationStageId: value }, error: null })
    if (value > this.state.ConditionDoc.validationStageId) {
      this.setState({ error: this.props.t('MASTERS.CAMPAIGN_DETAILS_MASTERS.MODAL_DOCUMENTS_EDIT.VALIDATE_ERROR') })
    }
  }

  handleValidation (value) {
    this.setState({ ConditionDoc: { ...this.state.ConditionDoc, validationStageId: value }, error: null })
    if (value < this.state.ConditionDoc.verificationStageId) {
      this.setState({ error: this.props.t('MASTERS.CAMPAIGN_DETAILS_MASTERS.MODAL_DOCUMENTS_EDIT.VALIDATE_ERROR') })
    }
  }

  handleFunctionToAdd (value) {
    this.setState({ ConditionDoc: { ...this.state.ConditionDoc, functionToAddId: value }, error: null })
  }

  handleBlur () {
  }

  validateStage () {
    if (this.state.ConditionDoc.verificationStageId > this.state.ConditionDoc.validationStageId) {
      this.setState({ error: this.props.t('MASTERS.CAMPAIGN_DETAILS_MASTERS.MODAL_DOCUMENTS_EDIT.VALIDATE_ERROR') })
      return false
    }
    this.setState({ error: null })
    return true
  }

  render () {
    const {
      t,
      showModalDocumentsEdit,
      combos,
      document,
      handleSubmit,
      combos: { UOTreeCombo },
      actions: { deleteUoConditionDocCampaign }
    } = this.props
    const tKey = 'MASTERS.CAMPAIGN_DETAILS_MASTERS.MODAL_DOCUMENTS_EDIT.'
    const deleteTooltip = (<Tooltip id="deleteTooltip">{t('MASTERS.DELETE')}</Tooltip>)
    // Don't show canceled and archived stages.
    let stagesCombo = []
    if (combos.stagesCombo) {
      stagesCombo = combos.stagesCombo.filter((val) => {
        return dossierStageStoredAndCancelled.indexOf(val.id) === -1
      })
    }

    let functionCombos = []
    functionCombos.push({ functionToAddId: -1, description: t(`${tKey}NO_CONDITION`), name: t(`${tKey}NO_CONDITION`) })
    functionCombos = functionCombos.concat(this.props.combos.functionsToAdd)

    let title = ''
    if (this.state.ConditionDoc !== null) {
      title = this.state.ConditionDoc.name

      return (

        <Modal className="role-modal modal-padding languages-modal" show={showModalDocumentsEdit} onHide={this.props.actions.closeEditDocumentalConditionModalEditSuccess}>
          <Form autoComplete="off" onSubmit={handleSubmit(this.editDocument.bind(this))}>
            <Modal.Header closeButton onHide={this.props.actions.closeEditDocumentalConditionModalEditSuccess}>
              <Modal.Title>{t(`${tKey}TITLE`) + ': ' + title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row className="inputs-wrapper">
                <Col sm={3} className={'col-title'}>
                  {t(`${tKey}INCORPORATION_STAGE`)} :
                </Col>
                <Col sm={3}>
                  <Field
                    id="verificationStageId"
                    name="verificationStageId"
                    placeholder={t(`${tKey}INCORPORATION_STAGE`)}
                    valueKey="id"
                    labelKey="value"
                    colSm={0}
                    component={InputSelect}
                    options={stagesCombo}
                    input = {{ value: this.state.ConditionDoc.verificationStageId, onChange: (value) => this.handleVerification(value), onBlur: (value) => this.handleBlur(value) }}
                    isClearable = {false}
                  />
                </Col>
                <Col sm={3} className={'col-title'}>
                  {t(`${tKey}VALIDATION_STAGE`)} :
                </Col>
                <Col sm={3}>
                  <Field
                    id="validationStageId"
                    name="validationStageId"
                    placeholder={t(`${tKey}VALIDATION_STAGE`)}
                    valueKey="id"
                    labelKey="value"
                    colSm={0}
                    component={InputSelect}
                    options={stagesCombo}
                    input = {{ value: this.state.ConditionDoc.validationStageId, onChange: (value) => this.handleValidation(value), onBlur: (value) => this.handleBlur(value) }}
                    isClearable = {false}
                  />
                </Col>
              </Row>
              <Row className="inputs-wrapper">
                <Col sm={4} className={'col-title'}>
                  {t(`${tKey}CONDITION`)} :
                </Col>
                <Col sm={8}>
                  <Field
                    id="functionToAddId"
                    name="functionToAddId"
                    placeholder={t(`${tKey}CONDITION`)}
                    valueKey="functionToAddId"
                    labelKey="name"
                    colSm={0}
                    component={InputSelect}
                    options={functionCombos}
                    input = {{ value: this.state.ConditionDoc.functionToAddId !== undefined ? this.state.ConditionDoc.functionToAddId : null, onChange: (value) => this.handleFunctionToAdd(value), onBlur: (value) => this.handleBlur(value) }}
                    isClearable = {false}
                  />
                </Col>
              </Row>
              <Row className="inputs-wrapper">
                <Table sm={10} className="editable-table" hover responsive>
                  <thead>
                    <tr>
                      <th onClick={() => { this.changeOrder('name') }}>
                        {t(`${tKey}UO_NAME`)}
                      </th>
                      <th className='width177'>{t('DOSSIER_COMPONENTS.ACCESSORIES.ACTIONS')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {document?.uoList && document?.uoList.length > 0 && document?.uoList.map((uo, idx) => {
                      return (
                        <tr key={idx} >
                          <td>{uo.name}</td>
                          <td>
                            <div>
                              <a id="deleteUoConditionDocCampaign" onClick={() => deleteUoConditionDocCampaign(uo.organizedUnitId, document)}>
                                <OverlayTrigger placement="left" overlay={deleteTooltip}>
                                  <i className="ico-trash"/>
                                </OverlayTrigger>
                              </a>
                            </div>
                          </td>
                        </tr>)
                    })
                    }
                    {(document?.uoList === null || document?.uoList === undefined || document?.uoList.length === 0) &&
                <tr>
                  <td colSpan={4}><h2>{t(`${tKey}RESULTS_NOT_FOUND`)}</h2></td>
                </tr>}
                  </tbody>
                </Table>
              </Row>
              <Row className="inputs-wrapper">
                <Col sm={9} className="custom-width-col">
                  <h2>{t(`${tKey}ORGANIZED_UNIT`)}</h2>
                  <Field
                    name="organizedUnitId"
                    placeholder={t(`${tKey}ORGANIZED_UNIT`)}
                    component={InputTreeSelect}
                    multi={true}
                    valueKey="value"
                    labelKey="label"
                    childrenKey="children"
                    colSm={0}
                    options={UOTreeCombo}
                    id="organizedUnitId"
                  />
                </Col>

                <Col sm={3} className="custom-width-col">
                  <a id="cleanUoCOmbo" className="a-trash" onClick={() => this.cleanUoCOmbo()}>
                    <OverlayTrigger placement="left" overlay={deleteTooltip}>
                      <i className="ico-trash trash-ico-uo"/>
                    </OverlayTrigger>
                  </a>
                </Col>
              </Row>
              {this.state.error &&
               <Row className="inputs-wrapper">
                 <Col sm={12} className={'col-error'}>
                   {this.state.error}
                 </Col>
               </Row>
              }
            </Modal.Body>
            <Modal.Footer>
              <Col sm={12} className="btn-wrapper">
                <Button id="btn_save" className="btn-standard" type="submit">{t('GLOBAL.BUTTONS.SAVE')}</Button>
              </Col>
            </Modal.Footer>
          </Form>
        </Modal>
      )
    } else { return null }
  }
}

export default reduxForm({
  form: 'document'
})(CampaingModalDocumentEdit)
