import React, { PureComponent } from 'react'
import { Field } from 'redux-form'
import { Panel, Row, Col } from 'react-bootstrap'
import InputSelect from '../../../../_v2/components/commons/form/InputSelect'
import InputDatePicker from '../../../../_v2/components/commons/form/InputDatePicker'
import { permissions } from '../../../../constants/backendIds'
import DocumentaryCheckListPopover from '../../../../components/dossiers/registrationDelivery/salesComponent/DocumentaryCheckListPopover'
import { validateDate, validateLicensePlate as validateLicensePlateFunciton } from '../../../../util/validationFunctions'
import { getFieldsPermisions } from '../../../../util/permisionFunctions'
import { compareObjects } from '../../../../util/utils'
import CustomFields from '../../../../components/commons/customFields/CustomFields'
import ComponentTitle from '../../../containers/dossiers/commons/ComponentTitlePage'

class SalesComponent extends PureComponent {
  constructor () {
    super()
    this.state = {
      operationTypeIdAndAdditionalInfoDisabled: true,
      fieldsConfiguration: {
        salesmanId: null,
        salesmanIdLock: null,
        salesmanIdManager: null,
        saleTypeId: null,
        operationTypeIds: null,
        expectedDeliveryDate: null,
        orderDate: null,
        paymentMethodId: null,
        proposalPercDiscount: null,
        orderPercDiscount: null,
        signalPayNotRequired: null,
        additionalInfoIds: null,
        rentalOrderNumber: null,
        deliveryDestination: null,
        arriveVehicle: null,
        dossierWithDeposit: null,
        despositDeliveryDate: null,
        despositEntityId: null,
        depositLicensePlate: null,
        depositKm: null,
        vehicleTypeId: null,
        productionWeek: null,
        monthsGuaranteeExtension: null,
        LOPDNotRequired: null,
        homologationRequired: null,
        homologationClassificationId: null,
        homologationProducerId: null,
        notMultipleInvoices: null,
        authSaleDepartamentNotRequired: null,
        serviceDestinationId: null,
        assignmentSheet: null,
        warrantyExtensionNotRequired: null,
        transformation: null,
        accesories: null,
        firstYearInsurance: null,
        multipleChoice: null,
        billingDate: null,
        billingNumber: null
      },
      depositVehicleDisabled: false,
      homologationRequired: false,
      homologationClassificationId: null
    }
    this.dateValidation = this.dateValidation.bind(this)
    this.validateLicensePlate = this.validateLicensePlate.bind(this)
  }

  initializePermisions (props) {
    const fieldKeys = Object.keys(this.state.fieldsConfiguration)
    const newState = getFieldsPermisions(props.componentConfiguration.fieldsConfiguration, fieldKeys, props.componentConfiguration.permission)
    this.setState({ fieldsConfiguration: newState.fieldsConfiguration })
  }

  handleSaleTypeChange (saleTypeId) {
    this.props.actions.change('salesComponent.operationTypeIds', [])
    if (saleTypeId) {
      this.props.actions.fetchOperationType(saleTypeId, this.props.dossier.organizedUnitId)
      this.setState({ operationTypeIdAndAdditionalInfoDisabled: false })
    } else {
      this.setState({ operationTypeIdAndAdditionalInfoDisabled: true })
    }
  }

  componentDidMount () {
    this.initializePermisions(this.props)
    this.setState({ operationTypeIdAndAdditionalInfoDisabled: !this.props.dossier.salesComponent.saleTypeId, depositVehicleDisabled: this.props.dossier.salesComponent.dossierWithDeposit, homologationRequired: this.props.dossier.salesComponent.homologationRequired, homologationClassificationId: this.props.dossier.salesComponent.homologationClassificationId })
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (this.props.dossier && this.props.dossier.dossierId && !compareObjects(prevProps.dossier.salesComponent || {}, this.props.dossier.salesComponent, true)) {
      this.setState({ operationTypeIdAndAdditionalInfoDisabled: !this.props.dossier.salesComponent.saleTypeId })
    }

    if (this.props.dossier && this.props.dossier.dossierId && !compareObjects(prevProps.componentConfiguration || {}, this.props.componentConfiguration, true)) {
      this.initializePermisions(this.props)
    }

    return prevProps
  }

  componentDidUpdate () {
    // don't remove use for getsnapshot
  }

  dateValidation (value, allValues, props) {
    const result = validateDate(value, props)
    return result === Object(result) ? undefined : result
  }

  validateLicensePlate (value) {
    return validateLicensePlateFunciton(value, this.props.formValues && this.props.formValues.validateLicensePlateCountry || false, this.props.t('GLOBAL.FORMS.INVALID_STRING'))
  }

  render () {
    // TODO paymentSignalStatus logic (callback for change status)
    const {
      t, combos, readOnlyFields, salesOpen, componentConfiguration, customFields,
      dossier: { salesComponent: { salesmanId }, salesDocumentaryCheckList },
      actions: { printDocumentaryChecklist, togglePanel }
    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.SALES.'

    return (
      <div className="dossier-Component">
        <div className="header-panel" onClick={togglePanel}>
          <ComponentTitle
            componentCode="REGISTRATION_DELIVERY"
            componentOpen={salesOpen}
            // extraInfo={'info extra'}
            // extraData={<a className="_Btn accept">texto</a>}
          />
        </div>
        <Panel expanded={salesOpen} onToggle={() => {}} >
          <Panel.Collapse>
            <div className='fieldsContainer'>
              {this.state.fieldsConfiguration.salesmanIdLock && this.state.fieldsConfiguration.salesmanIdLock.permission !== permissions.hidden && (<Field
                component={InputSelect}
                colWidht={33} // 25, 33, 50, 75, 100 default=25
                id="salesmanId"
                name="salesComponent.salesmanId"
                controlLabel={t(`${tKey}SALESMAN`)}
                placeholder={t(`${tKey}SALESMAN`)}
                valueKey="id"
                labelKey="value"
                options={combos.salesmanCombo}
                disabled={this.state.fieldsConfiguration.salesmanIdLock.permission !== permissions.editable || salesmanId}
              />)}
              {this.state.fieldsConfiguration.salesmanIdManager && this.state.fieldsConfiguration.salesmanIdManager.permission !== permissions.hidden && (<Field
                component={InputSelect}
                colWidht={33} // 25, 33, 50, 75, 100 default=25
                id="salesmanIdManager"
                name="salesComponent.salesmanIdManager"
                controlLabel={t(`${tKey}SALESMAN_MANAGER`)}
                placeholder={t(`${tKey}SALESMAN_MANAGER`)}
                valueKey="id"
                labelKey="value"
                options={combos.salesmanComboLogic}
                disabled={readOnlyFields || this.state.fieldsConfiguration.salesmanIdManager.permission !== permissions.editable}
              />)}
              {this.state.fieldsConfiguration.expectedDeliveryDate && this.state.fieldsConfiguration.expectedDeliveryDate.permission !== permissions.hidden && (<Field
                component={InputDatePicker}
                colWidht={33} // 25, 33, 50, 75, 100 default=25
                id="expectedDeliveryDate"
                name="salesComponent.expectedDeliveryDate"
                controlLabel={t(`${tKey}EXPECTED_DELIVERY_DATE`)}
                placeholder={t(`${tKey}EXPECTED_DELIVERY_DATE`)}
                disabled={readOnlyFields || this.state.fieldsConfiguration.expectedDeliveryDate.permission !== permissions.editable}
              />)}
              {this.state.fieldsConfiguration.orderDate && this.state.fieldsConfiguration.orderDate.permission !== permissions.hidden && (<Field
                component={InputDatePicker}
                colWidht={33} // 25, 33, 50, 75, 100 default=25
                id="orderDate"
                name="salesComponent.orderDate"
                controlLabel={t(`${tKey}ORDER_DATE`)}
                disabled={readOnlyFields || this.state.fieldsConfiguration.orderDate.permission !== permissions.editable}
                validate={[this.dateValidation]}
              />)}
            </div>
            <Row>
              <Col sm={2} className="col-row">
                <DocumentaryCheckListPopover t={t} salesDocumentaryCheckList={salesDocumentaryCheckList} printDocumentaryChecklist={printDocumentaryChecklist} componentConfiguration={componentConfiguration} />
              </Col>
            </Row>
            <CustomFields customFields={customFields} readOnlyFields={readOnlyFields} />
          </Panel.Collapse>
        </Panel>
      </div>
    )
  }
}

export default SalesComponent
