import dossierActionTypes from '../../constants/actions/dossier/dossier'

export function fetchCustomFields (dossierSubTypeId) {
  return {
    type: dossierActionTypes.FETCH_CUSTOM_FIELDS,
    dossierSubTypeId
  }
}

export function fetchCustomFieldsSuccess (customFieldsByComponentCode) {
  return {
    type: dossierActionTypes.FETCH_CUSTOM_FIELDS_SUCCESS,
    value: customFieldsByComponentCode
  }
}
