import React, { PureComponent } from 'react'
import { formatMoney } from '../../../../util/utils'

class EconomicPlanTotal extends PureComponent {
  render () {
    const { totalText, totals, costText, hideTotalVta, bold, showCost } = this.props
    return (
      <div className="name-wrapper-total">
        {
          !hideTotalVta && (
            <span className="name-wrapper-totals" >
              {
                bold === true && totals.amount !== 0
                  ? <b>{totalText}: {formatMoney(totals.amount)}</b>
                  : <p style={{ display: 'inline' }}>{totalText}: {formatMoney(totals.amount)}</p>
              }
            </span>
          )
        }
        {
          (showCost ?? true) &&
          <span className="name-wrapper-totals">
            {
              bold === true && totals.cost !== 0
                ? <b>{costText}: {formatMoney(totals.cost)}</b>
                : <p style={{ display: 'inline' }}>{costText}: {formatMoney(totals.cost)}</p>
            }
          </span>
        }

      </div>
    )
  }
}

export default EconomicPlanTotal
