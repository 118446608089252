import deviceActionTypes from '../../constants/actions/idocmobile/deviceInstall'

export function initialState () {
  return {
    deviceInstalls: null,
    maxInstallations: 0
  }
}

function fetchDeviceInstallsSuccess (state, { deviceInstalls }) {
  return {
    ...state,
    deviceInstalls
  }
}

function fetchMaxDeviceInstallsSuccess (state, { maxInstallations }) {
  return {
    ...state,
    maxInstallations
  }
}

function updateDeviceInstallSuccess (state, { device }) {
  let devices = state.deviceInstalls
  for (let d of devices) {
    if (d.idocMobileInstallsId === device.idocMobileInstallsId) {
      d = device
    }
  }
  return {
    ...state,
    deviceInstalls: devices
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case deviceActionTypes.FETCH_DEVICE_INSTALLS_SUCCESS:
      return fetchDeviceInstallsSuccess(state, action)
    case deviceActionTypes.FETCH_MAX_DEVICE_INSTALLS_SUCCESS:
      return fetchMaxDeviceInstallsSuccess(state, action)
    case deviceActionTypes.UPDATE_DEVICE_INSTALL_SUCCESS:
      return updateDeviceInstallSuccess(state, action)
    default:
      return state
  }
}
