import { put, call, select, takeEvery } from 'redux-saga/effects'
import { showLoader, hideLoader } from '../../../actions/common.js'
import mastersActionTypes from '../../../constants/actions/masters/masters'
import { getAuth } from '../../../selectors/access/auth'
import { handleError } from '../../errors/errorManager'
import { yesNoModal } from '../../modalsListeners/yesNoModal'

import { getDocumentTypeUseFilter } from '../../../selectors/masters/documentTypeUse'
import {
  fetchDocumentTypeUseListSuccess, openAddDocumentTypeUseModalSuccess, closeDocumentTypeUseMasterModal
} from '../../../actions/masters/documentTypeUse/documentTypeUse'

import getLanguageListComboService from '../../../services/language/getLanguageList'
import postDocumentTypeUseList from '../../../services/documentTypeUse/postDocumentTypeUseList'
import postDocumentTypeUseService from '../../../services/documentTypeUse/postDocumentTypeUse'
import putDocumentTypeUseService from '../../../services/documentTypeUse/putDocumentTypeUse'
import deleteDocumentTypeUseService from '../../../services/documentTypeUse/deleteDocumentTypeUse'

export function * deleteDocumentTypeUse (documentTypeUseId) {
  try {
    const confirmed = yield call(yesNoModal, 'deleteDocumentTypeUse')
    if (confirmed) {
      yield put(showLoader())
      const auth = yield select(getAuth)
      const filter = yield filter || select(getDocumentTypeUseFilter)
      yield call(deleteDocumentTypeUseService, auth.token, documentTypeUseId)
      yield call(fetchDocumentTypeUseList, filter)
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDeleteDocumentTypeUse () {
  yield takeEvery(mastersActionTypes.DELETE_DOCUMENT_TYPE_USE, deleteDocumentTypeUse)
}

export function * submitDocumentTypeUse ({ documentTypeUse, hasChanges }) {
  try {
    const auth = yield select(getAuth)
    const filter = yield filter || select(getDocumentTypeUseFilter)
    let confirmed
    if (hasChanges) {
      confirmed = yield call(yesNoModal, 'updateDocumentTypeUse')
    } else {
      confirmed = true
    }

    if (confirmed) {
      yield put(showLoader())
      if (!documentTypeUse.documentTypeUseId) {
        yield call(postDocumentTypeUseService, auth.token, documentTypeUse)
      } else {
        yield call(putDocumentTypeUseService, auth.token, documentTypeUse)
      }
      yield put(closeDocumentTypeUseMasterModal())
      yield call(fetchDocumentTypeUseList, filter)
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSubmitDocumentTypeUse () {
  yield takeEvery(mastersActionTypes.SUBMIT_DOCUMENT_TYPE_USE, submitDocumentTypeUse)
}

export function * fetchDocumentTypeUseList ({ filter }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield filter = yield filter || select(getDocumentTypeUseFilter)
    const documentTypeUseList = yield call(postDocumentTypeUseList, auth.token, filter)
    yield put(fetchDocumentTypeUseListSuccess(documentTypeUseList, filter))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchDocumentTypeUseList () {
  yield takeEvery(mastersActionTypes.FETCH_DOCUMENT_TYPE_USE_LIST, fetchDocumentTypeUseList)
}

export function * openDocumentTypeUseModal ({ documentTypeUse }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const languageList = yield call(getLanguageListComboService, auth.token)
    yield put(openAddDocumentTypeUseModalSuccess(languageList, documentTypeUse))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchOpenDocumentTypeUseModal () {
  yield takeEvery(mastersActionTypes.OPEN_DOCUMENT_TYPE_USE_MODAL, openDocumentTypeUseModal)
}
