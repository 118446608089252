import React, { PureComponent } from 'react'
import { reduxForm, Field } from 'redux-form'
import { Row, Col, Table, Button, Clearfix } from 'react-bootstrap'
import IDocCarPagination from '../../commons/iDocCarPagination'
import InputText from '../../commons/form/InputText'
import InputDatePicker from '../../commons/form/InputDatePicker'
import InputSelect from '../../commons/form/InputSelect'
import moment from 'moment'
import { formatMoney } from '../../../util/utils'

class SaleReport extends PureComponent {
  constructor () {
    super()
    this.state = {
      saleVehicleTypeCombo: [{ id: 1, value: 'Vehiculo Nuevo' }, { id: 2, value: 'Vehiculo Ocasión' }],
      filter: {
        searchSociety: null,
        searchBrand: null,
        searchModel: null,
        searchFinancial: null,
        searchDateDeliverInitial: null,
        searchDateDeliverEnd: null,
        searchTypeVehicle: 0
      }
    }
  }

  searchSale () {
    this.props.actions.fetchSaleList({ ...this.props.filter, ...this.state.filter, page: 1 })
  }

  downloadSale () {
    this.props.actions.downloadSaleList({ ...this.props.filter, ...this.state.filter })
  }

  componentDidMount () {
    this.props.actions.fetchBrands()
    this.props.actions.fetchFinancingCompanies()
    this.searchSale()
  }

  handleSocietyChange (searchSociety) {
    if (this.state.filter.searchSociety !== searchSociety) {
      this.setState({ filter: { ...this.state.filter, searchSociety: searchSociety } })
    }
  }

  handleBrandChange (searchBrand) {
    if (this.state.filter.searchBrand !== searchBrand) {
      this.setState({ filter: { ...this.state.filter, searchBrand: searchBrand } })
    }
  }

  handleModelChange (searchModel) {
    if (this.state.filter.searchModel !== searchModel) {
      this.setState({ filter: { ...this.state.filter, searchModel: searchModel } })
    }
  }

  handleFinancialChange (searchFinancial) {
    if (this.state.filter.searchFinancial !== searchFinancial) {
      this.setState({ filter: { ...this.state.filter, searchFinancial: searchFinancial } })
    }
  }

  handleDateDeliverInitialChange (searchDateDeliverInitial) {
    if (this.state.filter.searchDateDeliverInitial !== searchDateDeliverInitial && searchDateDeliverInitial !== null) {
      const [dd, mm, yyyy] = searchDateDeliverInitial.split('/')
      this.setState({ filter: { ...this.state.filter, searchDateDeliverInitial: new Date(`${yyyy}-${mm}-${dd}T00:00:01.000Z`) } })
    } else {
      this.setState({ filter: { ...this.state.filter, searchDateDeliverInitial: null } })
    }
  }

  handleDateDeliverEndChange (searchDateDeliverEnd) {
    if (this.state.filter.searchDateDeliverEnd !== searchDateDeliverEnd && searchDateDeliverEnd != null) {
      const [dd, mm, yyyy] = searchDateDeliverEnd.split('/')
      this.setState({ filter: { ...this.state.filter, searchDateDeliverEnd: new Date(`${yyyy}-${mm}-${dd}T23:59:59.000Z`) } })
    } else {
      this.setState({ filter: { ...this.state.filter, searchDateDeliverEnd: null } })
    }
  }

  handleTypeVehicleChange (searchTypeVehicle) {
    if (this.state.filter.searchTypeVehicle !== searchTypeVehicle) {
      this.setState({ filter: { ...this.state.filter, searchTypeVehicle: searchTypeVehicle } })
    }
  }

  render () {
    const {
      t, saleList, pagesCount, filter, saleCount,
      filter: { page },
      actions: { fetchSaleList },
      combos: { brandCombo, lowestOrganizedUnitsCombo, companiesCombo }
    } = this.props
    const tKey = 'REPORTS.'
    return (
      <div className="admin-wrapper">
        <Row className="section-users-header">
          <Col sm={12}>
            <div className="header-panel">
              <h4>{t(`${tKey}SALE.SALE_TITLE`)}</h4>
            </div>
          </Col>
        </Row>
        <Row>
          <div className="search-form">
            <span className="title-search"><i className="ico-search" /> {t(`${tKey}SALE.SEARCH_TITLE`)}</span>
            <Row>
              <Field
                colSm={3}
                id="society"
                name="filters.society"
                controlLabel={t(`${tKey}SALE.SOCIETY`)}
                placeholder={t(`${tKey}SALE.SOCIETY`)}
                component={InputSelect}
                options={lowestOrganizedUnitsCombo}
                valueKey="id"
                labelKey="value"
                onInputChange={(value) => this.handleSocietyChange(value)}
              />
              <Field
                colSm={3}
                id="vehicleBrand"
                name="filters.vehicleBrand"
                controlLabel={t(`${tKey}SALE.BRAND`)}
                placeholder={t(`${tKey}SALE.BRAND`)}
                component={InputSelect}
                valueKey="id"
                labelKey="value"
                options={brandCombo}
                onInputChange={(value) => this.handleBrandChange(value)}
              />
              <Field
                id="model"
                name="filters.model"
                colSm={3}
                controlLabel={t(`${tKey}SALE.MODEL`)}
                placeholder={t(`${tKey}SALE.MODEL`)}
                component={InputText}
                onInputChange={(value) => this.handleModelChange(value)}
              />
              <Field
                colSm={3}
                id="finantial"
                name='filters.finantial'
                placeholder={t(`${tKey}SALE.FINANTIAL`)}
                controlLabel={t(`${tKey}SALE.FINANTIAL`)}
                component={InputSelect}
                valueKey="id"
                labelKey="value"
                options={companiesCombo}
                onInputChange={(value) => this.handleFinancialChange(value)}
              />
              <Clearfix/>
              <Field
                id="initialdate"
                name="filters.initialdate"
                colSm={3}
                controlLabel={t(`${tKey}SALE.INITIAL_ORDER_DATE`)}
                placeholder={t(`${tKey}SALE.INITIAL_ORDER_DATE`)}
                component={InputDatePicker}
                onInputChange={(value) => this.handleDateDeliverInitialChange(value)}
              />
              <Field
                id="enddate"
                name="filters.enddate"
                colSm={3}
                controlLabel={t(`${tKey}SALE.END_ORDER_DATE`)}
                placeholder={t(`${tKey}SALE.END_ORDER_DATE`)}
                component={InputDatePicker}
                onInputChange={(value) => this.handleDateDeliverEndChange(value)}
              />
              <Field
                name="filters.vechicle"
                colSm={3}
                controlLabel={t(`${tKey}SALE.TYPE_VEHICLE`)}
                placeholder={t(`${tKey}SALE.TYPE_VEHICLE`)}
                component={InputSelect}
                multi={false}
                valueKey="id"
                labelKey="value"
                childrenKey="children"
                options={this.state.saleVehicleTypeCombo}
                onInputChange={(value) => this.handleTypeVehicleChange(value)}
              />
              <Col sm={3} className="btn-wrapper">
                <Button
                  className={'btn-white btn-icon btn-input btn-white search-button'}
                  style={{ width: '100%' }}
                  onClick={() => this.searchSale()}>
                  <i className="ico-search" />
                  {t(`${tKey}SEARCH`)}
                </Button>
              </Col>
            </Row>
          </div>
        </Row>
        <Col>
          <Button
            className={'btn-white btn-icon btn-input btn-white'}
            style={{ float: 'right' }}
            onClick={() => this.downloadSale()}>
            {t(`${tKey}DOWNLOAD`)}
          </Button>
        </Col>
        <Col sm={12} className="col-row  section-users-body">
          <Table className="editable-table admin-users" hover responsive>
            <thead>
              <tr>
                <th>
                  {t(`${tKey}SALE.DOSSIER_SALE`)}&nbsp;
                </th>
                <th>
                  {t(`${tKey}SALE.DOSSIER_BUY`)}&nbsp;
                </th>
                <th>
                  {t(`${tKey}SALE.SOCIETY`)}&nbsp;
                </th>
                <th>
                  {t(`${tKey}SALE.DATE`)}&nbsp;
                </th>
                <th>
                  {t(`${tKey}SALE.DELIVER_DATE`)}&nbsp;
                </th>
                <th>
                  {t(`${tKey}SALE.BRAND`)}&nbsp;
                </th>
                <th>
                  {t(`${tKey}SALE.MODEL`)}&nbsp;
                </th>
                <th>
                  {t(`${tKey}SALE.VINCHASSIS`)}&nbsp;
                </th>
                <th>
                  {t(`${tKey}SALE.LICENSEPLATE`)}&nbsp;
                </th>
                <th>
                  {t(`${tKey}SALE.BUY_DATE`)}&nbsp;
                </th>
                <th>
                  {t(`${tKey}SALE.ARRIVAL_DATE`)}&nbsp;
                </th>
                <th>
                  {t(`${tKey}SALE.INITIAL_DELIVER_DATE`)}&nbsp;
                </th>
                <th>
                  {t(`${tKey}SALE.PURCHASE_AMOUNT`)}&nbsp;
                </th>
                <th>
                  {t(`${tKey}SALE.SALES_AMOUNT`)}&nbsp;
                </th>
                <th>
                  {t(`${tKey}SALE.FINANTIAL`)}&nbsp;
                </th>
                <th>
                  {t(`${tKey}SALE.FINANTIAL_AMOUNT`)}&nbsp;
                </th>
                <th>
                  {t(`${tKey}SALE.TYPE_FINANTIAL`)}&nbsp;
                </th>
                <th>
                  {t(`${tKey}SALE.MONTH`)}&nbsp;
                </th>
                <th>
                  {t(`${tKey}SALE.LICENSEPLATE_VO`)}&nbsp;
                </th>
                <th>
                  {t(`${tKey}SALE.BRAND_VO`)}&nbsp;
                </th>
                <th>
                  {t(`${tKey}SALE.MODEL_VO`)}&nbsp;
                </th>
                <th>
                  {t(`${tKey}SALE.PURCHASE_AMOUNT_VO`)}&nbsp;
                </th>
                <th>
                  {t(`${tKey}SALE.TYPE_VEHICLE`)}&nbsp;
                </th>
              </tr>
            </thead>
            <tbody>
              {saleList && saleList.map((sale, idx) => {
                return (
                  <tr key={idx} className = "">
                    <td>{sale.dossierNumber}</td>
                    <td>{sale.parentDossierNumber}</td>
                    <td>{sale.organizedUnitName}</td>
                    <td>{moment(sale.creationDate).format('DD/MM/YYYY')}</td>
                    <td>{moment(sale.deliveryDate).format('DD/MM/YYYY')}</td>
                    <td>{sale.brandName}</td>
                    <td>{sale.model}</td>
                    <td>{sale.vinChassis}</td>
                    <td>{sale.licensePlate}</td>
                    <td>{moment(sale.billingDate).format('DD/MM/YYYY')}</td>
                    <td>{moment(sale.availabilityDate).format('DD/MM/YYYY')}</td>
                    <td>{moment(sale.realDeliveryDateTime).format('DD/MM/YYYY')}</td>
                    <td>{formatMoney(sale.dossierAmount)}</td>
                    <td>{formatMoney(sale.parentDossierAmount)}</td>
                    <td>{sale.financingCompanyName}</td>
                    <td>{formatMoney(sale.financingAmount)}</td>
                    <td>{sale.financingType != null && sale.financingType.name}</td>
                    <td>{sale.financingInstalments}</td>
                    <td>{sale.purchaseLicensePlate}</td>
                    <td>{sale.purchaseBrandName}</td>
                    <td>{sale.purchaseModel}</td>
                    <td>{formatMoney(sale.parentDossierVOAmount)}</td>
                    <td>{sale.dossierSubTypeId === 1 ? t(`${tKey}SALE.NEW_VEHICLE`) : t(`${tKey}SALE.USED_VEHICLE`)}</td>
                  </tr>)
              })
              }
              {(saleList === null || saleList === undefined || saleList.length === 0) &&
            <tr>
              <td colSpan={23}><h2>{t(`${tKey}RESULTS_NOT_FOUND`)}</h2></td>
            </tr>}
            </tbody>
          </Table>
          <div className="section-users-footer">
            <nav key={1} className="text-center">
              <IDocCarPagination
                id="btn_pag_sales"
                page={page}
                pagesCount={pagesCount}
                totalRows={saleCount}
                onSelectAction={(value) => fetchSaleList({ ...filter, ...this.state.filter, page: value })}
              />
            </nav>
          </div>
        </Col>
      </div>
    )
  }
}

export default reduxForm({
  form: 'listSale'
})(SaleReport)
