import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import DataDeliveryFleetModal from '../../../components/management/AuxModals/DataDeliveryFleetModal'
import { closeDataDeliveryFleetModal } from '../../../actions/management/auxModals/auxModals'
import { fetchTuneUpDestination } from '../../../actions/combos/combos'
import { fetchOperateDataFleetModalsPermissions } from '../../../actions/management/management'
import { setOperateDataDeliveryFleetProcess } from '../../../actions/unattendedProcess/unattendedProcess'
import { saleCodes } from '../../../constants/dossier/common/fieldCodePermissions'
import { openModal } from '../../../actions/common'

export function mapStateToProps (state) {
  return {
    ...state.managementAuxModals.dataDeliveryFleetModal,
    componentConfiguration: state.management.operateFleetModal.componentsModalConfiguration ? state.management.operateFleetModal.componentsModalConfiguration.componentsConfiguration.find(x => x.code === saleCodes.delivery) : null,
    combos: {
      ...state.combos
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      closeDataDeliveryFleetModal,
      fetchOperateDataFleetModalsPermissions,
      fetchTuneUpDestination,
      setOperateDataDeliveryFleetProcess,
      openModal
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(DataDeliveryFleetModal))
