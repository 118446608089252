import React, { PureComponent } from 'react'
import { reduxForm, Field } from 'redux-form'
import { Row, Col, Table, Tooltip, OverlayTrigger } from 'react-bootstrap'
import IDocCarPagination from '../../commons/iDocCarPagination'
import InputSelect from '../../commons/form/InputSelect'
import InputCheckbox from '../../commons/form/InputCheckBox'
import DossierStageModal from '../../../containers/masters/DossierStage/DossierStageModalPage'

class DossierStage extends PureComponent {
  constructor () {
    super()
    this.state = {
      filter: {
        dossierSubTypeId: null
      }
    }
  }

  componentDidMount () {
    this.props.actions.fetchSearchDossierSubType()
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (this.props.filter.dossierSubTypeId !== nextProps.filter.dossierSubTypeId && nextProps.filter.dossierSubTypeId === null) {
      this.props.initialize({})
      this.setState({ filter: { ...nextProps.filter } })
    }
  }

  getSortIcon (column = '') {
    switch (this.props.filter.orderBy) {
      case '+' + column:
        return 'ico-sort-selected-asc'
      case '-' + column:
        return 'ico-sort-selected-desc'
      default:
        return 'ico-sort'
    }
  }

  changeOrder (field) {
    switch (this.props.filter.orderBy) {
      case `+${field}`:
        this.props.actions.fetchDossierStageList({ ...this.state.filter, ...this.props.filter, orderBy: `-${field}` })
        break
      default:
        this.props.actions.fetchDossierStageList({ ...this.state.filter, ...this.props.filter, orderBy: `+${field}` })
        break
    }
  }

  handleDossierSubTypeChange (dossierSubTypeId) {
    if (this.state.filter?.dossierSubTypeId !== dossierSubTypeId) {
      this.setState({ filter: { ...this.state.filter, dossierSubTypeId } }, () => this.searchDossierStages())
    }
  }

  searchDossierStages () {
    this.props.actions.fetchDossierStageList({ ...this.props.filter, ...this.state.filter, page: 1 })
  }

  render () {
    const {
      t, dossierStageList,
      pagesCount, dossierStageCount, filter,
      filter: { page },
      actions: {
        openEditDossierStageModal
      }
    } = this.props

    const tKey = 'MASTERS.'
    const editTooltip = (<Tooltip id="editTooltip">{t(`${tKey}EDIT`)}</Tooltip>)

    return (
      <div className="admin-wrapper">
        <DossierStageModal/>
        <Row className="section-users-header">
          <Col sm={12}>
            <div className="header-panel">
              <h4>{t(`${tKey}DOSSIER_STAGES`)}</h4>
            </div>
          </Col>
        </Row>
        <Row>
          <div className="search-form">
            <Row>
              <Field
                name="filters.dossierSubTypeId"
                colSm={2}
                controlLabel={t(`${tKey}DOSSIER_STAGES_MASTERS.DOSSIER_SUB_TYPE`)}
                placeholder={t(`${tKey}DOSSIER_STAGES_MASTERS.DOSSIER_SUB_TYPE`)}
                component={InputSelect}
                multi={false}
                valueKey="id"
                labelKey="value"
                childrenKey="children"
                options={this.props.combos.searchDossierSubtypesCombo}
                onChange={(value) => this.handleDossierSubTypeChange(value)}
              />
            </Row>
          </div>
        </Row>
        <Col sm={12} className="col-row  section-users-body">
          <Table className="editable-table" hover responsive>
            <thead>
              <tr>
                <th onClick={() => { this.changeOrder('name') }}>
                  {t(`${tKey}DOSSIER_STAGES_MASTERS.NAME`)}
                  <i className={this.getSortIcon('name')} />
                </th>
                <th onClick={() => { this.changeOrder('MaxDays') }}>
                  {t(`${tKey}DOSSIER_STAGES_MASTERS.MAX_DAYS`)}
                  <i className={this.getSortIcon('MaxDays')} />
                </th>
                <th onClick={() => { this.changeOrder('PercentToWarn') }}>
                  {t(`${tKey}DOSSIER_STAGES_MASTERS.WARNING_PERCENTAGE`)}
                  <i className={this.getSortIcon('PercentToWarn')} />
                </th>
                <th onClick={() => { this.changeOrder('PermissionToNotification') }}>
                  {t(`${tKey}DOSSIER_STAGES_MASTERS.NOTIFY_PERMISSION`)}
                  <i className={this.getSortIcon('PermissionToNotification')} />
                </th>
                <th onClick={() => { this.changeOrder('PermissionResponsable') }}>
                  {t(`${tKey}DOSSIER_STAGES_MASTERS.MANAGER_PERMISSION`)}
                  <i className={this.getSortIcon('PermissionResponsable')} />
                </th>
                <th onClick={() => { this.changeOrder('VisibleToProcessingAgency') }}>
                  {t(`${tKey}DOSSIER_STAGES_MASTERS.VISIBLE_PROCESSING_AGENCY`)}
                  <i className={this.getSortIcon('VisibleToProcessingAgency')} />
                </th>
                <th style={{ width: '77px' }}>{t('DOSSIER_COMPONENTS.ACCESSORIES.ACTIONS')}</th>
              </tr>
            </thead>
            <tbody>
              {dossierStageList && dossierStageList.map((dossierStage, idx) => (
                <tr key={idx} className={dossierStage.isDeleted ? 'is-deleted' : ''}>
                  <td>{dossierStage.name}</td>
                  <td>{dossierStage.maxDays}</td>
                  <td>{dossierStage.percentToWarn}</td>
                  <td>{dossierStage.permissionToNotification}</td>
                  <td>{dossierStage.permissionResponsable}</td>
                  <td>{
                    <Field
                      component={InputCheckbox}
                      name={'selectedCheck_' + dossierStage.dossierStageId}
                      checked = {dossierStage.visibleToProcessingAgency}
                      disabled= {true}
                      id={'selectedCheck_' + dossierStage.dossierStageId}
                    />}
                  </td>
                  <td>
                    <div>
                      <a onClick={() => openEditDossierStageModal(dossierStage.dossierStageId)}>
                        <OverlayTrigger placement="left" overlay={editTooltip}>
                          <i className="ico-edit-white" />
                        </OverlayTrigger>
                      </a>
                    </div>
                  </td>
                </tr>
              ))
              }
              {(dossierStageList === null || dossierStageList === undefined || dossierStageList.length === 0) &&
                <tr>
                  <td colSpan={7}><h2>{t(`${tKey}DOSSIER_STAGES_MASTERS.RESULTS_NOT_FOUND`)}</h2></td>
                </tr>}
            </tbody>
          </Table>
          <div className="section-users-footer">
            <nav key={1} className="text-center">
              <IDocCarPagination
                id="btn_pag_sales"
                page={page}
                pagesCount={pagesCount}
                totalRows={dossierStageCount}
                onSelectAction={(value) =>
                  this.props.actions.fetchDossierStageList({ ...filter, ...this.state.filter, page: value })
                }
              />
            </nav>
          </div>
        </Col>
      </div>
    )
  }
}

export default reduxForm({
  form: 'listDossierStage'
})(DossierStage)
