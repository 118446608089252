import React, { PureComponent } from 'react'
import { Row } from 'react-bootstrap'
import { Field } from 'redux-form'
import InputCheckBox from '../../../commons/form/InputCheckBox'
import InputSelect from '../../../commons/form/InputSelect'
import InputText from '../../../commons/form/InputText'
import InputDatePicker from '../../../commons/form/InputDatePicker'
import { entityTypesId, permissions } from '../../../../constants/backendIds'
import { dossierTypes as dossierTypesConstant } from '../../../../constants/dossier/common/dossierTypes'
import NormalizedAddress from '../../../dossiers/common/client/NormalizedAddress'

class ClientData extends PureComponent {
  constructor (props) {
    super()
  }

  componentWillMount () {
    this.loadCombos()
  }

  loadCombos () {
    if (this.validateSchema()) {
      this.props.actions.fetchActivityBpro()
    }
  }

  validateSchema () {
    const {
      fieldsConfiguration
    } = this.props
    return (
      (fieldsConfiguration.startStreet && fieldsConfiguration.startStreet.permission !== permissions.hidden) ||
      (fieldsConfiguration.enndStreet && fieldsConfiguration.enndStreet.permission !== permissions.hidden) ||
      (fieldsConfiguration.externalNumber && fieldsConfiguration.externalNumber.permission !== permissions.hidden) ||
      (fieldsConfiguration.internalNumber && fieldsConfiguration.internalNumber.permission !== permissions.hidden) ||
      (fieldsConfiguration.countryCode && fieldsConfiguration.countryCode.permission !== permissions.hidden) ||
      (fieldsConfiguration.stateCode && fieldsConfiguration.stateCode.permission !== permissions.hidden) ||
      (fieldsConfiguration.cityCode && fieldsConfiguration.cityCode.permission !== permissions.hidden) ||
      (fieldsConfiguration.townCode && fieldsConfiguration.townCode.permission !== permissions.hidden) ||
      (fieldsConfiguration.colonyCode && fieldsConfiguration.colonyCode.permission !== permissions.hidden) ||
      (fieldsConfiguration.cpCode && fieldsConfiguration.cpCode.permission !== permissions.hidden)
    )
  }

  getEntityNameLabel () {
    const tKey = 'DOSSIER_COMPONENTS.ENTITY.'
    switch (this.props.dossier.entityComponent.entityTypeId) {
      case entityTypesId.privateEntity:
        return this.props.t(`${tKey}NAME`)
      default:
        return this.props.t(`${tKey}REGISTERED_NAME`)
    }
  }

  getTitle () {
    const tKey = 'DOSSIER_COMPONENTS.ENTITY.'
    switch (this.props.dossierType) {
      case dossierTypesConstant.sales:
        return this.props.t(`${tKey}CLIENT`)
      case dossierTypesConstant.purchase:
        return this.props.t(`${tKey}VENDOR`)
      default:
        return this.props.t(`${tKey}PANEL_TITLE`)
    }
  }

  getEntityTypeLabel () {
    const tKey = 'DOSSIER_COMPONENTS.ENTITY.'
    switch (this.props.dossierType) {
      case dossierTypesConstant.sales:
        return this.props.t(`${tKey}CLIENT_TYPE`)
      case dossierTypesConstant.purchase:
        return this.props.t(`${tKey}VENDOR_TYPE`)
      default:
        return this.props.t(`${tKey}ENTITY_TYPE`)
    }
  }

  getNumberLabel () {
    const tKey = 'DOSSIER_COMPONENTS.ENTITY.'
    switch (this.props.dossierType) {
      case dossierTypesConstant.sales:
        return this.props.t(`${tKey}MAIN_CLIENT_NUMBER`)
      case dossierTypesConstant.purchase:
        return this.props.t(`${tKey}MAIN_VENDOR_NUMBER`)
      default:
        return this.props.t(`${tKey}MAIN_ENTITY_NUMBER`)
    }
  }

  longSchema () {
    const {
      t,
      fieldsConfiguration,
      combos: {
        entityTypeCombo, channelCombo, segmentationCombo, entitySubTypeCombo, entityFilterSubTypeCombo, iaeTypeCombo,
        CSTCombo, activityBproCombo
      },
      dossier: { entityComponent },
      actions: {
        fetchEntityCountryTypeCombo,
        fetchEntityStateTypeCombo,
        fetchEntityCityTypeCombo,
        fetchEntityTownTypeCombo,
        fetchEntityColonyTypeCombo,
        fetchEntityCPTypeCombo,
        fetchClientDataAddress
      }
    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.ENTITY.'
    return (
      <Row className='flexRow'>
        {fieldsConfiguration.freeLance && fieldsConfiguration.freeLance.permission !== permissions.hidden && entityComponent.entityTypeId === entityTypesId.privateEntity && (

          <Field
            colSm={12}
            id="freeLance"
            name="entityComponent.freeLance"
            component={InputCheckBox}
            placeholder={t(`${tKey}FREELANCE`)}
            disabled={true}
          />

        )}
        {fieldsConfiguration.entityTypeId.permission !==
          permissions.hidden && (
          <Field
            colSm={4}
            id="entityTypeId"
            name="entityComponent.entityTypeId"
            controlLabel={this.getEntityTypeLabel()}
            placeholder={this.getEntityTypeLabel()}
            component={InputSelect}
            options={entityTypeCombo}
            valueKey="id"
            labelKey="value"
            disabled={true}
          />
        )}
        {entityComponent.entityTypeId === entityTypesId.recurrentEntity && [
          fieldsConfiguration.entitySubTypeId.permission !==
            permissions.hidden && (
            <Field
              colSm={4}
              id="entitySubTypeId"
              name="entityComponent.entitySubTypeId"
              key="entityComponent.entitySubTypeId"
              controlLabel={t(`${tKey}TYPE`)}
              placeholder={t(`${tKey}TYPE`)}
              component={InputSelect}
              options={entitySubTypeCombo}
              valueKey="id"
              labelKey="value"
              disabled={true}
            />
          ),
          fieldsConfiguration.entityId.permission !==
            permissions.hidden && (
            <Field
              colSm={4}
              id="entityId"
              name="entityComponent.entityId"
              key="entityComponent.entityId"
              controlLabel={this.getTitle()}
              placeholder={this.getTitle()}
              component={InputSelect}
              options={entityFilterSubTypeCombo}
              valueKey="id"
              labelKey="value"
              disabled={true}
            />
          )
        ]}
        {fieldsConfiguration.dniCif.permission !==
          permissions.hidden && (
          <Field
            id="dniCif"
            name="entityComponent.dniCif"
            colSm={4}
            controlLabel={t(`${tKey}DNI`)}
            placeholder={t(`${tKey}DNI`)}
            component={InputText}
            disabled={true}
          />
        )}
        {entityComponent.entityTypeId !== entityTypesId.recurrentEntity && [
          fieldsConfiguration.clientNumber.permission !==
            permissions.hidden && (
            <Field
              id="clientNumber"
              name="entityComponent.clientNumber"
              key="entityComponent.clientNumber"
              colSm={4}
              controlLabel={this.getNumberLabel()}
              placeholder={this.getNumberLabel()}
              component={InputText}
              disabled={true}
              forceControlLabelInOneLine={true}
            />
          )
        ]}
        {(entityComponent.entityTypeId === entityTypesId.privateEntity ||
          entityComponent.entityTypeId === entityTypesId.businessEntity) &&
          fieldsConfiguration.name.permission !==
            permissions.hidden && (
          <Field
            id="name"
            name="entityComponent.name"
            colSm={4}
            controlLabel={this.getEntityNameLabel()}
            placeholder={this.getEntityNameLabel()}
            component={InputText}
            disabled={true}
          />
        )}
        {entityComponent.entityTypeId === entityTypesId.privateEntity && [
          fieldsConfiguration.surname1.permission !==
            permissions.hidden && (
            <Field
              id="surname1"
              name="entityComponent.surname1"
              key="entityComponent.surname1"
              colSm={4}
              controlLabel={t(`${tKey}SURNAME1`)}
              placeholder={t(`${tKey}SURNAME1`)}
              component={InputText}
              disabled={true}
            />
          ),
          fieldsConfiguration.surname2.permission !==
            permissions.hidden && (
            <Field
              id="surname2"
              name="entityComponent.surname2"
              key="entityComponent.surname2"
              colSm={4}
              controlLabel={t(`${tKey}SURNAME2`)}
              placeholder={t(`${tKey}SURNAME2`)}
              component={InputText}
              disabled={true}
            />
          )
        ]}
        {entityComponent.entityTypeId === entityTypesId.businessEntity && [
          fieldsConfiguration.representativeDni.permission !==
            permissions.hidden && (
            <Field
              id="representativeDni"
              name="entityComponent.representativeDni"
              key="entityComponent.representativeDni"
              colSm={4}
              controlLabel={t(`${tKey}REPRESENTATIVE_DNI`)}
              placeholder={t(`${tKey}REPRESENTATIVE_DNI`)}
              component={InputText}
              disabled={true}
            />
          ),
          fieldsConfiguration.representativeName.permission !==
            permissions.hidden && (
            <Field
              id="representativeName"
              name="entityComponent.representativeName"
              key="entityComponent.representativeName"
              colSm={4}
              controlLabel={t(`${tKey}REPRESENTATIVE_NAME`)}
              placeholder={t(`${tKey}REPRESENTATIVE_NAME`)}
              component={InputText}
              disabled={true}
            />
          ),
          fieldsConfiguration.representativeSurname1.permission !==
            permissions.hidden && (
            <Field
              id="representativeSurname1"
              name="entityComponent.representativeSurname1"
              key="entityComponent.representativeSurname1"
              colSm={4}
              controlLabel={t(`${tKey}REPRESENTATIVE_SURNAME1`)}
              placeholder={t(`${tKey}REPRESENTATIVE_SURNAME1`)}
              component={InputText}
              disabled={true}
              forceControlLabelInOneLine={true}
            />
          ),
          fieldsConfiguration.representativeSurname2.permission !==
            permissions.hidden && (
            <Field
              id="representativeSurname2"
              name="entityComponent.representativeSurname2"
              key="entityComponent.representativeSurname2"
              colSm={4}
              controlLabel={t(`${tKey}REPRESENTATIVE_SURNAME2`)}
              placeholder={t(`${tKey}REPRESENTATIVE_SURNAME2`)}
              component={InputText}
              disabled={true}
              forceControlLabelInOneLine={true}
            />
          )
        ]}
        {entityComponent.entityTypeId !== entityTypesId.recurrentEntity && [
          fieldsConfiguration.phone.permission !==
            permissions.hidden && (
            <Field
              id="phone"
              name="entityComponent.phone"
              key="entityComponent.phone"
              colSm={4}
              controlLabel={t(`${tKey}PHONE`)}
              placeholder={t(`${tKey}PHONE`)}
              component={InputText}
              disabled={true}
            />
          ),
          fieldsConfiguration.mobilePhone.permission !==
            permissions.hidden && (
            <Field
              id="mobilePhone"
              name="entityComponent.mobilePhone"
              key="entityComponent.mobilePhone"
              colSm={4}
              controlLabel={t(`${tKey}MOBILE`)}
              placeholder={t(`${tKey}MOBILE`)}
              component={InputText}
              disabled={true}
            />
          ),
          entityComponent.entityTypeId === entityTypesId.businessEntity && (
            <div key="clearfix_mobilePhone" className="clearfix" />
          ),
          fieldsConfiguration.email.permission !==
            permissions.hidden && (
            <Field
              id="email"
              name="entityComponent.email"
              key="entityComponent.email"
              colSm={4}
              controlLabel={t(`${tKey}EMAIL`)}
              placeholder={t(`${tKey}EMAIL`)}
              component={InputText}
              disabled={true}
            />
          ),
          <NormalizedAddress
            t={t}
            fieldsConfiguration={fieldsConfiguration}
            readOnlyFields={true}
            allInformationDisabled={true}
            entityComponent= {entityComponent}
            entityTypeName= "entityComponent"
            actions={{
              fetchEntityCountryTypeCombo,
              fetchEntityStateTypeCombo,
              fetchEntityCityTypeCombo,
              fetchEntityTownTypeCombo,
              fetchEntityColonyTypeCombo,
              fetchEntityCPTypeCombo,
              fetchClientDataAddress
            }}
          />,
          fieldsConfiguration.address && fieldsConfiguration.address.permission !== permissions.hidden && (
            <Field
              id="entityAddress"
              name="entityComponent.address"
              key="entityComponent.address"
              colSm={8}
              controlLabel={t(`${tKey}ADDRESS`)}
              placeholder={t(`${tKey}ADDRESS`)}
              component={InputText}
              disabled={true}
            />
          ),
          fieldsConfiguration.externalNumber && fieldsConfiguration.externalNumber.permission !== permissions.hidden && (
            <Field
              id="externalNumber"
              name="entityComponent.externalNumber"
              key="entityComponent.externalNumber"
              colSm={4}
              controlLabel={t(`${tKey}EXTERNAL_NUMBER`)}
              placeholder={t(`${tKey}EXTERNAL_NUMBER`)}
              component={InputText}
              disabled={true}
              maxLength="20"
            />
          ),
          fieldsConfiguration.startStreet && fieldsConfiguration.startStreet.permission !== permissions.hidden && (
            <Field
              id="startStreet"
              name="entityComponent.startStreet"
              key="entityComponent.startStreet"
              colSm={8}
              controlLabel={t(`${tKey}START_STREET`)}
              placeholder={t(`${tKey}START_STREET`)}
              component={InputText}
              disabled={true}
              maxLength="200"
            />
          ),
          fieldsConfiguration.internalNumber && fieldsConfiguration.internalNumber.permission !== permissions.hidden && (
            <Field
              id="internalNumber"
              name="entityComponent.internalNumber"
              key="entityComponent.internalNumber"
              colSm={4}
              controlLabel={t(`${tKey}INTERNAL_NUMBER`)}
              placeholder={t(`${tKey}INTERNAL_NUMBER`)}
              component={InputText}
              disabled={true}
              maxLength="20"
            />
          ),
          fieldsConfiguration.enndStreet && fieldsConfiguration.enndStreet.permission !== permissions.hidden && (
            <Field
              id="enndStreet"
              name="entityComponent.enndStreet"
              key="entityComponent.enndStreet"
              colSm={8}
              controlLabel={t(`${tKey}ENND_STREET`)}
              placeholder={t(`${tKey}ENND_STREET`)}
              component={InputText}
              disabled={true}
              maxLength="200"
            />
          ),
          fieldsConfiguration.sourceChannelId.permission !==
            permissions.hidden && (
            <Field
              colSm={4}
              id="sourceChannelId"
              name="entityComponent.sourceChannelId"
              key="entityComponent.sourceChannelId"
              controlLabel={t(`${tKey}SOURCE_CHANNEL`)}
              placeholder={t(`${tKey}SOURCE_CHANNEL`)}
              component={InputSelect}
              options={channelCombo}
              valueKey="id"
              labelKey="value"
              disabled={true}
              customClass="hidden"
            />
          ),
          fieldsConfiguration.segmentationId.permission !==
            permissions.hidden && (
            <Field
              colSm={4}
              id="segmentation"
              name="entityComponent.segmentationId"
              key="entityComponent.segmentationId"
              controlLabel={t(`${tKey}SEGMENTATION`)}
              placeholder={t(`${tKey}SEGMENTATION`)}
              component={InputSelect}
              options={segmentationCombo}
              valueKey="id"
              labelKey="value"
              disabled={true}
              customClass="hidden"
            />
          ),
          fieldsConfiguration.iaeTypeId &&
            fieldsConfiguration.iaeTypeId.permission !==
              permissions.hidden &&
            entityComponent.entityTypeId === entityTypesId.businessEntity && (
            <Field
              colSm={4}
              id="iaeTypeId"
              name="entityComponent.iaeTypeId"
              key="entityComponent.iaeTypeId"
              controlLabel={t(`${tKey}IAE_TYPE`)}
              placeholder={t(`${tKey}IAE_TYPE`)}
              component={InputSelect}
              options={iaeTypeCombo}
              valueKey="id"
              labelKey="value"
              disabled={true}
            />
          )
        ]}
        {this.props.dossierType === dossierTypesConstant.paperwork &&
        fieldsConfiguration.differentPaperworkAddress.permission !==
          permissions.hidden ? (
            <Field
              colSm={4}
              id="differentPaperworkAddress"
              name="dossierPaperworkComponent.dossierPaperworkAddressComponent.differentPaperworkAddress"
              component={InputCheckBox}
              placeholder={t(`${tKey}PAPERWORK_ADDRESS_DIFFERENT`)}
              disabled={true}
            />
          ) : null}
        {fieldsConfiguration.rfc &&
          fieldsConfiguration.rfc.permission !==
            permissions.hidden && (
          <Field
            id="rfc"
            name="entityComponent.rfc"
            disabled={true}
            colSm={4}
            controlLabel={t(`${tKey}RFC`)}
            placeholder={t(`${tKey}RFC`)}
            component={InputText}
          />
        )}
        { fieldsConfiguration.commercialSocietyTypeId && fieldsConfiguration.commercialSocietyTypeId.permission !== permissions.hidden &&
                      (entityComponent.entityTypeId === entityTypesId.businessEntity || entityComponent.entityTypeId === entityTypesId.recurrentEntity) && (
          <Field
            colSm={4}
            id="commercialSocietyTypeId"
            name="entityComponent.commercialSocietyTypeId"
            key="entityComponent.commercialSocietyTypeId"
            controlLabel={t('ADMIN.RECURRENT_ENTITIES.COMMERCIAL_SOCIETY')}
            placeholder={t('ADMIN.RECURRENT_ENTITIES.COMMERCIAL_SOCIETY')}
            component={InputSelect}
            valueKey="id"
            labelKey="value"
            options={CSTCombo}
            disabled={true}
          />
        )}
        {fieldsConfiguration.activityBproId && fieldsConfiguration.activityBproId.permission !== permissions.hidden && (
          <Field
            colSm={12}
            id="activityBproId"
            name="entityComponent.activityBproId"
            key="entityComponent.activityBproId"
            controlLabel={t(`${tKey}ACTIVITY_BPRO`)}
            placeholder={t(`${tKey}ACTIVITY_BPRO`)}
            component={InputSelect}
            options={activityBproCombo}
            valueKey="id"
            labelKey="value"
            disabled={true}
          />
        )}
        {fieldsConfiguration.birthdayDate && fieldsConfiguration.birthdayDate.permission !== permissions.hidden && (entityComponent.entityTypeId === entityTypesId.privateEntity || entityComponent.entityTypeId === entityTypesId.recurrentEntity) && (
          <Field
            colSm={4}
            id="birthdayDate"
            name="entityComponent.birthdayDate"
            key="entityComponent.birthdayDate"
            controlLabel={t(`${tKey}BIRTHDAY_DATE`)}
            placeholder={t(`${tKey}BIRTHDAY_DATE`)}
            component={InputDatePicker}
            disabled={true}
          />
        )}
        {fieldsConfiguration.constitutionAct && fieldsConfiguration.constitutionAct.permission !== permissions.hidden && (entityComponent.entityTypeId === entityTypesId.businessEntity || entityComponent.entityTypeId === entityTypesId.recurrentEntity) && (
          <Field
            id="constitutionAct"
            name="entityComponent.constitutionAct"
            key="entityComponent.constitutionAct"
            colSm={8}
            controlLabel={t(`${tKey}CONSTITUTION_ACT`)}
            placeholder={t(`${tKey}CONSTITUTION_ACT`)}
            disabled={true}
            component={InputText}
          />
        )}
        {fieldsConfiguration.constitutionDate && fieldsConfiguration.constitutionDate.permission !== permissions.hidden && (entityComponent.entityTypeId === entityTypesId.businessEntity || entityComponent.entityTypeId === entityTypesId.recurrentEntity) && (
          <Field
            colSm={4}
            id="constitutionDate"
            name="entityComponent.constitutionDate"
            key="entityComponent.constitutionDate"
            controlLabel={t(`${tKey}CONSTITUTION_DATE`)}
            placeholder={t(`${tKey}CONSTITUTION_DATE`)}
            component={InputDatePicker}
            disabled={true}
          />
        )}
      </Row>
    )
  }

  shortSchema () {
    const {
      t,
      fieldsConfiguration,
      isParticularEntityType,
      combos: {
        entityTypeCombo,
        channelCombo,
        maritalStatusCombo,
        segmentationCombo,
        entitySubTypeCombo,
        entityFilterSubTypeCombo,
        iaeTypeCombo,
        CSTCombo, activityBproCombo
      },
      dossier: { entityComponent }
    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.ENTITY.'
    return (
      <Row className='flexRow'>
        {fieldsConfiguration.freeLance && fieldsConfiguration.freeLance.permission !== permissions.hidden && entityComponent.entityTypeId === entityTypesId.privateEntity && (
          <Field
            colSm={12}
            id="freeLance"
            name="entityComponent.freeLance"
            component={InputCheckBox}
            placeholder={t(`${tKey}FREELANCE`)}
            disabled={true}
          />
        )}
        {fieldsConfiguration.entityTypeId.permission !==
          permissions.hidden && (
          <Field
            colSm={4}
            id="entityTypeId"
            name="entityComponent.entityTypeId"
            controlLabel={this.getEntityTypeLabel()}
            placeholder={this.getEntityTypeLabel()}
            component={InputSelect}
            options={entityTypeCombo}
            valueKey="id"
            labelKey="value"
            disabled={true}
          />
        )}
        {entityComponent.entityTypeId === entityTypesId.recurrentEntity && [
          fieldsConfiguration.entitySubTypeId.permission !==
            permissions.hidden && (
            <Field
              colSm={4}
              id="entitySubTypeId"
              name="entityComponent.entitySubTypeId"
              key="entityComponent.entitySubTypeId"
              controlLabel={t(`${tKey}TYPE`)}
              placeholder={t(`${tKey}TYPE`)}
              component={InputSelect}
              options={entitySubTypeCombo}
              valueKey="id"
              labelKey="value"
              disabled={true}
            />
          ),
          fieldsConfiguration.entityId.permission !==
            permissions.hidden && (
            <Field
              colSm={4}
              id="entityId"
              name="entityComponent.entityId"
              key="entityComponent.entityId"
              controlLabel={this.getTitle()}
              placeholder={this.getTitle()}
              component={InputSelect}
              options={entityFilterSubTypeCombo}
              valueKey="id"
              labelKey="value"
              disabled={true}
            />
          )
        ]}
        {fieldsConfiguration.dniCif.permission !==
          permissions.hidden && (
          <Field
            id="dniCif"
            name="entityComponent.dniCif"
            colSm={4}
            controlLabel={t(`${tKey}DNI`)}
            placeholder={t(`${tKey}DNI`)}
            component={InputText}
            disabled={true}
          />
        )}
        { fieldsConfiguration.commercialSocietyTypeId && fieldsConfiguration.commercialSocietyTypeId.permission !== permissions.hidden &&
                      (entityComponent.entityTypeId === entityTypesId.businessEntity || entityComponent.entityTypeId === entityTypesId.recurrentEntity) && (
          <Field
            colSm={4}
            id="commercialSocietyTypeId"
            name="entityComponent.commercialSocietyTypeId"
            key="entityComponent.commercialSocietyTypeId"
            controlLabel={t('ADMIN.RECURRENT_ENTITIES.COMMERCIAL_SOCIETY')}
            placeholder={t('ADMIN.RECURRENT_ENTITIES.COMMERCIAL_SOCIETY')}
            component={InputSelect}
            valueKey="id"
            labelKey="value"
            options={CSTCombo}
            disabled={true}
          />
        )}
        {entityComponent.entityTypeId !== entityTypesId.recurrentEntity && [
          fieldsConfiguration.clientNumber.permission !==
            permissions.hidden && (
            <Field
              id="clientNumber"
              name="entityComponent.clientNumber"
              key="entityComponent.clientNumber"
              colSm={4}
              controlLabel={this.getNumberLabel()}
              placeholder={this.getNumberLabel()}
              component={InputText}
              disabled={true}
              forceControlLabelInOneLine={true}
            />
          )
        ]}
        {(entityComponent.entityTypeId === entityTypesId.privateEntity ||
          entityComponent.entityTypeId === entityTypesId.businessEntity) &&
          fieldsConfiguration.name.permission !==
            permissions.hidden && (
          <Field
            id="name"
            name="entityComponent.name"
            colSm={4}
            controlLabel={this.getEntityNameLabel()}
            placeholder={this.getEntityNameLabel()}
            component={InputText}
            disabled={true}
          />
        )}
        {entityComponent.entityTypeId === entityTypesId.privateEntity && [
          fieldsConfiguration.surname1.permission !==
            permissions.hidden && (
            <Field
              id="surname1"
              name="entityComponent.surname1"
              key="entityComponent.surname1"
              colSm={4}
              controlLabel={t(`${tKey}SURNAME1`)}
              placeholder={t(`${tKey}SURNAME1`)}
              component={InputText}
              disabled={true}
            />
          ),
          fieldsConfiguration.surname2.permission !==
            permissions.hidden && (
            <Field
              id="surname2"
              name="entityComponent.surname2"
              key="entityComponent.surname2"
              colSm={4}
              controlLabel={t(`${tKey}SURNAME2`)}
              placeholder={t(`${tKey}SURNAME2`)}
              component={InputText}
              disabled={true}
            />
          )
        ]}
        {entityComponent.entityTypeId === entityTypesId.businessEntity && [
          fieldsConfiguration.representativeDni.permission !==
            permissions.hidden && (
            <Field
              id="representativeDni"
              name="entityComponent.representativeDni"
              key="entityComponent.representativeDni"
              colSm={4}
              controlLabel={t(`${tKey}REPRESENTATIVE_DNI`)}
              placeholder={t(`${tKey}REPRESENTATIVE_DNI`)}
              component={InputText}
              disabled={true}
            />
          ),
          fieldsConfiguration.representativeName.permission !==
            permissions.hidden && (
            <Field
              id="representativeName"
              name="entityComponent.representativeName"
              key="entityComponent.representativeName"
              colSm={4}
              controlLabel={t(`${tKey}REPRESENTATIVE_NAME`)}
              placeholder={t(`${tKey}REPRESENTATIVE_NAME`)}
              component={InputText}
              disabled={true}
            />
          ),
          fieldsConfiguration.representativeSurname1.permission !==
            permissions.hidden && (
            <Field
              id="representativeSurname1"
              name="entityComponent.representativeSurname1"
              key="entityComponent.representativeSurname1"
              colSm={4}
              controlLabel={t(`${tKey}REPRESENTATIVE_SURNAME1`)}
              placeholder={t(`${tKey}REPRESENTATIVE_SURNAME1`)}
              component={InputText}
              disabled={true}
              forceControlLabelInOneLine={true}
            />
          ),
          fieldsConfiguration.representativeSurname2.permission !==
            permissions.hidden && (
            <Field
              id="representativeSurname2"
              name="entityComponent.representativeSurname2"
              key="entityComponent.representativeSurname2"
              colSm={4}
              controlLabel={t(`${tKey}REPRESENTATIVE_SURNAME2`)}
              placeholder={t(`${tKey}REPRESENTATIVE_SURNAME2`)}
              component={InputText}
              disabled={true}
              forceControlLabelInOneLine={true}
            />
          )
        ]}
        {
          isParticularEntityType &&
          fieldsConfiguration.maritalcmb && fieldsConfiguration.maritalcmb.permission !== permissions.hidden && (
            <Field
              colSm={4}
              id="maritalStatusId"
              name="entityComponent.maritalStatusId"
              key="entityComponent.maritalStatusId"
              controlLabel={t(`${tKey}MARITAL_STATUS`)}
              placeholder={t(`${tKey}MARITAL_STATUS`)}
              component={InputSelect}
              options={maritalStatusCombo}
              valueKey="id"
              labelKey="value"
              disabled={true}
            />
          )}
        {entityComponent.entityTypeId !== entityTypesId.recurrentEntity && [
          fieldsConfiguration.address.permission !==
            permissions.hidden && (
            <Field
              id="recurrentAddress"
              name="entityComponent.address"
              key="entityComponent.address"
              colSm={4}
              controlLabel={t(`${tKey}ADDRESS`)}
              placeholder={t(`${tKey}ADDRESS`)}
              component={InputText}
              disabled={true}
            />
          ),
          fieldsConfiguration.city.permission !==
            permissions.hidden && (
            <Field
              id="city"
              name="entityComponent.city"
              key="entityComponent.city"
              colSm={4}
              controlLabel={t(`${tKey}CITY`)}
              placeholder={t(`${tKey}CITY`)}
              component={InputText}
              disabled={true}
            />
          ),
          fieldsConfiguration.state.permission !==
            permissions.hidden && (
            <Field
              id="state"
              name="entityComponent.state"
              key="entityComponent.state"
              colSm={4}
              controlLabel={t(`${tKey}STATE`)}
              placeholder={t(`${tKey}STATE`)}
              component={InputText}
              disabled={true}
            />
          ),
          fieldsConfiguration.cp.permission !==
            permissions.hidden && (
            <Field
              id="cp"
              name="entityComponent.cp"
              key="entityComponent.cp"
              colSm={4}
              controlLabel={t(`${tKey}CP`)}
              placeholder={t(`${tKey}CP`)}
              component={InputText}
              disabled={true}
            />
          ),
          fieldsConfiguration.country.permission !==
            permissions.hidden && (
            <Field
              id="country"
              name="entityComponent.country"
              key="entityComponent.country"
              colSm={4}
              controlLabel={t(`${tKey}COUNTRY`)}
              placeholder={t(`${tKey}COUNTRY`)}
              component={InputText}
              disabled={true}
            />
          ),
          fieldsConfiguration.phone.permission !==
            permissions.hidden && (
            <Field
              id="phone"
              name="entityComponent.phone"
              key="entityComponent.phone"
              colSm={4}
              controlLabel={t(`${tKey}PHONE`)}
              placeholder={t(`${tKey}PHONE`)}
              component={InputText}
              disabled={true}
            />
          ),
          fieldsConfiguration.mobilePhone.permission !==
            permissions.hidden && (
            <Field
              id="mobilePhone"
              name="entityComponent.mobilePhone"
              key="entityComponent.mobilePhone"
              colSm={4}
              controlLabel={t(`${tKey}MOBILE`)}
              placeholder={t(`${tKey}MOBILE`)}
              component={InputText}
              disabled={true}
            />
          ),
          entityComponent.entityTypeId === entityTypesId.businessEntity && (
            <div key="clearfix_mobilePhone" className="clearfix" />
          ),
          fieldsConfiguration.email.permission !==
            permissions.hidden && (
            <Field
              id="email"
              name="entityComponent.email"
              key="entityComponent.email"
              colSm={4}
              controlLabel={t(`${tKey}EMAIL`)}
              placeholder={t(`${tKey}EMAIL`)}
              component={InputText}
              disabled={true}
            />
          ),
          fieldsConfiguration.sourceChannelId.permission !==
            permissions.hidden && (
            <Field
              colSm={4}
              id="sourceChannelId"
              name="entityComponent.sourceChannelId"
              key="entityComponent.sourceChannelId"
              controlLabel={t(`${tKey}SOURCE_CHANNEL`)}
              placeholder={t(`${tKey}SOURCE_CHANNEL`)}
              component={InputSelect}
              options={channelCombo}
              valueKey="id"
              labelKey="value"
              disabled={true}
              customClass="hidden"
            />
          ),
          fieldsConfiguration.segmentationId.permission !==
            permissions.hidden && (
            <Field
              colSm={4}
              id="segmentation"
              name="entityComponent.segmentationId"
              key="entityComponent.segmentationId"
              controlLabel={t(`${tKey}SEGMENTATION`)}
              placeholder={t(`${tKey}SEGMENTATION`)}
              component={InputSelect}
              options={segmentationCombo}
              valueKey="id"
              labelKey="value"
              disabled={true}
              customClass="hidden"
            />
          ),
          fieldsConfiguration.iaeTypeId &&
            fieldsConfiguration.iaeTypeId.permission !==
              permissions.hidden &&
            entityComponent.entityTypeId === entityTypesId.businessEntity && (
            <Field
              colSm={4}
              id="iaeTypeId"
              name="entityComponent.iaeTypeId"
              key="entityComponent.iaeTypeId"
              controlLabel={t(`${tKey}IAE_TYPE`)}
              placeholder={t(`${tKey}IAE_TYPE`)}
              component={InputSelect}
              options={iaeTypeCombo}
              valueKey="id"
              labelKey="value"
              disabled={true}
            />
          )
        ]}
        {this.props.dossierType === dossierTypesConstant.paperwork &&
        fieldsConfiguration.differentPaperworkAddress.permission !==
          permissions.hidden ? (
            <Field
              colSm={4}
              id="differentPaperworkAddress"
              name="dossierPaperworkComponent.dossierPaperworkAddressComponent.differentPaperworkAddress"
              component={InputCheckBox}
              placeholder={t(`${tKey}PAPERWORK_ADDRESS_DIFFERENT`)}
              disabled={true}
            />
          ) : null}
        {fieldsConfiguration.rfc &&
          fieldsConfiguration.rfc.permission !==
            permissions.hidden && (
          <Field
            id="rfc"
            name="entityComponent.rfc"
            disabled={true}
            colSm={4}
            controlLabel={t(`${tKey}RFC`)}
            placeholder={t(`${tKey}RFC`)}
            component={InputText}
          />
        )}
        {fieldsConfiguration.activityBproId && fieldsConfiguration.activityBproId.permission !== permissions.hidden && (
          <Field
            colSm={12}
            id="activityBproId"
            name="entityComponent.activityBproId"
            key="entityComponent.activityBproId"
            controlLabel={t(`${tKey}ACTIVITY_BPRO`)}
            placeholder={t(`${tKey}ACTIVITY_BPRO`)}
            component={InputSelect}
            options={activityBproCombo}
            valueKey="id"
            labelKey="value"
            disabled={true}
          />
        )}
        {fieldsConfiguration.birthdayDate && fieldsConfiguration.birthdayDate.permission !== permissions.hidden && (entityComponent.entityTypeId === entityTypesId.privateEntity || entityComponent.entityTypeId === entityTypesId.recurrentEntity) && (
          <Field
            colSm={4}
            id="birthdayDate"
            name="entityComponent.birthdayDate"
            key="entityComponent.birthdayDate"
            controlLabel={t(`${tKey}BIRTHDAY_DATE`)}
            placeholder={t(`${tKey}BIRTHDAY_DATE`)}
            component={InputDatePicker}
            disabled={true}
          />
        )}
        {fieldsConfiguration.constitutionAct && fieldsConfiguration.constitutionAct.permission !== permissions.hidden && (entityComponent.entityTypeId === entityTypesId.businessEntity || entityComponent.entityTypeId === entityTypesId.recurrentEntity) && (
          <Field
            id="constitutionAct"
            name="entityComponent.constitutionAct"
            key="entityComponent.constitutionAct"
            colSm={8}
            controlLabel={t(`${tKey}CONSTITUTION_ACT`)}
            placeholder={t(`${tKey}CONSTITUTION_ACT`)}
            disabled={true}
            component={InputText}
          />
        )}
        {fieldsConfiguration.constitutionDate && fieldsConfiguration.constitutionDate.permission !== permissions.hidden && (entityComponent.entityTypeId === entityTypesId.businessEntity || entityComponent.entityTypeId === entityTypesId.recurrentEntity) && (
          <Field
            colSm={4}
            id="constitutionDate"
            name="entityComponent.constitutionDate"
            key="entityComponent.constitutionDate"
            controlLabel={t(`${tKey}CONSTITUTION_DATE`)}
            placeholder={t(`${tKey}CONSTITUTION_DATE`)}
            component={InputDatePicker}
            disabled={true}
          />
        )}
      </Row>
    )
  }

  render () {
    return (
      <div>
        { !this.validateSchema() ? (
          this.shortSchema()
        ) : (
          this.longSchema()
        ) }
      </div>)
  }
}

export default ClientData
