import { call, put, select, takeLatest } from 'redux-saga/effects'
import { getAuth } from '../../selectors/access/auth'
import getMaritalStatus from '../../services/maritalStatus/getMaritalStatus'
import commonActions from '../../actions/common'
import messageModalActions from '../../actions/modals/messageModal'
import { fetchCombosSuccess } from '../../actions/combos/combos'
import combosActionTypes from '../../constants/actions/combos/combos'
import { handleError } from '../errors/errorManager'

export function * fetchMaritalStatusCombo (data) {
  try {
    yield put(commonActions.showLoader())
    const auth = yield select(getAuth)
    const maritalStatusCombo = yield call(getMaritalStatus, auth.token)
    yield put(fetchCombosSuccess({ maritalStatusCombo: maritalStatusCombo }))
  } catch (error) {
    const customHandler = function * () {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.FETCH_COMBO_ERROR'))
    }
    yield call(handleError, { error, customHandler })
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchFetchMaritalStatusCombo () {
  yield takeLatest(combosActionTypes.FETCH_MARITAL_STATUS_COMBO, fetchMaritalStatusCombo)
}
