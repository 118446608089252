import { bindActionCreators } from 'redux'
import { change } from 'redux-form'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { fetchProcessingManagersByNameOrUo, deleteProcessingManager, recoverProcessingManagerModal, showProcessingManagerModal, setProcessingAgencyManagerFilter } from '../../../actions/masters/processingManager/processingManager'
import ProcessingManagerMaster from '../../../components/masters/processingManager/ProcessingManagerMaster'
import { setChangeTabFromConfMaster } from '../../../actions/masters/masters'

export function mapStateToProps (state) {
  return {
    ...state.processingManager,
    combos: {
      organizedUnitsAllCombo: state.combos.organizedUnitsAllCombo
    }
  }
}
export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchProcessingManagersByNameOrUo,
      deleteProcessingManager,
      recoverProcessingManagerModal,
      showProcessingManagerModal,
      setChangeTabFromConfMaster,
      setProcessingAgencyManagerFilter,
      change
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(ProcessingManagerMaster))
