import React, { PureComponent } from 'react'
import { Col, FormControl, FormGroup, ControlLabel } from 'react-bootstrap'
import { formatNumber } from '../../../../util/utils'

class InputMoney extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      realValue: ('' + props.input.value).trim(),
      showedValue: ('' + props.input.value).trim() !== '' ? formatNumber(props.input.value, '%', props.decimals) : '',
      hasFocus: false
    }
  }

  componentDidMount () {
    this.setState({
      decimals: this.props.decimals ?? 2
    })
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (prevProps.input.value !== this.props.input.value) {
      let isValid = false
      let isCurrency = false
      let realValue = ('' + this.props.input.value).trim()
      let showedValue = ('' + this.props.input.value).trim()
      // Comprobar si el valor introducido es un número con separador de decimales. Este separador puede ser ',' o '.'.
      isValid = this.isValid(realValue)
      if (!isValid) {
        isCurrency = this.isCurrency(realValue)
      }

      if (isValid) {
        // Sanitizar: convertir ',' por '.' y eliminar el exceso de decimales.
        switch (this.props.decimals) {
          case 3:
            realValue = realValue.replace(',', '.').replace(/^(.*?\.\d{3})(\d*)/, '$1')
            break
          default:
            realValue = realValue.replace(',', '.').replace(/^(.*?\.\d{2})(\d*)/, '$1')
            break
        }
        showedValue = !this.state.hasFocus ? formatNumber(realValue, '%', this.props.decimals) : realValue
      } else if (isCurrency) {
        // Sanitizar: eliminar '.', simbolo de moneda y exceso de decimales.
        realValue = realValue.replace(/\./g, '').replace(',', '.').replace('%', '').replace(/^(.*?\.\d{2})(\d*)/, '$1')
        showedValue = !this.state.hasFocus ? formatNumber(realValue, '%', this.props.decimals) : realValue
      } else {
        realValue = this.state.realValue
        showedValue = this.state.showedValue
      }

      this.setState({
        realValue: realValue,
        showedValue: showedValue
      })
    }
    return null
  }

  componentDidUpdate () {
    // getSnapshotBeforeUpdate() should be used with componentDidUpdate()
  }

  isValid (value) {
    const acceptNegativeValue = this.props.acceptNegativeValue
    if (acceptNegativeValue === undefined || acceptNegativeValue) {
      return /^[+-]?[\d]+[,.]?[\d]*$/.test(value)
    } else {
      return /^[+]?[\d]+[,.]?[\d]*$/.test(value)
    }
  }

  isCurrency (value) {
    const acceptNegativeValue = this.props.acceptNegativeValue
    if (acceptNegativeValue === undefined || acceptNegativeValue) {
      return /^[+-]?(\d{1,3}\.(\d{3}\.)*\d{3}|\d*)(,\d+)?%?$/.test(value)
    } else {
      return /^[+]?(\d{1,3}\.(\d{3}\.)*\d{3}|\d*)(,\d+)?%?$/.test(value)
    }
  }

  isMaxPercentageValid (value) {
    return this.props.maxPercentage && !isNaN(this.props.maxPercentage)
      ? value <= this.props.maxPercentage
      : true
  }

  handleChange (event) {
    if ((this.isValid(event.target.value) || this.isCurrency(event.target.value)) && this.isMaxPercentageValid(event.target.value)) {
      this.props.input.onChange(event)
      if (this.props.onInputChange) {
        this.props.onInputChange(event)
      }
    }
  }

  handleFocus (event) {
    const currentTarget = event.currentTarget
    this.setState({
      showedValue: ('' + this.state.realValue).replace(/^(.*?)\.?$/, '$1'), // Si no hay decimales se eliminara el separador.
      hasFocus: true
    }, () => {
      // Timeout requerido en los navegadores Explorer y Edge.
      window.setTimeout(() => {
        currentTarget.select() // Seleccionar el contenido del input.
      }, 50)
    })
  }

  handleBlur (event) {
    const newState = { hasFocus: false }
    let value
    if (!isNaN(this.state.realValue)) {
      // Eliminar los ceros a la izquierda
      switch (this.props.decimals) {
        case 3:
          value = ('' + this.state.realValue).replace(/^([+-]?)(0*)([1-9]\d*|\d)(\.\d{1,3})?$/, '$1$3$4')
          break
        default:
          value = ('' + this.state.realValue).replace(/^([+-]?)(0*)([1-9]\d*|\d)(\.\d{1,2})?$/, '$1$3$4')
          break
      }
      newState.realValue = value
      newState.showedValue = formatNumber(value, '%', this.props.decimals)
    }
    if (this.props.onInputBlur) {
      this.props.onInputBlur(event.target.value)
    }
    this.setState(newState)
  }

  render () {
    const {
      id,
      colWidht = '25',
      colSm = 0,
      meta,
      showError = false,
      rightIcon = '',
      leftIcon = '',
      controlLabel,
      componentClass,
      customClass = '',
      disabled = false,
      textAlign,
      // paddingLeft,
      popOver = null,
      decimals = 2,
      maxLength
    } = this.props
    const errorMessage = ((showError || meta.touched) && meta.error && typeof meta.error !== 'boolean')
      ? meta.error
      : null
    return (
      <Col sm={colSm} className={'colWhidht-' + colWidht + ' ' + customClass} >
        <FormGroup
          className={rightIcon ? 'input-with-icon' : ''}
          validationState={(showError || meta.touched) && meta.error ? 'error' : null}>
          {controlLabel && <ControlLabel> {popOver || controlLabel} </ControlLabel>}
          <FormControl
            style={{ textAlign: textAlign || 'right' }}
            value={this.state.showedValue}
            id={id}
            disabled={disabled}
            type="text"
            placeholder={formatNumber('0.000', '%', decimals)}
            onChange={this.handleChange.bind(this)}
            componentClass={componentClass}
            onFocus={this.handleFocus.bind(this)}
            onBlur={this.handleBlur.bind(this)}
            maxLength={maxLength}
          />
          <i className={rightIcon || leftIcon} />
          {errorMessage && <span className='help-block text-center'>{errorMessage}</span>}
        </FormGroup>
      </Col>
    )
  }
}

export default InputMoney
