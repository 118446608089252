import { call, put, select, takeLatest } from 'redux-saga/effects'
import validationsActionTypes from '../../../constants/actions/dossier/common/validations'
import { showLoader, hideLoader } from '../../../actions/common'
import { getAuth } from '../../../selectors/access/auth'
import postValidation from '../../../services/commons/postValidations'

export function * validate ({ validationTypeCode, paramsToValidate, organizedUnitId, resolve, reject, withoutLoader }) {
  try {
    if (!withoutLoader) yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(postValidation, auth.token, organizedUnitId, validationTypeCode, paramsToValidate)
    yield call(resolve)
    yield put(hideLoader())
  } catch (error) {
    yield call(reject, error)
  } finally {
    if (!withoutLoader) yield put(hideLoader())
  }
}

export function * watchValidate () {
  yield takeLatest(validationsActionTypes.VALIDATE, validate)
}

export function * watchValidateRepresentative () {
  yield takeLatest(validationsActionTypes.VALIDATE_REPRESENTATIVE_DNI, validate)
}

export function * watchValidateDNICIF () {
  yield takeLatest(validationsActionTypes.VALIDATE_DNICIF, validate)
}
