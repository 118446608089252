import documentalSearchActionTypes from '../../constants/actions/search/documentalSearch/documentalSearch'

export function initialState () {
  return {
    selectedTabDocument: 1,
    isSearching: false,
    filters: {
      page: 1,
      pageSize: 10,
      orderBy: null
    },
    dynamicFilters: {
      documentEntityTypeId: null
    },
    documents: [],
    documentsIds: [],
    pages: 1,
    count: 0,
    massiveDowloadItems: []
  }
}

function findFilterDocumentsSuccess (state, { data, filters }) {
  return {
    ...state,
    isSearching: true,
    filters: {
      page: filters.page,
      pageSize: filters.pageSize,
      orderBy: filters.orderBy
    },
    dynamicFilters: filters,
    documents: data.items,
    pages: data.pages,
    count: data.count,
    documentsIds: data.documentIds,
    showPartialRecordsWarning: data.showPartialRecordsWarning
  }
}

function resetFilters (state) {
  return {
    ...state,
    isSearching: false,
    hideFilters: false,
    filters: initialState().filters
  }
}

function setFiltersSuccess (state, { filters }) {
  return {
    ...state,
    isSearching: false,
    hideFilters: true,
    dynamicFilters: filters
  }
}

function searchTabChangeSuccess (state, { selectedTab }) {
  return {
    ...state,
    selectedTabDocument: selectedTab,
    isSearching: false,
    filters: initialState().filters
  }
}

function findFilterMassiveDownloadSuccess (state, { data, filters }) {
  return {
    ...state,
    filters: {
      page: filters.page,
      pageSize: filters.pageSize,
      orderBy: filters.orderBy
    },
    dynamicFilters: filters,
    massiveDowloadItems: data.items,
    pages: data.pages,
    count: data.count
  }
}

function setHideFiltersSuccess (state, { hideFilters }) {
  return {
    ...state,
    hideFilters
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case documentalSearchActionTypes.DOCUMENTAL_SEARCH_TAB_CHANGE:
      return searchTabChangeSuccess(state, action)
    case documentalSearchActionTypes.FIND_FILTER_DOCUMENTS_SUCCESS:
      return findFilterDocumentsSuccess(state, action)
    case documentalSearchActionTypes.RESET_FILTERS_DOCUMENTS:
      return resetFilters(state, action)
    case documentalSearchActionTypes.SET_FILTERS:
      return setFiltersSuccess(state, action)
    case documentalSearchActionTypes.FIND_FILTER_MASSIVE_DOWNLOAD_SUCCESS:
      return findFilterMassiveDownloadSuccess(state, action)
    case documentalSearchActionTypes.SET_HIDE_FILTERS:
      return setHideFiltersSuccess(state, action)
    default:
      return state
  }
}
