import React, { PureComponent } from 'react'
import { Tab, Tabs, Table, Button, Col, Row, /* Glyphicon, */ DropdownButton, MenuItem, OverlayTrigger, Tooltip } from 'react-bootstrap'
import SectionComponent from '../../../../../components/dossiers/common/documentaryManagement/documentaryManagementChild/SectionComponent'
import DocumentListItem from '../../../../../_v2/components/dossiers/common/documentaryManagement/documentaryManagementChild/DocumentListItem'
import { documentEntityType as documentEntityTypeConstants } from '../../../../../constants/dossier/common/documentEntityType'
import { dossierTypes as dossierTypesConstant } from '../../../../../constants/dossier/common/dossierTypes'
import { paperworkTypes as paperworTypeIdConstant } from '../../../../../constants/dossier/paperwork/paperworkTypes'
import { dossierSubType as dossierSubTypeConstant } from '../../../../../constants/dossier/common/dossierSubType'
import { viewTypes } from '../../../../../constants/dossier/common/documentaryManagement'
import { buttonsPermissionCodes, entityTypesId as entityTypes, permissions, clientInformation } from '../../../../../constants/backendIds'
import settings from '../../../../../setting'
import { getDocumentaryPermission } from '../../../../../util/utils'
import LandingRequestModalPage from '../../../../../containers/dossiers/common/LandingRequestModalPage'
import landingAccessErrors from '../../../../../constants/errors/landingAccessErrors'
import DocumentaryMassiveDownloadModalPage from '../../../../../containers/dossiers/common/DocumentaryMassiveDownloadModalPage'
import DocumentaryGroupDocumentsModalPage from '../../../../../containers/dossiers/common/DocumentaryGroupDocumentsModalPage'
import { getFieldsPermisions } from '../../../../../util/permisionFunctions'

class DocumentaryManagement extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      viewType: viewTypes.detail,
      documents: [],
      order: {
        columnName: null,
        direction: 'asc'
      },
      structureGenerated: false,
      tabsConfig: {
        tab1: {
          showTab: true
        },
        tab2: {
          showTab: true
        },
        tab3: {
          showTab: true
        }
      },
      firstLoad: true,
      selectedTab: 1,
      fieldsConfiguration: {
        groupDocsButton: null
      },
      formErrors: null
    }
    this.handleOpenDocumentCreationModal = this.handleOpenDocumentCreationModal.bind(this)
    this.openCreatorModal = this.openCreatorModal.bind(this)
  }

  getEntityTag () {
    switch (this.props.dossierType) {
      case dossierTypesConstant.sales:
        return 'CLIENT'
      case dossierTypesConstant.purchase:
        return 'VENDOR'
      default:
        return 'ENTITY'
    }
  }

  getDocumentaryTag (documentEntityType) {
    if (this.props.dossierType === dossierTypesConstant.paperwork &&
      this.props.dossier.dossierPaperworkComponent?.paperworkTypeId === paperworTypeIdConstant.changeOwner) {
      switch (documentEntityType) {
        case documentEntityTypeConstants.ENTITY:
          return this.getEntityClientDocumentaryTag()
        case documentEntityTypeConstants.ENTITY_PROVIDER:
          return this.getEntityProviderDocumentaryTag()
        default:
          return 'ENTITY'
      }
    }
    return 'ENTITY'
  }

  getEntityClientDocumentaryTag () {
    switch (this.props.dossierType) {
      case dossierTypesConstant.paperwork:
        switch (this.getSubTypePapeworkRelatedDossier()) {
          case dossierSubTypeConstant.compra_vo:
            return 'VENDOR'
          case dossierSubTypeConstant.venta_vo:
            return 'CLIENT'
          default:
            return 'ENTITY'
        }
    }
  }

  getEntityProviderDocumentaryTag () {
    switch (this.props.dossierType) {
      case dossierTypesConstant.paperwork:
        switch (this.getSubTypePapeworkRelatedDossier()) {
          case dossierSubTypeConstant.compra_vo:
            return 'CLIENT'
          case dossierSubTypeConstant.venta_vo:
            return 'VENDOR'
          default:
            return 'ENTITY'
        }
    }
  }

  getSubTypePapeworkRelatedDossier () {
    if (this.props.dossier.dossierRelatedDossiers && this.props.dossier.dossierRelatedDossiers.length) {
      const paperworkRelatedDossier = this.props.dossier.dossierRelatedDossiers[0]
      return paperworkRelatedDossier.dossierSubType
    }
  }

  openCreatorModal (documentEntityType) {
    if (documentEntityType === documentEntityTypeConstants.DOSSIER ||
      (this.props.entityId && documentEntityType === documentEntityTypeConstants.ENTITY) ||
      (this.props.entityProviderId && documentEntityType === documentEntityTypeConstants.ENTITY_PROVIDER) ||
      (this.props.vehicleId && documentEntityType === documentEntityTypeConstants.VEHICLE)) {
      if (this.props.dossier &&
        !this.props.permissionRecurrentEntity &&
        ((documentEntityType === documentEntityTypeConstants.ENTITY &&
         this.props.dossier.entityComponent &&
         this.props.dossier.entityComponent.entityTypeId &&
         this.props.dossier.entityComponent.entityTypeId === entityTypes.recurrentEntity) ||
         (documentEntityType === documentEntityTypeConstants.ENTITY_PROVIDER &&
          this.props.dossier.entityProviderComponent &&
          this.props.dossier.entityProviderComponent.entityTypeId &&
          this.props.dossier.entityProviderComponent.entityTypeId === entityTypes.recurrentEntity))) {
        this.props.actions.openModal('entityAlertNoPermission')
      } else {
        this.props.actions.openDocumentCreationModal(this.props.dossierId, documentEntityType, this.props.dossier.organizedUnitId, this.props.entityId, this.props.vehicleId, () => this.props.actions.refreshDocumentManagementModal(this.props.dossier.dossierId), true, this.props.dossierType, this.props.formState, false, this.props.entityProviderId)
      }
    } else {
      const alert = documentEntityType === documentEntityTypeConstants.ENTITY ||
            documentEntityType === documentEntityTypeConstants.ENTITY_PROVIDER ? 'entityAlert' : 'vehicleAlert'
      this.props.actions.openModal(alert)
    }
  }

  handleOpenDocumentCreationModal (documentEntityType) {
    if (this.props.formState !== undefined && this.props.formState === false && this.props.actions.openDocWarning) {
      new Promise((resolve, reject) => {
        this.props.actions.openDocWarning(resolve, reject)
      }).then(() => {
        this.openCreatorModal(documentEntityType)
      })
    } else {
      this.openCreatorModal(documentEntityType)
    }
  }

  onSelectView (viewType) {
    this.props.actions.refreshDocumentManagementModal(this.props.dossier.dossierId)
    this.setState({ viewType })
  }

  onSelectTab (eventKey, props = this.props) {
    let tab
    switch (eventKey) {
      case 1:
        tab = props.tabCounters.tab1
        break
      case 2:
        tab = props.tabCounters.tab2
        break
      case 3:
        tab = props.tabCounters.tab3
        break
      case 4:
        tab = props.tabCounters.tab4
        break
      default:
        tab = props.tabCounters.tab1
    }
    props.actions.setCurrentTabCounterValues(tab, eventKey)
  }

  UNSAFE_componentWillMount () {
    this.props.actions.refreshDocumentManagementModal(this.props.dossier.dossierId, this.props.isHistorical)
    if (this.props.formErrors) {
      this.setState({ formErrors: this.props.formErrors },
        () => this.props.formName && this.props.actions.updateFormError ? this.props.actions.updateFormError(this.props.formName, this.state.formErrors) : null)
    }
  }

  initializePermisions (props) {
    if (props.tabConfiguration && props.tabConfiguration.componentsConfiguration && props.tabConfiguration.componentsConfiguration.length > 0) {
      const fieldKeys = Object.keys(this.state.fieldsConfiguration)
      const newState = getFieldsPermisions(props.tabConfiguration.componentsConfiguration[0].fieldsConfiguration, fieldKeys, props.tabConfiguration.componentsConfiguration[0].permission)
      this.setState({ ...this.state, ...newState })
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    this.initializePermisions(nextProps)

    let documents = []
    nextProps.sectionsDocuments.tab1 && nextProps.sectionsDocuments.tab1.map((section) => {
      documents = documents.concat(section.documents.map((document) => ({ ...document, documentEntityType: this.getDocumentaryTag(documentEntityTypeConstants.ENTITY), objectId: nextProps.entityId })))
    })
    nextProps.sectionsDocuments.tab2 && nextProps.sectionsDocuments.tab2.map((section) => {
      documents = documents.concat(section.documents.map((document) => ({ ...document, documentEntityType: documentEntityTypeConstants.VEHICLE, objectId: nextProps.vehicleId })))
    })
    nextProps.sectionsDocuments.tab5 && nextProps.sectionsDocuments.tab5.map((section) => {
      documents = documents.concat(section.documents.map((document) => ({ ...document, documentEntityType: this.getDocumentaryTag(documentEntityTypeConstants.ENTITY_PROVIDER), objectId: nextProps.entityProviderId })))
    })
    nextProps.sectionsDocuments.tab3 && nextProps.sectionsDocuments.tab3.map((section) => {
      documents = documents.concat(section.documents.map((document) => {
        if (document.documentTypeUse === 4 && document.checklistId && !this.props.checkListIdReciboPagaYSenal) { // Recibo de paga y señal
          this.props.actions.saveCheckListReciboPagaYSenal(document.checklistId)
        }
        return { ...document, documentEntityType: documentEntityTypeConstants.DOSSIER, objectId: nextProps.dossierId }
      }))
    })
    nextProps.sectionsDocuments.tab1 && nextProps.sectionsDocuments.tab2 && nextProps.sectionsDocuments.tab3 && this.checkTabsHasDocuments(nextProps.sectionsDocuments.tab1, nextProps.sectionsDocuments.tab2, nextProps.sectionsDocuments.tab3)
    this.setState({ documents })
  }

  orderTable () {
    const columnName = this.state.order.columnName

    const documents = this.state.documents.sort(function (a, b) {
      if (columnName === 'validationDate') {
        const fechaInicial = a.validationDate ? a.validationDate.split(' ') : ['-']
        const separarA = fechaInicial.map((dia) => dia[0] === '-' ? ('01,01,2000,00,00') : (fechaInicial[0].split('/').join(',') + ',' + fechaInicial[1].split(':').join(',')))
        const fechaA = separarA.map((datos) => datos.split(','))
        const fechaFinal = b.validationDate ? b.validationDate.split(' ') : ['-']
        const separarB = fechaFinal.map((dia) => dia[0] === '-' ? ('01,01,2000,00,00') : (fechaFinal[0].split('/').join(',') + ',' + fechaFinal[1].split(':').join(',')))
        const fechaB = separarB.map((datos) => datos.split(','))
        const fecha1 = new Date(fechaA[0][2], fechaA[0][1], fechaA[0][0], fechaA[0][3], fechaA[0][4])
        const fecha2 = new Date(fechaB[0][2], fechaB[0][1], fechaB[0][0], fechaB[0][3], fechaB[0][4])
        return fecha2 - fecha1
      } else if (columnName === 'incorporationDate') {
        const fechaInicial = a.incorporationDate ? a.incorporationDate.split(' ') : ['-']
        const separarA = fechaInicial.map((dia) => dia[0] === '-' ? ('01,01,2000,00,00') : (fechaInicial[0].split('/').join(',') + ',' + fechaInicial[1].split(':').join(',')))
        const fechaA = separarA.map((datos) => datos.split(','))
        const fechaFinal = b.incorporationDate ? b.incorporationDate.split(' ') : ['-']
        const separarB = fechaFinal.map((dia) => dia[0] === '-' ? ('01,01,2000,00,00') : (fechaFinal[0].split('/').join(',') + ',' + fechaFinal[1].split(':').join(',')))
        const fechaB = separarB.map((datos) => datos.split(','))
        const fecha1 = new Date(fechaA[0][2], fechaA[0][1], fechaA[0][0], fechaA[0][3], fechaA[0][4])
        const fecha2 = new Date(fechaB[0][2], fechaB[0][1], fechaB[0][0], fechaB[0][3], fechaB[0][4])
        return fecha2 - fecha1
      } else if (columnName === 'checklistStatus') {
        return a.checklistStatus - b.checklistStatus
      } else if (a[columnName] && a[columnName].localeCompare) {
        return a[columnName].localeCompare(b[columnName])
      } else {
        return (isNaN(a[columnName]) ? 0 : a[columnName]) - (isNaN(b[columnName]) ? 0 : b[columnName])
      }
    })
    if (this.state.order.direction === 'asc') {
      documents.reverse()
    }
    this.setState({
      documents
    })
  }

  changeDirection (columnName) {
    this.setState({
      order: {
        columnName: columnName,
        direction: this.state.order.direction === 'asc' ? 'desc' : 'asc'
      }
    }, this.orderTable)
  }

  changeOrderIcon (name) {
    let clase = 'ico-sort'
    if (this.state.order.direction === 'asc' && this.state.order.columnName === name) {
      clase += '-selected-asc'
    } else if (this.state.order.direction === 'desc' && this.state.order.columnName === name) {
      clase += '-selected-desc'
    }
    if (this.state.order.columnName !== null) {
      this.orderTable()
    }
    return clase
  }

  checkTabsHasDocuments (tab1, tab2, tab3) {
    let tabsConfig = {
      tab1: {
        showTab: true
      },
      tab2: {
        showTab: true
      },
      tab3: {
        showTab: true
      }
    }
    let selectedTab = 1

    if (tab1 && tab1.length === 0) {
      tabsConfig.tab1.showTab = false
      selectedTab = 2
    }
    if (tab2 && tab2.length === 0) {
      tabsConfig.tab2.showTab = false
      selectedTab = tabsConfig.tab1.showTab ? 1 : 3
    }
    if (tab3 && tab3.length === 0) {
      tabsConfig.tab3.showTab = false
      selectedTab = tabsConfig.tab1.showTab ? 1 : tabsConfig.tab2.showTab ? 2 : 1
    }
    this.setState({ tabsConfig })
    if (this.state.firstLoad) {
      this.setState({ selectedTab, firstLoad: false })
    }
  }

  openLandingRequestModal () {
    new Promise((resolve, reject) => {
      this.props.actions.getDocumentLandingRequest(this.props.dossierId, resolve, reject)
    }).then(() => {
      this.setState({ landingRequestModal: true })
    }).catch((e) => {
      if (e.tag === landingAccessErrors.NO_EMAIL_ADDRESS) {
        if (this.props.dossierType === dossierTypesConstant.purchase) {
          this.props.actions.openModal('providerEmailRequired')
        } else {
          this.props.actions.openModal('entityEmailRequired')
        }
      }
    })
  }

  openMassiveDownloadModal () {
    new Promise((resolve, reject) => {
      this.props.actions.getDocumentsMassiveDownload(this.props.dossierId, false, resolve, reject)
    }).then(() => {
      this.setState({ massiveDownloadModal: true })
    })
  }

  cancelLandingRequest () {
    new Promise((resolve, reject) => {
      this.props.actions.sendLandingCancelRequest(this.props.dossierId, this.props.dossierType, resolve, reject)
    }).then(() => {
    })
  }

  loadMenuItems (t, tKey) {
    if (settings.clientFieldValidation === clientInformation.QUADIS) {
      const isRecurrentEntityType = (this.props.entityComponent && this.props.entityComponent.entityTypeId ? this.props.entityComponent.entityTypeId === entityTypes.recurrentEntity
        : this.props.entityTypeIdDefault ? this.props.entityTypeIdDefault === entityTypes.recurrentEntity : false)
      const isRecurrentEntityProviderType = (this.props.entityProviderComponent && this.props.entityProviderComponent.entityTypeId ? this.props.entityProviderComponent.entityTypeId === entityTypes.recurrentEntity
        : false)
      return (<React.Fragment>
        {(this.props.dossier.dossierPaperworkComponent?.paperworkTypeId !== paperworTypeIdConstant.changeOwner)
          ? <MenuItem eventKey="1"><Button className="btn-standard" onClick={() => this.handleOpenDocumentCreationModal(documentEntityTypeConstants.ENTITY)}
          >{t(`${tKey + 'TO_' + this.getEntityTag()}`)}</Button></MenuItem> : null
        }
        {(this.props.dossier.dossierPaperworkComponent?.paperworkTypeId === paperworTypeIdConstant.changeOwner && !isRecurrentEntityType)
          ? <MenuItem eventKey="1"><Button className="btn-standard" onClick={() => this.handleOpenDocumentCreationModal(documentEntityTypeConstants.ENTITY)}
          >{t(`${tKey + 'TO_' + this.getEntityClientDocumentaryTag()}`)}</Button></MenuItem> : null
        }
        {(this.props.dossier.dossierPaperworkComponent?.paperworkTypeId === paperworTypeIdConstant.changeOwner && !isRecurrentEntityProviderType)
          ? <MenuItem eventKey="4"><Button className="btn-standard" onClick={() => this.handleOpenDocumentCreationModal(documentEntityTypeConstants.ENTITY_PROVIDER)}
          >{t(`${tKey + 'TO_' + this.getEntityProviderDocumentaryTag()}`) }</Button></MenuItem> : null
        }
      </React.Fragment>)
    }
    return (<React.Fragment>
      <MenuItem eventKey="1"><Button className="_Btn new" onClick={() => this.handleOpenDocumentCreationModal(documentEntityTypeConstants.ENTITY)}
      >{t(`${tKey + 'TO_' + this.getEntityTag()}`)}</Button></MenuItem>

    </React.Fragment>)
  }

  render () {
    const {
      t, dossierId, dossierType, entityId, vehicleId, readOnlyFields, // showRelatedDocuments = true, // documentEntityType, documentStatus,
      sectionsDocuments: { tab1 = [], tab2 = [], tab3 = [] },
      actions: {
        openDocWarning, openDocumentCreationModal, openDocumentEditionModal, cancelDocument, addDependant, printCheckList, openModal, signDocument, openModalVehicleNecessary,
        openDocumentEditionModalHistorical, fetchInsuranceCompanyCombo, openReservationContractModal
      },
      dossier: { dossierSubTypeId, organizedUnitId, viewConfiguration }
    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.DOCUMENTARY_MANAGEMENT.'
    const menuItems = this.loadMenuItems(t, tKey)
    console.log('viewconfiguration', viewConfiguration)
    return (
      <>
        <div className="wrapper _nf">
          <div className='wrapper-header'>
            <div className="title-page">
              <i className='ico-docs icon-page' />
              <h2>{t(`${tKey}TITLE`)}</h2>
            </div>
            <div className='extraActions'> {/** Extra buttons & actions here **/}
              { viewConfiguration.buttonsPermission?.indexOf(buttonsPermissionCodes.landing_request_documents) >= 0 &&
                <OverlayTrigger placement="left" overlay={<Tooltip id="landingAccess" className='popoverTooltip'>{t(`${tKey}LANDING_ACCESS.REQUEST_DOCUMENT`)}</Tooltip>}>
                  <Button className='_Btn accept o-icn' id="landingAccess" onClick={() => this.openLandingRequestModal()}><i className="iDocIcon-attachments"></i></Button>
                </OverlayTrigger>
              }
              { viewConfiguration.buttonsPermission?.indexOf(buttonsPermissionCodes.landing_cancel_request_documents) >= 0 &&
                <OverlayTrigger placement="left" overlay={<Tooltip id="cancelLandingAccess" className='popoverTooltip'>{t(`${tKey}LANDING_ACCESS.CANCEL_REQUEST_DOCUMENT`)}</Tooltip>}>
                  <Button className="_Btn cancel o-icn" id="cancelLandingAccess" onClick={() => this.cancelLandingRequest()}><i className='iDocIcon-Cancel-forbidden'></i></Button>
                </OverlayTrigger>
              }
              <OverlayTrigger placement="left" overlay={<Tooltip id="massiveDownloadButton" className='popoverTooltip'>{t(`${tKey}MASSIVE_DOWNLOAD_MODAL.DOWNLOAD`)}</Tooltip>}>
                <Button className="_Btn accept o-icn" id="massiveDownloadButton" onClick={() => this.openMassiveDownloadModal()}><i className='iDocIcon-download'></i></Button>
              </OverlayTrigger>
              {this.state.fieldsConfiguration.groupDocsButton && this.state.fieldsConfiguration.groupDocsButton.permission > permissions.hidden && (
                <OverlayTrigger placement="left" overlay={<Tooltip id="massiveDownloadButton" className='popoverTooltip'>{t(`${tKey}ADD_OR_EDIT_DOCUMENT.GROUP_DOCUMENT`)}</Tooltip>}>
                  <Button
                    className="_Btn accept o-icn"
                    onClick={() => this.setState({ groupDocumentsModal: true })}
                  >
                    <i className='iDocIcon-assignment-returned-self-consumption'></i>
                  </Button>
                </OverlayTrigger>
              )}
              { !readOnlyFields && this.props.configuration && this.props.configuration.permission === permissions.editable && (
                <OverlayTrigger placement="left" overlay={<Tooltip id="anadir_documento" className='popoverTooltip'>{t(`${tKey}ADD_DOCUMENT`)}</Tooltip>}>
                  <DropdownButton
                    title={<i className='iDocIcon-add-plus'></i>}
                    bsStyle={'addNewDocument'}
                    key={1}
                    id="anadir_documento"
                    className='_Btn new o-icn'
                  >
                    {menuItems}
                    <MenuItem eventKey="2">
                      <Button className="_Btn new" onClick={() => this.handleOpenDocumentCreationModal(documentEntityTypeConstants.VEHICLE)}>
                        {t(`${tKey + 'TO_VEHICLE'}`)}
                      </Button>
                    </MenuItem>
                    <MenuItem eventKey="3">
                      <Button className="_Btn new" onClick={() => this.handleOpenDocumentCreationModal(documentEntityTypeConstants.DOSSIER)}>
                        {t(`${tKey + 'TO_DOSSIER'}`)}
                      </Button>
                    </MenuItem>
                  </DropdownButton>
                </OverlayTrigger>
              )}
            </div>
          </div>
          <div className='wrapper-body'>
            <Table hover responsive className="simple-table gestion-documental-table" id="documentary-management-table">
              <thead>
                <tr>
                  <th onClick={() => [this.changeDirection('checklistStatus')]}><i className={(() => [this.changeOrderIcon('checklistStatus')])()}/></th>
                  <th className='actions' onClick={() => [this.changeDirection('documentStatus')]}><span>{t(`${tKey + 'TABLE.STATUS'}`)}<i className={(() => [this.changeOrderIcon('documentStatus')])()}/></span></th>
                  <th className='actions' onClick={() => [this.changeDirection('signatureStatus')]}><span>{t(`${tKey + 'TABLE.SIGNATURE_STATUS'}`)}<i className={(() => [this.changeOrderIcon('signatureStatus')])()}/></span></th>
                  <th onClick={() => [this.changeDirection('documentName')]}><span>{t(`${tKey + 'TABLE.TYPES'}`)}<i className={(() => [this.changeOrderIcon('documentName')])()}/></span></th>
                  <th onClick={() => [this.changeDirection('documentEntityType')]}><span>{t(`${tKey + 'TABLE.CATEGORY'}`)}<i className={(() => [this.changeOrderIcon('documentEntityType')])()}/></span></th>
                  <th onClick={() => [this.changeDirection('validationDate')]}><span>{t(`${tKey + 'TABLE.VALIDATION_DATE'}`)}<i className={(() => [this.changeOrderIcon('validationDate')])()}/></span></th>
                  <th onClick={() => [this.changeDirection('incorporationDate')]}><span>{t(`${tKey + 'TABLE.ASSIGNMENT_DATE'}`)}<i className={(() => [this.changeOrderIcon('incorporationDate')])()}/></span></th>
                  <th className='actions'><span>{t(`${tKey}ADD_OR_EDIT_DOCUMENT.SHEETS_TABLE.ACTIONS`)}</span></th>
                </tr>
              </thead>
              <tbody>
                {
                  this.state.documents.map((documentFile, idx) => <DocumentListItem
                    t={t}
                    key={idx}
                    readOnly={false}
                    dossierId={dossierId}
                    dossierType={dossierType}
                    documentEntityType={documentFile.documentEntityType}
                    documentFile={documentFile}
                    formState={this.props.formState}
                    objectId={documentFile.objectId}
                    hasVehicle={this.props.dossier && this.props.dossier.vehicleComponent?.vehicleId}
                    actions={{
                      openDocumentEditionModalWithChecklistId: (documentId, documentTypeUse, checklistId) => documentFile.isHistorical ? openDocumentEditionModalHistorical(documentId, documentTypeUse, documentFile.documentEntityType, dossierId, documentFile.objectId, vehicleId, organizedUnitId, checklistId, () => this.props.actions.refreshDocumentManagementModal(dossierId), true, this.props.dossierType, null, dossierSubTypeId)
                        : openDocumentEditionModal(documentId, documentTypeUse, documentFile.documentTypeEntityId, dossierId, documentFile.objectId, vehicleId, organizedUnitId, checklistId, () => this.props.actions.refreshDocumentManagementModal(dossierId), true, this.props.dossierType, dossierSubTypeId, null, this.props.formState, null, false, viewConfiguration ? getDocumentaryPermission(viewConfiguration) : true, readOnlyFields),
                      cancelDocument,
                      printCheckList,
                      addDependant,
                      openModal,
                      signDocument,
                      openModalVehicleNecessary,
                      openDocWarning,
                      fetchInsuranceCompanyCombo,
                      openReservationContractModal
                    }}
                    readOnlyFields={readOnlyFields}
                  />)
                }
              </tbody>
            </Table>
          </div>
        </div>
        <Row className="management-doc-modal hide">
          <LandingRequestModalPage
            dossierId={dossierId}
            dossierType={dossierType}
            showModal={this.state.landingRequestModal}
            closeModal={() => this.setState({ landingRequestModal: false })}></LandingRequestModalPage>
          <DocumentaryMassiveDownloadModalPage
            dossierId={dossierId}
            isHistorical={false}
            showModal={this.state.massiveDownloadModal}
            closeModal={() => this.setState({ massiveDownloadModal: false })}></DocumentaryMassiveDownloadModalPage>
          <DocumentaryGroupDocumentsModalPage
            dossierId={dossierId}
            isHistorical={false}
            showModal={this.state.groupDocumentsModal}
            documents={this.state.documents.filter(x => x.documentId)}
            closeModal={() => this.setState({ groupDocumentsModal: false })}
            dossier={this.props.dossier}
            entityId={this.props.entityId}
            vehicleId={this.props.vehicleId}
            entityProviderId={this.props.entityProviderId}
            organizedUnitId={organizedUnitId}></DocumentaryGroupDocumentsModalPage>

          <Col sm={12}>
            { this.state.viewType === viewTypes.mosaic ? (
              <Tabs id="1" activeKey={this.state.selectedTab}
                onSelect={(eventKey) => { this.onSelectTab(eventKey); this.setState({ selectedTab: eventKey }) }} >
                { this.state.tabsConfig.tab1.showTab ? (
                  <Tab eventKey={1} title={t(`${tKey + this.getEntityTag()}`)} className="user-tab">
                    { !readOnlyFields && entityId && this.props.configuration && this.props.configuration.permission === permissions.editable &&
                    <Button
                      className="btn-standard"
                      onClick={() => {
                        if (this.props.formState !== undefined && this.props.formState === false && openDocWarning) {
                          new Promise((resolve, reject) => {
                            openDocWarning(resolve, reject)
                          }).then(() => {
                            openDocumentCreationModal(dossierId, documentEntityTypeConstants.ENTITY, organizedUnitId, entityId, vehicleId, () => this.props.actions.refreshDocumentManagementModal(dossierId), true, this.props.dossierType, this.props.formState)
                          })
                        } else {
                          openDocumentCreationModal(dossierId, documentEntityTypeConstants.ENTITY, organizedUnitId, entityId, vehicleId, () => this.props.actions.refreshDocumentManagementModal(dossierId), true, this.props.dossierType, this.props.formState)
                        }
                      }}
                    >
                      <i className="ico-document-plus"/>
                      <span>{t(`${tKey}ADD_OR_EDIT_DOCUMENT.ADD`)}</span>
                    </Button>
                    }
                    {
                      tab1.map((section, idx) => (<SectionComponent
                        key={idx}
                        t={t}
                        section={section}
                        dossierId={dossierId}
                        dossierType={dossierType}
                        documentEntityType={documentEntityTypeConstants.ENTITY}
                        objectId={entityId}
                        formState={this.props.formState}
                        actions={{
                          openDocumentEditionModalWithChecklistId: (documentId, documentTypeUse, checklistId) => openDocumentEditionModal(documentId, documentTypeUse, documentEntityTypeConstants.ENTITY, dossierId, entityId, vehicleId, organizedUnitId, checklistId, () => this.props.actions.refreshDocumentManagementModal(dossierId), true, this.props.dossierType, dossierSubTypeId, null, this.props.formState, null, false, viewConfiguration ? getDocumentaryPermission(viewConfiguration) : true, readOnlyFields),
                          cancelDocument,
                          printCheckList,
                          addDependant,
                          openModal,
                          openDocWarning
                        }}
                        readOnlyFields={readOnlyFields}
                      />))
                    }
                  </Tab>) : null }
                { this.state.tabsConfig.tab2.showTab ? (
                  <Tab eventKey={2} title={t(`${tKey}VEHICLE`)} className="user-tab">
                    {
                      !readOnlyFields && vehicleId && this.props.configuration && this.props.configuration.permission === permissions.editable
                        ? (
                          <Button
                            className="btn-standard"
                            onClick={() => {
                              if (this.props.formState !== undefined && this.props.formState === false && openDocWarning) {
                                new Promise((resolve, reject) => {
                                  openDocWarning(resolve, reject)
                                }).then(() => {
                                  openDocumentCreationModal(dossierId, documentEntityTypeConstants.VEHICLE, organizedUnitId, entityId, vehicleId, () => this.props.actions.refreshDocumentManagementModal(dossierId), true, this.props.dossierType, this.props.formState)
                                })
                              } else {
                                openDocumentCreationModal(dossierId, documentEntityTypeConstants.VEHICLE, organizedUnitId, entityId, vehicleId, () => this.props.actions.refreshDocumentManagementModal(dossierId), true, this.props.dossierType, this.props.formState)
                              }
                            }}
                          >
                            <i className="ico-document-plus"/>
                            <span>{t(`${tKey}ADD_OR_EDIT_DOCUMENT.ADD`)}</span>
                          </Button>
                        ) : null
                    }
                    {
                      tab2.map((section, idx) => (<SectionComponent
                        key={idx}
                        t={t}
                        section={section}
                        dossierId={dossierId}
                        dossierType={dossierType}
                        documentEntityType={documentEntityTypeConstants.VEHICLE}
                        objectId={vehicleId}
                        formState={this.props.formState}
                        actions={{
                          openDocumentEditionModalWithChecklistId: (documentId, documentTypeUse, checklistId) => openDocumentEditionModal(documentId, documentTypeUse, documentEntityTypeConstants.VEHICLE, dossierId, entityId, vehicleId, organizedUnitId, checklistId, () => this.props.actions.refreshDocumentManagementModal(dossierId), true, this.props.dossierType, dossierSubTypeId, null, this.props.formState, null, false, viewConfiguration ? getDocumentaryPermission(viewConfiguration) : true, readOnlyFields),
                          cancelDocument,
                          printCheckList,
                          addDependant,
                          openModal,
                          openDocWarning
                        }}
                        readOnlyFields={readOnlyFields}
                      />))
                    }
                  </Tab>) : null}
                { this.state.tabsConfig.tab3.showTab ? (
                  <Tab eventKey={3} title={t(`${tKey}DOSSIER`)} className="user-tab">
                    {
                      !readOnlyFields && this.props.configuration && this.props.configuration.permission === permissions.editable &&
                    <Button
                      className="btn-standard"
                      onClick={() => {
                        if (this.props.formState !== undefined && this.props.formState === false && openDocWarning) {
                          new Promise((resolve, reject) => {
                            openDocWarning(resolve, reject)
                          }).then(() => {
                            openDocumentCreationModal(dossierId, documentEntityTypeConstants.DOSSIER, organizedUnitId, entityId, vehicleId, () => this.props.actions.refreshDocumentManagementModal(dossierId), true, this.props.dossierType, this.props.formState)
                          })
                        } else {
                          openDocumentCreationModal(dossierId, documentEntityTypeConstants.DOSSIER, organizedUnitId, entityId, vehicleId, () => this.props.actions.refreshDocumentManagementModal(dossierId), true, this.props.dossierType, this.props.formState)
                        }
                      }}
                    >
                      <i className="ico-document-plus"/>
                      <span>{t(`${tKey}ADD_OR_EDIT_DOCUMENT.ADD`)}</span>
                    </Button>
                    }
                    {
                      tab3.map((section, idx) => (<SectionComponent
                        key={idx}
                        t={t}
                        section={section}
                        dossierId={dossierId}
                        dossierType={dossierType}
                        documentEntityType={documentEntityTypeConstants.DOSSIER}
                        objectId={dossierId}
                        formState={this.props.formState}
                        actions={{
                          openDocumentEditionModalWithChecklistId: (documentId, documentTypeUse, checklistId) => openDocumentEditionModal(documentId, documentTypeUse, documentEntityTypeConstants.DOSSIER, dossierId, entityId, vehicleId, organizedUnitId, checklistId, () => this.props.actions.refreshDocumentManagementModal(dossierId), true, this.props.dossierType, dossierSubTypeId, null, this.props.formState, null, false, viewConfiguration ? getDocumentaryPermission(viewConfiguration) : true, readOnlyFields),
                          cancelDocument,
                          printCheckList,
                          addDependant,
                          openModal,
                          openDocWarning
                        }}
                        readOnlyFields={readOnlyFields}
                      />))
                    }
                  </Tab>) : null}
              </Tabs>
            ) : null}
            {
              this.state.viewType === viewTypes.detail && (
                <Table hover responsive className="simple-table gestion-documental-table" id="documentary-management-table">
                  <thead>
                    <tr>
                      <th onClick={() => [this.changeDirection('checklistStatus')]}><i className={(() => [this.changeOrderIcon('checklistStatus')])()}/></th>
                      <th onClick={() => [this.changeDirection('documentStatus')]}><span>{t(`${tKey + 'TABLE.STATUS'}`)}<i className={(() => [this.changeOrderIcon('documentStatus')])()}/></span></th>
                      <th onClick={() => [this.changeDirection('signatureStatus')]}><span>{t(`${tKey + 'TABLE.SIGNATURE_STATUS'}`)}<i className={(() => [this.changeOrderIcon('signatureStatus')])()}/></span></th>
                      <th onClick={() => [this.changeDirection('documentName')]}><span>{t(`${tKey + 'TABLE.TYPES'}`)}<i className={(() => [this.changeOrderIcon('documentName')])()}/></span></th>
                      <th onClick={() => [this.changeDirection('documentEntityType')]}><span>{t(`${tKey + 'TABLE.CATEGORY'}`)}<i className={(() => [this.changeOrderIcon('documentEntityType')])()}/></span></th>
                      <th onClick={() => [this.changeDirection('validationDate')]}><span>{t(`${tKey + 'TABLE.VALIDATION_DATE'}`)}<i className={(() => [this.changeOrderIcon('validationDate')])()}/></span></th>
                      <th onClick={() => [this.changeDirection('incorporationDate')]}><span>{t(`${tKey + 'TABLE.ASSIGNMENT_DATE'}`)}<i className={(() => [this.changeOrderIcon('incorporationDate')])()}/></span></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      this.state.documents.map((documentFile, idx) => <DocumentListItem
                        t={t}
                        key={idx}
                        readOnly={false}
                        dossierId={dossierId}
                        dossierType={dossierType}
                        documentEntityType={documentFile.documentEntityType}
                        documentFile={documentFile}
                        formState={this.props.formState}
                        objectId={documentFile.objectId}
                        hasVehicle={this.props.dossier && this.props.dossier.vehicleComponent?.vehicleId}
                        actions={{
                          openDocumentEditionModalWithChecklistId: (documentId, documentTypeUse, checklistId) => documentFile.isHistorical ? openDocumentEditionModalHistorical(documentId, documentTypeUse, documentFile.documentEntityType, dossierId, documentFile.objectId, vehicleId, organizedUnitId, checklistId, () => this.props.actions.refreshDocumentManagementModal(dossierId), true, this.props.dossierType, null, dossierSubTypeId)
                            : openDocumentEditionModal(documentId, documentTypeUse, documentFile.documentTypeEntityId, dossierId, documentFile.objectId, vehicleId, organizedUnitId, checklistId, () => this.props.actions.refreshDocumentManagementModal(dossierId), true, this.props.dossierType, dossierSubTypeId, null, this.props.formState, null, false, viewConfiguration ? getDocumentaryPermission(viewConfiguration) : true, readOnlyFields),
                          cancelDocument,
                          printCheckList,
                          addDependant,
                          openModal,
                          signDocument,
                          openModalVehicleNecessary,
                          openDocWarning
                        }}
                        readOnlyFields={readOnlyFields}
                      />)
                    }
                  </tbody>
                </Table>
              )}
          </Col>
        </Row>
      </>
    )
  }
}

export default DocumentaryManagement
