import dashboardTaskActionTypes from '../../constants/actions/dashboard/dashboardTask'

export function fetchFilteredDashboardTask (taskDescription, urgencyType, activeTaskId, orderby, page, pageSize, urgencyTypeTask, resolve, reject) {
  return {
    type: dashboardTaskActionTypes.FETCH_FILTERED_DASHBOARD_TASK,
    taskDescription,
    urgencyType,
    activeTaskId,
    orderby,
    page,
    pageSize,
    urgencyTypeTask,
    resolve,
    reject
  }
}

export function fetchFilteredDashboardTaskSuccess (urgencyType, activeTaskId, activeTaskDescription, activeTaskValues, orderby, page, pageSize, urgencyTypeTask) {
  return {
    type: dashboardTaskActionTypes.FETCH_FILTERED_DASHBOARD_TASK_SUCCESS,
    urgencyType,
    activeTaskId,
    activeTaskDescription,
    activeTaskValues,
    orderby,
    page,
    pageSize,
    urgencyTypeTask
  }
}

export function fetchDashboardTask (resolve, reject) {
  return {
    type: dashboardTaskActionTypes.FETCH_DASHBOARD_TASK,
    resolve,
    reject
  }
}

export function fetchDashboardTaskSuccess (tasks) {
  return {
    type: dashboardTaskActionTypes.FETCH_DASHBOARD_TASK_SUCCESS,
    tasks
  }
}

export function getFilteredTaskToExcel (activeTaskId, orderby, page, pageSize, urgencyTypeTask) {
  return {
    type: dashboardTaskActionTypes.EXPORT_TASK_TO_EXCEL,
    activeTaskId,
    orderby,
    page,
    pageSize,
    urgencyTypeTask
  }
}

export function switchTaskFavorite (taskId, favoriteStatus) {
  return {
    type: dashboardTaskActionTypes.SWITCH_TASK_FAVORITE,
    taskId,
    favoriteStatus
  }
}

export default {
  fetchFilteredDashboardTask,
  fetchDashboardTaskSuccess,
  fetchDashboardTask,
  fetchFilteredDashboardTaskSuccess,
  getFilteredTaskToExcel,
  switchTaskFavorite
}
