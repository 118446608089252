import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import SaleTypeMaster from '../../../components/masters/saleType/SaleTypeMaster'
import { translate } from 'react-polyglot'
import { fetchSaleTypeList, openSaleTypeModal, deleteSaleType, recoverSaleType, openDuplicateSaleTypeModal } from '../../../actions/masters/masters'

export function mapStateToProps (state) {
  return {
    ...state.saleType
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchSaleTypeList,
      openSaleTypeModal,
      deleteSaleType,
      recoverSaleType,
      openDuplicateSaleTypeModal
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(SaleTypeMaster))
