import { put, call, select, takeEvery } from 'redux-saga/effects'
import { getAuth } from '../../selectors/access/auth'
import { getBuyFilter } from '../../selectors/reports/buyReport'
import buyActionTypes from '../../constants/actions/reports/buyReport'
import { fetchBuyReportSuccess } from '../../actions/reports/buyReport'
import { showLoader, hideLoader } from '../../actions/common.js'
import postBuyListService from '../../services/reports/postBuyList'
import { handleError } from '../errors/errorManager'
import getLowestOrganizedUnits from '../../services/organizedUnit/getOrganizedUnits'
import { fetchCombosSuccess } from '../../actions/combos/combos'
import getDownloadBuyList from '../../services/reports/getDownloadBuyList'

export function * fetchBuyList ({ filter }) {
  try {
    yield put(showLoader())
    yield filter = yield filter || select(getBuyFilter)
    const auth = yield select(getAuth)
    const buyList = yield call(postBuyListService, auth.token, filter)
    const lowestOrganizedUnitsCombo = yield call(getLowestOrganizedUnits, auth.token)
    yield put(fetchCombosSuccess({
      lowestOrganizedUnitsCombo
    }))
    yield put(fetchBuyReportSuccess(buyList, filter))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchBuyList () {
  yield takeEvery(buyActionTypes.FETCH_BUY_REPORT, fetchBuyList)
}

export function * downloadBuyList ({ filter }) {
  try {
    yield put(showLoader())
    yield filter = yield filter || select(getBuyFilter)
    const auth = yield select(getAuth)
    yield call(getDownloadBuyList, auth.token, filter)
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDownloadBuyList () {
  yield takeEvery(buyActionTypes.DOWNLOAD_BUY_REPORT, downloadBuyList)
}
