// Libraries
import React, { PureComponent } from 'react'
import { reduxForm } from 'redux-form'
import { Row, Col, Form, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Element } from 'react-scroll'
// Consts
import { dossierTypes as dossierTypesConstant } from '../../../../constants/dossier/common/dossierTypes'
import { buttonsHeader, buttonsHeaderPermisionsIdReverse } from '../../../../constants/dossier/common/buttonsHeader'
import { buttonsSidebar } from '../../../../constants/dossier/common/buttonsSidebar'
import { permissions } from '../../../../constants/backendIds'
// Functions
import { compareObjects, getDocumentaryPermission } from '../../../../util/utils'
import { getComponentsPermisions } from '../../../../util/permisionFunctions'
// Components
import DocumentaryManagementModalPage from '../../../../containers/dossiers/common/DocumentaryManagementModalPage'
import ButtonsHeader from '../../../../_v2/components/dossiers/common/ButtonsHeader'
import ButtonsSidebar from '../../../../_v2/components/dossiers/common/ButtonsSidebar'

import TrackingContainer from '../../../../containers/tracking/trackingContainer'
import { TrackedPages } from '../../../../constants/tracking/TrackedPages'

import DossierBudgetRatingModalPage from '../../../../containers/dossiers/stock/DossierBudgetRatingModalPage'

import { buttonsPermissions } from '../../../../constants/dossier/common/buttonsPermissions'
/// ////////////////////////////////////////////
import DossierHeaderPage from '../../../containers/dossiers/commons/dossierHeaderPage'
import DossierTimeLineSectionPage from '../../../containers/dossiers/commons/DossierTimeLineSectionPage'
import DossierSummary from '../common/DossierSummary'
/// ////_V2 componets ////////////////////////////////////////////////////////
import Vehicle from '../../../../_v2/components/dossiers/common/Vehicle'
import Entry from '../../../../_v2/components/dossiers/stock/Entry'
import Reception from '../../../../_v2/components/dossiers/common/Reception'
import DossierRating from '../../../../_v2/components/dossiers/stock/DossierRating'
import DossierBudget from '../../../../_v2/components/dossiers/common/DossierBudget'
import Reconditioning from '../../../../_v2/components/dossiers/stock/Reconditioning'
import Cleaning from '../../../../_v2/components/dossiers/stock/Cleaning'
import Checklist from '../../../../_v2/components/dossiers/stock/Checklist'
import Photographs from '../../../../_v2/components/dossiers/stock/Photographs'
import Crane from '../../../../_v2/components/dossiers/stock/Crane'
import PriceChange from '../../../../_v2/components/dossiers/stock/PriceChange'
import BookingAndDelivery from '../../../../_v2/components/dossiers/stock/BookingAndDelivery'
/// //////////////////////////////////////////////
import DocumentaryManagementPage from '../../../../_v2/containers/dossiers/commons/DocumentaryManagementPage'
import RelatedDossiers from '../common/RelatedDossiers'
import AuditInfo from '../../../../_v2/components/dossiers/common/audit/AuditInfo'
import CommentComponent from '../../../../_v2/components/dossiers/common/comment/CommentComponent'

class StockDossier extends PureComponent {
  constructor () {
    super()
    this.state = {
      commentModalOpen: true,
      summaryOpen: true,
      showRejectCommentModal: false,
      activeTabConfiguration: null,
      headerTabConfiguration: null,
      isVehicleLoaded: false,
      componentsConfiguration: {
        vehicle: null,
        stock: null,
        reception: null,
        entry: null,
        price_change: null,
        dossier_rating: null,
        cleaning: null,
        checklist: null,
        reconditioning: null,
        dossierBudget: null,
        crane: null,
        photographs: null,
        booking_and_delivery: null
      },
      reason: null,
      comment: null,
      isBudgetLoaded: false,
      dossierBodyLeftClose: false,
      showTimelineInTab: false
    }
    this.goBack = this.goBack.bind(this)
  }

  goBack () {
    this.props.actions.setActiveTab(buttonsHeader.datos_expediente)
    this.props.history.goBack()
  }

  toggleRejectCommentModal (showRejectCommentModal) {
    this.setState({ showRejectCommentModal })
  }

  submitStockDossier (values) {
    if (values) {
      this.props.actions.saveStockDossier(values)
    }
  }

  fetchStockDossierPromise (dossierId) {
    return new Promise((resolve, reject) => {
      return this.props.actions.fetchStockDossier(dossierId, resolve, reject)
    })
  }

  onClickRelatedDossierColumn (columnCode) {
    let columnCodeOrder = '+' + columnCode
    if (columnCodeOrder === this.props.relatedDossiersOrderBy) {
      columnCodeOrder = '-' + columnCode
    }
    this.props.actions.fetchFilteredRelatedDossier(this.props.dossier.dossierId, columnCodeOrder)
  }

  getRelatedDossierColumnIcon (columnCode) {
    if ('+' + columnCode === this.props.relatedDossiersOrderBy) {
      return 'ico-sort-selected-asc'
    } else if ('-' + columnCode === this.props.relatedDossiersOrderBy) {
      return 'ico-sort-selected-desc'
    } else {
      return 'ico-sort'
    }
  }

  routerWillLeave (nextLocation) {
    let pristine = true
    if (this.props.dossier.viewConfiguration.buttonsPermission && this.props.dossier.viewConfiguration.buttonsPermission.includes('stock_dossier_save')) {
      pristine = this.props.pristine
    }
    this.props.actions.stockDossierWarningSave(this.props.reset, this.props.history, nextLocation, pristine)
    return pristine
  }

  initializePermisions (props) {
    const componentKeys = Object.keys(this.state.componentsConfiguration)
    const newState = getComponentsPermisions(props.dossier.viewConfiguration.tabsConfiguration, props.activeTab, componentKeys)
    this.setState({ ...this.state, ...newState })
  }

  componentDidMount () {
    this.props.actions.restartStockDossierState()
    this.fetchStockDossierPromise(this.props.match.params.dossierId).then(() => {
      const form = {
        ...this.props.dossier
      }
      this.props.initialize(form)
      this.initializePermisions(this.props)
      this.props.actions.fetchAllReasons(this.props.dossier.dossierSubTypeId)

      this.props.trackVisitPage({
        documentTitle: TrackedPages.STOCK,
        organizedUnitId: this.props.dossier.organizedUnitId,
        href: window.location.href
      })
      this.props.actions.fetchSameLevelOusCombo(this.props.dossier.organizedUnitId, null)
      this.props.actions.fetchRatingCombo()
      this.props.actions.fetchRatingComboByOrganizedUnit(this.props.dossier.organizedUnitId)
    })
    this.unregisterLeaveHook = this.props.history.block(this.routerWillLeave.bind(this))

    this.setState({ showTimelineInTab: this.props.auth.showChecklistAsTab ?? false })
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (this.props.dossier && this.props.dossier.viewConfiguration && this.props.dossier.viewConfiguration.tabsConfiguration && !compareObjects(prevProps.dossier.viewConfiguration.tabsConfiguration || {}, this.props.dossier.viewConfiguration.tabsConfiguration, true)) {
      this.initializePermisions(this.props)
    }
    if (prevProps.dossier.dossierId && prevProps.activeTab !== this.props.activeTab) {
      this.initializePermisions(this.props)
    }
    return prevProps
  }

  componentDidUpdate (prevProps) {
    if ((this.props.showManagementModal !== prevProps.showManagementModal && !this.props.showManagementModal) ||
      this.props.match.params.dossierId !== prevProps.match.params.dossierId) {
      if (this.props.match.params.dossierId !== prevProps.match.params.dossierId) {
        this.props.actions.restartStockDossierState()
        this.props.actions.setScrollTo(buttonsSidebar.vehicle)
        this.props.actions.setActiveSection(buttonsSidebar.vehicle)
        this.props.actions.setActiveTab(buttonsHeader.datos_expediente)
        this.props.actions.closeAddOrEditDocumentModal()
      }
      this.fetchStockDossierPromise(this.props.match.params.dossierId).then(() => {
        const form = { ...this.props.dossier }
        this.props.initialize(form)
        this.initializePermisions(this.props)
      })
    }
    if (this.props.dossier) {
      if (this.props.dossier.dossierId && (this.props.activeTab !== prevProps.activeTab || !compareObjects(this.props.dossier.viewConfiguration.tabsConfiguration || {}, prevProps.dossier.viewConfiguration.tabsConfiguration || {}, true))) {
        this.initializePermisions(this.props)
      }
      if (this.props.dossier.dossierId && this.props.dossier.comments && this.props.activeTab !== prevProps.activeTab && this.props.activeTab === buttonsHeader.observaciones) {
        this.props.actions.getComments(this.props.dossier.dossierId, dossierTypesConstant.stock)
      }
      if (this.props.dossier.organizedUnitId && this.props.dossier.organizedUnitId !== prevProps.dossier.organizedUnitId) {
        this.props.actions.fecthOrganizedUnitsByDossierUo(this.props.dossier.organizedUnitId)
      }
      if (this.props.dossier.dossierId && this.props.statusHeaderHeight && this.props.activeSection !== prevProps.activeSection) {
        switch (this.props.activeSection) {
          case buttonsSidebar.vehicle:
            this.setState({ summaryOpen: true })
            break
        }
        this.props.actions.setScrollTo(this.props.activeSection)
        this.props.actions.setActiveSection(false)
      }
    }
    if (prevProps.dossier && this.props.dossier &&
      prevProps.dossier.vehicleComponent && this.props.dossier.vehicleComponent &&
      !!prevProps.dossier.vehicleComponent?.vehicleId && !this.props.dossier.vehicleComponent?.vehicleId) {
      this.setState({ isVehicleLoaded: false })
    }
  }

  componentWillUnmount () {
    this.props.actions.setActiveSection(buttonsSidebar.vehicle)
    this.props.actions.setScrollTo(null)
    this.props.actions.resetButtonsHeader()
    this.unregisterLeaveHook
    this.props.actions.closeAddOrEditDocumentModal()
    this.props.actions.restartStockDossierState()
  }

  handleToUpdateComponent (component) {
    if (component.isVehicleLoaded === false || buttonsSidebar.vehicle === component) this.setState({ isVehicleLoaded: true })
    if (component.isBudgetLoaded === false || buttonsSidebar.dossierBudget === component) this.setState({ isBudgetLoaded: true })
    this.props.actions.setScrollTo(component)
  }

  handleSubmitForm () {
    if (this.props.formErrors) {
      let skipGeneralMsg = false
      if (this.props.isInvalid && !skipGeneralMsg) {
        this.props.actions.openErrorMessageModal('DOSSIER_COMPONENTS.VALIDATIONS.GENERAL_MANDATORY')
      }
    }
    this.props.handleSubmit((values) => this.submitStockDossier(values))()
  }

  togglePanel () {
    if (this.state.summaryOpen) {
      this.props.actions.setActiveSection(false)
    }
    this.setState({ summaryOpen: !this.state.summaryOpen })
  }

  setSelectedVehicle (vehicle) {
    vehicle.updated = true
    this.props.change('vehicleComponent', vehicle)
    this.props.actions.fetchStockDossierSuccess({ ...this.props.dossier, dossierVehicleComponent: vehicle })
    this.props.actions.resetSearchFields()
  }

  toggleShowTimelineInTab () {
    const showTimelineInTab = !this.state.showTimelineInTab

    if (this.props.activeTab === buttonsHeader.time_line) {
      let nextActiveTab = this.props.dossier.viewConfiguration.tabsConfiguration.filter((tabConf) => tabConf.permission > permissions.hidden)
      this.props.actions.setActiveTab(buttonsHeaderPermisionsIdReverse[nextActiveTab[0].code])
    }
    this.setState({ showTimelineInTab })
  }

  toogleHidedossierBodyLeft () {
    this.setState({ dossierBodyLeftClose: !this.state.dossierBodyLeftClose })
  }

  render () {
    const {
      t, change, combos, dossier, pristine, activeTab, buttonsHeaderHeight, statusHeaderHeight, windowWidth, reset, formValues, vehicleProps, activeSection, reason, comment, dossierRatingComponent, dossierPhotosComponent,
      vehicleSelectionModal,
      dossier: {
        dossierId, dossierSubTypeId, header, checkListHeader, comments, viewConfiguration, isMarked, auditInfo, dossierParentId, unreadComments, dossierRelatedDossiers, organizedUnitId
      },
      handleSubmit,
      actions: {
        updateComment, createComment, deleteComment, putCancelDossier,
        openDocumentModal, storeDossier, setActiveTab, setButtonsHeaderHeight, resetButtonsHeader, setActiveSection, setScrollerOptions,
        refreshAuditInfo, fetchAuditDetails, fetchAuditDetailsModal, fetchDocumentsGroupedAuditDetails, fetchDocumentsGroupedAuditDetailsModal,
        fetchLowestOrganizedUnits, fecthOrganizedUnitsByDossierUo, getChangeUo, getSalesmanChangeUo, postUpdateChangeUo, markCommentAsRead,
        searchVehicle, setSearchDealerNumber, setSearchLicensePlate, setSearchVinChassis, resetSearchFields, setSimpleInputTextValidation, clearVehicle, fecthFamily,
        fetchComboDestination, fetchComboOrigin, fetchStockDossierVehicle, stockApproveByRole, fetchCancelCombo,
        confirmDateWithIdByStage, confirmPhotos, deleteDossierRating, addDossierRating, getDossierBudget,
        fetchDossierBudgetRating, deleteDossierBudgetRating, rejectDossierStockVoBudget,
        changeRelationEntryValues, activateDossierCrane, deactivateDossierCrane, dossierCraneConfirmDate,
        getDossierPhoto, openDocumentCreationModal, openDocumentEditionModal, cancelDocument,
        skipDossierPhotos, closeVehicleSelectionModal, fetchVehicleSelectionModal, fetchFilteredRelatedDossier
      }
    } = this.props
    if (!dossierId) return null
    const tKey = 'DOSSIER_COMPONENTS.'
    const activeStage = checkListHeader.find(stage => stage.isActive)
    const panelsPaddingTop = statusHeaderHeight + buttonsHeaderHeight - 82
    const stockDossierSavePermission = viewConfiguration.buttonsPermission.includes(buttonsPermissions.stock_dossier_save)
    return (
      <>
        <div class="dossierGrid">
          <div class="dossierGridPart-header">
            <DossierHeaderPage
              headerData={header}
              stages={checkListHeader}
              dossierType={dossierTypesConstant.sales}
              dossierSubType={dossierSubTypeId}
              dossierId={dossier.dossierId}
              activeTab={activeTab}
              headerTabConfiguration={this.state.headerTabConfiguration}
              pristine={pristine}
              goBack={this.goBack}
              readOnlyFields={isMarked}
              editableDoc={viewConfiguration ? getDocumentaryPermission(viewConfiguration) : true}
              isSelfSupplyLoaded={this.state.isSelfSupplyLoaded}
              showTimelineInTab={this.state.showTimelineInTab}
              toggleShowTimelineInTab = {() => this.toggleShowTimelineInTab()}
              timeLinePosition = {this.state.showTimelineInTab}
              handleToUpdateSelfSupply = {() => this.handleToUpdateSelfSupply()}
              vehicleComponent = {dossier.vehicleComponent}
            />
          </div>
          <div class="dossierGridPart-body">
            <div className={'dossierBody-left ' + (this.state.dossierBodyLeftClose ? 'close' : '') }>
              <DossierSummary
                t = {t}
                dossier={dossier}
                header={header}
                dossierType={dossierTypesConstant.stock}
                dossierSubType={dossierSubTypeId}
                brandCombo = {combos.brandCombo}
                entityTypeCombo = {combos.entityTypeCombo}
                dossierRelatedDossiers = {dossierRelatedDossiers}
              >
              </DossierSummary>
            </div>
            <div className='dossierBody-center'>
              <OverlayTrigger placement="right" overlay={<Tooltip id="editProductTooltip">{`${t((this.state.dossierBodyLeftClose ? 'GLOBAL.TABLES.SHOW_INNER_MENU' : 'GLOBAL.TABLES.HIDE_INNER_MENU'))}`}</Tooltip>}>
                <a onClick={() => this.toogleHidedossierBodyLeft()} className={'closeDossierInfo ' + (this.state.dossierBodyLeftClose ? '' : 'closeInnerMenu')}><i className={this.state.dossierBodyLeftClose ? 'iDocIcon-Open-inner-menu' : 'iDocIcon-Close-inner-menu'}></i></a>
              </OverlayTrigger>
            </div>
            <div className='dossierBody-right'>
              {!this.state.showTimelineInTab && <div className='dossierGridPart-topTimeLine'>
                <DossierTimeLineSectionPage
                  showTitlePage={false}
                  headerData={header}
                  stages={checkListHeader}
                  dossierType={dossierTypesConstant.sales}
                  dossierSubType={dossierSubTypeId}
                  dossierId={dossier.dossierId}
                  activeTab={activeTab}
                  headerTabConfiguration={this.state.headerTabConfiguration}
                  pristine={pristine}
                  goBack={this.goBack}
                  readOnlyFields={isMarked}
                  editableDoc={viewConfiguration ? getDocumentaryPermission(viewConfiguration) : true}
                  isSelfSupplyLoaded={this.state.isSelfSupplyLoaded}
                  handleToUpdateSelfSupply = {() => this.handleToUpdateSelfSupply()}
                />
              </div>}
              <div class="dossierGridPart-sectionsMenu">
                <ButtonsHeader
                  t={t}
                  dossierId={dossierId ?? this.props.match.params.dossierId}
                  pristine={pristine}
                  reset={reset}
                  lowestOrganizedUnitsCombo={combos.organizedUnitsLegalCif}
                  lastAccessDate={dossier.lastAccessDate}
                  cancelCombo={combos.cancelCombo}
                  reason={reason}
                  comment={comment}
                  actions={{
                    storeDossier,
                    openDocumentModal: () => openDocumentModal(dossierId, dossierTypesConstant.stock, pristine),
                    setActiveTab,
                    setButtonsHeaderHeight,
                    resetButtonsHeader,
                    setScrollerOptions,
                    submitForm: this.handleSubmitForm.bind(this),
                    fetchLowestOrganizedUnits,
                    getChangeUo,
                    getSalesmanChangeUo,
                    postUpdateChangeUo,
                    fecthOrganizedUnitsByDossierUo,
                    stockApproveByRole,
                    putCancelDossier,
                    fetchCancelCombo: fetchCancelCombo
                  }}
                  viewConfiguration={viewConfiguration}
                  dossierType={dossierTypesConstant.stock}
                  activeTab={activeTab}
                  marginTop={statusHeaderHeight}
                  windowWidth={windowWidth}
                  commentUnreadCount={ unreadComments || comments.filter((comment) => { return !comment.read }).length}
                  changeUo={this.props.changeUo}
                  organizedUnitId={dossier.organizedUnitId}
                  readOnlyFields={isMarked}
                  type={dossierTypesConstant.stock}
                  showTimelineInTab={this.state.showTimelineInTab}
                />
              </div>
              <div class="dossierGridPart-ComponentSection">
                <Form autoComplete="off">
                  {// TIME LINE
                    activeTab === buttonsHeader.time_line && (
                      <div className="dossierGridPart-ComponentSection-Inner">
                        <div className="ComponentSection-InnerData">
                          <DossierTimeLineSectionPage
                            showTitlePage={true}
                            headerData={header}
                            stages={checkListHeader}
                            dossierType={dossierTypesConstant.sales}
                            dossierSubType={dossierSubTypeId}
                            dossierId={dossier.dossierId}
                            activeTab={activeTab}
                            headerTabConfiguration={this.state.headerTabConfiguration}
                            pristine={pristine}
                            goBack={this.goBack}
                            readOnlyFields={isMarked}
                            editableDoc={viewConfiguration ? getDocumentaryPermission(viewConfiguration) : true}
                            isSelfSupplyLoaded={this.state.isSelfSupplyLoaded}
                            handleToUpdateSelfSupply = {() => this.handleToUpdateSelfSupply()}
                          />
                        </div>
                      </div>
                    )}
                  {
                    activeTab === buttonsHeader.datos_expediente && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden && (
                      <div className="_nd dossierGridPart-ComponentSection-Inner">
                        <div className="ComponentSection-InnerMennu">
                          <ButtonsSidebar
                            t={t}
                            historical={false}
                            handleToUpdateComponent={this.handleToUpdateComponent.bind(this)}
                            actions={{
                              setActiveSection,
                              fetchStockDossierVehicle,
                              getDossierBudget
                            }}
                            dossierType={dossierTypesConstant.stock}
                            activeStage={activeStage}
                            marginTop={statusHeaderHeight}
                            buttonsHeaderHeight={buttonsHeaderHeight}
                            tabConfiguration={this.state.activeTabConfiguration}
                            dossierId={dossierId}
                            dossier={dossier}
                            pristine={pristine}
                            isVehicleLoaded={this.state.isVehicleLoaded}
                          />
                        </div>
                        <div className="ComponentSection-InnerData" id="dossierData" >
                          {this.state.componentsConfiguration.vehicle && this.state.componentsConfiguration.vehicle.permission > permissions.hidden && (
                            <Element name={buttonsSidebar.vehicle}>
                              <Vehicle
                                handleToUpdateComponent={this.handleToUpdateComponent.bind(this)}
                                isVehicleLoaded={this.state.isVehicleLoaded}
                                pristine={pristine}
                                dossierType={dossierTypesConstant.stock}
                                t={t}
                                dossierId={dossierId}
                                searchedVehicleProps={vehicleProps}
                                vehicleSelectionModal = {vehicleSelectionModal}
                                dossierParentId={dossierParentId}
                                dossierWithVehicle={!!dossier?.vehicleComponent?.vehicleId}
                                combos={combos}
                                actions={{
                                  searchVehicle,
                                  setSearchDealerNumber,
                                  setSearchVinChassis,
                                  setSearchLicensePlate,
                                  resetSearchFields,
                                  setSimpleInputTextValidation,
                                  clearVehicle,
                                  fetchComboOrigin,
                                  fetchComboDestination,
                                  fetchStockDossierVehicle,
                                  fecthFamily,
                                  setActiveSection,
                                  closeVehicleSelectionModal,
                                  fetchVehicleSelectionModal,
                                  selectVehicle: (vehicle) => this.setSelectedVehicle(vehicle)
                                }}
                                open={activeSection === buttonsSidebar.vehicle}
                                dossierSubType={dossierSubTypeId}
                                formValues={formValues ? formValues.vehicleComponent : {}}
                                componentConfiguration={this.state.componentsConfiguration.vehicle}
                                organizedUnitId={dossier.organizedUnitId}
                                readOnlyFields={!stockDossierSavePermission}
                                parentDossierType={{
                                  isPaperwork: false,
                                  isSales: false,
                                  isPurchase: false,
                                  isCampaign: false,
                                  isRegistrationDelivery: false,
                                  isStock: true
                                }}
                                showTopFirstLicensePlateDateAndAvailabilityDate = {true}
                              />
                            </Element>

                          )}
                          {this.state.componentsConfiguration.entry && this.state.componentsConfiguration.entry.permission > permissions.hidden && (
                            <Element name={buttonsSidebar.entry}>
                              <Entry
                                t={t}
                                open={activeSection === buttonsSidebar.entry}
                                componentConfiguration={this.state.componentsConfiguration.entry}
                                dossier={dossier}
                                dossierId={dossierId}
                                actions={{
                                  confirmDateWithIdByStage,
                                  setActiveSection,
                                  changeRelationEntryValues
                                }}
                                combos={combos}
                                change={change}
                              />
                            </Element>
                          )}
                          {this.state.componentsConfiguration.reception && this.state.componentsConfiguration.reception.permission > permissions.hidden && (
                            <Element name={buttonsSidebar.reception}>
                              <Reception
                                pristine={pristine}
                                dossierType={dossierTypesConstant.stock}
                                t={t}
                                dossierId={dossierId}
                                actions={{
                                  setActiveSection,
                                  confirmDateWithIdByStage
                                }}
                                open={activeSection === buttonsSidebar.reception}
                                dossierSubType={dossierSubTypeId}
                                formValues={formValues ? formValues.reception : {}}
                                componentConfiguration={this.state.componentsConfiguration.reception}
                                organizedUnitId={dossier.organizedUnitId}
                                parentDossierType={{
                                  isPaperwork: false,
                                  isSales: false,
                                  isPurchase: true,
                                  isCampaign: false,
                                  isRegistrationDelivery: false,
                                  isStock: false
                                }}
                                dossier={dossier}
                              />
                            </Element>
                          )}
                          {(
                            <Element name={buttonsSidebar.dossier_rating}>
                              <DossierRating
                                pristine={pristine}
                                t={t}
                                historical={false}
                                dossierId={dossierId}
                                combos={combos}
                                componentConfiguration={this.state.componentsConfiguration.dossier_rating}
                                open={activeSection === buttonsSidebar.dossier_rating}
                                dossier={dossier}
                                dossierRatingComponent={ dossierRatingComponent}
                                handleSubmit={handleSubmit}
                                actions={{
                                  deleteDossierRating,
                                  addDossierRating,
                                  change,
                                  setActiveSection,
                                  confirmDateWithIdByStage
                                }}
                              />
                            </Element>
                          )}
                          {this.state.componentsConfiguration.dossierBudget && this.state.componentsConfiguration.dossierBudget.permission > permissions.hidden && (
                            <Element name={buttonsSidebar.dossierBudget}>
                              <DossierBudget
                                pristine={pristine}
                                dossierType={dossierTypesConstant.stock}
                                t={t}
                                dossierId={dossierId}
                                actions={{
                                  setActiveSection,
                                  confirmDateWithIdByStage,
                                  getDossierBudget,
                                  fetchDossierBudgetRating,
                                  deleteDossierBudgetRating,
                                  rejectDossierStockVoBudget
                                }}
                                open={activeSection === buttonsSidebar.dossierBudget}
                                dossierSubType={dossierSubTypeId}
                                formValues={formValues ? formValues.dossierBudget : {}}
                                componentConfiguration={this.state.componentsConfiguration.dossierBudget}
                                organizedUnitId={dossier.organizedUnitId}
                                dossier={dossier}
                                isHistorical={false}
                                handleToUpdateComponent={this.handleToUpdateComponent.bind(this)}
                                isBudgetLoaded={this.state.isBudgetLoaded}
                              />
                            </Element>
                          )}
                          {this.state.componentsConfiguration.reconditioning && this.state.componentsConfiguration.reconditioning.permission > permissions.hidden && (
                            <Element name={buttonsSidebar.reconditioning}>
                              <Reconditioning
                                t={t}
                                open={activeSection === buttonsSidebar.reconditioning}
                                componentConfiguration={this.state.componentsConfiguration.reconditioning}
                                dossier={dossier}
                                dossierId={dossierId}
                                actions={{
                                  confirmDateWithIdByStage,
                                  setActiveSection
                                }}
                              />
                            </Element>
                          )}
                          {this.state.componentsConfiguration.cleaning && this.state.componentsConfiguration.cleaning.permission > permissions.hidden && (
                            <Element name={buttonsSidebar.cleaning}>
                              <Cleaning
                                dossierType={dossierTypesConstant.stock}
                                t={t}
                                dossierId={dossierId}
                                open={activeSection === buttonsSidebar.cleaning}
                                componentConfiguration={this.state.componentsConfiguration.cleaning}
                                dossier={dossier}
                                actions={{
                                  confirmDateWithIdByStage,
                                  setActiveSection
                                }}
                              />
                            </Element>
                          )}
                          {this.state.componentsConfiguration.checklist && this.state.componentsConfiguration.checklist.permission > permissions.hidden && (
                            <Element name={buttonsSidebar.checklist}>
                              <Checklist
                                dossierType={dossierTypesConstant.stock}
                                t={t}
                                dossierId={dossierId}
                                open={activeSection === buttonsSidebar.checklist}
                                componentConfiguration={this.state.componentsConfiguration.checklist}
                                dossier={dossier}
                                actions={{
                                  confirmDateWithIdByStage,
                                  setActiveSection
                                }}
                              />
                            </Element>
                          )}
                          {this.state.componentsConfiguration.photographs && this.state.componentsConfiguration.photographs.permission > permissions.hidden && (
                            <Element name={buttonsSidebar.photographs}>
                              <Photographs
                                dossierType={dossierTypesConstant.stock}
                                dossierPhotosComponent={dossierPhotosComponent?.dossierPhotosComponent}
                                t={t}
                                dossierId={dossierId}
                                open={activeSection === buttonsSidebar.photographs}
                                componentConfiguration={this.state.componentsConfiguration.photographs}
                                dossier={dossier}
                                actions={{
                                  confirmDateWithIdByStage,
                                  setActiveSection,
                                  getDossierPhoto,
                                  openDocumentCreationModal,
                                  openDocumentEditionModal,
                                  confirmPhotos,
                                  cancelDocument,
                                  skipDossierPhotos
                                }}
                              />
                            </Element>
                          )}
                          {this.state.componentsConfiguration.crane && this.state.componentsConfiguration.crane.permission > permissions.hidden && (
                            <Element name={buttonsSidebar.crane}>
                              <Crane
                                pristine={pristine}
                                t={t}
                                open={activeSection === buttonsSidebar.crane}
                                componentConfiguration={this.state.componentsConfiguration.crane}
                                dossier={dossier}
                                dossierId={dossierId}
                                combos={combos}
                                actions={{
                                  dossierCraneConfirmDate,
                                  setActiveSection,
                                  activateDossierCrane,
                                  deactivateDossierCrane
                                }} />
                            </Element>
                          )}
                          {this.state.componentsConfiguration.price_change && this.state.componentsConfiguration.price_change.permission > permissions.hidden && (
                            <Element name={buttonsSidebar.price_change}>
                              <PriceChange
                                t={t}
                                open={activeSection === buttonsSidebar.price_change}
                                componentConfiguration={this.state.componentsConfiguration.price_change}
                                dossier={dossier}
                                actions={{
                                  setActiveSection
                                }} />
                            </Element>
                          )}
                          {this.state.componentsConfiguration.booking_and_delivery && this.state.componentsConfiguration.booking_and_delivery.permission > permissions.hidden && (
                            <Element name={buttonsSidebar.booking_and_delivery}>
                              <BookingAndDelivery
                                t={t}
                                open={activeSection === buttonsSidebar.booking_and_delivery}
                                componentConfiguration={this.state.componentsConfiguration.booking_and_delivery}
                                dossierId={dossierId}
                                dossier={dossier}
                                actions={{
                                  confirmDateWithIdByStage,
                                  setActiveSection
                                }} />
                            </Element>
                          )}
                        </div>
                      </div>
                    )}
                  {
                    activeTab === buttonsHeader.gestor_documental && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden && (
                      <Row className="stock-panel dossier-panel">
                        <Col sm={12} className="col-row" style={{ paddingTop: panelsPaddingTop }}>
                          <DocumentaryManagementPage
                            dossier={dossier}
                            dossierType={dossierTypesConstant.stock}
                            formState={this.props.pristine}
                            configuration={this.state.activeTabConfiguration}
                            readOnlyFields={isMarked}
                          />
                        </Col>
                      </Row>
                    )}
                  {
                    activeTab === buttonsHeader.expedientes_relacionados && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden && (
                      <div className="dossierGridPart-ComponentSection-Inner related-dossiers-table">
                        <div className="ComponentSection-InnerData">
                          <RelatedDossiers
                            t={t}
                            relatedDossiersOrderBy = {this.props.relatedDossiersOrderBy}
                            panelsPaddingTop = {panelsPaddingTop}
                            history = {this.props.history}
                            dossierRelatedDossiers = {dossierRelatedDossiers}
                            dossierId= {dossierId}
                            actions={{ fetchFilteredRelatedDossier }}
                          />
                        </div>
                      </div>
                    )}
                  {
                    activeTab === buttonsHeader.actividad_expediente && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden && (
                      <div className="dossierGridPart-ComponentSection-Inner auditInfo">
                        <div sm={12} className="ComponentSection-InnerData">
                          <AuditInfo
                            auditInfo={auditInfo}
                            dossierType={dossierTypesConstant.stock}
                            actions={{ refreshAuditInfo, fetchAuditDetails, fetchAuditDetailsModal, fetchDocumentsGroupedAuditDetails, fetchDocumentsGroupedAuditDetailsModal }}
                            dossier={dossier}
                            t={t}
                            readOnlyFields={isMarked}
                          />
                        </div>
                      </div>
                    )}
                  {
                    activeTab === buttonsHeader.observaciones && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden && (
                      <div className="dossierGridPart-ComponentSection-Inner commentComponent">
                        <div className="ComponentSection-InnerData">
                          <CommentComponent
                            comments={comments}
                            actions={{ updateComment, createComment, deleteComment, markCommentAsRead }}
                            readOnlyFields={this.state.activeTabConfiguration.permission !== permissions.editable || isMarked}
                            dossierType={dossierTypesConstant.stock}
                            t={t}
                            tKey={tKey}
                            combos={{ allReasonCombo: this.props.combos.allReasonCombo }}
                            openComponent={activeTab === buttonsHeader.observaciones}
                            dossierId={this.props.dossier.dossierId}
                          />
                        </div>
                      </div>
                    )}
                </Form>
              </div>
            </div>
          </div>
          <div className='modalsContainer'>
            <DocumentaryManagementModalPage showRelatedDocuments={false} dossier={dossier} dossierType={dossierSubTypeId} readOnlyFields={isMarked} />
            <DossierBudgetRatingModalPage
              organizedUnitId={organizedUnitId}
            />
          </div>
        </div>

        <div className="dossier-stock-container hide">

          <Form autoComplete="off">

          </Form>
        </div>
      </>
    )
  }
}

const validate = (values, props) => {
  const error = {}
  return error
}

var decoratedComponent = TrackingContainer(StockDossier, false)
export default reduxForm({
  form: 'stock_dossier',
  validate,
  destroyOnUnmount: false
})(decoratedComponent)
