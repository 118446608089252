import apiFetch from '../apiFetch'

export default function (documentId, organizedUnitId, dossierId, token, file, lastAccessDate) {
  const queryParamsArray = [
    organizedUnitId ? `organizedUnitId=${organizedUnitId}` : null,
    dossierId ? `dossierId=${dossierId}` : null
  ]
  const formData = new FormData()
  formData.append(file.name, file)
  const queryParams = queryParamsArray.reduce(
    (result, query, idx) => result + (query ? (result ? `&${query}` : `?${query}`) : '')
    , ''
  )
  return apiFetch({ endPoint: `document/${documentId}/File${queryParams}`, method: 'PUT', body: formData, token: token, fileBody: true, lastAccessDate })
}
