import templatesActionTypes from '../../constants/actions/templates/templates'

function initialState () {
  return {
    templateList: [],
    pagesCount: 0,
    rolesCount: 0,
    filter: {
      page: 1,
      orderBy: '',
      pageSize: 10,
      templateTypeId: null
    },
    templateModal: {
      showModal: false,
      templateId: null,
      templateData: {
        typeField: null,
        fieldId: null,
        name: '',
        isMandatory: false,
        fields: []
      },
      isNew: false
    }
  }
}

function fetchTemplateListSuccess (state, { templateList, filter }) {
  return {
    ...state,
    templateList: templateList,
    templatesCount: templateList.length,
    pagesCount: 1,
    filter: { ...state.filter, ...filter, pageSize: templateList.length }
  }
}

function openEditTemplateModalSuccess (state, { templateId, templateData, isNew }) {
  return {
    ...state,
    templateModal: {
      ...state.templateModal,
      showModal: true,
      templateId,
      templateData,
      isNew
    }
  }
}

function closeEditTemplateModalSuccess (state) {
  return {
    ...state,
    templateModal: {
      ...state.templateModal,
      showModal: false
    }
  }
}

function resetTemplate (state) {
  return initialState()
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case templatesActionTypes.FETCH_TEMPLATE_LIST_SUCCESS:
      return fetchTemplateListSuccess(state, action)
    case templatesActionTypes.OPEN_EDIT_TEMPLATE_MODAL_SUCCESS:
      return openEditTemplateModalSuccess(state, action)
    case templatesActionTypes.CLOSE_EDIT_TEMPLATE_MODAL_SUCCESS:
      return closeEditTemplateModalSuccess(state, action)
    case templatesActionTypes.RESET_TEMPLATE:
      return resetTemplate(state, action)
    default:
      return state
  }
}
