import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getFormValues } from 'redux-form'
import { translate } from 'react-polyglot'
import { fetchRatingCombo, fetchRatingComboByOrganizedUnit } from '../../../actions/combos/combos'
import DossierBudgetRatingModal from '../../../components/dossiers/stock/DossierBudgetRatingModal'
import { addEditDossierBudgetRating, fetchDossierBudgetRating } from '../../../actions/dossier/stockDossier'

export function mapStateToProps (state) {
  return {
    showModal: state.stockDossier.showDossierBudgetRatingModal,
    dossierBudgetRatingId: state.stockDossier.dossierBudgetRatingId,
    combos: {
      ratingComboByOrganizedUnit: state.combos.ratingComboByOrganizedUnit
    },
    formValues: getFormValues('budgetRatingModal')(state)
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchRatingCombo,
      fetchRatingComboByOrganizedUnit,
      addEditDossierBudgetRating,
      fetchDossierBudgetRating
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(DossierBudgetRatingModal))
