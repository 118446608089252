import { call, put, select, takeLatest } from 'redux-saga/effects'
import { getAuth } from '../../selectors/access/auth'
import { showLoader, hideLoader } from '../../actions/common'
import messageModalActions from '../../actions/modals/messageModal'
import { fetchCombosSuccess } from '../../actions/combos/combos'
import combosActionTypes from '../../constants/actions/combos/combos'
import { handleError } from '../errors/errorManager'
import getOperationTypeDMSAll from '../../services/operationTypeDMS/getOperationTypeDMSAll'

export function * fetchOperationTypeDMS () {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const operationTypeDMSCombo = yield call(getOperationTypeDMSAll, auth.token)
    yield put(fetchCombosSuccess({ operationTypeDMSCombo }))
  } catch (error) {
    const customHandler = function * () {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.FETCH_COMBO_ERROR'))
    }

    yield call(handleError, { error, customHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchfetchOperationTypeDMS () {
  yield takeLatest(combosActionTypes.FETCH_OPERATION_TYPE_DMS_ALL, fetchOperationTypeDMS)
}
