import { put, call, select, takeEvery } from 'redux-saga/effects'
import { getAuth } from '../../selectors/access/auth'
import { getReceiptFilter } from '../../selectors/reports/receiptReport'
import receiptActionTypes from '../../constants/actions/reports/receiptReport'
import { fetchReceiptReportSuccess } from '../../actions/reports/receiptReport'
import { showLoader, hideLoader } from '../../actions/common.js'
import postReceiptListService from '../../services/reports/postReceiptList'
import { handleError } from '../errors/errorManager'
import { fetchCombosSuccess } from '../../actions/combos/combos'
import getSalesmanUserHierarchy from '../../services/salesman/getSalesmanUserHierarchy'
import getLowestOrganizedUnits from '../../services/organizedUnit/getOrganizedUnits'
import getDownloadReceiptList from '../../services/reports/getDownloadReceiptList'

export function * fetchReceiptList ({ filter }) {
  try {
    yield put(showLoader())
    yield filter = yield filter || select(getReceiptFilter)
    const auth = yield select(getAuth)
    const receiptList = yield call(postReceiptListService, auth.token, filter)
    const salesmanCombo = yield call(getSalesmanUserHierarchy, auth.token)
    const lowestOrganizedUnitsCombo = yield call(getLowestOrganizedUnits, auth.token)
    yield put(fetchCombosSuccess({
      salesmanCombo,
      lowestOrganizedUnitsCombo
    }))
    yield put(fetchReceiptReportSuccess(receiptList, filter))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchReceiptList () {
  yield takeEvery(receiptActionTypes.FETCH_RECEIPT_REPORT, fetchReceiptList)
}

export function * downloadReceiptList ({ filter }) {
  try {
    yield put(showLoader())
    yield filter = yield filter || select(getReceiptFilter)
    const auth = yield select(getAuth)
    yield call(getDownloadReceiptList, auth.token, filter)
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDownloadReceiptList () {
  yield takeEvery(receiptActionTypes.DOWNLOAD_RECEIPT_REPORT, downloadReceiptList)
}
