import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
// import { goBack } from 'react-router-redux'
import { refreshVehicleComponent } from '../../../../actions/dossier/common/vehicle'
import { fetchClientFromDocument } from '../../../../actions/dossier/common/client'
import { updateDossierCommon } from '../../../../actions/dossier/commonDossierActions'
import { openDocumentEditionModal } from '../../../../actions/dossier/common/documentEditionModal'
import { openDocumentEditionModal as openDocumentEditionModalHistorical } from '../../../../actions/dossier/common/documentHistoricalEditionModal'
import { refreshDocumentManagementModal } from '../../../../actions/dossier/common/documentManagementModal'
import statusHeaderActions from '../../../../actions/dossier/common/statusHeader'
import buttonsHeaderActions from '../../../../actions/dossier/common/buttonsHeader'
import buttonsSidebarActions from '../../../../actions/dossier/common/buttonsSidebar'
import { openModal, openDocWarning } from '../../../../actions/common'
import DossierTimeLineSection from '../../../components/dossiers/common/DossierTimeLineSection'
import { fetchSalesVehicle, fetchSalesClient, fetchSalesFinancing, fetchSalesDelivery, fetchSalesSelfSupply } from '../../../../actions/dossier/sales'
import { fetchPurchaseClient, fetchPurchaseVehicle } from '../../../../actions/dossier/purchase'
import { fetchPaperworkClient, fetchPaperworkVehicle } from '../../../../actions/dossier/paperwork'
import { fetchCampaignClient, fetchCampaignVehicle } from '../../../../actions/dossier/campaign'
import { fetchRegistrationDeliveryDelivery } from '../../../../actions/dossier/registrationDelivery'
import scrollerActions from '../../../../actions/commons/scroller'

export function mapStateToProps (state) {
  return {
    isFolded: state.statusHeader.isFolded,
    windowWidth: state.windowDimensions.width,
    defaultSalesmen: state.auth.defaultSalesmen,
    favorite: state.statusHeader.favorite
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchClientFromDocument,
      refreshVehicleComponent,
      fetchSalesVehicle,
      fetchSalesClient,
      fetchPurchaseClient,
      fetchPurchaseVehicle,
      fetchSalesFinancing,
      fetchSalesDelivery,
      fetchRegistrationDeliveryDelivery,
      fetchPaperworkClient,
      fetchCampaignClient,
      fetchCampaignVehicle,
      fetchPaperworkVehicle,
      updateDossierCommon,
      openDocumentEditionModal,
      refreshDocumentManagementModal,
      ...statusHeaderActions,
      ...buttonsHeaderActions,
      ...buttonsSidebarActions,
      openModal,
      openDocWarning,
      openDocumentEditionModalHistorical,
      fetchSalesSelfSupply,
      ...scrollerActions
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(DossierTimeLineSection))
