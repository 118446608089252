import React, { PureComponent } from 'react'
import { Button, Modal, Col, Row, FormGroup, Tooltip, OverlayTrigger, Glyphicon } from 'react-bootstrap'
import TextInput from '../../commons/TextInput'
import InputSelect from '../../commons/form/InputSelect'
import InputTreeSelect from '../../commons/form/InputTreeSelect'
import { reduxForm, Field } from 'redux-form'
import { isEmail, isValidPhone } from '../../../util/validationFunctions'
import InputPassword from '../../commons/form/InputPassword'
import InputCheckBox from '../../commons/form/InputCheckBox'
import { permissions } from '../../../constants/backendIds'
import { getSectionPermissions } from '../../../util/permisionFunctions'
import { sectionPermissions } from '../../../constants/dossier/common/buttonsHeader'
import UploadSignPage from '../../../containers/admin/user/UploadModalPage'

class UserModal extends PureComponent {
  constructor () {
    super()
    this.state = {
      editing: false,
      editingUO: false,
      selectedRole: null,
      selectedUo: null,
      roleList: [],
      uoList: [],
      uoListSalesman: [],
      showChangePassword: false,
      newPassword: null,
      repeatNewPassword: null,
      isOldUser: true,
      uoSalesman: [],
      UOsCanDelete: true
    }

    this.setActiveUoRol = this.setActiveUoRol.bind(this)
    this.setActiveUo = this.setActiveUo.bind(this)
    this.deletePermission = this.deletePermission.bind(this)
  }

  componentDidMount () {
    this.initializePermisions(this.props)
    this.props.actions.fetchPasswordPolicy()
    this.props.actions.fetchLanguagesCombo()
  }

  getSnapshotBeforeUpdate (prevProps) {
    this.setState({ isOldUser: this.props.userId !== null && this.props.userId !== undefined })
    if (prevProps.showModal !== this.props.showModal && this.props.showModal === true) {
      this.setState({ uoList: this.props.uoRoles, editing: false, selectedRole: null, selectedUo: null, uoSalesman: this.props.uoSalesman ? this.props.uoSalesman : { notOwnedUO: [], ownedUO: [] } })
      this.props.change('userRol.organizedUnit', null)
      this.props.change('userRol.role', null)
      this.props.change('userUO.organizedUnit', null)
    }
    return null
  }

  componentDidUpdate () {
    // getSnapshotBeforeUpdate() should be used with componentDidUpdate()
  }

  initializePermisions (props) {
    const section = props.sections.filter((tabConf) => tabConf.code === sectionPermissions.admin)
    if (section.length > 0 && section[0] != null) {
      const newState = getSectionPermissions(section[0])
      this.setState({ sectionTabsConfiguration: newState })
    }
  }

  setActiveUoRol (organizedUnitId) {
    if (organizedUnitId) {
      const uo = this.state.uoList.find(uoRol => uoRol.organizedUnitId === parseInt(organizedUnitId))
      this.setState({ roleList: uo && uo.notOwnedRoles ? uo.notOwnedRoles : [], selectedUo: organizedUnitId })
    } else {
      this.setState({ selectedUo: null })
    }
  }

  deleteRecoverSalesman (uo) {
    const uoSalesman = this.state.uoSalesman
    let uoToUpdate = uoSalesman.ownedUO.find(ownedUo => ownedUo.salesmanId === uo.salesmanId && ownedUo.uoId === uo.uoId)
    uoToUpdate.isDeleteSalesman = !uoToUpdate.isDeleteSalesman
    if (uoToUpdate.isDeleteSalesman) {
      this.props.actions.deleteSalesman(uoToUpdate.userId, uoToUpdate.salesmanId)
    } else {
      this.props.actions.recoverSalesman(uoToUpdate.userId, uoToUpdate.salesmanId)
    }
    this.setState({ uoSalesman })
  }

  setActiveUo (organizedUnitId) {
    if (organizedUnitId) {
      const UO = this.state.uoListSalesman.find(UOs => UOs.organizedUnitId === parseInt(organizedUnitId))
      this.setState({ uoListSalesman: UO && UO.notOwnedUO ? UO.notOwnedUO : [], selectedUo: organizedUnitId })
    } else {
      this.setState({ selectedUo: null })
    }
  }

  addUO (newUO) {
    const uoSalesman = this.state.uoSalesman
    const isAssigned = uoSalesman.ownedUO.find(val => val.uoId === newUO)
    if (!isAssigned) {
      const uoSalesmanCons = uoSalesman.notOwnedUO.find((val) => val.uoId === newUO)
      uoSalesman.notOwnedUO = uoSalesman.notOwnedUO.filter(val => val.uoId !== newUO)
      uoSalesman.ownedUO.push(uoSalesmanCons)
      this.setState({ uoSalesman })
    }
    this.setState({ editingUO: false })
  }

  deleteUO (oldUO) {
    let uoSalesman = Object.assign([], uoSalesman, this.state.uoSalesman)
    const uoSalesmanCons = uoSalesman.ownedUO.find((val) => val.uoId === oldUO)
    if (uoSalesmanCons) {
      uoSalesman.ownedUO = uoSalesman.ownedUO.filter(val => val.uoId !== oldUO)
      uoSalesman.notOwnedUO.push(uoSalesmanCons)
      this.setState({ uoSalesman })
    }
  }

  addPermission (newRole) {
    const uoRoles = this.state.uoList
    const uo = uoRoles.find(val => val.organizedUnitId === this.state.selectedUo)
    const isAssigned = uo.ownedRoles.find((val) => val.roleId === newRole)
    if (!isAssigned) {
      const role = uo.notOwnedRoles.find((val) => val.roleId === newRole)
      uo.notOwnedRoles = uo.notOwnedRoles.filter(val => val.roleId !== newRole)
      uo.ownedRoles.push(role)
      this.setState({ uoList: uoRoles, roleList: uo.notOwnedRoles })
      this.setState({ LastAdd: false })
      this.props.change('userRol.role', null)
    }
    this.setState({ editing: false })
  }

  deletePermission (oldRole, organizedUnitId) {
    let uoRoles = Object.assign([], uoRoles, this.state.uoList)
    const uo = uoRoles.find(val => val.organizedUnitId === organizedUnitId)
    const role = uo.ownedRoles.find((val) => val.roleId === oldRole)
    if (role) {
      uo.ownedRoles = uo.ownedRoles.filter(val => val.roleId !== oldRole)
      uo.notOwnedRoles.push(role)
      this.setState({ uoList: uoRoles, roleList: organizedUnitId === this.state.selectedUo ? uo.notOwnedRoles : this.state.roleList })
    }
  }

  submitUser (values) {
    const body = {
      ...values.user,
      uoRoles: this.state.uoList.map(uoRol => {
        return {
          organizedUnitId: uoRol.organizedUnitId,
          ownedRoles: uoRol.ownedRoles
        }
      }),
      uoSalesman: this.state.uoSalesman
        .ownedUO.map(uo => {
          return uo.uoId
        })
    }
    this.props.actions.submitUser(this.props.userId, body)
  }

  handleSavePassword () {
    this.props.actions.adminChangePassword(this.props.userId, this.state.newPassword, this.state.repeatNewPassword, this.props.emailAddress)
    this.setState({ showChangePassword: false, newPassword: null, repeatNewPassword: null })
    this.props.change('pwd.newPassword', null)
    this.props.change('pwd.pwdLoginRepeat', null)
  }

  uploadSign () {
    this.props.actions.openUploadSignModal()
  }

  downloadSign () {
    this.props.actions.downloadSignUser(this.props.userId)
  }

  validatePassword (newPassword) {
    if (newPassword && newPassword.length < this.props.passwordPolicy.minNumberOfCharactersInPassword) {
      return false
    }
    return true
  }

  render () {
    const {
      t, handleSubmit,
      showModal, /* uoRoles, */ userId,
      combos: { languagesCombo, UOTreeCombo }
    } = this.props

    const deleteTooltip = (<Tooltip id="deleteTooltip">{t('ADMIN.USERS.DELETE_BTN')}</Tooltip>)
    const saveTooltip = (<Tooltip id="saveTooltip">{t('ADMIN.USERS.SAVE_BTN')}</Tooltip>)
    const cancelTooltip = (<Tooltip id="cancelTooltip">{t('ADMIN.USERS.CANCEL_BTN')}</Tooltip>)
    const recoverTooltip = (<Tooltip id="recoverTooltip">{t('ADMIN.RECOVER')}</Tooltip>)

    return (
      <Modal className="role-modal languages-modal" show={showModal} onHide={this.props.actions.closeEditUserModal}>
        <form autoComplete="off" onSubmit={handleSubmit(this.submitUser.bind(this))}>
          <Modal.Header closeButton onHide={this.props.actions.closeEditUserModal}>
            <Modal.Title>{t('USER_MANAGEMENT.MODAL.TITLE')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <Row key={1} className="inputs-wrapper">
                <Col sm={4} className="custom-width-col">
                  <h2>{t('ADMIN.USERS.NAME')}</h2>
                  <Field
                    name="user.name"
                    placeholder={t('ADMIN.USERS.NAME')}
                    component={TextInput}
                  />
                </Col>
                <Col sm={4} className="custom-width-col">
                  <h2>{t('ADMIN.USERS.SURNAME')}</h2>
                  <Field
                    name="user.surnames"
                    placeholder={t('ADMIN.USERS.SURNAME')}
                    component={TextInput}
                  />
                </Col>
                <Col sm={4} className="custom-width-col">
                  <h2>{t('ADMIN.USERS.EMAIL')}</h2>
                  <Field
                    name="user.emailAddress"
                    placeholder={t('ADMIN.USERS.EMAIL')}
                    component={TextInput}
                  />
                </Col>
                <Col sm={4} className="custom-width-col">
                  <h2>{t('ADMIN.USERS.LANGUAJE')}</h2>
                  <Field
                    colSm={0}
                    id="languageId"
                    name="user.languageId"
                    placeholder={t('ADMIN.USERS.LANGUAJE')}
                    component={InputSelect}
                    options={languagesCombo}
                    valueKey="languageId"
                    labelKey="description"
                  />
                </Col>
                <Col sm={4} className="custom-width-col">
                  <h2>{t('ADMIN.USERS.DEPARTAMENT')}</h2>
                  <Field
                    name="user.department"
                    placeholder={t('ADMIN.USERS.DEPARTAMENT')}
                    component={TextInput}
                  />
                </Col>
                <Col sm={4} className="custom-width-col">
                  <h2>{t('ADMIN.USERS.POSITION')}</h2>
                  <Field
                    name="user.position"
                    placeholder={t('ADMIN.USERS.POSITION')}
                    component={TextInput}
                  />
                </Col>
                <Col sm={4} className="custom-width-col">
                  <h2>{t('ADMIN.USERS.CODE')}</h2>
                  <Field
                    name="user.code"
                    placeholder={t('ADMIN.USERS.CODE')}
                    component={TextInput}
                  />
                </Col>
                <Col sm={4} className="custom-width-col">
                  <h2>{t('ADMIN.USERS.PHONE')}</h2>
                  <Field
                    name="user.phone"
                    placeholder={t('ADMIN.USERS.PHONE')}
                    component={TextInput}
                  />
                </Col>
                <Col sm={4} className="custom-width-col">
                  <h2>{t('ADMIN.USERS.SHOW_UO')}</h2>
                  <Field
                    name="user.organizedUnit"
                    placeholder={t('ADMIN.USERS.SHOW_UO')}
                    component={InputTreeSelect}
                    multi={true}
                    valueKey="value"
                    labelKey="label"
                    childrenKey="children"
                    popupContainer=".role-modal"
                    options={UOTreeCombo}
                    colSm={0}
                  />
                </Col>
                {userId === null &&
                <Col sm={4} className="custom-width-col">
                  <Field
                    name="user.sendEmailFlag"
                    placeholder={t('ADMIN.USERS.SEND_PASS')}
                    component={InputCheckBox}
                    colSm={0}
                  />
                </Col>
                }
                <Col sm={4} className="custom-width-col">
                  <h2>{t('ADMIN.MAX_INSTALLATION')}</h2>
                  <Field
                    name="user.maxMobileInstalls"
                    placeholder={t('ADMIN.MAX_INSTALLATION')}
                    component={TextInput}
                  />
                </Col>
                {this.state.isOldUser &&
                <Col sm={4} className="custom-width-col">
                  <h2>{t('ADMIN.USERS.UPLOAD_SIGN.UPLOAD_SIGN_USER')}</h2>
                  <Button className="btn-standard" onClick={() => this.uploadSign()}>+ {t('ADMIN.USERS.UPLOAD_SIGN.UPLOAD_SIGN_USER')}</Button>
                </Col>}
                {this.state.isOldUser &&
                <Col sm={4} className="custom-width-col">
                  <h2>{t('ADMIN.USERS.UPLOAD_SIGN.DOWNLOAD_SIGN_USER')}</h2>
                  <Button className="btn-standard" onClick={() => this.downloadSign()}>{t('ADMIN.USERS.UPLOAD_SIGN.DOWNLOAD_SIGN_USER')}</Button>
                </Col>}
              </Row>
            </FormGroup>
            {this.state.isOldUser &&
            <Row className="selectors">
              {!this.state.showChangePassword &&
              <Col sm={12} className="custom-width-col">
                <div className="text-right">
                  <Button className="btn-standard" onClick={() => this.setState({ showChangePassword: !this.state.showChangePassword })}>{t('ADMIN.USERS.CHANGE_PASSWORD')}</Button>
                </div>
              </Col>}
              {this.state.showChangePassword &&
              <Col sm={12}>
                <Row>
                  <Field
                    t={t}
                    colSm={4}
                    id='newPassword'
                    name='pwd.newPassword'
                    placeholder={t('PROFILE.NEW_PASSWORD')}
                    controlLabel={t('PROFILE.NEW_PASSWORD')}
                    component={InputPassword}
                    onInputChange={(val) => this.setState({ newPassword: val.target.value })}
                  />
                  <Field
                    t={t}
                    colSm={4}
                    id='pwdLoginRepeat'
                    name='pwd.pwdLoginRepeat'
                    placeholder={t('PROFILE.REPEAT_PASSWORD')}
                    controlLabel={t('PROFILE.REPEAT_PASSWORD')}
                    component={InputPassword}
                    onChange={(val) => this.setState({ repeatNewPassword: val.target.value })}
                  />
                  <Col sm={4} className="custom-width-col">
                    <div className="buttons-wrapper text-center center-vertically user-modal-change-password">
                      { this.state.newPassword && this.state.repeatNewPassword && this.state.repeatNewPassword !== '' && this.state.newPassword !== '' &&
                      this.validatePassword(this.state.newPassword) &&
                      <Button className="btn-standard" onClick={() => this.handleSavePassword()}>{t('ADMIN.USERS.SAVE_PASSWORD')}</Button>}
                      <Button className="btn-standard" onClick={() => this.setState({ showChangePassword: !this.state.showChangePassword })}>{t('ADMIN.USERS.CANCEL_BTN')}</Button>
                    </div>
                  </Col>
                </Row>
              </Col>}
              <Col sm={12}>
                <br></br>
              </Col>
            </Row>}
            <Row className="selectors">
              <Col sm={6}>
                <Col sm={12}>
                  {this.state.editing ? (
                    <Row>
                      <Field
                        name="userRol.organizedUnit"
                        placeholder={t('ADMIN.USERS.UO')}
                        component={InputSelect}
                        valueKey="organizedUnitId"
                        labelKey="name"
                        popupContainer=".role-modal"
                        options={this.state.uoList}
                        colSm={12}
                        onInputChange={(value) => this.setActiveUoRol(value)}
                      />
                      <Field
                        colSm={12}
                        name="userRol.role"
                        placeholder={t('ADMIN.USERS.ROL')}
                        component={InputSelect}
                        options={this.state.roleList}
                        valueKey="roleId"
                        labelKey="description"
                        disabled={!this.state.roleList || this.state.roleList.length === 0}
                        onInputChange={(value) => this.setState({ selectedRole: value })}
                      />
                      <Col sm={12}>
                        <div className="buttons-wrapper center-vertically text-center">
                          {this.state.selectedRole &&
                        <OverlayTrigger placement="left" overlay={saveTooltip}>
                          <a onClick={() => this.addPermission(this.state.selectedRole)} id="ico-check"><i className="ico-check"/></a>
                        </OverlayTrigger>}
                          <OverlayTrigger placement="left" overlay={cancelTooltip}>
                            <a onClick={() => this.setState({ editing: false })} id="ico-cancel"><i className="ico-cancel"/></a>
                          </OverlayTrigger>
                        </div>
                      </Col>
                      <Col sm={12}>
                        <br/>
                      </Col>
                    </Row>
                  ) : (
                    <Button className="btn-standard" onClick={() => this.setState({ editing: true })}>+ {t('ADMIN.USERS.ADD_BTN')}</Button>
                  )}
                </Col>
                <Col sm={12}>
                  <h2>{t('USER_MANAGEMENT.MODAL.ASSIGNED_ROLES')}</h2>
                </Col>
                <Col sm={12}>
                  <ul className="role-tree" id="role-modal-tree-list">
                    {this.state.uoList && this.state.uoList.length > 0 && this.state.uoList.filter(uoRol => uoRol.ownedRoles.length > 0).map(uo => {
                      return (
                        <li key={uo.organizedUnitId}>
                          {uo.name}
                          {uo.ownedRoles.length > 0 && (
                            <ul>
                              {uo.ownedRoles.map(rol => {
                                return <li key={rol.roleId}>
                                  {rol.description}&nbsp;
                                  <a onClick={() => this.deletePermission(rol.roleId, uo.organizedUnitId)}>
                                    <OverlayTrigger placement="left" overlay={deleteTooltip}>
                                      <i className="ico-trash"/>
                                    </OverlayTrigger>
                                  </a>
                                </li>
                              })}
                            </ul>
                          )}
                        </li>
                      )
                    })}
                  </ul>
                </Col>
              </Col>
              {this.state.sectionTabsConfiguration && (this.state.sectionTabsConfiguration.salesmanUo === undefined || (this.state.sectionTabsConfiguration.salesmanUo.permission > permissions.hidden)) && (
                <>
                  <Col sm={6}>
                    <Col sm={12}>
                      {this.state.editingUO === false &&
                    <Button className="btn-standard" onClick={() => this.setState({ editingUO: true })}>+ {t('ADMIN.USERS.ADD_BTN')}</Button>
                      }
                      {this.state.editingUO &&
                    <Row>
                      <Field
                        name="userUO.organizedUnit"
                        placeholder={t('ADMIN.USERS.UO')}
                        component={InputSelect}
                        valueKey="uoId"
                        labelKey="name"
                        popupContainer=".role-modal"
                        options={this.state.uoSalesman.notOwnedUO}
                        colSm={12}
                        onInputChange={(value) => this.setActiveUo(value)}
                      />
                      <Col sm={12}>
                        <div className="buttons-wrapper center-vertically text-center">
                          {this.state.selectedUo &&
                          <OverlayTrigger placement="left" overlay={saveTooltip}>
                            <a onClick={() => this.addUO(this.state.selectedUo)}><i className="ico-check"/></a>
                          </OverlayTrigger>}

                          <OverlayTrigger placement="left" overlay={cancelTooltip}>
                            <a onClick={() => this.setState({ editingUO: false })}><i className="ico-cancel"/></a>
                          </OverlayTrigger>
                        </div>
                      </Col>
                      <Col sm={12}>
                        <br/>
                      </Col>
                    </Row>
                      }
                    </Col>
                    <Col sm={12}>
                      <h2>{t('USER_MANAGEMENT.MODAL.ASSIGNED_UO')}</h2>
                    </Col>
                    <Col sm={12}>
                      <ul className="role-tree" id="role-modal-tree-list">
                        {this.state.uoSalesman && this.state.uoSalesman.ownedUO && this.state.uoSalesman.ownedUO.map((uo, idx) => {
                          return (
                            <li key={idx}>
                              {uo.name}&nbsp;
                              <a onClick={() => this.deleteUO(uo.uoId)}>
                                {uo.notDelete === false &&
                                  <OverlayTrigger placement="left" overlay={deleteTooltip}>
                                    <i onClick={() => this.setState({ lastAdd: false })} className="ico-trash"/>
                                  </OverlayTrigger>
                                }
                              </a>
                              {uo.notDelete !== false &&
                                <a onClick={() => this.deleteRecoverSalesman(uo)}>
                                  <OverlayTrigger placement="left" overlay={uo.isDeleteSalesman ? recoverTooltip : deleteTooltip}>
                                    { uo.isDeleteSalesman ? (<Glyphicon glyph="repeat"/>) : (<i className="ico-trash"/>) }
                                  </OverlayTrigger>
                                </a>
                              }
                            </li>
                          )
                        })}
                      </ul>
                    </Col>
                  </Col></>)}
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Col sm={12} className="btn-wrapper">
              <Button
                id="role-modal-submit-button"
                className="btn-standard"
                type="submit"
                disabled={this.props.pristine}
              >
                {t('ROLE_MANAGEMENT.NEW_ROL_MODAL.SUBMIT_BUTTON')}
              </Button>
            </Col>
          </Modal.Footer>
        </form>
        <UploadSignPage />
      </Modal>
    )
  }
}

const validate = (values, props) => {
  const error = { user: {}, pwd: {} }
  if (values.user) {
    if (!values.user.name) {
      error.user.name = props.t('ADMIN.USERS.VALIDATION.NAME_REQUIRED')
    }
    if (!values.user.surnames) {
      error.user.surnames = props.t('ADMIN.USERS.VALIDATION.SURNAME_REQUIRED')
    }
    if (!values.user.emailAddress) {
      error.user.emailAddress = props.t('ADMIN.USERS.VALIDATION.EMAIL_REQUIRED')
    }
    if (!values.user.languageId) {
      error.user.languageId = props.t('ADMIN.USERS.VALIDATION.LANGUAJE_REQUIRED')
    }
    if (!isEmail(values.user.emailAddress)) {
      error.user.emailAddress = props.t('ADMIN.USERS.VALIDATION.VALID_EMAIL')
    }
    if (!isValidPhone(values.user.phone)) {
      error.user.phone = props.t('ADMIN.USERS.VALIDATION.VALID_PHONE')
    }
  } else {
    error.user.name = props.t('ADMIN.USERS.VALIDATION.NAME_REQUIRED')
    error.user.surnames = props.t('ADMIN.USERS.VALIDATION.SURNAME_REQUIRED')
    error.user.emailAddress = props.t('ADMIN.USERS.VALIDATION.EMAIL_REQUIRED')
    error.user.languageId = props.t('ADMIN.USERS.VALIDATION.LANGUAJE_REQUIRED')
  }
  if (values.pwd && values.pwd.newPassword && values.pwd.newPassword.length < props.passwordPolicy.minNumberOfCharactersInPassword) {
    error.pwd.newPassword = props.t('RESET_PASSWORD.MUST_CONTAIN_X_CHARACTERS').replace('##NUMBER##', props.passwordPolicy.minNumberOfCharactersInPassword)
  }

  return Object.keys(error.user).length > 0 || Object.keys(error.pwd).length > 0 ? error : {}
}

export default reduxForm({
  form: 'editUser',
  validate
})(UserModal)
