import documentManagementGroupCreateModal from '../../../constants/actions/dossier/common/documentManagementGroupCreateModal'

export function groupDocuments (groupedDocumentsIds, documentName, organizedUnitId, dossierId, okAction) {
  return {
    type: documentManagementGroupCreateModal.GROUP_FILES,
    groupedDocumentsIds,
    documentName,
    organizedUnitId,
    dossierId,
    okAction
  }
}

export default {
  groupDocuments
}
