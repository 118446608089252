import React, { PureComponent } from 'react'
import { Checkbox } from 'react-bootstrap'

class DeliveryChecklistRow extends PureComponent {
  render () {
    const {
      checkListItem: {
        name, type, deliverable
      }
    } = this.props
    return (
      <tr>
        <td>{name}</td>
        <td>{type}</td>
        <td><span><Checkbox className="checkbox-no-label" checked={deliverable} disabled inline /></span></td>
      </tr>
    )
  }
}
export default DeliveryChecklistRow
