import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { fetchDossierSubTypes, fetchAuditConfigurationType, fetchDossierTypes } from '../../../actions/combos/combos'
import { initializeSearch, resetFilters } from '../../../actions/search/search'
import { openModal, yesNoModal } from '../../../actions/common'
import { saveAuditAdmin, fetchAuditAdminInfo, closeAuditAdminModal, fetchAuditConfigurationDocument } from '../../../actions/management/management'
import AuditModal from '../../../components/management/AuxModals/AuditModal'

export function mapStateToProps (state) {
  const auditAdminModal = state.management.auditAdminModal
  return {
    showModal: auditAdminModal.showModal,
    auditAdmin: auditAdminModal.auditAdmin,
    ...state.management,
    ...state.formData,
    combos: {
      ...state.combos
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchDossierSubTypes,
      fetchAuditConfigurationType,
      fetchDossierTypes,
      initializeSearch,
      resetFilters,
      openModal,
      yesNoModal,
      fetchAuditAdminInfo,
      closeAuditAdminModal,
      saveAuditAdmin,
      fetchAuditConfigurationDocument
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(AuditModal))
