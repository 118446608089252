import commentsActionTypes from '../../../constants/actions/dossier/common/comments'

export function updateComment (comment, resolve, reject, dossierType) {
  return {
    type: commentsActionTypes.UPDATE_COMMENT,
    dossierType,
    comment,
    resolve,
    reject
  }
}

export function createComment (newComment, resolve, reject, dossierType) {
  return {
    type: commentsActionTypes.CREATE_COMMENT,
    dossierType,
    newComment,
    resolve,
    reject
  }
}

export function deleteComment (comment, dossierType) {
  return {
    type: commentsActionTypes.DELETE_COMMENT,
    dossierType,
    comment
  }
}

export function fetchSalesCommentsSuccess (comments, dossierType) {
  return {
    type: commentsActionTypes.FETCH_SALES_COMMENTS_SUCCESS,
    dossierType,
    comments
  }
}

export function fetchPaperworkCommentsSuccess (comments) {
  return {
    type: commentsActionTypes.FETCH_PAPERWORK_COMMENTS_SUCCESS,
    comments
  }
}

export function fetchPurchaseCommentsSuccess (comments) {
  return {
    type: commentsActionTypes.FETCH_PURCHASE_COMMENTS_SUCCESS,
    comments
  }
}

export function fetchCampaignCommentsSuccess (comments) {
  return {
    type: commentsActionTypes.FETCH_CAMPAIGN_COMMENTS_SUCCESS,
    comments
  }
}

export function fetchDamageClaimCommentsSuccess (comments) {
  return {
    type: commentsActionTypes.FETCH_DAMAGE_CLAIM_COMMENTS_SUCCESS,
    comments
  }
}

export function markCommentAsRead (dossierId, dossierType, historical) {
  return {
    type: commentsActionTypes.MARK_COMMENT_AS_READ,
    dossierId,
    dossierType,
    historical
  }
}

export function fetchServiceDossierCommentSuccess (comments) {
  return {
    type: commentsActionTypes.FETCH_SERVICE_COMMENTS_SUCCESS,
    comments
  }
}

export function fetchStockDossierCommentSuccess (comments) {
  return {
    type: commentsActionTypes.FETCH_STOCK_COMMENTS_SUCCESS,
    comments
  }
}

export function fetchRegistrationDeliveryCommentSuccess (comments) {
  return {
    type: commentsActionTypes.FETCH_REGISTRATION_DELIVERY_COMMENTS_SUCCESS,
    comments
  }
}

export function getComments (dossierId, dossierType, historical) {
  return {
    type: commentsActionTypes.GET_COMMENTS,
    dossierId,
    dossierType,
    historical
  }
}

export default {
  updateComment,
  createComment,
  deleteComment,
  fetchSalesCommentsSuccess,
  fetchPaperworkCommentsSuccess,
  fetchCampaignCommentsSuccess,
  markCommentAsRead,
  getComments,
  fetchServiceDossierCommentSuccess,
  fetchRegistrationDeliveryCommentSuccess,
  fetchStockDossierCommentSuccess
}
