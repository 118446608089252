import React, { PureComponent } from 'react'
import { Row, Button, Modal, Col } from 'react-bootstrap'
import { reduxForm, Field } from 'redux-form'
import InputSelect from '../commons/form/InputSelect'
import DropZone from 'react-dropzone'
import settings from '../../setting'

class DiferentDocumentUploadModal extends PureComponent {
  constructor () {
    super()
    this.state = {
      validatePermission: false,
      files: [],
      hasInvalidFile: false,
      hasNewDocument: false,
      dropzoneActive: false,
      dropzonePanelActive: false,
      documentName: '',
      documentLoaded: false,
      documentTypeUseId: null
    }

    this.onDrop = this.onDrop.bind(this)
    this.setDocumentTitleElementRef = element => {
      this.documentTitleElementRef = element
    }
  }

  componentDidMount () {

  }

  componentDidUpdate () {
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (prevProps.showModal !== this.props.showModal) {
      this.props.actions.getDocumentTypesFleets()

      this.setState({
        documentTitle: '',
        showTitle: false,
        files: [],
        hasNewDocument: false,
        showFileUploader: true,
        hasInvalidFile: false,
        documentLoaded: false,
        dropzoneActive: false,
        dropzonePanelActive: false,
        documentName: '',
        validatePermission: false,
        documentTypeUseId: null
      })
      this.buttonUpload = false
      this.buttonReject = false
      this.props.change('diferentDocumentUploadModal.documentType')
    }
    return null
  }

  upload () {

  }

  uploadValidate () {

  }

  handleDocTypeChange (value) {
    if (value) {
      new Promise((resolve, reject) => {
        return this.props.actions.fecthValidateDocumentFleetPermission(value, this.props.organizedUnitId, resolve, reject)
      }).then((response) => {
        this.setState({ validatePermission: response })
      }).catch(() => {
        this.setState({ validatePermission: false })
      })
    } else {
      this.setState({ validatePermission: false })
    }
    this.setState({ documentTypeUseId: value })
  }

  onDrop (fileToUpload, rejectedFiles) {
    if (fileToUpload.length === 0 && rejectedFiles.length > 0) {
      this.setState({ hasInvalidFile: true })
    } else {
      this.setState({ files: [fileToUpload[0]], hasNewDocument: true, hasInvalidFile: false })
      if (fileToUpload[0]) {
        this.buttonUpload = true
        this.buttonReject = true
        this.setState({
          documentLoaded: false,
          showFileUploader: false
        })

        this.setState({ documentName: fileToUpload[0].name })
      }
    }
  }

  onDragEnter (panel = false) {
    this.setState(panel ? { dropzonePanelActive: true } : { dropzoneActive: true })
  }

  onDragLeave (panel = false) {
    this.setState(panel ? { dropzonePanelActive: false } : { dropzoneActive: false })
  }

  submitDocument (values, validate) {
    let nfiles = this.state.files.length
    let lastFile = this.state.files[nfiles - 1]

    let dossiers = []

    this.props.dossiers.forEach(dossier => {
      let dos = {
        dossierId: dossier.dossierId,
        vinChassis: dossier.vehicle.vehicleVinChassis,
        vehicleId: dossier.vehicle.vehicleId,
        entityId: (dossier.entity && dossier.entity.entityId ? dossier.entity.entityId.toString() : '-1')
      }

      dossiers.push(dos)
    })

    this.props.actions.setUploadZipDocumentFleetProcess(lastFile, dossiers, this.state.documentTypeUseId, this.props.organizedUnitId, validate, () => { this.props.actions.closeDiferentDocumentUploadModal(); this.props.fetchFleets(this.props.fleetsFilter) })

    this.setState({
      documentTitle: '',
      showTitle: false,
      files: [],
      hasNewDocument: false,
      showFileUploader: true,
      hasInvalidFile: false,
      documentLoaded: false,
      dropzoneActive: false,
      dropzonePanelActive: false,
      documentName: '',
      validatePermission: false,
      documentTypeUseId: null
    })
    this.buttonUpload = false
    this.buttonReject = false
    this.props.initialize({})
    this.props.change('diferentDocumentUploadModal.documentType')
  }

  render () {
    const {
      t,
      showModal, combos: { documentsTypes }, handleSubmit
    } = this.props
    const tKey = 'MANAGEMENT.FLEETS_TAB.'

    const acceptFilesDropzone = settings.acceptCompressFormat
    const maxSizeFilesDropzone = 10240000
    return (
      <Modal bsSize="large" show={showModal} onHide={this.props.actions.closeDiferentDocumentUploadModal} backdrop='static' className='modal-documents'>
        <form autoComplete="off" >
          <Modal.Header closeButton onHide={this.props.actions.closeDiferentDocumentUploadModal}>
            <Modal.Title>{t(`${tKey}TITLE_DIFERENT_DOCUMENT_MODAL`)}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col sm={12} className="input-dropzone-wrapper">
                <Row className="form-group">
                  {
                    <Col sm={12} className={'document-edition-modal-middle-side'}>
                      <div className={'input-dropzone'} style={{ height: '100%' }}>
                        {
                          <DropZone
                            id="dropZone"
                            multiple={false}
                            onDrop={(acceptedFiles, rejectedFiles) => this.onDrop(acceptedFiles, rejectedFiles)}
                            disabled={false}
                            accept={acceptFilesDropzone}
                            maxSize={maxSizeFilesDropzone}
                            style={{ height: '100%' }}
                            className={this.state.dropzoneActive ? 'active' : ''}
                            onDragEnter={() => this.onDragEnter(false)}
                            onDragLeave={() => this.onDragLeave(false)}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div {...getRootProps()}>
                                <input {...getInputProps()} />
                                {this.state.documentName !== '' ? (
                                  <div className="text-wrapper">
                                    {t(`${tKey}SELECTED_FILES`)}: {this.state.documentName}
                                  </div>
                                ) : (
                                  <div className="text-wrapper modal-subir-fichero-inicio">
                                    <div>
                                      <i className='ico-cloud' />
                                      <h2 key="dropZone-title">{t(`${tKey}DROP_FILES`)}</h2>
                                      <p key="dropZone-subtitle">{t(`${tKey}SELECT_FILES`)}</p>
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
                          </DropZone>
                        }
                      </div>
                    </Col>
                  }
                </Row>
                <Row >
                  <Field
                    colSm={6}
                    controlLabel={t(`${tKey}DOCUMENT_TYPE`)}
                    id="diferentDocumentUploadModal.documentType"
                    name="diferentDocumentUploadModal.documentType"
                    placeholder={t(`${tKey}DOCUMENT_TYPE`)}
                    component={InputSelect}
                    menuContainerStyle={{ zIndex: 999 }}
                    options={documentsTypes}
                    onInputChange={(value) => this.handleDocTypeChange(value)}
                    valueKey="id"
                    labelKey="value"
                    customClass="select-document-management"
                  />
                </Row>
                {!this.state.showFileUploader && this.state.documentTypeUseId && (
                  <Row className="form-group">
                    <Col sm={12} className="document-edition-modal-middle-side" >
                      <div className="btn-wrapper">
                        {
                          this.buttonUpload ? [
                            this.buttonUpload && (<Button
                              id="btn_upload"
                              key="btn_upload"
                              className="btn-validate-white"
                              style={{ marginRight: '10px' }}
                              onClick={handleSubmit(values => {
                                this.submitDocument(values, false)
                              })}
                            ><i className="ico-check" /> {t(`${tKey}UPLOAD`)}</Button>),
                            this.buttonReject && this.state.validatePermission && (<Button
                              id="btn_upload_validate"
                              key="btn_upload_validate"
                              className="btn-validate-white"
                              style={{ marginRight: '10px' }}
                              onClick={handleSubmit(values => {
                                this.submitDocument(values, true)
                              })}
                            ><i className="ico-check" /> {t(`${tKey}UPLOAD_AND_VALIDATE`)}</Button>)
                          ] : null
                        }
                      </div>
                    </Col>
                  </Row>
                )
                }
              </Col>
            </Row>

          </Modal.Body>
          <Modal.Footer>
            <Col sm={12} className="btn-wrapper">
              <Button
                id="role-modal-submit-button"
                className="btn-standard"
                onClick = {this.props.actions.closeDiferentDocumentUploadModal}>
                {t(`${tKey}CANCEL`)}
              </Button>
            </Col>
          </Modal.Footer>
        </form>
      </Modal>

    )
  }
}

const validate = (values, props) => {
  const errors = { diferentDocumentUploadModal: {} }

  if (!values || !values.diferentDocumentUploadModal || !values.diferentDocumentUploadModal.documentType) {
    errors.diferentDocumentUploadModal.documentType = props.t('GLOBAL.FORMS.REQUIRED')
  }

  return errors
}

export default reduxForm({
  form: 'diferentDocumentUploadModal',
  validate
})(DiferentDocumentUploadModal)
