export default {
  FETCH_ENTITY_MASTERS: 'FETCH_ENTITY_MASTERS',
  FETCH_ENTITY_MASTERS_SUCCESS: 'FETCH_ENTITY_MASTERS_SUCCESS',
  OPEN_SOURCE_CHANNEL_MODAL: 'OPEN_SOURCE_CHANNEL_MODAL',
  OPEN_SOURCE_CHANNEL_MODAL_SUCCESS: 'OPEN_SOURCE_CHANNEL_MODAL_SUCCESS',
  CLOSE_SOURCE_CHANNEL_MODAL: 'CLOSE_SOURCE_CHANNEL_MODAL',
  SAVE_SOURCE_CHANNEL: 'SAVE_SOURCE_CHANNEL',
  DELETE_SOURCE_CHANNEL: 'DELETE_SOURCE_CHANNEL',
  FETCH_EXTRA_SALES_MASTERS: 'FETCH_EXTRA_SALES_MASTERS',
  FETCH_EXTRA_SALES_MASTERS_SUCCESS: 'FETCH_EXTRA_SALES_MASTERS_SUCCESS',
  OPEN_EXTRA_SALE_MODAL: 'OPEN_EXTRA_SALE_MODAL',
  OPEN_EXTRA_SALE_MODAL_SUCCESS: 'OPEN_EXTRA_SALE_MODAL_SUCCESS',
  CLOSE_EXTRA_SALE_MODAL: 'CLOSE_EXTRA_SALE_MODAL',
  SAVE_EXTRA_SALE: 'SAVE_EXTRA_SALE',
  DELETE_EXTRA_SALE_MASTERS: 'DELETE_EXTRA_SALE_MASTERS',
  RECOVER_EXTRA_SALE: 'RECOVER_EXTRA_SALE',
  FETCH_SUPPLIED_MASTERS: 'FETCH_SUPPLIED_MASTERS',
  FETCH_SUPPLIED_MASTERS_SUCCESS: 'FETCH_SUPPLIED_MASTERS_SUCCESS',
  OPEN_SUPPLIED_MODAL: 'OPEN_SUPPLIED_MODAL',
  OPEN_SUPPLIED_MODAL_SUCCESS: 'OPEN_SUPPLIED_MODAL_SUCCESS',
  CLOSE_SUPPLIED_MODAL: 'CLOSE_SUPPLIED_MODAL',
  SAVE_SUPPLIED_MASTER: 'SAVE_SUPPLIED_MASTER',
  DELETE_SUPPLIED_MASTER: 'DELETE_SUPPLIED_MASTER',
  RECOVER_SUPPLIED_MASTER: 'RECOVER_SUPPLIED_MASTER',
  FETCH_PRESENTS_MASTERS: 'FETCH_PRESENTS_MASTERS',
  FETCH_PRESENTS_MASTERS_SUCCESS: 'FETCH_PRESENTS_MASTERS_SUCCESS',
  OPEN_PRESENTS_MODAL: 'OPEN_PRESENTS_MODAL',
  OPEN_PRESENTS_MODAL_SUCCESS: 'OPEN_PRESENTS_MODAL_SUCCESS',
  CLOSE_PRESENTS_MODAL: 'CLOSE_PRESENTS_MODAL',
  SAVE_PRESENTS: 'SAVE_PRESENTS',
  DELETE_PRESENTS: 'DELETE_PRESENTS',
  RECOVER_PRESENTS: 'RECOVER_PRESENTS',
  FETCH_PROCESSING_AGENCY: 'FETCH_PROCESSING_AGENCY',
  FETCH_PROCESSING_AGENCY_QUOTA: 'FETCH_PROCESSING_AGENCY_QUOTA',
  FETCH_PAYMENT_METHOD: 'FETCH_PAYMENT_METHOD',
  FETCH_PAYMENT_METHOD_SUCCESS: 'FETCH_PAYMENT_METHOD_SUCCESS',
  FETCH_FINANCING: 'FETCH_FINANCING',
  FETCH_PROCESSING_AGENCY_SUCCESS: 'FETCH_PROCESSING_AGENCY_SUCCESS',
  FETCH_PROCESSING_AGENCY_QUOTA_SUCCESS: 'FETCH_PROCESSING_AGENCY_QUOTA_SUCCESS',
  FETCH_FINANCING_SUCCESS: 'FETCH_FINANCING_SUCCESS',
  OPEN_EDIT_PROCESSING_AGENCY_MODAL: 'OPEN_EDIT_PROCESSING_AGENCY_MODAL',
  OPEN_EDIT_PROCESSING_AGENCY_MODAL_SUCCESS: 'OPEN_EDIT_PROCESSING_AGENCY_MODAL_SUCCESS',
  OPEN_EDIT_PROCESSING_AGENCY_QUOTA_MODAL: 'OPEN_EDIT_PROCESSING_AGENCY_QUOTA_MODAL',
  OPEN_EDIT_PROCESSING_AGENCY_QUOTA_MODAL_SUCCESS: 'OPEN_EDIT_PROCESSING_AGENCY_QUOTA_MODAL_SUCCESS',
  OPEN_EDIT_FINANCING_MODAL: 'OPEN_EDIT_FINANCING_MODAL',
  OPEN_EDIT_FINANCING_MODAL_SUCCESS: 'OPEN_EDIT_FINANCING_MODAL_SUCCESS',
  CLOSE_EDIT_PROCESSING_AGENCY_MODAL: 'CLOSE_EDIT_PROCESSING_AGENCY_MODAL',
  CLOSE_EDIT_PROCESSING_AGENCY_QUOTA_MODAL: 'CLOSE_EDIT_PROCESSING_AGENCY_QUOTA_MODAL',
  OPEN_EDIT_PAYMENT_METHOD_MODAL: 'OPEN_EDIT_PAYMENT_METHOD_MODAL',
  OPEN_EDIT_PAYMENT_METHOD_MODAL_SUCCESS: 'OPEN_EDIT_PAYMENT_METHOD_MODAL_SUCCESS',
  CLOSE_EDIT_PAYMENT_METHOD_MODAL: 'CLOSE_EDIT_PPAYMENT_METHOD_MODAL',
  DELETE_PROCESSING_AGENCY: 'DELETE_PROCESSING_AGENCY',
  RECOVER_PROCESSING_AGENCY: 'RECOVER_PROCESSING_AGENCY',
  DELETE_PAYMENT_METHOD: 'DELETE_PAYMENT_METHOD',
  RECOVER_PAYMENT_METHOD: 'RECOVER_PAYMENT_METHOD',
  SUBMIT_PROCESSING_AGENCY: 'SUBMIT_PROCESSING_AGENCY',
  SUBMIT_PROCESSING_AGENCY_QUOTA: 'SUBMIT_PROCESSING_AGENCY_QUOTA',
  UPDATE_PROCESSING_AGENCY_QUOTA: 'UPDATE_PROCESSING_AGENCY_QUOTA',
  EDIT_PROCESSING_AGENCY_QUOTA: 'EDIT_PROCESSING_AGENCY_QUOTA',
  DELETE_PROCESSING_AGENCY_QUOTA: 'DELETE_PROCESSING_AGENCY_QUOTA',
  DELETE_PROCESSING_AGENCY_MASTER_QUOTA: 'DELETE_PROCESSING_AGENCY_MASTER_QUOTA',
  ADD_PROCESSING_AGENCY_QUOTA: 'ADD_PROCESSING_AGENCY_QUOTA',
  SUBMIT_PAYMENT_METHOD: 'SUBMIT_PAYMENT_METHOD',
  OPEN_ADD_PROCESSING_AGENCY_MODAL: 'OPEN_ADD_PROCESSING_AGENCY_MODAL',
  OPEN_ADD_PROCESSING_AGENCY_MODAL_SUCCESS: 'OPEN_ADD_PROCESSING_AGENCY_MODAL_SUCCESS',
  OPEN_ADD_PROCESSING_AGENCY_QUOTA_MODAL: 'OPEN_ADD_PROCESSING_AGENCY_QUOTA_MODAL',
  OPEN_ADD_PROCESSING_AGENCY_QUOTA_MODAL_SUCCESS: 'OPEN_ADD_PROCESSING_AGENCY_QUOTA_MODAL_SUCCESS',
  OPEN_ADD_PAYMENT_METHOD_MODAL: 'OPEN_ADD_PAYMENT_METHOD_MODAL',
  OPEN_ADD_PAYMENT_METHOD_MODAL_SUCCESS: 'OPEN_ADD_PAYMENT_METHOD_MODAL_SUCCESS',
  CLOSE_EDIT_FINANCING_MODAL: 'CLOSE_EDIT_FINANCING_MODAL',
  DELETE_FINANCING: 'DELETE_FINANCING',
  RECOVER_FINANCING: 'RECOVER_FINANCING',
  SUBMIT_FINANCING: 'SUBMIT_FINANCING',
  OPEN_ADD_FINANCING_MODAL: 'OPEN_ADD_FINANCING_MODAL',
  OPEN_ADD_FINANCING_MODAL_SUCCESS: 'OPEN_ADD_FINANCING_MODAL_SUCCESS',
  OPEN_ASSOCIATED_COSTS_MODAL: 'OPEN_ASSOCIATED_COSTS_MODAL',
  OPEN_ASSOCIATED_COSTS_MODAL_SUCCESS: 'OPEN_ASSOCIATED_COSTS_MODAL_SUCCESS',
  DELETE_ASSOCIATED_COSTS: 'DELETE_ASSOCIATED_COSTS',
  RECOVER_ASSOCIATED_COSTS: 'RECOVER_ASSOCIATED_COSTS',
  FETCH_ASSOCIATED_COSTS_MASTERS: 'FETCH_ASSOCIATED_COSTS_MASTERS',
  FETCH_ASSOCIATED_COSTS_MASTERS_SUCCESS: 'FETCH_ASSOCIATED_COSTS_MASTERS_SUCCESS',
  CLOSE_ASSOCIATED_COSTS_MODAL: 'CLOSE_ASSOCIATED_COSTS_MODAL',
  SAVE_ASSOCIATED_COSTS: 'SAVE_ASSOCIATED_COSTS',
  FETCH_SIGNATURE_CONFIG: 'FETCH_SIGNATURE_CONFIG',
  FETCH_SIGNATURE_CONFIG_SUCCESS: 'FETCH_SIGNATURE_CONFIG_SUCCESS',
  OPEN_EDIT_SIGNATURE_CONFIG_MODAL: 'OPEN_EDIT_SIGNATURE_CONFIG_MODAL',
  OPEN_EDIT_SIGNATURE_CONFIG_MODAL_SUCCESS: 'OPEN_EDIT_SIGNATURE_CONFIG_MODAL_SUCCESS',
  CLOSE_EDIT_SIGNATURE_CONFIG_MODAL: 'CLOSE_EDIT_SIGNATURE_CONFIG_MODAL',
  OPEN_EDIT_SIGNATURE_ENTITY_MODAL: 'OPEN_EDIT_SIGNATURE_ENTITY_MODAL',
  OPEN_EDIT_SIGNATURE_ENTITY_MODAL_SUCCESS: 'OPEN_EDIT_SIGNATURE_ENTITY_MODAL_SUCCESS',
  CLOSE_EDIT_SIGNATURE_ENTITY_MODAL: 'CLOSE_EDIT_SIGNATURE_ENTITY_MODAL',
  SUBMIT_SIGNATURE_CONFIG: 'SUBMIT_SIGNATURE_CONFIG',
  DELETE_SIGNATURE_CONFIG: 'DELETE_SIGNATURE_CONFIG',
  SUBMIT_SIGNATURE_ENTITY: 'SUBMIT_SIGNATURE_ENTITY',
  DELETE_SIGNATURE_ENTITY: 'DELETE_SIGNATURE_ENTITY',
  FETCH_FUEL: 'FETCH_FUEL',
  FETCH_FUEL_SUCCESS: 'FETCH_FUEL_SUCCESS',
  OPEN_EDIT_FUEL_MODAL: 'OPEN_EDIT_FUEL_MODAL',
  OPEN_EDIT_FUEL_MODAL_SUCCESS: 'OPEN_EDIT_FUEL_MODAL_SUCCESS',
  OPEN_ADD_FUEL_MODAL: 'OPEN_ADD_FUEL_MODAL',
  OPEN_ADD_FUEL_MODAL_SUCCESS: 'OPEN_ADD_FUEL_MODAL_SUCCESS',
  CLOSE_EDIT_FUEL_MODAL: 'CLOSE_EDIT_FUEL_MODAL',
  DELETE_FUEL: 'DELETE_FUEL',
  SUBMIT_FUEL: 'SUBMIT_FUEL',
  RECOVER_FUEL: 'RECOVER_FUEL',
  FETCH_FUEL_MASTERS_SUCCESS: 'FETCH_FUEL_MASTERS_SUCCESS',
  OPEN_FUEL_MODAL_SUCCESS: 'OPEN_FUEL_MODAL_SUCCESS',
  CLOSE_FUEL_MODAL: 'CLOSE_FUEL_MODAL',
  FETCH_DOSSIER_STAGES: 'FETCH_DOSSIER_STAGES',
  FETCH_DOSSIER_STAGES_SUCCESS: 'FETCH_DOSSIER_STAGES_SUCCESS',
  OPEN_EDIT_DOSSIER_STAGES_MODAL: 'OPEN_EDIT_DOSSIER_STAGES_MODAL',
  OPEN_EDIT_DOSSIER_STAGES_MODAL_SUCCESS: 'OPEN_EDIT_DOSSIER_STAGES_MODAL_SUCCESS',
  CLOSE_EDIT_DOSSIER_STAGES_MODAL: 'CLOSE_EDIT_DOSSIER_STAGES_MODAL',
  SUBMIT_DOSSIER_STAGE: 'SUBMIT_DOSSIER_STAGE',
  FETCH_FINANCING_TYPE_MASTERS: 'FETCH_FINANCING_TYPE_MASTERS',
  FETCH_FINANCING_TYPE_MASTERS_SUCCESS: 'FETCH_FINANCING_TYPE_MASTERS_SUCCESS',
  OPEN_FINANCING_TYPE_MODAL: 'OPEN_FINANCING_TYPE_MODAL',
  OPEN_FINANCING_TYPE_MODAL_SUCCESS: 'OPEN_FINANCING_TYPE_MODAL_SUCCESS',
  CLOSE_FINANCING_TYPE_MODAL: 'CLOSE_FINANCING_TYPE_MODAL',
  SAVE_FINANCING_TYPE: 'SAVE_FINANCING_TYPE',
  DELETE_FINANCING_TYPE_MASTERS: 'DELETE_FINANCING_TYPE_MASTERS',
  RECOVER_FINANCING_TYPE: 'RECOVER_FINANCING_TYPE',
  FETCH_FINANCING_TYPE_SUCCESS_LIST: 'FETCH_FINANCING_TYPE_SUCCESS_LIST',
  FETCH_FINANCING_TYPE: 'FETCH_FINANCING_TYPE',
  FETCH_FINANCING_TYPE_LIST: 'FETCH_FINANCING_TYPE_LIST',
  FETCH_PURCHASE_PRESENT_TYPE_LIST: 'FETCH_PURCHASE_PRESENT_TYPE_LIST',
  FETCH_PURCHASE_PRESENT_TYPE_SUCCESS_LIST: 'FETCH_PURCHASE_PRESENT_TYPE_SUCCESS_LIST',
  FETCH_PURCHASE_PRESENT_TYPE_MASTERS: 'FETCH_PURCHASE_PRESENT_TYPE_MASTERS',
  FETCH_PURCHASE_PRESENT_TYPE_MASTERS_SUCCESS: 'FETCH_PURCHASE_PRESENT_TYPE_MASTERS_SUCCESS',
  OPEN_PURCHASE_PRESENT_TYPE_MODAL: 'OPEN_PURCHASE_PRESENT_TYPE_MODAL',
  OPEN_PURCHASE_PRESENT_TYPE_MODAL_SUCCESS: 'OPEN_PURCHASE_PRESENT_TYPE_MODAL_SUCCESS',
  CLOSE_PURCHASE_PRESENT_TYPE_MODAL: 'CLOSE_PURCHASE_PRESENT_TYPE_MODAL',
  SAVE_PURCHASE_PRESENT_TYPE: 'SAVE_PURCHASE_PRESENT_TYPE',
  DELETE_PURCHASE_PRESENT_TYPE_MASTERS: 'DELETE_PURCHASE_PRESENT_TYPE_MASTERS',
  RECOVER_PURCHASE_PRESENT_TYPE: 'RECOVER_PURCHASE_PRESENT_TYPE',
  FETCH_CAMPAIGN_LIST: 'FETCH_CAMPAIGN_LIST',
  FETCH_CAMPAIGN_LIST_SUCCESS: 'FETCH_CAMPAIGN_LIST_SUCCESS',
  OPEN_EDIT_CAMPAIGN_MODAL_SUCCESS: 'OPEN_EDIT_CAMPAIGN_MODAL_SUCCESS',
  OPEN_ADD_CAMPAIGN_MODAL: 'OPEN_ADD_CAMPAIGN_MODAL',
  OPEN_ADD_CAMPAIGN_MODAL_SUCCESS: 'OPEN_ADD_CAMPAIGN_MODAL_SUCCESS',
  CLOSE_EDIT_CAMPAIGN_MODAL: 'CLOSE_EDIT_CAMPAIGN_MODAL',
  SUBMIT_CAMPAIGN_DETAIL: 'SUBMIT_CAMPAIGN_DETAIL',
  DELETE_CAMPAIGN_DETAIL: 'DELETE_CAMPAIGN_DETAIL',
  RECOVER_CAMPAIGN_DETAIL: 'RECOVER_CAMPAIGN_DETAIL',
  FETCH_CAMPAIGN_GROUP_LIST: 'FETCH_CAMPAIGN_GROUP_LIST',
  FETCH_CAMPAIGN_GROUP_LIST_SUCCESS: 'FETCH_CAMPAIGN_GROUP_LIST_SUCCESS',
  OPEN_CAMPAIGN_GROUP_MODAL: 'OPEN_CAMPAIGN_GROUP_MODAL',
  OPEN_EDIT_CAMPAIGN_GROUP_MODAL_SUCCESS: 'OPEN_EDIT_CAMPAIGN_GROUP_MODAL_SUCCESS',
  OPEN_ADD_CAMPAIGN_GROUP_MODAL_SUCCESS: 'OPEN_ADD_CAMPAIGN_GROUP_MODAL_SUCCESS',
  CLOSE_EDIT_CAMPAIGN_GROUP_MODAL: 'CLOSE_EDIT_CAMPAIGN_GROUP_MODAL',
  SUBMIT_CAMPAIGN_GROUP: 'SUBMIT_CAMPAIGN_GROUP',
  DELETE_CAMPAIGN_GROUP: 'DELETE_CAMPAIGN_GROUP',
  RECOVER_CAMPAIGN_GROUP: 'RECOVER_CAMPAIGN_GROUP',
  FETCH_CAMPAIGN_TYPE_LIST: 'FETCH_CAMPAIGN_TYPE_LIST',
  FETCH_CAMPAIGN_TYPE_LIST_SUCCESS: 'FETCH_CAMPAIGN_TYPE_LIST_SUCCESS',
  OPEN_EDIT_CAMPAIGN_TYPE_MODAL_SUCCESS: 'OPEN_EDIT_CAMPAIGN_TYPE_MODAL_SUCCESS',
  OPEN_CAMPAIGN_TYPE_MODAL: 'OPEN_CAMPAIGN_TYPE_MODAL',
  OPEN_ADD_CAMPAIGN_TYPE_MODAL_SUCCESS: 'OPEN_ADD_CAMPAIGN_TYPE_MODAL_SUCCESS',
  CLOSE_EDIT_CAMPAIGN_TYPE_MODAL: 'CLOSE_EDIT_CAMPAIGN_TYPE_MODAL',
  SUBMIT_CAMPAIGN_TYPE: 'SUBMIT_CAMPAIGN_TYPE',
  DELETE_CAMPAIGN_TYPE: 'DELETE_CAMPAIGN_TYPE',
  RECOVER_CAMPAIGN_TYPE: 'RECOVER_CAMPAIGN_TYPE',
  OPEN_EDIT_DOCUMENT_CONDITION_MODAL_SUCCESS: 'OPEN_EDIT_DOCUMENT_CONDITION_MODAL_SUCCESS',
  CLOSE_EDIT_DOCUMENT_CONDITION_MODAL_SUCCESS: 'CLOSE_EDIT_DOCUMENT_CONDITION_MODAL_SUCCESS',
  OPEN_COUNTRY_CAMPAIGN_DETAIL_MODAL_SUCCESS: 'OPEN_COUNTRY_CAMPAIGN_DETAIL_MODAL_SUCCESS',
  CLOSE_COUNTRY_CAMPAIGN_DETAIL_MODAL_SUCCESS: 'CLOSE_COUNTRY_CAMPAIGN_DETAIL_MODAL_SUCCESS',
  FETCH_COUNTRY_CAMPAIGN_DETAIL_LIST: 'FETCH_COUNTRY_CAMPAIGN_DETAIL_LIST',
  FETCH_COUNTRY_CAMPAIGN_DETAIL_LIST_SUCCESS: 'FETCH_COUNTRY_CAMPAIGN_DETAIL_LIST_SUCCESS',
  DELETE_COUNTRY_CAMPAIGN_DETAIL: 'DELETE_COUNTRY_CAMPAIGN_DETAIL',
  SET_COUNTRY_CAMPAIGN_DETAIL: 'SET_COUNTRY_CAMPAIGN_DETAIL',
  OPEN_STYLE_TASK_MODAL: 'OPEN_STYLE_TASK_MODAL',
  FETCH_STYLE_TASK_LIST: 'FETCH_STYLE_TASK_LIST',
  DELETE_STYLE_TASK: 'DELETE_STYLE_TASK',
  CLOSE_EDIT_STYLE_TASK_MODAL: 'CLOSE_EDIT_STYLE_TASK_MODAL',
  SUBMIT_STYLE_TASK: 'SUBMIT_STYLE_TASK',
  FETCH_STYLETASK_LIST_SUCCESS: 'FETCH_STYLETASK_LIST_SUCCESS',
  FETCH_STYLE_TASK_LIST_SUCCESS: 'FETCH_STYLE_TASK_LIST_SUCCESS',
  OPEN_ADD_STYLE_TASK_MODAL_SUCCESS: 'OPEN_ADD_STYLE_TASK_MODAL_SUCCESS',
  STYLE_TASK_ORDERS: 'STYLE_TASK_ORDERS',
  CLOSE_EDIT_DOCUMENT_CONDITION_EDITION_MODAL_SUCCESS: 'CLOSE_EDIT_DOCUMENT_CONDITION_EDITION_MODAL_SUCCESS',
  OPEN_EDIT_DOCUMENT_CONDITION_EDITION_MODAL_SUCCESS: 'OPEN_EDIT_DOCUMENT_CONDITION_EDITION_MODAL_SUCCESS',
  OPEN_ACTION_DATA_MODAL: 'OPEN_ACTION_DATA_MODAL',
  CLOSE_ACTION_DATA_MODAL: 'CLOSE_ACTION_DATA_MODAL',
  OPEN_EDIT_FINANCING_MODAL_UO: 'OPEN_EDIT_FINANCING_MODAL_UO',
  CLOSE_EDIT_FINANCING_MODAL_UO: 'CLOSE_EDIT_FINANCING_MODAL_UO',
  OPEN_EDIT_FINANCING_MODAL_UO_SUCCESS: 'OPEN_EDIT_FINANCING_MODAL_UO_SUCCESS',
  CLOSE_EDIT_FINANCING_MODAL_UO_SUCCESS: 'CLOSE_EDIT_FINANCING_MODAL_UO_SUCCESS',
  SAVE_UO_FINANCING_COMPANY: 'SAVE_UO_FINANCING_COMPANY',
  SAVE_UO_FINANCING_COMPANY_SUCCESS: 'SAVE_UO_FINANCING_COMPANY_SUCCESS',
  DELETE_UO_FINANCING_COMPANY: 'DELETE_UO_FINANCING_COMPANY',
  RECOVER_UO_FINANCING_COMPANY: 'RECOVER_UO_FINANCING_COMPANY',
  OPEN_EDIT_FINANCING_MODAL_PRODUCT: 'OPEN_EDIT_FINANCING_MODAL_PRODUCT',
  CLOSE_EDIT_FINANCING_MODAL_PRODUCT: 'CLOSE_EDIT_FINANCING_MODAL_PRODUCT',
  FETCH_FINANCING_PRODUCT_COMPANY: 'FETCH_FINANCING_PRODUCT_COMPANY',
  OPEN_FINANCING_TYPE_PRODUCT_MODAL: 'OPEN_FINANCING_TYPE_PRODUCT_MODAL',
  FETCH_FINANCING_PRODUCT_COMPANY_SUCCES: 'FETCH_FINANCING_PRODUCT_COMPANY_SUCCES',
  OPEN_FINANCING_TYPE_PRODUCT_MODAL_SUCCESS: 'OPEN_FINANCING_TYPE_PRODUCT_MODAL_SUCCESS',
  DELETE_FINANCING_TYPE_PRODUCT_MASTERS: 'DELETE_FINANCING_TYPE_PRODUCT_MASTERS',
  FETCH_FINANCING_TYPE_PRODUCT_MASTERS: 'FETCH_FINANCING_TYPE_PRODUCT_MASTERS',
  FETCH_FINANCING_TYPE_PRODUCT_MASTERS_SUCCESS: 'FETCH_FINANCING_TYPE_PRODUCT_MASTERS_SUCCESS',
  RECOVER_FINANCING_TYPE_PRODUCT: 'RECOVER_FINANCING_TYPE_PRODUCT',
  FETCH_FINANCING_TYPE_PRODUCT_LIST: 'FETCH_FINANCING_TYPE_PRODUCT_LIST',
  FETCH_FINANCING_TYPE_PRODUCT_SUCCESS_LIST: 'FETCH_FINANCING_TYPE_PRODUCT_SUCCESS_LIST',
  FETCH_FINANCING_TYPE_PRODUCT: 'FETCH_FINANCING_TYPE_PRODUCT',
  FETCH_FINANCING_TYPE_PRODUCT_SUCCES: 'FETCH_FINANCING_TYPE_PRODUCT_SUCCES',
  CLOSE_EDIT_FINANCING_TYPE_MODAL_PRODUCT: 'CLOSE_EDIT_FINANCING_TYPE_MODAL_PRODUCT',
  FETCH_FINANCING_TYPE_PRODUCT_FIELDS: 'FETCH_FINANCING_TYPE_PRODUCT_FIELDS',
  FETCH_FINANCING_TYPE_PRODUCT_FIELDS_SUCCESS: 'FETCH_FINANCING_TYPE_PRODUCT_FIELDS_SUCCESS',
  SAVE_FINANCING_TYPE_PRODUCT: 'SAVE_FINANCING_TYPE_PRODUCT',
  SAVE_FINANCING_TYPE_PRODUCT_SUCCESS: 'SAVE_FINANCING_TYPE_PRODUCT_SUCCESS',
  GET_ALL_FINANCING_TYPE_PRODUCT: 'GET_ALL_FINANCING_TYPE_PRODUCT',
  GET_ALL_FINANCING_TYPE_PRODUCT_SUCCESS: 'GET_ALL_FINANCING_TYPE_PRODUCT_SUCCESS',
  GET_FINANCING_TYPE_PRODUCT_BY_FINANCING_TYPE: 'GET_FINANCING_TYPE_PRODUCT_BY_FINANCING_TYPE',
  GET_FINANCING_TYPE_PRODUCT_BY_FINANCING_TYPE_SUCCESS: 'GET_FINANCING_TYPE_PRODUCT_BY_FINANCING_TYPE_SUCCESS',
  OPEN_MASS_MODAL_SUCCES: 'OPEN_MASS_MODAL_SUCCES',
  CLOSE_MASS_MODAL_SUCCES: 'CLOSE_MASS_MODAL_SUCCES',
  OPEN_MASS_MODAL: 'OPEN_MASS_MODAL',
  UPLOAD_UO_MASS_DOCUMENT: 'UPLOAD_UO_MASS_DOCUMENT',
  FETCH_UPLOAD_TEST: 'FETCH_UPLOAD_TEST',
  RESET_MODAL_MASS_UPLOAD: 'RESET_MODAL_MASS_UPLOAD',
  FETCH_TEMPLATE_LOAD_FILE: 'FETCH_TEMPLATE_LOAD_FILE',
  UPLOAD_PROC_AG_MASS_DOCUMENT: 'UPLOAD_PROC_AG_MASS_DOCUMENT',
  FETCH_UPLOAD_TEST_WITH_DOCS: 'FETCH_UPLOAD_TEST_WITH_DOCS',
  OPEN_APPROVAL_ACTION_MODAL: 'OPEN_APPROVAL_ACTION_MODAL',
  CLOSE_APPROVAL_ACTION_MODAL: 'CLOSE_APPROVAL_ACTION_MODAL',
  FETCH_PWK_TYPES_MASTER: 'FETCH_PWK_TYPES_MASTER',
  FETCH_PWK_TYPES_MASTER_SUCCES: 'FETCH_PWK_TYPES_MASTER_SUCCES',
  FETCH_PWK_TYPE_MASTER_INFO: 'FETCH_PWK_TYPE_MASTER_INFO',
  DELETE_PWK_TYPE_MASTER: 'DELETE_PWK_TYPE_MASTER',
  UPDATE_PWK_TYPE_MASTER: 'UPDATE_PWK_TYPE_MASTER',
  CLOSE_PWK_TYPE_MASTER_MODAL: 'CLOSE_PWK_TYPE_MASTER_MODAL',
  OPEN_PWK_TYPE_MASTER_MODAL: 'OPEN_PWK_TYPE_MASTER_MODAL',
  FETCH_DOCUMENT_TYPE_LIST: 'FETCH_DOCUMENT_TYPE_LIST',
  FETCH_DOCUMENT_TYPE_LIST_SUCCESS: 'FETCH_DOCUMENT_TYPE_LIST_SUCCESS',
  OPEN_DOCUMENT_TYPE_MODAL: 'OPEN_DOCUMENT_TYPE_MODAL',
  CLOSE_EDIT_DOCUMENT_TYPE_MODAL: 'CLOSE_EDIT_DOCUMENT_TYPE_MODAL',
  OPEN_DOCUMENT_TYPE_MODAL_SUCCESS: 'OPEN_DOCUMENT_TYPE_MODAL_SUCCESS',
  DELETE_DOCUMENT_TYPE: 'DELETE_DOCUMENT_TYPE',
  SUBMIT_DOCUMENT_TYPE: 'SUBMIT_DOCUMENT_TYPE',
  CLOSE_DOCUMENT_VISUALIZATION_MODAL: 'CLOSE_DOCUMENT_VISUALIZATION_MODAL',
  SUBMIT_DOCUMENT_VISUALIZATION: 'SUBMIT_DOCUMENT_VISUALIZATION',
  OPEN_DOCUMENT_VISUALIZATION_MODAL_SUCCESS: 'OPEN_DOCUMENT_VISUALIZATION_MODAL_SUCCESS',
  FETCH_DOCUMENT_VISUALIZATIONS: 'FETCH_DOCUMENT_VISUALIZATIONS',
  FETCH_DOCUMENT_VISUALIZATIONS_SUCCESS: 'FETCH_DOCUMENT_VISUALIZATIONS_SUCCESS',
  DELETE_DOCUMENT_VISUALIZATION: 'DELETE_DOCUMENT_VISUALIZATION',
  OPEN_DOCUMENT_VISUALIZATION_MODAL: 'OPEN_DOCUMENT_VISUALIZATION_MODAL',
  FETCH_DOCUMENT_TYPE_USE_LIST: 'FETCH_DOCUMENT_TYPE_USE_LIST',
  FETCH_DOCUMENT_TYPE_USE_LIST_SUCCESS: 'FETCH_DOCUMENT_TYPE_USE_LIST_SUCCESS',
  OPEN_EDIT_DOCUMENT_TYPE_USE_MODAL_SUCCESS: 'OPEN_EDIT_DOCUMENT_TYPE_USE_MODAL_SUCCESS',
  OPEN_DOCUMENT_TYPE_USE_MODAL: 'OPEN_DOCUMENT_TYPE_USE_MODAL',
  CLOSE_EDIT_DOCUMENT_TYPE_USE_MODAL: 'CLOSE_EDIT_DOCUMENT_TYPE_USE_MODAL',
  OPEN_ADD_DOCUMENT_TYPE_USE_MODAL_SUCCESS: 'OPEN_ADD_DOCUMENT_TYPE_USE_MODAL_SUCCESS',
  DELETE_DOCUMENT_TYPE_USE: 'DELETE_DOCUMENT_TYPE_USE',
  SUBMIT_DOCUMENT_TYPE_USE: 'SUBMIT_DOCUMENT_TYPE_USE',
  FETCH_ORGANIZED_UNIT_SALE_OPERATION_LIST: 'FETCH_ORGANIZED_UNIT_SALE_OPERATION_LIST',
  FETCH_ORGANIZED_UNIT_SALE_OPERATION_LIST_SUCCESS: 'FETCH_ORGANIZED_UNIT_SALE_OPERATION_LIST_SUCCESS',
  OPEN_EDIT_ORGANIZED_UNIT_SALE_OPERATION_MODAL_SUCCESS: 'OPEN_EDIT_ORGANIZED_UNIT_SALE_OPERATION_MODAL_SUCCESS',
  OPEN_ORGANIZED_UNIT_SALE_OPERATION_MODAL: 'OPEN_ORGANIZED_UNIT_SALE_OPERATION_MODAL',
  CLOSE_EDIT_ORGANIZED_UNIT_SALE_OPERATION_MODAL: 'CLOSE_EDIT_ORGANIZED_UNIT_SALE_OPERATION_MODAL',
  OPEN_ADD_ORGANIZED_UNIT_SALE_OPERATION_MODAL_SUCCESS: 'OPEN_ADD_ORGANIZED_UNIT_SALE_OPERATION_MODAL_SUCCESS',
  DELETE_ORGANIZED_UNIT_SALE_OPERATION: 'DELETE_ORGANIZED_UNIT_SALE_OPERATION',
  ADD_ORGANIZED_UNIT_SALE_OPERATION: 'ADD_ORGANIZED_UNIT_SALE_OPERATION',
  UPDATE_ORGANIZED_UNIT_SALE_OPERATION: 'UPDATE_ORGANIZED_UNIT_SALE_OPERATION',
  SUBMIT_ORGANIZED_UNIT_SALE_OPERATION: 'SUBMIT_ORGANIZED_UNIT_SALE_OPERATION',
  FETCH_PERMISSION_KEY_VALUE: 'FETCH_PERMISSION_KEY_VALUE',
  FETCH_PERMISSION_KEY_VALUE_SUCCESS: 'FETCH_PERMISSION_KEY_VALUE_SUCCESS',
  FETCH_DOCUMENT_TYPE_USE: 'FETCH_DOCUMENT_TYPE_USE',
  FETCH_DOCUMENT_TYPE_USE_SUCCESS: 'FETCH_DOCUMENT_TYPE_USE_SUCCESS',
  FETCH_DOCUMENT_TYPE_METADATA_LIST: 'FETCH_DOCUMENT_TYPE_METADATA_LIST',
  FETCH_DOCUMENT_TYPE_METADATA_LIST_SUCCESS: 'FETCH_DOCUMENT_TYPE_METADATA_LIST_SUCCESS',
  DELETE_DOCUMENT_TYPE_METADATA: 'DELETE_DOCUMENT_TYPE_METADATA',
  OPEN_DOCUMENT_TYPE_METADATA_MODAL: 'OPEN_DOCUMENT_TYPE_METADATA_MODAL',
  OPEN_DOCUMENT_TYPE_METADATA_MODAL_SUCCESS: 'OPEN_DOCUMENT_TYPE_METADATA_MODAL_SUCCESS',
  CLOSE_EDIT_DOCUMENT_TYPE_METADATA_MODAL: 'CLOSE_EDIT_DOCUMENT_TYPE_METADATA_MODAL',
  SUBMIT_DOCUMENT_TYPE_METADATA: 'SUBMIT_DOCUMENT_TYPE_METADATA',
  OPEN_METADATA_LIST_MODAL: 'OPEN_METADATA_LIST_MODAL',
  OPEN_METADATA_LIST_MODAL_SUCCESS: 'OPEN_METADATA_LIST_MODAL_SUCCESS',
  CLOSE_METADATA_LIST_MODAL: 'CLOSE_METADATA_LIST_MODAL',
  DELETE_METADATA_LIST_ELEMENT: 'DELETE_METADATA_LIST_ELEMENT',
  SUBMIT_METADATA_LIST: 'SUBMIT_METADATA_LIST',
  FETCH_OPERATION_TYPES_TABLE: 'FETCH_OPERATION_TYPES_TABLE',
  FETCH_OPERATION_TYPES_TABLE_SUCCESS: 'FETCH_OPERATION_TYPES_TABLE_SUCCESS',
  RECOVER_OPERATION_TYPE: 'RECOVER_OPERATION_TYPE',
  DELETE_OPERATION_TYPE: 'DELETE_OPERATION_TYPE',
  OPEN_OPERATION_TYPE_MODAL: 'OPEN_OPERATION_TYPE_MODAL',
  OPEN_OPERATION_TYPE_MODAL_SUCCESS: 'OPEN_OPERATION_TYPE_MODAL_SUCCESS',
  CLOSE_OPERATION_TYPE_MODAL: 'CLOSE_OPERATION_TYPE_MODAL',
  SAVE_OPERATION_TYPE: 'SAVE_OPERATION_TYPE',
  FETCH_DIRECTION_UO_MASTER: 'FETCH_DIRECTION_UO_MASTER',
  FETCH_DIRECTION_UO_MASTER_SUCCESS: 'FETCH_DIRECTION_UO_MASTER_SUCCESS',
  OPEN_DIRECTION_UO_MODAL: 'OPEN_DIRECTION_UO_MODAL',
  OPEN_DIRECTION_UO_MODAL_SUCCESS: 'OPEN_DIRECTION_UO_MODAL_SUCCESS',
  CLOSE_DIRECTION_UO_MODAL: 'CLOSE_DIRECTION_UO_MODAL',
  SAVE_DIRECTION_UO: 'SAVE_DIRECTION_UO',
  DELETE_DIRECTION_UO: 'DELETE_DIRECTION_UO',
  FETCH_LEGAL_COMPANY_LIST: 'FETCH_LEGAL_COMPANY_LIST',
  FETCH_LEGAL_COMPANY_LIST_SUCCESS: 'FETCH_LEGAL_COMPANY_LIST_SUCCESS',
  OPEN_LEGAL_COMPANY_MODAL: 'OPEN_LEGAL_COMPANY_MODAL',
  OPEN_LEGAL_COMPANY_MODAL_SUCCESS: 'OPEN_LEGAL_COMPANY_MODAL_SUCCESS',
  CLOSE_LEGAL_COMPANY_MODAL: 'CLOSE_LEGAL_COMPANY_MODAL',
  SAVE_LEGAL_COMPANY: 'SAVE_LEGAL_COMPANY',
  DELETE_LEGAL_COMPANY: 'DELETE_LEGAL_COMPANY',
  FETCH_ORGANIZED_UNIT_CONFIGURATION_LIST: 'FETCH_ORGANIZED_UNIT_CONFIGURATION_LIST',
  FETCH_ORGANIZED_UNIT_CONFIGURATION_LIST_SUCCESS: 'FETCH_ORGANIZED_UNIT_CONFIGURATION_LIST_SUCCESS',
  DELETE_ORGANIZED_UNIT_CONFIGURATION: 'DELETE_ORGANIZED_UNIT_CONFIGURATION',
  OPEN_ORGANIZED_UNIT_CONFIGURATION_MODAL: 'OPEN_ORGANIZED_UNIT_CONFIGURATION_MODAL',
  OPEN_ADD_ORGANIZED_UNIT_CONFIGURATION_MODAL_SUCCESS: 'OPEN_ADD_ORGANIZED_UNIT_CONFIGURATION_MODAL_SUCCESS',
  OPEN_EDIT_ORGANIZED_UNIT_CONFIGURATION_MODAL_SUCCESS: 'OPEN_EDIT_ORGANIZED_UNIT_CONFIGURATION_MODAL_SUCCESS',
  CLOSE_EDIT_ORGANIZED_UNIT_CONFIGURATION_MODAL: 'CLOSE_EDIT_ORGANIZED_UNIT_CONFIGURATION_MODAL',
  SUBMIT_ORGANIZED_UNIT_CONFIGURATION: 'SUBMIT_ORGANIZED_UNIT_CONFIGURATION',
  POST_PWK_TYPE_MASTER: 'POST_PWK_TYPE_MASTER',
  FETCH_ORGANIZED_UNIT_LIST: 'FETCH_ORGANIZED_UNIT_LIST',
  FETCH_ORGANIZED_UNIT_LIST_SUCCESS: 'FETCH_ORGANIZED_UNIT_LIST_SUCCESS',
  OPEN_ORGANIZED_UNIT_MODAL: 'OPEN_ORGANIZED_UNIT_MODAL',
  OPEN_ORGANIZED_UNIT_MODAL_SUCCESS: 'OPEN_ORGANIZED_UNIT_MODAL_SUCCESS',
  CLOSE_ORGANIZED_UNIT_MODAL: 'CLOSE_ORGANIZED_UNIT_MODAL',
  SAVE_ORGANIZED_UNIT: 'SAVE_ORGANIZED_UNIT',
  SET_CHANGE_TAB_FROM_CONF_MASTER: 'SET_CHANGE_TAB_FROM_CONF_MASTER',
  SET_CHANGE_TAB_FROM_CONF_MASTER_SUCCESS: 'SET_CHANGE_TAB_FROM_CONF_MASTER_SUCCESS',
  FETCH_SALE_TYPE_LIST: 'FETCH_SALE_TYPE_LIST',
  FETCH_SALE_TYPE_SUCCESS_LIST: 'FETCH_SALE_TYPE_SUCCESS_LIST',
  OPEN_SALE_TYPE_MODAL: 'OPEN_SALE_TYPE_MODAL',
  OPEN_SALE_TYPE_MODAL_SUCCESS: 'OPEN_SALE_TYPE_MODAL_SUCCESS',
  DELETE_SALE_TYPE_MASTERS: 'DELETE_SALE_TYPE_MASTERS',
  RECOVER_SALE_TYPE: 'RECOVER_SALE_TYPE',
  CLOSE_EDIT_SALE_TYPE_MODAL: 'CLOSE_EDIT_SALE_TYPE_MODAL',
  SAVE_SALE_TYPE: 'SAVE_SALE_TYPE',
  SAVE_SALE_TYPE_SUCCESS: 'SAVE_SALE_TYPE_SUCCESS',
  FETCH_SIGN_TYPE_LIST: 'FETCH_SIGN_TYPE_LIST',
  FETCH_SIGN_TYPE_SUCCESS_LIST: 'FETCH_SIGN_TYPE_SUCCESS_LIST',
  OPEN_SIGN_TYPE_MODAL: 'OPEN_SIGN_TYPE_MODAL',
  OPEN_SIGN_TYPE_MODAL_SUCCESS: 'OPEN_SIGN_TYPE_MODAL_SUCCESS',
  CLOSE_SIGN_TYPE_MODAL: 'CLOSE_SIGN_TYPE_MODAL',
  SUBMIT_SIGN_TYPE: 'SUBMIT_SIGN_TYPE',
  SUBMIT_SIGN_TYPE_SUCCESS: 'SUBMIT_SIGN_TYPE_SUCCESS',
  ADVICE_CLOSE_PWK_TYPE_MASTER_MODAL: 'ADVICE_CLOSE_PWK_TYPE_MASTER_MODAL',
  FETCH_PROCESSING_AGENCY_ORGANIZED_UNIT_CONFIG_LIST: 'FETCH_PROCESSING_AGENCY_ORGANIZED_UNIT_CONFIG_LIST',
  FETCH_PROCESSING_AGENCY_ORGANIZED_UNIT_CONFIG_LIST_SUCCESS: 'FETCH_PROCESSING_AGENCY_ORGANIZED_UNIT_CONFIG_LIST_SUCCESS',
  SHOW_PROCESSING_AGENCY_ORGANIZED_UNIT_CONFIG_MODAL: 'SHOW_PROCESSING_AGENCY_ORGANIZED_UNIT_CONFIG_MODAL',
  SHOW_PROCESSING_AGENCY_ORGANIZED_UNIT_CONFIG_MODAL_SUCCESS: 'SHOW_PROCESSING_AGENCY_ORGANIZED_UNIT_CONFIG_MODAL_SUCCESS',
  FETCH_PROCESSING_AGENCY_COMMUNICATION_SERVICES: 'FETCH_PROCESSING_AGENCY_COMMUNICATION_SERVICES',
  SUBMIT_PROCESSING_AGENCY_ORGANIZED_UNIT_CONFIG: 'SUBMIT_PROCESSING_AGENCY_ORGANIZED_UNIT_CONFIG',
  DELETE_PROCESSING_AGENCY_ORGANIZED_UNIT_CONFIG: 'DELETE_PROCESSING_AGENCY_ORGANIZED_UNIT_CONFIG',
  OPEN_EDIT_PROCESSING_AGENCY_INTEGRATION_MODAL: 'OPEN_EDIT_PROCESSING_AGENCY_INTEGRATION_MODAL',
  OPEN_EDIT_PROCESSING_AGENCY_INTEGRATION_MODAL_SUCCESS: 'OPEN_EDIT_PROCESSING_AGENCY_INTEGRATION_MODAL_SUCCESS',
  UPDATE_PROCESSING_AGENCY_INTEGRATION_COMMUNICATION_SERVICE_ID: 'UPDATE_PROCESSING_AGENCY_INTEGRATION_COMMUNICATION_SERVICE_ID',
  FETCH_PROCESSING_AGENCY_REGISTRATION_TYPES_LIST: 'FETCH_PROCESSING_AGENCY_REGISTRATION_TYPES_LIST',
  FETCH_PROCESSING_AGENCY_REGISTRATION_TYPES_LIST_SUCCESS: 'FETCH_PROCESSING_AGENCY_REGISTRATION_TYPES_LIST_SUCCESS',
  SHOW_PROCESSING_AGENCY_REGISTRATION_TYPES_MODAL: 'SHOW_PROCESSING_AGENCY_REGISTRATION_TYPES_MODAL',
  SHOW_PROCESSING_AGENCY_REGISTRATION_TYPES_MODAL_SUCCESS: 'SHOW_PROCESSING_AGENCY_REGISTRATION_TYPES_MODAL_SUCCESS',
  SUBMIT_PROCESSING_AGENCY_REGISTRATION_TYPES: 'SUBMIT_PROCESSING_AGENCY_REGISTRATION_TYPES',
  DELETE_PROCESSING_AGENCY_REGISTRATION_TYPES: 'DELETE_PROCESSING_AGENCY_REGISTRATION_TYPES',
  FETCH_PROCESSING_AGENCY_SERVICE_DESTINATIONS_LIST: 'FETCH_PROCESSING_AGENCY_SERVICE_DESTINATIONS_LIST',
  FETCH_PROCESSING_AGENCY_SERVICE_DESTINATIONS_LIST_SUCCESS: 'FETCH_PROCESSING_AGENCY_SERVICE_DESTINATIONS_LIST_SUCCESS',
  SHOW_PROCESSING_AGENCY_SERVICE_DESTINATIONS_MODAL: 'SHOW_PROCESSING_AGENCY_SERVICE_DESTINATIONS_MODAL',
  SHOW_PROCESSING_AGENCY_SERVICE_DESTINATIONS_MODAL_SUCCESS: 'SHOW_PROCESSING_AGENCY_SERVICE_DESTINATIONS_MODAL_SUCCESS',
  SUBMIT_PROCESSING_AGENCY_SERVICE_DESTINATIONS: 'SUBMIT_PROCESSING_AGENCY_SERVICE_DESTINATIONS',
  FETCH_PROCESSING_AGENCY_SERVICE_DESTINATIONS_COMBO: 'FETCH_PROCESSING_AGENCY_SERVICE_DESTINATIONS_COMBO',
  DELETE_PROCESSING_AGENCY_SERVICE_DESTINATIONS: 'DELETE_PROCESSING_AGENCY_SERVICE_DESTINATIONS',
  FETCH_PROCESSING_AGENCY_DOC_TYPE_USES_LIST: 'FETCH_PROCESSING_AGENCY_DOC_TYPE_USES_LIST',
  FETCH_PROCESSING_AGENCY_DOC_TYPE_USES_LIST_SUCCESS: 'FETCH_PROCESSING_AGENCY_DOC_TYPE_USES_LIST_SUCCESS',
  SHOW_PROCESSING_AGENCY_DOC_TYPE_USES_MODAL: 'SHOW_PROCESSING_AGENCY_DOC_TYPE_USES_MODAL',
  SHOW_PROCESSING_AGENCY_DOC_TYPE_USES_MODAL_SUCCESS: 'SHOW_PROCESSING_AGENCY_DOC_TYPE_USES_MODAL_SUCCESS',
  SUBMIT_PROCESSING_AGENCY_DOC_TYPE_USES: 'SUBMIT_PROCESSING_AGENCY_DOC_TYPE_USES',
  DELETE_PROCESSING_AGENCY_DOC_TYPE_USES: 'DELETE_PROCESSING_AGENCY_DOC_TYPE_USES',
  OPEN_USER_PROCESSING_AGENCY_MODAL: 'OPEN_USER_PROCESSING_AGENCY_MODAL',
  CLOSE_USER_PROCESSING_AGENCY_MODAL: 'CLOSE_USER_PROCESSING_AGENCY_MODAL',
  DELETE_USER_PROCESSING_AGENCY: 'DELETE_USER_PROCESSING_AGENCY',
  DELETE_USER_PROCESSING_AGENCY_SUCCESS: 'DELETE_USER_PROCESSING_AGENCY_SUCCESS',
  CHARGE_USER_PROCESSING_AGENCY_MODAL: 'CHARGE_USER_PROCESSING_AGENCY_MODAL',
  GET_USERS_PROCESSING_AGENCY: 'GET_USERS_PROCESSING_AGENCY',
  GET_USERS_PROCESSING_AGENCY_SUCCES: 'GET_USERS_PROCESSING_AGENCY_SUCCES',
  FETCH_USERS_LIST_FOR_PROCESSING_AGENCY: 'FETCH_USERS_LIST_FOR_PROCESSING_AGENCY',
  FETCH_USERS_LIST_FOR_PROCESSING_AGENCY_SUCCESS: 'FETCH_USERS_LIST_FOR_PROCESSING_AGENCY_SUCCESS',
  OPEN_USER_PROCESSING_AGENCY_ADD_MODAL: 'OPEN_USER_PROCESSING_AGENCY_ADD_MODAL',
  CLOSE_USER_PROCESSING_AGENCY_ADD_MODAL: 'CLOSE_USER_PROCESSING_AGENCY_ADD_MODAL',
  ASSING_USER_PROCESSING_AGENCY: 'ASSING_USER_PROCESSING_AGENCY',
  INITIALIZE_DOSSIER_STAGES_FILTER: 'INITIALIZE_DOSSIER_STAGES_FILTER',
  FETCH_RATING_LIST: 'FETCH_RATING_LIST',
  FETCH_RATING_LIST_SUCCESS: 'FETCH_RATING_LIST_SUCCESS',
  DELETE_RATING: 'DELETE_RATING',
  OPEN_RATING_MODAL: 'OPEN_RATING_MODAL',
  OPEN_ADD_RATING_MODAL_SUCCESS: 'OPEN_ADD_RATING_MODAL_SUCCESS',
  OPEN_EDIT_RATING_MODAL_SUCCESS: 'OPEN_EDIT_RATING_MODAL_SUCCESS',
  CLOSE_EDIT_RATING_MODAL: 'CLOSE_EDIT_RATING_MODAL',
  SUBMIT_RATING: 'SUBMIT_RATING',
  DELETE_REPAIRSHOP: 'DELETE_REPAIRSHOP',
  DELETE_REPAIRSHOP_SUCCESS: 'DELETE_REPAIRSHOP_SUCCESS',
  SUBMIT_REPAIRSHOP: 'SUBMIT_REPAIRSHOP',
  SUBMIT_REPAIRSHOP_SUCCESS: 'SUBMIT_REPAIRSHOP_SUCCESS',
  FETCH_REPAIRSHOP_LIST: 'FETCH_REPAIRSHOP_LIST',
  FETCH_REPAIRSHOP_LIST_SUCCESS: 'FETCH_REPAIRSHOP_LIST_SUCCESS',
  OPEN_REPAIRSHOP_MODAL: 'OPEN_REPAIRSHOP_MODAL',
  OPEN_REPAIRSHOP_MODAL_SUCCESS: 'OPEN_REPAIRSHOP_MODAL_SUCCESS',
  OPEN_REPAIRSHOP_USERS_MODAL: 'OPEN_REPAIRSHOP_USERS_MODAL',
  OPEN_ADD_REPAIRSHOP_USERS_MODAL_SUCCESS: 'OPEN_ADD_REPAIRSHOP_USERS_MODAL_SUCCESS',
  OPEN_ADD_REPAIRSHOP_MODAL_SUCCESS: 'OPEN_ADD_REPAIRSHOP_MODAL_SUCCESS',
  OPEN_ADD_REPAIRSHOP_MODAL: 'OPEN_ADD_REPAIRSHOP_MODAL',
  OPEN_EDIT_REPAIRSHOP_MODAL_SUCCESS: 'OPEN_EDIT_REPAIRSHOP_MODAL_SUCCESS',
  OPEN_EDIT_REPAIRSHOP_MODAL: 'OPEN_EDIT_REPAIRSHOP_MODAL',
  CLOSE_EDIT_REPAIRSHOP_MODAL: 'CLOSE_EDIT_REPAIRSHOP_MODAL',
  CLOSE_EDIT_REPAIRSHOP_MODAL_SUCCESS: 'CLOSE_EDIT_REPAIRSHOP_MODAL_SUCCESS',
  FETCH_REPAIRSHOP_USERS_LIST: 'FETCH_REPAIRSHOP_USERS_LIST',
  FETCH_REPAIRSHOP_USERS_LIST_SUCCESS: 'FETCH_REPAIRSHOP_USERS_LIST_SUCCESS',
  ASSING_USER_REPAIRSHOP: 'ASSING_USER_REPAIRSHOP',
  ASSING_USER_REPAIRSHOP_SUCCESS: 'ASSING_USER_REPAIRSHOP_SUCCESS',
  DELETE_USER_REPAIRSHOP: 'DELETE_USER_REPAIRSHOP',
  DELETE_USER_REPAIRSHOP_SUCCESS: 'DELETE_USER_REPAIRSHOP_SUCCESS',
  FETCH_CUSTOM_FIELD_LIST: 'FETCH_CUSTOM_FIELD_LIST',
  FETCH_CUSTOM_FIELD_LIST_SUCCESS: 'FETCH_CUSTOM_FIELD_LIST_SUCCESS',
  CLOSE_EDIT_CUSTOM_FIELD_MODAL: 'CLOSE_EDIT_CUSTOM_FIELD_MODAL',
  OPEN_CUSTOM_FIELD_MODAL_SUCCESS: 'OPEN_CUSTOM_FIELD_MODAL_SUCCESS',
  OPEN_CUSTOM_FIELD_MODAL: 'OPEN_CUSTOM_FIELD_MODAL',
  SUBMIT_CUSTOM_FIELD: 'SUBMIT_CUSTOM_FIELD',
  DELETE_CUSTOM_FIELD: 'DELETE_CUSTOM_FIELD',
  RECOVER_CUSTOM_FIELD: 'RECOVER_CUSTOM_FIELD',
  OPEN_DUPLICATE_SALE_TYPE_MODAL: 'OPEN_DUPLICATE_SALE_TYPE_MODAL',
  OPEN_DUPLICATE_SALE_TYPE_MODAL_SUCCESS: 'OPEN_DUPLICATE_SALE_TYPE_MODAL_SUCCESS',
  CLOSE_DUPLICATE_SALE_TYPE_MODAL: 'CLOSE_DUPLICATE_SALE_TYPE_MODAL',
  DESTINATION_CRANE_MASTER_SUCCES: 'DESTINATION_CRANE_MASTER_SUCCES',
  FETCH_DESTINATION_CRANE_MASTER: 'FETCH_DESTINATION_CRANE_MASTER',
  DESTINATION_CRANE_ITEM_SUCCES: 'DESTINATION_CRANE_ITEM_SUCCES',
  FETCH_DESTINATION_CRANE_ITEM: 'FETCH_DESTINATION_CRANE_ITEM',
  DELETE_DESTINATION_CRANE_ITEM: 'DELETE_DESTINATION_CRANE_ITEM',
  PUT_DESTINATION_CRANE_ITEM: 'PUT_DESTINATION_CRANE_ITEM',
  SET_DOCUMENT_TYPE_USE_ONLY_TEMPLATE_UPLOAD: 'SET_DOCUMENT_TYPE_USE_ONLY_TEMPLATE_UPLOAD',
  DELETE_MARITALSTATUS: 'DELETE_MARITALSTATUS',
  DELETE_MARITALSTATUS_SUCCESS: 'DELETE_MARITALSTATUS_SUCCESS',
  SUBMIT_MARITALSTATUS: 'SUBMIT_MARITALSTATUS',
  SUBMIT_MARITALSTATUS_SUCCESS: 'SUBMIT_MARITALSTATUS_SUCCESS',
  FETCH_MARITALSTATUS_LIST: 'FETCH_MARITALSTATUS_LIST',
  FETCH_MARITALSTATUS_LIST_SUCCESS: 'FETCH_MARITALSTATUS_LIST_SUCCESS',
  OPEN_MARITALSTATUS_MODAL: 'OPEN_MARITALSTATUS_MODAL',
  OPEN_MARITALSTATUS_MODAL_SUCCESS: 'OPEN_MARITALSTATUS_MODAL_SUCCESS',
  OPEN_ADD_MARITALSTATUS_MODAL_SUCCESS: 'OPEN_ADD_MARITALSTATUS_MODAL_SUCCESS',
  OPEN_ADD_MARITALSTATUS_MODAL: 'OPEN_ADD_MARITALSTATUS_MODAL',
  OPEN_EDIT_MARITALSTATUS_MODAL_SUCCESS: 'OPEN_EDIT_MARITALSTATUS_MODAL_SUCCESS',
  OPEN_EDIT_MARITALSTATUS_MODAL: 'OPEN_EDIT_MARITALSTATUS_MODAL',
  CLOSE_EDIT_MARITALSTATUS_MODAL: 'CLOSE_EDIT_MARITALSTATUS_MODAL',
  CLOSE_EDIT_MARITALSTATUS_MODAL_SUCCESS: 'CLOSE_EDIT_MARITALSTATUS_MODAL_SUCCESS',
  RESTART_MARITALSTATUS: 'RESTART_MARITALSTATUS',
  FETCH_PROCESSING_MANAGERS_BY_NAME_OR_UO: 'FETCH_PROCESSING_MANAGERS_BY_NAME_OR_UO',
  FETCH_PROCESSING_MANAGERS_BY_NAME_OR_UO_SUCCESS: 'FETCH_PROCESSING_MANAGERS_BY_NAME_OR_UO_SUCCESS',
  CREATE_PROCESSING_MANAGER: 'CREATE_PROCESSING_MANAGER',
  CREATE_PROCESSING_MANAGER_SUCCESS: 'CREATE_PROCESSING_MANAGER_SUCCESS',
  UPDATE_PROCESSING_MANAGER: 'UPDATE_PROCESSING_MANAGER',
  UPDATE_PROCESSING_MANAGER_SUCCESS: 'UPDATE_PROCESSING_MANAGER_SUCCESS',
  DELETE_PROCESSING_MANAGER: 'DELETE_PROCESSING_MANAGER',
  DELETE_PROCESSING_MANAGER_SUCCESS: 'DELETE_PROCESSING_MANAGER_SUCCESS',
  RECOVER_PROCESSING_MANAGER: 'RECOVER_PROCESSING_MANAGER',
  RECOVER_PROCESSING_MANAGER_SUCCESS: 'RECOVER_PROCESSING_MANAGER_SUCCESS',
  FETCH_PROCESSINGS_AGENCY_MANAGER: 'FETCH_PROCESSINGS_AGENCY_MANAGER',
  FETCH_PROCESSINGS_AGENCY_MANAGER_SUCCESS: 'FETCH_PROCESSINGS_AGENCY_MANAGER_SUCCESS',
  OPEN_PROCESSING_AGENCY_MANAGER_MODAL: 'OPEN_PROCESSING_AGENCY_MANAGER_MODAL',
  CLOSE_PROCESSING_AGENCY_MANAGER_MODAL: 'CLOSE_PROCESSING_AGENCY_MANAGER_MODAL',
  SET_PROCESSING_ANGENCY_MANAGER_FILTER: 'SET_PROCESSING_ANGENCY_MANAGER_FILTER',
  SET_PROCESSING_ANGENCY_MANAGER_MODAL_FILTER: 'SET_PROCESSING_ANGENCY_MANAGER_MODAL_FILTER',
  FETCH_CODE_COMMISSION_MASTER: 'FETCH_CODE_COMMISSION_MASTER',
  FETCH_CODE_COMMISSION_MASTER_SUCCESS: 'FETCH_CODE_COMMISSION_MASTER_SUCCESS',
  OPEN_CODE_COMMISSION_MODAL: 'OPEN_CODE_COMMISSION_MODAL',
  OPEN_CODE_COMMISSION_MODAL_SUCCESS: 'OPEN_CODE_COMMISSION_MODAL_SUCCESS',
  CLOSE_CODE_COMMISSION_MODAL: 'CLOSE_CODE_COMMISSION_MODAL',
  DELETE_CODE_COMMISSION: 'DELETE_CODE_COMMISSION',
  DELETE_CODE_COMMISSION_SUCCESS: 'DELETE_CODE_COMMISSION_SUCCESS',
  CREATE_OR_UPDATE_CODE_COMMISSION: 'CREATE_OR_UPDATE_CODE_COMMISSION',
  CREATE_OR_UPDATE_CODE_COMMISSION_SUCCESS: 'CREATE_OR_UPDATE_CODE_COMMISSION_SUCCESS'
}
