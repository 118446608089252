import React from 'react'
import { Route, Switch } from 'react-router-dom'
import App from './components/App'
import DefaultLayoutRoute from './routes/DefaultLayoutRoute'
import LoginPage from './containers/access/LoginPage'
import ResetPasswordPage from './containers/access/ResetPasswordPage'
import RememberPasswordPage from './containers/access/RememberPasswordPage'
import ProfilePage from './containers/profile/ProfilePage'
import DashboardPage from './containers/dashboard/DashboardPage'
import DossiersListPage from './containers/dashboard/dossiersList/DossiersListPage'
import DashboardTaskPage from './containers/dashboard/DashboardTaskPage'
import TasksPage from './containers/tasks/TasksPage'
import UnitsPage from './containers/units/UnitsPage'
import SearchPage from './containers/search/dossier/SearchPage'
import DocumentalSearchPage from './containers/search/documentalSearch/DocumentalSearchPage'
import ApprovePage from './containers/approve/ApprovePage'
import NewDossierPage from './containers/dossiers/newDossier/NewDossierPage'
import SalesPage from './containers/dossiers/sales/SalesPage'
import RegistrationDeliveryPage from './containers/dossiers/registrationDelivery/RegistrationDeliveryPage'
import RegistrationDeliveryHistoricalPage from './containers/dossierHistorical/registrationDelivery/registrationDeliveryHistoricalPage'
import SaleHistoricalPage from './containers/dossierHistorical/sale/SaleHistoricalPage'
import PaperworkPage from './containers/dossiers/paperwork/PaperworkPage'
import PaperworkHistoricalPage from './containers/dossierHistorical/paperwork/PaperworkHistoricalPage'
import PurchasePage from './containers/dossiers/purchase/PurchasePage'
import PurchaseHistoricalPage from './containers/dossierHistorical/purchase/PurchaseHistoricalPage'
import SalesDossierPage from './containers/dosierManagement/salesDossier/SalesDossierPage'
import PurchaseVNDossierPage from './containers/dosierManagement/purchasesVNDossier/PurchasesVNDossier'
import PurchaseVODossierPage from './containers/dosierManagement/purchasesVODossier/PurchasesVODossier'
import PurchaseUaCDossierPage from './containers/dosierManagement/purchasesUaCDossier/PurchasesUaCDossier'
import LogisticsDossierPage from './containers/dosierManagement/logisticsDossier/LogisticsDossier'
import TrafficProcessPage from './containers/dosierManagement/trafficProcess/TrafficProcessPage'
import MastersPage from './containers/masters/MastersPage'
import AdminPage from './containers/admin/AdminPage'
import TemplatesPage from './containers/templates/TemplatesPage'
import CampaignPage from './containers/dossiers/campaign/CampaignPage'
import CampaignHistoricalPage from './containers/dossierHistorical/campaign/CampaignHistoricalPage'
import conditionsMaintenance from './containers/conditionsMaintenance/ConditionsMaintenancePage'
import ReportPage from './containers/report/ReportPage'
import RedirectionPage from './containers/RedirectPage'
import Faq from './containers/faq/FaqPage'
import SignPage from './containers/sign/SignPage'
import importExportFilePage from './containers/importExportFile/importExportFilePage'
import DownloadErrorPage from './containers/downloadError/DownloadErrorPage'
import ServicePage from './containers/dossiers/service/ServicePage'
import StockPage from './containers/dossiers/stock/StockPage'
import StockHistoricalPage from './containers/dossierHistorical/stock/StockHistoricalPage'
import ServiceHistoricalPage from './containers/dossierHistorical/service/ServiceHistoricalPage'
import ManagementPage from './containers/management/ManagementPage'
import NonUpdatedDossiersPage from './containers/dossiers/common/NonUpdatedDossiersPage'
import PrivateRoutePage from './containers/routes/PrivateRoutePage'

export default function (store) {
  return (
    <App>
      <Switch>
        <Route exact path='/' render={() => (<RedirectionPage store={store} />)} />
        <Route path='/login' component={LoginPage} />
        <Route path='/remember-password' component={RememberPasswordPage} />
        <Route path='/reset-password/:tokenId' component={ResetPasswordPage} />
        <Route path='/download-error/:tag' component={DownloadErrorPage} />
        <PrivateRoutePage store={store}>
          <DefaultLayoutRoute>
            <Route path='/reset-password' component={ResetPasswordPage} />
            <Route path='/dashboard' component={DashboardPage} sectionCode='dashboard' exact />
            <Route path='/dashboard/:salesmanId/:urgencyTypeId/:subTypeId/:stageId/:page?/:columnOrder?' component={DossiersListPage} sectionCode='dashboard' />
            <Route path='/dashboard-tasks/' component={DashboardTaskPage} exact forceRefresh={true} />
            <Route path='/dashboard-tasks/:preselectTaskId/' component={DashboardTaskPage} exact />
            <Route path='/tasks' component={TasksPage} sectionCode='dashboard-tasks' />
            <Route path='/profile' component={ProfilePage} />
            <Route path='/admin-uo' component={UnitsPage} sectionCode='admin' />
            <Route exact path='/search' component={SearchPage} sectionCode='search' />
            <Route exact path='/documental-search' component={DocumentalSearchPage} sectionCode='documental-search' />
            <Route path='/approve' component={ApprovePage} />
            <Route path='/new-dossier' component={NewDossierPage} />
            <Route path='/dossier/sales/:dossierId' component={SalesPage} />
            <Route path='/dossier/salesh/:dossierId' component={SaleHistoricalPage} />
            <Route path='/dossier/registrationdelivery/:dossierId' component={RegistrationDeliveryPage} />
            <Route path='/dossier/registrationdeliveryh/:dossierId' component={RegistrationDeliveryHistoricalPage} />
            <Route path='/dossier/paperwork/:dossierId' component={PaperworkPage} />
            <Route path='/dossier/paperworkh/:dossierId' component={PaperworkHistoricalPage} />
            <Route exact path='/dossier/purchase/:dossierId' component={PurchasePage} />
            <Route path='/dossier/purchaseh/:dossierId' component={PurchaseHistoricalPage} />
            <Route path='/dossier/campaign/:dossierId' component={CampaignPage} />
            <Route path='/dossier/campaignh/:dossierId' component={CampaignHistoricalPage} />
            <Route path='/dossier/service/:dossierId' component={ServicePage} />
            <Route path='/dossier/serviceh/:dossierId' component={ServiceHistoricalPage} />
            <Route path='/dossier/stock/:dossierId' component={StockPage} />
            <Route path='/dossier/stockh/:dossierId' component={StockHistoricalPage} />
            <Route path='/new-dossier/:dossierId' component={NewDossierPage} />
            <Route path='/masters' component={MastersPage} sectionCode='masters' />
            <Route path='/admin' component={AdminPage} sectionCode='admin' />
            <Route path='/templates' component={TemplatesPage} sectionCode='templates' />
            <Route path='/conditions-maintenance' component={conditionsMaintenance} sectionCode='conditions-maintenance' />
            <Route path='/dossier-management/sales-dossier/:dossierId' component={SalesDossierPage} />
            <Route path='/dossier-management/purchase-vn-dossier' component={PurchaseVNDossierPage} />
            <Route path='/dossier-management/purchase-vo-dossier' component={PurchaseVODossierPage} />
            <Route path='/dossier-management/purchase-uac-dossier' component={PurchaseUaCDossierPage} />
            <Route path='/dossier-management/logistics-dossier' component={LogisticsDossierPage} />
            <Route path='/dossier-management/traffic-process' component={TrafficProcessPage} />
            <Route path='/reports' component={ReportPage} sectionCode='reports' />
            <Route path='/faq' component={Faq} sectionCode='faq'/>
            <Route path='/iDocSign' component={SignPage} sectionCode='iDocSign'/>
            <Route path='/importExportFile' component={importExportFilePage} sectionCode='importExportFile'/>
            <Route path='/management' component={ManagementPage} sectionCode='management'/>
            <Route path='/nonUpdatedDossiers' component={NonUpdatedDossiersPage} sectionCode='nonUpdatedDossiers'/>
          </DefaultLayoutRoute>
        </PrivateRoutePage>
      </Switch>
    </App>
  )
}
