import paperworkComponentActionTypes from '../../../constants/actions/dossier/common/paperworksTable'

export function initialState () {
  return {
    showModal: false,
    isEditionModal: false
  }
}

function fetchPaperworkEditionModalSuccess (state, { isEditionModal }) {
  return {
    ...state,
    showModal: true,
    isEditionModal
  }
}

function closePaperworkEditionModal (state) {
  return initialState()
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case paperworkComponentActionTypes.FETCH_PAPERWORK_EDITION_MODAL_SUCCESS:
      return fetchPaperworkEditionModalSuccess(state, action)
    case paperworkComponentActionTypes.CLOSE_PAPERWORK_EDITION_MODAL:
      return closePaperworkEditionModal(state)
    default:
      return state
  }
}
