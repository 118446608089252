import React, { PureComponent } from 'react'
import { reduxForm, Field, reset } from 'redux-form'
import { Form, Button, ProgressBar, Tooltip, OverlayTrigger } from 'react-bootstrap'
import { dossierTypesId } from '../../../constants/dossier/common/dossierTypes'
import { validateDate, validateDates } from '../../../util/validationFunctions'
import SimpleTablePage from '../../../containers/commons/general/SimpleTablePage'
import { urgencyTypesClass } from '../../../constants/backendIds'
import IDocCarPagination from '../../../components/commons/iDocCarPagination'
import VehiclePopover from '../../../_v2/components/search/VehiclePopover'
import { Link } from 'react-router-dom'
import { getDateNowMaxDatePicker, fromCamelToUnderscore } from '../../../util/utils'
import MapSectionsManagement from '../../../_v2/util/map-sections-management'
import InputDatePicker from '../../../_v2/components/commons/form/InputDatePicker'
import InputCheckBox from '../../../_v2/components/commons/form/InputCheckBox'
import InputSelect from '../../../_v2/components/commons/form/InputSelect'

class SelfSupplyManager extends PureComponent {
  constructor () {
    super()
    this.state = {
      fields: [],
      filters: [],
      dynamicFilters: [],
      disableResetButton: false,
      displayBar: 'none',
      progress: 0,
      progressFail: 0,
      errors: [],
      maxBar: 0,
      stepToVo: true,
      maxiTable: false,
      hideFilters: false
    }
  }

  componentDidMount () {
    this.props.actions.fetchDossierSubTypes(null, dossierTypesId.sales)
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (this.props.selectedTabManagement !== prevProps.selectedTabManagement) {
      this.handleClickReset()
    }
    return null
  }

  componentDidUpdate () {
    // don't remove use for getsnapshot
  }

  submitSearchForm (values) {
    let filters = values
    if (!filters.stepToVoPending) {
      filters.stepToVoPending = this.state.stepToVo
    }
    if (Object.keys(filters).length > 0) {
      let propFilters = this.props.filters
      propFilters.orderBy = '+number'
      this.props.actions.fetchSelfSupplyInfo({ ...filters, ...propFilters })
      this.setState({ dynamicFilters: filters, hideFilters: true })
    } else {
      this.props.actions.openModal('mustFillFilters')
    }
  }

  fieldFill (field) {
    this.setState({ fields: { ...this.state.fields, ...field } })
  }

  onDossierSubtypeChange (dossierSubtypeId) {
    this.props.actions.fetchStagesCombo(dossierSubtypeId)
  }

  dateValidation (value, allValues, props) {
    const result = validateDate(value, props)
    return result === Object(result) ? undefined : result
  }

  clearFields () {
    this.setState({ fields: {} })
  }

  handleClickReset (e) {
    if (e !== undefined) {
      e.preventDefault()
    }
    this.clearFields()
    this.props.actions.resetFiltersSelfSupplyInfo()
    this.props.dispatch(reset('selfSupplyManagerForm'))
    this.setState({ stepToVo: true, dynamicFilters: [] })
    this.props.actions.fetchStagesCombo(null)
  }

  exportData () {
    const tKey = 'MANAGEMENT.SELF_SUPPLY_TAB.'
    let columnsExcel = []
    columnsExcel.push(this.props.t(`${tKey}DOSSIER_NUMBER`) + '#Number')
    columnsExcel.push(this.props.t(`${tKey}ORGANIZED_UNIT`) + '#OrganizedUnit')
    columnsExcel.push(this.props.t('SEARCH.CATEGORIES.VEHICLE.BY_VEHICLE') + '#Vehicle')
    columnsExcel.push(this.props.t(`${tKey}STAGE`) + '#Stage')
    columnsExcel.push(this.props.t(`${tKey}CREATION_DATE`) + '#CreationDate')
    columnsExcel.push(this.props.t(`${tKey}LAST_MODIFICATION_DATE`) + '#LastUpdateDate')
    columnsExcel.push(this.props.t(`${tKey}STEP_TO_VO_PENDING`) + '#StepToVoPending')

    let cols = ''
    columnsExcel.forEach(element => {
      cols += element + '$'
    })
    cols = cols.substring(0, cols.length - 1)

    let filters = this.state.dynamicFilters
    this.props.actions.fetchSelfSupplyInfoDownload({ ...filters, ...this.props.filters }, cols)
  }

  onOrderChange (e) {
    const newOrderBy = this.props.filters.orderBy.includes(`+${e.currentTarget.dataset.field}`) ? `-${e.currentTarget.dataset.field}` : `+${e.currentTarget.dataset.field}`
    this.fetchTable({ ...this.props.filters, orderBy: newOrderBy })
  }

  onPageChange (page) {
    this.fetchTable({ ...this.props.filters, page })
  }

  getIconClass (field) {
    if (this.props.filters.orderBy.includes(field)) {
      return this.props.filters.orderBy.includes('-') ? 'ico-sort-selected-asc' : 'ico-sort-selected-desc'
    } else {
      return 'ico-sort'
    }
  }

  fetchTable (propFilters) {
    let filters = this.state.dynamicFilters
    this.props.actions.fetchSelfSupplyInfo({ ...filters, ...propFilters })
    this.setState({ dynamicFilters: filters })
  }

  stepToVo (dossier) {
    this.props.actions.passSelfSupplyVoAction(dossier.dossierId, dossier.organizedUnitId)
  }

  toogleHideFilters () {
    this.setState({ hideFilters: !this.state.hideFilters })
  }

  toogleMaxiTable () {
    this.setState({ maxiTable: !this.state.maxiTable })
  }

  showFilter (hasResults) {
    return !hasResults || !this.state.hideFilters
  }

  toggleFilterClass (hasResults) {
    if (!hasResults) {
      return 'iDocIcon-filter-hidden'
    }
    if (this.state.hideFilters) {
      return 'iDocIcon-filter-off'
    }
    return 'iDocIcon-filter'
  }

  hideFiltersBtn (hasResults) {
    if (!hasResults) {
      return 'hide'
    }
    return ''
  }

  extractMultipleCombo (combo, filters, item) {
    const values = combo.filter(object => {
      return filters[item] ? filters[item].some(selectedComboValue => object.id === selectedComboValue) : null
    })
    let value = values && this.formatMultipleValues(values)
    return value
  }

  extractMultipleComboStringArray (combo, filters, item) {
    let array = filters[item] ? filters[item].split(',').map(Number) : null
    const values = combo.filter(object => {
      return array ? array.some(selectedComboValue => object.id === selectedComboValue) : null
    })

    let value = values && this.formatMultipleValues(values)
    return value
  }

  formatMultipleValues (multiplecombovalues) {
    return multiplecombovalues
      .map((combovalue, index) => combovalue.value || combovalue.name)
      .join(', ')
  }

  selectedFilters () {
    const filters = this.props.dynamicFilters
    const tKey = 'MANAGEMENT.SELF_SUPPLY_TAB.'
    return Object.keys(filters)
      .filter(item => { return item !== 'page' && item !== 'pageSize' && item !== 'orderBy' })
      .map((item, index) => {
        let text = item
        let value = null
        const list = []
        switch (item) {
          case 'dossierSubtype':
          { const selectedSubtype = this.props.combos.dossierSubTypesCombo.find(subtype => subtype.id === filters[item])
            value = selectedSubtype && selectedSubtype.value
            text = this.props.t(`${tKey}${fromCamelToUnderscore(item).toUpperCase()}`)
            break }
          case 'organizedUnitId':
          {
            value = this.extractMultipleCombo(this.props.combos.lowestOrganizedUnitsCombo, filters, item)
            text = this.props.t(`${tKey}ORGANIZED_UNIT`)
            break }

          case 'stages':
          {
            value = this.extractMultipleCombo(this.props.combos.stagesCombo, filters, item)
            text = this.props.t(`${tKey}STAGE`)
            break }
          case 'dateToApply': {
            value = this.getDataToApplyCombo().find(d => d.id === filters[item]).value
            break
          }
          case 'dateType':
          {
            value = this.getDateTypeOptions().find(dateType => dateType.id === filters[item]).value
            break
          }
          case 'dateTo':
          case 'dateFrom': {
            const dateType = this.getDateTypeOptions().find(x => x.id === filters[item].dateType)?.value ?? ''
            text = text.replace('#DATETYPE#', dateType)
            value = filters[item]
            break
          }
          default:
          { value = filters[item]
            text = this.props.t(`${tKey}${fromCamelToUnderscore(item).toUpperCase()}`)
            break }
        }

        if (value) {
          list.push(<li key={index}>{text}: <strong>{value}</strong>;</li>)
        }
        return list
      })
  }

  render () {
    const {
      t, handleSubmit, className, hasSearch, count, dossierData, filters: { page, pages },
      combos: {
        lowestOrganizedUnitsCombo, dossierSubTypesCombo, stagesCombo
      }
    } = this.props
    const tKey = 'MANAGEMENT.SELF_SUPPLY_TAB.'
    const stepTooltip = (<Tooltip id="stepTooltip">{t(`${tKey}VO_STEP`)}</Tooltip>)
    const secMapManagement = MapSectionsManagement.find(function (s) { return s.page === 'selfSupplyManagementPage' })
    const hasResults = dossierData && dossierData.length > 0
    return (
      <>
        <div className="inner-results-panel">
          <div className="section-results-header">
            <div className="section-header-title">
              <OverlayTrigger placement="right" overlay={<Tooltip id="editProductTooltip">{`${t((this.props.hideMenu ? 'GLOBAL.TABLES.SHOW_INNER_MENU' : 'GLOBAL.TABLES.HIDE_INNER_MENU'))}`}</Tooltip>}>
                <a onClick={() => this.props.toogleInnerMenu()} className={this.props.hideMenu ? 'collapseBtn' : 'collapseBtn closeInnerMenu'}><i className={this.props.hideMenu ? 'iDocIcon-Open-inner-menu' : 'iDocIcon-Close-inner-menu'}></i></a>
              </OverlayTrigger>
              <h4>{t(`${secMapManagement.title}`)}
                <OverlayTrigger placement="left" overlay={<Tooltip id="editProductTooltip">{`${t((this.state.hideFilters ? 'GLOBAL.TABLES.SHOW_FILTERS' : 'GLOBAL.TABLES.HIDE_FILTERS'))}`}</Tooltip>}>
                  <a onClick={() => this.toogleHideFilters()} className={'toogleShowFilters ' + this.hideFiltersBtn(hasResults)}><i className={this.toggleFilterClass(hasResults)}></i></a>
                </OverlayTrigger>
              </h4>

            </div>
            <Form autoComplete="off" onSubmit={handleSubmit((values) => this.submitSearchForm(values))} className="filters">
              <div className={'search-form ' + (this.showFilter(hasResults) ? '' : 'hideFilters ')}>
                <Field
                  component={InputSelect}
                  colWidht={33} // 25, 33, 50, 75, 100 default=25
                  id="organizedUnitId"
                  name='organizedUnitId'
                  placeholder={t(`${tKey}ORGANIZED_UNIT`)}
                  controlLabel={t(`${tKey}ORGANIZED_UNIT`)}
                  options={lowestOrganizedUnitsCombo}
                  valueKey="id"
                  labelKey="value"
                  onInputChange={(val) => this.fieldFill({ organizedUnit: val })}
                  customClass={this.state.fields.organizedUnit ? className : ''}
                  multi={true}
                />
                <Field
                  component={InputSelect}
                  colWidht={33} // 25, 33, 50, 75, 100 default=25
                  id="dossierSubtype"
                  name='dossierSubtype'
                  placeholder={t(`${tKey}DOSSIER_SUBTYPE`)}
                  controlLabel={t(`${tKey}DOSSIER_SUBTYPE`)}
                  options={dossierSubTypesCombo}
                  valueKey="id"
                  labelKey="value"
                  onInputChange={(val) => { this.onDossierSubtypeChange(val); this.fieldFill({ dossierSubtypeId: val }) }}
                  customClass={this.state.fields.dossierSubtypeId ? className : ''}
                />
                <Field
                  component={InputSelect}
                  colWidht={33} // 25, 33, 50, 75, 100 default=25
                  id="stages"
                  name='stages'
                  placeholder={t(`${tKey}STAGE`)}
                  controlLabel={t(`${tKey}STAGE`)}
                  multi
                  menuContainerStyle={{ zIndex: 999 }}
                  options={stagesCombo}
                  valueKey="id"
                  labelKey="value"
                  disabled={stagesCombo.length === 0}
                  onInputChange={(val) => this.fieldFill({ stage: val })}
                  customClass={stagesCombo.length === 0 ? 'disabled' : className }
                />
                <Field
                  component={InputDatePicker}
                  colWidht={50} // 25, 33, 50, 75, 100 default=25
                  id="creationDateFrom"
                  name='creationDateFrom'
                  placeholder={t(`${tKey}CREATION_DATE_FROM`)}
                  controlLabel={t(`${tKey}CREATION_DATE_FROM`)}
                  validate={[this.dateValidation]}
                  onInputChange={(val) => this.fieldFill({ creationDateFrom: val })}
                  customClass={this.state.fields.creationDatefrom ? className : ''}
                  maxDate={getDateNowMaxDatePicker()}
                />
                <Field
                  component={InputDatePicker}
                  colWidht={50} // 25, 33, 50, 75, 100 default=25
                  id="creationDateTo"
                  name='creationDateTo'
                  placeholder={t(`${tKey}CREATION_DATE_TO`)}
                  controlLabel={t(`${tKey}CREATION_DATE_TO`)}
                  validate={[this.dateValidation]}
                  onInputChange={(val) => this.fieldFill({ creationDateTo: val })}
                  customClass={this.state.fields.creationDateTo ? className : ''}
                  maxDate={getDateNowMaxDatePicker()}
                />
                <Field
                  component={InputCheckBox}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="stepToVoPending"
                  name="stepToVoPending"
                  placeholder={t(`${tKey}STEP_TO_VO_PENDING`)}
                  onInputChange={(val) => { this.fieldFill({ stepToVoPending: val }); this.setState({ stepToVo: val }) } }
                  checked = {this.state.stepToVo}
                />
                <div className='search-form-actions flexInLine al-R'>
                  <Button id="btn_search"
                    type="submit"
                    className="_Btn accept stk">
                    <i className="iDocIcon-search"/>
                    {t('SEARCH.ACTIONS.SEARCH_BUTTON')}
                  </Button>
                  <Button
                    id="btn_reset"
                    onClick={(e) => this.handleClickReset(e)}
                    className={'_Btn' + (this.props.pristine ? ' accept' : ' accept')}
                    disabled={this.props.pristine}
                  >
                    <i className="iDocIcon-clear-decline"/> {t('SEARCH.ACTIONS.RESET_BUTTON')}
                  </Button>
                </div>
              </div>
            </Form>
          </div>
          <div className="section-results-body">
            <div className={'searchResoultsContainer' + (this.state.maxiTable ? ' maxiTable' : '')}>
              {hasSearch && ([
                <div className="table-wrapper" key="table-wrapper">
                  <ul className="list-inline">
                    {this.selectedFilters().length === 0 ? <li>{t(`${tKey}NO_SELECTED_FILTERS`)}</li> : (this.selectedFilters())}
                  </ul>
                  {/* table options, maximize, etc.... */}
                  {hasResults &&
                  <div className='flexInLine al-R'>
                    <div className='actionsField'>
                    </div>
                    <OverlayTrigger placement="left" overlay={<Tooltip id="editProductTooltip">{`${t((this.state.maxiTable ? 'GLOBAL.TABLES.MINIMIZE_TABLE' : 'GLOBAL.TABLES.MAXIMIZE_TABLE'))}`}</Tooltip>}>
                      <a onClick={() => this.toogleMaxiTable()} className='_Btn accept stk o-icn maximizeBtn'><i className={(this.state.maxiTable ? 'iDocIcon-Minimize' : 'iDocIcon-Maximize')}></i></a>
                    </OverlayTrigger>
                    <OverlayTrigger placement="left" overlay={<Tooltip id="editProductTooltip">{t('SEARCH.ACTIONS.EXPORT')}</Tooltip>}>
                      <a onClick={(event) => this.exportData(event)} className='_Btn new o-icn exportToExcel'><i className="iDocIcon-file-excel"></i></a>
                    </OverlayTrigger>
                  </div>}
                  {/* table */}
                  {hasResults ? [
                    <div className="table-responsive">
                      <SimpleTablePage
                        columns={[
                          <th key={0} />,
                          <th key={1} data-field="number" onClick={(event) => this.onOrderChange(event)}>
                            {t(`${tKey}DOSSIER_NUMBER`)}&nbsp;<i className={this.getIconClass('number')}/>
                          </th>,
                          <th key={2} data-field="organizedUnit" onClick={(event) => this.onOrderChange(event)}>
                            {t(`${tKey}ORGANIZED_UNIT`)}&nbsp;<i className={this.getIconClass('organizedUnit')}/>
                          </th>,
                          <th key={4}>{t('SEARCH.CATEGORIES.VEHICLE.BY_VEHICLE')}</th>,
                          <th key={6} data-field="stage" onClick={(event) => this.onOrderChange(event)}>
                            {t(`${tKey}STAGE`)}&nbsp;<i className={this.getIconClass('stage')}/>
                          </th>,
                          <th key={9} data-field="creationDate" onClick={(event) => this.onOrderChange(event)}>
                            {t(`${tKey}CREATION_DATE`)}&nbsp;<i className={this.getIconClass('creationDate')}/>
                          </th>,
                          <th key={5} data-field="lastUpdateDate" onClick={(event) => this.onOrderChange(event)}>
                            {t(`${tKey}LAST_MODIFICATION_DATE`)}&nbsp;<i className={this.getIconClass('lastUpdateDate')}/>
                          </th>,
                          <th key={12} data-field="actions" className='actions'>{t(`${tKey}ACTIONS`)}&nbsp;</th>
                        ]}
                        rows={dossierData && dossierData.map((row, idx) => (
                          <tr key={idx}>
                            <td>
                              <span className={`color-point ${urgencyTypesClass[row.urgencyType] || 'grey'}`}/>
                            </td>
                            <td>
                              <Link to={`/dossier${row.urlToNavigate}`}>
                                {row.number}
                              </Link>
                            </td>
                            <td>{row.organizedUnit}</td>
                            <td className="see-more">
                              <VehiclePopover
                                t={t}
                                vehicle={row.vehicle}
                                half={1 / (idx + 1)}
                                idx={idx}
                                id={'popover-vehicle' + idx}
                              />
                            </td>
                            <td>{row.stage}</td>
                            <td>{row.creationDate}</td>
                            <td>{row.lastUpdateDate}</td>
                            <td className='actions'>
                              <div className= {!row.isHistorical || !row.freeStockVo || !row.freeStockHistoricalVo ? 'disabled selfSupply-actions' : 'selfSupply-actions'}>
                                {
                                  !row.isHistorical || !row.freeStockVo || !row.freeStockHistoricalVo ? <i className="ico-discount" />
                                    : <OverlayTrigger placement="left" overlay={stepTooltip}>
                                      <a onClick={() => this.stepToVo(row)} className='_Btn accept stk o-icn '>
                                        <i className="ico-discount" />
                                      </a>
                                    </OverlayTrigger>
                                }
                              </div>
                            </td>
                          </tr>
                        ))}
                      />

                    </div>,
                    <div className="search-footer" key="search-footer">
                      <IDocCarPagination
                        id="btn_pag_sales"
                        page={page}
                        pagesCount={pages}
                        totalRows= {count}
                        onSelectAction={(page) => this.onPageChange(page)}
                      />
                    </div>
                  ] : <p className="no_results">{t('GLOBAL.TABLES.NO_RESULTS')}</p>}
                </div>
              ])}

              <ProgressBar style={{ display: this.state.displayBar }}>
                <ProgressBar max={this.state.maxBar} now={this.state.progress} label={`${this.state.progress}/${this.state.maxBar}`} striped variant="success" key={1} />
                <ProgressBar max={this.state.maxBar} now={this.state.progressFail} label={`${this.state.progressFail}/${this.state.maxBar}`} striped variant="danger" style={{ backgroundColor: '#d44b4b' }} key={2} />
              </ProgressBar>
            </div>
          </div>
        </div>
      </>
    )
  }
}

const validate = (values, { t }) => {
  const errors = {}
  if (values) {
    if (values.creationDateFrom && values.creationDateTo) {
      errors.creationDateTo = validateDates(t, values.creationDateFrom, values.creationDateTo)
    }
  }
  return errors
}

export default reduxForm({
  form: 'selfSupplyManagerForm',
  validate
})(SelfSupplyManager)
