import React, { PureComponent } from 'react'
import { Col, FormControl, FormGroup, ControlLabel, InputGroup, Glyphicon, OverlayTrigger, Tooltip } from 'react-bootstrap'

class InputPassword extends PureComponent {
  constructor () {
    super()
    this.state = {
      showPassword: false
    }
  }

  handleChange (value) {
    this.props.input.onChange(value)
    if (this.props.onInputChange) {
      this.props.onInputChange(value)
    }
  }

  handleBlur (value) {
    this.props.input.onBlur(value)
    if (this.props.onInputBlur) {
      this.props.onInputBlur(value)
    }
  }

  render () {
    const {
      id,
      colSm = 3,
      colSmOffset = 0,
      meta,
      showError = false,
      input,
      placeholder,
      rightIcon = '',
      leftIcon = '',
      controlLabel,
      componentClass,
      customClass = '',
      forceControlLabelInOneLine = false,
      disabled = false,
      textAlign,
      maxLength,
      autoComplete = 'off',
      t
    } = this.props
    const tKey = 'GLOBAL.FORMS.INPUT_PASSWORD.'
    const errorMessage = ((showError || meta.touched) && meta.error && typeof meta.error !== 'boolean')
      ? meta.error
      : null
    const tooltip = (
      <Tooltip id="tooltip-trigger-hover-focus">{t(tKey + (this.state.showPassword ? 'HIDE_PASSWORD' : 'SHOW_PASSWORD'))}</Tooltip>
    )
    return (
      <Col sm={colSm} smOffset={colSmOffset} className={customClass}>
        <FormGroup
          className={'form-group-password' + (rightIcon ? ' input-with-icon' : '')}
          validationState={(showError || meta.touched) && meta.error ? 'error' : null}>
          {controlLabel && <ControlLabel className={forceControlLabelInOneLine ? 'one-line ellipsis' : ''}>{controlLabel}</ControlLabel>}
          <InputGroup className="input-group-icon input-group-icon-right">
            <FormControl
              style={{ textAlign: textAlign || 'left' }}
              value={input.value}
              id={id}
              disabled={disabled}
              type={this.state.showPassword ? 'text' : 'password'}
              placeholder={placeholder}
              onChange={this.handleChange.bind(this)}
              componentClass={componentClass}
              onBlur={this.handleBlur.bind(this)}
              maxLength={maxLength}
              autoComplete={autoComplete}
            />
            <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={tooltip}>
              <InputGroup.Addon onClick={() => this.setState({ showPassword: !this.state.showPassword })}>
                <Glyphicon glyph={this.state.showPassword ? 'eye-close' : 'eye-open'}/>
              </InputGroup.Addon>
            </OverlayTrigger>
          </InputGroup>
          <i className={rightIcon || leftIcon} />
          {errorMessage && <span className='help-block text-center'>{errorMessage}</span>}
        </FormGroup>
      </Col>
    )
  }
}

export default InputPassword
