import apiFetch from '../apiFetch'

export default function (token, cells) {
  return apiFetch({
    endPoint: 'report/ExportCommercialReport',
    method: 'POST',
    body: cells,
    token: token,
    parseBlobAndAssign: true
  })
}
