import { put, call, select, takeEvery } from 'redux-saga/effects'
import { showLoader, hideLoader } from '../../../actions/common.js'
import mastersActionTypes from '../../../constants/actions/masters/masters'
import { getAuth } from '../../../selectors/access/auth'
import { handleError } from '../../errors/errorManager'
import {
  fetchSignatureConfigListSuccess, openEditSignatureConfigModalSuccess, closeSignatureConfigurationMasterModal,
  openEditSignatureEntityModalSuccess, closeSignatureEntityMasterModal
}
  from '../../../actions/masters/signatureConfiguration/signatureConfiguration'
import { getSignatureConfigFilter, getSignatureConfigList } from '../../../selectors/masters/signatureConfig'
import postSignatureConfigList from '../../../services/signatureConfig/postSignatureConfigList'
import { yesNoModal } from '../../modalsListeners/yesNoModal'
import postSignatureConfig from '../../../services/signatureConfig/postSignatureConfig.js'
import putSignatureConfig from '../../../services/signatureConfig/putSignatureConfig.js'
import deleteSignatureConfigService from '../../../services/signatureConfig/deleteSignatureConfig'
import deleteSignatureEntityService from '../../../services/signatureConfig/deleteSignatureEntity'
import putSignatureEntity from '../../../services/signatureConfig/putSignatureEntity.js'
import postSignatureEntity from '../../../services/signatureConfig/postSignatureEntity.js'

export function * fetchSignatureConfigList ({ filter }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield filter = yield filter || select(getSignatureConfigFilter)
    const signatureConfigList = yield call(postSignatureConfigList, auth.token, filter)
    yield put(fetchSignatureConfigListSuccess(signatureConfigList, filter))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchSignatureConfigList () {
  yield takeEvery(mastersActionTypes.FETCH_SIGNATURE_CONFIG, fetchSignatureConfigList)
}

export function * openSignatureConfigurationModal ({ signatureConfig }) {
  try {
    yield put(showLoader())
    yield put(openEditSignatureConfigModalSuccess(signatureConfig))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchOpenSignatureConfigurationModal () {
  yield takeEvery(mastersActionTypes.OPEN_EDIT_SIGNATURE_CONFIG_MODAL, openSignatureConfigurationModal)
}

export function * submitSignatureConfig ({ signatureConfig }) {
  try {
    const auth = yield select(getAuth)
    const filter = yield filter || select(getSignatureConfigFilter)
    const confirmed = yield call(yesNoModal, 'changeSignatureDocumentConfiguration')

    if (!confirmed) { return }

    yield put(showLoader())

    let functionToCall = signatureConfig.signDocumentConfigurationId ? putSignatureConfig : postSignatureConfig
    yield call(functionToCall, auth.token, signatureConfig)

    yield put(closeSignatureConfigurationMasterModal())
    yield call(fetchSignatureConfigList, filter)
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSubmitSignatureConfig () {
  yield takeEvery(mastersActionTypes.SUBMIT_SIGNATURE_CONFIG, submitSignatureConfig)
}

export function * deleteSignatureConfig ({ signatureConfigId }) {
  try {
    const confirmed = yield call(yesNoModal, 'changeSignatureDocumentConfiguration')
    if (!confirmed) { return }

    yield put(showLoader())
    const auth = yield select(getAuth)
    const filter = yield filter || select(getSignatureConfigFilter)
    yield call(deleteSignatureConfigService, auth.token, signatureConfigId)
    yield call(fetchSignatureConfigList, filter)
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDeleteSignatureConfig () {
  yield takeEvery(mastersActionTypes.DELETE_SIGNATURE_CONFIG, deleteSignatureConfig)
}

export function * openSignatureEntityModal ({ signatureEntity }) {
  try {
    yield put(showLoader())
    yield put(openEditSignatureEntityModalSuccess(signatureEntity))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchOpenSignatureEntityModal () {
  yield takeEvery(mastersActionTypes.OPEN_EDIT_SIGNATURE_ENTITY_MODAL, openSignatureEntityModal)
}

export function * submitSignatureEntity ({ signatureEntity }) {
  try {
    const auth = yield select(getAuth)
    const confirmed = yield call(yesNoModal, 'changeSignatureDocumentConfiguration')

    if (!confirmed) { return }

    yield put(showLoader())

    let functionToCall = signatureEntity.signEntityId ? putSignatureEntity : postSignatureEntity
    yield call(functionToCall, auth.token, signatureEntity)

    yield * UpdateSignatureConfigMasterModal(signatureEntity)

    yield put(closeSignatureEntityMasterModal())
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSubmitSignatureEntity () {
  yield takeEvery(mastersActionTypes.SUBMIT_SIGNATURE_ENTITY, submitSignatureEntity)
}

export function * deleteSignatureEntity ({ signatureEntity }) {
  try {
    const confirmed = yield call(yesNoModal, 'changeSignatureDocumentConfiguration')
    if (!confirmed) { return }

    yield put(showLoader())
    const auth = yield select(getAuth)
    const filter = yield filter || select(getSignatureConfigFilter)
    yield call(deleteSignatureEntityService, auth.token, signatureEntity.signEntityId)
    yield * UpdateSignatureConfigMasterModal(signatureEntity)
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDeleteSignatureEntity () {
  yield takeEvery(mastersActionTypes.DELETE_SIGNATURE_ENTITY, deleteSignatureEntity)
}

function * UpdateSignatureConfigMasterModal (signatureEntity) {
  const filter = yield select(getSignatureConfigFilter)
  yield call(fetchSignatureConfigList, filter)
  let signatureConfigList = yield select(getSignatureConfigList)
  let signatureConfigInModal = signatureConfigList
    .find(sc => sc.signDocumentConfigurationId === signatureEntity.signDocumentConfigurationId)
  yield put(openEditSignatureConfigModalSuccess(signatureConfigInModal))
}
