import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import SearchByPapForm from '../../../components/search/SearchByPapForm'
import { saveServicesAvailabilityDate } from '../../../actions/dossier/serviceDossier'
import { translate } from 'react-polyglot'
import { resetFilters } from '../../../actions/search/search'

export function mapStateToProps (state) {
  return {
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      resetFilters,
      saveServicesAvailabilityDate
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(SearchByPapForm))
