import { put, call, select, takeEvery } from 'redux-saga/effects'
import { getAuth } from '../../selectors/access/auth'
import { showLoader, hideLoader } from '../../actions/common'
import { fetchDashboardTaskSuccess } from '../../actions/dashboard/dashboardTask'
import dashboardTaskActionTypes from '../../constants/actions/dashboard/dashboardTask'
import messageModalActions from '../../actions/modals/messageModal'
import getTasks from '../../services/task/getTasks'
import { handleError } from '../errors/errorManager'

export function * fetchDashboardTask ({ urgencyType, resolve, reject }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    if (auth.token !== null) {
      const tasks = yield call(getTasks, auth.token, urgencyType, auth.organizedUnit)
      yield put(fetchDashboardTaskSuccess(tasks))
    }
    if (resolve) yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      if (reject) yield call(reject, customHandlerError)
    }
    yield call(handleError, { error, customHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchDashboardTask () {
  yield takeEvery(dashboardTaskActionTypes.FETCH_DASHBOARD_TASK, fetchDashboardTask)
}
