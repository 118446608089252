import { call, put, select, takeEvery } from 'redux-saga/effects'
import { getAuth } from '../../selectors/access/auth'
import getEntityCPTypeCombo from '../../services/dossier/get/getEntityCPTypeCombo'
import commonActions from '../../actions/common'
import messageModalActions from '../../actions/modals/messageModal'
import { fetchCombosSuccess } from '../../actions/combos/combos'
import combosActionTypes from '../../constants/actions/combos/combos'
import { handleError } from '../errors/errorManager'

export function * fetchEntityCPTypeCombo ({ colonyId, resolve }) {
  try {
    yield put(commonActions.showLoader())
    const auth = yield select(getAuth)
    let combo = []
    if (colonyId !== -1) {
      combo = yield call(getEntityCPTypeCombo, auth.token, colonyId)
    }
    if (resolve) {
      resolve(combo)
    } else {
      yield put(fetchCombosSuccess({ entityCPTypeCombo: combo }))
    }
  } catch (error) {
    const customHandler = function * () {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.FETCH_COMBO_ERROR'))
    }
    yield call(handleError, { error, customHandler })
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchFetchEntityCPTypeCombo () {
  yield takeEvery(combosActionTypes.FETCH_ENTITY_CP_COMBO, fetchEntityCPTypeCombo)
}
