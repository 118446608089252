import chargeQuiterActions from '../../../constants/actions/dossier/common/chargeQuiter'

export function openChargeModalDealer (dossierId, saleType) {
  return {
    type: chargeQuiterActions.OPEN_CHARGE_QUITER_MODAL_DEALER,
    dossierId,
    saleType
  }
}
export function openChargeModalDealerSuccess ({ dealerNumber, dossierId, saleType }) {
  return {
    type: chargeQuiterActions.OPEN_CHARGE_QUITER_MODAL_DEALER_SUCCESS,
    dealerNumber,
    dossierId,
    saleType
  }
}

export function closeChargeModalDealer () {
  return {
    type: chargeQuiterActions.CLOSE_CHARGE_QUITER_MODAL_DEALER
  }
}

export function getVehicleDataQuiter (dealerNumber, customer) {
  return {
    type: chargeQuiterActions.GET_VEHICLE_DATA_QUITER,
    dealerNumber,
    customer
  }
}

export function getVehicleDataQuiterSuccess ({ vehicleData }) {
  return {
    type: chargeQuiterActions.GET_VEHICLE_DATA_QUITER_SUCCESS,
    vehicleData
  }
}

export function sendVehicleDataQuiter (vehicleData, saleType, dossierId) {
  return {
    type: chargeQuiterActions.SEND_VEHICLE_DATA_QUITER,
    vehicleData,
    saleType,
    dossierId
  }
}

export function sendVehicleDataQuiterSuccess ({ result }) {
  return {
    type: chargeQuiterActions.SEND_VEHICLE_DATA_QUITER_SUCCESS,
    result
  }
}
