import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import DocumentsFleetModal from '../../components/management/DocumentsFleetModal'
import { translate } from 'react-polyglot'
import { changeEntityDossierFromFleet, resetFiltersFleetInfo, fetchFleetsInfo, fetchFleetsEntity, closeDocumentFleetModal, getDocumentFleetListSuccess, openOperateFleetModal } from '../../actions/management/management'
import { openModal } from '../../actions/common'
import { clearEntity, clearEntityChild } from '../../actions/dossier/common/client'

export function mapStateToProps (state) {
  return {
    ...state.management.documentsFleetModal,
    fleetFinderFilter: state.management.searchFleetInfo.dynamicFilters,
    dniCif: state.management.dniCif
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      openModal,
      changeEntityDossierFromFleet,
      resetFiltersFleetInfo,
      fetchFleetsInfo,
      fetchFleetsEntity,
      clearEntity,
      clearEntityChild,
      closeDocumentFleetModal,
      getDocumentFleetListSuccess,
      openOperateFleetModal
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(DocumentsFleetModal))
