import React, { PureComponent } from 'react'
import { Panel, Row, Col } from 'react-bootstrap'
import { Field } from 'redux-form'
import { permissions } from '../../../constants/backendIds'
import { getFieldsPermisions } from '../../../util/permisionFunctions'
import InputSelect from '../../commons/form/InputSelect'
import InputText from '../../commons/form/InputText'
import CustomFields from '../../commons/customFields/CustomFields'

class Fleet extends PureComponent {
  constructor () {
    super()
    this.state = {
      open: false,
      fieldsConfiguration: {
        fleetId: null,
        description: null,
        fleetAssignmentDate: null,
        observations: null
      }
    }
  }

  toggleComponent () {
    if (this.state.open) {
      this.props.actions.setActiveSection(false)
    }
    this.setState({ open: !this.state.open })
  }

  componentDidMount () {
    this.initializePermisions(this.props)
    this.props.actions.fetchFleet(this.props.dossier.organizedUnitId, false, this.props.dossier.dossierSubTypeId)
  }

  componentDidUpdate () {}

  getSnapshotBeforeUpdate (prevProps) {
    if (this.props.open) {
      this.setState({ open: true })
    }
    if (prevProps.dossier.organizedUnitId !== this.props.dossier.organizedUnitId) {
      this.props.actions.fetchFleet(this.props.dossier.organizedUnitId, false, this.props.dossier.dossierSubTypeId)
    }
    return null
  }

  initializePermisions (props) {
    const fieldKeys = Object.keys(this.state.fieldsConfiguration)
    const newState = getFieldsPermisions(props.componentConfiguration.fieldsConfiguration, fieldKeys, props.componentConfiguration.permission)
    this.setState({ fieldsConfiguration: newState.fieldsConfiguration })
  }

  chargeFleet (fleetId) {
    var fleet = this.props.combos.fleets.filter((fleet) => fleet.fleetId === fleetId.fleetId)
    fleet = fleet.length > 0 ? fleet[0] : null
    if (fleet == null || this.validateClient(fleet)) {
      this.props.updateFleetSelected(fleet)
    } else {
      this.props.openErrorMessageModal('DOSSIER_COMPONENTS.VALIDATIONS.FLEET_CLIENT_NOT_VALID')
      if (this.props.dossier.dossierFleetComponent.fleetId === null || this.props.dossier.dossierFleetComponent.fleetId === undefined) {
        this.props.updateFleetSelected(false)
      } else {
        this.props.updateFleetSelected(this.props.dossier.dossierFleetComponent)
      }
    }
  }

  validateClient (fleet) {
    if (this.props.dossier.entityComponent === null || this.props.dossier.entityComponent.entityId == null) {
      return true
    }
    if (this.props.dossier.entityComponent.entityId === fleet.entityId) {
      return true
    }
    return false
  }

  render () {
    const {
      t,
      readOnlyFields
      , combos, customFields
    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.FLEET.'
    return (
      <div className="charges-panel dossier-panel">
        <div className="header-panel" onClick={ () => this.toggleComponent()}>
          <div className="name-wrapper">
            <i className="ico-collect"/>
            <h4>{t(`${tKey}TITLE_PANEL`)}</h4>
          </div>
          <i className={this.state.open ? 'ico-arrow-black-up' : 'ico-arrow-black-down'}/>
        </div>
        <Panel expanded={this.state.open} onToggle={() => {}}>
          <Panel.Collapse>
            <Row>
              <Col >
                {this.state.fieldsConfiguration.fleetId && this.state.fieldsConfiguration.fleetId.permission !== permissions.hidden && (
                  <Field
                    colSm={4}
                    id="fleetId"
                    name="dossierFleetComponent.fleetId"
                    component={InputSelect}
                    options={combos.fleets}
                    disabled={readOnlyFields || this.state.fieldsConfiguration.fleetId.permission === permissions.readOnly}
                    valueKey="fleetId"
                    labelKey="fleetCode"
                    onInputChange={(fleetId) => this.chargeFleet({ fleetId })}
                    controlLabel={t(`${tKey}FLEET`)}
                    placeholder={t(`${tKey}FLEET`)}

                  />
                )}
              </Col>
              <Col >
                {this.state.fieldsConfiguration.description && this.state.fieldsConfiguration.description.permission !== permissions.hidden && (
                  <Field
                    colSm={4}
                    id="description"
                    name="dossierFleetComponent.description"
                    controlLabel={t(`${tKey}DESCRIPTION`)}
                    placeholder={t(`${tKey}DESCRIPTION`)}
                    component={InputText}
                    disabled={true}
                  />
                )}
              </Col>
              <Col >
                {this.state.fieldsConfiguration.fleetAssignmentDate && this.state.fieldsConfiguration.fleetAssignmentDate.permission !== permissions.hidden && (
                  <Field
                    colSm={4}
                    id="fleetAssignmentDate"
                    name="dossierFleetComponent.fleetAssignmentDate"
                    controlLabel={t(`${tKey}FLEET_ASSIGNMENT_DATE`)}
                    placeholder={t(`${tKey}FLEET_ASSIGNMENT_DATE`)}
                    component={InputText}
                    disabled={true}
                  />
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {this.state.fieldsConfiguration.observations && this.state.fieldsConfiguration.observations.permission !== permissions.hidden && (
                  <Field
                    colSm={12}
                    id="observations"
                    name="dossierFleetComponent.observations"
                    controlLabel={t(`${tKey}OBSERVATIONS`)}
                    placeholder={t(`${tKey}OBSERVATIONS`)}
                    component={InputText}
                    disabled={true}
                    componentClass="textarea"
                  />
                )}
              </Col>
            </Row>
            <CustomFields customFields={customFields} readOnlyFields={readOnlyFields} />
          </Panel.Collapse>
        </Panel>
      </div>
    )
  }
}
export default Fleet
