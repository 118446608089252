import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import SearchStockManagementForm from '../../components/search/SearchStockManagementForm'
import { translate } from 'react-polyglot'
import { fetchUserRepairWorkshopsCombo, fetchStagesCombo } from '../../../actions/combos/combos'
import { fetchStockManagementDossiers, fetchStockManagementDossiersDownload, setFilters, resetFilters, setHideFilters } from '../../../actions/search/search'
import { openModal } from '../../../actions/common'

export function mapStateToProps (state) {
  return {
    ...state.search
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchStagesCombo,
      fetchUserRepairWorkshopsCombo,
      fetchStockManagementDossiers,
      fetchStockManagementDossiersDownload,
      setFilters,
      resetFilters,
      setHideFilters,
      openModal
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(SearchStockManagementForm))
