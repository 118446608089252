import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { closeRatingMasterModal, submitRating } from '../../../actions/masters/rating/rating'
import RatingMasterModal from '../../../components/masters/rating/RatingMasterModal'

export function mapStateToProps (state) {
  const ratingModal = state.rating.ratingModal
  return {
    showModal: ratingModal.showModal,
    rating: ratingModal.rating,
    combos: {
      organizedUnitsAllCombo: state.combos.organizedUnitsAllCombo
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      submitRating,
      closeRatingMasterModal
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(RatingMasterModal))
