import React, { PureComponent } from 'react'
import { Button, Modal, Col } from 'react-bootstrap'
import { reduxForm, Field } from 'redux-form'
// import { dossierTypes as dossierTypesConstant } from '../../../constants/dossier/common/dossierTypes'
import { dossierSubTypeId } from '../../../constants/dossier/common/dossierSubType'
import PaperworkComponent from '../../../components/dossiers/paperwork/PaperworkComponent'
import InputCheckBox from '../../commons/form/InputCheckBox'

class DataPaperWorkFleetModal extends PureComponent {
  constructor () {
    super()
    this.state = {
      readOnly: false
    }
  }

  togglePanel () {

  }

  componentDidUpdate () {}

  getSnapshotBeforeUpdate (prevProps) {
    if (prevProps.showModal !== this.props.showModal && this.props.showModal) {
      this.props.initialize()
      this.setState({ readOnly: false })
      this.props.actions.fetchOperatePaperworkFleetPermissions(this.props.organizedUnitId)
      this.props.actions.getShowEconomic(this.props.organizedUnitId, dossierSubTypeId.tramite)
      this.props.actions.fetchPaperworkType(dossierSubTypeId.tramite)
      this.props.actions.fetchServiceDestinationTypes(this.props.dossiers[0].dossierId)
      this.props.actions.fetchVehicleType()
      this.props.actions.fetchProcessingAgency(this.props.organizedUnitId, this.props.dossiers[0].dossierId)
      this.props.actions.fetchOperationType(null, this.props.organizedUnitId, true)
    }
    return null
  }

  setActiveSection () {

  }

  saveData (values) {
    if (this.haveValues(values)) {
      let data = { dossierPaperworkComponent: values.dossierPaperworkComponent, dossierList: this.props.dossiers.map(x => { return x.dossierId }) }
      this.props.actions.setOperateDataPaperWorkFleetProcess(data, this.props.fleet.fleetId, () => this.props.actions.closeDataPaperWorkFleetModal())
    } else {
      this.props.actions.openModal('notChangesToSave')
    }
  }

  externalPaperworkChange (value) {
    this.setState({ readOnly: value })
  }

  haveValues (fields) {
    for (let key in fields) {
      if (fields[key] !== null && fields[key] !== undefined && fields[key] !== '') {
        return true
      }
    }
    return false
  }

  render () {
    const {
      t,
      showModal, handleSubmit, actions: { closeDataPaperWorkFleetModal, fetchProcessingAgencyManagersCombo }, combos, dossierPaperworkComponent, fieldConfiguration
    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.PAPERWORK_DOSSIER.PAPERWORK_COMPONENT.'
    if (showModal && fieldConfiguration) {
      return (
        <Modal className="fleet-created-select-modal languages-modal" show={showModal} onHide={closeDataPaperWorkFleetModal} backdrop={'static'}>
          <form className='formDataContactFleet' autoComplete="off" onSubmit={handleSubmit((values) => this.saveData(values))}>
            <Modal.Header closeButton onHide={this.props.actions.closeActionDataModal}>
              <Modal.Title>    <i className="ico-car-files"/>{t(`${tKey}TITLE`)}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <PaperworkComponent
                t={t}
                paperworkOpen={true}
                combos={ combos }
                actions={{
                  togglePanel: () => this.togglePanel(),
                  setActiveSection: () => this.setActiveSection(),
                  fetchProcessingAgencyManagersCombo
                }}
                dossierPaperworkComponent={dossierPaperworkComponent}
                componentConfiguration={fieldConfiguration}
                readOnlyFields={this.state.readOnly}
                dossierParentId={this.props.dossiers[0].dossierId}
                isFleetModal={true}
              />
              <Field
                id="externalPaperwork"
                name="dossierPaperworkComponent.externalPaperwork"
                colSm={6}
                inline
                placeholder={t('DOSSIER_COMPONENTS.PAPERWORK.EXTERNAL_PAPERWORK')}
                component={InputCheckBox}
                customClass='checkToClient'
                onChange={(val) => this.externalPaperworkChange(val)}
              />
            </Modal.Body>
            <Modal.Footer>
              <Col sm={12} className='fleet-created-moda-btn-exit'>
                <Button
                  id="btn_search"
                  className="btn-standard"
                  type='submit'
                  disabled={this.props.pristine}>
                  {t('MANAGEMENT.FLEETS_TAB.AUX_MODALS.SAVE')}
                </Button>
                <Button
                  id='btn-close-fleet-created-select-charge-modal'
                  className="btn-exit"
                  onClick={() => closeDataPaperWorkFleetModal()}>
                  {t('MANAGEMENT.FLEETS_TAB.AUX_MODALS.EXIT')}
                </Button>
              </Col>
            </Modal.Footer>
          </form>
        </Modal>

      )
    } else {
      return null
    }
  }
}

const validate = (values, props) => {
  const tKey = 'DOSSIER_COMPONENTS.PAPERWORK.MODAL.VALIDATION.'
  const errors = { dossierPaperworkComponent: {} }
  if (Object.keys(values).length > 0) {
    if (!values.dossierPaperworkComponent?.externalPaperwork) {
      if (!values.dossierPaperworkComponent?.paperworkTypeId) {
        errors.dossierPaperworkComponent.paperworkTypeId = props.t(`${tKey}FILL_PAPERWORK_TYPE`)
      }

      if (!values.dossierPaperworkComponent?.processingAgencyId) {
        errors.dossierPaperworkComponent.processingAgencyId = props.t(`${tKey}FILL_PROCESSING_AGENCY`)
      }
    }
  }

  return errors
}

export default reduxForm({
  form: 'dataPaperWorkFleetModal',
  validate
})(DataPaperWorkFleetModal)
