import getManagementActions from '../../services/managementActions/getManagementActions'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { getAuth } from '../../selectors/access/auth'
import commonActions from '../../actions/common'
import messageModalActions from '../../actions/modals/messageModal'
import { fetchManagementActionsSuccess } from '../../actions/combos/combos'
import combosActionTypes from '../../constants/actions/combos/combos'
import { handleError } from '../errors/errorManager'
import managementActions from '../../constants/actions/management/management'
import { getFleetOperationsSuccess } from '../../actions/management/management'
import { operationTabs } from '../../constants/management/operations'

export function * fetchManagementActions ({ tabId }) {
  try {
    yield put(commonActions.showLoader())
    const auth = yield select(getAuth)
    let response = yield call(getManagementActions, auth.token, { tabId })

    yield put(fetchManagementActionsSuccess(response))
  } catch (error) {
    const customHandler = function * () {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.FETCH_COMBO_ERROR'))
    }
    yield call(handleError, { error, customHandler })
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchfetchManagementActions () {
  yield takeLatest(combosActionTypes.FETCH_MANAGEMENT_ACTIONS, fetchManagementActions)
}

export function * fetchFleetOperateActions () {
  try {
    yield put(commonActions.showLoader())
    const auth = yield select(getAuth)

    let actions = yield call(getManagementActions, auth.token, { tabId: null })

    let dataActions = actions.filter(action => action.managementTabId === operationTabs.DATA)
    let operationActions = actions.filter(action => action.managementTabId === operationTabs.OPERATIONS)
    let dataPaperWorkActions = actions.filter(action => action.managementTabId === operationTabs.DATA_PAPERWORK)
    let operationPaperWorkActions = actions.filter(action => action.managementTabId === operationTabs.OPERATIONS_PAPERWORK)

    yield put(getFleetOperationsSuccess(dataActions, operationActions, dataPaperWorkActions, operationPaperWorkActions))
  } catch (error) {
    const customHandler = function * () {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.FETCH_COMBO_ERROR'))
    }
    yield call(handleError, { error, customHandler })
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchFetchFleetOperateActions () {
  yield takeLatest(managementActions.FETCH_OPERATE_ACTIONS_FLEET, fetchFleetOperateActions)
}
