export default {
  paths: [
    {
      path: 'dashboard',
      values: {
        showUOTree: true,
        showEntity: false,
        showVehicle: false
      }
    },
    {
      path: 'dashboard-tasks',
      values: {
        showUOTree: true,
        showEntity: false,
        showVehicle: false
      }
    },
    {
      path: 'dossier',
      values: {
        showUOTree: false,
        showEntity: true,
        showVehicle: true
      }
    }
  ],
  default: {
    showUOTree: false,
    showEntity: false,
    showVehicle: false
  }
}
