import React, { PureComponent } from 'react'
import { reduxForm, Field } from 'redux-form'
import { Row, Col, Table, Button, Tooltip, OverlayTrigger, Glyphicon } from 'react-bootstrap'
import IDocCarPagination from '../../commons/iDocCarPagination'
import InputText from '../../commons/form/InputText'
import InputCheckbox from '../../commons/form/InputCheckBox'
import FinancingTypeProductModalPage from '../../../containers/masters/FinancingTypeProduct/FinancingTypeProductModalPage'
class FinancingTypeProductMaster extends PureComponent {
  constructor () {
    super()
    this.state = {
      filter: {
        name: null,
        description: null
      }
    }
  }

  getSortIcon (column = '') {
    switch (this.props.filter.orderBy) {
      case '+' + column:
        return 'ico-sort-selected-asc'
      case '-' + column:
        return 'ico-sort-selected-desc'
      default:
        return 'ico-sort'
    }
  }

  changeOrder (field) {
    switch (this.props.filter.orderBy) {
      case `+${field}`:
        this.props.actions.fetchFinancingTypeProductList({ ...this.state.filter, ...this.props.filter, orderBy: `-${field}` })
        break
      default:
        this.props.actions.fetchFinancingTypeProductList({ ...this.state.filter, ...this.props.filter, orderBy: `+${field}` })
        break
    }
  }

  handleNameChange (value) {
    this.setState({ filter: { ...this.state.filter, name: value } })
  }

  handleDescriptionChange (value) {
    this.setState({ filter: { ...this.state.filter, description: value } })
  }

  searchFinancingTypeProductList () {
    this.props.actions.fetchFinancingTypeProductList({ ...this.props.filter, ...this.state.filter })
  }

  resetFilters () {
    this.setState({
      filter: {
        name: null,
        description: null
      }
    }, () => { this.searchFinancingTypeProductList() })
  }

  render () {
    const {
      t, financingTypeProductList, pagesCount, financingCount, filter, fields,
      filter: { page },
      actions: { deleteFinancingTypeProduct, openFinancingTypeProductModal, recoverFinancingTypeProduct, fetchFinancingTypeProductList }
    } = this.props

    const tKey = 'MASTERS.FINANCING_TYPE_PRODUCT.'
    const editTooltip = (<Tooltip id="editTooltip">{t('MASTERS.EDIT')}</Tooltip>)
    const deleteTooltip = (<Tooltip id="deleteTooltip">{t('MASTERS.DELETE')}</Tooltip>)
    const recoverTooltip = (<Tooltip id="recoverTooltip">{t('MASTERS.RECOVER')}</Tooltip>)
    const hasFilters = (this.state.filter.description || this.state.filter.name)

    return (
      <div className="admin-wrapper">
        <FinancingTypeProductModalPage/>
        <Row className="section-users-header">
          <Col sm={12}>
            <div className="header-panel">
              <h4>{t(`${tKey}FINANCING_TYPE_PRODUCT`)}</h4>
            </div>
          </Col>
        </Row>
        <Row>
          <div className="search-form">
            <span className="title-search"><i className="ico-uo" /> {t(`${tKey}SEARCH_FINANCING_TYPE_PRODUCT`)}</span>
            <Row>
              <Field
                id="filters.name"
                name="filters.name"
                colSm={2}
                controlLabel={t(`${tKey}NAME`)}
                placeholder={t(`${tKey}NAME`)}
                component={InputText}
                onInputChange={(value) => this.handleNameChange(value)}
              />
              <Field
                id="filters.description"
                name="filters.description"
                colSm={2}
                controlLabel={t(`${tKey}DESCRIPTION`)}
                placeholder={t(`${tKey}DESCRIPTION`)}
                component={InputText}
                onInputChange={(value) => this.handleDescriptionChange(value)}
              />
              <Col sm={8} className="btn-wrapper">
                <Button
                  className={'btn-white btn-icon btn-input btn-white search-button'}
                  style={{ width: '100%' }}
                  onClick={() => this.searchFinancingTypeProductList()}>
                  <i className="ico-search" />
                  {t(`${tKey}SEARCH_BTN`)}
                </Button>
                <Button
                  className={'btn-icon btn-input ' + (hasFilters ? 'btn-standard' : 'btn-cancel')}
                  onClick={() => {
                    this.props.initialize({})
                    this.resetFilters()
                  }}
                >
                  <i className="ico-cancel" />
                  {t('MASTERS.RESET_BUTTON')}
                </Button>
              </Col>
            </Row>
          </div>
        </Row>
        <Col sm={12} className="col-row  section-users-body">
          <Table className="editable-table" hover responsive>
            <thead>
              <tr>
                <th onClick={() => { this.changeOrder('name') }}>
                  {t(`${tKey}NAME`)}
                  <i className={this.getSortIcon('name')} />
                </th>
                <th onClick={() => { this.changeOrder('description') }}>
                  {t(`${tKey}DESCRIPTION`)}
                  <i className={this.getSortIcon('description')} />
                </th>
                <th onClick={() => { this.changeOrder('reactivatable') }}>
                  {t(`${tKey}REACTIVATABLE`)}
                  <i className={this.getSortIcon('reactivatable')} />
                </th>
                <th onClick={() => { this.changeOrder('repurchase') }}>
                  {t(`${tKey}REPURCHASE`)}
                  <i className={this.getSortIcon('repurchase')} />
                </th>
                {fields && fields.map((financingField, idx) => (
                  <th key={'field_' + idx}>
                    {('0' + (idx + 1)).slice(-2)}
                  </th>
                ))}
                <th style={{ width: '77px' }}>{t('DOSSIER_COMPONENTS.ACCESSORIES.ACTIONS')}</th>
              </tr>
            </thead>
            <tbody>
              {financingTypeProductList && financingTypeProductList.map((financingTypeMaster, idx) => (
                <tr key={idx} className={financingTypeMaster.isDeleted ? 'is-deleted' : ''}>
                  <td>{financingTypeMaster.name}</td>
                  <td>{financingTypeMaster.description}</td>
                  <td>{
                    <Field
                      component={InputCheckbox}
                      name={'selectedCheck_' + financingTypeMaster.financingTypeProductId}
                      checked = {financingTypeMaster.reactivatable}
                      disabled= {true}
                      id={'selectedCheck_' + financingTypeMaster.financingTypeProductId}
                    />}
                  </td>
                  <td>
                    { <Field
                      component={InputCheckbox}
                      name={'selectedCheck_rep' + financingTypeMaster.financingTypeProductId}
                      checked = {financingTypeMaster.repurchaseAgreement}
                      disabled= {true}
                      id={'selectedCheck_rep' + financingTypeMaster.financingTypeProductId}
                    />}
                  </td>
                  {fields && fields.map((financingField, idx) => (
                    <td key={'tdfield_' + idx}>
                      {
                        <Field
                          component={InputCheckbox}
                          name={'selectedCheck_rep_field' + financingTypeMaster.financingTypeProductId + financingField.financingFieldsProductId}
                          checked = {financingTypeMaster.fields[financingField.financingFieldsProductId] ? financingTypeMaster.fields[financingField.financingFieldsProductId] : false}
                          disabled= {true}
                          id={'selectedCheck_rep_field' + financingTypeMaster.financingTypeProductId + financingField.financingFieldsProductId}
                        />
                      }
                    </td>
                  ))}
                  <td>
                    {!financingTypeMaster.isDeleted ? (
                      <div>
                        <a onClick={() => openFinancingTypeProductModal(financingTypeMaster.financingTypeProductId)}>
                          <OverlayTrigger placement="left" overlay={editTooltip}>
                            <i className="ico-edit-white" />
                          </OverlayTrigger>
                        </a>
                        <a onClick={() => deleteFinancingTypeProduct(financingTypeMaster.financingTypeProductId)}>
                          <OverlayTrigger placement="left" overlay={deleteTooltip}>
                            <i className="ico-trash" />
                          </OverlayTrigger>
                        </a>
                      </div>
                    ) : (
                      <a onClick={() => recoverFinancingTypeProduct(financingTypeMaster.financingTypeProductId)}>
                        <OverlayTrigger placement="left" overlay={recoverTooltip}>
                          <Glyphicon glyph="repeat" />
                        </OverlayTrigger>
                      </a>
                    )}
                  </td>
                </tr>
              ))}
              {(financingTypeProductList === null || financingTypeProductList === undefined || financingTypeProductList.length === 0) &&
            <tr>
              <td colSpan={5 + (fields ? fields.length : 0) }><h2>{t(`${tKey}RESULTS_NOT_FOUND`)}</h2></td>
            </tr>}
              <tr className="button-table-wrapper">
                <td>
                  <Button
                    className="btn-editable-table btn-standard"
                    onClick={() => openFinancingTypeProductModal(null)}>
                  + {t('MASTERS.ADD')}
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>
          <div className="section-users-footer">
            <nav key={1} className="text-center">
              <IDocCarPagination
                id="btn_pag_finance"
                page={page}
                pagesCount={pagesCount}
                totalRows={financingCount}
                onSelectAction={(value) => fetchFinancingTypeProductList({ ...filter, ...this.state.filter, page: value })}
              />
            </nav>
          </div>
        </Col>
      </div>
    )
  }
}

export default reduxForm({
  form: 'financingTypeProductMaster'
})(FinancingTypeProductMaster)
