export const loadAuth = () => {
  try {
    const seriablizedAuth = sessionStorage.getItem('auth')
    if (seriablizedAuth === null) {
      return undefined
    }
    return JSON.parse(seriablizedAuth)
  } catch (err) {
    return undefined
  }
}

export const saveAuth = (auth) => {
  try {
    console.log('saving auth')
    const seriablizedAuth = JSON.stringify(auth)
    sessionStorage.setItem('auth', seriablizedAuth)
    return true
  } catch (err) {
    // Ignore write errors
    return false
  }
}

export const deleteAuth = () => {
  try {
    sessionStorage.removeItem('auth')
    return true
  } catch (err) {
    // Ignore write errors
    return false
  }
}
