import deviceActionTypes from '../../constants/actions/idocmobile/deviceInstall'

export function fetchDeviceInstalls (userId) {
  return {
    type: deviceActionTypes.FETCH_DEVICE_INSTALLS,
    userId
  }
}

export function fetchDeviceInstallsSuccess (deviceInstalls) {
  return {
    type: deviceActionTypes.FETCH_DEVICE_INSTALLS_SUCCESS,
    deviceInstalls
  }
}

export function fetchMaxDeviceInstalls (userId) {
  return {
    type: deviceActionTypes.FETCH_MAX_DEVICE_INSTALLS,
    userId
  }
}

export function fetchMaxDeviceInstallsSuccess (maxInstallations) {
  return {
    type: deviceActionTypes.FETCH_MAX_DEVICE_INSTALLS_SUCCESS,
    maxInstallations
  }
}

export function updateDeviceInstall (device) {
  return {
    type: deviceActionTypes.UPDATE_DEVICE_INSTALL,
    device
  }
}

export function updateDeviceInstallSuccess (device) {
  return {
    type: deviceActionTypes.UPDATE_DEVICE_INSTALL_SUCCESS,
    device
  }
}

export default {
  fetchDeviceInstalls, fetchDeviceInstallsSuccess, fetchMaxDeviceInstalls, fetchMaxDeviceInstallsSuccess, updateDeviceInstall, updateDeviceInstallSuccess
}
