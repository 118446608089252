
import React, { PureComponent } from 'react'
import { Field } from 'redux-form'
import { Button, Modal, Col, Row } from 'react-bootstrap'
import InputMoney from '../../../commons/form/InputMoney'
import InputSelect from '../../../commons/form/InputSelect'
import InputText from '../../../commons/form/InputText'
import { iemValues } from '../../../../constants/backendIds'

class EconomicPlanDiscountsModal extends PureComponent {
  constructor (props) {
    super()
    this.state = {
      taxquantity: null,
      amountWithoutTax: null,
      total: null,
      introducedAmount: null,
      originPristine: true
    }
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (!prevProps.showModal && this.props.showModal) {
      let taxvalue
      let pristineBeforeOpen = this.props.pristine
      this.props.ivaTypeCombo.filter((value) => {
        if ((this.props.valueIva) === value.id) {
          taxvalue = value.amountValue + 1
        }
      })
      this.props.iemTypeCombo.filter((value) => {
        if ((this.props.valueIem) === value.id) {
          if (this.props.valueIem === iemValues.other && value.amountValue === 0 && this.props.amountIem) {
            taxvalue += this.props.amountIem
          } else {
            taxvalue += value.amountValue
          }
        }
      })

      this.setState({ taxquantity: taxvalue, originPristine: pristineBeforeOpen }, () => this.props.actions.fetchDiscountModal(this.props.totalAmount, this.props.valueIva, this.props.valueIem, this.props.iemPercentage + '%', null, null, pristineBeforeOpen, this.props.dossierType))
    }
    return this.props
  }

  componentDidUpdate () {
  }

  handleBlurTotalOperation (event) {
    const ivaValue = this.props.ivaTypeCombo.find(x => x.id === this.props.valueIva).amountValue
    var iemValue = 0
    if (this.props.valueIem === iemValues.other) {
      iemValue = this.props.amountIem / 100
    } else {
      iemValue = this.props.iemTypeCombo.find(x => x.id === this.props.valueIem).amountValue / 100
    }

    const taxes = ivaValue + iemValue
    const objetive = parseFloat(event)
    const discount = this.props.totalAmount - objetive
    const discountWithoutTaxes = Number(discount / (1 + taxes)).toFixed(2)

    this.props.actions.fetchDiscountModal(null, null, null, null, discount, discountWithoutTaxes, this.props.pristine, this.props.dossierType)

    this.setState({ amountWithoutTax: discountWithoutTaxes, introducedAmount: event })
  }

  saveDiscount () {
    if (this.state.introducedAmount > this.props.totalAmount) {
      this.props.actions.openCommonModal('invalidAmount')
    } else {
      const discount = {
        name: 'descuento',
        amount: this.state.amountWithoutTax
      }
      return new Promise((resolve, reject) => {
        this.props.actions.createDiscount(discount, resolve, reject, this.props.dossierType)
        this.props.actions.closeModal()
      })
    }
  }

  closeModalWithPristine () {
    this.props.actions.fetchDiscountModal(0, 0, 0, 0, 0, 0, this.state.originPristine, this.props.dossierType)
    this.props.actions.closeModal()
  }

  render () {
    const { t, showModal, ivaTypeCombo, iemTypeCombo, valueIem } = this.props
    const tKey = 'DOSSIER_COMPONENTS.ECONOMIC_PLAN.MODAL_DISCOUNTS.'

    return (
      <Modal show={showModal} onHide={() => { this.closeModalWithPristine() }}>
        <Modal.Header closeButton>
          <Modal.Title>{t(`${tKey}TITLE`)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Field
              id="totalOperation"
              name="discountModal.totalOperation"
              colSm={6}
              controlLabel={t(`${tKey}TOTAL_BILL`)}
              placeholder={t(`${tKey}TOTAL_BILL`)}
              component={InputMoney}
              onInputBlur={(event) => this.handleBlurTotalOperation(event)}
            />
            <Field
              id="economicTotalOperation"
              name="discountModal.economicTotalOperation"
              colSm={6}
              controlLabel={t(`${tKey}ECONOMIC_TOTAL_BILL`)}
              placeholder={t(`${tKey}ECONOMIC_TOTAL_BILL`)}
              component={InputMoney}
              disabled={true}
            />
          </Row>
          <Row>
            <Field
              id="operationDiscountWithTax"
              name="discountModal.operationDiscountWithTax"
              colSm={6}
              controlLabel={t(`${tKey}ECONOMIC_OPERATION_TOTAL_IVA`)}
              placeholder={t(`${tKey}ECONOMIC_OPERATION_TOTAL_IVA`)}
              component={InputMoney}
              disabled={true}
            />
            <Field
              id="operationDiscountWithoutTax"
              name="discountModal.operationDiscountWithoutTax"
              colSm={6}
              controlLabel={t(`${tKey}ECONOMIC_OPERATION_TOTAL_NOT_IVA`)}
              placeholder={t(`${tKey}ECONOMIC_OPERATION_TOTAL_NOT_IVA`)}
              component={InputMoney}
              disabled={true}
            />
          </Row>
          <Row>
            <Field
              id="ivaType"
              name="discountModal.ivaType"
              type="text"
              valueKey="id"
              labelKey="value"
              colSm={6}
              controlLabel={t(`${tKey}SELECTED_TAX`)}
              placeholder={t(`${tKey}SELECTED_TAX`)}
              options={ivaTypeCombo}
              component={InputSelect}
              disabled={true}
            />
            {((valueIem === iemValues.other) ? ([
              <Field
                id="iemType"
                name="discountModal.iemType"
                type="text"
                valueKey="id"
                labelKey="value"
                key="iemType"
                colSm={3}
                controlLabel={t(`${tKey}SELECTED_IEM`)}
                placeholder={t(`${tKey}SELECTED_IEM`)}
                options={iemTypeCombo}
                component={InputSelect}
                disabled={true}
              />,
              <Field
                id="iemPercentage"
                key="iemPercentage"
                name="discountModal.iemPercentage"
                type="text"
                colSm={3}
                controlLabel={t(`${tKey}SELECTED_IEM`)}
                placeholder={t(`${tKey}SELECTED_IEM`)}
                component={InputText}
                disabled={true}
              />
            ]) : (
              <Field
                id="iemType"
                name="discountModal.iemType"
                type="text"
                valueKey="id"
                labelKey="value"
                colSm={6}
                controlLabel={t(`${tKey}SELECTED_IEM`)}
                placeholder={t(`${tKey}SELECTED_IEM`)}
                options={iemTypeCombo}
                component={InputSelect}
                disabled={true}
              />
            ))}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Col sm={6} className="btn-wrapper">
            <Button id="btn_save" className="btn-standard" onClick={() => { this.saveDiscount() }} >{t(`${tKey}SAVE_BTN`)}</Button>
          </Col>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default (EconomicPlanDiscountsModal)
