
import React, { PureComponent } from 'react'
import { Modal, Col, Row, FormGroup, Button } from 'react-bootstrap'
import { reduxForm, Field } from 'redux-form'
import InputText from '../../commons/form/InputText'
import InputSelect from '../../commons/form/InputSelect'
import InputCheckBox from '../../commons/form/InputCheckBox'
import TextInput from '../../commons/TextInput'
import { typeFields as metadataType } from '../../../constants/dossier/common/typeFields'
import { getComboTranslatedFromEnum, compareObjects } from '../../../util/utils'
import { getObjectWithLocales } from '../../../util/masters/masters'

class MetadataMasterModal extends PureComponent {
  constructor () {
    super()
    this.state = {
      isSelectedMetadataTypeSelect: false,
      initialDocumentTypeMetadata: {},
      functionToGetRealValue: null,
      functionToSetRealValue: null,
      fieldEntityName: null
    }
  }

  handleMetadataTypeChange (metadataTypeId) {
    const isListType = metadataTypeId === metadataType.list
    this.setState({ isSelectedMetadataTypeSelect: isListType })
    if (!isListType) {
      this.props.change('getListValues', null)
    }
  }

  submitDocument (values) {
    let languages = []
    Object.keys(values).map(item => {
      if (!!values[item] && typeof values[item] === 'object' && Object.keys(values[item]).length > 0) {
        languages.push(values[item])
      }
    })

    let basicDocumentTypeMetadata = {
      metadatoId: values.metadatoId,
      documentTypeId: this.props.documentTypeId,
      code: values.code,
      metadatoType: values.metadatoType,
      isMandatory: values.isMandatory,
      isReadOnly: values.isReadOnly,
      getListValues: values.getListValues,
      fieldEntityName: values.fieldEntityName,
      functionToGetRealValue: values.functionToGetRealValue,
      functionToSetRealValue: values.functionToSetRealValue,
      customValidations: values.customValidations,
      solpheoMetadataKey: values.solpheoMetadataKey,
      functionToExecuteInFront: values.functionToExecuteInFront
    }

    let docTypeCopy = Object.assign({}, { ...basicDocumentTypeMetadata, ...this.state.initialDocumentTypeMetadata })
    let initialDocumentTypeMetadataCopy = Object.assign({}, { ...this.state.initialDocumentTypeMetadata, ...basicDocumentTypeMetadata })
    const isEditedCriticalProperty = !compareObjects(docTypeCopy, initialDocumentTypeMetadataCopy, true)
    const documentTypeMetadata = { ...basicDocumentTypeMetadata, metadatoLocales: languages }
    this.props.actions.submitDocumentTypeMetadata(documentTypeMetadata, isEditedCriticalProperty)
  }

  initializeModal () {
    const documentTypeMetadata = this.props.documentTypeMetadata
    const documentTypeMetadataWithLocales = getObjectWithLocales(documentTypeMetadata, documentTypeMetadata?.metadatoLocales ?? [], this.props.languageList)
    const isListType = documentTypeMetadataWithLocales.metadatoType === metadataType.list
    if (!isListType) {
      documentTypeMetadataWithLocales.getListValues = null
    }
    if (documentTypeMetadataWithLocales.metadatoType === 0) {
      documentTypeMetadataWithLocales.metadatoType = null
    }

    this.setState({
      isSelectedMetadataTypeSelect: isListType,
      initialDocumentTypeMetadata: documentTypeMetadata || {},
      functionToGetRealValue: null,
      functionToSetRealValue: null,
      fieldEntityName: null
    })

    this.props.initialize({ ...documentTypeMetadataWithLocales })
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevProps.showModal !== this.props.showModal && this.props.showModal === true) {
      this.initializeModal()
    }
  }

  disableFieldEntityName () {
    return this.state.functionToSetRealValue?.trim().length > 0 || this.state.functionToGetRealValue?.trim().length > 0 || false
  }

  disableRealValueFunctionsFields () {
    return this.state.fieldEntityName?.trim().length > 0 || false
  }

  handleFieldEntityNameChange (value) {
    this.setState({
      fieldEntityName: value
    })
  }

  handleFunctionToGetRealValueChange (value) {
    this.setState({
      functionToGetRealValue: value
    })
  }

  handleFunctionToSetRealValueChange (value) {
    this.setState({
      functionToSetRealValue: value
    })
  }

  render () {
    const {
      t, handleSubmit,
      showModal, languageList, documentTypeMetadata
    } = this.props
    const tKey = 'MASTERS.METADATA_MASTERS.MODAL.'
    let metadatoTypes = getComboTranslatedFromEnum(t, tKey, metadataType)
    return (
      <Modal className="role-modal languages-modal" show={showModal} onHide={this.props.actions.closeMetadataMasterModal}>
        <form autoComplete="off" onSubmit={handleSubmit(this.submitDocument.bind(this))}>
          <Modal.Header closeButton onHide={this.props.actions.closeMetadataMasterModal}>
            <Modal.Title>{t(`${tKey}TITLE`)}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="inputs-wrapper">
              {documentTypeMetadata?.documentTypeMetadataId &&
              <Col sm={6} className="custom-width-col">
                <h2>{t(`${tKey}ID`)}</h2>
                <Field
                  name="metadatoId"
                  placeholder={t(`${tKey}ID`)}
                  component={InputText}
                  disabled={true}
                />
              </Col>}
              <Col sm={6} className="custom-width-col">
                <h2>{t(`${tKey}CODE`)}</h2>
                <Field
                  name="code"
                  placeholder={t(`${tKey}CODE`)}
                  component={InputText}
                  colSm={0}
                />
              </Col>
            </Row>
            <Row className="inputs-wrapper">
              <Col sm={6} className="custom-width-col">
                <h2>{t(`${tKey}METADATO_TYPE`)}</h2>
                <Field
                  name="metadatoType"
                  placeholder={t(`${tKey}METADATO_TYPE`)}
                  component={InputSelect}
                  options={metadatoTypes}
                  valueKey="id"
                  labelKey="value"
                  colSm={0}
                  onChange={(val) => this.handleMetadataTypeChange(val)}
                />
              </Col>
              <Col sm={6} className="custom-width-col">
                <h2>{t(`${tKey}IS_MANDATORY`)}</h2>
                <Field
                  name="isMandatory"
                  placeholder={t(`${tKey}IS_MANDATORY`)}
                  component={InputCheckBox}
                  colSm={0}
                />
              </Col>
            </Row>
            {this.state.isSelectedMetadataTypeSelect && <Row className="inputs-wrapper">
              <Col sm={6} className="custom-width-col">
                <h2>{t(`${tKey}GET_LIST_VALUES`)}</h2>
                <Field
                  name="getListValues"
                  placeholder={t(`${tKey}GET_LIST_VALUES`)}
                  component={InputText}
                  colSm={0}
                />
              </Col>
            </Row>}
            <Row className="inputs-wrapper">
              <Col sm={6} className="custom-width-col">
                <h2>{t(`${tKey}IS_READONLY`)}</h2>
                <Field
                  name="isReadOnly"
                  placeholder={t(`${tKey}IS_READONLY`)}
                  component={InputCheckBox}
                  colSm={0}
                />
              </Col>
              <Col sm={6} className="custom-width-col">
                <h2>{t(`${tKey}FIELD_ENTITY_NAME`)}</h2>
                <Field
                  name="fieldEntityName"
                  placeholder={t(`${tKey}FIELD_ENTITY_NAME`)}
                  component={InputText}
                  colSm={0}
                  disabled = {this.disableFieldEntityName()}
                  onInputChange = { (value) => this.handleFieldEntityNameChange(value)}
                  maxLength = {50}
                />
              </Col>
            </Row>
            <Row className="inputs-wrapper">
              <Col sm={6} className="custom-width-col">
                <h2>{t(`${tKey}FUNCTION_TO_GET_REAL_VALUE`)}</h2>
                <Field
                  name="functionToGetRealValue"
                  placeholder={t(`${tKey}FUNCTION_TO_GET_REAL_VALUE`)}
                  component={InputText}
                  onInputChange = { (value) => this.handleFunctionToGetRealValueChange(value)}
                  disabled = {this.disableRealValueFunctionsFields()}
                  maxLength = {50}
                />
              </Col>
              <Col sm={6} className="custom-width-col">
                <h2>{t(`${tKey}FUCNTION_TO_SET_REAL_VALUE`)}</h2>
                <Field
                  name="functionToSetRealValue"
                  placeholder={t(`${tKey}FUCNTION_TO_SET_REAL_VALUE`)}
                  component={InputText}
                  colSm={0}
                  onInputChange = { (value) => this.handleFunctionToSetRealValueChange(value)}
                  disabled = {this.disableRealValueFunctionsFields()}
                  maxLength = {50}
                />
              </Col>
            </Row>
            <Row className="inputs-wrapper">
              <Col sm={6} className="custom-width-col">
                <h2>{t(`${tKey}CUSTOM_VALIDATIONS`)}</h2>
                <Field
                  name="customValidations"
                  placeholder={t(`${tKey}CUSTOM_VALIDATIONS`)}
                  component={InputText}
                />
              </Col>
              <Col sm={6} className="custom-width-col">
                <h2>{t(`${tKey}SOLPHEO_METADATA_KEY`)}</h2>
                <Field
                  name="solpheoMetadataKey"
                  placeholder={t(`${tKey}SOLPHEO_METADATA_KEY`)}
                  component={InputText}
                  colSm={0}
                />
              </Col>
            </Row>
            <Row className="inputs-wrapper">
              <Col sm={6} className="custom-width-col">
                <h2>{t(`${tKey}FUNCTION_TO_EXECUTE_FRONT`)}</h2>
                <Field
                  name="functionToExecuteInFront"
                  placeholder={t(`${tKey}FUNCTION_TO_EXECUTE_FRONT`)}
                  component={InputText}
                />
              </Col>
            </Row>
            <div className="clearfix" />
            <FormGroup>
              {languageList && languageList.map((val, idx) => {
                return (
                  <Row key={idx} className="inputs-wrapper">
                    <Col sm={10} className="custom-width-col">
                      <h2>{t('MASTERS.NAME')}</h2>
                      <Field
                        name={`lang${val.languageId}.name`}
                        placeholder={t('MASTERS.NAME')}
                        component={TextInput}
                        maxLength={50}
                      />
                    </Col>
                    <Col sm={2} className="language-column">
                      <p>{`${val.description} (${val.name})`}</p>
                    </Col>
                    <Col sm={10} className="custom-width-col">
                      <h2>{t('MASTERS.DESCRIPTION')}</h2>
                      <Field
                        name={`lang${val.languageId}.description`}
                        placeholder={t('MASTERS.DESCRIPTION')}
                        component={TextInput}
                        maxLength={50}
                      />
                    </Col>
                    <Col sm={2} className="language-column">
                      <p>{`${val.description} (${val.name})`}</p>
                    </Col>
                  </Row>
                )
              })}
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <Col sm={12} className="btn-wrapper">
              <Button
                id="role-modal-submit-button"
                className="btn-standard"
                type="submit"
                disabled={this.props.pristine}>
                {t(`${tKey}SUBMIT_BUTTON`)}
              </Button>
            </Col>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default reduxForm({
  form: 'editDocumentTypeMetadata',
  validate: (values, props) => {
    let error = {}
    const tKey = 'MASTERS.METADATA_MASTERS.VALIDATION.'
    if (!values.code || values.code.length > 50) {
      error.code = props.t(`${tKey}CODE`)
    }
    if (!values.metadatoType || values.metadatoType <= 0) {
      error.metadatoType = props.t(`${tKey}METADATO_TYPE`)
    }
    if (!values.fieldEntityName && !values.functionToGetRealValue && !values.functionToSetRealValue) {
      error.fieldEntityName = props.t(`${tKey}FIELD_ENTITY_NAME`)
      error.functionToGetRealValue = props.t(`${tKey}FUNCTION_TO_GET_REAL_VALUE`)
      error.functionToSetRealValue = props.t(`${tKey}FUNCTION_TO_SET_REAL_VALUE`)
    }

    if (!values.fieldEntityName && (!values.functionToGetRealValue && !!values.functionToSetRealValue)) {
      error.functionToGetRealValue = props.t(`${tKey}FUNCTION_TO_GET_REAL_VALUE`)
    }
    if (!values.fieldEntityName && (!values.functionToSetRealValue && !!values.functionToGetRealValue)) {
      error.functionToSetRealValue = props.t(`${tKey}FUNCTION_TO_SET_REAL_VALUE`)
    }

    if (values.customValidations && values.customValidations.length > 50) {
      error.customValidations = props.t(`${tKey}CUSTOM_VALIDATIONS`)
    }
    if (values.solpheoMetadataKey && values.solpheoMetadataKey.length > 50) {
      error.solpheoMetadataKey = props.t(`${tKey}SOLPHEO_CONTAINER_ID`)
    }
    if (values.functionToExecuteInFront && values.functionToExecuteInFront.length > 50) {
      error.functionToExecuteInFront = props.t(`${tKey}FUNCTION_TO_EXECUTE_IN_FRONT`)
    }

    props.languageList && props.languageList.forEach((element) => {
      let valueLangName = values['lang' + element.languageId].name
      let valueLangDesc = values['lang' + element.languageId].description
      let errorLang = {
        name: null,
        description: null
      }
      if (!valueLangName) {
        errorLang.name = props.t(`${tKey}NAME`)
      } else if (valueLangName.length > 50) {
        errorLang.name = props.t(`${tKey}NAME_LENGTH`)
      }
      if (!valueLangDesc) {
        errorLang.description = props.t(`${tKey}DESCRIPTION`)
      } else if (valueLangDesc.length > 50) {
        errorLang.description = props.t(`${tKey}DESCRIPTION_LENGTH`)
      }
      error['lang' + element.languageId] = errorLang
    })

    return Object.keys(error).length > 0 ? error : {}
  }
})(MetadataMasterModal)
