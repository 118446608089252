import importVehicleModels from '../../constants/actions/vehicle/importVehicleModels'

function initialState () {
  return {
    errorExcel: '',
    sheetColumnErrors: [],
    totalCarlines: 0,
    totalCatalogues: 0,
    totalModels: 0,
    totalCatalogueModels: 0,
    totalColors: 0,
    totalTapestries: 0,
    error: false,
    showImportResult: false,
    brandId: 0
  }
}

function importVehicleModelFileSuccess (state, { result }) {
  return {
    ...state,
    ...result,
    error: !!result.errorExcel || !!result.sheetColumnErrors,
    showImportResult: true
  }
}

function cancelImportVehicleModelFile (state) {
  return initialState()
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case importVehicleModels.IMPORT_VEHICLE_MODEL_FILE_SUCCESS:
      return importVehicleModelFileSuccess(state, action)
    case importVehicleModels.CANCEL_IMPORT_VEHICLE_MODEL_FILE:
      return cancelImportVehicleModelFile(state)
    default:
      return state
  }
}
