export default {
  SET_DOSSIER_FLEET_DATA_CHANGE_PROCESS: 'SET_DOSSIER_FLEET_DATA_CHANGE_PROCESS',
  SET_ACTION_EXP_FLET_PROCESS: 'SET_ACTION_EXP_FLET_PROCESS',
  SET_APPROVE_FLEET_ACTION: 'SET_APPROVE_FLEET_ACTION',
  FIND_UNATTENDED_PROCESS: 'FIND_UNATTENDED_PROCESS',
  FIND_UNATTENDED_PROCESS_SUCCESS: 'FIND_UNATTENDED_PROCESS_SUCCESS',
  EXPORT_UNATTENDED_PROCESS_SUCCESS: 'EXPORT_UNATTENDED_PROCESS_SUCCESS',
  OPEN_UNATTENDED_PROCESS_DETAIL_MODAL: 'OPEN_UNATTENDED_PROCESS_DETAIL_MODAL',
  CLOSE_UNATTENDED_PROCESS_DETAIL_MODAL: 'CLOSE_UNATTENDED_PROCESS_DETAIL_MODAL',
  FIND_DOSSIER_NUMBERS_UNATTENDED_PROCESS: 'FIND_DOSSIER_NUMBERS_UNATTENDED_PROCESS',
  FIND_DOSSIER_NUMBERS_UNATTENDED_PROCESS_SUCCES: 'FIND_DOSSIER_NUMBERS_UNATTENDED_PROCESS_SUCCES',
  SET_UPLOAD_ZIP_DOCUMENT_FLEET_PROCESS: 'SET_UPLOAD_ZIP_DOCUMENT_FLEET_PROCESS',
  SET_ECONOMIC_PLAN_FLEET_PROCESS: 'SET_ECONOMIC_PLAN_FLEET_PROCESS',
  SET_OPERATE_DATA_SALE_FLEET_PROCESS: 'SET_OPERATE_DATA_SALE_FLEET_PROCESS',
  SET_OPERATE_DATA_SALE_FLEET_PROCESS_SUCCESS: 'SET_OPERATE_DATA_SALE_FLEET_PROCESS_SUCCESS',
  SET_OPERATE_DATA_CONTACT_FLEET_PROCESS: 'SET_OPERATE_DATA_CONTACT_FLEET_PROCESS',
  SET_OPERATE_DATA_PAPERWORK_FLEET_PROCESS: 'SET_OPERATE_DATA_PAPERWORK_FLEET_PROCESS',
  SET_OPERATE_DATA_FINANCING_FLEET_PROCESS: 'SET_OPERATE_DATA_FINANCING_FLEET_PROCESS',
  SET_OPERATE_DATA_FINANCING_FLEET_PROCESS_SUCCESS: 'SET_OPERATE_DATA_FINANCING_FLEET_PROCESS_SUCCESS',
  SET_OPERATE_DATA_CHARGE_FLEET_PROCESS: 'SET_OPERATE_DATA_CHARGE_FLEET_PROCESS',
  SET_OPERATE_DATA_CHARGE_FLEET_PROCESS_SUCCESS: 'SET_OPERATE_DATA_CHARGE_FLEET_PROCESS_SUCCESS',
  SET_OPERATE_ACTION_SEND_TO_APPROVAL_PROCESS: 'SET_OPERATE_ACTION_SEND_TO_APPROVAL_PROCESS',
  SET_OPERATE_ACTION_APPROVE_PROCESS: 'SET_OPERATE_ACTION_APPROVE_PROCESS',
  SET_OPERATE_ACTION_REJECT_PROCESS: 'SET_OPERATE_ACTION_REJECT_PROCESS',
  SET_OPERATE_ACTION_APPROVE_PAPERWORK_PROCESS: 'SET_OPERATE_ACTION_APPROVE_PAPERWORK_PROCESS',
  SET_OPERATE_ACTION_SEND_TO_AGENCY_PROCESS: 'SET_OPERATE_ACTION_SEND_TO_AGENCY_PROCESS',
  SET_OPERATE_ACTION_DELETE_PROCESS: 'SET_OPERATE_ACTION_DELETE_PROCESS',
  SET_INCORPORATE_DOCUMENT_FLEET_PROCESS: 'SET_INCORPORATE_DOCUMENT_FLEET_PROCESS',
  SET_VALIDATE_DOCUMENT_FLEET_PROCESS: 'SET_VALIDATE_DOCUMENT_FLEET_PROCESS',
  SET_REEDIT_DOCUMENT_FLEET_PROCESS: 'SET_REEDIT_DOCUMENT_FLEET_PROCESS',
  SET_CANCEL_DOCUMENT_FLEET_PROCESS: 'SET_CANCEL_DOCUMENT_FLEET_PROCESS',
  SET_OPERATE_ACTION_PRINT_DELIVERY_NOTE_PROCESS: 'SET_OPERATE_ACTION_PRINT_DELIVERY_NOTE_PROCESS',
  UNATTENDED_PROCESS_DOWNLOAD_DELIVERY_NOTES: 'UNATTENDED_PROCESS_DOWNLOAD_DELIVERY_NOTES',
  SET_OPERATE_ACTION_STORE_PROCESS: 'SET_OPERATE_ACTION_STORE_PROCESS',
  SET_REQUEST_SIGN_DOCUMENT_FLEET_PROCESS: 'SET_REQUEST_SIGN_DOCUMENT_FLEET_PROCESS',
  SET_OPERATE_DATA_DELIVERY_FLEET_PROCESS: 'SET_OPERATE_DATA_DELIVERY_FLEET_PROCESS',
  SET_OPERATE_DATA_SERVICE_FLEET_PROCESS: 'SET_OPERATE_DATA_SERVICE_FLEET_PROCESS',
  SET_DELETE_FLEET_PROCESS: 'SET_DELETE_FLEET_PROCESS',
  SET_CANCEL_SIGN_DOCUMENT_FLEET_PROCESS: 'SET_CANCEL_SIGN_DOCUMENT_FLEET_PROCESS',
  SET_REJECT_DOCUMENT_FLEET_PROCESS: 'SET_REJECT_DOCUMENT_FLEET_PROCESS',
  SET_FLEET_CHARGE_DOSSIERS_PROCESS: 'SET_FLEET_CHARGE_DOSSIERS_PROCESS',
  SET_UPDATE_CHARGE_DOSSIERS_PROCESS: 'SET_UPDATE_CHARGE_DOSSIERS_PROCESS',
  SET_RECOVER_CHARGE_DOSSIERS_PROCESS: 'SET_RECOVER_CHARGE_DOSSIERS_PROCESS'
}
