import { put, call, select, takeEvery } from 'redux-saga/effects'
import { change, SubmissionError } from 'redux-form'
import { getAuth } from '../../selectors/access/auth'
import localization from '../../services/commons/localization'
import getUserProfileService from '../../services/profile/getUserProfile'
import putUserProfileService from '../../services/profile/putUserProfile'
import postUserChangepassService from '../../services/profile/postUserChangepass'
import getLanguageListComboService from '../../services/language/getLanguageList'
import commonActions, { hideLoader, showLoader } from '../../actions/common.js'
import authActions from '../../actions/auth'
import { setSalesmanId } from '../../actions/dashboard/dashboard'
import messageModalActions from '../../actions/modals/messageModal'
import getSalesmanUserHierarchy from '../../services/salesman/getSalesmanUserHierarchy'
import profileActions, { fetchEventSubscriptionsSuccess } from '../../actions/profile/profile'
import profileActionTypes from '../../constants/actions/profile/profile'
import { handleError } from '../errors/errorManager'
import { saveAuth } from '../../services/sessionAuth'
import encryptPassword from '../../services/access/encryptPassword'
import getAllEventSubscriptions from '../../services/eventSubscription/getAllEventSubscriptions.js'
import postEventSubscription from '../../services/eventSubscription/postEventSubscription.js'
import deleteEventSubscription from '../../services/eventSubscription/deleteEventSubscription.js'
import { yesNoModal } from '../modalsListeners/yesNoModal.js'
import putEventSubscription from '../../services/eventSubscription/putEventSubscription.js'
import putEnableEventSubscription from '../../services/eventSubscription/putEnableEventSubscription.js'
import putDisableEventSubscription from '../../services/eventSubscription/putDisableEventSubscription.js'
import { setTheme } from '../../util/theme.js'

export function * fetchUserProfile () {
  try {
    yield put(commonActions.showLoader())
    const auth = yield select(getAuth)
    yield put(authActions.fetchPasswordPolicy())
    const data = yield call(getUserProfileService, auth.token)
    const languages = yield call(getLanguageListComboService, auth.token)
    yield put(profileActions.fetchUserProfileSuccess(data, languages))
    yield put(change('updateProfile', 'languageId', data.languageId))
    yield put(change('updateProfile', 'organizedUnit', data.organizedUnit))
    yield put(change('updateProfile', 'salesmansSelectedId', data.salesmansSelectedId))
    yield put(change('updateProfile', 'responsableSalesmansId', data.responsableSalesmansId))
    yield put(change('updateProfile', 'themeId', data.themeId))
    yield put(change('updateProfile', 'showChecklistAsTab', data.showChecklistAsTab))
    yield call(fetchComboSalesmanByUO, { organizedUnitId: data.organizedUnit })
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchFetchUserProfile () {
  yield takeEvery(profileActionTypes.FETCH_USER_PROFILE, fetchUserProfile)
}

export function * fetchComboSalesmanByUO ({ organizedUnitId, resolve, reject }) {
  try {
    yield put(commonActions.showLoader())
    const auth = yield select(getAuth)
    let salesmanCombo = yield call(getSalesmanUserHierarchy, auth.token, organizedUnitId)
    yield put(profileActions.fetchComboSalesmanByUOSuccess(salesmanCombo))
    if (resolve) yield call(resolve)
  } catch (error) {
    yield call(handleError, { error })
    if (reject) yield call(reject)
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchFetchComboSalesmanByUO () {
  yield takeEvery(profileActionTypes.FETCH_SALESMAN_COMBO, fetchComboSalesmanByUO)
}

export function * updateUserProfile ({ values, t, resolve, reject }) {
  try {
    yield put(commonActions.showLoader())
    let auth = yield select(getAuth)
    const profileValues = {}
    for (const prop in values) {
      if (prop !== 'newPassword' && prop !== 'pwdLoginRepeat' && prop !== 'oldPassword') {
        profileValues[prop] = values[prop]
      }
    }
    yield call(putUserProfileService, profileValues, auth.token)
    if (profileValues.themeId) {
      auth.themeId = profileValues.themeId
      setTheme(profileValues.themeId)
    }
    if (profileValues.showChecklistAsTab !== null) {
      auth.showChecklistAsTab = profileValues.showChecklistAsTab
    }

    const languages = yield call(getLanguageListComboService, auth.token)
    const languageName = languages.find(x => x.languageId === values.languageId).name
    yield put(commonActions.changeLocale(localization({ languageId: values.languageId, localeName: languageName })))

    if (values.oldPassword && values.newPassword) {
      let encriptedOldPasword = encryptPassword(values.oldPassword, auth.emailAddress)
      let encriptedNewPasword = encryptPassword(values.newPassword, auth.emailAddress)
      yield call(postUserChangepassService, { oldPassword: encriptedOldPasword, newPassword: encriptedNewPasword }, auth.token)
      yield put(change('updateProfile', 'oldPassword', ''))
      yield put(change('updateProfile', 'newPassword', ''))
      yield put(change('updateProfile', 'pwdLoginRepeat', ''))
    }

    const data = yield call(getUserProfileService, auth.token)

    let responsableSalesmansId = data.responsableSalesmansId && data.responsableSalesmansId.length > 0 ? data.responsableSalesmansId : null
    let responsableNotifications = data.responsableNotifications ? data.responsableNotifications : false
    let selectedSalesmanId = data.salesmansSelectedId && data.salesmansSelectedId.length > 0 ? data.salesmansSelectedId : 0
    yield put(profileActions.fetchUserProfileSuccess(data, languages))
    yield put(authActions.setSalesmanSelectedSuccess(selectedSalesmanId))
    yield put(setSalesmanId(selectedSalesmanId))
    yield call(saveAuth, { ...auth, selectedSalesmanId, responsableSalesmansId, responsableNotifications })
    yield put(change('updateProfile', 'languageId', data.languageId))
    yield put(change('updateProfile', 'organizedUnit', data.organizedUnit))
    yield put(messageModalActions.openSuccessMessageModal('PROFILE.UPDATE_PROFILE_SUCCESS'))
    const authState = yield select(state => state.auth)
    if (authState.organizedUnit !== data.organizedUnit) {
      yield put(authActions.setOrganizedUnitSuccess(data.organizedUnit))
      auth = yield select(getAuth)
      yield call(saveAuth, { ...auth, organizedUnit: data.organizedUnit })
    }
    yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json.tag === 'previouslyUsedPassword' || customHandlerError.json.tag === 'repeatedPassword') {
        yield call(reject, new SubmissionError({ newPassword: t('ERRORS.BACKEND_TAGS.repeatedPassword') }))
      } else if (customHandlerError.json.tag === 'passwordNotCorrect') {
        yield call(reject, new SubmissionError({ oldPassword: t('ERRORS.BACKEND_TAGS.passwordNotCorrect') }))
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    yield call(handleError, { error, customHandler })
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchUpdateUserProfile () {
  yield takeEvery(profileActionTypes.UPDATE_USER_PROFILE, updateUserProfile)
}

export function * saveEventSubscription ({ subscriptionId, eventSubscription, userId, resolve, reject }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    if (subscriptionId) {
      yield call(putEventSubscription, subscriptionId, eventSubscription, auth.token, userId)
    } else {
      yield call(postEventSubscription, eventSubscription, auth.token, userId)
    }
    yield call(fetchEventSubscriptions, { userId })
    if (resolve) resolve()
  } catch (error) {
    yield call(handleError, { error })
    if (reject) reject()
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSaveEventSubscription () {
  yield takeEvery(profileActionTypes.SAVE_EVENT_SUBSCRIPTION, saveEventSubscription)
}

export function * removeEventSubscription ({ subscriptionId, userId }) {
  try {
    const confirm = yield call(yesNoModal, 'deleteEventSubscription')
    if (confirm) {
      yield put(showLoader())
      const auth = yield select(getAuth)
      yield call(deleteEventSubscription, subscriptionId, auth.token)
      yield call(fetchEventSubscriptions, { userId })
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDeleteEventSubscription () {
  yield takeEvery(profileActionTypes.DELETE_EVENT_SUBSCRIPTION, removeEventSubscription)
}

export function * fetchEventSubscriptions ({ userId }) {
  try {
    yield put(commonActions.showLoader())
    const auth = yield select(getAuth)
    let eventSubscriptions = yield call(getAllEventSubscriptions, userId, auth.token)
    yield put(fetchEventSubscriptionsSuccess(eventSubscriptions))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchFetchEventSubscriptions () {
  yield takeEvery(profileActionTypes.FETCH_EVENT_SUBSCRIPTIONS, fetchEventSubscriptions)
}

export function * enableEventSubscription ({ subscriptionId, userId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(putEnableEventSubscription, subscriptionId, auth.token, userId)
    yield call(fetchEventSubscriptions, { userId })
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchEnableEventSubscription () {
  yield takeEvery(profileActionTypes.ENABLE_EVENT_SUBSCRIPTION, enableEventSubscription)
}

export function * disableEventSubscription ({ subscriptionId, userId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(putDisableEventSubscription, subscriptionId, auth.token, userId)
    yield call(fetchEventSubscriptions, { userId })
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDisableEventSubscription () {
  yield takeEvery(profileActionTypes.DISABLE_EVENT_SUBSCRIPTION, disableEventSubscription)
}
