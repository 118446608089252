import React, { PureComponent } from 'react'
// import { LinkContainer } from 'react-router-bootstrap'
// import { Col, Glyphicon, FormControl, FormGroup, InputGroup, Image, Button, Alert } from 'react-bootstrap'
import { Image } from 'react-bootstrap'
import Access from '../Access'

class DownloadError extends PureComponent {
  render () {
    const { t } = this.props
    return (
      <Access>
        <form className="auth" >
          <div className="company-wrapper">
            <h1 className="company-logo">
              <Image src="images/logo_kia.png" />
            </h1>
            <h2 className="company-name">
                iDocCar
            </h2>
          </div>
          <hr />
          <fieldset>
            <h3 className="title-modal-error">{t(`ERRORS.BACKEND_TAG_TRANSLATION.DOWNLOAD_APP_ERRORS.${this.props.match.params.tag}`)}</h3>
          </fieldset>
          <hr />
          <div className="poweredByWrapper">
            <Image src="images/poweredby.png" />
          </div>
        </form>
      </Access>
    )
  }
}

export default DownloadError
