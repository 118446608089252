import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { fetchDocumentTypeUseList, deleteDocumentTypeUse, openDocumentTypeUseModal } from '../../../actions/masters/documentTypeUse/documentTypeUse'
import DocumentTypeUseMaster from '../../../components/masters/documentTypeUse/DocumentTypeUseMaster'

export function mapStateToProps (state) {
  return {
    ...state.documentTypeUse,
    combos: {
      documentType: state.combos.documentTypes,
      permissionCombo: state.combos.permissionCombo,
      documentTypeUseCombo: state.combos.documentTypeUseCombo
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      deleteDocumentTypeUse,
      openDocumentTypeUseModal,
      fetchDocumentTypeUseList
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(DocumentTypeUseMaster))
