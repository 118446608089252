import React, { PureComponent } from 'react'
import { Table } from 'react-bootstrap'
import RelatedDossierList from '../../../../components/commons/RelatedDossierList'

class RelatedDossiers extends PureComponent {
  getRelatedDossierColumnIcon (columnCode) {
    if ('+' + columnCode === this.props.relatedDossiersOrderBy) {
      return 'ico-sort-selected-asc'
    } else if ('-' + columnCode === this.props.relatedDossiersOrderBy) {
      return 'ico-sort-selected-desc'
    } else {
      return 'ico-sort'
    }
  }

  onClickRelatedDossierColumn (columnCode) {
    let columnCodeOrder = '+' + columnCode
    if (columnCodeOrder === this.props.relatedDossiersOrderBy) {
      columnCodeOrder = '-' + columnCode
    }
    this.props.actions.fetchFilteredRelatedDossier(this.props.dossierId, columnCodeOrder)
  }

  render () {
    const {
      t,
      // panelsPaddingTop,
      history,
      dossierRelatedDossiers

    } = this.props

    const tKey = 'DOSSIER_COMPONENTS.'
    return (
      <>
        <div className="wrapper _nf">
          <div className='wrapper-header'>
            <div className="title-page">
              <i className='ico-relacion icon-page' />
              <h2>{t(`${tKey}RELATED_DOSSIER.RELATED_DOSSIER`)}</h2>
            </div>
            <div className='extraActions'> {/** Extra buttons & actions here **/}</div>
          </div>
          <div className='wrapper-body'>
            <Table hover responsive className="simple-table related-dossiers-table" id="related-dossiers-table">
              <thead>
                <tr>
                  <th onClick={() => this.onClickRelatedDossierColumn('urgencyType')}>
                    <i className={this.getRelatedDossierColumnIcon('urgencyType')}/>
                  </th>
                  <th onClick={() => this.onClickRelatedDossierColumn('number')}>
                    {t(`${tKey}RELATED_DOSSIER.DOSSIER_NUMBER`)} <i className={this.getRelatedDossierColumnIcon('number')}/>
                  </th>
                  <th onClick={() => this.onClickRelatedDossierColumn('organizedUnit')}>
                    {t(`${tKey}RELATED_DOSSIER.UO`)} <i className={this.getRelatedDossierColumnIcon('organizedUnit')}/>
                  </th>
                  <th onClick={() => this.onClickRelatedDossierColumn('dossierSubType')}>
                    {t(`${tKey}RELATED_DOSSIER.SUBTYPE`)} <i className={this.getRelatedDossierColumnIcon('dossierSubType')}/>
                  </th>
                  <th onClick={() => this.onClickRelatedDossierColumn('stage')}>
                    {t(`${tKey}RELATED_DOSSIER.STATE`)} <i className={this.getRelatedDossierColumnIcon('stage')}/>
                  </th>
                </tr>
              </thead>
              <tbody>
                <RelatedDossierList history={history} dossierRelatedDossiers={dossierRelatedDossiers} />
              </tbody>
            </Table>
          </div>
        </div>
      </>
    )
  }
}

export default RelatedDossiers
