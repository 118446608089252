import React, { PureComponent } from 'react'
import { Tooltip, Modal, Col, Row, Button, Table, OverlayTrigger } from 'react-bootstrap'
import { reduxForm, Field } from 'redux-form'
import InputSelect from '../../commons/form/InputSelect'
import InputText from '../../commons/form/InputText'
import InputNumber from '../../commons/form/InputNumber'
import { isJsonString } from '../../../util/utils'
import SignatureEntityModalPage from '../../../containers/masters/SignatureConfig/SignatureEntityModalPage'

class SignatureConfigMasterModal extends PureComponent {
  constructor () {
    super()
    this.state = {}
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevProps.showModal !== this.props.showModal && this.props.showModal === true) {
      this.props.initialize(this.props.signatureConfig)
    }
  }

  componentDidMount () {
    this.props.actions.fetchDocumentTypeUseCombo()
  }

  submitSignatureConfig (values) {
    const signatureConfig = {
      documentTypeUseId: values.documentTypeUseId,
      code: values.code,
      objectId: values.objectId,
      objectData: values.objectData,
      signDocumentConfigurationId: this.props.signatureConfig?.signDocumentConfigurationId
    }
    this.props.actions.submitSignatureConfig(signatureConfig)
  }

  render () {
    const {
      t, handleSubmit,
      showModal,
      signatureConfig,
      combos: { documentTypeUseCombo },
      actions: {
        closeSignatureConfigurationMasterModal,
        openEditSignatureEntityModal,
        deleteSignatureEntity
      }
    } = this.props
    const tKey = 'MASTERS.SIGNATURE_CONFIG_MASTERS.MODAL.'
    const editTooltip = (<Tooltip id="editTooltip">{t('MASTERS.EDIT')}</Tooltip>)
    const deleteTooltip = (<Tooltip id="deleteTooltip">{t('MASTERS.DELETE')}</Tooltip>)

    return (
      <div>
        <SignatureEntityModalPage/>
        <Modal
          className="role-modal languages-modal"
          show={showModal}
          onHide={closeSignatureConfigurationMasterModal}
          backdrop={'static'}>
          <form autoComplete="off"
            onSubmit={handleSubmit(this.submitSignatureConfig.bind(this))}>
            <Modal.Header
              closeButton
              onHide={closeSignatureConfigurationMasterModal}
            >
              <Modal.Title>{t(`${tKey}TITLE`)}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row className="inputs-wrapper">
                <Col sm={4} className="custom-width-col">
                  <h2>{t(`${tKey}DOCUMENT_TYPE_USE`)}</h2>
                  <Field
                    name="documentTypeUseId"
                    placeholder={t(`${tKey}DOCUMENT_TYPE_USE`)}
                    valueKey="id"
                    labelKey="value"
                    component={InputSelect}
                    colSm={0}
                    options={documentTypeUseCombo}
                  />
                </Col>
                <Col sm={4} className="custom-width-col">
                  <h2>{t(`${tKey}CONFIG_CODE`)}</h2>
                  <Field
                    id="code"
                    name="code"
                    placeholder={t(`${tKey}CONFIG_CODE`)}
                    component={InputText}
                    colSm={0}
                  />
                </Col>
                <Col sm={4} className="custom-width-col">
                  <h2>{t(`${tKey}OBJECT_ID`)}</h2>
                  <Field
                    id="objectId"
                    name="objectId"
                    placeholder={t(`${tKey}OBJECT_ID`)}
                    valueKey="id"
                    labelKey="value"
                    component={InputNumber}
                    colSm={0}
                  />
                </Col>
                <Col sm={4} className="custom-width-col">
                  <h2>{t(`${tKey}OBJECT_DATA`)}</h2>
                  <Field
                    id="objectData"
                    name="objectData"
                    placeholder={t(`${tKey}OBJECT_DATA`)}
                    component={InputText}
                    colSm={0}
                  />
                </Col>
              </Row>
              <Row className="inputs-wrapper">
                <Col sm={12} className="custom-width-col">
                  <h2>{t(`${tKey}SIGN_ENTITIES`)}</h2>
                  <Table className="editable-table" hover responsive>
                    <thead>
                      <tr>
                        <th>
                          {t(`${tKey}ORDER`)}
                        </th>
                        <th>
                          {t(`${tKey}SIGNATURE_TYPE`)}
                        </th>
                        <th>
                          {t(`${tKey}SIGNATURE_ENTITY_TYPE`)}
                        </th>
                        <th style={{ width: '77px' }}>{t('DOSSIER_COMPONENTS.ACCESSORIES.ACTIONS')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {signatureConfig?.signEntities && signatureConfig?.signEntities.map((signatureConfigEntity, idx) => (
                        <tr key={idx}>
                          <td>{signatureConfigEntity.order}</td>
                          <td>{signatureConfigEntity.signType}</td>
                          <td>{signatureConfigEntity.signEntityType}</td>
                          <td>
                            <div>
                              <a onClick={() => openEditSignatureEntityModal(signatureConfigEntity)}>
                                <OverlayTrigger placement="left" overlay={editTooltip}>
                                  <i className="ico-edit-white" />
                                </OverlayTrigger>
                              </a>
                              <a onClick={() => deleteSignatureEntity(signatureConfigEntity)}>
                                <OverlayTrigger placement="left" overlay={deleteTooltip}>
                                  <i className="ico-trash" />
                                </OverlayTrigger>
                              </a>
                            </div>
                          </td>
                        </tr>
                      ))
                      }
                      {(signatureConfig?.signEntities === null ||
                        signatureConfig?.signEntities === undefined ||
                        signatureConfig?.signEntities?.length === 0) &&
                        <tr>
                          <td colSpan={7}><h2>{t('MASTERS.SIGNATURE_CONFIG_MASTERS.RESULTS_NOT_FOUND')}</h2></td>
                        </tr>}
                      <tr className="button-table-wrapper">
                        <td>
                          <Button
                            className="btn-editable-table btn-standard"
                            onClick={() => openEditSignatureEntityModal(
                              { signDocumentConfigurationId: signatureConfig?.signDocumentConfigurationId }
                            )}
                            disabled={!signatureConfig?.signDocumentConfigurationId}
                          >
                            + {t('MASTERS.ADD')}
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Col sm={12} className="btn-wrapper">
                <Button
                  id="role-modal-submit-button"
                  className="btn-danger"
                  type="reset"
                  onClick={closeSignatureConfigurationMasterModal}>
                  {t(`${tKey}CANCEL_BUTTON`)}
                </Button>
                <Button
                  id="role-modal-submit-button"
                  className="btn-standard"
                  type="submit"
                  disabled={this.props.pristine}>
                  {t(`${tKey}SUBMIT_BUTTON`)}
                </Button>
              </Col>
            </Modal.Footer>
          </form>
        </Modal>
      </div>
    )
  }
}

export default reduxForm({
  form: 'editSignatureConfiguration',
  validate: (values, props) => {
    let error = {}
    const tKey = 'MASTERS.SIGNATURE_CONFIG_MASTERS.VALIDATION.'
    if (values.objectData && !isJsonString(values.objectData)) {
      error.objectData = props.t(`${tKey}OBJECT_DATA`)
    }
    if (!values.documentTypeUseId) {
      error.documentTypeUseId = props.t(`${tKey}DOCUMENT_TYPE_USE_REQUIRED`)
    }
    if (!values.code) {
      error.code = props.t(`${tKey}CODE`)
    }

    return Object.keys(error).length > 0 ? error : {}
  }
})(SignatureConfigMasterModal)
