import React, { PureComponent } from 'react'
import { Panel, Glyphicon, Button } from 'react-bootstrap'
import SimpleTablePage from '../../../containers/commons/general/SimpleTablePage'
import { compareValues } from '../../../util/utils'
import DocumentDetailsFleetModalPage from '../../../containers/management/auxModals/DocumentDetailsFleetModalPage'

class DocumentsListComponent extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      openDocumentPanel: true,
      filters: {
        orderBy: '+description',
        selectedCheck_massive: false
      }
    }
  }

  componentDidMount () {
    this.updateList()
  }

  updateList () {
    if (!this.props.dossiersMode) {
      if (this.props.fleet?.fleetId) {
        this.props.actions.getDocumentFleetList(this.props.fleet.fleetId, null)
      }
    } else {
      if (this.props.dossiers && this.props.dossiers.length > 0) {
        this.props.actions.getDocumentFleetList(null, this.props.dossiers)
      }
    }
  }

  collapsePanelDocuments () {
    this.setState({ openDocumentPanel: !this.state.openDocumentPanel })
  }

  componentDidUpdate (prevProps) {

  }

  getSnapshotBeforeUpdate (prevProps) {
    return null
  }

  getIconClass (field) {
    if (this.state.filters.orderBy.includes(field)) {
      return this.state.filters.orderBy.includes('-') ? 'ico-sort-selected-asc' : 'ico-sort-selected-desc'
    } else {
      return 'ico-sort'
    }
  }

  onOrderChange (e) {
    const newOrderBy = this.state.filters.orderBy.includes(`+${e.currentTarget.dataset.field}`) ? `-${e.currentTarget.dataset.field}` : `+${e.currentTarget.dataset.field}`
    this.setState({ filters: { orderBy: newOrderBy } })
    this.order(e.currentTarget.dataset.field, newOrderBy.includes('+') ? 'asc' : 'desc')
  }

  order (field, order) {
    let documents = JSON.parse(JSON.stringify(this.props.documentsFleetList))
    documents = documents.sort(compareValues(field, order))
    this.props.actions.getDocumentFleetListSuccess(documents)
  }

  detailDocument (document) {
    this.props.actions.openDocumentDetailsFleetModal(this.props.fleet, document, this.props.dossiers, this.props.dossiersMode)
  }

  render () {
    const {
      t,
      documentsFleetList,
      dossiers
    } = this.props
    const tKey = 'MANAGEMENT.FLEETS_TAB.'
    const numDossiers = this.props.dossiersMode ? dossiers.length : this.props.fleet?.numDossiers
    return (
      <>
        <div className="dossier-panel" >
          <div className="header-panel  fleet-panel-box" onClick={() => this.collapsePanelDocuments()}>

            <div className="name-wrapper fleet-panel-box-title">
              <h4>{t(`${tKey}DOCUMENTS`)}  <span className='fleet-panel-subTitle'>{' (' + t(`${tKey}SELECTED_DOSSIERS`) + ' ' + (numDossiers ?? 0) + ')'}</span> </h4>
            </div>

            <i className={this.state.openDocumentPanel ? 'ico-arrow-black-down fleet-panel-box-caret' : 'ico-arrow-black-up fleet-panel-box-caret'} />

          </div>
          <Panel expanded={this.state.openDocumentPanel} onToggle={() => {}}>
            <Panel.Collapse>
              <div id="fleet-dossier-details-table">
                <div className="flexInLine al-R">
                  <Button
                    id="update_dossiers_button"
                    className="_Btn accept stk flexInLine al-R"
                    onClick = {() => this.updateList()}>
                    <i className="iDocIcon-update"></i>
                    {t('MANAGEMENT.ADMIN_AUDIT.MODAL.UPDATE')}
                  </Button>
                </div>
                <SimpleTablePage
                  columns={[
                    <th key={0} data-field="documentTypeUseId" style={{ display: 'none' }}>
                    </th>,
                    <th key={1} data-field="description" onClick={(event) => this.onOrderChange(event)}>
                      {t(`${tKey}DOCUMENT`)}&nbsp;<i className={this.getIconClass('description')}></i>
                    </th>,
                    <th key={2} data-field="category" onClick={(event) => this.onOrderChange(event)}>
                      {t(`${tKey}CATEGORY`)}&nbsp;<i className={this.getIconClass('category')}/>
                    </th>,
                    <th key={3} data-field="requested" onClick={(event) => this.onOrderChange(event)}>
                      {t(`${tKey}REQUESTED`)}&nbsp;<i className={this.getIconClass('requested')}></i>
                    </th>,
                    <th key={4} data-field="incorporated" onClick={(event) => this.onOrderChange(event)}>
                      {t(`${tKey}INCORPORATED`)}&nbsp;<i className={this.getIconClass('incorporated')}/>
                    </th>,
                    <th key={5} data-field="validated" onClick={(event) => this.onOrderChange(event)}>
                      {t(`${tKey}VALIDATED`)}&nbsp;<i className={this.getIconClass('validated')}/>
                    </th>,
                    <th key={6} data-field="signRequested" onClick={(event) => this.onOrderChange(event)}>
                      {t(`${tKey}SIGN_REQUESTED`)}&nbsp;<i className={this.getIconClass('signRequested')}/>
                    </th>,
                    <th key={7} data-field="signed" onClick={(event) => this.onOrderChange(event)}>
                      {t(`${tKey}SIGNED`)}&nbsp;<i className={this.getIconClass('signed')}/>
                    </th>,
                    <th key={8} data-field="rejected" onClick={(event) => this.onOrderChange(event)}>
                      {t(`${tKey}REJECTED`)}&nbsp;<i className={this.getIconClass('rejected')}/>
                    </th>,
                    <th key={9} data-field="detail" className='flet-detail-dossier-check-title' >
                      {t(`${tKey}DETAIL`)}&nbsp;<i className={this.getIconClass('detail')}/>
                    </th>
                  ]}
                  rows={documentsFleetList && documentsFleetList.map((row, idx) => (
                    <tr key={idx}>
                      <td>{row.description}</td>
                      <td>{row.category}</td>
                      <td className='number-column'>{row.requested}</td>
                      <td className='number-column'>{row.incorporated}</td>
                      <td className='number-column'>{row.validated}</td>
                      <td className='number-column'>{row.signRequested}</td>
                      <td className='number-column'>{row.signed}</td>
                      <td className='number-column'>{row.rejected}</td>
                      <td>{
                        <a onClick={() => this.detailDocument(row)}>
                          <Glyphicon className='icon-action blue' glyph="eye-open"/>
                        </a>
                      }
                      </td>
                    </tr>
                  ))}

                ></SimpleTablePage>
              </div>

            </Panel.Collapse>
          </Panel>
        </div>
        <DocumentDetailsFleetModalPage getDocumentFleetList={!this.props.dossiersMode ? () => this.props.actions.getDocumentFleetList(this.props.fleet.fleetId, null) : () => this.props.actions.getDocumentFleetList(null, this.props.dossiers)}></DocumentDetailsFleetModalPage>
      </>
    )
  }
}

export default DocumentsListComponent
