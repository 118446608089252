import React, { PureComponent } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { Field, reduxForm } from 'redux-form'
import InputDropzone from '../../commons/form/InputDropzone'
import SepomexValidationModal from './SepomexValidationModal'

class LoadSepomexFileMaster extends PureComponent {
  constructor () {
    super()
    this.state = {
      values: {}
    }
  }

  submit (values) {
    this.setState({ values: values })
    this.props.actions.loadSepomexFileToValidate(values)
  }

  render () {
    const { t, handleSubmit, actions: { openModal } } = this.props
    const tKey = 'MASTERS.SEPOMEX.'

    return (
      <React.Fragment>
        <SepomexValidationModal
          t ={t}
          showModal = {this.props.loadSepomexFile.showValidationModal}
          validationResults = {this.props.loadSepomexFile.validationResult}
          importFile = {() => this.props.actions.loadSepomexFileToImport(this.state.values)}
          closeModal = {this.props.actions.cancelLoadSepomexFile}
        />
        <div className="admin-wrapper">
          <Row className="section-users-header">
            <Col sm={12}>
              <div className="header-panel">
                <h4>{t(`${tKey}TITLE`)}</h4>
              </div>
            </Col>
          </Row>
          <Row>
            <form autoComplete="off" onSubmit={handleSubmit(this.submit.bind(this))}>
              <Col sm={12} className="input-dropzone-wrapper">
                <Row className="form-group">
                  {
                    <Col sm={12} className={'document-edition-modal-middle-side'}>
                      <div className={'input-dropzone'} style={{ height: '100%' }}>
                        {
                          <Field
                            key={'field'}
                            colSm={12}
                            id={'sepomexFile'}
                            name={'sepomexFile' }
                            component={InputDropzone}
                            onInvalidFile={(acceptFiles) => openModal('nonValidFile', null, null, null, null, [{ id: '##FORMAT##', value: acceptFiles }])}
                            t={t}
                            acceptFiles = {'.txt'}
                            maxSizeFile = {20971520}
                            style={{ height: '100%' }}
                            className={'active'}
                          />
                        }
                      </div>
                    </Col>
                  }
                </Row>
                <Row>
                  <Col sm={12}>
                    <div className="text-left">
                      <Button className="btn-standard" type="submit">{t(`${tKey}SAVE`)}</Button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </form>
          </Row>
        </div>
      </React.Fragment>
    )
  }
}
const validate = (values, props) => {
  var error = {}
  if (!values.sepomexFile) {
    error.sepomexFile = props.t('MASTERS.SEPOMEX.VALIDATIONS.FILE_NOT_LOADED')
  }
  return error
}

export default reduxForm({
  form: 'LoadSepomexFileMaster',
  validate
})(LoadSepomexFileMaster)
