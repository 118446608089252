import mastersActionTypes from '../../../constants/actions/masters/masters'

function initialState () {
  return {
    campaignGroupList: [],
    campaignGroupCount: 0,
    pagesCount: 0,
    languageList: [],
    filter: {
      page: 1,
      orderBy: '',
      pageSize: 10
    },
    campaignGroupModal: {
      campaignId: null,
      showModal: false
    }
  }
}

function fetchcampaignGroupListSuccess (state, { campaignGroupList, filter }) {
  return {
    ...state,
    campaignGroupList: campaignGroupList.campaignGroupList,
    campaignGroupCount: campaignGroupList.campaignGroupCount,
    pagesCount: campaignGroupList.pagesCount,
    languageSelected: campaignGroupList.languageSelected,
    filter
  }
}

function openEditCampaignGroupModalSuccess (state, { languageList, campaignId }) {
  const initial = initialState()
  return {
    ...state,
    campaignGroupModal: {
      ...initial.campaignGroupModal,
      campaignId,
      languageList,
      nonEditableGroup: false,
      nonEditable: true,
      showModal: true
    }
  }
}

function openAddModalSuccess (state, { languageList, campaignId }) {
  const initial = initialState()
  return {
    ...state,
    campaignGroupModal: {
      ...initial.campaignGroupModal,
      languageList,
      campaignId: null,
      nonEditable: false,
      showModal: true
    }
  }
}

function closeEditModalSuccess (state) {
  return {
    ...state,
    campaignGroupModal: {
      ...initialState().campaignGroupModal
    }
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case mastersActionTypes.FETCH_CAMPAIGN_GROUP_LIST_SUCCESS:
      return fetchcampaignGroupListSuccess(state, action)
    case mastersActionTypes.OPEN_EDIT_CAMPAIGN_GROUP_MODAL_SUCCESS:
      return openEditCampaignGroupModalSuccess(state, action)
    case mastersActionTypes.OPEN_ADD_CAMPAIGN_GROUP_MODAL_SUCCESS:
      return openAddModalSuccess(state, action)
    case mastersActionTypes.CLOSE_EDIT_CAMPAIGN_GROUP_MODAL:
      return closeEditModalSuccess(state, action)
    default:
      return state
  }
}
