
import React, { PureComponent } from 'react'
import { Modal, Col, Button, Row } from 'react-bootstrap'
import SimpleTablePage from '../../../containers/commons/general/SimpleTablePage'
import SimpleInputText from '../../commons/simpleForm/SimpleInputText'
import SimpleSelect from '../../commons/simpleForm/SimpleSelect'
import SimpleInputDatePicker from '../../commons/simpleForm/SimpleInputDatePicker'
import ReassignReplacePurchaseRow from './ReassignReplacePurchaseRow'
import { validateAlfanumeric, validateChasisLength, validateMinChassisLengthSearch } from '../../../util/validationFunctions'
import { multipleNormalize, toUpperCase, trimAll } from '../../../util/normalizeFunctions'
import { purchaseTypesVn } from '../../../constants/dossier/purchase/purchaseTypes'
import settings from '../../../setting'
import { getFieldsPermisions } from '../../../util/permisionFunctions'
import { dossierSubTypes, permissions } from '../../../constants/backendIds'
import ReassingPurchaseModalPage from '../../../containers/dossiers/common/ReassingPurchaseModalPage'
import { convertStringToDate } from '../../../util/utils'

class ReassignReplacePurchaseModal extends PureComponent {
  constructor () {
    super()
    this.state = {
      dealerNumber: '',
      vinChassis: '',
      brand: '',
      model: '',
      color: '',
      tapestry: '',
      makerNumber: '',
      availabilityDateFrom: '',
      availabilityDateTo: '',
      hasSearch: false,
      showModalPurchase: false,
      fieldsConfiguration: {
        buttonOrderPurchase: null,
        brand: null,
        makerNumberRequired: null,
        colorRequired: null,
        vsbRequired: null,
        purchaseTypeIdInReassingPurchaseModal: null,
        availabilityDateInReassingPurchaseModal: null,
        carlineId: null,
        catalogueId: null,
        modelId: null,
        colorId: null,
        tapestryId: null,
        catalogue: null,
        carline: null,
        modelYear: null,
        color: null,
        tapestry: null,
        model: null
      },
      chasisError: '',
      validationChasisError: false,
      carlineId: '',
      catalogueId: '',
      modelId: '',
      colorId: '',
      tapestryId: '',
      carline: '',
      catalogue: '',
      modelYear: '',
      carlineDescription: '',
      catalogueDescription: '',
      modelDescription: '',
      colorDescription: '',
      tapestryDescription: '',
      availabilityDateError: ''

    }
  }

  initializePermisions (props) {
    let fieldKeys = Object.keys(this.state.fieldsConfiguration)
    const newState = getFieldsPermisions(props.componentConfiguration ? props.componentConfiguration.fieldsConfiguration : [],
      fieldKeys, props.componentConfiguration ? props.componentConfiguration.permission : [])
    this.setState({ fieldsConfiguration: newState.fieldsConfiguration })
  }

  resetFilters () {
    this.setState({
      dealerNumber: '',
      vinChassis: '',
      brand: '',
      model: '',
      color: '',
      makerNumber: '',
      availabilityDateFrom: '',
      availabilityDateTo: '',
      hasSearch: false,
      carlineId: '',
      catalogueId: '',
      modelId: '',
      catalogueModelId: '',
      colorId: '',
      tapestryId: '',
      carline: '',
      catalogue: '',
      modelYear: ''

    })
    this.props.actions.resetCarlineCombo()
    this.props.actions.resetCatalogueCombo()
    this.props.actions.resetColorCombo()
    this.props.actions.resetTapestryCombo()
  }

  searchPurchases () {
    const error = validateMinChassisLengthSearch(this.state.vinChassis, this.props)
    error.state ? this.setState({ validationChasisError: error.state, chasisError: error.value })
      : this.setState({ validationChasisError: error.state, chasisError: error.value })
    if (!error.state) {
      new Promise((resolve, reject) => {
        this.props.actions.searchFilteredPurchaseDossier({ ...this.state, isVo: this.props.isVo, organizedUnitId: this.props.organizedUnitId }, resolve, reject)
      }).then(() => {
        this.setState({ hasSearch: true })
      })
    }
  }

  validateIdPurchase () {
    const error = {}
    error.dealerNumber = {}
    if (this.state.dealerNumber) {
      const idDealeError = validateAlfanumeric(this.state.dealerNumber, this.props, false)
      if (Object.keys(idDealeError).length > 0) {
        error.dealerNumber = idDealeError
        return error
      }
    }
  }

  UNSAFE_componentWillMount () {
    this.initializePermisions(this.props)
  }

  componentDidUpdate (prevProps) {
    var carlineDescription = this.props.combos.carlineCombo.find(x => x.Id === prevProps.order.carlineId)
    if (carlineDescription) {
      this.setState({ carlineDescription: carlineDescription.value })
    }

    var catalogueDescription = this.props.combos.catalogueCombo.find(x => x.Id === prevProps.order.catalogueId)
    if (catalogueDescription) {
      this.setState({ catalogueDescription: catalogueDescription.value })
    }

    var modelDescription = this.props.combos.modelCombo.find(x => x.Id === prevProps.order.modelId)
    if (modelDescription) {
      this.setState({ modelDescription: modelDescription.value })
    }

    var colorDescription = this.props.combos.colorCombo.find(x => x.Id === prevProps.order.colorId)
    if (colorDescription) {
      this.setState({ colorDescription: colorDescription.value })
    }

    var tapestryDescription = this.props.combos.tapestryCombo.find(x => x.Id === prevProps.order.tapestryId)
    if (tapestryDescription) {
      this.setState({ tapestryDescription: tapestryDescription.value })
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.componentConfiguration !== this.props.componentConfiguration) {
      this.initializePermisions(nextProps)
    }

    if (!this.props.showModal && nextProps.showModal) {
      this.setState({
        dealerNumber: '',
        vinChassis: nextProps.order.vinChassis || '',
        brand: nextProps.order.brandId || '',
        model: nextProps.order.model || '',
        color: nextProps.order.color || '',
        makerNumber: nextProps.order.makerNumber || '',
        availabilityDateFrom: '',
        availabilityDateTo: '',
        hasSearch: false,
        carlineId: nextProps.order.carlineId || '',
        catalogueId: nextProps.order.catalogueId || '',
        modelId: nextProps.order.modelId || '',
        catalogueModelId: nextProps.order.catalogueModelId || '',
        colorId: nextProps.order.colorId || '',
        tapestryId: nextProps.order.tapestryId || '',
        carline: nextProps.order.carline || '',
        catalogue: nextProps.order.catalogue || '',
        modelYear: nextProps.order.modelYear || ''
      })
    }
  }

  openModalPurchase (value) {
    let error = validateChasisLength(this.state.vinChassis, this.props)
    error.state ? this.setState({ validationChasisError: error.state, chasisError: error.value })
      : this.setState({ validationChasisError: error.state, chasisError: error.value, showModalPurchase: value })
  }

  onBrandIdChanges (brand) {
    this.setState({ brand })
    this.props.actions.resetCarlineCombo()
    this.props.actions.resetCatalogueCombo()
    this.props.actions.resetColorCombo()
    this.props.actions.resetTapestryCombo()
    if (brand) {
      this.props.actions.fetchCarlineCombo(brand)
    }
  }

  onCarlineIdChanges (carlineId) {
    this.setState({ carlineId })
    this.props.actions.resetColorCombo()
    this.props.actions.resetTapestryCombo()
    if (carlineId) {
      this.props.actions.fetchCatalogueCombo(carlineId)
    }
  }

  fetchCatalogueModel (catalogueId, modelId) {
    new Promise((resolve) => {
      return this.props.actions.fetchOrderCatalogueModel(catalogueId, modelId, resolve)
    }).then((catalogueModel) => {
      this.setState({ model: catalogueModel.description, catalogueModelId: catalogueModel.catalogueModelId })
    })
  }

  onCatalogueIdChanges (catalogueId) {
    this.setState({ catalogueId })
    if (this.state.modelId && catalogueId) {
      this.fetchCatalogueModel(catalogueId, this.state.modelId)
      this.props.actions.fetchColorCombo(catalogueId, this.state.modelId)
      this.props.actions.fetchTapestryCombo(catalogueId, this.state.modelId)
    }
  }

  onModelIdChanges (modelId) {
    this.setState({ modelId })
    if (modelId && this.state.catalogueId) {
      this.fetchCatalogueModel(this.state.catalogueId, modelId)
      this.props.actions.fetchColorCombo(this.state.catalogueId, modelId)
      this.props.actions.fetchTapestryCombo(this.state.catalogueId, modelId)
    }
  }

  validateAvailabilityDate (availabilityDateTo, availabilityDateFrom) {
    let error = null
    if (availabilityDateFrom && availabilityDateTo) {
      let dateTo = convertStringToDate(availabilityDateTo)
      let dateFrom = convertStringToDate(availabilityDateFrom)
      if (dateFrom > dateTo) {
        error = this.props.t('GLOBAL.FORMS.DATE_TO_NOT_LESS_THAN_DATE_FROM')
      }
    }
    this.setState({ availabilityDateError: error, availabilityDateTo, availabilityDateFrom })
  }

  render () {
    const {
      t, showModal, modalType, vehiclesPurchaseList, orderId, order, showCreatePurchaseButton,
      openCommonModal, dossierSubTypeId,
      combos: { brandCombo, fuelCombo, purchaseTypeCombo, carlineCombo, catalogueCombo, modelCombo, colorCombo, tapestryCombo },
      actions: { closeReplacePurchaseModal, selectReplacePurchase }
    } = this.props
    const hasFilters = (
      this.state.dealerNumber ||
      this.state.vinChassis ||
      this.state.brand ||
      this.state.model ||
      this.state.color ||
      this.state.availabilityDateFrom ||
      this.state.availabilityDateTo ||
      this.state.modelId ||
      this.state.makerNumber
    )
    let brand = brandCombo.find((brand) => { return brand.id === order.brandId })
    brand = brand ? brand.value : ''
    let fuel = fuelCombo.find((fuel) => { return fuel.id === order.fuelId })
    fuel = fuel ? fuel.value : ''
    const tKey = 'DOSSIER_COMPONENTS.REASSIGN_REPLACE_PURCHASE_MODAL.'

    let title = dossierSubTypeId === dossierSubTypes.campaign ? t(`${tKey}TITLE_CAMPAIGN`)
      : dossierSubTypeId === dossierSubTypes.specialHelp ? t(`${tKey}TITLE_SPECIAL_HELP`)
        : t(`${tKey}TITLE`)

    return (
      <Modal className="rr-purchase-modal" show={showModal} onHide={() => closeReplacePurchaseModal()}>
        <ReassingPurchaseModalPage
          t={t}
          showModal={this.state.showModalPurchase}
          closeModal={ () => this.openModalPurchase(false)}
          combos={{ brandCombo, purchaseTypeCombo, carlineCombo, catalogueCombo, modelCombo, colorCombo, tapestryCombo }}
          vehicleOrderDetails={this.props.order}
          modalType={modalType}
          mandatoryFields={{
            brand: this.state.fieldsConfiguration.brand,
            vinChassis: this.state.fieldsConfiguration.vinChassisRequired,
            makerNumber: this.state.fieldsConfiguration.makerNumberRequired,
            vsb: this.state.fieldsConfiguration.vsbRequired,
            purchaseTypeId: this.state.fieldsConfiguration.purchaseTypeIdInReassingPurchaseModal,
            availabilityDate: this.state.fieldsConfiguration.availabilityDateInReassingPurchaseModal,
            carlineId: this.state.fieldsConfiguration.carlineId,
            carline: this.state.fieldsConfiguration.carline,
            catalogueId: this.state.fieldsConfiguration.catalogueId,
            catalogue: this.state.fieldsConfiguration.catalogue,
            modelId: this.state.fieldsConfiguration.modelId,
            modelYear: this.state.fieldsConfiguration.modelYear,
            colorId: this.state.fieldsConfiguration.colorId,
            color: this.state.fieldsConfiguration.color,
            tapestryId: this.state.fieldsConfiguration.tapestryId,
            tapestry: this.state.fieldsConfiguration.tapestry,
            model: this.state.fieldsConfiguration.model
          }}
        />
        <Modal.Header closeButton>
          <Modal.Title>{t(`${tKey}ORDER`)}</Modal.Title>
          {order.orderId ? (
            <Row>
              <Col sm={4}>
                {(order.purchaseTypeId === purchaseTypesVn.transfer || order.purchaseTypeId === purchaseTypesVn.stock) && (
                  <Col sm={4}>
                    <div className="text-band">
                      <span className="second">{t(`${tKey}CHASSIS`)}:</span>
                      <span className="first">{order.vinChassis}</span>
                    </div>
                  </Col>)}
                {order.purchaseTypeId === purchaseTypesVn.normal && (
                  <Col sm={4}>
                    <div className="text-band">
                      <span className="second">{t(`${tKey}MAKER_NUMBER`)}:</span>
                      <span className="first">{order.makerNumber}</span>
                    </div>
                  </Col>)}
                <Col sm={order.purchaseTypeId && (order.purchaseTypeId === purchaseTypesVn.transfer || order.purchaseTypeId === purchaseTypesVn.stock || order.purchaseTypeId === purchaseTypesVn.normal) ? 4 : 6}>
                  <div className="text-band">
                    <span className="second">{t(`${tKey}BRAND`)}:</span>
                    <span className="first">{brand}</span>
                  </div>
                </Col>
                {this.state.fieldsConfiguration.carlineId.permission !== permissions.hidden &&
                   <Col sm={ 6}>
                     <div className="text-band">
                       <span className="second">{t(`${tKey}CARLINE`)}:</span>
                       <span className="first">{this.state.carlineDescription}</span>
                     </div>
                   </Col>
                }
                {this.state.fieldsConfiguration.carline.permission !== permissions.hidden &&
                   <Col sm={ 6}>
                     <div className="text-band">
                       <span className="second">{t(`${tKey}CARLINE`)}:</span>
                       <span className="first">{order.carline}</span>
                     </div>
                   </Col>
                }
                <Col sm={order.purchaseTypeId ? 4 : 6}>
                  <div className="text-band">
                    <span className="second">{t(`${tKey}MODEL`)}:</span>
                    <span className="first">{order.model}</span>
                  </div>
                </Col>
              </Col>
              <Col sm={8}>
                {this.state.fieldsConfiguration.catalogueId.permission !== permissions.hidden &&
                  <Col sm={2}>
                    <div className="text-band">
                      <span className="second">{t(`${tKey}CATALOGUE`)}:</span>
                      <span className="first">{this.state.catalogueDescription}</span>
                    </div>
                  </Col>
                }
                {this.state.fieldsConfiguration.catalogue.permission !== permissions.hidden &&
                  <Col sm={2}>
                    <div className="text-band">
                      <span className="second">{t(`${tKey}CATALOGUE`)}:</span>
                      <span className="first">{order.catalogue}</span>
                    </div>
                  </Col>
                }
                {this.state.fieldsConfiguration.modelId.permission !== permissions.hidden &&
                  <Col sm={ 2}>
                    <div className="text-band">
                      <span className="second">{t(`${tKey}MODEL_YEAR`)}:</span>
                      <span className="first">{this.state.modelDescription}</span>
                    </div>
                  </Col>
                }
                {this.state.fieldsConfiguration.modelYear.permission !== permissions.hidden &&
                  <Col sm={ 2}>
                    <div className="text-band">
                      <span className="second">{t(`${tKey}MODEL_YEAR`)}:</span>
                      <span className="first">{order.modelYear}</span>
                    </div>
                  </Col>
                }
                {this.state.fieldsConfiguration.colorId.permission !== permissions.hidden &&
                  <Col sm={ 2}>
                    <div className="text-band">
                      <span className="second">{t(`${tKey}COLOR`)}:</span>
                      <span className="first">{this.state.colorDescription}</span>
                    </div>
                  </Col>
                }
                {this.state.fieldsConfiguration.tapestryId.permission !== permissions.hidden &&
                  <Col sm={ 2}>
                    <div className="text-band">
                      <span className="second">{t(`${tKey}TAPESTRY`)}:</span>
                      <span className="first">{this.state.tapestryDescription}</span>
                    </div>
                  </Col>
                }
                {this.state.fieldsConfiguration.color.permission !== permissions.hidden &&
                <Col sm={2}>
                  <div className="text-band">
                    <span className="second">{t(`${tKey}COLOR`)}:</span>
                    <span className="first">{order.color}</span>
                  </div>
                </Col>
                }
                {this.state.fieldsConfiguration.tapestry.permission !== permissions.hidden &&
                <Col sm={2}>
                  <div className="text-band">
                    <span className="second">{t(`${tKey}TAPESTRY`)}:</span>
                    <span className="first">{order.tapestry}</span>
                  </div>
                </Col>
                }
                <Col sm={2}>
                  <div className="text-band">
                    <span className="second">{t(`${tKey}FUEL`)}:</span>
                    <span className="first">{fuel}</span>
                  </div>
                </Col>
                <Col sm={4}>
                  <div className="text-band">
                    <span className="second">{t(`${tKey}COMMERCIAL_CODE`)}:</span>
                    <span className="first">{order.comercialCode}</span>
                  </div>
                </Col>
              </Col>
            </Row>)
            : (
              <span>{t(`${tKey}NO_ORDER`)}</span>
            )
          }
        </Modal.Header>
        <Modal.Body style={{ paddingLeft: '20px' }}>
          <Modal.Title>{title}</Modal.Title>
          <Row>
            <SimpleInputText
              colSm={4}
              value={this.state.makerNumber}
              id="makerNumber"
              controlLabel={t(`${tKey}MAKER_NUMBER`)}
              onInputChange={makerNumber => this.setState({ makerNumber })}
            />
            <SimpleInputText
              colSm={4}
              value={this.state.dealerNumber}
              id="dealerNumber"
              controlLabel={t(`${tKey}DEALER_NUMBER`)}
              onInputChange={dealerNumber => this.setState({ dealerNumber })}
              maxLength={settings.dealerMaxLength}
              error={this.validateIdPurchase() ? (this.validateIdPurchase().dealerNumber) : null}
            />
            <SimpleInputText
              colSm={4}
              value={this.state.vinChassis}
              id="vinChassis"
              controlLabel={t(`${tKey}CHASSIS`)}
              onInputChange={vinChassis => this.setState({ vinChassis })}
              maxLength={17}
              normalize={value => multipleNormalize(value, [toUpperCase, trimAll])}
              error={this.state.validationChasisError ? this.state.chasisError : null}
            />
            <SimpleSelect
              colSm={4}
              value={this.state.brand}
              id="brand"
              controlLabel={t(`${tKey}BRAND`)}
              placeholder={t(`${tKey}BRAND`)}
              options={brandCombo}
              labelKey="value"
              valueKey="id"
              onInputChange={this.onBrandIdChanges.bind(this)}
            />
            {this.state.fieldsConfiguration.carlineId.permission !== permissions.hidden &&
              <SimpleSelect
                id="carlineId"
                colSm={4}
                controlLabel={t(`${tKey}CARLINE`)}
                placeholder={t(`${tKey}CARLINE`)}
                valueKey="id"
                labelKey="value"
                options={carlineCombo}
                disabled={carlineCombo.length === 0}
                onInputChange={this.onCarlineIdChanges.bind(this)}
                menuPlacement="auto"
                value={this.state.carlineId}
              />
            }
            {this.state.fieldsConfiguration.carline.permission !== permissions.hidden &&
              <SimpleInputText
                colSm={4}
                value={this.state.carline}
                id="carline"
                controlLabel={t(`${tKey}CARLINE`)}
                onInputChange={carline => this.setState({ carline })}
              />
            }
            {this.state.fieldsConfiguration.catalogueId.permission !== permissions.hidden &&
              <SimpleSelect
                id="catalogueId"
                colSm={4}
                controlLabel={t(`${tKey}CATALOGUE`)}
                placeholder={t(`${tKey}CATALOGUE`)}
                valueKey="id"
                labelKey="value"
                options={catalogueCombo}
                disabled={catalogueCombo.length === 0}
                onInputChange={this.onCatalogueIdChanges.bind(this)}
                menuPlacement="auto"
                value={this.state.catalogueId}
              />
            }
            {this.state.fieldsConfiguration.catalogue.permission !== permissions.hidden &&
              <SimpleInputText
                colSm={4}
                value={this.state.catalogue}
                id="catalogue"
                controlLabel={t(`${tKey}CATALOGUE`)}
                onInputChange={catalogue => this.setState({ catalogue })}
              />
            }
            {this.state.fieldsConfiguration.modelId.permission !== permissions.hidden &&
              <SimpleSelect
                id="modelId"
                colSm={4}
                controlLabel={t(`${tKey}MODEL_YEAR`)}
                placeholder={t(`${tKey}MODEL_YEAR`)}
                valueKey="id"
                labelKey="value"
                options={modelCombo}
                disabled={modelCombo.length === 0}
                onInputChange={this.onModelIdChanges.bind(this)}
                menuPlacement="auto"
                value={this.state.modelId}
              />
            }
            {this.state.fieldsConfiguration.modelYear.permission !== permissions.hidden &&
              <SimpleInputText
                colSm={4}
                value={this.state.modelYear}
                id="modelYear"
                controlLabel={t(`${tKey}MODEL_YEAR`)}
                onInputChange={modelYear => this.setState({ modelYear })}
              />
            }
            {this.state.fieldsConfiguration.colorId.permission !== permissions.hidden &&
              <SimpleSelect
                id="colorId"
                colSm={4}
                controlLabel={t(`${tKey}COLOR`)}
                placeholder={t(`${tKey}COLOR`)}
                valueKey="id"
                labelKey="value"
                options={colorCombo}
                disabled={colorCombo.length === 0}
                menuPlacement="auto"
                onInputChange={colorId => this.setState({ colorId })}
                value={this.state.colorId}
              />
            }
            {this.state.fieldsConfiguration.colorId.permission !== permissions.hidden &&
              <SimpleSelect
                id="tapestryId"
                colSm={4}
                controlLabel={t(`${tKey}TAPESTRY`)}
                placeholder={t(`${tKey}TAPESTRY`)}
                valueKey="id"
                labelKey="value"
                options={tapestryCombo}
                disabled={tapestryCombo.length === 0}
                menuPlacement="auto"
                onInputChange={tapestryId => this.setState({ tapestryId })}
                value={this.state.tapestryId}
              />
            }
            <SimpleInputText
              colSm={4}
              value={this.state.model}
              id="model"
              controlLabel={t(`${tKey}MODEL`)}
              onInputChange={model => this.setState({ model })}
              disabled = {this.state.fieldsConfiguration.model.permission === permissions.readOnly}
            />
            {this.state.fieldsConfiguration.color.permission !== permissions.hidden &&
              <SimpleInputText
                colSm={4}
                value={this.state.color}
                id="color"
                controlLabel={t(`${tKey}COLOR`)}
                onInputChange={color => this.setState({ color })}
              />}
            {this.state.fieldsConfiguration.tapestry.permission !== permissions.hidden &&
              <SimpleInputText
                colSm={4}
                value={this.state.tapestry}
                id="tapestry"
                controlLabel={t(`${tKey}TAPESTRY`)}
                onInputChange={tapestry => this.setState({ tapestry })}
              />}
            <SimpleInputDatePicker
              colSm={4}
              value={this.state.availabilityDateFrom}
              id="availabilityDateFrom"
              controlLabel={t(`${tKey}AVAILABILITY_DATE_FROM`)}
              onInputChange={availabilityDateFrom => this.validateAvailabilityDate(this.state.availabilityDateTo, availabilityDateFrom)}
            />
            <SimpleInputDatePicker
              colSm={4}
              value={this.state.availabilityDateTo}
              id="availabilityDateTo"
              controlLabel={t(`${tKey}AVAILABILITY_DATE_TO`)}
              onInputChange={availabilityDateTo => this.validateAvailabilityDate(availabilityDateTo, this.state.availabilityDateFrom)}
              error={this.state.availabilityDateError}
            />
          </Row>
          <Row>
            <Col className="btn-wrapper text-center">
              <Button onClick={() => this.searchPurchases()} className="btn-standard" id="btn_search">{t(`${tKey}SEARCH_BUTTON`)}</Button>
              <Button
                onClick={(e) => { if (hasFilters) this.resetFilters() }}
                className={'btn-white btn-icon' + (hasFilters ? ' btn-standard' : ' btn-cancel')}
                id="btn_reset"
              >
                <i className="ico-cancel" /> {t(`${tKey}RESET_BUTTON`)}
              </Button>
            </Col>
          </Row>
          {this.state.hasSearch && (
            <Row>
              <SimpleTablePage
                columns={[
                  <th key="dealer_number">{t(`${tKey}TABLE.DEALER_NUMBER`)}</th>,
                  <th key="chassis">{t(`${tKey}TABLE.CHASSIS`)}</th>,
                  <th key="availability_date">{t(`${tKey}TABLE.AVAILABILITY_DATE`)}</th>,
                  <th key="brand">{t(`${tKey}TABLE.BRAND`)}</th>,
                  (this.state.fieldsConfiguration.carlineId.permission !== permissions.hidden ||
                    this.state.fieldsConfiguration.carline.permission !== permissions.hidden) &&
                  <th key="carline">{t(`${tKey}TABLE.CARLINE`)}</th>,
                  (this.state.fieldsConfiguration.catalogueId.permission !== permissions.hidden ||
                  this.state.fieldsConfiguration.catalogue.permission !== permissions.hidden) &&
                  <th key="catalogue">{t(`${tKey}TABLE.CATALOGUE`)}</th>,
                  (this.state.fieldsConfiguration.modelId.permission !== permissions.hidden ||
                    this.state.fieldsConfiguration.modelYear.permission !== permissions.hidden) &&
                  <th key="model">{t(`${tKey}TABLE.MODEL_YEAR`)}</th>,
                  <th key="model">{t(`${tKey}TABLE.MODEL`)}</th>,
                  (this.state.fieldsConfiguration.tapestryId.permission !== permissions.hidden ||
                    this.state.fieldsConfiguration.tapestry.permission !== permissions.hidden) &&
                  <th key="tapestry">{t(`${tKey}TABLE.TAPESTRY`)}</th>,
                  (this.state.fieldsConfiguration.colorId.permission !== permissions.hidden ||
                    this.state.fieldsConfiguration.color.permission !== permissions.hidden) &&
                  <th key="color">{t(`${tKey}TABLE.COLOR`)}</th>,
                  <th key="license_plate">{t(`${tKey}TABLE.LICENSE_PLATE`)}</th>,
                  <th key="admin">{t(`${tKey}TABLE.ADMIN`)}</th>
                ]}
                salesMan={vehiclesPurchaseList[0] ? vehiclesPurchaseList[0].salesManName : null}
                dossierId={vehiclesPurchaseList[0] ? vehiclesPurchaseList[0].purchaseId : null}
                rows={vehiclesPurchaseList.map((vehicle, idx) => {
                  return (
                    <ReassignReplacePurchaseRow
                      t={t}
                      key={idx}
                      row={{ ...vehicle, makerNumberSale: this.props.makerNumber, purchaseTypeId: this.props.purchaseTypeId }}
                      orderId={orderId}
                      modalType={modalType}
                      actions={{ selectReplacePurchase, openCommonModal }}
                      showColumns={{
                        carline: this.state.fieldsConfiguration.carlineId.permission !== permissions.hidden ||
                          this.state.fieldsConfiguration.carline.permission !== permissions.hidden,
                        catalogue: this.state.fieldsConfiguration.catalogueId.permission !== permissions.hidden ||
                          this.state.fieldsConfiguration.catalogue.permission !== permissions.hidden,
                        modelYear: this.state.fieldsConfiguration.modelId.permission !== permissions.hidden ||
                          this.state.fieldsConfiguration.modelYear.permission !== permissions.hidden,
                        color: this.state.fieldsConfiguration.colorId.permission !== permissions.hidden ||
                          this.state.fieldsConfiguration.color.permission !== permissions.hidden,
                        tapestry: this.state.fieldsConfiguration.tapestryId.permission !== permissions.hidden ||
                          this.state.fieldsConfiguration.tapestry.permission !== permissions.hidden
                      }}
                    />
                  )
                })}
              />
            </Row>
          )}
        </Modal.Body>
        {showCreatePurchaseButton && (
          <Modal.Footer>
            <Row>
              <Col sm={6} smOffset={6} className="btn-wrapper">
                <Button id="btn_save" disabled={!orderId} className="btn-standard" onClick={() => this.state.fieldsConfiguration.buttonOrderPurchase.permission !== permissions.hidden
                  ? this.openModalPurchase(true) : selectReplacePurchase(modalType, null, orderId)}>
                  <i className="ico-plus"/>
                  <span>{t(`${tKey}ADD_BUTTON`)}</span>
                </Button>
              </Col>
            </Row>
            <Row>
              <Col sm={7} smOffset={5} className="btn-wrapper">
                {order.orderId ? (
                  <span>{t(`${tKey}TOOLTIP2`)}</span>
                )
                  : (
                    <span>{t(`${tKey}TOOLTIP`)}</span>
                  )
                }
              </Col>
            </Row>
          </Modal.Footer>
        )}
      </Modal>
    )
  }
}

export default ReassignReplacePurchaseModal
