import stockActionTypes from '../../constants/actions/reports/stockReport'

export function fetchStockList (filter) {
  return {
    type: stockActionTypes.FETCH_STOCK_REPORT,
    filter
  }
}

export function fetchStockReportSuccess (stockList, filter) {
  return {
    type: stockActionTypes.FETCH_STOCK_REPORT_SUCCESS,
    stockList,
    filter
  }
}

export function downloadStockList (filter) {
  return {
    type: stockActionTypes.DOWNLOAD_STOCK_REPORT,
    filter
  }
}
