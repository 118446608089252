import mastersActionTypes from '../../../constants/actions/masters/masters'

function initialState () {
  return {
    organizedUnitConfigurations: [],
    organizedUnitConfigurationsCount: 0,
    pagesCount: 0,
    values: [],
    filter: {
      page: 1,
      orderBy: '',
      pageSize: 10
    },
    organizedUnitConfigurationModal: {
      organizedUnitConfiguration: null,
      showModal: false
    },
    changeTabFromMaster: false,
    ouIdFromMaster: null
  }
}

function fetchOrganizedUnitConfigurationListSuccess (state, { organizedUnitConfigurationList, filter }) {
  return {
    ...state,
    organizedUnitConfigurationList: organizedUnitConfigurationList.organizedUnitConfigurations,
    organizedUnitConfigurationsCount: organizedUnitConfigurationList.organizedUnitConfigurationsCount,
    pagesCount: organizedUnitConfigurationList.pagesCount,
    languageSelected: organizedUnitConfigurationList.languageSelected,
    filter
  }
}

function openEditOrganizedUnitConfigurationModalSuccess (state, { organizedUnitConfiguration }) {
  const initial = initialState()
  return {
    ...state,
    organizedUnitConfigurationModal: {
      ...initial.organizedUnitConfigurationModal,
      organizedUnitConfiguration,
      showModal: true
    }
  }
}

function openAddModalSuccess (state, { organizedUnitConfiguration }) {
  const initial = initialState()
  return {
    ...state,
    organizedUnitConfigurationModal: {
      ...initial.organizedUnitConfigurationModal,
      organizedUnitConfiguration: organizedUnitConfiguration,
      nonEditable: false,
      showModal: true
    }
  }
}

function closeEditModalSuccess (state) {
  return {
    ...state,
    organizedUnitConfigurationModal: {
      ...initialState().organizedUnitConfigurationModal
    }
  }
}

function setChangeTabFromConfMasterSuccess (state, { ouIdFromMaster, changeTabFromMaster }) {
  return {
    ...state,
    ouIdFromMaster,
    changeTabFromMaster
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case mastersActionTypes.FETCH_ORGANIZED_UNIT_CONFIGURATION_LIST_SUCCESS:
      return fetchOrganizedUnitConfigurationListSuccess(state, action)
    case mastersActionTypes.OPEN_EDIT_ORGANIZED_UNIT_CONFIGURATION_MODAL_SUCCESS:
      return openEditOrganizedUnitConfigurationModalSuccess(state, action)
    case mastersActionTypes.OPEN_ADD_ORGANIZED_UNIT_CONFIGURATION_MODAL_SUCCESS:
      return openAddModalSuccess(state, action)
    case mastersActionTypes.CLOSE_EDIT_ORGANIZED_UNIT_CONFIGURATION_MODAL:
      return closeEditModalSuccess(state, action)
    case mastersActionTypes.SET_CHANGE_TAB_FROM_CONF_MASTER_SUCCESS:
      return setChangeTabFromConfMasterSuccess(state, action)
    default:
      return state
  }
}
