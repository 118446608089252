export const approvalRoleCode = {
  SALESMAN: 1,
  SALES_MANAGER: 2,
  LOGISTICS: 3,
  ADMINISTRATION: 4,
  MANAGER: 5,
  FLEETS: 6,
  COMMERCIAL_DIRECTOR: 7,
  CONTROLLER: 8,
  BILLING: 9,
  APPROVE_LICENSEPLATE_FORMAT: 10,
  APPROVE_SERVICE_LOGISTICS: 11,
  COMPLETE_PAP: 12,
  SALES_MANAGER_VO: 13,
  BPA: 14,
  CFO: 15,
  APPROVE_AUTHORIZE_MANAGEMENT: 16,
  APPROVE_AUTHORIZE_FINANCE: 17
}

export const approvalType = {
  SEND_TO_APPROVAL: 1,
  APPROVE: 2,
  ROLE_APPROVE: 3,
  ROLE_AUTHORIZE_MANAGEMENT: 4,
  ROLE_AUTHORIZE_FINANCE: 5
}
