import { call, put, select, takeLatest } from 'redux-saga/effects'
import { getAuth } from '../../selectors/access/auth'
import commonActions from '../../actions/common'
import messageModalActions from '../../actions/modals/messageModal'
import { fetchSignEntityTypeComboSuccess } from '../../actions/combos/combos'
import combosActionTypes from '../../constants/actions/combos/combos'
import { handleError } from '../errors/errorManager'
import getSignEntityTypes from '../../services/signEntityType/getSignEntityTypes'

export function * fetchSignEntityTypeCombo () {
  try {
    yield put(commonActions.showLoader())
    const auth = yield select(getAuth)
    const signEntityTypes = yield call(getSignEntityTypes, auth.token)
    yield put(fetchSignEntityTypeComboSuccess(signEntityTypes))
  } catch (error) {
    const customHandler = function * () {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.FETCH_COMBO_ERROR'))
    }
    yield call(handleError, { error, customHandler })
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchFetchSignEntityTypeCombo () {
  yield takeLatest(combosActionTypes.FETCH_SIGN_ENTITY_TYPE, fetchSignEntityTypeCombo)
}
