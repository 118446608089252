import apiFetch from '../apiFetch'

export default function (token, { page, pageSize, orderBy, documentEntityTypeId, documentTypeId, documentStatusId, creationDateFrom, creationDateTo, validationDateFrom, validationDateTo, dossierSubTypeId, number, organizedUnitId, dossierStageId, dealerNumber, creationDossierDateFrom, creationDossierDateTo, orderDateFrom, orderDateTo, salesmanId, entityName, entitySurname1, entitySurname2, entityCifNif, entityMail, entityPhone, entityAddress, representativeName, representativeSurname1, representativeSurname2, representativeDni, entityTypeId, entitySubTypeId, entityId, vehicleBrandId, vehicleModel, vehicleVinChasis, vehicleLicensePlate, comercialCode, licensePlateDateFrom, licensePlateDateTo, load }) {
  const queryParamsArray = [
    page ? `page=${page}` : null,
    pageSize ? `pageSize=${pageSize}` : null,
    orderBy ? `orderBy=${orderBy}` : 'orderBy=null',
    documentEntityTypeId ? `documentEntityTypeId=${documentEntityTypeId}` : null,
    documentTypeId ? `documentTypeId=${documentTypeId}` : null,
    documentStatusId ? `documentStatusId=${documentStatusId}` : null,
    creationDateFrom ? `creationDateFrom=${creationDateFrom}` : null,
    creationDateTo ? `creationDateTo=${creationDateTo}` : null,
    validationDateFrom ? `validationDateFrom=${validationDateFrom}` : null,
    validationDateTo ? `validationDateTo=${validationDateTo}` : null,
    dossierSubTypeId ? `dossierSubTypeId=${dossierSubTypeId}` : null,
    number ? `number=${number}` : null,
    organizedUnitId ? `organizedUnitId=${organizedUnitId}` : null,
    dossierStageId ? `dossierStageId=${dossierStageId}` : null,
    dealerNumber ? `dealerNumber=${dealerNumber}` : null,
    creationDossierDateFrom ? `creationDossierDateFrom=${creationDossierDateFrom}` : null,
    creationDossierDateTo ? `creationDossierDateTo=${creationDossierDateTo}` : null,
    orderDateFrom ? `orderDateFrom=${orderDateFrom}` : null,
    orderDateTo ? `orderDateTo=${orderDateTo}` : null,
    salesmanId ? `salesmanId=${salesmanId}` : null,
    entityName ? `entityName=${entityName}` : null,
    entitySurname1 ? `entitySurname1=${entitySurname1}` : null,
    entitySurname2 ? `entitySurname2=${entitySurname2}` : null,
    entityCifNif ? `entityCifNif=${entityCifNif}` : null,
    entityMail ? `entityMail=${entityMail}` : null,
    entityPhone ? `entityPhone=${entityPhone}` : null,
    entityAddress ? `entityAddress=${entityAddress}` : null,
    representativeName ? `representativeName=${representativeName}` : null,
    representativeSurname1 ? `representativeSurname1=${representativeSurname1}` : null,
    representativeSurname2 ? `representativeSurname2=${representativeSurname2}` : null,
    representativeDni ? `representativeDni=${representativeDni}` : null,
    entityTypeId ? `entityTypeId=${entityTypeId}` : null,
    entitySubTypeId ? `entitySubTypeId=${entitySubTypeId}` : null,
    entityId ? `entityId=${entityId}` : null,
    vehicleBrandId ? `vehicleBrandId=${vehicleBrandId}` : null,
    vehicleModel ? `vehicleModel=${vehicleModel}` : null,
    vehicleVinChasis ? `vehicleVinChasis=${vehicleVinChasis}` : null,
    vehicleLicensePlate ? `vehicleLicensePlate=${vehicleLicensePlate}` : null,
    comercialCode ? `comercialCode=${comercialCode}` : null,
    licensePlateDateFrom ? `licensePlateDateFrom=${licensePlateDateFrom}` : null,
    licensePlateDateTo ? `licensePlateDateTo=${licensePlateDateTo}` : null,
    load ? `load=${load}` : null
  ]
  const queryParams = queryParamsArray.reduce(
    (result, query, idx) => result + (query ? (result ? `&${query}` : `?${query}`) : '')
    , ''
  )
  return apiFetch({
    endPoint: `documentFinder${queryParams}`,
    method: 'GET',
    body: null,
    token: token
  })
}
