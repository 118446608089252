import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { openEditSignatureConfigModal, fetchSignatureConfigList, deleteSignatureConfig }
  from '../../../actions/masters/signatureConfiguration/signatureConfiguration'
import SignatureConfigMaster from '../../../components/masters/signatureConfig/SignatureConfigMaster'

export function mapStateToProps (state) {
  return {
    ...state.signatureConfig
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      openEditSignatureConfigModal,
      fetchSignatureConfigList,
      deleteSignatureConfig
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(SignatureConfigMaster))
