import React from 'react'
import Select from 'react-select'
// import 'react-select/dist/react-select.css'
import { Col, FormGroup, ControlLabel } from 'react-bootstrap'

export default (props) => {
  const { meta } = props
  return (
    <FormGroup className='forms-with-label custom-select'>
      {props.label &&
        < Col sm={3} className="label-wrapper select-input">
          <ControlLabel>{props.label}</ControlLabel>
        </Col>
      }
      <Col sm={9} className={(meta && meta.error && meta.touched ? 'has-error' : '')}>
        <Col id={props.id} sm={11}>
          <Select
            {...props}
            value={props.input.value}
            onChange={(value) => props.input.onChange(value)}
            onBlur={() => props.input.onBlur(props.input.value)}
            options={props.options}
            multi={props.multi}
          />
        </Col>
        {meta && meta.error && meta.touched &&
          <span className='help-block text-center'>{meta && meta.error}</span>
        }
      </Col>
    </FormGroup>
  )
}
