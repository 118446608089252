import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import 'rc-tree-select/assets/index.css'
import TreeSelect from 'rc-tree-select'
import BubbleMeter from '../../../components/commons/BubbleMeter'
import setting from '../../../setting'
import { getSectionPermissions } from '../../../util/permisionFunctions'
import { permissions } from '../../../constants/backendIds'
import { sectionPermissions } from '../../../constants/dossier/common/buttonsHeader'
import ModalQrLogin from '../../../components/layout/modals/ModalQrLogin'
import { setTheme } from '../../../util/theme'

class Header extends PureComponent {
  constructor (props) {
    super()
    this.state = {
      tsOpen: false,
      showQrModal: false,
      toogleUserMenu: false,
      isDossier: false,
      UOTreeCombo: this.formatUOTree(props.combos.UOTreeCombo),
      organizedUnit: []
    }
    this.handleClick = this.handleClick.bind(this)
    this.handleLogOut = this.handleLogOut.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.UO = React.createRef()
    this.label = React.createRef()
  }

  formatUOTree (UOTreeCombo) {
    return UOTreeCombo.map((current) => {
      return {
        key: '' + current.value,
        value: '' + current.value,
        title: current.label,
        children: current.children ? this.formatUOTree(current.children) : []
      }
    })
  }

  findUO (uos, item, selectedUos) {
    if (Array.isArray(uos) && uos.length > 0) {
      selectedUos = uos.map((uo) => {
        if (uo.value && item && Number.parseInt(uo.value) === parseInt(item)) {
          let catched = {
            key: '' + uo.value,
            value: '' + uo.value,
            title: uo.label
          }
          selectedUos.push(catched)
        } else if (uo.children && Array.isArray(uo.children) && uo.children.length > 0) {
          this.findUO(uo.children, item, selectedUos)
        }
      })
    }
  }

  getFirstUo (UOS, change) {
    if (UOS && UOS.length > 0) {
      if (this.props.organizedUnit && this.props.organizedUnit.length > 0) {
        let organizedUnit = this.props.organizedUnit.split(',')
        let findParentChange = change
        UOS.map((uo) => {
          if (organizedUnit.find((unit) => { return Number.parseInt(unit) === uo.value }) !== undefined) {
            if (this.UO.current === null || findParentChange === true) {
              this.UO = uo.label
              findParentChange = false
            } else {}
          } else {
            if (uo.children) {
              this.getFirstUo(uo.children, findParentChange)
            }
          }
        })
      } else {
        if (change) this.UO = { current: null }
      }
    }
  }

  handleClick (event) {
    let tsOpen = true
    if (!event.target.classList.contains('header-tree-select')) {
      const treeSelect = document.querySelector('.header-tree-list:not(.hidden), .header-tree-select.rc-tree-select:not(.invisible)')
      const dropdown = document.querySelector('.header-tree-select.rc-tree-select-dropdown')
      tsOpen = (treeSelect && treeSelect.contains(event.target)) || (dropdown && dropdown.contains(event.target)) || false
    }

    if (this.state.tsOpen !== tsOpen) {
      this.setState({ tsOpen })
      if (!tsOpen) {
        let organizedUnit
        if (Array.isArray(this.state.organizedUnit) && this.state.organizedUnit.length > 0) {
          organizedUnit = this.state.organizedUnit.map(uoId => uoId.value).join(',')
        } else {
          organizedUnit = '0'
        }
        this.props.actions.setOrganizedUnit(organizedUnit)
      }
    }
  }

  getLabel (value, combos) {
    combos.map((uo) => {
      if (uo.value === Number.parseInt(value)) {
        this.label = uo.label
      } else {
        if (uo.children && uo.children.length > 0) {
          this.getLabel(value, uo.children)
        } else {
          this.label = ''
        }
      }
    })
  }

  getSnapshotBeforeUpdate (prevProps) {
    let state = {}
    if (this.props.combos.UOTreeCombo !== prevProps.combos.UOTreeCombo) {
      state.UOTreeCombo = this.formatUOTree(this.props.combos.UOTreeCombo)
      this.getFirstUo(this.props.combos.UOTreeCombo)
    }
    if (prevProps.organizedUnit !== this.props.organizedUnit) {
      this.getFirstUo(this.props.combos.UOTreeCombo, true)
    }
    if (this.props.combos.UOTreeCombo.length > 0 && (this.props.organizedUnit &&
      (!this.state.organizedUnit || this.state.organizedUnit.length === 0 || prevProps.organizedUnit !== this.props.organizedUnit)) &&
      !(prevProps.organizedUnit !== '' && this.state.organizedUnit.length === 0 && prevProps.combos.UOTreeCombo.length !== 0)) {
      let selectedUos = []
      this.props.organizedUnit ? this.props.organizedUnit.split(',').map(current => {
        this.findUO(this.props.combos.UOTreeCombo, current, selectedUos)
      }) : null
      if (selectedUos.length > 0) state.organizedUnit = selectedUos
      if (state.organizedUnit) {
        state.organizedUnit.sort((a, b) => Number.parseInt(a.value) > Number.parseInt(b.value) ? 1 : -1)
      }
    } else if (this.props.combos.UOTreeCombo.length > 0 && prevProps.organizedUnit && !this.props.organizedUnit) {
      state.organizedUnit = []
    }

    this.setState(state)

    return prevProps
  }

  componentDidUpdate (prevProps) {
    // not remove use for getSnapShot
  }

  handleChange (organizedUnit, ...arg) {
    let newOrganizedUnit = organizedUnit.map(uo => {
      return { title: uo.label, value: uo.value }
    })
    newOrganizedUnit.sort((a, b) => Number.parseInt(a.value) > Number.parseInt(b.value) ? 1 : -1)
    this.setState({ organizedUnit: newOrganizedUnit })
  }

  initializePermisions (props) {
    const section = props.sections.filter((tabConf) => tabConf.code === sectionPermissions.dashboard)
    if (section.length > 0 && section[0] != null) {
      const newState = getSectionPermissions(section[0])
      this.setState({ sectionTabsConfiguration: newState })
    }
  }

  componentDidMount () {
    setTheme(this.props.themeId)
    this.initializePermisions(this.props)
    window.addEventListener('click', this.handleClick)

    if (this.props.organizedUnit && this.props.organizedUnit.length > 0) {
      let selectedUos = []
      this.props.organizedUnit.split(',').map(current => { this.findUO(this.props.combos.UOTreeCombo, current, selectedUos) })
      if (selectedUos.length > 0) {
        this.setState({ organizedUnit: selectedUos })
      }
    }
    this.props.actions.fetchOrganizedUnitsTree()
    this.props.actions.fetchReleaseNotesFile(setting.version, this.props.languageId)
  }

  componentWillUnmount () {
    window.removeEventListener('click', this.handleClick)
  }

  handleLogOut () {
    this.props.actions.logout()
  }

  openQrModal () {
    this.setState({ showQrModal: true })
  }

  hideQrModal () {
    this.setState({ showQrModal: false })
  }

  redirectToUserManualUrl () {
    new Promise((resolve, reject) => {
      this.props.actions.getUserManualUrl(resolve, reject)
    }).then((url) => {
      window.open(url, '_blank')
    })
  }

  toogleUserMenuVisibility () {
    this.setState({ toogleUserMenu: !this.state.toogleUserMenu })
  }

  render () {
    const {
      t, userName, userSurnames, roleNames = [], /* entityData, vehicleData, isProvider, */ token, qrContentEncrypted,
      actions: { encryptQrContent }
    } = this.props
    const tKey = 'HEADER.'
    const tooltipIDS = (<Tooltip id="tooltipIDS">{t(`${tKey}IDOCSIGN`)}</Tooltip>)

    return (
      <div>
        <ModalQrLogin
          t={t}
          token={token}
          showModal={this.state.showQrModal}
          hideModal={() => this.hideQrModal()}
          encryptQrContent={encryptQrContent}
          qrContentEncrypted={qrContentEncrypted}
        />

        <div className='topMenu'>
          <div className={'topMenuBg ' + (this.state.toogleUserMenu ? ' openMenu' : '')} onClick={() => this.toogleUserMenuVisibility()}></div>
          <div className='topMenuPart-left'>
            <div className='poweredbyContent'>
              <div className='logoPoweredBy'></div>
              <div className="appVersion">{'v ' + setting.version + 'NF'}</div>
            </div>
            { !(this.props.isDossier === false) && this.props.headerValues.showUOTree && (
              <div className='UiFilter'>
                {!this.props.userCIF &&
                  <div className="main-uo-tree">
                    <label>
                      {t(`${tKey}UO`)}
                    </label>
                    <div className={'header-tree-list' + (this.state.tsOpen ? ' hidden' : '')}>
                      {this.state.organizedUnit && this.state.organizedUnit.length > 0
                        ? [
                          <span key="lable" className="tree-list-label tree-list-item">{this.state.organizedUnit[0].title}</span>,
                          <BubbleMeter key="bubble" listItems={this.state.organizedUnit.map(ou => ou.title)} />
                        ]
                        : <span className="tree-list-label tree-list-placeholder">{t(`${tKey}UO_PLACEHOLDER`)}</span>
                      }
                    </div>
                    <TreeSelect
                      treeLine
                      multiple
                      treeCheckable
                      treeCheckStrictly
                      showSearch
                      filterTreeNode
                      labelInValue
                      allowClear
                      treeNodeFilterProp='title'
                      dropdownMatchSelectWidth={false}
                      className={!this.state.tsOpen ? ' invisible' : ''}
                      prefixCls="header-tree-select rc-tree-select"
                      getPopupContainer={(node) => {
                        return document.querySelector('.main-uo-tree')
                      }}
                      placeholder={t(`${tKey}UO_PLACEHOLDER`)}
                      treeData={this.state.UOTreeCombo}
                      value={this.state.organizedUnit}
                      onChange={(val, ...args) => this.handleChange(val, ...args)}
                      open={this.state.tsOpen}
                      autoClearSearchValue
                      onDropdownVisibleChange={() => false}
                      maxTagCount={setting.maxTagCount}
                      maxTagTextLength={setting.maxTagTextLength}
                    />
                  </div>
                }
              </div>
            ) }
            {/* !(this.props.isDossier === true) && (
              <div className='fileInfo'>
                {entityData.entityId ? (
                  <Col className="see-more">
                    {!isProvider && (<label>{t(`${tKey}CLIENT`)}</label>)}
                    {isProvider && (<label>{t(`${tKey}PROVIDER`)}</label>)}
                    <EntityPopover
                      t={t}
                      entity={{
                        entityCifNif: entityData.dniCif,
                        entityName: entityData.name,
                        entitySurname1: entityData.surname1,
                        entityPhone: entityData.mobilePhone,
                        entityAddress: entityData.address,
                        entityMail: entityData.email
                      }}
                      half={2}
                      idx={1}
                      id={'popover-entity-head'}
                      isHeader={true}
                    />
                  </Col>
                ) : (entityData.name2 && entityData.name) ? (
                  <Col className="see-more">
                    {!isProvider && (<label>{t(`${tKey}CLIENT`)}</label>)}
                    <EntityPopover
                      t={t}
                      entity={{
                        entityNameOrigin: entityData.name,
                        entityNameDestination: entityData.name2
                      }}
                      half={2}
                      idx={1}
                      id={'popover-entity'}
                      isHeader={true}
                    />
                  </Col>
                ) : null
                }
                {vehicleData.vehicleId &&
                <Col className="see-more">
                  {vehicleData.vehicleId && <label>{t(`${tKey}VEHICLE`)}</label>}
                  <VehiclePopover
                    t={t}
                    vehicle={{
                      vehicleModel: vehicleData.model,
                      vehicleBrand: vehicleData.brand,
                      vehicleVinChassis: vehicleData.vinChassis,
                      color: vehicleData.colorDescription || vehicleData.color,
                      vehicleLicensePlate: vehicleData.licensePlate,
                      carline: vehicleData.carlineDescription || vehicleData.carline,
                      catalogue: vehicleData.catalogueDescription || vehicleData.catalogue,
                      modelYear: vehicleData.modelDescription || vehicleData.modelYear,
                      tapestry: vehicleData.tapestryDescription || vehicleData.tapestry
                    }}
                    half={2}
                    idx={1}
                    id={'popover-vehicle'}
                    isHeader={true}
                  />
                </Col>
                }
              </div>
            ) */}
          </div>
          <div className='topMenuPart-right'>
            <div className='UserMenuContainer'>
              {/** *** iDocSign ****************************************/}
              {this.state.sectionTabsConfiguration && this.state.sectionTabsConfiguration.SingLink && this.state.sectionTabsConfiguration.SingLink.permission > permissions.hidden && (
                <React.Fragment>
                  <a className='userBtn UseriDocSing' href={setting.IdocSingUrl + '?token=' + token} target="_blank" id={'IDOCSING_BUTTON_HEADER'}>
                    <OverlayTrigger placement="left" overlay={tooltipIDS}>
                      <i className="iDocIcon-signature-draw"></i>
                    </OverlayTrigger>
                  </a>
                </React.Fragment>
              )}
              <p className='userName'>{userName} {userSurnames}<p className="user-role">{roleNames[0]}<BubbleMeter listItems={roleNames} /></p></p>
              <a onClick={() => this.toogleUserMenuVisibility()} className='userBtn userMenu'><i className="iDocIcon-actions-menu-more"></i></a>
              <ul id='userMenu' className={'userMenuItems ' + (this.state.toogleUserMenu ? ' openMenu' : '')}>
                <li className='userName'>{userName} {userSurnames}<p className="user-role">{roleNames[0]}<BubbleMeter listItems={roleNames} /></p></li>
                {/* USER BTN */}
                <li onClick={() => this.toogleUserMenuVisibility() + this.toogleUserMenuVisibility()} className='userItems'>
                  <Link to="/profile">
                    <span>{t(`${tKey}PROFILE`)}</span>
                    <i className="iDocIcon-user-user-menu" />
                  </Link>
                </li>
                {/* MANUAL BTN */}
                { !(setting.usersManualUrl.length === 0) && (
                  <li className='userItems' onClick={() => { this.redirectToUserManualUrl() + this.toogleUserMenuVisibility() }}>
                    <a >
                      <span className="v-middle">{t(`${tKey}USER_MANUAL`)}</span>
                      <i className="iDocIcon-user-manual"></i>
                    </a>
                  </li>
                )}
                {/* RELEASE NOTES BTN */}
                { (this.props.releaseNotes && this.props.releaseNotes.url !== '') && (
                  <li className='userItems' onClick={() => window.open(this.props.releaseNotes.url, '_blank') + this.toogleUserMenuVisibility() }>
                    <a>
                      <span className="v-middle">{t(`${tKey}RELEASE_NOTES_URL`) }</span>
                      <i className="iDocIcon-description"></i>
                    </a>
                  </li>
                ) }
                {/* QR LOGIN BTN */}
                {this.state.sectionTabsConfiguration && this.state.sectionTabsConfiguration.qrLogin && this.state.sectionTabsConfiguration.qrLogin.permission > permissions.hidden && (
                  <li className='userItems' onClick={() => this.openQrModal() + this.toogleUserMenuVisibility()}>
                    <a>
                      <span className="v-middle">{t(`${tKey}QR_LOGIN`)}</span>
                      <i className="iDocIcon-qr-user-menu"></i>
                    </a>
                  </li>
                )}
                {/* LOGOUT BTN */}
                <li className='userItems' onClick={this.handleLogOut}>
                  <a>
                    <span className="v-middle">{t(`${tKey}LOGOUT`)}</span>
                    <i className="iDocIcon-sign-out-user-menu"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Header
