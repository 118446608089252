import React, { PureComponent } from 'react'
import { Overlay, Popover, Button } from 'react-bootstrap'
import { permissions } from '../../../../constants/backendIds'

class DossierTypeButton extends PureComponent {
  constructor (props, context) {
    super(props, context)

    this.handleClick = e => {
      this.setState({ target: e.target, show: !this.state.show })
    }

    this.state = { show: false }
  }

  render () {
    const { t, salesDocumentaryCheckList = [], printDocumentaryChecklist, componentConfiguration } = this.props
    const tKey = 'DOSSIER_COMPONENTS.SALES.'
    if (salesDocumentaryCheckList.length === 0) return (<div/>)
    return (
      <div>
        <Button className='order-button btn-editable-table btn-white btn-standard'
          onClick={this.handleClick}
          disabled={componentConfiguration.permission !== permissions.editable}
        >
          {t(`${tKey}MANAGEMENT_DOCUMENTATION`)}
        </Button>
        <Overlay
          show={this.state.show}
          onHide={() => this.setState({ show: false })}
          placement="bottom"
          rootClose
          target={this.state.target}
          container={this}
        >
          <Popover className="popover-documentation" id="checkListPopover">
            <ul>
              {salesDocumentaryCheckList.map((document, idx) => {
                return (
                  <li key={idx}>
                    <strong className={document.isComplete ? 'complete' : 'normal'}>{document.name}</strong>
                  </li>
                )
              })}
            </ul>
            <Button className="btn-white" onClick={() => printDocumentaryChecklist()}>
              <i className="ico-print"/>
              <span>{t(`${tKey}MANAGEMENT_DOCUMENTATION_PRINT`)}</span>
            </Button>
          </Popover>
        </Overlay>
      </div>
    )
  }
}

export default DossierTypeButton
