import actionsType from '../../../constants/actions/masters/faqSection/faqSectionMaster'

export function fetchFaqSectionTable (filter) {
  return {
    type: actionsType.FETCH_FAQ_SECTION_TABLE,
    filter
  }
}

export function loadFaqSectionTable (faqSectionTable, filter) {
  return {
    type: actionsType.LOAD_FAQ_SECTION_TABLE,
    faqSectionTable,
    filter
  }
}

export function saveFaqSection (faqSectionId, uoId, faqTranslations) {
  return {
    type: actionsType.SAVE_FAQ_SECTION,
    faqSectionId,
    uoId,
    faqTranslations
  }
}

export function deleteFaqSection (faqSectionId) {
  return {
    type: actionsType.DELETE_FAQ_SECTION,
    faqSectionId
  }
}

export function fetchFaqSection (faqSectionId = null) {
  return {
    type: actionsType.FETCH_FAQ_SECTION,
    faqSectionId
  }
}

export function loadFaqSection (languageList, faqSectionObject) {
  return {
    type: actionsType.LOAD_FAQ_SECTION,
    languageList,
    faqSectionObject
  }
}

export function recoverFaqSection (faqSectionId) {
  return {
    type: actionsType.RECOVER_FAQ_SECTION,
    faqSectionId
  }
}

export function closeFaqSectionModal () {
  return {
    type: actionsType.CLOSE_FAQ_SECTION_MODAL
  }
}
