import mastersActionTypes from '../../../constants/actions/masters/masters'

function initialState () {
  return {
    documentTypes: [],
    documentTypesCount: 0,
    pagesCount: 0,
    languageList: [],
    filter: {
      page: 1,
      orderBy: '',
      pageSize: 10
    },
    documentTypeUseModal: {
      documentTypeUse: null,
      showModal: false
    }
  }
}

function fetchDocumentTypeUseListSuccess (state, { documentTypeUseList, filter }) {
  return {
    ...state,
    documentTypeUseList: documentTypeUseList.documentTypeUses,
    documentTypeUsesCount: documentTypeUseList.documentTypeUsesCount,
    pagesCount: documentTypeUseList.pagesCount,
    languageSelected: documentTypeUseList.languageSelected,
    filter
  }
}

function openEditDocumentTypeUseModalSuccess (state, { languageList, documentTypeUse }) {
  const initial = initialState()
  return {
    ...state,
    documentTypeUseModal: {
      ...initial.documentTypeUseModal,
      languageList,
      documentTypeUse,
      showModal: true
    }
  }
}

function openAddModalSuccess (state, { languageList, documentTypeUse }) {
  const initial = initialState()
  return {
    ...state,
    documentTypeUseModal: {
      ...initial.documentTypeUseModal,
      languageList,
      documentTypeUse: documentTypeUse,
      nonEditable: false,
      showModal: true
    }
  }
}

function closeEditModalSuccess (state) {
  return {
    ...state,
    documentTypeUseModal: {
      ...initialState().documentTypeUseModal
    }
  }
}

function setDocumentDocumentOnlyTemplateUpload (state, { isOnlyTemplateUpload }) {
  return {
    ...state,
    documentTypeUseModal: {
      ...state.documentTypeUseModal,
      documentTypeUse: {
        ...state.documentTypeUseModal.documentTypeUse,
        canBePrinted: isOnlyTemplateUpload,
        onlyTemplateUpload: isOnlyTemplateUpload
      }
    }
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case mastersActionTypes.FETCH_DOCUMENT_TYPE_USE_LIST_SUCCESS:
      return fetchDocumentTypeUseListSuccess(state, action)
    case mastersActionTypes.OPEN_EDIT_DOCUMENT_TYPE_USE_MODAL_SUCCESS:
      return openEditDocumentTypeUseModalSuccess(state, action)
    case mastersActionTypes.OPEN_ADD_DOCUMENT_TYPE_USE_MODAL_SUCCESS:
      return openAddModalSuccess(state, action)
    case mastersActionTypes.CLOSE_EDIT_DOCUMENT_TYPE_USE_MODAL:
      return closeEditModalSuccess(state, action)
    case mastersActionTypes.SET_DOCUMENT_TYPE_USE_ONLY_TEMPLATE_UPLOAD:
      return setDocumentDocumentOnlyTemplateUpload(state, action)
    default:
      return state
  }
}
