import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
// import { history } from '../../index'
import { push } from 'connected-react-router'
import { getAuth } from '../../selectors/access/auth'
import fetchLoginService from '../../services/access/fetchAuth'
import fetchLocalization from '../../services/commons/localization'
import resetPasswordService from '../../services/access/resetPassword'
import resetPasswordWithTokenService from '../../services/access/resetPasswordWithToken'
import logoutService from '../../services/access/logout'
import checkTokenService from '../../services/access/checkToken'
import rememberPasswordService from '../../services/access/rememberPassword'
import authActions from '../../actions/auth'
import commonActions from '../../actions/common.js'
import { SubmissionError } from 'redux-form'
import authActionTypes from '../../constants/actions/auth'
import { loadAuth, saveAuth, deleteAuth } from '../../services/sessionAuth'
import { handleError } from '../errors/errorManager'
import { permissions } from '../../constants/backendIds'
import encryptQrContentServices from '../../services/access/encryptQrContent'
import dashboardActions from '../../actions/dashboard/dashboard'
import backEndTagTranslation from '../../constants/backEndTagTransaltion'
import encryptPassword from '../../services/access/encryptPassword'
import getLanguageListComboService from '../../services/language/getLanguageList'
import settings from '../../settings/settings'
import { setStorageValue } from '../../storage/storage'

export function * fetchAuth ({ values, resolve, reject, t }) {
  try {
    yield put(commonActions.showLoader())

    let encriptedPasword = encryptPassword(values.password, values.emailAddress)

    const response = yield call(fetchLoginService, { ...values, password: encriptedPasword })
    response.sections = response.sections.filter(sec => sec.permission > permissions.hidden)

    if (response.interfaceVersion !== undefined && response.interfaceVersion !== null) {
      setStorageValue('interfaceVersion', response.interfaceVersion)
    } else {
      setStorageValue('interfaceVersion', settings.interfaceVersion)
    }

    if (response.sections && response.sections.length > 0) {
      if (yield call(userHasNewVersion, { userVersion: response.lastVersionAccessed, systemVersion: settings.version })) {
        yield put(authActions.setNewVersionSuccess(settings.version))
      }

      if (response.changePassword) {
        yield put(push('/reset-password'))
      } else {
        let modresponse = {
          ...response,
          selectedSalesmanId: response.selectedSalesmanId.length > 0 ? response.selectedSalesmanId : null,
          nonUpdatedSalesControl: response.nonUpdatedSalesControl ?? false
        }
        yield put(authActions.setAuth(modresponse))
      }
      yield call(saveAuth, response)
      let auth = yield select(getAuth)

      const languages = yield call(getLanguageListComboService, auth.token)
      const languageName = languages.find(x => x.languageId === response.languageId).name
      const responseLocale = yield call(fetchLocalization, { ...response, localeName: languageName })

      yield put(commonActions.changeLocale(responseLocale))
      yield call(resolve)
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(authActions.fetchAuthError(customHandlerError))
      if (customHandlerError.json && (customHandlerError.json.tag === 'accountLockedInfo' || customHandlerError.json.tag === 'TRANS/accountLockedInfo')) {
        const transactionTag = customHandlerError.json.tag.match(/^TRANS\/([\w\d]*)/)
        let tKeyFilteredByTag
        if (transactionTag && backEndTagTranslation[transactionTag[1]]) {
          tKeyFilteredByTag = backEndTagTranslation[transactionTag[1]]
        } else if (backEndTagTranslation[customHandlerError.json.tag]) {
          tKeyFilteredByTag = backEndTagTranslation[customHandlerError.json.tag]
        }
        yield call(reject, new SubmissionError({ _error: t('ERRORS.BACKEND_TAG_TRANSLATION.' + tKeyFilteredByTag) }))
      } else {
        yield call(reject, new SubmissionError({ _error: customHandlerError.json ? customHandlerError.json.message : customHandlerError.message }))
      }
    }
    yield call(handleError, { error, customHandler })
  } finally {
    yield put(commonActions.hideLoader())
  }
}

function * userHasNewVersion (data) {
  let maxNumbers = 4
  if (data.userVersion === null || data.userVersion === undefined || data.userVersion.trim() === '') {
    return true
  }
  const systemVersionParts = data.systemVersion.split('.').map(Number)
  while (systemVersionParts.length < maxNumbers) {
    systemVersionParts.push(0)
  }
  const userVersionParts = data.userVersion.split('.').map(Number)
  while (userVersionParts.length < maxNumbers) {
    userVersionParts.push(0)
  }

  for (let i = 0; i < maxNumbers; i++) {
    if (systemVersionParts[i] > userVersionParts[i]) {
      return true
    } else if (systemVersionParts[i] < userVersionParts[i]) {
      return false
    }
  }

  return false
}

export function * watchFetchAuth () {
  yield takeLatest(authActionTypes.FETCH_AUTH, fetchAuth)
}

export function * logout () {
  try {
    yield put(commonActions.showLoader())
    const auth = yield select(getAuth)
    yield put(dashboardActions.setFirstLoad())
    yield call(logoutService, auth.token)
    yield call(deleteAuth)
    // yield put(history.push('/login'))
    yield put(authActions.logoutSuccess())
    yield put(push('/login'))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchLogout () {
  yield takeLatest(authActionTypes.LOGOUT, logout)
}

export function * resetPassword ({ values, t, resolve, reject, tokenPassword }) {
  try {
    const auth = yield select(getAuth)
    yield put(commonActions.showLoader())
    let encriptedPasword = encryptPassword(values.newPassword, auth.emailAddress)
    if (tokenPassword) {
      yield call(resetPasswordWithTokenService, { newPassword: encriptedPasword }, tokenPassword)
    } else {
      yield call(resetPasswordService, { newPassword: encriptedPasword }, auth.token)
    }
    yield call(resolve)
    // yield put(history.push('/dashboard'))
    yield put(push('/dashboard'))
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json.tag === 'previouslyUsedPassword' || customHandlerError.json.tag === 'repeatedPassword') {
        yield call(reject, new SubmissionError({ newPassword: t('ERRORS.BACKEND_TAGS.repeatedPassword') }))
      }
    }
    yield call(handleError, { error, customHandler })
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchResetPassword () {
  yield takeEvery(authActionTypes.RESET_PASSWORD, resetPassword)
}

export function * rememberPassword ({ values, resolve, reject }) {
  try {
    yield put(commonActions.showLoader())
    yield call(rememberPasswordService, values)
    yield put(authActions.rememberPasswordSuccess())
    yield call(resolve)
    // yield put(HashRouter.history.push('/login'))
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(authActions.rememberPasswordError(customHandlerError))
      yield call(reject, new SubmissionError({ _error: customHandlerError.json ? customHandlerError.json.message : customHandlerError.message }))
    }
    yield call(handleError, { error, customHandler })
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchRememberPassword () {
  yield takeEvery(authActionTypes.REMEMBER_PASSWORD, rememberPassword)
}

export function * setOrganizedUnit ({ organizedUnit }) {
  try {
    yield put(commonActions.showLoader())
    const auth = yield call(loadAuth)
    yield call(saveAuth, { ...auth, organizedUnit })
    yield put(authActions.setOrganizedUnitSuccess(organizedUnit))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchSetOrganizedUnit () {
  yield takeLatest(authActionTypes.SET_ORGANIZED_UNIT, setOrganizedUnit)
}

export function * checkToken ({ tokenPassword }) {
  try {
    yield put(commonActions.showLoader())
    const data = yield call(checkTokenService, tokenPassword)
    yield put(authActions.checkTokenSuccess(data.name, data.surnames, data.emailAddress))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchCheckToken () {
  yield takeLatest(authActionTypes.CHECK_TOKEN, checkToken)
}

export function * encryptQrContent () {
  try {
    yield put(commonActions.showLoader())
    const auth = yield select(getAuth)
    const qrContentEncrypted = yield call(encryptQrContentServices, auth.token)
    yield put(authActions.encryptQrContentSuccess(qrContentEncrypted))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchEncryptQrContent () {
  yield takeLatest(authActionTypes.ENCRYPT_QR_CONTENT, encryptQrContent)
}

export function * fetchReleaseNotesFile ({ newVersionNumber, userLanguageId }) {
  if (newVersionNumber === null) {
    newVersionNumber = 'default'
  }
  try {
    yield put(commonActions.showLoader())
    let response = yield call(fetch, '/releaseNotes/' + newVersionNumber + '.json')
    let data
    try {
      data = yield response.json()
    } catch (error) {
      response = yield call(fetch, '/releaseNotes/default.json')
      data = yield response.json()
    }

    const objectResult = data.details.find(obj => obj.languageId === userLanguageId)
    yield put(authActions.fetchReleaseNotesFileSuccess(objectResult.content))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchFetchReleaseNotesFile () {
  yield takeLatest(authActionTypes.FETCH_RELEASE_NOTES, fetchReleaseNotesFile)
}
