import React, { PureComponent } from 'react'
import { Col } from 'react-bootstrap'

class InputPasswordStrengthIndicator extends PureComponent {
  constructor () {
    super()
    this.state = {
      passwordStrengthLevel: 0
    }
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (prevProps.passwordValue !== this.props.passwordValue || prevProps.passwordError !== this.props.passwordError) {
      this.setState({
        passwordStrengthLevel: this.getPasswordStrengthLevel(this.props.passwordValue, this.props.passwordError)
      })
    }

    return null
  }

  componentDidUpdate () {
    // getSnapshotBeforeUpdate() should be used with componentDidUpdate()
  }

  getPasswordStrengthLevel (password, newPasswordError) {
    let passwordStrengthLevel = 0
    if (password.length > 0) {
      passwordStrengthLevel = 1
      if (password.length > this.props.passwordPolicy.minNumberOfCharactersInPassword / 2) {
        passwordStrengthLevel = 2
        if (!newPasswordError) passwordStrengthLevel = 3
      }
    }
    return passwordStrengthLevel
  }

  render () {
    const {
      t,
      colSm = 3,
      colSmOffset = 0
    } = this.props
    const tKey = 'GLOBAL.FORMS.INPUT_PASSWORD_STRENGTH_INDICATOR.'
    return (
      <Col sm={colSm} smOffset={colSmOffset} className="field password-strength-indicator-field">
        <label>{t(`${tKey}SECURITY_LEVEL`)}</label>
        <div>
          <span className={this.state.passwordStrengthLevel > 0 ? 'reached' : undefined}>{t(`${tKey}SECURITY_LEVEL_LOWER`)}</span>
          <span className={this.state.passwordStrengthLevel > 1 ? 'reached' : undefined}>{t(`${tKey}SECURITY_LEVEL_MEDIUM`)}</span>
          <span className={this.state.passwordStrengthLevel > 2 ? 'reached' : undefined}>{t(`${tKey}SECURITY_LEVEL_HIGH`)}</span>
        </div>
      </Col>
    )
  }
}

export default InputPasswordStrengthIndicator
