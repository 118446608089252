import apiFetch from '../apiFetch'
export default function (entityId, orderBy, token) {
  const queryParamsArray = [
    entityId ? `entityId=${entityId}` : null,
    orderBy ? `orderBy=${orderBy}` : null
  ]
  const queryParams = queryParamsArray.reduce(
    (result, query, idx) => result + (query ? (result ? `&${query}` : `?${query}`) : '')
    , ''
  )
  return apiFetch({ endPoint: 'Entity/GetUserRecurrentServiceEntity' + queryParams, method: 'GET', body: null, token: token })
}
