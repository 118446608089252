import apiFetch from '../apiFetch'

export default function (financingTypeId, financingTypeValues, companyId, financingTypeProductId, token) {
  const body = {
    financingTypeId: financingTypeId,
    isDeleted: false,
    financingTypeLanguage: financingTypeValues,
    financingTypeProductId: financingTypeProductId
  }
  return apiFetch({ endPoint: `FinancingType/Put/${financingTypeId}/${companyId}`, method: 'PUT', body: body, token: token })
}
