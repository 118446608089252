export default {
  default_error: 'DEFAULT_ERROR',
  dossierIsBeingUpdated: 'DOSSIER_IS_BEING_UPDATED',
  EntityNotFound: 'ENTITY_NOT_FOUND',
  recurrentEntityAssigned: 'RECURRENT_ENTITY_ASSIGNED',
  EntityNecessaryToPrint: 'ENTITY_NECESSARY_TO_PRINT',
  ContactNotFound: 'CONTACT_NOT_FOUND',
  vehicleRequired: 'VEHICLE_REQUIRED',
  VehicleNecessaryToPrint: 'VEHICLE_NECESSARY_TO_PRINT',
  errorNoDefinedInTransaction: 'TRANSACTION_ERROR',
  notValidToBeReviewed: 'IMAGE_UNAVAILABLE',
  vehicleNotAvailableForPurchase: 'VEHICLE_NOT_AVAILABLE_FOR_PURCHASE',
  fillAllLanguages: 'FILL_ALL_LANGUAGES',
  cannotDeleteIsUsed: 'CANNOT_DELETE_IS_USED',
  noPermission: 'NO_PERMISSION',
  noDocumentsToPrint: 'NO_DOCUMENTS_TO_PRINT',
  noOuDataToPrint: 'NO_OU_DATA_TO_PRINT',
  noOuLogoToPrint: 'NO_OU_LOGO_TO_PRINT',
  noPaperworkTypeToPrint: 'NO_PAPERWORK_TYPE_TO_PRINT',
  noEntityNameToPrint: 'NO_ENTITY_NAME_TO_PRINT',
  noEntityDniCifToPrint: 'NO_ENTITY_DNI_CIF_TO_PRINT',
  noEntityAddressToPrint: 'NO_ENTITY_ADDRESS_TO_PRINT',
  noEntityBirthDayDateToPrint: 'NO_ENTITY_BIRTH_DAY_DATE_TO_PRINT',
  noAgencyRepresentativeNameToPrint: 'NO_AGENCY_REPRESENTATIVE_NAME_TO_PRINT',
  noAgencyCollegiateNumberToPrint: 'NO_AGENCY_COLLEGIATE_NUMBER_TO_PRINT',
  noAgencyCityToPrint: 'NO_AGENCY_CITY_TO_PRINT',
  noAgencyAddressToPrint: 'NO_AGENCY_ADDRESS_TO_PRINT',
  noAgencyNumberToPrint: 'NO_AGENCY_NUMBER_TO_PRINT',
  noAgencyPostCodeToPrint: 'NO_AGENCY_POST_CODE_TO_PRINT',
  ProcessAgencyNotFound: 'PROCESS_AGENCY_NOT_FOUND',
  noEntityRepresentativeNameToPrint: 'NO_ENTITY_REPRESENTATIVE_NAME_TO_PRINT',
  noEntityRepresentativeDniCifToPrint: 'NO_ENTITY_REPRESENTATIVE_DNI_CIF_TO_PRINT',
  noChargeAmountToPrint: 'NO_CHARGE_AMOUNT_TO_PRINT',
  noChargeAmountDescToPrint: 'NO_CHARGE_AMOUNT_DESC_TO_PRINT',
  noVehicleBrandToPrint: 'NO_VEHICLE_BRAND_TO_PRINT',
  noVehicleModelToPrint: 'NO_VEHICLE_MODEL_TO_PRINT',
  noVehicleLineToPrint: 'NO_VEHICLE_LINE_TO_PRINT',
  noVehicleColorToPrint: 'NO_VEHICLE_COLOR_TO_PRINT',
  noModelPriceToPrint: 'NO_MODEL_PRICE_TO_PRINT',
  noDeliveryDateToPrint: 'NO_DELIVERY_DATE_TO_PRINT',
  noReceipDateToPrint: 'NO_RECEIP_DATE_TO_PRINT',
  noChargeNumberToPrint: 'NO_CHARGE_NUMBER_TO_PRINT',
  noCommercialRegistryToPrint: 'NO_COMMERCIAL_REGISTRY_TO_PRINT',
  noChargeOrdinalNumberToPrint: 'NO_CHARGE_ORDINAL_NUMBER_TO_PRINT',
  noDealerNumberToPrint: 'NO_DEALER_NUMBER_TO_PRINT',
  noChargeDescriptionToPrint: 'NO_CHARGE_DESCRIPTION_TO_PRINT',
  EntityNotFoundInParents: 'ENTITY_NOT_FOUND_IN_PARENTS',
  dealerNumberAlreadyExistsOnPurchase: 'DEALER_NUMBER_ALREADY_EXISTS_ON_PURCHASE',
  existingChassis: 'EXISTING_CHASSIS',
  existingLicensePlate: 'EXISTING_LICENSE_PLATE',
  chassisAndLicensePlateBelongToDifferentVehicles: 'CHASSIS_AND_LICENSE_PLATE_BELONG_TO_DIFFERENT_VEHICLES',
  chassisCanNotBeDeleted: 'CHASSIS_CAN_NOT_BE_DELETED',
  licensePlateCanNotBeDeleted: 'LICENSE_PLATE_CAN_NOT_BE_DELETED',
  unavailable: 'PURCHASE_IS_ALREADY_ASIGNED',
  cannotSaveEntity: 'ERROR_DNI',
  missingVehicleData: 'MISSING_VEHICLE_DATA',
  missingCampaignParameters: 'MISSING_CAMPAIGN_PARAMETERS',
  unableChangeCampaignType: 'UNABLE_CHANGE_CAMPAIGN_TYPE',
  missingPaperworkParameters: 'MISSING_PAPERWORK_PARAMETERS',
  DossierNotFound: 'DOSSIER_NOT_FOUND',
  VehicleAlreadyAssigned: 'VEHICLE_ALREADY_ASSIGNED',
  VehicleNotFound: 'VEHICLE_NOT_FOUND',
  VehicleVinChassisDuplicated: 'VEHICLE_DUPLICATED_NEW_DOSSIER',
  noOuDirectionCompleteToPrint: 'NO_OU_DIRECTION_COMPLETE_TO_PRINT',
  noCityUO: 'NO_CITY_UO',
  noLegalSocialRazon: 'NO_LEGAL_SOCIAL_RAZON',
  noLegalBrand: 'NO_LEGAL_BRAND',
  noLegalAddress: 'NO_LEGAL_ADDRESS',
  noLegalCP: 'NO_LEGAL_CP',
  noLegalCity: 'NO_LEGAL_CITY',
  noLegalAccounts: 'NO_LEGAL_ACCOUNTS',
  noEntityCityToPrint: 'NO_ENTITY_CITY_TO_PRINT',
  noEntityStateToPrint: 'NO_ENTITY_STATE_TO_PRINT',
  noEntityCpToPrint: 'NO_ENTITY_CP_TO_PRINT',
  noVehicleData: 'NO_VEHICLE_DATA',
  noModelVehicleToPrint: 'NO_MODEL_VEHICLE_TO_PRINT',
  noColorVehicleToPrint: 'NO_COLOR_VEHICLE_TO_PRINT',
  noVinVehicleToPrint: 'NO_VIN_VEHIECLE_TO_PRINT',
  noLicensePlateVehicleToPrint: 'NO_LICENSE_PLATE_VEHICLE_TO_PRINT',
  noUserName: 'NO_USER_NAME',
  noUserPhone: 'NO_USER_PHONE',
  noUserMail: 'NO_USER_MAIL',
  noDeliveryDateDossierToPrint: 'NO_DELIVERY_DATE_DOSSIER_TO_PRINT',
  noReceptionDateDossierToPrint: 'NO_RECEPTION_DATE_DOSSIER_TO_PRINT',
  noKmDossierToPrint: 'NO_KM_DOSSIER_TO_PRINT',
  noDealerNumberDossierToPrint: 'NO_DEALER_NUMBER_DOSSIER_TO_PRINT',
  noUaCModelVehicleToPrint: 'NO_UA_C_MODEL_VEHICLE_TO_PRINT',
  noUaCColorVehicleToPrint: 'NO_UA_C_COLOR_VEHICLE_TO_PRINT',
  noUaCVinVehicleToPrint: 'NO_UA_C_VIN_VEHICLE_TO_PRINT',
  noUaCLicensePlateVehicleToPrint: 'NO_UA_C_LICENSE_PLATE_VEHICLE_TO_PRINT',
  noUaCDeliveryDateDossierToPrint: 'NO_UA_C_DELIVERY_DATE_DOSSIER_TO_PRINT',
  noUaCKmDossierToPrint: 'NO_UA_C_KM_DOSSIER_TO_PRINT',
  noUaCDealerNumberDossierToPrint: 'NO_UA_C_DEALER_NUMBER_DOSSIER_TO_PRINT',
  noUaCEntityNameToPrint: 'NO_UA_C_ENTITY_NAME_TO_PRINT',
  noUaCEntityDniCifToPrint: 'NO_UA_C_ENTITY_DNI_TO_PRINT',
  noUaCEntityAddressToPrint: 'NO_UA_C_ENTITY_ADDRESS_TO_PRINT',
  noUaCEntityCityToPrint: 'NO_UA_C_ENTITY_CITY_TO_PRINT',
  noUaCEntityStateToPrint: 'NO_UA_C_ENTITY_STATE_TO_PRINT',
  noUaCEntityCpToPrint: 'NO_UA_C_ENTITY_CP_TO_PRINT',
  mailUserAlreadyExist: 'MAIL_USER_ALREADY_EXIST',
  noLicensePlateDate: 'NO_LICENSE_PLATE_DATE',
  noBillingData: 'NO_BILLING_DATA',
  noChargeCityToPrint: 'NO_CHARGE_CITY_TO_PRINT',
  noChargeDateToPrint: 'NO_CHARGE_DATE_TO_PRINT',
  noChargeTermToPrint: 'NO_CHARGE_TERM_TO_PRINT',
  noSpecifiedErrorToPrint: 'NO_SPECIFIED_ERROR_TO_PRINT',
  noContactDniToPrint: 'NO_CONTACT_DNI_TO_PRINT',
  noContactNameToPrint: 'NO_CONTACT_NAME_TO_PRINT',
  noContactAddressToPrint: 'NO_CONTACT_ADDRESS_TO_PRINT',
  noContactCityToPrint: 'NO_CONTACT_CITY_TO_PRINT',
  noContactStateToPrint: 'NO_CONTACT_STATE_TO_PRINT',
  noContactCountryToPrint: 'NO_CONTACT_COUNTRY_TO_PRINT',
  noContactCPToPrint: 'NO_CONTACT_CP_TO_PRINT',
  noDossierClausesToPrint: 'NO_DOSSIER_CLAUSES_TO_PRINT',
  hotFolderStructureNotFound: 'HOT_FOLDER_STRUCTURE_NOT_FOUND',
  hotFolderDossierTypeNotFound: 'HOT_FOLDER_DOSSIER_TYPE_NOT_FOUND',
  hotFolderDossierNotFound: 'HOT_FOLDER_DOSSIER_NOT_FOUND',
  purchaseCanNotBeCancelled: 'PURCHASE_CAN_NOT_BE_CANCELLED',
  parameterNotEspecified: 'PARAMETER_NOT_ESPECIFIED',
  sameVehicleInSale: 'UAC_AND_SALE_SAME_VEHICLE',
  emailFormatInvalid: 'EMAIL_FORMAT_INVALID_SIGN',
  zeroByteFile: 'ZERO_BYTE_FILE',
  serviceNotValidDate: 'SERVICE_NOT_VALID_DATE',
  lluzarJsonDeserializeError: 'LLUZAR_JSON_DESERIALIZE_ERROR',
  // TemplateTags
  noEntitySurnameToPrint: 'NO_ENTITY_SURNAME_TO_PRINT',
  noEntityMailToPrint: 'NO_ENTITY_MAIL_TO_PRINT',
  noEntityPhoneToPrint: 'NO_ENTITY_PHONE_TO_PRINT',
  noEntityMobileToPrint: 'NO_ENTITY_MOBILE_TO_PRINT',
  noEntityCountryToPrint: 'NO_ENTITY_COUNTRY_TO_PRINT',
  noVehicleVinChasisToPrint: 'NO_VEHICLE_VIN_CHASIS_TO_PRINT',
  noVehicleComercialCodeToPrint: 'NO_VEHICLE_COMERCIAL_CODE_TO_PRINT',
  noVehicleTapestryToPrint: 'NO_VEHICLE_TAPESTRY_TO_PRINT',
  noVehicleFuelToPrint: 'NO_VEHICLE_FUEL_TO_PRINT',
  noVehicleLicensePlateToPrint: 'NO_VEHICLE_LICENSE_PLATE_TO_PRINT',
  noVehicleExpirationItvDateToPrint: 'NO_VEHICLE_EXPIRATION_ITV_DATE_TO_PRINT',
  noVehicleFirstLicensePlateDateToPrint: 'NO_VEHICLE_FIRST_LICENSE_PLATE_DATE_TO_PRINT',
  noOrderBrandToPrint: 'NO_ORDER_BRAND_TO_PRINT',
  noOrderModelToPrint: 'NO_ORDER_MODEL_TO_PRINT',
  noOrderColorToPrint: 'NO_ORDER_COLOR_TO_PRINT',
  noOrderTapestryToPrint: 'NO_ORDER_TAPESTRY_TO_PRINT',
  noEntityParentDniCifToPrint: 'NO_ENTITY_PARENT_DNI_CIF_TO_PRINT',
  noEntityParentNameToPrint: 'NO_ENTITY_PARENT_NAME_TO_PRINT',
  noEntityParentSurnameToPrint: 'NO_ENTITY_PARENT_SURNAME_TO_PRINT',
  noEntityParentMailToPrint: 'NO_ENTITY_PARENT_MAIL_TO_PRINT',
  noEntityParentPhoneToPrint: 'NO_ENTITY_PARENT_PHONE_TO_PRINT',
  noEntityParentMobileToPrint: 'NO_ENTITY_PARENT_MOBILE_TO_PRINT',
  noEntityParentAddressToPrint: 'NO_ENTITY_PARENT_ADDRESS_TO_PRINT',
  noEntityParentCityToPrint: 'NO_ENTITY_PARENT_CITY_TO_PRINT',
  noEntityParentStateToPrint: 'NO_ENTITY_PARENT_STATE_TO_PRINT',
  noEntityParentCountryToPrint: 'NO_ENTITY_PARENT_COUNTRY_TO_PRINT',
  noEntityParentCpToPrint: 'NO_ENTITY_PARENT_CP_TO_PRINT',
  noVehicleParentVinChasisToPrint: 'NO_VEHICLE_PARENT_VIN_CHASIS_TO_PRINT',
  noVehicleParentBrandToPrint: 'NO_VEHICLE_PARENT_BRAND_TO_PRINT',
  noModelVehicleParentToPrint: 'NO_MODEL_VEHICLE_PARENT_TO_PRINT',
  noVehicleParentComercialCodeToPrint: 'NO_VEHICLE_PARENT_COMERCIAL_CODE_TO_PRINT',
  noColorVehicleParentToPrint: 'NO_COLOR_VEHICLE_PARENT_TO_PRINT',
  noVehicleParentTapestryToPrint: 'NO_VEHICLE_PARENT_TAPESTRY_TO_PRINT',
  noVehicleParentFuelToPrint: 'NO_VEHICLE_PARENT_FUEL_TO_PRINT',
  noVehicleParentLicensePlateToPrint: 'NO_VEHICLE_PARENT_LICENSE_PLATE_TO_PRINT',
  noVehicleParentExpirationItvDateToPrint: 'NO_VEHICLE_PARENT_EXPIRATION_ITV_DATE_TO_PRINT',
  noVehicleParentFirstLicensePlateDateToPrint: 'NO_VEHICLE_PARENT_FIRST_LICENSE_PLATE_DATE_TO_PRINT',
  noOuNameToPrint: 'NO_OU_NAME_TO_PRINT',
  noOuBrandToPrint: 'NO_OU_BRAND_TO_PRINT',
  noOuCpToPrint: 'NO_OU_CP_TO_PRINT',
  noOuAddressToPrint: 'NO_OU_ADDRESS_TO_PRINT',
  noOuStateToPrint: 'NO_OU_STATE_TO_PRINT',
  noOuCountryToPrint: 'NO_OU_COUNTRY_TO_PRINT',
  noLegalCIFToPrint: 'NO_LEGAL_CIF_TO_PRINT',
  noLegalCommercialRegistryToPrint: 'NO_LEGAL_COMMERCIAL_REGISTRY_TO_PRINT',
  noLegalDescriptionToPrint: 'NO_LEGAL_DESCRIPTION_TO_PRINT',
  noLegalAddressToPrint: 'NO_LEGAL_ADDRESS_TO_PRINT',
  noLegalCPToPrint: 'NO_LEGAL_CP_TO_PRINT',
  noLegalCityToPrint: 'NO_LEGAL_CITY_TO_PRINT',
  noLegalAccountsToPrint: 'NO_LEGAL_ACCOUNTS_TO_PRINT',
  noUserNameToPrint: 'NO_USER_NAME_TO_PRINT',
  noUserSurnamesToPrint: 'NO_USER_SURNAMES_TO_PRINT',
  noUserPhoneToPrint: 'NO_USER_PHONE_TO_PRINT',
  noUserEmailToPrint: 'NO_USER_EMAIL_TO_PRINT',
  noAgencyNameToPrint: 'NO_AGENCY_NAME_TO_PRINT',
  noAgencyCollegiateToPrint: 'NO_AGENCY_COLLEGIATE_TO_PRINT',
  noAgencyRepresentativeCifToPrint: 'NO_AGENCY_REPRESENTATIVE_CIF_TO_PRINT',
  noAgencyProvinceToPrint: 'NO_AGENCY_PROVINCE_TO_PRINT',
  noDeliveryCityToPrint: 'NO_DELIVERY_CITY_TO_PRINT',
  noLastITVDateToPrint: 'NO_LAST_ITV_DATE_TO_PRINT',
  noAmountToPrint: 'NO_AMOUNT_TO_PRINT',
  noAdditionalClausesToPrint: 'NO_ADDITIONAL_CLAUSES_TO_PRINT',
  noBillingDateToPrint: 'NO_BILLING_DATE_TO_PRINT',
  noBillingNumberToPrint: 'NO_BILLING_NUMBER_TO_PRINT',
  noActivityClientToPrint: 'NO_ACTIVITY_CLIENT_TO_PRINT',
  noActivityBuyerToPrint: 'NO_ACTIVITY_BUYER_TO_PRINT',
  noPaymentMethodToPrint: 'NO_PAYMENT_METHOD_TO_PRINT',
  noVehicleTypeToPrint: 'NO_VEHICLE_TYPE_TO_PRINT',
  noPreviousUsageToPrint: 'NO_PREVIOUS_USAGE_TO_PRINT',
  noIvaTypeToPrint: 'NO_IVA_TYPE_TO_PRINT',
  noLaterUseToPrint: 'NO_LATER_USE_TO_PRINT',
  noContactSurNameToPrint: 'NO_CONTACT_SUR_NAME_TO_PRINT',
  noContactEmailToPrint: 'NO_CONTACT_EMAIL_TO_PRINT',
  noContactPhoneToPrint: 'NO_CONTACT_PHONE_TO_PRINT',
  noContactMobileToPrint: 'NO_CONTACT_MOBILE_TO_PRINT',
  noEntityDocumentTypeToPrint: 'NO_ENTITY_DOCUMENT_TYPE_TO_PRINT',
  noEntRepNameToPrint: 'NO_ENT_REP_NAME_TO_PRINT',
  noEntRepSurnameToPrint: 'NO_ENT_REP_SURNAME_TO_PRINT',
  noEntRepDniToPrint: 'NO_ENT_REP_DNI_TO_PRINT',
  noEntRepDocumentTypeToPrint: 'NO_ENT_REP_DOCUMENT_TYPE_TO_PRINT',
  noVehicleBrandModelToPrint: 'NO_VEHICLE_BRAND_MODEL_TO_PRINT',
  noVehicleColorLicenseToPrint: 'NO_VEHICLE_COLOR_LICENSE_TO_PRINT',
  noContactRepCliNameToPrint: 'NO_CONTACT_REP_CLI_NAME_TO_PRINT',
  noContactRepCliDocTypeToPrint: 'NO_CONTACT_REP_CLI_DOC_TYPE_TO_PRINT',
  noContactRepCliDNIToPrint: 'NO_CONTACT_REP_CLI_DNI_TO_PRINT',
  noContactCliAddressToPrint: 'NO_CONTACT_CLI_ADDRESS_TO_PRINT',
  noContactCliCityToPrint: 'NO_CONTACT_CLI_CITY_TO_PRINT',
  noDocumentListToPrint: 'NO_DOCUMENT_LIST_TO_PRINT',
  noVehicleBrandModelColorToPrint: 'NO_VEHICLE_BRAND_MODEL_COLOR_TO_PRINT',
  noOuPhoneToPrint: 'NO_OU_PHONE_TO_PRINT',
  noSalesmanToPrint: 'NO_SALESMAN_TO_PRINT',
  noEntParentDocumentTypeToPrint: 'NO_ENT_PARENT_DOCUMENT_TYPE_TO_PRINT',
  noEntParentRepNameToPrint: 'NO_ENT_PARENT_REP_NAME_TO_PRINT',
  noEntParentRepSurnameToPrint: 'NO_ENT_PARENT_REP_SURNAME_TO_PRINT',
  noEntParentRepDniToPrint: 'NO_ENT_PARENT_REP_DNI_TO_PRINT',
  noEntParentRepDocumentTypeToPrint: 'NO_ENT_PARENT_REP_DOCUMENT_TYPE_TO_PRINT',
  noEntityCPCityStateToPrint: 'NO_ENTITY_CP_CITY_STATE_TO_PRINT',
  noEntityCPCityToPrint: 'NO_ENTITY_CP_CITY_TO_PRINT',
  noEntityParentCPCityStateToPrint: 'NO_ENTITY_PARENT_CP_CITY_STATE_TO_PRINT',
  noPurchaseMethodToPrint: 'NO_PURCHASE_METHOD_TO_PRINT',
  noLegalStateToPrint: 'NO_LEGAL_STATE_TO_PRINT',
  noLegalCountryToPrint: 'NO_LEGAL_COUNTRY_TO_PRINT',
  noLegalRepresentativeToPrint: 'NO_LEGAL_REPRESENTATIVE_TO_PRINT',
  noLegalDNIToPrint: 'NO_LEGAL_DNI_TO_PRINT',
  noLegalCompleteToPrint: 'NO_LEGAL_COMPLETE_TO_PRINT',
  noRepCliCompleteNameToPrint: 'NO_REP_CLI_COMPLETE_NAME_TO_PRINT',
  noRepCliParentCompleteNameToPrint: 'NO_REP_CLI_PARENT_COMPLETE_NAME_TO_PRINT',
  noContentUacTemplateToPrint: 'NO_CONTENT_UAC_TEMPLATE_TO_PRINT',
  noContentSaleUacTemplateToPrint: 'NO_CONTENT_SALE_UAC_TEMPLATE_TO_PRINT',
  noContentLocaltionUacTemplateToPrint: 'NO_CONTENT_LOCALTION_UAC_TEMPLATE_TO_PRINT',
  noRepTextUacTemplateToPrint: 'NO_REP_TEXT_UAC_TEMPLATE_TO_PRINT',
  noRepParentTextUacTemplateToPrint: 'NO_REP_PARENT_TEXT_UAC_TEMPLATE_TO_PRINT',
  passwordNotEqual: 'PASSWORD_REPEAT_NOT_EQUAL_TO_PASSWORD',
  actualUsedPassword: 'PASSWORD_USED_ACTUALLY',
  uonotFind: 'UO_NOT_FIND',
  documentCannotBeSaved: 'DOCUMENT_CAN_NOT_BE_SAVED',
  rejectedByProcessingAgency: 'REJECTED_BY_PROCESSING_AGENCY',
  agencyDeleteError: 'AGENCY_DELETE_ERROR',
  agencyInsertError: 'AGENCY_INSERT_ERROR',
  agencyUpdateError: 'AGENCY_UPDATE_ERROR',
  notValidPercentage: 'NOT_VALID_PERCENTAGE',
  invalidData: 'INVALID_DATA',
  quotasProcessingAgencyDuplicated: 'QUOTAS_PROCESSING_DUPLICATED',
  quotasDateError: 'QUOTAS_DATE_ERROR',
  invalidGroupCode: 'INVALID_GROUP_CODE',
  invalidTypeCode: 'INVALID_TYPE_CODE',
  errorUpdatingOrInsertDetails: 'ERROR_UPDATING_INSERTING_DETAILS',
  errorWhileUpdatingOrInsertingType: 'ERROR_WHILE_INSERTING_TYPE',
  errorWhileUpdatingOrInsertingGroup: 'ERROR_INSERTING_GROUP',
  orderDuplicated: 'ORDER_DUPLICATED',
  noInfoToExport: 'NO_INFO_TO_EXPORT',
  unavailableUAC: 'UNAVAILABLE_UAC',
  UACequalParent: 'UAC_EQUAL_PARENT',
  SaleStillActive: 'SALE_STILL_ACTIVE',
  columnNotValid: 'COLUMN_NOT_VALID',
  emptyTittle: 'EMPTY_COLUMN_NAME',
  error_sign_no_email: 'ERROR_SIGN_NO_EMAIL',
  error_sign_no_sms: 'ERROR_SIGN_NO_SMS',
  mandatoryAlreadyGenerated: 'MANDATORY_ALREADY_GENERATED',
  signature_client_not_found: 'SIGNATURE_CLIENT_NOT_FOUND',
  NotPermissionChangeUO: 'NOT_PERMISSION_CHANGE_UO',
  ErrorSendEmailIdocMobileUrlAuth: 'ERROR_SEND_EMAIL_IDOCMOBILEURLAUTH',
  signFileNotFound: 'SIGN_FILE_NOT_FOUND',
  noParentDossierOrSalesmanSign: 'NOT_PARENT_DOSSIER_OR_SALESMAN_SIGN',
  noSalesmanSign: 'NOT_SALESMAN_SIGN',
  pageNumberNotFound: 'PAGE_NUMBER_NOT_FOUND',
  noContactCliNameToPrint: 'NO_CLIENT_DATA',
  ftpnotconnect: 'FTP_NOT_CONNECTED',
  cantRemoveVehicle: 'CANT_REMOVE_VEHICLE',
  errorDataChangeUo: 'ERROR_DATA_CHANGEUO',
  CampaignDetailsCountryYetExists: 'CAMPAIGN_DETAILS_COUNTRY_YET_EXISTS',
  errorUploadSignUser: 'UPLOAD_SIGN_ERROR',
  errorDownloadSignUser: 'DOWNLOAD_SIGN_ERROR',
  error_format_email: 'EMAIL_FORMAT_OR_UNNECESSARY',
  accountLockedInfo: 'ACCOUNT_LOCKED_INFO',
  apiSignError: 'API_SIGN_ERROR',
  emailTemplateNotFound: 'EMAIL_TEMPLATE_NOT_FOUND',
  powerbiAuthFailed: 'POWERBI_AUTH_FAILED',
  powerbiReportsNotFound: 'POWERBI_REPORTS_NOT_FOUND',
  powerbiReportIdNotValid: 'POWERBI_REPORT_ID_NOT_VALID',
  powerbiReportNotFoundOnWorkspace: 'POWERBI_REPORT_NOT_FOUND_ON_WORKSPACE',
  powerbiGenerateTokenError: 'POWERBI_GENERATE_TOKEN_ERROR',
  powerbiReportError: 'POWERBI_REPORT_ERROR',
  errorGeneratingBilling: 'ERROR_GENERATING_BILLING',
  errorSendDataApiSavePlate: 'ERROR_SEND_DATA_API_SAVEPLATE',
  error_incompatible_sign_format: 'ERROR_INCOMPATIBLE_SIGN_FORMAT',
  deleteFinancingComponentError: 'DELETE_FINCANCING_COMPONENT_ERROR',
  updateUserError: 'UPDATE_USER_ERROR',
  errorEmailAlreadyExists: 'ERROR_EMAIL_ALREADY_EXISTS',
  multipleVehiclesFound: 'MULTIPLE_VEHICLES_FOUND',
  insufficientFilter: 'INSUFFICIENT_FILTER',
  onlyExcelFormat: 'ONLY_EXCEL_FORMAT',
  DealerNumberMandatory: 'DEALER_NUMBER_MANDATORY',
  QuiterVehicleError: 'QUITER_VEHICLE_ERROR',
  QuiterVehicleMandatory: 'QUITER_VEHICLE_MANDATORY',
  QuiterBrandNotRecognized: 'QUITER_BRAND_NOT_RECOGNIZED',
  BadSaleTypeInfo: 'BAD_SALE_TYPE_INFO',
  StockLoaderQuiterError: 'STOCK_LOADER_QUITER_ERROR',
  errorResponseDataApiSavePlate: 'ERROR_RESPONSE_DATA_API_SAVEPLATE',
  dossierSaleIsHistorical: 'DOSSIER_SALE_IS_HISTORICAL',
  noOrderVehicleBrandToPrint: 'NO_ORDER_VEHICLE_BRAND_TO_PRINT',
  noSalesmanManagerName: 'NO_SALESMAN_MANAGER_NAME',
  noSalesmanManagerPhoneToPrint: 'NO_USER_PHONE_TO_PRINT',
  FINANCING_COMPANY_YET_EXIST: 'FINANCING_COMPANY_YET_EXIST',
  FinanceTypeYetExist: 'FINANCE_TYPE_YET_EXIST',
  FuelYetExist: 'FUEL_YET_EXIST',
  PaymentMethodYetExist: 'PAYMENT_METHOD_YET_EXIST',
  itemNotExist: 'ITEM_NOT_EXIST',
  UpdateFinancingTypeError: 'UPDATE_FINANCING_TYPE_ERROR',
  invoiceNumberDuplicated: 'INVOICE_DUPLICATED',
  productYetExists: 'PRODUCT_YET_EXISTS',
  productNotExists: 'PRODUCT_NOT_EXISTS',
  productActive: 'PRODUCT_ACTIVE',
  nameMandatory: 'NAME_MANDATORY',
  contactChildDataRequired: 'CONTACT_OR_CHILD_NEEDED',
  noAgencyRealNameToPrint: 'NO_AGENCY_REAL_NAME_TO_PRINT',
  errorCancellationProcessingAgency: 'CANCELLATION_FAILED',
  loadTokenNotFound: 'LOAD_TOKEN_NOT_FOUND',
  successfulTestNotFound: 'SUCCESSFUL_TEST_NOT_FOUND',
  loadTokenDuplicated: 'LOAD_TOKEN_DUPLICATED',
  errorInsertOus: 'ERROR_INSERT_OUS',
  dataLoadColumnNotValid: 'DATA_LOAD_COLUMN_NOT_VALID',
  mustHaveSale: 'MUST_HAVE_SALE',
  noPaperworkDeliveryDateToPrint: 'NO_PAPERWORK_DELIVERY_DATE_TO_PRINT',
  onlyNumbersAndLetters: 'ONLY_NUMBERS_AND_LETTERS',
  vatTypeNotContemplated: 'VAT_TYPE_NOT_CONTEMPLATED',
  queryReturnTooManyEntities: 'QUERY_RETURN_TOO_MANY_ENTITIES',
  error_sign_recurrent_no_email_or_mobile: 'RECURRENT_NO_EMAIL_OR_MOBILE',
  massiveDownloadIdRequired: 'MASSIVE_DOWNLOADID_REQUIRED',
  fileNameExists: 'FILE_NAME_EXISTS',
  massiveDownloadPendingPathNotFound: 'MASSIVE_DOWNLOAD_PENDING_PATH_NOT_FOUND',
  fileNameAndDocumentsRequired: 'FILE_NAME_AND_DOCUMENTS_REQUIRED',
  massiveDownloadFileNotFound: 'MASSIVE_DOWNLOAD_FILE_NOT_FOUND',
  massiveDownloadNotFound: 'MASSIVE_DOWNLOAD_NOT_FOUND',
  notAllFilesHaveDocument: 'NOT_ALL_FILES_HAVE_DOCUMENT',
  notZipFormat: 'NOT_ZIP_FORMAT',
  dealerNotCorrespond: 'DEALER_NOT_CORRESPOND',
  notedContractAlreadyExists: 'NOTED_CONTRACT_ALREADY_EXISTS',
  contractNotFoundToshiko: 'CONTRACT_NOT_FOUND_TOSHIKO',
  errorDossierHasNoContract: 'ERROR_DOSSIER_HAS_NO_CONTRACT',
  errorConnectToshiko: 'ERROR_CONNECT_TOSHIKO',
  dossierToshikoSuccessfully: 'DOSSIER_TOSHIKO_SUCCESSFULLY',
  noConfigOu: 'NO_CONFIG_OU',
  errorDuplicatesOrganizedUnitConfiguration: 'ERROR_DUPLICATES_ORGANIZED_UNIT_CONFIGURATION',
  filesNumberExceeded: 'FILES_NUMBER_EXCEEDED',
  noExcelFileFound: 'NO_EXCEL_FILE_FOUND',
  noZipFileFound: 'NO_ZIP_FILE_FOUND',
  emptyFileRequest: 'EMPTY_FILE_REQUEST',
  signingProcessExists: 'SIGN_PROCESS_EXISTS',
  documentTypeHasDocumentTypeUses: 'DOCUMENT_TYPE_HAS_DOCUMENT_TYPE_USES',
  documentTypeHasDocumentTypeUsesDependant: 'DOCUMENT_TYPE_HAS_DOCUMENT_TYPE_USES_DEPENDANT',
  documentTypeHasDocumentTypeUsesHistorical: 'DOCUMENT_TYPE_HAS_DOCUMENT_TYPE_USES_HISTORICAL',
  errorDuplicatesOrganizedUnitSaleOperation: 'ERROR_DUPLICATES_ORGANIZED_UNIT_SALE_OPERATION',
  errorDeleteDossierOrganizedUnitSaleOperation: 'ERROR_DELETE_DOSSIER_ORGANIZED_UNIT_SALE_OPERATION',
  operationTypeAssociated: 'OPERATION_TYPE_ASSOCIATED',
  metadataHasDocument: 'METADATA_HAS_DOCUMENT',
  cancelSignSignaturitError: 'CANCEL_SIGN_SIGNATURIT_ERROR',
  saleTypeInUse: 'SALE_TYPE_IN_USE',
  signConfigurationNotExists: 'SIGN_CONFIGURATION_NOT_EXISTS',
  signEntityDoesNotExist: 'SIGN_ENTITY_DOES_NOT_EXIST',
  error_sign_unavailable: 'ERROR_SIGN_UNAVAILABLE',
  documentNoValid: 'DOCUMENT_NOT_VALID',
  errorAddSignImageToDocument: 'ERROR_ADD_SIGN_IMAGE_TO_DOCUMENT',
  signature_creation_error: 'SIGNATURE_CREATION_ERROR',
  errorDeleteSignDocumentConfiguration: 'ERROR_DELETE_SIGN_DOCUMENT_CONFIGURATION',
  errorDeleteSignEntity: 'ERROR_DELETE_SIGN_ENTITY',
  purchaseAlreadyExists: 'PURCHASE_ALREADY_EXISTS',
  defaultErrorSelfSupply: 'DEFAULT_ERROR_SELF_SUPPLY',
  checklistNotFound: 'CHECKLIST_NOT_FOUND',
  templateNotFound: 'TEMPLATE_NOT_FOUND',
  NoTemplateFileFound: 'NO_TEMPLATE_FILE_FOUND',
  error_sign_cancel_permission: 'ERROR_SIGN_CANCEL_PERMISSION',
  error_sign_cancel_unavailable: 'ERROR_SIGN_CANCEL_UNAVAILABLE',
  checkSingleCancelRequestFail: 'CHECK_SINGLE_CANCEL_REQUEST_FAIL',
  getSignIdListFromGroupFail: 'GET_SIGNID_LIST_FROM_GROUP_FAIL',
  sendCancelRequestFail: 'SEND_CANCEL_REQUEST_FAIL',
  documentStatusNotValid: 'DOCUMENT_STATUS_NOT_VALID',
  rejectDocumentUpdateDossierError: 'REJECT_DOCUMENT_UPDATE_DOSSIER_ERROR',
  rejectDocumentSendEmailCreatorError: 'REJECT_DOCUMENT_SEND_EMAIL_CREATOR_ERROR',
  organizedUnitAlreadyConfigured: 'PROCESSING_AGENCY_OU_ALREADY_CONFIGURED',
  noInsuranceStartDateToPrint: 'NO_INSURANCE_START_DATE_TO_PRINT',
  noInsuranceEndDateToPrint: 'NO_INSURANCE_END_DATE_TO_PRINT',
  errorCreateDossier: 'ERROR_CREATE_DOSSIER',
  actionExecutedErrorRefresh: 'ACTION_EXECUTED_ERROR_REFRESH',
  errorSaveEntity: 'ERROR_SAVE_ENTITY',
  errorCheckList: 'ERROR_CHECKLIST',
  errorCancelDossier: 'ERROR_CANCEL_DOSSIER',
  actionOkErrorSendToLeed: 'ACTION_OK_ERROR_SEND_TO_LEED',
  wrongDossierState: 'WRONG_DOSSIER_STATE',
  TemplateDocTypeUseNotFound: 'TEMPLATE_DOC_TYPE_USE_NOT_FOUND',
  processAgencyAlreadyHasEqualItem: 'PROCESSING_AGENCY_ITEM_ALREADY_EXISTS',
  VehicleDifferentUO: 'VEHICLE_DIFFERENT_UO',
  ErrorDeletingDirectionUOAssociedInUO: 'DELETE_DIRECTION_UO_ERROR',
  ErrorDeletingLegalCompanyAssociedInUO: 'DELETE_LEGAL_COMPANY_ERROR',
  userProcessingAgencyAssignError: 'USER_PROCESSING_AGENCY_ASSIGN_ERROR',
  userProcessingAgencyDeleteError: 'USER_PROCESSING_AGENCY_DELETE_ERROR',
  userAlreadyAssignedToAgency: 'USER_ALREADY_ASSIGNED_TO_AGENCY',
  sentToDMSSuccessfully: 'SENT_TO_DMS_SUCCESSFULLY',
  errorSentToDMS: 'ERROR_SENT_TO_DMS',
  errorSentChargeBPro: 'DMS_CHARGE_ERROR',
  noEntityFoundDmsCharge: 'DMS_CHARGE_ERROR_NO_ENTITY_FOUND',
  documentNotFound: 'DOCUMENT_NOT_FOUND',
  documentMoreThanOneFile: 'DOCUMENT_MORE_THAN_ONE_FILE',
  documentIsNotPdf: 'DOCUMENT_IS_NOT_PDF',
  notwatermarkConfiguration: 'NOT_WATERMARK_CONFIGURATION',
  errorSealStampService: 'ERROR_SEAL_STAMP_SERVICE',
  errorSealStamp: 'ERROR_SEAL_STAMP',
  dossierDependant: 'DOSSIER_DEPENDANT',
  errorUserUsed: 'ERROR_USER_USED',
  repairShopExists: 'REPAIRSHOP_EXISTS',
  rattingExists: 'RATTING_EXISTS',
  sentToStepsDMSSuccessfully: 'SENT_TO_STEPS_DMS_SUCCESSFULLY',
  documentAlreadyExists: 'DOCUMENT_ALREADY_EXISTS',
  customFieldCodeNotAvailable: 'CUSTOM_FIELD_CODE_NOT_AVAILABLE',
  customFieldOrderNotAvailable: 'CUSTOM_FIELD_ORDER_NOT_AVAILABLE',
  cannotBeDeletedTemplate: 'CANNOT_BE_DELETED_TEMPLATE',
  cifNotFormat: 'ENTITY_CIF_ERROR_FORMAT',
  cifNotValid: 'ENTITY_CIF_ERROR_INVALID',
  entityRfcNotFormat: 'ENTITY_RFC_ERROR_FORMAT',
  entityRfcNotValid: 'ENTITY_RFC_ERROR_INVALID',
  dniNotFormat: 'ENTITY_DNI_ERROR_FORMAT',
  dniNotValid: 'ENTITY_DNI_ERROR_INVALID',
  entityCurpNotValid: 'ENTITY_CURP_ERROR_INVALID',
  entityCurpNotFormat: 'ENTITY_CURP_ERROR_FORMAT',
  partitaIvaNotFormat: 'PARTITA_IVA_NOT_FORMAT',
  codiceFiscaleNotFormat: 'CODICE_FISCALE_NOT_FORMAT',
  identifierInvalid: 'IDENTIFIER_INVALID',
  maritalStatusExists: 'MARITALSTATUS_EXISTS',
  RUCNotValid: 'ENTITY_DNI_ERROR_INVALID',
  templateRequiredFields: 'TEMPLATE_REQUIRED_FIELDS',
  noPurchasePresentCommentToPrint: 'NO_PURCHASE_PRESENT_COMMENT_TO_PRINT',
  noPurchasePresentProviderToPrint: 'NO_PURCHASE_PRESENT_PROVIDER_TO_PRINT',
  noPurchasePresentCostToPrint: 'NO_PURCHASE_PRESENT_COST_TO_PRINT',
  noVehicleCarlineToPrint: 'NO_VEHICLE_CARLINE_TO_PRINT',
  noVehicleCatalogueToPrint: 'NO_VEHICLE_CATALOGUE_TO_PRINT',
  noVehicleParentModelToPrint: 'NO_VEHICLE_PARENT_MODEL_TO_PRINT',
  noVehicleParentCatalogueToPrint: 'NO_VEHICLE_PARENT_CATALOGUE_TO_PRINT',
  noVehicleParentCarlineToPrint: 'NO_VEHICLE_PARENT_CARLINE_TO_PRINT',
  noEntityTownToPrint: 'NO_ENTITY_TOWN_TO_PRINT',
  noEntityStartStreetToPrint: 'NO_ENTITY_START_STREET_TO_PRINT',
  noEntityEndStreetToPrint: 'NO_ENTITY_END_STREET_TO_PRINT',
  noEntityExternalNumberToPrint: 'NO_ENTITY_EXTERNAL_NUMBER_TO_PRINT',
  noEntityInternalNumberToPrint: 'NO_ENTITY_INTERNAL_NUMBER_TO_PRINT',
  noEntityColonyToPrint: 'NO_ENTITY_COLONY_TO_PRINT',
  noEntityParentColonyToPrint: 'NO_ENTITY_PARENT_COLONY_TO_PRINT',
  noEntityParentTownToPrint: 'NO_ENTITY_PARENT_TOWN_TO_PRINT',
  noEntityParentStartStreetToPrint: 'NO_ENTITY_PARENT_START_STREET_TO_PRINT',
  noEntityParentEndStreetToPrint: 'NO_ENTITY_PARENT_END_STREET_TO_PRINT',
  noEntityParentExternalNumberToPrint: 'NO_ENTITY_PARENT_EXTERNAL_NUMBER_TO_PRINT',
  noEntityParentInternalNumberToPrint: 'NO_ENTITY_PARENT_INTERNAL_NUMBER_TO_PRINT',
  codeAlreadyExists: 'CODE_ALREADY_EXISTS',
  fileNotFormatValid: 'FILE_NOT_FORMAT_VALID',
  fileFormatError: 'FILE_FORMAT_ERROR',
  notPossibleAuditForgetData: 'NOT_POSSIBLE_AUDIT_FORGET_DATA',
  entityDossierFail: 'ACTION_FLEET_ENTITY_ERROR',
  maxDossierFavoriteReached: 'MAX_DOSSIER_FAVORITE_REACHED',
  maxTaskFavoriteReached: 'MAX_TASK_FAVORITE_REACHED',
  processingAgencyManagerAlreadyExists: 'PROCESSING_AGENCY_MANAGER_ALREADY_EXISTS',
  processingAgencyManagerCreateError: 'PROCESSING_AGENCY_MANAGER_CREATE_ERROR',
  processingAgencyManagerNotExists: 'PROCESSING_AGENCY_MANAGER_NOT_EXISTS',
  processingAgencyManagerUpdateError: 'PROCESSING_AGENCY_MANAGER_UPDATE_ERROR',
  processingAgencyManagerDeleteError: 'PROCESSING_AGENCY_MANAGER_DELETE_ERROR',
  processingAgencyManagerRecoverError: 'PROCESSING_AGENCY_MANAGER_RECOVER_ERROR',
  commissionCancellationCodeIdAndCodeAreNull: 'COMMISSIONCANCELLATIONCODEIDANDCODEARENULL',
  commissionCancellationCodeIncomeIsNull: 'COMMISSIONCANCELLATIONCODEINCOMEISNULL',
  commissionCancellationCodeIncomeCodeIsNull: 'COMMISSIONCANCELLATIONCODEINCOMECODEISNULL',
  commissionCancellationCodeIncomeOrganizedUnitIdIsNull: 'COMMISSIONCANCELLATIONCODEINCOMEORGANIZEDUNITIDISNULL',
  commissionCancellationCodeAlreadyExists: 'COMMISSIONCANCELLATIONCODEALREADYEXISTS',
  errorCreatingCommissionCancellationCode: 'ERRORCREATINGCOMMISSIONCANCELLATIONCODE',
  commissionCancellationCodeNotExists: 'COMMISSIONCANCELLATIONCODENOTEXISTS',
  errorUpdatingCommissionCancellationCode: 'ERRORUPDATINGCOMMISSIONCANCELLATIONCODE',
  commissionCancellationCodeInUse: 'COMMISSIONCANCELLATIONCODEINUSE',
  errorDeletingCommissionCancellationCode: 'ERRORDELETINGCOMMISSIONCANCELLATIONCODE',
  notExistsOuWithEntities: 'NOT_EXISTS_OU_WITH_ENTITIES',
  vehicleAlreadyExists: 'VEHICLE_ALREADY_EXISTS',
  fleetChargeCountMoreThanOne: 'FLEET_CHARGE_COUNT_MORE_THAN_ONE'
}
