import mastersActionTypes from '../../../constants/actions/masters/masters'

function initialState () {
  return {
    documentTypes: [],
    documentTypesCount: 0,
    pagesCount: 0,
    languageList: [],
    filter: {
      page: 1,
      orderBy: '',
      pageSize: 10
    },
    documentTypeModal: {
      documentTypeId: null,
      showModal: false
    }
  }
}

function fetchDocumentTypeListSuccess (state, { documentTypeList, filter }) {
  return {
    ...state,
    documentTypeList: documentTypeList.documentTypes,
    documentTypesCount: documentTypeList.documentTypesCount,
    pagesCount: documentTypeList.pagesCount,
    languageSelected: documentTypeList.languageSelected,
    filter
  }
}

function openDocumentTypeModalSuccess (state, { languageList, documentType }) {
  const initial = initialState()
  return {
    ...state,
    documentTypeModal: {
      ...initial.documentTypeModal,
      languageList,
      documentType,
      showModal: true
    }
  }
}

function closeEditModalSuccess (state) {
  return {
    ...state,
    documentTypeModal: {
      ...initialState().documentTypeModal
    }
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case mastersActionTypes.FETCH_DOCUMENT_TYPE_LIST_SUCCESS:
      return fetchDocumentTypeListSuccess(state, action)
    case mastersActionTypes.OPEN_DOCUMENT_TYPE_MODAL_SUCCESS:
      return openDocumentTypeModalSuccess(state, action)
    case mastersActionTypes.CLOSE_EDIT_DOCUMENT_TYPE_MODAL:
      return closeEditModalSuccess(state, action)
    default:
      return state
  }
}
