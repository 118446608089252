import scrollerActionTypes from '../../constants/actions/commons/scroller'

export function initialState () {
  return {
    element: null,
    options: {
      offset: -116,
      duration: 1000,
      delay: 0,
      smooth: 'easeInOutQuart',
      isDynamic: true
    },
    autoScroll: false
  }
}

function setScrollTo (state, { element, options, containerId }) {
  return {
    element,
    options: {
      ...state.options,
      ...options,
      containerId
    },
    autoScroll: true
  }
}

function setScrollerOptions (state, { options }) {
  return {
    ...state,
    options: {
      ...state.options,
      ...options
    },
    autoScroll: false
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case scrollerActionTypes.SET_SCROLL_TO:
      return setScrollTo(state, action)
    case scrollerActionTypes.SET_SCROLLER_OPTIONS:
      return setScrollerOptions(state, action)
    default:
      return state
  }
}
