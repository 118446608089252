import React, { PureComponent } from 'react'
import { OverlayTrigger, Popover, Glyphicon } from 'react-bootstrap'

class ReassignReplacePurchaseRow extends PureComponent {
  constructor () {
    super()
    this.state = {
      target: null,
      show: false
    }
  }

  handleClick (e) {
    this.setState({ target: e.target, show: !this.state.show })
  }

  render () {
    const {
      t,
      modalType, orderId,
      row: {
        dealerNumber,
        vinChassis,
        availabilityDate,
        brand,
        model,
        tapestry,
        color,
        licensePlate,
        purchaseId,
        parentIsHistorical,
        makerNumber,
        makerNumberSale,
        purchaseTypeId,
        carlineDescription,
        carline,
        catalogueDescription,
        catalogue,
        modelDescription,
        modelYear,
        colorDescription,
        tapestryDescription
      },
      showColumns,
      actions: { selectReplacePurchase }
    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.REASSIGN_REPLACE_PURCHASE_MODAL.TABLE.'
    const dealerNumberPopover = (
      <Popover id="popover-trigger-hover-focus" title={t(`${tKey}DEALER_NUMBER`)}>
        {dealerNumber}
      </Popover>
    )
    const vinChassisPopover = (
      <Popover id="popover-trigger-hover-focus" title={t(`${tKey}CHASSIS`)}>
        {vinChassis}
      </Popover>
    )
    const availabilityDatePopover = (
      <Popover id="popover-trigger-hover-focus" title={t(`${tKey}AVAILABILITY_DATE`)}>
        {availabilityDate}
      </Popover>
    )
    const brandPopover = (
      <Popover id="popover-trigger-hover-focus" title={t(`${tKey}BRAND`)}>
        {brand}
      </Popover>
    )
    const modelPopover = (
      <Popover id="popover-trigger-hover-focus" title={t(`${tKey}MODEL`)}>
        {model}
      </Popover>
    )
    const tapestryPopover = (
      <Popover id="popover-trigger-hover-focus" title={t(`${tKey}TAPESTRY`)}>
        {tapestryDescription || tapestry}
      </Popover>
    )
    const colorPopover = (
      <Popover id="popover-trigger-hover-focus" title={t(`${tKey}COLOR`)}>
        {colorDescription || color}
      </Popover>
    )
    const licensePlatePopover = (
      <Popover id="popover-trigger-hover-focus" title={t(`${tKey}LICENSE_PLATE`)}>
        {licensePlate}
      </Popover>
    )
    const selectPopover = (
      <Popover id="popover-trigger-hover-focus" title={t(`${tKey}ADMIN`)}>
        {t(`${tKey}ADMIN`)}
      </Popover>
    )
    const carlinePopover = (
      <Popover id="popover-trigger-hover-focus" title={t(`${tKey}CARLINE`)}>
        {carlineDescription || carline}
      </Popover>
    )
    const cataloguePopover = (
      <Popover id="popover-trigger-hover-focus" title={t(`${tKey}CATALOGUE`)}>
        {catalogueDescription || catalogue}
      </Popover>
    )
    const modelYearPopover = (
      <Popover id="popover-trigger-hover-focus" title={t(`${tKey}MODEL_YEAR`)}>
        {modelDescription || modelYear}
      </Popover>
    )
    return (
      <tr>
        <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={dealerNumberPopover}>
          <td className="column-ellipsis">{dealerNumber}</td>
        </OverlayTrigger>
        <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={vinChassisPopover}>
          <td className="column-ellipsis">{vinChassis}</td>
        </OverlayTrigger>
        <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={availabilityDatePopover}>
          <td className="column-ellipsis">{availabilityDate}</td>
        </OverlayTrigger>
        <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={brandPopover}>
          <td className="column-ellipsis">{brand}</td>
        </OverlayTrigger>
        {showColumns.carline && <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={carlinePopover}>
          <td className="column-ellipsis">{carlineDescription || carline}</td>
        </OverlayTrigger>}
        {showColumns.catalogue && <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={cataloguePopover}>
          <td className="column-ellipsis">{catalogueDescription || catalogue}</td>
        </OverlayTrigger>}
        {showColumns.modelYear && <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={modelYearPopover}>
          <td className="column-ellipsis">{modelDescription || modelYear}</td>
        </OverlayTrigger>}
        <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={modelPopover}>
          <td className="column-ellipsis">{model}</td>
        </OverlayTrigger>
        {showColumns.tapestry && <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={tapestryPopover}>
          <td className="column-ellipsis">{tapestryDescription || tapestryDescription}</td>
        </OverlayTrigger>}
        {showColumns.color && <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={colorPopover}>
          <td className="column-ellipsis">{colorDescription || color}</td>
        </OverlayTrigger>}
        <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={licensePlatePopover}>
          <td className="column-ellipsis">{licensePlate}</td>
        </OverlayTrigger>
        <td style={{ textAlign: 'center' }}>
          <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={selectPopover}>
            <Glyphicon glyph="new-window" onClick={() => {
              selectReplacePurchase(modalType, purchaseId, orderId, parentIsHistorical, makerNumber, makerNumberSale, purchaseTypeId)
            }}/>
          </OverlayTrigger>
        </td>
      </tr>
    )
  }
}

export default ReassignReplacePurchaseRow
