import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
// import { push } from 'react-router-redux'
import CampaignHistorical from '../../../components/dossierHistorical/campaign/CampaignHistorical'
import CampaignHistorical_v2 from '../../../_v2/components/dossierHistorical/campaign/CampaignHistorical'
import dossierCampaignActions from '../../../actions/dossier/campaign'
import commentsActions from '../../../actions/dossier/common/comments'
import clientActions from '../../../actions/dossier/common/client'
import vehicleActions from '../../../actions/dossier/common/vehicle'
import commonHeaderActions from '../../../actions/dossier/common/commonHeader'
import { storeDossier } from '../../../actions/dossier/commonDossierActions'
import {
  fetchEntitySubTypeCombo, fetchEntityFilterSubTypeCombo, fecthFamily, fetchCommercialSocietyTypeCombo, fetchEntityCountryTypeCombo, fetchEntityStateTypeCombo, fetchEntityCityTypeCombo,
  fetchEntityTownTypeCombo, fetchEntityCPTypeCombo, fetchEntityColonyTypeCombo, fetchActivityBpro, fetchClientDataAddress
} from '../../../actions/combos/combos'
import { openModal as openCommonModal } from '../../../actions/common'
import { openDocumentManagementModal as openDocumentModal } from '../../../actions/dossier/common/documentManagementModal'
import { openDocumentEditionModal, closeAddOrEditDocumentModal } from '../../../actions/dossier/common/documentHistoricalEditionModal'
import scrollerActions from '../../../actions/commons/scroller'
import buttonsHeaderActions from '../../../actions/dossier/common/buttonsHeader'
import buttonsSidebarActions from '../../../actions/dossier/common/buttonsSidebar'
import { fetchFilteredRelatedDossier } from '../../../actions/dossier/common/dossierRelatedDossiers'
import { saveSelectedDocuments } from '../../../actions/dossier/sales'
import {
  fetchCustomFields
} from '../../../actions/dossier/customFields'
import { registerLastAccess } from '../../../actions/dossier/common/lastAccessDossier'
import { getStorageValue } from '../../../storage/storage'
import TrackingContainer from '../../tracking/trackingContainer'

export function mapStateToProps (state) {
  return {
    vehicleProps: state.vehicle,
    dossier: state.campaignDossier,
    combos: state.combos,
    windowWidth: state.windowDimensions.width,
    statusHeaderHeight: state.statusHeader.height,
    activeTab: state.buttonsHeader.activeTab,
    buttonsHeaderHeight: state.buttonsHeader.height,
    activeSection: state.buttonsSidebar.activeSection,
    relatedDossiersOrderBy: state.dossierRelatedDossiers.orderby
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      ...dossierCampaignActions,
      ...commentsActions,
      ...clientActions,
      ...vehicleActions,
      ...commonHeaderActions,
      ...buttonsHeaderActions,
      ...buttonsSidebarActions,
      ...scrollerActions,
      storeDossier,
      openDocumentEditionModal,
      closeAddOrEditDocumentModal,
      openCommonModal,
      fetchFilteredRelatedDossier,
      fetchEntitySubTypeCombo,
      fetchEntityFilterSubTypeCombo,
      openDocumentModal,
      fecthFamily,
      saveSelectedDocuments,
      fetchCommercialSocietyTypeCombo,
      fetchEntityCountryTypeCombo,
      fetchEntityStateTypeCombo,
      fetchEntityCityTypeCombo,
      fetchEntityTownTypeCombo,
      fetchEntityCPTypeCombo,
      fetchEntityColonyTypeCombo,
      fetchActivityBpro,
      fetchCustomFields,
      fetchClientDataAddress,
      registerLastAccess
    }, dispatch)
  }
}

let version = parseInt(getStorageValue('interfaceVersion'))
var decoratedComponent = null
if (version === 2) {
  decoratedComponent = TrackingContainer(CampaignHistorical_v2, true)
} else {
  decoratedComponent = TrackingContainer(CampaignHistorical, true)
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(decoratedComponent))
