import React, { PureComponent } from 'react'
import { reduxForm, Field } from 'redux-form'
import InputText from '../../commons/form/InputText'
import { Col, Button, Tooltip, OverlayTrigger, Row } from 'react-bootstrap'
import SimpleTablePage from '../../../containers/commons/general/SimpleTablePage'
import { formatDate } from '../../../util/utils'
import IDocCarPagination from '../../commons/iDocCarPagination'

class AddUsers extends PureComponent {
  constructor () {
    super()
    this.state = {
      filter: {
        searchName: null,
        searchEmail: null,
        role: 'VGR-TALLER'
      },
      orderType: true
    }
  }

  addRepairshop (userId) {
    new Promise((resolve, reject) => {
      this.props.actions.assingUserRepairshop(userId, this.props.repairshopId, resolve)
    }).then((result) => {
      let list = result.repairshopList.filter(x => x.repairshopId === this.props.repairshopId)[0].userRepairshop
      this.props.actions.closeRepairshopMasterModal()
      this.props.actions.openRepairshopUsersModal(list, this.props.repairshopId)
    })
  }

  getIconClass (field) {
    const filters = this.props.filters
    if (filters && filters.orderBy.includes(field)) {
      return filters.orderBy.includes('-')
        ? 'ico-sort-selected-asc'
        : 'ico-sort-selected-desc'
    } else {
      return 'ico-sort'
    }
  }

  onPageChange (page) {
    this.props.actions.fetchUserListForProcessingAgency({ ...this.props.pagination, ...this.state.filter, ...this.props.filter, page: page ?? 1, RepairshopId: this.props.repairshop?.repairshopId })
  }

  searchUsers (newPage) {
    this.props.actions.fetchUserListForProcessingAgency({ ...this.props.pagination, ...this.state.filter, ...this.props.filter, page: newPage ?? 1, RepairshopId: this.props.repairshop?.repairshopId })
  }

  componentDidMount () {
    this.searchUsers()
  }

  resetFilters () {
    this.props.initialize({})
    this.props.reset()
    this.setState({
      filter: {
        searchName: null,
        searchEmail: null,
        role: 'VGR-TALLER'
      }
    })
    this.props.actions.fetchUserListForProcessingAgency({ ...this.props.pagination, role: 'VGR-TALLER', page: 1, RepairshopId: this.props.repairshop?.repairshopId })
  }

  handleNameChange (searchName) {
    if (this.state.filter.searchName !== searchName) {
      this.setState({ filter: { ...this.state.filter, searchName } })
    }
  }

  handleEmailChange (searchEmail) {
    if (this.state.filter.searchEmail !== searchEmail) {
      this.setState({ filter: { ...this.state.filter, searchEmail } })
    }
  }

  onOrderChange (e) {
    const newOrderBy = (this.state.orderBy) ? `+${e.currentTarget.dataset.field}` : `-${e.currentTarget.dataset.field}`
    this.props.actions.fetchUserListForProcessingAgency({ ...this.props.pagination, ...this.state.filter, orderBy: newOrderBy, RepairshopId: this.props.repairshop?.repairshopId })
    this.setState({ orderBy: !this.state.orderBy })
  }

  componentDidUpdate () {}

  render () {
    const {
      t
    } = this.props
    const tKey = 'MASTERS.REPAIR_SHOPS_MASTERS.MODAL.'

    const addTooltip = (<Tooltip id="addTooltip">{t(`${tKey}ADD_USER`)}</Tooltip>)
    const hasFilters = (this.state.filter.searchName || this.state.filter.searchEmail)
    return (
      <div>
        <Row>
          <form id="create-course-form">
            <div className="search-form">
              <span className="title-search"><i className="ico-search" /> {t(`${tKey}SEARCH_TITLE`)}</span>
              <Row className='flexRow'>
                <Field
                  id="name"
                  name="filters.searchName"
                  colSm={4}
                  controlLabel={t(`${tKey}COMPLET_NAME`)}
                  placeholder={t(`${tKey}COMPLET_NAME`)}
                  component={InputText}
                  onInputChange={(value) => this.handleNameChange(value)}
                />
                <Field
                  id="email"
                  name="filters.searchEmail"
                  colSm={3}
                  controlLabel={t(`${tKey}EMAIL`)}
                  placeholder={t(`${tKey}EMAIL`)}
                  component={InputText}
                  onInputChange={(value) => this.handleEmailChange(value)}
                />
                <Col sm={2} className="btn-wrapper">
                  <Button
                    className={'btn-white btn-input2 btn-white search-button'}
                    style={{ width: '100%' }}
                    onClick={() => this.searchUsers()}>
                    <i className="ico-search" />
                    {t(`${tKey}SEARCH_BTN`)}
                  </Button>
                </Col>
                <Col sm={3} className="btn-wrapper">
                  <Button
                    style={{ marginTop: '33px' }}
                    className={'btn-icon btn-input ' + (hasFilters ? 'btn-standard' : 'btn-cancel')}
                    onClick={() => {
                      this.props.initialize({})
                      this.resetFilters()
                    }}>
                    <i className="ico-cancel" />
                    {t('MASTERS.RESET_BUTTON')}
                  </Button>
                </Col>
              </Row>
            </div>
          </form>
        </Row>
        <SimpleTablePage
          columns={[
            <th key={0} data-field="userRepairshopId" style={{ display: 'none' }}>
            </th>,
            <th key={1} data-field="completName" onClick={(event) => this.onOrderChange(event)}>
              {t(`${tKey}COMPLET_NAME`)}&nbsp;<i className={this.getIconClass('completName')}></i>
            </th>,
            <th key={2} data-field="emailAddress" onClick={(event) => this.onOrderChange(event)}>
              {t(`${tKey}EMAIL`)}&nbsp;<i className={this.getIconClass('emailAddress')}/>
            </th>,
            <th key={3} data-field="creationDate" onClick={(event) => this.onOrderChange(event)}>
              {t(`${tKey}CREATION_DATE`)}&nbsp;<i className={this.getIconClass('creationDate')}></i>
            </th>,
            <th key={4} data-field="actions" >
              {t(`${tKey}ACTIONS`)}&nbsp;
            </th>
          ]}
          rows={this.props.repairshop?.userList && this.props.repairshop?.userList?.map((row, idx) => (
            <tr key={idx}>
              <td>{row.completName}</td>
              <td>{row.emailAddress}</td>
              <td>{formatDate(row.creationDate)}</td>
              <td className='icon-center'>{<a onClick={() => this.addRepairshop(row.userId, this.props.repairshop.repairshopId)}>
                <OverlayTrigger placement="left" overlay={addTooltip}>
                  <i className="ico-plus"/>
                </OverlayTrigger>
              </a>}</td>
            </tr>
          ))}
        ></SimpleTablePage>
        <div className="search-footer" key="search-footer">
          <IDocCarPagination
            id="btn_pag_sales"
            page={this.props.repairshop?.pagination.page}
            pagesCount={this.props.repairshop?.pagination.pagesCount}
            totalRows= {this.props.repairshop?.usersCount}
            onSelectAction={(page) => this.onPageChange(page)} />
        </div>
      </div>
    )
  }
}

export default reduxForm({
  form: 'AddUsers'
})(AddUsers)
