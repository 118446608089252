import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
// import { goBack } from 'react-router-redux'
import { refreshVehicleComponent } from '../../../actions/dossier/common/vehicle'
import { fetchClientFromDocument } from '../../../actions/dossier/common/client'
import { updateDossierCommon } from '../../../actions/dossier/commonDossierActions'
import { openDocumentEditionModal } from '../../../actions/dossier/common/documentHistoricalEditionModal'
import statusHeaderActions from '../../../actions/dossier/common/statusHeader'
import StatusHeader from '../../../components/dossierHistorical/common/statusHeader/StatusHeader'

export function mapStateToProps (state) {
  return {
    isFolded: state.statusHeader.isFolded,
    windowWidth: state.windowDimensions.width,
    favorite: state.statusHeader.favorite
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchClientFromDocument,
      refreshVehicleComponent,
      updateDossierCommon,
      openDocumentEditionModal,
      ...statusHeaderActions/* ,
      goBack */
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(StatusHeader))
