import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import DataFinancingFleetModal from '../../../components/management/AuxModals/DataFinancingFleetModal'
import { closeDataFinancingFleetModal } from '../../../actions/management/auxModals/auxModals'
import { fetchFinancingType } from '../../../actions/combos/combos'
import { fetchSalesFinancing, removeFinance, cleanFinance } from '../../../actions/dossier/sales'
import { getFinancingTypeProductByFinancingType } from '../../../actions/masters/masters'
import { openModal as openCommonModal } from '../../../actions/common'
import { setActiveSection } from '../../../actions/dossier/common/buttonsSidebar'
import { fetchOperateDataFleetModalsPermissions } from '../../../actions/management/management'
import { setOperateDataFinancingFleetProcess } from '../../../actions/unattendedProcess/unattendedProcess'
import { buttonsSidebar } from '../../../constants/dossier/common/buttonsSidebar'

export function mapStateToProps (state) {
  return {
    ...state.managementAuxModals.dataFinancingFleetModal,
    financingTypeProduct: state.financingTypeProduct.financingTypeProductComponent,
    componentConfiguration: state.management.operateFleetModal.componentsModalConfiguration ? state.management.operateFleetModal.componentsModalConfiguration.componentsConfiguration.find(x => x.code === buttonsSidebar.finance) : null,
    combos: {
      ...state.combos
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      closeDataFinancingFleetModal,
      fetchSalesFinancing,
      removeFinance,
      cleanFinance,
      fetchFinancingType,
      getFinancingTypeProductByFinancingType,
      openCommonModal,
      setActiveSection,
      fetchOperateDataFleetModalsPermissions,
      setOperateDataFinancingFleetProcess
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(DataFinancingFleetModal))
