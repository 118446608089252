import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import OperateFleetModal from '../../components/management/OperateFleetModal'
import { translate } from 'react-polyglot'
import { changeEntityDossierFromFleet, resetFiltersFleetInfo, fetchFleetsInfo, fetchFleetsEntity, closeOperateFleetModal, getFleetOperations, initializeOperationActionsCombo, fetchOperateDataFleetModalsPermissions, openDocumentFleetModal, fetchGetCanCreateOperateServices, showFrontEconomicPlan, openEconomicFleetModal } from '../../actions/management/management'
import { openDataSalesFleetModal, fetchSalesTypeCombo, openDataContactFleetModal, openDataPaperWorkFleetModal, openDataFinancingFleetModal, closeDataFinancingFleetModal, openDataDeliveryFleetModal } from '../../actions/management/auxModals/auxModals'
import { openModal } from '../../actions/common'
import { fetchFinancingCompaniesBy, fetchReasons, fetchCancelCombo, fetchDossierSubTypes, fetchEntitySubTypeCombo, fetchEntityFilterSubTypeCombo } from '../../actions/combos/combos'
import { openDocumentEditionModalFleet, openDocumentEditionModal } from '../../actions/dossier/common/documentEditionModal'
import {
  setOperateActionSendToApprovalProcess, setOperateActionApproveProcess, setOperateActionRejectProcess, setOperateActionApprovePaperworkProcess, setOperateActionSendToAgencyProcess, setOperateActionDeleteProcess, setOperateActionStoreProcess, setOperateDataServiceFleetProcess,
  setOperateActionPrintDeliveryNoteProcess, setApproveActionProcess
} from '../../actions/unattendedProcess/unattendedProcess'

import { clearEntity, clearEntityChild, fetchDefaultEntity } from '../../actions/dossier/common/client'

import { fetchNotValidDatesService } from '../../actions/dossier/sales'

export function mapStateToProps (state) {
  return {
    ...state.management.operateFleetModal,
    fleetFinderFilter: state.management.searchFleetInfo.dynamicFilters,
    dniCif: state.management.dniCif,
    dossierFleetDetails: state.management.dossierFleetDetails,
    combos: state.combos,
    cancelCombo: state.combos.cancelCombo,
    dossierServiceRelateds: state.salesDossier.dossierServiceRelateds
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      openModal,
      changeEntityDossierFromFleet,
      resetFiltersFleetInfo,
      fetchFleetsInfo,
      fetchFleetsEntity,
      clearEntity,
      clearEntityChild,
      closeOperateFleetModal,
      getFleetOperations,
      initializeOperationActionsCombo,
      openDataSalesFleetModal,
      fetchOperateDataFleetModalsPermissions,
      fetchSalesTypeCombo,
      openDataContactFleetModal,
      openDataPaperWorkFleetModal,
      openDataFinancingFleetModal,
      closeDataFinancingFleetModal,
      fetchFinancingCompaniesBy,
      openDocumentEditionModal,
      openDocumentEditionModalFleet,
      setOperateActionSendToApprovalProcess,
      setOperateActionApproveProcess,
      fetchReasons,
      setOperateActionRejectProcess,
      setOperateActionApprovePaperworkProcess,
      setOperateActionSendToAgencyProcess,
      setOperateActionDeleteProcess,
      fetchCancelCombo,
      openDocumentFleetModal,
      setOperateActionStoreProcess,
      openDataDeliveryFleetModal,
      fetchGetCanCreateOperateServices,
      fetchDefaultEntity,
      fetchDossierSubTypes,
      fetchEntitySubTypeCombo,
      fetchEntityFilterSubTypeCombo,
      fetchNotValidDatesService,
      setOperateDataServiceFleetProcess,
      setOperateActionPrintDeliveryNoteProcess,
      showFrontEconomicPlan,
      openEconomicFleetModal,
      setApproveActionProcess
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(OperateFleetModal))
