import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Admin from '../../components/admin/Admin'
import { translate } from 'react-polyglot'
import { fetchRoleList } from '../../actions/admin/roleAdmin'
import { fetchUserList } from '../../actions/admin/userAdmin'
import { fetchRecurrentEntityList, setChangeTabFromMaster } from '../../actions/admin/recurrentEntityAdmin'
import TrackingContainer from '../../containers/tracking/trackingContainer'
export function mapStateToProps (state) {
  return {
    ...state.auth,
    ...state.recurrentEntityAdmin
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchRoleList,
      fetchUserList,
      fetchRecurrentEntityList,
      setChangeTabFromMaster
    }, dispatch)
  }
}

var decoratedComponent = TrackingContainer(Admin, true)
export default connect(mapStateToProps, mapDispatchToProps)(translate()(decoratedComponent))
