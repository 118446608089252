import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import DataSalesFleetModal from '../../../components/management/AuxModals/DataSalesFleetModal'
import { closeDataSalesFleetModal, fetchSalesmanCombos, fetchSalesTypeCombo } from '../../../actions/management/auxModals/auxModals'
import { fetchOperationType, fetchFleetOperationTypeForAutoselect, fetchAdditionalInfoCombo, fetchVehicleType } from '../../../actions/combos/combos'
import { openDocumentEditionModal } from '../../../actions/dossier/common/documentEditionModal'
import dossierSalesActions from '../../../actions/dossier/sales'
import { fetchPaymentCombo, fetchOperateDataFleetModalsPermissions } from '../../../actions/management/management'
import { setOperateDataSaleFleetProcess } from '../../../actions/unattendedProcess/unattendedProcess'
import { openModal } from '../../../actions/common'
import { saleCodes } from '../../../constants/dossier/common/fieldCodePermissions'
export function mapStateToProps (state) {
  return {
    ...state.managementAuxModals.dataSalesFleetModal,
    componentConfiguration: state.management.operateFleetModal.componentsModalConfiguration ? state.management.operateFleetModal.componentsModalConfiguration.componentsConfiguration.find(x => x.code === saleCodes.bsale) : null,
    combos: {
      ...state.combos,
      paymentMethodCombo: state.management.paymentCombo

    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      closeDataSalesFleetModal,
      fetchOperationType,
      fetchFleetOperationTypeForAutoselect,
      openDocumentEditionModal,
      fetchSalesmanCombos,
      fetchAdditionalInfoCombo,
      fetchPaymentCombo,
      fetchSalesTypeCombo,
      fetchOperateDataFleetModalsPermissions,
      setOperateDataSaleFleetProcess,
      fetchVehicleType,
      openModal,
      ...dossierSalesActions
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(DataSalesFleetModal))
