import React, { PureComponent } from 'react'
import { reduxForm } from 'redux-form'
import { Row, Col, Table, Button, Tooltip, OverlayTrigger } from 'react-bootstrap'
import IDocCarPagination from '../../commons/iDocCarPagination'
import DocumentVisualizationMasterModalPage from '../../../containers/masters/DocumentVisualization/DocumentVisualizationMasterModalPage'
class DocumentVisualizationMaster extends PureComponent {
  constructor () {
    super()
    this.state = {
      filter: {
        page: 1,
        orderBy: ''
      }
    }
  }

  getSortIcon (column = '') {
    switch (this.props.filter.orderBy) {
      case '+' + column:
        return 'ico-sort-selected-asc'
      case '-' + column:
        return 'ico-sort-selected-desc'
      default:
        return 'ico-sort'
    }
  }

  changeOrder (field) {
    switch (this.props.filter.orderBy) {
      case `+${field}`:
        this.props.actions.fetchDocumentVisualizations({ ...this.state.filter, ...this.props.filter, orderBy: `-${field}` })
        break
      default:
        this.props.actions.fetchDocumentVisualizations({ ...this.state.filter, ...this.props.filter, orderBy: `+${field}` })
        break
    }
  }

  searchDocumentVisualizationList () {
    this.props.actions.fetchDocumentVisualizations({ ...this.props.filter, ...this.state.filter })
  }

  render () {
    const {
      t, documentVisualizations, pagesCount, documentVisualizationsCount, filter,
      filter: { page },
      actions: { deleteDocumentVisualization, openDocumentVisualizationModal, fetchDocumentVisualizations },
      combos: { dossierSubtypesCombo, documentTypeUseCombo }
    } = this.props

    const tKey = 'MASTERS.DOCUMENT_VISUALIZATION.'
    const editTooltip = (<Tooltip id="editTooltip">{t('MASTERS.EDIT')}</Tooltip>)
    const deleteTooltip = (<Tooltip id="deleteTooltip">{t('MASTERS.DELETE')}</Tooltip>)

    return (
      <div className="admin-wrapper">
        <DocumentVisualizationMasterModalPage/>
        <Row className="section-users-header">
          <Col sm={12}>
            <div className="header-panel">
              <h4>{t(`${tKey}DOCUMENT_VISUALIZATION`)}</h4>
            </div>
          </Col>
        </Row>
        <Col sm={12} className="col-row  section-users-body">
          <Table className="editable-table" hover responsive>
            <thead>
              <tr>
                <th onClick={() => { this.changeOrder('originDossierSubTypeId') }}>
                  {t(`${tKey}ORIGIN_SUBTYPE`)}
                  <i className={this.getSortIcon('originDossierSubTypeId')} />
                </th>
                <th onClick={() => { this.changeOrder('destDossierSubTypeId') }}>
                  {t(`${tKey}DESTINY_SUBTYPE`)}
                  <i className={this.getSortIcon('destDossierSubTypeId')} />
                </th>
                <th onClick={() => { this.changeOrder('documentTypeUseId') }}>
                  {t(`${tKey}TYPE_USE`)}
                  <i className={this.getSortIcon('documentTypeUseId')} />
                </th>
                <th style={{ width: '77px' }}>{t('DOSSIER_COMPONENTS.ACCESSORIES.ACTIONS')}</th>
              </tr>
            </thead>
            <tbody>
              {documentVisualizations && documentVisualizations.map((documentVisualization, idx) => (
                <tr key={idx} className={documentVisualization.isDeleted ? 'is-deleted' : ''}>
                  <td>{dossierSubtypesCombo && dossierSubtypesCombo.find(x => x.id === documentVisualization.originDossierSubTypeId)?.value }</td>
                  <td>{dossierSubtypesCombo && dossierSubtypesCombo.find(x => x.id === documentVisualization.destDossierSubTypeId)?.value }</td>
                  <td>{documentTypeUseCombo && documentTypeUseCombo.find(x => x.id === documentVisualization.documentTypeUseId)?.value }</td>
                  <td>
                    {
                      <div>
                        <a onClick={() => openDocumentVisualizationModal(documentVisualization)}>
                          <OverlayTrigger placement="left" overlay={editTooltip}>
                            <i className="ico-edit-white" />
                          </OverlayTrigger>
                        </a>
                        <a onClick={() => deleteDocumentVisualization(documentVisualization.documentVisualizationId)}>
                          <OverlayTrigger placement="left" overlay={deleteTooltip}>
                            <i className="ico-trash" />
                          </OverlayTrigger>
                        </a>
                      </div>
                    }
                  </td>
                </tr>
              ))}
              {(documentVisualizations === null || documentVisualizations === undefined || documentVisualizations.length === 0) &&
            <tr>
              <td colSpan={5 + (documentVisualizations ? documentVisualizations.length : 0) }><h2>{t(`${tKey}RESULTS_NOT_FOUND`)}</h2></td>
            </tr>}
              <tr className="button-table-wrapper">
                <td>
                  <Button
                    className="btn-editable-table btn-standard"
                    onClick={() => openDocumentVisualizationModal(null)}>
                  + {t('MASTERS.ADD')}
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>
          <div className="section-users-footer">
            <nav key={1} className="text-center">
              <IDocCarPagination
                id="btn_pag_finance"
                page={page}
                pagesCount={pagesCount}
                totalRows={documentVisualizationsCount}
                onSelectAction={(value) => fetchDocumentVisualizations({ ...filter, ...this.state.filter, page: value })}
              />
            </nav>
          </div>
        </Col>
      </div>
    )
  }
}

export default reduxForm({
  form: 'documentVisualizationMaster'
})(DocumentVisualizationMaster)
