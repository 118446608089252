import { put, call, select, takeEvery } from 'redux-saga/effects'
import { getAuth } from '../../selectors/access/auth'
import appDownloadActionTypes from '../../constants/actions/idocmobile/appDownload'
import { handleError } from '../errors/errorManager'
import getAppDownload from '../../services/idocmobile/getAppDownload'
import { showLoader, hideLoader } from '../../actions/common.js'
import { openSuccessMessageModal } from '../../actions/modals/messageModal'

export function * sendMailAppDownload ({ platform }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const result = yield call(getAppDownload, auth.token, platform)
    if (result) {
      yield put(openSuccessMessageModal('IDOCMOBILE.SEND_MAIL_APP_DOWNLOAD_SUCCESS'))
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchsendMailAppDownload () {
  yield takeEvery(appDownloadActionTypes.FETCH_SEND_MAIL_DOWNLOAD, sendMailAppDownload)
}
