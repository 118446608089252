import React, { PureComponent } from 'react'
import { Tooltip } from 'react-bootstrap'
import { reduxForm } from 'redux-form'
import SearchByVehicleFormPage from '../../containers/search/SearchByVehicleFormPage'
import SearchByEntityFormPage from '../../containers/search/SearchByEntityFormPage'
import SearchByDossierFormPage from '../../containers/search/SearchByDossierFormPage'
import SearchByDossierSimpleFormPage from '../../containers/search/SearchByDossierSimpleFormPage'
import SearchByVehicleSimpleFormPage from '../../containers/search/SearchByVehicleSimpleFormPage'
import SearchByProcessingAgencyFormPage from '../../containers/search/SearchByProcessingAgencyFormPage'
import SearchStockManagementPage from '../../containers/search/SearchStockManagementPage'
import DocumentalSearchFormPage from '../../containers/search/DocumentalSearchFormPage'
import DocumentalSearchRestrictedFormPage from '../../containers/search/DocumentalSearchRestrictedFormPage'
import DocumentalSearchMassiveDownloadFormPage from '../../containers/search/DocumentalSearchMassiveDownloadFormPage'
import { validateDates } from '../../../util/validationFunctions'
import { permissions, backEndIdDocumentStatus } from '../../../constants/backendIds'
import { getSectionPermissions } from '../../../util/permisionFunctions'
import { searchType } from '../../../constants/search/searchConstants'
import settings from '../../../setting'
import { formNames } from '../../../constants/search/formNames'
import SimpleInputText from '../../../components/commons/simpleForm/SimpleInputText'
import MapSections from '../../../_v2/util/map-sections_v2'
import MapSectionsSearch from '../../../_v2/util/map-sections-search'
import { sectionPermissions } from '../../../constants/dossier/common/buttonsHeader'

class DossierFinder extends PureComponent {
  constructor () {
    super()
    this.state = {
      secMapSearch: MapSectionsSearch,
      disableResetButton: true,
      sectionTabsConfiguration: {
        dossier: null,
        vehicle: null,
        vehicleSimple: null
      },
      dossierIdToArray: [],
      stringDossierid: '',
      fields: {},
      selectDossierSubType: true,
      organizedUnitId: null,
      selectedTab: null
    }
    this.handleClickReset = this.handleClickReset.bind(this)
  }

  componentDidMount () {
    const disableResetButton = this.isCurrentTabFormEmpty(this.props.selectedTab, this.props.dynamicFilters)
    this.setState({ disableResetButton: disableResetButton })

    if (this.props.section === undefined || this.props.section.code !== 'search') {
      let codeSection = this.props.sectionsGeneral.find(section => section.code === 'search')
      this.props.actions.setCurrentSection(codeSection)
    }

    this.props.actions.initializeSearch()
    this.disableFields()
    this.initializePermisions(this.props)

    this.props.actions.closeReportCommercialTableModal()
  }

  getSnapshotBeforeUpdate (prevProps, prevState) {
    if ((prevProps.section === undefined || prevProps.section.code !== 'search') && this.props.section !== undefined) {
      this.initializePermisions(this.props)
    }

    let disableResetButton = this.props.pristine
    if (!this.props.pristine) {
      disableResetButton = this.isCurrentTabFormEmpty(this.props.selectedTab, this.props.formValues)
    }
    if (prevState.disableResetButton !== disableResetButton) {
      this.setState({ disableResetButton: disableResetButton })
    }

    return null
  }

  componentDidUpdate () {
    // getSnapshotBeforeUpdate() should be used with componentDidUpdate()
  }

  toogleInnerMenu () {
    this.setState({ hideMenu: !this.state.hideMenu })
  }

  handleClickReset (e) {
    if (!this.state.disableResetButton) {
      this.onClickResetFilters(e)
    }
    this.props.actions.fetchSaleTypeBySubtype(null, true)
    this.props.actions.fetchPurchaseType(null)
    this.props.actions.fetchOperationType(null, null, null, false)
    this.props.actions.fetchPaymentMethodCombo(null)
  }

  isCurrentTabFormEmpty (selectedTab, formValues) {
    let selectedTabValues
    if (formValues && selectedTab) {
      const config = this.state.config.find((d) => { return d.id === selectedTab })
      if (config) selectedTabValues = formValues[config.formValuesName]
    }

    let isEmpty = true
    for (const prop in selectedTabValues) {
      if (selectedTabValues[prop] != null && !(Array.isArray(selectedTabValues[prop]) && selectedTabValues[prop].length === 0)) {
        if (!(prop === 'repairWorkshops' && this.props.combos.repairWorkshopsCombo?.isWorkshopUser)) {
          isEmpty = false
          break
        }
      }
    }
    return isEmpty
  }

  onClickResetSearch (e) {
    if (e !== undefined) {
      e.preventDefault()
    }

    this.state.dossierIdToArray.map((dossierId, idx) => {
      this.props.change('selectedCheck_' + dossierId, false)
    })
    this.props.actions.resetFilters()
    this.setState({ dossierIdToArray: [] })
  }

  onClickResetFilters (e) {
    if (e) {
      e.preventDefault()
    }
    this.props.initialize({})
    this.props.actions.resetFilters()
    this.setState({ fields: {}, dossierIdToArray: [] })
    this.disableFields()
  }

  fieldFill (field) {
    this.props.actions.setFields({ ...this.props.fields, ...field })
  }

  initializePermisions (props) {
    if (!this.props.secMapSearch) {
      const section = props.sectionsGeneral.filter((tabConf) => tabConf.code === sectionPermissions.search)

      if (section.length > 0 && section[0] != null) {
        const newState = getSectionPermissions(section[0])
        this.setState({ sectionTabsConfiguration: newState })
        let visibleSectionConfiguration = []
        this.state.secMapSearch.forEach(function (item) {
          var permissionCode = newState[item.code]
          if (permissionCode !== undefined) {
            if (permissionCode.permission !== null && permissionCode.permission !== permissions.hidden) {
            // Add to visible sections
              item.active = false
              visibleSectionConfiguration.push(item)
            }
          }
        })
        visibleSectionConfiguration[0].active = true
        this.setState({ secMapSearch: visibleSectionConfiguration })
      }
    }
  }

  disableFields () {
    if (!this.props.selectedTab === searchType.STOCK_MANAGEMENT) {
      this.props.actions.fetchStagesCombo(null)
    }
    this.props.actions.fetchSaleTypeBySubtype(null, true)
    this.props.actions.fetchPurchaseType(null)
    this.props.actions.fetchOperationType(null, null, null)
  }

  onClickTaskListRow (documentId, documentTypeId, documentParentTypeId, objectId, organizedUnitId) {
    const callback = () => {
      if (this.props.filters) {
        this.fetchTable(this.props.filters)
      }
    }

    this.props.actions.openDocumentEditionModal(documentId, documentTypeId, documentParentTypeId, objectId, objectId, objectId,
      organizedUnitId, null, callback, true, '', '')
  }

  selectDossierSubType (selectedTab) {
    if (selectedTab && selectedTab === searchType.DOSSIER_SIMPLE && this.props.combos && this.props.combos.searchDossierSubtypesCombo &&
        this.props.combos.searchDossierSubtypesCombo.length > 0) {
      this.props.change('dossier.dossierSubtype', this.props.combos.searchDossierSubtypesCombo[0].id)
    }
  }

  selectDossierSubTypeFirstTime () {
    if (this.state && this.state.selectDossierSubType) {
      this.setState({ selectDossierSubType: false })
      this.selectDossierSubType(this.props.selectedTab)
    }
  }

  selectChargeStateFirstTime () {
    if (this.state && (!settings.dontFilterCharges)) {
      this.props.change('charge.documentStatus', backEndIdDocumentStatus.INCORPORATED)
    }
  }

  uploadDocuments () {
    if (this.state.dossierIdToArray && this.state.dossierIdToArray.length > 0) {
      this.props.actions.openDocumentCreationModalFleets(this.state.dossierIdToArray, this.state.organizedUnitId)
    } else {
      this.props.actions.openModal('anyDossierMustBeSelected')
    }
  }

  filterSections (t) {
    let nameFilter = this.state.sectionFilter
    let secMapSearch = this.props.secMapSearch ?? this.state.secMapSearch

    if (!this.state.sectionFilter) {
      return secMapSearch
    }
    let filtered = secMapSearch.filter(function (item) {
      return t(item.title).toUpperCase().includes(nameFilter.toUpperCase())
    })
    return filtered
  }

  selectSection (item) {
    let visibleSectionConfiguration = []
    this.state.secMapSearch.forEach(element => {
      element.active = element.code === item.code
      visibleSectionConfiguration.push(element)
    })
    this.setState({ secMapSearch: visibleSectionConfiguration })
    this.props.actions.searchSectionChange(visibleSectionConfiguration)
    this.props.actions.resetFilters()
    this.props.actions.resetDocumentSearchFilters()
  }

  getDateTypeOptions () {
    const { t } = this.props
    const tKey = 'SEARCH.CATEGORIES.STOCKMANAGEMENT.DATE_TYPE_OPTIONS.'
    return [
      { id: '1', value: t(`${tKey}CREATION_DATE`) },
      { id: '2', value: t(`${tKey}RECEPTION_DATE`) },
      { id: '3', value: t(`${tKey}RATING_DATE`) },
      { id: '4', value: t(`${tKey}BUDGET_SENT_DATE`) },
      { id: '5', value: t(`${tKey}BUDGET_ACCEPTED_DATE`) },
      { id: '6', value: t(`${tKey}RECONDITIONING_CONFIRMATION_DATE`) }
    ]
  }

  getMassiveDownloadEmailPermission (section) {
    const tabSection = section?.sectionTabsConfiguration.filter(x => x.code === 'massiveDownloads')
    if (tabSection && tabSection[0] && tabSection[0].sectionFieldsConfiguration) {
      const fieldSection = tabSection[0].sectionFieldsConfiguration.filter(x => x.code === 'massive_download_email')
      if (fieldSection && fieldSection[0]) {
        return fieldSection[0]
      }
    }
  }

  render () {
    const {
      t, hasSearch, dossierData, vehicleData, entityData, pages, count, filters: { page },
      showCampaignColumnsInExcel, processingAgenciesData, stockManagementData,
      combos: { stagesCombo, repairWorkshopsCombo },
      actions: { fetchStagesCombo, fetchUserRepairWorkshopsCombo }
    } = this.props
    const secMap = MapSections.search
    const tKey = 'SEARCH.'
    const className = 'filled'
    const classNameSelect = 'filledSelect'
    const stateTooltip = (<Tooltip id="tooltip-trigger-hover-focus">{t(`${tKey}CATEGORIES.VEHICLE.STATE_TOOLTIP`)}</Tooltip>)
    const activeTooltip = (<Tooltip id="tooltip-trigger-hover-focus">{t(`${tKey}CATEGORIES.VEHICLE.AVAILABLE`)}</Tooltip>)
    const inActiveTooltip = (<Tooltip id="tooltip-trigger-hover-focus">{t(`${tKey}CATEGORIES.VEHICLE.UNAVAILABLE`)}</Tooltip>)
    const availableSections = this.filterSections(t)
    const selectedSection = availableSections.filter(x => x.active === true)[0]

    return (
      <div className="wrapper _nf">
        <div className='wrapper-header'>

          <div className="title-page">
            <i className={secMap.icon + ' icon-page'} />
            <h2>{t(`${secMap.title}`)}</h2>
          </div>

        </div>
        <div className='wrapper-body'>
          <div className={this.state.hideMenu ? 'inner-menu-content innerMenuHide' : 'inner-menu-content'}>
            <div className='inner-menu-helpers'>
              <SimpleInputText
                componentClass = 'cajadebusqueda'
                placeholder = {t('SECTIONS.FILTER')}
                value={this.state.sectionFilter}
                onInputChange={sectionFilter => this.setState({ sectionFilter })}
              />
            </div>
            <ul className='inner-items'>
              {
                availableSections.map((item, idx) => {
                  return (
                    <li className={ item.active === true ? 'active' : ''}><p onClick={() => this.selectSection(item)}>{t(`${item.title}`)}</p></li>
                  )
                })
              }
            </ul>
          </div>
          <div className='inner-data-content'>
            {selectedSection !== undefined && selectedSection.page === 'dossierSimplePage' &&
          <SearchByDossierSimpleFormPage
            t={t}
            change={this.props.change}
            fields={this.props.fields}
            organizedUnitId={''}
            fieldFill={(val) => this.fieldFill(val)}
            className={className}
            classNameSelect={classNameSelect}
            selectDossierSubTypeFirstTime={() => this.selectDossierSubTypeFirstTime()}
            hasSearch={hasSearch}
            dossierData={dossierData}
            vehicleData={vehicleData}
            page={page}
            pages={pages}
            count={count}
            toogleInnerMenu={() => this.toogleInnerMenu()}
            hideMenu={this.state.hideMenu}
          />
            }
            {selectedSection !== undefined && selectedSection.page === 'dossierPage' &&
             <SearchByDossierFormPage
               t={t}
               change={this.props.change}
               fields={this.props.fields}
               fieldFill={(val) => this.fieldFill(val)}
               className={className}
               classNameSelect={classNameSelect}
               hasSearch={hasSearch}
               dossierData={dossierData}
               vehicleData={vehicleData}
               inActiveTooltip={inActiveTooltip}
               page={page}
               pages={pages}
               count={count}
               permissions = {this.state.sectionTabsConfiguration.dossier && this.state.sectionTabsConfiguration.dossier.sectionFieldsConfiguration ? this.state.sectionTabsConfiguration.dossier : null }
               showCampaignColumnsInExcel={showCampaignColumnsInExcel}
               toogleInnerMenu={() => this.toogleInnerMenu()}
               hideMenu={this.state.hideMenu}
             />
            }
            {selectedSection !== undefined && selectedSection.page === 'vehicleSimplePage' &&
              <SearchByVehicleSimpleFormPage
                t={t}
                fields={this.props.fields}
                fieldFill={(val) => this.fieldFill(val)}
                className={className}
                classNameSelect={classNameSelect}
                hasSearch={hasSearch}
                vehicleData={vehicleData}
                stateTooltip={stateTooltip}
                activeTooltip={activeTooltip}
                inActiveTooltip={inActiveTooltip}
                page={page}
                pages={pages}
                count={count}
                permissions = {this.state.sectionTabsConfiguration.vehicleSimple && this.state.sectionTabsConfiguration.vehicleSimple.sectionFieldsConfiguration ? this.state.sectionTabsConfiguration.vehicleSimple.sectionFieldsConfiguration : null }
                subtypesCombo={this.props.combos.dossierSubtypesCombo}
                toogleInnerMenu={() => this.toogleInnerMenu()}
                hideMenu={this.state.hideMenu}
              />
            }
            {selectedSection !== undefined && selectedSection.page === 'vehiclePage' &&
              <SearchByVehicleFormPage
                t={t}
                change={this.props.change}
                fields={this.props.fields}
                fieldFill={(val) => this.fieldFill(val)}
                className={className}
                classNameSelect={classNameSelect}
                hasSearch={hasSearch}
                vehicleData={vehicleData}
                stateTooltip={stateTooltip}
                activeTooltip={activeTooltip}
                inActiveTooltip={inActiveTooltip}
                page={page}
                pages={pages}
                count={count}
                permissions = {this.state.sectionTabsConfiguration.vehicle && this.state.sectionTabsConfiguration.vehicle.sectionFieldsConfiguration ? this.state.sectionTabsConfiguration.vehicle : null }
                subtypesCombo={this.props.combos.dossierSubtypesCombo}
                toogleInnerMenu={() => this.toogleInnerMenu()}
                hideMenu={this.state.hideMenu}
              />
            }
            {selectedSection !== undefined && selectedSection.page === 'entityPage' &&
              <SearchByEntityFormPage
                t={t}
                change={this.props.change}
                fields={this.props.fields}
                fieldFill={(val) => this.fieldFill(val)}
                className={className}
                classNameSelect={classNameSelect}
                formValues={this.props.formValues && this.props.formValues.entity ? this.props.formValues.entity : {}}
                hasSearch={hasSearch}
                entityData={entityData}
                vehicleData={vehicleData}
                page={page}
                pages={pages}
                count={count}
                subtypesCombo={this.props.combos.dossierSubtypesCombo}
                toogleInnerMenu={() => this.toogleInnerMenu()}
                hideMenu={this.state.hideMenu}
              />
            }
            {selectedSection !== undefined && selectedSection.page === 'processingAgencyPage' &&
              <SearchByProcessingAgencyFormPage
                t={t}
                change={this.props.change}
                fields={this.props.fields}
                fieldFill={(val) => this.fieldFill(val)}
                className={className}
                classNameSelect={classNameSelect}
                formValues={this.props.formValues && this.props.formValues.entity ? this.props.formValues.entity : {}}
                hasSearch={hasSearch}
                processingAgenciesData={processingAgenciesData}
                vehicleData={vehicleData}
                onClickResetSearch={(value) => this.onClickResetSearch(value)}
                page={page}
                pages={pages}
                count={count}
                subtypesCombo={this.props.combos.dossierSubtypesCombo}
                toogleInnerMenu={() => this.toogleInnerMenu()}
                hideMenu={this.state.hideMenu}
              />
            }
            {selectedSection !== undefined && selectedSection.page === 'stockManagementSearchPage' &&
              <SearchStockManagementPage
                t={t}
                fields={this.props.fields}
                fieldFill={(val) => this.fieldFill(val)}
                className={className}
                classNameSelect={classNameSelect}
                hasSearch={hasSearch}
                dossierData={stockManagementData}
                onClickResetSearch={(value) => this.onClickResetSearch(value)}
                page={page}
                pages={pages}
                count={count}
                combos={{ repairWorkshopsCombo, stagesCombo, dateTypeOptions: this.getDateTypeOptions() }}
                change = { this.props.change }
                initialize = {this.props.initialize}
                actions = {{
                  fetchStagesCombo,
                  fetchUserRepairWorkshopsCombo
                }}
                formValues = {this.props.formValues?.stockManagement}
                toogleInnerMenu={() => this.toogleInnerMenu()}
                hideMenu={this.state.hideMenu}
              />
            }

            {selectedSection !== undefined && selectedSection.page === 'documentalSearchPage' &&
              <DocumentalSearchFormPage
                t={t}
                tabPermission={this.props.section.sectionTabsConfiguration.documentFinder}
                fields={this.props.fields}
                change={this.props.change}
                page={page}
                pages={pages}
                count={count}
                fieldFill={(val) => this.fieldFill(val)}
                resetFields={() => this.resetFields()}
                toogleInnerMenu={() => this.toogleInnerMenu()}
                hideMenu={this.state.hideMenu}
                sections= {this.props.section.sectionTabsConfiguration}
                sectionConfigurationMassiveDownloads = {this.state.sectionTabsConfiguration.massiveDownloads}
              />
            }
            {selectedSection !== undefined && selectedSection.page === 'gdprDocumentalSearchPage' &&
              <DocumentalSearchRestrictedFormPage
                t={t}
                tabPermission={this.props.section.sectionTabsConfiguration.documentFinder}
                fields={this.props.fields}
                change={this.props.change}
                fieldFill={(val) => this.fieldFill(val)}
                resetFields={() => this.resetFields()}
                toogleInnerMenu={() => this.toogleInnerMenu()}
                hideMenu={this.state.hideMenu}
                sectionConfigurationMassiveDownloads = {this.state.sectionTabsConfiguration.massiveDownloads}
              />
            }
            {selectedSection !== undefined && selectedSection.page === 'massiveDownloadPage' &&
                                <DocumentalSearchMassiveDownloadFormPage
                                  t={t}
                                  fields={this.props.fields}
                                  change={this.props.change}
                                  fieldFill={(val) => this.fieldFill(val)}
                                  resetFields={() => this.resetFields()}
                                  sectionConfiguration = {this.state.sectionTabsConfiguration.massiveDownloads}
                                  massiveDowloadItems = {this.props.massiveDowloadItems}
                                  toogleInnerMenu={() => this.toogleInnerMenu()}
                                  hideMenu={this.state.hideMenu}
                                />
            }

          </div>
        </div>
      </div>
    )
  }
}

const validate = (values, { selectedTab, t }) => {
  const errors = {}
  if (values) {
    if (values.dossier && values.dossier.chassis && values.dossier.chassis.length < settings.chassisMinCharacter) {
      errors.dossier = {}
      errors.dossier.chassis = t('SEARCH.CATEGORIES.ERRORS.VINCHASIS').replace('##MIN_CHARACTERS##', settings.chassisMinCharacter)
    }
    if (values.vehicle && values.vehicle.chassis && values.vehicle.chassis.length < settings.chassisMinCharacter) {
      errors.vehicle = {}
      errors.vehicle.chassis = t('SEARCH.CATEGORIES.ERRORS.VINCHASIS').replace('##MIN_CHARACTERS##', settings.chassisMinCharacter)
    }

    if (values.dossier) {
      errors.dossier = {}
      if (values.dossier && values.dossier.creationDateFrom && values.dossier.creationDateTo) {
        errors.dossier.creationDateTo = validateDates(t, values.dossier.creationDateFrom, values.dossier.creationDateTo)
      }
      if (values.dossier && values.dossier.orderDateFrom && values.dossier.orderDateTo) {
        errors.dossier.orderDateTo = validateDates(t, values.dossier.orderDateFrom, values.dossier.orderDateTo)
      }
      if (values.dossier && values.dossier.availabilityDateFrom && values.dossier.availabilityDateTo) {
        errors.dossier.availabilityDateTo = validateDates(t, values.dossier.availabilityDateFrom, values.dossier.availabilityDateTo)
      }
      if (values.dossier && values.dossier.vehicleLicensePlateDateFrom && values.dossier.vehicleLicensePlateDateTo) {
        errors.dossier.vehicleLicensePlateDateTo = validateDates(t, values.dossier.vehicleLicensePlateDateFrom, values.dossier.vehicleLicensePlateDateTo)
      }
    }

    if (values.servicePreparationDelivery && values.servicePreparationDelivery.requiredDateFrom && values.servicePreparationDelivery.requiredDateTo) {
      errors.servicePreparationDelivery = {}
      errors.servicePreparationDelivery.requiredDateTo = validateDates(t, values.servicePreparationDelivery.requiredDateFrom, values.servicePreparationDelivery.requiredDateTo)
    }

    const stockManagement = values[`${formNames.STOCK_MANAGEMENT}`]
    if (stockManagement) {
      let errorsOnStockManagement = {}
      if (stockManagement.dateType && !stockManagement.dateFrom) {
        errorsOnStockManagement.dateFrom = t('GLOBAL.FORMS.REQUIRED')
      }
      if (stockManagement.dateFrom && stockManagement.dateTo) {
        errorsOnStockManagement.dateTo = validateDates(t, stockManagement.dateFrom, stockManagement.dateTo)
      }
      if (!stockManagement.repairWorkshops || stockManagement.repairWorkshops.length <= 0) {
        errorsOnStockManagement.repairWorkshops = t('GLOBAL.FORMS.REQUIRED')
      } else if (stockManagement.dossierNumber && stockManagement.dossierNumber.length < 3) {
        errorsOnStockManagement.dossierNumber = t('DOSSIER_COMPONENTS.VALIDATIONS.MIN_3_CHARACTER')
      }
      errors[`${formNames.STOCK_MANAGEMENT}`] = errorsOnStockManagement
    } else if (selectedTab === searchType.STOCK_MANAGEMENT) {
      errors.stockManagement = { repairWorkshops: t('GLOBAL.FORMS.REQUIRED') }
    }
  }

  return errors
}

export default reduxForm({
  form: 'dossierSearch',
  validate
})(DossierFinder)
