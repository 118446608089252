import React, { PureComponent } from 'react'
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Element } from 'react-scroll'
import DossierHeaderPage from '../../../containers/dossiers/commons/dossierHeaderPage'
import ButtonsHeader from '../../../../_v2/components/dossiers/common/ButtonsHeader'
import ButtonsSidebar from '../../dossiers/common/ButtonsSidebar'
import SalesComponent from '../../../../_v2/components/dossiers/sales/SalesComponent'
import { dossierTypes as dossierTypesConstant } from '../../../../constants/dossier/common/dossierTypes'
import { dossierSubTypeId as dossierSubTypeIdConstants } from '../../../../constants/dossier/common/dossierSubType'
import { compareObjects } from '../../../../util/utils'
import { getComponentsPermisions } from '../../../../util/permisionFunctions'
import { buttonsHeader } from '../../../../constants/dossier/common/buttonsHeader'
import { buttonsSidebar } from '../../../../constants/dossier/common/buttonsSidebar'
import { permissions } from '../../../../constants/backendIds'
import Client from '../../dossiers/common/Client'
import Vehicle from '../../dossiers/common/Vehicle'
import DocumentaryManagementPage from '../../../../_v2/containers/dossiers/commons/DocumentaryManagementPage'
import RejectCommentModal from '../../../../components/dossiers/common/RejectCommentModal'
import UAC from '../../dossiers/common/uac/UAC'
import Campaigns from '../../../../_v2/components/dossiers/sales/Campaigns'
import Finance from '../../dossiers/sales/Finance'
import PaperworkTablePage from '../../../../_v2/containers/dossiers/commons/PaperworkComponentPage'
import Charges from '../../../../_v2/components/dossiers/common/Charges'
import EconomicPlan from '../../../../_v2/components/dossiers/sales/EconomicPlan'
import Delivery from '../../../../_v2/components/dossiers/common/Delivery'
import { reduxForm } from 'redux-form'
import AuditInfo from '../../dossiers/common/audit/AuditInfo'
import CommentComponent from '../../dossiers/common/comment/CommentComponent'
import OtherData from '../../../../_v2/components/dossiers/common/OtherData'
import TrackingContainer from '../../../../containers/tracking/trackingContainer'
import { TrackedPages } from '../../../../constants/tracking/TrackedPages'
import Fleet from '../../dossiers/sales/Fleet'
import SelfSupply from './../../dossiers/sales/SelfSupply'
import Brokerage from '../../dossiers/sales/Brokerage'
import Services from '../../dossiers/common/Services'
import ReFinance from '../../../../components/dossierHistorical/sale/ReFinance'
import ReFinanceModalPage from '../../../../containers/dossierHistorical/sale/refinance/ReFinanceModalPage'
import DossierSummary from '../../dossiers/common/DossierSummary'
import RelatedDossiers from '../../dossiers/common/RelatedDossiers'

class SaleHistorical extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      salesOpen: true,
      modalOpen: true,
      isVehicleLoaded: false,
      isDeliveryLoaded: false,
      isFinanceLoaded: false,
      isBrokerageLoaded: false,
      isClientLoaded: false,
      showRejectCommentModal: false,
      accessoriesOpen: false,
      optionalsOpen: false,
      noRecoverableCostsOpen: false,
      discountsOpen: false,
      servicesOpen: false,
      activeTabConfiguration: null,
      headerTabConfiguration: null,
      isOtherDataLoaded: false,
      isEconomicLoaded: false,
      isFleetLoaded: false,
      isSelfSupplyLoaded: false,
      isRefinanceLoaded: false,
      showRefinanceModal: false,
      componentsConfiguration: {
        bsale: null,
        entity: null,
        vehicle: null,
        brokerage: null,
        campaign: null,
        uac: null,
        finance: null,
        paperwork: null,
        charge: null,
        other_data: null,
        delivery: null,
        services: null,
        downloadDocumentsButton: null,
        fleet: null,
        self_supply: null,
        refinance: null
      }
    }
    this.goBack = this.goBack.bind(this)
  }

  goBack () {
    this.props.actions.setActiveTab(buttonsHeader.datos_expediente)
    this.props.history.goBack()
  }

  toggleRejectCommentModal (showRejectCommentModal) {
    this.setState({ showRejectCommentModal })
  }

  onClickRelatedDossierColumn (columnCode) {
    let columnCodeOrder = '+' + columnCode
    if (columnCodeOrder === this.props.relatedDossiersOrderBy) {
      columnCodeOrder = '-' + columnCode
    }
    this.props.actions.fetchFilteredRelatedDossier(this.props.dossier.dossierId, columnCodeOrder, true)
  }

  getRelatedDossierColumnIcon (columnCode) {
    if ('+' + columnCode === this.props.relatedDossiersOrderBy) {
      return 'ico-sort-selected-asc'
    } else if ('-' + columnCode === this.props.relatedDossiersOrderBy) {
      return 'ico-sort-selected-desc'
    } else {
      return 'ico-sort'
    }
  }

  submitSalesDossier (values) {
    this.props.actions.saveSalesDossier(values)
  }

  fetchSalesDossierPromise (dossierId) {
    return new Promise((resolve, reject) => {
      return this.props.actions.fetchSalesDossier(dossierId, resolve, reject, true)
    })
  }

  initializePermisions (props) {
    const componentKeys = Object.keys(this.state.componentsConfiguration)
    const newState = getComponentsPermisions(props.dossier.viewConfiguration.tabsConfiguration, props.activeTab, componentKeys)
    this.setState({ ...this.state, ...newState })
  }

  UNSAFE_componentWillMount () {
    this.props.actions.restartSalesState()
    this.fetchSalesDossierPromise(this.props.match.params.dossierId).then(() => {
      const form = {
        ...this.props.dossier,
        entityComponent: this.props.dossier.entityComponent.entityId ? this.props.dossier.entityComponent : undefined
      }
      this.props.initialize(form)
      this.initializePermisions(this.props)
      this.props.actions.fetchSalesDossierDependants(this.props.match.params.dossierId, true)
      this.props.actions.fetchCustomFields(this.props.dossier.dossierSubTypeId)
      this.props.actions.registerLastAccess(this.props.match.params.dossierId, true)
      this.props.trackVisitPage({
        documentTitle: TrackedPages.SALES_HISTORICAL,
        organizedUnitId: this.props.dossier.organizedUnitId,
        href: window.location.href
      })
    })
    this.props.actions.fetchHomologationClassificationCombo()
    this.props.actions.fetchHomologationProducerCombo()
    this.props.actions.fetchIaeCombo()
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if ((this.props.showManagementModal && !nextProps.showManagementModal) || this.props.match.params.dossierId !== nextProps.match.params.dossierId) {
      if (this.props.match.params.dossierId !== nextProps.match.params.dossierId) {
        this.props.actions.restartSalesState()
        this.props.actions.setScrollTo(buttonsSidebar.bsale)
        this.props.actions.setActiveSection(buttonsSidebar.bsale)
        this.props.actions.setActiveTab(buttonsHeader.datos_expediente)
        this.props.actions.closeAddOrEditDocumentModal()
      }
      this.fetchSalesDossierPromise(nextProps.match.params.dossierId).then(() => {
        const form = { ...this.props.dossier }
        if (!this.props.dossier.entityComponent.entityId) delete form.entityComponent
        this.props.initialize(form)
        this.initializePermisions(this.props)
        this.props.actions.fetchSalesDossierDependants(this.props.match.params.dossierId, true)
      })
    }

    if (JSON.stringify(this.props.dossier.dossierEconomicComponent) !== JSON.stringify(nextProps.dossier.dossierEconomicComponent)) {
      this.props.initialize(nextProps.dossier)
    }

    if (nextProps.dossier.dossierId && !compareObjects(this.props.dossier.viewConfiguration.tabsConfiguration || {}, nextProps.dossier.viewConfiguration.tabsConfiguration, true)) {
      this.initializePermisions(nextProps)
    }
    if (nextProps.dossier.dossierId && nextProps.activeTab !== this.props.activeTab) {
      this.initializePermisions(nextProps)
    }
  }

  componentDidUpdate () {
    if (this.props.dossier.dossierId && this.props.statusHeaderHeight && this.props.activeSection) {
      switch (this.props.activeSection) {
        case buttonsSidebar.bsale:
          this.setState({ salesOpen: true })
          break
        case buttonsSidebar.accessory:
          this.setState({ accessoriesOpen: true })
          break
        case buttonsSidebar.optional:
          this.setState({ optionalsOpen: true })
          break
        case buttonsSidebar.no_recover:
          this.setState({ noRecoverableCostsOpen: true })
          break
        case buttonsSidebar.discount:
          this.setState({ discountsOpen: true })
          break
        case buttonsSidebar.service:
          this.setState({ servicesOpen: true })
          break
      }
    }

    let customFields = {}
    const firstCustomFieldsLoad = this.props.dossier.customFieldsByComponent?.length > 0
    if (firstCustomFieldsLoad) {
      if (this.props.dossier.customFieldsValues) {
        this.props.dossier.customFieldsValues.map(x => {
          customFields[x.key] = x.value
        })
      }
      const form = { ...this.props.dossier, customFields: customFields }
      if (!this.props.dossier.entityComponent.entityId) delete form.entityComponent
      if (!this.props.dossier.vehicleComponent.vehicleId) delete form.vehicleComponent
      this.props.initialize(form)
    }
  }

  componentDidMount () {
    this.props.actions.fetchCommercialSocietyTypeCombo()
  }

  componentWillUnmount () {
    this.props.actions.setActiveSection(buttonsSidebar.bsale)
    this.props.actions.setScrollTo(null)
    this.props.actions.resetButtonsHeader()
    this.props.actions.closeAddOrEditDocumentModal()
    this.props.actions.restartSalesState()
  }

  handleToUpdateComponent (component) {
    if (component.isVehicleLoaded === false || buttonsSidebar.vehicle === component) this.setState({ isVehicleLoaded: true })
    if (component.isClientLoaded === false || buttonsSidebar.entity === component) this.setState({ isClientLoaded: true })
    if (component.isFinanceLoaded === false || buttonsSidebar.finance === component) this.setState({ isFinanceLoaded: true })
    if (component.isBrokerageLoaded === false || buttonsSidebar.brokerage === component) this.setState({ isBrokerageLoaded: true })
    if (component.isDeliveryLoaded === false || buttonsSidebar.delivery === component) this.setState({ isDeliveryLoaded: true })
    if (component.isOtherDataLoaded === false || buttonsSidebar.other_data === component) this.setState({ isOtherDataLoaded: true })
    if (component.isFleetLoaded === false || buttonsSidebar.fleet === component) this.setState({ isFleetLoaded: true })
    if (component.isEconomicLoaded === false) this.setState({ isEconomicLoaded: true })
    if (component.isRefinanceLoaded === false || buttonsSidebar.refinance === component) this.setState({ isReFinanceLoaded: true })
    this.props.actions.setScrollTo(component)
  }

  togglePanel () {
    if (this.state.salesOpen) {
      this.props.actions.setActiveSection(false)
    }
    this.setState({ salesOpen: !this.state.salesOpen })
  }

  toogleHidedossierBodyLeft () {
    this.setState({ dossierBodyLeftClose: !this.state.dossierBodyLeftClose })
  }

  openRefinanceModal () {
    this.props.actions.openCommonModal('openRefinanceModal', () => {
      this.setState({ showRefinanceModal: true })
    }, null, null, null, null, null)
  }

  closeRefinanceModal () {
    this.setState({ showRefinanceModal: false })
  }

  render () {
    const {
      t, handleSubmit, dossier, combos = {}, change, pristine, vehicleProps, activeTab, buttonsHeaderHeight, activeSection,
      statusHeaderHeight, windowWidth, documentExport,
      dossier: {
        dossierId, dossierSubTypeId, header, entityChangeable, dossierServiceRelateds,
        checkListHeader, comments, campaigns, uac, paperWorks, charges, organizedUnitId, purchasesDossierId,
        dossierEconomicComponent, dossierCompleteDeliveryCheckListItems, dossierDeliveryCheckListPurchase, showAlertSendPending,
        viewConfiguration, viewConfiguration: { buttonsPermission = [], saleConfiguration },
        vehicleComponent: { vehicleId }, dossierRelatedDossiers, auditInfo, order,
        salesComponent: { chargebackAmount },
        externalPaperwork, dossierReFinancingComponent
      },
      actions: {
        openDocumentModal,
        updateDependantsSaleDossierAndHeader,
        fetchOperationType,
        fetchPresentTypesSuccess,
        postPaymentSignalStatusSuccess,
        openDocumentEditionModal,
        fetchOrder,
        fetchSalesDossierDependants,
        fetchSalesDossierDependantsEconomic,
        setActiveTab, setButtonsHeaderHeight, resetButtonsHeader,
        setActiveSection,
        setScrollerOptions,
        /* navigateTo, */
        fetchComboOrigin,
        fetchComboDestination,
        refreshAuditInfo,
        fetchAuditDetails,
        fetchAuditDetailsModal,
        fetchDocumentsGroupedAuditDetails,
        fetchDocumentsGroupedAuditDetailsModal,
        fetchSalesDeliveryHistorical,
        fetchSalesFinancingHistorical,
        fetchSalesVehicleHistorical,
        fetchSalesClientHistorical,
        fetchSalesOtherDataHistorical,
        fetchSalesEconomic,
        fecthFamily,
        saveSelectedDocuments,
        fetchSalesServices, fetchDossierSubTypes, fetchEntitySubTypeCombo, fetchEntityFilterSubTypeCombo,
        fetchFleet, saveSalesService, fetchDefaultEntity, fetchNotValidDatesService, openOrCloseServiceAddModal,
        fetchSalesSelfSupplyHistorical,
        fetchFinancingType,
        fetchSalesInsuranceCarrierHistorical,
        updateComment,
        createComment,
        deleteComment,
        markCommentAsRead, fetchEntityCountryTypeCombo, fetchEntityStateTypeCombo,
        fetchEntityCityTypeCombo, fetchEntityTownTypeCombo,
        fetchEntityColonyTypeCombo, fetchEntityCPTypeCombo, openCommonModal,
        fetchCommercialSocietyTypeCombo,
        fetchActivityBpro,
        printCompleteSummarySales,
        fetchClientDataAddress,
        fetchFilteredRelatedDossier
      }
    } = this.props

    if (!dossierId) return null
    const saveSalePermission = buttonsPermission.includes('sale_save')
    const tKey = 'DOSSIER_COMPONENTS.'
    const panelsPaddingTop = statusHeaderHeight + buttonsHeaderHeight - 82 // 82px es el paddingTop definido en el layout.
    const isRefinance = (this.props.dossier?.dossierFinancingComponent?.reactivatable ?? false)
    return (
      <div class="dossierGrid">
        <div class="dossierGridPart-header">
          <DossierHeaderPage
            headerData={header}
            stages={checkListHeader}
            dossierType={dossierTypesConstant.sales}
            headerTabConfiguration={this.state.headerTabConfiguration}
            goBack={this.goBack}
            dossierId={dossier.dossierId}
            isHistorical={true}
            entityComponent = {dossier.entityComponent}
            vehicleComponent = {dossier.vehicleComponent}
          />
        </div>
        <div class="dossierGridPart-body">

          <div className={'dossierBody-left ' + (this.state.dossierBodyLeftClose ? 'close' : '') }>

            <DossierSummary
              t = {t}
              dossier={dossier}
              header={header}
              dossierType={dossierTypesConstant.sales}
              dossierSubType={dossierSubTypeId}
              brandCombo = {combos.brandCombo}
              entityTypeCombo = {combos.entityTypeCombo}
              dossierRelatedDossiers = {dossierRelatedDossiers}
            >
            </DossierSummary>
          </div>
          <div className='dossierBody-center'>
            <OverlayTrigger placement="right" overlay={<Tooltip id="editProductTooltip">{`${t((this.state.dossierBodyLeftClose ? 'GLOBAL.TABLES.SHOW_INNER_MENU' : 'GLOBAL.TABLES.HIDE_INNER_MENU'))}`}</Tooltip>}>
              <a onClick={() => this.toogleHidedossierBodyLeft()} className={'closeDossierInfo ' + (this.state.dossierBodyLeftClose ? '' : 'closeInnerMenu')}><i className={this.state.dossierBodyLeftClose ? 'iDocIcon-Open-inner-menu' : 'iDocIcon-Close-inner-menu'}></i></a>
            </OverlayTrigger>
          </div>
          <div className='dossierBody-right'>
            <div class="dossierGridPart-sectionsMenu">
              <ButtonsHeader
                t={t}
                pristine={pristine}
                actions={{
                  openDocumentModal: () => openDocumentModal(dossierId, dossierTypesConstant.sales, pristine, 1, true),
                  setActiveTab,
                  setButtonsHeaderHeight,
                  resetButtonsHeader,
                  setScrollerOptions,
                  fetchSalesOtherDataHistorical,
                  fetchSalesDossierDependantsEconomic,
                  fetchSalesInsuranceCarrierHistorical,
                  printCompleteSummarySales
                }}
                dossierId={this.props.match.params.dossierId}
                viewConfiguration={viewConfiguration}
                dossierType={dossierTypesConstant.sales}
                activeTab={activeTab}
                marginTop={statusHeaderHeight}
                windowWidth={windowWidth}
                showEconomicPlan={saleConfiguration.showEconomicPlan}
                historical={true}
              />
            </div>
            <div class="dossierGridPart-ComponentSection">
              <Form autoComplete="off" onSubmit={handleSubmit(this.submitSalesDossier.bind(this))}>
                {
                  activeTab === buttonsHeader.datos_expediente && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden && (
                    <div className="_nd dossierGridPart-ComponentSection-Inner" >
                      <div className="ComponentSection-InnerMennu">
                        <ButtonsSidebar
                          t={t}
                          dossierId={dossierId}
                          historical={true}
                          isClientLoaded={this.state.isClientLoaded}
                          isFinanceLoaded={this.state.isFinanceLoaded}
                          isBrokerageLoaded={this.state.isBrokerageLoaded}
                          isDeliveryLoaded={this.state.isDeliveryLoaded}
                          isVehicleLoaded={this.state.isVehicleLoaded}
                          isOtherDataLoaded={this.state.isOtherDataLoaded}
                          isEconomicLoaded={this.state.isEconomicLoaded}
                          isSelfSupplyLoaded={this.state.isSelfSupplyLoaded}
                          isReFinanceLoaded={this.state.isRefinanceLoaded}
                          dossierType={dossierTypesConstant.sales}
                          dossierSubTypes={dossierSubTypeId}
                          marginTop={statusHeaderHeight}
                          buttonsHeaderHeight={buttonsHeaderHeight}
                          tabConfiguration={this.state.activeTabConfiguration}
                          handleToUpdateComponent={this.handleToUpdateComponent.bind(this)}
                          isrefinance = {isRefinance}
                          pristine={pristine}
                          actions={{
                            setActiveSection,
                            fetchSalesDeliveryHistorical,
                            fetchSalesFinancingHistorical,
                            fetchSalesVehicleHistorical,
                            fetchSalesClientHistorical,
                            fetchSalesOtherDataHistorical,
                            fetchSalesInsuranceCarrierHistorical,
                            fetchSalesEconomic,
                            fetchSalesServices,
                            fetchSalesSelfSupplyHistorical
                          }}
                        />
                      </div>
                      <div className="ComponentSection-InnerData" id="dossierData" >
                        {this.state.componentsConfiguration.bsale && this.state.componentsConfiguration.bsale.permission > permissions.hidden && (
                          <Element style={{ zIndex: 14 }} name={buttonsSidebar.bsale} id="bsale">
                            <SalesComponent
                              t={t}
                              dossier={dossier}
                              salesOpen={this.state.salesOpen}
                              combos={combos}
                              actions={{
                                change,
                                fetchOperationType,
                                openDocumentEditionModal,
                                postPaymentSignalStatusSuccess,
                                openDocumentModal: (defaultTab) => openDocumentModal(dossierId, dossierTypesConstant.sales, pristine, defaultTab),
                                togglePanel: () => this.togglePanel(),
                                setActiveSection
                              }}
                              readOnlyFields={!saveSalePermission}
                              dossierType={dossierTypesConstant.sales}
                              open={activeSection === buttonsSidebar.bsale}
                              componentConfiguration={this.state.componentsConfiguration.bsale}
                              customFields={this.props.dossier.customFieldsByComponent.find(x => x.componentCode === buttonsSidebar.bsale)?.customFields}
                            />
                          </Element>
                        )}
                        {this.state.componentsConfiguration.fleet && this.state.componentsConfiguration.fleet.permission > permissions.hidden && (
                          <Element name={buttonsSidebar.fleet}>
                            <Fleet
                              t={t}
                              dossierId={dossierId}
                              pristine={pristine}
                              handleToUpdateComponent = {this.handleToUpdateComponent.bind(this)}
                              combos={combos}
                              readOnlyFields={true}
                              open={activeSection === buttonsSidebar.fleet}
                              componentConfiguration={this.state.componentsConfiguration.fleet}
                              dossier={dossier}
                              actions={{ fetchFleet, setActiveSection }}
                              customFields={this.props.dossier.customFieldsByComponent.find(x => x.componentCode === buttonsSidebar.fleet)?.customFields}
                            />
                          </Element>
                        )}
                        {this.state.componentsConfiguration.entity && this.state.componentsConfiguration.entity.permission > permissions.hidden && (
                          <Element name={buttonsSidebar.entity}>
                            <Client
                              t={t}
                              handleToUpdateComponent={this.handleToUpdateComponent.bind(this)}
                              isClientLoaded={this.state.isClientLoaded}
                              dossier={dossier}
                              pristine={pristine}
                              combos={combos}
                              entityChangeable={entityChangeable}
                              dossierType={dossierTypesConstant.sales}
                              actions={{
                                change,
                                openDocumentEditionModal,
                                fetchSalesClientHistorical,
                                setActiveSection,
                                fetchEntityCountryTypeCombo,
                                fetchEntityStateTypeCombo,
                                fetchEntityCityTypeCombo,
                                fetchEntityTownTypeCombo,
                                fetchEntityColonyTypeCombo,
                                fetchEntityCPTypeCombo,
                                openCommonModal,
                                fetchCommercialSocietyTypeCombo,
                                fetchActivityBpro,
                                fetchClientDataAddress
                              }}
                              readOnlyFields={!saveSalePermission}
                              open={activeSection === buttonsSidebar.entity}
                              componentConfiguration={this.state.componentsConfiguration.entity}
                              customFields={this.props.dossier.customFieldsByComponent.find(x => x.componentCode === buttonsSidebar.entity)?.customFields}
                              isHistorical= {true}
                            />
                          </Element>
                        )}
                        {this.state.componentsConfiguration.vehicle && this.state.componentsConfiguration.vehicle.permission > permissions.hidden && (
                          <Element name={buttonsSidebar.vehicle}>
                            <Vehicle
                              parentDossierType={{
                                isPaperwork: false,
                                isSales: true,
                                isPurchase: false,
                                isRegistrationDelivery: false
                              }}
                              handleToUpdateComponent={this.handleToUpdateComponent.bind(this)}
                              isVehicleLoaded={this.state.isVehicleLoaded}
                              dossierId={dossierId}
                              t={t}
                              searchedVehicleProps={vehicleProps}
                              orderId={dossier.orderId}
                              order={order}
                              dossierParentId={purchasesDossierId}
                              dossierWithVehicle={!!vehicleId}
                              combos={combos}
                              actions={{
                                fetchOrder,
                                fetchComboOrigin,
                                fetchComboDestination,
                                fetchSalesVehicleHistorical,
                                fecthFamily,
                                setActiveSection
                              }}
                              readOnlyFields={!saveSalePermission}
                              open={activeSection === buttonsSidebar.vehicle}
                              dossierType={dossierTypesConstant.sales}
                              dossierSubType={dossierSubTypeId}
                              componentConfiguration={this.state.componentsConfiguration.vehicle}
                              codeVehicleConfiguration={saleConfiguration.showCodecombos}
                              organizedUnitId={dossier.organizedUnitId}
                              comercialCode={this.props.dossier.vehicleComponent.comercialCode}
                              purchaseOrderExetendedMode={saleConfiguration && dossierSubTypeId === dossierSubTypeIdConstants.venta_vn ? saleConfiguration.purchaseOrderExetendedMode : null}
                              customFields={this.props.dossier.customFieldsByComponent.find(x => x.componentCode === buttonsSidebar.vehicle)?.customFields}
                              isHistorical={true}
                            />
                          </Element>
                        )}
                        { this.state.componentsConfiguration.self_supply && this.state.componentsConfiguration.self_supply.permission > permissions.hidden && (
                          <Element name={buttonsSidebar.self_supply}>
                            <SelfSupply
                              t={t}
                              actions={ { fetchSalesSelfSupplyHistorical, setActiveSection }}
                              combos={combos}
                              readOnlyFields={true}
                              handleToUpdateComponent={this.handleToUpdateComponent.bind(this)}
                              isSelfSupplyLoaded={this.state.isSelfSupplyLoaded}
                              open={activeSection === buttonsSidebar.self_supply}
                              componentConfiguration={this.state.componentsConfiguration.self_supply}
                              historical={true}
                              dossier={dossier}
                              customFields={this.props.dossier.customFieldsByComponent.find(x => x.componentCode === buttonsSidebar.self_supply)?.customFields}
                            />
                          </Element>
                        )}
                        {this.state.componentsConfiguration.campaign && this.state.componentsConfiguration.campaign.permission > permissions.hidden && (
                          <Element name={buttonsSidebar.campaign}>
                            <Campaigns
                              t={t}
                              campaigns={campaigns}
                              actions={{ setActiveSection }}
                              readOnlyFields={!saveSalePermission}
                              open={activeSection === buttonsSidebar.campaign}
                              componentConfiguration={this.state.componentsConfiguration.campaign}
                              customFields={this.props.dossier.customFieldsByComponent.find(x => x.componentCode === buttonsSidebar.campaign)?.customFields}
                            />
                          </Element>
                        )}
                        {this.state.componentsConfiguration.uac && this.state.componentsConfiguration.uac.permission > permissions.hidden && (
                          <Element name={buttonsSidebar.uac}>
                            <UAC
                              t={t}
                              uac={uac}
                              actions={{ setActiveSection }}
                              readOnlyFields={true}
                              open={activeSection === buttonsSidebar.uac}
                              componentConfiguration={this.state.componentsConfiguration.uac}
                              dossierType = { dossierTypesConstant.sales }
                              customFields={this.props.dossier.customFieldsByComponent.find(x => x.componentCode === buttonsSidebar.uac)?.customFields}
                            />
                          </Element>
                        )}
                        {this.state.componentsConfiguration.brokerage && this.state.componentsConfiguration.brokerage.permission > permissions.hidden && (
                          <Element name={buttonsSidebar.brokerage}>
                            <Brokerage
                              t={t}
                              pristine={pristine}
                              handleToUpdateComponent = {this.handleToUpdateComponent.bind(this)}
                              isBrokerageLoaded = {this.state.isBrokerageLoaded}
                              historical={true}
                              dossierId={dossierId}
                              combos={combos}
                              readOnlyFields={true}
                              open={activeSection === buttonsSidebar.brokerage}
                              componentConfiguration={this.state.componentsConfiguration.brokerage}
                              dossier={dossier}
                              actions={{
                                change,
                                fetchSalesInsuranceCarrierHistorical,
                                setActiveSection
                              }}
                              customFields={this.props.dossier.customFieldsByComponent.find(x => x.componentCode === buttonsSidebar.brokerage)?.customFields}
                            />
                          </Element>
                        )}
                        {this.state.componentsConfiguration.finance && this.state.componentsConfiguration.finance.permission > permissions.hidden && (
                          <Element name={buttonsSidebar.finance}>
                            <Finance
                              t={t}
                              dossier={dossier}
                              pristine={pristine}
                              actions={{
                                fetchSalesFinancingHistorical,
                                setActiveSection,
                                change,
                                fetchFinancingType
                              }}
                              historical={true}
                              handleToUpdateComponent={this.handleToUpdateComponent.bind(this)}
                              isFinanceLoaded={this.state.isFinanceLoaded}
                              dossierId={dossierId}
                              combos={combos}
                              readOnlyFields={true}
                              open={activeSection === buttonsSidebar.finance}
                              componentConfiguration={this.state.componentsConfiguration.finance}
                              financingTypeProduct = {this.props.financingTypeProduct}
                              customFields={this.props.dossier.customFieldsByComponent.find(x => x.componentCode === buttonsSidebar.finance)?.customFields}
                              showEconomicPlan={saleConfiguration.showEconomicPlan}
                            />
                          </Element>
                        )}
                        {this.state.componentsConfiguration.paperwork && this.state.componentsConfiguration.paperwork.permission > permissions.hidden && (
                          <Element name={buttonsSidebar.paperwork}>
                            <PaperworkTablePage
                              organizedUnitId={organizedUnitId}
                              dossierParentId={dossierId}
                              updateDossierAction={fetchSalesDossierDependants}
                              showAlertSendPending={showAlertSendPending}
                              paperwork={paperWorks}
                              dossierType={dossierTypesConstant.sales}
                              readOnlyFields={true}
                              isHistorical={true}
                              open={activeSection === buttonsSidebar.paperwork}
                              componentConfiguration={this.state.componentsConfiguration.paperwork}
                              externalPaperwork = {externalPaperwork}
                              customFields={this.props.dossier.customFieldsByComponent.find(x => x.componentCode === buttonsSidebar.paperwork)?.customFields}
                            />
                          </Element>
                        )}
                        {this.state.componentsConfiguration.charge && this.state.componentsConfiguration.charge.permission > permissions.hidden && (
                          <Element name={buttonsSidebar.charge}>
                            <Charges
                              t={t}
                              dossier={dossier}
                              charges={charges}
                              actions={{
                                openDocumentEditionModal,
                                updateDependantsSaleDossierAndHeader,
                                fetchSalesEconomic,
                                setActiveSection
                              }}
                              dossierType={dossierTypesConstant.sales}
                              readOnlyFields={true}
                              isPreCancelled={chargebackAmount !== undefined && chargebackAmount !== null}
                              open={activeSection === buttonsSidebar.charge}
                              componentConfiguration={this.state.componentsConfiguration.charge}
                              dossierId={dossierId}
                              pristine={pristine}
                              historical={true}
                              isEconomicLoaded={this.state.isEconomicLoaded}
                              handleToUpdateComponent={this.handleToUpdateComponent.bind(this)}
                              customFields={this.props.dossier.customFieldsByComponent.find(x => x.componentCode === buttonsSidebar.charge)?.customFields}
                            />
                          </Element>
                        )}
                        {this.state.componentsConfiguration.services && this.state.componentsConfiguration.services.permission > permissions.hidden && (
                          <Element name={buttonsSidebar.services}>
                            <Services
                              t={t}
                              dossier={dossier}
                              combos={combos}
                              showAddModal={dossierServiceRelateds.showAddModal}
                              datesNotValid={dossierServiceRelateds.datesNotValid}
                              services={dossierServiceRelateds.rows}
                              subTypesAllowedToCreate={dossierServiceRelateds.subTypesToCreate}
                              canAdd={dossierServiceRelateds.canAdd}
                              dossierType={dossierTypesConstant.sales}
                              readOnlyFields={true}
                              open={activeSection === buttonsSidebar.services}
                              componentConfiguration={this.state.componentsConfiguration.services}
                              historical = {true}
                              handleToUpdateComponent = {this.handleToUpdateComponent.bind(this)}
                              pristine={pristine}
                              actions={{
                                fetchSalesServices,
                                fetchDossierSubTypes,
                                fetchEntitySubTypeCombo,
                                fetchEntityFilterSubTypeCombo,
                                saveSalesService,
                                fetchDefaultEntity,
                                fetchNotValidDatesService,
                                openOrCloseServiceAddModal,
                                setActiveSection
                              }}
                              customFields={this.props.dossier.customFieldsByComponent.find(x => x.componentCode === buttonsSidebar.services)?.customFields}
                            />
                          </Element>
                        )}
                        {dossierSubTypeId === dossierSubTypeIdConstants.venta_vo && this.state.componentsConfiguration.other_data && this.state.componentsConfiguration.other_data.permission > permissions.hidden && (
                          <Element name={buttonsSidebar.other_data}>
                            <OtherData
                              t={t}
                              actions={{ fetchSalesOtherDataHistorical, setActiveSection }}
                              dossierId={dossierId}
                              pristine={pristine}
                              handleToUpdateComponent={this.handleToUpdateComponent.bind(this)}
                              isOtherDataLoaded={this.state.isOtherDataLoaded}
                              dossierType={dossierTypesConstant.sales}
                              combos={combos}
                              readOnlyFields={!saveSalePermission}
                              showEconomicPlan={saleConfiguration.showEconomicPlan}
                              open={activeSection === buttonsSidebar.other_data}
                              componentConfiguration={this.state.componentsConfiguration.other_data}
                              customFields={this.props.dossier.customFieldsByComponent.find(x => x.componentCode === buttonsSidebar.other_data)?.customFields}
                            />
                          </Element>
                        )}
                        {this.state.componentsConfiguration.delivery && this.state.componentsConfiguration.delivery.permission > permissions.hidden && (
                          <Element name={buttonsSidebar.delivery}>
                            <Delivery
                              t={t}
                              handleToUpdateComponent={this.handleToUpdateComponent.bind(this)}
                              isDeliveryLoaded={this.state.isDeliveryLoaded}
                              actions={{
                                fetchSalesDeliveryHistorical,
                                setActiveSection
                              }}
                              dossierId={dossierId}
                              pristine={pristine}
                              isSalesDossier={true}
                              dossierCompleteDeliveryCheckListItems={dossierCompleteDeliveryCheckListItems}
                              dossierDeliveryCheckListPurchase={dossierDeliveryCheckListPurchase}
                              readOnlyFields={true}
                              open={activeSection === buttonsSidebar.delivery}
                              dossierSubTypeId={dossierSubTypeId}
                              componentConfiguration={this.state.componentsConfiguration.delivery}
                              combos={combos}
                              customFields={this.props.dossier.customFieldsByComponent.find(x => x.componentCode === buttonsSidebar.delivery)?.customFields}
                            />
                          </Element>
                        )}
                        {this.state.componentsConfiguration.refinance && this.state.componentsConfiguration.refinance.permission > permissions.hidden && isRefinance && (
                          <Element name={buttonsSidebar.refinance}>
                            <ReFinance
                              t={t}
                              dossier={dossier}
                              pristine={pristine}
                              actions={{
                                setActiveSection,
                                change,
                                fetchFinancingType
                              }}
                              historical={true}
                              handleToUpdateComponent={this.handleToUpdateComponent.bind(this)}
                              isReFinanceLoaded={this.state.isRefinanceLoaded}
                              dossierId={dossierId}
                              combos={combos}
                              readOnlyFields={true}
                              open={activeSection === buttonsSidebar.refinance}
                              componentConfiguration={this.state.componentsConfiguration.finance}
                              // financingTypeProduct = {this.props.financingTypeProduct}
                              openRefinanceModal = {this.openRefinanceModal.bind(this)}
                              customFields={this.props.dossier.customFieldsByComponent.find(x => x.componentCode === buttonsSidebar.refinance)?.customFields}
                            />
                          </Element>
                        )}

                      </div>
                    </div>
                  )
                }
                {
                  activeTab === buttonsHeader.gestor_documental && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden && (
                    <div className="dossierGridPart-ComponentSection-Inner sales-panel gestorDocumental">
                      <div className="ComponentSection-InnerData">
                        <DocumentaryManagementPage
                          dossier={dossier}
                          dossierType={dossierTypesConstant.sales}
                          saveSelectedDocuments={saveSelectedDocuments}
                          documentExport={documentExport}
                          combos={combos}
                          isHistorical={true}
                          tabConfiguration={this.state.activeTabConfiguration}
                          downloadButtonPermission={this.state.activeTabConfiguration.componentsConfiguration.downloadDocumentsButton}
                        />
                      </div>
                    </div>
                  )}
                {
                  activeTab === buttonsHeader.planteo_economico && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden && (
                    <div className="dossierGridPart-ComponentSection-Inner">
                      <EconomicPlan
                        t={t}
                        activeSection={activeSection}
                        activeTab={activeTab}
                        dossierEconomicComponent={dossierEconomicComponent}
                        readOnlyFields={true}
                        economicTab={true}
                        dossierType={dossierTypesConstant.sales}
                        dossierSubTypeId={dossierSubTypeId}
                        combos={combos}
                        actions={{
                          setActiveSection,
                          fetchPresentTypesSuccess,
                          fetchSalesEconomic
                        }}
                        panelsPaddingTop={panelsPaddingTop}
                        dossierId={dossierId}
                        pristine={pristine}
                        historical={true}
                        isEconomicLoaded={this.state.isEconomicLoaded}
                        handleToUpdateComponent={this.handleToUpdateComponent.bind(this)}
                        tabConfiguration={this.state.activeTabConfiguration}
                        tabConfiguration2={this.props.dossier.viewConfiguration.tabsConfiguration}
                        numberOfDecimals = {saleConfiguration?.numberOfDecimals}
                      />
                    </div>
                  )
                }
                {
                  activeTab === buttonsHeader.expedientes_relacionados && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden && (
                    <div className="dossierGridPart-ComponentSection-Inner related-dossiers-table">
                      <div className="ComponentSection-InnerData">
                        <RelatedDossiers
                          t={t}
                          relatedDossiersOrderBy = {this.props.relatedDossiersOrderBy}
                          panelsPaddingTop = {panelsPaddingTop}
                          history = {this.props.history}
                          dossierRelatedDossiers = {dossierRelatedDossiers}
                          dossierId= {dossierId}
                          isHistorical= {true}
                          actions={{ fetchFilteredRelatedDossier }}
                        />
                      </div>
                    </div>
                  )}
                {
                  activeTab === buttonsHeader.actividad_expediente && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden && (
                    <div className="dossierGridPart-ComponentSection-Inner auditInfo">
                      <div sm={12} className="ComponentSection-InnerData">
                        <AuditInfo
                          auditInfo={auditInfo}
                          dossierType={dossierTypesConstant.sales}
                          actions={{ refreshAuditInfo, fetchAuditDetails, fetchAuditDetailsModal, fetchDocumentsGroupedAuditDetails, fetchDocumentsGroupedAuditDetailsModal }}
                          dossier={dossier}
                          historical={true}
                          t={t}
                        />
                      </div>
                    </div>
                  )
                }
                {
                  (activeTab === buttonsHeader.observaciones && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden) && (
                    <div className="dossierGridPart-ComponentSection-Inner commentComponent">
                      <div className="ComponentSection-InnerData">
                        <CommentComponent
                          comments={comments}
                          actions={{ updateComment, createComment, deleteComment, markCommentAsRead }}
                          readOnlyFields={!saveSalePermission}
                          dossierType="salesDossier"
                          t={t}
                          tKey={tKey}
                          combos={{ allReasonCombo: this.props.combos.allReasonCombo }}
                          openComponent={activeTab === buttonsHeader.observaciones}
                          dossierId={this.props.dossier.dossierId}
                          historical={true}
                        />

                      </div>
                    </div>
                  )}

              </Form>
            </div>
          </div>
        </div>
        <RejectCommentModal
          t={t}
          showModal={this.state.showRejectCommentModal}
          actions={{
            closeModal: () => this.toggleRejectCommentModal(false)
          }}
        />
        <ReFinanceModalPage
          componentConfiguration = {this.state.componentsConfiguration.finance}
          financingTypeProduct = {this.props.financingTypeProduct}
          showModal = {this.state.showRefinanceModal}
          closeModal = {this.closeRefinanceModal.bind(this)}
          actions = {{ change, fetchFinancingType }}
          dossierId = {this.props.dossier.dossierId}
          dossierReFinancingComponent = {dossierReFinancingComponent}
        ></ReFinanceModalPage>

      </div>
    )
  }
}

var decoratedComponent = TrackingContainer(SaleHistorical, false)

export default reduxForm({
  form: 'salesDossier'
})(decoratedComponent)
