import { put, call, takeEvery, select } from 'redux-saga/effects'
import { reset, change } from 'redux-form'
import { yesNoModal } from '../../modalsListeners/yesNoModal'
import dossierActionsTypes from '../../../constants/actions/dossier/dossier'
import { dossierTypes as dossierTypesConstant } from '../../../constants/dossier/common/dossierTypes'
import messageModalActions from '../../../actions/modals/messageModal'
import { updatePresentSuccess } from '../../../actions/dossier/sales'
import { hideLoader } from '../../../actions/common'
import { handleError } from '../../errors/errorManager'
import { initializeDossier } from '../commonDossierSagas'
import { v4 as uuidv1 } from 'uuid'
import { updateEconomicPlanFleet } from '../../../actions/management/management'

export function * createPresent ({ newPresent, resolve, reject, dossierType }) {
  try {
    let stateDossier, economicState, dossierForm, formName, updateFunction

    if (dossierType === dossierTypesConstant.fleetEconomicPlan) {
      stateDossier = yield select(state => state.management.economicPlanModal)
      economicState = JSON.parse(JSON.stringify(stateDossier.dossierEconomicComponent))
      dossierForm = yield select(state => state.form.economicPlanModal.values.dossierEconomicComponent)
      formName = 'economicPlanModal'
      updateFunction = updateEconomicPlanFleet
    } else {
      stateDossier = yield select(state => state.salesDossier)
      economicState = JSON.parse(JSON.stringify(stateDossier.dossierEconomicComponent))
      dossierForm = yield select(state => state.form.salesDossier.values.dossierEconomicComponent)
      formName = 'salesDossier'
      updateFunction = updatePresentSuccess
    }

    let newEconomic = JSON.parse(JSON.stringify(dossierForm))
    // changes(add & delete items changes state not form)
    newEconomic.presents = economicState.presents
    newPresent.dossierId = stateDossier.dossierId
    newPresent.amount = parseFloat(newPresent.amount)
    newPresent.cost = parseFloat(newPresent.cost)
    newPresent.indexFront = uuidv1()
    newEconomic.presents.push(newPresent)
    if (dossierType === dossierTypesConstant.fleetEconomicPlan) {
      yield put(updateFunction(newEconomic))
    } else {
      yield put(updateFunction(newEconomic.presents))
    }
    yield put(change(formName, 'dossierEconomicComponent', newEconomic))
    yield put(messageModalActions.openSuccessMessageModal('MESSAGES.POST_TABLE_ITEM_SUCCESS'))
    yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          const salesDossierState = yield select(state => state.salesDossier)
          yield call(initializeDossier, dossierTypesConstant.sales, salesDossierState)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('MESSAGES.POST_TABLE_ITEM_ERROR', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
      yield call(reject, customHandlerError)
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchCreatePresent () {
  yield takeEvery(dossierActionsTypes.CREATE_PRESENT, createPresent)
}

export function * deletePresent ({ present, dossierType }) {
  try {
    let stateDossier, economicState, dossierForm, formName, updateFunction

    if (dossierType === dossierTypesConstant.fleetEconomicPlan) {
      stateDossier = yield select(state => state.management.economicPlanModal)
      economicState = JSON.parse(JSON.stringify(stateDossier.dossierEconomicComponent))
      dossierForm = yield select(state => state.form.economicPlanModal.values.dossierEconomicComponent)
      formName = 'economicPlanModal'
      updateFunction = updateEconomicPlanFleet
    } else {
      stateDossier = yield select(state => state.salesDossier)
      economicState = JSON.parse(JSON.stringify(stateDossier.dossierEconomicComponent))
      dossierForm = yield select(state => state.form.salesDossier.values.dossierEconomicComponent)
      formName = 'salesDossier'
      updateFunction = updatePresentSuccess
    }

    let newEconomic = JSON.parse(JSON.stringify(dossierForm))
    // changes(add & delete items changes state not form)
    newEconomic.presents = economicState.presents
    let index = present.id ? newEconomic.presents.findIndex(d => d.id === present.id)
      : newEconomic.presents.findIndex(d => d.indexFront === present.indexFront)
    newEconomic.presents.splice(index, 1)
    if (dossierType === dossierTypesConstant.fleetEconomicPlan) {
      yield put(updateFunction(newEconomic))
    } else {
      yield put(updateFunction(newEconomic.presents))
    }
    yield put(change(formName, 'dossierEconomicComponent', newEconomic))
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          const salesDossierState = yield select(state => state.salesDossier)
          yield call(initializeDossier, dossierTypesConstant.sales, salesDossierState)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDeletePresent () {
  yield takeEvery(dossierActionsTypes.DELETE_PRESENT, deletePresent)
}

export function * updatePresent ({ present, resolve, reject, dossierType }) {
  try {
    let stateDossier, economicState, dossierForm, formName, updateFunction

    if (dossierType === dossierTypesConstant.fleetEconomicPlan) {
      stateDossier = yield select(state => state.management.economicPlanModal)
      economicState = JSON.parse(JSON.stringify(stateDossier.dossierEconomicComponent))
      dossierForm = yield select(state => state.form.economicPlanModal.values.dossierEconomicComponent)
      formName = 'economicPlanModal'
      updateFunction = updateEconomicPlanFleet
    } else {
      stateDossier = yield select(state => state.salesDossier)
      economicState = JSON.parse(JSON.stringify(stateDossier.dossierEconomicComponent))
      dossierForm = yield select(state => state.form.salesDossier.values.dossierEconomicComponent)
      formName = 'salesDossier'
      updateFunction = updatePresentSuccess
    }

    let newEconomic = JSON.parse(JSON.stringify(dossierForm))

    // changes(add & delete items changes state not form)
    newEconomic.presents = economicState.presents
    let index = present.id ? newEconomic.presents.findIndex(d => d.id === present.id)
      : newEconomic.presents.findIndex(d => d.indexFront === present.indexFront)
    present.amount = parseFloat(present.amount)
    present.cost = parseFloat(present.cost)
    newEconomic.presents[index] = present
    if (dossierType === dossierTypesConstant.fleetEconomicPlan) {
      yield put(updateFunction(newEconomic))
    } else {
      yield put(updateFunction(newEconomic.presents))
    }
    yield put(change(formName, 'dossierEconomicComponent', newEconomic))
    yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          const salesDossierState = yield select(state => state.salesDossier)
          yield call(initializeDossier, dossierTypesConstant.sales, salesDossierState)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
      yield call(reject, customHandlerError)
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchUpdatePresent () {
  yield takeEvery(dossierActionsTypes.UPDATE_PRESENT, updatePresent)
}
