import apiFetch from '../apiFetch'

export default function (userId, token) {
  const queryParams = userId ? `userId=${userId}` : ''
  return apiFetch({
    endPoint: `EventSubscription/GetAll?${queryParams}`,
    method: 'GET',
    body: null,
    token: token
  })
}
