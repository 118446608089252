import React, { PureComponent } from 'react'
import { Popover, Overlay, Button, Glyphicon } from 'react-bootstrap'
import { urgencyTypesClass } from '../../../../../constants/backendIds'

class DossierPopover extends PureComponent {
  constructor () {
    super()
    this.state = {
      showPopover: false
    }
  }

  handleClick (e) {
    e.stopPropagation()
    this.setState({ target: e.target, showPopover: true })
  }

  render () {
    const {
      t,
      idx,
      half,
      id,
      dossier,
      dossier: { urgencyType }
    } = this.props
    const tKey = 'SEARCH.CATEGORIES.'
    if (Object.keys(dossier).length === 0) return null
    return (
      <div className="subcabecera">
        <i className="subcabecera-ico ico-document-lines"/>
        <span className="info-entidad-documento">
          {dossier.number}
        </span>
        {Object.keys(dossier).length > 0 &&
          <Button bsSize="xsmall" className="icon-only icon-plus-subcabecera" onClick={this.handleClick.bind(this)}><Glyphicon glyph="plus"/></Button>
        }
        <span className="espacio-subcabecera"></span>
        <Overlay
          show={this.state.showPopover}
          target={this.state.target}
          onHide={() => this.setState({ showPopover: false })}
          rootClose
          placement={(idx >= half) ? 'top' : 'bottom'}
        >
          <Popover id={id} className="dossier-popover-container">
            {dossier.number &&
            <div className="popover-item">
              <label>{t(`${tKey}DOSSIER.DOSSIER_NUMBER`)}: </label><br/>
              <span className={'color-point ' + urgencyTypesClass[urgencyType]}/>{' '}{dossier.number}
            </div>
            }
            {dossier.subtype && (
              <div className="popover-item">
                <label>{t(`${tKey}DOSSIER.DOSSIER_SUBTYPE`)}</label><br/>
                {dossier.subtype}
              </div>
            )}
            {dossier.stage && (
              <div className="popover-item">
                <label>{t(`${tKey}DOSSIER.STAGE`)}</label><br/>
                {dossier.stage}
              </div>
            )}
            {dossier.organizedUnit && (
              <div className="popover-item">
                <label>{t(`${tKey}DOSSIER.ORGANIZED_UNIT`)}</label><br/>
                {dossier.organizedUnit}
              </div>
            )}
            {dossier.salesman && (
              <div className="popover-item">
                <label>{t(`${tKey}DOSSIER.SALESMAN`)}</label><br/>
                {dossier.salesman}
              </div>
            )}
            {dossier.lastUpdateDate && (
              <div className="popover-item">
                <label>{t(`${tKey}DOSSIER.LAST_CHANGE_DATE`)}</label><br/>
                {dossier.lastUpdateDate}
              </div>
            )}
          </Popover>
        </Overlay>
      </div>
    )
  }
}

export default DossierPopover
