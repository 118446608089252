import dossierActionTypes from '../../constants/actions/dossier/dossier'
import { refreshAuditInfo, fetchAuditInfoSucces, fetchAuditDetails, fetchAuditDetailsModal, fetchDocumentsGroupedAuditDetails, fetchDocumentsGroupedAuditDetailsModal } from '../../actions/audit/auditInfo'

export function fetchPaperworkDossier (dossierId, resolve, reject, historical) {
  return {
    type: dossierActionTypes.FETCH_PAPERWORK_DOSSIER,
    dossierId,
    resolve,
    reject,
    historical
  }
}
export function fetchPaperworkClient (dossierId, resolve, reject, historical, pristine) {
  return {
    type: dossierActionTypes.FETCH_PAPERWORK_DOSSIER_CLIENT,
    dossierId,
    resolve,
    reject,
    historical,
    pristine
  }
}
export function fetchPaperworkVehicle (dossierId, resolve, reject, historical, pristine) {
  return {
    type: dossierActionTypes.FETCH_PAPERWORK_DOSSIER_VEHICLE,
    dossierId,
    resolve,
    reject,
    historical,
    pristine
  }
}
export function fetchPaperworkDossierSuccess (dossier, checkListHeader) {
  return {
    type: dossierActionTypes.FETCH_PAPERWORK_DOSSIER_SUCCESS,
    dossier,
    checkListHeader
  }
}

export function savePaperworkDossier (paperworkDossier) {
  return {
    type: dossierActionTypes.SAVE_PAPERWORK_DOSSIER,
    paperworkDossier
  }
}

export function paperworkReject (comment, resolve, reject, pristine) {
  return {
    type: dossierActionTypes.PAPERWORK_REJECT,
    comment,
    resolve,
    reject,
    pristine
  }
}

export function paperworkGenDelivery (pristine) {
  return {
    type: dossierActionTypes.PAPERWORK_GEN_DELIVERY,
    pristine
  }
}

export function restartPaperworkState () {
  return {
    type: dossierActionTypes.RESTART_PAPERWORK_STATE
  }
}

export function fetchDossierRelatedDossiersSuccess (dossierRelatedDossiers) {
  return {
    type: dossierActionTypes.FETCH_DOSSIER_RELATED_DOSSIERS_SUCCESS,
    dossierRelatedDossiers
  }
}

export function paperworkWarningSave (reset, router, nextLocation, pristine) {
  return {
    type: dossierActionTypes.PAPERWORK_WARNING_SAVE,
    reset,
    router,
    nextLocation,
    pristine
  }
}
export function fetchPaperworkVehicleHistorical (dossierId, resolve, reject) {
  return {
    type: dossierActionTypes.FETCH_PAPERWORK_DOSSIER_VEHICLE_HISTORICAL,
    dossierId,
    resolve,
    reject
  }
}

export function fetchPaperworkClientHistorical (dossierId, resolve, reject) {
  return {
    type: dossierActionTypes.FETCH_PAPERWORK_DOSSIER_CLIENT_HISTORICAL,
    dossierId,
    resolve,
    reject
  }
}

export function resendToProcessingAgency (paperworkId, lastAccessDate) {
  return {
    type: dossierActionTypes.PAPERWORK_RESEND_TO_PROCESSING_AGENCY,
    paperworkId,
    lastAccessDate
  }
}

export function paperworkNotify (paperworkId) {
  return {
    type: dossierActionTypes.PAPERWORK_NOTIFY,
    paperworkId
  }
}

export function paperworkTransferCompleted (pristine) {
  return {
    type: dossierActionTypes.PAPERWORK_TRANSFER_COMPLETED,
    pristine
  }
}

export function fetchProcessingAgency (organizedUnitId, dossierId) {
  return {
    type: dossierActionTypes.FETCH_PROCESSING_AGENCY_TYPE,
    organizedUnitId,
    dossierId
  }
}

export default {
  fetchPaperworkDossier,
  fetchPaperworkDossierSuccess,
  savePaperworkDossier,
  paperworkReject,
  paperworkGenDelivery,
  restartPaperworkState,
  fetchDossierRelatedDossiersSuccess,
  paperworkWarningSave,
  refreshAuditInfo,
  fetchAuditInfoSucces,
  fetchPaperworkVehicle,
  fetchPaperworkClient,
  fetchPaperworkVehicleHistorical,
  fetchPaperworkClientHistorical,
  resendToProcessingAgency,
  fetchAuditDetails,
  fetchAuditDetailsModal,
  paperworkNotify,
  paperworkTransferCompleted,
  fetchProcessingAgency,
  fetchDocumentsGroupedAuditDetails,
  fetchDocumentsGroupedAuditDetailsModal
}
