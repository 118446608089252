import apiFetch from '../apiFetch'

export default function (documentTypeUseCode, organizedUnitId, documentTypeId, token, sign, dossierId, id, checklistId, lastAccessDate, chargeId, dynamicValues) {
  const queryParamsArray = [
    documentTypeUseCode ? `documentTypeUseCode=${documentTypeUseCode}` : 'documentTypeUseCode=null',
    organizedUnitId ? `organizedUnitId=${organizedUnitId}` : 'organizedUnitId=null',
    documentTypeId ? `documentTypeId=${documentTypeId}` : 'documentTypeId=null',
    id ? `id=${id}` : null,
    checklistId ? `checklistId=${checklistId}` : null,
    dossierId ? `dossierId=${dossierId}` : null,
    chargeId ? `chargeId=${chargeId}` : null,
    sign ? `sign=${sign}` : `sign=${false}`
  ]

  const queryParams = queryParamsArray.reduce(
    (result, query, idx) => result + (query ? (result ? `&${query}` : `?${query}`) : '')
    , ''
  )
  return apiFetch({ endPoint: `Document/CreateAndSingDocumentFromDynamicTemplate${queryParams}`, method: 'POST', body: dynamicValues, token: token, lastAccessDate })
}
