import React, { PureComponent } from 'react'
import { OverlayTrigger, Tooltip, Glyphicon } from 'react-bootstrap'
import { urgencyTypesClass, urgencyTypesName, typeFrontEntities } from '../../../../constants/backendIds'
import { buttonsHeader } from '../../../../constants/dossier/common/buttonsHeader'
import { buttonsSidebar } from '../../../../constants/dossier/common/buttonsSidebar'
import EntityPopover from '../../../../_v2/components/search/EntityPopover'
import DossierPopover from '../../../../_v2/components/search/DossierPopover'
import VehiclePopover from '../../../../_v2/components/search/VehiclePopover'
import DocumentPopover from '../../../../_v2/components/search/DocumentPopover'
import DossierListPopover from '../../../../_v2/components/search/DossierListPopover'
import { formatMoney, getStockVoSectionByStage } from '../../../../util/utils'

class TaskListRow extends PureComponent {
  onClickTaskListRow (navigationType, urlToNavigate, documentId, documentTypeId, documentParentTypeId, objectId, organizedUnitId, stageId) {
    switch (navigationType) {
      case 1:
      { this.props.history.push(`dossier${urlToNavigate}`)
        this.props.actions.setActiveSection(null)
        break }
      case 2:
      { this.props.history.push(`dossier${urlToNavigate}`)
        this.props.actions.setActiveSection(buttonsSidebar.vehicle)
        this.props.actions.setScrollTo(buttonsSidebar.vehicle)
        break }
      case 3:
      { this.props.history.push(`dossier${urlToNavigate}`)
        this.props.actions.setActiveTab(buttonsHeader.planteo_economico)
        break }
      case 4:
      {
        let dossierId = typeFrontEntities.DOSSIER === documentParentTypeId ? objectId : this.props.row.dossier && this.props.row.dossier.dossierId ? this.props.row.dossier.dossierId : null
        let vehicleId = typeFrontEntities.VEHICLE === documentParentTypeId ? objectId : null
        let entityId = typeFrontEntities.ENTITY === documentParentTypeId ? objectId : null
        this.props.actions.openDocumentEditionModal(documentId, documentTypeId, documentParentTypeId, dossierId, entityId, vehicleId, organizedUnitId, null, this.props.actions.fetchFilteredDashboardTask, true, '', '', null, null, null, null, true, null, true)
        break }
      case 5:
      { this.props.history.push(`dossier${urlToNavigate}`)
        this.props.actions.setActiveTab(buttonsHeader.gestor_documental)
        break }
      case 6:
      { this.props.history.push(`dossier${urlToNavigate}`)
        this.props.actions.setActiveSection(buttonsSidebar.delivery)
        this.props.actions.setScrollTo(buttonsSidebar.delivery)
        break }
      case 7:
      { this.props.history.push(`dossier${urlToNavigate}`)
        this.props.actions.setActiveSection(buttonsSidebar.charge)
        this.props.actions.setScrollTo(buttonsSidebar.charge)
        break }
      case 8:
      { this.props.history.push(`dossier${urlToNavigate}`)
        let section = getStockVoSectionByStage(stageId)
        this.props.actions.setActiveSection(section)
        this.props.actions.setScrollTo(section)
        break }
    }
  }

  componentWillUnmount () {
    this.props.actions.closeAddOrEditDocumentModal()
  }

  render () {
    const {
      t,
      idr,
      columns,
      row,
      pageSize,
      navigationType
    } = this.props
    const tKey = 'DASHBOARD_TASK.TASK_LIST.'
    const navigationTooltip = (<Tooltip id="urgenciaTooltip">{t(`${tKey}TABLE.TOOLTIP.GO_TO`)}</Tooltip>)
    return (
      <tr>
        {
          (() => {
            const urgencyType = row.urgencyType
            return columns.map((column, idc) => {
              return (() => {
                switch (column.columnType) {
                  case 1:
                  { const urgenciaTooltip = (<Tooltip id="urgenciaTooltip">{t(`${tKey}TABLE.TOOLTIP.URGENCY`) + ' '}<span className="nombreUrgenciaTooltip">{t(`${tKey}URGENCY_TYPES.` + urgencyTypesName[urgencyType])}</span></Tooltip>)
                    return (
                      <td
                        key={idc}
                        className={column.columnType}
                      >
                        {row[column.columnCode] &&
                      <OverlayTrigger placement="right" overlay={urgenciaTooltip}>
                        <span className={'color-point ' + urgencyTypesClass[urgencyType]}/>
                      </OverlayTrigger>
                        }
                      </td>
                    ) }

                  case 2:
                  { return (
                    <td
                      key={idc}
                      className={column.columnType + ' see-more'}
                    >
                      {row[column.columnCode] &&
                      <EntityPopover
                        idx={idr}
                        half={pageSize / 2}
                        t={t}
                        id={'popover-' + column.columnType + idr}
                        entity={row[column.columnCode]}
                        urgencyType={row.urgencyType}
                      />
                      }
                    </td>
                  ) }

                  case 3:
                  { return (
                    <td
                      key={idc}
                      className={column.columnType + ' see-more'}
                    >
                      {row[column.columnCode] &&
                      <VehiclePopover
                        idx={idr}
                        half={pageSize / 2}
                        t={t}
                        id={'popover-' + column.columnType + idr}
                        vehicle={row[column.columnCode]}
                        dossierSubTypeId={row.dossier && row.dossier.subtypeId}
                      />
                      }
                    </td>
                  ) }

                  case 4:
                  { return (
                    <td
                      key={idc}
                      className={column.columnType + ' see-more'}
                    >
                      {row[column.columnCode] &&
                      <DossierPopover
                        idx={idr}
                        half={pageSize / 2}
                        t={t}
                        id={'popover-' + column.columnType + idr}
                        urgencyType={urgencyType}
                        dossier={row[column.columnCode]}
                      />
                      }
                    </td>
                  ) }

                  case 5:
                  { return (
                    <td
                      key={idc}
                      className={column.columnType + ' see-more'}
                    >
                      {row[column.columnCode] &&
                      <DocumentPopover
                        idx={idr}
                        half={pageSize / 2}
                        t={t}
                        id={'popover-' + column.columnType + idr}
                        urgencyType={urgencyType}
                        document={row[column.columnCode]}
                      />
                      }
                    </td>
                  ) }

                  case 6:
                  { const pendienteSubirTooltip = (<Tooltip id="pendienteSubirTooltip">{t(`${tKey}TABLE.TOOLTIP.PENDING_UPLOAD`)}</Tooltip>)
                    const pendienteValidarTooltip = (<Tooltip id="pendienteValidarTooltip">{t(`${tKey}TABLE.TOOLTIP.PENDING_VALIDATION`)}</Tooltip>)
                    return (
                      <td
                        key={idc}
                        className={column.columnType}
                      >
                        {row[column.columnCode] &&
                      <div className="icon-set">
                        <OverlayTrigger placement="right" overlay={pendienteSubirTooltip}>
                          <i className="ico-cloud"/>
                        </OverlayTrigger>
                        {row[column.columnCode].uploaded}/{row[column.columnCode].pendingUpload}
                        <OverlayTrigger placement="right" overlay={pendienteValidarTooltip}>
                          <Glyphicon glyph="ok-sign"/>
                        </OverlayTrigger>
                        {row[column.columnCode].validated}/{row[column.columnCode].pendingValidate}
                      </div>
                        }
                      </td>
                    ) }

                  case 7: case 8:
                  { return (
                    <td key={idc} className={column.columnType}>{row[column.columnCode]}</td>
                  ) }

                  case 9:
                    return (
                      <td key={idc} className={column.columnType} style={{ textAlign: 'right' }}>{formatMoney(row[column.columnCode])}</td>
                    )
                  case 10:
                  { return (<td key={idc} className="see-more">
                    <DossierListPopover
                      t={t}
                      idx={idr}
                      half={pageSize / 2}
                      id={'popover-' + column.columnType + idr}
                      alive={row[column.columnCode]}
                    />
                  </td>) }

                  default:
                  { return (
                    <td key={idc}>{column.columnCode + '(' + column.columnType + ')'}</td>
                  ) }
                }
              })()
            })
          })()
        }
        <td key={columns.length} className="navigation">
          {((row.dossier && row.dossier.urlToNavigate) || (row.document && row.document.documentId)) &&
          <OverlayTrigger placement="left" overlay={navigationTooltip}>
            <a className="_Btn accept stk o-icn noLine"
              onClick={this.onClickTaskListRow.bind(
                this,
                navigationType,
                row.dossier && row.dossier.urlToNavigate,
                row.document && row.document.documentId,
                row.document && row.document.documentTypeId,
                row.document && row.document.documentParentTypeId,
                row.document && row.document.objectId,
                row.document && row.document.organizedUnitId,
                row.dossier && row.dossier.stageId
              )}><i class="iDocIcon-open-in-new-black"></i></a>
          </OverlayTrigger>
          }
        </td>
      </tr>
    )
  }
}

export default TaskListRow
