import commonActionTypes from '../constants/actions/common.js'

export function initialState () {
  return {
    loading: 0,
    componentLoading: 0,
    locale: null,
    messages: {},
    languageAvailable: null,
    modal: {
      showModal: false,
      modalType: null,
      message: '',
      subMessage: '',
      replaceMessageArgs: [],
      replaceSubMessageArgs: [],
      okAction: null,
      cancelAction: null
    }
  }
}

function fetchLanguagesAvailableSuccess (state, { languageAvailable }) {
  return {
    ...state,
    languageAvailable: languageAvailable
  }
}

function showLoaderMain (state) {
  return {
    ...state,
    loading: state.loading + 1
  }
}

function hideLoaderMain (state) {
  return {
    ...state,
    loading: state.loading === 0 ? 0 : state.loading - 1
  }
}

function showSmallLoader (state) {
  return {
    ...state,
    componentLoading: state.componentLoading + 1
  }
}

function hideSmallLoader (state) {
  return {
    ...state,
    componentLoading: state.componentLoading === 0 ? 0 : state.componentLoading - 1
  }
}

function changeLocale (state, { responseLocale }) {
  return {
    ...state,
    locale: responseLocale.locale,
    messages: responseLocale.messages
  }
}

function openModal (state, { modalType, okAction, cancelAction, message, subMessage, replaceMessageArgs, replaceSubMessageArgs }) {
  return {
    ...state,
    modal: {
      showModal: true,
      modalType,
      okAction,
      cancelAction,
      message,
      subMessage,
      replaceMessageArgs,
      replaceSubMessageArgs
    }
  }
}

function closeModal (state) {
  const initial = initialState()
  return {
    ...state,
    modal: {
      ...initial.modal
    }
  }
}

function setCurrentSection (state, { section }) {
  return {
    ...state,
    section: section
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case commonActionTypes.FETCH_LANGUAGES_AVAILABLE_SUCCESS:
      return fetchLanguagesAvailableSuccess(state, action)
    case commonActionTypes.SHOW_LOADER:
      return showLoaderMain(state)
    case commonActionTypes.HIDE_LOADER:
      return hideLoaderMain(state)
    case commonActionTypes.SHOW_SMALL_LOADER:
      return showSmallLoader(state)
    case commonActionTypes.HIDE_SMALL_LOADER:
      return hideSmallLoader(state)
    case commonActionTypes.CHANGE_LOCALE:
      return changeLocale(state, action)
    case commonActionTypes.OPEN_MODAL:
      return openModal(state, action)
    case commonActionTypes.CLOSE_MODAL:
      return closeModal(state)
    case commonActionTypes.SET_CURRENT_SECTION:
      return setCurrentSection(state, action)
    default:
      return state
  }
}
