import React, { PureComponent } from 'react'
import { Row, Clearfix, Button } from 'react-bootstrap'
import { Field } from 'redux-form'
import InputSelect from '../commons/form/InputSelect'
import InputText from '../commons/form/InputText'
import InputDatePicker from '../commons/form/InputDatePicker'
import InputTreeSelect from '../commons/form/InputTreeSelect'
import { validateDate } from '../../util/validationFunctions'
import InputNumber from '../commons/form/InputNumber'
import EntityPopover from './EntityPopover'
import DossierPopover from './DossierPopover'
import VehiclePopover from './VehiclePopover'
import SimpleTablePage from '../../containers/commons/general/SimpleTablePage'
import IDocCarPagination from '../commons/iDocCarPagination'
import FinderFleetReportDatesPopover from './FinderFleetReportDatesPopover'
import { dossierSubTypeId } from '../../constants/dossier/common/dossierSubType'
import { entityTypesId } from '../../constants/backendIds'

class SearchByFleetReport extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      fields: {},
      objective: 0,
      selectedEntityType: null,
      selectedEntityChildType: null
    }
  }

  componentDidMount () {
    if (this.props.dynamicFilters?.fleetReport !== null && this.props.dynamicFilters?.fleetReport !== undefined) {
      if (this.props.dynamicFilters.fleetReport.entityTypeId !== null && this.props.dynamicFilters.fleetReport.entityTypeId !== undefined) {
        this.onEntityTypeChange(this.props.dynamicFilters.fleetReport.entityTypeId)
        if (this.props.dynamicFilters.fleetReport.entityTypeId === entityTypesId.recurrentEntity) {
          this.setState({ selectedEntitySubType: this.props.dynamicFilters.fleetReport.entitySubTypeId })
          this.props.actions.fetchEntityFilterSubTypeCombo(this.props.dynamicFilters.fleetReport.entityTypeId, this.props.dynamicFilters.fleetReport.entitySubTypeId)
        }
      }
    }
  }

  getYesNoComboOptions () {
    const tKey = 'SEARCH.CATEGORIES.DOSSIER.'
    return [
      { value: this.props.t(`${tKey}YES`), id: true },
      { value: this.props.t(`${tKey}NO`), id: false }]
  }

  validateDate (value, allValues, props) {
    const result = validateDate(value, props)
    return result === Object(result) ? undefined : result
  }

  exportData () {
    var tKey = 'SEARCH.CATEGORIES.FLEETREPORT.EXCEL.'
    let columnsExcel = []
    columnsExcel.push('' + '#Empty')
    columnsExcel.push(this.props.t(`${tKey}ISAGENCY`) + '#IsAgency')
    columnsExcel.push(this.props.t(`${tKey}ORDERDATE`) + '#OrderDate')
    columnsExcel.push(this.props.t('SEARCH.CATEGORIES.FLEETREPORT.MAKER_NUMBER') + '#MakerNumber')
    columnsExcel.push(this.props.t(`${tKey}RENTALORDERNUMBER`) + '#RentalOrderNumber')
    columnsExcel.push(this.props.t(`${tKey}NUMBER`) + '#Number')
    columnsExcel.push(this.props.t('SEARCH.CATEGORIES.FLEETREPORT.DOSSIER_STAGES') + '#Stage')
    columnsExcel.push(this.props.t('SEARCH.CATEGORIES.FLEETREPORT.SALESMAN') + '#SalesMan')
    columnsExcel.push(this.props.t(`${tKey}CLIENT`) + '#Client')
    columnsExcel.push(this.props.t(`${tKey}FINALCLIENT`) + '#FinalClient')
    columnsExcel.push(this.props.t(`${tKey}VEHICLEBRAND`) + '#VehicleBrand')
    columnsExcel.push(this.props.t(`${tKey}ORGANIZEDUNIT`) + '#OrganizedUnit')
    columnsExcel.push(this.props.t('SEARCH.CATEGORIES.VEHICLE.COMMERCIAL_CODE') + '#VehicleCommercialCode')
    columnsExcel.push(this.props.t(`${tKey}VEHICLEMODEL`) + '#VehicleModel')
    columnsExcel.push(this.props.t(`${tKey}VEHICLECOLOR`) + '#VehicleColor')
    columnsExcel.push(this.props.t(`${tKey}PURCHASESTATE`) + '#PurchaseState')
    columnsExcel.push(this.props.t(`${tKey}PRODUCTIONWEEK`) + '#ProductionWeek')
    columnsExcel.push(this.props.t(`${tKey}VEHICLEVINCHASSIS`) + '#VehicleVinChassis')
    columnsExcel.push(this.props.t(`${tKey}VEHICLELICENSEPLATE`) + '#VehicleLicensePlate')
    columnsExcel.push(this.props.t(`${tKey}DELIVERYCITY`) + '#DeliveryCity')
    columnsExcel.push(this.props.t(`${tKey}REALDELIVERYADDRESS`) + '#RealDeliveryAddress')
    columnsExcel.push(this.props.t(`${tKey}CUSTOMERNEEDDATE`) + '#CustomerNeedDate')
    columnsExcel.push(this.props.t(`${tKey}SHIPPINGORDERDATE`) + '#ShippingOrderDate')
    columnsExcel.push(this.props.t(`${tKey}CAMPAIGNOUTPUTDATE`) + '#CampaignOutputDate')
    columnsExcel.push(this.props.t(`${tKey}DESTINATIONARRIVALDATE`) + '#DestinationArrivalDate')
    columnsExcel.push(this.props.t(`${tKey}BRANDDISCHARGEDATE`) + '#BrandDischargeDate')

    this.props.exportToExcel(columnsExcel)
  }

  onEntityTypeChange (entityTypeId) {
    this.setState({ selectedEntityType: entityTypeId })
    if (entityTypeId === entityTypesId.recurrentEntity) {
      this.props.change('fleetReport.dniCif', null)
      this.props.change('fleetReport.entityName', null)
      this.props.actions.fetchEntitySubTypeCombo(entityTypeId)
    } else {
      this.props.change('fleetReport.entitySubTypeId', null)
      this.props.change('fleetReport.entityId', null)
    }
  }

  onEntitySubTypeChange (entitySubTypeId) {
    this.setState({ selectedEntitySubType: entitySubTypeId })
    if (entitySubTypeId) this.props.actions.fetchEntityFilterSubTypeCombo(this.state.selectedEntityType, entitySubTypeId)
  }

  getEntityNameLabel () {
    const tKey = 'SEARCH.CATEGORIES.ENTITY.'
    switch (this.state.selectedEntityType) {
      case entityTypesId.businessEntity:
        return this.props.t(`${tKey}REGISTERED_NAME`)
      default:
        return this.props.t(`${tKey}NAME`)
    }
  }

  onEntityChildTypeChange (entityChildTypeId) {
    this.setState({ selectedEntityChildType: entityChildTypeId })
    if (entityChildTypeId === entityTypesId.recurrentEntity) {
      this.props.change('fleetReport.dniCifChild', null)
      this.props.change('fleetReport.entityChildName', null)
      this.props.actions.fetchEntitySubTypeCombo(entityChildTypeId)
    } else {
      this.props.change('fleetReport.entityChildSubTypeId', null)
      this.props.change('fleetReport.entityChildId', null)
    }
  }

  onEntityChildSubTypeChange (entityChildSubTypeId) {
    this.setState({ selectedEntityChildSubType: entityChildSubTypeId })
    if (entityChildSubTypeId) this.props.actions.fetchEntityFilterSubTypeCombo(this.state.selectedEntityChildType, entityChildSubTypeId)
  }

  getEntityChildNameLabel () {
    const tKey = 'SEARCH.CATEGORIES.FLEETREPORT.'
    switch (this.state.selectedEntityChildType) {
      case entityTypesId.businessEntity:
        return this.props.t(`${tKey}REGISTERED_NAME_CHILD`)
      default:
        return this.props.t(`${tKey}ENTITY_CHILD_NAME`)
    }
  }

  removeRecurrentEntityTypeFromCombo (entityTypeCombo) {
    var entityTypeComboNoRecurrent = []
    for (const entity of entityTypeCombo) {
      if (entity.id !== entityTypesId.recurrentEntity) {
        entityTypeComboNoRecurrent.push(entity)
      }
    }
    return entityTypeComboNoRecurrent
  }

  render () {
    const {
      t, fields, fieldFill, className, classNameSelect, hasSearch, reportData, selectedFilters,
      onClickResetSearch, page, pages, count, onOrderChange, getIconClass, onPageChange,
      combos: { UOTreeCombo, entitySubTypeCombo, entityTypeCombo, entityFilterSubTypeCombo, salesmanCombo, purchaseStateLogisticCombo, stagesCombo }
    } = this.props
    const tKey = 'SEARCH.CATEGORIES.FLEETREPORT.'
    const tKeyGen = 'SEARCH.CATEGORIES.DOSSIER.'

    return (
      <div>

        {!hasSearch ? (
          <>
            <Row className='flexRow'>
              <Field
                colSm={3}
                id="orderDateFrom"
                name='fleetReport.orderDateFrom'
                placeholder={t(`${tKey}ORDER_DATE_FROM`)}
                controlLabel={t(`${tKey}ORDER_DATE_FROM`)}
                component={InputDatePicker}
                validate={[this.validateDate]}
                onInputChange={(val) => fieldFill({ orderDateFrom: val })}
                customClass={fields.orderDateFrom ? className : ''}
              />
              <Field
                colSm={3}
                id="orderDateTo"
                name='fleetReport.orderDateTo'
                placeholder={t(`${tKey}ORDER_DATE_TO`)}
                controlLabel={t(`${tKey}ORDER_DATE_TO`)}
                component={InputDatePicker}
                validate={[this.validateDate]}
                onInputChange={(val) => fieldFill({ orderDateTo: val })}
                customClass={fields.orderDateTo ? className : ''}
              />
              <Field
                colSm={3}
                id="searchMakerNumber"
                name='fleetReport.makerNumber'
                placeholder={t(`${tKey}MAKER_NUMBER`)}
                controlLabel={t(`${tKey}MAKER_NUMBER`)}
                component={InputText}
                type="text"
                onInputChange={(val) => fieldFill({ makerNumber: val })}
                customClass={fields.makerNumber ? className : ''}
              />
              <Field
                colSm={3}
                id="rentalOrderIdNumber"
                name='fleetReport.rentalOrderNumber'
                placeholder={t(`${tKey}RENTAL_ORDER_NUMBER`)}
                controlLabel={t(`${tKey}RENTAL_ORDER_NUMBER`)}
                component={InputText}
                type="text"
                onInputChange={(val) => fieldFill({ rentalOrderIdNumber: val })}
                customClass={fields.rentalOrderIdNumber ? className : ''}
              />
              <Field
                colSm={3}
                id="entityTypeId"
                name='fleetReport.entityTypeId'
                placeholder={t('SEARCH.CATEGORIES.ENTITY.ENTITY_TYPE_ID')}
                controlLabel={t('SEARCH.CATEGORIES.ENTITY.ENTITY_TYPE_ID')}
                component={InputSelect}
                options={entityTypeCombo}
                valueKey="id"
                labelKey="value"
                onInputChange={ (val) => { this.onEntityTypeChange(val); fieldFill({ entityTypeId: val }) } }
                customClass={fields.entityTypeId ? classNameSelect : ''}
              />
              {(this.state.selectedEntityType === entityTypesId.recurrentEntity) && [
                <Field
                  colSm={3}
                  id="entitySubTypeId"
                  name="fleetReport.entitySubTypeId"
                  key="fleetReport.entitySubTypeId"
                  controlLabel={t('SEARCH.CATEGORIES.ENTITY.ENTITY_SUB_TYPE_ID')}
                  placeholder={t('SEARCH.CATEGORIES.ENTITY.ENTITY_SUB_TYPE_ID')}
                  component={InputSelect}
                  options={entitySubTypeCombo}
                  valueKey="id"
                  labelKey="value"
                  onInputChange={ (val) => { this.onEntitySubTypeChange(val); fieldFill({ entitySubTypeId: val }) } }
                  customClass={fields.entitySubTypeId ? classNameSelect : ''}
                />,
                <Field
                  colSm={3}
                  id="entityId"
                  name="fleetReport.entityId"
                  key="fleetReport.entityId"
                  controlLabel={t('SEARCH.CATEGORIES.ENTITY.ENTITY_ID')}
                  placeholder={t('SEARCH.CATEGORIES.ENTITY.ENTITY_ID')}
                  component={InputSelect}
                  options={entityFilterSubTypeCombo}
                  valueKey="id"
                  labelKey="value"
                  disabled={!this.state.selectedEntitySubType}
                  onInputChange={ (val) => fieldFill({ entityId: val }) }
                  customClass={fields.entityId ? classNameSelect : ''}
                />
              ]}
              {(this.state.selectedEntityType !== entityTypesId.recurrentEntity) && [
                <Field
                  colSm={3}
                  id="dniCif"
                  name='fleetReport.dniCif'
                  key='fleetReport.dniCif'
                  placeholder={t('SEARCH.CATEGORIES.ENTITY.DNI_CIF')}
                  controlLabel={t('SEARCH.CATEGORIES.ENTITY.DNI_CIF')}
                  component={InputText}
                  type="text"
                  onInputChange={(val) => fieldFill({ dniCif: val })}
                  customClass={fields.dniCif ? className : ''}
                />,
                <Field
                  colSm={3}
                  id="entityName"
                  name='fleetReport.entityName'
                  key='fleetReport.entityName'
                  placeholder={this.getEntityNameLabel()}
                  controlLabel={this.getEntityNameLabel()}
                  component={InputText}
                  type="text"
                  onInputChange={(val) => fieldFill({ clientName: val })}
                  customClass={fields.clientName ? className : ''}
                />
              ]}

              <Field
                colSm={3}
                id="entityChildTypeId"
                name='fleetReport.entityChildTypeId'
                placeholder={t(`${tKey}ENTITY_CHILD_TYPE_ID`)}
                controlLabel={t(`${tKey}ENTITY_CHILD_TYPE_ID`)}
                component={InputSelect}
                options={this.removeRecurrentEntityTypeFromCombo(
                  entityTypeCombo
                )}
                valueKey="id"
                labelKey="value"
                onInputChange={ (val) => { this.onEntityChildTypeChange(val); fieldFill({ entityChildTypeId: val }) } }
                customClass={fields.entityChildTypeId ? classNameSelect : ''}
              />
              {(this.state.selectedEntityChildType === entityTypesId.recurrentEntity) && [
                <Field
                  colSm={3}
                  id="entityChildSubTypeId"
                  name="fleetReport.entityChildSubTypeId"
                  key="fleetReport.entityChildSubTypeId"
                  controlLabel={t(`${tKey}ENTITY_CHILD_SUB_TYPE_ID`)}
                  placeholder={t(`${tKey}ENTITY_CHILD_SUB_TYPE_ID`)}
                  component={InputSelect}
                  options={entitySubTypeCombo}
                  valueKey="id"
                  labelKey="value"
                  onInputChange={ (val) => { this.onEntityChildSubTypeChange(val); fieldFill({ entityChildSubTypeId: val }) } }
                  customClass={fields.entityChildSubTypeId ? classNameSelect : ''}
                />,
                <Field
                  colSm={3}
                  id="entityChildId"
                  name="fleetReport.entityChildId"
                  key="fleetReport.entityChildId"
                  controlLabel={t(`${tKey}ENTITY_CHILD_ID`)}
                  placeholder={t(`${tKey}ENTITY_CHILD_ID`)}
                  component={InputSelect}
                  options={entityFilterSubTypeCombo}
                  valueKey="id"
                  labelKey="value"
                  disabled={!this.state.selectedEntityChildSubType}
                  onInputChange={ (val) => fieldFill({ entityChildId: val }) }
                  customClass={fields.entityChildId ? classNameSelect : ''}
                />
              ]}
              {(this.state.selectedEntityChildType !== entityTypesId.recurrentEntity) && [
                <Field
                  colSm={3}
                  id="dniCifChild"
                  name='fleetReport.dniCifChild'
                  key='fleetReport.dniCifChild'
                  placeholder={t(`${tKey}DNI_CIF_CHILD`)}
                  controlLabel={t(`${tKey}DNI_CIF_CHILD`)}
                  component={InputText}
                  type="text"
                  onInputChange={(val) => fieldFill({ dniCifChild: val })}
                  customClass={fields.dniCifChild ? className : ''}
                />,
                <Field
                  colSm={3}
                  id="entityChildName"
                  name='fleetReport.entityChildName'
                  key='fleetReport.entityChildName'
                  placeholder={this.getEntityChildNameLabel()}
                  controlLabel={this.getEntityChildNameLabel()}
                  component={InputText}
                  type="text"
                  onInputChange={(val) => fieldFill({ clientChildName: val })}
                  customClass={fields.clientChildName ? className : ''}
                />
              ]}

              <Field
                colSm={3}
                id="commercial_code"
                name='fleetReport.commercialCode'
                placeholder={t('SEARCH.CATEGORIES.VEHICLE.COMMERCIAL_CODE')}
                controlLabel={t('SEARCH.CATEGORIES.VEHICLE.COMMERCIAL_CODE')}
                component={InputText}
                type="text"
                onInputChange={(val) => fieldFill({ commercial_code: val })}
                customClass={fields.commercial_code ? className : ''}
              />
              <Field
                id="organizedUnits"
                name="fleetReport.organizedUnits"
                placeholder={t(`${tKey}ORGANIZED_UNIT`)}
                controlLabel={t(`${tKey}ORGANIZED_UNIT`)}
                component={InputTreeSelect}
                multi={true}
                valueKey="value"
                labelKey="label"
                childrenKey="children"
                options={UOTreeCombo}
                onInputChange={(val) => { fieldFill({ organizedUnits: val }) } }
                customClass={fields.organizedUnits ? classNameSelect : ''}
                colSm={3}
              />
              <Field
                id="salesman"
                name='fleetReport.salesman'
                placeholder={t(`${tKey}SALESMAN`)}
                controlLabel={t(`${tKey}SALESMAN`)}
                component={InputSelect}
                options={salesmanCombo}
                multi={true}
                valueKey="id"
                labelKey="value"
                onInputChange={(val) => fieldFill({ salesman: val })}
                customClass={fields.salesman ? classNameSelect : ''}
              />
              <Field
                colSm={3}
                id="purchaseState"
                name='fleetReport.purchaseState'
                placeholder={t(`${tKey}PURCHASE_STATE`)}
                controlLabel={t(`${tKey}PURCHASE_STATE`)}
                component={InputSelect}
                valueKey="value"
                labelKey="value"
                options={purchaseStateLogisticCombo}
                onInputChange={(val) => fieldFill({ purchaseState: val })}
                customClass={fields.purchaseState ? className : ''}
              />
              <Field
                colSm={3}
                id="productionWeek"
                name='fleetReport.productionWeek'
                placeholder={t(`${tKey}PRODUCTION_WEEK`)}
                controlLabel={t(`${tKey}PRODUCTION_WEEK`)}
                component={InputNumber}
                onInputChange={(val) => fieldFill({ productionWeek: val })}
                customClass={fields.productionWeek ? className : ''}
              />
              <Field
                id="deliveryCity"
                name='fleetReport.deliveryCity'
                placeholder={t(`${tKey}DELIVERY_CITY`)}
                controlLabel={t(`${tKey}DELIVERY_CITY`)}
                component={InputText}
                type="text"
                onInputChange={(val) => fieldFill({ deliveryCity: val })}
                customClass={fields.deliveryCity ? className : ''}
              />
              <Field
                id="dossierStages"
                name='fleetReport.dossierStages'
                placeholder={t(`${tKey}DOSSIER_STAGES`)}
                controlLabel={t(`${tKey}DOSSIER_STAGES`)}
                component={InputSelect}
                multi
                menuContainerStyle={{ zIndex: 999 }}
                options={stagesCombo}
                valueKey="id"
                labelKey="value"
                disabled={stagesCombo.length === 0}
                onInputChange={(val) => fieldFill({ dossierStages: val })}
                customClass={fields.dossierStages ? classNameSelect : ''}
              />
              <Field
                id="licensePlateFilled"
                name='fleetReport.licensePlateFilled'
                placeholder={t(`${tKey}LICENSE_PLATE_FILLED`)}
                controlLabel={t(`${tKey}LICENSE_PLATE_FILLED`)}
                component={InputSelect}
                options={this.getYesNoComboOptions()}
                multi={false}
                valueKey="id"
                labelKey="value"
                onInputChange={(val) => fieldFill({ licensePlateFilled: val })}
                customClass={fields.licensePlateFilled ? classNameSelect : ''}
              />
              <Field
                id="isAgencyModel"
                name='fleetReport.isAgencyModel'
                placeholder={t(`${tKey}IS_AGENCY_MODEL`)}
                controlLabel={t(`${tKey}IS_AGENCY_MODEL`)}
                component={InputSelect}
                options={this.getYesNoComboOptions()}
                multi={false}
                valueKey="id"
                labelKey="value"
                onInputChange={(val) => fieldFill({ isAgencyModel: val })}
                customClass={fields.isAgencyModel ? classNameSelect : ''}
              />
              <Field
                id="brandDischargeDateFrom"
                name='fleetReport.brandDischargeDateFrom'
                placeholder={t(`${tKey}BRAND_DISCHARGE_DATE_FROM`)}
                controlLabel={t(`${tKey}BRAND_DISCHARGE_DATE_FROM`)}
                component={InputDatePicker}
                validate={[this.validateDate]}
                onInputChange={(val) => fieldFill({ brandDischargeDateFrom: val })}
                customClass={fields.brandDischargeDateFrom ? className : ''}
              />
              <Field
                id="brandDischargeDateTo"
                name='fleetReport.brandDischargeDateTo'
                placeholder={t(`${tKey}BRAND_DISCHARGE_DATE_TO`)}
                controlLabel={t(`${tKey}BRAND_DISCHARGE_DATE_TO`)}
                component={InputDatePicker}
                validate={[this.validateDate]}
                onInputChange={(val) => fieldFill({ brandDischargeDateTo: val })}
                customClass={fields.brandDischargeDateTo ? className : ''}
              />
              <Field
                id="customerNeedDateFrom"
                name='fleetReport.customerNeedDateFrom'
                placeholder={t(`${tKey}CUSTOMER_NEED_DATE_FROM`)}
                controlLabel={t(`${tKey}CUSTOMER_NEED_DATE_FROM`)}
                component={InputDatePicker}
                validate={[this.validateDate]}
                onInputChange={(val) => fieldFill({ customerNeedDateFrom: val })}
                customClass={fields.customerNeedDateFrom ? className : ''}
              />
              <Field
                id="customerNeedDateTo"
                name='fleetReport.customerNeedDateTo'
                placeholder={t(`${tKey}CUSTOMER_NEED_DATE_TO`)}
                controlLabel={t(`${tKey}CUSTOMER_NEED_DATE_TO`)}
                component={InputDatePicker}
                validate={[this.validateDate]}
                onInputChange={(val) => fieldFill({ customerNeedDateTo: val })}
                customClass={fields.customerNeedDateTo ? className : ''}
              />
              <Field
                id="shippingOrderDateFrom"
                name='fleetReport.shippingOrderDateFrom'
                placeholder={t(`${tKey}SHIPPING_ORDER_DATE_FROM`)}
                controlLabel={t(`${tKey}SHIPPING_ORDER_DATE_FROM`)}
                component={InputDatePicker}
                validate={[this.validateDate]}
                onInputChange={(val) => fieldFill({ shippingOrderDateFrom: val })}
                customClass={fields.shippingOrderDateFrom ? className : ''}
              />
              <Field
                id="shippingOrderDateTo"
                name='fleetReport.shippingOrderDateTo'
                placeholder={t(`${tKey}SHIPPING_ORDER_DATE_TO`)}
                controlLabel={t(`${tKey}SHIPPING_ORDER_DATE_TO`)}
                component={InputDatePicker}
                validate={[this.validateDate]}
                onInputChange={(val) => fieldFill({ shippingOrderDateTo: val })}
                customClass={fields.shippingOrderDateTo ? className : ''}
              />
              <Field
                id="campaignOutputDateFrom"
                name='fleetReport.campaignOutputDateFrom'
                placeholder={t(`${tKey}CAMPAIGN_OUTPUT_DATE_FROM`)}
                controlLabel={t(`${tKey}CAMPAIGN_OUTPUT_DATE_FROM`)}
                component={InputDatePicker}
                validate={[this.validateDate]}
                onInputChange={(val) => fieldFill({ campaignOutputDateFrom: val })}
                customClass={fields.campaignOutputDateFrom ? className : ''}
              />
              <Field
                id="campaignOutputDateTo"
                name='fleetReport.campaignOutputDateTo'
                placeholder={t(`${tKey}CAMPAIGN_OUTPUT_DATE_TO`)}
                controlLabel={t(`${tKey}CAMPAIGN_OUTPUT_DATE_TO`)}
                component={InputDatePicker}
                validate={[this.validateDate]}
                onInputChange={(val) => fieldFill({ campaignOutputDateTo: val })}
                customClass={fields.campaignOutputDateTo ? className : ''}
              />
              <Field
                id="destinationArrivalDateFrom"
                name='fleetReport.destinationArrivalDateFrom'
                placeholder={t(`${tKey}DESTINATION_ARRIVAL_DATE_FROM`)}
                controlLabel={t(`${tKey}DESTINATION_ARRIVAL_DATE_FROM`)}
                component={InputDatePicker}
                validate={[this.validateDate]}
                onInputChange={(val) => fieldFill({ destinationArrivalDateFrom: val })}
                customClass={fields.destinationArrivalDateFrom ? className : ''}
              />
              <Field
                id="destinationArrivalDateTo"
                name='fleetReport.destinationArrivalDateTo'
                placeholder={t(`${tKey}DESTINATION_ARRIVAL_DATE_TO`)}
                controlLabel={t(`${tKey}DESTINATION_ARRIVAL_DATE_TO`)}
                component={InputDatePicker}
                validate={[this.validateDate]}
                onInputChange={(val) => fieldFill({ destinationArrivalDateTo: val })}
                customClass={fields.destinationArrivalDateTo ? className : ''}
              />
            </Row>
          </>
        ) : ([
          <Row className="filters-selected" key="filters-selected">
            <ul className="list-inline pull-left">
              {selectedFilters().length === 0 ? <li>{t(`${tKeyGen}NO_SELECTED_FILTERS`)}</li> : (selectedFilters())}
            </ul>
            <Clearfix />
            <Button
              onClick={(event) => onClickResetSearch(event)}
              bsStyle="default"
              bsSize="small"
              className="pull-left"
              id='searchButtonFleetReport'
            >
              {t('SEARCH.ACTIONS.MODIFY_SEARCH')}
              <i className="ico-edit-turquoise" />
            </Button>
            {reportData && reportData.length > 0 &&
            <Button
              onClick={(event) => this.exportData(event)}
              bsStyle="default"
              bsSize="small"
              id='exportButtonFleetReport'>
              {t('SEARCH.ACTIONS.EXPORT')}
              <i className="ico-if_Download_1031520" />
            </Button>
            }
          </Row>,
          <div className="table-wrapper" key="table-wrapper">
            <SimpleTablePage
              columns={[
                <th key={0} data-field="isAgencyModel" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}M_A`)}&nbsp;<i className={getIconClass('isAgencyModel')}/>
                </th>,
                <th key={1} data-field="orderDate" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}ORDER_DATE`)}&nbsp;<i className={getIconClass('orderDate')}/>
                </th>,
                <th key={2} data-field="makerNumber" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}MAKER_NUMBER`)}&nbsp;<i className={getIconClass('makerNumber')}/>
                </th>,
                <th key={3} data-field="rentalOrderNumber" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}RENTAL_ORDER_NUMBER`)}&nbsp;<i className={getIconClass('rentalOrderNumber')}/>
                </th>,
                <th key={15} data-field="number" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}NUMBER`)}&nbsp;<i className={getIconClass('number')}/>
                </th>,
                <th key={4} data-field="organizedUnit" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}ORGANIZED_UNIT`)}&nbsp;<i className={getIconClass('organizedUnit')}/>
                </th>,
                <th key={5} data-field="salesMan" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}SALESMAN`)}&nbsp;<i className={getIconClass('salesMan')}/>
                </th>,
                <th key={6} data-field="entity" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}CLIENT`)}
                </th>,
                <th key={8} data-field="finalEntity" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}FINAL_CLIENT`)}
                </th>,
                <th key={9} data-field="vehicle" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}VEHICLE`)}
                </th>,
                <th key={10} data-field="purchaseState" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}PURCHASE_STATE`)}&nbsp;<i className={getIconClass('purchaseState')}/>
                </th>,
                <th key={11} data-field="productionWeek" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}PRODUCTION_WEEK`)}&nbsp;<i className={getIconClass('productionWeek')}/>
                </th>,
                <th key={12} data-field="deliveryCity" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}DELIVERY_CITY`)}&nbsp;<i className={getIconClass('deliveryCity')}/>
                </th>,
                <th key={13} data-field="realDeliveryAddress" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}REAL_DELIVERY_ADDRESS`)}&nbsp;<i className={getIconClass('realDeliveryAddress')}/>
                </th>,
                <th key={14} data-field="brandDischargeDate" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}BRAND_DISCHARGE_DATE`)}&nbsp;<i className={getIconClass('brandDischargeDate')}/>
                </th>
              ]}
              rows={reportData && reportData.map((row, idx) => (
                <tr key={idx}>
                  <td>
                    {row.isAgencyModel ? <i className="iDocIcon-sign-out-user-menu _green"></i> : null}
                  </td>
                  <td>
                    {row.orderDate}
                  </td>
                  <td>
                    {row.makerNumber}
                  </td>
                  <td>
                    {row.rentalOrderNumber}
                  </td>
                  <td className="see-more">
                    <DossierPopover
                      t={t}
                      dossier={row}
                      half={(reportData?.length || 1) / (idx + 1)}
                      idx={idx}
                      id={'popover-dossier' + idx}
                    />
                  </td>
                  <td>{row.organizedUnit}</td>
                  <td>{row.salesMan}</td>
                  <td className="see-more">
                    <EntityPopover
                      t={t}
                      entity={row.entity}
                      half={(reportData?.length || 1) / (idx + 1)}
                      idx={idx}
                      id={'popover-entity' + idx}
                    />
                  </td>
                  <td className="see-more">
                    <EntityPopover
                      t={t}
                      entity={row.finalEntity}
                      half={(reportData?.length || 1) / (idx + 1)}
                      idx={idx}
                      id={'popover-final-entity' + idx}
                    />
                  </td>
                  <td className="see-more">
                    <VehiclePopover
                      t={t}
                      vehicle={row.vehicle}
                      half={(reportData?.length || 1) / (idx + 1)}
                      idx={idx}
                      id={'popover-vehicle' + idx}
                      isHeader = {true}
                      dossierSubTypeId={dossierSubTypeId.venta_vn}
                    />
                  </td>
                  <td>{row.purchaseState}</td>
                  <td>{row.productionWeek}</td>
                  <td>{row.deliveryCity}</td>
                  <td>{row.realDeliveryAddress}</td>
                  <td className="see-more">
                    <FinderFleetReportDatesPopover
                      t={t}
                      dates={row.finderFleetReportDates}
                      half={(reportData?.length || 1) / (idx + 1)}
                      idx={idx}
                      id={'popover-finderFleetReportDates' + idx}
                      isHeader = {true}
                    />
                  </td>
                </tr>
              ))}
            />
          </div>,
          <div className="search-footer" key="search-footer">
            <IDocCarPagination
              id="btn_pag_sales"
              page={page}
              pagesCount={pages}
              totalRows= {count}
              onSelectAction={(page) => onPageChange(page)}
            />
          </div>
        ])}
      </div>
    )
  }
}

export default SearchByFleetReport
