import apiFetch from '../apiFetch'

export default function (extraSaleId, extraSaleValues, uoId, token) {
  const body = {
    organizedUnitId: uoId,
    extraSaleTypeId: extraSaleId,
    isDeleted: false,
    extraSaleTypeLanguages: extraSaleValues
  }
  return apiFetch({ endPoint: `ExtraSaleType/${extraSaleId}`, method: 'PUT', body: body, token: token })
}
