import conditionsMaintenanceActionTypes from '../../constants/actions/conditionsMaintenance/conditionsMaintenance'

function initialState () {
  return {
    activeStage: null,
    conditionDocumental: [],
    conditionNoDocumental: [],
    marginTypesCombo: [],
    conditionsMaintenanceModal: {
      showModal: false,
      isConditionDocumental: false,
      conditionId: null,
      data: null
    },
    conditionDocumentalCampaign: [],
    documentsConditionDocumentalCampaign: [],
    functionsToAddDocumentalCampaign: []
  }
}

function fetchStagesConditionsSuccess (state, { stages }) {
  return {
    ...state,
    stages
  }
}

function setActiveStageSuccess (state, { activeStage, conditionDocumental, conditionNoDocumental, conditionNoDocumentalOther }) {
  return {
    ...state,
    activeStage,
    conditionDocumental,
    conditionNoDocumental,
    conditionNoDocumentalOther
  }
}

function openAddConditionsModalSuccess (state, { isConditionDocumental, activeStage, conditionNoDocumentalType }) {
  return {
    ...state,
    conditionsMaintenanceModal: {
      showModal: true,
      isConditionDocumental,
      activeStage,
      conditionNoDocumentalType
    }
  }
}

function openEditConditionsModalSuccess (state, { isConditionDocumental, conditionId, data, conditionNoDocumentalType }) {
  return {
    ...state,
    conditionsMaintenanceModal: {
      showModal: true,
      isConditionDocumental,
      conditionId,
      data,
      conditionNoDocumentalType
    }
  }
}

function closeAddOrEditConditionsModalSuccess (state) {
  return {
    ...state,
    conditionsMaintenanceModal: {
      showModal: false
    }
  }
}

function updateConditionsMaintenanceFilter (state, { filter }) {
  return {
    ...state,
    conditionsMaintenanceFilter: filter,
    activeStage: null
  }
}

export function fetchConditionDocumentalCampaignSucces (state, { data }) {
  return {
    ...state,
    conditionDocumentalCampaign: data
  }
}

export function getDocumentsConditionDocumentalcampaignSucces (state, { data }) {
  return {
    ...state,
    documentsConditionDocumentalCampaign: data
  }
}

export function getFunctionsToAddDocumentalCampaignSucces (state, { data }) {
  return {
    ...state,
    functionsToAddDocumentalCampaign: data
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case conditionsMaintenanceActionTypes.FETCH_STAGES_CONDITIONS_SUCCESS:
      return fetchStagesConditionsSuccess(state, action)
    case conditionsMaintenanceActionTypes.SET_ACTIVE_STAGE_SUCCESS:
      return setActiveStageSuccess(state, action)
    case conditionsMaintenanceActionTypes.OPEN_ADD_CONDITIONS_MODAL_SUCCESS:
      return openAddConditionsModalSuccess(state, action)
    case conditionsMaintenanceActionTypes.OPEN_EDIT_CONDITIONS_MODAL_SUCCESS:
      return openEditConditionsModalSuccess(state, action)
    case conditionsMaintenanceActionTypes.CLOSE_ADD_OR_EDIT_CONDITIONS_MODAL_SUCCESS:
      return closeAddOrEditConditionsModalSuccess(state, action)
    case conditionsMaintenanceActionTypes.UPDATE_CONDITION_MAINTENANCE_FILTER:
      return updateConditionsMaintenanceFilter(state, action)
    case conditionsMaintenanceActionTypes.FETCH_CONDITION_DOCUMENTAL_CAMPAIGN_SUCCESS:
      return fetchConditionDocumentalCampaignSucces(state, action)
    case conditionsMaintenanceActionTypes.GET_DOCUMENTS_CONDITION_DOCUMENTAL_CAMPAIGN_SUCCESS:
      return getDocumentsConditionDocumentalcampaignSucces(state, action)
    case conditionsMaintenanceActionTypes.GET_FUNTIONTOADD_DOCUMENTAL_CAMPAIGN_SUCCES:
      return getFunctionsToAddDocumentalCampaignSucces(state, action)
    default:
      return state
  }
}
