import React, { Component } from 'react'
import { Popover, Overlay, Glyphicon } from 'react-bootstrap'

export default class CampaignsPopover extends Component {
  constructor () {
    super()
    this.state = {
      showPopover: false,
      target: this
    }
  }

  handleClick (e) {
    e.stopPropagation()
    this.setState({ target: e.target, showPopover: true })
  }

  handleOnMouseEnter (e) {
    e.stopPropagation()
    this.setState({ target: e.target, showPopover: true })
  }

  handleOnMouseLeave (e) {
    e.stopPropagation()
    this.setState({ target: e.target, showPopover: false })
  }

  render () {
    const {
      id,
      text,
      t
    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.CAMPAIGN_DOSSIER.CAMPAIGN_POPOVER.'
    return (
      <div>
        <div>
          {text + ' '}
          <Glyphicon glyph="info-sign" style={{ cursor: 'pointer' }} onMouseEnter={this.handleOnMouseEnter.bind(this)} onMouseLeave={this.handleOnMouseLeave.bind(this)}/>
        </div>
        <Overlay
          show={this.state.showPopover}
          target={this.state.target}
          onHide={() => this.setState({ showPopover: false })}
          rootClose
        >
          <Popover id={id} className="cost-popover-container">
            <div className="cost-popover-item">
              <label><b>{t(`${tKey}FIRST_QUESTION`)}</b></label><br/>
              {t(`${tKey}FIRST_ANSWER`)}
            </div>
            <div className="cost-popover-item">
              <label><b>{t(`${tKey}SECOND_QUESTION`)}</b></label><br/>
              {t(`${tKey}SECOND_ANSWER`)}
            </div>
          </Popover>

        </Overlay>
      </div>
    )
  }
}
