import apiFetch from '../apiFetch'

export default function (token, finderParams) {
  var body = JSON.parse(JSON.stringify(finderParams))
  if (body.organizedUnits) {
    body.organizedUnits = finderParams.organizedUnits.split(',').map(Number)
  }

  if (body.orderBy) {
    body.OrderDirection = 'ASC'
    if (body.orderBy.startsWith('%2B')) {
      body.orderBy = body.orderBy.slice(3)
    } else if (body.orderBy.startsWith('-')) {
      body.orderBy = body.orderBy.slice(1)
      body.OrderDirection = 'DESC'
    }
  }
  return apiFetch({
    endPoint: 'DossierFinder/DossierFleetReportFind',
    method: 'POST',
    body: body,
    token: token
  })
}
