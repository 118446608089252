import uacActionTypes from '../../../constants/actions/dossier/common/uac'

export function chooseUACSuggestion (purchaseId, vehicle, closeSuggestionModalFunc, closeUACModalFunc, dossierType) {
  return {
    type: uacActionTypes.CHOOSE_UAC_SUGGESTION,
    purchaseId,
    vehicle,
    closeSuggestionModalFunc,
    closeUACModalFunc,
    dossierType
  }
}

export function createUAC (uac, closeModalFunc, openVehicleListModalFn, vehicleId, dossierType) {
  return {
    type: uacActionTypes.CREATE_UAC,
    uac,
    closeModalFunc,
    openVehicleListModalFn,
    vehicleId,
    dossierType
  }
}

export function deleteUACUnit (purchaseId, dossierType) {
  return {
    type: uacActionTypes.DELETE_UAC_UNIT,
    purchaseId,
    dossierType
  }
}

export function fetchUACClientFromDocument (entityId) {
  return {
    type: uacActionTypes.FETCH_UAC_CLIENT_FROM_DOCUMENT,
    entityId
  }
}

export function fetchUACSuccess (UACList) {
  return {
    type: uacActionTypes.FETCH_UAC_SUCCESS,
    UACList
  }
}

export function fetchUACUnitEdition (purchaseId, openUACEditionFn, dossierType) {
  return {
    type: uacActionTypes.FETCH_UAC_UNIT_EDITION,
    purchaseId,
    openUACEditionFn,
    dossierType
  }
}

export function updateUAC (uac, closeModalFunc, openVehicleListModalFn, dossierType) {
  return {
    type: uacActionTypes.UPDATE_UAC,
    uac,
    closeModalFunc,
    openVehicleListModalFn,
    dossierType
  }
}

export default {
  fetchUACUnitEdition,
  updateUAC,
  createUAC,
  deleteUACUnit,
  chooseUACSuggestion,
  fetchUACSuccess,
  fetchUACClientFromDocument
}
