import { put, call, takeEvery, select } from 'redux-saga/effects'
import { reset } from 'redux-form'
import { yesNoModal } from '../../modalsListeners/yesNoModal'
import { getAuth } from '../../../selectors/access/auth'
import dossierActionsTypes from '../../../constants/actions/dossier/dossier'
import { dossierTypes as dossierTypesConstant } from '../../../constants/dossier/common/dossierTypes'
import messageModalActions from '../../../actions/modals/messageModal'
import { fetchSalesDossierDependants } from '../../../actions/dossier/sales'
import { showLoader, hideLoader } from '../../../actions/common'
import putDossierService from '../../../services/dossierService/putDossierService'
import postDossierService from '../../../services/dossierService/postDossierService'
import deleteDossierService from '../../../services/dossierService/deleteDossierService'
import { handleError } from '../../errors/errorManager'
import { initializeDossier } from '../commonDossierSagas'

export function * createService ({ newService, resolve, reject }) {
  try {
    const salesDossier = yield select(state => state.salesDossier)
    const showAlertSendPending = salesDossier.showAlertSendPending
    const dossierId = salesDossier.dossierId
    const auth = yield select(getAuth)
    if (showAlertSendPending) {
      const confirmed = yield call(yesNoModal, 'editDossierAlert')
      if (confirmed) {
        yield put(showLoader())
        yield call(postDossierService, dossierId, newService, auth.token, salesDossier.lastAccessDate)
        yield put(messageModalActions.openSuccessMessageModal('MESSAGES.POST_TABLE_ITEM_SUCCESS'))
        yield call(resolve)
        yield put(fetchSalesDossierDependants(dossierId))
      } else {
        yield call(reject, 'declined')
      }
    } else {
      yield put(showLoader())
      yield call(postDossierService, dossierId, newService, auth.token, salesDossier.lastAccessDate)
      yield put(messageModalActions.openSuccessMessageModal('MESSAGES.POST_TABLE_ITEM_SUCCESS'))
      yield put(fetchSalesDossierDependants(dossierId))
      yield call(resolve)
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          const salesDossierState = yield select(state => state.salesDossier)
          yield call(initializeDossier, dossierTypesConstant.sales, salesDossierState)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('MESSAGES.POST_TABLE_ITEM_ERROR', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
      yield call(reject, customHandlerError)
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchCreateService () {
  yield takeEvery(dossierActionsTypes.CREATE_SERVICE, createService)
}

export function * deleteService ({ service }) {
  try {
    const salesDossier = yield select(state => state.salesDossier)
    const showAlertSendPending = salesDossier.showAlertSendPending
    const dossierId = salesDossier.dossierId
    const auth = yield select(getAuth)
    if (showAlertSendPending) {
      const confirmed = yield call(yesNoModal, 'editDossierAlert')
      if (confirmed) {
        yield put(showLoader())
        yield call(deleteDossierService, service.serviceId, auth.token, salesDossier.lastAccessDate)
        yield put(fetchSalesDossierDependants(dossierId))
      }
    } else {
      yield put(showLoader())
      yield call(deleteDossierService, service.serviceId, auth.token, salesDossier.lastAccessDate)
      yield put(fetchSalesDossierDependants(dossierId))
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          const salesDossierState = yield select(state => state.salesDossier)
          yield call(initializeDossier, dossierTypesConstant.sales, salesDossierState)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDeleteService () {
  yield takeEvery(dossierActionsTypes.DELETE_SERVICE, deleteService)
}

export function * updateService ({ service, resolve, reject }) {
  try {
    const salesDossier = yield select(state => state.salesDossier)
    const showAlertSendPending = salesDossier.showAlertSendPending
    const dossierId = salesDossier.dossierId
    const auth = yield select(getAuth)
    if (showAlertSendPending) {
      const confirmed = yield call(yesNoModal, 'editDossierAlert')
      if (confirmed) {
        yield put(showLoader())
        yield call(putDossierService, service.serviceId, service, auth.token, salesDossier.lastAccessDate)
        yield call(resolve)
        yield put(fetchSalesDossierDependants(dossierId))
      } else {
        yield call(reject, 'declined')
      }
    } else {
      yield put(showLoader())
      yield call(putDossierService, service.serviceId, service, auth.token, salesDossier.lastAccessDate)
      yield put(fetchSalesDossierDependants(dossierId))
      yield call(resolve)
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          const salesDossierState = yield select(state => state.salesDossier)
          yield call(initializeDossier, dossierTypesConstant.sales, salesDossierState)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
      yield call(reject, customHandlerError)
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchUpdateService () {
  yield takeEvery(dossierActionsTypes.UPDATE_SERVICE, updateService)
}
