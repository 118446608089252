import React, { PureComponent } from 'react'
import { Glyphicon, Row, Col, Button, Table, FormControl, Pagination } from 'react-bootstrap'
import UnitsModal from './unitsModal/UnitsModal'
import Select from 'react-select'
import BubbleMeter from '../commons/BubbleMeter'

class Units extends PureComponent {
  componentDidMount () {
    this.props.actions.initializeUnitPage(this.props.filter)
  }

  render () {
    const {
      t, companyList, unitList, filter = {}, pagesCount, unitModalIsOpen,
      actions: { changeFilter, changeFilterString, openUnitModal, deleteUnit }
    } = this.props
    // TODO brands and locations should come from back service
    const brands = ['Kia', 'Renault', 'Ford']
    const locations = ['Madrid', 'Barcelona']
    const getSortIcon = (column = '') => {
      switch (filter.orderBy) {
        case column:
          return 'sort-bottom'
        case '-' + column:
          return 'sort-top'
        default:
          return 'sort-both'
      }
    }
    return (
      <Col sm={12} className="units-wrapper">
        <Row className="units-header">
          <div className="title-user">
            <i className="ico-uo"/>
            <h2>{t('UNITS.TITLE')}</h2>
          </div>
          <div className="btn-wrapper">
            <Button id="" className="btn-standard" onClick={ () => openUnitModal()}>
              <i className="ico-plus"/>{t('UNITS.NEW_UNIT')}
            </Button>
          </div>
        </Row>
        <Row className="search-wrapper-units">
          <Col sm={4}>
            <FormControl
              id='txt_find_unit'
              name='searchInput'
              icon='form-input-lupa'
              placeholder={t('UNITS.UNIT_NAME')}
              onChange={ element => changeFilterString({ ...filter, unitName: element.target.value, page: 1 })}
            />
          </Col>
          <Col sm={4}>
            <Select
              id='company'
              name='company'
              placeholder={t('UNITS.COMPANY')}
              options={companyList}
              value={filter.company}
              onChange={(valueSelected) => { changeFilter({ ...filter, company: valueSelected, page: 1 }) }}
              labelKey={'name'}
              valueKey={'companyId'}
            />
          </Col>
        </Row>
        <Row className="table-wrapper">
          <Table responsive>
            <thead>
              <tr>
                <th>
                  <Button id="btn_sort_by_user" type="button" bsClass="btn-table" data-field="name" onClick={(event) => {
                    if (filter.orderBy !== null && !filter.orderBy.includes('name')) {
                      changeFilter({ ...filter, orderBy: null })
                    }
                    if (filter.orderBy === null || !filter.orderBy.includes('-')) {
                      changeFilter({ ...filter, orderBy: '-' + event.currentTarget.dataset.field })
                    } else {
                      changeFilter({ ...filter, orderBy: event.currentTarget.dataset.field })
                    }
                  }}>
                    <span className="v-middle">{t('UNITS.UNIT_NAME_SHORT')}</span>
                    <Glyphicon bsClass='iconsprite' glyph={getSortIcon('name')}/>
                  </Button>
                </th>
                <th>{t('UNITS.BRAND')}</th>
                <th>{t('UNITS.LOCATION')}</th>
                <th/>
                <th/>
              </tr>
            </thead>
            <tbody>
              {
                unitList.map((unit, idx) => (
                  <tr key={idx}>
                    <td>
                      <p>{unit.name}</p>
                      <ul className="uo-list">
                        {
                          unit.companies.map((company, idx2) => (
                            <li key={idx2}>{company.name}</li>
                          ))
                        }
                      </ul>
                    </td>
                    <td>
                      <span>{brands && brands[0]}</span>
                      {brands && brands.length > 0 && <BubbleMeter listItems={brands}/>}
                    </td>
                    <td>
                      <span>{locations && locations[0]}</span>
                      {locations && locations.length > 0 && <BubbleMeter listItems={locations}/>}
                    </td>
                    <td>
                      <i className="ico-edit-white" onClick={() => openUnitModal(unit)}/>
                      <i className="ico-trash" onClick={() => deleteUnit(unit)}/>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </Table>
        </Row>
        <div className="section-users-footer">
          <nav key={1} className="text-center">
            <Pagination
              id="btn_pag_users"
              activePage={filter.page}
              prev
              next
              items={pagesCount}
              maxButtons={pagesCount}
              onSelect={(value) => changeFilter({ ...filter, page: value })}
            />
          </nav>
        </div>
        <UnitsModal
          showModal={unitModalIsOpen}
          props={this.props}
        />
      </Col>
    )
  }
}

export default Units
