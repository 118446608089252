
import React, { PureComponent } from 'react'
import { Field } from 'redux-form'
import { Button, Modal, Col, Row } from 'react-bootstrap'
import InputMoney from '../../../commons/form/InputMoney'
import InputSelect from '../../../commons/form/InputSelect'
import { iemValues } from '../../../../constants/backendIds'
import InputText from '../../../commons/form/InputText'

class EconomicPlanProcessingAgencyModal extends PureComponent {
  constructor (props) {
    super()
    this.state = {
      amount: 0,
      amountImc: 0,
      taxquantity: 0,
      originPristine: true
    }
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (this.props.showModal && !prevProps.showModal) {
      let taxvalue
      let pristineBeforeOpen = this.props.pristine
      this.props.ivaTypeCombo.filter((value) => {
        if ((this.props.valueIva) === value.id) {
          taxvalue = value.amountValue
        }
      })

      this.props.iemTypeCombo.filter((value) => {
        if ((this.props.valueIem) === value.id) {
          if (this.props.valueIem === iemValues.other && value.amountValue === 0 && this.props.amountIem) {
            taxvalue += this.props.amountIem
          } else {
            taxvalue += (value.amountValue / 100)
          }
        }
      })
      taxvalue += 1

      this.setState({ taxquantity: taxvalue, originPristine: pristineBeforeOpen }, () => this.props.valueIva ? this.props.actions.fetchProcessingAgencyModal(this.state.amount, this.state.amountImc, this.props.valueIva, this.props.valueIem, this.props.iemPercentage + '%', pristineBeforeOpen, this.props.dossierType)
        : this.props.actions.fetchProcessingAgencyModal(this.state.amount, this.state.amountImc, prevProps.valueIva, this.props.valueIem, this.props.iemPercentage + '%', pristineBeforeOpen, this.props.dossierType))
    }

    if (this.props.showModal !== prevProps.showModal) {
      this.props.actions.change('processingAgencyModal.amount', 0)
      this.props.actions.change('processingAgencyModal.amountImc', 0)
    }
    return this.props
  }

  componentDidUpdate () {
  }

  handleBlurAmount (value) {
    this.setState({ amount: value }, () => this.props.actions.fetchProcessingAgencyModal(this.state.amount, null, null, null, null, null, this.props.dossierType))
  }

  handleBlurAmountImc (value) {
    this.setState({ amountImc: value }, () => this.props.actions.fetchProcessingAgencyModal(null, this.state.amountImc, null, null, null, null, this.props.dossierType))
  }

  saveProcessingAgenctyAmount () {
    const value = parseFloat(this.state.amount) - parseFloat(this.state.amountImc)

    const extraSaleProcessingAgency = {
      amount: this.state.taxquantity === 0 ? value : parseFloat(value) * parseFloat(this.state.taxquantity),
      cost: this.state.taxquantity === 0 ? value : parseFloat(value) * parseFloat(this.state.taxquantity),
      dossierId: this.props.dossierId,
      name: 'Gestoría',
      provider: '',
      supInvoice: false,
      iem: true,
      typeId: 1
    }

    const extraSaleImc = {
      amount: this.state.amountImc,
      cost: this.state.amountImc,
      dossierId: this.props.dossierId,
      name: '',
      provider: '',
      supInvoice: true,
      iem: false,
      typeId: 3
    }

    return new Promise((resolve, reject) => {
      this.props.actions.createExtraSale(extraSaleImc, resolve, reject, this.props.dossierType)
      this.props.actions.closeModal()
      return this.props.actions.createExtraSale(extraSaleProcessingAgency, resolve, reject, this.props.dossierType)
    })
  }

  closeModalWithPristine () {
    this.props.actions.fetchProcessingAgencyModal(0, 0, 0, 0, 0, this.state.originPristine, this.props.dossierType)
    this.props.actions.closeModal()
  }

  render () {
    const { t, showModal, ivaTypeCombo, iemTypeCombo, valueIem } = this.props
    const tKey = 'DOSSIER_COMPONENTS.ECONOMIC_PLAN.MODAL_PROCESSING_AGENCY.'
    return (
      <Modal show={showModal} onHide={() => { this.closeModalWithPristine() }}>
        <Modal.Header closeButton>
          <Modal.Title>{t(`${tKey}TITLE`)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Field
              id="amount"
              name="processingAgencyModal.amount"
              colSm={6}
              controlLabel={t(`${tKey}AMOUNT`)}
              placeholder={t(`${tKey}AMOUNT`)}
              component={InputMoney}
              onInputBlur={(value) => this.handleBlurAmount(value)}
            />
            <Field
              id="amountImc"
              name="processingAgencyModal.amountImc"
              colSm={6}
              controlLabel={t(`${tKey}FEES`)}
              placeholder={t(`${tKey}FEES`)}
              component={InputMoney}
              onInputBlur={(value) => this.handleBlurAmountImc(value)}
            />
          </Row>
          <Row>
            <Field
              id="ivaType"
              name="processingAgencyModal.ivaType"
              type="text"
              valueKey="id"
              labelKey="value"
              colSm={6}
              controlLabel={t(`${tKey}SELECTED_TAX`)}
              placeholder={t(`${tKey}SELECTED_TAX`)}
              options={ivaTypeCombo}
              component={InputSelect}
              disabled={true}
            />
            {((valueIem === iemValues.other) ? ([
              <Field
                id="iemType"
                name="processingAgencyModal.iemType"
                type="text"
                valueKey="id"
                labelKey="value"
                key="iemType"
                colSm={3}
                controlLabel={t(`${tKey}SELECTED_IEM`)}
                placeholder={t(`${tKey}SELECTED_IEM`)}
                options={iemTypeCombo}
                component={InputSelect}
                disabled={true}
              />,
              <Field
                id="iemPercentage"
                key="iemPercentage"
                name="processingAgencyModal.iemPercentage"
                type="text"
                colSm={3}
                controlLabel={t(`${tKey}SELECTED_IEM`)}
                placeholder={t(`${tKey}SELECTED_IEM`)}
                component={InputText}
                disabled={true}
              />
            ]) : (
              <Field
                id="iemType"
                name="processingAgencyModal.iemType"
                type="text"
                valueKey="id"
                labelKey="value"
                colSm={6}
                controlLabel={t(`${tKey}SELECTED_IEM`)}
                placeholder={t(`${tKey}SELECTED_IEM`)}
                options={iemTypeCombo}
                component={InputSelect}
                disabled={true}
              />
            ))}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Col sm={6} className="btn-wrapper">
            <Button id="btn_save" className="btn-standard" onClick={() => { this.saveProcessingAgenctyAmount() }} >{t(`${tKey}SAVE_BTN`)}</Button>
          </Col>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default (EconomicPlanProcessingAgencyModal)
