import apiFetch from '../apiFetch'

export default function (token, signatureEntity) {
  return apiFetch({
    endPoint: `SignEntity/${signatureEntity.signEntityId}`,
    method: 'PUT',
    body: signatureEntity,
    token: token
  })
}
