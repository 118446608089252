import React, { PureComponent } from 'react'
import { reduxForm, Field } from 'redux-form'
import { Row, Col, Table, Glyphicon, Button, Tooltip, OverlayTrigger } from 'react-bootstrap'
import PaymentMethodModalPage from '../../../containers/masters/PaymentMethod/PaymentMethodModalPage'
import IDocCarPagination from '../../commons/iDocCarPagination'
import InputText from '../../commons/form/InputText'

class PaymentMethod extends PureComponent {
  constructor () {
    super()
    this.state = {
      filter: {
        searchName: null
      }
    }
  }

  getSortIcon (column = '') {
    switch (this.props.filter.orderBy) {
      case '+' + column:
        return 'ico-sort-selected-asc'
      case '-' + column:
        return 'ico-sort-selected-desc'
      default:
        return 'ico-sort'
    }
  }

  changeOrder (field) {
    switch (this.props.filter.orderBy) {
      case `+${field}`:
        this.props.actions.fetchPaymentMethodList({ ...this.state.filter, ...this.props.filter, orderBy: `-${field}` })
        break
      default:
        this.props.actions.fetchPaymentMethodList({ ...this.state.filter, ...this.props.filter, orderBy: `+${field}` })
        break
    }
  }

  handleNameChange (searchName) {
    if (this.state.filter.searchName !== searchName) {
      this.setState({ filter: { ...this.state.filter, searchName } })
    }
  }

  searchPaymentMethod () {
    this.props.actions.fetchPaymentMethodList({ ...this.props.filter, ...this.state.filter, page: 1 })
  }

  resetFilters () {
    this.setState({
      filter: {
        searchName: null
      }
    }, () => { this.searchPaymentMethod() })
  }

  render () {
    const {
      t, paymentMethodList, pagesCount, paymentCount, filter,
      filter: { page },
      actions: { openEditPaymentMethodModal, openAddPaymentMethodModal, fetchPaymentMethodList, deletePaymentMethod, recoverPaymentMethod }
    } = this.props
    const tKey = 'MASTERS.'
    const editTooltip = (<Tooltip id="editTooltip">{t(`${tKey}EDIT`)}</Tooltip>)
    const deleteTooltip = (<Tooltip id="deleteTooltip">{t(`${tKey}DELETE`)}</Tooltip>)
    const recoverTooltip = (<Tooltip id="recoverTooltip">{t(`${tKey}RECOVER`)}</Tooltip>)
    const hasFilters = (this.state.filter.searchName)
    return (
      <div className="admin-wrapper">
        <PaymentMethodModalPage />
        <Row className="section-users-header">
          <Col sm={12}>
            <div className="header-panel">
              <h4>{t(`${tKey}PAYMENT_METHOD`)}</h4>
            </div>
          </Col>
        </Row>
        <Row>
          <div className="search-form">
            <span className="title-search"><i className="ico-search" /> {t(`${tKey}PAYMENT_METHOD_MASTERS.SEARCH_TITLE`)}</span>
            <Row>
              <Field
                name="filters.name"
                colSm={2}
                controlLabel={t(`${tKey}PAYMENT_METHOD_MASTERS.NAME`)}
                placeholder={t(`${tKey}PAYMENT_METHOD_MASTERS.NAME`)}
                component={InputText}
                onInputChange={(value) => this.handleNameChange(value)}
              />
              <Col sm={10} className="btn-wrapper">
                <Button
                  className={'btn-white btn-icon btn-input btn-white search-button'}
                  style={{ width: '100%' }}
                  onClick={() => this.searchPaymentMethod()}>
                  <i className="ico-search" />
                  {t(`${tKey}SEARCH_BTN`)}
                </Button>
                <Button
                  className={'btn-icon btn-input' + (hasFilters ? ' btn-standard' : ' btn-cancel')}
                  onClick={() => {
                    this.props.initialize({})
                    this.resetFilters()
                  }}
                >
                  <i className="ico-cancel" />
                  {t(`${tKey}RESET_BUTTON`)}
                </Button>
              </Col>
            </Row>
          </div>
        </Row>
        <Col sm={12} className="col-row  section-users-body">
          <Table className="editable-table" hover responsive>
            <thead>
              <tr>
                <th onClick={() => { this.changeOrder('name') }}>
                  {t(`${tKey}PAYMENT_METHOD_MASTERS.NAME`)}
                  <i className={this.getSortIcon('name')} />
                </th>
                <th style={{ width: '77px' }}>{t('DOSSIER_COMPONENTS.ACCESSORIES.ACTIONS')}</th>
              </tr>
            </thead>
            <tbody>
              {paymentMethodList && paymentMethodList.map((payment, idx) => {
                return (
                  <tr key={idx} className={(payment.isDeleted ? 'is-deleted' : '')}>
                    <td>{payment.name}</td>
                    <td>
                      {!payment.isDeleted ? (
                        <div>
                          <a onClick={() => openEditPaymentMethodModal(payment.paymentMethodId)}>
                            <OverlayTrigger placement="left" overlay={editTooltip}>
                              <i className="ico-edit-white"/>
                            </OverlayTrigger>
                          </a>
                          <a onClick={() => deletePaymentMethod(payment.paymentMethodId, { ...filter, ...this.state.filter })}>
                            <OverlayTrigger placement="left" overlay={deleteTooltip}>
                              <i className="ico-trash"/>
                            </OverlayTrigger>
                          </a>
                        </div>
                      ) : (
                        <a onClick={() => recoverPaymentMethod(payment.paymentMethodId, { ...filter, ...this.state.filter })}>
                          <OverlayTrigger placement="left" overlay={recoverTooltip}>
                            <Glyphicon glyph="repeat"/>
                          </OverlayTrigger>
                        </a>
                      )}
                    </td>
                  </tr>)
              })
              }
              {(paymentMethodList === null || paymentMethodList === undefined || paymentMethodList.length === 0) &&
            <tr>
              <td colSpan={4}><h2>{t(`${tKey}PAYMENT_METHOD_MASTERS.RESULTS_NOT_FOUND`)}</h2></td>
            </tr>}
              <tr className="button-table-wrapper">
                <td>
                  <Button
                    className="btn-editable-table btn-standard"
                    onClick={() => openAddPaymentMethodModal()}>
                  + {t('MASTERS.ADD')}
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>
          <div className="section-users-footer">
            <nav key={1} className="text-center">
              <IDocCarPagination
                id="btn_pag_sales"
                page={page}
                pagesCount={pagesCount}
                totalRows={paymentCount}
                onSelectAction={(value) => fetchPaymentMethodList({ ...filter, ...this.state.filter, page: value })}
              />
            </nav>
          </div>
        </Col>
      </div>
    )
  }
}

export default reduxForm({
  form: 'listPaymentMethod'
})(PaymentMethod)
