import saleActionTypes from '../../constants/actions/reports/saleReport'

function initialState () {
  return {
    saleList: [],
    pagesCount: 0,
    saleCount: 0,
    filter: {
      page: 1,
      orderBy: '',
      isDeleted: null,
      pageSize: 10
    }
  }
}

function fetchSaleReportSuccess (state, { saleList, filter }) {
  return {
    ...state,
    saleList: saleList.saleList,
    saleCount: saleList.saleCount,
    pagesCount: saleList.pagesCount,
    filter
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case saleActionTypes.FETCH_SALE_REPORT_SUCCESS:
      return fetchSaleReportSuccess(state, action)
    default:
      return state
  }
}
