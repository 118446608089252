import { call, takeEvery, select, put } from 'redux-saga/effects'
import { handleError } from '../../../errors/errorManager'
import { getAuth } from '../../../../selectors/access/auth'
import { showLoader, hideLoader } from '../../../../actions/common'
import postDocumentsGroup from '../../../../services/document/postDocumentsGroup'
import documentManagementGroupCreateModal from '../../../../constants/actions/dossier/common/documentManagementGroupCreateModal'
import { yesNoModal } from '../../../modalsListeners/yesNoModal'

export function * groupDocuments ({ groupedDocumentsIds, documentName, organizedUnitId, dossierId, okAction }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(postDocumentsGroup, auth.token, groupedDocumentsIds, documentName, organizedUnitId, dossierId)
    if (okAction) okAction()
    yield put(hideLoader())
    yield call(yesNoModal, 'groupDocumentsSuccess', null, null, null, null, documentName)
  } catch (error) {
    yield put(hideLoader())
    yield call(handleError, { error })
  }
}

export function * watchGroupDocuments () {
  yield takeEvery(documentManagementGroupCreateModal.GROUP_FILES, groupDocuments)
}
