import React, { PureComponent } from 'react'
import { Row, Table, Button, Checkbox } from 'react-bootstrap'
class TabPane3 extends PureComponent {
  render () {
    return (
      <Row className="discount-tabpane">
        <div className="button-wrapper">
          <Button className="btn-standard-white">
            <i className="ico-check" /> Aprobar dirección
          </Button>
        </div>
        <Table responsive className="table-tabPane">
          <thead>
            <tr>
              <th>Campañas/Ayudas Fábrica</th>
              <th>Importe</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <Checkbox inline>
               Normal
                </Checkbox>
              </td>
              <td>0,0</td>
            </tr>
            <tr>
              <td>
                <Checkbox inline>
               Flexiplan
                </Checkbox>
              </td>
              <td>0,0</td>
            </tr>
            <tr>
              <td>
                <Checkbox inline>
               Sobretasación VO (NO PIVE)
                </Checkbox>
              </td>
              <td>0,0</td>
            </tr>
            <tr>
              <td>
                <Checkbox inline>
               PromoFinanciación
                </Checkbox>
              </td>
              <td>0,0</td>
            </tr>
            <tr>
              <td>
                <Checkbox inline>
               Autónomo/Empresa
                </Checkbox>
              </td>
              <td>0,0</td>
            </tr>
            <tr>
              <td>
                <Checkbox inline>
               Especiales Fábrica (10 días XY)
                </Checkbox>
              </td>
              <td>0,0</td>
            </tr>
            <tr>
              <td>Total campañas / Ayuda Fábrica</td>
              <td>0,0</td>
            </tr>
          </tbody>
        </Table>
        <Table responsive className="table-tabPane second-table">
          <thead>
            <tr>
              <th>Ayudas especiales</th>
              <th>Fecha solicitud</th>
              <th>Importe</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <Checkbox inline>
                Flotistas
                </Checkbox>
              </td>
              <td>Fecha solicitud</td>
              <td>0,0</td>
            </tr>
            <tr>
              <td>
                <Checkbox inline>
                RAC
                </Checkbox>
              </td>
              <td>Fecha solicitud</td>
              <td>0,0</td>
            </tr>
            <tr>
              <td>
                <Checkbox inline>
                RAC
                </Checkbox>
              </td>
              <td>Fecha solicitud</td>
              <td>0,0</td>
            </tr>
            <tr>
              <td>Total campañas / Ayuda Fábrica</td>
              <td>0,0</td>
              <td></td>
            </tr>
          </tbody>
        </Table>
      </Row>
    )
  }
}

export default TabPane3
