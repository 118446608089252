import React, { PureComponent } from 'react'
import { Panel, Button, Row } from 'react-bootstrap'
import { Field } from 'redux-form'
import InputCheckbox from '../../commons/form/InputCheckBox'
import SimpleTablePage from '../../../containers/commons/general/SimpleTablePage'
import { compareValues } from '../../../util/utils'
import { Link } from 'react-router-dom'
import InputSelect from '../../commons/form/InputSelect'
import { operationTabs } from '../../../constants/management/operations'
import { permissions } from '../../../constants/backendIds'
import { dossierSubTypeId } from '../../../constants/dossier/common/dossierSubType'

class PaperworkFleetListComponent extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      openPaperworkPanel: false,
      filters: {
        orderBy: '+number',
        selectedCheck_massive: false
      }
    }
  }

  componentDidMount () {
    if (this.props.fleet && !this.props.create) {
      this.props.actions.getDossierChildSubtypeDetailCount(this.props.fleet.fleetId, dossierSubTypeId.tramite)
    }
  }

  collapsePanelDossier () {
    var newState = !this.state.openPaperworkPanel
    this.setState({ openPaperworkPanel: newState })
    if (newState && this.props.fleet) {
      this.props.actions.getPaperworksDetailsFleet(this.props.fleet.fleetId)
    }
  }

  componentDidUpdate () {
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (this.props.changeCheckAll !== prevProps.changeCheckAll) {
      this.setState({ selectedCheck_massive: false })
    }
    return null
  }

  getIconClass (field) {
    if (this.state.filters.orderBy.includes(field)) {
      return this.state.filters.orderBy.includes('-') ? 'ico-sort-selected-asc' : 'ico-sort-selected-desc'
    } else {
      return 'ico-sort'
    }
  }

  onOrderChange (e) {
    const newOrderBy = this.state.filters.orderBy.includes(`+${e.currentTarget.dataset.field}`) ? `-${e.currentTarget.dataset.field}` : `+${e.currentTarget.dataset.field}`
    this.setState({ filters: { orderBy: newOrderBy } })
    this.order(e.currentTarget.dataset.field, newOrderBy.includes('+') ? 'asc' : 'desc')
  }

  order (field, order) {
    let dossiers = JSON.parse(JSON.stringify(this.props.paperWorkFleetDetails))
    dossiers = dossiers.sort(compareValues(field, order))
    this.props.actions.getPaperworksDetailsFleetSuccess(dossiers)
  }

  onCheckAll (e) {
    this.setState({ selectedCheck_massive: e })
    let dossiers = JSON.parse(JSON.stringify(this.props.paperWorkFleetDetails))

    dossiers.map(dossier => {
      dossier.checked = e
    })

    this.props.actions.getPaperworksDetailsFleetSuccess(dossiers)
  }

  onCheckOne (e, id) {
    this.setState({ selectedCheck_massive: false })
    let dossiers = JSON.parse(JSON.stringify(this.props.paperWorkFleetDetails))

    dossiers.map(dossier => {
      if (dossier.dossierId === id) {
        dossier.checked = e
      }
    })

    this.props.actions.getPaperworksDetailsFleetSuccess(dossiers)
  }

  updateDossiers () {
    if (this.props.fleet && !this.props.create) {
      this.props.actions.getPaperworksDetailsFleet(this.props.fleet.fleetId)
    }
  }

  render () {
    const {
      t,
      paperWorkFleetDetails,
      readOnlyFields,
      dataPaperWorkActions,
      operationPaperWorkActions,
      fleet,
      paperWorkCount
    } = this.props
    const tKey = 'MANAGEMENT.FLEETS_TAB.'
    const numDossiers = paperWorkCount || 0
    const selectedDossiers = this.props.paperWorkFleetDetails.filter(dossier => dossier.checked)
    let columnsTable = [
      <th key={0} data-field="number" onClick={(event) => this.onOrderChange(event)}>
        {t(`${tKey}DOSSIER_NUMBER`)}&nbsp;<i className={this.getIconClass('number')}></i>
      </th>,
      <th key={1} data-field="organizedUnit" onClick={(event) => this.onOrderChange(event)}>
        {t(`${tKey}ORGANIZED_UNIT`)}&nbsp;<i className={this.getIconClass('organizedUnit')}></i>
      </th>,
      <th key={3} data-field="vehicle" onClick={(event) => this.onOrderChange(event)}>
        {t(`${tKey}VEHICLE`)}&nbsp;<i className={this.getIconClass('vehicle')}/>
      </th>,
      <th key={4} data-field="model" onClick={(event) => this.onOrderChange(event)}>
        {t(`${tKey}MODEL`)}&nbsp;<i className={this.getIconClass('model')}></i>
      </th>,
      <th key={5} data-field="paperWorkType" onClick={(event) => this.onOrderChange(event)}>
        {t(`${tKey}PAPERWORK_TYPE`)}&nbsp;<i className={this.getIconClass('paperWorkType')}></i>
      </th>,
      <th key={6} data-field="stage" onClick={(event) => this.onOrderChange(event)}>
        {t(`${tKey}STAGE`)}&nbsp;<i className={this.getIconClass('stage')}/>
      </th>,
      <th key={7} data-field="processingAgencyName" onClick={(event) => this.onOrderChange(event)}>
        {t(`${tKey}PROCESSING_AGENCY`)}&nbsp;<i className={this.getIconClass('processingAgencyName')}/>
      </th>
    ]
    if (!readOnlyFields) {
      columnsTable.push(
        <th key={8} data-field="checked" className='flet-detail-dossier-check-title' >
          <Field
            component={InputCheckbox}
            id='selectedCheck_massive_paperwork'
            name={'selectedCheck_massive_paperwork'}
            onChange={(event) => this.onCheckAll(event)}
            checked={this.state.selectedCheck_massive}
          />
        </th>
      )
    }

    return (
      <>
        <div className="dossier-panel" >
          <div id="paperWorkFleetHeader" className="header-panel  fleet-panel-box" onClick={() => this.collapsePanelDossier()}>

            <div className="name-wrapper fleet-panel-box-title">
              <h4>{t(`${tKey}PAPERWORKS`)} | <span className='fleet-panel-subTitle'>{numDossiers}</span> </h4>
            </div>

            <i id="paperWorkFleetArrow" className={this.state.openPaperworkPanel ? 'ico-arrow-black-down fleet-panel-box-caret' : 'ico-arrow-black-up fleet-panel-box-caret'} />

          </div>
          <Panel expanded={this.state.openPaperworkPanel} onToggle={() => {}}>
            <Panel.Collapse>
              <div id="fleet-dossier-details-table">
                {!readOnlyFields && (
                  <div className="flexInLine al-R">
                    <Button
                      id="update_paperWorkds_button"
                      className="_Btn accept stk flexInLine al-R"
                      onClick = {() => this.updateDossiers()}>
                      <i className="iDocIcon-update"></i>
                      {t('MANAGEMENT.ADMIN_AUDIT.MODAL.UPDATE')}
                    </Button>
                  </div>
                )}
                <SimpleTablePage
                  columns={columnsTable}
                  rows={paperWorkFleetDetails && paperWorkFleetDetails.map((row, idx) => (
                    <tr key={idx}>
                      <td>
                        {
                          row.urlToNavigate && row.stageId ? (
                            <Link to={`/dossier${row.urlToNavigate}`}>
                              {row.number}
                            </Link>
                          ) : row.number
                        }
                      </td>
                      <td>{row.organizedUnit}</td>
                      <td>{row.vehicle}</td>
                      <td>{row.model}</td>
                      <td>{row.paperWorkType}</td>
                      <td>{row.stage}</td>
                      <td>{row.processingAgencyName}</td>
                      {!readOnlyFields && <td>{<Field
                        component={InputCheckbox}
                        name={'selectedCheckPW_' + idx}
                        id={'selectedCheckPW_' + idx}
                        checked={row.checked ? row.checked : false}
                        onChange={(event) => this.onCheckOne(event, row.dossierId)}
                      />}
                      </td>}
                    </tr>
                  ))}

                ></SimpleTablePage>
              </div>
              {!readOnlyFields && (
                <Row className='button-container'>
                  <Field
                    id="paperWorkDataId"
                    name='paperWorkDataId'
                    placeholder={t(`${tKey}DATA`)}
                    controlLabel={t(`${tKey}DATA`)}
                    component={InputSelect}
                    options={dataPaperWorkActions}
                    valueKey="managementActionId"
                    labelKey="name"
                    disabled={selectedDossiers.length === 0}
                    onInputChange={(val) => { document.activeElement?.blur(); this.props.launchAction(val, operationTabs.DATA_PAPERWORK, this.props.paperWorkFleetDetails.filter(dossier => dossier.checked)); this.setState({ dataId: val }) }}
                  />
                  <Field
                    id="paperWorkActionId"
                    name='paperWorkActionId'
                    placeholder={t(`${tKey}ACTIONS`)}
                    controlLabel={t(`${tKey}ACTIONS`)}
                    component={InputSelect}
                    options={operationPaperWorkActions}
                    valueKey="managementActionId"
                    labelKey="name"
                    disabled={selectedDossiers.length === 0}
                    onInputChange={(val) => { this.props.launchAction(val, operationTabs.OPERATIONS_PAPERWORK, this.props.paperWorkFleetDetails.filter(dossier => dossier.checked)); this.setState({ actionId: val }) }}

                  />
                  {this.props.fieldsConfiguration && this.props.fieldsConfiguration.documentsFleet && this.props.fieldsConfiguration.documentsFleet.permission === permissions.editable && (
                    <Button
                      id='paperWorkDocumentButton'
                      className="btn-Load document-button"
                      onClick={() => this.props.openDocumentsModal(fleet, selectedDossiers)}
                      disabled={selectedDossiers.length === 0}>
                      {t(`${tKey}DOCUMENTS`)}
                    </Button>)}

                </Row>
              )}

            </Panel.Collapse>
          </Panel>
        </div>

      </>
    )
  }
}

export default PaperworkFleetListComponent
