import mastersActionTypes from '../../../constants/actions/masters/masters'

export function fetchProcessingAgencyOrganizedUnitConfigList (filter) {
  return {
    type: mastersActionTypes.FETCH_PROCESSING_AGENCY_ORGANIZED_UNIT_CONFIG_LIST,
    filter
  }
}

export function fetchProcessingAgencyOrganizedUnitConfigListSuccess (processingAgencyOrganizedUnitConfigList, filter) {
  return {
    type: mastersActionTypes.FETCH_PROCESSING_AGENCY_ORGANIZED_UNIT_CONFIG_LIST_SUCCESS,
    processingAgencyOrganizedUnitConfigList,
    filter
  }
}

export function showProcessingAgencyOrganizedUnitConfigModal (show, processingAgencyOrganizedUnitConfig) {
  return {
    type: mastersActionTypes.SHOW_PROCESSING_AGENCY_ORGANIZED_UNIT_CONFIG_MODAL,
    show,
    processingAgencyOrganizedUnitConfig
  }
}

export function showProcessingAgencyOrganizedUnitConfigModalSuccess (show, processingAgencyOrganizedUnitConfig) {
  return {
    type: mastersActionTypes.SHOW_PROCESSING_AGENCY_ORGANIZED_UNIT_CONFIG_MODAL_SUCCESS,
    show,
    processingAgencyOrganizedUnitConfig
  }
}

export function submitProcessingAgencyOrganizedUnitConfig (processingAgencyOrganizedUnitConfig, processAgencyCommunicationServiceId) {
  return {
    type: mastersActionTypes.SUBMIT_PROCESSING_AGENCY_ORGANIZED_UNIT_CONFIG,
    processingAgencyOrganizedUnitConfig,
    processAgencyCommunicationServiceId
  }
}

export function deleteProcessingAgencyOrganizedUnitConfig (processingAgencyOrganizedUnitConfigId, processAgencyCommunicationServiceId) {
  return {
    type: mastersActionTypes.DELETE_PROCESSING_AGENCY_ORGANIZED_UNIT_CONFIG,
    processingAgencyOrganizedUnitConfigId,
    processAgencyCommunicationServiceId
  }
}
