import React, { PureComponent } from 'react'
import { reduxForm, Field } from 'redux-form'
import { Row, Col, Table, Glyphicon, Button, Tooltip, OverlayTrigger } from 'react-bootstrap'
import UserModalPage from '../../../containers/admin/user/UserModalPage'
import BubbleMeterUO2 from '../../commons/BubbleMeterUO2'
import IDocCarPagination from '../../commons/iDocCarPagination'
import InputText from '../../commons/form/InputText'
import InputTreeSelect from '../../commons/form/InputTreeSelect'
import InputSelect from '../../commons/form/InputSelect'
import { loadTemplatesNames } from '../../../constants/backendIds'
import ModalMassUploadPage from '../../../containers/masters/ModalMassUpload/ModalMassUploadPage'
import UserSubscriptionsModal from './subscriptions/UserSubscriptionsModal'

class UserAdmin extends PureComponent {
  constructor () {
    super()
    this.state = {
      filter: {
        searchUO: [],
        searchRole: [],
        searchName: null,
        searchEmail: null
      },
      showModal: false,
      showSubscriptionsModal: false,
      userId: null
    }
    this.searchUsers = this.searchUsers.bind(this)
  }

  componentDidMount () {
    this.props.actions.fetchRoleCombo()
    this.searchUsers()
  }

  getSortIcon (column = '') {
    switch (this.props.filter.orderBy) {
      case '+' + column:
        return 'ico-sort-selected-asc'
      case '-' + column:
        return 'ico-sort-selected-desc'
      default:
        return 'ico-sort'
    }
  }

  changeOrder (field) {
    switch (this.props.filter.orderBy) {
      case `+${field}`:
        this.props.actions.fetchUserList({ ...this.state.filter, ...this.props.filter, orderBy: `-${field}` })
        break
      default:
        this.props.actions.fetchUserList({ ...this.state.filter, ...this.props.filter, orderBy: `+${field}` })
        break
    }
  }

  handleRolChange (searchRole) {
    if (this.state.filter.searchRole !== searchRole) {
      this.setState({ filter: { ...this.state.filter, searchRole: searchRole } })
    }
  }

  handleOrganizedUnitChange (searchUO) {
    if (!searchUO || searchUO.length === 0) {
      this.setState({ filter: { ...this.state.filter, searchUO: [] } })
      return
    }
    const searchUoList = searchUO.split(',').map((item) => {
      return parseInt(item)
    })
    if (this.state.filter.searchUO !== searchUoList) {
      this.setState({ filter: { ...this.state.filter, searchUO: searchUoList } })
    }
  }

  handleNameChange (searchName) {
    if (this.state.filter.searchName !== searchName) {
      this.setState({ filter: { ...this.state.filter, searchName } })
    }
  }

  handleEmailChange (searchEmail) {
    if (this.state.filter.searchEmail !== searchEmail) {
      this.setState({ filter: { ...this.state.filter, searchEmail } })
    }
  }

  searchUsers () {
    this.props.actions.fetchUserList({ ...this.props.filter, ...this.state.filter, page: 1 })
  }

  openUserModalLoad () {
    this.setState({ showModal: true })
    this.props.actions.openModalMass('ADMIN.USERS.MODAL_UPLOAD.', this.props.actions.uploadUserMassDocument, this.searchUsers)
  }

  closeUserModalLoad () {
    this.setState({ showModal: false })
  }

  openUserSubscriptionsModal (userId) {
    this.setState({ showSubscriptionsModal: true, userId })
  }

  closeUserSubscriptionsModal () {
    this.setState({ showSubscriptionsModal: false })
  }

  render () {
    const {
      t, userList, pagesCount, filter, usersCount, eventSubscriptions, enableSubscriptions, defaultLanguageId,
      filter: { page },
      combos: { UOTreeCombo, roleCombo, lowestOrganizedUnitsCombo, languages, events },
      actions: {
        openEditUserModal, openAddUserModal, fetchUserList, deleteUser, recoverUser, fetchTemplateFile,
        saveEventSubscriptionFromAdmin, removeEventSubscriptionFromAdmin, fetchEventsCombo, fetchLowestOrganizedUnits,
        fetchEventSubscriptionsFromAdmin, enableEventSubscriptionFromAdmin, disableEventSubscriptionFromAdmin
      }
    } = this.props

    const tKey = 'ADMIN.'
    const editTooltip = (<Tooltip id="editTooltip">{t(`${tKey}EDIT`)}</Tooltip>)
    const deleteTooltip = (<Tooltip id="deleteTooltip">{t(`${tKey}DELETE`)}</Tooltip>)
    const recoverTooltip = (<Tooltip id="recoverTooltip">{t(`${tKey}RECOVER`)}</Tooltip>)

    return (
      <div className="admin-wrapper">
        <UserSubscriptionsModal
          actions = {{
            saveEventSubscriptionFromAdmin,
            removeEventSubscriptionFromAdmin,
            closeModal: () => this.closeUserSubscriptionsModal(),
            fetchEventsCombo,
            fetchLowestOrganizedUnits,
            fetchEventSubscriptionsFromAdmin,
            disableEventSubscriptionFromAdmin,
            enableEventSubscriptionFromAdmin
          }}
          t={t}
          combos={{ lowestOrganizedUnitsCombo, languages, events }}
          userId = {this.state.userId}
          showModal = {this.state.showSubscriptionsModal}
          eventSubscriptions = {eventSubscriptions}
          defaultLanguageId = {defaultLanguageId}
        />
        <UserModalPage/>
        <ModalMassUploadPage
          showModal={this.state.showModal}
          closeModal={this.closeUserModalLoad.bind(this)}
        />
        <Row className="section-users-header">
          <Col sm={12}>
            <div className="header-panel">
              <h4>{t(`${tKey}USERS_TITLE`)}</h4>
            </div>
          </Col>
        </Row>
        <Row>
          <div className="search-form">
            <span className="title-search"><i className="ico-search" /> {t(`${tKey}USERS.SEARCH_TITLE`)}</span>
            <Row>
              <Field
                name="filters.rol"
                controlLabel={t(`${tKey}USERS.ROL`)}
                placeholder={t(`${tKey}USERS.ROL`)}
                component={InputSelect}
                multi={true}
                valueKey="id"
                labelKey="value"
                options={roleCombo}
                colSm={3}
                onInputChange={(value) => this.handleRolChange(value)}
              />
              <Field
                name="filters.organizedUnit"
                controlLabel={t(`${tKey}USERS.UO`)}
                placeholder={t(`${tKey}USERS.UO`)}
                component={InputTreeSelect}
                multi={true}
                valueKey="value"
                labelKey="label"
                childrenKey="children"
                options={UOTreeCombo}
                colSm={3}
                onInputChange={(value) => this.handleOrganizedUnitChange(value)}
              />
              <Field
                id="name"
                name="filters.name"
                colSm={2}
                controlLabel={t(`${tKey}USERS.NAME`)}
                placeholder={t(`${tKey}USERS.NAME`)}
                component={InputText}
                onInputChange={(value) => this.handleNameChange(value)}
              />
              <Field
                id="email"
                name="filters.email"
                colSm={2}
                controlLabel={t(`${tKey}USERS.EMAIL`)}
                placeholder={t(`${tKey}USERS.EMAIL`)}
                component={InputText}
                onInputChange={(value) => this.handleEmailChange(value)}
              />
              <Col sm={2} className="btn-wrapper">
                <Button
                  className={'btn-white btn-icon btn-input btn-white search-button'}
                  style={{ width: '100%' }}
                  onClick={() => this.searchUsers()}>
                  <i className="ico-search" />
                  {t(`${tKey}SEARCH_BTN`)}
                </Button>
              </Col>
            </Row>
          </div>
        </Row>
        <Col sm={12}>
          <Button
            className="btn-download btn-editable-table btn-standard"
            onClick={() => fetchTemplateFile(loadTemplatesNames.users) }
          >
            {t('MASTERS.DOWNLOAD')}
          </Button>
        </Col>
        <Col sm={12} className="col-row  section-users-body">
          <Table className="editable-table admin-users" hover responsive>
            <thead>
              <tr>
                <th style={{ display: 'none' }} onClick={() => { this.changeOrder('code') }}>
                  {t(`${tKey}USERS.CODE`)}&nbsp;
                  <i className={this.getSortIcon('code')} />
                </th>
                <th onClick={() => { this.changeOrder('name') }}>
                  {t(`${tKey}USERS.NAME_SURNAME`)}&nbsp;
                  <i className={this.getSortIcon('name')} />
                </th>
                <th onClick={() => { this.changeOrder('emailaddress') }}>
                  {t(`${tKey}USERS.EMAIL`)}&nbsp;
                  <i className={this.getSortIcon('emailaddress')} />
                </th>
                <th>
                  {t(`${tKey}USERS.PASSWORD_EXPIRED`)}&nbsp;
                </th>
                <th>{t(`${tKey}USERS.UO_ROL`)}</th>
                <th style={{ width: '77px' }}>{t('DOSSIER_COMPONENTS.ACCESSORIES.ACTIONS')}</th>
              </tr>
            </thead>
            <tbody>
              {userList && userList.map((user, idx) => (
                <tr key={idx} className={(!user.isActive ? 'is-deleted' : '')}>
                  <td style={{ display: 'none' }}>{user.userId}</td>
                  <td>{user.name} {user.surnames}</td>
                  <td>{user.emailAddress}</td>
                  <td className="tempPass">
                    {user.isTemp &&
                      <i className="ico-cancel" />}
                    {user.isTemp === false &&
                      <i className="ico-check" />}
                  </td>
                  <td>
                    {user.organizedUnits && [
                      <span key="lable" className="tree-list-label tree-list-item">{user.organizedUnits[0].name}</span>,
                      <BubbleMeterUO2 key="bubble" listItems={user.organizedUnits} />
                    ]}
                  </td>
                  <td>
                    {user.isActive
                      ? (
                        <div className='accionsColumn'>
                          {enableSubscriptions && (
                            <a onClick={() => this.openUserSubscriptionsModal(user.userId)}>
                              <OverlayTrigger placement="right" overlay={editTooltip}>
                                <i className="iDocIcon-bell"></i>
                              </OverlayTrigger>
                            </a>
                          ) }
                          <a onClick={() => openEditUserModal(user.userId)}>
                            <OverlayTrigger placement="right" overlay={editTooltip}>
                              <i className="ico-edit-white" />
                            </OverlayTrigger>
                          </a>
                          <a onClick={() => deleteUser(user.userId, { ...filter, ...this.state.filter })}>
                            <OverlayTrigger placement="left" overlay={deleteTooltip}>
                              <i className="ico-trash" />
                            </OverlayTrigger>
                          </a>
                        </div>
                      ) : (
                        <a onClick={() => recoverUser(user.userId, { ...filter, ...this.state.filter })}>
                          <OverlayTrigger placement="left" overlay={recoverTooltip}>
                            <Glyphicon glyph="repeat" />
                          </OverlayTrigger>
                        </a>
                      )}
                  </td>
                </tr>))
              }
              {(userList === null || userList === undefined || userList.length === 0) &&
            <tr>
              <td colSpan={12}><h2>{t(`${tKey}USERS.RESULTS_NOT_FOUND`)}</h2></td>
            </tr>}
              <tr className="button-table-wrapper">
                <td>
                  <Button
                    className="btn-editable-table btn-standard"
                    onClick={() => openAddUserModal()}>
                  + {t('MASTERS.ADD')}
                  </Button>
                  <Button
                    className="btn-editable-table btn-standard col-2"
                    onClick={this.openUserModalLoad.bind(this)}
                  >
                    <Glyphicon glyph="open-file" />{' ' + t('MASTERS.ADD')}
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>
          <div className="section-users-footer">
            <nav key={1} className="text-center">
              <IDocCarPagination
                id="btn_pag_sales"
                page={page}
                pagesCount={pagesCount}
                totalRows={usersCount}
                onSelectAction={(value) => fetchUserList({ ...filter, ...this.state.filter, page: value })}
              />
            </nav>
          </div>
        </Col>
      </div>
    )
  }
}

export default reduxForm({
  form: 'listUser'
})(UserAdmin)
