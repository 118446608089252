import documentaryManagementModalActionTypes from '../../../constants/actions/dossier/common/documentEditionModal'

export function openDocumentCreationModal (dossierId, documentParentTypeId, organizedUnitId, entityId, vehicleId, callback, refreshAfterChange, dossierType, pristine, isRecurrent, entityProviderId) {
  return {
    type: documentaryManagementModalActionTypes.OPEN_DOCUMENT_CREATION_MODAL,
    dossierId,
    documentParentTypeId,
    organizedUnitId,
    entityId,
    vehicleId,
    callback,
    refreshAfterChange,
    dossierType,
    pristine,
    isRecurrent,
    entityProviderId
  }
}

export function openDocumentCreationModalSuccess (documentParentTypeId, organizedUnitId, dossierId, entityId, vehicleId, callback, refreshAfterChange, dossierType, dirty, isRecurrent, entityProviderId) {
  return {
    type: documentaryManagementModalActionTypes.OPEN_DOCUMENT_CREATION_MODAL_SUCCESS,
    documentParentTypeId,
    organizedUnitId,
    dossierId,
    entityId,
    vehicleId,
    callback,
    refreshAfterChange,
    dossierType,
    dirty,
    isRecurrent,
    entityProviderId
  }
}

export function openDocumentEditionModal (documentId, documentTypeUse, documentParentTypeId, dossierId, entityId, vehicleId, organizedUnitId, checklistId, callback, refreshAfterChange, dossierType, dossierSubType, lastAccessDate, pristine, chargeId = null, isRecurrent, editable, readOnlyFields, fromTask = false, buttonRefund = false, chargeIdParent = null,
  referenceType, referenceId, refreshPurchasePresents, documentCode = null) {
  return {
    type: documentaryManagementModalActionTypes.OPEN_DOCUMENT_EDITION_MODAL,
    documentId,
    documentTypeUse,
    documentParentTypeId,
    dossierId,
    entityId,
    vehicleId,
    organizedUnitId,
    checklistId,
    callback,
    refreshAfterChange,
    dossierType,
    dossierSubType,
    lastAccessDate,
    chargeId,
    pristine,
    isRecurrent,
    editable,
    readOnlyFields,
    fromTask,
    buttonRefund,
    chargeIdParent,
    referenceType,
    referenceId,
    refreshPurchasePresents,
    documentCode
  }
}

export function openDocumentEditionModalSuccess (docResult, documentTypeUseInfo, organizedUnitId, callback, dossierId, entityId, vehicleId, documentParentTypeId, checklistId, refreshAfterChange, dossierType, dossierSubType, lastAccessDate, dirty, isRecurrent, editable, readOnlyFields
  , refreshPurchasePresents, referenceType, referenceId) {
  return {
    type: documentaryManagementModalActionTypes.OPEN_DOCUMENT_EDITION_MODAL_SUCCESS,
    docResult,
    documentParentTypeId,
    documentTypeUseInfo,
    organizedUnitId,
    callback,
    dossierId,
    entityId,
    vehicleId,
    checklistId,
    refreshAfterChange,
    dossierType,
    dossierSubType,
    lastAccessDate,
    dirty,
    isRecurrent,
    editable,
    readOnlyFields,
    refreshPurchasePresents,
    referenceType,
    referenceId
  }
}

export function refreshDocResul (documentId) {
  return {
    type: documentaryManagementModalActionTypes.REFRESH_DOC_RESULT,
    documentId
  }
}

export function refreshDocResulSuccess (docResult) {
  return {
    type: documentaryManagementModalActionTypes.REFRESH_DOC_RESULT_SUCCESS,
    docResult
  }
}

export function onDocumentTypeIdChange (documentTypeId, documentTypeUseName) {
  return {
    type: documentaryManagementModalActionTypes.ON_DOCUMENT_TYPE_ID_CHANGE,
    documentTypeId,
    documentTypeUseName
  }
}

export function closeAddOrEditDocumentModal (refreshAfterChange) {
  return {
    type: documentaryManagementModalActionTypes.CLOSE_ADD_OR_EDIT_DOCUMENT_MODAL,
    refreshAfterChange
  }
}

export function closeAddOrEditDocumentModalSuccess () {
  return {
    type: documentaryManagementModalActionTypes.CLOSE_ADD_OR_EDIT_DOCUMENT_MODAL_SUCCESS
  }
}

export function fetchDocument (documentId) {
  return {
    type: documentaryManagementModalActionTypes.FETCH_DOCUMENT,
    documentId
  }
}

export function fetchDocumentSuccess (docResult, documentTypeUseInfo, documentTypeId, documentTypeUseName) {
  return {
    type: documentaryManagementModalActionTypes.FETCH_DOCUMENT_SUCCESS,
    docResult,
    documentTypeUseInfo,
    documentTypeId,
    documentTypeUseName
  }
}

export function createDocument (file, checklistId, documentTypeUseId, dossierId, resolve, chargeId, parentChargeId, fromTemplate, formValues) {
  return {
    type: documentaryManagementModalActionTypes.CREATE_DOCUMENT,
    file,
    checklistId,
    documentTypeUseId,
    dossierId,
    resolve,
    chargeId,
    parentChargeId,
    fromTemplate,
    formValues
  }
}

export function uploadDocument (file, documentTypeId) {
  return {
    type: documentaryManagementModalActionTypes.UPLOAD_DOCUMENT,
    file,
    documentTypeId
  }
}

export function uploadNewVersion () {
  return {
    type: documentaryManagementModalActionTypes.UPLOAD_NEW_VERSION
  }
}

export function saveDocument (fields, comment, fleetsVisor) {
  return {
    type: documentaryManagementModalActionTypes.SAVE_DOCUMENT,
    fields,
    comment,
    fleetsVisor
  }
}

export function toggleFullScreenDocumentModal (documentId, isDownload, organizedUnitId) {
  return {
    type: documentaryManagementModalActionTypes.TOGGLE_FULL_SCREEN_DOCUMENT_MODAL,
    documentId,
    isDownload,
    organizedUnitId
  }
}

export function rejectDocument (documentId, rejectMessage, resolve, reject) {
  return {
    type: documentaryManagementModalActionTypes.REJECT_DOCUMENT,
    documentId,
    rejectMessage,
    resolve,
    reject
  }
}

export function validateDocument (documentId, fields, comment, isRecurrent) {
  return {
    type: documentaryManagementModalActionTypes.VALIDATE_DOCUMENT,
    documentId,
    fields,
    comment,
    isRecurrent
  }
}

export function changeId (id) {
  return {
    type: documentaryManagementModalActionTypes.CHANGE_ID,
    id
  }
}

export function recoverDocument (documentId, documentTypeUse, documentParentTypeId, dossierId, entityId, vehicleId, organizedUnitId, checklistId, callback, refreshAfterChange, dossierType, dossierSubType, isRecurrent) {
  return {
    type: documentaryManagementModalActionTypes.RECOVER_DOCUMENT,
    documentId,
    documentTypeUse,
    documentParentTypeId,
    dossierId,
    entityId,
    vehicleId,
    organizedUnitId,
    checklistId,
    callback,
    refreshAfterChange,
    dossierType,
    dossierSubType,
    isRecurrent
  }
}

export function createDocumentSheet (file, organizedUnitId, documentId) {
  return {
    type: documentaryManagementModalActionTypes.CREATE_DOCUMENT_SHEET,
    file,
    organizedUnitId,
    documentId
  }
}

export function deleteDocumentSheet (documentId, sheetId, resolve, reject, organizedUnitId) {
  return {
    type: documentaryManagementModalActionTypes.DELETE_DOCUMENT_SHEET,
    documentId,
    sheetId,
    resolve,
    reject,
    organizedUnitId
  }
}

export function setActivePage (sheetId, pageId) {
  return {
    type: documentaryManagementModalActionTypes.SET_ACTIVE_PAGE,
    sheetId,
    pageId
  }
}

export function toggleFullScreenDocumentSheetModal (documentId, sheetId, isDownload) {
  return {
    type: documentaryManagementModalActionTypes.TOGGLE_FULL_SCREEN_DOCUMENT_SHEET_MODAL,
    documentId,
    sheetId,
    isDownload
  }
}

export function checkCanCloseDocumentModal (showAdvise, resolve) {
  return {
    type: documentaryManagementModalActionTypes.CHECK_CAN_CLOSE_DOCUMENT_MODAL,
    showAdvise,
    resolve
  }
}

export function updateDocumentModalLastAccessDate (lastAccessDate) {
  return {
    type: documentaryManagementModalActionTypes.DOCUMENT_MODAL_UPDATE_LAST_ACCESS_DATE,
    lastAccessDate
  }
}

export function openDocumentCreationModalFleets (dossierIds, organizedUnitId, callback, refreshAfterChange) {
  return {
    type: documentaryManagementModalActionTypes.OPEN_DOCUMENT_CREATION_MODAL_FLEETS,
    dossierIds,
    organizedUnitId,
    callback,
    refreshAfterChange
  }
}
export function requestSign (documentId, sheetId, callback, refreshAfterChange, signTypeId, resolve) {
  return {
    type: documentaryManagementModalActionTypes.REQUEST_SIGN,
    documentId,
    sheetId,
    callback,
    refreshAfterChange,
    signTypeId,
    resolve
  }
}

export function openDocumentCreationModalFleetsSuccess (organizedUnitId, dossierIds, callback, refreshAfterChange) {
  return {
    type: documentaryManagementModalActionTypes.OPEN_DOCUMENT_CREATION_MODAL_FLEETS_SUCCESS,
    organizedUnitId,
    dossierIds,
    callback,
    refreshAfterChange
  }
}

export function cancelSign (documentId, sheetId, callback, refreshAfterChange, multipleSignCancel, forceCancelDocument) {
  return {
    type: documentaryManagementModalActionTypes.CANCEL_SIGN,
    documentId,
    sheetId,
    callback,
    refreshAfterChange,
    multipleSignCancel,
    forceCancelDocument
  }
}

export function cancelSignMessage (message, resolve, reject) {
  return {
    type: documentaryManagementModalActionTypes.CANCEL_SIGN_MESSAGE,
    message,
    resolve,
    reject
  }
}

export function cancelSignMessageSuccess (message) {
  return {
    type: documentaryManagementModalActionTypes.CANCEL_SIGN_MESSAGE_SUCCESS,
    message
  }
}

export function onDocumentTypeIdChangeFleets (documentTypeId, documentTypeUseName, dossierIds) {
  return {
    type: documentaryManagementModalActionTypes.ON_DOCUMENT_TYPE_ID_CHANGE_FLEETS,
    documentTypeId,
    documentTypeUseName,
    dossierIds
  }
}

export function fetchDocumentFleetsSuccess (docResult, documentTypeId, documentTypeUseName, dossierIds) {
  return {
    type: documentaryManagementModalActionTypes.FETCH_DOCUMENT_FLEETS_SUCCESS,
    docResult,
    documentTypeId,
    documentTypeUseName,
    dossierIds
  }
}

export function createDocumentFleets (file, checklistId, documentTypeUseId, dossierIds) {
  return {
    type: documentaryManagementModalActionTypes.CREATE_DOCUMENT_FLEETS,
    file,
    checklistId,
    documentTypeUseId,
    dossierIds
  }
}

export function checkDependenceMetadato (metadatoId, resolve) {
  return {
    type: documentaryManagementModalActionTypes.CHECK_DEPENDENCE_METADATO,
    metadatoId,
    resolve
  }
}

export function signTypes (resolve, reject, documentId, documentTypeUseId = null, dossierId = null, pristine = null) {
  return {
    type: documentaryManagementModalActionTypes.SIGN_TYPES,
    resolve,
    reject,
    documentId,
    documentTypeUseId,
    dossierId,
    pristine
  }
}

export function extractMetadataAction (doc, organizedUnitId) {
  return {
    type: documentaryManagementModalActionTypes.EXTRACT_METADATA,
    doc,
    organizedUnitId
  }
}

export function updateMetaLoading (metaLoading) {
  return {
    type: documentaryManagementModalActionTypes.LOADING_METADATA,
    metaLoading
  }
}

export function accepMultipleSignCancel () {
  return {
    type: documentaryManagementModalActionTypes.ACCEPT_MULTIPLE_SIGN_CANCEL
  }
}

export function rejectMultipleSignCancel () {
  return {
    type: documentaryManagementModalActionTypes.REJECT_MULTIPLE_SIGN_CANCEL
  }
}

export function getCancelDocListAction (documentId, sheetId, resolve, reject) {
  return {
    type: documentaryManagementModalActionTypes.GET_CANCEL_DOC_LIST,
    documentId,
    sheetId,
    resolve,
    reject
  }
}

export function openDocumentEditionModalFleet (mode, documentId, documentTypeUse, documentParentTypeId, dossierIds, entityIds, vehicleIds, fleetDocumentDetails, organizedUnitId, checklistId, callback, refreshAfterChange, dossierType, dossierSubType, lastAccessDate, pristine, chargeId = null, isRecurrent, editable, readOnlyFields, fromTask = false, buttonRefund = false, chargeIdParent = null, fleetId, fleetChargeId) {
  return {
    type: documentaryManagementModalActionTypes.OPEN_DOCUMENT_EDITION_MODAL_FLEETS,
    mode,
    documentId,
    documentTypeUse,
    documentParentTypeId,
    dossierIds,
    entityIds,
    vehicleIds,
    organizedUnitId,
    checklistId,
    callback,
    refreshAfterChange,
    dossierType,
    dossierSubType,
    lastAccessDate,
    chargeId,
    pristine,
    isRecurrent,
    editable,
    readOnlyFields,
    fromTask,
    buttonRefund,
    chargeIdParent,
    fleetDocumentDetails,
    fleetId,
    fleetChargeId
  }
}

export function openDocumentEditionModalSuccessFleets (mode, docResult, documentTypeUseInfo, organizedUnitId, callback, dossierIds, entityIds, vehicleIds, fleetDocumentDetails, documentParentTypeId, checklistId, refreshAfterChange, dossierType, dossierSubType, lastAccessDate, dirty, isRecurrent, editable, readOnlyFields, fleetId, fleetChargeId) {
  return {
    type: documentaryManagementModalActionTypes.OPEN_DOCUMENT_EDITION_MODAL_SUCCESS_FEETS,
    mode,
    docResult,
    documentParentTypeId,
    documentTypeUseInfo,
    organizedUnitId,
    callback,
    dossierIds,
    entityIds,
    vehicleIds,
    checklistId,
    refreshAfterChange,
    dossierType,
    dossierSubType,
    lastAccessDate,
    dirty,
    isRecurrent,
    editable,
    readOnlyFields,
    fleetDocumentDetails,
    fleetId,
    fleetChargeId
  }
}

export function sealStampDocument (documentId, dossierId, resolve) {
  return {
    type: documentaryManagementModalActionTypes.SEAL_STAMP_DOCUMENT,
    documentId,
    dossierId,
    resolve
  }
}

export function createSignDocumentFromTemplate (documentTypeUseId, organizedUnitId, documentTypeId, sign, dossierId, id, checklistId, chargeId, dynamicValues, dossierType, lastAccessDate, resolve, reject) {
  return {
    type: documentaryManagementModalActionTypes.CREATE_SIGN_DOCUMENT_DYNAMIC_FROM_TEMPLATE,
    documentTypeUseId,
    organizedUnitId,
    documentTypeId,
    sign,
    dossierId,
    id,
    checklistId,
    chargeId,
    dynamicValues,
    dossierType,
    lastAccessDate,
    resolve,
    reject
  }
}

export function printDocumentDynamicFromTemplate (documentTypeUseId, dossierId, dynamicValues) {
  return {
    type: documentaryManagementModalActionTypes.GENERATE_DOCUMENT_DYNAMIC_FROM_TEMPLATE,
    documentTypeUseId,
    dossierId,
    dynamicValues
  }
}

export default {
  openDocumentCreationModal,
  openDocumentCreationModalSuccess,
  openDocumentEditionModal,
  openDocumentEditionModalSuccess,
  onDocumentTypeIdChange,
  closeAddOrEditDocumentModal,
  closeAddOrEditDocumentModalSuccess,
  fetchDocument,
  fetchDocumentSuccess,
  createDocument,
  uploadDocument,
  uploadNewVersion,
  saveDocument,
  toggleFullScreenDocumentModal,
  rejectDocument,
  validateDocument,
  changeId,
  recoverDocument,
  createDocumentSheet,
  deleteDocumentSheet,
  setActivePage,
  toggleFullScreenDocumentSheetModal,
  checkCanCloseDocumentModal,
  updateDocumentModalLastAccessDate,
  openDocumentCreationModalFleets,
  openDocumentCreationModalFleetsSuccess,
  onDocumentTypeIdChangeFleets,
  fetchDocumentFleetsSuccess,
  createDocumentFleets,
  requestSign,
  cancelSign,
  checkDependenceMetadato,
  signTypes,
  extractMetadataAction,
  updateMetaLoading,
  accepMultipleSignCancel,
  rejectMultipleSignCancel,
  refreshDocResul,
  refreshDocResulSuccess,
  cancelSignMessage,
  cancelSignMessageSuccess,
  getCancelDocListAction,
  openDocumentEditionModalFleet,
  openDocumentEditionModalSuccessFleets,
  sealStampDocument,
  createSignDocumentFromTemplate,
  printDocumentDynamicFromTemplate
}
