import mastersActionTypes from '../../../constants/actions/masters/masters'

export function fetchCustomFieldList (filter) {
  return {
    type: mastersActionTypes.FETCH_CUSTOM_FIELD_LIST,
    filter
  }
}

export function fetchCustomFieldListSuccess (customFieldList, filter) {
  return {
    type: mastersActionTypes.FETCH_CUSTOM_FIELD_LIST_SUCCESS,
    customFieldList: customFieldList,
    filter
  }
}

export function openCustomFieldModalSuccess (languageList, customField, viewComponents, dossierSubTypeId) {
  return {
    type: mastersActionTypes.OPEN_CUSTOM_FIELD_MODAL_SUCCESS,
    languageList,
    customField,
    viewComponents,
    dossierSubTypeId
  }
}

export function closeCustomFieldModal () {
  return {
    type: mastersActionTypes.CLOSE_EDIT_CUSTOM_FIELD_MODAL
  }
}

export function deleteCustomField (customFieldId) {
  return {
    type: mastersActionTypes.DELETE_CUSTOM_FIELD,
    customFieldId
  }
}

export function recoverCustomField (id) {
  return {
    type: mastersActionTypes.RECOVER_CUSTOM_FIELD,
    id
  }
}

export function openCustomFieldModal (customField, dossierSubTypeId) {
  return {
    type: mastersActionTypes.OPEN_CUSTOM_FIELD_MODAL,
    customField,
    dossierSubTypeId
  }
}

export function submitCustomField (customField) {
  return {
    type: mastersActionTypes.SUBMIT_CUSTOM_FIELD,
    customField: customField
  }
}
