import profileActionTypes from '../../constants/actions/profile/profile'

export function saveEventSubscription (subscriptionId, eventSubscription, userId, resolve, reject) {
  return {
    type: profileActionTypes.SAVE_EVENT_SUBSCRIPTION,
    subscriptionId,
    eventSubscription,
    userId,
    resolve,
    reject
  }
}

export function removeEventSubscription (subscriptionId, userId) {
  return {
    type: profileActionTypes.DELETE_EVENT_SUBSCRIPTION,
    subscriptionId,
    userId
  }
}

export function fetchEventSubscriptions (userId) {
  return {
    type: profileActionTypes.FETCH_EVENT_SUBSCRIPTIONS,
    userId
  }
}

export function fetchEventSubscriptionsSuccess (eventSubscriptions) {
  return {
    type: profileActionTypes.FETCH_EVENT_SUBSCRIPTIONS_SUCCESS,
    eventSubscriptions
  }
}

export function enableEventSubscription (subscriptionId, userId) {
  return {
    type: profileActionTypes.ENABLE_EVENT_SUBSCRIPTION,
    subscriptionId,
    userId
  }
}

export function disableEventSubscription (subscriptionId, userId) {
  return {
    type: profileActionTypes.DISABLE_EVENT_SUBSCRIPTION,
    subscriptionId,
    userId
  }
}

export function profileSectionChangeSuccess (secMapProfile) {
  return {
    type: profileActionTypes.PROFILE_SECTION_CHANGE_SUCCESS,
    secMapProfile
  }
}

export default {
  fetchUserProfile: () => ({ type: profileActionTypes.FETCH_USER_PROFILE }),
  fetchUserProfileSuccess: (data, languages) => ({ type: profileActionTypes.FETCH_USER_PROFILE_SUCCESS, data, languages }),
  updateUserProfile: (values, t, resolve, reject) => ({ type: profileActionTypes.UPDATE_USER_PROFILE, values, t, resolve, reject }),
  fetchComboSalesmanByUO: (organizedUnitId, resolve, reject) => ({ type: profileActionTypes.FETCH_SALESMAN_COMBO, organizedUnitId, resolve, reject }),
  fetchComboSalesmanByUOSuccess: (salesmanCombo) => ({ type: profileActionTypes.FETCH_SALESMAN_COMBO_SUCCESS, salesmanCombo }),
  saveEventSubscription,
  removeEventSubscription,
  fetchEventSubscriptions,
  fetchEventSubscriptionsSuccess,
  enableEventSubscription,
  disableEventSubscription,
  profileSectionChangeSuccess
}
