import { call, put, select, takeLatest } from 'redux-saga/effects'
import { getAuth } from '../../selectors/access/auth'
import { showLoader, hideLoader } from '../../actions/common'
import messageModalActions from '../../actions/modals/messageModal'
import { fetchCombosSuccess } from '../../actions/combos/combos'
import combosActionTypes from '../../constants/actions/combos/combos'
import { handleError } from '../errors/errorManager'
import getModelCombo from '../../services/model/getModelCombo'

export function * fetchModelCombo () {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const modelCombo = yield call(getModelCombo, auth.token)
    yield put(fetchCombosSuccess({ modelCombo }))
  } catch (error) {
    const customHandler = function * () {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.FETCH_COMBO_ERROR'))
    }
    yield call(handleError, { error, customHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchModelCombo () {
  yield takeLatest(combosActionTypes.FETCH_MODEL_YEAR, fetchModelCombo)
}
