import React, { PureComponent } from 'react'
import { Col, Clearfix } from 'react-bootstrap'
import FaqSectionItem from './FaqSectionItem'

class FaqSections extends PureComponent {
  constructor () {
    super()
    this.state = {
    }
  }

  componentWillMount () {
    this.props.actions.fetchSectionList()
  }

  render () {
    const {
      t, sections, openQuestion, openSection
    } = this.props

    return (
      <div className="sections-container">
        <div>
          <div className="sections">
            {
              sections && sections.map((section, idx) => {
                return (
                  [(idx % 3 === 0 ? <Clearfix key={'c' + idx}></Clearfix> : null),
                    <Col sm={4} key={idx}>
                      <FaqSectionItem
                        t={t}
                        section={section}
                        openQuestion={(questionId) => openQuestion(questionId)}
                        openSection={(sectionId, sectionTitle) => openSection(sectionId, sectionTitle)}
                      />
                    </Col>
                  ]
                )
              })
            }
          </div>
        </div>
      </div>
    )
  }
}

export default FaqSections
