import { createInstance } from '@datapunt/matomo-tracker-react'
import settings from './setting'

var disableMotomo = false
// process.argv.includes('--local')

const instance = createInstance({
  urlBase: settings.matomoUrl,
  siteId: settings.matomoSiteId,
  //   userId: 'UID76903202', // optional, default value: `undefined`.
  trackerUrl: settings.matomoUrl + '/matomo.php', // optional, default value: `${urlBase}matomo.php`
  srcUrl: settings.matomoUrl + '/matomo.js', // optional, default value: `${urlBase}matomo.js`
  disabled: disableMotomo, // optional, false by default. Makes all tracking calls no-ops if set to true.
  heartBeat: { // optional, enabled by default
    active: true, // optional, default value: true
    seconds: 10 // optional, default value: `15
  },
  linkTracking: false, // optional, default value: true
  configurations: { // optional, default value: {}
    // any valid matomo configuration, all below are optional
    disableCookies: true,
    setSecureCookie: true,
    setRequestMethod: 'POST'
  }
})

export default instance
