import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getFormValues } from 'redux-form'
import DocumentalSearchForm from '../../../components/search/documentalSearch/DocumentalSearchForm'
import { fetchDocumentalSearchFormCombos, findFilterDocuments, resetFilters, setFilters, initializeSearch } from '../../../actions/search/documentalSearch/documentalSearchForm'
import { translate } from 'react-polyglot'
import { fetchDossierSubTypes, fetchStagesCombo, fetchLowestOrganizedUnits, fetchSearchDossierSubType, fetchEntitySubTypeCombo, fetchEntityFilterSubTypeCombo } from '../../../actions/combos/combos'
import { openModal } from '../../../actions/common'

export function mapStateToProps (state, props) {
  return {
    ...state.documentalSearch,
    combos: {
      documentEntityTypeCombo: state.combos.documentEntityTypeCombo,
      entitySubTypeCombo: state.combos.entitySubTypeCombo,
      documentStatusCombo: state.combos.documentStatusCombo,
      documentsTypesCombo: state.combos.documentsTypesCombo,
      entityTypesCombo: state.combos.entityTypesCombo,
      dossierSubtypesCombo: state.combos.dossierSubTypesCombo,
      entityFilterSubTypeCombo: state.combos.entityFilterSubTypeCombo,
      stagesCombo: state.combos.stagesCombo,
      lowestOrganizedUnitsCombo: state.combos.lowestOrganizedUnitsCombo,
      salesmanCombo: state.combos.salesmanCombo,
      brandCombo: state.combos.brandCombo,
      searchDossierSubtypesCombo: state.combos.searchDossierSubtypesCombo
    },
    formValues: getFormValues('documentalSearchForm')(state)
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchDocumentalSearchFormCombos,
      findFilterDocuments,
      fetchDossierSubTypes,
      fetchStagesCombo,
      fetchEntityFilterSubTypeCombo,
      fetchLowestOrganizedUnits,
      resetFilters,
      openModal,
      fetchSearchDossierSubType,
      setFilters,
      initializeSearch,
      fetchEntitySubTypeCombo
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(DocumentalSearchForm))
