import auxModals from '../../../constants/actions/management/auxModals/auxModals'

export function closeFleetCreatedSelectChargeModal () {
  return {
    type: auxModals.CLOSE_FLEET_CREATED_SELECT_CHARGE_MODAL
  }
}

export function openFleetCreatedSelectChargeModal (fleet) {
  return {
    type: auxModals.OPEN_FLEET_CREATED_SELECT_CHARGE_MODAL,
    fleet
  }
}

export function closeAddDossiersToFleetModal () {
  return {
    type: auxModals.CLOSE_ADD_DOSSIERS_TO_FLEET_MODAL
  }
}

export function openAddDossiersToFleetModal (fleet) {
  return {
    type: auxModals.OPEN_ADD_DOSSIERS_TO_FLEET_MODAL,
    fleet
  }
}

export function fetchDossiersToAddToFleet (filters) {
  return {
    type: auxModals.FETCH_DOSSIERS_TO_ADD_TO_FLEET,
    filters
  }
}

export function fetchDossiersToAddToFleetSuccess (dossiers, filters) {
  return {
    type: auxModals.FETCH_DOSSIERS_TO_ADD_TO_FLEET_SUCCESS,
    dossiers,
    filters
  }
}

export function resetDossiersToAddToFleetSearch () {
  return {
    type: auxModals.DOSSIERS_TO_ADD_TO_FLEET_RESET_SEARCH
  }
}

export function fetchAddDossierToFleet (dossierId, fleetId, resolve, reject) {
  return {
    type: auxModals.FETCH_ADD_DOSSIER_TO_FLEET,
    dossierId,
    fleetId,
    resolve,
    reject
  }
}

export function openDataSalesFleetModal (fleet, dossiers) {
  return {
    type: auxModals.OPEN_DATA_SALES_FLEET_MODAL,
    fleet,
    dossiers
  }
}

export function closeDataSalesFleetModal () {
  return {
    type: auxModals.CLOSE_DATA_SALES_FLEET_MODAL
  }
}

export function fetchSalesmanCombos (organizedUnitId) {
  return {
    type: auxModals.FETCH_SALESMAN_COMBOS,
    organizedUnitId
  }
}

export function fetchSalesTypeCombo (organizedUnitId, dossierSubTypeId) {
  return {
    type: auxModals.FETCH_SALES_TYPE_COMBO,
    organizedUnitId,
    dossierSubTypeId
  }
}

export function openDataContactFleetModal (fleet, dossiers) {
  return {
    type: auxModals.OPEN_DATA_CONTACT_FLEET_MODAL,
    fleet,
    dossiers
  }
}

export function closeDataContactFleetModal () {
  return {
    type: auxModals.CLOSE_DATA_CONTACT_FLEET_MODAL
  }
}

export function fetchEntityOperateFleetSucces (data) {
  return {
    type: auxModals.FETCH_ENTITY_OPERATE_FLEET_SUCCES,
    data
  }
}

export function fetchEntityOperateChildFleetSucces (data) {
  return {
    type: auxModals.FETCH_ENTITY__OPERATE_CHILD_FLEET_SUCCES,
    data
  }
}

export function openDataPaperWorkFleetModal (fleet, dossiers) {
  return {
    type: auxModals.OPEN_DATA_PAPERWORK_FLEET_MODAL,
    fleet,
    dossiers
  }
}

export function closeDataPaperWorkFleetModal () {
  return {
    type: auxModals.CLOSE_DATA_PAPERWORK_FLEET_MODAL
  }
}

export function getShowEconomic (organizedUnitId, dossierSubtypeId) {
  return {
    type: auxModals.GET_FLEET_SHOW_ECONOMIC,
    organizedUnitId,
    dossierSubtypeId
  }
}

export function getShowEconomicSuccess (showEconomicFLeet) {
  return {
    type: auxModals.GET_FLEET_SHOW_ECONOMIC_SUCCESS,
    showEconomicFLeet
  }
}

export function openDataFinancingFleetModal (fleet, dossiers) {
  return {
    type: auxModals.OPEN_DATA_FINANCING_FLEET_MODAL,
    fleet,
    dossiers
  }
}

export function closeDataFinancingFleetModal () {
  return {
    type: auxModals.CLOSE_DATA_FINANCING_FLEET_MODAL
  }
}

export function fetchDocumentDetailsFleet (documentTypeUseId, fleetId, dossierList) {
  return {
    type: auxModals.FETCH_DOCUMENT_DETAIL_FLEET,
    documentTypeUseId,
    fleetId,
    dossierList
  }
}

export function fetchDocumentDetailsFleetSuccess (documentDetails) {
  return {
    type: auxModals.FETCH_DOCUMENT_DETAIL_FLEET_SUCCESS,
    documentDetails
  }
}

export function openDocumentDetailsFleetModal (fleet, documentFleet, dossierList, dossierMode) {
  return {
    type: auxModals.OPEN_DOCUMENT_DETAILS_FLEET_MODAL,
    fleet,
    documentFleet,
    dossierList,
    dossierMode
  }
}

export function closeDocumentDetailsFleetModal () {
  return {
    type: auxModals.CLOSE_DOCUMENT_DETAILS_FLEET_MODAL
  }
}

export function openDataDeliveryFleetModal (fleet, dossiers) {
  return {
    type: auxModals.OPEN_DATA_DELIVERY_FLEET_MODAL,
    fleet,
    dossiers
  }
}

export function closeDataDeliveryFleetModal () {
  return {
    type: auxModals.CLOSE_DATA_DELIVERY_FLEET_MODAL
  }
}

export function openChargeDossiersModal (fleet) {
  return {
    type: auxModals.OPEN_CHARGE_DOSSIERS_TO_FLEET_MODAL,
    fleet
  }
}

export function closeChargeDossiersModal () {
  return {
    type: auxModals.CLOSE_CHARGE_DOSSIERS_TO_FLEET_MODAL
  }
}

export function updateChargeDossiersModal (errors) {
  return {
    type: auxModals.UPDATE_CHARGE_DOSSIERS_TO_FLEET_MODAL,
    errors
  }
}

export function openCloseUnattendedProcessDownloadFileModal (showModal, unattendedProcessId) {
  return {
    type: auxModals.OPEN_CLOSE_UNATTENDED_PROCESS_DOWNLOAD_FILE_MODAL,
    showModal,
    unattendedProcessId
  }
}

export function fetchUnattendedProcessFileListInfo (unattendedProcessId) {
  return {
    type: auxModals.GET_UNATTENDED_PROCESS_FILE_LIST_INFO,
    unattendedProcessId
  }
}

export function fetchUnattendedProcessFileListInfoSucces (fileInfoList) {
  return {
    type: auxModals.GET_UNATTENDED_PROCESS_FILE_LIST_INFO_SUCCESS,
    fileInfoList
  }
}

export function fetchFleetOrganizedUnits (organizedUnitId) {
  return {
    type: auxModals.FETCH_FLEET_ORGANIZED_UNITS,
    organizedUnitId
  }
}

export function fetchFleetOrganizedUnitsSuccess (fleetOrganizedUnits) {
  return {
    type: auxModals.FETCH_FLEET_ORGANIZED_UNITS_SUCCESS,
    fleetOrganizedUnits
  }
}

export function openChargeDetailsModal (fleetCharge, fleet) {
  return {
    type: auxModals.OPEN_CHARGE_DETAILS_TO_FLEET_MODAL,
    fleetCharge,
    fleet
  }
}

export function openChargeDetailsModalSuccess (fleetCharge, dossierChargeDetails, fleet) {
  return {
    type: auxModals.OPEN_CHARGE_DETAILS_TO_FLEET_MODAL_SUCCESS,
    fleetCharge,
    dossierChargeDetails,
    fleet
  }
}

export function closeChargeDetailsModal () {
  return {
    type: auxModals.CLOSE_CHARGE_DETAILS_TO_FLEET_MODAL
  }
}

export default {
  closeFleetCreatedSelectChargeModal,
  openFleetCreatedSelectChargeModal,
  closeAddDossiersToFleetModal,
  openAddDossiersToFleetModal,
  fetchDossiersToAddToFleet,
  fetchDossiersToAddToFleetSuccess,
  resetDossiersToAddToFleetSearch,
  fetchAddDossierToFleet,
  openDataSalesFleetModal,
  closeDataSalesFleetModal,
  fetchSalesmanCombos,
  fetchSalesTypeCombo,
  openDataContactFleetModal,
  closeDataContactFleetModal,
  fetchEntityOperateFleetSucces,
  fetchEntityOperateChildFleetSucces,
  openDataPaperWorkFleetModal,
  closeDataPaperWorkFleetModal,
  getShowEconomic,
  getShowEconomicSuccess,
  openDataFinancingFleetModal,
  closeDataFinancingFleetModal,
  fetchDocumentDetailsFleet,
  fetchDocumentDetailsFleetSuccess,
  openDocumentDetailsFleetModal,
  closeDocumentDetailsFleetModal,
  openDataDeliveryFleetModal,
  closeDataDeliveryFleetModal,
  openCloseUnattendedProcessDownloadFileModal,
  fetchUnattendedProcessFileListInfo,
  fetchUnattendedProcessFileListInfoSucces,
  fetchFleetOrganizedUnits,
  fetchFleetOrganizedUnitsSuccess,
  openChargeDossiersModal,
  closeChargeDossiersModal
}
