export default {
  CLOSE_EDIT_MARITALSTATUS_MODAL: 'CLOSE_EDIT_MARITALSTATUS_MODAL',
  DELETE_MARITALSTATUS: 'DELETE_MARITALSTATUS',
  RESTART_MARITALSTATUS: 'RESTART_MARITALSTATUS',
  FETCH_MARITALSTATUS_LIST: 'FETCH_MARITALSTATUS_LIST',
  FETCH_MARITALSTATUS_LIST_SUCCESS: 'FETCH_MARITALSTATUS_LIST_SUCCESS',
  OPEN_ADD_MARITALSTATUS_MODAL_SUCCESS: 'OPEN_ADD_MARITALSTATUS_MODAL_SUCCESS',
  OPEN_EDIT_MARITALSTATUS_MODAL_SUCCESS: 'OPEN_EDIT_MARITALSTATUS_MODAL_SUCCESS',
  OPEN_MARITALSTATUS_MODAL: 'OPEN_MARITALSTATUS_MODAL',
  SUBMIT_MARITALSTATUS: 'SUBMIT_MARITALSTATUS',
  OPEN_EDIT_MARITALSTATUS_MODAL: 'OPEN_EDIT_MARITALSTATUS_MODAL'
}
