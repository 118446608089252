import apiFetch from '../../apiFetch'

export default function (paperworkId, token, lastAccessDate) {
  return apiFetch({
    endPoint: `dossier/Paperwork/${paperworkId}/file`,
    method: 'GET',
    parseBlobAndAssign: true,
    lastAccessDate,
    token: token
  })
}
