import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { updatePaperwokTypeMaster, adviceClosePaperwokTypeMasterModal, postPwkTypeMasterModal } from '../../../actions/masters/masters'
import PwkTypeMasterModal from '../../../components/masters/paperworkTypes/PwkTypeMasterModal'

export function mapStateToProps (state) {
  const pwkTypeModal = state.paperworkTypesMaster.pwkTypeModal
  return {
    languageList: state.paperworkTypesMaster.languageList,
    showModal: pwkTypeModal.showModal,
    pwkType: pwkTypeModal.paperworkType,
    pwkTypeId: pwkTypeModal.paperworkType ? pwkTypeModal.paperworkType.paperworkTypeId : null
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      updatePaperwokTypeMaster,
      adviceClosePaperwokTypeMasterModal,
      postPwkTypeMasterModal
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(PwkTypeMasterModal))
