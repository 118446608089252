import React, { PureComponent } from 'react'
import { Row, Table, Button, Col } from 'react-bootstrap'
import { currencySymbol } from '../../../../../constants/dossier/common/currencies'
import settings from '../../../../../setting'

class TabPane8 extends PureComponent {
  render () {
    return (
      <Row className="collection-tabpane">
        <div className="button-wrapper">
          <Button className="btn-standard-white">
            <i className="ico-plus" /> Alta cobro
          </Button>
          <Button className="btn-standard-white">
            <i className="ico-check" /> Aprobar cobros
          </Button>
          <Button className="btn-standard-white btn-right">
            Adelantar trámite de tráfico
          </Button>
        </div>
        <Row>
          <Col sm={3}>
            <div className="text-band-collection">
              <span className="point red"></span>
              <span className="first">Importe pendiente</span>
              <span className="second">25.000,00{currencySymbol[settings.currency]}</span>
            </div>
            <div className="text-band-collection">
              <span className="point green"></span>
              <span className="first">Pagado</span>
              <span className="second">0,00{currencySymbol[settings.currency]}</span>
            </div>
            <div className="text-band-collection">
              <span className="point white"></span>
              <span className="first">Importe venta</span>
              <span className="second">25.000,00{currencySymbol[settings.currency]}</span>
            </div>
          </Col>
          <Col sm={9}>
            <Table responsive className="table-tabPane">
              <thead>
                <tr>
                  <th>Cobros</th>
                  <th>Importe</th>
                  <th>Forma de pago</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Total señal</td>
                  <td>0,0</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Cobro 1</td>
                  <td>0,0</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Cobro 2</td>
                  <td>0,0</td>
                  <td></td>
                </tr>
                <tr>
                  <td><span>Total cobros</span></td>
                  <td><span>0,0</span></td>
                  <td></td>
                </tr>
                <tr>
                  <td>Importe total</td>
                  <td>0,0</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Importe UaC</td>
                  <td>0,0</td>
                  <td></td>
                </tr>
                <tr>
                  <td><span>Total pendiente</span></td>
                  <td><span>0,0</span></td>
                  <td></td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
      </Row>
    )
  }
}

export default TabPane8
