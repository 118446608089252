import apiFetch from '../apiFetch'

export default function (documentId, token, lastAccessDate) {
  return apiFetch({
    endPoint: `document/${documentId}/recover`,
    method: 'PUT',
    body: null,
    token: token,
    lastAccessDate
  })
}
