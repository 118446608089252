import dossierActionTypes from '../../constants/actions/dossier/dossier'
import { refreshAuditInfo, fetchAuditInfoSucces, fetchAuditDetails, fetchAuditDetailsModal, fetchDocumentsGroupedAuditDetails, fetchDocumentsGroupedAuditDetailsModal } from '../audit/auditInfo'

export function fetchRegistrationDeliveryDossier (dossierId, resolve, reject, historical) {
  return {
    type: dossierActionTypes.FETCH_REGISTRATION_DELIVERY_DOSSIER,
    dossierId,
    resolve,
    reject,
    historical
  }
}

export function fetchRegistrationDeliveryVehicle (dossierId, resolve, reject, pristine) {
  return {
    type: dossierActionTypes.FETCH_REGISTRATION_DELIVERY_DOSSIER_VEHICLE,
    dossierId,
    resolve,
    reject,
    pristine
  }
}

export function fetchRegistrationDeliveryVehicleHistorical (dossierId, resolve, reject) {
  return {
    type: dossierActionTypes.FETCH_REGISTRATION_DELIVERY_DOSSIER_VEHICLE_HISTORICAL,
    dossierId,
    resolve,
    reject
  }
}

export function fetchRegistrationDeliveryClient (dossierId, resolve, reject, pristine) {
  return {
    type: dossierActionTypes.FETCH_REGISTRATION_DELIVERY_DOSSIER_CLIENT,
    dossierId,
    resolve,
    reject,
    pristine
  }
}

export function fetchRegistrationDeliveryClientHistorical (dossierId, resolve, reject) {
  return {
    type: dossierActionTypes.FETCH_REGISTRATION_DELIVERY_DOSSIER_CLIENT_HISTORICAL,
    dossierId,
    resolve,
    reject
  }
}

export function fetchRegistrationDeliveryDelivery (dossierId, resolve, reject, pristine) {
  return {
    type: dossierActionTypes.FETCH_REGISTRATION_DELIVERY_DOSSIER_DELIVERY,
    dossierId,
    resolve,
    reject,
    pristine
  }
}

export function fetchRegistrationDeliveryDeliveryHistorical (dossierId, resolve, reject) {
  return {
    type: dossierActionTypes.FETCH_REGISTRATION_DELIVERY_DOSSIER_DELIVERY_HISTORICAL,
    dossierId,
    resolve,
    reject
  }
}

export function fetchRegistrationDeliveryDossierDependants (dossierId, historical) {
  return {
    type: dossierActionTypes.FETCH_REGISTRATION_DELIVERY_DOSSIER_DEPENDANTS,
    dossierId,
    historical
  }
}

export function fetchRegistrationDeliveryDossierSuccess (data) {
  return {
    type: dossierActionTypes.FETCH_REGISTRATION_DELIVERY_DOSSIER_SUCCESS,
    data
  }
}

export function updateLastAccessDate (lastAccessDateNew) {
  return {
    type: dossierActionTypes.PUT_LASTACCESSDATE,
    lastAccessDateNew
  }
}

export function fetchInitializeRegistrationDeliveryComponent (registrationDeliveryTypeId, ouId) {
  return {
    type: dossierActionTypes.FETCH_INITIALIZE_REGISTRATION_DELIVERY_COMPONENT,
    registrationDeliveryTypeId,
    ouId
  }
}

export function printDocumentaryChecklist () {
  return {
    type: dossierActionTypes.PRINT_DOCUMENTARY_CHECKLIST
  }
}

export function printDocumentaryWarranty () {
  return {
    type: dossierActionTypes.PRINT_DOCUMENTARY_WARRANTY
  }
}

export function saveRegistrationDeliveryDossier (registrationDeliveryDossier, resolve, reject) {
  return {
    type: dossierActionTypes.SAVE_REGISTRATION_DELIVERY_DOSSIER,
    registrationDeliveryDossier,
    resolve,
    reject
  }
}

export function fetchPaperworkEdition (paperworkId, openPaperworkEditionFn) {
  return {
    type: dossierActionTypes.FETCH_PAPERWORK_EDITION,
    paperworkId,
    openPaperworkEditionFn
  }
}

export function deletePaperwork (paperworkId) {
  return {
    type: dossierActionTypes.DELETE_PAPERWORK,
    paperworkId
  }
}

export function updatePaperwork (paperwork, closeModalFunc) {
  return {
    type: dossierActionTypes.UPDATE_PAPERWORK,
    paperwork,
    closeModalFunc
  }
}

export function createPaperwork (paperwork, closeModalFunc) {
  return {
    type: dossierActionTypes.CREATE_PAPERWORK,
    paperwork,
    closeModalFunc
  }
}

export function fetchPaperworkSuccess (paperworkList) {
  return {
    type: dossierActionTypes.FETCH_PAPERWORK_SUCCESS,
    paperworkList
  }
}

export function updateService (service, resolve, reject) {
  return {
    type: dossierActionTypes.UPDATE_SERVICE,
    service,
    resolve,
    reject
  }
}

export function createService (newService, resolve, reject) {
  return {
    type: dossierActionTypes.CREATE_SERVICE,
    newService,
    resolve,
    reject
  }
}

export function deleteService (service) {
  return {
    type: dossierActionTypes.DELETE_SERVICE,
    service
  }
}

export function fetchServicesSuccess (services) {
  return {
    type: dossierActionTypes.FETCH_SERVICE_SUCCESS,
    services
  }
}

export function registrationDeliveryApprove (pristine) {
  return {
    type: dossierActionTypes.REGISTRATION_DELIVERY_APPROVE,
    pristine
  }
}

export function registrationDeliveryApprovePaperwork (pristine) {
  return {
    type: dossierActionTypes.REGISTRATION_DELIVERY_APPROVE_PAPERWORK,
    pristine
  }
}

export function registrationDeliveryReject (comment, resolve, reject, pristine) {
  return {
    type: dossierActionTypes.REGISTRATION_DELIVERY_REJECT,
    comment,
    resolve,
    reject,
    pristine
  }
}

export function registrationDeliverySkipDocument (pristine) {
  return {
    type: dossierActionTypes.REGISTRATION_DELIVERY_SKIP_DOCUMENT,
    pristine
  }
}

export function registrationDeliverySkipPaperwork (pristine) {
  return {
    type: dossierActionTypes.REGISTRATION_DELIVERY_SKIP_PAPERWORK,
    pristine
  }
}

export function registrationDeliverySkipDelivery (pristine) {
  return {
    type: dossierActionTypes.REGISTRATION_DELIVERY_SKIP_DELIVERY,
    pristine
  }
}

export function updateDependantsRegistrationDeliveryDossierAndHeader (dossierId) {
  return {
    type: dossierActionTypes.UPDATE_DEPENDANTS_REGISTRATION_DELIVERY_DOSSIER_AND_HEADER,
    dossierId
  }
}

export function printPaperwork (paperworkId) {
  return {
    type: dossierActionTypes.PRINT_PAPERWORK,
    paperworkId
  }
}

export function restartRegistrationDeliveryState () {
  return {
    type: dossierActionTypes.RESTART_REGISTRATION_DELIVERY_STATE
  }
}

export function putPurchaseOnRegistrationDelivery (purchaseId, parentIsHistorical) {
  return {
    type: dossierActionTypes.PUT_PURCHASE_ON_REGISTRATION_DELIVERY,
    purchaseId,
    parentIsHistorical
  }
}

export function fetchDossierRelatedDossiersSuccess (dossierRelatedDossiers) {
  return {
    type: dossierActionTypes.FETCH_DOSSIER_RELATED_DOSSIERS_SUCCESS,
    dossierRelatedDossiers
  }
}

export function registrationDeliveryWarningSave (reset, router, nextLocation, pristine) {
  return {
    type: dossierActionTypes.REGISTRATION_DELIVERY_WARNING_SAVE,
    reset,
    router,
    nextLocation,
    pristine
  }
}

export function registrationDeliveryApproveByRole (pristine, approvalRoleCode) {
  return {
    type: dossierActionTypes.REGISTRATION_DELIVERY_APPROVE_BY_ROLE,
    pristine,
    approvalRoleCode
  }
}

export function fetchAllDocuments (filter) {
  return {
    type: dossierActionTypes.FETCH_DOCUMENTS_DOSSIERID,
    filter
  }
}

export function fetchAllDocumentsSuccess (documents) {
  return {
    type: dossierActionTypes.FETCH_DOCUMENTS_DOSSIERID_SUCCESS,
    documents
  }
}

export function saveSelectedDocuments (selectedDocuments, selectedDocumentHistorical) {
  return {
    type: dossierActionTypes.SAVE_SELECTED_DOCUMENTS,
    selectedDocuments,
    selectedDocumentHistorical
  }
}

export function openSearchDocumentsModal () {
  return {
    type: dossierActionTypes.OPEN_SEARCH_DOCUMENTS_MODAL
  }
}

export function skipItv (dossierId) {
  return {
    type: dossierActionTypes.SKIP_ITV,
    dossierId
  }
}

export function printDeliveryChecklist (dossierId) {
  return {
    type: dossierActionTypes.REGISTRATION_DELIVERY_PRINT_DELIVERY_CHECKLIST,
    dossierId
  }
}

export function fetchRegistrationDeliveryEconomic (dossierId, historical, pristine, resolve, reject) {
  return {
    type: dossierActionTypes.FETCH_REGISTRATION_DELIVERY_DOSSIER_ECONOMIC,
    dossierId,
    historical,
    pristine,
    resolve,
    reject
  }
}

export function fetchRegistrationDeliveryDossierEconomicSuccess (dossierEconomicComponent, dossierId, historical) {
  return {
    type: dossierActionTypes.FETCH_REGISTRATION_DELIVERY_DOSSIER_ECONOMIC_SUCCESS,
    dossierEconomicComponent,
    dossierId,
    historical
  }
}

export default {
  fetchRegistrationDeliveryDossier,
  fetchRegistrationDeliveryVehicle,
  fetchRegistrationDeliveryClient,
  fetchRegistrationDeliveryDelivery,
  fetchRegistrationDeliveryDeliveryHistorical,
  fetchRegistrationDeliveryVehicleHistorical,
  fetchRegistrationDeliveryClientHistorical,
  fetchRegistrationDeliveryDossierDependants,
  fetchRegistrationDeliveryDossierSuccess,
  fetchInitializeRegistrationDeliveryComponent,
  printDocumentaryChecklist,
  fetchPaperworkEdition,
  updatePaperwork,
  createPaperwork,
  deletePaperwork,
  updateService,
  createService,
  deleteService,
  fetchServicesSuccess,
  registrationDeliveryApprove,
  registrationDeliveryApprovePaperwork,
  registrationDeliveryReject,
  registrationDeliverySkipDocument,
  registrationDeliverySkipPaperwork,
  registrationDeliverySkipDelivery,
  saveRegistrationDeliveryDossier,
  updateDependantsRegistrationDeliveryDossierAndHeader,
  printPaperwork,
  restartRegistrationDeliveryState,
  fetchDossierRelatedDossiersSuccess,
  refreshAuditInfo,
  fetchAuditInfoSucces,
  fetchAuditDetails,
  fetchAuditDetailsModal,
  updateLastAccessDate,
  registrationDeliveryWarningSave,
  registrationDeliveryApproveByRole,
  fetchAllDocuments,
  fetchAllDocumentsSuccess,
  saveSelectedDocuments,
  openSearchDocumentsModal,
  printDocumentaryWarranty,
  skipItv,
  printDeliveryChecklist,
  fetchRegistrationDeliveryEconomic,
  fetchRegistrationDeliveryDossierEconomicSuccess,
  fetchDocumentsGroupedAuditDetails,
  fetchDocumentsGroupedAuditDetailsModal
}
