import React, { PureComponent } from 'react'
import { Field } from 'redux-form'
import { Button } from 'react-bootstrap'
import { validateDate } from '../../util/validationFunctions'
import SimpleTablePage from '../../containers/commons/general/SimpleTablePage'
import IDocCarPagination from '../commons/iDocCarPagination'
import InputCheckbox from '../commons/form/InputCheckBox'
import VehiclePopover from './VehiclePopover'
import DossierPopover from './DossierPopover'
import moment from 'moment'
import ApprovedServicesModal from './papServiceManager/ApprovedServicesModal'

class SearchByPlanLogisticsPapForm extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      enableConfirmButton: false,
      showConfirmButton: true,
      dossierIdArray: [],
      showResultApproveModal: false,
      selectAllDossiers: false,
      resultApprove: {}
    }
  }

  validateDate (value, props) {
    const result = validateDate(value, props)
    return result === Object(result) ? undefined : result
  }

  getSnapshotBeforeUpdate () {
    return null
  }

  componentDidUpdate (prevProps) {
    if (this.props.hasSearch !== prevProps.hasSearch && this.props.hasSearch) {
      this.restartState()
    }
  }

  restartState () {
    this.setState({
      enableConfirmButton: false,
      dossierIdArray: [],
      showResultApproveModal: false,
      selectAllDossiers: false
    })
  }

  selectAllDossiers (value) {
    const dossiers = []
    if (this.props.dossierData) {
      this.props.dossierData.map((row, idx) => {
        if (value) {
          dossiers.push(row.dossierId)
        }
      })
    }
    this.setState({ selectAllDossiers: value, enableConfirmButton: dossiers.length, dossierIdArray: dossiers })
  }

  setCheckboxValue (dossierId, check) {
    const dossierIdArray = this.state.dossierIdArray
    const index = dossierIdArray.findIndex(d => d === dossierId)
    if (index < 0) {
      dossierIdArray.push(dossierId)
    } else {
      dossierIdArray.splice(index, 1)
    }
    const selectedDossiersCount = dossierIdArray.length
    this.setState({ dossierIdArray: dossierIdArray, enableConfirmButton: selectedDossiersCount > 0 })

    if (!check && this.state.selectAllDossiers) {
      this.setState({ selectAllDossiers: false })
    }

    if (this.props.dossierData && dossierIdArray.length === this.props.dossierData.length) {
      this.setState({ selectAllDossiers: true })
    }
  }

  getCheckboxValue (dossierId) {
    return this.state.dossierIdArray.includes(dossierId)
  }

  showResultApproveModal () {
    new Promise((resolve, reject) => {
      return this.props.actions.saveServicesConfirmationLogitics(this.state.dossierIdArray, resolve, reject)
    }).then((data) => {
      this.setState({ showResultApproveModal: true, resultApprove: data })
      this.selectAllDossiers(false)
    })
  }

  closeConfirmationModal () {
    this.setState({ showResultApproveModal: false })
    this.props.onPageChange(this.props.page)
  }

  componentWillUnmount () {
    this.restartState()
  }

  render () {
    const {
      t, dossierData = [], getIconClass, pages, page, count, onPageChange, onOrderChange, vehicleData = [], openModal
    } = this.props
    const tKey = 'SEARCH.CATEGORIES.PLAN_LOGISTICS_PAP.'
    return (
      <div>
        <ApprovedServicesModal
          showModal = {this.state.showResultApproveModal}
          closeModal = {() => this.closeConfirmationModal() }
          resultApprove = {this.state.resultApprove}
          onPageChange
          onOrderChange
          vehicleData
          getIconClass={getIconClass}
          t = {t}
        />
        <div className="table-wrapper" key="table-wrapper">
          <SimpleTablePage
            columns={[
              <th key={0} data-field="dossier" onClick={(event) => onOrderChange(event)}>
                {t(`${tKey}DOSSIER`)}&nbsp;<i className={getIconClass('dossier')}/>
              </th>,
              <th key={1} data-field="vehicle">
                {t(`${tKey}VEHICLE`)}&nbsp;
              </th>,
              <th key={2} data-field="applicationDate" onClick={(event) => onOrderChange(event)}>
                {t(`${tKey}APPLICATION_DATE`)}&nbsp;<i className={getIconClass('applicationDate')}/>
              </th>,
              <th key={3} data-field="availabilityDate" onClick={(event) => onOrderChange(event)}>
                {t(`${tKey}AVAILABILITY_DATE`)}&nbsp;<i className={getIconClass('availabilityDate')}/>
              </th>,
              <th key={4} data-field="requiredDate" onClick={(event) => onOrderChange(event)}>
                {t(`${tKey}REQUIRED_DATE`)}&nbsp;<i className={getIconClass('requiredDate')}/>
              </th>,
              this.state.showConfirmButton && (
                <th key={5} data-field="selectAllDossiers" >
                  <Field
                    id='chkSelectAll'
                    component={InputCheckbox}
                    name='selectAllDossiers'
                    onChange={(value) => this.selectAllDossiers(value)}
                    placeholder = {t(`${tKey}SELECT_ALL`)}
                    inline
                    checked = { this.state.selectAllDossiers }
                  />
                </th>)
            ]}
            rows={dossierData.map((row, idx) => (
              <tr key={idx}>
                <td className={'dossier see-more'}>
                  <DossierPopover
                    idx={idx}
                    t={t}
                    id={'popover-dossier' + idx}
                    dossier={row.dossier}
                  />
                </td>
                <td className="see-more">
                  <VehiclePopover
                    t={t}
                    vehicle={row.vehicle}
                    half={(vehicleData.length || 1) / (idx + 1)}
                    idx={idx}
                    id={'popover-vehicle' + idx}
                  />
                </td>
                <td>{row.applicationDate ? moment(row.applicationDate).format('DD/MM/YYYY') : null}</td>
                <td>{row.availabilityDate ? moment(row.availabilityDate).format('DD/MM/YYYY') : null}</td>
                <td>{row.requiredDate ? moment(row.requiredDate).format('DD/MM/YYYY') : null}</td>
                { this.state.showConfirmButton && (
                  <td>{<Field
                    component={InputCheckbox}
                    name={'selectedDossier' + row.dossierId}
                    onChange={(value) => this.setCheckboxValue(row.dossierId, value)}
                    checked = {this.getCheckboxValue(row.dossierId)}
                  />}</td>)}
              </tr>
            ))}
          />
        </div>
        <div className="search-footer" key="search-footer">
          <IDocCarPagination
            id="btn_pag_dossiers"
            page={page}
            pagesCount={pages}
            totalRows= {count}
            onSelectAction={(page) => onPageChange(page)}
          />
        </div>
        <div key="search-footer-actions" className="search-footer-actions">
          { this.state.showConfirmButton &&
              <Button
                id='btm_appr_pap'
                name='approvePapButton'
                onClick={() => {
                  openModal('confirmLogitics', () => this.showResultApproveModal(), null, null, null,
                    [{ id: '##COUNT##', value: this.state.dossierIdArray.length ?? 0 }])
                }
                }
                className="btn-AcceptPap"
                disabled={!this.state.enableConfirmButton } >
                {t(`${tKey}CONFIRM`)}
              </Button>
          }
        </div>
      </div>
    )
  }
}

export default SearchByPlanLogisticsPapForm
