import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { submitFinancing, closeEditFinancingModal } from '../../../actions/masters/masters'
import FinancingMasterModal from '../../../components/masters/financing/FinancingMasterModal'

export function mapStateToProps (state) {
  const financingModal = state.financing.financingModal
  return {
    showModal: financingModal.showModal,
    financing: financingModal.financing,
    combos: {
      UOTreeCombo: state.combos.UOTreeCombo
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      submitFinancing,
      closeEditFinancingModal
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(FinancingMasterModal))
