import React, { PureComponent } from 'react'
import { Checkbox } from 'react-bootstrap'
import { formatMoney } from '../../../../util/utils'
import { Link } from 'react-router-dom'

class CampaignsRow extends PureComponent {
  render () {
    const {
      campaign: {
        number, name, type, amount, cost, stage, documents, urlToNavigate, amountPercentage, costPercentage, costConcessionaire, costConcessionPercentage
      }
    } = this.props
    return (
      <tr>
        <td>{urlToNavigate ? (<Link to={`/dossier${urlToNavigate}`}>{number}</Link>) : number}</td>
        <td>{name}</td>
        <td>{type}</td>
        <td className="text-left">{ amount ? formatMoney(amount) : amountPercentage ? amountPercentage + '%' : formatMoney(0)} </td>
        <td className="text-left">{ cost ? formatMoney(cost) : costPercentage ? costPercentage + '%' : formatMoney(0)}</td>
        <td className="text-left">{costConcessionaire && costConcessionaire !== 0 ? formatMoney(costConcessionaire) : costConcessionPercentage ? costConcessionPercentage + '%' : formatMoney(0)}</td>
        <td>{stage}</td>
        <td>
          {documents && documents.map((document, idx) => (
            <span key={idx}>
              <Checkbox className="campaign-checkbox" checked={document.completed} disabled inline>
                {document.name}
              </Checkbox></span>
          ))}</td>
        <td/>
      </tr>
    )
  }
}
export default CampaignsRow
