import apiFetch from '../apiFetch'

export default function (token, dossierId, lastAccessDate) {
  return apiFetch({
    endPoint: `dossier/Sale/${dossierId}/PrintChecklistVO`,
    method: 'GET',
    parseBlobAndAssign: true,
    lastAccessDate,
    token: token
  })
}
