import loadSepomexFileActionTypes from '../../constants/actions/loadSepomexFile/loadSepomexFile'

export function loadSepomexFileToValidate (body) {
  return {
    type: loadSepomexFileActionTypes.LOAD_SEPOMEX_FILE_TO_VALIDATE,
    body
  }
}

export function sepomexFileValidationSuccess (validationResult) {
  return {
    type: loadSepomexFileActionTypes.SEPOMEX_FILE_VALIDATION_SUCCESS,
    validationResult
  }
}

export function loadSepomexFileToImport (body) {
  return {
    type: loadSepomexFileActionTypes.LOAD_SEPOMEX_FILE_TO_IMPORT,
    body
  }
}

export function sepomexFileImportSuccess () {
  return {
    type: loadSepomexFileActionTypes.SEPOMEX_FILE_IMPORT_SUCCESS
  }
}

export function cancelLoadSepomexFile () {
  return {
    type: loadSepomexFileActionTypes.CANCEL_LOAD_SEPOMEX_FILE
  }
}

export default {
  sepomexFileValidationSuccess,
  loadSepomexFileToValidate,
  loadSepomexFileToImport,
  sepomexFileImportSuccess,
  cancelLoadSepomexFile
}
