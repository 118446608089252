import React, { PureComponent } from 'react'
import { reduxForm, Field } from 'redux-form'
import { Row, Col, Table, Button, Tooltip, OverlayTrigger } from 'react-bootstrap'
import PwkTypeMasterModalPage from '../../../containers/masters/PaperworkType/PwkTypeMasterModalPage'
import IDocCarPagination from '../../commons/iDocCarPagination'
import InputText from '../../commons/form/InputText'
import { permissions } from '../../../constants/backendIds'
import { getFieldsSectionPermissions } from '../../../util/permisionFunctions'

class PwkTypesMaster extends PureComponent {
  constructor () {
    super()
    this.state = {
      filter: {
        searchDescription: null,
        searchName: null
      },
      fieldsConfiguration: {
        deletePaperwokTypeMaster: null,
        addPaperwokTypeMaster: null
      }
    }
  }

  getSortIcon (column = '') {
    switch (this.props.filter.orderBy) {
      case '+' + column:
        return 'ico-sort-selected-asc'
      case '-' + column:
        return 'ico-sort-selected-desc'
      default:
        return 'ico-sort'
    }
  }

  changeOrder (field) {
    switch (this.props.filter.orderBy) {
      case `+${field}`:
        this.props.actions.fetchPaperwokTypesMaster({ ...this.state.filter, ...this.props.filter, orderBy: `-${field}` })
        break
      default:
        this.props.actions.fetchPaperwokTypesMaster({ ...this.state.filter, ...this.props.filter, orderBy: `+${field}` })
        break
    }
  }

  handleNameChange (searchName) {
    if (this.state.filter.searchName !== searchName) {
      this.setState({ filter: { ...this.state.filter, searchName } })
    }
  }

  handleDescriptionChange (searchDescription) {
    if (this.state.filter.searchDescription !== searchDescription) {
      this.setState({ filter: { ...this.state.filter, searchDescription } })
    }
  }

  searchPwkType () {
    this.props.actions.fetchPaperwokTypesMaster({ ...this.props.filter, ...this.state.filter, page: 1 })
  }

  componentDidUpdate (prevProps) {
    if (this.props.tabConfiguration !== prevProps.tabConfiguration) {
      if (this.props.tabConfiguration && this.props.tabConfiguration.sectionFieldsConfiguration) {
        const fieldKeys = Object.keys(this.state.fieldsConfiguration)
        const permission = getFieldsSectionPermissions(this.props.tabConfiguration.sectionFieldsConfiguration, fieldKeys, permissions.hidden)
        this.setState({ fieldsConfiguration: permission.fieldsConfiguration })
      }
    }
  }

  resetFilters () {
    this.setState({
      filter: {
        searchDescription: null,
        searchName: null
      }
    }, () => { this.searchPwkType() })
  }

  render () {
    const {
      t, pwkTypesList, pagesCount, pwkTypesCount, filter,
      filter: { page },
      actions: { fetchPaperwokTypeMasterInfo, deletePaperwokTypeMaster, fetchPaperwokTypesMaster }
    } = this.props
    const tKey = 'MASTERS.'
    const editTooltip = (<Tooltip id="editTooltip">{t(`${tKey}EDIT`)}</Tooltip>)
    const deleteTooltip = (<Tooltip id="deleteTooltip">{t(`${tKey}DELETE`)}</Tooltip>)
    const hasFilters = (this.state.filter.searchName || this.state.filter.searchDescription)
    return (
      <div className="admin-wrapper">
        <PwkTypeMasterModalPage />
        <Row className="section-users-header">
          <Col sm={12}>
            <div className="header-panel">
              <h4>{t(`${tKey}PWK_TYPES_MASTER.TITTLE`)}</h4>
            </div>
          </Col>
        </Row>
        <Row>
          <div className="search-form">
            <span className="title-search"><i className="ico-search" /> {t(`${tKey}PWK_TYPES_MASTER.SEARCH_TITLE`)}</span>
            <Row>
              <Field
                name="filters.name"
                colSm={2}
                controlLabel={t(`${tKey}PWK_TYPES_MASTER.NAME`)}
                placeholder={t(`${tKey}PWK_TYPES_MASTER.NAME`)}
                component={InputText}
                onInputChange={(value) => this.handleNameChange(value)}
              />
              <Field
                name="filters.description"
                colSm={2}
                controlLabel={t(`${tKey}PWK_TYPES_MASTER.DESCRIPTION`)}
                placeholder={t(`${tKey}PWK_TYPES_MASTER.DESCRIPTION`)}
                component={InputText}
                onInputChange={(value) => this.handleDescriptionChange(value)}
              />
              <Col sm={8} className="btn-wrapper">
                <Button
                  className={'btn-white btn-icon btn-input btn-white search-button'}
                  onClick={() => this.searchPwkType()}>
                  <i className="ico-search" />
                  {t(`${tKey}SEARCH_BTN`)}
                </Button>
                <Button
                  className={'btn-icon btn-input ' + (hasFilters ? 'btn-standard' : 'btn-cancel')}
                  onClick={() => {
                    this.props.initialize({})
                    this.resetFilters()
                  }}
                >
                  <i className="ico-cancel" />
                  {t('MASTERS.RESET_BUTTON')}
                </Button>
              </Col>
            </Row>
          </div>
        </Row>
        <Col sm={12} className="col-row  section-users-body">
          <Table className="editable-table" hover responsive>
            <thead>
              <tr>
                <th onClick={() => { this.changeOrder('name') }}>
                  {t(`${tKey}PWK_TYPES_MASTER.NAME`) + ' '}
                  <i className={this.getSortIcon('name')} />
                </th>
                <th onClick={() => { this.changeOrder('description') }}>
                  {t(`${tKey}PWK_TYPES_MASTER.DESCRIPTION`) + ' '}
                  <i className={this.getSortIcon('description')} />
                </th>
                <th style={{ width: '77px' }}>{t('DOSSIER_COMPONENTS.ACCESSORIES.ACTIONS')}</th>
              </tr>
            </thead>
            <tbody>
              {pwkTypesList && pwkTypesList.map((pwkType, idx) => {
                return (
                  <tr key={idx}>
                    <td>{pwkType.name}</td>
                    <td>{pwkType.description}</td>
                    <td>
                      <div>
                        <a onClick={() => fetchPaperwokTypeMasterInfo(pwkType.paperworkTypeId)}>
                          <OverlayTrigger placement="left" overlay={editTooltip}>
                            <i className="ico-edit-white"/>
                          </OverlayTrigger>
                        </a>
                        {
                          this.state.fieldsConfiguration.deletePaperwokTypeMaster && this.state.fieldsConfiguration.deletePaperwokTypeMaster.permission > permissions.hidden &&
                            <a onClick={() => deletePaperwokTypeMaster(pwkType.paperworkTypeId)}>
                              <OverlayTrigger placement="left" overlay={deleteTooltip}>
                                <i className="ico-trash"/>
                              </OverlayTrigger>
                            </a>
                        }
                      </div>
                    </td>
                  </tr>)
              })
              }
              {(pwkTypesList === null || pwkTypesList === undefined || pwkTypesList.length === 0) &&
            <tr>
              <td colSpan={4}><h2>{t(`${tKey}PWK_TYPES_MASTER.RESULTS_NOT_FOUND`)}</h2></td>
            </tr>}
              {
                this.state.fieldsConfiguration.addPaperwokTypeMaster && this.state.fieldsConfiguration.addPaperwokTypeMaster.permission > permissions.hidden &&
                <tr className="button-table-wrapper">
                  <td>
                    <Button
                      className="btn-editable-table btn-standard"
                      onClick={() => fetchPaperwokTypeMasterInfo()}>
                    + {t('MASTERS.ADD')}
                    </Button>
                  </td>
                </tr>
              }
            </tbody>
          </Table>
          <div className="section-users-footer">
            <nav key={1} className="text-center">
              <IDocCarPagination
                id="btn_pag_sales"
                page={page}
                pagesCount={pagesCount}
                totalRows={pwkTypesCount}
                onSelectAction={(value) => fetchPaperwokTypesMaster({ ...filter, ...this.state.filter, page: value })}
              />
            </nav>
          </div>
        </Col>
      </div>
    )
  }
}

export default reduxForm({
  form: 'listPwkTypesMaster'
})(PwkTypesMaster)
