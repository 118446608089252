import React, { PureComponent } from 'react'
import { Popover, Overlay, Button, Glyphicon } from 'react-bootstrap'
import { entityTypesId } from '../../constants/backendIds'

class EntityPopover extends PureComponent {
  constructor () {
    super()
    this.state = {
      showPopover: false,
      target: this
    }
  }

  handleClick (e) {
    e.stopPropagation()
    this.setState({ target: e.target, showPopover: true })
  }

  getEntityColumn () {
    const entity = this.props.entity || {}
    const entityChild = this.props.entityChild || {}
    const contact = this.props.contact || {}
    const entityNameAndSurname1 = entity.entityName ? entity.entityName + (entity.entitySurname1 ? ' ' + entity.entitySurname1 : '') : null
    const entityDataReturn = (entity.entityName || entity.entityNameDestination || entity.entitySurname1)
      ? this.props.isHeader && (entity.entityName || entity.entityNameDestination + (entity.entitySurname1 ? ' ' + entity.entitySurname1 : '')).length > 20
        ? (entityNameAndSurname1 || entity.entityNameDestination + (entity.entitySurname1 ? ' ' + entity.entitySurname1 : '')).substring(0, 15) + '...'
        : (entityNameAndSurname1 || entity.entityNameDestination + (entity.entitySurname1 ? ' ' + entity.entitySurname1 : ''))
      : ''
    if (entity.entityTypeId && entity.entityTypeId === entityTypesId.recurrentEntity) {
      const nameToShow = !entityChild.entityName || entityChild.entityName === ''
        ? !contact.contactCompletName || contact.contactCompletName === ''
          ? entityDataReturn
          : contact.contactCompletName
        : entityChild.entityName

      return this.props.isHeader && nameToShow.length > 20
        ? nameToShow.substring(0, 15) + '...'
        : nameToShow
    } else {
      return entityDataReturn
    }
  }

  validateKeysEntity (entity) {
    return entity.entityName || entity.entityNameOrigin || entity.entityNameDestination || entity.clientNumber ||
        entity.entityCifNif || entity.entityAddress || entity.entityPhone || entity.entityMail || entity.entityType
  }

  render () {
    const {
      t,
      idx,
      half,
      id,
      entity = {}
    } = this.props
    const entityNameAndSurname1 = entity.entityName ? entity.entityName + (entity.entitySurname1 ? ' ' + entity.entitySurname1 : '') : null
    const tKey = 'SEARCH.CATEGORIES.'
    return (
      <div>
        {Object.keys(entity).length > 2 &&
          <Button bsSize="xsmall" className="icon-only" onClick={this.handleClick.bind(this)} id={'entity_popOver_button_' + idx} ><Glyphicon glyph="plus"/></Button>
        }
        <div className="text-popover">
          {
            this.getEntityColumn()
          }
        </div>
        <Overlay
          container={this}
          placement={(idx >= half) ? 'right' : 'bottom'}
          show={this.state.showPopover}
          target={this.state.target}
          onHide={() => this.setState({ showPopover: false })}
          rootClose
          containerPadding = {0}
          arrowOffsetLeft = {0}
        >
          <Popover id={id} className="entity-popover-container search-comp">
            {entity.entityName &&
            <div className="popover-item">
              <label>{t(`${tKey}ENTITY.NAME`)}: </label><br/>
              {entityNameAndSurname1}
            </div>
            }
            {entity.entityNameOrigin &&
            <div className="popover-item">
              <label>{t(`${tKey}ENTITY.NAME_ORIGIN`)}: </label><br/>
              {entity.entityNameOrigin}
            </div>
            }
            {entity.entityNameDestination &&
            <div className="popover-item">
              <label>{t(`${tKey}ENTITY.NAME_DEST`)}: </label><br/>
              {entity.entityNameDestination}
            </div>
            }
            {entity.clientNumber &&
            <div className="popover-item">
              <label>{t(`${tKey}ENTITY.MAIN_ENTITY_NUMBER`)}:</label><br/>
              {entity.clientNumber}
            </div>
            }
            {entity.entityCifNif &&
            <div className="popover-item">
              <label>{t(`${tKey}ENTITY.DNI_CIF`)}:</label><br/>
              {entity.entityCifNif}
            </div>
            }
            {entity.entityAddress &&
            <div className="popover-item">
              <label>{t(`${tKey}ENTITY.ADDRESS`)}:</label><br/>
              {entity.entityAddress}
            </div>
            }
            {entity.entityPhone &&
            <div className="popover-item">
              <label>{t(`${tKey}ENTITY.PHONE`)}: </label><br/>
              {entity.entityPhone}
            </div>
            }
            {entity.entityMail &&
            <div className="popover-item">
              <label>{t(`${tKey}ENTITY.EMAIL`)}: </label><br/>
              {entity.entityMail}
            </div>
            }
            {entity.entityType &&
            <div className="popover-item">
              <label>{t(`${tKey}ENTITY.ENTITY_SUB_TYPE_ID`)}: </label><br/>
              {entity.entityType}
            </div>
            }
          </Popover>
        </Overlay>
      </div>
    )
  }
}

export default EntityPopover
