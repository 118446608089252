import unitActionTypes from '../../constants/actions/unit/unit'

export function initializeUnitPage (filter) {
  return {
    type: unitActionTypes.INITIALIZE_UNIT_PAGE,
    filter
  }
}

export function changeFilter (filter) {
  return {
    type: unitActionTypes.CHANGE_UNIT_FILTER,
    filter
  }
}

export function changeFilterString (filter) {
  return {
    type: unitActionTypes.CHANGE_FILTER_STRING,
    filter
  }
}

export function updateFilter (filter) {
  return {
    type: unitActionTypes.UPDATE_FILTER,
    filter
  }
}

export function fetchUnit () {
  return { type: unitActionTypes.FETCH_UNIT }
}

export function fetchUnitSuccess (data) {
  return {
    type: unitActionTypes.FETCH_UNIT_SUCCESS,
    data
  }
}

export function openUnitModal (unit) {
  return {
    type: unitActionTypes.OPEN_UNIT_MODAL,
    unit
  }
}

export function closeUnitModal () {
  return {
    type: unitActionTypes.CLOSE_UNIT_MODAL
  }
}

export function saveNewUnit (data, isEditModal, resolve, reject) {
  return {
    type: unitActionTypes.SAVE_NEW_UNIT,
    data,
    isEditModal,
    resolve,
    reject
  }
}

export function deleteUnit (unit) {
  return {
    type: unitActionTypes.DELETE_UNIT,
    unit
  }
}

export default {
  initializeUnitPage,
  changeFilter,
  changeFilterString,
  updateFilter,
  fetchUnit,
  fetchUnitSuccess,
  openUnitModal,
  closeUnitModal,
  saveNewUnit,
  deleteUnit
}
