
import React, { PureComponent } from 'react'
import { Modal, Col, Row, FormGroup, Button } from 'react-bootstrap'
import { reduxForm, Field } from 'redux-form'
import InputText from '../../commons/form/InputText'
import InputSelect from '../../commons/form/InputSelect'
import InputCheckBox from '../../commons/form/InputCheckBox'
import InputNumber from '../../commons/form/InputNumber'
import TextInput from '../../commons/TextInput'
import { documentTypeView } from '../../../constants/backendIds'
import { getComboTranslatedFromEnum } from '../../../util/utils'
import { getObjectWithLocales } from '../../../util/masters/masters'

class DocumentTypeMasterModal extends PureComponent {
  constructor () {
    super()
    this.state = {}
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevProps.showModal !== this.props.showModal && this.props.showModal === true) {
      const documentTypeMetadataWithLocales = getObjectWithLocales(this.props.documentType, this.props.documentType?.documentTypeLocales ?? [], this.props.languageList)
      this.props.initialize(documentTypeMetadataWithLocales)
    }
  }

  submitDocument (values) {
    let languages = []
    Object.keys(values).map(item => {
      if (!!values[item] && typeof values[item] === 'object') {
        languages.push(values[item])
      }
    })
    const documentType = {
      id: values.id,
      documentTypeEntityId: values.documentTypeEntityId,
      documentTypeView: values.documentTypeView,
      hasMultipleDocs: values.hasMultipleDocs,
      validationRequired: values.validationRequired,
      solpheoContainerId: values.solpheoContainerId,
      isExpirationType: values.isExpirationType,
      functionToExpire: values.functionToExpire,
      hasDescription: values.hasDescription,
      documentTypeLocales: languages
    }

    this.props.actions.submitDocumentType(documentType)
  }

  render () {
    const {
      t, handleSubmit, languageList,
      showModal, documentTypeId,
      combos: { documentTypeEntities }
    } = this.props
    const tKey = 'MASTERS.DOCUMENT_TYPE_MASTERS.MODAL.'
    let viewTypes = getComboTranslatedFromEnum(t, tKey, documentTypeView)
    return (
      <Modal className="role-modal languages-modal" show={showModal} onHide={this.props.actions.closeDocumentTypeMasterModal}>
        <form autoComplete="off" onSubmit={handleSubmit(this.submitDocument.bind(this))}>
          <Modal.Header closeButton onHide={this.props.actions.closeDocumentTypeMasterModal}>
            <Modal.Title>{t(`${tKey}TITLE`)}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="inputs-wrapper">
              {documentTypeId && (<Col sm={6} className="custom-width-col">
                <h2>{t(`${tKey}ID`)}</h2>
                <Field
                  name="id"
                  placeholder={t(`${tKey}ID`)}
                  component={InputText}
                  disabled={true}
                />
              </Col>)}
              <Col sm={6} className="custom-width-col">
                <h2>{t(`${tKey}ENTITY_TYPE`)}</h2>
                <Field
                  name="documentTypeEntityId"
                  placeholder={t(`${tKey}ENTITY_TYPE`)}
                  valueKey="id"
                  labelKey="value"
                  component={InputSelect}
                  colSm={0}
                  options={documentTypeEntities}
                />
              </Col>
            </Row>
            <Row className="inputs-wrapper">
              <Col sm={6}>
                <h2>{t(`${tKey}TYPE_VIEW`)}</h2>
                <Field
                  name="documentTypeView"
                  placeholder={t(`${tKey}TYPE_VIEW`)}
                  valueKey="id"
                  labelKey="value"
                  component={InputSelect}
                  colSm={0}
                  options={viewTypes}
                />
              </Col>
              <Col sm={6}>
                <h2>{t(`${tKey}EXPIRE_FUNCTION`)}</h2>
                <Field
                  name="functionToExpire"
                  placeholder={t(`${tKey}EXPIRE_FUNCTION`)}
                  component={InputText}
                  required={[true]}
                  maxLength={50}
                  colSm={0}
                />
              </Col>
            </Row>
            <Row className="inputs-wrapper">
              <Col sm={6}>
                <h2>{t(`${tKey}STORAGE_CONTAINER`)}</h2>
                <Field
                  name="solpheoContainerId"
                  placeholder={t(`${tKey}STORAGE_CONTAINER`)}
                  component={InputNumber}
                  maxLength={9}
                />
              </Col>
              <Col sm={6}>
                <h2>{t(`${tKey}REQUIRE_VALIDATION`)}</h2>
                <Field
                  name="validationRequired"
                  placeholder={t(`${tKey}REQUIRE_VALIDATION`)}
                  component={InputCheckBox}
                />
              </Col>
            </Row>
            <Row className="inputs-wrapper">
              <Col sm={6}>
                <h2>{t(`${tKey}MULTIPLE_DOCS`)}</h2>
                <Field
                  name="hasMultipleDocs"
                  placeholder={t(`${tKey}MULTIPLE_DOCS`)}
                  component={InputCheckBox}
                />
              </Col>
              <Col sm={6}>
                <h2>{t(`${tKey}EXPIRES`)}</h2>
                <Field
                  name="isExpirationType"
                  placeholder={t(`${tKey}EXPIRES`)}
                  component={InputCheckBox}
                />
              </Col>
            </Row>
            <Row className="inputs-wrapper">
              <Col sm={6}>
                <h2>{t(`${tKey}HAS_DESCRIPTION`)}</h2>
                <Field
                  name="hasDescription"
                  placeholder={t(`${tKey}HAS_DESCRIPTION`)}
                  component={InputCheckBox}
                />
              </Col>
            </Row>
            <div className="clearfix" />
            <FormGroup>
              {languageList && languageList.map((val, idx) => {
                return (
                  <Row key={idx} className="inputs-wrapper">
                    <Col sm={10} className="custom-width-col">
                      <h2>{t('MASTERS.NAME')}</h2>
                      <Field
                        name={`lang${val.languageId}.name`}
                        placeholder={t('MASTERS.NAME')}
                        component={TextInput}
                        maxLength={50}
                      />
                    </Col>
                    <Col sm={2} className="language-column">
                      <p>{`${val.description} (${val.name})`}</p>
                    </Col>
                    <Col sm={10} className="custom-width-col">
                      <h2>{t('MASTERS.DESCRIPTION')}</h2>
                      <Field
                        name={`lang${val.languageId}.description`}
                        placeholder={t('MASTERS.DESCRIPTION')}
                        component={TextInput}
                        maxLength={50}
                      />
                    </Col>
                    <Col sm={2} className="language-column">
                      <p>{`${val.description} (${val.name})`}</p>
                    </Col>
                  </Row>
                )
              })}
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <Col sm={12} className="btn-wrapper">
              <Button
                id="role-modal-submit-button"
                className="btn-standard"
                type="submit"
                disabled={this.props.pristine}>
                {t(`${tKey}SUBMIT_BUTTON`)}
              </Button>
            </Col>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default reduxForm({
  form: 'editDocumentType',
  validate: (values, props) => {
    let error = {}
    if (values) {
      const tKey = 'MASTERS.DOCUMENT_TYPE_MASTERS.VALIDATION.'
      if (!values.documentTypeEntityId) {
        error.documentTypeEntityId = props.t(`${tKey}ENTITY_TYPE`)
      }
      if (!values.documentTypeView) {
        error.documentTypeView = props.t(`${tKey}TIPE_VIEW`)
      }
      if (!values.solpheoContainerId) {
        error.solpheoContainerId = props.t(`${tKey}SOLPHEO_CONTAINER_ID`)
      }
      props.languageList && props.languageList.forEach((element) => {
        let valueLangName = values['lang' + element.languageId]?.name
        let valueLangDesc = values['lang' + element.languageId]?.description
        let errorLang = {
          name: null,
          description: null
        }
        if (!valueLangName) {
          errorLang.name = props.t(`${tKey}NAME`)
        } else if (valueLangName.length > 50) {
          errorLang.name = props.t(`${tKey}NAME_LENGTH`)
        }
        if (!valueLangDesc) {
          errorLang.description = props.t(`${tKey}DESCRIPTION`)
        } else if (valueLangDesc.length > 50) {
          errorLang.description = props.t(`${tKey}DESCRIPTION_LENGTH`)
        }
        error['lang' + element.languageId] = errorLang
      })
    }
    return Object.keys(error).length > 0 ? error : {}
  }
})(DocumentTypeMasterModal)
