export default {
  FETCH_LANGUAGES_AVAILABLE: 'FETCH_LANGUAGES_AVAILABLE',
  FETCH_LANGUAGES_AVAILABLE_SUCCESS: 'FETCH_LANGUAGES_AVAILABLE_SUCCESS',
  SHOW_LOADER: 'SHOW_LOADER',
  HIDE_LOADER: 'HIDE_LOADER',
  SHOW_SMALL_LOADER: 'SHOW_SMALL_LOADER',
  HIDE_SMALL_LOADER: 'HIDE_SMALL_LOADER',
  CHANGE_LOCALE: 'CHANGE_LOCALE',
  OPEN_MODAL: 'OPEN_MODAL',
  CLOSE_MODAL: 'CLOSE_MODAL',
  SET_CURRENT_SECTION: 'SET_CURRENT_SECTION',
  SET_SECTION_BY_PATH: 'SET_SECTION_BY_PATH',
  GET_USER_REGISTER_COUNT: 'GET_USER_REGISTER_COUNT',
  OPEN_DOC_WARNING: 'OPEN_DOC_WARNING',
  UPDATE_ERROR_MODEL: 'UPDATE_ERROR_MODEL'
}
