import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import ServiceHistorical from '../../../components/dossierHistorical/service/ServiceHistorical'
import ServiceHistorical_v2 from '../../../_v2/components/dossierHistorical/service/ServiceHistorical'
import serviceDossierActions from '../../../actions/dossier/serviceDossier'
import commentsActions from '../../../actions/dossier/common/comments'
import commonHeaderActions from '../../../actions/dossier/common/commonHeader'
import { openDocumentManagementModal as openDocumentModal } from '../../../actions/dossier/common/documentManagementModal'
import { closeAddOrEditDocumentModal } from '../../../actions/dossier/common/documentHistoricalEditionModal'
import scrollerActions from '../../../actions/commons/scroller'
import buttonsHeaderActions from '../../../actions/dossier/common/buttonsHeader'
import buttonsSidebarActions from '../../../actions/dossier/common/buttonsSidebar'
import { fetchFilteredRelatedDossier } from '../../../actions/dossier/common/dossierRelatedDossiers'
import { saveSelectedDocuments } from '../../../actions/dossier/sales'
import clientActions from '../../../actions/dossier/common/client'
import vehicleActions from '../../../actions/dossier/common/vehicle'
import { fecthFamily, fetchSameLevelOusCombo } from '../../../actions/combos/combos'
import {
  fetchCustomFields
} from '../../../actions/dossier/customFields'
import { registerLastAccess } from '../../../actions/dossier/common/lastAccessDossier'
import { getStorageValue } from '../../../storage/storage'
import TrackingContainer from '../../tracking/trackingContainer'

export function mapStateToProps (state) {
  return {
    dossier: state.serviceDossier,
    combos: state.combos,
    windowWidth: state.windowDimensions.width,
    statusHeaderHeight: state.statusHeader.height,
    activeTab: state.buttonsHeader.activeTab,
    buttonsHeaderHeight: state.buttonsHeader.height,
    activeSection: state.buttonsSidebar.activeSection,
    relatedDossiersOrderBy: state.dossierRelatedDossiers.orderby,
    vehicleProps: state.vehicle
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      ...serviceDossierActions,
      ...commentsActions,
      ...clientActions,
      ...commonHeaderActions,
      ...buttonsHeaderActions,
      ...buttonsSidebarActions,
      ...scrollerActions,
      closeAddOrEditDocumentModal,
      fetchFilteredRelatedDossier,
      openDocumentModal,
      saveSelectedDocuments,
      ...vehicleActions,
      fecthFamily,
      fetchSameLevelOusCombo,
      fetchCustomFields,
      registerLastAccess
    }, dispatch)
  }
}

let version = parseInt(getStorageValue('interfaceVersion'))
var decoratedComponent = null
if (version === 2) {
  decoratedComponent = TrackingContainer(ServiceHistorical_v2, true)
} else {
  decoratedComponent = TrackingContainer(ServiceHistorical, true)
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(decoratedComponent))
