import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { fetchDossierSubTypes, fetchAuditConfigurationType, fetchDossierTypes } from '../../../actions/combos/combos'
import { initializeSearch, resetFilters } from '../../../actions/search/search'
import { openModal, yesNoModal } from '../../../actions/common'
import { fetchAuditsInfo, resetFiltersAuditsInfo, openAuditAdminModal } from '../../../actions/management/management'
import Audits from '../../components/management/Audits'

export function mapStateToProps (state) {
  return {
    ...state.management.audits,
    combos: {
      ...state.combos
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      openAuditAdminModal,
      fetchDossierSubTypes,
      fetchAuditConfigurationType,
      fetchDossierTypes,
      initializeSearch,
      resetFilters,
      openModal,
      yesNoModal,
      fetchAuditsInfo,
      resetFiltersAuditsInfo
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(Audits))
