
import React, { PureComponent } from 'react'
import { OverlayTrigger, Button, Tooltip, Form } from 'react-bootstrap'
import { Field, reduxForm, reset } from 'redux-form'
import InputText from '../commons/form/InputText'
import InputSelect from '../commons/form/InputSelect'
import InputDatePicker from '../commons/form/InputDatePicker'
import { validateDate } from '../../../util/validationFunctions'
import { multipleNormalize, toUpperCase, trimAll } from '../../../util/normalizeFunctions'
import SimpleTablePage from '../../../containers/commons/general/SimpleTablePage'
import DossierListPopover from './DossierListPopover'
import IDocCarPagination from '../../../components/commons/iDocCarPagination'
import { permissions, clientInformation } from '../../../constants/backendIds'
import vehicleCondition from '../../../constants/vehicleCondition'
import { getFieldsSectionPermissions } from '../../../util/permisionFunctions'
import settings from '../../../setting'
import AdditionalSearchFieldsByVehicleTypeOperation from '../../../components/search/vehicleSearch/AdditionalSearchFieldsByVehicleTypeOperation'
import { showFilter, hideFiltersBtn, toggleFilterClass, getIconClass } from '../../../_v2/util/management/ManagementUtils'
import MapSectionsSearch from '../../../_v2/util/map-sections-search'
import { fromCamelToUnderscore, getDateNowMaxDatePicker } from '../../../util/utils'

class SearchByVehicleForm extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      fields: {},
      fieldsConfiguration: {
        findVehicleBydealerNumber: null,
        carline: null,
        catalogue: null,
        model: null,
        externalColor: null,
        internalColor: null,
        vehicleCondition: null
      },
      firstLicensePlateDateFromLimit: {
        min: null,
        max: null
      },
      firstLicensePlateDateToLimit: {
        min: null,
        max: null
      }
    }
    this.onBrandIdChanges = this.onBrandIdChanges.bind(this)
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (Object.keys(prevProps.fields).length > 0 && Object.keys(this.props.fields).length === 0) {
      this.setState({ firstLicensePlateDateFromLimit: { min: null, max: null }, firstLicensePlateDateToLimit: { min: null, max: null } })
    }
    return null
  }

  componentDidUpdate () {
    // React life cycle
  }

  validateDate (value, allValues, props) {
    const result = validateDate(value, props)
    return result === Object(result) ? undefined : result
  }

  handleClickReset (e) {
    if (e !== undefined) {
      e.preventDefault()
    }

    this.props.dispatch(reset('SearchByVehicleForm'))
    this.state.dossierIdToArray = []
    this.setState({ onlyFleets: true, checkAll: false, dossierIdToArray: [], vehicleData: [], fields: [] })
  }

  exportData () {
    const tKey = 'SEARCH.CATEGORIES.VEHICLE.'
    let columnsExcel = []
    columnsExcel.push(this.props.t(`${tKey}BRAND`) + '#Brand')
    this.state.fieldsConfiguration.carline && this.state.fieldsConfiguration.carline.permission > permissions.hidden && columnsExcel.push(this.props.t(`${tKey}CARLINE`) + '#CarlineDescription')
    this.state.fieldsConfiguration.catalogue && this.state.fieldsConfiguration.catalogue.permission > permissions.hidden && columnsExcel.push(this.props.t(`${tKey}CATALOGUE`) + '#CatalogueDescription')
    this.state.fieldsConfiguration.model && this.state.fieldsConfiguration.model.permission > permissions.hidden && columnsExcel.push(this.props.t(`${tKey}MODEL_YEAR`) + '#ModelDescription')
    columnsExcel.push(this.props.t(`${tKey}MODEL`) + '#Model')
    columnsExcel.push(this.props.t(`${tKey}CHASSIS`) + '#VinChassis')
    columnsExcel.push(this.props.t(`${tKey}LICENSE_PLATE`) + '#LicensePlate')
    settings.clientFieldValidation !== clientInformation.BUSS && columnsExcel.push(this.props.t(`${tKey}COMMERCIAL_CODE`) + '#ComercialCode')
    columnsExcel.push(this.props.t(`${tKey}FIRST_LPLATE_DATE_FROM`) + '#LicenseDate')
    if (this.state.fieldsConfiguration.findVehicleBydealerNumber > permissions.hidden) {
      columnsExcel.push(this.props.t(`${tKey}DEALER_NUMBER`) + '#DealerNumber')
    }
    columnsExcel.push(this.props.t(`${tKey}AMOUNT_RELATED_DOSSIERS`) + '#LivesStoreds')
    columnsExcel.push(this.props.t(`${tKey}STATE`) + '#Active')
    this.exportToExcel(columnsExcel)
  }

  exportToExcel (data) {
    let cols = ''
    data.forEach(element => {
      cols += element + '$'
    })
    cols = cols.substring(0, cols.length - 1)
    let values = this.props.dynamicFilters
    this.props.actions.fetchVehiclesDownload({ ...values.vehicle, ...this.props.filters }, cols)
  }

  componentDidMount () {
    for (const dynamicFilter in this.props.dynamicFilters) {
      this.props.change(dynamicFilter, this.props.dynamicFilters[dynamicFilter])
    }
    this.props.actions.fetchBrands()
    this.props.actions.fetchModelCombo()

    let permissionFindVehicleBydealerNumber = {
      findVehicleBydealerNumber: null
    }

    if (this.props.permissions && this.props.permissions.findVehicleBydealerNumber) {
      permissionFindVehicleBydealerNumber.findVehicleBydealerNumber = this.props.permissions.findVehicleBydealerNumber
    }

    if (this.props.permissions && this.props.permissions.sectionFieldsConfiguration) {
      const fieldKeys = Object.keys(this.state.fieldsConfiguration)
      const permission = getFieldsSectionPermissions(this.props.permissions.sectionFieldsConfiguration, fieldKeys, permissions.hidden)
      this.setState({ fieldsConfiguration: { ...permission.fieldsConfiguration, ...permissionFindVehicleBydealerNumber } })
    } else {
      this.setState({ fieldsConfiguration: permissionFindVehicleBydealerNumber })
    }
  }

  setDateLimit (fromDate, toDate) {
    const dateSplitted = fromDate ? fromDate.split('/') : toDate ? toDate.split('/') : null
    const dateTransformed = dateSplitted && new Date(+dateSplitted[2], dateSplitted[1] - 1, +dateSplitted[0])
    let newLimit = dateSplitted && new Date(+dateSplitted[2], dateSplitted[1] - 1, +dateSplitted[0])

    if (fromDate) {
      newLimit.setMonth(newLimit.getMonth() + 1)
      this.setState({ firstLicensePlateDateToLimit: { min: dateTransformed.toString(), max: newLimit.toString() } })
    } else if (toDate) {
      newLimit.setMonth(newLimit.getMonth() - 1)
      this.setState({ firstLicensePlateDateFromLimit: { min: newLimit.toString(), max: dateTransformed.toString() } })
    } else {
      if (fromDate === false) {
        this.setState({ firstLicensePlateDateFromLimit: { min: null, max: null } })
      } else if (toDate === false) {
        this.setState({ firstLicensePlateDateToLimit: { min: null, max: null } })
      }
    }
  }

  onBrandIdChanges (brandId) {
    this.props.actions.resetCarlineCombo()
    this.props.actions.resetCatalogueCombo()
    this.props.actions.resetColorCombo()
    this.props.actions.resetTapestryCombo()
    if (brandId) {
      this.props.actions.fetchCarlineCombo(brandId)
    }
  }

  selectedFilters () {
    const filters = this.props.dynamicFilters
    const tKey = 'SEARCH.CATEGORIES.'
    return Object.keys(filters)
      .map((item) => {
        let text = item
        let value = null
        const list = []
        Object.keys(filters[item]).map((filter, index) => {
          text = this.props.t(`${tKey}${item.toUpperCase()}.${fromCamelToUnderscore(filter).toUpperCase()}`)
          switch (filter) {
            case 'brand':
            { const brand = this.props.combos.brandCombo.find(brand => brand.id === filters[item][filter])
              value = brand && brand.value
              break }
            case 'vehicleCondition':
            { value = vehicleCondition.NEW === filters[item][filter] ? this.props.t(`${tKey}VEHICLE.VEHICLE_CONDITION_NEW`) : this.props.t(`${tKey}VEHICLE.VEHICLE_CONDITION_NEARLY_NEW`)
              break }
            default:
            { value = filters[item][filter]
              break }
          }

          if (value) {
            list.push(<li key={index}>{text}: <strong>{value}</strong>;</li>)
          }
        })
        return list
      })
  }

  validateLimitDate (values) {
    const fromDateSplitted = values.vehicle && values.vehicle.licensePlateDateFrom && values.vehicle.licensePlateDateFrom.split('/')
    const fromDateParsed = fromDateSplitted && new Date(+fromDateSplitted[2], fromDateSplitted[1] - 1, +fromDateSplitted[0])
    const fromDateLimit = fromDateSplitted && new Date(+fromDateSplitted[2], fromDateSplitted[1] - 1, +fromDateSplitted[0])
    if (fromDateLimit) {
      fromDateLimit.setMonth(fromDateLimit.getMonth() + 1)
    }

    const toDateSplitted = values.vehicle && values.vehicle.licensePlateDateTo && values.vehicle.licensePlateDateTo.split('/')
    const toDateParsed = toDateSplitted && new Date(+toDateSplitted[2], toDateSplitted[1] - 1, +toDateSplitted[0])

    return fromDateParsed && !toDateParsed || !fromDateParsed && toDateParsed || fromDateParsed > toDateParsed || fromDateLimit < toDateParsed
  }

  submitSearchForm (values) {
    // const config = { id: searchType.VEHICLE, formValuesName: 'vehicle', fetchAction: (value) => this.props.actions.fetchVehicles({ ...value, ...this.props.filters }), emptyFilterModal: 'mustFillFiltersVehicleDate', customValidation: (values) => { return this.validateLimitDate(values) } }

    var customValidation = this.validateLimitDate(values)
    if (customValidation) {
      return this.props.actions.openModal('mustFillFiltersVehicleDate')
    }

    this.props.actions.fetchVehicles({ ...values.vehicle, ...this.props.filters })
    this.setState({ dossierIdToArray: [], dynamicFilters: values, filterValues: { ...values.vehicle, ...this.props.filters } })

    this.props.actions.setFilters(values)
  }

  onPageChange (page) {
    this.setState({ filterValues: { ...this.state.filterValues, page: page } })
    this.props.actions.fetchVehicles({ ...this.state.filterValues, page })
  }

  onOrderChange (e) {
    const newOrderBy = this.props.filters.orderBy.includes(`%2B${e.currentTarget.dataset.field}`) ? `-${e.currentTarget.dataset.field}` : `%2B${e.currentTarget.dataset.field}`
    this.setState({ filterValues: { ...this.state.filterValues, orderBy: newOrderBy } })
    this.props.actions.fetchVehicles({ ...this.state.filterValues, orderBy: newOrderBy })
  }

  toogleHideFilters () {
    this.props.actions.setHideFilters(!this.props.hideFilters)
  }

  toogleMaxiTable () {
    this.setState({ maxiTable: !this.state.maxiTable })
  }

  render () {
    const {
      t, combos: { brandCombo }, fields, fieldFill, className, classNameSelect, hasSearch, vehicleData, stateTooltip, activeTooltip,
      inActiveTooltip, pages, page, count, hideFilters,
      handleSubmit // From search
    } = this.props
    const tKey = 'SEARCH.CATEGORIES.VEHICLE.'
    const secMapSearch = MapSectionsSearch.find(function (s) { return s.page === 'vehiclePage' })
    const hasResults = vehicleData && vehicleData.length > 0
    return (
      <div className="inner-results-panel">
        <div className="section-results-header">
          <div className="section-header-title">
            <OverlayTrigger placement="right" overlay={<Tooltip id="editProductTooltip">{`${t((this.props.hideMenu ? 'GLOBAL.TABLES.SHOW_INNER_MENU' : 'GLOBAL.TABLES.HIDE_INNER_MENU'))}`}</Tooltip>}>
              <a onClick={() => this.props.toogleInnerMenu()}s className={this.props.hideMenu ? 'collapseBtn' : 'collapseBtn closeInnerMenu'}><i className={this.props.hideMenu ? 'iDocIcon-Open-inner-menu' : 'iDocIcon-Close-inner-menu'}></i></a>
            </OverlayTrigger>
            <h4>{t(`${secMapSearch.title}`)}
              <OverlayTrigger placement="left" overlay={<Tooltip id="editProductTooltip">{`${t((hideFilters ? 'GLOBAL.TABLES.SHOW_FILTERS' : 'GLOBAL.TABLES.HIDE_FILTERS'))}`}</Tooltip>}>
                <a onClick={() => this.toogleHideFilters()} className={'toogleShowFilters ' + hideFiltersBtn(hasResults)}><i className={toggleFilterClass(hasResults, hideFilters)}></i></a>
              </OverlayTrigger>
            </h4>
          </div>
          <Form autoComplete="off" onSubmit={handleSubmit((values) => this.submitSearchForm(values))} className="filters ">
            <div className={'search-form ' + (showFilter(hasResults, hideFilters) ? '' : 'hideFilters ')}>
              {this.state.fieldsConfiguration.vehicleCondition && this.state.fieldsConfiguration.vehicleCondition.permission > permissions.hidden && <Field
                component={InputSelect}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                id="vehicleCondition"
                name='vehicle.vehicleCondition'
                placeholder={t(`${tKey}VEHICLE_CONDITION`)}
                controlLabel={t(`${tKey}VEHICLE_CONDITION`)}
                options={[{ id: vehicleCondition.NEW, value: t(`${tKey}VEHICLE_CONDITION_NEW`) }, { id: vehicleCondition.NEARLY_NEW, value: t(`${tKey}VEHICLE_CONDITION_NEARLY_NEW`) }]}
                valueKey="id"
                labelKey="value"
                onInputChange={(val) => { fieldFill({ vehicleCondition: val }) }}
                customClass={fields.brand ? classNameSelect : ''}/>
              }
              <Field
                component={InputSelect}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                id="brand"
                name='vehicle.brand'
                placeholder={t(`${tKey}BRAND`)}
                controlLabel={t(`${tKey}BRAND`)}
                options={brandCombo}
                valueKey="id"
                labelKey="value"
                onInputChange={(val) => { fieldFill({ brand: val }); this.onBrandIdChanges(val) }}
                customClass={fields.brand ? classNameSelect : ''}
              />
              <Field
                component={InputText}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                id="modelVehicle"
                name='vehicle.model'
                placeholder={t(`${tKey}MODEL`)}
                controlLabel={t(`${tKey}MODEL`)}
                type="text"
                onInputChange={(val) => fieldFill({ model: val })}
                customClass={fields.model ? className : ''}
              />
              <Field
                component={InputText}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                id="chassisVehicle"
                name='vehicle.chassis'
                placeholder={t(`${tKey}CHASSIS`)}
                controlLabel={t(`${tKey}CHASSIS`)}
                type="text"
                maxLength={17}
                normalize={value => multipleNormalize(value, [toUpperCase, trimAll])}
                onInputChange={(val) => fieldFill({ chassis: val })}
                customClass={fields.chassis ? className : ''}
              />
              <Field
                component={InputText}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                id="licence_plateVehicle"
                name='vehicle.licensePlate'
                placeholder={t(`${tKey}LICENSE_PLATE`)}
                controlLabel={t(`${tKey}LICENSE_PLATE`)}
                type="text"
                normalize={value => multipleNormalize(value, [toUpperCase, trimAll])}
                onInputChange={(val) => fieldFill({ licence_plate: val })}
                customClass={fields.licence_plate ? className : ''}
              />
              <Field
                component={InputText}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                id="commercial_code"
                name='vehicle.commercialCode'
                placeholder={t(`${tKey}COMMERCIAL_CODE`)}
                controlLabel={t(`${tKey}COMMERCIAL_CODE`)}
                type="text"
                onInputChange={(val) => fieldFill({ commercial_code: val })}
                customClass={fields.commercial_code ? className : ''}
              />
              <Field
                component={InputDatePicker}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                maxDate={getDateNowMaxDatePicker()}
                id="first_lplate_date"
                name='vehicle.licensePlateDateFrom'
                placeholder={t(`${tKey}FIRST_LPLATE_DATE_FROM`)}
                controlLabel={t(`${tKey}FIRST_LPLATE_DATE_FROM`)}
                validate={[this.validateDate]}
                onInputChange={
                  (val) => {
                    fieldFill({ first_lplate_date: val })
                    this.setDateLimit(val, false)
                  }
                }
                customClass={fields.first_lplate_date ? className : ''}
                minDate = {this.state.firstLicensePlateDateFromLimit.min}
                // maxDate = {this.state.firstLicensePlateDateFromLimit.max}
              />
              <Field
                component={InputDatePicker}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                maxDate={getDateNowMaxDatePicker()}
                id="first_lplate_date_to"
                name='vehicle.licensePlateDateTo'
                placeholder={t(`${tKey}FIRST_LPLATE_DATE_TO`)}
                controlLabel={t(`${tKey}FIRST_LPLATE_DATE_TO`)}
                validate={[this.validateDate]}
                onInputChange={
                  (val) => {
                    fieldFill({ first_lplate_date_to: val })
                    this.setDateLimit(false, val)
                  }
                }
                customClass={fields.first_lplate_date_to ? className : ''}
                minDate = {this.state.firstLicensePlateDateToLimit.min}
                // maxDate = {this.state.firstLicensePlateDateToLimit.max}
              />
              {this.state.fieldsConfiguration.findVehicleBydealerNumber > permissions.hidden &&
                <Field
                  component={InputText}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="dealerNumberSimple"
                  name='vehicle.dealerNumber'
                  placeholder={t(`${tKey}DEALER_NUMBER`)}
                  controlLabel={t(`${tKey}DEALER_NUMBER`)}
                  type="text"
                  onInputChange={(val) => fieldFill({ dealerNumber: val })}
                  customClass={fields.dealerNumber ? className : ''}
                />
              }
              <AdditionalSearchFieldsByVehicleTypeOperation {...this.props}></AdditionalSearchFieldsByVehicleTypeOperation>

              <div className='search-form-actions flexInLine al-R'>
                <Button id="btn_search"
                  type="submit"
                  className="_Btn accept stk">
                  <i className="iDocIcon-search"/>
                  {t('SEARCH.ACTIONS.SEARCH_BUTTON')}
                </Button>
                <Button
                  id="btn_reset"
                  onClick={(e) => this.handleClickReset(e)}
                  className={'_Btn' + (this.props.pristine ? ' accept' : ' accept')}
                  disabled={this.props.pristine}>
                  <i className="iDocIcon-clear-decline"/>
                  {t('SEARCH.ACTIONS.RESET_BUTTON')}
                </Button>
              </div>
            </div>
          </Form>
        </div>
        <div className="section-results-body">
          <div className={'searchResoultsContainer' + (this.state.maxiTable ? ' maxiTable' : '')}>
            {hasSearch && ([
              <div className="table-wrapper" key="table-wrapper">
                <ul className="list-inline pull-left">
                  {this.selectedFilters().length === 0 ? <li>{t(`${tKey}NO_SELECTED_FILTERS`)}</li> : (this.selectedFilters())}
                </ul>
                { hasResults &&
                  <div className='flexInLine al-R'>
                    <div className='actionsField'>
                    </div>
                    <OverlayTrigger placement="left" overlay={<Tooltip id="editProductTooltip">{`${t((this.state.maxiTable ? 'GLOBAL.TABLES.MINIMIZE_TABLE' : 'GLOBAL.TABLES.MAXIMIZE_TABLE'))}`}</Tooltip>}>
                      <a onClick={() => this.toogleMaxiTable()} className='_Btn accept stk o-icn maximizeBtn'><i className={(this.state.maxiTable ? 'iDocIcon-Minimize' : 'iDocIcon-Maximize')}></i></a>
                    </OverlayTrigger>
                    <OverlayTrigger placement="left" overlay={<Tooltip id="editProductTooltip">{t('SEARCH.ACTIONS.EXPORT')}</Tooltip>}>
                      <a onClick={(event) => this.exportData(event)} className='_Btn new o-icn exportToExcel'><i className="iDocIcon-file-excel"></i></a>
                    </OverlayTrigger>
                  </div>
                }
                {/* table */}
                { hasResults &&
                  <div className="table-responsive">
                    <SimpleTablePage
                      columns={[
                        <th key={0} data-field="vehicleBrand" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}BRAND`)}&nbsp;<i className={getIconClass('vehicleBrand', this.props.filters)}/>
                        </th>,
                        (this.state.fieldsConfiguration.carline && this.state.fieldsConfiguration.carline.permission > permissions.hidden &&
                        <th key={0} data-field="carlineDescription" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}CARLINE`)}&nbsp;<i className={getIconClass('carlineDescription', this.props.filters)}/>
                        </th>),
                        (this.state.fieldsConfiguration.catalogue && this.state.fieldsConfiguration.catalogue.permission > permissions.hidden &&
                        <th key={0} data-field="catalogueDescription" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}CATALOGUE`)}&nbsp;<i className={getIconClass('catalogueDescription', this.props.filters)}/>
                        </th>),
                        (this.state.fieldsConfiguration.model && this.state.fieldsConfiguration.model.permission > permissions.hidden &&
                        <th key={0} data-field="modelDescription" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}MODEL_YEAR`)}&nbsp;<i className={getIconClass('modelDescription', this.props.filters)}/>
                        </th>),
                        <th key={1} data-field="vehicleModel" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}MODEL`)}&nbsp;<i className={getIconClass('vehicleModel', this.props.filters)}/>
                        </th>,
                        <th key={2} data-field="vehicleVinChassis" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}CHASSIS`)}&nbsp;<i className={getIconClass('vehicleVinChassis', this.props.filters)}/>
                        </th>,
                        <th key={3} data-field="vehicleLicensePlate" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}LICENSE_PLATE`)}&nbsp;<i className={getIconClass('vehicleLicensePlate', this.props.filters)}/>
                        </th>,
                        (!(this.state.fieldsConfiguration.carline && this.state.fieldsConfiguration.carline.permission > permissions.hidden) &&
                        <th key={4} data-field="comercialCode" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}COMMERCIAL_CODE`)}&nbsp;<i className={getIconClass('comercialCode', this.props.filters)}/>
                        </th>),
                        <th key={5} data-field="licensePlateDate" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}FIRST_LPLATE_DATE`)}&nbsp;<i className={getIconClass('licensePlateDate', this.props.filters)} />
                        </th>,
                        (this.state.fieldsConfiguration.findVehicleBydealerNumber && this.state.fieldsConfiguration.findVehicleBydealerNumber.permission > permissions.hidden &&
                        <th key={6} data-field="dealerNumber" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}DEALER_NUMBER`)}&nbsp;<i className={getIconClass('dealerNumber', this.props.filters)} />
                        </th>),
                        <th key={7}>{t(`${tKey}AMOUNT_RELATED_DOSSIERS`)}</th>,
                        <OverlayTrigger key={8} trigger={['hover', 'focus']} placement="top" overlay={stateTooltip}>
                          <th data-field="active" onClick={(event) => this.onOrderChange(event)}>
                            {t(`${tKey}STATE`)}&nbsp;<i className={getIconClass('active', this.props.filters)}/>
                          </th>
                        </OverlayTrigger>
                      ]}
                      rows={vehicleData.map((row, idx) => (
                        <tr key={idx}>
                          <td>{row.vehicleBrand}</td>
                          {this.state.fieldsConfiguration.carline && this.state.fieldsConfiguration.carline.permission > permissions.hidden && <td>{row.carlineDescription}</td>}
                          {this.state.fieldsConfiguration.catalogue && this.state.fieldsConfiguration.catalogue.permission > permissions.hidden && <td>{row.catalogueDescription}</td>}
                          {this.state.fieldsConfiguration.model && this.state.fieldsConfiguration.model.permission > permissions.hidden && <td>{row.modelDescription}</td>}
                          <td>{row.vehicleModel}</td>
                          <td>{row.vehicleVinChassis}</td>
                          <td>{row.vehicleLicensePlate}</td>
                          {!(this.state.fieldsConfiguration.carline && this.state.fieldsConfiguration.carline.permission > permissions.hidden) && <td>{row.comercialCode}</td>}
                          <td>{row.licensePlateDate}</td>
                          {this.state.fieldsConfiguration.findVehicleBydealerNumber && this.state.fieldsConfiguration.findVehicleBydealerNumber.permission > permissions.hidden &&
                          <td>{row.dealerNumber}</td>
                          }
                          <td className="see-more">
                            <DossierListPopover
                              t={t}
                              half={(vehicleData.length || 1) / (idx + 1)}
                              idx={idx}
                              id={'popover-dossier-list' + idx}
                              alive={row.lives}
                              stored={row.stored}
                              subtypesCombo={this.props.subtypesCombo}
                            />
                          </td>
                          <td>
                            {
                              row.active ? (
                                <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={activeTooltip}>
                                  <span className={'color-point green'}/>
                                </OverlayTrigger>)
                                : (<OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={inActiveTooltip}>
                                  <span className={'color-point red'}/>
                                </OverlayTrigger>)
                            }
                          </td>
                        </tr>
                      ))}
                    />
                  </div>
                }
                <div className="search-footer" key="search-footer">
                  {hasResults ? (
                    <IDocCarPagination
                      id="btn_pag_sales"
                      page={page}
                      pagesCount={pages}
                      totalRows= {count}
                      onSelectAction={(page) => this.onPageChange(page)}
                    />) : <p className='no_results'>{t('GLOBAL.TABLES.NO_RESULTS')}</p>}
                </div>
              </div>
            ])}
          </div>
        </div>
      </div>
    )
  }
}

const validate = (values, { t }) => {
  const errors = {}
  if (values) {
    if (values.vehicle && values.vehicle.chassis && values.vehicle.chassis.length < settings.chassisMinCharacter) {
      errors.vehicle = {}
      errors.vehicle.chassis = t('SEARCH.CATEGORIES.ERRORS.VINCHASIS').replace('##MIN_CHARACTERS##', settings.chassisMinCharacter)
    }
  }

  return errors
}

export default reduxForm({
  form: 'SearchByVehicleForm',
  validate
})(SearchByVehicleForm)
