import mastersActionTypes from '../../../constants/actions/masters/masters'

function initialState () {
  return {
    styleTaskList: [],
    styleTaskCount: 0,
    pagesCount: 0,
    filter: {
      page: 1,
      orderBy: '',
      pageSize: 10
    },
    styleTaskModal: {
      taskId: null,
      showModal: false,
      comboId: null
    }
  }
}

function fetchStyleTaskListSuccess (state, { styleTaskList, filter }) {
  return {
    ...state,
    styleTaskList: styleTaskList.styleTaskList,
    styleTaskListCount: styleTaskList.styleTaskCount,
    pagesCount: styleTaskList.pagesCount,
    filter
  }
}

function openEditStyleTaskModalSuccess (state, { taskId, comboId }) {
  const initial = initialState()
  return {
    ...state,
    styleTaskModal: {
      ...initial.styleTaskModal,
      taskId,
      showModal: true,
      comboId: comboId
    }
  }
}

function closeEditModalSuccess (state) {
  return {
    ...state,
    styleTaskModal: {
      ...initialState().styleTaskModal
    }
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case mastersActionTypes.FETCH_STYLETASK_LIST_SUCCESS:
      return fetchStyleTaskListSuccess(state, action)
    case mastersActionTypes.FETCH_STYLE_TASK_LIST_SUCCESS:
      return openEditStyleTaskModalSuccess(state, action)
    case mastersActionTypes.CLOSE_EDIT_STYLE_TASK_MODAL:
      return closeEditModalSuccess(state, action)
    default:
      return state
  }
}
