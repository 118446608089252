import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import CommercialReportTableModal from '../../../components/report/Modals/CommercialReportTableModal'
import { translate } from 'react-polyglot'
import { fetchReportCommercialTable, closeReportCommercialTableModal, exportReportCommercialTable } from '../../../../actions/search/search'

export function mapStateToProps (state) {
  return {
    ...state.search.reportTable
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchReportCommercialTable,
      closeReportCommercialTableModal,
      exportReportCommercialTable
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(CommercialReportTableModal))
