import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import {
  fetchEntityFilterSubTypeCombo, fetchEntitySubTypeCombo, fetchIaeCombo, fetchOrganizedUnitsTreeComboByPermission, fetchOrganizedUnitsCombo, fetchLowestOrganizedUnits
  , fetchEntityCountryTypeCombo, fetchEntityStateTypeCombo, fetchEntityCityTypeCombo, fetchEntityTownTypeCombo, fetchEntityColonyTypeCombo
  , fetchEntityCPTypeCombo, fetchCommercialSocietyTypeCombo, fetchOrganizedUnitsAcceptClientsCombo, fetchEntityTypes, fetchActivityBpro, fetchOrganizedUnitsTree,
  fetchClientDataAddress, fetchEntityMaritalStatusCombo
} from '../../../actions/combos/combos'
import { getClientFleetPermissions, fetchFleetsEntity, checkChangeEntityDossier, showHistoricalFleetModal } from '../../../actions/management/management'
import { openModal } from '../../../actions/common'
import clientActions, { resetValues } from '../../../actions/dossier/common/client'
import FleetAndClientComponent from '../../../components/management/commons/FleetAndClientComponent'
import { validate } from '../../../actions/dossier/common/validations'

export function mapStateToProps (state) {
  return {
    entityFilterSubTypeCombo: state.combos.entityFilterSubTypeCombo,
    fleetFinderFilter: state.management.searchFleetInfo.dynamicFilters,
    entitySubTypeCombo: state.combos.entitySubTypeCombo,
    dniCif: state.management.dniCif,
    combos: state.combos,
    clientFleetPermissions: state.management.clientFleetPermissions,
    entityComponent: state.management.createNewFleetModal.entityComponent
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      ...clientActions,
      fetchEntityFilterSubTypeCombo,
      openModal,
      fetchEntitySubTypeCombo,
      getClientFleetPermissions,
      fetchFleetsEntity,
      checkChangeEntityDossier,
      fetchIaeCombo,
      fetchOrganizedUnitsTreeComboByPermission,
      fetchOrganizedUnitsCombo,
      fetchLowestOrganizedUnits,
      showHistoricalFleetModal,
      fetchCommercialSocietyTypeCombo,
      fetchEntityCountryTypeCombo,
      fetchEntityStateTypeCombo,
      fetchEntityCityTypeCombo,
      fetchEntityTownTypeCombo,
      fetchEntityColonyTypeCombo,
      fetchEntityCPTypeCombo,
      fetchEntityMaritalStatusCombo,
      fetchOrganizedUnitsAcceptClientsCombo,
      fetchEntityTypes,
      fetchActivityBpro,
      validate,
      resetValues,
      fetchClientDataAddress,
      fetchOrganizedUnitsTree
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(FleetAndClientComponent))
