export default {
  SEARCH_FILTERED_PURCHASE_DOSSIER: 'SEARCH_FILTERED_PURCHASE_DOSSIER',
  FETCH_AND_OPEN_REASSIGN_PURCHASE_MODAL: 'FETCH_AND_OPEN_REASSIGN_PURCHASE_MODAL',
  OPEN_REASSIGN_PURCHASE_MODAL: 'OPEN_REASSIGN_PURCHASE_MODAL',
  CLOSE_REASSIGN_PURCHASE_MODAL: 'CLOSE_REASSIGN_PURCHASE_MODAL',
  GET_DOSSIER_PURCHASE_SUCCESS: 'GET_DOSSIER_PURCHASE_SUCCESS',
  SELECT_REPLACE_PURCHASE: 'SELECT_REPLACE_PURCHASE',
  CREATE_ORDER_DOSSIER_PURCHASE: 'CREATE_ORDER_DOSSIER_PURCHASE',
  FETCH_REASSING_PURCHASE_VEHICLE_CATALOGUE_MODEL: 'FETCH_REASSING_PURCHASE_VEHICLE_CATALOGUE_MODEL'
}
