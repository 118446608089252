import React, { PureComponent } from 'react'
import { Col, Row } from 'react-bootstrap'
import InputSelect from '../../../../components/commons/form/InputSelect'
import InputText from '../../../../components/commons/form/InputText'
import InputCheckBox from '../../../../components/commons/form/InputCheckBox'
import { Field } from 'redux-form'
import { compareObjects } from '../../../../util/utils'
import { getFieldsPermisions } from '../../../../util/permisionFunctions'
import { entityTypesId, permissions, validationTypes } from '../../../../constants/backendIds'
import { dossierTypes as dossierTypesConstant } from '../../../../constants/dossier/common/dossierTypes'
import { dossierSubType as dossierSubTypeConstant } from '../../../../constants/dossier/common/dossierSubType'

class EntityProvider extends PureComponent {
  constructor () {
    super()
    this.state = {
      open: false,
      dniDisabled: true,
      allInformationDisabled: true,
      tabActiveKey: 1,
      fieldsConfiguration: {
        entityProviderId: null,
        entityProviderTypeId: null,
        entityProviderSubTypeId: null,
        dniCifProvider: null,
        nameProvider: null,
        surname1Provider: null,
        surname2Provider: null,
        representativeDniProvider: null,
        representativeNameProvider: null,
        representativeSurname1Provider: null,
        representativeSurname2Provider: null,
        addressProvider: null,
        cityProvider: null,
        stateProvider: null,
        cpProvider: null,
        countryProvider: null,
        phoneProvider: null,
        mobilePhoneProvider: null,
        emailProvider: null,
        iaeTypeProviderId: null,
        dniCifValidationDisableProvider: null,
        representativeDniCifValidationDisableProvider: null,
        differentPaperworkAddressProvider: null,
        clientNumberProvider: null,
        sourceChannelIdProvider: null,
        segmentationIdProvider: null
      },
      dniCif: null,
      errorDniCif: null,
      openEntityType: false,
      allMaritalStatus: false
    }
    this.setValidationDisable = this.setValidationDisable.bind(this)
    this.handleDNI = this.handleDNI.bind(this)
  }

  setValidationDisable (disableDniCifValidation, disableRepDniCifValidation, disableChildDniCifValidation, disableChildRepDniCifValidation) {
    this.props.actions.setEntityProviderValidationDisable(this.props.dossierType, disableDniCifValidation, disableRepDniCifValidation, disableChildDniCifValidation, disableChildRepDniCifValidation)
    if (disableDniCifValidation !== null && disableDniCifValidation !== undefined) {
      this.handleDNI(this.state.dniCif, this.props.dossier?.entityProviderComponent?.entityTypeId, disableDniCifValidation)
    }
    if (disableRepDniCifValidation !== null && disableRepDniCifValidation !== undefined) {
      this.handleDNI(this.state.representativeDniCif, this.props.dossier?.entityProviderComponent?.entityTypeId, disableRepDniCifValidation)
    }
  }

  getSubTypePapeworkRelatedDossier () {
    if (this.props.dossier.dossierRelatedDossiers && this.props.dossier.dossierRelatedDossiers.length) {
      const paperworkRelatedDossier = this.props.dossier.dossierRelatedDossiers[0]
      return paperworkRelatedDossier.dossierSubType
    }
  }

  getTitle () {
    const tKey = 'DOSSIER_COMPONENTS.ENTITY.'
    switch (this.props.dossierType) {
      case dossierTypesConstant.sales:
        return this.props.t(`${tKey}CLIENT`)
      case dossierTypesConstant.purchase:
        return this.props.t(`${tKey}VENDOR`)
      case dossierTypesConstant.paperwork:
        switch (this.getSubTypePapeworkRelatedDossier()) {
          case dossierSubTypeConstant.compra_vo:
            return this.props.t(`${tKey}ENTITY_VENDOR`)
          case dossierSubTypeConstant.venta_vo:
            return this.props.t(`${tKey}ENTITY_BUYER`)
          default:
            return this.props.t(`${tKey}PANEL_TITLE`)
        }
      default:
        return this.props.t(`${tKey}PANEL_TITLE`)
    }
  }

  getTitleSecondEntity () {
    const tKey = 'DOSSIER_COMPONENTS.ENTITY.'
    switch (this.props.dossierType) {
      case dossierTypesConstant.sales:
        return this.props.t(`${tKey}CLIENT`)
      case dossierTypesConstant.purchase:
        return this.props.t(`${tKey}VENDOR`)
      case dossierTypesConstant.paperwork:
        switch (this.getSubTypePapeworkRelatedDossier()) {
          case dossierSubTypeConstant.compra_vo:
            return this.props.t(`${tKey}ENTITY_BUYER`)
          case dossierSubTypeConstant.venta_vo:
            return this.props.t(`${tKey}ENTITY_VENDOR`)
          default:
            return this.props.t(`${tKey}PANEL_TITLE`)
        }
      default:
        return this.props.t(`${tKey}PANEL_TITLE`)
    }
  }

  getEntityTypeLabel () {
    const tKey = 'DOSSIER_COMPONENTS.ENTITY.'
    switch (this.props.dossierType) {
      case dossierTypesConstant.sales:
        return this.props.t(`${tKey}CLIENT_TYPE`)
      case dossierTypesConstant.purchase:
        return this.props.t(`${tKey}VENDOR_TYPE`)
      default:
        switch (this.getSubTypePapeworkRelatedDossier()) {
          case dossierSubTypeConstant.compra_vo:
            return this.props.t(`${tKey}ENTITY_VENDOR`)
          case dossierSubTypeConstant.venta_vo:
            return this.props.t(`${tKey}ENTITY_BUYER`)
          default:
            return this.props.t(`${tKey}ENTITY_TYPE`)
        }
    }
  }

  getNumberLabel () {
    const tKey = 'DOSSIER_COMPONENTS.ENTITY.'
    switch (this.props.dossierType) {
      case dossierTypesConstant.sales:
        return this.props.t(`${tKey}MAIN_CLIENT_NUMBER`)
      case dossierTypesConstant.purchase:
        return this.props.t(`${tKey}MAIN_VENDOR_NUMBER`)
      default:
        switch (this.getSubTypePapeworkRelatedDossier()) {
          case dossierSubTypeConstant.compra_vo:
            return this.props.t(`${tKey}MAIN_ENTITY_VENDOR_NUMBER`)
          case dossierSubTypeConstant.venta_vo:
            return this.props.t(`${tKey}MAIN_ENTITY_BUYER_NUMBER`)
          default:
            return this.props.t(`${tKey}MAIN_ENTITY_NUMBER`)
        }
    }
  }

  getEntityNameLabel () {
    const tKey = 'DOSSIER_COMPONENTS.ENTITY.'
    switch (this.props.dossier.entityProviderComponent.entityTypeId ? this.props.dossier.entityProviderComponent.entityTypeId : this.props.dossier.entityTypeIdDefault) {
      case entityTypesId.privateEntity:
        return this.props.t(`${tKey}NAME`)
      default:
        return this.props.t(`${tKey}REGISTERED_NAME`)
    }
  }

  menuOpenClose (value) {
    this.setState({ openEntityType: value })
  }

  onEntityTypeIdChanges (entityTypeId) {
    new Promise((resolve, reject) => {
      return this.props.actions.setEntityProviderTypeId(entityTypeId, this.props.dossier.entityProviderComponent.entityTypeId, this.props.dossierType, resolve, reject)
    }).then(() => {
      if (entityTypeId === entityTypesId.recurrentEntity) this.props.actions.fetchEntitySubTypeCombo(entityTypeId)
      if (entityTypeId) {
        this.setState({ dniDisabled: false, allInformationDisabled: true })
      } else {
        this.setState({ dniDisabled: true, allInformationDisabled: true })
      }
    })
    this.setState({ allMaritalStatus: false })
  }

  handleDNI (dnicif, entityTypeId, disableValidation, representativeValidation = false) {
    if (disableValidation) {
      if (!representativeValidation) {
        this.setState({ allInformationDisabled: dnicif === '' })
      }
    } else {
      if (representativeValidation) {
        this.setState({ representativeDniCif: dnicif })
      } else {
        this.setState({ allInformationDisabled: true, dniCif: dnicif })
        new Promise((resolve, reject) => {
          if (entityTypeId === entityTypesId.privateEntity) {
            return this.props.actions.validate(validationTypes.INDIVIDUAL_IDENTIFIER, { Dni: dnicif }, this.props.dossier.organizedUnitId, resolve, reject, true)
          } else {
            return this.props.actions.validate(validationTypes.COMPANY_IDENTIFIER, { Cif: dnicif }, this.props.dossier.organizedUnitId, resolve, reject, true)
          }
        }).then(() => {
          this.setState({ allInformationDisabled: dnicif === '', dniCif: dnicif })
        })
      }
    }
  }

  onRecurrentTypeIdChanges (entitySubTypeId) {
    new Promise((resolve, reject) => {
      return this.props.actions.setEntityProviderSubTypeId(entitySubTypeId, this.props.dossierType, resolve, reject)
    }).then(() => {
      if (entitySubTypeId) this.props.actions.fetchEntityFilterSubTypeCombo(this.props.dossier.entityProviderComponent.entityTypeId || this.props.dossier.entityTypeIdDefault, entitySubTypeId, this.props.dossier.organizedUnitId)
    })
  }

  onRecurrentEntityIdChanges (entityId) {
    new Promise((resolve, reject) => {
      return this.props.actions.setEntityProviderId(entityId, this.props.dossierType, resolve, reject)
    }).then(() => {
      if (entityId) this.props.actions.fetchProviderFromDocument(entityId, this.props.dossierType)
    })
  }

  getEntity (value) {
    const dniCif = value
    if (value) {
      this.props.actions.fetchClientByDni(dniCif, this.props.dossier.organizedUnitId, this.props.dossierType)
    }
  }

  initializePermisions (props) {
    const fieldKeys = Object.keys(this.state.fieldsConfiguration)
    const newState = getFieldsPermisions(props.componentConfiguration.fieldsConfiguration, fieldKeys, props.componentConfiguration.permission)
    this.setState({ ...this.state, ...newState })
  }

  componentDidMount () {
    this.initializePermisions(this.props)

    if (this.props.dossier.entityProviderComponent.parentEntityId) {
      this.props.actions.fetchEntitiesByTypeByParent(entityTypesId.claimClient, this.props.dossier.entityProviderComponent.parentEntityId)
    }
    this.setState({ allMaritalStatus: this.props.dossier?.entityComponent?.maritalStatusId !== null })
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({ open: true })
    }
    if (this.props.componentConfiguration && !compareObjects(prevProps.componentConfiguration || {}, this.props.componentConfiguration, true)) {
      this.initializePermisions(this.props)
    }

    if (this.state.isDifferentPaperworkAddress === undefined && this.props.dossier && this.props.dossier.dossierPaperworkComponent &&
            this.props.dossier.dossierPaperworkComponent.dossierPaperworkAddressComponent &&
            this.props.dossier.dossierPaperworkComponent.dossierPaperworkAddressComponent.differentPaperworkAddress !== this.state.isDifferentPaperworkAddress) {
      this.setState({ isDifferentPaperworkAddress: this.props.dossier.dossierPaperworkComponent.dossierPaperworkAddressComponent.differentPaperworkAddress })
    }
    if ((this.props.dossier.entityProviderComponent && this.props.dossier.entityProviderComponent.entityId && this.state.allInformationDisabled) ||
            (this.props.dossier.entityProviderComponent && this.props.dossier.entityProviderComponent.dniCifValidationDisable && this.state.dniCif !== null && this.state.dniCif !== '')) {
      this.setState({ allInformationDisabled: false })
    }
    if ((prevProps.dossier.entityProviderComponent && prevProps.dossier.entityProviderComponent.entityId &&
            (!this.props.dossier.entityProviderComponent || !this.props.dossier.entityProviderComponent.entityId) && !this.state.allInformationDisabled) ||
            (this.props.dossier.entityProviderComponent && !this.props.dossier.entityProviderComponent.dniCifValidationDisable && (this.state.dniCif !== null && this.state.dniCif !== '' &&
                (this.state.errorDniCif === 'dniNotValid' || this.state.errorDniCif === 'dniNotFormat' || this.state.errorDniCif === 'cifNotValid' || this.state.errorDniCif === 'cifNotFormat')))) {
      this.setState({ allInformationDisabled: true })
    }
    return this.props
  }

  componentDidUpdate () {
  }

  removeRecurrentEntityTypeFromCombo (entityTypeCombo) {
    var entityTypeComboNoRecurrent = []
    for (const entity of entityTypeCombo) {
      if (entity.id !== entityTypesId.recurrentEntity) {
        entityTypeComboNoRecurrent.push(entity)
      }
    }
    return entityTypeComboNoRecurrent
  }

  fetchSalesDossierPromiseClient () {
    if (this.state.open) {
      this.props.actions.setActiveSection(false)
    }
    this.setState({
      open: !this.state.open
    })
    if (this.props.isClientLoaded === false && this.props.dossier && (!this.props.dossier.entityProviderComponent.entityTypeId || this.props.dossier.entityProviderComponent.autoLoadedEntity === false)) {
      return new Promise((resolve, reject) => {
        this.props.handleToUpdateComponent(this.props)
        if (this.props.dossierType === dossierTypesConstant.sales) {
          return this.props.actions.fetchSalesClient(this.props.dossier.dossierId, resolve, reject, this.props.pristine)
        } else if (this.props.dossierType === dossierTypesConstant.purchase) {
          return this.props.actions.fetchPurchaseClient(this.props.dossier.dossierId, resolve, reject, this.props.pristine)
        } else if (this.props.dossierType === dossierTypesConstant.paperwork) {
          return this.props.actions.fetchPaperworkClient(this.props.dossier.dossierId, resolve, reject, false, this.props.pristine)
        } else if (this.props.dossierType === dossierTypesConstant.campaign) {
          return this.props.actions.fetchCampaignClient(this.props.dossier.dossierId, resolve, reject, this.props.pristine)
        } else if (this.props.dossierType === dossierTypesConstant.registrationDelivery) {
          return this.props.actions.fetchRegistrationDeliveryClient(this.props.dossier.dossierId, resolve, reject, this.props.pristine)
        }
      })
    }
  }

  render () {
    const {
      t,
      entityChangeable, readOnlyFields, combos: { entityTypeCombo, channelCombo, maritalStatusCombo, segmentationCombo, entitySubTypeCombo, entityFilterSubTypeCombo, iaeTypeCombo },
      dossier: {
        entityProviderComponent, entityTypeIdDefault
      }
    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.ENTITY.'
    let disabledByNotSelectedEntityTypeId = !this.props.dossier.entityProviderComponent && !this.props.dossier.entityProviderComponent.entityTypeId && !entityTypeIdDefault
    const isRecurrentEntityType = (entityProviderComponent && entityProviderComponent.entityTypeId ? entityProviderComponent.entityTypeId === entityTypesId.recurrentEntity
      : entityTypeIdDefault ? entityTypeIdDefault === entityTypesId.recurrentEntity : false)
    const isParticularEntityType = (entityProviderComponent && entityProviderComponent.entityTypeId ? entityProviderComponent.entityTypeId === entityTypesId.privateEntity
      : entityTypeIdDefault ? entityTypeIdDefault === entityTypesId.privateEntity : false)
    return (
      <React.Fragment>
        {
          this.state.fieldsConfiguration.dniCifValidationDisableProvider && this.state.fieldsConfiguration.dniCifValidationDisableProvider.permission !== permissions.hidden &&
                    entityProviderComponent && entityProviderComponent.entityTypeId && !isRecurrentEntityType &&
                    (
                      <Col sm={12}>
                        <Field
                          colSm={0}
                          id="dniCifValidationDisable"
                          name="entityProviderComponent.dniCifValidationDisable"
                          placeholder={t(`${tKey}DISABLE_DNI_CIF_VALIDATION`)}
                          component={InputCheckBox}
                          disabled={readOnlyFields || this.state.fieldsConfiguration.dniCifValidationDisableProvider.permission !== permissions.editable || (entityProviderComponent.entityId !== null && entityProviderComponent.entityId !== undefined)}
                          onInputChange={(value) => {
                            this.setValidationDisable(value, null, null, null)
                            this.props.actions.asyncValidate({ entityComponent: { ...this.props.formValues.entityProviderComponent, dniCifValidationDisable: value } }, this.props.actions.dispatch, this.props, 'dniCif', true).then((result) => {
                              this.props.actions.stopAsyncValidation({ ...result })
                            })
                          }}
                          inline
                        />
                      </Col>
                    )
        }
        {
          this.state.fieldsConfiguration.representativeDniCifValidationDisableProvider && this.state.fieldsConfiguration.representativeDniCifValidationDisableProvider.permission !== permissions.hidden &&
                    entityProviderComponent && entityProviderComponent.entityTypeId === entityTypesId.businessEntity &&
                    (
                      <Col sm={12}>
                        <Field
                          colSm={0}
                          id="representativeDniCifValidationDisable"
                          name="entityProviderComponent.representativeDniCifValidationDisable"
                          placeholder={t(`${tKey}DISABLE_REPR_DNI_CIF_VALIDATION`)}
                          component={InputCheckBox}
                          disabled={readOnlyFields || this.state.fieldsConfiguration.representativeDniCifValidationDisableProvider.permission !== permissions.editable}
                          onInputChange={(value) => {
                            this.setValidationDisable(null, value, null, null)
                            this.props.actions.asyncValidate({ entityComponent: { ...this.props.formValues.entityProviderComponent, representativeDniCifValidationDisable: value } }, this.props.actions.dispatch, this.props, 'representativeDniCif', true).then((result) => {
                              this.props.actions.stopAsyncValidation({ ...result })
                            })
                          }}
                          inline
                        />
                      </Col>
                    )
        }
        <Row>
          {this.state.fieldsConfiguration.entityProviderTypeId && this.state.fieldsConfiguration.entityProviderTypeId.permission !== permissions.hidden && (
            <Field
              colSm={4}
              id="entityTypeId"
              name="entityProviderComponent.entityTypeId"
              controlLabel={this.getEntityTypeLabel()}
              placeholder={this.getEntityTypeLabel()}
              component={InputSelect}
              options={entityTypeCombo}
              onInputChange={this.onEntityTypeIdChanges.bind(this)}
              valueKey="id"
              labelKey="value"
              isClearable={false}
              disabled={readOnlyFields || entityProviderComponent.entityId || this.state.fieldsConfiguration.entityProviderTypeId.permission !== permissions.editable}
              defaultValueEntityType={entityTypeIdDefault}
              menuIsOpen= {this.state.openEntityType}
              onMenuOpen = {(value) => this.menuOpenClose(value)}
              onMenuClose = {(value) => this.menuOpenClose(value)}
            />
          )}
          {isRecurrentEntityType && ([
            this.state.fieldsConfiguration.entityProviderSubTypeId && this.state.fieldsConfiguration.entityProviderSubTypeId.permission !== permissions.hidden && (
              <Field
                colSm={4}
                id="entitySubTypeId"
                name="entityProviderComponent.entitySubTypeId"
                key="entityProviderComponent.entitySubTypeId"
                controlLabel={t(`${tKey}TYPE`)}
                placeholder={t(`${tKey}TYPE`)}
                component={InputSelect}
                onInputChange={this.onRecurrentTypeIdChanges.bind(this)}
                options={entitySubTypeCombo}
                valueKey="id"
                labelKey="value"
                disabled={readOnlyFields || this.state.fieldsConfiguration.entityProviderSubTypeId.permission !== permissions.editable || disabledByNotSelectedEntityTypeId || !entityChangeable}
              />
            ),
            this.state.fieldsConfiguration.entityProviderId && this.state.fieldsConfiguration.entityProviderId.permission !== permissions.hidden && (
              <Field
                colSm={4}
                id="entityId"
                name="entityProviderComponent.entityId"
                key="entityProviderComponent.entityId"
                controlLabel={this.getTitle()}
                placeholder={this.getTitle()}
                component={InputSelect}
                onInputChange={this.onRecurrentEntityIdChanges.bind(this)}
                options={entityFilterSubTypeCombo}
                valueKey="id"
                labelKey="value"
                disabled={readOnlyFields || !entityProviderComponent.entitySubTypeId || this.state.fieldsConfiguration.entityProviderId.permission !== permissions.editable || !entityChangeable}
              />
            ),
            <div key="clearfix_entityId" className="clearfix" />
          ])}
          {this.state.fieldsConfiguration.dniCifProvider && this.state.fieldsConfiguration.dniCifProvider.permission !== permissions.hidden && (
            <Field
              id="dniCif"
              name="entityProviderComponent.dniCif"
              disabled={(!isRecurrentEntityType
                ? (readOnlyFields || !entityChangeable || this.state.fieldsConfiguration.dniCifProvider.permission !== permissions.editable || disabledByNotSelectedEntityTypeId)
                : true) || entityProviderComponent.entityId || this.state.dniDisabled}
              colSm={4}
              controlLabel={t(`${tKey}DNI`)}
              placeholder={t(`${tKey}DNI`)}
              component={InputText}
              onInputBlur={event => this.getEntity(event)}
              onInputChange={(value) => this.handleDNI(value, entityProviderComponent.entityTypeId || entityTypeIdDefault, entityProviderComponent.dniCifValidationDisable)}
            />
          )}
          {!isRecurrentEntityType && ([
            this.state.fieldsConfiguration.clientNumberProvider && this.state.fieldsConfiguration.clientNumberProvider.permission !== permissions.hidden && (
              <Field
                id="clientNumber"
                name="entityProviderComponent.clientNumber"
                key="entityProviderComponent.clientNumber"
                colSm={4}
                controlLabel={this.getNumberLabel()}
                placeholder={this.getNumberLabel()}
                component={InputText}
                disabled={readOnlyFields || this.state.allInformationDisabled || this.state.fieldsConfiguration.clientNumberProvider.permission !== permissions.editable}
                forceControlLabelInOneLine={true}
              />
            ),
            <div key="clearfix_clientNumber" className="clearfix" />
          ])}
          {(entityProviderComponent.entityTypeId === entityTypesId.privateEntity || entityProviderComponent.entityTypeId === entityTypesId.businessEntity) &&
                        this.state.fieldsConfiguration.nameProvider && this.state.fieldsConfiguration.nameProvider.permission !== permissions.hidden && (
            <Field
              id="name"
              name="entityProviderComponent.name"
              colSm={4}
              controlLabel={this.getEntityNameLabel()}
              placeholder={this.getEntityNameLabel()}
              component={InputText}
              disabled={readOnlyFields || this.state.allInformationDisabled || this.state.fieldsConfiguration.nameProvider.permission !== permissions.editable}
            />
          )}
          {entityProviderComponent.entityTypeId === entityTypesId.privateEntity && ([
            this.state.fieldsConfiguration.surname1Provider && this.state.fieldsConfiguration.surname1Provider.permission !== permissions.hidden && (
              <Field
                id="surname1"
                name="entityProviderComponent.surname1"
                key="entityProviderComponent.surname1"
                colSm={4}
                controlLabel={t(`${tKey}SURNAME1`)}
                placeholder={t(`${tKey}SURNAME1`)}
                component={InputText}
                disabled={readOnlyFields || this.state.allInformationDisabled || this.state.fieldsConfiguration.surname1Provider.permission !== permissions.editable}
              />
            ),
            this.state.fieldsConfiguration.surname2Provider && this.state.fieldsConfiguration.surname2Provider.permission !== permissions.hidden && (
              <Field
                id="surname2"
                name="entityProviderComponent.surname2"
                key="entityProviderComponent.surname2"
                colSm={4}
                controlLabel={t(`${tKey}SURNAME2`)}
                placeholder={t(`${tKey}SURNAME2`)}
                component={InputText}
                disabled={readOnlyFields || this.state.allInformationDisabled || this.state.fieldsConfiguration.surname2Provider.permission !== permissions.editable}
              />
            ),
            <div key="clearfix_surname2" className="clearfix" />
          ])}
          {entityProviderComponent.entityTypeId === entityTypesId.businessEntity && ([
            this.state.fieldsConfiguration.representativeDniProvider && this.state.fieldsConfiguration.representativeDniProvider.permission !== permissions.hidden && (
              <Field
                id="representativeDni"
                name="entityProviderComponent.representativeDni"
                key="entityProviderComponent.representativeDni"
                colSm={4}
                controlLabel={t(`${tKey}REPRESENTATIVE_DNI`)}
                placeholder={t(`${tKey}REPRESENTATIVE_DNI`)}
                component={InputText}
                disabled={readOnlyFields || this.state.allInformationDisabled || this.state.fieldsConfiguration.representativeDniProvider.permission !== permissions.editable}
                onInputChange={(value) => this.handleDNI(value, entityProviderComponent.entityTypeId || entityTypeIdDefault, entityProviderComponent.representativeDniCifValidationDisable, true)}
              />
            ),
            this.state.fieldsConfiguration.representativeNameProvider && this.state.fieldsConfiguration.representativeNameProvider.permission !== permissions.hidden && (
              <Field
                id="representativeName"
                name="entityProviderComponent.representativeName"
                key="entityProviderComponent.representativeName"
                colSm={4}
                controlLabel={t(`${tKey}REPRESENTATIVE_NAME`)}
                placeholder={t(`${tKey}REPRESENTATIVE_NAME`)}
                component={InputText}
                disabled={readOnlyFields || this.state.allInformationDisabled || this.state.fieldsConfiguration.representativeNameProvider.permission !== permissions.editable}
              />
            ),
            <div key="clearfix_representativeName" className="clearfix" />,
            this.state.fieldsConfiguration.representativeSurname1Provider && this.state.fieldsConfiguration.representativeSurname1Provider.permission !== permissions.hidden && (
              <Field
                id="representativeSurname1"
                name="entityProviderComponent.representativeSurname1"
                key="entityProviderComponent.representativeSurname1"
                colSm={4}
                controlLabel={t(`${tKey}REPRESENTATIVE_SURNAME1`)}
                placeholder={t(`${tKey}REPRESENTATIVE_SURNAME1`)}
                component={InputText}
                disabled={readOnlyFields || this.state.allInformationDisabled || this.state.fieldsConfiguration.representativeSurname1Provider.permission !== permissions.editable}
                forceControlLabelInOneLine={true}
              />
            ),
            this.state.fieldsConfiguration.representativeSurname2Provider && this.state.fieldsConfiguration.representativeSurname2Provider.permission !== permissions.hidden && (
              <Field
                id="representativeSurname2"
                name="entityProviderComponent.representativeSurname2"
                key="entityProviderComponent.representativeSurname2"
                colSm={4}
                controlLabel={t(`${tKey}REPRESENTATIVE_SURNAME2`)}
                placeholder={t(`${tKey}REPRESENTATIVE_SURNAME2`)}
                component={InputText}
                disabled={readOnlyFields || this.state.allInformationDisabled || this.state.fieldsConfiguration.representativeSurname2Provider.permission !== permissions.editable}
                forceControlLabelInOneLine={true}
              />
            )
          ])}
          { !isParticularEntityType &&
          this.state.fieldsConfiguration.maritalcmb && this.state.fieldsConfiguration.maritalcmb.permission !== permissions.hidden && (
            <Field
              colSm={4}
              id="maritalStatusId"
              name="entityComponent.maritalStatusId"
              key="entityComponent.maritalStatusId"
              controlLabel={t(`${tKey}MARITAL_STATUS`)}
              placeholder={t(`${tKey}MARITAL_STATUS`)}
              component={InputSelect}
              options={this.state.allMaritalStatus ? maritalStatusCombo : maritalStatusCombo?.filter(x => x.description === 'True')}
              valueKey="id"
              labelKey="value"
              disabled={readOnlyFields || this.state.allInformationDisabled || this.state.fieldsConfiguration.maritalcmb.permission !== permissions.editable}
            />
          )}
          {!isRecurrentEntityType && ([
            this.state.fieldsConfiguration.addressProvider && this.state.fieldsConfiguration.addressProvider.permission !== permissions.hidden && (
              <Field
                id="entityAddress"
                name="entityProviderComponent.address"
                key="entityProviderComponent.address"
                colSm={4}
                controlLabel={t(`${tKey}ADDRESS`)}
                placeholder={t(`${tKey}ADDRESS`)}
                component={InputText}
                disabled={readOnlyFields || this.state.allInformationDisabled || this.state.fieldsConfiguration.addressProvider.permission !== permissions.editable}
              />
            ),
            entityProviderComponent.entityTypeId === entityTypesId.businessEntity && (
              <div key="clearfix_address" className="clearfix" />
            ),
            this.state.fieldsConfiguration.cityProvider && this.state.fieldsConfiguration.cityProvider.permission !== permissions.hidden && (
              <Field
                id="city"
                name="entityProviderComponent.city"
                key="entityProviderComponent.city"
                colSm={4}
                controlLabel={t(`${tKey}CITY`)}
                placeholder={t(`${tKey}CITY`)}
                component={InputText}
                disabled={readOnlyFields || this.state.allInformationDisabled || this.state.fieldsConfiguration.cityProvider.permission !== permissions.editable}
              />
            ),
            this.state.fieldsConfiguration.stateProvider && this.state.fieldsConfiguration.stateProvider.permission !== permissions.hidden && (
              <Field
                id="state"
                name="entityProviderComponent.state"
                key="entityProviderComponent.state"
                colSm={4}
                controlLabel={t(`${tKey}STATE`)}
                placeholder={t(`${tKey}STATE`)}
                component={InputText}
                disabled={readOnlyFields || this.state.allInformationDisabled || this.state.fieldsConfiguration.stateProvider.permission !== permissions.editable}
              />
            ),
            entityProviderComponent.entityTypeId !== entityTypesId.businessEntity && (
              <div key="clearfix_state" className="clearfix" />
            ),
            this.state.fieldsConfiguration.cpProvider && this.state.fieldsConfiguration.cpProvider.permission !== permissions.hidden && (
              <Field
                id="cp"
                name="entityProviderComponent.cp"
                key="entityProviderComponent.cp"
                colSm={4}
                controlLabel={t(`${tKey}CP`)}
                placeholder={t(`${tKey}CP`)}
                component={InputText}
                disabled={readOnlyFields || this.state.allInformationDisabled || this.state.fieldsConfiguration.cityProvider.permission !== permissions.editable}
              />
            ),
            entityProviderComponent.entityTypeId === entityTypesId.businessEntity && (
              <div key="clearfix_cp" className="clearfix" />
            ),
            this.state.fieldsConfiguration.countryProvider && this.state.fieldsConfiguration.countryProvider.permission !== permissions.hidden && (
              <Field
                id="country"
                name="entityProviderComponent.country"
                key="entityProviderComponent.country"
                colSm={4}
                controlLabel={t(`${tKey}COUNTRY`)}
                placeholder={t(`${tKey}COUNTRY`)}
                component={InputText}
                disabled={readOnlyFields || this.state.allInformationDisabled || this.state.fieldsConfiguration.countryProvider.permission !== permissions.editable}
              />
            ),
            this.state.fieldsConfiguration.phoneProvider && this.state.fieldsConfiguration.phoneProvider.permission !== permissions.hidden && (
              <Field
                id="phone"
                name="entityProviderComponent.phone"
                key="entityProviderComponent.phone"
                colSm={4}
                controlLabel={t(`${tKey}PHONE`)}
                placeholder={t(`${tKey}PHONE`)}
                component={InputText}
                disabled={readOnlyFields || this.state.allInformationDisabled || this.state.fieldsConfiguration.phoneProvider.permission !== permissions.editable}
              />
            ),
            entityProviderComponent.entityTypeId !== entityTypesId.businessEntity && (
              <div key="clearfix_phone" className="clearfix" />
            ),
            this.state.fieldsConfiguration.mobilePhoneProvider && this.state.fieldsConfiguration.mobilePhoneProvider.permission !== permissions.hidden && (
              <Field
                id="mobilePhone"
                name="entityProviderComponent.mobilePhone"
                key="entityProviderComponent.mobilePhone"
                colSm={4}
                controlLabel={t(`${tKey}MOBILE`)}
                placeholder={t(`${tKey}MOBILE`)}
                component={InputText}
                disabled={readOnlyFields || this.state.allInformationDisabled || this.state.fieldsConfiguration.mobilePhoneProvider.permission !== permissions.editable}
              />
            ),
            entityProviderComponent.entityTypeId === entityTypesId.businessEntity && (
              <div key="clearfix_mobilePhone" className="clearfix" />
            ),
            this.state.fieldsConfiguration.emailProvider && this.state.fieldsConfiguration.emailProvider.permission !== permissions.hidden && (
              <Field
                id="email"
                name="entityProviderComponent.email"
                key="entityProviderComponent.email"
                colSm={4}
                controlLabel={t(`${tKey}EMAIL`)}
                placeholder={t(`${tKey}EMAIL`)}
                component={InputText}
                disabled={readOnlyFields || this.state.allInformationDisabled || this.state.fieldsConfiguration.emailProvider.permission !== permissions.editable}
              />
            ),
            this.state.fieldsConfiguration.sourceChannelIdProvider && this.state.fieldsConfiguration.sourceChannelIdProvider.permission !== permissions.hidden && (
              <Field
                colSm={4}
                id="sourceChannelId"
                name="entityProviderComponent.sourceChannelId"
                key="entityProviderComponent.sourceChannelId"
                controlLabel={t(`${tKey}SOURCE_CHANNEL`)}
                placeholder={t(`${tKey}SOURCE_CHANNEL`)}
                component={InputSelect}
                options={channelCombo}
                valueKey="id"
                labelKey="value"
                disabled={readOnlyFields || this.state.allInformationDisabled || this.state.fieldsConfiguration.sourceChannelIdProvider.permission !== permissions.editable}
                customClass="hidden"
              />
            ),
            entityProviderComponent.entityTypeId !== entityTypesId.businessEntity && (
              <div key="clearfix_sourceChannelId" className="clearfix" />
            ),
            this.state.fieldsConfiguration.segmentationIdProvider && this.state.fieldsConfiguration.segmentationIdProvider.permission !== permissions.hidden && (
              <Field
                colSm={4}
                id="segmentation"
                name="entityProviderComponent.segmentationId"
                key="entityProviderComponent.segmentationId"
                controlLabel={t(`${tKey}SEGMENTATION`)}
                placeholder={t(`${tKey}SEGMENTATION`)}
                component={InputSelect}
                options={segmentationCombo}
                valueKey="id"
                labelKey="value"
                disabled={readOnlyFields || this.state.allInformationDisabled || this.state.fieldsConfiguration.segmentationIdProvider.permission !== permissions.editable}
                customClass="hidden"
              />
            ),
            this.state.fieldsConfiguration.iaeTypeProviderId && this.state.fieldsConfiguration.iaeTypeProviderId.permission !== permissions.hidden && entityProviderComponent.entityTypeId === entityTypesId.businessEntity && (
              <Field
                colSm={4}
                id="iaeTypeId"
                name="entityProviderComponent.iaeTypeId"
                key="entityProviderComponent.iaeTypeId"
                controlLabel={t(`${tKey}IAE_TYPE`)}
                placeholder={t(`${tKey}IAE_TYPE`)}
                component={InputSelect}
                options={iaeTypeCombo}
                valueKey="id"
                labelKey="value"
                disabled={readOnlyFields || this.state.allInformationDisabled || this.state.fieldsConfiguration.iaeTypeProviderId.permission !== permissions.editable}
              />
            )
          ])}
          {isRecurrentEntityType && this.props.dossierType === dossierTypesConstant.sales && (
            <div key="clearfix_differentPaperworkAddress" className="clearfix" />
          )}
          {this.props.dossierType === dossierTypesConstant.paperwork && this.state.fieldsConfiguration.differentPaperworkAddressProvider &&
                        this.state.fieldsConfiguration.differentPaperworkAddressProvider.permission !== permissions.hidden ? (
              <div className="address-diferent">
                <Field
                  colSm={4}
                  id="differentPaperworkAddress"
                  name="dossierPaperworkComponent.dossierPaperworkAddressComponent.differentPaperworkAddress"
                  component={InputCheckBox}
                  placeholder={t(`${tKey}PAPERWORK_ADDRESS_DIFFERENT`)}
                  disabled={readOnlyFields || this.state.allInformationDisabled || this.state.fieldsConfiguration.differentPaperworkAddressProvider.permission !== permissions.editable}
                  onInputChange={(value) => this.setState({ isDifferentPaperworkAddress: value, tabActiveKey: 1 })}
                />
              </div>
            ) : null}
        </Row>
      </React.Fragment>
    )
  }
}
export default EntityProvider
