
import React, { PureComponent } from 'react'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'
import { translateDocumentStatus, documentStatusClass } from '../../../../../constants/dossier/common/documentStatus'
import { documentEntityType as entityType } from '../../../../../constants/dossier/common/documentEntityType'

class DocumentListItemRecurrentEntity extends PureComponent {
  render () {
    const {
      t,
      documentFile,
      readOnly,
      objectId,
      actions: { openDocumentEditionModalWithChecklistId, openModal, cancelDocument, printCheckList, addDependant }
    } = this.props
    const tKey = 'RECURRENT_ENTITY_MANAGEMENT.DOCUMENTARY_MANAGEMENT.'
    const statusTooltip = (<Tooltip id="statusTooltip">{documentFile.documentStatusId && t(`${tKey}TABLE.${translateDocumentStatus[documentFile.documentStatusId]}`)}</Tooltip>)
    const cancelTooltip = (<Tooltip id="cancelTooltip">{t(`${tKey}TABLE.CANCEL`)}</Tooltip>)
    const printTooltip = (<Tooltip id="printTooltip">{t(`${tKey}TABLE.PRINT`)}</Tooltip>)
    const dependantTooltip = (<Tooltip id="dependantTooltip">{t(`${tKey}TABLE.ADD_DEPENDANT`) + documentFile.addTypeDescription}</Tooltip>)
    return (
      <tr onClick={() => {
        if (readOnly) return
        if (objectId) {
          openDocumentEditionModalWithChecklistId(documentFile.documentId, documentFile.documentTypeUse, documentFile.checklistId)
        } else {
          const alert = documentFile.documentTypeEntityId === entityType.ENTITY ? 'entityAlert' : 'vehicleAlert'
          openModal(alert)
        }
      }}
      >
        <td className="text-left">
          <span>
            <OverlayTrigger placement="right" overlay={statusTooltip}>
              <i id="documentStatus" className={'ico-document-' + documentStatusClass[translateDocumentStatus[documentFile.documentStatus]]}/>
            </OverlayTrigger>
          </span>
        </td>
        <td><span>{documentFile.documentName}</span></td>
        <td><span className="file-text">{documentFile.validationDate}</span></td>
        <td><span className="file-text">{documentFile.incorporationDate}</span></td>
        <td>
          <span className="document-modal">
            {documentFile.canBePrinted && (<OverlayTrigger placement="left" overlay={printTooltip}><i onClick={(e) => {
              e.stopPropagation()
              documentFile.checklistId && printCheckList(documentFile.checklistId)
            }} className="ico-print"/></OverlayTrigger>)}
            {documentFile.showAddButton && (<OverlayTrigger placement="left" overlay={dependantTooltip}><i onClick={(e) => {
              e.stopPropagation()
              addDependant(null, documentFile.documentId, documentFile.documentEntityType)
            }} className="ico-document-plus"/></OverlayTrigger>)}
            {documentFile.showCancelButton
              ? (<OverlayTrigger placement="left" overlay={cancelTooltip}>
                <i className="ico-cerrar" onClick={(e) => {
                  e.stopPropagation()
                  cancelDocument(null, documentFile.documentId, documentFile.documentEntityType, null)
                }}/>
              </OverlayTrigger>
              ) : null
            }
          </span>
        </td>
      </tr>
    )
  }
}

export default DocumentListItemRecurrentEntity
