import { put, call, select, takeEvery } from 'redux-saga/effects'
import { reset } from 'redux-form'
import dossierActionsTypes from '../../../constants/actions/dossier/dossier'
import { getAuth } from '../../../selectors/access/auth'
import { showLoader, hideLoader } from '../../../actions/common'
import messageModalActions from '../../../actions/modals/messageModal'
import { handleError } from '../../errors/errorManager'
import postDossierService from '../../../services/dossier/post/postDossierService'
import { fetchSalesServices } from '../fetchSalesDossier'
import { getDossierRelatedDossiersConf } from '../../../selectors/dossier/common/dossierRelatedDossiers'
import getDossierRelatedDossiers from '../../../services/dossier/get/getDossierRelatedDossiers'
import getNotValidDatesService from '../../../services/dossier/get/getNotValidDatesService'
import { fetchSalesDossierSuccess } from '../../../actions/dossier/sales'
import createUpdateDossierToshikoService from '../../../services/toshiko/createUpdateDossierToshiko'

export function * saveSalesService ({ dossierService, isPurchase }) {
  try {
    let dossier = ''
    if (isPurchase) {
      dossier = yield select(state => state.purchaseDossier)
    } else {
      dossier = yield select(state => state.salesDossier)
    }

    const auth = yield select(getAuth)
    yield put(showLoader())
    yield call(postDossierService, dossierService, auth.token, dossier.lastAccessDate)
    yield put(messageModalActions.openSuccessMessageModal('MESSAGES.POST_TABLE_ITEM_SUCCESS'))
    yield put(hideLoader())
    yield call(fetchSalesServices, { dossierId: dossierService.dossierParentId })
    let dossierRelatedDossiersConf = yield select(getDossierRelatedDossiersConf)
    let dossierRelatedDossiers = yield call(getDossierRelatedDossiers, dossier.dossierId, dossierRelatedDossiersConf.orderby, auth.token)
    yield put(fetchSalesDossierSuccess({ dossierRelatedDossiers }))
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.SALES_DOSSIER.ERROR_GET_SALES_DOSSIER', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSaveSalesService () {
  yield takeEvery(dossierActionsTypes.SAVE_SALES_SERVICE, saveSalesService)
}

export function * fetchNotValidDatesService ({ ouId, dossierSubTypeId, month }) {
  try {
    yield put(showLoader())
    const salesDossier = yield select(state => state.salesDossier)
    const auth = yield select(getAuth)
    let dates = yield call(getNotValidDatesService, ouId, dossierSubTypeId, month, auth.token)
    let datesParsed = []
    dates.forEach(date => datesParsed.push(new Date(date)))
    let mod = salesDossier.dossierServiceRelateds
    mod = {
      ...mod,
      datesNotValid: datesParsed
    }
    yield put(fetchSalesDossierSuccess({ dossierServiceRelateds: mod }))
    yield put(hideLoader())
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.SALES_DOSSIER.ERROR_GET_SALES_DOSSIER', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchNotValidDatesService () {
  yield takeEvery(dossierActionsTypes.FETCH_SERVICE_NOT_VALID_DATES, fetchNotValidDatesService)
}

export function * createUpdateDossierToshiko ({ dossierId, externalOrderId, ouId, action, resolve, reject }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const toshiko = yield call(createUpdateDossierToshikoService, dossierId, externalOrderId, ouId, action, auth.token)
    if (toshiko.tag) {
      const others =
      {
        dossier: toshiko.dossier,
        contract: toshiko.contract
      }
      yield put(messageModalActions.openSuccessMessageModal('', 'DOSSIER_TOSHIKO_SUCCESSFULLY', others))
    }
    resolve(toshiko)
  } catch (error) {
    let others = ''
    if (error.json.tag === 'notedContractAlreadyExists') {
      others = { dossier: error.json.message }
    }
    yield put(messageModalActions.openErrorMessageModal('', error.json ? error.json.tag : '', '', error.json ? error.json.errorCode : '', others))
    if (error.json.tag === 'errorDossierHasNoContract') {
      resolve(false)
    }
  } finally {
    yield put(hideLoader())
  }
}

export function * watchCreateUpdateDossierToshiko () {
  yield takeEvery(dossierActionsTypes.UPDATE_DOSSIER_TOSHIKO, createUpdateDossierToshiko)
}
