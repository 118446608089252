import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { closeEditRecurrentEntityModal, submitRecurrentEntity, fetchDocumentList } from '../../../actions/admin/recurrentEntityAdmin'
import RecurrentEntityAdminModal from '../../../components/admin/recurrentEntity/RecurrentEntityAdminModal'
import { openModal } from '../../../actions/common'
import { openDocumentCreationModal, openDocumentEditionModal } from '../../../actions/dossier/common/documentEditionModal'
import { fetchOrganizedUnitsAcceptClientsCombo, fetchEntitySubTypeCombo, fetchEntityCountryTypeCombo, fetchEntityTownTypeCombo, fetchEntityColonyTypeCombo, fetchEntityCityTypeCombo, fetchEntityCPTypeCombo, fetchEntityStateTypeCombo, fetchCommercialSocietyTypeCombo, fetchActivityBpro, fetchClientDataAddress } from '../../../actions/combos/combos'

export function mapStateToProps (state) {
  const recurrentEntityAdminModal = state.recurrentEntityAdmin.recurrentEntityAdminModal
  return {
    ...recurrentEntityAdminModal,
    sections: state.auth.sections,
    combos: {
      UOTreeCombo: state.combos.UOTreeCombo,
      UOAcceptClientsCombo: state.combos.UOAcceptClientsCombo,
      entitySubTypeCombo: state.combos.entitySubTypeCombo,
      CSTCombo: state.combos.CSTCombo,
      activityBproCombo: state.combos.activityBproCombo
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchOrganizedUnitsAcceptClientsCombo,
      fetchEntitySubTypeCombo,
      fetchEntityCountryTypeCombo,
      fetchEntityTownTypeCombo,
      fetchEntityColonyTypeCombo,
      fetchEntityCityTypeCombo,
      fetchEntityCPTypeCombo,
      fetchEntityStateTypeCombo,
      fetchDocumentList,
      closeEditRecurrentEntityModal,
      submitRecurrentEntity,
      openModal,
      openDocumentCreationModal,
      openDocumentEditionModal,
      fetchCommercialSocietyTypeCombo,
      fetchActivityBpro,
      fetchClientDataAddress
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(RecurrentEntityAdminModal))
