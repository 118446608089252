import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import RecurrentEntityAdmin from '../../../components/admin/recurrentEntity/RecurrentEntityAdmin'
import { translate } from 'react-polyglot'
import {
  fetchRecurrentEntityList, openEditRecurrentEntityModal, openAddRecurrentEntityModal, deleteRecurrentEntity, fetchDocumentList, fetchEntitySubTypesList
  , uploadRecEntMassDocument,
  setChangeTabFromMaster, openCloseUserRecurrentServiceModal
} from '../../../actions/admin/recurrentEntityAdmin'
import { openModal } from '../../../actions/common'
import { fetchEntitySubTypeCombo, fetchOrganizedUnitsAcceptClientsCombo, fetchEntityCountryTypeCombo, fetchEntityTownTypeCombo, fetchEntityColonyTypeCombo, fetchEntityCityTypeCombo, fetchEntityCPTypeCombo, fetchEntityStateTypeCombo, fetchActivityBpro } from '../../../actions/combos/combos'
import { openModalMass, fetchTemplateFile } from '../../../actions/masters/masters'
export function mapStateToProps (state) {
  return {
    ...state.recurrentEntityAdmin,
    combos: {
      UOTreeCombo: state.combos.UOTreeCombo,
      entitySubTypeCombo: state.combos.entitySubTypeCombo,
      entityTypeCombo: state.combos.entityTypeCombo
    }
  }
}
export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchRecurrentEntityList,
      fetchDocumentList,
      fetchEntitySubTypeCombo,
      fetchOrganizedUnitsAcceptClientsCombo,
      openEditRecurrentEntityModal,
      openAddRecurrentEntityModal,
      deleteRecurrentEntity,
      openModal,
      fetchEntitySubTypesList,
      openModalMass,
      fetchTemplateFile,
      setChangeTabFromMaster,
      uploadRecEntMassDocument,
      fetchEntityCountryTypeCombo,
      fetchEntityTownTypeCombo,
      fetchEntityColonyTypeCombo,
      fetchEntityCityTypeCombo,
      fetchEntityCPTypeCombo,
      fetchEntityStateTypeCombo,
      fetchActivityBpro,
      openCloseUserRecurrentServiceModal
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(RecurrentEntityAdmin))
