import { call, put, select, takeLatest } from 'redux-saga/effects'
import { getAuth } from '../../selectors/access/auth'
import getOrganizedUnitsByDossierUoLegalCif from '../../services/organizedUnit/getOrganizedUnitsByDossierUoLegalCif'
import commonActions from '../../actions/common'
import messageModalActions from '../../actions/modals/messageModal'
import { fecthOrganizedUnitsByDossierUoSucces } from '../../actions/combos/combos'
import combosActionTypes from '../../constants/actions/combos/combos'
import { handleError } from '../errors/errorManager'

export function * fecthOrganizedUnitsByDossierUo ({ organizedUnitId }) {
  try {
    yield put(commonActions.showLoader())
    const auth = yield select(getAuth)
    const response = yield call(getOrganizedUnitsByDossierUoLegalCif, auth.token, organizedUnitId)
    yield put(fecthOrganizedUnitsByDossierUoSucces(response))
  } catch (error) {
    const customHandler = function * () {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.FETCH_COMBO_ERROR'))
    }
    yield call(handleError, { error, customHandler })
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchFecthOrganizedUnitsByDossierUo () {
  yield takeLatest(combosActionTypes.FETCH_ORGANIZEDUNITS_BY_DOSSIERUO, fecthOrganizedUnitsByDossierUo)
}
