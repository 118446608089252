import apiFetch from '../../apiFetch'

export default function (saleId, purchaseId, dossierSaleUacIncome, token, lastAccessDate) {
  return apiFetch({
    endPoint: `Dossier/Purchase/${purchaseId}/Uac?saleId=${saleId}`,
    method: 'PUT',
    body: dossierSaleUacIncome,
    token: token,
    lastAccessDate
  })
}
