import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import OuLoad from '../../../components/masters/ouLoad/OuLoad'
import { translate } from 'react-polyglot'
import { fetchOrganizedUnitsTree } from '../../../actions/combos/combos'
import { uploadUoMassDocument, openModalMass, fetchTemplateFile, fetchOrganizedUnitList, openOrganizedUnitModal, setChangeTabFromConfMaster } from '../../../actions/masters/masters'
import { setChangeTabFromMaster } from '../../../actions/admin/recurrentEntityAdmin'
export function mapStateToProps (state) {
  return {
    combos: {
      UOTreeCombo: state.combos.UOTreeCombo,
      organizedUnitsAllCombo: state.combos.organizedUnitsAllCombo,
      directionOUCombo: state.combos.directionOUCombo,
      processingAgencyCombo: state.combos.processingAgencyCombo,
      legalCompanyCombo: state.combos.legalCompanyCombo,
      countriesCombo: state.combos.countries
    },
    ...state.organizedUnit
  }
}
export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchOrganizedUnitsTree,
      fetchOrganizedUnitList,
      openOrganizedUnitModal,
      uploadUoMassDocument,
      openModalMass,
      fetchTemplateFile,
      setChangeTabFromMaster,
      setChangeTabFromConfMaster
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(OuLoad))
