import React, { PureComponent } from 'react'
import { Field } from 'redux-form'
import { Panel, Row, Col } from 'react-bootstrap'
import InputDatePicker from '../../commons/form/InputDatePicker'
import InputText from '../../commons/form/InputText'
import InputMoney from '../../commons/form/InputMoney'
import { validateDate } from '../../../util/validationFunctions'
import settings from '../../../setting'
import { clientInformation, permissions } from '../../../constants/backendIds'
import EconomicPlanGiftsPresents from './economicPlan/EconomicPlanGiftsPresents'
import { getComponentsPermisions, getFieldsPermisions, getFieldsConfiguration } from '../../../util/permisionFunctions'
import { icoCurrencyContent } from '../../../constants/dossier/common/currencies'

class PurchaseEconomicPlan extends PureComponent {
  constructor () {
    super()
    this.state = {
      open: true,
      lastAccesDate: null,
      componentsConfiguration: {
        mainEconomicPlan: null,
        reaconditioning: null
      },
      mainFieldsConfiguration: {
        amount: null,
        optionalsAmount: null,
        modelPriceCost: null,
        originalPrice: null,
        amortization: null,
        reconditioningField: null,
        expirationDate: null,
        billingNumber: null,
        billingDate: null
      },
      reaconditioningFieldsConfiguration: {
        amountTotal: null,
        presents: null
      },
      fieldsConfiguration: {
        reaconditioning: null
      }
    }
    this.calcAmount = this.calcAmount.bind(this)
  }

  calcAmount (e, value) {
    let originalPrice = this.props.dossierEconomicPlan.originalPrice && this.props.dossierEconomicPlan.originalPrice !== '' ? this.props.dossierEconomicPlan.originalPrice : 0
    let amortization = this.props.dossierEconomicPlan.amortization && this.props.dossierEconomicPlan.amortization !== '' ? this.props.dossierEconomicPlan.amortization : 0
    let reconditioning = this.props.dossierEconomicPlan.reconditioning && this.props.dossierEconomicPlan.reconditioning !== '' ? this.props.dossierEconomicPlan.reconditioning : 0

    switch (e.target.id) {
      case 'originalPrice':
        value !== '' ? originalPrice = value : originalPrice = 0
        break
      case 'amortization':
        value !== '' ? amortization = value : amortization = 0
        break
      case 'reconditioning':
        value !== '' ? reconditioning = value : reconditioning = 0
        break
    }

    let total = parseFloat(originalPrice) - parseFloat(amortization) + parseFloat(reconditioning)
    this.props.change('dossierPurchaseEconomicPlanComponent.amount', total)
  }

  componentDidMount () {
    this.setState({ lastAccesDate: this.props.dossierLastAccesDate })
    this.props.handleToUpdateComponent(this.props)
    this.props.actions.fetchPurchaseDossierEconomic(this.props.dossierId, this.props.historical, this.props.pristine)
    this.initializePermisions(this.props)
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (JSON.stringify(prevProps.dossierEconomicPlan) !== JSON.stringify(this.props.dossierEconomicPlan) &&
    this.state.lastAccesDate !== this.props.dossierLastAccesDate) {
      this.setState({ lastAccesDate: this.props.dossierLastAccesDate })
      this.props.handleToUpdateComponent(this.props)
      this.props.actions.fetchPurchaseDossierEconomic(this.props.dossierId, this.props.historical, this.props.pristine)
    }
    return prevProps
  }

  // not delete is necessary for snapshot
  componentDidUpdate () {

  }

  initializePermisions (props) {
    const componentKeys = Object.keys(this.state.componentsConfiguration)
    const newComponentState = getComponentsPermisions(props.tabsConfiguration, props.activeTab, componentKeys)
    const reaconditioning = getFieldsConfiguration(newComponentState.componentsConfiguration.reaconditioning)
    this.setState({ componentsConfiguration: newComponentState.componentsConfiguration, fieldsConfiguration: { reaconditioning } })

    if (newComponentState.componentsConfiguration.mainEconomicPlan &&
      newComponentState.componentsConfiguration.mainEconomicPlan.fieldsConfiguration) {
      const mainFieldKeys = Object.keys(this.state.mainFieldsConfiguration)
      const newMainFieldState = getFieldsPermisions(newComponentState.componentsConfiguration.mainEconomicPlan.fieldsConfiguration,
        mainFieldKeys, newComponentState.componentsConfiguration.mainEconomicPlan.permission)
      this.setState({ mainFieldsConfiguration: newMainFieldState.fieldsConfiguration })
    }

    if (newComponentState.componentsConfiguration.reaconditioning &&
      newComponentState.componentsConfiguration.reaconditioning.fieldsConfiguration) {
      const reaconditioningFieldKeys = Object.keys(this.state.reaconditioningFieldsConfiguration)
      const newReaconditioningFieldState = getFieldsPermisions(newComponentState.componentsConfiguration.reaconditioning.fieldsConfiguration,
        reaconditioningFieldKeys, newComponentState.componentsConfiguration.reaconditioning.permission)
      this.setState({ reaconditioningFieldsConfiguration: newReaconditioningFieldState.fieldsConfiguration })
    }
  }

  render () {
    const {
      t, readOnlyFields, panelsPaddingTop, isVN, dossierEconomicPlan, combos, dossierPurchaseComponent, dossierType
    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.PURCHASE_ECONOMIC_PLAN.'
    let totalPresent = {
      name: t('DOSSIER_COMPONENTS.ECONOMIC_PLAN.TOTALS'),
      amount: dossierEconomicPlan.purchasePresents ? dossierEconomicPlan.purchasePresents.reduce((acc, val) => acc + val.amount, 0) : '',
      percentage: '',
      cost: dossierEconomicPlan.purchasePresents ? dossierEconomicPlan.purchasePresents?.reduce((acc, val) => val.isDeleted ?? false ? acc + 0 : acc + val.cost, 0) : 0,
      supInvoice: '',
      iem: '',
      provider: ''
    }
    return (
      <Row className="sales-panel dossier-panel">
        <Col sm={12} className="col-row" style={{ paddingTop: panelsPaddingTop }}>
          <div className="economic-plan-panel dossier-panel">
            <div className="header-panel">
              <div className="name-wrapper">
                <h4><i className={icoCurrencyContent[settings.currency]}/> {t(`${tKey}TITLE_PANEL`)}</h4>
              </div>
            </div>
            <Panel expanded={this.state.open} onToggle={() => {}}>
              <Panel.Collapse>
                <Row>
                  {this.state.mainFieldsConfiguration.amount &&
                  this.state.mainFieldsConfiguration.amount.permission > permissions.hidden && (
                    <Field
                      id="amount"
                      name="dossierPurchaseEconomicPlanComponent.amount"
                      colSm={4}
                      disabled={readOnlyFields || (settings.clientFieldValidation === clientInformation.VGR && isVN === false) || this.state.mainFieldsConfiguration.amount && this.state.mainFieldsConfiguration.amount.permission < permissions.editable}
                      type="text"
                      controlLabel={t(`${tKey}AMOUNT`)}
                      placeholder={t(`${tKey}AMOUNT`)}
                      component={InputMoney}
                    />
                  )}
                  {this.state.mainFieldsConfiguration.modelPriceCost &&
                  this.state.mainFieldsConfiguration.modelPriceCost.permission > permissions.hidden && (
                    <Field
                      id="modelPriceCost"
                      name="dossierPurchaseEconomicPlanComponent.modelPriceCost"
                      colSm={4}
                      disabled={readOnlyFields || this.state.mainFieldsConfiguration.modelPriceCost && this.state.mainFieldsConfiguration.modelPriceCost.permission < permissions.editable}
                      type="text"
                      controlLabel={t(`${tKey}MODEL_PRICE_AMOUNT`)}
                      placeholder={t(`${tKey}MODEL_PRICE_AMOUNT`)}
                      component={InputMoney}
                    />
                  )}
                  {this.state.mainFieldsConfiguration.optionalsAmount &&
                  this.state.mainFieldsConfiguration.optionalsAmount.permission > permissions.hidden && (
                    <Field
                      id="optionalsAmount"
                      name="dossierPurchaseEconomicPlanComponent.optionalsAmount"
                      colSm={4}
                      disabled={readOnlyFields || this.state.mainFieldsConfiguration.optionalsAmount && this.state.mainFieldsConfiguration.optionalsAmount.permission < permissions.editable}
                      type="text"
                      controlLabel={t(`${tKey}OPTIONALS_AMOUNT`)}
                      placeholder={t(`${tKey}OPTIONALS_AMOUNT`)}
                      component={InputMoney}
                    />
                  )}
                  {this.state.mainFieldsConfiguration.originalPrice &&
                  this.state.mainFieldsConfiguration.originalPrice.permission > permissions.hidden && (
                    <Field
                      id="originalPrice"
                      name="dossierPurchaseEconomicPlanComponent.originalPrice"
                      colSm={4}
                      disabled={readOnlyFields || this.state.mainFieldsConfiguration.originalPrice && this.state.mainFieldsConfiguration.originalPrice.permission < permissions.editable}
                      type="text"
                      controlLabel={t('DOSSIER_COMPONENTS.ECONOMIC_PLAN.POPOVER_PRICE_COST.ORIGINAL_PRICE')}
                      placeholder={t('DOSSIER_COMPONENTS.ECONOMIC_PLAN.POPOVER_PRICE_COST.ORIGINAL_PRICE')}
                      component={InputMoney}
                      onChange={(e, value) => this.calcAmount(e, value)}
                    />)}
                  {this.state.mainFieldsConfiguration.amortization &&
                  this.state.mainFieldsConfiguration.amortization.permission > permissions.hidden && isVN === false && (
                    <Field
                      id="amortization"
                      name="dossierPurchaseEconomicPlanComponent.amortization"
                      colSm={4}
                      disabled={readOnlyFields || this.state.mainFieldsConfiguration.amortization && this.state.mainFieldsConfiguration.amortization.permission < permissions.editable}
                      type="text"
                      controlLabel={t('DOSSIER_COMPONENTS.ECONOMIC_PLAN.POPOVER_PRICE_COST.AMORTIZATION')}
                      placeholder={t('DOSSIER_COMPONENTS.ECONOMIC_PLAN.POPOVER_PRICE_COST.AMORTIZATION')}
                      component={InputMoney}
                      onChange={(e, value) => this.calcAmount(e, value)}
                    />)}
                  {this.state.mainFieldsConfiguration.reconditioningField &&
                  this.state.mainFieldsConfiguration.reconditioningField.permission > permissions.hidden && isVN === false && (
                    <Field
                      id="reconditioning"
                      name="dossierPurchaseEconomicPlanComponent.reconditioning"
                      colSm={4}
                      disabled={readOnlyFields || this.state.mainFieldsConfiguration.reconditioningField && this.state.mainFieldsConfiguration.reconditioningField.permission < permissions.editable}
                      type="text"
                      controlLabel={t('DOSSIER_COMPONENTS.ECONOMIC_PLAN.POPOVER_PRICE_COST.RECONDITIONING')}
                      placeholder={t('DOSSIER_COMPONENTS.ECONOMIC_PLAN.POPOVER_PRICE_COST.RECONDITIONING')}
                      component={InputMoney}
                      onChange={(e, value) => this.calcAmount(e, value)}
                    />)}
                  {this.state.mainFieldsConfiguration.expirationDate &&
                  this.state.mainFieldsConfiguration.expirationDate.permission > permissions.hidden && (
                    <Field
                      id="expirationDate"
                      name="dossierPurchaseEconomicPlanComponent.expirationDate"
                      colSm={4}
                      disabled={true}
                      controlLabel={t(`${tKey}EXPIRATION_DATE`)}
                      component={InputDatePicker}
                      validate={(value, allValues, props) => {
                        const result = validateDate(value, props)
                        return result === Object(result) ? undefined : result
                      }}
                    />)}
                  {this.state.mainFieldsConfiguration.billingNumber &&
                  this.state.mainFieldsConfiguration.billingNumber.permission > permissions.hidden && (
                    <Field
                      id="billingNumber"
                      name="dossierPurchaseEconomicPlanComponent.billingNumber"
                      colSm={4}
                      disabled={readOnlyFields || this.state.mainFieldsConfiguration.billingNumber && this.state.mainFieldsConfiguration.billingNumber.permission < permissions.editable}
                      controlLabel={t(`${tKey}BILLING_NUMBER`)}
                      placeholder={t(`${tKey}BILLING_NUMBER`)}
                      component={InputText}
                    />)}
                  {this.state.mainFieldsConfiguration.billingDate &&
                  this.state.mainFieldsConfiguration.billingDate.permission > permissions.hidden && (
                    <Field
                      id="billingDate"
                      name="dossierPurchaseEconomicPlanComponent.billingDate"
                      colSm={4}
                      disabled={readOnlyFields || this.state.mainFieldsConfiguration.billingDate && this.state.mainFieldsConfiguration.billingDate.permission < permissions.editable}
                      controlLabel={t(`${tKey}BILLING_DATE`)}
                      component={InputDatePicker}
                      validate={(value, allValues, props) => {
                        const result = validateDate(value, props)
                        return result === Object(result) ? undefined : result
                      }}
                    />)}
                  {this.state.componentsConfiguration.reaconditioning &&
                  this.state.componentsConfiguration.reaconditioning.permission > permissions.hidden && isVN === false && (
                    <React.Fragment>
                      {this.state.reaconditioningFieldsConfiguration.amountTotal &&
                        this.state.reaconditioningFieldsConfiguration.amountTotal.permission > permissions.hidden && (
                        <Field
                          id="amountTotal"
                          name="dossierPurchaseEconomicPlanComponent.amountTotal"
                          colSm={4}
                          disabled={true}
                          type="text"
                          controlLabel={t('DOSSIER_COMPONENTS.ECONOMIC_PLAN.TOTALS') + ' ' + t('DOSSIER_COMPONENTS.REACONDITIONING.COST')}
                          placeholder={t(`${tKey}AMOUNT`)}
                          component={InputMoney}
                        />)}
                      {this.state.reaconditioningFieldsConfiguration.presents &&
                        this.state.reaconditioningFieldsConfiguration.presents.permission > permissions.hidden && (
                        <EconomicPlanGiftsPresents
                          id="purchasePresents"
                          name="dossierPurchaseEconomicPlanComponent.purchasePresents"
                          t={t}
                          dossierEconomicPlan={dossierEconomicPlan}
                          readOnlyFields={readOnlyFields ||
                            this.state.reaconditioningFieldsConfiguration.presents.permission < permissions.editable}
                          combos = {combos}
                          updatePurchasePresent = {this.props.updatePurchasePresent}
                          deletePurchasePresent= {this.props.deletePurchasePresent}
                          recoverPurchasePresent = {this.props.recoverPurchasePresent}
                          createPurchasePresent = {this.props.createPurchasePresent}
                          totalPresent = {totalPresent}
                          dossierPurchaseComponent = {dossierPurchaseComponent}
                          fieldsConfiguration = {this.state.fieldsConfiguration}
                          openDocumentEditionModal={this.props.openDocumentEditionModal}
                          pristine={this.props.pristine}
                          dossierType={dossierType}
                          openDocWarning ={this.props.openDocWarning}
                        />)}
                    </React.Fragment>)}
                </Row>
                <Row>
                </Row>
              </Panel.Collapse>
            </Panel>
          </div>
        </Col>
      </Row>
    )
  }
}
export default PurchaseEconomicPlan
