import { put, call, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { getAuth } from '../../selectors/access/auth'
import powerbiActionTypes from '../../constants/actions/reports/powerbiReport'
import { fetchPowerbiConfigSuccess } from '../../actions/reports/powerbiReport'
import { showLoader, hideLoader } from '../../actions/common.js'
import getPowerBiConfig from '../../services/reports/getPowerbiConfig'
import refreshSession from '../../services/user/refreshSession'
import { handleError } from '../errors/errorManager'

export function * fetchPowerbiConfig () {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const config = yield call(getPowerBiConfig, auth.token)
    yield put(fetchPowerbiConfigSuccess(config))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchPowerbiConfig () {
  yield takeEvery(powerbiActionTypes.FETCH_POWERBI_CONFIG, fetchPowerbiConfig)
}

export function * powerBiRefreshSession () {
  try {
    const auth = yield select(getAuth)
    yield call(refreshSession, auth.token)
  } catch (error) {
    yield call(handleError, { error })
  }
}

export function * watchPowerBiRefreshSession () {
  yield takeLatest(powerbiActionTypes.POWERBI_REFRESH_SESSION, powerBiRefreshSession)
}
