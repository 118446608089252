import React, { PureComponent } from 'react'
import Select from 'react-select'
// import 'react-select/dist/react-select.css'
import { Col, FormGroup, ControlLabel } from 'react-bootstrap'

class SimpleInputSelect extends PureComponent {
  render () {
    const {
      colSm = 3, labelKey, valueKey, options, onInputChange, error = '', value, id,
      placeholder, disabled = false, controlLabel, hasError, menuPlacement
    } = this.props
    return (
      <Col sm={colSm}>
        <FormGroup
          className="forms-with-label"
          validationState={error ? 'error' : null}
        >
          {controlLabel && <ControlLabel>{controlLabel}</ControlLabel>}
          <Select
            id={id}
            isDisabled={disabled}
            placeholder={placeholder}
            options={options}
            getOptionLabel={(value) => value[labelKey]}
            getOptionValue={(id) => id[valueKey]}
            value={options && options.filter(option => option[valueKey] === value)}
            onChange={(value) => onInputChange(value ? value[valueKey] : value)}
            className={hasError ? 'has-error' : ''}
            menuPlacement = {menuPlacement}
          />
          {error && <span className='help-block text-center'>{error}</span>}
        </FormGroup>
      </Col>
    )
  }
}

export default SimpleInputSelect
