import { call, put, takeEvery, select } from 'redux-saga/effects'
import { change } from 'redux-form'
import vehicleActionsTypes from '../../../../constants/actions/dossier/common/vehicle'
import { showLoader, hideLoader } from '../../../../actions/common'
import { getAuth } from '../../../../selectors/access/auth'
import { handleError } from '../../../errors/errorManager'
import { dossierSubTypeId as dossierSubTypeIdConstants } from '../../../../constants/dossier/common/dossierSubType'
import getVehicleOriginCombo from '../../../../services/vehicle/getVehicleOriginCombo'
import { fetchCombosSuccess } from '../../../../actions/combos/combos'

export function * fetchComboOrigin ({ comercialCode, dossierSubType }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    var combo = null
    if (comercialCode.length >= 8) {
      var modifiedCode = comercialCode.substring(4, 8)
      combo = yield call(getVehicleOriginCombo, auth.token, modifiedCode)
    }
    yield put(fetchCombosSuccess({ vehicleOriginCombo: combo }))
    if (combo && combo.length === 1 && dossierSubType === dossierSubTypeIdConstants.venta_vn) {
      yield put(change('salesDossier', 'vehicleComponent.originId', combo[0].id))
    }
  } catch (error) {
    const sessionHandler = function * () {}
    yield call(handleError, { error, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchComboOrigin () {
  yield takeEvery(vehicleActionsTypes.FETCH_COMBO_ORIGIN, fetchComboOrigin)
}
