import React, { PureComponent } from 'react'
import { reduxForm, Field } from 'redux-form'
import { Row, Col, Form, Button } from 'react-bootstrap'
import InputText from '../commons/form/InputText'
import FaqTable from '../../containers/faq/FaqTablePage'
import FaqSections from '../../containers/faq/FaqSectionsPage'
import FaqModal from '../../containers/faq/FaqModalPage'

class Faq extends PureComponent {
  constructor () {
    super()
    this.state = {
      isSearching: false,
      showModal: false,
      questionId: null,
      sectionId: null,
      sectionTitle: null,
      search: null
    }
  }

  openOrCloseModal (flag) {
    this.setState({ showModal: flag })
  }

  openOrCloseTable (flag) {
    this.setState({ isSearching: flag })
  }

  openQuestion (questionId) {
    this.setState({ questionId: questionId })
    this.openOrCloseModal(true)
  }

  openSection (sectionId, sectionTitle) {
    this.setState({ sectionId: sectionId, sectionTitle: sectionTitle })
    this.openOrCloseTable(true)
  }

  handleSubmit () {
    if (this.props && this.props.formValues && this.props.formValues.filters && this.props.formValues.filters.search) {
      this.setState({ search: this.props.formValues.filters.search })
      this.openOrCloseTable(true)
    }
  }

  goBack () {
    if (this.state.isSearching) {
      this.setState({ search: null, questionId: null, sectionId: null, isSearching: false })
      this.openOrCloseModal(false)
    }
  }

  render () {
    const {
      t
    } = this.props

    const tKey = 'FAQ.'
    return (
      <div className="search-wrapper templates-wrapper">
        <FaqModal
          t={t}
          showModal={this.state.showModal}
          questionId={this.state.questionId}
          closeModal={() => this.openOrCloseModal(false)}
        />
        <div className="search-header">
          <div className="title-user">
            <i className="glyphicon glyphicon-question-sign"/>
            <h2>
              {t(`${tKey}TITLE`)}
            </h2>
          </div>
        </div>
        <div className="filters-faq-wrapper">
          <Form autoComplete="off" className="filters" onSubmit={() => this.handleSubmit()}>
            <Row>
              {
                !this.state.isSearching ? (
                  <div className="search-form">
                    <span className="title-search">
                      {t(`${tKey}SEARCH.TITLE`)}
                    </span>
                    <Row>
                      <Field
                        id="search"
                        name="filters.search"
                        colSm={10}
                        placeholder={t(`${tKey}SEARCH.SEARCH`)}
                        component={InputText}
                      />
                      <Col sm={2} className="btn-wrapper">
                        <Button
                          className={'btn-white btn-icon btn-white search-button'}
                          style={{ width: '100%' }}
                          type="submit"
                        >
                          <i className="ico-search" />
                          {t(`${tKey}SEARCH.SEARCH_BTN`)}
                        </Button>
                      </Col>
                    </Row>
                  </div>
                ) : (
                  <div className="go-back">
                    <Button className="btn-grey-little btn-back-dashboard" onClick={() => this.goBack()}>
                      <i className="ico-arrow-right"/>
                    </Button>
                    <div className="go-back-text" onClick={() => this.goBack()}>
                      {t(`${tKey}SEARCH.FAQ`)}
                    </div>
                    {
                      (this.state.search || this.state.sectionTitle) && [
                        <div key={1} className="go-back-search-text">
                          {'->'}
                        </div>,
                        <div key={2} className="go-back-search-text">
                          {this.state.search ? this.state.search : this.state.sectionTitle}
                        </div>
                      ]
                    }
                  </div>
                )
              }
            </Row>
          </Form>
        </div>
        {
          this.state && this.state.isSearching ? (
            <FaqTable
              t={t}
              sectionId={this.state.sectionId}
              openQuestion={(questionId) => this.openQuestion(questionId)}
              search={this.state.search}
            />
          ) : this.state && !this.state.isSearching ? (
            <FaqSections
              t={t}
              openQuestion={(questionId) => this.openQuestion(questionId)}
              openSection={(sectionId, sectionTitle) => this.openSection(sectionId, sectionTitle)}
            />
          ) : null
        }
      </div>
    )
  }
}

export default reduxForm({
  form: 'faq'
})(Faq)
