import { call, put, takeEvery, select } from 'redux-saga/effects'
import { change, reset } from 'redux-form'
import dossierActionsTypes from '../../../../constants/actions/dossier/dossier'
import clientActionsTypes from '../../../../constants/actions/dossier/common/client'
import { showLoader, hideLoader } from '../../../../actions/common'
import messageModalActions from '../../../../actions/modals/messageModal'
import { getAuth } from '../../../../selectors/access/auth'
import getEntityByDni from '../../../../services/entity/getEntityByDni'
import getEntityByRfc from '../../../../services/entity/getEntityByRfc'
import { fetchSalesDossierSuccess } from '../../../../actions/dossier/sales'
import { fetchPaperworkDossierSuccess } from '../../../../actions/dossier/paperwork'
import { fetchPurchaseDossierSuccess } from '../../../../actions/dossier/purchase'
import { fetchCampaignDossierSuccess } from '../../../../actions/dossier/campaign'
import { dossierTypes as dossierTypesConstant } from '.././../../../constants/dossier/common/dossierTypes'
import { handleError } from '../../../errors/errorManager'
import { yesNoModal } from '../../../modalsListeners/yesNoModal'
import { entityTypesId, genericRfc } from '../../../../constants/backendIds'
import { fetchEntitySubTypeCombo } from '../../../../sagas/combos/fetchEntitySubTypeCombo'
import { fetchEntityFilterSubTypeCombo } from '../../../../sagas/combos/fetchEntityFilterSubTypeCombo'
import { fetchRegistrationDeliveryDossierSuccess } from '../../../../actions/dossier/registrationDelivery'
import { fetchEntityFleetSucces, fetchEntityChildFleetSucces } from '../../../../actions/management/management'
import { fetchEntityOperateFleetSucces, fetchEntityOperateChildFleetSucces } from '../../../../actions/management/auxModals/auxModals'
import getEntityStateTypeCombo from '../../../../services/dossier/get/getEntityStateTypeCombo'
import getEntityCountryTypeCombo from '../../../../services/dossier/get/getEntityCountryTypeCombo'
import getEntityCityTypeCombo from '../../../../services/dossier/get/getEntityCityTypeCombo'
import getEntityTownTypeCombo from '../../../../services/dossier/get/getEntityTownTypeCombo'
import getEntityColonyTypeCombo from '../../../../services/dossier/get/getEntityColonyTypeCombo'
import getEntityCPTypeCombo from '../../../../services/dossier/get/getEntityCPTypeCombo'
import { fetchCombosSuccess } from '../../../../actions/combos/combos'

function checkBproEntity (entity, previousEntity) {
  if (entity.bproCharged && (entity.entityTypeId === null || entity.entityTypeId === undefined) && previousEntity.entityTypeId) {
    entity.entityTypeId = previousEntity.entityTypeId
  }
  return entity
}

export function * fetchClientByDni ({ dni, ouId, dossierType, resolve, reject }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    var entity = yield call(getEntityByDni, dni, ouId, auth.token)
    const recurrent = entity.entityTypeId === entityTypesId.recurrentEntity
    switch (dossierType) {
      case dossierTypesConstant.sales:
      { if (!recurrent) {
        const salesDossierEntity = yield select(state => state.salesDossier.entityComponent)

        entity = checkBproEntity(entity, salesDossierEntity)
        entity.updated = true
        yield put(fetchSalesDossierSuccess({ entityComponent: { ...entity, previousEntity: salesDossierEntity.previousEntity } }))
        yield put(change('salesDossier', 'entityComponent', entity))
      } else {
        yield put(hideLoader())
        const confirmed = yield call(yesNoModal, 'recurrentEntityFound')
        if (confirmed) {
          yield call(fetchEntitySubTypeCombo, { entityTypeId: entity.entityTypeId })
          yield call(fetchEntityFilterSubTypeCombo, { entityTypeId: entity.entityTypeId, entitySubTypeId: entity.entitySubTypeId, organizedUnitId: ouId })
          const salesDossierEntity = yield select(state => state.salesDossier.entityComponent)
          entity.updated = true
          yield put(fetchSalesDossierSuccess({ entityComponent: { ...entity, previousEntity: salesDossierEntity.previousEntity } }))
          yield put(change('salesDossier', 'entityComponent', entity))
        } else {
          let formEntitySale = yield select(state => state.form.salesDossier.values.entityComponent)
          formEntitySale.dniCif = ''
          yield put(change('salesDossier', 'entityComponent', formEntitySale))
        }
      }
      break }
      case dossierTypesConstant.paperwork:
      { if (!recurrent) {
        const paperworkDossierEntity = yield select(state => state.paperworkDossier.entityComponent)
        entity = checkBproEntity(entity, paperworkDossierEntity)
        entity.updated = true
        yield put(fetchPaperworkDossierSuccess({ entityComponent: { ...entity, previousEntity: paperworkDossierEntity.previousEntity } }))
        yield put(change('paperwork_dossier', 'entityComponent', entity))
      } else {
        yield put(hideLoader())
        const confirmed = yield call(yesNoModal, 'recurrentEntityFound')
        if (confirmed) {
          yield call(fetchEntitySubTypeCombo, { entityTypeId: entity.entityTypeId })
          yield call(fetchEntityFilterSubTypeCombo, { entityTypeId: entity.entityTypeId, entitySubTypeId: entity.entitySubTypeId, organizedUnitId: ouId })
          const paperworkDossierEntity = yield select(state => state.paperworkDossier.entityComponent)
          entity.updated = true
          yield put(fetchPaperworkDossierSuccess({ entityComponent: { ...entity, previousEntity: paperworkDossierEntity.previousEntity } }))
          yield put(change('paperwork_dossier', 'entityComponent', entity))
        } else {
          let formEntityPap = yield select(state => state.form.paperwork_dossier.values.entityComponent)
          formEntityPap.dniCif = ''
          yield put(change('paperwork_dossier', 'entityComponent', formEntityPap))
        }
      }
      break }
      case dossierTypesConstant.purchase:
      { if (!recurrent) {
        const purchaseDossierEntity = yield select(state => state.purchaseDossier.entityComponent)
        entity = checkBproEntity(entity, purchaseDossierEntity)
        entity.updated = true
        yield put(fetchPurchaseDossierSuccess({ entityComponent: { ...entity, previousEntity: purchaseDossierEntity.previousEntity } }))
        yield put(change('purchase_dossier', 'entityComponent', entity))
      } else {
        yield put(hideLoader())
        const confirmed = yield call(yesNoModal, 'recurrentEntityFound')
        if (confirmed) {
          yield call(fetchEntitySubTypeCombo, { entityTypeId: entity.entityTypeId })
          yield call(fetchEntityFilterSubTypeCombo, { entityTypeId: entity.entityTypeId, entitySubTypeId: entity.entitySubTypeId, organizedUnitId: ouId })
          const purchaseDossierEntity = yield select(state => state.purchaseDossier.entityComponent)
          entity.updated = true
          yield put(fetchPurchaseDossierSuccess({ entityComponent: { ...entity, previousEntity: purchaseDossierEntity.previousEntity } }))
          yield put(change('purchase_dossier', 'entityComponent', entity))
        } else {
          let formEntityPurc = yield select(state => state.form.purchase_dossier.values.entityComponent)
          formEntityPurc.dniCif = ''
          yield put(change('purchase_dossier', 'entityComponent', formEntityPurc))
        }
      }
      break }
      case dossierTypesConstant.campaign:
      { if (!recurrent) {
        const campaignDossierEntity = yield select(state => state.campaignDossier.entityComponent)
        entity = checkBproEntity(entity, campaignDossierEntity)
        entity.updated = true
        yield put(fetchCampaignDossierSuccess({ entityComponent: { ...entity, previousEntity: campaignDossierEntity.previousEntity } }))
        yield put(change('campaign_dossier', 'entityComponent', entity))
      } else {
        yield put(hideLoader())
        const confirmed = yield call(yesNoModal, 'recurrentEntityFound')
        if (confirmed) {
          yield call(fetchEntitySubTypeCombo, { entityTypeId: entity.entityTypeId })
          yield call(fetchEntityFilterSubTypeCombo, { entityTypeId: entity.entityTypeId, entitySubTypeId: entity.entitySubTypeId, organizedUnitId: ouId })
          const campaignDossierEntity = yield select(state => state.campaignDossier.entityComponent)
          entity.updated = true
          yield put(fetchCampaignDossierSuccess({ entityComponent: { ...entity, previousEntity: campaignDossierEntity.previousEntity } }))
          yield put(change('campaign_dossier', 'entityComponent', entity))
        } else {
          let formEntityCamp = yield select(state => state.form.campaign_dossier.values.entityComponent)
          formEntityCamp.dniCif = ''
          yield put(change('campaign_dossier', 'entityComponent', formEntityCamp))
        }
      }
      break }
      case dossierTypesConstant.registrationDelivery:
      { const registrationDeliveryDossierEntity = yield select(state => state.registrationDeliveryDossier.entityComponent)
        if (!recurrent) {
          entity = checkBproEntity(entity, registrationDeliveryDossierEntity)
          entity.updated = true
          yield put(fetchRegistrationDeliveryDossierSuccess({ entityComponent: { ...entity, previousEntity: registrationDeliveryDossierEntity.previousEntity } }))
          yield put(change('registrationDeliveryDossier', 'entityComponent', entity))
        } else {
          yield put(hideLoader())
          const confirmed = yield call(yesNoModal, 'recurrentEntityFound')
          if (confirmed) {
            yield call(fetchEntitySubTypeCombo, { entityTypeId: entity.entityTypeId })
            yield call(fetchEntityFilterSubTypeCombo, { entityTypeId: entity.entityTypeId, entitySubTypeId: entity.entitySubTypeId, organizedUnitId: ouId })
            entity.updated = true
            yield put(fetchRegistrationDeliveryDossierSuccess({ entityComponent: { ...entity, previousEntity: registrationDeliveryDossierEntity.previousEntity } }))
            yield put(change('registrationDeliveryDossier', 'entityComponent', entity))
          } else {
            let formEntity = yield select(state => state.form.registrationDeliveryDossier.values.entityComponent)
            formEntity.dniCif = ''
            yield put(change('registrationDeliveryDossier', 'entityComponent', formEntity))
          }
        }
        break }
      case dossierTypesConstant.addNewFleetModal:
      { if (!recurrent) {
        const salesDossierEntity = yield select(state => state.management.addNewFleetModal.entityComponent)
        entity = checkBproEntity(entity, salesDossierEntity)
        entity.updated = true
        yield put(fetchEntityFleetSucces({ entityComponent: { ...entity, previousEntity: salesDossierEntity.previousEntity } }))
        yield put(change('addNewFleetModal', 'entityComponent', entity))
      } else {
        yield put(hideLoader())
        const confirmed = yield call(yesNoModal, 'recurrentEntityFound')
        if (confirmed) {
          yield call(fetchEntitySubTypeCombo, { entityTypeId: entity.entityTypeId })
          yield call(fetchEntityFilterSubTypeCombo, { entityTypeId: entity.entityTypeId, entitySubTypeId: entity.entitySubTypeId, organizedUnitId: ouId })
          const salesDossierEntity = yield select(state => state.management.addNewFleetModal.entityComponent)
          entity.updated = true
          yield put(fetchEntityFleetSucces({ entityComponent: { ...entity, previousEntity: salesDossierEntity.previousEntity } }))
          yield put(change('addNewFleetModal', 'entityComponent', entity))
        } else {
          let formEntitySale = yield select(state => state.form.management.values.entityComponent)
          formEntitySale.dniCif = ''
          yield put(change('addNewFleetModal', 'entityComponent', formEntitySale))
        }
      }
      break }
      case dossierTypesConstant.createNewFleetModal:
      {
        if (!recurrent) {
          const salesDossierEntity = yield select(state => state.management.createNewFleetModal.entityComponent)
          entity = checkBproEntity(entity, salesDossierEntity)
          entity.updated = true
          yield put(fetchEntityOperateFleetSucces({
            entityComponent: { ...entity, previousEntity: salesDossierEntity.previousEntity }
          }))
          yield put(change('createNewFleetModal', 'entityComponent', entity))
        } else {
          yield put(hideLoader())
          const confirmed = yield call(yesNoModal, 'recurrentEntityFound')
          if (confirmed) {
            yield call(fetchEntitySubTypeCombo, { entityTypeId: entity.entityTypeId })
            yield call(fetchEntityFilterSubTypeCombo,
              {
                entityTypeId: entity.entityTypeId,
                entitySubTypeId: entity.entitySubTypeId,
                organizedUnitId: ouId
              })
            const salesDossierEntity = yield select(state => state.management.createNewFleetModal.entityComponent)
            entity.updated = true
            yield put(fetchEntityOperateFleetSucces({
              entityComponent: { ...entity, previousEntity: salesDossierEntity.previousEntity }
            }))
            yield put(change('createNewFleetModal', 'entityComponent', entity))
          } else {
            let formEntitySale = yield select(state => state.form.management.values.entityComponent)
            formEntitySale.dniCif = ''
            yield put(change('createNewFleetModal', 'entityComponent', formEntitySale))
          }
        }
        break
      }
      case dossierTypesConstant.dataContactFleetModal:
      {
        if (!recurrent) {
          const salesDossierEntity =
                  yield select(state => state.managementAuxModals.dataContactFleetModal.dossier.entityComponent)
          entity = checkBproEntity(entity, salesDossierEntity)
          entity.updated = true
          yield put(fetchEntityOperateFleetSucces({
            entityComponent: { ...entity, previousEntity: salesDossierEntity.previousEntity }
          }))
          yield put(change('dataContactFleetModal', 'entityComponent', entity))
        } else {
          yield put(hideLoader())
          const confirmed = yield call(yesNoModal, 'recurrentEntityFound')
          if (confirmed) {
            yield call(fetchEntitySubTypeCombo, { entityTypeId: entity.entityTypeId })
            yield call(fetchEntityFilterSubTypeCombo,
              {
                entityTypeId: entity.entityTypeId,
                entitySubTypeId: entity.entitySubTypeId,
                organizedUnitId: ouId
              })
            const salesDossierEntity =
                      yield select(state => state.managementAuxModals.dataContactFleetModal.dossier.entityComponent)
            entity.updated = true
            yield put(fetchEntityOperateFleetSucces({
              entityComponent: { ...entity, previousEntity: salesDossierEntity.previousEntity }
            }))
            yield put(change('dataContactFleetModal', 'entityComponent', entity))
          } else {
            let formEntitySale = yield select(state => state.form.createNewFleetModal.values.entityComponent)
            formEntitySale.dniCif = ''
            yield put(change('dataContactFleetModal', 'entityComponent', formEntitySale))
          }
        }
        break
      }
      case dossierTypesConstant.uacModal:
      {
        entity.updated = true
        yield put(change('uacModal', 'entityComponent', entity))
        break
      }
    }
    let entityCountryTypeCombo = []
    let entityStateTypeCombo = []
    let entityCityTypeCombo = []
    let entityTownTypeCombo = []
    let entityColonyTypeCombo = []
    let entityCPTypeCombo = []

    entityCountryTypeCombo = yield call(getEntityCountryTypeCombo, auth.token)
    if (entity.countryCode) {
      entityStateTypeCombo = yield call(getEntityStateTypeCombo, auth.token, entityCountryTypeCombo?.find(x => x.code === entity.countryCode)?.id)
    }

    if (entity.stateCode) {
      entityCityTypeCombo = yield call(getEntityCityTypeCombo, auth.token, entityStateTypeCombo?.find(x => x.code === entity.stateCode)?.id)
    }

    if (entity.cityCode) {
      entityTownTypeCombo = yield call(getEntityTownTypeCombo, auth.token, entityCityTypeCombo?.find(x => x.code === entity.cityCode)?.id)
    }

    if (entity.townCode) {
      entityColonyTypeCombo = yield call(getEntityColonyTypeCombo, auth.token, entityTownTypeCombo?.find(x => x.code === entity.townCode)?.id)
    }

    if (entity.colonyCode) {
      entityCPTypeCombo = yield call(getEntityCPTypeCombo, auth.token, entityColonyTypeCombo?.find(x => x.code === entity.colonyCode)?.id)
    }
    yield put(fetchCombosSuccess({ entityCountryTypeCombo, entityStateTypeCombo, entityCityTypeCombo, entityTownTypeCombo, entityColonyTypeCombo, entityCPTypeCombo }))

    if (resolve) {
      yield call(resolve, entity)
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag !== 'entityNotFound' && customHandlerError.json.tag !== 'TRANS/entityNotFound')) {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      } else if (reject) {
        yield call(reject, customHandlerError)
      }
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('purchase_dossier'))
      yield put(reset('paperwork_dossier'))
      yield put(reset('campaign_dossier'))
      yield put(reset('registrationDeliveryDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchClientByDni () {
  yield takeEvery(dossierActionsTypes.FETCH_CLIENT_DNI, fetchClientByDni)
}

export function * fetchClientByRfc ({ rfc, ouId, dossierType, resolve, reject }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    if (rfc && rfc !== genericRfc.privateRfc) {
      var entity = yield call(getEntityByRfc, rfc, ouId, auth.token)
      if (resolve) {
        yield call(resolve, entity)
      }
      const recurrent = entity.entityTypeId === entityTypesId.recurrentEntity
      switch (dossierType) {
        case dossierTypesConstant.sales:
        {
          if (!recurrent) {
            const salesDossierEntity = yield select(state => state.salesDossier.entityComponent)
            entity = checkBproEntity(entity, salesDossierEntity)
            entity.updated = true
            yield put(fetchSalesDossierSuccess({
              entityComponent: { ...entity, previousEntity: salesDossierEntity.previousEntity }
            }))
            yield put(change('salesDossier', 'entityComponent', entity))
          } else {
            yield put(hideLoader())
            const confirmed = yield call(yesNoModal, 'recurrentEntityFound')
            if (confirmed) {
              yield call(fetchEntitySubTypeCombo, { entityTypeId: entity.entityTypeId })
              yield call(fetchEntityFilterSubTypeCombo,
                {
                  entityTypeId: entity.entityTypeId,
                  entitySubTypeId: entity.entitySubTypeId,
                  organizedUnitId: ouId
                })
              const salesDossierEntity = yield select(state => state.salesDossier.entityComponent)
              entity.updated = true
              yield put(fetchSalesDossierSuccess({
                entityComponent: { ...entity, previousEntity: salesDossierEntity.previousEntity }
              }))
              yield put(change('salesDossier', 'entityComponent', entity))
            } else {
              let formEntitySale = yield select(state => state.form.salesDossier.values.entityComponent)
              formEntitySale.dniCif = ''
              yield put(change('salesDossier', 'entityComponent', formEntitySale))
            }
          }
          break
        }
        case dossierTypesConstant.paperwork:
        {
          if (!recurrent) {
            const paperworkDossierEntity = yield select(state => state.paperworkDossier.entityComponent)
            entity = checkBproEntity(entity, paperworkDossierEntity)
            entity.updated = true
            yield put(fetchPaperworkDossierSuccess({
              entityComponent: { ...entity, previousEntity: paperworkDossierEntity.previousEntity }
            }))
            yield put(change('paperwork_dossier', 'entityComponent', entity))
          } else {
            yield put(hideLoader())
            const confirmed = yield call(yesNoModal, 'recurrentEntityFound')
            if (confirmed) {
              yield call(fetchEntitySubTypeCombo, { entityTypeId: entity.entityTypeId })
              yield call(fetchEntityFilterSubTypeCombo,
                {
                  entityTypeId: entity.entityTypeId,
                  entitySubTypeId: entity.entitySubTypeId,
                  organizedUnitId: ouId
                })
              const paperworkDossierEntity = yield select(state => state.paperworkDossier.entityComponent)
              entity.updated = true
              yield put(fetchPaperworkDossierSuccess({
                entityComponent: { ...entity, previousEntity: paperworkDossierEntity.previousEntity }
              }))
              yield put(change('paperwork_dossier', 'entityComponent', entity))
            } else {
              let formEntityPap = yield select(state => state.form.paperwork_dossier.values.entityComponent)
              formEntityPap.dniCif = ''
              yield put(change('paperwork_dossier', 'entityComponent', formEntityPap))
            }
          }
          break
        }
        case dossierTypesConstant.purchase:
        {
          if (!recurrent) {
            const purchaseDossierEntity = yield select(state => state.purchaseDossier.entityComponent)
            entity = checkBproEntity(entity, purchaseDossierEntity)
            entity.updated = true
            yield put(fetchPurchaseDossierSuccess({
              entityComponent: { ...entity, previousEntity: purchaseDossierEntity.previousEntity }
            }))
            yield put(change('purchase_dossier', 'entityComponent', entity))
          } else {
            yield put(hideLoader())
            const confirmed = yield call(yesNoModal, 'recurrentEntityFound')
            if (confirmed) {
              yield call(fetchEntitySubTypeCombo, { entityTypeId: entity.entityTypeId })
              yield call(fetchEntityFilterSubTypeCombo,
                {
                  entityTypeId: entity.entityTypeId,
                  entitySubTypeId: entity.entitySubTypeId,
                  organizedUnitId: ouId
                })
              const purchaseDossierEntity = yield select(state => state.purchaseDossier.entityComponent)
              entity.updated = true
              yield put(fetchPurchaseDossierSuccess({
                entityComponent: { ...entity, previousEntity: purchaseDossierEntity.previousEntity }
              }))
              yield put(change('purchase_dossier', 'entityComponent', entity))
            } else {
              let formEntityPurc = yield select(state => state.form.purchase_dossier.values.entityComponent)
              formEntityPurc.dniCif = ''
              yield put(change('purchase_dossier', 'entityComponent', formEntityPurc))
            }
          }
          break
        }
        case dossierTypesConstant.campaign:
        {
          if (!recurrent) {
            const campaignDossierEntity = yield select(state => state.campaignDossier.entityComponent)
            entity = checkBproEntity(entity, campaignDossierEntity)
            entity.updated = true
            yield put(fetchCampaignDossierSuccess({
              entityComponent: { ...entity, previousEntity: campaignDossierEntity.previousEntity }
            }))
            yield put(change('campaign_dossier', 'entityComponent', entity))
          } else {
            yield put(hideLoader())
            const confirmed = yield call(yesNoModal, 'recurrentEntityFound')
            if (confirmed) {
              yield call(fetchEntitySubTypeCombo, { entityTypeId: entity.entityTypeId })
              yield call(fetchEntityFilterSubTypeCombo,
                {
                  entityTypeId: entity.entityTypeId,
                  entitySubTypeId: entity.entitySubTypeId,
                  organizedUnitId: ouId
                })
              const campaignDossierEntity = yield select(state => state.campaignDossier.entityComponent)
              entity.updated = true
              yield put(fetchCampaignDossierSuccess({
                entityComponent: { ...entity, previousEntity: campaignDossierEntity.previousEntity }
              }))
              yield put(change('campaign_dossier', 'entityComponent', entity))
            } else {
              let formEntityCamp = yield select(state => state.form.campaign_dossier.values.entityComponent)
              formEntityCamp.dniCif = ''
              yield put(change('campaign_dossier', 'entityComponent', formEntityCamp))
            }
          }
          break
        }
        case dossierTypesConstant.registrationDelivery:
        {
          const registrationDeliveryDossierEntity =
              yield select(state => state.registrationDeliveryDossier.entityComponent)
          if (!recurrent) {
            entity = checkBproEntity(entity, registrationDeliveryDossierEntity)
            entity.updated = true
            yield put(fetchRegistrationDeliveryDossierSuccess({
              entityComponent: { ...entity, previousEntity: registrationDeliveryDossierEntity.previousEntity }
            }))
            yield put(change('registrationDeliveryDossier', 'entityComponent', entity))
          } else {
            yield put(hideLoader())
            const confirmed = yield call(yesNoModal, 'recurrentEntityFound')
            if (confirmed) {
              yield call(fetchEntitySubTypeCombo, { entityTypeId: entity.entityTypeId })
              yield call(fetchEntityFilterSubTypeCombo,
                {
                  entityTypeId: entity.entityTypeId,
                  entitySubTypeId: entity.entitySubTypeId,
                  organizedUnitId: ouId
                })
              entity.updated = true
              yield put(fetchRegistrationDeliveryDossierSuccess({
                entityComponent: { ...entity, previousEntity: registrationDeliveryDossierEntity.previousEntity }
              }))
              yield put(change('registrationDeliveryDossier', 'entityComponent', entity))
            } else {
              let formEntity = yield select(state => state.form.registrationDeliveryDossier.values.entityComponent)
              formEntity.dniCif = ''
              yield put(change('registrationDeliveryDossier', 'entityComponent', formEntity))
            }
          }
          break }
        case dossierTypesConstant.addNewFleetModal:
        {
          if (!recurrent) {
            const salesDossierEntity = yield select(state => state.management.addNewFleetModal.entityComponent)
            entity = checkBproEntity(entity, salesDossierEntity)
            entity.updated = true
            yield put(fetchEntityFleetSucces({ entityComponent: { ...entity, previousEntity: salesDossierEntity.previousEntity } }))
            yield put(change('addNewFleetModal', 'entityComponent', entity))
          } else {
            yield put(hideLoader())
            const confirmed = yield call(yesNoModal, 'recurrentEntityFound')
            if (confirmed) {
              yield call(fetchEntitySubTypeCombo, { entityTypeId: entity.entityTypeId })
              yield call(fetchEntityFilterSubTypeCombo, { entityTypeId: entity.entityTypeId, entitySubTypeId: entity.entitySubTypeId, organizedUnitId: ouId })
              const salesDossierEntity = yield select(state => state.management.addNewFleetModal.entityComponent)
              entity.updated = true
              yield put(fetchEntityFleetSucces({ entityComponent: { ...entity, previousEntity: salesDossierEntity.previousEntity } }))
              yield put(change('addNewFleetModal', 'entityComponent', entity))
            } else {
              let formEntitySale = yield select(state => state.form.management.values.entityComponent)
              formEntitySale.dniCif = ''
              yield put(change('addNewFleetModal', 'entityComponent', formEntitySale))
            }
          }
          break }
        case dossierTypesConstant.createNewFleetModal:
        { if (!recurrent) {
          const salesDossierEntity = yield select(state => state.management.createNewFleetModal.entityComponent)
          entity = checkBproEntity(entity, salesDossierEntity)
          entity.updated = true
          yield put(fetchEntityOperateFleetSucces({ entityComponent: { ...entity, previousEntity: salesDossierEntity.previousEntity } }))
          yield put(change('createNewFleetModal', 'entityComponent', entity))
        } else {
          yield put(hideLoader())
          const confirmed = yield call(yesNoModal, 'recurrentEntityFound')
          if (confirmed) {
            yield call(fetchEntitySubTypeCombo, { entityTypeId: entity.entityTypeId })
            yield call(fetchEntityFilterSubTypeCombo, { entityTypeId: entity.entityTypeId, entitySubTypeId: entity.entitySubTypeId, organizedUnitId: ouId })
            const salesDossierEntity = yield select(state => state.management.createNewFleetModal.entityComponent)
            entity.updated = true
            yield put(fetchEntityFleetSucces({ entityComponent: { ...entity, previousEntity: salesDossierEntity.previousEntity } }))
            yield put(change('createNewFleetModal', 'entityComponent', entity))
          } else {
            let formEntitySale = yield select(state => state.form.management.values.entityComponent)
            formEntitySale.dniCif = ''
            yield put(change('createNewFleetModal', 'entityComponent', formEntitySale))
          }
        }
        break }

        case dossierTypesConstant.dataContactFleetModal:
        {
          if (!recurrent) {
            const salesDossierEntity =
                  yield select(state => state.managementAuxModals.dataContactFleetModal.dossier.entityComponent)
            entity = checkBproEntity(entity, salesDossierEntity)
            entity.updated = true
            yield put(fetchEntityOperateFleetSucces({
              entityComponent: { ...entity, previousEntity: salesDossierEntity.previousEntity }
            }))
            yield put(change('dataContactFleetModal', 'entityComponent', entity))
          } else {
            yield put(hideLoader())
            const confirmed = yield call(yesNoModal, 'recurrentEntityFound')
            if (confirmed) {
              yield call(fetchEntitySubTypeCombo, { entityTypeId: entity.entityTypeId })
              yield call(fetchEntityFilterSubTypeCombo,
                {
                  entityTypeId: entity.entityTypeId,
                  entitySubTypeId: entity.entitySubTypeId,
                  organizedUnitId: ouId
                })
              const salesDossierEntity =
                      yield select(state => state.managementAuxModals.dataContactFleetModal.dossier.entityComponent)
              entity.updated = true
              yield put(fetchEntityOperateFleetSucces({
                entityComponent: { ...entity, previousEntity: salesDossierEntity.previousEntity }
              }))
              yield put(change('dataContactFleetModal', 'entityComponent', entity))
            } else {
              let formEntitySale = yield select(state => state.form.createNewFleetModal.values.entityComponent)
              formEntitySale.dniCif = ''
              yield put(change('dataContactFleetModal', 'entityComponent', formEntitySale))
            }
          }
          break
        }
        default:
          break
      }
    } else {
      if (resolve) {
        yield call(resolve, null)
      }
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag !== 'entityNotFound' && customHandlerError.json.tag !== 'TRANS/entityNotFound')) {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      } else if (reject) {
        yield call(reject, customHandlerError)
      }
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('purchase_dossier'))
      yield put(reset('paperwork_dossier'))
      yield put(reset('campaign_dossier'))
      yield put(reset('registrationDeliveryDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchClientByRfc () {
  yield takeEvery(clientActionsTypes.FETCH_CLIENT_RFC, fetchClientByRfc)
}

export function * fetchClientByDniEntityChild ({ dni, ouId, dossierType, resolve, reject }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    var entity = yield call(getEntityByDni, dni, ouId, auth.token)
    const recurrent = entity.entityTypeId === entityTypesId.recurrentEntity
    if (resolve) {
      yield call(resolve, entity)
    }
    switch (dossierType) {
      case dossierTypesConstant.sales:
        if (!recurrent) {
          const salesDossierEntity = yield select(state => state.salesDossier.entityChildComponent)
          entity = checkBproEntity(entity, salesDossierEntity)
          yield put(fetchSalesDossierSuccess({ entityChildComponent: { ...entity, previousEntity: salesDossierEntity.previousEntity } }))
          yield put(change('salesDossier', 'entityChildComponent', entity))
        } else {
          let formEntitySale = yield select(state => state.form.salesDossier.values.entityChildComponent)
          formEntitySale.dniCif = ''
          yield put(change('salesDossier', 'entityChildComponent', formEntitySale))
          yield put(messageModalActions.openErrorMessageModal('', 'recurrentEntityAssigned'))
        }
        break
      case dossierTypesConstant.paperwork:
        if (!recurrent) {
          const paperworkDossierEntity = yield select(state => state.paperworkDossier.entityChildComponent)
          entity = checkBproEntity(entity, paperworkDossierEntity)
          yield put(fetchPaperworkDossierSuccess({ entityChildComponent: { ...entity, previousEntity: paperworkDossierEntity.previousEntity } }))
          yield put(change('paperwork_dossier', 'entityChildComponent', entity))
        } else {
          let formEntityPap = yield select(state => state.form.paperwork_dossier.values.entityChildComponent)
          formEntityPap.dniCif = ''
          yield put(change('paperwork_dossier', 'entityChildComponent', formEntityPap))
          yield put(messageModalActions.openErrorMessageModal('', 'recurrentEntityAssigned'))
        }
        break
      case dossierTypesConstant.purchase:
        if (!recurrent) {
          const purchaseDossierEntity = yield select(state => state.purchaseDossier.entityChildComponent)
          entity = checkBproEntity(entity, purchaseDossierEntity)
          yield put(fetchPurchaseDossierSuccess({ entityChildComponent: { ...entity, previousEntity: purchaseDossierEntity.previousEntity } }))
          yield put(change('purchase_dossier', 'entityChildComponent', entity))
        } else {
          let formEntityPurc = yield select(state => state.form.purchase_dossier.values.entityChildComponent)
          formEntityPurc.dniCif = ''
          yield put(change('purchase_dossier', 'entityChildComponent', formEntityPurc))
          yield put(messageModalActions.openErrorMessageModal('', 'recurrentEntityAssigned'))
        }
        break
      case dossierTypesConstant.campaign:
        if (!recurrent) {
          const campaignDossierEntity = yield select(state => state.campaignDossier.entityChildComponent)
          entity = checkBproEntity(entity, campaignDossierEntity)
          yield put(fetchCampaignDossierSuccess({ entityChildComponent: { ...entity, previousEntity: campaignDossierEntity.previousEntity } }))
          yield put(change('campaign_dossier', 'entityChildComponent', entity))
        } else {
          let formEntityCamp = yield select(state => state.form.campaign_dossier.values.entityChildComponent)
          formEntityCamp.dniCif = ''
          yield put(change('campaign_dossier', 'entityChildComponent', formEntityCamp))
          yield put(messageModalActions.openErrorMessageModal('', 'recurrentEntityAssigned'))
        }
        break
      case dossierTypesConstant.registrationDelivery:
        if (!recurrent) {
          const registrationDeliveryDossierEntity = yield select(state => state.registrationDeliveryDossier.entityChildComponent)
          entity = checkBproEntity(entity, registrationDeliveryDossierEntity)
          yield put(fetchRegistrationDeliveryDossierSuccess({ entityChildComponent: { ...entity, previousEntity: registrationDeliveryDossierEntity.previousEntity } }))
          yield put(change('registrationDeliveryDossier', 'entityChildComponent', entity))
        } else {
          let formEntity = yield select(state => state.form.registrationDeliveryDossier.values.entityChildComponent)
          formEntity.dniCif = ''
          yield put(change('registrationDeliveryDossier', 'entityChildComponent', formEntity))
          yield put(messageModalActions.openErrorMessageModal('', 'recurrentEntityAssigned'))
        }
        break
      case dossierTypesConstant.createNewFleetModal:
        if (!recurrent) {
          const createNewFleetModal = yield select(state => state.management.createNewFleetModal.entityChildComponent)
          entity = checkBproEntity(entity, createNewFleetModal)
          yield put(fetchEntityChildFleetSucces({ entityChildComponent: { ...entity, previousEntity: createNewFleetModal.previousEntity } }))
          yield put(change('createNewFleetModal', 'entityChildComponent', entity))
        } else {
          let formEntity = yield select(state => state.form.createNewFleetModal.values.entityChildComponent)
          formEntity.dniCif = ''
          yield put(change('createNewFleetModal', 'entityChildComponent', formEntity))
          yield put(messageModalActions.openErrorMessageModal('', 'recurrentEntityAssigned'))
        }
        break
      case dossierTypesConstant.dataContactFleetModal:
        if (!recurrent) {
          const dataContactFleetModal = yield select(state => state.managementAuxModals.dataContactFleetModal.dossier.entityChildComponent)
          entity = checkBproEntity(entity, dataContactFleetModal)
          yield put(fetchEntityOperateChildFleetSucces({ entityChildComponent: { ...entity, previousEntity: dataContactFleetModal.previousEntity } }))
          yield put(change('dataContactFleetModal', 'entityChildComponent', entity))
        } else {
          let formEntity = yield select(state => state.form.dataContactFleetModal.values.entityChildComponent)
          formEntity.dniCif = ''
          yield put(change('dataContactFleetModal', 'entityChildComponent', formEntity))
          yield put(messageModalActions.openErrorMessageModal('', 'recurrentEntityAssigned'))
        }
        break
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag !== 'entityNotFound' && customHandlerError.json.tag !== 'TRANS/entityNotFound')) {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      } else if (reject) {
        yield call(reject, customHandlerError)
      }
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('purchase_dossier'))
      yield put(reset('paperwork_dossier'))
      yield put(reset('campaign_dossier'))
      yield put(reset('registrationDeliveryDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchClientByDniEntityChild () {
  yield takeEvery(dossierActionsTypes.FETCH_CLIENT_DNI_ENTITY_CHILD, fetchClientByDniEntityChild)
}
