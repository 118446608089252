const searchSections =
  [
    {
      page: 'dossierSimplePage',
      id: 'dossier_simple',
      code: 'dossierSimple',
      icon: '',
      title: 'SEARCH.CATEGORIES.DOSSIER.BY_DOSSIER_SIMPLE'
    },
    {
      page: 'dossierPage',
      id: 'dossier',
      code: 'dossier',
      icon: '',
      title: 'SEARCH.CATEGORIES.DOSSIER.BY_DOSSIER'
    },
    {
      page: 'vehicleSimplePage',
      id: 'vehicle_simple',
      code: 'vehicleSimple',
      icon: '',
      title: 'SEARCH.CATEGORIES.VEHICLE.BY_VEHICLE_SIMPLE'
    },
    {
      page: 'vehiclePage',
      id: 'vehicle',
      code: 'vehicle',
      icon: '',
      title: 'SEARCH.CATEGORIES.VEHICLE.BY_VEHICLE'
    },
    {
      page: 'entityPage',
      id: 'entity',
      code: 'entity',
      icon: '',
      title: 'SEARCH.CATEGORIES.ENTITY.BY_ENTITY'
    },
    {
      page: 'processingAgencyPage',
      id: 'processing_agency',
      code: 'processingAgency',
      icon: '',
      title: 'SEARCH.CATEGORIES.PROCESSING_AGENCY.TITLE'
    },
    {
      page: 'stockManagementSearchPage',
      id: 'stock_management',
      code: 'stockManagementSearch',
      icon: '',
      title: 'SEARCH.CATEGORIES.STOCKMANAGEMENT.TITLE'
    },
    {
      page: 'documentalSearchPage',
      id: 'document_finder',
      code: 'documentFinder',
      icon: '',
      title: 'DOCUMENT_SEARCH.TITLE'
    },
    {
      page: 'gdprDocumentalSearchPage',
      id: 'restricted_finder',
      code: 'restrictedFinder',
      icon: '',
      title: 'DOCUMENT_SEARCH.RESTRICTED_FINDER'
    },
    {
      page: 'massiveDownloadPage',
      id: 'massive_download',
      code: 'massiveDownloads',
      icon: '',
      title: 'DOCUMENT_SEARCH.MASSIVE_DOWNLOAD.TITLE'
    }]

export default searchSections
