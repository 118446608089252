import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { openCampaignTypeModal, fetchCampaignTypeList, deleteCampaignType, recoverCampaignType } from '../../../actions/masters/masters'
import CampaingTypeMaster from '../../../components/masters/campaignType/CampaingTypeMaster'

export function mapStateToProps (state) {
  return {
    ...state.campaignType,
    combos: {
      campaignGroup: state.combos.campaignGroup
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      recoverCampaignType,
      deleteCampaignType,
      openCampaignTypeModal,
      fetchCampaignTypeList
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(CampaingTypeMaster))
