import mastersActionTypes from '../../../constants/actions/masters/masters'
import { languages } from '../../../constants/dossier/common/languages'

function initialState () {
  return {
    campaignList: [],
    campaignCount: 0,
    pagesCount: 0,
    languageSelected: languages.SPANISH,
    filter: {
      page: 1,
      orderBy: '',
      pageSize: 10
    },
    campaignModal: {
      campaign: null,
      conditionDocumentalCampaign: null,
      showModal: false,
      showModalDocuments: false,
      languageSelected: languages.SPANISH,
      showModalCountryDetails: false,
      showModalDocumentsEdit: false,
      document: null
    },
    actionDataModal: {
      showModal: false
    },
    campaignDetailsCountries: []
  }
}

function fetchCampaignListSuccess (state, { campaignList, filter }) {
  return {
    ...state,
    campaignList: campaignList.campaignList,
    campaignCount: campaignList.campaignCount,
    pagesCount: campaignList.pagesCount,
    filter
  }
}

function openEditModalSuccess (state, { campaign }) {
  const initial = initialState()
  return {
    ...state,
    campaignModal: {
      ...initial.campaignModal,
      campaign,
      nonEditable: true,
      showModal: true
    }
  }
}

function closeEditDocumentalConditionModalSuccess (state) {
  return {
    ...state,
    campaignModal: {
      ...initialState().campaignModal
    }
  }
}

function openEditDocumentalConditionModalSuccess (state, { campaign, id }) {
  const initial = initialState()
  return {
    ...state,
    campaignModal: {
      ...initial.campaignModal,
      campaign,
      showModalDocuments: true,
      languageSelected: id
    }

  }
}

function openActionDataModal (state) {
  const initial = initialState()
  return {
    ...state,
    actionDataModal: {
      ...initial.ActionDataModal,
      showModal: true
    }
  }
}

function openAddModalSuccess (state) {
  const initial = initialState()
  return {
    ...state,
    campaignModal: {
      ...initial.campaignModal,
      campaign: null,
      nonEditable: false,
      showModal: true
    }
  }
}

function closeEditModalSuccess (state) {
  return {
    ...state,
    campaignModal: {
      ...initialState().campaignModal
    }
  }
}

function closeCampaingModalCountryDetailSuccess (state) {
  return {
    ...state,
    campaignModal: {
      ...initialState().campaignModal
    }
  }
}

function openCampaingModalCountryDetailSuccess (state, { campaign }) {
  const initial = initialState()
  return {
    ...state,
    campaignModal: {
      ...initial.campaignModal,
      campaign,
      showModalCountryDetails: true
    }
  }
}

function openEditDocumentalConditionModalEditSuccess (state, { conditionDocumentalCampaign }) {
  return {
    ...state,
    campaignModal: {
      ...state.campaignModal,
      conditionDocumentalCampaign: conditionDocumentalCampaign,
      showModalDocumentsEdit: true,
      document: conditionDocumentalCampaign
    }
  }
}

function closeEditDocumentalConditionModalEditSuccess (state) {
  return {
    ...state,
    campaignModal: {
      ...state.campaignModal,
      showModalDocumentsEdit: false
    }
  }
}

function fetchCountryCampaignDetailListSuccess (state, { campaignDetailsCountries, campaignDetailId }) {
  return {
    ...state,
    campaignDetailsCountries: campaignDetailsCountries
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case mastersActionTypes.FETCH_CAMPAIGN_LIST_SUCCESS:
      return fetchCampaignListSuccess(state, action)
    case mastersActionTypes.OPEN_EDIT_CAMPAIGN_MODAL_SUCCESS:
      return openEditModalSuccess(state, action)
    case mastersActionTypes.OPEN_ADD_CAMPAIGN_MODAL_SUCCESS:
      return openAddModalSuccess(state, action)
    case mastersActionTypes.CLOSE_EDIT_CAMPAIGN_MODAL:
      return closeEditModalSuccess(state, action)
    case mastersActionTypes.OPEN_EDIT_DOCUMENT_CONDITION_MODAL_SUCCESS:
      return openEditDocumentalConditionModalSuccess(state, action)
    case mastersActionTypes.CLOSE_EDIT_DOCUMENT_CONDITION_MODAL_SUCCESS:
      return closeEditDocumentalConditionModalSuccess(state, action)
    case mastersActionTypes.OPEN_COUNTRY_CAMPAIGN_DETAIL_MODAL_SUCCESS:
      return openCampaingModalCountryDetailSuccess(state, action)
    case mastersActionTypes.CLOSE_COUNTRY_CAMPAIGN_DETAIL_MODAL_SUCCESS:
      return closeCampaingModalCountryDetailSuccess(state, action)
    case mastersActionTypes.FETCH_COUNTRY_CAMPAIGN_DETAIL_LIST_SUCCESS:
      return fetchCountryCampaignDetailListSuccess(state, action)
    case mastersActionTypes.CLOSE_EDIT_DOCUMENT_CONDITION_EDITION_MODAL_SUCCESS:
      return closeEditDocumentalConditionModalEditSuccess(state, action)
    case mastersActionTypes.OPEN_EDIT_DOCUMENT_CONDITION_EDITION_MODAL_SUCCESS:
      return openEditDocumentalConditionModalEditSuccess(state, action)
    case mastersActionTypes.OPEN_ACTION_DATA_MODAL:
      return openActionDataModal(state, action)
    default:
      return state
  }
}
