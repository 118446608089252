import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import BuyReport from '../../../components/report/buy/BuyReport'
import { fetchBuyList, downloadBuyList } from '../../../actions/reports/buyReport'
import { fetchBrands } from '../../../actions/combos/combos'

export function mapStateToProps (state) {
  return {
    ...state.buyReport,
    combos: {
      brandCombo: state.combos.brandCombo,
      lowestOrganizedUnitsCombo: state.combos.lowestOrganizedUnitsCombo
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchBuyList,
      fetchBrands,
      downloadBuyList
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(BuyReport))
