import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { closeDocumentTypeMasterModal, submitDocumentType } from '../../../actions/masters/documentType/documentType'
import DocumentTypeMasterModal from '../../../components/masters/documentType/DocumentTypeMasterModal'

export function mapStateToProps (state) {
  const documentTypeModal = state.documentType.documentTypeModal
  return {
    showModal: documentTypeModal.showModal,
    documentType: documentTypeModal.documentType,
    languageList: documentTypeModal.languageList,
    combos: {
      documentTypeEntities: state.combos.documentTypeEntities
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      submitDocumentType,
      closeDocumentTypeMasterModal
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(DocumentTypeMasterModal))
