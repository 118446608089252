// Libraries
import React, { PureComponent } from 'react'
import { reduxForm } from 'redux-form'
import { Row, Col, Table, Form } from 'react-bootstrap'
import { Element } from 'react-scroll'
// Consts
import { dossierTypes as dossierTypesConstant } from '../../../constants/dossier/common/dossierTypes'
import { buttonsHeader } from '../../../constants/dossier/common/buttonsHeader'
import { buttonsSidebar } from '../../../constants/dossier/common/buttonsSidebar'
import { dossierSubTypes, permissions } from '../../../constants/backendIds'
// Functions
import { compareObjects, getDocumentaryPermission } from '../../../util/utils'
import { getComponentsPermisions } from '../../../util/permisionFunctions'
// Components
import DocumentaryManagementModalPage from '../../../containers/dossiers/common/DocumentaryManagementModalPage'
import StatusHeaderPage from '../../../containers/dossiers/common/StatusHeaderPage'
import ButtonsHeader from '../common/ButtonsHeader'
import ButtonsSidebar from '../common/ButtonsSidebar'
import DocumentaryManagementPage from '../../../containers/dossiers/common/DocumentaryManagementPage'
import AuditInfo from '../common/audit/AuditInfo'
import RelatedDossierList from '../../commons/RelatedDossierList'
import CommentComponent from '../common/comment/CommentComponent'
import TrackingContainer from '../../../containers/tracking/trackingContainer'
import { TrackedPages } from '../../../constants/tracking/TrackedPages'
import Vehicle from '../common/Vehicle'
import { compareDate, validateVehicle, validateRegex, customValidations } from '../../../util/validationFunctions'
import ServiceComponent from '../service/ServiceComponent'
import IssueModal from './IssueModal'

class ServiceDossier extends PureComponent {
  constructor () {
    super()
    this.state = {
      commentModalOpen: true,
      summaryOpen: true,
      showRejectCommentModal: false,
      activeTabConfiguration: null,
      headerTabConfiguration: null,
      isVehicleLoaded: false,
      componentsConfiguration: {
        vehicle: null,
        bservice: null
      },
      reason: null,
      comment: null
    }
    this.goBack = this.goBack.bind(this)
  }

  goBack () {
    this.props.actions.setActiveTab(buttonsHeader.datos_expediente)
    this.props.history.goBack()
  }

  toggleRejectCommentModal (showRejectCommentModal) {
    this.setState({ showRejectCommentModal })
  }

  submitServiceDossier (values) {
    if (values) {
      this.props.actions.saveServiceDossier(values)
    }
  }

  fetchServiceDossierPromise (dossierId) {
    return new Promise((resolve, reject) => {
      return this.props.actions.fetchServiceDossier(dossierId, resolve, reject)
    })
  }

  onClickRelatedDossierColumn (columnCode) {
    let columnCodeOrder = '+' + columnCode
    if (columnCodeOrder === this.props.relatedDossiersOrderBy) {
      columnCodeOrder = '-' + columnCode
    }
    this.props.actions.fetchFilteredRelatedDossier(this.props.dossier.dossierId, columnCodeOrder)
  }

  getRelatedDossierColumnIcon (columnCode) {
    if ('+' + columnCode === this.props.relatedDossiersOrderBy) {
      return 'ico-sort-selected-asc'
    } else if ('-' + columnCode === this.props.relatedDossiersOrderBy) {
      return 'ico-sort-selected-desc'
    } else {
      return 'ico-sort'
    }
  }

  routerWillLeave (nextLocation) {
    let pristine = true
    if (this.props.dossier.viewConfiguration.buttonsPermission && this.props.dossier.viewConfiguration.buttonsPermission.includes('service_dossier_save')) {
      pristine = this.props.pristine
    }
    this.props.actions.serviceDossierWarningSave(this.props.reset, this.props.history, nextLocation, pristine)
    return pristine
  }

  initializePermisions (props) {
    const componentKeys = Object.keys(this.state.componentsConfiguration)
    const newState = getComponentsPermisions(props.dossier.viewConfiguration.tabsConfiguration, props.activeTab, componentKeys)
    this.setState({ ...this.state, ...newState })
  }

  componentDidMount () {
    this.props.actions.restartServiceDossierState()
    this.fetchServiceDossierPromise(this.props.match.params.dossierId).then(() => {
      const form = {
        ...this.props.dossier
      }
      this.props.initialize(form)
      this.initializePermisions(this.props)
      this.props.actions.fetchAllReasons(this.props.dossier.dossierSubTypeId)
      this.props.actions.fetchCustomFields(this.props.dossier.dossierSubTypeId)
      this.props.actions.registerLastAccess(this.props.match.params.dossierId, false)

      this.props.trackVisitPage({
        documentTitle: TrackedPages.SERVICE,
        organizedUnitId: this.props.dossier.organizedUnitId,
        href: window.location.href
      })
      this.props.actions.fetchSameLevelOusCombo(this.props.dossier.organizedUnitId, null)
    })
    this.unregisterLeaveHook = this.props.history.block(this.routerWillLeave.bind(this))
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (this.props.dossier && this.props.dossier.viewConfiguration && this.props.dossier.viewConfiguration.tabsConfiguration && !compareObjects(prevProps.dossier.viewConfiguration.tabsConfiguration || {}, this.props.dossier.viewConfiguration.tabsConfiguration, true)) {
      this.initializePermisions(this.props)
    }
    if (prevProps.dossier.dossierId && prevProps.activeTab !== this.props.activeTab) {
      this.initializePermisions(this.props)
    }
    return prevProps
  }

  componentDidUpdate (prevProps) {
    if ((this.props.showManagementModal !== prevProps.showManagementModal && !this.props.showManagementModal) ||
      this.props.match.params.dossierId !== prevProps.match.params.dossierId) {
      if (this.props.match.params.dossierId !== prevProps.match.params.dossierId) {
        this.props.actions.restartServiceDossierState()
        this.props.actions.setScrollTo(buttonsSidebar.bservice)
        this.props.actions.setActiveSection(buttonsSidebar.bservice)
        this.props.actions.setActiveTab(buttonsHeader.datos_expediente)
        this.props.actions.closeAddOrEditDocumentModal()
      }
      this.fetchServiceDossierPromise(this.props.match.params.dossierId).then(() => {
        const form = { ...this.props.dossier }
        this.props.initialize(form)
        this.initializePermisions(this.props)
      })
    }
    if (this.props.dossier) {
      if (this.props.dossier.dossierId && (this.props.activeTab !== prevProps.activeTab || !compareObjects(this.props.dossier.viewConfiguration.tabsConfiguration || {}, prevProps.dossier.viewConfiguration.tabsConfiguration || {}, true))) {
        this.initializePermisions(this.props)
      }
      if (this.props.dossier.dossierId && this.props.dossier.comments && this.props.activeTab !== prevProps.activeTab && this.props.activeTab === buttonsHeader.observaciones) {
        this.props.actions.getComments(this.props.dossier.dossierId, dossierTypesConstant.service)
      }
      if (this.props.dossier.organizedUnitId && this.props.dossier.organizedUnitId !== prevProps.dossier.organizedUnitId) {
        this.props.actions.fecthOrganizedUnitsByDossierUo(this.props.dossier.organizedUnitId)
      }
      if (this.props.dossier.dossierId && this.props.statusHeaderHeight && this.props.activeSection !== prevProps.activeSection) {
        switch (this.props.activeSection) {
          case buttonsSidebar.bservice:
            this.setState({ summaryOpen: true })
            break
        }
        this.props.actions.setScrollTo(this.props.activeSection)
        this.props.actions.setActiveSection(false)
      }
    }
    if (prevProps.dossier && this.props.dossier &&
      prevProps.dossier.vehicleComponent && this.props.dossier.vehicleComponent &&
      !!prevProps.dossier.vehicleComponent.vehicleId && !this.props.dossier.vehicleComponent.vehicleId) {
      this.setState({ isVehicleLoaded: false })
    }

    const firstCustomFieldsLoad = prevProps.dossier.customFieldsByComponent?.length < this.props.dossier.customFieldsByComponent?.length
    if (firstCustomFieldsLoad) {
      let customFields = {}
      if (this.props.dossier.customFieldsValues) {
        this.props.dossier.customFieldsValues.map(x => {
          customFields[x.key] = x.value
        })
        const form = {
          ...this.props.dossier,
          customFields: customFields
        }
        this.props.initialize(form)
      }
    }
  }

  componentWillUnmount () {
    this.props.actions.setActiveSection(buttonsSidebar.bservice)
    this.props.actions.setScrollTo(null)
    this.props.actions.resetButtonsHeader()
    this.unregisterLeaveHook
    this.props.actions.closeAddOrEditDocumentModal()
    this.props.actions.restartServiceDossierState()
  }

  handleToUpdateComponent (component) {
    if (component.isVehicleLoaded === false || buttonsSidebar.vehicle === component) this.setState({ isVehicleLoaded: true })
    this.props.actions.setScrollTo(component)
  }

  handleSubmitForm () {
    if (this.props.formErrors) {
      let skipGeneralMsg = false
      if (this.props.isInvalid && !skipGeneralMsg) {
        this.props.actions.openErrorMessageModal('DOSSIER_COMPONENTS.VALIDATIONS.GENERAL_MANDATORY')
      }
    }
    this.props.handleSubmit((values) => this.submitServiceDossier(values))()
  }

  togglePanel () {
    if (this.state.summaryOpen) {
      this.props.actions.setActiveSection(false)
    }
    this.setState({ summaryOpen: !this.state.summaryOpen })
  }

  render () {
    const {
      t, combos, dossier, pristine, activeTab, buttonsHeaderHeight, statusHeaderHeight, windowWidth, reset, formValues, vehicleProps, activeSection, change, reason, comment,
      dossier: {
        dossierId, dossierSubTypeId, header, checkListHeader, comments, viewConfiguration, isMarked, dossierRelatedDossiers, auditInfo, dossierParentId, unreadComments,
        vehicleComponent: { vehicleId }, viewConfiguration: { buttonsPermission = [] }
      },
      actions: {
        updateComment, createComment, deleteComment, putCancelDossier, fetchIssueEntity, saveIssueService, openOrCloseIssueModal,
        openDocumentModal, storeDossier, setActiveTab, setButtonsHeaderHeight, resetButtonsHeader, setActiveSection, setScrollerOptions,
        refreshAuditInfo, fetchAuditDetails, fetchAuditDetailsModal, fetchDocumentsGroupedAuditDetails, fetchDocumentsGroupedAuditDetailsModal, setEntitySubTypeId, fetchEntitySubTypeCombo, fetchEntityFilterSubTypeCombo,
        fetchLowestOrganizedUnits, fecthOrganizedUnitsByDossierUo, getChangeUo, getSalesmanChangeUo, postUpdateChangeUo, markCommentAsRead,
        searchVehicle, setSearchDealerNumber, setSearchLicensePlate, setSearchVinChassis, resetSearchFields, setSimpleInputTextValidation, clearVehicle, fecthFamily,
        fetchComboDestination, fetchComboOrigin, fetchServiceDossierVehicle, setEntityId, fetchClientFromDocument, serviceDossierApprove, serviceApproveByRole,
        serviceResolveIssue, serviceRequestSpareParts, serviceCompleteSpareParts, serviceCompleteDeliveryPreparation, fetchCancelCombo,
        closeVehicleCreationModal, createVehicleCreationModal
      }
    } = this.props
    if (!dossierId) return null
    const tKey = 'DOSSIER_COMPONENTS.'
    const activeStage = checkListHeader.find(stage => stage.isActive)
    const panelsPaddingTop = statusHeaderHeight + buttonsHeaderHeight - 82
    const saveServiceDossierPermission = buttonsPermission.includes('service_dossier_save')
    return (
      <div className="dossier-service-container">
        <IssueModal
          t={t}
          service={this.props.dossier}
          showModal={this.props.dossier.showIssueModal}
          actions={{
            openOrCloseModal: () => openOrCloseIssueModal(false),
            fetchIssueEntity,
            saveIssueService
          }}
          combos={combos}
        />
        <DocumentaryManagementModalPage showRelatedDocuments={false} dossier={dossier} dossierType={dossierSubTypeId} readOnlyFields={isMarked} />
        <Col sm={12} className="add-dossier-wrapper dossier">
          <StatusHeaderPage
            headerData={header}
            stages={checkListHeader}
            dossierType={dossierTypesConstant.service}
            dossierId={dossier.dossierId}
            activeTab={activeTab}
            headerTabConfiguration={this.state.headerTabConfiguration}
            pristine={pristine}
            goBack={this.goBack}
            editableDoc={viewConfiguration ? getDocumentaryPermission(viewConfiguration) : true}
            readOnlyFields={isMarked}
          />
        </Col>
        <Form autoComplete="off">
          <div className="row-header-buttons">
            <div className="buttons-header-box">
              <ButtonsHeader
                t={t}
                dossierId={dossierId ?? this.props.match.params.dossierId}
                pristine={pristine}
                reset={reset}
                lowestOrganizedUnitsCombo={combos.organizedUnitsLegalCif}
                lastAccessDate={dossier.lastAccessDate}
                cancelCombo={combos.cancelCombo}
                reason={reason}
                comment={comment}
                actions={{
                  storeDossier,
                  serviceDossierCancel: () => putCancelDossier(dossierId, pristine, reset, null, reason, comment),
                  openDocumentModal: () => openDocumentModal(dossierId, dossierTypesConstant.service, pristine),
                  setActiveTab,
                  setButtonsHeaderHeight,
                  resetButtonsHeader,
                  setScrollerOptions,
                  submitForm: this.handleSubmitForm.bind(this),
                  fetchLowestOrganizedUnits,
                  getChangeUo,
                  getSalesmanChangeUo,
                  postUpdateChangeUo,
                  fecthOrganizedUnitsByDossierUo,
                  serviceDossierApprove: () => serviceDossierApprove(dossierId, reset),
                  serviceApproveByRole,
                  openOrCloseIssueModal: () => openOrCloseIssueModal(),
                  serviceResolveIssue,
                  serviceRequestSpareParts,
                  serviceCompleteSpareParts,
                  serviceCompleteDeliveryPreparation,
                  putCancelDossier,
                  fetchCancelCombo: fetchCancelCombo
                }}
                viewConfiguration={viewConfiguration}
                dossierType={dossierTypesConstant.service}
                activeTab={activeTab}
                marginTop={statusHeaderHeight}
                windowWidth={windowWidth}
                commentUnreadCount={ unreadComments || comments.filter((comment) => { return !comment.read }).length}
                changeUo={this.props.changeUo}
                organizedUnitId={dossier.organizedUnitId}
                readOnlyFields={isMarked}
                type={dossierTypesConstant.service}
              />
            </div>
          </div>
          {
            activeTab === buttonsHeader.datos_expediente && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden && (
              <Row className="service-panel dossier-panel">
                <Col sm={3} className="col-row expediente-menu-izq">
                  <ButtonsSidebar
                    t={t}
                    historical={false}
                    handleToUpdateComponent={this.handleToUpdateComponent.bind(this)}
                    actions={{
                      setActiveSection,
                      fetchServiceDossierVehicle
                    }}
                    dossierType={dossierTypesConstant.service}
                    activeStage={activeStage}
                    marginTop={statusHeaderHeight}
                    buttonsHeaderHeight={buttonsHeaderHeight}
                    tabConfiguration={this.state.activeTabConfiguration}
                    dossierId={dossierId}
                    dossier={dossier}
                    pristine={pristine}
                    isVehicleLoaded={this.state.isVehicleLoaded}
                  />
                </Col>
                <Col sm={9} className="col-row contenido-paneles-expediente">
                  <Col className="panels-wrapper dossier fixed" style={{ paddingTop: panelsPaddingTop }}>
                    {this.state.componentsConfiguration.bservice && this.state.componentsConfiguration.bservice.permission > permissions.hidden && (
                      <Element name={buttonsSidebar.bservice}>
                        <ServiceComponent
                          dossier={dossier}
                          t={t}
                          summaryOpen={this.state.summaryOpen}
                          actions= {{
                            setActiveTab,
                            togglePanel: () => this.togglePanel(),
                            setEntitySubTypeId,
                            fetchEntitySubTypeCombo,
                            fetchEntityFilterSubTypeCombo,
                            change,
                            setEntityId,
                            fetchClientFromDocument,
                            setActiveSection
                          }}
                          combos={combos}
                          dossierType={dossierTypesConstant.service}
                          componentConfiguration={this.state.componentsConfiguration.bservice}
                          readOnlyFields={isMarked}
                          pristine={pristine}
                          customFields={this.props.dossier.customFieldsByComponent?.find(x => x.componentCode === buttonsSidebar.bservice)?.customFields}
                        />
                      </Element>
                    )}
                    {this.state.componentsConfiguration.vehicle && this.state.componentsConfiguration.vehicle.permission > permissions.hidden && (
                      <Element name={buttonsSidebar.vehicle}>
                        <Vehicle
                          handleToUpdateComponent={this.handleToUpdateComponent.bind(this)}
                          isVehicleLoaded={this.state.isVehicleLoaded}
                          pristine={pristine}
                          dossierType={dossierTypesConstant.service}
                          t={t}
                          dossierId={dossierId}
                          searchedVehicleProps={vehicleProps}
                          dossierParentId={dossierParentId}
                          dossierWithVehicle={!!vehicleId}
                          combos={combos}
                          actions={{
                            searchVehicle,
                            setSearchDealerNumber,
                            setSearchVinChassis,
                            setSearchLicensePlate,
                            resetSearchFields,
                            setSimpleInputTextValidation,
                            clearVehicle,
                            fetchComboOrigin,
                            fetchComboDestination,
                            fetchServiceDossierVehicle,
                            fecthFamily,
                            setActiveSection,
                            closeVehicleCreationModal,
                            createVehicleCreationModal
                          }}
                          open={activeSection === buttonsSidebar.vehicle}
                          dossierSubType={dossierSubTypeId}
                          formValues={formValues ? formValues.vehicleComponent : {}}
                          componentConfiguration={this.state.componentsConfiguration.vehicle}
                          organizedUnitId={dossier.organizedUnitId}
                          readOnlyFields={!saveServiceDossierPermission || isMarked || !!dossierParentId || this.state.componentsConfiguration.vehicle.permission === permissions.readOnly}
                          parentDossierType={{
                            isPaperwork: false,
                            isSales: false,
                            isPurchase: false,
                            isCampaign: false,
                            isRegistrationDelivery: false,
                            isService: true
                          }}
                          showTopFirstLicensePlateDateAndAvailabilityDate = {true}
                          customFields={this.props.dossier.customFieldsByComponent?.find(x => x.componentCode === buttonsSidebar.vehicle)?.customFields}
                        />
                      </Element>
                    )}
                  </Col>
                </Col>
              </Row>
            )}
          {
            activeTab === buttonsHeader.gestor_documental && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden && (
              <Row className="service-panel dossier-panel">
                <Col sm={12} className="col-row" style={{ paddingTop: panelsPaddingTop }}>
                  <DocumentaryManagementPage
                    dossier={dossier}
                    dossierType={dossierTypesConstant.service}
                    formState={this.props.pristine}
                    configuration={this.state.activeTabConfiguration}
                    readOnlyFields={isMarked}
                  />
                </Col>
              </Row>
            )}
          {
            activeTab === buttonsHeader.expedientes_relacionados && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden && (
              <Row className="service-panel dossier-panel panel-expedientes-relacionados">
                <Col sm={12} className="col-row" style={{ paddingTop: panelsPaddingTop }}>
                  <Col sm={6} className="col-row">
                    <h4><i className="ico-relacion" /> {t(`${tKey}RELATED_DOSSIER.RELATED_DOSSIER`)}</h4>
                  </Col>
                  <Col sm={12} className="col-row">
                    <Table hover responsive className="simple-table dashboard-tasks-table" id="related-dossiers-table">
                      <thead>
                        <tr>
                          <th onClick={this.onClickRelatedDossierColumn.bind(this, 'urgencyType')}>
                            <i className={this.getRelatedDossierColumnIcon('urgencyType')} />
                          </th>
                          <th onClick={this.onClickRelatedDossierColumn.bind(this, 'number')}>
                            {t(`${tKey}RELATED_DOSSIER.DOSSIER_NUMBER`)} <i className={this.getRelatedDossierColumnIcon('number')} />
                          </th>
                          <th onClick={this.onClickRelatedDossierColumn.bind(this, 'organizedUnit')}>
                            {t(`${tKey}RELATED_DOSSIER.UO`)} <i className={this.getRelatedDossierColumnIcon('organizedUnit')} />
                          </th>
                          <th onClick={this.onClickRelatedDossierColumn.bind(this, 'dossierSubType')}>
                            {t(`${tKey}RELATED_DOSSIER.SUBTYPE`)} <i className={this.getRelatedDossierColumnIcon('dossierSubType')} />
                          </th>
                          <th onClick={this.onClickRelatedDossierColumn.bind(this, 'stage')}>
                            {t(`${tKey}RELATED_DOSSIER.STATE`)} <i className={this.getRelatedDossierColumnIcon('stage')} />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <RelatedDossierList history={this.props.history} dossierRelatedDossiers={dossierRelatedDossiers} />
                      </tbody>
                    </Table>
                  </Col>
                </Col>
              </Row>
            )}
          {
            activeTab === buttonsHeader.actividad_expediente && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden && (
              <Row className="service-panel dossier-panel panel-actividad-expediente">
                <Col sm={12} className="col-row" style={{ paddingTop: panelsPaddingTop }}>
                  <AuditInfo
                    auditInfo={auditInfo}
                    dossierType={dossierTypesConstant.service}
                    actions={{ refreshAuditInfo, fetchAuditDetails, fetchAuditDetailsModal, fetchDocumentsGroupedAuditDetails, fetchDocumentsGroupedAuditDetailsModal }}
                    dossier={dossier}
                    t={t}
                    readOnlyFields={isMarked}
                  />
                </Col>
              </Row>
            )
          }
          {
            activeTab === buttonsHeader.observaciones && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden && (
              <Row className="service-panel dossier-panel panel-observaciones-expediente">
                <Col sm={12} className="col-row" style={{ paddingTop: panelsPaddingTop }}>
                  <Col sm={6} className="col-row">
                    <h4><i className="ico-bocadillo" onClick={() => { this.setState({ commentModalOpen: !this.state.commentModalOpen }) }} /> {t(`${tKey}COMMENTS.TITLE_PANEL`)}</h4>
                  </Col>
                  <Col sm={12} className="col-row">
                    <CommentComponent
                      comments={comments}
                      actions={{ updateComment, createComment, deleteComment, markCommentAsRead }}
                      readOnlyFields={this.state.activeTabConfiguration.permission !== permissions.editable || isMarked}
                      dossierType={dossierTypesConstant.service}
                      t={t}
                      tKey={tKey}
                      combos={{ allReasonCombo: this.props.combos.allReasonCombo }}
                      openComponent={activeTab === buttonsHeader.observaciones}
                      dossierId={this.props.dossier.dossierId}
                    />
                  </Col>
                </Col>
              </Row>
            )
          }
        </Form>
      </div>
    )
  }
}

const validate = (values, props) => {
  const error = {}

  if (values.vehicleComponent) {
    error.vehicleComponent = validateVehicle(values.vehicleComponent, props)
  }
  if (values.dossierServiceComponent && values.dossierServiceComponent.entitySubTypeId && !values.dossierServiceComponent.entityId) {
    error.dossierServiceComponent = {}
    error.dossierServiceComponent.entityId = props.t('DOSSIER_COMPONENTS.VALIDATIONS.ENTITY_MANDATORY')
  }
  if (values.dossierServiceComponent && values.dossierSubTypeId === dossierSubTypes.service_pap) {
    if (values.dossierServiceComponent.orderDate && values.dossierServiceComponent.availabilityDate) {
      const retCompareDates = compareDate(values.dossierServiceComponent.orderDate, values.dossierServiceComponent.availabilityDate)
      if (retCompareDates.secondDateIsBefore === true) {
        error.dossierServiceComponent = {}
        error.dossierServiceComponent.availabilityDate = props.t('DOSSIER_COMPONENTS.VALIDATIONS.AVAILABILITY_DATE_MUST_BE_EQUAL_OR_GREATER')
      }
    }
    if (values.dossierServiceComponent.availabilityDate && values.dossierServiceComponent.requiredDate) {
      const retCompareDates = compareDate(values.dossierServiceComponent.availabilityDate, values.dossierServiceComponent.requiredDate)
      if (retCompareDates.secondDateIsBefore === true) {
        error.dossierServiceComponent = {}
        error.dossierServiceComponent.requiredDate = props.t('DOSSIER_COMPONENTS.VALIDATIONS.REQUIRED_DATE_MUST_BE_EQUAL_OR_GREATER')
      }
    }
  }

  error.customFields = {}
  if (values.customFields && Object.keys(values.customFields).length > 0 && props.dossier.customFieldsByComponent) {
    error.customFields = {}
    let resultCustomFields = {}
    let allCustomFields = []
    props.dossier.customFieldsByComponent.map(x => x.customFields).map(x => {
      allCustomFields = allCustomFields.concat(x)
    })
    Object.keys(values.customFields).forEach(key => {
      const foundCustomField = allCustomFields.find(x => x.code === key)
      if (foundCustomField && foundCustomField.validationFunction) {
        if (foundCustomField.validationFunction && foundCustomField.validationFunction.indexOf('regEx-') !== -1) {
          resultCustomFields = validateRegex(foundCustomField.validationFunction, foundCustomField.customFieldLocales[0].name, values.customFields[key], props)
        } else {
          resultCustomFields = customValidations(foundCustomField.validationFunction, values.customFields[key], props)
        }
        error.customFields[key] = resultCustomFields === Object(resultCustomFields) ? undefined : resultCustomFields
      }
    })
  }

  return error
}

var decoratedComponent = TrackingContainer(ServiceDossier, false)
export default reduxForm({
  form: 'service_dossier',
  validate
})(decoratedComponent)
