
import React, { PureComponent } from 'react'
import { Modal, Button, Col, Row } from 'react-bootstrap'
import { reduxForm } from 'redux-form'
import AuditInfo from '../dossiers/common/audit/AuditInfo'

class HistoricalFleetModal extends PureComponent {
  constructor () {
    super()
    this.state = {}
  }

  componentDidUpdate () {
  }

  render () {
    const {
      t,
      showModal, handleSubmit, actions: { refreshAuditFleetInfo, auditFleetInfoDownload }, auditInfo
    } = this.props
    const tKey = 'MANAGEMENT.FLEETS_TAB.'
    let title = t(`${tKey}FLEET_HISTORICAL`)
    if (!showModal) { return null } else {
      return (
        <Modal className="fleet-Modal languages-modal" show={showModal} onHide={() => this.props.actions.showHistoricalFleetModal(false)} backdrop={'static'}>
          <form autoComplete="off" onSubmit={handleSubmit((values) => this.createOrUpdateFleet(values))}>
            <Modal.Header closeButton onHide={() => this.props.actions.showHistoricalFleetModal(false)}>
              <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Col sm={12} className="col-row">
                <Row className="inputs-wrapper flexRow justifyEnd">
                  <Button
                    onClick={() => auditFleetInfoDownload(this.props.fleet.fleetId)}
                    bsStyle="default"
                    bsSize="small">
                    {t('SEARCH.ACTIONS.EXPORT')}
                    <i className="ico-if_Download_1031520" />
                  </Button>
                </Row>
                <AuditInfo
                  auditInfo={auditInfo}
                  dossierType={this.props.fleet.dossierSubType}
                  actions={{ refreshAuditFleetInfo }}
                  t={t}
                  isFleetAudit={true}
                  fleetId={this.props.fleet.fleetId}
                />
              </Col>
            </Modal.Body>
            <Modal.Footer>
              <Button id="btn_close"
                className="btn-Accept"
                onClick={() => this.props.actions.showHistoricalFleetModal(false)}>
                {t(`${tKey}CLOSE`)}
              </Button>
            </Modal.Footer>
          </form>
        </Modal>

      )
    }
  }
}
const validate = (values, props) => {
  const errors = {}

  return errors
}

export default reduxForm({
  form: 'historicalFleetModal',
  validate

})(HistoricalFleetModal)
