export const CustomDimensionsConstant = {
  ORGANIZED_UNIT_ID: 1,
  USER_ID: 2,
  DOCUMENT_ID: 4,
  DOCUMENT_TYPE_ID: 5,
  DOCUMENT_TYPE_USE_ID: 6,
  DOCUMENT_TYPE_USE_NAME: 7,
  DASHBOARD_TASK_ID: 8,
  DASHBOARD_TASK_NAME: 9
}

/*
Código de proceso
Descripción proceso
*AÑADIR*Numero de Expediente. Si lo hay.
*ya SE GUARDA*Fecha y hora
*ya SE GUARDA*Id de Usuario
Usuario
*/
