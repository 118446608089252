import React, { PureComponent } from 'react'
import { Row, Table, Button, Tooltip, OverlayTrigger, Modal, Col } from 'react-bootstrap'
import { reduxForm } from 'redux-form'
import CampaignModalDocumentsEditPage from '../../../containers/masters/Campaign/CampaignModalDocumentsEditPage'

class CampaignModalDocument extends PureComponent {
  constructor () {
    super()
    this.state = {
      documentsToAdd: [],
      sort: 'value',
      sortConditions: 'name',
      conditionDoc: null
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.campaign !== null && this.props.campaign !== prevProps.campaign) {
      if (this.props.campaign.isCampaign) {
        this.props.actions.fetchConditionDocumentalCampaign(this.props.campaign.campaignDetailsId, null)
      } else {
        this.props.actions.fetchConditionDocumentalCampaign(null, this.props.campaign.campaignGroupId)
      }
    }
  }

  componentDidMount () {
    this.props.actions.getDocumentsConditionDocumentalcampaign()
  }

  filterDocuments (documents) {
    let docs = []
    let docsToAdd = this.props.documentsConditionDocumentalCampaign

    docsToAdd.forEach(element => {
      if (documents.find(el => el.documentTypeUseId === element.id) === undefined) {
        docs.push(element)
      }
    })

    docs = docs.sort(this.dynamicSort(this.state.sort))
    return docs
  }

  dynamicSort (property) {
    var sortOrder = 1
    if (property[0] === '-') {
      sortOrder = -1
      property = property.substr(1)
    }
    return function (a, b) {
      var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0
      return result * sortOrder
    }
  }

  changeOrderAdd (field) {
    switch (this.state.sort) {
      case 'value':
        this.setState({ sort: '-value' })
        break
      default:
        this.setState({ sort: 'value' })
        break
    }
  }

  changeOrder (field) {
    switch (this.state.sortConditions) {
      case 'name':
        this.setState({ sortConditions: '-name' })
        break
      default:
        this.setState({ sortConditions: 'name' })
        break
    }
  }

  deleteDocument (document) {
    if (this.props.campaign.isCampaign) {
      this.props.actions.deleteConditionDocumentalCampaign(document.conditionDocumentalCampaigId, this.props.campaign.campaignDetailsId, null)
    } else {
      this.props.actions.deleteConditionDocumentalCampaign(document.conditionDocumentalCampaigId, null, this.props.campaign.campaignGroupId)
    }
  }

  editDocument (document) {
    this.setState({ documentCon: document })
    this.props.actions.openEditDocumentalConditionModalEditSuccess(document)
  }

  addDocument (document) {
    let conditionDocumentalCampaignDto = {
      documentTypeUseId: document.id,
      campaignDetailsId: this.props.campaign.isCampaign ? this.props.campaign.campaignDetailsId : null,
      campaignGroupId: this.props.campaign.isCampaign ? null : this.props.campaign.campaignGroupId
    }
    this.props.actions.addConditionDocumentalCampaign(conditionDocumentalCampaignDto)
  }

  render () {
    const {
      t, conditionDocumentalCampaign,
      showModal
    } = this.props
    const tKey = 'MASTERS.CAMPAIGN_DETAILS_MASTERS.MODAL_DOCUMENTS.'
    const deleteDocumentTooltip = (<Tooltip id="deleteDocumentTooltip">{t(`${tKey}DELETE_DOCUMENT`)}</Tooltip>)
    const addDocumentTooltip = (<Tooltip id="addDocumentTooltip">{t(`${tKey}ADD_DOCUMENT`)}</Tooltip>)
    const editDocumentTooltip = (<Tooltip id="editDocumentTooltip">{t(`${tKey}EDIT_DOCUMENT`)}</Tooltip>)
    let documentsToAddList = []
    const style = {
      height: '350px',
      overflowY: 'auto'
    }

    let title = ''
    if (this.props.campaign && this.props.campaign.isCampaign) {
      title = this.props.campaign.campaignDetailsCode + ' - ' + this.props.campaign.description
    } else if (this.props.campaign && !this.props.campaign.isCampaign) {
      title = this.props.campaign.groupCode + ' - ' + (this.props.campaign.campaignGroupLanguage && this.props.campaign.campaignGroupLanguage.length > 0 ? this.props.campaign.campaignGroupLanguage.find(lang => (lang.languageId ? lang.languageId : '') === this.props.languageSelected).description : '')
    }

    documentsToAddList = this.filterDocuments(conditionDocumentalCampaign)
    let documentConditions = conditionDocumentalCampaign.sort(this.dynamicSort(this.state.sortConditions))
    return (
      <Modal className="role-modal languages-modal" show={showModal} onHide={this.props.actions.closeEditDocumentalConditionModalSuccess}>
        <CampaignModalDocumentsEditPage/>
        <form autoComplete="off" >
          <Modal.Header closeButton onHide={this.props.actions.closeEditDocumentalConditionModalSuccess}>
            <Modal.Title>{t(`${tKey}TITLE`) + ': ' + title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="inputs-wrapper">
              <Col sm={6}>
                <div style={style}>
                  <Table className="editable-table" hover responsive>
                    <thead>
                      <tr>
                        <th onClick={() => { this.changeOrder('name') }}>
                          {t(`${tKey}DOCUMENTS`)}
                        </th>
                        <th style={{ width: '70px' }}>{t('DOSSIER_COMPONENTS.ACCESSORIES.ACTIONS')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {documentConditions && documentConditions.map((document, idx) => {
                        return (
                          <tr key={idx}>
                            <td>{document.name}</td>
                            <td>
                              <div style={{ textAlign: 'right' }}>
                                <a onClick={() => this.editDocument(document)}>
                                  <OverlayTrigger placement="left" overlay={editDocumentTooltip}>
                                    <i className="ico-accessories" />
                                  </OverlayTrigger>
                                </a>
                                <a onClick={() => this.deleteDocument(document)}>
                                  <OverlayTrigger placement="left" overlay={deleteDocumentTooltip}>
                                    <i className="ico-trash" />
                                  </OverlayTrigger>
                                </a>
                              </div>
                            </td>
                          </tr>)
                      })
                      }
                      {(documentConditions === null || documentConditions === undefined || documentConditions.length === 0) &&
                  <tr>
                    <td colSpan={10}><h2>{t(`${tKey}RESULTS_NOT_FOUND`)}</h2></td>
                  </tr>}
                    </tbody>
                  </Table>
                </div>
              </Col>
              <Col sm={6}>
                <div style={style}>
                  <Table className="editable-table" hover responsive>
                    <thead>
                      <tr>
                        <th style={{ width: '70px' }}>{t('DOSSIER_COMPONENTS.ACCESSORIES.ACTIONS')}</th>
                        <th onClick={() => { this.changeOrderAdd('document') }}>
                          {t(`${tKey}DOCUMENTS_TO_ADD`)}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {documentsToAddList && documentsToAddList.map((documentsToAdd, idx) => {
                        return (
                          <tr key={idx}>
                            <td>
                              <div style={{ textAlign: 'center' }}>
                                <a onClick={() => this.addDocument(documentsToAdd)}>
                                  <OverlayTrigger placement="left" overlay={addDocumentTooltip}>
                                    <i className="ico-plus-square" />
                                  </OverlayTrigger>
                                </a>
                              </div>
                            </td>
                            <td>{documentsToAdd.value}</td>
                          </tr>)
                      })
                      }
                      {(documentsToAddList === null || documentsToAddList === undefined || documentsToAddList.length === 0) &&
                  <tr>
                    <td colSpan={10}><h2>{t(`${tKey}RESULTS_NOT_FOUND`)}</h2></td>
                  </tr>}
                    </tbody>
                  </Table>
                </div>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Col sm={12} className="btn-wrapper">
              <Button
                id="role-modal-submit-button"
                className="btn-standard"
                onClick = {() => this.props.actions.closeEditDocumentalConditionModalSuccess()}>
                {t(`${tKey}CLOSE`)}
              </Button>
            </Col>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default reduxForm({
  form: 'editDocumentCampaign'
})(CampaignModalDocument)
