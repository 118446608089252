
import React, { PureComponent } from 'react'
import { Button, Modal, Col, Row } from 'react-bootstrap'
import { reduxForm } from 'redux-form'
import { validateDate, validateEntity, validateContact, isContactEmpty, isEntityChildEmpty } from '../../util/validationFunctions'
import { dossierSubTypeId } from '../../constants/dossier/common/dossierSubType'
import { convertStringToDate, compareObjects } from '../../util/utils'
import FleetAndClientComponentPage from '../../containers/management/commons/FleetAndClientComponentPage'
import DossiersListFleetDetailsComponentPage from '../../containers/management/commons/DossiersListFleetDetailsComponentPage'
import {
  asyncValidateEntityDni, getFieldsValidated
} from '../../util/validationFunctionEntityDniAsync'
import { validationCreateNewFleetModalPromisesOrder } from '../../constants/validatedFields/backValidationPromisesOrder'
import promisesStatus from '../../constants/promisesStatus'
import permissionCodes from '../../constants/permissionCodes'
import PaperworkFleetListComponentPage from '../../containers/management/commons/PaperworkFleetListComponentPage'
import ChargeFleetListComponentPage from '../../containers/management/commons/ChargeFleetListComponentPage'

class CreateNewFleetModal extends PureComponent {
  constructor () {
    super()
    this.state = {
      disableClient: true,
      organizedUnit: null,
      dossierSubTypeId: null,
      openPanelFleet: true,
      openPanelClient: true,
      changeCheckAll: false
    }
  }

  componentDidUpdate () {

  }

  getSnapshotBeforeUpdate (prevProps) {
    if (prevProps.showModal !== this.props.showModal) {
      if (this.props.fleet) {
        this.props.change('fleetCode', this.props.fleet.fleetCode)
        this.props.change('description', this.props.fleet.description)
        this.props.change('observations', this.props.fleet.observations)
        this.props.change('organizedUnitId', this.props.fleet.organizedUnitId)
        this.props.change('dossierSubTypeId', this.props.fleet.dossierSubType)
        this.props.change('renewalDate', this.props.fleet.renewalDate)
        this.props.change('dossierCount', this.props.fleet.numDossiers)
        this.props.change('creationDate', this.props.fleet.creationDate)
        this.props.change('entityComponent', this.props.fleet.entityComponent)
        this.props.change('entityChildComponent', this.props.fleet.finalEntity)
        this.props.change('dossierContactComponent', this.props.fleet.dossierContactComponent)
      } else {
        this.props.actions.clearEntity(-1, dossierSubTypeId.venta_vn)
        this.props.change('fleetCode', null)
        this.props.change('description', null)
        this.props.change('observations', null)
        this.props.change('organizedUnitId', null)
        this.props.change('dossierSubTypeId', null)
        this.props.change('renewalDate', null)
      }

      this.setState({ disableClient: true, organizedUnit: null, dossierSubTypeId: null })
    }
    if (prevProps.showModal && !this.props.showModal) {
      this.props.initialize({})
      this.props.reset()
    }

    return null
  }

  createOrUpdateFleet (values) {
    let fleet = {}
    fleet.FleetCode = values.fleetCode
    fleet.Description = values.description
    fleet.Observations = values.observations
    fleet.EntityComponent = values.entityComponent
    fleet.OrganizedUnitId = values.organizedUnitId
    fleet.DossierSubType = values.dossierSubTypeId
    fleet.RenewalDate = values.renewalDate
    fleet.FinalEntity = values.entityChildComponent
    fleet.DossierContactComponent = values.dossierContactComponent

    if (this.props.create) {
      this.createFleet(fleet)
    } else {
      this.updateFleet(fleet)
    }
  }

  createFleet (fleetModel) {
    new Promise((resolve, reject) => {
      this.props.actions.createUpdateFleet(fleetModel, resolve, reject, this.props.fleet)
    }).then((response) => {
      this.props.fetchFleets()
      this.props.actions.closeCreateEditFleetModal()
      this.props.actions.openFleetCreatedSelectChargeModal(response)
    }).catch((error) => {
      this.props.actions.openModal('actionAdminFleetExpFleetError', null, null, null, null, error.json?.tag ? error.json.tag : null)
    })
  }

  updateFleet (fleetModel) {
    fleetModel.fleetId = this.props.fleet.fleetId
    if (!this.compareToSave(fleetModel)) {
      new Promise((resolve, reject) => {
        this.props.actions.createUpdateFleet(fleetModel, resolve, reject, this.props.fleet)
      }).then((response) => {
        if (response) {
          this.props.actions.openModal('fleetModified', () => { this.props.actions.closeCreateEditFleetModal(); this.props.fetchFleets() }, null, null, null, [{ id: '##FLEET_CODE##', value: response.fleetCode }, { id: '##DESCRIPTION##', value: response.description }], null)
        }
      }).catch(() => {
        if (this.props.fleet.numDossiers <= 0) {
          this.props.actions.openModal('actionAdminFleetExpFleetError')
        }
      })
    } else {
      this.props.actions.openModal('notChangesToSave')
    }
  }

  compareToSave (fleetModel) {
    let fleet = {}
    fleet.FleetCode = this.props.fleet.fleetCode
    fleet.Description = this.props.fleet.description
    fleet.Observations = this.props.fleet.observations
    fleet.EntityComponent = this.props.fleet.entityComponent
    fleet.OrganizedUnitId = this.props.fleet.organizedUnitId
    fleet.DossierSubType = this.props.fleet.dossierSubType
    fleet.RenewalDate = this.props.fleet.renewalDate
    fleet.FinalEntity = this.props.fleet.finalEntity
    fleet.DossierContactComponent = this.props.fleet.dossierContactComponent
    fleet.fleetId = this.props.fleet.fleetId

    return compareObjects(fleet, fleetModel, true)
  }

  updateComponentAfterDeleteAndRestart () {

  }

  validateDate (value, allValues, props) {
    const result = validateDate(value, props)
    return result === Object(result) ? undefined : result
  }

  fieldFill (field) {
    this.setState({ fields: { ...this.state.fields, ...field } })
  }

  collapsePanelFleet () {
    this.setState({ openPanelFleet: !this.state.openPanelFleet })
  }

  collapsePanelClient () {
    this.setState({ openPanelClient: !this.state.openPanelClient })
  }

  select () {
    let changeCheckAll = this.state.changeCheckAll
    this.setState({ changeCheckAll: !changeCheckAll })
    this.props.actions.openAddDossiersToFleetModal(this.props.fleet)
  }

  charge () {
    this.props.actions.fetchPaymentCombo(false)
    this.props.actions.fetchVehicleType()

    let changeCheckAll = this.state.changeCheckAll
    this.setState({ changeCheckAll: !changeCheckAll })
    this.props.actions.openChargeDossiersModal(this.props.fleet)
  }

  delete () {
    let selectedDossiers = this.props.dossierFleetDetails.filter(dossier => dossier.checked)
    if (this.validateDelete(selectedDossiers)) {
      this.props.actions.openModal('deleteDossierFromFleet', () => { this.deleteOK(selectedDossiers, this.props.fleet.fleetId) }, null, null, null, [{ id: '##FLEET_CODE##', value: this.props.fleet.fleetCode }, { id: '##NUMDOSSIERS##', value: selectedDossiers.length }], null)
    }
  }

  validateDelete (selectedDossiers) {
    var noDelete = selectedDossiers.filter(dossier => dossier.hasFleetCharges)

    if (noDelete && noDelete.length > 0) {
      this.props.actions.openModal('noDeleteDossierFromFleetForCharges', null, null, null, null, [{ id: '##DOSSIER_NUMBER##', value: noDelete.map(dossier => dossier.number) }], null)

      return false
    }
    return true
  }

  deleteOK (dossiers, fleet) {
    let changeCheckAll = this.state.changeCheckAll
    this.setState({ changeCheckAll: !changeCheckAll })
    let dossiersIds = dossiers.map(dossier => dossier.dossierId)

    new Promise((resolve) => {
      this.props.actions.deleteDossiersFromFleet(dossiersIds, fleet, resolve)
    }).then(() => {
      this.props.fetchFleets()
    })
  }

  render () {
    const {
      t,
      showModal, handleSubmit, create, isReadOnly
    } = this.props
    const tKey = 'MANAGEMENT.FLEETS_TAB.'
    let title = (this.props.create ? t(`${tKey}TITLE_ADD_FLEET`) : this.props.isReadOnly ? t(`${tKey}TITLE_VIEW_FLEET`) : t(`${tKey}TITLE_EDIT_FLEET`))
    if (!this.props.create) {
      if (this.props.fleet?.organizedUnit !== undefined) {
        title = title + ' - UO : ' + this.props.fleet?.organizedUnit
      } else if (this.props.dossierData?.length > 0) {
        title = title + ' - UO : ' + this.props.dossierData[0].organizedUnit
      }
    }
    let treeComboPermission
    if (isReadOnly) {
      treeComboPermission = permissionCodes.viewFleetManagement
    }
    if (create) {
      treeComboPermission = permissionCodes.createFleetManagement
    } else {
      treeComboPermission = permissionCodes.editFleetManagement
    }

    const selectedDossiers = this.props.dossierFleetDetails.filter(dossier => dossier.checked)
    if (!showModal) { return null } else {
      return (
        <Modal className="fleet-Modal languages-modal" show={showModal} onHide={this.props.actions.closeCreateEditFleetModal} backdrop={'static'}>
          <form className='formNewFleet' autoComplete="off" onSubmit={handleSubmit((values) => this.createOrUpdateFleet(values))}>
            <Modal.Header closeButton onHide={this.props.actions.closeCreateEditFleetModal}>
              <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <FleetAndClientComponentPage asyncValidateFunc={asyncValidate} stopAsyncValidation={(value) => this.props.actions.stopAsyncValidation('createNewFleetModal', value)}
                {...this.props} OUTreeComboPermission={treeComboPermission}
              />
              {!this.props.create && (
                <>
                  <DossiersListFleetDetailsComponentPage fleet={this.props.fleet} changeCheckAll={this.state.changeCheckAll} readOnlyFields={this.props.isReadOnly}/>
                  <PaperworkFleetListComponentPage fleet = {this.props.fleet} readOnlyFields={true}
                  ></PaperworkFleetListComponentPage>
                  <ChargeFleetListComponentPage fleet = {this.props.fleet} readOnlyFields={true} dossierSubType={this.props.fleet.dossierSubType}>
                  </ChargeFleetListComponentPage>
                  {!this.props.isReadOnly && <Row className='button-container'>
                    <Button
                      id="role-modal-submit-button-select"
                      className="btn-Select"
                      onClick = {() => this.select()}
                      disabled={selectedDossiers.length > 0}>
                      {t(`${tKey}AUX_MODALS.CHARGE`)}
                    </Button>
                    <Button
                      id="role-modal-submit-button-charge"
                      className="btn-Load btnIcoFolder"
                      onClick = {() => this.charge()}
                      disabled={selectedDossiers.length > 0}>
                      {t(`${tKey}AUX_MODALS.SELECT`)}
                    </Button>
                    <Button
                      id="role-modal-submit-button-delete"
                      className="btn-Delete"
                      onClick = {() => this.delete()}
                      disabled={selectedDossiers.length === 0}>
                      {t(`${tKey}DELETE`)}
                    </Button>
                  </Row>}
                </>

              )}
            </Modal.Body>
            <Modal.Footer>
              <Col sm={12} className="buttons-wrapper">
                {!this.props.isReadOnly && <Button
                  className="btn-Accept"
                  type="submit">
                  {t(`${tKey}SAVE`)}
                </Button>}
              </Col>
            </Modal.Footer>
          </form>
        </Modal>

      )
    }
  }
}
const validate = (values, props) => {
  const errors = {}

  if (!values || !values.organizedUnitId) {
    errors.organizedUnitId = props.t('GLOBAL.FORMS.REQUIRED')
  }
  if (!values || !values.fleetCode) {
    errors.fleetCode = props.t('GLOBAL.FORMS.REQUIRED')
  }
  if (!values || !values.description) {
    errors.description = props.t('GLOBAL.FORMS.REQUIRED')
  }
  if (!values || !values.dossierSubTypeId) {
    errors.dossierSubTypeId = props.t('GLOBAL.FORMS.REQUIRED')
  }

  if (values && values.renewalDate) {
    if (!props.fleet || (props.fleet.renewalDate !== values.renewalDate)) {
      let renewalDate = convertStringToDate(values.renewalDate)

      if (Date.now() > renewalDate) {
        errors.renewalDate = props.t('GLOBAL.FORMS.DATE_INVALID_GREATER_THAN_CURRENT_DATE')
      }
    }
  }
  errors.entityComponent = validateEntity(values.entityComponent, props)
  if (props.registeredFields) {
    const entityChildHasData = values.entityChildComponent ? !isEntityChildEmpty(values.entityChildComponent, props.registeredFields) : false
    if (entityChildHasData) {
      errors.entityChildComponent = validateEntity(values.entityChildComponent, props, null, true)
    }

    const contactHasData = values.dossierContactComponent ? !isContactEmpty(values.dossierContactComponent, props.registeredFields) : false

    if (contactHasData) {
      errors.dossierContactComponent = validateContact(values.dossierContactComponent, props)
    }
  }

  return errors
}

const asyncValidate = (values, dispatch, props, field, returnError) => {
  let promises = []
  if (values.entityComponent) {
    promises = promises.concat(asyncValidateEntityDni(values.entityComponent, dispatch, props, props.fleet?.organizedUnitId ?? values.organizedUnitId))
    promises = promises.concat(asyncValidateEntityDni(values.entityChildComponent, dispatch, props, props.fleet?.organizedUnitId ?? values.organizedUnitId))
  }

  return Promise.allSettled(promises).then(result => {
    let error = {}
    for (let index = 0; index < result.length; index++) {
      const element = result[index]
      if (element.status === promisesStatus.FULFILLED) {
        switch (index) {
          case validationCreateNewFleetModalPromisesOrder.ENTITY_COMPONENT:
            error = { ...error, entityComponent: element.value }
            break
          case validationCreateNewFleetModalPromisesOrder.ENTITY_CHILD_COMPONENT:
            error = { ...error, entityChildComponent: element.value }
            break
          default:
            break
        }
      }
    }
    if (Object.keys(error).length > 0 && !returnError) throw Object.assign(new Error(), error)
    if (returnError) return error
  })
}

export default reduxForm({
  form: 'createNewFleetModal',
  validate,
  asyncValidate,
  asyncBlurFields: [...getFieldsValidated('entityComponent'), ...getFieldsValidated('entityChildComponent')],
  destroyOnUnmount: false
})(CreateNewFleetModal)
