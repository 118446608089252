export default {
  FETCH_INITIAL_NOTIFICATIONS_COUNTER: 'FETCH_INITIAL_NOTIFICATIONS_COUNTER',
  SET_NOTIFICATIONS_COUNTER: 'SET_NOTIFICATIONS_COUNTER',
  FETCH_DOCUMENT_NOTIFICATIONS: 'FETCH_DOCUMENT_NOTIFICATIONS',
  FETCH_DOCUMENT_NOTIFICATIONS_SUCCESS: 'FETCH_DOCUMENT_NOTIFICATIONS_SUCCESS',
  CLOSE_DOCUMENT_NOTIFICATIONS_POPOVER: 'CLOSE_DOCUMENT_NOTIFICATIONS_POPOVER',
  FETCH_DOSSIER_NOTIFICATIONS: 'FETCH_DOSSIER_NOTIFICATIONS',
  FETCH_DOSSIER_NOTIFICATIONS_SUCCESS: 'FETCH_DOSSIER_NOTIFICATIONS_SUCCESS',
  CLOSE_DOSSIER_NOTIFICATIONS_POPOVER: 'CLOSE_DOSSIER_NOTIFICATIONS_POPOVER',
  MARK_DOSSIER_NOTIFICATION_AS_READ: 'MARK_DOSSIER_NOTIFICATION_AS_READ',
  MARK_DOSSIER_NOTIFICATION_AS_READ_SUCCESS: 'MARK_DOSSIER_NOTIFICATION_AS_READ_SUCCESS'
}
