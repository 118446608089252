import React, { PureComponent } from 'react'
import { Row, Col } from 'react-bootstrap'

class Tab1 extends PureComponent {
  render () {
    return (
      <Col sm={12} className="tab1-wrapper">
        <Row>
          <Col sm={4}>
            <div className="text-band">
              <span className="first">Gestor:</span>
              <span className="second number-modal">Nombre del gestor</span>
            </div>
          </Col>
          <Col sm={4}>
            <div className="text-band">
              <span className="first"> Empresa:</span>
              <span className="second number-modal">Empresa</span>
            </div>
          </Col>
          <Col sm={4}>
            <div className="text-band">
              <span className="first">Ubicación/Sucursal:</span>
              <span className="second number-modal">Ubicación/Sucursal</span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={4}>
            <div className="text-band">
              <span className="first">V.S.B.</span>
              <span className="second number-modal">V.S.B.</span>
            </div>
          </Col>
          <Col sm={4}>
            <div className="text-band">
              <span className="first">Fecha/hora pedido:</span>
              <span className="second number-modal">Fecha/hora pedido</span>
            </div>
          </Col>
          <Col sm={4}>
            <div className="text-band">
              <span className="first">Tipo de expediente:</span>
              <span className="second number-modal">Tipo de expediente</span>
            </div>
          </Col>
        </Row>
      </Col>
    )
  }
}

export default Tab1
