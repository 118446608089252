import React, { PureComponent } from 'react'
import { reduxForm, Field } from 'redux-form'
import { Row, Col, Table, Button, Tooltip, OverlayTrigger, Glyphicon } from 'react-bootstrap'
import FuelModal from '../../../containers/masters/fuel/FuelModalPage'
import IDocCarPagination from '../../commons/iDocCarPagination'
import InputText from '../../commons/form/InputText'

class Fuel extends PureComponent {
  constructor () {
    super()
    this.state = {
      filter: {
        searchName: null,
        searchDescription: null
      }
    }
  }

  getSortIcon (column = '') {
    switch (this.props.filter.orderBy) {
      case '+' + column:
        return 'ico-sort-selected-asc'
      case '-' + column:
        return 'ico-sort-selected-desc'
      default:
        return 'ico-sort'
    }
  }

  changeOrder (field) {
    switch (this.props.filter.orderBy) {
      case `+${field}`:
        this.props.actions.fetchFuelList({ ...this.state.filter, ...this.props.filter, orderBy: `-${field}` })
        break
      default:
        this.props.actions.fetchFuelList({ ...this.state.filter, ...this.props.filter, orderBy: `+${field}` })
        break
    }
  }

  handleNameChange (searchName) {
    if (this.state.filter.searchName !== searchName) {
      this.setState({ filter: { ...this.state.filter, searchName } })
    }
  }

  handleDescriptionChange (searchDescription) {
    if (this.state.filter.searchDescription !== searchDescription) {
      this.setState({ filter: { ...this.state.filter, searchDescription } })
    }
  }

  searchFuel () {
    this.props.actions.fetchFuelList({ ...this.props.filter, ...this.state.filter, page: 1 })
  }

  resetFilters () {
    this.setState({
      filter: {
        searchName: null,
        searchDescription: null
      }
    }, () => { this.searchFuel() })
  }

  render () {
    const {
      t, fuelList, pagesCount, fuelCount, filter,
      filter: { page },
      actions: { openEditFuelModal, fetchFuelList, deleteFuel, recoverFuel }
    } = this.props

    const tKey = 'MASTERS.'
    const editTooltip = (<Tooltip id="editTooltip">{t(`${tKey}EDIT`)}</Tooltip>)
    const deleteTooltip = (<Tooltip id="deleteTooltip">{t(`${tKey}DELETE`)}</Tooltip>)
    const recoverTooltip = (<Tooltip id="deleteTooltip">{t(`${tKey}RECOVER`)}</Tooltip>)
    const hasFilters = (this.state.filter.searchDescription || this.state.filter.searchName)

    return (
      <div className="admin-wrapper">
        <FuelModal/>
        <Row className="section-users-header">
          <Col sm={12}>
            <div className="header-panel">
              <h4>{t(`${tKey}FUEL_MASTERS.FUEL_MASTERS_TITLE`)}</h4>
            </div>
          </Col>
        </Row>
        <Row>
          <div className="search-form">
            <span className="title-search"><i className="ico-search" /> {t(`${tKey}FUEL_MASTERS.SEARCH_FUEL_MASTERS_TITLE`)}</span>
            <Row>
              <Field
                name="filters.name"
                colSm={2}
                controlLabel={t(`${tKey}FUEL_MASTERS.NAME`)}
                placeholder={t(`${tKey}FUEL_MASTERS.NAME`)}
                component={InputText}
                onInputChange={(value) => this.handleNameChange(value)}
              />
              <Field
                name="filters.description"
                colSm={2}
                controlLabel={t(`${tKey}FUEL_MASTERS.DESCRIPTION`)}
                placeholder={t(`${tKey}FUEL_MASTERS.DESCRIPTION`)}
                component={InputText}
                onInputChange={(value) => this.handleDescriptionChange(value)}
              />
              <Col sm={8} className="btn-wrapper">
                <Button
                  className={'btn-white btn-icon btn-input btn-white search-button'}
                  style={{ width: '100%' }}
                  onClick={() => this.searchFuel()}>
                  <i className="ico-search" />
                  {t(`${tKey}SEARCH_BTN`)}
                </Button>
                <Button
                  className={'btn-icon btn-input ' + (hasFilters ? 'btn-standard' : 'btn-cancel')}
                  onClick={() => {
                    this.props.initialize({})
                    this.resetFilters()
                  }}>
                  <i className="ico-cancel" />
                  {t(`${tKey}RESET_BUTTON`)}
                </Button>
              </Col>
            </Row>
          </div>
        </Row>
        <Col sm={12} className="col-row  section-users-body">
          <Table className="editable-table" hover responsive>
            <thead>
              <tr>
                <th onClick={() => { this.changeOrder('name') }}>
                  {t(`${tKey}FUEL_MASTERS.NAME`)}
                  <i className={this.getSortIcon('name')} />
                </th>
                <th onClick={() => { this.changeOrder('description') }}>
                  {t(`${tKey}FUEL_MASTERS.DESCRIPTION`)}
                  <i className={this.getSortIcon('description')} />
                </th>
                <th style={{ width: '77px' }}>{t('DOSSIER_COMPONENTS.ACCESSORIES.ACTIONS')}</th>
              </tr>
            </thead>
            <tbody>
              {fuelList && fuelList.map((fuel, idx) => (
                <tr key={idx} className={fuel.isDeleted ? 'is-deleted' : ''}>
                  <td>{fuel.name}</td>
                  <td>{fuel.description}</td>
                  <td>
                    {!fuel.isDeleted ? (
                      <div>
                        <a onClick={() => openEditFuelModal(fuel.fuelId)}>
                          <OverlayTrigger placement="left" overlay={editTooltip}>
                            <i className="ico-edit-white" />
                          </OverlayTrigger>
                        </a>
                        <a onClick={() => deleteFuel(fuel.fuelId)}>
                          <OverlayTrigger placement="left" overlay={deleteTooltip}>
                            <i className="ico-trash" />
                          </OverlayTrigger>
                        </a>
                      </div>
                    ) : (
                      <a onClick={() => recoverFuel(fuel.fuelId)}>
                        <OverlayTrigger placement="left" overlay={recoverTooltip}>
                          <Glyphicon glyph="repeat" />
                        </OverlayTrigger>
                      </a>
                    )}
                  </td>
                </tr>
              ))
              }
              {(fuelList === null || fuelList === undefined || fuelList.length === 0) &&
            <tr>
              <td colSpan={4}><h2>{t(`${tKey}FUEL_MASTERS.RESULTS_NOT_FOUND`)}</h2></td>
            </tr>}
              <tr className="button-table-wrapper">
                <td>
                  <Button
                    className="btn-editable-table btn-standard"
                    onClick={() => openEditFuelModal()}>
                  + {t('MASTERS.ADD')}
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>
          <div className="section-users-footer">
            <nav key={1} className="text-center">
              <IDocCarPagination
                id="btn_pag_sales"
                page={page}
                pagesCount={pagesCount}
                totalRows={fuelCount}
                onSelectAction={(value) => fetchFuelList({ ...filter, ...this.state.filter, page: value })}
              />
            </nav>
          </div>
        </Col>
      </div>
    )
  }
}

export default reduxForm({
  form: 'listFuel'
})(Fuel)
