import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { updateAgencyCommunicationServiceId } from '../../../../actions/masters/masters'
import ProcessingAgencyIntegrationGeneralMasterModal from '../../../../components/masters/processing/processingAgencyIntegrations/ProcessingAgencyIntegrationGeneralMasterModal'

export function mapStateToProps (state) {
  const processingAgencyModal = state.processingAgency.processingAgencyIntegrationModal
  return {
    agency: processingAgencyModal.agency,
    combos: {
      processingAgencyCommunicationServices: state.combos.processingAgencyCommunicationServices
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      updateAgencyCommunicationServiceId
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(ProcessingAgencyIntegrationGeneralMasterModal))
