import Settings from './settings'

export default {
  ...Settings,
  urlHotFolder: '',
  webSocketUrl: 'wss://test-cupra.idoccar.com:8006',
  notificationRefresh: 60000,
  showSignDocumentButton: false,
  acceptFileFormats: 'application/pdf, .jpg, .png, .jpeg, .gif, .docx, .doc, .xls, .xlsx, .ppt, .pptx, .msg, .tiff',
  qaSite: true,
  dealerMaxLength: 6,
  dealerNumberRegex: null,
  autoLoadDashboard: true,
  clientFieldValidation: 'demo',
  validateLicensePlate: true,
  maxTagCount: 2,
  maxTagTextLength: 10,
  matomoSiteId: '92',
  environmentName: 'test-cupra'
}
