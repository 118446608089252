
import React, { PureComponent } from 'react'
import { Modal, Col, Row, Button } from 'react-bootstrap'
import { reduxForm, Field } from 'redux-form'
import InputSelect from '../../commons/form/InputSelect'

class OrganizedUnitSaleOperationMasterModal extends PureComponent {
  constructor () {
    super()
    this.state = {}
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevProps.showModal !== this.props.showModal && this.props.showModal === true) {
      this.props.initialize(this.props.organizedUnitSaleOperation)
    }
  }

  submitDocument (values) {
    let languages = []
    Object.keys(values).map(item => {
      if (!!values[item] && typeof values[item] === 'object') {
        languages.push(values[item])
      }
    })
    const organizedUnitSaleOperation = {
      organizedUnitSaleOperationId: values.organizedUnitSaleOperationId,
      organizedUnitId: values.organizedUnitId,
      saleTypeId: values.saleTypeId,
      operationTypeId: values.operationTypeId
    }

    this.props.actions.submitOrganizedUnitSaleOperation(organizedUnitSaleOperation)
  }

  render () {
    const {
      t, handleSubmit,
      showModal,
      combos: { organizedUnitsAllCombo, saleTypeAllCombo, operationTypeAllCombo }
    } = this.props
    const tKey = 'MASTERS.OPERATION_TYPE_MASTERS.MODAL.'
    return (
      <Modal className="role-modal languages-modal" show={showModal} onHide={this.props.actions.closeOrganizedUnitSaleOperationMasterModal} backdrop={'static'}>
        <form autoComplete="off" onSubmit={handleSubmit(this.submitDocument.bind(this))}>
          <Modal.Header closeButton onHide={this.props.actions.closeOrganizedUnitSaleOperationMasterModal}>
            <Modal.Title>{t(`${tKey}TITLE`)}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="inputs-wrapper">
              <Col sm={4} className="custom-width-col">
                <h2>{t(`${tKey}OU`)}</h2>
                <Field
                  name="organizedUnitId"
                  placeholder={t(`${tKey}OU`)}
                  valueKey="id"
                  labelKey="value"
                  component={InputSelect}
                  colSm={0}
                  options={organizedUnitsAllCombo}
                />
              </Col>
              <Col sm={4} className="custom-width-col">
                <h2>{t(`${tKey}SALE_TYPE`)}</h2>
                <Field
                  name="saleTypeId"
                  placeholder={t(`${tKey}SALE_TYPE`)}
                  valueKey="id"
                  labelKey="value"
                  component={InputSelect}
                  colSm={0}
                  options={saleTypeAllCombo}
                />
              </Col>
              <Col sm={4} className="custom-width-col">
                <h2>{t(`${tKey}OPERATION_TYPE`)}</h2>
                <Field
                  name="operationTypeId"
                  placeholder={t(`${tKey}OPERATION_TYPE`)}
                  valueKey="id"
                  labelKey="value"
                  component={InputSelect}
                  colSm={0}
                  options={operationTypeAllCombo}
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Col sm={12} className="btn-wrapper">
              <Button
                id="role-modal-submit-button"
                className="btn-danger"
                type="reset"
                onClick={this.props.actions.closeOrganizedUnitSaleOperationMasterModal}
                disabled={this.props.pristine}>
                {t(`${tKey}CANCEL_BUTTON`)}
              </Button>
              <Button
                id="role-modal-submit-button"
                className="btn-standard"
                type="submit"
                disabled={this.props.pristine}>
                {t(`${tKey}SUBMIT_BUTTON`)}
              </Button>
            </Col>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default reduxForm({
  form: 'editOrganizedUnitSaleOperation',
  validate: (values, props) => {
    let error = {}
    const tKey = 'MASTERS.OPERATION_TYPE_MASTERS.VALIDATION.'
    if (!values.saleTypeId) {
      error.saleTypeId = props.t(`${tKey}SALE_TYPE`)
    }
    return Object.keys(error).length > 0 ? error : {}
  }
})(OrganizedUnitSaleOperationMasterModal)
