
import React, { PureComponent } from 'react'
import { Button, Modal, Col, Row } from 'react-bootstrap'
import { reduxForm, Field } from 'redux-form'
import InputSelect from '../../commons/form/InputSelect'

class DocumentVisualizationMasterModal extends PureComponent {
  constructor () {
    super()
    this.state = { }
  }

  submitDocumentVisualization (values) {
    var documentVisualization = {
      documentVisualizationId: values.documentVisualizationId,
      originDossierSubTypeId: values.originDossierSubTypeId,
      destDossierSubTypeId: values.destDossierSubTypeId,
      documentTypeUseId: values.documentTypeUseId
    }

    this.props.actions.submitDocumentVisualization(documentVisualization)
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevProps.showModal !== this.props.showModal && this.props.showModal === true) {
      this.props.initialize(this.props.documentVisualization)
    }
  }

  render () {
    const {
      t, showModal, handleSubmit,
      combos: { dossierSubtypesCombo, documentTypeUseCombo }
    } = this.props

    const tKey = 'MASTERS.DOCUMENT_VISUALIZATION.'
    return (
      <Modal show={showModal} onHide={this.props.actions.closeDocumentVisualizationMasterModal} className="languages-modal">
        <Modal.Header closeButton>
          <i className="ico-uo" /><Modal.Title>{t(`${tKey}DOCUMENT_VISUALIZATION`)}</Modal.Title>
        </Modal.Header>
        <form autoComplete="off" onSubmit={handleSubmit(this.submitDocumentVisualization.bind(this))}>
          <Modal.Body>
            <Row>
              <Col sm={6}>
                <Row>
                  <Field
                    name="originDossierSubTypeId"
                    placeholder={t(`${tKey}ORIGIN_SUBTYPE`)}
                    valueKey="id"
                    labelKey="value"
                    component={InputSelect}
                    colSm={0}
                    options={dossierSubtypesCombo}
                  />
                  <Field
                    name="destDossierSubTypeId"
                    placeholder={t(`${tKey}DESTINY_SUBTYPE`)}
                    valueKey="id"
                    labelKey="value"
                    component={InputSelect}
                    colSm={0}
                    options={dossierSubtypesCombo}
                  />
                  <Field
                    name="documentTypeUseId"
                    placeholder={t(`${tKey}TYPE_USE`)}
                    valueKey="id"
                    labelKey="value"
                    component={InputSelect}
                    colSm={0}
                    options={documentTypeUseCombo}
                  />
                </Row>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Col sm={6} className="btn-wrapper" smPush={6}>
              <Button type="submit" className="btn-standard">
                {t('MASTERS.SAVE')}
              </Button>
            </Col>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}
const validate = (values, props) => {
  const error = { }

  if (!values.originDossierSubTypeId) {
    error.originDossierSubTypeId = props.t('MASTERS.DOCUMENT_VISUALIZATION.VALIDATION.ORIGIN_SUBTYPE')
  }
  if (!values.destDossierSubTypeId) {
    error.destDossierSubTypeId = props.t('MASTERS.DOCUMENT_VISUALIZATION.VALIDATION.DESTINY_SUBTYPE')
  }
  if (!values.documentTypeUseId) {
    error.documentTypeUseId = props.t('MASTERS.DOCUMENT_VISUALIZATION.VALIDATION.TYPE_USE')
  }
  return error
}

export default reduxForm({
  form: 'documentVisualizationModal',
  validate
})(DocumentVisualizationMasterModal)
