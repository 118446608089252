import React, { PureComponent } from 'react'
import { OverlayTrigger, Button, Tooltip, Form } from 'react-bootstrap'
import { Field, reduxForm, reset } from 'redux-form'
import { validateDate, validateDates } from '../../../util/validationFunctions'
import { toDayString, convertStringToDate, differenceDaysDate, formatMoney, fromCamelToUnderscore, getDateNowMaxDatePicker } from '../../../util/utils'
import settings from '../../../setting'
/// /////////////////////////////////////////
import MapSectionsReport from '../../../_v2/util/map-sections-report'
import { showFilter, hideFiltersBtn, toggleFilterClass } from '../../../_v2/util/management/ManagementUtils'
import InputSelect from '../commons/form/InputSelect'
import InputDatePicker from '../commons/form/InputDatePicker'
import InputTreeSelect from '../commons/form/InputTreeSelect'
import InputNumber from '../commons/form/InputNumber'
import CommercialReportTableModalPage from '../../../_v2/containers/report/Modals/CommercialReportTableModalPage'

class SearchCommercialReport extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      fields: {},
      objective: 0,
      maxiTable: false
    }
  }

  componentDidMount () {
    this.configureReportCommercial()
    this.props.actions.fetchBrands()
  }

  validateDate (value, allValues, props) {
    const result = validateDate(value, props)
    return result === Object(result) ? undefined : result
  }

  handleClickReset (e) {
    if (e !== undefined) {
      e.preventDefault()
    }

    this.props.dispatch(reset('reportComercial'))
    this.state.dossierIdToArray = []
    this.setState({ onlyFleets: true, checkAll: false, dossierIdToArray: [], dossierData: [], fields: [] })
  }

  configureReportCommercial () {
    var today = new Date()
    var dd = '01'
    var mm = String(today.getMonth() + 1).padStart(2, '0')
    var yyyy = today.getFullYear()
    today = dd + '/' + mm + '/' + yyyy

    this.props.change('reportComercial', { dateFrom: today })
  }

  standardValidations (values) {
    var result = false
    const formValues = values.reportComercial
    const emptyFilterModal = 'mustFillFiltersReportCommercial'

    if (/^mustFillMinFilters(\d+)$/.test(emptyFilterModal) && emptyFilterModal) {
      const minFilters = parseInt(emptyFilterModal.match(/^mustFillMinFilters(\d+)$/)[1], 10)
      const valuesCount = formValues
        ? Object.keys(formValues).filter(item => formValues[item] !== null).length
        : 0
      result = !(valuesCount >= minFilters)
    }

    return result
  }

  submitSearchForm (values) {
    var customValidation = !values.reportComercial || !values.reportComercial.organizedUnits || !values.reportComercial.dateFrom
    var allEmptyFieldOrNoValues = this.standardValidations(values)
    if (allEmptyFieldOrNoValues || customValidation) {
      return this.props.actions.openModal('mustFillFiltersReportCommercial')
    }

    this.props.actions.fetchReportCommercial({ ...values.reportComercial, ...this.props.filters })
    this.setState({ dossierIdToArray: [], hideFilters: true, dynamicFilters: values, filterValues: { ...values.servicePreparationDelivery, ...this.props.filters } })

    this.props.actions.setFilters(values)
  }

  exportData () {
    var tKey = 'SEARCH.CATEGORIES.REPORT.'
    var body = {}

    var cells = []
    var values = []

    cells.push(this.props.t(`${tKey}PENDING_CANCELLATION`))
    values.push(this.props.reportData.pendingCancellation.length)

    cells.push(this.props.t(`${tKey}REGISTERED_PENDING_CANCELLATION`))
    values.push(this.props.reportData.registeredPendingCancellation.length)

    cells.push(this.props.t(`${tKey}INVOICED_PENDING_PLATE`))
    values.push(this.props.reportData.invoicedPendingPlate.length)

    cells.push(this.props.t(`${tKey}INVOICE_PENDING`))
    values.push(this.props.reportData.invoicePending.length)

    cells.push(this.props.t(`${tKey}DOSSIER_COLLECTION_STAGE`))
    values.push(this.props.reportData.dossierCollectionStage.length)

    cells.push(this.props.t(`${tKey}TOTAL_CLOSE`))
    values.push(this.props.reportData.totalClose.length)

    cells.push(this.props.t(`${tKey}OBJECTIVE`))
    values.push(this.state.objective ?? 0)

    cells.push(this.props.t(`${tKey}PENDING`))
    values.push((this.state.objective ?? 0) - this.props.reportData.totalClose.length)

    var today = toDayString()

    var dateFrom = this.props.fields.dateFrom !== null && this.props.fields.dateFrom !== undefined ? this.props.fields.dateFrom : today
    var dateTo = this.props.fields.dateTo !== null && this.props.fields.dateTo !== undefined ? this.props.fields.dateTo : this.props.t(`${tKey}NOW`)

    var title = this.props.t(`${tKey}TABLE_TITLE`) + ' ' + dateFrom + ' ' + this.props.t(`${tKey}TO`) + ' ' + dateTo

    body.cells = cells
    body.values = values
    body.title = title

    this.props.actions.fetchReportCommercialDownload(body)
  }

  fillObjective (value) {
    if (value) {
      this.setState({ objective: parseInt(value) })
    } else {
      this.setState({ objective: 0 })
    }
  }

  viewTable (val, title) {
    var filters = {
      page: 1,
      orderBy: '+dossier',
      pageSize: 10,
      dossierIds: val,
      title: title
    }
    this.props.actions.fetchReportCommercialTable(filters)
  }

  getDateTypeOptions () {
    const { t } = this.props
    const tKey = 'SEARCH.CATEGORIES.STOCKMANAGEMENT.DATE_TYPE_OPTIONS.'
    return [
      { id: '1', value: t(`${tKey}CREATION_DATE`) },
      { id: '2', value: t(`${tKey}RECEPTION_DATE`) },
      { id: '3', value: t(`${tKey}RATING_DATE`) },
      { id: '4', value: t(`${tKey}BUDGET_SENT_DATE`) },
      { id: '5', value: t(`${tKey}BUDGET_ACCEPTED_DATE`) },
      { id: '6', value: t(`${tKey}RECONDITIONING_CONFIRMATION_DATE`) }
    ]
  }

  extractMultipleComboStringArray (combo, filters, item) {
    let array = filters[item] ? filters[item].split(',').map(Number) : null
    const values = combo.filter(object => {
      return array ? array.some(selectedComboValue => object.id === selectedComboValue) : null
    })

    let value = values && this.formatMultipleValues(values)
    return value
  }

  formatMultipleValues (multiplecombovalues) {
    return multiplecombovalues
      .map((combovalue, index) => combovalue.value || combovalue.name)
      .join(', ')
  }

  treeComboToArray (combo) {
    var values = []
    combo.map((item, index) => {
      values.push({ id: item.value, value: item.label })
      if (item.children && item.children.length > 0) {
        values = values.concat(this.treeComboToArray(item.children))
      }
    })
    return values
  }

  extractMultipleCombo (combo, filters, item) {
    const values = combo.filter(object => {
      return filters[item] ? filters[item].some(selectedComboValue => object.id === selectedComboValue) : null
    })
    let value = values && this.formatMultipleValues(values)
    return value
  }

  selectedFilters () {
    const filters = this.props.dynamicFilters.reportComercial
    const tKey = 'SEARCH.CATEGORIES.REPORTCOMERCIAL.'
    return Object.keys(filters)
      .map((item) => {
        let text = item
        let value = null
        const list = []
        text = this.props.t(`${tKey}${fromCamelToUnderscore(item).toUpperCase()}`)
        switch (item) {
          case 'brands':
          {
            const brand = this.extractMultipleCombo(this.props.combos.brandCombo, filters, item)
            value = brand
            break }
          case 'organizedUnit':
          { const organizedUnit = this.props.combos.lowestOrganizedUnitsCombo.find(ou => ou.id === filters[item])
            value = organizedUnit && organizedUnit.value
            break }
          case 'organizedUnits':
          {
            value = this.extractMultipleComboStringArray(this.treeComboToArray(this.props.combos.UOTreeCombo), filters, item)
            break }
          case 'requestAmount':
          { value = formatMoney(filters[item])
            break }
          case 'amount':
          { value = formatMoney(filters[item])
            break }
          case 'dateTo':
          case 'dateFrom': {
            const dateType = this.getDateTypeOptions().find(x => x.id === filters[item].dateType)?.value ?? ''
            text = text.replace('#DATETYPE#', dateType)
            value = filters[item]
            break
          }
          default:
          { value = filters[item]
            break }
        }

        if (value) {
          list.push(<li key={item}>{text}: <strong>{value}</strong>;</li>)
        }

        return list
      })
  }

  toogleHideFilters () {
    this.setState({ hideFilters: !this.state.hideFilters })
  }

  toogleMaxiTable () {
    this.setState({ maxiTable: !this.state.maxiTable })
  }

  render () {
    const {
      t, combos: { UOTreeCombo, brandCombo }, fields, fieldFill, className, classNameSelect, hasSearch, reportData,
      handleSubmit // From search,
      // onClickResetSearch
    } = this.props
    const tKey = 'SEARCH.CATEGORIES.REPORT.'
    const secMapReport = MapSectionsReport.find(function (s) { return s.page === 'commercialReportPage' })
    var today = toDayString()

    return (
      <>
        <CommercialReportTableModalPage/>
        <div className="inner-results-panel">
          <div className="section-results-header">
            <div className="section-header-title">
              <OverlayTrigger placement="right" overlay={<Tooltip id="editProductTooltip">{`${t((this.props.hideMenu ? 'GLOBAL.TABLES.SHOW_INNER_MENU' : 'GLOBAL.TABLES.HIDE_INNER_MENU'))}`}</Tooltip>}>
                <a onClick={() => this.props.toogleInnerMenu()} className={this.props.hideMenu ? 'collapseBtn' : 'collapseBtn closeInnerMenu'}><i className={this.props.hideMenu ? 'iDocIcon-Open-inner-menu' : 'iDocIcon-Close-inner-menu'}></i></a>
              </OverlayTrigger>
              <h4>{t(`${secMapReport.title}`)}
                <OverlayTrigger placement="left" overlay={<Tooltip id="editProductTooltip">{`${t((this.state.hideFilters ? 'GLOBAL.TABLES.SHOW_FILTERS' : 'GLOBAL.TABLES.HIDE_FILTERS'))}`}</Tooltip>}>
                  <a onClick={() => this.toogleHideFilters()} className={'toogleShowFilters ' + hideFiltersBtn(hasSearch)}><i className={toggleFilterClass(hasSearch)}></i></a>
                </OverlayTrigger>
              </h4>
            </div>
            <Form autoComplete="off" onSubmit={handleSubmit((values) => this.submitSearchForm(values))} className="filters">
              <div className={'search-form ' + (showFilter(hasSearch, this.state.hideFilters) ? '' : 'hideFilters ')}>
                <Field
                  component={InputTreeSelect}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="OrganizedUnits"
                  name="reportComercial.organizedUnits"
                  placeholder={t(`${tKey}ORGANIZED_UNIT`)}
                  controlLabel={t(`${tKey}ORGANIZED_UNIT`)}
                  multi={true}
                  valueKey="value"
                  labelKey="label"
                  childrenKey="children"
                  options={UOTreeCombo}
                  onInputChange={(val) => { fieldFill({ organizedUnits: val }) } }
                  customClass={fields.organizedUnits ? classNameSelect : ''}
                />
                <Field
                  component={InputSelect}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="brands"
                  name='reportComercial.brands'
                  placeholder={t(`${tKey}BRAND`)}
                  controlLabel={t(`${tKey}BRAND`)}
                  options={brandCombo}
                  valueKey="id"
                  labelKey="value"
                  onInputChange={(val) => { fieldFill({ brands: val }) } }
                  customClass={fields.brands ? classNameSelect : ''}
                  multi={true}
                />
                <Field
                  component={InputDatePicker}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  maxDate={getDateNowMaxDatePicker()}
                  id="dateFrom"
                  name='reportComercial.dateFrom'
                  placeholder={t(`${tKey}DATE_FROM`)}
                  controlLabel={t(`${tKey}DATE_FROM`)}
                  validate={[this.validateDate]}
                  onInputChange={(val) => fieldFill({ dateFrom: val })}
                  customClass={fields.creationDateFrom ? className : ''}
                />
                <Field
                  component={InputDatePicker}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  maxDate={getDateNowMaxDatePicker()}
                  id="dateTo"
                  name='reportComercial.dateTo'
                  placeholder={t(`${tKey}DATE_TO`)}
                  controlLabel={t(`${tKey}DATE_TO`)}
                  validate={[this.validateDate]}
                  onInputChange={(val) => fieldFill({ dateTo: val })}
                  customClass={fields.creationDateTo ? className : ''}
                />
                <div className='search-form-actions flexInLine al-R'>
                  <Button id="btn_search"
                    type="submit"
                    className="_Btn accept stk">
                    <i className="iDocIcon-search"/>
                    {t('SEARCH.ACTIONS.SEARCH_BUTTON')}
                  </Button>
                  <Button
                    id="btn_reset"
                    onClick={(e) => this.handleClickReset(e)}
                    className={'_Btn' + (this.props.pristine ? ' accept' : ' accept')}
                    disabled={this.props.pristine}
                  >
                    <i className="iDocIcon-clear-decline"/> {t('SEARCH.ACTIONS.RESET_BUTTON')}
                  </Button>
                </div>
              </div>
            </Form>
          </div>
          <div className="section-results-body">
            <div className={'searchResoultsContainer' + (this.state.maxiTable ? ' maxiTable' : '')}>
              {hasSearch && ([
                <div className="table-wrapper" key="table-wrapper">
                  <ul className="list-inline pull-left">
                    {this.selectedFilters().length === 0 ? <li>{t(`${tKey}NO_SELECTED_FILTERS`)}</li> : (this.selectedFilters())}
                  </ul>
                  {/* table options, maximize, etc.... */}

                  <div className='flexInLine al-R'>
                    <div className='actionsField'>
                      <Field
                        component={InputNumber}
                        colWidht={100} // 25, 33, 50, 75, 100 default=25
                        name="objective"
                        // controlLabel={t(`${tKey}OBJECTIVE`)}
                        placeholder={t(`${tKey}OBJECTIVE`)}
                        valueKey="id"
                        labelKey="value"

                        onChange={(event, value) => this.fillObjective(value)}
                      />
                    </div>
                    <OverlayTrigger placement="left" overlay={<Tooltip id="editProductTooltip">{`${t((this.state.maxiTable ? 'GLOBAL.TABLES.MINIMIZE_TABLE' : 'GLOBAL.TABLES.MAXIMIZE_TABLE'))}`}</Tooltip>}>
                      <a onClick={() => this.toogleMaxiTable()} className='_Btn accept stk o-icn maximizeBtn'><i className={(this.state.maxiTable ? 'iDocIcon-Minimize' : 'iDocIcon-Maximize')}></i></a>
                    </OverlayTrigger>
                    <OverlayTrigger placement="left" overlay={<Tooltip id="editProductTooltip">{t('SEARCH.ACTIONS.EXPORT')}</Tooltip>}>
                      <a onClick={(event) => this.exportData(event)} className='_Btn new o-icn exportToExcel'><i className="iDocIcon-file-excel"></i></a>
                    </OverlayTrigger>
                  </div>

                  {/* table */}
                  <div className='table-responsive'>
                    {t(`${tKey}TABLE_TITLE`)} {this.props.fields.dateFrom ?? today} {t(`${tKey}TO`)} {this.props.fields.dateTo ?? t(`${tKey}NOW`)}
                    <div className='report'>
                      <div className='line PD' key={'rep_' + 1}>
                        <div className='title'>{t(`${tKey}PENDING_CANCELLATION`)}</div>
                        <div className='quantity'>{reportData.pendingCancellation.length}</div>
                        <div className='action'><a className="_Btn accept stk o-icn noLine" onClick={() => this.viewTable(reportData.pendingCancellation, t(`${tKey}PENDING_CANCELLATION`))}><i class="iDocIcon-open-in-new-black"></i></a></div>
                      </div>
                      <div className='line RPC' key={'rep_' + 2}>
                        <div className='title'>{t(`${tKey}REGISTERED_PENDING_CANCELLATION`)}</div>
                        <div className='quantity'>{reportData.registeredPendingCancellation.length}</div>
                        <div className='action'><a className="_Btn accept stk o-icn noLine" onClick={() => this.viewTable(reportData.registeredPendingCancellation, t(`${tKey}REGISTERED_PENDING_CANCELLATION`))}><i class="iDocIcon-open-in-new-black"></i></a></div>
                      </div>
                      <div className='line IPP' key={'rep_' + 3}>
                        <div className='title'>{t(`${tKey}INVOICED_PENDING_PLATE`)}</div>
                        <div className='quantity'>{reportData.invoicedPendingPlate.length}</div>
                        <div className='action'><a className="_Btn accept stk o-icn noLine" onClick={() => this.viewTable(reportData.invoicedPendingPlate, t(`${tKey}INVOICED_PENDING_PLATE`))}><i class="iDocIcon-open-in-new-black"></i></a></div>
                      </div>
                      <div className='line' key={'rep_' + 4}>
                        <div className='title'>{t(`${tKey}INVOICE_PENDING`)}</div>
                        <div className='quantity'>{reportData.invoicePending.length}</div>
                        <div className='action'><a className="_Btn accept stk o-icn noLine" onClick={() => this.viewTable(reportData.invoicePending, t(`${tKey}INVOICE_PENDING`))}><i class="iDocIcon-open-in-new-black"></i></a></div>
                      </div>
                      <div className='line' key={'rep_' + 5}>
                        <div className='title'>{t(`${tKey}DOSSIER_COLLECTION_STAGE`)}</div>
                        <div className='quantity'>{reportData.dossierCollectionStage.length}</div>
                        <div className='action'><a className="_Btn accept stk o-icn noLine" onClick={() => this.viewTable(reportData.dossierCollectionStage, t(`${tKey}DOSSIER_COLLECTION_STAGE`))}><i class="iDocIcon-open-in-new-black"></i></a></div>
                      </div>
                      <div className='line' key={'rep_' + 6}>
                        <div className='title'>{t(`${tKey}TOTAL_CLOSE`)}</div>
                        <div className='quantity'>{reportData.totalClose.length}</div>
                        <div className='action'><a className="_Btn accept stk o-icn noLine" onClick={() => this.viewTable(reportData.totalClose, t(`${tKey}TOTAL_CLOSE`))}><i class="iDocIcon-open-in-new-black"></i></a></div>
                      </div>
                      <div className='line' key={'rep_' + 7}>
                        <div className='title'>{t(`${tKey}OBJECTIVE`)}</div>
                        <div className='quantity'>{this.state.objective ?? 0}</div>
                        <div className='action'><a className="_Btn accept stk o-icn noLine" onClick={() => this.viewTable(reportData.pendingCancellation, t(`${tKey}PENDING_CANCELLATION`))}><i class="iDocIcon-open-in-new-black"></i></a></div>
                      </div>
                      <div className='line PEN' key={'rep_' + 8}>
                        <div className='title'>{t(`${tKey}PENDING`)}</div>
                        <div className='quantity'>{(this.state.objective ?? 0) - reportData.totalClose.length }</div>
                        <div className='action'></div>
                      </div>
                    </div>
                  </div>
                </div>
              ])}
            </div>
          </div>
        </div>
      </>
    )
  }
}

const validate = (values, { t }) => {
  const errors = {}
  if (values) {
    if (values.reportComercial && values.reportComercial.dateFrom) { // && values.reportComercial.dateTo) {
      var dateFrom = convertStringToDate(values.reportComercial.dateFrom)
      var dateTo
      if (values.reportComercial.dateTo) {
        dateTo = convertStringToDate(values.reportComercial.dateTo)
      } else {
        dateTo = new Date()
      }

      if (dateFrom > dateTo) {
        errors.reportComercial = { dateFrom: t('SEARCH.CATEGORIES.REPORT.DATE_FROM_GREATER') }
      } else {
        if (differenceDaysDate(dateFrom, dateTo) > 60) {
          errors.reportComercial = { dateFrom: t('SEARCH.CATEGORIES.REPORT.DATE_MAX_60') }
        }
      }
    }

    if (values.dossier && values.dossier.chassis && values.dossier.chassis.length < settings.chassisMinCharacter) {
      errors.dossier = {}
      errors.dossier.chassis = t('SEARCH.CATEGORIES.ERRORS.VINCHASIS').replace('##MIN_CHARACTERS##', settings.chassisMinCharacter)
    }
    if (values.vehicle && values.vehicle.chassis && values.vehicle.chassis.length < settings.chassisMinCharacter) {
      errors.vehicle = {}
      errors.vehicle.chassis = t('SEARCH.CATEGORIES.ERRORS.VINCHASIS').replace('##MIN_CHARACTERS##', settings.chassisMinCharacter)
    }

    if (values.dossier) {
      errors.dossier = {}
      if (values.dossier && values.dossier.creationDateFrom && values.dossier.creationDateTo) {
        errors.dossier.creationDateTo = validateDates(t, values.dossier.creationDateFrom, values.dossier.creationDateTo)
      }
      if (values.dossier && values.dossier.orderDateFrom && values.dossier.orderDateTo) {
        errors.dossier.orderDateTo = validateDates(t, values.dossier.orderDateFrom, values.dossier.orderDateTo)
      }
      if (values.dossier && values.dossier.availabilityDateFrom && values.dossier.availabilityDateTo) {
        errors.dossier.availabilityDateTo = validateDates(t, values.dossier.availabilityDateFrom, values.dossier.availabilityDateTo)
      }
      if (values.dossier && values.dossier.vehicleLicensePlateDateFrom && values.dossier.vehicleLicensePlateDateTo) {
        errors.dossier.vehicleLicensePlateDateTo = validateDates(t, values.dossier.vehicleLicensePlateDateFrom, values.dossier.vehicleLicensePlateDateTo)
      }
    }
  }
  return errors
}

export default reduxForm({
  form: 'reportComercial',
  validate
})(SearchCommercialReport)
