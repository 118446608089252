import React, { PureComponent } from 'react'
import { Panel } from 'react-bootstrap'
import { Field } from 'redux-form'
import { permissions } from '../../../../constants/backendIds'
import { getFieldsPermisions } from '../../../../util/permisionFunctions'
import InputSelect from '../../../../_v2/components/commons/form/InputSelect'
import InputText from '../../../../_v2/components/commons/form/InputText'
import CustomFields from '../../../../components/commons/customFields/CustomFields'
import ComponentTitle from '../../../../_v2/containers/dossiers/commons/ComponentTitlePage'

class Fleet extends PureComponent {
  constructor () {
    super()
    this.state = {
      open: false,
      fieldsConfiguration: {
        fleetId: null,
        description: null,
        fleetAssignmentDate: null,
        observations: null
      }
    }
  }

  toggleComponent () {
    if (this.state.open) {
      this.props.actions.setActiveSection(false)
    }
    this.setState({ open: !this.state.open })
  }

  componentDidMount () {
    this.initializePermisions(this.props)
    this.props.actions.fetchFleet(this.props.dossier.organizedUnitId, false, this.props.dossier.dossierSubTypeId)
  }

  componentDidUpdate () {}

  getSnapshotBeforeUpdate (prevProps) {
    if (this.props.open) {
      this.setState({ open: true })
    }
    if (prevProps.dossier.organizedUnitId !== this.props.dossier.organizedUnitId) {
      this.props.actions.fetchFleet(this.props.dossier.organizedUnitId, false, this.props.dossier.dossierSubTypeId)
    }
    return null
  }

  initializePermisions (props) {
    const fieldKeys = Object.keys(this.state.fieldsConfiguration)
    const newState = getFieldsPermisions(props.componentConfiguration.fieldsConfiguration, fieldKeys, props.componentConfiguration.permission)
    this.setState({ fieldsConfiguration: newState.fieldsConfiguration })
  }

  chargeFleet (fleetId) {
    var fleet = this.props.combos.fleets.filter((fleet) => fleet.fleetId === fleetId.fleetId)
    fleet = fleet.length > 0 ? fleet[0] : null
    if (fleet == null || this.validateClient(fleet)) {
      this.props.updateFleetSelected(fleet)
    } else {
      this.props.openErrorMessageModal('DOSSIER_COMPONENTS.VALIDATIONS.FLEET_CLIENT_NOT_VALID')
      if (this.props.dossier.dossierFleetComponent.fleetId === null || this.props.dossier.dossierFleetComponent.fleetId === undefined) {
        this.props.updateFleetSelected(false)
      } else {
        this.props.updateFleetSelected(this.props.dossier.dossierFleetComponent)
      }
    }
  }

  validateClient (fleet) {
    if (this.props.dossier.entityComponent === null || this.props.dossier.entityComponent.entityId == null) {
      return true
    }
    if (this.props.dossier.entityComponent.entityId === fleet.entityId) {
      return true
    }
    return false
  }

  render () {
    const {
      t,
      readOnlyFields
      , combos, customFields
    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.FLEET.'
    return (
      <>
        <div className="dossier-Component">
          <div className="header-panel" onClick={ () => this.toggleComponent()}>
            <ComponentTitle
              componentCode="FLEETS"
              componentOpen={this.state.open}
              // extraInfo={'info extra'}
              // extraData={<a className="_Btn accept">texto</a>}
            />
          </div>
          <Panel expanded={this.state.open} onToggle={() => {}}>
            <Panel.Collapse>
              <div className='fieldsContainer'></div>
              <div className='fieldsContainer'>
                {this.state.fieldsConfiguration.fleetId && this.state.fieldsConfiguration.fleetId.permission !== permissions.hidden && (
                  <Field
                    component={InputSelect}
                    colWidht={33} // 25, 33, 50, 75, 100 default=25
                    id="fleetId"
                    name="dossierFleetComponent.fleetId"
                    options={combos.fleets}
                    disabled={readOnlyFields || this.state.fieldsConfiguration.fleetId.permission === permissions.readOnly || (this.props.dossier.dossierFleetComponent.fleetId !== null && this.props.dossier.dossierFleetComponent.fleetId !== undefined)}
                    valueKey="fleetId"
                    labelKey="fleetCode"
                    onInputChange={(fleetId) => this.chargeFleet({ fleetId })}
                    controlLabel={t(`${tKey}FLEET`)}
                    placeholder={t(`${tKey}FLEET`)}
                  />
                )}
                {this.state.fieldsConfiguration.description && this.state.fieldsConfiguration.description.permission !== permissions.hidden && (
                  <Field
                    component={InputText}
                    colWidht={33} // 25, 33, 50, 75, 100 default=25
                    id="description"
                    name="dossierFleetComponent.description"
                    controlLabel={t(`${tKey}DESCRIPTION`)}
                    placeholder={t(`${tKey}DESCRIPTION`)}
                    disabled={true}
                  />
                )}
                {this.state.fieldsConfiguration.fleetAssignmentDate && this.state.fieldsConfiguration.fleetAssignmentDate.permission !== permissions.hidden && (
                  <Field
                    component={InputText}
                    colWidht={33} // 25, 33, 50, 75, 100 default=25
                    id="fleetAssignmentDate"
                    name="dossierFleetComponent.fleetAssignmentDate"
                    controlLabel={t(`${tKey}FLEET_ASSIGNMENT_DATE`)}
                    placeholder={t(`${tKey}FLEET_ASSIGNMENT_DATE`)}
                    disabled={true}
                  />
                )}
                {this.state.fieldsConfiguration.observations && this.state.fieldsConfiguration.observations.permission !== permissions.hidden && (
                  <Field
                    component={InputText}
                    colWidht={100} // 25, 33, 50, 75, 100 default=25
                    id="observations"
                    name="dossierFleetComponent.observations"
                    controlLabel={t(`${tKey}OBSERVATIONS`)}
                    placeholder={t(`${tKey}OBSERVATIONS`)}
                    disabled={true}
                    componentClass="textarea"
                  />
                )}
              </div>
              <CustomFields customFields={customFields} readOnlyFields={readOnlyFields} />
            </Panel.Collapse>
          </Panel>
        </div>
      </>
    )
  }
}
export default Fleet
