export function validCurrency (value, regexArray) {
  let valid = false
  regexArray.forEach(element => {
    valid = element.test(value) || valid
  })
  return valid
}

export function replaceCurrencySYmbol (value, symb) {
  return value.replace(/\./g, '').replace(',', '.').replace(symb, '').replace(/^(.*?\.\d{2})(\d*)/, '$1')
}
