import React, { PureComponent } from 'react'
import { Row, Button, Clearfix, Form, Col, Tooltip, OverlayTrigger, ProgressBar, Glyphicon } from 'react-bootstrap'
import { Field, reduxForm, reset } from 'redux-form'
import InputText from '../commons/form/InputText'
import { fromCamelToUnderscore } from '../../util/utils'
import SimpleTablePage from '../../containers/commons/general/SimpleTablePage'
import IDocCarPagination from '../commons/iDocCarPagination'

class FleetFinder extends PureComponent {
  constructor () {
    super()
    this.state = {
      fields: [],
      filters: [],
      dynamicFilters: [],
      disableResetButton: false,
      displayBar: 'none',
      progress: 0,
      progressFail: 0,
      errors: [],
      maxBar: 0
    }
  }

  componentDidMount () {
    this.props.actions.resetFiltersFleetInfo()
  }

  submitSearchForm (values) {
    let filters = values

    let propFilters = this.props.filters
    propFilters.orderBy = '%2BfleetCode'
    this.props.actions.fetchFleetsInfo({ ...filters, ...propFilters, organizedUnitId: this.props.organizedUnitId })
    this.state.dynamicFilters = filters
  }

  fieldFill (field) {
    this.setState({ fields: { ...this.state.fields, ...field } })
  }

  selectedFilters () {
    const filters = this.state.dynamicFilters
    const tKey = 'MANAGEMENT.FLEETS_TAB.'
    return Object.keys(filters)
      .map((item, index) => {
        let text = item
        let value = null
        const list = []
        text = this.props.t(`${tKey}${fromCamelToUnderscore(item).toUpperCase()}`)
        value = filters[item]
        if (value) {
          list.push(<li key={index}>{text}: <strong>{value}</strong></li>)
        }
        return list
      })
  }

  onClickResetSearch (e) {
    if (e !== undefined) {
      e.preventDefault()
    }

    this.props.actions.resetFiltersFleetInfo()
  }

  handleClickReset (e) {
    if (e !== undefined) {
      e.preventDefault()
    }

    this.props.dispatch(reset('fleetFinder'))
  }

  exportData () {
    const tKey = 'MANAGEMENT.FLEETS_TAB.'
    let columnsExcel = []

    columnsExcel.push(this.props.t(`${tKey}FLEET`) + '#FleetCode')
    columnsExcel.push(this.props.t(`${tKey}DESCRIPTION`) + '#Description')
    columnsExcel.push(this.props.t(`${tKey}OBSERVATIONS`) + '#Observations')
    columnsExcel.push(this.props.t(`${tKey}ORGANIZEDUNIT`) + '#OrganizedUnit')
    columnsExcel.push(this.props.t(`${tKey}NUM_DOSSIERS`) + '#Dossiers')

    let cols = ''
    columnsExcel.forEach(element => {
      cols += element + '$'
    })
    cols = cols.substring(0, cols.length - 1)

    let filters = this.state.dynamicFilters
    this.props.actions.fetchFleetsInfoDownload({ ...filters, ...this.props.filters, organizedUnitId: this.props.organizedUnitId }, cols)
  }

  onOrderChange (e) {
    const newOrderBy = this.props.filters.orderBy.includes(`%2B${e.currentTarget.dataset.field}`) ? `-${e.currentTarget.dataset.field}` : `%2B${e.currentTarget.dataset.field}`
    this.fetchTable({ ...this.props.filters, orderBy: newOrderBy })
  }

  onPageChange (page) {
    this.fetchTable({ ...this.props.filters, page })
  }

  getIconClass (field) {
    if (this.props.filters.orderBy.includes(field)) {
      return this.props.filters.orderBy.includes('-') ? 'ico-sort-selected-asc' : 'ico-sort-selected-desc'
    } else {
      return 'ico-sort'
    }
  }

  fetchTable (propFilters) {
    let filters = this.state.dynamicFilters
    this.props.actions.fetchFleetsInfo({ ...filters, ...propFilters, organizedUnitId: this.props.organizedUnitId })
    this.state.dynamicFilters = filters
  }

  deleteFleet (value) {
    if (value.dossiers > 0) {
      this.props.actions.openModal('actionDeleteFleetInfo')
    } else {
      new Promise((resolve, reject) => {
        this.props.actions.deleteFleet(value.fleetId, resolve, reject)
      }).then((response) => {
        if (response) {
          this.fetchTable({ ...this.props.filters })
          this.props.actions.fetchFleetsUser(false)
        }
      }).catch(() => {
        this.props.actions.openModal('actionAdminFleetExpFleetError')
      })
    }
  }

  editFleet (value) {
    this.props.actions.openNewFleetModal(false, null, value.organizedUnitId, value, null)
  }

  assignFleet (value) {
    if (this.dossiersIsFleet()) {
      this.props.actions.openModal('actionAdminFleetExpYetFleet', () => this.assignDossierFleet(value.fleetId))
    } else {
      this.assignDossierFleet(value.fleetId)
    }
  }

  assignDossierFleet (fleetId) {
    this.props.actions.setActionExpFleetProcess(this.props.dossiers.map(d => d.dossierId).join('$'), 1, fleetId, () => { this.props.actions.closeActionFleetFinder(); this.props.fetchFleets(this.props.fleetsFilter) })
  }

  launchNextassignDossierFleet (index, fleetId) {
    if (this.props.dossiers[index]) {
      new Promise((resolve, reject) => {
        return this.props.actions.asignDossierToFleet(this.props.dossiers[index].dossierId, fleetId, resolve, reject)
      }).then((response) => {
        this.setState({ progress: this.state.progress + 1 })
        this.launchNextassignDossierFleet(index + 1, fleetId)
      }).catch((error) => {
        this.setState({ errors: [...this.state.errors, error.json.message] })
        this.setState({ progressFail: this.state.progressFail + 1 })
        this.launchNextassignDossierFleet(index + 1, fleetId)
      })
    } else {
      let errors = ''
      this.state.errors.forEach(x => {
        var errormessage = this.props.t('MESSAGES.ACTION_FLEET_ENTITY_ERROR')
        var ob = JSON.parse(x)
        for (var key in ob) {
          errormessage = errormessage.replace('##' + key + '##', ob[key])
        }
        errors = errors + '\n' + errormessage
      })

      this.props.actions.openModal('actionAdminFleetExp', () => { this.props.actions.closeActionFleetFinder(); this.props.fetchFleets(this.props.fleetsFilter) }, null, null, null, [{ id: '##NUM_OK##', value: this.state.progress }, { id: '##NUM_TOTAL##', value: this.props.dossiers.length }, { id: '##ERRORS##', value: errors }], null)
    }
  }

  dossiersIsFleet () {
    let dossiers = this.props.dossiers
    let isFleet = false
    dossiers.forEach(x => {
      if (x.fleetCode !== null && x.fleetCode !== undefined) {
        isFleet = true
      }
    })
    return isFleet
  }

  render () {
    const {
      t, className, count, dossierData, filters: { page, pages }, handleSubmit, hasSearch
    } = this.props
    const tKey = 'MANAGEMENT.FLEETS_TAB.'

    const deleteTooltip = (<Tooltip id="deleteTooltip">{t(`${tKey}DELETE_FLEET`)}</Tooltip>)
    const editTooltip = (<Tooltip id="editTooltip">{t(`${tKey}EDIT_FLEET`)}</Tooltip>)
    const asignTooltip = (<Tooltip id="asignTooltip">{t(`${tKey}ASIGN_FLEET`)}</Tooltip>)

    return (
      <>
        <Form autoComplete="off" onSubmit={handleSubmit((values) => this.submitSearchForm(values))} className="filters">
          <div>
            {!hasSearch ? (
              <Row>

                <Field
                  id="fleetCode"
                  name='fleetCode'
                  placeholder={t(`${tKey}FLEET`)}
                  controlLabel={t(`${tKey}FLEET`)}
                  component={InputText}
                  type="text"
                  onInputChange={(val) => this.fieldFill({ fleetCode: val })}
                  customClass={this.state.fields.fleetCode ? className : ''}
                />
                <Field
                  id="description"
                  name='description'
                  placeholder={t(`${tKey}DESCRIPTION`)}
                  controlLabel={t(`${tKey}DESCRIPTION`)}
                  component={InputText}
                  type="text"
                  onInputChange={(val) => this.fieldFill({ description: val })}
                  customClass={this.state.fields.description ? className : ''}
                />

              </Row>
            ) : ([
              <Row className="filters-selected" key="filters-selected">
                <ul className="list-inline pull-left">
                  {this.selectedFilters().length === 0 ? <li>{t(`${tKey}NO_SELECTED_FILTERS`)}</li> : (this.selectedFilters())}
                </ul>

                <Clearfix/>
                <Col sm={3} className="row-space">
                  <Button
                    onClick={(event) => this.onClickResetSearch(event)}
                    bsStyle="default"
                    bsSize="small"
                    className="pull-left"
                  >
                    {t('SEARCH.ACTIONS.MODIFY_SEARCH')}
                    <i className="ico-edit-turquoise" />
                  </Button>
                  {count > 0 &&
                    <>
                      <Button
                        onClick={(event) => this.exportData(event)}
                        bsStyle="default"
                        bsSize="small"
                        className="button-search-left">
                        {t('SEARCH.ACTIONS.EXPORT')}
                        <i className="ico-if_Download_1031520"/>
                      </Button>

                    </>
                  }
                </Col>
              </Row>,
              <div className="table-wrapper" key="table-wrapper">
                <SimpleTablePage
                  columns={[
                    <th key={0} data-field="fleetCode" onClick={(event) => this.onOrderChange(event)}>
                      {t(`${tKey}FLEET`)}&nbsp;<i className={this.getIconClass('fleetCode')}></i>
                    </th>,
                    <th key={1} data-field="description" onClick={(event) => this.onOrderChange(event)}>
                      {t(`${tKey}DESCRIPTION`)}&nbsp;<i className={this.getIconClass('description')}></i>
                    </th>,
                    <th key={2} data-field="observations" onClick={(event) => this.onOrderChange(event)}>
                      {t(`${tKey}OBSERVATIONS`)}&nbsp;<i className={this.getIconClass('observations')}/>
                    </th>,
                    <th key={3} data-field="organizedUnit" onClick={(event) => this.onOrderChange(event)}>
                      {t(`${tKey}ORGANIZEDUNIT`)}&nbsp;<i className={this.getIconClass('organizedUnit')}></i>
                    </th>,
                    <th key={4} data-field="dossiers" onClick={(event) => this.onOrderChange(event)}>
                      {t(`${tKey}NUM_DOSSIERS`)}&nbsp;<i className={this.getIconClass('dossiers')}/>
                    </th>,
                    <th key={12} data-field="actions">{t(`${tKey}ACTIONS`)}&nbsp;</th>
                  ]}
                  rows={dossierData && dossierData.map((row, idx) => (
                    <tr key={idx}>
                      <td>
                        {row.fleetCode}
                      </td>
                      <td>
                        {row.description}
                      </td>
                      <td>{row.observations !== null && row.observations !== undefined ? row.observations.length < 70 ? row.observations
                        : <> {row.observations.substring(0, 70)}... <OverlayTrigger trigger={['hover', 'focus']} placement="bottom" overlay={<Tooltip id="tooltip-trigger-hover-focus">{row.observations}</Tooltip>}>
                          <Glyphicon glyph="info-sign" className="tooltip-function"></Glyphicon>
                        </OverlayTrigger> </> : '' }</td>
                      <td>
                        {row.organizedUnit}
                      </td>
                      <td>
                        {row.dossiers}
                      </td>
                      <td>
                        {!this.props.showModal && (
                          <div className="fleet-actions">
                            <a onClick={() => this.editFleet(row)}>
                              <OverlayTrigger placement="left" overlay={editTooltip}>
                                <i className="ico-edit-white" />
                              </OverlayTrigger>
                            </a>
                            <a onClick={() => this.deleteFleet(row)}>
                              <OverlayTrigger placement="left" overlay={deleteTooltip}>
                                <i className="ico-trash" />
                              </OverlayTrigger>
                            </a>
                          </div>)}
                        {this.props.showModal && (
                          <div className="fleet-actions">
                            <a onClick={() => this.assignFleet(row)}>
                              <OverlayTrigger placement="right" overlay={asignTooltip}>
                                <i className="ico-plus" />
                              </OverlayTrigger>
                            </a>
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
                />
              </div>,
              <div className="search-footer" key="search-footer">
                <IDocCarPagination
                  id="btn_pag_sales"
                  page={page}
                  pagesCount={pages}
                  totalRows= {count}
                  onSelectAction={(page) => this.onPageChange(page)}
                />
              </div>
            ])}
            {!hasSearch && (
              <Row>
                <Col sm={12} className="text-center">
                  <hr/>
                  <Button
                    id="btn_search"
                    type="submit"
                    className="btn-standard"
                  >
                    <i className="ico-search"/>
                    {t('SEARCH.ACTIONS.SEARCH_BUTTON')}
                  </Button>
                  <Button
                    id="btn_reset"
                    onClick={(e) => this.handleClickReset(e)}
                    className={'btn-white btn-icon' + (this.props.pristine ? ' btn-cancel' : ' btn-standard')}
                    disabled={this.props.pristine}
                  >
                    <i className="ico-cancel"/> {t('SEARCH.ACTIONS.RESET_BUTTON')}
                  </Button>
                  <br></br>
                  <br></br>
                </Col>
              </Row>
            )}
            <ProgressBar style={{ display: this.state.displayBar }}>
              <ProgressBar max={this.state.maxBar} now={this.state.progress} label={`${this.state.progress}/${this.state.maxBar}`} striped variant="success" key={1} />
              <ProgressBar max={this.state.maxBar} now={this.state.progressFail} label={`${this.state.progressFail}/${this.state.maxBar}`} striped variant="danger" style={{ backgroundColor: '#d44b4b' }} key={2} />
            </ProgressBar>
          </div>
        </Form>
      </>
    )
  }
}

export default reduxForm({
  form: 'fleetFinder'
})(FleetFinder)
