import documentManagementModalActionTypes from '../../../constants/actions/dossier/common/documentManagementModal'

export function openDocumentManagementModal (dossierId, dossierType, dossierWithoutChanges, defaultTab, historical) {
  return {
    type: documentManagementModalActionTypes.OPEN_DOCUMENT_MANAGEMENT_MODAL,
    dossierId,
    dossierType,
    dossierWithoutChanges,
    defaultTab,
    historical
  }
}

export function openDocumentManagementModalSuccess (sectionsDocuments, dossierId, dossierType, defaultTab, serviceData) {
  return {
    type: documentManagementModalActionTypes.OPEN_DOCUMENT_MANAGEMENT_MODAL_SUCCESS,
    sectionsDocuments,
    dossierId,
    dossierNumber: serviceData.dossierNumber,
    entityId: serviceData.entityId,
    entityDs: serviceData.entityDs,
    entityProviderId: serviceData.entityProviderId,
    entityProviderDs: serviceData.entityProviderDs,
    vehicleId: serviceData.vehicleId,
    vehicleDs: serviceData.vehicleDs,
    dossierType,
    dossierSubType: serviceData.dossierSubType,
    organizedUnit: serviceData.organizedUnit,
    stage: serviceData.stage,
    toUpload: serviceData.toUpload,
    toValidate: serviceData.toValidate,
    validated: serviceData.validated,
    tab1ToUpload: serviceData.tab1ToUpload,
    tab1ToValidate: serviceData.tab1ToValidate,
    tab1Validated: serviceData.tab1Validated,
    tab2ToUpload: serviceData.tab2ToUpload,
    tab2ToValidate: serviceData.tab2ToValidate,
    tab2Validated: serviceData.tab2Validated,
    tab3ToUpload: serviceData.tab3ToUpload,
    tab3ToValidate: serviceData.tab3ToValidate,
    tab3Validated: serviceData.tab3Validated,
    tab4ToUpload: serviceData.tab4ToUpload,
    tab4ToValidate: serviceData.tab4ToValidate,
    tab4Validated: serviceData.tab4Validated,
    tab5ToUpload: serviceData.tab5ToUpload,
    tab5ToValidate: serviceData.tab5ToValidate,
    tab5Validated: serviceData.tab5Validated,
    defaultTab
  }
}

export function refreshDocumentManagementModal (dossierId, historical) {
  return {
    type: documentManagementModalActionTypes.REFRESH_DOCUMENT_MANAGEMENT_MODAL,
    dossierId,
    historical
  }
}

export function refreshDocumentManagementModalSuccess (sectionsDocuments, dossierId, serviceData) {
  return {
    type: documentManagementModalActionTypes.REFRESH_DOCUMENT_MANAGEMENT_MODAL_SUCCESS,
    sectionsDocuments,
    dossierId,
    dossierNumber: serviceData.dossierNumber,
    entityId: serviceData.entityId,
    entityDs: serviceData.entityDs,
    entityProviderId: serviceData.entityProviderId,
    entityProviderDs: serviceData.entityProviderDs,
    vehicleId: serviceData.vehicleId,
    vehicleDs: serviceData.vehicleDs,
    dossierSubType: serviceData.dossierSubType,
    organizedUnit: serviceData.organizedUnit,
    stage: serviceData.stage,
    toUpload: serviceData.toUpload,
    toValidate: serviceData.toValidate,
    validated: serviceData.validated,
    tab1ToUpload: serviceData.tab1ToUpload,
    tab1ToValidate: serviceData.tab1ToValidate,
    tab1Validated: serviceData.tab1Validated,
    tab2ToUpload: serviceData.tab2ToUpload,
    tab2ToValidate: serviceData.tab2ToValidate,
    tab2Validated: serviceData.tab2Validated,
    tab3ToUpload: serviceData.tab3ToUpload,
    tab3ToValidate: serviceData.tab3ToValidate,
    tab3Validated: serviceData.tab3Validated,
    tab4ToUpload: serviceData.tab4ToUpload,
    tab4ToValidate: serviceData.tab4ToValidate,
    tab4Validated: serviceData.tab4Validated,
    tab5ToUpload: serviceData.tab5ToUpload,
    tab5ToValidate: serviceData.tab5ToValidate,
    tab5Validated: serviceData.tab5Validated
  }
}

export function closeDocumentModalCallbacks () {
  return {
    type: documentManagementModalActionTypes.CLOSE_DOCUMENT_MANAGEMENT_MODAL_CALLBACKS
  }
}

export function closeDocumentModal () {
  return {
    type: documentManagementModalActionTypes.CLOSE_DOCUMENT_MANAGEMENT_MODAL
  }
}

export function cancelDocument (documentId, documentEntityType) {
  return {
    type: documentManagementModalActionTypes.CANCEL_DOCUMENT,
    documentId,
    documentEntityType
  }
}

export function addDependant (documentId, documentEntityType) {
  return {
    type: documentManagementModalActionTypes.ADD_DEPENDANT,
    documentId,
    documentEntityType
  }
}

export function printCheckList (checkListId, formValues = null) {
  return {
    type: documentManagementModalActionTypes.PRINT_CHECK_LIST,
    checkListId,
    formValues
  }
}

export function dossierDocumentChanged () {
  return {
    type: documentManagementModalActionTypes.DOSSIER_DOCUMENT_CHANGED
  }
}

export function entityDocumentChanged () {
  return {
    type: documentManagementModalActionTypes.ENTITY_DOCUMENT_CHANGED
  }
}

export function vehicleDocumentChanged () {
  return {
    type: documentManagementModalActionTypes.VEHICLE_DOCUMENT_CHANGED
  }
}

export function setCurrentTabCounterValues (counterValues, activeTab) {
  return {
    type: documentManagementModalActionTypes.SET_CURRENT_TAB_COUNTER_VALUES,
    counterValues,
    activeTab
  }
}

export default {
  openDocumentManagementModal,
  openDocumentManagementModalSuccess,
  refreshDocumentManagementModal,
  refreshDocumentManagementModalSuccess,
  closeDocumentModal,
  closeDocumentModalCallbacks,
  cancelDocument,
  addDependant,
  dossierDocumentChanged,
  entityDocumentChanged,
  vehicleDocumentChanged,
  setCurrentTabCounterValues
}
