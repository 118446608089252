import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getFormValues } from 'redux-form'
import ReassingPurchaseModal from '../../../components/dossiers/common/ReassingPurchaseModal'
import { translate } from 'react-polyglot'
import reassignReplacePurchaseModalActions from '../../../actions/dossier/common/reassignReplacePurchaseModal'
import {
  fetchCarlineCombo, fetchCatalogueCombo, fetchModelCombo, fetchColorCombo, fetchTapestryCombo,
  resetCarlineCombo, resetCatalogueCombo, resetColorCombo, resetTapestryCombo
} from '../../../actions/combos/combos'

export function mapStateToProps (state) {
  return {
    formValues: getFormValues('reassingPurchaseModal')(state)
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      ...reassignReplacePurchaseModalActions,
      fetchCarlineCombo,
      fetchCatalogueCombo,
      fetchModelCombo,
      fetchColorCombo,
      fetchTapestryCombo,
      resetCarlineCombo,
      resetCatalogueCombo,
      resetColorCombo,
      resetTapestryCombo
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(ReassingPurchaseModal))
