import React, { PureComponent } from 'react'
import { Modal, Col, Row, Button } from 'react-bootstrap'
import { reduxForm, Field } from 'redux-form'
import InputText from '../../commons/form/InputText'
import InputNumber from '../../commons/form/InputNumber'
import InputDecimal from '../../commons/form/InputDecimal'
import InputCheckBox from '../../commons/form/InputCheckBox'
import InputSelect from '../../commons/form/InputSelect'

class OrganizedUnitConfigurationMasterModal extends PureComponent {
  constructor () {
    super()
    this.state = {
      type: null,
      values: null,
      deleted: false
    }
    this.required = this.required.bind(this)
  }

  submitDocument (values) {
    let value = values.value

    if (this.state.type === 'List<String>' || this.state.type === 'List<Int>') {
      value = values.value.filter(x => x.length > 0).join(',')
    }

    const organizedUnitConfiguration = {
      organizedUnitConfigurationId: values.organizedUnitConfigurationId,
      organizedUnitId: values.organizedUnitId,
      configParameterId: values.configParameterId,
      value: value
    }

    this.props.actions.submitOrganizedUnitConfiguration(organizedUnitConfiguration)
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevProps.showModal !== this.props.showModal && this.props.showModal === true) {
      this.props.initialize(this.props.organizedUnitConfiguration)
      this.setState({
        organizedUnitConfiguration: this.props.organizedUnitConfiguration,
        deleted: false,
        values: 1,
        type: this.props.organizedUnitConfiguration?.configParameter?.type
      })
    }
    if (!this.state.deleted && this.props.organizedUnitConfiguration !== null && (this.props.organizedUnitConfiguration?.value)?.length > this.state.values) {
      const count = (this.props.organizedUnitConfiguration?.value)?.length
      this.setState({ values: count })
    }
  }

  changeType (value) {
    this.props.combos.configParameterAllCombo.map(configParameter => {
      if (configParameter.id === value) {
        this.setState({ type: configParameter.code })
        if (this.state.organizedUnitConfiguration !== undefined) {
          let organizedUnitConfiguration = this.state.organizedUnitConfiguration
          organizedUnitConfiguration.value = ''

          if (configParameter.code === 'List<String>' || configParameter.code === 'List<Int>') {
            this.deleteAll()
            this.addElement()
            organizedUnitConfiguration.value = []
          }
          this.setState({
            organizedUnitConfiguration: organizedUnitConfiguration,
            values: 0
          })
        }
      }
    })
  }

  addElement () {
    this.setState({ values: this.state.values + 1 })
  }

  deleteAll (e) {
    if (this.state.organizedUnitConfiguration !== undefined) {
      let organizedUnitConfiguration = this.state.organizedUnitConfiguration
      organizedUnitConfiguration.value = []
      this.setState({
        values: 1,
        deleted: true,
        organizedUnitConfiguration: organizedUnitConfiguration
      })
    } else {
      this.resetForm()
    }
  }

  resetForm () {
    const { reset } = this.props
    reset()
    this.setState({
      values: 1,
      deleted: true,
      type: null
    })
  }

  required (value) {
    if (value || value === 0) {
      return undefined
    } else {
      return this.props.t('MASTERS.ORGANIZED_UNIT_CONFIGURATION_MASTERS.VALIDATION.VALUE_REQUIRED')
    }
  }

  render () {
    const {
      t, handleSubmit,
      showModal,
      combos: { organizedUnitsAllCombo, configParameterAllCombo }
    } = this.props
    const tKey = 'MASTERS.ORGANIZED_UNIT_CONFIGURATION_MASTERS.MODAL.'
    return (
      <Modal className="role-modal languages-modal" show={showModal} onHide={this.props.actions.closeOrganizedUnitConfigurationMasterModal} backdrop={'static'}>
        <form autoComplete="off" onSubmit={handleSubmit(this.submitDocument.bind(this))} onReset={this.handleFormReset}>
          <Modal.Header closeButton onHide={this.props.actions.closeOrganizedUnitConfigurationMasterModal}>
            <Modal.Title>{t(`${tKey}TITLE`)}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="inputs-wrapper">
              <Col sm={5} className="custom-width-col">
                <h2>{t(`${tKey}OU`)}</h2>
                <Field
                  name="organizedUnitId"
                  placeholder={t(`${tKey}OU`)}
                  valueKey="id"
                  labelKey="value"
                  component={InputSelect}
                  colSm={0}
                  options={organizedUnitsAllCombo}
                />
              </Col>
              <Col sm={6} className="custom-width-col">
                <h2>{t(`${tKey}CONFIG_PARAMETER`)}</h2>
                <Field
                  name="configParameterId"
                  placeholder={t(`${tKey}CONFIG_PARAMETER`)}
                  valueKey="id"
                  labelKey="value"
                  component={InputSelect}
                  colSm={0}
                  options={configParameterAllCombo}
                  onInputChange={(value) => this.changeType(value)}
                  disabled={this.state.type !== null && this.state.type !== undefined}
                />
              </Col>
              <Col sm={1} className="custom-width-col">
                <span onClick={() =>
                  this.resetForm()
                }><i className="ico-trash"/></span>
              </Col>
            </Row>
            {(this.state.type === 'String') && (
              <Row className="inputs-wrapper">
                <Col sm={12} className="custom-width-col">
                  <h2>{t(`${tKey}VALUE`)}</h2>
                  <Field
                    name="value"
                    placeholder={t(`${tKey}VALUE`)}
                    component={InputText}
                    validate={[this.required]}
                  />
                </Col>
              </Row>
            )}
            {(this.state.type === 'int') && (
              <Row className="inputs-wrapper">
                <Col sm={12} className="custom-width-col">
                  <h2>{t(`${tKey}VALUE`)}</h2>
                  <Field
                    name="value"
                    placeholder={t(`${tKey}VALUE`)}
                    component={InputNumber}
                    validate={[this.required]}
                  />
                </Col>
              </Row>
            )}
            {(this.state.type === 'Decimal') && (
              <Row className="inputs-wrapper">
                <Col sm={12} className="custom-width-col">
                  <h2>{t(`${tKey}VALUE`)}</h2>
                  <Field
                    name="value"
                    placeholder={t(`${tKey}VALUE`)}
                    component={InputDecimal}
                    validate={[this.required]}
                  />
                </Col>
              </Row>
            )}
            {(this.state.type === 'Bool') && (
              <Row className="inputs-wrapper">
                <Col sm={12} className="custom-width-col">
                  <h2>{t(`${tKey}VALUE`)}</h2>
                  <Field
                    name="value"
                    placeholder={t(`${tKey}VALUE`)}
                    component={InputCheckBox}
                  />
                </Col>
              </Row>
            )}
            {(this.state.type === 'List<String>') && new Array(this.state.values).fill(undefined).map((val, idx) => {
              return (
                <Row key={idx} className="inputs-wrapper" >
                  <Col sm={12} className="custom-width-col">
                    <h2>{t(`${tKey}VALUE`)}</h2>
                    <Field
                      name={`value[${idx}]`}
                      placeholder={t(`${tKey}VALUE`)}
                      component={InputText}
                    />
                  </Col>
                </Row>
              )
            }
            )}
            {(this.state.type === 'List<Int>') && new Array(this.state.values).fill(undefined).map((val, idx) => {
              return (
                <Row key={idx} className="inputs-wrapper">
                  <Col sm={12} className="custom-width-col">
                    <h2>{t(`${tKey}VALUE`)}</h2>
                    <Field
                      name={`value[${idx}]`}
                      placeholder={t(`${tKey}VALUE`)}
                      component={InputNumber}
                    />
                  </Col>
                </Row>
              )
            })}
            {(this.state.type === 'List<String>' || this.state.type === 'List<Int>') && (
              <Row className="inputs-wrapper">
                <Col sm={2} className="custom-width-col">
                  <Button
                    className="btn-editable-table btn-standard"
                    onClick={() => this.addElement()}>
                  + {t(`${tKey}ADD`)}
                  </Button>
                </Col>
              </Row>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Col sm={12} className="btn-wrapper">
              <Button
                id="role-modal-submit-button"
                className="btn-standard"
                type="submit"
                disabled={this.props.pristine}>
                {t(`${tKey}SUBMIT_BUTTON`)}
              </Button>
            </Col>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default reduxForm({
  form: 'editOrganizedUnitConfiguration',
  validate: (values, props) => {
    let error = {}
    const tKey = 'MASTERS.ORGANIZED_UNIT_CONFIGURATION_MASTERS.VALIDATION.'
    if (!values.configParameterId) {
      error.configParameterId = props.t(`${tKey}CONFIG_PARAMETER_REQUIRED`)
    }
    return Object.keys(error).length > 0 ? error : {}
  }
})(OrganizedUnitConfigurationMasterModal)
