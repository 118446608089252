import React, { PureComponent } from 'react'
import { /* Row,  Clearfix,  Col, */ Form, Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Field, reduxForm } from 'redux-form'

import { validateDate } from '../../../util/validationFunctions'
import SimpleTablePage from '../../../containers/commons/general/SimpleTablePage'
import IDocCarPagination from '../../../components/commons/iDocCarPagination'
import { unattendedResultClassNames, unattendedStatusClassNames, unnatendedResultToolTip } from '../../../constants/management/unattendedClassNames'
import { unattendedResult } from '../../../constants/management/unattendedStatus'
import UnattendedProcessDownloadFileModalPage from '../../../containers/management/auxModals/UnattendedProcessDownloadFileModalPage'
import { upperToLowerCamelCase, fromCamelToUnderscore, convertStringToDate, getDateNowMaxDatePicker, formatDate } from '../../../util/utils'
import MapSectionsManagement from '../../../_v2/util/map-sections-management'
import InputSelect from '../../../_v2/components/commons/form/InputSelect'
import InputDatePicker from '../../../_v2/components/commons/form/InputDatePicker'
// MODALS
import UnattendedProcessDetailModalPage from '../../../_v2/containers/management/Modals/UnattendedProcessDetailModalPage'

class UnattendedProcessesFinder extends PureComponent {
  constructor () {
    super()
    this.state = {
      fields: {
        unattendedProcessStatus: [unattendedResult.PENDING, unattendedResult.RUNNING_NO_ERRORS],
        unattendedProcessView: 1
      },
      filters: [],
      dynamicFilters: [],
      disableResetButton: false,
      maxiTable: false,
      hideFilters: false

    }
  }

  componentDidMount () {
    this.props.actions.fetchUnattendedProcessTypeCombo()
    return new Promise((resolve, reject) => {
      this.props.actions.fetchUnattendedProcessViewCombo(resolve, reject)
    }).then(() => {
      this.configureProcess()
    })
  }

  configureProcess () {
    if (this.props.dynamicFilters.unattendedProcessStatus !== undefined) {
      this.props.change('unattendedProcessStatus', this.props.dynamicFilters.unattendedProcessStatus)
    } else {
      this.props.change('unattendedProcessStatus', this.state.fields.unattendedProcessStatus)
    }

    if (this.props.dynamicFilters.unattendedProcessView !== undefined) {
      this.props.change('unattendedProcessView', this.props.dynamicFilters.unattendedProcessView)
    } else {
      this.props.change('unattendedProcessView', this.state.fields.unattendedProcessView)
    }
  }

  validateDate (value, allValues, props) {
    const result = validateDate(value, props)
    return result === Object(result) ? undefined : result
  }

  fieldFill (field) {
    this.setState({ fields: { ...this.state.fields, ...field } })
  }

  onOrderChange (e) {
    const newOrderBy = this.props.filters.orderBy.includes(`+${e.currentTarget.dataset.field}`) ? `-${e.currentTarget.dataset.field}` : `+${e.currentTarget.dataset.field}`
    this.search(null, { ...this.props.filters, orderBy: newOrderBy })
  }

  onPageChange (page) {
    this.search(null, { ...this.props.filters, page })
  }

  getIconClass (field) {
    if (this.props.filters.orderBy.includes(field)) {
      return this.props.filters.orderBy.includes('-') ? 'ico-sort-selected-asc' : 'ico-sort-selected-desc'
    } else {
      return 'ico-sort'
    }
  }

  extractMultipleCombo (combo, filters, item) {
    const values = combo.filter(object => {
      return filters[item] ? filters[item].some(selectedComboValue => object.id === selectedComboValue) : null
    })

    let value = values && this.formatMultipleValues(values)
    return value
  }

  formatMultipleValues (multiplecombovalues) {
    return multiplecombovalues
      .map((combovalue, index) => combovalue.value)
      .join(', ')
  }

  handleClickReset (e) {
    if (e !== undefined) {
      e.preventDefault()
    }

    this.props.initialize({})

    this.setState({
      filters: [],
      dynamicFilters: { unattendedProcessView: 0 }
    })
  }

  search (values, filters) {
    let body = {}

    if (values) {
      if (values.unattendedProcessView) {
        body.OnlyUser = true
      }
      body.Status = values.unattendedProcessStatus
      body.ProcessTypeId = values.unattendedProcessType
      body.StartDateLaunchProcess = values.launchDateFrom
      body.EndDateLaunchProcess = values.launchDateTo
      body.StartDateEndProcess = values.endDateFrom
      body.EndDateEndProcess = values.endDateTo
    } else {
      body = this.props.dynamicFilters
    }
    this.props.actions.findUnattendedProcess({ ...body, ...filters })
  }

  submitSearchForm (values) {
    this.search(values, { ...this.props.filters, page: 1, orderBy: '-startProcessDate' })
    this.setState({ hideFilters: true })
  }

  exportData () {
    const tKey = 'MANAGEMENT.UNATTENDED_PROCESS_TAB.'
    let columnsExcel = []

    columnsExcel.push(this.props.t(`${tKey}UNATTENDED_PROCESS_TYPE`))
    columnsExcel.push(this.props.t(`${tKey}CREATION_DATE`))
    columnsExcel.push(this.props.t(`${tKey}LAUNCH_DATE`))
    columnsExcel.push(this.props.t(`${tKey}UNATTENDED_PROCESS_STATUS`))
    columnsExcel.push(this.props.t(`${tKey}N_ERRORS`))
    columnsExcel.push(this.props.t(`${tKey}END_PROCESS`))
    columnsExcel.push(this.props.t(`${tKey}USER_NAME`))

    let columns = columnsExcel.join('$')

    let body = this.props.dynamicFilters
    body.Columns = columns

    this.props.actions.exportUnattendedProcess({ ...body, ...this.props.filters })
  }

  getStatusClassName (statusId) {
    return unattendedStatusClassNames[statusId]
  }

  getStatusToolTip (statusId) {
    return unnatendedResultToolTip[statusId]
  }

  getResultClassName (process) {
    return unattendedResultClassNames[process.result]
  }

  reload () {
    this.search(null, { ...this.props.filters, page: 1, orderBy: '-startProcessDate' })
  }

  openDetailModal (process) {
    this.props.actions.openUnattendedProcessDetailModal(process)
  }

  getColor (process) {
    switch (process.result) {
      case unattendedResult.PENDING:
        return 'blue'
      case unattendedResult.RUNNING_NO_ERRORS:
        return 'yellow'
      case unattendedResult.RUNNING_WHIT_ERRORS:
        return 'purple'
      case unattendedResult.ENDED_NO_ERRORS:
        return 'green'
      case unattendedResult.ENDED_WITH_ERRORS:
        return 'red'
      default:
        'gray'
    }
  }

  openFileDownloadModal (row, isDeleted) {
    if (isDeleted) {
      this.props.actions.openModal('unattendedProcessFileExpired', null, null, null, null, [{ id: '##EXPIRE_DATE##', value: row.fileExpirationTime }])
    } else {
      this.props.actions.openCloseUnattendedProcessDownloadFileModal(true, row.unattendedProcessId)
    }
  }

  getFileIcon (row) {
    const expiredToolTip = (<Tooltip id="expiredToolTip">{this.props.t('MANAGEMENT.UNATTENDED_PROCESS_TAB.EXPIRED_DOWNLOAD')}</Tooltip>)
    const downloadToolTip = (<Tooltip id="downloadToolTip">{this.props.t('MANAGEMENT.UNATTENDED_PROCESS_TAB.DOWNLOAD_FILE')}</Tooltip>)
    if (row.fileInfo !== null && row.fileInfo !== undefined) {
      if (row.isFileDeleted) {
        return (
          <a className='_Btn cancel stk o-icn noLine' onClick={() => this.openFileDownloadModal(row, row.isFileDeleted)} >
            <OverlayTrigger placement="left" overlay={expiredToolTip}>
              <i className="iDocIcon-attachments"/>
            </OverlayTrigger>
          </a>
        )
      } else {
        return (
          <a className='_Btn accept stk o-icn noLine' onClick={() => this.openFileDownloadModal(row, row.isFileDeleted)}>
            <OverlayTrigger placement="left" overlay={downloadToolTip}>
              <i className="iDocIcon-attachments"/>
            </OverlayTrigger>
          </a>
        )
      }
    }
  }

  toogleHideFilters () {
    this.setState({ hideFilters: !this.state.hideFilters })
  }

  toogleMaxiTable () {
    this.setState({ maxiTable: !this.state.maxiTable })
  }

  showFilter (hasResults) {
    return !hasResults || !this.state.hideFilters
  }

  extractValueComboOnlyUser (value, tKey) {
    if (value === true) return this.props.t(`${tKey}ONLY_USER`)
    if (value === false) return this.props.t(`${tKey}ALL_USERS`)
  }

  extractAndTranslateMultipleCombo (combo, filters, item, t, tKey) {
    const values = combo.filter(object => {
      return filters[item] ? filters[item].some(selectedComboValue => object.id === selectedComboValue) : null
    })

    let value = values && this.formatMultipleValues(values.map((item, index) => { return t(`${tKey}${item}`) }))
    return value
  }

  selectedFilters () {
    const filters = this.props.dynamicFilters
    const tKey = 'MANAGEMENT.UNATTENDED_PROCESS_TAB.'
    return Object.keys(filters)
      .filter(item => { return item !== 'page' && item !== 'pageSize' && item !== 'orderBy' })
      .map((item, index) => {
        let text = item
        let value = filters[item]
        const list = []
        if (value) {
          switch (item.trim()) {
            case 'Status':
            {
              const values = this.props.combos.unattendedProcessStatusCombo.filter(object => {
                return value ? value.some(selectedComboValue => object.id === selectedComboValue) : null
              })

              value = values && values.map((item) => {
                return this.props.t(`${tKey}${item.value}`)
              }).join(',')
              text = this.props.t(`${tKey}${'UNATTENDED_PROCESS_STATUS'}`)
              break }
            case 'ProcessTypeId':
            {
              value = this.extractMultipleCombo(this.props.combos.unattendedProcessTypeCombo, filters, item)
              text = this.props.t(`${tKey}${'UNATTENDED_PROCESS_TYPE'}`)
              break }
            case 'dossierSubtypeId':
            { const selectedSubtype = this.props.combos.dossierSubTypesCombo.find(subtype => subtype.id === filters[item])
              text = this.props.t(`${tKey}${fromCamelToUnderscore('dossierSubtype').toUpperCase()}`)
              value = selectedSubtype && selectedSubtype.value
              break }
            case 'StartDateLaunchProcess': {
              value = filters[item]
              text = this.props.t(`${tKey}CREATION_DATE_FROM`)
              break }
            case 'EndDateLaunchProcess': {
              value = filters[item]
              text = this.props.t(`${tKey}CREATION_DATE_TO`)
              break }
            case 'StartDateEndProcess': {
              value = filters[item]
              text = this.props.t(`${tKey}END_DATE_FROM`)
              break }
            case 'EndDateEndProcess': {
              value = filters[item]
              text = this.props.t(`${tKey}END_DATE_TO`)
              break }
            case 'OnlyUser':
            {
              value = this.extractValueComboOnlyUser(filters[item], tKey)
              text = this.props.t(`${tKey}UNATTENDED_PROCESS_VIEW`)
              break
            }
            default:
            {
              if (filters[item] instanceof Date) {
                value = formatDate(filters[item])
              } else {
                value = filters[item]
              }

              if (value) {
                text = this.props.t(`${tKey}${fromCamelToUnderscore(upperToLowerCamelCase(item)).toUpperCase()}`)
              }
              break }
          }

          list.push(<li key={index}>{text}: <strong>{value}</strong></li>)
        }

        return list
      })
  }

  toggleFilterClass (hasResults) {
    if (!hasResults) {
      return 'iDocIcon-filter-hidden'
    }
    if (this.state.hideFilters) {
      return 'iDocIcon-filter-off'
    }
    return 'iDocIcon-filter'
  }

  hideFiltersBtn (hasResults) {
    if (!hasResults) {
      return 'hide'
    }
    return ''
  }

  render () {
    const {
      t, combos: {
        unattendedProcessTypeCombo
      },
      classNameSelect, className, count, unattendedProcessData,
      filters: { page, pages }, handleSubmit, hasSearch
    } = this.props
    const tKey = 'MANAGEMENT.UNATTENDED_PROCESS_TAB.'
    const secMapManagement = MapSectionsManagement.find(function (s) { return s.page === 'unattendedProcessesFinderPage' })
    const hasResults = unattendedProcessData && unattendedProcessData.length > 0
    let unattendedProcessViewCombo = this.props.combos.unattendedProcessViewCombo
    unattendedProcessViewCombo = unattendedProcessViewCombo.map((combo) => {
      return { ...combo, value: t(`${tKey}${combo.value}`) }
    })

    let unattendedProcessStatusCombo = this.props.combos.unattendedProcessStatusCombo
    unattendedProcessStatusCombo = unattendedProcessStatusCombo.map((combo) => {
      return { ...combo, value: t(`${tKey}${combo.value}`) }
    })

    return (
      <>
        <UnattendedProcessDetailModalPage></UnattendedProcessDetailModalPage>
        <UnattendedProcessDownloadFileModalPage></UnattendedProcessDownloadFileModalPage>
        <div className="inner-results-panel">
          <div className="section-results-header">
            <div className="section-header-title">
              <OverlayTrigger placement="right" overlay={<Tooltip id="editProductTooltip">{`${t((this.props.hideMenu ? 'GLOBAL.TABLES.SHOW_INNER_MENU' : 'GLOBAL.TABLES.HIDE_INNER_MENU'))}`}</Tooltip>}>
                <a onClick={() => this.props.toogleInnerMenu()} className={this.props.hideMenu ? 'collapseBtn' : 'collapseBtn closeInnerMenu'}><i className={this.props.hideMenu ? 'iDocIcon-Open-inner-menu' : 'iDocIcon-Close-inner-menu'}></i></a>
              </OverlayTrigger>
              <h4>{t(`${secMapManagement.title}`)}
                <OverlayTrigger placement="left" overlay={<Tooltip id="editProductTooltip">{`${t((this.state.hideFilters ? 'GLOBAL.TABLES.SHOW_INNER_MENU' : 'GLOBAL.TABLES.HIDE_INNER_MENU'))}`}</Tooltip>}>
                  <a onClick={() => this.toogleHideFilters()} className={'toogleShowFilters ' + this.hideFiltersBtn(hasResults)}><i className={this.toggleFilterClass(hasResults)}></i></a>
                </OverlayTrigger>
              </h4>
            </div>
            <Form autoComplete="off" onSubmit={handleSubmit((values) => this.submitSearchForm(values))} className="filters">
              <div className={'search-form ' + (this.showFilter(hasResults) ? '' : 'hideFilters ')}>
                <Field
                  component={InputSelect}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="unattendedProcessType"
                  name='unattendedProcessType'
                  placeholder={t(`${tKey}UNATTENDED_PROCESS_TYPE`)}
                  controlLabel={t(`${tKey}UNATTENDED_PROCESS_TYPE`)}
                  options={unattendedProcessTypeCombo}
                  valueKey="id"
                  labelKey="value"
                  customClass={this.state.fields.unattendedProcessType ? classNameSelect : ''}
                  multi={true}
                  onInputChange={ (val) => this.fieldFill({ unattendedProcessType: val })}
                />
                <Field
                  component={InputSelect}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="unattendedProcessView"
                  name='unattendedProcessView'
                  placeholder={t(`${tKey}UNATTENDED_PROCESS_VIEW`)}
                  controlLabel={t(`${tKey}UNATTENDED_PROCESS_VIEW`)}
                  options={unattendedProcessViewCombo}
                  valueKey="id"
                  labelKey="value"
                  customClass={this.state.fields.unattendedProcessView ? className : ''}
                  onInputChange={ (val) => this.fieldFill({ unattendedProcessView: val })}
                  isClearable ={false}
                />
                <Field
                  component={InputDatePicker}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="launchDateFrom"
                  name='launchDateFrom'
                  placeholder={t(`${tKey}CREATION_DATE_FROM`)}
                  controlLabel={t(`${tKey}CREATION_DATE_FROM`)}
                  validate={[this.validateDate]}
                  customClass={this.state.fields.launchDateFrom ? className : ''}
                  onInputChange={ (val) => this.fieldFill({ launchDateFrom: val })}
                  maxDate={getDateNowMaxDatePicker()}

                />
                <Field
                  component={InputDatePicker}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="launchDateTo"
                  name='launchDateTo'
                  placeholder={t(`${tKey}CREATION_DATE_TO`)}
                  controlLabel={t(`${tKey}CREATION_DATE_TO`)}
                  validate={[this.validateDate]}
                  customClass={this.state.fields.launchDateTo ? className : ''}
                  onInputChange={ (val) => this.fieldFill({ launchDateTo: val })}
                  maxDate={getDateNowMaxDatePicker()}
                />
                <Field
                  component={InputSelect}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="unattendedProcessStatus"
                  name='unattendedProcessStatus'
                  placeholder={t(`${tKey}UNATTENDED_PROCESS_STATUS`)}
                  controlLabel={t(`${tKey}UNATTENDED_PROCESS_STATUS`)}
                  options={unattendedProcessStatusCombo}
                  valueKey="id"
                  labelKey="value"
                  customClass={this.state.fields.unattendedProcessStatus ? className : ''}
                  multi={true}
                  onInputChange={(val) => this.fieldFill({ unattendedProcessStatus: val })}
                />
                <Field
                  component={InputDatePicker}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="endDateFrom"
                  name='endDateFrom'
                  placeholder={t(`${tKey}END_DATE_FROM`)}
                  controlLabel={t(`${tKey}END_DATE_FROM`)}
                  validate={[this.validateDate]}
                  customClass={this.state.fields.endDateFrom ? className : ''}
                  onInputChange={ (val) => this.fieldFill({ endDateFrom: val })}
                  maxDate={getDateNowMaxDatePicker()}
                />
                <Field
                  component={InputDatePicker}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="endDateTo"
                  name='endDateTo'
                  placeholder={t(`${tKey}END_DATE_TO`)}
                  controlLabel={t(`${tKey}END_DATE_TO`)}
                  validate={[this.validateDate]}
                  customClass={this.state.fields.endDateTo ? className : ''}
                  onInputChange={(val) => this.fieldFill({ endDateTo: val })}
                  maxDate={getDateNowMaxDatePicker()}
                />
                <div className='search-form-actions flexInLine al-R'>
                  <Button id="btn_search"
                    type="submit"
                    className="_Btn accept stk">
                    <i className="iDocIcon-search"/>
                    {t('SEARCH.ACTIONS.SEARCH_BUTTON')}
                  </Button>
                  <Button
                    id="btn_reset"
                    onClick={(e) => this.handleClickReset(e)}
                    className={'_Btn' + (this.props.pristine ? ' accept' : ' accept')}
                    disabled={this.props.pristine}
                  >
                    <i className="iDocIcon-clear-decline"/> {t('SEARCH.ACTIONS.RESET_BUTTON')}
                  </Button>
                </div>
              </div>
            </Form>
          </div>
          <div className="section-results-body">
            <div className={'searchResoultsContainer' + (this.state.maxiTable ? ' maxiTable' : '')}>
              {hasSearch && ([
                <div className="table-wrapper" key="table-wrapper">
                  <ul className="list-inline"> {/* Active filter list */}
                    {this.selectedFilters().length === 0 ? <li>{t(`${tKey}NO_SELECTED_FILTERS`)}</li> : (this.selectedFilters())}
                  </ul>
                  {/* table options, maximize, etc.... */}
                  {hasResults && [
                    <div className='flexInLine al-R'>
                      <div className='actionsField'>
                      </div>
                      <OverlayTrigger placement="left" overlay={<Tooltip id="editProductTooltip">{`${t((this.state.maxiTable ? 'GLOBAL.TABLES.MINIMIZE_TABLE' : 'GLOBAL.TABLES.MAXIMIZE_TABLE'))}`}</Tooltip>}>
                        <a onClick={() => this.toogleMaxiTable()} className='_Btn accept stk o-icn maximizeBtn'><i className={(this.state.maxiTable ? 'iDocIcon-Minimize' : 'iDocIcon-Maximize')}></i></a>
                      </OverlayTrigger>
                      <OverlayTrigger placement="left" overlay={<Tooltip id="editProductTooltip">{t('SEARCH.ACTIONS.EXPORT')}</Tooltip>}>
                        <a onClick={(event) => this.exportData(event)} className='_Btn new o-icn exportToExcel'><i className="iDocIcon-file-excel"></i></a>
                      </OverlayTrigger>
                    </div>
                  ]}
                  {/* table */}
                  <div className="table-responsive">
                    {hasResults ? <SimpleTablePage
                      columns={[
                        <th key={0} data-field="unattendedProcessId" display="none">
                        </th>,
                        <th key={1} data-field="unattendedProcessName" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}UNATTENDED_PROCESS_TYPE`)}&nbsp;<i className={this.getIconClass('unattendedProcessName')}></i>
                        </th>,
                        <th key={2} data-field="creationDate" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}CREATION_DATE`)}&nbsp;<i className={this.getIconClass('creationDate')}/>
                        </th>,
                        <th key={3} data-field="startProcessDate" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}LAUNCH_DATE`)}&nbsp;<i className={this.getIconClass('startProcessDate')}/>
                        </th>,
                        <th key={4} data-field="unattendedProcessStatusId" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}UNATTENDED_PROCESS_STATUS`)}&nbsp;<i className={this.getIconClass('unattendedProcessStatusId')}></i>
                        </th>,
                        <th key={5} data-field="unattendedProcessStatusId" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}RESULT`)}&nbsp;<i className={this.getIconClass('unattendedProcessStatusId')}/>
                        </th>,
                        <th key={6} data-field="endProcessDate" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}END_PROCESS`)}&nbsp;<i className={this.getIconClass('endProcessDate')}/>
                        </th>,
                        <th key={7} data-field="userName" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}USER_NAME`)}&nbsp;<i className={this.getIconClass('userName')}/>
                        </th>,
                        <th key={8} className='actionTD' data-field="fileInfo">
                        </th>
                      ]}
                      rows={unattendedProcessData && unattendedProcessData.map((row, idx) => (
                        <tr key={idx}>
                          <td>
                            {row.UnattendedProcessId}
                          </td>
                          <td>
                            {row.unattendedProcessName}
                          </td>
                          <td>
                            {row.creationDate}
                          </td>
                          <td>
                            {row.startProcessDate}
                          </td>
                          <td>
                            <OverlayTrigger trigger={['hover', 'focus']} placement="bottom" overlay={<Tooltip id="tooltip-trigger-hover-focus">{t(this.getStatusToolTip(row.unattendedProcessStatusId))}</Tooltip>}>
                              <div className={'unattendedStatusIcon ' + this.getStatusClassName(row.unattendedProcessStatusId)}></div>
                            </OverlayTrigger>
                          </td>
                          <td>
                            <div className={'unattendedStatusIcon ' + this.getResultClassName(row)} onClick={() => this.openDetailModal(row)} style={{ cursor: 'pointer' }}></div>
                          </td>
                          <td>{row.endProcessDate}</td>
                          <td>{row.userName}</td>
                          <td className='actions'>{this.getFileIcon(row)}</td>
                        </tr>
                      ))}
                    />
                      : <p className="no_results">{t('GLOBAL.TABLES.NO_RESULTS')}</p>}
                  </div>
                  <div className="search-footer" key="search-footer">
                    <IDocCarPagination
                      id="btn_pag_sales"
                      page={page}
                      pagesCount={pages}
                      totalRows= {count}
                      onSelectAction={(page) => this.onPageChange(page)}
                    />
                  </div>
                </div>
              ])}
            </div>
          </div>
        </div>
      </>
    )
  }
}
const validate = (values, { t }, props) => {
  const errors = {}

  if (values && values.launchDateFrom && values.launchDateTo) {
    let dateTo = convertStringToDate(values.launchDateTo)
    let dateFrom = convertStringToDate(values.launchDateFrom)
    if (dateFrom > dateTo) {
      errors.launchDateTo = t('GLOBAL.FORMS.DATE_TO_NOT_LESS_THAN_DATE_FROM')
    }
  }

  if (values && values.endDateFrom && values.endDateTo) {
    let dateTo = convertStringToDate(values.endDateTo)
    let dateFrom = convertStringToDate(values.endDateFrom)
    if (dateFrom > dateTo) {
      errors.endDateTo = t('GLOBAL.FORMS.DATE_TO_NOT_LESS_THAN_DATE_FROM')
    }
  }

  if (values && (values.unattendedProcessStatus && values.unattendedProcessStatus.includes(unattendedResult.ENDED_NO_ERRORS)) || (!values.unattendedProcessStatus || values.unattendedProcessStatus.length === 0)) {
    if (!values.launchDateFrom) {
      errors.launchDateFrom = t('GLOBAL.FORMS.REQUIRED')
    }
    if (!values.launchDateTo) {
      errors.launchDateTo = t('GLOBAL.FORMS.REQUIRED')
    }
  }

  if (values.unattendedProcessView === null || values.unattendedProcessView === undefined) {
    errors.unattendedProcessView = t('GLOBAL.FORMS.REQUIRED')
  }

  return errors
}
export default reduxForm({
  form: 'unattendedProcessesFinder',
  validate
})(UnattendedProcessesFinder)
