export const economicPlanSectionType = {
  VENTA_OTROS: 1,
  SUPLIDOS: 2,
  COSTE_OBSEQUIOS: 3,
  ASSOCIATED_COSTS: 4
}

export const translateEconomicPlanSectionType = {
  1: 'VENTA_OTROS',
  2: 'SUPLIDOS',
  3: 'COSTE_OBSEQUIOS',
  4: 'ASSOCIATED_COSTS'
}

export const MAX_LENGTH_MONEY = 12

export const MAX_LENGTH_PERCENTAGE = 6

export const NUMBER_OF_DECIMALS = 3
export const DEFAULT_MAX_PERCENTAGE = 100
