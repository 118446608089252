import React, { PureComponent } from 'react'
import { getSectionPermissions } from '../../util/permisionFunctions'
import { sectionPermissions } from '../../constants/dossier/common/buttonsHeader'
import { Tab, Tabs } from 'react-bootstrap'
import UserDataPage from '../../containers/profile/UserDataPage'
import UserEventSubscriptionsPage from '../../containers/profile/UserEventSubscriptionsPage'

class Profile extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      activeTab: 1
    }
    this.onSelectTab = this.onSelectTab.bind(this)
  }

  initializePermisions (props) {
    const section = props.sections.filter((tabConf) => tabConf.code === sectionPermissions.dashboard)
    if (section.length > 0 && section[0] != null) {
      const newState = getSectionPermissions(section[0])
      this.setState({ sectionTabsConfiguration: newState })
    }
  }

  onSelectTab (tabKey) {
    this.setState({ activeTab: tabKey })
    switch (tabKey) {
      case 2:
        this.props.actions.fetchEventSubscriptions()
        break
      default:
        break
    }
  }

  componentDidMount () {
    this.initializePermisions(this.props)
  }

  render () {
    const tKey = 'PROFILE.'
    const { t, enableSubscriptions } = this.props
    return (
      <div className='profile-user'>
        <Tabs id="profileTabs" activeKey={this.state.activeTab} defaultActiveKey={1} onSelect={this.onSelectTab.bind(this)}>
          <Tab eventKey={1} title={t(`${tKey}USER_DATA_TAB`)}>
            <UserDataPage />
          </Tab>
          {enableSubscriptions && (
            <Tab eventKey={2} title={t(`${tKey}NOTIFICATIONS_TAB.TITLE`)}>
              <UserEventSubscriptionsPage/>
            </Tab>
          )}
        </Tabs>
      </div>
    )
  }
}

export default Profile
