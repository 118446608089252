import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Fleets from '../../components/management/Fleets'
import { translate } from 'react-polyglot'
import { fetchDossierSubTypes, fetchSearchDossierSubType, fetchFleetsUser, fetchEntitySubTypeCombo, fetchEntityFilterSubTypeCombo } from '../../actions/combos/combos'
import { openDocumentEditionModal, openDocumentCreationModalFleets } from '../../actions/dossier/common/documentEditionModal'
import {
  initializeSearch, resetFilters, fetchDossierFleets, fetchDossierFleetsDownload, setManagementFleetsFilter,
  resetManagementFleetsFilter
} from '../../actions/search/search'
import { openActionDataModal, closeActionDataModal, openApprovalActionModal } from '../../actions/masters/masters'
import { openModal, yesNoModal } from '../../actions/common'
import { openActionFleetFinder, closeActionFleetFinder, deleteDossierFromFleet, openNewFleetModal, openDocumentDiferentsModalFleets, openEconomicPlanModal, showFrontEconomicPlan } from '../../actions/management/management'
import { setActionExpFleetProcess } from '../../actions/unattendedProcess/unattendedProcess'

export function mapStateToProps (state) {
  return {
    ...state.search,
    shoModalFinderFleet: state.management.actionFleetFinder.showModal,
    combos: {
      salesmanCombo: state.combos.salesmanCombo,
      activeInactiveCombo: state.combos.activeInactiveCombo,
      dossierSubtypesCombo: state.combos.dossierSubTypesCombo,
      lowestOrganizedUnitsCombo: state.combos.lowestOrganizedUnitsCombo,
      recurrentEntitiesCombo: state.combos.recurrentEntitiesCombo,
      entitySubTypeCombo: state.combos.entitySubTypeCombo,
      entityFilterSubTypeCombo: state.combos.entityFilterSubTypeCombo,
      fleets: state.combos.fleetsUser
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchDossierSubTypes,
      fetchSearchDossierSubType,
      initializeSearch,
      resetFilters,
      fetchFleetsUser,
      fetchDossierFleets,
      openDocumentEditionModal,
      openDocumentCreationModalFleets,
      openActionDataModal,
      closeActionDataModal,
      openModal,
      yesNoModal,
      fetchDossierFleetsDownload,
      openActionFleetFinder,
      closeActionFleetFinder,
      deleteDossierFromFleet,
      openNewFleetModal,
      setManagementFleetsFilter,
      resetManagementFleetsFilter,
      fetchEntitySubTypeCombo,
      fetchEntityFilterSubTypeCombo,
      openApprovalActionModal,
      openDocumentDiferentsModalFleets,
      openEconomicPlanModal,
      showFrontEconomicPlan,
      setActionExpFleetProcess
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(Fleets))
