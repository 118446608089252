import documentalSearch from '../../../constants/actions/search/documentalSearch/documentalSearch'

export function fetchDocumentalSearchFormCombos (documentEntityType) {
  return {
    type: documentalSearch.FETCH_DOCUMENTAL_SEARCH_FORM_COMBOS,
    documentEntityType
  }
}

export function findFilterDocuments (filters) {
  return {
    type: documentalSearch.FIND_FILTER_DOCUMENTS,
    filters
  }
}

export function findFilterDocumentsSuccess (data, filters) {
  return {
    type: documentalSearch.FIND_FILTER_DOCUMENTS_SUCCESS,
    data,
    filters
  }
}

export function resetFilters () {
  return {
    type: documentalSearch.RESET_FILTERS_DOCUMENTS
  }
}

export function fetchDocumentSearchList (filters) {
  return {
    type: documentalSearch.FETCH_DOCUMENT_SEARCH_LIST,
    filters
  }
}

export function fetchDocumentSearchListSuccess (documents, filters) {
  return {
    type: documentalSearch.FETCH_DOCUMENT_SEARCH_LIST_SUCCESS,
    documents,
    filters
  }
}

export function initializeSearch () {
  return {
    type: documentalSearch.INITIALIZE_DOC_SEARCH
  }
}

export function setFilters (filters) {
  return {
    type: documentalSearch.SET_FILTERS,
    filters
  }
}

export function openDocumentEditionModal (documentId, documentTypeId, organizedUnitId) {
  return {
    type: documentalSearch.OPEN_DOCUMENT_MODAL,
    documentId,
    documentTypeId,
    organizedUnitId
  }
}

export function openDocumentalSearchModalSuccess (docResult, documentTypeUseInfo) {
  return {
    type: documentalSearch.OPEN_DOCUMENT_MODAL_SUCCESS,
    docResult,
    documentTypeUseInfo
  }
}

export function documentalSearchTabChange (selectedTab) {
  return {
    type: documentalSearch.DOCUMENTAL_SEARCH_TAB_CHANGE,
    selectedTab
  }
}

export function downloadDocumentList (documentIds) {
  return {
    type: documentalSearch.DOWNLOAD_DOCUMENT_LIST,
    documentIds
  }
}

export function massiveDownloadDocumentList (documentIds, resolve, reject) {
  return {
    type: documentalSearch.MASSIVE_DOWNLOAD_DOCUMENT_LIST,
    documentIds,
    resolve,
    reject
  }
}

export function createMassiveDownload (documentIds, fileName, observations, resolve) {
  return {
    type: documentalSearch.CREATE_MASSIVE_DOWNLOAD,
    documentIds,
    fileName,
    observations,
    resolve
  }
}

export function findFilterMassiveDownload (filters, currentUser) {
  return {
    type: documentalSearch.FIND_FILTER_MASSIVE_DOWNLOAD,
    filters,
    currentUser
  }
}

export function findFilterMassiveDownloadSuccess (data, filters) {
  return {
    type: documentalSearch.FIND_FILTER_MASSIVE_DOWNLOAD_SUCCESS,
    data,
    filters
  }
}

export function downloadMassiveFile (massiveDownloadId) {
  return {
    type: documentalSearch.DOWNLOAD_MASSIVE_FILE,
    massiveDownloadId
  }
}

export function cancelMassiveFile (massiveDownloadId, resolve) {
  return {
    type: documentalSearch.CANCEL_MASSIVE_FILE,
    massiveDownloadId,
    resolve
  }
}

export function setHideFilters (hideFilters) {
  return {
    type: documentalSearch.SET_HIDE_FILTERS,
    hideFilters
  }
}
