import mastersActionTypes from '../../constants/actions/masters/masters'

function initialState () {
  return {
    sourceChannelId: null,
    sourceChannelList: [],
    languageList: [],
    showSourceChannelModal: false,
    showExtraSaleModal: false,
    sourceChannelsFields: [],
    extraSalesMastersList: [],
    extraSaleId: null,
    extraSaleFields: [],
    suppliedMastersList: [],
    suppliedId: null,
    suppliedFields: [],
    presentsMastersList: [],
    presentsId: null,
    presentsFields: [],
    showPresentsModal: false,
    associatedCostsMastersList: [],
    associatedCostsId: null,
    associatedCostsFields: [],
    showAssociatedCostsModal: false,
    fuelId: null,
    showFuelModal: false,
    fuelList: [],
    fuelFields: [],
    financingTypeId: null,
    showFinancingTypeModal: false,
    financingTypeMastersList: [],
    financingTypeFields: [],
    purchasePresentTypeId: null,
    showPurchasePresentTypeModal: false,
    purchasePresentTypeMastersList: [],
    purchasePresentTypeFields: [],
    financing: null,
    financingTypeProduct: null,
    sourceDirectionsList: [],
    directionUOFields: [],
    directionUOId: null,
    showDirectionUOModal: false,
    legalCompanyList: [],
    legalCompanyFields: [],
    legalCompanyId: null,
    showLegalCompanyModal: false,
    organizedUnitList: [],
    organizedUnitFields: [],
    organizedUnitId: null,
    showOrganizedUnitModal: false,
    codeCommissionMastersList: [],
    showCodeCommissionModal: false,
    codeCommissionModalData: null,
    codeCommissionFields: {
      page: 1
    }
  }
}

function fetchEntityMastersSuccess (state, { sourceChannelList }) {
  return {
    ...state,
    sourceChannelList
  }
}

function fetchExtraSalesMastersSuccess (state, { extraSalesMastersList }) {
  return {
    ...state,
    extraSalesMastersList
  }
}

function openSourceChannelModalSuccess (state, { languageList, sourceChannelsFields, sourceChannelId }) {
  return {
    ...state,
    languageList,
    showSourceChannelModal: true,
    sourceChannelsFields,
    sourceChannelId
  }
}

function closeSourceChannelModal (state) {
  return {
    ...state,
    languageList: [],
    showSourceChannelModal: false,
    sourceChannelId: null,
    sourceChannelsFields: []
  }
}

function openExtraSaleModalSuccess (state, { languageList, extraSaleFields, extraSaleId }) {
  return {
    ...state,
    languageList,
    showExtraSaleModal: true,
    extraSaleFields,
    extraSaleId
  }
}

function closeExtraSaleModal (state) {
  return {
    ...state,
    languageList: [],
    showExtraSaleModal: false,
    extraSaleId: null,
    extraSaleFields: []
  }
}

function fetchSuppliedMastersSuccess (state, { suppliedMastersList }) {
  return {
    ...state,
    suppliedMastersList
  }
}

function openSuppliedModalSuccess (state, { languageList, suppliedFields, suppliedId }) {
  return {
    ...state,
    languageList,
    showSuppliedModal: true,
    suppliedFields,
    suppliedId
  }
}

function closeSuppliedModal (state) {
  return {
    ...state,
    languageList: [],
    showSuppliedModal: false,
    suppliedId: null,
    suppliedFields: []
  }
}

function fetchPresentsMastersSuccess (state, { presentsMastersList }) {
  return {
    ...state,
    presentsMastersList
  }
}

function openPresentsModalSuccess (state, { languageList, presentsFields, presentsId }) {
  return {
    ...state,
    languageList,
    showPresentsModal: true,
    presentsFields,
    presentsId
  }
}

function closePresentsModal (state) {
  return {
    ...state,
    languageList: [],
    showPresentsModal: false,
    presentsId: null,
    presentsFields: []
  }
}

function openAssociatedCostsModalSuccess (state, { languageList, associatedCostsFields, associatedCostsId }) {
  return {
    ...state,
    languageList,
    showAssociatedCostsModal: true,
    associatedCostsFields,
    associatedCostsId
  }
}

function fetchAssociatedCostsMastersSuccess (state, { associatedCostsMastersList }) {
  return {
    ...state,
    associatedCostsMastersList
  }
}

function closeAssociatedCostsModal (state) {
  return {
    ...state,
    languageList: [],
    showAssociatedCostsModal: false,
    associatedCostsId: null,
    associatedCostsFields: []
  }
}

function fetchFuelMastersSuccess (state, { fuelList }) {
  return {
    ...state,
    fuelList
  }
}
function openEditFuelModalSuccess (state, { languageList, fuelFields, fuelId }) {
  return {
    ...state,
    languageList,
    showFuelModal: true,
    fuelFields,
    fuelId
  }
}

function closeEditFuelModal (state) {
  return {
    ...state,
    languageList: [],
    showFuelModal: false,
    fuelId: null,
    fuelFields: []
  }
}
function fetchFinancingTypeMastersSuccess (state, { financingTypeMastersList }) {
  return {
    ...state,
    financingTypeMastersList
  }
}

function fetchPurchasePresentTypeMastersSuccess (state, { purchasePresentTypeMastersList }) {
  return {
    ...state,
    purchasePresentTypeMastersList
  }
}

function openFinancingTypeModalSuccess (state, { languageList, financingTypeFields, financingTypeId, financing, financingTypeProduct }) {
  return {
    ...state,
    languageList,
    showFinancingTypeModal: true,
    financingTypeFields,
    financingTypeId,
    financing,
    financingTypeProduct
  }
}

function closeFinancingTypeModal (state) {
  return {
    ...state,
    languageList: [],
    showFinancingTypeModal: false,
    extraSaleId: null,
    financingTypeFields: []
  }
}

function openPurchasePresentTypeModalSuccess (state, { languageList, purchasePresentTypeFields, purchasePresentTypeId }) {
  return {
    ...state,
    languageList,
    showPurchasePresentTypeModal: true,
    purchasePresentTypeFields,
    purchasePresentTypeId
  }
}

function closePurchasePresentTypeModal (state) {
  return {
    ...state,
    languageList: [],
    showPurchasePresentTypeModal: false,
    purchasePresentTypeFields: []
  }
}

function openDirectionUOModalSuccess (state, { directionUOFields, directionUOId }) {
  return {
    ...state,
    showDirectionUOModal: true,
    directionUOFields,
    directionUOId
  }
}

function closeDirectionUOModal (state) {
  return {
    ...state,
    showDirectionUOModal: false,
    directionUOId: null,
    directionUOFields: []
  }
}

function openLegalCompanyModalSuccess (state, { legalCompanyFields, legalCompanyId }) {
  return {
    ...state,
    showLegalCompanyModal: true,
    legalCompanyFields,
    legalCompanyId
  }
}

function closeLegalCompanyModal (state) {
  return {
    ...state,
    showLegalCompanyModal: false,
    legalCompanyId: null,
    legalCompanyFields: []
  }
}

function openOrganizedUnitModalSuccess (state, { organizedUnitFields, organizedUnitId }) {
  return {
    ...state,
    showOrganizedUnitModal: true,
    organizedUnitFields,
    organizedUnitId
  }
}

function closeOrganizedUnitModal (state) {
  return {
    ...state,
    showOrganizedUnitModal: false,
    organizedUnitId: null,
    organizedUnitFields: []
  }
}

function openCodeCommissionModalSuccess (state, { languageList, codeCommission }) {
  return {
    ...state,
    showCodeCommissionModal: true,
    codeCommissionModalData: codeCommission,
    languageList
  }
}

function closeCodeCommissionModal (state) {
  return {
    ...state,
    showCodeCommissionModal: false,
    codeCommissionModalData: null
  }
}

function fetchCodeCommissionMastersSuccess (state, { codeCommissionMastersList, codeCommissionFields }) {
  return {
    ...state,
    codeCommissionMastersList,
    codeCommissionFields
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case mastersActionTypes.FETCH_ENTITY_MASTERS_SUCCESS:
      return fetchEntityMastersSuccess(state, action)
    case mastersActionTypes.OPEN_SOURCE_CHANNEL_MODAL_SUCCESS:
      return openSourceChannelModalSuccess(state, action)
    case mastersActionTypes.CLOSE_SOURCE_CHANNEL_MODAL:
      return closeSourceChannelModal(state, action)
    case mastersActionTypes.FETCH_EXTRA_SALES_MASTERS_SUCCESS:
      return fetchExtraSalesMastersSuccess(state, action)
    case mastersActionTypes.OPEN_EXTRA_SALE_MODAL_SUCCESS:
      return openExtraSaleModalSuccess(state, action)
    case mastersActionTypes.CLOSE_EXTRA_SALE_MODAL:
      return closeExtraSaleModal(state, action)
    case mastersActionTypes.FETCH_SUPPLIED_MASTERS_SUCCESS:
      return fetchSuppliedMastersSuccess(state, action)
    case mastersActionTypes.OPEN_SUPPLIED_MODAL_SUCCESS:
      return openSuppliedModalSuccess(state, action)
    case mastersActionTypes.CLOSE_SUPPLIED_MODAL:
      return closeSuppliedModal(state, action)
    case mastersActionTypes.FETCH_PRESENTS_MASTERS_SUCCESS:
      return fetchPresentsMastersSuccess(state, action)
    case mastersActionTypes.OPEN_PRESENTS_MODAL_SUCCESS:
      return openPresentsModalSuccess(state, action)
    case mastersActionTypes.CLOSE_PRESENTS_MODAL:
      return closePresentsModal(state, action)
    case mastersActionTypes.OPEN_ASSOCIATED_COSTS_MODAL_SUCCESS:
      return openAssociatedCostsModalSuccess(state, action)
    case mastersActionTypes.FETCH_ASSOCIATED_COSTS_MASTERS_SUCCESS:
      return fetchAssociatedCostsMastersSuccess(state, action)
    case mastersActionTypes.CLOSE_ASSOCIATED_COSTS_MODAL:
      return closeAssociatedCostsModal(state, action)
    case mastersActionTypes.FETCH_FUEL_MASTERS_SUCCESS:
      return fetchFuelMastersSuccess(state, action)
    case mastersActionTypes.OPEN_EDIT_FUEL_MODAL_SUCCESS:
      return openEditFuelModalSuccess(state, action)
    case mastersActionTypes.CLOSE_EDIT_FUEL_MODAL:
      return closeEditFuelModal(state, action)
    case mastersActionTypes.FETCH_FINANCING_TYPE_MASTERS_SUCCESS:
      return fetchFinancingTypeMastersSuccess(state, action)
    case mastersActionTypes.OPEN_FINANCING_TYPE_MODAL_SUCCESS:
      return openFinancingTypeModalSuccess(state, action)
    case mastersActionTypes.CLOSE_FINANCING_TYPE_MODAL:
      return closeFinancingTypeModal(state, action)
    case mastersActionTypes.FETCH_PURCHASE_PRESENT_TYPE_MASTERS_SUCCESS:
      return fetchPurchasePresentTypeMastersSuccess(state, action)
    case mastersActionTypes.OPEN_PURCHASE_PRESENT_TYPE_MODAL_SUCCESS:
      return openPurchasePresentTypeModalSuccess(state, action)
    case mastersActionTypes.CLOSE_PURCHASE_PRESENT_TYPE_MODAL:
      return closePurchasePresentTypeModal(state, action)
    case mastersActionTypes.OPEN_DIRECTION_UO_MODAL_SUCCESS:
      return openDirectionUOModalSuccess(state, action)
    case mastersActionTypes.CLOSE_DIRECTION_UO_MODAL:
      return closeDirectionUOModal(state, action)
    case mastersActionTypes.OPEN_LEGAL_COMPANY_MODAL_SUCCESS:
      return openLegalCompanyModalSuccess(state, action)
    case mastersActionTypes.CLOSE_LEGAL_COMPANY_MODAL:
      return closeLegalCompanyModal(state, action)
    case mastersActionTypes.OPEN_ORGANIZED_UNIT_MODAL_SUCCESS:
      return openOrganizedUnitModalSuccess(state, action)
    case mastersActionTypes.CLOSE_ORGANIZED_UNIT_MODAL:
      return closeOrganizedUnitModal(state, action)
    case mastersActionTypes.OPEN_CODE_COMMISSION_MODAL_SUCCESS:
      return openCodeCommissionModalSuccess(state, action)
    case mastersActionTypes.CLOSE_CODE_COMMISSION_MODAL:
      return closeCodeCommissionModal(state, action)
    case mastersActionTypes.FETCH_CODE_COMMISSION_MASTER_SUCCESS:
      return fetchCodeCommissionMastersSuccess(state, action)
    default:
      return state
  }
}
