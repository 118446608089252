import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { openDirectionUOModal, deleteDirectionUOModal, fetchDirectionUOMaster } from '../../../actions/masters/masters'
import DirectionUOMaster from '../../../components/masters/directionUO/DirectionUOMaster'
export function mapStateToProps (state) {
  return {
    ...state.directionUO
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      openDirectionUOModal,
      deleteDirectionUOModal,
      fetchDirectionUOMaster
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(DirectionUOMaster))
