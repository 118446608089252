
import React, { PureComponent } from 'react'
import { Button, Modal, Col, Row } from 'react-bootstrap'
import { reduxForm, Field } from 'redux-form'
import { validateDate } from '../../util/validationFunctions'
import { dossierSubTypeId } from '../../constants/dossier/common/dossierSubType'
import FleetAndClientComponentPage from '../../containers/management/commons/FleetAndClientComponentPage'
import DossiersListFleetDetailsComponentPage from '../../containers/management/commons/DossiersListFleetDetailsComponentPage'
import InputSelect from '../commons/form/InputSelect'
import RejectCommentModal from '../dossiers/common/RejectCommentModal'
import { operationTabs } from '../../constants/management/operations'
import { documentTypeUse as documentTypeUseConstants, permissions, dossierSubTypes } from '../../constants/backendIds'
import DataSalesFleetModalPage from '../../containers/management/auxModals/DataSalesFleetModalPage'
import DataFinancingFleetModalPage from '../../containers/management/auxModals/DataFinancingFleetModalPage'
import DataContactFleetModalPage from '../../containers/management/auxModals/DataContactFleetModalPage'
import { saleCodes } from '../../constants/dossier/common/fieldCodePermissions'
import DataPaperWorkFleetModalPage from '../../containers/management/auxModals/DataPaperWorkFleetModalPage'
import { documentEntityType as documentEntityTypeConstants } from '../../constants/dossier/common/documentEntityType'
import { dossierTypes as dossierTypesConstant, dossierTypesId } from '../../constants/dossier/common/dossierTypes'
import { fleetMode } from '../../constants/dossier/common/documentEditionModal'
import { replaceableFields } from '../../constants/dossier/common/replaceableFields'
import CancelReasonModal from '../dossiers/common/CancelReasonModal'
import { saleFinalDocStages } from '../../constants/dossier/sale/stages'
import DataDeliveryFleetModalPage from '../../containers/management/auxModals/DataDeliveryFleetModalPage'
import ServiceAddModalPage from '../../containers/dossiers/common/services/ServiceAddModalPage'
import { dossierStagesBySubtype } from '../../constants/dossier/common/dossierStages'
import { approvalRoleCode, approvalType } from '../../constants/dossier/sale/approvalRoleCode'
import PaperworkFleetListComponentPage from '../../containers/management/commons/PaperworkFleetListComponentPage'
import ChargeFleetListComponentPage from '../../containers/management/commons/ChargeFleetListComponentPage'
class OperateFleetModal extends PureComponent {
  constructor () {
    super()
    this.state = {
      disableClient: true,
      organizedUnit: null,
      dossierSubTypeId: null,
      openPanelFleet: true,
      openPanelClient: true,
      changeCheckAll: false,
      dataId: null,
      actionId: null,
      showRejectCommentModal: false,
      showCancelReasonModal: false,
      showServiceModal: false,
      subTypesAllowedToCreate: [],
      dossierList: []
    }
    this.listSubTypesWithDefaultEntity = [dossierSubTypes.service_pap, dossierSubTypes.deliveryPreparation]
    this.listSubTypesWithBlockedEntity = [dossierSubTypes.deliveryPreparation]
    this.listSubTypesWithOrderDateByDefault = [dossierSubTypes.service_pap]
    this.listSubTypesWithOrderDateTodayNoEditable = [dossierSubTypes.deliveryPreparation]
    this.listSubTypesWithMandatoryObservations = [dossierSubTypes.deliveryPreparation]
    this.listSubTypesWithRequiredDatesInvalids = [dossierSubTypes.deliveryPreparation]
  }

  componentDidUpdate () {

  }

  getSnapshotBeforeUpdate (prevProps) {
    if (prevProps.showModal !== this.props.showModal) {
      if (this.props.fleet === null || this.props.fleet === undefined) {
        this.props.actions.clearEntity(-1, dossierSubTypeId.venta_vn)
      }

      this.setState({ disableClient: true, organizedUnit: null, dossierSubTypeId: null })
      if (this.props.showModal) {
        this.props.actions.getFleetOperations()
      }
    }
    if (prevProps.showModal && !this.props.showModal) {
      this.props.initialize({})
      this.props.reset()
    }

    return null
  }

  validateDistinctStage (dossiers) {
    return dossiers.map(x => x.stageId).filter((x, i, a) => a.indexOf(x) === i).length > 1
  }

  validateDistinctUo (dossiers) {
    return dossiers.map(x => x.organizedUnitId).filter((x, i, a) => a.indexOf(x) === i).length > 1
  }

  areSaleLastStage (dossiers) {
    return dossiers.map(x => x.stageId).filter((x, i, a) => a.indexOf(x) === i).every((x) => saleFinalDocStages.includes(x))
  }

  updateComponentAfterDeleteAndRestart () {

  }

  validateDate (value, allValues, props) {
    const result = validateDate(value, props)
    return result === Object(result) ? undefined : result
  }

  fieldFill (field) {
    this.setState({ fields: { ...this.state.fields, ...field } })
  }

  collapsePanelFleet () {
    this.setState({ openPanelFleet: !this.state.openPanelFleet })
  }

  collapsePanelClient () {
    this.setState({ openPanelClient: !this.state.openPanelClient })
  }

  select () {
    let changeCheckAll = this.state.changeCheckAll
    this.setState({ changeCheckAll: !changeCheckAll })
    this.props.actions.openAddDossiersToFleetModal(this.props.fleet)
  }

  openDocumentsModal (fleet, selectedDossiers) {
    selectedDossiers.map(dossier => dossier.dossierId)
    this.props.actions.closeOperateFleetModal()
    this.props.actions.openDocumentFleetModal(this.props.fleet, true, selectedDossiers.map(dossier => dossier.dossierId))
  }

  openEconomicPlan (fleet, selectedDossiers) {
    let dossierShowEconomic = this.props.checkShowEconomicDossiers(selectedDossiers)
    if (dossierShowEconomic && dossierShowEconomic.length > 0) {
      let dossierStageEconomic = this.props.checkStageForEconomicAnalysis(dossierShowEconomic, fleet.dossierSubType)
      if (dossierStageEconomic && dossierStageEconomic.length > 0) {
        if (dossierStageEconomic.length < selectedDossiers.length) {
          this.props.actions.openModal('noAllEconomicDosiersOnFleet', () => { this.props.actions.openEconomicFleetModal(fleet, false, dossierStageEconomic); this.props.actions.closeOperateFleetModal() }, null, null, null, [{ id: '##NUM_OK##', value: dossierStageEconomic.length }, { id: '##NUM_TOTAL##', value: selectedDossiers.length }], null)
        } else {
          this.props.actions.openEconomicFleetModal(this.props.fleet, false, dossierStageEconomic)
        }
      } else {
        this.props.actions.openModal('noEconomicStageDossiersOnFleet')
      }
    } else {
      this.props.actions.openModal('noEconomicDossiersOnFleet')
    }
  }

  launchAction (val, tabId, dossiers = null) {
    var found

    switch (tabId) {
      case operationTabs.DATA:
        found = this.props.dataActions.find(x => x.managementActionId === val)
        break
      case operationTabs.OPERATIONS:
        found = this.props.operationActions.find(x => x.managementActionId === val)
        break
      case operationTabs.DATA_PAPERWORK:
        found = this.props.dataPaperWorkActions.find(x => x.managementActionId === val)
        break
      case operationTabs.OPERATIONS_PAPERWORK:
        found = this.props.operationPaperWorkActions.find(x => x.managementActionId === val)
        break
    }

    this.setState({ actionId: null, dataId: null })
    this.props.actions.initializeOperationActionsCombo(null, null)
    if (found) {
      const selectedDossiers = dossiers || this.props.dossierFleetDetails.filter(dossier => dossier.checked)
      this.executeFunctionByName(found.function, this, selectedDossiers)
    }
  }

  toggleRejectCommentModal (showRejectCommentModal) {
    this.setState({ showRejectCommentModal })
  }

  executeFunctionByName (functionName, context) {
    var args = Array.prototype.slice.call(arguments, 2)
    var namespaces = functionName.split('.')
    var func = namespaces.pop()
    for (var i = 0; i < namespaces.length; i++) {
      context = context[namespaces[i]]
    }
    return context[func].apply(context, args)
  }

  setSaleData (dossiers) {
    if (this.validateDistinctStage(dossiers) || this.validateDistinctUo(dossiers)) {
      this.props.actions.openModal('notDistinctUoAndStage')
    } else {
      let fleet = this.props.fleet
      fleet.stageId = dossierStagesBySubtype.saleVn.provisional
      if (dossiers && dossiers.length > 0) {
        fleet.stageId = dossiers[0].stageId
        fleet.organizedUnitIdDossier = dossiers[0].organizedUnitId
      }
      this.props.actions.openDataSalesFleetModal(fleet, dossiers)
    }
  }

  setOperateContactData (dossiers) {
    if (this.validateDistinctStage(dossiers)) {
      this.props.actions.openModal('notDistinctStage')
    } else {
      let fleet = this.props.fleet
      fleet.stageId = dossierStagesBySubtype.saleVn.provisional
      if (dossiers && dossiers.length > 0) {
        fleet.stageId = dossiers[0].stageId
        fleet.organizedUnitIdDossier = dossiers[0].organizedUnitId
      }
      this.props.actions.openDataContactFleetModal(fleet, dossiers)
    }
  }

  setDeliveryData (dossiers) {
    if (this.validateDistinctStage(dossiers)) {
      this.props.actions.openModal('notDistinctStage')
    } else {
      let fleet = this.props.fleet
      fleet.stageId = dossierStagesBySubtype.saleVn.provisional
      if (dossiers && dossiers.length > 0) {
        fleet.stageId = dossiers[0].stageId
        fleet.organizedUnitIdDossier = dossiers[0].organizedUnitId
      }
      this.props.actions.openDataDeliveryFleetModal(fleet, dossiers)
    }
  }

  setOperatePaperWorkData (dossiers) {
    if (this.validateDistinctStage(dossiers) || this.validateDistinctUo(dossiers)) {
      this.props.actions.openModal('notDistinctUoAndStage')
    } else {
      let fleet = this.props.fleet
      fleet.stageId = dossierStagesBySubtype.paperWork.init
      if (dossiers && dossiers.length > 0) {
        fleet.stageId = dossiers[0].stageId
        fleet.organizedUnitIdDossier = dossiers[0].organizedUnitId
      }
      this.props.actions.openDataPaperWorkFleetModal(fleet, dossiers)
    }
  }

  setFinancingData (dossiers) {
    if (this.validateDistinctStage(dossiers)) {
      this.props.actions.openModal('notDistinctStage')
    } else {
      this.props.actions.fetchFinancingCompaniesBy(this.props.fleet.organizedUnitId)
      let fleet = this.props.fleet
      fleet.stageId = dossierStagesBySubtype.saleVn.provisional
      if (dossiers && dossiers.length > 0) {
        fleet.stageId = dossiers[0].stageId
        fleet.organizedUnitIdDossier = dossiers[0].organizedUnitId
      }
      this.props.actions.openDataFinancingFleetModal(fleet, dossiers)
    }
  }

  setChargeData (dossiers) {
    if (this.validateDistinctStage(dossiers)) {
      this.props.actions.openModal('notDistinctStage')
    } else {
      var organizedUnitId = this.props.fleet.organizedUnitId
      if (dossiers && dossiers.length > 0) {
        organizedUnitId = dossiers[0].organizedUnitId
      }
      this.props.actions.openDocumentEditionModalFleet(
        fleetMode.INCORPORATE_CHARGE,
        null,
        documentTypeUseConstants.charge,
        documentEntityTypeConstants.DOSSIER,
        dossiers.map(x => { return x.dossierId }),
        null,
        null,
        null,
        organizedUnitId,
        null,
        null,
        true,
        dossierTypesConstant.sales,
        null,
        null,
        null,
        null,
        null,
        true,
        false,
        null,
        null,
        null,
        this.props.fleet.fleetId
      )
    }
  }

  setOperateActionSendToApprovalProcess (dossiers) {
    if (this.validateDistinctStage(dossiers)) {
      this.props.actions.openModal('notDistinctStage')
    } else {
      this.props.actions.openModal('setOperateActionSendToApprovalProcessConfirm', () => { this.props.actions.setOperateActionSendToApprovalProcess(dossiers, this.props.fleet.fleetId, null) }, null, null, null, [{ id: replaceableFields.numDossiers, value: dossiers.length }], null)
    }
  }

  setOperateActionApproveProcess (dossiers) {
    if (this.validateDistinctStage(dossiers)) {
      this.props.actions.openModal('notDistinctStage')
    } else {
      this.props.actions.openModal('setOperateActionApproveProcessConfirm', () => { this.props.actions.setOperateActionApproveProcess(dossiers, this.props.fleet.fleetId, null) }, null, null, null, [{ id: replaceableFields.numDossiers, value: dossiers.length }], null)
    }
  }

  setOperateActionRejectProcess (dossiers) {
    if (this.validateDistinctStage(dossiers)) {
      this.props.actions.openModal('notDistinctStage')
    } else {
      this.props.actions.openModal('setOperateActionRejectProcessConfirm', () => this.toggleRejectCommentModal(true), null, null, null, [{ id: replaceableFields.numDossiers, value: dossiers.length }], null)
    }
  }

  setOperateActionApprovePaperworkProcess (dossiers) {
    if (this.validateDistinctStage(dossiers)) {
      this.props.actions.openModal('notDistinctStage')
    } else {
      this.props.actions.openModal('setOperateActionApprovePaperworkProcessConfirm', () => { this.props.actions.setOperateActionApprovePaperworkProcess(dossiers, this.props.fleet.fleetId, null) }, null, null, null, [{ id: replaceableFields.numDossiers, value: dossiers.length }], null)
    }
  }

  setOperateActionSendToAgencyProcess (dossiers) {
    if (this.validateDistinctStage(dossiers)) {
      this.props.actions.openModal('notDistinctStage')
    } else {
      this.props.actions.openModal('setOperateActionSendToAgencyProcessConfirm', () => { this.props.actions.setOperateActionSendToAgencyProcess(dossiers, this.props.fleet.fleetId) }, null, null, null, [{ id: replaceableFields.numDossiers, value: dossiers.length }], null)
    }
  }

  setOperateActionPrintDeliveryNoteProcess (dossiers) {
    if (this.validateDistinctStage(dossiers)) {
      this.props.actions.openModal('notDistinctStage')
    } else {
      this.props.actions.openModal('setOperateActionPrintDeliveryNoteProcessConfirm', () => { this.props.actions.setOperateActionPrintDeliveryNoteProcess(dossiers, this.props.fleet.fleetId, null) }, null, null, null, [{ id: replaceableFields.numDossiers, value: dossiers.length }], null)
    }
  }

  setOperateActionDeleteProcess (dossiers) {
    this.props.actions.openModal('setOperateActionDeleteProcessConfirm', () => { this.setState({ showCancelReasonModal: true }) }, null, null, null, [{ id: replaceableFields.numDossiers, value: dossiers.length }], null)
  }

  setOperateActionStoreProcess (dossiers) {
    if (this.validateDistinctStage(dossiers)) {
      this.props.actions.openModal('notDistinctStage')
    } else if (!this.areSaleLastStage(dossiers)) {
      this.props.actions.openModal('saleDossiersNotInLastStage')
    } else {
      this.props.actions.openModal('setOperateActionStoreProcessConfirm', () => { this.props.actions.setOperateActionStoreProcess(dossiers, this.props.fleet.fleetId, null) }, null, null, null, [{ id: replaceableFields.numDossiers, value: dossiers.length }], null)
    }
  }

  setOperateActionJvApproval (dossiers) {
    if (this.validateDistinctStage(dossiers)) {
      this.props.actions.openModal('notDistinctStage')
    } else {
      this.props.actions.openModal('setOperateActionSendToApprovalProcessConfirm', () => { this.props.actions.setApproveActionProcess(dossiers.map(x => x.dossierId).join('$'), 3, approvalRoleCode.SALES_MANAGER, null) }, null, null, null, [{ id: replaceableFields.numDossiers, value: dossiers.length }], null)
    }
  }

  setOperateActionAdministrationApproval (dossiers) {
    if (this.validateDistinctStage(dossiers)) {
      this.props.actions.openModal('notDistinctStage')
    } else {
      this.props.actions.openModal('setOperateActionSendToApprovalProcessConfirm', () => { this.props.actions.setApproveActionProcess(dossiers.map(x => x.dossierId).join('$'), 3, approvalRoleCode.ADMINISTRATION, null) }, null, null, null, [{ id: replaceableFields.numDossiers, value: dossiers.length }], null)
    }
  }

  setOperateActionManagerApproval (dossiers) {
    if (this.validateDistinctStage(dossiers)) {
      this.props.actions.openModal('notDistinctStage')
    } else {
      this.props.actions.openModal('setOperateActionSendToApprovalProcessConfirm', () => { this.props.actions.setApproveActionProcess(dossiers.map(x => x.dossierId).join('$'), 3, approvalRoleCode.MANAGER, null) }, null, null, null, [{ id: replaceableFields.numDossiers, value: dossiers.length }], null)
    }
  }

  setAuthorizeFinanceOperateFleet (dossiers) {
    if (this.validateDistinctStage(dossiers)) {
      this.props.actions.openModal('notDistinctStage')
    } else {
      this.props.actions.openModal('setOperateActionSendToApprovalProcessConfirm', () => { this.props.actions.setApproveActionProcess(dossiers.map(x => x.dossierId).join('$'), approvalType.ROLE_AUTHORIZE_FINANCE, approvalRoleCode.APPROVE_AUTHORIZE_FINANCE, null) }, null, null, null, [{ id: replaceableFields.numDossiers, value: dossiers.length }], null)
    }
  }

  setAuthorizeManagementOperateFleet (dossiers) {
    if (this.validateDistinctStage(dossiers)) {
      this.props.actions.openModal('notDistinctStage')
    } else {
      this.props.actions.openModal('setOperateActionSendToApprovalProcessConfirm', () => { this.props.actions.setApproveActionProcess(dossiers.map(x => x.dossierId).join('$'), approvalType.ROLE_AUTHORIZE_MANAGEMENT, approvalRoleCode.APPROVE_AUTHORIZE_MANAGEMENT, null) }, null, null, null, [{ id: replaceableFields.numDossiers, value: dossiers.length }], null)
    }
  }

  setOperateActionFleetApproval (dossiers) {
    if (this.validateDistinctStage(dossiers)) {
      this.props.actions.openModal('notDistinctStage')
    } else {
      this.props.actions.openModal('setOperateActionSendToApprovalProcessConfirm', () => { this.props.actions.setApproveActionProcess(dossiers.map(x => x.dossierId).join('$'), 3, approvalRoleCode.FLEETS, null) }, null, null, null, [{ id: replaceableFields.numDossiers, value: dossiers.length }], null)
    }
  }

  setOperateActionAuthorizeManagementApproval (dossiers) {
    if (this.validateDistinctStage(dossiers)) {
      this.props.actions.openModal('notDistinctStage')
    } else {
      this.props.actions.openModal('setOperateActionSendToApprovalProcessConfirm', () => { this.props.actions.setApproveActionProcess(dossiers.map(x => x.dossierId).join('$'), 3, approvalRoleCode.FLEETS, null) }, null, null, null, [{ id: replaceableFields.numDossiers, value: dossiers.length }], null)
    }
  }

  createServiceData (dossiers) {
    if (this.validateDistinctStage(dossiers) || this.validateDistinctUo(dossiers)) {
      this.props.actions.openModal('notDistinctUoAndStage')
    } else {
      new Promise((resolve) => {
        this.props.actions.fetchGetCanCreateOperateServices(dossiers[0].organizedUnitId, this.props.fleet.dossierSubTypeId, resolve)
      }).then((response) => {
        if (response && response.length > 0) {
          this.setState({ subTypesAllowedToCreate: response, dossierList: dossiers })
          this.openOrCloseServiceAddModal(true)
        } else {
          this.props.actions.openModal('notServiceExpedientesAvailable')
        }
      })
    }
  }

  openOrCloseServiceAddModal (value) {
    this.props.actions.fetchDossierSubTypes(null, dossierTypesId.sales)
    this.setState({ showServiceModal: value })
  }

  render () {
    const {
      t,
      showModal, handleSubmit, dataActions, operationActions, fleet, cancelCombo,
      actions: {
        fetchReasons,
        fetchCancelCombo,
        fetchDefaultEntity,
        fetchDossierSubTypes,
        fetchEntitySubTypeCombo,
        fetchNotValidDatesService,
        fetchEntityFilterSubTypeCombo,
        setOperateDataServiceFleetProcess
      }
    } = this.props

    const tKey = 'MANAGEMENT.FLEETS_TAB.'
    let title = t(`${tKey}TITLE_OPERATE_FLEET`)

    const selectedDossiers = this.props.dossierFleetDetails.filter(dossier => dossier.checked)

    if (!showModal || !this.props.fleet) { return null } else {
      return (
        <>
          <Modal className="fleet-Modal languages-modal" show={showModal} onHide={this.props.actions.closeOperateFleetModal} backdrop={'static'}>
            <form className='formEditFleet' autoComplete="off" onSubmit={handleSubmit(() => this.props.actions.closeOperateFleetModal())}>
              <Modal.Header closeButton onHide={this.props.actions.closeOperateFleetModal}>
                <Modal.Title>{title}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <FleetAndClientComponentPage
                  {...this.props}
                />
                <RejectCommentModal
                  t={t}
                  showModal={this.state.showRejectCommentModal}
                  reasonCombo={this.props.combos.reasonCombo}
                  dossierSubTypeId={this.props.fleet.dossierSubType}
                  actions={{
                    rejectAction: (comment, resolve, reject) => { this.props.actions.setOperateActionRejectProcess(comment, selectedDossiers, this.props.fleet.fleetId, () => { this.toggleRejectCommentModal(false) }) },
                    closeModal: () => this.toggleRejectCommentModal(false),
                    fetchReasons: fetchReasons
                  }}
                  backDropStatic={true}
                />
                <CancelReasonModal
                  t={t}
                  showModal={this.state.showCancelReasonModal}
                  combos={cancelCombo}
                  type={dossierTypesConstant.sales}
                  actions={{
                    rejectAction: (comment, resolve, reject) => {
                      this.props.actions.setOperateActionDeleteProcess(comment, selectedDossiers, this.props.fleet.fleetId, () => { this.props.actions.closeOperateFleetModal() })
                      this.setState({ showCancelReasonModal: false })
                    },
                    closeModal: () => {
                      this.setState({ showCancelReasonModal: false })
                    },
                    fetchCancelCombo: fetchCancelCombo
                  }}
                />
                {!this.props.create && (
                  <>
                    <DossiersListFleetDetailsComponentPage fleet={this.props.fleet} changeCheckAll={this.state.changeCheckAll} />
                    <Row className='button-container'>
                      <Field
                        id="dataId"
                        name='dataId'
                        placeholder={t(`${tKey}DATA`)}
                        controlLabel={t(`${tKey}DATA`)}
                        component={InputSelect}
                        options={dataActions}
                        valueKey="managementActionId"
                        labelKey="name"
                        disabled={selectedDossiers.length === 0}
                        onInputChange={(val) => { document.activeElement?.blur(); this.launchAction(val, operationTabs.DATA); this.setState({ dataId: val }) }}
                      />
                      <Field
                        id="actionId"
                        name='actionId'
                        placeholder={t(`${tKey}ACTIONS`)}
                        controlLabel={t(`${tKey}ACTIONS`)}
                        component={InputSelect}
                        options={operationActions}
                        valueKey="managementActionId"
                        labelKey="name"
                        disabled={selectedDossiers.length === 0}
                        onInputChange={(val) => { this.launchAction(val, operationTabs.OPERATIONS); this.setState({ actionId: val }) }}

                      />
                      {this.props.fieldsConfiguration && this.props.fieldsConfiguration.documentsFleet && this.props.fieldsConfiguration.documentsFleet.permission === permissions.editable && (
                        <Button
                          className="btn-Load document-button"
                          onClick={() => this.openDocumentsModal(fleet, selectedDossiers)}
                          disabled={selectedDossiers.length === 0}>
                          {t(`${tKey}DOCUMENTS`)}
                        </Button>)}
                      {this.props.fieldsConfiguration && this.props.fieldsConfiguration.economicFleet && this.props.fieldsConfiguration.economicFleet.permission && this.props.fieldsConfiguration.economicFleet.permission === permissions.editable && (
                        <Button
                          className="btn-Load"
                          onClick={() => this.openEconomicPlan(fleet, selectedDossiers)}
                          disabled={selectedDossiers.length === 0}>
                          {t(`${tKey}FINANCIAL_ANALYSIS`)}
                        </Button>)}
                    </Row>

                    <PaperworkFleetListComponentPage fleet = {fleet} fieldsConfiguration={this.props.fieldsConfiguration}
                      openDocumentsModal = {(fleet, selectedDossiers) => this.openDocumentsModal(fleet, selectedDossiers)}
                      launchAction = {(val, tabId, dossiers) => { this.launchAction(val, tabId, dossiers); this.setState({ actionId: val }) }}
                    ></PaperworkFleetListComponentPage>

                    <ChargeFleetListComponentPage fleet = {fleet} fieldsConfiguration={this.props.fieldsConfiguration} dossierSubType={this.props.fleet.dossierSubType} setCharges = {() => this.setChargeData(this.props.dossierFleetDetails)}>
                    </ChargeFleetListComponentPage>

                  </>

                )}

              </Modal.Body>
              <Modal.Footer>
                <Col sm={12} className="buttons-wrapper">
                  <Button
                    className="btn-Accept"
                    type="submit">
                    {t(`${tKey}ACCEPT`)}
                  </Button>
                </Col>
              </Modal.Footer>
            </form>
          </Modal>
          <DataSalesFleetModalPage
            componentConfiguration = {this.props.componentsModalConfiguration ? this.props.componentsModalConfiguration.componentsConfiguration.find(x => x.code === saleCodes.bsale) : null}
          ></DataSalesFleetModalPage>
          <DataFinancingFleetModalPage
            componentConfiguration = {this.props.componentsModalConfiguration ? this.props.componentsModalConfiguration.componentsConfiguration.find(x => x.code === saleCodes.bsale) : null}
          ></DataFinancingFleetModalPage>
          <DataContactFleetModalPage></DataContactFleetModalPage>
          <DataPaperWorkFleetModalPage></DataPaperWorkFleetModalPage>
          <DataDeliveryFleetModalPage
            componentConfiguration={this.props.componentsModalConfiguration ? this.props.componentsModalConfiguration.componentsConfiguration.find(x => x.code === saleCodes.bsale) : null}>
          </DataDeliveryFleetModalPage>
          <ServiceAddModalPage
            t={t}
            showModal={this.state.showServiceModal}
            ouId={this.state.dossierList.length > 0 ? this.state.dossierList[0].organizedUnitId : this.props.fleet.organizedUnitId}
            subTypesAllowedToCreate={this.state.subTypesAllowedToCreate}
            dossierId={-1}
            datesNotValid={this.props.dossierServiceRelateds.datesNotValid || []}
            actions={{
              openOrCloseAddServiceModal: () => this.openOrCloseServiceAddModal(false),
              fetchDossierSubTypes,
              fetchEntityFilterSubTypeCombo,
              fetchEntitySubTypeCombo,
              saveSalesService: () => null,
              fetchDefaultEntity,
              fetchNotValidDatesService,
              setOperateDataServiceFleetProcess
            }}
            dossierConstriction= {{
              listSubTypesWithOrderDateTodayNoEditable: this.listSubTypesWithOrderDateTodayNoEditable || [],
              listSubTypesWithDefaultEntity: this.listSubTypesWithDefaultEntity || [],
              listSubTypesWithBlockedEntity: this.listSubTypesWithBlockedEntity || [],
              listSubTypesWithRequiredDatesInvalids: this.listSubTypesWithRequiredDatesInvalids || [],
              listSubTypesWithOrderDateByDefault: this.listSubTypesWithOrderDateByDefault || [],
              listSubTypesWithMandatoryObservations: this.listSubTypesWithMandatoryObservations || []
            }}
            combos= {this.props.combos}
            isFleet={true}
            fleet = {this.props.fleet}
            dossierList = {this.state.dossierList}
          />
        </>
      )
    }
  }
}

export default reduxForm({
  form: 'operateFleetModal',
  destroyOnUnmount: false
})(OperateFleetModal)
