
import React, { PureComponent } from 'react'
import { Modal, Col, Button, Tooltip, OverlayTrigger } from 'react-bootstrap'
import { reduxForm } from 'redux-form'
import SimpleTablePage from '../../../containers/commons/general/SimpleTablePage'
import AddUsersPage from '../../../containers/masters/Repairshop/AddUsersPage'
import { compareValues } from '../../../util/utils'
import IDocCarPagination from '../../commons/iDocCarPagination'

class RepairshopUsersAddModal extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      pageFilter: 1,
      sortType: 'name',
      sortAsc: true,
      filters: {
        orderBy: '+completName',
        page: 1,
        pages: 1,
        maxRows: 10,
        count: 0
      },
      userrepairshop: [],
      isNewUser: false
    }
  }

  componentDidMount () {
  }

  componentDidUpdate () {
  }

  getSortIcon (column = '') {
    switch (this.state.filters.orderBy) {
      case '+' + column:
        return 'ico-sort-selected-asc'
      case '-' + column:
        return 'ico-sort-selected-desc'
      default:
        return 'ico-sort'
    }
  }

  onPageChange (page) {
    this.setState({ pageFilter: page })
  }

  onOrderChange (e) {
    this.setState({ sortType: e, sortAsc: !this.state.sortAsc })
  }

  order (field, order, data) {
    let orderData = JSON.parse(JSON.stringify(data))
    orderData = orderData.sort(compareValues(field, order.includes('+') ? 'asc' : 'desc'))
    return orderData
  }

  getIconClass (field) {
    const filters = this.state.filters
    if (filters && filters.orderBy.includes(field)) {
      return filters.orderBy.includes('-')
        ? 'ico-sort-selected-asc'
        : 'ico-sort-selected-desc'
    } else {
      return 'ico-sort'
    }
  }

  isNewUser () {
    this.setState({ isNewUser: !this.state.isNewUser })
  }

  sorted () {
    if (this.state.sortAsc) {
      return this.props.repairshop?.repairshopModal?.userList.sort((a, b) => a[this.state.sortType].localeCompare(b[this.state.sortType]))
    }
    return this.props.repairshop?.repairshopModal?.userList.sort((a, b) => b[this.state.sortType].localeCompare(a[this.state.sortType]))
  }

  deleteuserRepairshopId (userRepairshopId) {
    this.props.actions.deleteUserRepairshop(userRepairshopId)
    this.props.actions.closeRepairshopMasterModal()
    let list = this.props.repairshop.repairshopModal.repairshop
    this.props.actions.openRepairshopUsersModal(list.filter(x => x.userRepairshopId !== userRepairshopId), this.props.repairshop.repairshopId)
  }

  render () {
    const {
      t, showModal, repairshop
    } = this.props
    const tKey = 'MASTERS.REPAIR_SHOPS_MASTERS.MODAL.'
    const deleteTooltip = (<Tooltip id="deleteTooltip">{t(`${tKey}DELETE_USER`)}</Tooltip>)
    return (
      <>
        <Modal className="role-modal languages-modal angencyUsersModal" show={showModal} onHide={this.props.actions.closeuserRepairshopModal} backdrop={'static'}>
          <Modal.Header closeButton onHide={this.props.actions.closeRepairshopMasterModal}>
            <Modal.Title>{t(`${tKey}TITLE_USERS`)}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            { this.state.isNewUser
              ? <AddUsersPage repairshopId={this.props.repairshop.repairshopId} />
              : (<>
                <SimpleTablePage
                  columns={[
                    <th key={0} data-field="userRepairshopId" style={{ display: 'none' }}>
                    </th>,
                    <th key={1} data-field="completName" onClick={(event, value) => this.onOrderChange('completName')}>
                      {t(`${tKey}COMPLET_NAME`)}&nbsp;<i className={this.getIconClass('completName')}></i>
                    </th>,
                    <th key={2} data-field="email" onClick={(event) => this.onOrderChange('email')}>
                      {t(`${tKey}EMAIL`)}&nbsp;<i className={this.getIconClass('email')} />
                    </th>,
                    <th key={3} data-field="creationDate" onClick={(event) => this.onOrderChange('creationDate')}>
                      {t(`${tKey}CREATION_DATE`)}&nbsp;<i className={this.getIconClass('creationDate')}></i>
                    </th>,
                    <th key={4} data-field="actions">
                      {t(`${tKey}ACTIONS`)}&nbsp;
                    </th>
                  ]}
                  rows={repairshop?.repairshopModal?.userList && this.sorted()?.map((row, idx) => (
                    idx <= (this.state.pageFilter * 10) && idx >= (this.state.pageFilter * 10 - 10) ? ( // ARREGLA ESTO AQUI ?
                      <tr key={idx}>
                        <td>{row.completName}</td>
                        <td>{row.email}</td>
                        <td>{row.creationDate}</td>
                        <td className='actions'>{<a onClick={() => this.deleteuserRepairshopId(row.userRepairshopId)}>
                          <OverlayTrigger placement="left" overlay={deleteTooltip}>
                            <i className="ico-trash" />
                          </OverlayTrigger>
                        </a>}</td>
                      </tr>
                    ) : (<></>)))}
                ></SimpleTablePage>
                <div className="search-footer" key="search-footer">
                  <IDocCarPagination
                    id="btn_pag_sales"
                    page={this.state.pageFilter}
                    pagesCount={repairshop?.repairshopModal?.userList.length / 10 }
                    totalRows= {repairshop?.repairshopModal?.userList.length}
                    onSelectAction={(page) => this.onPageChange(page)}
                  />
                </div></>
              )
            }
          </Modal.Body>
          <Modal.Footer>
            <Col sm={12} className="btn-wrapper">
              { !this.state.isNewUser
                ? <Button
                  id="master_agency_add_usser"
                  className="btn-standard"
                  onClick={() => { this.isNewUser() }}>
                  {t(`${tKey}ADD_USER`)}
                </Button>
                : <Button
                  id="master_agency_add_usser"
                  className="btn-standard"
                  onClick={() => { this.isNewUser() }}>
                  {t(`${tKey}RETURN`)}
                </Button>
              }
            </Col>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}

export default reduxForm({
  form: 'repairshopUsersAddModal'
})(RepairshopUsersAddModal)
