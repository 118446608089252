import apiFetch from '../apiFetch'
export default function (token, campaignDetailsId, campaignGroupId) {
  const queryParamsArray = [
    campaignDetailsId ? `campaignDetailsId=${campaignDetailsId}` : null,
    campaignGroupId ? `campaignGroupId=${campaignGroupId}` : null
  ]

  const queryParams = queryParamsArray.reduce(
    (result, query, idx) => result + (query ? (result ? `&${query}` : `?${query}`) : '')
    , ''
  )
  return apiFetch({ endPoint: `ConditionDocumentalCampaign/GetConditions${queryParams}`, method: 'GET', body: null, token: token })
}
