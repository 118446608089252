import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import FuelMasters from '../../../components/masters/Fuel/FuelMaster'
import { openEditFuelModal, deleteFuel, recoverFuel, fetchFuelList, openAddFuelModal } from '../../../actions/masters/masters'

export function mapStateToProps (state) {
  return {
    ...state.fuel
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      openEditFuelModal,
      openAddFuelModal,
      deleteFuel,
      recoverFuel,
      fetchFuelList
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(FuelMasters))
