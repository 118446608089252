import { put, call, select, takeEvery } from 'redux-saga/effects'
import { getAuth } from '../../../selectors/access/auth'
import { reset } from 'redux-form'
import { yesNoModal } from '../../modalsListeners/yesNoModal'
import dossierActionsTypes from '../../../constants/actions/dossier/dossier'
import { dossierTypes as dossierTypesConstant } from '../../../constants/dossier/common/dossierTypes'
import messageModalActions from '../../../actions/modals/messageModal'
import { showLoader, hideLoader } from '../../../actions/common'
import { handleError } from '../../errors/errorManager'
import postFleetDossier from '../../../services/dossier/post/postFleetDossier'
import { initializeDossier } from '../commonDossierSagas'
import { fetchSalesDossierSuccess } from '../../../actions/dossier/sales'

export function * saveFleetCode ({ code, resolve, reject }) {
  try {
    const auth = yield select(getAuth)
    let salesDossierState = yield select(state => state.salesDossier)
    let dossierFleet = {
      dossierId: salesDossierState.dossierId,
      code: code
    }
    yield call(postFleetDossier, dossierFleet, auth.token)
    salesDossierState.salesComponent.fleetCode = code
    yield put(fetchSalesDossierSuccess({ ...salesDossierState }))
    yield put(showLoader())
    if (resolve) yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          const salesDossierState = yield select(state => state.salesDossier)
          yield call(initializeDossier, dossierTypesConstant.sales, salesDossierState)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
      if (reject) yield call(reject, customHandlerError)
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSaveFleetCode () {
  yield takeEvery(dossierActionsTypes.SAVE_FLEET_CODE, saveFleetCode)
}
