import React, { PureComponent } from 'react'
import { Col, FormControl, FormGroup, ControlLabel } from 'react-bootstrap'

class InputMoney extends PureComponent {
  constructor (props) {
    super()
    this.state = {
      realValue: ('' + props.input.value).trim(),
      showedValue: ('' + props.input.value).trim() !== '' ? props.input.value : '',
      hasFocus: false
    }
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (prevProps.input.value !== this.props.input.value) {
      let isValid = false
      let realValue = ('' + this.props.input.value).trim()
      let showedValue = ('' + this.props.input.value).trim()

      // Comprobar si el valor introducido es un número con separador de decimales. Este separador puede ser ',' o '.'.
      isValid = this.isValid(realValue)

      if (isValid) {
        // Sanitizar: convertir ',' por '.' y eliminar el exceso de decimales.
        realValue = realValue.replace(',', '.').replace(/^(.*?\.\d{2})(\d*)/, '$1')
        showedValue = realValue
      } else if (realValue !== '') {
        realValue = this.state.realValue
        showedValue = this.state.showedValue
      }

      this.setState({
        realValue: realValue,
        showedValue: showedValue
      })
    }
    return null
  }

  componentDidUpdate () {
    // getSnapshotBeforeUpdate() should be used with componentDidUpdate()
  }

  isValid (value) {
    return /^[+-]?[\d]+[,.]?[\d]*$/.test(value)
  }

  handleChange (event) {
    if (this.isValid(event.target.value) || event.target.value === '') {
      this.props.input.onChange(event)
      if (this.props.onInputChange) {
        this.props.onInputChange(event)
      }
    }
  }

  handleFocus (event) {
    const currentTarget = event.currentTarget
    this.setState({
      showedValue: ('' + this.state.realValue).replace(/^(.*?)\.?$/, '$1'), // Si no hay decimales se eliminara el separador.
      hasFocus: true
    }, () => {
      // Timeout requerido en los navegadores Explorer y Edge.
      window.setTimeout(() => {
        currentTarget.select() // Seleccionar el contenido del input.
      }, 50)
    })
  }

  handleBlur (event) {
    const newState = { hasFocus: false }
    let value
    if (!isNaN(this.state.realValue)) {
      // Eliminar los ceros a la izquierda
      value = ('' + this.state.realValue).replace(/^([+-]?)(0*)([1-9]\d*|\d)(\.\d{1,2})?$/, '$1$3$4')
      newState.realValue = value
      newState.showedValue = value
    }
    this.setState(newState)
  }

  render () {
    const {
      id,
      colSm = 3,
      meta,
      showError = false,
      rightIcon = '',
      leftIcon = '',
      controlLabel,
      componentClass,
      customClass = '',
      disabled = false,
      textAlign,
      paddingLeft
    } = this.props
    const errorMessage = ((showError || meta.touched) && meta.error && typeof meta.error !== 'boolean')
      ? meta.error
      : null
    return (
      <Col sm={colSm} className={customClass} style={paddingLeft}>
        <FormGroup
          className={rightIcon ? 'input-with-icon' : ''}
          validationState={(showError || meta.touched) && meta.error ? 'error' : null}>
          {controlLabel && <ControlLabel>{controlLabel}</ControlLabel>}
          <FormControl
            style={{ textAlign: textAlign || 'right' }}
            value={this.state.showedValue}
            id={id}
            disabled={disabled}
            type="text"
            placeholder={'0.00'}
            onChange={this.handleChange.bind(this)}
            componentClass={componentClass}
            onFocus={this.handleFocus.bind(this)}
            onBlur={this.handleBlur.bind(this)}
          />
          <i className={rightIcon || leftIcon} />
          {errorMessage && <span className='help-block text-center'>{errorMessage}</span>}
        </FormGroup>
      </Col>
    )
  }
}

export default InputMoney
