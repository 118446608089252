import { call, takeEvery, select, put } from 'redux-saga/effects'
import { initialize, reset } from 'redux-form'
import dossierActionsTypes from '../../../constants/actions/dossier/dossier'
import { dossierTypes as dossierTypesConstant } from '../../../constants/dossier/common/dossierTypes'
import { showLoader, hideLoader } from '../../../actions/common'
import messageModalActions from '../../../actions/modals/messageModal'
import { yesNoModal } from '../../modalsListeners/yesNoModal'

import { getAuth } from '../../../selectors/access/auth'
import { initializeDossier } from '../commonDossierSagas'
import putServiceApproval from '../../../services/serviceDossier/put/putServiceApproval'
import { handleError } from '../../errors/errorManager'
import { fetchServiceDossier } from './fetchServiceDossier'

import putApproveByRole from '../../../services/dossierService/putApproveByRole'
import putResolveIssue from '../../../services/dossierService/putResolveIssue'
import putRequestSpareParts from '../../../services/dossierService/putRequestSpareParts'
import putCompleteSpareParts from '../../../services/dossierService/putCompleteSpareParts'
import putCompleteDeliveryPreparation from '../../../services/dossierService/putCompleteDeliveryPreparation'
import { compareDate } from '../../../util/validationFunctions'
import { approvalRoleCode as approvalType, clientInformation } from '../../../constants/backendIds'
import { saveServiceDossier } from '../../../actions/dossier/serviceDossier'
import { fieldModal } from '../../modalsListeners/fieldModal'

import setting from '../../../setting'

import { fetchDossierServicePrepare } from '../../search/search'

export function * serviceDossierWarningSave ({ reset, router, nextLocation, pristine }) {
  if (!pristine) {
    const confirmedSaveWarning = yield call(yesNoModal, 'commonDossierSaveWarning')
    if (confirmedSaveWarning) {
      const promise = new Promise((resolve, reject) => {
        reset('service_dossier')
        resolve()
      })
      promise.then(() => {
        router.replace(nextLocation)
      })
    }
  }
}

export function * watchServiceDossierWarningSave () {
  yield takeEvery(dossierActionsTypes.SERVICE_DOSSIER_WARNING_SAVE, serviceDossierWarningSave)
}

export function * serviceDossierApprove ({ dossierId, reset }) {
  try {
    const auth = yield select(getAuth)
    const serviceDossierState = yield select(state => state.serviceDossier)
    const confirmed = yield call(yesNoModal, 'serviceProviderApprove')
    if (confirmed) {
      yield put(showLoader())
      yield call(putServiceApproval, dossierId, auth.token)
      yield call(initializeDossier, dossierTypesConstant.service, serviceDossierState)
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          const serviceDossierState = yield select(state => state.serviceDossier)
          yield call(initializeDossier, dossierTypesConstant.service, serviceDossierState)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('service_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchServiceDossierApprove () {
  yield takeEvery(dossierActionsTypes.SERVICE_DOSSIER_APPROVE, serviceDossierApprove)
}

export function * serviceApproveByRole ({ pristine, approvalRoleCode }) {
  try {
    if (!pristine) {
      const confirmedSaveWarning = yield call(yesNoModal, 'commonDossierSaveWarning')
      if (confirmedSaveWarning) {
        const auth = yield select(getAuth)
        const salesDossierState = yield select(state => state.serviceDossier)
        const confirmed = yield call(yesNoModal, 'saleDossierApproveByRole')
        if (confirmed) {
          if (approvalRoleCode === approvalType.approveServiceCompletePap && !salesDossierState.dossierServiceComponent.providerObservations &&
              salesDossierState.dossierServiceComponent.confirmedDate && salesDossierState.dossierServiceComponent.requiredDate) {
            const retCompareDates = compareDate(salesDossierState.dossierServiceComponent.requiredDate, salesDossierState.dossierServiceComponent.confirmedDate)
            if (retCompareDates.secondDateIsAfter) {
              yield put(initialize('customModal'))
              const providerObservations = yield call(fieldModal, 'putProviderObservations')
              if (providerObservations) {
                yield put(showLoader())
                const updateSalesDossier = yield select(state => state.serviceDossier)
                updateSalesDossier.dossierServiceComponent.providerObservations = providerObservations
                yield put(saveServiceDossier(updateSalesDossier))
                yield put(hideLoader())
              }
            }
          }
          yield put(showLoader())
          yield call(putApproveByRole, salesDossierState.dossierId, approvalRoleCode, auth.token, salesDossierState.lastAccessDate)
          yield call(fetchServiceDossier, { dossierId: salesDossierState.dossierId })
          const updatedSalesDossier = yield select(state => state.serviceDossier)
          yield put(initialize('service_dossier', updatedSalesDossier))
        }
      }
    } else {
      const auth = yield select(getAuth)
      const salesDossierState = yield select(state => state.serviceDossier)
      const confirmed = yield call(yesNoModal, 'saleDossierApproveByRole')
      if (confirmed) {
        if (approvalRoleCode === approvalType.approveServiceCompletePap && !salesDossierState.dossierServiceComponent.providerObservations &&
          salesDossierState.dossierServiceComponent.confirmedDate && salesDossierState.dossierServiceComponent.requiredDate) {
          const retCompareDates = compareDate(salesDossierState.dossierServiceComponent.requiredDate, salesDossierState.dossierServiceComponent.confirmedDate)
          if (retCompareDates.secondDateIsAfter) {
            yield put(initialize('customModal'))
            const providerObservations = yield call(fieldModal, 'putProviderObservations')
            if (providerObservations) {
              yield put(showLoader())
              const updateSalesDossier = yield select(state => state.serviceDossier)
              updateSalesDossier.dossierServiceComponent.providerObservations = providerObservations
              yield put(saveServiceDossier(updateSalesDossier))
              yield put(hideLoader())
            }
          }
        }
        yield put(showLoader())
        yield call(putApproveByRole, salesDossierState.dossierId, approvalRoleCode, auth.token, salesDossierState.lastAccessDate)
        yield call(fetchServiceDossier, { dossierId: salesDossierState.dossierId })
        const updatedSalesDossier = yield select(state => state.serviceDossier)
        yield put(initialize('service_dossier', updatedSalesDossier))
      }
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          const salesDossierState = yield select(state => state.serviceDossier)
          yield call(initializeDossier, dossierTypesConstant.service, salesDossierState)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('service_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}
export function * watchServiceApproveByRole () {
  yield takeEvery(dossierActionsTypes.APPROVE_SERVICE_DOSSIER, serviceApproveByRole)
}

export function * serviceResolveIssue ({ pristine }) {
  try {
    if (!pristine) {
      const confirmedSaveWarning = yield call(yesNoModal, 'commonDossierSaveWarning')
      if (confirmedSaveWarning) {
        const auth = yield select(getAuth)
        const serviceDossierState = yield select(state => state.serviceDossier)
        const confirmed = yield call(yesNoModal, 'serviceResolveIssue')
        if (confirmed) {
          yield put(showLoader())
          yield call(putResolveIssue, serviceDossierState.dossierId, auth.token, serviceDossierState.lastAccessDate)
          yield call(fetchServiceDossier, { dossierId: serviceDossierState.dossierId })
          const updatedSalesDossier = yield select(state => state.serviceDossier)
          yield put(initialize('service_dossier', updatedSalesDossier))
        }
      }
    } else {
      const auth = yield select(getAuth)
      const serviceDossierState = yield select(state => state.serviceDossier)
      const confirmed = yield call(yesNoModal, 'serviceResolveIssue')
      if (confirmed) {
        yield put(showLoader())
        yield call(putResolveIssue, serviceDossierState.dossierId, auth.token, serviceDossierState.lastAccessDate)
        yield call(fetchServiceDossier, { dossierId: serviceDossierState.dossierId })
        const updatedSalesDossier = yield select(state => state.serviceDossier)
        yield put(initialize('service_dossier', updatedSalesDossier))
      }
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          const serviceDossierState = yield select(state => state.serviceDossier)
          yield call(initializeDossier, dossierTypesConstant.service, serviceDossierState)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('service_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchserviceResolveIssue () {
  yield takeEvery(dossierActionsTypes.RESOLVE_SERVICE_ISSUE, serviceResolveIssue)
}

export function * serviceRequestSpareParts ({ pristine, dossierId, resolve }) {
  try {
    if (!pristine && dossierId == null) {
      const confirmedSaveWarning = yield call(yesNoModal, 'commonDossierSaveWarning')
      if (confirmedSaveWarning) {
        const auth = yield select(getAuth)
        const serviceDossierState = yield select(state => state.serviceDossier)
        const confirmed = yield call(yesNoModal, 'serviceRequestSpareParts')
        if (confirmed) {
          yield put(showLoader())
          yield call(putRequestSpareParts, serviceDossierState.dossierId, auth.token, serviceDossierState.lastAccessDate)
          yield call(fetchServiceDossier, { dossierId: serviceDossierState.dossierId })
          const updatedSalesDossier = yield select(state => state.serviceDossier)
          yield put(initialize('service_dossier', updatedSalesDossier))
          if (resolve) resolve(true)
        }
      }
    } else if ((pristine == null || pristine) && dossierId !== undefined && dossierId !== null) {
      const serviceDossierState = yield select(state => state.serviceDossier)
      if (setting.clientFieldValidation === clientInformation.VGR) {
        const orNumber = yield call(fieldModal, 'serviceRequestSparePartsORNumberField')
        if (orNumber !== false) {
          const auth = yield select(getAuth)
          yield put(showLoader())
          yield call(putRequestSpareParts, dossierId, auth.token, getDossierDateFromState(serviceDossierState), orNumber)
          if (pristine) {
            yield call(fetchServiceDossier, { dossierId })
          }
          yield put(hideLoader())
          yield put(messageModalActions.openSuccessMessageModal('MESSAGES.REQUEST_SPARE_PARTS_SUCCES'))
          if (orNumber) {
            yield call(yesNoModal, 'orNumberSavedSusccesfully')
            yield put(showLoader())
            const payload = {}
            payload.filters = {
              ...yield select(state => state.search.dynamicFilters.servicePreparationDelivery),
              ...yield select(state => state.search.filters)
            }
            yield call(fetchDossierServicePrepare, payload)
            if (resolve) resolve(orNumber)
          } else {
            if (resolve) resolve(true)
          }
        }
      } else {
        const confirmed = yield call(yesNoModal, 'serviceRequestSpareParts')
        if (confirmed) {
          const auth = yield select(getAuth)
          yield put(showLoader())
          yield call(putRequestSpareParts, dossierId, auth.token, getDossierDateFromState(serviceDossierState))
          yield put(messageModalActions.openSuccessMessageModal('MESSAGES.REQUEST_SPARE_PARTS_SUCCES'))
          if (resolve) resolve(true)
        }
      }
    } else {
      const auth = yield select(getAuth)
      const serviceDossierState = yield select(state => state.serviceDossier)
      const confirmed = yield call(yesNoModal, 'serviceRequestSpareParts')
      if (confirmed) {
        yield put(showLoader())
        yield call(putRequestSpareParts, serviceDossierState.dossierId, auth.token, serviceDossierState.lastAccessDate)
        yield call(fetchServiceDossier, { dossierId: serviceDossierState.dossierId })
        const updatedSalesDossier = yield select(state => state.serviceDossier)
        yield put(initialize('service_dossier', updatedSalesDossier))
        if (resolve) resolve(true)
      }
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          const serviceDossierState = yield select(state => state.serviceDossier)
          yield call(initializeDossier, dossierTypesConstant.service, serviceDossierState)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('service_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
    if (resolve) resolve(false)
  }
}

const getDossierDateFromState = (serviceDossierState) => {
  if (!serviceDossierState.lastAccessDate) {
    const today = new Date()
    return `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()} ${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`
  }
  return serviceDossierState.lastAccessDate
}
export function * watchServiceRequestSpareParts () {
  yield takeEvery(dossierActionsTypes.REQUEST_SERVICE_SPARE_PARTS, serviceRequestSpareParts)
}

export function * serviceCompleteSpareParts ({ pristine, dossierId, resolve }) {
  try {
    if (!pristine && dossierId == null) {
      const confirmedSaveWarning = yield call(yesNoModal, 'commonDossierSaveWarning')
      if (confirmedSaveWarning) {
        const auth = yield select(getAuth)
        const serviceDossierState = yield select(state => state.serviceDossier)
        const confirmed = yield call(yesNoModal, 'serviceCompleteSpareParts')
        if (confirmed) {
          yield put(showLoader())
          yield call(putCompleteSpareParts, serviceDossierState.dossierId, auth.token, serviceDossierState.lastAccessDate)
          yield call(fetchServiceDossier, { dossierId: serviceDossierState.dossierId })
          const updatedSalesDossier = yield select(state => state.serviceDossier)
          yield put(initialize('service_dossier', updatedSalesDossier))
          if (resolve) resolve(true)
        }
      }
    } else if (pristine == null && dossierId !== undefined && dossierId !== null) {
      const confirmed = yield call(yesNoModal, 'serviceCompleteSpareParts')
      if (confirmed) {
        const auth = yield select(getAuth)
        yield put(showLoader())
        yield call(putCompleteSpareParts, dossierId, auth.token, new Date().toLocaleString())
        yield put(messageModalActions.openSuccessMessageModal('MESSAGES.COMPLETE_SPARE_PARTS_SUCCES'))
        if (resolve) resolve(true)
      }
    } else {
      const auth = yield select(getAuth)
      const serviceDossierState = yield select(state => state.serviceDossier)
      const confirmed = yield call(yesNoModal, 'serviceCompleteSpareParts')
      if (confirmed) {
        yield put(showLoader())
        yield call(putCompleteSpareParts, serviceDossierState.dossierId, auth.token, serviceDossierState.lastAccessDate)
        yield call(fetchServiceDossier, { dossierId: serviceDossierState.dossierId })
        const updatedSalesDossier = yield select(state => state.serviceDossier)
        yield put(initialize('service_dossier', updatedSalesDossier))
        if (resolve) resolve(true)
      }
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          const serviceDossierState = yield select(state => state.serviceDossier)
          yield call(initializeDossier, dossierTypesConstant.service, serviceDossierState)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('service_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
    if (resolve) resolve(false)
  }
}

export function * watchServiceCompleteSpareParts () {
  yield takeEvery(dossierActionsTypes.COMPLETE_SERVICE_SPARE_PARTS, serviceCompleteSpareParts)
}

export function * serviceCompleteDeliveryPreparation ({ pristine, dossierId, resolve }) {
  try {
    if (!pristine && dossierId == null) {
      const confirmedSaveWarning = yield call(yesNoModal, 'commonDossierSaveWarning')
      if (confirmedSaveWarning) {
        const auth = yield select(getAuth)
        const serviceDossierState = yield select(state => state.serviceDossier)
        const confirmed = yield call(yesNoModal, 'serviceCompleteDeliveryPreparation')
        if (confirmed) {
          yield put(showLoader())
          yield call(putCompleteDeliveryPreparation, serviceDossierState.dossierId, auth.token, serviceDossierState.lastAccessDate)
          yield call(fetchServiceDossier, { dossierId: serviceDossierState.dossierId })
          const updatedSalesDossier = yield select(state => state.serviceDossier)
          yield put(initialize('service_dossier', updatedSalesDossier))
          if (resolve) resolve(true)
        }
      }
    } else if (pristine == null && dossierId !== undefined && dossierId !== null) {
      const confirmed = yield call(yesNoModal, 'serviceCompleteDeliveryPreparation')
      if (confirmed) {
        const auth = yield select(getAuth)
        yield put(showLoader())
        yield call(putCompleteDeliveryPreparation, dossierId, auth.token, new Date().toLocaleString())
        yield put(messageModalActions.openSuccessMessageModal('MESSAGES.COMPLETE_DELIVERY_PREPARATION_SUCCES'))
        if (resolve) resolve(true)
      }
    } else {
      const auth = yield select(getAuth)
      const serviceDossierState = yield select(state => state.serviceDossier)
      const confirmed = yield call(yesNoModal, 'serviceCompleteDeliveryPreparation')
      if (confirmed) {
        yield put(showLoader())
        yield call(putCompleteDeliveryPreparation, serviceDossierState.dossierId, auth.token, serviceDossierState.lastAccessDate)
        yield call(fetchServiceDossier, { dossierId: serviceDossierState.dossierId })
        const updatedSalesDossier = yield select(state => state.serviceDossier)
        yield put(initialize('service_dossier', updatedSalesDossier))
        if (resolve) resolve(true)
      }
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          const serviceDossierState = yield select(state => state.serviceDossier)
          yield call(initializeDossier, dossierTypesConstant.service, serviceDossierState)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('service_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
    if (resolve) resolve(false)
  }
}

export function * watchServiceCompleteDeliveryPreparation () {
  yield takeEvery(dossierActionsTypes.COMPLETE_SERVICE_DELIVERY_PREPARATION, serviceCompleteDeliveryPreparation)
}
