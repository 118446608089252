import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import UpdateDossierModal from '../../../components/commons/UpdateDossierModal'
import { createUpdateDossierToshiko } from '../../../../actions/dossier/sales'
export function mapStateToProps (state) {
  return {
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      createUpdateDossierToshiko
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(UpdateDossierModal))
