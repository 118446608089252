
import React, { PureComponent } from 'react'
import { Modal, Col, Button, Tooltip, OverlayTrigger, Row } from 'react-bootstrap'
import { reduxForm, Field } from 'redux-form'
import IDocCarPagination from '../../commons/iDocCarPagination'
import SimpleTablePage from '../../../containers/commons/general/SimpleTablePage'
import InputText from '../../commons/form/InputText'
import { formatDate } from '../../../util/utils'
class ProcessingAgencyUsersAddModal extends PureComponent {
  constructor () {
    super()
    this.state = {
      filter: {
        searchName: null,
        searchEmail: null
      }
    }
  }

  componentDidUpdate () {}
  getSnapshotBeforeUpdate (prevProps) {
    if (prevProps.showModal !== this.props.showModal && this.props.showModal) {
      this.props.initialize()
    }
    return this.props
  }

  getSortIcon (column = '') {
    switch (this.props.filters.orderBy) {
      case '+' + column:
        return 'ico-sort-selected-asc'
      case '-' + column:
        return 'ico-sort-selected-desc'
      default:
        return 'ico-sort'
    }
  }

  onPageChange (page) {

  }

  onOrderChange (e) {
    const newOrderBy = this.props.filter.orderBy.includes(`+${e.currentTarget.dataset.field}`) ? `-${e.currentTarget.dataset.field}` : `+${e.currentTarget.dataset.field}`
    this.props.actions.fetchUserListForProcessingAgency({ ...this.props.pagination, ...this.state.filter, orderBy: newOrderBy, processingAgencyId: this.props.agency.processingAgencyId })
  }

  getIconClass (field) {
    const filters = this.props.filters
    if (filters && filters.orderBy.includes(field)) {
      return filters.orderBy.includes('-')
        ? 'ico-sort-selected-asc'
        : 'ico-sort-selected-desc'
    } else {
      return 'ico-sort'
    }
  }

  handleNameChange (searchName) {
    if (this.state.filter.searchName !== searchName) {
      this.setState({ filter: { ...this.state.filter, searchName } })
    }
  }

  handleEmailChange (searchEmail) {
    if (this.state.filter.searchEmail !== searchEmail) {
      this.setState({ filter: { ...this.state.filter, searchEmail } })
    }
  }

  addUserToAgency (userId) {
    this.props.actions.assingUserProcessingAgency(userId, this.props.agency.processingAgencyId, () => this.props.actions.fetchUserListForProcessingAgency({ ...this.props.pagination, ...this.state.filter, processingAgencyId: this.props.agency.processingAgencyId, page: 1 }))
  }

  searchUsers (newPage) {
    this.props.actions.fetchUserListForProcessingAgency({ ...this.props.pagination, ...this.state.filter, ...this.props.filter, page: newPage ?? 1, processingAgencyId: this.props.agency.processingAgencyId })
  }

  closeModal () {
    this.props.actions.getUsersProcessingAgency(this.props.agency.processingAgencyId)
    this.props.actions.closeUserProcessingAgencyAddModal()
  }

  render () {
    const {
      t, handleSubmit,
      showModal, userList, pagination: { page, pagesCount }, usersCount
    } = this.props
    const tKey = 'MASTERS.PROCESSING_AGENCY_MASTERS.MODAL.'

    const addTooltip = (<Tooltip id="addTooltip">{t(`${tKey}ADD_USER`)}</Tooltip>)

    return (
      <Modal className="role-modal languages-modal angencyAddUsersModal" show={showModal} onHide={() => this.closeModal()} backdrop={'static'}>
        <form autoComplete="off" onSubmit={handleSubmit(this.addUserToAgency.bind(this))}>
          <Modal.Header closeButton onHide={() => this.closeModal()}>
            <Modal.Title>{t(`${tKey}TITLE_USERS_ADD`)}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <div className="search-form">
                <span className="title-search"><i className="ico-search" /> {t(`${tKey}SEARCH_TITLE`)}</span>
                <Row className='flexRow'>

                  <Field
                    id="name"
                    name="filters.name"
                    colSm={2}
                    controlLabel={t(`${tKey}COMPLET_NAME`)}
                    placeholder={t(`${tKey}COMPLET_NAME`)}
                    component={InputText}
                    onInputChange={(value) => this.handleNameChange(value)}
                  />
                  <Field
                    id="email"
                    name="filters.email"
                    colSm={2}
                    controlLabel={t(`${tKey}EMAIL`)}
                    placeholder={t(`${tKey}EMAIL`)}
                    component={InputText}
                    onInputChange={(value) => this.handleEmailChange(value)}
                  />
                  <Col sm={2} className="btn-wrapper">
                    <Button
                      className={'btn-white btn-icon btn-input btn-white search-button'}
                      style={{ width: '100%' }}
                      onClick={() => this.searchUsers()}>
                      <i className="ico-search" />
                      {t(`${tKey}SEARCH_BTN`)}
                    </Button>
                  </Col>
                </Row>
              </div>
            </Row>
            <SimpleTablePage
              columns={[
                <th key={0} data-field="userProcessingAgencyId" style={{ display: 'none' }}>
                </th>,
                <th key={1} data-field="completName" onClick={(event) => this.onOrderChange(event)}>
                  {t(`${tKey}COMPLET_NAME`)}&nbsp;<i className={this.getIconClass('completName')}></i>
                </th>,
                <th key={2} data-field="emailAddress" onClick={(event) => this.onOrderChange(event)}>
                  {t(`${tKey}EMAIL`)}&nbsp;<i className={this.getIconClass('emailAddress')}/>
                </th>,
                <th key={3} data-field="creationDate" onClick={(event) => this.onOrderChange(event)}>
                  {t(`${tKey}CREATION_DATE`)}&nbsp;<i className={this.getIconClass('creationDate')}></i>
                </th>,
                <th key={4} data-field="actions" >
                  {t(`${tKey}ACTIONS`)}&nbsp;
                </th>
              ]}
              rows={userList && userList.map((row, idx) => (
                <tr key={idx}>
                  <td>{row.completName}</td>
                  <td>{row.emailAddress}</td>
                  <td>{formatDate(row.creationDate)}</td>
                  <td className='icon-center'>{<a onClick={() => this.addUserToAgency(row.userId)}>
                    <OverlayTrigger placement="left" overlay={addTooltip}>
                      <i className="ico-plus"/>
                    </OverlayTrigger>
                  </a>}</td>
                </tr>
              ))}
            ></SimpleTablePage>
            <div className="search-footer" key="search-footer">
              <IDocCarPagination
                id="btn_pag_sales"
                page={page}
                pagesCount={pagesCount}
                totalRows= {usersCount}
                onSelectAction={(page) => this.searchUsers(page)}
              />
            </div>
          </Modal.Body>
        </form>
      </Modal>
    )
  }
}

export default reduxForm({
  form: 'processingAgencyUsersAddModal'
})(ProcessingAgencyUsersAddModal)
