export const campaignType = {
  CAMPAIGN: 5,
  SPECIAL: 6
}

export const campaignField = {
  COST_CONCESSION: 'costConcession',
  COST_CONCESSION_PERCENTAGE: 'costConcessionPercentage',
  AMOUNT: 'amount',
  AMOUNT_PERCENTAGE: 'amountPercentage',
  COST: 'cost',
  COST_PERCENTAGE: 'costPercentage',
  ADDITIONAL_PERCENTAGE: 'additionalPercentage',
  ADDITIONAL_AMOUNT: 'additionalAmount'
}
