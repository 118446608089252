import hotFolderActionTypes from '../../../constants/actions/dossier/common/hotFolder'

export function generateStructure (dossierId, dossierType, dossierNumber, resolve, reject) {
  return {
    type: hotFolderActionTypes.GENERATE_STRUCTURE,
    dossierId,
    dossierType,
    dossierNumber,
    resolve,
    reject
  }
}

export function loadFiles (dossierId, dossierType, dossierNumber, resolve, reject) {
  return {
    type: hotFolderActionTypes.LOAD_FILES,
    dossierId,
    dossierType,
    dossierNumber,
    resolve,
    reject
  }
}

export function getLoadResult (dossierNumber, dossierType, dossierId, resolve, reject) {
  return {
    type: hotFolderActionTypes.GET_LOAD_RESULT,
    dossierNumber,
    dossierType,
    dossierId,
    resolve,
    reject
  }
}
