import React, { PureComponent } from 'react'
import { Button, Modal, Col, Row, FormGroup } from 'react-bootstrap'
import InputText from '../../../components/commons/form/InputText'
import { reduxForm, Field } from 'redux-form'

class StoreCancelModal extends PureComponent {
  constructor () {
    super()
    this.state = {
      buttonPressed: null,
      textValidation: '',
      openText: false,
      openButton: false
    }
  }

  submitStoreCancel (dossierId) {
    if (this.state.buttonPressed === 'store') {
      this.props.actions.storeDossier(dossierId, true, this.props.reset, true)
    } else if (this.state.buttonPressed === 'cancel') {
      this.props.actions.putCancelDossier(dossierId, true, this.props.reset, true)
    }
    this.props.closeStoreCancelModal()
  }

  selectOption (value, text) {
    this.setState({ buttonPressed: value, textValidation: text, openText: true, openButton: false })
    this.props.change('textValidation', '')
  }

  handleTextChange (value) {
    if (this.state.buttonPressed && this.state.textValidation && this.state.openText && value === this.state.textValidation) {
      this.setState({ openButton: true })
    } else {
      this.setState({ openButton: false })
    }
  }

  componentDidUpdate () {

  }

  getSnapshotBeforeUpdate (nextProps) {
    if (nextProps.showModal !== this.props.showModal) {
      this.setState({ buttonPressed: null, textValidation: '', openText: false, openButton: false })
      this.props.change('textValidation', '')
    }
    return null
  }

  render () {
    const {
      t, handleSubmit, showModal,
      dossierId
    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.BUTTONS_HEADER.STORE_CANCEL_MODAL.'

    return (
      <Modal className="selectVoCompany-modal" show={showModal} onHide={this.props.closeStoreCancelModal}>
        <form autoComplete="off" onSubmit={handleSubmit(this.submitStoreCancel.bind(this, dossierId))}>
          <Modal.Header closeButton onHide={this.props.closeStoreCancelModal}>
            <Modal.Title>{t(`${tKey + 'TITLE'}`)}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <Row key={1} className="inputs-wrapper">
                <Col sm={6} className="custom-width-col">
                  <Button
                    id="store"
                    className="btn-white btn-icon col-sm-11"
                    type="button"
                    onClick={() => this.selectOption('store', t(`${tKey}STORE_BUTTON`))}>
                    <i className="ico-document-plus" />
                    <span>{t(`${tKey}STORE_BUTTON`)}</span>
                  </Button>
                </Col>
                <Col sm={6} className="custom-width-col">
                  <Button
                    id="cancel"
                    className="btn-white btn-icon col-sm-11"
                    type="button"
                    onClick={() => this.selectOption('cancel', t(`${tKey}CANCEL_BUTTON`))}>
                    <i className="ico-trash" />
                    <span>{t(`${tKey}CANCEL_BUTTON`)}</span>
                  </Button>
                </Col>
              </Row>
              <br/><br/>
              <Row key={2}>
                <Field
                  colSm={12}
                  id="textValidation"
                  name="textValidation"
                  controlLabel={t(`${tKey}TEXT_VALIDATION`) + this.state.textValidation }
                  placeholder={t(`${tKey}TEXT_VALIDATION`) + this.state.textValidation }
                  component={InputText}
                  disabled = {!this.state.openText}
                  onInputChange={(value) => this.handleTextChange(value)}
                />
              </Row>
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <Col sm={12} className="btn-wrapper">
              <Button
                id="recurrent-entity-modal-submit-button"
                className="btn-standard"
                type="submit"
                disabled={!this.state.openButton}>
                {t(`${tKey + 'SUBMIT_BUTTON'}`)}
              </Button>
            </Col>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default reduxForm({
  form: 'storeCancelModal'
})(StoreCancelModal)
