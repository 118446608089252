import React, { PureComponent } from 'react'
import { Glyphicon, OverlayTrigger, Panel, Row, Tooltip, Col } from 'react-bootstrap'
import InputSelect from '../../commons/form/InputSelect'
import InputCheckbox from '../../commons/form/InputCheckBox'
import InputText from '../../commons/form/InputText'
import { getFieldsPermisions } from '../../../util/permisionFunctions'
import { compareObjects } from '../../../util/utils'
import { permissions } from '../../../constants/backendIds'
import { Field } from 'redux-form'
import InputMoney from '../../commons/form/InputMoney'
import InputPercentage from '../../commons/form/InputPercentage'
import InputNatural from '../../commons/form/InputNatural'
import { Link } from 'react-router-dom'
import CustomFields from '../../commons/customFields/CustomFields'

class SelfSupply extends PureComponent {
  constructor () {
    super()
    this.state = {
      open: false,
      fieldsConfiguration: {
        organizedUnitId: null,
        departmentId: null,
        comments: null,
        exchangeVehicle: null,
        demoCount: null,
        goal: null,
        salesPrice: null,
        months: null,
        kilometers: null,
        depreciationAmount: null,
        depreciationPercentage: null,
        inputPriceBasedOnSalesPrice: null,
        estimatedSalesPrice: null,
        averageMargin: null,
        inputPriceBasedOnEstimatedSalesPrice: null,
        exchangeCount: 0
      },
      exchangeVehicle: false
    }
  }

  initializePermisions (props) {
    const fieldKeys = Object.keys(this.state.fieldsConfiguration)
    const newState = getFieldsPermisions(props.componentConfiguration.fieldsConfiguration, fieldKeys, props.componentConfiguration.permission)
    this.setState({ ...this.state, ...newState })
  }

  toggleComponent () {
    if (this.state.open) {
      this.props.actions.setActiveSection(false)
    }
    this.setState({
      open: !this.state.open
    })

    if (!this.props.isSelfSupplyLoaded) {
      return new Promise((resolve, reject) => {
        this.props.handleToUpdateComponent(this.props)
        if (this.props.historical) {
          return this.props.actions.fetchSalesSelfSupplyHistorical(this.props.dossier.dossierId, resolve, reject)
        } else {
          return this.props.actions.fetchSalesSelfSupply(this.props.dossier.dossierId, this.props.pristine, resolve, reject)
        }
      })
    }
  }

  renderLabel (tooltipId, labelText, tooltipText) {
    const tooltip = (
      <Tooltip id={tooltipId}>
        <p>{tooltipText}</p>
      </Tooltip>
    )
    return (
      <div>
        {labelText}
        {tooltipText && tooltipText !== '' && <OverlayTrigger placement="top" overlay={tooltip}>
          <Glyphicon glyph="info-sign" className="tooltip-function"></Glyphicon>
        </OverlayTrigger>}
      </div>)
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (!compareObjects(prevProps.componentConfiguration, this.props.componentConfiguration, true)) {
      this.initializePermisions(this.props)
    }
    if (this.props.open) {
      this.setState({ open: true })
    }
    if (this.props.dossier && this.props.dossier.dossierSelfSupplyComponent && this.props.dossier.dossierSelfSupplyComponent.exchangeVehicle !== null && this.props.dossier.dossierSelfSupplyComponent.exchangeVehicle !== undefined) {
      this.setState({ exchangeVehicle: this.props.dossier.dossierSelfSupplyComponent.exchangeVehicle })
    }

    return prevProps
  }

  componentDidMount () {
    this.initializePermisions(this.props)
  }

  handleExchangeVehicle (value) {
    this.setState({ exchangeCount: this.state.exchangeCount + 1 })
    if (this.props.dossier.dossierSelfSupplyComponent.dossierExchange !== null && this.props.dossier.dossierSelfSupplyComponent.dossierExchange !== undefined && this.props.dossier.dossierSelfSupplyComponent.dossierExchange !== '') {
      if (this.props.dossier.dossierSelfSupplyComponent.dossierExchangeId && this.props.dossier.dossierSelfSupplyComponent.isStored !== true) {
        this.props.actions.putCancelDossierPurchaseSelfSupply(this.props.dossier.dossierSelfSupplyComponent.dossierExchangeId, this.props.pristine, this.props.reset, this.props.dossier.dossierId)
      } else {
        this.props.openErrorMessageModal('DOSSIER_COMPONENTS.SELFSUPPLY.STORED_ERROR')
      }
    } else {
      this.props.actions.openSelfSupplyModal(this.props.pristine)
    }
  }

  componentDidUpdate () { }

  render () {
    const {
      t, readOnlyFields, customFields,
      combos: { selfSupplyOrganizedUnitCombo, departmentCombo }
    } = this.props

    const tKey = 'DOSSIER_COMPONENTS.SELFSUPPLY.'
    return (
      <div className="delivery-panel dossier-panel">
        <div className="header-panel" onClick={() => this.toggleComponent()}>
          <div className="name-wrapper">
            <i className="ico-self-supply" />
            <h4>{t(`${tKey}TITLE_PANEL`)}</h4>
          </div>
          <i className={this.state.open ? 'ico-arrow-black-up' : 'ico-arrow-black-down'} />
        </div>
        <div className="clearfix"></div>
        <div className="clearfix"></div>
        {<br></br>}
        <Panel expanded={this.state.open} onToggle={() => { }}>
          <Panel.Collapse>
            <Panel>
              <Panel.Heading>
                <Panel.Title componentClass="h4">{t(`${tKey}TITLE_GENERAL_DATA`)}</Panel.Title>
              </Panel.Heading>
              <Panel.Body>
                <Row>
                  { this.state.fieldsConfiguration.organizedUnitId && this.state.fieldsConfiguration.organizedUnitId.permission > permissions.hidden && (
                    <Field
                      colSm={4}
                      id="organizedUnitId"
                      name="dossierSelfSupplyComponent.organizedUnitId"
                      controlLabel={t(`${tKey}CONCESSIONAIRE`)}
                      placeholder={t(`${tKey}CONCESSIONAIRE`)}
                      valueKey="id"
                      labelKey="value"
                      component={InputSelect}
                      options={selfSupplyOrganizedUnitCombo}
                      disabled={readOnlyFields || this.state.fieldsConfiguration.organizedUnitId.permission !== permissions.editable}
                    />
                  )}
                  { this.state.fieldsConfiguration.departmentId && this.state.fieldsConfiguration.departmentId.permission > permissions.hidden && (
                    <Field
                      colSm={4}
                      id="departmentId"
                      name="dossierSelfSupplyComponent.departmentId"
                      controlLabel={t(`${tKey}DEPARTMENT`)}
                      placeholder={t(`${tKey}DEPARTMENT`)}
                      valueKey="id"
                      labelKey="value"
                      component={InputSelect}
                      options={departmentCombo}
                      disabled={readOnlyFields || this.state.fieldsConfiguration.departmentId.permission !== permissions.editable}

                    />
                  )}
                  { this.state.fieldsConfiguration.demoCount && this.state.fieldsConfiguration.demoCount.permission > permissions.hidden && (
                    <Field
                      colSm={2}
                      id="demoCount"
                      name="dossierSelfSupplyComponent.demoCount"
                      controlLabel={t(`${tKey}DEMO_COUNT`)}
                      component={InputNatural}
                      disabled={true}
                    />
                  )}
                  { this.state.fieldsConfiguration.goal && this.state.fieldsConfiguration.goal.permission > permissions.hidden && (
                    <Field
                      colSm={2}
                      id="goal"
                      name="dossierSelfSupplyComponent.goal"
                      controlLabel={t(`${tKey}GOAL`)}
                      component={InputNatural}
                      disabled={true}
                    />
                  )}
                </Row>
                <Row>
                  { this.state.fieldsConfiguration.exchangeVehicle && this.state.fieldsConfiguration.exchangeVehicle.permission > permissions.hidden && (
                    <Col sm={5}>
                      <Field
                        key={this.state.exchangeCount}
                        name="exchangeVehicleid"
                        inline
                        component={InputCheckbox}
                        disabled={readOnlyFields || this.state.fieldsConfiguration.exchangeVehicle.permission !== permissions.editable || (!this.props.dossier || this.props.dossier.dossierSelfSupplyComponent.organizedUnitId === null || this.props.dossier.dossierSelfSupplyComponent.organizedUnitId === undefined)}
                        input = {{ value: this.state.exchangeVehicle, onChange: (value) => this.handleExchangeVehicle(value) }}
                        placeholder={this.renderLabel('tooltipToExchangeVehicle', t(`${tKey}EXCHANGE_VEHICLE`), (!this.props.dossier || this.props.dossier.dossierSelfSupplyComponent.organizedUnitId === null || this.props.dossier.dossierSelfSupplyComponent.organizedUnitId === undefined) ? t(`${tKey}EXCHANGE_VEHICLE_DISABLED`) : '')}
                      />
                    </Col>

                  )}
                  {this.props.dossier && this.props.dossier.dossierSelfSupplyComponent && this.props.dossier.dossierSelfSupplyComponent.dossierExchange && this.props.dossier.dossierSelfSupplyComponent.dossierExchange !== '' && (
                    <Col sm={6} className="linkSelfSupply">
                      <Link to={`/dossier${this.props.dossier.dossierSelfSupplyComponent.dossierExchange}`} >
                        {t(`${tKey}EXCHANGE_VEHICLE_DOSSIER`)}
                      </Link>
                    </Col>
                  )}
                </Row>
                <Row>
                  { this.state.fieldsConfiguration.comments && this.state.fieldsConfiguration.comments.permission > permissions.hidden && (
                    <Field
                      id="comments"
                      name="dossierSelfSupplyComponent.comments"
                      componentClass="textarea"
                      controlLabel={t(`${tKey}COMMENTS`)}
                      disabled={readOnlyFields || this.state.fieldsConfiguration.comments.permission !== permissions.editable}
                      component={InputText}
                      resize = "none"
                      colSm = {12}
                    />
                  )}
                </Row>
              </Panel.Body>
            </Panel>
            {<br></br>}
            <Panel>
              <Panel.Heading componentClass="h4">{t(`${tKey}TITLE_ECONOMIC_DATA`)}</Panel.Heading>
              <Panel.Body>
                <Row>
                  { this.state.fieldsConfiguration.months && this.state.fieldsConfiguration.months.permission > permissions.hidden && (
                    <Field
                      id="months"
                      name="dossierSelfSupplyComponent.economicData.months"
                      controlLabel={t(`${tKey}MONTHS`)}
                      component={InputNatural}
                      disabled={readOnlyFields || this.state.fieldsConfiguration.months.permission !== permissions.editable}
                      colSm ={2}
                    />
                  )}
                  { this.state.fieldsConfiguration.kilometers && this.state.fieldsConfiguration.kilometers.permission > permissions.hidden && (
                    <Field
                      id="kilometers"
                      name="dossierSelfSupplyComponent.economicData.kilometers"
                      controlLabel={t(`${tKey}KILOMETERS`)}
                      component={InputNatural}
                      disabled={readOnlyFields || this.state.fieldsConfiguration.kilometers.permission !== permissions.editable}
                      colSm ={2}
                    />
                  )}
                  { this.state.fieldsConfiguration.depreciationPercentage && this.state.fieldsConfiguration.depreciationPercentage.permission > permissions.hidden && (
                    <Field
                      id="depreciationPercentage"
                      name="dossierSelfSupplyComponent.economicData.depreciationPercentage"
                      controlLabel={t(`${tKey}DEPRECIATION_PERCENTAGE`)}
                      component={InputPercentage}
                      disabled={true}
                      colSm ={2}
                    />
                  )}
                  { this.state.fieldsConfiguration.depreciationAmount && this.state.fieldsConfiguration.depreciationAmount.permission > permissions.hidden && (
                    <Field
                      id="depreciationAmount"
                      name="dossierSelfSupplyComponent.economicData.depreciationAmount"
                      controlLabel={t(`${tKey}DEPRECIATION_AMOUNT`)}
                      component={InputMoney}
                      disabled={true}
                      colSm ={2}
                    />
                  )}
                  { this.state.fieldsConfiguration.inputPriceBasedOnSalesPrice && this.state.fieldsConfiguration.inputPriceBasedOnSalesPrice.permission > permissions.hidden && (
                    <Field
                      id="inputPriceBasedOnSalesPrice"
                      name="dossierSelfSupplyComponent.economicData.inputPriceBasedOnSalesPrice"
                      controlLabel={this.renderLabel('tooltipToPass', t(`${tKey}INPUT_PRICE_BASED_ON_SALES_PRICE`), t(`${tKey}BASED_ON_SALES_PRICE`))}
                      component={InputMoney}
                      disabled={true}
                      colSm ={4}
                    />
                  )}
                </Row>
                <Row>
                  { this.state.fieldsConfiguration.estimatedSalesPrice && this.state.fieldsConfiguration.estimatedSalesPrice.permission > permissions.hidden && (
                    <Field
                      id="estimatedSalesPrice"
                      name="dossierSelfSupplyComponent.economicData.estimatedSalesPrice"
                      controlLabel={t(`${tKey}ESTIMATED_SALES_PRICE`)}
                      component={InputMoney}
                      disabled={readOnlyFields || this.state.fieldsConfiguration.estimatedSalesPrice.permission !== permissions.editable}
                      colSm ={4}
                    />
                  )}
                  { this.state.fieldsConfiguration.averageMargin && this.state.fieldsConfiguration.averageMargin.permission > permissions.hidden && (
                    <Field
                      id="averageMargin"
                      name="dossierSelfSupplyComponent.economicData.averageMargin"
                      controlLabel={t(`${tKey}AVERAGE_MARGIN`)}
                      component={InputPercentage}
                      disabled={readOnlyFields || this.state.fieldsConfiguration.averageMargin.permission !== permissions.editable}
                      colSm ={4}
                    />
                  )}
                  { this.state.fieldsConfiguration.inputPriceBasedOnEstimatedSalesPrice && this.state.fieldsConfiguration.inputPriceBasedOnEstimatedSalesPrice.permission > permissions.hidden && (
                    <Field
                      id="inputPriceBasedOnEstimatedSalesPrice"
                      name="dossierSelfSupplyComponent.economicData.inputPriceBasedOnEstimatedSalesPrice"
                      controlLabel={this.renderLabel('tooltipToPass', t(`${tKey}INPUT_PRICE_BASED_ON_ESTIMATED_SALES_PRICE`), t(`${tKey}BASED_ON_ESTIMATED_SALES_PRICE`))}
                      component={InputMoney}
                      disabled={true}
                      colSm ={4}
                    />
                  )}
                </Row>
                <CustomFields customFields={customFields} readOnlyFields={readOnlyFields} />
              </Panel.Body>
            </Panel>
          </Panel.Collapse>
        </Panel>
      </div>
    )
  }
}

export default SelfSupply
