
import React, { PureComponent } from 'react'
import { Button, Modal, /* Col, */ Table, Tooltip, OverlayTrigger } from 'react-bootstrap'
import { Link } from 'react-router-dom'
// import { reduxForm } from 'redux-form'

class VehicleSelectionModal extends PureComponent {
  constructor () {
    super()
    this.state = {
    }
  }

  canBeShowModal () {
    return this.props.show && this.props.stockDossierId && this.props.dossierId === this.props.stockDossierId
  }

  componentDidMount (prevProps, prevState) {
    if (this.canBeShowModal()) {
      this.props.actions.fetchVehicleSelectionModal()
    }
  }

  componentDidUpdate () {
  }

  render () {
    const {
      t, purchaseList,
      actions: { closeVehicleSelectionModal, selectVehicle }
    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.VEHICLE.VEHICLE_SELECTION_MODAL.'
    const selectTooltip = (<Tooltip id="selectTooltip">{t('GLOBAL.BUTTONS.SELECT')}</Tooltip>)
    return (
      <Modal className="vehicleSelectionModal" show={this.canBeShowModal()} onHide={closeVehicleSelectionModal} backdrop={'static'} >
        <Modal.Header closeButton>
          <Modal.Title>{t(`${tKey}TITLE`)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>{t(`${tKey}DEALER_NUMBER`)}</th>
                <th>{t(`${tKey}DOSSIER_NUMBER`)}</th>
                <th>{t(`${tKey}CREATION_DATE`)}</th>
                <th>{t(`${tKey}ACTIONS`)}</th>
              </tr>
            </thead>
            <tbody>
              {purchaseList.map((purchase, index) => (
                <tr key={index}>
                  <td>{purchase.dealerNumber}</td>
                  <td>{purchase.urlToNavigate ? (
                    <Link to={`/dossier${purchase.urlToNavigate}`} >
                      {purchase.dossierNumber}
                    </Link>
                  ) : purchase.dossierNumber}
                  </td>
                  <td>{purchase.creationDate}</td>
                  <td>
                    <a id="btnSelectVehicle" name="btnSelectVehicle" onClick={() => { selectVehicle(purchase); closeVehicleSelectionModal() }}>
                      <OverlayTrigger placement="right" overlay={selectTooltip}>
                        <i className="iDocIcon-add-vehicle"/>
                      </OverlayTrigger>
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer className='flexInLine al-C'>
          <Button id="btnCancelSelectVehicle" name="btnCancelSelectVehicle" onClick={closeVehicleSelectionModal} className="_Btn cancel">{t('GLOBAL.BUTTONS.CANCEL')}</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}
export default VehicleSelectionModal
// export default reduxForm({
//   form: 'vehicleSelectionModal',
//   destroyOnUnmount: false
// })(VehicleSelectionModal)
