import { put, call, all, select, takeEvery } from 'redux-saga/effects'
import { change, initialize, reset } from 'redux-form'
// import { replace } from 'react-router-redux'
import { replace } from 'connected-react-router'
import { permissions, entityTypesId, paymentMethodCampaign } from '../../../constants/backendIds'
import { buttonsHeader, buttonsHeaderPermisionsIdReverse } from '../../../constants/dossier/common/buttonsHeader'
import dossierActionsTypes from '../../../constants/actions/dossier/dossier'
import { showLoader, hideLoader } from '../../../actions/common'
import messageModalActions from '../../../actions/modals/messageModal'
import { refreshDocumentManagementModal } from '../../../actions/dossier/common/documentManagementModal'
import { setActiveTab } from '../../../actions/dossier/common/buttonsHeader'
import { fetchCampaignDossierSuccess } from '../../../actions/dossier/campaign'
import { getAuth } from '../../../selectors/access/auth'
import { getActiveTab } from '../../../selectors/dossier/common/buttonsHeader'
import { getDossierRelatedDossiersConf } from '../../../selectors/dossier/common/dossierRelatedDossiers'
import getCampaignDossierService from '../../../services/dossierCampaign/getCampaignDossier'
import getCampaignHistoricalDossierService from '../../../services/dossierCampaign/getCampaignHistoricalDossier'
import getCampaignDossierHeaderService from '../../../services/dossierCampaign/getCampaignDossierHeader'
import getCampaignDetailsForCampaign from '../../../services/campaignDetails/getCampaignDetailsForCampaign'
import getEntitySubType from '../../../services/entitySubType/getEntitySubType'
import getEntityFilterSubType from '../../../services/entity/getEntityFilterSubType'
import getStageCheckListService from '../../../services/dossier/get/getStageCheckList'
import getEntityTypes from '../../../services/entityType/getEntityTypes'
import getChannels from '../../../services/sourcechannel/getChanel'
import getSegmentations from '../../../services/segmentation/getSegmentation'
import getDossierEntityService from '../../../services/dossier/get/getDossierEntity'
import getDossierEntityChildService from '../../../services/dossier/get/getDossierEntityChild'
import getDossierHistoricalEntity from '../../../services/dossier/get/getDossierHistoricalEntity'
import getDossierHistoricalEntityChild from '../../../services/dossier/get/getDossierHistoricalEntityChild'
import getPurchaseWithVehicleById from '../../../services/dossier/get/getPurchaseWithVehicleById'
import getHistoricalPurchaseWithVehicleById from '../../../services/dossier/get/getHistoricalPurchaseWithVehicleById'
import getVehicleByIdService from '../../../services/vehicle/getVehicleById'
import getBrands from '../../../services/brand/getBrands'
import getMaritalStatus from '../../../services/maritalStatus/getMaritalStatus'
import getFuels from '../../../services/fuel/getFuels'
import { fetchCombosSuccess } from '../../../actions/combos/combos'
import getEntityById from '../../../services/entity/getEntityById'
import { fetchEntitySubTypeCombo } from '../../../sagas/combos/fetchEntitySubTypeCombo'
import { fetchEntityFilterSubTypeCombo } from '../../../sagas/combos/fetchEntityFilterSubTypeCombo'
import getSalesmanAll from '../../../services/salesman/getSalesmanAll'
import { switchFavoriteSuccess } from '../../../actions/dossier/common/statusHeader'
// Related component
import getDossierRelatedDossiers from '../../../services/dossier/get/getDossierRelatedDossiers'
import getDossierHistoricalRelatedDossiers from '../../../services/dossier/get/getDossierHistoricalRelatedDossiers'
// Audit Info
// import getAuditInfo from '../../../services/audit/auditInfo'
import getAuditHistoricalInfo from '../../../services/audit/auditHistoricalInfo'
import getCampaignType from '../../../services/campaignType/getCampaignType'
import getDocumentsTypes from '../../../services/document/getDocumentsTypes'
import { handleError } from '../../errors/errorManager'
// Contact
import getDossierContact from '../../../services/dossier/get/getDossierContact'
import getDossierContactHistorical from '../../../services/dossier/get/getDossierContactHistorical'
// Details
import getCampaignDetails from '../../../services/campaignDetails/getCampaignDetails'
import { fetchPaymentMethodCampaign } from '../../combos/fetchPaymentMethodCampaign'
// Vehicle
import getModelCombo from '../../../services/model/getModelCombo'
import getCarlineCombo from '../../../services/carline/getCarlineCombo'
import getCatalogueCombo from '../../../services/catalogue/getCatalogueCombo'
import getColorCombo from '../../../services/color/getColorCombo'
import getTapestryCombo from '../../../services/tapestry/getTapestryCombo'
import getCatalogueModel from '../../../services/catalogueModel/getCatalogueModel'

export function * fetchCampaignDossier ({ dossierId, resolve, reject, historical }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const dossierRelatedDossiersConf = yield select(getDossierRelatedDossiersConf)
    const campaignDossier = yield select(state => state.campaignDossier)
    let dossier = {}
    let checkListHeader = []
    let entityComponent = {}
    let entityChildComponent = {}
    let dossierRelatedDossiers = []
    let auditInfo = []
    if (historical) {
      dossier = yield call(getCampaignHistoricalDossierService, dossierId, auth.token)
      yield call(fetchPaymentMethodCampaign, true)
      // entityComponent = yield call(getDossierHistoricalEntity, dossierId, auth.token)
      entityChildComponent = yield call(getDossierHistoricalEntityChild, dossierId, auth.token)
      dossierRelatedDossiers = yield call(getDossierHistoricalRelatedDossiers, dossierId, dossierRelatedDossiersConf.orderby, auth.token)
      auditInfo = yield call(getAuditHistoricalInfo, dossierId, auth.token)
    } else {
      dossier = yield call(getCampaignDossierService, dossierId, auth.token)
      yield call(fetchPaymentMethodCampaign, false)
      let paymentMethodCampaignCombo = yield select(state => state.combos.paymentMethodCampaignCombo)
      if (paymentMethodCampaignCombo && Array.isArray(paymentMethodCampaignCombo) && paymentMethodCampaignCombo.length > 0) {
        if (!dossier.dossierCampaignComponent.paymentMethodCampaignId) {
          if (dossier.dossierCampaignComponent.filledBillingDateAndNumberInParent) {
            dossier.dossierCampaignComponent.paymentMethodCampaignId = paymentMethodCampaign.payment
          } else {
            dossier.dossierCampaignComponent.paymentMethodCampaignId = paymentMethodCampaign.invoice
          }
        }
      }
      checkListHeader = yield call(getStageCheckListService, dossierId, auth.token)
      // entityComponent = yield call(getDossierEntityService, dossierId, auth.token)
      // entityComponent.previousEntity = {...entityComponent}
      entityChildComponent = yield call(getDossierEntityChildService, dossierId, auth.token)
      entityChildComponent.previousEntity = { ...entityChildComponent }
      dossierRelatedDossiers = yield call(getDossierRelatedDossiers, dossierId, dossierRelatedDossiersConf.orderby, auth.token)
      // auditInfo = yield call(getAuditInfo, auth.token, dossierId)
    }

    const [
      campaignDetails,
      campaignsTypes,
      entityTypeCombo,
      channelCombo,
      maritalStatusCombo,
      segmentationCombo,
      brandCombo,
      fuelCombo,
      salesmanAllCombo,
      modelCombo
    ] = yield all([
      call(getCampaignDetails, auth.token, dossier.dossierSubTypeId),
      call(getCampaignType, auth.token),
      call(getEntityTypes, auth.token, dossier.dossierSubTypeId),
      call(getChannels, auth.token),
      call(getMaritalStatus, auth.token),
      call(getSegmentations, auth.token),
      call(getBrands, auth.token, dossier.dossierSubTypeId, dossier.organizedUnitId),
      call(getFuels, auth.token, historical),
      call(getSalesmanAll, auth.token),
      call(getModelCombo, auth.token)
    ])
    const documentsTypes = yield call(getDocumentsTypes, auth.token, null, dossierId, null, null, historical)

    entityComponent = campaignDossier.entityComponent
    entityComponent = { ...entityComponent, ...dossier.dossierEntityComponent }
    if (campaignDossier.entityComponent.updated === true) {
      entityComponent = yield call(getDossierEntityService, dossierId, auth.token)
      entityComponent.updated = true
      entityComponent.previousEntity = { ...entityComponent }
    }

    const entitySubTypeCombo = entityComponent.entityTypeId
      ? yield call(getEntitySubType, auth.token, entityComponent.entityTypeId)
      : []
    const entityFilterSubTypeCombo = entityComponent.entitySubTypeId
      ? yield call(getEntityFilterSubType, auth.token, entityComponent.entityTypeId, entityComponent.entitySubTypeId, dossier.organizedUnitId)
      : []

    let vehicleComponent = campaignDossier.vehicleComponent
    vehicleComponent = { ...vehicleComponent, ...dossier.dossierVehicleComponent }
    if (campaignDossier.vehicleComponent.updated === true) {
      vehicleComponent = yield call(getPurchaseWithVehicleById, dossierId, auth.token)
      vehicleComponent.updated = true
    }

    let dossierContactComponent = campaignDossier.dossierContactComponent
    if (dossierContactComponent.updated === true) {
      dossierContactComponent = yield call(getDossierContact, dossierId, auth.token)
      dossierContactComponent.updated = true
    }

    if (dossier.dossierCampaignComponent.amount) {
      const hasTotalAmountWithOptionals = dossier.totalAmountWithOptionals && dossier.totalAmountWithOptionals > 0
      dossier.dossierCampaignComponent.amountPercentage = hasTotalAmountWithOptionals ? ((dossier.dossierCampaignComponent.amount * 100) / dossier.totalAmountWithOptionals).toFixed(20) : 0
      dossier.dossierCampaignComponent.costPercentage = dossier.totalAmountWithOptionals ? ((dossier.dossierCampaignComponent.cost * 100) / dossier.totalAmountWithOptionals).toFixed(2) : 0
      dossier.dossierCampaignComponent.costConcession = ((dossier.dossierCampaignComponent.amount ?? 0) - (dossier.dossierCampaignComponent.cost ?? 0) - (dossier.dossierCampaignComponent.additionalAmount ?? 0)).toFixed(2)
      dossier.dossierCampaignComponent.costConcessionPercentage = hasTotalAmountWithOptionals ? ((dossier.dossierCampaignComponent.costConcession * 100) / dossier.totalAmountWithOptionals).toFixed(2) : 0
      dossier.dossierCampaignComponent.additionalPercentage = hasTotalAmountWithOptionals ? ((dossier.dossierCampaignComponent.additionalAmount * 100) / dossier.totalAmountWithOptionals).toFixed(2) : 0
      dossier.dossierCampaignComponent.setActiveTab = 1
    }
    if (dossier.dossierCampaignComponent.amountPercentage && !dossier.dossierCampaignComponent.setActiveTab) {
      dossier.dossierCampaignComponent.amount = dossier.totalAmountWithOptionals ? (((dossier.totalAmountWithOptionals || 1) * dossier.dossierCampaignComponent.amountPercentage) / 100).toFixed(2) : 0
      dossier.dossierCampaignComponent.cost = dossier.totalAmountWithOptionals ? (((dossier.totalAmountWithOptionals || 1) * dossier.dossierCampaignComponent.costPercentage) / 100).toFixed(2) : 0
      dossier.dossierCampaignComponent.additionalAmount = dossier.totalAmountWithOptionals ? (((dossier.totalAmountWithOptionals || 1) * dossier.dossierCampaignComponent.additionalPercentage) / 100).toFixed(2) : 0
      if (historical) {
        dossier.dossierCampaignComponent.costConcessionPercentage = dossier.dossierCampaignComponent.amountPercentage - dossier.dossierCampaignComponent.costPercentage - dossier.dossierCampaignComponent.additionalPercentage
        dossier.dossierCampaignComponent.costConcession = dossier.totalAmountWithOptionals ? (((dossier.totalAmountWithOptionals || 1) * (dossier.dossierCampaignComponent.costConcessionPercentage)) / 100).toFixed(2) : 0
      } else {
        dossier.dossierCampaignComponent.costConcession = dossier.totalAmountWithOptionals ? (((dossier.totalAmountWithOptionals || 1) * dossier.dossierCampaignComponent.costConcessionPercentage) / 100).toFixed(2) : 0
      }
      dossier.dossierCampaignComponent.setActiveTab = 2
    }

    if (entityComponent.entityId && entityChildComponent.entityId) {
      yield put(fetchCampaignDossierSuccess({ ...dossier, entityComponent, entityChildComponent, dossierContactComponent, vehicleComponent, dossierRelatedDossiers, auditInfo }, checkListHeader))
    } else if (!entityComponent.entityId && entityChildComponent.entityId) {
      yield put(change('campaign_dossier', 'entityComponent', {}))
      yield put(change('campaign_dossier', 'dossierContactComponent', {}))
      yield put(fetchCampaignDossierSuccess({ ...dossier, entityComponent: {}, entityChildComponent, dossierContactComponent, vehicleComponent, dossierRelatedDossiers, auditInfo }, checkListHeader))
    } else if (entityComponent.entityId && !entityChildComponent.entityId) {
      yield put(change('campaign_dossier', 'entityChildComponent', {}))
      yield put(fetchCampaignDossierSuccess({ ...dossier, entityComponent, dossierContactComponent, entityChildComponent: {}, vehicleComponent, dossierRelatedDossiers, auditInfo }, checkListHeader))
    } else {
      yield put(change('campaign_dossier', 'entityComponent', {}))
      yield put(change('campaign_dossier', 'entityChildComponent', {}))
      yield put(change('campaign_dossier', 'dossierContactComponent', {}))
      yield put(fetchCampaignDossierSuccess({ ...dossier, entityComponent: {}, entityChildComponent: {}, dossierContactComponent, vehicleComponent, dossierRelatedDossiers, auditInfo }, checkListHeader))
    }

    yield put(switchFavoriteSuccess(dossier.dossierHeaderComponent.favorite))

    yield put(fetchCombosSuccess({
      campaignDetails,
      campaignsTypes,
      documentsTypes,
      // Client component
      entityTypeCombo,
      channelCombo,
      maritalStatusCombo,
      segmentationCombo,
      entitySubTypeCombo,
      entityFilterSubTypeCombo,
      brandCombo,
      fuelCombo,
      salesmanAllCombo,
      modelCombo
    }))

    const currentActiveTab = yield select(getActiveTab)
    let nextActiveTab = dossier.viewConfiguration.tabsConfiguration.filter((tabConf) => tabConf.permission > permissions.hidden)
    nextActiveTab = nextActiveTab.length > 0 ? nextActiveTab[0] : currentActiveTab
    if (nextActiveTab !== currentActiveTab) yield put(setActiveTab(buttonsHeaderPermisionsIdReverse[nextActiveTab.code]))

    if (!historical && nextActiveTab === buttonsHeader.gestor_documental) {
      yield put(refreshDocumentManagementModal(dossierId))
    }

    if (resolve) yield call(resolve, entityComponent)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.status === 301) {
        // yield put(replace(`/dossier${customHandlerError.json}`))
        yield put(replace(`/dossier${customHandlerError.json}`))
      } else {
        yield put(messageModalActions.openErrorMessageModal('MESSAGES.ERROR_GET_PAPERWORK_DOSSIER', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
      if (reject) yield call(reject, customHandlerError)
    }
    yield call(handleError, { error, customHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchCampaignDossier () {
  yield takeEvery(dossierActionsTypes.FETCH_CAMPAIGN_DOSSIER, fetchCampaignDossier)
}

export function * refreshCampaignDossierEntityFromDocumentModal (documentEditionalModalState) {
  try {
    yield put(showLoader())
    const campaignDossier = yield select(state => state.campaignDossier)
    const auth = yield select(getAuth)
    let entityComponent = {}
    if (documentEditionalModalState !== null && documentEditionalModalState !== undefined && documentEditionalModalState.entityId) {
      entityComponent = yield call(getEntityById, documentEditionalModalState.entityId, auth.token)
    } else {
      entityComponent = yield call(getDossierEntityService, campaignDossier.dossierId, auth.token)
    }
    let dirty = false
    if (documentEditionalModalState !== null && documentEditionalModalState !== undefined &&
      documentEditionalModalState.dirty !== null && documentEditionalModalState.dirty !== undefined) {
      dirty = documentEditionalModalState.dirty
    }
    entityComponent.previousEntity = { ...campaignDossier.entityComponent.previousEntity }
    yield put(fetchCampaignDossierSuccess({ entityComponent }))
    if (dirty) {
      yield put(change('campaign_dossier', 'entityComponent', entityComponent))
    } else {
      const updateCampaignDossier = yield select(state => state.campaignDossier)
      yield put(initialize('campaign_dossier', { ...updateCampaignDossier, entityComponent: entityComponent }))
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.SALES_DOSSIER.ERROR_GET_SALES_DOSSIER', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    yield call(handleError, { error, customHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * refreshCampaignDossierVehicleFromDocumentModal (documentEditionalModalState) {
  try {
    yield put(showLoader())
    const campaignDossier = yield select(state => state.campaignDossier)
    const auth = yield select(getAuth)
    const vehicleComponent = yield call(getVehicleByIdService, auth.token, campaignDossier.vehicleComponent.vehicleId)
    yield put(fetchCampaignDossierSuccess({ vehicleComponent }))
    const updatedCampaignDossier = yield select(state => state.campaignDossier)
    let dirty = false
    if (documentEditionalModalState !== null && documentEditionalModalState !== undefined &&
      documentEditionalModalState.dirty !== null && documentEditionalModalState.dirty !== undefined) {
      dirty = documentEditionalModalState.dirty
    }
    if (dirty) {
      yield put(change('campaign_dossier', 'vehicleComponent', updatedCampaignDossier.vehicleComponent))
    } else {
      yield put(initialize('campaign_dossier', { ...updatedCampaignDossier, vehicleComponent: updatedCampaignDossier.vehicleComponent }))
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.SALES_DOSSIER.ERROR_GET_SALES_DOSSIER', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    yield call(handleError, { error, customHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * refreshCampaignDossierFromDocumentModal (documentEditionalModalState) {
  try {
    yield put(showLoader())
    const campaignDossier = yield select(state => state.campaignDossier)
    const auth = yield select(getAuth)
    const dossierId = campaignDossier.dossierId
    const dossier = yield call(getCampaignDossierService, dossierId, auth.token)
    let campaignComp = { ...campaignDossier.dossierCampaignComponent, ...dossier.dossierCampaignComponent }
    const components = yield call(fetchCampaignComponentsUpdated, campaignDossier, auth)
    yield put(fetchCampaignDossierSuccess({ ...dossier, ...components }))
    let dirty = false
    if (documentEditionalModalState !== null && documentEditionalModalState !== undefined &&
      documentEditionalModalState.dirty !== null && documentEditionalModalState.dirty !== undefined) {
      dirty = documentEditionalModalState.dirty
    }
    let formComp = yield select(state => state.form.campaign_dossier.values.dossierCampaignComponent)
    if (dirty) {
      campaignComp = { ...campaignComp, ...formComp }
      yield put(change('campaign_dossier', 'dossierCampaignComponent', campaignComp))
    } else {
      const updateCampaignDossier = yield select(state => state.campaignDossier)
      campaignComp = { ...formComp, ...campaignComp }
      yield put(initialize('campaign_dossier', { ...updateCampaignDossier, dossierCampaignComponent: campaignComp }))
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.SALES_DOSSIER.ERROR_GET_SALES_DOSSIER', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    yield call(handleError, { error, customHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * refreshCommonCampaignFromDocumental () {
  try {
    yield put(showLoader())
    const campaignDossier = yield select(state => state.campaignDossier)
    const auth = yield select(getAuth)
    let campaign
    const headerAndButtons = yield call(getCampaignDossierHeaderService, campaignDossier.dossierId, auth.token)
    const checkListHeader = yield call(getStageCheckListService, campaignDossier.dossierId, auth.token)
    campaign = { ...headerAndButtons }
    campaign = { ...campaign, ...campaignDossier }
    yield put(fetchCampaignDossierSuccess(campaign, checkListHeader))
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.CAMPAIGN_DOSSIER.ERROR_GET_CAMPAIGN_DOSSIER', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('campaign_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * fetchCampaignComponentsUpdated (campaignDossier, auth) {
  const result = {}
  if (campaignDossier.entityComponent && campaignDossier.entityComponent.updated) {
    const entityComponent = yield call(getDossierEntityService, campaignDossier.dossierId, auth.token)
    entityComponent.updated = true
    result.entityComponent = entityComponent
  }
  if (campaignDossier.vehicleComponent && campaignDossier.vehicleComponent.updated) {
    const vehicleComponent = yield call(getPurchaseWithVehicleById, campaignDossier.dossierId, auth.token)
    vehicleComponent.updated = true
    result.vehicleComponent = vehicleComponent
  }
  if (campaignDossier.dossierContactComponent && campaignDossier.dossierContactComponent.updated) {
    const dossierContactComponent = yield call(getDossierContact, campaignDossier.dossierId, auth.token)
    dossierContactComponent.updated = true
    result.dossierContactComponent = dossierContactComponent
  }
  return result
}

export function * fetchCampaignClient ({ dossierId, resolve, reject, pristine }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const campaignDossier = yield select(state => state.campaignDossier)
    const entityComponent = yield call(getDossierEntityService, dossierId, auth.token)
    const dossierContactComponent = yield call(getDossierContact, dossierId, auth.token)
    entityComponent.updated = true
    dossierContactComponent.updated = true

    if (entityComponent && entityComponent.entitySubTypeId) {
      const entitySubTypeCombo = yield call(getEntitySubType, auth.token, entityComponent.entityTypeId)
      const entityFilterSubTypeCombo = yield call(getEntityFilterSubType, auth.token, entityComponent.entityTypeId, entityComponent.entitySubTypeId, campaignDossier.organizedUnitId)
      yield put(fetchCombosSuccess({ entitySubTypeCombo, entityFilterSubTypeCombo }))
    }

    yield put(change('campaign_dossier', 'entityComponent', entityComponent.entityId ? entityComponent : {}))
    yield put(change('campaign_dossier', 'dossierContactComponent', dossierContactComponent))
    yield put(fetchCampaignDossierSuccess({ entityComponent, dossierContactComponent }))
    if (pristine === true || pristine === undefined) {
      yield put(initialize('campaign_dossier', { ...campaignDossier, entityComponent: entityComponent, dossierContactComponent }))
    }
    if (resolve) yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.CAMPAIGN_DOSSIER.ERROR_GET_CAMPAIGN_DOSSIER', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('campaign_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchCampaignClient () {
  yield takeEvery(dossierActionsTypes.FETCH_CAMPAIGN_DOSSIER_CLIENT, fetchCampaignClient)
}

export function * fetchCampaignVehicle ({ dossierId, resolve, reject, pristine }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const campaignDossier = yield select(state => state.campaignDossier)
    const vehicleComponent = yield call(getPurchaseWithVehicleById, dossierId, auth.token)
    vehicleComponent.updated = true
    yield put(change('campaign_dossier', 'vehicleComponent', vehicleComponent.vehicleId ? vehicleComponent : {}))
    yield put(fetchCampaignDossierSuccess({ vehicleComponent }))
    if (pristine === true || pristine === undefined) {
      yield put(initialize('campaign_dossier', { ...campaignDossier, vehicleComponent: vehicleComponent }))
    }
    if (resolve) yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.CAMPAIGN_DOSSIER.ERROR_GET_CAMPAIGN_DOSSIER', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('campaign_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchCampaignVehicle () {
  yield takeEvery(dossierActionsTypes.FETCH_CAMPAIGN_DOSSIER_VEHICLE, fetchCampaignVehicle)
}
export function * fetchCampaignVehicleHistorical ({ dossierId, resolve, reject }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const vehicleComponent = yield call(getHistoricalPurchaseWithVehicleById, dossierId, auth.token)
    yield put(change('campaign_dossier', 'vehicleComponent', vehicleComponent.vehicleId ? vehicleComponent : {}))
    yield put(fetchCampaignDossierSuccess({ vehicleComponent }))
    let carlineCombo = []; let catalogueCombo = []; let colorCombo = []; let tapestryCombo = []

    if (vehicleComponent.brandId) {
      carlineCombo = yield call(getCarlineCombo, auth.token, vehicleComponent.brandId)
    }
    if (vehicleComponent.carlineId) {
      catalogueCombo = yield call(getCatalogueCombo, auth.token, vehicleComponent.carlineId)
    }
    if (vehicleComponent.modelId && vehicleComponent.catalogueId) {
      colorCombo = yield call(getColorCombo, auth.token, vehicleComponent.catalogueId, vehicleComponent.modelId)
      tapestryCombo = yield call(getTapestryCombo, auth.token, vehicleComponent.catalogueId, vehicleComponent.modelId)
      var catalogueModel = yield call(getCatalogueModel, auth.token, vehicleComponent.catalogueId, vehicleComponent.modelId)
      vehicleComponent.model = catalogueModel.description || ''
      vehicleComponent.catalogueModelId = catalogueModel.catalogueModelId || ''
    }

    yield put(fetchCombosSuccess({ carlineCombo, catalogueCombo, colorCombo, tapestryCombo }))
    if (resolve) yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.CAMPAIGN_DOSSIER.ERROR_GET_CAMPAIGN_DOSSIER', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('campaign_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchCampaignvehicleHistorical () {
  yield takeEvery(dossierActionsTypes.FETCH_CAMPAIGN_DOSSIER_VEHICLE_HISTORICAL, fetchCampaignVehicleHistorical)
}

export function * fetchCampaignClientHistorical ({ dossierId, resolve, reject }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const entityComponent = yield call(getDossierHistoricalEntity, dossierId, auth.token)
    if (entityComponent.entityTypeId === entityTypesId.recurrentEntity) {
      const campaignDossier = yield select(state => state.campaignDossier)
      yield call(fetchEntitySubTypeCombo, { entityTypeId: entityComponent.entityTypeId })
      yield call(fetchEntityFilterSubTypeCombo, { entityTypeId: entityComponent.entityTypeId, entitySubTypeId: entityComponent.entitySubTypeId, organizedUnitId: campaignDossier.organizedUnitId })
    }
    const dossierContactComponent = yield call(getDossierContactHistorical, dossierId, auth.token)
    yield put(change('campaign_dossier', 'entityComponent', entityComponent.entityId ? entityComponent : {}))
    yield put(change('campaign_dossier', 'dossierContactComponent', dossierContactComponent))
    yield put(fetchCampaignDossierSuccess({ entityComponent, dossierContactComponent }))
    if (resolve) yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.CAMPAIGN_DOSSIER.ERROR_GET_CAMPAIGN_DOSSIER', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('campaign_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchCampaignClientHistorical () {
  yield takeEvery(dossierActionsTypes.FETCH_CAMPAIGN_DOSSIER_CLIENT_HISTORICAL, fetchCampaignClientHistorical)
}

export function * fetchCampaigndetailsForCampaign ({ dossierId, detailsId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const campaignDossier = yield select(state => state.campaignDossier)
    let details = {}
    if (detailsId) {
      details = yield call(getCampaignDetailsForCampaign, auth.token, dossierId, detailsId)
    }
    if (details.amountFreeOption) {
      details = { ...details, amountRequested: null, approvedAmount: null }
    }
    campaignDossier.dossierCampaignComponent.dossierCampaignDetailComponent = details
    yield put(change('campaign_dossier', 'dossierCampaignComponent.dossierCampaignDetailComponent', campaignDossier.dossierCampaignComponent.dossierCampaignDetailComponent))
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.CAMPAIGN_DOSSIER.ERROR_GET_CAMPAIGN_DOSSIER', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('campaign_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchfetchCampaigndetailsForCampaign () {
  yield takeEvery(dossierActionsTypes.FETCH_CAMPAIGN_DETAILS, fetchCampaigndetailsForCampaign)
}

export function * fetchCampaignActive ({ active }) {
  try {
    const campaignDossier = yield select(state => state.campaignDossier)
    campaignDossier.dossierCampaignComponent.active = active
    let dossierCampaignComponent = campaignDossier.dossierCampaignComponent
    yield put(change('campaign_dossier', 'dossierCampaignComponent', dossierCampaignComponent))
    yield put(fetchCampaignDossierSuccess({ dossierCampaignComponent }))
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.CAMPAIGN_DOSSIER.ERROR_GET_CAMPAIGN_DOSSIER', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('campaign_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchCampaignActive () {
  yield takeEvery(dossierActionsTypes.ACTIVE_CAMPAIGN, fetchCampaignActive)
}
