import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { closeDuplicateSaleTypeModal, saveSaleTypeModal } from '../../../actions/masters/masters'
import DuplicateSaleTypeModal from '../../../components/masters/saleType/DuplicateSaleTypeModal'

export function mapStateToProps (state) {
  return {
    ...state.saleType.duplicateSaleTypeModal
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      closeDuplicateSaleTypeModal,
      saveSaleTypeModal
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(DuplicateSaleTypeModal))
