import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { closeEditFinancingModalProduct, fetchFinancingProductCompany, deleteFinancingTypeModal, recoverFinancingTypeModal, openFinancingTypeModal } from '../../../actions/masters/masters'
import FinancingMasterModalProduct from '../../../components/masters/financing/FinancingMasterModalProduct'
import { openModal, yesNoModal } from '../../../actions/common'
export function mapStateToProps (state) {
  return {
    ...state.financing.financingModalProduct
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      closeEditFinancingModalProduct,
      openModal,
      yesNoModal,
      fetchFinancingProductCompany,
      deleteFinancingTypeModal,
      recoverFinancingTypeModal,
      openFinancingTypeModal
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(FinancingMasterModalProduct))
