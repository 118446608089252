import React, { PureComponent } from 'react'
import { Col, Panel, Row, Table, Tooltip, OverlayTrigger, Button, Modal } from 'react-bootstrap'
import InputDatePicker from '../../../../_v2/components/commons/form/InputDatePicker'
import { Field } from 'redux-form'
import { getFieldsPermisions } from '../../../../util/permisionFunctions'
import { compareObjects, formatMoney } from '../../../../util/utils'
import { permissions } from '../../../../constants/backendIds'
import InputSelect from '../../../../_v2/components/commons/form/InputSelect'
import ComponentTitle from '../../../../_v2/containers/dossiers/commons/ComponentTitlePage'

class DossierRating extends PureComponent {
  constructor () {
    super()
    this.state = {
      open: false,
      showModal: false,
      dossierRatingComponent: null,
      dossierRatingRatingId: null,
      fieldsConfiguration: {
        ratings: null,
        confirmationDoneButton: null,
        confirmationDate: null
      }
    }
  }

  initializePermisions (props) {
    const fieldKeys = Object.keys(this.state.fieldsConfiguration)
    const newState = getFieldsPermisions(props.componentConfiguration.fieldsConfiguration, fieldKeys, props.componentConfiguration.permission)
    this.setState({ fieldsConfiguration: newState.fieldsConfiguration })
  }

  componentDidUpdate (prevProps) {
    if (this.props.dossier && this.props.dossier.dossierId) {
      if (!compareObjects(prevProps.componentConfiguration || {}, this.props.componentConfiguration, true)) {
        this.initializePermisions(this.props)
      }
      if (this.props.open) {
        this.setState({ open: true })
      }
      this.setState({ dossierRatingComponent: this.props.dossierRatingComponent })
    }
    return prevProps
  }

  componentDidMount () {
    if (this.props.componentConfiguration && this.props.componentConfiguration !== null) {
      this.initializePermisions(this.props)
      this.setState({ dossierRatingComponent: this.props.dossierRatingComponent })
    }
  }

  fetchSalesDossierPromiseDossierRating () {
    if (this.state.open) {
      this.props.actions.setActiveSection(false)
    }
    this.setState({
      open: !this.state.open
    })
  }

  editDossierRating (ratingId, dossierRatingRatingId) {
    this.setState({ showModal: true, dossierRatingRatingId: dossierRatingRatingId })
    this.props.actions.change('ratingId', ratingId)
  }

  submit (value) {
    return new Promise((resolve, reject) => {
      this.props.actions.addDossierRating(value.ratingId, this.state.dossierRatingRatingId, resolve, reject)
    }).then((result) => {
      this.setState({ dossierRatingComponent: result })
    })
  }

  openModal () {
    this.setState({ showModal: true, dossierRatingRatingId: null })
    this.props.actions.change('ratingId', null)
  }

  delete (id) {
    return new Promise((resolve, reject) => {
      this.props.actions.deleteDossierRating(id, resolve, reject)
    }).then((result) => {
      if (result !== null) {
        this.setState({ dossierRatingComponent: result })
      }
    })
  }

  closeModal () {
    this.setState({ showModal: false })
  }

  modal () {
    const { t, handleSubmit, combos: { ratingComboByOrganizedUnit } } = this.props
    const tKey = 'DOSSIER_COMPONENTS.DOSSIER_RATING.'
    return (
      <Modal className="role-modal languages-modal" show={this.state.showModal} backdrop={'static'} >
        <form autoComplete="off" onSubmit={handleSubmit(this.submit.bind(this))}>
          <Modal.Header closeButton onHide={() => this.closeModal()}>
            <Modal.Title>{t(`${tKey}MODAL.TITTLE`)}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="inputs-wrapper-no-border">
              <Col sm={12} className="custom-width-col">
                <h2>{t(`${tKey}CONCEPT`)}</h2>
                <Field
                  name="ratingId"
                  placeholder={t(`${tKey}CONCEPT`)}
                  valueKey="id"
                  labelKey="value"
                  component={InputSelect}
                  colSm={0}
                  autoComplete
                  options={ratingComboByOrganizedUnit}
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Col sm={1} className="btn-wrapper">
              <Button
                id="role-modal-submit-button"
                className="btn-standard"
                type="submit"
                onClick={() => this.setState({ showModal: false })}>
                {t(`${tKey}MODAL.ADD`)}
              </Button>
            </Col>
          </Modal.Footer>
        </form>
      </Modal>)
  }

  table () {
    const { t, combos: { ratingCombo } } = this.props
    const tKey = 'DOSSIER_COMPONENTS.DOSSIER_RATING.'
    const editTooltip = (<Tooltip id="editTooltip">{t(`${tKey}EDIT_BTN`)}</Tooltip>)
    const deleteTooltip = (<Tooltip id="deleteTooltip">{t(`${tKey}DELETE_BTN`)}</Tooltip>)
    return (
      this.state.dossierRatingComponent?.ratings?.map((dossierRating, idx) => {
        return (
          <tr key={idx}>
            <td>{ratingCombo.find(ra => ra.id === dossierRating.ratingId)?.value}</td>
            <td>{formatMoney(parseFloat(ratingCombo.find(ra => ra.id === dossierRating.ratingId)?.description))}</td>
            {this.permissionButon() && (
              <td>
                <a onClick={() => this.editDossierRating(dossierRating.ratingId, dossierRating.id)}>
                  <OverlayTrigger placement="left" overlay={editTooltip}>
                    <i className="ico-edit-white" />
                  </OverlayTrigger>
                </a>
                <a onClick={() => new Promise((resolve, reject) => this.delete(dossierRating.id, resolve, reject))}>
                  <OverlayTrigger placement="left" overlay={deleteTooltip}>
                    <i className="ico-trash" />
                  </OverlayTrigger>
                </a>
              </td>
            )}
          </tr>
        )
      }))
  }

  total () {
    const { t, combos: { ratingCombo } } = this.props
    const tKey = 'DOSSIER_COMPONENTS.DOSSIER_RATING.'
    const total = this.state.dossierRatingComponent?.ratings?.reduce((accumulator, currentValue) => {
      return parseFloat(accumulator) + parseFloat(ratingCombo.find(ra => ra.id === currentValue.ratingId)?.description)
    }, 0)
    return (
      <Col sm={12} className="colums-total-charges">
        <p><b>{t(`${tKey}TOTAL`)}:</b> <span>{formatMoney(total)}</span></p>
      </Col>
    )
  }

  permissionButon () {
    return !this.props.historical && !this.props.dossierRatingComponent?.confirmationDate && this.state.fieldsConfiguration?.confirmationDoneButton && this.state.fieldsConfiguration.confirmationDoneButton.permission !== permissions.hidden
  }

  render () {
    const { t, actions: { confirmDateWithIdByStage }, dossierId } = this.props
    const tKey = 'DOSSIER_COMPONENTS.DOSSIER_RATING.'
    return (
      <Row className='dossier-Component'>
        <div className='header-panel' onClick={this.fetchSalesDossierPromiseDossierRating.bind(this)}>
          <ComponentTitle
            componentCode="DOSSIER_RATING"
            componentOpen={this.state.open}
            // extraInfo={'info extra'}
            // extraData={<a className="_Btn accept">texto</a>}
          />
        </div>
        <Panel expanded={this.state.open} onToggle={() => {}}>
          <Panel.Collapse>
            { this.permissionButon() && (
              <div className="flexInLine al-R">
                <Button className='_Btn new' onClick={() => confirmDateWithIdByStage(dossierId)} disabled={this.state.fieldsConfiguration.confirmationDoneButton.permission !== permissions.editable}>
                  {t(`${tKey}DONE_BUTTON`)}
                </Button>
              </div>
            )}
            { this.state.fieldsConfiguration.ratings && this.state.fieldsConfiguration.ratings.permission !== permissions.hidden && (
              <>
                <div className="fieldsContainer">
                  <div className="wrapper _nf">
                    <Table className="editable-table" hover>
                      <thead>
                        <tr>
                          <th key={'concept'} style={{ width: '80%' }}>{t(`${tKey}CONCEPT`)}</th>
                          <th key={'import'}>{t(`${tKey}IMPORT`)}</th>
                          {this.permissionButon() &&
                              <th key={'actions'}>{t(`${tKey}ACTIONS`)}</th>
                          }
                        </tr>
                      </thead>
                      <tbody>
                        {this.table()}
                      </tbody>
                    </Table>
                  </div>
                </div>
                <div className='resultContainer dossierRating'>
                  <div className='resultContainerLeft'>
                    {this.permissionButon() && (
                      <Button
                        className="_Btn new"
                        id="add-button"
                        onClick={() => this.openModal()}>
                        <i class="iDocIcon-add-plus"></i>
                        {t(`${tKey}BTN_ADD_DOSSIER_RATING`)}
                      </Button>
                    )}
                  </div>
                  <div className='table-totals-charges'>
                    {this.total()}
                  </div>
                </div>
              </>
            )}
            <div className='fieldsContainer'>
              { this.state.fieldsConfiguration.confirmationDate && this.state.fieldsConfiguration.confirmationDate.permission !== permissions.hidden && (<>
                {(
                  <Field
                    component={InputDatePicker}
                    colWidht={33} // 25, 33, 50, 75, 100 default=25
                    id="confirmationDate"
                    key="confirmationDate"
                    name="dossierRatingComponent.confirmationDate"
                    controlLabel={t(`${tKey}CONFIRM_DATE`)}
                    placeholder={t(`${tKey}CONFIRM_DATE`)}
                    disabled={true}
                    customClass={'disabled'}
                  />
                )}
              </>)}
            </div>
            { this.modal()}
          </Panel.Collapse>
        </Panel>
      </Row>
    )
  }
}
export default DossierRating
