import dossierActionTypes from '../../../constants/actions/dossier/dossier'
import commentsActionTypes from '../../../constants/actions/dossier/common/comments'
import { paymentSignalStatus as paymentSignalStatusConstants } from '../../../constants/backendIds'
import auditInfoTypes from '../../../constants/actions/audit/auditInfo'

export function initialState () {
  return {
    dossierId: null,
    dossierSubTypeId: null,
    isPreCancelled: null,
    organizedUnitId: null,
    salesDocumentaryCheckList: [],
    showAlertSendPending: null,
    entityChangeable: true,
    isMarked: false,
    header: {},
    checkListHeader: [],
    viewConfiguration: {},
    salesComponent: {},
    entityComponent: { updated: false },
    entityChildComponent: {},
    entityProviderComponent: {},
    dossierContactComponent: { updated: false },
    purchasesDossierId: null,
    parentIsHistorical: null,
    vehicleComponent: { updated: false },
    dossierKm: null,
    orderId: null,
    order: null,
    noRecoverableCosts: [],
    campaigns: [],
    uac: [],
    dossierFinancingComponent: { updated: false },
    dossierReFinancingComponent: { updated: false },
    paperWorks: [],
    charges: [],
    dossierEconomicComponent: { updated: false },
    dossierCompleteDeliveryComponent: { dossierDeliveryComponent: { updated: false } },
    dossierCompleteDeliveryCheckListItems: [],
    dossierDeliveryCheckListPurchase: [],
    comments: [],
    dossierRelatedDossiers: [],
    auditInfo: [],
    ivaType: [],
    dossierCompleteOtherDataComponent: { updated: false },
    lastAccessDate: null,
    financingStatusId: null,
    campaignSubtype: [],
    dossierServiceRelateds: { showAddModal: false },
    dossierSelfSupplyComponent: { updated: false },
    dossierPaperworkComponent: { updated: false },
    dossierInsuranceCarrierComponent: {},
    unreadComments: null,
    reason: null,
    comment: null,
    cancelCombo: [],
    submitSucced: null,
    defaultDestination: null,
    customFieldsByComponent: [],
    customFieldsValues: [],
    customFields: {},
    modelPriceAmount: 0
  }
}

function fetchSalesDossierSuccess (state, { data }) {
  return {
    ...state,
    dossierId: data.dossierId || state.dossierId,
    dossierSubTypeId: data.dossierSubTypeId || state.dossierSubTypeId,
    isPreCancelled: (data.isPreCancelled !== null && data.isPreCancelled !== undefined ? data.isPreCancelled : state.isPreCancelled),
    isMarked: (data.isMarked !== null && data.isMarked !== undefined ? data.isMarked : state.isMarked),
    salesDocumentaryCheckList: data.salesDocumentaryCheckList || state.salesDocumentaryCheckList,
    organizedUnitId: data.organizedUnitId || state.organizedUnitId,
    showAlertSendPending: data.showAlertSendPending || state.showAlertSendPending,
    entityChangeable: data.entityChangeable || state.entityChangeable,
    header: data.dossierHeaderComponent || state.header,
    checkListHeader: data.checkListHeader || state.checkListHeader,
    viewConfiguration: { ...state.viewConfiguration, ...data.viewConfiguration },
    salesComponent: data.dossierSaleComponent || state.salesComponent,
    entityComponent: data.entityComponent || state.entityComponent,
    entityChildComponent: data.entityChildComponent || state.entityChildComponent,
    entityProviderComponent: data.entityProviderComponent || state.entityProviderComponent,
    dossierContactComponent: data.dossierContactComponent || state.dossierContactComponent,
    purchasesDossierId: (data.dossierParentId || data.dossierParentId === null ? data.dossierParentId : state.purchasesDossierId),
    parentIsHistorical: (data.parentIsHistorical !== null && data.parentIsHistorical !== undefined ? data.parentIsHistorical : state.parentIsHistorical),
    vehicleComponent: data.vehicleComponent || state.vehicleComponent,
    orderId: data.orderId || state.orderId,
    order: data.order || state.order,
    campaigns: data.dossierCompleteCampaigns || state.campaigns,
    uac: data.dossierCompleteUaCs || state.uac,
    dossierFinancingComponent: data.dossierFinancingComponent || state.dossierFinancingComponent,
    dossierReFinancingComponent: data.dossierReFinancingComponent || state.dossierReFinancingComponent,
    originalDossierFinancingComponent: data.originalDossierFinancingComponent || state.originalDossierFinancingComponent,
    paperWorks: data.dossierCompletePaperworks || state.paperWorks,
    charges: data.dossierCompleteCharges || state.charges,
    dossierEconomicComponent: data.dossierEconomicComponent || state.dossierEconomicComponent,
    dossierCompleteDeliveryComponent: data.dossierCompleteDeliveryComponent
      ? data.dossierCompleteDeliveryComponent.dossierDeliveryComponent
        ? { dossierDeliveryComponent: data.dossierCompleteDeliveryComponent.dossierDeliveryComponent }
        : { dossierDeliveryComponent: {} }
      : state.dossierCompleteDeliveryComponent,
    dossierCompleteDeliveryCheckListItems: data.dossierCompleteDeliveryComponent
      ? data.dossierCompleteDeliveryComponent.dossierCompleteDeliveryCheckListItems
        ? data.dossierCompleteDeliveryComponent.dossierCompleteDeliveryCheckListItems
        : []
      : state.dossierCompleteDeliveryCheckListItems,
    dossierDeliveryCheckListPurchase: data.dossierCompleteDeliveryComponent ? (
      data.dossierCompleteDeliveryComponent.dossierDeliveryCheckListPurchase ? (
        data.dossierCompleteDeliveryComponent.dossierDeliveryCheckListPurchase
      ) : []
    ) : (
      state.dossierDeliveryCheckListPurchase
    ),
    comments: data.dossierCompleteComment || state.comments,
    dossierRelatedDossiers: data.dossierRelatedDossiers || state.dossierRelatedDossiers,
    auditInfo: data.auditInfo || state.auditInfo,
    ivaType: data.ivaType || state.ivaType,
    dossierCompleteOtherDataComponent: data.dossierCompleteOtherDataComponent || state.dossierCompleteOtherDataComponent,
    lastAccessDate: data.lastAccessDate || state.lastAccessDate,
    financingStatusId: data.financingStatusId || state.financingStatusId,
    totalAmountWithOptionals: data.totalAmountWithOptionals || state.totalAmountWithOptionals,
    modelPriceAmount: data.modelPriceAmount >= 0 ? data.modelPriceAmount : state.modelPriceAmount,
    totalAmountWithOptionalsPurchase: data.totalAmountWithOptionalsPurchase || state.totalAmountWithOptionalsPurchase,
    campaignSubtype: data.campaignSubtype || state.campaignSubtype,
    dossierServiceRelateds: data.dossierServiceRelateds || state.dossierServiceRelateds,
    dossierFleetComponent: data.dossierFleetComponent || state.dossierFleetComponent,
    dossierSelfSupplyComponent: data.dossierSelfSupplyComponent || state.dossierSelfSupplyComponent,
    dossierPaperworkComponent: data.dossierPaperworkComponent || state.dossierPaperworkComponent,
    dossierInsuranceCarrierComponent: data.dossierInsuranceCarrierComponent || state.dossierInsuranceCarrierComponent,
    unreadComments: data.unreadComments >= 0 ? data.unreadComments : state.unreadComments,
    reason: data.reason || state.reason,
    comment: data.comment || state.comment,
    cancelCombo: data.cancelCombo || state.cancelCombo,
    customFieldsValues: data.customFields || state.customFieldsValues
  }
}

function openOrCloseServiceAddModal (state, { show }) {
  return {
    ...state,
    dossierServiceRelateds: {
      ...state.dossierServiceRelateds,
      showAddModal: show !== undefined ? show : !state.dossierServiceRelateds.showAddModal
    }
  }
}

function postPaymentSignalStatusSuccess (state) {
  return {
    ...state,
    salesComponent: {
      ...state.salesComponent,
      paymentSignalStatus: paymentSignalStatusConstants.finished_payment
    }
  }
}

function fetchDiscountsSuccess (state, { discounts }) {
  return {
    ...state,
    discounts
  }
}

function updateDiscountsSuccess (state, { discounts }) {
  return {
    ...state,
    dossierEconomicComponent: {
      ...state.dossierEconomicComponent,
      discounts
    }
  }
}

function updateOptionalsSuccess (state, { optionals }) {
  return {
    ...state,
    dossierEconomicComponent: {
      ...state.dossierEconomicComponent,
      optionals
    }
  }
}

function updateExtraSalesSuccess (state, { extraSales }) {
  return {
    ...state,
    dossierEconomicComponent: {
      ...state.dossierEconomicComponent,
      extraSales
    }
  }
}

function updateSuppliedsSuccess (state, { supplieds }) {
  return {
    ...state,
    dossierEconomicComponent: {
      ...state.dossierEconomicComponent,
      supplieds
    }
  }
}

function updatePresentsSuccess (state, { presents }) {
  return {
    ...state,
    dossierEconomicComponent: {
      ...state.dossierEconomicComponent,
      presents
    }
  }
}

function updateAssociatedCostsSuccess (state, { associatedCosts }) {
  return {
    ...state,
    dossierEconomicComponent: {
      ...state.dossierEconomicComponent,
      associatedCosts
    }
  }
}

function fetchExtrasSuccess (state, { dossierExtras, dossierExtraType }) {
  return {
    ...state,
    [dossierExtraType]: dossierExtras
  }
}

function fetchUACSuccess (state, { UACList }) {
  return {
    ...state,
    uac: UACList
  }
}

function fetchPaperworkSuccess (state, { paperworkList }) {
  return {
    ...state,
    paperWorks: paperworkList
  }
}

function fetchCampaignsSuccess (state, { campaigns }) {
  return {
    ...state,
    campaigns
  }
}

function fetchCampaignsTableSuccess (state, { campaignSubtype }) {
  return {
    ...state,
    campaignSubtype: campaignSubtype.campaignSubtype || state.campaignSubtype
  }
}

function fetchCampaignsTableSuccessAdd (state, { campaign }) {
  return {
    ...state,
    campaignSubtype: state.campaignSubtype.concat(campaign.campaignSubtype)
  }
}

function fetchSuppliedListSuccess (state, { dossierSuppliedList }) {
  return {
    ...state,
    supplieds: dossierSuppliedList
  }
}

function fetchEconomicPlanSuccess (state, { economicPlan }) {
  return {
    ...state,
    dossierEconomicComponent: economicPlan
  }
}

function fetchServicesSuccess (state, { services }) {
  return {
    ...state,
    services
  }
}

function fetchCommentsSuccess (state, { comments }) {
  return {
    ...state,
    comments
  }
}

function fetchDossierRelatedDossiersSuccess (state, { dossierRelatedDossiers }) {
  return {
    ...state,
    dossierRelatedDossiers
  }
}

function fetchAuditInfoSucces (state, { auditInfo }) {
  return {
    ...state,
    auditInfo
  }
}

function fetchSalesDossierEconomicSucces (state, { dossierEconomicComponent }) {
  return {
    ...state,
    dossierEconomicComponent
  }
}

function fetchSalesDossierServiceSucces (state, { dossierServiceRelateds }) {
  return {
    ...state,
    dossierServiceRelateds: dossierServiceRelateds
  }
}

function putPurchaseOnSale (state, { purchaseId, parentIsHistorical }) {
  return {
    ...state,
    purchasesDossierId: purchaseId,
    parentIsHistorical
  }
}

function updateLastAccessDate (state, { lastAccessDateNew }) {
  return {
    ...state,
    lastAccessDate: lastAccessDateNew
  }
}

export function fetchSalesSelfSupplySuccess (state, { dossierSelfSupplyComponent }) {
  return {
    ...state,
    dossierSelfSupplyComponent: { ...dossierSelfSupplyComponent }
  }
}

export function fetchFleetOperationTypeForAutoselectSuccess (state, { fleetOperationTypeId }) {
  return {
    ...state,
    salesComponent: {
      ...state.salesComponent,
      operationTypeIds: [fleetOperationTypeId]
    }
  }
}

function updateClientNoEmployee (state, { value }) {
  return {
    ...state,
    salesComponent: {
      ...state.salesComponent,
      clientNotEmployeeCheck: value
    }
  }
}

function updateResultSubmit (state, { value }) {
  return {
    ...state,
    submitSucced: value
  }
}

function fetchSalesDefaultDestination (state, { value }) {
  return {
    ...state,
    defaultDestination: value
  }
}

function fetchCustomFieldsSuccess (state, { value }) {
  return {
    ...state,
    customFieldsByComponent: value
  }
}

function changeCodeCommissionSuccess (state, { codeCommission }) {
  return {
    ...state,
    dossierEconomicComponent: {
      ...state.dossierEconomicComponent,
      commissionCancellationCodeId: codeCommission.commissionCancellationCodeId,
      commissionCancellationCodeDescription: codeCommission.commissionCancellationCodeDescription,
      fixedCommissionPerc: codeCommission.fixedPercentage,
      variableCommissionPerc: codeCommission.quantitativePercentage,
      qualitativeCommissionPerc: codeCommission.qualitativePercentage,
      updated: true
    }
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case dossierActionTypes.UPDATE_ASSOCIATED_COSTS_SUCCESS:
      return updateAssociatedCostsSuccess(state, action)
    case dossierActionTypes.UPDATE_PRESENTS_SUCCESS:
      return updatePresentsSuccess(state, action)
    case dossierActionTypes.UPDATE_SUPPLIEDS_SUCCESS:
      return updateSuppliedsSuccess(state, action)
    case dossierActionTypes.UPDATE_EXTRA_SALES_SUCCESS:
      return updateExtraSalesSuccess(state, action)
    case dossierActionTypes.UPDATE_OPTIONAL_SUCCESS:
      return updateOptionalsSuccess(state, action)
    case dossierActionTypes.UPDATE_DISCOUNT_SUCCESS:
      return updateDiscountsSuccess(state, action)
    case dossierActionTypes.FETCH_SALES_DOSSIER_SUCCESS:
      return fetchSalesDossierSuccess(state, action)
    case dossierActionTypes.FETCH_SALES_DOSSIER_ECONOMIC_SUCCESS:
      return fetchSalesDossierEconomicSucces(state, action)
    case dossierActionTypes.FETCH_SALES_SERVICES_SUCCESS:
      return fetchSalesDossierServiceSucces(state, action)
    case dossierActionTypes.FETCH_DISCOUNT_SUCCESS:
      return fetchDiscountsSuccess(state, action)
    case dossierActionTypes.POST_PAYMENT_SIGNAL_STATUS_SUCCESS:
      return postPaymentSignalStatusSuccess(state, action)
    case dossierActionTypes.FETCH_EXTRAS_SUCCESS:
      return fetchExtrasSuccess(state, action)
    case dossierActionTypes.FETCH_UAC_SUCCESS:
      return fetchUACSuccess(state, action)
    case dossierActionTypes.FETCH_PAPERWORK_SUCCESS:
      return fetchPaperworkSuccess(state, action)
    case dossierActionTypes.FETCH_CAMPAIGNS_SUCCESS:
      return fetchCampaignsSuccess(state, action)
    case dossierActionTypes.FETCH_SUPPLIED_LIST_SUCCESS:
      return fetchSuppliedListSuccess(state, action)
    case dossierActionTypes.FETCH_ECONOMIC_PLAN_SUCCESS:
      return fetchEconomicPlanSuccess(state, action)
    case dossierActionTypes.FETCH_SERVICE_SUCCESS:
      return fetchServicesSuccess(state, action)
    case commentsActionTypes.FETCH_SALES_COMMENTS_SUCCESS:
      return fetchCommentsSuccess(state, action)
    case dossierActionTypes.FETCH_DOSSIER_RELATED_DOSSIERS_SUCCESS:
      return fetchDossierRelatedDossiersSuccess(state, action)
    case auditInfoTypes.FETCH_AUDIT_INFO_SUCCESS:
      return fetchAuditInfoSucces(state, action)
    case dossierActionTypes.RESTART_SALES_STATE:
      return initialState()
    case dossierActionTypes.PUT_PURCHASE_ON_SALE:
      return putPurchaseOnSale(state, action)
    case dossierActionTypes.PUT_LASTACCESSDATE:
      return updateLastAccessDate(state, action)
    case dossierActionTypes.FETCH_CAMPAIGNSTABLE_SUCCESS:
      return fetchCampaignsTableSuccess(state, action)
    case dossierActionTypes.FETCH_CAMPAIGNSTABLE_SUCCESS_ADD:
      return fetchCampaignsTableSuccessAdd(state, action)
    case dossierActionTypes.OPEN_ADD_SERVICE_MODAL:
      return openOrCloseServiceAddModal(state, action)
    case dossierActionTypes.FETCH_SALES_DOSSIER_SELFSUPPLY_SUCCESS:
      return fetchSalesSelfSupplySuccess(state, action)
    case dossierActionTypes.FETCH_FLEET_OPERATION_TYPE_FOR_AUTOSELECT_SUCCESS:
      return fetchFleetOperationTypeForAutoselectSuccess(state, action)
    case dossierActionTypes.UPDATE_CHECK_CLIENT_NOT_EMPLOYEE:
      return updateClientNoEmployee(state, action)
    case dossierActionTypes.UPDATE_RESULT_SUBMIT:
      return updateResultSubmit(state, action)
    case dossierActionTypes.FETCH_SALES_DEFAULT_DESTINATION:
      return fetchSalesDefaultDestination(state, action)
    case dossierActionTypes.FETCH_CUSTOM_FIELDS_SUCCESS:
      return fetchCustomFieldsSuccess(state, action)
    case dossierActionTypes.CHANGE_CODE_COMMISSION_SUCCESS:
      return changeCodeCommissionSuccess(state, action)
    default:
      return state
  }
}
