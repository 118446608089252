import React, { PureComponent } from 'react'
import { Modal, Row, Col, Button, Table, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { reduxForm, Field } from 'redux-form'
import InputText from '../../commons/form/InputText'
import { formatDate } from '../../../util/utils'
import IDocCarPagination from '../../commons/iDocCarPagination'

class SearchUserRecurrentEntityModal extends PureComponent {
  constructor () {
    super()
    this.state = {
      orderBy: '+name'
    }
    this.closeModal = this.closeModal.bind(this)
  }

  chargeEntityUsers (entityId) {
    this.props.actions.getUserRecurrentServiceEntity(entityId, this.props.orderBy)
  }

  getSortIcon (column = '') {
    switch (this.props.userAdmin.filter.orderBy) {
      case '+' + column:
        return 'ico-sort-selected-asc'
      case '-' + column:
        return 'ico-sort-selected-desc'
      default:
        return 'ico-sort'
    }
  }

  changeOrder (field) {
    switch (this.props.userAdmin.filter.orderBy) {
      case `+${field}`:
        this.props.actions.fetchUserList({ ...this.props.filter, orderBy: `-${field}` })
        break
      default:
        this.props.actions.fetchUserList({ ...this.props.filter, orderBy: `+${field}` })

        break
    }
  }

  fillFilter (value) {
    let filter = { ...this.props.filter, ...value }
    this.props.actions.fillFilterFieldAddUserRecurrentServiceModal(filter)
  }

  closeModal () {
    this.props.actions.openCloseAddUserRecurrentServiceModal(false)
    this.onClickResetSearch()
  }

  searchUsers () {
    this.props.actions.fetchUserList({ ...this.props.filter, page: 1 })
  }

  addUser (userId) {
    new Promise((resolve) => {
      this.props.actions.setUserRecurrentServiceEntity(this.props.entityId, userId, resolve)
    }).then(() => {
      this.props.actions.getUserRecurrentServiceEntity(this.props.entityId, '+name')
    })
  }

  onClickResetSearch () {
    this.props.change('filter.searchName', null)
    this.props.change('filter.searchEmail', null)
    this.props.actions.clearUserAdminSearchFilter()
    this.props.actions.clearUserAdminSearchFilterModal()
  }

  filterActiveUsers (users) {
    if (!users || users.length === 0) {
      return []
    }

    let excludedUsers = this.props.userEntityList || []

    return users.filter((user) => user.isActive === true && !excludedUsers.some((excludedUser) => excludedUser.userId === user.userId))
  }

  doesUserMeetConditions (user) {
    let excludedUsers = this.props.userEntityList || []
    return user.isActive === true && !excludedUsers.some((excludedUser) => excludedUser.userId === user.userId)
  }

  render () {
    const {
      t, showModal, userAdmin: { userList, filter: { page }, pagesCount, usersCount }
    } = this.props
    const tKey = 'ADMIN.'
    const addToolTip = (<Tooltip id="editTooltip">{t('MASTERS.ADD_USER')}</Tooltip>)

    return (
      <Modal className="role-modal languages-modal SearchUserRecurrentEntity" show={showModal} onHide={this.closeModal} backdrop={'static'}>
        <form autoComplete="off" >
          <Modal.Header closeButton onHide={this.closeModal}>
            <Modal.Title>{t(`${tKey}USER_RECURRENT_SERVICE`)}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <div className="search-form">
                <span className="title-search"><i className="ico-search" /> {t(`${tKey}USERS.SEARCH_TITLE`)}</span>
                <Row className='body-search'>
                  <Field
                    id="filter.searchName"
                    name="filter.searchName"
                    controlLabel={t(`${tKey}USERS.NAME`)}
                    placeholder={t(`${tKey}USERS.NAME`)}
                    component={InputText}
                    onInputChange={(val) => this.fillFilter({ searchName: val })}
                  />
                  <Field
                    id="filter.searchEmail"
                    name="filter.searchEmail"
                    controlLabel={t(`${tKey}USERS.EMAIL`)}
                    placeholder={t(`${tKey}USERS.EMAIL`)}
                    component={InputText}
                    onInputChange={(val) => this.fillFilter({ searchEmail: val })}
                  />
                  <Col className="btn-wrapper">
                    <Button
                      className="_Btn accept stk"
                      onClick={() => this.searchUsers()}>
                      <i className="ico-search" />
                      {t(`${tKey}SEARCH_BTN`)}
                    </Button>
                    <Button
                      onClick={() => this.onClickResetSearch()}
                      bsStyle="default"
                      bsSize="small"
                      className="_Btn accept stk"
                      id='modSearchButtonIdPrepare'
                    >
                      {t('MASTERS.RESET_BUTTON')}

                    </Button>
                  </Col>
                </Row>
              </div>
            </Row>
            <Table className="editable-table admin-users" hover responsive>
              <thead>
                <tr>
                  <th style={{ display: 'none' }} onClick={() => { this.changeOrder('code') }}>
                    {t(`${tKey}USERS.CODE`)}&nbsp;
                    <i className={this.getSortIcon('code')} />
                  </th>
                  <th onClick={() => { this.changeOrder('name') }}>
                    {t(`${tKey}USERS.NAME_SURNAME`)}&nbsp;
                    <i className={this.getSortIcon('name')} />
                  </th>
                  <th onClick={() => { this.changeOrder('emailaddress') }}>
                    {t(`${tKey}USERS.EMAIL`)}&nbsp;
                    <i className={this.getSortIcon('emailaddress')} />
                  </th>
                  <th onClick={() => { this.changeOrder('creationDate') }}>
                    {t(`${tKey}USERS.CREATION_DATE`)}
                    <i className={this.getSortIcon('creationDate')} />
                  </th>
                  <th style={{ width: '77px' }}>{t('DOSSIER_COMPONENTS.ACCESSORIES.ACTIONS')}</th>
                </tr>
              </thead>
              <tbody>
                {userList && userList.map((user, idx) => {
                  return (
                    <tr key={idx} className={(!user.isActive ? 'is-deleted' : this.doesUserMeetConditions(user) ? '' : 'selected')}>
                      <td style={{ display: 'none' }}>{user.userId}</td>
                      <td>{user.name} {user.surnames}</td>
                      <td>{user.emailAddress}</td>
                      <td>{formatDate(user.creationDate)}</td>
                      <td className='actions'>
                        {this.doesUserMeetConditions(user) && (
                          <div>
                            <a onClick={() => this.addUser(user.userId)}>
                              <OverlayTrigger placement="right" overlay={addToolTip}>
                                <i className="ico-plus"/>
                              </OverlayTrigger>
                            </a>
                          </div>
                        ) }
                      </td>
                    </tr>)
                })
                }
                {(userList === null || userList === undefined || userList.length === 0) &&
            <tr>
              <td colSpan={12}><h2>{t(`${tKey}USERS.RESULTS_NOT_FOUND`)}</h2></td>
            </tr>}
              </tbody>
            </Table>
            <div className="section-users-footer">
              <nav key={1} className="text-center paginationGroup">
                <IDocCarPagination
                  id="btn_pag_sales"
                  page={page}
                  pagesCount={pagesCount}
                  totalRows={usersCount}
                  onSelectAction={(value) => this.props.actions.fetchUserList({ ...this.props.filter, page: value })}
                />
              </nav>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Col sm={12} className="btn-wrapper">
              <Button
                id="role-modal-submit-button"
                className="btn-standard"
                onClick={() => this.closeModal()}
              >
                {t('MASTERS.RETURN')}
              </Button>
            </Col>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default reduxForm({
  form: 'searchUserRecurrentEntityModal'
})(SearchUserRecurrentEntityModal)
