export const documentStatus = {
  VALIDATED: 5,
  REJECTED: 4,
  PENDING: 3,
  INCORPORATION_PENDING: 2,
  REMOVED: 1,
  EXPIRED: -1
}

export const documentStatusClass = {
  REJECTED: 'wrong',
  PENDING: 'pending',
  VALIDATED: 'ok',
  REMOVED: 'no-retrievable',
  INCORPORATION_PENDING: 'services',
  EXPIRED: 'wrong'
}

export const translateDocumentStatus = {
  5: 'VALIDATED',
  4: 'REJECTED',
  3: 'PENDING',
  2: 'INCORPORATION_PENDING',
  1: 'REMOVED',
  '-1': 'EXPIRED'
}

export const checklistStatusIcon = {
  1: 'ok',
  2: 'warning-sign',
  3: 'record',
  4: 'remove-circle'
}

export const checklistStatusClass = {
  1: '',
  2: 'red',
  3: 'orange'
}

export const signatureStatusIcon = {
  0: 'pending',
  1: 'ok',
  2: 'no-retrievable',
  3: 'wrong',
  5: 'expired'
}

export const translateSignatureStatus = {
  5: 'SIGN_EXPIRED',
  3: 'SIGN_REJECTED',
  2: 'SIGN_CANCELLED',
  1: 'SIGN_SIGNED',
  0: 'SIGN_PENDING',
  '-1': 'SIGN_AVAILABLE'
}

export const signStatus = {
  SIGN_PENDING: 0,
  SIGN_SIGNED: 1,
  SIGN_CANCELLED: 2,
  SIGN_REJECTED: 3,
  SIGN_AVAILABLE: -1
}

export const sealStatus = {
  PENDING_SEND: 1,
  PENDING_SEAL: 2,
  SEALED: 3
}
