import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import FaqMaster from '../../../components/masters/faq/FaqMaster'
import { translate } from 'react-polyglot'
import { fetchFaqList, deleteFaq, openFaqModal, recoverFaqModal, fecthFaqSectionCombo } from '../../../actions/masters/faq/faqMaster'

const mapStateToProps = state => {
  return {
    ...state.faqMaster,
    combos: {
      UOTreeCombo: state.combos.UOTreeCombo,
      faqSectionCombo: state.faqMaster.faqSectionCombo || []
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchFaqList,
      deleteFaq,
      openFaqModal,
      recoverFaqModal,
      fecthFaqSectionCombo
    }, dispatch)

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(FaqMaster))
