import apiFetch from '../apiFetch'

export default function (purchasePresentTypeValues, uoId, token) {
  const body = {
    organizedUnitId: uoId,
    isDeleted: false,
    purchasePresentTypeLanguage: purchasePresentTypeValues
  }
  return apiFetch({
    endPoint: 'PurchasePresentType',
    method: 'POST',
    body: body,
    token: token
  })
}
