
import { fetchProcessingManagersByNameOrUoSuccess, createProcessingManagerSuccess, updateProcessingManagerSuccess, fetchProcessingsAgencyManagerSuccess, setProcessingAgencyManagerFilter, closeProcessingManagerModal } from '../../../actions/masters/processingManager/processingManager'
import { getAuth } from '../../../selectors/access/auth'
import { handleError } from '../../errors/errorManager'
import { put, call, select, takeEvery } from 'redux-saga/effects'
import { showLoader, hideLoader } from '../../../actions/common.js'
import mastersActionTypes from '../../../constants/actions/masters/masters'
import fetchProcessingManagersByNameOrUoService from '../../../services/processingManager/fetchProcessingManagersByNameOrUoService'
import createProcessingManagerService from '../../../services/processingManager/createProcessingManagerService'
import updateProcessingManagerService from '../../../services/processingManager/updateProcessingManagerService'
import deleteProcessingManagerService from '../../../services/processingManager/deleteProcessingManagerService'
import recoverProcessingManagerService from '../../../services/processingManager/recoverProcessingManagerService'
import fetchProcessingAgencyForManager from '../../../services/processingManager/fetchProcessingAgencyForManager'
import { getProcessingManagerState } from '../../../selectors/masters/processingManager'
import { yesNoModal } from '../../modalsListeners/yesNoModal'

export function * fetchProcessingManagersByNameOrUo ({ name, uo }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const processingManagers = yield call(fetchProcessingManagersByNameOrUoService, auth.token, name, uo)
    yield put(setProcessingAgencyManagerFilter({ page: 1, orderBy: '', pageSize: 10 }))
    yield put(fetchProcessingManagersByNameOrUoSuccess(processingManagers))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchProcessingManagersByNameOrUo () {
  yield takeEvery(mastersActionTypes.FETCH_PROCESSING_MANAGERS_BY_NAME_OR_UO, fetchProcessingManagersByNameOrUo)
}

export function * createProcessingManager ({ processingManager }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const result = yield call(createProcessingManagerService, auth.token, processingManager)
    yield put(createProcessingManagerSuccess(result))

    let processingManagerData = JSON.parse(JSON.stringify(yield select(getProcessingManagerState)))
    let processingManagers = processingManagerData.processingManagers
    processingManagers.push(result)
    yield put(fetchProcessingManagersByNameOrUoSuccess(processingManagers))
    yield put(closeProcessingManagerModal())
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchCreateProcessingManager () {
  yield takeEvery(mastersActionTypes.CREATE_PROCESSING_MANAGER, createProcessingManager)
}

export function * updateProcessingManager ({ processingManager }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const result = yield call(updateProcessingManagerService, auth.token, processingManager)
    yield put(updateProcessingManagerSuccess(result))

    let processingManagerData = JSON.parse(JSON.stringify(yield select(getProcessingManagerState)))

    let processingManagers = processingManagerData.processingManagers

    const foundIndex = processingManagers.findIndex(
      (manager) => manager.processingAgencyManagerId === processingManager.processingAgencyManagerId
    )

    if (foundIndex !== -1) {
      processingManagers[foundIndex].name = processingManager.name
      processingManagers[foundIndex].processingAgencyIds = processingManager.processingAgencyIds
    }

    yield put(fetchProcessingManagersByNameOrUoSuccess(processingManagers))
    yield put(closeProcessingManagerModal())
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchUpdateProcessingManager () {
  yield takeEvery(mastersActionTypes.UPDATE_PROCESSING_MANAGER, updateProcessingManager)
}

export function * deleteProcessingManager ({ processingManagerId }) {
  try {
    let processingManagerData = JSON.parse(JSON.stringify(yield select(getProcessingManagerState)))

    const processingManagers = processingManagerData.processingManagers

    const foundIndex = processingManagers.findIndex(
      (manager) => manager.processingAgencyManagerId === processingManagerId
    )

    const confirmed = yield call(yesNoModal, 'htmlDefaultAcceptCancel', null, null, '<p>#T#MASTERS.PROCESSING_MANAGER.DELETE_MANAGER#T#<p>', null, [{ id: '##MANAGER##', value: processingManagers[foundIndex].name }])

    if (confirmed) {
      yield put(showLoader())
      const auth = yield select(getAuth)
      yield call(deleteProcessingManagerService, auth.token, processingManagerId)

      if (foundIndex !== -1) {
        processingManagers[foundIndex].isDeleted = true
      }

      yield put(fetchProcessingManagersByNameOrUoSuccess(processingManagers))
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDeleteProcessingManager () {
  yield takeEvery(mastersActionTypes.DELETE_PROCESSING_MANAGER, deleteProcessingManager)
}

export function * recoverProcessingManager ({ processingManagerId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(recoverProcessingManagerService, auth.token, processingManagerId)

    let processingManagerData = JSON.parse(JSON.stringify(yield select(getProcessingManagerState)))

    const processingManagers = processingManagerData.processingManagers

    const foundIndex = processingManagers.findIndex(
      (manager) => manager.processingAgencyManagerId === processingManagerId
    )

    if (foundIndex !== -1) {
      processingManagers[foundIndex].isDeleted = false
    }

    yield put(fetchProcessingManagersByNameOrUoSuccess(processingManagers))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchRecoverProcessingManager () {
  yield takeEvery(mastersActionTypes.RECOVER_PROCESSING_MANAGER, recoverProcessingManager)
}

export function * fetchProcessingsAgencyManager () {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const processingAgencys = yield call(fetchProcessingAgencyForManager, auth.token)
    yield put(fetchProcessingsAgencyManagerSuccess(processingAgencys))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchProcessingsAgencyManager () {
  yield takeEvery(mastersActionTypes.FETCH_PROCESSINGS_AGENCY_MANAGER, fetchProcessingsAgencyManager)
}
