import React, { PureComponent } from 'react'
import { Col, FormGroup, ControlLabel, OverlayTrigger, Tooltip, Glyphicon } from 'react-bootstrap'
import DropZone from 'react-dropzone'

class InputDropzone extends PureComponent {
  constructor () {
    super()
    this.state = {
      dropzoneActive: false,
      hasInvalidFile: false
    }
  }

  onDrop (acceptedFiles, rejectedFiles) {
    if (acceptedFiles.length === 0 && rejectedFiles.length > 0) {
      this.setState({ hasInvalidFile: true })
      this.props.onInvalidFile ? this.props.onInvalidFile(this.props.acceptFiles) : null
    } else {
      this.props.input.onChange(acceptedFiles[0])
      if (this.props.onInputChange) {
        this.props.onInputChange(acceptedFiles[0])
      }
    }
  }

  onDragEnter () {
    this.setState({
      dropzoneActive: true
    })
  }

  onDragLeave () {
    this.setState({
      dropzoneActive: false
    })
  }

  formatBytes (bytes, decimals = 2) {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
  }

  render () {
    const {
      t,
      id,
      colSm = 3,
      colSmOffset = 0,
      meta,
      showError = true,
      input,
      rightIcon = '',
      controlLabel,
      customClass = '',
      forceControlLabelInOneLine = false,
      disabled = false,
      paddingLeft,
      inline,
      multiple = false,
      preview = false,
      previewTooltipText = '',
      previewTooltipPosition = 'left',
      fetchTemplateFile,
      acceptFiles,
      maxSizeFile
    } = this.props

    const acceptFilesDropzone = 'application/pdf'
    const maxSizeFilesDropzone = 5120000
    const tKey = 'GLOBAL.FORMS.INPUT_DROPZONE.'
    const previewTooltip = (<Tooltip id="previewTooltip">{previewTooltipText}</Tooltip>)

    let errorMessage = ((showError || meta.touched) && meta.error && typeof meta.error !== 'boolean')
      ? meta.error
      : null
    if (this.state.hasInvalidFile) errorMessage = t(`${tKey}INVALID_FILE`) + '(' + (acceptFiles || acceptFilesDropzone) + ')'

    return (
      <Col sm={colSm} smOffset={colSmOffset} className={'input-dropzone-wrapper ' + customClass} style={paddingLeft}>
        <FormGroup
          className={(rightIcon ? 'input-with-icon' : '') + (inline ? ' row' : '')}
          validationState={(showError || meta.touched) && (meta.error || this.state.hasInvalidFile) ? 'error' : null}
        >
          {controlLabel && (
            <ControlLabel className={(forceControlLabelInOneLine ? 'one-line ellipsis' : '') + (inline ? ' col-sm-3' : '')}>
              {controlLabel} {preview && (
                <OverlayTrigger onClick= {() => fetchTemplateFile && fetchTemplateFile()}placement={previewTooltipPosition} overlay={previewTooltip}>
                  <Glyphicon glyph="ok-sign"/>
                </OverlayTrigger>
              )}
            </ControlLabel>
          )}
          <Col sm={inline ? 9 : 0}>
            <DropZone
              id={id}
              multiple={multiple}
              onDrop={(acceptedFiles, rejectedFiles) => this.onDrop(acceptedFiles, rejectedFiles)}
              accept={acceptFiles || acceptFilesDropzone}
              maxSize={maxSizeFile || maxSizeFilesDropzone}
              className={'input-dropzone ' + (this.state.dropzoneActive ? 'active' : '') + customClass}
              disabled={disabled}
              onDragEnter={this.onDragEnter.bind(this)}
              onDragLeave={this.onDragLeave.bind(this)}
            >
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  { input.value ? (
                    <div className="text-wrapper">
                      {t(`${tKey}SELECTED_FILES`)}: {input.value.name}
                    </div>
                  ) : (
                    <div className="text-wrapper">
                      <i className="ico-cloud"/>&nbsp;{t(`${tKey}DROP_FILES`)}
                      <br/>
                      {t(`${tKey}SELECT_FILES`).replace('{{SIZE}}', this.formatBytes(maxSizeFile || maxSizeFilesDropzone))}
                      {errorMessage && (<br key="br"/>)}
                      <div className={this.state.hasInvalidFile ? 'file-error' : ''}>
                        {errorMessage}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </DropZone>
          </Col>
        </FormGroup>
      </Col>
    )
  }
}

export default InputDropzone
