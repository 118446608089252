import apiFetch from '../../apiFetch'

export default function (paperworkId, comment, token, lastAccessDate) {
  return apiFetch({
    endPoint: `dossier/paperwork/${paperworkId}/reject`,
    method: 'PUT',
    body: comment,
    token: token,
    lastAccessDate
  })
}
