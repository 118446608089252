import documentHistoricalEditionModalActionTypes from '../../../constants/actions/dossier/common/documentHistoricalEditionModal'

export function openDocumentCreationModal (dossierId, documentParentTypeId, organizedUnitId, entityId, vehicleId, callback, refreshAfterChange, dossierType) {
  return {
    type: documentHistoricalEditionModalActionTypes.OPEN_DOCUMENT_HISTORICAL_CREATION_MODAL,
    dossierId,
    documentParentTypeId,
    organizedUnitId,
    entityId,
    vehicleId,
    callback,
    refreshAfterChange,
    dossierType
  }
}

export function openDocumentCreationModalSuccess (documentParentTypeId, organizedUnitId, dossierId, entityId, vehicleId, callback, refreshAfterChange, dossierType) {
  return {
    type: documentHistoricalEditionModalActionTypes.OPEN_DOCUMENT_HISTORICAL_CREATION_MODAL_SUCCESS,
    documentParentTypeId,
    organizedUnitId,
    dossierId,
    entityId,
    vehicleId,
    callback,
    refreshAfterChange,
    dossierType
  }
}

export function openDocumentEditionModal (documentId, documentTypeUse, documentParentTypeId, dossierId, entityId, vehicleId, organizedUnitId, checklistId, callback, refreshAfterChange, dossierType) {
  return {
    type: documentHistoricalEditionModalActionTypes.OPEN_DOCUMENT_HISTORICAL_EDITION_MODAL,
    documentId,
    documentTypeUse,
    documentParentTypeId,
    dossierId,
    entityId,
    vehicleId,
    organizedUnitId,
    checklistId,
    callback,
    refreshAfterChange,
    dossierType
  }
}

export function openDocumentEditionModalSuccess (docResult, documentTypeUseInfo, organizedUnitId, callback, dossierId, entityId, vehicleId, documentParentTypeId, checklistId, refreshAfterChange, dossierType) {
  return {
    type: documentHistoricalEditionModalActionTypes.OPEN_DOCUMENT_HISTORICAL_EDITION_MODAL_SUCCESS,
    docResult,
    documentParentTypeId,
    documentTypeUseInfo,
    organizedUnitId,
    callback,
    dossierId,
    entityId,
    vehicleId,
    checklistId,
    refreshAfterChange,
    dossierType
  }
}

export function onDocumentTypeIdChange (documentTypeId, documentTypeUseName) {
  return {
    type: documentHistoricalEditionModalActionTypes.ON_DOCUMENT_HISTORICAL_TYPE_ID_CHANGE,
    documentTypeId,
    documentTypeUseName
  }
}

export function closeAddOrEditDocumentModal (refreshAfterChange) {
  return {
    type: documentHistoricalEditionModalActionTypes.CLOSE_ADD_OR_EDIT_DOCUMENT_HISTORICAL_MODAL,
    refreshAfterChange
  }
}

export function closeAddOrEditDocumentModalSuccess () {
  return {
    type: documentHistoricalEditionModalActionTypes.CLOSE_ADD_OR_EDIT_DOCUMENT_HISTORICAL_MODAL_SUCCESS
  }
}

export function fetchDocument (documentId) {
  return {
    type: documentHistoricalEditionModalActionTypes.FETCH_DOCUMENT_HISTORICAL,
    documentId
  }
}

export function fetchDocumentSuccess (docResult, documentTypeId, documentTypeUseName) {
  return {
    type: documentHistoricalEditionModalActionTypes.FETCH_DOCUMENT_HISTORICAL_SUCCESS,
    docResult,
    documentTypeId,
    documentTypeUseName
  }
}

export function createDocument (file, checklistId, documentTypeUseId, dossierId) {
  return {
    type: documentHistoricalEditionModalActionTypes.CREATE_DOCUMENT_HISTORICAL,
    file,
    checklistId,
    documentTypeUseId,
    dossierId
  }
}

export function uploadDocument (file, documentTypeId) {
  return {
    type: documentHistoricalEditionModalActionTypes.UPLOAD_DOCUMENT_HISTORICAL,
    file,
    documentTypeId
  }
}

export function uploadNewVersion () {
  return {
    type: documentHistoricalEditionModalActionTypes.UPLOAD_NEW_VERSION_HISTORICAL
  }
}

export function saveDocument (fields) {
  return {
    type: documentHistoricalEditionModalActionTypes.SAVE_DOCUMENT_HISTORICAL,
    fields
  }
}

export function toggleFullScreenDocumentModal (documentId, isDownload) {
  return {
    type: documentHistoricalEditionModalActionTypes.TOGGLE_FULL_SCREEN_DOCUMENT_HISTORICAL_MODAL,
    documentId,
    isDownload
  }
}

export function rejectDocument (documentId, rejectMessage, resolve, reject) {
  return {
    type: documentHistoricalEditionModalActionTypes.REJECT_DOCUMENT_HISTORICAL,
    documentId,
    rejectMessage,
    resolve,
    reject
  }
}

export function validateDocument (documentId, fields) {
  return {
    type: documentHistoricalEditionModalActionTypes.VALIDATE_DOCUMENT_HISTORICAL,
    documentId,
    fields
  }
}

export function changeId (id) {
  return {
    type: documentHistoricalEditionModalActionTypes.CHANGE_ID_HISTORICAL,
    id
  }
}

export function recoverDocument (documentId, documentTypeUse, documentParentTypeId, dossierId, entityId, vehicleId, organizedUnitId, checklistId, callback, refreshAfterChange, dossierType, dossierSubType) {
  return {
    type: documentHistoricalEditionModalActionTypes.RECOVER_DOCUMENT_HISTORICAL,
    documentId,
    documentTypeUse,
    documentParentTypeId,
    dossierId,
    entityId,
    vehicleId,
    organizedUnitId,
    checklistId,
    callback,
    refreshAfterChange,
    dossierType,
    dossierSubType
  }
}

export function createDocumentSheet (file, organizedUnitId) {
  return {
    type: documentHistoricalEditionModalActionTypes.CREATE_DOCUMENT_SHEET_HISTORICAL,
    file,
    organizedUnitId
  }
}

export function deleteDocumentSheet (documentId, sheetId, resolve, reject, organizedUnitId) {
  return {
    type: documentHistoricalEditionModalActionTypes.DELETE_DOCUMENT_SHEET_HISTORICAL,
    documentId,
    sheetId,
    resolve,
    reject,
    organizedUnitId
  }
}

export function setActivePage (sheetId, pageId) {
  return {
    type: documentHistoricalEditionModalActionTypes.SET_ACTIVE_PAGE_HISTORICAL,
    sheetId,
    pageId
  }
}

export function toggleFullScreenDocumentSheetModal (documentId, sheetId, isDownload) {
  return {
    type: documentHistoricalEditionModalActionTypes.TOGGLE_FULL_SCREEN_DOCUMENT_SHEET_HISTORICAL_MODAL,
    documentId,
    sheetId,
    isDownload
  }
}

export default {
  openDocumentCreationModal,
  openDocumentCreationModalSuccess,
  openDocumentEditionModal,
  openDocumentEditionModalSuccess,
  onDocumentTypeIdChange,
  closeAddOrEditDocumentModal,
  closeAddOrEditDocumentModalSuccess,
  fetchDocument,
  fetchDocumentSuccess,
  createDocument,
  uploadDocument,
  uploadNewVersion,
  saveDocument,
  toggleFullScreenDocumentModal,
  rejectDocument,
  validateDocument,
  changeId,
  recoverDocument,
  createDocumentSheet,
  deleteDocumentSheet,
  setActivePage,
  toggleFullScreenDocumentSheetModal
}
