import React, { PureComponent } from 'react'
import { reduxForm, Field } from 'redux-form'
import { Row, Col, Table, Tooltip, OverlayTrigger, Button } from 'react-bootstrap'
import IDocCarPagination from '../../commons/iDocCarPagination'
import DocumentTypeMetadataModalPage from '../../../containers/masters/Metadata/MetadataModalPage'
import DocumentTypeMetadataListModalPage from '../../../containers/masters/Metadata/MetadataListModalPage'
import InputSelect from '../../commons/form/InputSelect'
import { typeFields as metadataType } from '../../../constants/dossier/common/typeFields'
import { getComboTranslatedFromEnum } from '../../../util/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faListUl } from '@fortawesome/free-solid-svg-icons'

class MetadataMaster extends PureComponent {
  constructor () {
    super()
    this.state = {
      documentTypeId: -1,
      filter: {
        entityTypeId: null,
        text: null
      }
    }
  }

  getSortIcon (column = '') {
    switch (this.props.filter.orderBy) {
      case '+' + column:
        return 'ico-sort-selected-asc'
      case '-' + column:
        return 'ico-sort-selected-desc'
      default:
        return 'ico-sort'
    }
  }

  changeOrder (field) {
    switch (this.props.filter.orderBy) {
      case `+${field}`:
        this.props.actions.fetchDocumentTypeMetadataList({ ...this.state.filter, ...this.props.filter, orderBy: `-${field}` })
        break
      default:
        this.props.actions.fetchDocumentTypeMetadataList({ ...this.state.filter, ...this.props.filter, orderBy: `+${field}` })
        break
    }
  }

  searchCampaign () {
    this.props.actions.fetchDocumentTypeMetadataList({ ...this.props.filter, ...this.state.filter, page: 1 })
  }

  changedValue (value) {
    this.setState({ documentTypeId: value })
    this.props.actions.fetchDocumentTypeMetadataList({ ...this.props.filter, ...this.state.filter, page: 1, documentTypeId: value ?? -1 })
  }

  render () {
    const {
      t, pagesCount, documentTypeMetadataListCount, filter, documentTypeMetadataList, languageSelected,
      combos: { documentTypes },
      filter: { page },
      actions: { openDocumentTypeMetadataModal, fetchDocumentTypeMetadataList, deleteDocumentTypeMetadata, openMetadataListModal }
    } = this.props
    const tKey = 'MASTERS.METADATA_MASTERS.'
    const editTooltip = (<Tooltip id="editTooltip">{t(`${tKey}EDIT`)}</Tooltip>)
    const editListTooltip = (<Tooltip id="editTooltip">{t(`${tKey}EDIT_METADATA_LIST`)}</Tooltip>)
    const deleteTooltip = (<Tooltip id="deleteTooltip">{t(`${tKey}DELETE`)}</Tooltip>)
    let metadatoTypes = getComboTranslatedFromEnum(t, tKey, metadataType)
    return (
      <div className="admin-wrapper">
        <DocumentTypeMetadataModalPage/>
        <DocumentTypeMetadataListModalPage/>
        <Row className="section-users-header">
          <Col sm={12}>
            <div className="header-panel">
              <h4>{t(`${tKey}DOCUMENT_TYPE_METADATA`)}</h4>
            </div>
          </Col>
        </Row>
        <Col sm={12} className="col-row  section-users-body">
          <h5>{t(`${tKey}DOCUMENT_TYPES`)}</h5>
          <Field
            name="documentType"
            placeholder={t(`${tKey}DOCUMENT_TYPES`)}
            valueKey="id"
            labelKey="value"
            component={InputSelect}
            colSm={6}
            options={documentTypes}
            onChange={(val) => this.changedValue(val)}
          />
          <Table className="editable-table" hover responsive>
            <thead>
              <tr>
                <th onClick={() => { this.changeOrder('documentTypeId') }}>
                  {t(`${tKey}METADATO_TYPE`)}
                </th>
                <th onClick={() => { this.changeOrder('documentTypeId') }}>
                  {t(`${tKey}CODE`)}
                </th>
                <th onClick={() => { this.changeOrder('documentTypeId') }}>
                  {t(`${tKey}NAME`)}
                </th>
                <th onClick={() => { this.changeOrder('documentTypeId') }}>
                  {t(`${tKey}DESCRIPTION`)}
                </th>
                <th style={{ width: '115px' }}>{t('DOSSIER_COMPONENTS.ACCESSORIES.ACTIONS')}</th>
              </tr>
            </thead>
            <tbody>
              {documentTypeMetadataList && documentTypeMetadataList.map((documentTypeMetadata, idx) => {
                return (
                  <tr key={idx} className={documentTypeMetadata.isDeleted ? 'is-deleted' : ''}>
                    <td>{metadatoTypes.find(x => x.id === documentTypeMetadata.metadatoType)?.value || ''}</td>
                    <td>{documentTypeMetadata.code}</td>
                    <td>{documentTypeMetadata.metadatoLocales.find(x => x.languageId === languageSelected)?.name || ''}</td>
                    <td>{documentTypeMetadata.metadatoLocales.find(x => x.languageId === languageSelected)?.description || ''}</td>
                    <td>
                      {!documentTypeMetadata.isDeleted && (
                        <div>
                          <a onClick={() => openDocumentTypeMetadataModal(documentTypeMetadata, this.state.documentTypeId)}>
                            <OverlayTrigger placement="left" overlay={editTooltip}>
                              <i className="ico-edit-white" />
                            </OverlayTrigger>
                          </a>
                          <a onClick={() => deleteDocumentTypeMetadata(documentTypeMetadata.metadatoId, this.state.documentTypeId)}>
                            <OverlayTrigger placement="left" overlay={deleteTooltip}>
                              <i className="ico-trash" />
                            </OverlayTrigger>
                          </a>
                          {documentTypeMetadata.metadatoType === metadataType.list && (
                            <a onClick={() => openMetadataListModal(documentTypeMetadata)}>
                              <OverlayTrigger placement="left" overlay={editListTooltip}>
                                <FontAwesomeIcon icon={faListUl} />
                              </OverlayTrigger>
                            </a>
                          )}
                        </div>
                      )}
                    </td>
                  </tr>)
              })
              }
              {(documentTypeMetadataList === null || documentTypeMetadataList === undefined || documentTypeMetadataList.length === 0) &&
            <tr>
              <td colSpan={10}><h2>{t(`${tKey}RESULTS_NOT_FOUND`)}</h2></td>
            </tr>}
              <tr className="button-table-wrapper">
                <td>
                  <Button
                    disabled={this.props.invalid}
                    className="btn-editable-table btn-standard"
                    onClick={() => openDocumentTypeMetadataModal(null, this.state.documentTypeId)}>
                  + {t(`${tKey}ADD`)}
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>
          <div className="section-users-footer">
            <nav key={1} className="text-center">
              <IDocCarPagination
                id="btn_pag_sales"
                page={page}
                pagesCount={pagesCount}
                totalRows={documentTypeMetadataListCount}
                onSelectAction={(value) => fetchDocumentTypeMetadataList({ ...filter, ...this.state.filter, page: value })}
              />
            </nav>
          </div>
        </Col>
      </div>
    )
  }
}

export default reduxForm({
  form: 'listDocumentTypeMetadata',
  validate: (values, props) => {
    let error = {}

    const tKey = 'MASTERS.METADATA_MASTERS.VALIDATION.'
    if (!values.documentType) {
      error.documentType = props.t(`${tKey}DOCUMENTS_TYPES`)
    }

    return Object.keys(error).length > 0 ? error : {}
  }
})(MetadataMaster)
