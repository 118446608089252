import { put, call, select, takeEvery } from 'redux-saga/effects'
import { initialize, reset } from 'redux-form'
import dossierActionsTypes from '../../../constants/actions/dossier/dossier'
import { dossierTypes as dossierTypesConstant } from '../../../constants/dossier/common/dossierTypes'
import { showLoader, hideLoader } from '../../../actions/common'
import messageModalActions from '../../../actions/modals/messageModal'
import { yesNoModal } from '../../modalsListeners/yesNoModal'
import { fetchPaperworkDossier } from './fetchPaperworkDossier'
import { getAuth } from '../../../selectors/access/auth'
import putPaperworkRejectService from '../../../services/dossierPaperwork/put/putPaperworkReject'
import putGenDeliveryService from '../../../services/dossierPaperwork/put/putGenDelivery'
import getStageCheckList from '../../../services/dossier/get/getStageCheckList'
import getSaleHeaderAndButtons from '../../../services/dossier/get/getSaleHeaderAndButtons'
import { handleError } from '../../errors/errorManager'
import { initializeDossier } from '../commonDossierSagas'
import getResendToProcessingAgency from '../../../services/dossier/get/getResendToProcessingAgency'
import putPaperworkNotify from '../../../services/dossier/put/putPaperworkNotify'
import putDossierTransferCompletedService from '../../../services/dossier/put/putDossierTransferCompleted'

export function * paperworkReject ({ comment, resolve, reject, pristine }) {
  try {
    if (!pristine) {
      const confirmedSaveWarning = yield call(yesNoModal, 'commonDossierSaveWarning')
      if (confirmedSaveWarning) {
        const auth = yield select(getAuth)
        const paperworkDossierState = yield select(state => state.paperworkDossier)
        const confirmed = yield call(yesNoModal, 'paperworkDossierReject')
        if (confirmed) {
          yield put(showLoader())
          yield call(putPaperworkRejectService, paperworkDossierState.dossierId, comment, auth.token, paperworkDossierState.lastAccessDate)
          yield call(initializeDossier, dossierTypesConstant.paperwork, paperworkDossierState)
        }
      }
    } else {
      const auth = yield select(getAuth)
      const paperworkDossierState = yield select(state => state.paperworkDossier)
      const confirmed = yield call(yesNoModal, 'paperworkDossierReject')
      if (confirmed) {
        yield put(showLoader())
        yield call(putPaperworkRejectService, paperworkDossierState.dossierId, comment, auth.token, paperworkDossierState.lastAccessDate)
        yield call(initializeDossier, dossierTypesConstant.paperwork, paperworkDossierState)
      }
    }
    yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          const paperworkDossierState = yield select(state => state.paperworkDossier)
          yield call(initializeDossier, dossierTypesConstant.paperwork, paperworkDossierState)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
      yield call(reject, customHandlerError)
    }
    const sessionHandler = function * () {
      yield put(reset('paperwork_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchPaperworkReject () {
  yield takeEvery(dossierActionsTypes.PAPERWORK_REJECT, paperworkReject)
}

export function * paperworkGenDelivery (pristine) {
  try {
    if (!pristine.pristine) {
      const confirmedSaveWarning = yield call(yesNoModal, 'commonDossierSaveWarning')
      if (confirmedSaveWarning) {
        const auth = yield select(getAuth)
        const paperworkDossierState = yield select(state => state.paperworkDossier)
        const confirmed = yield call(yesNoModal, 'paperworkGenDelivery')
        if (confirmed) {
          yield put(showLoader())
          const response = yield call(putGenDeliveryService, paperworkDossierState.dossierId, auth.token, paperworkDossierState.lastAccessDate, paperworkDossierState.dossierPaperworkComponent.printAgencyDocument)
          if (response.rejectMessageCode) {
            yield put(messageModalActions.openErrorMessageModal('DOSSIER_COMPONENTS.PAPERWORK_DOSSIER.PAPERWORK_COMPONENT.' + response.rejectMessageCode))
          }
          let paperwork
          const checkListHeader = yield call(getStageCheckList, paperworkDossierState.dossierId, auth.token)
          const headerAndButtons = yield call(getSaleHeaderAndButtons, auth.token, paperworkDossierState.dossierId)
          paperwork = { ...headerAndButtons }
          paperwork = { ...paperwork, checkListHeader }
          yield call(fetchPaperworkDossier, { dossierId: paperworkDossierState.dossierId, paperwork })
          const updatedPaperworkDossier = yield select(state => state.paperworkDossier)
          yield put(initialize('paperwork_dossier', updatedPaperworkDossier))
        }
      }
    } else {
      const auth = yield select(getAuth)
      const paperworkDossierState = yield select(state => state.paperworkDossier)
      const confirmed = yield call(yesNoModal, 'paperworkGenDelivery')
      if (confirmed) {
        yield put(showLoader())
        const response = yield call(putGenDeliveryService, paperworkDossierState.dossierId, auth.token, paperworkDossierState.lastAccessDate, paperworkDossierState.dossierPaperworkComponent.printAgencyDocument)
        if (response.rejectMessageCode) {
          yield put(messageModalActions.openErrorMessageModal('DOSSIER_COMPONENTS.PAPERWORK_DOSSIER.PAPERWORK_COMPONENT.' + response.rejectMessageCode))
        }
        let paperwork
        const checkListHeader = yield call(getStageCheckList, paperworkDossierState.dossierId, auth.token)
        const headerAndButtons = yield call(getSaleHeaderAndButtons, auth.token, paperworkDossierState.dossierId)
        paperwork = { ...headerAndButtons }
        paperwork = { ...paperwork, checkListHeader }
        yield call(fetchPaperworkDossier, { dossierId: paperworkDossierState.dossierId, paperwork })
        const updatedPaperworkDossier = yield select(state => state.paperworkDossier)
        yield put(initialize('paperwork_dossier', updatedPaperworkDossier))
      }
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          const paperworkDossierState = yield select(state => state.paperworkDossier)
          yield call(initializeDossier, dossierTypesConstant.paperwork, paperworkDossierState)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('paperwork_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchPaperworkGenDelivery () {
  yield takeEvery(dossierActionsTypes.PAPERWORK_GEN_DELIVERY, paperworkGenDelivery)
}

export function * paperworkWarningSave ({ reset, router, nextLocation, pristine }) {
  if (!pristine) {
    const confirmedSaveWarning = yield call(yesNoModal, 'commonDossierSaveWarning')
    if (confirmedSaveWarning) {
      const promise = new Promise((resolve, reject) => {
        reset('paperwork_dossier')
        resolve()
      })
      promise.then(() => {
        router.replace(nextLocation)
      })
    }
  }
}

export function * watchPaperworkWarningSave () {
  yield takeEvery(dossierActionsTypes.PAPERWORK_WARNING_SAVE, paperworkWarningSave)
}

export function * paperworkResendToProcessingAgency ({ paperworkId, lastAccessDate }) {
  try {
    const paperworkDossierState = yield select(state => state.paperworkDossier)
    const confirm = yield call(yesNoModal, 'paperworkGenDelivery')
    if (confirm) {
      const auth = yield select(getAuth)
      yield put(showLoader())
      const response = yield call(getResendToProcessingAgency, paperworkId, lastAccessDate, auth.token)
      if (response.rejectMessageCode) {
        yield put(messageModalActions.openErrorMessageModal('DOSSIER_COMPONENTS.PAPERWORK_DOSSIER.PAPERWORK_COMPONENT.' + response.rejectMessageCode))
      }
      let paperwork
      const checkListHeader = yield call(getStageCheckList, paperworkDossierState.dossierId, auth.token)
      const headerAndButtons = yield call(getSaleHeaderAndButtons, auth.token, paperworkDossierState.dossierId)
      paperwork = { ...headerAndButtons }
      paperwork = { ...paperwork, checkListHeader }
      yield call(fetchPaperworkDossier, { dossierId: paperworkDossierState.dossierId, paperwork })
      const updatedPaperworkDossier = yield select(state => state.paperworkDossier)
      yield put(initialize('paperwork_dossier', updatedPaperworkDossier))
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          const paperworkDossierState = yield select(state => state.paperworkDossier)
          yield call(initializeDossier, dossierTypesConstant.paperwork, paperworkDossierState)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('paperwork_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchPaperworkResendToProcessingAgency () {
  yield takeEvery(dossierActionsTypes.PAPERWORK_RESEND_TO_PROCESSING_AGENCY, paperworkResendToProcessingAgency)
}

export function * paperworkNotify ({ paperworkId }) {
  try {
    const vehicleId = yield select(state => state.paperworkDossier.vehicleComponent.vehicleId)
    const confirm = yield call(yesNoModal, 'paperworkSendDataVehicleSima')
    if (confirm) {
      const auth = yield select(getAuth)
      yield put(showLoader())
      yield call(putPaperworkNotify, paperworkId, vehicleId, auth.token)
      const paperworkDossierState = yield select(state => state.paperworkDossier)
      yield call(initializeDossier, dossierTypesConstant.paperwork, paperworkDossierState)
      yield put(messageModalActions.openSuccessMessageModal('DOSSIER_COMPONENTS.PAPERWORK_DOSSIER.PAPERWORK_COMPONENT.SEND_VEHICLE_DATA_SIMA_SUCCESS'))
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchPaperworkNotify () {
  yield takeEvery(dossierActionsTypes.PAPERWORK_NOTIFY, paperworkNotify)
}

export function * paperworkTransferCompleted (pristine) {
  try {
    if (!pristine.pristine) {
      const confirmedSaveWarning = yield call(yesNoModal, 'commonDossierSaveWarning')
      if (confirmedSaveWarning) {
        const auth = yield select(getAuth)
        const paperworkDossierState = yield select(state => state.paperworkDossier)
        const confirmed = yield call(yesNoModal, 'paperworkTransferCompleted')
        if (confirmed) {
          yield put(showLoader())
          yield call(putDossierTransferCompletedService, paperworkDossierState.dossierId, auth.token, paperworkDossierState.lastAccessDate)
          let paperwork
          const checkListHeader = yield call(getStageCheckList, paperworkDossierState.dossierId, auth.token)
          const headerAndButtons = yield call(getSaleHeaderAndButtons, auth.token, paperworkDossierState.dossierId)
          paperwork = { ...headerAndButtons }
          paperwork = { ...paperwork, checkListHeader }
          yield call(fetchPaperworkDossier, { dossierId: paperworkDossierState.dossierId, paperwork })
          const updatedPaperworkDossier = yield select(state => state.paperworkDossier)
          yield put(initialize('paperwork_dossier', updatedPaperworkDossier))
        }
      }
    } else {
      const auth = yield select(getAuth)
      const paperworkDossierState = yield select(state => state.paperworkDossier)
      const confirmed = yield call(yesNoModal, 'paperworkTransferCompleted')
      if (confirmed) {
        yield put(showLoader())
        yield call(putDossierTransferCompletedService, paperworkDossierState.dossierId, auth.token, paperworkDossierState.lastAccessDate)
        let paperwork
        const checkListHeader = yield call(getStageCheckList, paperworkDossierState.dossierId, auth.token)
        const headerAndButtons = yield call(getSaleHeaderAndButtons, auth.token, paperworkDossierState.dossierId)
        paperwork = { ...headerAndButtons }
        paperwork = { ...paperwork, checkListHeader }
        yield call(fetchPaperworkDossier, { dossierId: paperworkDossierState.dossierId, paperwork })
        const updatedPaperworkDossier = yield select(state => state.paperworkDossier)
        yield put(initialize('paperwork_dossier', updatedPaperworkDossier))
      }
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          const paperworkDossierState = yield select(state => state.paperworkDossier)
          yield call(initializeDossier, dossierTypesConstant.paperwork, paperworkDossierState)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('paperwork_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchPaperworkTransferCompleted () {
  yield takeEvery(dossierActionsTypes.PAPERWORK_TRANSFER_COMPLETED, paperworkTransferCompleted)
}
