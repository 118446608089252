import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import processRegisterFinder from '../../components/importExportFile/processRegisterFinder'

import { fetchRegisterOrder, fetchRegisterOrderDownload } from '../../actions/search/search'

import { translate } from 'react-polyglot'
export function mapStateToProps (state) {
  return {
    ...state.importExportFile,
    ...state.search.registerOrder
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({ fetchRegisterOrder, fetchRegisterOrderDownload }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(processRegisterFinder))
