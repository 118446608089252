import apiFetch from '../apiFetch'

export default function (token, filters) {
  let queryParamsArray = []

  if (filters) {
    for (let prop of Object.keys(filters)) {
      if (filters[prop] != null) { queryParamsArray.push(`${prop}=${filters[prop]}`) }
    }
  }

  const queryParams = queryParamsArray.reduce(
    (result, query, idx) => result + (query ? (result ? `&${query}` : `?${query}`) : '')
    , ''
  )
  return apiFetch({
    endPoint: `Fleets/CheckChangeEntityDossier${queryParams}`,
    method: 'GET',
    body: null,
    token: token
  })
}
