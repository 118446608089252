import React, { PureComponent } from 'react'
import { OverlayTrigger, Tooltip, Button } from 'react-bootstrap'
import TaskListPage_v2 from '../../containers/dashboard/taskList/TaskListPage'
import { styleTasks } from '../../../constants/backendIds'
import TrackingContainer from '../../../containers/tracking/trackingContainer'
import { CustomDimensionsConstant } from '../../../constants/tracking/CustomDimensionsConstant'
import { TrackingActions } from '../../../constants/tracking/TrackingActions'
import { TrackedPages } from '../../../constants/tracking/TrackedPages'
import settings from '../../../setting'
import MapSections from '../../util/map-sections_v2'
import SimpleInputText from '../../../components/commons/simpleForm/SimpleInputText'

class DashboardTasks extends PureComponent {
  constructor () {
    super()
    this.state = {
      isLoading: true,
      preselectTask: null,
      hideMenu: false,
      taskNameFilter: ''
    }
  }

  onSelectUrgencyTaskGroupTab (urgencyType) {
    this.props.actions.fetchFilteredDashboardTask(this.props.activeTaskDescription, urgencyType, this.props.activeTaskId)
  }

  onSelectTaskGroupItem (taskGroupKey, taskDescription) {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
    this.props.actions.fetchFilteredDashboardTask(taskDescription, this.props.urgencyType, taskGroupKey)
    this.props.trackAction(this.factoryDashBoardTask(taskGroupKey, taskDescription))
  }

  factoryDashBoardTask (activeTaskid, activeTaskDescription) {
    return {
      href: window.location.href,
      action: TrackingActions.LOAD_DASHBOADTASK,
      documentTitle: TrackedPages.DASHBOAD_TASK,
      category: TrackedPages.DASHBOAD_TASK,
      customDimensions: [
        {
          id: CustomDimensionsConstant.DASHBOARD_TASK_ID,
          value: activeTaskid || this.props.activeTaskId
        },
        {
          id: CustomDimensionsConstant.DASHBOARD_TASK_NAME,
          value: activeTaskDescription || this.props.activeTaskDescription
        }
      ]
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.organizedUnit && this.props.organizedUnit !== prevProps.organizedUnit) {
      if (this.props.activeTaskId) {
        this.props.actions.fetchFilteredDashboardTask(this.props.activeTaskDescription,
          this.props.urgencyType,
          this.props.activeTaskId)
      } else {
        this.props.actions.fetchDashboardTask()
      }
    }

    this.loadPreselectedTask(this.state.preselectTask, this.props.match.params.preselectTaskId)
  }

  componentDidMount () {
    this.props.trackVisitPage({
      documentTitle: TrackedPages.DASHBOAD_TASK,
      href: window.location.href
    })
    return new Promise((resolve, reject) => {
      this.reloadTaskDashboard(resolve, reject)
    }).then(() => {
      this.loadPreselectedTask(null, this.props.match.params.preselectTaskId)
      this.setState({ isLoading: false })
    })
  }

  loadPreselectedTask (prevPreselectedTaskId, preselectedId) {
    let taskDesc
    if (preselectedId && prevPreselectedTaskId !== preselectedId) {
      this.props.tasks.taskList.forEach((task) => {
        if (task.taskId === parseInt(preselectedId)) {
          taskDesc = task.taskDescription
        }
      })

      if (taskDesc) {
        this.onSelectTaskGroupItem(parseInt(preselectedId), taskDesc)
        this.setState({ preselectTask: preselectedId })
      }
    }
  }

  reloadTaskDashboard (resolve, reject) {
    if (settings.reloadTaskDashboardOnReturn || !this.props.activeTaskId) {
      this.props.actions.fetchDashboardTask(resolve, reject)
    }

    if (this.props.activeTaskId) {
      this.props.actions.fetchFilteredDashboardTask(
        this.props.activeTaskDescription,
        this.props.urgencyType,
        this.props.activeTaskId
      )
    }
  }

  applyStyleTask (task, activeTaskId) {
    switch (task.styleCss) {
      case styleTasks.Bold:
        if (task.taskId === activeTaskId) {
          return 'activebold active'
        } else {
          return 'activebold'
        }
      case styleTasks.BoldBlue:
        if (task.taskId === activeTaskId) {
          return 'activeboldblue active'
        } else {
          return 'activeboldblue'
        }
      case styleTasks.BoldRed:
        if (task.taskId === activeTaskId) {
          return 'activeboldred active'
        } else {
          return 'activeboldred'
        }
      default:
        if (task.taskId === activeTaskId) {
          return 'active'
        } else {
          return ''
        }
    }
  }

  markTask (item) {
    this.props.actions.switchTaskFavorite(item.taskId, item.isFavorite)
  }

  toogleInnerMenu () {
    this.setState({ hideMenu: !this.state.hideMenu })
  }

  filterSearchTasks () {
    let nameFilter = this.state.taskNameFilter
    if (!this.state.taskNameFilter) {
      return this.props.tasks.taskList
    }
    let filtered = this.props.tasks.taskList.filter(function (item) {
      return item.taskDescription.toUpperCase().includes(nameFilter.toUpperCase())
    })
    return filtered
  }

  render () {
    const {
      t,
      tasks,
      activeTaskId
    } = this.props
    const tKey = 'DASHBOARD_TASK.'
    const secMap = MapSections['dashboard-tasks']
    const taskTextTooltip = (taskTextTooltip) => (<Tooltip id="editTooltip">{taskTextTooltip}</Tooltip>)

    return (
      <div className="wrapper _nf">
        <div className='wrapper-header'>
          <div className="title-page">
            <i className={secMap.icon + ' icon-page'} />
            <h2>
              {t(`${secMap.title}`)}
              {tasks.taskList.length > 0 && settings.enableTaskFollowUp &&
                (<div className='folow'><p>{t(`${tKey}TASK_TRACKING`)} </p><i className="iDocIcon-visibility-eye"></i><span>({tasks.taskList.filter(item => item.isFavorite === true).length}/{tasks.maxFavoriteTasks})</span></div>)
              }
            </h2>
          </div>
          {tasks.taskList.length > 0 &&
            <div className='title-page-extra'>
              <Button className='_Btn accent' onClick={() => this.props.actions.fetchDashboardTask()} id="BUTTON_REFRESH_TASK_LIST">{t(tKey + 'REFRESH_TASK_LIST')}</Button>
            </div>}
        </div>

        {(!tasks.taskList.length) &&
            <div className='wrapper-body'>
              <div className='inner-menu-content'>
                <p className='taskNoFound'>{`${t(tKey + (this.state.isLoading ? 'SEARCHING_MESSAGE' : 'INFO_MESSAGE'))}`}</p>
              </div>
              <div className='inner-data-content'></div>
            </div>
        }
        {(tasks.taskList.length) &&
          <div className='wrapper-body'>
            <div className={this.state.hideMenu ? 'inner-menu-content innerMenuHide' : 'inner-menu-content'}>
              <div className='inner-menu-helpers'>
                <SimpleInputText
                  componentClass = 'cajadebusqueda'
                  placeholder = {t('SECTIONS.FILTER')}
                  value={this.state.taskNameFilter}
                  onInputChange={taskNameFilter => this.setState({ taskNameFilter })}
                />
              </div>
              <ul className='task-list'>
                {
                  this.filterSearchTasks(tasks.taskList).map((item, idx) => {
                    return (
                      <li className={this.applyStyleTask(item, activeTaskId)}>
                        <div>
                          {settings.enableTaskFollowUp &&
                      <i onClick={() => this.markTask(item)} className={item.isFavorite === true ? 'iDocIcon-visibility-eye follow' : 'iDocIcon-visibility-eye'}></i>
                          }
                          <OverlayTrigger key={idx} trigger={['hover', 'focus']} placement="right" overlay={taskTextTooltip(item.taskTextTooltip)}>
                            <span onClick={this.onSelectTaskGroupItem.bind(this, item.taskId, item.taskDescription)} key={idx}>
                              {item.taskDescription}
                            </span>
                          </OverlayTrigger>
                        </div>
                      </li>
                    )
                  })
                }
              </ul>
            </div>
            <div className='inner-data-content'>
              <TaskListPage_v2 history={this.props.history} toogleInnerMenu={() => this.toogleInnerMenu()} hideMenu={this.state.hideMenu}/>
            </div>

          </div>}
      </div>
    )
  }
}
var decoratedComponent = TrackingContainer(DashboardTasks, false)
export default decoratedComponent
