import { referenceTypes } from '../../constants/document/documentTypeUse'
import apiFetch from '../apiFetch'

export default function (organizedUnitId, documentTypeId, token, id, checklistId, dossierId, lastAccessDate, chargeId, referenceType, referenceId, formValues) {
  const queryParamsArray = [
    organizedUnitId ? `organizedUnitId=${organizedUnitId}` : 'organizedUnitId=null',
    documentTypeId ? `documentTypeId=${documentTypeId}` : 'documentTypeId=null',
    id ? `id=${id}` : null,
    checklistId ? `checklistId=${checklistId}` : null,
    dossierId ? `dossierId=${dossierId}` : null,
    chargeId ? `chargeId=${chargeId}` : null,
    referenceType ? `referenceType=${referenceType}` : referenceTypes.NONE,
    referenceId ? `referenceId=${referenceId}` : null
  ]

  const queryParams = queryParamsArray.reduce(
    (result, query, idx) => result + (query ? (result ? `&${query}` : `?${query}`) : '')
    , ''
  )

  return apiFetch({ endPoint: `document/fromtemplateformvalues${queryParams}`, method: 'POST', body: formValues, token: token, lastAccessDate })
}
