import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import ReFinanceModal from '../../../../components/dossierHistorical/sale/refinance/RefinanceModal'
import { fetchFinancingType } from '../../../../actions/combos/combos'
import { openModal as openCommonModal } from '../../../../actions/common'
import { getFinancingTypeProductByFinancingType } from '../../../../actions/masters/masters'
import { saveRefinancing, deleteRefinancing } from '../../../../actions/dossier/sales'
export function mapStateToProps (state) {
  return {
    combos: state.combos
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchFinancingType,
      openCommonModal,
      getFinancingTypeProductByFinancingType,
      saveRefinancing,
      deleteRefinancing
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(ReFinanceModal))
