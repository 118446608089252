import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getFormValues } from 'redux-form'
import OrderModal from '../../../../components/dossiers/common/vehicle/OrderModal'
import vehicleActions, { fetchOrderCatalogueModel } from '../../../../actions/dossier/common/vehicle'
import { translate } from 'react-polyglot'
import {
  fetchCarlineCombo, fetchCatalogueCombo, fetchModelCombo, fetchColorCombo, fetchTapestryCombo,
  resetCarlineCombo, resetCatalogueCombo, resetColorCombo, resetTapestryCombo
} from '../../../../actions/combos/combos'

export function mapStateToProps (state) {
  return {
    ...state.vehicle,
    combos: {
      brandCombo: state.combos.brandCombo,
      fuelCombo: state.combos.fuelCombo,
      entityTransferCombo: state.combos.entityTransferCombo,
      purchaseTypeVnCombo: state.combos.purchaseTypeVnCombo,
      carlineCombo: state.combos.carlineCombo,
      catalogueCombo: state.combos.catalogueCombo,
      modelCombo: state.combos.modelCombo,
      colorCombo: state.combos.colorCombo,
      tapestryCombo: state.combos.tapestryCombo
    },
    formValues: getFormValues('order')(state)
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      ...vehicleActions,
      fetchCarlineCombo,
      fetchCatalogueCombo,
      fetchModelCombo,
      fetchColorCombo,
      fetchTapestryCombo,
      fetchOrderCatalogueModel,
      resetCarlineCombo,
      resetCatalogueCombo,
      resetColorCombo,
      resetTapestryCombo
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(OrderModal))
