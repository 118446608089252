import apiFetch from '../../apiFetch'

export default function (token, dealerNumber, vinChassis, licensePlate, makerNumber, organizedUnitId) {
  const queryParamsArray = [
    `organizedUnitId=${organizedUnitId}`,
    dealerNumber ? `dealerNumber=${dealerNumber}` : null,
    vinChassis ? `vinChassis=${vinChassis}` : null,
    licensePlate ? `licensePlate=${licensePlate}` : null,
    makerNumber ? `makerNumber=${makerNumber}` : null
  ]
  const queryParams = queryParamsArray.reduce(
    (result, query, idx) => result + (query ? (result ? `&${query}` : `?${query}`) : '')
    , ''
  )

  return apiFetch({
    endPoint: `Dossier/Purchase/SearchFromStockDossier${queryParams}`,
    method: 'GET',
    token: token
  })
}
