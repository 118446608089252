import React, { PureComponent } from 'react'
import UserDataPage from '../../../containers/profile/UserDataPage'
import UserOrganizationPage from '../../../_v2/containers/profile/UserOrganizationPage'
import UserApplicationsPage from '../../../_v2/containers/profile/UserApplicationsPage'
import UserEventSubscriptionsPage from '../../../containers/profile/UserEventSubscriptionsPage'
import MapSectionsProfile from '../../../_v2/util/map-sections-profile'
import SimpleInputText from '../../../components/commons/simpleForm/SimpleInputText'
import { getSectionPermissions } from '../../../util/permisionFunctions'
import { sectionPermissions } from '../../../constants/dossier/common/buttonsHeader'
import { reduxForm } from 'redux-form'
import { validatePhone } from '../../../util/validationFunctions'
import validatePassword from '../../../components/access/validation/validatePassword'

class Profile extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      secMapProfile: MapSectionsProfile,
      activeTab: 1,
      hideMenu: false
    }
    this.onSelectTab = this.onSelectTab.bind(this)
  }

  initializePermisions (props) {
    let secMapProfile = this.state.secMapProfile

    secMapProfile[0].active = true
    this.setState({ secMapProfile: secMapProfile })

    const section = props.sections.filter((tabConf) => tabConf.code === sectionPermissions.dashboard)
    if (section.length > 0 && section[0] != null) {
      const newState = getSectionPermissions(section[0])
      this.setState({ sectionTabsConfiguration: newState })
    }
  }

  onSelectTab (tabKey) {
    this.setState({ activeTab: tabKey })
    switch (tabKey) {
      case 2:
        this.props.actions.fetchEventSubscriptions() // mover a sección de suscripciones
        break
      default:
        break
    }
  }

  componentDidMount () {
    this.props.actions.fetchUserProfile()
    this.initializePermisions(this.props)
  }

  selectSection (item) {
    let visibleSectionConfiguration = []
    this.state.secMapProfile.forEach(element => {
      element.active = element.code === item.code
      visibleSectionConfiguration.push(element)
    })

    this.props.actions.profileSectionChangeSuccess(visibleSectionConfiguration)
  }

  filterSections (t) {
    let nameFilter = this.state.sectionFilter
    let secMapProfile = this.props.secMapProfile ?? this.state.secMapProfile

    if (!this.props.enableSubscriptions || this.props.enableSubscriptions === false) {
      secMapProfile = secMapProfile.filter(x => x.code !== 'notifications')
    }

    if (!this.state.sectionFilter) {
      return secMapProfile
    }
    let filtered = secMapProfile.filter(function (item) {
      return t(item.title).toUpperCase().includes(nameFilter.toUpperCase())
    })
    return filtered
  }

  onSubmit (values) {
    console.log('values', values)
    return new Promise((resolve, reject) => {
      this.props.actions.updateUserProfile(values, this.props.t, resolve, reject)
    }).then(() => {
      this.setState({ phoneEditable: false })
    })
  }

  toogleInnerMenu () {
    this.setState({ hideMenu: !this.state.hideMenu })
  }

  render () {
    const tKey = 'PROFILE.'
    const { t, handleSubmit } = this.props
    const availableSections = this.filterSections(t)
    const selectedSection = availableSections.filter(x => x.active === true)[0]

    return (
      <div className="wrapper _nf profile">
        <div className='wrapper-header'>
          <div className="title-page">
            <i className={'iDocIcon-user-user-menu icon-page'} />
            <h2>{t(tKey + 'TITLE')}</h2>
          </div>
        </div>
        <div className='wrapper-body'>
          <div className={this.state.hideMenu ? 'inner-menu-content innerMenuHide' : 'inner-menu-content'}>
            <div className='inner-menu-helpers'>
              <SimpleInputText
                componentClass = 'cajadebusqueda'
                placeholder = {t('SECTIONS.FILTER')}
                value={this.state.sectionFilter}
                onInputChange={sectionFilter => this.setState({ sectionFilter })}
              />
            </div>
            <ul className='inner-items'>
              {
                availableSections.map((item, idx) => {
                  return (
                    <li className={ item.active === true ? 'active' : ''}><p onClick={() => this.selectSection(item)}>{t(`${item.title}`)}</p></li>
                  )
                })
              }
            </ul>
          </div>
          <div className='inner-data-content'>
            <form autoComplete="off" onSubmit={handleSubmit(this.onSubmit.bind(this))}>
              {selectedSection !== undefined && selectedSection.page === 'userDataPage' &&
                  <UserDataPage
                    sectionTabsConfiguration = {this.state.sectionTabsConfiguration}
                    toogleInnerMenu={() => this.toogleInnerMenu()}
                    hideMenu={this.state.hideMenu}
                  />
              }
              {selectedSection !== undefined && selectedSection.page === 'userOrganizationPage' &&
                  <UserOrganizationPage
                    sectionTabsConfiguration = {this.state.sectionTabsConfiguration}
                    toogleInnerMenu={() => this.toogleInnerMenu()}
                    hideMenu={this.state.hideMenu}
                  />
              }
              {selectedSection !== undefined && selectedSection.page === 'userApplicationsPage' &&
                  <UserApplicationsPage
                    sectionTabsConfiguration = {this.state.sectionTabsConfiguration}
                    toogleInnerMenu={() => this.toogleInnerMenu()}
                    hideMenu={this.state.hideMenu}
                  />
              }
              { selectedSection !== undefined && selectedSection.page === 'notificationsPage' &&
                  <UserEventSubscriptionsPage
                    toogleInnerMenu={() => this.toogleInnerMenu()}
                    hideMenu={this.state.hideMenu}
                  />
              }
            </form>
          </div>
        </div>
      </div>

    )
  }
}

export default reduxForm({
  form: 'updateProfile',
  validate: (values, props) => {
    const tKey = 'PROFILE.'
    let errors = {}
    if (values.newPassword || values.pwdLoginRepeat) {
      errors = validatePassword(values, props)
      if (!values.oldPassword) {
        errors.oldPassword = props.t(tKey + 'OLD_PASSWORD_REQUIRED')
      }
    }
    if (!values.languageId) {
      errors.languageId = props.t(tKey + 'LANGUAGE_REQUIRED')
    }
    if (values.phone && validatePhone(values, props) === false) {
      errors.phone = props.t(tKey + 'PHONE_NUMBER')
    }
    return errors
  }
})(Profile)
