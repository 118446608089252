import { put, call, select, takeEvery } from 'redux-saga/effects'
import { getAuth } from '../../selectors/access/auth'
import postDocumentUploadMass from '../../services/organizedUnit/postDocumentUploadMass'
// import postProcAgDocumentUploadMass from '../../services/processingAgency/postProcAgDocumentUploadMass'
import getTemplateOUFile from '../../services/organizedUnit/getTemplateOUFile'
import { showLoader, hideLoader } from '../../actions/common.js'
import masterActionTypes from '../../constants/actions/masters/masters'
import { handleError } from '../errors/errorManager'
import messageModalActions from '../../actions/modals/messageModal'
import { fetchUploadTest } from '../../actions/masters/masters'
import { reset } from 'redux-form'

export function * uploadUoMassDocument ({ file, code, callback, resolve, t }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    let modalState = yield select(state => state.modalMassUpload)
    let response = yield call(postDocumentUploadMass, code, auth.token, file)
    let confirmation = modalState.code && modalState.code === response.token
    let succes = response.totalRecords === response.correct
    let errors = []
    if (response.errors && response.errors.length > 0) {
      response.errors.map((er, i) => {
        let prcError = {}
        prcError.errorHeader = er.rowNumber ? er.rowNumber : er.uoCode
        prcError.errorMessage = er.errorMessage ? t('ERRORS.BACKEND_TAG_TRANSLATION.' + er.errorMessage)
          : er.columErrors && er.columErrors.length > 0 ? er.columErrors.map((ms, i) => { return t('ERRORS.BACKEND_TAG_TRANSLATION.' + ms) + '. ' }) : ''
        errors.push(prcError)
      })
    }
    yield put(fetchUploadTest(response.uosCreated, errors, succes, succes ? response.token : null, response.StorageConfigError))
    if (succes && confirmation && callback) yield call(callback)
    if (resolve) yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MASTERS.OU_MASTERS.FETCH_ERROR_MSG', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('DocumentMassUpload'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchUploadUoMassDocument () {
  yield takeEvery(masterActionTypes.UPLOAD_UO_MASS_DOCUMENT, uploadUoMassDocument)
}

export function * fetchTemplateFile ({ filename }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(getTemplateOUFile, auth.token, filename)
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchfetchTemplateFile () {
  yield takeEvery(masterActionTypes.FETCH_TEMPLATE_LOAD_FILE, fetchTemplateFile)
}
