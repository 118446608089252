import { call, put, select, takeLatest } from 'redux-saga/effects'
import { reset } from 'redux-form'
import { getAuth } from '../../selectors/access/auth'
import getFamily from '../../services/family/getFamily'
import commonActions from '../../actions/common'
import messageModalActions from '../../actions/modals/messageModal'
import { fecthFamilySucces } from '../../actions/combos/combos'
import combosActionTypes from '../../constants/actions/combos/combos'
import { handleError } from '../errors/errorManager'

export function * fetchFamily () {
  try {
    yield put(commonActions.showLoader())
    const auth = yield select(getAuth)
    const response = yield call(getFamily, auth.token)
    yield put(fecthFamilySucces(response))
  } catch (error) {
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    const customHandler = function * () {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.FETCH_COMBO_ERROR'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchFetchFamily () {
  yield takeLatest(combosActionTypes.FETCH_FAMILY_COMBO, fetchFamily)
}
