import apiFetch from '../../apiFetch'

export default function (token, uoId, salesmanId, urgencyTypeId, subTypeId, stageId, page, orderBy, pageSize = 10) {
  const queryParamsArray = [
    page ? `page=${page}` : null,
    pageSize ? `pageSize=${pageSize}` : null,
    uoId && uoId !== '0' ? `organizedUnit=${uoId}` : null,
    salesmanId && salesmanId !== '0' ? `salesman=${salesmanId}` : null,
    subTypeId && subTypeId !== '0' ? `dossierSubTypeId=${subTypeId}` : null,
    urgencyTypeId && urgencyTypeId !== '0' ? `urgencyTypeId=${urgencyTypeId}` : null,
    stageId && stageId !== '0' ? `dossierStageId=${stageId}` : null,
    orderBy ? `orderBy=${orderBy}` : null
  ]
  const queryParams = queryParamsArray.reduce(
    (result, query, idx) => result + (query ? (result ? `&${query}` : `?${query}`) : '')
    , ''
  )
  return apiFetch({ endPoint: `dossier/dashboardCompleteTable${queryParams}`, method: 'GET', body: null, token: token })
}
