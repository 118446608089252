
import React, { PureComponent } from 'react'
import { reduxForm, Field } from 'redux-form'
import { Modal, Button, Form } from 'react-bootstrap'
import InputText from '../../../components/commons/form/InputText'

class ZipModal extends PureComponent {
  constructor () {
    super()
    this.state = {
    }
  }

  componentDidMount () {}

  componentDidUpdate (prevProps, prevState) {
  }

  cancel () {
    this.props.actions.closeZipModal()
  }

  save (values) {
    new Promise((resolve, reject) => {
      this.props.actions.createMassiveDownload(this.props.zipModal?.number, values.name, values.observations, resolve)
    }).then((result) => {
      this.props.actions.closeZipModal()
    })
  }

  render () {
    const {
      t, showModal, handleSubmit
    } = this.props
    const tKey = 'MANAGEMENT.SEARCH_DOCUMENTAL.MODAL.'
    return (
      <>
        <Modal className="_nf ZipModal" show={showModal} onHide={() => this.cancel()} backdrop={'static'} >
          <Modal.Header closeButton>
            <Modal.Title>{t(`${tKey + 'TITLE'}`)}</Modal.Title>
          </Modal.Header>
          <Form autoComplete="off" onSubmit={handleSubmit(this.save.bind(this))}>
            <Modal.Body>
              <div className='search-form'>
                <Field
                  component={InputText}
                  colWidht={100} // 25, 33, 50, 75, 100 default=25
                  id="name"
                  name="name"
                  controlLabel={t(`${tKey}NAME`)}
                  placeholder={t(`${tKey}NAME`)}
                />
                <Field
                  component={InputText}
                  colWidht={100} // 25, 33, 50, 75, 100 default=25
                  id="observations"
                  name="observations"
                  colSm={12}
                  componentClass="textarea"
                  controlLabel={t(`${tKey}OBSERVATIONS`)}
                  placeholder={t(`${tKey}OBSERVATIONS`)}
                  maxLength={512}
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <div className='flexInLine al-R'>
                <Button
                  className="_Btn accept"
                  type="submit">
                  {t(`${tKey}ACEPT`)}
                </Button>
                <Button
                  id="role-modal-submit-button"
                  className="_Btn cancel"
                  onClick = { () => { this.cancel() }}>
                  {t(`${tKey}CANCEL`)}
                </Button>
              </div>
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    )
  }
}

export default reduxForm({
  form: 'ZipModal',
  shouldValidate: () => true,
  validate: (values, props) => {
    const errors = {}
    if (!values.name || values.name === undefined) {
      errors.name = props.t('DOSSIER_COMPONENTS.VALIDATIONS.MANDATORY_FIELD')
      return errors
    }
  }
})(ZipModal)
