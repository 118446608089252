import { call, put, select, takeLatest } from 'redux-saga/effects'
import { getAuth } from '../../selectors/access/auth'
import unattendedProcessTypes from '../../constants/actions/unattendedProcess/unattendedProcess'
import { handleError } from '../errors/errorManager'
import commonActions, { openModal } from '../../actions/common.js'
import { updateChargeDossiersModal } from '../../actions/management/auxModals/auxModals'
import postDossierFleetDataChangeProcess from '../../services/unattendedProcess/postDossierFleetDataChangeProcess'
import postActionExpFleetProcess from '../../services/unattendedProcess/postActionExpFleetProcess'
import postApproveActionFleetProcess from '../../services/unattendedProcess/postApproveActionFleetProcess'
import { fetchUnattendedProcessTypeComboSuccess, fetchUnattendedProcessViewComboSuccess } from '../../actions/combos/combos'
import combosActionTypes from '../../constants/actions/combos/combos'
import getUnattendedProcessTypes from '../../services/unattendedProcess/getUnattendedProcessTypes'
import postSearchUnattendedProcess from '../../services/unattendedProcess/postSearchUnattendedProcess'
import { findUnattendedProcessSuccess, findDossierNumbersFromIdSuccess } from '../../actions/unattendedProcess/unattendedProcess'
import exportUnattendedProcess from '../../services/unattendedProcess/exportUnattendedProcess'
import postGetDossierNumbersFromIds from '../../services/unattendedProcess/postGetDossierNumbersFromIds'
import postUploadDiferentDocumentProcess from '../../services/unattendedProcess/postUploadDiferentDocumentProcess'
import postEconomicPlanProcess from '../../services/unattendedProcess/postEconomicPlanProcess'
import postSetOperateActionSendToApprovalProcess from '../../services/unattendedProcess/postSetOperateActionSendToApprovalProcess'
import postSetOperateActionSendToAgencyProcess from '../../services/unattendedProcess/postSetOperateActionSendToAgencyProcess'
import postFleetChargeDossiersProcess from '../../services/unattendedProcess/postFleetChargeDossiersProcess'
import postValidateFleetChargeDossiersProcess from '../../services/unattendedProcess/postValidateFleetChargeDossiersProcess'
import { yesNoModal } from '../modalsListeners/yesNoModal'
import postSetOperateDataSaleFleet from '../../services/unattendedProcess/postSetOperateDataSaleFleet'
import postSetOperateDataContactFleet from '../../services/unattendedProcess/postSetOperateDataContactFleet'
import { replaceableFields } from '../../constants/dossier/common/replaceableFields'
import postSetOperateDatapaperWorkFleet from '../../services/unattendedProcess/postSetOperateDataPaperWorkFleet'
import postSetOperateDataFinanceFleet from '../../services/unattendedProcess/postSetOperateDataFinanceFleet'
import postSetOperateDataChargeFleet from '../../services/unattendedProcess/postSetOperateDataChargeFleet'
import postSetOperateActionApproveProcess from '../../services/unattendedProcess/postSetOperateActionApproveProcess'
import postSetOperateActionRejectProcess from '../../services/unattendedProcess/postSetOperateActionRejectProcess'
import postSetOperateActionApprovePaperworkProcess from '../../services/unattendedProcess/postSetOperateActionApprovePaperworkProcess'
import postSetOperateActionDeleteProcess from '../../services/unattendedProcess/postSetOperateActionDeleteProcess'
import postIncorporateDocumentFleetProcess from '../../services/unattendedProcess/postIncorporateDocumentFleetProcess'
import postValidateDocumentFleetProcess from '../../services/unattendedProcess/postValidateDocumentFleetProcess'
import postReEditDocumentFleetProccess from '../../services/unattendedProcess/postReEditDocumentFleetProccess'
import postCanceDocumentFleetProcess from '../../services/unattendedProcess/postCanceDocumentFleetProcess'
import postSetOperateActionStoreProcess from '../../services/unattendedProcess/postSetOperateActionStoreProcess'
import postRequestSignDocumentFleetProcess from '../../services/unattendedProcess/postRequestSignDocumentFleetProcess'
import postSetOperateDataDeliveryFleet from '../../services/unattendedProcess/postSetOperateDataDeliveryFleet'
import postSetOperateDataServiceFleet from '../../services/unattendedProcess/postSetOperateDataServiceFleet'
import postSetDeleteFleetProcess from '../../services/unattendedProcess/postSetDeleteFleetProcess'
import postSetOperateActionPrintDeliveryNoteProcess from '../../services/unattendedProcess/postSetOperateActionPrintDeliveryNoteProcess'
import getOperateActionPrintDeliveryNote from '../../services/unattendedProcess/getOperateActionPrintDeliveryNote'
import postCancelSignDocumentFleetProcess from '../../services/unattendedProcess/postCancelSignDocumentFleetProcess'
import postRejectDocumentFleetProcess from '../../services/unattendedProcess/postRejectDocumentFleetProcess'
import getUnattendedProcessView from '../../services/unattendedProcess/getUnattendedProcessView'
import postUpdateDocumentFleetChargeProcess from '../../services/unattendedProcess/postUpdateDocumentFleetChargeProcess.js'
import postRecoverDocumentFleetChargeProcess from '../../services/unattendedProcess/postRecoverDocumentFleetChargeProcess'

export function * setDossierFleetDataChangeProcess ({ dossierList, saleType, operationTypes, paymentMethod, actionOK }) {
  try {
    yield put(commonActions.showLoader())
    const auth = yield select(getAuth)
    yield call(postDossierFleetDataChangeProcess, auth.token, { dossierList, saleType, operationTypes, paymentMethod })
    yield put(openModal('unattendedProcessCreated', actionOK))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchsetDossierFleetDataChangeProcess () {
  yield takeLatest(unattendedProcessTypes.SET_DOSSIER_FLEET_DATA_CHANGE_PROCESS, setDossierFleetDataChangeProcess)
}

export function * setActionExpFleetProcess ({ dossierList, mode, fleetId, actionOK }) {
  try {
    yield put(commonActions.showLoader())
    const auth = yield select(getAuth)
    yield call(postActionExpFleetProcess, auth.token, { dossierList, mode, fleetId })
    yield put(openModal('unattendedProcessCreated', actionOK))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchSetActionExpFleetProcess () {
  yield takeLatest(unattendedProcessTypes.SET_ACTION_EXP_FLET_PROCESS, setActionExpFleetProcess)
}

export function * setApproveActionFleetProcess ({ dossierList, mode, roleId, actionOK }) {
  try {
    yield put(commonActions.showLoader())
    const auth = yield select(getAuth)
    yield call(postApproveActionFleetProcess, auth.token, { dossierList, mode, roleId })
    yield put(openModal('unattendedProcessCreated', actionOK))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchSetApproveActionFleetProcess () {
  yield takeLatest(unattendedProcessTypes.SET_APPROVE_FLEET_ACTION, setApproveActionFleetProcess)
}

export function * fetchUnattendedProcessTypes () {
  try {
    yield put(commonActions.showLoader())
    const auth = yield select(getAuth)
    const unattendedProcessTypes = yield call(getUnattendedProcessTypes, auth.token)
    yield put(fetchUnattendedProcessTypeComboSuccess({ unattendedProcessTypes }))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchgFetchUnattendedProcessTypes () {
  yield takeLatest(combosActionTypes.FETCH_UNATTENDED_PROCESS_TYPES, fetchUnattendedProcessTypes)
}

export function * fetchUnattendedProcessView ({ resolve, reject }) {
  try {
    yield put(commonActions.showLoader())
    const auth = yield select(getAuth)
    const unattendedProcessView = yield call(getUnattendedProcessView, auth.token)
    unattendedProcessView.forEach(element => {
      if (!isNaN(element.id)) {
        element.id = parseInt(element.id)
      }
    })
    yield put(fetchUnattendedProcessViewComboSuccess({ unattendedProcessView }))
    if (resolve) yield call(resolve)
  } catch (error) {
    yield call(handleError, { error })
    if (reject) yield call(reject)
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchFetchUnattendedProcessView () {
  yield takeLatest(combosActionTypes.FETCH_UNATTENDED_PROCESS_VIEW, fetchUnattendedProcessView)
}

export function * findUnattendedProcess ({ filter }) {
  try {
    yield put(commonActions.showLoader())
    const auth = yield select(getAuth)
    const data = yield call(postSearchUnattendedProcess, auth.token, filter)
    yield put(findUnattendedProcessSuccess(data, filter))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchgFindUnattendedProcess () {
  yield takeLatest(unattendedProcessTypes.FIND_UNATTENDED_PROCESS, findUnattendedProcess)
}

export function * fetchUnattendedProcess ({ filter }) {
  try {
    yield put(commonActions.showLoader())
    const auth = yield select(getAuth)
    yield call(exportUnattendedProcess, auth.token, filter)
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchFetchUnattendedProcess () {
  yield takeLatest(unattendedProcessTypes.EXPORT_UNATTENDED_PROCESS_SUCCESS, fetchUnattendedProcess)
}

export function * fetchDossierNumbersFromIds ({ dossiersIdList, dossierSubTypes }) {
  try {
    yield put(commonActions.showLoader())
    const auth = yield select(getAuth)
    var dossierNumbers = yield call(postGetDossierNumbersFromIds, auth.token, dossiersIdList, dossierSubTypes)
    yield put(findDossierNumbersFromIdSuccess(dossierNumbers))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchFetchDossierNumbersFromIds () {
  yield takeLatest(unattendedProcessTypes.FIND_DOSSIER_NUMBERS_UNATTENDED_PROCESS, fetchDossierNumbersFromIds)
}

export function * createDifferentDocumentFleetProcess ({ file, dossiers, documentTypeUseId, organizedUnitId, validate, actionOK }) {
  try {
    yield put(commonActions.showLoader())
    const auth = yield select(getAuth)
    yield call(postUploadDiferentDocumentProcess, auth.token, file, dossiers, documentTypeUseId, organizedUnitId, validate)
    yield put(openModal('unattendedProcessCreated', actionOK))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchCreateDifferentDocumentFleetProcess () {
  yield takeLatest(unattendedProcessTypes.SET_UPLOAD_ZIP_DOCUMENT_FLEET_PROCESS, createDifferentDocumentFleetProcess)
}

export function * setEconomicPlanFleetProcess ({ dossierList, actionOK }) {
  try {
    let confirmed = yield call(yesNoModal, 'saveEconomicFleetAlert')

    if (confirmed) {
      yield put(commonActions.showLoader())
      const auth = yield select(getAuth)
      let stateDossier = yield select(state => state.management.economicPlanModal)
      let stateCampaigns = JSON.parse(JSON.stringify(stateDossier.campaigns))
      let dossierForm = yield select(state => state.form.economicPlanModal.values.dossierEconomicComponent)
      let body = { economicPlan: dossierForm, listCampaignDossierIncome: stateCampaigns, dossierList: dossierList }
      yield call(postEconomicPlanProcess, auth.token, body)
      yield put(openModal('unattendedProcessCreated', actionOK))
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchSetEconomicPlanFleetProcess () {
  yield takeLatest(unattendedProcessTypes.SET_ECONOMIC_PLAN_FLEET_PROCESS, setEconomicPlanFleetProcess)
}

export function * setOperateDataSaleFleetProcess ({ dossierList, componentConfiguration, saleComponent, vehicleTypeId, fleetId, actionOK }) {
  try {
    let confirmed = true
    if (dossierList.length > 1) {
      confirmed = yield call(yesNoModal, 'saveSetOperateDataSaleFleet', null, null, null, null, [{ id: replaceableFields.numDossiers, value: dossierList.length }])
    }

    if (confirmed) {
      yield put(commonActions.showLoader())
      const auth = yield select(getAuth)

      let body = { componentConfiguration: componentConfiguration, saleComponent: saleComponent, dossierList: dossierList, vehicleTypeId: vehicleTypeId }
      yield call(postSetOperateDataSaleFleet, auth.token, body, fleetId)
      yield put(openModal('unattendedProcessCreated', actionOK))
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchsetOperateDataSaleFleetProcess () {
  yield takeLatest(unattendedProcessTypes.SET_OPERATE_DATA_SALE_FLEET_PROCESS, setOperateDataSaleFleetProcess)
}

export function * setOperateDataContactFleetProcess ({ data, fleetId, organizedUnitId, actionOK }) {
  try {
    let confirmed = true
    if (data.dossierList.length > 1) {
      confirmed = yield call(yesNoModal, 'saveSetOperateDataContactFleet', null, null, null, null, [{ id: replaceableFields.numDossiers, value: data.dossierList.length }])
    }

    if (confirmed) {
      yield put(commonActions.showLoader())
      const auth = yield select(getAuth)

      yield call(postSetOperateDataContactFleet, auth.token, data, { fleetId, organizedUnitId })
      yield put(openModal('unattendedProcessCreated', actionOK))
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchSetOperateDataContactFleetProcess () {
  yield takeLatest(unattendedProcessTypes.SET_OPERATE_DATA_CONTACT_FLEET_PROCESS, setOperateDataContactFleetProcess)
}

export function * setOperateDataPaperWorkFleetProcess ({ data, fleetId, actionOK }) {
  try {
    let confirmed = true
    if (data.dossierList.length > 1) {
      if (data.dossierPaperworkComponent.externalPaperwork) {
        confirmed = yield call(yesNoModal, 'saveSetOperateDataPaperWorkDeleteFleet', null, null, null, null, [{ id: replaceableFields.numDossiers, value: data.dossierList.length }])
      } else {
        confirmed = yield call(yesNoModal, 'saveSetOperateDataPaperWorkFleet', null, null, null, null, [{ id: replaceableFields.numDossiers, value: data.dossierList.length }])
      }
    }

    if (confirmed) {
      yield put(commonActions.showLoader())
      const auth = yield select(getAuth)

      yield call(postSetOperateDatapaperWorkFleet, auth.token, data, { fleetId })
      yield put(openModal('unattendedProcessCreated', actionOK))
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchsetOperateDataPaperWorkFleetProcess () {
  yield takeLatest(unattendedProcessTypes.SET_OPERATE_DATA_PAPERWORK_FLEET_PROCESS, setOperateDataPaperWorkFleetProcess)
}

export function * setOperateDataFinancingFleetProcess ({ dossierList, dossierFinancingComponent, fleetId, actionOK }) {
  try {
    let confirmed = true
    if (dossierList.length > 1) {
      confirmed = yield call(yesNoModal, 'saveSetOperateDataFinancingFleet', null, null, null, null, [{ id: '##NUMDOSSIERS##', value: dossierList.length }])
    }

    if (confirmed) {
      yield put(commonActions.showLoader())
      const auth = yield select(getAuth)

      let body = { dossierFinancingComponent: dossierFinancingComponent, dossierList: dossierList }
      yield call(postSetOperateDataFinanceFleet, auth.token, body, fleetId)
      yield put(openModal('unattendedProcessCreated', actionOK))
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchsetOperateDataFinancingFleetProcess () {
  yield takeLatest(unattendedProcessTypes.SET_OPERATE_DATA_FINANCING_FLEET_PROCESS, setOperateDataFinancingFleetProcess)
}

export function * setOperateDataChargeFleetProcess ({ documentId, organizedUnitId, dossierList, fields, comment, fleetId, actionOK }) {
  try {
    let entityAmount = fields.find(x => x.name === 'Importe')
    let amountCalculated = parseFloat(entityAmount.entityValue)
    entityAmount.entityValue = amountCalculated.toFixed(2).toString()
    let confirmed = true
    if (dossierList.length > 1) {
      confirmed = yield call(yesNoModal, 'saveSetOperateDataChargeFleet', null, null, null, null, [{ id: replaceableFields.numDossiers, value: dossierList.length }, { id: replaceableFields.amount, value: parseFloat(entityAmount.entityValue) }])
    }

    if (confirmed) {
      yield put(commonActions.showLoader())
      const auth = yield select(getAuth)

      let body = { documentId: documentId, fields: fields, comment: comment, dossierList: dossierList, organizedUnitId: organizedUnitId }
      yield call(postSetOperateDataChargeFleet, auth.token, body, fleetId)
      yield put(openModal('unattendedProcessCreated', actionOK))
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchsetOperateDataChargeFleetProcess () {
  yield takeLatest(unattendedProcessTypes.SET_OPERATE_DATA_CHARGE_FLEET_PROCESS, setOperateDataChargeFleetProcess)
}

export function * setOperateActionSendToApprovalProcess ({ dossiers, fleetId, actionOK }) {
  try {
    yield put(commonActions.showLoader())
    const auth = yield select(getAuth)
    let body = { fleetId, dossierIds: dossiers.map(x => x.dossierId) }
    yield call(postSetOperateActionSendToApprovalProcess, auth.token, body)
    yield put(openModal('unattendedProcessCreated', actionOK))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchSetOperateActionSendToApprovalProcess () {
  yield takeLatest(unattendedProcessTypes.SET_OPERATE_ACTION_SEND_TO_APPROVAL_PROCESS, setOperateActionSendToApprovalProcess)
}

export function * setOperateActionApproveProcess ({ dossiers, fleetId, actionOK }) {
  try {
    yield put(commonActions.showLoader())
    const auth = yield select(getAuth)
    let body = { fleetId, dossierIds: dossiers.map(x => x.dossierId) }
    yield call(postSetOperateActionApproveProcess, auth.token, body)
    yield put(openModal('unattendedProcessCreated', actionOK))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchSetOperateActionApproveProcess () {
  yield takeLatest(unattendedProcessTypes.SET_OPERATE_ACTION_APPROVE_PROCESS, setOperateActionApproveProcess)
}

export function * setOperateActionRejectProcess ({ rejectReasonDto, dossiers, fleetId, actionOK, fleetChargeId = null }) {
  try {
    yield put(commonActions.showLoader())
    const auth = yield select(getAuth)
    let body = { fleetId, dossierIds: dossiers.map(x => x.dossierId), rejectReasonDto, fleetChargeId }
    yield call(postSetOperateActionRejectProcess, auth.token, body)
    yield put(openModal('unattendedProcessCreated', actionOK))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchSetOperateActionRejectProcess () {
  yield takeLatest(unattendedProcessTypes.SET_OPERATE_ACTION_REJECT_PROCESS, setOperateActionRejectProcess)
}

export function * setOperateActionApprovePaperworkProcess ({ dossiers, fleetId, actionOK }) {
  try {
    yield put(commonActions.showLoader())
    const auth = yield select(getAuth)
    let body = { fleetId, dossierIds: dossiers.map(x => x.dossierId) }
    yield call(postSetOperateActionApprovePaperworkProcess, auth.token, body)
    yield put(openModal('unattendedProcessCreated', actionOK))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchSetOperateActionApprovePaperworkProcess () {
  yield takeLatest(unattendedProcessTypes.SET_OPERATE_ACTION_APPROVE_PAPERWORK_PROCESS, setOperateActionApprovePaperworkProcess)
}

export function * setOperateActionSendToAgencyProcess ({ dossiers, fleetId, actionOK }) {
  try {
    yield put(commonActions.showLoader())
    const auth = yield select(getAuth)
    let body = { fleetId: fleetId, dossierIds: dossiers.map(x => x.dossierId) }
    yield call(postSetOperateActionSendToAgencyProcess, auth.token, body)
    yield put(openModal('unattendedProcessCreated', actionOK))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchSetOperateActionSendToAgencyProcess () {
  yield takeLatest(unattendedProcessTypes.SET_OPERATE_ACTION_SEND_TO_AGENCY_PROCESS, setOperateActionSendToAgencyProcess)
}

export function * setOperateActionDeleteProcess ({ comment, dossiers, fleetId, actionOK }) {
  try {
    yield put(commonActions.showLoader())
    const auth = yield select(getAuth)
    let body = { fleetId, dossierIds: dossiers.map(x => x.dossierId), rejectReasonDto: comment }
    yield call(postSetOperateActionDeleteProcess, auth.token, body)
    yield put(openModal('unattendedProcessCreated', actionOK))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchSetOperateActionDeleteProcess () {
  yield takeLatest(unattendedProcessTypes.SET_OPERATE_ACTION_DELETE_PROCESS, setOperateActionDeleteProcess)
}

export function * createIncorporateDocumentFleetProcess ({ file, documentTypeUseId, infoIncome, fleetDocumentDetails, fleetId, actionOK }) {
  try {
    let confirmed = true
    if (fleetDocumentDetails.length > 1) {
      confirmed = yield call(yesNoModal, 'saveSetDocumentFleetProcess', null, null, null, null, [{ id: '##NUMDOSSIERS##', value: fleetDocumentDetails.length }])
    }

    if (confirmed) {
      yield put(commonActions.showLoader())
      const auth = yield select(getAuth)
      yield call(postIncorporateDocumentFleetProcess, auth.token, file, infoIncome, fleetDocumentDetails, documentTypeUseId, fleetId)
      yield put(openModal('unattendedProcessCreated', actionOK))
    } else {
      yield call(actionOK)
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchCreateIncorporateDocumentFleetProcess () {
  yield takeLatest(unattendedProcessTypes.SET_INCORPORATE_DOCUMENT_FLEET_PROCESS, createIncorporateDocumentFleetProcess)
}

export function * createValidateDocumentFleetProcess ({ fields, comment, isRecurrent, fleetDocumentDetails, fleetId, actionOK }) {
  try {
    let confirmed = true
    if (fleetDocumentDetails.length > 1) {
      confirmed = yield call(yesNoModal, 'saveSetDocumentFleetProcess', null, null, null, null, [{ id: '##NUMDOSSIERS##', value: fleetDocumentDetails.length }])
    }

    if (confirmed) {
      yield put(commonActions.showLoader())

      const infoIncome = {
        documentTypeId: null,
        objectId: null,
        fields,
        comment,
        isRecurrent,
        fleetChargeId: fleetDocumentDetails.length > 0 ? fleetDocumentDetails[0].fleetChargeId : null
      }

      const auth = yield select(getAuth)

      let body = { infoIncome, fleetDocumentDetails }

      yield call(postValidateDocumentFleetProcess, auth.token, body, fleetId)
      yield put(openModal('unattendedProcessCreated', actionOK))
    } else {
      yield call(actionOK)
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchCreateValidateDocumentFleetProcess () {
  yield takeLatest(unattendedProcessTypes.SET_VALIDATE_DOCUMENT_FLEET_PROCESS, createValidateDocumentFleetProcess)
}

export function * createReEditDocumentFleetProcess ({ fleetDocumentDetails, fleetId, actionOK }) {
  try {
    let confirmed = true
    if (fleetDocumentDetails.length > 1) {
      confirmed = yield call(yesNoModal, 'saveSetDocumentFleetProcess', null, null, null, null, [{ id: '##NUMDOSSIERS##', value: fleetDocumentDetails.length }])
    }

    if (confirmed) {
      yield put(commonActions.showLoader())

      const auth = yield select(getAuth)

      yield call(postReEditDocumentFleetProccess, auth.token, fleetDocumentDetails, fleetId)
      yield put(openModal('unattendedProcessCreated', actionOK))
    } else {
      yield call(actionOK)
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchCreateReEditDocumentFleetProcess () {
  yield takeLatest(unattendedProcessTypes.SET_REEDIT_DOCUMENT_FLEET_PROCESS, createReEditDocumentFleetProcess)
}

export function * createCancelDocumentFleetProcess ({ fleetDocumentDetails, fleetId, actionOK }) {
  try {
    let confirmed = true
    if (fleetDocumentDetails.length > 1) {
      confirmed = yield call(yesNoModal, 'saveSetDocumentFleetProcess', null, null, null, null, [{ id: '##NUMDOSSIERS##', value: fleetDocumentDetails.length }])
    }

    if (confirmed) {
      yield put(commonActions.showLoader())

      const auth = yield select(getAuth)

      yield call(postCanceDocumentFleetProcess, auth.token, fleetDocumentDetails, fleetId)
      yield put(openModal('unattendedProcessCreated', actionOK))
    } else {
      yield call(actionOK)
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchCreateCancelDocumentFleetProcess () {
  yield takeLatest(unattendedProcessTypes.SET_CANCEL_DOCUMENT_FLEET_PROCESS, createCancelDocumentFleetProcess)
}

export function * setOperateActionStoreProcess ({ dossiers, fleetId, actionOK }) {
  try {
    yield put(commonActions.showLoader())
    const auth = yield select(getAuth)
    let body = { fleetId, dossierIds: dossiers.map(x => x.dossierId) }
    yield call(postSetOperateActionStoreProcess, auth.token, body)
    yield put(openModal('unattendedProcessCreated', actionOK))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchSetOperateActionStoreProcess () {
  yield takeLatest(unattendedProcessTypes.SET_OPERATE_ACTION_STORE_PROCESS, setOperateActionStoreProcess)
}

export function * setFleetChargeDossiersProcess ({ dossierValues, fleetId, fleetCode, dossierSubType, file, actionOK, t }) {
  try {
    yield put(commonActions.showLoader())
    const auth = yield select(getAuth)
    let validation = yield call(postValidateFleetChargeDossiersProcess, auth.token, file, dossierValues, fleetId, dossierSubType)
    yield put(commonActions.hideLoader())
    let errors = []
    if (validation.errors && validation.errors.length < 1) {
      let confirmed = yield call(yesNoModal, 'saveFleetsDossiersAlert', null, null, null, null, [{ id: replaceableFields.fleetCode, value: fleetCode }, { id: replaceableFields.numDossiers, value: validation.totalRecords }])
      if (confirmed) {
        yield put(commonActions.showLoader())
        const auth = yield select(getAuth)
        let response = yield call(postFleetChargeDossiersProcess, auth.token, file, dossierValues, fleetId, dossierSubType)
        if (response.errors && response.errors.length > 0) {
          response.errors.map((er, i) => {
            let excelErrors = {}
            excelErrors.errorHeader = er.rowNumber
            excelErrors.errorMessage = er.columErrors && er.columErrors.length > 0 ? er.columErrors.map((ms, i) => { return t('ERRORS.BACKEND_TAG_TRANSLATION.' + ms) + '. ' }) : ''
            errors.push(excelErrors)
          })
        } else {
          yield put(openModal('unattendedProcessCreated', actionOK))
        }
      }
    } else {
      validation.errors.map((er, i) => {
        let excelErrors = {}
        excelErrors.errorHeader = er.rowNumber
        excelErrors.errorMessage = er.columErrors && er.columErrors.length > 0 ? er.columErrors.map((ms, i) => { return t('ERRORS.BACKEND_TAG_TRANSLATION.' + ms) + '. ' }) : ''
        errors.push(excelErrors)
      })
    }
    yield put(updateChargeDossiersModal(errors))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchSetFleetChargeDossiersProcess () {
  yield takeLatest(unattendedProcessTypes.SET_FLEET_CHARGE_DOSSIERS_PROCESS, setFleetChargeDossiersProcess)
}

export function * createRequestSignDocumentFleetProcess ({ fleetDocumentDetails, fleetId, signType, actionOK }) {
  try {
    let confirmed = true
    if (fleetDocumentDetails.length > 1) {
      confirmed = yield call(yesNoModal, 'saveSetDocumentFleetProcess', null, null, null, null, [{ id: '##NUMDOSSIERS##', value: fleetDocumentDetails.length }])
    }

    if (confirmed) {
      yield put(commonActions.showLoader())

      const auth = yield select(getAuth)

      yield call(postRequestSignDocumentFleetProcess, auth.token, fleetDocumentDetails, fleetId, signType)
      yield put(openModal('unattendedProcessCreated', actionOK))
    } else {
      yield call(actionOK)
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchCreateRequestSignDocumentFleetProcess () {
  yield takeLatest(unattendedProcessTypes.SET_REQUEST_SIGN_DOCUMENT_FLEET_PROCESS, createRequestSignDocumentFleetProcess)
}

export function * setOperateDataDeliveryFleetProcess ({ data, fleetId, actionOK }) {
  try {
    let confirmed = true
    if (data.dossierList.length > 1) {
      confirmed = yield call(yesNoModal, 'saveSetOperateDataDeliveryFleet', null, null, null, null, [{ id: replaceableFields.numDossiers, value: data.dossierList.length }])
    }

    if (confirmed) {
      yield put(commonActions.showLoader())
      const auth = yield select(getAuth)

      yield call(postSetOperateDataDeliveryFleet, auth.token, data, { fleetId })
      yield put(openModal('unattendedProcessCreated', actionOK))
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchsetOperateDataDeliveryFleetProcess () {
  yield takeLatest(unattendedProcessTypes.SET_OPERATE_DATA_DELIVERY_FLEET_PROCESS, setOperateDataDeliveryFleetProcess)
}

export function * setOperateDataServiceFleetProcess ({ data, fleetId, actionOK }) {
  try {
    let confirmed = true
    if (data.dossierList.length > 1) {
      confirmed = yield call(yesNoModal, 'saveSetOperateDataServiceFleet', null, null, null, null, [{ id: replaceableFields.numDossiers, value: data.dossierList.length }])
    }

    if (confirmed) {
      yield put(commonActions.showLoader())
      const auth = yield select(getAuth)

      yield call(postSetOperateDataServiceFleet, auth.token, data, { fleetId })
      yield put(openModal('unattendedProcessCreated', actionOK))
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchsetOperateDataServiceFleetProcess () {
  yield takeLatest(unattendedProcessTypes.SET_OPERATE_DATA_SERVICE_FLEET_PROCESS, setOperateDataServiceFleetProcess)
}

export function * setDeleteFleetProcess ({ fleetId, deleteDossiers }) {
  try {
    yield put(commonActions.showLoader())
    const auth = yield select(getAuth)
    yield call(postSetDeleteFleetProcess, auth.token, fleetId, deleteDossiers)
    yield put(openModal('unattendedProcessCreated', () => true))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchSetDeleteFleetProcess () {
  yield takeLatest(unattendedProcessTypes.SET_DELETE_FLEET_PROCESS, setDeleteFleetProcess)
}

export function * setOperateActionPrintDeliveryNoteProcess ({ dossiers, fleetId, actionOK }) {
  try {
    yield put(commonActions.showLoader())
    const auth = yield select(getAuth)
    let body = { fleetId, dossierIds: dossiers.map(x => x.dossierId) }
    yield call(postSetOperateActionPrintDeliveryNoteProcess, auth.token, body)
    yield put(openModal('unattendedProcessCreated', actionOK))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchSetOperateActionPrintDeliveryNoteProcess () {
  yield takeLatest(unattendedProcessTypes.SET_OPERATE_ACTION_PRINT_DELIVERY_NOTE_PROCESS, setOperateActionPrintDeliveryNoteProcess)
}

export function * unattendedProcessDownloadDeliveryNotes ({ unattendedProcessId }) {
  try {
    yield put(commonActions.showLoader())
    const auth = yield select(getAuth)
    yield call(getOperateActionPrintDeliveryNote, auth.token, unattendedProcessId)
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchUnattendedProcessDownloadDeliveryNotes () {
  yield takeLatest(unattendedProcessTypes.UNATTENDED_PROCESS_DOWNLOAD_DELIVERY_NOTES, unattendedProcessDownloadDeliveryNotes)
}

export function * createCancelSignDocumentFleetProcess ({ fleetDocumentDetails, fleetId, reason, actionOK }) {
  try {
    let confirmed = true
    if (fleetDocumentDetails.length > 1) {
      confirmed = yield call(yesNoModal, 'saveSetDocumentFleetProcess', null, null, null, null, [{ id: '##NUMDOSSIERS##', value: fleetDocumentDetails.length }])
    }

    if (confirmed) {
      yield put(commonActions.showLoader())

      const auth = yield select(getAuth)

      yield call(postCancelSignDocumentFleetProcess, auth.token, fleetDocumentDetails, fleetId, reason)
      yield put(openModal('unattendedProcessCreated', actionOK))
    } else {
      yield call(actionOK)
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchCreateCancelSignDocumentFleetProcess () {
  yield takeLatest(unattendedProcessTypes.SET_CANCEL_SIGN_DOCUMENT_FLEET_PROCESS, createCancelSignDocumentFleetProcess)
}

export function * createRejectDocumentFleetProcess ({ fleetDocumentDetails, fleetId, reason, actionOK }) {
  try {
    let confirmed = true
    if (fleetDocumentDetails.length > 1) {
      confirmed = yield call(yesNoModal, 'saveSetDocumentFleetProcess', null, null, null, null, [{ id: '##NUMDOSSIERS##', value: fleetDocumentDetails.length }])
    }

    if (confirmed) {
      yield put(commonActions.showLoader())

      const auth = yield select(getAuth)

      yield call(postRejectDocumentFleetProcess, auth.token, fleetDocumentDetails, fleetId, reason)
      yield put(openModal('unattendedProcessCreated', actionOK))
    } else {
      yield call(actionOK)
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchCreateRejectDocumentFleetProcess () {
  yield takeLatest(unattendedProcessTypes.SET_REJECT_DOCUMENT_FLEET_PROCESS, createRejectDocumentFleetProcess)
}

export function * createUpdateDocumentFleetChargeProcess ({ fields, comment, isRecurrent, fleetDocumentDetails, fleetId, actionOK }) {
  try {
    yield put(commonActions.showLoader())

    const infoIncome = {
      documentTypeId: null,
      objectId: null,
      fields,
      comment,
      isRecurrent,
      fleetChargeId: fleetDocumentDetails.length > 0 ? fleetDocumentDetails[0].fleetChargeId : null
    }

    const auth = yield select(getAuth)

    let body = { infoIncome, fleetDocumentDetails }

    yield call(postUpdateDocumentFleetChargeProcess, auth.token, body, fleetId)
    yield put(openModal('unattendedProcessCreated', actionOK))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchCreateUpdateDocumentFleetChargeProcess () {
  yield takeLatest(unattendedProcessTypes.SET_UPDATE_CHARGE_DOSSIERS_PROCESS, createUpdateDocumentFleetChargeProcess)
}

export function * createRecoverDocumentFleetChargeProcess ({ fleetId, fleetChargeId, actionOK }) {
  try {
    yield put(commonActions.showLoader())

    const auth = yield select(getAuth)

    yield call(postRecoverDocumentFleetChargeProcess, auth.token, fleetId, fleetChargeId)
    yield put(openModal('unattendedProcessCreated', actionOK))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchCreateRecoverDocumentFleetChargeProcess () {
  yield takeLatest(unattendedProcessTypes.SET_RECOVER_CHARGE_DOSSIERS_PROCESS, createRecoverDocumentFleetChargeProcess)
}
