import React, { PureComponent } from 'react'
import { Row, Button, Modal, FormGroup, Col } from 'react-bootstrap'
import { reduxForm, Field } from 'redux-form'
import InputMoney from '../../commons/form/InputMoney'
import InputPercentage from '../../commons/form/InputPercentage'
import InputSelect from '../../commons/form/InputSelect'

class CampaingModalCountryDetail extends PureComponent {
  constructor () {
    super()
    this.state = {
      amountCampaign: null,
      percentageCampaign: null,
      amountBrand: null,
      percentageBrand: null,
      countryId: null,
      errors: {}
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.detail !== this.props.detail || prevProps.modalImportShow !== this.props.modalImportShow) {
      this.props.initialize(this.props.detail)
      this.setState({
        amountCampaign: this.props.detail.amountCampaign != null ? this.props.detail.amountCampaign : null,
        percentageCampaign: this.props.detail.percentageCampaign != null ? this.props.detail.percentageCampaign : null,
        amountBrand: this.props.detail.amountBrand != null ? this.props.detail.amountBrand : null,
        percentageBrand: this.props.detail.percentageBrand != null ? this.props.detail.percentageBrand : null,
        countryId: this.props.detail.countryId ? this.props.detail.countryId : null,
        errors: {}
      })
    }
  }

  componentDidMount () {
    this.props.actions.fetchCountries()
  }

  handleAmount (event, field) {
    switch (field) {
      case 'amountCampaign':
        this.setState({ amountCampaign: event.target.value !== '' ? event.target.value : 0 })
        break
      case 'percentageCampaign':
        this.setState({ percentageCampaign: event.target.value !== '' ? event.target.value : 0 })
        break
      case 'amountBrand':
        this.setState({ amountBrand: event.target.value !== '' ? event.target.value : 0 })
        break
      case 'percentageBrand':
        this.setState({ percentageBrand: event.target.value !== '' ? event.target.value : 0 })
        break
      default:
        break
    }
  }

  handleCountry (event) {
    this.setState({ countryId: event })
  }

  blurCountry (value) {
  }

  save (values) {
    const body = {
      ...values
    }
    this.props.actions.setCountryCampaignDetail(body, body.campaignDetailsId)
    this.props.actions.close()
  }

  render () {
    const {
      t,
      modalImportShow,
      editable,
      countries,
      handleSubmit
    } = this.props
    const tKey = 'MASTERS.CAMPAIGN_DETAILS_MASTERS.MODAL_COUNTRY_DETAILS.'

    return (
      <Modal className="role-modal languages-modal" show={modalImportShow} onHide={this.props.actions.close}>
        <form autoComplete="off" onSubmit={handleSubmit(this.save.bind(this))}>
          <Modal.Header closeButton onHide={this.props.actions.close}>
            <Modal.Title>{t(`${tKey}TITLE`)}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <Row className="inputs-wrapper">
                <Col sm={6} className="custom-width-col">
                  <h2>{t(`${tKey}COUNTRY`)}</h2>
                  <Field
                    name="countryId"
                    placeholder={t(`${tKey}COUNTRY`)}
                    valueKey="countryId"
                    labelKey="nameCalculated"
                    colSm={0}
                    component={InputSelect}
                    options={countries}
                    disabled={editable}
                    onBlur = {(value) => this.blurCountry(value)}
                    onChange ={(value) => this.handleCountry(value)}
                  />
                </Col>
                <div className="clearfix" />
                <Col sm={6} className="custom-width-col">
                  <h2>{t(`${tKey}CAMPAIGN_AMOUNT`)}</h2>
                  <Field
                    name = "amountCampaign"
                    placeholder={t(`${tKey}CAMPAIGN_AMOUNT`)}
                    component={InputMoney}
                    onChange = {(value) => this.handleAmount(value, 'amountCampaign')}
                    disabled={this.state.amountCampaign === null}
                  />
                  <span style={{ color: 'red' }}>{this.state.errors.amountCampaign}</span>
                </Col>
                <Col sm={6} className="custom-width-col">
                  <h2>{t(`${tKey}CAMPAIGN_PERCENTAGE`)}</h2>
                  <Field
                    id = "percentageCampaign"
                    name="percentageCampaign"
                    placeholder={t(`${tKey}CAMPAIGN_PERCENTAGE`)}
                    component={InputPercentage}
                    onChange = {(value) => this.handleAmount(value, 'percentageCampaign')}
                    disabled={this.state.percentageCampaign === null}
                  />
                </Col>
                <div className="clearfix" />
                <Col sm={6} className="custom-width-col">
                  <h2>{t(`${tKey}BRAND_APORT`)}</h2>
                  <Field
                    name="amountBrand"
                    placeholder={t(`${tKey}BRAND_APORT`)}
                    component={InputMoney}
                    onChange = {(value) => this.handleAmount(value, 'amountBrand')}
                    disabled={this.state.amountBrand === null}
                  />
                </Col>
                <Col sm={6} className="custom-width-col">
                  <h2>{t(`${tKey}BRAND_PERCENTAGE`)}</h2>
                  <Field
                    name="percentageBrand"
                    placeholder={t(`${tKey}BRAND_PERCENTAGE`)}
                    component={InputPercentage}
                    onChange = {(value) => this.handleAmount(value, 'percentageBrand') }
                    disabled={this.state.percentageBrand === null}
                  />
                </Col>
              </Row>
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <Col sm={6}>
              <Button
                className="btn-standard"
                disabled= {this.props.pristine}
                type="submit">
                {t(`${tKey}OK`)}
              </Button>
            </Col>
            <Col sm={6} className="btn-wrapper">
              <Button
                id="role-modal-submit-button"
                className="btn-standard"
                onClick = {() => this.props.actions.close()}>
                {t(`${tKey}CLOSE`)}
              </Button>
            </Col>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default reduxForm({
  form: 'CampaingModalCountryDetail',
  validate: (values, props) => {
    const tKey = 'MASTERS.CAMPAIGN_DETAILS_MASTERS.MODAL_COUNTRY_DETAILS.VALIDATIONS.'
    let error = {}
    if (values) {
      if (!values.countryId) {
        error.countryId = props.t(`${tKey}COUNTRY`)
      }
      if (values.percentageCampaign) {
        if (values.percentageCampaign < 0 || values.percentageCampaign > 100) {
          error.percentageCampaign = props.t(`${tKey}PERCENTAGE_CAMPAIGN_VALUE`)
        }
        if (values.percentageBrand < 0 || values.percentageBrand > 100) {
          error.percentageBrand = props.t(`${tKey}PERCENTAGE_BRAND_VALUE`)
        }
        if (values.percentageBrand > values.percentageCampaign) {
          error.percentageBrand = props.t(`${tKey}PERCENTAGE_BIGGER_THAN_CAMPAIGN`)
        }
      } else {
        if (values.amountCampaign < 0) {
          error.amountCampaign = props.t(`${tKey}AMOUNT_CAMPAIGN_ZERO`)
        }
        if (values.amountBrand < 0) {
          error.amountBrand = props.t(`${tKey}AMOUNT_BRAND_ZERO`)
        }
        if (values.amountBrand > values.amountCampaign) {
          error.amountBrand = props.t(`${tKey}AMOUNT_BIGGER_THAN_CAMPAIGN`)
        }
      }
      return Object.keys(error).length > 0 ? error : {}
    }
  }
})(CampaingModalCountryDetail)
