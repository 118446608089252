import chargeQuiterActions from '../../../constants/actions/dossier/common/chargeQuiter'
import { chargeQuiterModalTypes } from '../../../constants/dossier/common/chargeQuiter'
export function initialState () {
  return {
    dossierId: null,
    dealerNumber: null,
    showQuiterModal: false,
    modalType: null,
    vehicleData: null,
    sendResult: null,
    saleType: null
  }
}

function openChargeModalDealerSuccess (state, { dealerNumber, dossierId, saleType }) {
  return {
    ...state,
    dealerNumber: dealerNumber,
    dossierId: dossierId,
    modalType: (dealerNumber === null || dealerNumber === undefined || dealerNumber === '') ? chargeQuiterModalTypes.getDealerNumber : chargeQuiterModalTypes.showDealerNumber,
    showQuiterModal: true,
    saleType: saleType
  }
}

function closeChargeModalDealer (state) {
  return {
    ...state,
    dossierId: null,
    dealerNumber: null,
    showQuiterModal: false,
    modalType: null,
    vehicleData: null,
    sendResult: null
  }
}

function getVehicleDataQuiterSuccess (state, { vehicleData }) {
  return {
    ...state,
    showQuiterModal: true,
    modalType: chargeQuiterModalTypes.showVehicleInfo,
    vehicleData: vehicleData
  }
}

export function sendVehicleDataQuiterSuccess (state, { result }) {
  return {
    ...state,
    showQuiterModal: true,
    modalType: chargeQuiterModalTypes.showResultInfo,
    sendResult: result,
    dealerNumber: null
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case chargeQuiterActions.OPEN_CHARGE_QUITER_MODAL_DEALER_SUCCESS:
      return openChargeModalDealerSuccess(state, action)
    case chargeQuiterActions.CLOSE_CHARGE_QUITER_MODAL_DEALER:
      return closeChargeModalDealer(state, action)
    case chargeQuiterActions.GET_VEHICLE_DATA_QUITER_SUCCESS:
      return getVehicleDataQuiterSuccess(state, action)
    case chargeQuiterActions.SEND_VEHICLE_DATA_QUITER_SUCCESS:
      return sendVehicleDataQuiterSuccess(state, action)
    default:
      return state
  }
}
