
import React, { PureComponent } from 'react'
import { reduxForm } from 'redux-form'
import { Row, Col, Table, Button, Tooltip, OverlayTrigger } from 'react-bootstrap'
import IDocCarPagination from '../../../commons/iDocCarPagination'
import ProcessingAgencyIntegrationServiceDestinationsMasterModalPage from '../../../../containers/masters/ProcessingAgency/processingAgencyIntegrations/ProcessingAgencyIntegrationServiceDestinationsMasterModalPage'

class ProcessingAgencyIntegrationServiceDestinationsMaster extends PureComponent {
  constructor () {
    super()
    this.state = {
      initialized: false,
      filter: {
      }
    }
  }

  getSortIcon (column = '') {
    switch (this.props.filter.orderBy) {
      case '+' + column:
        return 'ico-sort-selected-asc'
      case '-' + column:
        return 'ico-sort-selected-desc'
      default:
        return 'ico-sort'
    }
  }

  componentDidMount () {
    this.props.actions.fetchProcessingAgencyServiceDestinationsList(this.props.agency.processingAgencyId, { ...this.state.filter, ...this.props.filter })
    this.props.actions.fetchProcessingAgencyServiceDestinationsCombo()
  }

  changeOrder (field) {
    switch (this.props.filter.orderBy) {
      case `+${field}`:
        this.props.actions.fetchProcessingAgencyServiceDestinationsList(this.props.agency.processingAgencyId, { ...this.state.filter, ...this.props.filter, orderBy: `-${field}` })
        break
      default:
        this.props.actions.fetchProcessingAgencyServiceDestinationsList(this.props.agency.processingAgencyId, { ...this.state.filter, ...this.props.filter, orderBy: `+${field}` })
        break
    }
  }

  render () {
    const {
      t, processingAgencyServiceDestinations, pagesCount, filter, processingAgencyServiceDestinationsCount,
      filter: { page },
      actions: { showProcessingAgencyServiceDestinationsModal }
    } = this.props
    const tKey = 'MASTERS.PROCESSING_AGENCY_MASTERS.SERVICE_DESTINATIONS.'
    const editTooltip = (<Tooltip id="editTooltip">{t(`${tKey}EDIT`)}</Tooltip>)
    const deleteTooltip = (<Tooltip id="deleteTooltip">{t(`${tKey}DELETE`)}</Tooltip>)
    return (
      <Col className="admin-wrapper masters-process-agency-padding">
        <ProcessingAgencyIntegrationServiceDestinationsMasterModalPage />
        <Row className="section-users-header">
          <Col sm={12}>
            <div className="header-panel">
              <h4>{t(`${tKey}SERVICE_DESTINATION`)}</h4>
            </div>
          </Col>
        </Row>
        <Table className="editable-table" hover responsive>
          <thead>
            <tr>
              <th onClick={() => { this.changeOrder('serviceDestinationLocale') }}>
                {t(`${tKey}SERVICE_DESTINATION`)}
                <i className={this.getSortIcon('serviceDestinationLocale')} />
              </th>
              <th onClick={() => { this.changeOrder('destinationUseValue') }}>
                {t(`${tKey}PROCESSING_AGENCY_CODE`)}
                <i className={this.getSortIcon('destinationUseValue')} />
              </th>
              <th style={{ width: '77px' }}>{t('DOSSIER_COMPONENTS.ACCESSORIES.ACTIONS')}</th>
            </tr>
          </thead>
          <tbody>
            {processingAgencyServiceDestinations && processingAgencyServiceDestinations.map((processingAgencyServiceDestination, idx) => {
              return (
                <tr key={idx} className={processingAgencyServiceDestination.isDeleted ? 'is-deleted' : ''}>
                  <td>{processingAgencyServiceDestination.serviceDestinationLocale}</td>
                  <td>{processingAgencyServiceDestination.destinationUseValue}</td>
                  <td>
                    {!processingAgencyServiceDestination.isDeleted && (
                      <div>
                        <a onClick={() => showProcessingAgencyServiceDestinationsModal(this.props.agency.processingAgencyId, true, processingAgencyServiceDestination)}>
                          <OverlayTrigger placement="left" overlay={editTooltip}>
                            <i className="ico-edit-white" />
                          </OverlayTrigger>
                        </a>
                        <a onClick={() => this.props.actions.deleteProcessingAgencyServiceDestinations(this.props.agency.processingAgencyId, processingAgencyServiceDestination.processingAgencyServiceDestinationId)}>
                          <OverlayTrigger placement="left" overlay={deleteTooltip}>
                            <i className="ico-trash" />
                          </OverlayTrigger>
                        </a>
                      </div>
                    )}
                  </td>
                </tr>)
            })
            }
            {(processingAgencyServiceDestinations === null || processingAgencyServiceDestinations === undefined || processingAgencyServiceDestinations.length === 0) &&
          <tr>
            <td colSpan={10}><h2>{t(`${tKey}RESULTS_NOT_FOUND`)}</h2></td>
          </tr>}
            <tr className="button-table-wrapper">
              <td>
                <Button
                  className="btn-editable-table btn-standard"
                  onClick={() => showProcessingAgencyServiceDestinationsModal(this.props.agency.processingAgencyId, true)}
                >
                  {t(`${tKey}ADD`)}
                </Button>
              </td>
            </tr>
          </tbody>
        </Table>
        <div className="section-users-footer">
          <nav key={1} className="text-center">
            <IDocCarPagination
              id="btn_pag_sales"
              page={page}
              pagesCount={pagesCount}
              totalRows={processingAgencyServiceDestinationsCount}
              onSelectAction={(value) => this.props.actions.fetchProcessingAgencyServiceDestinationsList(this.props.agency.processingAgencyId, { ...filter, ...this.state.filter, page: value })}
            />
          </nav>
        </div>
      </Col>
    )
  }
}

export default reduxForm({
  form: 'editProcessingAgencyIntegrationServiceDestinationsMaster',
  validate: (values, props) => {
  }
})(ProcessingAgencyIntegrationServiceDestinationsMaster)
