import { call, select, takeEvery } from 'redux-saga/effects'
import setting from '../../setting'
import CryptoJS from 'crypto-js'
import usersManualActionTypes from '../../constants/actions/profile/usersManual'
import { getAuth } from '../../selectors/access/auth'
import { handleError } from '../errors/errorManager'

export function * getUserManualUrl ({ resolve, reject }) {
  const auth = yield select(getAuth)

  try {
    const currentDateTime = new Date()
    const year = currentDateTime.getFullYear()
    const month = (currentDateTime.getMonth() + 1).toString().padStart(2, '0')
    const day = currentDateTime.getDate().toString().padStart(2, '0')
    const hours = currentDateTime.getHours().toString().padStart(2, '0')
    const minutes = currentDateTime.getMinutes().toString().padStart(2, '0')
    const seconds = currentDateTime.getSeconds().toString().padStart(2, '0')

    const stringToCipher = `${year}${month}${day}${hours}${minutes}${seconds}_${auth.token}`
    // console.log('stringToCipher', stringToCipher)

    const secretKey = 'ciphKey'
    const iv = 'dsuiklgy457fdgk3'

    // Convert key to SHA-256 hash
    const hashedKey = CryptoJS.SHA256(secretKey).toString(CryptoJS.enc.Hex)

    // Take the first 32 bytes of the hash to get a 32-byte key
    const key = hashedKey.substring(0, 32)

    const keyHex = CryptoJS.enc.Utf8.parse(key)
    const ivHex = CryptoJS.enc.Utf8.parse(iv)

    const unencoded = CryptoJS.AES.encrypt(stringToCipher, keyHex, { iv: ivHex, mode: CryptoJS.mode.CBC })
    const encrypted = encodeURIComponent(unencoded).toString()
    const url = `${setting.usersManualUrl}?data=${encrypted}&origin=${setting.usersManualEnvironment}`
    yield call(resolve, url)
  } catch (error) {
    yield call(reject)
    yield call(handleError, { error })
  }
}

export function * watchGetUserManualUrl () {
  yield takeEvery(usersManualActionTypes.GET_USERS_MANUAL_URL, getUserManualUrl)
}
