import { call, put, select, takeLatest } from 'redux-saga/effects'
import { getAuth } from '../../selectors/access/auth'
import getSaleTypes from '../../services/saleType/getSalesTypes'
import getOperationTypeSalesTypes from '../../services/operationType/getOperationTypeSalesTypes'
import { showLoader, hideLoader } from '../../actions/common.js'
import messageModalActions from '../../actions/modals/messageModal'
import { fetchSaleTypeSuccess } from '../../actions/combos/combos'
import combosActionTypes from '../../constants/actions/combos/combos'
import { handleError } from '../errors/errorManager'

export function * fetchSaleType ({ ouId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const response = yield call(getSaleTypes, ouId, auth.token)
    yield put(fetchSaleTypeSuccess(response))
  } catch (error) {
    const customHandler = function * () {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.FETCH_COMBO_ERROR'))
    }
    yield call(handleError, { error, customHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchSaleType () {
  yield takeLatest(combosActionTypes.FETCH_SALE_TYPE, fetchSaleType)
}

export function * fetchSaleTypeByOperationTypeId ({ ouId, operationTypeId, dossierSubtypeId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const response = yield call(getOperationTypeSalesTypes, auth.token, operationTypeId, ouId, dossierSubtypeId)
    yield put(fetchSaleTypeSuccess(response))
  } catch (error) {
    const customHandler = function * () {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.FETCH_COMBO_ERROR'))
    }
    yield call(handleError, { error, customHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchSaleTypeByOperationTypeId () {
  yield takeLatest(combosActionTypes.FETCH_SALE_TYPE_BY_OPERATION_TYPE, fetchSaleTypeByOperationTypeId)
}
