
import apiFetch from '../apiFetch'

export default function (values, token) {
  const body = {
    isDeleted: false,
    paymentMethodLanguage: values
  }
  return apiFetch({
    endPoint: 'PaymentMethod/Create',
    method: 'POST',
    body: body,
    token: token
  })
}
