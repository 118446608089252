import { put, call, takeEvery, select } from 'redux-saga/effects'
import { initialize, reset } from 'redux-form'
import dossierActionsTypes from '../../../constants/actions/dossier/dossier'
import { getAuth } from '../../../selectors/access/auth'
import { showLoader, hideLoader } from '../../../actions/common'
import messageModalActions from '../../../actions/modals/messageModal'
import { fetchSalesDossierSuccess } from '../../../actions/dossier/sales'
import { handleError } from '../../errors/errorManager'
import { yesNoModal } from '../../modalsListeners/yesNoModal'
import saveRefinance from '../../../services/dossierSale/saveRefinance'
import deleteRefinanceComponent from '../../../services/dossierSale/deleteRefinanceComponent'
import { fetchFinancingType } from '../../../actions/combos/combos'

export function * saveReFinance ({ dossierFinancingComponent, saleId }) {
  let auth, salesDossierState
  try {
    auth = yield select(getAuth)
    salesDossierState = yield select(state => state.salesDossier)

    yield put(showLoader())
    var dossierReFinancingComponent = yield call(saveRefinance, dossierFinancingComponent, saleId, auth.token)

    dossierReFinancingComponent.updated = true

    yield put(initialize('salesDossier', { ...salesDossierState, dossierReFinancingComponent: dossierReFinancingComponent }))
    yield put(fetchFinancingType(dossierReFinancingComponent.companyId, 'refinance'))

    yield put(fetchSalesDossierSuccess({ dossierReFinancingComponent: dossierReFinancingComponent, lastAccessDate: Date.now() }))
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchsaveReFinance () {
  yield takeEvery(dossierActionsTypes.SAVE_REFINANCING, saveReFinance)
}

export function * deleteReFinancing ({ saleId, resolve }) {
  try {
    const confirmed = yield call(yesNoModal, 'deleteReFinancing')
    var salesDossierState = yield select(state => state.salesDossier)
    if (confirmed) {
      yield put(showLoader())
      const auth = yield select(getAuth)
      var dossierReFinancingComponent = yield call(deleteRefinanceComponent, saleId, auth.token)

      yield put(initialize('salesDossier', { ...salesDossierState, dossierReFinancingComponent: dossierReFinancingComponent }))
      yield put(fetchSalesDossierSuccess({ dossierReFinancingComponent: dossierReFinancingComponent, lastAccessDate: Date.now() }))

      if (resolve) resolve(true)
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchdeleteReFinancing () {
  yield takeEvery(dossierActionsTypes.DELETE_REFINANCING, deleteReFinancing)
}
