import apiFetch from '../apiFetch'

export default function (token, finderParams) {
  return apiFetch({
    endPoint: 'DossierFinder/VehiclesDownload',
    method: 'POST',
    body: finderParams,
    token: token,
    parseBlobAndAssign: true
  })
}
