import React, { PureComponent } from 'react'
import { Panel, Table, Button, Tooltip/*, Col, OverlayTrigger, Glyphicon */ } from 'react-bootstrap'
import CampaignsRow from '../../../../_v2/components/dossiers/sales/campaings/CampaignsRow'
import SmallLoaderPage from '../../../../containers/commons/SmallLoaderPage'
import { getFieldsPermisions } from '../../../../util/permisionFunctions'
import { permissions } from '../../../../constants/backendIds'
import { compareObjects } from '../../../../util/utils'
import { dossierStagesBySubtype } from '../../../../constants/dossier/common/dossierStages'
import CustomFields from '../../../../components/commons/customFields/CustomFields'
import InputCheckBox from '../../../../_v2/components/commons/form/InputCheckBox'
import { Field } from 'redux-form'
import ComponentTitle from '../../../../_v2/containers/dossiers/commons/ComponentTitlePage'

class Campaigns extends PureComponent {
  constructor () {
    super()
    this.state = {
      open: false,
      fieldsConfiguration: {
        downloadButton: null,
        addCampaignButton: null,
        campaignsBlocked: null
      },
      canAdd: true
    }
  }

  initializePermisions (props) {
    const fieldKeys = Object.keys(this.state.fieldsConfiguration)
    const newState = getFieldsPermisions(props.componentConfiguration.fieldsConfiguration, fieldKeys, props.componentConfiguration.permission)
    this.setState({ ...this.state, ...newState })
  }

  UNSAFE_componentWillMount () {
    this.initializePermisions(this.props)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.stageId >= dossierStagesBySubtype.saleVn.delivery || nextProps.stageId >= dossierStagesBySubtype.saleVo.delivery) {
      this.state.canAdd = false
    }
    if (nextProps.open) {
      this.setState({ open: true })
      // this.initializePermisions(nextProps)
    }
    if (nextProps.dossier && nextProps.dossier.dossierId && !compareObjects(this.props.componentConfiguration || {}, nextProps.componentConfiguration, true)) {
      this.initializePermisions(nextProps)
    }
  }

  toggleComponent () {
    if (this.state.open) {
      this.props.actions.setActiveSection(false)
    }
    this.setState({
      open: !this.state.open
    })
  }

  render () {
    const { t, readOnlyFields, campaigns, customFields, actions: { openSearchDocumentsModal, openCampaignDocument, openCampaignEdition, deleteCampaign, fetchCampaignEdition } } = this.props
    const tKey = 'DOSSIER_COMPONENTS.CAMPAIGNS.'
    const blockTooltip = (<Tooltip id="blockTooltip">{t(`${tKey}CAMPAIGNS_BLOCKED_INFO`)}</Tooltip>)
    return (
      <div className="dossier-Component">
        <div className="header-panel" onClick={() => this.toggleComponent()}>
          <ComponentTitle
            componentCode="CAMPAIGNS"
            componentOpen={this.state.open}
          />
        </div>
        <Panel expanded={this.state.open} onToggle={() => {}}>
          <Panel.Collapse>
            <div className="fieldsContainer">
              {this.state.fieldsConfiguration.downloadButton.permission !== permissions.hidden && (
                <div className='flexInLine al-R'>
                  <Button
                    className="_Btn accept stk"
                    id="generate-zip-button"
                    onClick={() => openSearchDocumentsModal(openCampaignDocument)}
                  >
                    <i className="iDocIcon-assignment-returned-self-consumption"></i>
                    {t(`${tKey}ZIP_BTN`)}
                  </Button>
                </div>
              )}
              {this.state.fieldsConfiguration.campaignsBlocked && this.state.fieldsConfiguration.campaignsBlocked.permission !== permissions.hidden && (
                <Field
                  component={InputCheckBox}
                  colWidht={100} // 25, 33, 50, 75, 100 default=25
                  id="campaignsBlocked"
                  name="salesComponent.campaignsBlocked"
                  controlLabel={t(`${tKey}CAMPAIGNS_BLOCKED`)}
                  placeholder={t(`${tKey}CAMPAIGNS_BLOCKED`)}
                  disabled={readOnlyFields || this.state.fieldsConfiguration.campaignsBlocked.permission !== permissions.editable}
                  customClass="checkbox-inline-camp"
                  tooltip={ blockTooltip }
                />

              )}
              <div className='wrapper _nf'>
                <Table className="editable-table" hover responsive>
                  <thead>
                    <tr>
                      <th>{t(`${tKey}EXP_NUMBER`)}</th>
                      <th>{t(`${tKey}NAME`)}</th>
                      <th>{t(`${tKey}TYPE`)}</th>
                      <th>{t(`${tKey}AMOUNT`)}</th>
                      <th>{t(`${tKey}COST`)}</th>
                      <th>{t(`${tKey}COST_CONCESSIONAIRE`)}</th>
                      <th>{t(`${tKey}STATUS`)}</th>
                      <th>{t(`${tKey}DOCUMENTS`)}</th>
                      <th className='actions'>{t('DOSSIER_COMPONENTS.ACCESSORIES.ACTIONS')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      campaigns.map((campaign, idx) => {
                        return (
                          <CampaignsRow
                            t={t}
                            tKey={tKey}
                            campaign={campaign}
                            key={idx}
                            openCampaignEdition={openCampaignEdition}
                            fetchCampaignEdition={fetchCampaignEdition}
                            deleteCampaign={deleteCampaign}
                            readOnlyFields={readOnlyFields}
                          />
                        )
                      })
                    }
                  </tbody>
                  <div className='flexInLine al-L'>
                    {!readOnlyFields && this.state.canAdd &&
                      <Button
                        className="_Btn new"
                        id="add-button"
                        onClick={() => fetchCampaignEdition(null, openCampaignEdition)}
                        disabled={this.state.fieldsConfiguration.addCampaignButton && this.state.fieldsConfiguration.addCampaignButton.permission !== permissions.editable}
                      >
                        <i className="iDocIcon-add-plus"></i>
                        {t(`${tKey}ADD_BTN`)}
                      </Button>
                    }
                  </div>
                </Table>
              </div>
            </div>
            <CustomFields customFields={customFields} readOnlyFields={readOnlyFields} />
          </Panel.Collapse>
        </Panel>
        <SmallLoaderPage/>
      </div>
    )
  }
}
export default Campaigns
