export default {
  CLOSE_EDIT_REPAIRSHOP_MODAL: 'CLOSE_EDIT_REPAIRSHOP_MODAL',
  DELETE_REPAIRSHOP: 'DELETE_REPAIRSHOP',
  FETCH_REPAIRSHOP_LIST: 'FETCH_REPAIRSHOP_LIST',
  FETCH_REPAIRSHOP_LIST_SUCCESS: 'FETCH_REPAIRSHOP_LIST_SUCCESS',
  OPEN_ADD_REPAIRSHOP_MODAL_SUCCESS: 'OPEN_ADD_REPAIRSHOP_MODAL_SUCCESS',
  OPEN_EDIT_REPAIRSHOP_MODAL_SUCCESS: 'OPEN_EDIT_REPAIRSHOP_MODAL_SUCCESS',
  OPEN_REPAIRSHOP_MODAL: 'OPEN_REPAIRSHOP_MODAL',
  OPEN_REPAIRSHOP_USERS_MODAL: 'OPEN_REPAIRSHOP_USERS_MODAL',
  OPEN_ADD_REPAIRSHOP_USERS_MODAL_SUCCESS: 'OPEN_ADD_REPAIRSHOP_USERS_MODAL_SUCCESS',
  SUBMIT_REPAIRSHOP: 'SUBMIT_REPAIRSHOP',
  FETCH_REPAIRSHOP_USERS_LIST: 'FETCH_REPAIRSHOP_USERS_LIST',
  FETCH_REPAIRSHOP_USERS_LIST_SUCCESS: 'FETCH_REPAIRSHOP_USERS_LIST_SUCCESS',
  ASSING_USER_REPAIRSHOP: 'ASSING_USER_REPAIRSHOP',
  ASSING_USER_REPAIRSHOP_SUCCESS: 'ASSING_USER_REPAIRSHOP_SUCCESS',
  DELETE_USER_REPAIRSHOP: 'DELETE_USER_REPAIRSHOP',
  DELETE_USER_REPAIRSHOP_SUCCESS: 'DELETE_USER_REPAIRSHOP_SUCCESS',
  OPEN_EDIT_REPAIRSHOP_MODAL: 'OPEN_EDIT_REPAIRSHOP_MODAL'
}
