import React, { PureComponent } from 'react'
import { Glyphicon, Tooltip, OverlayTrigger } from 'react-bootstrap'
import { translateDocumentStatus, documentStatusClass, checklistStatusIcon } from '../../../../../constants/dossier/common/documentStatus'
import { translateDocumentEntityType } from '../../../../../constants/dossier/common/documentEntityType'

class DocumenListtItem extends PureComponent {
  render () {
    const {
      t,
      documentFile,
      readOnly,
      objectId,
      documentEntityType,
      actions: { openDocumentEditionModalWithChecklistId, openModal }
    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.'
    const statusTooltip = (<Tooltip id="statusTooltip">{documentFile.documentStatus && t(`${tKey}DOCUMENTARY_MANAGEMENT.${translateDocumentStatus[documentFile.documentStatus]}`)}</Tooltip>)
    return (
      <tr onClick={() => {
        if (readOnly) return
        if (objectId) {
          openDocumentEditionModalWithChecklistId(documentFile.documentId, documentFile.documentTypeUse, documentFile.checklistId)
        } else {
          const alert = documentEntityType === translateDocumentEntityType.ENTITY ? 'entityAlert' : 'vehicleAlert'
          openModal(alert)
        }
      }}
      >
        <td>
          <span><Glyphicon glyph={checklistStatusIcon[documentFile.checklistStatus]}/></span>
        </td>
        <td className="text-left">
          <span>
            <OverlayTrigger placement="right" overlay={statusTooltip}>
              <i id="documentStatus" className={'ico-document-' + documentStatusClass[translateDocumentStatus[documentFile.documentStatus]]}/>
            </OverlayTrigger>
          </span>
        </td>
        <td><span>{documentFile.documentName}</span></td>
        <td><span className="file-text">{(t(`${tKey}DOCUMENTARY_MANAGEMENT.${translateDocumentEntityType[documentEntityType]}`))}</span></td>
        <td><span className="file-text">{documentFile.validationDate}</span></td>
        <td><span className="file-text">{documentFile.incorporationDate}</span></td>
      </tr>
    )
  }
}

export default DocumenListtItem
