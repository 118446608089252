import React, { PureComponent } from 'react'
import { Col } from 'react-bootstrap'

class InputCheckboxSlider extends PureComponent {
  handleChange (value) {
    if (!this.props.disabled) {
      if (this.props.input && this.props.input.onChange) {
        this.props.input.onChange(value.target.checked)
      }
      if (this.props.onInputChange) {
        this.props.onInputChange(value.target.checked)
      }
    }
  }

  render () {
    const { placeholder, disabled = false, input, checked, colWidht = '25', customClass = '' } = this.props
    const value = input?.value
    return (
      <Col className={'switch-container colWhidht-' + colWidht + ' ' + customClass}>
        <label className="switch">
          <input
            type="checkbox"
            checked={checked === undefined ? (typeof value) === 'string' ? value.toLowerCase() === 'true' : value : checked}
            onChange={this.handleChange.bind(this)}
            disabled={disabled} />
          <span className="slider round"></span>
        </label>
        <p>{placeholder} </p>
      </Col>
    )
  }
}

export default InputCheckboxSlider
