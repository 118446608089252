import React, { PureComponent } from 'react'
import { OverlayTrigger, Tooltip, Tabs, Tab } from 'react-bootstrap'
import { dossierTypes } from '../../../constants/dossier/common/dossierTypes'
import setting from '../../../setting'
import { icoCurrencyContent } from '../../../constants/dossier/common/currencies'

class ButtonsHeader extends PureComponent {
  hasPermission (permissionKey) {
    return this.props.buttonsPermissions.indexOf(permissionKey) !== -1
  }

  onSelectButtonHeader (eventKey) {
    this.props.actions.setActiveTab(eventKey)
  }

  calcButtonsHeaderHeight () {
    const buttonsHeader = document.getElementsByClassName('button-header-wrapper')[0]
    return buttonsHeader.clientHeight
  }

  componentDidMount () {
    const heigth = this.calcButtonsHeaderHeight()
    this.props.actions.setButtonsHeaderHeight(heigth)
    this.props.actions.setScrollerOptions({ offset: (heigth * 2 + 22 + this.props.marginTop) * -1 })
  }

  componentDidUpdate (prevProps) {
    if (prevProps.marginTop !== this.props.marginTop || prevProps.windowWidth !== this.props.windowWidth) {
      const heigth = this.calcButtonsHeaderHeight()
      this.props.actions.setButtonsHeaderHeight(heigth)
      this.props.actions.setScrollerOptions({ offset: (heigth + this.props.marginTop) * -1 })
    }
  }

  render () {
    const {
      t,
      activeTab,
      dossierType,
      showEconomicPlan = true,
      marginTop
    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.BUTTONS_HEADER.'
    const tooltipDatosExpediente = (<Tooltip id="tooltipDatosExpediente" className="tooltip-buttons-header"><span >{t(`${tKey}DOSSIER_DATE`)}</span></Tooltip>)
    const tooltipGestionDocumental = (<Tooltip id="tooltipGestionDocumental" className="tooltip-buttons-header"><span >{t(`${tKey}DOCUMENTARY_MANAGEMENT`)}</span></Tooltip>)
    const tooltipPlanteoEconomico = (<Tooltip id="tooltipPlanteoEconomico" className="tooltip-buttons-header"><span >{t(`${tKey}ECONOMIC_PLAN`)}</span></Tooltip>)
    const tooltipExpedientesRelacionados = (<Tooltip id="tooltipExpedientesRelacionados" className="tooltip-buttons-header"><span >{t(`${tKey}RELATED_DOSSIER`)}</span></Tooltip>)
    const tooltipActividadExpediente = (<Tooltip id="tooltipActividadExpediente" className="tooltip-buttons-header"><span >{t(`${tKey}RECORD_ACTIVITY`)}</span></Tooltip>)
    const tooltipObservaciones = (<Tooltip id="tooltipObservaciones" className="tooltip-buttons-header"><span >{t(`${tKey}COMMENTS`)}</span></Tooltip>)
    return (
      <div className={'button-header-wrapper fixed'} style={{ top: marginTop }}>
        <div className="col-left">
          <Tabs
            activeKey={activeTab}
            id="tab-botones-header-box"
            onSelect={this.onSelectButtonHeader.bind(this)}
          >
            <Tab eventKey={1} title={<OverlayTrigger placement="top" overlay={tooltipDatosExpediente}><i className="ico-document-lines"/></OverlayTrigger>}></Tab>
            <Tab eventKey={2} title={<OverlayTrigger placement="top" overlay={tooltipGestionDocumental}><i className="ico-docs"/></OverlayTrigger>} ></Tab>
            {
              showEconomicPlan && dossierType !== 'paperwork' && dossierType !== 'campaign' && dossierType !== dossierTypes.service && (
                <Tab eventKey={3} title={<OverlayTrigger placement="top" overlay={tooltipPlanteoEconomico}><i className={icoCurrencyContent[setting.currency]} /></OverlayTrigger>}></Tab>
              )
            }
            <Tab eventKey={4} title={<OverlayTrigger placement="top" overlay={tooltipExpedientesRelacionados}><i className="ico-relacion"/></OverlayTrigger>}></Tab>
            <Tab eventKey={5} title={<OverlayTrigger placement="top" overlay={tooltipActividadExpediente}><i className="ico-actividad-expediente"/></OverlayTrigger>}></Tab>
            <Tab eventKey={6} title={<OverlayTrigger placement="top" overlay={tooltipObservaciones}><i className="ico-bocadillo"/></OverlayTrigger>}></Tab>
          </Tabs>
        </div>
      </div>
    )
  }
}

export default ButtonsHeader
