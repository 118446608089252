import React, { PureComponent } from 'react'
import { reduxForm, Field } from 'redux-form'
import { Row, Col, Table, Button, Tooltip, OverlayTrigger } from 'react-bootstrap'
import IDocCarPagination from '../../commons/iDocCarPagination'
import InputText from '../../commons/form/InputText'
import InputTreeSelect from '../../commons/form/InputTreeSelect'
import DestinationCraneMasterModalPage from '../../../containers/masters/DestinationCrane/DestinationCraneMasterModalPage'

class DestinationCraneMaster extends PureComponent {
  constructor () {
    super()
    this.state = {
      filter: {
        searchUO: null,
        searchName: null,
        searchDescription: null
      }
    }
  }

  getSortIcon (column = '') {
    switch (this.props.filter.orderBy) {
      case '+' + column:
        return 'ico-sort-selected-asc'
      case '-' + column:
        return 'ico-sort-selected-desc'
      default:
        return 'ico-sort'
    }
  }

  changeOrder (field) {
    switch (this.props.filter.orderBy) {
      case `+${field}`:
        this.props.actions.fetchDestinationCraneMaster({ ...this.state.filter, ...this.props.filter, orderBy: `-${field}` })
        break
      default:
        this.props.actions.fetchDestinationCraneMaster({ ...this.state.filter, ...this.props.filter, orderBy: `+${field}` })
        break
    }
  }

  handleOrganizedUnitChange (searchUO) {
    if (!searchUO || searchUO.length === 0) {
      this.setState({ filter: { ...this.state.filter, searchUO: [] } })
      return
    }
    const searchUoList = searchUO.split(',').map((item) => {
      return parseInt(item)
    })
    if (this.state.filter.searchUO !== searchUoList) {
      this.setState({ filter: { ...this.state.filter, searchUO: searchUoList } })
    }
  }

  handleNameChange (searchName) {
    if (this.state.filter.searchName !== searchName) {
      this.setState({ filter: { ...this.state.filter, searchName } })
    }
  }

  handleDescriptionChange (searchDescription) {
    if (this.state.filter.searchDescription !== searchDescription) {
      this.setState({ filter: { ...this.state.filter, searchDescription } })
    }
  }

  searchDestinationCrane () {
    this.props.actions.fetchDestinationCraneMaster({ ...this.props.filter, ...this.state.filter, page: 1 })
  }

  resetFilters () {
    this.setState({
      filter: {
        searchUO: null,
        searchName: null,
        searchDescription: null
      }
    }, () => { this.searchDestinationCrane() })
  }

  componentDidUpdate (prevProps) {

  }

  render () {
    const {
      t, destinationCraneList, pagesCount, destinationCraneCount, filter, filter: { page },
      combos: { UOTreeCombo },
      actions: { fetchDestinationCraneItem, fetchDestinationCraneMaster, deleteDestinationCraneItem }
    } = this.props

    const tKey = 'MASTERS.'
    const editTooltip = (<Tooltip id="editTooltip">{t(`${tKey}EDIT`)}</Tooltip>)
    const deleteTooltip = (<Tooltip id="deleteTooltip">{t(`${tKey}DELETE`)}</Tooltip>)
    const hasFilters = (this.state.filter.searchUO || this.state.filter.searchName)

    return (
      <>
        <div className="admin-wrapper">
          <DestinationCraneMasterModalPage />
          <Row className="section-users-header">
            <Col sm={12}>
              <div className="header-panel">
                <h4>{t(`${tKey}DESTINATION_CRANE_MASTER.TITTLE`)}</h4>
              </div>
            </Col>
          </Row>
          <Row>
            <div className="search-form">
              <span className="title-search"><i className="ico-search" /> {t(`${tKey}DESTINATION_CRANE_MASTER.SEARCH_TITLE`)}</span>
              <Row>
                <Field
                  name="destCraneFilters.organizedUnit"
                  controlLabel={t(`${tKey}DESTINATION_CRANE_MASTER.ORGANIZED_UNIT`)}
                  placeholder={t(`${tKey}DESTINATION_CRANE_MASTER.ORGANIZED_UNIT`)}
                  component={InputTreeSelect}
                  multi={true}
                  valueKey="value"
                  labelKey="label"
                  childrenKey="children"
                  options={UOTreeCombo}
                  colSm={3}
                  onInputChange={(value) => this.handleOrganizedUnitChange(value)}
                />
                <Field
                  name="destCraneFilters.name"
                  colSm={2}
                  controlLabel={t(`${tKey}DESTINATION_CRANE_MASTER.NAME`)}
                  placeholder={t(`${tKey}DESTINATION_CRANE_MASTER.NAME`)}
                  component={InputText}
                  onInputChange={(value) => this.handleNameChange(value)}
                />
                <Field
                  name="destCraneFilters.description"
                  colSm={2}
                  controlLabel={t(`${tKey}DESTINATION_CRANE_MASTER.DESCRIPTION`)}
                  placeholder={t(`${tKey}DESTINATION_CRANE_MASTER.DESCRIPTION`)}
                  component={InputText}
                  onInputChange={(value) => this.handleDescriptionChange(value)}
                />
                <Col sm={5} className="btn-wrapper">
                  <Button
                    className={'btn-white btn-icon btn-input btn-white search-button'}
                    style={{ width: '100%' }}
                    onClick={() => this.searchDestinationCrane()}>
                    <i className="ico-search" />
                    {t(`${tKey}SEARCH_BTN`)}
                  </Button>
                  <Button
                    className={'btn-icon  btn-input ' + (hasFilters ? 'btn-standard' : 'btn-cancel')}
                    onClick={() => {
                      this.props.initialize({})
                      this.resetFilters()
                    }}
                  >
                    <i className="ico-cancel" />
                    {t(`${tKey}RESET_BUTTON`)}
                  </Button>
                </Col>
              </Row>
            </div>
          </Row>
          <Col sm={12} className="col-row  section-users-body">
            <Table className="editable-table" hover responsive>
              <thead>
                <tr>
                  <th onClick={() => { this.changeOrder('name') }}>
                    {t(`${tKey}DESTINATION_CRANE_MASTER.NAME`)}
                    <i className={this.getSortIcon('name')} />
                  </th>
                  <th onClick={() => { this.changeOrder('code') }}>
                    {t(`${tKey}DESTINATION_CRANE_MASTER.ORGANIZED_UNIT`)}
                    <i className={this.getSortIcon('code')} />
                  </th>
                  <th style={{ width: '177px' }}>{t('DOSSIER_COMPONENTS.ACCESSORIES.ACTIONS')}</th>
                </tr>
              </thead>
              <tbody>
                {destinationCraneList && destinationCraneList.map((destCrane, idx) => {
                  return (
                    <tr key={idx} className={(destCrane.isDeleted ? 'is-deleted' : '')}>
                      <td>{destCrane.name}</td>
                      <td>{destCrane.organizedUnit}</td>
                      <td>
                        <div>
                          <a onClick={() => fetchDestinationCraneItem(destCrane, true)}>
                            <OverlayTrigger placement="right" overlay={editTooltip}>
                              <i className="ico-edit-white" />
                            </OverlayTrigger>
                          </a>
                          {destCrane.canBeDeleted
                            ? <a onClick={() => deleteDestinationCraneItem(destCrane.destinationCraneId)}>
                              <OverlayTrigger placement="left" overlay={deleteTooltip}>
                                <i className="ico-trash" />
                              </OverlayTrigger>
                            </a> : null
                          }
                        </div>
                      </td>
                    </tr>)
                })
                }
                {(destinationCraneList === null || destinationCraneList === undefined || destinationCraneList.length === 0) &&
                  <tr>
                    <td colSpan={4}><h2>{t(`${tKey}DESTINATION_CRANE_MASTER.RESULTS_NOT_FOUND`)}</h2></td>
                  </tr>}
                <tr className="button-table-wrapper">
                  <td colSpan="2">
                    <Button
                      className="btn-editable-table btn-standard col-2"
                      onClick={() => fetchDestinationCraneItem(null, true)}>
                      + {t('MASTERS.ADD')}
                    </Button>
                  </td>
                </tr>
              </tbody>
            </Table>
            <div className="section-users-footer">
              <nav key={1} className="text-center">
                <IDocCarPagination
                  id="btn_pag_sales"
                  page={page}
                  pagesCount={pagesCount}
                  totalRows={destinationCraneCount}
                  onSelectAction={(value) => fetchDestinationCraneMaster({ ...filter, ...this.state.filter, page: value })}
                />
              </nav>
            </div>
          </Col>
        </div>
      </>
    )
  }
}

export default reduxForm({
  form: 'destinationCraneMaster'
})(DestinationCraneMaster)
