import QRCode from 'qrcode.react'
import React, { PureComponent } from 'react'

class QrDossier extends PureComponent {
  constructor () {
    super()
    this.state = {
      interval: null
    }
  }

  render () {
    return (
      <QRCode
        style={{ marginRight: -140 + 'px', marginLeft: 12 + 'px' }}
        className={'qr_code'}
        value= {this.props.dossierSubType + '/' + this.props.dossierId + '/' + this.props.number }
        size={128}
        bgColor={'#ffffff'}
        fgColor={'#000000'}
        level={'L'}
        includeMargin={false}
        renderAs={'svg'}
        imageSettings={{
          src: 'images/poweredby.png',
          x: null,
          y: null,
          height: 15,
          width: 25,
          excavate: true
        }}
      />
    )
  }
}
export default QrDossier
