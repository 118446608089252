import React, { PureComponent } from 'react'
import { Tabs, Tab } from 'react-bootstrap'
// Components
import FavoritesCard from './FavoritesCard'
import settings from '../../../setting'
// import RecentsCard from './RecentsCard'

class ExtraBox extends PureComponent {
  componentDidMount () {
    if (settings.enableFavoritesAndRecents) {
      this.props.actions.fetchDossierFavorites()
      this.props.actions.fetchDossierLastAccessed()
    }
  }

  reloadFavoritesAndLastAccessed () {
    this.props.actions.fetchDossierFavorites()
    this.props.actions.fetchDossierLastAccessed()
  }

  render () {
    const { t } = this.props
    const tKey = 'DASHBOARD.'
    // Favoritos
    var currentF = 0
    var FavoriteCard = []
    if (this.props.dossierFavorites) {
      for (var i = 0; i < this.props.dossierFavorites.favorites.length; i++) {
        FavoriteCard.push(<FavoritesCard
          t={t}
          data={this.props.dossierFavorites.favorites[i] }
          switchCardDossierFavorite={this.props.actions.switchCardDossierFavorite}
          key={'fav' + i}
        />)
        currentF++
      }
    }

    // Recientes
    var RecentCard = []
    if (this.props.dossierLastAccessed) {
      for (var r = 0; r < this.props.dossierLastAccessed.dossiers.length; r++) {
        RecentCard.push(<FavoritesCard
          t={t}
          data={this.props.dossierLastAccessed.dossiers[r]}
          switchCardDossierFavorite={this.props.actions.switchCardDossierFavorite}
          key={'rec' + r}
        />)
      }
    }
    return (
      <>{ settings.enableFavoritesAndRecents &&
      <Tabs className='extra-groups-tabs' id="extra-groups-tabs">

        <Tab eventKey={0} title={<span><i className="iDocIcon-favorite"></i>{t(`${tKey}FAVORITES.FAVORITES`)} {currentF}/{this.props.dossierFavorites?.maxFavorites}</span>}>
          <div className='actionsBar'><a onClick={() => this.reloadFavoritesAndLastAccessed()} ><i className="iDocIcon-update"></i></a></div>
          <p className={'hideText ' + (FavoriteCard.length === 0 ? 'show' : '')}><span><i className="iDocIcon-warning"></i>{t(`${tKey}FAVORITES.NO_FAVORITES`)}</span></p>
          <div className='CardContainer'>
            {FavoriteCard}
          </div>
        </Tab>

        <Tab eventKey={1} title={<span><i className="iDocIcon-last-update"></i>{t(`${tKey}FAVORITES.RECENTS`)} {this.props.dossierLastAccessed?.dossiers.length}/{this.props.dossierLastAccessed?.maxDossiers}</span>}>
          <div className='actionsBar'><a onClick={() => this.reloadFavoritesAndLastAccessed()}><i className="iDocIcon-update"></i></a></div>
          <div className='CardContainer'>
            {RecentCard}
          </div>
        </Tab>

      </Tabs>
      }
      </>
    )
  }
}
export default ExtraBox
