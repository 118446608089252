import combosActionTypes from '../../constants/actions/combos/combos'
import { documentEntityType, translateDocumentEntityType } from '../../constants/dossier/common/documentEntityType'
import { economicPlanSectionType, translateEconomicPlanSectionType } from '../../constants/dossier/common/economicPlanSection'

export function initialState () {
  return {
    cancelCombo: [],
    puscharseSaleEntitys: [],
    auditCodes: [],
    auditLoadData: [],
    dossierTypesCombo: [],
    languagesCombo: [],
    dossierSubTypesCombo: [],
    auditConfigurationType: [],
    entityTypeCombo: [],
    entitySubTypeCombo: [],
    entityFilterSubTypeCombo: [],
    saleTypeCombo: [],
    orderTypeCombo: [],
    operationTypeCombo: [],
    channelCombo: [],
    maritalStatusCombo: [],
    segmentationCombo: [],
    deliveryLocationCombo: [],
    collaboratorSaleCombo: [],
    financialInstitutionCombo: [],
    insuranceCarrierCombo: [],
    financingTypeCombo: [],
    financingTypeComboReFinance: [],
    financingTypeComboReFinanceModal: [],
    financingStatusCombo: [],
    issuesEntities: [],
    lowestOrganizedUnitsCombo: [],
    contactScheduleCombo: [{ tag: 'MORNING' }, { tag: 'MIDDLE_DAY' }, { tag: 'AFTERNOON' }, { tag: 'ALL_DAY' }],
    contactWayCombo: [{ tag: 'EMAIL' }, { tag: 'PHONE' }, { tag: 'MOBILE' }],
    salesmanCombo: [],
    completeSalesmanCombo: [],
    UOTreeCombo: [],
    UOTreeComboByPermission: [],
    UOAcceptClientsCombo: [],
    paymentMethodCombo: [],
    paymentMethodCampaignCombo: [],
    previousUsageCombo: [],
    laterUseCombo: [],
    activityClientCombo: [],
    activityBuyerCombo: [],
    brandCombo: [],
    carlineCombo: [],
    catalogueCombo: [],
    modelCombo: [],
    catalogueModelCombo: [],
    colorCombo: [],
    tapestryCombo: [],
    companiesCombo: [],
    vehicletypeCombo: [],
    fuelCombo: [],
    campaignsTypes: [],
    documentsTypes: [],
    processingAgency: [],
    usersCombo: [],
    paperworkTypes: [],
    serviceTypeCombo: [],
    stagesPurchaseCombo: [],
    ivaTypeCombo: [],
    stagesCombo: [],
    documentEntityTypeCombo: [
      { id: documentEntityType.ENTITY, value: translateDocumentEntityType[1] },
      { id: documentEntityType.VEHICLE, value: translateDocumentEntityType[2] },
      { id: documentEntityType.DOSSIER, value: translateDocumentEntityType[3] }
    ],
    documentStatusCombo: [],
    economicTargetTypes: [
      { id: economicPlanSectionType.VENTA_OTROS, value: translateEconomicPlanSectionType[economicPlanSectionType.VENTA_OTROS] },
      { id: economicPlanSectionType.SUPLIDOS, value: translateEconomicPlanSectionType[economicPlanSectionType.SUPLIDOS] },
      { id: economicPlanSectionType.COSTE_OBSEQUIOS, value: translateEconomicPlanSectionType[economicPlanSectionType.COSTE_OBSEQUIOS] }
    ],
    documentsTypesCombo: [],
    entityTypesCombo: [],
    extraSaleTypeCombo: [],
    presentTypeCombo: [],
    purchasePresentTypeCombo: [],
    suppliedTypeCombo: [],
    purchaseTypeCombo: [],
    searchDossierSubtypesCombo: [],
    templateTypesCombo: [],
    templateLogoPositionCombo: [
      { id: 1, value: 'LEFT' },
      { id: 2, value: 'RIGHT' },
      { id: 3, value: 'CUSTOM' }
    ],
    templateDataTypeCombo: [
      { id: 1, value: 'DOSSIER' },
      { id: 2, value: 'ENTITY' },
      { id: 3, value: 'VEHICLE' },
      { id: 4, value: 'PARENT_ENTITY' },
      { id: 5, value: 'PARENT_VEHICLE' },
      { id: 6, value: 'UO' },
      { id: 7, value: 'ECONOMIC_PLAN' },
      { id: 8, value: 'ORDER' },
      { id: 9, value: 'CHARGE' },
      { id: 10, value: 'USER' },
      { id: 11, value: 'PROCESSING_AGENCY' },
      { id: 12, value: 'OTHERS' },
      { id: 13, value: 'PARENT_ORDER' },
      { id: 14, value: 'SALESMAN_MANAGER' },
      { id: 15, value: 'UAC' },
      { id: 28, value: 'CUSTOM_FIELD' },
      { id: 26, value: 'PURCHASE_PRESENT' },
      { id: 29, value: 'TYPE_TEMPLATE' }
    ],
    templateFieldCombos: {},
    roleCombo: [],
    functionToAddCombo: [],
    functionToPassCombo: [],
    rejectedCombo: [
      { id: 0, value: 'NO_REJECTED' },
      { id: 1, value: 'REJECTED' }
    ],
    dossierDestinationCombo: [
      { id: 0, value: 'No aplica' },
      { id: 1, value: 'Venta' },
      { id: 2, value: 'Vehículo' },
      { id: 3, value: 'Cliente / Proveedor' },
      { id: 4, value: 'Accesorio' },
      { id: 5, value: 'Opcional' },
      { id: 6, value: 'Costes No Recuperables' },
      { id: 7, value: 'Campaña' },
      { id: 8, value: 'UAC' },
      { id: 9, value: 'Servicios' },
      { id: 10, value: 'Financiación' },
      { id: 11, value: 'Trámite' },
      { id: 12, value: 'Cobro' },
      { id: 13, value: 'Planteo económico' },
      { id: 14, value: 'Entregas' },
      { id: 15, value: 'Comentarios' },
      { id: 16, value: 'Trámite' },
      { id: 17, value: 'Compra' },
      { id: 18, value: 'Campaña (Componente en Venta)' },
      { id: 19, value: 'Descuentos' },
      { id: 20, value: 'Reclamación' },
      { id: 21, value: 'Daños principales' },
      { id: 26, value: 'Otros datos' }
    ],
    activeInactiveCombo: [
      { id: 0, value: 'INACTIVE' },
      { id: 1, value: 'ACTIVE' }
    ],
    reasonCombo: [],
    dealersCombo: [],
    additionalInfoIds: [],
    entityTransferCombo: [],
    purchaseTypeVnCombo: [],
    uoSalesman: [],
    newClaimDossier: -1,
    organizedUnitsLegalCif: [],
    familyCombo: [],
    externalCode: [],
    countries: [],
    stockStatusCombo: [],
    uacIvaTpyes: [],
    fleetsCombo: [],
    fleets: [],
    fleetsUser: [],
    managementActions: [],
    departmentCombo: [],
    selfSupplyOrganizedUnitCombo: [],
    recurrentEntitiesCombo: [],
    massiveDownloadStatusCombo: [],
    unattendedProcessViewCombo: [],
    /* [
      { id: 0, value: 'ALL_USERS' },
      { id: 1, value: 'ONLY_USER' }
    ] */
    unattendedProcessStatusCombo: [
      { id: 1, value: 'PENDING' },
      { id: 2, value: 'RUNNING' },
      { id: 3, value: 'FINALIZED' }
    ],
    unattendedProcessTypeCombo: [],
    dossierSubTypesComboCampaign: [],
    satPaymentMethod: [],
    fleetDocumentActionsCombo: [
      { id: 0, value: 'INCORPORATE' },
      { id: 1, value: 'VALIDATE' },
      { id: 2, value: 'CANCEL' },
      { id: 3, value: 'EDIT' },
      { id: 4, value: 'REQUEST_SIGN' },
      { id: 5, value: 'CANCEL_SIGN' },
      { id: 6, value: 'REJECT' }
    ],
    currencyCombo: [],
    signTypeCombo: [],
    signEntityTypeCombo: [],
    tuneUpDestinationCombo: [],
    purchaseTypeComboFromSale: [],
    legalCompanyCombo: [],
    processingAgencyCombo: [],
    directionOUCombo: [],
    CSTCombo: [],
    activityBproCombo: [],
    destinationCombo: [],
    repairshopCombo: [],
    ratingCombo: [],
    ratingComboByOrganizedUnit: [],
    documentDataOptionsCombo: [],
    destinationCraneCombo: [],
    processingAgencyManagersCombo: [],
    purchaseStateLogisticCombo: [],
    insuranceCompanyCombo: []
  }
}

function fetchCombosSuccess (state, { data }) {
  return {
    ...state,
    ...data
  }
}

function fetchDossierTypesSuccess (state, { data }) {
  return {
    ...state,
    dossierTypesCombo: data
  }
}

function fetchDossierSubTypesSuccess (state, { data }) {
  return {
    ...state,
    dossierSubTypesCombo: data
  }
}

function fetchLowestOrganizedUnitsSuccess (state, { data }) {
  return {
    ...state,
    lowestOrganizedUnitsCombo: data
  }
}

function fetchOrganizedUnitsTreeSuccess (state, { data }) {
  return {
    ...state,
    UOTreeCombo: data
  }
}

function fetchOrganizedUnitsComboSuccess (state, { data }) {
  return {
    ...state,
    UOCombo: data
  }
}

function fetchOrganizedUnitsTreeComboByPermissionSuccess (state, { data }) {
  return {
    ...state,
    UOTreeComboByPermission: data
  }
}

function fetchEntityTypesSuccess (state, { data }) {
  return {
    ...state,
    entityTypeCombo: data
  }
}

function fetchSaleTypeSuccess (state, { data }) {
  return {
    ...state,
    saleTypeCombo: data
  }
}

function fetchSaleTypeBySubtypeSuccess (state, { data }) {
  return {
    ...state,
    saleTypeCombo: data
  }
}

function fetchPurchaseTypeSuccess (state, { data }) {
  return {
    ...state,
    purchaseTypeCombo: data
  }
}

function fetchPaperworkTypeSuccess (state, { data }) {
  return {
    ...state,
    paperworkTypes: data
  }
}

function fetchCampaignTypeSuccess (state, { data }) {
  return {
    ...state,
    campaignsTypes: data
  }
}

function fetchOrderTypeSuccess (state, { data }) {
  return {
    ...state,
    orderTypeCombo: data
  }
}

function fetchOperationTypeSuccess (state, { data }) {
  return {
    ...state,
    operationTypeCombo: data
  }
}

function fetchOperationTypeModalSuccess (state, { data }) {
  return {
    ...state,
    operationTypeModalCombo: data
  }
}

function fetchChanelSourceSuccess (state, { data }) {
  return {
    ...state,
    chanelSourceCombo: data
  }
}

function fetchSegmentationSuccess (state, { data }) {
  return {
    ...state,
    segmentationCombo: data
  }
}

function fetchDeliveryLocationSuccess (state, { data }) {
  return {
    ...state,
    deliveryLocationCombo: data
  }
}

function fetchCollaboratorSaleSuccess (state, { data }) {
  return {
    ...state,
    collaboratorSaleCombo: data
  }
}

function fetchFinancialInstitutionSuccess (state, { data }) {
  return {
    ...state,
    financialInstitutionCombo: data
  }
}

function fetchInsuranceCarrierSuccess (state, { data }) {
  return {
    ...state,
    insuranceCarrierCombo: data
  }
}

function fetchFinancingTypeSuccess (state, { data, page }) {
  var _financingTypeCombo = state.financingTypeCombo
  var _financingTypeComboReFinance = state.financingTypeComboReFinance
  var _financingTypeComboReFinanceModal = state.financingTypeComboReFinanceModal

  if (page == null || page === undefined) {
    _financingTypeCombo = data
  } else if (page === 'refinance') {
    _financingTypeComboReFinance = data
  } else if (page === 'refinanceModal') {
    _financingTypeComboReFinanceModal = data
  }

  return {
    ...state,
    financingTypeCombo: _financingTypeCombo,
    financingTypeComboReFinance: _financingTypeComboReFinance,
    financingTypeComboReFinanceModal: _financingTypeComboReFinanceModal

  }
}

function fetchFinancingCompaniesSuccess (state, { data }) {
  return {
    ...state,
    companiesCombo: data
  }
}

function fetchSalesmanSuccess (state, { data }) {
  return {
    ...state,
    salesmanCombo: data
  }
}

function fetchPaymentMethodSuccess (state, { data }) {
  return {
    ...state,
    paymentMethodCombo: data
  }
}

function fetchPaymentMethodCampaignSuccess (state, { data }) {
  return {
    ...state,
    paymentMethodCampaignCombo: data
  }
}

function fetchServiceTypeComboSuccess (state, { data }) {
  return {
    ...state,
    serviceTypeCombo: data
  }
}

function fetchExtraSaleTypeComboSuccess (state, { data }) {
  return {
    ...state,
    extraSaleTypeCombo: data
  }
}

export function fetchUserComboSuccess (state, { data }) {
  return {
    ...state,
    usersCombo: data
  }
}

export function fetchCampaignTypeByGroupSuccess (state, { data }) {
  return {
    ...state,
    campaignTypeTest: data
  }
}

export function fetchCampaignClassSuccess (state, { data }) {
  return {
    ...state,
    campaignClass: data
  }
}

export function fetchCampaignGroupSuccess (state, { data }) {
  return {
    ...state,
    campaignGroup: data
  }
}

function fetchPresentTypeComboSuccess (state, { data }) {
  return {
    ...state,
    presentTypeCombo: data
  }
}

function fetchPurchasePresentTypeComboSuccess (state, { data }) {
  return {
    ...state,
    purchasePresentTypeCombo: data
  }
}

function fetchSuppliedTypeComboSuccess (state, { data }) {
  return {
    ...state,
    suppliedTypeCombo: data
  }
}

function fetchRolesSuccess (state, { data }) {
  return {
    ...state,
    roleCombo: data
  }
}

function fetchSectionsSuccess (state, { data }) {
  return {
    ...state,
    sectionCombo: data
  }
}

function fetchPermissionsSuccess (state, { data }) {
  return {
    ...state,
    permissionCombo: data
  }
}
function fetchDocumentStatusSuccess (state, { data }) {
  return {
    ...state,
    documentStatusCombo: data
  }
}

function fetchMassiveDownloadStatusSuccess (state, { data }) {
  return {
    ...state,
    massiveDownloadStatusCombo: data
  }
}

function fetchDocumentTypeUseSuccess (state, { data }) {
  return {
    ...state,
    documentTypeUseCombo: data
  }
}

function fetchSignTypeSuccess (state, { data }) {
  return {
    ...state,
    signTypeCombo: data
  }
}

function fetchSignEntityTypeSuccess (state, { data }) {
  return {
    ...state,
    signEntityTypeCombo: data
  }
}

function fetchFunctionToAddSuccess (state, { data }) {
  return {
    ...state,
    functionToAddCombo: data
  }
}

function fetchFunctionToPassSuccess (state, { data }) {
  return {
    ...state,
    functionToPassCombo: data
  }
}

function fetchReasonsSuccess (state, { data }) {
  return {
    ...state,
    reasonCombo: data
  }
}

function fetchCancelComboSuccess (state, { data }) {
  return {
    ...state,
    cancelCombo: data
  }
}

function fetchRatingComboSuccess (state, { data }) {
  return {
    ...state,
    ratingCombo: data
  }
}

function fetchAllReasonsSuccess (state, { data }) {
  return {
    ...state,
    allReasonCombo: data
  }
}

function fecthOrganizedUnitsByDossierUoSucces (state, { data }) {
  return {
    ...state,
    organizedUnitsLegalCif: data
  }
}

function fetchCountriesSucess (state, { data }) {
  return {
    ...state,
    countries: data
  }
}
function fecthFamilySucces (state, { data }) {
  return {
    ...state,
    familyCombo: data
  }
}

function fetchExternalCodeSucces (state, { data }) {
  return {
    ...state,
    externalCode: data
  }
}

function fetchFleetsComboSuccess (state, { data }) {
  return {
    ...state,
    fleetsCombo: data
  }
}
function fetchFleetsUserSuccess (state, { data }) {
  return {
    ...state,
    fleetsUser: data
  }
}
function fetchManagementActionsSuccess (state, { data }) {
  return {
    ...state,
    managementActions: data
  }
}

function fetchFleetSuccess (state, { data }) {
  return {
    ...state,
    fleets: data
  }
}

function fetchDocumentTypeEntitiesSuccess (state, { data }) {
  return {
    ...state,
    documentTypeEntities: data
  }
}

function fetchDocumentTypeSuccess (state, { data }) {
  return {
    ...state,
    documentTypes: data
  }
}

function fetchUnattendedProcessTypeComboSuccess (state, { data }) {
  return {
    ...state,
    unattendedProcessTypeCombo: data.unattendedProcessTypes
  }
}

function fetchUnattendedProcessViewComboSuccess (state, { data }) {
  return {
    ...state,
    unattendedProcessViewCombo: data.unattendedProcessView
  }
}

function fetchProcessingAgencySuccess (state, { data }) {
  return {
    ...state,
    processingAgencyCombo: data
  }
}

function fetchLegalCompanySuccess (state, { data }) {
  return {
    ...state,
    legalCompanyCombo: data
  }
}

function fetchDirectionOUSuccess (state, { data }) {
  return {
    ...state,
    directionOUCombo: data
  }
}
function fetchAllSaleTypeSuccess (state, { data }) {
  return {
    ...state,
    saleTypeCombo: data
  }
}

function fetchCurrenciesSuccess (state, { data }) {
  return {
    ...state,
    currencyCombo: data
  }
}
function fetchTuneUpDestinationSuccess (state, { data }) {
  return {
    ...state,
    tuneUpDestinationCombo: data
  }
}

function fetchServiceDestinationsComboSuccess (state, { data }) {
  return {
    ...state,
    serviceDestinations: data
  }
}

function fetchCommercialSocietyTypeComboSuccess (state, { data }) {
  return {
    ...state,
    CSTCombo: data
  }
}

export function resetCarlineCombo (state) {
  return {
    ...state,
    carlineCombo: []
  }
}

export function resetCatalogueCombo (state) {
  return {
    ...state,
    catalogueCombo: []
  }
}

export function resetColorCombo (state) {
  return {
    ...state,
    colorCombo: []
  }
}

export function resetTapestryCombo (state) {
  return {
    ...state,
    tapestryCombo: []
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case combosActionTypes.FETCH_COMBOS_SUCCESS:
      return fetchCombosSuccess(state, action)
    case combosActionTypes.FETCH_DOSSIER_TYPES_SUCCESS:
      return fetchDossierTypesSuccess(state, action)
    case combosActionTypes.FETCH_DOSSIER_SUBTYPES_SUCCESS:
      return fetchDossierSubTypesSuccess(state, action)
    case combosActionTypes.FETCH_LOWEST_ORGANIZED_UNITS_SUCCESS:
      return fetchLowestOrganizedUnitsSuccess(state, action)
    case combosActionTypes.FETCH_ENTITY_TYPES_SUCCESS:
      return fetchEntityTypesSuccess(state, action)
    case combosActionTypes.FETCH_SALE_TYPE_SUCCESS:
      return fetchSaleTypeSuccess(state, action)
    case combosActionTypes.FETCH_SALE_TYPE_BY_SUBTYPE_SUCCESS:
      return fetchSaleTypeBySubtypeSuccess(state, action)
    case combosActionTypes.FETCH_PURCHASE_TYPE_SUCCESS:
      return fetchPurchaseTypeSuccess(state, action)
    case combosActionTypes.FETCH_PAPERWORK_TYPE_SUCCESS:
      return fetchPaperworkTypeSuccess(state, action)
    case combosActionTypes.FETCH_CAMPAIGN_TYPE_SUCCESS:
      return fetchCampaignTypeSuccess(state, action)
    case combosActionTypes.FETCH_ORDER_TYPE_SUCCESS:
      return fetchOrderTypeSuccess(state, action)
    case combosActionTypes.FETCH_OPERATION_TYPE_SUCCESS:
      return fetchOperationTypeSuccess(state, action)
    case combosActionTypes.FETCH_OPERATION_TYPE_MODAL_SUCCESS:
      return fetchOperationTypeModalSuccess(state, action)
    case combosActionTypes.FETCH_CHANEL_SOURCE_SUCCESS:
      return fetchChanelSourceSuccess(state, action)
    case combosActionTypes.FETCH_SEGMENTATION_SUCCESS:
      return fetchSegmentationSuccess(state, action)
    case combosActionTypes.FETCH_DELIVERY_LOCATION_SUCCESS:
      return fetchDeliveryLocationSuccess(state, action)
    case combosActionTypes.FETCH_COLLABORATOR_SALE_SUCCESS:
      return fetchCollaboratorSaleSuccess(state, action)
    case combosActionTypes.FETCH_FINANCIAL_INSTITUTION_SUCCESS:
      return fetchFinancialInstitutionSuccess(state, action)
    case combosActionTypes.FETCH_FINANCING_TYPE_SUCCESS:
      return fetchFinancingTypeSuccess(state, action)
    case combosActionTypes.FETCH_INSURANCE_CARRIER_SUCCESS:
      return fetchInsuranceCarrierSuccess(state, action)
    case combosActionTypes.FETCH_COMPANIES_SUCCESS:
      return fetchFinancingCompaniesSuccess(state, action)
    case combosActionTypes.FETCH_SALESMAN_SUCCESS:
      return fetchSalesmanSuccess(state, action)
    case combosActionTypes.FETCH_PAYMENT_METHOD_SUCCESS:
      return fetchPaymentMethodSuccess(state, action)
    case combosActionTypes.FETCH_PAYMENT_METHOD_CAMPAIGN_SUCCESS:
      return fetchPaymentMethodCampaignSuccess(state, action)
    case combosActionTypes.FETCH_DOSSIER_SUBTYPES_BY_OU:
      return fetchServiceTypeComboSuccess(state, action)
    case combosActionTypes.FETCH_EXTRA_SALE_TYPES:
      return fetchExtraSaleTypeComboSuccess(state, action)
    case combosActionTypes.FETCH_PRESENT_TYPES:
      return fetchPresentTypeComboSuccess(state, action)
    case combosActionTypes.FETCH_PURCHASE_PRESENT_TYPES:
      return fetchPurchasePresentTypeComboSuccess(state, action)
    case combosActionTypes.FETCH_SUPPLIED_TYPES:
      return fetchSuppliedTypeComboSuccess(state, action)
    case combosActionTypes.FETCH_ORGANIZED_UNITS_TREE_SUCCESS:
      return fetchOrganizedUnitsTreeSuccess(state, action)
    case combosActionTypes.FETCH_DOCUMENT_STATUS_SUCCESS:
      return fetchDocumentStatusSuccess(state, action)
    case combosActionTypes.FETCH_MASSIVE_DOWNLOAD_STATUS_SUCCESS:
      return fetchMassiveDownloadStatusSuccess(state, action)
    case combosActionTypes.FETCH_DOCUMENT_TYPE_USE_SUCCESS:
      return fetchDocumentTypeUseSuccess(state, action)
    case combosActionTypes.FETCH_SIGN_TYPE_SUCCESS:
      return fetchSignTypeSuccess(state, action)
    case combosActionTypes.FETCH_SIGN_ENTITY_TYPE_SUCCESS:
      return fetchSignEntityTypeSuccess(state, action)
    case combosActionTypes.FETCH_ORGANIZED_UNITS_COMBO_SUCCESS:
      return fetchOrganizedUnitsComboSuccess(state, action)
    case combosActionTypes.FETCH_ORGANIZED_UNITS_TREE_COMBO_BY_PERMISSION_SUCCESS:
      return fetchOrganizedUnitsTreeComboByPermissionSuccess(state, action)
    case combosActionTypes.FETCH_ROLES_SUCCESS:
      return fetchRolesSuccess(state, action)
    case combosActionTypes.FETCH_SECTION_KEY_VALUE_SUCCESS:
      return fetchSectionsSuccess(state, action)
    case combosActionTypes.FETCH_PERMISSION_KEY_VALUE_SUCCESS:
      return fetchPermissionsSuccess(state, action)
    case combosActionTypes.FETCH_FUNCTION_TO_ADD_SUCCESS:
      return fetchFunctionToAddSuccess(state, action)
    case combosActionTypes.FETCH_FUNCTION_TO_PASS_SUCCESS:
      return fetchFunctionToPassSuccess(state, action)
    case combosActionTypes.FETCH_REASONS_SUCCESS:
      return fetchReasonsSuccess(state, action)
    case combosActionTypes.FETCH_ALL_REASONS_SUCCESS:
      return fetchAllReasonsSuccess(state, action)
    case combosActionTypes.FETCH_ORGANIZEDUNITS_BY_DOSSIERUO_SUCCES:
      return fecthOrganizedUnitsByDossierUoSucces(state, action)
    case combosActionTypes.FETCH_USER_COMBO_SUCCESS:
      return fetchUserComboSuccess(state, action)
    case combosActionTypes.FETCH_CAMPAIGN_TYPE_BY_GROUP_SUCCESS:
      return fetchCampaignTypeByGroupSuccess(state, action)
    case combosActionTypes.FETCH_CAMPAIGN_CLASS_SUCCESS:
      return fetchCampaignClassSuccess(state, action)
    case combosActionTypes.FETCH_CAMPAIGN_GROUP_SUCCESS:
      return fetchCampaignGroupSuccess(state, action)
    case combosActionTypes.FETCH_COUNTRY_COMBO_SUCCES:
      return fetchCountriesSucess(state, action)
    case combosActionTypes.FETCH_FAMILY_COMBO_SUCCES:
      return fecthFamilySucces(state, action)
    case combosActionTypes.FETCH_EXTERNALCODE_COMBO_SUCCES:
      return fetchExternalCodeSucces(state, action)
    case combosActionTypes.FETCH_FLEETS_COMBO_SUCCESS:
      return fetchFleetsComboSuccess(state, action)
    case combosActionTypes.FETCH_FLEET_SUCCESS:
      return fetchFleetSuccess(state, action)
    case combosActionTypes.FETCH_FLEETS_USER_SUCCES:
      return fetchFleetsUserSuccess(state, action)
    case combosActionTypes.FETCH_MANAGEMENT_ACTIONS_SUCCESS:
      return fetchManagementActionsSuccess(state, action)
    case combosActionTypes.FETCH_CANCEL_COMBO_SUCCESS:
      return fetchCancelComboSuccess(state, action)
    case combosActionTypes.FETCH_RATING_COMBO_SUCCESS:
      return fetchRatingComboSuccess(state, action)
    case combosActionTypes.FETCH_DOCUMENT_TYPE_ENTITIES_SUCCESS:
      return fetchDocumentTypeEntitiesSuccess(state, action)
    case combosActionTypes.FETCH_DOCUMENT_TYPE_SUCCESS:
      return fetchDocumentTypeSuccess(state, action)
    case combosActionTypes.FETCH_UNATTENDED_PROCESS_TYPES_SUCCESS:
      return fetchUnattendedProcessTypeComboSuccess(state, action)
    case combosActionTypes.FETCH_UNATTENDED_PROCESS_VIEW_SUCCESS:
      return fetchUnattendedProcessViewComboSuccess(state, action)
    case combosActionTypes.FETCH_PROCESSING_AGENCY_COMBO_SUCCESS:
      return fetchProcessingAgencySuccess(state, action)
    case combosActionTypes.FETCH_LEGAL_COMPANY_COMBO_SUCCESS:
      return fetchLegalCompanySuccess(state, action)
    case combosActionTypes.FETCH_DIRECTIION_OU_COMBO_SUCCESS:
      return fetchDirectionOUSuccess(state, action)
    case combosActionTypes.FETCH_ALL_SALE_TYPE_SUCCESS:
      return fetchAllSaleTypeSuccess(state, action)
    case combosActionTypes.FETCH_CURRENCY_COMBO_SUCCESS:
      return fetchCurrenciesSuccess(state, action)
    case combosActionTypes.FETCH_TUNE_UP_DESTINATION_SUCCESS:
      return fetchTuneUpDestinationSuccess(state, action)
    case combosActionTypes.FETCH_PROCESSING_AGENCY_SERVICE_DESTINATIONS_COMBO_SUCCESS:
      return fetchServiceDestinationsComboSuccess(state, action)
    case combosActionTypes.FETCH_COMMERCIAL_SOCIETY_TYPE_COMBO_SUCCESS:
      return fetchCommercialSocietyTypeComboSuccess(state, action)
    case combosActionTypes.RESET_CARLINE_COMBO:
      return resetCarlineCombo(state)
    case combosActionTypes.RESET_CATALOGUE_COMBO:
      return resetCatalogueCombo(state)
    case combosActionTypes.RESET_COLOR_COMBO:
      return resetColorCombo(state)
    case combosActionTypes.RESET_TAPESTRY_COMBO:
      return resetTapestryCombo(state)
    default:
      return state
  }
}
