import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { fetchPaperwokTypesMaster, fetchPaperwokTypeMasterInfo, deletePaperwokTypeMaster } from '../../../actions/masters/masters'
import PwkTypesMaster from '../../../components/masters/paperworkTypes/PwkTypesMaster'

export function mapStateToProps (state) {
  return {
    ...state.paperworkTypesMaster
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchPaperwokTypesMaster,
      fetchPaperwokTypeMasterInfo,
      deletePaperwokTypeMaster
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(PwkTypesMaster))
