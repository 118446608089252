import { put, call, select, takeEvery } from 'redux-saga/effects'
import { getAuth } from '../../selectors/access/auth'
import { showLoader, hideLoader } from '../../actions/common.js'
import { handleError } from '../errors/errorManager'
import postImportVehicleModels from '../../services/vehicle/postImportVehicleModels'
import importVehicleModels from '../../constants/actions/vehicle/importVehicleModels'
import { importVehicleModelFileSuccess } from '../../actions/vehicle/importVehicleModels'

export function * importVehicleModelFile ({ body, brandId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const result = yield call(postImportVehicleModels, auth.token, body, brandId)
    yield put(importVehicleModelFileSuccess(result))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchImportVehicleModelFile () {
  yield takeEvery(importVehicleModels.IMPORT_VEHICLE_MODEL_FILE, importVehicleModelFile)
}
