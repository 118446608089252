import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import SearchByChargeForm from '../../../components/search/SearchByChargeForm'
import { translate } from 'react-polyglot'
import { fetchBankCombo, fetchDocumentStatusCombo } from '../../../actions/combos/combos'

export function mapStateToProps (state) {
  return {
    combos: {
      salesmanCombo: state.combos.salesmanCombo,
      banksCombo: state.combos.banksCombo,
      documentStatusCombo: state.combos.documentStatusCombo
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchDocumentStatusCombo,
      fetchBankCombo
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(SearchByChargeForm))
