import { put, call, select, takeEvery } from 'redux-saga/effects'
import { getAuth } from '../../../selectors/access/auth'
import { showLoader, hideLoader, openModal } from '../../../actions/common.js'
import mastersActionTypes from '../../../constants/actions/masters/masters'
import {
  fetchFinancingTypeProductFieldsSuccess,
  fetchFinancingTypeProductListSuccess,
  openFinancingTypeProductModalSuccess,
  saveFinancingTypeProductModalSuccess,
  getAllFinancingTypeProductSucces,
  getFinancingTypeProductByFinancingTypeSuccess
} from '../../../actions/masters/masters'
import { handleError } from '../../errors/errorManager'
import { getfilterFinancingTypeProduct } from '../../../selectors/masters/filterFinancingTypeProduct'
import { yesNoModal } from '../../modalsListeners/yesNoModal'
import getFinancingTypeProductFields from '../../../services/financigTypeProduct/getFinancingTypeProductFields'
import getFinancingTypeProductList from '../../../services/financigTypeProduct/getFinancingTypeProductList'
import checkAndGetProductTypeUses from '../../../services/financigTypeProduct/checkAndGetProductTypeUses'
import deleteFinancingTypeProduct from '../../../services/financigTypeProduct/deleteFinancingTypeProduct'
import recoverFinancingTypeProduct from '../../../services/financigTypeProduct/recoverFinancingTypeProduct'
import getFinancingTypeProduct from '../../../services/financigTypeProduct/getFinancingTypeProduct'
import getLanguageListComboService from '../../../services/language/getLanguageList'
import updateFinancingTypeProduct from '../../../services/financigTypeProduct/updateFinancingTypeProduct'
import insertFinancingTypeProduct from '../../../services/financigTypeProduct/insertFinancingTypeProduct'
import getAllFinancingTypeProduct from '../../../services/financigTypeProduct/getAllFinancingTypeProduct'
import getFinancingTypeProductByProductId from '../../../services/financigTypeProduct/getFinancingTypeProductByProductId'

export function * fetchFinancingTypeProductByProductId ({ id }) {
  try {
    if (id === null || id === undefined) {
      yield put(getFinancingTypeProductByFinancingTypeSuccess(null))
    } else {
      yield put(showLoader())
      const auth = yield select(getAuth)
      const financingTypeProduct = yield call(getFinancingTypeProductByProductId, auth.token, id)
      yield put(getFinancingTypeProductByFinancingTypeSuccess(financingTypeProduct))
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchfetchFinancingTypeProductByProductId () {
  yield takeEvery(mastersActionTypes.GET_FINANCING_TYPE_PRODUCT_BY_FINANCING_TYPE, fetchFinancingTypeProductByProductId)
}

export function * fetchAllFinancingTypeProduct ({ isDeleted }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const fields = yield call(getAllFinancingTypeProduct, auth.token, isDeleted)
    yield put(getAllFinancingTypeProductSucces(fields))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchfetchAllFinancingTypeProduct () {
  yield takeEvery(mastersActionTypes.GET_ALL_FINANCING_TYPE_PRODUCT, fetchAllFinancingTypeProduct)
}

export function * fetchFinancingTypeProductFields () {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const fields = yield call(getFinancingTypeProductFields, auth.token)
    yield put(fetchFinancingTypeProductFieldsSuccess(fields))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchfetchFinancingTypeProductFields () {
  yield takeEvery(mastersActionTypes.FETCH_FINANCING_TYPE_PRODUCT_FIELDS, fetchFinancingTypeProductFields)
}

export function * fetchFinancingTypeProductList ({ filter }) {
  try {
    if (filter === null || filter === undefined) {
      filter = yield filter || select(getfilterFinancingTypeProduct)
    }
    yield put(showLoader())
    const auth = yield select(getAuth)
    const result = yield call(getFinancingTypeProductList, auth.token, filter.page, filter.orderBy, filter.pageSize, filter.name, filter.description, true)
    yield put(fetchFinancingTypeProductListSuccess(result, filter))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchfetchFinancingTypeProductList () {
  yield takeEvery(mastersActionTypes.FETCH_FINANCING_TYPE_PRODUCT_LIST, fetchFinancingTypeProductList)
}

export function * deleteFinancingTypeProd ({ financingTypeProductId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const check = yield call(checkAndGetProductTypeUses, auth.token, financingTypeProductId)
    yield put(hideLoader())
    if (check !== null) {
      var msj = ''
      check.map(ck => {
        msj = msj + ck.item1 + '/' + ck.item2 + ' \n'
      })
      yield put(openModal('deleteFinancingTypeProductInUse', null, null, null, null, [{ id: '##PRODUCTS##', value: msj }], null))
    } else {
      const confirmed = yield call(yesNoModal, 'deleteFinancingTypeProduct')

      if (confirmed) {
        yield put(showLoader())

        var ok = yield call(deleteFinancingTypeProduct, auth.token, financingTypeProductId)
        if (ok) {
          const filter = yield filter || select(getfilterFinancingTypeProduct)
          const result = yield call(getFinancingTypeProductList, auth.token, filter.page, filter.orderBy, filter.pageSize, filter.name, filter.description, true)
          yield put(fetchFinancingTypeProductListSuccess(result, filter))
        }
      }
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchdeleteFinancingTypeProd () {
  yield takeEvery(mastersActionTypes.DELETE_FINANCING_TYPE_PRODUCT_MASTERS, deleteFinancingTypeProd)
}

export function * recoverFinancingTypeProd ({ financingTypeProductId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    var ok = yield call(recoverFinancingTypeProduct, auth.token, financingTypeProductId)
    if (ok) {
      const filter = yield filter || select(getfilterFinancingTypeProduct)
      const result = yield call(getFinancingTypeProductList, auth.token, filter.page, filter.orderBy, filter.pageSize, filter.name, filter.description, true)
      yield put(fetchFinancingTypeProductListSuccess(result, filter))
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchrecoverFinancingTypeProd () {
  yield takeEvery(mastersActionTypes.RECOVER_FINANCING_TYPE_PRODUCT, recoverFinancingTypeProd)
}

export function * fetchOpenFinancingTypeProductModal ({ financingTypeProductId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const languageList = yield call(getLanguageListComboService, auth.token)
    if (financingTypeProductId === null || financingTypeProductId === undefined) {
      yield put(openFinancingTypeProductModalSuccess(null, null, languageList))
    } else {
      const result = yield call(getFinancingTypeProduct, auth.token, financingTypeProductId)
      yield put(openFinancingTypeProductModalSuccess(financingTypeProductId, result, languageList))
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchfetchOpenFinancingTypeProductModal () {
  yield takeEvery(mastersActionTypes.OPEN_FINANCING_TYPE_PRODUCT_MODAL, fetchOpenFinancingTypeProductModal)
}

export function * updateFinancingTypeProd ({ financingTypeProduct }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    if (financingTypeProduct.financingTypeProductId === null) {
      yield call(insertFinancingTypeProduct, auth.token, financingTypeProduct)
    } else {
      yield call(updateFinancingTypeProduct, auth.token, financingTypeProduct)
    }

    const filter = yield filter || select(getfilterFinancingTypeProduct)
    const result = yield call(getFinancingTypeProductList, auth.token, filter.page, filter.orderBy, filter.pageSize, filter.name, filter.description, true)
    yield put(fetchFinancingTypeProductListSuccess(result, filter))
    yield put(saveFinancingTypeProductModalSuccess())
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchupdateFinancingTypeProd () {
  yield takeEvery(mastersActionTypes.SAVE_FINANCING_TYPE_PRODUCT, updateFinancingTypeProd)
}
