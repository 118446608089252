
import { getAuth } from '../../../selectors/access/auth'
import { handleError } from '../../errors/errorManager'
import { put, call, select, takeEvery } from 'redux-saga/effects'
import { showLoader, hideLoader } from '../../../actions/common.js'
import mastersActionTypes from '../../../constants/actions/masters/masters'
// import { yesNoModal } from '../../modalsListeners/yesNoModal'
import { getDestinationCraneUOFilter } from '../../../selectors/masters/destinationCrane'
import fetchDestinationCraneMasterList from '../../../services/destinationCrane/fetchDestinationCraneMasterList'
import { fetchDestinationCraneListSuccess, fetchDestinationCraneItemSucces } from '../../../actions/masters/masters'
import { initialize } from 'redux-form'
import deleteDestinationCraneItemCall from '../../../services/destinationCrane/deleteDestinationCraneItem'
import putPostDestinationCraneItem from '../../../services/destinationCrane/putPostDestinationCraneItem'

export function * fetchDestinationCraneMaster ({ filter }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield filter = yield filter || select(getDestinationCraneUOFilter)
    const destinationCraneList = yield call(fetchDestinationCraneMasterList, auth.token, filter)
    yield put(fetchDestinationCraneListSuccess(destinationCraneList, filter))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchDestinationCraneMaster () {
  yield takeEvery(mastersActionTypes.FETCH_DESTINATION_CRANE_MASTER, fetchDestinationCraneMaster)
}

export function * fetchDestinationCraneItem ({ destinationCrane, showModal }) {
  if (destinationCrane?.organizedUnitId) {
    destinationCrane.organizedUnitId = destinationCrane.organizedUnitId.toString()
  }
  yield put(fetchDestinationCraneItemSucces(destinationCrane, showModal))
  yield put(initialize('editDestinationCrane', destinationCrane))
}

export function * watchFetchDestinationCraneItem () {
  yield takeEvery(mastersActionTypes.FETCH_DESTINATION_CRANE_ITEM, fetchDestinationCraneItem)
}

export function * deleteDestinationCraneItem ({ destinationCraneId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(deleteDestinationCraneItemCall, auth.token, destinationCraneId)
    let filter = yield select(getDestinationCraneUOFilter)
    const destinationCraneList = yield call(fetchDestinationCraneMasterList, auth.token, filter)
    yield put(fetchDestinationCraneListSuccess(destinationCraneList, filter))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDeleteDestinationCraneItem () {
  yield takeEvery(mastersActionTypes.DELETE_DESTINATION_CRANE_ITEM, deleteDestinationCraneItem)
}

export function * putDestinationCraneItem ({ destinationCrane }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(putPostDestinationCraneItem, auth.token, destinationCrane)
    let filter = yield select(getDestinationCraneUOFilter)
    const destinationCraneList = yield call(fetchDestinationCraneMasterList, auth.token, filter)
    yield put(fetchDestinationCraneListSuccess(destinationCraneList, filter))
    yield put(fetchDestinationCraneItemSucces(null, false))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchPutDestinationCraneItem () {
  yield takeEvery(mastersActionTypes.PUT_DESTINATION_CRANE_ITEM, putDestinationCraneItem)
}
