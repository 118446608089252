import { put, call, select, takeEvery } from 'redux-saga/effects'
import { initialize, reset } from 'redux-form'
import dossierActionsTypes from '../../../constants/actions/dossier/dossier'
import { dossierTypes as dossierTypesConstant } from '../../../constants/dossier/common/dossierTypes'
import { showLoader, hideLoader, openModal } from '../../../actions/common'
import messageModalActions from '../../../actions/modals/messageModal'
import { yesNoModal } from '../../modalsListeners/yesNoModal'
import { fetchSalesDossier, fetchDependantsSaleDossier } from '../fetchSalesDossier'
import { getAuth } from '../../../selectors/access/auth'
import putDossierSaleRejectService from '../../../services/dossierSale/putDossierSaleReject'
import putDossierSaleApproveService from '../../../services/dossierSale/putDossierSaleApprove'
import putDossierSaleApprovePaperworkService from '../../../services/dossierSale/putDossierSaleApprovePaperwork'
import putDossierSaleSkipChargeService from '../../../services/dossierSale/putDossierSaleSkipCharge'
import putDossierSaleSkipPaperworkService from '../../../services/dossierSale/putDossierSaleSkipPaperwork'
import putDossierSaleSkipDocumentationService from '../../../services/dossierSale/putDossierSaleSkipDocumentation'
import putDossierSkipDeliveryService from '../../../services/dossierSale/putDossierSkipDelivery'
import putDossierSaleSendToValidationService from '../../../services/dossierSale/putDossierSaleSendToValidation'
import putDossierTransferRequestService from '../../../services/dossier/put/putDossierTransferRequest'
import putDossierTransferRequestApprovedService from '../../../services/dossier/put/putDossierTransferRequestApproved'
import putApproveByRole from '../../../services/dossierSale/putApproveByRole'
import { handleError } from '../../errors/errorManager'
import { initializeDossier } from '../commonDossierSagas'
import putDossierSaleApproveFleet from '../../../services/dossierSale/putDossierFleetApprove'
import putDossierSaleApproveVoucher from '../../../services/dossierSale/putDossierVoucherApprove'
import putDossierStatusVehicleRenting from '../../../services/dossierSale/putDossierStatusVehicleRenting'
import putConfigureCampaign from '../../../services/campaigns/putConfigureCampaign'
import getAllCampaignTable from '../../../services/campaignTable/getAllCampaign'
import getCampaignByCode from '../../../services/campaignTable/getCampaignByCode'
import { fetchCampaignsTableSuccess, fetchCampaignsTableSuccessAdd } from '../../../actions/dossier/sales'
import putSkipItvService from '../../../services/dossierSale/putSkipItv'
import putDossierSaleApproveBilling from '../../../services/dossierSale/putDossierSaleApproveBilling'
import checkCanConfigureCampaign from '../../../services/campaigns/checkConfigureCampaign'
import putDossierSaleSendToBproDMS from '../../../services/dossierSale/putDossierSaleSendToBproDMS'
import putDossierStepsCompleted from '../../../services/dossierSale/putDossierStepsCompleted'
import { typeStepsCodes } from '../../../constants/dossier/common/typeStepsCodes'
import putDossierCancelTransferRequestService from '../../../services/dossier/put/putDossierCancelTransferRequest'

export function * saleApprove (pristine) {
  try {
    if (!pristine.pristine) {
      const confirmedSaveWarning = yield call(yesNoModal, 'commonDossierSaveWarning')
      if (confirmedSaveWarning) {
        const auth = yield select(getAuth)
        const salesDossierState = yield select(state => state.salesDossier)
        const confirmed = yield call(yesNoModal, 'saleDossierApprove')
        if (confirmed) {
          yield put(showLoader())
          yield call(putDossierSaleApproveService, salesDossierState.dossierId, auth.token, salesDossierState.lastAccessDate)
          yield call(initializeDossier, dossierTypesConstant.sales, salesDossierState)
        }
      }
    } else {
      const auth = yield select(getAuth)
      const salesDossierState = yield select(state => state.salesDossier)
      const confirmed = yield call(yesNoModal, 'saleDossierApprove')
      if (confirmed) {
        yield put(showLoader())
        yield call(putDossierSaleApproveService, salesDossierState.dossierId, auth.token, salesDossierState.lastAccessDate)
        yield call(initializeDossier, dossierTypesConstant.sales, salesDossierState)
      }
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          const salesDossierState = yield select(state => state.salesDossier)
          yield call(initializeDossier, dossierTypesConstant.sales, salesDossierState)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSaleApprove () {
  yield takeEvery(dossierActionsTypes.SALE_APPROVE, saleApprove)
}

export function * salePayRenting ({ dossierId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const salesDossierState = yield select(state => state.salesDossier)
    yield call(putDossierStatusVehicleRenting, dossierId, auth.token)
    yield call(initializeDossier, dossierTypesConstant.sales, salesDossierState)
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSalePayRenting () {
  yield takeEvery(dossierActionsTypes.SALE_PAY_RENTING, salePayRenting)
}

export function * saleApproveFleet (pristine) {
  try {
    if (!pristine.pristine) {
      const confirmedSaveWarning = yield call(yesNoModal, 'commonDossierSaveWarning')
      if (confirmedSaveWarning) {
        const auth = yield select(getAuth)
        const salesDossierState = yield select(state => state.salesDossier)
        const confirmed = yield call(yesNoModal, 'saleDossierApproveFleet')
        if (confirmed) {
          yield put(showLoader())
          yield call(putDossierSaleApproveFleet, salesDossierState.dossierId, auth.token, salesDossierState.lastAccessDate)
          yield call(initializeDossier, dossierTypesConstant.sales, salesDossierState)
        }
      }
    } else {
      const auth = yield select(getAuth)
      const salesDossierState = yield select(state => state.salesDossier)
      const confirmed = yield call(yesNoModal, 'saleDossierApproveFleet')
      if (confirmed) {
        yield put(showLoader())
        yield call(putDossierSaleApproveFleet, salesDossierState.dossierId, auth.token, salesDossierState.lastAccessDate)
        yield call(initializeDossier, dossierTypesConstant.sales, salesDossierState)
      }
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          const salesDossierState = yield select(state => state.salesDossier)
          yield call(initializeDossier, dossierTypesConstant.sales, salesDossierState)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSaleApproveFleet () {
  yield takeEvery(dossierActionsTypes.SALE_APPROVE_FLEET, saleApproveFleet)
}

export function * saleApprovePaperwork ({ pristine, approvalRoleCode }) {
  try {
    let confirmedSaveWarning = true
    if (!pristine) {
      confirmedSaveWarning = yield call(yesNoModal, 'commonDossierSaveWarning')
    }
    if (confirmedSaveWarning) {
      const auth = yield select(getAuth)
      const salesDossierState = yield select(state => state.salesDossier)
      const confirmed = yield call(yesNoModal, 'saleDossierApprovePaperwork')
      if (confirmed) {
        yield put(showLoader())
        yield call(putDossierSaleApprovePaperworkService, salesDossierState.dossierId, auth.token, salesDossierState.lastAccessDate, approvalRoleCode)
        yield call(initializeDossier, dossierTypesConstant.sales, salesDossierState)
      }
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          const salesDossierState = yield select(state => state.salesDossier)
          yield call(initializeDossier, dossierTypesConstant.sales, salesDossierState)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSaleApprovePaperwork () {
  yield takeEvery(dossierActionsTypes.SALE_APPROVE_PAPERWORK, saleApprovePaperwork)
}

export function * saleReject ({ comment, resolve, reject, pristine }) {
  try {
    if (!pristine) {
      const confirmedSaveWarning = yield call(yesNoModal, 'commonDossierSaveWarning')
      if (confirmedSaveWarning) {
        const auth = yield select(getAuth)
        const salesDossierState = yield select(state => state.salesDossier)
        const confirmed = yield call(yesNoModal, 'saleDossierReject')
        if (confirmed) {
          yield put(showLoader())
          yield call(putDossierSaleRejectService, salesDossierState.dossierId, comment, auth.token, salesDossierState.lastAccessDate)
          yield call(fetchSalesDossier, { dossierId: salesDossierState.dossierId })
          const updatedSalesDossier = yield select(state => state.salesDossier)
          yield put(initialize('salesDossier', updatedSalesDossier))
        }
      }
    } else {
      const auth = yield select(getAuth)
      const salesDossierState = yield select(state => state.salesDossier)
      const confirmed = yield call(yesNoModal, 'saleDossierReject')
      if (confirmed) {
        yield put(showLoader())
        yield call(putDossierSaleRejectService, salesDossierState.dossierId, comment, auth.token, salesDossierState.lastAccessDate)
        yield call(fetchSalesDossier, { dossierId: salesDossierState.dossierId })
        yield call(fetchDependantsSaleDossier, { dossierId: salesDossierState.dossierId })
        const updatedSalesDossier = yield select(state => state.salesDossier)
        yield put(initialize('salesDossier', updatedSalesDossier))
      }
    }
    if (resolve) yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          const salesDossierState = yield select(state => state.salesDossier)
          yield call(initializeDossier, dossierTypesConstant.sales, salesDossierState)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
      if (reject) yield call(reject, customHandlerError)
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSaleReject () {
  yield takeEvery(dossierActionsTypes.SALE_REJECT, saleReject)
}

export function * saleSkipCharge (pristine) {
  try {
    if (!pristine.pristine) {
      const confirmedSaveWarning = yield call(yesNoModal, 'commonDossierSaveWarning')
      if (confirmedSaveWarning) {
        const auth = yield select(getAuth)
        const salesDossierState = yield select(state => state.salesDossier)
        const confirmed = yield call(yesNoModal, 'saleDossierSkipCharge')
        if (confirmed) {
          yield put(showLoader())
          yield call(putDossierSaleSkipChargeService, salesDossierState.dossierId, auth.token, salesDossierState.lastAccessDate)
          yield call(fetchSalesDossier, { dossierId: salesDossierState.dossierId })
          const updatedSalesDossier = yield select(state => state.salesDossier)
          yield put(initialize('salesDossier', updatedSalesDossier))
        }
      }
    } else {
      const auth = yield select(getAuth)
      const salesDossierState = yield select(state => state.salesDossier)
      const confirmed = yield call(yesNoModal, 'saleDossierSkipCharge')
      if (confirmed) {
        yield put(showLoader())
        yield call(putDossierSaleSkipChargeService, salesDossierState.dossierId, auth.token, salesDossierState.lastAccessDate)
        yield call(fetchSalesDossier, { dossierId: salesDossierState.dossierId })
        const updatedSalesDossier = yield select(state => state.salesDossier)
        yield put(initialize('salesDossier', updatedSalesDossier))
      }
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          const salesDossierState = yield select(state => state.salesDossier)
          yield call(initializeDossier, dossierTypesConstant.sales, salesDossierState)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSaleSkipCharge () {
  yield takeEvery(dossierActionsTypes.SALE_SKIP_CHARGE, saleSkipCharge)
}

export function * saleSkipDocument (pristine) {
  try {
    if (!pristine.pristine) {
      const confirmedSaveWarning = yield call(yesNoModal, 'commonDossierSaveWarning')
      if (confirmedSaveWarning) {
        const auth = yield select(getAuth)
        const salesDossierState = yield select(state => state.salesDossier)
        const confirmed = yield call(yesNoModal, 'saleDossierSkipDocument')
        if (confirmed) {
          yield put(showLoader())
          yield call(putDossierSaleSkipDocumentationService, salesDossierState.dossierId, auth.token, salesDossierState.lastAccessDate)
          yield call(fetchSalesDossier, { dossierId: salesDossierState.dossierId })
          const updatedSalesDossier = yield select(state => state.salesDossier)
          yield put(initialize('salesDossier', updatedSalesDossier))
        }
      }
    } else {
      const auth = yield select(getAuth)
      const salesDossierState = yield select(state => state.salesDossier)
      const confirmed = yield call(yesNoModal, 'saleDossierSkipDocument')
      if (confirmed) {
        yield put(showLoader())
        yield call(putDossierSaleSkipDocumentationService, salesDossierState.dossierId, auth.token, salesDossierState.lastAccessDate)
        yield call(fetchSalesDossier, { dossierId: salesDossierState.dossierId })
        const updatedSalesDossier = yield select(state => state.salesDossier)
        yield put(initialize('salesDossier', updatedSalesDossier))
      }
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          const salesDossierState = yield select(state => state.salesDossier)
          yield call(initializeDossier, dossierTypesConstant.sales, salesDossierState)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSaleSkipDocument () {
  yield takeEvery(dossierActionsTypes.SALE_SKIP_DOCUMENT, saleSkipDocument)
}

export function * saleSkipPaperwork (pristine) {
  try {
    if (!pristine.pristine) {
      const confirmedSaveWarning = yield call(yesNoModal, 'commonDossierSaveWarning')
      if (confirmedSaveWarning) {
        const auth = yield select(getAuth)
        const salesDossierState = yield select(state => state.salesDossier)
        const confirmed = yield call(yesNoModal, 'saleDossierSkipPaperwork')
        if (confirmed) {
          yield put(showLoader())
          yield call(putDossierSaleSkipPaperworkService, salesDossierState.dossierId, auth.token, salesDossierState.lastAccessDate)
          yield call(fetchSalesDossier, { dossierId: salesDossierState.dossierId })
          const updatedSalesDossier = yield select(state => state.salesDossier)
          yield put(initialize('salesDossier', updatedSalesDossier))
        }
      }
    } else {
      const auth = yield select(getAuth)
      const salesDossierState = yield select(state => state.salesDossier)
      const confirmed = yield call(yesNoModal, 'saleDossierSkipPaperwork')
      if (confirmed) {
        yield put(showLoader())
        yield call(putDossierSaleSkipPaperworkService, salesDossierState.dossierId, auth.token, salesDossierState.lastAccessDate)
        yield call(fetchSalesDossier, { dossierId: salesDossierState.dossierId })
        const updatedSalesDossier = yield select(state => state.salesDossier)
        yield put(initialize('salesDossier', updatedSalesDossier))
      }
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          const salesDossierState = yield select(state => state.salesDossier)
          yield call(initializeDossier, dossierTypesConstant.sales, salesDossierState)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSaleSkipPaperwork () {
  yield takeEvery(dossierActionsTypes.SALE_SKIP_PAPERWORK, saleSkipPaperwork)
}

export function * saleSkipDelivery (pristine) {
  try {
    if (!pristine.pristine) {
      const confirmedSaveWarning = yield call(yesNoModal, 'commonDossierSaveWarning')
      if (confirmedSaveWarning) {
        const auth = yield select(getAuth)
        const salesDossierState = yield select(state => state.salesDossier)
        const confirmed = yield call(yesNoModal, 'saleDossierSkipDelivery')
        if (confirmed) {
          yield put(showLoader())
          yield call(putDossierSkipDeliveryService, salesDossierState.dossierId, auth.token, salesDossierState.lastAccessDate)
          yield call(fetchSalesDossier, { dossierId: salesDossierState.dossierId })
          const updatedSalesDossier = yield select(state => state.salesDossier)
          yield put(initialize('salesDossier', updatedSalesDossier))
        }
      }
    } else {
      const auth = yield select(getAuth)
      const salesDossierState = yield select(state => state.salesDossier)
      const confirmed = yield call(yesNoModal, 'saleDossierSkipDelivery')
      if (confirmed) {
        yield put(showLoader())
        yield call(putDossierSkipDeliveryService, salesDossierState.dossierId, auth.token, salesDossierState.lastAccessDate)
        yield call(fetchSalesDossier, { dossierId: salesDossierState.dossierId })
        const updatedSalesDossier = yield select(state => state.salesDossier)
        yield put(initialize('salesDossier', updatedSalesDossier))
      }
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          const salesDossierState = yield select(state => state.salesDossier)
          yield call(initializeDossier, dossierTypesConstant.sales, salesDossierState)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSaleSkipDelivery () {
  yield takeEvery(dossierActionsTypes.SALE_SKIP_DELIVERY, saleSkipDelivery)
}

export function * saleSendApprove (pristine) {
  try {
    if (!pristine.pristine) {
      const confirmedSaveWarning = yield call(yesNoModal, 'commonDossierSaveWarning')
      if (confirmedSaveWarning) {
        const auth = yield select(getAuth)
        const salesDossierState = yield select(state => state.salesDossier)
        const confirmed = yield call(yesNoModal, 'saleDossierSendApprove')
        if (confirmed) {
          yield put(showLoader())
          yield call(putDossierSaleSendToValidationService, salesDossierState.dossierId, auth.token, salesDossierState.lastAccessDate)
          yield call(fetchSalesDossier, { dossierId: salesDossierState.dossierId })
          const updatedSalesDossier = yield select(state => state.salesDossier)
          yield put(initialize('salesDossier', updatedSalesDossier))
        }
      }
    } else {
      const auth = yield select(getAuth)
      const salesDossierState = yield select(state => state.salesDossier)
      const confirmed = yield call(yesNoModal, 'saleDossierSendApprove')
      if (confirmed) {
        yield put(showLoader())
        yield call(putDossierSaleSendToValidationService, salesDossierState.dossierId, auth.token, salesDossierState.lastAccessDate)
        yield call(fetchSalesDossier, { dossierId: salesDossierState.dossierId })
        const updatedSalesDossier = yield select(state => state.salesDossier)
        yield put(initialize('salesDossier', updatedSalesDossier))
      }
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          const salesDossierState = yield select(state => state.salesDossier)
          yield call(initializeDossier, dossierTypesConstant.sales, salesDossierState)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSaleSendApprove () {
  yield takeEvery(dossierActionsTypes.SALE_SEND_APPROVE, saleSendApprove)
}

export function * saleWarningSave ({ reset, router, nextLocation, pristine }) {
  if (!pristine) {
    const confirmedSaveWarning = yield call(yesNoModal, 'commonDossierSaveWarning')
    if (confirmedSaveWarning) {
      const promise = new Promise((resolve, reject) => {
        reset('salesDossier')
        resolve()
      })
      promise.then(() => {
        router.replace(nextLocation)
      })
    }
  }
}

export function * watchSaleWarningSave () {
  yield takeEvery(dossierActionsTypes.SALE_WARNING_SAVE, saleWarningSave)
}

export function * saleApproveByRole ({ pristine, approvalRoleCode, hideConfirmModal }) {
  try {
    if (!pristine) {
      const confirmedSaveWarning = yield call(yesNoModal, 'commonDossierSaveWarning')
      if (confirmedSaveWarning) {
        const auth = yield select(getAuth)
        const salesDossierState = yield select(state => state.salesDossier)
        const confirmed = hideConfirmModal ? true : yield call(yesNoModal, 'saleDossierApproveByRole')
        if (confirmed) {
          yield put(showLoader())
          yield call(putApproveByRole, salesDossierState.dossierId, approvalRoleCode, auth.token, salesDossierState.lastAccessDate)
          yield call(fetchSalesDossier, { dossierId: salesDossierState.dossierId })
          const updatedSalesDossier = yield select(state => state.salesDossier)
          yield put(initialize('salesDossier', updatedSalesDossier))
        }
      }
    } else {
      const auth = yield select(getAuth)
      const salesDossierState = yield select(state => state.salesDossier)
      const confirmed = hideConfirmModal ? true : yield call(yesNoModal, 'saleDossierApproveByRole')
      if (confirmed) {
        yield put(showLoader())
        yield call(putApproveByRole, salesDossierState.dossierId, approvalRoleCode, auth.token, salesDossierState.lastAccessDate)
        yield call(fetchSalesDossier, { dossierId: salesDossierState.dossierId })
        const updatedSalesDossier = yield select(state => state.salesDossier)
        yield put(initialize('salesDossier', updatedSalesDossier))
      }
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          const salesDossierState = yield select(state => state.salesDossier)
          yield call(initializeDossier, dossierTypesConstant.sales, salesDossierState)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSaleApproveByRole () {
  yield takeEvery(dossierActionsTypes.SALE_APPROVE_BY_ROLE, saleApproveByRole)
}

export function * saleApproveVoucher (pristine) {
  try {
    if (!pristine.pristine) {
      const confirmedSaveWarning = yield call(yesNoModal, 'commonDossierSaveWarning')
      if (confirmedSaveWarning) {
        const auth = yield select(getAuth)
        const salesDossierState = yield select(state => state.salesDossier)
        const confirmed = yield call(yesNoModal, 'saleDossierApproveVoucher')
        if (confirmed) {
          yield put(showLoader())
          yield call(putDossierSaleApproveVoucher, salesDossierState.dossierId, auth.token, salesDossierState.lastAccessDate)
          yield call(initializeDossier, dossierTypesConstant.sales, salesDossierState)
        }
      }
    } else {
      const auth = yield select(getAuth)
      const salesDossierState = yield select(state => state.salesDossier)
      const confirmed = yield call(yesNoModal, 'saleDossierApproveVoucher')
      if (confirmed) {
        yield put(showLoader())
        yield call(putDossierSaleApproveVoucher, salesDossierState.dossierId, auth.token, salesDossierState.lastAccessDate)
        yield call(initializeDossier, dossierTypesConstant.sales, salesDossierState)
      }
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          const salesDossierState = yield select(state => state.salesDossier)
          yield call(initializeDossier, dossierTypesConstant.sales, salesDossierState)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSaleApproveVoucher () {
  yield takeEvery(dossierActionsTypes.SALE_APPROVE_VOUCHER, saleApproveVoucher)
}

export function * configureCampaign ({ openConfigureCampaignEditionFn, dossierId, pristine }) {
  try {
    let confirmedSaveWarning = true
    if (!pristine) {
      confirmedSaveWarning = yield call(yesNoModal, 'commonDossierSaveWarning')
    }
    if (confirmedSaveWarning) {
      yield put(showLoader())
      const auth = yield select(getAuth)
      const dossier = yield select(state => state.salesDossier)
      const checkCampaignData = yield call(checkCanConfigureCampaign, auth.token, dossierId)

      if (checkCampaignData.entity && checkCampaignData.orderDate && checkCampaignData.saleType && checkCampaignData.vehicle) {
        const campaignSubtype = yield call(getAllCampaignTable, auth.token, dossierId)
        yield put(fetchCampaignsTableSuccess({ ...dossier, campaignSubtype }))
        yield call(openConfigureCampaignEditionFn)
      } else {
        yield put(hideLoader())
        yield call(yesNoModal, 'canNotConfigureCampaigns', null, null, null, checkCampaignData)
      }
    }
  } catch (error) {
  } finally {
    yield put(hideLoader())
  }
}

export function * watchConfigureCampaign () {
  yield takeEvery(dossierActionsTypes.CONFIGURE_CAMPAIGN, configureCampaign)
}

export function * saveconfigureCampaign ({ campaigns, closeModalFunc, dossierid }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(putConfigureCampaign, auth.token, campaigns, dossierid)
    yield put(messageModalActions.openSuccessMessageModal('MESSAGES.POST_TABLE_ITEM_SUCCESS'))
    yield call(fetchDependantsSaleDossier, { dossierId: dossierid })
    yield call(closeModalFunc)
  } catch (error) {
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSaveconfigureCampaign () {
  yield takeEvery(dossierActionsTypes.CONFIGURE_CAMPAIGN_SAVE, saveconfigureCampaign)
}

export function * searchCampaignByCode ({ campaignCode, dossierId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const dossier = yield select(state => state.salesDossier)
    const campaignSubtype = yield call(getCampaignByCode, auth.token, campaignCode, dossierId)
    if (campaignSubtype) {
      yield put(fetchCampaignsTableSuccessAdd({ ...dossier, campaignSubtype }))
      yield put(hideLoader())
      yield call(yesNoModal, 'modalCampaignNoApplied')
    } else {
      yield put(messageModalActions.openErrorMessageModal('DOSSIER_COMPONENTS.CAMPAIGNS.CAMPAIGNS_MODAL_CONFIGURE.ERROR_SEARCH_VEHICLE'))
    }
  } catch (error) {
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSearchCampaignByCode () {
  yield takeEvery(dossierActionsTypes.CONFIGURE_CAMPAIGN_SEARCHCODE, searchCampaignByCode)
}

export function * hasOptionals ({ hasOptionals, appliedCampaigns, campaignId, value, resolve }) {
  try {
    yield put(showLoader())
    let confirmedSaveWarning
    if (hasOptionals === true && value === true) {
      yield put(hideLoader())
      confirmedSaveWarning = yield call(yesNoModal, 'modalCampaignOptionals')
    }
    if (confirmedSaveWarning === false) {
      appliedCampaigns['cam' + campaignId] = false
      yield put(initialize('campaign_configure_modal', appliedCampaigns))
    } else {
      appliedCampaigns['cam' + campaignId] = value
      yield put(initialize('campaign_configure_modal', appliedCampaigns))
    }
    if (resolve) resolve(confirmedSaveWarning)
  } catch (error) {
  } finally {
    yield put(hideLoader())
  }
}

export function * watchHasOptionals () {
  yield takeEvery(dossierActionsTypes.CONFIGURE_CAMPAIGN_HASOPTIONALS, hasOptionals)
}

export function * skipItv ({ dossierId }) {
  try {
    let confirm = yield call(yesNoModal, 'skipItvModal')
    // const salesDossierState = yield select(state => state.salesDossier)
    if (confirm) {
      yield put(showLoader())
      const auth = yield select(getAuth)
      yield call(putSkipItvService, dossierId, auth.token)
      yield call(fetchSalesDossier, { dossierId: dossierId })
      yield put(hideLoader())
    }
  } catch (error) {
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSkipeItv () {
  yield takeEvery(dossierActionsTypes.SKIP_ITV, skipItv)
}

export function * saleApproveBilling (pristine) {
  try {
    if (!pristine.pristine) {
      const confirmedSaveWarning = yield call(yesNoModal, 'commonDossierSaveWarning')
      if (confirmedSaveWarning) {
        const auth = yield select(getAuth)
        const salesDossierState = yield select(state => state.salesDossier)
        const confirmed = yield call(yesNoModal, 'saleDossierApproveBilling')
        if (confirmed) {
          yield put(showLoader())
          yield call(putDossierSaleApproveBilling, salesDossierState.dossierId, auth.token)
          yield call(initializeDossier, dossierTypesConstant.sales, salesDossierState)
        }
      }
    } else {
      const auth = yield select(getAuth)
      const salesDossierState = yield select(state => state.salesDossier)
      const confirmed = yield call(yesNoModal, 'saleDossierApproveBilling')
      if (confirmed) {
        yield put(showLoader())
        yield call(putDossierSaleApproveBilling, salesDossierState.dossierId, auth.token)
        yield call(initializeDossier, dossierTypesConstant.sales, salesDossierState)
      }
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          const salesDossierState = yield select(state => state.salesDossier)
          yield call(initializeDossier, dossierTypesConstant.sales, salesDossierState)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSaleApproveBilling () {
  yield takeEvery(dossierActionsTypes.SALE_APPROVE_BILLING, saleApproveBilling)
}

function * performTransferRequestCheckingDoubleTransfer (service) {
  const auth = yield select(getAuth)
  const salesDossierState = yield select(state => state.salesDossier)
  let modalToShow = 'saleDossierTransferRequest'
  if (salesDossierState.salesComponent.doubleTransferCheck) {
    modalToShow = 'dossierDoubleTransferConfirmation'
  }
  const confirmed = yield call(yesNoModal, modalToShow)
  if (confirmed) {
    yield put(showLoader())
    yield call(service, salesDossierState.dossierId, auth.token, salesDossierState.lastAccessDate)
    yield call(fetchSalesDossier, { dossierId: salesDossierState.dossierId })
    const updatedSalesDossier = yield select(state => state.salesDossier)
    yield put(initialize('salesDossier', updatedSalesDossier))
  }
}

export function * saleTransferRequest (pristine) {
  try {
    if (!pristine.pristine) {
      const confirmedSaveWarning = yield call(yesNoModal, 'commonDossierSaveWarning')
      if (confirmedSaveWarning) {
        yield call(performTransferRequestCheckingDoubleTransfer, putDossierTransferRequestService)
      }
    } else {
      yield call(performTransferRequestCheckingDoubleTransfer, putDossierTransferRequestService)
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          const salesDossierState = yield select(state => state.salesDossier)
          yield call(initializeDossier, dossierTypesConstant.sales, salesDossierState)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSaleTransferRequest () {
  yield takeEvery(dossierActionsTypes.SALE_TRANSFER_REQUEST, saleTransferRequest)
}

export function * saleTransferRequestApprove (pristine) {
  try {
    if (!pristine.pristine) {
      const confirmedSaveWarning = yield call(yesNoModal, 'commonDossierSaveWarning')
      if (confirmedSaveWarning) {
        const auth = yield select(getAuth)
        const salesDossierState = yield select(state => state.salesDossier)
        const confirmed = yield call(yesNoModal, 'saleDossierTransferRequestApprove')
        if (confirmed) {
          yield put(showLoader())
          yield call(putDossierTransferRequestApprovedService, salesDossierState.dossierId, auth.token, salesDossierState.lastAccessDate)
          yield call(fetchSalesDossier, { dossierId: salesDossierState.dossierId })
          const updatedSalesDossier = yield select(state => state.salesDossier)
          yield put(initialize('salesDossier', updatedSalesDossier))
        }
      }
    } else {
      const auth = yield select(getAuth)
      const salesDossierState = yield select(state => state.salesDossier)
      const confirmed = yield call(yesNoModal, 'saleDossierTransferRequestApprove')
      if (confirmed) {
        yield put(showLoader())
        yield call(putDossierTransferRequestApprovedService, salesDossierState.dossierId, auth.token, salesDossierState.lastAccessDate)
        yield call(fetchSalesDossier, { dossierId: salesDossierState.dossierId })
        const updatedSalesDossier = yield select(state => state.salesDossier)
        yield put(initialize('salesDossier', updatedSalesDossier))
      }
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          const salesDossierState = yield select(state => state.salesDossier)
          yield call(initializeDossier, dossierTypesConstant.sales, salesDossierState)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSaleTransferRequestApprove () {
  yield takeEvery(dossierActionsTypes.SALE_TRANSFER_REQUEST_APPROVED, saleTransferRequestApprove)
}

export function * saleSendToBproDms (pristine) {
  try {
    let confirmedSaveWarning = true
    if (!pristine.pristine) {
      confirmedSaveWarning = yield call(yesNoModal, 'commonDossierSaveWarning')
    }
    if (confirmedSaveWarning) {
      const auth = yield select(getAuth)
      const salesDossierState = yield select(state => state.salesDossier)
      const confirmed = yield call(yesNoModal, 'saleSendToBproDms')
      if (confirmed) {
        yield put(showLoader())
        var response = yield call(putDossierSaleSendToBproDMS, salesDossierState.dossierId, auth.token, salesDossierState.lastAccessDate)
        yield put(openModal('sentToDMSSuccessfully', null, null, null, null, [{ id: '##NUMBER##', value: response?.result?.externalOrderId }], null))
        yield call(fetchSalesDossier, { dossierId: salesDossierState.dossierId })
        const updatedSalesDossier = yield select(state => state.salesDossier)
        yield put(initialize('salesDossier', updatedSalesDossier))
        yield put(openModal('sentToStepsDMSSuccessfully'))
      }
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          const salesDossierState = yield select(state => state.salesDossier)
          yield call(initializeDossier, dossierTypesConstant.sales, salesDossierState)
        }
      } else {
        yield put(openModal('errorSentToDMS'))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSaleSendToBproDms () {
  yield takeEvery(dossierActionsTypes.SEND_TO_BPRO_DMS, saleSendToBproDms)
}

export function * saleRequestInvoiceToDMS (pristine) {
  yield call(RequestStepToDMS, pristine, 'saleRequestInvoiceToDMS', typeStepsCodes.invoiceReady)
}

export function * watchRequestInvoiceToDMS () {
  yield takeEvery(dossierActionsTypes.REQUEST_INVOICE_TO_DMS, saleRequestInvoiceToDMS)
}

export function * saleRequestDeliveryeToDMS (pristine) {
  yield call(RequestStepToDMS, pristine, 'saleRequestDeliveryToDMS', typeStepsCodes.printDeliveryReady)
}

export function * watchRequestDeliveryToDMS () {
  yield takeEvery(dossierActionsTypes.REQUEST_DELIVERY_TO_DMS, saleRequestDeliveryeToDMS)
}

export function * saleRequestOutboundToDMS (pristine) {
  yield call(RequestStepToDMS, pristine, 'saleRequestOutboundToDMS', typeStepsCodes.goOutVehicleReady)
}

export function * watchRequestOutboundToDMS () {
  yield takeEvery(dossierActionsTypes.REQUEST_OUTBOUND_TO_DMS, saleRequestOutboundToDMS)
}

function * RequestStepToDMS (pristine, modalMessage, typeStepsCode) {
  try {
    let confirmedSaveWarning = true
    if (!pristine.pristine) {
      confirmedSaveWarning = yield call(yesNoModal, 'commonDossierSaveWarning')
    }
    if (confirmedSaveWarning) {
      const auth = yield select(getAuth)
      const salesDossierState = yield select(state => state.salesDossier)
      const confirmed = yield call(yesNoModal, modalMessage)
      if (confirmed) {
        yield put(showLoader())
        yield call(putDossierStepsCompleted, salesDossierState.dossierId, auth.token, typeStepsCode)
        yield call(fetchSalesDossier, { dossierId: salesDossierState.dossierId })
        const updatedSalesDossier = yield select(state => state.salesDossier)
        yield put(initialize('salesDossier', updatedSalesDossier))
        yield put(openModal('sentToStepsDMSSuccessfully'))
      }
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          const salesDossierState = yield select(state => state.salesDossier)
          yield call(initializeDossier, dossierTypesConstant.sales, salesDossierState)
        }
      } else {
        yield put(openModal('errorSentToDMS'))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * saleTransferReject ({ comment, resolve, reject, pristine }) {
  try {
    if (!pristine) {
      const confirmedSaveWarning = yield call(yesNoModal, 'commonDossierSaveWarning')
      if (confirmedSaveWarning) {
        yield put(showLoader())
        const auth = yield select(getAuth)
        const salesDossierState = yield select(state => state.salesDossier)
        yield call(putDossierCancelTransferRequestService, salesDossierState.dossierId, auth.token, salesDossierState.lastAccessDate, comment)
        yield call(fetchSalesDossier, { dossierId: salesDossierState.dossierId })
        const updatedSalesDossier = yield select(state => state.salesDossier)
        yield put(initialize('salesDossier', updatedSalesDossier))
      }
    } else {
      yield put(showLoader())
      const auth = yield select(getAuth)
      const salesDossierState = yield select(state => state.salesDossier)
      yield call(putDossierCancelTransferRequestService, salesDossierState.dossierId, auth.token, salesDossierState.lastAccessDate, comment)
      yield call(fetchSalesDossier, { dossierId: salesDossierState.dossierId })
      const updatedSalesDossier = yield select(state => state.salesDossier)
      yield put(initialize('salesDossier', updatedSalesDossier))
    }
    if (resolve) yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          const salesDossierState = yield select(state => state.salesDossier)
          yield call(initializeDossier, dossierTypesConstant.sales, salesDossierState)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
      if (reject) yield call(reject, customHandlerError)
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSaleTransferReject () {
  yield takeEvery(dossierActionsTypes.SALE_TRANSFER_REJECT, saleTransferReject)
}
