
import React, { PureComponent } from 'react'
import { Button, Modal, Col, Row, FormGroup } from 'react-bootstrap'
import { Field, reduxForm } from 'redux-form'
import InputText from '../commons/form/InputText'

class DirectionOUModal extends PureComponent {
  submitDirectionUO (values) {
    this.props.actions.saveDirectionUOModal(values, this.props.directionUOId)
  }

  render () {
    const { t, showDirectionUOModal, handleSubmit } = this.props
    const tKey = 'MASTERS.DIRECTION_OU_MASTERS.'
    return (
      <Modal show={showDirectionUOModal} onHide={this.props.actions.closeDirectionUOModal} className="languages-modal" backdrop={'static'}>
        <Modal.Header closeButton>
          <i className="ico-users" /><Modal.Title>{t(`${tKey}DIRECTION_OU_TITLE`)}</Modal.Title>
        </Modal.Header>
        <form autoComplete="off" onSubmit={ handleSubmit((values) => this.submitDirectionUO(values))}>
          <Modal.Body>
            <FormGroup>
              <Row className="inputs-wrapper flexRow">
                <Col sm={8} className="custom-width-col">
                  <h2>{t('MASTERS.DESCRIPTION')}</h2>
                  <Field
                    name="description"
                    placeholder={t('MASTERS.DESCRIPTION')}
                    component={InputText}
                  />
                </Col>
                <Col sm={4} className="custom-width-col">
                  <h2>{t(`${tKey}COUNTRY`)}</h2>
                  <Field
                    name="country"
                    placeholder={t(`${tKey}COUNTRY`)}
                    component={InputText}
                  />
                </Col>
                <Col sm={8} className="custom-width-col">
                  <h2>{t(`${tKey}ADDRESS`)}</h2>
                  <Field
                    name="address"
                    placeholder={t(`${tKey}ADDRESS`)}
                    component={InputText}
                  />
                </Col>
                <Col sm={4} className="custom-width-col">
                  <h2>{t(`${tKey}CP`)}</h2>
                  <Field
                    name="cp"
                    placeholder={t(`${tKey}CP`)}
                    component={InputText}
                  />
                </Col>
                <Col sm={4} className="custom-width-col">
                  <h2>{t(`${tKey}CITY`)}</h2>
                  <Field
                    name="city"
                    placeholder={t(`${tKey}CITY`)}
                    component={InputText}
                  />
                </Col>
                <Col sm={4} className="custom-width-col">
                  <h2>{t(`${tKey}STATE`)}</h2>
                  <Field
                    name="state"
                    placeholder={t(`${tKey}STATE`)}
                    component={InputText}
                  />
                </Col>
                <Col sm={4} className="custom-width-col">
                  <h2>{t(`${tKey}PHONE`)}</h2>
                  <Field
                    name="phone"
                    placeholder={t(`${tKey}PHONE`)}
                    component={InputText}
                  />
                </Col>
              </Row>
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <Col sm={6} className="btn-wrapper" smPush={6}>
              <Button type="submit" className="btn-standard" disabled={this.props.pristine}>
                {t('MASTERS.SAVE')}
              </Button>
            </Col>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

const validate = (values, props) => {
  const errors = {}
  if (values) {
    const tKey = 'MASTERS.DIRECTION_OU_MASTERS.VALIDATION.'
    if (!values.description) {
      errors.description = props.t(`${tKey}DESCRIPTION`)
    }
    if (!values.country) {
      errors.country = props.t(`${tKey}COUNTRY`)
    }
  }

  return errors
}

export default reduxForm({
  form: 'editDirectionUO',
  validate
})(DirectionOUModal)
