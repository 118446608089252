import React, { PureComponent } from 'react'
import { Panel, Col, Table, Row } from 'react-bootstrap'
import SmallLoaderPage from '../../../containers/commons/SmallLoaderPage'
import { compareObjects } from '../../../util/utils'
import { getFieldsPermisions } from '../../../util/permisionFunctions'
import ServiceRow from './services/ServiceRow'
import ServiceObservationsModal from '../../dossiers/common/services/ServiceObservationsModal'
import CustomFields from '../../commons/customFields/CustomFields'

class Services extends PureComponent {
  constructor () {
    super()
    this.state = {
      open: false,
      showModal: false,
      service: null,
      showAddModal: false,
      fieldsConfiguration: { }
    }
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (this.props.dossier && this.props.dossier.dossierId) {
      if (!compareObjects(prevProps.componentConfiguration || {}, this.props.componentConfiguration, true)) {
        this.initializePermisions(this.props)
      }
      if (this.props.open) {
        this.setState({ open: true })
      }
    }
    return prevProps
  }

  componentDidMount () {
    this.initializePermisions(this.props)
  }

  componentDidUpdate () {
    // don't remove use for getsnapshot
  }

  initializePermisions (props) {
    const fieldKeys = Object.keys(this.state.fieldsConfiguration)
    const newState = getFieldsPermisions(props.componentConfiguration.fieldsConfiguration, fieldKeys, props.componentConfiguration.permission)
    this.setState({ fieldsConfiguration: newState.fieldsConfiguration })
  }

  toggleComponent () {
    if (!this.state.open) {
      this.props.actions.fetchSalesServices(this.props.dossier.dossierId, true)
    } else {
      this.props.actions.setActiveSection(false)
    }
    this.setState({ open: !this.state.open })
  }

  showServiceObservationModal (show, service) {
    this.setState({ showModal: show, service: service })
  }

  render () {
    const {
      t, readOnlyFields, isPurchase, dossierType, services = [], customFields,
      dossier: { dossierId, organizedUnitId }
    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.SALE_SERVICES.'
    return (
      <div className="service-panel dossier-panel">
        <ServiceObservationsModal
          t={t}
          showModal={this.state.showModal}
          service={this.state.service}
          actions={{ closeServiceObservationModal: () => this.showServiceObservationModal(false, null) }}
        />
        <div className="header-panel" onClick={() => this.toggleComponent()}>
          <div className="name-wrapper">
            <i className="ico-accessories" />
            <h4>{t(`${tKey}TITLE_PANEL`)}</h4>
          </div>
          <i className={this.state.open ? 'ico-arrow-black-up' : 'ico-arrow-black-down'} />
        </div>
        <Panel expanded={this.state.open} onToggle={() => { }}>
          <Panel.Collapse>
            <Row>
              <Col sm={12}>
                <Table className="editable-table" hover responsive>
                  <thead>
                    <tr>
                      <th>{t(`${tKey}DOSSIER_NUMBER`)}</th>
                      <th>{t(`${tKey}SERVICE_SUBTYPE`)}</th>
                      <th>{t(`${tKey}DOSSIER_STAGE`)}</th>
                      <th>{t(`${tKey}ENTITY`)}</th>
                      <th>{t(`${tKey}DESTINY`)}</th>
                      <th>{t(`${tKey}ORDER_DATE`)}</th>
                      <th>{t(`${tKey}REQUIRED_DATE`)}</th>
                      <th>{t(`${tKey}AVAILABILITY_DATE`)}</th>
                      <th>{t(`${tKey}CONFIRMED_DATE`)}</th>
                      <th>{t(`${tKey}OBSERVATIONS`)}</th>
                      {isPurchase === undefined && (
                        <th>{t(`${tKey}PURCHASE_SERVICE`)}</th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {
                      services.map((service, idx) => {
                        return (
                          <ServiceRow
                            t={t}
                            tKey={tKey}
                            service={service}
                            key={idx}
                            dossierId={dossierId}
                            organizedUnitId={organizedUnitId}
                            actions={ {
                              showServiceObservationModal: () => this.showServiceObservationModal(true, service)
                            } }
                            dossierType={dossierType}
                            readOnlyFields={readOnlyFields}
                            isPurchase={isPurchase}
                          />
                        )
                      })
                    }
                  </tbody>
                </Table>
              </Col>
            </Row>
            <CustomFields customFields={customFields} readOnlyFields={true} />
          </Panel.Collapse>
        </Panel>
        <SmallLoaderPage />
      </div>
    )
  }
}
export default Services
