import walletActionTypes from '../../constants/actions/reports/walletReport'

function initialState () {
  return {
    walletList: [],
    pagesCount: 0,
    walletCount: 0,
    filter: {
      page: 1,
      orderBy: '',
      isDeleted: null,
      pageSize: 10
    }
  }
}

function fetchWalletReportSuccess (state, { walletList, filter }) {
  return {
    ...state,
    walletList: walletList.walletList,
    walletCount: walletList.walletCount,
    pagesCount: walletList.pagesCount,
    filter
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case walletActionTypes.FETCH_WALLET_REPORT_SUCCESS:
      return fetchWalletReportSuccess(state, action)
    default:
      return state
  }
}
