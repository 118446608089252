
import React, { PureComponent } from 'react'
import { Button, Modal, Tooltip, OverlayTrigger, Row, Glyphicon, Form } from 'react-bootstrap'
import { reduxForm, Field } from 'redux-form'
import InputSelect from '../../../commons/form/InputSelect'
import InputText from '../../../commons/form/InputText'
import InputCheckbox from '../../../commons/form/InputCheckBox'
import { multipleNormalize, toUpperCase, trimAll } from '../../../../util/normalizeFunctions'
import { validateVinChassis, validateLicensePlate as validateLicensePlateFunciton, validateVehicleCreationModal as validateVehicle } from '../../../../util/validationFunctions'

class VehicleCreationModal extends PureComponent {
  constructor () {
    super()
    this.state = {
      disabledValidateLicensePlate: false
    }
    this.validateLicensePlate = this.validateLicensePlate.bind(this)
    this.vinChassisValidation = this.vinChassisValidation.bind(this)
  }

  componentDidMount (prevProps, prevState) {
  }

  componentDidUpdate (prevProps, nextProps, snapshot) {
  }

  vinChassisValidation (value, allValues, props) {
    const result = validateVinChassis(value, props)
    return result === Object(result) ? undefined : result
  }

  validateLicensePlate (value) {
    return validateLicensePlateFunciton(value, this.props.validateLicensePlateCountry || false, this.props.t('GLOBAL.FORMS.INVALID_STRING'))
  }

  changeBoolLicensePlate (value) {
    this.setState({ disabledValidateLicensePlate: value })
  }

  submitCreation (values) {
    this.props.actions.createVehicleCreationModal(values)
  }

  CloseModal () {
    this.props.initialize({})
    this.props.actions.closeVehicleCreationModal()
  }

  render () {
    const {
      t, show, handleSubmit,
      combos: { brandCombo, fuelCombo }
    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.VEHICLE.'
    const editTooltip = (<Tooltip id="editTooltip">{t(`${tKey}TOOLTIP_VALIDATE_LICENSE_PLATE`)}</Tooltip>)
    return (
      <Modal className="vehicleCreationModal" show={show} onHide={() => this.CloseModal()} backdrop={'static'} >
        <Form autoComplete="off" onSubmit={handleSubmit(this.submitCreation.bind(this))}>
          <Modal.Header closeButton>
            <Modal.Title>{t(`${tKey}VEHICLE_CREATION_MODAL.TITLE`)}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Field
                id="licensePlate"
                name="licensePlate"
                colSm={4}
                controlLabel={t(`${tKey}LICENSE_PLATE`)}
                placeholder={t(`${tKey}LICENSE_PLATE`)}
                component={InputText}
                validate={this.state.disabledValidateLicensePlate !== true ? [this.validateLicensePlate] : null }
                normalize={value => multipleNormalize(value, [toUpperCase, trimAll])}
              />
              <Field
                id="vinChassis"
                name="vinChassis"
                colSm={4}
                controlLabel={t(`${tKey}CHASSIS`)}
                placeholder={t(`${tKey}CHASSIS`)}
                component={InputText}
                minLength={17}
                maxLength={17}
                normalize={value => multipleNormalize(value, [toUpperCase, trimAll])}
                validate={[this.vinChassisValidation]}
              />
              <Field
                id="brandId"
                name="brandId"
                colSm={4}
                controlLabel={t(`${tKey}BRAND`)}
                placeholder={t(`${tKey}BRAND`)}
                component={InputSelect}
                options={brandCombo}
                valueKey="id"
                labelKey="value"
              />
              <Field
                id="model"
                name="model"
                colSm={4}
                controlLabel={t(`${tKey}MODEL`)}
                placeholder={t(`${tKey}MODEL`)}
                component={InputText}
              />
              <Field
                id="color"
                name="color"
                colSm={4}
                controlLabel={t(`${tKey}COLOR`)}
                placeholder={t(`${tKey}COLOR`)}
                component={InputText}
              />
              <Field
                id="tapestry"
                name="tapestry"
                colSm={4}
                controlLabel={t(`${tKey}TAPESTRY`)}
                placeholder={t(`${tKey}TAPESTRY`)}
                component={InputText}
              />
              <Field
                colSm={4}
                id="fuelId"
                menuContainerStyle={{ zIndex: 999 }}
                name="fuelId"
                controlLabel={t(`${tKey}FUEL`)}
                placeholder={t(`${tKey}FUEL`)}
                component={InputSelect}
                options={fuelCombo}
                valueKey="id"
                labelKey="value"
              />
              <Field
                id="comercialCode"
                name="comercialCode"
                colSm={4}
                controlLabel={t(`${tKey}COMMERCIAL_CODE`)}
                placeholder={t(`${tKey}COMMERCIAL_CODE`)}
                component={InputText}
              />
            </Row>
            <Row>
              <OverlayTrigger placement="right" overlay={editTooltip}
                colSm={1}>
                <Glyphicon glyph="info-sign"/>
              </OverlayTrigger>
              <Field
                colSm={3}
                id="disabledValidateLicensePlate"
                name="disabledValidateLicensePlate"
                placeholder={t(`${tKey}DISABLED_VALIDATE_LICENSE_PLATE`)}
                component={InputCheckbox}
                inline
                onChange= {(value) => (this.changeBoolLicensePlate(value))}
              />
            </Row>
          </Modal.Body>
          <Modal.Footer className='flexInLine al-C'>
            <Button id="btnAcceptCreateVehicle" name="btnAcceptCreateVehicle" type="submit" className="_Btn accept">{t('GLOBAL.BUTTONS.OK')}</Button>
            <Button id="btnCancelCreateVehicle" name="btnCancelCreateVehicle" onClick={() => this.CloseModal()} className="_Btn cancel">{t('GLOBAL.BUTTONS.CANCEL')}</Button>
          </Modal.Footer>
        </Form>
      </Modal>
    )
  }
}

const validate = (values, props) => {
  return validateVehicle(values, props)
}

export default reduxForm({
  form: 'service_vehicle_creation_modal',
  validate
})(VehicleCreationModal)
