import apiFetch from '../apiFetch'

export default function (token, auditConfigurationId, file) {
  const formData = new FormData()
  formData.append('', file[0], file[0].name)

  return apiFetch({
    endPoint: `UnattendedProcess/AuditConfiguration/${auditConfigurationId}/DocumentAudit`,
    method: 'POST',
    body: formData,
    token: token,
    fileBody: true
  })
}
