import React, { PureComponent } from 'react'
import { Tabs, Tab, Popover, Overlay, Button, Glyphicon } from 'react-bootstrap'
import { urgencyTypesClass } from '../../../../../constants/backendIds'
import { Link } from 'react-router-dom'

const MAX_DOSSIER_TO_VIEW = 10

class DossierListPopover extends PureComponent {
  constructor () {
    super()
    this.state = {
      showPopover: false,
      target: this
    }
  }

  handleClick (e) {
    e.stopPropagation()
    this.setState({ target: e.target, showPopover: true })
  }

  render () {
    const {
      t,
      idx,
      half,
      id,
      alive = [],
      stored = []
    } = this.props
    const tKey = 'SEARCH.'
    return (
      <span>
        <span className="info-entidad-documento">
          {`${alive.length} ${t(`${tKey}ONTIME`)} / ${stored.length} ${t(`${tKey}ARCHIVED`)}`}
        </span>
        {(stored.length > 0 || alive.length > 0) &&
          <Button bsSize="xsmall" className="icon-only icon-plus-subcabecera" onClick={this.handleClick.bind(this)}><Glyphicon glyph="plus"/></Button>
        }
        <span className="espacio-subcabecera"></span>
        <Overlay
          show={this.state.showPopover}
          target={this.state.target}
          onHide={() => this.setState({ showPopover: false })}
          rootClose
          placement={(idx >= half) ? 'top' : 'bottom'}
        >
          <Popover id={id} className="document-count-popover-container">
            <Tabs
              onClick={e => e.stopPropagation()}
              defaultActiveKey={1}
              animation={false}
              id="tab-dossier01"
            >
              <Tab eventKey={1} title={t(`${tKey}ONTIME`)}>
                <div className="popover-document-count">
                  {
                    alive.slice(0, MAX_DOSSIER_TO_VIEW).map((dossier, idx) => (
                      <p key={idx}>
                        <span className={`color-point ${urgencyTypesClass[dossier.urgencyType] || 'grey'}`}/> <Link to={`/dossier${dossier.urlToNavigate}`}>{dossier.number}</Link> - {dossier.dossierSubType}
                      </p>
                    ))
                  }
                </div>
              </Tab>
              <Tab eventKey={2} title={t(`${tKey}ARCHIVED`)}>
                <div className="popover-document-count">
                  {
                    stored.slice(0, MAX_DOSSIER_TO_VIEW).map((dossier, idx) => (
                      <p key={idx}>
                        <span className="color-point grey"/> <Link to={`/dossier${dossier.urlToNavigate}`}>{dossier.number}</Link> - {dossier.dossierSubType}
                      </p>
                    ))
                  }
                </div>
              </Tab>
            </Tabs>
          </Popover>
        </Overlay>
      </span>
    )
  }
}

export default DossierListPopover
