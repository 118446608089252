import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { Col, FormGroup, ControlLabel } from 'react-bootstrap'
import DayPicker from 'react-day-picker'

class ReactDayPickerComponent extends PureComponent {
  constructor () {
    super()
    this.state = {
      selectedDay: null
    }
    this.modStyle = {
      today: {
        color: '#ff8a8a'
      },
      disabled: {
        cursor: 'not-allowed',
        outline: 'none'
      },
      selected: {
        borderRadius: 'inherit'
      }
    }
  }

  convertData (str) {
    var date = new Date(str)
    var mnth = ('0' + (date.getMonth() + 1)).slice(-2)
    var day = ('0' + date.getDate()).slice(-2)
    return [day, mnth, date.getFullYear()].join('/')
  }

  handleSelectedDay (value, modifiers) {
    if (modifiers.selected) {
      this.props.setSelectedDate(null)
      this.setState({ selectedDay: null })
    } else {
      if (!modifiers.disabled) {
        this.props.setSelectedDate(value)
        this.setState({ selectedDay: value })
      }
    }
  }

  setDate (value) {
    this.setState({ selectedDay: value })
  }

  getWeekDays_long (t, tKey) {
    const tKeyDays = tKey + 'DAYS_OF_THE_WEEK_FULL.'
    return [t(tKeyDays + 'SUNDAY'), t(tKeyDays + 'MONDAY'), t(tKeyDays + 'TUESDAY'), t(tKeyDays + 'WEDNESDAY'),
      t(tKeyDays + 'THURSDAY'), t(tKeyDays + 'FRIDAY'), t(tKeyDays + 'SATURDAY')]
  }

  getWeekDays_short (t, tKey) {
    const tKeyDays = tKey + 'DAYS_OF_THE_WEEK.'
    return [t(tKeyDays + 'SUNDAY'), t(tKeyDays + 'MONDAY'), t(tKeyDays + 'TUESDAY'), t(tKeyDays + 'WEDNESDAY'),
      t(tKeyDays + 'THURSDAY'), t(tKeyDays + 'FRIDAY'), t(tKeyDays + 'SATURDAY')]
  }

  getMonths (t, tKey) {
    const tKeyDays = tKey + 'MONTHS.'
    return [t(tKeyDays + 'JANUARY'), t(tKeyDays + 'FEBRUARY'), t(tKeyDays + 'MARCH'), t(tKeyDays + 'APRIL'),
      t(tKeyDays + 'MAY'), t(tKeyDays + 'JUNE'), t(tKeyDays + 'JULY'), t(tKeyDays + 'AUGUST'), t(tKeyDays + 'SEPTEMBER'), t(tKeyDays + 'OCTOBER'),
      t(tKeyDays + 'NOVEMBER'), t(tKeyDays + 'DECEMBER')]
  }

  getDisabledDays () {
    let disabled = [
      { before: new Date() },
      { daysOfWeek: [0, 6] }
    ]
    if (this.props.disabledDates && this.props.disabledDates.length > 0) {
      this.props.disabledDates.forEach(date => {
        disabled.push(date)
      })
    }
    return disabled
  }

  render () {
    const {
      t,
      colSm,
      customClass,
      paddingLeft,
      meta,
      showError = false,
      rightIcon,
      leftIcon = '',
      controlLabel,
      forceControlLabelInOneLine = false,
      setSelectedMonth
    } = this.props
    const errorMessage = ((showError || meta.touched) && meta.error && typeof meta.error !== 'boolean')
      ? meta.error
      : null
    const tKey = 'GLOBAL.FORMS.INPUT_DATE_PICKER.'
    return (
      <Col sm={colSm} className={customClass} style={paddingLeft}>
        <FormGroup
          className={rightIcon ? 'input-with-icon' : ''}
          validationState={(showError || meta.touched) && meta.error ? 'error' : null}>
          {controlLabel && <ControlLabel className={forceControlLabelInOneLine ? 'one-line ellipsis' : ''}>{controlLabel}</ControlLabel>}
          <DayPicker
            disableToolbar
            firstDayOfWeek={1}
            months={this.getMonths(t, tKey)}
            todayButton={t(tKey + 'TODAY')}
            selectedDays={this.state.selectedDay}
            fromMonth={new Date()}
            weekdaysShort={this.getWeekDays_short(t, tKey)}
            weekdaysLong={this.getWeekDays_long(t, tKey)}
            modifiers={{ today: new Date() }}
            modifiersStyles={this.modStyle}
            disabledDays={this.getDisabledDays()}
            onMonthChange={(value) => setSelectedMonth(value)}
            onDayClick={(value, modifiers) => this.handleSelectedDay(value, modifiers) }
          />
          <i className={rightIcon || leftIcon} />
          {errorMessage && <span className='help-block text-center'>{errorMessage}</span>}
        </FormGroup>
      </Col>
    )
  }
}
export default connect(null, null)(translate()(ReactDayPickerComponent))
