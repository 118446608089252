export const replaceableFields = {
  numDossiers: '##NUMDOSSIERS##',
  fleetCode: '##FLEET_CODE##',
  description: '##DESCRIPTION##',
  errors: '##ERRORS##',
  numTotal: '##NUM_TOTAL##',
  numOK: '##NUM_OK##',
  count: '##COUNT##',
  fleetDescription: '##FLEET_DESCRIPTION##',
  dossier: '##DOSSIER##',
  contract: '##CONTRACT##',
  amount: '##AMOUNT##',
  row: '##ROW##'
}
