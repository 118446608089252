import { call, takeEvery, select, put } from 'redux-saga/effects'
import { handleError } from '../../../errors/errorManager'
import { getAuth } from '../../../../selectors/access/auth'
import { showLoader, hideLoader, openModal } from '../../../../actions/common'
import getMassiveDownloadDocuments from '../../../../services/document/getMassiveDownloadDocuments'
import documentMassiveDownload from '../../../../constants/actions/dossier/common/documentMassiveDownload'
import { getDocumentsMassiveDownloadSucess } from '../../../../actions/dossier/common/documentMassiveDownloadActions'
import getDownloadDocumentsSelected from '../../../../services/document/getDownloadDocumentsSelected'

export function * getDocumentsMassiveDownload ({ dossierId, isHistorical, resolve, reject }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const docList = yield call(getMassiveDownloadDocuments, dossierId, isHistorical, auth.token)
    yield put(getDocumentsMassiveDownloadSucess(docList))
    if (resolve) resolve()
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchGetDocumentsMassiveDownload () {
  yield takeEvery(documentMassiveDownload.GET_ALL_AVAILABLE_DOCUMENT_MASSIVE_DOWNLOAD, getDocumentsMassiveDownload)
}

export function * downloadDocumentsSelected ({ dossierId, isHistorical, aliveDocuments, historicalDocuments, resolve, reject }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(getDownloadDocumentsSelected, dossierId, isHistorical, aliveDocuments, historicalDocuments, auth.token)
    if (resolve) resolve()
  } catch (error) {
    if (error.json.tag === 'failedToDownload') {
      yield put(openModal('failedToDownload', null, null, null, null, [{ id: '##DOCUMENT_NAME##', value: error.json.message }], null))
    } else if (error.json.tag === 'maximumSizeExceeded') {
      yield put(openModal('maximumSizeExceeded', null, null, null, null, [{ id: '##MAX_SIZE##', value: error.json.message }], null))
    } else {
      yield call(handleError, { error })
    }
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDownloadDocumentsSelected () {
  yield takeEvery(documentMassiveDownload.DOWNLOAD_DOCUMENTS_MASSIVE_DOWNLOAD, downloadDocumentsSelected)
}
