import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { stopAsyncValidation, getFormValues } from 'redux-form'

import AddNewFleetModal from '../../components/management/AddNewFleetModal'
import { translate } from 'react-polyglot'
import {
  fetchCommercialSocietyTypeCombo, fetchEntityFilterSubTypeCombo, fetchEntitySubTypeCombo, fetchIaeCombo
  , fetchEntityCountryTypeCombo, fetchEntityStateTypeCombo, fetchEntityCityTypeCombo, fetchEntityTownTypeCombo, fetchEntityColonyTypeCombo, fetchEntityCPTypeCombo, fetchActivityBpro,
  fetchClientDataAddress, fetchEntityMaritalStatusCombo
} from '../../actions/combos/combos'
import { closeNewFleetModal, createUpdateFleet, asignDossierToFleet, closeActionFleetFinder, changeEntityDossierFromFleet, resetFiltersFleetInfo, fetchFleetsInfo, getClientFleetPermissions, fetchFleetsEntity, checkChangeEntityDossier } from '../../actions/management/management'
import { openModal } from '../../actions/common'
import clientActions, { fetchClientFromDocument, resetValues } from '../../actions/dossier/common/client'
import buttonsSidebarActions from '../../actions/dossier/common/buttonsSidebar'
import { setActionExpFleetProcess } from '../../actions/unattendedProcess/unattendedProcess'
import { validate } from '../../actions/dossier/common/validations'

export function mapStateToProps (state) {
  return {
    ...state.management.addNewFleetModal,
    entityFilterSubTypeCombo: state.combos.entityFilterSubTypeCombo,
    fleetFinderFilter: state.management.searchFleetInfo.dynamicFilters,
    entitySubTypeCombo: state.combos.entitySubTypeCombo,
    dniCif: state.management.dniCif,
    combos: state.combos,
    clientFleetPermissions: state.management.clientFleetPermissions,
    formValues: getFormValues('addNewFleetModal')(state)
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      ...clientActions,
      ...buttonsSidebarActions,
      fetchEntityFilterSubTypeCombo,
      closeNewFleetModal,
      createUpdateFleet,
      asignDossierToFleet,
      openModal,
      closeActionFleetFinder,
      changeEntityDossierFromFleet,
      resetFiltersFleetInfo,
      fetchFleetsInfo,
      fetchEntitySubTypeCombo,
      fetchClientFromDocument,
      getClientFleetPermissions,
      fetchFleetsEntity,
      checkChangeEntityDossier,
      fetchIaeCombo,
      setActionExpFleetProcess,
      fetchCommercialSocietyTypeCombo,
      fetchEntityCountryTypeCombo,
      fetchEntityStateTypeCombo,
      fetchEntityCityTypeCombo,
      fetchEntityTownTypeCombo,
      fetchEntityColonyTypeCombo,
      fetchEntityCPTypeCombo,
      fetchEntityMaritalStatusCombo,
      fetchActivityBpro,
      validate,
      stopAsyncValidation,
      resetValues,
      fetchClientDataAddress
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(AddNewFleetModal))
