import React, { PureComponent } from 'react'
import { Col, ControlLabel, Radio } from 'react-bootstrap'

class InputRadio extends PureComponent {
  handleChange (value) {
    this.props.input.onChange(value.target.value)
    if (this.props.onInputChange) {
      this.props.onInputChange(value.target.value)
    }
  }

  getItemsOneLine (items, properties) {
    return items && items.map((element, idx) => {
      return <Col sm={12 / items.length} key={'col_' + idx}>
        <Radio
          type="radio"
          key={idx}
          name={properties.groupName}
          id={properties.id + '-' + element.id}
          inline={properties.inline}
          disabled={properties.disabled || element.disabled}
          value={element.value}
          checked={element.value !== null && element.value !== undefined && properties.value !== null && element.value.toString() === properties.value.toString()}
          onChange={this.handleChange.bind(this)}>{properties.t(element.label)}
        </Radio>
      </Col>
    })
  }

  getItems (items, properties) {
    return items && items.map((element, idx) => {
      return <Radio
        type="radio"
        key={idx}
        name={properties.groupName}
        id={properties.id + '-' + element.id}
        inline={properties.inline}
        disabled={properties.disabled || element.disabled}
        value={element.value}
        checked={element.value !== null && element.value !== undefined && properties.value !== null && element.value.toString() === properties.value.toString()}
        onChange={this.handleChange.bind(this)}>{properties.t(element.label)}
      </Radio>
    })
  }

  render () {
    const { id, groupName, placeholder, inline = false, disabled = false, colSm = 6, customClass = '', items, meta, showError = false, input: { value }, t, oneLine = false } = this.props
    const errorMessage = ((showError || meta.touched) && meta.error && typeof meta.error !== 'boolean')
      ? meta.error
      : null
    const properties = { id, groupName, placeholder, inline, disabled, colSm, customClass, items, meta, showError, value, t }
    return (
      <Col sm={colSm} className={customClass + ((showError || meta.touched) && meta.error ? ' has-error' : '') } id={id}>
        {!oneLine &&
        <>
          <div>
            <ControlLabel > {placeholder} </ControlLabel>
          </div>
          {this.getItems(items, properties)}
        </>
        }

        {oneLine &&
          <div>
            <Col sm={colSm / 2}>
              <ControlLabel className='oneLine' > {placeholder} </ControlLabel>
            </Col>
            <Col sm={colSm / 2}>
              {this.getItemsOneLine(items, properties)}
            </Col>
          </div>
        }
        {errorMessage && <span className='help-block text-center'>{errorMessage}</span>}
      </Col>
    )
  }
}

export default InputRadio
