import React, { PureComponent } from 'react'
import { formatMoney } from '../../../../util/utils'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { documentTypeUse as documentTypeUseConstants } from '../../../../constants/backendIds'
import { documentEntityType as documentEntityTypeConstants } from '../../../../constants/dossier/common/documentEntityType'
import { translateDocumentStatus, documentStatusClass } from '../../../../constants/dossier/common/documentStatus'

class ChargeRow extends PureComponent {
  render () {
    const {
      t,
      readOnlyFields,
      tKey,
      actions: { openDocumentEditionModal, postPaymentSignalStatusSuccess },
      dossierId, organizedUnitId, dossierType,
      charge: {
        number, documentStatusId, description, amount, documentId, chargeId, documentTypeUseId
      }
    } = this.props
    const viewDocument = (<Tooltip id="viewDocument">{t(`${tKey}VIEW_DOC`)}</Tooltip>)
    const addRefund = (<Tooltip id="printRefund">{t(`${tKey}ADD_REFUND`)}</Tooltip>)
    const statusTooltip = (<Tooltip id="statusTooltip">{documentStatusId && t(`${tKey}${translateDocumentStatus[documentStatusId]}`)}</Tooltip>)
    return (
      <tr>
        <td>{number}</td>
        <td>{description}</td>
        <td className="text-right">{formatMoney(amount)}</td>
        <td style={{ textAlign: 'center' }}>
          <span>
            <OverlayTrigger placement="right" overlay={statusTooltip}>
              <i id="documentStatus" className={'icono-heder-modal ico-document-' + documentStatusClass[translateDocumentStatus[documentStatusId]]}/>
            </OverlayTrigger>
          </span>
        </td>
        <td style={{ textAlign: 'center' }}>
          {
            <OverlayTrigger placement="right" overlay={viewDocument}>
              <i className="ico-magnifying-plus"
                onClick={() => openDocumentEditionModal(
                  documentId,
                  (documentTypeUseId || documentTypeUseConstants.charge),
                  documentEntityTypeConstants.DOSSIER,
                  dossierId,
                  null,
                  null,
                  organizedUnitId,
                  null,
                  // () => updateDependantsSaleDossierAndHeader(dossierId)
                  null,
                  true,
                  dossierType,
                  null,
                  null,
                  null,
                  chargeId,
                  null,
                  this.props.editableDoc,
                  readOnlyFields
                )}/>
            </OverlayTrigger>
          }
        </td>
        {(documentTypeUseId === documentTypeUseConstants.down_payment || documentTypeUseId === documentTypeUseConstants.charge)
          ? this.props.charge.showRefundButton && (
            <td style={{ textAlign: 'center' }}>
              <OverlayTrigger placement="right" overlay={addRefund}>
                <i className="ico-refund"
                  onClick={() => openDocumentEditionModal(
                    null,
                    documentTypeUseConstants.instalment_down_payment,
                    documentEntityTypeConstants.DOSSIER,
                    dossierId,
                    null,
                    null,
                    organizedUnitId,
                    null,
                    () => postPaymentSignalStatusSuccess(),
                    true,
                    dossierType,
                    null,
                    null,
                    null,
                    null,
                    null,
                    this.props.editableDoc,
                    readOnlyFields,
                    null,
                    true,
                    chargeId
                  )} />
              </OverlayTrigger>
            </td>)
          : <td></td>
        }
      </tr>
    )
  }
}
export default ChargeRow
