import React, { PureComponent } from 'react'
import { /* Col, Row, OverlayTrigger , Tooltip, DropdownButton */ } from 'react-bootstrap'
import { /* urgencyTypesId, urgencyTypesClass, */ permissions } from '../../../../constants/backendIds'
import { /* compareArrays, */ compareObjects } from '../../../../util/utils'
import { getHeaderPermisions } from '../../../../util/permisionFunctions'
// import { GoBackTooltip } from '../../../../components/commons/Tooltips'
import TimeLine from '../../../../_v2/components/dossiers/common/statusHeader/TimeLine'
import StageHeader from '../../../../_v2/components/dossiers/common/statusHeader/StageHeader'
// import settings from '../../../../setting'

class StatusHeader extends PureComponent {
  constructor () {
    super()
    this.state = {
      isVehicleLoaded: false,
      isClientLoaded: false,
      isFinanceLoaded: false,
      isDeliveryLoaded: false,
      isEconomicLoaded: false,
      isSelfSupplyLoaded: false,
      componentsConfiguration: {
        summary: {
          code: 'summary',
          permission: permissions.hidden
        },
        stages: {
          code: 'stages',
          permission: permissions.hidden
        },
        checklist: {
          code: 'checklist',
          permission: permissions.hidden
        },
        qrcode: {
          code: 'qrcode',
          permission: permissions.hidden
        }
      }
    }
  }

  initializePermisions (props) {
    const newState = getHeaderPermisions(props.headerTabConfiguration)
    this.setState({ ...this.state, componentsConfiguration: { ...newState } })
  }

  handleClick () {
    this.props.actions.toggleTimeline()
  }

  calcStatusHeaderHeight () {
    const header = document.querySelector('.navbar-headerPage') || {}
    const statusSidebar = document.querySelector('#dossier-header .dossier-header') || {}
    return header.clientHeight + statusSidebar.clientHeight + 1
  }

  UNSAFE_componentWillMount () {
    if (this.props.headerTabConfiguration) this.initializePermisions(this.props)
  }

  componentDidMount () {
    this.props.actions.setStatusHeaderHeight(this.calcStatusHeaderHeight())
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.headerTabConfiguration && !compareObjects(this.props.headerTabConfiguration || {}, nextProps.headerTabConfiguration, true)) {
      this.initializePermisions(nextProps)
    }
  }

  componentDidUpdate (prevProps) {
    /* if (prevProps.isFolded !== this.props.isFolded || prevProps.windowWidth !== this.props.windowWidth || !compareArrays(prevProps.stages, this.props.stages, true)) {
      this.props.actions.setStatusHeaderHeight(this.calcStatusHeaderHeight())
    } */
    this.props.actions.setStatusHeaderHeight(this.calcStatusHeaderHeight())
  }

  componentWillUnmount () {

  }

  handleToUpdateVehicle () {
    this.setState({ isVehicleLoaded: true })
  }

  handleToUpdateClient () {
    this.setState({ isClientLoaded: true })
  }

  handleToUpdateFinance () {
    this.setState({ isFinanceLoaded: true })
  }

  handleToUpdateDelivery () {
    this.setState({ isDeliveryLoaded: true })
  }

  handleToUpdateEconomic () {
    this.setState({ isEconomicLoaded: true })
  }

  switchFavorite (currentFavoriteStatus) {
    this.props.actions.switchFavorite(currentFavoriteStatus, this.props.dossierId, false)
  }

  render () {
    const {
      t, headerData, stages, dossierType, dossierSubType, dossierId, isFolded, activeTab,
      showEconomicPlan = false, showTitlePage = false,
      pristine, defaultSalesmen, readOnlyFields, editableDoc /* favorite */
    } = this.props
    // const tKey = 'DOSSIER_COMPONENTS.HEADER.'
    /* const tooltipUrgency = (
      <Tooltip container={this} id="tooltipUrgency">
        {headerData.urgencyType === urgencyTypesId.high && t(`${tKey}URGENCY_TYPES.EXPIRED`)}
        {headerData.urgencyType === urgencyTypesId.medium && t(`${tKey}URGENCY_TYPES.ABOUT_TO_EXPIRE`)}
        {headerData.urgencyType === urgencyTypesId.low && t(`${tKey}URGENCY_TYPES.ON_TIME`)}
        &nbsp;
        {headerData.urgencyType === urgencyTypesId.high ? t(`${tKey}FROM`) : t(`${tKey}TO`)}
        &nbsp;
        {headerData.urgencyDate}
      </Tooltip>
    ) */
    /*     const tooltipCreationDateDealerNumber = (
      <Tooltip id="tooltipCreationDateDealerNumber">
        {headerData.dealerNumber !== undefined && (t(`${tKey}DEALER_NUMBER`) + ' / ')}
        {t(`${tKey}CREATION_DATE`)}
      </Tooltip>
    )
    const tooltipRentalOderNumber = (
      <Tooltip id="tooltipRentalOderNumber">
        {headerData.rentalOrderNumber !== undefined && (t(`${tKey}RENTALORDER_NUMBER`))}
      </Tooltip>
    )
    const tooltipChassis = (
      <Tooltip id="tooltipChassis">
        {vinChassis !== undefined && (t(`${tKey}CHASSIS`))}
      </Tooltip>
    )
    const tooltipCreator = (
      <Tooltip id="tooltipCreator">
        {creatorName !== undefined && (t(`${tKey}CREATOR`))}
      </Tooltip>
    ) */

    const shoWFolderClient = !isFolded

    return (
      <div className="wrapper _nf">
        { showTitlePage === true &&
          <div className='wrapper-header'>
            <div className="title-page">
              <i className='iDocIcon-timeline icon-page' />
              <h2>Time-Line</h2>
            </div>
          </div>
        }
        <div className='wrapper-body'>
          {!readOnlyFields && ([
            <StageHeader
              t={t}
              stagesPermission={this.state.componentsConfiguration.stages.permission}
              stages={stages}
              defaultSalesmen={defaultSalesmen}
              dossierType={dossierType}
              key={1}
            />,
            <TimeLine
              t={t}
              show={shoWFolderClient}
              stages={stages}
              dossierId={dossierId}
              dossierType={dossierType}
              dossierSubType={dossierSubType}
              activeTab={activeTab}
              showEconomicPlan={showEconomicPlan}
              stagesPermission={this.state.componentsConfiguration.stages.permission}
              checklistPermission={this.state.componentsConfiguration.checklist.permission}
              qrcodePermission={this.state.componentsConfiguration.qrcode !== undefined ? this.state.componentsConfiguration.qrcode.permission : permissions.hidden}
              handleToUpdateVehicle = {this.handleToUpdateVehicle.bind(this)}
              isVehicleLoaded = {this.state.isVehicleLoaded}
              handleToUpdateClient= {this.handleToUpdateClient.bind(this)}
              isClientLoaded = {this.state.isClientLoaded}
              handleToUpdateFinance= {this.handleToUpdateFinance.bind(this)}
              isFinanceLoaded = {this.state.isFinanceLoaded}
              handleToUpdateDelivery = {this.handleToUpdateDelivery.bind(this)}
              isDeliveryLoaded = {this.state.isDeliveryLoaded}
              handleToUpdateEconomic = {this.handleToUpdateEconomic.bind(this)}
              isEconomicLoaded = {this.state.isEconomicLoaded}
              pristine={pristine}
              editableDoc={editableDoc !== undefined && editableDoc !== null ? editableDoc : true}
              key={2}
              number={headerData.number}
              isSelfSupplyLoaded = {this.props.isSelfSupplyLoaded}
              handleToUpdateSelfSupply = {() => this.props.handleToUpdateSelfSupply()}
              actions={this.props.actions}
              showTitlePage={this.props.showTitlePage}
            />
          ])}
        </div>
      </div>
    )
  }
}

export default StatusHeader
