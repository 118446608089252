import { call, put, select, takeLatest } from 'redux-saga/effects'
import { getAuth } from '../../selectors/access/auth'
import { showLoader, hideLoader } from '../../actions/common'
import messageModalActions from '../../actions/modals/messageModal'
import { fetchCombosSuccess } from '../../actions/combos/combos'
import combosActionTypes from '../../constants/actions/combos/combos'
import { handleError } from '../errors/errorManager'
import getCatalogueCombo from '../../services/catalogue/getCatalogueCombo'
import { change } from 'redux-form'

export function * fetchCatalogueCombo ({ carlineId }) {
  try {
    yield put(showLoader())
    if (carlineId) {
      const auth = yield select(getAuth)
      const catalogueCombo = yield call(getCatalogueCombo, auth.token, carlineId)
      yield put(fetchCombosSuccess({ catalogueCombo }))
    } else {
      yield put(change('order', 'catalogueId', null))
      yield put(change('order', 'colorId', null))
      yield put(change('order', 'tapestryId', null))
      yield put(change('order', 'modelId', null))
      yield put(change('order', 'model', null))
    }
  } catch (error) {
    const customHandler = function * () {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.FETCH_COMBO_ERROR'))
    }
    yield call(handleError, { error, customHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchCatalogueCombo () {
  yield takeLatest(combosActionTypes.FETCH_CATALOGUE_COMBO, fetchCatalogueCombo)
}
