
import React, { PureComponent } from 'react'
import { Modal, Col, Row, FormGroup, Button } from 'react-bootstrap'
import InputText from '../../../commons/form/InputText'
import { reduxForm, Field } from 'redux-form'

class ServiceObservationsModal extends PureComponent {
  constructor () {
    super()
    this.state = {}
  }

  componentDidUpdate (prevProps) {
    if (this.props.showModal !== prevProps.showModal && this.props.showModal === true) {
      this.props.initialize(this.props.service)
    }
  }

  render () {
    const { showModal, actions: { closeServiceObservationModal }, t } = this.props
    const tKey = 'DOSSIER_COMPONENTS.SALE_SERVICES.'
    return (
      <Modal className="role-modal languages-modal" show={showModal} onHide={closeServiceObservationModal}>
        <form autoComplete="off">
          <Modal.Header closeButton onHide={closeServiceObservationModal}>
            <Modal.Title> {t(`${tKey}TITLE`)} </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <Row className="inputs-wrapper">
                <Field
                  colSm={12}
                  componentClass="textarea"
                  id="providerObservations"
                  name="providerObservations"
                  component={InputText}
                  customClass="textarea-noresize"
                  disabled={true}/>
              </Row>
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <Col sm={12} className="btn-wrapper">
              <Button
                id="role-modal-submit-button"
                className="btn-standard"
                onClick={closeServiceObservationModal}>
                {t(`${tKey}CLOSE`)}
              </Button>
            </Col>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default reduxForm({ form: 'serviceObservationsModal' })(ServiceObservationsModal)
