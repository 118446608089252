import React, { Component } from 'react'
import { Field } from 'redux-form'
import { Button, Modal, Col, Row, Tooltip, OverlayTrigger, Glyphicon } from 'react-bootstrap'
import InputMoney from '../../../commons/form/InputMoney'
import InputPercentage from '../../../commons/form/InputPercentage'

class EconomicPlanAssociatedCostByPercentage extends Component {
  constructor (props) {
    super()
    this.state = {
      cost: 0,
      costByPercentage: 0,
      operationTotalAmount: 0,
      originPristine: true
    }
  }

  componentDidUpdate () {}

  getSnapshotBeforeUpdate (prevProps) {
    if (!prevProps.showModal && this.props.showModal) {
      const associatedCost = this.props.additionalCostId
        ? this.props.associatedCosts.find(ac => ac.id === this.props.additionalCostId)
        : this.props.associatedCosts.find(ac => ac.indexFront === this.props.additionalCostIndexFront)

      if (!associatedCost) return

      const totalAmount = this.props.totalAmount ?? 0
      const cost = associatedCost.cost ?? 0
      const costByPercentage = totalAmount > 0 ? (100 * associatedCost.cost / this.props.totalAmount) : 0

      this.setState({ originPristine: this.props.pristine }, () => {
        this.props.actions.fetchAssociatedCostByPercentageModal(null,
          null,
          null,
          totalAmount.toFixed(2),
          cost.toFixed(2),
          costByPercentage.toFixed(2),
          this.props.pristine,
          this.props.dossierType)
      })
    }
    return this.props
  }

  handleBlurCost (value) {
    this.props.actions.change('costByPercentage', (100 * value / this.props.totalAmount).toFixed(2))
    this.setState({ costByPercentage: (100 * value / this.props.totalAmount).toFixed(2) })
  }

  handleBlurPercentage (value) {
    this.props.actions.fetchAssociatedCostByPercentageModal(null,
      null,
      null,
      null,
      (this.props.totalAmount * value / 100).toFixed(2),
      null,
      this.state.originPristine,
      this.props.dossierType)
    this.setState({ cost: (this.props.totalAmount * value / 100).toFixed(2) })
  }

  save () {
    this.props.actions.fetchAssociatedCostByPercentageModal(this.props.additionalCostId,
      this.props.additionalCostIndexFront,
      this.props.associatedCosts,
      null,
      this.state.cost,
      null,
      false,
      this.props.dossierType)
  }

  render () {
    const { t, showModal, actions: { closeModal } } = this.props
    const tKey = 'DOSSIER_COMPONENTS.ECONOMIC_PLAN.ASSOCIATED_COSTS.'

    const percentageTooltip = (<Tooltip id="percentageTooltip">
      {t(`${tKey}PERCENTAGE_TOOLTIP`)}
    </Tooltip>)
    const overlayInfoIcon = (<OverlayTrigger overlay={percentageTooltip}>
      <span>{t(`${tKey}COST_BY_PERCENTAGE`)}<Glyphicon className='m-left' glyph="info-sign"/></span>
    </OverlayTrigger>)
    return (
      <Modal
        show={showModal}
        onHide={() => { closeModal() }}
        backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>{t(`${tKey}TITLE`)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Field
              id="costByPercentage"
              name="associatedCost.costByPercentage"
              colSm={6}
              controlLabel={overlayInfoIcon}
              placeholder={t(`${tKey}COST_BY_PERCENTAGE`)}
              component={InputPercentage}
              onInputBlur={(value) => this.handleBlurPercentage(value)}
            />
            <Field
              id="cost"
              name="associatedCost.cost"
              colSm={6}
              controlLabel={t(`${tKey}COST`)}
              placeholder={t(`${tKey}COST`)}
              component={InputMoney}
              onInputBlur={(value) => this.handleBlurCost(value)}
              disabled={true}
            />
          </Row>
          <Row>
            <Field
              id="operationTotalAmount"
              name="associatedCost.operationTotalAmount"
              colSm={12}
              controlLabel={t(`${tKey}CALCULATION_BASE`)}
              placeholder={t(`${tKey}CALCULATION_BASE`)}
              component={InputMoney}
              disabled={true}
            />
          </Row>
        </Modal.Body>
        <Modal.Footer style={{ textAlign: 'center' }}>
          <Col sm={12} className="btn-wrapper">
            <Button id="btn_save" className="btn-standard" onClick={() => { this.save(); closeModal() }} >{t(`${tKey}SAVE_BTN`)}</Button>
          </Col>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default (EconomicPlanAssociatedCostByPercentage)
