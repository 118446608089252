
import { bindActionCreators } from 'redux'
import ProcessingAgencyIntegrationRegistrationTypesMaster from '../../../../components/masters/processing/processingAgencyIntegrations/ProcessingAgencyIntegrationRegistrationTypesMaster'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { fetchProcessingAgencyRegistrationTypesList, showProcessingAgencyRegistrationTypesModal, deleteProcessingAgencyRegistrationTypes } from '../../../../actions/masters/processingAgency/processingAgencyIntegrations/processingAgencyRegistrationTypes'
import {
  fetchOperationTypeAll
} from '../../../../actions/combos/combos'

export function mapStateToProps (state) {
  const registrationTypes = state.processingAgency.processingAgencyIntegrationModal.registrationTypes
  return {
    ...registrationTypes,
    agency: state.processingAgency.processingAgencyIntegrationModal.agency
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchProcessingAgencyRegistrationTypesList,
      showProcessingAgencyRegistrationTypesModal,
      deleteProcessingAgencyRegistrationTypes,
      fetchOperationTypeAll
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(ProcessingAgencyIntegrationRegistrationTypesMaster))
