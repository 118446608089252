import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import AuditAdminModal from '../../../components/management/AuxModals/AuditAdminModal'
import { fetchAuditConfigurationDocument } from '../../../actions/management/management'
import { fetchAuditLoadData, fetchDossierSubTypes, fetchAuditConfigurationType, fetchDossierTypes, fetchDocumentTypeUseCombo } from '../../../actions/combos/combos'

export function mapStateToProps (state) {
  return {
    ...state.management,
    combos: {
      ...state.combos
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchAuditLoadData,
      fetchDossierSubTypes,
      fetchDossierTypes,
      fetchAuditConfigurationType,
      fetchDocumentTypeUseCombo,
      fetchAuditConfigurationDocument
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(AuditAdminModal))
