import { put, call, select, takeEvery } from 'redux-saga/effects'
// import { replace } from 'react-router-redux'
import { replace } from 'connected-react-router'
import commonHeaderActionsTypes from '../../../constants/actions/dossier/common/commonHeader'
import buttonsHeaderActionTypes from '../../../constants/actions/dossier/common/buttonsHeader'
import messageModalActions from '../../../actions/modals/messageModal'
import { showLoader, hideLoader } from '../../../actions/common'
import { yesNoModal } from '../../modalsListeners/yesNoModal'
import { getAuth } from '../../../selectors/access/auth'
import putDossierCancelService from '../../../services/dossier/put/putDossierCancel'
import { handleError } from '../../errors/errorManager'
import { getDossierStateById, initializeDossier } from '../commonDossierSagas'
// import getVOCompanies from '../../../services/dossier/get/getVOCompanies'
import putSaleToVOCompany from '../../../services/dossier/put/putSaleToVOCompany'
// import { openSelectVOCompany } from '../../../actions/dossier/common/buttonsHeader'
import putApproveMarginService from '../../../services/margins/putApproveMargin'

export function * putCancelDossier ({ dossierId, pristine, reset, forceCancel, reason, comment }) {
  const dossierState = yield call(getDossierStateById, dossierId)
  try {
    if (!pristine) {
      const confirmedSaveWarning = yield call(yesNoModal, 'commonDossierSaveWarning')
      if (confirmedSaveWarning) {
        reset('salesDossier')
        reset('purchase_dossier')
        reset('paperwork_dossier')
        reset('campaign_dossier')
        reset('registrationDeliverydossier')
        reset('service_dossier')
        reset('stock_dossier')
        const auth = yield select(getAuth)
        const confirmed = yield call(yesNoModal, 'commonDossierCancel')
        if (confirmed) {
          yield put(showLoader())
          const response = yield call(putDossierCancelService, dossierId, auth.token, dossierState.dossier.lastAccessDate, forceCancel, reason, comment)
          yield put(messageModalActions.openSuccessMessageModal('MESSAGES.CANCEL_DOSSIER_SUCCESS'))
          if (response.urlToNavigate) {
            // yield put(replace('/dossier' + response.urlToNavigate))
            yield put(replace('/dossier' + response.urlToNavigate))
          } else {
            yield call(initializeDossier, dossierState.type, dossierState.dossier)
          }
        }
      }
    } else {
      const auth = yield select(getAuth)
      const confirmed = yield call(yesNoModal, 'commonDossierCancel')
      if (confirmed) {
        yield put(showLoader())
        const response = yield call(putDossierCancelService, dossierId, auth.token, dossierState.dossier.lastAccessDate, forceCancel, reason, comment)
        yield put(messageModalActions.openSuccessMessageModal('MESSAGES.CANCEL_DOSSIER_SUCCESS'))
        if (response.urlToNavigate) {
          // yield put(replace('/dossier' + response.urlToNavigate))
          yield put(replace('/dossier' + response.urlToNavigate))
        } else {
          yield call(initializeDossier, dossierState.type, dossierState.dossier)
        }
      }
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          yield call(initializeDossier, dossierState.type, dossierState.dossier)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('MESSAGES.CANCEL_DOSSIER_ERROR', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('purchase_dossier'))
      yield put(reset('paperwork_dossier'))
      yield put(reset('campaign_dossier'))
      yield put(reset('registrationDeliverydossier'))
      yield put(reset('service_dossier'))
      yield put(reset('stock_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchPutCancelDossier () {
  yield takeEvery(commonHeaderActionsTypes.PUT_CANCEL_DOSSIER, putCancelDossier)
}

// export function * selectVOCompany ({ organizedUnitId }) {
//   try {
//     yield put(showLoader())
//     const auth = yield select(getAuth)
//     const companies = yield call(getVOCompanies, organizedUnitId, auth.token)
//     yield put(openSelectVOCompany(companies))
//   } catch (error) {
//     yield call(handleError, { error })
//   } finally {
//     yield put(hideLoader())
//   }
// }

// export function * watchSelectVOCompany () {
//   yield takeEvery(buttonsHeaderActionTypes.SELECT_VO_COMPANY, selectVOCompany)
// }

export function * saleToVOCompany ({ dossierId, organizedUnitId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield put(hideLoader())
    const confirmed = yield call(yesNoModal, 'selectVOCompanyConfirmation')
    if (confirmed) {
      yield put(showLoader())
      yield call(putSaleToVOCompany, dossierId, organizedUnitId, auth.token)
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchPutVOCompany () {
  yield takeEvery(buttonsHeaderActionTypes.SALE_TO_VO_COMPANY, saleToVOCompany)
}

export function * approveMargin ({ dossierId, code }) {
  try {
    yield put(showLoader())
    const dossierState = yield call(getDossierStateById, dossierId)
    const auth = yield select(getAuth)
    yield put(hideLoader())
    const confirmed = yield call(yesNoModal, 'approveMargin')
    if (confirmed) {
      yield put(showLoader())
      yield call(putApproveMarginService, dossierId, code, auth.token)
      yield call(initializeDossier, dossierState.type, dossierState.dossier)
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchApproveMargin () {
  yield takeEvery(buttonsHeaderActionTypes.APPROVE_MARGIN, approveMargin)
}
