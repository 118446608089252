import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import LandingRequestModal from '../../../components/dossiers/common/documentaryManagement/landingRequestModal/LandingRequestModal'

import { translate } from 'react-polyglot'

import { getAllDocumentLandingRequest, sendRequestToClient } from '../../../actions/landingRequest/landingRequestActions'

export function mapStateToProps (state, ownProps) {
  return {
    ...state.landingRequestModal,
    showModal: ownProps.showModal,
    closeModal: ownProps.closeModal,
    dossierId: ownProps.dossierId,
    dossierType: ownProps.dossierType
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      getAllDocumentLandingRequest,
      sendRequestToClient
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(LandingRequestModal))
