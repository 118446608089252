import apiFetch from '../apiFetch'
export default function (token, includeDeleted = false) {
  const queryParamsArray = [
    `includeDeleted=${includeDeleted}`
  ]
  const queryParams = queryParamsArray.reduce(
    (result, query, idx) => result + (query ? (result ? `&${query}` : `?${query}`) : '')
    , ''
  )
  return apiFetch({ endPoint: 'paymentMethod' + queryParams, method: 'GET', body: null, token: token })
}
