import { put, call, select, takeEvery } from 'redux-saga/effects'
import { getAuth } from '../../../selectors/access/auth'
import { reset, change } from 'redux-form'
import { yesNoModal } from '../../modalsListeners/yesNoModal'
import dossierActionsTypes from '../../../constants/actions/dossier/dossier'
import { dossierTypes as dossierTypesConstant } from '../../../constants/dossier/common/dossierTypes'
import { fetchSalesDossierSuccess } from '../../../actions/dossier/sales'
import messageModalActions from '../../../actions/modals/messageModal'
import { showLoader, hideLoader } from '../../../actions/common'
import { handleError } from '../../errors/errorManager'
import { initializeDossier } from '../commonDossierSagas'
import deleteFinanceComponent from '../../../services/dossierSale/deleteFinanceComponent'// Header Component
import getStageCheckList from '../../../services/dossier/get/getStageCheckList'
import { getFinancingTypeProductByFinancingTypeSuccess } from '../../../actions/masters/masters'

export function * removeFinanceServices ({ pristine, resolve, reject }) {
  let auth, salesDossierState
  try {
    auth = yield select(getAuth)
    salesDossierState = yield select(state => state.salesDossier)
    let confirmDelete = yield call(yesNoModal, 'deleteFinanceComponentWarning')
    if (confirmDelete) {
      yield put(showLoader())
      yield put(getFinancingTypeProductByFinancingTypeSuccess(null))
      yield call(deleteFinanceComponent, salesDossierState.dossierId, auth.token, salesDossierState.lastAccessDate)
      let checkListHeader = yield call(getStageCheckList, salesDossierState.dossierId, auth.token)

      if (!pristine) {
        yield put(change('salesDossier', 'dossierFinancingComponent', { updated: true }))
      } else {
        yield call(initializeDossier, dossierTypesConstant.sales, salesDossierState)
      }
      yield put(fetchSalesDossierSuccess({ checkListHeader, dossierFinancingComponent: { updated: true }, originalDossierFinancingComponent: { updated: true } }))
    }
    if (resolve) yield call(resolve, confirmDelete)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          const salesDossierState = yield select(state => state.salesDossier)
          yield call(initializeDossier, dossierTypesConstant.sales, salesDossierState)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
      if (reject) yield call(reject, customHandlerError)
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchRemoveFinanceServices () {
  yield takeEvery(dossierActionsTypes.REMOVE_FINANCE, removeFinanceServices)
}

export function * cleanFinanceServices ({ resolve }) {
  try {
    let confirmDelete = yield call(yesNoModal, 'deleteFinanceComponentWarning')
    if (confirmDelete) {
      yield put(change('salesDossier', 'dossierFinancingComponent', { updated: true }))
      yield put(fetchSalesDossierSuccess({ dossierFinancingComponent: { updated: true }, originalDossierFinancingComponent: { updated: true } }))
    }
    if (resolve) {
      yield call(resolve, confirmDelete)
    }
  } catch (error) {
    yield put(reset('salesDossier'))
  } finally {
    yield put(hideLoader())
  }
}

export function * watchCleanFinanceServices () {
  yield takeEvery(dossierActionsTypes.CLEAN_FINANCE, cleanFinanceServices)
}
