import recurrentEntityActionTypes from '../../constants/actions/admin/recurrentEntityAdmin'

export function fetchRecurrentEntityList (filter) {
  return {
    type: recurrentEntityActionTypes.FETCH_RECURRENT_ENTITY_ADMIN,
    filter
  }
}

export function fetchRecurrentEntityAdminSuccess (recurrentEntities, filter) {
  return {
    type: recurrentEntityActionTypes.FETCH_RECURRENT_ENTITY_ADMIN_SUCCESS,
    recurrentEntities,
    filter
  }
}

export function fetchDocumentList (entityId) {
  return {
    type: recurrentEntityActionTypes.FETCH_DOCUMENT_LIST_ADMIN,
    entityId
  }
}

export function fetchDocumentListAdminSuccess (documents, entityId) {
  return {
    type: recurrentEntityActionTypes.FETCH_DOCUMENT_LIST_ADMIN_SUCCESS,
    documents,
    entityId
  }
}

export function openEditRecurrentEntityModal (recurrentEntityId) {
  return {
    type: recurrentEntityActionTypes.OPEN_EDIT_RECURRENT_ENTITY_MODAL,
    entityId: recurrentEntityId
  }
}

export function openEditRecurrentEntityModalSuccess (recurrentEntity) {
  return {
    type: recurrentEntityActionTypes.OPEN_EDIT_RECURRENT_ENTITY_MODAL_SUCCESS,
    recurrentEntity
  }
}

export function openAddRecurrentEntityModal () {
  return {
    type: recurrentEntityActionTypes.OPEN_ADD_RECURRENT_ENTITY_MODAL
  }
}

export function openAddRecurrentEntityModalSuccess () {
  return {
    type: recurrentEntityActionTypes.OPEN_ADD_RECURRENT_ENTITY_MODAL_SUCCESS
  }
}

export function closeEditRecurrentEntityModal () {
  return {
    type: recurrentEntityActionTypes.CLOSE_EDIT_RECURRENT_ENTITY_MODAL
  }
}

export function submitRecurrentEntity (recurrentEntityId, body, filter) {
  return {
    type: recurrentEntityActionTypes.SUBMIT_RECURRENT_ENTITY,
    entityId: recurrentEntityId,
    body,
    filter
  }
}

export function deleteRecurrentEntity (recurrentEntityId, filter) {
  return {
    type: recurrentEntityActionTypes.DELETE_RECURRENT_ENTITY,
    recurrentEntityId,
    filter
  }
}

export function fetchEntitySubTypesList () {
  return {
    type: recurrentEntityActionTypes.FETCH_ENTITY_SUB_TYPES_LIST
  }
}

export function fetchEntitySubTypesListSuccess (recurrentEntitySubTypesList) {
  return {
    type: recurrentEntityActionTypes.FETCH_ENTITY_SUB_TYPES_LIST_SUCCESS,
    recurrentEntitySubTypesList
  }
}

export function uploadRecEntMassDocument (files, code, callback, resolve, t) {
  return {
    type: recurrentEntityActionTypes.UPLOAD_REC_ENTITY_MASS_DOCUMENT,
    files,
    code,
    callback,
    resolve,
    t
  }
}

export function setChangeTabFromMaster (ouIdFromMaster, changeTabFromMaster) {
  return {
    type: recurrentEntityActionTypes.SET_CHANGE_TAB_FROM_MASTER,
    ouIdFromMaster,
    changeTabFromMaster
  }
}

export function setChangeTabFromMasterSuccess (ouIdFromMaster, changeTabFromMaster) {
  return {
    type: recurrentEntityActionTypes.SET_CHANGE_TAB_FROM_MASTER_SUCCESS,
    ouIdFromMaster,
    changeTabFromMaster
  }
}

export function getUserRecurrentServiceEntity (entityId, orderBy) {
  return {
    type: recurrentEntityActionTypes.GET_USER_RECURRENT_SERVICE,
    entityId,
    orderBy
  }
}

export function getUserRecurrentServiceEntitySuccess (entityId, orderBy, userList) {
  return {
    type: recurrentEntityActionTypes.GET_USER_RECURRENT_SERVICE_SUCCESS,
    entityId,
    orderBy,
    userList
  }
}

export function setUserRecurrentServiceEntity (entityId, userId, resolve) {
  return {
    type: recurrentEntityActionTypes.SET_USER_RECURRENT_SERVICE,
    entityId,
    userId,
    resolve
  }
}

export function deleteUserRecurrentServiceEntity (userRecurrentServiceEntityId, resolve) {
  return {
    type: recurrentEntityActionTypes.DELETE_USER_RECURRENT_SERVICE,
    userRecurrentServiceEntityId,
    resolve
  }
}

export function openCloseUserRecurrentServiceModal (showModal, entityId) {
  return {
    type: recurrentEntityActionTypes.OPEN_CLOSE_USER_RECURRENT_SERVICE_MODAL,
    showModal,
    entityId
  }
}

export function openCloseAddUserRecurrentServiceModal (showModal, entityId, userEntityList) {
  return {
    type: recurrentEntityActionTypes.OPEN_CLOSE_ADD_USER_RECURRENT_SERVICE_MODAL,
    showModal,
    entityId,
    userEntityList
  }
}

export function fillFilterFieldAddUserRecurrentServiceModal (filter) {
  return {
    type: recurrentEntityActionTypes.FILL_FILTER_FIELD_ADD_USER_RECURRENT_SERVICE_MODAL,
    filter
  }
}
export function clearUserAdminSearchFilterModal () {
  return {
    type: recurrentEntityActionTypes.CLEAR_USER_ADMIN_SEARCH_FILTER_MODAL
  }
}
