import { put, call, select, takeEvery } from 'redux-saga/effects'
import { getAuth } from '../../selectors/access/auth'
import { initialState as getDashboardTaskInitialState } from '../../reducers/dashboard/dashboardTask'
import { showLoader, hideLoader } from '../../actions/common'
import messageModalActions from '../../actions/modals/messageModal'
import { fetchFilteredDashboardTaskSuccess } from '../../actions/dashboard/dashboardTask'
import dashboardTaskActionTypes from '../../constants/actions/dashboard/dashboardTask'
import getTaskValues from '../../services/task/getTaskValues'
import { handleError } from '../errors/errorManager'

export function * fetchFilteredDashboardTask ({ taskDescription, urgencyType, activeTaskId, orderby, page, pageSize, urgencyTypeTask, resolve, reject }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const dashboardTaskInitialState = getDashboardTaskInitialState()
    if (auth.token !== null) {
      const taskValues = yield call(
        getTaskValues,
        auth.token,
        activeTaskId,
        orderby || dashboardTaskInitialState.orderby,
        page || dashboardTaskInitialState.page,
        pageSize || dashboardTaskInitialState.pageSize,
        urgencyTypeTask || dashboardTaskInitialState.urgencyTypeTask,
        auth.organizedUnit
      )
      yield put(fetchFilteredDashboardTaskSuccess(
        urgencyType,
        activeTaskId,
        taskDescription,
        taskValues,
        orderby || dashboardTaskInitialState.orderby,
        page || dashboardTaskInitialState.page,
        pageSize || dashboardTaskInitialState.pageSize,
        urgencyTypeTask || dashboardTaskInitialState.urgencyTypeTask
      ))
    }
    if (resolve) yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      if (reject) yield call(reject, customHandlerError)
    }
    yield call(handleError, { error, customHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchFilteredDashboardTask () {
  yield takeEvery(dashboardTaskActionTypes.FETCH_FILTERED_DASHBOARD_TASK, fetchFilteredDashboardTask)
}
