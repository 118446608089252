import apiFetch from '../apiFetch'

export default function (token, filters) {
  return apiFetch({
    endPoint: 'DossierFinder/StockManagementDossiers',
    method: 'POST',
    body: filters,
    token: token
  })
}
