import React, { PureComponent } from 'react'
import { reduxForm } from 'redux-form'
import { Col, Row, Table } from 'react-bootstrap'
import { Element } from 'react-scroll'
import StatusHeaderPage from '../../../containers/dossierHistorical/common/StatusHeaderPage'
import ButtonsHeader from '../common/ButtonsHeader'
import ButtonsSidebar from '../../dossiers/common/ButtonsSidebar'
import { compareObjects } from '../../../util/utils'
import { getComponentsPermisions } from '../../../util/permisionFunctions'
import { urgencyTypesClass, permissions } from '../../../constants/backendIds'
import { buttonsHeader } from '../../../constants/dossier/common/buttonsHeader'
import { buttonsSidebar } from '../../../constants/dossier/common/buttonsSidebar'
import RejectCommentModal from '../common/RejectCommentModal'
import Client from '../common/Client'
import Vehicle from '../common/Vehicle'
import PaperworksPage from '../../../containers/dossierHistorical/common/PaperworkComponentPage'
import PurchaseComponent from './PurchaseComponent'
import PurchaseEconomicPlan from './PurchaseEconomicPlan'
import { dossierTypes as dossierTypesConstant } from '../../../constants/dossier/common/dossierTypes'
import { dossierSubTypeId as dossierSubTypeIdConstants } from '../../../constants/dossier/common/dossierSubType'
import DocumentaryManagementPage from '../../../containers/dossierHistorical/common/DocumentaryManagementPage'
import AuditInfo from '../../dossiers/common/audit/AuditInfo'
import OtherData from '../common/OtherData'
import { Link } from 'react-router-dom'
import TrackingContainer from '../../../containers/tracking/trackingContainer'
import { TrackedPages } from '../../../constants/tracking/TrackedPages'
import { purchaseTypesVo } from '../../../constants/dossier/purchase/purchaseTypes'
import CommentComponent from '../../dossiers/common/comment/CommentComponent'
import SmallLoaderPage from '../../../containers/commons/SmallLoaderPage'
import Services from '../common/Services'

class PurchaseHistorical extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      modalOpen: true,
      commentsOpen: true,
      purchaseOpen: true,
      isVehicleLoaded: false,
      isDeliveryLoaded: false,
      isFinanceLoaded: false,
      isClientLoaded: false,
      isOtherDataLoaded: false,
      showRejectCommentModal: false,
      isEconomicLoaded: false,
      activeTabConfiguration: null,
      headerTabConfiguration: null,
      componentsConfiguration: {
        bpurchase: null,
        entity: null,
        vehicle: null,
        paperwork: null,
        other_data: null,
        downloadDocumentsButton: null,
        services: null
      }
    }
    this.goBack = this.goBack.bind(this)
  }

  goBack () {
    this.props.actions.setActiveTab(buttonsHeader.datos_expediente)
    this.props.history.goBack()
  }

  toggleRejectCommentModal (showRejectCommentModal) {
    this.setState({ showRejectCommentModal })
  }

  onClickRelatedDossierColumn (columnCode) {
    let columnCodeOrder = '+' + columnCode
    if (columnCodeOrder === this.props.relatedDossiersOrderBy) {
      columnCodeOrder = '-' + columnCode
    }
    this.props.actions.fetchFilteredRelatedDossier(this.props.dossier.dossierId, columnCodeOrder, true)
  }

  getRelatedDossierColumnIcon (columnCode) {
    if ('+' + columnCode === this.props.relatedDossiersOrderBy) {
      return 'ico-sort-selected-asc'
    } else if ('-' + columnCode === this.props.relatedDossiersOrderBy) {
      return 'ico-sort-selected-desc'
    } else {
      return 'ico-sort'
    }
  }

  submitPurchaseDossier (values) {
    this.props.actions.savePurchaseDossier(values)
  }

  fetchPurchaseDossierPromise (dossierId) {
    return new Promise((resolve, reject) => {
      return this.props.actions.fetchPurchaseDossier(dossierId, resolve, reject, true)
    })
  }

  initializePermisions (props) {
    const componentKeys = Object.keys(this.state.componentsConfiguration)
    const newState = getComponentsPermisions(props.dossier.viewConfiguration.tabsConfiguration, props.activeTab, componentKeys)
    this.setState({ ...this.state, ...newState })
  }

  UNSAFE_componentWillMount () {
    this.props.actions.restartPurchaseState()
    this.fetchPurchaseDossierPromise(this.props.match.params.dossierId).then(() => {
      const form = { ...this.props.dossier }
      if (!this.props.dossier.entityComponent.entityId) delete form.entityComponent
      if (!this.props.dossier.vehicleComponent.vehicleId) delete form.vehicleComponent
      this.props.initialize(form)
      this.initializePermisions(this.props)
      this.props.actions.fetchPurchaseDossierDependants(this.props.match.params.dossierId, true)
      this.props.actions.fetchStockStatusCombo()
      this.props.actions.fetchCountries()
      this.props.actions.fetchSaleTypeAll()
      this.props.actions.fetchPuscharseSaleEntitys(this.props.dossier.organizedUnitId)
      this.props.actions.fetchPurchaseOrderTypeCombo()
      this.props.actions.fetchCustomFields(this.props.dossier.dossierSubTypeId)
      this.props.actions.registerLastAccess(this.props.match.params.dossierId, true)
      this.props.trackVisitPage({
        documentTitle: TrackedPages.PURCHASE_HISTORICAL,
        organizedUnitId: this.props.dossier.organizedUnitId,
        href: window.location.href
      })
    })
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if ((this.props.showManagementModal && !nextProps.showManagementModal) || this.props.match.params.dossierId !== nextProps.match.params.dossierId) {
      if (this.props.match.params.dossierId !== nextProps.match.params.dossierId) {
        this.props.actions.restartPurchaseState()
        this.props.actions.setScrollTo(buttonsSidebar.bpurchase)
        this.props.actions.setActiveSection(buttonsSidebar.bpurchase)
        this.props.actions.setActiveTab(buttonsHeader.datos_expediente)
        this.props.actions.closeAddOrEditDocumentModal()
      }
      this.fetchPurchaseDossierPromise(nextProps.match.params.dossierId).then(() => {
        const form = { ...this.props.dossier }
        if (!this.props.dossier.entityComponent.entityId) delete form.entityComponent
        if (!this.props.dossier.vehicleComponent.vehicleId) delete form.vehicleComponent
        this.props.initialize(form)
        this.initializePermisions(this.props)
        this.props.actions.fetchPurchaseDossierDependants(this.props.match.params.dossierId, true)
      })
    }
    if (JSON.stringify(this.props.dossier.dossierPurchaseEconomicPlanComponent) !== JSON.stringify(nextProps.dossier.dossierPurchaseEconomicPlanComponent)) {
      this.props.initialize(nextProps.dossier)
    }
    if (nextProps.dossier.dossierId && !compareObjects(this.props.dossier.viewConfiguration.tabsConfiguration || {}, nextProps.dossier.viewConfiguration.tabsConfiguration, true)) {
      this.initializePermisions(nextProps)
    }
    if (nextProps.dossier.dossierId && nextProps.activeTab !== this.props.activeTab) {
      this.initializePermisions(nextProps)
    }
  }

  componentDidUpdate () {
    if (this.props.dossier.dossierId && this.props.statusHeaderHeight && this.props.activeSection) {
      switch (this.props.activeSection) {
        case buttonsSidebar.bpurchase:
          this.setState({ purchaseOpen: true })
          break
      }
    }

    let customFields = {}
    const firstCustomFieldsLoad = this.props.dossier.customFieldsByComponent?.length > 0
    if (firstCustomFieldsLoad) {
      if (this.props.dossier.customFieldsValues) {
        this.props.dossier.customFieldsValues.map(x => {
          customFields[x.key] = x.value
        })
      }
      const form = { ...this.props.dossier, customFields: customFields }
      if (!this.props.dossier.entityComponent.entityId) delete form.entityComponent
      if (!this.props.dossier.vehicleComponent.vehicleId) delete form.vehicleComponent
      this.props.initialize(form)
    }
  }

  componentDidMount () {
    this.props.actions.fetchCommercialSocietyTypeCombo()
  }

  componentWillUnmount () {
    this.props.actions.setActiveSection(buttonsSidebar.bpurchase)
    this.props.actions.setScrollTo(null)
    this.props.actions.resetButtonsHeader()
    this.props.actions.closeAddOrEditDocumentModal()
    this.props.actions.restartPurchaseState()
  }

  handleToUpdateComponent (component) {
    if (component.isVehicleLoaded === false || buttonsSidebar.vehicle === component) this.setState({ isVehicleLoaded: true })
    if (component.isClientLoaded === false || buttonsSidebar.entity === component) this.setState({ isClientLoaded: true })
    if (component.isFinanceLoaded === false || buttonsSidebar.finance === component) this.setState({ isFinanceLoaded: true })
    if (component.isDeliveryLoaded === false || buttonsSidebar.delivery === component) this.setState({ isDeliveryLoaded: true })
    if (component.isOtherDataLoaded === false || buttonsSidebar.other_data === component) this.setState({ isOtherDataLoaded: true })
    if (component.isEconomicLoaded === false) this.setState({ isEconomicLoaded: true })
    this.props.actions.setScrollTo(component)
  }

  togglePanel () {
    if (this.state.purchaseOpen) {
      this.props.actions.setActiveSection(false)
    }
    this.setState({ purchaseOpen: !this.state.purchaseOpen })
  }

  render () {
    const {
      t, handleSubmit, combos = {}, pristine, dossier, vehicleProps, change, activeTab, buttonsHeaderHeight, activeSection, statusHeaderHeight, windowWidth, documentExport,
      dossier: {
        dossierId, dossierSubTypeId, dossierParentId, organizedUnitId, checkListHeader, header,
        dossierPurchaseComponent, comments, showAlertSendPending, dossierCompletePaperworks,
        viewConfiguration, viewConfiguration: { buttonsPermission = [], purchaseConfiguration },
        vehicleComponent: { vehicleId }, entityChangeable, dossierRelatedDossiers, auditInfo,
        dossierPurchaseEconomicPlanComponent
      },
      dossierSales: { dossierServiceRelateds },
      combos: {
        fuelCombo, previousUsageCombo, ivaTypeCombo, paymentMethodPurchase, laterUseCombo, vehicleType, brandCombo,
        entityTypeCombo, channelCombo, maritalStatusCombo, segmentationCombo, entitySubTypeCombo, entityFilterSubTypeCombo, purchaseTypeCombo,
        activityClientCombo, stockStatusCombo, purchaseOrderTypeCombo, countries, familyCombo, salesmanCombo, activityBproCombo,
        carlineCombo, catalogueCombo, modelCombo, colorCombo, tapestryCombo, saleTypeAllCombo, puscharseSaleEntitys
      },
      actions: {
        fetchPurchaseDossierDependants,
        openDocumentModal, openDocumentEditionModal, setActiveSection, setActiveTab, setButtonsHeaderHeight, resetButtonsHeader, setScrollerOptions, /* navigateTo, */
        fetchComboOrigin,
        fetchComboDestination,
        refreshAuditInfo,
        fetchAuditDetails,
        fetchAuditDetailsModal,
        fetchDocumentsGroupedAuditDetails,
        fetchDocumentsGroupedAuditDetailsModal,
        fetchPurchaseDossierEconomic,
        fetchPurchaseVehicleHistorical,
        fetchPurchaseClientHistorical,
        fetchPurchaseOtherDataHistorical,
        fecthFamily,
        saveSelectedDocuments,
        updateComment,
        createComment,
        deleteComment,
        markCommentAsRead,
        fetchSalesServices,
        fetchDossierSubTypes,
        fetchEntitySubTypeCombo,
        fetchEntityFilterSubTypeCombo,
        saveSalesService,
        fetchDefaultEntity,
        fetchNotValidDatesService,
        openOrCloseServiceAddModal,
        fetchEntityCountryTypeCombo,
        fetchEntityStateTypeCombo,
        fetchEntityCityTypeCombo,
        fetchEntityTownTypeCombo,
        fetchEntityColonyTypeCombo,
        fetchEntityCPTypeCombo,
        openCommonModal,
        fetchCommercialSocietyTypeCombo,
        fetchActivityBpro,
        fetchClientDataAddress
      }
    } = this.props

    if (!dossierId) return null

    // TODO change isVn condition on PurchaseEconomicPlan
    const tKey = 'DOSSIER_COMPONENTS.'
    const savePurchasePermission = buttonsPermission.includes('purchase_save')
    const panelsPaddingTop = statusHeaderHeight + buttonsHeaderHeight - 82 // 82px es el paddingTop definido en el layout.

    return (
      <div className="dossier-purchase-container">
        <RejectCommentModal
          t={t}
          showModal={this.state.showRejectCommentModal}
          actions={{
            closeModal: () => this.toggleRejectCommentModal(false)
          }}
        />
        <Col sm={12} className="add-dossier-wrapper dossier">
          <StatusHeaderPage
            headerData={header}
            stages={checkListHeader}
            dossierType={dossierTypesConstant.purchase}
            headerTabConfiguration={this.state.headerTabConfiguration}
            goBack={this.goBack}
            dossierId={dossier.dossierId}
          />
        </Col>
        <form autoComplete="off" onSubmit={handleSubmit(this.submitPurchaseDossier.bind(this))}>
          <div className="row-header-buttons">
            <div className="buttons-header-box">
              <ButtonsHeader
                t={t}
                pristine={pristine}
                actions={{
                  openDocumentModal: () => openDocumentModal(dossierId, dossierTypesConstant.purchase, pristine, 1, true),
                  setActiveTab,
                  setButtonsHeaderHeight,
                  resetButtonsHeader,
                  setScrollerOptions
                }}
                dossierId={this.props.match.params.dossierId}
                viewConfiguration={viewConfiguration}
                dossierType={dossierTypesConstant.purchase}
                activeTab={activeTab}
                marginTop={statusHeaderHeight}
                windowWidth={windowWidth}
              />
            </div>
          </div>
          {
            activeTab === buttonsHeader.datos_expediente && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden && (
              <Row className="sales-panel dossier-panel">
                <Col sm={3} className="col-row expediente-menu-izq">
                  <ButtonsSidebar
                    t={t}
                    dossierId={dossierId}
                    historical={true}
                    isClientLoaded = {this.state.isClientLoaded}
                    isVehicleLoaded = {this.state.isVehicleLoaded}
                    isOtherDataLoaded = {this.state.isOtherDataLoaded}
                    handleToUpdateComponent = {this.handleToUpdateComponent.bind(this)}
                    pristine = {pristine}
                    actions={{
                      setActiveSection,
                      fetchPurchaseVehicleHistorical,
                      fetchPurchaseClientHistorical,
                      fetchPurchaseOtherDataHistorical,
                      fetchSalesServices
                    }}
                    dossierType={dossierTypesConstant.purchase}
                    dossierSubTypes={dossierSubTypeId}
                    marginTop={statusHeaderHeight}
                    buttonsHeaderHeight={buttonsHeaderHeight}
                    tabConfiguration={this.state.activeTabConfiguration}
                  />
                </Col>
                <Col sm={9} className="col-row contenido-paneles-expediente">
                  <Col className="panels-wrapper dossier fixed" style={{ paddingTop: panelsPaddingTop }}>
                    {this.state.componentsConfiguration.bpurchase && this.state.componentsConfiguration.bpurchase.permission > permissions.hidden && (
                      <Element name={buttonsSidebar.bpurchase}>
                        <PurchaseComponent
                          t={t}
                          purchaseOpen={this.state.purchaseOpen}
                          showNewVehicleFields={dossierPurchaseComponent.showNewVehicleFields}
                          dossierPurchaseComponent={dossierPurchaseComponent}
                          combos={{ purchaseTypeCombo, stockStatusCombo, purchaseOrderTypeCombo, salesmanCombo, saleTypeAllCombo, puscharseSaleEntitys }}
                          actions={{
                            togglePanel: () => this.togglePanel(),
                            setActiveSection
                          }}
                          readOnlyFields={!savePurchasePermission}
                          dossierType={dossierTypesConstant.purchase}
                          dossierSubType={dossierSubTypeId}
                          componentConfiguration={this.state.componentsConfiguration.bpurchase}
                          hasParent={!!dossierParentId}
                          showPurchaseContract={dossier && dossier.dossierPurchaseComponent && dossier.dossierPurchaseComponent.purchaseTypeId === purchaseTypesVo.scrapping}
                          customFields={this.props.dossier.customFieldsByComponent.find(x => x.componentCode === buttonsSidebar.bpurchase)?.customFields}
                        />
                      </Element>
                    )}
                    {this.state.componentsConfiguration.entity && this.state.componentsConfiguration.entity.permission > permissions.hidden && (
                      <Element name={buttonsSidebar.entity}>
                        <Client
                          t={t}
                          dossier={dossier}
                          handleToUpdateComponent = {this.handleToUpdateComponent.bind(this)}
                          isClientLoaded = {this.state.isClientLoaded}
                          pristine={pristine}
                          combos={{
                            entityTypeCombo,
                            channelCombo,
                            maritalStatusCombo,
                            segmentationCombo,
                            entitySubTypeCombo,
                            entityFilterSubTypeCombo,
                            activityBproCombo
                          }}
                          entityChangeable={entityChangeable}
                          dossierType={dossierTypesConstant.purchase}
                          actions={{
                            change,
                            openDocumentEditionModal,
                            fetchPurchaseClientHistorical,
                            setActiveSection,
                            fetchEntityCountryTypeCombo,
                            fetchEntityStateTypeCombo,
                            fetchEntityCityTypeCombo,
                            fetchEntityTownTypeCombo,
                            fetchEntityColonyTypeCombo,
                            fetchEntityCPTypeCombo,
                            openCommonModal,
                            fetchCommercialSocietyTypeCombo,
                            fetchActivityBpro,
                            fetchClientDataAddress
                          }}
                          readOnlyFields={!savePurchasePermission}
                          open={activeSection === buttonsSidebar.entity}
                          componentConfiguration={this.state.componentsConfiguration.entity}
                          customFields={this.props.dossier.customFieldsByComponent.find(x => x.componentCode === buttonsSidebar.entity)?.customFields}
                        />
                      </Element>
                    )}
                    {this.state.componentsConfiguration.vehicle && this.state.componentsConfiguration.vehicle.permission > permissions.hidden && (
                      <Element name={buttonsSidebar.vehicle}>
                        <Vehicle
                          parentDossierType={{
                            isPaperwork: false,
                            isSales: false,
                            isPurchase: true,
                            isRegistrationDelivery: false
                          }}
                          t={t}
                          actions={{
                            fetchComboOrigin,
                            fetchComboDestination,
                            fetchPurchaseVehicleHistorical,
                            fecthFamily,
                            setActiveSection
                          }}
                          handleToUpdateComponent = {this.handleToUpdateComponent.bind(this)}
                          isVehicleLoaded = {this.state.isVehicleLoaded}
                          dossierId={dossierId}
                          vehicleProps={vehicleProps}
                          dossierParentId={dossierParentId}
                          dossierWithVehicle={!!vehicleId}
                          combos={{ fuelCombo, brandCombo, previousUsageCombo, countries, familyCombo, carlineCombo, catalogueCombo, modelCombo, colorCombo, tapestryCombo }}
                          readOnlyFields={!savePurchasePermission}
                          open={activeSection === buttonsSidebar.vehicle}
                          dossierType={dossierTypesConstant.purchase}
                          dossierSubType={dossierSubTypeId}
                          componentConfiguration={this.state.componentsConfiguration.vehicle}
                          codeVehicleConfiguration={dossierSubTypeId === dossierSubTypeIdConstants.compra_vn ? false : purchaseConfiguration.showCodecombos}
                          organizedUnitId={dossier.organizedUnitId}
                          purchaseType = {this.props.dossier.dossierPurchaseComponent.purchaseTypeId}
                          customFields={this.props.dossier.customFieldsByComponent.find(x => x.componentCode === buttonsSidebar.vehicle)?.customFields}
                        />
                      </Element>
                    )}
                    {this.state.componentsConfiguration.paperwork && this.state.componentsConfiguration.paperwork.permission > permissions.hidden && (
                      <Element name={buttonsSidebar.paperwork}>
                        <PaperworksPage
                          organizedUnitId={organizedUnitId}
                          dossierParentId={dossierId}
                          updateDossierAction={fetchPurchaseDossierDependants}
                          showAlertSendPending={showAlertSendPending}
                          paperwork={dossierCompletePaperworks}
                          readOnlyFields={!savePurchasePermission}
                          dossierType={dossierTypesConstant.purchase}
                          open={activeSection === buttonsSidebar.paperwork}
                          componentConfiguration={this.state.componentsConfiguration.paperwork}
                          customFields={this.props.dossier.customFieldsByComponent.find(x => x.componentCode === buttonsSidebar.paperwork)?.customFields}
                        />
                      </Element>
                    )}
                    {this.state.componentsConfiguration.services && this.state.componentsConfiguration.services.permission > permissions.hidden && (
                      <Element name={buttonsSidebar.services}>
                        <Services
                          t={t}
                          dossier={dossier}
                          isPurchase = {true}
                          combos= {combos }
                          showAddModal={dossierServiceRelateds.showAddModal}
                          datesNotValid={dossierServiceRelateds.datesNotValid}
                          services={dossierServiceRelateds.rows}
                          subTypesAllowedToCreate={dossierServiceRelateds.subTypesToCreate}
                          canAdd={dossierServiceRelateds.canAdd}
                          dossierType={dossierTypesConstant.purchase}
                          readOnlyFields={true}
                          isPreCancelled={false}
                          open={activeSection === buttonsSidebar.services}
                          componentConfiguration={this.state.componentsConfiguration.services}
                          historical = {true}
                          handleToUpdateComponent = {this.handleToUpdateComponent.bind(this)}
                          pristine={pristine}
                          actions={{
                            fetchSalesServices,
                            fetchDossierSubTypes,
                            fetchEntitySubTypeCombo,
                            fetchEntityFilterSubTypeCombo,
                            saveSalesService,
                            fetchDefaultEntity,
                            fetchNotValidDatesService,
                            openOrCloseServiceAddModal,
                            setActiveSection
                          }}
                          customFields={this.props.dossier.customFieldsByComponent.find(x => x.componentCode === buttonsSidebar.services)?.customFields}
                        />
                      </Element>
                    )}
                    {dossierSubTypeId === dossierSubTypeIdConstants.compra_vo && this.state.componentsConfiguration.other_data && this.state.componentsConfiguration.other_data.permission > permissions.hidden && (
                      <Element name={buttonsSidebar.other_data}>
                        <OtherData
                          t={t}
                          dossierType={dossierTypesConstant.purchase}
                          combos={{
                            ivaTypeCombo,
                            paymentMethodPurchase,
                            laterUseCombo,
                            vehicleType,
                            previousUsageCombo,
                            activityClientCombo
                          }}
                          actions={{ fetchPurchaseOtherDataHistorical, setActiveSection }}
                          dossierId={dossierId}
                          pristine={pristine}
                          handleToUpdateComponent={this.handleToUpdateComponent.bind(this)}
                          isOtherDataLoaded={this.state.isOtherDataLoaded}
                          readOnlyFields={!savePurchasePermission}
                          open={activeSection === buttonsSidebar.other_data}
                          componentConfiguration={this.state.componentsConfiguration.other_data}
                          customFields={this.props.dossier.customFieldsByComponent.find(x => x.componentCode === buttonsSidebar.other_data)?.customFields}
                        />
                      </Element>
                    )}
                  </Col>
                </Col>
              </Row>
            )
          }
          {
            activeTab === buttonsHeader.gestor_documental && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden && (
              <Row className="sales-panel dossier-panel">
                <Col sm={12} className="col-row" style={{ paddingTop: panelsPaddingTop }}>
                  <DocumentaryManagementPage
                    dossier={dossier}
                    dossierType={dossierTypesConstant.sales}
                    saveSelectedDocuments={saveSelectedDocuments}
                    documentExport={documentExport}
                    combos={this.props.scombos}
                    isHistorical={true}
                    tabConfiguration={this.state.activeTabConfiguration}
                  />
                </Col>
              </Row>
            )
          }
          {
            activeTab === buttonsHeader.expedientes_relacionados && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden && (
              <Row className="sales-panel dossier-panel panel-expedientes-relacionados">
                <Col sm={12} className="col-row" style={{ paddingTop: panelsPaddingTop }}>
                  <Col sm={6} className="col-row">
                    <h4><i className="ico-relacion" /> {t(`${tKey}RELATED_DOSSIER.RELATED_DOSSIER`)}</h4>
                  </Col>
                  <Col sm={12} className="col-row">
                    <Table hover responsive className="simple-table dashboard-tasks-table" id="related-dossiers-table">
                      <thead>
                        <tr>
                          <th onClick={this.onClickRelatedDossierColumn.bind(this, 'urgencyType')}>
                            <i className={this.getRelatedDossierColumnIcon('urgencyType')} />
                          </th>
                          <th onClick={this.onClickRelatedDossierColumn.bind(this, 'number')}>
                            {t(`${tKey}RELATED_DOSSIER.DOSSIER_NUMBER`)} <i className={this.getRelatedDossierColumnIcon('number')} />
                          </th>
                          <th onClick={this.onClickRelatedDossierColumn.bind(this, 'organizedUnit')}>
                            {t(`${tKey}RELATED_DOSSIER.UO`)} <i className={this.getRelatedDossierColumnIcon('organizedUnit')} />
                          </th>
                          <th onClick={this.onClickRelatedDossierColumn.bind(this, 'dossierSubType')}>
                            {t(`${tKey}RELATED_DOSSIER.SUBTYPE`)} <i className={this.getRelatedDossierColumnIcon('dossierSubType')} />
                          </th>
                          <th onClick={this.onClickRelatedDossierColumn.bind(this, 'stage')}>
                            {t(`${tKey}RELATED_DOSSIER.STATE`)} <i className={this.getRelatedDossierColumnIcon('stage')} />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          dossierRelatedDossiers.map((relatedDossier, idx) => {
                            return (
                              <tr key={idx}>
                                <td>
                                  <span className={'color-point ' + urgencyTypesClass[relatedDossier.urgencyType]}></span>
                                </td>
                                <td>
                                  {
                                    relatedDossier.urlToNavigate ? (
                                      <Link to={`/dossier${relatedDossier.urlToNavigate}`}>
                                        {relatedDossier.number}
                                      </Link>
                                    ) : relatedDossier.number
                                  }
                                </td>
                                <td>{relatedDossier.organizedUnit}</td>
                                <td>{relatedDossier.dossierSubType}</td>
                                <td>{relatedDossier.stage}</td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </Table>
                  </Col>
                </Col>
              </Row>
            )
          }
          {
            activeTab === buttonsHeader.planteo_economico && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden && (
              <PurchaseEconomicPlan
                t={t}
                isVN={dossierPurchaseComponent.showNewVehicleFields}
                actions={{ fetchPurchaseDossierEconomic }}
                combos={{ ivaTypeCombo }}
                readOnlyFields={!savePurchasePermission}
                panelsPaddingTop={panelsPaddingTop}
                dossierId={dossierId}
                historical={true}
                pristine={pristine}
                isEconomicLoaded={this.state.isEconomicLoaded}
                handleToUpdateComponent={this.handleToUpdateComponent.bind(this)}
                dossierEconomicPlan = {dossierPurchaseEconomicPlanComponent}
                dossierPurchaseComponent = {dossierPurchaseComponent}
                tabsConfiguration = {viewConfiguration.tabsConfiguration}
                activeTab={activeTab}
                openDocumentEditionModal = {openDocumentEditionModal}
              />
            )
          }
          {
            activeTab === buttonsHeader.actividad_expediente && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden && (
              <Row className="sales-panel dossier-panel panel-actividad-expediente">
                <Col sm={12} className="col-row" style={{ paddingTop: panelsPaddingTop }}>
                  <AuditInfo
                    auditInfo={auditInfo}
                    dossierType={dossierTypesConstant.sales}
                    actions={{ refreshAuditInfo, fetchAuditDetails, fetchAuditDetailsModal, fetchDocumentsGroupedAuditDetails, fetchDocumentsGroupedAuditDetailsModal }}
                    dossier={dossier}
                    historical={true}
                    t={t}
                  />
                </Col>
              </Row>
            )
          }
          {
            activeTab === buttonsHeader.observaciones && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden && (
              <Row className="sales-panel dossier-panel panel-observaciones-expediente">
                <Col sm={12} className="col-row" style={{ paddingTop: panelsPaddingTop }}>
                  <Col sm={6} className="col-row">
                    <h4><i className="ico-bocadillo" onClick={() => { this.setState({ modalOpen: !this.state.modalOpen }) }}/> {t(`${tKey}COMMENTS.TITLE_PANEL`)}</h4>
                  </Col>
                  <Col sm={12} className="col-row">
                    <CommentComponent
                      comments={comments}
                      actions={{ updateComment, createComment, deleteComment, markCommentAsRead }}
                      readOnlyFields={!savePurchasePermission}
                      dossierType="purchaseDossier"
                      t={t}
                      tKey={tKey}
                      combos={{ allReasonCombo: this.props.combos.allReasonCombo }}
                      openComponent={activeTab === buttonsHeader.observaciones}
                      dossierId={this.props.dossier.dossierId}
                      historical={true}
                    />
                    <SmallLoaderPage/>
                  </Col>
                </Col>
              </Row>
            )}
        </form>
      </div>
    )
  }
}

var decoratedComponent = TrackingContainer(PurchaseHistorical, false)

export default reduxForm({
  form: 'purchase_dossier'
})(decoratedComponent)
