import React, { PureComponent } from 'react'
// import { Glyphicon } from 'react-bootstrap'
import { permissions } from '../../../../../constants/backendIds'
import { buttonsHeader } from '../../../../../constants/dossier/common/buttonsHeader'
import { buttonsSidebarId } from '../../../../../constants/dossier/common/buttonsSidebar'
import { documentEntityType as entityType } from '../../../../../constants/dossier/common/documentEntityType'
import { dossierTypes as dossierTypesConstant } from '../../../../../constants/dossier/common/dossierTypes'

class TimeLine extends PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      liClass: '',
      showConditions: true
    }
    this.handleDocumentClick = this.handleDocumentClick.bind(this)
    this.openDocModal = this.openDocModal.bind(this)
  }

  openDocModal (doc) {
    const callback = () => {
      if (this.props.activeTab === buttonsHeader.gestor_documental) {
        this.props.actions.refreshDocumentManagementModal(this.props.dossierId)
      }
    }
    if (doc.objectId) {
      const entityId = doc.documentParentTypeId === entityType.ENTITY ? doc.objectId : null
      const vehicleId = doc.documentParentTypeId === entityType.VEHICLE ? doc.objectId : null

      if (doc.isHistorical) {
        this.props.actions.openDocumentEditionModalHistorical(doc.documentId, doc.documentTypeUse, doc.documentParentTypeId, doc.objectId, entityId, vehicleId, doc.organizedUnitId, doc.checklistId, callback, true, this.props.dossierType, this.props.dossierSubType)
      } else {
        this.props.actions.openDocumentEditionModal(doc.documentId, doc.documentTypeUse, doc.documentParentTypeId, this.props.dossierId, entityId, vehicleId, doc.organizedUnitId, doc.checklistId, callback, true, this.props.dossierType, this.props.dossierSubType, null, this.props.pristine, null, false, this.props.editableDoc, this.props.readOnlyFields)
      }
    } else {
      const alert = doc.documentParentTypeId === entityType.ENTITY ? 'entityAlert' : 'vehicleAlert'
      this.props.actions.openModal(alert)
    }
  }

  handleDocumentClick (doc) {
    if (this.props.actions.openDocWarning && this.props.pristine !== undefined && this.props.pristine !== null && this.props.pristine === false &&
      doc.couldChangeDossierData !== null && doc.couldChangeDossierData !== undefined && doc.couldChangeDossierData === true) {
      new Promise((resolve, reject) => {
        this.props.actions.openDocWarning(resolve, reject)
      }).then(() => {
        this.openDocModal(doc)
      })
    } else {
      this.openDocModal(doc)
    }
  }

  handleGetComponent (buttonSideBar) {
    return new Promise((resolve) => {
      switch (buttonSideBar) {
        case 'vehicle':
          if (this.props.isVehicleLoaded === false) {
            return new Promise((resolve, reject) => {
              this.props.handleToUpdateVehicle()
              if (this.props.dossierType === dossierTypesConstant.sales) {
                return this.props.actions.fetchSalesVehicle(this.props.dossierId, resolve, reject, this.props.pristine)
              } else if (this.props.dossierType === dossierTypesConstant.purchase) {
                return this.props.actions.fetchPurchaseVehicle(this.props.dossierId, resolve, reject, this.props.pristine)
              } else if (this.props.dossierType === dossierTypesConstant.paperwork) {
                return this.props.actions.fetchPaperworkVehicle(this.props.dossierId, resolve, reject, this.props.pristine)
              } else if (this.props.dossierType === dossierTypesConstant.campaign) {
                return this.props.actions.fetchCampaignVehicle(this.props.dossierId, resolve, reject, this.props.pristine)
              }
            })
          }
          break
        case 'entity':
          if (this.props.isClientLoaded === false) {
            return new Promise((resolve, reject) => {
              this.props.handleToUpdateClient()
              if (this.props.dossierType === dossierTypesConstant.sales) {
                return this.props.actions.fetchSalesClient(this.props.dossierId, resolve, reject, this.props.pristine)
              } else if (this.props.dossierType === dossierTypesConstant.purchase) {
                return this.props.actions.fetchPurchaseClient(this.props.dossierId, resolve, reject, this.props.pristine)
              } else if (this.props.dossierType === dossierTypesConstant.paperwork) {
                return this.props.actions.fetchPaperworkClient(this.props.dossierId, resolve, reject, this.props.pristine)
              } else if (this.props.dossierType === dossierTypesConstant.campaign) {
                return this.props.actions.fetchCampaignClient(this.props.dossierId, resolve, reject, this.props.pristine)
              }
            })
          }
          break
        case 'finance':
          if (this.props.historical === false) {
            return new Promise((resolve, reject) => {
              this.props.handleToUpdateFinance()
              return this.props.actions.fetchSalesFinancing(this.props.dossierId, resolve, reject, this.props.pristine)
            })
          }
          break
        case 'delivery':
          if (this.props.isDeliveryLoaded === false) {
            return new Promise((resolve, reject) => {
              this.props.handleToUpdateDelivery()
              if (this.props.dossierType === dossierTypesConstant.sales) {
                return this.props.actions.fetchSalesDelivery(this.props.dossierId, resolve, reject, this.props.pristine)
              } else if (this.props.dossierType === dossierTypesConstant.registrationDelivery) {
                return this.props.actions.fetchRegistrationDeliveryDelivery(this.props.dossierId, resolve, reject, this.props.pristine)
              }
            })
          }
          break
        case 'self_supply':
          if (this.props.isSelfSupplyLoaded === false) {
            return new Promise((resolve, reject) => {
              this.props.handleToUpdateSelfSupply()
              return this.props.actions.fetchSalesSelfSupply(this.props.dossierId, this.props.pristine, resolve, reject)
            })
          }
          break
        case 'economic':
          if (this.props.isEconomicLoaded === false) {
            return new Promise((resolve, reject) => {
              this.props.handleToUpdateEconomic()
              return this.props.actions.fetchSalesEconomic(this.props.dossier.dossierId, this.props.historical, this.props.pristine, resolve, reject)
            })
          }
          break
      }
      resolve()
    })
  }

  async handleDataClick (componentAction = 0) {
    switch (componentAction) {
      case 0:
        break
      case 13:
        if (this.props.showEconomicPlan) this.props.actions.setActiveTab(buttonsHeader.planteo_economico)
        break
      case 15:
        this.props.actions.setActiveTab(buttonsHeader.observaciones)
        break
      default:
        this.props.actions.setActiveTab(buttonsHeader.datos_expediente)
        this.props.actions.setActiveSection(buttonsSidebarId[componentAction])
        await this.nuevafuncion(componentAction)
    }
  }

  async nuevafuncion (componentAction) {
    await this.handleGetComponent(buttonsSidebarId[componentAction])
    return new Promise((resolve, reject) => {
      this.props.actions.setScrollTo(buttonsSidebarId[componentAction], null, 'dossierData')
    })
  }

  toogleHideConditions () {
    this.setState({ showConditions: !this.state.showConditions })
  }

  render () {
    const {
      t, stages = [], checklistPermission, showTitlePage
    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.HEADER.'
    if (stages.length === 0 || this.props.show === false) return (<div/>)
    const localStages = stages
    const activeStage = localStages.find(stage => stage.isActive)
    return (
      <div className='conditionals'>
        {checklistPermission >= permissions.hidden && activeStage &&
        <div className={'time-line-list ' + (this.state.showConditions ? '' : 'hide')}>
          {activeStage.docs &&
            <div className='time-line-list-group documents' id={'TIMELINE_DOCUMENTS'}>
              <div className='time-line-list-group-header'>
                <i className='iDocIcon-file-document'/>
                <p>{t(`${tKey}POPOVER.DOCUMENTS`)}:</p>
              </div>
              <div className='time-line-list-group-body'>
                <ul id="Timeline_DocumentsList">
                  {activeStage.docs.map((doc, idx) => (
                    <li
                      key={idx}
                      className={(checklistPermission > permissions.hidden ? 'abrir-modal-documentos ' : '') + (doc.isComplete ? 'complete' : 'warning')}
                      onClick={() => checklistPermission > permissions.hidden && this.handleDocumentClick(doc)}
                      documentcode={doc.documentCode} >
                      <div className='name'>
                        <i className={'type documents ' + (doc.isComplete ? 'iDocIcon-done-ok' : 'iDocIcon-warning')}/>
                        {/* <i className={'status ' + (doc.isComplete ? 'iDocIcon-done-ok' : 'iDocIcon-warning')}/> */}
                        <span className="nombre-datos-timeline">{doc.name}</span>
                      </div>
                      <div className='action'><i className={'iDocIcon-open-in-new-black ' + (doc.isComplete ? '' : 'warning')}></i></div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          }
          {activeStage.fields &&
            <div className='time-line-list-group fields' id={'TIMELINE_FIELDS'}>
              <div className='time-line-list-group-header'>
                <i className='iDocIcon-description-file-data'/>
                <p>{t(`${tKey}POPOVER.FIELDS`)}:</p>
              </div>
              <div className='time-line-list-group-body'>
                <ul id="Financial_fieldsList">
                  {activeStage.fields.map((fields, idx) => (
                    <li
                      key={idx}
                      className={(fields.componentAction && checklistPermission > permissions.hidden ? 'navegar-seccion ' : '') + (fields.isComplete ? 'complete' : 'warning')}
                      onClick={() => checklistPermission > permissions.hidden && this.handleDataClick(fields.componentAction)}
                      functiontopass={fields.functionToPass}
                    >
                      <div className='name'>
                        <i className={'type fields ' + (fields.isComplete ? 'iDocIcon-done-ok' : 'iDocIcon-warning')}/>
                        <span className="nombre-datos-timeline">{fields.name}</span>
                      </div>
                      <div className='action'><i className={'iDocIcon-edit ' + (fields.isComplete ? '' : 'warning')}></i></div>
                    </li>))}
                </ul>
              </div>
            </div>
          }
          {activeStage.others &&
            <div className='time-line-list-group others' id={'TIMELINE_OTHERS'}>
              <div className='time-line-list-group-header'>
                <i className='iDocIcon-ads-actions'/>
                <p>{t(`${tKey}POPOVER.OTHERS`)}:</p>
              </div>
              <div className='time-line-list-group-body'>
                <ul id="Financial_othersList">
                  {activeStage.others.map((other, idx) => (
                    <li
                      key={idx}
                      className={(other.componentAction && checklistPermission > permissions.hidden ? 'navegar-seccion ' : '') + (other.isComplete ? 'complete' : 'warning')}
                      onClick={() => checklistPermission > permissions.hidden && this.handleDataClick(other.componentAction)}
                      functiontopass={other.functionToPass}
                    >
                      <div className='name'>
                        <i className={'type others ' + (other.isComplete ? 'iDocIcon-done-ok' : 'iDocIcon-warning')}/>
                        <span className="nombre-datos-timeline">{other.name}</span>
                      </div>
                      <div className='action'></div>
                    </li>))}
                </ul>
              </div>
            </div>
          }
        </div>}
        {showTitlePage === false &&
          <a onClick={() => this.toogleHideConditions()} className="_Btn closeConditions"><i class={'iDocIcon-caret-right-solid ' + (this.state.showConditions ? '' : 'rotate')}></i></a>
        }
      </div>
    )
  }
}

export default TimeLine
