import clientActionTypes from '../../../constants/actions/dossier/common/client'

export function setEntityTypeId (entityTypeId, prevEntityTypeId, dossierType, resolve, reject) {
  return {
    type: clientActionTypes.SET_ENTITY_TYPE_ID,
    entityTypeId,
    prevEntityTypeId,
    dossierType,
    resolve,
    reject
  }
}

export function setEntityChildTypeId (entityTypeId, prevEntityTypeId, dossierType, resolve, reject) {
  return {
    type: clientActionTypes.SET_ENTITY_CHILD_TYPE_ID,
    entityTypeId,
    prevEntityTypeId,
    dossierType,
    resolve,
    reject
  }
}

export function setEntitySubTypeId (entitySubTypeId, dossierType, resolve, reject) {
  return {
    type: clientActionTypes.SET_ENTITY_SUBTYPE_ID,
    entitySubTypeId,
    dossierType,
    resolve,
    reject
  }
}

export function setEntityId (entityId, dossierType, resolve, reject) {
  return {
    type: clientActionTypes.SET_ENTITY_ID,
    entityId,
    dossierType,
    resolve,
    reject
  }
}

export function setEntityProviderId (entityId, dossierType, resolve, reject) {
  return {
    type: clientActionTypes.SET_ENTITY_PROVIDER_ID,
    entityId,
    dossierType,
    resolve,
    reject
  }
}
export function fetchClientByDni (dni, ouId, dossierType, resolve, reject) {
  return {
    type: clientActionTypes.FETCH_CLIENT_DNI,
    dni,
    ouId,
    dossierType,
    resolve,
    reject
  }
}

export function fetchClientByRfc (rfc, ouId, dossierType, resolve, reject) {
  return {
    type: clientActionTypes.FETCH_CLIENT_RFC,
    rfc,
    ouId,
    dossierType,
    resolve,
    reject
  }
}

export function fetchClientByDniEntityChild (dni, ouId, dossierType, resolve, reject) {
  return {
    type: clientActionTypes.FETCH_CLIENT_DNI_ENTITY_CHILD,
    dni,
    ouId,
    dossierType,
    resolve,
    reject
  }
}

export function fetchDefaultEntity (dossierSubTypeId, ouId, resolve, reject) {
  return {
    type: clientActionTypes.FETCH_DEFAULT_ENTITY,
    dossierSubTypeId,
    ouId,
    resolve,
    reject
  }
}

export function fetchClientFromDocument (entityId, parentDossierType) {
  return {
    type: clientActionTypes.FETCH_CLIENT_FROM_DOCUMENT,
    entityId,
    parentDossierType
  }
}

export function resetValues (resolve, reject) {
  return {
    type: clientActionTypes.RESET_VALUES,
    resolve,
    reject
  }
}

export function fetchProviderFromDocument (entityId, parentDossierType) {
  return {
    type: clientActionTypes.FETCH_PROVIDER_FROM_DOCUMENT,
    entityId,
    parentDossierType
  }
}

export function fetchClientFromParent (entityFromParentDossier, dossierType, entityParentId, pristine) {
  return {
    type: clientActionTypes.FETCH_CLIENT_FROM_PARENT,
    entityFromParentDossier,
    dossierType,
    entityParentId,
    pristine
  }
}

export function clearEntity (dossierId, dossierSubTypeId, isFromParent = false) {
  return {
    type: clientActionTypes.CLEAR_ENTITY,
    dossierId,
    dossierSubTypeId,
    isFromParent
  }
}

export function clearEntityChild (dossierId, dossierSubTypeId) {
  return {
    type: clientActionTypes.CLEAR_ENTITY_CHILD,
    dossierId,
    dossierSubTypeId
  }
}

export function setEntityValidationDisable (dossierTypeId, disableDniCifValidation, disableRepDniCifValidation, disableChildDniCifValidation, disableChildRepDniCifValidation) {
  return {
    type: clientActionTypes.SET_ENTITY_VALIDATION_DISABLE,
    dossierTypeId,
    disableDniCifValidation,
    disableRepDniCifValidation,
    disableChildDniCifValidation,
    disableChildRepDniCifValidation
  }
}

export function fetchClientUacModal (entityId) {
  return {
    type: clientActionTypes.FETCH_CLIENT_UAC_MODAL,
    entityId
  }
}

export function setEntityProviderTypeId (entityTypeId, prevEntityTypeId, dossierType, resolve, reject) {
  return {
    type: clientActionTypes.SET_ENTITY_PROVIDER_TYPE_ID,
    entityTypeId,
    prevEntityTypeId,
    dossierType,
    resolve,
    reject
  }
}

export function setEntityProviderSubTypeId (entitySubTypeId, dossierType, resolve, reject) {
  return {
    type: clientActionTypes.SET_ENTITY_PROVIDER_SUBTYPE_ID,
    entitySubTypeId,
    dossierType,
    resolve,
    reject
  }
}

export function setEntityProviderValidationDisable (dossierTypeId, disableDniCifValidation, disableRepDniCifValidation, disableChildDniCifValidation, disableChildRepDniCifValidation) {
  return {
    type: clientActionTypes.SET_ENTITY_PROVIDER_VALIDATION_DISABLE,
    dossierTypeId,
    disableDniCifValidation,
    disableRepDniCifValidation,
    disableChildDniCifValidation,
    disableChildRepDniCifValidation
  }
}

export function clearClientFields (entityTypeId, dossierType) {
  return {
    type: clientActionTypes.CLEAR_CLIENT_FIELDS,
    entityTypeId,
    dossierType
  }
}

export function fetchClientByPhoneEmail (organizationUnitId, phone, email, resolve) {
  return {
    type: clientActionTypes.FETCH_CLIENT_PHONE_EMAIL,
    organizationUnitId,
    phone,
    email,
    resolve
  }
}

export default {
  fetchDefaultEntity,
  setEntityTypeId,
  setEntitySubTypeId,
  setEntityId,
  fetchClientByDni,
  fetchClientFromDocument,
  resetValues,
  fetchClientFromParent,
  setEntityChildTypeId,
  fetchClientByDniEntityChild,
  clearEntity,
  clearEntityChild,
  setEntityValidationDisable,
  fetchClientUacModal,
  fetchClientByRfc,
  setEntityProviderTypeId,
  setEntityProviderSubTypeId,
  setEntityProviderValidationDisable,
  clearClientFields,
  setEntityProviderId,
  fetchProviderFromDocument,
  fetchClientByPhoneEmail
}
