import apiFetch from '../apiFetch'

export default function (suppliedId, suppliedValues, uoId, token) {
  const body = {
    organizedUnitId: uoId,
    suppliedTypeId: suppliedId,
    isDeleted: false,
    suppliedTypeLanguages: suppliedValues
  }
  return apiFetch({ endPoint: `SuppliedType/${suppliedId}`, method: 'PUT', body: body, token: token })
}
