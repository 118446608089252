import stockActionTypes from '../../constants/actions/reports/stockReport'

function initialState () {
  return {
    stockList: [],
    pagesCount: 0,
    stockCount: 0,
    filter: {
      page: 1,
      orderBy: '',
      isDeleted: null,
      pageSize: 10
    }
  }
}

function fetchStockReportSuccess (state, { stockList, filter }) {
  return {
    ...state,
    stockList: stockList.stockList,
    stockCount: stockList.stockCount,
    pagesCount: stockList.pagesCount,
    filter
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case stockActionTypes.FETCH_STOCK_REPORT_SUCCESS:
      return fetchStockReportSuccess(state, action)
    default:
      return state
  }
}
