import apiFetch from '../apiFetch'

export default function (checkListId, token, referenceType, referenceId) {
  return apiFetch({
    endPoint: `checkList/${checkListId}/generate`,
    method: 'GET',
    token: token,
    parseBlobAndAssign: true
  })
}
