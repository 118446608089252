import { put, call, select, takeEvery } from 'redux-saga/effects'
import { initialize, reset } from 'redux-form'
import dossierActionsTypes from '../../../constants/actions/dossier/dossier'
import { dossierTypes as dossierTypesConstant } from '../../../constants/dossier/common/dossierTypes'
import { showLoader, hideLoader } from '../../../actions/common'
import messageModalActions from '../../../actions/modals/messageModal'
import { yesNoModal } from '../../modalsListeners/yesNoModal'
import { fetchRegistrationDeliveryDossier } from './fetchRegistrationDeliveryDossier'
import { getAuth } from '../../../selectors/access/auth'
import putApproveByRole from '../../../services/dossierSale/putApproveByRole'
import { handleError } from '../../errors/errorManager'
import { initializeDossier } from '../commonDossierSagas'
import putSkipItvService from '../../../services/dossierSale/putSkipItv'

export function * registrationDeliveryWarningSave ({ reset, router, nextLocation, pristine }) {
  if (!pristine) {
    const confirmedSaveWarning = yield call(yesNoModal, 'commonDossierSaveWarning')
    if (confirmedSaveWarning) {
      const promise = new Promise((resolve, reject) => {
        reset('registrationDeliveryDossier')
        resolve()
      })
      promise.then(() => {
        router.replace(nextLocation)
      })
    }
  }
}

export function * watchRegistrationDeliveryWarningSave () {
  yield takeEvery(dossierActionsTypes.REGISTRATION_DELIVERY_WARNING_SAVE, registrationDeliveryWarningSave)
}

export function * saleApproveByRole ({ pristine, approvalRoleCode, hideConfirmModal }) {
  try {
    if (!pristine) {
      const confirmedSaveWarning = yield call(yesNoModal, 'commonDossierSaveWarning')
      if (confirmedSaveWarning) {
        const auth = yield select(getAuth)
        const salesDossierState = yield select(state => state.salesDossier)
        const confirmed = hideConfirmModal ? true : yield call(yesNoModal, 'saleDossierApproveByRole')
        if (confirmed) {
          yield put(showLoader())
          yield call(putApproveByRole, salesDossierState.dossierId, approvalRoleCode, auth.token, salesDossierState.lastAccessDate)
          yield call(fetchRegistrationDeliveryDossier, { dossierId: salesDossierState.dossierId })
          const updatedSalesDossier = yield select(state => state.salesDossier)
          yield put(initialize('salesDossier', updatedSalesDossier))
        }
      }
    } else {
      const auth = yield select(getAuth)
      const salesDossierState = yield select(state => state.salesDossier)
      const confirmed = hideConfirmModal ? true : yield call(yesNoModal, 'saleDossierApproveByRole')
      if (confirmed) {
        yield put(showLoader())
        yield call(putApproveByRole, salesDossierState.dossierId, approvalRoleCode, auth.token, salesDossierState.lastAccessDate)
        yield call(fetchRegistrationDeliveryDossier, { dossierId: salesDossierState.dossierId })
        const updatedSalesDossier = yield select(state => state.salesDossier)
        yield put(initialize('salesDossier', updatedSalesDossier))
      }
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          const salesDossierState = yield select(state => state.salesDossier)
          yield call(initializeDossier, dossierTypesConstant.sales, salesDossierState)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSaleApproveByRole () {
  yield takeEvery(dossierActionsTypes.SALE_APPROVE_BY_ROLE, saleApproveByRole)
}

export function * hasOptionals ({ hasOptionals, appliedCampaigns, campaignId, value, resolve }) {
  try {
    yield put(showLoader())
    let confirmedSaveWarning
    if (hasOptionals === true && value === true) {
      yield put(hideLoader())
      confirmedSaveWarning = yield call(yesNoModal, 'modalCampaignOptionals')
    }
    if (confirmedSaveWarning === false) {
      appliedCampaigns['cam' + campaignId] = false
      yield put(initialize('campaign_configure_modal', appliedCampaigns))
    } else {
      appliedCampaigns['cam' + campaignId] = value
      yield put(initialize('campaign_configure_modal', appliedCampaigns))
    }
    if (resolve) resolve(confirmedSaveWarning)
  } catch (error) {
  } finally {
    yield put(hideLoader())
  }
}

export function * watchHasOptionals () {
  yield takeEvery(dossierActionsTypes.CONFIGURE_CAMPAIGN_HASOPTIONALS, hasOptionals)
}

export function * skipItv ({ dossierId }) {
  try {
    let confirm = yield call(yesNoModal, 'skipItvModal')
    if (confirm) {
      yield put(showLoader())
      const auth = yield select(getAuth)
      yield call(putSkipItvService, dossierId, auth.token)
      yield put(hideLoader())
    }
  } catch (error) {
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSkipeItv () {
  yield takeEvery(dossierActionsTypes.SKIP_ITV, skipItv)
}
