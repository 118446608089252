import React, { PureComponent } from 'react'
import { Col } from 'react-bootstrap'
import InputDatePicker from './InputDatePicker'
import InputTimePicker from './InputTimePicker'

class InputDateTimePicker extends PureComponent {
  constructor (props) {
    super()
    const { date, time } = this.splitDateTime(props.input.value)
    this.state = {
      date,
      time
    }
  }

  getSnapshotBeforeUpdate () {
    if (this.state.date + ' ' + this.state.time !== this.props.input.value) {
      const { date, time } = this.splitDateTime(this.props.input.value)
      this.setState({
        date,
        time
      })
    }
    return null
  }

  componentDidUpdate () {
    // getSnapshotBeforeUpdate() should be used with componentDidUpdate()
  }

  splitDateTime (datetime) {
    const [date, time] = datetime.split(' ')
    return { date, time }
  }

  handleChangeDate (value) {
    this.setState({ date: value })
    this.props.input.onChange(value + ' ' + this.state.time)
    if (this.props.onInputChange) {
      this.props.onInputChange(value + ' ' + this.state.time)
    }
  }

  handleChangeTime (value) {
    this.setState({ time: value })
    this.props.input.onChange(this.state.date + ' ' + value)
    if (this.props.onInputChange) {
      this.props.onInputChange(this.state.date + ' ' + value)
    }
  }

  render () {
    const { colSm = 6, controlLabel, input, id } = this.props
    return (
      <div>
        <Col sm={colSm}>
          <label className="control-label">{controlLabel}</label>
        </Col>
        <InputDatePicker
          {...this.props}
          controlLabel={false}
          input={{
            ...input,
            value: this.state.date,
            onChange: this.handleChangeDate.bind(this)
          }}
          colSm={colSm / 2}
          id = {id}
        />
        <InputTimePicker
          {...this.props}
          controlLabel={false}
          input={{
            ...input,
            value: this.state.time,
            onChange: this.handleChangeTime.bind(this)
          }}
          colSm={colSm / 2}
          id={id}
        />
      </div>
    )
  }
}

export default InputDateTimePicker
