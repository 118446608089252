import { loadAuth } from './services/sessionAuth'
import authActions from './actions/auth'
// import { setSectionByPath } from './actions/common'

export function isAuthenticated (store, state) {
  const auth = store.getState().auth
  if (!auth.token) {
    const auth = loadAuth()
    if (auth) {
      store.dispatch(authActions.setAuth(auth))
      // store.dispatch(setSectionByPath(state.routes[3].sectionCode))
      return true
    } else {
      return false
    }
  } else {
    return true
  }
}

export default {
  isAuthenticated
}
