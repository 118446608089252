import React, { PureComponent } from 'react'
import { Button } from 'react-bootstrap'
import { reduxForm, Field } from 'redux-form'
import InputSelect from '../../../../_v2/components/commons/form/InputSelect'
import { dossierSubTypeId as dossierSubTypesIds } from '../../../../constants/dossier/common/dossierSubType'
import UpdateDossierPage from '../../../containers/dossiers/commons/UpdateDossierPage'
/// /////////////////////////////////////////////////
// import MapSections from '../../../../_v2/util/map-sections_v2'

class NewDossier extends PureComponent {
  constructor () {
    super()
    this.state = {
      disableTypeCombo: true,
      disableSubTypeCombo: true,
      ouIdSelected: null,
      dossierSubTypeId: null,
      showTravelsFoundModal: false,
      travels: [],
      showUpdateDossier: false
    }
  }

  handleOrganizedUnitChange (ouId) {
    this.props.change('dossierTypes', null)
    this.props.change('dossierSubTypes', null)
    if (ouId === null) {
      this.setState({ disableTypeCombo: true, disableSubTypeCombo: true, ouIdSelected: ouId })
    } else {
      this.setState({ disableTypeCombo: false, disableSubTypeCombo: true, ouIdSelected: ouId })
      this.props.actions.fetchDossierTypes(ouId)
    }
  }

  handleDossierTypeChange (dossierTypeId) {
    this.props.change('dossierSubTypes', null)
    if (dossierTypeId === null) {
      this.setState({ disableSubTypeCombo: true })
    } else {
      this.setState({ disableSubTypeCombo: false })
      this.props.actions.fetchDossierSubTypes(this.state.ouIdSelected, dossierTypeId)
    }
  }

  submitNewDossier (values) {
    this.props.actions.submitNewDossier(values)
  }

  componentDidMount () {
    this.props.actions.fetchLowestOrganizedUnits()
  }

  getSnapshotBeforeUpdate () {
    if (this.props.combos.dossierTypes.length === 1 && this.state.ouIdSelected && this.props.newDossierForm.values.dossierTypes == null) {
      this.autoSelectDamageClaimTypes()
    }
    if (this.props.combos.dossierTypes.length === 1 && this.props.combos.dossierSubTypes.length === 1 && this.state.ouIdSelected && this.props.newDossierForm.values.dossierType !== null && this.props.newDossierForm.values.dossierSubType == null) {
      this.autoSelectDamageClaimSubTypes()
    }
    return null
  }

  autoSelectDamageClaimTypes () {
    if (this.props.combos.dossierTypes.length === 1 && this.state.ouIdSelected) {
      this.props.change('dossierTypes', this.props.combos.dossierTypes[0].id)
      this.setState({ disableSubTypeCombo: false })
      if (this.state.ouIdSelected) {
        this.props.actions.fetchDossierSubTypes(this.state.ouIdSelected, this.props.combos.dossierTypes[0].id)
      }
    }
  }

  autoSelectDamageClaimSubTypes () {
    if (this.props.combos.dossierSubTypes.length === 1 && this.state.ouIdSelected) {
      if (!this.state.dossierSubTypeId || this.state.dossierSubTypeId !== this.props.combos.dossierSubTypes[0].id) {
        this.props.change('dossierSubTypes', this.props.combos.dossierSubTypes[0].id)
        this.setState({ dossierSubTypeId: this.props.combos.dossierSubTypes[0].id })
      }
    }
  }

  toggleTravelsFoundModal (showTravelsFoundModal, travels = []) {
    this.setState({ showTravelsFoundModal: showTravelsFoundModal, travels: travels })
  }

  importDossierToshiko () {
    this.setState({
      showUpdateDossier: true
    })
  }

  showDossierToshikoModal (t) {
    return (<UpdateDossierPage
      showModal={this.state.showUpdateDossier}
      isNew= {true}
      pristine = {true}
      rest={true}
      t={t}
      ouId={this.state.ouIdSelected}
      closeStoreCancelModal={() => this.setState({ showUpdateDossier: false })}
    ></UpdateDossierPage>)
  }

  showButtonToshiko () {
    return (this.state.dossierSubTypeId === dossierSubTypesIds.venta_vn || this.state.dossierSubTypeId === dossierSubTypesIds.venta_vo) && this.props.updateToshiko
  }

  componentDidUpdate () {}

  render () {
    const { t, handleSubmit, combos: { lowestOrganizedUnits, dossierTypes, dossierSubTypes } } = this.props
    const tKey = 'NEW_DOSSIER.'
    /// /const secMap = MapSections.management
    return (
      <div className="wrapper _nf">
        <div className='wrapper-header'>
          <div className="title-page">
            <i className="iDocIcon-add-folder-plus icon-page" />
            <h2>{t(`${tKey}TITLE`)}</h2>
          </div>
          <div className='title-page-extra'>
          </div>
        </div>
        <div className='wrapper-body'>
          <div className='inner-data-content'>
            <form autoComplete="off" onSubmit={handleSubmit(this.submitNewDossier.bind(this))}>
              <div className='search-form'>
                <Field
                  component={InputSelect}
                  colWidht={33} // 25, 33, 50, 75, 100 default=25
                  id="lowestOrganizedUnits"
                  name="lowestOrganizedUnits"
                  placeholder={t(`${tKey}ORGANIZED_UNIT`)}
                  controlLabel={t(`${tKey}ORGANIZED_UNIT`)}
                  onInputChange={(value) => { this.handleOrganizedUnitChange(value); this.setState({ dossierSubTypeId: null }) }}
                  options={lowestOrganizedUnits}
                  labelKey='value'
                  valueKey='id'
                />
                <Field
                  component={InputSelect}
                  colWidht={33} // 25, 33, 50, 75, 100 default=25
                  id="dossierTypes"
                  name="dossierTypes"
                  placeholder={t(`${tKey}TYPE`)}
                  controlLabel={t(`${tKey}TYPE`)}
                  disabled={this.state.disableTypeCombo}
                  customClass={this.state.disableTypeCombo ? ' disabled' : ''}
                  options={dossierTypes}
                  onInputChange={(value) => { this.handleDossierTypeChange(value); this.setState({ dossierSubTypeId: null }) }}
                  labelKey="value"
                  valueKey="id"
                />
                <Field
                  component={InputSelect}
                  colWidht={33} // 25, 33, 50, 75, 100 default=25
                  name="dossierSubTypes"
                  placeholder={t(`${tKey}SUBTYPE`)}
                  controlLabel={t(`${tKey}SUBTYPE`)}
                  options={dossierSubTypes}
                  disabled={this.state.disableSubTypeCombo}
                  customClass={this.state.disableSubTypeCombo ? ' disabled' : ''}
                  onInputChange={(value) => this.setState({ dossierSubTypeId: value })}
                  labelKey="value"
                  valueKey="id"
                />
                <div className="search-form-actions flexInLine al-R">
                  <Button className="_Btn accept" type="submit">
                    <i className="ico-plus"/>
                    {t(`${tKey}SUBMIT`)}
                  </Button>
                      &nbsp;&nbsp;
                  {(this.showButtonToshiko() &&
                    <Button className="_Btn accept stk" onClick={() => this.importDossierToshiko()}>
                      {t(`${tKey}IMPORT_DOSSIER`)}
                    </Button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
        {this.showDossierToshikoModal(t)}
      </div>
    )
  }
}

export default reduxForm({
  form: 'newDossier',
  validate: (values, props) => {
    const error = {}
    if (!values.lowestOrganizedUnits) {
      error.lowestOrganizedUnits = true
      error.lowestOrganizedUnits = props.t('DOSSIER_COMPONENTS.VALIDATIONS.NEW_DOSSIER_MANDATORY')
    }
    if (!values.dossierTypes) {
      error.dossierTypes = true
      error.dossierTypes = props.t('DOSSIER_COMPONENTS.VALIDATIONS.NEW_DOSSIER_MANDATORY')
    }
    if (!values.dossierSubTypes) {
      error.dossierSubTypes = true
      error.dossierSubTypes = props.t('DOSSIER_COMPONENTS.VALIDATIONS.NEW_DOSSIER_MANDATORY')
    }
    return error
  }
})(NewDossier)
