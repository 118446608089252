import React, { PureComponent } from 'react'
import { Row } from 'react-bootstrap'
import { Field } from 'redux-form'
import InputText from '../../commons/form/InputText'
import InputDecimal from '../../commons/form/InputDecimal'
import InputNumber from '../../commons/form/InputNumber'
import { dataTypes } from '../../../constants/dossier/common/customFieldsDataType'

class CustomFields extends PureComponent {
  render () {
    const {
      readOnlyFields
    } = this.props
    return (<Row>
      {!!this.props.customFields && this.props.customFields.map(
        (customField, idx) => {
          switch (customField.customFieldType.code) {
            case dataTypes.text:
              return (
                <Field
                  key={idx}
                  id={customField.code}
                  name={`customFields.${customField.code}`}
                  placeholder={!!customField.customFieldLocales?.length > 0 ? customField.customFieldLocales[0].description : ''}
                  controlLabel={!!customField.customFieldLocales?.length > 0 ? customField.customFieldLocales[0].name : ''}
                  component={InputText}
                  showError={true}
                  disabled={readOnlyFields}
                />)
            case dataTypes.decimal:
              return (
                <Field
                  key={idx}
                  id={customField.code}
                  name={`customFields.${customField.code}`}
                  placeholder={!!customField.customFieldLocales?.length > 0 ? customField.customFieldLocales[0].description : ''}
                  controlLabel={!!customField.customFieldLocales?.length > 0 ? customField.customFieldLocales[0].name : ''}
                  component={InputDecimal}
                  showError={true}
                  disabled={readOnlyFields}
                />)
            case dataTypes.int:
              return (
                <Field
                  key={idx}
                  id={customField.code}
                  name={`customFields.${customField.code}`}
                  placeholder={!!customField.customFieldLocales?.length > 0 ? customField.customFieldLocales[0].description : ''}
                  controlLabel={!!customField.customFieldLocales?.length > 0 ? customField.customFieldLocales[0].name : ''}
                  component={InputNumber}
                  showError={true}
                  disabled={readOnlyFields}
                />)
            default:
              break
          }
        })}
    </Row>)
  }
}
export default CustomFields
