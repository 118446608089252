import dossierModalActionTypes from '../../../constants/actions/dossier/dossier'
import commentsActionTypes from '../../../constants/actions/dossier/common/comments'
import auditInfoTypes from '../../../constants/actions/audit/auditInfo'
import combosActionTypes from '../../../constants/actions/combos/combos'

export function initialState () {
  return {
    dossierId: null,
    dossierSubTypeId: null,
    dossierParentId: null,
    parentIsHistorical: null,
    organizedUnitId: null,
    entityChangeable: true,
    isMarked: false,
    header: {},
    checkListHeader: [],
    viewConfiguration: {},
    dossierCampaignComponent: {},
    entityComponent: {},
    entityChildComponent: {},
    entityProviderComponent: {},
    vehicleComponent: {},
    dossierKm: null,
    comments: [],
    dossierRelatedDossiers: [],
    auditInfo: [],
    lastAccessDate: null,
    dossierContactComponent: { updated: false },
    unreadComments: null,
    reason: null,
    comment: null,
    cancelCombo: [],
    customFieldsByComponent: [],
    customFieldsValues: [],
    customFields: {}
  }
}

function fetchCampaignDossierSuccess (state, { dossier, checkListHeader }) {
  return {
    ...state,
    dossierId: dossier.dossierId || state.dossierId,
    dossierSubTypeId: dossier.dossierSubTypeId || state.dossierSubTypeId,
    dossierParentId: dossier.dossierParentId || state.dossierParentId,
    parentIsHistorical: dossier.parentIsHistorical || state.parentIsHistorical,
    isMarked: (dossier.isMarked !== null && dossier.isMarked !== undefined ? dossier.isMarked : state.isMarked),
    organizedUnitId: dossier.organizedUnitId || state.organizedUnitId,
    entityChangeable: dossier.entityChangeable || state.entityChangeable,
    header: dossier.dossierHeaderComponent || state.header,
    checkListHeader: checkListHeader || state.checkListHeader,
    viewConfiguration: dossier.viewConfiguration || state.viewConfiguration,
    dossierCampaignComponent: dossier.dossierCampaignComponent || state.dossierCampaignComponent,
    entityComponent: dossier.entityComponent || state.entityComponent,
    entityChildComponent: dossier.entityChildComponent || state.entityChildComponent,
    entityProviderComponent: dossier.entityProviderComponent || state.entityProviderComponent,
    vehicleComponent: {
      ...(dossier.vehicleComponent || state.vehicleComponent),
      previousUsageId: dossier.previousUsageId || state.vehicleComponent.previousUsageId,
      makerNumber: dossier.makerNumber || state.vehicleComponent.makerNumber,
      availabilityDate: dossier.availabilityDate || state.vehicleComponent.availabilityDate
    },
    dossierKm: dossier ? dossier.dossierKm : state.dossierKm,
    comments: dossier.dossierCompleteComment || state.comments,
    dossierRelatedDossiers: dossier.dossierRelatedDossiers || state.dossierRelatedDossiers,
    auditInfo: dossier.auditInfo || state.auditInfo,
    lastAccessDate: dossier.lastAccessDate || state.lastAccessDate,
    dossierContactComponent: dossier.dossierContactComponent || state.dossierContactComponent,
    totalAmountWithOptionals: dossier.totalAmountWithOptionals || state.totalAmountWithOptionals,
    totalAmountWithOptionalsPurchase: dossier.totalAmountWithOptionalsPurchase || state.totalAmountWithOptionalsPurchase,
    unreadComments: dossier.unreadComments >= 0 ? dossier.unreadComments : state.unreadComments,
    reason: dossier.reason || state.reason,
    comment: dossier.comment || state.comment,
    cancelCombo: dossier.cancelCombo || state.cancelCombo,
    customFieldsValues: dossier.customFields || state.customFieldsValues
  }
}

function fetchCommentsSuccess (state, { comments }) {
  return {
    ...state,
    comments
  }
}

function fetchDossierRelatedDossiersSuccess (state, { dossierRelatedDossiers }) {
  return {
    ...state,
    dossierRelatedDossiers
  }
}

function fetchAuditInfoSucces (state, { auditInfo }) {
  return {
    ...state,
    auditInfo
  }
}

function fetchCombosSuccess (state, { data }) {
  return {
    ...state,
    ...data
  }
}

function fetchCampaignDetailsSucces (state, { data }) {
  return {
    ...state,
    dossierCampaignComponent: { dossierCampaignDetailComponent: data }
  }
}

function fetchCustomFieldsSuccess (state, { value }) {
  return {
    ...state,
    customFieldsByComponent: value
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case combosActionTypes.FETCH_COMBOS_SUCCESS:
      return fetchCombosSuccess(state, action)
    case dossierModalActionTypes.FETCH_CAMPAIGN_DOSSIER_SUCCESS:
      return fetchCampaignDossierSuccess(state, action)
    case commentsActionTypes.FETCH_CAMPAIGN_COMMENTS_SUCCESS:
      return fetchCommentsSuccess(state, action)
    case dossierModalActionTypes.RESTART_CAMPAIGN_STATE:
      return initialState()
    case dossierModalActionTypes.FETCH_DOSSIER_RELATED_DOSSIERS_SUCCESS:
      return fetchDossierRelatedDossiersSuccess(state, action)
    case auditInfoTypes.FETCH_AUDIT_INFO_SUCCESS:
      return fetchAuditInfoSucces(state, action)
    case dossierModalActionTypes.FETCH_CAMPAIGN_DETAILS_SUCCESS:
      return fetchCampaignDetailsSucces(state, action)
    case dossierModalActionTypes.FETCH_CUSTOM_FIELDS_SUCCESS:
      return fetchCustomFieldsSuccess(state, action)
    default:
      return state
  }
}
