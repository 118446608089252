import apiFetch from '../apiFetch'
export default function (userRecurrentServiceEntityId, token) {
  const queryParamsArray = [
    userRecurrentServiceEntityId ? `userRecurrentServiceEntityId=${userRecurrentServiceEntityId}` : null
  ]
  const queryParams = queryParamsArray.reduce(
    (result, query, idx) => result + (query ? (result ? `&${query}` : `?${query}`) : '')
    , ''
  )
  return apiFetch({ endPoint: '/Entity/DeleteUserRecurrentServiceEntity' + queryParams, method: 'DELETE', body: null, token: token })
}
