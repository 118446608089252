import React, { PureComponent } from 'react'
import { Col, Row, Button } from 'react-bootstrap'
import UpperTrafficSummary from './upperTraffic'
import Step2 from './Step2/Step2'

class trafficProcessSummary extends PureComponent {
  render () {
    return (
      <Col sm={12} className="traffic-process-summary dossier">
        <Row className="time-line-button">
          <Col sm={7} className="time-line">
            <div className="step-wrapper">
              <div className="step active">
                <span className="step-number">1</span>
                <span className="step-name">Aprobación</span>
              </div>
            </div>
            <div className="step-wrapper">
              <div className="step">
                <span className="step-number">2</span>
                <span className="step-name">Envío</span>
              </div>
            </div>
            <div className="step-wrapper">
              <div className="step">
                <span className="step-number">3</span>
                <span className="step-name">Recepción</span>
              </div>
            </div>
          </Col>
          <Col sm={2} smPush={2} className="text-right btn-timeline">
            <Button className="btn-standard btn-continue">
              Documentación
            </Button>
          </Col>
        </Row>
        <Row className="band-user">
          <Col sm={3} className="band-icon-item">
            <i className="ico-user" />
            <div className="text-band">
              <span className="first">Nombre del gestor:</span>
              <span className="second">Nombre del gestor</span>
            </div>
          </Col>
          <Col sm={3} className="band-icon-item">
            <i className="ico-user" />
            <div className="text-band">
              <span className="first">Nombre del proveedor:</span>
              <span className="second">Nombre del proveedor</span>
            </div>
          </Col>
          <Col sm={3} className="band-icon-item">
            <i className="ico-car" />
            <div className="text-band">
              <span className="first">Marca:</span>
              <span className="second">Marca</span>
            </div>
          </Col>
          <Col sm={3} className="band-icon-item">
            <i className="ico-car" />
            <div className="text-band">
              <span className="first">Modelo:</span>
              <span className="second">Modelo</span>
            </div>
          </Col>
        </Row>
        <UpperTrafficSummary />
        <Step2 />
      </Col>
    )
  }
}

export default trafficProcessSummary
