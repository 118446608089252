const managementSections =
  [
    {
      page: 'powerBIPage',
      id: 'btn_powerbi',
      code: 'powerbi',
      icon: 'iDocIcon-admin-tools',
      title: 'REPORTS.POWERBI'
    },
    {
      page: 'fleetReportPage',
      id: 'btn_masters',
      code: 'fleetReport',
      icon: 'iDocIcon-admin-tools',
      title: 'SEARCH.CATEGORIES.FLEETREPORT.TITLE'
    },
    {
      page: 'commercialReportPage',
      id: 'btn_masters',
      code: 'reportCommercial',
      icon: 'iDocIcon-admin-tools',
      title: 'SEARCH.CATEGORIES.REPORT.COMMERCIAL_REPORT'
    }
  ]

export default managementSections
