import React, { PureComponent } from 'react'
import { Field } from 'redux-form'
import { Panel, Row } from 'react-bootstrap'
import InputSelect from '../../commons/form/InputSelect'
import InputPercentage from '../../commons/form/InputPercentage'
import InputDatePicker from '../../commons/form/InputDatePicker'
import InputMoney from '../../commons/form/InputMoney'
import { permissions } from '../../../constants/backendIds'
import { getFieldsPermisions } from '../../../util/permisionFunctions'
import { validateDate } from '../../../util/validationFunctions'
import { campaignType } from '../../../constants/dossier/common/campaignType'
import { dossierSubTypeId as dossierSubTypeIdConstants } from '../../../constants/dossier/common/dossierSubType'
import InputText from '../../commons/form/InputText'
import InputCheckBox from '../../commons/form/InputCheckBox'
import CustomFields from '../../commons/customFields/CustomFields'

class CampaignComponent extends PureComponent {
  constructor () {
    super()
    this.state = {
      open: false,
      documentIds: true,
      checklistEditable: true,
      fieldsConfiguration: {
        campaignTypeId: {
          permission: null
        },
        approvalDate: {
          permission: null
        },
        amountPercentage: {
          permission: null
        },
        costPercentage: {
          permission: null
        },
        costConcession: {
          permission: null
        },
        costConcessionPercentage: {
          permission: null
        },
        amount: {
          permission: null
        },
        cost: {
          permission: null
        },
        documentIds: {
          permission: null
        },
        campaignDetailsId: {
          permission: null
        },
        startDate: {
          permission: null
        },
        endDate: {
          permission: null
        },
        orderDate: {
          permission: null
        },
        amountCampaign: {
          permission: null
        },
        amountBrand: {
          permission: null
        },
        percentageCampaign: {
          permission: null
        },
        percentageBrand: {
          permission: null
        },
        amountRequested: {
          permission: null
        },
        approvedAmount: {
          permission: null
        },
        bonified: {
          permission: null
        },
        observations: {
          permission: null
        },
        amountCost: {
          permission: null
        },
        responsable: {
          permission: null
        },
        billingNumber: {
          permission: null
        },
        billingDate: {
          permission: null
        },
        expirationDate: {
          permission: null
        },
        denied: {
          permission: null
        },
        vehiclePrice: {
          permission: null
        },
        vehicleOptionalsPrice: {
          permission: null
        },
        percentageRequested: {
          permission: null
        },
        percentageApproved: {
          permission: null
        },
        checkInvoiceProform: {
          permission: null
        },
        checkBillingRequested: {
          permission: null
        },
        paymentMethodCampaign: {
          permission: null
        },
        name: {
          permission: null
        },
        additionalPercentage: { permission: null },
        additionalAmount: { permission: null }
      }
    }
  }

  initializePermisions (props) {
    const fieldKeys = Object.keys(this.state.fieldsConfiguration)
    const newState = getFieldsPermisions(props.componentConfiguration.fieldsConfiguration, fieldKeys, props.componentConfiguration.permission)
    this.setState({ fieldsConfiguration: newState.fieldsConfiguration })
  }

  UNSAFE_componentWillMount () {
    if (this.props.componentConfiguration !== null) {
      this.initializePermisions(this.props)
    } else {
      this.setState({ permission: 0 })
    }
  }

  handleCampaign (campaignTypeId) {
    this.props.actions.change('dossierCampaignComponent.documentIds', [])
    if (campaignTypeId) {
      this.props.actions.fetchChecklistCampaign(campaignTypeId, this.props.dossier.organizedUnitId)
      this.setState({ documentIds: false })
    } else {
      this.setState({ documentIds: true })
    }
  }

  componentDidMount () {
    this.setState({ documentIds: !this.props.dossier.dossierCampaignComponent.campaignTypeId })
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.open) {
      this.setState({ open: true })
    }

    if (this.props.dossier.dossierCampaignComponent !== nextProps.dossier.dossierCampaignComponent) {
      this.setState({ documentIds: !nextProps.dossier.dossierCampaignComponent.campaignTypeId })
    }
  }

  render () {
    const { t, campaignOpen, customFields, combos: { campaignsTypes, documentsTypes, campaignDetails, paymentMethodCampaignCombo }, actions: { togglePanel }, dossier: { dossierSubTypeId } } = this.props
    const tKey = 'DOSSIER_COMPONENTS.'
    return (
      <div className="campaign-panel dossier-panel">
        <div className="header-panel" onClick={togglePanel}>
          <div className="name-wrapper">
            <i className="ico-car-files" />
            <h4>{dossierSubTypeId === dossierSubTypeIdConstants.campanya_ayuda_especial ? t(`${tKey}SPECIAL_HELP_DOSSIER.CAMPAIGN_COMPONENT.TITLE`) : t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.TITLE`)}</h4>
          </div>
          <i className={campaignOpen ? 'ico-arrow-black-up' : 'ico-arrow-black-down'} />
        </div>
        <Panel onToggle={() => { }} expanded={campaignOpen}>
          <Panel.Collapse>
            <Row>
              {this.state.fieldsConfiguration.name.permission !== permissions.hidden && (
                <Field
                  colSm={12}
                  id="name"
                  name="dossierCampaignComponent.name"
                  controlLabel={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.NAME`)}
                  placeholder={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.NAME`)}
                  component={InputText}
                  disabled={true}
                />
              )}
              {this.state.fieldsConfiguration.responsable.permission !== permissions.hidden && (
                <Field
                  colSm={6}
                  id="responsable"
                  name="dossierCampaignComponent.responsable"
                  controlLabel={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.RESPONSABLE`)}
                  placeholder={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.RESPONSABLE`)}
                  component={InputText}
                  disabled={true}
                />
              )}
              {this.state.fieldsConfiguration.campaignTypeId.permission !== permissions.hidden && (
                <Field
                  colSm={6}
                  id="campaignTypeId"
                  name="dossierCampaignComponent.campaignTypeId"
                  controlLabel={dossierSubTypeId === dossierSubTypeIdConstants.campanya_ayuda_especial ? t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.HELP_TYPE`) : t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.CAMPAIGN_TYPE`)}
                  placeholder={dossierSubTypeId === dossierSubTypeIdConstants.campanya_ayuda_especial ? t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.HELP_TYPE`) : t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.CAMPAIGN_TYPE`)}
                  component={InputSelect}
                  options={campaignsTypes}
                  valueKey="id"
                  labelKey="value"
                  disabled={true}
                />)}
              {this.state.fieldsConfiguration.campaignDetailsId.permission !== permissions.hidden && (
                <Field
                  id="campaignDetailsId"
                  name="dossierCampaignComponent.campaignDetailsId"
                  colSm={6}
                  controlLabel={this.props.dossier.dossierSubTypeId === campaignType.SPECIAL ? t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.HELP_TYPE`) : t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.CAMPAIGN_TYPE`)}
                  placeholder={this.props.dossier.dossierSubTypeId === campaignType.SPECIAL ? t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.HELP_TYPE`) : t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.CAMPAIGN_TYPE`)}
                  component={InputSelect}
                  valueKey="id"
                  labelKey="value"
                  options={campaignDetails}
                  disabled={true}
                />)}
              {this.state.fieldsConfiguration.startDate.permission !== permissions.hidden && (
                <Field
                  id="startDate"
                  name="dossierCampaignComponent.dossierCampaignDetailComponent.startDate"
                  colSm={6}
                  controlLabel={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.START_DATE`)}
                  component={InputDatePicker}
                  disabled={true}
                />
              )}
              {this.state.fieldsConfiguration.endDate.permission !== permissions.hidden && (
                <Field
                  id="endDate"
                  name="dossierCampaignComponent.dossierCampaignDetailComponent.endDate"
                  colSm={6}
                  controlLabel={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.END_DATE`)}
                  component={InputDatePicker}
                  disabled={true}
                />
              )}
              {this.state.fieldsConfiguration.orderDate.permission !== permissions.hidden && (
                <Field
                  id="orderDate"
                  name="dossierCampaignComponent.dossierCampaignDetailComponent.orderDate"
                  colSm={6}
                  controlLabel={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.ORDER_DATE`)}
                  component={InputDatePicker}
                  disabled={true}
                />
              )}
              <Field
                id="ApprovalDate"
                name="dossierCampaignComponent.approvalDate"
                colSm={6}
                controlLabel={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.APPROVAL_DATE`)}
                component={InputDatePicker}
                validate={(value, allValues, props) => {
                  const result = validateDate(value, props)
                  return result === Object(result) ? undefined : result
                }}
                disabled={true}
              />
              {this.state.fieldsConfiguration.amountCampaign.permission !== permissions.hidden && (
                <Field
                  id="amountCampaign"
                  name="dossierCampaignComponent.dossierCampaignDetailComponent.amountCampaign"
                  type="text"
                  colSm={6}
                  controlLabel={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.AMOUNT_CAMPAIGN`)}
                  placeholder={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.AMOUNT_CAMPAIGN`)}
                  component={InputMoney}
                  disabled={true}
                />)}
              {this.state.fieldsConfiguration.amountBrand.permission !== permissions.hidden && (
                <Field
                  id="amountBrand"
                  name="dossierCampaignComponent.dossierCampaignDetailComponent.amountBrand"
                  type="text"
                  colSm={6}
                  controlLabel={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.AMOUNT_BRAND`)}
                  placeholder={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.AMOUNT_BRAND`)}
                  component={InputMoney}
                  disabled={true}
                />)}
              {this.state.fieldsConfiguration.percentageCampaign.permission !== permissions.hidden && (
                <Field
                  id="percentageCampaign"
                  name="dossierCampaignComponent.dossierCampaignDetailComponent.percentageCampaign"
                  type="text"
                  colSm={6}
                  controlLabel={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.CAMPAIGN_PERCENTAGE`)}
                  placeholder={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.CAMPAIGN_PERCENTAGE`)}
                  component={InputPercentage}
                  disabled={true}
                />)}
              {this.state.fieldsConfiguration.percentageBrand.permission !== permissions.hidden && (
                <Field
                  id="percentageBrand"
                  name="dossierCampaignComponent.dossierCampaignDetailComponent.percentageBrand"
                  type="text"
                  colSm={6}
                  controlLabel={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.BRAND_PERCENTAGE`)}
                  placeholder={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.BRAND_PERCENTAGE`)}
                  component={InputPercentage}
                  disabled={true}
                />)}
              {this.state.fieldsConfiguration.percentageRequested.permission !== permissions.hidden && (
                <Field
                  id="percentageCampaignRequested"
                  name="dossierCampaignComponent.dossierCampaignDetailComponent.percentageCampaignRequested"
                  type="text"
                  colSm={6}
                  controlLabel={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.PERCENTAGE_REQUESTED`)}
                  placeholder={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.PERCENTAGE_REQUESTED`)}
                  component={InputPercentage}
                  disabled={true}
                />)}
              {(this.state.fieldsConfiguration.percentageApproved.permission !== permissions.hidden) && (
                <Field
                  id="percentageCampaignApproved"
                  name="dossierCampaignComponent.dossierCampaignDetailComponent.percentageCampaignApproved"
                  type="text"
                  colSm={6}
                  controlLabel={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.PERCENTAGE_APPROVED`)}
                  placeholder={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.PERCENTAGE_APPROVED`)}
                  component={InputPercentage}
                  disabled={true}
                />)}
              {this.state.fieldsConfiguration.amountRequested.permission !== permissions.hidden && (
                <Field
                  id="amountRequested"
                  name="dossierCampaignComponent.dossierCampaignDetailComponent.amountRequested"
                  type="text"
                  colSm={6}
                  controlLabel={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.AMOUNT_FREE`)}
                  placeholder={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.AMOUNT_FREE`)}
                  component={InputMoney}
                  disabled={true}
                />)}
              {this.state.fieldsConfiguration.approvedAmount.permission !== permissions.hidden && (
                <Field
                  id="approvedAmount"
                  name="dossierCampaignComponent.dossierCampaignDetailComponent.approvedAmount"
                  type="text"
                  colSm={6}
                  controlLabel={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.AMOUNT_APPROVED`)}
                  placeholder={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.AMOUNT_APPROVED`)}
                  component={InputMoney}
                  disabled={true}
                />)}
              {this.state.fieldsConfiguration.bonified.permission !== permissions.hidden && (
                <Field
                  id="bonified"
                  name="dossierCampaignComponent.dossierCampaignDetailComponent.bonified"
                  colSm={12}
                  componentClass="textarea"
                  controlLabel={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.BONIFIED`)}
                  placeholder={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.BONIFIED`)}
                  component={InputText}
                  disabled={true}
                />)}
              {this.state.fieldsConfiguration.observations.permission !== permissions.hidden && (
                <Field
                  id="observations"
                  name="dossierCampaignComponent.dossierCampaignDetailComponent.observations"
                  colSm={12}
                  componentClass="textarea"
                  controlLabel={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.OBSERVATIONS`)}
                  placeholder={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.OBSERVATIONS`)}
                  component={InputText}
                  disabled={true}
                />)}
              <div className="clearfix" />
              {this.state.fieldsConfiguration.amountCost.permission !== permissions.hidden && this.state.fieldsConfiguration.amount.permission !== permissions.hidden && (
                <Field
                  id="amount"
                  name="dossierCampaignComponent.amount"
                  type="text"
                  colSm={6}
                  controlLabel={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.AMOUNT`)}
                  placeholder={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.AMOUNT`)}
                  component={InputMoney}
                  disabled={true}
                />)}
              {this.state.fieldsConfiguration.amountPercentage.permission !== permissions.hidden && (
                <Field
                  id="amountPercentage"
                  name="dossierCampaignComponent.amountPercentage"
                  type="text"
                  colSm={6}
                  controlLabel={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.AMOUNT_PERCENTAGE`)}
                  placeholder={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.AMOUNT_PERCENTAGE`)}
                  component={InputPercentage}
                  disabled={true}
                />
              )}
              {this.state.fieldsConfiguration.amountCost.permission !== permissions.hidden && this.state.fieldsConfiguration.cost.permission !== permissions.hidden && (
                <Field
                  id="cost"
                  name="dossierCampaignComponent.cost"
                  type="text"
                  colSm={6}
                  controlLabel={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.COST`)}
                  placeholder={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.COST`)}
                  component={InputMoney}
                  disabled={true}
                />)}
              {this.state.fieldsConfiguration.costPercentage.permission !== permissions.hidden && (
                <Field
                  id="costPercentage"
                  name="dossierCampaignComponent.costPercentage"
                  type="text"
                  colSm={6}
                  controlLabel={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.COST_PERCENTAGE`)}
                  placeholder={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.COST_PERCENTAGE`)}
                  component={InputPercentage}
                  disabled={true}
                />
              )}
              {this.state.fieldsConfiguration.costConcession.permission !== permissions.hidden && (
                <Field
                  id="costConcession"
                  name="dossierCampaignComponent.costConcession"
                  type="text"
                  colSm={6}
                  controlLabel={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.COST_CONCESSION`)}
                  placeholder={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.COST_CONCESSION`)}
                  component={InputMoney}
                  disabled={true}
                />
              )}
              {this.state.fieldsConfiguration.costConcessionPercentage.permission !== permissions.hidden && (
                <Field
                  id="costConcessionPercentage"
                  name="dossierCampaignComponent.costConcessionPercentage"
                  type="text"
                  colSm={6}
                  controlLabel={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.COST_CONCESSION_PERCENTAGE`)}
                  placeholder={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.COST_CONCESSION_PERCENTAGE`)}
                  component={InputPercentage}
                  disabled={true}
                />
              )}
              {this.state.fieldsConfiguration.additionalAmount.permission !== permissions.hidden && (
                <Field
                  id="additionalAmount"
                  name="dossierCampaignComponent.additionalAmount"
                  type="text"
                  colSm={6}
                  controlLabel={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.ADDITIONAL_AMOUNT`)}
                  placeholder={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.ADDITIONAL_AMOUNT`)}
                  component={InputMoney}
                  disabled={true}
                />
              )}
              {this.state.fieldsConfiguration.additionalPercentage.permission !== permissions.hidden && (
                <Field
                  id="additionalPercentage"
                  name="dossierCampaignComponent.additionalPercentage"
                  type="text"
                  colSm={6}
                  controlLabel={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.ADDITIONAL_PERCENTAGE`)}
                  placeholder={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.ADDITIONAL_PERCENTAGE`)}
                  component={InputPercentage}
                  disabled={true}
                />
              )}
              <div className="clearfix" />
              {this.state.fieldsConfiguration.documentIds.permission !== permissions.hidden && (
                <Field
                  colSm={6}
                  id="documentIds"
                  name="dossierCampaignComponent.documentIds"
                  multi
                  controlLabel={t(`${tKey}CAMPAIGNS.CAMPAIGNS_MODAL.CHECKLIST`)}
                  placeholder={t(`${tKey}CAMPAIGNS.CAMPAIGNS_MODAL.CHECKLIST`)}
                  component={InputSelect}
                  options={documentsTypes}
                  // onInputChange={(value) => this.handleCampaign(value)}
                  valueKey="id"
                  labelKey="value"
                  disabled={true}
                />
              )}
              {this.state.fieldsConfiguration.expirationDate.permission !== permissions.hidden && (
                <Field
                  id="expirationDate"
                  name="dossierCampaignComponent.expirationDate"
                  colSm={6}
                  controlLabel={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.EXPIRATION_DATE`)}
                  component={InputDatePicker}
                  validate={(value, allValues, props) => {
                    const result = validateDate(value, props)
                    return result === Object(result) ? undefined : result
                  }}
                  disabled={true}
                />
              )}
              {this.state.fieldsConfiguration.vehiclePrice.permission !== permissions.hidden && (
                <Field
                  id="vehiclePrice"
                  name="dossierCampaignComponent.vehiclePrice"
                  colSm={6}
                  controlLabel={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.VEHICLE_PRICE`)}
                  component={InputMoney}
                  disabled={true}
                />
              )}
              {this.state.fieldsConfiguration.vehicleOptionalsPrice.permission !== permissions.hidden && (
                <Field
                  id="vehicleOptionalsPrice"
                  name="dossierCampaignComponent.vehicleOptionalsPrice"
                  colSm={6}
                  controlLabel={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.VEHICLE_OPTIONALS_PRICE`)}
                  component={InputMoney}
                  disabled={true}
                />
              )}
              {this.state.fieldsConfiguration.billingNumber.permission !== permissions.hidden && (
                <Field
                  colSm={6}
                  id="BillingNumber"
                  name='dossierCampaignComponent.billingNumber'
                  key='dossierCampaignComponent.billingNumber'
                  controlLabel={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.BILLING_NUMBER`)}
                  placeholder={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.BILLING_NUMBER`)}
                  component={InputText}
                  type="text"
                  disabled={true}
                />
              )}
              {this.state.fieldsConfiguration.billingDate.permission !== permissions.hidden && (
                <Field
                  colSm={6}
                  id="BillingDate"
                  name="dossierCampaignComponent.billingDate"
                  controlLabel={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.BILLING_DATE`)}
                  component={InputDatePicker}
                  disabled={true}
                />
              )}
              {this.state.fieldsConfiguration.paymentMethodCampaign.permission !== permissions.hidden && (
                <Field
                  colSm={6}
                  id="paymentMethodCampaignId"
                  name="dossierCampaignComponent.paymentMethodCampaignId"
                  controlLabel={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.PAYMENT_METHOD`)}
                  placeholder={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.PAYMENT_METHOD`)}
                  component={InputSelect}
                  options={paymentMethodCampaignCombo}
                  valueKey="id"
                  labelKey="value"
                  isClearable={false}
                  disabled={true}
                />
              )}
              <div className="clearfix" />
              {this.state.fieldsConfiguration.denied.permission !== permissions.hidden && (
                <Field
                  id="denied"
                  name="dossierCampaignComponent.denied"
                  colSm={6}
                  controlLabel={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.DENIED`)}
                  placeholder={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.DENIED`)}
                  component={InputCheckBox}
                  disabled={true}
                />)}
              {this.state.fieldsConfiguration.checkInvoiceProform.permission !== permissions.hidden && (
                <Field
                  colSm={6}
                  id="checkInvoiceProform"
                  name="dossierCampaignComponent.checkInvoiceProform"
                  controlLabel={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.CHECK_INVOICE_PROFORM`)}
                  placeholder={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.CHECK_INVOICE_PROFORM`)}
                  component={InputCheckBox}
                  disabled={true}
                />
              )}
              <div className="clearfix" />
              {this.state.fieldsConfiguration.checkBillingRequested.permission !== permissions.hidden && (
                <Field
                  colSm={6}
                  id="checkBillingRequested"
                  name="dossierCampaignComponent.checkBillingRequested"
                  controlLabel={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.CHECK_BILLING_REQUESTED`)}
                  placeholder={t(`${tKey}CAMPAIGN_DOSSIER.CAMPAIGN_COMPONENT.CHECK_BILLING_REQUESTED`)}
                  component={InputCheckBox}
                  disabled={true}
                />
              )}
            </Row>
            <CustomFields customFields={customFields} readOnlyFields={true} />
          </Panel.Collapse>
        </Panel>
      </div>
    )
  }
}

export default CampaignComponent
