import profileAction from '../../constants/actions/profile/profile'

export function initialState () {
  return {
    data: null,
    languages: null,
    salesmanCombo: null,
    eventSubscriptions: []
  }
}

function fetchUserProfileSuccess (state, { data, languages }) {
  return {
    ...state,
    data,
    languages
  }
}

function fetchComboSalesmanByUOSuccess (state, { salesmanCombo }) {
  return {
    ...state,
    salesmanCombo: salesmanCombo
  }
}

function fetchEventSubscriptionsSuccess (state, { eventSubscriptions }) {
  return {
    ...state,
    eventSubscriptions
  }
}

function profileSectionChangeSuccess (state, { secMapProfile }) {
  return {
    ...state,
    secMapProfile
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case profileAction.FETCH_USER_PROFILE_SUCCESS:
      return fetchUserProfileSuccess(state, action)
    case profileAction.FETCH_SALESMAN_COMBO_SUCCESS:
      return fetchComboSalesmanByUOSuccess(state, action)
    case profileAction.FETCH_EVENT_SUBSCRIPTIONS_SUCCESS:
      return fetchEventSubscriptionsSuccess(state, action)
    case profileAction.PROFILE_SECTION_CHANGE_SUCCESS:
      return profileSectionChangeSuccess(state, action)
    default:
      return state
  }
}
