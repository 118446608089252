/* eslint-disable no-unused-vars */
import { put, call, select, takeEvery } from 'redux-saga/effects'
import { reset } from 'redux-form'
// selectors
import { getAuth } from '../../../selectors/access/auth'
// constants
import dossierActionsTypes from '../../../constants/actions/dossier/dossier'
import { dossierTypes as dossierTypesConstant } from '../../../constants/dossier/common/dossierTypes'
// actions
import { showLoader, hideLoader, openModal } from '../../../actions/common'
import messageModalActions from '../../../actions/modals/messageModal'
import serviceDossierActions from '../../../actions/dossier/serviceDossier'
// services
import putServiceDossierService from '../../../services/serviceDossier/put/putServiceDossier'
import putServiceDossierIssue from '../../../services/serviceDossier/put/putServiceDossierIssue'
// sagas
import { yesNoModal } from '../../modalsListeners/yesNoModal'
import { handleError } from '../../errors/errorManager'
import { initializeDossier } from '../commonDossierSagas'
import { compareObjects } from '../../../util/utils'
import putServiceAvailabilityDate from '../../../services/serviceDossier/put/putServiceAvailabilityDate'
import putServiceLogisticsApprove from '../../../services/serviceDossier/put/putServiceLogisticsApprove'

export function * saveServiceDossier ({ serviceDossier }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const serviceDossierState = yield select(state => state.serviceDossier)

    if (!compareObjects(serviceDossierState.dossierServiceComponent || {}, serviceDossier.dossierServiceComponent, true)) {
      serviceDossier.dossierServiceComponent.updated = true
    }

    const subDossier = {
      dossierParentId: serviceDossier.dossierParentId,
      parentIsHistorical: serviceDossier.parentIsHistorical,
      dossierServiceComponent: serviceDossier.dossierServiceComponent,
      dossierVehicleComponent: JSON.stringify(serviceDossier.vehicleComponent) === '{}' ? null : serviceDossier.vehicleComponent,
      customFields: serviceDossier?.customFields ? Object.keys(serviceDossier.customFields).map(x => { return { key: x, value: serviceDossier.customFields[x] } }) : []
    }

    if (serviceDossier?.customFields) {
      serviceDossierState.customFields = {}
      Object.keys(serviceDossier.customFields).map(x => {
        serviceDossierState.customFields[x] = serviceDossier.customFields[x]
      })
    }

    yield call(putServiceDossierService, serviceDossier.dossierId, subDossier, auth.token, serviceDossierState.lastAccessDate)
    yield put(messageModalActions.openSuccessMessageModal('MESSAGES.PUT_DOSSIER_SUCCESS'))
    yield call(initializeDossier, dossierTypesConstant.service, serviceDossier)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          yield call(initializeDossier, dossierTypesConstant.service, serviceDossier)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('service_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSaveServiceDossier () {
  yield takeEvery(dossierActionsTypes.SAVE_SERVICE_DOSSIER, saveServiceDossier)
}

export function * saveIssueService ({ issue }) {
  try {
    const auth = yield select(getAuth)
    const serviceDossierState = yield select(state => state.serviceDossier)
    let confirm = true

    if (serviceDossierState.dossierServiceComponent.issueEntityId && issue.issueEntityId !== serviceDossierState.dossierServiceComponent.issueEntityId &&
      serviceDossierState.dossierServiceComponent.providerObservations && issue.providerObservations !== serviceDossierState.dossierServiceComponent.providerObservations) {
      confirm = yield call(yesNoModal, 'sendMailIssue')
    }

    yield put(showLoader())

    if (confirm) {
      yield call(putServiceDossierIssue, serviceDossierState.dossierId, issue, auth.token, serviceDossierState.lastAccessDate)
      yield put(serviceDossierActions.openOrCloseIssueModal())
      yield put(messageModalActions.openSuccessMessageModal('MESSAGES.PUT_DOSSIER_SUCCESS'))
      yield call(initializeDossier, dossierTypesConstant.service, serviceDossierState)
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          // yield call(initializeDossier, dossierTypesConstant.service, serviceDossierState)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('service_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSaveIssueService () {
  yield takeEvery(dossierActionsTypes.SAVE_ISSUE_SERVICE, saveIssueService)
}

export function * saveServicesAvailabilityDate ({ dossierIdArray, availabilityDate, resolve, reject, fields }) {
  try {
    if (dossierIdArray) {
      const saveDate = yield call(yesNoModal, 'confirmSaveAvailabilityDate', null, null, null, null,
        [{ id: '##DATE##', value: availabilityDate }, { id: '##COUNT##', value: dossierIdArray.length ?? 0 }])
      if (saveDate) {
        const auth = yield select(getAuth)
        yield put(showLoader())
        yield call(putServiceAvailabilityDate, auth.token, dossierIdArray, availabilityDate, fields)
        yield put(hideLoader())
        yield put(openModal('availabiliteDateSaved'))
        if (resolve) resolve()
      }
    }
  } catch (error) {
    if (reject) {
      yield call(reject, error)
    } else {
      yield call(handleError, { error })
    }
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSaveServicesAvailabilityDate () {
  yield takeEvery(dossierActionsTypes.SAVE_SERVICES_AVAILABILITY_DATE, saveServicesAvailabilityDate)
}

export function * saveServicesConfirmationLogitics ({ dossierIdArray, resolve, reject }) {
  try {
    if (dossierIdArray) {
      const auth = yield select(getAuth)
      yield put(showLoader())
      var data = yield call(putServiceLogisticsApprove, auth.token, dossierIdArray, 11)
      yield put(hideLoader())
      if (resolve) resolve(data)
    }
  } catch (error) {
    if (reject) {
      yield call(reject, error)
    } else {
      yield call(handleError, { error })
    }
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSaveServicesConfirmationLogitics () {
  yield takeEvery(dossierActionsTypes.SAVE_SERVICES_CONFIRMATION_LOGISTICS, saveServicesConfirmationLogitics)
}
