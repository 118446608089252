import documentManagementModalActionTypes from '../../../constants/actions/dossier/common/documentManagementModal'

export function initialState () {
  return {
    showModal: false,
    dossierId: null,
    entityId: null,
    entityDs: '',
    entityProviderId: null,
    entityProviderDs: '',
    vehicleId: null,
    vehicleDs: '',
    sectionsDocuments: {},
    dossierNumber: '',
    dossierType: '',
    dossierDocumentChanged: false,
    entityDocumentChanged: false,
    vehicleDocumentChanged: false,
    dossierSubType: '',
    organizedUnit: '',
    permissionRecurrentEntity: false,
    stage: '',
    totalToUpload: 0,
    totalToValidate: 0,
    totalValidated: 0,
    tabCounters: {
      tabToUpload: 0,
      tabToValidate: 0,
      tabValidated: 0,
      tab1: {
        tabToUpload: 0,
        tabToValidate: 0,
        tabValidated: 0
      },
      tab2: {
        tabToUpload: 0,
        tabToValidate: 0,
        tabValidated: 0
      },
      tab3: {
        tabToUpload: 0,
        tabToValidate: 0,
        tabValidated: 0
      },
      tab4: {
        tabToUpload: 0,
        tabToValidate: 0,
        tabValidated: 0
      },
      tab5: {
        tabToUpload: 0,
        tabToValidate: 0,
        tabValidated: 0
      }
    },
    defaultTab: 1,
    activeTab: 1,
    checkListIdReciboPagaYSenal: null
  }
}

export function openDocumentManagementModalSuccess (state, {
  sectionsDocuments, dossierNumber, dossierId, entityId, entityDs, entityProviderId, entityProviderDs, vehicleId, vehicleDs, dossierType
  , dossierSubType, organizedUnit, stage, toUpload, toValidate, validated, tab1ToUpload, tab1ToValidate, tab1Validated, tab2ToUpload, tab2ToValidate, tab2Validated
  , tab3ToUpload, tab3ToValidate, tab3Validated, tab4ToUpload, tab4ToValidate, tab4Validated, tab5ToUpload, tab5ToValidate, tab5Validated, defaultTab, permissionRecurrentEntity
}) {
  const initialStateObject = initialState()
  let tab = {}
  switch (defaultTab) {
    case 1:
      tab = { tabToUpload: tab1ToUpload, tabToValidate: tab1ToValidate, tabValidated: tab1Validated }
      break
    case 2:
      tab = { tabToUpload: tab2ToUpload, tabToValidate: tab2ToValidate, tabValidated: tab2Validated }
      break
    case 3:
      tab = { tabToUpload: tab3ToUpload, tabToValidate: tab3ToValidate, tabValidated: tab3Validated }
      break
    case 4:
      tab = { tabToUpload: tab4ToUpload, tabToValidate: tab4ToValidate, tabValidated: tab4Validated }
      break
    case 5:
      tab = { tabToUpload: tab5ToUpload, tabToValidate: tab5ToValidate, tabValidated: tab5Validated }
      break
    default:
      tab = { tabToUpload: tab1ToUpload, tabToValidate: tab1ToValidate, tabValidated: tab1Validated }
  }
  return {
    ...initialStateObject,
    showModal: true,
    sectionsDocuments,
    dossierNumber,
    dossierId,
    entityId,
    entityDs,
    entityProviderId,
    entityProviderDs,
    vehicleId,
    vehicleDs,
    dossierType,
    dossierSubType,
    organizedUnit,
    permissionRecurrentEntity,
    stage,
    tabCounters: {
      ...tab,
      tab1: {
        tabToUpload: tab1ToUpload,
        tabToValidate: tab1ToValidate,
        tabValidated: tab1Validated
      },
      tab2: {
        tabToUpload: tab2ToUpload,
        tabToValidate: tab2ToValidate,
        tabValidated: tab2Validated
      },
      tab3: {
        tabToUpload: tab3ToUpload,
        tabToValidate: tab3ToValidate,
        tabValidated: tab3Validated
      },
      tab4: {
        tabToUpload: tab4ToUpload,
        tabToValidate: tab4ToValidate,
        tabValidated: tab4Validated
      },
      tab5: {
        tabToUpload: tab5ToUpload,
        tabToValidate: tab5ToValidate,
        tabValidated: tab5Validated
      }
    },
    totalToUpload: toUpload,
    totalToValidate: toValidate,
    totalValidated: validated,
    defaultTab: defaultTab || initialStateObject.defaultTab,
    activeTab: defaultTab || initialStateObject.defaultTab
  }
}

export function refreshDocumentManagementModalSuccess (state, {
  sectionsDocuments, dossierNumber, dossierId, entityId, entityDs, entityProviderId, entityProviderDs, vehicleId, vehicleDs,
  dossierSubType, organizedUnit, stage, toUpload, toValidate, validated, tab1ToUpload, tab1ToValidate, tab1Validated, tab2ToUpload, tab2ToValidate,
  tab2Validated, tab3ToUpload, tab3ToValidate, tab3Validated, tab4ToUpload, tab4ToValidate, tab4Validated, tab5ToUpload, tab5ToValidate, tab5Validated,
  permissionRecurrentEntity, pristine
}) {
  let tab = {}
  switch (state.activeTab) {
    case 1:
      tab = { tabToUpload: tab1ToUpload, tabToValidate: tab1ToValidate, tabValidated: tab1Validated }
      break
    case 2:
      tab = { tabToUpload: tab2ToUpload, tabToValidate: tab2ToValidate, tabValidated: tab2Validated }
      break
    case 3:
      tab = { tabToUpload: tab3ToUpload, tabToValidate: tab3ToValidate, tabValidated: tab3Validated }
      break
    case 4:
      tab = { tabToUpload: tab4ToUpload, tabToValidate: tab4ToValidate, tabValidated: tab4Validated }
      break
    case 5:
      tab = { tabToUpload: tab5ToUpload, tabToValidate: tab5ToValidate, tabValidated: tab5Validated }
      break
    default:
      tab = { tabToUpload: tab1ToUpload, tabToValidate: tab1ToValidate, tabValidated: tab1Validated }
  }
  return {
    ...state,
    sectionsDocuments,
    dossierNumber,
    dossierId,
    entityId,
    entityProviderId,
    entityDs,
    entityProviderDs,
    vehicleId,
    vehicleDs,
    dossierSubType,
    organizedUnit,
    stage,
    permissionRecurrentEntity,
    tabCounters: {
      ...tab,
      tab1: {
        tabToUpload: tab1ToUpload,
        tabToValidate: tab1ToValidate,
        tabValidated: tab1Validated
      },
      tab2: {
        tabToUpload: tab2ToUpload,
        tabToValidate: tab2ToValidate,
        tabValidated: tab2Validated
      },
      tab3: {
        tabToUpload: tab3ToUpload,
        tabToValidate: tab3ToValidate,
        tabValidated: tab3Validated
      },
      tab4: {
        tabToUpload: tab4ToUpload,
        tabToValidate: tab4ToValidate,
        tabValidated: tab4Validated
      },
      tab5: {
        tabToUpload: tab5ToUpload,
        tabToValidate: tab5ToValidate,
        tabValidated: tab5Validated
      }
    },
    totalToUpload: toUpload,
    totalToValidate: toValidate,
    totalValidated: validated,
    dirty: pristine !== null && pristine !== undefined ? !pristine : false
  }
}

export function dossierDocumentChanged (state) {
  return {
    ...state,
    dossierDocumentChanged: true
  }
}

export function entityDocumentChanged (state) {
  return {
    ...state,
    entityDocumentChanged: true
  }
}

export function vehicleDocumentChanged (state) {
  return {
    ...state,
    vehicleDocumentChanged: true
  }
}

function closeDocumentModal (state) {
  return initialState()
}

function setCurrentTabCounterValuesSuccess (state, { counterValues, activeTab }) {
  return {
    ...state,
    activeTab,
    tabCounters: {
      ...state.tabCounters,
      tabToUpload: counterValues.tabToUpload,
      tabToValidate: counterValues.tabToValidate,
      tabValidated: counterValues.tabValidated
    }
  }
}

export function saveCheckListReciboPagaYSenal (state, { checkListIdReciboPagaYSenal }) {
  return {
    ...state,
    checkListIdReciboPagaYSenal
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case documentManagementModalActionTypes.OPEN_DOCUMENT_MANAGEMENT_MODAL_SUCCESS:
      return openDocumentManagementModalSuccess(state, action)
    case documentManagementModalActionTypes.REFRESH_DOCUMENT_MANAGEMENT_MODAL_SUCCESS:
      return refreshDocumentManagementModalSuccess(state, action)
    case documentManagementModalActionTypes.CLOSE_DOCUMENT_MANAGEMENT_MODAL:
      return closeDocumentModal(state, action)
    case documentManagementModalActionTypes.DOSSIER_DOCUMENT_CHANGED:
      return dossierDocumentChanged(state, action)
    case documentManagementModalActionTypes.ENTITY_DOCUMENT_CHANGED:
      return entityDocumentChanged(state, action)
    case documentManagementModalActionTypes.VEHICLE_DOCUMENT_CHANGED:
      return vehicleDocumentChanged(state, action)
    case documentManagementModalActionTypes.SET_CURRENT_TAB_COUNTER_VALUES:
      return setCurrentTabCounterValuesSuccess(state, action)
    case documentManagementModalActionTypes.SAVE_CHECK_LIST_RECIBO_PAGA_Y_SENAL:
      return saveCheckListReciboPagaYSenal(state, action)
    default:
      return state
  }
}
