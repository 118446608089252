import apiFetch from '../apiFetch'
import { brands } from '../../constants/backendIds'

export default function (token, file, brand, isBuyAndSell) {
  const formData = new FormData()
  formData.append(file.name, file)
  switch (brand) {
    case brands.AUDI:
      return apiFetch({ endPoint: `AutoDossierExcelAudi/${isBuyAndSell}`, method: 'POST', body: formData, token: token, fileBody: true })
    case brands.SEAT:
      return apiFetch({ endPoint: `AutoDossierExcelSeat/${isBuyAndSell}`, method: 'POST', body: formData, token: token, fileBody: true })
    case brands.VW:
      return apiFetch({ endPoint: `AutoDossierExcelVW/${isBuyAndSell}`, method: 'POST', body: formData, token: token, fileBody: true })
  }
}
