import React, { PureComponent } from 'react'
import { Modal, Button, Row } from 'react-bootstrap'
import { Field } from 'redux-form'
import InputText from '../../../commons/form/InputText'

class DocumentaryGroupDocumentsCreateModal extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      groupedDocName: null,
      suffixName: '.pdf',
      preffixName: 'DA-'
    }
  }

  componentDidUpdate (prevProps) {
  }

  sendRequest () {
    if (this.props.documentsSelected.length > 0) {
      this.props.actions.groupDocuments(this.props.documentsSelected.filter(x => !this.props.invalidDocuments.includes(x)), this.state.preffixName + this.state.groupedDocName + this.state.suffixName, this.props.organizedUnitId, this.props.dossierId, () => { this.props.closeModal(); this.props.closeParentModal(); this.props.actions.refreshDocumentManagementModal(this.props.dossierId) })
    }
  }

  required (value) {
    return (value || value === 0) && value !== ''
      ? undefined : this.props.t('GLOBAL.FORMS.REQUIRED')
  }

  render () {
    let {
      t, showModal, closeModal
    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.DOCUMENTARY_MANAGEMENT.'

    return (
      <Modal show={showModal} className="custom-modal massive-download-modal setDocName" onHide={() => closeModal()} backdrop={'static'}>
        <Modal.Body>
          <h4>{t(`${tKey}GROUP_DOCUMENTS_CREATE_MODAL.SUBTITLE`)}</h4>
          <Row id='grouped_document_name'>
            <span>{this.state.preffixName}</span>
            <Field
              id="documentName"
              name="documentName"
              placeholder={t(`${tKey}GROUP_DOCUMENTS_CREATE_MODAL.GROUPED_DOCUMENT_NAME`)}
              component={InputText}
              onInputChange={(value) => this.setState({ groupedDocName: value })}
            />
            <span>{this.state.suffixName}</span>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button className="btn-save" onClick={() => this.sendRequest()} disabled={!this.state.groupedDocName}>{t(`${tKey + 'GROUP_DOCUMENTS_CREATE_MODAL.ACCEPT'}`)}</Button>
          <Button className="btn-cancel" onClick={() => closeModal()}>{t(`${tKey + 'GROUP_DOCUMENTS_CREATE_MODAL.CANCEL'}`)}</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default DocumentaryGroupDocumentsCreateModal
