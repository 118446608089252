import React, { PureComponent } from 'react'
import { Row, Col, Tooltip, OverlayTrigger } from 'react-bootstrap'

class BubbleMeterUO2 extends PureComponent {
  render () {
    const tooltip = (
      <Tooltip id="tooltip" className="tooltip-with-list">
        <ul className="role-header-tooltip tooltip-list" style={{ textAlign: 'left' }}>
          {this.props.listItems.map((uo, idxUo) =>
            <li key={idxUo}>
              <Row>
                <Col sm={12}>
                  <span style={{ fontWeight: 700 }}>{uo.name}: </span>
                </Col>
                <Col sm={11} smOffset={1}>
                  {uo.roleList.map((role) => role.value).join(', ')}
                </Col>
              </Row>
            </li>
          )}
        </ul>
      </Tooltip>
    )
    const { listItems } = this.props
    // if (!listItems || listItems.length < 2) return null
    return (
      <OverlayTrigger placement="bottom" overlay={tooltip}>
        <span className="grey-point">+{listItems.length - 1}</span>
      </OverlayTrigger>
    )
  }
}

export default BubbleMeterUO2
