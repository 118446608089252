export function multipleNormalize (value, normalizeFnArray) {
  return normalizeFnArray.reduce((accumulator, currentFn) => currentFn(accumulator), value)
}

export function toUpperCase (value) {
  return value.toUpperCase()
}

export function toLowerCase (value) {
  return value.toUpperCase()
}

export function trimAll (value) {
  return value.replace(' ', '')
}
