import apiFetch from '../apiFetch'

export default function (token, filename) {
  const queryParamsArray = [
    filename ? `templateName=${filename}` : null
  ]
  const queryParams = queryParamsArray.reduce(
    (result, query, idx) => result + (query ? (result ? `&${query}` : `?${query}`) : '')
    , ''
  )
  return apiFetch({ endPoint: `LoadData/DownloadTemplate${queryParams}`, method: 'GET', parseBlobAndAssign: true, token: token })
}
