import React, { PureComponent } from 'react'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Field } from 'redux-form'
import InputText from '../commons/form/InputText'
import { filterSalesmanByUOSelected } from '../../../util/utils'
import { permissions } from '../../../constants/backendIds'
import Promise from 'promise-polyfill'
import InputSelect from '../commons/form/InputSelect'
import InputTreeSelect from '../commons/form/InputTreeSelect'
import InputCheckBox from '../commons/form/InputCheckBox'

class UserOrganization extends PureComponent {
  constructor (props) {
    super(props)
    this.state = { rechargeDevices: false, phoneEditable: false }
  }

  componentDidMount () {
  }

  handleUO (organizedUnitId) {
    if (organizedUnitId !== this.props.organizedUnitId) {
      return new Promise((resolve, reject) => {
        this.props.actions.fetchComboSalesmanByUO(organizedUnitId, resolve, reject)
      }).then(() => {
        organizedUnitId && organizedUnitId !== '' ? this.props.change('salesmansSelectedId', filterSalesmanByUOSelected(this.props.combos.SalesmanCombo, this.props.formValues.salesmansSelectedId))
          : this.props.change('salesmansSelectedId', [])
      })
    }
  }

  editPhone (editPhone) {
    if (editPhone) {
      this.setState({ phoneEditable: true })
    } else {
      this.setState({ phoneEditable: false })
    }
  }

  render () {
    const tKey = 'PROFILE.'
    const editTooltip = (<Tooltip id="editTooltip">{ this.props.t(`${tKey}EDIT_PHONE`)}</Tooltip>)
    const closeTooltip = (<Tooltip id="editTooltip">{ this.props.t(`${tKey}CLOSE_PHONE`)}</Tooltip>)
    const { t, data, languages, combos: { UOTreeCombo, SalesmanCombo }, sectionTabsConfiguration } = this.props
    if (!data || !languages) return null
    return (
      <div className='inner-results-panel'>
        <div className='section-results-header'>
          <div className="section-header-title">
            <OverlayTrigger placement="right" overlay={<Tooltip id="editProductTooltip">{`${t((this.props.hideMenu ? 'GLOBAL.TABLES.SHOW_INNER_MENU' : 'GLOBAL.TABLES.HIDE_INNER_MENU'))}`}</Tooltip>}>
              <a onClick={() => this.props.toogleInnerMenu()} className={this.props.hideMenu ? 'collapseBtn' : 'collapseBtn closeInnerMenu'}><i className={this.props.hideMenu ? 'iDocIcon-Open-inner-menu' : 'iDocIcon-Close-inner-menu'}></i></a>
            </OverlayTrigger>
            <h4>{t(tKey + 'ORGANIZATION_TAB.TITLE')}</h4>
          </div>
        </div>
        <div className="section-results-body organization">
          <div className="field inline-field">
            <label>{t(tKey + 'NAME')}:</label>
            <span>{data.name}</span>
          </div>
          <div className="field inline-field">
            <label>{t(tKey + 'SURNAMES')}:</label>
            <span>{data.surnames}</span>
          </div>
          <div className="field inline-field">
            <label>{t(tKey + 'EMAIL_ADDRESS')}:</label>
            <span>{data.emailAddress}</span>
          </div>
          {this.state.phoneEditable
            ? <div className="field inline-field"><label>{t(tKey + 'PHONE')}:</label> &nbsp;
              <a onClick={() => this.editPhone(false)}>
                <OverlayTrigger placement="right" overlay={closeTooltip}>
                  <i className="ico-cerrar" />
                </OverlayTrigger></a>
              <Field
                t={t}
                colSm={12}
                id='phone'
                name='phone'
                placeholder={t(tKey + 'NEW_PHONE')}
                component={InputText}
                autoComplete="phone"
              /></div>
            : <div className="field inline-field">
              <label>{t(tKey + 'PHONE')}:</label>
              <span>{data.phone ? data.phone + ' ' : '---'}</span>
              <a onClick={() => this.editPhone(true)}>
                <OverlayTrigger placement="right" overlay={editTooltip}>
                  <i className="ico-edit-white" />
                </OverlayTrigger>
              </a>
            </div>}
          <div className="field inline-field">
            <label>{t(tKey + 'UNIT_NAMES')}:</label>
            <ul>
              {data.uoRoles.map(uo => {
                return (
                  <li key={uo.organizedUnitId}>
                    {uo.name}
                    {uo.ownedRoles.length > 0 && (
                      <ul>
                        {uo.ownedRoles.map(rol => {
                          return <li key={rol.roleId}>{rol.description}</li>
                        })}
                      </ul>
                    )}
                  </li>
                )
              })}
            </ul>
          </div>
          <div className="field inline-field">
            <label>{t(tKey + 'DEPARTMENT')}:</label>
            <span>{data.department}</span>
          </div>
          <div className="field inline-field">
            <label>{t(tKey + 'POSITION')}:</label>
            <span>{data.position}</span>
          </div>
          {data.code && (<div className="field inline-field">
            <label>{t(tKey + 'CODE')}:</label>
            <span>{data.code}</span>
          </div>)}
          <div className='search-form customScrollbar'>
            <Field
              component={InputTreeSelect}
              colWidht={33} // 25, 33, 50, 75, 100 default=25
              name="organizedUnit"
              id="organizedUnit"
              placeholder={t('ADMIN.USERS.UO')}
              controlLabel={t('ADMIN.USERS.UO')}
              multi={true}
              valueKey="value"
              labelKey="label"
              childrenKey="children"
              options={UOTreeCombo}
              onInputChange={(organizedUnitId) => this.handleUO(organizedUnitId)}
            />
            {sectionTabsConfiguration && (sectionTabsConfiguration.salesmansSelected === undefined || (sectionTabsConfiguration.salesmansSelected.permission > permissions.hidden)) && (
              <Field
                component={InputSelect}
                colWidht={33} // 25, 33, 50, 75, 100 default=25
                id="salesmansSelectedId"
                name="salesmansSelectedId"
                placeholder={t(tKey + 'SELECTED_SALESMAN')}
                controlLabel={t(tKey + 'SELECTED_SALESMAN')}
                multi={true}
                valueKey="id"
                labelKey="value"
                options={SalesmanCombo}
              />)}
            {sectionTabsConfiguration && sectionTabsConfiguration.responsableSalesman && sectionTabsConfiguration.responsableSalesman.permission > permissions.hidden && (
              <Field
                component={InputSelect}
                colWidht={33} // 25, 33, 50, 75, 100 default=25
                id="responsableSalesmansId"
                name="responsableSalesmansId"
                placeholder={t(tKey + 'RESPONSABLE_SALESMAN')}
                controlLabel={t(tKey + 'RESPONSABLE_SALESMAN')}
                multi={true}
                valueKey="id"
                labelKey="value"
                options={SalesmanCombo}
              />)}
            {sectionTabsConfiguration && sectionTabsConfiguration.responsableNotifications && sectionTabsConfiguration.responsableNotifications.permission > permissions.hidden && (
              <Field
                component={InputCheckBox}
                colWidht={33} // 25, 33, 50, 75, 100 default=25
                id="responsableNotifications"
                name="responsableNotifications"
                placeholder={t(`${tKey}RECEIVE_SELLER_NOTIFICATIONS`)}
              />)}
            <div className='save-form flexInLine al-R'>
              <Button type="submit" className="_Btn new">
                <i className="ico-folder-open"/> <span>{t(tKey + 'SAVE')}</span>
              </Button>
            </div>

          </div>
        </div>
      </div>
    )
  }
}

export default UserOrganization
