import React, { PureComponent } from 'react'
import { Panel, Row, Col, Table, Glyphicon } from 'react-bootstrap'
import { Field } from 'redux-form'
import { Element } from 'react-scroll'
import InputText from '../../commons/form/InputText'
import InputSelect from '../../commons/form/InputSelect'
import InputMoney from '../../commons/form/InputMoney'
import { formatMoney, formatPercent } from '../../../util/utils'
import ButtonsSidebar from '../../dossiers/common/ButtonsSidebar'
import { buttonsSidebar } from '../../../constants/dossier/common/buttonsSidebar'
import { economicPlanSectionType, NUMBER_OF_DECIMALS } from '../../../constants/dossier/common/economicPlanSection'
import EconomicPlanTotal from '../../dossiers/sales/economicPlan/EconomicPlanTotal'
import { permissions, iemValues, suppliedCodes } from '../../../constants/backendIds'
import { dossierSubTypeId as dossierSubTypes } from '../../../constants/dossier/common/dossierSubType'
import { getComponentsPermisions, getFieldsConfiguration } from '../../../util/permisionFunctions'
import EconomicPlanReaconditioning from '../../dossiers/sales/economicPlan/EconomicPlanReaconditioning'
import setting from '../../../setting'
import { icoCurrencyContent } from '../../../constants/dossier/common/currencies'
import { ivaTypeCodes } from '../../../constants/dossier/common/taxes'

class EconomicPlan extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      taxesOpen: true,
      vehicle_amountOpen: true,
      campaign_discountOpen: false,
      additional_discountOpen: false,
      optionalOpen: false,
      sale_othersOpen: false,
      suppliedOpen: false,
      costs_giftsOpen: false,
      reacontitioning_open: false,
      valueIva: props.dossierEconomicComponent.ivaTypeId,
      valueIem: props.dossierEconomicComponent.iemTypeId,
      componentsConfiguration: {
        totals: null,
        updatedTotals: null,
        taxes: null,
        vehicle_amount: null,
        campaign_discount: null,
        additional_discount: null,
        optional: null,
        sale_others: null,
        supplied: null,
        costs_gifts: null,
        associated_costs: null,
        iemTypeId: null,
        iemPercentage: null,
        reaconditioning: null
      },
      fieldsConfiguration: {
        vehicle_amount_modelPriceAmount: null,
        vehicle_amount_modelPriceCost: null,
        vehicle_amount_transportationAmount: null,
        vehicle_amount_transportationCost: null,
        additional_discount_discounts: null,
        sale_others_extraSales: null,
        taxes_ivaType: null,
        taxes_iemType: null,
        costs_gifts_presents: null,
        tin: null,
        insurance: null,
        maintenance: null,
        uacTotalAmount: null
      },
      btnProcessingAgency: null,
      btnDiscounts: null,
      additionalCostId: null
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.activeSection && nextProps.activeSection !== '' && !this.state[nextProps.activeSection + 'Open']) {
      this.setState({ [nextProps.activeSection + 'Open']: true })
    }
    if (this.props.dossierEconomicComponent.ivaTypeId !== nextProps.dossierEconomicComponent.ivaTypeId) {
      this.setState({ valueIva: nextProps.dossierEconomicComponent.ivaTypeId })
    }
  }

  UNSAFE_componentWillMount () {
    this.initializePermisions(this.props)
    this.props.handleToUpdateComponent(this.props)
    this.props.actions.fetchSalesEconomic(this.props.dossierId, this.props.historical, this.props.pristine)
  }

  initializePermisions (props) {
    const componentKeys = Object.keys(this.state.componentsConfiguration)
    const newState = getComponentsPermisions(props.tabConfiguration2, 3, componentKeys)
    const btnProcessingAgency = newState.componentsConfiguration.sale_others.fieldsConfiguration.filter((permission) => permission.code === 'btnProcessingAgency')[0]
    const btnDiscounts = newState.componentsConfiguration.additional_discount.fieldsConfiguration.filter((permission) => permission.code === 'btnDiscounts')[0]

    const tin = newState.componentsConfiguration.totals.fieldsConfiguration.filter((permission) => permission.code === 'tin')[0]
    const maintenance = newState.componentsConfiguration.totals.fieldsConfiguration.filter((permission) => permission.code === 'maintenance')[0]
    const insurance = newState.componentsConfiguration.totals.fieldsConfiguration.filter((permission) => permission.code === 'insurance')[0]

    const totals = getFieldsConfiguration(newState.componentsConfiguration.totals)
    const additional_discount = getFieldsConfiguration(newState.componentsConfiguration.additional_discount)
    const vehicle_amount = getFieldsConfiguration(newState.componentsConfiguration.vehicle_amount)
    const updatedTotals = getFieldsConfiguration(newState.componentsConfiguration.updatedTotals)
    const taxes = getFieldsConfiguration(newState.componentsConfiguration.taxes)
    const campaign_discount = getFieldsConfiguration(newState.componentsConfiguration.campaign_discount)
    const optional = getFieldsConfiguration(newState.componentsConfiguration.optional)
    const sale_others = getFieldsConfiguration(newState.componentsConfiguration.sale_others)
    const supplied = getFieldsConfiguration(newState.componentsConfiguration.supplied)
    const costs_gifts = getFieldsConfiguration(newState.componentsConfiguration.costs_gifts)
    const associated_costs = getFieldsConfiguration(newState.componentsConfiguration.associated_costs)
    const reaconditioning = getFieldsConfiguration(newState.componentsConfiguration.reaconditioning)

    this.setState({
      ...this.state,
      ...newState,
      btnProcessingAgency: btnProcessingAgency,
      btnDiscounts: btnDiscounts,
      fieldsConfiguration: {
        totals,
        additional_discount,
        updatedTotals,
        taxes,
        vehicle_amount,
        campaign_discount,
        optional,
        sale_others,
        supplied,
        costs_gifts,
        associated_costs,
        reaconditioning,
        tin,
        insurance,
        maintenance
      }
    })
  }

  openOrCloseReaconditioning () {
    this.setState({ reaconditioningOpen: !this.state.reaconditioningOpen })
  }

  isOtherIvaType (ivaTypeId) {
    if (ivaTypeId) {
      const code = this.props.combos.ivaTypeCombo.find((iva) => iva.id === ivaTypeId).code
      if (code === ivaTypeCodes.OTHERS_SVO) {
        return true
      }
    }
    return false
  }

  getCommissionCancellationCodeDescription (value) {
    if (value === null || value === '' || value === undefined || value === '-') {
      return this.props.t('MASTERS.CODE_COMMISSION.CODE_COMMISSION_EMPTY')
    } else {
      return value
    }
  }

  render () {
    const {
      t, economicTab, isFixed, activeStageFields, dossierType, combos, panelsPaddingTop, dossierEconomicComponent, handleToUpdateComponent,
      actions: { setActiveSection }, tabConfiguration, dossierSubTypeId,
      numberOfDecimals = NUMBER_OF_DECIMALS
    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.'
    let margenComprasPorcentaje = dossierEconomicComponent.purchaseMarginPerc ? dossierEconomicComponent.purchaseMarginPerc : 0
    let descuentoTotalPorcentaje = dossierEconomicComponent.allDiscountPerc ? dossierEconomicComponent.allDiscountPerc : 0
    let margenOperacionPorcentaje = dossierEconomicComponent.operationMarginPerc ? dossierEconomicComponent.operationMarginPerc : 0
    const netMarginPercentage = dossierEconomicComponent.netMarginPerc ? dossierEconomicComponent.netMarginPerc : 0
    const fixedCommissionPercentage = dossierEconomicComponent.fixedCommissionPerc ? parseFloat(dossierEconomicComponent.fixedCommissionPerc) : 0
    const variableCommissionPercentage = dossierEconomicComponent.variableCommissionPerc ? parseFloat(dossierEconomicComponent.variableCommissionPerc) : 0
    const qualitativeCommissionPercentage = dossierEconomicComponent.qualitativeCommissionPerc ? parseFloat(dossierEconomicComponent.qualitativeCommissionPerc) : 0

    let totalOptional = {
      name: t(`${tKey}ECONOMIC_PLAN.TOTALS`),
      amount: dossierEconomicComponent.optionals ? dossierEconomicComponent.optionals.reduce((acc, val) => acc + val.amount, 0) : '',
      percentage: '',
      cost: dossierEconomicComponent.optionals ? dossierEconomicComponent.optionals.reduce((acc, val) => acc + val.cost, 0) : '',
      supInvoice: '',
      iem: '',
      provider: ''
    }

    let totalCampaign = {
      name: t(`${tKey}ECONOMIC_PLAN.TOTALS`),
      amount: dossierEconomicComponent.campaigns ? dossierEconomicComponent.campaigns.reduce((acc, val) => acc + val.amount, 0) : '',
      percentage: dossierEconomicComponent.campaigns ? dossierEconomicComponent.campaigns.reduce((acc, val) => acc + (val.amountPercentage ?? 0), 0).toFixed(2) : '',
      cost: dossierEconomicComponent.campaigns ? dossierEconomicComponent.campaigns.reduce((acc, val) => acc + val.cost, 0) : '',
      costConcessionaire: dossierEconomicComponent.campaigns ? dossierEconomicComponent.campaigns.reduce((acc, val) => acc + val.costConcessionaire, 0) : '',
      costConcessionPercentage: dossierEconomicComponent.campaigns ? dossierEconomicComponent.campaigns.reduce((acc, val) => acc + (val.costConcessionPercentage ?? 0), 0).toFixed(2) : '',
      supInvoice: '',
      iem: '',
      provider: ''
    }

    const totalDiscount = {
      name: t(`${tKey}ECONOMIC_PLAN.TOTALS`),
      amount: dossierEconomicComponent.discounts ? dossierEconomicComponent.discounts.reduce((acc, val) => acc + val.amount, 0) : '',
      percentage: dossierEconomicComponent.modelPriceAmount && dossierEconomicComponent.discounts ? ((dossierEconomicComponent.discounts.reduce((acc, val) => acc + val.amount, 0) / (dossierEconomicComponent.modelPriceAmount + totalOptional.amount) * 100).toFixed(2) + '%') : '',
      cost: dossierEconomicComponent.discounts ? dossierEconomicComponent.discounts.reduce((acc, val) => acc + val.cost, 0) : '',
      supInvoice: '',
      iem: '',
      provider: ''
    }

    const totalExtraSale = {
      name: t(`${tKey}ECONOMIC_PLAN.TOTALS`),
      oculto: '',
      amount: dossierEconomicComponent.extraSales && dossierEconomicComponent.paperworks ? dossierEconomicComponent.extraSales.reduce((acc, val) => acc + val.amount, 0) + dossierEconomicComponent.paperworks.reduce((acc, val) => acc + (val.typeId === economicPlanSectionType.VENTA_OTROS ? val.amount : 0), 0) : '',
      percentage: '',
      cost: dossierEconomicComponent.extraSales && dossierEconomicComponent.paperworks ? dossierEconomicComponent.extraSales.reduce((acc, val) => acc + val.cost, 0) + dossierEconomicComponent.paperworks.reduce((acc, val) => acc + (val.typeId === economicPlanSectionType.VENTA_OTROS ? val.cost : 0), 0) : '',
      supInvoice: '',
      iem: '',
      provider: ''
    }
    const totalSuplidos = {
      name: t(`${tKey}ECONOMIC_PLAN.TOTALS`),
      amount: dossierEconomicComponent.supplieds && dossierEconomicComponent.specialHelps ? dossierEconomicComponent.supplieds.reduce((acc, val) => acc + val.amount, 0) + dossierEconomicComponent.specialHelps.reduce((acc, val) => acc + val.amount, 0) + dossierEconomicComponent.iemAmount + dossierEconomicComponent.paperworks.reduce((acc, val) => acc + (val.typeId === economicPlanSectionType.SUPLIDOS ? val.amount : 0), 0) : '',
      percentage: '',
      cost: dossierEconomicComponent.supplieds && dossierEconomicComponent.specialHelps ? dossierEconomicComponent.supplieds.reduce((acc, val) => acc + val.cost, 0) + dossierEconomicComponent.specialHelps.reduce((acc, val) => acc + val.cost, 0) + dossierEconomicComponent.iemAmount + dossierEconomicComponent.paperworks.reduce((acc, val) => acc + (val.typeId === economicPlanSectionType.SUPLIDOS ? val.cost : 0), 0) : '',
      supInvoice: '',
      iem: '',
      provider: ''
    }
    const totalPresent = {
      name: t(`${tKey}ECONOMIC_PLAN.TOTALS`),
      amount: dossierEconomicComponent.presents ? dossierEconomicComponent.presents.reduce((acc, val) => acc + val.amount, 0) : '',
      percentage: '',
      cost: dossierEconomicComponent.presents && dossierEconomicComponent.paperworks ? dossierEconomicComponent.presents.reduce((acc, val) => acc + val.cost, 0) + dossierEconomicComponent.paperworks.reduce((acc, val) => acc + (val.typeId === economicPlanSectionType.COSTE_OBSEQUIOS ? val.cost : 0), 0) : '',
      supInvoice: '',
      iem: '',
      provider: ''
    }

    let totalAssociatedCosts = {
      name: t(`${tKey}ECONOMIC_PLAN.TOTALS`),
      amount: dossierEconomicComponent.associatedCosts ? dossierEconomicComponent.associatedCosts.reduce((acc, val) => acc + val.amount, 0) : '',
      percentage: '',
      cost: dossierEconomicComponent.associatedCosts && dossierEconomicComponent.paperworks ? dossierEconomicComponent.associatedCosts.reduce((acc, val) => acc + val.cost, 0) + dossierEconomicComponent.paperworks.reduce((acc, val) => acc + (val.typeId === economicPlanSectionType.ASSOCIATED_COSTS ? val.cost : 0), 0) : 0,
      supInvoice: '',
      iem: '',
      provider: ''
    }

    const iemSuppied = {
      name: 'IEM',
      amount: dossierEconomicComponent.iemAmount,
      percentage: '',
      cost: dossierEconomicComponent.iemAmount,
      supInvoice: dossierEconomicComponent.iemData ? dossierEconomicComponent.iemData.supInvoice : '',
      iem: '',
      provider: dossierEconomicComponent.iemData ? dossierEconomicComponent.iemData.provider : ''
    }

    let totalBill = dossierEconomicComponent.billTotalAmount ? dossierEconomicComponent.billTotalAmount : 0

    let totalReaconditioning = {
      name: t(`${tKey}ECONOMIC_PLAN.TOTALS`),
      amount: dossierEconomicComponent.purchasePresents ? dossierEconomicComponent.purchasePresents.reduce((acc, val) => acc + val.amount, 0) : '',
      percentage: '',
      cost: dossierEconomicComponent.purchasePresents ? dossierEconomicComponent.purchasePresents.reduce((acc, val) => acc + val.cost, 0) : 0,
      supInvoice: '',
      iem: '',
      provider: ''
    }

    const ivaType = combos.ivaTypeCombo && combos.ivaTypeCombo.find((iva) => iva.id === dossierEconomicComponent?.ivaTypeId ?? 0)
    let totalTaxesPercentaje = ((dossierEconomicComponent.iemTypeId === iemValues.other ? this.state.iemPercentage : Number(dossierEconomicComponent.iemTypeId ? combos.iemType[dossierEconomicComponent.iemTypeId - 1].amountValue : 0)) +
    Number((ivaType ? ivaType.code === ivaTypeCodes.OTHERS_SVO ? this.state.ivaPercentage : ((ivaType.amountValue ?? 0) * 100) : 0))) ?? 0

    const showField = (fieldCode, componentCode) => {
      let componentPermissionObj = this.state.fieldsConfiguration[componentCode]
      if (componentPermissionObj[fieldCode]) {
        return componentPermissionObj[fieldCode].permission > permissions.hidden
      }
      return false
    }

    return (
      <Row className="sales-panel dossier-panel">
        <Col sm={3} className="col-row expediente-menu-izq">
          <ButtonsSidebar
            t={t}
            actions={{ setActiveSection }}
            isFixed={isFixed}
            economicTab={economicTab}
            activeStageFields={activeStageFields}
            dossierType={dossierType}
            paddingTop={panelsPaddingTop}
            tabConfiguration={tabConfiguration}
            handleToUpdateComponent = { handleToUpdateComponent }
          />
        </Col>
        <Col sm={9} className="col-row contenido-paneles-expediente contenido-planteo-economico">
          <Col className="panels-wrapper dossier fixed" style={{ paddingTop: panelsPaddingTop }}>
            {
              // TITLE + TOTALS
            }
            {this.state.componentsConfiguration.totals &&
            this.state.componentsConfiguration.totals.permission > permissions.hidden &&
            (
              <Element name={buttonsSidebar.totals}>
                <div className="dossier-panel">
                  <div className="header-panel">
                    <div className="name-wrapper">
                      <h4><i className={icoCurrencyContent[setting.currency]} /> {t(`${tKey}ECONOMIC_PLAN.TITLE_PANEL`)}</h4>
                    </div>
                  </div>
                  <Col sm={12} className="totals-economic-plan">
                    <Col sm={6} className="totals-economic-plan-izq">
                      <div><b><span className="total-import-left">{t(`${tKey}TOTALS.OPERATION_TOTAL`)}</span><span id="Financial_SaleTotal" className="total-import-right">{formatMoney(dossierEconomicComponent.operationTotalAmount)}</span></b></div>
                      {!!(showField('uacTotalAmount', 'totals')) &&
                      <div><span className="total-import-left">{t(`${tKey}TOTALS.UAC`)}</span><span id="Financial_SecondHandVehicleInReturn" className="total-import-right">{formatMoney(dossierEconomicComponent.uacTotalAmount)}</span></div>
                      }
                      <div><span className="total-import-left">{t(`${tKey}TOTALS.COLLECTED`)}</span><span id="Financial_Paid" className="total-import-right">{formatMoney(dossierEconomicComponent.chargesTotalAmount)}</span></div>
                      <div><span className="total-import-left">{t(`${tKey}TOTALS.COLLECT_TOTAL`)}</span><span id="Financial_TotalReceivable" className="total-import-right">{formatMoney(dossierEconomicComponent.paymentPending)}</span></div>
                      {!!(showField('totalAmountChargeConcession', 'totals')) &&
                      <div><span className="total-import-left">{t(`${tKey}TOTALS.TOTAL_AMOUNT_CHARGE_CONCESSION`)}</span><span id="Financial_SecondHandVehicleInReturn" className="total-import-right">{formatMoney(dossierEconomicComponent.totalAmountChargeConcession ?? 0)}</span></div>
                      }
                      <div><span className="total-import-left">{t(`${tKey}TOTALS.FINANCING_AMOUNT`)}</span><span id="Financial_FinancingAmount" className="total-import-right">{formatMoney(dossierEconomicComponent.financingAmount)}</span></div>
                      <div><span className="total-import-left">{t(`${tKey}TOTALS.FINANCING_BONUS_AMOUNT`)}</span><span id="Financial_FinancingBonusAmount" className="total-import-right">{formatMoney(dossierEconomicComponent.financingBonusAmount)}</span></div>
                      <div><span className="total-import-left">{t(`${tKey}TOTALS.FINANCING_COMISSION`)}</span><span id="Financial_FinancingComission" className="total-import-right">{formatMoney(dossierEconomicComponent.financingCommission ?? 0)}</span></div>
                      <div><span className="total-import-left">{t(`${tKey}TOTALS.TOTAL_BILL`)}</span><span id="Financial_BillTotal" className="total-import-right">{formatMoney(totalBill)}</span></div>
                    </Col>
                    <Col sm={6} className="totals-economic-plan-der">
                      <div><span className="total-import-left">{t(`${tKey}TOTALS.PURCHASE_MARGIN`)}</span><span id="Financial_PurchaseMargins" className="total-import-right">{formatMoney(dossierEconomicComponent.purchaseMargin)}</span><span id="Financial_PurchaseMargins_Percent" className="porcentajes-totales">{margenComprasPorcentaje.toFixed(2) + '%'}</span></div>
                      <div><span className="total-import-left">{t(`${tKey}TOTALS.DTO_TOTAL`)}</span><span id="Financial_TotalDiscount" className="total-import-right">{formatMoney(dossierEconomicComponent.allDiscount)}</span><span id="Financial_TotalDiscount_Percent" className="porcentajes-totales">{descuentoTotalPorcentaje.toFixed(2) + '%'}</span></div>
                      {(this.state.componentsConfiguration.reaconditioning && this.state.componentsConfiguration.reaconditioning.permission > permissions.hidden && dossierSubTypeId === dossierSubTypes.venta_vo) &&
                      <div><span className="total-import-left">{t(`${tKey}TOTALS.DTO_TOTAL_REACONDITIONING`)}</span><span id="Financial_modelPriceCost" className="total-import-right">{formatMoney(dossierEconomicComponent.modelPriceCost)}</span></div> }
                      <div><span className="total-import-left">{t(`${tKey}TOTALS.OPERATION_MARGIN`)}</span><span id="Financial_ComercialMargin" className="total-import-right">{formatMoney(dossierEconomicComponent.operationMargin)}</span><span id="Financial_ComercialMargin_Percent" className="porcentajes-totales">{margenOperacionPorcentaje.toFixed(2) + '%'}</span></div>
                      <div><span className="total-import-left">{t(`${tKey}TOTALS.NET_MARGIN`)}</span><span id="Financial_NetMargin" className="total-import-right">{formatMoney(dossierEconomicComponent.netMargin)}</span><span id="Financial_NetMargin_Percent" className="porcentajes-totales">{netMarginPercentage.toFixed(2) + '%'}</span></div>
                      {!!(showField('fixedCommission', 'totals')) &&
                        <div><span className="total-import-left-double">{this.getCommissionCancellationCodeDescription(dossierEconomicComponent?.commissionCancellationCodeDescription)}</span>
                        </div>
                      }
                      {!!(showField('fixedCommission', 'totals')) &&
                        <div><span className="total-import-left">{t(`${tKey}TOTALS.FIXED_COMMISSION`)}</span><span id="Totals_FixedCommission" className="total-import-right">{formatMoney(dossierEconomicComponent.fixedCommission ?? 0)}</span><span id="Totals_FixedCommission_Percent" className="porcentajes-totales">{fixedCommissionPercentage.toFixed(2) + '%'}</span></div>
                      }
                      {!!(showField('variableCommission', 'totals')) &&
                        <div><span className="total-import-left">{t(`${tKey}TOTALS.VARIABLE_COMMISSION`)}</span><span id="Totals_VariableCommission" className="total-import-right">{formatMoney(dossierEconomicComponent.variableCommission ?? 0)}</span><span id="Totals_VariableCommission_Percent" className="porcentajes-totales">{variableCommissionPercentage.toFixed(2) + '%'}</span></div>
                      }
                      {!!(showField('variableCommission', 'totals')) &&
                        <div><span className="total-import-left">{t('MASTERS.CODE_COMMISSION.QUALITATIVE_PERCENTAGE')}</span><span id="Totals_CualitativeCommission" className="total-import-right">{formatMoney(dossierEconomicComponent.qualitativeCommission ?? 0)}</span><span id="Totals_VariableCommission_Percent" className="porcentajes-totales">{qualitativeCommissionPercentage.toFixed(2) + '%'}</span></div>
                      }
                      {this.state.fieldsConfiguration.tin && this.state.fieldsConfiguration.totals.tin.permission !== permissions.hidden && (
                        <div><span className="total-import-left">{t(`${tKey}TOTALS.TIN`)}</span><span id="Financial_TIN" className="total-import-right">{formatPercent(dossierEconomicComponent.tin)}</span></div>
                      )}
                      {this.state.fieldsConfiguration.insurance && this.state.fieldsConfiguration.totals.insurance.permission !== permissions.hidden && (
                        <div><span className="total-import-left">{t(`${tKey}TOTALS.INSURANCE`)}</span><span id="Financial_Insurance" className="total-import-right">{dossierEconomicComponent.supplieds?.some(s => s.typeCode === suppliedCodes.INSURANCE) ? t(`${tKey}TOTALS.YES`) : t(`${tKey}TOTALS.NO`)}</span></div>
                      )}
                      {this.state.fieldsConfiguration.maintenance && this.state.fieldsConfiguration.totals.maintenance.permission !== permissions.hidden && (
                        <div><span className="total-import-left">{t(`${tKey}TOTALS.MAINTENANCE`)}</span><span id="Financial_Maintenance" className="total-import-right">{dossierEconomicComponent.supplieds?.some(s => s.typeCode === suppliedCodes.MAINTENANCE) ? t(`${tKey}TOTALS.YES`) : t(`${tKey}TOTALS.NO`)}</span></div>
                      )}
                    </Col>
                  </Col>
                </div>
              </Element>
            )
            }
            {this.state.componentsConfiguration.updatedTotals &&
            this.state.componentsConfiguration.updatedTotals.permission > permissions.hidden &&
            (
              <Element name={buttonsSidebar.updatedTotals}>
                <div className="dossier-panel" >
                  <div className="header-panel">
                    <div className="name-wrapper">
                      <h4><i className={icoCurrencyContent[setting.currency]} /> {t(`${tKey}ECONOMIC_PLAN.TITLE_PANEL`)}</h4>
                    </div>
                  </div>
                  <Col sm={12} className="totals-economic-plan">
                    {!!(showField('subtotalCol', 'updatedTotals')) &&
                    <Col sm={4} className="totals-economic-plan-izq-triple">
                      <div><span className="total-import-right">{t(`${tKey}TOTALS.PFF_OPC`)}</span><span className="total-import-right">{formatMoney(Number(isNaN(dossierEconomicComponent.modelPriceAmount) ? 0 + totalOptional.amount : Number(dossierEconomicComponent.modelPriceAmount) + totalOptional.amount).toFixed(2))}</span></div>
                      <div><span className="total-import-right">{t(`${tKey}TOTALS.DISCOUNTS`)}</span><span className="total-import-right">{formatMoney(Math.abs(Number((Math.abs(totalCampaign.amount) * -1) + (Math.abs(totalDiscount.amount) * -1))))}</span></div>
                      <div><span className="total-import-right">{t(`${tKey}SALE_OTHERS.TITLE_PANEL`)}</span><span className="total-import-right">{formatMoney(Number(totalExtraSale.amount + (isNaN(dossierEconomicComponent.transportationAmount) ? 0 : Number(dossierEconomicComponent.transportationAmount))))}</span></div>
                      <div><span className="total-import-right">{t(`${tKey}TOTALS.TAXABLE_BASE`)}</span><span className="total-import-right">{formatMoney(Number(isNaN(dossierEconomicComponent.ivaTaxableBase) ? 0 : Number(dossierEconomicComponent.ivaTaxableBase)))}</span></div>
                      <div><span className="total-import-right">{t(`${tKey}TAXES.TITLE_PANEL`)}</span><span className="total-import-right">{formatMoney(Number(dossierEconomicComponent.ivaAmount + dossierEconomicComponent.iemAmount))}</span><span className="porcentajes-totales">{Number(totalTaxesPercentaje) + '%'}</span></div>
                      <div><span className="total-import-right">{t(`${tKey}SUPPLIED.TITLE_PANEL`)}</span><span className="total-import-right">{formatMoney(Number(totalSuplidos.amount))}</span></div>
                      <div><b><span className="total-import-right">{t(`${tKey}TOTALS.SUBTOTAL`)}</span><span className="total-import-right">{formatMoney(Number(totalBill))}</span></b></div>
                    </Col>}
                    {!!(showField('totalInvoiceCol', 'updatedTotals')) &&
                    <Col sm={4} className="totals-economic-plan-der-triple">
                      <div><span className="total-import-center">{t(`${tKey}TOTALS.FINANCING_BONUS_AMOUNT`)}</span><span className="total-import-right">{formatMoney(Number(dossierEconomicComponent.financingBonusAmount))}</span></div>
                      <div><span className="total-import-center">{t(`${tKey}TOTALS.FINANCING_COMISSION`)}</span><span id="Financial_FinancingComission" className="total-import-right">{formatMoney(dossierEconomicComponent.financingCommission ?? 0)}</span></div>
                      <div><b><span className="total-import-center">{t(`${tKey}TOTALS.OPERATION_TOTAL`)}</span><span className="total-import-right">{formatMoney(Number(dossierEconomicComponent.operationTotalAmount))}</span></b></div>
                      {!!(showField('uacTotalAmount', 'updatedTotals')) &&
                       <div><span className="total-import-center">{t(`${tKey}TOTALS.PERMUTA`)}</span><span className="total-import-right">{formatMoney(Number(dossierEconomicComponent.uacTotalAmount))}</span></div>
                      }
                      <div><span className="total-import-center">{t(`${tKey}TOTALS.FINANCING_AMOUNT`)}</span><span className="total-import-right">{formatMoney(Number(dossierEconomicComponent.financingAmount))}</span></div>
                      <div><span className="total-import-center">{t(`${tKey}TOTALS.COLLECTED`)}</span><span className="total-import-right">{formatMoney(Number(dossierEconomicComponent.chargesTotalAmount))}</span></div>
                      <div><span className="total-import-center">{t(`${tKey}TOTALS.COLLECT_TOTAL`)}</span><span className="total-import-right">{formatMoney(Number(dossierEconomicComponent.paymentPending))}</span></div>
                    </Col>}
                    {!!(showField('marginsCol', 'updatedTotals')) &&
                    <Col sm={4} className="totals-economic-plan-der">
                      <div><span className="total-import-left-triple">{t(`${tKey}TOTALS.PURCHASE_MARGIN`)}</span><span className="total-import-right">{formatMoney(Number(dossierEconomicComponent.purchaseMargin))}</span><span className="porcentajes-totales">{margenComprasPorcentaje.toFixed(2) + '%'}</span></div>
                      <div><span className="total-import-left-triple">{t(`${tKey}TOTALS.DTO_TOTAL`)}</span><span className="total-import-right">{formatMoney(Number(dossierEconomicComponent.allDiscount))}</span><span className="porcentajes-totales">{descuentoTotalPorcentaje.toFixed(2) + '%'}</span></div>
                      {(this.state.componentsConfiguration.reaconditioning && this.state.componentsConfiguration.reaconditioning.permission > permissions.hidden && dossierSubTypeId === dossierSubTypes.venta_vo) &&
                      <div><span className="total-import-left-triple">{t(`${tKey}TOTALS.DTO_TOTAL_REACONDITIONING`)}</span><span className="total-import-right">{formatMoney(Number(dossierEconomicComponent.modelPriceCost))}</span></div> }
                      <div><span className="total-import-left-triple">{t(`${tKey}TOTALS.OPERATION_MARGIN`)}</span><span className="total-import-right">{formatMoney(Number(dossierEconomicComponent.operationMargin))}</span><span className="porcentajes-totales">{margenOperacionPorcentaje.toFixed(2) + '%'}</span></div>
                      <div><span className="total-import-left-triple">{t(`${tKey}TOTALS.NET_MARGIN`)}</span><span className="total-import-right">{formatMoney(Number(dossierEconomicComponent.netMargin))}</span><span className="porcentajes-totales">{netMarginPercentage.toFixed(2) + '%'}</span></div>
                      {!!(showField('fixedCommission', 'updatedTotals')) &&
                        <div><span className="total-import-left">{t(`${tKey}TOTALS.FIXED_COMMISSION`)}</span><span id="UpdatedTotals_FixedCommission" className="total-import-right">{formatMoney(dossierEconomicComponent.fixedCommission ?? 0)}</span><span id="UpdatedTotals_FixedCommission_Percent" className="porcentajes-totales">{fixedCommissionPercentage.toFixed(2) + '%'}</span></div>
                      }
                      {!!(showField('variableCommission', 'updatedTotals')) &&
                        <div><span className="total-import-left">{t(`${tKey}TOTALS.VARIABLE_COMMISSION`)}</span><span id="UpdatedTotals_VariableCommission" className="total-import-right">{formatMoney(dossierEconomicComponent.variableCommission ?? 0)}</span><span id="UpdatedTotals_VariableCommission_Percent" className="porcentajes-totales">{variableCommissionPercentage.toFixed(2) + '%'}</span></div>
                      }
                      {this.state.fieldsConfiguration.tin && this.state.fieldsConfiguration.totals.tin.permission !== permissions.hidden && (
                        <div><span className="total-import-left">{t(`${tKey}TOTALS.TIN`)}</span><span id="Financial_TIN" className="total-import-right">{formatPercent(dossierEconomicComponent.tin)}</span></div>
                      )}
                      {this.state.fieldsConfiguration.insurance && this.state.fieldsConfiguration.totals.insurance.permission !== permissions.hidden && (
                        <div><span className="total-import-left">{t(`${tKey}TOTALS.INSURANCE`)}</span><span id="Financial_Insurance" className="total-import-right">{dossierEconomicComponent.supplieds?.some(s => s.typeCode === suppliedCodes.INSURANCE) ? t(`${tKey}TOTALS.YES`) : t(`${tKey}TOTALS.NO`)}</span></div>
                      )}
                      {this.state.fieldsConfiguration.maintenance && this.state.fieldsConfiguration.totals.maintenance.permission !== permissions.hidden && (
                        <div><span className="total-import-left">{t(`${tKey}TOTALS.MAINTENANCE`)}</span><span id="Financial_Maintenance" className="total-import-right">{dossierEconomicComponent.supplieds?.some(s => s.typeCode === suppliedCodes.MAINTENANCE) ? t(`${tKey}TOTALS.YES`) : t(`${tKey}TOTALS.NO`)}</span></div>
                      )}
                    </Col>}
                  </Col>
                </div>
              </Element>
            )
            }
            {
              // VEHICLE AMOUNT
            }
            {this.state.componentsConfiguration.vehicle_amount &&
            this.state.componentsConfiguration.vehicle_amount.permission > permissions.hidden &&
            (
              <Element name={buttonsSidebar.vehicle_amount}>
                <div className="dossier-panel">
                  <div className="header-panel" onClick={() => this.setState({ vehicle_amountOpen: !this.state.vehicle_amountOpen })}>
                    <div className="name-wrapper">
                      <i className={this.state.vehicle_amountOpen ? 'ico-arrow-black-up' : 'ico-arrow-black-down'} />
                      <h4>{t(`${tKey}VEHICLE_AMOUNT.TITLE_PANEL`)}</h4>
                    </div>
                  </div>
                  <Panel expanded={this.state.vehicle_amountOpen} onToggle={() => {}}>
                    <Panel.Collapse>
                      <Table hover responsive>
                        <thead>
                          <tr>
                            <th className='maxW'></th>
                            {!!showField('amountCol', 'vehicle_amount') && <th className="text-center">{t(`${tKey}VEHICLE_AMOUNT.VTA_IMPORT`)}</th>}
                            {/* <th className="text-center"></th> */}
                            {!!showField('costCol', 'vehicle_amount') && <th className="text-center">{t(`${tKey}VEHICLE_AMOUNT.COST`)}</th>}
                            {!!showField('fraSupCol', 'vehicle_amount') && <th className="text-center">{t(`${tKey}VEHICLE_AMOUNT.FRA_SUP`)}</th>}
                            {!!showField('iemCol', 'vehicle_amount') && <th className="text-center">{t(`${tKey}VEHICLE_AMOUNT.IEM`)}</th>}
                            {!!showField('providerCol', 'vehicle_amount') && <th className="text-center">{t(`${tKey}VEHICLE_AMOUNT.PROVIDER`)}</th>}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className='maxW'><span style={{ float: 'left', marginTop: '12px' }}>{t(`${tKey}VEHICLE_AMOUNT.VEH_PREVIUS`)}</span></td>
                            {!!showField('amountCol', 'vehicle_amount') && <td>
                              {!!showField('modelPriceAmount', 'vehicle_amount') &&
                              (
                                <Field
                                  colSm={10}
                                  id="modelPriceAmount"
                                  name="dossierEconomicComponent.modelPriceAmount"
                                  component={InputMoney}
                                  inputType="number"
                                  disabled="disabled"
                                  placeholder={formatMoney(dossierEconomicComponent.modelPriceAmount)}
                                  style={{ textAlign: 'right' }}
                                />
                              )
                              }
                            </td>}
                            {/* <td></td> */}
                            {!!showField('costCol', 'vehicle_amount') && <td>
                              {!!showField('modelPriceCost', 'vehicle_amount') &&
                              (
                                <Field
                                  colSm={12}
                                  id="modelPriceCost"
                                  name="dossierEconomicComponent.modelPriceCost"
                                  component={InputMoney}
                                  inputType="number"
                                  disabled="disabled"
                                  placeholder={formatMoney(dossierEconomicComponent.modelPriceCost)}
                                  style={{ textAlign: 'right' }}
                                />
                              )
                              }
                            </td>}
                            {!!showField('fraSupCol', 'vehicle_amount') && <td></td>}
                            {!!showField('iemCol', 'vehicle_amount') && <td className="text-center">
                              <span style={{ fontSize: '9px', paddingLeft: '5px' }}><Glyphicon glyph="ok" /></span>
                            </td>}
                            {!!showField('providerCol', 'vehicle_amount') && <td></td>}
                          </tr>
                          <tr>
                            <td className='maxW'><span style={{ float: 'left', marginTop: '12px' }}>{t(`${tKey}VEHICLE_AMOUNT.TRANSPORT`)}</span></td>
                            {!!showField('amountCol', 'vehicle_amount') && <td>
                              {!!showField('transportationAmount', 'vehicle_amount') &&
                              (
                                <Field
                                  colSm={10}
                                  id="transportationAmount"
                                  name="dossierEconomicComponent.transportationAmount"
                                  component={InputMoney}
                                  inputType="number"
                                  disabled="disabled"
                                  placeholder={formatMoney(dossierEconomicComponent.transportationAmount)}
                                  style={{ textAlign: 'right' }}
                                />
                              )}
                            </td>}
                            {/* <td></td> */}
                            {!!showField('costCol', 'vehicle_amount') && <td>
                              {!!showField('transportationCost', 'vehicle_amount') &&
                            (
                              <Field
                                colSm={12}
                                id="transportationCost"
                                name="dossierEconomicComponent.transportationCost"
                                component={InputMoney}
                                inputType="number"
                                disabled="disabled"
                                placeholder={formatMoney(dossierEconomicComponent.transportationCost)}
                                style={{ textAlign: 'right' }}
                              />
                            )}
                            </td>}
                            {!!showField('fraSupCol', 'vehicle_amount') && <td></td>}
                            {!!showField('iemCol', 'vehicle_amount') && <td className="text-center">
                              <span style={{ fontSize: '9px', paddingLeft: '5px' }}><Glyphicon glyph="ok" /></span>
                            </td>}
                            {!!showField('providerCol', 'vehicle_amount') && <td></td>}
                          </tr>
                        </tbody>
                      </Table>
                    </Panel.Collapse>
                  </Panel>
                </div>
              </Element>
            )
            }
            {
              // OPTIONALS
            }
            {this.state.componentsConfiguration.optional &&
            this.state.componentsConfiguration.optional.permission > permissions.hidden &&
            (
              <Element name={buttonsSidebar.optional}>
                <div className="optionals-panel dossier-panel">
                  <div className="header-panel" onClick={() => this.setState({ optionalOpen: !this.state.optionalOpen })}>
                    <div className="name-wrapper">
                      <i className={this.state.optionalOpen ? 'ico-arrow-black-up' : 'ico-arrow-black-down'} />
                      <h4>{t(`${tKey}OPTIONALS.TITLE_PANEL`)}</h4>
                      <EconomicPlanTotal
                        totals={totalOptional}
                        totalText={t(`${tKey}ECONOMIC_PLAN.TOTALS`) + ' ' + t(`${tKey}OPTIONALS.VTA_IMPORT`)}
                        costText={t(`${tKey}ECONOMIC_PLAN.TOTALS`) + ' ' + t(`${tKey}OPTIONALS.COST`)}
                        showCost={!!showField('costCol', 'optional')}
                      />
                    </div>
                  </div>
                  <Panel expanded={this.state.optionalOpen} onToggle={() => {}}>
                    <Panel.Collapse>
                      <Table hover responsive>
                        <thead>
                          <tr>
                            <th className='maxW40'></th>
                            {!!showField('optionalsCol', 'optional') && <th className="text-center">{t(`${tKey}OPTIONALS.TITLE_PANEL`)}</th>}
                            {!!showField('amountCol', 'optional') && <th className="text-center">{t(`${tKey}OPTIONALS.VTA_IMPORT`)}</th>}
                            <th className="text-center"></th>
                            {!!showField('costCol', 'optional') && <th className="text-center">{t(`${tKey}OPTIONALS.COST`)}</th>}
                            {!!showField('fraSupCol', 'optional') && <th className="text-center">{t(`${tKey}OPTIONALS.FRA_SUP`)}</th>}
                            {!!showField('iemCol', 'optional') && <th className="text-center">{t(`${tKey}OPTIONALS.IEM`)}</th>}
                            {!!showField('providerCol', 'optional') && <th className="text-center">{t(`${tKey}OPTIONALS.PROVIDER`)}</th>}
                          </tr>
                        </thead>
                        <tbody>
                          {
                            dossierEconomicComponent.optionals && dossierEconomicComponent.optionals.length > 0 &&
                        dossierEconomicComponent.optionals.sort((a, b) => a.id - b.id).map((optionals, idx) => (
                          <tr key={idx}>
                            <td></td>
                            {!!showField('optionalsCol', 'optional') && <td>{optionals.name}</td>}
                            {!!showField('amountCol', 'optional') && <td className="text-right">{formatMoney(optionals.amount)}</td>}
                            <td className="text-right"></td>
                            {!!showField('costCol', 'optional') && <td className="text-right">{formatMoney(optionals.cost)}</td>}
                            {!!showField('fraSupCol', 'optional') && <td className="text-center">{optionals.supInvoice}</td>}
                            {!!showField('iemCol', 'optional') && <td className="text-center">{<span style={{ fontSize: '9px', paddingLeft: '5px' }}><Glyphicon glyph="ok" /></span>}</td>}
                            {!!showField('providerCol', 'optional') && <td className="text-center">{optionals.provider}</td>}
                          </tr>
                        ))
                          }
                          {
                            totalOptional &&
                        <tr>
                          <td><b>{totalOptional.name}</b></td>
                          {!!showField('optionalsCol', 'optional') && <td></td>}
                          {!!showField('amountCol', 'optional') && <td className="text-right"><b>{formatMoney(totalOptional.amount)}</b></td>}
                          <td className="text-right"><b>{totalOptional.percentage}</b></td>
                          {!!showField('costCol', 'optional') && <td className="text-right"><b>{formatMoney(totalOptional.cost)}</b></td>}
                          {!!showField('fraSupCol', 'optional') && <td className="text-center"><b>{totalOptional.supInvoice}</b></td>}
                          {!!showField('iemCol', 'optional') && <td className="text-center"><b>{totalOptional.iem}</b></td>}
                          {!!showField('providerCol', 'optional') && <td className="text-center"><b>{totalOptional.provider}</b></td>}
                        </tr>
                          }
                        </tbody>
                      </Table>
                    </Panel.Collapse>
                  </Panel>
                </div>
              </Element>
            )
            }
            {
              // DTO. CAMPAIGN
            }
            {this.state.componentsConfiguration.campaign_discount &&
            this.state.componentsConfiguration.campaign_discount.permission > permissions.hidden &&
            (
              <Element name={buttonsSidebar.campaign_discount}>
                <div className="dossier-panel">
                  <div className="header-panel" onClick={() => this.setState({ campaign_discountOpen: !this.state.campaign_discountOpen })}>
                    <div className="name-wrapper">
                      <i className={this.state.campaign_discountOpen ? 'ico-arrow-black-up' : 'ico-arrow-black-down'} />
                      <h4>{t(`${tKey}CAMPAIGN_DISCOUNT.TITLE_PANEL`)}</h4>
                      <EconomicPlanTotal
                        totals={totalCampaign}
                        totalText={t(`${tKey}ECONOMIC_PLAN.TOTALS`) + ' ' + t(`${tKey}CAMPAIGN_DISCOUNT.VTA_IMPORT`)}
                        costText={t(`${tKey}ECONOMIC_PLAN.TOTALS`) + ' ' + t(`${tKey}CAMPAIGN_DISCOUNT.COST`)} />
                    </div>
                  </div>
                  <Panel expanded={this.state.campaign_discountOpen} onToggle={() => {}}>
                    <Panel.Collapse>
                      <Table hover responsive>
                        <thead>
                          <tr>
                            <th className='maxW40'></th>
                            {!!showField('campaignDiscountCol', 'campaign_discount') && <th className="text-center">{t(`${tKey}CAMPAIGN_DISCOUNT.TITLE_PANEL`)}</th>}
                            {!!showField('amountCol', 'campaign_discount') && <th className="text-left">{t(`${tKey}CAMPAIGN_DISCOUNT.VTA_IMPORT`)}</th>}
                            {!!showField('percentageCol', 'campaign_discount') && <th className="text-center">{t(`${tKey}CAMPAIGN_DISCOUNT.PERCENTAGE`)}</th>}
                            {!!showField('costCol', 'campaign_discount') && <th className="text-left">{t(`${tKey}CAMPAIGN_DISCOUNT.COST`)}</th>}
                            {!!showField('apConcCol', 'campaign_discount') && <th className="text-left">{t(`${tKey}CAMPAIGN_DISCOUNT.COST_CONCESSIONAIRE`)}</th>}
                            {!!showField('costConcessionPercentageCol', 'campaign_discount') && <th className="text-left">{t(`${tKey}CAMPAIGN_DISCOUNT.PERCENTAGE`)}</th>}
                            {!!showField('fraSupCol', 'campaign_discount') && <th className="text-center">{t(`${tKey}CAMPAIGN_DISCOUNT.FRA_SUP`)}</th>}
                            {!!showField('iemCol', 'campaign_discount') && <th className="text-center">{t(`${tKey}CAMPAIGN_DISCOUNT.IEM`)}</th>}
                            {!!showField('providerCol', 'campaign_discount') && <th className="text-center">{t(`${tKey}CAMPAIGN_DISCOUNT.PROVIDER`)}</th>}
                          </tr>
                        </thead>
                        <tbody>
                          {
                            dossierEconomicComponent.campaigns &&
                            dossierEconomicComponent.campaigns.map((campaigns, idx) => (
                              <tr key={idx}>
                                <td></td>
                                {!!showField('campaignDiscountCol', 'campaign_discount') && <td>{campaigns.name}</td>}
                                {!!showField('amountCol', 'campaign_discount') && <td className="text-left">{formatMoney(campaigns.amount)}</td>}
                                {!!showField('percentageCol', 'campaign_discount') && <td className="text-left">{
                                  dossierEconomicComponent.modelPriceAmount
                                    ? ((campaigns.amount / (dossierEconomicComponent.modelPriceAmount +
                                      (totalOptional ? totalOptional.amount : 0)) * 100).toFixed(2) + '%') : ''
                                }</td>}
                                {!!showField('costCol', 'campaign_discount') && <td className="text-left">{formatMoney(campaigns.cost)}</td>}
                                {!!showField('apConcCol', 'campaign_discount') && <td className="text-left">{formatMoney(campaigns.costConcessionaire)}</td>}
                                {!!showField('costConcessionPercentageCol', 'campaign_discount') && <td className="text-left">{formatPercent(campaigns.costConcessionPercentage)}</td>}
                                {!!showField('fraSupCol', 'campaign_discount') && <td className="text-center">{campaigns.supInvoice}</td>}
                                {!!showField('iemCol', 'campaign_discount') && <td className="text-center"><span style={{ fontSize: '9px', paddingLeft: '5px' }}><Glyphicon glyph="ok"/></span></td>}
                                {!!showField('providerCol', 'campaign_discount') && <td className="text-center">{campaigns.provider}</td>}
                              </tr>
                            )
                            )
                          }
                          {
                            totalCampaign &&
                            <tr>
                              <td><b>{totalCampaign.name}</b></td>
                              {!!showField('campaignDiscountCol', 'campaign_discount') && <td></td>}
                              {!!showField('amountCol', 'campaign_discount') && <td className="text-left"><b>{formatMoney(totalCampaign.amount)}</b></td>}
                              {!!showField('percentageCol', 'campaign_discount') && <td className="text-left"><b>{formatPercent(totalCampaign.percentage)}</b></td>}
                              {!!showField('costCol', 'campaign_discount') && <td className="text-left"><b>{formatMoney(totalCampaign.cost)}</b></td>}
                              {!!showField('apConcCol', 'campaign_discount') && <td className="text-left"><b>{formatMoney(totalCampaign.costConcessionaire)}</b></td>}
                              {!!showField('costConcessionPercentageCol', 'campaign_discount') && <td className="text-left"><b>{formatPercent(totalCampaign.costConcessionPercentage)}</b></td>}
                              {!!showField('fraSupCol', 'campaign_discount') && <td className="text-center"><b>{totalCampaign.supInvoice}</b></td>}
                              {!!showField('iemCol', 'campaign_discount') && <td className="text-center"><b>{totalCampaign.iem}</b></td>}
                              {!!showField('providerCol', 'campaign_discount') && <td className="text-center"><b>{totalCampaign.provider}</b></td>}
                            </tr>
                          }
                        </tbody>
                      </Table>
                    </Panel.Collapse>
                  </Panel>
                </div>
              </Element>
            )
            }
            {
              // ADITIONAL DISCOUNT
            }
            {this.state.componentsConfiguration.additional_discount &&
            this.state.componentsConfiguration.additional_discount.permission > permissions.hidden &&
            (
              <Element name={buttonsSidebar.additional_discount}>
                <div className="dossier-panel">
                  <div className="header-panel" onClick={() => this.setState({ additional_discountOpen: !this.state.additional_discountOpen })}>
                    <div className="name-wrapper">
                      <i className={this.state.additional_discountOpen ? 'ico-arrow-black-up' : 'ico-arrow-black-down'} />
                      <h4>{t(`${tKey}ADITIONAL_DISCOUNT.TITLE_PANEL`)}</h4>
                      <EconomicPlanTotal
                        totals={totalDiscount}
                        totalText={t(`${tKey}ECONOMIC_PLAN.TOTALS`) + ' ' + t(`${tKey}ADITIONAL_DISCOUNT.VTA_IMPORT`)}
                        costText={t(`${tKey}ECONOMIC_PLAN.TOTALS`) + ' ' + t(`${tKey}ADITIONAL_DISCOUNT.COST`)} />
                    </div>
                  </div>
                  <Panel expanded={this.state.additional_discountOpen} onToggle={() => {}}>
                    <Panel.Collapse>
                      {!!showField('discounts', 'additional_discount') &&
                    (
                      <Table hover responsive>
                        <thead>
                          <tr>
                            <th className='maxW40'></th>
                            {!!showField('additionalDiscountCol', 'additional_discount') && <th className="text-center">{t(`${tKey}CAMPAIGN_DISCOUNT.TITLE_PANEL`)}</th>}
                            {!!showField('amountCol', 'additional_discount') && <th className="text-center">{t(`${tKey}CAMPAIGN_DISCOUNT.VTA_IMPORT`)}</th>}
                            {!!showField('percentageCol', 'additional_discount') && <th className="text-center">{t(`${tKey}CAMPAIGN_DISCOUNT.PERCENTAGE`)}</th>}
                            {!!showField('costCol', 'additional_discount') && <th className="text-center">{t(`${tKey}CAMPAIGN_DISCOUNT.COST`)}</th>}
                            {!!showField('fraSupCol', 'additional_discount') && <th className="text-center">{t(`${tKey}CAMPAIGN_DISCOUNT.FRA_SUP`)}</th>}
                            {!!showField('iemCol', 'additional_discount') && <th className="text-center">{t(`${tKey}CAMPAIGN_DISCOUNT.IEM`)}</th>}
                            {!!showField('providerCol', 'additional_discount') && <th className="text-center">{t(`${tKey}CAMPAIGN_DISCOUNT.PROVIDER`)}</th>}
                          </tr>
                        </thead>
                        <tbody>
                          {
                            dossierEconomicComponent.discounts &&
                          dossierEconomicComponent.discounts.map((discounts, idx) => (
                            <tr key={idx}>
                              <td></td>
                              {!!showField('additionalDiscountCol', 'additional_discount') && <td>{discounts.name}</td>}
                              {!!showField('amountCol', 'additional_discount') && <td className="text-right">{formatMoney(discounts.amount)}</td>}
                              {!!showField('percentageCol', 'additional_discount') && <td className="text-right">{dossierEconomicComponent.modelPriceAmount ? ((discounts.amount / (dossierEconomicComponent.modelPriceAmount + totalOptional.amount) * 100).toFixed(2) + '%') : ''}</td>}
                              {!!showField('costCol', 'additional_discount') && <td className="text-right">{formatMoney(discounts.cost)}</td>}
                              {!!showField('fraSupCol', 'additional_discount') && <td className="text-center">{discounts.supInvoice}</td>}
                              {!!showField('iemCol', 'additional_discount') && <td className="text-center"><span style={{ fontSize: '9px', paddingLeft: '5px' }}><Glyphicon glyph="ok" /></span></td>}
                              {!!showField('providerCol', 'additional_discount') && <td className="text-center">{discounts.provider}</td>}
                            </tr>
                          ))
                          }
                          {
                            totalDiscount &&
                          <tr>
                            <td><b>{totalDiscount.name}</b></td>
                            {!!showField('additionalDiscountCol', 'additional_discount') && <td></td>}
                            {!!showField('amountCol', 'additional_discount') && <td className="text-right"><b>{formatMoney(totalDiscount.amount)}</b></td>}
                            {!!showField('percentageCol', 'additional_discount') && <td className="text-right"><b>{totalDiscount.percentage}</b></td>}
                            {!!showField('costCol', 'additional_discount') && <td className="text-right"><b>{formatMoney(totalDiscount.cost)}</b></td>}
                            {!!showField('fraSupCol', 'additional_discount') && <td className="text-center"><b>{totalDiscount.supInvoice}</b></td>}
                            {!!showField('iemCol', 'additional_discount') && <td className="text-center"><b>{totalDiscount.iem}</b></td>}
                            {!!showField('providerCol', 'additional_discount') && <td className="text-center"><b>{totalDiscount.provider}</b></td>}
                          </tr>
                          }
                        </tbody>
                      </Table>
                    )
                      }
                    </Panel.Collapse>
                  </Panel>
                </div>
              </Element>
            )
            }
            {
              // SALE OTHERS - EXTRA SALES
            }
            {this.state.componentsConfiguration.sale_others &&
            this.state.componentsConfiguration.sale_others.permission > permissions.hidden &&
            (
              <Element name={buttonsSidebar.sale_others}>
                <div className="optionals-panel dossier-panel">
                  <div className="header-panel" onClick={() => this.setState({ sale_othersOpen: !this.state.sale_othersOpen })}>
                    <div className="name-wrapper">
                      <i className={this.state.sale_othersOpen ? 'ico-arrow-black-up' : 'ico-arrow-black-down'} />
                      <h4>{t(`${tKey}SALE_OTHERS.TITLE_PANEL`)}</h4>
                      <EconomicPlanTotal
                        totals={totalExtraSale}
                        totalText={t(`${tKey}ECONOMIC_PLAN.TOTALS`) + ' ' + t(`${tKey}SALE_OTHERS.VTA_IMPORT`)}
                        costText={t(`${tKey}ECONOMIC_PLAN.TOTALS`) + ' ' + t(`${tKey}SALE_OTHERS.COST`)}
                        bold={true}
                        showCost={!!showField('costCol', 'sale_others')} />
                    </div>
                  </div>
                  <Panel expanded={this.state.sale_othersOpen} onToggle={() => {}}>
                    <Panel.Collapse>
                      {!!showField('extraSales', 'sale_others') &&
                    (
                      <Table hover responsive>
                        <thead>
                          <tr>
                            <th className='maxW40'></th>
                            {!!showField('otherSalesCol', 'sale_others') && <th className="text-center">{t(`${tKey}SALE_OTHERS.TITLE_PANEL`)}</th>}
                            {!!showField('amountCol', 'sale_others') && <th className="text-center">{t(`${tKey}SALE_OTHERS.VTA_IMPORT`)}</th>}
                            <th className="text-center"></th>
                            {!!showField('costCol', 'sale_others') && <th className="text-center">{t(`${tKey}SALE_OTHERS.COST`)}</th>}
                            {!!showField('fraSupCol', 'sale_others') && <th className="text-center">{t(`${tKey}SALE_OTHERS.FRA_SUP`)}</th>}
                            {!!showField('iemCol', 'sale_others') && <th className="text-center">{t(`${tKey}SALE_OTHERS.IEM`)}</th>}
                            {!!showField('providerCol', 'sale_others') && <th className="text-center">{t(`${tKey}SALE_OTHERS.PROVIDER`)}</th>}
                          </tr>
                        </thead>
                        <tbody>
                          {
                            dossierEconomicComponent.extraSales &&
                          dossierEconomicComponent.extraSales.map((extraSales, idx) => {
                            const extraSaleType = combos.extraSaleTypeCombo.find((extraSaleTypeEl) => extraSaleTypeEl.id === extraSales.typeId)
                            const hasCustomName = extraSaleType && (extraSaleType.typeView === 1 || (!extraSaleType.typeView && extraSales.name))
                            return (
                              <tr key={idx}>
                                <td></td>
                                {!!showField('otherSalesCol', 'sale_others') && <td>
                                  <Field
                                    colSm={hasCustomName ? 6 : 12}
                                    id={'name_' + idx}
                                    name="extraSales.name"
                                    component={InputText}
                                    disabled={true}
                                    placeholder={extraSaleType.value}
                                  />
                                  {hasCustomName && (
                                    <Field
                                      colSm={6}
                                      id={'name_custom_' + idx}
                                      name="extraSales.name_custom"
                                      component={InputText}
                                      disabled={true}
                                      placeholder={extraSales.name}
                                    />
                                  )}
                                </td>}
                                {!!showField('amountCol', 'sale_others') && <td className="text-right">{formatMoney(extraSales.amount)}</td>}
                                <td className="text-right"></td>
                                {!!showField('costCol', 'sale_others') && <td className="text-right">{formatMoney(extraSales.cost)}</td>}
                                {!!showField('fraSupCol', 'sale_others') && <td className="text-center">{extraSales.supInvoice ? <span style={{ fontSize: '9px', paddingLeft: '5px' }}><Glyphicon glyph="ok" /></span> : null}</td>}
                                {!!showField('iemCol', 'sale_others') && <td className="text-center">{extraSales.iem ? <span style={{ fontSize: '9px', paddingLeft: '5px' }}><Glyphicon glyph="ok" /></span> : null }</td>}
                                {!!showField('providerCol', 'sale_others') && <td className="text-center">{extraSales.provider}</td>}
                              </tr>
                            )
                          })
                          }
                          {
                            dossierEconomicComponent.paperworks &&
                          dossierEconomicComponent.paperworks.map((paperworks, idx) => (
                            economicPlanSectionType.VENTA_OTROS === paperworks.typeId ? (
                              <tr key={idx}>
                                <td></td>
                                {!!showField('otherSalesCol', 'sale_others') && <td key={idx}><Col sm={12}><span>{t(`${tKey}EDITABLE_TABLE.GERENCIA`)}</span></Col></td>}
                                {!!showField('amountCol', 'sale_others') && <td className="text-right">{formatMoney(paperworks.amount)}</td>}
                                <td className="text-right"></td>
                                {!!showField('costCol', 'sale_others') && <td className="text-right">{formatMoney(paperworks.cost)}</td>}
                                {!!showField('fraSupCol', 'sale_others') && <td className="text-center">{paperworks.supInvoice ? <span style={{ fontSize: '9px', paddingLeft: '5px' }}><Glyphicon glyph="ok" /></span> : null}</td>}
                                {!!showField('iemCol', 'sale_others') && <td className="text-center">{paperworks.iem ? <span style={{ fontSize: '9px', paddingLeft: '5px' }}><Glyphicon glyph="ok" /></span> : null}</td>}
                                {!!showField('providerCol', 'sale_others') && <td className="text-center">{paperworks.provider}</td>}
                              </tr>
                            ) : null
                          )
                          )
                          }
                          {
                            totalExtraSale &&
                          <tr>
                            <td><b>{totalExtraSale.name}</b></td>
                            {!!showField('otherSalesCol', 'sale_others') && <td></td>}
                            {!!showField('amountCol', 'sale_others') && <td className="text-right"><b>{formatMoney(totalExtraSale.amount)}</b></td>}
                            <td className="text-right"><b>{totalExtraSale.percentage}</b></td>
                            {!!showField('costCol', 'sale_others') && <td className="text-right"><b>{formatMoney(totalExtraSale.cost)}</b></td>}
                            {!!showField('fraSupCol', 'sale_others') && <td className="text-center"><b>{totalExtraSale.supInvoice}</b></td>}
                            {!!showField('iemCol', 'sale_others') && <td className="text-center"><b>{totalExtraSale.iem}</b></td>}
                            {!!showField('providerCol', 'sale_others') && <td className="text-center"><b>{totalExtraSale.provider}</b></td>}
                          </tr>
                          }
                        </tbody>
                      </Table>
                    )
                      }
                    </Panel.Collapse>
                  </Panel>
                </div>
              </Element>
            )
            }
            {
              // TAXES
            }
            {this.state.componentsConfiguration.taxes &&
            this.state.componentsConfiguration.taxes.permission > permissions.hidden &&
            (
              <Element name={buttonsSidebar.taxes}>
                <div className="dossier-panel">
                  <div className="header-panel" onClick={() => this.setState({ taxesOpen: !this.state.taxesOpen })}>
                    <div className="name-wrapper">
                      <i className={this.state.taxesOpen ? 'ico-arrow-black-up' : 'ico-arrow-black-down'} />
                      <h4>{t(`${tKey}TAXES.TITLE_PANEL`)}</h4>
                    </div>
                  </div>
                  <Panel expanded={this.state.taxesOpen} onToggle={() => {}}>
                    <Panel.Collapse>
                      <Table hover responsive>
                        <thead>
                          <tr>
                            <th className='maxW40'></th>
                            {!!showField('ivaIemCol', 'taxes') && <th className="text-center" style={{ paddingLeft: '18px' }}></th>}
                            {!!showField('typeCol', 'taxes') && <th className="text-center" style={{ paddingLeft: '18px' }}>{t(`${tKey}TAXES.TYPE`)}</th>}
                            {!!showField('baseAmountCol', 'taxes') && <th className="text-center">{t(`${tKey}TAXES.TAXABLE_BASE`)}</th>}
                            {!!showField('amountCol', 'taxes') && <th className="text-center">{t(`${tKey}TAXES.AMOUNT`)}</th>}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className='maxW40'>{t(`${tKey}ECONOMIC_PLAN.VAT`)}</td>
                            {!!showField('ivaIemCol', 'taxes') &&
                            !!showField('ivaType', 'taxes') &&
                              <td>
                                <Field
                                  colSm={10}
                                  customClass="column-planteo-iem-iva"
                                  id="ivaTypeId"
                                  name="dossierEconomicComponent.ivaTypeId"
                                  component={InputSelect}
                                  options={combos.ivaTypeCombo}
                                  disabled={true}
                                  valueKey="id"
                                  labelKey="value"
                                  onInputChange={(valueIva) => this.setState({ valueIva })}
                                />
                              </td>
                            }
                            {!!showField('typeCol', 'taxes') &&
                                                        <td>
                                                          <Col sm={2}>
                                                            <div className="form-group" style={{ paddingTop: '8px', textAlign: 'center' }}>
                                                              {this.isOtherIvaType(this.state.valueIva)
                                                                ? (<span>
                                                                  {this.state.ivaPercentage ? this.state.ivaPercentage : ''}
                                                                </span>)
                                                                : (<span>
                                                                  {this.state.valueIva ? formatPercent(combos.ivaTypeCombo.find(x => x.id === this.state.valueIva).amountValue * 100, 0) : ''}
                                                                </span>)}

                                                            </div>
                                                          </Col>
                                                        </td>}
                            {!!showField('baseAmountCol', 'taxes') && <td className="text-right" style={{ paddingRight: '15px' }}>{formatMoney(dossierEconomicComponent.ivaTaxableBase)}</td>}
                            {!!showField('amountCol', 'taxes') && <td className="text-right" style={{ paddingRight: '15px' }}>{formatMoney(dossierEconomicComponent.ivaAmount)}</td>}
                          </tr>
                          <tr>
                            <td className='maxW40'>{t(`${tKey}ECONOMIC_PLAN.IEM`)}</td>
                            {!!showField('ivaIemCol', 'taxes') &&
                            !!showField('iemType', 'taxes') &&
                              <td>
                                <Field
                                  colSm={10}
                                  customClass="column-planteo-iem-iva"
                                  id="iemTypeId"
                                  name="dossierEconomicComponent.iemTypeId"
                                  component={InputSelect}
                                  options={combos.iemType}
                                  disabled={true}
                                  valueKey="id"
                                  labelKey="value"
                                  onInputChange={(valueIem) => this.setState({ valueIem })}
                                />
                              </td>
                            }
                            {!!showField('typeCol', 'taxes') &&
                            <td>
                              <Col sm={2}>
                                <div className="form-group" style={{ paddingTop: '8px', textAlign: 'center' }}>
                                  <span>{dossierEconomicComponent.iemTypeId ? dossierEconomicComponent.iemTypeId !== 10 ? formatPercent(combos.iemType.find(x => x.id === dossierEconomicComponent.iemTypeId).amountValue, numberOfDecimals) : formatPercent(dossierEconomicComponent.iemPercentage, numberOfDecimals) : ''}</span>
                                </div>
                              </Col>
                            </td> }
                            {!!showField('baseAmountCol', 'taxes') && <td className="text-right" style={{ paddingRight: '15px' }}>{formatMoney(dossierEconomicComponent.iemTaxableBase)}</td>}
                            {!!showField('amountCol', 'taxes') && <td className="text-right" style={{ paddingRight: '15px' }}>{formatMoney(dossierEconomicComponent.iemAmount)}</td>}
                          </tr>
                        </tbody>
                      </Table>
                    </Panel.Collapse>
                  </Panel>
                </div>
              </Element>
            )
            }
            {
              // SUPPLIED
            }
            {this.state.componentsConfiguration.supplied &&
            this.state.componentsConfiguration.supplied.permission > permissions.hidden &&
            (
              <Element name={buttonsSidebar.supplied}>
                <div className="dossier-panel">
                  <div className="header-panel" onClick={() => this.setState({ suppliedOpen: !this.state.suppliedOpen })}>
                    <div className="name-wrapper">
                      <i className={this.state.suppliedOpen ? 'ico-arrow-black-up' : 'ico-arrow-black-down'} />
                      <h4>{t(`${tKey}SUPPLIED.TITLE_PANEL`)}</h4>
                      <EconomicPlanTotal
                        totals={totalSuplidos}
                        totalText={t(`${tKey}ECONOMIC_PLAN.TOTALS`) + ' ' + t(`${tKey}SUPPLIED.VTA_IMPORT`)}
                        costText={t(`${tKey}ECONOMIC_PLAN.TOTALS`) + ' ' + t(`${tKey}SUPPLIED.COST`)}
                        bold={true}
                        showCost={!!showField('costCol', 'supplied')}/>
                    </div>
                  </div>
                  <Panel expanded={this.state.suppliedOpen} onToggle={() => {}}>
                    <Panel.Collapse>
                      <Table hover responsive>
                        <thead>
                          <tr>
                            <th className='maxW40'></th>
                            {!!showField('suppliedCol', 'supplied') && <th className="text-center">{t(`${tKey}SUPPLIED.TITLE_PANEL`)}</th>}
                            {!!showField('amountCol', 'supplied') && <th className="text-center">{t(`${tKey}SUPPLIED.VTA_IMPORT`)}</th>}
                            <th className="text-center"></th>
                            {!!showField('costCol', 'supplied') && <th className="text-center">{t(`${tKey}SUPPLIED.COST`)}</th>}
                            {!!showField('fraSupCol', 'supplied') && <th className="text-center">{t(`${tKey}SUPPLIED.FRA_SUP`)}</th>}
                            {!!showField('iemCol', 'supplied') && <th className="text-center">{t(`${tKey}SUPPLIED.IEM`)}</th>}
                            {!!showField('providerCol', 'supplied') && <th className="text-center">{t(`${tKey}SUPPLIED.PROVIDER`)}</th>}
                          </tr>
                        </thead>
                        <tbody>
                          {
                            iemSuppied &&
                          <tr>
                            <td></td>
                            {!!showField('suppliedCol', 'supplied') && <td>{iemSuppied.name}</td>}
                            {!!showField('amountCol', 'supplied') && <td className="text-right">{formatMoney(iemSuppied.amount)}</td>}
                            <td className="text-right">{iemSuppied.percentage}</td>
                            {!!showField('costCol', 'supplied') && <td className="text-right">{formatMoney(iemSuppied.cost)}</td>}
                            {!!showField('fraSupCol', 'supplied') && <td className="text-center">{iemSuppied.supInvoice ? <span style={{ fontSize: '9px', paddingLeft: '5px' }}><Glyphicon glyph="ok" /></span> : null}</td>}
                            {!!showField('iemCol', 'supplied') && <td className="text-center">{iemSuppied.iem ? <span style={{ fontSize: '9px', paddingLeft: '5px' }}><Glyphicon glyph="ok" /></span> : null}</td>}
                            {!!showField('providerCol', 'supplied') && <td className="text-center">{iemSuppied.provider}</td>}
                          </tr>
                          }
                          {
                            dossierEconomicComponent.supplieds &&
                          dossierEconomicComponent.supplieds.map((supplieds, idx) => (
                            <tr key={idx}>
                              <td></td>
                              {!!showField('suppliedCol', 'supplied') && <td>
                                <Field
                                  colSm={12}
                                  id="name"
                                  name="suplidos.name"
                                  component={InputText}
                                  disabled={true}
                                  valueKey="id"
                                  labelKey="value"
                                  placeholder={combos.suppliedTypeCombo.find(combo => combo.id === supplieds.typeId) ? combos.suppliedTypeCombo.find(combo => combo.id === supplieds.typeId).value : ''}
                                />
                              </td>}
                              {!!showField('amountCol', 'supplied') && <td className="text-right">{formatMoney(supplieds.amount)}</td>}
                              <td className="text-right"></td>
                              {!!showField('costCol', 'supplied') && <td className="text-right">{formatMoney(supplieds.cost)}</td>}
                              {!!showField('fraSupCol', 'supplied') && <td className="text-center">{supplieds.supInvoice ? <span style={{ fontSize: '9px', paddingLeft: '5px' }}><Glyphicon glyph="ok" /></span> : null}</td>}
                              {!!showField('iemCol', 'supplied') && <td className="text-center">{supplieds.iem ? <span style={{ fontSize: '9px', paddingLeft: '5px' }}><Glyphicon glyph="ok" /></span> : null}</td>}
                              {!!showField('providerCol', 'supplied') && <td className="text-center">{supplieds.provider}</td>}
                            </tr>
                          ))
                          }
                          {
                            dossierEconomicComponent.specialHelps &&
                          dossierEconomicComponent.specialHelps.map((specialHelps, idx) => (
                            <tr key={idx}>
                              <td></td>
                              {!!showField('suppliedCol', 'supplied') && <td>{t(`${tKey}EDITABLE_TABLE.SPECIAL_HELP`)}</td>}
                              {!!showField('amountCol', 'supplied') && <td className="text-right">{formatMoney(specialHelps.amount)}</td>}
                              <td className="text-right"></td>
                              {!!showField('costCol', 'supplied') && <td className="text-right">{formatMoney(specialHelps.cost)}</td>}
                              {!!showField('fraSupCol', 'supplied') && <td className="text-center">{specialHelps.supInvoice ? <span style={{ fontSize: '9px', paddingLeft: '5px' }}><Glyphicon glyph="ok" /></span> : null}</td>}
                              {!!showField('iemCol', 'supplied') && <td className="text-center">{specialHelps.iem ? <span style={{ fontSize: '9px', paddingLeft: '5px' }}><Glyphicon glyph="ok" /></span> : null}</td>}
                              {!!showField('providerCol', 'supplied') && <td className="text-center">{specialHelps.provider}</td>}
                            </tr>
                          ))
                          }
                          {
                            dossierEconomicComponent.paperworks &&
                          dossierEconomicComponent.paperworks.map((paperworks, idx) => (
                            economicPlanSectionType.SUPLIDOS === paperworks.typeId ? (
                              <tr key={idx}>
                                <td></td>
                                {!!showField('suppliedCol', 'supplied') && <td key={idx}><Col sm={12}><span>{t(`${tKey}EDITABLE_TABLE.GERENCIA`)}</span></Col></td>}
                                {!!showField('amountCol', 'supplied') && <td className="text-right">{formatMoney(paperworks.amount)}</td>}
                                <td className="text-right"></td>
                                {!!showField('costCol', 'supplied') && <td className="text-right">{formatMoney(paperworks.cost)}</td>}
                                {!!showField('fraSupCol', 'supplied') && <td className="text-center">{paperworks.supInvoice ? <span style={{ fontSize: '9px', paddingLeft: '5px' }}><Glyphicon glyph="ok" /></span> : null}</td>}
                                {!!showField('iemCol', 'supplied') && <td className="text-center">{paperworks.iem ? <span style={{ fontSize: '9px', paddingLeft: '5px' }}><Glyphicon glyph="ok" /></span> : null}</td>}
                                {!!showField('providerCol', 'supplied') && <td className="text-center">{paperworks.provider}</td>}
                              </tr>
                            ) : null
                          )
                          )
                          }
                          {
                            totalSuplidos &&
                          <tr>
                            <td><b>{totalSuplidos.name}</b></td>
                            {!!showField('suppliedCol', 'supplied') && <td></td>}
                            {!!showField('amountCol', 'supplied') && <td className="text-right"><b>{formatMoney(totalSuplidos.amount)}</b></td>}
                            <td className="text-right"><b>{totalSuplidos.percentage}</b></td>
                            {!!showField('costCol', 'supplied') && <td className="text-right"><b>{formatMoney(totalSuplidos.cost)}</b></td>}
                            {!!showField('fraSupCol', 'supplied') && <td className="text-center"><b>{totalSuplidos.supInvoice}</b></td>}
                            {!!showField('iemCol', 'supplied') && <td className="text-center"><b>{totalSuplidos.iem}</b></td>}
                            {!!showField('providerCol', 'supplied') && <td className="text-center"><b>{totalSuplidos.provider}</b></td>}
                          </tr>
                          }
                        </tbody>
                      </Table>
                    </Panel.Collapse>
                  </Panel>
                </div>
              </Element>
            )
            }
            {
              // COST GIFTS - PRESENTS
            }
            {this.state.componentsConfiguration.costs_gifts &&
            this.state.componentsConfiguration.costs_gifts.permission > permissions.hidden &&
            (
              <Element name={buttonsSidebar.costs_gifts}>
                <div className="dossier-panel">
                  <div className="header-panel" onClick={() => this.setState({ costs_giftsOpen: !this.state.costs_giftsOpen })}>
                    <div className="name-wrapper">
                      <i className={this.state.costs_giftsOpen ? 'ico-arrow-black-up' : 'ico-arrow-black-down'} />
                      <h4>{t(`${tKey}COSTS_GIFTS.TITLE_PANEL`)}</h4>
                      <EconomicPlanTotal
                        totals={totalPresent}
                        totalText={t(`${tKey}ECONOMIC_PLAN.TOTALS`) + ' ' + t(`${tKey}COSTS_GIFTS.VTA_IMPORT`)}
                        costText={t(`${tKey}ECONOMIC_PLAN.TOTALS`) + ' ' + t(`${tKey}COSTS_GIFTS.COST`)}
                        hideTotalVta={true}
                        bold={true}
                        showCost={!!showField('costCol', 'costs_gifts')}
                      />
                    </div>
                  </div>
                  <Panel expanded={this.state.costs_giftsOpen} onToggle={() => {}}>
                    <Panel.Collapse>
                      {!!showField('presents', 'costs_gifts') &&
                       <Table hover responsive>
                         <thead>
                           <tr>
                             <th className='maxW40'></th>
                             {!!showField('costsPresentsCol', 'costs_gifts') && <th className="text-center">{t(`${tKey}COSTS_GIFTS.TITLE_PANEL`)}</th>}
                             <th className="text-center"></th>
                             {!!showField('costCol', 'costs_gifts') && <th className="text-center">{t(`${tKey}COSTS_GIFTS.COST`)}</th>}
                             {!!showField('providerCol', 'costs_gifts') && <th className="text-center">{t(`${tKey}COSTS_GIFTS.PROVIDER`)}</th>}
                           </tr>
                         </thead>
                         <tbody>
                           {
                             dossierEconomicComponent.presents &&
                          dossierEconomicComponent.presents.map((presents, idx) => (
                            <tr key={idx}>
                              <td></td>
                              {!!showField('costsPresentsCol', 'costs_gifts') &&
                              <td>
                                <Field
                                  colSm={12}
                                  id="name"
                                  name="totalPresent.name"
                                  component={InputText}
                                  disabled={true}
                                  valueKey="id"
                                  labelKey="value"
                                  placeholder={combos.presentTypeCombo.find(combo => combo.id === presents.typeId) ? combos.presentTypeCombo.find(combo => combo.id === presents.typeId).value : ''}
                                />
                              </td>}
                              <td className="text-right"></td>
                              {!!showField('costCol', 'costs_gifts') && <td className="text-right">{formatMoney(presents.cost)}</td>}
                              {!!showField('providerCol', 'costs_gifts') && <td className="text-center">{presents.provider}</td>}
                            </tr>
                          ))
                           }
                           {
                             dossierEconomicComponent.paperworks &&
                          dossierEconomicComponent.paperworks.map((paperworks, idx) => (
                            economicPlanSectionType.COSTE_OBSEQUIOS === paperworks.typeId ? (
                              <tr key={idx}>
                                <td></td>
                                {!!showField('costsPresentsCol', 'costs_gifts') && <td key={idx}><Col sm={12}><span>{t(`${tKey}EDITABLE_TABLE.GERENCIA`)}</span></Col></td>}
                                <td className="text-right">{formatMoney(paperworks.amount)}</td>
                                {!!showField('costCol', 'costs_gifts') && <td className="text-right">{formatMoney(paperworks.cost)}</td>}
                                {!!showField('providerCol', 'costs_gifts') && <td className="text-center">{paperworks.provider}</td>}
                              </tr>
                            ) : null
                          )
                          )
                           }
                           {
                             totalPresent &&
                          <tr>
                            <td><b>{totalPresent.name}</b></td>
                            {!!showField('costsPresentsCol', 'costs_gifts') && <td></td>}
                            <td className="text-right"></td>
                            {!!showField('costCol', 'costs_gifts') && <td className="text-right"><b>{formatMoney(totalPresent.cost)}</b></td>}
                            {!!showField('providerCol', 'costs_gifts') && <td className="text-center"><b>{totalPresent.provider}</b></td>}
                          </tr>
                           }
                         </tbody>
                       </Table>}
                    </Panel.Collapse>
                  </Panel>
                </div>
              </Element>
            )}
            {
              // ASSOCIATED COSTS
            }
            {this.state.componentsConfiguration.associated_costs &&
              this.state.componentsConfiguration.associated_costs.permission > permissions.hidden &&
            (
              <Element name={buttonsSidebar.associated_costs} className={buttonsSidebar.associated_costs}>
                <div className="dossier-panel">
                  <div className="header-panel" onClick={ () => this.setState({ associated_costsOpen: !this.state.associated_costsOpen })}>
                    <div className="name-wrapper">
                      <i className={this.state.associated_costsOpen ? 'ico-arrow-black-up' : 'ico-arrow-black-down'}/>
                      <h4>{t(`${tKey}ASSOCIATED_COSTS.TITLE_PANEL`)}</h4>
                      <EconomicPlanTotal
                        totals={totalAssociatedCosts}
                        totalText={t(`${tKey}ECONOMIC_PLAN.TOTALS`) + ' ' + t(`${tKey}ASSOCIATED_COSTS.VTA_IMPORT`)}
                        costText={t(`${tKey}ECONOMIC_PLAN.TOTALS`) + ' ' + t(`${tKey}COSTS_GIFTS.COST`)}
                        hideTotalVta={true}
                        bold={true}
                        showCost={!!showField('costCol', 'associated_costs')}
                      />
                    </div>
                  </div>
                  <Panel className="table-responsive" onToggle={() => {}} expanded={this.state.associated_costsOpen}>
                    <Panel.Collapse>
                      <Table hover responsive>
                        <thead>
                          <tr>
                            <th className='maxW40'></th>
                            {!!showField('associatedCostsCol', 'associated_costs') &&
                            <th className="text-center">{t(`${tKey}ASSOCIATED_COSTS.TITLE_PANEL`)}</th>}
                            {!!showField('costCol', 'associated_costs') &&
                            <th className="text-center">{t(`${tKey}ASSOCIATED_COSTS.COST`)}</th>}
                            {!!showField('providerCol', 'associated_costs') &&
                            <th className="text-center">{t(`${tKey}ASSOCIATED_COSTS.PROVIDER`)}</th>}
                          </tr>
                        </thead>
                        <tbody>
                          {
                            dossierEconomicComponent.associatedCosts &&
                          dossierEconomicComponent.associatedCosts.map((associatedCosts, idx) => (
                            <tr key={idx}>
                              <td></td>
                              {!!showField('associatedCostsCol', 'associated_costs') &&
                              <td>
                                <Field
                                  colSm={12}
                                  id="name"
                                  name="totalAssociatedCosts.name"
                                  component={InputText}
                                  disabled={true}
                                  valueKey="id"
                                  labelKey="value"
                                  placeholder={combos.associatedCostTypeCombo.find((combo) => combo.id === associatedCosts.typeId) ? combos.associatedCostTypeCombo.find((combo) => combo.id === associatedCosts.typeId).value : ''}
                                />
                              </td>}
                              {!!showField('costCol', 'associated_costs') &&
                              <td className="text-center">{formatMoney(associatedCosts.cost)}</td>}
                              {!!showField('providerCol', 'associated_costs') &&
                              <td className="text-center">{associatedCosts.provider}</td>}
                            </tr>
                          ))
                          }
                          {
                            totalAssociatedCosts &&
                          <tr>
                            <td><b>{totalAssociatedCosts.name}</b></td>
                            {!!showField('associatedCostsCol', 'associated_costs') &&
                            <td></td>}
                            {!!showField('costCol', 'associated_costs') &&
                            <td className="text-center"><b>{formatMoney(totalAssociatedCosts.cost)}</b></td>}
                            {!!showField('providerCol', 'associated_costs') &&
                            <td className="text-center"></td>}
                          </tr>
                          }
                        </tbody>
                      </Table>
                    </Panel.Collapse>
                  </Panel>
                </div>
              </Element>
            )}
            {
            /// REACONDITIONING
            }
            {this.state.componentsConfiguration.reaconditioning &&
            this.state.componentsConfiguration.reaconditioning.permission > permissions.hidden &&
            this.props.dossierSubTypeId === dossierSubTypes.venta_vo && (
              <EconomicPlanReaconditioning
                t={t}
                dossierEconomicComponent = {dossierEconomicComponent}
                combos = {combos}
                reaconditioningOpen = { this.state.reaconditioningOpen }
                openOrCloseReaconditioning = {this.openOrCloseReaconditioning.bind(this)}
                totalReaconditioning = {totalReaconditioning}
                fieldsConfiguration = {this.state.fieldsConfiguration}

              ></EconomicPlanReaconditioning>)}
          </Col>
        </Col>
      </Row>
    )
  }
}
export default EconomicPlan
