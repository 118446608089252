import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import {
  openAddCampaignModalSuccess, fetchCampaignList, fetchCampaignGroupList,
  fetchCampaignTypeList, deleteProcessingAgency, recoverProcessingAgency, openEditCampaignDetailsModalSuccess,
  deleteCampaignDetail, recoverCampaignDetail, openEditDocumentalConditionModalSuccess, openCampaingModalCountryDetailSuccess
} from '../../../actions/masters/masters'
import CampaingMaster from '../../../components/masters/campaign/CampaingMaster'

export function mapStateToProps (state) {
  return {
    ...state.campaign,
    combos: {
      campaignGroup: state.combos.campaignGroup,
      campaignClass: state.combos.campaignClass,
      campaignsTypes: state.combos.campaignsTypes
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      recoverCampaignDetail,
      deleteCampaignDetail,
      openEditCampaignDetailsModalSuccess,
      openAddCampaignModalSuccess,
      fetchCampaignList,
      fetchCampaignGroupList,
      fetchCampaignTypeList,
      deleteProcessingAgency,
      recoverProcessingAgency,
      openEditDocumentalConditionModalSuccess,
      openCampaingModalCountryDetailSuccess
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(CampaingMaster))
