import roleActionTypes from '../../constants/actions/admin/roleAdmin'

export function fetchRoleList (filter) {
  return {
    type: roleActionTypes.FETCH_ROLE_ADMIN,
    filter
  }
}

export function fetchRoleListSuccess (roleList, filter) {
  return {
    type: roleActionTypes.FETCH_ROLE_ADMIN_SUCCESS,
    roleList,
    filter
  }
}

export function openEditRoleModal (roleId) {
  return {
    type: roleActionTypes.OPEN_EDIT_ROLE_MODAL,
    roleId
  }
}

export function openEditRoleModalSuccess (role) {
  return {
    type: roleActionTypes.OPEN_EDIT_ROLE_MODAL_SUCCESS,
    role
  }
}

export function openAddRoleModal () {
  return {
    type: roleActionTypes.OPEN_ADD_ROLE_MODAL
  }
}

export function openAddRoleModalSuccess (sectionPermissions) {
  return {
    type: roleActionTypes.OPEN_ADD_ROLE_MODAL_SUCCESS,
    sectionPermissions
  }
}

export function closeEditRoleModal () {
  return {
    type: roleActionTypes.CLOSE_EDIT_ROLE_MODAL
  }
}

export function submitRole (body, roleId, filter) {
  return {
    type: roleActionTypes.SUBMIT_ROLE,
    body,
    roleId,
    filter
  }
}

export function deleteRole (roleId, filter) {
  return {
    type: roleActionTypes.DELETE_ROLE,
    roleId,
    filter
  }
}

export function cloneRole (roleId, filter) {
  return {
    type: roleActionTypes.CLONE_ROLE,
    roleId,
    filter
  }
}
