import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { change } from 'redux-form'
import { closeDocumentTypeUseMasterModal, submitDocumentTypeUse, setDocumentDocumentOnlyTemplateUpload } from '../../../actions/masters/documentTypeUse/documentTypeUse'
import DocumentTypeUseMasterModal from '../../../components/masters/documentTypeUse/DocumentTypeUseMasterModal'

export function mapStateToProps (state) {
  const documentTypeUseModal = state.documentTypeUse.documentTypeUseModal
  return {
    showModal: documentTypeUseModal.showModal,
    documentTypeUse: documentTypeUseModal.documentTypeUse,
    languageList: documentTypeUseModal.languageList,
    canBePrintedDisabled: documentTypeUseModal.onlyTemplateUpload,
    combos: {
      documentType: state.combos.documentTypes,
      permissionCombo: state.combos.permissionCombo,
      documentTypeUseCombo: state.combos.documentTypeUseCombo
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      submitDocumentTypeUse,
      closeDocumentTypeUseMasterModal,
      setDocumentDocumentOnlyTemplateUpload,
      change
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(DocumentTypeUseMasterModal))
