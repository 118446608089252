import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { Col, FormGroup, ControlLabel } from 'react-bootstrap'
import DatePicker from 'react-16-bootstrap-date-picker'

class InputDatePicker extends PureComponent {
  handleChange (value, formattedValue) {
    this.props.input.onChange(formattedValue)
    if (this.props.onInputChange) {
      this.props.onInputChange(formattedValue)
    }
  }

  formattedInputValue (inputValue) {
    if (inputValue) {
      if (!inputValue.match(/[0-3][0-9]\/[0-1][0-9]\/[0-9][0-9][0-9][0-9]/)) {
        return null
      }
      const [dd, mm, yyyy] = inputValue.split('/')
      return yyyy ? `${yyyy}-${mm}-${dd}T10:00:00.000Z` : null
    }
    return null
  }

  handleBlur (event) {
    this.props.input.onBlur(event)
    if (this.props.onInputBlur) {
      this.props.onInputBlur(event)
    }
  }

  render () {
    const {
      id,
      t,
      colSm = 3,
      meta,
      showError = false,
      input,
      rightIcon = '',
      leftIcon = '',
      controlLabel,
      customClass = '',
      forceControlLabelInOneLine = false,
      disabled = false,
      paddingLeft,
      minDate = '0001-01-01T12:00:00.000Z',
      maxDate = '9999-01-01T12:00:00.000Z',
      placement = 'bottom',
      showTodayButton = true,
      defaultValue = '',
      showClearButton = true
    } = this.props
    const errorMessage = ((showError || meta.touched) && meta.error && typeof meta.error !== 'boolean')
      ? meta.error
      : null
    const tKey = 'GLOBAL.FORMS.INPUT_DATE_PICKER.'
    const tKeyDays = tKey + 'DAYS_OF_THE_WEEK.'
    const tKeyMonths = tKey + 'MONTHS.'
    return (
      <Col sm={colSm} className={customClass} style={paddingLeft}>
        <FormGroup
          className={rightIcon ? 'input-with-icon' : ''}
          validationState={(showError || meta.touched) && meta.error ? 'error' : null}>
          {controlLabel && <ControlLabel className={forceControlLabelInOneLine ? 'one-line ellipsis' : ''}>{controlLabel}</ControlLabel>}
          <DatePicker
            id = {id}
            value={this.formattedInputValue(input.value)}
            onChange={this.handleChange.bind(this)}
            // onBlur={this.handleBlur.bind(this)}
            disabled={disabled}
            placeholder="dd/mm/aaaa"
            dateFormat="DD/MM/YYYY"
            dayLabels={[
              t(tKeyDays + 'SUNDAY'), t(tKeyDays + 'MONDAY'), t(tKeyDays + 'TUESDAY'), t(tKeyDays + 'WEDNESDAY'),
              t(tKeyDays + 'THURSDAY'), t(tKeyDays + 'FRIDAY'), t(tKeyDays + 'SATURDAY')
            ]}
            monthLabels={[
              t(tKeyMonths + 'JANUARY'), t(tKeyMonths + 'FEBRUARY'), t(tKeyMonths + 'MARCH'), t(tKeyMonths + 'APRIL'),
              t(tKeyMonths + 'MAY'), t(tKeyMonths + 'JUNE'), t(tKeyMonths + 'JULY'), t(tKeyMonths + 'AUGUST'),
              t(tKeyMonths + 'SEPTEMBER'), t(tKeyMonths + 'OCTOBER'), t(tKeyMonths + 'NOVEMBER'), t(tKeyMonths + 'DECEMBER')
            ]}
            todayButtonLabel={t(tKey + 'TODAY')}
            weekStartsOn={1}
            showTodayButton={showTodayButton}
            minDate={minDate}
            maxDate={maxDate}
            calendarPlacement={placement}
            defaultValue ={ defaultValue }
            showClearButton ={showClearButton }
          />
          <i className={rightIcon || leftIcon} />
          {errorMessage && <span className='help-block text-center'>{errorMessage}</span>}
        </FormGroup>
      </Col>
    )
  }
}

export default connect(null, null)(translate()(InputDatePicker))
