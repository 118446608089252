import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Sidebar_v2 from '../../components/layout/Sidebar'
import { translate } from 'react-polyglot'
import { setCurrentSection } from '../../../actions/common'
import { getDocumentsCount } from '../../../actions/sign/sign'
import { resetFilters } from '../../../actions/search/search'

export function mapStateToProps (state) {
  return {
    ...state.auth,
    stateSignCount: state.sign.count
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      setCurrentSection,
      getDocumentsCount,
      resetFilters
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(Sidebar_v2))
