import React, { PureComponent } from 'react'
import { reduxForm, Field } from 'redux-form'
import { Row, Col, Table, Button, OverlayTrigger, Glyphicon, Tooltip } from 'react-bootstrap'
import IDocCarPagination from '../../commons/iDocCarPagination'
import InputText from '../../commons/form/InputText'
import InputTreeSelect from '../../commons/form/InputTreeSelect'
import FaqSectionMasterModalPage from '../../../containers/masters/FaqSection/FaqSectionMasterModalPage'

class FaqSectionMaster extends PureComponent {
  constructor () {
    super()
    this.state = {
      filter: {
        searchUO: null,
        searchTitle: null
      }
    }
  }

  getSortIcon (column = '') {
    switch (this.props.filter.orderBy) {
      case '+' + column:
        return 'ico-sort-selected-asc'
      case '-' + column:
        return 'ico-sort-selected-desc'
      default:
        return 'ico-sort'
    }
  }

  changeOrder (field) {
    switch (this.props.filter.orderBy) {
      case `+${field}`:
        this.props.actions.fetchFaqSectionTable({ ...this.state.filter, ...this.props.filter, orderBy: `-${field}` })
        break
      default:
        this.props.actions.fetchFaqSectionTable({ ...this.state.filter, ...this.props.filter, orderBy: `+${field}` })
        break
    }
  }

  handleOrganizedUnitChange (filterUO) {
    if (!filterUO || filterUO.length === 0) {
      this.setState({ filter: { ...this.state.filter, searchUO: [] } })
    } else {
      const searchUoList = filterUO.split(',').map((item) => {
        return parseInt(item)
      })
      if (this.state.filter.searchUO !== searchUoList) {
        this.setState({ filter: { ...this.state.filter, searchUO: searchUoList } })
      }
    }
  }

  handleTitleChange (filterText) {
    if (this.state.filter.searchTitle !== filterText) {
      this.setState({ filter: { ...this.state.filter, searchTitle: filterText } })
    }
  }

  btnSearchEventHandler () {
    const filter = { ...this.props.filter, ...this.state.filter, page: 1 }
    this.props.actions.fetchFaqSectionTable(filter)
  }

  resetFilters () {
    this.setState({
      filter: {
        searchUO: null,
        searchTitle: null
      }
    }, () => { this.btnSearchEventHandler() })
  }

  render () {
    const {
      t, faqSections, pagesCount, sectionsCount, filter,
      filter: { page },
      combos: { UOTreeCombo },
      actions: { deleteFaqSection, fetchFaqSection, recoverFaqSection, fetchFaqSectionTable }
    } = this.props

    const tKey = 'MASTERS'
    const tFaqSection = 'FAQSECTION'
    const editTooltip = (<Tooltip id="editTooltip">{t(`${tKey}.EDIT`)}</Tooltip>)
    const deleteTooltip = (<Tooltip id="deleteTooltip">{t(`${tKey}.DELETE`)}</Tooltip>)
    const recoverTooltip = (<Tooltip id="recoverTooltip">{t(`${tKey}.RECOVER`)}</Tooltip>)
    const hasFilters = (this.state.filter.searchTitle || this.state.filter.searchUO)

    return (
      <div className="admin-wrapper">
        <FaqSectionMasterModalPage/>
        <Row className="section-users-header">
          <Col sm={12}>
            <div className="header-panel">
              <h4>{t(`${tKey}.FAQSECTIONS`)}</h4>
            </div>
          </Col>
        </Row>
        <Row>
          <div className="search-form">
            <span className="title-search"><i className="ico-search" />
              {t(`${tKey}.${tFaqSection}.SEARCH`)}</span>
            <Row>
              <Field
                name="filters.organizedUnitCombo"
                controlLabel={t(`${tKey}.${tFaqSection}.ORGANIZED_UNIT`)}
                placeholder={t(`${tKey}.${tFaqSection}.ORGANIZED_UNIT`)}
                component={InputTreeSelect}
                multi={true}
                valueKey="value"
                labelKey="label"
                childrenKey="children"
                options={UOTreeCombo}
                colSm={3}
                onInputChange={(value) => this.handleOrganizedUnitChange(value)}
                showSearch={false}
              />
              <Field
                name="filters.title"
                colSm={2}
                controlLabel={t(`${tKey}.${tFaqSection}.TITLE_FIELD`)}
                placeholder={t(`${tKey}.${tFaqSection}.TITLE_FIELD`)}
                component={InputText}
                onInputChange={(value) => this.handleTitleChange(value)}
              />
              <Col sm={7} className="btn-wrapper">
                <Button
                  className={'btn-white btn-icon btn-input btn-white search-button'}
                  style={{ width: '100%' }}
                  onClick={() => this.btnSearchEventHandler()}>
                  <i className="ico-search" />
                  {t(`${tKey}.SEARCH_BTN`)}
                </Button>
                <Button
                  className={'btn-icon btn-input ' + (hasFilters ? 'btn-standard' : 'btn-cancel')}
                  onClick={() => {
                    this.props.initialize({})
                    this.resetFilters()
                  }}
                >
                  <i className="ico-cancel" />
                  {t(`${tKey}.RESET_BUTTON`)}
                </Button>
              </Col>
            </Row>
          </div>
        </Row>
        <Col sm={12} className="col-row  section-users-body">
          <Table className="editable-table" hover responsive>
            <thead>
              <tr>
                <th onClick={() => { this.changeOrder('organizedUnitName') }}>
                  {t(`${tKey}.${tFaqSection}.ORGANIZED_UNIT`)} <i className={this.getSortIcon('organizedUnitName')} />
                </th>
                <th onClick={() => { this.changeOrder('title') }}>
                  {t(`${tKey}.${tFaqSection}.TITLE_FIELD`)} <i className={this.getSortIcon('title')} />
                </th>
                <th onClick={() => { this.changeOrder('description') }}>
                  {t(`${tKey}.${tFaqSection}.SECTION_FIELD`)} <i className={this.getSortIcon('description')} />
                </th>
                <th style={{ width: '77px' }}>
                  {t('DOSSIER_COMPONENTS.ACCESSORIES.ACTIONS')}
                </th>
              </tr>
            </thead>
            <tbody>
              {faqSections && faqSections.map((faqSection, idx) => (
                <tr key={idx} className={faqSection.disabled ? 'is-deleted' : '' }>
                  <td>{faqSection.organizedUnitName}</td>
                  <td>{faqSection.title}</td>
                  <td>{faqSection.description}</td>
                  <td>
                    {!faqSection.disabled ? (
                      <div>
                        <a onClick={() => fetchFaqSection(faqSection.faqSectionId)}>
                          <OverlayTrigger placement="left" overlay={editTooltip}>
                            <i className="ico-edit-white" />
                          </OverlayTrigger>
                        </a>
                        <a onClick={() => deleteFaqSection(faqSection.faqSectionId)}>
                          <OverlayTrigger placement="left" overlay={deleteTooltip}>
                            <i className="ico-trash" />
                          </OverlayTrigger>
                        </a>
                      </div>
                    ) : (
                      <a onClick={() => recoverFaqSection(faqSection.faqSectionId)}>
                        <OverlayTrigger placement="left" overlay={recoverTooltip}>
                          <Glyphicon glyph="repeat" />
                        </OverlayTrigger>
                      </a>
                    )}
                  </td>
                </tr>
              ))}
              {(faqSections === null || faqSections === undefined || faqSections.length === 0) &&
            <tr>
              <td colSpan={4}>
                <h2>{t(`${tKey}.${tFaqSection}.RESULTS_NOT_FOUND`)}</h2>
              </td>
            </tr>}
              <tr className="button-table-wrapper">
                <td>
                  <Button className="btn-editable-table btn-standard" onClick={() => fetchFaqSection()}>
                        + {t('MASTERS.ADD')}
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>
          <div className="section-faqsectionmaster-footer">
            <nav key={1} className="text-center">
              <IDocCarPagination id="btn_pag_faqsectionmaster" page={page} pagesCount={pagesCount} totalRows={sectionsCount}
                onSelectAction={(value) => fetchFaqSectionTable({ ...filter, ...this.state.filter, page: value })}
              />
            </nav>
          </div>
        </Col>
      </div>)
  }
}

export default reduxForm({
  form: 'listFaqSectionMaster'
})(FaqSectionMaster)
