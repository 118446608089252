import { connect } from 'react-redux'
import Redirecting from '../components/Redirection'
import authActions from '../actions/auth'
import { bindActionCreators } from 'redux'

export function mapStateToProps (state) {
  return {
    ...state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators(authActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Redirecting)
