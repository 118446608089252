import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { stopAsyncValidation, getFormValues } from 'redux-form'

import CreateNewFleetModal from '../../components/management/CreateNewFleetModal'
import { translate } from 'react-polyglot'
import { createUpdateFleet, changeEntityDossierFromFleet, resetFiltersFleetInfo, fetchFleetsInfo, fetchFleetsEntity, closeCreateEditFleetModal, deleteDossiersFromFleet, fetchPaymentCombo } from '../../actions/management/management'
import { openModal } from '../../actions/common'
import { openFleetCreatedSelectChargeModal, openAddDossiersToFleetModal, openChargeDossiersModal } from '../../actions/management/auxModals/auxModals'
import { clearEntity, clearEntityChild } from '../../actions/dossier/common/client'
import { fetchVehicleType, fetchOperationType } from '../../actions/combos/combos'
import { validate } from '../../actions/dossier/common/validations'

export function mapStateToProps (state) {
  return {
    ...state.management.createNewFleetModal,
    fleetFinderFilter: state.management.searchFleetInfo.dynamicFilters,
    dniCif: state.management.dniCif,
    dossierFleetDetails: state.management.dossierFleetDetails,
    formValues: getFormValues('createNewFleetModal')(state)
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      createUpdateFleet,
      openModal,
      changeEntityDossierFromFleet,
      resetFiltersFleetInfo,
      fetchFleetsInfo,
      fetchFleetsEntity,
      closeCreateEditFleetModal,
      openFleetCreatedSelectChargeModal,
      clearEntity,
      clearEntityChild,
      deleteDossiersFromFleet,
      openAddDossiersToFleetModal,
      openChargeDossiersModal,
      fetchVehicleType,
      fetchPaymentCombo,
      fetchOperationType,
      validate,
      stopAsyncValidation
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(CreateNewFleetModal))
