import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import Profile from '../../components/profile/Profile'
import Profile_v2 from '../../_v2/components/profile/Profile'
import profileActions from '../../actions/profile/profile'
import { openErrorMessageModal } from '../../actions/modals/messageModal'
import TrackingContainer from '../../containers/tracking/trackingContainer'
import { getStorageValue } from '../../storage/storage'

export function mapStateToProps (state) {
  return {
    ...state.profile,
    ...state.auth,
    combos: {
      UOTreeCombo: state.combos.UOTreeCombo
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      ...profileActions,
      openErrorMessageModal
    }, dispatch)
  }
}

let version = parseInt(getStorageValue('interfaceVersion'))
var decoratedComponent = null
if (version === 2) {
  decoratedComponent = TrackingContainer(Profile_v2, true)
} else {
  decoratedComponent = TrackingContainer(Profile, true)
}
export default connect(mapStateToProps, mapDispatchToProps)(translate()(decoratedComponent))
