import dashboardActionTypes from '../../constants/actions/dashboard/dashboard'

export function fetchDashboard (organizedUnit, salesmanId) {
  return {
    type: dashboardActionTypes.FETCH_DASHBOARD,
    organizedUnit,
    salesmanId
  }
}

export function fetchFilteredDashboard (organizedUnit, salesmanId) {
  return {
    type: dashboardActionTypes.FETCH_FILTERED_DASHBOARD,
    organizedUnit,
    salesmanId
  }
}

export function fetchDashboardSuccess (data, salesmanId) {
  return {
    type: dashboardActionTypes.FETCH_DASHBOARD_SUCCESS,
    data,
    salesmanId
  }
}
export function openUploadDocumentFleetModal (organizedUnit, brand, isBuyAndSell, code) {
  return {
    type: dashboardActionTypes.OPEN_DOCUMENT_UPLOAD_MODAL,
    organizedUnit,
    brand,
    isBuyAndSell,
    code
  }
}

export function openUploadDocumentFleetModalSuccess (organizedUnit, brand, isBuyAndSell, code) {
  return {
    type: dashboardActionTypes.OPEN_DOCUMENT_UPLOAD_MODAL_SUCCESS,
    organizedUnit,
    brand,
    isBuyAndSell,
    code
  }
}

export function closeUploadDocumentFleetModal () {
  return {
    type: dashboardActionTypes.CLOSE_DOCUMENT_UPLOAD_MODAL
  }
}

export function closeUploadDocumentFleetModalSuccess () {
  return {
    type: dashboardActionTypes.CLOSE_DOCUMENT_UPLOAD_MODAL_SUCCESS
  }
}

export function checkCanCloseDocumentModal (showAdvise, resolve) {
  return {
    type: dashboardActionTypes.CHECK_CAN_CLOSE_UPLOAD_MODAL,
    showAdvise,
    resolve
  }
}

export function uploadDocumentFleet (file, brand, resolve, isBuyAndSell, code) {
  return {
    type: dashboardActionTypes.UPLOAD_DOCUMENT_FLEET,
    file,
    brand,
    resolve,
    isBuyAndSell,
    code
  }
}

export function fetchCreateDossierButton (organizedUnit) {
  return {
    type: dashboardActionTypes.FETCH_CREATE_DOSSIER_BUTTON,
    organizedUnit
  }
}

export function fetchCreateDossierButtonSuccess (canCreateDossier) {
  return {
    type: dashboardActionTypes.FETCH_CREATE_DOSSIER_BUTTON_SUCCESS,
    canCreateDossier
  }
}

export function setFirstLoad () {
  return {
    type: dashboardActionTypes.SET_FIRST_LOAD
  }
}

export function setSalesmanId (salesmanId) {
  return {
    type: dashboardActionTypes.SET_SALESMAN_ID,
    salesmanId
  }
}

export function fetchDashboardButtons () {
  return {
    type: dashboardActionTypes.FETCH_CREATE_BUTTONS
  }
}

export function fetchDashboardButtonsSuccess (data) {
  return {
    type: dashboardActionTypes.FETCH_CREATE_BUTTONS_SUCCESS,
    data
  }
}

export function fetchDossierFavorites () {
  return {
    type: dashboardActionTypes.FETCH_DOSSIER_FAVORITES
  }
}

export function fetchDossierFavoritesSuccess (data) {
  return {
    type: dashboardActionTypes.FETCH_DOSSIER_FAVORITES_SUCCESS,
    data
  }
}

export function fetchDossierLastAccessed () {
  return {
    type: dashboardActionTypes.FETCH_DOSSIER_LAST_ACCESSED
  }
}

export function fetchDossierLastAccessedSuccess (data) {
  return {
    type: dashboardActionTypes.FETCH_DOSSIER_LAST_ACCESSED_SUCCESS,
    data
  }
}

export function fetchTaskFavorites (organizedUnit) {
  return {
    type: dashboardActionTypes.FETCH_TASK_FAVORITES,
    organizedUnit
  }
}

export function fetchTaskFavoritesSuccess (data) {
  return {
    type: dashboardActionTypes.FETCH_TASK_FAVORITES_SUCCESS,
    data
  }
}

export function switchCardTaskFavorite (taskId, favoriteStatus) {
  return {
    type: dashboardActionTypes.SWITCH_CARD_TASK_FAVORITE,
    taskId,
    favoriteStatus
  }
}

export function switchCardDossierFavorite (dossierId, isHistorical, favoriteStatus) {
  return {
    type: dashboardActionTypes.SWITCH_CARD_DOSSIER_FAVORITE,
    dossierId,
    isHistorical,
    favoriteStatus
  }
}

export default {
  fetchDashboard,
  fetchFilteredDashboard,
  fetchDashboardSuccess,
  openUploadDocumentFleetModal,
  openUploadDocumentFleetModalSuccess,
  closeUploadDocumentFleetModal,
  closeUploadDocumentFleetModalSuccess,
  checkCanCloseDocumentModal,
  uploadDocumentFleet,
  fetchCreateDossierButton,
  fetchCreateDossierButtonSuccess,
  setFirstLoad,
  setSalesmanId,
  fetchDashboardButtons,
  fetchDashboardButtonsSuccess,
  fetchDossierFavorites,
  fetchDossierFavoritesSuccess,
  fetchDossierLastAccessed,
  fetchDossierLastAccessedSuccess,
  fetchTaskFavorites,
  fetchTaskFavoritesSuccess,
  switchCardTaskFavorite,
  switchCardDossierFavorite
}
