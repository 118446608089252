import apiFetch from '../apiFetch'

export default function (token, finderParams) {
  return apiFetch({
    endPoint: 'AuditAdmin',
    method: 'POST',
    body: finderParams,
    token: token
  })
}
