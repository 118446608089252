import documentDataCDA from '../../../constants/actions/dossier/common/documentDataCDAModal'

export function initialState () {
  return {
    dossierId: null,
    showModal: false,
    dossierType: null,
    organizedUnitId: null,
    lastAccessDate: null

  }
}

function openCDAModal (state, { dossierId, dossierType, organizedUnitId, lastAccessDate }) {
  return {
    ...state,
    dossierId: dossierId,
    showModal: true,
    dossierType: dossierType,
    organizedUnitId: organizedUnitId,
    lastAccessDate: lastAccessDate
  }
}

function closeCDAModal (state) {
  return {
    ...state,
    dossierId: null,
    showModal: false
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case documentDataCDA.OPEN_CDA_MODAL:
      return openCDAModal(state, action)
    case documentDataCDA.CLOSE_CDA_MODAL:
      return closeCDAModal(state, action)
    default:
      return state
  }
}
