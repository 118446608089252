import { put, call, select, takeEvery } from 'redux-saga/effects'
import { getAuth } from '../../../selectors/access/auth'
import { initialize, reset } from 'redux-form'
import { yesNoModal } from '../../modalsListeners/yesNoModal'
import dossierActionsTypes from '../../../constants/actions/dossier/dossier'
import { entityRequiredFields } from '../../../constants/validatedFields/entity'
import { dossierTypes as dossierTypesConstant } from '../../../constants/dossier/common/dossierTypes'
import { fetchRegistrationDeliveryDossierSuccess } from '../../../actions/dossier/registrationDelivery'
import messageModalActions from '../../../actions/modals/messageModal'
import getEntityFilterSubType from '../../../services/entity/getEntityFilterSubType'
import putDossierRegistrationDeliveryervice from '../../../services/dossierregistrationDelivery/putDossierRegistrationDelivery'
import { showLoader, hideLoader } from '../../../actions/common'
import { fetchCombosSuccess } from '../../../actions/combos/combos'
import { handleError } from '../../errors/errorManager'
import { compareObjects } from '../../../util/utils'
import { initializeDossier } from '../commonDossierSagas'

export function * saveRegistrationDeliveryDossier ({ registrationDeliveryDossier, resolve, reject }) {
  try {
    const auth = yield select(getAuth)
    const registrationDeliveryDossierState = yield select(state => state.registrationDeliveryDossier)
    const myEntityRequiredFields = entityRequiredFields(registrationDeliveryDossier.entityComponent ? registrationDeliveryDossier.entityComponent.entityTypeId : null)
    let willBeAnyImportantFieldUpdated = false
    let confirmedSaveWarning = true

    for (const prop in myEntityRequiredFields) {
      if (registrationDeliveryDossierState.entityComponent.entityId && registrationDeliveryDossier.entityComponent[prop] !== registrationDeliveryDossierState.entityComponent[prop]) {
        willBeAnyImportantFieldUpdated = true
        break
      }
    }

    if (registrationDeliveryDossierState.dossierContactComponent !== registrationDeliveryDossier.dossierContactComponent) {
      registrationDeliveryDossier.dossierContactComponent.updated = true
    }
    if (willBeAnyImportantFieldUpdated || (registrationDeliveryDossierState.entityComponent && registrationDeliveryDossierState.entityComponent.previousEntity && registrationDeliveryDossierState.entityComponent.previousEntity.entityId &&
        registrationDeliveryDossier.entityComponent && registrationDeliveryDossier.entityComponent.entityId && registrationDeliveryDossier.entityComponent.entityId !== registrationDeliveryDossierState.entityComponent.previousEntity.entityId)) {
      confirmedSaveWarning = yield call(yesNoModal, 'commonEntitySaveWarning')
    }

    if (!compareObjects(registrationDeliveryDossierState.dossierCompleteOtherDataComponent || {}, registrationDeliveryDossier.dossierCompleteOtherDataComponent, true)) {
      registrationDeliveryDossier.dossierCompleteOtherDataComponent.updated = true
    }

    if (confirmedSaveWarning) {
      yield put(showLoader())
      const subDossier = {
        dossierSaleComponent: registrationDeliveryDossier.salesComponent,
        dossierEntityComponent: registrationDeliveryDossier.entityComponent,
        dossierEntityChildComponent: registrationDeliveryDossier.entityChildComponent,
        dossierContactComponent: registrationDeliveryDossier.dossierContactComponent,
        dossierParentId: registrationDeliveryDossier.vehicleComponent.dossierId ? registrationDeliveryDossier.vehicleComponent.dossierId : registrationDeliveryDossier.purchasesDossierId,
        parentIsHistorical: registrationDeliveryDossierState.parentIsHistorical,
        dossierVehicleComponent: registrationDeliveryDossier.vehicleComponent,
        dossierDeliveryComponent: registrationDeliveryDossier.dossierCompleteDeliveryComponent.dossierDeliveryComponent,
        dossierCompleteOtherDataComponent: registrationDeliveryDossier.dossierCompleteOtherDataComponent,
        customFields: registrationDeliveryDossier?.customFields ? Object.keys(registrationDeliveryDossier.customFields).map(x => { return { key: x, value: registrationDeliveryDossier.customFields[x] } }) : []
      }

      if (registrationDeliveryDossier?.customFields) {
        registrationDeliveryDossierState.customFields = {}
        Object.keys(registrationDeliveryDossier.customFields).map(x => {
          registrationDeliveryDossierState.customFields[x] = registrationDeliveryDossier.customFields[x]
        })
      }

      yield call(putDossierRegistrationDeliveryervice, registrationDeliveryDossier.dossierId, subDossier, auth.token, registrationDeliveryDossierState.lastAccessDate)
      yield put(messageModalActions.openSuccessMessageModal('MESSAGES.PUT_DOSSIER_SUCCESS'))
      yield call(initializeDossier, dossierTypesConstant.registrationDelivery, registrationDeliveryDossierState)
    } else {
      if (registrationDeliveryDossierState.entityComponent.previousEntity.entitySubTypeId) {
        const entityFilterSubTypeCombo = yield call(getEntityFilterSubType, auth.token, registrationDeliveryDossierState.entityComponent.previousEntity.entityTypeId, registrationDeliveryDossierState.entityComponent.previousEntity.entitySubTypeId, registrationDeliveryDossierState.organizedUnitId)
        yield put(fetchCombosSuccess({ entityFilterSubTypeCombo }))
      }

      if (registrationDeliveryDossier?.customFields) {
        registrationDeliveryDossierState.customFields = {}
        Object.keys(registrationDeliveryDossier.customFields).map(x => {
          registrationDeliveryDossierState.customFields[x] = registrationDeliveryDossier.customFields[x]
        })
      }
      yield put(fetchRegistrationDeliveryDossierSuccess({ entityComponent: { ...registrationDeliveryDossierState.entityComponent.previousEntity, previousEntity: registrationDeliveryDossierState.entityComponent.previousEntity } }))
      yield put(initialize('registrationDeliveryDossier', { ...registrationDeliveryDossier, entityComponent: registrationDeliveryDossierState.entityComponent.previousEntity, customFields: registrationDeliveryDossierState.customFields }))
    }
    if (resolve) yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
      if (reject) yield call(reject, customHandlerError)
    }
    const sessionHandler = function * () {
      yield put(reset('registrationDeliveryDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSaveRegistrationDeliveryDossier () {
  yield takeEvery(dossierActionsTypes.SAVE_REGISTRATION_DELIVERY_DOSSIER, saveRegistrationDeliveryDossier)
}
