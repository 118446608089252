import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import AddUsers from '../../../components/masters/repairshop/AddUsers'
import { fetchUserListForProcessingAgency } from '../../../actions/masters/masters'
import { openRepairshopUsersModal, closeRepairshopMasterModal, assingUserRepairshop } from '../../../actions/masters/repairshop/repairshop'

export function mapStateToProps (state) {
  return {
    repairshopModal: state.repairshop.repairshopModal,
    ...state.repairshop,
    userList: state.repairshop.userList,
    repairshop: state.repairshop.repairshop
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchUserListForProcessingAgency,
      assingUserRepairshop,
      closeRepairshopMasterModal,
      openRepairshopUsersModal
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(AddUsers))
