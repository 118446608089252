export const saleCodes = {
  bsale: 'bsale',
  currency: 'currency',
  delivery: 'delivery',
  fleetCode: 'fleetCode'
}

export const commonCodes = {
  main: 'main',
  admin: 'admin'
}

export const economicCodes = {
  campaign: 'campaign'
}

export const paperworkCodes = {
  bpaperwork: 'bpaperwork'
}

export const recurrentEntityTabCode = {
  recEntTabCode: 'recEntTab'
}

export const rfcFieldCode = {
  rfc: 'rfc',
  startStreet: 'startStreet',
  enndStreet: 'enndStreet',
  externalNumber: 'externalNumber',
  internalNumber: 'internalNumber',
  countryCode: 'countryCode',
  state: 'state',
  city: 'city',
  colonyCode: 'colonyCode',
  townCode: 'townCode',
  cp: 'cp'
}

export const errorSelfSupplyErrorCode = {
  purchaseAlreadyExists: 'purchaseAlreadyExists'
}

export const commercialSocietyTypeIdFieldCode = {
  commercialSocietyTypeId: 'commercialSocietyTypeId'
}

export const activityBproFields = {
  activityBproId: 'activityBproId',
  birthdayDate: 'birthdayDate',
  constitutionAct: 'constitutionAct',
  constitutionDate: 'constitutionDate'
}
