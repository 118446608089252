import { put, call, select, takeEvery } from 'redux-saga/effects'
import { initialize, reset, change } from 'redux-form'
import dossierActionsTypes from '../../../constants/actions/dossier/dossier'
import { dossierTypes as dossierTypesConstant } from '../../../constants/dossier/common/dossierTypes'
import { showLoader, hideLoader } from '../../../actions/common'
import messageModalActions from '../../../actions/modals/messageModal'
import { yesNoModal } from '../../modalsListeners/yesNoModal'
import { fetchPurchaseDossier, fetchDependantsPurchaseDossier } from './fetchPurchaseDossier'
import { getAuth } from '../../../selectors/access/auth'
import putDossierPurchaseReject from '../../../services/dossierPurchase/put/putDossierPurchaseReject'
import putDossierPurchaseSendToValidation from '../../../services/dossierPurchase/put/putDossierPurchaseSendToValidation'
import putDossierPurchaseApprove from '../../../services/dossierPurchase/put/putDossierPurchaseApprove'
import putDossierPurchaseApproveManager from '../../../services/dossierPurchase/put/putDossierPurchaseApproveManager'
import putDossierPurchaseApproveSalesManagerVo from '../../../services/dossierPurchase/put/putDossierPurchaseApproveSalesManagerVo'
import putDossierTransferRequestService from '../../../services/dossier/put/putDossierTransferRequest'
import putDossierTransferRequestApprovedService from '../../../services/dossier/put/putDossierTransferRequestApproved'
import getPurchaseDoubleTransferCheck from '../../../services/dossierPurchase/get/getPurchaseDoubleTransferCheck'
import { handleError } from '../../errors/errorManager'
import { initializeDossier } from '../commonDossierSagas'
import putDossierCancelTransferRequestService from '../../../services/dossier/put/putDossierCancelTransferRequest'

export function * purchaseReject ({ comment, resolve, reject, pristine }) {
  try {
    if (!pristine) {
      const confirmedSaveWarning = yield call(yesNoModal, 'commonDossierSaveWarning')
      if (confirmedSaveWarning) {
        const auth = yield select(getAuth)
        const purchaseDossierState = yield select(state => state.purchaseDossier)
        const confirmed = yield call(yesNoModal, 'purchaseDossierReject')
        if (confirmed) {
          yield put(showLoader())
          // TODO review with real service
          yield call(putDossierPurchaseReject, purchaseDossierState.dossierId, comment, auth.token, purchaseDossierState.lastAccessDate)
          yield call(fetchPurchaseDossier, { dossierId: purchaseDossierState.dossierId })
          const updatedPurchaseDossier = yield select(state => state.purchaseDossier)
          yield put(initialize('purchase_dossier', updatedPurchaseDossier))
        }
      }
    } else {
      const auth = yield select(getAuth)
      const purchaseDossierState = yield select(state => state.purchaseDossier)
      const confirmed = yield call(yesNoModal, 'purchaseDossierReject')
      if (confirmed) {
        yield put(showLoader())
        // TODO review with real service
        yield call(putDossierPurchaseReject, purchaseDossierState.dossierId, comment, auth.token, purchaseDossierState.lastAccessDate)
        yield call(fetchPurchaseDossier, { dossierId: purchaseDossierState.dossierId })
        const updatedPurchaseDossier = yield select(state => state.purchaseDossier)
        yield put(initialize('purchase_dossier', updatedPurchaseDossier))
      }
    }
    if (resolve) yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          const purchaseDossierState = yield select(state => state.purchaseDossier)
          yield call(initializeDossier, dossierTypesConstant.purchase, purchaseDossierState)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
      if (reject) yield call(reject, customHandlerError)
    }
    const sessionHandler = function * () {
      yield put(reset('purchase_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchPurchaseReject () {
  yield takeEvery(dossierActionsTypes.PURCHASE_REJECT, purchaseReject)
}

export function * purchaseApprove (pristine) {
  try {
    if (!pristine.pristine) {
      const confirmedSaveWarning = yield call(yesNoModal, 'commonDossierSaveWarning')
      if (confirmedSaveWarning) {
        const auth = yield select(getAuth)
        const purchaseDossierState = yield select(state => state.purchaseDossier)
        const confirmed = yield call(yesNoModal, 'purchaseDossierApprove')
        if (confirmed) {
          yield put(showLoader())
          yield call(putDossierPurchaseApprove, purchaseDossierState.dossierId, auth.token, purchaseDossierState.lastAccessDate)
          yield call(fetchPurchaseDossier, { dossierId: purchaseDossierState.dossierId })
          yield call(fetchDependantsPurchaseDossier, { dossierId: purchaseDossierState.dossierId })
          const updatedPurchaseDossier = yield select(state => state.purchaseDossier)
          yield put(initialize('purchase_dossier', updatedPurchaseDossier))
        }
      }
    } else {
      const auth = yield select(getAuth)
      const purchaseDossierState = yield select(state => state.purchaseDossier)
      const confirmed = yield call(yesNoModal, 'purchaseDossierApprove')
      if (confirmed) {
        yield put(showLoader())
        yield call(putDossierPurchaseApprove, purchaseDossierState.dossierId, auth.token, purchaseDossierState.lastAccessDate)
        yield call(fetchPurchaseDossier, { dossierId: purchaseDossierState.dossierId })
        yield call(fetchDependantsPurchaseDossier, { dossierId: purchaseDossierState.dossierId })
        const updatedPurchaseDossier = yield select(state => state.purchaseDossier)
        yield put(initialize('purchase_dossier', updatedPurchaseDossier))
      }
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          const purchaseDossierState = yield select(state => state.purchaseDossier)
          yield call(initializeDossier, dossierTypesConstant.purchase, purchaseDossierState)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('purchase_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchPurchaseApprove () {
  yield takeEvery(dossierActionsTypes.PURCHASE_APPROVE, purchaseApprove)
}

export function * purchaseSendApprove (pristine) {
  try {
    if (!pristine.pristine) {
      const confirmedSaveWarning = yield call(yesNoModal, 'commonDossierSaveWarning')
      if (confirmedSaveWarning) {
        const auth = yield select(getAuth)
        const purchaseDossierState = yield select(state => state.purchaseDossier)
        const confirmed = yield call(yesNoModal, 'purchaseDossierSendApprove')
        if (confirmed) {
          yield put(showLoader())
          yield call(putDossierPurchaseSendToValidation, purchaseDossierState.dossierId, auth.token, purchaseDossierState.lastAccessDate)
          yield call(fetchPurchaseDossier, { dossierId: purchaseDossierState.dossierId })
          const updatedPurchaseDossier = yield select(state => state.purchaseDossier)
          yield put(initialize('purchase_dossier', updatedPurchaseDossier))
        }
      }
    } else {
      const auth = yield select(getAuth)
      const purchaseDossierState = yield select(state => state.purchaseDossier)
      const confirmed = yield call(yesNoModal, 'purchaseDossierSendApprove')
      if (confirmed) {
        yield put(showLoader())
        yield call(putDossierPurchaseSendToValidation, purchaseDossierState.dossierId, auth.token, purchaseDossierState.lastAccessDate)
        yield call(fetchPurchaseDossier, { dossierId: purchaseDossierState.dossierId })
        const updatedPurchaseDossier = yield select(state => state.purchaseDossier)
        yield put(initialize('purchase_dossier', updatedPurchaseDossier))
      }
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          const purchaseDossierState = yield select(state => state.purchaseDossier)
          yield call(initializeDossier, dossierTypesConstant.purchase, purchaseDossierState)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('purchase_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchPurchaseSendApprove () {
  yield takeEvery(dossierActionsTypes.PURCHASE_SEND_APPROVE, purchaseSendApprove)
}

export function * purchaseWarningSave ({ reset, router, nextLocation, pristine }) {
  if (!pristine) {
    const confirmedSaveWarning = yield call(yesNoModal, 'commonDossierSaveWarning')
    if (confirmedSaveWarning) {
      const promise = new Promise((resolve, reject) => {
        reset('purchase_dossier')
        resolve()
      })
      promise.then(() => {
        router.replace(nextLocation)
      })
    }
  }
}

export function * watchPurchaseWarningSave () {
  yield takeEvery(dossierActionsTypes.PURCHASE_WARNING_SAVE, purchaseWarningSave)
}

export function * purchaseSynchroniseAvailabilityDate ({ availabilityDate }) {
  try {
    const purchaseDossierForm = yield select(state => state.form.purchase_dossier.values)
    yield put(change('purchase_dossier', 'vehicleComponent', { ...purchaseDossierForm.vehicleComponent, availabilityDate }))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchPurchaseSynchroniseAvailabilityDate () {
  yield takeEvery(dossierActionsTypes.PURCHASE_SYNCHRONISE_AVAILABILITY_DATE, purchaseSynchroniseAvailabilityDate)
}

function * performTransferRequestOperation (service) {
  const auth = yield select(getAuth)
  const purchaseDossierState = yield select(state => state.purchaseDossier)
  let modalToShow = 'purchaseDossierTransferRequest'
  if (purchaseDossierState.dossierPurchaseComponent.doubleTransferCheck) {
    const doubleTransferCheck = yield call(getPurchaseDoubleTransferCheck,
      purchaseDossierState.dossierId,
      auth.token,
      purchaseDossierState.lastAccessDate)
    if (doubleTransferCheck) {
      modalToShow = 'dossierDoubleTransferConfirmation'
    }
  }
  const confirmed = yield call(yesNoModal, modalToShow)
  if (confirmed) {
    yield put(showLoader())
    yield call(service, purchaseDossierState.dossierId, auth.token, purchaseDossierState.lastAccessDate)
    yield call(fetchPurchaseDossier, { dossierId: purchaseDossierState.dossierId })
    const updatedPurchaseDossier = yield select(state => state.purchaseDossier)
    yield put(initialize('purchase_dossier', updatedPurchaseDossier))
  }
}

export function * purchaseTransferRequest (pristine) {
  try {
    if (!pristine.pristine) {
      const confirmedSaveWarning = yield call(yesNoModal, 'commonDossierSaveWarning')
      if (confirmedSaveWarning) {
        yield call(performTransferRequestOperation, putDossierTransferRequestService)
      }
    } else {
      yield call(performTransferRequestOperation, putDossierTransferRequestService)
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          const purchaseDossierState = yield select(state => state.purchaseDossier)
          yield call(initializeDossier, dossierTypesConstant.purchase, purchaseDossierState)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('purchase_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchPurchaseTransferRequest () {
  yield takeEvery(dossierActionsTypes.PURCHASE_TRANSFER_REQUEST, purchaseTransferRequest)
}

export function * purchaseTransferRequestApproved (pristine) {
  try {
    if (!pristine.pristine) {
      const confirmedSaveWarning = yield call(yesNoModal, 'commonDossierSaveWarning')
      if (confirmedSaveWarning) {
        yield call(performTransferRequestOperation, putDossierTransferRequestApprovedService)
      }
    } else {
      yield call(performTransferRequestOperation, putDossierTransferRequestApprovedService)
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          const purchaseDossierState = yield select(state => state.purchaseDossier)
          yield call(initializeDossier, dossierTypesConstant.purchase, purchaseDossierState)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('purchase_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchPurchaseTransferRequestApproved () {
  yield takeEvery(dossierActionsTypes.PURCHASE_TRANSFER_REQUEST_APPROVED, purchaseTransferRequestApproved)
}

export function * purchaseApproveManager (pristine) {
  try {
    if (!pristine.pristine) {
      const confirmedSaveWarning = yield call(yesNoModal, 'commonDossierSaveWarning')
      if (confirmedSaveWarning) {
        const auth = yield select(getAuth)
        const purchaseDossierState = yield select(state => state.purchaseDossier)
        const confirmed = yield call(yesNoModal, 'purchaseApproveManager')
        if (confirmed) {
          yield put(showLoader())
          yield call(putDossierPurchaseApproveManager, purchaseDossierState.dossierId, auth.token, purchaseDossierState.lastAccessDate)
          yield call(fetchPurchaseDossier, { dossierId: purchaseDossierState.dossierId })
          yield call(fetchDependantsPurchaseDossier, { dossierId: purchaseDossierState.dossierId })
          const updatedPurchaseDossier = yield select(state => state.purchaseDossier)
          yield put(initialize('purchase_dossier', updatedPurchaseDossier))
        }
      }
    } else {
      const auth = yield select(getAuth)
      const purchaseDossierState = yield select(state => state.purchaseDossier)
      const confirmed = yield call(yesNoModal, 'purchaseApproveManager')
      if (confirmed) {
        yield put(showLoader())
        yield call(putDossierPurchaseApproveManager, purchaseDossierState.dossierId, auth.token, purchaseDossierState.lastAccessDate)
        yield call(fetchPurchaseDossier, { dossierId: purchaseDossierState.dossierId })
        yield call(fetchDependantsPurchaseDossier, { dossierId: purchaseDossierState.dossierId })
        const updatedPurchaseDossier = yield select(state => state.purchaseDossier)
        yield put(initialize('purchase_dossier', updatedPurchaseDossier))
      }
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          const purchaseDossierState = yield select(state => state.purchaseDossier)
          yield call(initializeDossier, dossierTypesConstant.purchase, purchaseDossierState)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('purchase_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchPurchaseApproveManager () {
  yield takeEvery(dossierActionsTypes.PURCHASE_APPROVE_MANAGER_VO, purchaseApproveManager)
}

export function * purchaseApproveSalesManagerVo (pristine) {
  try {
    if (!pristine.pristine) {
      const confirmedSaveWarning = yield call(yesNoModal, 'commonDossierSaveWarning')
      if (confirmedSaveWarning) {
        const auth = yield select(getAuth)
        const purchaseDossierState = yield select(state => state.purchaseDossier)
        const confirmed = yield call(yesNoModal, 'purchaseApproveSalesManagerVo')
        if (confirmed) {
          yield put(showLoader())
          yield call(putDossierPurchaseApproveSalesManagerVo, purchaseDossierState.dossierId, auth.token, purchaseDossierState.lastAccessDate)
          yield call(fetchPurchaseDossier, { dossierId: purchaseDossierState.dossierId })
          yield call(fetchDependantsPurchaseDossier, { dossierId: purchaseDossierState.dossierId })
          const updatedPurchaseDossier = yield select(state => state.purchaseDossier)
          yield put(initialize('purchase_dossier', updatedPurchaseDossier))
        }
      }
    } else {
      const auth = yield select(getAuth)
      const purchaseDossierState = yield select(state => state.purchaseDossier)
      const confirmed = yield call(yesNoModal, 'purchaseApproveSalesManagerVo')
      if (confirmed) {
        yield put(showLoader())
        yield call(putDossierPurchaseApproveSalesManagerVo, purchaseDossierState.dossierId, auth.token, purchaseDossierState.lastAccessDate)
        yield call(fetchPurchaseDossier, { dossierId: purchaseDossierState.dossierId })
        yield call(fetchDependantsPurchaseDossier, { dossierId: purchaseDossierState.dossierId })
        const updatedPurchaseDossier = yield select(state => state.purchaseDossier)
        yield put(initialize('purchase_dossier', updatedPurchaseDossier))
      }
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          const purchaseDossierState = yield select(state => state.purchaseDossier)
          yield call(initializeDossier, dossierTypesConstant.purchase, purchaseDossierState)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('purchase_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchPurchaseApproveSalesManagerVo () {
  yield takeEvery(dossierActionsTypes.PURCHASE_APPROVE_SALES_MANGER_VO, purchaseApproveSalesManagerVo)
}

export function * purchaseTransferReject ({ comment, resolve, reject, pristine }) {
  try {
    if (!pristine) {
      const confirmedSaveWarning = yield call(yesNoModal, 'commonDossierSaveWarning')
      if (confirmedSaveWarning) {
        const auth = yield select(getAuth)
        const purchaseDossierState = yield select(state => state.purchaseDossier)
        yield call(putDossierCancelTransferRequestService, purchaseDossierState.dossierId, auth.token, purchaseDossierState.lastAccessDate, comment)
        yield call(fetchPurchaseDossier, { dossierId: purchaseDossierState.dossierId })
        const updatedPurchaseDossier = yield select(state => state.purchaseDossier)
        yield put(initialize('purchase_dossier', updatedPurchaseDossier))
      }
    } else {
      const auth = yield select(getAuth)
      const purchaseDossierState = yield select(state => state.purchaseDossier)
      yield call(putDossierCancelTransferRequestService, purchaseDossierState.dossierId, auth.token, purchaseDossierState.lastAccessDate, comment)
      yield call(fetchPurchaseDossier, { dossierId: purchaseDossierState.dossierId })
      const updatedPurchaseDossier = yield select(state => state.purchaseDossier)
      yield put(initialize('purchase_dossier', updatedPurchaseDossier))
    }
    if (resolve) yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          const purchaseDossierState = yield select(state => state.purchaseDossier)
          yield call(initializeDossier, dossierTypesConstant.purchase, purchaseDossierState)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
      if (reject) yield call(reject, customHandlerError)
    }
    const sessionHandler = function * () {
      yield put(reset('purchase_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchPurchaseTransferReject () {
  yield takeEvery(dossierActionsTypes.PURCHASE_TRANSFER_REJECT, purchaseTransferReject)
}
