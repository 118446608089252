import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { closeCampaignTypeMasterModal, submitCampaignType } from '../../../actions/masters/masters'
import CampaingTypeMasterModal from '../../../components/masters/campaignType/CampaingTypeMasterModal'

export function mapStateToProps (state) {
  const campaignTypeModal = state.campaignType.campaignTypeModal
  return {
    showModal: campaignTypeModal.showModal,
    campaignId: campaignTypeModal.campaignId,
    languageList: campaignTypeModal.languageList,
    nonEditableType: campaignTypeModal.nonEditableType,
    nonEditable: campaignTypeModal.nonEditable,
    combos: {
      campaignGroup: state.combos.campaignGroup
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      submitCampaignType,
      closeCampaignTypeMasterModal
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(CampaingTypeMasterModal))
