import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { fetchRepairshopUsersList, fetchRepairshopList, openRepairshopUsersModal, closeRepairshopMasterModal, submitRepairshop, deleteUserRepairshop } from '../../../actions/masters/repairshop/repairshop'
import RepairshopUsersAddModal from '../../../components/masters/repairshop/RepairshopUsersAddModal'

export function mapStateToProps (state) {
  return {
    ...state.repairshop.repairshopModal,
    repairshop: state.repairshop,
    repairshopId: state.repairshopId
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      submitRepairshop,
      closeRepairshopMasterModal,
      openRepairshopUsersModal,
      fetchRepairshopUsersList,
      fetchRepairshopList,
      deleteUserRepairshop
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(RepairshopUsersAddModal))
