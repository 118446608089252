import React, { PureComponent } from 'react'
import { reduxForm, Field, reset } from 'redux-form'
import { Row, Form, Button, Col, ProgressBar, Tooltip, OverlayTrigger } from 'react-bootstrap'
import InputCheckBox from '../commons/form/InputCheckBox'
import { dossierTypesId } from '../../constants/dossier/common/dossierTypes'
import InputSelect from '../commons/form/InputSelect'
import { validateDate, validateDates } from '../../util/validationFunctions'
import InputDatePicker from '../commons/form/InputDatePicker'
import SimpleTablePage from '../../containers/commons/general/SimpleTablePage'
import { urgencyTypesClass } from '../../constants/backendIds'
import IDocCarPagination from '../commons/iDocCarPagination'
import VehiclePopover from '../../components/search/VehiclePopover'
import { Link } from 'react-router-dom'

class SelfSupplyManager extends PureComponent {
  constructor () {
    super()
    this.state = {
      fields: [],
      filters: [],
      dynamicFilters: [],
      disableResetButton: false,
      displayBar: 'none',
      progress: 0,
      progressFail: 0,
      errors: [],
      maxBar: 0,
      stepToVo: true
    }
  }

  componentDidMount () {
    this.props.actions.fetchDossierSubTypes(null, dossierTypesId.sales)
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (this.props.selectedTabManagement !== prevProps.selectedTabManagement) {
      this.handleClickReset()
    }
    return null
  }

  componentDidUpdate () {
    // don't remove use for getsnapshot
  }

  submitSearchForm (values) {
    let filters = values
    if (!filters.stepToVoPending) {
      filters.stepToVoPending = this.state.stepToVo
    }
    if (Object.keys(filters).length > 0) {
      let propFilters = this.props.filters
      propFilters.orderBy = '+number'
      this.props.actions.fetchSelfSupplyInfo({ ...filters, ...propFilters })
      this.setState({ dynamicFilters: filters })
    } else {
      this.props.actions.openModal('mustFillFilters')
    }
  }

  fieldFill (field) {
    this.setState({ fields: { ...this.state.fields, ...field } })
  }

  onDossierSubtypeChange (dossierSubtypeId) {
    this.props.actions.fetchStagesCombo(dossierSubtypeId)
  }

  dateValidation (value, allValues, props) {
    const result = validateDate(value, props)
    return result === Object(result) ? undefined : result
  }

  clearFields () {
    this.setState({ fields: {} })
  }

  handleClickReset (e) {
    if (e !== undefined) {
      e.preventDefault()
    }
    this.clearFields()
    this.props.actions.resetFiltersSelfSupplyInfo()
    this.props.dispatch(reset('selfSupplyManagerForm'))
    this.setState({ stepToVo: true, dynamicFilters: [] })
    this.props.actions.fetchStagesCombo(null)
  }

  exportData () {
    const tKey = 'MANAGEMENT.SELF_SUPPLY_TAB.'
    let columnsExcel = []
    columnsExcel.push(this.props.t(`${tKey}DOSSIER_NUMBER`) + '#Number')
    columnsExcel.push(this.props.t(`${tKey}ORGANIZED_UNIT`) + '#OrganizedUnit')
    columnsExcel.push(this.props.t('SEARCH.CATEGORIES.VEHICLE.BY_VEHICLE') + '#Vehicle')
    columnsExcel.push(this.props.t(`${tKey}STAGE`) + '#Stage')
    columnsExcel.push(this.props.t(`${tKey}CREATION_DATE`) + '#CreationDate')
    columnsExcel.push(this.props.t(`${tKey}LAST_MODIFICATION_DATE`) + '#LastUpdateDate')
    columnsExcel.push(this.props.t(`${tKey}STEP_TO_VO_PENDING`) + '#StepToVoPending')

    let cols = ''
    columnsExcel.forEach(element => {
      cols += element + '$'
    })
    cols = cols.substring(0, cols.length - 1)

    let filters = this.state.dynamicFilters
    this.props.actions.fetchSelfSupplyInfoDownload({ ...filters, ...this.props.filters }, cols)
  }

  onOrderChange (e) {
    const newOrderBy = this.props.filters.orderBy.includes(`+${e.currentTarget.dataset.field}`) ? `-${e.currentTarget.dataset.field}` : `+${e.currentTarget.dataset.field}`
    this.fetchTable({ ...this.props.filters, orderBy: newOrderBy })
  }

  onPageChange (page) {
    this.fetchTable({ ...this.props.filters, page })
  }

  getIconClass (field) {
    if (this.props.filters.orderBy.includes(field)) {
      return this.props.filters.orderBy.includes('-') ? 'ico-sort-selected-asc' : 'ico-sort-selected-desc'
    } else {
      return 'ico-sort'
    }
  }

  fetchTable (propFilters) {
    let filters = this.state.dynamicFilters
    this.props.actions.fetchSelfSupplyInfo({ ...filters, ...propFilters })
    this.setState({ dynamicFilters: filters })
  }

  stepToVo (dossier) {
    this.props.actions.passSelfSupplyVoAction(dossier.dossierId, dossier.organizedUnitId)
  }

  render () {
    const {
      t, handleSubmit, className, hasSearch, count, dossierData, filters: { page, pages },
      combos: {
        lowestOrganizedUnitsCombo, dossierSubTypesCombo, stagesCombo
      }
    } = this.props
    const tKey = 'MANAGEMENT.SELF_SUPPLY_TAB.'
    const stepTooltip = (<Tooltip id="stepTooltip">{t(`${tKey}VO_STEP`)}</Tooltip>)
    return (
      <>
        <Form autoComplete="off" onSubmit={handleSubmit((values) => this.submitSearchForm(values))} className="filters">
          <div>
            <>
              <Row>
                <Field
                  id="organizedUnitId"
                  name='organizedUnitId'
                  placeholder={t(`${tKey}ORGANIZED_UNIT`)}
                  controlLabel={t(`${tKey}ORGANIZED_UNIT`)}
                  component={InputSelect}
                  options={lowestOrganizedUnitsCombo}
                  valueKey="id"
                  labelKey="value"
                  onInputChange={(val) => this.fieldFill({ organizedUnit: val })}
                  customClass={this.state.fields.organizedUnit ? className : ''}
                  multi={true}
                />
                <Field
                  id="dossierSubtype"
                  name='dossierSubtype'
                  placeholder={t(`${tKey}DOSSIER_SUBTYPE`)}
                  controlLabel={t(`${tKey}DOSSIER_SUBTYPE`)}
                  component={InputSelect}
                  options={dossierSubTypesCombo}
                  valueKey="id"
                  labelKey="value"
                  onInputChange={(val) => { this.onDossierSubtypeChange(val); this.fieldFill({ dossierSubtypeId: val }) }}
                  customClass={this.state.fields.dossierSubtypeId ? className : ''}
                />
                <Field
                  id="stages"
                  name='stages'
                  placeholder={t(`${tKey}STAGE`)}
                  controlLabel={t(`${tKey}STAGE`)}
                  component={InputSelect}
                  multi
                  menuContainerStyle={{ zIndex: 999 }}
                  options={stagesCombo}
                  valueKey="id"
                  labelKey="value"
                  disabled={stagesCombo.length === 0}
                  onInputChange={(val) => this.fieldFill({ stage: val })}
                  customClass={this.state.fields.dossierSubType && this.state.fields.stage ? className : ''}
                />
                <Field
                  id="creationDateFrom"
                  name='creationDateFrom'
                  placeholder={t(`${tKey}CREATION_DATE_FROM`)}
                  controlLabel={t(`${tKey}CREATION_DATE_FROM`)}
                  component={InputDatePicker}
                  validate={[this.dateValidation]}
                  onInputChange={(val) => this.fieldFill({ creationDateFrom: val })}
                  customClass={this.state.fields.creationDatefrom ? className : ''}
                />
                <Field
                  id="creationDateTo"
                  name='creationDateTo'
                  placeholder={t(`${tKey}CREATION_DATE_TO`)}
                  controlLabel={t(`${tKey}CREATION_DATE_TO`)}
                  component={InputDatePicker}
                  validate={[this.dateValidation]}
                  onInputChange={(val) => this.fieldFill({ creationDateTo: val })}
                  customClass={this.state.fields.creationDateTo ? className : ''}
                />
                <div key="clearfix_1" className="clearfix" />
                <Field
                  id="stepToVoPending"
                  name="stepToVoPending"
                  placeholder={t(`${tKey}STEP_TO_VO_PENDING`)}
                  component={InputCheckBox}
                  onInputChange={(val) => { this.fieldFill({ stepToVoPending: val }); this.setState({ stepToVo: val }) } }
                  checked = {this.state.stepToVo}
                />
              </Row>
              <Row className='buttons-row'>
                <Col sm={12} className="text-center">
                  <hr/>
                  <Button
                    id="btn_search"
                    type="submit"
                    className="btn-standard"
                  >
                    <i className="ico-search"/>
                    {t('SEARCH.ACTIONS.SEARCH_BUTTON')}
                  </Button>
                  <Button
                    id="btn_reset"
                    onClick={(e) => this.handleClickReset(e)}
                    className={'btn-white btn-icon' + (this.props.pristine && !hasSearch ? ' btn-cancel' : ' btn-standard')}
                    disabled={this.props.pristine && !hasSearch}
                  >
                    <i className="ico-cancel"/> {t('SEARCH.ACTIONS.RESET_BUTTON')}
                  </Button>
                </Col>
              </Row>
              {hasSearch && count > 0 &&
                <Row>
                  <Button
                    onClick={(event) => this.exportData(event)}
                    bsStyle="default"
                    bsSize="small"
                    className="button-search-left">
                    {t('SEARCH.ACTIONS.EXPORT')}
                    <i className="ico-if_Download_1031520"/>
                  </Button>
                </Row>
              }
            </>
            {
              hasSearch && ([
                <div className="table-wrapper" key="table-wrapper">
                  <SimpleTablePage
                    columns={[
                      <th key={0} />,
                      <th key={1} data-field="number" onClick={(event) => this.onOrderChange(event)}>
                        {t(`${tKey}DOSSIER_NUMBER`)}&nbsp;<i className={this.getIconClass('number')}/>
                      </th>,
                      <th key={2} data-field="organizedUnit" onClick={(event) => this.onOrderChange(event)}>
                        {t(`${tKey}ORGANIZED_UNIT`)}&nbsp;<i className={this.getIconClass('organizedUnit')}/>
                      </th>,
                      <th key={4}>{t('SEARCH.CATEGORIES.VEHICLE.BY_VEHICLE')}</th>,
                      <th key={6} data-field="stage" onClick={(event) => this.onOrderChange(event)}>
                        {t(`${tKey}STAGE`)}&nbsp;<i className={this.getIconClass('stage')}/>
                      </th>,
                      <th key={9} data-field="creationDate" onClick={(event) => this.onOrderChange(event)}>
                        {t(`${tKey}CREATION_DATE`)}&nbsp;<i className={this.getIconClass('creationDate')}/>
                      </th>,
                      <th key={5} data-field="lastUpdateDate" onClick={(event) => this.onOrderChange(event)}>
                        {t(`${tKey}LAST_MODIFICATION_DATE`)}&nbsp;<i className={this.getIconClass('lastUpdateDate')}/>
                      </th>,
                      <th key={12} data-field="actions">{t(`${tKey}ACTIONS`)}&nbsp;</th>
                    ]}
                    rows={dossierData && dossierData.map((row, idx) => (
                      <tr key={idx}>
                        <td>
                          <span className={`color-point ${urgencyTypesClass[row.urgencyType] || 'grey'}`}/>
                        </td>
                        <td>
                          <Link to={`/dossier${row.urlToNavigate}`}>
                            {row.number}
                          </Link>
                        </td>
                        <td>{row.organizedUnit}</td>
                        <td className="see-more">
                          <VehiclePopover
                            t={t}
                            vehicle={row.vehicle}
                            half={1 / (idx + 1)}
                            idx={idx}
                            id={'popover-vehicle' + idx}
                          />
                        </td>
                        <td>{row.stage}</td>
                        <td>{row.creationDate}</td>
                        <td>{row.lastUpdateDate}</td>
                        <td>
                          <div className= {!row.isHistorical || !row.freeStockVo || !row.freeStockHistoricalVo ? 'disabled selfSupply-actions' : 'selfSupply-actions'}>
                            {
                              !row.isHistorical || !row.freeStockVo || !row.freeStockHistoricalVo ? <i className="ico-discount" />
                                : <a onClick={() => this.stepToVo(row)}>
                                  <OverlayTrigger placement="right" overlay={stepTooltip}>
                                    <i className="ico-discount" />
                                  </OverlayTrigger>
                                </a>
                            }
                          </div>
                        </td>
                      </tr>
                    ))}
                  />
                </div>,
                <div className="search-footer" key="search-footer">
                  <IDocCarPagination
                    id="btn_pag_sales"
                    page={page}
                    pagesCount={pages}
                    totalRows= {count}
                    onSelectAction={(page) => this.onPageChange(page)}
                  />
                </div>
              ])
            }
            <ProgressBar style={{ display: this.state.displayBar }}>
              <ProgressBar max={this.state.maxBar} now={this.state.progress} label={`${this.state.progress}/${this.state.maxBar}`} striped variant="success" key={1} />
              <ProgressBar max={this.state.maxBar} now={this.state.progressFail} label={`${this.state.progressFail}/${this.state.maxBar}`} striped variant="danger" style={{ backgroundColor: '#d44b4b' }} key={2} />
            </ProgressBar>
          </div>
        </Form>
      </>
    )
  }
}

const validate = (values, { t }) => {
  const errors = {}
  if (values) {
    if (values.creationDateFrom && values.creationDateTo) {
      errors.creationDateTo = validateDates(t, values.creationDateFrom, values.creationDateTo)
    }
  }
  return errors
}

export default reduxForm({
  form: 'selfSupplyManagerForm',
  validate
})(SelfSupplyManager)
