import walletActionTypes from '../../constants/actions/reports/walletReport'

export function fetchWalletList (filter) {
  return {
    type: walletActionTypes.FETCH_WALLET_REPORT,
    filter
  }
}

export function fetchWalletReportSuccess (walletList, filter) {
  return {
    type: walletActionTypes.FETCH_WALLET_REPORT_SUCCESS,
    walletList,
    filter
  }
}

export function downloadWalletList (filter) {
  return {
    type: walletActionTypes.DOWNLOAD_WALLET_REPORT,
    filter
  }
}
