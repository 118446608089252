import searchActionTypes from '../../constants/actions/search/search'

export function initialState () {
  return {
    selectedTab: null,
    hasSearch: false,
    filters: {
      page: 1,
      pageSize: 10,
      orderBy: '%2BvehicleBrand'
    },
    dynamicFilters: {},
    searchFilters: {},
    vehicleData: [],
    entityData: [],
    dossierData: [],
    prepareData: [],
    processingAgenciesData: [],
    serviceRoleConfig: null,
    pages: 1,
    count: 0,
    ftpSearch: {
      hasSearch: false,
      filters: {
        page: 1,
        pageSize: 10,
        orderBy: '%2Bname'
      },
      pages: 1,
      count: 0,
      ftpRegister: []
    },
    registerOrder: {
      filters: {
        page: 1,
        pageSize: 10,
        orderBy: '%2BdateFrom'
      },
      data: [],
      pages: 1,
      count: 0,
      hasSearch: false
    },
    selfSupply: {
      filters: {
        page: 1,
        pageSize: 10,
        orderBy: '%2BdateFrom'
      },
      selfSupplyData: [],
      pages: 1,
      count: 0,
      hasSearch: false,
      showModal: false,
      pristineParent: true
    },
    fleets_mang_filter: {},
    reportData: null,
    reportTable: {
      showModal: false,
      dossierList: null,
      dossierSubType: null,
      filters: {
        page: 1,
        orderBy: '+dossier',
        pageSize: 10,
        dossierIds: [],
        title: ''
      },
      count: 0
    },
    showCampaignColumnsInExcel: false,
    maxDossierExport: null,
    fleetReportData: null,
    stockManagementData: [],
    fields: {}
  }
}

function searchTabChangeSuccess (state, { selectedTab }) {
  return {
    ...state,
    selectedTab,
    hasSearch: false,
    filters: initialState().filters
  }
}

function fetchDossierFilterSuccess (state, { data, filters }) {
  return {
    ...state,
    showCampaignColumnsInExcel: data.showCampaignColumnsInExcel,
    dossierData: data.items,
    pages: data.pages,
    count: data.count,
    hasSearch: true,
    filters: {
      pageSize: filters.pageSize,
      page: filters.page,
      orderBy: filters.orderBy
    },
    maxDossierExport: data.count && data.maxDossierExport && data.count > data.maxDossierExport ? data.maxDossierExport : null
  }
}

function fetchRentingSuccess (state, { data, filters }) {
  return {
    ...state,
    rentingData: data.items,
    pages: data.pages,
    count: data.count,
    hasSearch: true,
    filters: {
      pageSize: filters.pageSize,
      page: filters.page,
      orderBy: filters.orderBy
    }
  }
}

function fetchSelfSupplySuccess (state, { data, filters }) {
  return {
    ...state,
    selfSupplyData: data.items,
    pages: data.pages,
    count: data.count,
    hasSearch: true,
    filters: {
      pageSize: filters.pageSize,
      page: filters.page,
      orderBy: filters.orderBy
    },
    selfSupply: {
      filters: {
        pageSize: filters.pageSize,
        page: filters.page,
        orderBy: filters.orderBy
      },
      selfSupplyData: data.items,
      pages: data.pages,
      count: data.count,
      hasSearch: true,
      showModal: state.selfSupply.showModal,
      pristineParent: state.selfSupply.pristineParent
    }
  }
}

function fetchVehicleFilterSuccess (state, { data, filters }) {
  return {
    ...state,
    vehicleData: data.items,
    pages: data.pages,
    count: data.count,
    hasSearch: true,
    filters: {
      pageSize: filters.pageSize,
      page: filters.page,
      orderBy: filters.orderBy
    },
    maxDossierExport: data.count && data.maxDossierExport && data.count > data.maxDossierExport ? data.maxDossierExport : null
  }
}

function fetchEntityFilterSuccess (state, { data, filters }) {
  return {
    ...state,
    entityData: data.items,
    pages: data.pages,
    count: data.count,
    hasSearch: true,
    filters: {
      pageSize: filters.pageSize,
      page: filters.page,
      orderBy: filters.orderBy
    },
    maxDossierExport: data.count && data.maxDossierExport && data.count > data.maxDossierExport ? data.maxDossierExport : null
  }
}

function fetchChargeFilterSuccess (state, { data, filters }) {
  return {
    ...state,
    chargeData: data.items,
    pages: data.pages,
    count: data.count,
    hasSearch: true,
    filters: {
      pageSize: filters.pageSize,
      page: filters.page,
      orderBy: filters.orderBy
    }
  }
}

function resetFiltersSuccess (state) {
  return {
    ...state,
    hasSearch: false,
    hideFilters: false,
    dynamicFilters: initialState().dynamicFilters,
    filters: initialState().filters,
    selfSupply: {
      filters: initialState().selfSupply.filters,
      selfSupplyData: initialState().selfSupply.selfSupplyData,
      pages: initialState().selfSupply.pages,
      count: initialState().selfSupply.count,
      hasSearch: false,
      showModal: state.selfSupply.showModal,
      pristineParent: state.selfSupply.pristineParent
    }
  }
}

function setFiltersSuccess (state, { filters }) {
  return {
    ...state,
    dynamicFilters: filters,
    hideFilters: true
  }
}

function setNoDynamicFiltersSuccess (state, { filters }) {
  return {
    ...state,
    filters: filters
  }
}

function fetchFleetsSuccess (state, { data, filters }) {
  return {
    ...state,
    dossierData: data.items,
    pages: data.pages,
    count: data.count,
    hasSearch: true,
    filters: {
      pageSize: filters.pageSize,
      page: filters.page,
      orderBy: filters.orderBy
    },
    showButtonUploadDocument: data.showButtonUploadDocument
  }
}

function fetchCampaignExportSuccess (state, { data, filters }) {
  return {
    ...state,
    dossierData: data.items,
    pages: data.pages,
    count: data.count,
    hasSearch: true,
    filters: {
      pageSize: filters.pageSize,
      page: filters.page,
      orderBy: filters.orderBy
    }
  }
}

function fetchFtpRegisterSuccess (state, { data, filters }) {
  return {
    ...state,
    ftpSearch: {
      hasSearch: true,
      filters: {
        pageSize: filters.pageSize,
        page: filters.page,
        orderBy: filters.orderBy
      },
      pages: data.pages,
      count: data.count,
      ftpRegister: data.items
    }
  }
}

function changeExportCampaignExportSucces (state, dossiers) {
  return {
    ...state,
    dossierData: dossiers.dossiers
  }
}

function fetchRegisterOrderSuccess (state, { data, filters }) {
  return {
    ...state,
    registerOrder: {
      filters: {
        pageSize: filters.pageSize,
        page: filters.page,
        orderBy: filters.orderBy
      },
      data: data.items,
      pages: data.pages,
      count: data.count,
      hasSearch: true
    }
  }
}

function fetchServicePrepareSuccess (state, { data, filters }) {
  return {
    ...state,
    prepareData: data.items,
    pages: data.pages,
    count: data.count,
    hasSearch: true,
    filters: {
      pageSize: filters.pageSize,
      page: filters.page,
      orderBy: filters.orderBy
    }
  }
}

function fetchServicePrepareRoleConfigSuccess (state, { data }) {
  return {
    ...state,
    serviceRoleConfig: data

  }
}

export function openSelfSupplyModal (state, { pristine }) {
  return {
    ...state,
    selfSupply: {
      ...initialState().selfSupply,
      showModal: true,
      pristineParent: pristine
    }
  }
}

export function closeSelfSupplyModal (state) {
  return {
    ...state,
    selfSupply: {
      ...initialState().selfSupply
    }
  }
}

function fetchDossierFleetsSuccess (state, { data, filters }) {
  return {
    ...state,
    dossierData: data.items,
    pages: data.pages,
    count: data.count,
    hasSearch: true,
    dynamicFilters: filters,
    filters: {
      pageSize: filters.pageSize,
      page: filters.page,
      orderBy: filters.orderBy
    }
  }
}
function setManagementFleetsFilterSuccess (state, { filters }) {
  return {
    ...state,
    fleets_mang_filter: filters
  }
}

function resetManagementFleetsFilterSuccess (state) {
  return {
    ...state,
    fleets_mang_filter: {}
  }
}

function fetchReportCommercialSuccess (state, { data, filters }) {
  return {
    ...state,
    reportData: data,
    hasSearch: true,
    pages: 1,
    count: 100,
    filters: {
      pageSize: 100,
      page: 1,
      orderBy: ''
    }
  }
}

function fetchReportCommercialTableSuccess (state, { data, filters }) {
  return {
    ...state,
    reportTable: {
      showModal: true,
      dossierList: data.dossiers,
      count: data.total,
      filters: filters,
      dossierSubType: 1
    }
  }
}

function closeReportCommercialTableModal (state) {
  return {
    ...state,
    reportTable: {
      ...initialState().reportTable
    }
  }
}

function fetchPapServicesFromManagerSuccess (state, { data, filters }) {
  return {
    ...state,
    papServicesData: data.items,
    pages: data.pages,
    count: data.count,
    hasSearch: true,
    filters: {
      pageSize: filters.pageSize,
      page: filters.page,
      orderBy: filters.orderBy
    }
  }
}

function fetchPlanLogiticsPapSuccess (state, { data, filters }) {
  return {
    ...state,
    papServicesData: data.items,
    pages: data.pages,
    count: data.count,
    hasSearch: true,
    filters: {
      pageSize: filters.pageSize,
      page: filters.page,
      orderBy: filters.orderBy
    }
  }
}

function fetchProcessingAgencyFilterSuccess (state, { data, filters }) {
  return {
    ...state,
    processingAgenciesData: data.items,
    pages: data.pages,
    count: data.count,
    hasSearch: true,
    filters: {
      pageSize: filters.pageSize,
      page: filters.page,
      orderBy: filters.orderBy
    },
    maxDossierExport: data.count && data.maxDossierExport && data.count > data.maxDossierExport ? data.maxDossierExport : null
  }
}

function fetchFleetReportSuccess (state, { data, filters }) {
  return {
    ...state,
    fleetReportData: data.items,
    pages: data.pages,
    count: data.count,
    hasSearch: true,
    filters: {
      pageSize: filters.pageSize,
      page: filters.page,
      orderBy: filters.orderBy
    }
  }
}

function fetchStockManagementDossiersSuccess (state, { data, filters }) {
  return {
    ...state,
    stockManagementData: data.items,
    pages: data.pages,
    count: data.count,
    hasSearch: true,
    filters: {
      pageSize: filters.pageSize,
      page: filters.page,
      orderBy: filters.orderBy
    }
  }
}

function searchSectionChangeSuccess (state, { secMapSearch }) {
  return {
    ...state,
    secMapSearch,
    filters: initialState().filters
  }
}

function setHideFiltersSuccess (state, { hideFilters }) {
  return {
    ...state,
    hideFilters
  }
}

function setFieldsSuccess (state, { fields }) {
  return {
    ...state,
    fields
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case searchActionTypes.SEARCH_TAB_CHANGE:
      return searchTabChangeSuccess(state, action)
    case searchActionTypes.FETCH_DOSSIER_FILTER_SUCCESS:
      return fetchDossierFilterSuccess(state, action)
    case searchActionTypes.FETCH_VEHICLE_FILTER_SUCCESS:
      return fetchVehicleFilterSuccess(state, action)
    case searchActionTypes.FETCH_ENTITY_FILTER_SUCCESS:
      return fetchEntityFilterSuccess(state, action)
    case searchActionTypes.FETCH_CHARGE_FILTER_SUCCESS:
      return fetchChargeFilterSuccess(state, action)
    case searchActionTypes.RESET_FILTERS:
      return resetFiltersSuccess(state, action)
    case searchActionTypes.SET_FILTERS:
      return setFiltersSuccess(state, action)
    case searchActionTypes.FETCH_CHARGE_RENTING_SUCCESS:
      return fetchRentingSuccess(state, action)
    case searchActionTypes.FETCH_FLEETS_SUCCESS:
      return fetchFleetsSuccess(state, action)
    case searchActionTypes.FETCH_SELFSUPPLY_SUCCESS:
      return fetchSelfSupplySuccess(state, action)
    case searchActionTypes.FETCH_CAMPAIGN_EXPORT_SUCCES:
      return fetchCampaignExportSuccess(state, action)
    case searchActionTypes.CHANGE_EXPORT_CAMPAIGN_EXPORT:
      return changeExportCampaignExportSucces(state, action)
    case searchActionTypes.SET_NO_DYNAMIC_FILTERS:
      return setNoDynamicFiltersSuccess(state, action)
    case searchActionTypes.FETCH_REGISTER_ORDER_SUCCESS:
      return fetchRegisterOrderSuccess(state, action)
    case searchActionTypes.FETCH_FTP_REGISTER_SUCCESS:
      return fetchFtpRegisterSuccess(state, action)
    case searchActionTypes.FETCH_SERVICE_PREPARE_SUCCESS:
      return fetchServicePrepareSuccess(state, action)
    case searchActionTypes.FETCH_SERVICE_PREPARE_ROLE_CONFIG_SUCCESS:
      return fetchServicePrepareRoleConfigSuccess(state, action)
    case searchActionTypes.FETCH_DOSSIER_FLEETS_SUCCESS:
      return fetchDossierFleetsSuccess(state, action)
    case searchActionTypes.OPEN_SELF_SUPPLY_MODAL:
      return openSelfSupplyModal(state, action)
    case searchActionTypes.CLOSE_SELF_SUPPLY_MODAL:
      return closeSelfSupplyModal(state, action)
    case searchActionTypes.SET_FLEETS_MANAGEMENT_FILTERS:
      return setManagementFleetsFilterSuccess(state, action)
    case searchActionTypes.RESET_FLEETS_MANAGEMENT_FILTERS:
      return resetManagementFleetsFilterSuccess(state, action)
    case searchActionTypes.FETCH_REPORT_COMMERCIAL_SUCCESS:
      return fetchReportCommercialSuccess(state, action)
    case searchActionTypes.FETCH_REPORT_COMMERCIAL_TABLE_SUCCESS:
      return fetchReportCommercialTableSuccess(state, action)
    case searchActionTypes.CLOSE_REPORT_COMMERCIAL_TABLE_MODAL:
      return closeReportCommercialTableModal(state, action)
    case searchActionTypes.FETCH_PAP_SERVICES_FROM_MANAGER_SUCCESS:
      return fetchPapServicesFromManagerSuccess(state, action)
    case searchActionTypes.FETCH_PLAN_LOGISTICS_PAP_SUCCESS:
      return fetchPlanLogiticsPapSuccess(state, action)
    case searchActionTypes.FETCH_PROCESSING_AGENCIES_SUCCESS:
      return fetchProcessingAgencyFilterSuccess(state, action)
    case searchActionTypes.FETCH_FLEET_REPORT_SUCCESS:
      return fetchFleetReportSuccess(state, action)
    case searchActionTypes.FETCH_STOCK_MANAGEMENT_DOSSIERS_SUCCESS:
      return fetchStockManagementDossiersSuccess(state, action)
    case searchActionTypes.SEARCH_SECTION_CHANGE:
      return searchSectionChangeSuccess(state, action)
    case searchActionTypes.SET_HIDE_FILTERS:
      return setHideFiltersSuccess(state, action)
    case searchActionTypes.SET_FIELDS_SUCCESS:
      return setFieldsSuccess(state, action)
    default:
      return state
  }
}
