import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { isInvalid, getFormSyncErrors, getFormValues } from 'redux-form'
import StockDossier from '../../../components/dossiers/stock/StockDossier'
import StockDossier_V2 from '../../../_v2/components/dossiers/stock/StockDossier'
import messageModalActions from '../../../actions/modals/messageModal'
import commentsActions from '../../../actions/dossier/common/comments'
import commonHeaderActions from '../../../actions/dossier/common/commonHeader'
import { storeDossier } from '../../../actions/dossier/commonDossierActions'
import combosActions, { fetchAllReasons, fetchCancelCombo, fetchRatingCombo, fetchRatingComboByOrganizedUnit, fetchLowestOrganizedUnits, fecthOrganizedUnitsByDossierUo, fetchSameLevelOusCombo } from '../../../actions/combos/combos'
import { openModal as openCommonModal } from '../../../actions/common'
import { openDocumentManagementModal as openDocumentModal, cancelDocument } from '../../../actions/dossier/common/documentManagementModal'
import { openDocumentEditionModal, closeAddOrEditDocumentModal, openDocumentCreationModal } from '../../../actions/dossier/common/documentEditionModal'
import scrollerActions from '../../../actions/commons/scroller'
import buttonsHeaderActions from '../../../actions/dossier/common/buttonsHeader'
import buttonsSidebarActions from '../../../actions/dossier/common/buttonsSidebar'
import { fetchFilteredRelatedDossier } from '../../../actions/dossier/common/dossierRelatedDossiers'
import { getChangeUo, getSalesmanChangeUo, postUpdateChangeUo } from '../../../actions/dossier/common/changeUo'
import stockDossierActions, { getDossierPhoto } from '../../../actions/dossier/stockDossier'
import vehicleActions from '../../../actions/dossier/common/vehicle'
import { getStorageValue } from '../../../storage/storage'
import TrackingContainer from '../../tracking/trackingContainer'

export function mapStateToProps (state) {
  return {
    dossier: state.stockDossier,
    combos: state.combos,
    showManagementModal: state.documentaryManagementModal.showModal,
    windowWidth: state.windowDimensions.width,
    statusHeaderHeight: state.statusHeader.height,
    activeTab: state.buttonsHeader.activeTab,
    buttonsHeaderHeight: state.buttonsHeader.height,
    activeSection: state.buttonsSidebar.activeSection,
    relatedDossiersOrderBy: state.dossierRelatedDossiers.orderby,
    changeUo: state.changeUo,
    isInvalid: isInvalid('stock_dossier')(state),
    formErrors: getFormSyncErrors('stock_dossier')(state),
    formValues: getFormValues('stock_dossier')(state),
    vehicleProps: state.vehicle,
    comment: state.comment,
    reason: state.reason,
    cancelCombo: state.combos.cancelCombo,
    dossierRatingComponent: state.stockDossier?.dossierRatingComponent,
    dossierPhotosComponent: state.stockDossier?.dossierPhotosComponent,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      ...stockDossierActions,
      ...commentsActions,
      ...commonHeaderActions,
      ...buttonsHeaderActions,
      ...buttonsSidebarActions,
      ...scrollerActions,
      ...combosActions,
      storeDossier,
      openDocumentEditionModal,
      closeAddOrEditDocumentModal,
      openCommonModal,
      openDocumentModal,
      fetchFilteredRelatedDossier,
      ...messageModalActions,
      fetchAllReasons,
      fetchLowestOrganizedUnits,
      getChangeUo,
      getSalesmanChangeUo,
      postUpdateChangeUo,
      fecthOrganizedUnitsByDossierUo,
      fetchCancelCombo,
      fetchRatingCombo,
      fetchRatingComboByOrganizedUnit,
      ...vehicleActions,
      fetchSameLevelOusCombo,
      getDossierPhoto,
      openDocumentCreationModal,
      cancelDocument
    }, dispatch)
  }
}
let version = parseInt(getStorageValue('interfaceVersion'))
var decoratedComponent = null
if (version === 2) {
  decoratedComponent = TrackingContainer(StockDossier_V2, true)
} else {
  decoratedComponent = TrackingContainer(StockDossier, true)
}
export default connect(mapStateToProps, mapDispatchToProps)(translate()(decoratedComponent))
