import React, { PureComponent } from 'react'
import { Row, Panel } from 'react-bootstrap'
import InputText from '../../../../_v2/components/commons/form/InputText'
import InputSelect from '../../../../_v2/components/commons/form/InputSelect'
import { Field } from 'redux-form'
import { dossierTypes as dossierTypesConstant } from '../../../../constants/dossier/common/dossierTypes'
import { getFieldsPermisions } from '../../../../util/permisionFunctions'
import { compareObjects } from '../../../../util/utils'
import { permissions } from '../../../../constants/backendIds'
import CustomFields from '../../../../components/commons/customFields/CustomFields'
import ComponentTitle from '../../../../_v2/containers/dossiers/commons/ComponentTitlePage'

class OtherData extends PureComponent {
  constructor () {
    super()
    this.state = {
      open: false,
      fieldsConfiguration: {
        additionalClauses: null,
        previousUsageId: null,
        laterUseId: null,
        vehicleTypeId: null,
        activityClient: null,
        activityBuyer: null,
        paymentMethodPurchaseId: null,
        ivaTypeId: null
      }
    }
  }

  initializePermisions (props) {
    const fieldKeys = Object.keys(this.state.fieldsConfiguration)
    const newState = getFieldsPermisions(props.componentConfiguration.fieldsConfiguration, fieldKeys, props.componentConfiguration.permission)
    this.setState({ fieldsConfiguration: newState.fieldsConfiguration })
  }

  UNSAFE_componentWillMount () {
    this.initializePermisions(this.props)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.open) {
      this.setState({ open: true })
    }

    if (nextProps.dossierId && !compareObjects(this.props.componentConfiguration || {}, nextProps.componentConfiguration, true)) {
      this.initializePermisions(nextProps)
    }
  }

  componentDidMount () {
    this.fetchInitialData()
  }

  fetchInitialData () {
    if (this.props.isOtherDataLoaded === false) {
      return new Promise((resolve, reject) => {
        this.props.handleToUpdateComponent(this.props)
        if (this.props.dossierType === dossierTypesConstant.sales) {
          return this.props.actions.fetchSalesOtherData(this.props.dossierId, resolve, reject, this.props.pristine)
        } else if (this.props.dossierType === dossierTypesConstant.purchase) {
          return this.props.actions.fetchPurchaseOtherData(this.props.dossierId, resolve, reject, this.props.pristine)
        }
      })
    }
  }

  fetchSalesDossierPromiseOtherData () {
    if (this.state.open) {
      this.props.actions.setActiveSection(false)
    }
    this.setState({
      open: !this.state.open
    })
    if (this.props.isOtherDataLoaded === false) {
      return new Promise((resolve, reject) => {
        this.props.handleToUpdateComponent(this.props)
        if (this.props.dossierType === dossierTypesConstant.sales) {
          return this.props.actions.fetchSalesOtherData(this.props.dossierId, resolve, reject, this.props.pristine)
        } else if (this.props.dossierType === dossierTypesConstant.purchase) {
          return this.props.actions.fetchPurchaseOtherData(this.props.dossierId, resolve, reject, this.props.pristine)
        }
      })
    }
  }

  render () {
    const {
      t, dossierType, readOnlyFields, showEconomicPlan, customFields,
      combos: {
        ivaTypeCombo, paymentMethodPurchase, laterUseCombo, vehicleType, previousUsageCombo, activityClientCombo
        , activityBuyerCombo
      }
    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.'

    return (
      <div className={dossierType === dossierTypesConstant.purchase ? ('dossier-Component') : 'dossier-Component'}>
        <div className="header-panel" onClick={this.fetchSalesDossierPromiseOtherData.bind(this)}>
          <ComponentTitle
            componentCode="OTHERS"
            componentOpen={this.state.open}
          />
        </div>
        <Panel expanded={this.state.open} onToggle={() => {}}>
          <Panel.Collapse>
            {
              dossierType === dossierTypesConstant.purchase ? (
                <Row className='fieldsContainer'>
                  {this.state.fieldsConfiguration.additionalClauses.permission !== permissions.hidden && (<Field
                    component={InputText}
                    colWidht={25} // 25, 33, 50, 75, 100 default=25
                    id="additionalClauses"
                    name="dossierCompleteOtherDataComponent.additionalClauses"
                    controlLabel={t(`${tKey}OTHER_DATA.ADDITIONAL_CLAUSES`)}
                    placeholder={t(`${tKey}OTHER_DATA.ADDITIONAL_CLAUSES`)}
                    disabled={readOnlyFields || this.state.fieldsConfiguration.additionalClauses.permission !== permissions.editable}
                    forceControlLabelInOneLine={true}
                  />)}
                  {this.state.fieldsConfiguration.previousUsageId.permission !== permissions.hidden && (<Field
                    component={InputSelect}
                    colWidht={25} // 25, 33, 50, 75, 100 default=25
                    id="previousUsageId"
                    name="dossierCompleteOtherDataComponent.previousUsageId"
                    controlLabel={t(`${tKey}OTHER_DATA.PREVIOUS_USAGE`)}
                    placeholder={t(`${tKey}OTHER_DATA.PREVIOUS_USAGE`)}
                    options={previousUsageCombo}
                    valueKey="id"
                    labelKey="value"
                    disabled={readOnlyFields || this.state.fieldsConfiguration.previousUsageId.permission !== permissions.editable}
                  />)}
                  {this.state.fieldsConfiguration.laterUseId.permission !== permissions.hidden && (<Field
                    component={InputSelect}
                    colWidht={25} // 25, 33, 50, 75, 100 default=25
                    id="laterUseId"
                    name="dossierCompleteOtherDataComponent.laterUseId"
                    controlLabel={t(`${tKey}OTHER_DATA.LATER_USE`)}
                    placeholder={t(`${tKey}OTHER_DATA.LATER_USE`)}
                    options={laterUseCombo}
                    disabled={readOnlyFields || this.state.fieldsConfiguration.laterUseId.permission !== permissions.editable}
                    valueKey="id"
                    labelKey="value"
                  />)}
                  {this.state.fieldsConfiguration.vehicleTypeId.permission !== permissions.hidden && (<Field
                    component={InputSelect}
                    colWidht={25} // 25, 33, 50, 75, 100 default=25
                    id="vehicleTypeId"
                    name="dossierCompleteOtherDataComponent.vehicleTypeId"
                    controlLabel={t(`${tKey}OTHER_DATA.VEHICLE_TYPE`)}
                    placeholder={t(`${tKey}OTHER_DATA.VEHICLE_TYPE`)}
                    options={vehicleType}
                    disabled={readOnlyFields || this.state.fieldsConfiguration.vehicleTypeId.permission !== permissions.editable}
                    valueKey="id"
                    labelKey="value"
                  />)}
                  {this.state.fieldsConfiguration.activityClient.permission !== permissions.hidden && (<Field
                    component={InputSelect}
                    colWidht={25} // 25, 33, 50, 75, 100 default=25
                    id="activityClient"
                    name="dossierCompleteOtherDataComponent.activityClientId"
                    controlLabel={t(`${tKey}OTHER_DATA.ACTIVITY_CLIENT`)}
                    placeholder={t(`${tKey}OTHER_DATA.ACTIVITY_CLIENT`)}
                    options={activityClientCombo}
                    valueKey="id"
                    labelKey="value"
                    disabled={readOnlyFields || this.state.fieldsConfiguration.activityClient.permission !== permissions.editable}
                  />)}
                  {this.state.fieldsConfiguration.activityBuyer.permission !== permissions.hidden && (<Field
                    component={InputSelect}
                    colWidht={25} // 25, 33, 50, 75, 100 default=25
                    id="activityBuyer"
                    name="dossierCompleteOtherDataComponent.activityBuyerId"
                    controlLabel={t(`${tKey}OTHER_DATA.ACTIVITY_BUYER`)}
                    placeholder={t(`${tKey}OTHER_DATA.ACTIVITY_BUYER`)}
                    options={activityBuyerCombo}
                    valueKey="id"
                    labelKey="value"
                    disabled={readOnlyFields || this.state.fieldsConfiguration.activityBuyer.permission !== permissions.editable}
                  />)}
                  {this.state.fieldsConfiguration.paymentMethodPurchaseId.permission !== permissions.hidden && (<Field
                    component={InputSelect}
                    colWidht={25} // 25, 33, 50, 75, 100 default=25
                    id="paymentMethodPurchaseId"
                    name="dossierCompleteOtherDataComponent.paymentMethodPurchaseId"
                    controlLabel={t(`${tKey}OTHER_DATA.PAYMENT_METHOD`)}
                    placeholder={t(`${tKey}OTHER_DATA.PAYMENT_METHOD`)}
                    options={paymentMethodPurchase}
                    disabled={readOnlyFields || this.state.fieldsConfiguration.paymentMethodPurchaseId.permission !== permissions.editable}
                    valueKey="id"
                    labelKey="value"
                  />)}
                  {this.state.fieldsConfiguration.ivaTypeId.permission !== permissions.hidden && (<Field
                    component={InputSelect}
                    colWidht={25} // 25, 33, 50, 75, 100 default=25
                    id="ivaTypeId"
                    name="dossierCompleteOtherDataComponent.ivaTypeId"
                    controlLabel={t(`${tKey}OTHER_DATA.IVA_TYPE_ID`)}
                    placeholder={t(`${tKey}OTHER_DATA.IVA_TYPE_ID`)}
                    options={ivaTypeCombo}
                    disabled={readOnlyFields || this.state.fieldsConfiguration.ivaTypeId.permission !== permissions.editable}
                    valueKey="id"
                    labelKey="value"
                  />)}
                </Row>
              ) : (dossierType === dossierTypesConstant.sales ? (
                <Row className='fieldsContainer'>
                  {this.state.fieldsConfiguration.additionalClauses.permission !== permissions.hidden && (<Field
                    component={InputText}
                    colWidht={25} // 25, 33, 50, 75, 100 default=25
                    id="additionalClauses"
                    name="dossierCompleteOtherDataComponent.additionalClauses"
                    controlLabel={t(`${tKey}OTHER_DATA.ADDITIONAL_CLAUSES`)}
                    placeholder={t(`${tKey}OTHER_DATA.ADDITIONAL_CLAUSES`)}
                    forceControlLabelInOneLine={true}
                    disabled={readOnlyFields || this.state.fieldsConfiguration.additionalClauses.permission !== permissions.editable}
                  />)}
                  {this.state.fieldsConfiguration.previousUsageId.permission !== permissions.hidden && (<Field
                    component={InputSelect}
                    colWidht={25} // 25, 33, 50, 75, 100 default=25
                    id="previousUsageId"
                    name="dossierCompleteOtherDataComponent.previousUsageId"
                    controlLabel={t(`${tKey}OTHER_DATA.PREVIOUS_USAGE`)}
                    placeholder={t(`${tKey}OTHER_DATA.PREVIOUS_USAGE`)}
                    options={previousUsageCombo}
                    valueKey="id"
                    labelKey="value"
                    disabled={readOnlyFields || this.state.fieldsConfiguration.previousUsageId.permission !== permissions.editable}
                  />)}
                  {this.state.fieldsConfiguration.laterUseId.permission !== permissions.hidden && (<Field
                    component={InputSelect}
                    colWidht={25} // 25, 33, 50, 75, 100 default=25
                    id="laterUseId"
                    name="dossierCompleteOtherDataComponent.laterUseId"
                    controlLabel={t(`${tKey}OTHER_DATA.LATER_USE`)}
                    placeholder={t(`${tKey}OTHER_DATA.LATER_USE`)}
                    options={laterUseCombo}
                    disabled={readOnlyFields || this.state.fieldsConfiguration.laterUseId.permission !== permissions.editable}
                    valueKey="id"
                    labelKey="value"
                  />)}
                  {this.state.fieldsConfiguration.vehicleTypeId.permission !== permissions.hidden && (<Field
                    component={InputSelect}
                    colWidht={25} // 25, 33, 50, 75, 100 default=25
                    id="vehicleTypeId"
                    name="dossierCompleteOtherDataComponent.vehicleTypeId"
                    controlLabel={t(`${tKey}OTHER_DATA.VEHICLE_TYPE`)}
                    placeholder={t(`${tKey}OTHER_DATA.VEHICLE_TYPE`)}
                    options={vehicleType}
                    disabled={readOnlyFields || this.state.fieldsConfiguration.vehicleTypeId.permission !== permissions.editable}
                    valueKey="id"
                    labelKey="value"
                  />)}
                  {this.state.fieldsConfiguration.activityClient.permission !== permissions.hidden && (<Field
                    component={InputSelect}
                    colWidht={25} // 25, 33, 50, 75, 100 default=25
                    id="activityClient"
                    name="dossierCompleteOtherDataComponent.activityClientId"
                    controlLabel={t(`${tKey}OTHER_DATA.ACTIVITY_CLIENT`)}
                    placeholder={t(`${tKey}OTHER_DATA.ACTIVITY_CLIENT`)}
                    options={activityClientCombo}
                    defaultValueEntityType={this.state.defaultActivityClientId}
                    onChange={value => this.setState({ defaultActivityClientId: value })}
                    valueKey="id"
                    labelKey="value"
                    disabled={readOnlyFields || this.state.fieldsConfiguration.activityClient.permission !== permissions.editable}
                  />)}
                  {this.state.fieldsConfiguration.activityBuyer.permission !== permissions.hidden && (<Field
                    component={InputSelect}
                    colWidht={25} // 25, 33, 50, 75, 100 default=25
                    id="activityBuyer"
                    name="dossierCompleteOtherDataComponent.activityBuyerId"
                    controlLabel={t(`${tKey}OTHER_DATA.ACTIVITY_BUYER`)}
                    placeholder={t(`${tKey}OTHER_DATA.ACTIVITY_BUYER`)}
                    options={activityBuyerCombo}
                    valueKey="id"
                    labelKey="value"
                    disabled={readOnlyFields || this.state.fieldsConfiguration.activityBuyer.permission !== permissions.editable}
                  />)}
                  {!showEconomicPlan && this.state.fieldsConfiguration.ivaTypeId.permission !== permissions.hidden && (
                    <Field
                      component={InputSelect}
                      colWidht={25} // 25, 33, 50, 75, 100 default=25
                      id="ivaTypeId"
                      name="dossierCompleteOtherDataComponent.ivaTypeId"
                      controlLabel={t(`${tKey}OTHER_DATA.IVA_TYPE_ID`)}
                      placeholder={t(`${tKey}OTHER_DATA.IVA_TYPE_ID`)}
                      options={ivaTypeCombo}
                      disabled={readOnlyFields || this.state.fieldsConfiguration.ivaTypeId.permission !== permissions.editable}
                      valueKey="id"
                      labelKey="value"
                    />
                  )}
                </Row>
              ) : null
              )
            }
            <CustomFields customFields={customFields} readOnlyFields={readOnlyFields} />
          </Panel.Collapse>
        </Panel>
      </div>
    )
  }
}
export default OtherData
