import React, { PureComponent } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import BubbleMeter from '../../../commons/BubbleMeter'
import { formatMoney } from '../../../../util/utils'
import { documentTypeUse as documentTypeUseConstants, permissions } from '../../../../constants/backendIds'
import { documentEntityType as documentEntityTypeConstants } from '../../../../constants/dossier/common/documentEntityType'
import { getFieldsPermisions } from '../../../../util/permisionFunctions'
import { Link } from 'react-router-dom'
import SignTypesModal from '../../common/SignTypesModal'

class PaperworkRow extends PureComponent {
  constructor () {
    super()
    this.state = {
      fieldsConfiguration: {
        signPaperworkOrder: {
          permission: null
        }
      },
      showSignTypesModal: false,
      signTypesCombo: [],
      alreadyExists: false,
      docId: null
    }
    this.signDocument = this.signDocument.bind(this)
  }

  initializePermisions (props) {
    const fieldKeys = Object.keys(this.state.fieldsConfiguration)
    const newState = getFieldsPermisions(props.componentConfiguration.fieldsConfiguration, fieldKeys, props.componentConfiguration.permission)
    this.setState({ fieldsConfiguration: newState.fieldsConfiguration })
  }

  componentDidMount () {
    if (this.props.componentConfiguration !== null) {
      this.initializePermisions(this.props)
    } else {
      this.setState({ permission: 0 })
    }
  }

  signDocument (documentId, dossierType, updateDossierAction, dossierParentId) {
    new Promise((resolve, reject) => {
      return this.props.checkOrderStatus(this.props.paperwork.paperworkId, resolve, reject)
    }).then((infoOrder) => {
      if (infoOrder && infoOrder.continue) {
        new Promise((resolve, reject) => {
          return this.props.actions.signTypes(resolve, reject, null, documentTypeUseConstants.command, this.props.paperwork.paperworkId)
        }).then((result) => {
          if (result && result.length > 1) {
            this.setState({ signTypesCombo: result, showSignTypesModal: true, alreadyExists: infoOrder.info !== null, docId: infoOrder.info ? infoOrder.info.documentId : null })
          } else if (result && result.length === 1) {
            this.requestSign(documentId, null, null, null, result[0].id, null, infoOrder.info !== null, infoOrder.info, dossierType, updateDossierAction, dossierParentId)
          } else {
            this.requestSign(documentId, null, null, null, null, null, infoOrder.info !== null, infoOrder.info, dossierType, updateDossierAction, dossierParentId)
          }
        })
      } else {
        if (infoOrder && !infoOrder.continue && infoOrder.info === null) {
          this.props.actions.openErrorMessageModal(this.props.tKey + 'SIGN_STATUS_ERROR')
        }
      }
    })
  }

  requestSign (documentId, nulo, callback, refreshAfterChange, signTypeId, resolve, existsOrder = false, info = null, dossierType, updateDossierAction, dossierParentId) {
    if (!existsOrder && !this.state.alreadyExists) {
      this.props.generateFileToSign(documentId, signTypeId, dossierType, updateDossierAction, dossierParentId)
    } else {
      new Promise((resolve, reject) => {
        return info !== null
          ? this.props.actions.requestSign(info.documentId, null, null, null, signTypeId, resolve, dossierType, updateDossierAction, dossierParentId)
          : this.props.actions.requestSign(this.state.docId, null, null, null, signTypeId, resolve, dossierType, updateDossierAction, dossierParentId)
      }).then(() => {
        this.setState({ alreadyExists: false, docId: null })
      })
    }
  }

  closeModalAction () {
    this.setState({ showSignTypesModal: false })
  }

  render () {
    const {
      t,
      tKey,
      fetchPaperworkEditionModal,
      deletePaperwork,
      readOnlyFields,
      openDocumentEditionModal,
      dossierType,
      printPaperwork,
      dossierParentId,
      organizedUnitId,
      updateDossierAction,
      showAlertSendPending,
      paperwork: {
        paperworkId, urlToNavigate, number, type, stage, date, cost, processingAgency, documents, isHistorical, canBeEditable = true
      }
    } = this.props
    const editTooltip = (<Tooltip id="editTooltip">{t(`${tKey}EDIT_BTN`)}</Tooltip>)
    const deleteTooltip = (<Tooltip id="deleteTooltip">{t(`${tKey}DELETE_BTN`)}</Tooltip>)
    const printTooltip = (<Tooltip id="printTooltip">{t(`${tKey}PRINT`)}</Tooltip>)
    const addDocumentTooltip = (<Tooltip id="addDocumentTooltip">{t(`${tKey}ADD_BTN`)}</Tooltip>)
    const generateFileToSignTooltip = (<Tooltip id="signDocumentTooltip">{t(`${tKey}GENERATE_FILE_SIGN_BTN`)}</Tooltip>)
    const requestSign = this.requestSign.bind(this)
    return (
      <tr>
        <SignTypesModal
          t={t}
          showModal={this.state.showSignTypesModal}
          closeModalAction={this.closeModalAction.bind(this)}
          actions={{
            requestSign
          }}
          documentId={paperworkId}
          dossierParentId={dossierParentId}
          updateDossierAction={updateDossierAction}
          dossierType={dossierType}
          callback={null}
          refreshAfterChange={null}
          signTypesCombo={this.state.signTypesCombo}
        />
        <td>
          {
            urlToNavigate ? (<Link to={`/dossier${urlToNavigate}`} id={'pwk_' + paperworkId}>{number}</Link>)
              : number
          }
        </td>
        <td>{type}</td>
        <td>{stage}</td>
        <td>{date}</td>
        <td className="text-right">{formatMoney(cost)}</td>
        <td>{processingAgency}</td>
        {documents && documents.length ? <td><span >{documents[0].name} </span><BubbleMeter listItems={documents.map(document => document.name)} /></td> : <td></td>}
        <td>
          {
            !readOnlyFields && !isHistorical && this.props.componentConfiguration.permission === permissions.editable && (
              <>
                <OverlayTrigger placement="right" overlay={printTooltip}>
                  <i className="ico-print" onClick={() => printPaperwork(paperworkId, dossierType)} />
                </OverlayTrigger>
                <OverlayTrigger placement="right" overlay={addDocumentTooltip}>
                  <i className="ico-document-plus" onClick={() => openDocumentEditionModal(
                    null,
                    documentTypeUseConstants.command,
                    documentEntityTypeConstants.DOSSIER,
                    paperworkId,
                    null,
                    null,
                    organizedUnitId,
                    null,
                    // () => updateDependantsSaleDossierAndHeader(dossierParentId)
                    null,
                    true,
                    dossierType
                  )} />
                </OverlayTrigger>
                {this.state.fieldsConfiguration.signPaperworkOrder.permission !== permissions.hidden && this.props.componentConfiguration.permission === permissions.editable && (
                  <OverlayTrigger placement="right" overlay={generateFileToSignTooltip}>
                    <i className="glyphicon glyphicon-edit" onClick={() => {
                      this.signDocument(paperworkId, dossierType, updateDossierAction, dossierParentId)
                    }} />
                  </OverlayTrigger>
                )}
              </>
            )
          }

        </td>
        {
          canBeEditable && !readOnlyFields && this.props.componentConfiguration.permission === permissions.editable
            ? <td>
              <a onClick={() => fetchPaperworkEditionModal(organizedUnitId, dossierParentId, paperworkId)}>
                <OverlayTrigger placement="right" overlay={editTooltip}>
                  <i className="ico-edit-white" />
                </OverlayTrigger>
              </a>
              <a onClick={() => deletePaperwork(paperworkId, dossierParentId, updateDossierAction, showAlertSendPending)}>
                <OverlayTrigger placement="left" overlay={deleteTooltip}>
                  <i className="ico-trash" />
                </OverlayTrigger>
              </a>
            </td>
            : <td />
        }
      </tr>
    )
  }
}
export default PaperworkRow
