import apiFetch from '../apiFetch'

export default function (token, entityTypeId, entitySubTypeId, organizedUnitId) {
  return apiFetch({
    endPoint: `entity/FilterSubtype?entityTypeId=${entityTypeId}&entitySubTypeId=${entitySubTypeId}&organizedUnitId=${organizedUnitId}`,
    method: 'GET',
    body: null,
    token: token
  })
}
