import React, { PureComponent } from 'react'
import DocumentThumbnail from './DocumentThumbnail'

class DocumentItem extends PureComponent {
  render () {
    const {
      t, dossierId, dossierType, documentFile, readOnly, documentEntityType, objectId, className, readOnlyFields,
      actions: {
        openDocumentEditionModalWithChecklistId,
        cancelDocument,
        addDependant,
        printCheckList,
        openModal,
        openDocWarning
      }
    } = this.props
    return (
      <div className={className + ' wrapper-document'}>
        <DocumentThumbnail
          t={t}
          formState={this.props.formState}
          dossierId={dossierId}
          dossierType={dossierType}
          documentFile={documentFile}
          readOnly={readOnly}
          objectId={objectId}
          documentEntityType={documentEntityType}
          actions={{ openDocWarning, openDocumentEditionModalWithChecklistId, cancelDocument, addDependant, printCheckList, openModal }}
          readOnlyFields={readOnlyFields}
        />
        <span className="block-with-text">{documentFile.documentName}</span>
      </div>
    )
  }
}

export default DocumentItem
