import buyActionTypes from '../../constants/actions/reports/buyReport'

export function fetchBuyList (filter) {
  return {
    type: buyActionTypes.FETCH_BUY_REPORT,
    filter
  }
}

export function fetchBuyReportSuccess (buyList, filter) {
  return {
    type: buyActionTypes.FETCH_BUY_REPORT_SUCCESS,
    buyList,
    filter
  }
}

export function downloadBuyList (filter) {
  return {
    type: buyActionTypes.DOWNLOAD_BUY_REPORT,
    filter
  }
}
