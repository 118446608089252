import changeUoActionTypes from '../../../constants/actions/dossier/common/changeUo'

export function initialState () {
  return {
    changesDossierUo: null,
    salesmanChange: null
  }
}

function getChangeUoSuccess (state, { changesDossierUo }) {
  return {
    ...state,
    changesDossierUo: changesDossierUo
  }
}

function getSalesmanChangeUoSuccess (state, { salesman }) {
  return {
    ...state,
    salesmanChange: salesman
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case changeUoActionTypes.GET_CHANGES_UO_SUCCESS:
      return getChangeUoSuccess(state, action)
    case changeUoActionTypes.GET_SALESMAN_CHANGE_SUCCESS:
      return getSalesmanChangeUoSuccess(state, action)
    default:
      return state
  }
}
