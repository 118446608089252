import React, { PureComponent } from 'react'
import { Col, Checkbox, OverlayTrigger, Glyphicon } from 'react-bootstrap'

class InputCheckbox extends PureComponent {
  handleChange (value) {
    this.props.input.onChange(value.target.checked)
    if (this.props.onInputChange) {
      this.props.onInputChange(value.target.checked)
    }
  }

  render () {
    const {
      id,
      placeholder,
      disabled = false,
      inline = false,
      input: { value },
      colSm = 0,
      colWidht = '25',
      customClass = '',
      checked,
      tooltip,
      tooltipPosition = 'right'
    } = this.props
    return (
      <Col sm={colSm} className={'colWhidht-' + colWidht + ' ' + customClass}>
        <Checkbox
          id={id}
          inline={inline}
          disabled={disabled}
          checked={checked === undefined ? (typeof value) === 'string' ? value.toLowerCase() === 'true' : value : checked}
          onChange={this.handleChange.bind(this)}
        >
          {placeholder}
          {tooltip &&
            <OverlayTrigger placement={tooltipPosition} overlay={tooltip}>
              <Glyphicon className='Select-info' glyph="info-sign"/>
            </OverlayTrigger>
          }
        </Checkbox>
      </Col>
    )
  }
}

export default InputCheckbox
