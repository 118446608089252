import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getFormValues } from 'redux-form'
import SearchByPrepareForm from '../../components/management/SearchByPrepareForm'
import { serviceRequestSpareParts, serviceCompleteSpareParts, serviceCompleteDeliveryPreparation } from '../../../actions/dossier/serviceDossier'
import { translate } from 'react-polyglot'
import {
  fetchStagesCombo
} from '../../../actions/combos/combos'
import { setFilters, resetFilters, fetchServicePrepare, fetchServicePrepareDownload, fetchServicePrepareRoleConfig } from '../../../actions/search/search'
import { openModal } from '../../../actions/common'

export function mapStateToProps (state) {
  return {
    ...state.search,
    combos: {
      stagesCombo: state.combos.stagesCombo
    },
    formValues: getFormValues('SearchByPrepareForm')(state)
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchStagesCombo,
      setFilters,
      resetFilters,
      serviceRequestSpareParts,
      serviceCompleteSpareParts,
      serviceCompleteDeliveryPreparation,
      fetchServicePrepare,
      fetchServicePrepareDownload,
      fetchServicePrepareRoleConfig,
      openModal
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(SearchByPrepareForm))
