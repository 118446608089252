import React from 'react'
import { Redirect } from 'react-router-dom'
import { isAuthenticated } from '../ensureLogin'
import routeHeaderItems from '../constants/header/routeHeaderItems'

export default class PrivateRoute extends React.Component {
  getHeaderConfiguration () {
    const pathRoute = this.props.location.pathname.split('/')[1]
    let section = routeHeaderItems.paths.find(obj => obj.path === pathRoute)
    let headerProperties

    if (section === undefined) {
      headerProperties = routeHeaderItems.default
    } else {
      headerProperties = section.values
    }

    this.props.actions.setHeaderDataSuccess(headerProperties)
  }

  componentDidMount () {
    this.getHeaderConfiguration()
  }

  getSnapshotBeforeUpdate (nextProps) {
    if (nextProps.location.pathname !== this.props.location.pathname) {
      this.getHeaderConfiguration()
    }

    return null
  }

  componentDidUpdate () {

  }

  render () {
    const redirection = '/#' + this.props.location.pathname

    return isAuthenticated(this.props.store) ? this.props.children : <Redirect to={'/login' + redirection}></Redirect>
  }
}
