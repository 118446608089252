import React, { PureComponent } from 'react'
import { Col, FormControl, FormGroup, ControlLabel } from 'react-bootstrap'
import { formatInteger } from '../../../../util/utils'

class InputNatural extends PureComponent {
  constructor (props) {
    super()
    this.state = {
      realValue: ('' + props.input.value).trim(),
      showedValue: ('' + props.input.value).trim() !== '' ? formatInteger(props.input.value) : '',
      hasFocus: false
    }
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (prevProps.input.value !== this.props.input.value) {
      let isValid = false
      let hasFormat = false
      let realValue = ('' + this.props.input.value).trim()
      let showedValue = ('' + this.props.input.value).trim()

      // Comprobar si el valor introducido es un número natural.
      isValid = this.isValid(realValue)
      if (!isValid) {
        hasFormat = this.hasFormat(realValue)
      }

      if (isValid) {
        showedValue = !this.state.hasFocus ? formatInteger(realValue) : realValue
      } else if (hasFormat) {
        // Sanitizar: eliminar '.'.
        realValue = realValue.replace(/\./g, '')
        showedValue = !this.state.hasFocus ? formatInteger(realValue) : realValue
      } else {
        realValue = this.state.realValue
        showedValue = this.state.showedValue
      }
      this.setState({
        realValue: realValue,
        showedValue: showedValue
      })
    }
    return null
  }

  componentDidUpdate () {
    // getSnapshotBeforeUpdate() should be used with componentDidUpdate()
  }

  isValid (value) {
    return /^[\d]+$/.test(value)
  }

  hasFormat (value) {
    return /^(\d{1,3}\.(\d{3}\.)*\d{3}|\d*)$/.test(value)
  }

  handleChange (event) {
    if (this.isValid(event.target.value) || this.hasFormat(event.target.value)) {
      this.props.input.onChange(event)
      if (this.props.onInputChange) {
        this.props.onInputChange(event)
      }
    }
  }

  handleFocus (event) {
    const currentTarget = event.currentTarget
    this.setState({
      showedValue: ('' + this.state.realValue),
      hasFocus: true
    }, () => {
      // Timeout requerido en los navegadores Explorer y Edge.
      window.setTimeout(() => {
        currentTarget.select() // Seleccionar el contenido del input.
      }, 50)
    })
  }

  handleBlur (event) {
    const newState = { hasFocus: false }
    let value
    if (!isNaN(this.state.realValue)) {
      // Eliminar los ceros a la izquierda.
      value = ('' + this.state.realValue).replace(/^(0*)([1-9]\d*|\d)$/, '$2')
      newState.realValue = value
      newState.showedValue = formatInteger(value)
    }
    this.setState(newState)
  }

  render () {
    const {
      id,
      colSm = 0,
      colWidht = '25',
      meta,
      showError = false,
      rightIcon = '',
      leftIcon = '',
      controlLabel,
      componentClass,
      customClass = '',
      disabled = false,
      textAlign,
      maxLength
    } = this.props
    const errorMessage = ((showError || meta.touched) && meta.error && typeof meta.error !== 'boolean')
      ? meta.error
      : null
    return (
      <Col sm={colSm} className={'colWhidht-' + colWidht + ' ' + customClass }>
        <FormGroup
          className={rightIcon ? 'input-with-icon' : ''}
          validationState={(showError || meta.touched) && meta.error ? 'error' : null}>
          {controlLabel && <ControlLabel>{controlLabel}</ControlLabel>}
          <FormControl
            style={{ textAlign: textAlign || 'right' }}
            value={this.state.showedValue}
            id={id}
            disabled={disabled}
            type="text"
            placeholder="0"
            onChange={this.handleChange.bind(this)}
            componentClass={componentClass}
            onFocus={this.handleFocus.bind(this)}
            onBlur={this.handleBlur.bind(this)}
            maxLength={maxLength}
          />
          <i className={rightIcon || leftIcon} />
          {errorMessage && <span className='help-block text-center'>{errorMessage}</span>}
        </FormGroup>
      </Col>
    )
  }
}

export default InputNatural
