import dossierActionTypes from '../../constants/actions/dossier/dossier'
import { refreshAuditInfo, fetchAuditInfoSucces, fetchAuditDetails, fetchAuditDetailsModal, fetchDocumentsGroupedAuditDetails, fetchDocumentsGroupedAuditDetailsModal } from '../../actions/audit/auditInfo'

export function fetchPurchaseDossier (dossierId, resolve, reject, historical) {
  return {
    type: dossierActionTypes.FETCH_PURCHASE_DOSSIER,
    dossierId,
    resolve,
    reject,
    historical
  }
}

export function fetchPurchaseDossierDependants (dossierId, historical) {
  return {
    type: dossierActionTypes.FETCH_PURCHASE_DOSSIER_DEPENDANTS,
    dossierId,
    historical
  }
}

export function fetchPurchaseDossierEconomic (dossierId, historical, pristine) {
  return {
    type: dossierActionTypes.FETCH_PURCHASE_DOSSIER_ECONOMIC_PLAN,
    dossierId,
    historical,
    pristine
  }
}

export function fetchPurchaseClient (dossierId, resolve, reject, pristine) {
  return {
    type: dossierActionTypes.FETCH_PURCHASE_DOSSIER_CLIENT,
    dossierId,
    resolve,
    reject,
    pristine
  }
}
export function fetchPurchaseVehicle (dossierId, resolve, reject, pristine) {
  return {
    type: dossierActionTypes.FETCH_PURCHASE_DOSSIER_VEHICLE,
    dossierId,
    resolve,
    reject,
    pristine
  }
}
export function fetchPurchaseOtherData (dossierId, resolve, reject, pristine) {
  return {
    type: dossierActionTypes.FETCH_PURCHASE_DOSSIER_OTHER_DATA,
    dossierId,
    resolve,
    reject,
    pristine
  }
}

export function fetchPurchaseDossierSuccess (data) {
  return {
    type: dossierActionTypes.FETCH_PURCHASE_DOSSIER_SUCCESS,
    data
  }
}

export function updateDependantsPurchaseDossierAndHeader (dossierId) {
  return {
    type: dossierActionTypes.UPDATE_DEPENDANTS_PURCHASE_DOSSIER_AND_HEADER,
    dossierId
  }
}

export function restartPurchaseState () {
  return {
    type: dossierActionTypes.RESTART_PURCHASE_STATE
  }
}

export function purchaseReject (comment, resolve, reject, pristine) {
  return {
    type: dossierActionTypes.PURCHASE_REJECT,
    comment,
    resolve,
    reject,
    pristine
  }
}

export function purchaseSendApprove (pristine) {
  return {
    type: dossierActionTypes.PURCHASE_SEND_APPROVE,
    pristine
  }
}

export function purchaseApprove (pristine) {
  return {
    type: dossierActionTypes.PURCHASE_APPROVE,
    pristine
  }
}

export function purchaseApproveManager (pristine) {
  return {
    type: dossierActionTypes.PURCHASE_APPROVE_MANAGER_VO,
    pristine
  }
}

export function purchaseApproveSalesManagerVo (pristine) {
  return {
    type: dossierActionTypes.PURCHASE_APPROVE_SALES_MANGER_VO,
    pristine
  }
}

export function savePurchaseDossier (purchaseDossier) {
  return {
    type: dossierActionTypes.SAVE_PURCHASE_DOSSIER,
    purchaseDossier
  }
}

export function fetchDossierRelatedDossiersSuccess (dossierRelatedDossiers) {
  return {
    type: dossierActionTypes.FETCH_DOSSIER_RELATED_DOSSIERS_SUCCESS,
    dossierRelatedDossiers
  }
}

export function purchaseWarningSave (reset, router, nextLocation, pristine) {
  return {
    type: dossierActionTypes.PURCHASE_WARNING_SAVE,
    reset,
    router,
    nextLocation,
    pristine
  }
}

export function synchroniseAvailabilityDate (availabilityDate) {
  return {
    type: dossierActionTypes.PURCHASE_SYNCHRONISE_AVAILABILITY_DATE,
    availabilityDate
  }
}
export function fetchPurchaseVehicleHistorical (dossierId, resolve, reject) {
  return {
    type: dossierActionTypes.FETCH_PURCHASE_DOSSIER_VEHICLE_HISTORICAL,
    dossierId,
    resolve,
    reject
  }
}

export function fetchPurchaseClientHistorical (dossierId, resolve, reject) {
  return {
    type: dossierActionTypes.FETCH_PURCHASE_DOSSIER_CLIENT_HISTORICAL,
    dossierId,
    resolve,
    reject
  }
}

export function fetchPurchaseOtherDataHistorical (dossierId, resolve, reject, pristine) {
  return {
    type: dossierActionTypes.FETCH_PURCHASE_DOSSIER_OTHERDATA_HISTORICAL,
    dossierId,
    resolve,
    reject,
    pristine
  }
}

export function updatePurchasePresentSuccess (purchasePresents) {
  return {
    type: dossierActionTypes.UPDATE_PURCHASE_PRESENTS_SUCCESS,
    purchasePresents
  }
}

export function updatePurchasePresent (present, values, resolve, reject) {
  return {
    type: dossierActionTypes.UPDATE_PURCHASE_PRESENT,
    present,
    values,
    resolve,
    reject
  }
}

export function createPurchasePresent (newPresent, values, resolve, reject) {
  return {
    type: dossierActionTypes.CREATE_PURCHASE_PRESENT,
    newPresent,
    values,
    resolve,
    reject
  }
}

export function deletePurchasePresent (present, values, isSoftDelete, rowIndex) {
  return {
    type: dossierActionTypes.DELETE_PURCHASE_PRESENT,
    present,
    values,
    isSoftDelete,
    rowIndex
  }
}

export function recoverPurchasePresent (present, values, rowIndex) {
  return {
    type: dossierActionTypes.RECOVER_PURCHASE_PRESENT,
    present,
    values,
    rowIndex
  }
}

export function checkPurchaseTypeAndSetDefaultEntity (typeId, ouId) {
  return {
    type: dossierActionTypes.CHECK_PUCHASE_TYPE_SET_DEFAULT_ENTITY,
    typeId,
    ouId
  }
}

export function launchCampaignProcessFiles () {
  return {
    type: dossierActionTypes.LAUNCH_CAMPAIGN_PROCESS_FILES
  }
}

export function launchCampaignProcessFilesSucces (data) {
  return {
    type: dossierActionTypes.LAUNCH_CAMPAIGN_PROCESS_FILES_SUCCES,
    data
  }
}

export function fetchVehiclePurchaseComponent (vinChassis, licensePlate, organizedUnitId) {
  return {
    type: dossierActionTypes.FETCH_VEH_PURCH_CHASIS_LICENSE,
    vinChassis,
    licensePlate,
    organizedUnitId
  }
}

export function purchaseTransferRequest (pristine) {
  return {
    type: dossierActionTypes.PURCHASE_TRANSFER_REQUEST,
    pristine
  }
}

export function purchaseTransferRequestApproved (pristine) {
  return {
    type: dossierActionTypes.PURCHASE_TRANSFER_REQUEST_APPROVED,
    pristine
  }
}

export function fetchPurchaseCatalogueModel (catalogueId, modelId) {
  return {
    type: dossierActionTypes.FETCH_VEHICLE_CATALOGUE_MODEL,
    catalogueId,
    modelId
  }
}

export function fetchPurchaseCatalogueModelSuccess (data) {
  return {
    type: dossierActionTypes.FETCH_VEHICLE_CATALOGUE_MODEL_SUCCESS,
    data
  }
}

export function fetchPurchaseDefaultDestination (value) {
  return {
    type: dossierActionTypes.FETCH_PURCHASE_DEFAULT_DESTINATION,
    value
  }
}

export function refreshPurchasePresentFromDocumentModal (purchasePresentId, pristine) {
  return {
    type: dossierActionTypes.REFRESH_PURCHASE_PRESENT_FROM_DOCUMENT_MODAL,
    purchasePresentId,
    pristine
  }
}

export function transferReject (comment, resolve, reject, pristine) {
  return {
    type: dossierActionTypes.PURCHASE_TRANSFER_REJECT,
    comment,
    resolve,
    reject,
    pristine
  }
}

export default {
  fetchPurchaseDossier,
  fetchPurchaseDossierDependants,
  fetchPurchaseDossierEconomic,
  fetchPurchaseDossierSuccess,
  restartPurchaseState,
  fetchPurchaseVehicleHistorical,
  fetchPurchaseClientHistorical,
  purchaseReject,
  purchaseSendApprove,
  purchaseApprove,
  savePurchaseDossier,
  fetchDossierRelatedDossiersSuccess,
  purchaseWarningSave,
  synchroniseAvailabilityDate,
  updateDependantsPurchaseDossierAndHeader,
  refreshAuditInfo,
  fetchAuditInfoSucces,
  fetchPurchaseClient,
  fetchPurchaseVehicle,
  fetchPurchaseOtherData,
  fetchPurchaseOtherDataHistorical,
  updatePurchasePresentSuccess,
  updatePurchasePresent,
  createPurchasePresent,
  deletePurchasePresent,
  fetchAuditDetails,
  fetchAuditDetailsModal,
  launchCampaignProcessFiles,
  launchCampaignProcessFilesSucces,
  checkPurchaseTypeAndSetDefaultEntity,
  fetchVehiclePurchaseComponent,
  purchaseTransferRequest,
  purchaseTransferRequestApproved,
  purchaseApproveManager,
  purchaseApproveSalesManagerVo,
  fetchPurchaseCatalogueModel,
  fetchPurchaseCatalogueModelSuccess,
  fetchPurchaseDefaultDestination,
  fetchDocumentsGroupedAuditDetails,
  fetchDocumentsGroupedAuditDetailsModal,
  recoverPurchasePresent,
  refreshPurchasePresentFromDocumentModal,
  transferReject
}
