import React, { PureComponent } from 'react'
import { Table, Row, Col, OverlayTrigger, Tooltip, Button } from 'react-bootstrap'
import SourceChannelModalPage from '../../../containers/masters/SourceChannelModalPage'

class EntityMasters extends PureComponent {
  render () {
    const {
      t, sourceChannelList,
      actions: { deleteSourceChannelModal, openSourceChannelModal }
    } = this.props
    const tKey = 'MASTERS.ENTITY_MASTERS.'
    const editTooltip = (<Tooltip id="editTooltip">{t('MASTERS.EDIT')}</Tooltip>)
    const deleteTooltip = (<Tooltip id="deleteTooltip">{t('MASTERS.DELETE')}</Tooltip>)
    return (
      <Row>
        <Col sm={12}>
          <div className="header-panel">
            <h4>{t(`${tKey}SOURCE_CHANNEL`)}</h4>
          </div>
        </Col>
        <SourceChannelModalPage />
        <Col sm={12}>
          <Table className="editable-table" hover responsive>
            <thead>
              <tr>
                <th>{t('MASTERS.ID')}</th>
                <th>{t('MASTERS.NAME')}</th>
                <th>{t('MASTERS.DESCRIPTION')}</th>
                <th style={{ width: '77px' }}>{t('DOSSIER_COMPONENTS.ACCESSORIES.ACTIONS')}</th>
              </tr>
            </thead>
            <tbody>
              {sourceChannelList.map((sourceChannel, idx) => (
                <tr key={idx}>
                  <td>{sourceChannel.sourceChannelId}</td>
                  <td>{sourceChannel.name}</td>
                  <td>{sourceChannel.description}</td>
                  <td>
                    <a onClick={() => openSourceChannelModal(sourceChannel.sourceChannelId)}>
                      <OverlayTrigger placement="left" overlay={editTooltip}>
                        <i className="ico-edit-white"/>
                      </OverlayTrigger>
                    </a>
                    <a onClick={() => deleteSourceChannelModal(sourceChannel.sourceChannelId)}>
                      <OverlayTrigger placement="left" overlay={deleteTooltip}>
                        <i className="ico-trash"/>
                      </OverlayTrigger>
                    </a>
                  </td>
                </tr>
              ))}
              <tr className="button-table-wrapper">
                <td>
                  <Button
                    className="btn-editable-table btn-standard"
                    onClick={() => openSourceChannelModal()}>
                  + {t('MASTERS.ADD')}
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    )
  }
}

export default EntityMasters
