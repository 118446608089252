import React, { PureComponent } from 'react'
import DocumentThumbnail from './DocumentThumbnail'

class DocumentItem extends PureComponent {
  render () {
    const {
      t, documentFile, readOnly, documentEntityType, objectId,
      actions: {
        openDocumentEditionModalWithChecklistId,
        cancelDocument,
        addDependant,
        printCheckList,
        openModal
      }
    } = this.props
    return (
      <div className="wrapper-document">
        <DocumentThumbnail
          t={t}
          documentFile={documentFile}
          readOnly={readOnly}
          objectId={objectId}
          documentEntityType={documentEntityType}
          actions={{ openDocumentEditionModalWithChecklistId, cancelDocument, addDependant, printCheckList, openModal }}
        />
        <span className="block-with-text">{documentFile.documentName}</span>
      </div>
    )
  }
}

export default DocumentItem
