import React, { PureComponent } from 'react'
import { Panel, Button } from 'react-bootstrap'
import { permissions } from '../../../../constants/backendIds'
import { getFieldsPermisions } from '../../../../util/permisionFunctions'
import { compareObjects, getDateNowMaxDatePicker } from '../../../../util/utils'
import InputDatePicker from '../../../../_v2/components/commons/form/InputDatePicker'
import { Field } from 'redux-form'
import ComponentTitle from '../../../../_v2/containers/dossiers/commons/ComponentTitlePage'

export default class Reconditioning extends PureComponent {
  constructor () {
    super()
    this.state = {
      open: false,
      fieldsConfiguration: {
        confirmationDoneButton: null,
        confirmationDate: null
      }
    }
  }

  initializePermisions (props) {
    const fieldKeys = Object.keys(this.state.fieldsConfiguration)
    const newState = getFieldsPermisions(props.componentConfiguration.fieldsConfiguration, fieldKeys, props.componentConfiguration.permission)
    this.setState({ fieldsConfiguration: newState.fieldsConfiguration })
  }

  toggleComponent () {
    if (this.state.open) {
      this.props.actions.setActiveSection(false)
    }
    this.setState({ open: !this.state.open })
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (this.props.dossier && this.props.dossier.dossierId) {
      if (!compareObjects(prevProps.componentConfiguration || {}, this.props.componentConfiguration, true)) {
        this.initializePermisions(this.props)
      }
      if (this.props.open) {
        this.setState({ open: true })
      }
    }
    return prevProps
  }

  componentDidUpdate () {
    // don't remove use for getsnapshot
  }

  componentDidMount () {
    this.initializePermisions(this.props)
  }

  render () {
    const {
      t, dossierId, actions: { confirmDateWithIdByStage }, dossier: { reconditioning }
    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.RECONDITIONING.'

    return (
      <div className="dossier-Component">
        <div className="header-panel" onClick={() => this.toggleComponent()}>
          <ComponentTitle
            componentCode="RECONDITIONING"
            componentOpen={this.state.open}
            // extraInfo={'info extra'}
            // extraData={<a className="_Btn accept">texto</a>}
          />
        </div>
        <Panel expanded={this.state.open} onToggle={() => {}}>
          <Panel.Collapse>
            {this.state.fieldsConfiguration.confirmationDoneButton && this.state.fieldsConfiguration.confirmationDoneButton.permission === permissions.editable && !reconditioning?.confirmationDate && (
              <div className='flexInLine al-R'>
                <Button className='_Btn new' onClick={() => confirmDateWithIdByStage(dossierId)} >
                  {t(`${tKey}PERFORM`)}
                </Button>
              </div>
            )}
            {this.state.fieldsConfiguration.confirmationDate && this.state.fieldsConfiguration.confirmationDate.permission !== permissions.hidden && (
              <div className='fieldsContainer'>
                <Field
                  component={InputDatePicker}
                  colWidht={33} // 25, 33, 50, 75, 100 default=25
                  maxDate={getDateNowMaxDatePicker()}
                  id="confirmationDate"
                  key="confirmationDate"
                  name="reconditioning.confirmationDate"
                  controlLabel={t(`${tKey}CONFIRM_DATE`)}
                  placeholder={t(`${tKey}CONFIRM_DATE`)}
                  disabled={true}
                  customClass={'disabled'}
                />
              </div>
            )}
          </Panel.Collapse>
        </Panel>
      </div>
    )
  }
}
