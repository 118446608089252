import { put, call, all, select, takeEvery } from 'redux-saga/effects'
import { change, reset, initialize } from 'redux-form'
// import { replace } from 'react-router-redux'
import { replace } from 'connected-react-router'
import { permissions, entityTypesId, recurrentEntitySubTypes } from '../../../constants/backendIds'
import { documentEntityType as documentEntityTypeConstant } from '../../../constants/dossier/common/documentEntityType'
import { buttonsHeader, buttonsHeaderPermisionsIdReverse } from '../../../constants/dossier/common/buttonsHeader'
import dossierActionsTypes from '../../../constants/actions/dossier/dossier'
import { getAuth } from '../../../selectors/access/auth'
import { getActiveTab } from '../../../selectors/dossier/common/buttonsHeader'
import { getDossierRelatedDossiersConf } from '../../../selectors/dossier/common/dossierRelatedDossiers'
import { showSmallLoader, hideSmallLoader, showLoader, hideLoader } from '../../../actions/common'
import { refreshDocumentManagementModal } from '../../../actions/dossier/common/documentManagementModal'
import { fetchRegistrationDeliveryDossierSuccess, fetchRegistrationDeliveryDossierEconomicSuccess } from '../../../actions/dossier/registrationDelivery'
import { fetchCombosSuccess } from '../../../actions/combos/combos'
import messageModalActions from '../../../actions/modals/messageModal'
import { setActiveTab } from '../../../actions/dossier/common/buttonsHeader'
import getDossierById from '../../../services/dossier/get/getRegistrationDeliveryDossierById'

import getHistoricalDossierById from '../../../services/dossier/get/getRegistrationDeliveryHistoricalDossierById'
import getDossierDependantsById from '../../../services/dossier/get/getRegistrationDeliveryDossierDependantsById'
import getRegistrationDeliveryHistoricalDossierDependantsById from '../../../services/dossierregistrationDelivery/getRegistrationDeliveryHistoricalDossierDependantsById'

// Header Component
import getStageCheckList from '../../../services/dossier/get/getStageCheckList'
import { switchFavoriteSuccess } from '../../../actions/dossier/common/statusHeader'
// Sales component
import getSaleTypes from '../../../services/saleType/getSalesTypes'
import getExtraSaleTypes from '../../../services/extraSale/getExtraSaleTypes'
import getPresentTypes from '../../../services/present/getPresentTypes'
import getAssociatedCostsTypes from '../../../services/associatedCost/getAssociatedCostsTypes'
import getSuppliedTypes from '../../../services/supplied/getSuppliedTypes'
import getOperationTypes from '../../../services/saleType/getOperationType'
import getSalesman from '../../../services/salesman/getSalesman'
import getSalesmanLogic from '../../../services/salesman/getSalesmanLogic'
import getPaymentMethod from '../../../services/paymentMethod/getPaymentMethod'
import getDocumentaryCheckList from '../../../services/document/getDocumentaryCheckList'

import getRegistrationDeliveryHeaderAndButtons from '../../../services/dossier/get/getRegistrationDeliveryHeaderAndButtons'
import getAdditionalInfo from '../../../services/additionalInfo/getAdditionalInfo'
// Client component
import getEntityTypes from '../../../services/entityType/getEntityTypes'
import getChannels from '../../../services/sourcechannel/getChanel'
import getSegmentation from '../../../services/segmentation/getSegmentation'
import getDossierEntityService from '../../../services/dossier/get/getDossierEntity'
import getDossierEntityChildService from '../../../services/dossier/get/getDossierEntityChild'
import getDossierHistoricalEntity from '../../../services/dossier/get/getDossierHistoricalEntity'
import getDossierHistoricalEntityChild from '../../../services/dossier/get/getDossierHistoricalEntityChild'
import getEntityById from '../../../services/entity/getEntityById'
import getEntitySubType from '../../../services/entitySubType/getEntitySubType'
import getEntityFilterSubType from '../../../services/entity/getEntityFilterSubType'
import { fetchEntitySubTypeCombo } from '../../../sagas/combos/fetchEntitySubTypeCombo'
import { fetchEntityFilterSubTypeCombo } from '../../../sagas/combos/fetchEntityFilterSubTypeCombo'
// Vehicle component
import getBrands from '../../../services/brand/getBrands'
import getFuels from '../../../services/fuel/getFuels'
import getPreviousUsage from '../../../services/previousUsage/getPreviousUsage'
import getPurchaseWithVehicleById from '../../../services/dossier/get/getPurchaseWithVehicleById'
import getHistoricalPurchaseWithVehicleById from '../../../services/dossier/get/getHistoricalPurchaseWithVehicleById'

// Services component
// import getServiceType from '../../services/serviceType/getServiceType'
// Dossier type subtipe
import getDossierSubTypeByTypeAndUo from '../../../services/dossierType/getDossierSubTypeByTypeAndUo'
// Finance component
import getFinanceCompaniesService from '../../../services/financeCompany/getFinanceCompanies'
import getFinancingTypesService from '../../../services/financingType/getFinancingTypes'
import getFinancingStatusService from '../../../services/financingStatus/getFinancingStatus'
// Related component
import getDossierRelatedDossiers from '../../../services/dossier/get/getDossierRelatedDossiers'
import getDossierHistoricalRelatedDossiers from '../../../services/dossier/get/getDossierHistoricalRelatedDossiers'
// Iva Type
import getIvaType from '../../../services/ivaType/getIvaType'
// Iem Type
import getIemType from '../../../services/iemType/getIemType'
// LaterUse
import getLaterUse from '../../../services/laterUse/getLaterUse'
// ActivityClient
import getActivityClient from '../../../services/activityClient/getActivityClient'
// vehicleType
import getVehicleType from '../../../services/vehicleType/getVehicleType'
import { handleError } from '../../errors/errorManager'
// SaleRequestModal
import getPurchaseTypesByDossierSubType from '../../../services/purchaseType/getPurchaseTypesByDossierSubType'
import getTuneUpDestination from '../../../services/tuneUpDestination/getTuneUpDestination'
// Contact
import getDossierContact from '../../../services/dossier/get/getDossierContact'
import getDossierContactHistorical from '../../../services/dossier/get/getDossierContactHistorical'
// Financing
import getDossierFinancing from '../../../services/dossier/get/getDossierFinancing'
import getDossierFinancingHistorical from '../../../services/dossier/get/getDossierFinancingHistorical'
import getRegistrationDeliveryEconomicPlan from '../../../services/dossier/get/getRegistrationDeliveryEconomicPlan'
import getRegistrationDeliveryEconomicPlanHistorical from '../../../services/dossier/get/getRegistrationDeliveryEconomicPlanHistorical'
// Delivery
import getDossierDelivery from '../../../services/dossier/get/getDossierDelivery'
import getDossierDeliveryHistorical from '../../../services/dossier/get/getDossierDeliveryHistorical'

// Other data
import getDossierOtherData from '../../../services/dossier/get/getDossierOtherData'
import getDossierOtherDataHistorical from '../../../services/dossier/get/getDossierOtherDataHistorical'
import getMaritalStatus from '../../../services/maritalStatus/getMaritalStatus'

export function * fetchDependantsRegistrationDeliveryDossier ({ dossierId, historical }) {
  try {
    yield put(showSmallLoader())
    const auth = yield select(getAuth)
    let dependants = {}
    if (historical) {
      dependants = yield call(getRegistrationDeliveryHistoricalDossierDependantsById, dossierId, auth.token)
      dependants.dossierEconomicComponent = { charges: [], campaigns: [], discounts: [], extraRegistrationDelivery: [], optionals: [], presents: [], associatedCosts: [], specialHelps: [], supplieds: [], paperworks: [], iemData: {} }
      yield put(fetchRegistrationDeliveryDossierSuccess(dependants))
    } else {
      dependants = yield call(getDossierDependantsById, dossierId, auth.token)
      yield put(fetchRegistrationDeliveryDossierSuccess(dependants))
    }
  } catch (error) {
    const sessionHandler = function * () {
      yield put(reset('registrationDeliveryDossier'))
    }
    yield call(handleError, { error, sessionHandler })
  } finally {
    yield put(hideSmallLoader())
  }
}
export function * watchFetchRegistrationDeliveryDossierDependants () {
  yield takeEvery(dossierActionsTypes.FETCH_REGISTRATION_DELIVERY_DOSSIER_DEPENDANTS, fetchDependantsRegistrationDeliveryDossier)
}

function * fetchRegistrationDeliveryDossierHistorical (dossierId, auth) {
  const dossierRelatedDossiersConf = yield select(getDossierRelatedDossiersConf)
  const registrationDeliveryDossier = yield select(state => state.registrationDeliveryDossier)
  const [
    dossier,
    entityChildComponent,
    paymentMethodCombo,
    channelCombo,
    maritalStatusCombo,
    segmentationCombo,
    brandCombo,
    fuelCombo,
    dossierRelatedDossiers
  ] = yield all([
    call(getHistoricalDossierById, dossierId, auth.token),
    call(getDossierHistoricalEntityChild, dossierId, auth.token),
    call(getPaymentMethod, auth.token, true),
    call(getChannels, auth.token),
    call(getMaritalStatus, auth.token),
    call(getSegmentation, auth.token),
    call(getBrands, auth.token),
    call(getFuels, auth.token, true),
    call(getDossierHistoricalRelatedDossiers, dossierId, dossierRelatedDossiersConf.orderby, auth.token)
  ])
  const entityTypeCombo = yield call(getEntityTypes, auth.token, dossier.dossierSubTypeId)
  let entityComponent = registrationDeliveryDossier.entityComponent
  entityComponent = { ...entityComponent, ...dossier.dossierEntityComponent }
  entityChildComponent.previousEntity = { ...entityChildComponent }
  const [
    saleTypeCombo, salesmanCombo, salesmanComboLogic, previousUsageCombo, financialInstitutionCombo, serviceTypeCombo, financingTypeCombo, financingStatusCombo, ivaTypeCombo, iemType,
    extraSaleTypeCombo, presentTypeCombo, suppliedTypeCombo, laterUseCombo, activityClientCombo, vehicleType, additionalInfoCombo, entityFilterSubType, entitySubTypeCombo, tuneUpDestinationCombo, associatedCostTypeCombo
  ] = yield all([
    call(getSaleTypes, dossier.dossierId, auth.token, true),
    call(getSalesman, dossier.organizedUnitId, auth.token),
    call(getSalesmanLogic, dossier.organizedUnitId, auth.token),
    call(getPreviousUsage, dossier.organizedUnitId, auth.token),
    call(getDossierSubTypeByTypeAndUo, dossierId, dossier.organizedUnitId, auth.token),
    call(getLaterUse, auth.token),
    call(getActivityClient, auth.token),
    call(getVehicleType, auth.token),
    call(getEntityFilterSubType, auth.token, entityTypesId.recurrentEntity, recurrentEntitySubTypes.renting, dossier.organizedUnitId),
    call(getEntitySubType, auth.token, entityComponent.entityTypeId)
  ])

  let operationTypeCombo = []
  if (dossier.dossierSaleComponent && dossier.dossierSaleComponent.saleTypeId) {
    operationTypeCombo = yield call(getOperationTypes, dossier.dossierSaleComponent.saleTypeId, dossier.organizedUnitId, auth.token)
  }

  let entityFilterSubTypeCombo
  if (entityComponent.entityTypeId && entityComponent.entitySubTypeId) {
    entityFilterSubTypeCombo = yield call(getEntityFilterSubType, auth.token, entityComponent.entityTypeId, entityComponent.entitySubTypeId, dossier.organizedUnitId)
  }

  const dossierContactComponent = registrationDeliveryDossier.dossierContactComponent

  let vehicleComponent = registrationDeliveryDossier.vehicleComponent
  vehicleComponent = { ...entityComponent, ...dossier.dossierVehicleComponent }
  let dossierCompleteDeliveryComponent = registrationDeliveryDossier.dossierCompleteDeliveryComponent.dossierDeliveryComponent

  yield put(switchFavoriteSuccess(dossier.dossierHeaderComponent.favorite))

  yield put(fetchCombosSuccess({
    financialInstitutionCombo,
    financingTypeCombo,
    financingStatusCombo,
    saleTypeCombo,
    operationTypeCombo,
    salesmanCombo,
    salesmanComboLogic,
    paymentMethodCombo,
    entityTypeCombo,
    channelCombo,
    maritalStatusCombo,
    segmentationCombo,
    previousUsageCombo,
    brandCombo,
    fuelCombo,
    serviceTypeCombo,
    ivaTypeCombo,
    iemType,
    extraSaleTypeCombo,
    presentTypeCombo,
    suppliedTypeCombo,
    laterUseCombo,
    activityClientCombo,
    vehicleType,
    additionalInfoCombo,
    entityFilterSubType,
    entitySubTypeCombo,
    entityFilterSubTypeCombo,
    tuneUpDestinationCombo,
    associatedCostTypeCombo
  }))

  yield put(fetchRegistrationDeliveryDossierSuccess({ ...dossier, checkListHeader: [], entityComponent, entityChildComponent, dossierContactComponent, dossierCompleteDeliveryComponent, vehicleComponent, RegistrationDeliveryDocumentaryCheckList: [], dossierRelatedDossiers }))
}
export function * fetchRegistrationDeliveryDossier ({ dossierId, resolve, reject, historical }) {
  try {
    yield put(showLoader())
    const registrationDeliveryDossier = yield select(state => state.registrationDeliveryDossier)
    const auth = yield select(getAuth)
    if (historical) {
      yield call(fetchRegistrationDeliveryDossierHistorical, dossierId, auth)
    } else {
      const dossierRelatedDossiersConf = yield select(getDossierRelatedDossiersConf)
      const dossier = yield call(getDossierById, dossierId, auth.token)

      let entityComponent = registrationDeliveryDossier.entityComponent
      entityComponent = { ...entityComponent, ...dossier.dossierEntityComponent }
      if (registrationDeliveryDossier.entityComponent.updated === true) {
        entityComponent = yield call(getDossierEntityService, dossierId, auth.token)
        entityComponent.updated = true
        entityComponent.previousEntity = { ...entityComponent }
      }

      let entityTypeIdDefault = dossier.dossierEntityComponent ? dossier.dossierEntityComponent.entityTypeIdDefault : null

      const [
        entityChildComponent, checkListHeader, paymentMethodCombo, RegistrationDeliveryDocumentaryCheckList, entityTypeCombo, channelCombo, maritalStatusCombo, segmentationCombo,
        brandCombo, fuelCombo, dossierRelatedDossiers, entityTransferCombo, tuneUpDestinationCombo
      ] = yield all([
        call(getDossierEntityChildService, dossierId, auth.token),
        call(getStageCheckList, dossierId, auth.token),
        call(getPaymentMethod, auth.token),
        call(getDocumentaryCheckList, auth.token, dossierId),
        call(getEntityTypes, auth.token, dossier.dossierSubTypeId),
        call(getChannels, auth.token),
        call(getMaritalStatus, auth.token),
        call(getSegmentation, auth.token),
        call(getBrands, auth.token, dossier.dossierSubTypeId, dossier.organizedUnitId),
        call(getFuels, auth.token),
        call(getDossierRelatedDossiers, dossierId, dossierRelatedDossiersConf.orderby, auth.token),
        call(getEntityFilterSubType, auth.token, entityTypesId.recurrentEntity, recurrentEntitySubTypes.distributor, dossier.organizedUnitId),
        call(getTuneUpDestination, auth.token)
      ])

      entityChildComponent.previousEntity = { ...entityChildComponent }

      let dossierContactComponent = registrationDeliveryDossier.dossierContactComponent
      dossierContactComponent = { ...dossierContactComponent, ...dossier.dossierContactComponent }
      if (registrationDeliveryDossier.dossierContactComponent.updated === true) {
        dossierContactComponent = yield call(getDossierContact, dossierId, auth.token)
        dossierContactComponent.updated = true
      }
      const [
        saleTypeCombo, salesmanCombo, salesmanComboLogic, previousUsageCombo, financialInstitutionCombo,
        serviceTypeCombo, financingTypeCombo, financingStatusCombo, ivaTypeCombo, iemType, extraSaleTypeCombo, presentTypeCombo,
        suppliedTypeCombo, laterUseCombo, activityClientCombo, vehicleType, additionalInfoCombo, entityFilterSubType, entitySubTypeCombo, purchaseTypeVnCombo,
        associatedCostTypeCombo
      ] = yield all([
        call(getSaleTypes, dossier.dossierId, auth.token),
        call(getSalesman, dossier.organizedUnitId, auth.token),
        call(getSalesmanLogic, dossier.organizedUnitId, auth.token),
        call(getPreviousUsage, dossier.organizedUnitId, auth.token),
        call(getFinanceCompaniesService, dossier.organizedUnitId, auth.token),
        call(getDossierSubTypeByTypeAndUo, dossierId, dossier.organizedUnitId, auth.token),
        call(getFinancingTypesService, dossier.organizedUnitId, auth.token),
        call(getFinancingStatusService, auth.token),
        call(getIvaType, auth.token, dossier.dossierId, false),
        call(getIemType, auth.token),
        call(getExtraSaleTypes, dossier.organizedUnitId, auth.token),
        call(getPresentTypes, dossier.organizedUnitId, auth.token),
        call(getSuppliedTypes, dossier.organizedUnitId, auth.token),
        call(getLaterUse, auth.token),
        call(getActivityClient, auth.token),
        call(getVehicleType, auth.token),
        call(getAdditionalInfo, auth.token, dossier.dossierSubTypeId),
        call(getEntityFilterSubType, auth.token, entityTypesId.recurrentEntity, recurrentEntitySubTypes.renting, dossier.organizedUnitId),
        call(getEntitySubType, auth.token, entityComponent.entityTypeId || entityTypeIdDefault),
        call(getPurchaseTypesByDossierSubType, auth.token, dossier.dossierSubTypeId),
        call(getAssociatedCostsTypes, dossier.organizedUnitId, auth.token)
      ])

      const operationTypeIdsSorted = dossier.dossierSaleComponent && dossier.dossierSaleComponent.operationTypeIds ? dossier.dossierSaleComponent.operationTypeIds.sort() : []

      let operationTypeCombo = []
      if (dossier.dossierSaleComponent && dossier.dossierSaleComponent.saleTypeId) {
        operationTypeCombo = yield call(getOperationTypes, dossier.dossierSaleComponent.saleTypeId, dossier.organizedUnitId, auth.token, false, operationTypeIdsSorted)
      }

      let entityFilterSubTypeCombo = []
      if (entityComponent.entityTypeId && entityComponent.entitySubTypeId) {
        entityFilterSubTypeCombo = yield call(getEntityFilterSubType, auth.token, entityComponent.entityTypeId, entityComponent.entitySubTypeId, dossier.organizedUnitId)
      }

      let vehicleComponent = registrationDeliveryDossier.vehicleComponent
      vehicleComponent = { ...vehicleComponent, ...dossier.dossierVehicleComponent }
      if (registrationDeliveryDossier.vehicleComponent.updated === true) {
        vehicleComponent = dossier.dossierParentId ? yield call(getPurchaseWithVehicleById, dossierId, auth.token) : {}
        vehicleComponent.updated = true
      }
      let dossierCompleteOtherDataComponent = registrationDeliveryDossier.dossierCompleteOtherDataComponent
      if (dossierCompleteOtherDataComponent.updated === true) {
        dossierCompleteOtherDataComponent = yield call(getDossierOtherData, dossierId, auth.token)
        dossierCompleteOtherDataComponent.updated = true
      } else {
        dossierCompleteOtherDataComponent = dossier.dossierCompleteOtherDataComponent
      }

      let dossierFinancingComponent = registrationDeliveryDossier.dossierFinancingComponent
      if (dossierFinancingComponent.updated === true) {
        dossierFinancingComponent = yield call(getDossierFinancing, dossierId, auth.token)
        dossierFinancingComponent.updated = true
      }
      let dossierCompleteDeliveryComponent = null
      if (dossier.dossierCompleteDeliveryComponent) {
        dossierCompleteDeliveryComponent = dossier.dossierCompleteDeliveryComponent
        if (dossierCompleteDeliveryComponent.dossierDeliveryComponent !== registrationDeliveryDossier.dossierCompleteDeliveryComponent.dossierDeliveryComponent) {
          dossierCompleteDeliveryComponent.dossierDeliveryComponent.updated = true
        }
      } else {
        dossierCompleteDeliveryComponent = registrationDeliveryDossier.dossierCompleteDeliveryComponent
      }
      if (dossierCompleteDeliveryComponent.dossierDeliveryComponent && dossierCompleteDeliveryComponent.dossierDeliveryComponent.updated === true) {
        let newDossierCompleteDeliveryComponent = yield call(getDossierDelivery, dossierId, auth.token)
        dossierCompleteDeliveryComponent = { ...dossierCompleteDeliveryComponent, dossierDeliveryComponent: newDossierCompleteDeliveryComponent.dossierDeliveryComponent }
        dossierCompleteDeliveryComponent.dossierDeliveryComponent.updated = true
      }

      entityComponent.autoLoadedEntity = false

      yield put(fetchCombosSuccess({
        financialInstitutionCombo,
        financingTypeCombo,
        financingStatusCombo,
        saleTypeCombo,
        operationTypeCombo,
        salesmanCombo,
        salesmanComboLogic,
        paymentMethodCombo,
        entityTypeCombo,
        channelCombo,
        maritalStatusCombo,
        segmentationCombo,
        previousUsageCombo,
        brandCombo,
        fuelCombo,
        serviceTypeCombo,
        ivaTypeCombo,
        iemType,
        extraSaleTypeCombo,
        presentTypeCombo,
        suppliedTypeCombo,
        laterUseCombo,
        activityClientCombo,
        vehicleType,
        additionalInfoCombo,
        entityTransferCombo,
        purchaseTypeVnCombo,
        entityFilterSubType,
        entitySubTypeCombo,
        entityFilterSubTypeCombo,
        tuneUpDestinationCombo,
        associatedCostTypeCombo
      }))

      yield put(fetchRegistrationDeliveryDossierSuccess({ ...dossier, checkListHeader, entityComponent, dossierFinancingComponent, dossierCompleteOtherDataComponent, entityChildComponent, vehicleComponent, RegistrationDeliveryDocumentaryCheckList, dossierRelatedDossiers, dossierContactComponent, dossierCompleteDeliveryComponent, entityTypeIdDefault }))

      const currentActiveTab = yield select(getActiveTab)
      let nextActiveTab = dossier.viewConfiguration.tabsConfiguration.filter((tabConf) => tabConf.permission > permissions.hidden)
      nextActiveTab = nextActiveTab.length > 0 ? nextActiveTab[0] : currentActiveTab
      if (nextActiveTab !== currentActiveTab) yield put(setActiveTab(buttonsHeaderPermisionsIdReverse[nextActiveTab.code]))
      if (!historical && nextActiveTab === buttonsHeader.gestor_documental) {
        yield put(refreshDocumentManagementModal(dossierId))
      }
    }
    if (resolve) yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.status === 301) {
        // yield put(replace(`/dossier${customHandlerError.json}`))
        yield put(replace(`/dossier${customHandlerError.json}`))
      } else {
        yield put(messageModalActions.openErrorMessageModal('MESSAGES.REGISTRATION_DELIVERY_DOSSIER.ERROR_GET_REGISTRATION_DELIVERY_DOSSIER', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
      if (reject) yield call(reject, customHandlerError)
    }
    const sessionHandler = function * () {
      yield put(reset('registrationDeliveryDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchRegistrationDeliveryDossier () {
  yield takeEvery(dossierActionsTypes.FETCH_REGISTRATION_DELIVERY_DOSSIER, fetchRegistrationDeliveryDossier)
}

export function * fetchRegistrationDeliveryVehicle ({ dossierId, resolve, reject, pristine }) {
  try {
    yield put(showSmallLoader())
    const auth = yield select(getAuth)
    const registrationDeliveryDossier = yield select(state => state.registrationDeliveryDossier)
    let vehicleComponent = yield call(getPurchaseWithVehicleById, dossierId, auth.token)

    if (vehicleComponent && !vehicleComponent.vehicleId) {
      vehicleComponent = {}
    }
    vehicleComponent.updated = true

    yield put(change('registrationDeliveryDossier', 'vehicleComponent', vehicleComponent))
    yield put(fetchRegistrationDeliveryDossierSuccess({ vehicleComponent }))
    if (pristine === true || pristine === undefined) {
      yield put(initialize('registrationDeliveryDossier', { ...registrationDeliveryDossier, vehicleComponent: vehicleComponent }))
    }

    if (resolve) yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.REGISTRATION_DELIVERY_DOSSIER.ERROR_GET_REGISTRATION_DELIVERY_DOSSIER', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('registrationDeliveryDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideSmallLoader())
  }
}

export function * watchFetchRegistrationDeliveryVehicle () {
  yield takeEvery(dossierActionsTypes.FETCH_REGISTRATION_DELIVERY_DOSSIER_VEHICLE, fetchRegistrationDeliveryVehicle)
}

export function * fetchRegistrationDeliveryClient ({ dossierId, resolve, reject, pristine }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const registrationDeliveryDossier = yield select(state => state.registrationDeliveryDossier)
    const entityComponent = yield call(getDossierEntityService, dossierId, auth.token)
    const dossierContactComponent = yield call(getDossierContact, dossierId, auth.token)
    entityComponent.updated = true
    dossierContactComponent.updated = true

    if (entityComponent && entityComponent.entitySubTypeId) {
      const entitySubTypeCombo = yield call(getEntitySubType, auth.token, entityComponent.entityTypeId)
      const entityFilterSubTypeCombo = yield call(getEntityFilterSubType, auth.token, entityComponent.entityTypeId, entityComponent.entitySubTypeId, registrationDeliveryDossier.organizedUnitId)
      yield put(fetchCombosSuccess({ entitySubTypeCombo, entityFilterSubTypeCombo }))
    }

    yield put(change('registrationDeliveryDossier', 'entityComponent', entityComponent.entityId ? entityComponent : {}))
    yield put(change('registrationDeliveryDossier', 'dossierContactComponent', dossierContactComponent))
    yield put(fetchRegistrationDeliveryDossierSuccess({ entityComponent, dossierContactComponent }))
    if (pristine === true || pristine === undefined) {
      yield put(initialize('registrationDeliveryDossier', { ...registrationDeliveryDossier, entityComponent: entityComponent, dossierContactComponent }))
    }
    if (resolve) yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.REGISTRATION_DELIVERY_DOSSIER.ERROR_GET_REGISTRATION_DELIVERY_DOSSIER', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('registrationDeliveryDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchRegistrationDeliveryClient () {
  yield takeEvery(dossierActionsTypes.FETCH_REGISTRATION_DELIVERY_DOSSIER_CLIENT, fetchRegistrationDeliveryClient)
}

export function * fetchRegistrationDeliveryFinancing ({ dossierId, resolve, reject, pristine }) {
  try {
    yield put(showSmallLoader())
    const auth = yield select(getAuth)
    const registrationDeliveryDossier = yield select(state => state.registrationDeliveryDossier)
    const dossierFinancingComponent = yield call(getDossierFinancing, dossierId, auth.token)
    dossierFinancingComponent.updated = true
    yield put(change('registrationDeliveryDossier', 'dossierFinancingComponent', dossierFinancingComponent))
    yield put(fetchRegistrationDeliveryDossierSuccess({ dossierFinancingComponent }))
    if (pristine === true) {
      yield put(initialize('registrationDeliveryDossier', { ...registrationDeliveryDossier, dossierFinancingComponent }))
    }
    if (resolve) yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.REGISTRATION_DELIVERY_DOSSIER.ERROR_GET_REGISTRATION_DELIVERY_DOSSIER', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('registrationDeliveryDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideSmallLoader())
  }
}

export function * watchfetchRegistrationDeliveryFinancing () {
  yield takeEvery(dossierActionsTypes.FETCH_REGISTRATION_DELIVERY_DOSSIER_FINANCING, fetchRegistrationDeliveryFinancing)
}

export function * fetchRegistrationDeliveryDelivery ({ dossierId, financingStatusId, resolve, reject, pristine }) {
  try {
    yield put(showSmallLoader())
    const auth = yield select(getAuth)
    const registrationDeliveryDossier = yield select(state => state.registrationDeliveryDossier)
    const dossierCompleteDeliveryComponent = yield call(getDossierDelivery, dossierId, auth.token)
    dossierCompleteDeliveryComponent.dossierDeliveryComponent.updated = true
    const completeDeliveryComponent = {
      ...registrationDeliveryDossier.dossierCompleteDeliveryComponent,
      ...dossierCompleteDeliveryComponent,
      dossierDeliveryComponent: {
        ...registrationDeliveryDossier.dossierCompleteDeliveryComponent.dossierDeliveryComponent,
        ...dossierCompleteDeliveryComponent.dossierDeliveryComponent
      }
    }
    yield put(change('registrationDeliveryDossier', 'dossierCompleteDeliveryComponent', completeDeliveryComponent))
    yield put(fetchRegistrationDeliveryDossierSuccess({ dossierCompleteDeliveryComponent: completeDeliveryComponent }))
    if (pristine === true) {
      yield put(initialize('registrationDeliveryDossier', { ...registrationDeliveryDossier, dossierCompleteDeliveryComponent: completeDeliveryComponent }))
    }
    if (resolve) yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.REGISTRATION_DELIVERY_DOSSIER.ERROR_GET_REGISTRATION_DELIVERY_DOSSIER', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('registrationDeliveryDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideSmallLoader())
  }
}

export function * watchfetchRegistrationDeliveryDelivery () {
  yield takeEvery(dossierActionsTypes.FETCH_REGISTRATION_DELIVERY_DOSSIER_DELIVERY, fetchRegistrationDeliveryDelivery)
}

export function * fetchRegistrationDeliveryDeliveryHistorical ({ dossierId, resolve, reject }) {
  try {
    yield put(showSmallLoader())
    const auth = yield select(getAuth)
    const dossierCompleteDeliveryComponent = yield call(getDossierDeliveryHistorical, dossierId, auth.token)
    yield put(change('registrationDeliveryDossier', 'dossierCompleteDeliveryComponent', dossierCompleteDeliveryComponent))
    yield put(fetchRegistrationDeliveryDossierSuccess({ dossierCompleteDeliveryComponent }))
    if (resolve) yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.REGISTRATION_DELIVERY_DOSSIER.ERROR_GET_REGISTRATION_DELIVERY_DOSSIER', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('registrationDeliveryDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideSmallLoader())
  }
}

export function * watchfetchRegistrationDeliveryDeliveryHistorical () {
  yield takeEvery(dossierActionsTypes.FETCH_REGISTRATION_DELIVERY_DOSSIER_DELIVERY_HISTORICAL, fetchRegistrationDeliveryDeliveryHistorical)
}

export function * fetchRegistrationDeliveryFinancingHistorical ({ dossierId, resolve, reject }) {
  try {
    yield put(showSmallLoader())
    const auth = yield select(getAuth)
    const dossierFinancingComponent = yield call(getDossierFinancingHistorical, dossierId, auth.token)
    yield put(change('registrationDeliveryDossier', 'dossierFinancingComponent', dossierFinancingComponent))
    yield put(fetchRegistrationDeliveryDossierSuccess({ dossierFinancingComponent }))
    if (resolve) yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.REGISTRATION_DELIVERY_DOSSIER.ERROR_GET_REGISTRATION_DELIVERY_DOSSIER', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('registrationDeliveryDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideSmallLoader())
  }
}

export function * watchfetchRegistrationDeliveryFinancingHistorical () {
  yield takeEvery(dossierActionsTypes.FETCH_REGISTRATION_DELIVERY_DOSSIER_FINANCING_HISTORICAL, fetchRegistrationDeliveryFinancingHistorical)
}

export function * fetchRegistrationDeliveryVehicleHistorical ({ dossierId, resolve, reject }) {
  try {
    yield put(showSmallLoader())
    const auth = yield select(getAuth)
    const vehicleComponent = yield call(getHistoricalPurchaseWithVehicleById, dossierId, auth.token)
    yield put(change('registrationDeliveryDossier', 'vehicleComponent', vehicleComponent.vehicleId ? vehicleComponent : {}))
    yield put(fetchRegistrationDeliveryDossierSuccess({ vehicleComponent }))
    if (resolve) yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.REGISTRATION_DELIVERY_DOSSIER.ERROR_GET_REGISTRATION_DELIVERY_DOSSIER', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('registrationDeliveryDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideSmallLoader())
  }
}

export function * watchFetchRegistrationDeliveryVehicleHistorical () {
  yield takeEvery(dossierActionsTypes.FETCH_REGISTRATION_DELIVERY_DOSSIER_VEHICLE_HISTORICAL, fetchRegistrationDeliveryVehicleHistorical)
}

export function * fetchRegistrationDeliveryClientHistorical ({ dossierId, resolve, reject }) {
  try {
    yield put(showSmallLoader())
    const auth = yield select(getAuth)
    const entityComponent = yield call(getDossierHistoricalEntity, dossierId, auth.token)
    if (entityComponent.entityTypeId === entityTypesId.recurrentEntity) {
      const registrationDeliveryDossier = yield select(state => state.registrationDeliveryDossier)
      yield call(fetchEntitySubTypeCombo, { entityTypeId: entityComponent.entityTypeId })
      yield call(fetchEntityFilterSubTypeCombo, { entityTypeId: entityComponent.entityTypeId, entitySubTypeId: entityComponent.entitySubTypeId, organizedUnitId: registrationDeliveryDossier.organizedUnitId })
    }
    const dossierContactComponent = yield call(getDossierContactHistorical, dossierId, auth.token)
    yield put(change('registrationDeliveryDossier', 'entityComponent', entityComponent.entityId ? entityComponent : {}))
    yield put(change('registrationDeliveryDossier', 'dossierContactComponent', dossierContactComponent))
    yield put(fetchRegistrationDeliveryDossierSuccess({ entityComponent, dossierContactComponent }))
    if (resolve) yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.REGISTRATION_DELIVERY_DOSSIER.ERROR_GET_REGISTRATION_DELIVERY_DOSSIER', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('registrationDeliveryDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideSmallLoader())
  }
}

export function * watchFetchRegistrationDeliveryClientHistorical () {
  yield takeEvery(dossierActionsTypes.FETCH_REGISTRATION_DELIVERY_DOSSIER_CLIENT_HISTORICAL, fetchRegistrationDeliveryClientHistorical)
}

export function * refreshRegistrationDeliveryDossierEntityFromDocumentModal (documentEditionalModalState) {
  try {
    yield put(showLoader())
    const registrationDeliveryDossier = yield select(state => state.registrationDeliveryDossier)
    const auth = yield select(getAuth)
    let entityComponent = {}
    let dirty = false
    if (documentEditionalModalState !== null && documentEditionalModalState !== undefined &&
       documentEditionalModalState.entityId !== null && documentEditionalModalState.entityId !== undefined) {
      entityComponent = yield call(getEntityById, documentEditionalModalState.entityId, auth.token)
    } else {
      entityComponent = yield call(getDossierEntityService, registrationDeliveryDossier.dossierId, auth.token)
    }
    if (documentEditionalModalState !== null && documentEditionalModalState !== undefined &&
      documentEditionalModalState.dirty !== null && documentEditionalModalState.dirty !== undefined) {
      dirty = documentEditionalModalState.dirty
    }
    entityComponent.previousEntity = { ...registrationDeliveryDossier.entityComponent.previousEntity }
    if (dirty) {
      yield put(change('registrationDeliveryDossier', 'entityComponent', entityComponent))
    } else {
      yield put(initialize('registrationDeliveryDossier', { ...registrationDeliveryDossier, entityComponent: entityComponent }))
    }
    yield put(fetchRegistrationDeliveryDossierSuccess({ entityComponent }))
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.REGISTRATION_DELIVERY_DOSSIER.ERROR_GET_REGISTRATION_DELIVERY_DOSSIER', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('registrationDeliveryDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * refreshRegistrationDeliveryDossierVehicleFromDocumentModal (documentEditionalModalState) {
  try {
    yield put(showLoader())
    const registrationDeliveryDossier = JSON.parse(JSON.stringify(yield select(state => state.registrationDeliveryDossier)))
    const auth = yield select(getAuth)
    const vehicleComponent = yield call(getPurchaseWithVehicleById, registrationDeliveryDossier.dossierId, auth.token)
    let dirty = false
    if (documentEditionalModalState !== null && documentEditionalModalState !== undefined &&
      documentEditionalModalState.dirty !== null && documentEditionalModalState.dirty !== undefined) {
      dirty = documentEditionalModalState.dirty
    }
    yield put(fetchRegistrationDeliveryDossierSuccess({ vehicleComponent }))

    let registration
    const headerAndButtons = yield call(getRegistrationDeliveryHeaderAndButtons, auth.token, registrationDeliveryDossier.dossierId)
    const checkListHeader = yield call(getStageCheckList, registrationDeliveryDossier.dossierId, auth.token)
    registration = { ...headerAndButtons }
    registration = { ...registration, checkListHeader }
    yield put(fetchRegistrationDeliveryDossierSuccess(registration))

    if (dirty) {
      yield put(change('registrationDeliveryDossier', 'vehicleComponent', vehicleComponent))
    } else {
      yield put(initialize('registrationDeliveryDossier', { ...registrationDeliveryDossier, vehicleComponent: vehicleComponent }))
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.REGISTRATION_DELIVERY_DOSSIER.ERROR_GET_REGISTRATION_DELIVERY_DOSSIER', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('registrationDeliveryDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * refreshRegistrationDeliveryDossierFromDocumentModal (documentEditionalModalState) {
  try {
    yield put(showLoader())
    const registrationDeliveryDossier = JSON.parse(JSON.stringify(yield select(state => state.registrationDeliveryDossier)))
    const auth = yield select(getAuth)
    const dossierId = registrationDeliveryDossier.dossierId
    let salesComponent = registrationDeliveryDossier.salesComponent
    let RegistrationDelivery
    let dossier = {}
    let dirty = false
    const components = yield call(fetchRegistrationDeliveryComponentsUpdated, registrationDeliveryDossier, auth)
    if (documentEditionalModalState === null || documentEditionalModalState === undefined || documentEditionalModalState.documentParentTypeId === documentEntityTypeConstant.DOSSIER || documentEditionalModalState.documentParentTypeId === null) {
      dossier = yield call(getDossierById, dossierId, auth.token)
      salesComponent = { ...dossier.dossierSaleComponent }
    }
    if (documentEditionalModalState && documentEditionalModalState.dirty) {
      dirty = documentEditionalModalState.dirty
    }
    dossier = { ...registrationDeliveryDossier, ...dossier, ...components }
    RegistrationDelivery = { ...RegistrationDelivery, ...dossier }
    yield put(fetchRegistrationDeliveryDossierSuccess(RegistrationDelivery))
    if (dirty) {
      yield put(change('registrationDeliveryDossier', 'salesComponent', salesComponent))
    } else {
      yield put(initialize('registrationDeliveryDossier', { ...registrationDeliveryDossier, salesComponent: salesComponent }))
    }
    yield put(hideLoader())
    yield call(fetchDependantsRegistrationDeliveryDossier, { dossierId })
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.REGISTRATION_DELIVERY_DOSSIER.ERROR_GET_REGISTRATION_DELIVERY_DOSSIER', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('registrationDeliveryDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * refreshCommonRegisDelivFromDocumental () {
  try {
    yield put(showLoader())
    const registrationDeliveryDossier = yield select(state => state.registrationDeliveryDossier)
    const auth = yield select(getAuth)
    let registration
    const headerAndButtons = yield call(getRegistrationDeliveryHeaderAndButtons, auth.token, registrationDeliveryDossier.dossierId)
    const checkListHeader = yield call(getStageCheckList, registrationDeliveryDossier.dossierId, auth.token)
    registration = { ...headerAndButtons }
    registration = { ...registration, checkListHeader }
    yield put(fetchRegistrationDeliveryDossierSuccess(registration))
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.REGISTRATION_DELIVERY_DOSSIER.ERROR_GET_REGISTRATION_DELIVERY_DOSSIER', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('registrationDeliveryDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * fetchRegistrationDeliveryComponentsUpdated (registrationDeliveryDossier, auth) {
  const result = {}
  if (registrationDeliveryDossier.entityComponent && registrationDeliveryDossier.entityComponent.updated) {
    const entityComponent = yield call(getDossierEntityService, registrationDeliveryDossier.dossierId, auth.token)
    entityComponent.updated = true
    result.entityComponent = entityComponent
  }
  if (registrationDeliveryDossier.vehicleComponent && registrationDeliveryDossier.vehicleComponent.updated) {
    const vehicleComponent = yield call(getPurchaseWithVehicleById, registrationDeliveryDossier.dossierId, auth.token)
    vehicleComponent.updated = true
    result.vehicleComponent = vehicleComponent
  }
  if (registrationDeliveryDossier.dossierContactComponent && registrationDeliveryDossier.dossierContactComponent.updated) {
    const dossierContactComponent = yield call(getDossierContact, registrationDeliveryDossier.dossierId, auth.token)
    dossierContactComponent.updated = true
    result.dossierContactComponent = dossierContactComponent
  }
  if (registrationDeliveryDossier.dossierFinancingComponent && registrationDeliveryDossier.dossierFinancingComponent.updated) {
    const dossierFinancingComponent = yield call(getDossierFinancing, registrationDeliveryDossier.dossierId, auth.token)
    dossierFinancingComponent.updated = true
    result.dossierFinancingComponent = dossierFinancingComponent
  }
  if (registrationDeliveryDossier.dossierCompleteDeliveryComponent && registrationDeliveryDossier.dossierCompleteDeliveryComponent.dossierDeliveryComponent &&
    registrationDeliveryDossier.dossierCompleteDeliveryComponent.dossierDeliveryComponent.updated) {
    const dossierCompleteDeliveryComponent = yield call(getDossierDelivery, registrationDeliveryDossier.dossierId, auth.token)
    dossierCompleteDeliveryComponent.dossierDeliveryComponent.updated = true
    result.dossierCompleteDeliveryComponent = dossierCompleteDeliveryComponent
  }
  if (registrationDeliveryDossier.dossierCompleteOtherDataComponent && registrationDeliveryDossier.dossierCompleteOtherDataComponent.updated) {
    const dossierCompleteOtherDataComponent = yield call(getDossierOtherData, registrationDeliveryDossier.dossierId, auth.token)
    dossierCompleteOtherDataComponent.updated = true
    result.dossierCompleteOtherDataComponent = dossierCompleteOtherDataComponent
  }
  if (registrationDeliveryDossier.dossierEconomicComponent && registrationDeliveryDossier.dossierEconomicComponent.updated) {
    const dossierEconomicComponent = yield call(getRegistrationDeliveryEconomicPlan, registrationDeliveryDossier.dossierId, auth.token)
    dossierEconomicComponent.updated = true
    result.dossierEconomicComponent = dossierEconomicComponent
  }
  return result
}

export function * fetchRegistrationDeliveryOtherData ({ dossierId, resolve, reject, pristine }) {
  try {
    yield put(showSmallLoader())
    const auth = yield select(getAuth)
    const registrationDeliveryDossier = yield select(state => state.registrationDeliveryDossier)
    const dossierCompleteOtherDataComponent = yield call(getDossierOtherData, dossierId, auth.token)
    dossierCompleteOtherDataComponent.updated = true
    yield put(change('registrationDeliveryDossier', 'dossierCompleteOtherDataComponent', dossierCompleteOtherDataComponent))
    yield put(fetchRegistrationDeliveryDossierSuccess({ dossierCompleteOtherDataComponent }))
    if (pristine === true) {
      yield put(initialize('registrationDeliveryDossier', { ...registrationDeliveryDossier, dossierCompleteOtherDataComponent }))
    }
    if (resolve) yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.REGISTRATION_DELIVERY_DOSSIER.ERROR_GET_OTHER_DATA', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('registrationDeliveryDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideSmallLoader())
  }
}

export function * watchfetchRegistrationDeliveryOtherData () {
  yield takeEvery(dossierActionsTypes.FETCH_REGISTRATION_DELIVERY_DOSSIER_OTHERDATA, fetchRegistrationDeliveryOtherData)
}
export function * fetchRegistrationDeliveryOtherDataHistorical ({ dossierId, resolve, reject, pristine }) {
  try {
    yield put(showSmallLoader())
    const auth = yield select(getAuth)
    const registrationDeliveryDossier = yield select(state => state.registrationDeliveryDossier)
    const dossierCompleteOtherDataComponent = yield call(getDossierOtherDataHistorical, dossierId, auth.token)
    dossierCompleteOtherDataComponent.updated = true
    yield put(change('registrationDeliveryDossier', 'dossierCompleteOtherDataComponent', dossierCompleteOtherDataComponent))
    yield put(fetchRegistrationDeliveryDossierSuccess({ dossierCompleteOtherDataComponent }))
    if (pristine === true) {
      yield put(initialize('registrationDeliveryDossier', { ...registrationDeliveryDossier, dossierCompleteOtherDataComponent }))
    }
    if (resolve) yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.REGISTRATION_DELIVERY_DOSSIER.ERROR_GET_OTHER_DATA', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('registrationDeliveryDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideSmallLoader())
  }
}
export function * watchfetchRegistrationDeliveryOtherDataHistorical () {
  yield takeEvery(dossierActionsTypes.FETCH_REGISTRATION_DELIVERY_DOSSIER_OTHERDATA_HISTORICAL, fetchRegistrationDeliveryOtherDataHistorical)
}
export function * refreshRegistrationDeliveryDossierFromDocumentModalLight () {
  try {
    yield put(showLoader())
    const registrationDeliveryDossier = yield select(state => state.registrationDeliveryDossier)
    const auth = yield select(getAuth)
    const dossierId = registrationDeliveryDossier.dossierId
    let RegistrationDelivery
    const checkListHeader = yield call(getStageCheckList, dossierId, auth.token)
    const headerAndButtons = yield call(getRegistrationDeliveryHeaderAndButtons, auth.token, dossierId)
    RegistrationDelivery = { ...headerAndButtons }
    RegistrationDelivery = { ...RegistrationDelivery, checkListHeader }
    yield put(fetchRegistrationDeliveryDossierSuccess(RegistrationDelivery))
    yield put(hideLoader())
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.REGISTRATION_DELIVERY_DOSSIER.ERROR_GET_REGISTRATION_DELIVERY_DOSSIER', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('registrationDeliveryDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * fetchRegistrationDeliveryEconomic ({ dossierId, historical, pristine, resolve, reject }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    let dossierEconomicComponent = {}
    if (historical) {
      dossierEconomicComponent = yield call(getRegistrationDeliveryEconomicPlanHistorical, dossierId, auth.token)
    } else {
      dossierEconomicComponent = yield call(getRegistrationDeliveryEconomicPlan, dossierId, auth.token)
    }
    dossierEconomicComponent.updated = true
    let registrationDeliveryDossier = JSON.parse(JSON.stringify(yield select(state => state.form.registrationDeliveryDossier.values)))
    registrationDeliveryDossier.dossierEconomicComponent = dossierEconomicComponent
    yield put(change('registrationDeliveryDossier', 'dossierEconomicComponent', dossierEconomicComponent))
    yield put(fetchRegistrationDeliveryDossierEconomicSuccess(dossierEconomicComponent, dossierId, historical))
    if (pristine === true) {
      yield put(initialize('registrationDeliveryDossier', registrationDeliveryDossier))
    }
    if (resolve) yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.REGISTRATION_DELIVERY_DOSSIER.ERROR_GET_REGISTRATION_DELIVERY_DOSSIER', customHandlerError.json ? customHandlerError.json.tag : ''))
      if (reject) yield call(reject, customHandlerError)
    }
    const sessionHandler = function * () {
      yield put(reset('registrationDeliveryDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchRegistrationDeliveryEconomic () {
  yield takeEvery(dossierActionsTypes.FETCH_REGISTRATION_DELIVERY_DOSSIER_ECONOMIC, fetchRegistrationDeliveryEconomic)
}
