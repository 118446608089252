import React, { PureComponent } from 'react'
import { reduxForm } from 'redux-form'
import { Row, Col, Panel, Table } from 'react-bootstrap'
import { Element } from 'react-scroll'
import { dossierTypes as dossierTypesConstant } from '../../../constants/dossier/common/dossierTypes'
import { compareObjects } from '../../../util/utils'
import { getComponentsPermisions } from '../../../util/permisionFunctions'
import EditableTable from '../../commons/editableTable/EditableTable'
import StatusHeaderPage from '../../../containers/dossierHistorical/common/StatusHeaderPage'
import ButtonsHeader from '../common/ButtonsHeader'
import ButtonsSidebar from '../../dossiers/common/ButtonsSidebar'
import RejectCommentModal from '../common/RejectCommentModal'
import { buttonsHeader } from '../../../constants/dossier/common/buttonsHeader'
import { urgencyTypesClass, permissions } from '../../../constants/backendIds'
import DocumentaryManagementPage from '../../../containers/dossierHistorical/common/DocumentaryManagementPage'
import AuditInfo from '../../dossiers/common/audit/AuditInfo'
import { Link } from 'react-router-dom'
import TrackingContainer from '../../../containers/tracking/trackingContainer'
import { TrackedPages } from '../../../constants/tracking/TrackedPages'
import Vehicle from '../common/Vehicle'
import { buttonsSidebar } from '../../../constants/dossier/common/buttonsSidebar'
import Reception from '../../dossiers/common/Reception'
import DossierRating from '../../dossiers/stock/DossierRating'
import Cleaning from '../../dossiers/stock/Cleaning'
import Checklist from '../../dossiers/stock/Checklist'
import Entry from '../../dossiers/stock/Entry'
import DossierBudget from '../../dossiers/common/DossierBudget'
import PriceChange from '../../dossiers/stock/PriceChange'
import Photographs from '../../dossiers/stock/Photographs'
import Crane from '../../dossiers/stock/Crane'
import Reconditioning from '../../dossiers/stock/Reconditioning'

class StockHistorical extends PureComponent {
  constructor () {
    super()
    this.state = {
      modalOpen: true,
      summaryOpen: true,
      showRejectCommentModal: false,
      activeTabConfiguration: null,
      headerTabConfiguration: null,
      loaded: false,
      componentsConfiguration: {
        downloadDocumentsButton: null,
        vehicle: null,
        stock: null,
        dossier_rating: null,
        cleaning: null,
        checklist: null,
        entry: null,
        reception: null,
        reconditioning: null,
        dossierBudget: null,
        priceChange: null,
        crane: null,
        photographs: null
      },
      isVehicleLoaded: false,
      isBudgetLoaded: false
    }
    this.goBack = this.goBack.bind(this)
  }

  goBack () {
    this.props.actions.setActiveTab(buttonsHeader.datos_expediente)
    this.props.history.goBack()
  }

  toggleRejectCommentModal (showRejectCommentModal) {
    this.setState({ showRejectCommentModal })
  }

  onClickRelatedDossierColumn (columnCode) {
    let columnCodeOrder = '+' + columnCode
    if (columnCodeOrder === this.props.relatedDossiersOrderBy) {
      columnCodeOrder = '-' + columnCode
    }
    this.props.actions.fetchFilteredRelatedDossier(this.props.dossier.dossierId, columnCodeOrder, true)
  }

  getRelatedDossierColumnIcon (columnCode) {
    if ('+' + columnCode === this.props.relatedDossiersOrderBy) {
      return 'ico-sort-selected-asc'
    } else if ('-' + columnCode === this.props.relatedDossiersOrderBy) {
      return 'ico-sort-selected-desc'
    } else {
      return 'ico-sort'
    }
  }

  fetchStockDossierPromise (dossierId) {
    return new Promise((resolve, reject) => {
      return this.props.actions.fetchStockDossier(dossierId, resolve, reject, true)
    })
  }

  initializePermisions (props) {
    const componentKeys = Object.keys(this.state.componentsConfiguration)
    const newState = getComponentsPermisions(props.dossier.viewConfiguration.tabsConfiguration, props.activeTab, componentKeys)
    this.setState({ ...this.state, ...newState })
  }

  componentDidMount () {
    this.props.actions.restartStockDossierState()
    this.fetchStockDossierPromise(this.props.match.params.dossierId).then(() => {
      const form = { ...this.props.dossier }
      this.props.initialize(form)
      this.initializePermisions(this.props)
      this.props.trackVisitPage({
        documentTitle: TrackedPages.SERVICE_HISTORICAL,
        organizedUnitId: this.props.dossier.organizedUnitId,
        href: window.location.href
      })
      this.props.actions.fetchSameLevelOusCombo(this.props.dossier.organizedUnitId, null)
      this.props.actions.fetchRatingCombo()
      this.setState({ loaded: true })
    })
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (this.props.dossier && this.props.dossier.viewConfiguration && this.props.dossier.viewConfiguration.tabsConfiguration && !compareObjects(prevProps.dossier.viewConfiguration.tabsConfiguration || {}, this.props.dossier.viewConfiguration.tabsConfiguration, true)) {
      this.initializePermisions(this.props)
    }
    if (this.props.dossier.dossierId && prevProps.activeTab !== this.props.activeTab) {
      this.initializePermisions(this.props)
    }
    return this.props
  }

  componentDidUpdate (prevProps) {
    if ((this.props.showManagementModal && !prevProps.showManagementModal) || (this.props.match.params.dossierId !== prevProps.match.params.dossierId)) {
      if (this.props.match.params.dossierId !== prevProps.match.params.dossierId) {
        this.props.actions.restartStockDossierState()
        this.props.actions.setScrollTo(buttonsSidebar.stock)
        this.props.actions.setActiveSection(buttonsSidebar.stock)
        this.props.actions.setActiveTab(buttonsHeader.datos_expediente)
        this.props.actions.closeAddOrEditDocumentModal()
      }

      if (this.props.match.params.dossierId) {
        this.fetchStockDossierPromise(this.props.match.params.dossierId).then(() => {
          const form = { ...this.props.dossier }
          this.props.initialize(form)
          this.initializePermisions(this.props)
          this.setState({ loaded: true })
        })
      }
    }

    if (this.props.dossier && this.props.dossier.dossierId && this.props.statusHeaderHeight && this.props.activeSection !== prevProps.activeSection) {
      switch (this.props.activeSection) {
        case buttonsSidebar.stock:
          this.setState({ summaryOpen: true })
          break
      }
      this.props.actions.setScrollTo(this.props.activeSection)
      this.props.actions.setActiveSection(false)
    }
  }

  componentWillUnmount () {
    this.props.actions.setActiveSection(buttonsSidebar.stock)
    this.props.actions.setScrollTo(null)
    this.props.actions.resetButtonsHeader()
    this.props.actions.closeAddOrEditDocumentModal()
    this.props.actions.restartStockDossierState()
  }

  handleToUpdateComponent (component) {
    if (component.isVehicleLoaded === false || buttonsSidebar.vehicle === component) this.setState({ isVehicleLoaded: true })
    if (component.isBudgetLoaded === false || buttonsSidebar.dossierBudget === component) this.setState({ isBudgetLoaded: true })
    this.props.actions.setScrollTo(component)
  }

  togglePanel () {
    if (this.state.summaryOpen) {
      this.props.actions.setActiveSection(false)
    }
    this.setState({ summaryOpen: !this.state.summaryOpen })
  }

  render () {
    const {
      t, dossier, pristine, activeTab, buttonsHeaderHeight, statusHeaderHeight, windowWidth, documentExport, vehicleProps, combos, activeSection, change,
      dossierRatingComponent, handleSubmit,
      dossier: {
        dossierId, header, checkListHeader, comments, viewConfiguration, dossierRelatedDossiers, auditInfo, dossierSubTypeId, dossierParentId,
        vehicleComponent: { vehicleId },
        viewConfiguration: { buttonsPermission = [] }
      },
      actions: {
        openDocumentModal, setActiveTab, setButtonsHeaderHeight, resetButtonsHeader, setActiveSection, setScrollerOptions,
        refreshAuditInfo, saveSelectedDocuments, fetchStockDossierVehicleHistorical, fetchComboOrigin, fetchComboDestination, fecthFamily,
        fetchAuditDetails, fetchAuditDetailsModal, getDossierBudget,
        fetchDossierBudgetRating,
        fetchDocumentsGroupedAuditDetails, fetchDocumentsGroupedAuditDetailsModal
      }
    } = this.props

    if (!dossierId) return null
    const tKey = 'DOSSIER_COMPONENTS.'
    const panelsPaddingTop = statusHeaderHeight + buttonsHeaderHeight - 82
    const saveStockDossierPermission = buttonsPermission.includes('stock_dossier_save')
    return (
      <div className="dossier-purchase-container">
        <RejectCommentModal
          t={t}
          showModal={this.state.showRejectCommentModal}
          actions={{
            closeModal: () => this.toggleRejectCommentModal(false)
          }}
        />
        <Col sm={12} className="add-dossier-wrapper dossier">
          <StatusHeaderPage
            headerData={header}
            stages={checkListHeader}
            dossierType={dossierTypesConstant.stock}
            headerTabConfiguration={this.state.headerTabConfiguration}
            goBack={this.goBack}
            loadedDossier={this.state.loaded}
            dossierId={dossier.dossierId}
          />
        </Col>
        <form autoComplete="off">
          <div className="row-header-buttons">
            <div className="buttons-header-box">
              <ButtonsHeader
                t={t}
                pristine={pristine}
                actions={{
                  openDocumentModal: () => openDocumentModal(dossierId, dossierTypesConstant.stock, pristine, 1, true),
                  setActiveTab,
                  setButtonsHeaderHeight,
                  resetButtonsHeader,
                  setScrollerOptions
                }}
                viewConfiguration={viewConfiguration}
                dossierId={this.props.match.params.dossierId}
                dossierType={dossierTypesConstant.stock}
                activeTab={activeTab}
                marginTop={statusHeaderHeight}
                windowWidth={windowWidth}
              />
            </div>
          </div>
          {
            activeTab === buttonsHeader.datos_expediente && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden && (
              <Row className="sales-panel dossier-panel">
                <Col sm={3} className="col-row expediente-menu-izq">
                  <ButtonsSidebar
                    t={t}
                    dossierId={dossierId}
                    historical={true}
                    handleToUpdateComponent={this.handleToUpdateComponent.bind(this)}
                    pristine={pristine}
                    actions={{ setActiveSection, fetchStockDossierVehicleHistorical }}
                    dossierType={dossierTypesConstant.stock}
                    marginTop={statusHeaderHeight}
                    dossierSubTypes={dossierSubTypeId}
                    buttonsHeaderHeight={buttonsHeaderHeight}
                    tabConfiguration={this.state.activeTabConfiguration}
                    isVehicleLoaded={this.state.isVehicleLoaded}
                  />
                </Col>
                <Col sm={9} className="col-row contenido-paneles-expediente">
                  <Col className="panels-wrapper dossier fixed" style={{ paddingTop: panelsPaddingTop }}>
                    {this.state.componentsConfiguration.vehicle && this.state.componentsConfiguration.vehicle.permission > permissions.hidden && (
                      <Element name={buttonsSidebar.vehicle}>
                        <Vehicle
                          parentDossierType={{
                            isPaperwork: false,
                            isSales: false,
                            isPurchase: false,
                            isRegistrationDelivery: false,
                            isStock: true
                          }}
                          actions={{
                            fetchComboOrigin,
                            fetchComboDestination,
                            fetchStockDossierVehicleHistorical,
                            fecthFamily,
                            setActiveSection
                          }}
                          t={t}
                          dossierId={dossierId}
                          dossierType={dossierTypesConstant.stock}
                          handleToUpdateComponent={this.handleToUpdateComponent.bind(this)}
                          isVehicleLoaded={this.state.isVehicleLoaded}
                          vehicleProps={vehicleProps}
                          dossierSubType={dossierSubTypeId}
                          dossierParentId={dossierParentId}
                          dossierWithVehicle={!!vehicleId}
                          combos={combos}
                          readOnlyFields={!saveStockDossierPermission || !!dossierParentId}
                          open={activeSection === buttonsSidebar.vehicle}
                          componentConfiguration={this.state.componentsConfiguration.vehicle}
                          organizedUnitId={dossier.organizedUnitId}
                        />
                      </Element>
                    )}
                    {this.state.componentsConfiguration.entry && this.state.componentsConfiguration.entry.permission > permissions.hidden && (
                      <Element name={buttonsSidebar.entry}>
                        <Entry
                          t={t}
                          open={activeSection === buttonsSidebar.entry}
                          componentConfiguration={this.state.componentsConfiguration.entry}
                          dossier={dossier}
                          dossierId={dossierId}
                          actions={{
                            setActiveSection
                          }}
                          combos={combos}
                          readOnlyFields={true}
                        />
                      </Element>
                    )}
                    {this.state.componentsConfiguration.reception && this.state.componentsConfiguration.reception.permission > permissions.hidden && (
                      <Element name={buttonsSidebar.reception}>
                        <Reception
                          pristine={pristine}
                          dossierType={dossierTypesConstant.stock}
                          t={t}
                          dossierId={dossierId}
                          open={activeSection === buttonsSidebar.reception}
                          dossierSubType={dossierSubTypeId}
                          componentConfiguration={this.state.componentsConfiguration.reception}
                          organizedUnitId={dossier.organizedUnitId}
                          parentDossierType={{
                            isPaperwork: false,
                            isSales: false,
                            isPurchase: true,
                            isCampaign: false,
                            isRegistrationDelivery: false,
                            isStock: false
                          }}
                          dossier={dossier}
                          readOnlyFields={true}
                          actions={{
                            setActiveSection
                          }}
                        />
                      </Element>
                    )}
                    {this.state.componentsConfiguration.dossier_rating && this.state.componentsConfiguration.dossier_rating.permission > permissions.hidden && (
                      <Element name={buttonsSidebar.reception}>
                        <DossierRating
                          pristine={pristine}
                          t={t}
                          historical={true}
                          dossierId={dossierId}
                          combos={combos}
                          componentConfiguration={this.state.componentsConfiguration.dossier_rating}
                          open={activeSection === buttonsSidebar.dossier_rating}
                          dossier={dossier}
                          dossierRatingComponent={ dossierRatingComponent}
                          handleSubmit={handleSubmit}
                          actions={{
                            change,
                            setActiveSection
                          }}
                        />
                      </Element>
                    )}
                    {this.state.componentsConfiguration.dossierBudget && this.state.componentsConfiguration.dossierBudget.permission > permissions.hidden && (
                      <Element name={buttonsSidebar.dossierBudget}>
                        <DossierBudget
                          pristine={pristine}
                          dossierType={dossierTypesConstant.stock}
                          t={t}
                          dossierId={dossierId}
                          actions={{
                            setActiveSection,
                            getDossierBudget,
                            fetchDossierBudgetRating
                          }}
                          open={activeSection === buttonsSidebar.dossierBudget}
                          dossierSubType={dossierSubTypeId}
                          componentConfiguration={this.state.componentsConfiguration.dossierBudget}
                          organizedUnitId={dossier.organizedUnitId}
                          dossier={dossier}
                          readOnlyFields={true}
                          handleToUpdateComponent={this.handleToUpdateComponent.bind(this)}
                          isBudgetLoaded={this.state.isBudgetLoaded}
                        />
                      </Element>
                    )}
                    {this.state.componentsConfiguration.entry && this.state.componentsConfiguration.reconditioning.permission > permissions.hidden && (
                      <Element name={buttonsSidebar.reconditioning}>
                        <Reconditioning
                          t={t}
                          open={activeSection === buttonsSidebar.reconditioning}
                          componentConfiguration={this.state.componentsConfiguration.reconditioning}
                          dossier={dossier}
                          dossierId={dossierId}
                          actions={{
                            setActiveSection
                          }}
                          readOnlyFields={true}
                        />
                      </Element>
                    )}
                    {this.state.componentsConfiguration.cleaning && this.state.componentsConfiguration.cleaning.permission > permissions.hidden && (
                      <Element name={buttonsSidebar.cleaning}>
                        <Cleaning
                          dossierType={dossierTypesConstant.stock}
                          t={t}
                          dossierId={dossierId}
                          historical={true}
                          open={activeSection === buttonsSidebar.cleaning}
                          componentConfiguration={this.state.componentsConfiguration.cleaning}
                          dossier={dossier}
                          actions={{
                            setActiveSection
                          }}
                        />
                      </Element>
                    )}
                    {this.state.componentsConfiguration.checklist && this.state.componentsConfiguration.checklist.permission > permissions.hidden && (
                      <Element name={buttonsSidebar.checklist}>
                        <Checklist
                          historical={true}
                          dossierType={dossierTypesConstant.stock}
                          t={t}
                          dossierId={dossierId}
                          open={activeSection === buttonsSidebar.checklist}
                          componentConfiguration={this.state.componentsConfiguration.checklist}
                          dossier={dossier}
                          actions={{
                            setActiveSection
                          }}
                        />
                      </Element>
                    )}
                    {this.state.componentsConfiguration.photographs && this.state.componentsConfiguration.photographs.permission > permissions.hidden && (
                      <Element name={buttonsSidebar.photographs}>
                        <Photographs
                          dossierType={dossierTypesConstant.stock}
                          t={t}
                          dossierId={dossierId}
                          isHistorical={true}
                          open={activeSection === buttonsSidebar.photographs}
                          componentConfiguration={this.state.componentsConfiguration.photographs}
                          dossier={dossier}
                          actions={{
                            setActiveSection
                          }}
                        />
                      </Element>
                    )}

                    {this.state.componentsConfiguration.crane && this.state.componentsConfiguration.crane.permission > permissions.hidden && (
                      <Element name={buttonsSidebar.crane}>
                        <Crane
                          t={t}
                          open={activeSection === buttonsSidebar.crane}
                          componentConfiguration={this.state.componentsConfiguration.crane}
                          dossier={dossier}
                          dossierId={dossierId}
                          combos={combos}
                          readOnlyFields={true}
                          actions={{
                            setActiveSection
                          }} />
                      </Element>
                    )}
                    {this.state.componentsConfiguration.priceChange && this.state.componentsConfiguration.priceChange.permission > permissions.hidden && (
                      <Element name={buttonsSidebar.price_change}>
                        <PriceChange
                          t={t}
                          open={activeSection === buttonsSidebar.price_change}
                          componentConfiguration={this.state.componentsConfiguration.priceChange}
                          dossier={dossier}
                          actions={{
                            setActiveSection
                          }}
                          readOnlyFields={true}/>
                      </Element>
                    )}
                  </Col>
                </Col>
              </Row>
            )}
          {activeTab === buttonsHeader.gestor_documental && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden && (
            <Row className="sales-panel dossier-panel panel-expedientes-relacionados">
              <Col sm={12} className="col-row" style={{ paddingTop: panelsPaddingTop }}>
                <DocumentaryManagementPage
                  dossier={dossier}
                  dossierType={dossierTypesConstant.stock}
                  saveSelectedDocuments={saveSelectedDocuments}
                  documentExport={documentExport}
                  combos={this.props.combos}
                  isHistorical={true}
                  tabConfiguration={this.state.activeTabConfiguration}
                  downloadButtonPermission={this.state.activeTabConfiguration.componentsConfiguration.downloadDocumentsButton}
                />
              </Col>
            </Row>
          )}
          {activeTab === buttonsHeader.expedientes_relacionados && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden && (
            <Row className="sales-panel dossier-panel panel-expedientes-relacionados">
              <Col sm={12} className="col-row" style={{ paddingTop: panelsPaddingTop }}>
                <Col sm={6} className="col-row">
                  <h4><i className="ico-relacion" /> Expedientes relacionados</h4>
                </Col>
                <Col sm={12} className="col-row">
                  <Table hover responsive className="simple-table dashboard-tasks-table" id="related-dossiers-table">
                    <thead>
                      <tr>
                        <th onClick={this.onClickRelatedDossierColumn.bind(this, 'urgencyType')}>
                          <i className={this.getRelatedDossierColumnIcon('urgencyType')} />
                        </th>
                        <th onClick={this.onClickRelatedDossierColumn.bind(this, 'number')}>
                          Número de expediente <i className={this.getRelatedDossierColumnIcon('number')} />
                        </th>
                        <th onClick={this.onClickRelatedDossierColumn.bind(this, 'organizedUnit')}>
                          UO <i className={this.getRelatedDossierColumnIcon('organizedUnit')} />
                        </th>
                        <th onClick={this.onClickRelatedDossierColumn.bind(this, 'dossierSubType')}>
                          Subtipo <i className={this.getRelatedDossierColumnIcon('dossierSubType')} />
                        </th>
                        <th onClick={this.onClickRelatedDossierColumn.bind(this, 'stage')}>
                          Estado <i className={this.getRelatedDossierColumnIcon('stage')} />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        dossierRelatedDossiers.map((relatedDossier, idx) => {
                          return (
                            <tr key={idx}>
                              <td>
                                <span className={'color-point ' + urgencyTypesClass[relatedDossier.urgencyType]}></span>
                              </td>
                              <td>
                                {
                                  relatedDossier.urlToNavigate ? (
                                    <Link to={`/dossier${relatedDossier.urlToNavigate}`}>
                                      {relatedDossier.number}
                                    </Link>
                                  ) : relatedDossier.number
                                }
                              </td>
                              <td>{relatedDossier.organizedUnit}</td>
                              <td>{relatedDossier.dossierSubType}</td>
                              <td>{relatedDossier.stage}</td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </Table>
                </Col>
              </Col>
            </Row>
          )}
          {
            activeTab === buttonsHeader.actividad_expediente && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden && (
              <Row className="sales-panel dossier-panel panel-actividad-expediente">
                <Col sm={12} className="col-row" style={{ paddingTop: panelsPaddingTop }}>
                  <AuditInfo
                    auditInfo={auditInfo}
                    dossierType={dossierTypesConstant.stock}
                    actions={{ refreshAuditInfo, fetchAuditDetails, fetchAuditDetailsModal, fetchDocumentsGroupedAuditDetails, fetchDocumentsGroupedAuditDetailsModal }}
                    dossier={dossier}
                    historical={true}
                    t={t}
                  />
                </Col>
              </Row>
            )
          }
          {
            activeTab === buttonsHeader.observaciones && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden && (
              <Row className="sales-panel dossier-panel panel-observaciones-expediente">
                <Col sm={12} className="col-row" style={{ paddingTop: panelsPaddingTop }}>
                  <Col sm={6} className="col-row">
                    <h4><i className="ico-bocadillo" onClick={() => { this.setState({ modalOpen: !this.state.modalOpen }) }} /> {t(`${tKey}COMMENTS.TITLE_PANEL`)}</h4>
                  </Col>
                  <Col sm={12} className="col-row">
                    <Panel expanded={this.state.modalOpen} onToggle={() => { }}>
                      <Panel.Collapse>
                        <Row>
                          <EditableTable
                            t={t}
                            columnsConfig={
                              [
                                { th: t(`${tKey}COMMENTS.USER_NAME`), key: 'userName', nonEditable: true },
                                { th: t(`${tKey}COMMENTS.COMMENT_TYPE`), key: 'commentType', nonEditable: true },
                                { th: t(`${tKey}COMMENTS.TEXT`), key: 'text', style: { width: '30%' } },
                                { th: t(`${tKey}COMMENTS.CREATION_DATE`), key: 'creationDate', nonEditable: true }
                              ]
                            }
                            dossierType={dossierTypesConstant.stock}
                            editableObjectsArray={comments}
                            readOnlyFields={true}
                          />
                        </Row>
                      </Panel.Collapse>
                    </Panel>
                  </Col>
                </Col>
              </Row>
            )
          }
        </form>
      </div>
    )
  }
}

var decoratedComponent = TrackingContainer(StockHistorical, false)

export default reduxForm({
  form: 'stock_dossier'
})(decoratedComponent)
