import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import releaseNotesModalActions from '../../../actions/modals/releaseNotesModal'
import ModalReleaseNotes from '../../../components/layout/modals/ModalReleaseNotes'
import { fetchReleaseNotesFile } from '../../../actions/auth'

export function mapStateToProps (state) {
  return {
    newVersionNumber: state.auth.newVersionNumber,
    showNewVersion: state.auth.showNewVersion,
    userLanguageId: state.auth.languageId,
    releaseNotes: state.auth.releaseNotes
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({ ...releaseNotesModalActions, fetchReleaseNotesFile }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(ModalReleaseNotes))
