import React, { PureComponent } from 'react'
import { Row, Checkbox } from 'react-bootstrap'
class Tab1 extends PureComponent {
  render () {
    return (
      <Row className="tabPane-container no-mrg">
        <div className="checkbox-point">
          <Checkbox inline>Ficha ITV</Checkbox>
          <span className="point green"></span>
        </div>
        <div className="checkbox-point">
          <Checkbox inline>Permiso circulaci�n</Checkbox>
          <span className="point green"></span>
        </div>
        <div className="checkbox-point">
          <Checkbox inline>Impuestos circulaci�n</Checkbox>
          <span className="point green"></span>
        </div>
        <div className="checkbox-point">
          <Checkbox inline> DNI/CIF familiar PIVE </Checkbox>
          <span className="point green"></span>
        </div>
        <div className="checkbox-point">
          <Checkbox inline> Libro fam. PIVE </Checkbox>
          <span className="point green"></span>
        </div>
        <div className="checkbox-point">
          <Checkbox inline> Tasaci�n UaC </Checkbox>
          <span className="point green"></span>
        </div>
      </Row>
    )
  }
}

export default Tab1
