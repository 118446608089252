import { call, takeEvery, select, put } from 'redux-saga/effects'
import { handleError } from '../../../errors/errorManager'
import { getAuth } from '../../../../selectors/access/auth'
import { showLoader, hideLoader, openModal } from '../../../../actions/common'
import { getDocumentLandingRequestSuccess, getAllDocumentLandingRequestSuccess } from '../../../../actions/landingRequest/landingRequestActions'
import landingActions from '../../../../constants/actions/dossier/common/landingAccess'
import getAllAvailableLandingDocumentList from '../../../../services/landingRequest/getAllAvailableLandingDocumentList'
import getAvailableLandingDocumentList from '../../../../services/landingRequest/getAvailableLandingDocumentList'
import postSendRequestToClient from '../../../../services/landingRequest/postSendRequestToClient'
import landingAccessErrors from '../../../../constants/errors/landingAccessErrors'
import { yesNoModal } from '../../../modalsListeners/yesNoModal'
import deleteLandingRequest from '../../../../services/landingRequest/DeleteLandingRequest'

import { refreshDossierByTypeLight } from './managementModalSagas'

export function * getAllAvailableDocumentLandingRequest ({ dossierId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const docList = yield call(getAllAvailableLandingDocumentList, dossierId, auth.token)
    yield put(getAllDocumentLandingRequestSuccess(docList))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchGetAllAvailableDocumentLandingRequest () {
  yield takeEvery(landingActions.GET_ALL_AVAILABLE_DOCUMENT_LANDING_REQUEST, getAllAvailableDocumentLandingRequest)
}

export function * getAvailableDocumentLandingRequest ({ dossierId, resolve, reject }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const landingAccessInfo = yield call(getAvailableLandingDocumentList, dossierId, auth.token)
    yield put(getDocumentLandingRequestSuccess(landingAccessInfo))
    if (resolve) resolve()
  } catch (error) {
    if (reject) reject(error.json.tag === landingAccessErrors.NO_EMAIL_ADDRESS ? error.json : null)
    if (error.json.tag !== landingAccessErrors.NO_EMAIL_ADDRESS) {
      yield call(handleError, { error })
    }
  } finally {
    yield put(hideLoader())
  }
}

export function * watchGetDocumentLandingRequest () {
  yield takeEvery(landingActions.GET_DOCUMENT_LANDING_REQUEST, getAvailableDocumentLandingRequest)
}

export function * sendRequestToClient ({ dossierId, dossierTypeId, docList }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(postSendRequestToClient, auth.token, dossierId, docList)
    yield put(openModal('sendLandingRequest'))
    yield call(refreshDossierByTypeLight, {
      dossierType: dossierTypeId,
      dossierDocumentChanged: true,
      entityDocumentChanged: false,
      vehicleDocumentChanged: false
    })
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSendRequestToClient () {
  yield takeEvery(landingActions.SEND_LANDING_REQUEST_TO_CLIENT, sendRequestToClient)
}

export function * watchSendLandingCancelRequest () {
  yield takeEvery(landingActions.SEND_LANDING_CANCEL_REQUEST, sendLandingCancelRequest)
}

export function * sendLandingCancelRequest ({ dossierId, dossierTypeId, resolve, reject }) {
  const confirmed = yield call(yesNoModal, 'landingCancelAllRequest')
  try {
    if (confirmed) {
      yield put(showLoader())
      const auth = yield select(getAuth)
      yield call(deleteLandingRequest, dossierId, auth.token)
      yield put(openModal('cancelLandingRequest'))
      yield call(refreshDossierByTypeLight, {
        dossierType: dossierTypeId,
        dossierDocumentChanged: true,
        entityDocumentChanged: false,
        vehicleDocumentChanged: false
      })
      if (resolve) resolve()
    }
  } catch (error) {
    if (error.json.tag !== landingAccessErrors.NO_EMAIL_ADDRESS) {
      yield call(handleError, { error })
    }
    if (reject) reject()
  } finally {
    yield put(hideLoader())
  }
}
