import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import ftpRegisterSearch from '../../components/importExportFile/ftpRegisterSearch'

import { fetchFtpRegister, fetchFtpRegisterExport } from '../../actions/search/search'

import { translate } from 'react-polyglot'
export function mapStateToProps (state) {
  return {
    ...state.search.ftpSearch
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({ fetchFtpRegister, fetchFtpRegisterExport }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(ftpRegisterSearch))
