import mastersActionTypes from '../../../../constants/actions/masters/masters'

export function fetchProcessingAgencyDocTypeUsesList (processingAgencyId, filter, typeId) {
  return {
    type: mastersActionTypes.FETCH_PROCESSING_AGENCY_DOC_TYPE_USES_LIST,
    filter,
    processingAgencyId,
    typeId
  }
}

export function fetchProcessingAgencyDocTypeUsesListSuccess (processingAgencyDocTypeUsesList, filter) {
  return {
    type: mastersActionTypes.FETCH_PROCESSING_AGENCY_DOC_TYPE_USES_LIST_SUCCESS,
    processingAgencyDocTypeUsesList,
    filter
  }
}

export function showProcessingAgencyDocTypeUsesModal (processingAgencyId, show, processingAgencyDocTypeUse, isReturnDocument) {
  return {
    type: mastersActionTypes.SHOW_PROCESSING_AGENCY_DOC_TYPE_USES_MODAL,
    show,
    processingAgencyDocTypeUse,
    processingAgencyId,
    isReturnDocument
  }
}

export function showProcessingAgencyDocTypeUsesModalSuccess (processingAgencyId, show, processingAgencyDocTypeUse, isReturnDocument) {
  return {
    type: mastersActionTypes.SHOW_PROCESSING_AGENCY_DOC_TYPE_USES_MODAL_SUCCESS,
    show,
    processingAgencyDocTypeUse,
    processingAgencyId,
    isReturnDocument
  }
}

export function submitProcessingAgencyDocTypeUses (processingAgencyId, processingAgencyDocTypeUse) {
  return {
    type: mastersActionTypes.SUBMIT_PROCESSING_AGENCY_DOC_TYPE_USES,
    processingAgencyDocTypeUse,
    processingAgencyId
  }
}

export function deleteProcessingAgencyDocTypeUses (processingAgencyId, processingAgencyDocTypeUsesId, isReturnDocument) {
  return {
    type: mastersActionTypes.DELETE_PROCESSING_AGENCY_DOC_TYPE_USES,
    processingAgencyDocTypeUsesId,
    processingAgencyId,
    isReturnDocument
  }
}
