import React, { PureComponent } from 'react'
import { Field } from 'redux-form'
import { Row, Button, Clearfix } from 'react-bootstrap'
import InputText from '../commons/form/InputText'
import InputMoney from '../commons/form/InputMoney'
import InputCheckbox from '../commons/form/InputCheckBox'
import { multipleNormalize, toUpperCase, trimAll } from '../../util/normalizeFunctions'
import { urgencyTypesClass } from '../../constants/backendIds'
import SimpleTablePage from '../../containers/commons/general/SimpleTablePage'
import IDocCarPagination from '../commons/iDocCarPagination'
import { Link } from 'react-router-dom'
import { formatMoney } from '../../util/utils'

class SearchByRentingForm extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      fields: {}
    }
  }

  exportData () {
    const tKey = 'SEARCH.CATEGORIES.'
    let columnsExcel = []
    columnsExcel.push(this.props.t(`${tKey}DOSSIER.URGENCY`) + '#Urgency')
    columnsExcel.push(this.props.t(`${tKey}DOSSIER.SALE_PAY_RENTING`) + '#SalePayRenting')
    columnsExcel.push(this.props.t(`${tKey}VEHICLE.LICENSE_PLATE`) + '#LicensePlate')
    columnsExcel.push(this.props.t(`${tKey}CHARGE.CLIENT_NUMBER`) + '#ClientNumber')
    columnsExcel.push(this.props.t(`${tKey}CHARGE.AMOUNT`) + '#Amount')
    columnsExcel.push(this.props.t(`${tKey}VEHICLE.CHASSIS`) + '#VinChasis')
    columnsExcel.push(this.props.t(`${tKey}DOSSIER.CREATION_DATE`) + '#CrationDate')

    this.props.exportToExcel(columnsExcel)
  }

  render () {
    const {
      t, fields, fieldFill, className, hasSearch, rentingData, selectedFilters, onClickResetSearch,
      onOrderChange, getIconClass, page, pages, count, onPageChange, arrayToString, addDossierIdToArray
    } = this.props
    const tKey = 'SEARCH.CATEGORIES.CHARGE.'
    return (
      <div>
        {!hasSearch ? (
          <Row>
            <Field
              colSm={3}
              id="clientNumberRenting"
              name='renting.clientNumber'
              controlLabel={t('SEARCH.CATEGORIES.CHARGE.CLIENT_NUMBER')}
              placeholder={t('SEARCH.CATEGORIES.CHARGE.CLIENT_NUMBER')}
              component={InputText}
              type="text"
              onInputChange={(val) => fieldFill({ clientNumber: val })}
              customClass={fields.clientNumber ? className : ''}
            />
            <Field
              colSm={3}
              id="vinChassis"
              name='renting.vinChassis'
              placeholder={t('SEARCH.CATEGORIES.VEHICLE.CHASSIS')}
              controlLabel={t('SEARCH.CATEGORIES.VEHICLE.CHASSIS')}
              component={InputText}
              type="text"
              maxLength={17}
              normalize={value => multipleNormalize(value, [toUpperCase, trimAll])}
              onInputChange={(val) => fieldFill({ vinChassis: val })}
              customClass={fields.vinChassis ? className : ''}
            />
            <Field
              colSm={3}
              id="makerNumberRenting"
              name="renting.makerNumberRenting"
              controlLabel={t('SEARCH.CATEGORIES.DOSSIER.RENTALORDER_NUMBER')}
              placeholder={t('SEARCH.CATEGORIES.DOSSIER.RENTALORDER_NUMBER')}
              component={InputText}
              onInputChange={(val) => fieldFill({ makerNumberRenting: val })}
              customClass={fields.makerNumberRenting ? className : ''}
            />
            <Field
              colSm={3}
              id="dealerNumber"
              name="renting.dealerNumber"
              controlLabel={t('SEARCH.CATEGORIES.DOSSIER.DEALER_NUMBER')}
              placeholder={t('SEARCH.CATEGORIES.DOSSIER.DEALER_NUMBER')}
              component={InputText}
              onInputChange={(val) => fieldFill({ dealerNumber: val })}
              customClass={fields.dealerNumber ? className : ''}
            />
            <Field
              colSm={3}
              id="requestAmount"
              name="renting.requestAmount"
              controlLabel={t('SEARCH.CATEGORIES.DOSSIER.REQUEST_AMOUNT')}
              placeholder={t('SEARCH.CATEGORIES.DOSSIER.REQUEST_AMOUNT')}
              component={InputMoney}
              onInputChange={(val) => fieldFill({ requestAmount: val })}
              customClass={fields.requestAmount ? className : ''}
            />
          </Row>
        ) : ([
          <Row className="filters-selected" key="filters-selected">
            <ul className="list-inline pull-left">
              {selectedFilters().length === 0 ? <li>{t(`${tKey}NO_SELECTED_FILTERS`)}</li> : (selectedFilters())}
            </ul>
            <Clearfix/>
            <Button
              onClick={(event) => onClickResetSearch(event)}
              bsStyle="default"
              bsSize="small"
              className="pull-left"
              id='modSearchButtonIdRenting'
            >
              {t('SEARCH.ACTIONS.MODIFY_SEARCH')}
              <i className="ico-edit-turquoise" />
            </Button>
            {count > 0 &&
            <Button
              onClick={(event) => this.exportData(event)}
              bsStyle="default"
              bsSize="small"
              id='expSearchButtonIdRenting'>
              {t('SEARCH.ACTIONS.EXPORT')}
              <i className="ico-if_Download_1031520" />
            </Button>
            }
          </Row>,
          <div className="table-wrapper" key="table-wrapper">
            <Button
              onClick={() => arrayToString() }
              className="button pay renting"
              id='payButtonIdRenting'>
              <span>{t('SEARCH.CATEGORIES.DOSSIER.SALE_PAY_RENTING')}</span>
            </Button>
            <SimpleTablePage
              columns={[
                <th key={0} />,
                <th key={1} data-field="number" onClick={(event) => onOrderChange(event)}>
                  {t('SEARCH.CATEGORIES.DOSSIER.SALE_PAY_RENTING')}&nbsp;<i className={getIconClass('number')}/>
                </th>,
                <th key={2} data-field="vinChassis" onClick={(event) => onOrderChange(event)}>
                  {t('SEARCH.CATEGORIES.VEHICLE.LICENSE_PLATE')}&nbsp;<i className={getIconClass('number')}/>
                </th>,
                <th key={3} data-field="clientNumber" onClick={(event) => onOrderChange(event)}>
                  {t('SEARCH.CATEGORIES.CHARGE.CLIENT_NUMBER')}&nbsp;<i className={getIconClass('number')}/>
                </th>,
                <th key={4} data-field="resquestAmount" onClick={(event) => onOrderChange(event)}>
                  {t('SEARCH.CATEGORIES.CHARGE.AMOUNT')}&nbsp;<i className={getIconClass('number')}/>
                </th>,
                <th key={5} data-field="vinChasis" onClick={(event) => onOrderChange(event)}>
                  {t('SEARCH.CATEGORIES.VEHICLE.CHASSIS')}&nbsp;<i className={getIconClass('number')}/>
                </th>,
                <th key={6} data-field="creationDate" onClick={(event) => onOrderChange(event)}>
                  {t('SEARCH.CATEGORIES.DOSSIER.CREATION_DATE')}&nbsp;<i className={getIconClass('number')}/>
                </th>,
                <th key={7} data-field="check" onClick={(event) => onOrderChange(event)}>
                  {'Cobrar'}&nbsp;<i className={getIconClass('number')}/>
                </th>
              ]}
              rows={rentingData && rentingData.map((row, idx) => (
                <tr key={idx}>
                  <td>
                    <span className={`color-point ${urgencyTypesClass[row.urgencyType] || 'grey'}`}/>
                  </td>
                  <td> <Link to={`/dossier${row.urlToNavigate}`}>
                    {row.number}
                  </Link> </td>
                  <td>{row.vehicle.vehicleLicensePlate}</td>
                  <td>{row.entity.clientNumber}</td>
                  <td>{formatMoney(row.requestAmount)}</td>
                  <td>{row.vehicle.vehicleVinChassis}</td>
                  <td>{row.creationDate}</td>
                  <td>{<Field
                    component={InputCheckbox}
                    name={'paymentCheck' + row.dossierId}
                    onChange={() => addDossierIdToArray(row.dossierId)}
                  />}</td>
                </tr>))}/>
          </div>,
          <div className="search-footer" key="search-footer">
            <IDocCarPagination
              id="btn_pag_sales"
              page={page}
              pagesCount={pages}
              totalRows= {count}
              onSelectAction={(page) => onPageChange(page)}
            />
          </div>
        ])}
      </div>
    )
  }
}

export default SearchByRentingForm
