import React, { PureComponent } from 'react'
import { Col, Tab, Tabs } from 'react-bootstrap'
import MainClientManagement from './Tab3Tabs/MainClientManagemente'
import BillingClientManagement from './Tab3Tabs/BillingClientManagemente'

class Tab3 extends PureComponent {
  render () {
    return (
      <Col sm={12}>
        <Tabs id='main-billing-client' className='sub-tabs'>
          <Tab eventKey={1} title='Cliente principal'>
            <MainClientManagement />
          </Tab>
          <Tab eventKey={2} title='Cliente facturación'>
            <BillingClientManagement />
          </Tab>
        </Tabs>
      </Col>
    )
  }
}

export default Tab3
