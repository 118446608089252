import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import SearchByPrepareForm from '../../../components/search/SearchByPrepareForm'
import { serviceRequestSpareParts, serviceCompleteSpareParts, serviceCompleteDeliveryPreparation } from '../../../actions/dossier/serviceDossier'
import { translate } from 'react-polyglot'
import {
  fetchStagesCombo
} from '../../../actions/combos/combos'
import { resetFilters } from '../../../actions/search/search'

export function mapStateToProps (state) {
  return {
    combos: {
      stagesCombo: state.combos.stagesCombo
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchStagesCombo,
      resetFilters,
      serviceRequestSpareParts,
      serviceCompleteSpareParts,
      serviceCompleteDeliveryPreparation
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(SearchByPrepareForm))
