import React, { PureComponent } from 'react'
import { reduxForm } from 'redux-form'
import { Button } from 'react-bootstrap'
import { getSectionPermissions } from '../../../util/permisionFunctions'
import { sectionPermissions } from '../../../constants/dossier/common/buttonsHeader'
import { unattendedResult } from '../../../constants/management/unattendedStatus'
import AuditAdminPage from '../../../_v2/containers/management/AuditAdminPage'
import AuditsPage from '../../../_v2/containers/management/AuditsPage'
import SearchDocumentalPage from '../../../_v2/containers/management/SearchDocumentalPage'
import { managementTab, managementTabCode } from '../../../constants/management/managementConstants'
import AuditModalPage from '../../../containers/management/auxModals/AuditModalPage'
import SimpleInputText from '../../../components/commons/simpleForm/SimpleInputText'
import { permissions } from '../../../constants/backendIds'

import MapSections from '../../../_v2/util/map-sections_v2'
import MapSectionsManagement from '../../../_v2/util/map-sections-management'
import FleetsPage from '../../../_v2/containers/management/FleetsPage'
import FleetFinderPage from '../../../_v2/containers/management/FleetFinderPage'
import UnattendedProcessesFinderPage from '../../../_v2/containers/management/UnattendedProcessesFinderPage'
import FleetsManagerPage from '../../../_v2/containers/management/FleetsManagerPage'
import SelfSupplyManagementPage from '../../../_v2/containers/management/SelfSupplyManagementPage'
import SearchByChargeFormPage from '../../../_v2/containers/management/SearchByChargeFormPage'
import SearchByPrepareFormPage from '../../../_v2/containers/management/SearchByPrepareFormPage'
import SearchByPapFormPage from '../../../_v2/containers/management/SearchByPapFormPage'
import SearchByPlanLogisticsPapFormPage from '../../../_v2/containers/management/SearchByPlanLogisticsPapFormPage'
import SearchByFleetsFormPage from '../../../_v2/containers/management/SearchByFleetsFormPage'

class Management extends PureComponent {
  constructor () {
    super()
    this.state = {
      secMapManagement: MapSectionsManagement,
      sectionTabsConfiguration: {
        fleets: {
          permission: null
        },
        fleetsManagement: {
          permission: null
        },
        unattendedProcess: {
          permission: null
        },
        fleet_management_section: {
          permission: null
        },
        self_supply_section: {
          permission: null
        },
        audit_admin: {
          permission: null
        },
        audits: {
          permission: null
        },
        search_documental: {
          permission: null
        },
        hideMenu: false
      },
      processSearched: false,
      processfields: {
        unattendedProcessStatus: [unattendedResult.PENDING, unattendedResult.RUNNING_NO_ERRORS],
        unattendedProcessView: 1
      },
      visible: false,
      sectionFilter: '',
      // from search page
      dossierIdToArray: [],
      fields: {}
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.auditAdminModal.showModal === false && prevProps.auditAdminModal.showModal === true) {
      this.setState({ visible: false })
    }
    if (!this.props.selectedTabManagement) {
      this.getDefaultSelectedTab()
    }
  }

  componentDidMount () {
    this.initializePermisions(this.props)
  }

  initializePermisions (props) {
    if (!this.props.secMapManagement) {
      const section = props.sectionsGeneral.filter((tabConf) => tabConf.code === sectionPermissions.management)
      if (section.length > 0 && section[0] != null) {
        const newState = getSectionPermissions(section[0])
        let visibleSectionConfiguration = []
        this.state.secMapManagement.forEach(function (item) {
          var permissionCode = newState[item.code]
          if (permissionCode !== undefined) {
            if (permissionCode.permission !== null && permissionCode.permission !== permissions.hidden) {
            // Add to visible sections
              item.active = false
              visibleSectionConfiguration.push(item)
            }
          }
        })
        visibleSectionConfiguration[0].active = true
        this.setState({ secMapManagement: visibleSectionConfiguration })
      }
    }
  }

  getDefaultSelectedTab () {
    let firstTabToShow = null
    Object.keys(this.state.sectionTabsConfiguration).forEach(tab => {
      if (!firstTabToShow && this.state.sectionTabsConfiguration[tab] && this.state.sectionTabsConfiguration[tab].permission > permissions.hidden) {
        switch (tab) {
          case managementTabCode.FLEETS: {
            firstTabToShow = managementTab.FLEETS
            break
          } case managementTabCode.FLEETS_MANAGEMENT: {
            firstTabToShow = managementTab.FLEETS_MANAGEMENT
            break
          } case managementTabCode.UNATTENDED_PROCESS: {
            firstTabToShow = managementTab.UNATTENDED_PROCESS
            break
          } case managementTabCode.FLEETS_MANAGEMENT_SECTION: {
            firstTabToShow = managementTab.FLEETS_MANAGEMENT_SECTION
            break
          } case managementTabCode.SELF_SUPPLT_SECTION: {
            firstTabToShow = managementTab.SELF_SUPPLT_SECTION
            break
          }
        }
      }
    })
    if (firstTabToShow) {
      this.props.actions.managementTabChange(firstTabToShow)
    }
  }

  openAuditAdminModal () {
    this.setState({
      visible: true
    }, () => {
      const auditConfiguration = {
        AuditObservation: false,
        audit: false
      }
      this.props.actions.openAuditAdminModal(auditConfiguration)
    })
  }

  toogleInnerMenu () {
    this.setState({ hideMenu: !this.state.hideMenu })
  }

  filterSections (t) {
    let nameFilter = this.state.sectionFilter
    let secMapManagement = this.props.secMapManagement ?? this.state.secMapManagement

    if (!this.state.sectionFilter) {
      return secMapManagement
    }
    let filtered = secMapManagement.filter(function (item) {
      return t(item.title).toUpperCase().includes(nameFilter.toUpperCase())
    })
    return filtered
  }

  selectSection (item) {
    let visibleSectionConfiguration = []
    this.state.secMapManagement.forEach(element => {
      element.active = element.code === item.code
      visibleSectionConfiguration.push(element)
    })

    this.props.actions.managementSectionChangeSuccess(visibleSectionConfiguration)
    this.props.actions.resetFilters()
  }

  fieldFill (field) {
    this.setState({ fields: { ...this.state.fields, ...field } })
  }

  render () {
    const {
      t, selectedTabManagement,
      showButtonUploadDocument, pages, count, hasSearch, chargeData, papServicesData, dossierData, vehicleData, prepareData, serviceRoleConfig, filters: { page } // props de la sección de buscadores
    } = this.props
    const secMap = MapSections.management
    const tKey = 'MANAGEMENT.'
    const showButton = selectedTabManagement === managementTab.ADMIN_AUDIT
    const availableSections = this.filterSections(t)
    const selectedSection = availableSections.filter(x => x.active === true)[0]
    const className = 'filled'
    const classNameSelect = 'filledSelect'

    return (
      <div className="wrapper _nf">
        <div className='wrapper-header'>
          <div className="title-page">
            <i className={secMap.icon + ' icon-page'} />
            <h2>{t(`${secMap.title}`)}</h2>
          </div>
          <div className='title-page-extra'>
            {showButton && (
              <Button id="btn_delete" className="_Btn new" onClick={() => this.openAuditAdminModal()}><i class="iDocIcon-add-plus"></i>{t(`${tKey}NEW_AUDIT`)}</Button>
            )}
          </div>
        </div>
        <div className='wrapper-body'>
          <div className={this.state.hideMenu ? 'inner-menu-content innerMenuHide' : 'inner-menu-content'}>
            <div className='inner-menu-helpers'>
              <SimpleInputText
                componentClass = 'cajadebusqueda'
                placeholder = {t('SECTIONS.FILTER')}
                value={this.state.sectionFilter}
                onInputChange={sectionFilter => this.setState({ sectionFilter })}
              />
            </div>
            <ul className='inner-items'>
              {
                availableSections.map((item, idx) => {
                  return (
                    <li className={ item.active === true ? 'active' : ''}><p onClick={() => this.selectSection(item)}>{t(`${item.title}`)}</p></li>
                  )
                })
              }
            </ul>
          </div>
          <div className='inner-data-content'>

            {selectedSection !== undefined && selectedSection.page === 'fleetsPage' &&
                <FleetsPage
                  tabId = {1}
                  toogleInnerMenu={() => this.toogleInnerMenu()}
                  hideMenu={this.state.hideMenu}
                  fetchManagementActions = {this.props.actions.fetchManagementActions}
                  managementActions = {this.props.combos.managementActions}
                  sectionsGeneral = {this.props.sectionsGeneral}
                ></FleetsPage>}

            {selectedSection !== undefined && selectedSection.page === 'fleetFinderPage' &&
                <FleetFinderPage
                  toogleInnerMenu={() => this.toogleInnerMenu()}
                  hideMenu={this.state.hideMenu}>
                </FleetFinderPage>}

            {selectedSection !== undefined && selectedSection.page === 'unattendedProcessesFinderPage' &&
                <UnattendedProcessesFinderPage
                  toogleInnerMenu={() => this.toogleInnerMenu()}
                  hideMenu={this.state.hideMenu}>
                </UnattendedProcessesFinderPage>}

            {selectedSection !== undefined && selectedSection.page === 'fleetsManagerPage' &&
                <FleetsManagerPage
                  sectionsGeneral = {this.props.sectionsGeneral}
                  toogleInnerMenu={() => this.toogleInnerMenu()}
                  hideMenu={this.state.hideMenu}>
                </FleetsManagerPage> }

            {selectedSection !== undefined && selectedSection.page === 'selfSupplyManagementPage' &&
                <SelfSupplyManagementPage
                  selectedTabManagement = {selectedTabManagement}
                  toogleInnerMenu={() => this.toogleInnerMenu()}
                  hideMenu={this.state.hideMenu}>
                </SelfSupplyManagementPage>}

            {selectedSection !== undefined && selectedSection.page === 'auditAdminPage' &&
                    <AuditAdminPage
                      selectedTabManagement = {selectedTabManagement}
                      toogleInnerMenu={() => this.toogleInnerMenu()}
                      hideMenu={this.state.hideMenu}>
                    </AuditAdminPage>}

            {selectedSection !== undefined && selectedSection.page === 'auditsPage' &&
                    <AuditsPage
                      selectedTabManagement = {selectedTabManagement}
                      toogleInnerMenu={() => this.toogleInnerMenu()}
                      hideMenu={this.state.hideMenu}>
                    </AuditsPage>}

            {selectedSection !== undefined && selectedSection.page === 'searchDocumentalPage' &&
                    <SearchDocumentalPage
                      selectedTabManagement = {selectedTabManagement}
                      toogleInnerMenu={() => this.toogleInnerMenu()}
                      hideMenu={this.state.hideMenu}>
                    </SearchDocumentalPage>}

            {selectedSection !== undefined && selectedSection.page === 'searchCharge' &&
                <SearchByChargeFormPage // COBROS
                  t={t}
                  change={this.props.change}
                  fields={this.state.fields}
                  organizedUnitId={''}
                  fieldFill={(val) => this.fieldFill(val)}
                  className={className}
                  classNameSelect={classNameSelect}
                  hasSearch={hasSearch}
                  chargeData={chargeData}
                  permissions = {this.state.sectionTabsConfiguration.charge && this.state.sectionTabsConfiguration.charge && this.state.sectionTabsConfiguration.charge.sectionFieldsConfiguration ? this.state.sectionTabsConfiguration.charge.sectionFieldsConfiguration : null }
                  toogleInnerMenu={() => this.toogleInnerMenu()}
                  hideMenu={this.state.hideMenu}
                />}

            {selectedSection !== undefined && selectedSection.page === 'searchByPAP' &&
              <SearchByPrepareFormPage // GESTION DE PREPARACIONES
                t={t}
                change={this.props.change}
                prepareData = {prepareData}
                serviceRoleConfig = {serviceRoleConfig}
                fields={this.state.fields}
                fieldFill={(val) => this.fieldFill(val)}
                className={className}
                classNameSelect={classNameSelect}
                hasSearch={hasSearch}
                permissions={this.state.sectionTabsConfiguration.prepare && this.state.sectionTabsConfiguration.prepare.sectionFieldsConfiguration ? this.state.sectionTabsConfiguration.prepare.sectionFieldsConfiguration : null}
                fetchServicePrepareRoleConfig = {this.props.actions.fetchServicePrepareRoleConfig}
                toogleInnerMenu={() => this.toogleInnerMenu()}
                hideMenu={this.state.hideMenu}
              />}
            {selectedSection !== undefined && selectedSection.page === 'searchByPapServices' &&
              <SearchByPapFormPage // GESTION MASIVA DE CAMPA
                t={t}
                change={this.props.change}
                fields={this.state.fields}
                organizedUnitId={''}
                fieldFill={(val) => this.fieldFill(val)}
                className={className}
                classNameSelect={classNameSelect}
                hasSearch={hasSearch}
                dossierData={papServicesData}
                page={page}
                pages={pages}
                count={count}
                permissions = {this.state.sectionTabsConfiguration?.papServicesManager && this.state.sectionTabsConfiguration.papServicesManager.sectionFieldsConfiguration ? this.state.sectionTabsConfiguration.papServicesManager : null }
                toogleInnerMenu={() => this.toogleInnerMenu()}
                hideMenu={this.state.hideMenu}
              />}

            {selectedSection !== undefined && selectedSection.page === 'searchsPlanLogisticsPap' &&
              <SearchByPlanLogisticsPapFormPage // PLANIFICAR PAP LOGISTICA
                t={t}
                change={this.props.change}
                fields={this.state.fields}
                organizedUnitId={''}
                fieldFill={(val) => this.fieldFill(val)}
                className={className}
                classNameSelect={classNameSelect}
                hasSearch={hasSearch}
                dossierData={papServicesData}
                page={page}
                pages={pages}
                count={count}
                toogleInnerMenu={() => this.toogleInnerMenu()}
                openModal ={this.props.actions.openModal}
              />}

            {selectedSection !== undefined && selectedSection.page === 'searchByFleets' &&
                                <SearchByFleetsFormPage // FLOTAS (SE DENOMINARÁ BÚSQUEDA DE FLOTAS)
                                  t={t}
                                  change={this.props.change}
                                  fields={this.state.fields}
                                  organizedUnitId={''}
                                  fieldFill={(val) => this.fieldFill(val)}
                                  className={className}
                                  classNameSelect={classNameSelect}
                                  hasSearch={hasSearch}
                                  dossierData={dossierData}
                                  vehicleData={vehicleData}
                                  toogleInnerMenu={() => this.toogleInnerMenu()}
                                  page={page}
                                  pages={pages}
                                  count={count}
                                  showButtonUploadDocument = {showButtonUploadDocument}
                                />
            }

            { this.state.visible &&
              <AuditModalPage
                readOnly={false}
                isAdmin={true}
              />
            }
          </div>
        </div>

      </div>
    )
  }
}

export default reduxForm({
  form: 'management'
})(Management)
