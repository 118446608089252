import messageModalActionTypes from '../../constants/actions/modals/messageModal'

export function openSuccessMessageModal (tKeyMessage, tKeyFilteredByTag, others) {
  return {
    type: messageModalActionTypes.SUCCESS_MESSAGE_MODAL,
    tKeyMessage,
    tKeyFilteredByTag,
    others
  }
}

export function openErrorMessageModal (tKeyMessage, backendFilteredTag, sufixErrorTag, errorCode, others) {
  return {
    type: messageModalActionTypes.ERROR_MESSAGE_MODAL,
    tKeyMessage,
    backendFilteredTag,
    sufixErrorTag,
    errorCode,
    others
  }
}

export function closeMessageModal () {
  return {
    type: messageModalActionTypes.CLOSE_MESSAGE_MODAL
  }
}

export default {
  openSuccessMessageModal,
  openErrorMessageModal,
  closeMessageModal
}
