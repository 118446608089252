import React, { PureComponent } from 'react'
import { Field, reduxForm } from 'redux-form'
import { Form, OverlayTrigger, Button, Tooltip } from 'react-bootstrap'
import { validateDate, validateDates } from '../../../util/validationFunctions'
import SimpleTablePage from '../../../containers/commons/general/SimpleTablePage'
import IDocCarPagination from '../../../components/commons/iDocCarPagination'
import InputCheckbox from '../commons/form/InputCheckBox'
import moment from 'moment'
import { formatDate } from '../../../util/utils'
import ApprovedServicesModal from '../../../_v2/components/management/papServiceManager/ApprovedServicesModal'
import settings from '../../../setting'

import MapSectionsManagement from '../../../_v2/util/map-sections-management'
import VehiclePopover from '../../../_v2/components/search/VehiclePopover'
import DossierPopover from '../../../_v2/components/search/DossierPopover'
import { getIconClass } from '../../../_v2/util/management/ManagementUtils'

class SearchByPlanLogisticsPapForm extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      enableConfirmButton: false,
      showConfirmButton: true,
      dossierIdArray: [],
      showResultApproveModal: false,
      selectAllDossiers: false,
      resultApprove: {}
    }
  }

  validateDate (value, props) {
    const result = validateDate(value, props)
    return result === Object(result) ? undefined : result
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (this.props.serviceRoleConfig &&
      ((JSON.stringify(prevProps.serviceRoleConfig) !== JSON.stringify(this.props.serviceRoleConfig)) ||
      !this.state.serviceRoleConfig)) {
      if (this.props.serviceRoleConfig.setStage) {
        let stages = []
        this.state.stages.map(x => {
          if (this.props.serviceRoleConfig.stages.includes(x.id)) {
            stages.push(x)
          }
        })
        let serviceRoleConfigStagesChanged = false
        if (JSON.stringify(stages) !== JSON.stringify(this.state.stages)) {
          this.setState({ stages: stages })
          serviceRoleConfigStagesChanged = true
        }
        const stageFilterIsCleared = (!this.props.fields.stage || this.props.fields.stage.length < 0) && stages.length > 0
        if (stageFilterIsCleared || serviceRoleConfigStagesChanged) {
          let arrStages = []
          arrStages.push(stages[0]?.id)
          this.props.fieldFill({ stage: arrStages })
          this.props.change('servicePreparationDelivery.stageId', arrStages)
        }
      }
      if (this.props.serviceRoleConfig.setDate) {
        let strdate = formatDate()
        if (this.props.fields.requiredDateFrom === undefined) {
          this.props.fieldFill({ requiredDateFrom: strdate })
          this.props.change('servicePreparationDelivery.requiredDateFrom', strdate)
        }
        if (this.props.fields.requiredDateTo === undefined) {
          this.props.fieldFill({ requiredDateTo: strdate })
          this.props.change('servicePreparationDelivery.requiredDateTo', strdate)
        }
      }
    }

    return null
  }

  componentDidMount () {
    this.props.actions.fetchPlanLogiticsPap({ ...this.props.filters })
    this.setState({ filterValues: { ...this.props.filters } })
  }

  componentDidUpdate (prevProps) {
    if (this.props.hasSearch !== prevProps.hasSearch && this.props.hasSearch) {
      this.restartState()
    }
  }

  submitSearchForm (values) {
    this.props.actions.fetchPlanLogiticsPap({ ...this.props.filters, ...values.plan_logistics_pap })
    this.props.actions.setFilters(values)
  }

  onPageChange (page) {
    this.setState({ filterValues: { ...this.state.filterValues, page: page } })
    this.props.actions.fetchPlanLogiticsPap({ ...this.state.filterValues, page })
  }

  onOrderChange (e) {
    const newOrderBy = this.props.filters.orderBy.includes(`%2B${e.currentTarget.dataset.field}`) ? `-${e.currentTarget.dataset.field}` : `%2B${e.currentTarget.dataset.field}`
    this.setState({ filterValues: { ...this.state.filterValues, orderBy: newOrderBy } })
    this.props.actions.fetchPlanLogiticsPap({ ...this.state.filterValues, orderBy: newOrderBy })
  }

  restartState () {
    this.setState({
      enableConfirmButton: false,
      dossierIdArray: [],
      showResultApproveModal: false,
      selectAllDossiers: false
    })
  }

  selectAllDossiers (value) {
    const dossiers = []
    if (this.props.papServicesData) {
      this.props.papServicesData.map((row, idx) => {
        if (value) {
          dossiers.push(row.dossierId)
        }
      })
    }
    this.setState({ selectAllDossiers: value, enableConfirmButton: dossiers.length, dossierIdArray: dossiers })
  }

  setCheckboxValue (dossierId, check) {
    const dossierIdArray = this.state.dossierIdArray
    const index = dossierIdArray.findIndex(d => d === dossierId)
    if (index < 0) {
      dossierIdArray.push(dossierId)
    } else {
      dossierIdArray.splice(index, 1)
    }
    const selectedDossiersCount = dossierIdArray.length
    this.setState({ dossierIdArray: dossierIdArray, enableConfirmButton: selectedDossiersCount > 0 })

    if (!check && this.state.selectAllDossiers) {
      this.setState({ selectAllDossiers: false })
    }

    if (this.props.papServicesData && dossierIdArray.length === this.props.papServicesData.length) {
      this.setState({ selectAllDossiers: true })
    }
  }

  getCheckboxValue (dossierId) {
    return this.state.dossierIdArray.includes(dossierId)
  }

  showResultApproveModal () {
    new Promise((resolve, reject) => {
      return this.props.actions.saveServicesConfirmationLogitics(this.state.dossierIdArray, resolve, reject)
    }).then((data) => {
      this.setState({ showResultApproveModal: true, resultApprove: data })
      this.selectAllDossiers(false)
    })
  }

  closeConfirmationModal () {
    this.setState({ showResultApproveModal: false })
    this.props.onPageChange(this.props.page)
  }

  componentWillUnmount () {
    this.restartState()
  }

  toogleHideFilters () {
    this.setState({ hideFilters: !this.state.hideFilters })
  }

  toogleMaxiTable () {
    this.setState({ maxiTable: !this.state.maxiTable })
  }

  render () {
    const {
      t, pages, page, count, vehicleData = [],
      papServicesData, handleSubmit, filters // From search
    } = this.props
    const tKey = 'SEARCH.CATEGORIES.PLAN_LOGISTICS_PAP.'
    const secMapManagement = MapSectionsManagement.find(function (s) { return s.page === 'searchsPlanLogisticsPap' })
    const hasResults = papServicesData && papServicesData.length > 0

    return (
      <div className="inner-results-panel">
        <div className="section-results-header">
          <div className="section-header-title">
            <OverlayTrigger placement="right" overlay={<Tooltip id="editProductTooltip">{`${t((this.props.hideMenu ? 'GLOBAL.TABLES.SHOW_INNER_MENU' : 'GLOBAL.TABLES.HIDE_INNER_MENU'))}`}</Tooltip>}>
              <a onClick={() => this.props.toogleInnerMenu()} className={this.props.hideMenu ? 'collapseBtn' : 'collapseBtn closeInnerMenu'}><i className={this.props.hideMenu ? 'iDocIcon-Open-inner-menu' : 'iDocIcon-Close-inner-menu'}></i></a>
            </OverlayTrigger>
            <h4>{t(`${secMapManagement.title}`)}
            </h4>
          </div>
          <Form autoComplete="off" onSubmit={handleSubmit((values) => this.submitSearchForm(values))} className="filters">
            <ApprovedServicesModal
              showModal = {this.state.showResultApproveModal}
              closeModal = {() => this.closeConfirmationModal() }
              resultApprove = {this.state.resultApprove}
              vehicleData
              filters = {filters}
              t = {t}
            />
          </Form>
        </div>
        <div className="section-results-body">
          <div className={'searchResoultsContainer' + (this.state.maxiTable ? ' maxiTable' : '')}>
            {hasResults &&
              <div className="table-wrapper" key="table-wrapper">
                {/* table options, maximize, etc.... */}
                <div className='flexInLine al-R'>
                  <div className='actionsField'>
                  </div>
                  <OverlayTrigger placement="left" overlay={<Tooltip id="editProductTooltip">{`${t((this.state.maxiTable ? 'GLOBAL.TABLES.MINIMIZE_TABLE' : 'GLOBAL.TABLES.MAXIMIZE_TABLE'))}`}</Tooltip>}>
                    <a onClick={() => this.toogleMaxiTable()} className='_Btn accept stk o-icn maximizeBtn'><i className={(this.state.maxiTable ? 'iDocIcon-Minimize' : 'iDocIcon-Maximize')}></i></a>
                  </OverlayTrigger>
                </div>
                {/* table */}
                <div className="table-responsive">
                  <SimpleTablePage
                    columns={[
                      <th key={0} data-field="dossier" onClick={(event) => this.onOrderChange(event)}>
                        {t(`${tKey}DOSSIER`)}&nbsp;<i className={getIconClass('dossier', filters)}/>
                      </th>,
                      <th key={1} data-field="vehicle">
                        {t(`${tKey}VEHICLE`)}&nbsp;
                      </th>,
                      <th key={2} data-field="applicationDate" onClick={(event) => this.onOrderChange(event)}>
                        {t(`${tKey}APPLICATION_DATE`)}&nbsp;<i className={getIconClass('applicationDate', filters)}/>
                      </th>,
                      <th key={3} data-field="availabilityDate" onClick={(event) => this.onOrderChange(event)}>
                        {t(`${tKey}AVAILABILITY_DATE`)}&nbsp;<i className={getIconClass('availabilityDate', filters)}/>
                      </th>,
                      <th key={4} data-field="requiredDate" onClick={(event) => this.onOrderChange(event)}>
                        {t(`${tKey}REQUIRED_DATE`)}&nbsp;<i className={getIconClass('requiredDate', filters)}/>
                      </th>,
                      this.state.showConfirmButton && (
                        <th key={5} data-field="check" >
                          <Field
                            id='chkSelectAll'
                            component={InputCheckbox}
                            name='checkAll'
                            onChange={(value) => this.selectAllDossiers(value)}
                            // placeholder = {t(`${tKey}SELECT_ALL`)}
                            // inline
                            checked = { this.state.selectAllDossiers }
                          />
                        </th>)
                    ]}
                    rows={papServicesData.map((row, idx) => (
                      <tr key={idx}>
                        <td className={'dossier see-more'}>
                          <DossierPopover
                            idx={idx}
                            t={t}
                            id={'popover-dossier' + idx}
                            dossier={row.dossier}
                          />
                        </td>
                        <td className="see-more">
                          <VehiclePopover
                            t={t}
                            vehicle={row.vehicle}
                            half={(vehicleData.length || 1) / (idx + 1)}
                            idx={idx}
                            id={'popover-vehicle' + idx}
                          />
                        </td>
                        <td>{row.applicationDate ? moment(row.applicationDate).format('DD/MM/YYYY') : null}</td>
                        <td>{row.availabilityDate ? moment(row.availabilityDate).format('DD/MM/YYYY') : null}</td>
                        <td>{row.requiredDate ? moment(row.requiredDate).format('DD/MM/YYYY') : null}</td>
                        { this.state.showConfirmButton && (
                          <td>{<Field
                            component={InputCheckbox}
                            name={'selectedDossier' + row.dossierId}
                            onChange={(value) => this.setCheckboxValue(row.dossierId, value)}
                            checked = {this.getCheckboxValue(row.dossierId)}
                          />}</td>)}
                      </tr>
                    ))}
                  />
                </div>
                <div key="search-footer-actions" className="search-footer-actions">
                  { this.state.showConfirmButton &&
                      <Button
                        id='btm_appr_pap'
                        name='approvePapButton'
                        onClick={() => {
                          this.props.actions.openModal('confirmLogitics', () => this.showResultApproveModal(), null, null, null,
                            [{ id: '##COUNT##', value: this.state.dossierIdArray.length ?? 0 }])
                        }
                        }
                        className="btn-AcceptPap"
                        disabled={!this.state.enableConfirmButton } >
                        {t(`${tKey}CONFIRM`)}
                      </Button>
                  }
                </div>
                <div className="search-footer" key="search-footer">
                  <IDocCarPagination
                    id="btn_pag_dossiers"
                    page={page}
                    pagesCount={pages}
                    totalRows= {count}
                    onSelectAction={(page) => this.onPageChange(page)}
                  />
                </div>

              </div>
            }
          </div>
        </div>
      </div>
    )
  }
}

const validate = (values, { t }) => {
  const errors = {}
  if (values) {
    if (values.dossier && values.dossier.chassis && values.dossier.chassis.length < settings.chassisMinCharacter) {
      errors.dossier = {}
      errors.dossier.chassis = t('SEARCH.CATEGORIES.ERRORS.VINCHASIS').replace('##MIN_CHARACTERS##', settings.chassisMinCharacter)
    }
    if (values.vehicle && values.vehicle.chassis && values.vehicle.chassis.length < settings.chassisMinCharacter) {
      errors.vehicle = {}
      errors.vehicle.chassis = t('SEARCH.CATEGORIES.ERRORS.VINCHASIS').replace('##MIN_CHARACTERS##', settings.chassisMinCharacter)
    }

    if (values.dossier) {
      errors.dossier = {}
      if (values.dossier && values.dossier.creationDateFrom && values.dossier.creationDateTo) {
        errors.dossier.creationDateTo = validateDates(t, values.dossier.creationDateFrom, values.dossier.creationDateTo)
      }
      if (values.dossier && values.dossier.orderDateFrom && values.dossier.orderDateTo) {
        errors.dossier.orderDateTo = validateDates(t, values.dossier.orderDateFrom, values.dossier.orderDateTo)
      }
      if (values.dossier && values.dossier.availabilityDateFrom && values.dossier.availabilityDateTo) {
        errors.dossier.availabilityDateTo = validateDates(t, values.dossier.availabilityDateFrom, values.dossier.availabilityDateTo)
      }
      if (values.dossier && values.dossier.vehicleLicensePlateDateFrom && values.dossier.vehicleLicensePlateDateTo) {
        errors.dossier.vehicleLicensePlateDateTo = validateDates(t, values.dossier.vehicleLicensePlateDateFrom, values.dossier.vehicleLicensePlateDateTo)
      }
    }
  }
  return errors
}

export default reduxForm({
  form: 'SearchByPlanLogisticsPapForm',
  validate
})(SearchByPlanLogisticsPapForm)
