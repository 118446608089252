import React, { PureComponent } from 'react'
import { Button, Modal, Col, Row, Form } from 'react-bootstrap'
import { reduxForm, Field } from 'redux-form'
import InputText from '../../../components/commons/form/InputText'

class CancelSignModal extends PureComponent {
  rejectActionPromise (values) {
    return new Promise((resolve, reject) => {
      return this.props.actions.rejectAction(values.text, resolve, reject)
    })
  }

  rejectAction (values) {
    this.rejectActionPromise(values).then(() => {
      this.props.actions.cancelSign(this.props.documentId, null, this.props.actions.callback, this.props.refreshAfterChange, this.props.multipleSignCancel, this.props.forceCancelDocument)
      this.props.actions.closeModal()
    })
  }

  render () {
    const { t, handleSubmit, showModal, actions: { closeModal }, title, subTitle, placeHolder, buttonText, cancelDocList, buttonCncelText } = this.props
    const tKey = 'DOSSIER_COMPONENTS.CANCEL_SIGN_MODAL.'
    const _title = title ?? 'TITLE'
    const _subTitle = subTitle ?? 'SUBTITLE'
    const _placeHolder = placeHolder ?? 'PLACEHOLDER'
    const _buttonText = buttonText ?? 'CANCEL_BUTTON'
    const _buttonCncelText = buttonCncelText ?? 'NO_CANCEL_BUTTON'
    return (
      <Modal className="add-user-modal" show={showModal} onHide={closeModal}>
        <Form autoComplete="off" onSubmit={handleSubmit(this.rejectAction.bind(this))}>
          <Modal.Header closeButton>
            <Modal.Title>{t(`${tKey}${_title}`)}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {
              cancelDocList && cancelDocList.length > 0
                ? <Row className="cancelDataModal">
                  <p>{t(`${tKey}${_subTitle}`)}</p>
                </Row> : null
            }
            {
              cancelDocList && cancelDocList.length > 0
                ? cancelDocList.map((it, idx) => {
                  return <Row className="cancelDataModal" key={idx}>
                    <p>{it}</p>
                  </Row>
                }) : null
            }
            <Row>
              <Field
                id="text"
                name="text"
                componentClass="textarea"
                colSm={12}
                placeholder={t(`${tKey}${_placeHolder}`)}
                component={InputText}
                customClass="comment-reject-modal-textarea"
              />
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Col sm={12} className="btn-wrapper">
              <Button id="btn_save" className="btn-standard" type="submit">{t(`${tKey}${_buttonText}`)}</Button>
              <Button id="btn_no_modal" className="btn-reject-white" onClick={closeModal}>{t(`${tKey}${_buttonCncelText}`)}</Button>
            </Col>
          </Modal.Footer>
        </Form>
      </Modal>
    )
  }
}
const validate = (values, { t, reasonCombo, commentValidation }) => {
  const tKey = 'DOSSIER_COMPONENTS.CANCEL_SIGN_MODAL.'
  const errors = {}
  if (!values.text) errors.text = t(`${tKey}${commentValidation || 'COMMENT_MANDATORY'}`)
  return errors
}

export default reduxForm({
  form: 'cancel_sign_modal',
  validate
})(CancelSignModal)
