import { put, call, takeEvery, select } from 'redux-saga/effects'
import { reset, change, initialize } from 'redux-form'
import { yesNoModal } from '../../modalsListeners/yesNoModal'
import dossierActionsTypes from '../../../constants/actions/dossier/dossier'
import { dossierTypes as dossierTypesConstant } from '../../../constants/dossier/common/dossierTypes'
import messageModalActions from '../../../actions/modals/messageModal'
import { updateAssociatedCostsSuccess } from '../../../actions/dossier/sales'
import { hideLoader } from '../../../actions/common'
import { handleError } from '../../errors/errorManager'
import { initializeDossier } from '../commonDossierSagas'
import { v4 as uuidv1 } from 'uuid'
import { updateEconomicPlanFleet } from '../../../actions/management/management'

export function * createAssociatedCosts ({ newAssociatedCost, resolve, reject, dossierType }) {
  try {
    let stateDossier, economicState, dossierForm, formName, updateFunction

    if (dossierType === dossierTypesConstant.fleetEconomicPlan) {
      stateDossier = yield select(state => state.management.economicPlanModal)
      economicState = JSON.parse(JSON.stringify(stateDossier.dossierEconomicComponent))
      dossierForm = yield select(state => state.form.economicPlanModal.values.dossierEconomicComponent)
      formName = 'economicPlanModal'
      updateFunction = updateEconomicPlanFleet
    } else {
      stateDossier = yield select(state => state.salesDossier)
      economicState = JSON.parse(JSON.stringify(stateDossier.dossierEconomicComponent))
      dossierForm = yield select(state => state.form.salesDossier.values.dossierEconomicComponent)
      formName = 'salesDossier'
      updateFunction = updateAssociatedCostsSuccess
    }

    let newEconomic = JSON.parse(JSON.stringify(dossierForm))
    // changes(add & delete items changes state not form)
    newEconomic.associatedCosts = economicState.associatedCosts
    newAssociatedCost.dossierId = stateDossier.dossierId
    newAssociatedCost.amount = parseFloat(newAssociatedCost.amount)
    newAssociatedCost.cost = parseFloat(newAssociatedCost.cost)
    newAssociatedCost.indexFront = uuidv1()
    newEconomic.associatedCosts.push(newAssociatedCost)
    if (dossierType === dossierTypesConstant.fleetEconomicPlan) {
      yield put(updateFunction(newEconomic))
    } else {
      yield put(updateFunction(newEconomic.associatedCosts))
    }
    yield put(change(formName, 'dossierEconomicComponent', newEconomic))
    yield put(messageModalActions.openSuccessMessageModal('MESSAGES.POST_TABLE_ITEM_SUCCESS'))
    yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          const salesDossierState = yield select(state => state.salesDossier)
          yield call(initializeDossier, dossierTypesConstant.sales, salesDossierState)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('MESSAGES.POST_TABLE_ITEM_ERROR', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
      yield call(reject, customHandlerError)
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchCreateAssociatedCosts () {
  yield takeEvery(dossierActionsTypes.CREATE_ASSOCIATED_COST, createAssociatedCosts)
}

export function * deleteAssociatedCost ({ associatedCost, dossierType }) {
  try {
    let stateDossier, economicState, dossierForm, formName, updateFunction

    if (dossierType === dossierTypesConstant.fleetEconomicPlan) {
      stateDossier = yield select(state => state.management.economicPlanModal)
      economicState = JSON.parse(JSON.stringify(stateDossier.dossierEconomicComponent))
      dossierForm = yield select(state => state.form.economicPlanModal.values.dossierEconomicComponent)
      formName = 'economicPlanModal'
      updateFunction = updateEconomicPlanFleet
    } else {
      stateDossier = yield select(state => state.salesDossier)
      economicState = JSON.parse(JSON.stringify(stateDossier.dossierEconomicComponent))
      dossierForm = yield select(state => state.form.salesDossier.values.dossierEconomicComponent)
      formName = 'salesDossier'
      updateFunction = updateAssociatedCostsSuccess
    }

    let newEconomic = JSON.parse(JSON.stringify(dossierForm))
    // changes(add & delete items changes state not form)
    newEconomic.associatedCosts = economicState.associatedCosts
    let index = associatedCost.id ? newEconomic.associatedCosts.findIndex(d => d.id === associatedCost.id)
      : newEconomic.associatedCosts.findIndex(d => d.indexFront === associatedCost.indexFront)
    newEconomic.associatedCosts.splice(index, 1)
    if (dossierType === dossierTypesConstant.fleetEconomicPlan) {
      yield put(updateFunction(newEconomic))
    } else {
      yield put(updateFunction(newEconomic.associatedCosts))
    }
    yield put(change(formName, 'dossierEconomicComponent', newEconomic))
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          const salesDossierState = yield select(state => state.salesDossier)
          yield call(initializeDossier, dossierTypesConstant.sales, salesDossierState)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDeleteAssociatedCosts () {
  yield takeEvery(dossierActionsTypes.DELETE_ASSOCIATED_COST, deleteAssociatedCost)
}

export function * updateAssociatedCost ({ associatedCost, resolve, reject, dossierType }) {
  try {
    let stateDossier, economicState, dossierForm, formName, updateFunction

    if (dossierType === dossierTypesConstant.fleetEconomicPlan) {
      stateDossier = yield select(state => state.management.economicPlanModal)
      economicState = JSON.parse(JSON.stringify(stateDossier.dossierEconomicComponent))
      dossierForm = yield select(state => state.form.economicPlanModal.values.dossierEconomicComponent)
      formName = 'economicPlanModal'
      updateFunction = updateEconomicPlanFleet
    } else {
      stateDossier = yield select(state => state.salesDossier)
      economicState = JSON.parse(JSON.stringify(stateDossier.dossierEconomicComponent))
      dossierForm = yield select(state => state.form.salesDossier.values.dossierEconomicComponent)
      formName = 'salesDossier'
      updateFunction = updateAssociatedCostsSuccess
    }

    let newEconomic = JSON.parse(JSON.stringify(dossierForm))

    // changes(add & delete items changes state not form)
    newEconomic.associatedCosts = economicState.associatedCosts
    let index = associatedCost.id ? newEconomic.associatedCosts.findIndex(d => d.id === associatedCost.id)
      : newEconomic.associatedCosts.findIndex(d => d.indexFront === associatedCost.indexFront)
    associatedCost.amount = parseFloat(associatedCost.amount)
    associatedCost.cost = parseFloat(associatedCost.cost)
    newEconomic.associatedCosts[index] = associatedCost
    if (dossierType === dossierTypesConstant.fleetEconomicPlan) {
      yield put(updateFunction(newEconomic))
    } else {
      yield put(updateFunction(newEconomic.associatedCosts))
    }
    yield put(change(formName, 'dossierEconomicComponent', newEconomic))
    yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          const salesDossierState = yield select(state => state.salesDossier)
          yield call(initializeDossier, dossierTypesConstant.sales, salesDossierState)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
      yield call(reject, customHandlerError)
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchUpdateAssociatedCosts () {
  yield takeEvery(dossierActionsTypes.UPDATE_ASSOCIATED_COST, updateAssociatedCost)
}

export function * fetchAssociatedCostByPercentageModal ({
  additionalCostId,
  additionalCostIndexFront,
  associatedCosts,
  totalAmount,
  cost,
  costByPercentage,
  pristine,
  dossierType
}) {
  try {
    let formName, formDossier
    if (dossierType === dossierTypesConstant.fleetEconomicPlan) {
      formName = 'economicPlanModal'
    } else {
      formName = 'salesDossier'
    }

    if (additionalCostId || additionalCostIndexFront) {
      if (dossierType === dossierTypesConstant.fleetEconomicPlan) {
        let stateDossier = yield select(state => state.management.economicPlanModal)
        let economicState = JSON.parse(JSON.stringify(stateDossier.dossierEconomicComponent))
        economicState.associatedCosts.find(ac => ac.indexFront === additionalCostIndexFront)
          .cost = parseFloat(cost)
        let dossierForm = yield select(state => state.form.economicPlanModal.values.dossierEconomicComponent)
        let newEconomic = JSON.parse(JSON.stringify(dossierForm))
        newEconomic.associatedCosts = economicState.associatedCosts
        yield put(updateEconomicPlanFleet(newEconomic))
        yield put(change(formName, 'dossierEconomicComponent', newEconomic))
        associatedCosts = economicState.associatedCosts
      } else {
        associatedCosts.find(ac => ac.id === additionalCostId).cost = parseFloat(cost)
        yield put(change(formName, 'dossierEconomicComponent.associatedCosts', associatedCosts))
      }
    }
    if (totalAmount) yield put(change(formName, 'associatedCost.operationTotalAmount', totalAmount))
    if (cost) yield put(change(formName, 'associatedCost.cost', cost))
    if (costByPercentage) {
      yield put(change(formName, 'associatedCost.costByPercentage',
        costByPercentage))
    }

    if (dossierType === dossierTypesConstant.fleetEconomicPlan) {
      formDossier = JSON.parse(JSON.stringify(yield select(state => state.form.economicPlanModal.values)))
    } else {
      formDossier = JSON.parse(JSON.stringify(yield select(state => state.form.salesDossier.values)))
    }

    if (pristine) {
      yield put(initialize(formName, formDossier))
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions
        .openErrorMessageModal('MESSAGES.SALES_DOSSIER.ERROR_ASSOCIATED_COST_BY_PERCENTAGE_MODAL_DATA',
          customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    yield call(handleError, { error, customHandler })
  }
}

export function * watchFetchAssociatedCostByPercentageModal () {
  yield takeEvery(dossierActionsTypes.FETCH_ASSOCIATED_COST_BY_PERCENTAGE_MODAL,
    fetchAssociatedCostByPercentageModal)
}
