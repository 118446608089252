import { call, takeEvery } from 'redux-saga/effects'
import dossierActionsTypes from '../../../constants/actions/dossier/dossier'
import { yesNoModal } from '../../modalsListeners/yesNoModal'

export function * stockDossierWarningSave ({ reset, router, nextLocation, pristine }) {
  if (!pristine) {
    const confirmedSaveWarning = yield call(yesNoModal, 'commonDossierSaveWarning')
    if (confirmedSaveWarning) {
      const promise = new Promise((resolve, reject) => {
        reset('stock_dossier')
        resolve()
      })
      promise.then(() => {
        router.replace(nextLocation)
      })
    }
  }
}

export function * watchStockDossierWarningSave () {
  yield takeEvery(dossierActionsTypes.STOCK_DOSSIER_WARNING_SAVE, stockDossierWarningSave)
}
