import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { closeAssociatedCostsModal, saveAssociatedCostsModal } from '../../actions/masters/masters'
import AssociatedCostsModal from '../../components/masters/AssociatedCostsModal'

export function mapStateToProps (state) {
  return {
    showAssociatedCostsModal: state.entityMasters.showAssociatedCostsModal,
    languageList: state.entityMasters.languageList,
    associatedCostTypeLanguages: state.entityMasters.associatedCostsFields.associatedCostTypeLanguages || [],
    associatedCostsId: state.entityMasters.associatedCostsId
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      closeAssociatedCostsModal,
      saveAssociatedCostsModal
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(AssociatedCostsModal))
