// import apiFetch from '../apiFetch'

export default function (token, dossierSubtypeId) {
  // return apiFetch({endPoint: `dossierStage?dossierSubtypeId=${dossierSubtypeId}`, method: 'GET', body: null, token: token})
  let stages = [
    {
      name: 'Provisional',
      skipped: false,
      isActive: true,
      docs: [
        { name: 'Propuesta de venta', documentTypeUse: 62, documentParentTypeId: 3, objectId: 553, organizedUnitId: 6, checklistId: 8228, isComplete: false, validationRequired: false }
      ],
      fields: [
        { name: 'Datos del vehículo o pedido', componentAction: 2, isComplete: false, validationRequired: false },
        { name: 'Datos del cliente', componentAction: 3, isComplete: false, validationRequired: false },
        { name: 'Tipo de venta y operación', componentAction: 1, isComplete: false, validationRequired: false },
        { name: 'Forma de cobro obligatoria', componentAction: 1, isComplete: false, validationRequired: false }
      ],
      others: [
        { name: 'Venta enviada aprobar', isComplete: false, validationRequired: false }
      ]
    },
    { name: 'Aprobación', skipped: false, isActive: false, docs: [], fields: [], others: [] },
    {
      name: 'Disposición',
      skipped: false,
      isActive: false,
      docs: [],
      fields: [
        { name: 'Rellenar campo Disposición', componentAction: 2, isComplete: false, validationRequired: false }
      ],
      others: []
    },
    { name: 'Cobro', skipped: false, isActive: false, docs: [], fields: [], others: [] },
    { name: 'Documentación', skipped: false, isActive: false, docs: [], fields: [], others: [] },
    { name: 'Tramitación', skipped: false, isActive: false, docs: [], fields: [], others: [] },
    { name: 'Prep. Entrega', skipped: false, isActive: false, docs: [], fields: [], others: [] },
    { name: 'Entrega', skipped: false, isActive: false, docs: [], fields: [], others: [] },
    { name: 'Doc. Final', skipped: false, isActive: false, docs: [], fields: [], others: [] }
  ]
  stages = stages.filter((value, idx) => { return idx < 9 })
  return stages
}
