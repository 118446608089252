import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { getFormValues, getFormSyncErrors } from 'redux-form'
import userOrganization from '../../../_v2/components/profile/UserOrganization'
import profileActions from '../../../actions/profile/profile'
import { fetchDeviceInstalls, fetchMaxDeviceInstalls, updateDeviceInstall } from '../../../actions/idocmobile/deviceInstall'
import { openErrorMessageModal } from '../../../actions/modals/messageModal'
import { sendMailAppDownload } from '../../../actions/idocmobile/appDownload'

export function mapStateToProps (state) {
  return {
    ...state.profile,
    ...state.auth,
    formValues: getFormValues('updateProfile')(state),
    formErrors: getFormSyncErrors('updateProfile')(state),
    combos: {
      UOTreeCombo: state.combos.UOTreeCombo,
      SalesmanCombo: state.profile.salesmanCombo
    },
    ...state.deviceInstall
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      ...profileActions,
      fetchDeviceInstalls,
      fetchMaxDeviceInstalls,
      updateDeviceInstall,
      openErrorMessageModal,
      sendMailAppDownload
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(userOrganization))
