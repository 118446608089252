import React, { PureComponent } from 'react'
import { Row, Col, Button, Table, FormGroup, FormControl, Radio, Pagination } from 'react-bootstrap'
import Select from 'react-select'

class Approve extends PureComponent {
  constructor () {
    super()
    this.state = { showModal: false }
  }

  render () {
    return (
      <Col sm={12} className="approve-wrapper">
        <Row className="approve-header">
          <div className="title-user">
            <i className="ico-check-white" />
            <h2>Aprobaciones expedientes</h2>
          </div>
        </Row>
        <Row className="filters-search-wrapper">
          <Row className="filters-button-wrapper">
            <div>
              <Button className="btn-standard btn-filter-toggle"> Filtros</Button>
            </div>
            <div className="filter-on-wrapper">
              <div className="filter-on">
                <span>Nombre del filtro</span>
                <i className="ico-cancel"></i>
              </div>
            </div>
          </Row>
          <Col sm={12} className="filters">
            <form autoComplete="off">
              <FormGroup className="filters">
                <Row>
                  <Col sm={3} className="input-with-icon">
                    <FormControl
                      id='clientName'
                      name='clientName'
                      placeholder='Nombre del cliente'
                    />
                    <i className="ico-user"></i>
                  </Col>
                  <Col sm={3} className="input-with-icon">
                    <FormControl
                      id='idNumber'
                      name='idNumber'
                      placeholder='DNI/CIF'
                    />
                    <i className="ico-document"></i>
                  </Col>
                  <Col sm={3} className="calendar-wrapper">
                    <FormControl
                      type="text"
                      placeholder="Fecha desde"
                    />
                    <i className="ico-calendar" />
                  </Col>
                  <Col sm={3} className="calendar-wrapper">
                    <FormControl
                      type="text"
                      placeholder="Fecha hasta"
                    />
                    <i className="ico-calendar" />
                  </Col>
                </Row>
                <Row>
                  <Col sm={3} className="input-with-icon">
                    <FormControl
                      id='idRegister'
                      name='idRegister'
                      placeholder='Nº Expediente'
                    />
                    <i className="ico-folder"></i>
                  </Col>
                  <Col sm={3} className="input-with-icon">
                    <FormControl
                      type="text"
                      placeholder="Nº  V.S.B"
                    />
                    <i className="ico-document-lines"></i>
                  </Col>
                  <Col sm={3} className="input-with-icon">
                    <FormControl
                      type="text"
                      placeholder="VIN Vehículo"
                    />
                    <i className="ico-document-lines"></i>
                  </Col>
                  <Col sm={3}>
                    <Select
                      id='family'
                      name='family'
                      placeholder='Familia'
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}>
                    <Select
                      id='stage'
                      name='stage'
                      placeholder='Etapa'
                    />
                  </Col>
                  <Col sm={3}>
                    <Select
                      id='place'
                      name='place'
                      placeholder='Concesionario'
                    />
                  </Col>
                  <Col sm={3}>
                    <Select
                      id='location'
                      name='location'
                      placeholder='Ubicación'
                    />
                  </Col>
                  <Col sm={3} className="input-with-icon">
                    <FormControl
                      type="text"
                      placeholder="Matrícula"
                    />
                    <i className="ico-binary"></i>
                  </Col>
                </Row>
                <Row>
                  <Col sm={3}>
                    <Select
                      id='manager'
                      name='manager'
                      placeholder='Gestor'
                    />
                  </Col>
                  <Col sm={3}>
                    <Select
                      id='workGroup'
                      name='workGroup'
                      placeholder='Grupo trabajo'
                    />
                  </Col>
                  <Col sm={3}>
                    <Select
                      id='typeAprobation'
                      name='typeAprobation'
                      placeholder='Tipo aprobación'
                    />
                  </Col>
                  <Col sm={3} className="radio-button-wrapper">
                    <FormGroup>
                      <Radio inline>
                        Borrador
                      </Radio>
                      <Radio inline>
                        Definitivo
                      </Radio>
                    </FormGroup>

                  </Col>
                </Row>
                <Row>
                  <Col sm={4} smPush={5}>
                    <Button className="btn-standard disabled btn-filters">Buscar</Button>
                    <Button className="btn-standard-disabled btn-filters">Cerrar</Button>
                  </Col>
                </Row>
              </FormGroup>
            </form>
          </Col>
        </Row>
        <Row className="table-wrapper">
          <Table responsive>
            <thead>
              <tr>
                <th>Expediente</th>
                <th>Cliente</th>
                <th>Vehículo</th>
                <th>Grupo</th>
                <th>Fecha exp.</th>
                <th>Tipo de expediente</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <a href="">EXP17010900n</a>
                </td>
                <td>Nombre Apellido</td>
                <td>KIA XY</td>
                <td>Grupo 2</td>
                <td>25/08/2016</td>
                <td>Propuestas venta</td>
              </tr>
              <tr>
                <td>
                  <a href="">EXP17010900n</a>
                </td>
                <td>Nombre Apellido</td>
                <td>KIA XY</td>
                <td>Grupo 2</td>
                <td>25/08/2016</td>
                <td>Propuestas venta</td>
              </tr>
              <tr>
                <td>
                  <a href="">EXP17010900n</a>
                </td>
                <td>Nombre Apellido</td>
                <td>KIA XY</td>
                <td>Grupo 2</td>
                <td>25/08/2016</td>
                <td>Propuestas venta</td>
              </tr>
              <tr>
                <td>
                  <a href="">EXP17010900n</a>
                </td>
                <td>Nombre Apellido</td>
                <td>KIA XY</td>
                <td>Grupo 2</td>
                <td>25/08/2016</td>
                <td>Propuestas venta</td>
              </tr>
              <tr>
                <td>
                  <a href="">EXP17010900n</a>
                </td>
                <td>Nombre Apellido</td>
                <td>KIA XY</td>
                <td>Grupo 2</td>
                <td>25/08/2016</td>
                <td>Propuestas venta</td>
              </tr>
              <tr>
                <td>
                  <a href="">EXP17010900n</a>
                </td>
                <td>Nombre Apellido</td>
                <td>KIA XY</td>
                <td>Grupo 2</td>
                <td>25/08/2016</td>
                <td>Propuestas venta</td>
              </tr>
              <tr>
                <td>
                  <a href="">EXP17010900n</a>
                </td>
                <td>Nombre Apellido</td>
                <td>KIA XY</td>
                <td>Grupo 2</td>
                <td>25/08/2016</td>
                <td>Propuestas venta</td>
              </tr>
              <tr>
                <td>
                  <a href="">EXP17010900n</a>
                </td>
                <td>Nombre Apellido</td>
                <td>KIA XY</td>
                <td>Grupo 2</td>
                <td>25/08/2016</td>
                <td>Propuestas venta</td>
              </tr>
              <tr>
                <td>
                  <a href="">EXP17010900n</a>
                </td>
                <td>Nombre Apellido</td>
                <td>KIA XY</td>
                <td>Grupo 2</td>
                <td>25/08/2016</td>
                <td>Propuestas venta</td>
              </tr>

            </tbody>
          </Table>
        </Row>
        <div className="approve-footer">
          <nav className="text-center">
            <Pagination
              id="btn_pag_sales"
              prev
              next
            />
          </nav>
        </div>
      </Col>
    )
  }
}

export default Approve
