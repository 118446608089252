import React, { PureComponent } from 'react'
import { Field } from 'redux-form'
import { Row, Clearfix, Button, OverlayTrigger, Tooltip } from 'react-bootstrap' // OverlayTrigger, Popover
import InputText from '../commons/form/InputText'
import { multipleNormalize, toUpperCase, trimAll } from '../../util/normalizeFunctions'
import InputSelect from '../commons/form/InputSelect'

import SimpleTablePage from '../../containers/commons/general/SimpleTablePage'
import IDocCarPagination from '../commons/iDocCarPagination'
import VehiclePopover from './VehiclePopover'
import DossierPopover from './DossierPopover'
import InputDatePicker from '../commons/form/InputDatePicker'

class SearchStockManagement extends PureComponent {
  constructor () {
    super()
    this.state = {
      selectedDateType: null
    }
  }

  exportData () {
    const tKey = 'SEARCH.CATEGORIES.STOCKMANAGEMENT.'
    let columnsExcel = []
    columnsExcel.push(this.props.t(`${tKey}REPAIR_WORKSHOPS`) + '#RepairWorkshop')
    columnsExcel.push(this.props.t(`${tKey}STAGES`) + '#DossierStage')
    columnsExcel.push(this.props.t(`${tKey}VEHICLE`) + '#Vehicle')
    columnsExcel.push(this.props.t(`${tKey}DOSSIER`) + '#Dossier')
    columnsExcel.push(this.props.t(`${tKey}CREATION_DATE`) + '#CreationDate')
    columnsExcel.push(this.props.t(`${tKey}RECEPTION_DATE`) + '#ReceptionDate')
    columnsExcel.push(this.props.t(`${tKey}RATING_DATE`) + '#RatingDate')
    columnsExcel.push(this.props.t(`${tKey}BUDGET_SENT_DATE`) + '#BudgetSentDate')
    columnsExcel.push(this.props.t(`${tKey}BUDGET_ACCEPTED_DATE`) + '#BudgetAcceptedDate')
    columnsExcel.push(this.props.t(`${tKey}RECONDITIONING_CONFIRMATION_DATE`) + '#ReconditioningConfirmationDate')
    this.props.exportToExcel(columnsExcel)
  }

  componentDidMount () {
    this.props.actions.fetchUserRepairWorkshopsCombo()
  }

  componentDidUpdate (prevProps, prevState) {
    const { repairWorkshopsCombo } = this.props.combos
    const isWorkshopUser = repairWorkshopsCombo?.isWorkshopUser ?? false
    if (isWorkshopUser && !prevProps.formValues?.repairWorkshops && !this.props.formValues?.repairWorkshops) {
      const repairWorkshops = repairWorkshopsCombo.repairShopsList?.map((item) => { return item.id })
      this.props.initialize({ stockManagement: { repairWorkshops: repairWorkshops } })
    }
  }

  getDateLabel (text) {
    const dateTypeSelected = this.props.formValues?.dateType
    const dateTypeValue = this.props.combos.dateTypeOptions.find(dateType => dateType.id === dateTypeSelected)?.value
    return text.replace('#DATETYPE#', dateTypeValue ?? '')
  }

  dateTypeOnInputChange (val) {
    const text = this.props.combos.dateTypeOptions.find(dateType => dateType.id === val)?.value
    this.props.fieldFill({ dateType: val })
    this.setState({ selectedDateType: text })
    if (val === null) {
      this.dateFromOnInputChange(null)
      this.dateToOnInputChange(null)
    }
  }

  dateFromOnInputChange (val) {
    this.props.change('stockManagement.dateFrom', val)
    this.props.fieldFill({ dateFrom: val })
  }

  dateToOnInputChange (val) {
    this.props.change('stockManagement.dateTo', val)
    this.props.fieldFill({ dateTo: val })
  }

  getRepairWorkshopsCustomClass (isWorkshopUser) {
    return isWorkshopUser
      ? 'hiddenSelect'
      : this.props.fields.repairWorkshops ? this.props.classNameSelect : ''
  }

  getTooltip (idx, text) {
    return (
      <Tooltip id={`repairWorkshop${idx}`}>{text}</Tooltip>
    )
  }

  render () {
    const {
      t, fields, fieldFill, className, classNameSelect,
      hasSearch, dossierData, selectedFilters, onClickResetSearch, page, pages, count, onPageChange, onOrderChange, getIconClass,
      combos: { repairWorkshopsCombo, stagesCombo, dateTypeOptions }
    } = this.props
    const isWorkshopUser = repairWorkshopsCombo?.isWorkshopUser ?? false
    const repairWorkshops = repairWorkshopsCombo?.repairShopsList ?? []
    const tKey = 'SEARCH.CATEGORIES.STOCKMANAGEMENT.'
    return (
      <div>
        {!hasSearch ? (
          <Row className='flexRow'>
            <Field
              colSm={3}
              id="stockManagement.repairWorkshops"
              name='stockManagement.repairWorkshops'
              placeholder={t(`${tKey}REPAIR_WORKSHOPS`)}
              controlLabel={t(`${tKey}REPAIR_WORKSHOPS`)}
              component={InputSelect}
              options={repairWorkshops}
              valueKey="id"
              labelKey="name"
              onInputChange={(val) => fieldFill({ repairWorkshops: val })}
              customClass={this.getRepairWorkshopsCustomClass(isWorkshopUser)}
              multi = {true}
            />
            <Field
              colSm={3}
              name='stockManagement.stages'
              id ='stockManagement.stages'
              placeholder={t(`${tKey}STAGES`)}
              controlLabel={t(`${tKey}STAGES`)}
              component={InputSelect}
              options={stagesCombo}
              valueKey="id"
              labelKey="value"
              onInputChange={(val) => fieldFill({ stages: val })}
              customClass={fields.stages ? classNameSelect : ''}
              multi = {true}
              menuContainerStyle={{ zIndex: 999 }}
            />
            <Field
              colSm={3}
              id="stockManagement.dossierNumber"
              name='stockManagement.dossierNumber'
              placeholder={t(`${tKey}DOSSIER_NUMBER`)}
              controlLabel={t(`${tKey}DOSSIER_NUMBER`)}
              component={InputText}
              type="text"
              minLength = {3}
              onInputChange={(val) => fieldFill({ dossierNumber: val })}
              customClass={fields.dossierNumber ? className : ''}
            />
            <Field
              colSm={3}
              id="stockManagement.licensePlate"
              name='stockManagement.licensePlate'
              placeholder={t(`${tKey}LICENSE_PLATE`)}
              controlLabel={t(`${tKey}LICENSE_PLATE`)}
              component={InputText}
              type="text"
              normalize={value => multipleNormalize(value, [toUpperCase, trimAll])}
              onInputChange={(val) => fieldFill({ licensePlate: val })}
              customClass={fields.licensePlate ? className : ''}
            />
            <Field
              colSm={3}
              id="stockManagement.vinchassis"
              name='stockManagement.vinchassis'
              placeholder={t(`${tKey}VINCHASSIS`)}
              controlLabel={t(`${tKey}VINCHASSIS`)}
              component={InputText}
              type="text"
              maxLength={17}
              normalize={value => multipleNormalize(value, [toUpperCase, trimAll])}
              onInputChange={(val) => fieldFill({ vinChassis: val })}
              customClass={fields.vinChassis ? className : ''}
            />
            <Field
              colSm={3}
              id="stockManagement.dateType"
              name='stockManagement.dateType'
              controlLabel={t(`${tKey}DATE_TYPE`)}
              placeholder={t(`${tKey}DATE_TYPE`)}
              component={InputSelect}
              options={dateTypeOptions}
              valueKey="id"
              labelKey="value"
              onInputChange={(value) => this.dateTypeOnInputChange(value)}
              customClass={fields.dateType ? classNameSelect : ''}
            />
            { this.props.formValues?.dateType && (
              <><Field
                colSm={3}
                id="stockManagement.dateFrom"
                name='stockManagement.dateFrom'
                controlLabel={this.getDateLabel(t(`${tKey}DATE_FROM`))}
                component={InputDatePicker}
                onInputChange={(value) => this.dateFromOnInputChange(value)}
                customClass={fields.dateFrom ? className : ''} />
              <Field
                colSm={3}
                id="stockManagement.dateTo"
                name='stockManagement.dateTo'
                controlLabel={this.getDateLabel(t(`${tKey}DATE_TO`))}
                component={InputDatePicker}
                value={this.state.dateTo}
                onInputChange={(value) => this.dateToOnInputChange(value)}
                customClass={fields.dateTo ? className : ''} />
              </>
            )}
          </Row>
        ) : ([
          <Row className="filters-selected" key="filters-selected">
            <ul className="list-inline pull-left">
              {selectedFilters().length === 0 ? <li>{t(`${tKey}NO_SELECTED_FILTERS`)}</li> : (selectedFilters())}
            </ul>
            <Clearfix/>
            <Button
              onClick={(event) => onClickResetSearch(event)}
              bsStyle="default"
              bsSize="small"
              className="pull-left"
              id='modSearchButtonIdEntity'
            >
              {t('SEARCH.ACTIONS.MODIFY_SEARCH')}
              <i className="ico-edit-turquoise" />
            </Button>
            {count > 0 &&
            <Button
              onClick={(event) => this.exportData(event)}
              bsStyle="default"
              bsSize="small"
              id='expSearchButtonIdEntity'>
              {t('SEARCH.ACTIONS.EXPORT')}
              <i className="ico-if_Download_1031520" />

            </Button>
            }
          </Row>,
          <div className="table-wrapper" key="table-wrapper">
            <SimpleTablePage
              columns={[
                <th key={1} data-field="repairWorkshopOrganizedUnit" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}REPAIR_WORKSHOPS`)}&nbsp;<i className={getIconClass('repairWorkshopOrganizedUnit')}/>
                </th>,
                <th key={2} data-field="dossierStage" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}STAGES`)}&nbsp;<i className={getIconClass('dossierStage')}/>
                </th>,
                <th key={3} data-field="vehicle" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}VEHICLE`)}&nbsp;<i className={getIconClass('vehicle')}/>
                </th>,
                <th key={4} data-field="dossier" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}DOSSIER`)}&nbsp;<i className={getIconClass('dossier')} />
                </th>,
                <th key={5} data-field="creationDate" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}CREATION_DATE`)}&nbsp;<i className={getIconClass('creationDate')}/>
                </th>,
                <th key={6} data-field="receptionDate" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}RECEPTION_DATE`)}&nbsp;<i className={getIconClass('receptionDate')}/>
                </th>,
                <th key={7} data-field="ratingDate" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}RATING_DATE`)}&nbsp;<i className={getIconClass('ratingDate')}/>
                </th>,
                <th key={8} data-field="budgetSentDate" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}BUDGET_SENT_DATE`)}&nbsp;<i className={getIconClass('budgetSentDate')} />
                </th>,
                <th key={9} data-field="budgetAcceptedDate" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}BUDGET_ACCEPTED_DATE`)}&nbsp;<i className={getIconClass('budgetAcceptedDate')} />
                </th>,
                <th key={10} data-field="reconditioningConfirmationDate" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}RECONDITIONING_CONFIRMATION_DATE`)}&nbsp;<i className={getIconClass('reconditioningConfirmationDate')} />
                </th>
              ]}
              rows={dossierData && dossierData.map((row, idx) => (
                <tr key={idx}>
                  { row.repairWorkshopOrganizedUnit?.length > 20
                    ? <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={this.getTooltip(idx, row.repairWorkshopOrganizedUnit)}>
                      <td className="column-ellipsis">
                        {row.repairWorkshopOrganizedUnit.substring(0, 20) + '(...)'}
                      </td>
                    </OverlayTrigger>
                    : <td className="column-ellipsis">
                      {row.repairWorkshopOrganizedUnit}
                    </td> }
                  <td>{row.dossierStage}</td>
                  <td className="see-more">
                    <VehiclePopover
                      t={t}
                      vehicle={row.vehicle}
                      half={(dossierData.length || 1) / (idx + 1)}
                      idx={idx}
                      id={'popover-vehicle' + idx}
                      isHeader = {true}
                      dossierSubTypeId={row.dossierSubTypeId}
                    />
                  </td>
                  <td className="see-more">
                    <DossierPopover
                      idx={idx}
                      t={t}
                      id={'popover-dossier' + idx}
                      dossier={row.dossier}
                      half={(dossierData.length || 1) / (idx + 1)}
                      urgencyType={row.urgencyType}
                    />
                  </td>
                  <td>{row.creationDate}</td>
                  <td>{row.receptionDate}</td>
                  <td>{row.ratingDate}</td>
                  <td>{row.budgetSentDate}</td>
                  <td>{row.budgetAcceptedDate}</td>
                  <td>{row.reconditioningConfirmationDate}</td>
                </tr>
              ))}
            />
          </div>,
          <div className="search-footer" key="search-footer">
            <IDocCarPagination
              id="btn_pag_sales"
              page={page}
              pagesCount={pages}
              totalRows= {count}
              onSelectAction={(page) => onPageChange(page)}
            />
          </div>
        ])}
      </div>
    )
  }
}
export default SearchStockManagement
