import React, { PureComponent } from 'react'
import { Row, Tabs, Tab } from 'react-bootstrap'
import Tab1 from './Tabs/Tab1'
import Tab2 from './Tabs/Tab2'
import Tab3 from './Tabs/Tab3'

class upperTrafficSummary extends PureComponent {
  render () {
    return (
      <div className="upper-process-summary">
        <Row className="tabs-wrapper">
          <Tabs id="traffic-process-tabs">
            <Tab eventKey={1} title="Expediente">
              <Tab1/>
            </Tab>
            <Tab eventKey={2} title="Cliente">
              <Tab2/>
            </Tab>
            <Tab eventKey={3} title="Vehículo">
              <Tab3/>
            </Tab>
          </Tabs>
        </Row>
      </div>
    )
  }
}

export default upperTrafficSummary
