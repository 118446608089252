import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import SearchCommercialReport from '../../../components/search/SearchCommercialReport'
import { translate } from 'react-polyglot'
import { fetchBrands } from '../../../actions/combos/combos'
import { fetchReportCommercialTable, fetchReportCommercialDownload } from '../../../actions/search/search'
export function mapStateToProps (state) {
  return {
    combos: {
      UOTreeCombo: state.combos.UOTreeCombo,
      brandCombo: state.combos.brandCombo
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchBrands,
      fetchReportCommercialTable,
      fetchReportCommercialDownload
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(SearchCommercialReport))
