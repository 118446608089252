import React, { PureComponent } from 'react'
import { Panel, Col, Table, OverlayTrigger, Glyphicon, Tooltip } from 'react-bootstrap'
import CampaignsRow from './campaings/CampaignsRow'
import SmallLoaderPage from '../../../containers/commons/SmallLoaderPage'
import CustomFields from '../../commons/customFields/CustomFields'
import InputCheckBox from '../../commons/form/InputCheckBox'
import { Field } from 'redux-form'
import { permissions } from '../../../constants/backendIds'
import { getFieldsPermisions } from '../../../util/permisionFunctions'
class Campaigns extends PureComponent {
  constructor () {
    super()
    this.state = {
      open: false,
      fieldsConfiguration: {
        downloadButton: null,
        addCampaignButton: null,
        campaignsBlocked: null
      }
    }
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (prevProps.open !== this.props.open && this.props.open) {
      return {
        open: this.props.open
      }
    }
    return null
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (snapshot) {
      this.setState({ open: snapshot.open })
    }
  }

  componentDidMount () {
    this.initializePermisions(this.props)
  }

  toggleComponent () {
    if (this.state.open) {
      this.props.actions.setActiveSection(false)
    }
    this.setState({
      open: !this.state.open
    })
  }

  initializePermisions (props) {
    const fieldKeys = Object.keys(this.state.fieldsConfiguration)
    const newState = getFieldsPermisions(props.componentConfiguration.fieldsConfiguration, fieldKeys, props.componentConfiguration.permission)
    this.setState({ ...this.state, ...newState })
  }

  render () {
    const { t, readOnlyFields, campaigns, customFields, actions: { openCampaignEdition, deleteCampaign, fetchCampaignEdition } } = this.props
    const tKey = 'DOSSIER_COMPONENTS.CAMPAIGNS.'
    const blockTooltip = (<Tooltip id="blockTooltip">{t(`${tKey}CAMPAIGNS_BLOCKED_INFO`)}</Tooltip>)
    return (
      <div className="campaigns-panel dossier-panel">
        <div className="header-panel" onClick={() => this.toggleComponent()}>
          <div className="name-wrapper">
            <i className="ico-campaing" />
            <h4>{t(`${tKey}TITLE_PANEL`)}</h4>
          </div>
          <i className={this.state.open ? 'ico-arrow-black-up' : 'ico-arrow-black-down'} />
        </div>
        <Panel expanded={this.state.open} onToggle={() => {}}>
          <Panel.Collapse>
            <Col sm={6}>
              {this.state.fieldsConfiguration.campaignsBlocked && this.state.fieldsConfiguration.campaignsBlocked.permission !== permissions.hidden && (
                <>
                  <Field
                    colSm={4}
                    id="campaignsBlocked"
                    name="salesComponent.campaignsBlocked"
                    controlLabel={t(`${tKey}CAMPAIGNS_BLOCKED`)}
                    placeholder={t(`${tKey}CAMPAIGNS_BLOCKED`)}
                    inline
                    component={InputCheckBox}
                    disabled={true}
                  />
                  <OverlayTrigger placement="right" overlay={blockTooltip}
                    colSm={1}>
                    <Glyphicon glyph="info-sign"/>
                  </OverlayTrigger>
                </>
              )}
            </Col>
            <Col sm={12} className="col-row">
              <Table className="editable-table" hover responsive >
                <thead>
                  <tr>
                    <th>{t(`${tKey}EXP_NUMBER`)}</th>
                    <th>{t(`${tKey}NAME`)}</th>
                    <th>{t(`${tKey}TYPE`)}</th>
                    <th>{t(`${tKey}AMOUNT`)}</th>
                    <th>{t(`${tKey}COST`)}</th>
                    <th>{t(`${tKey}COST_CONCESSIONAIRE`)}</th>
                    <th>{t(`${tKey}STATUS`)}</th>
                    <th>{t(`${tKey}DOCUMENTS`)}</th>
                    <th style={{ width: '77px' }}>{t('DOSSIER_COMPONENTS.ACCESSORIES.ACTIONS')}</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    campaigns.map((campaign, idx) => {
                      return (
                        <CampaignsRow
                          t={t}
                          tKey={tKey}
                          campaign={campaign}
                          key={idx}
                          openCampaignEdition={openCampaignEdition}
                          fetchCampaignEdition={fetchCampaignEdition}
                          deleteCampaign={deleteCampaign}
                          readOnlyFields={readOnlyFields}
                        />
                      )
                    })
                  }
                  <tr className="button-table-wrapper">
                    <td>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <CustomFields customFields={customFields} readOnlyFields={true} />
          </Panel.Collapse>
        </Panel>
        <SmallLoaderPage />
      </div>
    )
  }
}
export default Campaigns
