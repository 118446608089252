import React, { Component } from 'react'
import { Table, Row, Col, OverlayTrigger, Tooltip, Button } from 'react-bootstrap'
import SimpleTreeSelect from '../../commons/simpleForm/SimpleTreeSelect'
import IDocCarPagination from '../../commons/iDocCarPagination'
import { formatPercent } from '../../../util/utils'
import CodeCommissionMastersModalPage from '../../../containers/masters/CodeCommission/CodeCommissionMastersModalPage'
class CodeCommissionMasters extends Component {
  constructor () {
    super()
    this.state = {
      uoId: null,
      filters: {
        page: 1,
        pageSize: 10,
        orderBy: 'organizedUnitName',
        orderDirection: 'ASC'
      },
      dataList: {
        items: [],
        pages: 0,
        count: 0
      }
    }
  }

  handleOrganizedUnitChange (uoId) {
    this.setState({ uoId })
    const { pageSize, orderBy, orderDirection } = this.state.filters
    this.props.actions.fetchcodeCommissionMasters(uoId, 1, pageSize, orderBy, orderDirection)
  }

  fetchCodeCommissionList (value) {
    this.setState({ filters: { ...this.state.filters, page: value } })
    const { pageSize, orderBy, orderDirection } = this.state.filters
    this.props.actions.fetchcodeCommissionMasters(this.state.uoId, value, pageSize, orderBy, orderDirection)
  }

  changeOrder (orderBy) {
    var changeDirection = this.state.filters.orderBy === orderBy
    var orderDirection = 'ASC'

    if (changeDirection) {
      orderDirection = this.state.filters.orderDirection === 'ASC' ? 'DESC' : 'ASC'
    }

    this.setState({ filters: { ...this.state.filters, orderBy, orderDirection } })
    const { page, pageSize } = this.state.filters

    this.props.actions.fetchcodeCommissionMasters(this.state.uoId, page, pageSize, orderBy, orderDirection)
  }

  getSortIcon (column = '') {
    if (this.state.filters.orderBy === column) {
      return this.state.filters.orderDirection === 'ASC' ? 'ico-sort-selected-asc' : 'ico-sort-selected-desc'
    }

    return 'ico-sort'
  }

  render () {
    const {
      t, codeCommissionMastersList,
      actions: { deleteCodeComission, openCodeCommissionModal },
      combos: { UOTreeCombo },
      codeCommissionFields: { page }
    } = this.props
    const tKey = 'MASTERS.CODE_COMMISSION.'
    const editTooltip = (<Tooltip id="editTooltip">{t('MASTERS.EDIT')}</Tooltip>)
    const deleteTooltip = (<Tooltip id="deleteTooltip">{t('MASTERS.DELETE')}</Tooltip>)

    return (
      <Row>
        <Col sm={12}>
          <div className="header-panel">
            <h4>{t(`${tKey}TITLE`)}</h4>
          </div>
        </Col>
        <SimpleTreeSelect
          colSm={12}
          controlLabel={t('DOSSIER_COMPONENTS.HEADER.UO')}
          placeholder={t('DOSSIER_COMPONENTS.HEADER.UO')}
          options={UOTreeCombo}
          searchPlaceholder={t('DOSSIER_COMPONENTS.HEADER.UO')}
          value={this.state.uoId ? this.state.uoId.toString() : null}
          onInputChange={(value) => this.handleOrganizedUnitChange(value)}
          className='dashboard-select'
        />
        <CodeCommissionMastersModalPage
          uoId={this.state.uoId}
        />
        <Col sm={12} className="col-row  section-users-body">
          <Table sm={12} className="editable-table" hover responsive>
            <thead>
              <tr>
                <th className='td20per' onClick={() => { this.changeOrder('organizedUnitName') }}>{t('DOSSIER_COMPONENTS.HEADER.UO')}
                  <i className={this.getSortIcon('organizedUnitName')} /></th>
                <th className='td15per' onClick={() => { this.changeOrder('code') }}>{t(`${tKey}CODE`)}
                  <i className={this.getSortIcon('code')} /></th>
                <th className='td25per' onClick={() => { this.changeOrder('description') }}>{t('MASTERS.DESCRIPTION')}
                  <i className={this.getSortIcon('description')} /></th>
                <th className='td10per' onClick={() => { this.changeOrder('fixedPercentage') }}>{t(`${tKey}FIXED_PERCENTAGE`)}
                  <i className={this.getSortIcon('fixedPercentage')} /></th>
                <th className='td10per' onClick={() => { this.changeOrder('quantitativePercentage') }}>{t(`${tKey}QUANTITATIVE_PERCENTAGE`)}
                  <i className={this.getSortIcon('quantitativePercentage')} /></th>
                <th className='td10per' onClick={() => { this.changeOrder('qualitativePercentage') }}>{t(`${tKey}QUALITATIVE_PERCENTAGE`)}
                  <i className={this.getSortIcon('qualitativePercentage')} /></th>
                <th className='td10per' >{t('DOSSIER_COMPONENTS.ACCESSORIES.ACTIONS')}</th>
              </tr>
            </thead>
            <tbody>
              {codeCommissionMastersList?.items && codeCommissionMastersList?.items.map((codeCommissionMaster, idx) => (
                <tr key={idx} >
                  <td className='td20per'>{codeCommissionMaster.organizedUnitName}</td>
                  <td className='td15per'>{codeCommissionMaster.code}</td>
                  <td className='td25per'>{codeCommissionMaster.description}</td>
                  <td className='td10per'>{formatPercent(codeCommissionMaster.fixedPercentage)}</td>
                  <td className='td10per'>{formatPercent(codeCommissionMaster.quantitativePercentage)}</td>
                  <td className='td10per'>{formatPercent(codeCommissionMaster.qualitativePercentage)}</td>
                  <td className='td10per'>
                    <div>
                      <a onClick={() => openCodeCommissionModal(codeCommissionMaster)}>
                        <OverlayTrigger placement="left" overlay={editTooltip}>
                          <i className="ico-edit-white"/>
                        </OverlayTrigger>
                      </a>
                      <a onClick={() => deleteCodeComission(codeCommissionMaster.commissionCancellationCodeId, this.state.uoId)}>
                        <OverlayTrigger placement="left" overlay={deleteTooltip}>
                          <i className="ico-trash"/>
                        </OverlayTrigger>
                      </a>
                    </div>
                  </td>
                </tr>
              ))}
              {(codeCommissionMastersList?.items === null || codeCommissionMastersList?.items === undefined || codeCommissionMastersList?.items?.length === 0) &&
              <tr>
                <td colSpan={10}><h2>{t('MASTERS.RESULTS_NOT_FOUND')}</h2></td>
              </tr>}
              <tr className="button-table-wrapper">
                <td>
                  <Button
                    className="btn-editable-table btn-standard"
                    onClick={() => openCodeCommissionModal(null)}>
                    + {t('MASTERS.ADD')}
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>
          <div className="section-users-footer">
            <nav key={1} className="text-center">
              <IDocCarPagination
                id="btn_pag_sales"
                page={page}
                pagesCount={codeCommissionMastersList?.pages ?? 0}
                totalRows={codeCommissionMastersList?.count ?? 0}
                onSelectAction={(value) => this.fetchCodeCommissionList(value)}
              />
            </nav>
          </div>
        </Col>
      </Row>
    )
  }
}

export default CodeCommissionMasters
