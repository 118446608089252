import nonUpdatedDossiersActions from '../../../constants/actions/dossier/common/nonUpdatedDossiers'

export function initialState () {
  return {
    nonUpdatedDossiersList: null,
    showModal: false,
    dossiersList: [],
    pages: 0,
    count: 0,
    filters: {
      pageSize: 7,
      page: 1,
      orderBy: '+dossier'
    }
  }
}

function openNonUpdatedDossiers (state, { dossiersList }) {
  return {
    ...state,
    nonUpdatedDossiersList: dossiersList,
    showModal: true
  }
}

function closeNonUpdatedDossiers (state) {
  return {
    ...state,
    showModal: false
  }
}

function fetchNonUpdatedDossiersSuccess (state, { data, filters }) {
  return {
    ...state,
    dossiersList: data.items,
    pages: data.pages,
    count: data.count,
    showModal: true,
    filters: {
      pageSize: filters.pageSize,
      page: filters.page,
      orderBy: filters.orderBy
    }
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case nonUpdatedDossiersActions.OPEN_NON_UPDATED_DOSSIERS_MODAL:
      return openNonUpdatedDossiers(state, action)
    case nonUpdatedDossiersActions.CLOSE_NON_UPDATED_DOSSIERS_MODAL:
      return closeNonUpdatedDossiers(state, action)
    case nonUpdatedDossiersActions.FETCH_NON_UPDATED_DOSSIERS_SUCCESS:
      return fetchNonUpdatedDossiersSuccess(state, action)
    default:
      return state
  }
}
