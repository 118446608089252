import Settings from './settings'

export default {
  ...Settings,
  urlHotFolder: '',
  webSocketUrl: 'wss://qa-toyota.idoccar.com:8006',
  notificationRefresh: 60000,
  showSignDocumentButton: false,
  acceptFileFormats: 'application/pdf, .jpg, .png, .jpeg, .gif, .docx, .doc, .xls, .xlsx, .ppt, .pptx, .msg, .tiff',
  qaSite: true,
  dealerMaxLength: 6,
  dealerNumberRegex: null,
  autoLoadDashboard: true,
  clientFieldValidation: 'toyo',
  validateLicensePlate: true,
  validateSalesManager: false,
  matomoSiteId: '21',
  enableFavoritesAndRecents: true,
  enableTaskFollowUp: true,
  dashboardVersion: 2,
  reloadTaskDashboardOnReturn: true,
  usersManualUrl: 'https://toyota-formacion.idoccar.com/',
  usersManualEnvironment: 'qa-toyota',
  environmentName: 'qa-toyota'
}
