import React, { PureComponent } from 'react'
import { Panel, Row, Col, Table, Button } from 'react-bootstrap'
import { Field } from 'redux-form'
import ChargeRow from '../sales/charges/ChargeRow'
import SmallLoaderPage from '../../../containers/commons/SmallLoaderPage'
import { documentTypeUse as documentTypeUseConstants, permissions } from '../../../constants/backendIds'
import { documentEntityType as documentEntityTypeConstants } from '../../../constants/dossier/common/documentEntityType'
import { formatMoney, compareObjects } from '../../../util/utils'
import InputMoney from '../../commons/form/InputMoney'
import CustomFields from '../../commons/customFields/CustomFields'
import { getFieldsPermisions } from '../../../util/permisionFunctions'
import setting from '../../../setting'

class Charges extends PureComponent {
  constructor () {
    super()
    this.state = {
      open: false,
      fieldsConfiguration: {
        chargebackAmount: null,
        chargeFinancingAmount: null,
        saleAddCharge: null,
        showRefundButton: null,
        uacTotalAmount: null,
        saleStartGateway: null
      },
      showRefundColumn: false,
      loaded: false
    }
  }

  UNSAFE_componentWillMount () {
    this.initializePermisions(this.props)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.open) {
      this.setState({ open: true })
    }

    if ((nextProps.open && this.props.open !== nextProps.open) || (nextProps.open === true && this.props.dossier.dossierEconomicComponent.operationTotalAmount === undefined && !this.state.loaded)) {
      this.getEconomicInfo()
      this.setState({ loaded: true })
    }

    if (nextProps.dossier.dossierId && !compareObjects(this.props.componentConfiguration || {}, nextProps.componentConfiguration, true)) {
      this.initializePermisions(nextProps)
    }
    this.setRefund(nextProps.charges)
  }

  initializePermisions (props) {
    const fieldKeys = Object.keys(this.state.fieldsConfiguration)
    const newState = getFieldsPermisions(props.componentConfiguration.fieldsConfiguration, fieldKeys, props.componentConfiguration.permission)
    this.setState({ fieldsConfiguration: newState.fieldsConfiguration })
  }

  getEconomicInfo () {
    if (!this.props.isEconomicLoaded || this.props.dossier.dossierEconomicComponent.operationTotalAmount === undefined) {
      this.props.handleToUpdateComponent(this.props)
      this.props.actions.fetchSalesEconomic(this.props.dossier.dossierId, this.props.historical, this.props.pristine)
    }
  }

  toggleComponent () {
    if (this.state.open) {
      this.props.actions.setActiveSection(false)
    }
    this.setState({ open: !this.state.open })
    this.getEconomicInfo()
  }

  setRefund (charges) {
    let showColumn = false
    if (charges && charges.length > 0) {
      charges.map((charge, idx) => {
        if (charge.showRefundButton === true) {
          showColumn = true
        }
      })
    }
    if (this.state.showRefundColumn !== showColumn) {
      this.setState({ showRefundColumn: showColumn })
    }
  }

  render () {
    const {
      t, readOnlyFields, dossierType, charges = [], customFields, actions: { openDocumentEditionModal, printCheckList, postPaymentSignalStatusSuccess },
      dossier: { dossierId, organizedUnitId, dossierEconomicComponent }, isPreCancelled
    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.CHARGES.'
    const totalCompleted = charges.reduce((prevVal, newVal) => {
      return prevVal + (newVal.completed ? (newVal.amount || 0) : 0)
    }, 0)
    const isFleet = this.props.fleetId !== undefined && this.props.fleetId !== null
    return (
      <div className="charges-panel dossier-panel">
        <div className="header-panel" onClick={ () => this.toggleComponent()}>
          <div className="name-wrapper">
            <i className="ico-collect"/>
            <h4>{t(`${tKey}TITLE_PANEL`)}</h4>
          </div>
          <i className={this.state.open ? 'ico-arrow-black-up' : 'ico-arrow-black-down'}/>
        </div>
        <Panel expanded={this.state.open} onToggle={() => {}}>
          <Panel.Collapse>
            {!readOnlyFields && this.state.fieldsConfiguration.saleStartGateway.permission !== permissions.hidden && !isFleet &&
              <Row>
                <Col sm={12} style={{ textAlign: 'right' }}>
                  <Button
                    href={setting.paymentGatewayUrl}
                    target="_blank"
                    disabled={readOnlyFields || this.state.fieldsConfiguration.saleStartGateway.permission !== permissions.editable || isFleet}
                    className="btn-editable-table btn-standard"
                    id="start-gatteway-button"
                    style={{ marginBottom: '10px' }}
                  >{t(`${tKey}PAYMENT_GATEWAY`)}</Button>
                </Col>
              </Row>
            }
            <Row>
              <Col>
                {isPreCancelled && this.state.fieldsConfiguration.chargebackAmount.permission !== permissions.hidden && (
                  <Field
                    id="dossierEconomicComponent.chargebackAmount"
                    name="dossierEconomicComponent.chargebackAmount"
                    colSm={2}
                    controlLabel={t(`${tKey}CHARGEBACK_AMOUNT`)}
                    placeholder={t(`${tKey}CHARGEBACK_AMOUNT`)}
                    component={InputMoney}
                    disabled={readOnlyFields || this.state.fieldsConfiguration.chargebackAmount.permission !== permissions.editable || isFleet}
                  />
                )}
              </Col>
              <Col sm={12}>
                <Table className="editable-table" hover>
                  <thead>
                    <tr>
                      <th>{t(`${tKey}RECEIPT_NUMBER`)}</th>
                      <th>{t(`${tKey}DESCRIPTION`)}</th>
                      <th>{t(`${tKey}AMOUNT`)}</th>
                      <th>{t(`${tKey}VALIDATED`)}</th>
                      <th>{t(`${tKey}DOCUMENT`)}</th>
                      {this.state.showRefundColumn &&
                        <th>{t(`${tKey}REFUND`)}</th>
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {
                      charges.map((charge, idx) => {
                        return (
                          <ChargeRow
                            t={t}
                            tKey={tKey}
                            charge={charge}
                            key={idx}
                            dossierId={dossierId}
                            organizedUnitId={organizedUnitId}
                            actions={{
                              openDocumentEditionModal, // ,
                              // updateDependantsSaleDossierAndHeader
                              printCheckList,
                              postPaymentSignalStatusSuccess
                            }}
                            dossierType={dossierType}
                            readOnlyFields={readOnlyFields || (charge.fleetChargeId !== null && charge.fleetChargeId !== undefined)}
                          />
                        )
                      })
                    }
                  </tbody>
                </Table>
              </Col>
              <Col sm={4}>
                <div className="button-table-wrapper">
                  {!readOnlyFields && this.state.fieldsConfiguration.saleAddCharge.permission !== permissions.hidden && !isFleet &&
                  <Button
                    onClick={() => openDocumentEditionModal(
                      null,
                      documentTypeUseConstants.charge,
                      documentEntityTypeConstants.DOSSIER,
                      dossierId,
                      null,
                      null,
                      organizedUnitId,
                      null,
                      // () => updateDependantsSaleDossierAndHeader(dossierId)
                      null,
                      true,
                      dossierType
                    )}
                    disabled={readOnlyFields || this.state.fieldsConfiguration.saleAddCharge.permission !== permissions.editable || isFleet}
                    className="btn-editable-table btn-standard"
                    id="add-button"
                  >+ {t(`${tKey}ADD_BTN`)}</Button>
                  }
                </div>
              </Col>
              <Col sm={8}>
                <div className="table-totals-charges">
                  <Col sm={6} className="colums-total-charges">
                    <p style={{ borderRight: 'none' }}><b>{t(`${tKey}TOTALS.TOTAL_CHARGED`)}:</b> <span>{formatMoney(totalCompleted)}</span></p>
                    { this.state.fieldsConfiguration.uacTotalAmount.permission !== permissions.hidden && (
                      <p style={{ borderTop: 'none', borderRight: 'none' }}><b>{t(`${tKey}TOTALS.UAC_TOTAL`)}:</b> <span>{formatMoney(dossierEconomicComponent.uacTotalAmount)}</span></p>
                    )}
                  </Col>
                  <Col sm={6} className="colums-total-charges">
                    <p style={{ borderBottom: 'none' }}><b>{t(`${tKey}TOTALS.OPERATION_TOTAL`)}:</b> <span>{formatMoney(dossierEconomicComponent.operationTotalAmount !== 0 || !dossierEconomicComponent.dossierOperationTotalAmount ? dossierEconomicComponent.operationTotalAmount : dossierEconomicComponent.dossierOperationTotalAmount)}</span></p>
                    {this.state.fieldsConfiguration.chargeFinancingAmount.permission !== permissions.hidden && (
                      <p style={{ borderBottom: 'none' }}><b>{t(`${tKey}TOTALS.FINANCING_CHARGE_AMOUNT`)}:</b> <span>{formatMoney(dossierEconomicComponent.financingAmount)}</span></p>
                    )}
                    <p><b>{t(`${tKey}TOTALS.COLLECT_TOTAL`)}:</b> <span>{formatMoney(dossierEconomicComponent.paymentPending)}</span></p>
                  </Col>
                </div>
              </Col>
            </Row>
            <CustomFields customFields={customFields} readOnlyFields={readOnlyFields} />
          </Panel.Collapse>
        </Panel>
        <SmallLoaderPage/>
      </div>
    )
  }
}
export default Charges
