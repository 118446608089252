import React, { PureComponent } from 'react'
import { Field, reduxForm } from 'redux-form'
import { Form, Button, Row, Col, Clearfix } from 'react-bootstrap'
import InputSelect from '../commons/form/InputSelect'
import InputTreeSelect from '../commons/form/InputTreeSelect'
import TimeLine from './TimeLine'
import { dossierTypes, dossierStageStoredAndCancelled, dossierSubTypes } from '../../constants/backendIds'
import ConditionsMaintenanceModalPage from '../../containers/conditionsMaintenance/ConditionsMaintenanceModalPage'

class ConditionsMaintenance extends PureComponent {
  constructor (props) {
    super()
    this.state = {
      dossierTypeId: null,
      dossierSubTypeId: null,
      saleTypeId: null,
      purchaseTypeId: null,
      paperworkTypeId: null,
      campaignTypeId: null,
      operationTypeId: [],
      organizedUnitSearch: null,
      showRelatedDossierCombos: false,
      searched: false
    }
  }

  componentDidMount () {
    this.props.actions.fetchOrganizedUnitsTree()
    this.props.actions.fetchDossierTypes()
    this.props.actions.fetchDocumentsAllTypes()
    this.props.actions.fetchOperationType()
    this.props.actions.fetchPaperworkType(dossierSubTypes.paperwork)
  }

  componentWillUnmount () {
    this.props.initialize({})
    this.props.reset()
  }

  handleDossierTypeChange (dossierTypeId) {
    this.props.actions.fetchDossierSubTypes(null, dossierTypeId)
    this.props.initialize({ dossierTypeId, organizedUnitSearch: this.state.organizedUnitSearch })
    this.setState({
      dossierTypeId,
      dossierSubTypeId: null,
      saleTypeId: null,
      purchaseTypeId: null,
      paperworkTypeId: null,
      campaignTypeId: null,
      operationTypeId: [],
      showRelatedDossierCombos: false,
      searched: false
    })
  }

  handleDossierSubTypeChange (dossierSubTypeId) {
    if (this.state.dossierTypeId && dossierSubTypeId) {
      this.props.actions.fetchAdditionalInfoCombo(dossierSubTypeId)
      switch (this.state.dossierTypeId) {
        case dossierTypes.sales:
          this.props.actions.fetchSaleTypeBySubtype(dossierSubTypeId)
          this.props.actions.fetchMarginTypesCombo(dossierSubTypeId)
          break
        case dossierTypes.purchase:
          this.props.actions.fetchPurchaseType(dossierSubTypeId)
          this.props.actions.fetchSaleTypeBySubtype(null)
          break
        case dossierTypes.paperwork:
          this.props.actions.fetchPaperworkType(dossierSubTypeId)
          this.props.actions.fetchSaleTypeBySubtype(null)
          break
        case dossierTypes.campaign:
          this.props.actions.fetchCampaignType(dossierSubTypeId)
          this.props.actions.fetchSaleTypeBySubtype(null)
          break
      }
    }
    this.props.initialize({ dossierTypeId: this.state.dossierTypeId, dossierSubTypeId, organizedUnitSearch: this.state.organizedUnitSearch })
    this.setState({
      dossierSubTypeId,
      saleTypeId: null,
      purchaseTypeId: null,
      paperworkTypeId: null,
      campaignTypeId: null,
      operationTypeId: [],
      showRelatedDossierCombos: !!dossierSubTypeId,
      searched: false
    })
  }

  handleSaleTypeChange (saleTypeId) {
    this.props.initialize({ dossierTypeId: this.state.dossierTypeId, dossierSubTypeId: this.state.dossierSubTypeId, saleTypeId, organizedUnitSearch: this.state.organizedUnitSearch })
    this.setState({
      saleTypeId,
      purchaseTypeId: null,
      paperworkTypeId: null,
      campaignTypeId: null,
      operationTypeId: [],
      searched: false
    })
  }

  handlePurchaseTypeChange (purchaseTypeId) {
    this.props.initialize({ dossierTypeId: this.state.dossierTypeId, dossierSubTypeId: this.state.dossierSubTypeId, purchaseTypeId, organizedUnitSearch: this.state.organizedUnitSearch })
    this.setState({
      saleTypeId: null,
      purchaseTypeId,
      paperworkTypeId: null,
      campaignTypeId: null,
      operationTypeId: [],
      searched: false
    })
  }

  handlePaperworkTypeChange (paperworkTypeId) {
    this.props.initialize({ dossierTypeId: this.state.dossierTypeId, dossierSubTypeId: this.state.dossierSubTypeId, paperworkTypeId, organizedUnitSearch: this.state.organizedUnitSearch })
    this.setState({
      saleTypeId: null,
      purchaseTypeId: null,
      paperworkTypeId,
      campaignTypeId: null,
      operationTypeId: [],
      searched: false
    })
  }

  handleCampaignTypeChange (campaignTypeId) {
    this.props.initialize({ dossierTypeId: this.state.dossierTypeId, dossierSubTypeId: this.state.dossierSubTypeId, campaignTypeId, organizedUnitSearch: this.state.organizedUnitSearch })
    this.setState({
      saleTypeId: null,
      purchaseTypeId: null,
      paperworkTypeId: null,
      campaignTypeId,
      operationTypeId: [],
      searched: false
    })
  }

  handleOperationTypeBlur (e, operationTypeId) {
    this.props.initialize({ dossierTypeId: this.state.dossierTypeId, dossierSubTypeId: this.state.dossierSubTypeId, saleTypeId: this.state.saleTypeId, operationTypeId, organizedUnitSearch: this.state.organizedUnitSearch })
    this.setState({
      operationTypeId,
      searched: false
    })
  }

  handleUoChange (organizedUnitSearch) {
    this.props.initialize({
      dossierTypeId: this.state.dossierTypeId,
      dossierSubTypeId: this.state.dossierSubTypeId,
      saleTypeId: this.state.saleTypeId,
      purchaseTypeId: this.state.purchaseTypeId,
      paperworkTypeId: this.state.paperworkTypeId,
      campaignTypeId: this.state.campaignTypeId,
      operationTypeId: this.state.operationTypeId,
      organizedUnitSearch
    })
    this.setState({
      organizedUnitSearch,
      searched: false
    })
  }

  handleSearch () {
    this.setState({ searched: true })
    this.props.actions.fetchStagesCombo(this.state.dossierSubTypeId)
    this.props.actions.updateConditionsMaintenanceFilter({
      campaignTypeId: this.state.campaignTypeId,
      dossierSubTypeId: this.state.dossierSubTypeId,
      dossierTypeId: this.state.dossierTypeId,
      operationTypeId: this.state.operationTypeId,
      organizedUnitSearch: this.state.organizedUnitSearch,
      paperworkTypeId: this.state.paperworkTypeId,
      purchaseTypeId: this.state.purchaseTypeId,
      saleTypeId: this.state.saleTypeId
    })
  }

  render () {
    const {
      t, activeStage, conditionDocumental, conditionNoDocumental, conditionNoDocumentalOther,
      actions: {
        setActiveStage, openAddConditionsModal, openEditConditionsModal, deleteCondition
      },
      combos: {
        dossierTypesCombo, dossierSubTypesCombo, saleTypeCombo, purchaseTypeCombo, paperworkTypes, campaignsTypes,
        operationTypeCombo, UOTreeCombo, documentsTypes
      }
    } = this.props

    // No mostrar las etapas cancelado y archivado.
    let stagesCombo = []
    if (this.props.combos.stagesCombo) {
      stagesCombo = this.props.combos.stagesCombo.filter((val) => {
        return dossierStageStoredAndCancelled.indexOf(val.id) === -1
      })
    }

    const tKey = 'CONDITIONS_MAINTENANCE.'
    const hasFilters = this.state.dossierTypeId && this.state.dossierSubTypeId

    return (
      <div className="conditions-maintenance-container search-wrapper">
        <ConditionsMaintenanceModalPage />
        <div className="search-header">
          <div className="title-user">
            <i className="ico-mantenimiento-condiciones" />
            <h2>{t(`${tKey}TITLE`)}</h2>
          </div>
        </div>
        <Form autoComplete="off" className="form-conditions-maintenance filters-search-wrapper">
          <Row>
            <Field
              colSm={3}
              id="dossierTypeId"
              name="dossierTypeId"
              controlLabel={t(`${tKey}DOSSIER_TYPE`)}
              placeholder={t(`${tKey}DOSSIER_TYPE`)}
              component={InputSelect}
              options={dossierTypesCombo}
              valueKey="id"
              labelKey="value"
              onInputChange={(value) => this.handleDossierTypeChange(value)}
            />
            <Field
              colSm={3}
              id="dossierSubTypeId"
              name="dossierSubTypeId"
              controlLabel={t(`${tKey}DOSSIER_SUBTYPE`)}
              placeholder={t(`${tKey}DOSSIER_SUBTYPE`)}
              component={InputSelect}
              options={dossierSubTypesCombo}
              valueKey="id"
              labelKey="value"
              disabled={!dossierSubTypesCombo || dossierSubTypesCombo.length === 0}
              onInputChange={(value) => this.handleDossierSubTypeChange(value)}
            />
            <Field
              id="organizedUnitSearch"
              name="organizedUnitSearch"
              key="organizedUnitSearch"
              controlLabel={t(`${tKey}UO`)}
              placeholder={t(`${tKey}UO`)}
              component={InputTreeSelect}
              filterTreeNode={true}
              multi={false}
              valueKey="value"
              labelKey="label"
              childrenKey="children"
              options={UOTreeCombo}
              colSm={3}
              onInputChange={(value) => this.handleUoChange(value)}
            />
            <Col sm={3} className="btn-wrapper">
              <Button
                id="btn_search"
                className={'btn-white btn-icon btn-input' + (hasFilters ? ' btn-white search-button' : ' btn-cancel')}
                style={{ width: '100%' }}
                onClick={() => hasFilters && this.handleSearch()}
              >
                <i className="ico-search" /> {t(`${tKey}SEARCH_BTN`)}
              </Button>
            </Col>
            <Clearfix />
            {this.state.showRelatedDossierCombos && (
              this.state.dossierTypeId === dossierTypes.sales ? (
                <Field
                  colSm={3}
                  id="saleTypeId"
                  name="saleTypeId"
                  controlLabel={t(`${tKey}SALES_TYPE`)}
                  placeholder={t(`${tKey}SALES_TYPE`)}
                  component={InputSelect}
                  options={saleTypeCombo}
                  valueKey="id"
                  labelKey="value"
                  onInputChange={(value) => this.handleSaleTypeChange(value)}
                />
              ) : this.state.dossierTypeId === dossierTypes.purchase ? (
                <Field
                  colSm={3}
                  id="purchaseTypeId"
                  name="purchaseTypeId"
                  controlLabel={t(`${tKey}PURCHASE_TYPE`)}
                  placeholder={t(`${tKey}PURCHASE_TYPE`)}
                  component={InputSelect}
                  options={purchaseTypeCombo}
                  valueKey="id"
                  labelKey="value"
                  onInputChange={(value) => this.handlePurchaseTypeChange(value)}
                />
              ) : this.state.dossierTypeId === dossierTypes.paperwork ? (
                <Field
                  colSm={3}
                  id="paperworkTypeId"
                  name="paperworkTypeId"
                  controlLabel={t(`${tKey}PAPERWORK_TYPE`)}
                  placeholder={t(`${tKey}PAPERWORK_TYPE`)}
                  component={InputSelect}
                  options={paperworkTypes}
                  valueKey="id"
                  labelKey="value"
                  onInputChange={(value) => this.handlePaperworkTypeChange(value)}
                />
              ) : this.state.dossierTypeId === dossierTypes.campaign && (
                <Field
                  colSm={3}
                  id="campaignTypeId"
                  name="campaignTypeId"
                  controlLabel={t(`${tKey}CAMPAIGN_TYPE`)}
                  placeholder={t(`${tKey}CAMPAIGN_TYPE`)}
                  component={InputSelect}
                  options={campaignsTypes}
                  valueKey="id"
                  labelKey="value"
                  onInputChange={(value) => this.handleCampaignTypeChange(value)}
                />
              )
            )}
            {this.state.showRelatedDossierCombos && this.state.dossierTypeId === dossierTypes.sales && (
              <Field
                colSm={3}
                id="operationTypeId"
                name="operationTypeId"
                controlLabel={t(`${tKey}OPERATION_TYPE`)}
                placeholder={t(`${tKey}OPERATION_TYPE`)}
                component={InputSelect}
                options={operationTypeCombo}
                valueKey="id"
                labelKey="value"
                multi
                disabled={!operationTypeCombo || operationTypeCombo.length === 0}
                onInputBlur={(e, value) => this.handleOperationTypeBlur(e, value)}
              />
            )}
          </Row>
          {this.state.searched && stagesCombo && stagesCombo.length > 0 && [
            <hr key="separatorTimeLine" />,
            <Row className="time-line-container" key="timeLineContainer">
              <Col sm={12}>
                <h4>{t(`${tKey}CHOOSE_STAGE`)}:</h4>
              </Col>
              <Col sm={12}>
                <TimeLine
                  t={t}
                  show={true}
                  stages={stagesCombo}
                  actions={{ setActiveStage, openAddConditionsModal, openEditConditionsModal, deleteCondition }}
                  activeStage={activeStage}
                  docs={conditionDocumental}
                  fields={conditionNoDocumental}
                  others={conditionNoDocumentalOther}
                  documentsTypes={documentsTypes}
                />
              </Col>
            </Row>
          ]}
        </Form>
      </div>
    )
  }
}

const validate = (values, props) => {
  const error = {}
  return error
}

export default reduxForm({
  form: 'conditionsMaintenanceForm',
  destroyOnUnmount: false,
  validate
})(ConditionsMaintenance)
