import { put, race, take } from 'redux-saga/effects'
import { openModal, closeModal } from '../../actions/common.js'
import customModalTypes from '../../constants/actions/modals/customModal'

export function * fieldModal (type) {
  yield put(openModal(type))
  const { ok } = yield race({
    ok: take(customModalTypes.CUSTOM_MODAL_VALUE),
    nok: take(customModalTypes.CUSTOM_MODAL_NOK)
  })
  yield put(closeModal())
  return (!!ok && ok.value)
}
