
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { getAuth } from '../../../selectors/access/auth'
import { handleError } from '../../errors/errorManager'
import { showLoader, hideLoader } from '../../../actions/common.js'
import getFleetDocumentDetails from '../../../services/fleets/getFleetDocumentDetails'
import getDependentOrganizedUnits from '../../../services/organizedUnit/getDependentOrganizedUnits'
import { fetchDocumentDetailsFleetSuccess, fetchUnattendedProcessFileListInfoSucces, fetchFleetOrganizedUnitsSuccess, openChargeDetailsModalSuccess } from '../../../actions/management/auxModals/auxModals'
import auxModalsTypes from '../../../constants/actions/management/auxModals/auxModals'
import getUnattendedProcessFileListInfo from '../../../services/unattendedProcess/getUnattendedProcessFileListInfo'
import getChargeDetailsModal from '../../../services/fleets/getChargeDetailsModal'

export function * fetchDocumentDetailsFleet ({ documentTypeUseId, fleetId, dossierList }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)

    const data = yield call(getFleetDocumentDetails, auth.token, { documentTypeUseId: documentTypeUseId, fleetId: fleetId }, dossierList)
    yield put(fetchDocumentDetailsFleetSuccess(data))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchfetchDocumentDetailsFleet () {
  yield takeLatest(auxModalsTypes.FETCH_DOCUMENT_DETAIL_FLEET, fetchDocumentDetailsFleet)
}

export function * fetchUnattendedProcessFileListInfo ({ unattendedProcessId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const fileInfoList = yield call(getUnattendedProcessFileListInfo, auth.token, unattendedProcessId)
    yield put(fetchUnattendedProcessFileListInfoSucces(fileInfoList))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchfetchUnattendedProcessFileListInfo () {
  yield takeLatest(auxModalsTypes.GET_UNATTENDED_PROCESS_FILE_LIST_INFO, fetchUnattendedProcessFileListInfo)
}

export function * fetchFleetOrganizedUnits ({ organizedUnitId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const fleetOrganizedUnits = yield call(getDependentOrganizedUnits, auth.token, organizedUnitId)
    yield put(fetchFleetOrganizedUnitsSuccess(fleetOrganizedUnits))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchFleetOrganizedUnits () {
  yield takeLatest(auxModalsTypes.FETCH_FLEET_ORGANIZED_UNITS, fetchFleetOrganizedUnits)
}

export function * openChargeDetailsModal ({ fleetCharge, fleet }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const dossierChargeDetails = yield call(getChargeDetailsModal, auth.token, { fleetChargeId: fleetCharge.fleetChargeId })
    yield put(openChargeDetailsModalSuccess(fleetCharge, dossierChargeDetails, fleet))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchOpenChargeDetailsModal () {
  yield takeLatest(auxModalsTypes.OPEN_CHARGE_DETAILS_TO_FLEET_MODAL, openChargeDetailsModal)
}
