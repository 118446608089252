import React, { PureComponent } from 'react'
import { Row, Button, Col, Radio } from 'react-bootstrap'
import InputText from '../../../commons/form/InputText'
import InputSelect from '../../../commons/form/InputSelect'
import { reduxForm, Field } from 'redux-form'

class Step4 extends PureComponent {
  render () {
    return (
      <div>
        <Row className="row-border">
          <Field
            controlLabel='Fecha llegada'
            colSm={3}
            id='arriveDate'
            name='arriveDate'
            placeholder='Fecha llegada'
            component={InputText}
          />
          <Field
            controlLabel='Ubicación'
            colSm={3}
            id='place'
            name='place'
            placeholder='Ubicación'
            labelKey='place'
            valueKey='place'
            component={InputSelect}
          />
          <Field
            controlLabel='Box'
            colSm={3}
            id='box'
            name='box'
            placeholder='Box'
            labelKey='box'
            valueKey='box'
            component={InputSelect}
          />
          <Col sm={3} className="radio-wrapper">
            <Radio inline>
              Disponible
            </Radio>
            <Radio inline>
              Dañado
            </Radio>
          </Col>
        </Row>
        <div className="button-wrapper text-left">
          <Button className="btn-standard-white btn-continue">
            <i className="ico-check" /> Recepción
          </Button>
          <Button className="btn-standard-white btn-continue">
            Asignar CMR
          </Button>
        </div>
      </div>
    )
  }
}

export default reduxForm({ form: 'newForm' })(Step4)
