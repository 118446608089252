
import React, { PureComponent } from 'react'
import { reduxForm, Field } from 'redux-form'
import InputSelect from '../../../commons/form/InputSelect'
import { Row, Button } from 'react-bootstrap'

class ProcessingAgencyIntegrationGeneralMasterModal extends PureComponent {
  constructor () {
    super()
    this.state = {
      initialized: false
    }
  }

  componentDidMount () {
    if (!this.state.initialized && this.props.agency) {
      this.props.initialize({ processingAgencyCommunicationServiceId: this.props.agency.processingAgencyCommunicationServiceId })
      this.setState({ initialized: true })
    }
  }

  componentDidUpdate (prevProps) {
    if (!this.state.initialized && this.props.agency) {
      this.props.initialize({ processingAgencyCommunicationServiceId: this.props.agency.processingAgencyCommunicationServiceId })
      this.setState({ initialized: true })
    }
  }

  submitAgencyCommunicationServiceId (values) {
    this.props.actions.updateAgencyCommunicationServiceId(this.props.agency.processingAgencyId, !values.processingAgencyCommunicationServiceId ? -1 : values.processingAgencyCommunicationServiceId)
  }

  render () {
    const {
      t, handleSubmit,
      combos: { processingAgencyCommunicationServices }
    } = this.props
    return (
      <div className="masters-container search-wrapper">
        <form autoComplete="off" onSubmit={handleSubmit(this.submitAgencyCommunicationServiceId.bind(this))}>
          <Row className="inputs-wrapper">
            <Field
              name="processingAgencyCommunicationServiceId"
              colSm={4}
              controlLabel={t('MASTERS.PROCESSING_AGENCY_ORGANIZED_UNIT_CONFIG.COMMUNICATION_SERVICE')}
              placeholder={t('MASTERS.PROCESSING_AGENCY_ORGANIZED_UNIT_CONFIG.COMMUNICATION_SERVICE')}
              component={InputSelect}
              valueKey="id"
              labelKey="value"
              options={processingAgencyCommunicationServices}
            />
          </Row>
          <Row className="masters-process-agency-padding">
            <Button
              id="general-tab-submit-button"
              className="btn-standard"
              type="submit"
              disabled={this.props.pristine}>
              {t('MASTERS.PROCESSING_AGENCY_ORGANIZED_UNIT_CONFIG.MODAL.SUBMIT_BUTTON')}
            </Button>
          </Row>
        </form>

      </div>
    )
  }
}

export default reduxForm({
  form: 'editProcessingAgencyIntegrationGeneralMasterModal',
  validate: (values, props) => {
  }
})(ProcessingAgencyIntegrationGeneralMasterModal)
