import React, { PureComponent } from 'react'
import { Table, OverlayTrigger, Popover, Label, Button, Glyphicon } from 'react-bootstrap'
import { currencySymbol } from '../../constants/dossier/common/currencies'
import settings from '../../setting'

class Tasks extends PureComponent {
  render () {
    const popoverTarea = (
      <Popover id="popover-tarea">
        <div className="popover-item">
          <label>Usuario último estado:</label><br/>
          Jose Manuel
        </div>
        <div className="popover-item">
          <label>Fecha último estado:</label><br/>
          10/11/2017 12:20
        </div>
        <div className="popover-item">
          <label>Importe a cobrar:</label><br/>
          5,000.00 {currencySymbol[settings.currency]}
        </div>
        <div className="popover-item">
          <label>Total cobrado:</label><br/>
          5,000.00 {currencySymbol[settings.currency]}
        </div>
        <div className="popover-item">
          <label>Pendiente de cobro:</label><br/>
          5,000.00 {currencySymbol[settings.currency]}
        </div>
        <div className="popover-item">
          <label>Cobros incluídos:</label><br/>
          5,000.00 {currencySymbol[settings.currency]}
        </div>
        <div className="popover-item">
          <label>Cobros validados:</label><br/>
          5,000.00 {currencySymbol[settings.currency]}
        </div>
      </Popover>
    )
    const popoverCliente = (
      <Popover id="popover-tarea">
        <div className="popover-item">
          <label>Nombre:</label><br/>
          Gorka Aguirre Velasco
        </div>
        <div className="popover-item">
          <label>Número entidad principal:</label><br/>
          CLI0001236
        </div>
        <div className="popover-item">
          <label>DNI/CIF:</label><br/>
          7127547Y
        </div>
        <div className="popover-item">
          <label>Dirección:</label><br/>
          Calle de Abajo Nº 3<br/>
          Madrid
        </div>
        <div className="popover-item">
          <label>Movil:</label><br/>
          656899256
        </div>
        <div className="popover-item">
          <label>Email:</label><br/>
          avelasco70@gmail.com
        </div>
      </Popover>
    )

    return (
      <div className="tasks-wrapper">
        <div className="tasks-header">
          <ul className="list-inline">
            <li className="turquoise active">
              TODAS <Label bsStyle="info">311</Label>
            </li>
            <li className="gray">
              ON TIME <Label bsStyle="default">298</Label>
            </li>
            <li className="red">
              ROJOS <Label bsStyle="danger">13</Label>
            </li>
          </ul>
        </div>
        <div className="tasks-body">
          <div className="tasks-panel-left">
            <ul className="list">
              <li>Enviar a aprobación&nbsp;<small>(61)</small>&nbsp;<span className="color-point red"/></li>
              <li>Aprobar expediente&nbsp;<small>(16)</small>&nbsp;<span className="color-point red"/></li>
              <li>Avisar cliente pedidos desviados&nbsp;<span>(3)</span></li>
              <li>Cobrar&nbsp;<small>(3)</small></li>
              <li>Validar Cobros&nbsp;<small>(36)</small>&nbsp;<span className="color-point red"/></li>
              <li>Completar documentación&nbsp;<small>(17)</small>&nbsp;<span className="color-point red"/></li>
              <li>Validar documentación&nbsp;<small>(6)</small></li>
              <li>Facturar&nbsp;<small>(10)</small>&nbsp;<span className="color-point red"/></li>
              <li>Provisionales y que esté en etapa&nbsp;<small>(24)</small></li>
              <li>Preparar entrega&nbsp;<small>(5)</small></li>
              <li>Entregados no documentados&nbsp;<small>(3)</small></li>
              <li>Completar datos&nbsp;<small>(14)</small></li>
              <li>Enviar a trámite&nbsp;<small>(14)</small>&nbsp;<span className="color-point red"/></li>
              <li>Recibir documentación provisional&nbsp;<small>(21)</small></li>
              <li>Tasar vehículo&nbsp;<small>(18)</small></li>
              <li>Validar tasación&nbsp;<small>(5)</small>&nbsp;<span className="color-point red"/></li>
              <li>Hacer pedido&nbsp;<small>(9)</small></li>
              <li>Recepción del vehiculo&nbsp;<small>(12)</small>&nbsp;<span className="color-point red"/></li>
              <li>Preparación de documentación&nbsp;<small>(6)</small></li>
            </ul>
          </div>
          <div className="tasks-panel-right">
            <h2 className="tasks-title">Tareas por hacer</h2>
            <Table hover responsive className="simple-table">
              <thead>
                <tr>
                  <th><i className="ico-sort"/></th>
                  <th className="sorting">TAREA <i className="ico-sort-selected-asc"/></th>
                  <th>EXP/DOC <i className="ico-sort"/></th>
                  <th>CLIENTE <i className="ico-sort"/></th>
                  <th>VEHICULO <i className="ico-sort"/></th>
                  <th>ÚLTIMO CAMBIO <i className="ico-sort"/></th>
                  <th>OTROS <i className="ico-sort"/></th>
                </tr>
              </thead>
              <tbody>
                <tr id="task-1">
                  <td className="status">
                    <span className="color-point green"/>
                  </td>
                  <td className="see-more sorting">
                    <OverlayTrigger trigger={['click']} placement="bottom" overlay={popoverTarea}>
                      <Button bsSize="xsmall" className="icon-only"><Glyphicon glyph="plus" /></Button>
                    </OverlayTrigger>
                    Enviar a aprobación<br/>
                  </td>
                  <td className="see-more">
                    <Button bsSize="xsmall" className="icon-only"><Glyphicon glyph="plus" /></Button>
                    EXP02<br/>
                  </td>
                  <td className="see-more">
                    <OverlayTrigger trigger={['click', 'hover', 'focus']} placement="bottom" overlay={popoverCliente}>
                      <Button bsSize="xsmall" className="icon-only"><Glyphicon glyph="plus" /></Button>
                    </OverlayTrigger>
                    Gamez Ochoa
                  </td>
                  <td className="see-more">
                    <Button bsSize="xsmall" className="icon-only"><Glyphicon glyph="plus" /></Button>
                    Sorento<br/>
                    U005246
                  </td>
                  <td>
                    11/11/2017<br/>
                    10:38:25
                  </td>
                  <td>
                    DOCUMENTOS<br/>
                    <i className="ico-cloud"/> 1/5 <Glyphicon glyph="ok-sign"/> 0/5
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    )
  }
}

export default Tasks
