import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getFormValues } from 'redux-form'
import SearchByChargeForm from '../../../_v2/components/management/SearchByChargeForm'
import { translate } from 'react-polyglot'
import { fetchBankCombo, fetchDocumentStatusCombo } from '../../../actions/combos/combos'
import { setFilters, fetchCharges, fetchChargesDownload } from '../../../actions/search/search'
import { openModal } from '../../../actions/common'
import { openDocumentEditionModal } from '../../../actions/dossier/common/documentEditionModal'

export function mapStateToProps (state) {
  return {
    ...state.search,
    combos: {
      salesmanCombo: state.combos.salesmanCombo,
      banksCombo: state.combos.banksCombo,
      documentStatusCombo: state.combos.documentStatusCombo
    },
    formValues: getFormValues('SearchByCharge')(state)
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchDocumentStatusCombo,
      fetchBankCombo,
      fetchCharges,
      fetchChargesDownload,
      openDocumentEditionModal,
      setFilters,
      openModal
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(SearchByChargeForm))
