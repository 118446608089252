import React, { PureComponent } from 'react'
import { Field } from 'redux-form'
import { Panel, Row } from 'react-bootstrap'
import EditableTable from '../../../commons/editableTable/EditableTable'
import { buttonsSidebar } from '../../../../constants/dossier/common/buttonsSidebar'
import EconomicPlanTotal from '../../sales/economicPlan/EconomicPlanTotal'
import { formatMoney } from '../../../../util/utils'
import { required } from '../../../../util/validationFunctions'
import { Element } from 'react-scroll'
import { permissions } from '../../../../constants/backendIds'

class EconomicPlanGiftsPresents extends PureComponent {
  constructor (props) {
    super()
    this.state = {
      totalPresent: 0,
      costs_giftsOpen: false
    }
  }

  showField (fieldCode, componentCode) {
    let componentPermissionObj = this.props.fieldsConfiguration[componentCode]
    if (componentPermissionObj[fieldCode]) {
      return componentPermissionObj[fieldCode].permission > permissions.hidden
    }
    return false
  }

  render () {
    const {
      t, combos, dossierEconomicPlan, readOnlyFields, totalPresent, fieldsConfiguration, dossierType
    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.'
    const showUploadOrderBtn = !!this.showField('uploadOrderBtn', 'reaconditioning')
    const showUploadInvoiceBtn = !!this.showField('uploadInvoiceBtn', 'reaconditioning')
    const showRecoverBtn = !!this.showField('recoverBtn', 'reaconditioning')
    return (
      <Element name={buttonsSidebar.reaconditioning} className={buttonsSidebar.reaconditioning}>
        <div className="dossier-panel">
          <div className="header-panel" onClick={() => this.setState({ costs_giftsOpen: !this.state.costs_giftsOpen })}>
            <div className="name-wrapper">
              <i className={this.state.costs_giftsOpen ? 'ico-arrow-black-up' : 'ico-arrow-black-down'} />
              <h4>{t(`${tKey}REACONDITIONING.TITLE_PANEL`)}</h4>
              <EconomicPlanTotal
                totals={totalPresent}
                totalText={t(`${tKey}ECONOMIC_PLAN.TOTALS`) + ' ' + t(`${tKey}REACONDITIONING.VTA_IMPORT`)}
                costText={t(`${tKey}ECONOMIC_PLAN.TOTALS`) + ' ' + t(`${tKey}REACONDITIONING.COST`)}
                hideTotalVta={true}
              />
            </div>
          </div>
          <Panel className="table-responsive" onToggle={() => {}} expanded={this.state.costs_giftsOpen}>
            <Panel.Collapse>
              <Row>
                {<br></br>}
                <Field responsive hover
                  component={EditableTable}
                  customClass=" -group"
                  id="purchasePresents"
                  name="dossierEconomicComponent.purchasePresents"
                  t={t}
                  columnsConfig={
                    [
                      { key: 'oculto', onlyShow: true },
                      {
                        th: t(`${tKey}REACONDITIONING.TITLE_PANEL`),
                        key: 'typeId',
                        isSelectCompuesto: true,
                        selectConfig: { options: combos.purchasePresentTypeCombo, valueKey: 'id', labelKey: 'value' },
                        validationFunc: required,
                        className: 'description-table-planteo',
                        keyColumnaAsociada: 'comment',
                        showColumn: fieldsConfiguration.reaconditioning.reaconditioningCol ? fieldsConfiguration.reaconditioning.reaconditioningCol.permission > permissions.hidden : false,
                        nonEditable: fieldsConfiguration.reaconditioning.reaconditioningCol ? fieldsConfiguration.reaconditioning.reaconditioningCol.permission < permissions.editable : true
                      },
                      { key: 'comment', onlyShow: true },
                      { th: t(`${tKey}REACONDITIONING.VTA_IMPORT`), key: 'amount', inputType: 'number', formatFunc: (amount) => formatMoney(amount), nonEditable: true, className: 'amount-table-planteo', showColumn: false },
                      { key: 'percentage', nonEditable: true, className: 'perc-table-planteo', inputType: 'number', showColumn: false },
                      {
                        th: t(`${tKey}REACONDITIONING.COST`),
                        key: 'cost',
                        inputType: 'number',
                        className: 'cost-table-planteo',
                        validationFunc: required,
                        formatFunc: (amount) => formatMoney(amount),
                        showColumn: fieldsConfiguration.reaconditioning.costCol ? fieldsConfiguration.reaconditioning.costCol.permission > permissions.hidden : false,
                        nonEditable: fieldsConfiguration.reaconditioning.costCol ? fieldsConfiguration.reaconditioning.costCol.permission < permissions.editable : true
                      },
                      {
                        th: t(`${tKey}REACONDITIONING.PROVIDER`),
                        key: 'provider',
                        className: 'provider-table-planteo',
                        showColumn: fieldsConfiguration.reaconditioning.providerCol ? fieldsConfiguration.reaconditioning.providerCol.permission > permissions.hidden : false,
                        nonEditable: fieldsConfiguration.reaconditioning.providerCol ? fieldsConfiguration.reaconditioning.providerCol.permission < permissions.editable : true
                      }
                    ]
                  }
                  columnsConfigTotales={
                    [
                      { key: 'name', onlyShow: true },
                      {
                        key: 'oculto',
                        showColumn: fieldsConfiguration.reaconditioning.reaconditioningCol ? fieldsConfiguration.reaconditioning.reaconditioningCol.permission > permissions.hidden : false
                      },
                      { key: 'oculto' },
                      { th: t(`${tKey}REACONDITIONING.VTA_IMPORT`), key: 'amount', inputType: 'number', formatFunc: (amount) => formatMoney(amount), nonEditable: true, className: 'amount-table-planteo', showColumn: false },
                      { key: 'percentage', nonEditable: true, className: 'perc-table-planteo', inputType: 'number', showColumn: false },
                      {
                        th: t(`${tKey}REACONDITIONING.COST`),
                        key: 'cost',
                        inputType: 'number',
                        formatFunc: (amount) => formatMoney(amount),
                        className: 'cost-table-planteo',
                        showColumn: fieldsConfiguration.reaconditioning.costCol ? fieldsConfiguration.reaconditioning.costCol.permission > permissions.hidden : false
                      },
                      {
                        th: t(`${tKey}REACONDITIONING.PROVIDER`),
                        key: 'provider',
                        className: 'provider-table-planteo',
                        showColumn: fieldsConfiguration.reaconditioning.providerCol ? fieldsConfiguration.reaconditioning.providerCol.permission > permissions.hidden : false
                      }
                    ]
                  }
                  columnsConfigPresent={
                    [
                      { key: 'oculto', onlyShow: true },
                      {
                        th: t(`${tKey}REACONDITIONING.TITLE_PANEL`),
                        key: 'name',
                        nonEditable: true,
                        onlyShow: true,
                        className: 'description-table-planteo',
                        keyColumnaAsociada: 'comment',
                        showColumn: fieldsConfiguration.reaconditioning.reaconditioningCol ? fieldsConfiguration.reaconditioning.reaconditioningCol.permission > permissions.hidden : false
                      },
                      { key: 'comment', onlyShow: true },
                      { th: t(`${tKey}REACONDITIONING.VTA_IMPORT`), key: 'amount', inputType: 'number', formatFunc: (amount) => formatMoney(amount), nonEditable: true, className: 'amount-table-planteo', showColumn: false },
                      { key: 'percentage', nonEditable: true, className: 'perc-table-planteo', inputType: 'number', showColumn: false },
                      {
                        th: t(`${tKey}REACONDITIONING.COST`),
                        key: 'cost',
                        inputType: 'number',
                        className: 'cost-table-planteo',
                        validationFunc: required,
                        formatFunc: (amount) => formatMoney(amount),
                        showColumn: fieldsConfiguration.reaconditioning.costCol ? fieldsConfiguration.reaconditioning.costCol.permission > permissions.hidden : false
                      },
                      {
                        th: t(`${tKey}REACONDITIONING.PROVIDER`),
                        key: 'provider',
                        className: 'provider-table-planteo',
                        showColumn: fieldsConfiguration.reaconditioning.providerCol ? fieldsConfiguration.reaconditioning.providerCol.permission > permissions.hidden : false
                      }
                    ]
                  }
                  editableObjectsArrayTotales={totalPresent}
                  editableObjectsArrayPresent={dossierEconomicPlan.purchasePresents || []}
                  updateAction={this.props.updatePurchasePresent}
                  createAction={this.props.createPurchasePresent}
                  deleteAction={this.props.deletePurchasePresent}
                  recoverAction = {this.props.recoverPurchasePresent}
                  readOnlyFields={readOnlyFields}
                  showDeleteAction={fieldsConfiguration.reaconditioning.btnDeleteAction ? fieldsConfiguration.reaconditioning.btnDeleteAction.permission > permissions.hidden : false}
                  showEditAction={fieldsConfiguration.reaconditioning.btnEditAction ? fieldsConfiguration.reaconditioning.btnEditAction.permission > permissions.hidden : false}
                  showAddButton={fieldsConfiguration.reaconditioning.btnAddReaconditioning ? fieldsConfiguration.reaconditioning.btnAddReaconditioning.permission > permissions.hidden : false}
                  showUploadOrderBtn={showUploadOrderBtn}
                  showUploadInvoiceBtn={showUploadInvoiceBtn}
                  showRecoverAction={showRecoverBtn}
                  orderDocTypeUseId= {dossierEconomicPlan.orderDocTypeUseId}
                  invoiceDocTypeUseId= {dossierEconomicPlan.invoiceDocTypeUseId}
                  openDocumentEditionModal = {this.props.openDocumentEditionModal}
                  isPristine={this.props.pristine}
                  dossierType = {dossierType}
                  openDocWarning ={this.props.openDocWarning}
                />
              </Row>
            </Panel.Collapse>
          </Panel>
        </div>
      </Element>
    )
  }
}
export default EconomicPlanGiftsPresents
