import { createStore, applyMiddleware, compose } from 'redux'
// import { HashRouter } from 'react-router-dom'
// import { routerMiddleware as createRouterMiddleware } from 'react-router-redux'
import rootReducer from '../reducers'
import createSagaMiddleware from 'redux-saga'
import rootSagas from '../sagas/index'
// import { createLogger } from 'redux-logger'
import { createHashHistory } from 'history'
import { routerMiddleware as createRouterMiddleware } from 'connected-react-router'

let logger = null
// Uncomment to allow action log
// if (process.env.NODE_ENV !== 'production') {
//   logger = createLogger({
//     collapsed: true
//   })
// }

const sagaMiddleware = createSagaMiddleware()

export const hashHistory = createHashHistory()

const routerMiddleware = createRouterMiddleware(hashHistory)

const composeEnhancers =
  process.env.NODE_ENV !== 'production' &&
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // Specify here name, actionsBlacklist, actionsCreators and other options
    }) : compose

const middleware = logger ? applyMiddleware(sagaMiddleware, routerMiddleware, logger) : applyMiddleware(sagaMiddleware, routerMiddleware)
const enhancer = composeEnhancers(middleware)

const configureStore = (initialState) => {
  const store = createStore(rootReducer(hashHistory), initialState, enhancer)

  if (module.hot) {
    module.hot.accept('../reducers', () =>
      store.replaceReducer(require('../reducers'))
    )
  }

  sagaMiddleware.run(rootSagas)

  return store
}

export default configureStore
