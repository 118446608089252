import mastersActionTypes from '../../../constants/actions/masters/masters'

function initialState () {
  return {
    financingList: [],
    financingCount: 0,
    pagesCount: 0,
    filter: {
      page: 1,
      orderBy: '',
      pageSize: 10
    },
    financingModal: {
      financing: null,
      showModal: false
    },
    financingModalUo: {
      financing: null,
      showModal: false,
      uoList: null,
      uoListCount: 0,
      pagesCount: 0,
      uoAddeds: null,
      filter: {
        page: 1,
        pageSize: 10,
        orderBy: ''
      }
    },
    financingModalProduct: {
      financing: null,
      showModal: false,
      productList: null,
      productListCount: 0,
      pagesCount: 0,
      filter: {
        page: 1,
        pageSize: 10,
        orderBy: ''
      }
    }
  }
}

function fetchFinancingListSuccess (state, { financingList, filter }) {
  return {
    ...state,
    financingList: financingList.financingCompanyList,
    financingCount: financingList.financingCount,
    pagesCount: financingList.pagesCount,
    filter
  }
}

function saveUoFinancingCompanySucces (state, { uoAddeds }) {
  return {
    ...state,
    financingModalUo: {
      ...state.financingModalUo,
      uoAddeds: uoAddeds
    }
  }
}

function openEditModalSuccess (state, { financing }) {
  const initial = initialState()
  return {
    ...state,
    financingModal: {
      ...initial.financingModal,
      financing,
      showModal: true
    }
  }
}

function openEditModalUoSuccess (state, { financing, uoList, page, pageSize, orderBy }) {
  return {
    ...state,
    financingModalUo: {
      showModal: true,
      uoList: uoList.items,
      financing: financing,
      uoListCount: uoList.count,
      pagesCount: uoList.pages,
      uoAddeds: state.financingModalUo.uoAddeds,
      filter: {
        page: page,
        orderBy: orderBy,
        pageSize: pageSize
      }
    }
  }
}

function openAddModalSuccess (state) {
  const initial = initialState()
  return {
    ...state,
    financingModal: {
      ...initial.financingModal,
      showModal: true
    }
  }
}

function closeEditModalSuccess (state) {
  return {
    ...state,
    financingModal: {
      ...initialState().financingModal
    }
  }
}
function closeEditModalUoSuccess (state) {
  return {
    ...state,
    financingModalUo: {
      ...initialState().financingModalUo
    }
  }
}

function openEditFinancingModalProduct (state, { financing }) {
  const initial = initialState()
  return {
    ...state,
    financingModalProduct: {
      ...initial.financingModalProduct,
      showModal: true,
      financing: financing
    }
  }
}

function closeEditFinancingModalProduct (state) {
  const initial = initialState()
  return {
    ...state,
    financingModalProduct: {
      ...initial.financingModalProduct
    }
  }
}

function fetchFinancingProductCompanySuccess (state, { productList, filter }) {
  return {
    ...state,
    financingModalProduct: {
      ...state.financingModalProduct,
      productList: productList.financingTypeMastersList,
      productListCount: productList.financingCount,
      pagesCount: productList.pagesCount,
      filter: filter
    }

  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case mastersActionTypes.FETCH_FINANCING_SUCCESS:
      return fetchFinancingListSuccess(state, action)
    case mastersActionTypes.OPEN_EDIT_FINANCING_MODAL_SUCCESS:
      return openEditModalSuccess(state, action)
    case mastersActionTypes.OPEN_ADD_FINANCING_MODAL_SUCCESS:
      return openAddModalSuccess(state, action)
    case mastersActionTypes.CLOSE_EDIT_FINANCING_MODAL:
      return closeEditModalSuccess(state, action)
    case mastersActionTypes.OPEN_EDIT_FINANCING_MODAL_UO_SUCCESS:
      return openEditModalUoSuccess(state, action)
    case mastersActionTypes.CLOSE_EDIT_FINANCING_MODAL_UO:
      return closeEditModalUoSuccess(state, action)
    case mastersActionTypes.SAVE_UO_FINANCING_COMPANY_SUCCESS:
      return saveUoFinancingCompanySucces(state, action)
    case mastersActionTypes.OPEN_EDIT_FINANCING_MODAL_PRODUCT:
      return openEditFinancingModalProduct(state, action)
    case mastersActionTypes.CLOSE_EDIT_FINANCING_MODAL_PRODUCT:
      return closeEditFinancingModalProduct(state, action)
    case mastersActionTypes.FETCH_FINANCING_PRODUCT_COMPANY_SUCCES:
      return fetchFinancingProductCompanySuccess(state, action)
    default:
      return state
  }
}
