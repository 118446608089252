import headerActionTypes from '../../constants/actions/header/header'

function initialState () {
  return {
    headerValues: {
      showUOTree: false,
      showEntity: false,
      showVehicle: false
    }
  }
}

function setHeader (state, { values }) {
  return {
    ...state,
    headerValues: values
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case headerActionTypes.SET_HEADER_SUCCESS:
      return setHeader(state, action)
    default:
      return state
  }
}
