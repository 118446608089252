export default {
  format_email_error: {
    value: 'Formato incorrecto para el email',
    tag: 'emailFormatInvalid'
  },
  id_sign_req_error: {
    value: 'IdFirma es requerido',
    tag: 'apiSignError'
  },
  id_sig_value_error: {
    value: 'El tipo de firma requerida no es correcta',
    tag: 'apiSignError'
  },
  id_doc_req_error: {
    value: 'IdDocumento es requerido',
    tag: 'apiSignError'
  },
  id_type_re_error: {
    value: 'IdTipo es requerido',
    tag: 'apiSignError'
  },
  signer_name_error: {
    value: 'NombreFirmante es requerido',
    tag: 'apiSignError'
  },
  signer_name_error_2: {
    value: 'El nombre del firmante es obligatorio',
    tag: 'apiSignError'
  },
  signer_nif_error: {
    value: 'Nif es requerido',
    tag: 'apiSignError'
  },
  signer_nif_error2: {
    value: 'El NIF es obligatorio',
    tag: 'apiSignError'
  },
  size_format_error: {
    value: 'Longitud máxima 50 caracteres',
    tag: 'apiSignError'
  },
  order_req_error: {
    value: 'Orden es requerido',
    tag: 'apiSignError'
  },
  order_page_value_error: {
    value: 'El valor debe ser mayor que 0',
    tag: 'apiSignError'
  },
  page_num_req_error: {
    value: 'Pagina es requerido',
    tag: 'apiSignError'
  },
  page_num_value_error: {
    value: 'El valor debe ser mayor que 0',
    tag: 'apiSignError'
  },
  width_req_error: {
    value: 'Ancho es requerido',
    tag: 'apiSignError'
  },
  heigth_req_error: {
    value: 'Alto es requerido',
    tag: 'apiSignError'
  },
  data_size_pos_error: {
    value: 'El valor debe estar comprendido entre  0 y 100.00',
    tag: 'apiSignError'
  },
  positionX_req_error: {
    value: 'OrigenX es requerido',
    tag: 'apiSignError'
  },
  positionY_req_error: {
    value: 'OrigenY es requerido',
    tag: 'apiSignError'
  },
  data_format_error: {
    value: 'Formato incorrecto de fecha',
    tag: 'apiSignError'
  }

}
