import mastersActionTypes from '../../../constants/actions/masters/masters'

function initialState () {
  return {
    processingAgencyOrganizedUnitConfigs: [],
    processingAgencyOrganizedUnitConfigsCount: 0,
    pagesCount: 0,
    languageList: [],
    filter: {
      page: 1,
      orderBy: '',
      pageSize: 10
    },
    processingAgencyOrganizedUnitConfigModal: {
      showModal: false
    }
  }
}

function fetchProcessingAgencyOrganizedUnitConfigListSuccess (state, { processingAgencyOrganizedUnitConfigList, filter }) {
  return {
    ...state,
    processingAgencyOrganizedUnitConfigs: processingAgencyOrganizedUnitConfigList.processingAgencyOrganizedUnitConfigs,
    processingAgencyOrganizedUnitConfigsCount: processingAgencyOrganizedUnitConfigList.processingAgencyOrganizedUnitConfigsCount,
    pagesCount: processingAgencyOrganizedUnitConfigList.pagesCount,
    languageSelected: processingAgencyOrganizedUnitConfigList.languageSelected,
    filter
  }
}

function showProcessingAgencyOrganizedUnitConfigModalSuccess (state, { show, processingAgencyOrganizedUnitConfig }) {
  const initial = initialState()
  return {
    ...state,
    processingAgencyOrganizedUnitConfigModal: {
      ...initial.processingAgencyOrganizedUnitConfigModal,
      processingAgencyOrganizedUnitConfig: processingAgencyOrganizedUnitConfig,
      showModal: show
    }
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case mastersActionTypes.FETCH_PROCESSING_AGENCY_ORGANIZED_UNIT_CONFIG_LIST_SUCCESS:
      return fetchProcessingAgencyOrganizedUnitConfigListSuccess(state, action)
    case mastersActionTypes.SHOW_PROCESSING_AGENCY_ORGANIZED_UNIT_CONFIG_MODAL_SUCCESS:
      return showProcessingAgencyOrganizedUnitConfigModalSuccess(state, action)
    default:
      return state
  }
}
