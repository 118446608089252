import React, { PureComponent } from 'react'
import { Field } from 'redux-form'
import { Row, Clearfix, Button } from 'react-bootstrap'
import InputText from '../commons/form/InputText'
import SimpleTablePage from '../../containers/commons/general/SimpleTablePage'
import IDocCarPagination from '../commons/iDocCarPagination'
import { multipleNormalize, toUpperCase, trimAll } from '../../util/normalizeFunctions'
import EntityPopover from './EntityPopover'
import VehiclePopover from './VehiclePopover'
import DossierPopover from './DossierPopover'

class SearchByProcessingAgencyForm extends PureComponent {
  constructor (props) {
    super()
    this.state = {
    }
  }

  exportData () {
    const tKey = 'SEARCH.CATEGORIES.PROCESSING_AGENCY.'
    let columnsExcel = []
    columnsExcel.push(this.props.t(`${tKey}DOSSIER`) + '#Dossier')
    columnsExcel.push(this.props.t(`${tKey}ENTITY`) + '#Entity')
    columnsExcel.push(this.props.t(`${tKey}VEHICLE`) + '#Vehicle')
    columnsExcel.push(this.props.t(`${tKey}STAGE`) + '#Stage')
    this.props.exportToExcel(columnsExcel)
  }

  render () {
    const {
      t, fields, fieldFill, className,
      hasSearch, processingAgenciesData, selectedFilters, onClickResetSearch, page, pages, count, onPageChange, onOrderChange, getIconClass
    } = this.props
    const tKey = 'SEARCH.CATEGORIES.PROCESSING_AGENCY.'
    return (
      <div>
        {!hasSearch ? (
          <Row className='flexRow'>
            <Field
              colSm={3}
              id="chassisVehicle"
              name='processing_Agency.chassis'
              placeholder={t(`${tKey}CHASSIS`)}
              controlLabel={t(`${tKey}CHASSIS`)}
              component={InputText}
              type="text"
              maxLength={17}
              normalize={value => multipleNormalize(value, [toUpperCase, trimAll])}
              onInputChange={(val) => fieldFill({ chassis: val })}
              customClass={fields.chassis ? className : ''}
            />
            <Field
              colSm={3}
              id="licence_plateVehicle"
              name='processing_Agency.licensePlate'
              placeholder={t(`${tKey}LICENSE_PLATE`)}
              controlLabel={t(`${tKey}LICENSE_PLATE`)}
              component={InputText}
              type="text"
              normalize={value => multipleNormalize(value, [toUpperCase, trimAll])}
              onInputChange={(val) => fieldFill({ licence_plate: val })}
              customClass={fields.licence_plate ? className : ''}
            />
            <Field
              colSm={3}
              id="idNumber"
              name='processing_Agency.dniCif'
              key='processing_Agency.dniCif'
              placeholder={t(`${tKey}DNI_CIF`)}
              controlLabel={t(`${tKey}DNI_CIF`)}
              component={InputText}
              type="text"
              onInputChange={(val) => fieldFill({ DNI_CIF: val })}
              customClass={fields.idNumber ? className : ''}
            />
            <Field
              colSm={3}
              id="clientNameEntity"
              name='processing_Agency.name'
              key='processing_Agency.name'
              placeholder={t(`${tKey}NAME`)}
              controlLabel={t(`${tKey}NAME`)}
              component={InputText}
              type="text"
              onInputChange={(val) => fieldFill({ clientName: val })}
              customClass={fields.clientName ? className : ''}
            />
            <Field
              colSm={3}
              id="clientSurname1"
              name='processing_Agency.surname1'
              key='processing_Agency.surname1'
              placeholder={t(`${tKey}SURNAME1`)}
              controlLabel={t(`${tKey}SURNAME1`)}
              component={InputText}
              type="text"
              onInputChange={(val) => fieldFill({ clientSurname1: val })}
              customClass={fields.clientSurname1 ? className : ''}
            />
            <Field
              colSm={3}
              id="clientSurname2"
              name='processing_Agency.surname2'
              key='processing_Agency.surname2'
              placeholder={t(`${tKey}SURNAME2`)}
              controlLabel={t(`${tKey}SURNAME2`)}
              component={InputText}
              type="text"
              onInputChange={(val) => fieldFill({ clientSurname2: val })}
              customClass={fields.clientSurname2 ? className : ''}
            />
          </Row>
        ) : ([
          <Row className="filters-selected" key="filters-selected">
            <ul className="list-inline pull-left">
              {selectedFilters().length === 0 ? <li>{t(`${tKey}NO_SELECTED_FILTERS`)}</li> : (selectedFilters())}
            </ul>
            <Clearfix/>
            <Button
              onClick={(event) => onClickResetSearch(event)}
              bsStyle="default"
              bsSize="small"
              className="pull-left"
              id='modSearchButtonIdEntity'
            >
              {t('SEARCH.ACTIONS.MODIFY_SEARCH')}
              <i className="ico-edit-turquoise" />
            </Button>
            {count > 0 &&
            <Button
              onClick={(event) => this.exportData(event)}
              bsStyle="default"
              bsSize="small"
              id='expSearchButtonIdEntity'>
              {t('SEARCH.ACTIONS.EXPORT')}
              <i className="ico-if_Download_1031520" />

            </Button>
            }
          </Row>,
          <div className="table-wrapper" key="table-wrapper">
            <SimpleTablePage
              columns={[
                <th key={1} data-field="number" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}DOSSIER_NUMBER`)}&nbsp;<i className={getIconClass('number')}/>
                </th>,
                <th key={2} data-field="entity" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}BY_ENTITY`)}&nbsp;<i className={getIconClass('entity')}/>
                </th>,
                <th key={3} data-field="vehicle" onClick={(event) => onOrderChange(event)}>{t(`${tKey}BY_VEHICLE`)}&nbsp;<i className={getIconClass('vehicle')}/></th>,
                <th key={4} data-field="stage" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}STAGE`)}&nbsp;<i className={getIconClass('stage')}/>
                </th>
              ]}
              rows={processingAgenciesData && processingAgenciesData.map((row, idx) => (
                <tr key={idx}>
                  <td className="see-more">
                    <DossierPopover
                      idx={idx}
                      t={t}
                      id={'popover-dossier' + idx}
                      dossier={row.dossier}
                    />
                  </td>
                  <td className="see-more">
                    <EntityPopover
                      t={t}
                      entity={row.entity}
                      half={(processingAgenciesData.length || 1) / (idx + 1)}
                      idx={idx}
                      id={'popover-entity' + idx}
                    />
                  </td>
                  <td className="see-more">
                    <VehiclePopover
                      t={t}
                      vehicle={row.vehicle}
                      half={(processingAgenciesData.length || 1) / (idx + 1)}
                      idx={idx}
                      id={'popover-vehicle' + idx}
                      isHeader = {true}
                      dossierSubTypeId={row.dossierParentSubtypeId}
                    />
                  </td>
                  <td>{row.stage}</td>
                </tr>
              ))}
            />
          </div>,
          <div className="search-footer" key="search-footer">
            <IDocCarPagination
              id="btn_pag_sales"
              page={page}
              pagesCount={pages}
              totalRows= {count}
              onSelectAction={(page) => onPageChange(page)}
            />
          </div>
        ])}
      </div>
    )
  }
}

export default SearchByProcessingAgencyForm
