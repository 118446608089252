import React, { PureComponent } from 'react'
import { reduxForm, Field } from 'redux-form'
import { Row, Col, Table, Button, Tooltip, OverlayTrigger, Glyphicon } from 'react-bootstrap'
import IDocCarPagination from '../../commons/iDocCarPagination'
import CustomFieldModalPage from '../../../containers/masters/CustomField/CustomFieldModalPage'
import InputSelect from '../../commons/form/InputSelect'

class CustomFieldMaster extends PureComponent {
  constructor () {
    super()
    this.state = {
      filter: {
        dossierSubtypeId: null
      }
    }
  }

  getSortIcon (column = '') {
    switch (this.props.filter.orderBy) {
      case '+' + column:
        return 'ico-sort-selected-asc'
      case '-' + column:
        return 'ico-sort-selected-desc'
      default:
        return 'ico-sort'
    }
  }

  changeOrder (field) {
    switch (this.props.filter.orderBy) {
      case `+${field}`:
        this.props.actions.fetchCustomFieldList({ ...this.state.filter, ...this.props.filter, orderBy: `-${field}` })
        break
      default:
        this.props.actions.fetchCustomFieldList({ ...this.state.filter, ...this.props.filter, orderBy: `+${field}` })
        break
    }
  }

  handleDossierSubtypeChange (dossierSubtypeId) {
    if (this.state.filter.dossierSubtypeId !== dossierSubtypeId) {
      this.setState({ filter: { ...this.state.filter, dossierSubtypeId: dossierSubtypeId, page: 1 } })
    }
  }

  searchCustomFieldList () {
    this.props.actions.fetchCustomFieldList({ ...this.props.filter, ...this.state.filter, page: 1 })
  }

  resetFilters () {
    this.setState({
      filter: {
        dossierSubtypeId: null
      }
    }, () => { this.props.actions.fetchCustomFieldList({ ...this.props.filter, ...this.state.filter, page: 1 }) })
  }

  render () {
    const {
      t, customFieldList, pagesCount, customFieldsCount, filter,
      combos: { dossierSubtypes, customFieldTypes },
      filter: { page },
      actions: { openCustomFieldModal, fetchCustomFieldList, deleteCustomField, recoverCustomField }
    } = this.props
    const tKey = 'MASTERS.CUSTOM_FIELDS.'
    const editTooltip = (<Tooltip id="editTooltip">{t('MASTERS.EDIT')}</Tooltip>)
    const deleteTooltip = (<Tooltip id="deleteTooltip">{t('MASTERS.DELETE')}</Tooltip>)
    const recoverTooltip = (<Tooltip id="recoverTooltip">{t('MASTERS.RECOVER')}</Tooltip>)
    const hasFilters = (this.state.filter.dossierSubtypeId)
    return (
      <div className="admin-wrapper">
        <CustomFieldModalPage/>
        <Row className="section-users-header">
          <Col sm={12}>
            <div className="header-panel">
              <h4>{t(`${tKey}TITLE`)}</h4>
            </div>
          </Col>
        </Row>
        <Row>
          <div className="search-form">
            <span className="title-search"><i className="ico-search" /> {t(`${tKey}SEARCH_TITLE`)}</span>
            <Row>
              <Field
                name="filters.dossierSubtypeId"
                colSm={4}
                controlLabel={t(`${tKey}DOSSIER_SUBTYPE`)}
                placeholder={t(`${tKey}DOSSIER_SUBTYPE`)}
                component={InputSelect}
                valueKey="id"
                labelKey="value"
                options={dossierSubtypes}
                onInputChange={(value) => this.handleDossierSubtypeChange(value)}
              />
              <Col sm={4} className="btn-wrapper">
                <p></p>
                <Button
                  className={'btn-white btn-icon btn-input btn-white search-button'}
                  onClick={() => this.searchCustomFieldList()}>
                  <i className="ico-search" />
                  {t(`${tKey}SEARCH`)}
                </Button>
                <Button
                  className={'btn-icon btn-input ' + (hasFilters ? 'btn-standard' : 'btn-cancel')}
                  onClick={() => {
                    this.props.initialize({})
                    this.resetFilters()
                  }}
                >
                  <i className="ico-cancel" />
                  {t('MASTERS.RESET_BUTTON')}
                </Button>
              </Col>
            </Row>
          </div>
        </Row>
        <Col sm={12} className="col-row  section-users-body">
          <Table className="editable-table" hover responsive>
            <thead>
              <tr>
                <th onClick={() => { this.changeOrder('code') }}>
                  {t(`${tKey}FIELD_CODE`)}
                  <i className={this.getSortIcon('code')} />
                </th>
                <th onClick={() => { this.changeOrder('viewComponentCode') }}>
                  {t(`${tKey}COMPONENT`)}
                  <i className={this.getSortIcon('viewComponentCode')} />
                </th>
                <th onClick={() => { this.changeOrder('name') }}>
                  {t(`${tKey}FIELD_NAME`)}
                  <i className={this.getSortIcon('name')} />
                </th>
                <th onClick={() => { this.changeOrder('CustomFieldTypeName') }}>
                  {t(`${tKey}FIELD_TYPE`)}
                  <i className={this.getSortIcon('CustomFieldTypeName')} />
                </th>
                <th className='accesoriesActions'>{t(`${tKey}ACTIONS`)}</th>
              </tr>
            </thead>
            <tbody>
              {customFieldList?.length > 0
                ? customFieldList.map((customField, idx) => {
                  return (
                    <tr key={idx} className={customField.isDeleted ? 'is-deleted' : ''}>
                      <td>{customField.code}</td>
                      <td>{customField.viewComponent?.code}</td>
                      <td>{customField.customFieldLocales && customField.customFieldLocales.length > 0
                        ? customField.customFieldLocales.find(lang => (lang.languageId ?? '') === this.props.languageSelected)?.name
                        : ''}</td>
                      <td>{customFieldTypes?.find(x => x.id === customField.customFieldTypeId)?.value ?? null}</td>
                      <td>
                        <div>
                          <a onClick={() => openCustomFieldModal(customField, customField.dossierSubTypeId)}>
                            <OverlayTrigger placement="left" overlay={editTooltip}>
                              <i className="ico-edit-white" />
                            </OverlayTrigger>
                          </a>
                          { customField.isDeleted
                            ? (<a onClick={() => recoverCustomField(customField.customFieldId)}>
                              <OverlayTrigger placement="left" overlay={recoverTooltip}>
                                <Glyphicon glyph="repeat" />
                              </OverlayTrigger>
                            </a>)
                            : (<a onClick={() => deleteCustomField(customField.customFieldId)}>
                              <OverlayTrigger placement="left" overlay={deleteTooltip}>
                                <i className="ico-trash" />
                              </OverlayTrigger>
                            </a>)
                          }
                        </div>
                      </td>
                    </tr>)
                })
                : (<tr>
                  <td colSpan={10}><h2>{t(`${tKey}RESULTS_NOT_FOUND`)}</h2></td>
                </tr>)}
              {this.state.filter?.dossierSubtypeId && (
                <tr className="button-table-wrapper">
                  <td>
                    <Button
                      className="btn-editable-table btn-standard"
                      onClick={() => openCustomFieldModal(null, this.state.filter?.dossierSubtypeId)}>
                      + {t('MASTERS.ADD')}
                    </Button>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          <div className="section-users-footer">
            <nav key={1} className="text-center">
              <IDocCarPagination
                id="btn_pag_sales"
                page={page}
                pagesCount={pagesCount}
                totalRows={customFieldsCount}
                onSelectAction={(value) => fetchCustomFieldList({ ...filter, ...this.state.filter, page: value })}
              />
            </nav>
          </div>
        </Col>
      </div>
    )
  }
}

export default reduxForm({
  form: 'customFildList'
})(CustomFieldMaster)
