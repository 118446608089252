import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import ExtraSaleMasters from '../../../components/masters/extrasale/ExtraSaleMasters'
import { translate } from 'react-polyglot'
import { openExtraSaleModal, deleteExtraSaleModal, fetchExtraSalesMasters, recoverExtraSaleModal } from '../../../actions/masters/masters'
import { fetchOrganizedUnitsTree } from '../../../actions/combos/combos'

export function mapStateToProps (state) {
  return {
    combos: {
      UOTreeCombo: state.combos.UOTreeCombo
    },
    extraSalesMastersList: state.entityMasters.extraSalesMastersList
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      openExtraSaleModal,
      deleteExtraSaleModal,
      fetchExtraSalesMasters,
      recoverExtraSaleModal,
      fetchOrganizedUnitsTree
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(ExtraSaleMasters))
