import React, { PureComponent } from 'react'
import { Button, Modal, Col, Row, FormGroup, Table } from 'react-bootstrap'
import TextInput from '../../commons/TextInput'
import InputSelect from '../../commons/form/InputSelect'
import InputDatePicker from '../../commons/form/InputDatePicker'
import { reduxForm, Field } from 'redux-form'
import { isEmail, customValidations } from '../../../util/validationFunctions'
import { validateBusinessRfcFormat, validateBusinessRfc, validateBusinessRfcDate } from '../../../util/mxEntityValidationFunctions'
import { documentEntityType as documentEntityTypeConstants } from '../../../constants/dossier/common/documentEntityType'
import DocumentListItemRecurrentEntity from '../../dossiers/common/documentaryManagement/documentaryManagementChild/DocumentListItemRecurrentEntity'
import { permissions, clientInformation, entityTypesId } from '../../../constants/backendIds'
import InputText from '../../commons/form/InputText'
import { commercialSocietyTypeIdFieldCode, commonCodes, recurrentEntityTabCode, rfcFieldCode, activityBproFields } from '../../../constants/dossier/common/fieldCodePermissions'
import { comboType, numberCombo, activityTypes } from '../../../constants/dossier/common/client'
import { EntityType } from '../../../constants/dossier/common/entityTypes'
import settings from '../../../setting'
import {
  asyncValidateRecurrentEntity, getFieldsRecurrentValidated
} from '../../../util/validationFunctionEntityDniAsync'
import { validationEntityPromisesOrder } from '../../../constants/validatedFields/backValidationPromisesOrder'
import promisesStatus from '../../../constants/promisesStatus'
import NormalizedAddress from '../../dossiers/common/client/NormalizedAddress'
import InputTreeSelect from '../../commons/form/InputTreeSelect'

class RecurrentEntityModal extends PureComponent {
  constructor () {
    super()
    this.state = {
      entityId: null,
      name: null,
      emailAddress: null,
      selectedUo: null,
      uoList: [],
      organizedUnitId: null,
      documents: [],
      entitySubTypeDisabled: true,
      order: {
        columnName: null,
        direction: 'asc'
      },
      rfc: null,
      commercialSocietyTypeId: null,
      startStreet: null,
      enndStreet: null,
      externalNumber: null,
      internalNumber: null,
      countryCode: null,
      state: null,
      city: null,
      colonyCode: null,
      townCode: null,
      cp: null,
      combo: 0,
      alert: true,
      recurrentEntityTypeCombo: null,
      activityBproId: null,
      birthdayDate: null,
      constitutionAct: null,
      constitutionDate: null,
      private: false,
      bussiness: false
    }
  }

  dateLessTodayValidation (value, allValues, props) {
    const result = customValidations('LessToday', value, props)
    return result === Object(result) ? undefined : result
  }

  validatePermissions (type) {
    return this.state[type] && this.state[type].permission && this.state[type].permission !== permissions.hidden
  }

  submitRecurrentEntity (values) {
    const body = {
      ...values.recurrentEntity
    }

    this.props.actions.submitRecurrentEntity(this.props.entityId, body)
    this.props
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (this.props.sections && (prevProps.sections !== this.props.sections || prevProps.showModal !== this.props.showModal) && this.props.sections.find(s => s.code === commonCodes.admin)) {
      let admin = this.props.sections.find(s => s.code === commonCodes.admin)
      let recEntTabCode = admin.sectionTabsConfiguration ? admin.sectionTabsConfiguration.find(x => x.code === recurrentEntityTabCode.recEntTabCode && x.permission > permissions.hidden) : null
      if (recEntTabCode && recEntTabCode.sectionFieldsConfiguration) {
        this.setState({ rfc: recEntTabCode.sectionFieldsConfiguration.find(x => x.code === rfcFieldCode.rfc) })
        this.setState({ startStreet: recEntTabCode.sectionFieldsConfiguration.find(x => x.code === rfcFieldCode.startStreet) })
        this.setState({ enndStreet: recEntTabCode.sectionFieldsConfiguration.find(x => x.code === rfcFieldCode.enndStreet) })
        this.setState({ externalNumber: recEntTabCode.sectionFieldsConfiguration.find(x => x.code === rfcFieldCode.externalNumber) })
        this.setState({ internalNumber: recEntTabCode.sectionFieldsConfiguration.find(x => x.code === rfcFieldCode.internalNumber) })
        this.setState({ countryCode: recEntTabCode.sectionFieldsConfiguration.find(x => x.code === rfcFieldCode.countryCode) })
        this.setState({ state: recEntTabCode.sectionFieldsConfiguration.find(x => x.code === rfcFieldCode.state) })
        this.setState({ city: recEntTabCode.sectionFieldsConfiguration.find(x => x.code === rfcFieldCode.city) })
        this.setState({ colonyCode: recEntTabCode.sectionFieldsConfiguration.find(x => x.code === rfcFieldCode.colonyCode) })
        this.setState({ townCode: recEntTabCode.sectionFieldsConfiguration.find(x => x.code === rfcFieldCode.townCode) })
        this.setState({ cp: recEntTabCode.sectionFieldsConfiguration.find(x => x.code === rfcFieldCode.cp) })
        this.setState({ activityBproId: recEntTabCode.sectionFieldsConfiguration.find(x => x.code === activityBproFields.activityBproId) })
        this.setState({ birthdayDate: recEntTabCode.sectionFieldsConfiguration.find(x => x.code === activityBproFields.birthdayDate) })
        this.setState({ constitutionAct: recEntTabCode.sectionFieldsConfiguration.find(x => x.code === activityBproFields.constitutionAct) })
        this.setState({ constitutionDate: recEntTabCode.sectionFieldsConfiguration.find(x => x.code === activityBproFields.constitutionDate) })
      }

      if (recEntTabCode && recEntTabCode.sectionFieldsConfiguration && recEntTabCode.sectionFieldsConfiguration.find(x => x.code === commercialSocietyTypeIdFieldCode.commercialSocietyTypeId)) {
        this.setState({ commercialSocietyTypeId: recEntTabCode.sectionFieldsConfiguration.find(x => x.code === commercialSocietyTypeIdFieldCode.commercialSocietyTypeId) })
      }
    }
    if (!this.props.showModal && this.props.showModal !== prevProps.showModal) {
      this.props.initialize({})
      this.setState({ entitySubTypeDisabled: true })
    }
    if (this.props.showModal && this.props.showModal !== prevProps.showModal) {
      if (this.props.entityId) {
        this.searchDocuments(this.props.entityId)
      }
      if (this.props.entityTypeId) {
        this.props.actions.fetchEntitySubTypeCombo(this.props.entityTypeId)
        this.setState({ entitySubTypeDisabled: false })
      }
      if (this.props.organizedUnitId) {
        this.setState({ selectedUo: this.props.organizedUnitId })
      }
      if (this.props.postalCodeCode) {
        this.setState({ combo: 5 })
      } else {
        this.setState({ combo: 0 })
      }
      this.setState({ private: false, bussiness: false })

      if (this.props.activityBproId) {
        let type = this.props.combos.activityBproCombo.find(x => x.id === this.props.activityBproId)?.type?.trim()?.toUpperCase()
        if (type === activityTypes.particular.trim().toUpperCase()) {
          this.setState({ private: true, bussiness: false })
        } else {
          this.setState({ private: false, bussiness: true })
        }
      }
    }

    return this.props
  }

  componentDidMount () {
    this.props.actions.fetchCommercialSocietyTypeCombo()
    this.props.actions.fetchEntityCountryTypeCombo()
    this.props.actions.fetchActivityBpro()
    this.setState({
      recurrentEntityTypeCombo: [
        { id: EntityType.RECURRING, value: this.props.t('ADMIN.RECURRENT_ENTITY_TYPE') },
        { id: EntityType.RECURRING_SERVICE, value: this.props.t('ADMIN.RECURRENT_SERVICE_ENTITY_TYPE') }]
    })
  }

  componentDidUpdate () {
    if (this.state.alert && this.props.combos[`entity${comboType[this.state.combo]}`]?.length === 0 && this.props.combos[`entity${comboType[this.state.combo - 1]}`]?.length > 0) {
      const alert = 'notData'
      this.props.actions.openModal(alert)
      this.setState({
        combo: this.state.combo - 1,
        alert: false
      })
      if (this.state.combo !== numberCombo.postalCodeCode) {
        this.props.actions[`fetchEntity${comboType[this.state.combo]}`]()
      }
    }
  }

  orderTable () {
    const columnName = this.state.order.columnName

    const documents = this.state.documents.sort(function (a, b) {
      if (columnName === 'validationDate') {
        const fechaInicial = a.validationDate ? a.validationDate.split(' ') : ['-']
        const separarA = fechaInicial.map((dia) => dia[0] === '-' ? ('01,01,2000,00,00') : (fechaInicial[0].split('/').join(',') + ',' + fechaInicial[1].split(':').join(',')))
        const fechaA = separarA.map((datos) => datos.split(','))
        const fechaFinal = b.validationDate ? b.validationDate.split(' ') : ['-']
        const separarB = fechaFinal.map((dia) => dia[0] === '-' ? ('01,01,2000,00,00') : (fechaFinal[0].split('/').join(',') + ',' + fechaFinal[1].split(':').join(',')))
        const fechaB = separarB.map((datos) => datos.split(','))
        const fecha1 = new Date(fechaA[0][2], fechaA[0][1], fechaA[0][0], fechaA[0][3], fechaA[0][4])
        const fecha2 = new Date(fechaB[0][2], fechaB[0][1], fechaB[0][0], fechaB[0][3], fechaB[0][4])
        return fecha2 - fecha1
      } else if (columnName === 'incorporationDate') {
        const fechaInicial = a.incorporationDate ? a.incorporationDate.split(' ') : ['-']
        const separarA = fechaInicial.map((dia) => dia[0] === '-' ? ('01,01,2000,00,00') : (fechaInicial[0].split('/').join(',') + ',' + fechaInicial[1].split(':').join(',')))
        const fechaA = separarA.map((datos) => datos.split(','))
        const fechaFinal = b.incorporationDate ? b.incorporationDate.split(' ') : ['-']
        const separarB = fechaFinal.map((dia) => dia[0] === '-' ? ('01,01,2000,00,00') : (fechaFinal[0].split('/').join(',') + ',' + fechaFinal[1].split(':').join(',')))
        const fechaB = separarB.map((datos) => datos.split(','))
        const fecha1 = new Date(fechaA[0][2], fechaA[0][1], fechaA[0][0], fechaA[0][3], fechaA[0][4])
        const fecha2 = new Date(fechaB[0][2], fechaB[0][1], fechaB[0][0], fechaB[0][3], fechaB[0][4])
        return fecha2 - fecha1
      } else if (columnName === 'checklistStatus') {
        return a.checklistStatus - b.checklistStatus
      } else if (a[columnName] && a[columnName].localeCompare) {
        return a[columnName].localeCompare(b[columnName])
      } else {
        return (isNaN(a[columnName]) ? 0 : a[columnName]) - (isNaN(b[columnName]) ? 0 : b[columnName])
      }
    })
    if (this.state.order.direction === 'asc') {
      documents.reverse()
    }
    this.setState({
      documents
    })
  }

  searchDocuments (entityId) {
    this.props.actions.fetchDocumentList(entityId)
  }

  handleOpenDocumentCreationModal (documentEntityType, documentTypeUse) {
    if (this.props.entityId) {
      this.props.actions.openDocumentCreationModal(null, documentEntityType, this.state.selectedUo, this.props.entityId, null, () => this.searchDocuments(this.props.entityId), true, null, null, true)
    } else {
      const alert = documentEntityType === documentEntityTypeConstants.ENTITY ? 'entityAlert' : 'vehicleAlert'
      this.props.actions.openModal(alert)
    }
  }

  changeDirection (columnName) {
    this.setState({
      order: {
        columnName: columnName,
        direction: this.state.order.direction === 'asc' ? 'desc' : 'asc'
      }
    }, this.orderTable)
  }

  changeOrderIcon (name) {
    let clase = 'ico-sort'
    if (this.state.order.direction === 'asc' && this.state.order.columnName === name) {
      clase += '-selected-asc'
    } else if (this.state.order.direction === 'desc' && this.state.order.columnName === name) {
      clase += '-selected-desc'
    }
    return clase
  }

  handleEntityTypeChange (entityTypeId) {
    if (entityTypeId) {
      this.setState({ entitySubTypeDisabled: false })
      this.props.actions.fetchEntitySubTypeCombo(entityTypeId)
    } else {
      this.setState({ entitySubTypeDisabled: true })
    }
  }

  handleEntityTypesChange (code, disable) {
    if (code) {
      if (disable !== 5) {
        this.props.actions[`fetchEntity${comboType[disable + 1]}`](this.props.combos[`entity${comboType[disable]}`].find(x => x.code === code)?.id)
        this.AddCombo()
      }
    } else {
      this.setState({ combo: (disable !== 0) ? disable - 1 : disable, alert: true })
    }
  }

  AddCombo () {
    this.setState({
      combo: this.state.combo + 1
    })
  }

  getActivitiesByType (type) {
    return this.props.combos.activityBproCombo.filter(x => x.type.toUpperCase().trim() === type.toUpperCase().trim())
  }

  handleEntityTypeActivity (value, type) {
    if (type === activityTypes.particular && value) {
      this.setState({ private: true, bussiness: false })
    } else if (type === activityTypes.empresa && value) {
      this.setState({ private: false, bussiness: true })
    } else {
      this.setState({ private: false, bussiness: false })
    }
  }

  longSchema () {
    const {
      t,
      combos: { UOAcceptClientsCombo, entitySubTypeCombo, CSTCombo },
      actions: {
        fetchEntityCountryTypeCombo,
        fetchEntityStateTypeCombo,
        fetchEntityCityTypeCombo,
        fetchEntityTownTypeCombo,
        fetchEntityColonyTypeCombo,
        fetchEntityCPTypeCombo,
        fetchClientDataAddress
      }
    } = this.props

    let activitiesPrivate = this.getActivitiesByType(activityTypes.particular)
    let activitiesbussiness = this.getActivitiesByType(activityTypes.empresa)
    return (
      <Row key={1} className="inputs-wrapper flexRow">
        <Col sm={4} className="custom-width-col">
          <h2>{t('ADMIN.RECURRENT_ENTITIES.CLIENT_NUMBER')}</h2>
          <Field
            name="recurrentEntity.clientNumber"
            placeholder={t('ADMIN.RECURRENT_ENTITIES.CLIENT_NUMBER')}
            component={TextInput}
          />
        </Col>
        <Col sm={4} className="custom-width-col">
          <h2>{t('ADMIN.RECURRENT_ENTITIES.NAME')}</h2>
          <Field
            name="recurrentEntity.name"
            placeholder={t('ADMIN.RECURRENT_ENTITIES.NAME')}
            component={TextInput}
          />
        </Col>
        <Col sm={4} className="custom-width-col">
          <h2>{t('ADMIN.RECURRENT_ENTITIES.REPRESENTATIVE_NAME')}</h2>
          <Field
            name="recurrentEntity.representativeName"
            placeholder={t('ADMIN.RECURRENT_ENTITIES.REPRESENTATIVE_NAME')}
            component={TextInput}
          />
        </Col>
        <Col sm={4} className="custom-width-col">
          <h2>{t('ADMIN.RECURRENT_ENTITIES.REPRESENTATIVE_DNI')}</h2>
          <Field
            name="recurrentEntity.representativeDni"
            placeholder={t('ADMIN.RECURRENT_ENTITIES.REPRESENTATIVE_DNI')}
            component={TextInput}
          />
        </Col>
        <Col sm={4} className="custom-width-col">
          <h2>{t('ADMIN.RECURRENT_ENTITIES.UO')}</h2>
          <Field
            name="recurrentEntity.organizedUnitId"
            placeholder={t('ADMIN.RECURRENT_ENTITIES.UO')}
            component={InputSelect}
            multi={false}
            valueKey="id"
            labelKey="value"
            childrenKey="children"
            options={UOAcceptClientsCombo}
            colSm={0}
            onChange={(el, value) => this.setState({ selectedUo: value })}
          />
        </Col>
        <Col sm={4} className="custom-width-col">
          <h2>{t('ADMIN.RECURRENT_ENTITIES.ENTITY_TYPE')}</h2>
          <Field
            name="recurrentEntity.entityTypeId"
            placeholder={t('ADMIN.RECURRENT_ENTITIES.ENTITY_TYPE')}
            component={InputSelect}
            multi={false}
            valueKey="id"
            labelKey="value"
            childrenKey="children"
            isClearable={false}
            options={[{ id: 3, value: 'Entidad Recurrente' }]}
            onInputChange={(value) => this.handleEntityTypeChange(value)}
            colSm={0}
          />
        </Col>
        <Col sm={4} className="custom-width-col">
          <h2>{t('ADMIN.RECURRENT_ENTITIES.ENTITY_SUB_TYPE')}</h2>
          <Field
            name="recurrentEntity.entitySubTypeId"
            placeholder={t('ADMIN.RECURRENT_ENTITIES.ENTITY_SUB_TYPE')}
            component={InputSelect}
            multi={false}
            disabled={this.state.entitySubTypeDisabled}
            valueKey="id"
            labelKey="value"
            childrenKey="children"
            options={entitySubTypeCombo}
            // options={[{id: 1, value: 'Renting SEAT'}, {id: 2, value: 'Renting VW'}, {id: 3, value: 'Renting Audi'}, {id: 3, value: 'Renting Skoda'}]}
            colSm={0}
          />
        </Col>
        {this.validatePermissions(rfcFieldCode.rfc) &&
                  (<Col sm={4} className="custom-width-col">
                    <h2>{t('DOSSIER_COMPONENTS.ENTITY.RFC')}</h2>
                    <Field
                      name="recurrentEntity.rfc"
                      colSm={0}
                      placeholder={t('DOSSIER_COMPONENTS.ENTITY.RFC')}
                      component={InputText}
                    />
                  </Col>)
        }
        <Col sm={4} className="custom-width-col">
          <h2>{t('ADMIN.RECURRENT_ENTITIES.DNICIF')}</h2>
          <Field
            name="recurrentEntity.dniCif"
            placeholder={t('ADMIN.RECURRENT_ENTITIES.DNICIF')}
            component={TextInput}
          />
        </Col>
        <div className="clearfix" />
        <Col sm={4} className="custom-width-col">
          <h2>{t('ADMIN.RECURRENT_ENTITIES.PHONE')}</h2>
          <Field
            name="recurrentEntity.phone"
            placeholder={t('ADMIN.RECURRENT_ENTITIES.PHONE')}
            component={TextInput}
          />
        </Col>
        <Col sm={4} className="custom-width-col">
          <h2>{t('ADMIN.RECURRENT_ENTITIES.EMAIL')}</h2>
          <Field
            name="recurrentEntity.email"
            placeholder={t('ADMIN.RECURRENT_ENTITIES.EMAIL')}
            component={TextInput}
          />
        </Col>
        <Col sm={4} className="custom-width-col"></Col>
        <div className="clearfix" />
        <NormalizedAddress
          t={t}
          fieldsConfiguration={{
            countryCode: this.state.countryCode,
            stateCode: this.state.state,
            cityCode: this.state.city,
            townCode: this.state.townCode,
            colonyCode: this.state.colonyCode,
            cpCode: this.state.cp
          }}
          readOnlyFields={false}
          allInformationDisabled={false}
          entityComponent= {this.props}
          entityTypeName= "recurrentEntity"
          actions={{
            fetchEntityCountryTypeCombo,
            fetchEntityStateTypeCombo,
            fetchEntityCityTypeCombo,
            fetchEntityTownTypeCombo,
            fetchEntityColonyTypeCombo,
            fetchEntityCPTypeCombo,
            fetchClientDataAddress
          }}
        />,
        <div className="clearfix" />
        <Col sm={8} className="custom-width-col">
          <h2>{t('ADMIN.RECURRENT_ENTITIES.ADDRESS')}</h2>
          <Field
            name="recurrentEntity.address"
            placeholder={t('ADMIN.RECURRENT_ENTITIES.ADDRESS')}
            component={TextInput}
          />
        </Col>
        { this.validatePermissions(rfcFieldCode.externalNumber) && (
          <Col sm={4} className="custom-width-col">
            <h2>{t('ADMIN.RECURRENT_ENTITIES.EXTERNAL_NUMBER')}</h2>
            <Field
              name="recurrentEntity.externalNumber"
              placeholder={t('ADMIN.RECURRENT_ENTITIES.EXTERNAL_NUMBER')}
              component={TextInput}
              maxLength="20"
            />
          </Col>
        )}
        { this.validatePermissions(rfcFieldCode.startStreet) && (
          <Col sm={8} className="custom-width-col">
            <h2>{t('ADMIN.RECURRENT_ENTITIES.START_STREET')}</h2>
            <Field
              name="recurrentEntity.startStreet"
              controlLabel={t('ADMIN.RECURRENT_ENTITIES.START_STREET')}
              placeholder={t('ADMIN.RECURRENT_ENTITIES.START_STREET')}
              component={TextInput}
              maxLength="200"
            />
          </Col>
        )}
        { this.validatePermissions(rfcFieldCode.internalNumber) && (
          <Col sm={4} className="custom-width-col">
            <h2>{t('ADMIN.RECURRENT_ENTITIES.INTERNAL_NUMBER')}</h2>
            <Field
              name="recurrentEntity.internalNumber"
              placeholder={t('ADMIN.RECURRENT_ENTITIES.INTERNAL_NUMBER')}
              component={TextInput}
              maxLength="20"
            />
          </Col>
        )}
        { this.validatePermissions(rfcFieldCode.enndStreet) && (
          <Col sm={8} className="custom-width-col">
            <h2>{t('ADMIN.RECURRENT_ENTITIES.ENND_STREET')}</h2>
            <Field
              name="recurrentEntity.enndStreet"
              placeholder={t('ADMIN.RECURRENT_ENTITIES.ENND_STREET')}
              component={TextInput}
              maxLength="200"
            />
          </Col>
        )}
        {this.state.commercialSocietyTypeId && this.state.commercialSocietyTypeId?.permission && this.state.commercialSocietyTypeId?.permission !== permissions.hidden &&
        (<Col sm={4} className="custom-width-col">
          <h2>{t('ADMIN.RECURRENT_ENTITIES.COMMERCIAL_SOCIETY')}</h2>
          <Field
            name="recurrentEntity.commercialSocietyTypeId"
            placeholder={t('ADMIN.RECURRENT_ENTITIES.COMMERCIAL_SOCIETY')}
            component={InputSelect}
            multi={false}
            valueKey="id"
            labelKey="value"
            childrenKey="children"
            options={CSTCombo}
            colSm={0}
          />
        </Col>)
        }
        {this.state.activityBproId && this.state.activityBproId?.permission && this.state.activityBproId?.permission !== permissions.hidden && (
          <>
            <Col sm={12} className="custom-width-col">
              <h2>{t('DOSSIER_COMPONENTS.ENTITY.ACTIVITY_BPRO_PRIVATE')}</h2>
              <Field
                colSm={12}
                id="activityBproIdPrivate"
                name="recurrentEntity.activityBproId"
                key="recurrentEntity.activityBproId"
                placeholder={t('DOSSIER_COMPONENTS.ENTITY.ACTIVITY_BPRO_PRIVATE')}
                component={InputSelect}
                options={activitiesPrivate}
                valueKey="id"
                labelKey="value"
                onInputChange={(value) => this.handleEntityTypeActivity(value, activityTypes.particular)}
                disabled={this.state.bussiness}
              /></Col>
            <Col sm={12} className="custom-width-col">
              <h2>{t('DOSSIER_COMPONENTS.ENTITY.ACTIVITY_BPRO_BUSSINES')}</h2>
              <Field
                colSm={12}
                id="activityBproIdBussiness"
                name="recurrentEntity.activityBproId"
                key="recurrentEntity.activityBproId"
                placeholder={t('DOSSIER_COMPONENTS.ENTITY.ACTIVITY_BPRO_BUSSINES')}
                component={InputSelect}
                options={activitiesbussiness}
                valueKey="id"
                labelKey="value"
                onInputChange={(value) => this.handleEntityTypeActivity(value, activityTypes.empresa)}
                disabled={this.state.private}
              /></Col></>)}

        {this.state.private && this.state.birthdayDate && this.state.birthdayDate?.permission && this.state.birthdayDate?.permission !== permissions.hidden && (
          <Col sm={12} className="custom-width-col">
            <h2>{t('DOSSIER_COMPONENTS.ENTITY.BIRTHDAY_DATE')}</h2>
            <Field
              colSm={4}
              id="birthdayDate"
              name="recurrentEntity.birthdayDate"
              key="recurrentEntity.birthdayDate"
              placeholder={t('DOSSIER_COMPONENTS.ENTITY.BIRTHDAY_DATE')}
              component={InputDatePicker}
              validate={[this.dateLessTodayValidation]}
              disabled={!this.state.private}
            /></Col>)}
        {this.state.bussiness && this.state.constitutionAct && this.state.constitutionAct?.permission && this.state.constitutionAct?.permission !== permissions.hidden && (
          <Col sm={12} className="custom-width-col">
            <h2>{t('DOSSIER_COMPONENTS.ENTITY.CONSTITUTION_ACT')}</h2>
            <Field
              id="constitutionAct"
              name="recurrentEntity.constitutionAct"
              key="recurrentEntity.constitutionAct"
              colSm={8}
              placeholder={t('DOSSIER_COMPONENTS.ENTITY.CONSTITUTION_ACT')}
              maxLength={50}
              component={InputText}
              disabled={!this.state.bussiness}
            /></Col>)}
        {this.state.bussiness && this.state.constitutionDate && this.state.constitutionDate?.permission && this.state.constitutionDate?.permission !== permissions.hidden && (
          <Col sm={12} className="custom-width-col">
            <h2>{t('DOSSIER_COMPONENTS.ENTITY.CONSTITUTION_DATE')}</h2>
            <Field
              colSm={4}
              id="constitutionDate"
              name="recurrentEntity.constitutionDate"
              key="recurrentEntity.constitutionDate"
              placeholder={t('DOSSIER_COMPONENTS.ENTITY.CONSTITUTION_DATE')}
              component={InputDatePicker}
              validate={[this.dateLessTodayValidation]}
              disabled={!this.state.bussiness}
            /></Col>)}

        <div className="clearfix" />
      </Row>
    )
  }

  shortSchema () {
    const {
      t,
      combos: { UOAcceptClientsCombo, entitySubTypeCombo, CSTCombo, UOTreeCombo }
    } = this.props
    return (
      <Row key={1} className="inputs-wrapper flexRow">
        <Col sm={4} className="custom-width-col">
          <h2>{t('ADMIN.RECURRENT_ENTITIES.CLIENT_NUMBER')}</h2>
          <Field
            name="recurrentEntity.clientNumber"
            placeholder={t('ADMIN.RECURRENT_ENTITIES.CLIENT_NUMBER')}
            component={TextInput}
          />
        </Col>
        <Col sm={4} className="custom-width-col">
          <h2>{t('ADMIN.RECURRENT_ENTITIES.NAME')}</h2>
          <Field
            name="recurrentEntity.name"
            placeholder={t('ADMIN.RECURRENT_ENTITIES.NAME')}
            component={TextInput}
          />
        </Col>
        <Col sm={4} className="custom-width-col">
          <h2>{t('ADMIN.RECURRENT_ENTITIES.DNICIF')}</h2>
          <Field
            name="recurrentEntity.dniCif"
            placeholder={t('ADMIN.RECURRENT_ENTITIES.DNICIF')}
            component={TextInput}
          />
        </Col>
        <div className="clearfix" />
        <Col sm={4} className="custom-width-col">
          <h2>{t('ADMIN.RECURRENT_ENTITIES.PHONE')}</h2>
          <Field
            name="recurrentEntity.phone"
            placeholder={t('ADMIN.RECURRENT_ENTITIES.PHONE')}
            component={TextInput}
          />
        </Col>
        <Col sm={4} className="custom-width-col">
          <h2>{t('ADMIN.RECURRENT_ENTITIES.EMAIL')}</h2>
          <Field
            name="recurrentEntity.email"
            placeholder={t('ADMIN.RECURRENT_ENTITIES.EMAIL')}
            component={TextInput}
          />
        </Col>
        <Col sm={4} className="custom-width-col">
          <h2>{t('ADMIN.RECURRENT_ENTITIES.ADDRESS')}</h2>
          <Field
            name="recurrentEntity.address"
            placeholder={t('ADMIN.RECURRENT_ENTITIES.ADDRESS')}
            component={TextInput}
          />
        </Col>
        <div className="clearfix" />
        <Col sm={4} className="custom-width-col">
          <h2>{t('ADMIN.RECURRENT_ENTITIES.CITY')}</h2>
          <Field
            name="recurrentEntity.city"
            placeholder={t('ADMIN.RECURRENT_ENTITIES.CITY')}
            component={TextInput}
          />
        </Col>
        <Col sm={4} className="custom-width-col">
          <h2>{t('ADMIN.RECURRENT_ENTITIES.CP')}</h2>
          <Field
            name="recurrentEntity.cp"
            placeholder={t('ADMIN.RECURRENT_ENTITIES.CP')}
            component={TextInput}
          />
        </Col>
        <Col sm={4} className="custom-width-col">
          <h2>{t('ADMIN.RECURRENT_ENTITIES.STATE')}</h2>
          <Field
            name="recurrentEntity.state"
            placeholder={t('ADMIN.RECURRENT_ENTITIES.STATE')}
            component={TextInput}
          />
        </Col>
        <div className="clearfix" />
        <Col sm={4} className="custom-width-col">
          <h2>{t('ADMIN.RECURRENT_ENTITIES.REPRESENTATIVE_NAME')}</h2>
          <Field
            name="recurrentEntity.representativeName"
            placeholder={t('ADMIN.RECURRENT_ENTITIES.REPRESENTATIVE_NAME')}
            component={TextInput}
          />
        </Col>
        <Col sm={4} className="custom-width-col">
          <h2>{t('ADMIN.RECURRENT_ENTITIES.REPRESENTATIVE_DNI')}</h2>
          <Field
            name="recurrentEntity.representativeDni"
            placeholder={t('ADMIN.RECURRENT_ENTITIES.REPRESENTATIVE_DNI')}
            component={TextInput}
          />
        </Col>
        <Col sm={4} className="custom-width-col">
          <h2>{t('ADMIN.RECURRENT_ENTITIES.UO')}</h2>
          <Field
            name="recurrentEntity.organizedUnitId"
            placeholder={t('ADMIN.RECURRENT_ENTITIES.UO')}
            component={InputSelect}
            multi={false}
            valueKey="id"
            labelKey="value"
            childrenKey="children"
            options={UOAcceptClientsCombo}
            colSm={0}
            onChange={(el, value) => this.setState({ selectedUo: value })}
          />
        </Col>
        <Col sm={4} className="custom-width-col">
          <h2>{t('ADMIN.RECURRENT_ENTITIES.ENTITY_TYPE')}</h2>
          <Field
            name="recurrentEntity.entityTypeId"
            placeholder={t('ADMIN.RECURRENT_ENTITIES.ENTITY_TYPE')}
            component={InputSelect}
            multi={false}
            valueKey="id"
            labelKey="value"
            childrenKey="children"
            isClearable={false}
            options={[{ id: 3, value: 'Entidad Recurrente' }]}
            onInputChange={(value) => this.handleEntityTypeChange(value)}
            colSm={0}
          />
        </Col>
        <Col sm={4} className="custom-width-col">
          <h2>{t('ADMIN.RECURRENT_ENTITIES.ENTITY_SUB_TYPE')}</h2>
          <Field
            name="recurrentEntity.entitySubTypeId"
            placeholder={t('ADMIN.RECURRENT_ENTITIES.ENTITY_SUB_TYPE')}
            component={InputSelect}
            multi={false}
            disabled={this.state.entitySubTypeDisabled}
            valueKey="id"
            labelKey="value"
            childrenKey="children"
            options={entitySubTypeCombo}
            // options={[{id: 1, value: 'Renting SEAT'}, {id: 2, value: 'Renting VW'}, {id: 3, value: 'Renting Audi'}, {id: 3, value: 'Renting Skoda'}]}
            colSm={0}
          />
        </Col>
        {this.props.entityTypeId && this.props.entityTypeId === entityTypesId.recurrentService &&
        <Col sm={4} className="custom-width-col">
          <h2>{t('ADMIN.RECURRENT_ENTITIES.UO_RELATED_CAMPA')}</h2>
          <Field
            name="recurrentEntity.recurrentServiceEntityOrganizedUnit"
            placeholder={t('ADMIN.RECURRENT_ENTITIES.UO_RELATED_CAMPA')}
            component={InputTreeSelect}
            multi={true}
            valueKey="value"
            labelKey="label"
            childrenKey="children"
            options={UOTreeCombo}
            colSm={0}
          />
        </Col>
        }
        {this.state.rfc && this.state.rfc.permission && this.state.rfc.permission !== permissions.hidden &&
      (<Col sm={4} className="custom-width-col">
        <h2>{t('DOSSIER_COMPONENTS.ENTITY.RFC')}</h2>
        <Field
          name="recurrentEntity.rfc"
          colSm={0}
          placeholder={t('DOSSIER_COMPONENTS.ENTITY.RFC')}
          component={InputText}
        />
      </Col>)
        }
        {this.state.commercialSocietyTypeId && this.state.commercialSocietyTypeId?.permission && this.state.commercialSocietyTypeId?.permission !== permissions.hidden &&
        (<Col sm={4} className="custom-width-col">
          <h2>{t('ADMIN.RECURRENT_ENTITIES.COMMERCIAL_SOCIETY')}</h2>
          <Field
            name="recurrentEntity.commercialSocietyTypeId"
            placeholder={t('ADMIN.RECURRENT_ENTITIES.COMMERCIAL_SOCIETY')}
            component={InputSelect}
            multi={false}
            valueKey="id"
            labelKey="value"
            childrenKey="children"
            options={CSTCombo}
            colSm={0}
          />
        </Col>)
        }

      </Row>
    )
  }

  validateSchema () {
    return (
      this.validatePermissions(rfcFieldCode.enndStreet) ||
      this.validatePermissions(rfcFieldCode.startStreet) ||
      this.validatePermissions(rfcFieldCode.externalNumber) ||
      this.validatePermissions(rfcFieldCode.internalNumber) ||
      this.validatePermissions(rfcFieldCode.countryCode) ||
      this.validatePermissions(rfcFieldCode.state) ||
      this.validatePermissions(rfcFieldCode.city) ||
      this.validatePermissions(rfcFieldCode.townCode) ||
      this.validatePermissions(rfcFieldCode.colonyCode) ||
      this.validatePermissions(rfcFieldCode.cp)
    )
  }

  render () {
    const {
      t, handleSubmit,
      showModal, entityId, documents,
      actions: { openDocumentEditionModal, cancelDocument, addDependant, printCheckList, openModal }
    } = this.props
    const tKey = 'RECURRENT_ENTITY_MANAGEMENT.'
    return (
      <Modal className="role-modal languages-modal" show={showModal} onHide={this.props.actions.closeEditRecurrentEntityModal}>
        <form autoComplete="off" onSubmit={handleSubmit(this.submitRecurrentEntity.bind(this))}>
          <Modal.Header closeButton onHide={this.props.actions.closeEditRecurrentEntityModal}>
            <Modal.Title>{t('RECURRENT_ENTITY_MANAGEMENT.MODAL.TITLE')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              { !this.validateSchema() ? (
                this.shortSchema()
              ) : (
                this.longSchema()
              ) }
            </FormGroup>
            <Row className="selectors">
              {entityId &&
              <div>
                <Col sm={12} className="btn-wrapper">
                  <Button
                    id="open-document-creation-modal"
                    className="btn-standard"
                    disabled={entityId == null}
                    onClick={() => this.handleOpenDocumentCreationModal(documentEntityTypeConstants.ENTITY, 2)}>
                  + {t('RECURRENT_ENTITY_MANAGEMENT.MODAL.ADD')}
                  </Button>
                </Col>
                <Col sm={12} className="table-wrapper">
                  <h2>{t('RECURRENT_ENTITY_MANAGEMENT.MODAL.ADD_DOCUMENTS')}</h2>
                  <Table hover responsive className="simple-table gestion-documental-table" id="documentary-management-table">
                    <thead>
                      <tr>
                        <th onClick={() => [this.changeDirection('documentStatus')]}><span>{t(`${tKey + 'DOCUMENTARY_MANAGEMENT.TABLE.STATUS'}`)}<i className={(() => [this.changeOrderIcon('documentStatus')])()}/></span></th>
                        <th onClick={() => [this.changeDirection('documentName')]}><span>{t(`${tKey + 'DOCUMENTARY_MANAGEMENT.TABLE.TYPES'}`)}<i className={(() => [this.changeOrderIcon('documentName')])()}/></span></th>
                        <th onClick={() => [this.changeDirection('validationDate')]}><span>{t(`${tKey + 'DOCUMENTARY_MANAGEMENT.TABLE.VALIDATION_DATE'}`)}<i className={(() => [this.changeOrderIcon('validationDate')])()}/></span></th>
                        <th onClick={() => [this.changeDirection('incorporationDate')]}><span>{t(`${tKey + 'DOCUMENTARY_MANAGEMENT.TABLE.ASSIGNMENT_DATE'}`)}<i className={(() => [this.changeOrderIcon('incorporationDate')])()}/></span></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        documents && documents.map((documentFile, idx) => <DocumentListItemRecurrentEntity
                          t={t}
                          key={idx}
                          documentFile={documentFile}
                          readOnly={false}
                          documentEntityType={documentFile.documentEntityType}
                          objectId={entityId}
                          actions={{
                            openDocumentEditionModalWithChecklistId: (documentId, documentTypeUse, checklistId) => openDocumentEditionModal(documentId, documentTypeUse, documentFile.documentEntityType, null, entityId, null, this.state.organizedUnitId, checklistId, () => this.props.actions.fetchDocumentList(entityId), true, null, null, null, null, null, true, true),
                            cancelDocument,
                            printCheckList,
                            addDependant,
                            openModal
                          }}
                        />)
                      }
                    </tbody>
                  </Table>
                </Col>
              </div>
              }
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Col sm={12} className="btn-wrapper">
              <Button
                id="recurrent-entity-modal-submit-button"
                className="btn-standard"
                type="submit"
                disabled={this.props.pristine}
              >
                {t('RECURRENT_ENTITY_MANAGEMENT.MODAL.SUBMIT_BUTTON')}
              </Button>
            </Col>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

const validate = (values, props) => {
  let rfcValidation = null
  if (props.sections && props.sections.find(s => s.code === commonCodes.admin)) {
    let admin = props.sections.find(s => s.code === commonCodes.admin)
    let recEntTabCode = admin.sectionTabsConfiguration ? admin.sectionTabsConfiguration.find(x => x.code === recurrentEntityTabCode.recEntTabCode && x.permission > permissions.hidden) : null
    if (recEntTabCode && recEntTabCode.sectionFieldsConfiguration && recEntTabCode.sectionFieldsConfiguration.find(x => x.code === rfcFieldCode.rfc)) {
      rfcValidation = recEntTabCode.sectionFieldsConfiguration.find(x => x.code === rfcFieldCode.rfc)
    }
  }
  const error = { recurrentEntity: {} }
  if (values.recurrentEntity) {
    if (values.recurrentEntity.rfc && !validateBusinessRfcFormat(values.recurrentEntity.rfc)) {
      error.recurrentEntity.rfc = props.t('DOSSIER_COMPONENTS.VALIDATIONS.ENTITY_RFC_ERROR_FORMAT')
    } else if (values.recurrentEntity.rfc && (!validateBusinessRfc(values.recurrentEntity.rfc) || !validateBusinessRfcDate(values.recurrentEntity.rfc))) {
      error.recurrentEntity.rfc = props.t('DOSSIER_COMPONENTS.VALIDATIONS.ENTITY_RFC_ERROR_INVALID')
    }
    if (!values.recurrentEntity.name) {
      error.recurrentEntity.name = props.t('ADMIN.RECURRENT_ENTITIES.VALIDATION.NAME_REQUIRED')
    }
    if (!values.recurrentEntity.dniCif) {
      error.recurrentEntity.dniCif = props.t('ADMIN.RECURRENT_ENTITIES.VALIDATION.DNICIF_REQUIRED')
    } else if (rfcValidation && rfcValidation.permission === permissions.editable) {
      if (!validateBusinessRfcFormat(values.recurrentEntity.dniCif)) {
        error.recurrentEntity.dniCif = props.t('DOSSIER_COMPONENTS.VALIDATIONS.ENTITY_RFC_ERROR_FORMAT')
      } else if (!validateBusinessRfc(values.recurrentEntity.dniCif) || !validateBusinessRfcDate(values.recurrentEntity.dniCif)) {
        error.recurrentEntity.dniCif = props.t('DOSSIER_COMPONENTS.VALIDATIONS.ENTITY_RFC_ERROR_INVALID')
      }
    }
    if (!isEmail(values.recurrentEntity.email)) {
      error.recurrentEntity.email = props.t('ADMIN.RECURRENT_ENTITIES.VALIDATION.VALID_EMAIL')
    }
    if (!values.recurrentEntity.organizedUnitId) {
      error.recurrentEntity.organizedUnitId = props.t('ADMIN.RECURRENT_ENTITIES.VALIDATION.UO_REQUIRED')
    }
    if (!values.recurrentEntity.entityTypeId) {
      error.recurrentEntity.entityTypeId = props.t('ADMIN.RECURRENT_ENTITIES.VALIDATION.ENTITY_TYPE_REQUIRED')
    }
    if (!values.recurrentEntity.entitySubTypeId) {
      error.recurrentEntity.entitySubTypeId = props.t('ADMIN.RECURRENT_ENTITIES.VALIDATION.ENTITY_SUBTYPE_REQUIRED')
    }
    if (values.recurrentEntity.dniCif) {
      if (validateBusinessRfc(values.recurrentEntity.dniCif)) {
        if (!values.recurrentEntity.commercialSocietyTypeId && (this == null ? true : (this.state?.commercialSocietyTypeId && this.state?.commercialSocietyTypeId?.permission && this.state?.commercialSocietyTypeId?.permission !== permissions.hidden))) {
          error.recurrentEntity.commercialSocietyTypeId = props.t('ADMIN.RECURRENT_ENTITIES.VALIDATION.COMMERCIAL_SOCIETY_REQUIRED')
        }
      }
    }
    if (!values.recurrentEntity.countryCode) {
      error.recurrentEntity.countryCode = props.t('ADMIN.RECURRENT_ENTITIES.VALIDATION.COUNTRY_REQUIRED')
    }
    if (!values.recurrentEntity.stateCode) {
      error.recurrentEntity.stateCode = props.t('ADMIN.RECURRENT_ENTITIES.VALIDATION.STATE_REQUIRED')
    }
    if (!values.recurrentEntity.cityCode) {
      error.recurrentEntity.cityCode = props.t('ADMIN.RECURRENT_ENTITIES.VALIDATION.CITY_REQUIRED')
    }
    if (!values.recurrentEntity.townCode) {
      error.recurrentEntity.townCode = props.t('ADMIN.RECURRENT_ENTITIES.VALIDATION.TOWN_REQUIRED')
    }
    if (!values.recurrentEntity.colonyCode) {
      error.recurrentEntity.colonyCode = props.t('ADMIN.RECURRENT_ENTITIES.VALIDATION.COLONY_REQUIRED')
    }
    if (!values.recurrentEntity.postalCodeCode) {
      error.recurrentEntity.postalCodeCode = props.t('ADMIN.RECURRENT_ENTITIES.VALIDATION.CP_REQUIRED')
    }
    if (settings.clientFieldValidation === clientInformation.BUSS) {
      if (!values.recurrentEntity.activityBproId) {
        error.recurrentEntity.activityBproId = props.t('DOSSIER_COMPONENTS.VALIDATIONS.MANDATORY_FIELD')
      } else {
        let type = props.combos.activityBproCombo.find(x => x.id === values.recurrentEntity.activityBproId)?.type?.trim()?.toUpperCase()
        if (type === activityTypes.particular.trim().toUpperCase()) {
          if (!values.recurrentEntity.birthdayDate) {
            error.recurrentEntity.birthdayDate = props.t('DOSSIER_COMPONENTS.VALIDATIONS.MANDATORY_FIELD')
          }
        } else {
          if (!values.recurrentEntity.constitutionAct) {
            error.recurrentEntity.constitutionAct = props.t('DOSSIER_COMPONENTS.VALIDATIONS.MANDATORY_FIELD')
          }
          if (!values.recurrentEntity.constitutionDate) {
            error.recurrentEntity.constitutionDate = props.t('DOSSIER_COMPONENTS.VALIDATIONS.MANDATORY_FIELD')
          }
        }
      }
    }
  }
  return Object.keys(error.recurrentEntity).length > 0 ? error : {}
}

const asyncValidate = (values, dispatch, props, field, returnError) => {
  let promises = []
  if (values) {
    promises = promises.concat(asyncValidateRecurrentEntity(values.recurrentEntity, dispatch, props, values.recurrentEntity.organizedUnitId))
  }

  return Promise.allSettled(promises).then(result => {
    let error = {}
    for (let index = 0; index < result.length; index++) {
      const element = result[index]
      if (element.status === promisesStatus.FULFILLED) {
        switch (index) {
          case validationEntityPromisesOrder.ENTITY_COMPONENT_DNI_CIF:
            error = { ...error, recurrentEntity: element.value }
            break
          default:
            break
        }
      }
    }
    if (Object.keys(error).length > 0 && !returnError) throw Object.assign(new Error(), error)
    if (returnError) return error
  })
}

export default reduxForm({
  form: 'editRecurrentEntity',
  validate,
  asyncValidate,
  asyncBlurFields: [...getFieldsRecurrentValidated('recurrentEntity')]
})(RecurrentEntityModal)
