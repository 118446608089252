import apiFetch from '../apiFetch'

export default function (token, signatureConfig) {
  return apiFetch({
    endPoint: `SignDocumentConfiguration/${signatureConfig.signDocumentConfigurationId}`,
    method: 'PUT',
    body: signatureConfig,
    token: token
  })
}
