import { call, put, select, takeLatest } from 'redux-saga/effects'
import { getAuth } from '../../selectors/access/auth'
import getDossierCancelReasonCombo from '../../services/dossier/get/getDossierCancelReasonCombo'
import commonActions from '../../actions/common'
import messageModalActions from '../../actions/modals/messageModal'
import { fetchCancelComboSuccess } from '../../actions/combos/combos'
import combosActionTypes from '../../constants/actions/combos/combos'
import { handleError } from '../errors/errorManager'

export function * fetchCancelCombo () {
  try {
    yield put(commonActions.showLoader())
    const auth = yield select(getAuth)
    const cancelCombo = yield call(getDossierCancelReasonCombo, auth.token)
    yield put(fetchCancelComboSuccess(cancelCombo))
  } catch (error) {
    const customHandler = function * () {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.FETCH_COMBO_ERROR'))
    }
    yield call(handleError, { error, customHandler })
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchFetchCancelCombo () {
  yield takeLatest(combosActionTypes.FETCH_CANCEL_COMBO, fetchCancelCombo)
}
