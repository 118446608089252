import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { openStyleTaskModal, fetchStyleTaskList, deleteStyleTask } from '../../../actions/masters/masters'
import StyleTaskMaster from '../../../components/masters/styleTask/StyleTaskMaster'

export function mapStateToProps (state) {
  return {
    ...state.styleTask,
    combos: {
      styleTaskCombo: state.combos.styleTaskCombo
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      deleteStyleTask,
      openStyleTaskModal,
      fetchStyleTaskList
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(StyleTaskMaster))
