import React, { PureComponent } from 'react'
import { Tooltip, OverlayTrigger, Glyphicon } from 'react-bootstrap'
import auditTagTranslation from '../../../../../constants/auditTagTranslation'
import { auditDetailsType } from '../../../../../constants/backendIds'
import { dossierSubTypeId as dossierSubTypeIdConstants } from '../../../../../constants/dossier/common/dossierSubType'

class AuditInfo extends PureComponent {
  UNSAFE_componentWillMount () {
    if (!this.props.isFleetAudit) {
      this.props.actions.refreshAuditInfo(this.props.dossier.dossierId, this.props.historical)
    } else {
      this.props.actions.refreshAuditFleetInfo(this.props.fleetId)
    }
  }

  auditDetails (idx) {
    new Promise((resolve) => {
      if (!this.props.isFleetAudit) {
        if (this.getAuditDetailsTypeNumber(idx) === auditDetailsType.GROUPED_DOCUMENTS) {
          this.props.actions.fetchDocumentsGroupedAuditDetails(this.props.dossier.dossierId, this.props.historical, parseInt(idx.substring(0, 3)), resolve)
        } else {
          this.props.actions.fetchAuditDetails(this.props.dossier.dossierId, this.props.historical, parseInt(idx.substring(0, 3)), this.props.dossier.organizedUnitId, this.props.dossier.dossierSubTypeId, resolve)
        }
      }
    }).then((result) => {
      if (this.getAuditDetailsTypeNumber(idx) === auditDetailsType.GROUPED_DOCUMENTS) {
        this.props.actions.fetchDocumentsGroupedAuditDetailsModal(result)
      } else {
        this.props.actions.fetchAuditDetailsModal(this.ParseInfo(result, this.props.dossier.dossierSubTypeId))
      }
    })
  }

  ParseInfo (info, dossierSubTypeId) {
    var myObj = JSON.parse(info)
    var result = ''
    const isPurchase = dossierSubTypeId === dossierSubTypeIdConstants.compra_vn || dossierSubTypeId === dossierSubTypeIdConstants.compra_vo
    for (let seccion in myObj) {
      if (myObj[seccion]) {
        if (Object.keys(myObj[seccion]).length > 0) {
          if (seccion.toUpperCase() === 'LANDINGACCESSDETAILS') {
            result += this.CreateLandingSectionDetails(myObj, seccion)
          } else {
            result += this.CreateDefaultSectionDetails(myObj, seccion, isPurchase)
          }
        }
      }
    }
    if (result === '') {
      result = this.props.t('DOSSIER_COMPONENTS.AUDIT.NO_DETAILS')
    }
    return result
  }

  CreateDefaultSectionDetails (myObj, seccion, isPurchase) {
    var result = ''
    if (isPurchase && seccion.toUpperCase() === 'ENTITY') {
      result += '\n\n' + this.props.t('DOSSIER_COMPONENTS.AUDIT.SECTION') + ': ' + this.props.t(auditTagTranslation.VENDOR) + '\n'
    } else {
      result += '\n\n' + this.props.t('DOSSIER_COMPONENTS.AUDIT.SECTION') + ': ' + this.props.t(auditTagTranslation[seccion.toUpperCase()]) + '\n'
    }
    for (let campo in myObj[seccion]) {
      if (Array.isArray(myObj[seccion])) {
        for (let index = 0; index < myObj[seccion].length; index++) {
          const auditObject = myObj[seccion][index]
          for (let audit in auditObject) {
            result += '\n' + auditObject[audit].FieldName + ' : '
            if (auditObject[audit].OldValue !== undefined) {
              const oldValueTranslate = auditObject[audit].OldValue === 'True' || auditObject[audit].OldValue === 'False' || auditObject[audit].OldValue === 'EMPTY_AUDIT_VALUE'
                ? this.props.t('DOSSIER_COMPONENTS.AUDIT.' + auditObject[audit].OldValue.toUpperCase()) : auditObject[audit].OldValue
              result += this.props.t('DOSSIER_COMPONENTS.AUDIT.OLD_VALUE') + ' = ' + oldValueTranslate + '; '
            }
            const newValueTranslate = auditObject[audit].NewValue === 'True' || auditObject[audit].NewValue === 'False' || auditObject[audit].NewValue === 'EMPTY_AUDIT_VALUE'
              ? this.props.t('DOSSIER_COMPONENTS.AUDIT.' + auditObject[audit].NewValue.toUpperCase()) : auditObject[audit].NewValue
            result += this.props.t('DOSSIER_COMPONENTS.AUDIT.NEW_VALUE') + ' = ' + newValueTranslate
          }
        }
      } else {
        result += '\n' + this.props.t(auditTagTranslation[campo.toUpperCase()]) + ': '
        if (myObj[seccion][campo].OldValue !== undefined) {
          const oldValueTranslate = myObj[seccion][campo].OldValue === 'True' || myObj[seccion][campo].OldValue === 'False' || myObj[seccion][campo].OldValue === 'EMPTY_AUDIT_VALUE'
            ? this.props.t('DOSSIER_COMPONENTS.AUDIT.' + myObj[seccion][campo].OldValue.toUpperCase()) : myObj[seccion][campo].OldValue
          result += this.props.t('DOSSIER_COMPONENTS.AUDIT.OLD_VALUE') + ' = ' + oldValueTranslate + '; '
        }
        const newValueTranslate = myObj[seccion][campo].NewValue === 'True' || myObj[seccion][campo].NewValue === 'False' || myObj[seccion][campo].NewValue === 'EMPTY_AUDIT_VALUE'
          ? this.props.t('DOSSIER_COMPONENTS.AUDIT.' + myObj[seccion][campo].NewValue.toUpperCase()) : myObj[seccion][campo].NewValue
        result += this.props.t('DOSSIER_COMPONENTS.AUDIT.NEW_VALUE') + ' = ' + newValueTranslate
      }
    }
    return result
  }

  CreateLandingSectionDetails (myObj, seccion) {
    var result = ''
    result += this.props.t('DOSSIER_COMPONENTS.AUDIT.LANDING_REQUEST.EMAIL') + ' = ' + myObj[seccion].Email + '\n'
    result += this.props.t('DOSSIER_COMPONENTS.AUDIT.LANDING_REQUEST.DOCUMENTS') + ' = ' + myObj[seccion].Documents
    return result
  }

  hasAuditDetails (hasDetails) {
    return this.getAuditDetailsTypeNumber(hasDetails) === auditDetailsType.GENERIC || this.getAuditDetailsTypeNumber(hasDetails) === auditDetailsType.GROUPED_DOCUMENTS
  }

  getAuditDetailsTypeNumber (idx) {
    return parseInt(idx.charAt(idx.indexOf(':') - 1))
  }

  render () {
    const {
      auditInfo
    } = this.props
    const tooltipActividadExpediente = (<Tooltip id="tooltipActividadExpediente" className=""><span>{this.props.t('DOSSIER_COMPONENTS.BUTTONS_HEADER.DETAILS')}</span></Tooltip>)
    return (
      <div className="wrapper _nf">
        <div className='wrapper-header'>
          <div className="title-page">
            <i className='ico-actividad-expediente icon-page' />
            <h2>{this.props.t('DOSSIER_COMPONENTS.BUTTONS_HEADER.RECORD_ACTIVITY')}</h2>
          </div>
          <div className='extraActions'> {/** Extra buttons & actions here **/}</div>
        </div>
        <div className='wrapper-body'>
          <div className='activitiContainer'>
            {Object.keys(auditInfo).map((idx) =>
              <div className='activitiContainer-line' key={idx}>
                <div className='Icon'><i className="ico-actividad-expediente"/></div>
                <div className='body'>
                  <div className='date' key={idx}>{idx.replace(/^([\d]+ [\d]+: )(.*)/, '$2')}</div>
                  <div className='text' key={idx}>{auditInfo[idx]}</div>
                </div>
                {!this.props.isFleetAudit && this.hasAuditDetails(idx) &&
                  <OverlayTrigger placement="left" overlay={tooltipActividadExpediente}>
                    <div className='details' onClick={() => { this.auditDetails(idx) } }>
                      <Glyphicon glyph="info-sign" />
                    </div>
                  </OverlayTrigger>
                }
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}
export default AuditInfo
