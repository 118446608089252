import documentMassiveDownload from '../../../constants/actions/dossier/common/documentMassiveDownload'

export function initialState () {
  return {
    documentList: []
  }
}

function getDocumentsMassiveDownloadSucess (state, { docList }) {
  return {
    ...state,
    documentList: docList
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case documentMassiveDownload.GET_ALL_AVAILABLE_DOCUMENT_MASSIVE_DOWNLOAD_SUCESS:
      return getDocumentsMassiveDownloadSucess(state, action)
    default:
      return state
  }
}
