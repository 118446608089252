import React, { PureComponent } from 'react'
import { formatMoney } from '../../../../util/utils'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { documentTypeUse as documentTypeUseConstants } from '../../../../constants/backendIds'
import { documentEntityType as documentEntityTypeConstants } from '../../../../constants/dossier/common/documentEntityType'

class ChargeRow extends PureComponent {
  render () {
    const {
      t,
      tKey,
      readOnlyFields,
      actions: { openDocumentEditionModal },
      dossierId, organizedUnitId, dossierType,
      charge: {
        number, completed, description, amount, documentId
      }
    } = this.props
    const viewDocument = (<Tooltip id="viewDocument">{t(`${tKey}VIEW_DOC`)}</Tooltip>)
    const validatedDocument = (<Tooltip id="viewDocument">{t(`${tKey}VALIDATED`)}</Tooltip>)
    const pendingDocument = (<Tooltip id="viewDocument">{t(`${tKey}PENDING`)}</Tooltip>)
    return (
      <tr>
        <td>{number}</td>
        <td>{description}</td>
        <td className="text-right">{formatMoney(amount)}</td>
        <td style={{ textAlign: 'center' }}>
          <span>
            {completed
              ? <OverlayTrigger placement="right" overlay={validatedDocument}>
                <i className="ico-document-ok"/>
              </OverlayTrigger>
              : <OverlayTrigger placement="right" overlay={pendingDocument}>
                <i className="ico-document-pending"/>
              </OverlayTrigger>
            }
          </span>
        </td>
        <td style={{ textAlign: 'center' }}>
          <OverlayTrigger placement="right" overlay={viewDocument}>
            <i className="ico-magnifying-plus"
              onClick={() => openDocumentEditionModal(
                documentId,
                documentTypeUseConstants.charge,
                documentEntityTypeConstants.DOSSIER,
                dossierId,
                null,
                null,
                organizedUnitId,
                null,
                // () => updateDependantsSaleDossierAndHeader(dossierId)
                null,
                true,
                dossierType,
                null,
                null,
                null,
                null,
                null,
                null,
                readOnlyFields
              )}/>
          </OverlayTrigger>
        </td>
      </tr>
    )
  }
}
export default ChargeRow
