import React, { PureComponent } from 'react'
import { Row, Col } from 'react-bootstrap'
import SelectInput from '../../../commons/SelectInput'
import { reduxForm, Field } from 'redux-form'

class Tab2 extends PureComponent {
  render () {
    return (
      <Col sm={12}>
        <Row>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Tipo venta:</span>
              <span className="second">Tipo venta</span>
            </div>
          </Col>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Tipo pedido:</span>
              <span className="second">Tipo pedido</span>
            </div>
          </Col>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Nº de flotas:</span>
              <span className="second">Nº flotas</span>
            </div>
          </Col>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Fecha prevista factura:</span>
              <span className="second">Fecha prevista factura</span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Fecha/hora entrega:</span>
              <span className="second">Fecha/hora entrega</span>
            </div>
          </Col>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Box:</span>
              <span className="second">Box</span>
            </div>
          </Col>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Ubicación entrega:</span>
              <span className="second">Ubicación entrega</span>
            </div>
          </Col>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Colaborador:</span>
              <span className="second">Colaborador</span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={4} className="multi-input-wrapper select-input-full-width">
            <Field
              name='roles'
              label='Tipo operaciones'
              placeholder='Tipo operaciones'
              component={SelectInput}
              labelKey='name'
              valueKey='roleId'
              multi
            />
          </Col>
        </Row>
      </Col>
    )
  }
}

export default reduxForm({ form: 'newForm' })(Tab2)
