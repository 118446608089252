import dossierActionTypes from '../../../constants/actions/dossier/dossier'
import commentsActionTypes from '../../../constants/actions/dossier/common/comments'
import auditInfoTypes from '../../../constants/actions/audit/auditInfo'

export function initialState () {
  return {
    dossierId: null,
    dossierSubTypeId: null,
    isPreCancelled: null,
    organizedUnitId: null,
    registrationDeliveryDocumentaryCheckList: [],
    showAlertSendPending: null,
    entityChangeable: true,
    header: {},
    checkListHeader: [],
    viewConfiguration: {},
    salesComponent: {},
    entityComponent: { updated: false },
    entityChildComponent: {},
    entityProviderComponent: {},
    dossierContactComponent: { updated: false },
    purchasesDossierId: null,
    parentIsHistorical: null,
    vehicleComponent: { updated: false },
    dossierKm: null,
    orderId: null,
    order: null,
    noRecoverableCosts: [],
    campaigns: [],
    uac: [],
    dossierFinancingComponent: { updated: false },
    paperWorks: [],
    charges: [],
    dossierEconomicComponent: { updated: false },
    dossierCompleteDeliveryComponent: { dossierDeliveryComponent: { updated: false } },
    dossierCompleteDeliveryCheckListItems: [],
    dossierDeliveryCheckListPurchase: [],
    comments: [],
    dossierRelatedDossiers: [],
    auditInfo: [],
    ivaType: [],
    dossierCompleteOtherDataComponent: { updated: false },
    lastAccessDate: null,
    financingStatusId: null,
    campaignSubtype: [],
    entityTypeIdDefault: null,
    unreadComments: null,
    reason: null,
    comment: null,
    cancelCombo: [],
    isMarked: null,
    customFieldsByComponent: [],
    customFieldsValues: [],
    customFields: {}
  }
}

function fetchRegistrationDeliveryDossierSuccess (state, { data }) {
  return {
    ...state,
    dossierId: data.dossierId || state.dossierId,
    dossierSubTypeId: data.dossierSubTypeId || state.dossierSubTypeId,
    isPreCancelled: (data.isPreCancelled !== null && data.isPreCancelled !== undefined ? data.isPreCancelled : state.isPreCancelled),
    isMarked: (data.isMarked !== null && data.isMarked !== undefined ? data.isMarked : state.isMarked),
    registrationDeliveryDocumentaryCheckList: data.registrationDeliveryDocumentaryCheckList || state.registrationDeliveryDocumentaryCheckList,
    organizedUnitId: data.organizedUnitId || state.organizedUnitId,
    showAlertSendPending: data.showAlertSendPending || state.showAlertSendPending,
    entityChangeable: data.entityChangeable || state.entityChangeable,
    header: data.dossierHeaderComponent || state.header,
    checkListHeader: data.checkListHeader || state.checkListHeader,
    viewConfiguration: { ...state.viewConfiguration, ...data.viewConfiguration },
    salesComponent: data.dossierSaleComponent || state.salesComponent,
    entityComponent: data.entityComponent || state.entityComponent,
    entityChildComponent: data.entityChildComponent || state.entityChildComponent,
    entityProviderComponent: data.entityProviderComponent || state.entityProviderComponent,
    dossierContactComponent: data.dossierContactComponent || state.dossierContactComponent,
    purchasesDossierId: (data.dossierParentId || data.dossierParentId === null ? data.dossierParentId : state.purchasesDossierId),
    parentIsHistorical: (data.parentIsHistorical !== null && data.parentIsHistorical !== undefined ? data.parentIsHistorical : state.parentIsHistorical),
    vehicleComponent: data.vehicleComponent || state.vehicleComponent,
    orderId: data.orderId || state.orderId,
    order: data.order || state.order,
    campaigns: data.dossierCompleteCampaigns || state.campaigns,
    uac: data.dossierCompleteUaCs || state.uac,
    dossierFinancingComponent: data.dossierFinancingComponent || state.dossierFinancingComponent,
    paperWorks: data.dossierCompletePaperworks || state.paperWorks,
    charges: data.dossierCompleteCharges || state.charges,
    dossierEconomicComponent: data.dossierEconomicComponent || state.dossierEconomicComponent,
    dossierCompleteDeliveryComponent: data.dossierCompleteDeliveryComponent
      ? data.dossierCompleteDeliveryComponent.dossierDeliveryComponent
        ? { dossierDeliveryComponent: data.dossierCompleteDeliveryComponent.dossierDeliveryComponent }
        : { dossierDeliveryComponent: {} }
      : state.dossierCompleteDeliveryComponent,
    dossierCompleteDeliveryCheckListItems: data.dossierCompleteDeliveryComponent
      ? data.dossierCompleteDeliveryComponent.dossierCompleteDeliveryCheckListItems
        ? data.dossierCompleteDeliveryComponent.dossierCompleteDeliveryCheckListItems
        : []
      : state.dossierCompleteDeliveryCheckListItems,
    dossierDeliveryCheckListPurchase: data.dossierCompleteDeliveryComponent ? (
      data.dossierCompleteDeliveryComponent.dossierDeliveryCheckListPurchase ? (
        data.dossierCompleteDeliveryComponent.dossierDeliveryCheckListPurchase
      ) : []
    ) : (
      state.dossierDeliveryCheckListPurchase
    ),
    comments: data.dossierCompleteComment || state.comments,
    dossierRelatedDossiers: data.dossierRelatedDossiers || state.dossierRelatedDossiers,
    auditInfo: data.auditInfo || state.auditInfo,
    ivaType: data.ivaType || state.ivaType,
    dossierCompleteOtherDataComponent: data.dossierCompleteOtherDataComponent || state.dossierCompleteOtherDataComponent,
    lastAccessDate: data.lastAccessDate || state.lastAccessDate,
    financingStatusId: data.financingStatusId || state.financingStatusId,
    totalAmountWithOptionals: data.totalAmountWithOptionals || state.totalAmountWithOptionals,
    campaignSubtype: data.campaignSubtype || state.campaignSubtype,
    entityTypeIdDefault: data.entityTypeIdDefault || state.entityTypeIdDefault,
    unreadComments: data.unreadComments >= 0 ? data.unreadComments : state.unreadComments,
    reason: data.reason || state.reason,
    comment: data.comment || state.comment,
    cancelCombo: data.cancelCombo || state.cancelCombo,
    customFieldsValues: data.customFields || state.customFieldsValues
  }
}

function fetchUACSuccess (state, { UACList }) {
  return {
    ...state,
    uac: UACList
  }
}

function fetchPaperworkSuccess (state, { paperworkList }) {
  return {
    ...state,
    paperWorks: paperworkList
  }
}

function fetchCommentsSuccess (state, { comments }) {
  return {
    ...state,
    comments
  }
}

function fetchDossierRelatedDossiersSuccess (state, { dossierRelatedDossiers }) {
  return {
    ...state,
    dossierRelatedDossiers
  }
}

function fetchAuditInfoSucces (state, { auditInfo }) {
  return {
    ...state,
    auditInfo
  }
}

function putPurchaseOnRegistrationDelivery (state, { purchaseId, parentIsHistorical }) {
  return {
    ...state,
    purchasesDossierId: purchaseId,
    parentIsHistorical
  }
}

function updateLastAccessDate (state, { lastAccessDateNew }) {
  return {
    ...state,
    lastAccessDate: lastAccessDateNew
  }
}

function fetchRegistrationDeliveryDossierEconomicSucces (state, { dossierEconomicComponent }) {
  return {
    ...state,
    dossierEconomicComponent
  }
}

function fetchCustomFieldsSuccess (state, { value }) {
  return {
    ...state,
    customFieldsByComponent: value
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case dossierActionTypes.FETCH_REGISTRATION_DELIVERY_DOSSIER_SUCCESS:
      return fetchRegistrationDeliveryDossierSuccess(state, action)
    case dossierActionTypes.FETCH_UAC_SUCCESS:
      return fetchUACSuccess(state, action)
    case dossierActionTypes.FETCH_PAPERWORK_SUCCESS:
      return fetchPaperworkSuccess(state, action)
    case commentsActionTypes.FETCH_REGISTRATION_DELIVERY_COMMENTS_SUCCESS:
      return fetchCommentsSuccess(state, action)
    case dossierActionTypes.FETCH_DOSSIER_RELATED_DOSSIERS_SUCCESS:
      return fetchDossierRelatedDossiersSuccess(state, action)
    case auditInfoTypes.FETCH_AUDIT_INFO_SUCCESS:
      return fetchAuditInfoSucces(state, action)
    case dossierActionTypes.RESTART_REGISTRATION_DELIVERY_STATE:
      return initialState()
    case dossierActionTypes.PUT_PURCHASE_ON_REGISTRATION_DELIVERY:
      return putPurchaseOnRegistrationDelivery(state, action)
    case dossierActionTypes.PUT_LASTACCESSDATE:
      return updateLastAccessDate(state, action)
    case dossierActionTypes.FETCH_REGISTRATION_DELIVERY_DOSSIER_ECONOMIC_SUCCESS:
      return fetchRegistrationDeliveryDossierEconomicSucces(state, action)
    case dossierActionTypes.FETCH_CUSTOM_FIELDS_SUCCESS:
      return fetchCustomFieldsSuccess(state, action)
    default:
      return state
  }
}
