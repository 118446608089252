import reservationContractModal from '../../../constants/actions/dossier/common/reservationContractModal'

export function openReservationContractModal (dossierId, printCheckList = false, checklistId = null) {
  return {
    type: reservationContractModal.OPEN_RESERVATION_CONTRACT_MODAL,
    dossierId,
    printCheckList,
    checklistId
  }
}

export function openReservationContractModalSuccess (financingAmount, printCheckList = false, checklistId = null) {
  return {
    type: reservationContractModal.OPEN_RESERVATION_CONTRACT_MODAL_SUCCESS,
    financingAmount,
    printCheckList,
    checklistId
  }
}

export function closeReservationContractModal () {
  return {
    type: reservationContractModal.CLOSE_RESERVATION_CONTRACT_MODAL
  }
}

export function closeReservationContractModalSuccess () {
  return {
    type: reservationContractModal.CLOSE_RESERVATION_CONTRACT_MODAL_SUCCESS
  }
}

export default {
  openReservationContractModal,
  openReservationContractModalSuccess,
  closeReservationContractModal,
  closeReservationContractModalSuccess
}
