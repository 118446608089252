import {
  entityTypesId, signTypes, campaignStages, clientsForContactAndEntityChildMandatoryValidation, clientInformation, additionalInfoIds
  , recurrentEntitySubTypes, operationTypeCodes, iemValues, permissions, dateType
} from '../constants/backendIds'
import { buttonsHeaderPermisionsId } from '../constants/dossier/common/buttonsHeader'
import { entityRequiredFields } from '../constants/validatedFields/entity'
import { contactRequiredFields } from '../constants/validatedFields/contact'
import { paperworkAddressRequiredFields } from '../constants/validatedFields/paperworkAddress'
import { otherDataRequiredFields } from '../constants/validatedFields/otherData'
import { dossierSubTypeId as dossierSubTypeIdConstants } from '../constants/dossier/common/dossierSubType'
import { chassisLength, countryImplementation } from '../constants/dossier/common/vehicle'
import settings from '../setting'
import { countryValidate } from '../constants/dossier/common/licensePlateValidationCountry'
import { saleTypeCodeEnum } from '../constants/dossier/sale/saleType'
import { paymentMethodModeEnum } from '../constants/dossier/common/paymentMethod'
import { codeMetadataFields } from '../constants/dossier/common/typeFields'
import { searchTabCode } from '../constants/search/searchConstants'
import {
  validatePrivateRfcFormat, validateBusinessRfcFormat, validateBusinessRfcDate,
  validatePrivateRfc, validateBusinessRfc, validatePrivateRfcDate
} from './mxEntityValidationFunctions'
import { rfcFieldCode, saleCodes, commonCodes } from '../constants/dossier/common/fieldCodePermissions'

import { returnObjectWithoutNulls, transformObjectBooleanStringsProps } from './utils'
import commonRegExp from './commonRegExp'
import moment from 'moment'

export function required (value) {
  return (!!value && ('' + value).trim().length > 0)
}

export function validateDNINIFNIE (idDocument) {
  var validChars = 'TRWAGMYFPDXBNJZSQVHLCKET'
  var nifRexp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i
  var nieRexp = /^[XYZ]{1}[0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i
  var str = idDocument.toString().toUpperCase()

  if (!nifRexp.test(str) && !nieRexp.test(str)) return false

  var nie = str
    .replace(/^[X]/, '0')
    .replace(/^[Y]/, '1')
    .replace(/^[Z]/, '2')

  var letter = str.substr(-1)
  var charIndex = parseInt(nie.substr(0, 8)) % 23
  if (validChars.charAt(charIndex) === letter) return true

  return false
}

function validDNI (dni) {
  var validChars = 'TRWAGMYFPDXBNJZSQVHLCKET'
  var valueDni = dni.substr(0, dni.length - 1)
  var letra = dni.substr(dni.length - 1, 1).toUpperCase()
  if (validChars.charAt(valueDni % 23) === letra) { return true }
  return false
}

function validNIE (nie) {
  var validChars = 'TRWAGMYFPDXBNJZSQVHLCKET'
  var str = nie.toString().toUpperCase()
  var nieNew = str
    .replace(/^[X]/, '0')
    .replace(/^[Y]/, '1')
    .replace(/^[Z]/, '2')
  var letter = str.substr(-1)
  var charIndex = parseInt(nieNew.substr(0, 8)) % 23
  if (validChars.charAt(charIndex) === letter) return true
  return false
}

export function validFormatDNI (dni_nie) {
  if (validateDNINIEFormat(dni_nie) === true) {
    if (!validDNI(dni_nie) && !validNIE(dni_nie)) {
      return 'dniNotValid'
    }
  } else {
    return 'dniNotFormat'
  }
}

export function validFormatCIF (cif) {
  if (validateCIF(cif) === true) {
    if (!validCIF(cif)) {
      return 'cifNotValid'
    }
  } else {
    return 'cifNotFormat'
  }
}

function validCIF (cif) {
  cif = cif.toString().toUpperCase()
  if (!cif || cif.length !== 9) {
    return false
  }

  var letters = ['J', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I']
  var digits = cif.substr(1, cif.length - 2)
  var letter = cif.substr(0, 1)
  var control = cif.substr(cif.length - 1)
  var sum = 0
  var i
  var digit

  if (!letter.match(/[A-Z]/)) {
    return false
  }

  for (i = 0; i < digits.length; ++i) {
    digit = parseInt(digits[i])

    if (isNaN(digit)) {
      return false
    }

    if (i % 2 === 0) {
      digit *= 2
      if (digit > 9) {
        digit = parseInt(digit / 10) + (digit % 10)
      }

      sum += digit
    } else {
      sum += digit
    }
  }

  sum %= 10
  if (sum !== 0) {
    digit = 10 - sum
  } else {
    digit = sum
  }

  if (letter.match(/[ABEH]/)) {
    return String(digit) === control
  }
  if (letter.match(/[NPQRSW]/)) {
    return letters[digit] === control
  }

  return String(digit) === control || letters[digit] === control
}

export function validateDNINIEFormat (dni) {
  if (dni) {
    var dniRexp = /^[0-9]{8}[ABCDEFGHIJKLMNOPQRSTUVWXYZ]{1}$/i
    var nieRexp = /^[XYZ]{1}[0-9]{7}[ABCDEFGHIJKLMNOPQRSTUVWXYZ]{1}$/i
    var str = dni.toString().toUpperCase()
    if (!dniRexp.test(str) && !nieRexp.test(str)) return false
    else return true
  }
}

export function isEmail (email) {
  return /^([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|(!#-[^-~ \t]|(\\[\t -~]))+)@([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\[[\t -Z^-~]*])$/i.test(email)
}

export function validateCIF (cif) {
  var cifRexp = /^[ABCDEFGHJNPQRSUVW]{1}[0123456789]{7}[ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789]{1}$/i
  var str = cif.toString().toUpperCase()
  if (!cifRexp.test(str)) return false
  else return true
}

export function compareDate (firstDate, secondDate) {
  if (firstDate) {
    let charToSplit = firstDate.indexOf('/') > -1 ? '/' : '-'
    const firstDateArray = firstDate.split(charToSplit)
    const validationDate = new Date(firstDateArray[2], firstDateArray[1] - 1, firstDateArray[0])
    if (secondDate) {
      const secondDateArray = secondDate.split(charToSplit)
      const referenceDate = new Date(secondDateArray[2], secondDateArray[1] - 1, secondDateArray[0])
      return {
        secondDateIsAfter: validationDate < referenceDate,
        secondDateIsEqual: validationDate.getTime() === referenceDate.getTime(),
        secondDateIsBefore: validationDate > referenceDate
      }
    } else {
      const currentDate = new Date()
      currentDate.setHours(0, 0, 0, 0)
      return {
        currentDateIsAfter: validationDate < currentDate,
        currentDateIsEqual: validationDate.getTime() === currentDate.getTime(),
        currentDateIsBefore: validationDate > currentDate
      }
    }
  }
}

export function validateInteger (value, props, showDecimals = true) {
  let error = {}
  if (typeof value !== 'undefined' && isNaN(value)) {
    error = showDecimals ? props.t('DOSSIER_COMPONENTS.VALIDATIONS.INTEGER_VALIDATION') : props.t('DOSSIER_COMPONENTS.VALIDATIONS.INTEGER_WITHOUT_DECIMALS_VALIDATION')
  }
  return error
}

export function validateAlfanumeric (value, props, esSimbolo = true) {
  var exRe = /^[A-Za-z0-9]*$/
  let error = {}
  if (!exRe.exec(value)) {
    error = esSimbolo ? ('') : props.t('DOSSIER_COMPONENTS.VALIDATIONS.ALFANUMERIC_VALIDATION')
  }
  return error
}

export function validateDate (value, props) {
  let error = {}
  if (value) {
    if (value.match(/[0-3][0-9].[0-1][0-9].[0-9][0-9][0-9][0-9]/)) {
      const [day, month, year] = value.split('/')
      const date = new Date(`${year}-${month}-${day}T12:00:00.000Z`)
      if (
        parseInt(day) <= 0 || parseInt(day) > 31 || parseInt(month) <= 0 || parseInt(month) > 12 || parseInt(year) <= 1752 ||
        `${year}-${month}-${day}T12:00:00.000Z` !== date.toISOString()
      ) {
        error = props.t('DOSSIER_COMPONENTS.VALIDATIONS.DATE_VALIDATION')
      }
    } else {
      error = props.t('DOSSIER_COMPONENTS.VALIDATIONS.DATE_VALIDATION')
    }
  }
  return error
}

export function validateDates (t, dateFromString, dateToString, dateFormat) {
  let error
  if (dateFromString && dateToString) {
    const dateFrom = moment(dateFromString, dateFormat ?? dateType.standard)
    const dateTo = moment(dateToString, dateFormat ?? dateType.standard)
    if (dateTo.isBefore(dateFrom)) {
      error = t('DOSSIER_COMPONENTS.VALIDATIONS.DATE_VALIDATION')
    }
  }
  return error
}

export function validateChasisLength (value, props) {
  let error = {}
  if (value.length > 0 && value.length !== 17) {
    error.state = true
    if (value.length > 17) {
      error.value = props.t('DOSSIER_COMPONENTS.VALIDATIONS.VEHICLE_CHASSIS_MAXLENGTH')
    } else {
      error.value = props.t('DOSSIER_COMPONENTS.VALIDATIONS.VEHICLE_CHASSIS_MINLENGTH')
    }
    return error
  } else {
    error.state = false
    error.value = ''
    return error
  }
}

export function validateMinChassisLengthSearch (value, props) {
  let error = {}
  if (value.length > 0 && value.length < 6) {
    error.state = true
    if (value.length > 17) {
      error.value = props.t('DOSSIER_COMPONENTS.VALIDATIONS.VEHICLE_CHASSIS_MAXLENGTH')
    } else {
      error.value = props.t('DOSSIER_COMPONENTS.VALIDATIONS.VEHICLE_CHASSIS_MINLENGTH_SEARCH')
    }
    return error
  } else {
    error.state = false
    error.value = ''
    return error
  }
}

export function validateRegex (regExString, name, value, props) {
  let error = {}
  const regEx = new RegExp(regExString.slice(6)) // Suprimir el texto regEx-
  if (!regEx.exec(value)) {
    error = props.t('DOSSIER_COMPONENTS.VALIDATIONS.REGEX_VALIDATION').replace('##FIELD##', name)
  }
  return error
}

export function customValidations (name, value, props) {
  let error = {}
  if (value) {
    switch (name) {
      case 'LessEqualToday':
      { const [day, month, year] = value.split('/')
        const date = new Date(`${year}-${month}-${day}T12:00:00.000Z`)
        let today = new Date()
        let [tDay, tMonth, tYear] = [today.getDate(), today.getMonth() + 1, today.getFullYear()]
        if (tMonth < 10) tMonth = '0' + tMonth
        if (tDay < 10) tDay = '0' + tDay
        today = new Date(`${tYear}-${tMonth}-${tDay}T12:00:00.000Z`)
        if (date.getTime() > today.getTime()) {
          error = props.t('DOSSIER_COMPONENTS.VALIDATIONS.DATE_LESS_EQUAL_TODAY')
        }
        break }
      case 'LaterThanToday':
      { const [day2, month2, year2] = value.split('/')
        const date2 = new Date(`${year2}-${month2}-${day2}T12:00:00.000Z`)
        let today2 = new Date()
        let [tDay2, tMonth2, tYear2] = [today2.getDate(), today2.getMonth() + 1, today2.getFullYear()]
        if (tMonth2 < 10) tMonth2 = '0' + tMonth2
        if (tDay2 < 10) tDay2 = '0' + tDay2
        today2 = new Date(`${tYear2}-${tMonth2}-${tDay2}T12:00:00.000Z`)
        if (date2.getTime() < today2.getTime()) {
          error = props.t('DOSSIER_COMPONENTS.VALIDATIONS.LATER_THAN_TODAY')
        }
        break }
      case 'LessToday':
      { const [day, month, year] = value.split('/')
        const date = new Date(`${year}-${month}-${day}T12:00:00.000Z`)
        let today = new Date()
        let [tDay, tMonth, tYear] = [today.getDate(), today.getMonth() + 1, today.getFullYear()]
        if (tMonth < 10) tMonth = '0' + tMonth
        if (tDay < 10) tDay = '0' + tDay
        today = new Date(`${tYear}-${tMonth}-${tDay}T12:00:00.000Z`)
        if (date.getTime() >= today.getTime()) {
          error = props.t('DOSSIER_COMPONENTS.VALIDATIONS.DATE_LESS_TODAY')
        }
        break }
      case 'LoadCodeValidation':
      { // V + 10 dígitos
        if (!/^V+[0-9]{10}$/i.test(value)) {
          error = props.t('DOSSIER_COMPONENTS.VALIDATIONS.LOAD_CODE_NOT_VALID')
        }
        break }
      case 'CanNotBeZero':
      { if (value === '0') {
        error = props.t('DOSSIER_COMPONENTS.VALIDATIONS.AMOUNT_CAN_NOT_BE_ZERO')
      }
      break }
      case 'CanNotBeNegative':
      { if (parseInt(value) < 0) {
        error = props.t('DOSSIER_COMPONENTS.VALIDATIONS.AMOUNT_CAN_NOT_BE_NEGATIVE')
      }
      break }
    }
  }
  return error
}

export function checkRfcConfiguration (props) {
  let activeTab = props.activeTab ? props.activeTab : 1

  let entConfig = props.dossier.viewConfiguration && props.dossier.viewConfiguration.tabsConfiguration && props.dossier.viewConfiguration.tabsConfiguration.find(c => c.code === buttonsHeaderPermisionsId[activeTab]) &&
        props.dossier.viewConfiguration.tabsConfiguration.find(c => c.code === buttonsHeaderPermisionsId[activeTab]).componentsConfiguration &&
        props.dossier.viewConfiguration.tabsConfiguration.find(c => c.code === buttonsHeaderPermisionsId[activeTab]).componentsConfiguration.find(c => c.code === searchTabCode.ENTITY)

  var res = entConfig && entConfig.fieldsConfiguration && entConfig.fieldsConfiguration.find(f => f.code === rfcFieldCode.rfc)
  return res ?? false
}

export function validateEntity (values, props, entityTypeIdDefault = null, isChildComponent = false) {
  const error = {}
  if (values) {
    if (values.rfc && values.entityTypeId === entityTypesId.privateEntity && !validatePrivateRfcFormat(values.rfc)) {
      error.rfc = props.t('DOSSIER_COMPONENTS.VALIDATIONS.ENTITY_RFC_ERROR_FORMAT')
    } else if (values.rfc && values.entityTypeId === entityTypesId.privateEntity && (!validatePrivateRfc(values.rfc) || !validatePrivateRfcDate(values.rfc))) {
      error.rfc = props.t('DOSSIER_COMPONENTS.VALIDATIONS.ENTITY_RFC_ERROR_INVALID')
    } else if (values.rfc && values.entityTypeId === entityTypesId.businessEntity && !validateBusinessRfcFormat(values.rfc)) {
      error.rfc = props.t('DOSSIER_COMPONENTS.VALIDATIONS.ENTITY_RFC_ERROR_FORMAT')
    } else if (values.rfc && values.entityTypeId === entityTypesId.businessEntity && (!validateBusinessRfc(values.rfc) || !validateBusinessRfcDate(values.rfc))) {
      error.rfc = props.t('DOSSIER_COMPONENTS.VALIDATIONS.ENTITY_RFC_ERROR_INVALID')
    }

    if (!isValidPhone(values.phone)) {
      error.phone = props.t('ADMIN.USERS.VALIDATION.VALID_PHONE')
    }

    if (values.entityTypeId !== entityTypesId.recurrentEntity && values.email && !isEmail(values.email)) {
      error.email = props.t('GLOBAL.FORMS.INVALID_EMAIL_ADDRESS')
    }

    let requiredFields = null
    if (values.entityTypeId) {
      requiredFields = entityRequiredFields(values.entityTypeId, props.dossier.dossierSubTypeId, null, isChildComponent)
      for (const prop in requiredFields) {
        if (!values[prop] || values[prop] === '') error[prop] = props.t(requiredFields[prop])
      }
    } else if (!entityTypeIdDefault && !values.entityTypeIdDefault) {
      error.entityTypeId = props.t('DOSSIER_COMPONENTS.VALIDATIONS.ENTITY_TYPE_MANDATORY')
    }
  } else {
    error.entityTypeId = props.t('DOSSIER_COMPONENTS.VALIDATIONS.ENTITY_TYPE_MANDATORY')
  }
  return error
}

export function validateContact (values, props) {
  let error = {}
  const requiredFields = contactRequiredFields(values)
  for (const prop in requiredFields) {
    if (!values[prop] || values[prop] === '') error[prop] = props.t(requiredFields[prop])
  }
  if (!isValidPhone(values.contactPhone)) {
    error.contactPhone = props.t('ADMIN.USERS.VALIDATION.VALID_PHONE')
  }
  return error
}

export function validatePaperworkAddress (values, props) {
  const error = {}
  const requiredFields = paperworkAddressRequiredFields
  if (values.differentPaperworkAddress) {
    for (const prop in requiredFields) {
      if (!values[prop]) error[prop] = props.t(requiredFields[prop])
    }
  }
  return error
}

export function validateOtherData (values, props, dossierSubtype) {
  const error = {}
  const requiredFields = otherDataRequiredFields(dossierSubtype)
  for (const prop in requiredFields) {
    if (!values[prop]) {
      error[prop] = props.t(requiredFields[prop])
    }
  }
  return error
}

export function validateVehicle (values, props) {
  const error = {}
  if (!values.updated) {
    return error
  }
  if (settings.clientFieldValidation === 'vgr') {
    if (
      values.vinChassis ||
    values.licensePlate ||
    values.comercialCode ||
    values.brandId ||
    values.model ||
    values.color ||
    values.tapestry ||
    values.brandReference ||
    values.optionals ||
    values.fuelId ||
    values.expirationItvDate ||
    values.vinChassisRight ||
    values.vinChassisLeft
    // values.originId ||
    // values.destinationId
    ) {
      if (values.vinChassis && values.vinChassis.length < chassisLength.total) {
        error.vinChassis = props.t('DOSSIER_COMPONENTS.VALIDATIONS.VEHICLE_CHASSIS_MINLENGTH')
      }
      if (values.vinChassis && values.vinChassis.length > chassisLength.total) {
        error.vinChassis = props.t('DOSSIER_COMPONENTS.VALIDATIONS.VEHICLE_CHASSIS_MAXLENGTH')
      }
      if (!values.brandId) {
        error.brandId = props.t('DOSSIER_COMPONENTS.VALIDATIONS.VEHICLE_BRAND_MANDATORY')
      }
      if (!values.model) {
        error.model = props.t('DOSSIER_COMPONENTS.VALIDATIONS.VEHICLE_MODEL_MANDATORY')
      }
      if (!values.color) {
        error.color = props.t('DOSSIER_COMPONENTS.VALIDATIONS.VEHICLE_COLOR_MANDATORY')
      }
      /*
      if (!values.originId) {
        error.originId = props.t('DOSSIER_COMPONENTS.VALIDATIONS.VEHICLE_ORIGIN_CLASIFICATION')
      }
      if (!values.destinationId) {
        error.destinationId = props.t('DOSSIER_COMPONENTS.VALIDATIONS.VEHICLE_DESTINATION_CLASIFICATION')
      }
      */
    }
  } else {
    if (
      values.vinChassis ||
      values.licensePlate ||
      values.comercialCode ||
      values.brandId ||
      values.model ||
      values.color ||
      values.tapestry ||
      values.optionals ||
      values.brandReference ||
      values.fuelId ||
      values.expirationItvDate ||
      values.vinChassisRight ||
      values.vinChassisLeft ||
      values.familyId ||
      values.originalLicensePlate ||
      values.countryId ||
      values.firstLicensePlateDate
    ) {
      if (values.vinChassis && values.vinChassis.length < chassisLength.total) {
        error.vinChassis = props.t('DOSSIER_COMPONENTS.VALIDATIONS.VEHICLE_CHASSIS_MINLENGTH')
      }
      if (values.vinChassis && values.vinChassis.length > chassisLength.total) {
        error.vinChassis = props.t('DOSSIER_COMPONENTS.VALIDATIONS.VEHICLE_CHASSIS_MAXLENGTH')
      }
      if (!values.brandId) {
        error.brandId = props.t('DOSSIER_COMPONENTS.VALIDATIONS.VEHICLE_BRAND_MANDATORY')
      }
      if (!values.model) {
        error.model = props.t('DOSSIER_COMPONENTS.VALIDATIONS.VEHICLE_MODEL_MANDATORY')
      }
      if (!values.color) {
        error.color = props.t('DOSSIER_COMPONENTS.VALIDATIONS.VEHICLE_COLOR_MANDATORY')
      }
    }
  }

  if (settings.clientFieldValidation === 'buss') {
    if (!values.carlineId) {
      error.carlineId = props.t('DOSSIER_COMPONENTS.VALIDATIONS.VEHICLE_CARLINE_MANDATORY')
    }
    if (!values.catalogueId) {
      error.catalogueId = props.t('DOSSIER_COMPONENTS.VALIDATIONS.VEHICLE_CATALOGUE_MANDATORY')
    }
    if (!values.modelId) {
      error.modelId = props.t('DOSSIER_COMPONENTS.VALIDATIONS.VEHICLE_MODEL_YEAR_MANDATORY')
    }
    if (!values.colorId) {
      error.colorId = props.t('DOSSIER_COMPONENTS.VALIDATIONS.VEHICLE_COLOR_MANDATORY')
    }
    if (!values.tapestryId) {
      error.tapestryId = props.t('DOSSIER_COMPONENTS.VALIDATIONS.VEHICLE_TAPESTRY_MANDATORY')
    }
    if (!values.carline) {
      error.carline = props.t('DOSSIER_COMPONENTS.VALIDATIONS.VEHICLE_CARLINE_MANDATORY')
    }
    if (!values.catalogue) {
      error.catalogue = props.t('DOSSIER_COMPONENTS.VALIDATIONS.VEHICLE_CATALOGUE_MANDATORY')
    }
    if (!values.modelYear || !values.modelYear.match(commonRegExp.is4DigitYear)) {
      var errorMessage = values?.modelYear?.length > 0 &&
        !values.modelYear.match(commonRegExp.is4DigitYear)
        ? props.t('GLOBAL.FORMS.INVALID_STRING')
        : props.t('DOSSIER_COMPONENTS.VALIDATIONS.VEHICLE_MODEL_YEAR_MANDATORY')
      error.modelYear = errorMessage
    }
  }

  return error
}

export function validateVehicleCreationModal (values, props) {
  const error = {}

  if (!values.vinChassis) {
    error.vinChassis = props.t('DOSSIER_COMPONENTS.VALIDATIONS.VEHICLE_CHASSISRIGHT_MANDATORY')
  }
  if (values.vinChassis && values.vinChassis.length < chassisLength.total) {
    error.vinChassis = props.t('DOSSIER_COMPONENTS.VALIDATIONS.VEHICLE_CHASSIS_MINLENGTH')
  }
  if (values.vinChassis && values.vinChassis.length > chassisLength.total) {
    error.vinChassis = props.t('DOSSIER_COMPONENTS.VALIDATIONS.VEHICLE_CHASSIS_MAXLENGTH')
  }
  if (!values.licensePlate) {
    error.licensePlate = props.t('DOSSIER_COMPONENTS.VALIDATIONS.VEHICLE_LICENSE_PLATE')
  }
  if (!values.brandId) {
    error.brandId = props.t('DOSSIER_COMPONENTS.VALIDATIONS.VEHICLE_BRAND_MANDATORY')
  }
  if (!values.model) {
    error.model = props.t('DOSSIER_COMPONENTS.VALIDATIONS.VEHICLE_MODEL_MANDATORY')
  }
  if (!values.color) {
    error.color = props.t('DOSSIER_COMPONENTS.VALIDATIONS.VEHICLE_COLOR_MANDATORY')
  }

  return error
}

export function validateDossierEconomic (dossierEconomicValues, t) {
  if (dossierEconomicValues && dossierEconomicValues.iemTypeId) {
    let dossierEconomicComponent = {}
    if (dossierEconomicValues.iemTypeId === iemValues.other && dossierEconomicValues.iemPercentage && parseFloat(dossierEconomicValues.iemPercentage) < 0) {
      dossierEconomicComponent.iemPercentage = t('DOSSIER_COMPONENTS.VALIDATIONS.IEM_PERCENTAGE_POSITIVE')
    }
    return dossierEconomicComponent
  }
}

export function validateDelivery (values, props, dossierSubtype = '', defaultDeliveryAddress = null) {
  const error = {}
  if (
    values.realDeliveryDate || values.realDeliveryTime ||
     values.deliveryCity || (
      dossierSubtype === dossierSubTypeIdConstants.venta_vo && (values.km || values.lastITVDate)
    )
  ) {
    if (!values.realDeliveryDate) {
      error.realDeliveryDate = props.t('DOSSIER_COMPONENTS.VALIDATIONS.DELIVERY_DATE_MANDATORY')
    }
    if (!values.realDeliveryTime && (dossierSubtype === dossierSubTypeIdConstants.compra_vn ||
          dossierSubtype === dossierSubTypeIdConstants.compra_vo ||
          (settings.clientFieldValidation !== clientInformation.TOYOTA && settings.clientFieldValidation !== clientInformation.KIA &&
            settings.clientFieldValidation !== clientInformation.DEMO && settings.clientFieldValidation !== clientInformation.CONQ &&
            settings.clientFieldValidation !== clientInformation.BUSS && settings.clientFieldValidation !== clientInformation.CANA &&
            settings.clientFieldValidation !== clientInformation.EUROMOTORS))) {
      error.realDeliveryTime = props.t('DOSSIER_COMPONENTS.VALIDATIONS.DELIVERY_TIME_MANDATORY')
    }
    if (!values.deliveryCity) {
      error.deliveryCity = props.t('DOSSIER_COMPONENTS.VALIDATIONS.DELIVERY_CITY_MANDATORY')
    }
    if (dossierSubtype === dossierSubTypeIdConstants.venta_vo) {
      if (values.km === undefined || values.km === null || values.km === '') {
        error.km = props.t('DOSSIER_COMPONENTS.VALIDATIONS.DELIVERY_KM_MANDATORY')
      }
      if (!values.lastITVDate) {
        error.lastITVDate = props.t('DOSSIER_COMPONENTS.VALIDATIONS.DELIVERY_LAST_ITV_DATE_MANDATORY')
      }
    }
  }
  return error
}

export function validateFinancingQuadis (values, props) {
  if (Object.keys(values).length > 1) {
    let error = {}
    if (values.financingTypeProductId !== null && props.financingTypeProduct !== null) {
      const fieldsFinancingMandatory = ['companyId', 'typeId', 'financingStatusId', 'bonusAmount']

      fieldsFinancingMandatory.forEach((field) => {
        if (!values.hasOwnProperty(field) || values[field] === '' || values[field] === null || values[field] === undefined) {
          error[field] = props.t('DOSSIER_COMPONENTS.VALIDATIONS.FINANCE_FIELD_MANDATORY')
        }
      })

      const fieldsFinancing = ['amount', 'instalments', 'firstInstalmentDate',
        'km', 'tin', 'entry', 'residualValue', 'openingCommission', 'monthlyFee', 'apr',
        'renovation', 'maintenanceIncluded', 'insuranceIncluded']

      if (props.financingTypeProduct.fields) {
        fieldsFinancing.forEach((field) => {
          if (props.financingTypeProduct.fields.find(x => x.fieldId === field) && props.financingTypeProduct.fields.find(x => x.fieldId === field).mandatory) {
            if (!values.hasOwnProperty(field) || values[field] === '' || values[field] === null || values[field] === undefined) {
              error[field] = props.t('DOSSIER_COMPONENTS.VALIDATIONS.FINANCE_FIELD_MANDATORY')
            }
          }
        })
      }
    } else {
      const fieldsFinancing = ['companyId', 'typeId', 'amount', 'instalments', 'firstInstalmentDate',
        'km', 'financingStatusId', 'tin', 'bonusAmount', 'entry', 'residualValue', 'openingCommission', 'monthlyFee', 'apr',
        'renovation', 'maintenanceIncluded', 'insuranceIncluded']

      fieldsFinancing.forEach((field) => {
        if (!values.hasOwnProperty(field) || values[field] === '' || values[field] === null || values[field] === undefined) {
          error[field] = props.t('DOSSIER_COMPONENTS.VALIDATIONS.FINANCE_FIELD_MANDATORY')
        }
      })
    }

    let repurchaseChecked = values.repurchaseAgreement
    if (repurchaseChecked === true) {
      if (!values.firstInstalmentDate) {
        error.firstInstalmentDate = props.t('DOSSIER_COMPONENTS.VALIDATIONS.FINANCE_FIELD_MANDATORY')
      }
    }

    let renovationChecked = values.renovation
    if (renovationChecked === 'true') {
      if (!values.lastDNICIF) {
        error.lastDNICIF = props.t('DOSSIER_COMPONENTS.VALIDATIONS.FINANCE_FIELD_MANDATORY')
      }
    }

    if (values.amount && parseFloat(values.amount) === 0) {
      error.amount = props.t('DOSSIER_COMPONENTS.VALIDATIONS.AMOUNT_CAN_NOT_BE_ZERO')
    }

    if (values.instalments && parseFloat(values.instalments) === 0) {
      error.instalments = props.t('DOSSIER_COMPONENTS.VALIDATIONS.VALUE_CAN_NOT_BE_ZERO')
    }

    if (values.tin && parseFloat(values.tin) === 0) {
      error.tin = props.t('DOSSIER_COMPONENTS.VALIDATIONS.VALUE_CAN_NOT_BE_ZERO')
    }

    if (values.monthlyFee && parseFloat(values.monthlyFee) === 0) {
      error.monthlyFee = props.t('DOSSIER_COMPONENTS.VALIDATIONS.VALUE_CAN_NOT_BE_ZERO')
    }

    if (values.apr && parseFloat(values.apr) === 0) {
      error.apr = props.t('DOSSIER_COMPONENTS.VALIDATIONS.VALUE_CAN_NOT_BE_ZERO')
    }

    if (values.lastDNICIF && validFormatDNI(values.lastDNICIF) !== undefined && validFormatCIF(values.lastDNICIF) !== undefined) {
      error.lastDNICIF = props.t('DOSSIER_COMPONENTS.VALIDATIONS.DNI_CIF_NOT_VALID')
    }
    return error
  }
  return {}
}

export function validateReFinancingQuadis (values, props) {
  if (Object.keys(values).length > 1) {
    let error = {}
    if (values.financingTypeProductId !== null && props.financingTypeProduct !== null) {
      const fieldsFinancingMandatory = ['companyId', 'typeId']

      fieldsFinancingMandatory.forEach((field) => {
        if (!values.hasOwnProperty(field) || values[field] === '' || values[field] === null || values[field] === undefined) {
          error[field] = props.t('DOSSIER_COMPONENTS.VALIDATIONS.FINANCE_FIELD_MANDATORY')
        }
      })

      const fieldsFinancing = ['amount', 'instalments', 'firstInstalmentDate',
        'km', 'tin', 'entry', 'residualValue', 'openingCommission', 'monthlyFee', 'apr',
        'renovation', 'maintenanceIncluded', 'insuranceIncluded']

      if (props.financingTypeProduct.fields) {
        fieldsFinancing.forEach((field) => {
          if (props.financingTypeProduct.fields.find(x => x.fieldId === field) && props.financingTypeProduct.fields.find(x => x.fieldId === field).mandatory) {
            if (!values.hasOwnProperty(field) || values[field] === '' || values[field] === null || values[field] === undefined) {
              error[field] = props.t('DOSSIER_COMPONENTS.VALIDATIONS.FINANCE_FIELD_MANDATORY')
            }
          }
        })
      }
    } else {
      const fieldsFinancing = ['companyId', 'typeId', 'amount', 'instalments', 'firstInstalmentDate',
        'km', 'tin', 'entry', 'residualValue', 'openingCommission', 'monthlyFee', 'apr',
        'renovation', 'maintenanceIncluded', 'insuranceIncluded']

      fieldsFinancing.forEach((field) => {
        if (!values.hasOwnProperty(field) || values[field] === '' || values[field] === null || values[field] === undefined) {
          error[field] = props.t('DOSSIER_COMPONENTS.VALIDATIONS.FINANCE_FIELD_MANDATORY')
        }
      })
    }

    let repurchaseChecked = values.repurchaseAgreement
    if (repurchaseChecked === true) {
      if (!values.firstInstalmentDate) {
        error.firstInstalmentDate = props.t('DOSSIER_COMPONENTS.VALIDATIONS.FINANCE_FIELD_MANDATORY')
      }
    }

    let renovationChecked = values.renovation
    if (renovationChecked === 'true') {
      if (!values.lastDNICIF) {
        error.lastDNICIF = props.t('DOSSIER_COMPONENTS.VALIDATIONS.FINANCE_FIELD_MANDATORY')
      }
    }

    if (values.amount && parseFloat(values.amount) === 0) {
      error.amount = props.t('DOSSIER_COMPONENTS.VALIDATIONS.AMOUNT_CAN_NOT_BE_ZERO')
    }

    if (values.instalments && parseFloat(values.instalments) === 0) {
      error.instalments = props.t('DOSSIER_COMPONENTS.VALIDATIONS.VALUE_CAN_NOT_BE_ZERO')
    }

    if (values.tin && parseFloat(values.tin) === 0) {
      error.tin = props.t('DOSSIER_COMPONENTS.VALIDATIONS.VALUE_CAN_NOT_BE_ZERO')
    }

    if (values.monthlyFee && parseFloat(values.monthlyFee) === 0) {
      error.monthlyFee = props.t('DOSSIER_COMPONENTS.VALIDATIONS.VALUE_CAN_NOT_BE_ZERO')
    }

    if (values.apr && parseFloat(values.apr) === 0) {
      error.apr = props.t('DOSSIER_COMPONENTS.VALIDATIONS.VALUE_CAN_NOT_BE_ZERO')
    }

    if (values.lastDNICIF && validFormatDNI(values.lastDNICIF) !== undefined && validFormatCIF(values.lastDNICIF) !== undefined) {
      error.lastDNICIF = props.t('DOSSIER_COMPONENTS.VALIDATIONS.DNI_CIF_NOT_VALID')
    }

    return error
  }
  return {}
}

export function getAccepFiles () {
  return settings.acceptFileFormats
}

export function validatefilterComboIndividual (val) {
  return val.id !== signTypes.SIGNATUREURL && val.id !== signTypes.SIGNATUREEMAIL && val.id !== signTypes.SIGNATURESMS
}

export function validatefilterComboMultiple (val) {
  return val.id !== signTypes.BIOMETRIC && val.id !== signTypes.DIGITAL && val.id !== signTypes.HANDWRITING
}

export function validatePercentages (values, props) {
  const error = { }

  const percentageFreeOption = props.dossier.dossierCampaignComponent.dossierCampaignDetailComponent ? props.dossier.dossierCampaignComponent.dossierCampaignDetailComponent.percentageFreeOption : false
  const amountFreeOption = props.dossier.dossierCampaignComponent.dossierCampaignDetailComponent ? props.dossier.dossierCampaignComponent.dossierCampaignDetailComponent.amountFreeOption : false
  const activeStage = props.dossier.checkListHeader && props.dossier.checkListHeader.find(stge => stge.isActive)
  const stageId = activeStage ? activeStage.stageId : null

  // validacion importe solicitado
  if (amountFreeOption && (parseFloat(values.dossierCampaignDetailComponent.amountRequested) <= 0 || !values.dossierCampaignDetailComponent.amountRequested)) {
    error.amountRequested = props.t('DOSSIER_COMPONENTS.VALIDATIONS.IMPORT_REQUESTED')
  }

  // validacion % solicitado
  if (percentageFreeOption && values.dossierCampaignDetailComponent.percentageCampaignRequested) {
    if (parseFloat(values.dossierCampaignDetailComponent.percentageCampaignRequested) <= 0 || parseFloat(values.dossierCampaignDetailComponent.percentageCampaignRequested) > 100) {
      error.percentageCampaignRequested = props.t('DOSSIER_COMPONENTS.VALIDATIONS.PERCENTAGE_REQUESTED')
    }
  } else if (percentageFreeOption && !values.dossierCampaignDetailComponent.percentageCampaignRequested) {
    error.percentageCampaignRequested = props.t('DOSSIER_COMPONENTS.VALIDATIONS.PERCENTAGE_REQUESTED')
  }

  // validacion % aprobado etapa verificacion
  if (percentageFreeOption && (stageId === campaignStages.verificationCAM || stageId === campaignStages.verificationAY)) {
    if (parseFloat(values.dossierCampaignDetailComponent.percentageCampaignApproved) < 0 || parseFloat(values.dossierCampaignDetailComponent.percentageCampaignApproved) > parseFloat(values.dossierCampaignDetailComponent.percentageCampaignRequested)) {
      error.percentageCampaignApproved = props.t('DOSSIER_COMPONENTS.VALIDATIONS.PERCENTAGE_APPROVED_VERIFICATION')
    }

    // validacion % aprobado etapa aprobacion
  } else if (percentageFreeOption && (stageId === campaignStages.approvalCAM || stageId === campaignStages.approvalAY)) {
    if (parseFloat(values.dossierCampaignDetailComponent.percentageCampaignApproved) <= 0 ||
    parseFloat(values.dossierCampaignDetailComponent.percentageCampaignApproved) > parseFloat(values.dossierCampaignDetailComponent.percentageCampaignRequested) ||
      !values.dossierCampaignDetailComponent.percentageCampaignApproved) {
      error.percentageCampaignApproved = props.t('DOSSIER_COMPONENTS.VALIDATIONS.PERCENTAGE_APPROVED_APROVAL')
    }
  }

  // validacion importe aprobado etapa verificacion
  if (amountFreeOption && (stageId === campaignStages.verificationCAM || stageId === campaignStages.verificationAY)) {
    if (parseFloat(values.dossierCampaignDetailComponent.approvedAmount) < 0 || parseFloat(values.dossierCampaignDetailComponent.approvedAmount) > parseFloat(values.dossierCampaignDetailComponent.amountRequested)) {
      error.approvedAmount = props.t('DOSSIER_COMPONENTS.VALIDATIONS.AMOUNT_APPROVED_VERIFICATION')
    }

    // validacion importe aprobado etapa aprobacion
  } else if (amountFreeOption && (stageId === campaignStages.approvalCAM || stageId === campaignStages.approvalAY)) {
    if (parseFloat(values.dossierCampaignDetailComponent.approvedAmount) <= 0 ||
      parseFloat(values.dossierCampaignDetailComponent.approvedAmount) > parseFloat(values.dossierCampaignDetailComponent.amountRequested) ||
        !values.dossierCampaignDetailComponent.approvedAmount) {
      error.approvedAmount = props.t('DOSSIER_COMPONENTS.VALIDATIONS.AMOUNT_APPROVED_APROVAL')
    }
  }

  return error
}

export function validateLicensePlate (value, validateLicensePlateCountry, errorMessage) {
  if (settings.validateLicensePlate) {
    const validateLicense = chooseCountry(validateLicensePlateCountry)
    var array = value && value !== '' && validateLicense ? value.match(validateLicense) : undefined
    return (value && value !== '' && validateLicense ? (value.match(validateLicense) && (array ? array[0] === value : false) ? undefined : errorMessage) : undefined)
  }
  return undefined
}

export function validateMetadatoByCode (entityValue, field, props, error) {
  if (field.code) {
    switch (field.code) {
      case codeMetadataFields.EXPECTED_REPURCHASE_DATE:
        if (entityValue[field.fieldId.toString()]) {
          if (props.docResult && props.docResult.data && props.docResult.data.dossier && props.docResult.data.dossier.firstInstalmentDate && entityValue[field.fieldId.toString()]) {
            var stringInstalmentDate = props.docResult.data.dossier.firstInstalmentDate.replace(' 00:00:00', '')
            var firstInstalmentDateParts = stringInstalmentDate.split('/')
            var firstInstalmentDate = new Date(parseInt(firstInstalmentDateParts[2], 10),
              parseInt(firstInstalmentDateParts[1], 10) - 1,
              parseInt(firstInstalmentDateParts[0], 10))

            var parts = entityValue[field.fieldId.toString()].split('/')
            var introduced = new Date(parseInt(parts[2], 10),
              parseInt(parts[1], 10) - 1,
              parseInt(parts[0], 10))

            if (firstInstalmentDate >= introduced) {
              error.entityValue[field.fieldId.toString()] = props.t('DOSSIER_COMPONENTS.DOCUMENTARY_MANAGEMENT.ADD_OR_EDIT_DOCUMENT.VALIDATION.EXPECTED_REPURCHASE_DATE_VALIDATION').replace('##FIELD##', field.name)
            }
          } else {
            error.entityValue[field.fieldId.toString()] = props.t('DOSSIER_COMPONENTS.DOCUMENTARY_MANAGEMENT.ADD_OR_EDIT_DOCUMENT.VALIDATION.EXPECTED_REPURCHASE_DATE_NO_DATE').replace('##FIELD##', field.name)
          }
        }
        break
    }
  }
}

function chooseCountry (countryCode) {
  switch (countryCode) {
    case countryImplementation.spain:
      return countryValidate.ESP
    case countryImplementation.vgr:
      return countryValidate.DEFAULT_VGR
    default:
      return countryValidate.DEFAULT
  }
}

export function validatePhone (values, props) {
  if (values.phone) {
    const ex = values.phone.match(/^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})((x|ext)\d{1,5}){0,1}$/)
    if (!ex) {
      return false
    } else {
      return true
    }
  }
}

export function isSvnInQuadisWithFleets (props) {
  const salesComponent = props.values.salesComponent
  const dossierSubTypeId = props.dossier.dossierSubTypeId
  const saleTypeId = salesComponent ? salesComponent.saleTypeId : null
  const saleTypeCode = saleTypeId ? props.combos.saleTypeCombo.find((value) => { return value.id === saleTypeId }).code : null

  return settings.clientFieldValidation === clientInformation.QUADIS &&
    dossierSubTypeId === dossierSubTypeIdConstants.venta_vn &&
    saleTypeCode === saleTypeCodeEnum.FLOTAS
}

export function isSvnInQuadisWithFleetsAndRenting (props) {
  const salesComponent = props.values.salesComponent
  const dossierSubTypeId = props.dossier.dossierSubTypeId
  const saleTypeId = salesComponent ? salesComponent.saleTypeId : null
  const paymentMethodId = salesComponent ? salesComponent.paymentMethodId : null
  const paymentMethodMode = paymentMethodId ? props.combos.paymentMethodCombo.find((value) => { return value.id === paymentMethodId })?.mode : null
  const saleTypeCode = saleTypeId ? props.combos.saleTypeCombo.find((value) => { return value.id === saleTypeId })?.code : null

  const componentsConfiguration = props.dossier.viewConfiguration.tabsConfiguration ? props.dossier.viewConfiguration.tabsConfiguration.find((value) => value.code === 'main')?.componentsConfiguration : null
  const fieldsConfiguration = componentsConfiguration ? componentsConfiguration.find((value) => value.code === 'bsale')?.fieldsConfiguration : null
  const permission = fieldsConfiguration ? fieldsConfiguration.find((value) => value.code === 'rentalOrderNumber')?.permission : null

  return settings.clientFieldValidation === clientInformation.QUADIS &&
    dossierSubTypeId === dossierSubTypeIdConstants.venta_vn &&
    saleTypeCode === saleTypeCodeEnum.FLOTAS &&
    paymentMethodMode === paymentMethodModeEnum.RENTING &&
    permission === permissions.editable
}

export function isSaleWithFleetsVGR (props) {
  const salesComponent = props.values.salesComponent
  const dossierSubTypeId = props.dossier.dossierSubTypeId
  const operationTypes = salesComponent && salesComponent.operationTypeIds ? salesComponent.operationTypeIds : []
  let fleetOperationTypeId = props.combos.operationTypeCombo.find(o => o.code === operationTypeCodes.FLEETS)?.id

  return (dossierSubTypeId === dossierSubTypeIdConstants.venta_vn ||
    dossierSubTypeId === dossierSubTypeIdConstants.venta_vo) &&
    operationTypes.includes(fleetOperationTypeId)
}

export function isValidFleetCode (fleetCode, props) {
  let error = {}
  if (!fleetCode && isSvnWithFleets(props)) {
    error = props.t('DOSSIER_COMPONENTS.VALIDATIONS.MANDATORY_FIELD')
  }
  return error
}

export function isSvnWithFleets (props) {
  const salesComponent = props.values.salesComponent
  const dossierSubTypeId = props.dossier.dossierSubTypeId
  const saleTypeId = salesComponent ? salesComponent.saleTypeId : null
  const saleTypeCode = saleTypeId ? props.combos.saleTypeCombo.find((value) => { return value.id === saleTypeId })?.code : null
  const additionalInfos = salesComponent && salesComponent.additionalInfoIds ? salesComponent.additionalInfoIds : []

  return dossierSubTypeId === dossierSubTypeIdConstants.venta_vn &&
    (saleTypeCode === saleTypeCodeEnum.FLOTAS || additionalInfos.includes(additionalInfoIds.FLEETS_VN) || additionalInfos.includes(additionalInfoIds.FLEETS_VO))
}

export function isSvoWithFleets (props) {
  const salesComponent = props.values.salesComponent
  const dossierSubTypeId = props.dossier.dossierSubTypeId
  const saleTypeId = salesComponent ? salesComponent.saleTypeId : null
  const saleTypeCode = saleTypeId ? props.combos.saleTypeCombo.find((value) => { return value.id === saleTypeId })?.code : null
  const additionalInfos = salesComponent && salesComponent.additionalInfoIds ? salesComponent.additionalInfoIds : []

  return dossierSubTypeId === dossierSubTypeIdConstants.venta_vo &&
    (saleTypeCode === saleTypeCodeEnum.FLOTAS || additionalInfos.includes(additionalInfoIds.FLEETS_VN) || additionalInfos.includes(additionalInfoIds.FLEETS_VO))
}

export function isRequiredEndCustomerAndContact (props) {
  const entityTypeId = props.dossier.entityComponent ? props.dossier.entityComponent.entityTypeId : null

  return entityTypeId === entityTypesId.recurrentEntity &&
    (isSvnInQuadisWithFleets(props) || clientsForContactAndEntityChildMandatoryValidation.includes(settings.clientFieldValidation))
}

export function isRequiredEndCustomerAndContactVGR (props, values) {
  const dossierSubTypeId = props.dossier.dossierSubTypeId
  const entityTypeId = props.dossier.entityComponent && props.dossier.entityComponent.entityTypeId ? props.dossier.entityComponent.entityTypeId : values.entityComponent ? values.entityComponent.entityTypeId : null
  const isSale = dossierSubTypeId === dossierSubTypeIdConstants.venta_vn || dossierSubTypeId === dossierSubTypeIdConstants.venta_vo
  const subEntityTypeId = props.dossier.entityComponent && props.dossier.entityComponent.entitySubTypeId ? props.dossier.entityComponent.entitySubTypeId : values.entityComponent ? values.entityComponent.entitySubTypeId : null
  const svnWithFleets = isSaleWithFleetsVGR(props) && entityTypeId === entityTypesId.recurrentEntity
  const saleType = props.combos && props.combos.saleTypeCombo && values.salesComponent && props.combos.saleTypeCombo.find(x => x.id === values.salesComponent.saleTypeId)
  const isSelfSupply = saleType && (saleType.code === saleTypeCodeEnum.SELFSUPPLY_VN || saleType.code === saleTypeCodeEnum.SELFSUPPLY_VO)
  const oldSaleType = props.combos && props.combos.saleTypeCombo && props.dossier && props.dossier.salesComponent && props.combos.saleTypeCombo.find(x => x.id === props.dossier.salesComponent.saleTypeId)
  const oldSaleTypeIsSelfSupply = oldSaleType && (oldSaleType.code === saleTypeCodeEnum.SELFSUPPLY_VN || oldSaleType.code === saleTypeCodeEnum.SELFSUPPLY_VO)

  if (isSale && (svnWithFleets || isSelfSupply || oldSaleTypeIsSelfSupply)) {
    return false
  } else if ((entityTypeId === entityTypesId.recurrentEntity && isSale && (subEntityTypeId === recurrentEntitySubTypes.trading || subEntityTypeId === recurrentEntitySubTypes.renting))) {
    return false
  } else if (isSale && entityTypeId === entityTypesId.recurrentEntity) {
    return true
  } else {
    false
  }
}

export function isEntityEmpty (entityValues, registeredFields) {
  let isFilled = false
  isFilled = registeredFields && Object.keys(registeredFields).map(registeredField => {
    if (registeredField.indexOf('entityComponent') !== -1) {
      var entityComponentProps = registeredField.split('.')
      if (entityComponentProps.length > 0) {
        let value = entityValues[entityComponentProps[1]]
        if (value && value.toString() !== '') {
          return true
        }
      }
    }
  }).includes(true)
  return !isFilled
}

export function isContactEmpty (contactValues, registeredFields) {
  let isFilled = Object.keys(registeredFields).map(registeredField => {
    if (registeredField.indexOf('dossierContactComponent') !== -1) {
      var contactComponentProps = registeredField.split('.')
      if (contactComponentProps.length > 0) {
        let value = contactValues[contactComponentProps[1]]
        if (value && value !== '') {
          return true
        }
      }
    }
  }).includes(true)
  return !isFilled
}

export function isEntityChildEmpty (entityChildValues, registeredFields) {
  let isFilled = Object.keys(registeredFields).map(registeredField => {
    if (registeredField.indexOf('entityChildComponent') !== -1) {
      var entityChildComponentProps = registeredField.split('.')
      if (entityChildComponentProps.length > 0) {
        let value = entityChildValues[entityChildComponentProps[1]]
        if (value && value !== '') {
          return true
        }
      }
    }
  }).includes(true)
  return !isFilled
}

export function validateVinChassis (value, props) {
  let error = {}
  if (value) {
    if (!value.match(/^[A-HJ-NPR-Z0-9]{17}$/)) {
      error = props.t('DOSSIER_COMPONENTS.VALIDATIONS.REGEX_VALIDATION').replace('##FIELD##', '')
    }
  }
  return error
}

export function validateFinancingModal (values, props) {
  let error = {}
  const financingValues = values.dossierFinancingComponent && transformObjectBooleanStringsProps(returnObjectWithoutNulls(values.dossierFinancingComponent))
  if (values.dossierFinancingComponent && JSON.stringify(financingValues) !== JSON.stringify(values.originalDossierFinancingComponent)) {
    if (settings.clientFieldValidation === clientInformation.QUADIS) {
      error = validateFinancingQuadis(values.dossierFinancingComponent, props)
    } else {
      const financeVal = values.dossierFinancingComponent
      let dossierFinancingComponent = {}
      if (
        financeVal.companyId || financeVal.typeId || financeVal.amount ||
        financeVal.instalments || financeVal.firstInstalmentDate || financeVal.km || financeVal.tin
      ) {
        if (!financeVal.companyId) dossierFinancingComponent.companyId = props.t('DOSSIER_COMPONENTS.VALIDATIONS.FINANCE_COMPANY_MANDATORY')
        if (!financeVal.typeId) dossierFinancingComponent.typeId = props.t('DOSSIER_COMPONENTS.VALIDATIONS.FINANCE_TYPE_MANDATORY')
        if (!financeVal.amount) {
          dossierFinancingComponent.amount = props.t('DOSSIER_COMPONENTS.VALIDATIONS.FINANCE_AMOUNT_MANDATORY')
        } else {
          const auxError = validateInteger(financeVal.amount, props)
          if (Object.keys(auxError).length > 0) {
            dossierFinancingComponent.amount = auxError
          }
        }
      }
      if (Object.keys(dossierFinancingComponent).length > 0) {
        error = dossierFinancingComponent
      }
    }
  }
  return error
}

export function getFieldErrors (formErrors) {
  let stringErrors = []
  if (formErrors) {
    if (Object.keys(formErrors).length > 0) {
      Object.keys(formErrors).map((comp) => {
        if (formErrors[comp]) {
          if (Object.keys(formErrors[comp]).length > 0) {
            Object.keys(formErrors[comp]).map((fieldName) => {
              if (formErrors[comp][fieldName]) {
                stringErrors.push(comp + '.' + fieldName)
              }
            })
          }
        }
      })
    }
  }
  return stringErrors
}

export function checkCurrencyValidation (values, props) {
  return props.dossier && props.dossier.viewConfiguration && props.dossier.viewConfiguration.tabsConfiguration && props.dossier.viewConfiguration.tabsConfiguration.find(v => v.code === commonCodes.main) &&
        props.dossier.viewConfiguration.tabsConfiguration.find(v => v.code === commonCodes.main).componentsConfiguration &&
        props.dossier.viewConfiguration.tabsConfiguration.find(v => v.code === commonCodes.main).componentsConfiguration.find(c => c.code === saleCodes.bsale) &&
        props.dossier.viewConfiguration.tabsConfiguration.find(v => v.code === commonCodes.main).componentsConfiguration.find(c => c.code === saleCodes.bsale).fieldsConfiguration &&
        props.dossier.viewConfiguration.tabsConfiguration.find(v => v.code === commonCodes.main).componentsConfiguration.find(c => c.code === saleCodes.bsale).fieldsConfiguration.find(f => f.code === saleCodes.currency) &&
        props.dossier.viewConfiguration.tabsConfiguration.find(v => v.code === commonCodes.main).componentsConfiguration.find(c => c.code === saleCodes.bsale).fieldsConfiguration.find(f => f.code === saleCodes.currency).permission === permissions.editable &&
        !values.salesComponent.currencyId
}

export function isVisibleFleetCode (props) {
  return props.dossier.viewConfiguration.tabsConfiguration.find(d => d.code === commonCodes.main).componentsConfiguration.find(d => d.code === saleCodes.bsale).fieldsConfiguration.find(d => d.code === saleCodes.fleetCode) &&
         props.dossier.viewConfiguration.tabsConfiguration.find(d => d.code === commonCodes.main).componentsConfiguration.find(d => d.code === saleCodes.bsale).fieldsConfiguration.find(d => d.code === saleCodes.fleetCode).permission !== permissions.hidden
}

export function isValidPhone (phoneNumber) {
  if ((phoneNumber && phoneNumber !== '') && phoneNumber.length !== 10 && settings.clientFieldValidation === clientInformation.BUSS) {
    return false
  }
  return true
}

export function validateMinimalStringLength (t, value, minLength) {
  let error
  if (value && value !== '' && value.length > 0 && value.length < minLength) {
    error = t('DOSSIER_COMPONENTS.VALIDATIONS.MIN_LENGTH_STRING')?.replace('##MIN_LENGTH##', minLength)
  }
  return error
}

export function validateCampaignAmount (value, totalAmountWithOptionals, t) {
  if (!t || !value || value <= totalAmountWithOptionals) {
    return undefined
  } else {
    return t('DOSSIER_COMPONENTS.VALIDATIONS.AMOUNT_BIGGER_THAN_VEHICLE_PRICE')
  }
}

export function validateCampaignPercentage (value, t) {
  if (!t || !value || value <= 100) {
    return undefined
  } else {
    return t('DOSSIER_COMPONENTS.VALIDATIONS.PERCENTAGE_BIGGER_THAN_HUNDRED')
  }
}

export function validateCampaignTotalAmount (value, totalAmountWithOptionals, t) {
  if (!t || !value || value <= totalAmountWithOptionals) {
    return undefined
  } else {
    return t('DOSSIER_COMPONENTS.VALIDATIONS.TOTAL_AMOUNT_BIGGER_THAN_VEHICLE_PRICE')
  }
}

export function validateCampaignTotalPercentage (value, t) {
  if (!t || !value || value <= 100) {
    return undefined
  } else {
    return t('DOSSIER_COMPONENTS.VALIDATIONS.TOTAL_AMOUNT_BIGGER_THAN_VEHICLE_PRICE')
  }
}
