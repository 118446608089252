import apiFetch from '../apiFetch'

export default function (token, documentId, body, lastAccessDate, referenceId, referenceType) {
  const queryParamsArray = [
    referenceType ? `referenceType=${referenceType}` : null,
    referenceId ? `referenceId=${referenceId}` : null
  ]

  const queryParams = queryParamsArray.reduce(
    (result, query, idx) => result + (query ? (result ? `&${query}` : `?${query}`) : '')
    , ''
  )
  return apiFetch({ endPoint: `document/${documentId}/reject${queryParams}`, body: body, method: 'PUT', token: token, lastAccessDate })
}
