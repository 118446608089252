import React, { PureComponent } from 'react'
import { Modal, Tabs, Tab, Button } from 'react-bootstrap'
import VehiclePopover from '../VehiclePopover'
import DossierPopover from '../DossierPopover'
import moment from 'moment'
import { reduxForm } from 'redux-form'
import SimpleTablePage from '../../../containers/commons/general/SimpleTablePage'

class ApprovedServicesModal extends PureComponent {
  constructor () {
    super()
    this.state = {
    }
  }

  getSnapshotBeforeUpdate () {
    return null
  }

  componentDidUpdate (prevProps) {
    if (prevProps.showModal !== this.props.showModal && this.props.showModal) {
      this.props.reset()
    }
  }

  componentWillUnmount () {
    this.props.reset()
  }

  render () {
    const {
      t, resultApprove, showModal, closeModal, onOrderChange, getIconClass, vehicleData = []
    } = this.props
    const tKey = 'SEARCH.CATEGORIES.PLAN_LOGISTICS_PAP.'

    return (
      <Modal show={showModal}
        bsSize="large"
        keyboard={false}
        className="approvedServicesModal"
        onHide={closeModal}
        backdrop={'static'}>

        <Modal.Header>
          <div className="title-user">
            <h2>{t(`${tKey}RESULTS`)}</h2>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Tabs
            id="tab-approve-services-modal"
            defaultActiveKey={1}
          >
            {resultApprove?.servicesApproved &&
            <Tab eventKey={1} title={t(`${tKey}APPROVED`) + ' ' + `(${resultApprove.servicesApproved.length})`}>
              <PapPlanLogiticsTable
                t ={t}
                data = {resultApprove.servicesApproved}
                onOrderChange ={onOrderChange}
                getIconClass ={getIconClass}
                vehicleData= {vehicleData}
              />
            </Tab>
            }
            {resultApprove?.servicesNonApproved &&
            <Tab eventKey={2} title={t(`${tKey}NOT_APPROVED`) + ' ' + `(${resultApprove.servicesNonApproved.length})`}>
              <PapPlanLogiticsTable
                t ={t}
                data = {resultApprove.servicesNonApproved}
                onOrderChange ={onOrderChange}
                getIconClass ={getIconClass}
                vehicleData= {vehicleData}
              />
            </Tab>}
          </Tabs>
        </Modal.Body>
        <Modal.Footer>
          <Button
            id="btn_close"
            className="btn-standard"
            onClick={() => { closeModal() }}
          >
            {t('GLOBAL.BUTTONS.CLOSE')}
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

class PapPlanLogiticsTable extends PureComponent {
  render () {
    const {
      t, data, onOrderChange, getIconClass, vehicleData
    } = this.props
    const tKey = 'SEARCH.CATEGORIES.PLAN_LOGISTICS_PAP.'

    return (
      <SimpleTablePage
        columns={[
          <th key={0} data-field="dossier" onClick={(event) => onOrderChange(event)}>
            {t(`${tKey}DOSSIER`)}&nbsp;<i className={getIconClass('dossier')}/>
          </th>,
          <th key={1} data-field="vehicle">
            {t(`${tKey}VEHICLE`)}&nbsp;
          </th>,
          <th key={2} data-field="applicationDate" onClick={(event) => onOrderChange(event)}>
            {t(`${tKey}APPLICATION_DATE`)}&nbsp;<i className={getIconClass('applicationDate')}/>
          </th>,
          <th key={3} data-field="availabilityDate" onClick={(event) => onOrderChange(event)}>
            {t(`${tKey}AVAILABILITY_DATE`)}&nbsp;<i className={getIconClass('availabilityDate')}/>
          </th>,
          <th key={4} data-field="requiredDate" onClick={(event) => onOrderChange(event)}>
            {t(`${tKey}REQUIRED_DATE`)}&nbsp;<i className={getIconClass('requiredDate')}/>
          </th>
        ]}
        rows={data.map((row, idx) => (
          <tr key={idx}>
            <td className={'dossier see-more'}>
              <DossierPopover
                idx={idx}
                t={t}
                id={'popover-dossier' + idx}
                dossier={row.dossier}
              />
            </td>
            <td className="see-more">
              <VehiclePopover
                t={t}
                vehicle={row.vehicle}
                half={(vehicleData.length || 1) / (idx + 1)}
                idx={idx}
                id={'popover-vehicle' + idx}
              />
            </td>
            <td>{row.applicationDate ? moment(row.applicationDate).format('DD/MM/YYYY') : null}</td>
            <td>{row.availabilityDate ? moment(row.availabilityDate).format('DD/MM/YYYY') : null}</td>
            <td>{row.requiredDate ? moment(row.requiredDate).format('DD/MM/YYYY') : null}</td>
          </tr>
        ))}
      />
    )
  }
}

export default reduxForm({
  form: 'ApprovedServicesModal'
})(ApprovedServicesModal)
