import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PurchasePresentTypeModal from '../../components/masters/PurchasePresentTypeModal'
import { translate } from 'react-polyglot'
import { closePurchasePresentTypeModal, savePurchasePresentTypeModal } from '../../actions/masters/masters'

export function mapStateToProps (state) {
  return {
    showPurchasePresentTypeModal: state.entityMasters.showPurchasePresentTypeModal,
    languageList: state.entityMasters.languageList,
    purchasePresentTypeLanguage: state.entityMasters.purchasePresentTypeFields.purchasePresentTypeLanguage || [],
    purchasePresentTypeId: state.entityMasters.purchasePresentTypeId,
    organizedUnitId: state.entityMasters.purchasePresentTypeFields.organizedUnitId,
    combos: {
      UOTreeCombo: state.combos.UOTreeCombo
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      closePurchasePresentTypeModal,
      savePurchasePresentTypeModal
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(PurchasePresentTypeModal))
