import React, { PureComponent } from 'react'
import { Field } from 'redux-form'
import { permissions } from '../../../../constants/backendIds'
import InputSelect from '../../../commons/form/InputSelect'

class NormalizedAddress extends PureComponent {
  constructor (props) {
    super()
    this.state = {
      disableCountry: false,
      disableState: false,
      disableCity: false,
      disableTown: false,
      disableColony: false,
      disableCP: false,
      combos: {
        entityCountryTypeCombo: null,
        entityStateTypeCombo: null,
        entityCityTypeCombo: null,
        entityTownTypeCombo: null,
        entityColonyTypeCombo: null,
        entityCPTypeCombo: null
      }
    }
  }

  componentDidMount () {
    if ((this.props?.entityComponent?.countryCode !== null && this.props?.entityComponent?.countryCode !== undefined) && this.state.combos.entityCountryTypeCombo == null) {
      this.getAllEntityAddressData()
      this.disableEntityAddressCombos()
    } else if (this.state.combos.entityCountryTypeCombo == null) {
      this.getEntityCountryCombo()
      this.disableEmptyEntityAddressCombos()
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props?.entityComponent?.countryCode !== prevProps?.entityComponent?.countryCode &&
      (this.props?.entityComponent?.countryCode !== null && this.props?.entityComponent?.countryCode !== undefined) &&
    (this.props?.entityComponent?.stateCode !== null && this.props?.entityComponent?.stateCode !== undefined) &&
    (this.state.combos.entityCountryTypeCombo == null || this.state.combos.entityStateTypeCombo == null)) {
      this.getAllEntityAddressData()
      this.disableEntityAddressCombos()
    }
    if (this.props?.entityComponent?.entityTypeId !== prevProps.entityComponent?.entityTypeId) {
      this.disableEmptyEntityAddressCombos()
    }
  }

  disableEntityAddressCombos () {
    this.setState({
      disableCountry: !!this.props?.entityComponent.stateCode,
      disableState: !!this.props?.entityComponent.cityCode,
      disableCity: !!this.props?.entityComponent.townCode,
      disableTown: !!this.props?.entityComponent.colonyCode,
      disableColony: !!this.props?.entityComponent.postalCodeCode
    })
  }

  disableEmptyEntityAddressCombos () {
    this.setState({
      disableCountry: false,
      disableState: true,
      disableCity: true,
      disableTown: true,
      disableColony: true,
      disableCP: true
    })
  }

  getAllEntityAddressData () {
    new Promise((resolve) => {
      this.props.actions.fetchClientDataAddress(this.props?.entityComponent, null, resolve)
    }).then((result) => {
      if (result) {
        this.setState({ combos: { ...result } })
      }
    })
  }

  getEntityCountryCombo () {
    new Promise((resolve) => {
      this.props.actions.fetchEntityCountryTypeCombo(resolve)
    }).then((result) => {
      if (result) {
        this.setState({ combos: { ...this.state.combos, entityCountryTypeCombo: result } })
      }
    })
  }

  handleEntityCountryChange (code) {
    if (code) {
      new Promise((resolve) => {
        this.props.actions.fetchEntityStateTypeCombo(this.state.combos.entityCountryTypeCombo?.find(x => x.code === code)?.id, resolve)
      }).then((result) => {
        if (result) {
          this.setState({ combos: { ...this.state.combos, entityStateTypeCombo: result }, disableCountry: true, disableState: false })
        }
      })
    }
  }

  handleEntityStateChange (code) {
    if (code) {
      new Promise((resolve) => {
        this.props.actions.fetchEntityCityTypeCombo(this.state.combos.entityStateTypeCombo?.find(x => x.code === code)?.id, resolve)
      }).then((result) => {
        if (result) {
          this.setState({ combos: { ...this.state.combos, entityCityTypeCombo: result }, disableState: true, disableCity: false })
        }
      })
    } else {
      this.setState({ combos: { ...this.state.combos, entityCityTypeCombo: null }, disableCountry: false, disableState: true })
    }
  }

  handleEntityCityChange (code) {
    if (code) {
      new Promise((resolve) => {
        this.props.actions.fetchEntityTownTypeCombo(this.state.combos.entityCityTypeCombo?.find(x => x.code === code)?.id, resolve)
      }).then((result) => {
        if (result) {
          this.setState({ combos: { ...this.state.combos, entityTownTypeCombo: result }, disableCity: true, disableTown: false })
        }
      })
    } else {
      this.setState({ combos: { ...this.state.combos, entityTownTypeCombo: null }, disableState: false, disableCity: true })
    }
  }

  handleEntityTownChange (code) {
    if (code) {
      new Promise((resolve) => {
        this.props.actions.fetchEntityColonyTypeCombo(this.state.combos.entityTownTypeCombo?.find(x => x.code === code)?.id, resolve)
      }).then((result) => {
        if (result) {
          this.setState({ combos: { ...this.state.combos, entityColonyTypeCombo: result }, disableTown: true, disableColony: false })
        }
      })
    } else {
      this.setState({ combos: { ...this.state.combos, entityColonyTypeCombo: null }, disableCity: false, disableTown: true })
    }
  }

  handleEntityColonyChange (code) {
    if (code) {
      new Promise((resolve) => {
        this.props.actions.fetchEntityCPTypeCombo(this.state.combos.entityColonyTypeCombo?.find(x => x.code === code)?.id, resolve)
      }).then((result) => {
        if (result) {
          this.setState({ combos: { ...this.state.combos, entityCPTypeCombo: result }, disableColony: true, disableCP: false })
        }
      })
    } else {
      this.setState({ combos: { ...this.state.combos, entityCPTypeCombo: null }, disableTown: false, disableColony: true })
    }
  }

  handleEntityCPChange (code) {
    if (!code) {
      this.setState({ disableColony: false, disableCP: true })
    }
  }

  render () {
    const {
      t, fieldsConfiguration, readOnlyFields, allInformationDisabled, entityTypeName
    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.ENTITY.'
    return (
      [fieldsConfiguration.countryCode && fieldsConfiguration.countryCode.permission !== permissions.hidden && (
        <Field
          colSm={4}
          id="countryCode"
          name={`${entityTypeName}.countryCode`}
          key={`${entityTypeName}.countryCode`}
          controlLabel={t(`${tKey}COUNTRY`)}
          placeholder={t(`${tKey}COUNTRY`)}
          component={InputSelect}
          onInputChange={(value) => this.handleEntityCountryChange(value)}
          disabled={ readOnlyFields || allInformationDisabled || fieldsConfiguration.countryCode.permission !== permissions.editable || this.state.disableCountry}
          options={this.state.combos.entityCountryTypeCombo}
          valueKey="code"
          labelKey="value"
        />
      ),
      fieldsConfiguration.stateCode && fieldsConfiguration.stateCode.permission !== permissions.hidden && (
        <Field
          colSm={4}
          id="stateCode"
          name={`${entityTypeName}.stateCode`}
          key={`${entityTypeName}.stateCode`}
          controlLabel={t(`${tKey}STATE`)}
          placeholder={t(`${tKey}STATE`)}
          component={InputSelect}
          onInputChange={(value) => this.handleEntityStateChange(value)}
          disabled={ readOnlyFields || allInformationDisabled || fieldsConfiguration.stateCode.permission !== permissions.editable || this.state.disableState}
          options={this.state.combos.entityStateTypeCombo}
          valueKey="code"
          labelKey="value"
        />
      ),
      fieldsConfiguration.cityCode && fieldsConfiguration.cityCode.permission !== permissions.hidden && (
        <Field
          colSm={4}
          id="cityCode"
          name={`${entityTypeName}.cityCode`}
          key={`${entityTypeName}.cityCode`}
          controlLabel={t(`${tKey}CITY`)}
          placeholder={t(`${tKey}CITY`)}
          component={InputSelect}
          onInputChange={(value) => this.handleEntityCityChange(value)}
          disabled={ readOnlyFields || allInformationDisabled || fieldsConfiguration.cityCode.permission !== permissions.editable || this.state.disableCity}
          options={this.state.combos.entityCityTypeCombo}
          valueKey="code"
          labelKey="value"
        />
      ),
      fieldsConfiguration.townCode && fieldsConfiguration.townCode.permission !== permissions.hidden && (
        <Field
          colSm={4}
          id="townCode"
          name={`${entityTypeName}.townCode`}
          key={`${entityTypeName}.townCode`}
          controlLabel={t(`${tKey}TOWN`)}
          placeholder={t(`${tKey}TOWN`)}
          component={InputSelect}
          onInputChange={(value) => this.handleEntityTownChange(value)}
          disabled={ readOnlyFields || allInformationDisabled || fieldsConfiguration.townCode.permission !== permissions.editable || this.state.disableTown}
          options={this.state.combos.entityTownTypeCombo}
          valueKey="code"
          labelKey="value"
        />
      ),
      fieldsConfiguration.colonyCode && fieldsConfiguration.colonyCode.permission !== permissions.hidden && (
        <Field
          colSm={4}
          id="colonyCode"
          name={`${entityTypeName}.colonyCode`}
          key={`${entityTypeName}.colonyCode`}
          controlLabel={t(`${tKey}COLONY`)}
          placeholder={t(`${tKey}COLONY`)}
          component={InputSelect}
          onInputChange={(value) => this.handleEntityColonyChange(value)}
          disabled={ readOnlyFields || allInformationDisabled || fieldsConfiguration.colonyCode.permission !== permissions.editable || this.state.disableColony}
          options={this.state.combos.entityColonyTypeCombo}
          valueKey="code"
          labelKey="value"
        />
      ),
      fieldsConfiguration.cpCode && fieldsConfiguration.cpCode.permission !== permissions.hidden && (
        <Field
          colSm={4}
          id="postalCodeCode"
          name={`${entityTypeName}.postalCodeCode`}
          key={`${entityTypeName}.postalCodeCode`}
          controlLabel={t(`${tKey}CP`)}
          placeholder={t(`${tKey}CP`)}
          component={InputSelect}
          onInputChange={(value) => this.handleEntityCPChange(value)}
          disabled={ readOnlyFields || allInformationDisabled || fieldsConfiguration.cpCode.permission !== permissions.editable || this.state.disableCP}
          options={this.state.combos.entityCPTypeCombo}
          valueKey="code"
          labelKey="value"
        />
      )]
    )
  }
}
export default NormalizedAddress
