import apiFetch from '../apiFetch'

export default function (token, taskId) {
  return apiFetch({
    endPoint: `TaskDashboard/FollowUpTask?taskId=${taskId}`,
    method: 'DELETE',
    body: null,
    token: token
  })
}
