import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import NewDossier from '../../../components/dossiers/newDossier/NewDossier'
import NewDossier_v2 from '../../../_v2/components/dossiers/newDossier/NewDossier'
import { fetchLowestOrganizedUnits, fetchDossierTypes, fetchDossierSubTypes } from '../../../actions/combos/combos'
import { submitNewDossier } from '../../../actions/dossier/commonDossierActions'
import TrackingContainer from '../../../containers/tracking/trackingContainer'
import { buttonsPermissions } from '../../../constants/dossier/common/buttonsPermissions'
import { getStorageValue } from '../../../storage/storage'
export function mapStateToProps (state) {
  return {
    combos: {
      lowestOrganizedUnits: state.combos.lowestOrganizedUnitsCombo,
      dossierTypes: state.combos.dossierTypesCombo,
      dossierSubTypes: state.combos.dossierSubTypesCombo
    },
    updateToshiko: state.auth.sections[0]?.sectionTabsConfiguration[1]?.sectionFieldsConfiguration[0]?.code === buttonsPermissions.update_dossier_toshiko,
    newDossierForm: state.form.newDossier
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchLowestOrganizedUnits,
      fetchDossierTypes,
      fetchDossierSubTypes,
      submitNewDossier
    }, dispatch)
  }
}
// var decoratedComponent = TrackingContainer(NewDossier, true)
let version = parseInt(getStorageValue('interfaceVersion'))
var decoratedComponent = null
if (version === 2) {
  decoratedComponent = TrackingContainer(NewDossier_v2, true)
} else {
  decoratedComponent = TrackingContainer(NewDossier, true)
}
export default connect(mapStateToProps, mapDispatchToProps)(translate()(decoratedComponent))
