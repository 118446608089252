import commonActionTypes from '../constants/actions/common.js'
import customModalActionTypes from '../constants/actions/modals/customModal.js'

export function fetchLanguagesAvailable () {
  return {
    type: commonActionTypes.FETCH_LANGUAGES_AVAILABLE
  }
}

export function fetchLanguagesAvailableSuccess (languageAvailable) {
  return {
    type: commonActionTypes.FETCH_LANGUAGES_AVAILABLE_SUCCESS,
    languageAvailable
  }
}

export function showLoader () {
  return {
    type: commonActionTypes.SHOW_LOADER
  }
}

export function hideLoader () {
  return {
    type: commonActionTypes.HIDE_LOADER
  }
}

export function showSmallLoader () {
  return {
    type: commonActionTypes.SHOW_SMALL_LOADER
  }
}

export function hideSmallLoader () {
  return {
    type: commonActionTypes.HIDE_SMALL_LOADER
  }
}

export function changeLocale (responseLocale) {
  return {
    type: commonActionTypes.CHANGE_LOCALE,
    responseLocale
  }
}

export function openModal (modalType, okAction, cancelAction, message, subMessage, replaceMessageArgs, replaceSubMessageArgs) {
  return {
    type: commonActionTypes.OPEN_MODAL,
    okAction,
    cancelAction,
    modalType,
    message,
    subMessage,
    replaceMessageArgs,
    replaceSubMessageArgs
  }
}

export function closeModal () {
  return {
    type: commonActionTypes.CLOSE_MODAL
  }
}

export function yesNoModal () {
  return {
    type: customModalActionTypes.YES_NO_MODAL
  }
}

export function setCurrentSection (section) {
  return {
    type: commonActionTypes.SET_CURRENT_SECTION,
    section
  }
}

export function setSectionByPath (sectionCode) {
  return {
    type: commonActionTypes.SET_SECTION_BY_PATH,
    sectionCode
  }
}

export function getUserRegisterCount (resolve, reject) {
  return {
    type: commonActionTypes.GET_USER_REGISTER_COUNT,
    resolve,
    reject
  }
}

export function openDocWarning (resolve, reject) {
  return {
    type: commonActionTypes.OPEN_DOC_WARNING,
    resolve,
    reject
  }
}

export function updateErrorModel (model, fields) {
  return {
    type: commonActionTypes.UPDATE_ERROR_MODEL,
    model,
    fields
  }
}

export default {
  fetchLanguagesAvailable,
  fetchLanguagesAvailableSuccess,
  showLoader,
  hideLoader,
  showSmallLoader,
  hideSmallLoader,
  changeLocale,
  openModal,
  yesNoModal,
  setCurrentSection,
  getUserRegisterCount,
  openDocWarning,
  updateErrorModel
}
