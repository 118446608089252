
export const documenTypeUseCodes = {
  DOC_RECONDITIONING_INVOICE: 'DOC_RECONDITIONING_INVOICE',
  DOC_RECONDITIONING_ORDER: 'DOC_RECONDITIONING_ORDER',
  FOTOS_DOCS_STOCK: 'FOTOS_DOCS_STOCK'
}

export const referenceTypes = {
  NONE: 0,
  ORDER_DOCUMENT_ID: 1,
  INVOICE_DOCUMENT_ID: 2
}
