import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import SelfSupplyFinderModal from '../../../../components/dossiers/sales/SelfSupply/SelfSupplyFinderModal'
import { closeSelfSupplyModal, fetchSelfSupply, saleSelfSupplyList, resetFilters } from '../../../../actions/search/search'

export function mapStateToProps (state) {
  return {
    ...state.search.selfSupply
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      closeSelfSupplyModal,
      fetchSelfSupply,
      saleSelfSupplyList,
      resetFilters
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(SelfSupplyFinderModal))
