/* eslint-disable no-unused-vars */
import React, { PureComponent } from 'react'
import { Glyphicon } from 'react-bootstrap'
import { permissions } from '../../../../constants/backendIds'
import { buttonsSidebar, buttonsSidebarGetId } from '../../../../constants/dossier/common/buttonsSidebar'
import { dossierTypes as dossierTypesConstant } from '../../../../constants/dossier/common/dossierTypes'
import { dossierSubTypeId as dossierSubTypeIdConstants } from '../../../../constants/dossier/common/dossierSubType'

class ButtonsSidebar extends PureComponent {
  constructor () {
    super()
    this.Li = React.createRef()
    this.state = {
      servicesLoaded: null
    }
  }

  setActiveSection (section) {
    this.props.actions.setActiveSection(section)
    this.props.handleToUpdateComponent(section)
    switch (section) {
      case buttonsSidebar.vehicle:
        if (this.props.isVehicleLoaded === false) {
          if (this.props.historical === false) {
            return new Promise((resolve, reject) => {
              if (this.props.dossierType === dossierTypesConstant.sales) {
                return this.props.actions.fetchSalesVehicle(this.props.dossierId, resolve, reject, this.props.pristine)
              } else if (this.props.dossierType === dossierTypesConstant.purchase) {
                return this.props.actions.fetchPurchaseVehicle(this.props.dossierId, resolve, reject, this.props.pristine)
              } else if (this.props.dossierType === dossierTypesConstant.paperwork) {
                return this.props.actions.fetchPaperworkVehicle(this.props.dossierId, resolve, reject, this.props.pristine)
              } else if (this.props.dossierType === dossierTypesConstant.campaign) {
                return this.props.actions.fetchCampaignVehicle(this.props.dossierId, resolve, reject, this.props.pristine)
              } else if (this.props.dossierType === dossierTypesConstant.registrationDelivery) {
                return this.props.actions.fetchRegistrationDeliveryVehicle(this.props.dossierId, resolve, reject, this.props.pristine)
              } else if (this.props.dossierType === dossierTypesConstant.service) {
                return this.props.actions.fetchServiceDossierVehicle(this.props.dossierId, resolve, reject, this.props.pristine)
              } else if (this.props.dossierType === dossierTypesConstant.stock) {
                return this.props.actions.fetchStockDossierVehicle(this.props.dossierId, resolve, reject, this.props.pristine)
              }
            })
          } else {
            return new Promise((resolve, reject) => {
              if (this.props.dossierType === dossierTypesConstant.sales) {
                return this.props.actions.fetchSalesVehicleHistorical(this.props.dossierId, resolve, reject)
              } else if (this.props.dossierType === dossierTypesConstant.purchase) {
                return this.props.actions.fetchPurchaseVehicleHistorical(this.props.dossierId, resolve, reject)
              } else if (this.props.dossierType === dossierTypesConstant.paperwork) {
                return this.props.actions.fetchPaperworkVehicleHistorical(this.props.dossierId, resolve, reject)
              } else if (this.props.dossierType === dossierTypesConstant.campaign) {
                return this.props.actions.fetchCampaignVehicleHistorical(this.props.dossierId, resolve, reject)
              } else if (this.props.dossierType === dossierTypesConstant.registrationDelivery) {
                return this.props.actions.fetchRegistrationDeliveryVehicleHistorical(this.props.dossierId, resolve, reject, this.props.pristine)
              } else if (this.props.dossierType === dossierTypesConstant.service) {
                return this.props.actions.fetchServiceDossierVehicleHistorical(this.props.dossierId, resolve, reject, this.props.pristine)
              } else if (this.props.dossierType === dossierTypesConstant.stock) {
                return this.props.actions.fetchStockDossierVehicleHistorical(this.props.dossierId, resolve, reject, this.props.pristine)
              }
            })
          }
        }
        break
      case buttonsSidebar.entity:
        if (this.props.isClientLoaded === false) {
          if (this.props.historical === false) {
            return new Promise((resolve, reject) => {
              if (this.props.dossierType === dossierTypesConstant.sales) {
                return this.props.actions.fetchSalesClient(this.props.dossierId, resolve, reject, this.props.pristine)
              } else if (this.props.dossierType === dossierTypesConstant.purchase) {
                return this.props.actions.fetchPurchaseClient(this.props.dossierId, resolve, reject, this.props.pristine)
              } else if (this.props.dossierType === dossierTypesConstant.paperwork) {
                return this.props.actions.fetchPaperworkClient(this.props.dossierId, resolve, reject, this.props.pristine)
              } else if (this.props.dossierType === dossierTypesConstant.campaign) {
                return this.props.actions.fetchCampaignClient(this.props.dossierId, resolve, reject, this.props.pristine)
              } else if (this.props.dossierType === dossierTypesConstant.registrationDelivery) {
                return this.props.actions.fetchRegistrationDeliveryClient(this.props.dossierId, resolve, reject, this.props.pristine)
              }
            })
          } else {
            return new Promise((resolve, reject) => {
              if (this.props.dossierType === dossierTypesConstant.sales) {
                return this.props.actions.fetchSalesClientHistorical(this.props.dossierId, resolve, reject)
              } else if (this.props.dossierType === dossierTypesConstant.purchase) {
                return this.props.actions.fetchPurchaseClientHistorical(this.props.dossierId, resolve, reject)
              } else if (this.props.dossierType === dossierTypesConstant.paperwork) {
                return this.props.actions.fetchPaperworkClientHistorical(this.props.dossierId, resolve, reject)
              } else if (this.props.dossierType === dossierTypesConstant.campaign) {
                return this.props.actions.fetchCampaignClientHistorical(this.props.dossierId, resolve, reject)
              } else if (this.props.dossierType === dossierTypesConstant.registrationDelivery) {
                return this.props.actions.fetchRegistrationDeliveryClient(this.props.dossierId, resolve, reject, this.props.pristine)
              }
            })
          }
        }
        break
      case buttonsSidebar.brokerage:
        if (this.props.isBrokerageLoaded === false) {
          if (this.props.historical === false) {
            return new Promise((resolve, reject) => {
              return this.props.actions.fetchSalesInsuranceCarrier(this.props.dossierId, resolve, reject, this.props.pristine)
            })
          } else {
            return new Promise((resolve, reject) => {
              return this.props.actions.fetchSalesInsuranceCarrierHistorical(this.props.dossierId, resolve, reject)
            })
          }
        }
        break
      case buttonsSidebar.finance:
        if (this.props.isFinanceLoaded === false) {
          if (this.props.historical === false) {
            return new Promise((resolve, reject) => {
              return this.props.actions.fetchSalesFinancing(this.props.dossierId, resolve, reject, this.props.pristine)
            })
          } else {
            return new Promise((resolve, reject) => {
              return this.props.actions.fetchSalesFinancingHistorical(this.props.dossierId, resolve, reject)
            })
          }
        }
        break
      case buttonsSidebar.services:
        if (this.props.historical === false) {
          return new Promise((resolve, reject) => {
            return this.props.actions.fetchSalesServices(this.props.dossierId, false, resolve, reject)
          })
        } else {
          return new Promise((resolve, reject) => {
            return this.props.actions.fetchSalesServices(this.props.dossierId, true, resolve, reject)
          })
        }
      case buttonsSidebar.delivery:
        if (this.props.isDeliveryLoaded === false) {
          if (this.props.historical === false) {
            return new Promise((resolve, reject) => {
              if (this.props.dossierType === dossierTypesConstant.sales) {
                return this.props.actions.fetchSalesDelivery(this.props.dossierId, resolve, reject, this.props.pristine)
              } else if (this.props.dossierType === dossierTypesConstant.registrationDelivery) {
                return this.props.actions.fetchRegistrationDeliveryDelivery(this.props.dossierId, resolve, reject, this.props.pristine)
              }
            })
          } else {
            return new Promise((resolve, reject) => {
              if (this.props.dossierType === dossierTypesConstant.sales) {
                return this.props.actions.fetchSalesDeliveryHistorical(this.props.dossierId, resolve, reject)
              } else if (this.props.dossierType === dossierTypesConstant.registrationDelivery) {
                return this.props.actions.fetchRegistrationDeliveryDeliveryHistorical(this.props.dossierId, resolve, reject, this.props.pristine)
              }
            })
          }
        }
        break
      case buttonsSidebar.other_data:
        if (this.props.isOtherDataLoaded === false) {
          if (this.props.historical === false) {
            return new Promise((resolve, reject) => {
              if (this.props.dossierType === dossierTypesConstant.sale) {
                return this.props.actions.fetchSalesOtherData(this.props.dossierId, resolve, reject, this.props.pristine)
              } else if (this.props.dossierType === dossierTypesConstant.purchase) {
                return this.props.actions.fetchPurchaseOtherData(this.props.dossierId, resolve, reject, this.props.pristine)
              }
            })
          } else {
            return new Promise((resolve, reject) => {
              if (this.props.dossierType === dossierTypesConstant.sale) {
                return this.props.actions.fetchSalesOtherDataHistorical(this.props.dossierId, resolve, reject, this.props.pristine)
              } else if (this.props.dossierType === dossierTypesConstant.sale) {
                return this.props.actions.fetchPurchaseOtherDataHistorical(this.props.dossierId, resolve, reject, this.props.pristine)
              }
            })
          }
        }
        break
      case buttonsSidebar.self_supply:
        if (this.props.isSelfSupplyLoaded === false) {
          if (this.props.historical) {
            return new Promise((resolve, reject) => {
              return this.props.actions.fetchSalesSelfSupplyHistorical(this.props.dossierId, resolve, reject)
            })
          } else {
            return new Promise((resolve, reject) => {
              return this.props.actions.fetchSalesSelfSupply(this.props.dossierId, this.props.pristine, resolve, reject)
            })
          }
        }
        break
      case buttonsSidebar.dossierBudget:
        if (!this.props.isBudgetLoaded) {
          this.props.actions.getDossierBudget(this.props.dossierId, this.props.historical, this.props.pristine)
        }
        break
      default:
        break
    }
  }

  getLi ({ key, tKey, section, id }) {
    let activeStageElements = this.props.activeStage && this.props.activeStage.fields.concat(this.props.activeStage.others)
    let componentsConfiguration = this.props.tabConfiguration ? this.props.tabConfiguration.componentsConfiguration.filter((componentConf) => componentConf.code === section) : []
    componentsConfiguration = componentsConfiguration.length > 0 ? componentsConfiguration[0] : null
    if (!this.props.tabConfiguration || this.props.tabConfiguration.permission === permissions.hidden || !componentsConfiguration || componentsConfiguration.permission === permissions.hidden) {
      return null
    }

    const currentElements = activeStageElements && activeStageElements.filter(field => field.componentAction && field.componentAction === buttonsSidebarGetId[section])
    const status = (currentElements && currentElements.length > 0) ? !currentElements.find((field) => !field.isComplete) : null
    return (
      <li id={id} key={`${key}`} onClick={this.setActiveSection.bind(this, section)}><p>
        {this.props.t(`DOSSIER_COMPONENTS.BUTTONS_SIDEBAR.${tKey}`)}{status === true ? (<Glyphicon glyph="ok" />) : status === false ? <Glyphicon glyph="warning-sign" /> : ''}
        {id === 'services' && this.props.dossierType === dossierTypesConstant.sales && this.props.dossier?.dossierServiceRelateds?.rows?.filter(x => x.isFromPurchaseParent === true).length > 0 ? (<span className="pap_icon" >PAP</span>) : ''}
      </p></li>
    )
  }

  getLiList () {
    this.Li = (this.props.dossierType === dossierTypesConstant.sales && this.props.economicTab === true ? ([
      this.getLi({ key: '1', tKey: 'TOTALS', section: buttonsSidebar.totals, id: 'totals' }) ??
      this.getLi({ key: '1', tKey: 'TOTALS', section: buttonsSidebar.updatedTotals, id: 'totals' }),
      this.getLi({ key: '3', tKey: 'VEHICLE_AMOUNT', section: buttonsSidebar.vehicle_amount, id: 'vehicle_amount' }),
      this.getLi({ key: '6', tKey: 'OPTIONALS', section: buttonsSidebar.optional, id: 'optional' }),
      this.getLi({ key: '4', tKey: 'CAMPAIGN_DISCOUNT', section: buttonsSidebar.campaign_discount, id: 'campaign_discount' }),
      this.getLi({ key: '5', tKey: 'ADDITIONAL_DISCOUNT', section: buttonsSidebar.additional_discount, id: 'additional_discount' }),
      this.getLi({ key: '7', tKey: 'SALE_OTHERS', section: buttonsSidebar.sale_others, id: 'sale_others' }),
      this.getLi({ key: '2', tKey: 'TAXES', section: buttonsSidebar.taxes, id: 'taxes' }),
      this.getLi({ key: '8', tKey: 'SUPPLIED', section: buttonsSidebar.supplied, id: 'supplied' }),
      this.getLi({ key: '9', tKey: 'COSTS_GIFTS', section: buttonsSidebar.costs_gifts, id: 'costs_gifts' }),
      this.getLi({ key: '10', tKey: 'ASSOCIATED_COSTS', section: buttonsSidebar.associated_costs, id: 'associated_costs' }),
      this.getLi({ key: '11', tKey: 'REACONDITIONING', section: buttonsSidebar.reaconditioning, id: 'reaconditioning' })
    ]) : this.props.dossierType === dossierTypesConstant.sales && this.props.dossierSubTypes === dossierSubTypeIdConstants.venta_vo ? ([
      this.getLi({ key: '1', tKey: 'SALES', section: buttonsSidebar.bsale, id: 'bsale' }),
      this.getLi({ key: '12', tKey: 'FLEET', section: buttonsSidebar.fleet, id: 'fleet' }),
      this.getLi({ key: '2', tKey: 'CLIENT', section: buttonsSidebar.entity, id: 'entity' }),
      this.getLi({ key: '3', tKey: 'VEHICLE', section: buttonsSidebar.vehicle, id: 'vehicle' }),
      this.getLi({ key: '13', tKey: 'SELF_SUPPLY', section: buttonsSidebar.self_supply, id: 'self_supply' }),
      this.getLi({ key: '4', tKey: 'CAMPAIGNS', section: buttonsSidebar.campaign, id: 'campaign' }),
      this.getLi({ key: '5', tKey: 'UAC', section: buttonsSidebar.uac, id: 'uac' }),
      this.getLi({ key: '28', tKey: 'BROKERAGE', section: buttonsSidebar.brokerage, id: 'brokerage' }),
      this.getLi({ key: '6', tKey: 'FINANCE', section: buttonsSidebar.finance, id: 'finance' }),
      this.getLi({ key: '7', tKey: 'PAPERWORK', section: buttonsSidebar.paperwork, id: 'paperwork' }),
      this.getLi({ key: '8', tKey: 'CHARGES', section: buttonsSidebar.charge, id: 'charge' }),
      this.getLi({ key: '11', tKey: 'SERVICES', section: buttonsSidebar.services, id: 'services' }),
      this.getLi({ key: '9', tKey: 'OTHER_DATA', section: buttonsSidebar.other_data, id: 'other_data' }),
      this.getLi({ key: '10', tKey: 'DELIVERY', section: buttonsSidebar.delivery, id: 'delivery' }),
      this.props.isrefinance ? this.getLi({ key: '14', tKey: 'REFINANCE', section: buttonsSidebar.refinance, id: 'refinance' }) : null
    ]) : this.props.dossierType === dossierTypesConstant.sales && this.props.dossierSubTypes !== dossierSubTypeIdConstants.venta_vo ? ([
      this.getLi({ key: '1', tKey: 'SALES', section: buttonsSidebar.bsale, id: 'bsale' }),
      this.getLi({ key: '12', tKey: 'FLEET', section: buttonsSidebar.fleet, id: 'fleet' }),
      this.getLi({ key: '2', tKey: 'CLIENT', section: buttonsSidebar.entity, id: 'entity' }),
      this.getLi({ key: '3', tKey: 'VEHICLE', section: buttonsSidebar.vehicle, id: 'vehicle' }),
      this.getLi({ key: '13', tKey: 'SELF_SUPPLY', section: buttonsSidebar.self_supply, id: 'self_supply' }),
      this.getLi({ key: '4', tKey: 'CAMPAIGNS', section: buttonsSidebar.campaign, id: 'campaign' }),
      this.getLi({ key: '5', tKey: 'UAC', section: buttonsSidebar.uac, id: 'uac' }),
      this.getLi({ key: '28', tKey: 'BROKERAGE', section: buttonsSidebar.brokerage, id: 'brokerage' }),
      this.getLi({ key: '6', tKey: 'FINANCE', section: buttonsSidebar.finance, id: 'finance' }),
      this.getLi({ key: '7', tKey: 'PAPERWORK', section: buttonsSidebar.paperwork, id: 'paperwork' }),
      this.getLi({ key: '8', tKey: 'CHARGES', section: buttonsSidebar.charge, id: 'charge' }),
      this.getLi({ key: '11', tKey: 'SERVICES', section: buttonsSidebar.services, id: 'services' }),
      this.getLi({ key: '9', tKey: 'OTHER_DATA', section: buttonsSidebar.other_data, id: 'other_data' }),
      this.getLi({ key: '10', tKey: 'DELIVERY', section: buttonsSidebar.delivery, id: 'delivery' }),
      this.props.isrefinance ? this.getLi({ key: '14', tKey: 'REFINANCE', section: buttonsSidebar.refinance, id: 'refinance' }) : null

    ]) : this.props.dossierType === dossierTypesConstant.purchase && this.props.dossierSubTypes === dossierSubTypeIdConstants.compra_vo ? ([
      this.getLi({ key: '1', tKey: 'PURCHASE', section: buttonsSidebar.bpurchase, id: 'bpurchase' }),
      this.getLi({ key: '2', tKey: 'VENDOR', section: buttonsSidebar.entity, id: 'entity' }),
      this.getLi({ key: '3', tKey: 'VEHICLE', section: buttonsSidebar.vehicle, id: 'vehicle' }),
      this.getLi({ key: '4', tKey: 'PAPERWORK', section: buttonsSidebar.paperwork, id: 'paperwork' }),
      this.getLi({ key: '11', tKey: 'SERVICES', section: buttonsSidebar.services, id: 'services' }),
      this.getLi({ key: '6', tKey: 'OTHER_DATA', section: buttonsSidebar.other_data, id: 'other_data' })
    ]) : this.props.dossierType === dossierTypesConstant.purchase && this.props.dossierSubTypes !== dossierSubTypeIdConstants.compra_vo ? ([
      this.getLi({ key: '1', tKey: 'PURCHASE', section: buttonsSidebar.bpurchase, id: 'bpurchase' }),
      this.getLi({ key: '2', tKey: 'VENDOR', section: buttonsSidebar.entity, id: 'entity' }),
      this.getLi({ key: '3', tKey: 'VEHICLE', section: buttonsSidebar.vehicle, id: 'vehicle' }),
      this.getLi({ key: '4', tKey: 'PAPERWORK', section: buttonsSidebar.paperwork, id: 'paperwork' }),
      this.getLi({ key: '11', tKey: 'SERVICES', section: buttonsSidebar.services, id: 'services' })
    ]) : this.props.dossierType === dossierTypesConstant.paperwork ? ([
      this.getLi({ key: '1', tKey: 'PAPERWORK', section: buttonsSidebar.bpaperwork, id: 'bpaperwork' }),
      this.getLi({ key: '2', tKey: 'ENTITY', section: buttonsSidebar.entity, id: 'entity' }),
      this.getLi({ key: '3', tKey: 'VEHICLE', section: buttonsSidebar.vehicle, id: 'vehicle' })
    ]) : this.props.dossierType === dossierTypesConstant.campaign && this.props.dossierSubTypes === dossierSubTypeIdConstants.campanya_ayuda_especial ? ([
      this.getLi({ key: '1', tKey: 'SPECIAL_HELP', section: buttonsSidebar.bcampaign, id: 'bcampaign' }),
      this.getLi({ key: '2', tKey: 'ENTITY', section: buttonsSidebar.entity, id: 'entity' }),
      this.getLi({ key: '3', tKey: 'VEHICLE', section: buttonsSidebar.vehicle, id: 'vehicle' })
    ]) : this.props.dossierType === dossierTypesConstant.campaign && this.props.dossierSubTypes === dossierSubTypeIdConstants.campanya ? ([
      this.getLi({ key: '1', tKey: 'CAMPAIGNS', section: buttonsSidebar.bcampaign, id: 'bcampaign' }),
      this.getLi({ key: '2', tKey: 'ENTITY', section: buttonsSidebar.entity, id: 'entity' }),
      this.getLi({ key: '3', tKey: 'VEHICLE', section: buttonsSidebar.vehicle, id: 'vehicle' })
    ]) : this.props.dossierType === dossierTypesConstant.registrationDelivery ? ([
      this.getLi({ key: '1', tKey: 'REGISTRATION_DELIVERY', section: buttonsSidebar.bsale, id: 'bsale' }),
      this.getLi({ key: '2', tKey: 'CLIENT', section: buttonsSidebar.entity, id: 'entity' }),
      this.getLi({ key: '3', tKey: 'VEHICLE', section: buttonsSidebar.vehicle, id: 'vehicle' }),
      this.getLi({ key: '4', tKey: 'UAC', section: buttonsSidebar.uac, id: 'uac' }),
      this.getLi({ key: '5', tKey: 'PAPERWORK', section: buttonsSidebar.paperwork, id: 'paperwork' }),
      this.getLi({ key: '6', tKey: 'CHARGES', section: buttonsSidebar.charge, id: 'charge' }),
      this.getLi({ key: '7', tKey: 'DELIVERY', section: buttonsSidebar.delivery, id: 'delivery' })
    ]) : this.props.dossierType === dossierTypesConstant.service ? ([
      this.getLi({ key: '1', tKey: 'SERVICE', section: buttonsSidebar.bservice, id: 'bservice' }),
      this.getLi({ key: '2', tKey: 'VEHICLE', section: buttonsSidebar.vehicle, id: 'vehicle' })
    ]) : this.props.dossierType === dossierTypesConstant.stock ? ([
      this.getLi({ key: '1', tKey: 'VEHICLE', section: buttonsSidebar.vehicle, id: 'vehicle' }),
      this.getLi({ key: '29', tKey: 'ENTRY', section: buttonsSidebar.entry, id: 'entry' }),
      this.getLi({ key: '31', tKey: 'RECEPTION', section: buttonsSidebar.reception, id: 'reception' }),
      this.getLi({ key: '32', tKey: 'DOSSIER_RATING', section: buttonsSidebar.dossier_rating, id: 'dossier_rating' }),
      this.getLi({ key: '33', tKey: 'DOSSIER_BUDGET', section: buttonsSidebar.dossierBudget, id: 'dossierBudget' }),
      this.getLi({ key: '35', tKey: 'RECONDITIONING', section: buttonsSidebar.reconditioning, id: 'reconditioning' }),
      this.getLi({ key: '34', tKey: 'CLEANING', section: buttonsSidebar.cleaning, id: 'cleaning' }),
      this.getLi({ key: '36', tKey: 'CHECKLIST', section: buttonsSidebar.checklist, id: 'checklist' }),
      this.getLi({ key: '37', tKey: 'PHOTOGRAPHS', section: buttonsSidebar.photographs, id: 'photographs' }),
      this.getLi({ key: '39', tKey: 'CRANE', section: buttonsSidebar.crane, id: 'crane' }),
      this.getLi({ key: '38', tKey: 'PRICE_CHANGE', section: buttonsSidebar.price_change, id: 'price_change' }),
      this.getLi({ key: '40', tKey: 'BOOKING_AND_DELIVERY', section: buttonsSidebar.booking_and_delivery, id: 'booking_and_delivery' })
    ]) : '')
  }

  UNSAFE_componentWillMount () {
    this.getLiList()
  }

  componentDidUpdate (prevProps) {
    if (prevProps.tabConfiguration && prevProps.tabConfiguration.code !== this.props.tabConfiguration.code) {
      this.getLiList()
      this.setState({ needToUpdate: true })
    }
    if (this.props.dossierType === dossierTypesConstant.sales && !this.state.servicesLoaded && this.props.actions.fetchSalesServices) {
      let result = null
      if (!this.props.historical) {
        result = new Promise((resolve, reject) => {
          this.props.actions.fetchSalesServices(this.props.dossierId, false, resolve, reject)
          this.setState({ servicesLoaded: true })
        })
      } else {
        result = new Promise((resolve, reject) => {
          this.props.actions.fetchSalesServices(this.props.dossierId, true, resolve, reject)
          this.setState({ servicesLoaded: true })
        })
      }
    }
  }

  UNSAFE_componentWillReceiveProps () {
    this.getLiList()
  }

  render () {
    const {
      marginTop,
      buttonsHeaderHeight,
      paddingTop
    } = this.props
    return (
      <>
        <ul id="" className="inner-items" style={paddingTop && !isNaN(paddingTop) ? { paddingTop: paddingTop } : { top: (isNaN(marginTop) ? 0 : marginTop) + buttonsHeaderHeight }}>
          {this.Li}
        </ul>
      </>
    )
  }
}

export default ButtonsSidebar
