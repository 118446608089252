import React, { PureComponent } from 'react'
import { Redirect } from 'react-router-dom'
import { isAuthenticated } from '../ensureLogin'

class Redirection extends PureComponent {
  render () {
    const { sections } = this.props
    return (isAuthenticated(this.props.store) && sections && sections.length > 0) ? <Redirect to={'/' + sections[0].code} ></Redirect> : <Redirect to='/login'></Redirect>
  }
}

export default Redirection
