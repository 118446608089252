import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { closeRepairshopMasterModal, submitRepairshop } from '../../../actions/masters/repairshop/repairshop'
import RepairshopMasterModal from '../../../components/masters/repairshop/RepairshopMasterModal'

export function mapStateToProps (state) {
  const repairshopModal = state.repairshop.repairshopModal
  return {
    showModal: repairshopModal.showModal,
    repairshop: repairshopModal.repairshop,
    combos: {
      organizedUnitsAllCombo: state.combos.organizedUnitsAllCombo
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      submitRepairshop,
      closeRepairshopMasterModal
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(RepairshopMasterModal))
