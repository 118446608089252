import dossierRelatedDossiersActionTypes from '../../../constants/actions/dossier/common/dossierRelatedDossiers'

export function initialState () {
  return {
    orderby: null
  }
}

function fetchFilteredRelatedDossier (state, { orderby }) {
  return {
    ...state,
    orderby
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case dossierRelatedDossiersActionTypes.FETCH_DOSSIER_RELATED_DOSSIERS:
      return fetchFilteredRelatedDossier(state, action)
    default:
      return state
  }
}
