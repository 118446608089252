
import React, { PureComponent } from 'react'
import { Field, reduxForm } from 'redux-form'
import { Col, Button, Form, Glyphicon, Clearfix } from 'react-bootstrap'
import InputSelect from '../../commons/form/InputSelect'
import InputText from '../../commons/form/InputText'
import InputDatePicker from '../../commons/form/InputDatePicker'
import { documentEntityType } from '../../../constants/dossier/common/documentEntityType'
import { entityTypesId } from '../../../constants/backendIds'
import { validateDate } from '../../../util/validationFunctions'
import { multipleNormalize, toUpperCase, trimAll } from '../../../util/normalizeFunctions'
import settings from '../../../setting'
import { convertStringToDate } from '../../../util/utils'

class DocumentalSearchForm extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      documentEntityTypeId: props.dynamicFilters.documentEntityTypeId,
      disableTypeCombo: true,
      disableSubTypeCombo: true,
      ouIdSelected: null,
      vehicleInfo: true,
      electedEntityType: props.filters && props.filters.entityTypeId,
      selectedEntitySubType: props.filters && props.filters.entitySubTypeId
    }
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (this.props.formValues) {
      if (
        !prevProps.formValues || (prevProps.formValues.documentEntityTypeId && !this.props.formValues.documentEntityTypeId) ||
    prevProps.formValues.documentEntityTypeId !== this.props.formValues.documentEntityTypeId
      ) {
        this.onDocumentEntityTypeChanges(this.props.formValues.documentEntityTypeId)
      }
      if (
        !prevProps.formValues || (prevProps.formValues.entityTypeId && !this.props.formValues.entityTypeId) ||
      prevProps.formValues.entityTypeId !== this.props.formValues.entityTypeId
      ) {
        this.onEntityTypeChange(this.props.formValues.entityTypeId)
      }
    }
    return null
  }

  componentDidUpdate () {
    // getSnapshotBeforeUpdate() should be used with componentDidUpdate()
  }

  componentDidMount () {
    this.props.actions.fetchDocumentalSearchFormCombos(this.state.documentEntityTypeId)
    if (this.props.formValues !== undefined) {
      if (!this.props.formValues.documentTypeId && this.props.formValues.documentEntityTypeId) {
        this.onDocumentEntityTypeChanges(this.props.formValues.documentEntityTypeId)
      }

      if (this.props.formValues.documentEntityTypeId === documentEntityType.ENTITY && this.props.formValues.entityTypeId) {
        this.onEntityTypeChange(this.props.formValues.entityTypeId)
      }
    }
  }

  getEntityNameLabel () {
    const tKey = 'DOCUMENT_SEARCH.'
    switch (this.state.selectedEntityType) {
      case entityTypesId.privateEntity:
        return this.props.t(`${tKey}NAME`)
      default:
        return this.props.t(`${tKey}REGISTERED_NAME`)
    }
  }

  dateValidation (value, allValues, props) {
    const result = validateDate(value, props)
    return result === Object(result) ? undefined : result
  }

  onDocumentEntityTypeChanges (documentEntityTypeId) {
    if (documentEntityTypeId === null) {
      this.props.actions.fetchDocumentalSearchFormCombos(this.state.documentEntityTypeId)
    }
    if (documentEntityTypeId === documentEntityType.ENTITY || documentEntityTypeId === null) {
      this.props.change('documentTypeId', null)
      /* vehiculo */
      this.props.change('vehicleBrandId', null)
      this.props.change('comercialCode', null)
      this.props.change('licensePlateDateFrom', null)
      this.props.change('vehicleLicensePlate', null)
      this.props.change('licensePlateDateTo', null)
      this.props.change('vehicleModel', null)
      this.props.change('vehicleVinChasis', null)
      /* dossier */
      this.props.change('number', null)
      this.props.change('organizedUnitId', null)
      this.props.change('dossierSubTypeId', null)
      this.props.change('dossierStageId', null)
      this.props.change('orderDateFrom', null)
      this.props.change('orderDateTo', null)
      this.props.change('creationDossierDateTo', null)
      this.props.change('creationDossierDateFrom', null)
      this.props.change('dealerNumber', null)
    }
    if (documentEntityTypeId === documentEntityType.VEHICLE || documentEntityTypeId === null) {
      this.props.change('documentTypeId', null)
      /* cliente */
      this.onEntityTypeChange(null)
      this.props.change('entityTypeId', null)
      this.props.change('entityCifNif', null)
      this.props.change('entityName', null)
      this.props.change('entitySurname1', null)
      this.props.change('entitySurname2', null)
      this.props.change('representativeDni', null)
      this.props.change('representativeName', null)
      this.props.change('representativeSurname1', null)
      this.props.change('representativeSurname2', null)
      this.props.change('entityMail', null)
      this.props.change('entityPhone', null)
      this.props.change('entityAddress', null)
      /* dossier */
      this.props.change('number', null)
      this.props.change('organizedUnitId', null)
      this.props.change('dossierSubTypeId', null)
      this.props.change('dossierStageId', null)
      this.props.change('orderDateFrom', null)
      this.props.change('orderDateTo', null)
      this.props.change('creationDossierDateTo', null)
      this.props.change('creationDossierDateFrom', null)
      this.props.change('dealerNumber', null)
      this.props.change('load', null)
    }
    if (documentEntityTypeId === documentEntityType.DOSSIER || documentEntityTypeId === null) {
      this.props.change('documentTypeId', null)
      /* cliente */
      this.onEntityTypeChange(null)
      this.props.change('entityTypeId', null)
      this.props.change('entityCifNif', null)
      this.props.change('entityName', null)
      this.props.change('entitySurname1', null)
      this.props.change('entitySurname2', null)
      this.props.change('representativeDni', null)
      this.props.change('representativeName', null)
      this.props.change('representativeSurname1', null)
      this.props.change('representativeSurname2', null)
      this.props.change('entityMail', null)
      this.props.change('entityPhone', null)
      this.props.change('entityAddress', null)
      /* vehiculo */
      this.props.change('vehicleBrandId', null)
      this.props.change('comercialCode', null)
      this.props.change('licensePlateDateFrom', null)
      this.props.change('vehicleLicensePlate', null)
      this.props.change('licensePlateDateTo', null)
      this.props.change('vehicleModel', null)
      this.props.change('vehicleVinChasis', null)
    }
    this.setState({ documentEntityTypeId })
    if (documentEntityTypeId !== null) {
      this.props.actions.fetchDocumentalSearchFormCombos(documentEntityTypeId)
    }
  }

  onEntitySubTypeChange (entitySubTypeId) {
    this.setState({ selectedEntitySubType: entitySubTypeId })
    if (entitySubTypeId) this.props.actions.fetchEntityFilterSubTypeCombo(this.state.selectedEntityType, entitySubTypeId)
  }

  onEntityTypeChange (entityTypeId) {
    this.setState({ selectedEntityType: entityTypeId })

    if (entityTypeId === entityTypesId.recurrentEntity) {
      this.props.actions.fetchEntitySubTypeCombo(entityTypeId)
      this.props.change('entityCifNif', null)
      this.props.change('entityName', null)
      this.props.change('entitySurname1', null)
      this.props.change('entitySurname2', null)
      this.props.change('representativeDni', null)
      this.props.change('representativeName', null)
      this.props.change('representativeSurname1', null)
      this.props.change('representativeSurname2', null)
      this.props.change('entityEmail', null)
      this.props.change('entityPhone', null)
      this.props.change('entityAddress', null)
    } else {
      this.props.change('entitySubTypeId', null)
      this.props.change('entityId', null)
      if (entityTypeId !== entityTypesId.privateEntity) {
        this.props.change('entitySurname1', null)
        this.props.change('entitySurname2', null)
      } else if (entityTypeId !== entityTypesId.businessEntity) {
        this.props.change('representativeDni', null)
        this.props.change('representativeName', null)
        this.props.change('representativeSurname1', null)
        this.props.change('representativeSurname2', null)
      }
    }
  }

  submitDocumentalSearchForm (values) {
    if ((values.documentEntityTypeId === documentEntityType.ENTITY) && (!values.entityName && !values.entityCifNif && !values.entityMail && !values.entityPhone && !values.entityAddress && !values.representativeName && !values.representativeDni && !values.entityTypeId)) {
      return this.props.actions.openModal('mustFillFiltersClient')
    }
    if ((values.documentEntityTypeId === documentEntityType.VEHICLE) && (!values.vehicleBrandId && !values.vehicleModel && !values.vehicleVinChasis && !values.vehicleLicensePlate &&
      !values.licensePlateDateFrom && !values.licensePlateDateTo && !values.comercialCode)) {
      return this.props.actions.openModal('mustFillFiltersVehicle')
    }
    if ((values.documentEntityTypeId === documentEntityType.DOSSIER) && (!values.number && !values.organizedUnitId && !values.dossierSubTypeId && !values.orderDateFrom && !values.orderDateTo && !values.dealerNumber && !values.load && !values.creationDossierDateFrom && !values.creationDossierDateTo)) {
      return this.props.actions.openModal('mustFillFiltersDossier')
    }
    this.props.actions.findFilterDocuments({ ...values, ...this.props.filters })
  }

  handleOrganizedUnitChange (ouId) {
    this.props.change('dossierTypes', null)
    this.props.change('dossierSubTypes', null)
    if (ouId === null) {
      this.setState({ disableTypeCombo: true, disableSubTypeCombo: true, ouIdSelected: ouId })
    } else {
      this.setState({ disableTypeCombo: false, disableSubTypeCombo: true, ouIdSelected: ouId })
      this.props.actions.fetchDossierTypes(ouId)
    }
  }

  handleDossierTypeChange (dossierTypeId) {
    this.props.change('dossierSubTypes', null)
    if (dossierTypeId === null) {
      this.setState({ disableSubTypeCombo: true })
    } else {
      this.setState({ disableSubTypeCombo: false })
      this.props.actions.fetchDossierSubTypes(this.state.ouIdSelected, dossierTypeId)
    }
  }

  onDossierSubtypeChange (dossierSubtypeId) {
    this.props.change('dossierStageId', null)
    this.props.actions.fetchStagesCombo(dossierSubtypeId)
  }

  onDossierStageChange (value) {
    if (!value || value.length === 0) {
      this.props.change('dossierStageId', null)
    }
  }

  render () {
    const {
      t, handleSubmit, initialize, pristine,
      combos: {
        documentsTypesCombo,
        entityTypesCombo,
        searchDossierSubtypesCombo,
        stagesCombo,
        lowestOrganizedUnitsCombo,
        brandCombo,
        documentStatusCombo,
        entitySubTypeCombo,
        entityFilterSubTypeCombo
      },
      fields,
      fieldFill,
      resetFields
    } = this.props

    const tKey = 'DOCUMENT_SEARCH.'
    const className = 'filled'
    const classNameSelect = 'filledSelect'

    let documentEntityTypeCombo = this.props.combos.documentEntityTypeCombo
    documentEntityTypeCombo = documentEntityTypeCombo.map((documentEntityType) => {
      return { ...documentEntityType, value: t(`${tKey}COMBOS.DOCUMENT_ENTITY_TYPE.${documentEntityType.value}`) }
    })

    return (
      <Form autoComplete="off" className="filters" onSubmit={handleSubmit(this.submitDocumentalSearchForm.bind(this))}>
        <div className="general-form-search form-search-box">
          <h4><Glyphicon glyph="filter" /> {t(`${tKey}GENERAL_FILTERS`)}</h4>
          <Field
            colSm={3}
            id="documentEntityTypeId"
            name="documentEntityTypeId"
            controlLabel={t(`${tKey}SEARCH_DOCUMENTS_IN`)}
            placeholder={t(`${tKey}SEARCH_DOCUMENTS_INPUT`)}
            component={InputSelect}
            options={documentEntityTypeCombo}
            valueKey="id"
            labelKey="value"
            onInputChange={(val) => { this.onDocumentEntityTypeChanges.bind(this); fieldFill({ documentEntityTypeId: val }) }}
            customClass={fields.documentEntityTypeId ? classNameSelect : ''}
          />
          <Field
            colSm={3}
            id="documentTypeId"
            name="documentTypeId"
            controlLabel={t(`${tKey}DOCUMENT_TYPE`)}
            placeholder={t(`${tKey}DOCUMENT_TYPE`)}
            component={InputSelect}
            options={documentsTypesCombo}
            valueKey="id"
            labelKey="value"
            onInputChange={(val) => { fieldFill({ documentTypeId: val }) }}
            customClass={fields.documentTypeId ? classNameSelect : ''}
          />
          <Field
            colSm={3}
            id="documentStatusId"
            name="documentStatusId"
            controlLabel={t(`${tKey}STATE`)}
            placeholder={t(`${tKey}STATE`)}
            component={InputSelect}
            options={documentStatusCombo}
            valueKey="id"
            labelKey="value"
            onInputChange={(val) => { fieldFill({ documentStatusId: val }) }}
            customClass={fields.documentStatusId ? classNameSelect : ''}
          />
          <Clearfix />
          <Field
            colSm={3}
            id="dossierCreationDateFrom"
            name="creationDateFrom"
            controlLabel={t(`${tKey}CREATION_DATE_FROM`)}
            placeholder={t(`${tKey}SINCE`)}
            component={InputDatePicker}
            validate={[this.dateValidation]}
            onInputChange={(val) => fieldFill({ dossierCreationDateFrom: val })}
            customClass={fields.dossierCreationDateFrom ? className : ''}
          />
          <Field
            colSm={3}
            id="dossierCreationDateTo"
            name="creationDateTo"
            controlLabel={t(`${tKey}CREATION_DATE_TO`)}
            component={InputDatePicker}
            validate={[this.dateValidation]}
            onInputChange={(val) => fieldFill({ dossierCreationDateTo: val })}
            customClass={fields.dossierCreationDateTo ? className : ''}
          />
          <Field
            colSm={3}
            id="dossierValidationDateFrom"
            name="validationDateFrom"
            controlLabel={t(`${tKey}VALIDATION_DATE_FROM`)}
            placeholder={t(`${tKey}SINCE`)}
            component={InputDatePicker}
            validate={[this.dateValidation]}
            onInputChange={(val) => fieldFill({ dossierValidationDateFrom: val })}
            customClass={fields.dossierValidationDateFrom ? className : ''}
          />
          <Field
            colSm={3}
            id="dossierValidationDateTo"
            name="validationDateTo"
            controlLabel={t(`${tKey}VALIDATION_DATE_TO`)}
            component={InputDatePicker}
            validate={[this.dateValidation]}
            onInputChange={(val) => fieldFill({ dossierValidationDateTo: val })}
            customClass={fields.dossierValidationDateTo ? className : ''}
          />
          <Clearfix />
          {
            (this.props.formValues === undefined || Object.keys(this.props.formValues).length === 0 || (
              !this.props.formValues.documentEntityTypeId && !this.props.formValues.documentTypeId &&
              !this.props.formValues.documentStatusId && !this.props.formValues.creationDateFrom &&
              !this.props.formValues.creationDateTo && !this.props.formValues.validationDateFrom &&
              !this.props.formValues.validationDateTo
            )) && (
              <span className="info-general-filer"><Glyphicon glyph="info-sign" /> {t(`${tKey}SOME_GENERAL_FILTER`)}</span>
            )
          }
        </div>
        {
          this.state.documentEntityTypeId === documentEntityType.VEHICLE && (
            <div className="form-search-box vehicle-form-search">
              <h4><i className="ico-car" /> {t(`${tKey}VEHICLE_FILTERS`)}</h4>
              <Field
                colSm={3}
                id="vehicleBrand"
                name="vehicleBrandId"
                controlLabel={t(`${tKey}BRAND`)}
                placeholder={t(`${tKey}BRAND`)}
                component={InputSelect}
                options={brandCombo}
                valueKey="id"
                labelKey="value"
                onInputChange={(val) => { fieldFill({ vehicleBrand: val }) }}
                customClass={fields.vehicleBrand ? classNameSelect : ''}
              />
              <Field
                colSm={3}
                id="vehicleModel"
                name="vehicleModel"
                controlLabel={t(`${tKey}MODEL`)}
                placeholder={t(`${tKey}MODEL`)}
                component={InputText}
                onInputChange={(val) => fieldFill({ vehicleModel: val })}
                customClass={fields.vehicleModel ? className : ''}
              />
              <Field
                colSm={3}
                id="vehicleChassisNumber"
                name="vehicleVinChasis"
                controlLabel={t(`${tKey}CHASSIS`)}
                placeholder={t(`${tKey}CHASSIS`)}
                component={InputText}
                maxLength={17}
                normalize={value => multipleNormalize(value, [toUpperCase, trimAll])}
                onInputChange={(val) => fieldFill({ vehicleChassisNumber: val })}
                customClass={fields.vehicleChassisNumber ? className : ''}
              />
              <Field
                colSm={3}
                id="vehicleNumberPlate"
                name="vehicleLicensePlate"
                controlLabel={t(`${tKey}LICENSE_PLATE`)}
                placeholder={t(`${tKey}LICENSE_PLATE`)}
                component={InputText}
                normalize={value => multipleNormalize(value, [toUpperCase, trimAll])}
                onInputChange={(val) => fieldFill({ vehicleNumberPlate: val })}
                customClass={fields.vehicleNumberPlate ? className : ''}
              />
              <Clearfix />
              <Field
                colSm={3}
                id="comercialCode"
                name="comercialCode"
                controlLabel={t(`${tKey}COMMERCIAL_CODE`)}
                placeholder={t(`${tKey}COMMERCIAL_CODE`)}
                component={InputText}
                onInputChange={(val) => fieldFill({ comercialCode: val })}
                customClass={fields.comercialCode ? className : ''}
              />
              <Field
                colSm={3}
                id="licensePlateDateFrom"
                name="licensePlateDateFrom"
                controlLabel={t(`${tKey}FIRST_LPLATE_DATE_FROM`)}
                placeholder={t(`${tKey}SINCE`)}
                component={InputDatePicker}
                validate={[this.dateValidation]}
                forceControlLabelInOneLine={true}
                onInputChange={(val) => fieldFill({ licensePlateDateFrom: val })}
                customClass={fields.licensePlateDateFrom ? className : ''}
              />
              <Field
                colSm={3}
                id="licensePlateDateTo"
                name="licensePlateDateTo"
                controlLabel={t(`${tKey}FIRST_LPLATE_DATE_TO`)}
                component={InputDatePicker}
                validate={[this.dateValidation]}
                onInputChange={(val) => fieldFill({ licensePlateDateTo: val })}
                customClass={fields.licensePlateDateTo ? className : ''}
              />
              <Clearfix />
              {
                (this.props.formValues === undefined || Object.keys(this.props.formValues).length === 0 || (
                  !this.props.formValues.vehicleBrandId && !this.props.formValues.comercialCode &&
                  !this.props.formValues.licensePlateDateFrom && !this.props.formValues.vehicleLicensePlate &&
                  !this.props.formValues.licensePlateDateTo && !this.props.formValues.vehicleModel &&
                  !this.props.formValues.vehicleVinChasis
                )) && (
                  <span className="info-general-filer"><Glyphicon glyph="info-sign" /> {t(`${tKey}SOME_GENERAL_FILTER`)}</span>
                )
              }
            </div>
          )
        }

        {
          this.state.documentEntityTypeId === documentEntityType.ENTITY && (
            <div className="form-search-box client-form-search">
              <h4><i className="ico-user" /> {t(`${tKey}CLIENT_FILTERS`)}</h4>
              <Field
                colSm={3}
                id="entityTypeId"
                name="entityTypeId"
                controlLabel={t(`${tKey}CLIENT_TYPE`)}
                placeholder={t(`${tKey}CLIENT_TYPE`)}
                component={InputSelect}
                options={entityTypesCombo}
                valueKey="id"
                labelKey="value"
                onInputChange={(val) => { this.onEntityTypeChange(val); fieldFill({ entityTypeId: val }) }}
                customClass={fields.entityTypeId ? classNameSelect : ''}
              />
              {(this.state.selectedEntityType === entityTypesId.recurrentEntity) && [
                <Field
                  colSm={3}
                  id="entitySubTypeId"
                  name="entitySubTypeId"
                  key="entitySubTypeId"
                  controlLabel={t(`${tKey}ENTITY_SUB_TYPE_ID`)}
                  placeholder={t(`${tKey}ENTITY_SUB_TYPE_ID`)}
                  component={InputSelect}
                  options={entitySubTypeCombo}
                  valueKey="id"
                  labelKey="value"
                  onInputChange={(val) => { this.onEntitySubTypeChange(val); fieldFill({ entitySubTypeId: val }) }}
                  customClass={fields.entitySubTypeId ? className : ''}
                />,
                <Field
                  colSm={3}
                  id="entityId"
                  name="entityId"
                  key="entityId"
                  controlLabel={t(`${tKey}ENTITY_ID`)}
                  placeholder={t(`${tKey}ENTITY_ID`)}
                  component={InputSelect}
                  options={entityFilterSubTypeCombo}
                  valueKey="id"
                  labelKey="value"
                  disabled={!this.state.selectedEntitySubType}
                  onInputChange={(val) => fieldFill({ entityId: val })}
                  customClass={fields.entityId ? className : ''}
                />
              ]}
              {(this.state.selectedEntityType === entityTypesId.privateEntity || this.state.selectedEntityType === entityTypesId.businessEntity) && [
                <Field
                  colSm={3}
                  id="entityCifNif"
                  name="entityCifNif"
                  key="entityCifNif"
                  controlLabel={t(`${tKey}DNI`)}
                  placeholder={t(`${tKey}DNI`)}
                  component={InputText}
                  onInputChange={(val) => fieldFill({ entityCifNif: val })}
                  customClass={fields.entityCifNif ? className : ''}
                />,
                <Field
                  colSm={3}
                  id="entityName"
                  name="entityName"
                  key="entityName"
                  controlLabel={this.getEntityNameLabel()}
                  placeholder={this.getEntityNameLabel()}
                  component={InputText}
                  onInputChange={(val) => fieldFill({ entityName: val })}
                  customClass={fields.entityName ? className : ''}
                />
              ]}
              {this.state.selectedEntityType === entityTypesId.privateEntity && [
                <Field
                  colSm={3}
                  id="entitySurname1"
                  name="entitySurname1"
                  key="entitySurname1"
                  controlLabel={t(`${tKey}SURNAME1`)}
                  placeholder={t(`${tKey}SURNAME1`)}
                  component={InputText}
                  onInputChange={(val) => fieldFill({ entitySurname1: val })}
                  customClass={fields.entitySurname1 ? className : ''}
                />,
                <Clearfix key="clearfix_entitySurname1" />,
                <Field
                  colSm={3}
                  id="entitySurname2"
                  name="entitySurname2"
                  key="entitySurname2"
                  controlLabel={t(`${tKey}SURNAME2`)}
                  placeholder={t(`${tKey}SURNAME2`)}
                  component={InputText}
                  onInputChange={(val) => fieldFill({ entitySurname2: val })}
                  customClass={fields.entitySurname2 ? className : ''}
                />
              ]}
              {this.state.selectedEntityType === entityTypesId.businessEntity && [
                <Field
                  colSm={3}
                  id="representativeDni"
                  name="representativeDni"
                  key="representativeDni"
                  controlLabel={t(`${tKey}LEGAL_REPRESENTATIVE_DNI`)}
                  placeholder={t(`${tKey}LEGAL_REPRESENTATIVE_DNI`)}
                  component={InputText}
                  onInputChange={(val) => fieldFill({ representativeDni: val })}
                  customClass={fields.representativeDni ? className : ''}
                />,
                <Clearfix key="clearfix_entitySurname1" />,
                <Field
                  colSm={3}
                  id="representativeName"
                  name="representativeName"
                  key="representativeName"
                  controlLabel={t(`${tKey}LEGAL_REPRESENTATIVE_NAME`)}
                  placeholder={t(`${tKey}LEGAL_REPRESENTATIVE_NAME`)}
                  component={InputText}
                  forceControlLabelInOneLine={true}
                  onInputChange={(val) => fieldFill({ representativeName: val })}
                  customClass={fields.representativeName ? className : ''}
                />,
                <Field
                  colSm={3}
                  id="representativeSurname1"
                  name="representativeSurname1"
                  key="representativeSurname1"
                  controlLabel={t(`${tKey}LEGAL_REPRESENTATIVE_SURNAME1`)}
                  placeholder={t(`${tKey}LEGAL_REPRESENTATIVE_SURNAME1`)}
                  component={InputText}
                  forceControlLabelInOneLine={true}
                  onInputChange={(val) => fieldFill({ representativeSurname1: val })}
                  customClass={fields.representativeSurname1 ? className : ''}
                />,
                <Field
                  colSm={3}
                  id="representativeSurname2"
                  name="representativeSurname2"
                  key="representativeSurname2"
                  controlLabel={t(`${tKey}LEGAL_REPRESENTATIVE_SURNAME2`)}
                  placeholder={t(`${tKey}LEGAL_REPRESENTATIVE_SURNAME2`)}
                  component={InputText}
                  forceControlLabelInOneLine={true}
                  onInputChange={(val) => fieldFill({ representativeSurname2: val })}
                  customClass={fields.representativeSurname2 ? className : ''}
                />
              ]}
              {(this.state.selectedEntityType !== entityTypesId.recurrentEntity) && [
                <Field
                  colSm={3}
                  id="entityMail"
                  name="entityMail"
                  key="entityMail"
                  controlLabel={t(`${tKey}EMAIL`)}
                  placeholder={t(`${tKey}EMAIL`)}
                  component={InputText}
                  onInputChange={(val) => fieldFill({ entityMail: val })}
                  customClass={fields.entityMail ? className : ''}
                />,
                this.state.selectedEntityType === entityTypesId.businessEntity && <Clearfix key="clearfix_entityMail" />,
                <Field
                  colSm={3}
                  id="entityPhone"
                  name="entityPhone"
                  key="entityPhone"
                  controlLabel={t(`${tKey}TELEPHONE`)}
                  placeholder={t(`${tKey}TELEPHONE`)}
                  component={InputText}
                  onInputChange={(val) => fieldFill({ entityPhone: val })}
                  customClass={fields.entityPhone ? className : ''}
                />,
                <Field
                  colSm={3}
                  id="entityAddress"
                  name="entityAddress"
                  key="entityAddress"
                  controlLabel={t(`${tKey}DIRECCTION`)}
                  placeholder={t(`${tKey}DIRECCTION`)}
                  component={InputText}
                  onInputChange={(val) => fieldFill({ entityAddress: val })}
                  customClass={fields.entityAddress ? className : ''}
                />
              ]}
              <Clearfix />
              {
                (this.props.formValues === undefined || Object.keys(this.props.formValues).length === 0 || (
                  !this.props.formValues.entityTypeId && !this.props.formValues.entityCifNif &&
                  !this.props.formValues.entityName && !this.props.formValues.entitySurname1 &&
                  !this.props.formValues.entitySurname2 && !this.props.formValues.representativeDni &&
                  !this.props.formValues.representativeName && !this.props.formValues.representativeSurname1 &&
                  !this.props.formValues.representativeSurname2 && !this.props.formValues.entityMail &&
                  !this.props.formValues.entityPhone && !this.props.formValues.entityAddress
                )) && (
                  <span className="info-general-filer"><Glyphicon glyph="info-sign" /> {t(`${tKey}SOME_GENERAL_FILTER`)}</span>
                )
              }
            </div>
          )
        }

        {
          this.state.documentEntityTypeId === documentEntityType.DOSSIER && (
            <div className="form-search-box dossier-form-search">
              <h4><i className="ico-docs" /> {t(`${tKey}DOSSIER_FILTERS`)}</h4>
              <Field
                colSm={3}
                id="number"
                name="number"
                controlLabel={t(`${tKey}DOSSIER_NUMBER`)}
                placeholder={t(`${tKey}DOSSIER_NUMBER`)}
                component={InputText}
                onInputChange={(val) => fieldFill({ number: val })}
                customClass={fields.number ? className : ''}
              />
              <Field
                colSm={3}
                id="organizedUnitId"
                name="organizedUnitId"
                controlLabel={t(`${tKey}UO`)}
                placeholder={t(`${tKey}UO`)}
                component={InputSelect}
                options={lowestOrganizedUnitsCombo}
                valueKey="id"
                labelKey="value"
                onInputChange={(val) => { fieldFill({ organizedUnitId: val }) }}
                customClass={fields.organizedUnitId ? classNameSelect : ''}
              />
              <Field
                colSm={3}
                id="dossierSubtype"
                name="dossierSubTypeId"
                controlLabel={t(`${tKey}DOSSIER_SUBTYPE`)}
                placeholder={t(`${tKey}DOSSIER_SUBTYPE`)}
                component={InputSelect}
                options={searchDossierSubtypesCombo}
                valueKey="id"
                labelKey="value"
                onInputChange={(val) => { this.onDossierSubtypeChange(val); fieldFill({ dossierSubtype: val }) }}
                customClass={fields.dossierSubtype ? classNameSelect : ''}
              />
              <Field
                colSm={3}
                id="dossierStage"
                name="dossierStageId"
                controlLabel={t(`${tKey}STAGE`)}
                placeholder={t(`${tKey}STAGE`)}
                component={InputSelect}
                multi
                menuContainerStyle={{ zIndex: 999 }}
                options={stagesCombo}
                valueKey="id"
                labelKey="value"
                disabled={stagesCombo.length === 0}
                onInputChange={(val) => { fieldFill({ dossierStage: val }); this.onDossierStageChange(val) }}
                customClass={fields.dossierStage ? classNameSelect : ''}
              />
              <Clearfix />
              <Field
                colSm={3}
                id="orderDateFrom"
                name="orderDateFrom"
                controlLabel={t(`${tKey}ORDER_DATE_FROM`)}
                placeholder={t(`${tKey}SINCE`)}
                component={InputDatePicker}
                validate={[this.dateValidation]}
                onInputChange={(val) => fieldFill({ orderDateFrom: val })}
                customClass={fields.orderDateFrom ? className : ''}
              />
              <Field
                colSm={3}
                id="orderDateTo"
                name="orderDateTo"
                controlLabel={t(`${tKey}ORDER_DATE_TO`)}
                component={InputDatePicker}
                validate={[this.dateValidation]}
                onInputChange={(val) => fieldFill({ orderDateTo: val })}
                customClass={fields.orderDateTo ? className : 'date-margin '}
              />
              <Field
                colSm={3}
                id="creationDossierDateFrom"
                name="creationDossierDateFrom"
                controlLabel={t(`${tKey}CREATION_DATE_FROM`)}
                placeholder={t(`${tKey}SINCE`)}
                component={InputDatePicker}
                validate={[this.dateValidation]}
                onInputChange={(val) => fieldFill({ creationDossierDateFrom: val })}
                customClass={fields.creationDossierDateFrom ? className : ''}
              />
              <Field
                colSm={3}
                id="creationDossierDateTo"
                name="creationDossierDateTo"
                controlLabel={t(`${tKey}CREATION_DATE_TO`)}
                component={InputDatePicker}
                validate={[this.dateValidation]}
                onInputChange={(val) => fieldFill({ creationDossierDateTo: val })}
                customClass={fields.creationDossierDateTo ? className : 'date-margin '}
              />
              <Clearfix />
              <Field
                colSm={3}
                id="dealerNumber"
                name="dealerNumber"
                controlLabel={t(`${tKey}DEALER_NUMBER`)}
                placeholder={t(`${tKey}DEALER_NUMBER`)}
                component={InputText}
                maxLength={settings.dealerMaxLength}
                onInputChange={(val) => fieldFill({ dealerNumber: val })}
                customClass={fields.dealerNumber ? className : ''}
              />
              <Clearfix />
              {
                !this.props.formValues || (!this.props.formValues.number && !this.props.formValues.organizedUnitId && !this.props.formValues.dossierSubTypeId && !this.props.formValues.orderDateFrom && !this.props.formValues.orderDateTo &&
                  !this.props.formValues.creationDossierDateFrom && !this.props.formValues.creationDossierDateTo && !this.props.formValues.dealerNumber && !this.props.formValues.load) && (
                  <span className="info-general-filer"><Glyphicon glyph="info-sign" /> {t(`${tKey}SOME_GENERAL_FILTER`)}</span>
                )
              }
            </div>
          )
        }
        <Col sm={12} className="filters-footer">
          <hr />
          {
            this.props.formValues !== undefined ? (
              (!this.props.formValues.documentEntityTypeId && !this.props.formValues.documentTypeId && !this.props.formValues.documentStatusId && !this.props.formValues.creationDateFrom && !this.props.formValues.creationDateTo && !this.props.formValues.validationDateFrom && !this.props.formValues.validationDateTo) ? (
                <Button bsStyle="primary m-right" className={'btn-white btn-icon btn-cancel'}><Glyphicon glyph="search" /> {t(`${tKey}ACTIONS.SEARCH_BUTTON`)}</Button>
              ) : (
                <Button bsStyle="primary m-right" className={'btn-white btn-icon' + (pristine ? ' btn-cancel' : ' btn-standard')} type="submit"><Glyphicon glyph="search" /> {t(`${tKey}ACTIONS.SEARCH_BUTTON`)}</Button>
              )
            ) : (
              <Button bsStyle="primary m-right" className={'btn-white btn-icon btn-cancel'}><Glyphicon glyph="search" /> {t(`${tKey}ACTIONS.SEARCH_BUTTON`)}</Button>
            )
          }
          <Button
            onClick={(e) => {
              if (!pristine) {
                initialize({})
                resetFields()
              }
            }}
            className={'btn-white btn-icon' + (pristine ? ' btn-cancel' : ' btn-standard')}
          >
            <i className="ico-cancel" /> {t(`${tKey}ACTIONS.RESET_BUTTON`)}
          </Button>
        </Col>
      </Form>
    )
  }
}

const validate = (values, { t }, props) => {
  const error = {}
  if (values && values.creationDateFrom && values.creationDateTo) {
    let dateTo = convertStringToDate(values.creationDateTo)
    let dateFrom = convertStringToDate(values.creationDateFrom)
    if (dateFrom > dateTo) {
      error.creationDateTo = t('GLOBAL.FORMS.DATE_TO_NOT_LESS_THAN_DATE_FROM')
    }
  }
  if (values && values.validationDateFrom && values.validationDateTo) {
    let dateTo = convertStringToDate(values.validationDateTo)
    let dateFrom = convertStringToDate(values.validationDateFrom)
    if (dateFrom > dateTo) {
      error.validationDateTo = t('GLOBAL.FORMS.DATE_TO_NOT_LESS_THAN_DATE_FROM')
    }
  }

  if (values && values.orderDateFrom && values.orderDateTo) {
    let dateTo = convertStringToDate(values.orderDateTo)
    let dateFrom = convertStringToDate(values.orderDateFrom)
    if (dateFrom > dateTo) {
      error.orderDateTo = t('GLOBAL.FORMS.DATE_TO_NOT_LESS_THAN_DATE_FROM')
    }
  }

  if (values && values.creationDossierDateFrom && values.creationDossierDateTo) {
    let dateTo = convertStringToDate(values.creationDossierDateTo)
    let dateFrom = convertStringToDate(values.creationDossierDateFrom)
    if (dateFrom > dateTo) {
      error.creationDossierDateTo = t('GLOBAL.FORMS.DATE_TO_NOT_LESS_THAN_DATE_FROM')
    }
  }

  if (values && values.licensePlateDateFrom && values.licensePlateDateTo) {
    let dateTo = convertStringToDate(values.licensePlateDateTo)
    let dateFrom = convertStringToDate(values.licensePlateDateFrom)
    if (dateFrom > dateTo) {
      error.licensePlateDateTo = t('GLOBAL.FORMS.DATE_TO_NOT_LESS_THAN_DATE_FROM')
    }
  }
  return error
}

export default reduxForm({
  form: 'documentalSearchForm',
  destroyOnUnmount: false,
  validate
})(DocumentalSearchForm)
