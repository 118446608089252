
import React, { PureComponent } from 'react'
import { Modal, Col, Row, FormGroup, Button } from 'react-bootstrap'
import { reduxForm, Field } from 'redux-form'
import InputDatePicker from '../../commons/form/InputDatePicker'
import InputTreeSelect from '../../commons/form/InputTreeSelect'
import EditableTable from '../../commons/editableTable/EditableTable'

class ProcessingAgencyAssignationModal extends PureComponent {
  constructor () {
    super()
    this.state = {
      uoId: null
    }
  }

  submitAgency (values) {
    const body = {
      ...this.props.quota,
      startDate: values.startDate,
      endDate: values.endDate,
      previousStartDate: this.props.quota.startDate,
      previousEndDate: this.props.quota.endDate
    }
    this.props.actions.submitProcessingAgencyAssignation(body)
  }

  componentDidUpdate (prevProps) {
    if (this.props.showModal !== prevProps.showModal && this.props.showModal === true) {
      this.props.initialize(this.props.quota)
    }
  }

  checkValues (value, resolve, reject) {
    if (!value.processingAgencyId || !value.quota) {
      this.props.actions.openModal('mustFillDataQuota')
    } else {
      this.props.actions.addProcessingAgencyAssignation(value, resolve, reject)
    }
  }

  render () {
    const {
      t, handleSubmit, showModal, quota,
      combos: { ProcessingAgencyCombo, UOTreeCombo },
      actions: { editProcessingAgencyAssignation, deleteProcessingAgencyAssignation }
    } = this.props
    const tKey = 'MASTERS.PROCESSING_AGENCY_ASSIGNATION_QUOTA_MASTERS.MODAL.'
    return (
      <Modal className="role-modal languages-modal" show={showModal} onHide={this.props.actions.closeEditProcessingAgencyAssignationModal} backdrop={'static'}>
        <form autoComplete="off" onSubmit={handleSubmit(this.submitAgency.bind(this))}>
          <Modal.Header closeButton onHide={this.props.actions.closeEditProcessingAgencyAssignationModal}>
            <Modal.Title>{t(`${tKey}TITLE`)}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <Row key={1} className="inputs-wrapper">
                <Col sm={4} className="custom-width-col">
                  <h2>{t(`${tKey}ORGANIZED_UNIT`)}</h2>
                  <Field
                    name="organizedUnitId"
                    placeholder={t(`${tKey}ORGANIZED_UNIT`)}
                    component={InputTreeSelect}
                    multi={false}
                    allowClear={false}
                    valueKey="value"
                    labelKey="label"
                    childrenKey="children"
                    colSm={0}
                    options={UOTreeCombo}
                    disabled={true}
                  />
                </Col>
                <Col sm={4} className="custom-width-col">
                  <h2>{t(`${tKey}START_DATE`)}</h2>
                  <Field
                    name="startDate"
                    placeholder={t(`${tKey}START_DATE`)}
                    component={InputDatePicker}
                  />
                </Col>
                <Col sm={4} className="custom-width-col">
                  <h2>{t(`${tKey}END_DATE`)}</h2>
                  <Field
                    name="endDate"
                    placeholder={t(`${tKey}END_DATE`)}
                    component={InputDatePicker}
                  />
                </Col>
              </Row>
              <EditableTable
                t={t}
                columnsConfig={
                  [
                    { th: t(`${tKey}NAME`), key: 'processingAgencyId', isSelect: true, selectConfig: { options: ProcessingAgencyCombo, valueKey: 'id', labelKey: 'value' } },
                    { th: t(`${tKey}QUOTA`), key: 'quota', nonEditable: false, inputType: 'number' }
                  ]
                }
                editableObjectsArray={quota && quota.processingAgencyQuotas ? quota.processingAgencyQuotas : []}
                updateAction={editProcessingAgencyAssignation}
                createAction={ (value, resolve, reject) => this.checkValues(value, resolve, reject)}
                deleteAction={deleteProcessingAgencyAssignation}
                readOnlyFields={false}
              />
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <Col sm={12} className="btn-wrapper">
              <Button
                id="role-modal-submit-button"
                className="btn-standard"
                type="submit">
                {t(`${tKey}SAVE_BTN`)}
              </Button>
            </Col>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default reduxForm({
  form: 'editAgencyAssignation'
})(ProcessingAgencyAssignationModal)
