import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import SourceChannelModal from '../../components/masters/SourceChannelModal'
import { translate } from 'react-polyglot'
import { closeSourceChannelModal, saveSourceChannelModal } from '../../actions/masters/masters'

export function mapStateToProps (state) {
  return {
    ...state.entityMasters /* ,
    languageList: [
      {languageId: 1, name: 'Castellano', description: 'es-ES'},
      {languageId: 2, name: 'Catalán', description: 'es-CA'}
    ] */
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      closeSourceChannelModal,
      saveSourceChannelModal
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(SourceChannelModal))
