import { put, call, select, takeEvery } from 'redux-saga/effects'
import { getAuth } from '../../selectors/access/auth'
import { showLoader, hideLoader } from '../../actions/common.js'
import { handleError } from '../errors/errorManager'
import getSignDocuments from '../../services/sign/getSignDocuments'
import getSignDocumentsCount from '../../services/sign/getSignDocumentsCount'
import signActionTypes from '../../constants/actions/sign/sign'
import { searchDocumentsSuccess, getDocumentsCountSucces } from '../../actions/sign/sign'

export function * searchDocuments ({ filters }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const result = yield call(getSignDocuments, auth.token, filters)
    yield put(searchDocumentsSuccess(result, filters))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchsearchDocuments () {
  yield takeEvery(signActionTypes.SEARCH_DOCUMENTS, searchDocuments)
}

export function * getDocumentsCount ({ status, resolve, reject }) {
  try {
    const auth = yield select(getAuth)
    const result = yield call(getSignDocumentsCount, auth.token, status)
    yield put(getDocumentsCountSucces(result))
    if (resolve) resolve(result)
  } catch (error) {
    yield call(handleError, { error })
  } finally {
  }
}

export function * watchgetDocumentsCount () {
  yield takeEvery(signActionTypes.GET_DOCUMENTS_COUNT, getDocumentsCount)
}
