import statusHeaderActionTypes from '../../../constants/actions/dossier/common/statusHeader'

export function initialState () {
  return {
    isFolded: false,
    favorite: false,
    height: 0
  }
}

function toggleTimeline (state) {
  return {
    ...state,
    isFolded: !state.isFolded
  }
}

function setStatusHeaderHeight (state, { height }) {
  return {
    ...state,
    height
  }
}

function resetStatusHeader (state) {
  return initialState()
}

function switchDossierFavorite (state, { newFavoriteStatus }) {
  return {
    ...state,
    favorite: newFavoriteStatus
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case statusHeaderActionTypes.TOGGLE_TIMELINE:
      return toggleTimeline(state, action)
    case statusHeaderActionTypes.SET_STATUS_HEADER_HEIGHT:
      return setStatusHeaderHeight(state, action)
    case statusHeaderActionTypes.RESET_STATUS_HEADER:
      return resetStatusHeader(state, action)
    case statusHeaderActionTypes.SWITCH_DOSSIER_FAVORITE_SUCCESS:
      return switchDossierFavorite(state, action)
    default:
      return state
  }
}
