import { bindActionCreators } from 'redux'
import { change } from 'redux-form'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { fetchOrganizedUnitConfigurationList, deleteOrganizedUnitConfiguration, openOrganizedUnitConfigurationModal } from '../../../actions/masters/organizedUnitConfiguration/organizedUnitConfiguration'
import OrganizedUnitConfigurationMaster from '../../../components/masters/organizedUnitConfiguration/OrganizedUnitConfigurationMaster'
import { setChangeTabFromConfMaster } from '../../../actions/masters/masters'

export function mapStateToProps (state) {
  return {
    ...state.organizedUnitConfiguration,
    combos: {
      organizedUnitsAllCombo: state.combos.organizedUnitsAllCombo,
      configParameterAllCombo: state.combos.configParameterAllCombo
    }
  }
}
export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      deleteOrganizedUnitConfiguration,
      openOrganizedUnitConfigurationModal,
      fetchOrganizedUnitConfigurationList,
      setChangeTabFromConfMaster,
      change
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(OrganizedUnitConfigurationMaster))
