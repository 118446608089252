import React, { PureComponent } from 'react'
import { Field, reduxForm, reset } from 'redux-form'
import { Button, OverlayTrigger, Tooltip, Form } from 'react-bootstrap'
import InputText from '../commons/form/InputText'
import { multipleNormalize, toUpperCase, trimAll } from '../../../util/normalizeFunctions'
import InputSelect from '../commons/form/InputSelect'
import MapSectionsSearch from '../../../_v2/util/map-sections-search'
import SimpleTablePage from '../../../containers/commons/general/SimpleTablePage'
import IDocCarPagination from '../../../components/commons/iDocCarPagination'
import VehiclePopover from './VehiclePopover'
import DossierPopover from './DossierPopover'
import InputDatePicker from '../commons/form/InputDatePicker'
import { dossierSubTypes } from '../../../constants/backendIds'
// import settings from '../../../setting'
import { validateDates } from '../../../util/validationFunctions'
import { showFilter, hideFiltersBtn, toggleFilterClass, getIconClass } from '../../../_v2/util/management/ManagementUtils'
import { fromCamelToUnderscore, getDateNowMaxDatePicker } from '../../../util/utils'

class SearchStockManagementForm extends PureComponent {
  constructor () {
    super()
    this.state = {
      selectedDateType: null
    }
  }

  exportData () {
    const tKey = 'SEARCH.CATEGORIES.STOCKMANAGEMENT.'
    let columnsExcel = []
    columnsExcel.push(this.props.t(`${tKey}REPAIR_WORKSHOPS`) + '#RepairWorkshop')
    columnsExcel.push(this.props.t(`${tKey}STAGES`) + '#DossierStage')
    columnsExcel.push(this.props.t(`${tKey}VEHICLE`) + '#Vehicle')
    columnsExcel.push(this.props.t(`${tKey}DOSSIER`) + '#Dossier')
    columnsExcel.push(this.props.t(`${tKey}CREATION_DATE`) + '#CreationDate')
    columnsExcel.push(this.props.t(`${tKey}RECEPTION_DATE`) + '#ReceptionDate')
    columnsExcel.push(this.props.t(`${tKey}RATING_DATE`) + '#RatingDate')
    columnsExcel.push(this.props.t(`${tKey}BUDGET_SENT_DATE`) + '#BudgetSentDate')
    columnsExcel.push(this.props.t(`${tKey}BUDGET_ACCEPTED_DATE`) + '#BudgetAcceptedDate')
    columnsExcel.push(this.props.t(`${tKey}RECONDITIONING_CONFIRMATION_DATE`) + '#ReconditioningConfirmationDate')
    this.exportToExcel(columnsExcel)
  }

  exportToExcel (data) {
    let cols = ''
    data.forEach(element => {
      cols += element + '$'
    })
    cols = cols.substring(0, cols.length - 1)
    let values = this.props.dynamicFilters
    this.props.actions.fetchStockManagementDossiersDownload({ ...values.stockManagement, ...this.props.filters, columns: cols })
  }

  componentDidMount () {
    for (const dynamicFilter in this.props.dynamicFilters) {
      this.props.change(dynamicFilter, this.props.dynamicFilters[dynamicFilter])
    }

    this.props.actions.fetchStagesCombo(dossierSubTypes.stock, true)
    this.props.actions.fetchUserRepairWorkshopsCombo()
  }

  componentDidUpdate (prevProps, prevState) {
    const { repairWorkshopsCombo } = this.props.combos
    const isWorkshopUser = repairWorkshopsCombo?.isWorkshopUser ?? false
    if (isWorkshopUser && !prevProps.formValues?.repairWorkshops && !this.props.formValues?.repairWorkshops) {
      const repairWorkshops = repairWorkshopsCombo.repairShopsList?.map((item) => { return item.id })
      this.props.initialize({ stockManagement: { repairWorkshops: repairWorkshops } })
    }
  }

  getDateLabel (text) {
    const dateTypeSelected = this.props.formValues?.dateType
    const dateTypeValue = this.props.combos.dateTypeOptions.find(dateType => dateType.id === dateTypeSelected)?.value
    return text.replace('#DATETYPE#', dateTypeValue ?? '')
  }

  dateTypeOnInputChange (val) {
    const text = this.props.combos.dateTypeOptions.find(dateType => dateType.id === val)?.value
    this.props.fieldFill({ dateType: val })
    this.setState({ selectedDateType: text })
    if (val === null) {
      this.dateFromOnInputChange(null)
      this.dateToOnInputChange(null)
    }
  }

  dateFromOnInputChange (val) {
    this.props.change('stockManagement.dateFrom', val)
    this.props.fieldFill({ dateFrom: val })
  }

  dateToOnInputChange (val) {
    this.props.change('stockManagement.dateTo', val)
    this.props.fieldFill({ dateTo: val })
  }

  getRepairWorkshopsCustomClass (isWorkshopUser) {
    return isWorkshopUser
      ? 'hiddenSelect'
      : this.props.fields.repairWorkshops ? this.props.classNameSelect : ''
  }

  getTooltip (idx, text) {
    return (
      <Tooltip id={`repairWorkshop${idx}`}>{text}</Tooltip>
    )
  }

  submitSearchForm (values) {
    this.props.actions.fetchStockManagementDossiers({ ...values.stockManagement, ...this.props.filters })
    this.setState({ dossierIdToArray: [], hideFilters: true, dynamicFilters: values, filterValues: { ...values.entity, ...this.props.filters } })

    this.props.actions.setFilters(values)
  }

  onPageChange (page) {
    this.setState({ filterValues: { ...this.state.filterValues, page: page } })
    this.props.actions.fetchStockManagementDossiers({ ...this.state.filterValues, page })
  }

  onOrderChange (e) {
    const newOrderBy = this.props.filters.orderBy.includes(`%2B${e.currentTarget.dataset.field}`) ? `-${e.currentTarget.dataset.field}` : `%2B${e.currentTarget.dataset.field}`
    this.setState({ filterValues: { ...this.state.filterValues, orderBy: newOrderBy } })
    this.props.actions.fetchStockManagementDossiers({ ...this.state.filterValues, orderBy: newOrderBy })
  }

  handleClickReset (e) {
    if (e !== undefined) {
      e.preventDefault()
    }

    this.props.dispatch(reset('SearchStockManagementForm'))
    this.state.dossierIdToArray = []
    this.setState({ onlyFleets: true, checkAll: false, dossierIdToArray: [], entityData: [], fields: [] })
  }

  extractMultipleCombo (combo, filters, item, filter) {
    const values = combo.filter(object => {
      return filters[item][filter] ? filters[item][filter].some(selectedComboValue => object.id === selectedComboValue) : null
    })
    let value = values && this.formatMultipleValues(values)
    return value
  }

  formatMultipleValues (multiplecombovalues) {
    return multiplecombovalues
      .map((combovalue, index) => combovalue.value || combovalue.name)
      .join(', ')
  }

  getDateTypeOptions () {
    const { t } = this.props
    const tKey = 'SEARCH.CATEGORIES.STOCKMANAGEMENT.DATE_TYPE_OPTIONS.'
    return [
      { id: '1', value: t(`${tKey}CREATION_DATE`) },
      { id: '2', value: t(`${tKey}RECEPTION_DATE`) },
      { id: '3', value: t(`${tKey}RATING_DATE`) },
      { id: '4', value: t(`${tKey}BUDGET_SENT_DATE`) },
      { id: '5', value: t(`${tKey}BUDGET_ACCEPTED_DATE`) },
      { id: '6', value: t(`${tKey}RECONDITIONING_CONFIRMATION_DATE`) }
    ]
  }

  selectedFilters () {
    const filters = this.props.dynamicFilters
    const tKey = 'SEARCH.CATEGORIES.'
    return Object.keys(filters)
      .map((item) => {
        let text = item
        let value = null
        const list = []
        Object.keys(filters[item]).map((filter, index) => {
          text = this.props.t(`${tKey}${item.toUpperCase()}.${fromCamelToUnderscore(filter).toUpperCase()}`)
          switch (filter) {
            case 'stages':
            {
              value = this.extractMultipleCombo(this.props.combos.stagesCombo, filters, item, filter)
              break }
            case 'repairWorkshops':
            {
              const repairWorkshops = this.props.combos.repairWorkshopsCombo?.repairShopsList
              const isWorkshopUser = this.props.combos.repairWorkshopsCombo?.isWorkshopUser ?? false
              value = isWorkshopUser ? null : this.extractMultipleCombo(repairWorkshops, filters, item, filter)
              break
            }
            case 'dateType':
            {
              value = this.getDateTypeOptions().find(dateType => dateType.id === filters[item][filter]).value
              break
            }
            case 'dateTo':
            case 'dateFrom': {
              const dateType = this.getDateTypeOptions().find(x => x.id === filters[item].dateType)?.value ?? ''
              text = text.replace('#DATETYPE#', dateType)
              value = filters[item][filter]
              break
            }
            default:
            { value = filters[item][filter]
              break }
          }

          if (value) {
            if (filter !== 'entityChildSelected') {
              list.push(<li key={index}>{text}: <strong>{value}</strong>;</li>)
            } else {
              list.push(<li key={index}> <strong>{text}</strong>;</li>)
            }
          }
        })
        return list
      })
  }

  toogleHideFilters () {
    this.props.actions.setHideFilters(!this.props.hideFilters)
  }

  toogleMaxiTable () {
    this.setState({ maxiTable: !this.state.maxiTable })
  }

  render () {
    const {
      t, fields, fieldFill, className, classNameSelect,
      hasSearch, stockManagementData, page, pages, count, handleSubmit, hideFilters,
      combos: { repairWorkshopsCombo, stagesCombo, dateTypeOptions }
    } = this.props
    const isWorkshopUser = repairWorkshopsCombo?.isWorkshopUser ?? false
    const repairWorkshops = repairWorkshopsCombo?.repairShopsList ?? []
    const tKey = 'SEARCH.CATEGORIES.STOCKMANAGEMENT.'
    const secMapSearch = MapSectionsSearch.find(function (s) { return s.page === 'stockManagementSearchPage' })
    const hasResults = stockManagementData && stockManagementData.length > 0

    return (
      <div className="inner-results-panel">
        <div className="section-results-header">
          <div className="section-header-title">
            <OverlayTrigger placement="right" overlay={<Tooltip id="editProductTooltip">{`${t((this.props.hideMenu ? 'GLOBAL.TABLES.SHOW_INNER_MENU' : 'GLOBAL.TABLES.HIDE_INNER_MENU'))}`}</Tooltip>}>
              <a onClick={() => this.props.toogleInnerMenu()}s className={this.props.hideMenu ? 'collapseBtn' : 'collapseBtn closeInnerMenu'}><i className={this.props.hideMenu ? 'iDocIcon-Open-inner-menu' : 'iDocIcon-Close-inner-menu'}></i></a>
            </OverlayTrigger>
            <h4>{t(`${secMapSearch.title}`)}
              <OverlayTrigger placement="left" overlay={<Tooltip id="editProductTooltip">{`${t((hideFilters ? 'GLOBAL.TABLES.SHOW_FILTERS' : 'GLOBAL.TABLES.HIDE_FILTERS'))}`}</Tooltip>}>
                <a onClick={() => this.toogleHideFilters()} className={'toogleShowFilters ' + hideFiltersBtn(hasResults)}><i className={toggleFilterClass(hasResults, hideFilters)}></i></a>
              </OverlayTrigger>
            </h4>
          </div>
          <Form autoComplete="off" onSubmit={handleSubmit((values) => this.submitSearchForm(values))} className="filters ">
            <div className={'search-form ' + (showFilter(hasResults, hideFilters) ? '' : 'hideFilters ')}>
              <Field
                component={InputSelect}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                id="stockManagement.repairWorkshops"
                name='stockManagement.repairWorkshops'
                placeholder={t(`${tKey}REPAIR_WORKSHOPS`)}
                controlLabel={t(`${tKey}REPAIR_WORKSHOPS`)}
                options={repairWorkshops}
                valueKey="id"
                labelKey="name"
                onInputChange={(val) => fieldFill({ repairWorkshops: val })}
                customClass={this.getRepairWorkshopsCustomClass(isWorkshopUser)}
                multi = {true}
              />
              <Field
                component={InputSelect}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                name='stockManagement.stages'
                id ='stockManagement.stages'
                placeholder={t(`${tKey}STAGES`)}
                controlLabel={t(`${tKey}STAGES`)}
                options={stagesCombo}
                valueKey="id"
                labelKey="value"
                onInputChange={(val) => fieldFill({ stages: val })}
                customClass={fields.stages ? classNameSelect : ''}
                multi = {true}
                menuContainerStyle={{ zIndex: 999 }}
              />
              <Field
                component={InputText}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                id="stockManagement.dossierNumber"
                name='stockManagement.dossierNumber'
                placeholder={t(`${tKey}DOSSIER_NUMBER`)}
                controlLabel={t(`${tKey}DOSSIER_NUMBER`)}
                type="text"
                minLength = {3}
                onInputChange={(val) => fieldFill({ dossierNumber: val })}
                customClass={fields.dossierNumber ? className : ''}
              />
              <Field
                component={InputText}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                id="stockManagement.licensePlate"
                name='stockManagement.licensePlate'
                placeholder={t(`${tKey}LICENSE_PLATE`)}
                controlLabel={t(`${tKey}LICENSE_PLATE`)}
                type="text"
                normalize={value => multipleNormalize(value, [toUpperCase, trimAll])}
                onInputChange={(val) => fieldFill({ licensePlate: val })}
                customClass={fields.licensePlate ? className : ''}
              />
              <Field
                component={InputText}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                id="stockManagement.vinchassis"
                name='stockManagement.vinchassis'
                placeholder={t(`${tKey}VINCHASSIS`)}
                controlLabel={t(`${tKey}VINCHASSIS`)}
                type="text"
                maxLength={17}
                normalize={value => multipleNormalize(value, [toUpperCase, trimAll])}
                onInputChange={(val) => fieldFill({ vinChassis: val })}
                customClass={fields.vinChassis ? className : ''}
              />
              <Field
                component={InputSelect}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                id="stockManagement.dateType"
                name='stockManagement.dateType'
                controlLabel={t(`${tKey}DATE_TYPE`)}
                placeholder={t(`${tKey}DATE_TYPE`)}
                options={dateTypeOptions}
                valueKey="id"
                labelKey="value"
                onInputChange={(value) => this.dateTypeOnInputChange(value)}
                customClass={fields.dateType ? classNameSelect : ''}
              />
              { this.props.formValues?.dateType && (
                <>
                  <Field
                    component={InputDatePicker}
                    colWidht={25} // 25, 33, 50, 75, 100 default=25
                    maxDate={getDateNowMaxDatePicker()}
                    id="stockManagement.dateFrom"
                    name='stockManagement.dateFrom'
                    controlLabel={this.getDateLabel(t(`${tKey}DATE_FROM`))}
                    onInputChange={(value) => this.dateFromOnInputChange(value)}
                    customClass={fields.dateFrom ? className : ''} />
                  <Field
                    component={InputDatePicker}
                    colWidht={25} // 25, 33, 50, 75, 100 default=25
                    maxDate={getDateNowMaxDatePicker()}
                    id="stockManagement.dateTo"
                    name='stockManagement.dateTo'
                    controlLabel={this.getDateLabel(t(`${tKey}DATE_TO`))}
                    value={this.state.dateTo}
                    onInputChange={(value) => this.dateToOnInputChange(value)}
                    customClass={fields.dateTo ? className : ''} />
                </>
              )}
              <div className='search-form-actions flexInLine al-R'>
                <Button id="btn_search"
                  type="submit"
                  className="_Btn accept stk">
                  <i className="iDocIcon-search"/>
                  {t('SEARCH.ACTIONS.SEARCH_BUTTON')}
                </Button>
                <Button
                  id="btn_reset"
                  onClick={(e) => this.handleClickReset(e)}
                  className={'_Btn' + (this.props.pristine ? ' accept' : ' accept')}
                  disabled={this.props.pristine}>
                  <i className="iDocIcon-clear-decline"/>
                  {t('SEARCH.ACTIONS.RESET_BUTTON')}
                </Button>
              </div>
            </div>
          </Form>
        </div>
        <div className="section-results-body">
          <div className={'searchResoultsContainer' + (this.state.maxiTable ? ' maxiTable' : '')}>
            {hasSearch && ([
              <div className="table-wrapper" key="table-wrapper">
                <ul className="list-inline pull-left">
                  {this.selectedFilters().length === 0 ? <li>{t(`${tKey}NO_SELECTED_FILTERS`)}</li> : (this.selectedFilters())}
                </ul>
                { hasResults &&
                  <div className='flexInLine al-R'>
                    <OverlayTrigger placement="left" overlay={<Tooltip id="editProductTooltip">{`${t((this.state.maxiTable ? 'GLOBAL.TABLES.MINIMIZE_TABLE' : 'GLOBAL.TABLES.MAXIMIZE_TABLE'))}`}</Tooltip>}>
                      <a onClick={() => this.toogleMaxiTable()} className='_Btn accept stk o-icn maximizeBtn'><i className={(this.state.maxiTable ? 'iDocIcon-Minimize' : 'iDocIcon-Maximize')}></i></a>
                    </OverlayTrigger>
                    <OverlayTrigger placement="left" overlay={<Tooltip id="editProductTooltip">{t('SEARCH.ACTIONS.EXPORT')}</Tooltip>}>
                      <a onClick={(event) => this.exportData(event)} className='_Btn new o-icn exportToExcel'><i className="iDocIcon-file-excel"></i></a>
                    </OverlayTrigger>
                  </div>
                }
                {/* table */}
                { hasResults &&
                <div className="table-responsive">
                  <SimpleTablePage
                    columns={[
                      <th key={1} data-field="repairWorkshopOrganizedUnit" onClick={(event) => this.onOrderChange(event)}>
                        {t(`${tKey}REPAIR_WORKSHOPS`)}&nbsp;<i className={getIconClass('repairWorkshopOrganizedUnit', this.props.filters)}/>
                      </th>,
                      <th key={2} data-field="dossierStage" onClick={(event) => this.onOrderChange(event)}>
                        {t(`${tKey}STAGES`)}&nbsp;<i className={getIconClass('dossierStage', this.props.filters)}/>
                      </th>,
                      <th key={3} data-field="vehicle" onClick={(event) => this.onOrderChange(event)}>
                        {t(`${tKey}VEHICLE`)}&nbsp;<i className={getIconClass('vehicle', this.props.filters)}/>
                      </th>,
                      <th key={4} data-field="dossier" onClick={(event) => this.onOrderChange(event)}>
                        {t(`${tKey}DOSSIER`)}&nbsp;<i className={getIconClass('dossier', this.props.filters)} />
                      </th>,
                      <th key={5} data-field="creationDate" onClick={(event) => this.onOrderChange(event)}>
                        {t(`${tKey}CREATION_DATE`)}&nbsp;<i className={getIconClass('creationDate', this.props.filters)}/>
                      </th>,
                      <th key={6} data-field="receptionDate" onClick={(event) => this.onOrderChange(event)}>
                        {t(`${tKey}RECEPTION_DATE`)}&nbsp;<i className={getIconClass('receptionDate', this.props.filters)}/>
                      </th>,
                      <th key={7} data-field="ratingDate" onClick={(event) => this.onOrderChange(event)}>
                        {t(`${tKey}RATING_DATE`)}&nbsp;<i className={getIconClass('ratingDate', this.props.filters)}/>
                      </th>,
                      <th key={8} data-field="budgetSentDate" onClick={(event) => this.onOrderChange(event)}>
                        {t(`${tKey}BUDGET_SENT_DATE`)}&nbsp;<i className={getIconClass('budgetSentDate', this.props.filters)} />
                      </th>,
                      <th key={9} data-field="budgetAcceptedDate" onClick={(event) => this.onOrderChange(event)}>
                        {t(`${tKey}BUDGET_ACCEPTED_DATE`)}&nbsp;<i className={getIconClass('budgetAcceptedDate', this.props.filters)} />
                      </th>,
                      <th key={10} data-field="reconditioningConfirmationDate" onClick={(event) => this.onOrderChange(event)}>
                        {t(`${tKey}RECONDITIONING_CONFIRMATION_DATE`)}&nbsp;<i className={getIconClass('reconditioningConfirmationDate', this.props.filters)} />
                      </th>
                    ]}
                    rows={stockManagementData && stockManagementData.map((row, idx) => (
                      <tr key={idx}>
                        { row.repairWorkshopOrganizedUnit?.length > 20
                          ? <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={this.getTooltip(idx, row.repairWorkshopOrganizedUnit)}>
                            <td className="column-ellipsis">
                              {row.repairWorkshopOrganizedUnit.substring(0, 20) + '(...)'}
                            </td>
                          </OverlayTrigger>
                          : <td className="column-ellipsis">
                            {row.repairWorkshopOrganizedUnit}
                          </td> }
                        <td>{row.dossierStage}</td>
                        <td className="see-more">
                          <VehiclePopover
                            t={t}
                            vehicle={row.vehicle}
                            half={(stockManagementData.length || 1) / (idx + 1)}
                            idx={idx}
                            id={'popover-vehicle' + idx}
                            isHeader = {true}
                            dossierSubTypeId={row.dossierSubTypeId}
                          />
                        </td>
                        <td className="see-more">
                          <DossierPopover
                            idx={idx}
                            t={t}
                            id={'popover-dossier' + idx}
                            dossier={row.dossier}
                            half={(stockManagementData.length || 1) / (idx + 1)}
                            urgencyType={row.urgencyType}
                          />
                        </td>
                        <td>{row.creationDate}</td>
                        <td>{row.receptionDate}</td>
                        <td>{row.ratingDate}</td>
                        <td>{row.budgetSentDate}</td>
                        <td>{row.budgetAcceptedDate}</td>
                        <td>{row.reconditioningConfirmationDate}</td>
                      </tr>
                    ))}
                  />
                </div>
                }
                <div className="search-footer" key="search-footer">
                  {hasResults ? (
                    <IDocCarPagination
                      id="btn_pag_sales"
                      page={page}
                      pagesCount={pages}
                      totalRows= {count}
                      onSelectAction={(page) => this.onPageChange(page)}/>
                  ) : <p className='no_results'>{t('GLOBAL.TABLES.NO_RESULTS')}</p>
                  }
                </div>
              </div>
            ])}
          </div>
        </div>
      </div>
    )
  }
}

const validate = (values, { t }) => {
  const errors = {}
  if (values) {
    const stockManagement = values[`${'stockManagement'}`]
    if (stockManagement) {
      let errorsOnStockManagement = {}
      if (stockManagement.dateType && !stockManagement.dateFrom) {
        errorsOnStockManagement.dateFrom = t('GLOBAL.FORMS.REQUIRED')
      }
      if (stockManagement.dateFrom && stockManagement.dateTo) {
        errorsOnStockManagement.dateTo = validateDates(t, stockManagement.dateFrom, stockManagement.dateTo)
      }
      if (!stockManagement.repairWorkshops || stockManagement.repairWorkshops.length <= 0) {
        errorsOnStockManagement.repairWorkshops = t('GLOBAL.FORMS.REQUIRED')
      } else if (stockManagement.dossierNumber && stockManagement.dossierNumber.length < 3) {
        errorsOnStockManagement.dossierNumber = t('DOSSIER_COMPONENTS.VALIDATIONS.MIN_3_CHARACTER')
      }
      errors[`${stockManagement}`] = errorsOnStockManagement
    } else {
      errors.stockManagement = { repairWorkshops: t('GLOBAL.FORMS.REQUIRED') }
    }
  }

  return errors
}

export default reduxForm({
  form: 'SearchStockManagementForm',
  validate
})(SearchStockManagementForm)
