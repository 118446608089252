
import React, { PureComponent } from 'react'
import { Button, Modal, Col, Row } from 'react-bootstrap'
import { reduxForm } from 'redux-form'

class SepomexValidationModal extends PureComponent {
  render () {
    const { t, showModal, validationResults: { readRows, validateRows, nonValidateRows }, importFile, closeModal } = this.props
    const tKey = 'MASTERS.SEPOMEX.VALIDATION_MODAL.'

    return (
      <Modal className='sepomexModal' show={showModal} backdrop={'static'}>
        <Modal.Header>
          <Row>
            {t(`${tKey}RESULT`)}
          </Row>
        </Modal.Header>
        <Modal.Body>
          <Row className='rowRead'>
            {t(`${tKey}READ_ROWS`) + ' ' + readRows}
          </Row>
          <Row className='rowValid'>
            {t(`${tKey}VALID_ROWS`) + ' ' + validateRows}
          </Row>
          <Row className='rowNoValid'>
            {t(`${tKey}NON_VALID_ROWS`) + ' ' + nonValidateRows}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row>
            {this.props.validationResults.validateRows !== 0 ? t(`${tKey}IMPORT_FILE`) : t(`${tKey}NON_VALID_FILE`) }
          </Row>
          <Col>
            {this.props.validationResults.validateRows !== 0 && (
              <Button onClick={importFile} className="btn-Accept">
                {t(`${tKey}IMPORT`)}
              </Button>
            )}
            <Button onClick={closeModal} className="btn-exit">
              {t(`${tKey}CANCEL`)}
            </Button>
          </Col>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default reduxForm({
  form: 'SepomexValidationModal'
})(SepomexValidationModal)
