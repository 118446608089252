import React, { PureComponent } from 'react'
import SimpleTablePage from '../../containers/commons/general/SimpleTablePage'

class FaqTable extends PureComponent {
  componentWillMount () {
    if (this.props && this.props.sectionId) {
      this.props.actions.fetchSectionQuestions(this.props.sectionId)
    } else if (this.props && this.props.search) {
      this.props.actions.fetchQuestionsBySearch(this.props.search)
    }
  }

  openQuestion (questionId) {
    this.props.openQuestion(questionId)
  }

  render () {
    const {
      t, questions
    } = this.props

    const tKey = 'FAQ.'
    return (
      <div className="table-containter">
        {questions &&
          <SimpleTablePage
            columns={[
              <th key={0} className="table-header-title">
                {t(`${tKey}QUESTION`)}
              </th>,
              <th key={1} className="table-header-section text-right">
                {t(`${tKey}SECTION`)}
              </th>
            ]}
            rows={questions.map((question, idx) => (
              <tr key={idx} className="faq-table-row" onClick={() => { this.openQuestion(question.faqId) }}>
                <td>
                  {question.faqTitle}
                </td>
                <td className="text-right">
                  {question.faqSectionTitle}
                </td>
              </tr>
            ))}
          />
        }
      </div>
    )
  }
}

export default FaqTable
