import mastersActionTypes from '../../../constants/actions/masters/masters'

function initialState () {
  return {
    customFields: [],
    customFieldsCount: 0,
    pagesCount: 0,
    languageList: [],
    filter: {
      page: 1,
      orderBy: '',
      pageSize: 10
    },
    customFieldModal: {
      customFieldId: null,
      showModal: false,
      viewComponents: []
    }
  }
}

function fetchCustomFieldListSuccess (state, { customFieldList, filter }) {
  return {
    ...state,
    customFieldList: customFieldList.customFields,
    customFieldsCount: customFieldList.customFieldsCount,
    pagesCount: customFieldList.pagesCount,
    languageSelected: customFieldList.languageSelected,
    filter
  }
}

function openCustomFieldModalSuccess (state, { languageList, customField, viewComponents }) {
  const initial = initialState()
  return {
    ...state,
    customFieldModal: {
      ...initial.customFieldModal,
      languageList,
      customField,
      showModal: true,
      viewComponents
    }
  }
}

function closeEditModalSuccess (state) {
  return {
    ...state,
    customFieldModal: {
      ...initialState().customFieldModal
    }
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case mastersActionTypes.FETCH_CUSTOM_FIELD_LIST_SUCCESS:
      return fetchCustomFieldListSuccess(state, action)
    case mastersActionTypes.OPEN_CUSTOM_FIELD_MODAL_SUCCESS:
      return openCustomFieldModalSuccess(state, action)
    case mastersActionTypes.CLOSE_EDIT_CUSTOM_FIELD_MODAL:
      return closeEditModalSuccess(state, action)
    default:
      return state
  }
}
