import { dossierTypes as dossierTypesConstant } from '../dossier/common/dossierTypes.js'

export const TrackedPages = {
  DASHBOAD_TASK: 'dashboadTask-page',
  CAMPAIGN_HISTORICAL: dossierTypesConstant.campaign + 'Historical-page',
  PAPERWORK_HISTORICAL: dossierTypesConstant.paperwork + 'Historical-page',
  PURCHASE_HISTORICAL: dossierTypesConstant.purchase + 'Historical-page',
  REGISTRATION_DELIVERY_HISTORICAL: dossierTypesConstant.registrationDelivery + 'Historical-page',
  SALES_HISTORICAL: dossierTypesConstant.sales + 'Historical-page',
  SERVICE_HISTORICAL: dossierTypesConstant.service + 'Historical-page',
  CAMPAIGN: dossierTypesConstant.campaign + '-page',
  PAPERWORK: dossierTypesConstant.paperwork + '-page',
  PURCHASE: dossierTypesConstant.purchase + '-page',
  REGISTRATION_DELIVERY: dossierTypesConstant.registrationDelivery + '-page',
  SALE: dossierTypesConstant.sales + '-page',
  SERVICE: dossierTypesConstant.service + '-page',
  STOCK: dossierTypesConstant.stock + '-page',
  STOCK_HISTORICAL: dossierTypesConstant.stock + 'Historical-page'
}
