import { put, call, select, takeEvery } from 'redux-saga/effects'
import { showLoader, hideLoader } from '../../../actions/common.js'
import mastersActionTypes from '../../../constants/actions/masters/masters'
import { getAuth } from '../../../selectors/access/auth'
import { handleError } from '../../errors/errorManager'
import { getProcessingAgencyOrganizedUnitConfigFilter } from '../../../selectors/masters/processingAgency'

import { fetchProcessingAgencyOrganizedUnitConfigListSuccess, showProcessingAgencyOrganizedUnitConfigModalSuccess } from '../../../actions/masters/processingAgencyOrganizedUnitConfig/processingAgencyOrganizedUnitConfig'
import postProcessingAgencyOrganizedUnitConfigListService from '../../../services/processingAgencyOrganizedUnitConfig/postProcessingAgencyOrganizedUnitConfigList'
import postProcessingAgencyOrganizedUnitConfigService from '../../../services/processingAgencyOrganizedUnitConfig/postProcessingAgencyOrganizedUnitConfig'
import putProcessingAgencyOrganizedUnitConfigService from '../../../services/processingAgencyOrganizedUnitConfig/putProcessingAgencyOrganizedUnitConfig'
import deleteProcessingAgencyOrganizedUnitConfigService from '../../../services/processingAgencyOrganizedUnitConfig/deleteProcessingAgencyOrganizedUnitConfig'
import { yesNoModal } from '../../modalsListeners/yesNoModal'

export function * fetchProcessingAgencyOrganizedUnitConfigList ({ filter }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const processingAgencyOrganizedUnitConfigList = yield call(postProcessingAgencyOrganizedUnitConfigListService, auth.token, filter)
    yield put(fetchProcessingAgencyOrganizedUnitConfigListSuccess(processingAgencyOrganizedUnitConfigList, filter))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchProcessingAgencyOrganizedUnitConfigList () {
  yield takeEvery(mastersActionTypes.FETCH_PROCESSING_AGENCY_ORGANIZED_UNIT_CONFIG_LIST, fetchProcessingAgencyOrganizedUnitConfigList)
}

export function * showProcessingAgencyOrganizedUnitConfigModal ({ show, processingAgencyOrganizedUnitConfig }) {
  try {
    yield put(showLoader())
    yield put(showProcessingAgencyOrganizedUnitConfigModalSuccess(show, processingAgencyOrganizedUnitConfig))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchShowProcessingAgencyOrganizedUnitConfigModal () {
  yield takeEvery(mastersActionTypes.SHOW_PROCESSING_AGENCY_ORGANIZED_UNIT_CONFIG_MODAL, showProcessingAgencyOrganizedUnitConfigModal)
}

export function * submitProcessingAgencyOrganizedUnitConfigModal ({ processingAgencyOrganizedUnitConfig, processAgencyCommunicationServiceId }) {
  try {
    const auth = yield select(getAuth)
    if (processingAgencyOrganizedUnitConfig.ProcessingAgencyOrganizedUnitConfigId) {
      const confirmed = yield call(yesNoModal, 'editProcessingAgencyOrganizedUnitConfig')
      if (confirmed) {
        yield put(showLoader())
        yield call(putProcessingAgencyOrganizedUnitConfigService, auth.token, processingAgencyOrganizedUnitConfig, processAgencyCommunicationServiceId)
      }
    } else {
      yield put(showLoader())
      yield call(postProcessingAgencyOrganizedUnitConfigService, auth.token, processingAgencyOrganizedUnitConfig, processAgencyCommunicationServiceId)
    }
    yield put(showProcessingAgencyOrganizedUnitConfigModalSuccess(false))
    const filter = yield filter || select(getProcessingAgencyOrganizedUnitConfigFilter)
    const processingAgencyOrganizedUnitConfigList = yield call(postProcessingAgencyOrganizedUnitConfigListService, auth.token, filter, processAgencyCommunicationServiceId)
    yield put(fetchProcessingAgencyOrganizedUnitConfigListSuccess(processingAgencyOrganizedUnitConfigList, filter))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSubmitProcessingAgencyOrganizedUnitConfigModal () {
  yield takeEvery(mastersActionTypes.SUBMIT_PROCESSING_AGENCY_ORGANIZED_UNIT_CONFIG, submitProcessingAgencyOrganizedUnitConfigModal)
}

export function * deleteProcessingAgencyOrganizedUnitConfig ({ processingAgencyOrganizedUnitConfigId, processAgencyCommunicationServiceId }) {
  try {
    const confirmed = yield call(yesNoModal, 'deleteProcessingAgencyOrganizedUnitConfig')
    if (confirmed) {
      yield put(showLoader())
      const auth = yield select(getAuth)
      yield call(deleteProcessingAgencyOrganizedUnitConfigService, auth.token, processingAgencyOrganizedUnitConfigId, processAgencyCommunicationServiceId)
      yield put(showProcessingAgencyOrganizedUnitConfigModalSuccess(false))
      const filter = yield filter || select(getProcessingAgencyOrganizedUnitConfigFilter)
      const processingAgencyOrganizedUnitConfigList = yield call(postProcessingAgencyOrganizedUnitConfigListService, auth.token, filter, processAgencyCommunicationServiceId)
      yield put(fetchProcessingAgencyOrganizedUnitConfigListSuccess(processingAgencyOrganizedUnitConfigList, filter))
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDeleteProcessingAgencyOrganizedUnitConfig () {
  yield takeEvery(mastersActionTypes.DELETE_PROCESSING_AGENCY_ORGANIZED_UNIT_CONFIG, deleteProcessingAgencyOrganizedUnitConfig)
}
