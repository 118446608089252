import apiFetch from '../apiFetch'

export default function (organizedUnitId, dossierId, token) {
  return apiFetch({
    endPoint: `purchasePresentTypes/${dossierId}?organizedUnitId=${organizedUnitId}`,
    method: 'GET',
    body: null,
    token: token
  })
}
