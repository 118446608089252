import React, { PureComponent } from 'react'
import { Row, Button } from 'react-bootstrap'
import InputText from '../../../commons/form/InputText'
import InputSelect from '../../../commons/form/InputSelect'
import { reduxForm, Field } from 'redux-form'

class Step4 extends PureComponent {
  render () {
    return (
      <div>
        <Row className="row-border">
          <Field
            controlLabel='Financiera'
            colSm={3}
            id='financial'
            name='financial'
            placeholder='Financiera'
            labelKey='financial'
            valueKey='financial'
            component={InputSelect}
          />
          <Field
            controlLabel='Fecha pago'
            colSm={3}
            id='paymentDate'
            name='paymentDate'
            placeholder='Fecha pago'
            component={InputText}
          />
          <Field
            controlLabel='Importe'
            colSm={3}
            id='amount'
            name='amount'
            placeholder='Importe'
            component={InputText}
          />
        </Row>
        <div className="button-wrapper text-left">
          <Button className="btn-standard-white btn-continue">
            <i className="ico-check" /> Alta pago
          </Button>
        </div>
      </div>
    )
  }
}

export default reduxForm({ form: 'newForm' })(Step4)
