import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { fetchDestinationCraneItem, deleteDestinationCraneItem, putDestinationCraneItem } from '../../../actions/masters/masters'
import DestinationCraneMasterModal from '../../../components/masters/destinationCrane/DestinationCraneMasterModal'

export function mapStateToProps (state) {
  const destinationCraneModal = state.destinationCraneMaster.destinationCraneModal
  return {
    showModal: destinationCraneModal.showModal,
    destinationCrane: destinationCraneModal.destinationCrane,
    combos: {
      UOTreeCombo: state.combos.UOTreeCombo
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchDestinationCraneItem,
      deleteDestinationCraneItem,
      putDestinationCraneItem
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(DestinationCraneMasterModal))
