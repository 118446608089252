import React from 'react'

export default ({ loading }) => {
  if (loading <= 0) {
    return null
  }

  return (
    <div style={{ position: 'fixed' }} className={ `loader-main-overlay animated ${loading ? 'fadeIn' : 'fadeOut'}`}>
      <div className='loader-main'>
        <div className="lds-double-ring">
          <div></div>
          <div></div>
        </div>
        <svg>
          <defs>
            <filter id="goo">
              <feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="12" />
              <feColorMatrix
                in="blur"
                mode="matrix"
                values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 18 -7"
                result="goo" />
            </filter>
          </defs>
        </svg>
      </div>
    </div>
  )
}
