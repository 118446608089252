import React, { PureComponent } from 'react'
import { Field, reduxForm, reset } from 'redux-form'
import { Form, OverlayTrigger, Button, Tooltip } from 'react-bootstrap'
import { validateDate, validateDates } from '../../../util/validationFunctions'
import SimpleTablePage from '../../../containers/commons/general/SimpleTablePage'
import IDocCarPagination from '../../../components/commons/iDocCarPagination'
import { dossierStagesId } from '../../../constants/dossier/common/dossierStages'
import { formatDate, fromCamelToUnderscore, getDateNowMaxDatePicker } from '../../../util/utils'
import { Link } from 'react-router-dom'
import settings from '../../../setting'
import { clientInformation } from '../../../constants/backendIds'
import { dossierSubTypeId } from '../../../constants/dossier/common/dossierSubType'

import MapSectionsManagement from '../../../_v2/util/map-sections-management'
import InputSelect from '../../../_v2/components/commons/form/InputSelect'
import InputDatePicker from '../../../_v2/components/commons/form/InputDatePicker'
import { showFilter, hideFiltersBtn, toggleFilterClass, getIconClass } from '../../../_v2/util/management/ManagementUtils'
import ExpandableText from '../commons/ExpandableText'

class SearchByPrepareForm extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      fields: {},
      fieldsConfiguration: {
      },
      stages: [],
      serviceRoleConfig: null,
      maxiTable: false,
      hideFilters: false,
      showAllText: false
    }
  }

  validateDate (value, allValues, props) {
    const result = validateDate(value, props)
    return result === Object(result) ? undefined : result
  }

  exportData () {
    const tKey = 'SEARCH.CATEGORIES.SERVICE.'
    let columnsExcel = []
    columnsExcel.push(this.props.t(`${tKey}DOSSIER_NUMBER`) + '#Number')
    columnsExcel.push(this.props.t(`${tKey}STAGE`) + '#Stage')
    columnsExcel.push(this.props.t(`${tKey}ORDER_DATE`) + '#OrderDate')
    columnsExcel.push(this.props.t(`${tKey}REQUIRED_DATE`) + '#RequiredDate')
    columnsExcel.push(this.props.t(`${tKey}CONFIRMED_DATE`) + '#ConfirmedDate')
    if (settings.clientFieldValidation === clientInformation.VGR) {
      columnsExcel.push(this.props.t(`${tKey}OR_NUMBER`) + '#ORNumber')
    }
    columnsExcel.push(this.props.t(`${tKey}SALE_OBSERVATIONS`) + '#SaleObservations')
    columnsExcel.push(this.props.t(`${tKey}PROVIDER_OBSERVATIONS`) + '#ProviderObservations')
    this.exportToExcel(columnsExcel)
  }

  exportToExcel (data) {
    let cols = ''
    data.forEach(element => {
      cols += element + '$'
    })
    cols = cols.substring(0, cols.length - 1)
    let values = this.props.formValues
    this.props.actions.fetchServicePrepareDownload({ ...values.servicePreparationDelivery, ...this.props.filters }, cols)
  }

  componentDidMount () {
    this.props.actions.fetchStagesCombo(dossierSubTypeId.servicePreparationDelivery, true)
    this.props.actions.fetchServicePrepareRoleConfig()
  }

  componentDidUpdate () {
    // Required for getSnapshotBeforeUpdate
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (prevProps.combos.stagesCombo !== this.props.combos.stagesCombo && this.props.combos.stagesCombo.length > 0 && this.state.stages.length === 0) {
      this.setState({ stages: this.props.combos.stagesCombo })
    }
    if ((JSON.stringify(prevProps.serviceRoleConfig) !== JSON.stringify(this.props.serviceRoleConfig)) ||
      (!this.state.serviceRoleConfig && this.props.serviceRoleConfig)) {
      if (this.props.serviceRoleConfig.setStage) {
        let stages = []
        this.state.stages.map(x => {
          if (this.props.serviceRoleConfig.stages.includes(x.id)) {
            stages.push(x)
          }
        })
        let serviceRoleConfigStagesChanged = false
        if (JSON.stringify(stages) !== JSON.stringify(this.state.stages)) {
          this.setState({ stages: stages })
          serviceRoleConfigStagesChanged = true
        }
        const stageFilterIsCleared = (!this.props.fields.stage || this.props.fields.stage.length < 0) && stages.length > 0
        if (stageFilterIsCleared || serviceRoleConfigStagesChanged) {
          let arrStages = []
          arrStages.push(stages[0].id)
          this.props.fieldFill({ stage: arrStages })
          this.props.change('servicePreparationDelivery.stageId', arrStages)
        }
      }
      if (this.props.serviceRoleConfig.setDate) {
        let strdate = formatDate()
        if (this.props.fields.requiredDateFrom === undefined) {
          this.props.fieldFill({ requiredDateFrom: strdate })
          this.props.change('servicePreparationDelivery.requiredDateFrom', strdate)
        }
        if (this.props.fields.requiredDateTo === undefined) {
          this.props.fieldFill({ requiredDateTo: strdate })
          this.props.change('servicePreparationDelivery.requiredDateTo', strdate)
        }
      }
    }

    return null
  }

  getActions (row) {
    const tKey = 'SEARCH.CATEGORIES.SERVICE.'
    switch (row.actions) {
      case '0':
        return <OverlayTrigger id={row.dossierId} trigger={['hover', 'focus']} placement="left" overlay={<Tooltip id="tooltip-trigger-hover-focus">{this.props.t(`${tKey}ALERT_ACTION`)}</Tooltip>}>
          {
            row.url ? (
              <Link to={`/dossier${row.url}`}>
                <i className="iDocIcon-warning _Btn accent stk o-icn noLine"></i>
              </Link>
            ) : <i className="iDocIcon-warning _Btn accent stk o-icn noLine"></i>
          }
        </OverlayTrigger>
      case dossierStagesId.service_preparation_delivery_request.toString() :
        return <OverlayTrigger id={row.dossierId + 'ot'} trigger={['hover', 'focus']} placement="left" overlay={<Tooltip id="tooltip-trigger-hover-focus">{this.props.t(`${tKey}REQUEST_PARTS`)}</Tooltip>}>
          <Button
            onClick={(event) => this.requestParts(row)}
            id={row.dossierId}
            bsStyle="default"
            bsSize="small"
            className="_Btn accent stk o-icn noLine"
          >
            <i className="iDocIcon-arrow-alt-circle-right-uncollapse"></i>
          </Button>
        </OverlayTrigger>
      case dossierStagesId.service_preparation_delivery_confirmation.toString():
        return <OverlayTrigger id={row.dossierId + 'ot'} trigger={['hover', 'focus']} placement="left" overlay={<Tooltip id="tooltip-trigger-hover-focus">{this.props.t(`${tKey}COMPLETE_PARTS`)}</Tooltip>}>
          <Button
            id={row.dossierId}
            onClick={(event) => this.completedParts(row)}
            bsStyle="default"
            bsSize="small"
            className="_Btn new stk o-icn noLine"
          >
            <i className="iDocIcon-arrow-alt-circle-right-uncollapse"></i>
          </Button>
        </OverlayTrigger>
      case dossierStagesId.service_preparation_delivery_realization.toString():
        return <OverlayTrigger id={row.dossierId + 'ot'} trigger={['hover', 'focus']} placement="left" overlay={<Tooltip id="tooltip-trigger-hover-focus">{this.props.t(`${tKey}PREPARE_COMPLETE`)}</Tooltip>}>
          <Button
            id={row.dossierId}
            onClick={(event) => this.completPrepare(row)}
            bsStyle="default"
            bsSize="small"
            className="_Btn new stk o-icn noLine"
          >
            <i className="iDocIcon-done-ok"></i>
          </Button>
        </OverlayTrigger>
      default:
        return ''
    }
  }

  requestParts (row) {
    new Promise((resolve) => {
      this.props.actions.serviceRequestSpareParts(null, row.dossierId, resolve)
    }).then((result) => {
      if (result) {
        if (result === true || result === false) {
          this.props.actions.fetchServicePrepare({ ...this.state.filterValues })
        }
      }
    })
  }

  completedParts (row) {
    new Promise((resolve) => {
      this.props.actions.serviceCompleteSpareParts(null, row.dossierId, resolve)
    }).then((result) => {
      if (result) {
        this.props.actions.fetchServicePrepare({ ...this.state.filterValues })
      }
    })
  }

  completPrepare (row) {
    new Promise((resolve) => {
      this.props.actions.serviceCompleteDeliveryPreparation(null, row.dossierId, resolve)
    }).then((result) => {
      if (result) {
        this.props.actions.fetchServicePrepare({ ...this.state.filterValues })
      }
    })
  }

  validateStage (value, allValues, props) {
    if (props.serviceRoleConfig !== undefined && props.serviceRoleConfig !== null && props.serviceRoleConfig.setStage) {
      if (value !== undefined && value !== null && value.length === 0) {
        return props.t('SEARCH.CATEGORIES.SERVICEPREPARATIONDELIVERY.STAGE_VALIDATION')
      }
    }
  }

  selectedFilters () {
    const filters = this.props.dynamicFilters
    const tKey = 'SEARCH.CATEGORIES.'
    return Object.keys(filters)
      .map((item) => {
        let text = item
        let value = null
        const list = []
        Object.keys(filters[item]).map((filter, index) => {
          text = this.props.t(`${tKey}${item.toUpperCase()}.${fromCamelToUnderscore(filter).toUpperCase()}`)
          switch (filter) {
            case 'stage':
            case 'stageId':
            {
              value = this.extractMultipleCombo(this.props.combos.stagesCombo, filters, item, filter)
              break }
            default:
            { value = filters[item][filter]
              break }
          }

          if (value) {
            if (filter !== 'entityChildSelected') {
              list.push(<li key={index}>{text}: <strong>{value}</strong>;</li>)
            } else {
              list.push(<li key={index}> <strong>{text}</strong>;</li>)
            }
          }
        })
        return list
      })
  }

  extractMultipleCombo (combo, filters, item, filter) {
    const values = combo.filter(object => {
      return filters[item][filter] ? filters[item][filter].some(selectedComboValue => object.id === selectedComboValue) : null
    })

    let value = values && values.map((combovalue, index) => combovalue.value)
      .join(', ')
    return value
  }

  handleClickReset (e) {
    if (e !== undefined) {
      e.preventDefault()
    }

    this.props.dispatch(reset('SearchByPrepareForm'))
    this.state.dossierIdToArray = []
    this.setState({ onlyFleets: true, checkAll: false, dossierIdToArray: [], dossierData: [], fields: [] })
  }

  standardValidations (values) {
    return !values.servicePreparationDelivery || !Object.keys(values.servicePreparationDelivery).filter(item => values.servicePreparationDelivery[item] !== null).length
  }

  submitSearchForm (values) {
    if (this.standardValidations(values)) {
      return this.props.actions.openModal('mustFillFilters')
    }

    this.props.actions.fetchServicePrepare({ ...values.servicePreparationDelivery, ...this.props.filters })
    this.setState({ dossierIdToArray: [], hideFilters: true, dynamicFilters: values, filterValues: { ...values.servicePreparationDelivery, ...this.props.filters } })

    this.props.actions.setFilters(values)
  }

  onPageChange (page) {
    this.setState({ filterValues: { ...this.state.filterValues, page: page } })
    this.props.actions.fetchServicePrepare({ ...this.state.filterValues, page })
  }

  onOrderChange (e) {
    const newOrderBy = this.props.filters.orderBy.includes(`%2B${e.currentTarget.dataset.field}`) ? `-${e.currentTarget.dataset.field}` : `%2B${e.currentTarget.dataset.field}`
    this.setState({ filterValues: { ...this.state.filterValues, orderBy: newOrderBy } })
    this.props.actions.fetchServicePrepare({ ...this.state.filterValues, orderBy: newOrderBy })
  }

  toogleHideFilters () {
    this.setState({ hideFilters: !this.state.hideFilters })
  }

  toogleMaxiTable () {
    this.setState({ maxiTable: !this.state.maxiTable })
  }

  render () {
    const {
      t, fields, fieldFill, className, classNameSelect, hasSearch, prepareData, pages, count,
      handleSubmit, // From search,
      filters: { page }
    } = this.props
    const tKey = 'SEARCH.CATEGORIES.SERVICE.'
    const secMapManagement = MapSectionsManagement.find(function (s) { return s.page === 'searchByPAP' })
    const hasResults = prepareData && prepareData.length > 0
    // const actionToolTip = this.state.dossierIdToArray.length === 0 ? (<Tooltip id="documentTooltip">{t(`${tKey}ACTIONS_NOT_DOSSIER_SELECTED`)}</Tooltip>) : (<Tooltip id="documentTooltip">{t(`${tKey}ACTIONS_TOOLTIP`)}</Tooltip>)

    return (
      <div className="inner-results-panel">
        <div className="section-results-header">
          <div className="section-header-title">
            <OverlayTrigger placement="right" overlay={<Tooltip id="editProductTooltip">{`${t((this.props.hideMenu ? 'GLOBAL.TABLES.SHOW_INNER_MENU' : 'GLOBAL.TABLES.HIDE_INNER_MENU'))}`}</Tooltip>}>
              <a onClick={() => this.props.toogleInnerMenu()} className={this.props.hideMenu ? 'collapseBtn' : 'collapseBtn closeInnerMenu'}><i className={this.props.hideMenu ? 'iDocIcon-Open-inner-menu' : 'iDocIcon-Close-inner-menu'}></i></a>
            </OverlayTrigger>
            <h4>{t(`${secMapManagement.title}`)}
              <OverlayTrigger placement="left" overlay={<Tooltip id="editProductTooltip">{`${t((this.state.hideFilters ? 'GLOBAL.TABLES.SHOW_FILTERS' : 'GLOBAL.TABLES.HIDE_FILTERS'))}`}</Tooltip>}>
                <a onClick={() => this.toogleHideFilters()} className={'toogleShowFilters ' + hideFiltersBtn(hasResults)}><i className={toggleFilterClass(hasResults, this.state.hideFilters)}></i></a>
              </OverlayTrigger>
            </h4>
          </div>
          <Form autoComplete="off" onSubmit={handleSubmit((values) => this.submitSearchForm(values))} className="filters">
            <div className={'search-form ' + (showFilter(hasResults, this.state.hideFilters) ? '' : 'hideFilters ')}>
              <Field
                component={InputSelect}
                colWidht={33} // 25, 33, 50, 75, 100 default=25
                id="stage"
                name='servicePreparationDelivery.stageId'
                placeholder={t(`${tKey}STAGE`)}
                controlLabel={t(`${tKey}STAGE`)}
                menuContainerStyle={{ zIndex: 999 }}
                options={this.state.stages}
                valueKey="id"
                labelKey="value"
                multi
                disabled={this.state.stages.length === 0}
                onInputChange={(val) => fieldFill({ stage: val })}
                customClass={fields.dossierSubType && fields.stage ? classNameSelect : ''}
                validate={[this.validateStage]}
              />
              <Field
                component={InputDatePicker}
                colWidht={33} // 25, 33, 50, 75, 100 default=25
                maxDate={getDateNowMaxDatePicker()}
                id="requiredDateFrom"
                name='servicePreparationDelivery.requiredDateFrom'
                placeholder={t(`${tKey}REQUIRED_DATE_FROM`)}
                controlLabel={t(`${tKey}REQUIRED_DATE_FROM`)}
                validate={[this.validateDate]}
                onInputChange={(val) => fieldFill({ requiredDateFrom: val })}
                customClass={fields.requiredDateFrom ? className : ''}
              />
              <Field
                component={InputDatePicker}
                colWidht={33} // 25, 33, 50, 75, 100 default=25
                maxDate={getDateNowMaxDatePicker()}
                id="requiredDateTo"
                name='servicePreparationDelivery.requiredDateTo'
                placeholder={t(`${tKey}REQUIRED_DATE_TO`)}
                controlLabel={t(`${tKey}REQUIRED_DATE_TO`)}
                validate={[this.validateDate]}
                onInputChange={(val) => fieldFill({ requiredDateTo: val })}
                customClass={fields.requiredDateTo ? className : ''}
              />
              <div className='search-form-actions flexInLine al-R'>
                <Button id="btn_search"
                  type="submit"
                  className="_Btn accept stk">
                  <i className="iDocIcon-search"/>
                  {t('SEARCH.ACTIONS.SEARCH_BUTTON')}
                </Button>
                <Button
                  id="btn_reset"
                  onClick={(e) => this.handleClickReset(e)}
                  className={'_Btn' + (this.props.pristine ? ' accept' : ' accept')}
                  disabled={this.props.pristine}
                >
                  <i className="iDocIcon-clear-decline"/> {t('SEARCH.ACTIONS.RESET_BUTTON')}
                </Button>
              </div>
            </div>
          </Form>
        </div>
        <div className="section-results-body">
          <div className={'searchResoultsContainer' + (this.state.maxiTable ? ' maxiTable' : '')}>
            {hasSearch && ([
              <div className="table-wrapper" key="table-wrapper">
                <ul className="list-inline"> {/* Active filter list */}
                  {this.selectedFilters().length === 0 ? <li>{t(`${tKey}NO_SELECTED_FILTERS`)}</li> : (this.selectedFilters())}
                </ul>
                {/* table options, maximize, etc.... */}
                { hasResults &&
                  <div className='flexInLine al-R'>
                    <OverlayTrigger placement="left" overlay={<Tooltip id="editProductTooltip">{`${t((this.state.maxiTable ? 'GLOBAL.TABLES.MINIMIZE_TABLE' : 'GLOBAL.TABLES.MAXIMIZE_TABLE'))}`}</Tooltip>}>
                      <a onClick={() => this.toogleMaxiTable()} className='_Btn accept stk o-icn maximizeBtn'><i className={(this.state.maxiTable ? 'iDocIcon-Minimize' : 'iDocIcon-Maximize')}></i></a>
                    </OverlayTrigger>
                    <OverlayTrigger placement="left" overlay={<Tooltip id="editProductTooltip">{t('SEARCH.ACTIONS.EXPORT')}</Tooltip>}>
                      <a onClick={(event) => this.exportData(event)} className='_Btn new o-icn exportToExcel'><i className="iDocIcon-file-excel"></i></a>
                    </OverlayTrigger>
                  </div>}
                {/* table */}
                { hasResults && <div className="table-responsive">
                  <SimpleTablePage
                    columns={[
                      <th key={0} data-field="number" onClick={(event) => this.onOrderChange(event)}>
                        {t(`${tKey}DOSSIER_NUMBER`)}&nbsp;<i className={getIconClass('number', this.props.filters)}/>
                      </th>,
                      <th key={1} data-field="stage" onClick={(event) => this.onOrderChange(event)}>
                        {t(`${tKey}STAGE`)}&nbsp;<i className={getIconClass('stage', this.props.filters)}/>
                      </th>,
                      <th key={2} data-field="orderDate" onClick={(event) => this.onOrderChange(event)}>
                        {t(`${tKey}ORDER_DATE`)}&nbsp;<i className={getIconClass('orderDate', this.props.filters)}/>
                      </th>,
                      <th key={3} data-field="requiredDate" onClick={(event) => this.onOrderChange(event)}>
                        {t(`${tKey}REQUIRED_DATE`)}&nbsp;<i className={getIconClass('requiredDate', this.props.filters)}/>
                      </th>,
                      <th key={4} data-field="confirmedDate" onClick={(event) => this.onOrderChange(event)}>
                        {t(`${tKey}CONFIRMED_DATE`)}&nbsp;<i className={getIconClass('confirmedDate', this.props.filters)}/>
                      </th>,
                      settings.clientFieldValidation === clientInformation.VGR && (<th key={5} data-field="orNumber" onClick={(event) => this.onOrderChange(event)}>
                        {t(`${tKey}OR_NUMBER`)}&nbsp;<i className={getIconClass('orNumber', this.props.filters)}/>
                      </th>),
                      <th key={6} data-field="saleObservations" onClick={(event) => this.onOrderChange(event)}>
                        {t(`${tKey}SALE_OBSERVATIONS`)}&nbsp;<i className={getIconClass('saleObservations', this.props.filters)}/>
                      </th>,
                      <th key={7} data-field="providerObservations" onClick={(event) => this.onOrderChange(event)}>
                        {t(`${tKey}PROVIDER_OBSERVATIONS`)}&nbsp;<i className={getIconClass('providerObservations', this.props.filters)} />
                      </th>,
                      <th key={8} data-field="actions" onClick={(event) => this.onOrderChange(event)} className='actions'>
                        {t(`${tKey}ACTIONS`)}&nbsp;<i className={getIconClass('actions', this.props.filters)} />
                      </th>
                    ]}
                    rows={prepareData.map((row, idx) => (
                      <tr key={idx}>
                        <td>
                          <Link to={`/dossier${row.url}`}>
                            {row.number}
                          </Link>
                        </td>
                        <td>{row.stage}</td>
                        <td>{row.orderDate}</td>
                        <td>{row.requiredDate}</td>
                        <td>{row.confirmedDate}</td>
                        {settings.clientFieldValidation === clientInformation.VGR && (
                          <td>{row.orNumber}</td>
                        )}
                        <td className='expandableTextColumn'>
                          <p className='colapseTd'>
                            <ExpandableText text= {row.saleObservations} ></ExpandableText>
                          </p>
                        </td>
                        <td className='expandableTextColumn'>
                          <p className='colapseTd'>
                            <ExpandableText text= {row.providerObservations} ></ExpandableText>
                          </p>
                        </td>
                        <td className='actions'>
                          <div className='content'>
                            {this.getActions(row)}
                          </div>
                        </td>
                      </tr>
                    ))}
                  />
                </div> }
                <div className="search-footer" key="search-footer">
                  <IDocCarPagination
                    id="btn_pag_sales"
                    page={page}
                    pagesCount={pages}
                    totalRows= {count}
                    onSelectAction={(page) => this.onPageChange(page)}
                  />
                </div>
              </div>
            ])}
          </div>
        </div>
      </div>
    )
  }
}

const validate = (values, { t }) => {
  const errors = {}
  if (values) {
    if (values.dossier && values.dossier.chassis && values.dossier.chassis.length < settings.chassisMinCharacter) {
      errors.dossier = {}
      errors.dossier.chassis = t('SEARCH.CATEGORIES.ERRORS.VINCHASIS').replace('##MIN_CHARACTERS##', settings.chassisMinCharacter)
    }
    if (values.vehicle && values.vehicle.chassis && values.vehicle.chassis.length < settings.chassisMinCharacter) {
      errors.vehicle = {}
      errors.vehicle.chassis = t('SEARCH.CATEGORIES.ERRORS.VINCHASIS').replace('##MIN_CHARACTERS##', settings.chassisMinCharacter)
    }

    if (values.dossier) {
      errors.dossier = {}
      if (values.dossier && values.dossier.creationDateFrom && values.dossier.creationDateTo) {
        errors.dossier.creationDateTo = validateDates(t, values.dossier.creationDateFrom, values.dossier.creationDateTo)
      }
      if (values.dossier && values.dossier.orderDateFrom && values.dossier.orderDateTo) {
        errors.dossier.orderDateTo = validateDates(t, values.dossier.orderDateFrom, values.dossier.orderDateTo)
      }
      if (values.dossier && values.dossier.availabilityDateFrom && values.dossier.availabilityDateTo) {
        errors.dossier.availabilityDateTo = validateDates(t, values.dossier.availabilityDateFrom, values.dossier.availabilityDateTo)
      }
      if (values.dossier && values.dossier.vehicleLicensePlateDateFrom && values.dossier.vehicleLicensePlateDateTo) {
        errors.dossier.vehicleLicensePlateDateTo = validateDates(t, values.dossier.vehicleLicensePlateDateFrom, values.dossier.vehicleLicensePlateDateTo)
      }
    }

    if (values.servicePreparationDelivery && values.servicePreparationDelivery.requiredDateFrom && values.servicePreparationDelivery.requiredDateTo) {
      errors.servicePreparationDelivery = {}
      errors.servicePreparationDelivery.requiredDateTo = validateDates(t, values.servicePreparationDelivery.requiredDateFrom, values.servicePreparationDelivery.requiredDateTo)
    }

    if (values.charge && values.charge.chargeDateFrom && values.charge.chargeDateTo) {
      errors.charge = {}
      errors.charge.chargeDateTo = validateDates(t, values.charge.chargeDateFrom, values.charge.chargeDateTo)
    }
  }
  return errors
}

export default reduxForm({
  form: 'SearchByPrepareForm',
  validate
})(SearchByPrepareForm)
