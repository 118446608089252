import { fetchMaritalStatusListSuccess, openEditMaritalStatusModalSuccess, openMaritalStatusModalSuccess, closeMaritalStatusMasterModal } from '../../../actions/masters/maritalStatus/maritalStatus'
import { showLoader, hideLoader } from '../../../actions/common.js'
import { setChangeTabFromConfMasterSuccess } from '../../../actions/masters/masters'

import mastersActionTypes from '../../../constants/actions/masters/masters'

import { handleError } from '../../errors/errorManager'

import { yesNoModal } from '../../modalsListeners/yesNoModal'

import { put, call, select, takeEvery } from 'redux-saga/effects'

import deleteMaritalStatusService from '../../../services/maritalStatus/deleteMaritalStatus'
import postMaritalStatus from '../../../services/maritalStatus/postMaritalStatus'
import postMaritalStatusList from '../../../services/maritalStatus/postMaritalStatusList'
import putMaritalStatus from '../../../services/maritalStatus/putMaritalStatus'
import getLanguageListComboService from '../../../services/language/getLanguageList'

import { getAuth } from '../../../selectors/access/auth'
import { getMaritalStatusFilter } from '../../../selectors/masters/maritalStatus'

export function * deleteMaritalStatus (maritalStatusId) {
  try {
    const confirmed = yield call(yesNoModal, 'deleteMaritalStatus')
    if (confirmed) {
      yield put(showLoader())
      const auth = yield select(getAuth)
      const filter = yield filter || select(getMaritalStatusFilter)
      yield call(deleteMaritalStatusService, auth.token, maritalStatusId)
      yield call(fetchMaritalStatusList, filter)
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDeleteMaritalStatus () {
  yield takeEvery(mastersActionTypes.DELETE_MARITALSTATUS, deleteMaritalStatus)
}

export function * submitMaritalStatus ({ maritalStatus }) {
  try {
    const auth = yield select(getAuth)
    const filter = yield filter || select(getMaritalStatusFilter)
    if (!maritalStatus.maritalStatusId) {
      yield put(showLoader())
      yield call(postMaritalStatus, auth.token, maritalStatus)
    } else {
      yield put(showLoader())
      yield call(putMaritalStatus, auth.token, maritalStatus)
    }

    yield put(closeMaritalStatusMasterModal())
    yield call(fetchMaritalStatusList, filter)
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSubmitMaritalStatus () {
  yield takeEvery(mastersActionTypes.SUBMIT_MARITALSTATUS, submitMaritalStatus)
}

export function * fetchMaritalStatusList ({ filter }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield filter = yield filter || select(getMaritalStatusFilter)
    const maritalStatusList = yield call(postMaritalStatusList, auth.token, filter)
    yield put(fetchMaritalStatusListSuccess(maritalStatusList, filter))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchMaritalStatusList () {
  yield takeEvery(mastersActionTypes.FETCH_MARITALSTATUS_LIST, fetchMaritalStatusList)
}

export function * openMaritalStatusModal ({ maritalStatus }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const languageList = yield call(getLanguageListComboService, auth.token)
    yield put(openMaritalStatusModalSuccess(maritalStatus, languageList))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchOpenMaritalStatusModal () {
  yield takeEvery(mastersActionTypes.OPEN_MARITALSTATUS_MODAL, openMaritalStatusModal)
}

export function * openEditMaritalStatusModal ({ maritalStatus }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const languageList = yield call(getLanguageListComboService, auth.token)
    yield put(openEditMaritalStatusModalSuccess(maritalStatus, languageList))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchOpenEditMaritalStatusModal () {
  yield takeEvery(mastersActionTypes.OPEN_EDIT_MARITALSTATUS_MODAL, openEditMaritalStatusModal)
}

export function * setChangeTabFromConfMaster ({ ouIdFromMaster, changeTabFromMaster }) {
  yield put(setChangeTabFromConfMasterSuccess(ouIdFromMaster, changeTabFromMaster))
}

export function * watchSetChangeTabFromConfMaster () {
  yield takeEvery(mastersActionTypes.SET_CHANGE_TAB_FROM_CONF_MASTER, setChangeTabFromConfMaster)
}
