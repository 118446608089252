
import React, { PureComponent } from 'react'
import { Modal, Tab, Tabs, Button, Col, Row, Tooltip, OverlayTrigger } from 'react-bootstrap'
import SectionComponent from './managementChilds/SectionComponent'
import { documentEntityType as documentEntityTypeConstants } from '../../../../constants/dossier/common/documentEntityType'
import { dossierTypes as dossierTypesConstant } from '../../../../constants/dossier/common/dossierTypes'

class DocumentaryManagementModal extends PureComponent {
  UNSAFE_componentWillReceiveProps (nextProps) {
    if (
      this.props.showModal &&
      nextProps.showModal &&
      this.props.showDocumentEditionModal &&
      !nextProps.showDocumentEditionModal
    ) {
      this.props.actions.refreshDocumentManagementModal(this.props.dossierId)
    }
    if (!this.props.showModal && nextProps.showModal) {
      this.onSelectTab(nextProps.defaultTab, nextProps)
    }
  }

  getEntityTitle () {
    const tKey = 'DOSSIER_COMPONENTS.DOCUMENTARY_MANAGEMENT.'
    switch (this.props.dossierType) {
      case dossierTypesConstant.sales:
        return this.props.t(`${tKey}CLIENT`)
      case dossierTypesConstant.purchase:
        return this.props.t(`${tKey}VENDOR`)
      default:
        return this.props.t(`${tKey}ENTITY`)
    }
  }

  onSelectTab (eventKey, props = this.props) {
    let tab
    switch (eventKey) {
      case 1:
        tab = props.tabCounters.tab1
        break
      case 2:
        tab = props.tabCounters.tab2
        break
      case 3:
        tab = props.tabCounters.tab3
        break
      case 4:
        tab = props.tabCounters.tab4
        break
      default:
        tab = props.tabCounters.tab1
    }
    props.actions.setCurrentTabCounterValues(tab, eventKey)
  }

  render () {
    const {
      t, showModal, dossierId, entityId, vehicleId, showRelatedDocuments = true,
      sectionsDocuments: { tab1 = [], tab2 = [], tab3 = [], tab4 = [] },
      dossierNumber,
      organizedUnit,
      defaultTab = 1,
      dossierSubType,
      stage,
      totalToUpload = 0,
      totalToValidate = 0,
      totalValidated = 0,
      tabCounters: { tabToUpload, tabToValidate, tabValidated },
      entityDs,
      vehicleDs,
      readOnlyFields,
      actions: { openDocumentCreationModal, closeDocumentModalCallbacks, openDocumentEditionModal, cancelDocument, addDependant, printCheckList, openModal },
      dossier: { organizedUnitId }
    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.DOCUMENTARY_MANAGEMENT.'
    const toUploadTooltip = (<Tooltip id="statusTooltip">{t(`${tKey}COUNTERS_TOUP_ICO`)}</Tooltip>)
    const toValidateTooltip = (<Tooltip id="statusTooltip">{t(`${tKey}COUNTERS_TOVAL_ICO`)}</Tooltip>)
    const validatedTooltip = (<Tooltip id="statusTooltip">{t(`${tKey}COUNTERS_VALIDATED_ICO`)}</Tooltip>)
    return (
      <Modal className="management-doc-modal" show={showModal} onHide={() => closeDocumentModalCallbacks()}>
        <Modal.Header closeButton>
          <Modal.Title>{t(`${tKey}TITLE`)}</Modal.Title>
          <Row>
            <Col sm={2}>
              <div className="text-band">
                <span className="second">{t(`${tKey}SUBTITLE`)}:</span>
                <span className="first">{dossierNumber}</span>
              </div>
            </Col>
            <Col sm={2}>
              <div className="text-band">
                <span className="second">{t('DOSSIER_COMPONENTS.HEADER.UO')}:</span>
                <span className="first">{organizedUnit}</span>
              </div>
            </Col>
            <Col sm={2}>
              <div className="text-band">
                <span className="second">{t('DOSSIER_COMPONENTS.HEADER.DOSSIER_SUBTYPE')}:</span>
                <span className="first">{dossierSubType}</span>
              </div>
            </Col>
            <Col sm={2}>
              <div className="text-band">
                <span className="second">{t('DOSSIER_COMPONENTS.HEADER.FUNCTIONAL_STATUS')}:</span>
                <span className="first">{stage}</span>
              </div>
            </Col>
            <Col sm={1} style={{ textAlign: 'right' }}>
              <span className="second">{t(`${tKey}COUNTERS_TITLE`)}:</span>
            </Col>
            <Col sm={2} className="counterIcoColumn">
              <div className="text-band">
                <OverlayTrigger placement="right" overlay={toUploadTooltip}><i className="ico-document-plus" /></OverlayTrigger>
                <span className="first">{totalToUpload}</span>
              </div>
              <div className="text-band">
                <OverlayTrigger placement="right" overlay={toValidateTooltip}><i className="ico-document-pending" /></OverlayTrigger>
                <span className="first">{totalToValidate}</span>
              </div>
              <div className="text-band">
                <OverlayTrigger placement="right" overlay={validatedTooltip}><i className="ico-document-ok" /></OverlayTrigger>
                <span className="first">{totalValidated}</span>
              </div>
            </Col>
          </Row>
          <Row>
            {entityDs &&
              <Col sm={4}>
                <div className="text-band">
                  <span className="second">{this.getEntityTitle()}:</span>
                  <span className="first">{entityDs}</span>
                </div>
              </Col>
            }
            {vehicleDs &&
              <Col sm={5}>
                <div className="text-band">
                  <span className="second">{t(`${tKey}VEHICLE`)}:</span>
                  <span className="first">{vehicleDs}</span>
                </div>
              </Col>
            }
          </Row>
          {/* <p className="number-exp">{t(`${tKey}SUBTITLE`)}: {dossierNumber}</p> */}
        </Modal.Header>
        <Modal.Body>
          <Tabs id="1" defaultActiveKey={defaultTab}
            onSelect={(eventKey) => this.onSelectTab(eventKey)}>
            <Tab eventKey={1} title={this.getEntityTitle()} className="user-tab">
              {
                entityId &&
                <Button
                  className="btn-standard"
                  onClick={() => openDocumentCreationModal(dossierId, documentEntityTypeConstants.ENTITY, organizedUnitId, entityId, vehicleId)}
                >
                  <i className="ico-document-plus" />
                  <span>{t(`${tKey}ADD_OR_EDIT_DOCUMENT.ADD`)}</span>
                </Button>
              }
              {
                tab1.map((section, idx) => (<SectionComponent
                  key={idx}
                  t={t}
                  section={section}
                  documentEntityType={documentEntityTypeConstants.ENTITY}
                  objectId={entityId}
                  actions={{
                    openDocumentEditionModalWithChecklistId: (documentId, documentTypeUse, checklistId) => openDocumentEditionModal(documentId, documentTypeUse, documentEntityTypeConstants.ENTITY, dossierId, entityId, vehicleId, organizedUnitId, checklistId, null, null, null, null, null, null, null, readOnlyFields),
                    cancelDocument,
                    printCheckList,
                    addDependant,
                    openModal
                  }}
                />))
              }
            </Tab>
            <Tab eventKey={2} title={t(`${tKey}VEHICLE`)} className="user-tab">
              {
                vehicleId
                  ? (
                    <Button
                      className="btn-standard"
                      onClick={() => openDocumentCreationModal(dossierId, documentEntityTypeConstants.VEHICLE, organizedUnitId, entityId, vehicleId)}
                    >
                      <i className="ico-document-plus" />
                      <span>{t(`${tKey}ADD_OR_EDIT_DOCUMENT.ADD`)}</span>
                    </Button>
                  ) : null
              }
              {
                tab2.map((section, idx) => (<SectionComponent
                  key={idx}
                  t={t}
                  section={section}
                  documentEntityType={documentEntityTypeConstants.VEHICLE}
                  objectId={vehicleId}
                  actions={{
                    openDocumentEditionModalWithChecklistId: (documentId, documentTypeUse, checklistId) => openDocumentEditionModal(documentId, documentTypeUse, documentEntityTypeConstants.VEHICLE, dossierId, entityId, vehicleId, organizedUnitId, checklistId, null, null, null, null, null, null, null, readOnlyFields),
                    cancelDocument,
                    printCheckList,
                    addDependant,
                    openModal
                  }}
                />))
              }
            </Tab>
            <Tab eventKey={3} title={t(`${tKey}DOSSIER`)} className="user-tab">
              <Button
                className="btn-standard"
                onClick={() => openDocumentCreationModal(dossierId, documentEntityTypeConstants.DOSSIER, organizedUnitId, entityId, vehicleId)}
              >
                <i className="ico-document-plus" />
                <span>{t(`${tKey}ADD_OR_EDIT_DOCUMENT.ADD`)}</span>
              </Button>
              {
                tab3.map((section, idx) => (<SectionComponent
                  key={idx}
                  t={t}
                  section={section}
                  documentEntityType={documentEntityTypeConstants.DOSSIER}
                  objectId={dossierId}
                  actions={{
                    openDocumentEditionModalWithChecklistId: (documentId, documentTypeUse, checklistId) => openDocumentEditionModal(documentId, documentTypeUse, documentEntityTypeConstants.DOSSIER, dossierId, entityId, vehicleId, organizedUnitId, checklistId, null, null, null, null, null, null, null, readOnlyFields),
                    cancelDocument,
                    printCheckList,
                    addDependant,
                    openModal
                  }}
                />))
              }
            </Tab>
            {
              showRelatedDocuments
                ? (
                  <Tab eventKey={4} title={t(`${tKey}RELATED`)} className="user-tab">
                    {
                      tab4.map((section, idx) => (<SectionComponent
                        t={t}
                        key={idx}
                        section={section}
                        readOnlyDoc={true}
                        actions={{}}
                      />))
                    }
                  </Tab>
                ) : null
            }
          </Tabs>
          <div className="counterIcoColumn" style={{ right: '100px', top: '10px', position: 'absolute' }}>
            <div className="second">{t(`${tKey}TAB_COUNTERS_TITLE`)}:</div>
            <div className="text-band">
              <OverlayTrigger placement="right" overlay={toUploadTooltip}><i className="ico-document-plus" /></OverlayTrigger>
              <span className="first">{tabToUpload}</span>
            </div>
            <div className="text-band">
              <OverlayTrigger placement="right" overlay={toValidateTooltip}><i className="ico-document-pending" /></OverlayTrigger>
              <span className="first">{tabToValidate}</span>
            </div>
            <div className="text-band">
              <OverlayTrigger placement="right" overlay={validatedTooltip}><i className="ico-document-ok" /></OverlayTrigger>
              <span className="first">{tabValidated}</span>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    )
  }
}

export default DocumentaryManagementModal
