import reportActionTypes from '../../constants/actions/reports/report'

export function fetchReportTypeCode (resolve, reject) {
  return {
    type: reportActionTypes.FETCH_REPORT_TYPE_CODE,
    resolve,
    reject
  }
}

export function fetchReportTypeCodeSuccess (reportTypeCode) {
  return {
    type: reportActionTypes.FETCH_REPORT_TYPE_CODE_SUCCESS,
    reportTypeCode
  }
}
