import { put, call, takeEvery, select } from 'redux-saga/effects'
import { reset } from 'redux-form'
import deliveryActionsTypes from '../../../constants/actions/dossier/common/delivery'
import { getAuth } from '../../../selectors/access/auth'
import getPrintDeliveryChecklist from '../../../services/dossierSale/getPrintDeliveryChecklist'
import getPrintCheckListVo from '../../../services/dossierSale/getPrintCheckListVo'
import { refreshDossierByType } from '../common/documents/managementModalSagas'
import { dossierTypes as dossierTypesConstant } from '../../../constants/dossier/common/dossierTypes'
import { showLoader, hideLoader } from '../../../actions/common'
import messageModalActions from '../../../actions/modals/messageModal'
import { fetchSalesDossierSuccess } from '../../../actions/dossier/sales'
import getSaleHeaderAndButtons from '../../../services/dossier/get/getSaleHeaderAndButtons'
import getStageCheckList from '../../../services/dossier/get/getStageCheckList'
import { handleError } from '../../errors/errorManager'
import { yesNoModal } from '../../modalsListeners/yesNoModal'
import { initializeDossier } from '../commonDossierSagas'
import getPrintDeliveryCertificates from '../../../services/dossierSale/getPrintDeliveryCertificates'
import { templateTypes } from '../../../constants/backendIds'
import getPrintDeliveryChecklistSale from '../../../services/dossierSale/getPrintDeliveryChecklistSale'

export function * printDeliveryChecklist ({ dossierId, isSalesDossier }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const salesDossier = yield select(state => state.salesDossier)
    yield call(getPrintDeliveryChecklist, auth.token, dossierId, isSalesDossier, salesDossier.lastAccessDate)
    if (isSalesDossier) {
      yield call(refreshDossierByType, {
        dossierType: dossierTypesConstant.sales,
        dossierDocumentChanged: true,
        entityDocumentChanged: false,
        vehicleDocumentChanged: false,
        documentEditionalModalState: null
      })
      const updatedSalesDossier = yield select(state => state.salesDossier)
      let sales
      const headerAndButtons = yield call(getSaleHeaderAndButtons, auth.token, updatedSalesDossier.dossierId)
      const checkListHeader = yield call(getStageCheckList, updatedSalesDossier.dossierId, auth.token)
      sales = { ...headerAndButtons }
      sales = { ...sales, checkListHeader }
      yield put(fetchSalesDossierSuccess(sales))
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          const salesDossierState = yield select(state => state.salesDossier)
          yield call(initializeDossier, dossierTypesConstant.sales, salesDossierState)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchPrintDeliveryChecklist () {
  yield takeEvery(deliveryActionsTypes.PRINT_DELIVERY_CHECKLIST, printDeliveryChecklist)
}

export function * printChecklistVO ({ dossierId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(getPrintCheckListVo, auth.token, dossierId)
    const updatedSalesDossier = yield select(state => state.salesDossier)
    let sales
    const headerAndButtons = yield call(getSaleHeaderAndButtons, auth.token, updatedSalesDossier.dossierId)
    const checkListHeader = yield call(getStageCheckList, updatedSalesDossier.dossierId, auth.token)
    sales = { ...headerAndButtons }
    sales = { ...sales, checkListHeader }
    yield put(fetchSalesDossierSuccess(sales))
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchprintChecklistVO () {
  yield takeEvery(deliveryActionsTypes.PRINT_CHECKLIST_VO, printChecklistVO)
}

export function * printDeliveryCertificates ({ dossierId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(getPrintDeliveryCertificates, auth.token, dossierId, templateTypes.SANITATION_CERTIFICATE)
    yield call(getPrintDeliveryCertificates, auth.token, dossierId, templateTypes.KM_CERTIFICATE)
    yield call(getPrintDeliveryCertificates, auth.token, dossierId, templateTypes.RETURN_WARRANTY_CERTIFICATE)
    yield call(getPrintDeliveryCertificates, auth.token, dossierId, templateTypes.PREPARATION_CERTIFICATE)
    yield call(getPrintDeliveryCertificates, auth.token, dossierId, templateTypes.HISTORY_VERIFICATION_CERTIFICATE)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchPrintDeliveryCertificates () {
  yield takeEvery(deliveryActionsTypes.PRINT_DELIVERY_CERTIFICATES, printDeliveryCertificates)
}

export function * printDeliveryChecklistSale ({ dossierId, deliveryCheckListSale }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const salesDossier = yield select(state => state.salesDossier)
    yield call(getPrintDeliveryChecklistSale, auth.token, dossierId, deliveryCheckListSale, salesDossier.lastAccessDate)

    yield call(refreshDossierByType, {
      dossierType: dossierTypesConstant.sales,
      dossierDocumentChanged: true,
      entityDocumentChanged: false,
      vehicleDocumentChanged: false,
      documentEditionalModalState: null
    })
    const updatedSalesDossier = yield select(state => state.salesDossier)
    let sales
    const headerAndButtons = yield call(getSaleHeaderAndButtons, auth.token, updatedSalesDossier.dossierId)
    const checkListHeader = yield call(getStageCheckList, updatedSalesDossier.dossierId, auth.token)
    sales = { ...headerAndButtons }
    sales = { ...sales, checkListHeader }
    yield put(fetchSalesDossierSuccess(sales))
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          const salesDossierState = yield select(state => state.salesDossier)
          yield call(initializeDossier, dossierTypesConstant.sales, salesDossierState)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchprintDeliveryChecklistSale () {
  yield takeEvery(deliveryActionsTypes.PRINT_DELIVERY_CHECKLIST_SALE, printDeliveryChecklistSale)
}
