import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { closeOperationTypeModal, saveOperationType } from '../../../actions/masters/operationType/operationType'
import OperationTypeMasterModal from '../../../components/masters/operationType/OperationTypeMasterModal'
import { openModal } from '../../../actions/common'

export function mapStateToProps (state) {
  const operationTypeModal = state.operationType.operationTypeModal
  return {
    showModal: operationTypeModal.showModal,
    operationType: operationTypeModal.operationType,
    languageList: state.operationType.languageList,
    initialValues: { operationType: operationTypeModal.operationType, languageList: state.operationType.languageList }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      saveOperationType,
      closeOperationTypeModal,
      openModal
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(OperationTypeMasterModal))
