import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getFormValues } from 'redux-form'
import SearchByFleetsForm from '../../../_v2/components/management/SearchByFleetsForm'
import { translate } from 'react-polyglot'
import { openModal } from '../../../actions/common'
import { fetchFleets, fetchFleetsDownload, setFilters } from '../../../actions/search/search'
import { openDocumentCreationModalFleets } from '../../../actions/dossier/common/documentEditionModal'

export function mapStateToProps (state) {
  return {
    ...state.search,
    combos: {
      salesmanCombo: state.combos.salesmanCombo,
      activeInactiveCombo: state.combos.activeInactiveCombo
    },
    formValues: getFormValues('SearchByFleetsForm')(state)
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      openModal,
      setFilters,
      fetchFleets,
      fetchFleetsDownload,
      openDocumentCreationModalFleets
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(SearchByFleetsForm))
