import { put, call, select, takeEvery } from 'redux-saga/effects'
import { getAuth } from '../../../selectors/access/auth'
import { initialize, reset, change } from 'redux-form'
import { yesNoModal } from '../../modalsListeners/yesNoModal'
import dossierActionsTypes from '../../../constants/actions/dossier/dossier'
import { dossierTypes as dossierTypesConstant } from '../../../constants/dossier/common/dossierTypes'
import { entityRequiredFields } from '../../../constants/validatedFields/entity'
import getEntityFilterSubType from '../../../services/entity/getEntityFilterSubType'
import putDossierPurchaseService from '../../../services/dossierPurchase/put/putDossierPurchase'
import { showLoader, hideLoader } from '../../../actions/common'
import messageModalActions from '../../../actions/modals/messageModal'
import { fetchCombosSuccess } from '../../../actions/combos/combos'
import { fetchPurchaseDossierSuccess } from '../../../actions/dossier/purchase'
import { handleError } from '../../errors/errorManager'
import { initializeDossier } from '../commonDossierSagas'

export function * savePurchaseDossier ({ purchaseDossier }) {
  try {
    const auth = yield select(getAuth)
    const purchaseDossierState = yield select(state => state.purchaseDossier)
    const myEntityRequiredFields = entityRequiredFields(purchaseDossier.entityComponent ? purchaseDossier.entityComponent.entityTypeId : null)
    let willBeAnyImportantFieldUpdated = false
    let confirmedSaveWarning = true
    for (const prop in myEntityRequiredFields) {
      if (purchaseDossierState.entityComponent.entityId && purchaseDossier.entityComponent[prop] !== purchaseDossierState.entityComponent[prop]) {
        willBeAnyImportantFieldUpdated = true
        break
      }
    }
    if (willBeAnyImportantFieldUpdated || (purchaseDossierState.entityComponent.previousEntity && purchaseDossierState.entityComponent.previousEntity.entityId && purchaseDossier.entityComponent.entityId && purchaseDossier.entityComponent.entityId && purchaseDossier.entityComponent.entityId !== purchaseDossierState.entityComponent.previousEntity.entityId)) {
      confirmedSaveWarning = yield call(yesNoModal, 'commonEntitySaveWarning')
    }
    if (confirmedSaveWarning) {
      const subDossier = {
        dossierEntityComponent: purchaseDossier.entityComponent,
        dossierContactComponent: purchaseDossier.dossierContactComponent,
        dossierVehicleComponent: { ...purchaseDossierState.vehicleComponent, ...purchaseDossier.vehicleComponent },
        dossierDeliveryComponent: purchaseDossier.dossierCompleteDeliveryComponent ? purchaseDossier.dossierCompleteDeliveryComponent.dossierDeliveryComponent : {},
        dossierPurchaseComponent: purchaseDossier.dossierPurchaseComponent,
        dossierPurchaseEconomicPlanComponent: purchaseDossier.dossierPurchaseEconomicPlanComponent,
        dossierCompleteOtherDataComponent: purchaseDossier.dossierCompleteOtherDataComponent,
        customFields: purchaseDossier?.customFields ? Object.keys(purchaseDossier.customFields).map(x => { return { key: x, value: purchaseDossier.customFields[x] } }) : []
      }
      let confirmed = false
      if (subDossier.dossierPurchaseComponent.rescueLeasing && purchaseDossierState.dossierPurchaseComponent.rescueLeasing === false) {
        confirmed = yield call(yesNoModal, 'rescueLeasingAlert')
        if (!confirmed) {
          return null
        }
      }
      if (subDossier.dossierPurchaseComponent.additionalInvoice && purchaseDossierState.dossierPurchaseComponent.additionalInvoice === false) {
        confirmed = yield call(yesNoModal, 'additionalInvoiceAlert')
        if (!confirmed) {
          return null
        }
      }
      yield put(showLoader())
      if (purchaseDossierState.vehicleComponent !== purchaseDossier.vehicleComponent) {
        subDossier.dossierVehicleComponent.updated = true
      }

      if (purchaseDossier?.customFields) {
        purchaseDossierState.customFields = {}
        Object.keys(purchaseDossier.customFields).map(x => {
          purchaseDossierState.customFields[x] = purchaseDossier.customFields[x]
        })
      }

      yield call(putDossierPurchaseService, purchaseDossier.dossierId, subDossier, auth.token, purchaseDossierState.lastAccessDate)
      yield put(messageModalActions.openSuccessMessageModal('MESSAGES.PUT_DOSSIER_SUCCESS'))
      yield call(initializeDossier, dossierTypesConstant.purchase, purchaseDossier)
      if (purchaseDossier.dossierPurchaseEconomicPlanComponent?.amortization != null) {
        yield put(change('purchase_dossier', 'dossierPurchaseEconomicPlanComponent', purchaseDossier.dossierPurchaseEconomicPlanComponent))
        yield put(fetchPurchaseDossierSuccess({ dossierPurchaseEconomicPlanComponent: purchaseDossier.dossierPurchaseEconomicPlanComponent }))
        const dossierPurchaseEconomicPlanComponent = purchaseDossier.dossierPurchaseEconomicPlanComponent
        yield put(initialize('purchase_dossier', { ...purchaseDossier, dossierPurchaseEconomicPlanComponent }))
      }
    } else {
      if (purchaseDossierState.entityComponent.previousEntity.entitySubTypeId) {
        const entityFilterSubTypeCombo = yield call(getEntityFilterSubType, auth.token, purchaseDossierState.entityComponent.previousEntity.entityTypeId, purchaseDossierState.entityComponent.previousEntity.entitySubTypeId, purchaseDossierState.organizedUnitId)
        yield put(fetchCombosSuccess({ entityFilterSubTypeCombo }))
      }
      if (purchaseDossier?.customFields) {
        purchaseDossierState.customFields = {}
        Object.keys(purchaseDossier.customFields).map(x => {
          purchaseDossierState.customFields[x] = purchaseDossier.customFields[x]
        })
      }
      yield put(fetchPurchaseDossierSuccess({ entityComponent: { ...purchaseDossierState.entityComponent.previousEntity, previousEntity: purchaseDossierState.entityComponent.previousEntity } }))
      yield put(initialize('purchase_dossier', { ...purchaseDossier, entityComponent: purchaseDossierState.entityComponent.previousEntity, customFields: purchaseDossierState.customFields }))
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          yield call(initializeDossier, dossierTypesConstant.purchase, purchaseDossier)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('purchase_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSavePurchaseDossier () {
  yield takeEvery(dossierActionsTypes.SAVE_PURCHASE_DOSSIER, savePurchaseDossier)
}
