import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import SearchByPlanLogisticsPapForm from '../../../components/search/SearchByPlanLogisticsPapForm'
import { saveServicesConfirmationLogitics } from '../../../actions/dossier/serviceDossier'
import { translate } from 'react-polyglot'
import { resetFilters } from '../../../actions/search/search'

export function mapStateToProps (state) {
  return {
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      resetFilters,
      saveServicesConfirmationLogitics
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(SearchByPlanLogisticsPapForm))
