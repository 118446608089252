import apiFetch from '../apiFetch'

export default function (token, { page, pageSize, orderBy, stageId, requiredDateFrom, requiredDateTo }) {
  const queryParamsArray = [
    page ? `page=${page}` : null,
    pageSize ? `pageSize=${pageSize}` : null,
    orderBy ? `orderBy=${orderBy}` : null,
    stageId ? `stageId=${stageId}` : null,
    requiredDateFrom ? `requiredDateFrom=${requiredDateFrom}` : null,
    requiredDateTo ? `requiredDateTo=${requiredDateTo}` : null
  ]
  const queryParams = queryParamsArray.reduce(
    (result, query, idx) => result + (query ? (result ? `&${query}` : `?${query}`) : '')
    , ''
  )
  return apiFetch({
    endPoint: `DossierFinder/DossierServicePreparation${queryParams}`,
    method: 'GET',
    body: null,
    token: token
  })
}
