import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import FaqMasterModal from '../../../components/masters/faq/FaqMasterModal'
import { translate } from 'react-polyglot'
import { closeFaqModal, saveFaqMasterModal, fecthFaqSectionComboModal } from '../../../actions/masters/faq/faqMaster'

export function mapStateToProps (state) {
  return {
    showFaqModal: state.faqMaster.faqModal.showModal,
    languageList: state.faqMaster.faqModal.languages,
    faqTranslations: state.faqMaster.faqModal.faqFields.faqTranslations || [],
    faqId: state.faqMaster.faqModal.faqFields.faqId,
    faqSectionId: state.faqMaster.faqModal.faqFields.faqSectionId,
    organizedUnitId: state.faqMaster.faqModal.faqFields.organizedUnitId,
    rating: state.faqMaster.faqModal.faqFields.rating,
    combos: {
      UOTreeCombo: state.combos.UOTreeCombo,
      faqSectionCombo: state.faqMaster.faqModal.faqSectionCombo || []
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      closeFaqModal,
      saveFaqMasterModal,
      fecthFaqSectionComboModal
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(FaqMasterModal))
