import faqMasterActionTypes from '../../../constants/actions/masters/faq/faqMaster'

export function fetchFaqList (filter) {
  return {
    type: faqMasterActionTypes.FETCH_FAQ_LIST,
    filter
  }
}

export function openFaqMaster () {
  return {
    type: faqMasterActionTypes.OPEN_FAQ_MASTER
  }
}

export function fetchFaqListSuccess (faqMasterList, filter) {
  return {
    type: faqMasterActionTypes.FETCH_FAQ_SUCCESS_LIST,
    faqMasterList,
    filter
  }
}

export function saveFaqMasterModal (faqSectionId, faqValues, faqId, rating) {
  return {
    type: faqMasterActionTypes.SAVE_FAQ,
    faqSectionId,
    faqValues,
    faqId,
    rating
  }
}

export function deleteFaq (faqId) {
  return {
    type: faqMasterActionTypes.DELETE_FAQ,
    faqId
  }
}

export function openFaqModal (faqId = null) {
  return {
    type: faqMasterActionTypes.OPEN_FAQ_MODAL,
    faqId
  }
}

export function openFaqModalSuccess (languageList, faqMaster, faqSectionCombo, organizedUnitId) {
  return {
    type: faqMasterActionTypes.OPEN_FAQ_MODAL_SUCCESS,
    languageList,
    faqMaster,
    faqSectionCombo,
    organizedUnitId
  }
}

export function recoverFaqModal (faqId) {
  return {
    type: faqMasterActionTypes.RECOVER_FAQ,
    faqId
  }
}

export function closeFaqModal () {
  return {
    type: faqMasterActionTypes.CLOSE_FAQ_MODAL
  }
}

export function fecthFaqSectionCombo (organizedUnitId = null) {
  return {
    type: faqMasterActionTypes.FETCH_FAQ_SECTION_COMBO,
    organizedUnitId
  }
}

export function loadFaqSectionComboFilter (faqSectionsCombo = []) {
  return {
    type: faqMasterActionTypes.LOAD_FAQ_SECTION_COMBO_FILTER,
    faqSectionsCombo
  }
}

export function fecthFaqSectionComboModal (organizedUnitId = null) {
  return {
    type: faqMasterActionTypes.FETCH_FAQ_SECTION_COMBO_MODAL,
    organizedUnitId
  }
}

export function loadFaqSectionComboModal (faqSectionsCombo = []) {
  return {
    type: faqMasterActionTypes.LOAD_FAQ_SECTION_COMBO_MODAL,
    faqSectionsCombo
  }
}
