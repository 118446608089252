import Settings from './settings'

export default {
  ...Settings,
  urlHotFolder: '',
  webSocketUrl: 'wss://vgrs.idoccar.com:8007',
  notificationRefresh: 60000,
  showSignDocumentButton: false,
  acceptFileFormats: 'application/pdf, .jpg, .png, .jpeg, .gif, .docx, .doc, .xls, .xlsx, .ppt, .pptx, .msg, .tiff',
  qaSite: false,
  dealerMaxLength: 7,
  dealerNumberRegex: null,
  clientFieldValidation: 'vgr',
  autoLoadDashboard: false,
  validateLicensePlate: true,
  maxTagCount: 2,
  maxTagTextLength: 10,
  matomoSiteId: '3',
  orderDateLimitHour: '12',
  enableFavoritesAndRecents: true,
  enableTaskFollowUp: true,
  dashboardVersion: 2,
  paymentGatewayUrl: 'https://canales.redsys.es/admincanales-web/index.jsp#/login'
}
