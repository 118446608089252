import React, { PureComponent } from 'react'
import { Modal, Col, Row, FormGroup, Button } from 'react-bootstrap'
import InputText from '../../commons/form/InputText'
import InputDatePicker from '../../commons/form/InputDatePicker'
import InputSelect from '../../commons/form/InputSelect'
import settings from '../../../setting'
import { clientInformation } from '../../../constants/backendIds'

import { reduxForm, Field } from 'redux-form'

class IssueModal extends PureComponent {
  constructor () {
    super()
    this.state = {}
  }

  componentDidUpdate (prevProps) {
    if (this.props.showModal !== prevProps.showModal && this.props.showModal === true) {
      this.props.initialize(this.props.service.dossierServiceComponent)
      this.props.actions.fetchIssueEntity()
    }
  }

  submitService (values) {
    if (values) {
      if (this.props.service.dossierServiceComponent &&
        values.availabilityDate !== this.props.service.dossierServiceComponent?.availabilityDate &&
        settings.clientFieldValidation === clientInformation.VGR) {
        values.requiredDate = null
      }
      const body = {
        ...values
      }
      this.props.actions.saveIssueService(body)
    }
  }

  render () {
    const { handleSubmit, showModal, actions: { openOrCloseModal }, combos: { issuesEntities }, t } = this.props
    const tKey = 'DOSSIER_COMPONENTS.SERVICE_DOSSIER.SERVICE_COMPONENT.ISSUE_MODAL.'
    return (
      <Modal className="role-modal languages-modal" show={showModal} onHide={openOrCloseModal}>
        <form autoComplete="off" onSubmit={handleSubmit(this.submitService.bind(this))}>
          <Modal.Header closeButton onHide={openOrCloseModal}>
            <Modal.Title> {t(`${tKey}TITLE`)} </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <Row className="inputs-wrapper">
                {settings.clientFieldValidation === clientInformation.VGR ? (
                  <Col sm={4} className="custom-width-col">
                    <h2>{t(`${tKey}AVAILABILITY_DATE`)}</h2>
                    <Field
                      id="availabilityDate"
                      name="availabilityDate"
                      placeholder={t(`${tKey}AVAILABILITY_DATE`)}
                      component={InputDatePicker}
                    />
                  </Col>
                ) : (
                  <Col sm={4} className="custom-width-col">
                    <h2>{t(`${tKey}CONFIRMED_DATE`)}</h2>
                    <Field
                      id="confirmedDate"
                      name="confirmedDate"
                      placeholder={t(`${tKey}CONFIRMED_DATE`)}
                      component={InputDatePicker}
                    />
                  </Col>)
                }
                <Col sm={4} className="custom-width-col">
                  <h2>{t(`${tKey}RESPONSABLE`)}</h2>
                  <Field
                    name="issueEntityId"
                    placeholder={t(`${tKey}RESPONSABLE`)}
                    valueKey="id"
                    labelKey="value"
                    options={issuesEntities}
                    component={InputSelect}
                    colSm={0}
                  />
                </Col>
                <div className="clearfix" />
                <Col sm={12} className="custom-width-col">
                  <h2>{t(`${tKey}PROVIDER_OBSERVATIONS`)}</h2>
                  <Field
                    id="providerObservations"
                    name="providerObservations"
                    placeholder={t(`${tKey}PROVIDER_OBSERVATIONS`)}
                    component={InputText}
                    customClass="textarea-noresize"
                    componentClass="textarea"
                    colSm={0}
                  />
                </Col>
              </Row>
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <Col sm={12} className="btn-wrapper">
              <Button
                id="role-modal-submit-button"
                className="btn-standard"
                type="submit"
                disabled={this.props.pristine}>
                {t(`${tKey}SAVE`)}
              </Button>
            </Col>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default reduxForm({
  form: 'issueServiceModal',
  validate: (values, props) => {
    const error = { agency: {} }
    const tKey = 'DOSSIER_COMPONENTS.SERVICE_DOSSIER.SERVICE_COMPONENT.ISSUE_MODAL.VALIDATIONS.'
    if (!values.confirmedDate) {
      error.confirmedDate = props.t(`${tKey}CONFIRMED_DATE`)
    }
    if (!values.providerObservations) {
      error.providerObservations = props.t(`${tKey}PROVIDER_OBSERVATIONS`)
    }
    if (!values.issueEntityId) {
      error.issueEntityId = props.t(`${tKey}RESPONSABLE`)
    }
    return Object.keys(error).length > 0 ? error : {}
  }
})(IssueModal)
