
import React, { PureComponent } from 'react'
import { Modal, Col, Row, FormGroup, Button } from 'react-bootstrap'
import { reduxForm, Field } from 'redux-form'
import TextInput from '../../commons/form/InputText'

class PaymentMethodModal extends PureComponent {
  submitPayment (values) {
    Object.keys(values).map(item => {
      if (values[item].languageId === undefined || values[item].languageId === null && (values[item].description !== null || values[item].name !== null)) {
        values[item].languageId = item.slice(-1)
      }
    })
    const body = []
    Object.keys(values).map(item => {
      body.push(values[item])
    })
    this.props.actions.submitPaymentMethod(body, this.props.paymentMethodId)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.showModal !== this.props.showModal && nextProps.showModal === true) {
      let init = {}
      this.props.initialize({ ...init })
    }
    if (this.props.paymentMethodTypeLanguages !== nextProps.paymentMethodTypeLanguages &&
        nextProps.paymentMethodTypeLanguages.length > 0) {
      nextProps.languageList.map((language) => {
        this.props.change(`lang${language.languageId}.languageId`, language.languageId)
        const source = nextProps.paymentMethodTypeLanguages.find((sourceChannel) => sourceChannel.languageId === language.languageId)
        this.props.change(`lang${language.languageId}.name`, source ? source.name : '')
      })
    }
  }

  render () {
    const {
      t, handleSubmit, languageList = [],
      showModal
    } = this.props
    const tKey = 'MASTERS.PAYMENT_METHOD_MASTERS.MODAL.'
    return (
      <Modal className="languages-modal" show={showModal} onHide={this.props.actions.closeEditPaymentMethodModal}>
        <form autoComplete="off" onSubmit={handleSubmit(this.submitPayment.bind(this))}>
          <Modal.Header closeButton onHide={this.props.actions.closeEditPaymentMethodModal}>
            <Modal.Title>{t(`${tKey}TITLE`)}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="title-column">
              <Col sm={4}>
                <h2>{t('MASTERS.NAME')}</h2>
              </Col>
              <Col sm={2}>
                <h2>{t('MASTERS.LANGUAGE')}</h2>
              </Col>
            </Row>
            <FormGroup>
              {languageList.map((val, idx) => {
                return (
                  <Row key={idx} className="inputs-wrapper">
                    <Col sm={4} className="custom-width-col">
                      <Field
                        name={`lang${val.languageId}.name`}
                        placeholder={t('MASTERS.NAME')}
                        component={TextInput}
                        maxLength={40}
                      />
                    </Col>
                    <Col sm={2} className="language-column">
                      <p>{`${val.description} (${val.name})`}</p>
                    </Col>
                  </Row>
                )
              })}
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <Col sm={12} className="btn-wrapper">
              <Button
                id="role-modal-submit-button"
                className="btn-standard"
                type="submit"
                disabled={this.props.pristine}>
                {t(`${tKey}SUBMIT_BUTTON`)}
              </Button>
            </Col>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}
const validate = (values, props) => {
  const errors = {}
  props.languageList.map(language => {
    errors[`lang${language.languageId}`] = {}
    if (values[`lang${language.languageId}`]) {
      if (!(values[`lang${language.languageId}`].name)) {
        errors[`lang${language.languageId}`].name = props.t('MASTERS.VALIDATION.NAME_REQUIRED')
      }
    } else {
      errors[`lang${language.languageId}`].name = props.t('MASTERS.VALIDATION.NAME_REQUIRED')
    }
  })
  return errors
}

export default reduxForm({
  form: 'editPaymentMethod',
  validate
})(PaymentMethodModal)
