export const purchaseTypesVn = {
  normal: 1,
  transfer: 5,
  stock: 12,
  production: 13
}

export const purchaseTypesVo = {
  normal: 1,
  scrapping: 4,
  buyBack: 30,
  internal: 31
}
