import { fork, all } from 'redux-saga/effects'
import { watchFetchAuth, watchResetPassword, watchLogout, watchRememberPassword, watchSetOrganizedUnit, watchCheckToken, watchEncryptQrContent, watchFetchReleaseNotesFile } from './auth/auth'
import { watchCloseReleaseNotesModal } from './modals/releaseNotes'
import { watchFetchPasswordPolicy } from './auth/fetchPasswordPolicy'
import {
  watchFetchUserProfile, watchUpdateUserProfile, watchFetchComboSalesmanByUO, watchSaveEventSubscription, watchDeleteEventSubscription,
  watchFetchEventSubscriptions, watchEnableEventSubscription, watchDisableEventSubscription
} from './profile/profile'
import { watchGetUserManualUrl } from './profile/usersManual'
import { watchFetchInitialNotificationsCounter, watchFetchDocumentNotifications, watchFetchDossierNotifications, watchMarkDossierNotificationAsRead } from './notifications/notifications'
import { watchFetchLanguagesAvailable } from './language/fetchLanguagesAvailable'
import { watchFetchDashboard, watchOpenUploadDocumentFleetModal, watchCheckCanCloseUploadDocumentFleetModal, watchCloseUploadDocumentFleetModal, watchDashboardUploadDocumentFleet, watchFetchCreateDossierButton, watchFetchDashboardCreateButtons, watchFetchDossierFavorites, watchFetchDossierLastAccessed, watchFetchTaskFavorites, watchSwitchCardTaskFavorite, watchSwitchCardDossierFavorite } from './dashboard/fetchDashboard'
import { watchFetchFilteredDashboard } from './dashboard/fetchFilteredDashboard'
import { watchFetchDossiersList, watchFetchDossiersCompleteList, watchExportDossiersCompleteList } from './dashboard/dossiersList/fetchDossiersList'
import { watchFetchFilteredDashboardTask } from './dashboard/fetchFilteredDashboardTask'
import { watchGetFilteredTaskToExcel } from './dashboard/getFilteredTaskToExcel'
import { watchFetchDashboardTask } from './dashboard/fetchDashboardTask'
import { watchFetchEntitySubTypeCombo } from './combos/fetchEntitySubTypeCombo'
import { watchFetchMaritalStatusCombo } from './combos/fetchMaritalStatusCombo'
import { watchFetchEventsCombo } from './combos/fetchEventsCombo'
import { watchFetchStages } from './combos/fetchStages'
import { watchFetchEntityCountryTypeCombo } from './combos/fetchEntityCountryTypeCombo'
import { watchFetchEntityTownTypeCombo } from './combos/fetchEntityTownTypeCombo'
import { watchFetchEntityCityTypeCombo } from './combos/fetchEntityCityTypeCombo'
import { watchFetchEntityColonyTypeCombo } from './combos/fetchEntityColonyTypeCombo'
import { watchFetchEntityCPTypeCombo } from './combos/fetchEntityCPTypeCombo'
import { watchFetchEntityStateTypeCombo } from './combos/fetchEntityStateTypeCombo'
import { watchFetchLanguages } from './combos/fetchLanguages'
import { watchFetchIssuesEntity } from './combos/fetchIssuesEntity'
import { watchFetchEntityTypes } from './combos/fetchEntityTypes'
import { watchFetchEntityFilterSubTypeCombo } from './combos/fetchEntityFilterSubTypeCombo'
import { watchFetchEntitiesByTypeByParent } from './combos/fetchEntitiesByTypeByParent'
import { watchFetchOperationType, watchFleetOperationTypeForAutoselect } from './combos/fetchOperationsTypes'
import { watchFetchFinancingTypes } from './combos/fetchFinancingTypes'
import { watchFetchLowestOrganizedUnits } from './combos/fetchLowestOrganizedUnits'
import { watchFecthOrganizedUnitsByDossierUo } from './combos/fecthOrganizedUnitsByDossierUo'
import { watchFetchOrganizedUnitsTree, watchFetchOrganizedUnitsTreeComboByPermission } from './combos/fetchOrganizedUnitsTree'
import { watchFetchDossierTypes } from './combos/fetchDossierTypes'
import { watchFetchDossierSubTypes } from './combos/fetchDossierSubTypes'
import { watchFetchSalesman } from './combos/fetchSalesman'
import { watchFetchSaleType, watchFetchSaleTypeByOperationTypeId } from './combos/fetchSaleType'
import { watchFetchSaleTypeBySubtype } from './combos/fetchSaleTypeBySubtype'
import { watchFetchPurchaseType } from './combos/fetchPurchaseType'
import { watchFetchTuneUpDestination } from './combos/fetchTuneUpDestination'
import { watchFetchPaperworkType } from './combos/fetchPaperworkType'
import { watchFetchCampaignType, watchFetchCampaignTypeByGroup, watchFetchCampaignClass, watchFetchCampaignGroup } from './combos/fetchCampaignType'
import { watchFetchExtraSaleTypes } from './combos/fetchExtraSaleTypes'
import { watchFetchBrands } from './combos/fetchBrands'
import { watchFetchFinancingCompanies, watchFetchFinancingCompaniesBy } from './combos/fetchFinancingCompanies'
import { watchFetchVehicleType } from './combos/fetchVehicleType'
import { watchFetchSearchDossierSubType } from './combos/fetchSearchDossierSubType'
import { watchFetchTemplateTypes, watchFetchTemplateFields } from './combos/fetchTemplateTypes'
import { watchFetchDocumentsAllTypes } from './combos/fetchDocumentsAllTypes'
import { watchFetchDocumentStatus } from './combos/fetchDocumentStatus'
import { watchFetchMassiveDownloadStatus } from './combos/fetchMassiveDownloadStatus'
import { watchFetchDocumentTypeUse } from './combos/fetchDocumentTypeUse'
import { watchFetchSignTypeCombo } from './combos/fetchSignTypeCombo'
import { watchFetchSignEntityTypeCombo } from './combos/fetchSignEntityTypeCombo'
import { watchPaymentMethodCampaign } from './combos/fetchPaymentMethodCampaign'
import { watchFetchBankCombo } from './combos/fetchBankCombo'
import { watchFetchCountries } from './combos/fetchCountry'
import { watchFetchDocumentTypeEntities } from './combos/fetchDocumentTypeEntities'
import { watchFetchDocumentType } from './combos/fetchDocumentType'
import { watchFetchOrganizedUnitsAll } from './combos/fetchOrganizedUnitsAll'
import { watchFetchSaleTypeAll } from './combos/fetchSaleTypeAll'
import { watchFetchOperationTypeAll } from './combos/fetchOperationTypeAll'
import { watchFetchFleets, watchfetchFleetsUser, watchfetchFleetsEntity, watchgetCheckChangeEntityDossier } from './combos/fetchFleets'
import { watchChangeFilter, watchChangeFilterString } from './units/fetchUnits'
import { watchInitializeUnits } from './units/initializeUnits'
import { watchSaveNewUnit } from './units/unitsModal/updateUnits'
import { watchDeleteUnit } from './units/deleteUnit'
import {
  watchSubmitNewDossier, watchStoreDossier, watchUpdateDossierCommon, watchUndoCancel, watchConfirmCancel, watchRefreshAuditInfo, watchSendDocumentToSign, watchDocumentsToSendSignGetAll, watchDocumentsToSendSign, watchOpenDocWarning, watchRefreshAuditDetails, watchRefreshAuditDetailsModal, watchputCancelDossierPurchaseSelf,
  watchUpdateErrorModel, watchCheckCurrencyDocTypes, watchRefreshAuditFleetInfo, watchAuditFleetInfoDownload, watchFetchDocumentsGroupedAuditDetails, watchDocumentsGroupedAuditDetailsModal, watchFetchOpenCodeCommisionModal, watchFetchCodeCommissionEconomicPlan, watchFetchCodeCommissionCombo
} from './dossier/commonDossierSagas'
import { watchFetchSalesDossier, watchFetchSalesDossierDependants, watchFetchSalesDossierDependantsEconomic, watchFetchSalesVehicle, watchFetchSalesClient, watchfetchSalesFinancing, watchfetchSalesDelivery, watchfetchSalesDeliveryHistorical, watchfetchSalesFinancingHistorical, watchFetchSalesVehicleHistorical, watchFetchSalesClientHistorical, watchfetchSalesOtherData, watchfetchSalesOtherDataHistorical, watchfetchSalesEconomic, watchFetchSalesServices, watchfetchSalesSelfSupply, watchfetchSalesSelfSupplyHistorical, watchcheckPrivateRfcFormat, watchforeignPassportWarning, watchFetchSalesInsuranceCarrier, watchFetchSalesInsuranceCarrierHistorical } from './dossier/fetchSalesDossier'
import { watchSaveSalesDossier } from './dossier/sales/saveSalesDossier'
import { watchSaveFleetCode } from './dossier/sales/saveFleetCode'
import { watchSaveRegistrationDeliveryDossier } from './dossier/registrationDelivery/saveRegistrationDeliveryDossier'
import { watchUpdateDependantsSaleDossierAndHeader } from './dossier/sales/updateDependantsSaleDossierAndHeader'
import { watchFetchAmountTaxModal } from './dossier/sales/amountWithTax'
import { watchPrintDocumentaryChecklist, watchPrintDocumentaryWarranty, watchprintSummarySale, watchPrintCompeteSummarySale } from './dossier/sales/salesComponent'
import { watchFetchClientByDni, watchFetchClientByDniEntityChild, watchFetchClientByRfc } from './dossier/common/client/fetchClientByDni'
import { watchSetEntityTypeId, watchSetEntityProviderTypeId, watchSetEntitySubTypeId, watchSetEntityProviderSubTypeId, watchSetEntityId, watchSetEntityProviderId, watchSetClientFromParent, watchSetEntityChildTypeId, watchSetEntityValidationDisable, watchClearClientFields } from './dossier/common/client/saveClient'
import { watchFetchClientFromDocument, watchFetchClientUacModal, watchFetchProviderFromDocument, watchResetValues } from './dossier/common/client/fetchClientFromDocument'
import { watchSearchVehicle, watchRefreshVehicle, watchClearVehicle, watchFetchVehicleSelectionModal, watchCreateVehicleCreationModal } from './dossier/common/vehicle/searchVehicle'
import { watchFetchOrder, watchOrderFetchCatalogueModel } from './dossier/common/vehicle/fetchOrder'
import { watchSaveOrder } from './dossier/common/vehicle/saveOrder'
import { watchFetchDossierRelatedDossiers } from './dossier/common/dossierRelatedDossiers'
import { watchCreateExtra, watchDeleteExtra, watchUpdateExtra } from './dossier/sales/dossierExtras'
import { watchCreateDiscount, watchDeleteDiscount, watchUpdateDiscount, watchFetchDiscountModal } from './dossier/sales/discounts'
import { watchCreateComment, watchDeleteComment, watchUpdateComment, watchMarkAsReadComment, watchGetComments } from './dossier/common/comments'
import { watchFetchUACUnitEdition, watchDeleteUAC, watchCreateUAC, watchUpdateUAC, watchChooseUACSuggestion, watchFetchUACClientFromDocument } from './dossier/common/uac'
import { watchFetchCampaignEdition, watchDeleteCampaign, watchUpdateCampaign, watchCreateCampaign, watchFetchAllDocuments, watchFetchAllDocumentHistoricals, watchSaveSelectedDocuments } from './dossier/sales/campaigns'
import { watchCreateAssociatedCosts, watchDeleteAssociatedCosts, watchUpdateAssociatedCosts, watchFetchAssociatedCostByPercentageModal } from './dossier/sales/associatedCosts'
import { watchFetchPaperworkEditionModal, watchDeletePaperwork, watchCreatePaperwork, watchUpdatePaperwork, watchPrintPaperwork, watchGenerateFileToSign, watchCheckOrderStatus } from './dossier/common/paperworksTable'
import { watchCreateSupplied, watchDeleteSupplied, watchUpdateSupplied } from './dossier/sales/supplied'
import { watchCreateService, watchDeleteService, watchUpdateService } from './dossier/sales/services'
import { watchCreateExtraSale, watchDeleteExtraSale, watchUpdateExtraSale, watchFetchProcessingAgencyModal } from './dossier/sales/extraSales'
import { watchCreatePresent, watchDeletePresent, watchUpdatePresent } from './dossier/sales/presents'
import { watchFetchDefaultEntity } from './dossier/common/client/fetchDefaultEntity'
import {
  watchCreatePurchasePresent, watchDeletePurchasePresent, watchUpdatePurchasePresent, watchRecoverPurchasePresent
  , watchRefreshPurchasePresentFromDocumentModal
} from './dossier/purchase/purchasePresents'
import { watchFetchAndOpenReplacePurchaseModal, watchSearchFilteredPurchaseDossier, watchSelectReplacePurchase, watchCreateOrderDossierPurchase, watchReassignPurchaseCatalogueModel } from './dossier/common/reassignAndReplacePurchaseModal'
import {
  watchSaleApprove, watchSaleApprovePaperwork, watchSaleReject, watchSaleSkipCharge, watchSaleSkipDocument,
  watchSaleSkipPaperwork, watchSaleSkipDelivery, watchSaleSendApprove, watchSaleWarningSave, watchSaleApproveByRole,
  watchSaleApproveFleet, watchSaleApproveVoucher, watchSalePayRenting, watchConfigureCampaign, watchSaveconfigureCampaign,
  watchSearchCampaignByCode, watchHasOptionals, watchSkipeItv, watchSaleTransferRequest, watchSaleTransferRequestApprove,
  watchSaleSendToBproDms, watchRequestInvoiceToDMS, watchRequestDeliveryToDMS, watchRequestOutboundToDMS, watchSaleTransferReject
} from './dossier/sales/headerAdminSalesActions'
import { watchPutCancelDossier, watchPutVOCompany, watchApproveMargin } from './dossier/common/headerAdminCommonActions'
import {
  watchOpenDocumentManagementModal, watchCancelDocument, watchAddDependant,
  watchPrintCheckList, watchCloseDocumentModalCallbacks, watchRefreshDocumentManagementModal, watchSignDocument, watchOpenModalVehicleNecessary,
  watchRefreshFormErrors, watchPrintTemplateByReferenceType
} from './dossier/common/documents/managementModalSagas'
import {
  watchOpenDocumentCreationModal, watchCloseAddOrEditDocumentModalCallback, watchOnDocumentTypeIdChange,
  watchRejectDocument, watchValidateDocument, watchUploadDocument, watchOpenDocumentEditionModal,
  watchCreateDocument, watchSaveDocument, watchToggleFullScreenDocumentModal, watchRecoverDocument,
  watchCreateDocumentSheet, watchDeleteDocumentSheet, watchToggleFullScreenDocumentSheetModal, watchCheckCanCloseDocumentModal,
  watchOpenDocumentCreationModalFleets, watchOnDocumentTypeIdChangeFleets, watchCreateDocumentFleets,
  watchCheckDependenceMetadato, watchRequestSign, watchCancelSign, watchSignTypes,
  watchExtracMetadata, watchrefreshDocumentInfo, watchCancelSignMessage, watchGetCancelDocListFromRequest, watchopenDocumentEditionModalFleet, watchSealDocumentStamp,
  watchCreateSignDocumentFromTemplate, watchPrintDocumentDynamicFromTemplate
} from './dossier/common/documents/creationEditionDocumentSagas'
import { watchSendDMS } from './dossier/charges/dms/dmsSagas'
import {
  watchOpenDocumentHistoricalCreationModal, watchCloseAddOrEditDocumentHistoricalModalCallback, watchOnDocumentHistoricalTypeIdChange,
  watchOpenDocumentHistoricalEditionModal, watchCreateDocumentHistorical, watchSaveDocumentHistorical,
  watchToggleFullScreenDocumentHistoricalModal, watchCreateDocumentSheetHistorical, watchDeleteDocumentSheetHistorical,
  watchToggleFullScreenDocumentSheetHistoricalModal
} from './dossier/common/documents/documentHistoricalSagas'
import { watchOpenReservationContractModal, watchCloseReservationContractModal } from './dossier/common/documents/reservationContractModalSagas'
import { watchFetchInsuranceCompanyCombo } from './combos/fetchInsuranceCompanyCombo'
import { watchFetchRegistrationDeliveryDossier, watchFetchRegistrationDeliveryDossierDependants, watchFetchRegistrationDeliveryVehicle, watchFetchRegistrationDeliveryClient, watchfetchRegistrationDeliveryFinancing, watchfetchRegistrationDeliveryDelivery, watchfetchRegistrationDeliveryDeliveryHistorical, watchfetchRegistrationDeliveryFinancingHistorical, watchFetchRegistrationDeliveryVehicleHistorical, watchFetchRegistrationDeliveryClientHistorical, watchfetchRegistrationDeliveryOtherData, watchfetchRegistrationDeliveryOtherDataHistorical, watchFetchRegistrationDeliveryEconomic } from './dossier/registrationDelivery/fetchRegistrationDeliveryDossier'
import { watchFetchPaperworkDossier, watchFetchPaperworkClient, watchFetchPaperworkVehicle, watchFetchPaperworkVehicleHistorical, watchFetchPaperworkClientHistorical, watchfetchAgencyProcessing } from './dossier/paperwork/fetchPaperworkDossier'

import { watchSavePaperworkDossier } from './dossier/paperwork/savePaperworkDossier'
import { watchPaperworkReject, watchPaperworkGenDelivery, watchPaperworkWarningSave, watchPaperworkResendToProcessingAgency, watchPaperworkNotify, watchPaperworkTransferCompleted } from './dossier/paperwork/headerAdminPaperworkActions'
import { watchPrintDeliveryChecklist, watchprintChecklistVO, watchPrintDeliveryCertificates, watchprintDeliveryChecklistSale } from './dossier/sales/delivery'
import { watchFetchPurchaseDossier, watchFetchDependantsPurchaseDossier, watchFetchEconomicPlanPurchaseDossier, watchChangeDossierSubType, watchFetchPurchaseClient, watchFetchPurchaseVehicle, watchfetchPurchaseOtherData, watchFetchPurchaseVehicleHistorical, watchFetchPurchaseClientHistorical, watchfetchPurchaseOtherDataHistorical, watchfetchVehiclePurchaseComponent, watchPurchaseFetchCatalogueModel } from './dossier/purchase/fetchPurchaseDossier'
import {
  watchPurchaseReject, watchPurchaseSendApprove, watchPurchaseApprove, watchPurchaseWarningSave, watchPurchaseSynchroniseAvailabilityDate, watchPurchaseTransferRequest
  , watchPurchaseTransferRequestApproved, watchPurchaseApproveManager, watchPurchaseApproveSalesManagerVo, watchPurchaseTransferReject
} from './dossier/purchase/headerAdminPurchaseActions'
import { watchSavePurchaseDossier } from './dossier/purchase/savePurchaseDossier'
import {
  watchFetchEntityMasters, watchOpenSourceChannelModal, watchSaveSourceChannelModal, watchDeleteSourceChannelModal,
  watchFetchExtraSalesMasters, watchOpenExtraSaleModal, watchSaveExtraSaleModal, watchDeleteExtraSaleModal, watchRecoverExtraSaleModal,
  watchFetchSuppliedMasters, watchOpenSuppliedModal, watchSaveSuppliedModal, watchDeleteSuppliedModal, watchRecoverSuppliedModal,
  watchFetchPresentsMasters, watchOpenPresentsModal, watchSavePresentsModal, watchDeletePresentsModal, watchRecoverPresentsModal, watchFetchProcessingAgencyList, watchOpenEditProcessingAgencyModal, watchFetchProcessingAgencyQuotaList,
  watchSubmitProcessingAgency, watchOpenAddProcessingAgencyModal, watchDeleteProcessingAgency, watchRecoverProcessingAgency, watchOpenAssociatedCostsModal, watchDeleteAssociatedCostsModal,
  watchFetchFuelList, watchOpenEditFuelModal, watchSavePurchasePresentTypeModal, watchOpenAddFuelModal, watchSubmitFuel, watchDeleteFuel, watchRecoverFuel, watchSubmitProcessingAgencyAssignation,
  watchFetchAssociatedCostsMasters, watchRecoverAssociatedCostsModal, watchSaveAssociatedCostsModal, watchFetchFinancingTypeMasters, watchSaveFinancingTypeModal, watchOpenFinancingTypeModal, watchOpenPurchasePresentTypeModal, watchDeleteFinancingTypeModal, watchDeletePurchasePresentTypeModal, watchRecoverPurchasePresentTypeModal, watchRecoverFinancingTypeModal, watchFetchFinancingTypeList, watchFetchPurchasePresentTypeList,
  watchFetchPaymentMethod, watchDeletePaymentMethod, watchRecoverPaymentMethod,
  watchOpenEditPaymentMethodModal, watchOpenAddPaymentMethodModal, watchSubmitPaymentMethod,
  watchFetchFinancingList, watchOpenAddFinancingModal, watchOpenEditFinancingModal, watchDeleteProcessingAgencyQuota,
  watchDeleteFinancing, watchSubmitFinancing, watchRecoverFinancing, watchEditProcessingAgencyAssignation, watchAddProcessingAgencyAssignation, watchDeleteProcessingAgencyAssignation, watchFetchCampaignList,
  watchSubmitCampaignDetail, watchDeleteCampaignDetail, watchRecoverCampaignDetail, watchFetchCampaignGroupList,
  watchDeleteCampaignGroup, watchRecoverCampaignGroup, watchSubmitCampaignGroup, watchFetchCampaignTypeList, watchDeleteCampaignType, watchRecoverCampaignType, watchOpenCampaignGroupModal, watchOpenCampaignTypeModal, watchSubmitCampaignType, watchfetchCountryCampaignDetailList, watchopenMassModal,
  watchdeleteCountryCampaignDetail, watchsetCountryCampaignDetail, watchDeleteStyleTask, watchOpenStyleTaskModal, watchFetchStyleTaskList, watchSubmitStyleTask, watchGetOrdersStyleTask, watchOpenEditFinancingModalUo, watchsaveUoFinancingCompany, watchdeleteUoFinancingCompany, watchRecoverUoFinancingCompany, watchfetchFinancingProductCompany,
  watchPrcAgLoadMassDocument, watchFetchDossierStageList, watchOpenEditDossierStageModal, watchSubmitDossierStage, watchShowEditProcessingAgencyIntegrationModal, watchUpdateAgencyCommunicationServiceId, watchfetchCodeCommisionList, watchDeleteCodeCommission, watchOpenCodeCommissionModal, watchCreateOrUpdateCodeCommission
} from './masters/entityMasters'
import {
  watchFetchSignatureConfigList, watchOpenSignatureConfigurationModal, watchSubmitSignatureConfig,
  watchDeleteSignatureConfig, watchOpenSignatureEntityModal, watchSubmitSignatureEntity,
  watchDeleteSignatureEntity
}
  from './masters/signatureConfiguration/signatureConfiguration'
import {
  watchFetchOrganizedUnitSaleOperationList, watchOpenOrganizedUnitSaleOperationModal, watchSubmitOrganizedUnitSaleOperation, watchDeleteOrganizedUnitSaleOperation
} from './masters/organizedUnitSaleOperation/organizedUnitSaleOperation'
import {
  watchOpenDocumentTypeModal, watchSubmitDocumentType, watchDeleteDocumentType, watchFetchDocumentTypeList
} from './masters/documentType/documentType'
import {
  watchOpenDocumentTypeUseModal, watchSubmitDocumentTypeUse, watchDeleteDocumentTypeUse, watchFetchDocumentTypeUseList
} from './masters/documentTypeUse/documentTypeUse'
import {
  watchDocumentVisualizations, watchDocumentVisualizationDelete, watchDocumentVisualizationSubmit, watchOpenDocumentVisualitationModal
} from './masters/documentVisualization/documentVisualization'
import {
  watchFetchDocumentTypeMetadataList, watchOpenDocumentTypeMetadataModal, watchSubmitDocumentTypeMetadata, watchDeleteDocumentTypeMetadata, watchOpenMetadataListModal, watchDeleteMetadataListElement, watchSubmitMetadataList
} from './masters/metadata/metadata'
import { watchOpenOrganizedUnitConfigurationModal, watchSubmitOrganizedUnitConfiguration, watchDeleteOrganizedUnitConfiguration, watchFetchOrganizedUnitConfigurationList, watchSetChangeTabFromConfMaster } from './masters/organizedUnitConfiguration/organizedUnitConfigurationMaster'
import { watchOpenRatingModal, watchSubmitRating, watchDeleteRating, watchFetchRatingList } from './masters/rating/ratingMaster'
import { watchDeleteUserRepairshop, watchAssingUserRepairshop, watchOpenRepairshopModal, watchSubmitRepairshop, watchDeleteRepairshop, watchFetchRepairshopList, watchOpenEditRepairshopModal, watchOpenRepairshopUsersModal } from './masters/repairshop/repairshopMaster'
import { watchFetchRoleList, watchOpenEditRoleModal, watchOpenAddRoleModal, watchSubmitRole, watchDeleteRole, watchCloneRole } from './admin/roleAdmin'
import { watchsearchDocuments, watchgetDocumentsCount } from './sign/sign'
import { watchFetchDocumentalSearchFormCombos, watchFindFilterDocuments, watchInitializeDocumentalSearch, watchFetchDocumentSearchListSuccess, watchOpenDocumentalSearchModal, watchDownloadDocumentList, watchMassiveDownloadDocumentList, watchCreateMassiveDownload, watchFindFilterMassiveDownload, watchDownloadMassiveFile, watchCancelMassiveFile } from './search/documentalSearch/documentalSearchForm'
import {
  watchInitializeSearch, watchFetchDossiers, watchFetchVehicles, watchFetchEntities, watchFetchCharges, watchFetchDossiersSimple, watchFetchVehiclesSimple, watchfetchChargeRenting, watchfetchSelfSupply, watchSalePayRentingList, watchSaleSelfSupplyList, watchfetchFleets, watchFetchDossiersDownload,
  watchFetchVehiclesDownloads, watchFetchEntitiesDownload, watchFetchDossiersSimpleDownload, watchFetchVehiclesSimpleDownload, watchfetchChargeRentingDownload, watchfetchSelfSupplyDownload, watchFetchChargesDownload, watchfetchFleetsDownload, watchfetchCampaignExport, watchlaunchExportCampaign, watchlaunchExportCampaignExcel, watchfetchRegisterOrderProcess, watchfetchRegisterOrderDownload, watchfetchFtpRegister, watchfetchFtpRegisterExport
  , watchfetchServicePrepare, watchfetchDossierServicePrepareDownload, watchfetchDossierServicePrepareRoleConfig, watchfetchDossierFleets, watchfetchDossierFleetsDownload, watchfetchReportCommercial, watchfetchReportCommercialTable, watchfetchReportCommercialTableDownload, watchfetchexportReportCommercial
  , watchFetchPapServicesFromManager, watchFetchPapServicesDownloadFromManager, watchFetchPlanLogiticsPapServices, watchFetchProcessingAgencies, watchFetchProcessAgenciesDownload, watchFetchFleetReport, watchFetchFleetReportDownload,
  watchFetchStockManagementDossiers, watchFetchStockManagementDossiersDownload
} from './search/search'
import { watchfetchAuditInfo } from './audit/auditInfo'
import { watchFetchUserList, watchOpenAddUserModal, watchOpenEditUserModal, watchSubmitUser, watchDeleteUser, watchRecoverUser, watchAdminChangeUserPassword, watchDeleteSalesman, watchRecoverSalesman, watchExistCif, watchOpenUploadSignModal, watchUploadSign, watchCloseUploadSignModal, watchCheckCanCloseSignModal, watchDownloadSignUser, watchUploadUserMassDocument } from './admin/userAdmin'
import { watchFetchWalletList, watchDownloadWalletList } from './report/WalletReport'
import { watchFetchBuyList, watchDownloadBuyList } from './report/BuyReport'
import { watchFetchStockList, watchDownloadStockList } from './report/StockReport'
import { watchFetchUsageList, watchDownloadUsageList, watchFetchUsageInfo, watchDownloadUsageInfo } from './report/UsageReport'
import { watchFetchSaleList, watchDownloadSaleList } from './report/SaleReport'
import { watchFetchReceiptList, watchDownloadReceiptList } from './report/ReceiptReport'
import { watchFetchPowerbiConfig, watchPowerBiRefreshSession } from './report/PowerBiReport'
import { watchFetchReportTypeCode } from './report/Report'
import {
  watchFetchTemplateList, watchSetActiveTemplate, watchDuplicateTemplate, watchDeleteTemplate,
  watchOpenAddTemplateModal, watchOpenEditTemplateModal, watchSubmitTemplate, watchCloseEditTemplateModal,
  watchCreateTemplateField, watchUpdateTemplateField, watchDeleteTemplateField, watchFetchTemplateFile, watchDownloadTemplate
} from './templates/templates'
import { watchFetchCampaignDossier, watchFetchCampaignClient, watchFetchCampaignVehicle, watchFetchCampaignClientHistorical, watchFetchCampaignvehicleHistorical, watchfetchCampaigndetailsForCampaign, watchCampaignActive } from './dossier/campaign/fetchCampaignDossier'
import { watchSaveCampaignDossier } from './dossier/campaign/saveCampaignDossier'
import { watchCampaignReject, watchCampaignApprove, watchCampaignSend, watchCampaignDeny, watchCampaignWarningSave, watchAnulateCampaign, watchCampaignReturn, watchCampaignDenie, watchCampaignValidate } from './dossier/campaign/headerAdminCampaignActions'
import { watchYesNoModal } from './modalsListeners/yesNoModal'
import { watchGenerateStructure, watchLoadFiles, watchGetLoadResult } from './dossier/common/documents/hotFolderSagas'
import { watchFetchRoles } from './combos/fetchRoles'
import { watchFetchRentingEntityCombo } from './combos/fetchRentingEntityCombo'
import { watchFetchSectionKeyValue } from './combos/fetchSectionsKeyValue'
import { watchFetchPermissionKeyValue } from './combos/fetchPermissionKeyValue'
import { watchUpdateDependantsPurchaseDossierAndHeader } from './dossier/purchase/updateDependantsPurchaseDossierAndHeader'
import {
  watchFetchStagesConditions, watchSetActiveStage, watchOpenAddConditionsModal, watchOpenEditConditionsModal,
  watchSaveConditionsModal, watchCloseAddOrEditConditionsModal, watchDeleteConditions, watchfetchDocumentaConditionCampaign, watchdeleteDocumentaConditionCampaign, watchfetchDocumentsConditionCampaign,
  watchaddConditionDocumentalCampaign, watchfetchFuntionsToAddDocumentalCampaign, watchDeleteUoConditionDocCampaign
} from './conditionsMaitenance/conditionsMaintenance'
import { watchFetchOrganizedUnitsCombo } from './combos/fetchOrganizedUnitsCombo'
import { watchFetchCancelCombo } from './combos/fetchCancelCombo'
import { watchFetchAuditCodes } from './combos/fetchAuditCodes'
import { watchFetchauditLoadData } from './combos/fetchAuditLoadData'
import { watchFetchRatingCombo, watchFetchRatingComboByOrganizedUnit } from './combos/fetchRatingCombo'
import { watchFetchAuditConfigurationType } from './combos/fetchAuditConfigurationType'
import { watchsetCurrentSectionByPath } from './section/section'
import { watchFetchFunctionToAdd } from './combos/fetchFunctionToAdd'
import { watchFetchFunctionToPass } from './combos/fetchFunctionToPass'
import { watchFetchReasons, watchFetchAllReasons } from './combos/fetchReasonCombo'
import { watchFetchDeliveryLocations } from './combos/fetchDeliveryLocations'
import { watchFetchAdditionalInfoCombo } from './combos/fetchAdditionalInfo'
import {
  watchFetchEntitiesList, watchOpenAddEntityModal, watchOpenEditEntityModal, watchSubmitEntity, watchDeleteEntity, watchFetchDocumentList, watchFetchEntitySubTypesList, watchRecEntLoadMassDocument, watchSetChangeTabFromMaster,
  watchFetchUserRecurrentServiceEntity, watchSaveUserRecurrentServiceEntity, watchDeleteUserRecurrentServiceEntity
} from './admin/recurrentEntityAdmin'
import { watchFetchOrganizedUnitsAcceptClientsCombo } from './combos/fetchOrganizedUnitsAcceptClientsCombo'
import { watchFetchVoCompaniesCombo } from './combos/fetchVoCompanies'
import { watchClearEntity, watchClearEntityChild } from './dossier/common/client/clearClient'
import { watchFetchHomologationClassificationCombo } from './combos/fetchHomologationClassificationCombo'
import { watchFetchHomologationProducerCombo } from './combos/fetchHomologationProducerCombo'
import { watchFetchIaeCombo } from './combos/fetchIaeCombo'
import { watchFetchComboOrigin } from './dossier/common/vehicle/fetchComboOrigin'
import { watchFetchComboDestination } from './dossier/common/vehicle/fetchComboDestination'
import { watchFetchDocumentsTypeRestrictedCombo } from './combos/fetchDocumentsTypeRestrictedCombo'
import { watchFetchServiceDestinationTypes } from './combos/fetchServiceDestinationTypes'
import { watchFetchStockStatusCombo } from './combos/fetchStockStatusCombo'
import { watchFetchPurchaseOrderTypeCombo } from './combos/fetchPurchaseOrderTypeCombo'
import { watchFetchSectionList, watchFetchQuestion, watchFetchSectionQuestions, watchFetchQuestionsBySearch, watchVoteQuestion } from './faq/faq'
import { watchFetchMarginTypesCombo } from './combos/fetchMarginTypesCombo'
import { watchFetchFaqList, watchDeleteFaq, watchOpenFaqModal, watchRecoverFaqModal, watchSaveFaqModal, watchFetchFaqSectionCombo, watchFetchFaqSectionComboModal } from './masters/faq/faqMaster'
import { watchFetchFaqSectionTable, watchDeleteFaqSection, watchFetchFaqSection, watchRecoverFaqSection, watchSaveFaqSection } from './masters/faqSection/faqSectionMaster'
import { watchGetChangeUo, watchgetSalesmanChangeUo, watchPostChageUoDossier } from './dossier/common/changeUo'
import { watchfetchDeviceInstalls, watchfetchMaxDeviceInstalls, watchupdateDeviceInstall } from './idocmobile/deviceInstall'
import { watchsendMailAppDownload } from './idocmobile/appDownload'
import { watchSetHeader } from './header/header'
import { watchFetchUsers } from './combos/fetchUsers'
import { watchFetchFamily } from './combos/fetchFamily'
import { watchFetchExternalCode } from './combos/fetchExternalCode'
import { watchlaunchCampaignProcess } from './dossier/purchase/launchPurchaseCampaignProcess'
import { watchCheckPurchaseTypeAndSetDefaultEntity } from './dossier/purchase/purchaseSaga'
import { watchRegistrationDeliveryWarningSave } from './dossier/registrationDelivery/headerAdminRegistrationDeliveryActions'
import { watchPrintDeliveryChecklist as watchRegistrationPrintDeliveryChecklist } from './dossier/registrationDelivery/delivery'
import { watchUpdateDependantsRegistrationDeliveryDossierAndHeader } from './dossier/registrationDelivery/updateDependantsRegistrationDeliveryDossierAndHeader'
import { watchSaveServiceDossier, watchSaveIssueService, watchSaveServicesAvailabilityDate, watchSaveServicesConfirmationLogitics } from './dossier/service/saveServiceDossier'
import { watchFetchServiceDossier, watchFetchServiceVehicle, watchFetchServiceDossierVehicleHistorical } from './dossier/service/fetchServiceDossier'
import { watchServiceDossierWarningSave, watchServiceDossierApprove, watchServiceApproveByRole, watchserviceResolveIssue, watchServiceRequestSpareParts, watchServiceCompleteSpareParts, watchServiceCompleteDeliveryPreparation } from './dossier/service/headerAdminServiceDossierActions'
import { watchSaveSalesService, watchFetchNotValidDatesService, watchCreateUpdateDossierToshiko } from './dossier/sales/salesServices'
import { watchfetchManagementActions, watchFetchFleetOperateActions } from './combos/fetchManagementActions'
import { watchRemoveFinanceServices, watchCleanFinanceServices } from './dossier/sales/removeFinance'
import { watchfetchFleet, watchfetchFleetComponent } from './combos/fetchFleetCombo'
import { watchFetchSelfSupplyOrganizedUnites } from './combos/fetchSelfSupplyOrganizedUnits'
import { watchUploadUoMassDocument, watchfetchTemplateFile } from './massUpload/massUploadDocs'
import {
  watchFetchOperationTypesTable, watchOpenOperationTypeModal, watchSaveOperationType,
  watchRecoverOperationType, watchDeleteOperationType
} from './masters/operationType/operationTypeMaster'

import {
  watchsaveFleetData, watchfetchFleetsInfo, watchFetchFeleteDossierFleet, watchFetchCreateOrUpdateFleet, watchFetchChangeClientDossier, watchFetchAssignDossierToFleet,
  watchFetchDeleteFleet, watchfetchFleetsInfoDownload, watchfetchPaymentCombo, watchfetchClientFleetPermissions,
  watchsendToApproveActionFleet, watchapproveActionFleet, watchapproveRoleActionFleet, watchfetchCombosEconomicModal, watchfetchEconomicFleetPermissions,
  watchSimulateEconomicPlanFleet, watchAddCampaign, watchOpenEconomicModal, watchsaveEconomicPlanFleet, watchfetchShowFrontEconomicPlan, watchDeleteEconomicCampaign,
  watchfecthValidateDocumentFleetPermission, watchCreateDifferentDocumentFleet, watchgetDocumentTypeFleets, watchFindFleetsManagement, watchExportFleetsManagement,
  watchfetchFleetModel, watchfetchDossierDetailsFleet, watchfetchDeleteDossiersFleet, watchopenOperateFleetModal, watchInitializeOperationActionsCombo, watchfetchSalesManCombos, watchfetchSalesTypeCombo,
  watchfetchPaperWorkFleetPermissions, watchfetchEconomicShow, watchfetchDocumentLisFleet, watchFetchSelfSupplyInfo, watchFetchSelfSupplyInfoDownload, watchPassSelfSupplyVoAction,
  watchfetchOperateDataFleetModalsPermissions, watchfetchGetCanCreateOperateServices, watchOpenEconomicFleetModal, watchFetchAuditAdminInfo, watchFetchAuditsInfo,
  watchOpenAuditAdminModal, watchFetchDocumentTypeUsesInfo, watchSaveAuditAdmin, watchDocumentAudit, watchDocumentAuditFinish, watchFetchAuditConfigurationDocument, watchDeleteAuditAdmin,
  watchFetchSearchDocumentsInfo, watchOpenZipModal, watchFetchCodeCommissionFleet, watchFetchPaperworksDetailsFleet, watchFetchDossierChildSubtypeDetailCount, watchFetchChargesDetailsFleet
} from './management/management'

import { watchgetDealerNumberModal, watchgetVehicleDataQuiter, watchsendQuiterVehicle } from './dossier/common/chargeQuiter'

import { watchfetchFinancingTypeProductFields, watchfetchFinancingTypeProductList, watchdeleteFinancingTypeProd, watchrecoverFinancingTypeProd, watchfetchOpenFinancingTypeProductModal, watchupdateFinancingTypeProd, watchfetchAllFinancingTypeProduct, watchfetchFinancingTypeProductByProductId } from './masters/financing/financingTypeProduct'
import { watchsaveReFinance, watchdeleteReFinancing } from './dossier/sales/refinance'
import { watchGetAllAvailableDocumentLandingRequest, watchGetDocumentLandingRequest, watchSendRequestToClient, watchSendLandingCancelRequest } from './dossier/common/documents/landingRequestModalSagas'
import { watchFetchNonUpdatedDossiers, watchDelayNonUpdatedDossierAlert } from './dossier/common/nonUpdatedDossiers'
import { watchFetchConfigParameterAll } from './combos/fetchConfigParameterAll'
import {
  watchsetDossierFleetDataChangeProcess, watchSetActionExpFleetProcess, watchSetApproveActionFleetProcess, watchgFetchUnattendedProcessTypes, watchgFindUnattendedProcess, watchFetchUnattendedProcess, watchFetchDossierNumbersFromIds, watchCreateDifferentDocumentFleetProcess,
  watchSetEconomicPlanFleetProcess, watchsetOperateDataSaleFleetProcess, watchSetOperateDataContactFleetProcess, watchsetOperateDataPaperWorkFleetProcess, watchsetOperateDataFinancingFleetProcess, watchsetOperateDataChargeFleetProcess, watchSetOperateActionSendToApprovalProcess,
  watchSetOperateActionApproveProcess, watchSetOperateActionRejectProcess, watchSetOperateActionApprovePaperworkProcess, watchSetOperateActionSendToAgencyProcess, watchSetOperateActionDeleteProcess, watchCreateIncorporateDocumentFleetProcess, watchCreateValidateDocumentFleetProcess,
  watchCreateReEditDocumentFleetProcess, watchCreateCancelDocumentFleetProcess, watchCreateRequestSignDocumentFleetProcess, watchSetOperateActionStoreProcess, watchsetOperateDataDeliveryFleetProcess, watchsetOperateDataServiceFleetProcess,
  watchSetDeleteFleetProcess, watchSetOperateActionPrintDeliveryNoteProcess, watchUnattendedProcessDownloadDeliveryNotes, watchCreateCancelSignDocumentFleetProcess, watchCreateRejectDocumentFleetProcess, watchSetFleetChargeDossiersProcess,
  watchFetchUnattendedProcessView, watchCreateUpdateDocumentFleetChargeProcess, watchCreateRecoverDocumentFleetChargeProcess
} from './unattendedProcess/unattendedProcess'
import { watchDeleteDirectionUOModal, watchFetchDirectionsUOMasterList, watchOpenDirectionUOModal, watchSaveDirectionUOModal } from './masters/directionUO/directionUOMaster'
import { watchDeleteLegalCompanyModal, watchFetchLegalCompanyList, watchOpenLegalCompanyModal, watchSaveLegalCompanyModal } from './masters/legalCompany/legalCompanyMaster'
import { watchFetchOrganizedUnitList, watchSaveOrganizedUnitModal, watchOpenOrganizedUnitModal } from './masters/organizedUnit/organizedUnitMaster'
import { watchFetchLegalCompanyCombo } from './combos/fetchLegalCompanyCombo'
import { watchFetchProcessingAgencyCombo } from './combos/fetchProcessingAgencyCombo'
import { watchFetchDirectionOUCombo } from './combos/fetchDirectionOUCombo'
import { watchfetchSaleTypeList, watchfetchOpenSaleTypeModal, watchUpdateSaleType, watchDeleteSaleTypeModal, watchRecoverSaleTypeModal, watchFetchOpenDuplicateSaleTypeModal } from './masters/saleType/saleType'
import { watchfetchSignTypeList, watchSubmitSignType } from './masters/signType/signType'
import { watchFetchDossiersToAddFleet, watchFetchAddDossiersToFleet } from './fleets/fleets'
import { watchFetchCurrencyCombo } from './combos/fetchCurrencyCombo'
import { watchFetchProcessingAgencyOrganizedUnitConfigList, watchShowProcessingAgencyOrganizedUnitConfigModal, watchSubmitProcessingAgencyOrganizedUnitConfigModal, watchDeleteProcessingAgencyOrganizedUnitConfig } from './masters/processingAgencyOrganizedUnitConfig/processingAgencyOrganizedUnitConfig'
import { watchFetchProcessingAgencyRegistrationTypesList, watchShowProcessingAgencyRegistrationTypesModal, watchSubmitProcessingAgencyRegistrationTypesModal, watchDeleteProcessingAgencyRegistrationTypes } from './masters/processingAgency/processingAgencyIntegrations/processingAgencyRegistrationTypes'
import { watchFetchProcessingAgencyServiceDestinationsList, watchShowProcessingAgencyServiceDestinationsModal, watchSubmitProcessingAgencyServiceDestinationsModal, watchDeleteProcessingAgencyServiceDestinations, watchFetchProcessingAgencyServiceDestinationsCombo } from './masters/processingAgency/processingAgencyIntegrations/processingAgencyServiceDestinations'
import { watchFetchProcessingAgencyDocTypeUsesList, watchShowProcessingAgencyDocTypeUsesModal, watchSubmitProcessingAgencyDocTypeUsesModal, watchDeleteProcessingAgencyDocTypeUses } from './masters/processingAgency/processingAgencyIntegrations/processingAgencyDocTypeUses'
import { watchFetchProcessingAgencyCommunicationServices } from './masters/processingAgencyCommunicationServices/processingAgencyCommunicationService'

import { watchfetchDocumentDetailsFleet, watchfetchUnattendedProcessFileListInfo, watchFetchFleetOrganizedUnits, watchOpenChargeDetailsModal } from './management/auxModals/auxModalsManagement'
import { watchLoadSepomexFileToValidate, watchLoadSepomexFileToImport } from './loadSepomexFile/loadSepomexFile'

import { watchDeleteUserProcessingAgency, watchGetUsersProcessingAgency, watchFetchUserListForProcessingAgency, watchAssignUserProcessingAgency } from './masters/processingAgency/processingAgency'
import { watchFetchPaymentMethodCombo } from './combos/fetchPaymentMethodCombo'
import { watchFetchPuscharseSaleEntitys } from './combos/fetchPuscharseSaleEntitys'
import { watchfetchOperationTypeDMS } from './combos/fetchOperationTypesDms'
import { watchFetchCommercialSocietyTypeCombo } from './combos/fetchCommercialSocietyTypeCombo'
import { watchImportVehicleModelFile } from './vehicle/importVehicleModels'
import { watchFetchClientByPhoneEmail } from './dossier/common/client/fetchClienByPhoneEmail'
import { watchFetchCarlineCombo } from './combos/fetchCarlineCombo'
import { watchFetchCatalogueCombo } from './combos/fetchCatalogueCombo'
import { watchFetchColorCombo } from './combos/fetchColorCombo'
import { watchFetchTapestryCombo } from './combos/fetchTapestryCombo'
import { watchFecthActivitiesBpro } from './combos/fecthActivitiesBpro'
import { watchFetchPaperworkTypesList, watchFetchPaperworkTypeInfo, watchDeletePaperworkType, watchUpdatePaperworkType, watchPostPaperworkType, watchAdviceClosePaperwokTypeMasterModal } from './masters/pwkTypes/pwkTypesMaster'
import { watchFetchSameLevelOusCombo } from './combos/fetchSameLevelOus'
import { watchFetchStockDossier, watchFetchStockVehicle, watchFetchStockDossierVehicleHistorical, watchDeleteDossierRating, watchAddDossierRating, watchGetDossierBudget, watchFetchDossierBudgetRating, watchGetDossierPhoto } from './dossier/stock/fetchStockDossier'
import { watchStockDossierWarningSave } from './dossier/stock/headerAdminStockDossierActions'
import {
  watchSaveStockDossier, watchConfirmPhotos, watchConfirmDateWithIdByStage, watchAddEditDossierBudgetRating, watchDeleteDossierBudgetRating, watchRejectDossierStockVoBudget, watchChangeRelationEntryValues,
  watchActivateDossierCrane, watchDeactivateDossierCrane, watchDossierCraneConfirmDate, watchSkipDossierPhotos
} from './dossier/stock/saveStockDossier'
import { watchDownloadDocumentsSelected, watchGetDocumentsMassiveDownload } from './dossier/common/documents/massiveDownloadModalSagas'
import { watchfetchDocumentDataOptions } from './combos/fetchDocumentDataOptions'
import {
  watchDeleteCustomField, watchFetchCustomFieldList, watchOpenCustomFieldModal, watchSubmitCustomField
  , watchRecoverCustomField
} from './masters/customField/customField'
import { watchFetchCustomFieldTypesCombo } from './combos/fetchCustomFieldTypesCombo'
import { watchFetchCustomFields } from './dossier/customFields'
import { watchValidate, watchValidateRepresentative, watchValidateDNICIF } from './dossier/common/validations'
import { watchSwitchFavorite } from './dossier/common/statusHeader'
import { watchSwitchTaskFavorite } from './dashboard/dashboardTask'
import { watchFetchDestinationCraneMaster, watchFetchDestinationCraneItem, watchDeleteDestinationCraneItem, watchPutDestinationCraneItem } from './masters/destinationCrane/destinationCraneMaster'

import { watchOpenMaritalStatusModal, watchSubmitMaritalStatus, watchDeleteMaritalStatus, watchFetchMaritalStatusList, watchOpenEditMaritalStatusModal } from './masters/maritalStatus/maritalStatusMaster'
import { watchGroupDocuments } from './dossier/common/documents/managementGroupDocumentsCreateModalSagas'

import { watchFetchModelCombo } from './combos/fetchModelCombo'
import { watchFetchClientDataAddress } from './dossier/common/client/clientDataAddress'
import { watchOpenDownloadModal, watchPostBulkDownload } from './search/document'
import { watchFetchProcessingAgencyManagers } from './combos/fetchProcessingAgencyManagers'
import { watchRegisterLastAccessDossier } from './dossier/common/registerLastAccessDossier'

import { watchFetchProcessingManagersByNameOrUo, watchCreateProcessingManager, watchUpdateProcessingManager, watchDeleteProcessingManager, watchRecoverProcessingManager, watchFetchProcessingsAgencyManager } from './masters/processingManager/processingManager'
import { watchfetchPurchaseStateLogisticCombo } from './combos/fetchPurchaseStateLogisticCombo'
import { watchDeleteEventSubscriptionFromAdmin, watchDisableEventSubscriptionFromAdmin, watchEnableEventSubscriptionFromAdmin, watchFetchEventSubscriptionsFromAdmin, watchSaveEventSubscriptionFromAdmin } from './admin/subscriptionsAdmin'
import { watchFetchUserRepairWorkshopsCombo } from './combos/fetchUserRepairWorkshopsCombo'
import { watchFetchFuelCombo } from './combos/fetchFuelCombo'
import { watchFetchUsageProcessTypesCombo } from './combos/fetchUsageProcessTypesCombo'

export default function * root () {
  yield all([
    fork(watchFetchPuscharseSaleEntitys),
    fork(watchFetchAuditCodes),
    fork(watchFetchAuth),
    fork(watchFetchPasswordPolicy),
    fork(watchResetPassword),
    fork(watchLogout),
    fork(watchFetchUserProfile),
    fork(watchGetUserManualUrl),
    fork(watchUpdateUserProfile),
    fork(watchFetchInitialNotificationsCounter),
    fork(watchFetchDocumentNotifications),
    fork(watchFetchDossierNotifications),
    fork(watchMarkDossierNotificationAsRead),
    fork(watchRememberPassword),
    fork(watchSetOrganizedUnit),
    fork(watchFetchLanguagesAvailable),
    fork(watchFetchDashboard),
    fork(watchFetchFilteredDashboard),
    fork(watchFetchDossiersList),
    fork(watchFetchFilteredDashboardTask),
    fork(watchFetchEntitySubTypeCombo),
    fork(watchFetchStages),
    fork(watchFetchLanguages),
    fork(watchFetchEntityTypes),
    fork(watchFetchEntityFilterSubTypeCombo),
    fork(watchFetchEntitiesByTypeByParent),
    fork(watchFetchOperationType),
    fork(watchFleetOperationTypeForAutoselect),
    fork(watchFetchFinancingTypes),
    fork(watchFetchLowestOrganizedUnits),
    fork(watchFecthOrganizedUnitsByDossierUo),
    fork(watchFetchDossierTypes),
    fork(watchFetchDossierSubTypes),
    fork(watchFetchSaleType),
    fork(watchFetchSaleTypeBySubtype),
    fork(watchFetchPurchaseType),
    fork(watchFetchTuneUpDestination),
    fork(watchFetchPaperworkType),
    fork(watchFetchCampaignType),
    fork(watchInitializeUnits),
    fork(watchSaveNewUnit),
    fork(watchDeleteUnit),
    fork(watchChangeFilter),
    fork(watchChangeFilterString),
    fork(watchFetchSalesDossier),
    fork(watchFetchSalesVehicle),
    fork(watchFetchSalesClient),
    fork(watchfetchSalesFinancing),
    fork(watchfetchSalesFinancingHistorical),
    fork(watchFetchSalesVehicleHistorical),
    fork(watchFetchSalesClientHistorical),
    fork(watchfetchSalesDelivery),
    fork(watchfetchSalesDeliveryHistorical),
    fork(watchFetchSalesDossierDependants),
    fork(watchFetchSalesInsuranceCarrier),
    fork(watchFetchSalesInsuranceCarrierHistorical),
    fork(watchfetchSalesOtherData),
    fork(watchfetchSalesOtherDataHistorical),
    fork(watchFetchSalesDossierDependantsEconomic),
    fork(watchFetchRegistrationDeliveryDossier),
    fork(watchFetchRegistrationDeliveryVehicle),
    fork(watchFetchRegistrationDeliveryClient),
    fork(watchfetchRegistrationDeliveryFinancing),
    fork(watchfetchRegistrationDeliveryFinancingHistorical),
    fork(watchFetchRegistrationDeliveryVehicleHistorical),
    fork(watchFetchRegistrationDeliveryClientHistorical),
    fork(watchfetchRegistrationDeliveryDelivery),
    fork(watchfetchRegistrationDeliveryDeliveryHistorical),
    fork(watchFetchRegistrationDeliveryDossierDependants),
    fork(watchfetchRegistrationDeliveryOtherData),
    fork(watchfetchRegistrationDeliveryOtherDataHistorical),
    fork(watchSubmitNewDossier),
    fork(watchStoreDossier),
    fork(watchSaveSalesDossier),
    fork(watchfetchSalesEconomic),
    fork(watchPrintDocumentaryChecklist),
    fork(watchSetEntityTypeId),
    fork(watchSetEntityProviderSubTypeId),
    fork(watchSetEntityProviderTypeId),
    fork(watchSetEntityValidationDisable),
    fork(watchSetEntityChildTypeId),
    fork(watchSetEntitySubTypeId),
    fork(watchSetEntityId),
    fork(watchSetEntityProviderId),
    fork(watchSetClientFromParent),
    fork(watchFetchClientByDni),
    fork(watchFetchClientByDniEntityChild),
    fork(watchFetchClientFromDocument),
    fork(watchFetchProviderFromDocument),
    fork(watchSearchVehicle),
    fork(watchFetchVehicleSelectionModal),
    fork(watchFetchOrder),
    fork(watchSaveOrder),
    fork(watchFetchDossierRelatedDossiers),
    fork(watchUpdateDiscount),
    fork(watchFetchDiscountModal),
    fork(watchFetchAssociatedCostByPercentageModal),
    fork(watchSaveRegistrationDeliveryDossier),
    fork(watchCreateDiscount),
    fork(watchDeleteDiscount),
    fork(watchCreateExtra),
    fork(watchDeleteExtra),
    fork(watchUpdateExtra),
    fork(watchCreateComment),
    fork(watchDeleteComment),
    fork(watchUpdateComment),
    fork(watchFetchUACUnitEdition),
    fork(watchDeleteUAC),
    fork(watchCreateUAC),
    fork(watchUpdateUAC),
    fork(watchChooseUACSuggestion),
    fork(watchFetchUACClientFromDocument),
    fork(watchFetchCampaignEdition),
    fork(watchDeleteCampaign),
    fork(watchCreateCampaign),
    fork(watchUpdateCampaign),
    fork(watchFetchPaperworkEditionModal),
    fork(watchDeletePaperwork),
    fork(watchCreatePaperwork),
    fork(watchUpdatePaperwork),
    fork(watchPrintPaperwork),
    fork(watchCreateSupplied),
    fork(watchDeleteSupplied),
    fork(watchUpdateSupplied),
    fork(watchCreateService),
    fork(watchDeleteService),
    fork(watchUpdateService),
    fork(watchSaleApprove),
    fork(watchSaleApproveFleet),
    fork(watchSalePayRenting),
    fork(watchSaleApprovePaperwork),
    fork(watchSaleReject),
    fork(watchSaleSkipCharge),
    fork(watchSaleSkipDocument),
    fork(watchSaleSkipPaperwork),
    fork(watchFetchAndOpenReplacePurchaseModal),
    fork(watchSearchFilteredPurchaseDossier),
    fork(watchSelectReplacePurchase),
    fork(watchSaleSkipDelivery),
    fork(watchSaleSendApprove),
    fork(watchPutCancelDossier),
    fork(watchOpenDocumentManagementModal),
    fork(watchCancelDocument),
    fork(watchAddDependant),
    fork(watchPrintCheckList),
    fork(watchCloseDocumentModalCallbacks),
    fork(watchRefreshDocumentManagementModal),
    fork(watchOpenDocumentCreationModal),
    fork(watchCloseAddOrEditDocumentModalCallback),
    fork(watchOpenDocumentEditionModal),
    fork(watchCreateDocument),
    fork(watchSaveDocument),
    fork(watchSalePayRentingList),
    fork(watchSaleSelfSupplyList),
    fork(watchToggleFullScreenDocumentModal),
    fork(watchRecoverDocument),
    fork(watchSendDMS),
    fork(watchCreateDocumentSheet),
    fork(watchDeleteDocumentSheet),
    fork(watchToggleFullScreenDocumentSheetModal),
    fork(watchOnDocumentTypeIdChange),
    fork(watchFetchPaperworkDossier),
    fork(watchSavePaperworkDossier),
    fork(watchUploadDocument),
    fork(watchRejectDocument),
    fork(watchPaperworkReject),
    fork(watchPaperworkGenDelivery),
    fork(watchPaperworkTransferCompleted),
    fork(watchPrintDeliveryChecklist),
    fork(watchValidateDocument),
    fork(watchUpdateDependantsSaleDossierAndHeader),
    fork(watchFetchAmountTaxModal),
    fork(watchFetchPurchaseDossier),
    fork(watchFetchDependantsPurchaseDossier),
    fork(watchFetchEconomicPlanPurchaseDossier),
    fork(watchChangeDossierSubType),
    fork(watchPurchaseReject),
    fork(watchPurchaseSendApprove),
    fork(watchPurchaseApprove),
    fork(watchSavePurchaseDossier),
    fork(watchPurchaseSynchroniseAvailabilityDate),
    fork(watchUpdateDossierCommon),
    fork(watchRefreshVehicle),
    fork(watchFetchEntityMasters),
    fork(watchOpenSourceChannelModal),
    fork(watchSaveSourceChannelModal),
    fork(watchDeleteSourceChannelModal),
    fork(watchOpenDocumentHistoricalCreationModal),
    fork(watchOpenDocumentHistoricalEditionModal),
    fork(watchCloseAddOrEditDocumentHistoricalModalCallback),
    fork(watchCreateDocumentHistorical),
    fork(watchSaveDocumentHistorical),
    fork(watchToggleFullScreenDocumentHistoricalModal),
    fork(watchCreateDocumentSheetHistorical),
    fork(watchDeleteDocumentSheetHistorical),
    fork(watchToggleFullScreenDocumentSheetHistoricalModal),
    fork(watchOpenReservationContractModal),
    fork(watchCloseReservationContractModal),
    fork(watchFetchInsuranceCompanyCombo),
    fork(watchOnDocumentHistoricalTypeIdChange),
    fork(watchFetchDossiers),
    fork(watchFetchDossiersSimple),
    fork(watchFetchVehicles),
    fork(watchFetchVehiclesSimple),
    fork(watchFetchEntities),
    fork(watchFetchCharges),
    fork(watchInitializeSearch),
    fork(watchFetchDocumentalSearchFormCombos),
    fork(watchFindFilterDocuments),
    fork(watchFetchExtraSalesMasters),
    fork(watchFetchRoleList),
    fork(watchOpenEditRoleModal),
    fork(watchOpenAddRoleModal),
    fork(watchSubmitRole),
    fork(watchDeleteRole),
    fork(watchfetchAuditInfo),
    fork(watchFetchCampaignDossier),
    fork(watchSaveCampaignDossier),
    fork(watchCampaignReject),
    fork(watchCampaignApprove),
    fork(watchCampaignSend),
    fork(watchCampaignDeny),
    fork(watchCreateExtraSale),
    fork(watchDeleteExtraSale),
    fork(watchUpdateExtraSale),
    fork(watchFetchProcessingAgencyModal),
    fork(watchCreatePresent),
    fork(watchDeletePresent),
    fork(watchUpdatePresent),
    fork(watchCreatePurchasePresent),
    fork(watchDeletePurchasePresent),
    fork(watchRecoverPurchasePresent),
    fork(watchUpdatePurchasePresent),
    fork(watchFetchExtraSaleTypes),
    fork(watchFetchBrands),
    fork(watchFetchFinancingCompanies),
    fork(watchFetchVehicleType),
    fork(watchFetchOrganizedUnitsTree),
    fork(watchFetchOrganizedUnitsTreeComboByPermission),
    fork(watchFetchTemplateTypes),
    fork(watchFetchTemplateFields),
    fork(watchFetchDocumentsAllTypes),
    fork(watchOpenExtraSaleModal),
    fork(watchSaveExtraSaleModal),
    fork(watchDeleteExtraSaleModal),
    fork(watchFetchSuppliedMasters),
    fork(watchOpenSuppliedModal),
    fork(watchSaveSuppliedModal),
    fork(watchDeleteSuppliedModal),
    fork(watchRecoverSuppliedModal),
    fork(watchFetchPresentsMasters),
    fork(watchOpenPresentsModal),
    fork(watchSavePresentsModal),
    fork(watchDeletePresentsModal),
    fork(watchRecoverPresentsModal),
    fork(watchRecoverExtraSaleModal),
    fork(watchFetchUserList),
    fork(watchFetchWalletList),
    fork(watchFetchBuyList),
    fork(watchFetchStockList),
    fork(watchFetchSaleList),
    fork(watchFetchReceiptList),
    fork(watchOpenAddUserModal),
    fork(watchOpenEditUserModal),
    fork(watchSubmitUser),
    fork(watchDeleteUser),
    fork(watchDeleteSalesman),
    fork(watchRecoverSalesman),
    fork(watchRecoverUser),
    fork(watchAdminChangeUserPassword),
    fork(watchFetchTemplateList),
    fork(watchSetActiveTemplate),
    fork(watchDuplicateTemplate),
    fork(watchDeleteTemplate),
    fork(watchOpenAddTemplateModal),
    fork(watchOpenEditTemplateModal),
    fork(watchSubmitTemplate),
    fork(watchCloseEditTemplateModal),
    fork(watchCreateTemplateField),
    fork(watchUpdateTemplateField),
    fork(watchDeleteTemplateField),
    fork(watchYesNoModal),
    fork(watchSaleWarningSave),
    fork(watchPurchaseWarningSave),
    fork(watchCampaignWarningSave),
    fork(watchPaperworkWarningSave),
    fork(watchClearVehicle),
    fork(watchCreateVehicleCreationModal),
    fork(watchInitializeDocumentalSearch),
    fork(watchFetchDocumentSearchListSuccess),
    fork(watchFetchSearchDossierSubType),
    fork(watchOpenDocumentalSearchModal),
    fork(watchGenerateStructure),
    fork(watchLoadFiles),
    fork(watchGetLoadResult),
    fork(watchUpdateDependantsPurchaseDossierAndHeader),
    fork(watchFetchStagesConditions),
    fork(watchSetActiveStage),
    fork(watchOpenAddConditionsModal),
    fork(watchOpenEditConditionsModal),
    fork(watchSaveConditionsModal),
    fork(watchCloseAddOrEditConditionsModal),
    fork(watchDeleteConditions),
    fork(watchFetchDocumentType),
    fork(watchFetchDocumentStatus),
    fork(watchFetchOrganizedUnitsCombo),
    fork(watchFetchCancelCombo),
    fork(watchFetchRoles),
    fork(watchFetchSectionKeyValue),
    fork(watchFetchPermissionKeyValue),
    fork(watchCheckCanCloseDocumentModal),
    fork(watchCheckDependenceMetadato),
    fork(watchsetCurrentSectionByPath),
    fork(watchFetchFunctionToAdd),
    fork(watchFetchFunctionToPass),
    fork(watchFetchReasons),
    fork(watchFetchAllReasons),
    fork(watchFetchDeliveryLocations),
    fork(watchFetchDossiersCompleteList),
    fork(watchSaleApproveByRole),
    fork(watchFetchAdditionalInfoCombo),
    fork(watchUndoCancel),
    fork(watchConfirmCancel),
    fork(watchMarkAsReadComment),
    fork(watchGetComments),
    fork(watchFetchAllDocuments),
    fork(watchFetchAllDocumentHistoricals),
    fork(watchSaveSelectedDocuments),
    fork(watchFetchEntitiesList),
    fork(watchOpenAddEntityModal),
    fork(watchOpenEditEntityModal),
    fork(watchSubmitEntity),
    fork(watchDeleteEntity),
    fork(watchFetchDocumentList),
    fork(watchFetchOrganizedUnitsAcceptClientsCombo),
    fork(watchFetchProcessingAgencyList),
    fork(watchOpenEditProcessingAgencyModal),
    fork(watchSubmitProcessingAgency),
    fork(watchOpenAddProcessingAgencyModal),
    fork(watchDeleteProcessingAgency),
    fork(watchRecoverProcessingAgency),
    fork(watchFetchFinancingList),
    fork(watchOpenAddFinancingModal),
    fork(watchOpenEditFinancingModal),
    fork(watchDeleteFinancing),
    fork(watchSubmitFinancing),
    fork(watchRecoverFinancing),
    // fork(watchSelectVOCompany),
    fork(watchFetchVoCompaniesCombo),
    fork(watchPutVOCompany),
    fork(watchSignDocument),
    fork(watchOpenModalVehicleNecessary),
    fork(watchFetchTemplateFile),
    fork(watchFetchRentingEntityCombo),
    fork(watchFetchEntitySubTypesList),
    fork(watchClearEntity),
    fork(watchClearEntityChild),
    fork(watchOpenUploadDocumentFleetModal),
    fork(watchCheckCanCloseUploadDocumentFleetModal),
    fork(watchCloseUploadDocumentFleetModal),
    fork(watchDashboardUploadDocumentFleet),
    fork(watchCheckToken),
    fork(watchFetchBankCombo),
    fork(watchDownloadBuyList),
    fork(watchDownloadSaleList),
    fork(watchDownloadStockList),
    fork(watchDownloadWalletList),
    fork(watchDownloadReceiptList),
    fork(watchFetchCreateDossierButton),
    fork(watchFetchHomologationClassificationCombo),
    fork(watchFetchHomologationProducerCombo),
    fork(watchSaleApproveVoucher),
    fork(watchFetchIaeCombo),
    fork(watchFetchComboOrigin),
    fork(watchFetchComboDestination),
    fork(watchFetchDashboardTask),
    fork(watchfetchChargeRenting),
    fork(watchfetchSelfSupply),
    fork(watchRefreshAuditInfo),
    fork(watchfetchFleets),
    fork(watchOpenDocumentCreationModalFleets),
    fork(watchOnDocumentTypeIdChangeFleets),
    fork(watchCreateDocumentFleets),
    fork(watchRequestSign),
    fork(watchCancelSign),
    fork(watchFetchPurchaseClient),
    fork(watchFetchPurchaseVehicle),
    fork(watchFetchPaperworkVehicle),
    fork(watchFetchPaperworkClient),
    fork(watchFetchCampaignClient),
    fork(watchFetchCampaignVehicle),
    fork(watchfetchPurchaseOtherData),
    fork(watchFetchPurchaseClientHistorical),
    fork(watchFetchPurchaseVehicleHistorical),
    fork(watchfetchPurchaseOtherDataHistorical),
    fork(watchfetchVehiclePurchaseComponent),
    fork(watchFetchPaperworkVehicleHistorical),
    fork(watchFetchPaperworkClientHistorical),
    fork(watchFetchCampaignClientHistorical),
    fork(watchFetchCampaignvehicleHistorical),
    fork(watchDownloadDocumentList),
    fork(watchFetchDocumentsTypeRestrictedCombo),
    fork(watchGenerateFileToSign),
    fork(watchCheckOrderStatus),
    fork(watchConfigureCampaign),
    fork(watchSaveconfigureCampaign),
    fork(watchSearchCampaignByCode),
    fork(watchHasOptionals),
    fork(watchPaperworkResendToProcessingAgency),
    fork(watchFetchServiceDestinationTypes),
    fork(watchCreateAssociatedCosts),
    fork(watchDeleteAssociatedCosts),
    fork(watchUpdateAssociatedCosts),
    fork(watchOpenAssociatedCostsModal),
    fork(watchDeleteAssociatedCostsModal),
    fork(watchFetchAssociatedCostsMasters),
    fork(watchRecoverAssociatedCostsModal),
    fork(watchSaveAssociatedCostsModal),
    fork(watchFetchPaymentMethod),
    fork(watchDeletePaymentMethod),
    fork(watchRecoverPaymentMethod),
    fork(watchOpenAddPaymentMethodModal),
    fork(watchSubmitPaymentMethod),
    fork(watchOpenEditPaymentMethodModal),
    fork(watchCreateOrderDossierPurchase),
    fork(watchFetchStockStatusCombo),
    fork(watchFetchPurchaseOrderTypeCombo),
    fork(watchSignTypes),
    fork(watchExistCif),
    fork(watchDocumentsToSendSign),
    fork(watchSendDocumentToSign),
    fork(watchDocumentsToSendSignGetAll),
    fork(watchPrintDocumentaryWarranty),
    fork(watchFetchFuelList),
    fork(watchOpenEditFuelModal),
    fork(watchSavePurchasePresentTypeModal),
    fork(watchOpenAddFuelModal),
    fork(watchSubmitFuel),
    fork(watchDeleteFuel),
    fork(watchRecoverFuel),
    fork(watchFetchFinancingTypeMasters),
    fork(watchSaveFinancingTypeModal),
    fork(watchOpenFinancingTypeModal),
    fork(watchOpenPurchasePresentTypeModal),
    fork(watchRecoverFinancingTypeModal),
    fork(watchRecoverPurchasePresentTypeModal),
    fork(watchDeleteFinancingTypeModal),
    fork(watchDeletePurchasePresentTypeModal),
    fork(watchFetchFinancingTypeList),
    fork(watchFetchPurchasePresentTypeList),
    fork(watchOpenDocWarning),
    fork(watchRefreshAuditDetails),
    fork(watchRefreshAuditDetailsModal),
    fork(watchSkipeItv),
    fork(watchSaleTransferRequest),
    fork(watchSaleTransferRequestApprove),
    fork(watchPurchaseTransferRequest),
    fork(watchPurchaseTransferRequestApproved),
    fork(watchFetchSectionList),
    fork(watchFetchQuestion),
    fork(watchFetchSectionQuestions),
    fork(watchFetchQuestionsBySearch),
    fork(watchVoteQuestion),
    fork(watchsearchDocuments),
    fork(watchgetDocumentsCount),
    fork(watchOpenDocWarning),
    fork(watchSkipeItv),
    fork(watchFetchMarginTypesCombo),
    fork(watchApproveMargin),
    fork(watchEncryptQrContent),
    fork(watchFetchReleaseNotesFile),
    fork(watchFetchFaqList),
    fork(watchDeleteFaq),
    fork(watchOpenFaqModal),
    fork(watchRecoverFaqModal),
    fork(watchFetchFaqSectionCombo),
    fork(watchSaveFaqModal),
    fork(watchFetchFaqSectionTable),
    fork(watchDeleteFaqSection),
    fork(watchFetchFaqSection),
    fork(watchRecoverFaqSection),
    fork(watchSaveFaqSection),
    fork(watchFetchFaqSectionComboModal),
    fork(watchfetchDeviceInstalls),
    fork(watchfetchMaxDeviceInstalls),
    fork(watchupdateDeviceInstall),
    fork(watchsendMailAppDownload),
    fork(watchFetchProcessingAgencyQuotaList),
    fork(watchSubmitProcessingAgencyAssignation),
    fork(watchEditProcessingAgencyAssignation),
    fork(watchAddProcessingAgencyAssignation),
    fork(watchDeleteProcessingAgencyAssignation),
    fork(watchDeleteProcessingAgencyQuota),
    fork(watchGetChangeUo),
    fork(watchgetSalesmanChangeUo),
    fork(watchFetchDossiersDownload),
    fork(watchFetchVehiclesDownloads),
    fork(watchFetchEntitiesDownload),
    fork(watchFetchDossiersSimpleDownload),
    fork(watchFetchVehiclesSimpleDownload),
    fork(watchfetchChargeRentingDownload),
    fork(watchfetchSelfSupplyDownload),
    fork(watchFetchChargesDownload),
    fork(watchfetchFleetsDownload),
    fork(watchPostChageUoDossier),
    fork(watchFetchComboSalesmanByUO),
    fork(watchFetchUsers),
    fork(watchAnulateCampaign),
    fork(watchFetchCampaignList),
    fork(watchFetchCampaignTypeByGroup),
    fork(watchFetchCampaignClass),
    fork(watchFetchCampaignGroup),
    fork(watchSubmitCampaignDetail),
    fork(watchDeleteCampaignDetail),
    fork(watchRecoverCampaignDetail),
    fork(watchAnulateCampaign),
    fork(watchFetchCountries),
    fork(watchFetchFamily),
    fork(watchprintChecklistVO),
    fork(watchFetchCampaignGroupList),
    fork(watchSubmitCampaignGroup),
    fork(watchRecoverCampaignGroup),
    fork(watchDeleteCampaignGroup),
    fork(watchFetchCampaignTypeList),
    fork(watchRecoverCampaignType),
    fork(watchDeleteCampaignType),
    fork(watchOpenCampaignGroupModal),
    fork(watchOpenCampaignTypeModal),
    fork(watchSubmitCampaignType),
    fork(watchlaunchCampaignProcess),
    fork(watchfetchCampaigndetailsForCampaign),
    fork(watchfetchDocumentaConditionCampaign),
    fork(watchdeleteDocumentaConditionCampaign),
    fork(watchfetchDocumentsConditionCampaign),
    fork(watchaddConditionDocumentalCampaign),
    fork(watchCampaignActive),
    fork(watchfetchCampaignExport),
    fork(watchlaunchExportCampaign),
    fork(watchlaunchExportCampaignExcel),
    fork(watchFetchExternalCode),
    fork(watchfetchCountryCampaignDetailList),
    fork(watchdeleteCountryCampaignDetail),
    fork(watchsetCountryCampaignDetail),
    fork(watchCampaignValidate),
    fork(watchCampaignDenie),
    fork(watchCampaignReturn),
    fork(watchRegistrationDeliveryWarningSave),
    fork(watchFetchClientUacModal),
    fork(watchCheckPurchaseTypeAndSetDefaultEntity),
    fork(watchRegistrationPrintDeliveryChecklist),
    fork(watchGetFilteredTaskToExcel),
    fork(watchOpenUploadSignModal),
    fork(watchUploadSign),
    fork(watchCloseUploadSignModal),
    fork(watchCheckCanCloseSignModal),
    fork(watchDownloadSignUser),
    fork(watchfetchRegisterOrderProcess),
    fork(watchfetchRegisterOrderDownload),
    fork(watchRegistrationPrintDeliveryChecklist),
    fork(watchUpdateDependantsRegistrationDeliveryDossierAndHeader),
    fork(watchfetchFtpRegister),
    fork(watchfetchFtpRegisterExport),
    fork(watchDownloadTemplate),
    fork(watchSaveServiceDossier),
    fork(watchFetchServiceDossier),
    fork(watchServiceDossierWarningSave),
    fork(watchFetchPowerbiConfig),
    fork(watchPowerBiRefreshSession),
    fork(watchFetchReportTypeCode),
    fork(watchPaperworkNotify),
    fork(watchExportDossiersCompleteList),
    fork(watchDeleteStyleTask),
    fork(watchOpenStyleTaskModal),
    fork(watchFetchStyleTaskList),
    fork(watchSubmitStyleTask),
    fork(watchGetOrdersStyleTask),
    fork(watchFetchServiceVehicle),
    fork(watchFetchServiceDossierVehicleHistorical),
    fork(watchFetchSalesServices),
    fork(watchSaveSalesService),
    fork(watchServiceDossierApprove),
    fork(watchServiceApproveByRole),
    fork(watchFetchIssuesEntity),
    fork(watchSaveIssueService),
    fork(watchserviceResolveIssue),
    fork(watchServiceRequestSpareParts),
    fork(watchServiceCompleteSpareParts),
    fork(watchServiceCompleteDeliveryPreparation),
    fork(watchfetchServicePrepare),
    fork(watchfetchDossierServicePrepareDownload),
    fork(watchfetchDossierServicePrepareRoleConfig),
    fork(watchServiceCompleteDeliveryPreparation),
    fork(watchFetchDefaultEntity),
    fork(watchFetchNotValidDatesService),
    fork(watchPaymentMethodCampaign),
    fork(watchfetchFuntionsToAddDocumentalCampaign),
    fork(watchFetchFleets),
    fork(watchSaveFleetCode),
    fork(watchfetchFleetsUser),
    fork(watchfetchDossierFleets),
    fork(watchfetchManagementActions),
    fork(watchsaveFleetData),
    fork(watchfetchDossierFleetsDownload),
    fork(watchRemoveFinanceServices),
    fork(watchCleanFinanceServices),
    fork(watchExtracMetadata),
    fork(watchfetchFleet),
    fork(watchfetchFleetComponent),
    fork(watchfetchFleetsInfo),
    fork(watchFetchFeleteDossierFleet),
    fork(watchFetchCreateOrUpdateFleet),
    fork(watchFetchChangeClientDossier),
    fork(watchFetchAssignDossierToFleet),
    fork(watchFetchDeleteFleet),
    fork(watchfetchFleetsInfoDownload),
    fork(watchfetchPaymentCombo),
    fork(watchfetchSalesSelfSupply),
    fork(watchfetchSalesSelfSupplyHistorical),
    fork(watchFetchSelfSupplyOrganizedUnites),
    fork(watchputCancelDossierPurchaseSelf),
    fork(watchgetDealerNumberModal),
    fork(watchgetVehicleDataQuiter),
    fork(watchsendQuiterVehicle),
    fork(watchUpdateErrorModel),
    fork(watchOpenEditFinancingModalUo),
    fork(watchsaveUoFinancingCompany),
    fork(watchdeleteUoFinancingCompany),
    fork(watchfetchFinancingProductCompany),
    fork(watchRecoverUoFinancingCompany),
    fork(watchfetchFinancingTypeProductFields),
    fork(watchfetchFinancingTypeProductList),
    fork(watchdeleteFinancingTypeProd),
    fork(watchrecoverFinancingTypeProd),
    fork(watchfetchOpenFinancingTypeProductModal),
    fork(watchupdateFinancingTypeProd),
    fork(watchfetchAllFinancingTypeProduct),
    fork(watchfetchFinancingTypeProductByProductId),
    fork(watchFetchDashboardCreateButtons),
    fork(watchFetchDossierFavorites),
    fork(watchFetchDossierLastAccessed),
    fork(watchFetchTaskFavorites),
    fork(watchSwitchCardTaskFavorite),
    fork(watchSwitchCardDossierFavorite),
    fork(watchUploadUoMassDocument),
    fork(watchopenMassModal),
    fork(watchfetchTemplateFile),
    fork(watchfetchReportCommercial),
    fork(watchfetchReportCommercialTable),
    fork(watchfetchReportCommercialTableDownload),
    fork(watchfetchexportReportCommercial),
    fork(watchprintSummarySale),
    fork(watchPrintCompeteSummarySale),
    fork(watchsaveReFinance),
    fork(watchdeleteReFinancing),
    fork(watchGetAllAvailableDocumentLandingRequest),
    fork(watchSendRequestToClient),
    fork(watchGetDocumentLandingRequest),
    fork(watchSendLandingCancelRequest),
    fork(watchPrcAgLoadMassDocument),
    fork(watchPrintDeliveryCertificates),
    fork(watchprintDeliveryChecklistSale),
    fork(watchfetchClientFleetPermissions),
    fork(watchfetchFleetsEntity),
    fork(watchgetCheckChangeEntityDossier),
    fork(watchClearClientFields),
    fork(watchFetchNonUpdatedDossiers),
    fork(watchDelayNonUpdatedDossierAlert),
    fork(watchsendToApproveActionFleet),
    fork(watchapproveActionFleet),
    fork(watchapproveRoleActionFleet),
    fork(watchrefreshDocumentInfo),
    fork(watchfecthValidateDocumentFleetPermission),
    fork(watchCreateDifferentDocumentFleet),
    fork(watchfetchCombosEconomicModal),
    fork(watchfetchEconomicFleetPermissions),
    fork(watchSimulateEconomicPlanFleet),
    fork(watchAddCampaign),
    fork(watchOpenEconomicModal),
    fork(watchsaveEconomicPlanFleet),
    fork(watchfetchShowFrontEconomicPlan),
    fork(watchDeleteEconomicCampaign),
    fork(watchMassiveDownloadDocumentList),
    fork(watchCreateMassiveDownload),
    fork(watchFetchMassiveDownloadStatus),
    fork(watchFindFilterMassiveDownload),
    fork(watchDownloadMassiveFile),
    fork(watchCancelMassiveFile),
    fork(watchfecthValidateDocumentFleetPermission),
    fork(watchCreateDifferentDocumentFleet),
    fork(watchgetDocumentTypeFleets),
    fork(watchFetchConfigParameterAll),
    fork(watchFetchOrganizedUnitsAll),
    fork(watchFetchOrganizedUnitConfigurationList),
    fork(watchOpenOrganizedUnitConfigurationModal),
    fork(watchSubmitOrganizedUnitConfiguration),
    fork(watchDeleteOrganizedUnitConfiguration),
    fork(watchgetDocumentTypeFleets),
    fork(watchUploadUserMassDocument),
    fork(watchRecEntLoadMassDocument),
    fork(watchCancelSignMessage),
    fork(watchGetCancelDocListFromRequest),
    fork(watchfecthValidateDocumentFleetPermission),
    fork(watchCreateDifferentDocumentFleet),
    fork(watchgetDocumentTypeFleets),
    fork(watchCreateUpdateDossierToshiko),
    fork(watchFetchDocumentTypeList),
    fork(watchOpenDocumentTypeModal),
    fork(watchSubmitDocumentType),
    fork(watchFetchDocumentTypeEntities),
    fork(watchDeleteDocumentType),
    fork(watchDocumentVisualizations),
    fork(watchDocumentVisualizationDelete),
    fork(watchDocumentVisualizationSubmit),
    fork(watchFetchDocumentTypeUseList),
    fork(watchOpenDocumentTypeUseModal),
    fork(watchSubmitDocumentTypeUse),
    fork(watchFetchDocumentTypeUse),
    fork(watchFetchSignTypeCombo),
    fork(watchFetchSignEntityTypeCombo),
    fork(watchDeleteDocumentTypeUse),
    fork(watchFetchOrganizedUnitSaleOperationList),
    fork(watchOpenOrganizedUnitSaleOperationModal),
    fork(watchSubmitOrganizedUnitSaleOperation),
    fork(watchDeleteOrganizedUnitSaleOperation),
    fork(watchFetchSaleTypeAll),
    fork(watchFetchOrganizedUnitsAll),
    fork(watchFetchOperationTypeAll),
    fork(watchOpenDocumentVisualitationModal),
    fork(watchFetchDocumentTypeMetadataList),
    fork(watchOpenDocumentTypeMetadataModal),
    fork(watchSubmitDocumentTypeMetadata),
    fork(watchDeleteDocumentTypeMetadata),
    fork(watchFetchDocumentType),
    fork(watchOpenMetadataListModal),
    fork(watchDeleteMetadataListElement),
    fork(watchSubmitMetadataList),
    fork(watchFetchOperationTypesTable),
    fork(watchOpenOperationTypeModal),
    fork(watchSaveOperationType),
    fork(watchRecoverOperationType),
    fork(watchDeleteOperationType),
    fork(watchFetchClientByRfc),
    fork(watchcheckPrivateRfcFormat),
    fork(watchforeignPassportWarning),
    fork(watchPurchaseApproveManager),
    fork(watchPurchaseApproveSalesManagerVo),
    fork(watchsetDossierFleetDataChangeProcess),
    fork(watchSetActionExpFleetProcess),
    fork(watchSetApproveActionFleetProcess),
    fork(watchgFetchUnattendedProcessTypes),
    fork(watchgFindUnattendedProcess),
    fork(watchFetchUnattendedProcess),
    fork(watchFetchDossierNumbersFromIds),
    fork(watchCreateDifferentDocumentFleetProcess),
    fork(watchSetEconomicPlanFleetProcess),
    fork(watchFetchDirectionsUOMasterList),
    fork(watchOpenDirectionUOModal),
    fork(watchSaveDirectionUOModal),
    fork(watchDeleteDirectionUOModal),
    fork(watchFetchLegalCompanyList),
    fork(watchOpenLegalCompanyModal),
    fork(watchSaveLegalCompanyModal),
    fork(watchDeleteLegalCompanyModal),
    fork(watchFindFleetsManagement),
    fork(watchExportFleetsManagement),
    fork(watchDeleteUoConditionDocCampaign),
    fork(watchFetchLegalCompanyCombo),
    fork(watchFetchProcessingAgencyCombo),
    fork(watchFetchDirectionOUCombo),
    fork(watchFetchOrganizedUnitList),
    fork(watchOpenOrganizedUnitModal),
    fork(watchSaveOrganizedUnitModal),
    fork(watchSetChangeTabFromMaster),
    fork(watchSetChangeTabFromConfMaster),
    fork(watchfetchFleetModel),
    fork(watchfetchDossierDetailsFleet),
    fork(watchfetchDeleteDossiersFleet),
    fork(watchFetchDossiersToAddFleet),
    fork(watchFetchAddDossiersToFleet),
    fork(watchFetchFleetOperateActions),
    fork(watchopenOperateFleetModal),
    fork(watchInitializeOperationActionsCombo),
    fork(watchfetchSaleTypeList),
    fork(watchfetchOpenSaleTypeModal),
    fork(watchUpdateSaleType),
    fork(watchDeleteSaleTypeModal),
    fork(watchRecoverSaleTypeModal),
    fork(watchFetchDossierStageList),
    fork(watchOpenEditDossierStageModal),
    fork(watchSubmitDossierStage),
    fork(watchfetchSignTypeList),
    fork(watchSubmitSignType),
    fork(watchFetchPapServicesFromManager),
    fork(watchFetchPapServicesDownloadFromManager),
    fork(watchSaveServicesAvailabilityDate),
    fork(watchfetchOperateDataFleetModalsPermissions),
    fork(watchfetchSalesManCombos),
    fork(watchfetchSalesTypeCombo),
    fork(watchsetOperateDataSaleFleetProcess),
    fork(watchSetOperateDataContactFleetProcess),
    fork(watchfetchPaperWorkFleetPermissions),
    fork(watchfetchEconomicShow),
    fork(watchfetchAgencyProcessing),
    fork(watchsetOperateDataPaperWorkFleetProcess),
    fork(watchsetOperateDataFinancingFleetProcess),
    fork(watchFetchFinancingCompaniesBy),
    fork(watchsetOperateDataChargeFleetProcess),
    fork(watchopenDocumentEditionModalFleet),
    fork(watchSetOperateActionSendToApprovalProcess),
    fork(watchSetOperateActionApproveProcess),
    fork(watchSetOperateActionRejectProcess),
    fork(watchSetOperateActionApprovePaperworkProcess),
    fork(watchSetOperateActionSendToAgencyProcess),
    fork(watchSetOperateActionDeleteProcess),
    fork(watchfetchDocumentLisFleet),
    fork(watchfetchDocumentDetailsFleet),
    fork(watchCreateIncorporateDocumentFleetProcess),
    fork(watchCreateValidateDocumentFleetProcess),
    fork(watchCreateReEditDocumentFleetProcess),
    fork(watchCreateCancelDocumentFleetProcess),
    fork(watchRefreshFormErrors),
    fork(watchFetchPlanLogiticsPapServices),
    fork(watchFetchCurrencyCombo),
    fork(watchCheckCurrencyDocTypes),
    fork(watchCreateRequestSignDocumentFleetProcess),
    fork(watchFetchSelfSupplyInfo),
    fork(watchFetchSelfSupplyInfoDownload),
    fork(watchFetchSignatureConfigList),
    fork(watchOpenSignatureConfigurationModal),
    fork(watchSubmitSignatureConfig),
    fork(watchDeleteSignatureConfig),
    fork(watchOpenSignatureEntityModal),
    fork(watchSubmitSignatureEntity),
    fork(watchDeleteSignatureEntity),
    fork(watchSetOperateActionStoreProcess),
    fork(watchPassSelfSupplyVoAction),
    fork(watchsetOperateDataDeliveryFleetProcess),
    fork(watchfetchGetCanCreateOperateServices),
    fork(watchsetOperateDataServiceFleetProcess),
    fork(watchSetDeleteFleetProcess),
    fork(watchSetOperateActionPrintDeliveryNoteProcess),
    fork(watchUnattendedProcessDownloadDeliveryNotes),
    fork(watchOpenEconomicFleetModal),
    fork(watchFetchEntityCountryTypeCombo),
    fork(watchFetchEntityTownTypeCombo),
    fork(watchFetchEntityCityTypeCombo),
    fork(watchFetchEntityColonyTypeCombo),
    fork(watchFetchEntityCPTypeCombo),
    fork(watchFetchEntityStateTypeCombo),
    fork(watchFetchRegistrationDeliveryEconomic),
    fork(watchSaveServicesConfirmationLogitics),
    fork(watchCreateCancelSignDocumentFleetProcess),
    fork(watchCreateRejectDocumentFleetProcess),
    fork(watchFetchProcessingAgencyOrganizedUnitConfigList),
    fork(watchShowProcessingAgencyOrganizedUnitConfigModal),
    fork(watchFetchProcessingAgencyCommunicationServices),
    fork(watchSubmitProcessingAgencyOrganizedUnitConfigModal),
    fork(watchDeleteProcessingAgencyOrganizedUnitConfig),
    fork(watchRefreshAuditFleetInfo),
    fork(watchAuditFleetInfoDownload),
    fork(watchOpenEconomicFleetModal),
    fork(watchDeleteProcessingAgencyOrganizedUnitConfig),
    fork(watchShowEditProcessingAgencyIntegrationModal),
    fork(watchUpdateAgencyCommunicationServiceId),
    fork(watchFetchProcessingAgencyRegistrationTypesList),
    fork(watchShowProcessingAgencyRegistrationTypesModal),
    fork(watchSubmitProcessingAgencyRegistrationTypesModal),
    fork(watchDeleteProcessingAgencyRegistrationTypes),
    fork(watchFetchProcessingAgencyServiceDestinationsList),
    fork(watchShowProcessingAgencyServiceDestinationsModal),
    fork(watchSubmitProcessingAgencyServiceDestinationsModal),
    fork(watchDeleteProcessingAgencyServiceDestinations),
    fork(watchFetchProcessingAgencyDocTypeUsesList),
    fork(watchShowProcessingAgencyDocTypeUsesModal),
    fork(watchSubmitProcessingAgencyDocTypeUsesModal),
    fork(watchDeleteProcessingAgencyDocTypeUses),
    fork(watchFetchProcessingAgencyServiceDestinationsCombo),
    fork(watchSetFleetChargeDossiersProcess),
    fork(watchFetchSalesman),
    fork(watchCreateRequestSignDocumentFleetProcess),
    fork(watchFetchSaleTypeByOperationTypeId),
    fork(watchLoadSepomexFileToValidate),
    fork(watchLoadSepomexFileToImport),
    fork(watchDeleteUserProcessingAgency),
    fork(watchGetUsersProcessingAgency),
    fork(watchFetchUserListForProcessingAgency),
    fork(watchAssignUserProcessingAgency),
    fork(watchSaleSendToBproDms),
    fork(watchRequestInvoiceToDMS),
    fork(watchRequestDeliveryToDMS),
    fork(watchRequestOutboundToDMS),
    fork(watchfetchOperationTypeDMS),
    fork(watchFetchCommercialSocietyTypeCombo),
    fork(watchImportVehicleModelFile),
    fork(watchFetchCarlineCombo),
    fork(watchFetchCatalogueCombo),
    fork(watchFetchColorCombo),
    fork(watchFetchTapestryCombo),
    fork(watchPurchaseFetchCatalogueModel),
    fork(watchOrderFetchCatalogueModel),
    fork(watchFetchPaymentMethodCombo),
    fork(watchSealDocumentStamp),
    fork(watchFetchClientByPhoneEmail),
    fork(watchReassignPurchaseCatalogueModel),
    fork(watchCloneRole),
    fork(watchOpenRatingModal),
    fork(watchSubmitRating),
    fork(watchDeleteRating),
    fork(watchFetchRatingList),
    fork(watchOpenRepairshopModal),
    fork(watchSubmitRepairshop),
    fork(watchDeleteRepairshop),
    fork(watchFetchRepairshopList),
    fork(watchOpenRepairshopUsersModal),
    fork(watchAssingUserRepairshop),
    fork(watchDeleteUserRepairshop),
    fork(watchOpenEditRepairshopModal),
    fork(watchFecthActivitiesBpro),
    fork(watchFetchPaperworkTypesList),
    fork(watchFetchPaperworkTypeInfo),
    fork(watchDeletePaperworkType),
    fork(watchUpdatePaperworkType),
    fork(watchPostPaperworkType),
    fork(watchAdviceClosePaperwokTypeMasterModal),
    fork(watchFetchSameLevelOusCombo),
    fork(watchFetchStockDossier),
    fork(watchFetchStockVehicle),
    fork(watchFetchStockDossierVehicleHistorical),
    fork(watchStockDossierWarningSave),
    fork(watchSaveStockDossier),
    fork(watchConfirmDateWithIdByStage),
    fork(watchConfirmPhotos),
    fork(watchGetDossierBudget),
    fork(watchFetchRatingCombo),
    fork(watchFetchRatingComboByOrganizedUnit),
    fork(watchDeleteDossierRating),
    fork(watchAddDossierRating),
    fork(watchFetchSameLevelOusCombo),
    fork(watchGetDocumentsMassiveDownload),
    fork(watchDownloadDocumentsSelected),
    fork(watchfetchDocumentDataOptions),
    fork(watchCreateSignDocumentFromTemplate),
    fork(watchPrintDocumentDynamicFromTemplate),
    fork(watchFetchDossierBudgetRating),
    fork(watchAddEditDossierBudgetRating),
    fork(watchDeleteDossierBudgetRating),
    fork(watchRejectDossierStockVoBudget),
    fork(watchDeleteCustomField),
    fork(watchFetchCustomFieldList),
    fork(watchOpenCustomFieldModal),
    fork(watchSubmitCustomField),
    fork(watchFetchCustomFieldTypesCombo),
    fork(watchRecoverCustomField),
    fork(watchChangeRelationEntryValues),
    fork(watchFetchCustomFields),
    fork(watchValidate),
    fork(watchValidateRepresentative),
    fork(watchValidateDNICIF),
    fork(watchSwitchFavorite),
    fork(watchSwitchTaskFavorite),
    fork(watchFetchDestinationCraneMaster),
    fork(watchFetchDestinationCraneItem),
    fork(watchDeleteDestinationCraneItem),
    fork(watchPutDestinationCraneItem),
    fork(watchActivateDossierCrane),
    fork(watchDeactivateDossierCrane),
    fork(watchDossierCraneConfirmDate),
    fork(watchGetDossierPhoto),
    fork(watchFetchMaritalStatusCombo),
    fork(watchOpenMaritalStatusModal),
    fork(watchSubmitMaritalStatus),
    fork(watchDeleteMaritalStatus),
    fork(watchFetchMaritalStatusList),
    fork(watchOpenEditMaritalStatusModal),
    fork(watchFetchModelCombo),
    fork(watchResetValues),
    fork(watchSkipDossierPhotos),
    fork(watchGroupDocuments),
    fork(watchFetchDocumentsGroupedAuditDetails),
    fork(watchDocumentsGroupedAuditDetailsModal),
    fork(watchFetchModelCombo),
    fork(watchRefreshPurchasePresentFromDocumentModal),
    fork(watchFetchClientDataAddress),
    fork(watchFetchProcessingAgencies),
    fork(watchFetchProcessAgenciesDownload),
    fork(watchSaleTransferReject),
    fork(watchPurchaseTransferReject),
    fork(watchPrintTemplateByReferenceType),
    fork(watchFetchOpenDuplicateSaleTypeModal),
    fork(watchFetchAuditConfigurationType),
    fork(watchFetchAuditAdminInfo),
    fork(watchOpenAuditAdminModal),
    fork(watchSaveAuditAdmin),
    fork(watchDocumentAudit),
    fork(watchFetchDocumentTypeUsesInfo),
    fork(watchFetchauditLoadData),
    fork(watchFetchAuditConfigurationDocument),
    fork(watchDeleteAuditAdmin),
    fork(watchFetchAuditsInfo),
    fork(watchDocumentAuditFinish),
    fork(watchFetchSearchDocumentsInfo),
    fork(watchOpenZipModal),
    fork(watchfetchUnattendedProcessFileListInfo),
    fork(watchFetchUserRecurrentServiceEntity),
    fork(watchSaveUserRecurrentServiceEntity),
    fork(watchDeleteUserRecurrentServiceEntity),
    fork(watchOpenDownloadModal),
    fork(watchPostBulkDownload),
    fork(watchFetchProcessingAgencyManagers),
    fork(watchFetchProcessingManagersByNameOrUo),
    fork(watchCreateProcessingManager),
    fork(watchUpdateProcessingManager),
    fork(watchDeleteProcessingManager),
    fork(watchRecoverProcessingManager),
    fork(watchFetchProcessingsAgencyManager),
    fork(watchFetchUnattendedProcessView),
    fork(watchRegisterLastAccessDossier),
    fork(watchfetchPurchaseStateLogisticCombo),
    fork(watchFetchFleetReport),
    fork(watchFetchFleetReportDownload),
    fork(watchSaveEventSubscription),
    fork(watchDeleteEventSubscription),
    fork(watchFetchEventSubscriptions),
    fork(watchEnableEventSubscription),
    fork(watchDisableEventSubscription),
    fork(watchFetchEventsCombo),
    fork(watchSaveEventSubscriptionFromAdmin),
    fork(watchDeleteEventSubscriptionFromAdmin),
    fork(watchFetchEventSubscriptionsFromAdmin),
    fork(watchEnableEventSubscriptionFromAdmin),
    fork(watchDisableEventSubscriptionFromAdmin),
    fork(watchfetchCodeCommisionList),
    fork(watchDeleteCodeCommission),
    fork(watchOpenCodeCommissionModal),
    fork(watchCreateOrUpdateCodeCommission),
    fork(watchFetchFleetOrganizedUnits),
    fork(watchFetchOpenCodeCommisionModal),
    fork(watchFetchCodeCommissionEconomicPlan),
    fork(watchFetchCodeCommissionCombo),
    fork(watchFetchCodeCommissionFleet),
    fork(watchCloseReleaseNotesModal),
    fork(watchFetchStockManagementDossiers),
    fork(watchFetchStockManagementDossiersDownload),
    fork(watchFetchUserRepairWorkshopsCombo),
    fork(watchSetHeader),
    fork(watchFetchFuelCombo),
    fork(watchFetchPaperworksDetailsFleet),
    fork(watchFetchDossierChildSubtypeDetailCount),
    fork(watchFetchUsageProcessTypesCombo),
    fork(watchFetchUsageList),
    fork(watchDownloadUsageList),
    fork(watchFetchUsageInfo),
    fork(watchDownloadUsageInfo),
    fork(watchFetchChargesDetailsFleet),
    fork(watchCreateUpdateDocumentFleetChargeProcess),
    fork(watchCreateRecoverDocumentFleetChargeProcess),
    fork(watchOpenChargeDetailsModal)
  ])
}
