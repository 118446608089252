import apiFetch from '../../apiFetch'

export default function (token, requestParams) {
  return apiFetch({
    endPoint: 'dossier/DashboardCompleteTableExport',
    method: 'POST',
    body: requestParams,
    token: token,
    parseBlobAndAssign: true
  })
}
