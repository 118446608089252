import React, { PureComponent } from 'react'
import { reduxForm, Field } from 'redux-form'
import { Tabs, Tab, Row, Button, Col } from 'react-bootstrap'
import InputText from '../commons/form/InputText'
import InputSelect from '../commons/form/InputSelect'
import InputDatePicker from '../commons/form/InputDatePicker'
import { validateDate } from '../../util/validationFunctions'
import SimpleTablePage from '../../containers/commons/general/SimpleTablePage'
import IDocCarPagination from '../commons/iDocCarPagination'
import InputCheckbox from '../commons/form/InputCheckBox'
import ImportExportFilePage from '../../containers/importExportFile/processRegisterFinderPage'
import FtpRegisterSearchPage from '../../containers/importExportFile/ftpRegisterSearchPage'
import { getSectionPermissions } from '../../util/permisionFunctions'
import { sectionPermissions } from '../../constants/dossier/common/buttonsHeader'
import { permissions } from '../../constants/backendIds'
import { formatMoney } from '../../util/utils'
class importExportFile extends PureComponent {
  constructor () {
    super()
    this.state = {
      disableResetButton: true,
      sectionTabsConfiguration: {
        importOrder: {
          permission: null
        },
        exportInvoice: {
          permission: null
        },
        ftpRegister: {
          permission: null
        }
      },
      fields: {},
      documentIds: [],
      toExport: null,
      notExport: null,
      checkAll: false,
      defaultActiveKey: 3
    }
  }

  checkExport (id, value) {
    let data = JSON.parse(JSON.stringify(this.props.dossierData))
    let toexport = this.state.toExport !== null ? this.state.toExport : []
    let notexport = this.state.notExport !== null ? this.state.notExport : []
    data.forEach(element => {
      if (element.dossierId === id) {
        element.export = value
      }
      if (element.export) {
        if (toexport.indexOf(element.dossierId) === -1) {
          toexport.push(element.dossierId)
        }
        if (notexport.indexOf(element.dossierId) !== -1) {
          notexport.splice(notexport.indexOf(element.dossierId), 1)
        }
      } else {
        if (notexport.indexOf(element.dossierId) === -1) {
          notexport.push(element.dossierId)
        }
        if (toexport.indexOf(element.dossierId) !== -1) {
          toexport.splice(toexport.indexOf(element.dossierId), 1)
        }
      }
    })
    this.setState({ toExport: toexport, notExport: notexport })
    this.props.actions.changeExportCampaignExport(data)
    this.setState({ checkAll: false })
  }

  componentDidMount () {
    this.initializePermisions(this.props)
    this.props.actions.initializeSearch()
    this.props.actions.fetchLowestOrganizedUnits()
    this.props.actions.resetFilters()
    this.defaultTab()
  }

  initializePermisions (props) {
    const section = props.sectionsGeneral.filter((tabConf) => tabConf.code === sectionPermissions.importExport)
    if (section.length > 0 && section[0] != null) {
      const newState = getSectionPermissions(section[0])
      let permissionState = this.state.sectionTabsConfiguration
      permissionState = { ...permissionState, ...newState }
      this.setState({ sectionTabsConfiguration: permissionState })
    }
  }

  handleClickReset (e) {
    if (!this.state.disableResetButton) {
      this.setState({ checkAll: false, toExport: null, notExport: null })
      this.onClickResetFilters(e)
    }
  }

  getIconClass (field) {
    if (this.props.filters.orderBy.includes(field)) {
      return this.props.filters.orderBy.includes('-') ? 'ico-sort-selected-asc' : 'ico-sort-selected-desc'
    } else {
      return 'ico-sort'
    }
  }

  onClickResetFilters (e) {
    if (e) {
      e.preventDefault()
    }
    this.props.initialize({})
    this.props.actions.resetFilters()
    this.setState({ fields: {} })
  }

  fieldFill (field) {
    this.setState({ fields: { ...this.state.fields, ...field } })
  }

  validateDate (value, allValues, props) {
    const result = validateDate(value, props)
    return result === Object(result) ? undefined : result
  }

  search (e) {
    this.setState({ toExport: null, notExport: null, checkAll: false })
    this.props.actions.fetchCampaignExport({ ...this.props.filters, ...this.state.fields })
  }

  onOrderChange (e) {
    const newOrderBy = this.props.filters.orderBy.includes(`%2B${e.currentTarget.dataset.field}`) ? `-${e.currentTarget.dataset.field}` : `%2B${e.currentTarget.dataset.field}`
    this.props.actions.fetchCampaignExport({ ...this.props.filters, ...this.state.fields, orderBy: newOrderBy })
  }

  onPageChange (page) {
    this.props.actions.fetchCampaignExport({ ...this.props.filters, ...this.state.fields, page: page })
  }

  checkExportorNot (dossierData) {
    if (this.state.checkAll) {
      dossierData.forEach(element => {
        element.export = true
      })
    } else {
      dossierData.forEach(element => {
        if (element.exported) {
          element.export = false
        }
      })
      if (this.state.toExport != null) {
        this.state.toExport.forEach(x => {
          let found = dossierData.find(function (element) {
            return element.dossierId === x
          })
          if (found !== null && found !== undefined) {
            found.export = true
          }
        })
      }
      if (this.state.notExport != null) {
        this.state.notExport.forEach(x => {
          let found = dossierData.find(function (element) {
            return element.dossierId === x
          })
          if (found !== null && found !== undefined) {
            found.export = false
          }
        })
      }
    }
  }

  checkAll (dossiers, value) {
    this.setState({ checkAll: value })
  }

  exportData (e) {
    this.props.actions.launchExportCampaign({ ...this.props.filters, ...this.state.fields }, this.state.checkAll, this.state.toExport, this.state.notExport)
  }

  exportExcelData (e) {
    this.props.actions.launchExportCampaignExcel({ ...this.props.filters, ...this.state.fields }, this.state.checkAll, this.state.toExport, this.state.notExport)
  }

  componentDidUpdate (nextProps) {
    let disableResetButton = nextProps.pristine
    this.setState({ disableResetButton: disableResetButton })
  }

  chargeFilesFtp () {
    this.props.actions.launchCampaignProcessFiles()
  }

  defaultTab () {
    let defaultActiveKey = 1
    if (this.state.sectionTabsConfiguration.importOrder.permission === permissions.hidden) {
      defaultActiveKey = 2
    }
    if (this.state.sectionTabsConfiguration.exportInvoice.permission === permissions.hidden) {
      defaultActiveKey = 3
    }

    this.setState({ defaultActiveKey: defaultActiveKey })
  }

  render () {
    const {
      t, hasSearch, filters: { page }, pages, count, dossierData,
      actions: { searchTabChange }, lowestOrganizedUnitsCombo
    } = this.props

    const tKey = 'IMPORT_EXPORT_FILE.'

    const tKeySearch = 'SEARCH.CATEGORIES.DOSSIER.'

    const styleTd = {
      verticalAlign: 'middle'
    }

    this.checkExportorNot(dossierData)
    this.defaultTab()
    return (
      <div className="search-wrapper">
        <div className="search-header">
          <div className="title-user">
            <i className="ico-documental-search"/> <h2>{t(`${tKey}TITLE`)}</h2>
          </div>
        </div>
        <div className="filters-search-wrapper">
          <Row>
            <Tabs
              defaultActiveKey={this.state.defaultActiveKey}
              onSelect={(idx, event) => { searchTabChange(idx, event); this.onClickResetFilters(); this.setState({ idx }) }}
              animation={false}
              id="tab-search"
              className="col-sm-12"
              activeKey={this.state.tabActiveKey}
            >
              {this.state.sectionTabsConfiguration.importOrder.permission !== permissions.hidden && (
                <Tab
                  eventKey={1}
                  title={t(`${tKey}IMPORT`)}>
                  <Button className="btn-standard" onClick={() => { this.chargeFilesFtp(event) }}><span> {t(`${tKey}IMPORT_ORDER`)}</span></Button>
                  <ImportExportFilePage></ImportExportFilePage>
                </Tab>
              )}
              {this.state.sectionTabsConfiguration.exportInvoice.permission !== permissions.hidden && (
                <Tab
                  eventKey={2} title={t(`${tKey}EXPORT`)}
                >
                  <div className="search-header">
                    <div className="title-user">
                      <i className="ico-documental-search"/> <h2>{t(`${tKey}DOSSIER_EXPORT`)}</h2>
                    </div>
                  </div>
                  <Field
                    id="organizedUnit"
                    name='dossier.organizedUnit'
                    placeholder={t(`${tKeySearch}ORGANIZED_UNIT`)}
                    controlLabel={t(`${tKeySearch}ORGANIZED_UNIT`)}
                    component={InputSelect}
                    options={lowestOrganizedUnitsCombo}
                    valueKey="id"
                    labelKey="value"
                    onInputChange={ (val) => { this.fieldFill({ organizedUnit: val }) } }
                  />
                  <Field
                    id="creationDateFrom"
                    name='dossier.approvalDateFrom'
                    placeholder={t(`${tKey}APROVE_DATE_FROM`)}
                    controlLabel={t(`${tKey}APROVE_DATE_FROM`)}
                    component={InputDatePicker}
                    validate={[this.validateDate]}
                    onInputChange={(val) => this.fieldFill({ approvalDateFrom: val })}
                  />
                  <Field
                    id="creationDateTo"
                    name='dossier.approvalDateTo'
                    placeholder={t(`${tKey}APROVE_DATE_TO`)}
                    controlLabel={t(`${tKey}APROVE_DATE_TO`)}
                    component={InputDatePicker}
                    validate={[this.validateDate]}
                    onInputChange={(val) => this.fieldFill({ approvalDateTo: val })}
                  />
                  <Field
                    id="number"
                    name='dossier.dossierNumber'
                    placeholder={t(`${tKeySearch}DOSSIER_NUMBER`)}
                    controlLabel={t(`${tKeySearch}DOSSIER_NUMBER`)}
                    component={InputText}
                    type="text"
                    onInputChange={(val) => this.fieldFill({ number: val })}
                  />
                  <Row>
                    <Col sm={12} className="text-center">
                      <hr/>
                      <Button
                        onClick={(e) => this.search(e)}
                        className="btn-standard"
                      >
                        <i className="ico-search"/>
                        {t('SEARCH.ACTIONS.SEARCH_BUTTON')}
                      </Button>
                      <Button
                        onClick={(e) => this.handleClickReset(e)}
                        className={'btn-white btn-icon' + (this.state.disableResetButton ? ' btn-cancel' : ' btn-standard')}
                        style={{ marginLeft: '10px', fontSize: '12px' }}
                      >
                        <i className="ico-cancel"/> {t('SEARCH.ACTIONS.RESET_BUTTON')}
                      </Button>
                      {hasSearch && dossierData && dossierData.length > 0 && (<Button
                        onClick={(event) => this.exportData(event)}
                        className="btn-standard" style={{ marginLeft: '166px' }}>
                        <i className="ico-if_Download_1031520" />
                        {t('SEARCH.ACTIONS.EXPORT_FTP')}
                      </Button>)}
                      {hasSearch && dossierData && dossierData.length > 0 && (<Button
                        onClick={(event) => this.exportExcelData(event)}
                        className="btn-standard" style={{ marginLeft: '166px' }}>
                        <i className="ico-if_Download_1031520" />
                        {t('SEARCH.ACTIONS.EXPORT_EXCEL')}
                      </Button>)}
                      <br></br>
                      <br></br>
                    </Col>
                  </Row>

                  <div>
                    {hasSearch ? ([

                      <div className="table-wrapper" key="table-wrapper">
                        <Field
                          component={InputCheckbox}
                          name={'checkAll'}
                          placeholder={t(`${tKey}CHECK_ALL`)}
                          input = {{ value: this.state.checkAll, onChange: (value) => this.checkAll(dossierData, value) }}
                        />

                        <SimpleTablePage
                          columns={[
                            <th key={0} data-field="dossierId" onClick={(event) => this.onOrderChange(event)}>
                              {t(`${tKey}DOSSIER_ID`)}&nbsp;<i className={this.getIconClass('dossierId')}/>
                            </th>,
                            <th key={1} data-field="number" onClick={(event) => this.onOrderChange(event)}>
                              {t(`${tKey}DOSSIER_NUMBER`)}&nbsp;<i className={this.getIconClass('number')}/>
                            </th>,
                            <th key={2} data-field="comision" onClick={(event) => this.onOrderChange(event)}>
                              {t(`${tKey}COMISION`)}&nbsp;<i className={this.getIconClass('comision')}/>
                            </th>,
                            <th key={3} data-field="approvedAmount" onClick={(event) => this.onOrderChange(event)}>
                              {t(`${tKey}APPROVED_AMOUNT`)}&nbsp;<i className={this.getIconClass('approvedAmount')}/>
                            </th>,
                            <th key={4} data-field="approvedDate" onClick={(event) => this.onOrderChange(event)}>
                              {t(`${tKey}APPROVED_DATE`)}&nbsp;<i className={this.getIconClass('approvedDate')}/>
                            </th>,
                            <th key={5} data-field="organizedUnit" onClick={(event) => this.onOrderChange(event)}>
                              {t(`${tKey}ORGANIZED_UNIT`)}&nbsp;<i className={this.getIconClass('organizedUnit')}/>
                            </th>,
                            <th key={6} data-field="dealerCode" onClick={(event) => this.onOrderChange(event)}>
                              {t(`${tKey}DEALER_CODE`)}&nbsp;<i className={this.getIconClass('dealerCode')}/>
                            </th>,
                            <th key={7} data-field="exported" onClick={(event) => this.onOrderChange(event)}>
                              {t(`${tKey}EXPORTED`)}&nbsp;<i className={this.getIconClass('exported')}/>
                            </th>,
                            <th key={8} data-field="export" onClick={(event) => this.onOrderChange(event)}>
                              {t(`${tKey}TO_EXPORT`)}&nbsp;<i className={this.getIconClass('export')}/>
                            </th>
                          ]}
                          rows={dossierData && dossierData.map((row, idx) => (
                            <tr key={idx}>
                              <td style={styleTd}>{row.dossierId}</td>
                              <td style={styleTd}>{row.number}</td>
                              <td style={styleTd}>{row.comision}</td>
                              <td style={styleTd}>{row.approvedAmount !== undefined ? formatMoney(row.approvedAmount, 2, ',', '.') : ''}</td>
                              <td style={styleTd}>{row.approvedDate}</td>
                              <td style={styleTd}>{row.organizedUnit}</td>
                              <td style={styleTd}>{row.dealerCode}</td>
                              <td style={styleTd}>{row.exported ? 'SI' : 'NO'}</td>
                              <td>
                                {<Field
                                  component={InputCheckbox}
                                  name={'selectedCheck_' + row.dossierId}
                                  input = {{ value: row.export, onChange: (value) => this.checkExport(row.dossierId, value) }}
                                />}
                              </td>
                            </tr>
                          ))}
                        />
                      </div>,
                      <div className="search-footer" key="search-footer">
                        <IDocCarPagination
                          id="btn_pag_sales"
                          page={page}
                          pagesCount={pages}
                          totalRows= {count}
                          onSelectAction={(page) => this.onPageChange(page)}
                        />
                      </div>
                    ]) : null}
                  </div>
                </Tab>)}
              {this.state.sectionTabsConfiguration.ftpRegister.permission !== permissions.hidden && (
                <Tab
                  eventKey={3} title={t(`${tKey}FTPREGISTER`)}
                >
                  <FtpRegisterSearchPage/>
                </Tab>)}
            </Tabs>
          </Row>
        </div>
      </div>
    )
  }
}

const validate = (values, { t }) => {
  const errors = {}
  return errors
}

export default reduxForm({
  form: 'importExport',
  validate
})(importExportFile)
