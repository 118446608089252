import React, { PureComponent } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import InputSelect from '../../../../commons/form/InputSelect'
import { reduxForm, Field } from 'redux-form'

class Step2 extends PureComponent {
  render () {
    return (
      <Row>
        <Col sm={12} className="filter-wrapper">
          <Field
            colSm={3}
            id='documentType'
            name='documentType'
            placeholder='Tipo de documento'
            labelKey='documentType'
            valueKey='documentType'
            component={InputSelect}
          />
          <Field
            colSm={3}
            id='documentStage'
            name='documentStage'
            placeholder='Estado del documento'
            labelKey='documentStage'
            valueKey='documentStage'
            component={InputSelect}
          />
          <Col sm={3} smPush={3} className="text-right">
            <Button className="btn-standard btn-continue">
              Asignar
            </Button>
          </Col>
        </Col>
        <Col sm={12}>
          <ul className="list-document-uploaded">
            <li className="document-uploaded">
              <i className="ico-cancel" />
              <i id="documentStatus" className="document ico-document-pending" />
            </li>
          </ul>
        </Col>
      </Row>
    )
  }
}

export default reduxForm({ form: 'newForm' })(Step2)
