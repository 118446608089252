import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PresentsModal from '../../components/masters/PresentsModal'
import { translate } from 'react-polyglot'
import { closePresentsModal, savePresentsModal } from '../../actions/masters/masters'

export function mapStateToProps (state) {
  return {
    showPresentsModal: state.entityMasters.showPresentsModal,
    languageList: state.entityMasters.languageList,
    presentTypeLanguages: state.entityMasters.presentsFields.presentTypeLanguages || [],
    presentsId: state.entityMasters.presentsId
    /* ,
    languageList: [
      {languageId: 1, name: 'Castellano', description: 'es-ES'},
      {languageId: 2, name: 'Catalán', description: 'es-CA'}
    ] */
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      closePresentsModal,
      savePresentsModal
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(PresentsModal))
