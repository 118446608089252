import { call, put, takeEvery, select } from 'redux-saga/effects'
import { change, reset } from 'redux-form'
import clientActionsTypes from '../../../../constants/actions/dossier/common/client'
import { getAuth } from '../../../../selectors/access/auth'
import getEntityById from '../../../../services/entity/getEntityById'
import { showLoader, hideLoader } from '../../../../actions/common'
import { fetchSalesDossierSuccess } from '../../../../actions/dossier/sales'
import { fetchPaperworkDossierSuccess } from '../../../../actions/dossier/paperwork'
import { fetchPurchaseDossierSuccess } from '../../../../actions/dossier/purchase'
import { fetchCampaignDossierSuccess } from '../../../../actions/dossier/campaign'
import { fetchRegistrationDeliveryDossierSuccess } from '../../../../actions/dossier/registrationDelivery'
import { dossierTypes as dossierTypesConstant } from '../../../../constants/dossier/common/dossierTypes'
import { handleError } from '../../../errors/errorManager'
import { changeDniData, fetchEntityFleetSucces, fetchEntityUacModalSucces } from '../../../../actions/management/management'
import { fetchEntityOperateFleetSucces } from '../../../../actions/management/auxModals/auxModals'
import { yesNoModal } from '../../../modalsListeners/yesNoModal'

export function * fetchClientFromDocument ({ entityId, parentDossierType }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const entity = yield call(getEntityById, entityId, auth.token)
    entity.updated = true
    switch (parentDossierType) {
      case dossierTypesConstant.sales:
      { const salesDossierEntity = yield select(state => state.salesDossier.entityComponent)
        yield put(fetchSalesDossierSuccess({ entityComponent: { ...entity, previousEntity: salesDossierEntity.previousEntity } }))
        yield put(change('salesDossier', 'entityComponent', entity))
        break }
      case dossierTypesConstant.paperwork:
      { const paperworkDossierEntity = yield select(state => state.paperworkDossier.entityComponent)
        yield put(fetchPaperworkDossierSuccess({ entityComponent: { ...entity, previousEntity: paperworkDossierEntity.previousEntity } }))
        yield put(change('paperwork_dossier', 'entityComponent', entity))
        break }
      case dossierTypesConstant.purchase:
      { const purchaseDossierEntity = yield select(state => state.purchaseDossier.entityComponent)
        yield put(fetchPurchaseDossierSuccess({ entityComponent: { ...entity, previousEntity: purchaseDossierEntity.previousEntity } }))
        yield put(change('purchase_dossier', 'entityComponent', entity))
        break }
      case dossierTypesConstant.campaign:
      { const campaignDossierEntity = yield select(state => state.campaignDossier.entityComponent)
        yield put(fetchCampaignDossierSuccess({ entityComponent: { ...entity, previousEntity: campaignDossierEntity.previousEntity } }))
        yield put(change('campaign_dossier', 'entityComponent', entity))
        break }
      case dossierTypesConstant.registrationDelivery:
      { const registrationDeliveryDossierEntity = yield select(state => state.registrationDeliveryDossier.entityComponent)
        yield put(fetchRegistrationDeliveryDossierSuccess({ entityComponent: { ...entity, previousEntity: registrationDeliveryDossierEntity.previousEntity } }))
        yield put(change('registrationDeliveryDossier', 'entityComponent', entity))
        break }
      case dossierTypesConstant.fleetFinder:
      {
        yield put(changeDniData(entity.dniCif))
        break
      }
      case dossierTypesConstant.addNewFleetModal:
      { const salesDossierEntity = yield select(state => state.management.addNewFleetModal.entityComponent)
        yield put(fetchEntityFleetSucces({ entityComponent: { ...entity, previousEntity: salesDossierEntity.previousEntity } }))
        yield put(change('addNewFleetModal', 'entityComponent', entity))
        break
      }
      case dossierTypesConstant.createNewFleetModal:
      { const salesDossierEntity = yield select(state => state.management.createNewFleetModal.entityComponent)
        yield put(fetchEntityOperateFleetSucces({ entityComponent: { ...entity, previousEntity: salesDossierEntity.previousEntity } }))
        yield put(change('createNewFleetModal', 'entityComponent', entity))
        break }
      case dossierTypesConstant.uacModal:
      { const salesDossierEntity = yield select(state => state.management.uacModal.entityComponent)
        yield put(fetchEntityUacModalSucces({ entityComponent: { ...entity, previousEntity: salesDossierEntity.previousEntity } }))
        yield put(change('uacModal', 'entityComponent', entity))
        break }
    }
  } catch (error) {
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('purchase_dossier'))
      yield put(reset('paperwork_dossier'))
      yield put(reset('campaign_dossier'))
      yield put(reset('registrationDeliveryDossier'))
    }
    yield call(handleError, { error, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchClientFromDocument () {
  yield takeEvery(clientActionsTypes.FETCH_CLIENT_FROM_DOCUMENT, fetchClientFromDocument)
}

export function * fetchClientUacModal ({ entityId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const entity = yield call(getEntityById, entityId, auth.token)
    yield put(change('uacModal', 'uacEntityOwner', entity))
  } catch (error) {
    const sessionHandler = function * () {
      yield put(reset('uacModal'))
    }
    yield call(handleError, { error, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchClientUacModal () {
  yield takeEvery(clientActionsTypes.FETCH_CLIENT_UAC_MODAL, fetchClientUacModal)
}

export function * fetchProviderFromDocument ({ entityId, parentDossierType }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const entity = yield call(getEntityById, entityId, auth.token)
    entity.updated = true
    switch (parentDossierType) {
      case dossierTypesConstant.paperwork:
      { const paperworkDossierEntityProvider = yield select(state => state.paperworkDossier.entityComponent)
        yield put(fetchPaperworkDossierSuccess({ entityProviderComponent: { ...entity, previousEntity: paperworkDossierEntityProvider.previousEntity } }))
        yield put(change('paperwork_dossier', 'entityProviderComponent', entity))
        break }
    }
  } catch (error) {
    const sessionHandler = function * () {
      yield put(reset('paperwork_dossier'))
    }
    yield call(handleError, { error, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchProviderFromDocument () {
  yield takeEvery(clientActionsTypes.FETCH_PROVIDER_FROM_DOCUMENT, fetchProviderFromDocument)
}

export function * resetValues ({ resolve, reject }) {
  try {
    const confirmed = yield call(yesNoModal, 'confirmClientFleets')
    if (confirmed) {
      const state = yield select(state => state)
      const dossierEntityState = state.form.createNewFleetModal.values.entityComponent

      let data = {
        entityId: dossierEntityState?.entityId
      }
      var fleet = yield select(state => state.management.createNewFleetModal.fleet)

      for (const prop in dossierEntityState) {
        if (prop !== 'previousEntity' && prop !== 'entityTypeId') {
          data[prop] = dossierEntityState[prop]
        }
      }

      fleet.entityComponent = {}
      fleet.entityComponent.entityTypeId = dossierEntityState?.entityId
      fleet.entityChildComponent = {}
      fleet.dossierContactComponent = {}
      yield put(fetchEntityFleetSucces({ entityComponent: fleet }))

      yield put(change('createNewFleetModal', 'entityComponent', dossierEntityState?.entityId))
      resolve(true)
    }
    resolve(false)
  } catch (error) {
    yield call(handleError, { error })
    reject(false)
  } finally {
    yield put(hideLoader())
  }
}

export function * watchResetValues () {
  yield takeEvery(clientActionsTypes.RESET_VALUES, resetValues)
}
