export default {
  FETCH_ROLE_ADMIN: 'FETCH_ROLE_ADMIN',
  FETCH_ROLE_ADMIN_SUCCESS: 'FETCH_ROLE_ADMIN_SUCCESS',
  OPEN_ADD_ROLE_MODAL: 'OPEN_ADD_ROLE_MODAL',
  OPEN_ADD_ROLE_MODAL_SUCCESS: 'OPEN_ADD_ROLE_MODAL_SUCCESS',
  OPEN_EDIT_ROLE_MODAL: 'OPEN_EDIT_ROLE_MODAL',
  OPEN_EDIT_ROLE_MODAL_SUCCESS: 'OPEN_EDIT_ROLE_MODAL_SUCCESS',
  CLOSE_EDIT_ROLE_MODAL: 'CLOSE_EDIT_ROLE_MODAL',
  SUBMIT_ROLE: 'SUBMIT_ROLE',
  DELETE_ROLE: 'DELETE_ROLE',
  CLONE_ROLE: 'CLONE_ROLE'
}
