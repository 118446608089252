import React, { PureComponent } from 'react'
import { reduxForm, Field } from 'redux-form'
import { Row, Col, Table, Button, OverlayTrigger, Glyphicon, Tooltip } from 'react-bootstrap'
import IDocCarPagination from '../../commons/iDocCarPagination'
import InputText from '../../commons/form/InputText'
import InputTreeSelect from '../../commons/form/InputTreeSelect'
import FaqMasterModalPage from '../../../containers/masters/Faq/FaqMasterModalPage'

class FaqMaster extends PureComponent {
  constructor () {
    super()
    this.state = {
      filter: {
        searchUO: null,
        searchSection: null,
        searchQuestion: null
      },
      ouId: null
    }
  }

  getSortIcon (column = '') {
    switch (this.props.filter.orderBy) {
      case '+' + column:
        return 'ico-sort-selected-asc'
      case '-' + column:
        return 'ico-sort-selected-desc'
      default:
        return 'ico-sort'
    }
  }

  changeOrder (field) {
    switch (this.props.filter.orderBy) {
      case `+${field}`:
        this.props.actions.fetchFaqList({ ...this.state.filter, ...this.props.filter, orderBy: `-${field}` })
        break
      default:
        this.props.actions.fetchFaqList({ ...this.state.filter, ...this.props.filter, orderBy: `+${field}` })
        break
    }
  }

  handleOrganizedUnitChange (filterUO) {
    this.setState({ filter: { ...this.state.filter, searchUO: [filterUO], searchSection: [] }, ouId: filterUO })
    this.props.change('filters.fasqSection', [])
    this.props.actions.fecthFaqSectionCombo(filterUO)
  }

  handleQuestionChange (filterText) {
    if (this.state.filter.searchQuestion !== filterText) {
      this.setState({ filter: { ...this.state.filter, searchQuestion: filterText } })
    }
  }

  handleSectionChange (searchSection) {
    this.setState({ filter: { ...this.state.filter, searchSection: [searchSection] } })
  }

  searchFaqListEventHandler () {
    const faqFilter = { ...this.props.filter, ...this.state.filter, page: 1 }
    this.props.actions.fetchFaqList(faqFilter)
  }

  componentDidMount () {
    this.props.actions.fecthFaqSectionCombo(null)
  }

  resetFilters () {
    this.setState({
      filter: {
        searchUO: null,
        searchSection: null,
        searchQuestion: null
      },
      ouId: null
    }, () => { this.searchFaqListEventHandler() })
  }

  render () {
    const {
      t, faqList, pagesCount, faqCount, filter,
      filter: { page },
      combos: { UOTreeCombo, faqSectionCombo },
      actions: { deleteFaq, openFaqModal, recoverFaqModal, fetchFaqList }
    } = this.props

    const tKey = 'MASTERS.'
    const editTooltip = (<Tooltip id="editTooltip">{t(`${tKey}EDIT`)}</Tooltip>)
    const deleteTooltip = (<Tooltip id="deleteTooltip">{t(`${tKey}DELETE`)}</Tooltip>)
    const recoverTooltip = (<Tooltip id="recoverTooltip">{t(`${tKey}RECOVER`)}</Tooltip>)
    const hasFilters = (this.state.filter.searchQuestion || this.state.filter.searchSection || this.state.filter.searchUO || this.state.ouId)

    return (
      <div className="admin-wrapper">
        <FaqMasterModalPage/>
        <Row className="section-users-header">
          <Col sm={12}>
            <div className="header-panel">
              <h4>{t(`${tKey}FAQ.TITLE`)}</h4>
            </div>
          </Col>
        </Row>
        <Row>
          <div className="search-form faq">
            <span className="title-search"><i className="ico-search" />
              {t(`${tKey}FAQ.SEARCH_FAQ`)}</span>
            <Row>
              <Field
                name="filters.organizedUnitId"
                placeholder={t(`${tKey}FAQ.ORGANIZED_UNIT`)}
                controlLabel={t(`${tKey}FAQ.ORGANIZED_UNIT`)}
                component={InputTreeSelect}
                multi={false}
                valueKey="value"
                labelKey="label"
                childrenKey="children"
                colSm={3}
                options={UOTreeCombo}
                onInputChange={(value) => this.handleOrganizedUnitChange(value)}
                className='dashboard-select'
                showSearch={false}
              />
              <Field
                name="filters.fasqSection"
                controlLabel={t(`${tKey}FAQ.FAQ_SECTION`)}
                placeholder={t(`${tKey}FAQ.FAQ_SECTION`)}
                component={InputTreeSelect}
                multi={false}
                valueKey="id"
                labelKey="value"
                options={faqSectionCombo}
                colSm={2}
                allowClear= {true}
                onInputChange={(value) => this.handleSectionChange(value)}
                className='dashboard-select'
              />
              <Field
                name="filters.question"
                colSm={2}
                controlLabel={t(`${tKey}FAQ.FAQ_QUESTION`)}
                placeholder={t(`${tKey}FAQ.FAQ_QUESTION`)}
                component={InputText}
                onInputChange={(value) => this.handleQuestionChange(value)}
              />
              <Col sm={5} className="btn-wrapper">
                <Button
                  className={'btn-white btn-icon btn-input btn-white search-button'}
                  style={{ width: '100%' }}
                  onClick={() => this.searchFaqListEventHandler()}>
                  <i className="ico-search" />
                  {t(`${tKey}SEARCH_BTN`)}
                </Button>
                <Button
                  className={'btn-icon btn-input ' + (hasFilters ? 'btn-standard' : 'btn-cancel')}
                  onClick={() => {
                    this.props.initialize({})
                    this.resetFilters()
                  }}
                >
                  <i className="ico-cancel" />
                  {t(`${tKey}RESET_BUTTON`)}
                </Button>
              </Col>
            </Row>
          </div>
        </Row>
        <Col sm={12} className="col-row  section-users-body">
          <Table className="editable-table" hover responsive>
            <thead>
              <tr>
                <th onClick={() => { this.changeOrder('organizedUnitName') }}>
                  {t(`${tKey}FAQ.ORGANIZED_UNIT`)} <i className={this.getSortIcon('organizedUnitName')} />
                </th>
                <th onClick={() => { this.changeOrder('section') }}>
                  {t(`${tKey}FAQ.FAQ_SECTION`)} <i className={this.getSortIcon('section')} />
                </th>
                <th onClick={() => { this.changeOrder('question') }}>
                  {t(`${tKey}FAQ.FAQ_QUESTION`)} <i className={this.getSortIcon('question')} />
                </th>
                <th onClick={() => { this.changeOrder('answer') }}>
                  {t(`${tKey}FAQ.FAQ_ANSWER`)} <i className={this.getSortIcon('answer')} />
                </th>
                <th style={{ width: '77px' }}>
                  {t('DOSSIER_COMPONENTS.ACCESSORIES.ACTIONS')}
                </th>
              </tr>
            </thead>
            <tbody>
              {faqList && faqList.map((faqMaster, idx) => (
                <tr key={idx} className={faqMaster.isDeleted ? 'is-deleted' : '' }>
                  <td>{faqMaster.organizedUnitName}</td>
                  <td>{faqMaster.section}</td>
                  <td>{faqMaster.question}</td>
                  <td><div dangerouslySetInnerHTML={{ __html: faqMaster.answer }}/></td>
                  <td>
                    {!faqMaster.isDeleted ? (
                      <div>
                        <a onClick={() => openFaqModal(faqMaster.faqId)}>
                          <OverlayTrigger placement="left" overlay={editTooltip}>
                            <i className="ico-edit-white" />
                          </OverlayTrigger>
                        </a>
                        <a onClick={() => deleteFaq(faqMaster.faqId)}>
                          <OverlayTrigger placement="left" overlay={deleteTooltip}>
                            <i className="ico-trash" />
                          </OverlayTrigger>
                        </a>
                      </div>
                    ) : (
                      <a onClick={() => recoverFaqModal(faqMaster.faqId)}>
                        <OverlayTrigger placement="left" overlay={recoverTooltip}>
                          <Glyphicon glyph="repeat" />
                        </OverlayTrigger>
                      </a>
                    )}
                  </td>
                </tr>
              ))}
              {(faqList === null || faqList === undefined || faqList.length === 0) &&
            <tr>
              <td colSpan={5}>
                <h2>{t(`${tKey}FAQ.RESULTS_NOT_FOUND`)}</h2>
              </td>
            </tr>}
              <tr className="button-table-wrapper">
                <td>
                  <Button className="btn-editable-table btn-standard" onClick={() => openFaqModal()}>
                        + {t('MASTERS.ADD')}
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>
          <div className="section-faqmaster-footer">
            <nav key={1} className="text-center">
              <IDocCarPagination id="btn_pag_faqmaster" page={page} pagesCount={pagesCount} totalRows={faqCount}
                onSelectAction={(value) => fetchFaqList({ ...filter, ...this.state.filter, page: value })}
              />
            </nav>
          </div>
        </Col>
      </div>)
  }
}

export default reduxForm({
  form: 'listFAQsMaster'
})(FaqMaster)
