import React, { PureComponent } from 'react'
import { OverlayTrigger, Overlay, Popover, Tooltip, Button, Glyphicon } from 'react-bootstrap'
import { translateDocumentStatus, documentStatusClass } from '../../constants/dossier/common/documentStatus'

class DocumentPopover extends PureComponent {
  constructor () {
    super()
    this.state = {
      showPopover: false,
      target: this
    }
  }

  handleClick (e) {
    e.stopPropagation()
    this.setState({ target: e.target, showPopover: true })
  }

  render () {
    const {
      t,
      idx,
      half,
      id,
      document = {}
    } = this.props
    const tKey = 'SEARCH.CATEGORIES.'
    const statusTooltipTKey = 'DOSSIER_COMPONENTS.DOCUMENTARY_MANAGEMENT.'
    const statusTooltip = (<Tooltip id="statusTooltip">{document.status && t(`${statusTooltipTKey}${translateDocumentStatus[document.status]}`)}</Tooltip>)
    return (
      <div>
        {Object.keys(document).length > 0 &&
          <Button bsSize="xsmall" className="icon-only" onClick={this.handleClick.bind(this)}><Glyphicon glyph="plus"/></Button>
        }
        <OverlayTrigger placement="right" overlay={statusTooltip}>
          <i id="documentStatus" className={'ico-document-' + documentStatusClass[translateDocumentStatus[document.status]]}/>
        </OverlayTrigger>
        {document.description}
        <Overlay
          show={this.state.showPopover}
          target={this.state.target}
          onHide={() => this.setState({ showPopover: false })}
          rootClose
          placement={(idx >= half) ? 'top' : 'bottom'}
        >
          <Popover id={id} className="document-count-popover-container">
            {document.creationDate &&
            <div className="popover-item">
              <label>{t(`${tKey}DOCUMENT.CREATION_DATE`)}: </label><br/>
              {document.creationDate}
            </div>
            }
            {document.creationUser &&
            <div className="popover-item">
              <label>{t(`${tKey}DOCUMENT.USER_CREATOR`)}: </label><br/>
              {document.creationUser}
            </div>
            }
            {document.documentTypeUse &&
            <div className="popover-item">
              <label>{t(`${tKey}DOCUMENT.TYPE`)}: </label><br/>
              {document.documentTypeUse}
            </div>
            }
          </Popover>
        </Overlay>
      </div>
    )
  }
}

export default DocumentPopover
