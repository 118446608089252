import React, { PureComponent } from 'react'
import { Row, Table, Button, Col } from 'react-bootstrap'

class TabPane4 extends PureComponent {
  render () {
    return (
      <Row className="collection-tabpane">
        <Col sm={12}>
          <div className="button-wrapper">
            <Button className="btn-standard-white">
              <i className="ico-plus" /> Nueva UaC
            </Button>
            <Button className="btn-standard-white">
              <i className="ico-check" /> Aprobar dirección
            </Button>
          </div>
        </Col>
        <Col sm={12}>
          <div className="table-mega-title">
            <p>Información expedientes UaC</p>
          </div>
          <Table responsive className="table-tabPane table-tabPane6">
            <thead>
              <tr>
                <th>Exp. UaC</th>
                <th>Vehículo/km</th>
                <th>Doc<br/>UaC</th>
                <th>Importe<br/>tasación</th>
                <th colSpan={2} className="double-title">Matriculación
                  <div className="sub-title-head">
                    <div>Matricula</div>
                    <div>Fecha mat.</div>
                  </div>
                </th>
                <th>Fecha cad. tasación</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><span className="point green"></span>Exp.Uac</td>
                <td>Vehículo Km</td>
                <td><span className="point yellow"></span></td>
                <td>0,0</td>
                <td>Matricula</td>
                <td>Fecha mat.</td>
                <td>Fecha cad.</td>
                <td>
                  <i className="ico-edit-white"></i>
                  <i className="ico-trash"></i>
                </td>
              </tr>
              <tr>
                <td><span className="point green"></span>Exp.Uac</td>
                <td>Vehículo Km</td>
                <td><span className="point yellow"></span></td>
                <td>0,0</td>
                <td>Matricula</td>
                <td>Fecha mat.</td>
                <td>Fecha cad.</td>
                <td>
                  <i className="ico-edit-white"></i>
                  <i className="ico-trash"></i>
                </td>
              </tr>
              <tr>
                <td><span className="point green"></span>Exp.Uac</td>
                <td>Vehículo Km</td>
                <td><span className="point yellow"></span></td>
                <td>0,0</td>
                <td>Matricula</td>
                <td>Fecha mat.</td>
                <td>Fecha cad.</td>
                <td>
                  <i className="ico-edit-white"></i>
                  <i className="ico-trash"></i>
                </td>
              </tr>
              <tr>
                <td><span className="point green"></span>Exp.Uac</td>
                <td>Vehículo Km</td>
                <td><span className="point yellow"></span></td>
                <td>0,0</td>
                <td>Matricula</td>
                <td>Fecha mat.</td>
                <td>Fecha cad.</td>
                <td>
                  <i className="ico-edit-white"></i>
                  <i className="ico-trash"></i>
                </td>
              </tr>
              <tr>
                <td><span className="point green"></span>Exp.Uac</td>
                <td>Vehículo Km</td>
                <td><span className="point yellow"></span></td>
                <td>0,0</td>
                <td>Matricula</td>
                <td>Fecha mat.</td>
                <td>Fecha cad.</td>
                <td>
                  <i className="ico-edit-white"></i>
                  <i className="ico-trash"></i>
                </td>
              </tr>
              <tr>
                <td colSpan={3}><span>Total pendiente</span></td>
                <td><span>0,0</span></td>
                <td colSpan={4}></td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    )
  }
}

export default TabPane4
