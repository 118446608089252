export default {
  FETCH_AUDIT_INFO: 'FETCH_AUDIT_INFO',
  FETCH_AUDIT_INFO_SUCCESS: 'FETCH_AUDIT_INFO_SUCCESS',
  REFRESH_AUDIT_INFO: 'REFRESH_AUDIT_INFO',
  FETCH_AUDIT_DETAILS: 'FETCH_AUDIT_DETAILS',
  FETCH_AUDIT_DETAILS_MODAL: 'FETCH_AUDIT_DETAILS_MODAL',
  FETCH_AUDIT_FLEET_INFO_SUCCESS: 'FETCH_AUDIT_FLEET_INFO_SUCCESS',
  REFRESH_AUDIT_FLEET_INFO: 'REFRESH_AUDIT_FLEET_INFO',
  AUDIT_FLEET_INFO_DOWNLOAD: 'AUDIT_FLEET_INFO_DOWNLOAD',
  FETCH_GROUPED_DOCUMENTS_AUDIT_DETAILS: 'FETCH_GROUPED_DOCUMENTS_AUDIT_DETAILS',
  FETCH_GROUPED_DOCUMENTS_AUDIT_DETAILS_MODAL: 'FETCH_GROUPED_DOCUMENTS_AUDIT_DETAILS_MODAL'
}
