import React, { PureComponent } from 'react'
import { reduxForm, Field } from 'redux-form'
import { Row, Col, Table, Button, Tooltip, OverlayTrigger, Glyphicon } from 'react-bootstrap'
import RecurrentEntityModalPage from '../../../containers/admin/recurrentEntity/RecurrentEntityModalPage'
import IDocCarPagination from '../../commons/iDocCarPagination'
import InputText from '../../commons/form/InputText'
import InputTreeSelect from '../../commons/form/InputTreeSelect'
import InputSelect from '../../commons/form/InputSelect'
import ModalMassUploadPage from '../../../containers/masters/ModalMassUpload/ModalMassUploadPage'
import { loadTemplatesNames, entityTypesId } from '../../../constants/backendIds'
import { EntityType } from '../../../constants/dossier/common/entityTypes'
import UserRecurrrentEntityModalPage from '../../../containers/admin/recurrentEntity/UserRecurrrentEntityModalPage'
class RecurrentEntityAdmin extends PureComponent {
  constructor () {
    super()
    this.state = {
      recurrentEntityTypeCombo: null,
      filter: {
        searchName: null,
        searchDniCif: null,
        searchClientNumber: null,
        searchUO: [],
        searchEntityTypeId: null,
        searchEntitySubTypeId: null,
        includeOU: true
      },
      showModal: false,
      searchClicked: false
    }
  }

  componentDidMount () {
    // this.searchRecurrentEntity()
    this.props.actions.fetchOrganizedUnitsAcceptClientsCombo()
    this.props.actions.fetchEntitySubTypesList()
    this.setState({
      recurrentEntityTypeCombo: [
        { id: EntityType.RECURRING, value: this.props.t('ADMIN.RECURRENT_ENTITY_TYPE') },
        { id: EntityType.RECURRING_SERVICE, value: this.props.t('ADMIN.RECURRENT_SERVICE_ENTITY_TYPE') }]
    })
  }

  componentDidUpdate () {
    if (this.props.ouIdFromMaster != null) {
      if (!this.state.filter.searchUO.some(x => x.toString() === this.props.ouIdFromMaster.toString())) {
        this.setState({ filter: { ...this.state.filter, searchUO: [this.props.ouIdFromMaster.toString()] } })
      }
      this.props.actions.setChangeTabFromMaster(null, false)
    }
  }

  getSortIcon (column = '') {
    switch (this.props.filter.orderBy) {
      case '+' + column:
        return 'ico-sort-selected-asc'
      case '-' + column:
        return 'ico-sort-selected-desc'
      default:
        return 'ico-sort'
    }
  }

  changeOrder (field) {
    switch (this.props.filter.orderBy) {
      case `+${field}`:
        this.props.actions.fetchRecurrentEntityList({ ...this.state.filter, ...this.props.filter, orderBy: `-${field}` })
        break
      default:
        this.props.actions.fetchRecurrentEntityList({ ...this.state.filter, ...this.props.filter, orderBy: `+${field}` })
        break
    }
  }

  handleNameChange (searchName) {
    if (this.state.filter.searchName !== searchName) {
      this.setState({ filter: { ...this.state.filter, searchName } })
    }
  }

  handleDniCifChange (searchDniCif) {
    if (this.state.filter.searchDniCif !== searchDniCif) {
      this.setState({ filter: { ...this.state.filter, searchDniCif } })
    }
  }

  handleClientNumberChange (searchClientNumber) {
    if (this.state.filter.searchClientNumber !== searchClientNumber) {
      this.setState({ filter: { ...this.state.filter, searchClientNumber } })
    }
  }

  handleOrganizedUnitChange (searchUO) {
    if (!searchUO || searchUO.length === 0) {
      this.setState({ filter: { ...this.state.filter, searchUO: [] } })
      return
    }
    const searchUoList = searchUO.split(',').map((item) => {
      return parseInt(item)
    })
    if (this.state.filter.searchUO !== searchUoList) {
      this.setState({ filter: { ...this.state.filter, searchUO: searchUoList } })
    }
  }

  handleEntityTypeChange (searchEntityTypeId) {
    if (this.state.filter.searchEntityTypeId !== searchEntityTypeId) {
      this.props.change('filters.entitySubTypeId', null)
      setTimeout(() => { this.setState({ filter: { ...this.state.filter, searchEntityTypeId: searchEntityTypeId, searchEntitySubTypeId: null } }) }, 100)
    }
  }

  handleEntitySubTypeChange (searchEntitySubTypeId) {
    if (this.state.filter.searchEntitySubTypeId !== searchEntitySubTypeId) {
      this.setState({ filter: { ...this.state.filter, searchEntitySubTypeId: searchEntitySubTypeId } })
    }
  }

  searchRecurrentEntity () {
    this.props.actions.fetchRecurrentEntityList({ ...this.props.filter, ...this.state.filter, page: 1 })
    this.setState({ searchClicked: true })
  }

  closeRecEntModalLoad () {
    this.setState({ showModal: false })
  }

  openRecEntModalLoad () {
    this.setState({ showModal: true })
    this.props.actions.openModalMass('ADMIN.RECURRENT_ENTITIES.MODAL_MASS_UPLOAD.', this.props.actions.uploadRecEntMassDocument, this.searchRecurrentEntity.bind(this))
  }

  openModal (entityId) {
    const entity = this.props.entityList.filter(x => x.entityId === entityId)[0]
    if (entity.countryCode) {
      if (entity.stateCode) {
        this.props.actions.fetchEntityStateTypeCombo(this.props.combos.entityCountryTypeCombo?.find(x => x.code === entity.countryCode)?.id)
        if (entity.cityCode) {
          this.props.actions.fetchEntityCityTypeCombo(this.props.combos.entityStateTypeCombo?.find(x => x.code === entity.stateCode)?.id)
          if (entity.townCode) {
            this.props.actions.fetchEntityTownTypeCombo(this.props.combos.entityCityTypeCombo?.find(x => x.code === entity.cityCode)?.id)
            if (entity.colonyCode) {
              this.props.actions.fetchEntityColonyTypeCombo(this.props.combos.entityTownTypeCombo?.find(x => x.code === entity.townCode)?.id)
              this.props.actions.fetchEntityCPTypeCombo(this.props.combos.entityColonyTypeCombo?.find(x => x.code === entity.colonyCode)?.id)
            }
          }
        }
      } else {
        this.props.actions.fetchEntityCPTypeCombo(-1)
        this.props.actions.fetchEntityStateTypeCombo(-1)
        this.props.actions.fetchEntityCityTypeCombo(-1)
        this.props.actions.fetchEntityTownTypeCombo(-1)
        this.props.actions.fetchEntityColonyTypeCombo(-1)
      }
    }
    this.props.actions.openEditRecurrentEntityModal(entityId)
  }

  editUserServiceEntity (entityId, filters) {
    this.props.actions.openCloseUserRecurrentServiceModal(true, entityId)
  }

  render () {
    const {
      t, entityList, pagesCount, filter, entityCount, recurrentEntitySubTypesList,
      filter: { page },
      combos: { UOTreeCombo },
      actions: { openAddRecurrentEntityModal, fetchRecurrentEntityList, deleteRecurrentEntity, fetchTemplateFile }
    } = this.props

    const tKey = 'ADMIN.'
    const editTooltip = (<Tooltip id="editTooltip">{t(`${tKey}EDIT`)}</Tooltip>)
    const deleteTooltip = (<Tooltip id="deleteTooltip">{t(`${tKey}DELETE`)}</Tooltip>)
    const userTooltip = (<Tooltip id="userTooltip">{t(`${tKey}USER_RECURRENT_SERVICE`)}</Tooltip>)

    return (
      <div className="admin-wrapper">
        <RecurrentEntityModalPage/>
        <UserRecurrrentEntityModalPage/>
        <ModalMassUploadPage
          showModal={this.state.showModal}
          closeModal={this.closeRecEntModalLoad.bind(this)}
          acceptZip={true}
        />
        <Row className="section-recurrentEntity-header">
          <Col sm={12}>
            <div className="header-panel">
              <h4>{t(`${tKey}RECURRENT_ENTITIES_TITLE`)}</h4>
            </div>
          </Col>
        </Row>
        <Row>
          <div className="search-form recurrentEntity">
            <span className="title-search"><i className="ico-search" /> {t(`${tKey}RECURRENT_ENTITIES.SEARCH_TITLE`)}</span>
            <Row>
              <Field
                name="filters.organizedUnit"
                controlLabel={t(`${tKey}RECURRENT_ENTITIES.UO`)}
                placeholder={t(`${tKey}RECURRENT_ENTITIES.UO`)}
                component={InputTreeSelect}
                multi={true}
                valueKey="value"
                labelKey="label"
                childrenKey="children"
                options={UOTreeCombo}
                colSm={4}
                onInputChange={(value) => this.handleOrganizedUnitChange(value)}
              />
              <Field
                name="filters.entityTypeId"
                controlLabel={t(`${tKey}RECURRENT_ENTITIES.ENTITY_TYPE`)}
                placeholder={t(`${tKey}RECURRENT_ENTITIES.ENTITY_TYPE`)}
                component={InputSelect}
                multi={false}
                valueKey="id"
                labelKey="value"
                childrenKey="children"
                options={this.state.recurrentEntityTypeCombo}
                colSm={4}
                onInputChange={(value) => this.handleEntityTypeChange(value)}
              />
              <Field
                name="filters.entitySubTypeId"
                controlLabel={t(`${tKey}RECURRENT_ENTITIES.ENTITY_SUB_TYPE`)}
                placeholder={t(`${tKey}RECURRENT_ENTITIES.ENTITY_SUB_TYPE`)}
                component={InputSelect}
                multi={false}
                valueKey="id"
                labelKey="value"
                childrenKey="children"
                options={recurrentEntitySubTypesList?.filter(x => x.mode === this.state.filter.searchEntityTypeId)}
                colSm={4}
                onInputChange={(value) => this.handleEntitySubTypeChange(value)}
                disabled={!this.state.filter.searchEntityTypeId}
              />
              <Field
                id="name"
                name="filters.name"
                colSm={4}
                controlLabel={t(`${tKey}RECURRENT_ENTITIES.NAME`)}
                placeholder={t(`${tKey}RECURRENT_ENTITIES.NAME`)}
                component={InputText}
                onInputChange={(value) => this.handleNameChange(value)}
              />
              <Field
                id="dniCif"
                name="filters.dniCif"
                colSm={4}
                controlLabel={t(`${tKey}RECURRENT_ENTITIES.DNICIF`)}
                placeholder={t(`${tKey}RECURRENT_ENTITIES.DNICIF`)}
                component={InputText}
                onInputChange={(value) => this.handleDniCifChange(value)}
              />
              <Col sm={4} className="btn-wrapper">
                <Button
                  className={'btn-white btn-icon btn-input btn-white search-button'}
                  style={{ width: '100%' }}
                  onClick={() => this.searchRecurrentEntity()}>
                  <i className="ico-search" />
                  {t(`${tKey}SEARCH_BTN`)}
                </Button>
              </Col>
            </Row>
          </div>
        </Row>
        <Col sm={12}>
          <Button
            className="btn-download btn-editable-table btn-standard"
            onClick={() => fetchTemplateFile(loadTemplatesNames.recEntity)}
          >
            {t('MASTERS.DOWNLOAD')}
          </Button>
        </Col>
        <Col sm={12} className="col-row  section-recurrentEntity-body">
          <Table className="editable-table" hover responsive>
            <thead>
              <tr>
                <th style={{ display: 'none' }} onClick={() => { this.changeOrder('code') }}>
                  {t(`${tKey}RECURRENT_ENTITIES.CODE`)}&nbsp;
                  <i className={this.getSortIcon('code')} />
                </th>
                <th onClick={() => { this.changeOrder('name') }}>
                  {t(`${tKey}RECURRENT_ENTITIES.NAME`)}&nbsp;
                  <i className={this.getSortIcon('name')} />
                </th>
                <th onClick={() => { this.changeOrder('dnicif') }}>
                  {t(`${tKey}RECURRENT_ENTITIES.DNICIF`)}&nbsp;
                  <i className={this.getSortIcon('dnicif')} />
                </th>
                <th onClick={() => { this.changeOrder('ou') }}>
                  {t(`${tKey}RECURRENT_ENTITIES.UO`)}&nbsp;
                  <i className={this.getSortIcon('ou')} />
                </th>
                <th>{t(`${tKey}RECURRENT_ENTITIES.TYPE`)}</th>
                <th>{t(`${tKey}RECURRENT_ENTITIES.SUB_TYPE`)}</th>
                <th style={{ width: '177px' }}>{t('DOSSIER_COMPONENTS.ACCESSORIES.ACTIONS')}
                </th>
              </tr>
            </thead>
            <tbody>
              {entityList && entityList.map((recurrentEntity, idx) => {
                return (
                  <tr key={idx} className=''>
                    <td style={{ display: 'none' }}>{recurrentEntity.entityId}</td>
                    <td>{recurrentEntity.name}</td>
                    <td>{recurrentEntity.dniCif}</td>
                    <td>{recurrentEntity.organizedUnitName}</td>
                    <td>{
                      recurrentEntity.entityTypeId && this.state.recurrentEntityTypeCombo &&
                    this.state.recurrentEntityTypeCombo.find((value, idx) => { return value.id === recurrentEntity.entityTypeId }) &&
                    (this.state.recurrentEntityTypeCombo.find((value, idx) => { return value.id === recurrentEntity.entityTypeId }).value)
                    }</td>
                    <td>{
                      recurrentEntity.entitySubTypeId && recurrentEntitySubTypesList &&
                    recurrentEntitySubTypesList.find((value, idx) => { return value.id === recurrentEntity.entitySubTypeId }) &&
                    (recurrentEntitySubTypesList.find((value, idx) => { return value.id === recurrentEntity.entitySubTypeId }).value)
                    }</td>
                    <td>
                      <div>
                        {(recurrentEntity.entityTypeId === entityTypesId.recurrentService) && (
                          <a onClick={() => this.editUserServiceEntity(recurrentEntity.entityId, { ...filter, ...this.state.filter })}>
                            <OverlayTrigger placement="left" overlay={userTooltip}>
                              <i className="ico-user"/>
                            </OverlayTrigger>
                          </a>
                        )}
                        <a onClick={() => this.openModal(recurrentEntity.entityId)}>
                          <OverlayTrigger placement="right" overlay={editTooltip}>
                            <i className="ico-edit-white"/>
                          </OverlayTrigger>
                        </a>
                        {(recurrentEntity.canDelete) && (
                          <a onClick={() => deleteRecurrentEntity(recurrentEntity.entityId, { ...filter, ...this.state.filter })}>
                            <OverlayTrigger placement="right" overlay={deleteTooltip}>
                              <i className="ico-trash"/>
                            </OverlayTrigger>
                          </a>
                        )}
                      </div>
                    </td>
                  </tr>)
              })
              }
              {(this.state.searchClicked === true && (entityList === null || entityList === undefined || entityList.length === 0)) &&
            <tr>
              <td colSpan={12}><h2>{t(`${tKey}RECURRENT_ENTITIES.RESULTS_NOT_FOUND`)}</h2></td>
            </tr>}
              <tr className="button-table-wrapper">
                <td>
                  <Button
                    className="btn-editable-table btn-standard"
                    onClick={() => openAddRecurrentEntityModal()}>
                  + {t('MASTERS.ADD')}
                  </Button>
                  <Button
                    className="btn-editable-table btn-standard"
                    onClick={this.openRecEntModalLoad.bind(this)}
                  >
                    <Glyphicon glyph="open-file" />{' ' + t('MASTERS.ADD')}
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>
          <div className="section-recurrentEntities-footer">
            <nav key={1} className="text-center">
              <IDocCarPagination
                id="btn_pag_sales"
                page={page}
                pagesCount={pagesCount}
                totalRows={entityCount}
                onSelectAction={(value) => fetchRecurrentEntityList({ ...filter, ...this.state.filter, page: value })}
              />
            </nav>
          </div>
        </Col>
      </div>
    )
  }
}

export default reduxForm({
  form: 'listRecurrentEntity'
})(RecurrentEntityAdmin)
