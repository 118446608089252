import React, { PureComponent } from 'react'
import { Table, Row } from 'react-bootstrap'

class Tab3 extends PureComponent {
  render () {
    return (
      <Row className="tabPane-container">
        <Table responsive className="table-tabPane">
          <thead>
            <tr>
              <th>Concepto</th>
              <th>Gastos</th>
              <th>Ingresos</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Vendedor</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Colaborador/a</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Reacon/DEMO</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Dto en factura</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Fact Carrocero</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Sobreval V.O.</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Diferencia PVP</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Abono Esp. Fab</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Campaña</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td><span>Base Imp</span></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </Table>
      </Row>
    )
  }
}

export default Tab3
