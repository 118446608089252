import mastersActionTypes from '../../../constants/actions/masters/masters'

export function fetchProcessingManagersByNameOrUo (name, uo) {
  return {
    type: mastersActionTypes.FETCH_PROCESSING_MANAGERS_BY_NAME_OR_UO,
    name,
    uo
  }
}

export function fetchProcessingManagersByNameOrUoSuccess (processingManagers) {
  return {
    type: mastersActionTypes.FETCH_PROCESSING_MANAGERS_BY_NAME_OR_UO_SUCCESS,
    processingManagers
  }
}

export function createProcessingManager (processingManager) {
  return {
    type: mastersActionTypes.CREATE_PROCESSING_MANAGER,
    processingManager
  }
}

export function createProcessingManagerSuccess (processingManager) {
  return {
    type: mastersActionTypes.CREATE_PROCESSING_MANAGER_SUCCESS,
    processingManager
  }
}

export function updateProcessingManager (processingManager) {
  return {
    type: mastersActionTypes.UPDATE_PROCESSING_MANAGER,
    processingManager
  }
}

export function updateProcessingManagerSuccess (processingManager) {
  return {
    type: mastersActionTypes.UPDATE_PROCESSING_MANAGER_SUCCESS,
    processingManager
  }
}

export function deleteProcessingManager (processingManagerId) {
  return {
    type: mastersActionTypes.DELETE_PROCESSING_MANAGER,
    processingManagerId
  }
}

export function deleteProcessingManagerSuccess (result) {
  return {
    type: mastersActionTypes.DELETE_PROCESSING_MANAGER_SUCCESS,
    result
  }
}

export function recoverProcessingManagerModal (processingManagerId) {
  return {
    type: mastersActionTypes.RECOVER_PROCESSING_MANAGER,
    processingManagerId
  }
}

export function recoverProcessingManagerModalSuccess (result) {
  return {
    type: mastersActionTypes.RECOVER_PROCESSING_MANAGER_SUCCESS,
    result
  }
}

export function showProcessingManagerModal (processingManager) {
  return {
    type: mastersActionTypes.OPEN_PROCESSING_AGENCY_MANAGER_MODAL,
    processingManager
  }
}

export function closeProcessingManagerModal () {
  return {
    type: mastersActionTypes.CLOSE_PROCESSING_AGENCY_MANAGER_MODAL
  }
}

export function fetchProcessingsAgencyManager () {
  return {
    type: mastersActionTypes.FETCH_PROCESSINGS_AGENCY_MANAGER
  }
}

export function fetchProcessingsAgencyManagerSuccess (processingAgencys) {
  return {
    type: mastersActionTypes.FETCH_PROCESSINGS_AGENCY_MANAGER_SUCCESS,
    processingAgencys
  }
}

export function setProcessingAgencyManagerFilter (filter) {
  return {
    type: mastersActionTypes.SET_PROCESSING_ANGENCY_MANAGER_FILTER,
    filter
  }
}

export function setProcessingAgencyManagerModalFilter (filter) {
  return {
    type: mastersActionTypes.SET_PROCESSING_ANGENCY_MANAGER_MODAL_FILTER,
    filter
  }
}
