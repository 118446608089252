import React, { PureComponent } from 'react'
import { Panel, Col, Table, Button } from 'react-bootstrap'
import { Field } from 'redux-form'
import ChargeRow from '../../../../_v2/components/dossiers/sales/charges/ChargeRow'
import SmallLoaderPage from '../../../../containers/commons/SmallLoaderPage'
import { documentTypeUse as documentTypeUseConstants, permissions } from '../../../../constants/backendIds'
import { documentEntityType as documentEntityTypeConstants } from '../../../../constants/dossier/common/documentEntityType'
import { formatMoney, compareObjects } from '../../../../util/utils'
import InputMoney from '../../../../_v2/components/commons/form/InputMoney'
import CustomFields from '../../../../components/commons/customFields/CustomFields'
import { getFieldsPermisions } from '../../../../util/permisionFunctions'
import setting from '../../../../setting'
import ComponentTitle from '../../../../_v2/containers/dossiers/commons/ComponentTitlePage'

class Charges extends PureComponent {
  constructor () {
    super()
    this.state = {
      open: false,
      fieldsConfiguration: {
        chargebackAmount: null,
        chargeFinancingAmount: null,
        saleAddCharge: null,
        showRefundButton: null,
        uacTotalAmount: null,
        saleStartGateway: null
      },
      showRefundColumn: false,
      loaded: false
    }
  }

  UNSAFE_componentWillMount () {
    this.initializePermisions(this.props)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.open) {
      this.setState({ open: true })
    }

    if ((nextProps.open && this.props.open !== nextProps.open) || (nextProps.open === true && this.props.dossier.dossierEconomicComponent.operationTotalAmount === undefined && !this.state.loaded)) {
      this.getEconomicInfo()
      this.setState({ loaded: true })
    }

    if (nextProps.dossier.dossierId && !compareObjects(this.props.componentConfiguration || {}, nextProps.componentConfiguration, true)) {
      this.initializePermisions(nextProps)
    }
    this.setRefund(nextProps.charges)
  }

  initializePermisions (props) {
    const fieldKeys = Object.keys(this.state.fieldsConfiguration)
    const newState = getFieldsPermisions(props.componentConfiguration.fieldsConfiguration, fieldKeys, props.componentConfiguration.permission)
    this.setState({ fieldsConfiguration: newState.fieldsConfiguration })
  }

  getEconomicInfo () {
    if (!this.props.isEconomicLoaded || this.props.dossier.dossierEconomicComponent.operationTotalAmount === undefined) {
      this.props.handleToUpdateComponent(this.props)
      this.props.actions.fetchSalesEconomic(this.props.dossier.dossierId, this.props.historical, this.props.pristine)
    }
  }

  toggleComponent () {
    if (this.state.open) {
      this.props.actions.setActiveSection(false)
    }
    this.setState({ open: !this.state.open })
    this.getEconomicInfo()
  }

  setRefund (charges) {
    let showColumn = false
    if (charges && charges.length > 0) {
      charges.map((charge, idx) => {
        if (charge.showRefundButton === true) {
          showColumn = true
        }
      })
    }
    if (this.state.showRefundColumn !== showColumn) {
      this.setState({ showRefundColumn: showColumn })
    }
  }

  render () {
    const {
      t, readOnlyFields, dossierType, charges = [], customFields, actions: { openDocumentEditionModal, printCheckList, postPaymentSignalStatusSuccess },
      dossier: { dossierId, organizedUnitId, dossierEconomicComponent }, isPreCancelled
    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.CHARGES.'
    const totalCompleted = charges.reduce((prevVal, newVal) => {
      return prevVal + (newVal.completed ? (newVal.amount || 0) : 0)
    }, 0)
    const isFleet = this.props.fleetId !== undefined && this.props.fleetId !== null
    return (
      <div className="dossier-Component charges">
        <div className="header-panel" onClick={ () => this.toggleComponent()}>
          <ComponentTitle
            componentCode="CHARGES"
            componentOpen={this.state.open}
          />
        </div>
        <Panel expanded={this.state.open} onToggle={() => {}}>
          <Panel.Collapse>
            <div className="fieldsContainer">
              {!readOnlyFields && this.state.fieldsConfiguration.saleStartGateway.permission !== permissions.hidden && !isFleet &&
              <>
                <div className='flexInLine al-R'>
                  <Button
                    href={setting.paymentGatewayUrl}
                    target="_blank"
                    disabled={readOnlyFields || this.state.fieldsConfiguration.saleStartGateway.permission !== permissions.editable || isFleet}
                    className="_Btn accept stk"
                    id="start-gatteway-button"
                  >{t(`${tKey}PAYMENT_GATEWAY`)}
                  </Button>
                </div>
                <div className='separate'></div>
              </>
              }
            </div>
            <div className="fieldsContainer al-R">
              {isPreCancelled && this.state.fieldsConfiguration.chargebackAmount.permission !== permissions.hidden && (
                <Field
                  component={InputMoney}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="dossierEconomicComponent.chargebackAmount"
                  name="dossierEconomicComponent.chargebackAmount"
                  controlLabel={t(`${tKey}CHARGEBACK_AMOUNT`)}
                  placeholder={t(`${tKey}CHARGEBACK_AMOUNT`)}
                  disabled={readOnlyFields || this.state.fieldsConfiguration.chargebackAmount.permission !== permissions.editable || isFleet}
                />
              )}
            </div>
            <div className="fieldsContainer">
              <div className='wrapper _nf'>
                <Table className="editable-table" hover responsive>
                  <thead>
                    <tr>
                      <th>{t(`${tKey}RECEIPT_NUMBER`)}</th>
                      <th>{t(`${tKey}DESCRIPTION`)}</th>
                      <th className='text-end'>{t(`${tKey}AMOUNT`)}</th>
                      <th className='actions'>{t(`${tKey}VALIDATED`)}</th>
                      <th className='actions'>{t(`${tKey}DOCUMENT`)}</th>
                      {this.state.showRefundColumn &&
                          <th className='actions'>{t(`${tKey}REFUND`)}</th>
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {
                      charges.map((charge, idx) => {
                        return (
                          <ChargeRow
                            t={t}
                            tKey={tKey}
                            charge={charge}
                            key={idx}
                            dossierId={dossierId}
                            organizedUnitId={organizedUnitId}
                            actions={{
                              openDocumentEditionModal, // ,
                              // updateDependantsSaleDossierAndHeader
                              printCheckList,
                              postPaymentSignalStatusSuccess
                            }}
                            dossierType={dossierType}
                            readOnlyFields={readOnlyFields || (charge.fleetChargeId !== null && charge.fleetChargeId !== undefined)}
                          />
                        )
                      })
                    }
                  </tbody>
                </Table>
              </div>
              <div className='resultContainer'>
                <div className='resultContainerLeft'>
                  {!readOnlyFields && this.state.fieldsConfiguration.saleAddCharge.permission !== permissions.hidden && !isFleet &&
                    <Button
                      onClick={() => openDocumentEditionModal(
                        null,
                        documentTypeUseConstants.charge,
                        documentEntityTypeConstants.DOSSIER,
                        dossierId,
                        null,
                        null,
                        organizedUnitId,
                        null,
                        // () => updateDependantsSaleDossierAndHeader(dossierId)
                        null,
                        true,
                        dossierType
                      )}
                      disabled={readOnlyFields || this.state.fieldsConfiguration.saleAddCharge.permission !== permissions.editable || isFleet}
                      className="_Btn new"
                      id="add-button">
                      <i className="iDocIcon-add-plus"></i>
                      {t(`${tKey}ADD_BTN`)}
                    </Button>
                  }
                </div>
                <div className='table-totals-charges'>
                  <Col sm={6} className="colums-total-charges">
                    <p style={{ borderRight: 'none' }}><b>{t(`${tKey}TOTALS.TOTAL_CHARGED`)}:</b> <span>{formatMoney(totalCompleted)}</span></p>
                    { this.state.fieldsConfiguration.uacTotalAmount.permission !== permissions.hidden && (
                      <p style={{ borderTop: 'none', borderRight: 'none' }}><b>{t(`${tKey}TOTALS.UAC_TOTAL`)}:</b> <span>{formatMoney(dossierEconomicComponent.uacTotalAmount)}</span></p>
                    )}
                  </Col>
                  <Col sm={6} className="colums-total-charges">
                    <p style={{ borderBottom: 'none' }}><b>{t(`${tKey}TOTALS.OPERATION_TOTAL`)}:</b> <span>{formatMoney(dossierEconomicComponent.operationTotalAmount !== 0 || !dossierEconomicComponent.dossierOperationTotalAmount ? dossierEconomicComponent.operationTotalAmount : dossierEconomicComponent.dossierOperationTotalAmount)}</span></p>
                    {this.state.fieldsConfiguration.chargeFinancingAmount.permission !== permissions.hidden && (
                      <p style={{ borderBottom: 'none' }}><b>{t(`${tKey}TOTALS.FINANCING_CHARGE_AMOUNT`)}:</b> <span>{formatMoney(dossierEconomicComponent.financingAmount)}</span></p>
                    )}
                    <p><b>{t(`${tKey}TOTALS.COLLECT_TOTAL`)}:</b> <span>{formatMoney(dossierEconomicComponent.paymentPending)}</span></p>
                  </Col>
                </div>
              </div>

              <CustomFields customFields={customFields} readOnlyFields={readOnlyFields} />
            </div>
          </Panel.Collapse>
        </Panel>
        <SmallLoaderPage/>
      </div>
    )
  }
}
export default Charges
