import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import ProcessingAgencyIntegrationRegistrationTypesMasterModal from '../../../../components/masters/processing/processingAgencyIntegrations/ProcessingAgencyIntegrationRegistrationTypesMasterModal'
import { showProcessingAgencyRegistrationTypesModal, submitProcessingAgencyRegistrationTypes } from '../../../../actions/masters/processingAgency/processingAgencyIntegrations/processingAgencyRegistrationTypes'

export function mapStateToProps (state) {
  const registrationTypesModal = state.processingAgency?.processingAgencyIntegrationModal?.registrationTypes?.registrationTypesModal
  return {
    agency: state.processingAgency.processingAgencyIntegrationModal.agency,
    showModal: registrationTypesModal?.showModal,
    processingAgencyRegistrationType: registrationTypesModal?.processingAgencyRegistrationType,
    languageList: registrationTypesModal?.languageList,
    combos: {
      operationTypeAllCombo: state.combos.operationTypeAllCombo
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      showProcessingAgencyRegistrationTypesModal,
      submitProcessingAgencyRegistrationTypes
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(ProcessingAgencyIntegrationRegistrationTypesMasterModal))
