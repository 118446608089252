export default {
  DELETE_FAQ_SECTION: 'DELETE_FAQ_SECTION',
  RECOVER_FAQ_SECTION: 'RECOVER_FAQ_SECTION',
  CLOSE_FAQ_SECTION_MODAL: 'CLOSE_FAQ_SECTION_MODAL',
  SAVE_FAQ_SECTION: 'SAVE_FAQ_SECTION',
  LOAD_FAQ_SECTION: 'LOAD_FAQ_SECTION',
  LOAD_FAQ_SECTION_TABLE: 'LOAD_FAQ_SECTION_TABLE',
  FETCH_FAQ_SECTION: 'FETCH_FAQ_SECTION',
  FETCH_FAQ_SECTION_TABLE: 'FETCH_FAQ_SECTION_TABLE'
}
