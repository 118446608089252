import React, { PureComponent } from 'react'
import { Row, Table, Checkbox } from 'react-bootstrap'

class TabPane6 extends PureComponent {
  render () {
    return (
      <Row className="collection-tabpane">
        <Table responsive className="table-tabPane table-tabPane6">
          <thead>
            <tr>
              <th>Servicios contratados</th>
              <th>Importe</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <Checkbox inline>
                  Revisión / Preentregas
                </Checkbox>
              </td>
              <td>0,0</td>
            </tr>
            <tr>
              <td>
                <Checkbox inline>
                  Venta de Accesorios y servicios
                </Checkbox>
              </td>
              <td>0,0</td>
            </tr>
            <tr>
              <td>
                <Checkbox inline>
                  IMC (Veh Matriculados)
                </Checkbox>
              </td>
              <td>0,0</td>
            </tr>
            <tr>
              <td>
                <Checkbox inline>
                  IEM (Veh Matriculados)
                </Checkbox>
              </td>
              <td>0,0</td>
            </tr>
            <tr>
              <td>
                <Checkbox inline>
                  Venta de garantía
                </Checkbox>
              </td>
              <td>0,0</td>
            </tr>
            <tr>
              <td>
                <Checkbox inline>
                  Venta de mantenimiento
                </Checkbox>
              </td>
              <td>0,0</td>
            </tr>
            <tr>
              <td>
                <Checkbox inline>
                  Suplido IEM
                </Checkbox>
              </td>
              <td>0,0</td>
            </tr>
            <tr>
              <td>
                <Checkbox inline>
                  Soplido IMC
                </Checkbox>
              </td>
              <td>0,0</td>
            </tr>
            <tr>
              <td>
                <Checkbox inline>
                  Suplido gestoria
                </Checkbox>
              </td>
              <td>0,0</td>
            </tr>
            <tr>
              <td>
                <Checkbox inline>
                  Suplido de garantía
                </Checkbox>
              </td>
              <td>0,0</td>
            </tr>
            <tr>
              <td>
                <Checkbox inline>
                  Suplido de mantenimiento
                </Checkbox>
              </td>
              <td>0,0</td>
            </tr>
            <tr>
              <td>
                <Checkbox inline>
                  Ayuda estatal (suplido)
                </Checkbox>
              </td>
              <td>0,0</td>
            </tr>
            <tr>
              <td>
                <span>Total servicios contratados</span>
              </td>
              <td><span>0,0</span></td>
            </tr>
          </tbody>
        </Table>
      </Row>
    )
  }
}

export default TabPane6
