import apiFetch from '../apiFetch'

export default function (token, { orderBy, dateFrom, dateTo }, columns) {
  const queryParamsArray = [
    orderBy ? `orderBy=${orderBy}` : null,
    columns ? `columns=${columns}` : null,
    dateFrom ? `dateFrom=${dateFrom}` : null,
    dateTo ? `dateTo=${dateTo}` : null
  ]
  const queryParams = queryParamsArray.reduce(
    (result, query, idx) => result + (query ? (result ? `&${query}` : `?${query}`) : '')
    , ''
  )
  return apiFetch({
    endPoint: `CampaignProcessInvoice/GetPurchaseOrderFileDownload${queryParams}`,
    method: 'GET',
    body: null,
    token: token,
    parseBlobAndAssign: true
  })
}
