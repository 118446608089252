import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { closeSignTypeModal, submitSignType } from '../../../actions/masters/signType/signType'
import SignTypeModal from '../../../components/masters/signType/SignTypeModal'

export function mapStateToProps (state) {
  return {
    ...state.signType.signTypeModal
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      closeSignTypeModal,
      submitSignType
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(SignTypeModal))
