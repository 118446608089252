import templatesActionTypes from '../../constants/actions/templates/templates'

export function fetchTemplateList (filter) {
  return {
    type: templatesActionTypes.FETCH_TEMPLATE_LIST,
    filter
  }
}

export function fetchTemplateListSuccess (templateList, filter) {
  return {
    type: templatesActionTypes.FETCH_TEMPLATE_LIST_SUCCESS,
    templateList,
    filter
  }
}

export function setActiveTemplate (templateId) {
  return {
    type: templatesActionTypes.SET_ACTIVE_TEMPLATE,
    templateId
  }
}

export function duplicateTemplate (templateId) {
  return {
    type: templatesActionTypes.DUPLICATE_TEMPLATE,
    templateId
  }
}

export function deleteTemplate (templateId) {
  return {
    type: templatesActionTypes.DELETE_TEMPLATE,
    templateId
  }
}

export function openAddTemplateModal (templateTypeId) {
  return {
    type: templatesActionTypes.OPEN_ADD_TEMPLATE_MODAL,
    templateTypeId
  }
}

export function openEditTemplateModal (templateId) {
  return {
    type: templatesActionTypes.OPEN_EDIT_TEMPLATE_MODAL,
    templateId
  }
}

export function openEditTemplateModalSuccess (templateId, templateData, isNew) {
  return {
    type: templatesActionTypes.OPEN_EDIT_TEMPLATE_MODAL_SUCCESS,
    templateId,
    templateData,
    isNew
  }
}

export function closeEditTemplateModal (body) {
  return {
    type: templatesActionTypes.CLOSE_EDIT_TEMPLATE_MODAL,
    body
  }
}

export function closeEditTemplateModalSuccess () {
  return {
    type: templatesActionTypes.CLOSE_EDIT_TEMPLATE_MODAL_SUCCESS
  }
}

export function submitTemplate (body) {
  return {
    type: templatesActionTypes.SUBMIT_TEMPLATE,
    body
  }
}

export function createTemplateField (templateId, templateFieldData, resolve, reject) {
  return {
    type: templatesActionTypes.CREATE_TEMPLATE_FIELD,
    templateId,
    templateFieldData,
    resolve,
    reject
  }
}

export function updateTemplateField (templateId, templateFieldData, resolve, reject) {
  return {
    type: templatesActionTypes.UPDATE_TEMPLATE_FIELD,
    templateId,
    templateFieldData,
    resolve,
    reject
  }
}

export function deleteTemplateField (templateId, templateFieldData) {
  return {
    type: templatesActionTypes.DELETE_TEMPLATE_FIELD,
    templateId,
    templateFieldData
  }
}

export function resetTemplate () {
  return {
    type: templatesActionTypes.RESET_TEMPLATE
  }
}

export function fetchTemplateFile (templateId, languageId) {
  return {
    type: templatesActionTypes.FETCH_TEMPLATE_FILE,
    templateId,
    languageId
  }
}

export function downloadTemplate (templateId) {
  return {
    type: templatesActionTypes.DONWLOAD_TEMPLATE_FILE,
    templateId
  }
}

export default {
  fetchTemplateList,
  fetchTemplateListSuccess,
  setActiveTemplate,
  duplicateTemplate,
  deleteTemplate,
  openAddTemplateModal,
  openEditTemplateModal,
  openEditTemplateModalSuccess,
  closeEditTemplateModal,
  closeEditTemplateModalSuccess,
  submitTemplate,
  createTemplateField,
  updateTemplateField,
  deleteTemplateField,
  resetTemplate,
  fetchTemplateFile,
  downloadTemplate
}
