import { put, call, select, takeEvery } from 'redux-saga/effects'
import { getAuth } from '../../selectors/access/auth'
import { getSaleFilter } from '../../selectors/reports/saleReport'
import saleActionTypes from '../../constants/actions/reports/saleReport'
import { fetchSaleReportSuccess } from '../../actions/reports/saleReport'
import { showLoader, hideLoader } from '../../actions/common.js'
import postSaleListService from '../../services/reports/postSaleList'
import { handleError } from '../errors/errorManager'
import { fetchCombosSuccess } from '../../actions/combos/combos'
import getSalesmanUserHierarchy from '../../services/salesman/getSalesmanUserHierarchy'
import getLowestOrganizedUnits from '../../services/organizedUnit/getOrganizedUnits'
import getDownloadSaleList from '../../services/reports/getDownloadSaleList'

export function * fetchSaleList ({ filter }) {
  try {
    yield put(showLoader())
    yield filter = yield filter || select(getSaleFilter)
    const auth = yield select(getAuth)
    const saleList = yield call(postSaleListService, auth.token, filter)
    const salesmanCombo = yield call(getSalesmanUserHierarchy, auth.token)
    const lowestOrganizedUnitsCombo = yield call(getLowestOrganizedUnits, auth.token)
    yield put(fetchCombosSuccess({
      salesmanCombo,
      lowestOrganizedUnitsCombo
    }))
    yield put(fetchSaleReportSuccess(saleList, filter))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchSaleList () {
  yield takeEvery(saleActionTypes.FETCH_SALE_REPORT, fetchSaleList)
}

export function * downloadSaleList ({ filter }) {
  try {
    yield put(showLoader())
    yield filter = yield filter || select(getSaleFilter)
    const auth = yield select(getAuth)
    yield call(getDownloadSaleList, auth.token, filter)
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDownloadSaleList () {
  yield takeEvery(saleActionTypes.DOWNLOAD_SALE_REPORT, downloadSaleList)
}
