import apiFetch from '../apiFetch'

export default function (token, dealerNumber, vinChassis, licensePlate, active, organizedUnitId, dossierSubtypeId) {
  const queryParamsArray = [
    dealerNumber ? `dealerNumber=${dealerNumber}` : null,
    vinChassis ? `vinChassis=${vinChassis}` : null,
    licensePlate ? `licensePlate=${licensePlate}` : null,
    typeof (active) === 'boolean' ? `active=${active}` : null,
    organizedUnitId ? `organizedUnitId=${organizedUnitId}` : null,
    dossierSubtypeId ? `dossierSubtypeId=${dossierSubtypeId}` : null
  ]
  const queryParams = queryParamsArray.reduce(
    (result, query, idx) => result + (query ? (result ? `&${query}` : `?${query}`) : '')
    , ''
  )
  return apiFetch({ endPoint: `vehicle${queryParams}`, method: 'GET', body: null, token: token })
}
