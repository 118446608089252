import React, { PureComponent } from 'react'
import { Button, Modal, Col, Row, Form } from 'react-bootstrap'
import { reduxForm, Field } from 'redux-form'
import InputText from '../../../components/commons/form/InputText'

class RejectCommentModal extends PureComponent {
  rejectActionPromise (values) {
    return new Promise((resolve, reject) => {
      return this.props.actions.rejectAction(values, resolve, reject)
    })
  }

  rejectAction (values) {
    this.rejectActionPromise(values).then(() => this.props.actions.closeModal())
  }

  render () {
    const { t, handleSubmit, showModal, actions: { closeModal } } = this.props
    const tKey = 'DOSSIER_COMPONENTS.REJECT_MODAL.'
    return (
      <Modal className="add-user-modal" show={showModal} onHide={closeModal}>
        <Form autoComplete="off" onSubmit={handleSubmit(this.rejectAction.bind(this))}>
          <Modal.Header closeButton>
            <Modal.Title>{t(`${tKey}TITLE`)}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Field
                id="text"
                name="text"
                componentClass="textarea"
                colSm={12}
                placeholder={t(`${tKey}PLACEHOLDER`)}
                component={InputText}
              />
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Col sm={6} className="btn-wrapper">
              <Button id="btn_save" className="btn-standard" type="submit">{t(`${tKey}REJECT_BUTTON`)}</Button>
            </Col>
          </Modal.Footer>
        </Form>
      </Modal>
    )
  }
}
const validate = (values, { t }) => {
  const tKey = 'DOSSIER_COMPONENTS.REJECT_MODAL.'
  const errors = {}
  if (!values.text) errors.text = t(`${tKey}COMMENT_MANDATORY`)
  return errors
}

export default reduxForm({
  form: 'reject_comment_modal',
  validate
})(RejectCommentModal)
