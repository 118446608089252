import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import FinancingTypeProductMasters from '../../../components/masters/financingTypeProduct/FinancingTypeProductMaster'
import { translate } from 'react-polyglot'
import { openFinancingTypeProductModal, deleteFinancingTypeProduct, fetchFinancingTypeProductList, recoverFinancingTypeProduct, fetchFinancingTypeProductFields } from '../../../actions/masters/masters'

export function mapStateToProps (state) {
  return {
    ...state.financingTypeProduct
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      openFinancingTypeProductModal,
      deleteFinancingTypeProduct,
      fetchFinancingTypeProductList,
      recoverFinancingTypeProduct,
      fetchFinancingTypeProductFields
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(FinancingTypeProductMasters))
