export const chassisLength = {
  left: 9,
  right: 8,
  total: 17
}
export const countryImplementation = {
  spain: 'ESP',
  vgr: 'DEFAULT_VGR'
}
export const modelLength = {
  maxLength: 4
}
