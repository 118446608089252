import { put, call, select, takeEvery } from 'redux-saga/effects'
import { getAuth } from '../../selectors/access/auth'
import getLowestOrganizedUnits from '../../services/organizedUnit/getOrganizedUnits'
import getSalesmanUserHierarchy from '../../services/salesman/getSalesmanUserHierarchy'
import getEntityTypes from '../../services/entityType/getEntityTypes'
import findDossiers from '../../services/dossierFinder/findDossiers'
import putDossierStatusVehicleRentingList from '../../services/dossierSale/putDossierStatusVehicleRentingList'
import findVehicles from '../../services/dossierFinder/findVehicles'
import findEntities from '../../services/dossierFinder/findEntities'
import findCharges from '../../services/dossierFinder/findCharges'
import findChargeRenting from '../../services/dossierFinder/findChargeRenting'
import findSelfSupply from '../../services/dossierFinder/findSelfSupply'
import { fetchCombosSuccess } from '../../actions/combos/combos'
import { showLoader, hideLoader, openModal } from '../../actions/common.js'
import searchActionTypes from '../../constants/actions/search/search'
import {
  fetchDossierFilterSuccess, fetchVehicleFilterSuccess, fetchEntityFilterSuccess, fetchChargesFilterSuccess, fetchRentingSuccess, fetchSelfSupplySuccess, fetchFleetsSuccess,
  fetchCampaignExportSuccess, fetchRegisterOrderSuccess, fetchFtpRegisterSuccess, fetchServicePrepareSuccess, fetchServicePrepareRoleConfigSuccess, fetchDossierFleetsSuccess, fetchReportCommercialSuccess,
  fetchReportCommercialTableSuccess, fetchPapServicesFromManagerSuccess, fetchPlanLogiticsPapSuccess, fetchProcessingAgenciesSuccess, fetchFleetReportSuccess,
  fetchStockManagementDossiersSuccess
} from '../../actions/search/search'
import { handleError } from '../errors/errorManager'
import findDossiersSimple from '../../services/dossierFinder/findDossiersSimple'
import findVehiclesSimple from '../../services/dossierFinder/findVehiclesSimple'
import findFleets from '../../services/dossierFinder/findFleets'
import messageModalActions from '../../actions/modals/messageModal'
import putPurchaseSelfSupply from '../../services/dossierSale/putPurchaseSelfSupply'
import findDossiersDownload from '../../services/dossierFinder/findDossiersDownload'
import findVehiclesDownload from '../../services/dossierFinder/findVehicleDownload'
import findEntitiesDownload from '../../services/dossierFinder/findEntitiesDownload'
import findDossiersSimpleDownload from '../../services/dossierFinder/findDossierSimpleDownload'
import findVehiclesSimpleDownload from '../../services/dossierFinder/findVehiclesSimpleDownload'
import findChargeRentingDownload from '../../services/dossierFinder/findChargeRentingDownload'
import findSelfSupplyDownload from '../../services/dossierFinder/findSelfSupplyDownload'
import findChargesDownload from '../../services/dossierFinder/findChargesDownload'
import findFleetsDownload from '../../services/dossierFinder/findFleetsDownload'
import findCampaignExport from '../../services/dossierFinder/findCampaignExport'
import launchExportCampaignProcess from '../../services/dossierFinder/launchExportCampaignProcess'
import launchExportCampaignProcessExcel from '../../services/dossierFinder/launchExportCampaignProcessExcel'
import getRegisterOrderProcess from '../../services/purchaseCampaignProcessFiles/getRegisterOrderProcess'
import registerOrderProcessDownload from '../../services/purchaseCampaignProcessFiles/registerOrderProcessDownload'
import getFtpRegister from '../../services/ftpRegister/getFtpRegister'
import getFtpRegisterExport from '../../services/ftpRegister/getFtpRegisterExport'
import findServicePreparation from '../../services/dossierFinder/findServicePreparation'
import findServicePreparationDownload from '../../services/dossierFinder/findServicePreparationDownload'
import findServicePreparationRoleConfig from '../../services/dossierFinder/findServicePreparationRoleConfig'
import findDossierFleets from '../../services/dossierFinder/findDossierFleets'
import findDossierFleetsDownload from '../../services/dossierFinder/findDossierFleetsDownload'
import { getDossierStateById, initializeDossier } from '../dossier/commonDossierSagas'
import { yesNoModal } from '../modalsListeners/yesNoModal'
import findReportComercial from '../../services/dossierFinder/findReportComercial'
import findReportCommercialTable from '../../services/dossierFinder/findReportCommercialTable'
import findReportCommercialTableDownload from '../../services/dossierFinder/findReportCommercialTableDownload'
import exportReportCommercial from '../../services/dossierFinder/exportReportCommercial'
import findPapServicesFromManager from '../../services/dossierFinder/findPapServicesFromManager'
import findPapServicesFromManagerDownload from '../../services/dossierFinder/findPapServicesFromManagerDownload'
import findPlanLogisticsPap from '../../services/dossierFinder/findPlanLogisticsPap'
import findProcessingAgencies from '../../services/dossierFinder/findProcessingAgencies'
import findProcessingAgenciesDownload from '../../services/dossierFinder/findProcessingAgenciesDownload'
import findFleetReport from '../../services/dossierFinder/findFleetReport'
import findFleetReportDownload from '../../services/dossierFinder/findFleetReportDownload'
import findStockManagementDossiers from '../../services/dossierFinder/findStockManagementDossiers.js'
import findStockManagementDossiersDownload from '../../services/dossierFinder/findStockManagementDossiersDownload.js'

export function * initializeSearch () {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const lowestOrganizedUnitsCombo = yield call(getLowestOrganizedUnits, auth.token)
    const entityTypeCombo = yield call(getEntityTypes, auth.token)
    const salesmanCombo = yield call(getSalesmanUserHierarchy, auth.token)
    yield put(fetchCombosSuccess({
      lowestOrganizedUnitsCombo,
      entityTypeCombo,
      salesmanCombo
    }))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchInitializeSearch () {
  yield takeEvery(searchActionTypes.INITIALIZE_SEARCH, initializeSearch)
}

export function * fetchDossiers ({ filters }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const result = yield call(findDossiers, auth.token, filters)
    yield put(fetchDossierFilterSuccess(result, filters))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchDossiers () {
  yield takeEvery(searchActionTypes.FETCH_DOSSIERS, fetchDossiers)
}

export function * fetchDossiersDownload ({ filters, columns }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(findDossiersDownload, auth.token, { ...filters, columns })
    const searchState = yield select(state => state.search)
    if (searchState.maxDossierExport) {
      yield put(hideLoader())
      yield put(openModal('dossierListExportReducedSuccess', null, null, null, null, [{ id: '##AMOUNT##', value: searchState.maxDossierExport }], null))
    } else {
      yield put(messageModalActions.openSuccessMessageModal('MESSAGES.DOSSIER_LIST_EXPORT_SUCCESS'))
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchDossiersDownload () {
  yield takeEvery(searchActionTypes.FETCH_DOSSIERS_DOWNLOAD, fetchDossiersDownload)
}

export function * fetchChargeRenting ({ filters }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const result = yield call(findChargeRenting, auth.token, filters)
    yield put(fetchRentingSuccess(result, filters))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchfetchChargeRenting () {
  yield takeEvery(searchActionTypes.FETCH_RENTING, fetchChargeRenting)
}

export function * fetchChargeRentingDownload ({ filters, columns }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(findChargeRentingDownload, auth.token, { ...filters, columns })
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchfetchChargeRentingDownload () {
  yield takeEvery(searchActionTypes.FETCH_RENTING_DOWNLOAD, fetchChargeRentingDownload)
}

export function * fetchSelfSupply ({ filters }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const result = yield call(findSelfSupply, auth.token, filters)
    yield put(fetchSelfSupplySuccess(result, filters))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchfetchSelfSupply () {
  yield takeEvery(searchActionTypes.FETCH_SELFSUPPLY, fetchSelfSupply)
}

export function * fetchSelfSupplyDownload ({ filters, columns }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(findSelfSupplyDownload, auth.token, { ...filters, columns })
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchfetchSelfSupplyDownload () {
  yield takeEvery(searchActionTypes.FETCH_SELFSUPPLY_DOWNLOAD, fetchSelfSupplyDownload)
}

export function * salePayRentingList (dossierId) {
  yield put(showLoader())
  const auth = yield select(getAuth)
  yield call(putDossierStatusVehicleRentingList, dossierId.filters, auth.token)
  yield put(hideLoader())
  yield put(messageModalActions.openSuccessMessageModal('MESSAGES.PAY_RENTING_SUCCESS'))
}

export function * watchSalePayRentingList () {
  yield takeEvery(searchActionTypes.SALE_PAY_RENTING_LIST, salePayRentingList)
}

export function * saleSelfSupplyList (dossierId) {
  try {
    var confirmedSaveWarning = true
    if (dossierId.filters.pristine !== null && dossierId.filters.pristine !== undefined && dossierId.filters.pristine === false) {
      confirmedSaveWarning = yield call(yesNoModal, 'commonDossierSaveWarning')
      if (confirmedSaveWarning) {
        dossierId.filters.reset('salesDossier')
        dossierId.filters.reset('purchase_dossier')
      }
    }
    if (confirmedSaveWarning) {
      yield put(showLoader())
      const auth = yield select(getAuth)
      yield call(putPurchaseSelfSupply, dossierId.filters, auth.token)
      yield put(hideLoader())
      yield put(messageModalActions.openSuccessMessageModal('MESSAGES.SALE_CREATE_PURCHASE_SELFSUPPLY'))

      if (dossierId.filters.saleParentId) {
        const dossierState = yield call(getDossierStateById, dossierId.filters.saleParentId)
        yield call(initializeDossier, dossierState.type, dossierState.dossier)
      }
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSaleSelfSupplyList () {
  yield takeEvery(searchActionTypes.SALE_SELFSUPPLY_LIST, saleSelfSupplyList)
}

export function * fetchDossiersSimple ({ filters }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const result = yield call(findDossiersSimple, auth.token, filters)
    yield put(fetchDossierFilterSuccess(result, filters))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchDossiersSimple () {
  yield takeEvery(searchActionTypes.FETCH_DOSSIERS_SIMPLE, fetchDossiersSimple)
}

export function * fetchDossiersSimpleDownload ({ filters, columns }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(findDossiersSimpleDownload, auth.token, { ...filters, columns })
    const searchState = yield select(state => state.search)
    if (searchState.maxDossierExport) {
      yield put(hideLoader())
      yield put(openModal('dossierListExportReducedSuccess', null, null, null, null, [{ id: '##AMOUNT##', value: searchState.maxDossierExport }], null))
    } else {
      yield put(messageModalActions.openSuccessMessageModal('MESSAGES.DOSSIER_LIST_EXPORT_SUCCESS'))
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchDossiersSimpleDownload () {
  yield takeEvery(searchActionTypes.FETCH_DOSSIERS_SIMPLE_DOWNLOAD, fetchDossiersSimpleDownload)
}

export function * fetchVehicles ({ filters }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const result = yield call(findVehicles, auth.token, filters)
    yield put(fetchVehicleFilterSuccess(result, filters))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchVehicles () {
  yield takeEvery(searchActionTypes.FETCH_VEHICLES, fetchVehicles)
}

export function * fetchVehiclesDownloads ({ filters, columns }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(findVehiclesDownload, auth.token, { ...filters, columns })
    const searchState = yield select(state => state.search)
    if (searchState.maxDossierExport) {
      yield put(hideLoader())
      yield put(openModal('vehicleListExportReducedSuccess', null, null, null, null, [{ id: '##AMOUNT##', value: searchState.maxDossierExport }], null))
    } else {
      yield put(messageModalActions.openSuccessMessageModal('MESSAGES.VEHICLE_LIST_EXPORT_SUCCESS'))
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchVehiclesDownloads () {
  yield takeEvery(searchActionTypes.FETCH_VEHICLES_DOWNLOAD, fetchVehiclesDownloads)
}

export function * fetchVehiclesSimple ({ filters }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const result = yield call(findVehiclesSimple, auth.token, filters)
    yield put(fetchVehicleFilterSuccess(result, filters))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchVehiclesSimple () {
  yield takeEvery(searchActionTypes.FETCH_VEHICLES_SIMPLE, fetchVehiclesSimple)
}

export function * fetchVehiclesSimpleDownload ({ filters, columns }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(findVehiclesSimpleDownload, auth.token, { ...filters, columns })
    const searchState = yield select(state => state.search)
    if (searchState.maxDossierExport) {
      yield put(hideLoader())
      yield put(openModal('vehicleListExportReducedSuccess', null, null, null, null, [{ id: '##AMOUNT##', value: searchState.maxDossierExport }], null))
    } else {
      yield put(messageModalActions.openSuccessMessageModal('MESSAGES.VEHICLE_LIST_EXPORT_SUCCESS'))
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchVehiclesSimpleDownload () {
  yield takeEvery(searchActionTypes.FETCH_VEHICLES_SIMPLE_DOWNLOAD, fetchVehiclesSimpleDownload)
}

export function * fetchEntities ({ filters }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const result = yield call(findEntities, auth.token, filters)
    yield put(fetchEntityFilterSuccess(result, filters))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchEntities () {
  yield takeEvery(searchActionTypes.FETCH_ENTITIES, fetchEntities)
}

export function * fetchEntitiesDownload ({ filters, columns }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(findEntitiesDownload, auth.token, { ...filters, columns })
    const searchState = yield select(state => state.search)
    if (searchState.maxDossierExport) {
      yield put(hideLoader())
      yield put(openModal('entityListExportReducedSuccess', null, null, null, null, [{ id: '##AMOUNT##', value: searchState.maxDossierExport }], null))
    } else {
      yield put(messageModalActions.openSuccessMessageModal('MESSAGES.ENTITY_LIST_EXPORT_SUCCESS'))
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchEntitiesDownload () {
  yield takeEvery(searchActionTypes.FETCH_ENTITIES_DOWNLOAD, fetchEntitiesDownload)
}

export function * fetchCharges ({ filters }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const result = yield call(findCharges, auth.token, filters)
    yield put(fetchChargesFilterSuccess(result, filters))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchCharges () {
  yield takeEvery(searchActionTypes.FETCH_CHARGES, fetchCharges)
}

export function * fetchChargesDownload ({ filters, columns }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(findChargesDownload, auth.token, { ...filters, columns })
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchChargesDownload () {
  yield takeEvery(searchActionTypes.FETCH_CHARGES_DOWNLOAD, fetchChargesDownload)
}

export function * fetchFleets ({ filters }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const result = yield call(findFleets, auth.token, filters)
    yield put(fetchFleetsSuccess(result, filters))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchfetchFleets () {
  yield takeEvery(searchActionTypes.FETCH_FLEETS, fetchFleets)
}

export function * fetchFleetsDownload ({ filters, columns }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(findFleetsDownload, auth.token, { ...filters, columns })
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchfetchFleetsDownload () {
  yield takeEvery(searchActionTypes.FETCH_FLEETS_DOWNLOAD, fetchFleetsDownload)
}

export function * fetchCampaignExport ({ filters }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const result = yield call(findCampaignExport, auth.token, filters)
    yield put(fetchCampaignExportSuccess(result, filters))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchfetchCampaignExport () {
  yield takeEvery(searchActionTypes.FETCH_CAMPAIGN_EXPORT, fetchCampaignExport)
}

export function * launchExportCampaign ({ filters, checkAll, toExport, notToExport }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const result = yield call(launchExportCampaignProcess, auth.token, filters, { checkAll: checkAll, export: toExport, notExport: notToExport })
    if (result !== null) {
      if (result.value) {
        yield put(messageModalActions.openSuccessMessageModal('MESSAGES.CAMPAIGN_EXPORTED_SUCCESS'))
      } else {
        yield put(messageModalActions.openErrorMessageModal('MESSAGES.CAMPAIGN_EXPORTED_FAILED'))
      }
      let res = { pages: 0, count: 0, items: [] }
      yield put(fetchCampaignExportSuccess(res, filters))
    } else {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.CAMPAIGN_EXPORTED_FAILED'))
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchlaunchExportCampaign () {
  yield takeEvery(searchActionTypes.LAUCH_EXPORT_CAMPAIGN, launchExportCampaign)
}

export function * launchExportCampaignExcel ({ filters, checkAll, toExport, notToExport }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(launchExportCampaignProcessExcel, auth.token, filters, { checkAll: checkAll, toExport: toExport, notExport: notToExport })
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchlaunchExportCampaignExcel () {
  yield takeEvery(searchActionTypes.LAUCH_EXPORT_CAMPAIGN_EXCEL, launchExportCampaignExcel)
}

export function * fetchRegisterOrderProcess ({ filters }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const result = yield call(getRegisterOrderProcess, auth.token, filters)
    yield put(fetchRegisterOrderSuccess(result, filters))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchfetchRegisterOrderProcess () {
  yield takeEvery(searchActionTypes.FETCH_REGISTER_ORDER, fetchRegisterOrderProcess)
}

export function * fetchRegisterOrderDownload ({ filters, columns }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(registerOrderProcessDownload, auth.token, filters, columns)
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchfetchRegisterOrderDownload () {
  yield takeEvery(searchActionTypes.FETCH_REGISTER_ORDER_DOWNLOAD, fetchRegisterOrderDownload)
}

export function * fetchFtpRegister ({ filters }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const result = yield call(getFtpRegister, auth.token, filters)
    yield put(fetchFtpRegisterSuccess(result, filters))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchfetchFtpRegister () {
  yield takeEvery(searchActionTypes.FETCH_FTP_REGISTER, fetchFtpRegister)
}

export function * fetchFtpRegisterExport ({ filters, columns }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(getFtpRegisterExport, auth.token, filters, columns)
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchfetchFtpRegisterExport () {
  yield takeEvery(searchActionTypes.FETCH_FTP_REGISTER_EXPORT, fetchFtpRegisterExport)
}

export function * fetchDossierServicePrepare ({ filters }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const result = yield call(findServicePreparation, auth.token, filters)
    yield put(fetchServicePrepareSuccess(result, filters))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchfetchServicePrepare () {
  yield takeEvery(searchActionTypes.FETCH_SERVICE_PREPARE, fetchDossierServicePrepare)
}

export function * fetchDossierServicePrepareDownload ({ filters, columns }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(findServicePreparationDownload, auth.token, { ...filters, columns })
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchfetchDossierServicePrepareDownload () {
  yield takeEvery(searchActionTypes.FETCH_SERVICE_PREPARE_DOWNLOAD, fetchDossierServicePrepareDownload)
}

export function * fetchDossierServicePrepareRoleConfig () {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const result = yield call(findServicePreparationRoleConfig, auth.token)
    yield put(fetchServicePrepareRoleConfigSuccess(result))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchfetchDossierServicePrepareRoleConfig () {
  yield takeEvery(searchActionTypes.FETCH_SERVICE_PREPARE_ROLE_CONFIG, fetchDossierServicePrepareRoleConfig)
}

export function * fetchDossierFleets ({ filters, resolve }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const result = yield call(findDossierFleets, auth.token, filters)
    if (resolve) {
      yield call(resolve, result)
    } else {
      yield put(fetchDossierFleetsSuccess(result, filters))
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchfetchDossierFleets () {
  yield takeEvery(searchActionTypes.FETCH_DOSSIER_FLEETS, fetchDossierFleets)
}

export function * fetchDossierFleetsDownload ({ filters, columns }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(findDossierFleetsDownload, auth.token, filters, columns)
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchfetchDossierFleetsDownload () {
  yield takeEvery(searchActionTypes.FETCH_DOSSIER_FLEETS_DOWNLOAD, fetchDossierFleetsDownload)
}

export function * fetchReportCommercial ({ filters }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const result = yield call(findReportComercial, auth.token, filters)
    yield put(fetchReportCommercialSuccess(result, filters))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchfetchReportCommercial () {
  yield takeEvery(searchActionTypes.FETCH_REPORT_COMMERCIAL, fetchReportCommercial)
}

export function * fetchReportCommercialTable ({ filters }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const result = yield call(findReportCommercialTable, auth.token, filters)
    yield put(fetchReportCommercialTableSuccess(result, filters))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchfetchReportCommercialTable () {
  yield takeEvery(searchActionTypes.FETCH_REPORT_COMMERCIAL_TABLE, fetchReportCommercialTable)
}

export function * fetchReportCommercialTableDownload ({ filters, columns }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(findReportCommercialTableDownload, auth.token, filters, columns)
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchfetchReportCommercialTableDownload () {
  yield takeEvery(searchActionTypes.EXPORT_REPORT_COMMERCIAL_TABLE, fetchReportCommercialTableDownload)
}

export function * fetchexportReportCommercial ({ cells }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(exportReportCommercial, auth.token, cells)
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchfetchexportReportCommercial () {
  yield takeEvery(searchActionTypes.FETCH_REPORT_COMMERCIAL_DOWNLOAD, fetchexportReportCommercial)
}

export function * fetchPapServicesFromManager ({ filters }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const result = yield call(findPapServicesFromManager, auth.token, filters)
    yield put(fetchPapServicesFromManagerSuccess(result, filters))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchPapServicesFromManager () {
  yield takeEvery(searchActionTypes.FETCH_PAP_SERVICES_FROM_MANAGER, fetchPapServicesFromManager)
}

export function * fetchPapServicesDownloadFromManager ({ filters, columns }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(findPapServicesFromManagerDownload, auth.token, { filters, columns })
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchPapServicesDownloadFromManager () {
  yield takeEvery(searchActionTypes.FETCH_PAP_SERVICES_DOWNLOAD_FROM_MANAGER, fetchPapServicesDownloadFromManager)
}

export function * fetchPlanLogiticsPapServices ({ filters }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const result = yield call(findPlanLogisticsPap, auth.token, filters)
    yield put(fetchPlanLogiticsPapSuccess(result, filters))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchPlanLogiticsPapServices () {
  yield takeEvery(searchActionTypes.FETCH_PLAN_LOGISTICS_PAP, fetchPlanLogiticsPapServices)
}

export function * fetchProcessingAgencies ({ filters }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const result = yield call(findProcessingAgencies, auth.token, filters)
    yield put(fetchProcessingAgenciesSuccess(result, filters))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchProcessingAgencies () {
  yield takeEvery(searchActionTypes.FETCH_PROCESSING_AGENCIES, fetchProcessingAgencies)
}

export function * fetchProcessAgenciesDownload ({ filters, columns }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(findProcessingAgenciesDownload, auth.token, { ...filters, columns })
    yield put(messageModalActions.openSuccessMessageModal('MESSAGES.DOSSIER_LIST_EXPORT_SUCCESS'))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchProcessAgenciesDownload () {
  yield takeEvery(searchActionTypes.FETCH_PROCESSING_AGENCIES_DOWNLOAD, fetchProcessAgenciesDownload)
}

export function * fetchFleetReport ({ filters }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const result = yield call(findFleetReport, auth.token, filters)
    yield put(fetchFleetReportSuccess(result, filters))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchFleetReport () {
  yield takeEvery(searchActionTypes.FETCH_FLEET_REPORT, fetchFleetReport)
}

export function * fetchFleetReportDownload ({ filters, columns }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(findFleetReportDownload, auth.token, { ...filters, columns })
    yield put(messageModalActions.openSuccessMessageModal('MESSAGES.DOSSIER_LIST_EXPORT_SUCCESS'))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchFleetReportDownload () {
  yield takeEvery(searchActionTypes.FETCH_FLEET_REPORT_DOWNLOAD, fetchFleetReportDownload)
}

export function * fetchStockManagementDossiers ({ filters }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const result = yield call(findStockManagementDossiers, auth.token, filters)
    yield put(fetchStockManagementDossiersSuccess(result, filters))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchStockManagementDossiers () {
  yield takeEvery(searchActionTypes.FETCH_STOCK_MANAGEMENT_DOSSIERS, fetchStockManagementDossiers)
}

export function * fetchStockManagementDossiersDownload ({ filters }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(findStockManagementDossiersDownload, auth.token, filters)
    yield put(messageModalActions.openSuccessMessageModal('MESSAGES.DOSSIER_LIST_EXPORT_SUCCESS'))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchStockManagementDossiersDownload () {
  yield takeEvery(searchActionTypes.FETCH_STOCK_MANAGEMENT_DOSSIERS_DOWNLOAD, fetchStockManagementDossiersDownload)
}
