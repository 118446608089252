import { put, call, select, takeEvery } from 'redux-saga/effects'
import { change, reset } from 'redux-form'
import { getAuth } from '../../selectors/access/auth'
import { getUserFilter } from '../../selectors/admin/userAdmin'
import userActionTypes from '../../constants/actions/admin/userAdmin'
import { fetchUserAdminSuccess, openAddUserModalSuccess, openEditUserModalSuccess, closeEditUserModal, openUploadSignModalSuccess, closeUploadSignModalSuccess } from '../../actions/admin/userAdmin'
import { showLoader, hideLoader } from '../../actions/common.js'
import postUserListService from '../../services/user/postUsersList'
import getUserService from '../../services/user/getUser'
import getEmptyRolesService from '../../services/user/getUserEmptyRoles'
import getEmptyUOService from '../../services/user/getUserEmptyUO'
import putUserService from '../../services/user/putUser'
import postUserService from '../../services/user/postUser'
import deleteUserService from '../../services/user/deleteUser'
import recoverUserService from '../../services/user/recoverUser'
import putAdminUserPassword from '../../services/user/putAdminUserPassword'
import { yesNoModal } from '../modalsListeners/yesNoModal'
import { handleError } from '../errors/errorManager'
import messageModalActions, { openSuccessMessageModal } from '../../actions/modals/messageModal'
import deleteSalesmanUser from '../../services/user/deleteSalesmanUser'
import recoverSalesmanUser from '../../services/user/recoverSalesmanUser'
import getExistCifService from '../../services/user/getExistCif'
import postUploadSign from '../../services/user/postUploadSign'
import getUserSign from '../../services/user/getUserSign'
import encryptPassword from '../../services/access/encryptPassword'
import postUsersMassLoad from '../../services/user/postUsersMassLoad'
import { fetchUploadTest } from '../../actions/masters/masters'

export function * fetchUserList ({ filter }) {
  try {
    yield put(showLoader())
    yield filter = yield filter || select(getUserFilter)
    const auth = yield select(getAuth)
    const userList = yield call(postUserListService, auth.token, filter)
    yield put(fetchUserAdminSuccess(userList, filter))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchUserList () {
  yield takeEvery(userActionTypes.FETCH_USER_ADMIN, fetchUserList)
}

export function * openAddUserModal () {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield put(reset('editUser'))
    const uoRoles = yield call(getEmptyRolesService, auth.token)
    const uoSalesman = yield call(getEmptyUOService, auth.token)
    yield put(openAddUserModalSuccess(uoRoles, uoSalesman))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchOpenAddUserModal () {
  yield takeEvery(userActionTypes.OPEN_ADD_USER_MODAL, openAddUserModal)
}

export function * openEditUserModal ({ userId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const user = yield call(getUserService, userId, auth.token)
    yield put(change('editUser', 'user', user))
    yield put(openEditUserModalSuccess(user))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchOpenEditUserModal () {
  yield takeEvery(userActionTypes.OPEN_EDIT_USER_MODAL, openEditUserModal)
}

export function * submitUser ({ userId, body, filter }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const filter = yield filter || select(getUserFilter)
    if (userId) {
      yield call(putUserService, userId, body, auth.token)
    } else {
      yield call(postUserService, body, auth.token)
    }
    yield put(closeEditUserModal())
    const userList = yield call(postUserListService, auth.token, filter)
    yield put(fetchUserAdminSuccess(userList, filter))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSubmitUser () {
  yield takeEvery(userActionTypes.SUBMIT_USER, submitUser)
}

export function * deleteUser ({ userId, filter }) {
  try {
    const confirmed = yield call(yesNoModal, 'deleteUser')
    if (confirmed) {
      yield put(showLoader())
      const auth = yield select(getAuth)
      const filter = yield filter || select(getUserFilter)
      yield call(deleteUserService, userId, auth.token)
      const userList = yield call(postUserListService, auth.token, filter)
      yield put(fetchUserAdminSuccess(userList, filter))
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDeleteUser () {
  yield takeEvery(userActionTypes.DELETE_USER, deleteUser)
}

export function * deleteSalesman ({ userId, salesmanId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const user = yield call(getUserService, userId, auth.token)
    yield call(deleteSalesmanUser, salesmanId, auth.token)
    yield put(openEditUserModalSuccess(user))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDeleteSalesman () {
  yield takeEvery(userActionTypes.DELETE_SALESMAN, deleteSalesman)
}

export function * recoverSalesman ({ userId, salesmanId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const user = yield call(getUserService, userId, auth.token)
    yield call(recoverSalesmanUser, salesmanId, auth.token)
    yield put(openEditUserModalSuccess(user))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchRecoverSalesman () {
  yield takeEvery(userActionTypes.RECOVER_SALESMAN, recoverSalesman)
}

export function * recoverUser ({ userId, filter }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const filter = yield filter || select(getUserFilter)
    yield call(recoverUserService, userId, auth.token)
    const userList = yield call(postUserListService, auth.token, filter)
    yield put(fetchUserAdminSuccess(userList, filter))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchRecoverUser () {
  yield takeEvery(userActionTypes.RECOVER_USER, recoverUser)
}

export function * adminChangeUserPassword ({ userId, newPassword, repeatNewPassword, emailAddress }) {
  try {
    const confirmed = yield call(yesNoModal, 'changeUserPassword')
    if (confirmed) {
      yield put(showLoader())
      const auth = yield select(getAuth)
      let encriptedNewPasword = encryptPassword(newPassword, emailAddress)
      let encriptedRepeatNewPasword = encryptPassword(repeatNewPassword, emailAddress)
      yield call(putAdminUserPassword, userId, { newPassword: encriptedNewPasword, repeatNewPassword: encriptedRepeatNewPasword }, auth.token)
      yield put(openSuccessMessageModal('MESSAGES.PASSWORD_CHANGE_SUCCESS'))
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchAdminChangeUserPassword () {
  yield takeEvery(userActionTypes.ADMIN_CHANGE_PASSWORD, adminChangeUserPassword)
}

export function * existCif ({ cif, resolve, reject }) {
  try {
    yield put(showLoader())
    const response = yield call(getExistCifService, cif)
    if (resolve) resolve(response)
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchExistCif () {
  yield takeEvery(userActionTypes.EXIST_CIF, existCif)
}

export function * openUploadSignModal () {
  try {
    yield put(showLoader())
    yield put(openUploadSignModalSuccess())
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchOpenUploadSignModal () {
  yield takeEvery(userActionTypes.OPEN_SIGN_UPLOAD_MODAL, openUploadSignModal)
}

export function * uploadSign ({ file, userId, resolve }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const response = yield call(
      postUploadSign,
      auth.token,
      file,
      userId
    )
    if (response) {
      yield put(hideLoader())
      yield put(closeUploadSignModalSuccess())
      yield put(openSuccessMessageModal('ADMIN.USERS.UPLOAD_SIGN.UPLOAD_SIGN_SUCCESS'))
      resolve(true)
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchUploadSign () {
  yield takeEvery(userActionTypes.UPLOAD_SIGN, uploadSign)
}

export function * closeUploadSignModal () {
  yield put(closeUploadSignModalSuccess())
}

export function * watchCloseUploadSignModal () {
  yield takeEvery(userActionTypes.CLOSE_SIGN_UPLOAD_MODAL, closeUploadSignModal)
}

export function * checkCanCloseSignModal ({ showAdvise, resolve }) {
  if (showAdvise) {
    const acceptCloseModal = yield call(yesNoModal, 'commonDossierSaveWarning')
    if (resolve) resolve(acceptCloseModal)
  } else {
    resolve(true)
  }
}

export function * watchCheckCanCloseSignModal () {
  yield takeEvery(userActionTypes.CHECK_CAN_CLOSE_SIGN_MODAL, checkCanCloseSignModal)
}

export function * downloadSignUser ({ userId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(getUserSign, auth.token, userId)
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDownloadSignUser () {
  yield takeEvery(userActionTypes.DOWNLOAD_SIGN, downloadSignUser)
}

export function * uploadUserMassDocument ({ file, code, callback, resolve, t }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    let modalState = JSON.parse(JSON.stringify(yield select(state => state.modalMassUpload)))
    let response = yield call(postUsersMassLoad, code, auth.token, file)
    let confirmation = modalState.code && modalState.code === response.token
    let succes = response.totalRecords === response.correct
    let errors = []
    if (response.userErrors && response.userErrors.length > 0) {
      response.userErrors.map((er, i) => {
        let userError = {}
        userError.errorHeader = er.rowNumber ? er.rowNumber : er.userName
        userError.errorMessage = er.errorMessage ? t('ERRORS.BACKEND_TAG_TRANSLATION.' + er.errorMessage)
          : er.columErrors && er.columErrors.length > 0 ? er.columErrors.map((ms, i) => { return t('ERRORS.BACKEND_TAG_TRANSLATION.' + ms) + '. ' }) : ''
        errors.push(userError)
      })
    }
    yield put(fetchUploadTest(response.usersCreated, errors, succes, succes ? response.token : null, response.StorageConfigError))
    if (succes && confirmation && callback) yield call(callback)
    if (resolve) yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MASTERS.OU_MASTERS.FETCH_ERROR_MSG', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('DocumentMassUpload'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchUploadUserMassDocument () {
  yield takeEvery(userActionTypes.UPLOAD_USER_MASS_DOC, uploadUserMassDocument)
}
