import { connect } from 'react-redux'
import DownloadError from '../../components/access/DownloadError'
import authActions from '../../actions/auth'
import { translate } from 'react-polyglot'
import { bindActionCreators } from 'redux'

export function mapStateToProps (state) {
  return {
    ...state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators(authActions, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(DownloadError))
