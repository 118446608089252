export const unattendedStatusClassNames = {
  1: 'unnatendedProcessStatusPending',
  2: 'unnatendedProcessStatusRunning',
  3: 'unnatendedProcessStatusEndedNoErrors',
  4: 'unnatendedProcessStatusEndedWithErrors'
}

export const unattendedResultClassNames = {
  1: 'unnatendedProcessResultPending',
  2: 'unnatendedProcessResultRunningNoErrors',
  3: 'unnatendedProcessResultEndedNoErrors',
  4: 'unnatendedProcessResultEndedWithErrors',
  5: 'unnatendedProcessResultRunningWhitErrors'
}

export const unnatendedResultToolTip = {
  1: 'MANAGEMENT.UNATTENDED_PROCESS_TAB.PENDING',
  2: 'MANAGEMENT.UNATTENDED_PROCESS_TAB.RUNNING',
  3: 'MANAGEMENT.UNATTENDED_PROCESS_TAB.FINALIZED',
  4: 'MANAGEMENT.UNATTENDED_PROCESS_TAB.FAILED'
}
