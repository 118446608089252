import React, { PureComponent } from 'react'
import { Checkbox, OverlayTrigger, Tooltip, Glyphicon } from 'react-bootstrap'
import { formatMoney, formatNumber } from '../../../../util/utils'
import { permissions } from '../../../../constants/backendIds'
import { Link } from 'react-router-dom'

class UACrow extends PureComponent {
  constructor () {
    super()
    this.state = {
      open: false
    }
  }

  render () {
    const {
      t,
      tKey,
      fetchUACUnitEdition,
      readOnlyFields,
      openUACEdition,
      deleteUACUnit,
      allDocumentUAC,
      uacUnit: {
        number, dealerNumber, vehicleBrand, vehicleModel, km, licensePlate, vinChassis, licensePlateDate, isPartOfPayment, amount, purchaseId, canBeEditable, urlToNavigate, allDocumentCompleted
      },
      dossierType
    } = this.props
    const editTooltip = (<Tooltip id="editTooltip">{t(`${tKey}EDIT_BTN`)}</Tooltip>)
    const deleteTooltip = (<Tooltip id="deleteTooltip">{t(`${tKey}DELETE_BTN`)}</Tooltip>)
    return (
      <tr>
        {allDocumentUAC !== permissions.hidden &&
        <td>
          {allDocumentCompleted === true &&
            <Glyphicon glyph="ok-sign"/>
          }
          {allDocumentCompleted === false &&
          <Glyphicon glyph="warning-sign"/>
          }
        </td>
        }
        <td>
          {
            urlToNavigate ? (<Link to={`/dossier${urlToNavigate}`}>{number}</Link>)
              : number
          }
        </td>
        <td>{dealerNumber}</td>
        <td>{vehicleBrand}</td>
        <td>{vehicleModel}</td>
        <td className="text-right">{formatNumber(km, '', 0, ',', '.')}</td>
        <td>{licensePlate}</td>
        <td>{vinChassis}</td>
        <td>{licensePlateDate}</td>
        <td><span><Checkbox className="checkbox-no-label" checked={isPartOfPayment} disabled inline /></span>{formatMoney(amount)}</td>
        {
          canBeEditable && !readOnlyFields
            ? <td>
              <a onClick={() => fetchUACUnitEdition(purchaseId, openUACEdition, dossierType)}>
                <OverlayTrigger placement="right" overlay={editTooltip}>
                  <i className="ico-edit-white"/>
                </OverlayTrigger>
              </a>
              <a onClick={() => deleteUACUnit(purchaseId, dossierType)}>
                <OverlayTrigger placement="left" overlay={deleteTooltip}>
                  <i className="ico-trash"/>
                </OverlayTrigger>
              </a>
            </td>
            : <td/>

        }
      </tr>
    )
  }
}
export default UACrow
