import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { closeMaritalStatusMasterModal, submitMaritalStatus } from '../../../actions/masters/maritalStatus/maritalStatus'
import MaritalStatusMasterModal from '../../../components/masters/maritalStatus/MaritalStatusMasterModal'

export function mapStateToProps (state) {
  const maritalStatusModal = state.maritalStatus.maritalStatusModal
  return {
    showModal: maritalStatusModal.showModal,
    maritalStatus: maritalStatusModal.maritalStatus,
    languageList: maritalStatusModal.languageList
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      submitMaritalStatus,
      closeMaritalStatusMasterModal
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(MaritalStatusMasterModal))
