import apiFetch from '../apiFetch'

export default function (token, groupId) {
  const queryParamsArray = [
    `groupId=${groupId}`
  ]
  const queryParams = queryParamsArray.reduce(
    (result, query, idx) => result + (query ? (result ? `&${query}` : `?${query}`) : '')
    , ''
  )
  return apiFetch({ endPoint: `CampaignGroup/Recover${queryParams}`, method: 'POST', body: null, token: token })
}
