import React, { PureComponent } from 'react'
import { Table, Row } from 'react-bootstrap'
class Tab2 extends PureComponent {
  render () {
    return (
      <Row className="tabPane-container">
        <Table responsive className="table-tabPane">
          <thead>
            <tr>
              <th>Impuesto</th>
              <th>%</th>
              <th>Importe</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>IVA</td>
              <td>0,0%</td>
              <td></td>
            </tr>
            <tr>
              <td>IEM</td>
              <td>0,0%</td>
              <td></td>
            </tr>
            <tr>
              <td><span>Total impuestos</span></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </Table>
      </Row>
    )
  }
}

export default Tab2
