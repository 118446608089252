export default {
  FETCH_SECTION_LIST: 'FETCH_SECTION_LIST',
  FETCH_SECTION_LIST_SUCCESS: 'FETCH_SECTION_LIST_SUCCESS',
  FETCH_QUESTION: 'FETCH_QUESTION',
  FETCH_QUESTION_SUCCESS: 'FETCH_QUESTION_SUCCESS',
  FETCH_SECTION_QUESTION: 'FETCH_SECTION_QUESTION',
  FETCH_SECTION_QUESTION_SUCCESS: 'FETCH_SECTION_QUESTION_SUCCESS',
  FETCH_QUESTIONS_BY_SEARCH: 'FETCH_QUESTIONS_BY_SEARCH',
  FETCH_QUESTIONS_BY_SEARCH_SUCCESS: 'FETCH_QUESTIONS_BY_SEARCH_SUCCESS',
  VOTE_QUESTION: 'VOTE_QUESTION'
}
