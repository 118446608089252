import React, { PureComponent } from 'react'
import { Col, Table } from 'react-bootstrap'
import EditableRow from './EditableRow'
import { economicPlanSectionType } from '../../../constants/dossier/common/economicPlanSection'
import { dossierTypes as dossierTypesConstant } from '../../../constants/dossier/common/dossierTypes'

class EditableTable extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      recharge: false,
      onEdition: false
    }
    this.clickRecharge = this.clickRecharge.bind(this)
    this.takeCare = this.takeCare.bind(this)
  }

  clickRecharge () {
    this.setState({ recharge: !this.state.recharge })
  }

  takeCare () {
    this.setState({ onEdition: !this.state.onEdition })
  }

  render () {
    const {
      t, input, readOnlyFields, tableClassName = '', editAction, updateAction, deleteAction, createAction, dossierType,
      columnsConfig, columnsConfigSpecialHelp, columnsConfigTotales, columnsConfigPaperwork, columnsConfigPresent, columnsConfigIem,
      editableObjectsArray, editableObjectsArraySecundary = [], editableObjectsArrayIemSupplied, editableObjectsArrayTotales,
      editableObjectsArrayPaperworkSupplied, editableObjectsArrayPaperworkExtraSale, editableObjectsArrayPaperworkPresent,
      editableObjectsArrayPaperworkAssociatedCost, openOrCloseAssociatedCostPercentageModal, editableCommentsArray, takeCareEdition,
      editableObjectsArrayPresent, editableObjectsArrayCampaign, deleteEconomicCampaign,
      showDeleteAction, showEditAction, showAddButton, showUploadInvoiceBtn = false, showUploadOrderBtn = false, showRecoverAction = false,
      invoiceDocTypeUseId, orderDocTypeUseId, recoverAction, openDocumentEditionModal, openDocWarning
    } = this.props

    return (
      <Col sm={12} className="tables-add-dossier">
        <Table className={ (tableClassName + ' editable-table').trim() } hover>
          <thead>
            <tr>
              {
                columnsConfig.map((column, idx) => {
                  return column.showColumn !== false ? (<th key={idx} style={column.style} className={column.className + ' text-center'}>{column.th}</th>) : null
                })
              }
              {(showEditAction !== false || showDeleteAction !== false || showAddButton !== false || showRecoverAction !== false) &&
              <th style={{ width: '77px' }} className="text-center">{t('DOSSIER_COMPONENTS.ACCESSORIES.ACTIONS')}</th>}
            </tr>
          </thead>
          <tbody>
            {
              editableObjectsArrayIemSupplied && (
                <EditableRow
                  dossierType={dossierType}
                  columnsConfig={columnsConfigIem}
                  editableObject={editableObjectsArrayIemSupplied}
                  readOnlyFields={true}
                  input={input}
                  showAddButton={showAddButton}
                  showDeleteAction={showDeleteAction}
                  showEditAction={showEditAction}
                />
              )
            }
            {
              editableObjectsArray && editableObjectsArray.length > 0 && editableObjectsArray.map((editableObject, idx) => (
                <EditableRow
                  dossierType={dossierType}
                  key={idx}
                  columnsConfig={columnsConfig}
                  editableObject={editableObject}
                  editAction={editAction}
                  updateAction={updateAction}
                  deleteAction={deleteAction}
                  readOnlyFields={readOnlyFields }
                  optionals={this.props.optionals}
                  input={input}
                  takeCareEdition={takeCareEdition ? this.state.onEdition : null}
                  takeCareAction={takeCareEdition ? this.takeCare : null}
                  showAddButton={showAddButton}
                  showDeleteAction={showDeleteAction}
                  showEditAction={showEditAction}
                />
              ))
            }
            {
              editableCommentsArray && editableCommentsArray.length > 0 && editableCommentsArray.map((editableObject, idx) => (
                <EditableRow
                  dossierType={dossierType}
                  key={idx}
                  columnsConfig={columnsConfig}
                  editableObject={editableObject}
                  editAction={editAction}
                  updateAction={updateAction}
                  deleteAction={deleteAction}
                  readOnlyFields={readOnlyFields || editableObject.canEditComment !== true }
                  optionals={this.props.optionals}
                  input={input}
                  showAddButton={showAddButton}
                  showDeleteAction={showDeleteAction}
                  showEditAction={showEditAction}
                />
              ))
            }
            {
              editableObjectsArraySecundary.map((editableObjectSecundary, idx) => (
                <EditableRow
                  dossierType={dossierType}
                  key={idx}
                  columnsConfig={columnsConfigSpecialHelp}
                  editableObject={editableObjectSecundary}
                  readOnlyFields={this.props.dossierType ? !(this.props.dossierType === dossierTypesConstant.fleetEconomicPlan) : true}
                  input={input}
                  showAddButton={showAddButton}
                  showDeleteAction={showDeleteAction}
                  showEditAction={showEditAction}
                  deleteAction={deleteEconomicCampaign}
                />
              ))
            }
            {
              editableObjectsArrayPaperworkExtraSale && editableObjectsArrayPaperworkExtraSale.length > 0 &&
              editableObjectsArrayPaperworkExtraSale.map((editableObject, idx) => (
                economicPlanSectionType.VENTA_OTROS === editableObject.typeId ? (
                  <EditableRow
                    dossierType={dossierType}
                    key={idx}
                    columnsConfig={columnsConfigPaperwork}
                    editableObject={editableObject}
                    readOnlyFields={true}
                    input={input}
                    showAddButton={showAddButton}
                    showDeleteAction={showDeleteAction}
                    showEditAction={showEditAction}
                  />) : null
              ))
            }
            {
              editableObjectsArrayPaperworkSupplied && editableObjectsArrayPaperworkSupplied.length > 0 &&
              editableObjectsArrayPaperworkSupplied.map((editableObject, idx) => (
                economicPlanSectionType.SUPLIDOS === editableObject.typeId ? (
                  <EditableRow
                    dossierType={dossierType}
                    key={idx}
                    columnsConfig={columnsConfigPaperwork}
                    editableObject={editableObject}
                    readOnlyFields={true}
                    input={input}
                    showAddButton={showAddButton}
                    showDeleteAction={showDeleteAction}
                    showEditAction={showEditAction}
                  />) : null
              ))
            }
            {
              editableObjectsArrayPaperworkPresent && editableObjectsArrayPaperworkPresent.length > 0 &&
              editableObjectsArrayPaperworkPresent.map((editableObject, idx) => (
                economicPlanSectionType.COSTE_OBSEQUIOS === editableObject.typeId ? (
                  <EditableRow
                    dossierType={dossierType}
                    key={idx}
                    columnsConfig={columnsConfigPaperwork}
                    editableObject={editableObject}
                    readOnlyFields={true}
                    input={input}
                    showAddButton={showAddButton}
                    showDeleteAction={showDeleteAction}
                    showEditAction={showEditAction}
                  />) : null
              ))
            }
            {
              editableObjectsArrayPaperworkAssociatedCost && editableObjectsArrayPaperworkAssociatedCost.length > 0 &&
            editableObjectsArrayPaperworkAssociatedCost.map((editableObject, idx) => (
              economicPlanSectionType.ASSOCIATED_COSTS === editableObject.typeId ? (
                <EditableRow
                  dossierType={dossierType}
                  key={idx}
                  columnsConfig={columnsConfigPaperwork}
                  editableObject={editableObject}
                  readOnlyFields={true}
                  openOrCloseAssociatedCostPercentageModal={openOrCloseAssociatedCostPercentageModal}
                  input={input}
                  showAddButton={showAddButton}
                  showDeleteAction={showDeleteAction}
                  showEditAction={showEditAction}
                />) : null
            ))
            }
            {
              editableObjectsArrayPresent && editableObjectsArrayPresent.length > 0 &&
              editableObjectsArrayPresent.map((editableObject, idx) => (
                <EditableRow
                  dossierType={dossierType}
                  key={idx}
                  rowIndex = {idx}
                  columnsConfig={columnsConfigPresent}
                  editableObject={editableObject}
                  editAction={editAction}
                  updateAction={updateAction}
                  deleteAction={deleteAction}
                  recoverAction={recoverAction}
                  readOnlyFields={readOnlyFields }
                  optionals={this.props.optionals}
                  input={input}
                  takeCareEdition={takeCareEdition ? this.state.onEdition : null}
                  takeCareAction={takeCareEdition ? this.takeCare : null}
                  showAddButton={showAddButton}
                  showDeleteAction={showDeleteAction}
                  showEditAction={showEditAction}
                  showUploadInvoiceBtn={showUploadInvoiceBtn}
                  showUploadOrderBtn={showUploadOrderBtn}
                  showRecoverAction={showRecoverAction}
                  invoiceDocTypeUseId={invoiceDocTypeUseId}
                  orderDocTypeUseId={orderDocTypeUseId}
                  openDocumentEditionModal={openDocumentEditionModal}
                  isPristine={this.props.isPristine}
                  openDocWarning ={openDocWarning}
                />)
              )
            }
            {
              editableObjectsArrayCampaign && editableObjectsArrayCampaign.length > 0 &&
              editableObjectsArrayCampaign.map((editableObjectArrayCampaign, idx) => (
                <EditableRow
                  dossierType={dossierType}
                  key={idx}
                  columnsConfig={columnsConfig}
                  editableObject={editableObjectArrayCampaign}
                  readOnlyFields={this.props.dossierType ? !(this.props.dossierType === dossierTypesConstant.fleetEconomicPlan) : true}
                  input={input}
                  deleteAction={deleteAction}
                  showEditAction={this.props.dossierType !== dossierTypesConstant.fleetEconomicPlan}
                />
              ))
            }
            {
              editableObjectsArrayTotales && (
                <EditableRow
                  dossierType={dossierType}
                  columnsConfig={columnsConfigTotales}
                  editableObject={editableObjectsArrayTotales}
                  readOnlyFields={true}
                  total={true}
                  input={input}
                  showAddButton={showAddButton}
                  showDeleteAction={showDeleteAction}
                  showEditAction={showEditAction}
                  showUploadInvoiceBtn={showUploadInvoiceBtn}
                  showUploadOrderBtn={showUploadOrderBtn}
                />
              )
            }

            <EditableRow
              dossierType={dossierType}
              createRowOption={true}
              columnsConfig={columnsConfig}
              createAction={createAction}
              readOnlyFields={readOnlyFields}
              input={input}
              clickRecharge={this.clickRecharge}
              takeCareEdition={takeCareEdition ? this.state.onEdition : null}
              showAddButton={showAddButton}
              showDeleteAction={showDeleteAction}
              showEditAction={showEditAction}
              showUploadInvoiceBtn={showUploadInvoiceBtn}
              showUploadOrderBtn={showUploadOrderBtn}
            />
          </tbody>
        </Table>
      </Col>
    )
  }
}
export default EditableTable
