
import React, { PureComponent } from 'react'
import { Modal, Tabs, Tab } from 'react-bootstrap'
import { reduxForm } from 'redux-form'
import ProcessingAgencyIntegrationDocumentsMasterModalPage from '../../../containers/masters/ProcessingAgency/processingAgencyIntegrations/ProcessingAgencyIntegrationDocumentsMasterModalPage'
import ProcessingAgencyIntegrationRegistrationTypesMasterPage from '../../../containers/masters/ProcessingAgency/processingAgencyIntegrations/ProcessingAgencyIntegrationRegistrationTypesMasterPage'
import ProcessingAgencyIntegrationServiceDestinationsMasterModalPage from '../../../containers/masters/ProcessingAgency/processingAgencyIntegrations/ProcessingAgencyIntegrationServiceDestinationsMasterPage'
import ProcessingAgencyIntegrationGeneralMasterModalPage from '../../../containers/masters/ProcessingAgency/processingAgencyIntegrations/ProcessingAgencyIntegrationGeneralMasterModalPage'

class ProcessingAgencyIntegrationMasterModal extends PureComponent {
  constructor () {
    super()
    this.state = {
    }
  }

  submitAgency (values) {
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.showModal !== this.props.showModal && nextProps.showModal === true) {
      this.props.initialize({ ...nextProps.agency })
    }
  }

  onSelectTab (tabKey) {
    switch (tabKey) {
      default:
        break
    }
  }

  render () {
    const {
      t,
      showModal
    } = this.props
    const tKey = 'MASTERS.PROCESSING_AGENCY_INTEGRATIONS_MASTERS.MODAL.'
    return (
      <Modal className="role-modal languages-modal integrations-modal-padding" show={showModal} onHide={() => this.props.actions.showEditProcessingAgencyIntegrationModal(false)} backdrop={'static'}>
        <Modal.Header closeButton onHide={() => this.props.actions.showEditProcessingAgencyIntegrationModal(false)}>
          <Modal.Title>{t(`${tKey}TITLE`)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="masters-container search-wrapper">
            <Tabs defaultActiveKey={1} onSelect={this.onSelectTab.bind(this)} id="groups-tabs">
              <Tab eventKey={1} title={t(`${tKey}GENERAL`)}>
                <ProcessingAgencyIntegrationGeneralMasterModalPage />
              </Tab>
              <Tab eventKey={2} title={t(`${tKey}DOCUMENTS`)}>
                <ProcessingAgencyIntegrationDocumentsMasterModalPage />
              </Tab>
              <Tab eventKey={3} title={t(`${tKey}REGISTRATION_TYPES`)}>
                <ProcessingAgencyIntegrationRegistrationTypesMasterPage/>
              </Tab>
              <Tab eventKey={4} title={t(`${tKey}SERVICE_DESTINATION`)}>
                <ProcessingAgencyIntegrationServiceDestinationsMasterModalPage/>
              </Tab>
            </Tabs>
          </div>
        </Modal.Body>
      </Modal>
    )
  }
}

export default reduxForm({
  form: 'editAgency',
  validate: (values, props) => {
  }
})(ProcessingAgencyIntegrationMasterModal)
