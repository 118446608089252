import React, { PureComponent } from 'react'
import { Col } from 'react-bootstrap'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'
import { sliderValueColors } from '../../../../constants/dossier/common/keyTabs'

class InputSliderButton extends PureComponent {
  constructor () {
    super()
    this.state = {
      backgroundColor: null
    }
  }

  handleChange (value) {
    if (this.props.onInputChange) {
      this.props.onInputChange(value)
    }
    this.setState({ backgroundColor: sliderValueColors[value] })
    this.props.input.onChange(value)
  }

  componentDidUpdate () {
    if (this.props.input && this.state.backgroundColor !== sliderValueColors[this.props.input.value]) {
      this.setState({ backgroundColor: sliderValueColors[this.props.input.value] })
    }
  }

  render () {
    const { id = '', colSm = 0, colWidht = '25', customClass = '', defaultChoice = 1, textDescription = '', disabled = false, marks = { 0: '', 1: '', 2: '' } } = this.props
    return (
      <Col sm={colSm} className={'colWhidht-' + colWidht + ' ' + customClass}>
        <Slider
          id={id}
          min={0}
          max={2}
          step={1}
          marks={marks}
          railStyle={{ backgroundColor: this.state.backgroundColor ? this.state.backgroundColor : sliderValueColors[defaultChoice] }}
          included={false}
          onChange={this.handleChange.bind(this)}
          defaultValue={defaultChoice}
          handleStyle={{ backgroundColor: this.state.backgroundColor ? this.state.backgroundColor : sliderValueColors[defaultChoice] }}
          dotStyle={ { backgroundColor: 'transparent', borderColor: 'transparent' } }
          className='slider-with-steps'
          disabled={disabled}
          value={this.props.input.value}
        />
        <span className='slider-with-steps-tittle'>{textDescription}</span>
      </Col>
    )
  }
}

export default InputSliderButton
