import vehicleActionTypes from '../../../constants/actions/dossier/common/vehicle'

export function searchVehicle (dealerNumber, vinChassis, licensePlate, makerNumber, parentDossierType, dossierWithVehicle, organizedUnitId, dossierSubtypeId, pristine) {
  return {
    type: vehicleActionTypes.SEARCH_VEHICLE,
    dealerNumber,
    vinChassis,
    licensePlate,
    makerNumber,
    parentDossierType,
    dossierWithVehicle,
    organizedUnitId,
    dossierSubtypeId,
    pristine
  }
}

export function fetchOrder (dossierId) {
  return {
    type: vehicleActionTypes.FETCH_ORDER,
    dossierId
  }
}

export function fetchOrderModalSuccess (dossierId) {
  return {
    type: vehicleActionTypes.FETCH_ORDER_MODAL_SUCCESS,
    dossierId
  }
}

export function fetchComboOrigin (comercialCode, dossierSubType) {
  return {
    type: vehicleActionTypes.FETCH_COMBO_ORIGIN,
    comercialCode,
    dossierSubType
  }
}

export function fetchComboDestination (comercialCode, dossierSubType) {
  return {
    type: vehicleActionTypes.FETCH_COMBO_DESTINATION,
    comercialCode,
    dossierSubType
  }
}

export function setSearchDealerNumber (searchDealerNumber) {
  return {
    type: vehicleActionTypes.SET_SEARCH_DEALER_NUMBER,
    searchDealerNumber
  }
}

export function setSearchVinChassis (searchVinChassis) {
  return {
    type: vehicleActionTypes.SET_SEARCH_VIN_CHASSIS,
    searchVinChassis
  }
}

export function setSearchLicensePlate (searchLicensePlate) {
  return {
    type: vehicleActionTypes.SET_SEARCH_LICENCE_PLATE,
    searchLicensePlate
  }
}

export function setSearchMakerNumber (searchMakerNumber) {
  return {
    type: vehicleActionTypes.SET_SEARCH_MAKER_NUMBER,
    searchMakerNumber
  }
}

export function setSimpleInputTextValidation (simpleInputTextValidation) {
  return {
    type: vehicleActionTypes.SET_SIMPLE_INPUT_TEXT_VALIDATION,
    simpleInputTextValidation
  }
}

export function resetSearchFields (state) {
  return {
    type: vehicleActionTypes.RESET_SEARCH_FIELDS
  }
}

export function closeOrderModal () {
  return {
    type: vehicleActionTypes.CLOSE_ORDER_MODAL
  }
}

export function saveOrder (order, dossierId, isSalesRequestModal, dossierType, resolve) {
  return {
    type: vehicleActionTypes.SAVE_ORDER,
    order,
    dossierId,
    isSalesRequestModal,
    resolve,
    dossierType
  }
}

export function refreshVehicleComponent (parentDossierType) {
  return {
    type: vehicleActionTypes.REFRESH_VEHICLE_COMPONENT,
    parentDossierType
  }
}

export function clearVehicle (parentDossierType, pristine = null) {
  return {
    type: vehicleActionTypes.CLEAR_VEHICLE,
    parentDossierType,
    pristine
  }
}

export function fetchOrderCatalogueModel (catalogueId, modelId, resolve) {
  return {
    type: vehicleActionTypes.FETCH_ORDER_VEHICLE_CATALOGUE_MODEL,
    catalogueId,
    modelId,
    resolve
  }
}

export function fetchVehicleSelectionModal () {
  return {
    type: vehicleActionTypes.FETCH_VEHICLE_SELECTION_MODAL
  }
}

export function fetchVehicleSelectionModalSuccess (purchaseList, stockDossierId, filters) {
  return {
    type: vehicleActionTypes.FETCH_VEHICLE_SELECTION_MODAL_SUCCESS,
    purchaseList,
    stockDossierId,
    filters
  }
}

export function closeVehicleSelectionModal () {
  return {
    type: vehicleActionTypes.CLOSE_VEHICLE_SELECTION_MODAL
  }
}

export function closeVehicleCreationModal () {
  return {
    type: vehicleActionTypes.CLOSE_VEHICLE_CREATION_MODAL
  }
}

export function createVehicleCreationModal (values) {
  return {
    type: vehicleActionTypes.CREATE_VEHICLE_CREATION_MODAL,
    values
  }
}

export default {
  searchVehicle,
  fetchOrder,
  fetchOrderModalSuccess,
  setSearchDealerNumber,
  setSearchVinChassis,
  setSearchLicensePlate,
  setSearchMakerNumber,
  setSimpleInputTextValidation,
  resetSearchFields,
  closeOrderModal,
  saveOrder,
  refreshVehicleComponent,
  clearVehicle,
  fetchComboOrigin,
  fetchComboDestination,
  fetchOrderCatalogueModel,
  fetchVehicleSelectionModal,
  fetchVehicleSelectionModalSuccess,
  closeVehicleSelectionModal,
  closeVehicleCreationModal,
  createVehicleCreationModal
}
