import React, { PureComponent } from 'react'
import { Panel, Row } from 'react-bootstrap'
import InputSelect from '../../commons/form/InputSelect'
import InputText from '../../commons/form/InputText'
import InputNumber from '../../commons/form/InputNumber'
import InputCheckBox from '../../commons/form/InputCheckBox'
import InputPercentage from '../../commons/form/InputPercentage'
import InputDatePicker from '../../commons/form/InputDatePicker'
import { Field } from 'redux-form'
import { validateDate } from '../../../util/validationFunctions'
import { permissions, homologationClassification } from '../../../constants/backendIds'
import { getFieldsPermisions } from '../../../util/permisionFunctions'
import { compareObjects } from '../../../util/utils'
import { dossierSubTypeId } from '../../../constants/dossier/common/dossierSubType'
import CustomFields from '../../commons/customFields/CustomFields'

class SalesComponent extends PureComponent {
  constructor () {
    super()
    this.state = {
      open: true,
      operationTypeIdDisabled: true,
      fieldsConfiguration: {
        salesmanId: null,
        salesmanIdManager: null,
        salesmanIdLock: null,
        saleTypeId: null,
        operationTypeIds: null,
        expectedDeliveryDate: null,
        orderDate: null,
        paymentMethodId: null,
        proposalPercDiscount: null,
        orderPercDiscount: null,
        signalPayNotRequired: null,
        additionalInfoIds: null,
        rentalOrderNumber: null,
        deliveryDestination: null,
        arriveVehicle: null,
        vehicleTypeId: null,
        LOPDNotRequired: null,
        homologationRequired: null,
        homologationClassificationId: null,
        homologationProducerId: null,
        despositDeliveryDate: null,
        despositEntityId: null,
        depositLicensePlate: null,
        depositKm: null,
        productionWeek: null,
        monthsGuaranteeExtension: null,
        notMultipleInvoices: null,
        serviceDestinationId: null,
        warrantyExtensionNotRequired: null,
        warrantyExtensionRequired: null,
        assignmentSheet: null,
        billingDate: null,
        billingNumber: null,
        campaignDocumentNotRequired: null,
        maintenanceInclude: null,
        reportableLLD: null,
        firstYearInsurance: null,
        accesories: null,
        transformation: null,
        multipleChoice: null,
        dossierWithDeposit: null,
        authSaleDepartamentNotRequired: null,
        fleetCode: null,
        pickPreviousVehicle: null,
        invoiceNumber: null,
        invoiceDate: null,
        brandDischargeDate: null,
        doubleTransferCheck: null,
        orderExternalId: null,
        satPaymentMethod: null,
        currency: null,
        operationTypeDMSIds: null,
        notLoJack: null,
        fullSumary: null,
        purchaseState: null,
        customerNeedDate: null,
        shippingOrderDate: null,
        campaignOutputDate: null,
        destinationArrivalDate: null,
        checkAutoWarranty: null
      },
      depositVehicleDisabled: false
    }
    this.dateValidation = this.dateValidation.bind(this)
  }

  initializePermisions (props) {
    const fieldKeys = Object.keys(this.state.fieldsConfiguration)
    const newState = getFieldsPermisions(props.componentConfiguration.fieldsConfiguration, fieldKeys, props.componentConfiguration.permission)
    this.setState({ ...this.state, ...newState })
  }

  handleSaleTypeChange (saleTypeId) {
    this.props.actions.change('salesComponent.operationTypeIds', [])
    if (saleTypeId) {
      this.props.actions.fetchOperationType(saleTypeId, this.props.dossier.organizedUnitId)
      this.setState({ operationTypeIdDisabled: false })
    } else {
      this.setState({ operationTypeIdDisabled: true })
    }
  }

  UNSAFE_componentWillMount () {
    this.initializePermisions(this.props)
    this.setState({
      homologationRequired: this.props.dossier.salesComponent.homologationRequired,
      homologationClassificationId: this.props.dossier.salesComponent.homologationClassificationId,
      depositVehicleDisabled: this.props.dossier.salesComponent.dossierWithDeposit
    })
  }

  dateValidation (value, allValues, props) {
    const result = validateDate(value, props)
    return result === Object(result) ? undefined : result
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.open) {
      this.setState({ open: true })
    }

    if (this.props.dossier.salesComponent !== nextProps.dossier.salesComponent) {
      nextProps.dossier.salesComponent.saleTypeId ? this.setState({ operationTypeIdDisabled: false }) : this.setState({ operationTypeIdDisabled: true })
    }

    if (!compareObjects(this.props.componentConfiguration || nextProps.componentConfiguration, true)) {
      this.initializePermisions(nextProps)
    }
  }

  render () {
    // TODO paymentSignalStatus logic (callback for change status)
    const { t, combos, customFields } = this.props
    const tKey = 'DOSSIER_COMPONENTS.SALES.'
    return (
      <Row className="sales-panel dossier-panel">
        <div className="header-panel" onClick={() => this.setState({ open: !this.state.open })}>
          <div className="name-wrapper">
            <i className="ico-sales" />
            <h4>{t(`${tKey}SALES`)}</h4>
          </div>
          <i className={this.state.open ? 'ico-arrow-black-up' : 'ico-arrow-black-down'} />
        </div>
        <Panel expanded={this.state.open} onToggle={() => {}}>
          <Panel.Collapse>
            <Row>
              {this.state.fieldsConfiguration.doubleTransferCheck &&
                this.state.fieldsConfiguration.doubleTransferCheck.permission !== permissions.hidden &&
                (<Field
                  colSm={4}
                  id="doubleTransferCheck"
                  name="salesComponent.doubleTransferCheck"
                  controlLabel={t(`${tKey}DOUBLE_TRANSFER`)}
                  placeholder={t(`${tKey}DOUBLE_TRANSFER`)}
                  valueKey="id"
                  labelKey="value"
                  component={InputCheckBox}
                  disabled={true}
                />)}
            </Row>
            <Row>
              {this.state.fieldsConfiguration.salesmanId.permission !== permissions.hidden && (<Field
                colSm={4}
                id="salesmanId"
                name="salesComponent.salesmanId"
                controlLabel={t(`${tKey}SALESMAN`)}
                placeholder={t(`${tKey}SALESMAN`)}
                valueKey="id"
                labelKey="value"
                component={InputSelect}
                options={combos.salesmanComboLogic}
                disabled={true}
              />)}
              {this.state.fieldsConfiguration.salesmanIdLock.permission !== permissions.hidden && (<Field
                colSm={4}
                id="salesmanId"
                name="salesComponent.salesmanId"
                controlLabel={t(`${tKey}SALESMAN`)}
                placeholder={t(`${tKey}SALESMAN`)}
                valueKey="id"
                labelKey="value"
                component={InputSelect}
                options={combos.salesmanCombo}
                disabled={true}
              />)}
              {this.state.fieldsConfiguration.salesmanIdManager.permission !== permissions.hidden && (<Field
                colSm={4}
                id="salesmanIdManager"
                name="salesComponent.salesmanIdManager"
                controlLabel={t(`${tKey}SALESMAN_MANAGER`)}
                placeholder={t(`${tKey}SALESMAN_MANAGER`)}
                valueKey="id"
                labelKey="value"
                component={InputSelect}
                options={combos.salesmanComboLogic}
                disabled={true}
              />)}
              {this.state.fieldsConfiguration.saleTypeId && this.state.fieldsConfiguration.saleTypeId.permission !== permissions.hidden && (<Field
                colSm={4}
                controlLabel={t(`${tKey}SALES_TYPE`)}
                id="saleTypeId"
                name="salesComponent.saleTypeId"
                placeholder={t(`${tKey}SALES_TYPE`)}
                menuContainerStyle={{ zIndex: 999 }}
                component={InputSelect}
                options={combos.saleTypeCombo}
                onInputChange={(value) => this.handleSaleTypeChange(value)}
                valueKey="id"
                labelKey="value"
                disabled={true}
              />)}
              {this.state.fieldsConfiguration.operationTypeIds && this.state.fieldsConfiguration.operationTypeIds.permission !== permissions.hidden && (<Field
                colSm={4}
                controlLabel={t(`${tKey}OPERATION_TYPE`)}
                id="operationTypeIds"
                name="salesComponent.operationTypeIds"
                multi
                menuContainerStyle={{ zIndex: 999 }}
                disabled={true}
                placeholder={t(`${tKey}OPERATION_TYPE`)}
                component={InputSelect}
                options={combos.operationTypeCombo}
                valueKey="id"
                labelKey="value"
              />)}
              {this.state.fieldsConfiguration.operationTypeDMSIds && this.state.fieldsConfiguration.operationTypeDMSIds.permission !== permissions.hidden && (<Field
                colSm={4}
                id="operationTypeDMSId"
                name="salesComponent.operationTypeDMSId"
                controlLabel={t(`${tKey}OPERATION_TYPE_DMS`)}
                placeholder={t(`${tKey}OPERATION_TYPE_DMS`)}
                valueKey="id"
                labelKey="description"
                component={InputSelect}
                menuContainerStyle={{ zIndex: 999 }}
                options={combos.operationTypeDMSCombo}
                disabled={true}
              />)}
              {this.state.fieldsConfiguration.additionalInfoIds && this.state.fieldsConfiguration.additionalInfoIds.permission !== permissions.hidden && (<Field
                colSm={4}
                controlLabel={t(`${tKey}ADDITIONAL_INFO`)}
                id="additionalInfoIds"
                name="salesComponent.additionalInfoIds"
                multi
                menuContainerStyle={{ zIndex: 999 }}
                disabled={true}
                placeholder={t(`${tKey}ADDITIONAL_INFO`)}
                component={InputSelect}
                options={combos.additionalInfoCombo}
                valueKey="id"
                labelKey="value"
              />)}
              {this.state.fieldsConfiguration.expectedDeliveryDate && this.state.fieldsConfiguration.expectedDeliveryDate.permission !== permissions.hidden && (<Field
                id="expectedDeliveryDate"
                name="salesComponent.expectedDeliveryDate"
                colSm={4}
                controlLabel={t(`${tKey}EXPECTED_DELIVERY_DATE`)}
                disabled
                placeholder={t(`${tKey}EXPECTED_DELIVERY_DATE`)}
                component={InputText}
              />)}
              {this.state.fieldsConfiguration.orderDate && this.state.fieldsConfiguration.orderDate.permission !== permissions.hidden && (<Field
                id="orderDate"
                name="salesComponent.orderDate"
                colSm={4}
                controlLabel={t(`${tKey}ORDER_DATE`)}
                component={InputDatePicker}
                validate={[this.dateValidation]}
                disabled={true}
              />)}
              {this.state.fieldsConfiguration.paymentMethodId && this.state.fieldsConfiguration.paymentMethodId.permission !== permissions.hidden && (<Field
                id="paymentMethodId"
                name="salesComponent.paymentMethodId"
                colSm={4}
                controlLabel={t(`${tKey}PAYMENT_FORM`)}
                placeholder={t(`${tKey}PAYMENT_FORM`)}
                component={InputSelect}
                options={combos.paymentMethodCombo}
                valueKey="id"
                labelKey="value"
                disabled={true}
              />)}
              {this.state.fieldsConfiguration.rentalOrderNumber && this.state.fieldsConfiguration.rentalOrderNumber.permission !== permissions.hidden && (<Field
                id="rentalOrderNumber"
                name="salesComponent.rentalOrderNumber"
                colSm={4}
                controlLabel={t(`${tKey}RENTALORDER_NUMBER`)}
                disabled={true}
                placeholder={t(`${tKey}RENTALORDER_NUMBER`)}
                component={InputText}
              />)}
              {this.state.fieldsConfiguration.vehicleTypeId.permission !== permissions.hidden && (<Field
                colSm={4}
                id="vehicleTypeId"
                name="dossierCompleteOtherDataComponent.vehicleTypeId"
                controlLabel={t(`${tKey}VEHICLE_TYPE`)}
                placeholder={t(`${tKey}VEHICLE_TYPE`)}
                component={InputSelect}
                options={combos.vehicleType}
                disabled={true}
                valueKey="id"
                labelKey="value"
              />)}
              {this.state.fieldsConfiguration.proposalPercDiscount && this.state.fieldsConfiguration.proposalPercDiscount.permission !== permissions.hidden && (<Field
                id="proposalPercDiscount"
                name="salesComponent.proposalPercDiscount"
                colSm={4}
                controlLabel={t(`${tKey}PROPOSAL_PERC_DISCOUNT`)}
                placeholder={t(`${tKey}PROPOSAL_PERC_DISCOUNT`)}
                component={InputPercentage}
                disabled={true}
              />)}
              {this.state.fieldsConfiguration.orderPercDiscount && this.state.fieldsConfiguration.orderPercDiscount.permission !== permissions.hidden && (<Field
                id="orderPercDiscount"
                name="salesComponent.orderPercDiscount"
                colSm={4}
                controlLabel={t(`${tKey}ORDER_PERC_DISCOUNT`)}
                placeholder={t(`${tKey}ORDER_PERC_DISCOUNT`)}
                component={InputPercentage}
                disabled={true}
              />)}
              {this.state.fieldsConfiguration.homologationClassificationId && this.state.fieldsConfiguration.homologationClassificationId.permission !== permissions.hidden && this.state.homologationRequired && (<Field
                colSm={4}
                id="homologationClassificationId"
                name="salesComponent.homologationClassificationId"
                controlLabel={t(`${tKey}HOMOLOGATION_CLASSIFICATION`)}
                placeholder={t(`${tKey}HOMOLOGATION_CLASSIFICATION`)}
                valueKey="id"
                labelKey="value"
                component={InputSelect}
                options={combos.homologationClassificationCombo}
                disabled={true}
              />)}
              {this.state.fieldsConfiguration.homologationProducerId && this.state.fieldsConfiguration.homologationProducerId.permission !== permissions.hidden && this.state.homologationClassificationId &&
              this.state.homologationClassificationId === homologationClassification.REFORMA && this.state.homologationRequired && (<Field
                colSm={4}
                id="homologationProducerId"
                name="salesComponent.homologationProducerId"
                controlLabel={t(`${tKey}HOMOLOGATION_PRODUCER`)}
                placeholder={t(`${tKey}HOMOLOGATION_PRODUCER`)}
                valueKey="id"
                labelKey="value"
                component={InputSelect}
                options={combos.homologationProducerCombo}
                disabled={true}
              />)}
              {this.state.depositVehicleDisabled && this.state.fieldsConfiguration.despositDeliveryDate.permission !== permissions.hidden && (<Field
                colSm={4}
                id="despositDeliveryDate"
                name="salesComponent.despositDeliveryDate"
                controlLabel={t(`${tKey}DELIVERY_DATE_DEPOSIT`)}
                placeholder={t(`${tKey}DELIVERY_DATE_DEPOSIT`)}
                component={InputDatePicker}
                disabled={true}
                validate={[this.dateValidation]}
              />)}
              {this.state.depositVehicleDisabled && this.state.fieldsConfiguration.depositLicensePlate.permission !== permissions.hidden && (<Field
                colSm={4}
                id="depositLicensePlate"
                name="salesComponent.depositLicensePlate"
                controlLabel={t(`${tKey}CAR_NUMBER_PLATE_DEPOSIT`)}
                placeholder={t(`${tKey}CAR_NUMBER_PLATE_DEPOSIT`)}
                valueKey="id"
                labelKey="value"
                component={InputText}
                disabled={true}
              />)}
              {this.state.depositVehicleDisabled && this.state.fieldsConfiguration.depositKm.permission !== permissions.hidden && (<Field
                colSm={4}
                id="depositKm"
                name="salesComponent.depositKm"
                controlLabel={t(`${tKey}KM_DEPOSIT`)}
                placeholder={t(`${tKey}KM_DEPOSIT`)}
                valueKey="id"
                labelKey="value"
                component={InputText}
                disabled={true}
              />)}
              {this.state.fieldsConfiguration.productionWeek.permission !== permissions.hidden && this.props.dossier.dossierSubTypeId !== dossierSubTypeId.venta_vo && (<Field
                colSm={4}
                id="productionWeek"
                name="salesComponent.productionWeek"
                controlLabel={t(`${tKey}PRODUCTION_WEEK`)}
                placeholder={t(`${tKey}PRODUCTION_WEEK`)}
                valueKey="id"
                labelKey="value"
                component={InputNumber}
                disabled={true}
              />)}
              {this.state.fieldsConfiguration.monthsGuaranteeExtension.permission !== permissions.hidden && (<Field
                colSm={4}
                id="monthsGuaranteeExtension"
                name="salesComponent.monthsGuaranteeExtension"
                controlLabel={t(`${tKey}MONTH_GUARANTEE_EXTENSION`)}
                placeholder={t(`${tKey}MONTH_GUARANTEE_EXTENSION`)}
                valueKey="id"
                labelKey="value"
                component={InputNumber}
                disabled={true}
              />)}
              {this.state.fieldsConfiguration.arriveVehicle.permission !== permissions.hidden && this.props.dossier.salesComponent.deliveryDestination && (<Field
                id="arriveVehicle"
                controlLabel={t(`${tKey}ARRIVE_VEHICLE`)}
                name="salesComponent.arriveVehicle"
                key="salesComponent.arriveVehicle"
                placeholder={t(`${tKey}ARRIVE_VEHICLE`)}
                component={InputText}
                colSm={4}
                disabled={true}
              />)}
              {this.state.fieldsConfiguration.serviceDestinationId.permission !== permissions.hidden &&
              <Field
                colSm={4}
                id="serviceDestinationId"
                name="salesComponent.serviceDestinationId"
                controlLabel={t(`${tKey}SERVICE_DESTINATION`)}
                placeholder={t(`${tKey}SERVICE_DESTINATION`)}
                component={InputSelect}
                options={combos.serviceDestinationCombo}
                valueKey="id"
                labelKey="value"
                disabled={true}
              />
              }
              {this.state.fieldsConfiguration.billingNumber && this.state.fieldsConfiguration.billingNumber.permission !== permissions.hidden && (<Field
                colSm={4}
                id="billingNumber"
                name="salesComponent.billingNumber"
                controlLabel={t(`${tKey}BILLING_NUMBER`)}
                placeholder={t(`${tKey}BILLING_NUMBER`)}
                component={InputText}
                disabled={true}
              />)}
              {this.state.fieldsConfiguration.billingDate && this.state.fieldsConfiguration.billingDate.permission !== permissions.hidden && (<Field
                colSm={4}
                id="billingDate"
                name="salesComponent.billingDate"
                controlLabel={t(`${tKey}BILLING_DATE`)}
                placeholder={t(`${tKey}BILLING_DATE`)}
                component={InputDatePicker}
                disabled={true}
              />)}
              {this.state.fieldsConfiguration.satPaymentMethod && this.state.fieldsConfiguration.satPaymentMethod.permission !== permissions.hidden && (<Field
                colSm={4}
                id="satPaymentMethodId"
                name="salesComponent.satPaymentMethodId"
                controlLabel={t(`${tKey}PAYMENT_METHOD`)}
                placeholder={t(`${tKey}PAYMENT_METHOD`)}
                valueKey="id"
                labelKey="value"
                component={InputSelect}
                options={combos.satPaymentMethod}
                disabled={true}
              />)}
              {this.state.fieldsConfiguration.fleetCode && this.state.fieldsConfiguration.fleetCode.permission !== permissions.hidden && (<Field
                colSm={4}
                id="fleetCode"
                name="salesComponent.fleetCode"
                controlLabel={t(`${tKey}FLEET_NUMBER`)}
                placeholder={t(`${tKey}FLEET_NUMBER`)}
                component={InputText}
                disabled={true}
              />)}
              {this.state.fieldsConfiguration.invoiceNumber && this.state.fieldsConfiguration.invoiceNumber.permission !== permissions.hidden && (<Field
                colSm={4}
                id="invoiceNumber"
                name="salesComponent.invoiceNumber"
                controlLabel={t(`${tKey}INVOICE_NUMBER`)}
                placeholder={t(`${tKey}INVOICE_NUMBER`)}
                component={InputNumber}
                disabled={true}
              />)}
              {this.state.fieldsConfiguration.invoiceDate && this.state.fieldsConfiguration.invoiceDate.permission !== permissions.hidden && (<Field
                colSm={4}
                id="invoiceDate"
                name="salesComponent.invoiceDate"
                controlLabel={t(`${tKey}INVOICE_DATE`)}
                placeholder={t(`${tKey}INVOICE_DATE`)}
                component={InputDatePicker}
                disabled={true}
              />)}
              {this.state.fieldsConfiguration.brandDischargeDate && this.state.fieldsConfiguration.brandDischargeDate.permission !== permissions.hidden && (
                <Field
                  colSm={4}
                  id="brandDischargeDate"
                  name="salesComponent.brandDischargeDate"
                  controlLabel={t(`${tKey}BRAND_DISCHARGE_DATE`)}
                  placeholder={t(`${tKey}BRAND_DISCHARGE_DATE`)}
                  component={InputDatePicker}
                  disabled={true}
                />)}
              {this.state.fieldsConfiguration.orderExternalId && this.state.fieldsConfiguration.orderExternalId.permission !== permissions.hidden && (<Field
                colSm={4}
                id="orderExternalId"
                name="salesComponent.orderExternalId"
                controlLabel={t(`${tKey}EXTERNAL_ORDER_ID`)}
                placeholder={t(`${tKey}EXTERNAL_ORDER_ID`)}
                valueKey="id"
                labelKey="value"
                component={InputText}
                maxLength={20}
                disabled={true}
              />)}
              {this.state.fieldsConfiguration.currency && this.state.fieldsConfiguration.currency.permission !== permissions.hidden && (<Field
                colSm={4}
                id="currencyId"
                name="salesComponent.currencyId"
                controlLabel={t(`${tKey}CURRENCY_ID`)}
                placeholder={t(`${tKey}CURRENCY_ID`)}
                valueKey="id"
                labelKey="value"
                component={InputSelect}
                options={combos.currencyCombo}
                disabled={true}
              />)}
              {this.state.fieldsConfiguration.purchaseState && this.state.fieldsConfiguration.purchaseState.permission !== permissions.hidden && (<Field
                colSm={4}
                id="purchaseState"
                name="salesComponent.purchaseState"
                controlLabel={t(`${tKey}PURCHASE_STATE`)}
                placeholder={t(`${tKey}PURCHASE_STATE`)}
                valueKey="id"
                labelKey="value"
                component={InputText}
                disabled={true}
              />)}
              {this.state.fieldsConfiguration.customerNeedDate && this.state.fieldsConfiguration.customerNeedDate.permission !== permissions.hidden && (
                <Field
                  colSm={4}
                  id="customerNeedDate"
                  name="salesComponent.customerNeedDate"
                  controlLabel={t(`${tKey}CUSTOMER_NEED_DATE`)}
                  placeholder={t(`${tKey}CUSTOMER_NEED_DATE`)}
                  component={InputDatePicker}
                  disabled={true}
                />)}
              {this.state.fieldsConfiguration.shippingOrderDate && this.state.fieldsConfiguration.shippingOrderDate.permission !== permissions.hidden && (
                <Field
                  colSm={4}
                  id="shippingOrderDate"
                  name="salesComponent.shippingOrderDate"
                  controlLabel={t(`${tKey}SHIPPING_ORDER_DATE`)}
                  placeholder={t(`${tKey}SHIPPING_ORDER_DATE`)}
                  component={InputDatePicker}
                  disabled={true}
                />)}
              {this.state.fieldsConfiguration.campaignOutputDate && this.state.fieldsConfiguration.campaignOutputDate.permission !== permissions.hidden && (
                <Field
                  colSm={4}
                  id="campaignOutputDate"
                  name="salesComponent.campaignOutputDate"
                  controlLabel={t(`${tKey}CAMPAIGN_OUTPUT_DATE`)}
                  placeholder={t(`${tKey}CAMPAIGN_OUTPUT_DATE`)}
                  component={InputDatePicker}
                  disabled={true}
                />)}
              {this.state.fieldsConfiguration.destinationArrivalDate && this.state.fieldsConfiguration.destinationArrivalDate.permission !== permissions.hidden && (
                <Field
                  colSm={4}
                  id="destinationArrivalDate"
                  name="salesComponent.destinationArrivalDate"
                  controlLabel={t(`${tKey}DESTINATION_ARRIVAL_DATE`)}
                  placeholder={t(`${tKey}DESTINATION_ARRIVAL_DATE`)}
                  component={InputDatePicker}
                  disabled={true}
                />)}
            </Row>
            <Row>
              {this.state.fieldsConfiguration.pickPreviousVehicle && this.state.fieldsConfiguration.pickPreviousVehicle.permission !== permissions.hidden && (<Field
                colSm={4}
                id="pickPreviousVehicle"
                name="salesComponent.pickPreviousVehicle"
                placeholder={t(`${tKey}PREVIUOS_VEHICLE`)}
                inline
                component={InputCheckBox}
                disabled={true}
              />)}
              {this.state.fieldsConfiguration.signalPayNotRequired && this.state.fieldsConfiguration.signalPayNotRequired.permission !== permissions.hidden && (<Field
                name="salesComponent.signalPayNotRequired"
                placeholder={t(`${tKey}SIGNAL_PAY_NOT_REQUIRED`)}
                component={InputCheckBox}
                colSm={4}
                inline
                disabled={true}
              />)}
              {this.state.fieldsConfiguration.LOPDNotRequired && this.state.fieldsConfiguration.LOPDNotRequired.permission !== permissions.hidden && (<Field
                name="salesComponent.lopdNotRequired"
                placeholder={t(`${tKey}LOPD_NOT_REQUIRED`)}
                component={InputCheckBox}
                colSm={4}
                inline
                disabled={true}
              />)}
              {this.state.fieldsConfiguration.notMultipleInvoices.permission !== permissions.hidden && (<Field
                colSm={4}
                id="notMultipleInvoices"
                name="salesComponent.notMultipleInvoices"
                placeholder={t(`${tKey}NOT_MULTIPLES_INVOICES`)}
                inline
                component={InputCheckBox}
                disabled={true}
              />)}
              {this.state.fieldsConfiguration.assignmentSheet.permission !== permissions.hidden && (<Field
                colSm={4}
                id="assignmentSheet"
                name="salesComponent.assignmentSheet"
                placeholder={t(`${tKey}NOT_ASSIGMENT_SHEET`)}
                inline
                component={InputCheckBox}
                disabled={true}
              />)}
              {this.state.fieldsConfiguration.warrantyExtensionNotRequired.permission !== permissions.hidden && (<Field
                colSm={4}
                id="warrantyExtensionNotRequired"
                name="salesComponent.warrantyExtensionNotRequired"
                placeholder={t(`${tKey}NOT_WARRANTY_EXTENSION`)}
                inline
                component={InputCheckBox}
                disabled={true}
              />)}
              {this.state.fieldsConfiguration.deliveryDestination.permission !== permissions.hidden && (<Field
                id="deliveryDestination"
                name="salesComponent.deliveryDestination"
                placeholder={t(`${tKey}DELIVER_DESTINATION`)}
                component={InputCheckBox}
                inline
                colSm={4}
                disabled={true}
              />)}
              {this.state.fieldsConfiguration.dossierWithDeposit && this.state.fieldsConfiguration.dossierWithDeposit.permission !== permissions.hidden && (<Field
                colSm={4}
                id="dossierWithDeposit"
                name="salesComponent.dossierWithDeposit"
                controlLabel={t(`${tKey}VEHICLE_IN_DEPOSIT`)}
                placeholder={t(`${tKey}VEHICLE_IN_DEPOSIT`)}
                inline
                component={InputCheckBox}
                disabled={true}
              />)}
              {this.state.fieldsConfiguration.homologationRequired.permission !== permissions.hidden && (<Field
                colSm={4}
                id="homologationRequired"
                name="salesComponent.homologationRequired"
                controlLabel={t(`${tKey}HOMOLOGATION_REQUIRED`)}
                placeholder={t(`${tKey}HOMOLOGATION_REQUIRED`)}
                value={true}
                inline
                component={InputCheckBox}
                disabled={true}
              />)}
              {this.state.fieldsConfiguration.authSaleDepartamentNotRequired && this.state.fieldsConfiguration.authSaleDepartamentNotRequired.permission !== permissions.hidden && (<Field
                colSm={4}
                id="authSaleDepartamentNotRequired"
                name="salesComponent.authSaleDepartamentNotRequired"
                controlLabel={t(`${tKey}AUTH_SALE_NOT_REQUIRED`)}
                placeholder={t(`${tKey}AUTH_SALE_NOT_REQUIRED`)}
                inline
                component={InputCheckBox}
                disabled={true}
              />)}
              {this.state.fieldsConfiguration.transformation && this.state.fieldsConfiguration.transformation.permission !== permissions.hidden && (<Field
                colSm={4}
                id="transformation"
                name="salesComponent.transformation"
                controlLabel={t(`${tKey}TRANSFORMATION`)}
                placeholder={t(`${tKey}TRANSFORMATION`)}
                inline
                component={InputCheckBox}
                disabled={true}
              />)}
              {this.state.fieldsConfiguration.accesories && this.state.fieldsConfiguration.accesories.permission !== permissions.hidden && (<Field
                colSm={4}
                id="accesories"
                name="salesComponent.accesories"
                controlLabel={t(`${tKey}ACCESORIES`)}
                placeholder={t(`${tKey}ACCESORIES`)}
                inline
                component={InputCheckBox}
                disabled={true}
              />)}
              {this.state.fieldsConfiguration.firstYearInsurance && this.state.fieldsConfiguration.firstYearInsurance.permission !== permissions.hidden && (<Field
                colSm={4}
                id="firstYearInsurance"
                name="salesComponent.firstYearInsurance"
                controlLabel={t(`${tKey}FIRST_YEAR_INSURANCE`)}
                placeholder={t(`${tKey}FIRST_YEAR_INSURANCE`)}
                inline
                component={InputCheckBox}
                disabled={true}
              />)}
              {this.state.fieldsConfiguration.multipleChoice && this.state.fieldsConfiguration.multipleChoice.permission !== permissions.hidden && (<Field
                colSm={4}
                id="multipleChoice"
                name="salesComponent.multipleChoice"
                controlLabel={t(`${tKey}MULTIPLE_CHOICE`)}
                placeholder={t(`${tKey}MULTIPLE_CHOICE`)}
                inline
                component={InputCheckBox}
                disabled={true}
              />)}
              {this.state.fieldsConfiguration.campaignDocumentNotRequired && this.state.fieldsConfiguration.campaignDocumentNotRequired.permission !== permissions.hidden && (<Field
                colSm={4}
                id="campaignDocumentNotRequired"
                name="salesComponent.campaignDocumentNotRequired"
                controlLabel={t(`${tKey}CAMPAIGN_DOCUMENT_NOT_REQUIRED`)}
                placeholder={t(`${tKey}CAMPAIGN_DOCUMENT_NOT_REQUIRED`)}
                inline
                component={InputCheckBox}
                disabled={true}
              />)}
              {this.state.fieldsConfiguration.maintenanceInclude && this.state.fieldsConfiguration.maintenanceInclude.permission !== permissions.hidden && (<Field
                colSm={4}
                id="maintenanceInclude"
                name="salesComponent.maintenanceInclude"
                controlLabel={t(`${tKey}MAINTENANCE_INCLUDE`)}
                placeholder={t(`${tKey}MAINTENANCE_INCLUDE`)}
                inline
                component={InputCheckBox}
                disabled={true}
              />)}
              {this.state.fieldsConfiguration.reportableLLD && this.state.fieldsConfiguration.reportableLLD.permission !== permissions.hidden && (<Field
                colSm={4}
                id="reportableLLD"
                name="salesComponent.reportableLLD"
                placeholder={t(`${tKey}REPORTABLE_LLD`)}
                inline
                component={InputCheckBox}
                disabled={true}
              />)}
              {this.state.fieldsConfiguration.notLoJack && this.state.fieldsConfiguration.notLoJack.permission !== permissions.hidden && (<Field
                colSm={4}
                id="notLoJack"
                name="salesComponent.notLoJack"
                placeholder={t(`${tKey}NOT_LOJACK`)}
                inline
                component={InputCheckBox}
                disabled={true}
              />)}
              {this.state.fieldsConfiguration.checkAutoWarranty && this.state.fieldsConfiguration.checkAutoWarranty.permission !== permissions.hidden && (<Field
                colSm={4}
                id="checkAutoWarranty"
                name="salesComponent.checkAutoWarranty"
                placeholder={t(`${tKey}NOT_AUTOWARRANTY`)}
                inline
                component={InputCheckBox}
                disabled={true}
              />)}
            </Row>
            <Row className="salesBtnRow">
            </Row>
            <CustomFields customFields={customFields} readOnlyFields={true} />
          </Panel.Collapse>
        </Panel>
      </Row>

    )
  }
}

export default SalesComponent
