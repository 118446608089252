import { put, call, all, select, takeEvery } from 'redux-saga/effects'
import { change, reset, initialize } from 'redux-form'
// import { replace } from 'react-router-redux'
import { replace } from 'connected-react-router'
import { permissions, entityTypesId, recurrentEntitySubTypes, genericRfc } from '../../constants/backendIds'
import { documentEntityType as documentEntityTypeConstant } from '../../constants/dossier/common/documentEntityType'
import { dossierSubTypeId } from '../../constants/dossier/common/dossierSubType'
import { buttonsHeader, buttonsHeaderPermisionsIdReverse, buttonsHeaderPermisions } from '../../constants/dossier/common/buttonsHeader'
import dossierActionsTypes from '../../constants/actions/dossier/dossier'
import { getAuth } from '../../selectors/access/auth'
import { getActiveTab } from '../../selectors/dossier/common/buttonsHeader'
import { getDossierRelatedDossiersConf } from '../../selectors/dossier/common/dossierRelatedDossiers'
import { showLoader, hideLoader } from '../../actions/common'
import { refreshDocumentManagementModal } from '../../actions/dossier/common/documentManagementModal'
import { fetchSalesDossierSuccess, fetchSalesDossierEconomicSucces, fetchSalesServicesSuccess, fetchSalesSelfSupplySuccess } from '../../actions/dossier/sales'
import { fetchCombosSuccess } from '../../actions/combos/combos'
import messageModalActions from '../../actions/modals/messageModal'
import { setActiveTab } from '../../actions/dossier/common/buttonsHeader'
import getDossierById from '../../services/dossier/get/getSaleDossierById'
import getHistoricalDossierById from '../../services/dossier/get/getSaleHistoricalDossierById'
import getDossierDependantsById from '../../services/dossier/get/getSaleDossierDependantsById'
import getSaleHistoricalDossierDependantsById from '../../services/dossier/get/getSaleHistoricalDossierDependantsById'
import getSaleHistoricalDossierDependantsEconomicById from '../../services/dossier/get/getSaleHistoricalDossierDependantsEconomicById'
// Header Component
import getStageCheckList from '../../services/dossier/get/getStageCheckList'
// Sales component
import getSaleTypes from '../../services/saleType/getSalesTypes'
import getExtraSaleTypes from '../../services/extraSale/getExtraSaleTypes'
import getPresentTypes from '../../services/present/getPresentTypes'
import getAssociatedCostsTypes from '../../services/associatedCost/getAssociatedCostsTypes'
import getSuppliedTypes from '../../services/supplied/getSuppliedTypes'
import getOperationTypes from '../../services/saleType/getOperationType'
import getSalesman from '../../services/salesman/getSalesman'
import getSalesmanLogic from '../../services/salesman/getSalesmanLogic'
import getPaymentMethod from '../../services/paymentMethod/getPaymentMethod'
import getSatPaymentMethod from '../../services/satPaymentMethod/getSatPaymentMethod'
import getDocumentaryCheckList from '../../services/document/getDocumentaryCheckList'
import getSaleHeaderAndButtons from '../../services/dossier/get/getSaleHeaderAndButtons'
import getAdditionalInfo from '../../services/additionalInfo/getAdditionalInfo'
// Client component
import getEntityTypes from '../../services/entityType/getEntityTypes'
import getChannels from '../../services/sourcechannel/getChanel'
import getSegmentation from '../../services/segmentation/getSegmentation'
import getDossierEntityService from '../../services/dossier/get/getDossierEntity'
import getDossierEntityChildService from '../../services/dossier/get/getDossierEntityChild'
import getDossierHistoricalEntity from '../../services/dossier/get/getDossierHistoricalEntity'
import getDossierHistoricalEntityChild from '../../services/dossier/get/getDossierHistoricalEntityChild'
import getEntityById from '../../services/entity/getEntityById'
import getEntitySubType from '../../services/entitySubType/getEntitySubType'
import getEntityFilterSubType from '../../services/entity/getEntityFilterSubType'
import { fetchEntitySubTypeCombo } from '../../sagas/combos/fetchEntitySubTypeCombo'
import { fetchEntityFilterSubTypeCombo } from '../../sagas/combos/fetchEntityFilterSubTypeCombo'
// Vehicle component
import getBrands from '../../services/brand/getBrands'
import getFuels from '../../services/fuel/getFuels'
import getPreviousUsage from '../../services/previousUsage/getPreviousUsage'
import getPurchaseWithVehicleById from '../../services/dossier/get/getPurchaseWithVehicleById'
import getHistoricalPurchaseWithVehicleById from '../../services/dossier/get/getHistoricalPurchaseWithVehicleById'
import getFinancingTypeProduct from '../../services/financigTypeProduct/getFinancingTypeProduct'
import getModelCombo from '../../services/model/getModelCombo'
import getCarlineCombo from '../../services/carline/getCarlineCombo'
import getCatalogueCombo from '../../services/catalogue/getCatalogueCombo'
import getColorCombo from '../../services/color/getColorCombo'
import getTapestryCombo from '../../services/tapestry/getTapestryCombo'
import getCatalogueModel from '../../services/catalogueModel/getCatalogueModel'

// Services component
// import getServiceType from '../../services/serviceType/getServiceType'
// Dossier type subtipe
import getDossierSubTypeByTypeAndUo from '../../services/dossierType/getDossierSubTypeByTypeAndUo'
// Finance component
import getFinanceCompaniesService from '../../services/financeCompany/getFinanceCompanies'
import getFinancingTypesService from '../../services/financingType/getFinancingTypes'
import getFinancingStatusService from '../../services/financingStatus/getFinancingStatus'
// Related component
import getDossierRelatedDossiers from '../../services/dossier/get/getDossierRelatedDossiers'
import getDossierHistoricalRelatedDossiers from '../../services/dossier/get/getDossierHistoricalRelatedDossiers'
// EconomicPlan
import getSaleEconomicPlan from '../../services/dossier/get/getSaleEconomicPlan'
import getSaleEconomicPlanHistorical from '../../services/dossier/get/getSaleEconomicPlanHistorical'
// Iva Type
import getIvaType from '../../services/ivaType/getIvaType'
// Iem Type
import getIemType from '../../services/iemType/getIemType'
// LaterUse
import getLaterUse from '../../services/laterUse/getLaterUse'
// ActivityClient
import getActivityClient from '../../services/activityClient/getActivityClient'
// ActivityBuyer
import getActivityBuyer from '../../services/activityBuyer/getActivityBuyer'
// vehicleType
import getVehicleType from '../../services/vehicleType/getVehicleType'
import { handleError } from '../errors/errorManager'
// SaleRequestModal
import getPurchaseTypesByDossierSubType from '../../services/purchaseType/getPurchaseTypesByDossierSubType'
import getTuneUpDestination from '../../services/tuneUpDestination/getTuneUpDestination'
// Contact
import getDossierContact from '../../services/dossier/get/getDossierContact'
import getDossierContactHistorical from '../../services/dossier/get/getDossierContactHistorical'
// InsuranceCarrier
import getInsuranceCarriers from '../../services/insuranceCarrier/getInsuranceCarriers'
import getDossierInsuranceCarrier from '../../services/dossier/get/getDossierInsuranceCarrier'
import getDossierInsuranceCarrierHistorical from '../../services/dossier/get/getDossierInsuranceCarrierHistorical'
// Financing
import getDossierFinancing from '../../services/dossier/get/getDossierFinancing'
import getDossierFinancingHistorical from '../../services/dossier/get/getDossierFinancingHistorical'
// Delivery
import getDossierDelivery from '../../services/dossier/get/getDossierDelivery'
import getDossierDeliveryHistorical from '../../services/dossier/get/getDossierDeliveryHistorical'

// Other data
import getDossierOtherData from '../../services/dossier/get/getDossierOtherData'
import getDossierOtherDataHistorical from '../../services/dossier/get/getDossierOtherDataHistorical'
import getSaleTypesByUo from '../../services/saleType/getSaleTypesByUo'
import getSaleServiceDossiers from '../../services/dossier/get/getSaleServiceDossiers'
import getSaleServiceDossiersHistorical from '../../services/dossier/get/getSaleServiceDossiersHistorical'
import getDossierSelfSupply from '../../services/selfSupply/getDossierSelfSupply'
import getDossierSelfSupplyHistorical from '../../services/selfSupply/getDossierSelfSupplyHistorical'
import getDepartmentsCombo from '../../services/department/getDepartmentsCombo'
import getSelfSupplyOrganizedUnites from '../../services/selfSupply/getSelfSupplyOrganizedUnites'
import { buttonsSidebar } from '../../constants/dossier/common/buttonsSidebar'
import { yesNoModal } from '../modalsListeners/yesNoModal'

import { getFinancingTypeProductByFinancingTypeSuccess } from '../../actions/masters/masters'
import getServiceDestinationTypes from '../../services/serviceDestination/getServiceDestinationTypes'
import getCurrencies from '../../services/dossier/get/getCurrencyCombo'
import getOperationTypeDMSAll from '../../services/operationTypeDMS/getOperationTypeDMSAll'
import getMaritalStatus from '../../services/maritalStatus/getMaritalStatus'
import { switchFavoriteSuccess } from '../../actions/dossier/common/statusHeader'

export function * fetchDependantsSaleDossier ({ dossierId, historical }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    let dependants = {}
    if (historical) {
      dependants = yield call(getSaleHistoricalDossierDependantsById, dossierId, auth.token)
      let dossierEconomicComponent = dependants.dossierEconomicComponent
      dependants.dossierEconomicComponent = { campaigns: [], discounts: [], extraSales: [], optionals: [], presents: [], associatedCosts: [], specialHelps: [], supplieds: [], paperworks: [], iemData: {} }
      dossierEconomicComponent = dependants.dossierEconomicComponent
      yield put(change('salesDossier', 'dossierEconomicComponent', dossierEconomicComponent))
      yield put(fetchSalesDossierSuccess(dependants))
    } else {
      dependants = yield call(getDossierDependantsById, dossierId, auth.token)
      yield put(fetchSalesDossierSuccess(dependants))
    }
  } catch (error) {
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}
export function * watchFetchSalesDossierDependants () {
  yield takeEvery(dossierActionsTypes.FETCH_SALES_DOSSIER_DEPENDANTS, fetchDependantsSaleDossier)
}

export function * fetchDependantsEconomicSaleDossier ({ dossierId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    let dependants = {}
    dependants = yield call(getSaleHistoricalDossierDependantsEconomicById, dossierId, auth.token)
    const dossierEconomicComponent = dependants.dossierEconomicComponent
    yield put(change('salesDossier', 'dossierEconomicComponent', dossierEconomicComponent))
    yield put(fetchSalesDossierSuccess(dependants, dossierEconomicComponent))
  } catch (error) {
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}
export function * watchFetchSalesDossierDependantsEconomic () {
  yield takeEvery(dossierActionsTypes.FETCH_SALES_DOSSIER_DEPENDANTS_ECONOMIC, fetchDependantsEconomicSaleDossier)
}

function * fetchSaleDossierHistorical (dossierId, auth) {
  const dossierRelatedDossiersConf = yield select(getDossierRelatedDossiersConf)
  const salesDossier = JSON.parse(JSON.stringify(JSON.parse(JSON.stringify(yield select(state => state.salesDossier)))))
  const [
    dossier,
    entityChildComponent,
    paymentMethodCombo,
    channelCombo,
    maritalStatusCombo,
    segmentationCombo,
    brandCombo,
    fuelCombo,
    dossierRelatedDossiers,
    satPaymentMethod,
    currencyCombo,
    modelCombo
  ] = yield all([
    call(getHistoricalDossierById, dossierId, auth.token),
    call(getDossierHistoricalEntityChild, dossierId, auth.token),
    call(getPaymentMethod, auth.token, true),
    call(getChannels, auth.token),
    call(getMaritalStatus, auth.token),
    call(getSegmentation, auth.token),
    call(getBrands, auth.token),
    call(getFuels, auth.token, true),
    call(getDossierHistoricalRelatedDossiers, dossierId, dossierRelatedDossiersConf.orderby, auth.token),
    call(getSatPaymentMethod, auth.token, true),
    call(getCurrencies, auth.token),
    call(getModelCombo, auth.token)
  ])
  const entityTypeCombo = yield call(getEntityTypes, auth.token, dossier.dossierSubTypeId)

  const dossierFinancingComponent = yield call(getDossierFinancingHistorical, dossierId, auth.token)

  let entityComponent = salesDossier.entityComponent
  entityComponent = { ...entityComponent, ...dossier.dossierEntityComponent }
  entityChildComponent.previousEntity = { ...entityChildComponent }
  const [
    saleTypeCombo, salesmanCombo, salesmanComboLogic, previousUsageCombo, financialInstitutionCombo, serviceTypeCombo, financingTypeCombo, financingStatusCombo, ivaTypeCombo, iemType,
    extraSaleTypeCombo, presentTypeCombo, suppliedTypeCombo, laterUseCombo, activityClientCombo, activityBuyerCombo,
    vehicleType, additionalInfoCombo, entityFilterSubType, entitySubTypeCombo, tuneUpDestinationCombo, associatedCostTypeCombo,
    departmentCombo, purchaseTypeVnCombo, entityTransferCombo, serviceDestinationCombo, insuranceCarrierCombo, operationTypeDMSCombo
  ] = yield all([
    call(getSaleTypesByUo, dossier.organizedUnitId, dossier.dossierSubTypeId, auth.token, true),
    call(getSalesman, dossier.organizedUnitId, auth.token),
    call(getSalesmanLogic, dossier.organizedUnitId, auth.token),
    call(getPreviousUsage, dossier.organizedUnitId, auth.token),
    call(getFinanceCompaniesService, dossier.organizedUnitId, auth.token, true),
    call(getDossierSubTypeByTypeAndUo, dossierId, dossier.organizedUnitId, auth.token),
    call(getFinancingTypesService, dossierFinancingComponent.companyId, auth.token, true, dossierFinancingComponent.typeId),
    call(getFinancingStatusService, auth.token),
    call(getIvaType, auth.token, dossier.dossierId, true),
    call(getIemType, auth.token),
    call(getExtraSaleTypes, dossier.organizedUnitId, auth.token),
    call(getPresentTypes, dossier.organizedUnitId, auth.token),
    call(getSuppliedTypes, dossier.organizedUnitId, auth.token),
    call(getLaterUse, auth.token),
    call(getActivityClient, auth.token),
    call(getActivityBuyer, auth.token),
    call(getVehicleType, auth.token),
    call(getAdditionalInfo, auth.token, dossier.dossierSubTypeId),
    call(getEntityFilterSubType, auth.token, entityTypesId.recurrentEntity, recurrentEntitySubTypes.renting, dossier.organizedUnitId),
    call(getEntitySubType, auth.token, entityComponent.entityTypeId),
    call(getTuneUpDestination, auth.token),
    call(getAssociatedCostsTypes, dossier.organizedUnitId, auth.token),
    call(getDepartmentsCombo, dossier.organizedUnitId, auth.token),
    call(getPurchaseTypesByDossierSubType, auth.token, dossierSubTypeId.compra_vn),
    call(getEntityFilterSubType, auth.token, entityTypesId.recurrentEntity, recurrentEntitySubTypes.distributor, dossier.organizedUnitId),
    call(getServiceDestinationTypes, false, dossier.dossierSaleComponent ? dossier.dossierSaleComponent.serviceDestinationId : null, auth.token),
    call(getInsuranceCarriers, auth.token, dossier.organizedUnitId),
    call(getOperationTypeDMSAll, auth.token)
  ])

  let operationTypeCombo = []
  if (dossier.dossierSaleComponent && dossier.dossierSaleComponent.saleTypeId) {
    operationTypeCombo = yield call(getOperationTypes, dossier.dossierSaleComponent.saleTypeId, dossier.organizedUnitId, auth.token)
  }

  let entityFilterSubTypeCombo
  if (entityComponent.entityTypeId && entityComponent.entitySubTypeId) {
    entityFilterSubTypeCombo = yield call(getEntityFilterSubType, auth.token, entityComponent.entityTypeId, entityComponent.entitySubTypeId, dossier.organizedUnitId)
  }

  const dossierContactComponent = salesDossier.dossierContactComponent

  let vehicleComponent = salesDossier.vehicleComponent
  vehicleComponent = { ...entityComponent, ...dossier.dossierVehicleComponent }
  let dossierCompleteDeliveryComponent = salesDossier.dossierCompleteDeliveryComponent.dossierDeliveryComponent

  const dossierSelfSupplyComponent = { ...dossier.dossierSelfSupplyComponent }
  const dossierPaperworkComponent = dossier.dossierPaperworkComponent

  yield put(switchFavoriteSuccess(dossier.dossierHeaderComponent.favorite))

  yield put(fetchCombosSuccess({
    financialInstitutionCombo,
    insuranceCarrierCombo,
    financingTypeCombo,
    financingStatusCombo,
    saleTypeCombo,
    operationTypeCombo,
    salesmanCombo,
    salesmanComboLogic,
    paymentMethodCombo,
    entityTypeCombo,
    channelCombo,
    maritalStatusCombo,
    segmentationCombo,
    previousUsageCombo,
    brandCombo,
    fuelCombo,
    serviceTypeCombo,
    ivaTypeCombo,
    iemType,
    extraSaleTypeCombo,
    presentTypeCombo,
    suppliedTypeCombo,
    laterUseCombo,
    activityClientCombo,
    activityBuyerCombo,
    vehicleType,
    additionalInfoCombo,
    entityFilterSubType,
    entitySubTypeCombo,
    entityFilterSubTypeCombo,
    tuneUpDestinationCombo,
    associatedCostTypeCombo,
    departmentCombo,
    purchaseTypeVnCombo,
    entityTransferCombo,
    serviceDestinationCombo,
    satPaymentMethod,
    currencyCombo,
    operationTypeDMSCombo,
    modelCombo
  }))

  yield put(fetchSalesDossierSuccess({
    ...dossier,
    checkListHeader: [],
    entityComponent,
    entityChildComponent,
    dossierContactComponent,
    dossierCompleteDeliveryComponent,
    vehicleComponent,
    salesDocumentaryCheckList: [],
    dossierRelatedDossiers,
    dossierSelfSupplyComponent,
    dossierPaperworkComponent
  }))
}
export function * fetchSalesDossier ({ dossierId, resolve, reject, historical }) {
  try {
    yield put(showLoader())
    const salesDossier = JSON.parse(JSON.stringify(yield select(state => state.salesDossier)))
    const auth = yield select(getAuth)
    if (historical) {
      yield call(fetchSaleDossierHistorical, dossierId, auth)
      const dossierFinancingComponent = yield call(getDossierFinancingHistorical, dossierId, auth.token)
      yield put(fetchSalesDossierSuccess({ dossierFinancingComponent }))
    } else {
      const dossierRelatedDossiersConf = yield select(getDossierRelatedDossiersConf)
      const dossier = yield call(getDossierById, dossierId, auth.token)

      const operationTypeIdsSorted = dossier.dossierSaleComponent && dossier.dossierSaleComponent.operationTypeIds ? dossier.dossierSaleComponent.operationTypeIds.sort() : []
      const additionalInfoIdsSorted = dossier.dossierSaleComponent && dossier.dossierSaleComponent.additionalInfoIds ? dossier.dossierSaleComponent.additionalInfoIds.sort() : []
      const dossierSaleComponent = { ...dossier.dossierSaleComponent, operationTypeIds: operationTypeIdsSorted, additionalInfoIds: additionalInfoIdsSorted }
      const dossierFleetComponent = dossier.dossierFleetComponent
      const purchaseSubtypeId = (dossier.dossierSubTypeId ?? dossierSubTypeId.venta_vn) === dossierSubTypeId.venta_vn
        ? dossierSubTypeId.compra_vn : dossierSubTypeId.compra_vo
      const [
        entityChildComponent, checkListHeader, paymentMethodCombo, salesDocumentaryCheckList, entityTypeCombo, channelCombo, maritalStatusCombo, segmentationCombo,
        brandCombo, fuelCombo, dossierRelatedDossiers, entityTransferCombo, tuneUpDestinationCombo, serviceDestinationCombo, satPaymentMethod,
        purchaseTypeComboFromSale, currencyCombo, modelCombo
      ] = yield all([
        call(getDossierEntityChildService, dossierId, auth.token),
        call(getStageCheckList, dossierId, auth.token),
        call(getPaymentMethod, auth.token),
        call(getDocumentaryCheckList, auth.token, dossierId),
        call(getEntityTypes, auth.token, dossier.dossierSubTypeId),
        call(getChannels, auth.token),
        call(getMaritalStatus, auth.token),
        call(getSegmentation, auth.token),
        call(getBrands, auth.token, dossier.dossierSubTypeId, dossier.organizedUnitId),
        call(getFuels, auth.token),
        call(getDossierRelatedDossiers, dossierId, dossierRelatedDossiersConf.orderby, auth.token),
        // call(getAuditInfo, auth.token, dossierId),
        // call(getSaleEconomicPlan, dossierId, auth.token),
        call(getEntityFilterSubType, auth.token, entityTypesId.recurrentEntity, recurrentEntitySubTypes.distributor, dossier.organizedUnitId),
        call(getTuneUpDestination, auth.token),
        call(getServiceDestinationTypes, false, dossier.dossierSaleComponent ? dossier.dossierSaleComponent.serviceDestinationId : null, auth.token),
        call(getSatPaymentMethod, auth.token),
        call(getPurchaseTypesByDossierSubType, auth.token, purchaseSubtypeId),
        call(getCurrencies, auth.token),
        call(getModelCombo, auth.token)
      ])
      // hacer carga aqui de los combos
      let entityComponent = salesDossier.entityComponent
      entityComponent = { ...entityComponent, ...dossier.dossierEntityComponent }
      if (salesDossier.entityComponent.updated === true) {
        entityComponent = yield call(getDossierEntityService, dossierId, auth.token)
        entityComponent.updated = true
        entityComponent.previousEntity = { ...entityComponent }
      }
      entityChildComponent.previousEntity = { ...entityChildComponent }

      let dossierContactComponent = salesDossier.dossierContactComponent
      dossierContactComponent = { ...dossierContactComponent, ...dossier.dossierContactComponent }
      if (dossierContactComponent.updated === true) {
        dossierContactComponent = yield call(getDossierContact, dossierId, auth.token)
        dossierContactComponent.updated = true
      }

      let dossierFinancingComponent = yield call(getDossierFinancing, dossierId, auth.token)

      const [
        saleTypeCombo, salesmanCombo, salesmanComboLogic, previousUsageCombo, financialInstitutionCombo,
        serviceTypeCombo, financingTypeCombo, financingStatusCombo, ivaTypeCombo, iemType, extraSaleTypeCombo, presentTypeCombo,
        suppliedTypeCombo, laterUseCombo, activityClientCombo, activityBuyerCombo, vehicleType, additionalInfoCombo, entityFilterSubType, entitySubTypeCombo, purchaseTypeVnCombo,
        associatedCostTypeCombo, insuranceCarrierCombo, operationTypeDMSCombo
      ] = yield all([
        call(getSaleTypes, dossier.dossierId, auth.token),
        call(getSalesman, dossier.organizedUnitId, auth.token),
        call(getSalesmanLogic, dossier.organizedUnitId, auth.token),
        call(getPreviousUsage, dossier.organizedUnitId, auth.token),
        call(getFinanceCompaniesService, dossier.organizedUnitId, auth.token, false, dossierFinancingComponent.companyId),
        call(getDossierSubTypeByTypeAndUo, dossierId, dossier.organizedUnitId, auth.token),
        call(getFinancingTypesService, dossierFinancingComponent.companyId, auth.token, false, dossierFinancingComponent.typeId),
        call(getFinancingStatusService, auth.token),
        call(getIvaType, auth.token, dossier.dossierId, false),
        call(getIemType, auth.token),
        call(getExtraSaleTypes, dossier.organizedUnitId, auth.token),
        call(getPresentTypes, dossier.organizedUnitId, auth.token),
        call(getSuppliedTypes, dossier.organizedUnitId, auth.token),
        call(getLaterUse, auth.token),
        call(getActivityClient, auth.token),
        call(getActivityBuyer, auth.token),
        call(getVehicleType, auth.token),
        call(getAdditionalInfo, auth.token, dossier.dossierSubTypeId),
        call(getEntityFilterSubType, auth.token, entityTypesId.recurrentEntity, recurrentEntitySubTypes.renting, dossier.organizedUnitId),
        call(getEntitySubType, auth.token, entityComponent.entityTypeId),
        call(getPurchaseTypesByDossierSubType, auth.token, dossierSubTypeId.compra_vn),
        call(getAssociatedCostsTypes, dossier.organizedUnitId, auth.token),
        call(getInsuranceCarriers, auth.token, dossier.organizedUnitId),
        call(getOperationTypeDMSAll, auth.token)
      ])

      let operationTypeCombo = []
      if (dossier.dossierSaleComponent && dossier.dossierSaleComponent.saleTypeId) {
        operationTypeCombo = yield call(getOperationTypes, dossier.dossierSaleComponent.saleTypeId, dossier.organizedUnitId, auth.token)
      }

      let entityFilterSubTypeCombo
      if (entityComponent.entityTypeId && entityComponent.entitySubTypeId) {
        entityFilterSubTypeCombo = yield call(getEntityFilterSubType, auth.token, entityComponent.entityTypeId, entityComponent.entitySubTypeId, dossier.organizedUnitId)
      }

      let carlineCombo = []; let catalogueCombo = []; let colorCombo = []; let tapestryCombo = []
      if (dossier.order?.brandId) {
        carlineCombo = yield call(getCarlineCombo, auth.token, dossier.order.brandId)
      }
      if (dossier.order?.carlineId) {
        catalogueCombo = yield call(getCatalogueCombo, auth.token, dossier.order.carlineId)
      }
      if (dossier.order?.modelId && dossier.order?.catalogueId) {
        colorCombo = yield call(getColorCombo, auth.token, dossier.order.catalogueId, dossier.order.modelId)
        tapestryCombo = yield call(getTapestryCombo, auth.token, dossier.order.catalogueId, dossier.order.modelId)
        dossier.order.model = yield call(getCatalogueModel, auth.token, dossier.order?.catalogueId, dossier.order?.modelId)
        var orderCatalogueModel = yield call(getCatalogueModel, auth.token, dossier.order.catalogueId, dossier.order.modelId)
        dossier.order.model = orderCatalogueModel.description || ''
        dossier.order.catalogueModelId = orderCatalogueModel.catalogueModelId || ''
      }

      let vehicleComponent = salesDossier.vehicleComponent
      vehicleComponent = { ...vehicleComponent, ...dossier.dossierVehicleComponent }
      if (salesDossier.vehicleComponent.updated === true) {
        vehicleComponent = dossier.dossierParentId ? yield call(getPurchaseWithVehicleById, dossierId, auth.token) : vehicleComponent
        vehicleComponent.updated = true
      }

      if (vehicleComponent.brandId) {
        carlineCombo = yield call(getCarlineCombo, auth.token, vehicleComponent.brandId)
      }
      if (vehicleComponent.carlineId) {
        catalogueCombo = yield call(getCatalogueCombo, auth.token, vehicleComponent.carlineId)
      }
      if (vehicleComponent.modelId && vehicleComponent.catalogueId) {
        colorCombo = yield call(getColorCombo, auth.token, vehicleComponent.catalogueId, vehicleComponent.modelId)
        tapestryCombo = yield call(getTapestryCombo, auth.token, vehicleComponent.catalogueId, vehicleComponent.modelId)
      }

      if (vehicleComponent.catalogueId && vehicleComponent.modelId) {
        var catalogueModel = yield call(getCatalogueModel, auth.token, vehicleComponent.catalogueId, vehicleComponent.modelId)
        vehicleComponent.model = catalogueModel.description || ''
        vehicleComponent.catalogueModelId = catalogueModel.catalogueModelId || ''
      }

      let dossierCompleteOtherDataComponent = salesDossier.dossierCompleteOtherDataComponent
      if (dossierCompleteOtherDataComponent.updated === true) {
        dossierCompleteOtherDataComponent = yield call(getDossierOtherData, dossierId, auth.token)
        dossierCompleteOtherDataComponent.updated = true
      } else {
        dossierCompleteOtherDataComponent = dossier.dossierCompleteOtherDataComponent
      }
      let dossierEconomicComponent = dossier.dossierEconomicComponent ? dossier.dossierEconomicComponent : salesDossier.dossierEconomicComponent
      if (dossierEconomicComponent.updated === true) {
        dossierEconomicComponent = yield call(getSaleEconomicPlan, dossierId, auth.token)
        dossierEconomicComponent.updated = true
      }

      dossierFinancingComponent = salesDossier.dossierFinancingComponent
      if (dossierFinancingComponent.updated === true) {
        dossierFinancingComponent = yield call(getDossierFinancing, dossierId, auth.token)
        dossierFinancingComponent.updated = true
      }
      let dossierCompleteDeliveryComponent = null
      if (dossier.dossierCompleteDeliveryComponent) {
        dossierCompleteDeliveryComponent = dossier.dossierCompleteDeliveryComponent
        if (JSON.stringify(dossierCompleteDeliveryComponent.dossierDeliveryComponent) !== JSON.stringify(salesDossier.dossierCompleteDeliveryComponent.dossierDeliveryComponent)) {
          dossierCompleteDeliveryComponent.dossierDeliveryComponent.updated = true
        }
      } else {
        dossierCompleteDeliveryComponent = salesDossier.dossierCompleteDeliveryComponent.dossierDeliveryComponent
      }
      if (dossierCompleteDeliveryComponent.dossierDeliveryComponent && dossierCompleteDeliveryComponent.dossierDeliveryComponent.updated === true) {
        let newDossierCompleteDeliveryComponent = yield call(getDossierDelivery, dossierId, auth.token)
        dossierCompleteDeliveryComponent = { ...dossierCompleteDeliveryComponent, dossierDeliveryComponent: newDossierCompleteDeliveryComponent.dossierDeliveryComponent }
        dossierCompleteDeliveryComponent.dossierDeliveryComponent.updated = true
      }
      let selfSupplyViewPermission
      dossier.viewConfiguration.tabsConfiguration.some(t => {
        if (t.code === buttonsHeaderPermisions.main) {
          const componentConf = t.componentsConfiguration.filter((comConf) => comConf.code === buttonsSidebar.self_supply)
          if (componentConf.length > 0) {
            selfSupplyViewPermission = componentConf[0].permission
            return true
          }
        }
      })

      let selfSupplyViewPermissionState
      if (salesDossier.viewConfiguration && salesDossier.viewConfiguration.tabsConfiguration) {
        salesDossier.viewConfiguration.tabsConfiguration.some(t => {
          if (t.code === buttonsHeaderPermisions.main) {
            const componentConf = t.componentsConfiguration.filter((comConf) => comConf.code === buttonsSidebar.self_supply)
            if (componentConf.length > 0) {
              selfSupplyViewPermissionState = componentConf[0].permission
              return true
            }
          }
        })
      }

      let dossierSelfSupplyComponent = salesDossier.dossierSelfSupplyComponent
      if (selfSupplyViewPermissionState !== selfSupplyViewPermission && (!selfSupplyViewPermission || selfSupplyViewPermission === permissions.hidden)) {
        dossierSelfSupplyComponent = { removedComponent: true }
      } else if (dossierSelfSupplyComponent.updated === true) {
        dossierSelfSupplyComponent = yield call(getDossierSelfSupply, dossierId, auth.token)
        dossierSelfSupplyComponent.updated = true
      } else {
        if (dossierSelfSupplyComponent.removedComponent) dossierSelfSupplyComponent.removedComponent = false
      }

      yield put(switchFavoriteSuccess(dossier.dossierHeaderComponent.favorite))

      yield put(fetchCombosSuccess({
        financialInstitutionCombo,
        insuranceCarrierCombo,
        financingTypeCombo,
        financingStatusCombo,
        saleTypeCombo,
        operationTypeCombo,
        salesmanCombo,
        salesmanComboLogic,
        paymentMethodCombo,
        entityTypeCombo,
        channelCombo,
        maritalStatusCombo,
        segmentationCombo,
        previousUsageCombo,
        brandCombo,
        fuelCombo,
        serviceTypeCombo,
        ivaTypeCombo,
        iemType,
        extraSaleTypeCombo,
        presentTypeCombo,
        suppliedTypeCombo,
        laterUseCombo,
        activityClientCombo,
        activityBuyerCombo,
        vehicleType,
        additionalInfoCombo,
        entityTransferCombo,
        purchaseTypeVnCombo,
        entityFilterSubType,
        entitySubTypeCombo,
        entityFilterSubTypeCombo,
        tuneUpDestinationCombo,
        associatedCostTypeCombo,
        serviceDestinationCombo,
        satPaymentMethod,
        purchaseTypeComboFromSale,
        currencyCombo,
        operationTypeDMSCombo,
        modelCombo,
        carlineCombo,
        catalogueCombo,
        colorCombo,
        tapestryCombo
      }))

      const dossierPaperworkComponent = dossier.dossierPaperworkComponent

      yield put(fetchSalesDossierSuccess({
        ...dossier,
        dossierSaleComponent,
        checkListHeader,
        entityComponent,
        dossierFinancingComponent,
        originalDossierFinancingComponent: dossierFinancingComponent,
        dossierEconomicComponent,
        dossierCompleteOtherDataComponent,
        entityChildComponent,
        vehicleComponent,
        salesDocumentaryCheckList,
        dossierRelatedDossiers,
        dossierContactComponent,
        dossierFleetComponent,
        dossierSelfSupplyComponent,
        dossierPaperworkComponent,
        dossierCompleteDeliveryComponent
      }))

      const currentActiveTab = yield select(getActiveTab)
      let nextActiveTab = dossier.viewConfiguration.tabsConfiguration.filter((tabConf) => tabConf.permission > permissions.hidden)
      nextActiveTab = nextActiveTab.length > 0 ? nextActiveTab[0] : currentActiveTab
      if (nextActiveTab !== currentActiveTab) {
        if (currentActiveTab !== buttonsHeaderPermisionsIdReverse.economic_plan &&
              currentActiveTab !== buttonsHeaderPermisionsIdReverse.doc_manager) {
          yield put(setActiveTab(buttonsHeaderPermisionsIdReverse[nextActiveTab.code]))
        }
      }
      if (!historical && nextActiveTab === buttonsHeader.gestor_documental) {
        yield put(refreshDocumentManagementModal(dossierId))
      }
    }
    if (resolve) yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.status === 301) {
        // yield put(replace(`/dossier${customHandlerError.json}`))
        yield put(replace(`/dossier${customHandlerError.json}`))
      } else {
        yield put(messageModalActions.openErrorMessageModal('MESSAGES.SALES_DOSSIER.ERROR_GET_SALES_DOSSIER', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
      if (reject) yield call(reject, customHandlerError)
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchSalesDossier () {
  yield takeEvery(dossierActionsTypes.FETCH_SALES_DOSSIER, fetchSalesDossier)
}

export function * fetchSalesVehicle ({ dossierId, resolve, reject, pristine }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const salesDossier = JSON.parse(JSON.stringify(yield select(state => state.salesDossier)))
    const vehicleComponent = yield call(getPurchaseWithVehicleById, dossierId, auth.token)
    let carlineCombo = []; let catalogueCombo = []; let colorCombo = []; let tapestryCombo = []

    if (vehicleComponent.brandId) {
      carlineCombo = yield call(getCarlineCombo, auth.token, vehicleComponent.brandId)
    }
    if (vehicleComponent.carlineId) {
      catalogueCombo = yield call(getCatalogueCombo, auth.token, vehicleComponent.carlineId)
    }
    if (vehicleComponent.modelId && vehicleComponent.catalogueId) {
      colorCombo = yield call(getColorCombo, auth.token, vehicleComponent.catalogueId, vehicleComponent.modelId)
      tapestryCombo = yield call(getTapestryCombo, auth.token, vehicleComponent.catalogueId, vehicleComponent.modelId)
    }

    if (vehicleComponent.catalogueId && vehicleComponent.modelId) {
      var catalogueModel = yield call(getCatalogueModel, auth.token, vehicleComponent.catalogueId, vehicleComponent.modelId)
      vehicleComponent.model = catalogueModel.description || ''
      vehicleComponent.catalogueModelId = catalogueModel.catalogueModelId || ''
    }
    yield put(fetchCombosSuccess({
      carlineCombo,
      catalogueCombo,
      colorCombo,
      tapestryCombo
    }))

    if (vehicleComponent && vehicleComponent.vehicleId) {
      vehicleComponent.updated = true
      yield put(change('salesDossier', 'vehicleComponent', vehicleComponent.vehicleId ? vehicleComponent : {}))
      yield put(fetchSalesDossierSuccess({ vehicleComponent }))
      if (pristine === true || pristine === undefined) {
        yield put(initialize('salesDossier', { ...salesDossier, vehicleComponent: vehicleComponent }))
      }
    }
    if (resolve) yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.SALES_DOSSIER.ERROR_GET_SALES_DOSSIER', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchSalesVehicle () {
  yield takeEvery(dossierActionsTypes.FETCH_SALES_DOSSIER_VEHICLE, fetchSalesVehicle)
}

export function * fetchSalesClient ({ dossierId, resolve, reject, pristine }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const salesDossier = JSON.parse(JSON.stringify(yield select(state => state.salesDossier)))
    const entityComponent = yield call(getDossierEntityService, dossierId, auth.token)
    const dossierContactComponent = yield call(getDossierContact, dossierId, auth.token)
    entityComponent.updated = true
    dossierContactComponent.updated = true

    if (entityComponent && entityComponent.entitySubTypeId) {
      const entitySubTypeCombo = yield call(getEntitySubType, auth.token, entityComponent.entityTypeId)
      const entityFilterSubTypeCombo = yield call(getEntityFilterSubType, auth.token, entityComponent.entityTypeId, entityComponent.entitySubTypeId, salesDossier.organizedUnitId)
      yield put(fetchCombosSuccess({ entitySubTypeCombo, entityFilterSubTypeCombo }))
    }

    yield put(change('salesDossier', 'entityComponent', entityComponent.entityId ? entityComponent : {}))
    yield put(change('salesDossier', 'dossierContactComponent', dossierContactComponent))
    yield put(fetchSalesDossierSuccess({ entityComponent, dossierContactComponent }))
    if (pristine === true || pristine === undefined) {
      yield put(initialize('salesDossier', { ...salesDossier, entityComponent: entityComponent, dossierContactComponent }))
    }
    if (resolve) yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.SALES_DOSSIER.ERROR_GET_SALES_DOSSIER', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchSalesClient () {
  yield takeEvery(dossierActionsTypes.FETCH_SALES_DOSSIER_CLIENT, fetchSalesClient)
}

export function * fetchSalesFinancing ({ dossierId, resolve, reject, pristine }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const salesDossier = JSON.parse(JSON.stringify(yield select(state => state.salesDossier)))
    const dossierFinancingComponent = yield call(getDossierFinancing, dossierId, auth.token)
    dossierFinancingComponent.updated = true
    yield put(change('salesDossier', 'dossierFinancingComponent', dossierFinancingComponent))
    yield put(fetchSalesDossierSuccess({ dossierFinancingComponent, originalDossierFinancingComponent: dossierFinancingComponent }))
    yield put(getFinancingTypeProductByFinancingTypeSuccess({}))
    if (pristine === true) {
      yield put(initialize('salesDossier', { ...salesDossier, dossierFinancingComponent, originalDossierFinancingComponent: dossierFinancingComponent }))
    }
    if (dossierFinancingComponent.financingTypeProductId !== null && dossierFinancingComponent.financingTypeProductId !== undefined) {
      const financingTypeProduct = yield call(getFinancingTypeProduct, auth.token, dossierFinancingComponent.financingTypeProductId)
      yield put(getFinancingTypeProductByFinancingTypeSuccess(financingTypeProduct))
    }

    if (resolve) yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.SALES_DOSSIER.ERROR_GET_SALES_DOSSIER', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchfetchSalesFinancing () {
  yield takeEvery(dossierActionsTypes.FETCH_SALES_DOSSIER_FINANCING, fetchSalesFinancing)
}

export function * fetchSalesInsuranceCarrier ({ dossierId, resolve, reject, pristine }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const salesDossier = JSON.parse(JSON.stringify(yield select(state => state.salesDossier)))
    const dossierInsuranceCarrierComponent = yield call(getDossierInsuranceCarrier, dossierId, auth.token)
    yield put(change('salesDossier', 'dossierInsuranceCarrierComponent', dossierInsuranceCarrierComponent))
    yield put(fetchSalesDossierSuccess({ dossierInsuranceCarrierComponent, originaldossierInsuranceCarrierComponent: dossierInsuranceCarrierComponent }))
    if (pristine === true) {
      yield put(initialize('salesDossier', { ...salesDossier, dossierInsuranceCarrierComponent, originaldossierInsuranceCarrierComponent: dossierInsuranceCarrierComponent }))
    }
    if (resolve) yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.SALES_DOSSIER.ERROR_GET_SALES_DOSSIER', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchSalesInsuranceCarrier () {
  yield takeEvery(dossierActionsTypes.FETCH_SALES_DOSSIER_INSURANCE_CARRIER, fetchSalesInsuranceCarrier)
}

export function * fetchSalesInsuranceCarrierHistorical ({ dossierId, resolve, reject, pristine }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const salesDossier = JSON.parse(JSON.stringify(yield select(state => state.salesDossier)))
    const dossierInsuranceCarrierComponent = yield call(getDossierInsuranceCarrierHistorical, dossierId, auth.token)
    yield put(change('salesDossier', 'dossierInsuranceCarrierComponent', dossierInsuranceCarrierComponent))
    yield put(fetchSalesDossierSuccess({ dossierInsuranceCarrierComponent, originaldossierInsuranceCarrierComponent: dossierInsuranceCarrierComponent }))
    if (pristine === true) {
      yield put(initialize('salesDossier', { ...salesDossier, dossierInsuranceCarrierComponent, originaldossierInsuranceCarrierComponent: dossierInsuranceCarrierComponent }))
    }
    if (resolve) yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.SALES_DOSSIER.ERROR_GET_SALES_DOSSIER', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchSalesInsuranceCarrierHistorical () {
  yield takeEvery(dossierActionsTypes.FETCH_SALES_DOSSIER_INSURANCE_CARRIER_HISTORICAL, fetchSalesInsuranceCarrierHistorical)
}

export function * fetchSalesDelivery ({ dossierId, financingStatusId, resolve, reject, pristine }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const salesDossier = JSON.parse(JSON.stringify(yield select(state => state.salesDossier)))
    const dossierCompleteDeliveryComponent = yield call(getDossierDelivery, dossierId, auth.token)
    dossierCompleteDeliveryComponent.dossierDeliveryComponent.updated = true
    const completeDeliveryComponent = {
      ...salesDossier.dossierCompleteDeliveryComponent,
      ...dossierCompleteDeliveryComponent,
      dossierDeliveryComponent: {
        ...salesDossier.dossierCompleteDeliveryComponent.dossierDeliveryComponent,
        ...dossierCompleteDeliveryComponent.dossierDeliveryComponent
      }
    }
    yield put(change('salesDossier', 'dossierCompleteDeliveryComponent', completeDeliveryComponent))
    yield put(fetchSalesDossierSuccess({ dossierCompleteDeliveryComponent: completeDeliveryComponent }))
    if (pristine === true) {
      yield put(initialize('salesDossier', { ...salesDossier, dossierCompleteDeliveryComponent: completeDeliveryComponent }))
    }
    if (resolve) yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.SALES_DOSSIER.ERROR_GET_SALES_DOSSIER', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchfetchSalesDelivery () {
  yield takeEvery(dossierActionsTypes.FETCH_SALES_DOSSIER_DELIVERY, fetchSalesDelivery)
}

export function * fetchSalesDeliveryHistorical ({ dossierId, resolve, reject }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const dossierCompleteDeliveryComponent = yield call(getDossierDeliveryHistorical, dossierId, auth.token)
    yield put(change('salesDossier', 'dossierCompleteDeliveryComponent', dossierCompleteDeliveryComponent))
    yield put(fetchSalesDossierSuccess({ dossierCompleteDeliveryComponent }))
    if (resolve) yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.SALES_DOSSIER.ERROR_GET_SALES_DOSSIER', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchfetchSalesDeliveryHistorical () {
  yield takeEvery(dossierActionsTypes.FETCH_SALES_DOSSIER_DELIVERY_HISTORICAL, fetchSalesDeliveryHistorical)
}

export function * fetchSalesFinancingHistorical ({ dossierId, resolve, reject }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const dossierFinancingComponent = yield call(getDossierFinancingHistorical, dossierId, auth.token)
    yield put(change('salesDossier', 'dossierFinancingComponent', dossierFinancingComponent))
    yield put(fetchSalesDossierSuccess({ dossierFinancingComponent }))

    if (dossierFinancingComponent.financingTypeProductId !== null && dossierFinancingComponent.financingTypeProductId !== undefined) {
      const financingTypeProduct = yield call(getFinancingTypeProduct, auth.token, dossierFinancingComponent.financingTypeProductId)
      yield put(getFinancingTypeProductByFinancingTypeSuccess(financingTypeProduct))
    }

    if (resolve) yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.SALES_DOSSIER.ERROR_GET_SALES_DOSSIER', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchfetchSalesFinancingHistorical () {
  yield takeEvery(dossierActionsTypes.FETCH_SALES_DOSSIER_FINANCING_HISTORICAL, fetchSalesFinancingHistorical)
}

export function * fetchSalesVehicleHistorical ({ dossierId, resolve, reject }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const vehicleComponent = yield call(getHistoricalPurchaseWithVehicleById, dossierId, auth.token)
    yield put(change('salesDossier', 'vehicleComponent', vehicleComponent.vehicleId ? vehicleComponent : {}))
    let carlineCombo = []; let catalogueCombo = []; let colorCombo = []; let tapestryCombo = []

    if (vehicleComponent.brandId) {
      carlineCombo = yield call(getCarlineCombo, auth.token, vehicleComponent.brandId)
    }
    if (vehicleComponent.carlineId) {
      catalogueCombo = yield call(getCatalogueCombo, auth.token, vehicleComponent.carlineId)
    }
    if (vehicleComponent.modelId && vehicleComponent.catalogueId) {
      colorCombo = yield call(getColorCombo, auth.token, vehicleComponent.catalogueId, vehicleComponent.modelId)
      tapestryCombo = yield call(getTapestryCombo, auth.token, vehicleComponent.catalogueId, vehicleComponent.modelId)
    }

    if (vehicleComponent.catalogueModelId) {
      var catalogueModel = yield call(getCatalogueModel, auth.token, vehicleComponent.catalogueId, vehicleComponent.modelId)
      vehicleComponent.model = catalogueModel.description || ''
      vehicleComponent.catalogueModelId = catalogueModel.catalogueModelId || ''
    }
    yield put(fetchSalesDossierSuccess({ vehicleComponent }))
    yield put(fetchCombosSuccess({
      carlineCombo,
      catalogueCombo,
      colorCombo,
      tapestryCombo
    }))
    if (resolve) yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.SALES_DOSSIER.ERROR_GET_SALES_DOSSIER', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchSalesVehicleHistorical () {
  yield takeEvery(dossierActionsTypes.FETCH_SALES_DOSSIER_VEHICLE_HISTORICAL, fetchSalesVehicleHistorical)
}

export function * fetchSalesClientHistorical ({ dossierId, resolve, reject }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const entityComponent = yield call(getDossierHistoricalEntity, dossierId, auth.token)
    if (entityComponent.entityTypeId === entityTypesId.recurrentEntity) {
      const salesDossier = JSON.parse(JSON.stringify(yield select(state => state.salesDossier)))
      yield call(fetchEntitySubTypeCombo, { entityTypeId: entityComponent.entityTypeId })
      yield call(fetchEntityFilterSubTypeCombo, { entityTypeId: entityComponent.entityTypeId, entitySubTypeId: entityComponent.entitySubTypeId, organizedUnitId: salesDossier.organizedUnitId })
    }
    const dossierContactComponent = yield call(getDossierContactHistorical, dossierId, auth.token)
    yield put(change('salesDossier', 'entityComponent', entityComponent.entityId ? entityComponent : {}))
    yield put(change('salesDossier', 'dossierContactComponent', dossierContactComponent))
    yield put(fetchSalesDossierSuccess({ entityComponent, dossierContactComponent }))
    if (resolve) yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.SALES_DOSSIER.ERROR_GET_SALES_DOSSIER', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchSalesClientHistorical () {
  yield takeEvery(dossierActionsTypes.FETCH_SALES_DOSSIER_CLIENT_HISTORICAL, fetchSalesClientHistorical)
}

export function * refreshSalesDossierEntityFromDocumentModal (documentEditionalModalState) {
  try {
    yield put(showLoader())
    const salesDossier = JSON.parse(JSON.stringify(yield select(state => state.salesDossier)))
    const auth = yield select(getAuth)
    let entityComponent = {}
    let dirty = false
    if (documentEditionalModalState !== null && documentEditionalModalState !== undefined &&
       documentEditionalModalState.entityId !== null && documentEditionalModalState.entityId !== undefined) {
      entityComponent = yield call(getEntityById, documentEditionalModalState.entityId, auth.token)
    } else {
      entityComponent = yield call(getDossierEntityService, salesDossier.dossierId, auth.token)
    }
    if (documentEditionalModalState !== null && documentEditionalModalState !== undefined &&
      documentEditionalModalState.dirty !== null && documentEditionalModalState.dirty !== undefined) {
      dirty = documentEditionalModalState.dirty
    }
    entityComponent.previousEntity = { ...salesDossier.entityComponent.previousEntity }

    if (dirty) {
      yield put(change('salesDossier', 'entityComponent', entityComponent))
    } else {
      yield put(initialize('salesDossier', { ...salesDossier, entityComponent: entityComponent }))
    }
    yield put(fetchSalesDossierSuccess({ entityComponent }))
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.SALES_DOSSIER.ERROR_GET_SALES_DOSSIER', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * refreshSalesDossierVehicleFromDocumentModal (documentEditionalModalState) {
  try {
    yield put(showLoader())
    const salesDossier = JSON.parse(JSON.stringify(yield select(state => state.salesDossier)))
    const auth = yield select(getAuth)
    const vehicleComponent = yield call(getPurchaseWithVehicleById, salesDossier.dossierId, auth.token)
    let dirty = false
    if (documentEditionalModalState !== null && documentEditionalModalState !== undefined &&
      documentEditionalModalState.dirty !== null && documentEditionalModalState.dirty !== undefined) {
      dirty = documentEditionalModalState.dirty
    }
    yield put(fetchSalesDossierSuccess({ vehicleComponent }))
    let sales
    const headerAndButtons = yield call(getSaleHeaderAndButtons, auth.token, salesDossier.dossierId)
    const checkListHeader = yield call(getStageCheckList, salesDossier.dossierId, auth.token)
    sales = { ...headerAndButtons }
    sales = { ...sales, checkListHeader }
    if (dirty) {
      yield put(change('salesDossier', 'vehicleComponent', vehicleComponent))
    } else {
      yield put(initialize('salesDossier', { ...salesDossier, vehicleComponent: vehicleComponent }))
    }
    yield put(fetchSalesDossierSuccess(sales))
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.SALES_DOSSIER.ERROR_GET_SALES_DOSSIER', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * refreshSalesDossierFromDocumentModal (documentEditionalModalState) {
  try {
    yield put(showLoader())
    const salesDossier = JSON.parse(JSON.stringify(yield select(state => state.salesDossier)))
    const auth = yield select(getAuth)
    const dossierId = salesDossier.dossierId
    let salesComponent = salesDossier.salesComponent
    let sales
    let dossier = {}
    let dirty = false
    const components = yield call(fetchSalesComponentsUpdated, salesDossier, auth)
    if (documentEditionalModalState === null || documentEditionalModalState === undefined || documentEditionalModalState.documentParentTypeId === documentEntityTypeConstant.DOSSIER || documentEditionalModalState.documentParentTypeId === null) {
      dossier = yield call(getDossierById, dossierId, auth.token)
      salesComponent = { ...dossier.dossierSaleComponent }
    }
    if (documentEditionalModalState && documentEditionalModalState.dirty) {
      dirty = documentEditionalModalState.dirty
    }
    const headerAndButtons = yield call(getSaleHeaderAndButtons, auth.token, dossierId)
    const checkListHeader = yield call(getStageCheckList, dossierId, auth.token)
    dossier = { ...salesDossier, ...dossier, ...components }
    sales = { ...sales, ...dossier, checkListHeader, ...headerAndButtons }
    yield put(fetchSalesDossierSuccess(sales))
    if (dirty) {
      yield put(change('salesDossier', 'salesComponent', salesComponent))
    } else {
      yield put(initialize('salesDossier', { ...salesDossier, salesComponent: salesComponent }))
    }
    yield put(hideLoader())
    yield call(fetchDependantsSaleDossier, { dossierId })
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.SALES_DOSSIER.ERROR_GET_SALES_DOSSIER', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * refreshCommonSaleFromDocumental () {
  try {
    yield put(showLoader())
    const salesDossier = JSON.parse(JSON.stringify(yield select(state => state.salesDossier)))
    const auth = yield select(getAuth)
    const dossierId = salesDossier.dossierId
    const headerAndButtons = yield call(getSaleHeaderAndButtons, auth.token, dossierId)
    const checkListHeader = yield call(getStageCheckList, dossierId, auth.token)
    let sales
    sales = { ...headerAndButtons }
    sales = { ...sales, checkListHeader }
    yield put(fetchSalesDossierSuccess(sales))
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.SALES_DOSSIER.ERROR_GET_SALES_DOSSIER', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * fetchSalesComponentsUpdated (salesDossier, auth) {
  const result = {}
  if (salesDossier.entityComponent && salesDossier.entityComponent.updated) {
    const entityComponent = yield call(getDossierEntityService, salesDossier.dossierId, auth.token)
    entityComponent.updated = true
    result.entityComponent = entityComponent
  }
  if (salesDossier.vehicleComponent && salesDossier.vehicleComponent.updated) {
    const vehicleComponent = yield call(getPurchaseWithVehicleById, salesDossier.dossierId, auth.token)
    vehicleComponent.updated = true
    result.vehicleComponent = vehicleComponent
  }
  if (salesDossier.dossierEconomicComponent && salesDossier.dossierEconomicComponent.updated) {
    const dossierEconomicComponent = yield call(getSaleEconomicPlan, salesDossier.dossierId, auth.token)
    dossierEconomicComponent.updated = true
    result.dossierEconomicComponent = dossierEconomicComponent
  }
  if (salesDossier.dossierContactComponent && salesDossier.dossierContactComponent.updated) {
    const dossierContactComponent = yield call(getDossierContact, salesDossier.dossierId, auth.token)
    dossierContactComponent.updated = true
    result.dossierContactComponent = dossierContactComponent
  }
  if (salesDossier.dossierFinancingComponent) {
    const dossierFinancingComponent = yield call(getDossierFinancing, salesDossier.dossierId, auth.token)
    dossierFinancingComponent.updated = true
    result.dossierFinancingComponent = dossierFinancingComponent
    result.originalDossierFinancingComponent = dossierFinancingComponent
  }
  if (salesDossier.dossierCompleteDeliveryComponent && salesDossier.dossierCompleteDeliveryComponent.dossierDeliveryComponent &&
    salesDossier.dossierCompleteDeliveryComponent.dossierDeliveryComponent.updated) {
    const dossierCompleteDeliveryComponent = yield call(getDossierDelivery, salesDossier.dossierId, auth.token)
    dossierCompleteDeliveryComponent.dossierDeliveryComponent.updated = true
    result.dossierCompleteDeliveryComponent = dossierCompleteDeliveryComponent
  }
  if (salesDossier.dossierCompleteOtherDataComponent && salesDossier.dossierCompleteOtherDataComponent.updated) {
    const dossierCompleteOtherDataComponent = yield call(getDossierOtherData, salesDossier.dossierId, auth.token)
    dossierCompleteOtherDataComponent.updated = true
    result.dossierCompleteOtherDataComponent = dossierCompleteOtherDataComponent
  }
  if (salesDossier.dossierSelfSupplyComponent && salesDossier.dossierSelfSupplyComponent.updated) {
    const dossierSelfSupplyComponent = yield call(getDossierSelfSupply, salesDossier.dossierId, auth.token)
    dossierSelfSupplyComponent.updated = true
    result.dossierSelfSupplyComponent = dossierSelfSupplyComponent
  }
  return result
}

export function * fetchSalesOtherData ({ dossierId, resolve, reject, pristine }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const salesDossier = JSON.parse(JSON.stringify(yield select(state => state.salesDossier)))
    const dossierCompleteOtherDataComponent = yield call(getDossierOtherData, dossierId, auth.token)
    dossierCompleteOtherDataComponent.updated = true
    yield put(change('salesDossier', 'dossierCompleteOtherDataComponent', dossierCompleteOtherDataComponent))
    yield put(fetchSalesDossierSuccess({ dossierCompleteOtherDataComponent }))
    if (pristine === true) {
      yield put(initialize('salesDossier', { ...salesDossier, dossierCompleteOtherDataComponent }))
    }
    if (resolve) yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.SALES_DOSSIER.ERROR_GET_OTHER_DATA', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchfetchSalesOtherData () {
  yield takeEvery(dossierActionsTypes.FETCH_SALES_DOSSIER_OTHERDATA, fetchSalesOtherData)
}
export function * fetchSalesOtherDataHistorical ({ dossierId, resolve, reject, pristine }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const salesDossier = JSON.parse(JSON.stringify(yield select(state => state.salesDossier)))
    const dossierCompleteOtherDataComponent = yield call(getDossierOtherDataHistorical, dossierId, auth.token)
    dossierCompleteOtherDataComponent.updated = true
    yield put(change('salesDossier', 'dossierCompleteOtherDataComponent', dossierCompleteOtherDataComponent))
    yield put(fetchSalesDossierSuccess({ dossierCompleteOtherDataComponent }))
    if (pristine === true) {
      yield put(initialize('salesDossier', { ...salesDossier, dossierCompleteOtherDataComponent }))
    }
    if (resolve) yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.SALES_DOSSIER.ERROR_GET_OTHER_DATA', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}
export function * watchfetchSalesOtherDataHistorical () {
  yield takeEvery(dossierActionsTypes.FETCH_SALES_DOSSIER_OTHERDATA_HISTORICAL, fetchSalesOtherDataHistorical)
}
export function * refreshSalesDossierFromDocumentModalLight () {
  try {
    yield put(showLoader())
    const salesDossier = JSON.parse(JSON.stringify(yield select(state => state.salesDossier)))
    const auth = yield select(getAuth)
    const dossierId = salesDossier.dossierId
    let sales
    const checkListHeader = yield call(getStageCheckList, dossierId, auth.token)
    const headerAndButtons = yield call(getSaleHeaderAndButtons, auth.token, dossierId)
    sales = { ...headerAndButtons }
    sales = { ...sales, checkListHeader }
    yield put(fetchSalesDossierSuccess(sales))
    yield put(hideLoader())
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.SALES_DOSSIER.ERROR_GET_SALES_DOSSIER', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * fetchSalesEconomic ({ dossierId, historical, pristine, resolve, reject }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    let dossierEconomicComponent = {}
    if (historical) {
      dossierEconomicComponent = yield call(getSaleEconomicPlanHistorical, dossierId, auth.token)
    } else {
      dossierEconomicComponent = yield call(getSaleEconomicPlan, dossierId, auth.token)
    }
    dossierEconomicComponent.updated = true
    let salesDossier = JSON.parse(JSON.stringify(JSON.parse(JSON.stringify(yield select(state => state.form.salesDossier.values)))))
    salesDossier.dossierEconomicComponent = dossierEconomicComponent
    yield put(change('salesDossier', 'dossierEconomicComponent', dossierEconomicComponent))
    yield put(fetchSalesDossierEconomicSucces(dossierEconomicComponent, dossierId, historical))
    if (pristine === true) {
      yield put(initialize('salesDossier', salesDossier))
    }
    if (resolve) yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.SALES_DOSSIER.ERROR_GET_SALES_DOSSIER', customHandlerError.json ? customHandlerError.json.tag : ''))
      if (reject) yield call(reject, customHandlerError)
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchfetchSalesEconomic () {
  yield takeEvery(dossierActionsTypes.FETCH_SALES_DOSSIER_ECONOMIC, fetchSalesEconomic)
}

export function * fetchSalesServices ({ dossierId, resolve, reject, isHistorical }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    let table = isHistorical ? yield call(getSaleServiceDossiersHistorical, dossierId, auth.token) : yield call(getSaleServiceDossiers, dossierId, auth.token)
    yield put(fetchSalesServicesSuccess(table))
    if (resolve) yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.SALES_DOSSIER.ERROR_GET_SALES_DOSSIER', customHandlerError.json ? customHandlerError.json.tag : ''))
      if (reject) yield call(reject, customHandlerError)
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchSalesServices () {
  yield takeEvery(dossierActionsTypes.FETCH_SALES_SERVICES, fetchSalesServices)
}

export function * fetchSalesSelfSupply ({ dossierId, pristine, resolve, reject }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const salesDossier = JSON.parse(JSON.stringify(yield select(state => state.salesDossier)))

    let dossierSelfSupplyComponent = yield call(getDossierSelfSupply, dossierId, auth.token)
    dossierSelfSupplyComponent.updated = true

    yield put(fetchSalesSelfSupplySuccess(dossierSelfSupplyComponent))

    const departmentCombo = yield call(getDepartmentsCombo, salesDossier.organizedUnitId, auth.token, false, dossierSelfSupplyComponent?.departmentId)
    const selfSupplyOrganizedUnitCombo = yield call(getSelfSupplyOrganizedUnites, auth.token)

    yield put(fetchCombosSuccess({ departmentCombo, selfSupplyOrganizedUnitCombo }))
    yield put(change('salesDossier', 'dossierSelfSupplyComponent', dossierSelfSupplyComponent))
    if (pristine === true) {
      yield put(initialize('salesDossier', { ...salesDossier, dossierSelfSupplyComponent }))
    }
    if (resolve) yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.SALES_DOSSIER.ERROR_GET_SALES_DOSSIER', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchfetchSalesSelfSupply () {
  yield takeEvery(dossierActionsTypes.FETCH_SALES_DOSSIER_SELFSUPPLY, fetchSalesSelfSupply)
}

export function * fetchSalesSelfSupplyHistorical ({ dossierId, resolve, reject }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const salesDossier = JSON.parse(JSON.stringify(yield select(state => state.salesDossier)))
    const dossierSelfSupplyComponent = yield call(getDossierSelfSupplyHistorical, dossierId, auth.token)
    dossierSelfSupplyComponent.updated = true

    yield put(change('salesDossier', 'dossierSelfSupplyComponent', dossierSelfSupplyComponent))
    yield put(fetchSalesSelfSupplySuccess(dossierSelfSupplyComponent))

    const departmentCombo = yield call(getDepartmentsCombo, salesDossier.organizedUnitId, auth.token, false, dossierSelfSupplyComponent?.departmentId)
    const selfSupplyOrganizedUnitCombo = yield call(getSelfSupplyOrganizedUnites, auth.token)

    yield put(fetchCombosSuccess({ departmentCombo, selfSupplyOrganizedUnitCombo }))

    if (resolve) yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.SALES_DOSSIER.ERROR_GET_SALES_DOSSIER', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchfetchSalesSelfSupplyHistorical () {
  yield takeEvery(dossierActionsTypes.FETCH_SALES_DOSSIER_SELFSUPPLY_HISTORICAL, fetchSalesSelfSupplyHistorical)
}

export function * checkPrivateRfcFormat ({ rfc, resolve }) {
  if (rfc === genericRfc.privateRfc) {
    const confirmedPrivate = yield call(yesNoModal, 'warningPrivateRfcFormat')
    if (resolve) yield call(resolve, confirmedPrivate)
  } else if (rfc === genericRfc.foreignRfc) {
    const confirmedForeign = yield call(yesNoModal, 'errorForeignRfcFormat')
    if (resolve) yield call(resolve, !confirmedForeign)
  } else {
    if (resolve) yield call(resolve, true)
  }
}

export function * watchcheckPrivateRfcFormat () {
  yield takeEvery(dossierActionsTypes.CHECK_PRIVATE_RFC_FORMAT, checkPrivateRfcFormat)
}

export function * foreignPassportWarning ({ value, resolve }) {
  if (value) {
    const confirmed = yield call(yesNoModal, 'passportWarning')
    if (resolve && confirmed) yield call(resolve)
  }
}

export function * watchforeignPassportWarning () {
  yield takeEvery(dossierActionsTypes.FOREIGN_PASSPORT_WARNING, foreignPassportWarning)
}
