import React, { PureComponent } from 'react'
import { Table, Row, Col, OverlayTrigger, Tooltip, Button, Glyphicon } from 'react-bootstrap'
import PresentsModalPage from '../../../containers/masters/PresentsModalPage'
import SimpleTreeSelect from '../../commons/simpleForm/SimpleTreeSelect'
// import 'react-select/dist/react-select.css'

class PresentsMasters extends PureComponent {
  constructor () {
    super()
    this.state = {
      uoId: null
    }
  }

  handleOrganizedUnitChange (uoId) {
    this.setState({ uoId })
    this.props.actions.fetchPresentsMasters(uoId)
  }

  render () {
    const {
      t, presentsMastersList,
      actions: { deletePresentsModal, openPresentsModal, recoverPresentsModal },
      combos: { UOTreeCombo }
    } = this.props
    const tKey = 'MASTERS.PRESENTS_MASTERS.'
    const editTooltip = (<Tooltip id="editTooltip">{t('MASTERS.EDIT')}</Tooltip>)
    const deleteTooltip = (<Tooltip id="deleteTooltip">{t('MASTERS.DELETE')}</Tooltip>)
    const recoverTooltip = (<Tooltip id="recoverTooltip">{t('MASTERS.RECOVER')}</Tooltip>)
    return (
      <Row>
        <Col sm={12}>
          <div className="header-panel">
            <h4>{t(`${tKey}SOURCE_CHANNEL`)}</h4>
          </div>
        </Col>
        <SimpleTreeSelect
          colSm={12}
          controlLabel={t(`${tKey}ORGANIZED_UNIT`)}
          placeholder={t(`${tKey}ORGANIZED_UNIT`)}
          options={UOTreeCombo}
          searchPlaceholder={t(`${tKey}ORGANIZED_UNIT`)}
          value={this.state.uoId ? this.state.uoId.toString() : null}
          onInputChange={(value) => this.handleOrganizedUnitChange(value)}
          className='dashboard-select'
        />
        <PresentsModalPage
          uoId={this.state.uoId}
        />
        <Col sm={12}>
          <Table className="editable-table" hover responsive>
            <thead>
              <tr>
                <th>{t('MASTERS.ID')}</th>
                <th>{t('MASTERS.NAME')}</th>
                <th>{t('MASTERS.DESCRIPTION')}</th>
                <th style={{ width: '77px' }}>{t('DOSSIER_COMPONENTS.ACCESSORIES.ACTIONS')}</th>
              </tr>
            </thead>
            <tbody>
              {presentsMastersList && presentsMastersList.map((presentsMaster, idx) => (
                <tr key={idx} className={presentsMaster.isDeleted && 'is-deleted'}>
                  <td>{presentsMaster.id}</td>
                  <td>{presentsMaster.name}</td>
                  <td>{presentsMaster.description}</td>
                  <td>
                    {!presentsMaster.isDeleted && (
                      <div>
                        <a onClick={() => openPresentsModal(this.state.uoId, presentsMaster.id)}>
                          <OverlayTrigger placement="left" overlay={editTooltip}>
                            <i className="ico-edit-white" />
                          </OverlayTrigger>
                        </a>
                        <a onClick={() => deletePresentsModal(this.state.uoId, presentsMaster.id)}>
                          <OverlayTrigger placement="left" overlay={deleteTooltip}>
                            <i className="ico-trash" />
                          </OverlayTrigger>
                        </a>
                      </div>
                    )}
                    {presentsMaster.isDeleted && (
                      <a onClick={() => recoverPresentsModal(this.state.uoId, presentsMaster.id)}>
                        <OverlayTrigger placement="left" overlay={recoverTooltip}>
                          <Glyphicon glyph="repeat" />
                        </OverlayTrigger>
                      </a>
                    )}
                  </td>
                </tr>
              ))}
              {this.state.uoId && (
                <tr className="button-table-wrapper">
                  <td>
                    <Button
                      className="btn-editable-table btn-standard"
                      onClick={() => openPresentsModal(this.state.uoId)}>
                      + {t('MASTERS.ADD')}
                    </Button>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
    )
  }
}

export default PresentsMasters
