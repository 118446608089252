import { put, call, all, takeEvery, select } from 'redux-saga/effects'
import { initialize, formValueSelector, change, reset } from 'redux-form'
import { yesNoModal } from '../../modalsListeners/yesNoModal'
import { getAuth } from '../../../selectors/access/auth'
import uacActionsTypes from '../../../constants/actions/dossier/common/uac'
import { dossierSubTypeId } from '../../../constants/dossier/common/dossierSubType'
import { dossierTypes as dossierTypesConstant } from '../../../constants/dossier/common/dossierTypes'
import messageModalActions from '../../../actions/modals/messageModal'
import { fetchSalesDossierDependants, updateDependantsSaleDossierAndHeader, fetchSalesEconomic, fetchDossierRelatedDossiersSuccess } from '../../../actions/dossier/sales'
import { fetchCombosSuccess } from '../../../actions/combos/combos'
import { showLoader, hideLoader } from '../../../actions/common'
import getPurchaseTypesByDossierSubType from '../../../services/purchaseType/getPurchaseTypesByDossierSubType'
import getFuelsService from '../../../services/fuel/getFuels'
import getPreviousUsage from '../../../services/previousUsage/getPreviousUsage'
import getEntityTypes from '../../../services/entityType/getEntityTypes'
import getChannels from '../../../services/sourcechannel/getChanel'
import getSegmentations from '../../../services/segmentation/getSegmentation'
import deletePurchaseUAC from '../../../services/dossier/delete/deletePurchase'
import postDossierPurchaseUAC from '../../../services/dossier/post/postDossierPurchaseUAC'
import getDossierPurchaseUAC from '../../../services/dossier/get/getDossierPurchaseUAC'
import putDossierPurchaseUACAssignToSale from '../../../services/dossier/put/putDossierPurchaseUACAssignToSale'
import dossierPurchaseIdUACService from '../../../services/dossier/put/dossierPurchaseIdUAC'
import getDossierRelatedDossiers from '../../../services/dossier/get/getDossierRelatedDossiers'
import getEntityById from '../../../services/entity/getEntityById'
import getIvaTypeByDossierSubType from '../../../services/ivaType/getIvaTypeByDossierSubType'
import { handleError } from '../../errors/errorManager'
import { initializeDossier } from '../commonDossierSagas'
import { fetchRegistrationDeliveryDossierDependants, fetchRegistrationDeliveryEconomic, updateDependantsRegistrationDeliveryDossierAndHeader } from '../../../actions/dossier/registrationDelivery'
import { fetchDependantsRegistrationDeliveryDossier } from '../registrationDelivery/fetchRegistrationDeliveryDossier'
import settingsEntityPurchase from '../../../settingPurchaseEntity.json'
import getEntityByDni from '../../../services/entity/getEntityByDni'
import { getDossierRelatedDossiersConf } from '../../../selectors/dossier/common/dossierRelatedDossiers'
import getMaritalStatus from '../../../services/maritalStatus/getMaritalStatus'
import { openUacModal } from '../../../actions/management/management'

export function * fetchUACUnitEdition ({ purchaseId, openUACEditionFn, dossierType }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    let organizedUnitId
    let saleSubTypeId
    if (dossierType === dossierTypesConstant.registrationDelivery) {
      organizedUnitId = yield select(state => state.registrationDeliveryDossier.organizedUnitId)
      saleSubTypeId = yield select(state => state.registrationDeliveryDossier.dossierSubTypeId)
    } else {
      organizedUnitId = yield select(state => state.salesDossier.organizedUnitId)
      saleSubTypeId = yield select(state => state.salesDossier.dossierSubTypeId)
    }
    const [purchaseTypeCombo, fuelCombo, previousUsageCombo, entityTypeCombo, channelCombo, maritalStatusCombo, segmentationCombo, uacIvaTpyes] = yield all([
      call(getPurchaseTypesByDossierSubType, auth.token, saleSubTypeId !== dossierSubTypeId.model_agency ? dossierSubTypeId.compra_vo : dossierSubTypeId.model_agency),
      call(getFuelsService, auth.token),
      call(getPreviousUsage, organizedUnitId, auth.token),
      call(getEntityTypes, auth.token, saleSubTypeId),
      call(getChannels, auth.token),
      call(getMaritalStatus, auth.token),
      call(getSegmentations, auth.token),
      call(getIvaTypeByDossierSubType, auth.token, dossierSubTypeId.compra_vo)
    ])
    yield put(fetchCombosSuccess({ purchaseTypeCombo, fuelCombo, previousUsageCombo, entityTypeCombo, channelCombo, maritalStatusCombo, segmentationCombo, uacIvaTpyes }))
    if (purchaseId) {
      const UAC = yield call(getDossierPurchaseUAC, purchaseId, auth.token)
      yield put(initialize('uacModal', UAC))
      yield put(openUacModal({ uacUnit: UAC }))
    } else {
      yield put(openUacModal())
    }
  } catch (error) {
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('registrationDeliveryDossier'))
    }
    yield call(handleError, { error, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchUACUnitEdition () {
  yield takeEvery(uacActionsTypes.FETCH_UAC_UNIT_EDITION, fetchUACUnitEdition)
}

export function * deleteUAC ({ purchaseId, dossierType }) {
  try {
    const auth = yield select(getAuth)
    let salesDossier = yield select(state => state.salesDossier)
    if (dossierType === dossierTypesConstant.registrationDelivery) {
      salesDossier = yield select(state => state.registrationDeliveryDossier)
    }
    const showAlertSendPending = salesDossier.showAlertSendPending
    const dossierId = salesDossier.dossierId
    if (showAlertSendPending) {
      const confirmed = yield call(yesNoModal, 'editDossierAlert')
      if (confirmed) {
        yield put(showLoader())
        yield call(deletePurchaseUAC, purchaseId, auth.token, salesDossier.lastAccessDate)
        if (dossierType === dossierTypesConstant.registrationDelivery) {
          yield put(fetchDependantsRegistrationDeliveryDossier(dossierId))
          yield put(updateDependantsRegistrationDeliveryDossierAndHeader(dossierId))
        } else if (dossierType === dossierTypesConstant.sales) {
          yield put(fetchSalesDossierDependants(dossierId))
          yield put(updateDependantsSaleDossierAndHeader(dossierId))
        }
      }
    } else {
      yield put(showLoader())
      yield call(deletePurchaseUAC, purchaseId, auth.token, salesDossier.lastAccessDate)
      if (dossierType === dossierTypesConstant.registrationDelivery) {
        yield put(fetchDependantsRegistrationDeliveryDossier(dossierId))
      } else if (dossierType === dossierTypesConstant.sales) {
        yield put(fetchSalesDossierDependants(dossierId))
      }
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          if (dossierType === dossierTypesConstant.registrationDelivery) {
            const regDevDossierState = yield select(state => state.registrationDeliveryDossier)
            yield call(initializeDossier, dossierTypesConstant.registrationDelivery, regDevDossierState)
          } else {
            const salesDossierState = yield select(state => state.salesDossier)
            yield call(initializeDossier, dossierTypesConstant.sales, salesDossierState)
          }
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('registrationDeliveryDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDeleteUAC () {
  yield takeEvery(uacActionsTypes.DELETE_UAC_UNIT, deleteUAC)
}

export function * updateUAC ({ uac, closeModalFunc, openVehicleListModalFn, dossierType }) {
  try {
    const auth = yield select(getAuth)

    let stateDossier = yield select(state => state.salesDossier)

    if (dossierType === dossierTypesConstant.registrationDelivery) {
      stateDossier = yield select(state => state.registrationDeliveryDossier)
    }
    const showAlertSendPending = stateDossier.showAlertSendPending
    const dossierId = stateDossier.dossierId
    if (showAlertSendPending) {
      const confirmed = yield call(yesNoModal, 'editDossierAlert')
      if (confirmed) {
        yield put(showLoader())
        const vehicleList = yield call(dossierPurchaseIdUACService, dossierId, uac.purchaseId, uac, auth.token, stateDossier.lastAccessDate)
        if (vehicleList && vehicleList.length) {
          yield call(openVehicleListModalFn, true, vehicleList)
        } else {
          yield call(closeModalFunc)
          yield put(showLoader())
          if (dossierType === dossierTypesConstant.sales) {
            yield put(fetchSalesDossierDependants(dossierId))
            yield put(updateDependantsSaleDossierAndHeader(dossierId))
          } else if (dossierType === dossierTypesConstant.registrationDelivery) {
            yield put(fetchRegistrationDeliveryDossierDependants(dossierId))
            yield put(updateDependantsRegistrationDeliveryDossierAndHeader(dossierId))
          }

          yield call(closeModalFunc)
        }
        yield put(hideLoader())
      }
    } else {
      yield put(showLoader())
      const vehicleList = yield call(dossierPurchaseIdUACService, dossierId, uac.purchaseId, uac, auth.token, stateDossier.lastAccessDate)
      if (vehicleList && vehicleList.length) {
        yield call(openVehicleListModalFn, true, vehicleList)
      } else {
        yield call(closeModalFunc)
        if (dossierType === dossierTypesConstant.sales) {
          yield put(fetchSalesDossierDependants(dossierId))
        } else if (dossierType === dossierTypesConstant.registrationDelivery) {
          yield put(fetchRegistrationDeliveryDossierDependants(dossierId))
        }
      }
      yield put(hideLoader())
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          yield call(closeModalFunc)
          if (dossierType === dossierTypesConstant.registrationDelivery) {
            const regDevDossierState = yield select(state => state.registrationDeliveryDossier)
            yield call(initializeDossier, dossierTypesConstant.registrationDelivery, regDevDossierState)
          } else {
            const salesDossierState = yield select(state => state.salesDossier)
            yield call(initializeDossier, dossierTypesConstant.sales, salesDossierState)
          }
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('registrationDeliveryDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchUpdateUAC () {
  yield takeEvery(uacActionsTypes.UPDATE_UAC, updateUAC)
}

export function * checkPurchaseTypeAndSetDefaultEntity (typeId, ouId) {
  let entity = null
  if (settingsEntityPurchase) {
    let foundConfig = settingsEntityPurchase.find(s => s.purchaseTypeId === typeId)
    if (foundConfig) {
      const auth = yield select(getAuth)
      entity = yield call(getEntityByDni, foundConfig.DniCif, ouId, auth.token)
    }
  }
  return entity
}

export function * createUAC ({ uac, closeModalFunc, openVehicleListModalFn, vehicleId, dossierType }) {
  try {
    const auth = yield select(getAuth)
    let stateDossier = yield select(state => state.salesDossier)
    if (dossierType === dossierTypesConstant.registrationDelivery) {
      stateDossier = yield select(state => state.registrationDeliveryDossier)
    }
    let showAlertSendPending = stateDossier.showAlertSendPending
    let dossierId = stateDossier.dossierId
    const showModalPayPerDrive = uac.payPerDriveAlert !== undefined ? uac.payPerDriveAlert : false
    let defaultEntity = yield call(checkPurchaseTypeAndSetDefaultEntity, uac.purchaseTypeId, uac.organizedUnitId)
    let uacMod = {
      ...uac,
      uacEntityOwner: defaultEntity || uac.uacEntityOwner
    }
    let confirmed = false
    if (uac.rescueLeasing) {
      confirmed = yield call(yesNoModal, 'rescueLeasingAlert')
      if (!confirmed) {
        return null
      }
    }
    if (uac.additionalInvoice) {
      confirmed = yield call(yesNoModal, 'additionalInvoiceAlert')
      if (!confirmed) {
        return null
      }
    }
    if (defaultEntity) {
      confirmed = yield call(yesNoModal, 'entityWillBeReplaced')
      if (!confirmed) {
        return null
      }
      uacMod.entityFromParentDossier = false
    }
    if (showAlertSendPending || showModalPayPerDrive) {
      confirmed = false
      if (showModalPayPerDrive) {
        confirmed = yield call(yesNoModal, 'previousUsageUAC')
      }
      if (showAlertSendPending) {
        confirmed = yield call(yesNoModal, 'editDossierAlert')
      }
      if (confirmed) {
        yield put(showLoader())
        const vehicleList = yield call(postDossierPurchaseUAC, dossierId, uacMod, auth.token, stateDossier.lastAccessDate, vehicleId)
        if (vehicleList && vehicleList.length) {
          yield call(openVehicleListModalFn, true, vehicleList)
        } else {
          yield put(messageModalActions.openSuccessMessageModal('MESSAGES.POST_TABLE_ITEM_SUCCESS'))
          yield call(closeModalFunc)
          if (dossierType === dossierTypesConstant.registrationDelivery) {
            yield put(fetchRegistrationDeliveryDossierDependants(dossierId))
            yield put(updateDependantsRegistrationDeliveryDossierAndHeader(dossierId))
          } else {
            yield put(fetchSalesDossierDependants(dossierId))
            yield put(updateDependantsSaleDossierAndHeader(dossierId))
          }
        }
      }
    } else {
      yield put(showLoader())
      const vehicleList = yield call(postDossierPurchaseUAC, dossierId, uacMod, auth.token, stateDossier.lastAccessDate, vehicleId)
      if (vehicleList && vehicleList.length) {
        yield call(openVehicleListModalFn, true, vehicleList)
      } else {
        yield put(messageModalActions.openSuccessMessageModal('MESSAGES.POST_TABLE_ITEM_SUCCESS'))
        yield call(closeModalFunc)
        if (dossierType === dossierTypesConstant.registrationDelivery) {
          yield put(fetchRegistrationDeliveryDossierDependants(dossierId))
          yield put(updateDependantsRegistrationDeliveryDossierAndHeader(dossierId))
        } else {
          yield put(fetchSalesDossierDependants(dossierId))
          yield put(updateDependantsSaleDossierAndHeader(dossierId))
        }
      }
    }
    if (dossierType === dossierTypesConstant.registrationDelivery) {
      yield put(fetchRegistrationDeliveryEconomic(dossierId, false, true))
    } else {
      yield put(fetchSalesEconomic(dossierId, false, true))
    }
    const dossierRelatedDossiersConf = yield select(getDossierRelatedDossiersConf)
    const dossierRelatedDossiers = yield call(getDossierRelatedDossiers, dossierId, dossierRelatedDossiersConf.orderby, auth.token)
    yield put(fetchDossierRelatedDossiersSuccess(dossierRelatedDossiers))
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          yield call(closeModalFunc)
          if (dossierType === dossierTypesConstant.registrationDelivery) {
            const stateRegDevDossier = yield select(state => state.registrationDeliveryDossier)
            yield call(initializeDossier, dossierTypesConstant.registrationDelivery, stateRegDevDossier)
          } else {
            const salesDossierState = yield select(state => state.salesDossier)
            yield call(initializeDossier, dossierTypesConstant.sales, salesDossierState)
          }
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('MESSAGES.POST_TABLE_ITEM_ERROR', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('registrationDeliveryDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchCreateUAC () {
  yield takeEvery(uacActionsTypes.CREATE_UAC, createUAC)
}

export function * chooseUACSuggestion ({ purchaseId, vehicle, closeSuggestionModalFunc, closeUACModalFunc, dossierType }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    let salesDossier = yield select(state => state.salesDossier)
    if (dossierType === dossierTypesConstant.registrationDelivery) {
      salesDossier = yield select(state => state.registrationDeliveryDossier)
    }
    const dossierId = salesDossier.dossierId
    if (purchaseId) {
      yield call(putDossierPurchaseUACAssignToSale, dossierId, purchaseId, vehicle.purchaseIsHistorical, auth.token, salesDossier.lastAccessDate)
      yield call(closeSuggestionModalFunc)
      yield call(closeUACModalFunc)
      if (dossierType === dossierTypesConstant.sales) {
        yield put(updateDependantsSaleDossierAndHeader(dossierId))
      } else if (dossierType === dossierTypesConstant.registrationDelivery) {
        yield put(updateDependantsRegistrationDeliveryDossierAndHeader(dossierId))
      }
    } else {
      const state = yield select(state => state)
      const uacFormSelector = formValueSelector('uacModal')
      const previousUacVehicleComplete = uacFormSelector(state, 'uacVehicleComplete')
      const uacVehicleComplete = {
        ...previousUacVehicleComplete,
        dealerNumber: vehicle.dealerNumber,
        vehicleBrand: vehicle.vehicleBrand,
        brandId: vehicle.vehicleBrandId,
        model: vehicle.vehicleModel,
        color: vehicle.color,
        tapestry: vehicle.tapestry,
        fuelId: vehicle.fuelId,
        km: vehicle.km,
        licensePlate: vehicle.licensePlate,
        licensePlateDate: vehicle.licensePlateDate,
        vinChassis: vehicle.vinChassis,
        vehicleId: vehicle.vehicleId
      }
      yield put(change('uacModal', 'uacVehicleComplete', uacVehicleComplete))
      yield put(change('uacModal', 'isPartOfPayment', vehicle.isPartOfPayment))
      yield call(closeSuggestionModalFunc)
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          yield call(closeSuggestionModalFunc)
          yield call(closeUACModalFunc)
          if (dossierType === dossierTypesConstant.sales) {
            const salesDossierState = yield select(state => state.salesDossier)
            yield call(initializeDossier, dossierTypesConstant.sales, salesDossierState)
          } else if (dossierType === dossierTypesConstant.registrationDelivery) {
            const salesDossierState = yield select(state => state.registrationDeliveryDossier)
            yield call(initializeDossier, dossierTypesConstant.registrationDelivery, salesDossierState)
          }
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('registrationDeliveryDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchChooseUACSuggestion () {
  yield takeEvery(uacActionsTypes.CHOOSE_UAC_SUGGESTION, chooseUACSuggestion)
}

export function * fetchUACClientFromDocument ({ entityId }) {
  try {
    const auth = yield select(getAuth)
    const entity = yield call(getEntityById, entityId, auth.token)
    yield put(change('uacModal', 'uacEntityOwner', entity))
  } catch (error) {
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('registrationDeliveryDossier'))
    }
    yield call(handleError, { error, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchUACClientFromDocument () {
  yield takeEvery(uacActionsTypes.FETCH_UAC_CLIENT_FROM_DOCUMENT, fetchUACClientFromDocument)
}
