import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import SignTypeMaster from '../../../components/masters/signType/SignTypeMaster'
import { translate } from 'react-polyglot'
import { fetchSignTypeList, openSignTypeModal } from '../../../actions/masters/signType/signType'

export function mapStateToProps (state) {
  return {
    ...state.signType
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchSignTypeList,
      openSignTypeModal
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(SignTypeMaster))
