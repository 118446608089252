import apiFetch from '../apiFetch'

export default function (values, paymentMethodId, token) {
  const body = {
    isDeleted: false,
    paymentMethodLanguage: values
  }
  return apiFetch({
    endPoint: 'PaymentMethod/Put/' + paymentMethodId,
    method: 'PUT',
    body: body,
    token: token
  })
}
