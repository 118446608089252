import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { fetchCampaignGroupList, openCampaignGroupModal, deleteCampaignGroup, recoverCampaignGroup, openEditDocumentalConditionModalSuccess } from '../../../actions/masters/masters'
import CampaingGroupMaster from '../../../components/masters/campaignGroup/CampaingGroupMaster'

export function mapStateToProps (state) {
  return {
    ...state.campaignGroup,
    combos: {
      campaignClass: state.combos.campaignClass
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      recoverCampaignGroup,
      deleteCampaignGroup,
      openCampaignGroupModal,
      fetchCampaignGroupList,
      openEditDocumentalConditionModalSuccess
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(CampaingGroupMaster))
