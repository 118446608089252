import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import EntityMasters from '../../../components/masters/entity/EntityMasters'
import { translate } from 'react-polyglot'
import { openSourceChannelModal, deleteSourceChannelModal } from '../../../actions/masters/masters'

export function mapStateToProps (state) {
  return {
    ...state.entityMasters
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      openSourceChannelModal,
      deleteSourceChannelModal
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(EntityMasters))
