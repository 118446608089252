import React, { PureComponent } from 'react'
import { Grid } from 'react-bootstrap'

class Access extends PureComponent {
  render () {
    return (
      <div className="bg-auth">
        <Grid>
          {this.props.children}
        </Grid>
      </div>
    )
  }
}
export default Access
