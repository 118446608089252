import receiptActionTypes from '../../constants/actions/reports/receiptReport'

export function fetchReceiptList (filter) {
  return {
    type: receiptActionTypes.FETCH_RECEIPT_REPORT,
    filter
  }
}

export function fetchReceiptReportSuccess (receiptList, filter) {
  return {
    type: receiptActionTypes.FETCH_RECEIPT_REPORT_SUCCESS,
    receiptList,
    filter
  }
}

export function downloadReceiptList (filter) {
  return {
    type: receiptActionTypes.DOWNLOAD_RECEIPT_REPORT,
    filter
  }
}
