import React, { PureComponent } from 'react'
import { Tabs, Tab, Table, Tooltip, OverlayTrigger /* Button */ } from 'react-bootstrap'
import { urgencyTypesIdToUrgencyTabsId, urgencyTabsIdToUrgencyTypesId } from '../../../../constants/backendIds'
import IDocCarPagination from '../../../../components/commons/iDocCarPagination'
import TaskListRow from '../../../../_v2/components/dashboard/taskList/TaskListRow'

class TasksList extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      urgency: 0,
      maxiTable: false,
      hideFilters: false
    }
  }

  onSelectUrgencyTaskListTab (eventKey) {
    this.props.actions.fetchFilteredDashboardTask(
      this.props.activeTaskDescription,
      this.props.urgencyType,
      this.props.activeTaskId,
      this.props.orderby,
      1, // page to move when change task list tab
      this.props.pageSize,
      urgencyTabsIdToUrgencyTypesId[eventKey]
    )
    this.setState({ urgency: urgencyTabsIdToUrgencyTypesId[eventKey] })
  }

  onClickTaskListColumn (columnCode) {
    let columnCodeOrder = encodeURIComponent('+' + columnCode)
    if (columnCodeOrder === this.props.orderby) {
      columnCodeOrder = '-' + columnCode
    }
    this.props.actions.fetchFilteredDashboardTask(
      this.props.activeTaskDescription,
      this.props.urgencyType,
      this.props.activeTaskId,
      columnCodeOrder,
      this.props.page,
      this.props.pageSize,
      this.props.urgencyTypeTask
    )
  }

  selectTotalDossiersByUrgency (urgency, activeTaskValues) {
    let total = 0
    switch (urgency[0] || urgency) {
      case 0:
        if (activeTaskValues && activeTaskValues.total) {
          total = activeTaskValues.total
        }
        break
      case 2:
        if (activeTaskValues && activeTaskValues.totalHigh) {
          total = activeTaskValues.totalHigh
        }
        break
      case 3:
        if (activeTaskValues && activeTaskValues.totalMedium) {
          total = activeTaskValues.totalMedium
        }
        break
      case 4:
        if (activeTaskValues && activeTaskValues.totalLow) {
          total = activeTaskValues.totalLow
        }
        break
    }
    return total
  }

  exportTaskToExcel () {
    let totalDossiers = this.selectTotalDossiersByUrgency(this.props.urgencyTypeTask, this.props.activeTaskValues)
    this.props.actions.getFilteredTaskToExcel(this.props.activeTaskId, '', 1, totalDossiers, this.state.urgency)
  }

  toogleMaxiTable () {
    this.setState({ maxiTable: !this.state.maxiTable })
  }

  render () {
    const {
      t,
      // tasks,
      history,
      urgencyType,
      activeTaskId,
      activeTaskDescription,
      activeTaskValues,
      orderby,
      page,
      pageSize,
      urgencyTypeTask,
      actions: { fetchFilteredDashboardTask, openDocumentEditionModal, closeAddOrEditDocumentModal, /* navigateTo, */ setActiveTab, setActiveSection, setScrollTo }
    } = this.props
    let totalDossiers = this.selectTotalDossiersByUrgency(urgencyTypeTask, activeTaskValues)
    const tKey = 'DASHBOARD_TASK.TASK_LIST.'
    const dKey = 'GLOBAL.TABLES.'
    const maximizate = (<Tooltip id="editProductTooltip">{`${t(dKey + (this.state.maxiTable ? 'MINIMIZE_TABLE' : 'MAXIMIZE_TABLE'))}`}</Tooltip>)
    const hideInnerMenu = (<Tooltip id="editProductTooltip">{`${t(dKey + (this.props.hideMenu ? 'SHOW_INNER_MENU' : 'HIDE_INNER_MENU'))}`}</Tooltip>)
    if (!activeTaskId) {
      return (
        <p className="task-content-select-task">{t(`${tKey}SELECT_TASK_MESSAGE`)}</p>
      )
    } else {
      return (
        <div className="inner-results-panel">
          <div className="section-results-header">
            <div className="section-header-title">
              <OverlayTrigger placement="right" overlay={hideInnerMenu}>
                <a onClick={() => this.props.toogleInnerMenu()} className={this.props.hideMenu ? 'collapseBtn' : 'collapseBtn closeInnerMenu'}><i className={this.props.hideMenu ? 'iDocIcon-Open-inner-menu' : 'iDocIcon-Close-inner-menu'}></i></a>
              </OverlayTrigger>
              <h4>{activeTaskDescription}<a onClick={() => this.toogleHideFilters()} className='toogleShowFilters'><i /* className={this.state.hideFilters ? 'iDocIcon-filter-off' : 'iDocIcon-filter'} */></i></a></h4>
              {totalDossiers > 0 &&
                <a onClick={() => this.exportTaskToExcel()} className="_Btn new"><i className="iDocIcon-file-excel"></i>{t('SEARCH.ACTIONS.EXPORT_EXCEL')}</a>
              }
            </div>
            {activeTaskValues.total !== null && (
              <Tabs
                activeKey={urgencyTypesIdToUrgencyTabsId[urgencyTypeTask]}
                onSelect={this.onSelectUrgencyTaskListTab.bind(this)}
                id="urgencyTabs"
              >
                <Tab eventKey={0} title={`${t(`${tKey}URGENCY_TYPES.TOTAL`)} (${activeTaskValues.total})`}>
                </Tab>
                <Tab eventKey={1} title={`${t(`${tKey}URGENCY_TYPES.HIGH`)} (${activeTaskValues.totalHigh})`}>
                </Tab>
                <Tab eventKey={2} title={`${t(`${tKey}URGENCY_TYPES.MEDIUM`)} (${activeTaskValues.totalMedium})`}>
                </Tab>
                <Tab eventKey={3} title={`${t(`${tKey}URGENCY_TYPES.LOW`)} (${activeTaskValues.totalLow})`}>
                </Tab>
              </Tabs>
            )}
          </div>
          <div className="section-results-body">
            <div className='searchResoultsContainer'>
              <div id="dashboard-tasks-table-wrapper" className={'table-wrapper' + (this.state.maxiTable ? ' maxiTable' : '')}>
                {/* table options, maximize, etc.... */}
                <div className='flexInLine al-R'>
                  <OverlayTrigger placement="left" overlay={maximizate}>
                    <a onClick={() => this.toogleMaxiTable()} className='_Btn accept stk o-icn maximizeBtn'><i className={(this.state.maxiTable ? 'iDocIcon-Minimize' : 'iDocIcon-Maximize')}></i></a>
                  </OverlayTrigger>
                </div>
                {/* table */}

                <Table hover responsive>
                  <thead>
                    <tr>
                      {
                        activeTaskValues.columns.map(column => {
                          const urgenciaTooltip = <Tooltip id="urgenciaTooltip">{t(`${tKey}TABLE.TOOLTIP.ORDER_BY_URGENCY`)}</Tooltip>
                          return (
                            <th
                              key={column.columnCode}
                              onClick={this.onClickTaskListColumn.bind(this, column.columnCode)}
                              className={column.columnType === 9 ? 'td-money' : ''}
                            >
                              {column.columnType !== 1 ? column.columnDescription : ''}&nbsp;
                              {
                                column.columnType === 1
                                  ? (<OverlayTrigger placement="right" overlay={urgenciaTooltip}>
                                    <i className={(() => {
                                      let clase = 'ico-sort'
                                      if (orderby === '+' + column.columnCode) {
                                        clase += '-selected-asc'
                                      } else if (orderby === '-' + column.columnCode) {
                                        clase += '-selected-desc'
                                      }
                                      return clase
                                    })()} />
                                  </OverlayTrigger>)
                                  : (<i className={(() => {
                                    let clase = 'ico-sort'
                                    if (orderby === '+' + column.columnCode) {
                                      clase += '-selected-asc'
                                    } else if (orderby === '-' + column.columnCode) {
                                      clase += '-selected-desc'
                                    }
                                    return clase
                                  })()} />)
                              }
                            </th>
                          )
                        })
                      }
                      <th key="showDetail"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {activeTaskValues.rows &&
                      activeTaskValues.rows.map((row, idr) => {
                        const isLastPage = activeTaskValues.pagesCount === page
                        const currentPageSize = (isLastPage ? activeTaskValues.total % pageSize : pageSize)
                        return (
                          <TaskListRow
                            key={idr}
                            history={history}
                            idr={idr}
                            columns={activeTaskValues.columns}
                            row={row}
                            pageSize={currentPageSize}
                            navigationType={activeTaskValues.navigationType}
                            actions={{
                              openDocumentEditionModal,
                              closeAddOrEditDocumentModal,
                              /* navigateTo, */
                              setActiveTab,
                              setActiveSection,
                              fetchFilteredDashboardTask: () => fetchFilteredDashboardTask(activeTaskDescription, urgencyType, activeTaskId, orderby, page, pageSize, urgencyTypeTask),
                              setScrollTo
                            }}
                            t={t}
                          />
                        )
                      })
                    }
                  </tbody>
                </Table>

                {
                  totalDossiers > 0 && activeTaskValues.pagesCount > 0 && (
                    <div className="table-footer">
                      {
                        <IDocCarPagination
                          id="btn_pag_sales"
                          page={page}
                          pagesCount={activeTaskValues.pagesCount}
                          totalRows={totalDossiers}
                          label={t(`${tKey}TABLE.OF`)}
                          onSelectAction={(newPage) => fetchFilteredDashboardTask(activeTaskDescription, urgencyType, activeTaskId, orderby, newPage, pageSize, urgencyTypeTask)}
                        />
                      }
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      )
    }
  }
}

export default TasksList
