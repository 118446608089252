import apiFetch from '../apiFetch'

export default function (documentId, dossierId, token, lastAccessDate) {
  const queryParamsArray = [
    documentId ? `documentId=${documentId}` : null,
    dossierId ? `dossierId=${dossierId}` : null
  ]
  const queryParams = queryParamsArray.reduce(
    (result, query, idx) => result + (query ? (result ? `&${query}` : `?${query}`) : '')
    , ''
  )
  return apiFetch({
    endPoint: `/Document/SealDocumentStamp${queryParams}`,
    method: 'POST',
    body: null,
    token: token,
    lastAccessDate
  })
}
