import subscriptionsAdmTypes from '../../constants/actions/admin/subscriptionsAdmin'

export function fetchEventSubscriptionsFromAdmin (userId) {
  return {
    type: subscriptionsAdmTypes.FETCH_EVENT_SUBSCRIPTIONS_FROM_ADMIN,
    userId
  }
}

export function saveEventSubscriptionFromAdmin (subscriptionId, eventSubscription, userId, resolve, reject) {
  return {
    type: subscriptionsAdmTypes.SAVE_EVENT_SUBSCRIPTION_FROM_ADMIN,
    subscriptionId,
    eventSubscription,
    userId,
    resolve,
    reject
  }
}

export function removeEventSubscriptionFromAdmin (subscriptionId, userId) {
  return {
    type: subscriptionsAdmTypes.DELETE_EVENT_SUBSCRIPTION_FROM_ADMIN,
    subscriptionId,
    userId
  }
}

export function fetchEventSubscriptionsFromAdminSuccess (eventSubscriptions) {
  return {
    type: subscriptionsAdmTypes.FETCH_EVENT_SUBSCRIPTIONS_FROM_ADMIN_SUCCESS,
    eventSubscriptions
  }
}

export function enableEventSubscriptionFromAdmin (subscriptionId, userId) {
  return {
    type: subscriptionsAdmTypes.ENABLE_EVENT_SUBSCRIPTION_FROM_ADMIN,
    subscriptionId,
    userId
  }
}

export function disableEventSubscriptionFromAdmin (subscriptionId, userId) {
  return {
    type: subscriptionsAdmTypes.DISABLE_EVENT_SUBSCRIPTION_FROM_ADMIN,
    subscriptionId,
    userId
  }
}
