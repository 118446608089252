
import React, { PureComponent } from 'react'
import { reduxForm, Field } from 'redux-form'
import { Modal, Row, Col, Button } from 'react-bootstrap'
import InputText from '../commons/form/InputText'
import InputDropzone from '../commons/form/InputDropzone'
import InputCheckBox from '../commons/form/InputCheckBox'
import InputButtonsSelect from '../commons/form/InputButtonsSelect'
import EditableTable from '../commons/editableTable/EditableTable'
import { required } from '../../util/validationFunctions'
import { compareObjects } from '../../util/utils'
import { icoPosition } from '../../constants/backendIds'
import InputPercentage from '../commons/form/InputPercentage'

class TemplatesModal extends PureComponent {
  constructor () {
    super()
    this.state = {
      hasIco: false,
      nonEditableField: true,
      showCustomLogo: false,
      isNew: false
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (this.state.isNew !== this.props.templateModal.isNew) {
      this.setState({ isNew: this.props.templateModal.isNew === true })
    }
    if (this.props.templateModal.showModal && !nextProps.templateModal.showModal) {
      this.setState({ hasIco: false, nonEditableField: true })
    }
    if (nextProps.templateModal.showModal && (
      this.props.templateModal.templateId !== nextProps.templateModal.templateId ||
      !compareObjects(this.props.templateModal, nextProps.templateModal, true)
    )) {
      const templateData = nextProps.templateModal.templateData
      templateData.hasIco = !!templateData.icoPosition
      const icoCustom = templateData.icoPosition === icoPosition.CUSTOM
      this.setState({ hasIco: templateData.hasIco, showCustomLogo: icoCustom })
      nextProps.initialize(templateData)
    }
  }

  submitTemplate (values) {
    const cleanedValues = Object.assign({}, values)
    cleanedValues.templateId = '' + this.props.templateModal.templateId
    if (!cleanedValues.hasIco) {
      cleanedValues.icoPosition = null
    }
    if (!cleanedValues.icoPosition) {
      this.props.change('hasIco', false)
      this.setState({ hasIco: false })
    }
    if (cleanedValues.logoPosition) {
      cleanedValues.logoHeigth = cleanedValues.logoPosition.logoHeigth
      cleanedValues.logoWidth = cleanedValues.logoPosition.logoWidth
      cleanedValues.logoX = cleanedValues.logoPosition.logoX
      cleanedValues.logoY = cleanedValues.logoPosition.logoY
    }
    delete cleanedValues.fields
    delete cleanedValues.hasIco
    delete cleanedValues.fileNames
    delete cleanedValues.logoPosition

    this.props.actions.submitTemplate(cleanedValues)
  }

  closeModal (values) {
    const cleanedValues = Object.assign({}, values)
    cleanedValues.templateId = '' + this.props.templateModal.templateId
    if (!cleanedValues.hasIco) {
      cleanedValues.icoPosition = null
    }
    if (!cleanedValues.icoPosition) {
      this.props.change('hasIco', false)
      this.setState({ hasIco: false })
    }
    if (cleanedValues.logoPosition) {
      cleanedValues.logoHeigth = cleanedValues.logoPosition.logoHeigth
      cleanedValues.logoWidth = cleanedValues.logoPosition.logoWidth
      cleanedValues.logoX = cleanedValues.logoPosition.logoX
      cleanedValues.logoY = cleanedValues.logoPosition.logoY
    }
    delete cleanedValues.fields
    delete cleanedValues.hasIco
    delete cleanedValues.fileNames
    delete cleanedValues.logoPosition

    this.props.actions.closeEditTemplateModal(cleanedValues)
  }

  logoCustom (value) {
    if (value === icoPosition.CUSTOM) {
      this.setState({ showCustomLogo: true })
    } else {
      this.setState({ showCustomLogo: false })
    }
  }

  downloadTemplate (value) {
    if (value) {
      this.props.actions.downloadTemplate(this.props.templateModal.templateId)
    }
  }

  render () {
    const {
      t, handleSubmit,
      templateModal: { showModal, templateId, templateData: { fields, filenames } },
      combos: { templateFieldCombos },
      actions: { fetchTemplateFields, createTemplateField, updateTemplateField, deleteTemplateField, fetchTemplateFile }
    } = this.props
    const tKey = 'TEMPLATES.MODAL.'

    if (!showModal) return null

    let templateLogoPositionCombo = this.props.combos.templateLogoPositionCombo
    templateLogoPositionCombo = templateLogoPositionCombo.map((templateLogoPosition) => {
      return { ...templateLogoPosition, value: t(`${tKey}TABLE.COMBOS.LOGO_POSITION.${templateLogoPosition.value}`) }
    })

    let templateDataTypeCombo = this.props.combos.templateDataTypeCombo
    templateDataTypeCombo = templateDataTypeCombo.map((templateDataType) => {
      return { ...templateDataType, value: t(`${tKey}TABLE.COMBOS.DATA_TYPE.${templateDataType.value}`) }
    })
    return (
      <Modal show={showModal} bsSize="large">
        <form autoComplete="off" onSubmit={handleSubmit(this.submitTemplate.bind(this))}>
          <Modal.Header closeButton onHide={this.closeModal.bind(this)} >
            <Modal.Title>{t(`${tKey}TITLE`)}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Field
                colSm={12}
                id="versionName"
                name="versionName"
                controlLabel={t(`${tKey}DESCRIPTION`)}
                placeholder={t(`${tKey}DESCRIPTION`)}
                labelKey="value"
                component={InputText}
                inline
              />
              {filenames.map((file) => {
                return <Field
                  key={file.languageId}
                  colSm={12}
                  id={'file_' + file.languageId}
                  name={'file_' + file.languageId}
                  controlLabel={t(`${tKey}FILE`) + ' ' + file.descriptionLanguage}
                  placeholder={t(`${tKey}FILE`) + ' ' + file.descriptionLanguage}
                  component={InputDropzone}
                  fetchTemplateFile={() => fetchTemplateFile && fetchTemplateFile(templateId, file.languageId)}
                  inline
                  t={t}
                  preview={file.fileNameTemplate}
                  previewTooltipText={t(`${tKey}PREVIEW_TOOLTIP`)}
                  previewTooltipPosition="right"
                />
              })}
              <Field
                id="hasIco"
                name="hasIco"
                placeholder={t(`${tKey}HAS_ICO`)}
                component={InputCheckBox}
                onInputChange={(hasIco) => this.setState({ hasIco })}
                colSm={3}
                inline
              />
              {this.state.hasIco && (
                <Field
                  id="icoPosition"
                  name="icoPosition"
                  controlLabel={t(`${tKey}POSITION`)}
                  component={InputButtonsSelect}
                  options={templateLogoPositionCombo}
                  valueKey="id"
                  labelKey="value"
                  colSm={5}
                  onChange={(e) => this.logoCustom(e)}
                />
              )}
            </Row>
            {this.state.showCustomLogo && this.state.hasIco &&
              <Row>
                <Field
                  colSm={2}
                  id="logoPosition.logoHeigth"
                  name="logoPosition.logoHeigth"
                  controlLabel={t(`${tKey}HEIGHT`)}
                  placeholder={t(`${tKey}HEIGHT`)}
                  component={InputPercentage}
                />
                <Field
                  colSm={2}
                  id="logoPosition.logoWidth"
                  name="logoPosition.logoWidth"
                  controlLabel={t(`${tKey}WIDTH`)}
                  placeholder={t(`${tKey}WIDTH`)}
                  component={InputPercentage}
                />
                <Field
                  colSm={2}
                  id="logoPosition.logoX"
                  name="logoPosition.logoX"
                  controlLabel={t(`${tKey}POSITION_X`)}
                  placeholder={t(`${tKey}POSITION_X`)}
                  component={InputPercentage}
                />
                <Field
                  colSm={2}
                  id="logoPosition.logoY"
                  name="logoPosition.logoY"
                  controlLabel={t(`${tKey}POSITION_Y`)}
                  placeholder={t(`${tKey}POSITION_Y`)}
                  component={InputPercentage}
                />

                <Button
                  style={{ marginTop: 25 }}
                  className="btn-standard"
                  disabled={!this.props.templateModal.templateData.logoPosition}
                  onClick={() => { this.downloadTemplate(true) }}>{t(`${tKey}DOWNLOAD_TEMPLATE`)}
                </Button>

              </Row>
            }
            <Col sm={12}>
              <div className="text-right">
                <Button className="btn-standard" type="submit">{t(`${tKey}SAVE`)}</Button>
              </div>
            </Col>

            <hr />
            <Row>
              {!this.state.isNew &&
              <EditableTable
                t={t}
                columnsConfig={
                  [
                    {
                      th: t(`${tKey}TABLE.FIELD_TYPE`),
                      key: 'typeField',
                      isSelect: true,
                      selectConfig: {
                        placeholder: t(`${tKey}TABLE.FIELD_TYPE`),
                        options: templateDataTypeCombo,
                        valueKey: 'id',
                        labelKey: 'value',
                        onLoad: (val, key) => {
                          fetchTemplateFields(val)
                        },
                        onInputChange: (val, key, resolve, reject) => {
                          fetchTemplateFields(val, resolve, reject)
                        }
                      },
                      validationFunc: required
                    },
                    {
                      th: t(`${tKey}TABLE.FIELD`),
                      key: 'fieldId',
                      isSelect: true,
                      selectConfig: {
                        placeholder: t(`${tKey}TABLE.FIELD`),
                        options: templateFieldCombos,
                        valueKey: 'id',
                        labelKey: 'value',
                        dependantSelect: 'typeField'
                      },
                      validationFunc: required,
                      nonEditable: (!templateFieldCombos || templateFieldCombos.length === 0)
                    },
                    {
                      th: t(`${tKey}TABLE.PDF_FIELD_COD`),
                      key: 'name',
                      validationFunc: required
                    },
                    {
                      th: t(`${tKey}TABLE.REQUIRED`),
                      key: 'isMandatory',
                      isCheckbox: true,
                      className: 'text-center'
                    }
                  ]
                }
                editableObjectsArray={fields}
                tableClassName="vertical-middle"
                createAction={(values, resolve, reject) => {
                  createTemplateField(templateId, values, resolve, reject)
                }}
                updateAction={(values, resolve, reject) => {
                  updateTemplateField(templateId, values, resolve, reject)
                }}
                deleteAction={(values) => {
                  deleteTemplateField(templateId, values)
                }}
              />
              }
            </Row>
          </Modal.Body>
        </form>
      </Modal>
    )
  }
}

const validate = (values, props) => {
  const error = { logoPosition: {} }
  if (values.logoPosition) {
    let logoHeigth = parseFloat(values.logoPosition.logoHeigth)
    let logoWidth = parseFloat(values.logoPosition.logoWidth)
    let logoX = parseFloat(values.logoPosition.logoX)
    let logoY = parseFloat(values.logoPosition.logoY)
    if (!logoHeigth || (logoHeigth < 0.0 || logoHeigth > 100.0)) {
      error.logoPosition.logoHeigth = props.t('TEMPLATES.MODAL.VALIDATIONS.LOGO_RANGE_ERROR')
    }

    if (!logoWidth || (logoWidth < 0.0 || logoWidth > 100.0)) {
      error.logoPosition.logoWidth = props.t('TEMPLATES.MODAL.VALIDATIONS.LOGO_RANGE_ERROR')
    }

    if (!logoX || (logoX < 0.0 || logoX > 100.0)) {
      error.logoPosition.logoX = props.t('TEMPLATES.MODAL.VALIDATIONS.LOGO_RANGE_ERROR')
    }

    if (!logoY || (logoY < 0.0 || logoY > 100.0)) {
      error.logoPosition.logoY = props.t('TEMPLATES.MODAL.VALIDATIONS.LOGO_RANGE_ERROR')
    }
  } else if (values.icoPosition && values.icoPosition === icoPosition.CUSTOM && !values.logoPosition) {
    error.logoPosition.logoHeigth = props.t('TEMPLATES.MODAL.VALIDATIONS.LOGO_RANGE_ERROR')
    error.logoPosition.logoWidth = props.t('TEMPLATES.MODAL.VALIDATIONS.LOGO_RANGE_ERROR')
    error.logoPosition.logoX = props.t('TEMPLATES.MODAL.VALIDATIONS.LOGO_RANGE_ERROR')
    error.logoPosition.logoY = props.t('TEMPLATES.MODAL.VALIDATIONS.LOGO_RANGE_ERROR')
  }
  if (!values.icoPosition) {
    error.icoPosition = props.t('GLOBAL.FORMS.REQUIRED')
  }
  if (!values.versionName) {
    error.versionName = props.t('GLOBAL.FORMS.REQUIRED')
  }
  return error
}

export default reduxForm({
  form: 'templatesSearchModal',
  validate
})(TemplatesModal)
