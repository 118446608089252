import saleActionTypes from '../../constants/actions/reports/saleReport'

export function fetchSaleList (filter) {
  return {
    type: saleActionTypes.FETCH_SALE_REPORT,
    filter
  }
}

export function fetchSaleReportSuccess (saleList, filter) {
  return {
    type: saleActionTypes.FETCH_SALE_REPORT_SUCCESS,
    saleList,
    filter
  }
}

export function downloadSaleList (filter) {
  return {
    type: saleActionTypes.DOWNLOAD_SALE_REPORT,
    filter
  }
}
