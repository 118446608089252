import React, { PureComponent } from 'react'
import { Modal, Col, Row, Button } from 'react-bootstrap'
import { reduxForm, Field } from 'redux-form'
import InputText from '../../commons/form/InputText'
import InputSelect from '../../commons/form/InputSelect'

class RepairshopMasterModal extends PureComponent {
  constructor () {
    super()
    this.state = {
      type: null,
      values: null,
      deleted: false
    }
    this.required = this.required.bind(this)
  }

  submitDocument (values) {
    this.props.actions.submitRepairshop({
      repairshopId: values.repairshopId,
      organizedUnitId: values.organizedUnitId,
      name: values.name,
      description: values.description
    })
  }

  componentDidMount () {
    this.props.initialize(this.props.repairshop)
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevProps.showModal !== this.props.showModal && this.props.showModal === true) {
      this.props.initialize(this.props.repairshop)
      this.setState({
        repairshop: this.props.repairshop,
        deleted: false,
        values: 1,
        type: this.props.repairshop?.configParameter?.type
      })
    }
    if (!this.state.deleted && this.props.repairshop !== null && (this.props.repairshop?.value)?.length > this.state.values) {
      const count = (this.props.repairshop?.value)?.length
      this.setState({ values: count })
    }
  }

  required (value) {
    if (value || value === 0) {
      return undefined
    } else {
      return this.props.t('MASTERS.REPAIR_SHOPS_MASTERS.VALIDATION.VALUE_REQUIRED')
    }
  }

  render () {
    const {
      t, handleSubmit,
      showModal,
      combos: { organizedUnitsAllCombo }
    } = this.props
    const tKey = 'MASTERS.REPAIR_SHOPS_MASTERS.MODAL.'
    return (
      <Modal className="role-modal languages-modal" show={showModal} onHide={this.props.actions.closeRepairshopMasterModal} backdrop={'static'}>
        <form autoComplete="off" onSubmit={handleSubmit(this.submitDocument.bind(this))}>
          <Modal.Header closeButton onHide={this.props.actions.closeRepairshopMasterModal}>
            <Modal.Title>{t(`${tKey}TITLE`)}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="inputs-wrapper flexRow">
              <Col sm={4} className="custom-width-col">
                <Field
                  t={t}
                  colSm={4}
                  id='name'
                  name='name'
                  placeholder={t(`${tKey}NAME`)}
                  controlLabel={t(`${tKey}NAME`)}
                  component={InputText}
                />
              </Col>
              <Col sm={8} className="custom-width-col">
                <Field
                  t={t}
                  colSm={6}
                  id='description'
                  name='description'
                  placeholder={t(`${tKey}DESCRIPTION`)}
                  controlLabel={t(`${tKey}DESCRIPTION`)}
                  component={InputText}
                />
              </Col>
              <Col sm={4} className="custom-width-col">
                <Field
                  name="organizedUnitId"
                  controlLabel={t(`${tKey}OU`)}
                  placeholder={t(`${tKey}OU`)}
                  valueKey="id"
                  labelKey="value"
                  component={InputSelect}
                  colSm={0}
                  options={organizedUnitsAllCombo}
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Col sm={6} className="btn-wrapper" smPush={6}>
              <Button type="submit" className="btn-standard" disabled={this.props.pristine}>
                {t('MASTERS.SAVE')}
              </Button>
            </Col>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default reduxForm({
  form: 'editRepairshop',
  validate: (values, props) => {
    let error = {}
    const tKey = 'MASTERS.REPAIR_SHOPS_MASTERS.MODAL.VALIDATION.'
    if (!values.name) {
      error.name = props.t(`${tKey}NAME`)
    }
    if (!values.description) {
      error.description = props.t(`${tKey}DESCRIPTION`)
    }
    if (!values.organizedUnitId) {
      error.organizedUnitId = props.t(`${tKey}OU`)
    }

    return Object.keys(error).length > 0 ? error : {}
  }
})(RepairshopMasterModal)
