import React, { PureComponent } from 'react'
import { Row, Button, Col, Checkbox, Table } from 'react-bootstrap'
import { Field, reduxForm } from 'redux-form'
import InputText from '../../../../commons/form/InputText'

class TabPane7 extends PureComponent {
  render () {
    return (
      <Col sm={12} className="accessories-tabpane">
        <Row>
          <Col sm={12}>
            <div className="button-wrapper">
              <Button className="btn-standard-white">
                <i className="ico-check" /> Aprobar jefe taller
              </Button>
            </div>
          </Col>
        </Row>
        <Row className="accessory-wrapper">
          <Field
            colSm={4}
            name='CreationDateFrom'
            id='text_dateFrom'
            controlLabel='Fecha petición'
            placeholder='Fecha petición'
            leftIcon='ico-calendar'
            component={InputText}
          />
          <Col sm={4}>
            <Checkbox inline className="margin-check">
             Vehículo disponible para entrega
            </Checkbox>
          </Col>
          <Field
            colSm={4}
            name='deliveryDate'
            id='text_dateFrom'
            controlLabel='Fecha entrega'
            placeholder='Fecha entrega'
            leftIcon='ico-calendar'
            component={InputText}
          />
        </Row>
        <Row>
          <Table responsive className="table-tabPane table-tabPane6">
            <thead>
              <tr>
                <th>Opcionales</th>
                <th>Referencia</th>
                <th>Importe</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Checkbox inline>
                  Llantas
                  </Checkbox>
                </td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <Checkbox inline>
                  Sensor parking
                  </Checkbox>
                </td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <Checkbox inline>
                  KIT Premium
                  </Checkbox>
                </td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <Checkbox inline>
                  KIT Versión Aniversario Marca
                  </Checkbox>
                </td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </Table>
          <Table responsive className="table-tabPane table-tabPane6">
            <thead>
              <tr>
                <th>Accesorios</th>
                <th>Referencia</th>
                <th>Importe</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Checkbox inline>
                  Techo solar
                  </Checkbox>
                </td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <Checkbox inline>
                  Navegador
                  </Checkbox>
                </td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <Checkbox inline>
                  KIT Seguridad
                  </Checkbox>
                </td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <Checkbox inline>
                  Bola remolque
                  </Checkbox>
                </td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <Checkbox inline>
                  Estriberas
                  </Checkbox>
                </td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </Table>
        </Row>
        <Row className="margin-row">
          <Col sm={4}>
            <Table responsive className="table-tabPane table-tabPane6">
              <thead>
                <tr>
                  <th></th>
                  <th>Importe</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Accesorios</td>
                  <td>0,0</td>
                </tr>
                <tr>
                  <td>Opcionales</td>
                  <td>0,0</td>
                </tr>
                <tr>
                  <td><span>Total base</span></td>
                  <td><span>0,0</span></td>
                </tr>
              </tbody>
            </Table>
          </Col>
          <Col sm={8}>
            <div className="text-band">
              <span className="first">Ubicación entrega:</span>
              <span className="second">Ubicación entrega</span>
            </div>
            <div className="text-band">
              <span className="first">Box entrega:</span>
              <span className="second">Box entrega</span>
            </div>
          </Col>
        </Row>
      </Col>
    )
  }
}

export default reduxForm({ form: 'newForm' })(TabPane7)
