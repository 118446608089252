import React, { PureComponent } from 'react'
import SimpleTablePage from '../../containers/commons/general/SimpleTablePage'
import IDocCarPagination from '../commons/iDocCarPagination'
import VehiclePopover from '../search/VehiclePopover'
import EntityPopover from '../search/EntityPopover'
import DossierPopover from '../search/DossierPopover'
import { Tooltip, OverlayTrigger, Glyphicon } from 'react-bootstrap'
class Sign extends PureComponent {
  constructor () {
    super()
    this.state = {
    }
  }

  componentDidMount () {
    this.props.actions.searchDocuments(this.props.filters)
  }

  onOrderChange (e) {
    let newOrderBy = ''
    if (this.props.filters.orderBy && this.props.filters.orderBy.includes(`%2B${e.currentTarget.dataset.field}`)) {
      newOrderBy = `-${e.currentTarget.dataset.field}`
    } else {
      newOrderBy = `%2B${e.currentTarget.dataset.field}`
    }
    this.props.actions.searchDocuments({ type: this.props.filters.type, page: this.props.filters.page, pageSize: this.props.filters.pageSize, orderBy: newOrderBy })
  }

  getIconClass (field) {
    if (this.props.filters.orderBy && this.props.filters.orderBy.includes(field)) {
      if (this.props.filters.orderBy.includes('-')) {
        return 'ico-sort-selected-asc'
      } else {
        return 'ico-sort-selected-desc'
      }
    } else {
      return 'ico-sort'
    }
  }

  render () {
    const {
      t, documents, pages, count,
      filters: { type, page, orderBy, pageSize },
      actions: { searchDocuments }
    } = this.props
    const tKey = 'SIGN.'
    const navigationTooltip = (<Tooltip id="navigationTooltip">{t(`${tKey}TABLE.TOOLTIP.GO_TO`)}</Tooltip>)
    return (
      <div className="masters-container search-wrapper">
        <div className="search-header">
          <div className="title-user">
            <i className="ico-users" />
            <h2>{t(`${tKey}TITLE`)}</h2>
          </div>
          <div>
            <SimpleTablePage
              columns={[
                <th key={0} data-field="documentType" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.DOCUMENT_TYPE`)} <i className={this.getIconClass('documentType')}/></th>,
                <th key={1} data-field="dossier" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.DOSSIER`)} <i className={this.getIconClass('dossier')}/></th>,
                <th key={2} data-field="entity" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.ENTITY`)} <i className={this.getIconClass('entity')}/></th>,
                <th key={3} data-field="vehicle" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.VEHICLE`)} <i className={this.getIconClass('vehicle')}/></th>,
                <th key={4} data-field="creationDate" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.CREATION_DATE`)} <i className={this.getIconClass('creationDate')}/></th>,
                <th key={5}></th>
              ]}
              rows={documents.map((row, idx) => (
                <tr key={idx}>
                  <td>{row.documentType}</td>
                  <td className="see-more">
                    <DossierPopover
                      t={t}
                      dossier={row.dossier}
                      half={(documents.length || 1) / (idx + 1)}
                      idx={idx}
                      id={'popover-dossier' + idx}
                    />
                  </td>
                  <td className="see-more">
                    <EntityPopover
                      t={t}
                      entity={row.entity}
                      half={(documents.length || 1) / (idx + 1)}
                      idx={idx}
                      id={'popover-entity' + idx}
                    />
                  </td>
                  <td className="see-more">
                    <VehiclePopover
                      t={t}
                      vehicle={row.vehicle}
                      half={(documents.length || 1) / (idx + 1)}
                      idx={idx}
                      id={'popover-vehicle' + idx}
                    />
                  </td>
                  <td>{row.creationDate}</td>
                  <td key={idx} className="navigation">
                    {row.documentId && (
                      <OverlayTrigger placement="left" overlay={navigationTooltip}>
                        <Glyphicon
                          glyph="new-window"
                          onClick={() => row.isHistorical === false ? (
                            this.props.actions.openDocumentEditionModal(row.documentId, row.documentTypeId, row.documentParentTypeId, row.dossierId, row.entityId, row.vehicleId, row.organizedUnitId, null, () => searchDocuments(this.props.filters), false, '', '')
                          ) : (
                            this.props.actions.openDocumentEditionModalHistorical(row.documentId, row.documentTypeId, row.documentParentTypeId, row.dossierId, row.entityId, row.vehicleId)
                          )
                          }
                        />
                      </OverlayTrigger>
                    )}
                  </td>
                </tr>
              ))}
            />
            <div className="search-footer">
              <IDocCarPagination
                id="btn_pag_sales"
                page={page}
                pagesCount={pages}
                totalRows= {count}
                onSelectAction={(page) => searchDocuments({ type, page, pageSize, orderBy })}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Sign
