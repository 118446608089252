import React, { PureComponent } from 'react'
import { reduxForm, Field } from 'redux-form'
import { Row, Col, Modal, Button, FormGroup } from 'react-bootstrap'
import TextInput from '../../commons/TextInput'

class FuelModal extends PureComponent {
  submitFuel (values) {
    Object.keys(values).map(item => {
      if (values[item].languageId === undefined || values[item].languageId === null && (values[item].description !== null || values[item].name !== null)) {
        values[item].languageId = item.slice(-1)
      }
    })
    const body = []
    Object.keys(values).map(item => {
      body.push(values[item])
    })
    this.props.actions.submitFuel(body, this.props.fuelId)
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (prevProps.showFuelModal !== this.props.showFuelModal && this.props.showFuelModal === false) {
      let init = {}
      this.props.initialize({ ...init })
    }
    return this.props
  }

  componentDidUpdate () {
  }

  render () {
    const { t, showFuelModal, handleSubmit, languageList = [] } = this.props
    return (
      <Modal show={showFuelModal} onHide={this.props.actions.closeEditFuelModal} className="languages-modal">
        <Modal.Header closeButton>
          <i className="ico-users" /><Modal.Title>{t('MASTERS.FUEL_MASTERS.FUEL_MASTERS_TITLE')}</Modal.Title>
        </Modal.Header>
        <form autoComplete="off" onSubmit={handleSubmit(this.submitFuel.bind(this))}>
          <Modal.Body>
            <h2>{t('MASTERS.FUEL_MASTERS.ORGANIZED_UNIT')}</h2>
            <Row className="title-column">
              <Col sm={4}>
                <h2>{t('MASTERS.NAME')}</h2>
              </Col>
              <Col sm={6}>
                <h2>{t('MASTERS.DESCRIPTION')}</h2>
              </Col>
              <Col sm={2}>
                <h2>{t('MASTERS.LANGUAGE')}</h2>
              </Col>
            </Row>
            <FormGroup>
              {languageList.map((val, idx) => {
                return (
                  <Row key={idx} className="inputs-wrapper">
                    <Col sm={4} className="custom-width-col">
                      <Field
                        name={`lang${val.languageId}.name`}
                        placeholder={t('MASTERS.NAME')}
                        component={TextInput}
                      />
                    </Col>
                    <Col sm={6} className="custom-width-col">
                      <Field
                        name={`lang${val.languageId}.description`}
                        placeholder={t('MASTERS.DESCRIPTION')}
                        component={TextInput}
                      />
                    </Col>
                    <Col sm={2} className="language-column">
                      <p>{`${val.description} (${val.name})`}</p>
                    </Col>
                  </Row>
                )
              })}
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <Col sm={6} className="btn-wrapper" smPush={6}>
              <Button type="submit" className="btn-standard">
                {t('MASTERS.SAVE')}
              </Button>
            </Col>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

const validate = (values, props) => {
  const errors = {}
  props.languageList.map(language => {
    errors[`lang${language.languageId}`] = {}
    if (values[`lang${language.languageId}`]) {
      if (!(values[`lang${language.languageId}`].name)) {
        errors[`lang${language.languageId}`].name = props.t('MASTERS.VALIDATION.NAME_REQUIRED')
      }
      if (!(values[`lang${language.languageId}`].description)) {
        errors[`lang${language.languageId}`].description = props.t('MASTERS.VALIDATION.DESCRIPTION_REQUIRED')
      }
    } else {
      errors[`lang${language.languageId}`].name = props.t('MASTERS.VALIDATION.NAME_REQUIRED')
      errors[`lang${language.languageId}`].description = props.t('MASTERS.VALIDATION.DESCRIPTION_REQUIRED')
    }
  })
  return errors
}

export default reduxForm({
  form: 'editFuelModal',
  validate
})(FuelModal)
