import React, { PureComponent } from 'react'

class ExpandableText extends PureComponent {
  constructor () {
    super()
    this.state = {
      showAllText: false
    }
  }

  toogleAlltext () {
    this.setState({ showAllText: !this.state.showAllText })
  }

  render () {
    const {
      text
    } = this.props

    return text !== null && text !== undefined ? text.length < 30 ? text
      : <>
        {this.state.showAllText ? (text) : (text.substring(0, 25) + '...') }
        <i onClick={() => this.toogleAlltext()} className={this.state.showAllText ? 'iDocIcon-square-minus-show_less' : 'iDocIcon-square-plus-show_more'}></i>
      </> : ''
  }
}

export default ExpandableText
