import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import DiferentDocumentUploadModal from '../../components/management/DiferentDocumentUploadModal'
import { translate } from 'react-polyglot'
import { openModal } from '../../actions/common'
import { closeDiferentDocumentUploadModal, fecthValidateDocumentFleetPermission, uploadZipDocumentFleet, getDocumentTypesFleets } from '../../actions/management/management'
import { fetchDocumentsAllTypes } from '../../actions/combos/combos'
import { setUploadZipDocumentFleetProcess } from '../../actions/unattendedProcess/unattendedProcess'
export function mapStateToProps (state) {
  return {
    ...state.management.diferentDocumentUploadModal,
    combos: {
      documentsTypes: state.combos.documentsTypes
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      openModal,
      closeDiferentDocumentUploadModal,
      fetchDocumentsAllTypes,
      fecthValidateDocumentFleetPermission,
      uploadZipDocumentFleet,
      getDocumentTypesFleets,
      setUploadZipDocumentFleetProcess
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(DiferentDocumentUploadModal))
