
import React, { PureComponent } from 'react'
import { reduxForm, Field } from 'redux-form'
import { Modal, Col, Panel, Table } from 'react-bootstrap'
import InputText from '../../commons/form/InputText'
import InputDatePicker from '../../commons/form/InputDatePicker'
import InputCheckBox from '../../commons/form/InputCheckBox'
import InputSelect from '../../commons/form/InputSelect'
import IDocCarPagination from '../../commons/iDocCarPagination'
import { getDateNowMinDatePicker } from '../../../util/utils'
import { connect } from 'react-redux'
import AuditFileModalPage from '../../../containers/management/auxModals/AuditFileModalPage'
import { dossierTypes } from '../../../constants/backendIds'
import moment from 'moment'
import { validateDate } from '../../../util/validationFunctions'

class AuditAdminModal extends PureComponent {
  constructor () {
    super()
    this.state = {
      openAuditPanel: true,
      openCharacteristicsPanel: true,
      openDocumentsPanel: false,
      openFilePanel: true,
      showModal2: false,
      selectAll: false,
      checkboxes: {},
      manualCheck: {},
      form: {},
      itemsPerPage: 10,
      currentPage: 1,
      mount: true,
      documents: []
    }
  }

  onCheckAll (e) {
    const checkboxes = this.props?.documents?.reduce(
      (checkboxes, document) => ({
        ...checkboxes,
        [document.id]: e
      }),
      {}
    )
    this.setState({ checkboxes: checkboxes, selectAll: e, manualCheck: checkboxes })
  }

  onCheckOne (e, id) {
    const { checkboxes, manualCheck } = this.state

    const formProperty = `check${id}`
    if (
      this.props.change &&
      typeof this.props.change === 'function' &&
      this.props.form &&
      this.props.form.AuditAdminModal &&
      this.props.form.AuditAdminModal.values &&
      this.props.form.AuditAdminModal.values.hasOwnProperty(formProperty)
    ) {
      this.props.change(formProperty, e.target.checked)
    }
    manualCheck[id] = e

    let trueValuesCount = 0

    for (const key in this.state.manualCheck) {
      if (this.state.manualCheck[key] === true) {
        trueValuesCount++
      }
    }

    this.setState({
      checkboxes: { ...checkboxes, [id]: !checkboxes[id] },
      manualCheck: manualCheck,
      selectAll: (this.props.documents.length === trueValuesCount)
    })
  }

  initializeCheckboxes () {
    const checkboxes = this.props?.documents?.reduce(
      (checkboxes, document) => ({
        ...checkboxes,
        [document.id]: this.state.selectAll
      }),
      {}
    )
    this.setState({ checkboxes })
  }

  componentDidMount () {
    this.loadCombos()
    this.initializeCheckboxes()
    this.setState({ formData: this.props.formData })
    this.inicialize()
  }

  loadCombos () {
    this.props.actions.fetchAuditLoadData()
    this.props.actions.fetchDossierSubTypes(null, dossierTypes.sales)
    this.props.actions.fetchDossierTypes()
    this.props.actions.fetchAuditConfigurationType()
    this.props.actions.fetchDocumentTypeUseCombo()
  }

  componentWillUnmount () {
    this.setState({
      openAuditPanel: true,
      openCharacteristicsPanel: true,
      openDocumentsPanel: false,
      showModal2: false,
      selectAll: false,
      checkboxes: {},
      form: {},
      itemsPerPage: 10,
      currentPage: 1,
      mount: true,
      documents: []
    }, () => {
      this.props.initialize({})
      this.props.reset()
    })
  }

  onDossierTypeChange (dossierTypesId) {
    this.props.actions.fetchDossierSubTypes(null, dossierTypesId)
  }

  componentDidUpdate (prevProps) {
    if (this.props.formValues !== prevProps.formValues && !this.state.mount) {
      this.updateFormValues()
    }

    if (this.state.documents !== this.props.documents) {
      this.setState({ documents: this.props.documents, currentPage: 1, manualCheck: {}, checkboxes: {}, selectAll: false })
    }

    this.setState({ mount: false })
  }

  updateFormValues () {
    if (!this.props.showDocuments) {
      this.props.handleFormValuesChange(this.props.formValues, null)

      const documentsTypeUses = this.props.documents
      if (documentsTypeUses && documentsTypeUses.length > 0) {
        const formChecks = []

        if (this.props.formValues && this.props.formValues.selectedCheck_massive) {
          documentsTypeUses.forEach(doc => {
            if (this.state.manualCheck[doc.id] !== false) {
              formChecks.push({
                id: doc.id,
                value: doc.value,
                documentTypeEntity: doc.documentTypeEntity
              })
            }
          })
        } else {
          if (this.props.formValues && typeof this.props.formValues === 'object') {
            Object.keys(this.props.formValues).forEach((key) => {
              if (key.startsWith('selectedCheck_')) {
                const id = key.split('_')[1]
                const document = documentsTypeUses.find(doc => doc.id.toString() === id)
                if (document && this.props.formValues[key] && this.state.manualCheck[document.id] !== false) {
                  formChecks.push({
                    id: document.id,
                    value: document.value,
                    documentTypeEntity: document.documentTypeEntity
                  })
                }
              }
            })
          }
        }
        this.props.handleFormValuesChange(this.props.formValues, formChecks)
      }
    }
  }

  inicialize () {
    this.props.initialize({
      AuditCode: this.props.formData?.AuditCode,
      AuditDescription: this.props.formData?.AuditDescription,
      AuditConfigurationTypeId: this.props.formData?.AuditConfigurationTypeId,
      AuditExpirationDate: this.props.formData?.AuditExpirationDate,
      AuditLoadDataIds: this.props.formData?.AuditLoadDataIds,
      AuditUserObservations: this.props.formData?.AuditUserObservations,
      AuditAdminObservations: this.props.formData?.AuditAdminObservations,
      dossierTypeId: this.props.formData?.dossierTypeId,
      dossierSubtypeId: this.props.formData?.dossierSubtypeId,
      audit: this.props.formData?.audit
    })
  }

  fieldFill (val) {
    const format = 'DD/MM/YYYY'
    const fromDate = moment(val, format)
    const today = moment().startOf('day')
    if (fromDate.isBefore(today)) {
      this.props.change('AuditExpirationDate', null)
    }
  }

  dateValidation (value, allValues, props) {
    const result = validateDate(value, props)
    return result === Object(result) ? undefined : result
  }

  onSubmit (formValues) {
    this.props.onFormSubmit(formValues)
  }

  render () {
    const {
      t, combos: {
        dossierSubTypesCombo,
        auditConfigurationType,
        dossierTypesCombo,
        auditLoadData
      }, documents, readOnly, isAdmin
    } = this.props
    const tKey = 'MANAGEMENT.ADMIN_AUDIT.MODAL.'
    const { itemsPerPage, currentPage } = this.state
    const startIndex = (currentPage - 1) * itemsPerPage
    const endIndex = currentPage * itemsPerPage
    let documentTypeUses = documents
    const totalRows = documentTypeUses.length
    documentTypeUses = documentTypeUses?.slice(startIndex, endIndex)
    const pagesCount = Math.ceil(totalRows / itemsPerPage)
    const page = (currentPage > pagesCount) ? 1 : currentPage
    return (
      <>
        <Modal.Body>
          <div className="fleet-panel dossier-panel" >
            <div className="header-panel fleet-panel-box" onClick={() => this.setState({ openAuditPanel: !this.state.openAuditPanel })}>

              <div className="name-wrapper fleet-panel-box-title">
                <h4>{t(`${tKey}AUDIT`)}  <span className='fleet-panel-subTitle'></span></h4>
              </div>

              <i className={this.state.openAuditPanel ? 'ico-arrow-black-down fleet-panel-box-caret' : 'ico-arrow-black-up fleet-panel-box-caret'} />

            </div>
            <Panel expanded={this.state.openAuditPanel} onToggle={() => {}}>
              <Panel.Collapse>
                <Col sm={12}>
                  <Field
                    id="AuditCode"
                    name="AuditCode"
                    placeholder={t(`${tKey}CODE`)}
                    controlLabel={t(`${tKey}CODE`)}
                    component={InputText}
                    type="text"
                    maxLength={20}
                    disabled={readOnly || !isAdmin || this.props?.formData?.AuditConfigurationId}
                    colSm={3}
                  />

                  <Field
                    id="AuditDescription"
                    name='AuditDescription'
                    placeholder={t(`${tKey}DESCRIPTION`)}
                    controlLabel={t(`${tKey}DESCRIPTION`)}
                    component={InputText}
                    type="text"
                    maxLength={60}
                    colSm={6}
                    disabled={readOnly || !isAdmin}
                  />
                  <Field
                    id="AuditExpirationDate"
                    name='AuditExpirationDate'
                    placeholder={t(`${tKey}VALIDATION_DATE`)}
                    controlLabel={t(`${tKey}VALIDATION_DATE`)}
                    component={InputDatePicker}
                    minDate={getDateNowMinDatePicker()}
                    validate={[this.dateValidation]}
                    onInputChange={(val) => this.fieldFill(val)}
                    disabled={readOnly || !isAdmin}
                  />
                </Col>
                <Col sm={12}>
                  <Field
                    id="AuditUserObservations"
                    name="AuditUserObservations"
                    colSm={12}
                    componentClass="textarea"
                    controlLabel={t(`${tKey}OBSERVATIONS`)}
                    placeholder={t(`${tKey}OBSERVATIONS`)}
                    component={InputText}
                    maxLength={250}
                    disabled={readOnly || this.props?.formData?.audit || !isAdmin}
                  />
                </Col>
                {this.props?.formData?.AuditConfigurationId &&
                <Col sm={12}>
                  <Field
                    id="AuditAdminObservations"
                    name="AuditAdminObservations"
                    colSm={12}
                    componentClass="textarea"
                    controlLabel={t(`${tKey}OBSERVATIONS_AUDIT`)}
                    placeholder={t(`${tKey}OBSERVATIONS_AUDIT`)}
                    component={InputText}
                    disabled={!this.props?.formData?.audit || !isAdmin}
                    maxLength={512}
                  />
                </Col>
                }
              </Panel.Collapse>
            </Panel>
          </div>
          <div className="fleet-panel dossier-panel" >
            <div className="header-panel fleet-panel-box" onClick={() => this.setState({ openCharacteristicsPanel: !this.state.openCharacteristicsPanel })}>

              <div className="name-wrapper fleet-panel-box-title">
                <h4>{t(`${tKey}CHARACTERISTICS`)}  <span className='fleet-panel-subTitle'></span></h4>
              </div>

              <i className={this.state.openCharacteristicsPanel ? 'ico-arrow-black-down fleet-panel-box-caret' : 'ico-arrow-black-up fleet-panel-box-caret'} />

            </div>
            <Panel expanded={this.state.openCharacteristicsPanel} onToggle={() => {}}>
              <Panel.Collapse>
                <Col sm={12}>
                  <Field
                    id="AuditConfigurationTypeId"
                    name='AuditConfigurationTypeId'
                    placeholder={t(`${tKey}AUDIT_TYPE`)}
                    controlLabel={t(`${tKey}AUDIT_TYPE`)}
                    component={InputSelect}
                    multi={false}
                    valueKey="id"
                    labelKey="value"
                    childrenKey="children"
                    isClearable={false}
                    options={auditConfigurationType}
                    colSm={6}
                    disabled={readOnly || !isAdmin || this.props?.formData?.AuditConfigurationId}
                  />
                  <Field
                    id="AuditLoadDataIds"
                    name='AuditLoadDataIds'
                    placeholder={t(`${tKey}LOAD_CHARGE`)}
                    controlLabel={t(`${tKey}LOAD_CHARGE`)}
                    component={InputSelect}
                    multi={true}
                    valueKey="id"
                    labelKey="value"
                    childrenKey="children"
                    isClearable={false}
                    options={auditLoadData}
                    colSm={6}
                    disabled={readOnly || !isAdmin || this.props?.formData?.AuditConfigurationId}
                  />
                </Col>
                <Col sm={12}>
                  <Field
                    id="dossierTypeId"
                    name='dossierTypeId'
                    placeholder={t(`${tKey}DOSSIER_TYPE`)}
                    controlLabel={t(`${tKey}DOSSIER_TYPE`)}
                    component={InputSelect}
                    multi={false}
                    valueKey="id"
                    labelKey="value"
                    childrenKey="children"
                    isClearable={false}
                    options={dossierTypesCombo.filter(x => x.id === dossierTypes.sales)}
                    onInputChange={(val) => { this.onDossierTypeChange(val) }}
                    colSm={6}
                    disabled={readOnly || !isAdmin || this.props?.formData?.AuditConfigurationId}
                  />
                  <Field
                    id="dossierSubtypeId"
                    name='dossierSubtypeId'
                    placeholder={t(`${tKey}DOSSIER_SUBTYPE`)}
                    controlLabel={t(`${tKey}DOSSIER_SUBTYPE`)}
                    component={InputSelect}
                    multi={false}
                    valueKey="id"
                    labelKey="value"
                    childrenKey="children"
                    isClearable={false}
                    options={dossierSubTypesCombo}
                    colSm={6}
                    disabled={readOnly || !isAdmin || this.props?.formData?.AuditConfigurationId}
                  />
                </Col>
              </Panel.Collapse>
            </Panel>
          </div>
          <div className="fleet-panel dossier-panel" >
            <div className="header-panel fleet-panel-box" onClick={() => this.setState({ openDocumentsPanel: !this.state.openDocumentsPanel })}>

              <div className="name-wrapper fleet-panel-box-title">
                <h4>{t(`${tKey}DOCUMENTS`)}  <span className='fleet-panel-subTitle'></span></h4>
              </div>

              <i className={this.state.openDocumentsPanel ? 'ico-arrow-black-down fleet-panel-box-caret' : 'ico-arrow-black-up fleet-panel-box-caret'} />

            </div>
            <Panel expanded={this.state.openDocumentsPanel} onToggle={() => {}}>
              <Panel.Collapse>
                <div className='col-sm-12'>
                  <Table className="editable-table auditDocsTable" hover responsive>
                    <thead>
                      <tr>
                        <th>
                          {t(`${tKey}DOCUMENT`)}&nbsp;
                        </th>
                        <th>
                          {t(`${tKey}CATEGORY`)}&nbsp;
                        </th>
                        <th className='action'>
                          <Field
                            component={InputCheckBox}
                            name={'selectedCheck_massive'}
                            onChange={(e) => this.onCheckAll(e)}
                            checked={this.state.selectAll}
                            disabled={!documentTypeUses || documentTypeUses?.length === 0 || !isAdmin}
                          />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {documentTypeUses ? (
                        documentTypeUses.map(document => (
                          <tr key={document.id}>
                            <td>{document.value}</td>
                            <td>{document.documentTypeEntity}</td>
                            <td className='action'>
                              <Field
                                component={InputCheckBox}
                                name={'selectedCheck_' + document.id}
                                id={'selectedCheck_' + document.id}
                                checked={
                                  this.state.manualCheck.hasOwnProperty(document.id)
                                    ? this.state.manualCheck[document.id]
                                    : this.state.checkboxes[document.id] || false
                                }
                                onChange={(event) => this.onCheckOne(event, document.id)}
                                disabled={!isAdmin}/>

                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="3">No hay documentos disponibles</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
                <div className="section-users-footer">
                  <nav key={1} className="text-center">
                    <IDocCarPagination
                      id="btn_pag_sales"
                      page={page}
                      pagesCount={pagesCount}
                      totalRows= {totalRows}
                      onSelectAction={(page) => this.setState({ currentPage: page })}
                    />
                  </nav>
                </div>
              </Panel.Collapse>
            </Panel>
          </div>
          <div className="fleet-panel dossier-panel" style={this.props.formData?.audit ? { } : { display: 'none' } }>
            <div className="header-panel fleet-panel-box" onClick={() => this.setState({ openFilePanel: !this.state.openFilePanel })}>

              <div className="name-wrapper fleet-panel-box-title">
                <h4>{t(`${tKey}OPERATIONS_AUDIT`)}  <span className='fleet-panel-subTitle'></span></h4>
              </div>

              <i className={this.state.openFilePanel ? 'ico-arrow-black-down fleet-panel-box-caret' : 'ico-arrow-black-up fleet-panel-box-caret'} />

            </div>
            <Panel expanded={this.state.openFilePanel} onToggle={() => {}}>
              <Panel.Collapse>
                <AuditFileModalPage/>
              </Panel.Collapse>
            </Panel>
          </div>
        </Modal.Body>
      </>
    )
  }
}

const validate = (values, { t }) => {
  const tKey = 'DOSSIER_COMPONENTS.VALIDATIONS.'
  const errors = {}

  const fieldsToValidate = ['AuditCode', 'AuditDescription', 'AuditConfigurationTypeId', 'AuditLoadDataIds', 'dossierTypeId', 'dossierSubtypeId']

  fieldsToValidate.forEach(field => {
    if (!values[field]) {
      errors[field] = t(`${tKey}MANDATORY_FIELD`)
    }
  })

  if (values.AuditExpirationDate) {
    const format = 'DD/MM/YYYY'
    const fromDate = moment(values.AuditExpirationDate, format)
    const toDate = moment().startOf('day')
    if (fromDate.isBefore(toDate)) {
      errors.AuditExpirationDate = t(`${tKey}MANDATORY_FIELD`)
    }
  }

  return errors
}

const mapStateToProps = state => {
  return {
    formValues: state.form && state.form.AuditAdminModal ? state.form.AuditAdminModal.values : null
  }
}

export default connect(mapStateToProps)(
  reduxForm({
    form: 'AuditAdminModal',
    validate,
    shouldValidate: () => true
  })(AuditAdminModal)
)
