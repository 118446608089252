import React, { PureComponent } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { urgencyTypesId, brands, permissions, dashboardUploadExcel } from '../../constants/backendIds'
import StagePie from './charts/StagePie'
import StagesBarChart from './charts/StagesBarChart'
import UploadModalPage from '../../containers/dashboard/UploadModalPage'
import { reduxForm, Field } from 'redux-form'
import settings from '../../setting'
import InputSelect from '../commons/form/InputSelect'
import { getSectionPermissions } from '../../util/permisionFunctions'
import { sectionPermissions } from '../../constants/dossier/common/buttonsHeader'

import Card from '../dashboard/Cards/Card'
import ExtraBoxPage from '../../containers/dashboard/ExtraBoxPage'
import ExtraBoxTasksPage from '../../containers/dashboard/ExtraBoxTasksPage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAlignRight, faTh } from '@fortawesome/free-solid-svg-icons'
import Cookies from 'js-cookie'

class Dashboard extends PureComponent {
  constructor () {
    super()

    let versionV1 = settings.dashboardVersion === 1
    let cookieCardsSelected = Cookies.get('cardsSelected') === 'true'
    this.state = {
      salesmanId: null,
      loadDashboard: settings.autoLoadDashboard,
      firstLoad: true,
      cardsActive: !versionV1 && cookieCardsSelected,
      rowsActive: versionV1 || !cookieCardsSelected
    }

    Cookies.set('cardsSelected', this.state.cardsActive, { expires: 400 })

    this.handleNav = this.handleNav.bind(this)
  }

  componentDidMount () {
    if (this.state.firstLoad) {
      if (this.props.salesmanId) this.props.change('salesmanId', this.props.salesmanId)
    } else {
      if (this.state.salesmanId) this.props.change('salesmanId', this.state.salesmanId)
    }

    var organizedUnit = this.props.organizedUnit !== '0' ? this.props.organizedUnit : null
    if (settings.autoLoadDashboard) {
      this.props.actions.fetchDashboard(organizedUnit, this.props.salesmanId)
    } else {
      this.props.actions.fetchCreateDossierButton(organizedUnit)
      this.props.actions.fetchDashboardButtons()
    }

    this.initializePermisions(this.props)
  }

  componentDidUpdate () {
    // getSnapshotBeforeUpdate() should be used with componentDidUpdate()
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (this.props.salesmanId && JSON.stringify(this.props.salesmanId) !== JSON.stringify(prevProps.salesmanId)) {
      if (this.state.salesmanId) {
        this.props.change('salesmanId', this.state.salesmanId)
      } else if (!this.state.firstLoad) {
        this.props.change('salesmanId', this.props.salesmanId)
      }
    }
    if (this.props.organizedUnit && this.props.organizedUnit !== prevProps.organizedUnit) {
      this.props.actions.fetchDashboard(this.props.organizedUnit, this.props.salesmanId)
    }
    this.setState({ firstLoad: false })
    return this.props
  }

  initializePermisions (props) {
    const section = props.sections.filter((tabConf) => tabConf.code === sectionPermissions.dashboard)
    if (section.length > 0 && section[0] != null) {
      const newState = getSectionPermissions(section[0])
      this.setState({ sectionTabsConfiguration: newState })
    }
  }

  handleNav (val) {
    let urgencyTypeId
    let tKey = 'DASHBOARD.'
    switch (val) {
      case this.props.t(`${tKey}URGENCY_TYPES.HIGH`):
        urgencyTypeId = urgencyTypesId.high
        break
      case this.props.t(`${tKey}URGENCY_TYPES.MEDIUM`):
        urgencyTypeId = urgencyTypesId.medium
        break
      case this.props.t(`${tKey}URGENCY_TYPES.LOW`):
        urgencyTypeId = urgencyTypesId.low
        break
    }
    this.props.history.push(`/dashboard/${this.props.salesmanId || 0}/${urgencyTypeId}/0/0`)
  }

  selectSalesman (organizedUnit, salesmanIdValue) {
    organizedUnit = this.props.organizedUnit !== '0' ? this.props.organizedUnit : null
    salesmanIdValue.length > 0 ? this.setState({ salesmanId: salesmanIdValue }) : this.setState({ salesmanId: null })
    salesmanIdValue.length > 0 ? this.props.actions.fetchFilteredDashboard(organizedUnit, salesmanIdValue) : this.props.actions.fetchFilteredDashboard(organizedUnit, null)
  }

  loadDashboard () {
    this.setState({ loadDashboard: true })
    this.props.actions.fetchDashboard(this.props.organizedUnit, this.props.salesmanId)
  }

  showCards () {
    this.setState({ rowsActive: false, cardsActive: true })
    Cookies.set('cardsSelected', 'true', { expires: 400 })
  }

  showRows () {
    this.setState({ rowsActive: true, cardsActive: false })
    Cookies.set('cardsSelected', 'false', { expires: 400 })
  }

  render () {
    const {
      t, organizedUnit, salesmanId,
      dashboard: {
        total = 0, totalHigh = 0, totalMedium = 0, totalLow = 0, subTypes = [], canCreateDossier, canCreateDossierAudi, canCreateDossierSeat,
        canCreateDossierVW, canCreateDossierBuyAndSellVW, canCreateDossierBuyAndSellAudi, canCreateDossierExcelFleetQuadis
      },
      actions: { openUploadDocumentFleetModal }, combos: { completeSalesmanCombo }, defaultSalesmen
    } = this.props
    const tKey = 'DASHBOARD.'

    return (
      <div>
        <Col sm={12} className="dashboard-wrapper">
          <div className={'row dashboard-header' + (canCreateDossier ? ' has-button' : '')}>
            <Col className="dashboard-header-col-title">
              <h2><i className="ico-home"></i> {t(`${tKey}NAME`)}</h2>
            </Col>
            <Col className="dashboard-header-col-rows">
              <Row className="dashboard-header-row">
                { settings.dashboardVersion === 1 && !this.state.loadDashboard && (
                  <div className="btn-wrapper">
                    <Button id="stage-load-dashboard" onClick={() => this.loadDashboard()} className="btn-standard">
                      {/* <i className="ico-plus" /> */}
                      {t(`${tKey}LOAD_DASHBOARD`)}
                    </Button>
                  </div>
                )}
                {this.state.sectionTabsConfiguration && this.state.sectionTabsConfiguration.newDossier && this.state.sectionTabsConfiguration.newDossier.permission > permissions.hidden &&
                canCreateDossier && (
                  <div className="btn-wrapper">
                    <Button id="stage-new-dossier" onClick={() => this.props.history.push('/new-dossier')} className="btn-standard newDossier">
                      <i className="ico-plus" />
                      {t(`${tKey}NEW_DOSSIER`)}
                    </Button>
                  </div>
                )}
                {canCreateDossierAudi && (
                  <div className="btn-wrapper">
                    <Button id="stage-new-dossier" onClick={() => openUploadDocumentFleetModal(organizedUnit, brands.AUDI, false)} className="btn-standard">
                      <i className="ico-plus" />
                      {t(`${tKey}BTN_AUDI_FLEET`)}
                    </Button>
                  </div>
                )}
                {canCreateDossierSeat && (
                  <div className="btn-wrapper">
                    <Button id="stage-new-dossier" onClick={() => openUploadDocumentFleetModal(organizedUnit, brands.SEAT, false)} className="btn-standard">
                      <i className="ico-plus" />
                      {t(`${tKey}BTN_SEAT_FLEET`)}
                    </Button>
                  </div>
                )}
                {canCreateDossierVW && (
                  <div className="btn-wrapper">
                    <Button id="stage-new-dossier" onClick={() => openUploadDocumentFleetModal(organizedUnit, brands.VW, false)} className="btn-standard">
                      <i className="ico-plus" />
                      {t(`${tKey}BTN_VW_FLEET`)}
                    </Button>
                  </div>
                )}
                {canCreateDossierExcelFleetQuadis && (
                  <div className="btn-wrapper">
                    <Button id="stage-new-dossier" onClick={() => openUploadDocumentFleetModal(organizedUnit, null, null, dashboardUploadExcel.FLEET_QUADIS)} className="btn-standard">
                      <i className="ico-plus" />
                      {t(`${tKey}BTN_QUADIS_FLEET`)}
                    </Button>
                  </div>
                )}
              </Row>
              <Row className="dashboard-header-row">
                {canCreateDossierBuyAndSellVW && (
                  <div className="btn-wrapper">
                    <Button id="stage-new-dossier" onClick={() => openUploadDocumentFleetModal(organizedUnit, brands.VW, true)} className="btn-standard">
                      <i className="ico-plus" />
                      {t(`${tKey}BTN_VW_BUY_AND_SELL`)}
                    </Button>
                  </div>
                )}
                {canCreateDossierBuyAndSellAudi && (
                  <div className="btn-wrapper">
                    <Button id="stage-new-dossier" onClick={() => openUploadDocumentFleetModal(organizedUnit, brands.AUDI, true)} className="btn-standard">
                      <i className="ico-plus" />
                      {t(`${tKey}BTN_AUDI_BUY_AND_SELL`)}
                    </Button>
                  </div>
                )}
              </Row>
            </Col>
          </div>
          <div className='extraFunctions customScrollbar'>
            { settings.enableFavoritesAndRecents &&
              <ExtraBoxPage/>
            }
            { settings.enableTaskFollowUp &&
              <ExtraBoxTasksPage
                organizedUnit= {organizedUnit}
              ></ExtraBoxTasksPage>
            }
          </div>
          {settings.dashboardVersion > 1 &&
          <Row className='statistics'>
            <a onClick={() => this.loadDashboard()} ><i className="iDocIcon-visibility-eye"></i> { this.state.loadDashboard ? t(`${tKey}RELOAD_STATISTICS`) : t(`${tKey}LOAD_STATISTICS`)}</a>
          </Row>}
          <div className='resume'>
            {this.state.loadDashboard &&
            <Col className="box sm-box">
              <div>
                {this.state.loadDashboard && settings.clientFieldValidation !== 'porsche' &&
                <Col sm={6}>
                  <Field
                    id="salesmanId"
                    name='salesmanId'
                    colSm={12}
                    controlLabel={t(`${tKey}SALESMAN_LABEL`)}
                    placeholder={t(`${tKey}SALESMAN_PLACEHOLDER`)}
                    options={completeSalesmanCombo}
                    component={InputSelect}
                    multi={true}
                    valueKey="id"
                    labelKey="value"
                    onInputChange={(salesmanIdValue) => this.selectSalesman(organizedUnit, salesmanIdValue)}
                    customClass='dashboard-select'
                  />
                </Col> }
                <Col sm={6}>
                  <StagePie
                    t={t}
                    data={{
                      total: total,
                      urgencyChartList: [
                        { total: totalHigh, urgencyTypeName: t(`${tKey}URGENCY_TYPES.HIGH`) },
                        { total: totalMedium, urgencyTypeName: t(`${tKey}URGENCY_TYPES.MEDIUM`) },
                        { total: totalLow, urgencyTypeName: t(`${tKey}URGENCY_TYPES.LOW`) }
                      ]
                    }}
                    showChart={this.state.loadDashboard}
                    navPieFunction={this.handleNav}
                    salesmanId={salesmanId}
                  />
                </Col>
              </div>
            </Col>}
          </div>
          {this.state.loadDashboard && (settings.dashboardVersion > 1) && <Row className='switchBtnContainer'>
            <a onClick={() => this.showCards()} className={'switch-cards ' + (this.state.cardsActive === true ? 'active' : '')}><FontAwesomeIcon icon={faTh} /></a>
            <a onClick={() => this.showRows()} className={'switch-columns ' + (this.state.rowsActive === true ? 'active' : '')}><FontAwesomeIcon icon={faAlignRight} /></a>

          </Row>}
          {this.state.loadDashboard && this.state.cardsActive && <Row>
            <div className='cardContainer'>
              {
                subTypes.map((subType, idx) => <Card
                  t={t}
                  urgencyLowLabel={t(`${tKey}URGENCY_TYPES.LOW`)}
                  urgencyMediumLabel={t(`${tKey}URGENCY_TYPES.MEDIUM`)}
                  urgencyHighLabel={t(`${tKey}URGENCY_TYPES.HIGH`)}
                  salesmanId={salesmanId}
                  key={idx}
                  subType={subType}
                  history={this.props.history}
                  defaultSalesmen={defaultSalesmen}
                />)
              }
            </div>
          </Row> }
          {this.state.loadDashboard && this.state.rowsActive && <Row>
            <Col sm={12}>
              {
                subTypes.map((subType, idx) => <StagesBarChart
                  t={t}
                  urgencyLowLabel={t(`${tKey}URGENCY_TYPES.LOW`)}
                  urgencyMediumLabel={t(`${tKey}URGENCY_TYPES.MEDIUM`)}
                  urgencyHighLabel={t(`${tKey}URGENCY_TYPES.HIGH`)}
                  salesmanId={salesmanId}
                  key={idx}
                  subType={subType}
                  history={this.props.history}
                  defaultSalesmen={defaultSalesmen}
                />)
              }
            </Col>
          </Row>}

        </Col>
        <UploadModalPage />
      </div>
    )
  }
}

export default reduxForm({
  form: 'DashboardForm'
})(Dashboard)
