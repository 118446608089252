import React, { PureComponent } from 'react'
import { Panel, Row } from 'react-bootstrap'
import InputSelect from '../../commons/form/InputSelect'
import InputText from '../../commons/form/InputText'
import InputDatePicker from '../../commons/form/InputDatePicker'
import { Field } from 'redux-form'
import { validateDate } from '../../../util/validationFunctions'
import { permissions } from '../../../constants/backendIds'
import { getFieldsPermisions } from '../../../util/permisionFunctions'
import { compareObjects } from '../../../util/utils'
import CustomFields from '../../commons/customFields/CustomFields'

class SalesComponent extends PureComponent {
  constructor () {
    super()
    this.state = {
      open: true,
      operationTypeIdDisabled: true,
      fieldsConfiguration: {
        salesmanId: null,
        salesmanIdManager: null,
        salesmanIdLock: null,
        saleTypeId: null,
        operationTypeIds: null,
        expectedDeliveryDate: null,
        orderDate: null,
        paymentMethodId: null,
        proposalPercDiscount: null,
        orderPercDiscount: null,
        signalPayNotRequired: null,
        additionalInfoIds: null,
        rentalOrderNumber: null,
        deliveryDestination: null,
        arriveVehicle: null,
        vehicleTypeId: null,
        LOPDNotRequired: null,
        homologationRequired: null,
        homologationClassificationId: null,
        homologationProducerId: null,
        despositDeliveryDate: null,
        despositEntityId: null,
        depositLicensePlate: null,
        depositKm: null,
        productionWeek: null,
        monthsGuaranteeExtension: null,
        notMultipleInvoices: null,
        serviceDestinationId: null,
        warrantyExtensionNotRequired: null,
        assignmentSheet: null,
        billingDate: null,
        billingNumber: null
      }
    }
    this.dateValidation = this.dateValidation.bind(this)
  }

  initializePermisions (props) {
    const fieldKeys = Object.keys(this.state.fieldsConfiguration)
    const newState = getFieldsPermisions(props.componentConfiguration.fieldsConfiguration, fieldKeys, props.componentConfiguration.permission)
    this.setState({ ...this.state, ...newState })
  }

  handleSaleTypeChange (saleTypeId) {
    this.props.actions.change('salesComponent.operationTypeIds', [])
    if (saleTypeId) {
      this.props.actions.fetchOperationType(saleTypeId, this.props.dossier.organizedUnitId)
      this.setState({ operationTypeIdDisabled: false })
    } else {
      this.setState({ operationTypeIdDisabled: true })
    }
  }

  componentDidMount () {
    this.initializePermisions(this.props)
  }

  dateValidation (value, allValues, props) {
    const result = validateDate(value, props)
    return result === Object(result) ? undefined : result
  }

  componentDidUpdate (prevProps) {
    if (prevProps.open !== this.props.open && this.props.open) {
      this.setState({ open: true })
    }

    if (this.props.dossier.salesComponent !== prevProps.dossier.salesComponent) {
      this.props.dossier.salesComponent.saleTypeId ? this.setState({ operationTypeIdDisabled: false }) : this.setState({ operationTypeIdDisabled: true })
    }

    if (!compareObjects(this.props.componentConfiguration || {}, prevProps.componentConfiguration || {}, true)) {
      this.initializePermisions(this.props)
    }
  }

  render () {
    // TODO paymentSignalStatus logic (callback for change status)
    const { t, combos, customFields } = this.props
    const tKey = 'DOSSIER_COMPONENTS.SALES.'
    return (
      <Row className="sales-panel dossier-panel">
        <div className="header-panel" onClick={() => this.setState({ open: !this.state.open })}>
          <div className="name-wrapper">
            <i className="ico-sales" />
            <h4>{t(`${tKey}REGISTRATION_DELIVERY`)}</h4>
          </div>
          <i className={this.state.open ? 'ico-arrow-black-up' : 'ico-arrow-black-down'} />
        </div>
        <Panel expanded={this.state.open} onToggle={() => {}}>
          <Panel.Collapse>
            <Row>
              {this.state.fieldsConfiguration.salesmanIdLock && this.state.fieldsConfiguration.salesmanIdLock.permission !== permissions.hidden && (<Field
                colSm={4}
                id="salesmanId"
                name="salesComponent.salesmanId"
                controlLabel={t(`${tKey}SALESMAN`)}
                placeholder={t(`${tKey}SALESMAN`)}
                valueKey="id"
                labelKey="value"
                component={InputSelect}
                options={combos.salesmanCombo}
                disabled={true}
              />)}
              {this.state.fieldsConfiguration.salesmanIdManager && this.state.fieldsConfiguration.salesmanIdManager.permission !== permissions.hidden && (<Field
                colSm={4}
                id="salesmanIdManager"
                name="salesComponent.salesmanIdManager"
                controlLabel={t(`${tKey}SALESMAN_MANAGER`)}
                placeholder={t(`${tKey}SALESMAN_MANAGER`)}
                valueKey="id"
                labelKey="value"
                component={InputSelect}
                options={combos.salesmanComboLogic}
                disabled={true}
              />)}
              {this.state.fieldsConfiguration.expectedDeliveryDate && this.state.fieldsConfiguration.expectedDeliveryDate.permission !== permissions.hidden && (<Field
                id="expectedDeliveryDate"
                name="salesComponent.expectedDeliveryDate"
                colSm={4}
                controlLabel={t(`${tKey}EXPECTED_DELIVERY_DATE`)}
                disabled
                placeholder={t(`${tKey}EXPECTED_DELIVERY_DATE`)}
                component={InputText}
              />)}
              {this.state.fieldsConfiguration.orderDate && this.state.fieldsConfiguration.orderDate.permission !== permissions.hidden && (<Field
                id="orderDate"
                name="salesComponent.orderDate"
                colSm={4}
                controlLabel={t(`${tKey}ORDER_DATE`)}
                component={InputDatePicker}
                validate={[this.dateValidation]}
                disabled={true}
              />)}
            </Row>
            <CustomFields customFields={customFields} readOnlyFields={true} />
          </Panel.Collapse>
        </Panel>
      </Row>

    )
  }
}

export default SalesComponent
