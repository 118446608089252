
import { getAuth } from '../../../selectors/access/auth'
import { handleError } from '../../errors/errorManager'
import { put, call, select, takeEvery } from 'redux-saga/effects'
import { showLoader, hideLoader } from '../../../actions/common.js'
import mastersActionTypes from '../../../constants/actions/masters/masters'
import { closeLegalCompanyModal, fetchLegalCompanyListSuccess, openLegalCompanyModalSuccess } from '../../../actions/masters/masters'
import { yesNoModal } from '../../modalsListeners/yesNoModal'
import deleteLegalCompany from '../../../services/legalCompany/deleteLegalCompany'
import getAllLegalCompany from '../../../services/legalCompany/getAllLegalCompany'
import getLegalCompanyById from '../../../services/legalCompany/getLegalCompanyById'
import postLegalCompany from '../../../services/legalCompany/postLegalCompany'
import putLegalCompany from '../../../services/legalCompany/putLegalCompany'
import { getProcessingLegalCompanyFilter } from '../../../selectors/masters/legalCompany'
import { initialize } from 'redux-form'

export function * fetchLegalCompanyList ({ filter }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield filter = yield filter || select(getProcessingLegalCompanyFilter)
    const legalCompanyList = yield call(getAllLegalCompany, auth.token, filter)
    yield put(fetchLegalCompanyListSuccess(legalCompanyList, filter))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}
export function * watchFetchLegalCompanyList () {
  yield takeEvery(mastersActionTypes.FETCH_LEGAL_COMPANY_LIST, fetchLegalCompanyList)
}

export function * openLegalCompanyModal ({ legalCompanyId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    let legalCompanyFields = []
    if (legalCompanyId) {
      legalCompanyFields = yield call(getLegalCompanyById, auth.token, legalCompanyId)
    }
    yield put(openLegalCompanyModalSuccess(legalCompanyFields, legalCompanyId))
    if (legalCompanyId) {
      yield put(initialize('editLegalCompany', legalCompanyFields))
    } else {
      yield put(initialize('editLegalCompany'))
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchOpenLegalCompanyModal () {
  yield takeEvery(mastersActionTypes.OPEN_LEGAL_COMPANY_MODAL, openLegalCompanyModal)
}

export function * saveLegalCompanyModal ({ legalCompanyValues, legalCompanyId }) {
  try {
    const isUpdate = (legalCompanyId != null)
    const auth = yield select(getAuth)
    let updateList = true
    if (isUpdate) {
      const confirmed = yield call(yesNoModal, 'legalCompanyAlert')
      if (confirmed) {
        yield put(showLoader())
        yield call(putLegalCompany, auth.token, legalCompanyValues)
      } else {
        updateList = false
      }
    } else {
      yield put(showLoader())
      yield call(postLegalCompany, auth.token, legalCompanyValues)
    }
    if (updateList) {
      const filter = yield filter || select(getProcessingLegalCompanyFilter)
      const legalCompanyList = yield call(getAllLegalCompany, auth.token, filter)
      yield put(fetchLegalCompanyListSuccess(legalCompanyList, filter))
    }
    yield put(closeLegalCompanyModal())
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSaveLegalCompanyModal () {
  yield takeEvery(mastersActionTypes.SAVE_LEGAL_COMPANY, saveLegalCompanyModal)
}

export function * deleteLegalCompanyModal ({ legalCompanyId }) {
  const toDelete = yield call(yesNoModal, 'legalCompanyDeleteAlert')
  if (toDelete) {
    try {
      yield put(showLoader())
      const auth = yield select(getAuth)
      yield call(deleteLegalCompany, auth.token, legalCompanyId)
      const filter = yield filter || select(getProcessingLegalCompanyFilter)
      const legalCompanyList = yield call(getAllLegalCompany, auth.token, filter)
      yield put(fetchLegalCompanyListSuccess(legalCompanyList, filter))
    } catch (error) {
      yield call(handleError, { error })
    } finally {
      yield put(hideLoader())
    }
  }
}

export function * watchDeleteLegalCompanyModal () {
  yield takeEvery(mastersActionTypes.DELETE_LEGAL_COMPANY, deleteLegalCompanyModal)
}
