import authActionTypes from '../constants/actions/auth'

export function checkTokenSuccess (userName, userSurnames, emailAddress) {
  return ({ type: authActionTypes.CHECK_TOKEN_SUCCESS, userName, userSurnames, emailAddress })
}

export function rememberPasswordSuccess () {
  return ({ type: authActionTypes.REMEMBER_PASSWORD_SUCCESS })
}

export function encryptQrContent () {
  return ({ type: authActionTypes.ENCRYPT_QR_CONTENT })
}

export function encryptQrContentSuccess (qrContentEncrypted) {
  return ({ type: authActionTypes.ENCRYPT_QR_CONTENT_SUCCESS, qrContentEncrypted })
}

export function setSalesmanSelectedSuccess (selectedSalesmanId) {
  return ({ type: authActionTypes.SET_SALESMAN_SELECTED_SUCCESS, selectedSalesmanId })
}

export function setNewVersionSuccess (newVersionNumber) {
  return ({ type: authActionTypes.SET_NEW_VERSION_SUCCESS, newVersionNumber })
}

export function fetchReleaseNotesFile (newVersionNumber, userLanguageId) {
  return ({ type: authActionTypes.FETCH_RELEASE_NOTES, newVersionNumber, userLanguageId })
}

export function fetchReleaseNotesFileSuccess (releaseNotes) {
  return ({ type: authActionTypes.FETCH_RELEASE_NOTES_SUCCESS, releaseNotes })
}

export default {
  setAuth: auth => ({ type: authActionTypes.SET_AUTH, auth }),
  fetchAuth: (values, resolve, reject, t) => ({ type: authActionTypes.FETCH_AUTH, values, resolve, reject, t }),
  fetchAuthError: error => ({ type: authActionTypes.FETCH_AUTH_ERROR, error }),
  logout: () => ({ type: authActionTypes.LOGOUT }),
  logoutSuccess: () => ({ type: authActionTypes.LOGOUT_SUCCESS }),
  resetPassword: (values, t, resolve, reject, tokenPassword) => ({ type: authActionTypes.RESET_PASSWORD, values, t, resolve, reject, tokenPassword }),
  resetPasswordError: (error) => ({ type: authActionTypes.RESET_PASSWORD_ERROR, error }),
  rememberPassword: (values, resolve, reject) => ({ type: authActionTypes.REMEMBER_PASSWORD, values, resolve, reject }),
  rememberPasswordError: () => ({ type: authActionTypes.REMEMBER_PASSWORD_ERROR }),
  fetchPasswordPolicy: () => ({ type: authActionTypes.FETCH_PASSWORD_POLICY }),
  fetchPasswordPolicySuccess: (data) => ({ type: authActionTypes.FETCH_PASSWORD_POLICY_SUCCESS, data }),
  setOrganizedUnit: organizedUnit => ({ type: authActionTypes.SET_ORGANIZED_UNIT, organizedUnit }),
  setOrganizedUnitSuccess: organizedUnit => ({ type: authActionTypes.SET_ORGANIZED_UNIT_SUCCESS, organizedUnit }),
  checkToken: (tokenPassword) => ({ type: authActionTypes.CHECK_TOKEN, tokenPassword }),
  setSalesmanSelectedSuccess,
  checkTokenSuccess,
  rememberPasswordSuccess,
  encryptQrContent,
  encryptQrContentSuccess,
  setNewVersionSuccess,
  fetchReleaseNotesFile,
  fetchReleaseNotesFileSuccess
}
