import { put, call, all, select, takeEvery } from 'redux-saga/effects'
import { change, reset, initialize } from 'redux-form'
// import { replace } from 'react-router-redux'
import { replace } from 'connected-react-router'
import { permissions, entityTypesId } from '../../../constants/backendIds'
import { buttonsHeader, buttonsHeaderPermisionsIdReverse } from '../../../constants/dossier/common/buttonsHeader'
import dossierActionsTypes from '../../../constants/actions/dossier/dossier'
import { showLoader, hideLoader } from '../../../actions/common'
import { fetchCombosSuccess } from '../../../actions/combos/combos'
import messageModalActions from '../../../actions/modals/messageModal'
import { refreshDocumentManagementModal } from '../../../actions/dossier/common/documentManagementModal'
import { setActiveTab } from '../../../actions/dossier/common/buttonsHeader'
import { fetchPaperworkDossierSuccess } from '../../../actions/dossier/paperwork'
import { getAuth } from '../../../selectors/access/auth'
import { getActiveTab } from '../../../selectors/dossier/common/buttonsHeader'
import { getDossierRelatedDossiersConf } from '../../../selectors/dossier/common/dossierRelatedDossiers'
import getPaperworkHistoricalDossier from '../../../services/dossierPaperwork/get/getPaperworkHistoricalDossier'
import getPaperworkDossierService from '../../../services/dossierPaperwork/get/getPaperworkDossier'
import getPaperworkDossierHeaderService from '../../../services/dossierPaperwork/get/getPaperworkDossierHeader'
import getEntitySubType from '../../../services/entitySubType/getEntitySubType'
import getEntityFilterSubType from '../../../services/entity/getEntityFilterSubType'
import getStageCheckListService from '../../../services/dossier/get/getStageCheckList'
import getPaperworkTypesService from '../../../services/paperworkType/getPaperworkTypes'
import getProcessingAgencyService from '../../../services/processingAgency/getProcessingAgency'
import getEntityTypes from '../../../services/entityType/getEntityTypes'
import getChannels from '../../../services/sourcechannel/getChanel'
import getSegmentations from '../../../services/segmentation/getSegmentation'
import getDossierEntityService from '../../../services/dossier/get/getDossierEntity'
import getDossierEntityProviderService from '../../../services/dossier/get/getDossierEntityProvider'
import getDossierEntityChildService from '../../../services/dossier/get/getDossierEntityChild'
import getDossierHistoricalEntity from '../../../services/dossier/get/getDossierHistoricalEntity'
import getDossierHistoricalEntityChild from '../../../services/dossier/get/getDossierHistoricalEntityChild'
import getPurchaseWithVehicleById from '../../../services/dossier/get/getPurchaseWithVehicleById'
import getPurchaseHistoricalWithVehicleById from '../../../services/dossier/get/getPurchaseHistoricalWithVehicleById'
import getVehicleByIdService from '../../../services/vehicle/getVehicleById'
import getPreviousUsage from '../../../services/previousUsage/getPreviousUsage'
import getBrands from '../../../services/brand/getBrands'
import getFuels from '../../../services/fuel/getFuels'
import getEntityById from '../../../services/entity/getEntityById'
import { fetchEntitySubTypeCombo } from '../../../sagas/combos/fetchEntitySubTypeCombo'
import { fetchEntityFilterSubTypeCombo } from '../../../sagas/combos/fetchEntityFilterSubTypeCombo'
import { switchFavoriteSuccess } from '../../../actions/dossier/common/statusHeader'
// Related component
import getDossierRelatedDossiers from '../../../services/dossier/get/getDossierRelatedDossiers'
import getDossierHistoricalRelatedDossiers from '../../../services/dossier/get/getDossierHistoricalRelatedDossiers'
import { handleError } from '../../errors/errorManager'
// Contact
import getDossierContact from '../../../services/dossier/get/getDossierContact'
import getDossierContactHistorical from '../../../services/dossier/get/getDossierContactHistorical'
import getOperationTypes from '../../../services/saleType/getOperationType'
import getServiceDestinationTypes from '../../../services/serviceDestination/getServiceDestinationTypes'
// Vehicle
import getModelCombo from '../../../services/model/getModelCombo'
import getCarlineCombo from '../../../services/carline/getCarlineCombo'
import getCatalogueCombo from '../../../services/catalogue/getCatalogueCombo'
import getColorCombo from '../../../services/color/getColorCombo'
import getTapestryCombo from '../../../services/tapestry/getTapestryCombo'
import getCatalogueModel from '../../../services/catalogueModel/getCatalogueModel'
import getMaritalStatus from '../../../services/maritalStatus/getMaritalStatus'
import getProcessingAgencyManagersCombo from '../../../services/processingAgency/getProcessingAgencyManagersCombo'

export function * fetchPaperworkDossier ({ dossierId, resolve, reject, historical = false }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const dossierRelatedDossiersConf = yield select(getDossierRelatedDossiersConf)
    const paperworkDossier = yield select(state => state.paperworkDossier)
    let dossier = {}
    let checkListHeader = []
    let entityComponent = {}
    let entityProviderComponent = {}
    let entityChildComponent = {}
    let entityComponentDossierSuccess = {}
    let entityChildComponentDossierSuccess = {}
    let entityProviderComponentDossierSuccess = {}
    let dossierRelatedDossiers = []
    const auditInfo = []
    if (historical) {
      dossier = yield call(getPaperworkHistoricalDossier, dossierId, auth.token)
      // entityComponent = yield call(getDossierHistoricalEntity, dossierId, auth.token)
      entityChildComponent = yield call(getDossierHistoricalEntityChild, dossierId, auth.token)
      dossierRelatedDossiers = yield call(getDossierHistoricalRelatedDossiers, dossierId, dossierRelatedDossiersConf.orderby, auth.token)
    } else {
      dossier = yield call(getPaperworkDossierService, dossierId, auth.token)
      checkListHeader = yield call(getStageCheckListService, dossierId, auth.token)
      // entityComponent = yield call(getDossierEntityService, dossierId, auth.token)
      entityComponent.previousEntity = { ...entityComponent }
      entityChildComponent = yield call(getDossierEntityChildService, dossierId, auth.token)
      entityChildComponent.previousEntity = { ...entityChildComponent }
      dossierRelatedDossiers = yield call(getDossierRelatedDossiers, dossierId, dossierRelatedDossiersConf.orderby, auth.token)
    }

    const operationTypeIdsSorted = dossier.dossierPaperworkComponent && dossier.dossierPaperworkComponent.operationTypeIds ? dossier.dossierPaperworkComponent.operationTypeIds.sort() : []
    const [
      paperworkTypes,
      entityTypeCombo,
      channelCombo,
      maritalStatusCombo,
      segmentationCombo,
      brandCombo,
      fuelCombo,
      operationTypeCombo,
      serviceDestinationCombo,
      modelCombo,
      processingAgencyManagersCombo
    ] = yield all([
      call(getPaperworkTypesService, auth.token, dossier.dossierId, historical),
      call(getEntityTypes, auth.token, dossier.dossierSubTypeId),
      call(getChannels, auth.token),
      call(getMaritalStatus, auth.token),
      call(getSegmentations, auth.token),
      call(getBrands, auth.token, dossier.dossierSubTypeId, dossier.organizedUnitId),
      call(getFuels, auth.token, historical),
      call(getOperationTypes, null, dossier.organizedUnitId, auth.token, false, operationTypeIdsSorted),
      call(getServiceDestinationTypes, false, dossier.dossierPaperworkComponent ? dossier.dossierPaperworkComponent.serviceDestinationId : null, auth.token),
      call(getModelCombo, auth.token),
      call(getProcessingAgencyManagersCombo, auth.token, dossier.dossierPaperworkComponent.processingAgencyId ?? 0)
    ])

    const [processingAgency, previousUsageCombo] =
      yield all([
        call(getProcessingAgencyService, dossier.organizedUnitId, dossier.dossierId, auth.token),
        call(getPreviousUsage, dossier.organizedUnitId, auth.token)
      ])

    entityComponent = paperworkDossier.entityComponent
    entityComponent = { ...entityComponent, ...dossier.dossierEntityComponent }
    if (paperworkDossier.entityComponent.updated) {
      entityComponent = yield call(getDossierEntityService, dossierId, auth.token)
      entityComponent.updated = true
      entityComponent.previousEntity = { ...entityComponent }
    }

    if (paperworkDossier.entityProviderComponent.updated) {
      entityProviderComponent = yield call(getDossierEntityProviderService, dossierId, auth.token)
      entityProviderComponent.updated = true
      entityProviderComponent.previousEntity = { ...entityProviderComponent }
    }
    const entitySubTypeCombo = entityComponent.entityTypeId
      ? yield call(getEntitySubType, auth.token, entityComponent.entityTypeId)
      : []
    const entityFilterSubTypeCombo = entityComponent.entitySubTypeId
      ? yield call(getEntityFilterSubType, auth.token, entityComponent.entityTypeId, entityComponent.entitySubTypeId, dossier.organizedUnitId)
      : []

    // entityChildComponent.previousEntity = {...entityChildComponent}
    let dossierContactComponent = paperworkDossier.dossierContactComponent
    if (dossierContactComponent.updated === true) {
      dossierContactComponent = yield call(getDossierContact, dossierId, auth.token)
      dossierContactComponent.updated = true
    }
    let vehicleComponent = paperworkDossier.vehicleComponent
    vehicleComponent = { ...entityComponent, ...dossier.dossierVehicleComponent }
    if (paperworkDossier.vehicleComponent.updated === true) {
      vehicleComponent = yield call(getPurchaseWithVehicleById, dossierId, auth.token)
      vehicleComponent.updated = true
    }

    if (!entityComponent.entityId) {
      yield put(change('paperwork_dossier', 'entityComponent', {}))
      entityComponentDossierSuccess = {}
    } else {
      entityComponentDossierSuccess = entityComponent
    }

    if (!entityChildComponent.entityId) {
      yield put(change('paperwork_dossier', 'entityChildComponent', {}))
      entityChildComponentDossierSuccess = {}
    } else {
      entityChildComponentDossierSuccess = entityChildComponent
    }

    if (!entityProviderComponent.entityId) {
      yield put(change('paperwork_dossier', 'entityProviderComponent', {}))
      entityProviderComponentDossierSuccess = {}
    } else {
      entityProviderComponentDossierSuccess = entityProviderComponent
    }

    if ((!entityComponent.entityId && !entityChildComponent.entityId) || (!entityComponent.entityId && entityChildComponent.entityId)) {
      yield put(change('paperwork_dossier', 'dossierContactComponent', dossierContactComponent))
    }

    yield put(fetchPaperworkDossierSuccess({ ...dossier, entityComponent: entityComponentDossierSuccess, entityChildComponent: entityChildComponentDossierSuccess, dossierContactComponent, vehicleComponent, dossierRelatedDossiers, auditInfo, entityProviderComponent: entityProviderComponentDossierSuccess }, checkListHeader))

    yield put(switchFavoriteSuccess(dossier.dossierHeaderComponent.favorite))

    yield put(fetchCombosSuccess({
      // Paperwork component
      paperworkTypes,
      processingAgency,
      // Client component
      entityTypeCombo,
      channelCombo,
      maritalStatusCombo,
      segmentationCombo,
      entitySubTypeCombo,
      entityFilterSubTypeCombo,
      // Vehicle component
      brandCombo,
      fuelCombo,
      previousUsageCombo,
      operationTypeCombo,
      serviceDestinationCombo,
      modelCombo,
      processingAgencyManagersCombo
    }))

    const currentActiveTab = yield select(getActiveTab)
    let nextActiveTab = dossier.viewConfiguration.tabsConfiguration.filter((tabConf) => tabConf.permission > permissions.hidden)
    nextActiveTab = nextActiveTab.length > 0 ? nextActiveTab[0] : currentActiveTab
    if (nextActiveTab !== currentActiveTab) yield put(setActiveTab(buttonsHeaderPermisionsIdReverse[nextActiveTab.code]))

    if (!historical && nextActiveTab === buttonsHeader.gestor_documental) {
      yield put(refreshDocumentManagementModal(dossierId))
    }

    if (resolve) {
      yield call(resolve, entityComponent)
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.status === 301) {
        // yield put(replace(`/dossier${customHandlerError.json}`))
        yield put(replace(`/dossier${customHandlerError.json}`))
      } else {
        yield put(messageModalActions.openErrorMessageModal('MESSAGES.ERROR_GET_PAPERWORK_DOSSIER', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
      if (reject) yield call(reject, customHandlerError)
    }
    const sessionHandler = function * () {
      yield put(reset('paperwork_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchPaperworkDossier () {
  yield takeEvery(dossierActionsTypes.FETCH_PAPERWORK_DOSSIER, fetchPaperworkDossier)
}

export function * refreshPaperworkDossierEntityFromDocumentModal (documentEditionalModalState) {
  try {
    yield put(showLoader())
    const paperworkDossier = yield select(state => state.paperworkDossier)
    const auth = yield select(getAuth)
    let entityComponent = {}
    if (documentEditionalModalState !== null && documentEditionalModalState !== undefined && documentEditionalModalState.entityId) {
      entityComponent = yield call(getEntityById, documentEditionalModalState.entityId, auth.token)
    } else {
      entityComponent = yield call(getDossierEntityService, paperworkDossier.dossierId, auth.token)
    }
    entityComponent.previousEntity = { ...paperworkDossier.entityComponent.previousEntity }
    let dirty = false
    if (documentEditionalModalState !== null && documentEditionalModalState !== undefined &&
      documentEditionalModalState.dirty !== null && documentEditionalModalState.dirty !== undefined) {
      dirty = documentEditionalModalState.dirty
    }
    yield put(fetchPaperworkDossierSuccess({ entityComponent }))
    const updatePaperworkDossier = yield select(state => state.paperworkDossier)
    if (dirty) {
      yield put(change('paperwork_dossier', 'entityComponent', entityComponent))
    } else {
      yield put(initialize('paperwork_dossier', { ...updatePaperworkDossier, entityComponent: entityComponent }))
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.SALES_DOSSIER.ERROR_GET_SALES_DOSSIER', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('paperwork_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * refreshPaperworkDossierVehicleFromDocumentModal (documentEditionalModalState) {
  try {
    yield put(showLoader())
    const paperworkDossier = yield select(state => state.paperworkDossier)
    const auth = yield select(getAuth)
    const vehicleComponent = yield call(getVehicleByIdService, auth.token, paperworkDossier.vehicleComponent.vehicleId)
    let dirty = false
    if (documentEditionalModalState !== null && documentEditionalModalState !== undefined &&
      documentEditionalModalState.dirty !== null && documentEditionalModalState.dirty !== undefined) {
      dirty = documentEditionalModalState.dirty
    }
    yield put(fetchPaperworkDossierSuccess({ vehicleComponent }))
    const updatedPaperworkDossier = yield select(state => state.paperworkDossier)
    if (dirty) {
      yield put(change('paperwork_dossier', 'vehicleComponent', updatedPaperworkDossier.vehicleComponent))
    } else {
      yield put(initialize('paperwork_dossier', { ...updatedPaperworkDossier, vehicleComponent: updatedPaperworkDossier.vehicleComponent }))
    }
    yield put(hideLoader())
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.PAPERWORK_DOSSIER.ERROR_GET_PAPERWORK_DOSSIER', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('paperwork_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * refreshPaperworkDossierFromDocumentModal (documentEditionalModalState) {
  try {
    yield put(showLoader())
    const paperworkDossier = yield select(state => state.paperworkDossier)
    const auth = yield select(getAuth)
    const dossierId = paperworkDossier.dossierId
    const dossier = yield call(getPaperworkDossierService, dossierId, auth.token)
    let dirty = false
    if (documentEditionalModalState !== null && documentEditionalModalState !== undefined &&
      documentEditionalModalState.dirty !== null && documentEditionalModalState.dirty !== undefined) {
      dirty = documentEditionalModalState.dirty
    }
    const components = yield call(fetchPaperworkComponentsUpdated, paperworkDossier, auth)
    yield put(fetchPaperworkDossierSuccess({ ...dossier, ...components }))
    const updatePaperworkDossier = yield select(state => state.paperworkDossier)
    if (dirty) {
      yield put(change('paperwork_dossier', 'dossierPaperworkComponent', dossier.dossierPaperworkComponent))
    } else {
      yield put(initialize('paperwork_dossier', { ...updatePaperworkDossier, dossierPaperworkComponent: dossier.dossierPaperworkComponent }))
    }
    yield put(hideLoader())
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.PAPERWORK_DOSSIER.ERROR_GET_PAPERWORK_DOSSIER', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('paperwork_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * refreshCommonPpkFromDocumental () {
  try {
    yield put(showLoader())
    const paperworkDossier = yield select(state => state.paperworkDossier)
    const auth = yield select(getAuth)
    const dossierId = paperworkDossier.dossierId
    const headerAndButtons = yield call(getPaperworkDossierHeaderService, dossierId, auth.token)
    const checkListHeader = yield call(getStageCheckListService, dossierId, auth.token)
    let paperwork
    paperwork = { ...headerAndButtons }
    paperwork = { ...paperworkDossier, ...paperwork }
    yield put(fetchPaperworkDossierSuccess(paperwork, checkListHeader))
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.PAPERWORK_DOSSIER.ERROR_GET_PAPERWORK_DOSSIER', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('paperwork_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * fetchPaperworkComponentsUpdated (paperworkDossier, auth) {
  const result = {}
  if (paperworkDossier.entityComponent && paperworkDossier.entityComponent.updated) {
    const entityComponent = yield call(getDossierEntityService, paperworkDossier.dossierId, auth.token)
    entityComponent.updated = true
    result.entityComponent = entityComponent
  }

  if (paperworkDossier.entityProviderComponent && paperworkDossier.entityProviderComponent.updated) {
    const entityProviderComponent = yield call(getDossierEntityProviderService, paperworkDossier.dossierId, auth.token)
    entityProviderComponent.updated = true
    result.entityProviderComponent = entityProviderComponent
  }

  if (paperworkDossier.vehicleComponent && paperworkDossier.vehicleComponent.updated) {
    const vehicleComponent = yield call(getPurchaseWithVehicleById, paperworkDossier.dossierId, auth.token)
    vehicleComponent.updated = true
    result.vehicleComponent = vehicleComponent
  }
  if (paperworkDossier.dossierContactComponent && paperworkDossier.dossierContactComponent.updated) {
    const dossierContactComponent = yield call(getDossierContact, paperworkDossier.dossierId, auth.token)
    dossierContactComponent.updated = true
    result.dossierContactComponent = dossierContactComponent
  }
  return result
}

export function * fetchPaperworkClient ({ dossierId, resolve, reject, pristine }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const paperworkDossier = yield select(state => state.paperworkDossier)
    const entityComponent = yield call(getDossierEntityService, dossierId, auth.token)
    const entityProviderComponent = yield call(getDossierEntityProviderService, dossierId, auth.token)
    const dossierContactComponent = yield call(getDossierContact, dossierId, auth.token)

    if (entityComponent && entityComponent.entitySubTypeId) {
      const entitySubTypeCombo = yield call(getEntitySubType, auth.token, entityComponent.entityTypeId)
      const entityFilterSubTypeCombo = yield call(getEntityFilterSubType, auth.token, entityComponent.entityTypeId, entityComponent.entitySubTypeId, paperworkDossier.organizedUnitId)
      yield put(fetchCombosSuccess({ entitySubTypeCombo, entityFilterSubTypeCombo }))
    }

    if (entityProviderComponent && entityProviderComponent.entitySubTypeId) {
      const entitySubTypeCombo = yield call(getEntitySubType, auth.token, entityProviderComponent.entityTypeId)
      const entityFilterSubTypeCombo = yield call(getEntityFilterSubType, auth.token, entityProviderComponent.entityTypeId, entityProviderComponent.entitySubTypeId, paperworkDossier.organizedUnitId)
      yield put(fetchCombosSuccess({ entitySubTypeCombo, entityFilterSubTypeCombo }))
    }

    entityProviderComponent.updated = true
    entityComponent.updated = true
    dossierContactComponent.updated = true
    yield put(change('paperwork_dossier', 'entityComponent', entityComponent.entityId ? entityComponent : {}))
    yield put(change('paperwork_dossier', 'entityProviderComponent', entityProviderComponent.entityId ? entityProviderComponent : {}))
    yield put(change('paperwork_dossier', 'dossierContactComponent', dossierContactComponent))
    yield put(fetchPaperworkDossierSuccess({ entityComponent, dossierContactComponent, entityProviderComponent }))
    if (pristine === true || pristine === undefined) {
      yield put(initialize('paperwork_dossier', { ...paperworkDossier, entityComponent: entityComponent, dossierContactComponent, entityProviderComponent: entityProviderComponent }))
    }
    if (resolve) yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.PAPERWORK_DOSSIER.ERROR_GET_PAPERWORK_DOSSIER', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('paperwork_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchPaperworkClient () {
  yield takeEvery(dossierActionsTypes.FETCH_PAPERWORK_DOSSIER_CLIENT, fetchPaperworkClient)
}

export function * fetchPaperworkVehicle ({ dossierId, resolve, reject, pristine }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const paperworkDossier = yield select(state => state.paperworkDossier)
    const vehicleComponent = yield call(getPurchaseWithVehicleById, dossierId, auth.token)
    vehicleComponent.updated = true
    yield put(change('paperwork_dossier', 'vehicleComponent', vehicleComponent.vehicleId ? vehicleComponent : {}))
    yield put(fetchPaperworkDossierSuccess({ vehicleComponent }))
    if (pristine === true || pristine === undefined) {
      yield put(initialize('paperwork_dossier', { ...paperworkDossier, vehicleComponent: vehicleComponent }))
    }
    if (resolve) yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.PAPERWORK_DOSSIER.ERROR_GET_PAPERWORK_DOSSIER', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('paperwork_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchPaperworkVehicle () {
  yield takeEvery(dossierActionsTypes.FETCH_PAPERWORK_DOSSIER_VEHICLE, fetchPaperworkVehicle)
}

export function * fetchPaperworkVehicleHistorical ({ dossierId, resolve, reject }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const vehicleComponent = yield call(getPurchaseHistoricalWithVehicleById, dossierId, auth.token)
    yield put(change('paperwork_dossier', 'vehicleComponent', vehicleComponent.vehicleId ? vehicleComponent : {}))
    yield put(fetchPaperworkDossierSuccess({ vehicleComponent }))

    let carlineCombo = []; let catalogueCombo = []; let colorCombo = []; let tapestryCombo = []

    if (vehicleComponent.brandId) {
      carlineCombo = yield call(getCarlineCombo, auth.token, vehicleComponent.brandId)
    }
    if (vehicleComponent.carlineId) {
      catalogueCombo = yield call(getCatalogueCombo, auth.token, vehicleComponent.carlineId)
    }
    if (vehicleComponent.modelId && vehicleComponent.catalogueId) {
      colorCombo = yield call(getColorCombo, auth.token, vehicleComponent.catalogueId, vehicleComponent.modelId)
      tapestryCombo = yield call(getTapestryCombo, auth.token, vehicleComponent.catalogueId, vehicleComponent.modelId)
      var catalogueModel = yield call(getCatalogueModel, auth.token, vehicleComponent.catalogueId, vehicleComponent.modelId)
      vehicleComponent.model = catalogueModel.description || ''
      vehicleComponent.catalogueModelId = catalogueModel.catalogueModelId || ''
    }

    yield put(fetchCombosSuccess({ carlineCombo, catalogueCombo, colorCombo, tapestryCombo }))
    if (resolve) yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.PAPERWORK_DOSSIER.ERROR_GET_PAPERWORK_DOSSIER', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('paperwork_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchPaperworkVehicleHistorical () {
  yield takeEvery(dossierActionsTypes.FETCH_PAPERWORK_DOSSIER_VEHICLE_HISTORICAL, fetchPaperworkVehicleHistorical)
}

export function * fetchPaperworkClientHistorical ({ dossierId, resolve, reject }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const entityComponent = yield call(getDossierHistoricalEntity, dossierId, auth.token)
    if (entityComponent.entityTypeId === entityTypesId.recurrentEntity) {
      const paperworkDossier = yield select(state => state.paperworkDossier)
      yield call(fetchEntitySubTypeCombo, { entityTypeId: entityComponent.entityTypeId })
      yield call(fetchEntityFilterSubTypeCombo, { entityTypeId: entityComponent.entityTypeId, entitySubTypeId: entityComponent.entitySubTypeId, organizedUnitId: paperworkDossier.organizedUnitId })
    }
    const dossierContactComponent = yield call(getDossierContactHistorical, dossierId, auth.token)
    yield put(change('paperwork_dossier', 'entityComponent', entityComponent.entityId ? entityComponent : {}))
    yield put(change('paperwork_dossier', 'dossierContactComponent', dossierContactComponent))
    yield put(fetchPaperworkDossierSuccess({ entityComponent, dossierContactComponent }))
    if (resolve) yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.PAPERWORK_DOSSIER.ERROR_GET_PAPERWORK_DOSSIER', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('paperwork_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchPaperworkClientHistorical () {
  yield takeEvery(dossierActionsTypes.FETCH_PAPERWORK_DOSSIER_CLIENT_HISTORICAL, fetchPaperworkClientHistorical)
}

export function * fetchAgencyProcessing ({ organizedUnitId, dossierId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)

    const processingAgency = yield call(getProcessingAgencyService, organizedUnitId, dossierId, auth.token)

    yield put(fetchCombosSuccess({ processingAgency }))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchfetchAgencyProcessing () {
  yield takeEvery(dossierActionsTypes.FETCH_PROCESSING_AGENCY_TYPE, fetchAgencyProcessing)
}
