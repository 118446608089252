import { put, call, select, takeEvery } from 'redux-saga/effects'
import { getAuth } from '../../../selectors/access/auth'
import { initialize, reset } from 'redux-form'
import { yesNoModal } from '../../modalsListeners/yesNoModal'
import dossierActionsTypes from '../../../constants/actions/dossier/dossier'
import { dossierTypes as dossierTypesConstant } from '../../../constants/dossier/common/dossierTypes'
import { entityRequiredFields } from '../../../constants/validatedFields/entity'
import { fetchPaperworkDossierSuccess } from '../../../actions/dossier/paperwork'
import getEntityFilterSubType from '../../../services/entity/getEntityFilterSubType'
import putPaperworkDossierService from '../../../services/dossierPaperwork/put/putPaperworkDossier'
import { showLoader, hideLoader } from '../../../actions/common'
import messageModalActions from '../../../actions/modals/messageModal'
import { fetchCombosSuccess } from '../../../actions/combos/combos'
import { handleError } from '../../errors/errorManager'
import { initializeDossier } from '../commonDossierSagas'

export function * savePaperworkDossier ({ paperworkDossier }) {
  try {
    const auth = yield select(getAuth)
    const paperworkDossierState = yield select(state => state.paperworkDossier)
    const myEntityRequiredFields = entityRequiredFields(paperworkDossier.entityComponent ? paperworkDossier.entityComponent.entityTypeId : null)
    let willBeAnyImportantFieldUpdated = false
    let confirmedSaveWarning = true

    for (const prop in myEntityRequiredFields) {
      if (paperworkDossierState.entityComponent.entityId && paperworkDossier.entityComponent[prop] !== paperworkDossierState.entityComponent[prop]) {
        willBeAnyImportantFieldUpdated = true
        break
      }
    }

    if (willBeAnyImportantFieldUpdated || (paperworkDossierState.entityComponent && paperworkDossierState.entityComponent.previousEntity && paperworkDossierState.entityComponent.previousEntity.entityId &&
        paperworkDossier.entityComponent && paperworkDossier.entityComponent.entityId && paperworkDossier.entityComponent.entityId !== paperworkDossierState.entityComponent.previousEntity.entityId)) {
      confirmedSaveWarning = yield call(yesNoModal, 'commonEntitySaveWarning')
    }

    if (paperworkDossier.dossierPaperworkComponent.amount !== paperworkDossierState.dossierPaperworkComponent.amount ||
      paperworkDossier.dossierPaperworkComponent.cost !== paperworkDossierState.dossierPaperworkComponent.cost ||
      paperworkDossier.dossierPaperworkComponent.economicTarget !== paperworkDossierState.dossierPaperworkComponent.economicTarget ||
      paperworkDossier.dossierPaperworkComponent.hasIem !== paperworkDossierState.dossierPaperworkComponent.hasIem ||
      paperworkDossier.dossierPaperworkComponent.hasSupInvoice !== paperworkDossierState.dossierPaperworkComponent.hasSupInvoice) {
      paperworkDossier.dossierPaperworkComponent.economicDataUpdated = true
    }
    if (confirmedSaveWarning) {
      yield put(showLoader())
      const subDossier = {
        dossierPaperworkComponent: paperworkDossier.dossierPaperworkComponent,
        dossierEntityComponent: JSON.stringify(paperworkDossier.entityComponent) === '{}' ? null : paperworkDossier.entityComponent,
        dossierEntityProviderComponent: JSON.stringify(paperworkDossier.entityProviderComponent) === '{}' ? null : paperworkDossier.entityProviderComponent,
        dossierEntityChildComponent: JSON.stringify(paperworkDossier.entityChildComponent) === '{}' ? null : paperworkDossier.entityChildComponent,
        dossierContactComponent: paperworkDossier.dossierContactComponent,
        dossierVehicleComponent: JSON.stringify(paperworkDossier.vehicleComponent) === '{}' ? null : paperworkDossier.vehicleComponent,
        dossierParentId: paperworkDossier.dossierParentId,
        parentIsHistorical: paperworkDossier.parentIsHistorical,
        customFields: paperworkDossier?.customFields ? Object.keys(paperworkDossier.customFields).map(x => { return { key: x, value: paperworkDossier.customFields[x] } }) : []
      }
      if (paperworkDossier?.customFields) {
        paperworkDossierState.customFields = {}
        Object.keys(paperworkDossier.customFields).map(x => {
          paperworkDossierState.customFields[x] = paperworkDossier.customFields[x]
        })
      }
      yield call(putPaperworkDossierService, paperworkDossier.dossierId, subDossier, auth.token, paperworkDossierState.lastAccessDate)
      yield put(messageModalActions.openSuccessMessageModal('MESSAGES.PUT_DOSSIER_SUCCESS'))
      yield call(initializeDossier, dossierTypesConstant.paperwork, paperworkDossier)
    } else {
      if (paperworkDossierState.entityComponent.previousEntity.entitySubTypeId) {
        const entityFilterSubTypeCombo = yield call(getEntityFilterSubType, auth.token, paperworkDossierState.entityComponent.previousEntity.entityTypeId, paperworkDossierState.entityComponent.previousEntity.entitySubTypeId, paperworkDossierState.organizedUnitId)
        yield put(fetchCombosSuccess({ entityFilterSubTypeCombo }))
      }
      if (paperworkDossier?.customFields) {
        paperworkDossierState.customFields = {}
        Object.keys(paperworkDossier.customFields).map(x => {
          paperworkDossierState.customFields[x] = paperworkDossier.customFields[x]
        })
      }
      yield put(fetchPaperworkDossierSuccess({ entityComponent: { ...paperworkDossierState.entityComponent.previousEntity, previousEntity: paperworkDossierState.entityComponent.previousEntity } }))
      yield put(initialize('paperwork_dossier', { ...paperworkDossier, entityComponent: paperworkDossierState.entityComponent.previousEntity, customFields: paperworkDossierState.customFields }))
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          yield call(initializeDossier, dossierTypesConstant.paperwork, paperworkDossier)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('paperwork_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSavePaperworkDossier () {
  yield takeEvery(dossierActionsTypes.SAVE_PAPERWORK_DOSSIER, savePaperworkDossier)
}
