import mastersActionTypes from '../../../constants/actions/masters/masters'

function initialState () {
  return {
    documentVisualizations: [],
    dossierSubtypesCombo: [],
    documentTypeUseCombo: [],
    documentVisualizationsCount: 0,
    pagesCount: 0,
    filter: {
      page: 1,
      orderBy: '',
      pageSize: 10
    },
    documentVisualizationModal: {
      showModal: false,
      documentVisualizationId: null
    }
  }
}

function fetchDocumentVisualizationsSuccess (state, { documentVisualizations, filter }) {
  return {
    ...state,
    documentVisualizations: documentVisualizations.documentVisualizations,
    documentVisualizationsCount: documentVisualizations.documentVisualizationsCount,
    pagesCount: documentVisualizations.pagesCount,
    filter: filter
  }
}

function openDocumentVisualizationModalSuccess (state, { documentVisualization }) {
  const initial = initialState()
  return {
    ...state,
    documentVisualizationModal: {
      ...initial.documentVisualizationModal,
      showModal: true,
      documentVisualization: documentVisualization
    }
  }
}

function closeDocumentVisualizationModalSuccess (state) {
  return {
    ...state,
    documentVisualizationModal: {
      ...initialState().documentVisualizationModal
    }
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case mastersActionTypes.FETCH_DOCUMENT_VISUALIZATIONS_SUCCESS:
      return fetchDocumentVisualizationsSuccess(state, action)
    case mastersActionTypes.OPEN_DOCUMENT_VISUALIZATION_MODAL_SUCCESS:
      return openDocumentVisualizationModalSuccess(state, action)
    case mastersActionTypes.CLOSE_DOCUMENT_VISUALIZATION_MODAL:
      return closeDocumentVisualizationModalSuccess(state, action)
    default:
      return state
  }
}
