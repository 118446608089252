import buttonsSidebarActionTypes from '../../../constants/actions/dossier/common/buttonsSidebar'

export function setActiveSection (activeSection) {
  return {
    type: buttonsSidebarActionTypes.SET_ACTIVE_SECTION,
    activeSection
  }
}

export default {
  setActiveSection
}
