import React, { PureComponent } from 'react'
import { translateDocumentStatus, documentStatusClass, checklistStatusClass } from '../../../../../constants/dossier/common/documentStatus'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { documentEntityType as documentEntityTypeConstants } from '../../../../../constants/dossier/common/documentEntityType'

class DocumentThumbnail extends PureComponent {
  render () {
    const {
      readOnly,
      t,
      documentEntityType,
      objectId,
      documentFile: {
        documentId, checklistId, checklistStatus, documentStatus,
        showPrintButton, documentTypeUse, iconUrl = 'generic-document.jpg'
      },
      actions: { openDocumentEditionModalWithChecklistId, printCheckList, openModal }
    } = this.props
    // TODO print service for documents
    // TODO image from url key in documentFile
    const tKey = 'DOSSIER_COMPONENTS.DOCUMENTARY_MANAGEMENT.'
    const statusTooltip = (<Tooltip id="statusTooltip">{documentStatus && t(`${tKey}${translateDocumentStatus[documentStatus]}`)}</Tooltip>)
    const printTooltip = (<Tooltip id="printTooltip">{t(`${tKey}PRINT`)}</Tooltip>)
    return (
      <div
        style={{ borderColor: checklistStatusClass[checklistStatus] }}
        onClick={() => {
          if (readOnly) return
          if (objectId) {
            openDocumentEditionModalWithChecklistId(documentId, documentTypeUse, checklistId)
          } else {
            const alert = documentEntityType === documentEntityTypeConstants.ENTITY ? 'entityAlert' : 'vehicleAlert'
            openModal(alert)
          }
        }} className="document-uploaded-modal"
      >
        <img src={`/images/document_types/${iconUrl}`}/>
        <span className="document-modal">
          <OverlayTrigger placement="right" overlay={statusTooltip}>
            <i id="documentStatus" className={'ico-document-' + documentStatusClass[translateDocumentStatus[documentStatus]]}/>
          </OverlayTrigger>
          {showPrintButton && (<OverlayTrigger placement="right" overlay={printTooltip}><i onClick={(e) => {
            e.stopPropagation()
            checklistId && printCheckList(checklistId)
          }} className="ico-print"/></OverlayTrigger>)}
        </span>
      </div>
    )
  }
}

export default DocumentThumbnail
