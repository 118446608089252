import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import LoadSepomexFileMaster from '../../../components/masters/loadSepomexFile/LoadSepomexFileMaster'
import { loadSepomexFileToValidate, loadSepomexFileToImport, cancelLoadSepomexFile } from '../../../actions/loadSepomexFile/loadSepomexFile'
import { openModal } from '../../../actions/common'

export function mapStateToProps (state) {
  return { loadSepomexFile: state.loadSepomexFile }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      loadSepomexFileToValidate,
      loadSepomexFileToImport,
      cancelLoadSepomexFile,
      openModal
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(LoadSepomexFileMaster))
