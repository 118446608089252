import apiFetch from '../../apiFetch'

export default function (paperworkId, token, lastAccessDate, printDocument) {
  return apiFetch({
    endPoint: `dossier/paperwork/${paperworkId}/genDelivery?lastAccessDate=${lastAccessDate}`,
    method: 'GET',
    parseBlobAndAssign: printDocument,
    onlyOneRequestAllowed: true,
    token: token
  })
}
