
import React, { PureComponent } from 'react'
import { Button, Modal, Col, Row } from 'react-bootstrap'
import { reduxForm } from 'redux-form'
import { validateDate, validateDossierEconomic } from '../../util/validationFunctions'
import { dossierSubTypeId } from '../../constants/dossier/common/dossierSubType'
import FleetAndClientComponentPage from '../../containers/management/commons/FleetAndClientComponentPage'

import { dossierTypes as dossierTypesConstant } from '../../constants/dossier/common/dossierTypes'
import { getComponentsPermisions } from '../../util/permisionFunctions'
import { buttonsHeader } from '../../constants/dossier/common/buttonsHeader'
import CampaignsModal from '../dossiers/sales/campaings/CampaignsModal'
import EconomicPlan from '../dossiers/sales/EconomicPlan'
import { formatMoney } from '../../util/utils'
import { saleTypeCodeEnum } from '../../constants/dossier/sale/saleType'

class EconomicFleetModal extends PureComponent {
  constructor () {
    super()
    this.state = {
      disableClient: true,
      organizedUnit: null,
      dossierSubTypeId: null,
      openPanelFleet: true,
      openPanelClient: true,
      changeCheckAll: false,
      dataId: null,
      actionId: null,
      sidebarConfiguration: null,
      showCampaignModal: false,
      campaignSelected: {},
      total: 0
    }
  }

  componentDidUpdate () {

  }

  getSnapshotBeforeUpdate (prevProps) {
    if (prevProps.showModal !== this.props.showModal) {
      if (this.props.fleet === null || this.props.fleet === undefined) {
        this.props.actions.clearEntity(-1, dossierSubTypeId.venta_vn)
      }

      this.setState({ disableClient: true, organizedUnit: null, dossierSubTypeId: null })
    }
    if (prevProps.showModal && !this.props.showModal) {
      this.props.initialize({})
      this.props.reset()
      this.setState({
        disableClient: true,
        sidebarConfiguration: null,
        showCampaignModal: false,
        campaignSelected: {}
      })
    }

    if (prevProps.showModal !== this.props.showModal && this.props.showModal) {
      this.props.actions.fetchEconomicPlanCombos(this.props.fleet.organizedUnitId, this.props.fleet.dossierSubType)
      this.props.actions.getEconomicPlanPermissions(this.props.fleet.dossierSubType, this.props.fleet.organizedUnitId)
    }

    return null
  }

  updateComponentAfterDeleteAndRestart () {

  }

  validateDate (value, allValues, props) {
    const result = validateDate(value, props)
    return result === Object(result) ? undefined : result
  }

  fieldFill (field) {
    this.setState({ fields: { ...this.state.fields, ...field } })
  }

  collapsePanelFleet () {
    this.setState({ openPanelFleet: !this.state.openPanelFleet })
  }

  collapsePanelClient () {
    this.setState({ openPanelClient: !this.state.openPanelClient })
  }

  sidebarPermissions () {
    const componentKeys = Object.keys({
      bsale: null,
      entity: null,
      vehicle: null,
      campaign: null,
      uac: null,
      finance: null,
      paperwork: null,
      charge: null,
      other_data: null,
      delivery: null,
      services: null,
      fleet: null,
      self_supply: null
    })
    const newState = getComponentsPermisions(this.props.economicPlanModal?.economicFleetPermissions, buttonsHeader.planteo_economico, componentKeys)
    this.setState({ sidebarConfiguration: newState.activeTabConfiguration })
  }

  simulate (values) {
    this.props.actions.simulateEconomicPlanFleet()
  }

  handleToUpdateComponent (component) {

  }

  toggleCampaignModal (value) {
    const priceModel = !isNaN(this.props?.economicPlanModal?.dossierEconomicComponent?.modelPriceAmount) ? this.props?.economicPlanModal?.dossierEconomicComponent.modelPriceAmount : 0
    if (!value || (value && priceModel > 0)) {
      this.setState({ showCampaignModal: value })
    } else {
      this.props.actions.openErrorMessageModal('DOSSIER_COMPONENTS.VALIDATIONS.REQUIRED_VEHICLE_PRICE')
    }
  }

  saveEconomicPlan (values, mode) {
    this.setEconomicForCalculate(values.dossierEconomicComponent, this.props.economicPlanModal?.dossierEconomicComponent)
    if (mode === 'save' && this.props.economicPlanModal?.dossierEconomicComponent.simulated) {
      this.updateEconomic()
    } else {
      this.simulate()
    }
  }

  setEconomicForCalculate (values, dossierEconomicComponent) {
    values.associatedCosts = dossierEconomicComponent.associatedCosts
    values.discounts = dossierEconomicComponent.discounts
    values.extraSales = dossierEconomicComponent.extraSales
    values.optionals = dossierEconomicComponent.optionals
    values.presents = dossierEconomicComponent.presents
    values.supplieds = dossierEconomicComponent.supplieds
    return values
  }

  createCampaign (values) {
    this.props.actions.addCampaign(values)
    this.toggleCampaignModal(false)
  }

  updateEconomic () {
    let dossierList = this.props.dossiers.map(x => { return x.dossierId })
    this.props.actions.setEconomicPlanFleetProcess(dossierList, () => { this.props.actions.closeEconomicFleetModal() })
  }

  getEconomicDataUpdate () {
    this.props.actions.setSimulatedFlag(false)
  }

  checkKeyDown (e) {
    if (e.key === 'Enter') e.preventDefault()
  }

  closeModal () {
    this.props.actions.closeEconomicFleetModal()
  }

  getCodeComissionSaleType () {
    const { dossierFleetDetails } = this.props
    if (dossierFleetDetails === null || dossierFleetDetails === undefined || dossierFleetDetails.length === 0) {
      return false
    }

    for (let i = 0; i < dossierFleetDetails.length; i++) {
      let saleCode = dossierFleetDetails[i].saleTypeCode
      if (saleCode !== saleTypeCodeEnum.AGENCY_MODEL_FLEET_WAGEN && saleCode !== saleTypeCodeEnum.AGENCY_MODEL_FLEET_SEAT && saleCode !== saleTypeCodeEnum.AGENCY_MODEL_PART_WAGEN && saleCode !== saleTypeCodeEnum.AGENCY_MODEL_PART_SEAT) {
        return false
      }
    }

    return true
  }

  render () {
    const {
      t,
      showModal, handleSubmit, combos, change, economicPlanModal: { dossierEconomicComponent, economicFleetPermissions, campaignPermissions, editedCampaign, saleConfiguration },
      actions: {
        updateDiscount,
        createDiscount,
        deleteDiscount,
        updateOptional,
        createOptional,
        deleteOptional,
        updateComment,
        createComment,
        deleteComment,
        fetchCampaignEdition,
        updateCampaign,
        createCampaign,
        updateSupplied,
        createSupplied,
        deleteSupplied,
        updateExtraSale,
        createExtraSale,
        deleteExtraSale,
        deletePresent,
        updatePresent,
        createPresent,
        setActiveSection,
        openProcessingAgencyModal,
        openCommonModal,
        fetchSalesEconomic,
        updateAssociatedCosts,
        createAssociatedCosts,
        deleteAssociatedCosts,
        fetchDiscountModal,
        fetchAssociatedCostByPercentageModal,
        fetchProcessingAgencyModal,
        fetchAmountTaxModal,
        printDocumentaryWarranty,
        deleteEconomicCampaign,
        openCodeCommissionModal,
        changeCodeCommissionFleet
      }, dossierFleetDetails
    } = this.props
    const tKey = 'MANAGEMENT.FLEETS_TAB.'
    let title = t(`${tKey}FINANCIAL_ANALYSIS_TITLE`)

    let combosCampaing = JSON.parse(JSON.stringify(combos))
    combosCampaing.dossierSubTypesCombo = combosCampaing.dossierSubTypesComboCampaign

    let totalAmountWithOptionals = (!isNaN(dossierEconomicComponent.modelPriceAmount) ? dossierEconomicComponent.modelPriceAmount : 0) + (!isNaN(dossierEconomicComponent.optionalTotalAmount) ? dossierEconomicComponent.optionalTotalAmount : 0)

    let totalBill = dossierEconomicComponent.billTotalAmount ? dossierEconomicComponent.billTotalAmount : 0
    let organizedUnits = dossierFleetDetails ? dossierFleetDetails.map(x => x.organizedUnitId) : []
    const showCampaignPercentages = saleConfiguration?.showCampaignPercentages ?? false
    const showEconomicPlan = saleConfiguration?.showEconomicPlan ?? false

    if (!showModal || !this.props.fleet) { return null } else {
      return (
        <>
          <CampaignsModal
            t={t}
            editedCampaign= {editedCampaign}
            showModal={this.state.showCampaignModal}
            campaignSelected={this.state.campaignSelected}
            vehicleAmount={totalAmountWithOptionals}
            combos={combosCampaing}
            componentConfiguration={campaignPermissions}
            actions={{
              closeModal: () => this.toggleCampaignModal(false),
              updateCampaign,
              createCampaign: (values) => this.createCampaign(values)
            }}
            secondClass = 'secondModal'
            showPercentages = {showCampaignPercentages && showEconomicPlan}
          />
          <Modal className="fleet-Modal languages-modal economicFleetModal" show={showModal} onHide={() => this.closeModal()} backdrop={'static'}>
            <form autoComplete="off" onSubmit={handleSubmit((values) => this.saveEconomicPlan(values, 'save'))} onKeyPress={(e) => this.checkKeyDown(e)}>
              <Modal.Header closeButton onHide={() => this.closeModal()}>
                <Modal.Title>{title} <span className='fleet-panel-subTitle'>{'  (' + t(`${tKey}SELECTED_DOSSIERS`) + ' ' + (this.props.dossiers ? this.props.dossiers.length : 0) + ')'}</span></Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <FleetAndClientComponentPage
                  {...{ ...this.props, openPanelClient: false, openPanelFleet: false }}
                />
                <EconomicPlan
                  t={t}
                  pristine={true}
                  activeTab={buttonsHeader.planteo_economico}
                  dossierEconomicComponent={dossierEconomicComponent}
                  actions={{
                    updateDiscount,
                    createDiscount,
                    deleteDiscount,
                    updateOptional,
                    createOptional,
                    deleteOptional,
                    updateComment,
                    createComment,
                    deleteComment,
                    fetchCampaignEdition,
                    deleteEconomicCampaign,
                    updateCampaign,
                    createCampaign,
                    updateSupplied,
                    createSupplied,
                    deleteSupplied,
                    updateExtraSale,
                    createExtraSale,
                    deleteExtraSale,
                    deletePresent,
                    updatePresent,
                    createPresent,
                    setActiveSection,
                    openProcessingAgencyModal,
                    change,
                    openCommonModal,
                    fetchSalesEconomic,
                    updateAssociatedCosts,
                    createAssociatedCosts,
                    deleteAssociatedCosts,
                    fetchDiscountModal,
                    fetchProcessingAgencyModal,
                    fetchAssociatedCostByPercentageModal,
                    fetchAmountTaxModal,
                    printDocumentaryWarranty,
                    getEconomicDataUpdate: () => this.getEconomicDataUpdate(),
                    openCodeCommissionModal,
                    changeCodeCommission: changeCodeCommissionFleet
                  }}
                  readOnlyFields={false}
                  economicTab={true}
                  isFixed={true}
                  dossierType={dossierTypesConstant.fleetEconomicPlan}
                  activeStage={null}
                  combos={combos}
                  panelsPaddingTop={1}
                  tabConfiguration={this.state.sidebarConfiguration}
                  tabConfiguration2={economicFleetPermissions}
                  historical = {false}
                  isEconomicLoaded = {false}
                  handleToUpdateComponent = {this.handleToUpdateComponent.bind(this)}
                  dossierId={-1}
                  activeSection={this.props.activeSection}
                  dossierSubTypeId={this.props.fleet.dossierSubType}
                  codeCommissionSaleTypeShow = {this.getCodeComissionSaleType()}
                  organizedUnits={organizedUnits}
                />
              </Modal.Body>
              <Modal.Footer>
                <Row className='row-calculate'>
                  <Col sm={6} className='btn-wrapper'>
                    <Button
                      className="btn-standard"
                      onClick={() => this.toggleCampaignModal(true)} >
                      {t(`${tKey}ADD_CAMPAIGN`)}
                    </Button>
                    <Button
                      className="btn-standard"
                      onClick={handleSubmit((values) => this.saveEconomicPlan(values, 'simulate'))}>
                      {t(`${tKey}SIMULATE`)}
                    </Button>
                  </Col>
                  <Col sm={6} className="col-total">
                    <div><span className="total-import-left">{t('DOSSIER_COMPONENTS.TOTALS.TOTAL_BILL')}</span><span className="total-import-right span-bill">{formatMoney(totalBill)}</span></div>
                  </Col>
                </Row>
                <Row className='row-save'>
                  <Col sm={12} className='btn-wrapper'>
                    <Button
                      className="btn-Accept"
                      type="submit"
                      disabled={!dossierEconomicComponent.simulated}>
                      {t(`${tKey}SAVE`)}
                    </Button>
                    <Button
                      id="role-modal-submit-button"
                      className="btn-exit"
                      onClick = {() => this.closeModal()}>
                      {t(`${tKey}CANCEL`)}
                    </Button>
                  </Col>
                </Row>
              </Modal.Footer>
            </form>
          </Modal>
        </>
      )
    }
  }
}

const validate = (values, { t }, props) => {
  let errors = {}

  errors.dossierEconomicComponent = validateDossierEconomic(values.dossierEconomicComponent, t)
  return errors
}

export default reduxForm({
  form: 'economicPlanModal',
  validate

})(EconomicFleetModal)
