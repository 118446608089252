import React, { PureComponent } from 'react'
import { Panel, Row } from 'react-bootstrap'
import InputText from '../../commons/form/InputText'
import InputNatural from '../../commons/form/InputNatural'
import InputSelect from '../../commons/form/InputSelect'
import InputTimePicker from '../../commons/form/InputTimePicker'
import { getFieldsPermisions } from '../../../util/permisionFunctions'
import InputDatePicker from '../../commons/form/InputDatePicker'
import { Field } from 'redux-form'
import { permissions, clientInformation } from '../../../constants/backendIds'
import { dossierSubTypeId as dossierSubTypeIdConstants } from '../../../constants/dossier/common/dossierSubType'
import InputCheckBox from '../../commons/form/InputCheckBox'
import settings from '../../../setting'
import CustomFields from '../../commons/customFields/CustomFields'

class Delivery extends PureComponent {
  constructor () {
    super()
    this.state = {
      open: false,
      fieldsConfiguration: {
        tuneUpDestinationId: null,
        differentDeliveryPerson: null,
        deliveryCity: null,
        externalDelivery: null,
        km: null,
        realDeliveryDate: null,
        realDeliveryTime: null,
        lastITVDate: null,
        realDeliveryAddress: null,
        realDeliveryLocationId: null,
        deliveryPAP: null,
        observations: null,
        insuranceStartDate: null
      }
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.open) {
      this.setState({ open: true })
    }
  }

  UNSAFE_componentWillMount () {
    this.initializePermisions(this.props)
  }

  initializePermisions (props) {
    const fieldKeys = Object.keys(this.state.fieldsConfiguration)
    const newState = getFieldsPermisions(props.componentConfiguration.fieldsConfiguration, fieldKeys, props.componentConfiguration.permission)
    this.setState({ ...this.state, ...newState })
  }

  fetchSalesDossierPromiseDelivery () {
    if (this.state.open) {
      this.props.actions.setActiveSection(false)
    }
    this.setState({
      open: !this.state.open
    })
    if (this.props.isDeliveryLoaded === false) {
      return new Promise((resolve, reject) => {
        this.props.handleToUpdateComponent(this.props)
        if (this.props.dossierSubTypeId === dossierSubTypeIdConstants.registration_delivery || this.props.dossierSubTypeId === dossierSubTypeIdConstants.model_agency) {
          return this.props.actions.fetchRegistrationDeliveryDeliveryHistorical(this.props.dossierId, resolve, reject)
        } else {
          return this.props.actions.fetchSalesDeliveryHistorical(this.props.dossierId, resolve, reject)
        }
      })
    }
  }

  render () {
    const {
      t,
      deliveryLocationCombo = [],
      isSalesDossier,
      isRegistrationDeliveryDossier,
      dossierSubTypeId, customFields,
      combos: { tuneUpDestinationCombo }
    } = this.props
    const isVN = dossierSubTypeId === dossierSubTypeIdConstants.compra_vn || dossierSubTypeId === dossierSubTypeIdConstants.venta_vn || dossierSubTypeId === dossierSubTypeIdConstants.registration_delivery
    const tKey = 'DOSSIER_COMPONENTS.DELIVERY.'
    return (
      <div className="delivery-panel dossier-panel">
        <div className="header-panel" onClick={this.fetchSalesDossierPromiseDelivery.bind(this)}>
          <div className="name-wrapper">
            <i className="ico-delivery" />
            <h4>{isSalesDossier || isRegistrationDeliveryDossier ? t(`${tKey}TITLE_PANEL`) : 'Recepción'}</h4>
          </div>
          <i className={this.state.open ? 'ico-arrow-black-up' : 'ico-arrow-black-down'} />
        </div>
        <Panel expanded={this.state.open} onToggle={() => {}}>
          <Panel.Collapse>
            <Row>
              {
                this.state.fieldsConfiguration.externalDelivery.permission !== permissions.hidden && (<Field
                  id="externalDelivery"
                  name="dossierCompleteDeliveryComponent.dossierDeliveryComponent.externalDelivery"
                  placeholder={t(`${tKey}EXTERNAL_DELIVERY`)}
                  component={InputCheckBox}
                  disabled={true}
                />)
              }
            </Row>
            <Row>
              {this.state.fieldsConfiguration.realDeliveryDate.permission !== permissions.hidden && (<Field
                id="date"
                name="dossierCompleteDeliveryComponent.dossierDeliveryComponent.realDeliveryDate"
                colSm={!isVN ? (4) : (3)}
                controlLabel={!isSalesDossier && !isRegistrationDeliveryDossier ? (t(`${tKey}DATE_RECIVE`)) : (t(`${tKey}DATE_DELIVERY`))}
                component={InputDatePicker}
                disabled={true}
              />)
              }
              {this.state.fieldsConfiguration.realDeliveryTime.permission !== permissions.hidden && (<Field
                id="hour"
                name="dossierCompleteDeliveryComponent.dossierDeliveryComponent.realDeliveryTime"
                colSm={!isVN ? (4) : (3)}
                controlLabel={!isSalesDossier && !isRegistrationDeliveryDossier ? (t(`${tKey}HOUR_RECIVE`)) : (t(`${tKey}HOUR_DELIVERY`))}
                placeholder={!isSalesDossier && !isRegistrationDeliveryDossier ? (t(`${tKey}HOUR_RECIVE`)) : (t(`${tKey}HOUR_DELIVERY`))}
                component={InputTimePicker}
                disabled={true}
              />)
              }
              {this.state.fieldsConfiguration.lastITVDate.permission !== permissions.hidden && !isVN &&
                <Field
                  id="lastITVDate"
                  name="dossierCompleteDeliveryComponent.dossierDeliveryComponent.lastITVDate"
                  key="dossierCompleteDeliveryComponent.dossierDeliveryComponent.lastITVDate"
                  colSm={4}
                  controlLabel={t(`${tKey}LAST_ITV_DATE`)}
                  disabled={true}
                  component={InputDatePicker}
                />
              }
              {this.state.fieldsConfiguration.km.permission !== permissions.hidden && (<Field
                id="Km"
                name="dossierCompleteDeliveryComponent.dossierDeliveryComponent.km"
                colSm={!isVN ? (4) : (3)}
                controlLabel={t(`${tKey}KM`)}
                placeholder={t(`${tKey}KM`)}
                component={InputNatural}
                disabled={true}
                textAlign="left"
              />)
              }
              {this.state.fieldsConfiguration.deliveryCity.permission !== permissions.hidden && (<Field
                id="deliveryCity"
                name="dossierCompleteDeliveryComponent.dossierDeliveryComponent.deliveryCity"
                colSm={!isVN ? (4) : (3)}
                controlLabel={t(`${tKey}DELIVERY_CITY`)}
                placeholder={t(`${tKey}DELIVERY_CITY`)}
                component={InputText}
                disabled={true}
              />)
              }
              {(dossierSubTypeId === dossierSubTypeIdConstants.venta_vo) &&
              this.state.fieldsConfiguration.tuneUpDestinationId.permission !== permissions.hidden && (<Field
                colSm={4}
                id="tuneUpDestinationId"
                name="dossierCompleteDeliveryComponent.dossierDeliveryComponent.tuneUpDestinationId"
                controlLabel={t(`${tKey}TUNE_UP_DESTINATION`)}
                placeholder={t(`${tKey}TUNE_UP_DESTINATION`)}
                valueKey="id"
                labelKey="value"
                component={InputSelect}
                options={tuneUpDestinationCombo}
                disabled={true}
              />
              )}
              {isSalesDossier && this.state.fieldsConfiguration.insuranceStartDate.permission !== permissions.hidden && (<Field
                id="date"
                name="dossierCompleteDeliveryComponent.dossierDeliveryComponent.insuranceStartDate"
                colSm={4}
                controlLabel={t(`${tKey}INSURANCE_START_DATE`)}
                placeholder={t(`${tKey}INSURANCE_START_DATE`)}
                component={InputDatePicker}
                disabled={true}
              />)
              }
              <div className="clearfix"></div>
              {
                isSalesDossier || isRegistrationDeliveryDossier
                  ? this.state.fieldsConfiguration.realDeliveryAddress.permission !== permissions.hidden && (<Field
                    id="deliveryAddress"
                    name="dossierCompleteDeliveryComponent.dossierDeliveryComponent.realDeliveryAddress"
                    colSm={12}
                    componentClass="textarea"
                    controlLabel={t(`${tKey}ADDRESS`)}
                    placeholder={t(`${tKey}ADDRESS`)}
                    component={InputText}
                    disabled={true}
                  />)
                  : this.state.fieldsConfiguration.realDeliveryLocationId.permission !== permissions.hidden && (<Field
                    id="deliveryLocationId"
                    name="dossierCompleteDeliveryComponent.dossierDeliveryComponent.deliveryLocationId"
                    colSm={4}
                    controlLabel={t(`${tKey}ADDRESS`)}
                    placeholder={t(`${tKey}ADDRESS`)}
                    options={deliveryLocationCombo}
                    component={InputSelect}
                    disabled={true}
                  />)
              }
              {this.state.fieldsConfiguration.observations.permission !== permissions.hidden && (
                <Field
                  id="observations"
                  name="dossierCompleteDeliveryComponent.dossierDeliveryComponent.observations"
                  colSm={12}
                  componentClass="textarea"
                  controlLabel={t(`${tKey}OBSERVATIONS`)}
                  placeholder={t(`${tKey}OBSERVATIONS`)}
                  component={InputText}
                  disabled={true}
                  maxLength={512}
                />
              )}
              {
                this.state.fieldsConfiguration.differentDeliveryPerson.permission !== permissions.hidden && (<Field
                  id="differentDeliveryPerson"
                  name="dossierCompleteDeliveryComponent.dossierDeliveryComponent.differentDeliveryPerson"
                  placeholder={t(`${tKey}DIFFERENT_DELIVERY_PERSON`)}
                  inline
                  component={InputCheckBox}
                  disabled={true}
                />)
              }
              {
                (this.state.fieldsConfiguration.deliveryPAP.permission !== permissions.hidden && settings.clientFieldValidation === clientInformation.VGR) && (<Field
                  id="pap"
                  name="dossierCompleteDeliveryComponent.dossierDeliveryComponent.pap"
                  placeholder={t(`${tKey}PAP`)}
                  inline
                  component={InputCheckBox}
                  disabled={true}
                />)
              }
            </Row>
            <CustomFields customFields={customFields} readOnlyFields={true} />
          </Panel.Collapse>
        </Panel>
      </div>
    )
  }
}
export default Delivery
