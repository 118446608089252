import React, { PureComponent } from 'react'
import { Panel, Button } from 'react-bootstrap'
import InputDatePicker from '../../../../_v2/components/commons/form/InputDatePicker'
import { Field } from 'redux-form'
import { getFieldsPermisions } from '../../../../util/permisionFunctions'
import { compareObjects } from '../../../../util/utils'
import { permissions, dateType } from '../../../../constants/backendIds'
import moment from 'moment'
import ComponentTitle from '../../../../_v2/containers/dossiers/commons/ComponentTitlePage'

class Checklist extends PureComponent {
  constructor () {
    super()
    this.state = {
      open: false,
      fieldsConfiguration: {
        confirmationDoneButton: null,
        confirmationDate: null
      }
    }
  }

  initializePermisions (props) {
    const fieldKeys = Object.keys(this.state.fieldsConfiguration)
    const newState = getFieldsPermisions(props.componentConfiguration.fieldsConfiguration, fieldKeys, props.componentConfiguration.permission)
    this.setState({ fieldsConfiguration: newState.fieldsConfiguration })
  }

  componentDidUpdate (prevProps) {
    if (this.props.dossier && this.props.dossier.dossierId) {
      if (!compareObjects(prevProps.componentConfiguration || {}, this.props.componentConfiguration, true)) {
        this.initializePermisions(this.props)
      }
      if (this.props.open) {
        this.setState({ open: true })
      }
    }
    return prevProps
  }

  componentDidMount () {
    if (this.props.componentConfiguration && this.props.componentConfiguration !== null) {
      this.initializePermisions(this.props)
    }
  }

  permissionButon () {
    return !this.props.historical &&
    isNaN((moment(this.props.dossier?.dossierChecklistComponent?.confirmationDate, dateType.standard).toDate())) &&
    this.state.fieldsConfiguration?.confirmationDoneButton && this.state.fieldsConfiguration.confirmationDoneButton.permission !== permissions.hidden &&
    this.props.dossier?.checkListHeader?.find(x => x.name === 'Checklist')?.docs?.filter(x => !x.isComplete).length < 1
  }

  fetchSalesDossierPromiseChecklist () {
    if (this.state.open) {
      this.props.actions.setActiveSection(false)
    }
    this.setState({
      open: !this.state.open
    })
  }

  render () {
    const { t, actions: { confirmDateWithIdByStage }, dossierId } = this.props
    const tKey = 'DOSSIER_COMPONENTS.CHECKLIST_COMPONENT.'
    return (
      <div className='dossier-Component'>
        <div className='header-panel' onClick={this.fetchSalesDossierPromiseChecklist.bind(this)}>
          <ComponentTitle
            componentCode="CHECKLIST_COMPONENT"
            componentOpen={this.state.open}
            // extraInfo={'info extra'}
            // extraData={<a className="_Btn accept">texto</a>}
          />
        </div>
        <Panel expanded={this.state.open} onToggle={() => {}}>
          <Panel.Collapse>
            {this.permissionButon() && (
              <div className='flexInLine al-R'>
                <Button className='_Btn new'
                  onClick={() => confirmDateWithIdByStage(dossierId)}
                  disabled={this.state.fieldsConfiguration.confirmationDoneButton.permission !== permissions.editable}
                >
                  {t(`${tKey}DONE_BUTTON`)}
                </Button>
              </div>
            )}
            {this.state.fieldsConfiguration.confirmationDate && this.state.fieldsConfiguration.confirmationDate.permission !== permissions.hidden && (
              <div className='fieldsContainer'>
                <Field
                  component={InputDatePicker}
                  colWidht={33} // 25, 33, 50, 75, 100 default=25
                  id="confirmationDate"
                  key="confirmationDate"
                  name="dossierChecklistComponent.confirmationDate"
                  controlLabel={t(`${tKey}CONFIRM_DATE`)}
                  placeholder={t(`${tKey}CONFIRM_DATE`)}
                  disabled={true}
                  customClass={'disabled'}
                />
              </div>
            )}
          </Panel.Collapse>
        </Panel>
      </div>
    )
  }
}
export default Checklist
