import React, { PureComponent } from 'react'
import { Modal, Col, Row, FormGroup, Button } from 'react-bootstrap'
import { reduxForm, Field } from 'redux-form'
import InputText from '../../commons/form/InputText'
// import InputTreeSelect from '../../commons/form/InputTreeSelect'

class FinancingModal extends PureComponent {
  constructor () {
    super()
    this.state = {
    }
  }

  submitFinancing (values) {
    const body = {
      ...values
    }
    this.props.actions.submitFinancing(body, this.props.financing)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.showModal !== this.props.showModal && nextProps.showModal === true) {
      this.props.initialize({ ...nextProps.financing })
    }
  }

  render () {
    const {
      t, handleSubmit,
      showModal
      //, combos: { UOTreeCombo }
    } = this.props
    const tKey = 'MASTERS.FINANCING_MASTERS.MODAL.'
    return (
      <Modal className="role-modal languages-modal" show={showModal} onHide={this.props.actions.closeEditFinancingModal}>
        <form autoComplete="off" onSubmit={handleSubmit(this.submitFinancing.bind(this))}>
          <Modal.Header closeButton onHide={this.props.actions.closeEditFinancingModal}>
            <Modal.Title>{t(`${tKey}TITLE`)}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <Row key={1} className="inputs-wrapper">
                {/* <Col sm={4} className="custom-width-col">
                  <h2>{t(`${tKey}ORGANIZED_UNIT`)}</h2>
                  <Field
                    name="organizedUnitId"
                    placeholder={t(`${tKey}ORGANIZED_UNIT`)}
                    component={InputTreeSelect}
                    multi={false}
                    allowClear={false}
                    valueKey="value"
                    labelKey="label"
                    childrenKey="children"
                    colSm={0}
                    options={UOTreeCombo}
                  />
                </Col> */}
                <Col sm={4} className="custom-width-col">
                  <h2>{t(`${tKey}NAME`)}</h2>
                  <Field
                    name="name"
                    placeholder={t(`${tKey}NAME`)}
                    component={InputText}
                  />
                </Col>
                <Col sm={4} className="custom-width-col">
                  <h2>{t(`${tKey}DESCRIPTION`)}</h2>
                  <Field
                    name="description"
                    placeholder={t(`${tKey}DESCRIPTION`)}
                    component={InputText}
                  />
                </Col>
              </Row>
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <Col sm={12} className="btn-wrapper">
              <Button
                id="role-modal-submit-button"
                className="btn-standard"
                type="submit"
                disabled={this.props.pristine}>
                {t(`${tKey}SUBMIT_BUTTON`)}
              </Button>
            </Col>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default reduxForm({
  form: 'editFinancing',
  validate: (values, props) => {
    const error = { financing: {} }
    if (values) {
      const tKey = 'MASTERS.FINANCING_MASTERS.VALIDATION.'
      if (!values.name) {
        error.name = props.t(`${tKey}NAME`)
      }
      if (!values.description) {
        error.description = props.t(`${tKey}DESCRIPTION`)
      }
    }
    return Object.keys(error).length > 0 ? error : {}
  }
})(FinancingModal)
