import React, { PureComponent } from 'react'
import { Panel, Table, Button } from 'react-bootstrap'
import PaperworkRow from '../../../../../_v2/components/dossiers/common/paperworksTable/PaperworkRow'
import PaperworkModal from '../../../../../components/dossiers/common/paperworksTable/PaperworkEditionModal'
import SmallLoaderPage from '../../../../../containers/commons/SmallLoaderPage'
import InputCheckbox from '../../../../../_v2/components/commons/form/InputCheckBox'
import { getFieldsPermisions } from '../../../../../util/permisionFunctions'
import { permissions } from '../../../../../constants/backendIds'
import { compareObjects } from '../../../../../util/utils'
import { Field } from 'redux-form'
import { dossierTypes as dossierTypesConstant } from '../../../../../constants/dossier/common/dossierTypes'
import CustomFields from '../../../../../components/commons/customFields/CustomFields'
import ComponentTitle from '../../../../../_v2/containers/dossiers/commons/ComponentTitlePage'

class PaperworksTable extends PureComponent {
  constructor () {
    super()
    this.state = {
      open: true,
      fieldsConfiguration: {
        externalPaperwork: null,
        btnAdd: null
      }
    }
  }

  initializePermisions (props) {
    const fieldKeys = Object.keys(this.state.fieldsConfiguration)
    const newState = getFieldsPermisions(props.componentConfiguration.fieldsConfiguration, fieldKeys, props.componentConfiguration.permission)
    this.setState({ fieldsConfiguration: newState.fieldsConfiguration })
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (this.props.dossierParentId && !compareObjects(prevProps.componentConfiguration || {}, this.props.componentConfiguration, true)) {
      this.initializePermisions(this.props)
    }
    if (!prevProps.open && this.props.open) {
      this.setState({ open: true })
    }
    return prevProps
  }

  componentDidMount () {
    this.initializePermisions(this.props)
  }

  componentDidUpdate () {
    // don't remove use for getsnapshot
  }

  toggleComponent () {
    if (this.state.open) {
      this.props.actions.setActiveSection(false)
    }
    this.setState({ open: !this.state.open })
  }

  render () {
    const {
      t, paperwork, editedPaperwork, dossierType,
      readOnlyFields, combos, organizedUnitId, dossierParentId,
      showModal, isEditionModal, updateDossierAction, showAlertSendPending, showEconomicPlan, componentConfiguration,
      purchaseVoMandate, customFields,
      actions: {
        openPaperworkEdition,
        deletePaperwork,
        fetchPaperworkEditionModal,
        printPaperwork,
        updateDependantsSaleDossierAndHeader,
        updatePaperwork,
        createPaperwork,
        closePaperworkEditionModal,
        openDocumentEditionModal,
        generateFileToSign,
        checkOrderStatus,
        navigateTo,
        signTypes,
        requestSign,
        openErrorMessageModal
      }
    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.PAPERWORK.'
    return (
      <div className="dossier-Component">
        <PaperworkModal
          t={t}
          editedPaperwork={editedPaperwork}
          combos={combos}
          showModal={showModal}
          dossierParentId={dossierParentId}
          isEditionModal={isEditionModal}
          showAlertSendPending={showAlertSendPending}
          actions={{
            closePaperworkEditionModal,
            updatePaperwork,
            createPaperwork,
            updateDossierAction
          }}
          showEconomicPlan={showEconomicPlan}
        />
        <div className="header-panel" onClick={this.toggleComponent.bind(this)}>
          <ComponentTitle
            componentCode="PAPERWORK"
            componentOpen={this.state.open}
          />
        </div>
        <Panel expanded={this.state.open} onToggle={() => {}}>
          <Panel.Collapse>
            { this.state.fieldsConfiguration.externalPaperwork && this.state.fieldsConfiguration.externalPaperwork.permission !== permissions.hidden &&
              dossierType && dossierType === dossierTypesConstant.sales && (
              <>
                <div className="fieldsContainer">
                  <Field
                    component={InputCheckbox}
                    colWidht={100} // 25, 33, 50, 75, 100 default=25
                    id="externalPaperwork"
                    name="dossierPaperworkComponent.externalPaperwork"
                    placeholder={t(`${tKey}EXTERNAL_PAPERWORK`)}
                    disabled={readOnlyFields || this.state.fieldsConfiguration.externalPaperwork.permission !== permissions.editable}
                  />
                </div>
                <div className='separate'></div>
              </>
            )}
            <div className="fieldsContainer">
              <div className="wrapper _nf">
                <Table className="editable-table" hover responsive>
                  <thead>
                    <tr>
                      <th>{t(`${tKey}NUMBER`)}</th>
                      <th>{t(`${tKey}TYPE`)}</th>
                      <th>{t(`${tKey}STAGE`)}</th>
                      <th>{t(`${tKey}DATE`)}</th>
                      <th>{t(`${tKey}COST`)}</th>
                      <th>{t(`${tKey}PROCESSING_AGENCY`)}</th>
                      <th>{t(`${tKey}DOCUMENTS`)}</th>
                      <th className='actions'>{t(`${tKey}ORDER`)}</th>
                      <th className='actions'>{t('DOSSIER_COMPONENTS.ACCESSORIES.ACTIONS')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      paperwork.map((paperwork, idx) => {
                        return (
                          <PaperworkRow
                            t={t}
                            tKey={tKey}
                            organizedUnitId={organizedUnitId}
                            dossierParentId={dossierParentId}
                            paperwork={paperwork}
                            navigateTo={navigateTo}
                            key={idx}
                            updateDependantsSaleDossierAndHeader={updateDependantsSaleDossierAndHeader}
                            openPaperworkEdition={openPaperworkEdition}
                            printPaperwork={printPaperwork}
                            fetchPaperworkEditionModal={fetchPaperworkEditionModal}
                            deletePaperwork={deletePaperwork}
                            updateDossierAction={updateDossierAction}
                            showAlertSendPending={showAlertSendPending}
                            openDocumentEditionModal={openDocumentEditionModal}
                            readOnlyFields={readOnlyFields}
                            dossierType={dossierType}
                            componentConfiguration={componentConfiguration}
                            generateFileToSign={generateFileToSign}
                            checkOrderStatus={checkOrderStatus}
                            purchaseVoMandate={purchaseVoMandate}
                            actions={{ signTypes, requestSign, openErrorMessageModal }}
                          />
                        )
                      })
                    }
                  </tbody>
                  <div className='flexInLine al-L'>
                    {
                      !paperwork.length && !readOnlyFields && this.state.fieldsConfiguration.btnAdd && this.state.fieldsConfiguration.btnAdd.permission === permissions.editable ? (
                        <Button className="_Btn new" onClick={() => fetchPaperworkEditionModal(organizedUnitId, dossierParentId)}>
                          <i className="iDocIcon-add-plus"></i>
                          {t(`${tKey}ADD_BTN`)}
                        </Button>
                      ) : null
                    }
                  </div>
                </Table>
              </div>
            </div>
            <CustomFields customFields={customFields} readOnlyFields={readOnlyFields} />
          </Panel.Collapse>
        </Panel>
        <SmallLoaderPage />
      </div>
    )
  }
}

export default PaperworksTable
