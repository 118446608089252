export const currencies = {
  EUR: 'EUR',
  USD: 'USD'
}

export const currencyTextsConfig = {
  EUR: {
    1: {
      single: 'euro',
      plural: 'euros',
      special: 'de euros'
    },
    2: {
      single: 'euro',
      plural: 'euros',
      special: 'd\'euros'
    },
    3: {
      single: 'euro',
      plural: 'euros',
      special: 'de euros'
    },
    4: {
      single: 'euro',
      plural: 'euros',
      special: 'de euros'
    },
    5: {
      single: 'euro',
      plural: 'euros',
      special: 'de euros'
    }
  },
  USD: {
    1: {
      single: 'dólar',
      plural: 'dólares',
      special: 'de dólares'
    },
    2: {
      single: 'dólar',
      plural: 'dólares',
      special: 'de dólares'
    },
    3: {
      single: 'dólar',
      plural: 'dólares',
      special: 'de dólares'
    },
    4: {
      single: 'dólar',
      plural: 'dólares',
      special: 'de dólares'
    },
    5: {
      single: 'dólar',
      plural: 'dólares',
      special: 'de dólares'
    }
  }
}

export const currencySymbol = {
  EUR: '€',
  USD: '$'
}

export const currencyMoneyFormat = {
  EUR: {
    format: {
      style: 'currency',
      currency: 'EUR',
      maximumFractionDigits: 2
    },
    currency: 'es-ES'
  },
  USD: {
    format: {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 2
    },
    currency: 'en-US'
  }
}

export const icoCurrencyContent = {
  EUR: 'ico-planteo-economico',
  USD: 'ico-planteo-economico-usd'
}
