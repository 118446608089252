import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { closeNonUpdatedDossiers, fetchNonUpdatedDossiers, delayNonUpdatedDossierAlert } from '../../../actions/dossier/common/nonUpdatedDossiers'
import NonUpdatedDossiers from '../../../components/dossiers/common/nonUpdatedDossiers/NonUpdatedDossiers'
import { openErrorMessageModal } from '../../../actions/modals/messageModal'

export function mapStateToProps (state) {
  const sections = state.auth.sections
  return {
    ...state.nonUpdatedDossiers,
    backTo: sections && sections.length > 1 ? sections[0].code : ''
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      closeNonUpdatedDossiers,
      fetchNonUpdatedDossiers,
      delayNonUpdatedDossierAlert,
      openErrorMessageModal
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(NonUpdatedDossiers))
