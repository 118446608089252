import loadSepomexFileActionTypes from '../../../constants/actions/loadSepomexFile/loadSepomexFile'

function initialState () {
  return {
    validationResult: {
      readRows: 0,
      validateRows: 0,
      nonValidateRows: 0
    },
    showValidationModal: false,
    importResult: {
      readRows: 0,
      validateRows: 0,
      updatedRows: 0
    },
    showImportModal: false
  }
}

function sepomexFileValidationSuccess (state, { validationResult }) {
  return {
    ...state,
    validationResult: validationResult,
    showValidationModal: true
  }
}

function sepomexFileImportSuccess (state) {
  return {
    ...state,
    showValidationModal: false
  }
}

function cancelLoadSepomexFile (state) {
  return initialState()
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case loadSepomexFileActionTypes.SEPOMEX_FILE_VALIDATION_SUCCESS:
      return sepomexFileValidationSuccess(state, action)
    case loadSepomexFileActionTypes.SEPOMEX_FILE_IMPORT_SUCCESS:
      return sepomexFileImportSuccess(state, action)
    case loadSepomexFileActionTypes.CANCEL_LOAD_SEPOMEX_FILE:
      return cancelLoadSepomexFile(state)
    default:
      return state
  }
}
