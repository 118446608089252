import Settings from './settings'

export default {
  ...Settings,
  url: 'http://localhost/BackEnd-iDocCar/api/',
  IdocSingUrl: 'http://localhost:4200/sign-app/login',
  urlHotFolder: '',
  webSocketUrl: 'wss://localhost:8009',
  notificationRefresh: 60000,
  showSignDocumentButton: false,
  acceptFileFormats: 'application/pdf, .jpg, .png, .jpeg, .gif, .docx, .doc, .xls, .xlsx, .ppt, .pptx, .msg, .tiff',
  qaSite: true,
  dealerMaxLength: 7,
  dealerNumberRegex: null,
  clientFieldValidation: 'all',
  autoLoadDashboard: false,
  validateLicensePlate: true,
  maxTagCount: 2,
  maxTagTextLength: 10,
  matomoUrl: 'https://localhost',
  matomoSiteId: '0',
  authenticationWithSalt: false,
  enableFavoritesAndRecents: true,
  enableTaskFollowUp: true,
  dashboardVersion: 2,
  paymentGatewayUrl: 'https://canales.redsys.es/admincanales-web/index.jsp#/login'
}
