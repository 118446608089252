// import apiFetch from '../apiFetch'
// import settings from '../../setting'
import { loadAuth } from '../../services/sessionAuth'

import { CustomDimensionsConstant } from '../../constants/tracking/CustomDimensionsConstant'

export default class MatomoService {
  trackPageVisit (infoNavigation, trackPageView) {
    return trackPageView({
      documentTitle: infoNavigation.documentTitle, // optional
      href: infoNavigation.href, // optional
      customDimensions: this.getCustomDimensions(infoNavigation)// optional
    })
  }

  getValueFromCustomDimension (data, customDimensionsConstant) {
    if (data.customDimensions) {
      var dimension = data.customDimensions.find(element => element.id === customDimensionsConstant)
      return dimension ? dimension.value : undefined
    }
    return undefined
  }

  getCustomDimensions (data) {
    var auth = loadAuth()
    var commonCustomDimensions = [
      {
        id: CustomDimensionsConstant.USER_ID,
        value: auth ? auth.userId : null
      },
      {
        id: CustomDimensionsConstant.ORGANIZED_UNIT_ID,
        value: data.organizedUnitId
      },
      {
        id: CustomDimensionsConstant.DASHBOARD_TASK_ID,
        value: this.getValueFromCustomDimension(data, CustomDimensionsConstant.DASHBOARD_TASK_ID)
      },
      {
        id: CustomDimensionsConstant.DASHBOARD_TASK_NAME,
        value: this.getValueFromCustomDimension(data, CustomDimensionsConstant.DASHBOARD_TASK_NAME)
      },
      {
        id: CustomDimensionsConstant.DOCUMENT_ID,
        value: this.getValueFromCustomDimension(data, CustomDimensionsConstant.DOCUMENT_ID)
      },
      {
        id: CustomDimensionsConstant.DOCUMENT_TYPE_ID,
        value: this.getValueFromCustomDimension(data, CustomDimensionsConstant.DOCUMENT_TYPE_ID)
      },
      {
        id: CustomDimensionsConstant.DOCUMENT_TYPE_USE_NAME,
        value: this.getValueFromCustomDimension(data, CustomDimensionsConstant.DOCUMENT_TYPE_USE_NAME)
      },
      {
        id: CustomDimensionsConstant.DOCUMENT_TYPE_USE_ID,
        value: this.getValueFromCustomDimension(data, CustomDimensionsConstant.DOCUMENT_TYPE_USE_ID)
      }
    ]
    return commonCustomDimensions
  }

  trackAction (infoAction, trackEvent) {
    return trackEvent({
      category: infoAction.category,
      action: infoAction.action,
      // name: infoAction.name, // optional
      // value: infoAction.value, // optional, numerical value
      documentTitle: infoAction.documentTitle, // optional
      href: infoAction.href, // optional
      customDimensions: this.getCustomDimensions(infoAction) // optional
    })
  }
}
