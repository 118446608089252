import apiFetch from '../../apiFetch'

export default function (dossierId, token) {
  return apiFetch({
    endPoint: `dossier/dossierBudgetComponentHistorical/${dossierId}`,
    method: 'GET',
    body: null,
    token: token
  })
}
