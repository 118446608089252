import React, { PureComponent } from 'react'
import { reduxForm } from 'redux-form'
import { Row, Col, Table } from 'react-bootstrap'
import { Element } from 'react-scroll'
import { dossierTypes as dossierTypesConstant } from '../../../constants/dossier/common/dossierTypes'
import { compareObjects, compareObjectsWithStringify, getDocumentaryPermission } from '../../../util/utils'
import { validateInteger, validateEntity, validateVehicle, validateContact, validatePercentages, validateRegex, customValidations } from '../../../util/validationFunctions'
import { getComponentsPermisions } from '../../../util/permisionFunctions'
import DocumentaryManagementModalPage from '../../../containers/dossiers/common/DocumentaryManagementModalPage'
import StatusHeaderPage from '../../../containers/dossiers/common/StatusHeaderPage'
import ButtonsHeader from '../common/ButtonsHeader'
import ButtonsSidebar from '../common/ButtonsSidebar'
import Client from '../common/Client'
import Vehicle from '../common/Vehicle'
import CampaignComponent from './CampaignComponent'
import RejectCommentModal from '../common/RejectCommentModal'
import { buttonsHeader } from '../../../constants/dossier/common/buttonsHeader'
import { buttonsSidebar } from '../../../constants/dossier/common/buttonsSidebar'
import { permissions, entityTypesId, clientInformation } from '../../../constants/backendIds'
import DocumentaryManagementPage from '../../../containers/dossiers/common/DocumentaryManagementPage'
import AuditInfo from '../common/audit/AuditInfo'
import RelatedDossierList from '../../commons/RelatedDossierList'
import ReassignReplacePurchaseModalPage from '../../../containers/dossiers/common/ReassignReplacePurchaseModalPage'
import CommentComponent from '../common/comment/CommentComponent'
import settings from '../../../setting'
import TrackingContainer from '../../../containers/tracking/trackingContainer'
import { TrackedPages } from '../../../constants/tracking/TrackedPages'
import {
  asyncValidateEntityDni, getFieldsValidated
} from '../../../util/validationFunctionEntityDniAsync'
import { validationCampaignPromisesOrder } from '../../../constants/validatedFields/backValidationPromisesOrder'
import promisesStatus from '../../../constants/promisesStatus'

class Campaign extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      modalOpen: true,
      showRejectCommentModal: false,
      showDenyCommentModal: false,
      campaignOpen: true,
      savePercentages: 1,
      isVehicleLoaded: false,
      isClientLoaded: false,
      activeTabConfiguration: null,
      headerTabConfiguration: null,
      componentsConfiguration: {
        bcampaign: null,
        entity: null,
        vehicle: null
      },
      reason: null,
      comment: null
    }
    this.goBack = this.goBack.bind(this)
    this.togglePanel = this.togglePanel.bind(this)
  }

  goBack () {
    this.props.actions.setActiveTab(buttonsHeader.datos_expediente)
    this.props.history.goBack()
  }

  togglePanel () {
    if (this.state.campaignOpen) {
      this.props.actions.setActiveSection(false)
    }
    this.setState({ campaignOpen: !this.state.campaignOpen })
  }

  toggleRejectCommentModal (showRejectCommentModal) {
    this.setState({ showRejectCommentModal })
  }

  toggleDenyCommentModal (showDenyCommentModal) {
    this.setState({ showDenyCommentModal })
  }

  submitCampaignDossier (values) {
    if (this.state.savePercentages === 1) {
      values.dossierCampaignComponent.costPercentage = null
      values.dossierCampaignComponent.costConcessionPercentage = null
      values.dossierCampaignComponent.amountPercentage = null
      values.dossierCampaignComponent.additionalPercentage = null
    } else {
      values.dossierCampaignComponent.cost = null
      values.dossierCampaignComponent.costConcession = null
      values.dossierCampaignComponent.amount = null
      values.dossierCampaignComponent.additionalAmount = null
    }
    this.props.actions.saveCampaignDossier(values)
  }

  fetchCampaignDossierPromise (dossierId) {
    return new Promise((resolve, reject) => {
      return this.props.actions.fetchCampaignDossier(dossierId, resolve, reject)
    })
  }

  onClickRelatedDossierColumn (columnCode) {
    let columnCodeOrder = '+' + columnCode
    if (columnCodeOrder === this.props.relatedDossiersOrderBy) {
      columnCodeOrder = '-' + columnCode
    }
    this.props.actions.fetchFilteredRelatedDossier(this.props.dossier.dossierId, columnCodeOrder)
  }

  getRelatedDossierColumnIcon (columnCode) {
    if ('+' + columnCode === this.props.relatedDossiersOrderBy) {
      return 'ico-sort-selected-asc'
    } else if ('-' + columnCode === this.props.relatedDossiersOrderBy) {
      return 'ico-sort-selected-desc'
    } else {
      return 'ico-sort'
    }
  }

  routerWillLeave (nextLocation) {
    let pristine = true
    if (this.props.dossier.viewConfiguration.buttonsPermission && this.props.dossier.viewConfiguration.buttonsPermission.includes('campaign_save')) {
      pristine = this.props.pristine
    }
    this.props.actions.campaignWarningSave(this.props.reset, this.props.history, nextLocation, pristine)
    return pristine
  }

  initializePermisions (props) {
    const componentKeys = Object.keys(this.state.componentsConfiguration)
    const newState = getComponentsPermisions(props.dossier.viewConfiguration.tabsConfiguration, props.activeTab, componentKeys)
    this.setState({ ...this.state, ...newState })
  }

  componentDidMount () {
    this.props.actions.restartCampaignState()
    this.props.actions.fetchCommercialSocietyTypeCombo()
    this.fetchCampaignDossierPromise(this.props.match.params.dossierId).then(() => {
      const form = {
        ...this.props.dossier,
        entityComponent: this.props.dossier.entityComponent.entityId ? this.props.dossier.entityComponent : undefined
      }
      if (!this.props.dossier.entityComponent.entityId) delete form.entityComponent
      if (!this.props.dossier.vehicleComponent.vehicleId) delete form.vehicleComponent
      this.props.initialize(form)
      this.initializePermisions(this.props)
      this.props.actions.fetchAllReasons(this.props.dossier.dossierSubTypeId)
      this.props.actions.getComments(this.props.match.params.dossierId, 'campaignDossier')
      this.props.actions.fetchCustomFields(this.props.dossier.dossierSubTypeId)
      this.props.actions.registerLastAccess(this.props.match.params.dossierId, false)

      this.props.trackVisitPage({
        documentTitle: TrackedPages.CAMPAIGN,
        organizedUnitId: this.props.dossier.organizedUnitId,
        href: window.location.href
      })
    })
    this.unregisterLeaveHook = this.props.history.block(this.routerWillLeave.bind(this))
  }

  getSnapshotBeforeUpdate (prevProps) {
    if ((this.props.showManagementModal && !prevProps.showManagementModal) || this.props.match.params.dossierId !== prevProps.match.params.dossierId) {
      if (this.props.match.params.dossierId !== prevProps.match.params.dossierId) {
        this.props.actions.restartCampaignState()
        this.props.actions.setScrollTo(buttonsSidebar.bcampaign)
        this.props.actions.setActiveSection(buttonsSidebar.bcampaign)
        this.props.actions.setActiveTab(buttonsHeader.datos_expediente)
        this.props.actions.closeAddOrEditDocumentModal()
      }
      this.fetchCampaignDossierPromise(this.props.match.params.dossierId).then(() => {
        const form = { ...this.props.dossier }
        if (!this.props.dossier.entityComponent.entityId) delete form.entityComponent
        if (!this.props.dossier.vehicleComponent.vehicleId) delete form.vehicleComponent
        this.props.initialize(form)
        this.initializePermisions(this.props)
      })
    }
    /*
    if (this.props.dossier.dossierId && !compareObjects(prevProps.dossier.dossierCampaignComponent || {}, this.props.dossier.dossierCampaignComponent, true)) {
      const form = { ...this.props.dossier }
      this.props.initialize(form)
    }
    */
    if (this.props.dossier.dossierId && !compareObjects(prevProps.dossier.viewConfiguration.tabsConfiguration || {}, this.props.dossier.viewConfiguration.tabsConfiguration, true)) {
      this.initializePermisions(this.props)
    }
    if (this.props.dossier.dossierId && prevProps.activeTab !== this.props.activeTab) {
      this.initializePermisions(this.props)
    }
    if (this.props.dossier && this.props.dossier.comments && this.props.activeTab !== prevProps.activeTab && this.props.activeTab === buttonsHeader.observaciones) {
      this.props.actions.getComments(this.props.dossier.dossierId, 'campaignDossier')
    }
    if (this.props.dossier && this.props.dossier.organizedUnitId && prevProps.dossier && prevProps.dossier.organizedUnitId !== this.props.dossier.organizedUnitId) {
      this.props.actions.fecthOrganizedUnitsByDossierUo(this.props.dossier.organizedUnitId)
    }
    return prevProps
  }

  componentDidUpdate (prevProps) {
    if (this.props.dossier.dossierId && this.props.statusHeaderHeight && this.props.activeSection) {
      switch (this.props.activeSection) {
        case buttonsSidebar.bcampaign:
          this.setState({ campaignOpen: true })
          break
      }
    }

    const firstCustomFieldsLoad = prevProps.dossier.customFieldsByComponent?.length < this.props.dossier.customFieldsByComponent?.length
    if (firstCustomFieldsLoad) {
      let customFields = {}
      if (this.props.dossier.customFieldsValues) {
        this.props.dossier.customFieldsValues.map(x => {
          customFields[x.key] = x.value
        })
      }
      const form = {
        ...this.props.dossier,
        customFields: customFields
      }
      this.props.initialize(form)
    }
  }

  savePercentages (value) {
    this.setState({ savePercentages: value })
  }

  componentWillUnmount () {
    this.props.actions.setActiveSection(buttonsSidebar.bcampaign)
    this.props.actions.setScrollTo(null)
    this.props.actions.resetButtonsHeader()
    this.unregisterLeaveHook
    this.props.actions.closeAddOrEditDocumentModal()
    this.props.actions.restartCampaignState()
  }

  handleSubmitForm () {
    if (this.props.formErrors) {
      let skipGeneralMsg = false
      if (this.props.formErrors.dossierCamapignComponent && Object.keys(this.props.formErrors.dossierCampaignComponent).length > 0) {
        this.props.actions.setScrollTo(buttonsSidebar.bcampaign)
        this.props.actions.setActiveSection(buttonsSidebar.bcampaign)
      } else if (this.props.formErrors.entityComponent && Object.keys(this.props.formErrors.entityComponent).length > 0 ||
                 this.props.formErrors.entityChildComponent && Object.keys(this.props.formErrors.entityChildComponent).length > 0 ||
                 this.props.formErrors.dossierContactComponent && Object.keys(this.props.formErrors.dossierContactComponent).length > 0) {
        this.props.actions.setScrollTo(buttonsSidebar.entity)
        this.props.actions.setActiveSection(buttonsSidebar.entity)
        if (this.props.formErrors.entityComponent && !Object.keys(this.props.formErrors.entityComponent).length > 0 || !compareObjectsWithStringify(this.props.formErrors.dossierContactComponent, {}) || !compareObjectsWithStringify(this.props.formErrors.entityChildComponent, {})) {
          this.props.actions.openErrorMessageModal('DOSSIER_COMPONENTS.VALIDATIONS.FINAL_CLIENT_OR_CONTACT_MANDATORY')
          skipGeneralMsg = true
        }
      } else if (this.props.formErrors.vehicleComponent && Object.keys(this.props.formErrors.vehicleComponent).length > 0) {
        this.props.actions.setScrollTo(buttonsSidebar.vehicle)
        this.props.actions.setActiveSection(buttonsSidebar.vehicle)
      }
      if (this.props.isInvalid && !skipGeneralMsg) {
        this.props.actions.openErrorMessageModal('DOSSIER_COMPONENTS.VALIDATIONS.GENERAL_MANDATORY')
      }
    }
    this.props.handleSubmit((values) => this.submitCampaignDossier(values))()
  }

  handleToUpdateComponent (component) {
    if (component.isVehicleLoaded === false || buttonsSidebar.vehicle === component) this.setState({ isVehicleLoaded: true })
    if (component.isClientLoaded === false || buttonsSidebar.entity === component) this.setState({ isClientLoaded: true })
    if (component.isEconomicLoaded === false) this.setState({ isEconomicLoaded: true })
    this.props.actions.setScrollTo(component)
  }

  updateComponentAfterDeleteAndRestart () {
    this.setState({
      isClientLoaded: false,
      isVehicleLoaded: false,
      isEconomicLoaded: false
    })
    this.props.actions.setActiveSection(buttonsSidebar.bpurchase)
    this.props.actions.setScrollTo(null)
  }

  render () {
    const {
      t, /* handleSubmit, */ combos, dossier, change, pristine, vehicleProps, activeTab, buttonsHeaderHeight, activeSection, statusHeaderHeight, windowWidth, /* isInvalid, formErrors, */ reset, formValues, reason, comment, dispatch,
      dossier: {
        dossierId, header, checkListHeader, comments, dossierParentId, unreadComments,
        viewConfiguration, viewConfiguration: { buttonsPermission = [], campaignConfiguration, saleConfiguration },
        vehicleComponent: { vehicleId }, entityChangeable, dossierRelatedDossiers, auditInfo,
        dossierSubTypeId, isMarked
      },
      actions: {
        updateComment, createComment, deleteComment, fetchClientByDni, searchVehicle, setSearchDealerNumber, fetchClientByDniEntityChild,
        setSearchVinChassis, setSearchLicensePlate, setSimpleInputTextValidation,
        fetchEntitySubTypeCombo, fetchEntityFilterSubTypeCombo, campaignSend, campaignApprove, campaignReject, putCancelDossier, campaignGenDelivery,
        openDocumentModal, openDocumentEditionModal, fetchClientFromDocument, storeDossier, resetSearchFields,
        setActiveTab, setButtonsHeaderHeight, resetButtonsHeader, setActiveSection, /* setScrollTo, navigateTo, openErrorMessageModal, */ fetchChecklistCampaign, setScrollerOptions,
        setEntityTypeId, setEntitySubTypeId, setEntityId, clearVehicle, fetchReasons, setEntityChildSubTypeId, setEntityChildTypeId,
        fetchVoCompaniesCombo, submitSelectVoCompany, clearEntity, clearEntityChild, refreshAuditInfo, fetchCampaignClient, fetchCampaignVehicle, fetchAuditDetails, fetchAuditDetailsModal, fetchDocumentsGroupedAuditDetails, fetchDocumentsGroupedAuditDetailsModal,
        fetchLowestOrganizedUnits, fecthOrganizedUnitsByDossierUo, setEntityValidationDisable,
        getChangeUo,
        getSalesmanChangeUo,
        postUpdateChangeUo, anulateCampaign, fecthFamily, openCommonModal, fetchAndOpenReplacePurchaseModal, fetchCampaignDetails, markCommentAsRead, fetchCampaignActive,
        campaignvalidate, campaignDenie, campaignReturn, clearClientFields, fetchCancelCombo, fetchClientByRfc,
        foreignPassportWarning, updateClientNoEmployee,
        checkPrivateRfcFormat, fetchEntityCountryTypeCombo, fetchEntityStateTypeCombo,
        fetchEntityCityTypeCombo, fetchEntityTownTypeCombo,
        fetchEntityColonyTypeCombo, fetchEntityCPTypeCombo, fetchCommercialSocietyTypeCombo, fetchActivityBpro,
        fetchClientByPhoneEmail, validate, stopAsyncValidation,
        fetchClientDataAddress
      }
    } = this.props

    if (!dossierId) return null
    const tKey = 'DOSSIER_COMPONENTS.'
    const saveCampaignPermission = buttonsPermission.includes('campaign_save')
    const activeStage = checkListHeader.find(stage => stage.isActive)
    const panelsPaddingTop = statusHeaderHeight + buttonsHeaderHeight - 82 // 82px es el paddingTop definido en el layout.
    const showCampaignPercentages = campaignConfiguration?.showCampaignPercentages ?? false
    const showEconomicPlan = saleConfiguration?.showEconomicPlan ?? false
    return (
      <div className="dossier-campaign-container">
        <ReassignReplacePurchaseModalPage
          showCreatePurchaseButton={false}
          isVo={false}
          organizedUnitId={dossier.organizedUnitId}
          makerNumber={this.props && this.props.dossier && this.props.dossier.order && this.props.dossier.order.makerNumber}
          openCommonModal={openCommonModal}
          purchaseTypeId={this.props && this.props.dossier && this.props.dossier.order && this.props.dossier.order.purchaseTypeId}
          componentConfiguration={this.state.componentsConfiguration.bcampaign}
        />
        <DocumentaryManagementModalPage
          showRelatedDocuments={false}
          dossier={dossier}
          dossierType={dossierTypesConstant.campaign}
          readOnlyFields={isMarked}
        />
        <RejectCommentModal
          t={t}
          showModal={this.state.showRejectCommentModal}
          dossierSubTypeId={dossierSubTypeId}
          reasonCombo={this.props.combos.reasonCombo}
          actions={{
            rejectAction: (comment, resolve, reject) => campaignReject(comment, resolve, reject, pristine),
            closeModal: () => this.toggleRejectCommentModal(false),
            fetchReasons: fetchReasons
          }}
        />
        <RejectCommentModal
          t={t}
          showModal={this.state.showDenyCommentModal}
          dossierSubTypeId={dossierSubTypeId}
          reasonCombo={this.props.combos.reasonCombo}
          title ="TITLE_DENY"
          placeHolder= "DENY_REASON"
          buttonText= "DENY_BUTTON"
          commentValidation="DENY_COMMENT_MANDATORY"
          actions={{
            rejectAction: (comment, resolve, reject) => campaignDenie(null, comment, resolve, reject),
            closeModal: () => this.toggleDenyCommentModal(false),
            fetchReasons: fetchReasons
          }}
        />
        <Col sm={12} className="add-dossier-wrapper dossier">
          <StatusHeaderPage
            headerData={header}
            stages={checkListHeader}
            dossierType={dossierTypesConstant.campaign}
            dossierId={dossier.dossierId}
            activeTab={activeTab}
            headerTabConfiguration={this.state.headerTabConfiguration}
            pristine={pristine}
            goBack={this.goBack}
            readOnlyFields={isMarked}
            editableDoc={viewConfiguration ? getDocumentaryPermission(viewConfiguration) : true}
          />
        </Col>
        <form autoComplete="off">
          <div className="row-header-buttons">
            <div className="buttons-header-box">
              <ButtonsHeader
                t={t}
                pristine={pristine}
                reset={reset}
                combos={ combos.purchaseTypeCombo }
                lowestOrganizedUnitsCombo = {combos.organizedUnitsLegalCif}
                cancelCombo={combos.cancelCombo}
                reason={reason}
                comment={comment}
                actions={{
                  storeDossier,
                  campaignReject: () => this.toggleRejectCommentModal(true),
                  campaignSend,
                  campaignApprove,
                  campaignCancel: () => putCancelDossier(dossierId, pristine, reset),
                  campaignGenDelivery,
                  openDocumentModal: () => openDocumentModal(dossierId, dossierTypesConstant.campaign, pristine),
                  setActiveTab,
                  setButtonsHeaderHeight,
                  resetButtonsHeader,
                  setScrollerOptions,
                  fetchVoCompaniesCombo,
                  submitSelectVoCompany,
                  submitForm: this.handleSubmitForm.bind(this),
                  fetchLowestOrganizedUnits,
                  getChangeUo,
                  getSalesmanChangeUo,
                  postUpdateChangeUo,
                  fecthOrganizedUnitsByDossierUo,
                  anulateCampaign,
                  fetchAndOpenReplacePurchaseModal,
                  campaignvalidate,
                  campaignDenie: () => this.toggleDenyCommentModal(true),
                  campaignReturn,
                  putCancelDossier,
                  fetchCancelCombo: fetchCancelCombo
                }}
                viewConfiguration={viewConfiguration}
                dossierId={this.props.match.params.dossierId}
                dossierType={dossierTypesConstant.campaign}
                activeTab={activeTab}
                marginTop={statusHeaderHeight}
                windowWidth={windowWidth}
                commentUnreadCount={ unreadComments || comments.filter((comment) => { return !comment.read }).length}
                changeUo = {this.props.changeUo}
                organizedUnitId={dossier.organizedUnitId}
                readOnlyFields={isMarked}
                type={dossierTypesConstant.campaign}
              />
            </div>
          </div>
          {
            activeTab === buttonsHeader.datos_expediente && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden && (
              <Row className="sales-panel dossier-panel">
                <Col sm={3} className="col-row expediente-menu-izq">
                  <ButtonsSidebar
                    t={t}
                    historical={false}
                    handleToUpdateComponent = {this.handleToUpdateComponent.bind(this)}
                    isVehicleLoaded = {this.state.isVehicleLoaded}
                    isClientLoaded = {this.state.isClientLoaded}
                    actions={{ setActiveSection, fetchCampaignClient, fetchCampaignVehicle }}
                    dossierType={dossierTypesConstant.campaign}
                    dossierSubTypes={dossierSubTypeId}
                    activeStage={activeStage}
                    marginTop={statusHeaderHeight}
                    buttonsHeaderHeight={buttonsHeaderHeight}
                    tabConfiguration={this.state.activeTabConfiguration}
                    dossierId={dossierId}
                    dossier={dossier}
                    pristine={pristine}
                  />
                </Col>
                <Col sm={9} className="col-row contenido-paneles-expediente">
                  <Col className="panels-wrapper dossier fixed" style={{ paddingTop: panelsPaddingTop }}>
                    {this.state.componentsConfiguration.bcampaign && this.state.componentsConfiguration.bcampaign.permission > permissions.hidden && (
                      <Element name={buttonsSidebar.bcampaign}>
                        <CampaignComponent
                          t={t}
                          dossier={dossier}
                          campaignOpen={this.state.campaignOpen}
                          combos={combos}
                          actions={{
                            change,
                            fetchChecklistCampaign,
                            togglePanel: () => this.togglePanel(),
                            savePercentages: (value) => this.savePercentages(value),
                            fetchCampaignDetails,
                            fetchCampaignActive,
                            setActiveSection
                          }}
                          readOnlyFields={!saveCampaignPermission || isMarked}
                          componentConfiguration={this.state.componentsConfiguration.bcampaign}
                          change={change}
                          totalAmountWithOptionals={this.props.dossier.totalAmountWithOptionals}
                          totalAmountWithOptionalsPurchase={this.props.dossier.totalAmountWithOptionalsPurchase}
                          customFields={this.props.dossier.customFieldsByComponent.find(x => x.componentCode === buttonsSidebar.bcampaign)?.customFields}
                          showPercentages = {showCampaignPercentages && showEconomicPlan}
                        />
                      </Element>
                    )}
                    {this.state.componentsConfiguration.entity && this.state.componentsConfiguration.entity.permission > permissions.hidden && (
                      <Element name={buttonsSidebar.entity}>
                        <Client
                          t={t}
                          dossier={dossier}
                          combos={combos}
                          entityChangeable={entityChangeable}
                          dossierType={dossierTypesConstant.campaign}
                          actions={{
                            change,
                            fetchClientByDni,
                            fetchClientByDniEntityChild,
                            fetchEntitySubTypeCombo,
                            fetchEntityFilterSubTypeCombo,
                            openDocumentEditionModal,
                            fetchClientFromDocument,
                            setEntityTypeId,
                            setEntitySubTypeId,
                            setEntityId,
                            setEntityChildSubTypeId,
                            setEntityChildTypeId,
                            clearEntity,
                            clearEntityChild,
                            fetchCampaignClient,
                            setEntityValidationDisable,
                            setActiveSection,
                            clearClientFields,
                            fetchClientByRfc,
                            foreignPassportWarning,
                            updateClientNoEmployee,
                            checkPrivateRfcFormat,
                            fetchEntityCountryTypeCombo,
                            fetchEntityStateTypeCombo,
                            fetchEntityCityTypeCombo,
                            fetchEntityTownTypeCombo,
                            fetchEntityColonyTypeCombo,
                            fetchEntityCPTypeCombo,
                            openCommonModal,
                            fetchCommercialSocietyTypeCombo,
                            fetchClientByPhoneEmail,
                            fetchActivityBpro,
                            validate,
                            dispatch,
                            asyncValidate,
                            stopAsyncValidation: (value) => stopAsyncValidation('campaign_dossier', value),
                            fetchClientDataAddress
                          }}
                          readOnlyFields={!saveCampaignPermission || isMarked}
                          handleToUpdateComponent= {this.handleToUpdateComponent.bind(this)}
                          isClientLoaded = {this.state.isClientLoaded}
                          pristine={pristine}
                          open={activeSection === buttonsSidebar.entity}
                          componentConfiguration={this.state.componentsConfiguration.entity}
                          updateComponentAfterDeleteAndRestart={this.updateComponentAfterDeleteAndRestart.bind(this)}
                          change={change}
                          customFields={this.props.dossier.customFieldsByComponent.find(x => x.componentCode === buttonsSidebar.entity)?.customFields}
                          formValues={formValues}
                        />
                      </Element>
                    )}
                    {this.state.componentsConfiguration.vehicle && this.state.componentsConfiguration.vehicle.permission > permissions.hidden && (
                      <Element name={buttonsSidebar.vehicle} style={settings.clientFieldValidation === clientInformation.PORSCHE ? { height: '350px' } : { height: 'auto' }}>
                        <Vehicle
                          handleToUpdateComponent = {this.handleToUpdateComponent.bind(this)}
                          isVehicleLoaded = {this.state.isVehicleLoaded}
                          pristine={pristine}
                          dossierType={dossierTypesConstant.campaign}
                          parentDossierType={{
                            isPaperwork: false,
                            isSales: false,
                            isPurchase: false,
                            isCampaign: true,
                            isRegistrationDelivery: false
                          }}
                          t={t}
                          dossierId={dossierId}
                          dossierSubType={dossierSubTypeId}
                          searchedVehicleProps={vehicleProps}
                          dossierParentId={dossierParentId}
                          dossierWithVehicle={!!vehicleId}
                          combos={combos}
                          actions={{
                            searchVehicle,
                            setSearchDealerNumber,
                            setSearchVinChassis,
                            setSearchLicensePlate,
                            resetSearchFields,
                            setSimpleInputTextValidation,
                            clearVehicle,
                            fetchCampaignVehicle,
                            fecthFamily,
                            setActiveSection
                          }}
                          readOnlyFields={!saveCampaignPermission || isMarked}
                          open={activeSection === buttonsSidebar.vehicle}
                          formValues={formValues ? formValues.vehicleComponent : {}}
                          componentConfiguration={this.state.componentsConfiguration.vehicle}
                          organizedUnitId={dossier.organizedUnitId}
                          customFields={this.props.dossier.customFieldsByComponent.find(x => x.componentCode === buttonsSidebar.vehicle)?.customFields}
                        />
                      </Element>
                    )}
                  </Col>
                </Col>
              </Row>
            )}
          {
            activeTab === buttonsHeader.gestor_documental && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden && (
              <Row className="sales-panel dossier-panel">
                <Col sm={12} className="col-row" style={{ paddingTop: panelsPaddingTop }}>
                  <DocumentaryManagementPage
                    dossier={dossier}
                    dossierType={dossierTypesConstant.campaign}
                    formState={pristine}
                    configuration={this.state.activeTabConfiguration}
                    readOnlyFields={isMarked}
                  />
                </Col>
              </Row>
            )}
          {
            activeTab === buttonsHeader.expedientes_relacionados && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden && (
              <Row className="sales-panel dossier-panel panel-expedientes-relacionados">
                <Col sm={12} className="col-row" style={{ paddingTop: panelsPaddingTop }}>
                  <Col sm={6} className="col-row">
                    <h4><i className="ico-relacion"/> {t(`${tKey}RELATED_DOSSIER.RELATED_DOSSIER`)}</h4>
                  </Col>
                  <Col sm={12} className="col-row">
                    <Table hover responsive className="simple-table dashboard-tasks-table" id="related-dossiers-table">
                      <thead>
                        <tr>
                          <th onClick={this.onClickRelatedDossierColumn.bind(this, 'urgencyType')}>
                            <i className={this.getRelatedDossierColumnIcon('urgencyType')}/>
                          </th>
                          <th onClick={this.onClickRelatedDossierColumn.bind(this, 'number')}>
                            {t(`${tKey}RELATED_DOSSIER.DOSSIER_NUMBER`)} <i className={this.getRelatedDossierColumnIcon('number')}/>
                          </th>
                          <th onClick={this.onClickRelatedDossierColumn.bind(this, 'organizedUnit')}>
                            {t(`${tKey}RELATED_DOSSIER.UO`)} <i className={this.getRelatedDossierColumnIcon('organizedUnit')}/>
                          </th>
                          <th onClick={this.onClickRelatedDossierColumn.bind(this, 'dossierSubType')}>
                            {t(`${tKey}RELATED_DOSSIER.SUBTYPE`)} <i className={this.getRelatedDossierColumnIcon('dossierSubType')}/>
                          </th>
                          <th onClick={this.onClickRelatedDossierColumn.bind(this, 'stage')}>
                            {t(`${tKey}RELATED_DOSSIER.STATE`)} <i className={this.getRelatedDossierColumnIcon('stage')}/>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <RelatedDossierList history={this.props.history} dossierRelatedDossiers={dossierRelatedDossiers} />
                      </tbody>
                    </Table>
                  </Col>
                </Col>
              </Row>
            )}
          {
            activeTab === buttonsHeader.actividad_expediente && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden && (
              <Row className="sales-panel dossier-panel panel-actividad-expediente">
                <Col sm={12} className="col-row" style={{ paddingTop: panelsPaddingTop }}>
                  <AuditInfo
                    auditInfo={auditInfo}
                    dossierType={dossierTypesConstant.sales}
                    actions={{ refreshAuditInfo, fetchAuditDetails, fetchAuditDetailsModal, fetchDocumentsGroupedAuditDetails, fetchDocumentsGroupedAuditDetailsModal }}
                    dossier={dossier}
                    t={t}
                  />
                </Col>
              </Row>
            )
          }
          {
            activeTab === buttonsHeader.observaciones && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden && (
              <Row className="sales-panel dossier-panel panel-observaciones-expediente">
                <Col sm={12} className="col-row" style={{ paddingTop: panelsPaddingTop }}>
                  <Col sm={6} className="col-row">
                    <h4><i className="ico-bocadillo" onClick={() => { this.setState({ modalOpen: !this.state.modalOpen }) }}/> {t(`${tKey}COMMENTS.TITLE_PANEL`)}</h4>
                  </Col>
                  <Col sm={12} className="col-row">
                    <CommentComponent
                      comments={comments}
                      actions={{ updateComment, createComment, deleteComment, markCommentAsRead }}
                      readOnlyFields={!saveCampaignPermission || isMarked}
                      dossierType="campaignDossier"
                      t={t}
                      tKey={tKey}
                      combos={{ allReasonCombo: this.props.combos.allReasonCombo }}
                      openComponent={activeTab === buttonsHeader.observaciones}
                      dossierId={this.props.dossier.dossierId}
                    />
                  </Col>
                </Col>
              </Row>
            )}
        </form>
      </div>
    )
  }
}

const validate = (values, props) => {
  const error = {}
  if (values.dossierCampaignComponent) {
    if (values.dossierCampaignComponent.cost) {
      const auxError = validateInteger(values.dossierCampaignComponent.cost, props)
      if (Object.keys(auxError).length > 0) {
        error.dossierCampaignComponent = { cost: auxError }
      }
    }
    if (values.dossierCampaignComponent.dossierCampaignDetailComponent) {
      let parsedApprovedAmount = parseFloat(values.dossierCampaignComponent.dossierCampaignDetailComponent.approvedAmount || 0.0)
      let parsedRequestAmount = parseFloat(values.dossierCampaignComponent.dossierCampaignDetailComponent.amountRequested || 0.0)
      if (!error.dossierCampaignComponent) {
        error.dossierCampaignComponent = { dossierCampaignDetailComponent: {} }
      }
      if (!error.dossierCampaignComponent.dossierCampaignDetailComponent) {
        error.dossierCampaignComponent.dossierCampaignDetailComponent = {}
      }
      if (parsedApprovedAmount > parsedRequestAmount) {
        error.dossierCampaignComponent.dossierCampaignDetailComponent.approvedAmount = props.t('DOSSIER_COMPONENTS.VALIDATIONS.AMOUNT_BRAND_BIGGER_THAN_AMOUNT_CAMPAIGN')
      }
      if (parsedApprovedAmount < 0) {
        error.dossierCampaignComponent.dossierCampaignDetailComponent.approvedAmount = props.t('DOSSIER_COMPONENTS.VALIDATIONS.AMOUNT_CANNOT_BE_LESS_THAN_ZERO')
      }
      if (parsedRequestAmount < 0) {
        error.dossierCampaignComponent.dossierCampaignDetailComponent.amountRequested = props.t('DOSSIER_COMPONENTS.VALIDATIONS.AMOUNT_CANNOT_BE_LESS_THAN_ZERO')
      }
      error.dossierCampaignComponent = { ...error.dossierCampaignComponent, dossierCampaignDetailComponent: {} }
      error.dossierCampaignComponent.dossierCampaignDetailComponent = validatePercentages(values.dossierCampaignComponent, props)
    }
  }
  if (values.vehicleComponent) {
    error.vehicleComponent = validateVehicle(values.vehicleComponent, props)
  }
  if (values.entityComponent && values.entityComponent.updated && Object.keys(values.entityComponent).length > 1) {
    error.entityComponent = validateEntity(values.entityComponent, props)

    if (values.entityComponent.entityTypeId && values.entityComponent.entityTypeId === entityTypesId.recurrentEntity) {
      if (values.entityChildComponent && Object.keys(values.entityChildComponent).length > 0 && values.entityChildComponent.entityTypeId !== undefined) {
        error.entityChildComponent = validateEntity(values.entityChildComponent, props, null, true)
      }
      if (values.dossierContactComponent && Object.keys(values.dossierContactComponent).length > 1) {
        error.dossierContactComponent = validateContact(values.dossierContactComponent, props)
      }
    }
  }

  error.customFields = {}
  if (values.customFields && Object.keys(values.customFields).length > 0 && props.dossier.customFieldsByComponent) {
    error.customFields = {}
    let resultCustomFields = {}
    let allCustomFields = []
    props.dossier.customFieldsByComponent.map(x => x.customFields).map(x => {
      allCustomFields = allCustomFields.concat(x)
    })
    Object.keys(values.customFields).forEach(key => {
      const foundCustomField = allCustomFields.find(x => x.code === key)
      if (foundCustomField && foundCustomField.validationFunction) {
        if (foundCustomField.validationFunction && foundCustomField.validationFunction.indexOf('regEx-') !== -1) {
          resultCustomFields = validateRegex(foundCustomField.validationFunction, foundCustomField.customFieldLocales[0].name, values.customFields[key], props)
        } else {
          resultCustomFields = customValidations(foundCustomField.validationFunction, values.customFields[key], props)
        }
        error.customFields[key] = resultCustomFields === Object(resultCustomFields) ? undefined : resultCustomFields
      }
    })
  }

  return error
}

const asyncValidate = (values, dispatch, props, field, returnError) => {
  let promises = []
  if (values) {
    promises = promises.concat(asyncValidateEntityDni(values.entityComponent, dispatch, props, props.dossier?.organizedUnitId))
    promises = promises.concat(asyncValidateEntityDni(values.entityChildComponent, dispatch, props, props.dossier?.organizedUnitId))
  }

  return Promise.allSettled(promises).then(result => {
    let error = {}
    for (let index = 0; index < result.length; index++) {
      const element = result[index]
      if (element.status === promisesStatus.FULFILLED) {
        switch (index) {
          case validationCampaignPromisesOrder.ENTITY_COMPONENT:
            error = { ...error, entityComponent: element.value }
            break
          case validationCampaignPromisesOrder.ENTITY_CHILD_COMPONENT:
            error = { ...error, entityChildComponent: element.value }
            break
          default:
            break
        }
      }
    }
    if (Object.keys(error).length > 0 && !returnError) throw Object.assign(new Error(), error)
    if (returnError) return error
  })
}

var decoratedComponent = TrackingContainer(Campaign, false)

export default reduxForm({
  form: 'campaign_dossier',
  validate,
  asyncValidate,
  asyncBlurFields: [...getFieldsValidated('entityComponent'), ...getFieldsValidated('entityChildComponent')]
})(decoratedComponent)
