import { put, call, select, takeEvery } from 'redux-saga/effects'
import { getAuth } from '../../../selectors/access/auth'
import { initialize, reset } from 'redux-form'
import { yesNoModal } from '../../modalsListeners/yesNoModal'
import dossierActionsTypes from '../../../constants/actions/dossier/dossier'
import { entityRequiredFields } from '../../../constants/validatedFields/entity'
import { dossierTypes as dossierTypesConstant } from '../../../constants/dossier/common/dossierTypes'
import { fetchPaperworkDossierSuccess } from '../../../actions/dossier/paperwork'
import getEntityFilterSubType from '../../../services/entity/getEntityFilterSubType'
import putCampaignDossierService from '../../../services/campaigns/putDossierCampaign'
import { showLoader, hideLoader } from '../../../actions/common'
import messageModalActions from '../../../actions/modals/messageModal'
import { fetchCombosSuccess } from '../../../actions/combos/combos'
import { handleError } from '../../errors/errorManager'
import { initializeDossier } from '../commonDossierSagas'

export function * saveCampaignDossier ({ campaignDossier }) {
  try {
    if (campaignDossier.dossierCampaignComponent.active === false) {
      yield call(yesNoModal, 'campaignNotActive')
    } else {
      const auth = yield select(getAuth)
      const campaignDossierState = yield select(state => state.campaignDossier)
      const myEntityRequiredFields = entityRequiredFields(campaignDossier.entityComponent ? campaignDossier.entityComponent.entityTypeId : null)
      let willBeAnyImportantFieldUpdated = false
      let confirmedSaveWarning = true

      for (const prop in myEntityRequiredFields) {
        if (campaignDossierState.entityComponent.entityId && campaignDossier.entityComponent[prop] !== campaignDossierState.entityComponent[prop]) {
          willBeAnyImportantFieldUpdated = true
          break
        }
      }

      if (willBeAnyImportantFieldUpdated || (campaignDossierState.entityComponent && campaignDossierState.entityComponent.previousEntity && campaignDossierState.entityComponent.previousEntity.entityId &&
        campaignDossier.entityComponent && campaignDossier.entityComponent.entityId && campaignDossier.entityComponent.entityId !== campaignDossierState.entityComponent.previousEntity.entityId)) {
        confirmedSaveWarning = yield call(yesNoModal, 'commonEntitySaveWarning')
      }
      if (confirmedSaveWarning) {
        yield put(showLoader())
        const subDossier = {
          dossierCampaignComponent: campaignDossier.dossierCampaignComponent,
          dossierEntityComponent: JSON.stringify(campaignDossier.entityComponent) === '{}' ? null : campaignDossier.entityComponent,
          dossierEntityChildComponent: JSON.stringify(campaignDossier.entityChildComponent) === '{}' ? null : campaignDossier.entityChildComponent,
          dossierVehicleComponent: JSON.stringify(campaignDossier.vehicleComponent) === '{}' ? null : campaignDossier.vehicleComponent,
          dossierParentId: campaignDossier.dossierParentId,
          dossierKm: campaignDossier.dossierKm,
          parentIsHistorical: campaignDossier.parentIsHistorical,
          dossierContactComponent: campaignDossier.dossierContactComponent,
          customFields: campaignDossier?.customFields ? Object.keys(campaignDossier.customFields).map(x => { return { key: x, value: campaignDossier.customFields[x] } }) : []
        }
        if (campaignDossier?.customFields) {
          campaignDossierState.customFields = {}
          Object.keys(campaignDossier.customFields).map(x => {
            campaignDossierState.customFields[x] = campaignDossier.customFields[x]
          })
        }
        yield call(putCampaignDossierService, campaignDossier.dossierId, subDossier, auth.token, campaignDossierState.lastAccessDate)
        yield put(messageModalActions.openSuccessMessageModal('MESSAGES.PUT_DOSSIER_SUCCESS'))
        yield call(initializeDossier, dossierTypesConstant.campaign, campaignDossier)
      } else {
        if (campaignDossierState.entityComponent.previousEntity.entitySubTypeId) {
          const entityFilterSubTypeCombo = yield call(getEntityFilterSubType, auth.token, campaignDossierState.entityComponent.previousEntity.entityTypeId, campaignDossierState.entityComponent.previousEntity.entitySubTypeId, campaignDossierState.organizedUnitId)
          yield put(fetchCombosSuccess({ entityFilterSubTypeCombo }))
        }
        if (campaignDossier?.customFields) {
          campaignDossierState.customFields = {}
          Object.keys(campaignDossier.customFields).map(x => {
            campaignDossierState.customFields[x] = campaignDossier.customFields[x]
          })
        }
        yield put(fetchPaperworkDossierSuccess({ entityComponent: { ...campaignDossierState.entityComponent.previousEntity, previousEntity: campaignDossierState.entityComponent.previousEntity } }))
        yield put(initialize('campaign_dossier', { ...campaignDossier, entityComponent: campaignDossierState.entityComponent.previousEntity, customFields: campaignDossierState.customFields }))
      }
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          yield call(initializeDossier, dossierTypesConstant.campaign, campaignDossier)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('campaign_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSaveCampaignDossier () {
  yield takeEvery(dossierActionsTypes.SAVE_CAMPAIGN_DOSSIER, saveCampaignDossier)
}
