
import React, { PureComponent } from 'react'
import { Field } from 'redux-form'
import { Row, Clearfix, OverlayTrigger, Button } from 'react-bootstrap'
import InputText from '../commons/form/InputText'
import InputSelect from '../commons/form/InputSelect'
import InputDatePicker from '../commons/form/InputDatePicker'
import { validateDate } from '../../util/validationFunctions'
import { multipleNormalize, toUpperCase, trimAll } from '../../util/normalizeFunctions'
import SimpleTablePage from '../../containers/commons/general/SimpleTablePage'
import DossierListPopover from './DossierListPopover'
import IDocCarPagination from '../commons/iDocCarPagination'
import { permissions, clientInformation } from '../../constants/backendIds'
import vehicleCondition from '../../constants/vehicleCondition'
import { getFieldsSectionPermissions } from '../../util/permisionFunctions'
import settings from '../../setting'
import AdditionalSearchFieldsByVehicleTypeOperation from './vehicleSearch/AdditionalSearchFieldsByVehicleTypeOperation'

class SearchByVehicleForm extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      fieldsConfiguration: {
        findVehicleBydealerNumber: null,
        carline: null,
        catalogue: null,
        model: null,
        externalColor: null,
        internalColor: null,
        vehicleCondition: null
      },
      firstLicensePlateDateFromLimit: {
        min: null,
        max: null
      },
      firstLicensePlateDateToLimit: {
        min: null,
        max: null
      }
    }
    this.onBrandIdChanges = this.onBrandIdChanges.bind(this)
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (Object.keys(prevProps.fields).length > 0 && Object.keys(this.props.fields).length === 0) {
      this.setState({ firstLicensePlateDateFromLimit: { min: null, max: null }, firstLicensePlateDateToLimit: { min: null, max: null } })
    }
    return null
  }

  componentDidUpdate () {
    // React life cycle
  }

  validateDate (value, allValues, props) {
    const result = validateDate(value, props)
    return result === Object(result) ? undefined : result
  }

  exportData () {
    const tKey = 'SEARCH.CATEGORIES.VEHICLE.'
    let columnsExcel = []
    columnsExcel.push(this.props.t(`${tKey}BRAND`) + '#Brand')
    this.state.fieldsConfiguration.carline && this.state.fieldsConfiguration.carline.permission > permissions.hidden && columnsExcel.push(this.props.t(`${tKey}CARLINE`) + '#CarlineDescription')
    this.state.fieldsConfiguration.catalogue && this.state.fieldsConfiguration.catalogue.permission > permissions.hidden && columnsExcel.push(this.props.t(`${tKey}CATALOGUE`) + '#CatalogueDescription')
    this.state.fieldsConfiguration.model && this.state.fieldsConfiguration.model.permission > permissions.hidden && columnsExcel.push(this.props.t(`${tKey}MODEL_YEAR`) + '#ModelDescription')
    columnsExcel.push(this.props.t(`${tKey}MODEL`) + '#Model')
    columnsExcel.push(this.props.t(`${tKey}CHASSIS`) + '#VinChassis')
    columnsExcel.push(this.props.t(`${tKey}LICENSE_PLATE`) + '#LicensePlate')
    settings.clientFieldValidation !== clientInformation.BUSS && columnsExcel.push(this.props.t(`${tKey}COMMERCIAL_CODE`) + '#ComercialCode')
    columnsExcel.push(this.props.t(`${tKey}FIRST_LPLATE_DATE_FROM`) + '#LicenseDate')
    if (this.state.fieldsConfiguration.findVehicleBydealerNumber > permissions.hidden) {
      columnsExcel.push(this.props.t(`${tKey}DEALER_NUMBER`) + '#DealerNumber')
    }
    columnsExcel.push(this.props.t(`${tKey}AMOUNT_RELATED_DOSSIERS`) + '#LivesStoreds')
    columnsExcel.push(this.props.t(`${tKey}STATE`) + '#Active')
    this.props.exportToExcel(columnsExcel)
  }

  componentDidMount () {
    this.props.actions.fetchBrands()
    this.props.actions.fetchModelCombo()

    let permissionFindVehicleBydealerNumber = {
      findVehicleBydealerNumber: null
    }

    if (this.props.permissions && this.props.permissions.findVehicleBydealerNumber) {
      permissionFindVehicleBydealerNumber.findVehicleBydealerNumber = this.props.permissions.findVehicleBydealerNumber
    }

    if (this.props.permissions && this.props.permissions.sectionFieldsConfiguration) {
      const fieldKeys = Object.keys(this.state.fieldsConfiguration)
      const permission = getFieldsSectionPermissions(this.props.permissions.sectionFieldsConfiguration, fieldKeys, permissions.hidden)
      this.setState({ fieldsConfiguration: { ...permission.fieldsConfiguration, ...permissionFindVehicleBydealerNumber } })
    } else {
      this.setState({ fieldsConfiguration: permissionFindVehicleBydealerNumber })
    }
  }

  setDateLimit (fromDate, toDate) {
    const dateSplitted = fromDate ? fromDate.split('/') : toDate ? toDate.split('/') : null
    const dateTransformed = dateSplitted && new Date(+dateSplitted[2], dateSplitted[1] - 1, +dateSplitted[0])
    let newLimit = dateSplitted && new Date(+dateSplitted[2], dateSplitted[1] - 1, +dateSplitted[0])

    if (fromDate) {
      newLimit.setMonth(newLimit.getMonth() + 1)
      this.setState({ firstLicensePlateDateToLimit: { min: dateTransformed.toString(), max: newLimit.toString() } })
    } else if (toDate) {
      newLimit.setMonth(newLimit.getMonth() - 1)
      this.setState({ firstLicensePlateDateFromLimit: { min: newLimit.toString(), max: dateTransformed.toString() } })
    } else {
      if (fromDate === false) {
        this.setState({ firstLicensePlateDateFromLimit: { min: null, max: null } })
      } else if (toDate === false) {
        this.setState({ firstLicensePlateDateToLimit: { min: null, max: null } })
      }
    }
  }

  onBrandIdChanges (brandId) {
    this.props.actions.resetCarlineCombo()
    this.props.actions.resetCatalogueCombo()
    this.props.actions.resetColorCombo()
    this.props.actions.resetTapestryCombo()
    if (brandId) {
      this.props.actions.fetchCarlineCombo(brandId)
    }
  }

  render () {
    const {
      t, combos: { brandCombo }, fields, fieldFill, className, classNameSelect, hasSearch, vehicleData, stateTooltip, activeTooltip,
      inActiveTooltip, getIconClass, selectedFilters, onClickResetSearch, pages, page, count, onPageChange, onOrderChange
    } = this.props
    const tKey = 'SEARCH.CATEGORIES.VEHICLE.'
    return (
      <div>
        {!hasSearch ? (
          <Row>
            {this.state.fieldsConfiguration.vehicleCondition && this.state.fieldsConfiguration.vehicleCondition.permission > permissions.hidden && <Field
              colSm={3}
              id="vehicleCondition"
              name='vehicle.vehicleCondition'
              placeholder={t(`${tKey}VEHICLE_CONDITION`)}
              controlLabel={t(`${tKey}VEHICLE_CONDITION`)}
              component={InputSelect}
              options={[{ id: vehicleCondition.NEW, value: t(`${tKey}VEHICLE_CONDITION_NEW`) }, { id: vehicleCondition.NEARLY_NEW, value: t(`${tKey}VEHICLE_CONDITION_NEARLY_NEW`) }]}
              valueKey="id"
              labelKey="value"
              onInputChange={(val) => { fieldFill({ vehicleCondition: val }) }}
              customClass={fields.brand ? classNameSelect : ''}
            />}
            <Field
              colSm={3}
              id="brand"
              name='vehicle.brand'
              placeholder={t(`${tKey}BRAND`)}
              controlLabel={t(`${tKey}BRAND`)}
              component={InputSelect}
              options={brandCombo}
              valueKey="id"
              labelKey="value"
              onInputChange={(val) => { fieldFill({ brand: val }); this.onBrandIdChanges(val) }}
              customClass={fields.brand ? classNameSelect : ''}
            />
            <Field
              colSm={3}
              id="modelVehicle"
              name='vehicle.model'
              placeholder={t(`${tKey}MODEL`)}
              controlLabel={t(`${tKey}MODEL`)}
              component={InputText}
              type="text"
              onInputChange={(val) => fieldFill({ model: val })}
              customClass={fields.model ? className : ''}
            />
            <Field
              colSm={3}
              id="chassisVehicle"
              name='vehicle.chassis'
              placeholder={t(`${tKey}CHASSIS`)}
              controlLabel={t(`${tKey}CHASSIS`)}
              component={InputText}
              type="text"
              maxLength={17}
              normalize={value => multipleNormalize(value, [toUpperCase, trimAll])}
              onInputChange={(val) => fieldFill({ chassis: val })}
              customClass={fields.chassis ? className : ''}
            />
            <Field
              colSm={3}
              id="licence_plateVehicle"
              name='vehicle.licensePlate'
              placeholder={t(`${tKey}LICENSE_PLATE`)}
              controlLabel={t(`${tKey}LICENSE_PLATE`)}
              component={InputText}
              type="text"
              normalize={value => multipleNormalize(value, [toUpperCase, trimAll])}
              onInputChange={(val) => fieldFill({ licence_plate: val })}
              customClass={fields.licence_plate ? className : ''}
            />
            <Clearfix />
            <Field
              colSm={3}
              id="commercial_code"
              name='vehicle.commercialCode'
              placeholder={t(`${tKey}COMMERCIAL_CODE`)}
              controlLabel={t(`${tKey}COMMERCIAL_CODE`)}
              component={InputText}
              type="text"
              onInputChange={(val) => fieldFill({ commercial_code: val })}
              customClass={fields.commercial_code ? className : ''}
            />
            <Field
              colSm={3}
              id="first_lplate_date"
              name='vehicle.licensePlateDateFrom'
              placeholder={t(`${tKey}FIRST_LPLATE_DATE_FROM`)}
              controlLabel={t(`${tKey}FIRST_LPLATE_DATE_FROM`)}
              component={InputDatePicker}
              validate={[this.validateDate]}
              onInputChange={
                (val) => {
                  fieldFill({ first_lplate_date: val })
                  this.setDateLimit(val, false)
                }
              }
              customClass={fields.first_lplate_date ? className : ''}
              minDate = {this.state.firstLicensePlateDateFromLimit.min}
              maxDate = {this.state.firstLicensePlateDateFromLimit.max}
            />
            <Field
              colSm={3}
              id="first_lplate_date_to"
              name='vehicle.licensePlateDateTo'
              placeholder={t(`${tKey}FIRST_LPLATE_DATE_TO`)}
              controlLabel={t(`${tKey}FIRST_LPLATE_DATE_TO`)}
              component={InputDatePicker}
              validate={[this.validateDate]}
              onInputChange={
                (val) => {
                  fieldFill({ first_lplate_date_to: val })
                  this.setDateLimit(false, val)
                }
              }
              customClass={fields.first_lplate_date_to ? className : ''}
              minDate = {this.state.firstLicensePlateDateToLimit.min}
              maxDate = {this.state.firstLicensePlateDateToLimit.max}
            />
            {this.state.fieldsConfiguration.findVehicleBydealerNumber > permissions.hidden &&
            <Field
              colSm={3}
              id="dealerNumberSimple"
              name='vehicle.dealerNumber'
              placeholder={t(`${tKey}DEALER_NUMBER`)}
              controlLabel={t(`${tKey}DEALER_NUMBER`)}
              component={InputText}
              type="text"
              onInputChange={(val) => fieldFill({ dealerNumber: val })}
              customClass={fields.dealerNumber ? className : ''}
            />
            }
            <Clearfix />
            <AdditionalSearchFieldsByVehicleTypeOperation {...this.props}></AdditionalSearchFieldsByVehicleTypeOperation>
          </Row>
        ) : ([
          <Row className="filters-selected" key="filters-selected">
            <ul className="list-inline pull-left">
              {selectedFilters().length === 0 ? <li>{t(`${tKey}NO_SELECTED_FILTERS`)}</li> : (selectedFilters())}
            </ul>
            <Clearfix/>
            <Button
              onClick={(event) => onClickResetSearch(event)}
              bsStyle="default"
              bsSize="small"
              className="pull-left"
              id='modSearchButtonIdVehicle'
            >
              {t('SEARCH.ACTIONS.MODIFY_SEARCH')}
              <i className="ico-edit-turquoise" />
            </Button>
            {count > 0 &&
            <Button
              onClick={(event) => this.exportData(event)}
              bsStyle="default"
              bsSize="small"
              id='expSearchButtonIdVehicle'>
              {t('SEARCH.ACTIONS.EXPORT')}
              <i className="ico-if_Download_1031520" />

            </Button>
            }
          </Row>,
          <div className="table-wrapper" key="table-wrapper">
            <SimpleTablePage
              columns={[
                <th key={0} data-field="vehicleBrand" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}BRAND`)}&nbsp;<i className={getIconClass('vehicleBrand')}/>
                </th>,
                (this.state.fieldsConfiguration.carline && this.state.fieldsConfiguration.carline.permission > permissions.hidden &&
                <th key={0} data-field="carlineDescription" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}CARLINE`)}&nbsp;<i className={getIconClass('carlineDescription')}/>
                </th>),
                (this.state.fieldsConfiguration.catalogue && this.state.fieldsConfiguration.catalogue.permission > permissions.hidden &&
                <th key={0} data-field="catalogueDescription" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}CATALOGUE`)}&nbsp;<i className={getIconClass('catalogueDescription')}/>
                </th>),
                (this.state.fieldsConfiguration.model && this.state.fieldsConfiguration.model.permission > permissions.hidden &&
                <th key={0} data-field="modelDescription" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}MODEL_YEAR`)}&nbsp;<i className={getIconClass('modelDescription')}/>
                </th>),
                <th key={1} data-field="vehicleModel" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}MODEL`)}&nbsp;<i className={getIconClass('vehicleModel')}/>
                </th>,
                <th key={2} data-field="vehicleVinChassis" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}CHASSIS`)}&nbsp;<i className={getIconClass('vehicleVinChassis')}/>
                </th>,
                <th key={3} data-field="vehicleLicensePlate" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}LICENSE_PLATE`)}&nbsp;<i className={getIconClass('vehicleLicensePlate')}/>
                </th>,
                (!(this.state.fieldsConfiguration.carline && this.state.fieldsConfiguration.carline.permission > permissions.hidden) &&
                <th key={4} data-field="comercialCode" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}COMMERCIAL_CODE`)}&nbsp;<i className={getIconClass('comercialCode')}/>
                </th>),
                <th key={5} data-field="licensePlateDate" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}FIRST_LPLATE_DATE`)}&nbsp;<i className={getIconClass('licensePlateDate')} />
                </th>,
                (this.state.fieldsConfiguration.findVehicleBydealerNumber && this.state.fieldsConfiguration.findVehicleBydealerNumber.permission > permissions.hidden &&
                <th key={6} data-field="dealerNumber" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}DEALER_NUMBER`)}&nbsp;<i className={getIconClass('dealerNumber')} />
                </th>),
                <th key={7}>{t(`${tKey}AMOUNT_RELATED_DOSSIERS`)}</th>,
                <OverlayTrigger key={8} trigger={['hover', 'focus']} placement="top" overlay={stateTooltip}>
                  <th data-field="active" onClick={(event) => onOrderChange(event)}>
                    {t(`${tKey}STATE`)}&nbsp;<i className={getIconClass('active')}/>
                  </th>
                </OverlayTrigger>
              ]}
              rows={vehicleData.map((row, idx) => (
                <tr key={idx}>
                  <td>{row.vehicleBrand}</td>
                  {this.state.fieldsConfiguration.carline && this.state.fieldsConfiguration.carline.permission > permissions.hidden && <td>{row.carlineDescription}</td>}
                  {this.state.fieldsConfiguration.catalogue && this.state.fieldsConfiguration.catalogue.permission > permissions.hidden && <td>{row.catalogueDescription}</td>}
                  {this.state.fieldsConfiguration.model && this.state.fieldsConfiguration.model.permission > permissions.hidden && <td>{row.modelDescription}</td>}
                  <td>{row.vehicleModel}</td>
                  <td>{row.vehicleVinChassis}</td>
                  <td>{row.vehicleLicensePlate}</td>
                  {!(this.state.fieldsConfiguration.carline && this.state.fieldsConfiguration.carline.permission > permissions.hidden) && <td>{row.comercialCode}</td>}
                  <td>{row.licensePlateDate}</td>
                  {this.state.fieldsConfiguration.findVehicleBydealerNumber && this.state.fieldsConfiguration.findVehicleBydealerNumber.permission > permissions.hidden &&
                   <td>{row.dealerNumber}</td>
                  }
                  <td className="see-more">
                    <DossierListPopover
                      t={t}
                      half={(vehicleData.length || 1) / (idx + 1)}
                      idx={idx}
                      id={'popover-dossier-list' + idx}
                      alive={row.lives}
                      stored={row.stored}
                      subtypesCombo={this.props.subtypesCombo}
                    />
                  </td>
                  <td>
                    {
                      row.active ? (
                        <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={activeTooltip}>
                          <span className={'color-point green'}/>
                        </OverlayTrigger>)
                        : (<OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={inActiveTooltip}>
                          <span className={'color-point red'}/>
                        </OverlayTrigger>)
                    }
                  </td>
                </tr>
              ))}
            />
          </div>,
          <div className="search-footer" key="search-footer">
            <IDocCarPagination
              id="btn_pag_sales"
              page={page}
              pagesCount={pages}
              totalRows= {count}
              onSelectAction={(page) => onPageChange(page)}
            />
          </div>
        ])}
      </div>
    )
  }
}

export default SearchByVehicleForm
