
import React, { PureComponent } from 'react'
import { Row, Button, Modal, Col, ProgressBar } from 'react-bootstrap'
import { reduxForm, Field } from 'redux-form'
import InputSelect from '../commons/form/InputSelect'

class ActionDataModal extends PureComponent {
  constructor () {
    super()
    this.state = {
      saleType: null,
      operationTypes: [],
      paymentMethod: null,
      operationTypeDisabled: true,
      progress: 0,
      progressFail: 0,
      displayBar: 'none'
    }
  }

  componentDidMount () {
    this.props.actions.fetchPaymentCombo(false)
  }

  componentDidUpdate () {
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (this.props.dossierSubtypeId && this.props.dossierSubtypeId !== null && this.props.dossierSubtypeId !== prevProps.dossierSubtypeId) {
      this.props.actions.fetchSaleTypeBySubtype(this.props.dossierSubtypeId)
    }
    if (this.props.dossierIds && JSON.stringify(prevProps.dossierIds) !== JSON.stringify(this.props.dossierIds)) {
      this.setState({
        saleType: null,
        operationTypes: [],
        paymentMethod: null,
        operationTypeDisabled: true,
        progress: 0,
        progressFail: 0,
        displayBar: 'none'
      })
      this.props.change('saleTypeId', null)
      this.props.change('operationTypeIds', null)
      this.props.change('paymentMethodId', null)
    }

    return null
  }

  handleSaleTypeChange (saleTypeId) {
    this.setState({ operationTypes: [] })
    if (saleTypeId) {
      this.props.actions.fetchOperationType(saleTypeId, this.props.uoId, false)
      this.setState({ operationTypeDisabled: false })
    } else {
      this.props.change('operationTypeIds', null)
      this.setState({ operationTypeDisabled: true })
    }
    this.setState({ saleType: saleTypeId })
  }

  handleOperationTypeChange (operationTypes) {
    this.setState({ operationTypes: operationTypes })
  }

  handlePaymentMethodTypeChange (paymentMethod) {
    this.setState({ paymentMethod: paymentMethod })
  }

  updateSaleData () {
    this.props.actions.setDossierFleetDataChangeProcess(this.props.dossierIds.join('$'), this.state.saleType, this.state.operationTypes, this.state.paymentMethod, () => { this.props.actions.closeActionDataModal(); this.props.fetchFleets(this.props.fleetsFilter) })
  }

  closeModal () {
    this.props.actions.closeActionDataModal()
  }

  render () {
    const {
      t,
      showModal, combos: { saleTypeCombo, operationTypeCombo, paymentMethodCombo }
    } = this.props
    const tKey = 'MANAGEMENT.FLEETS_TAB.'

    return (
      <Modal className="role-modal languages-modal" show={showModal} onHide={this.props.actions.closeActionDataModal}>
        <form autoComplete="off" >
          <Modal.Header closeButton onHide={this.props.actions.closeActionDataModal}>
            <Modal.Title>{t(`${tKey}TITLE`)}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="inputs-wrapper">
              <Col>
                <Field
                  colSm={4}
                  id="saleTypeId"
                  name="saleTypeId"
                  controlLabel={t(`${tKey}SALES_TYPE`)}
                  placeholder={t(`${tKey}SALES_TYPE`)}
                  valueKey="id"
                  labelKey="value"
                  component={InputSelect}
                  menuContainerStyle={{ zIndex: 999 }}
                  options={saleTypeCombo}
                  onInputChange={(value) => this.handleSaleTypeChange(value)}
                />

                <Field
                  colSm={4}
                  id="operationTypeIds"
                  name="operationTypeIds"
                  controlLabel={t(`${tKey}OPERATION_TYPE`)}
                  placeholder={t(`${tKey}OPERATION_TYPE`)}
                  valueKey="id"
                  labelKey="value"
                  multi
                  component={InputSelect}
                  menuContainerStyle={{ zIndex: 999 }}
                  options={operationTypeCombo}
                  disabled = {this.state.operationTypeDisabled}
                  onInputChange={(value) => this.handleOperationTypeChange(value)}
                />
                <Field
                  colSm={4}
                  id="paymentMethodId"
                  name="paymentMethodId"
                  controlLabel={t(`${tKey}PAYMENT_FORM`)}
                  placeholder={t(`${tKey}PAYMENT_FORM`)}
                  valueKey="id"
                  labelKey="value"
                  component={InputSelect}
                  options={paymentMethodCombo}
                  onInputChange={(value) => this.handlePaymentMethodTypeChange(value)}
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <ProgressBar style={{ display: this.state.displayBar }}>
              <ProgressBar max={this.props.dossierIds ? this.props.dossierIds.length : 0} now={this.state.progress} label={`${this.state.progress}/${this.props.dossierIds ? this.props.dossierIds.length : 0}`} striped variant="success" key={1} />
              <ProgressBar max={this.props.dossierIds ? this.props.dossierIds.length : 0} now={this.state.progressFail} label={`${this.state.progressFail}/${this.props.dossierIds ? this.props.dossierIds.length : 0}`} striped variant="danger" style={{ backgroundColor: '#d44b4b' }} key={2} />
            </ProgressBar>
            <Col sm={6}>
              <Button
                className="btn-standard"
                onClick = {() => this.updateSaleData()}>
                {t(`${tKey}ACCEPT`)}
              </Button>
            </Col>
            <Col sm={6} className="btn-wrapper">
              <Button
                id="role-modal-submit-button"
                className="btn-standard"
                onClick = {this.props.actions.closeActionDataModal}>
                {t(`${tKey}CANCEL`)}
              </Button>
            </Col>
          </Modal.Footer>
        </form>
      </Modal>

    )
  }
}

export default reduxForm({
  form: 'editActionDataModal'
})(ActionDataModal)
