import messageModalActionTypes from '../../../constants/actions/dossier/common/nonUpdatedDossiers'

export function openNonUpdatedDossiers () {
  return {
    type: messageModalActionTypes.OPEN_NON_UPDATED_DOSSIERS_MODAL
  }
}

export function closeNonUpdatedDossiers () {
  return {
    type: messageModalActionTypes.CLOSE_NON_UPDATED_DOSSIERS_MODAL
  }
}

export function fetchNonUpdatedDossiers (filters) {
  return {
    type: messageModalActionTypes.FETCH_NON_UPDATED_DOSSIERS,
    filters: filters
  }
}

export function delayNonUpdatedDossierAlert (dossierId, notifyData, resolve, reject) {
  return {
    type: messageModalActionTypes.DELAY_NON_UPDATED_DOSSIER,
    dossierId: dossierId,
    notifyData: notifyData,
    resolve,
    reject
  }
}

export function fetchNonUpdatedDossiersSuccess (data, filters) {
  return {
    type: messageModalActionTypes.FETCH_NON_UPDATED_DOSSIERS_SUCCESS,
    data,
    filters
  }
}

export default {
  openNonUpdatedDossiers,
  closeNonUpdatedDossiers,
  fetchNonUpdatedDossiers,
  delayNonUpdatedDossierAlert,
  fetchNonUpdatedDossiersSuccess
}
