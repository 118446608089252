import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import UnattendedProcessDownloadFileModal from '../../../components/management/AuxModals/UnattendedProcessDownloadFileModal'
import { openCloseUnattendedProcessDownloadFileModal, fetchUnattendedProcessFileListInfo } from '../../../actions/management/auxModals/auxModals'

export function mapStateToProps (state) {
  return {
    ...state.managementAuxModals.unattendedProcessDownloadFileModal
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      openCloseUnattendedProcessDownloadFileModal,
      fetchUnattendedProcessFileListInfo
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(UnattendedProcessDownloadFileModal))
