import { call, put, select, takeLatest } from 'redux-saga/effects'
import { getAuth } from '../../selectors/access/auth'
import getCountries from '../../services/country/getCountries'
import commonActions from '../../actions/common'
import messageModalActions from '../../actions/modals/messageModal'
import { fetchCountriesSucess } from '../../actions/combos/combos'
import combosActionTypes from '../../constants/actions/combos/combos'
import { handleError } from '../errors/errorManager'

export function * fetchCountries () {
  try {
    yield put(commonActions.showLoader())
    const auth = yield select(getAuth)
    const countries = yield call(getCountries, auth.token)
    yield put(fetchCountriesSucess(countries))
  } catch (error) {
    const customHandler = function * () {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.FETCH_COMBO_ERROR'))
    }
    yield call(handleError, { error, customHandler })
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchFetchCountries () {
  yield takeLatest(combosActionTypes.FETCH_COUNTRY_COMBO, fetchCountries)
}
