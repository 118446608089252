import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { getFormValues } from 'redux-form'
import Faq from '../../components/faq/Faq'
import { fetchQuestion } from '../../actions/faq/faq'
import TrackingContainer from '../../containers/tracking/trackingContainer'

export function mapStateToProps (state) {
  return {
    ...state.faq,
    formValues: getFormValues('faq')(state)
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchQuestion
    }, dispatch)
  }
}

var decoratedComponent = TrackingContainer(Faq, true)
export default connect(mapStateToProps, mapDispatchToProps)(translate()(decoratedComponent))
