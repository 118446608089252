import React, { PureComponent } from 'react'
import { Popover, Overlay, Button, Glyphicon } from 'react-bootstrap'

class FinderFleetReportDatesPopover extends PureComponent {
  constructor () {
    super()
    this.state = {
      showPopover: false,
      target: this
    }
  }

  handleClick (e) {
    e.stopPropagation()
    this.setState({ target: e.target, showPopover: true })
  }

  getDateColumn (dates) {
    return Object.keys(dates)?.length > 0 ? dates?.brandDischargeDate || this.props.t('SEARCH.CATEGORIES.FLEETREPORT.NOT_INDICATED') : ''
  }

  render () {
    const {
      t,
      idx,
      half,
      id,
      dates = {}
    } = this.props

    const tKey = 'SEARCH.CATEGORIES.FLEETREPORT.'
    return (
      <div>
        {Object.keys(dates)?.length > 0 &&
          <Button bsSize="xsmall" className="icon-only" onClick={this.handleClick.bind(this)} id={'entity_popOver_button_' + idx} ><Glyphicon glyph="plus"/></Button>
        }
        <div className="text-popover">
          {
            this.getDateColumn(dates)
          }
        </div>
        <Overlay
          container={this}
          placement={(idx >= half) ? 'right' : 'bottom'}
          show={this.state.showPopover}
          target={this.state.target}
          onHide={() => this.setState({ showPopover: false })}
          rootClose
          containerPadding = {0}
          arrowOffsetLeft = {0}
        >
          <Popover id={id} className="entity-popover-container search-comp">
            {dates?.customerNeedDate &&
            <div className="popover-item">
              <label>{t(`${tKey}CUSTOMER_NEED_DATE`)}: </label><br/>
              {dates?.customerNeedDate}
            </div>
            }
            {dates?.shippingOrderDate &&
            <div className="popover-item">
              <label>{t(`${tKey}SHIPPING_ORDER_DATE`)}: </label><br/>
              {dates?.shippingOrderDate}
            </div>
            }
            {dates?.campaignOutputDate &&
            <div className="popover-item">
              <label>{t(`${tKey}CAMPAIGN_OUTPUT_DATE`)}: </label><br/>
              {dates?.campaignOutputDate}
            </div>
            }
            {dates?.destinationArrivalDate &&
            <div className="popover-item">
              <label>{t(`${tKey}DESTINATION_ARRIVAL_DATE`)}:</label><br/>
              {dates?.destinationArrivalDate}
            </div>
            }

          </Popover>
        </Overlay>
      </div>
    )
  }
}

export default FinderFleetReportDatesPopover
