import React, { PureComponent } from 'react'
import SimpleInputCheckBox from '../simpleForm/SimpleInputCheckBox'

class EditableCheckboxCeil extends PureComponent {
  render () {
    const { onInputChange, inputValue = false, stateValue = false, editing, nonEditable = false, id, hasError = false, className, disabled } = this.props
    return (
      <td className={className}>
        <SimpleInputCheckBox
          id={id}
          value={editing && !nonEditable ? inputValue : stateValue}
          onInputChange={onInputChange}
          disabled={!editing || nonEditable || disabled}
          hasError={hasError}
        />
      </td>
    )
  }
}
export default EditableCheckboxCeil
