import authActionTypes from '../constants/actions/auth'
import releaseNotesModalActionTypes from '../constants/actions/modals/releaseNotesModal'

export function initialState () {
  return {
    token: null,
    userId: null,
    userName: null,
    userSurnames: null,
    roleNames: [],
    organizedUnit: null,
    changePassword: null,
    sections: [],
    emailAddress: null,
    logoFilePath: null,
    error: null,
    passwordPolicy: {},
    emailSended: null,
    userCIF: null,
    qrContentEncrypted: null,
    selectedSalesmanId: null,
    updatedSelected: false,
    nonUpdatedSalesControl: false,
    languageId: null,
    enableSubscriptions: false,
    newVersionNumber: null,
    showNewVersion: false,
    releaseNotes: null
  }
}

// TODO review this function
function fetchAuth (state) {
  return {
    ...state
  }
}

function setAuth (state, {
  auth: {
    token, userId, userName, userSurnames, roleNames, organizedUnit,
    changePassword, sections, emailAddress, logoFilePath, defaultSalesmen,
    selectedSalesmanId, userCIF, nonUpdatedSalesControl, languageId, subscriptions, themeId, showChecklistAsTab
  }
}) {
  return {
    ...state,
    token,
    userId,
    userName,
    userSurnames,
    roleNames,
    organizedUnit,
    changePassword,
    sections,
    emailAddress,
    logoFilePath,
    defaultSalesmen,
    selectedSalesmanId,
    userCIF,
    nonUpdatedSalesControl,
    languageId,
    themeId,
    showChecklistAsTab,
    enableSubscriptions: subscriptions
  }
}

function fetchAuthError (state, { error }) {
  return {
    ...state,
    error
  }
}

// TODO review this function
function logout (state) {
  return {
    ...state
  }
}

// TODO review this function
function logoutSuccess (state) {
  return {
    ...state,
    ...initialState()
  }
}

// TODO review this function
function resetPassword (state) {
  return {
    ...state
  }
}

// TODO review this function
function rememberPassword (state) {
  return {
    ...state
  }
}

function rememberPasswordSuccess (state) {
  return {
    ...state,
    emailSended: true
  }
}

function rememberPasswordError (state, { error }) {
  return {
    ...state,
    error
  }
}

function fetchPasswordPolicySuccess (state, { data }) {
  return {
    ...state,
    passwordPolicy: data
  }
}

function setOrganizedUnitSuccess (state, { organizedUnit }) {
  return {
    ...state,
    organizedUnit
  }
}

function checkTokenSuccess (state, { userName, userSurnames, emailAddress }) {
  return {
    ...state,
    userName,
    userSurnames,
    emailAddress
  }
}

function encryptQrContentSuccess (state, { qrContentEncrypted }) {
  return {
    ...state,
    qrContentEncrypted
  }
}

function setSalesmanSelectedSuccess (state, { selectedSalesmanId }) {
  return {
    ...state,
    selectedSalesmanId,
    updatedSelected: true
  }
}

function setNewVersionSuccess (state, { newVersionNumber }) {
  return {
    ...state,
    newVersionNumber,
    showNewVersion: true
  }
}

function setReleaseNotesSuccess (state, { releaseNotes }) {
  return {
    ...state,
    releaseNotes
  }
}

function closeReleaseNotesModalSuccess (state) {
  return {
    ...state,
    showNewVersion: false
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case authActionTypes.FETCH_AUTH:
      return fetchAuth(state, action)
    case authActionTypes.SET_AUTH:
      return setAuth(state, action)
    case authActionTypes.FETCH_AUTH_ERROR:
      return fetchAuthError(state, action)
    case authActionTypes.LOGOUT:
      return logout(state, action)
    case authActionTypes.LOGOUT_SUCCESS:
      return logoutSuccess()
    case authActionTypes.RESET_PASSWORD:
      return resetPassword(state, action)
    case authActionTypes.REMEMBER_PASSWORD:
      return rememberPassword(state, action)
    case authActionTypes.REMEMBER_PASSWORD_SUCCESS:
      return rememberPasswordSuccess(state, action)
    case authActionTypes.REMEMBER_PASSWORD_ERROR:
      return rememberPasswordError()
    case authActionTypes.FETCH_PASSWORD_POLICY_SUCCESS:
      return fetchPasswordPolicySuccess(state, action)
    case authActionTypes.SET_ORGANIZED_UNIT_SUCCESS:
      return setOrganizedUnitSuccess(state, action)
    case authActionTypes.CHECK_TOKEN_SUCCESS:
      return checkTokenSuccess(state, action)
    case authActionTypes.ENCRYPT_QR_CONTENT_SUCCESS:
      return encryptQrContentSuccess(state, action)
    case authActionTypes.SET_SALESMAN_SELECTED_SUCCESS:
      return setSalesmanSelectedSuccess(state, action)
    case authActionTypes.SET_NEW_VERSION_SUCCESS:
      return setNewVersionSuccess(state, action)
    case authActionTypes.FETCH_RELEASE_NOTES_SUCCESS:
      return setReleaseNotesSuccess(state, action)
    case releaseNotesModalActionTypes.RELEASE_NOTES_CLOSE_SUCCESS:
      return closeReleaseNotesModalSuccess(state, action)
    default:
      return state
  }
}
