
import React, { PureComponent } from 'react'
import { Modal, Col, Row, Button } from 'react-bootstrap'
import { reduxForm, Field } from 'redux-form'
import InputSelect from '../../commons/form/InputSelect'
import InputNumber from '../../commons/form/InputNumber'
import { styleTasksIds } from '../../../constants/backendIds'

class StyleTaskMasterModal extends PureComponent {
  constructor () {
    super()
    this.state = {
      styleTasksCombo: null,
      styleCssIdSelected: null,
      actualStyle: null,
      actualOrderSelected: null,
      ordersCombo: null
    }
  }

  componentDidMount () {
    return new Promise((resolve, reject) => {
      this.props.actions.getOrdersStyleTask(resolve, reject)
    }).then((result) => {
      if (result) {
        this.setState({ ordersCombo: result })
        this.setState({
          styleTasksCombo: [
            { id: 1, value: this.props.t('DASHBOARD_TASK.STYLE_TYPES.NORMAL'), code: '' },
            { id: 2, value: this.props.t('DASHBOARD_TASK.STYLE_TYPES.BOLD'), code: 'Bold' },
            { id: 3, value: this.props.t('DASHBOARD_TASK.STYLE_TYPES.BOLDBLUE'), code: 'BoldBlue' },
            { id: 4, value: this.props.t('DASHBOARD_TASK.STYLE_TYPES.BOLDRED'), code: 'BoldRed' }
          ]
        })
      }
    })
  }

  submitStyle (values) {
    let body = []
    Object.keys(values).map(item => {
      if (typeof values[item] === 'object') {
        body.push(values[item])
      }
    })
    body = {
      styleTaskId: this.props.styleTaskId,
      OrderId: this.props.styleTask.order && !this.state.actualOrderSelected ? this.props.styleTask.order : parseInt(this.state.actualOrderSelected),
      StyleCssCode: this.setCodeByStyleCssId(this.state.styleCssIdSelected || this.props.styleTask.styleTaskCssId)
    }
    new Promise((resolve) => {
      this.props.actions.submitStyleTask(body, resolve)
    }).then((result) => {
      if (result) {
        this.setState({ styleCssIdSelected: null, actualStyle: null, actualOrderSelected: null })
      }
    })
  }

  setCodeByStyleCssId (StyleCssId) {
    var cssSelected = this.state.styleTasksCombo && this.state.styleTasksCombo.find(b => b.id === parseInt(StyleCssId))
    this.setState({ styleCssIdSelected: cssSelected.styleTaskCssId })
    return cssSelected.code
  }

  applyStyleTask (task, styleTaskCssId) {
    if (task || styleTaskCssId) {
      switch ((task && task.styleTaskCssId) || styleTaskCssId) {
        case styleTasksIds.Normal:
          return ''
        case styleTasksIds.Bold:
          return 'activebold'
        case styleTasksIds.BoldBlue:
          return 'activeboldblue'
        case styleTasksIds.BoldRed:
          return 'activeboldred'
      }
    }
  }

  setStateStyle (value) {
    this.setState({ styleCssIdSelected: value, actualStyle: this.applyStyleTask(null, value) })
  }

  setOrderStyle (value) {
    this.setState({ actualOrderSelected: value })
  }

  close () {
    this.setState({ styleCssIdSelected: null, actualStyle: null, actualOrderSelected: null })
    this.props.actions.closeStyleTaskMasterModal()
  }

  render () {
    const {
      t, handleSubmit, showModal, styleTask
    } = this.props
    const tKey = 'MASTERS.STYLE_TASK_MASTERS.MODAL.'
    return (
      <Modal className="role-modal languages-modal" show={showModal} onHide={this.close.bind(this)}>
        <form autoComplete="off" onSubmit={handleSubmit(this.submitStyle.bind(this))}>
          <Modal.Header closeButton onHide={this.close.bind(this)}>
            <Modal.Title>{t(`${tKey}STYLE_TASK`)}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="inputs-wrapper">
              <Col sm={2} className="custom-width-col">
                <h2>{t(`${tKey}ORDER`)}</h2>
                {/* <Field
                  name={'orderId'}
                  placeholder={t(`${tKey}ORDER`)}
                  valueKey="id"
                  labelKey="value"
                  component={InputSelect}
                  colSm={0}
                  options={this.state.ordersCombo}
                  onChange={(event, value) => this.setOrderStyle(value)}
                /> */}
                <Field
                  name="orderId"
                  placeholder={t(`${tKey}ORDER`)}
                  component={InputNumber}
                  colSm={0}
                  onChange={(event, value) => this.setOrderStyle(value)}
                  minLength={1}
                  maxLength={3}
                  max={999}
                  min={1}
                />
              </Col>
              <Col sm={6} className="custom-width-col" >
                <h2>{t(`${tKey}NAME`)}</h2>
                <p style={{ paddingTop: 10 }} className={this.state.actualStyle !== null ? this.state.actualStyle : this.applyStyleTask(styleTask, null)}>{styleTask && styleTask.name}</p>
              </Col>
              <Col sm={4} className="custom-width-col">
                <h2>{t(`${tKey}STYLE`)}</h2>
                <Field
                  name={'styleTaskComboId'}
                  placeholder={t(`${tKey}STYLE`)}
                  valueKey="id"
                  labelKey="value"
                  component={InputSelect}
                  colSm={0}
                  options={this.state.styleTasksCombo}
                  onChange={(event, value) => this.setStateStyle(value)}
                />
              </Col>
            </Row>
            <div className="clearfix" />
          </Modal.Body>
          <Modal.Footer>
            <Col sm={12} className="btn-wrapper">
              <Button
                id="role-modal-submit-button"
                className="btn-standard"
                type="submit"
                disabled={this.props.pristine}>
                {t(`${tKey}SUBMIT_BUTTON`)}
              </Button>
            </Col>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default reduxForm({
  form: 'editStyleTask',
  validate: (values, props) => {
    let error = {}
    if (values) {
      const tKey = 'MASTERS.STYLE_TASK_MASTERS.VALIDATION.'
      if (!values.styleTaskComboId) {
        error.styleTaskComboId = props.t(`${tKey}STYLE_COMBO`)
      }
      if (!values.orderId) {
        error.orderId = props.t(`${tKey}STYLE_ORDER`)
      } else if (values.orderId) {
        if (isNaN(parseInt(values.orderId))) {
          error.orderId = props.t(`${tKey}STYLE_ORDER`)
        }
      }
    }
    return Object.keys(error).length > 0 ? error : {}
  }
})(StyleTaskMasterModal)
