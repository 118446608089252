import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { fetchAuditCodes, fetchDocumentTypeUseCombo } from '../../actions/combos/combos'
import { initializeSearch, resetFilters } from '../../actions/search/search'
import { openModal, yesNoModal } from '../../actions/common'
import { fetchSearchDocumentsInfo, resetFiltersAuditsInfo, openZipModal, resetSearchDocumentsInfo } from '../../actions/management/management'
import SearchDocumental from '../../components/management/SearchDocumental'
import { openDocumentEditionModal } from '../../actions/dossier/common/documentEditionModal'
import { openDocumentEditionModal as openDocumentEditionModalHistorical } from '../../actions/dossier/common/documentHistoricalEditionModal'

export function mapStateToProps (state) {
  return {
    ...state.management.searchDocuments,
    combos: {
      ...state.combos
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      openDocumentEditionModal,
      fetchDocumentTypeUseCombo,
      fetchAuditCodes,
      openZipModal,
      initializeSearch,
      resetFilters,
      openModal,
      yesNoModal,
      fetchSearchDocumentsInfo,
      resetFiltersAuditsInfo,
      resetSearchDocumentsInfo,
      openDocumentEditionModalHistorical
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(SearchDocumental))
