export const validationEntityPromisesOrder = {
  ENTITY_COMPONENT_DNI_CIF: 0,
  ENTITY_COMPONENT_REPRESENTATIVE_DNI: 1
}

export const validationSalesPromisesOrder = {
  ENTITY_COMPONENT: 0,
  ENTITY_CHILD_COMPONENT: 1
}

export const validationCampaignPromisesOrder = {
  ENTITY_COMPONENT: 0,
  ENTITY_CHILD_COMPONENT: 1
}

export const validationUACModalPromisesOrder = {
  UAC_ENTITY_OWNER: 0
}

export const validationPaperworkPromisesOrder = {
  ENTITY_COMPONENT: 0,
  ENTITY_CHILD_COMPONENT: 1
}

export const validationPurchasePromisesOrder = {
  ENTITY_COMPONENT: 0
}

export const validationRegistrationDeliveryPromisesOrder = {
  ENTITY_COMPONENT: 0,
  ENTITY_CHILD_COMPONENT: 1
}

export const validationAddNewFleerModalPromisesOrder = {
  ENTITY_COMPONENT: 0
}

export const validationCreateNewFleetModalPromisesOrder = {
  ENTITY_COMPONENT: 0,
  ENTITY_CHILD_COMPONENT: 1
}

export const validationDataContactFleetModalPromisesOrder = {
  ENTITY_COMPONENT: 0,
  ENTITY_CHILD_COMPONENT: 1
}
