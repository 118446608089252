import React, { PureComponent } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { formatMoney } from '../../../util/utils'

class BudgetRow extends PureComponent {
  render () {
    const {
      tKey,
      t,
      budget: { dossierBudgetRatingId, ratingItem, freeRating, amount },
      fetchDossierBudgetRating,
      deleteDossierBudgetRating,
      readOnlyFields
    } = this.props
    const editTooltip = (<Tooltip id="editTooltip">{t(`${tKey}EDIT_BTN`)}</Tooltip>)
    const deleteTooltip = (<Tooltip id="deleteTooltip">{t(`${tKey}DELETE_BTN`)}</Tooltip>)
    return (
      <tr>
        <td>{ratingItem || freeRating}</td>
        <td className="text-left">{ amount ? formatMoney(amount) : formatMoney(0)} </td>
        {
          !readOnlyFields
            ? <td>
              <a onClick={() => fetchDossierBudgetRating(dossierBudgetRatingId)}>
                <OverlayTrigger placement="right" overlay={editTooltip}>
                  <i className="ico-edit-white"/>
                </OverlayTrigger>
              </a>
              <a onClick={() => deleteDossierBudgetRating(dossierBudgetRatingId)}>
                <OverlayTrigger placement="right" overlay={deleteTooltip}>
                  <i className="ico-trash"/>
                </OverlayTrigger>
              </a>
            </td>
            : <td/>
        }
      </tr>
    )
  }
}
export default BudgetRow
