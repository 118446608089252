import mastersActionTypes from '../../../constants/actions/masters/masters'

function initialState () {
  return {
    purchasePresentTypeMastersList: [],
    purchasePresentCount: 0,
    pagesCount: 0,
    filter: {
      page: 1,
      orderBy: '',
      pageSize: 10
    }
  }
}

function fetchPurchasePresentTypeListSuccess (state, { purchasePresentTypeMastersList, filter }) {
  return {
    ...state,
    purchasePresentTypeMastersList: purchasePresentTypeMastersList.purchasePresentTypeMastersList,
    purchasePresentCount: purchasePresentTypeMastersList.purchasePresentCount,
    pagesCount: purchasePresentTypeMastersList.pagesCount,
    filter
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case mastersActionTypes.FETCH_PURCHASE_PRESENT_TYPE_SUCCESS_LIST:
      return fetchPurchasePresentTypeListSuccess(state, action)
    default:
      return state
  }
}
