import React, { PureComponent } from 'react'
import { reduxForm } from 'redux-form'
import { Tabs, Tab, Row, Button, Glyphicon } from 'react-bootstrap'
import FleetsPage from '../../containers/management/FleetsPage'
import FleetFinderPage from '../../containers/management/FleetFinderPage'
import { permissions } from '../../constants/backendIds'
import { getSectionPermissions } from '../../util/permisionFunctions'
import { sectionPermissions } from '../../constants/dossier/common/buttonsHeader'
import UnattendedProcessesFinderPage from '../../containers/management/UnattendedProcessesFinderPage'
import { unattendedResult } from '../../constants/management/unattendedStatus'
import FleetsManagerPage from '../../containers/management/FleetsManagerPage'
import SelfSupplyManagementPage from '../../containers/management/SelfSupplyManagementPage'
import AuditAdminPage from '../../containers/management/AuditAdminPage'
import AuditsPage from '../../containers/management/AuditsPage'
import SearchDocumentalPage from '../../containers/management/SearchDocumentalPage'
import { managementTab, managementTabCode } from '../../constants/management/managementConstants'
import AuditModalPage from '../../containers/management/auxModals/AuditModalPage'

class Management extends PureComponent {
  constructor () {
    super()
    this.state = {
      sectionTabsConfiguration: {
        fleets: {
          permission: null
        },
        fleetsManagement: {
          permission: null
        },
        unattendedProcess: {
          permission: null
        },
        fleet_management_section: {
          permission: null
        },
        self_supply_section: {
          permission: null
        },
        audit_admin: {
          permission: null
        },
        audits: {
          permission: null
        },
        search_documental: {
          permission: null
        },
        hideMenu: false
      },
      processSearched: false,
      processfields: {
        unattendedProcessStatus: [unattendedResult.PENDING, unattendedResult.RUNNING_NO_ERRORS],
        unattendedProcessView: 1
      },
      visible: false
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.auditAdminModal.showModal === false && prevProps.auditAdminModal.showModal === true) {
      this.setState({ visible: false })
    }
    if (!this.props.selectedTabManagement) {
      this.getDefaultSelectedTab()
    }
  }

  componentDidMount () {
    this.initializePermisions(this.props)
  }

  initializePermisions (props) {
    const section = props.sectionsGeneral.filter((tabConf) => tabConf.code === sectionPermissions.management)
    if (section.length > 0 && section[0] != null) {
      const newState = getSectionPermissions(section[0])
      let permissionState = this.state.sectionTabsConfiguration
      permissionState = { ...permissionState, ...newState }
      this.setState({ sectionTabsConfiguration: permissionState })
    }
  }

  onSelectTabs (idx, event, props) {
    this.props.actions.managementTabChange(idx)
    if (idx === 3) {
      if (!this.state.processSearched) {
        if (props.unnatendedProcess.dynamicFilters?.unattendedProcessStatus !== undefined) {
          this.searchFinder(props.unnatendedProcess.dynamicFilters, props.unnatendedProcess.filters, props)
        } else {
          this.searchFinder(this.state.processfields, props.unnatendedProcess.filters, props)
        }
        this.setState({ processSearched: true })
      }
    }
  }

  searchFinder (values, filters, props) {
    let body = {}
    if (values.unattendedProcessView) {
      body.OnlyUser = true
    }
    body.Status = values.unattendedProcessStatus
    body.ProcessTypeId = values.unattendedProcessType
    body.StartDateLaunchProcess = values.launchDateFrom
    body.EndDateLaunchProcess = values.launchDateTo
    body.StartDateEndProcess = values.endDateFrom
    body.EndDateEndProcess = values.endDateTo
    props.actions.findUnattendedProcess({ ...body, ...filters })
  }

  getDefaultSelectedTab () {
    let firstTabToShow = null
    Object.keys(this.state.sectionTabsConfiguration).forEach(tab => {
      if (!firstTabToShow && this.state.sectionTabsConfiguration[tab] && this.state.sectionTabsConfiguration[tab].permission > permissions.hidden) {
        switch (tab) {
          case managementTabCode.FLEETS: {
            firstTabToShow = managementTab.FLEETS
            break
          } case managementTabCode.FLEETS_MANAGEMENT: {
            firstTabToShow = managementTab.FLEETS_MANAGEMENT
            break
          } case managementTabCode.UNATTENDED_PROCESS: {
            firstTabToShow = managementTab.UNATTENDED_PROCESS
            break
          } case managementTabCode.FLEETS_MANAGEMENT_SECTION: {
            firstTabToShow = managementTab.FLEETS_MANAGEMENT_SECTION
            break
          } case managementTabCode.SELF_SUPPLT_SECTION: {
            firstTabToShow = managementTab.SELF_SUPPLT_SECTION
            break
          }
        }
      }
    })
    if (firstTabToShow) {
      this.props.actions.managementTabChange(firstTabToShow)
    }
  }

  openAuditAdminModal () {
    this.setState({
      visible: true
    }, () => {
      const auditConfiguration = {
        AuditObservation: false,
        audit: false
      }
      this.props.actions.openAuditAdminModal(auditConfiguration)
    })
  }

  toogleInnerMenu () {
    this.setState({ hideMenu: !this.state.hideMenu })
  }

  render () {
    const {
      t, selectedTabManagement
    } = this.props
    const tKey = 'MANAGEMENT.'
    const showButton = selectedTabManagement === managementTab.ADMIN_AUDIT

    return (
      <div className="masters-container search-wrapper">
        { this.state.visible &&
            <AuditModalPage
              readOnly={false}
              isAdmin={true}
            />
        }
        <div className="search-header">
          <div className="title-user management-title">
            <div>
              <i className="ico-mantenimiento-condiciones" />
              <h2>{t(`${tKey}TITLE`)}</h2>
            </div>
            {showButton && (
              <Button id="btn_delete" className="btn-actions new-audit-button" onClick={() => this.openAuditAdminModal()}>
                <Glyphicon className='icon-action white' glyph="plus" />
              &nbsp;
                {t(`${tKey}NEW_AUDIT`)}
              </Button>
            )}
          </div>
        </div>
        <div className="filters-search-wrapper">
          <Row>
            <Tabs
              activeKey={selectedTabManagement}
              defaultActiveKey={selectedTabManagement}
              animation={false}
              id="tab-management"
              className="col-sm-12"
              onSelect={(idx, event) => this.onSelectTabs(idx, event, this.props)}
            >
              {this.state.sectionTabsConfiguration.fleets.permission !== null && this.state.sectionTabsConfiguration.fleets.permission !== permissions.hidden && (
                <Tab
                  eventKey={1}
                  title={t(`${tKey}FLEETS`)}>
                  <FleetsPage
                    tabId = {1}
                    fetchManagementActions = {this.props.actions.fetchManagementActions}
                    managementActions = {this.props.combos.managementActions}
                    sectionsGeneral = {this.props.sectionsGeneral}
                  ></FleetsPage>
                </Tab>)}
              {this.state.sectionTabsConfiguration.fleetsManagement.permission !== null && this.state.sectionTabsConfiguration.fleetsManagement.permission !== permissions.hidden && (
                <Tab
                  eventKey={2}
                  title={t(`${tKey}FLEETS_MANAGEMENT`)}>
                  <FleetFinderPage></FleetFinderPage>
                </Tab>)}
              {this.state.sectionTabsConfiguration.unattendedProcess.permission !== null && this.state.sectionTabsConfiguration.unattendedProcess.permission !== permissions.hidden && (
                <Tab
                  eventKey={3}
                  title={t(`${tKey}PROCESSES`)}>
                  <UnattendedProcessesFinderPage></UnattendedProcessesFinderPage>
                </Tab>)}
              {this.state.sectionTabsConfiguration.fleet_management_section.permission !== null && this.state.sectionTabsConfiguration.fleet_management_section.permission !== permissions.hidden && (
                <Tab
                  eventKey={4}
                  title={t('MANAGEMENT.FLEETS_TAB.FLEETS_MANAGEMENT')}>
                  <FleetsManagerPage sectionsGeneral = {this.props.sectionsGeneral}></FleetsManagerPage>
                </Tab>)}
              {this.state.sectionTabsConfiguration.self_supply_section.permission !== null && this.state.sectionTabsConfiguration.self_supply_section.permission !== permissions.hidden && (
                <Tab
                  eventKey={5}
                  title={t('MANAGEMENT.SELF_SUPPLY_TAB.SELF_SUPPLY_MANAGEMENT')}>
                  <SelfSupplyManagementPage
                    selectedTabManagement = {selectedTabManagement}></SelfSupplyManagementPage>
                </Tab>)}
              {this.state.sectionTabsConfiguration.audit_admin.permission !== null && this.state.sectionTabsConfiguration.audit_admin.permission !== permissions.hidden && (
                <Tab
                  eventKey={6}
                  title={t('MANAGEMENT.ADMIN_AUDIT.ADMIN_AUDIT')}>
                  <AuditAdminPage
                    selectedTabManagement = {selectedTabManagement}>
                  </AuditAdminPage>
                </Tab>)}
              {this.state.sectionTabsConfiguration.audits.permission !== null && this.state.sectionTabsConfiguration.audits.permission !== permissions.hidden && (
                <Tab
                  eventKey={7}
                  title={t('MANAGEMENT.ADMIN_AUDIT.AUDITS')}>
                  <AuditsPage
                    selectedTabManagement = {selectedTabManagement}>
                  </AuditsPage>
                </Tab>)}
              {this.state.sectionTabsConfiguration.search_documental.permission !== null && this.state.sectionTabsConfiguration.search_documental.permission !== permissions.hidden && (
                <Tab
                  eventKey={8}
                  title={t('MANAGEMENT.SEARCH_DOCUMENTAL.TITLE')}>
                  <SearchDocumentalPage
                    selectedTabManagement = {selectedTabManagement}>
                  </SearchDocumentalPage>
                </Tab>
              )}
            </Tabs>
          </Row>
        </div>
      </div>
    )
  }
}

export default reduxForm({
  form: 'management'
})(Management)
