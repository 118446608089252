import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { fetchDashboard, fetchFilteredDashboard, openUploadDocumentFleetModal, fetchCreateDossierButton, fetchDashboardButtons, fetchDossierFavorites, fetchDossierLastAccessed, fetchTaskFavorites } from '../../actions/dashboard/dashboard'
import Dashboard from '../../components/dashboard/Dashboard'
import Dashboard_v2 from '../../_v2/components/dashboard/Dashboard'
import { translate } from 'react-polyglot'
import TrackingContainer from '../../containers/tracking/trackingContainer'
import { getStorageValue } from '../../storage/storage'
// import { push as navigateTo } from 'react-router-redux'

export function mapStateToProps (state) {
  const salesmanUO = []
  if (state.auth.defaultSalesmen) {
    Object.keys(state.auth.defaultSalesmen).map(item => { salesmanUO.push(state.auth.defaultSalesmen[item].salesmanId) })
  }
  return {
    sections: state.auth.sections,
    ...state.dashboard,
    organizedUnit: state.auth.organizedUnit,
    combos: state.combos,
    defaultSalesmen: state.auth.defaultSalesmen,
    salesmanId: state.dashboard.firstLoad || state.auth.updatedSelected ? state.auth.selectedSalesmanId && state.auth.selectedSalesmanId.length > 0 ? state.auth.selectedSalesmanId
      : salesmanUO.length > 0 ? salesmanUO : state.dashboard.salesmanId : state.dashboard.salesmanId
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchDashboard,
      fetchFilteredDashboard,
      /* navigateTo, */
      openUploadDocumentFleetModal,
      fetchCreateDossierButton,
      fetchDashboardButtons,
      fetchDossierFavorites,
      fetchDossierLastAccessed,
      fetchTaskFavorites
    }, dispatch)
  }
}
// var decoratedComponent = TrackingContainer(Dashboard)
let version = parseInt(getStorageValue('interfaceVersion'))
var decoratedComponent = null
if (version === 2) {
  decoratedComponent = TrackingContainer(Dashboard_v2, true)
} else {
  decoratedComponent = TrackingContainer(Dashboard, true)
}
export default connect(mapStateToProps, mapDispatchToProps)(translate()(decoratedComponent))
