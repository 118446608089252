import apiFetch from '../apiFetch'

export default function (token, { filters, columns }) {
  const filter = { ...filters, columns }
  return apiFetch({
    endPoint: 'DossierFinder/PapServicesFromManagerDownload',
    method: 'POST',
    body: filter,
    token: token,
    parseBlobAndAssign: true
  })
}
