import { openReservationContractModalSuccess, closeReservationContractModalSuccess } from '../../../../actions/dossier/common/reservationContractModal'
import { showLoader, hideLoader } from '../../../../actions/common.js'

import dossierActionTypes from '../../../../constants/actions/dossier/dossier'
import getDossierFinancing from '../../../../services/dossier/get/getDossierFinancing.js'
import { getAuth } from '../../../../selectors/access/auth'

import { handleError } from '../../../errors/errorManager'

import { put, call, takeEvery, select } from 'redux-saga/effects'

export function * openReservationContractModal ({ dossierId, printCheckList, checklistId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const financingDetails = yield call(getDossierFinancing, dossierId, auth.token)
    const financingAmount = (financingDetails.amount !== undefined) ? financingDetails.amount : 0
    yield put(openReservationContractModalSuccess(financingAmount, printCheckList, checklistId))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchOpenReservationContractModal () {
  yield takeEvery(dossierActionTypes.OPEN_RESERVATION_CONTRACT_MODAL, openReservationContractModal)
}

export function * closeReservationContractModal () {
  try {
    yield put(showLoader())
    yield put(closeReservationContractModalSuccess())
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchCloseReservationContractModal () {
  yield takeEvery(dossierActionTypes.CLOSE_RESERVATION_CONTRACT_MODAL, closeReservationContractModal)
}
