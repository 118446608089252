import apiFetch from '../apiFetch'

export default function (token, status) {
  status = status || 0

  return apiFetch({
    endPoint: `Document/Sign/${status}/GetCount`,
    method: 'GET',
    body: null,
    token: token
  })
}
