export const EntityType = {
  INDIVIDUAL: 1,
  COMPANY: 2,
  RECURRING: 3,
  CLIENT_CLAIM: 4,
  RECURRING_SERVICE: 5
}
export const EntitySubType = {
  MANUFACTURER: 1,
  DISTRIBUTOR: 2,
  CONCESSIONAIRE_QUADIS: 4
}
