import React, { PureComponent } from 'react'
import { Panel, Col, Table, Button, Tooltip, OverlayTrigger, Glyphicon } from 'react-bootstrap'
import CampaignsRow from './campaings/CampaignsRow'
import SmallLoaderPage from '../../../containers/commons/SmallLoaderPage'
import { getFieldsPermisions } from '../../../util/permisionFunctions'
import { permissions } from '../../../constants/backendIds'
import { compareObjects } from '../../../util/utils'
import { dossierStagesBySubtype } from '../../../constants/dossier/common/dossierStages'
import CustomFields from '../../commons/customFields/CustomFields'
import InputCheckBox from '../../commons/form/InputCheckBox'
import { Field } from 'redux-form'

class Campaigns extends PureComponent {
  constructor () {
    super()
    this.state = {
      open: false,
      fieldsConfiguration: {
        downloadButton: null,
        addCampaignButton: null,
        campaignsBlocked: null
      },
      canAdd: true
    }
  }

  initializePermisions (props) {
    const fieldKeys = Object.keys(this.state.fieldsConfiguration)
    const newState = getFieldsPermisions(props.componentConfiguration.fieldsConfiguration, fieldKeys, props.componentConfiguration.permission)
    this.setState({ ...this.state, ...newState })
  }

  UNSAFE_componentWillMount () {
    this.initializePermisions(this.props)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.stageId >= dossierStagesBySubtype.saleVn.delivery || nextProps.stageId >= dossierStagesBySubtype.saleVo.delivery) {
      this.state.canAdd = false
    }
    if (nextProps.open) {
      this.setState({ open: true })
      // this.initializePermisions(nextProps)
    }
    if (nextProps.dossier && nextProps.dossier.dossierId && !compareObjects(this.props.componentConfiguration || {}, nextProps.componentConfiguration, true)) {
      this.initializePermisions(nextProps)
    }
  }

  toggleComponent () {
    if (this.state.open) {
      this.props.actions.setActiveSection(false)
    }
    this.setState({
      open: !this.state.open
    })
  }

  render () {
    const { t, readOnlyFields, campaigns, customFields, actions: { openSearchDocumentsModal, openCampaignDocument, openCampaignEdition, deleteCampaign, fetchCampaignEdition } } = this.props
    const tKey = 'DOSSIER_COMPONENTS.CAMPAIGNS.'
    const blockTooltip = (<Tooltip id="blockTooltip">{t(`${tKey}CAMPAIGNS_BLOCKED_INFO`)}</Tooltip>)
    return (
      <div className="campaigns-panel dossier-panel">
        <div className="header-panel" onClick={() => this.toggleComponent()}>
          <div className="name-wrapper">
            <i className="ico-campaing"/>
            <h4>{t(`${tKey}TITLE_PANEL`)}</h4>
          </div>
          <i className={this.state.open ? 'ico-arrow-black-up' : 'ico-arrow-black-down'}/>
        </div>
        <Panel expanded={this.state.open} onToggle={() => {}}>
          <Panel.Collapse>
            <Col sm={12} className="col-row">
              <Col sm={6}>
                {this.state.fieldsConfiguration.campaignsBlocked && this.state.fieldsConfiguration.campaignsBlocked.permission !== permissions.hidden && (
                  <>
                    <Field
                      colSm={4}
                      id="campaignsBlocked"
                      name="salesComponent.campaignsBlocked"
                      controlLabel={t(`${tKey}CAMPAIGNS_BLOCKED`)}
                      placeholder={t(`${tKey}CAMPAIGNS_BLOCKED`)}
                      inline
                      component={InputCheckBox}
                      disabled={readOnlyFields || this.state.fieldsConfiguration.campaignsBlocked.permission !== permissions.editable}
                      customClass="checkbox-inline-camp"
                    />
                    <OverlayTrigger placement="right" overlay={blockTooltip}
                      colSm={1}>
                      <Glyphicon glyph="info-sign"/>
                    </OverlayTrigger>
                  </>
                )}
              </Col>
              {this.state.fieldsConfiguration.downloadButton.permission !== permissions.hidden && (
                <Col sm={6} style={{ textAlign: 'right' }}>
                  <Button
                    className="btn-white btn-up-right"
                    id="generate-zip-button"
                    style={{ fontSize: '11px' }}
                    onClick={() => openSearchDocumentsModal(openCampaignDocument)}
                  >
                    {t(`${tKey}ZIP_BTN`)}
                  </Button>
                </Col>
              )}
              <Table className="editable-table" hover responsive>
                <thead>
                  <tr>
                    <th>{t(`${tKey}EXP_NUMBER`)}</th>
                    <th>{t(`${tKey}NAME`)}</th>
                    <th>{t(`${tKey}TYPE`)}</th>
                    <th>{t(`${tKey}AMOUNT`)}</th>
                    <th>{t(`${tKey}COST`)}</th>
                    <th>{t(`${tKey}COST_CONCESSIONAIRE`)}</th>
                    <th>{t(`${tKey}STATUS`)}</th>
                    <th>{t(`${tKey}DOCUMENTS`)}</th>
                    <th style={{ width: '77px' }}>{t('DOSSIER_COMPONENTS.ACCESSORIES.ACTIONS')}</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    campaigns.map((campaign, idx) => {
                      return (
                        <CampaignsRow
                          t={t}
                          tKey={tKey}
                          campaign={campaign}
                          key={idx}
                          openCampaignEdition={openCampaignEdition}
                          fetchCampaignEdition={fetchCampaignEdition}
                          deleteCampaign={deleteCampaign}
                          readOnlyFields={readOnlyFields}
                        />
                      )
                    })
                  }
                  <tr className="button-table-wrapper">
                    <td>
                      {!readOnlyFields && this.state.canAdd &&
                    <Button
                      className="btn-editable-table btn-standard"
                      id="add-button"
                      onClick={() => fetchCampaignEdition(null, openCampaignEdition)}
                      disabled={this.state.fieldsConfiguration.addCampaignButton && this.state.fieldsConfiguration.addCampaignButton.permission !== permissions.editable}
                    >
                      + {t(`${tKey}ADD_BTN`)}
                    </Button>
                      }
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <CustomFields customFields={customFields} readOnlyFields={readOnlyFields} />
          </Panel.Collapse>
        </Panel>
        <SmallLoaderPage/>
      </div>
    )
  }
}
export default Campaigns
