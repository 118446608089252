import React, { PureComponent } from 'react'
import { Col, Panel, Row, Button } from 'react-bootstrap'
import InputDatePicker from '../../commons/form/InputDatePicker'
import { Field } from 'redux-form'
import { getFieldsPermisions } from '../../../util/permisionFunctions'
import { compareObjects } from '../../../util/utils'
import { permissions, dateType } from '../../../constants/backendIds'
import moment from 'moment'

class Checklist extends PureComponent {
  constructor () {
    super()
    this.state = {
      open: false,
      fieldsConfiguration: {
        confirmationDoneButton: null,
        confirmationDate: null
      }
    }
  }

  initializePermisions (props) {
    const fieldKeys = Object.keys(this.state.fieldsConfiguration)
    const newState = getFieldsPermisions(props.componentConfiguration.fieldsConfiguration, fieldKeys, props.componentConfiguration.permission)
    this.setState({ fieldsConfiguration: newState.fieldsConfiguration })
  }

  componentDidUpdate (prevProps) {
    if (this.props.dossier && this.props.dossier.dossierId) {
      if (!compareObjects(prevProps.componentConfiguration || {}, this.props.componentConfiguration, true)) {
        this.initializePermisions(this.props)
      }
      if (this.props.open) {
        this.setState({ open: true })
      }
    }
    return prevProps
  }

  componentDidMount () {
    if (this.props.componentConfiguration && this.props.componentConfiguration !== null) {
      this.initializePermisions(this.props)
    }
  }

  permissionButon () {
    return !this.props.historical &&
    isNaN((moment(this.props.dossier?.dossierChecklistComponent?.confirmationDate, dateType.standard).toDate())) &&
    this.state.fieldsConfiguration?.confirmationDoneButton && this.state.fieldsConfiguration.confirmationDoneButton.permission !== permissions.hidden &&
    this.props.dossier?.checkListHeader?.find(x => x.name === 'Checklist')?.docs?.filter(x => !x.isComplete).length < 1
  }

  fetchSalesDossierPromiseChecklist () {
    if (this.state.open) {
      this.props.actions.setActiveSection(false)
    }
    this.setState({
      open: !this.state.open
    })
  }

  render () {
    const { t, actions: { confirmDateWithIdByStage }, dossierId } = this.props
    const tKey = 'DOSSIER_COMPONENTS.CHECKLIST_COMPONENT.'
    return (
      <Row className='checklist-panel dossier-panel'>
        <div
          className='header-panel' onClick={this.fetchSalesDossierPromiseChecklist.bind(this)}
        >
          <div className='name-wrapper'>
            <i className='ico-i' />
            <h4>{t(`${tKey}TITLE_PANEL`)}</h4>
          </div>
          <i
            className={
              this.state.open ? 'ico-arrow-black-up' : 'ico-arrow-black-down'
            }
          />
        </div>
        <Panel expanded={this.state.open} onToggle={() => {}}>
          <Panel.Collapse>
            {
              this.permissionButon() && (
                <Row>
                  <Col className="col-right">
                    <Button className='btn-save'
                      onClick={() => confirmDateWithIdByStage(dossierId)}
                      disabled={this.state.fieldsConfiguration.confirmationDoneButton.permission !== permissions.editable}
                    >
                      {t(`${tKey}DONE_BUTTON`)}
                    </Button>
                  </Col>
                </Row>
              )
            }
            <Col>
              <br/>
              {
                this.state.fieldsConfiguration.confirmationDate && this.state.fieldsConfiguration.confirmationDate.permission !== permissions.hidden && (
                  <Row>
                    { (
                      <Field
                        id="confirmationDate"
                        key="confirmationDate"
                        name="dossierChecklistComponent.confirmationDate"
                        colSm={4}
                        controlLabel={t(`${tKey}CONFIRM_DATE`)}
                        placeholder={t(`${tKey}CONFIRM_DATE`)}
                        disabled={true}
                        component={InputDatePicker}
                      />
                    )}
                  </Row>
                )}
            </Col>
          </Panel.Collapse>
        </Panel>
      </Row>
    )
  }
}
export default Checklist
