import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import EconomicFleetModal from '../../components/management/EconomicFleetModal'
import { translate } from 'react-polyglot'
import {
  changeEntityDossierFromFleet, resetFiltersFleetInfo, fetchFleetsInfo, fetchFleetsEntity, closeEconomicFleetModal, fetchOperateDataFleetModalsPermissions,
  fetchEconomicPlanCombos, getEconomicPlanPermissions, simulateEconomicPlanFleet, addCampaign, saveEconomicPlanFleet, deleteEconomicCampaign, setSimulatedFlag,
  getDossierDetailsFleet, changeCodeCommissionFleet
} from '../../actions/management/management'
import { fetchSalesTypeCombo } from '../../actions/management/auxModals/auxModals'
import { openModal, openModal as openCommonModal, yesNoModal } from '../../actions/common'
import { clearEntity, clearEntityChild } from '../../actions/dossier/common/client'
import { fetchEntityFilterSubTypeCombo, fetchEntitySubTypeCombo } from '../../actions/combos/combos'
import dossierSalesActions from '../../actions/dossier/sales'
import commentsActions from '../../actions/dossier/common/comments'
import buttonsSidebarActions from '../../actions/dossier/common/buttonsSidebar'
import scrollerActions from '../../actions/commons/scroller'
import { setEconomicPlanFleetProcess } from '../../actions/unattendedProcess/unattendedProcess'
import { openCodeCommissionModal } from '../../actions/dossier/common/codeCommisionModal'
import { openErrorMessageModal } from '../../actions/modals/messageModal'

export function mapStateToProps (state) {
  return {
    ...state.management.economicFleetModal,
    fleetFinderFilter: state.management.searchFleetInfo.dynamicFilters,
    dniCif: state.management.dniCif,
    dossierFleetDetails: state.management.dossierFleetDetails,
    combos: state.combos,
    cancelCombo: state.combos.cancelCombo,
    economicPlanModal: state.management.economicPlanModal
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      openModal,
      changeEntityDossierFromFleet,
      resetFiltersFleetInfo,
      fetchFleetsInfo,
      fetchFleetsEntity,
      clearEntity,
      clearEntityChild,
      closeEconomicFleetModal,
      fetchOperateDataFleetModalsPermissions,
      fetchSalesTypeCombo,
      fetchEconomicPlanCombos,
      getEconomicPlanPermissions,
      simulateEconomicPlanFleet,
      addCampaign,
      saveEconomicPlanFleet,
      deleteEconomicCampaign,
      setSimulatedFlag,
      fetchEntityFilterSubTypeCombo,
      fetchEntitySubTypeCombo,
      openCommonModal,
      yesNoModal,
      updateOptional: (dossierExtra, resolve, reject, dossierExtraType) => dossierSalesActions.updateExtra(dossierExtra, resolve, reject, dossierExtraType),
      createOptional: (dossierExtra, resolve, reject, dossierExtraType) => dossierSalesActions.createExtra(dossierExtra, resolve, reject, dossierExtraType),
      deleteOptional: (dossierExtra, dossierExtraType) => dossierSalesActions.deleteExtra(dossierExtra, dossierExtraType),
      ...dossierSalesActions,
      ...commentsActions,
      ...buttonsSidebarActions,
      ...scrollerActions,
      setEconomicPlanFleetProcess,
      getDossierDetailsFleet,
      openCodeCommissionModal,
      changeCodeCommissionFleet,
      openErrorMessageModal
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(EconomicFleetModal))
