import React, { PureComponent } from 'react'
import { Col, Row, Glyphicon } from 'react-bootstrap'

class HotFolderReport extends PureComponent {
  render () {
    const { t, documentList } = this.props
    return (
      <div className="hot-folder-report">
        <h4>{t('MESSAGES.HOTFOLDER.LOAD_FILES_REPORT')}</h4>
        {documentList !== undefined && documentList !== null && documentList.map((document, idx) => {
          return (<Row key={idx}>
            <Col sm={12}>
              {document.isCorrect && (
                <div className="document-correct"><span className="text-center">{document.documentName}</span> <Glyphicon style={{ float: 'right' }} glyph="ok-sign"/></div>)}
              {!document.isCorrect && (
                <div className="document-error" ><span className="text-center">{document.documentName}</span> <Glyphicon style={{ float: 'right' }} glyph="remove"/></div>)}
            </Col>
          </Row>
          )
        })}
        {documentList !== undefined && documentList !== null && documentList.length === 0 && (<p>{t('MESSAGES.HOTFOLDER.NOT_LOAD_FILES')}</p>)}
      </div>
    )
  }
}
export default HotFolderReport
