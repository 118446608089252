import organizedUnitConfigurationMasterActionTypes from '../../../constants/actions/masters/organizedUnitConfiguration/organizedUnitConfigurationMaster'

export function fetchOrganizedUnitConfigurationList (filter) {
  return {
    type: organizedUnitConfigurationMasterActionTypes.FETCH_ORGANIZED_UNIT_CONFIGURATION_LIST,
    filter
  }
}

export function fetchOrganizedUnitConfigurationListSuccess (organizedUnitConfigurationList, filter) {
  return {
    type: organizedUnitConfigurationMasterActionTypes.FETCH_ORGANIZED_UNIT_CONFIGURATION_LIST_SUCCESS,
    organizedUnitConfigurationList,
    filter
  }
}

export function deleteOrganizedUnitConfiguration (organizedUnitConfigurationId) {
  return {
    type: organizedUnitConfigurationMasterActionTypes.DELETE_ORGANIZED_UNIT_CONFIGURATION,
    organizedUnitConfigurationId
  }
}

export function openOrganizedUnitConfigurationModal (organizedUnitConfiguration) {
  return {
    type: organizedUnitConfigurationMasterActionTypes.OPEN_ORGANIZED_UNIT_CONFIGURATION_MODAL,
    organizedUnitConfiguration
  }
}

export function openOrganizedUnitConfigurationModalSuccess (organizedUnitConfiguration) {
  return {
    type: organizedUnitConfigurationMasterActionTypes.OPEN_ADD_ORGANIZED_UNIT_CONFIGURATION_MODAL_SUCCESS,
    organizedUnitConfiguration
  }
}

export function openEditOrganizedUnitConfigurationModalSuccess (organizedUnitConfiguration) {
  return {
    type: organizedUnitConfigurationMasterActionTypes.OPEN_EDIT_ORGANIZED_UNIT_CONFIGURATION_MODAL_SUCCESS,
    organizedUnitConfiguration
  }
}

export function closeOrganizedUnitConfigurationMasterModal () {
  return {
    type: organizedUnitConfigurationMasterActionTypes.CLOSE_EDIT_ORGANIZED_UNIT_CONFIGURATION_MODAL
  }
}

export function submitOrganizedUnitConfiguration (organizedUnitConfiguration) {
  return {
    type: organizedUnitConfigurationMasterActionTypes.SUBMIT_ORGANIZED_UNIT_CONFIGURATION,
    organizedUnitConfiguration
  }
}
