export default {
  INITIALIZE_SEARCH: 'INITIALIZE_SEARCH',
  SEARCH_TAB_CHANGE: 'SEARCH_TAB_CHANGE',
  FETCH_DOSSIERS: 'FETCH_DOSSIERS',
  FETCH_VEHICLES: 'FETCH_VEHICLES',
  FETCH_ENTITIES: 'FETCH_ENTITIES',
  FETCH_RENTING: 'FETCH_RENTING',
  FETCH_SELFSUPPLY: 'FETCH_SELFSUPPLY',
  SALE_SELFSUPPLY_LIST: 'SALE_SELFSUPPLY_LIST',
  FETCH_DOSSIER_FILTER_SUCCESS: 'FETCH_DOSSIER_FILTER_SUCCESS',
  FETCH_VEHICLE_FILTER_SUCCESS: 'FETCH_VEHICLE_FILTER_SUCCESS',
  FETCH_ENTITY_FILTER_SUCCESS: 'FETCH_ENTITY_FILTER_SUCCESS',
  RESET_FILTERS: 'RESET_FILTERS',
  SET_FILTERS: 'SET_FILTERS',
  SET_NO_DYNAMIC_FILTERS: 'SET_NO_DYNAMIC_FILTERS',
  FETCH_CHARGES: 'FETCH_CHARGES',
  FETCH_DOSSIERS_SIMPLE: 'FETCH_DOSSIERS_SIMPLE',
  FETCH_VEHICLES_SIMPLE: 'FETCH_VEHICLES_SIMPLE',
  FETCH_CHARGE_FILTER_SUCCESS: 'FETCH_CHARGE_FILTER_SUCCESS',
  FETCH_CHARGE_RENTING_SUCCESS: 'FETCH_CHARGE_RENTING_SUCCESS',
  FETCH_CHARGE_RENTING_FILTER: 'FETCH_CHARGE_RENTING_FILTER',
  SALE_PAY_RENTING: 'SALE_PAY_RENTING',
  SALE_PAY_RENTING_LIST: 'SALE_PAY_RENTING_LIST',
  FETCH_FLEETS: 'FETCH_FLEETS',
  FETCH_FLEETS_SUCCESS: 'FETCH_FLEETS_SUCCESS',
  FETCH_SELFSUPPLY_SUCCESS: 'FETCH_SELFSUPPLY_SUCCESS',
  FETCH_DOSSIERS_DOWNLOAD: 'FETCH_DOSSIERS_DOWNLOAD',
  FETCH_VEHICLES_DOWNLOAD: 'FETCH_VEHICLES_DOWNLOAD',
  FETCH_ENTITIES_DOWNLOAD: 'FETCH_ENTITIES_DOWNLOAD',
  FETCH_DOSSIERS_SIMPLE_DOWNLOAD: 'FETCH_DOSSIERS_SIMPLE_DOWNLOAD',
  FETCH_VEHICLES_SIMPLE_DOWNLOAD: 'FETCH_VEHICLES_SIMPLE_DOWNLOAD',
  FETCH_RENTING_DOWNLOAD: 'FETCH_RENTING_DOWNLOAD',
  FETCH_SELFSUPPLY_DOWNLOAD: 'FETCH_SELFSUPPLY_DOWNLOAD',
  FETCH_CHARGES_DOWNLOAD: 'FETCH_CHARGES_DOWNLOAD',
  FETCH_FLEETS_DOWNLOAD: 'FETCH_FLEETS_DOWNLOAD',
  FETCH_CAMPAIGN_EXPORT: 'FETCH_CAMPAIGN_EXPORT',
  FETCH_CAMPAIGN_EXPORT_SUCCES: 'FETCH_CAMPAIGN_EXPORT_SUCCES',
  CHANGE_EXPORT_CAMPAIGN_EXPORT: 'CHANGE_EXPORT_CAMPAIGN_EXPORT',
  LAUCH_EXPORT_CAMPAIGN: 'LAUCH_EXPORT_CAMPAIGN',
  LAUCH_EXPORT_CAMPAIGN_EXCEL: 'LAUCH_EXPORT_CAMPAIGN_EXCEL',
  FETCH_REGISTER_ORDER: 'FETCH_REGISTER_ORDER',
  FETCH_REGISTER_ORDER_SUCCESS: 'FETCH_REGISTER_ORDER_SUCCESS',
  FETCH_REGISTER_ORDER_DOWNLOAD: 'FETCH_REGISTER_ORDER_DOWNLOAD',
  FETCH_FTP_REGISTER: 'FETCH_FTP_REGISTER',
  FETCH_FTP_REGISTER_SUCCESS: 'FETCH_FTP_REGISTER_SUCCESS',
  FETCH_FTP_REGISTER_EXPORT: 'FETCH_FTP_REGISTER_EXPORT',
  FETCH_SERVICE_PREPARE: 'FETCH_SERVICE_PREPARE',
  FETCH_SERVICE_PREPARE_SUCCESS: 'FETCH_SERVICE_PREPARE_SUCCESS',
  FETCH_SERVICE_PREPARE_DOWNLOAD: 'FETCH_SERVICE_PREPARE_DOWNLOAD',
  FETCH_SERVICE_PREPARE_ROLE_CONFIG: 'FETCH_SERVICE_PREPARE_ROLE_CONFIG',
  FETCH_SERVICE_PREPARE_ROLE_CONFIG_SUCCESS: 'FETCH_SERVICE_PREPARE_ROLE_CONFIG_SUCCESS',
  FETCH_DOSSIER_FLEETS: 'FETCH_DOSSIER_FLEETS',
  FETCH_DOSSIER_FLEETS_SUCCESS: 'FETCH_DOSSIER_FLEETS_SUCCESS',
  FETCH_DOSSIER_FLEETS_DOWNLOAD: 'FETCH_DOSSIER_FLEETS_DOWNLOAD',
  OPEN_SELF_SUPPLY_MODAL: 'OPEN_SELF_SUPPLY_MODAL',
  CLOSE_SELF_SUPPLY_MODAL: 'CLOSE_SELF_SUPPLY_MODAL',
  SET_FLEETS_MANAGEMENT_FILTERS: 'SET_FLEETS_MANAGEMENT_FILTERS',
  RESET_FLEETS_MANAGEMENT_FILTERS: 'RESET_FLEETS_MANAGEMENT_FILTERS',
  FETCH_REPORT_COMMERCIAL: 'FETCH_REPORT_COMMERCIAL',
  FETCH_REPORT_COMMERCIAL_SUCCESS: 'FETCH_REPORT_COMMERCIAL_SUCCESS',
  FETCH_REPORT_COMMERCIAL_DOWNLOAD: 'FETCH_REPORT_COMMERCIAL_DOWNLOAD',
  FETCH_REPORT_COMMERCIAL_TABLE: 'FETCH_REPORT_COMMERCIAL_TABLE',
  FETCH_REPORT_COMMERCIAL_TABLE_SUCCESS: 'FETCH_REPORT_COMMERCIAL_TABLE_SUCCESS',
  CLOSE_REPORT_COMMERCIAL_TABLE_MODAL: 'CLOSE_REPORT_COMMERCIAL_TABLE_MODAL',
  EXPORT_REPORT_COMMERCIAL_TABLE: 'EXPORT_REPORT_COMMERCIAL_TABLE',
  FETCH_RECURRENT_ENTITIES: 'FETCH_RECURRENT_ENTITIES',
  FETCH_PAP_SERVICES_FROM_MANAGER: 'FETCH_PAP_SERVICES_FROM_MANAGER',
  FETCH_PAP_SERVICES_FROM_MANAGER_SUCCESS: 'FETCH_PAP_SERVICES_FROM_MANAGER_SUCCESS',
  FETCH_PAP_SERVICES_DOWNLOAD_FROM_MANAGER: 'FETCH_PAP_SERVICES_DOWNLOAD_FROM_MANAGER',
  FETCH_PLAN_LOGISTICS_PAP: 'FETCH_PLAN_LOGISTICS_PAP',
  FETCH_PLAN_LOGISTICS_PAP_SUCCESS: 'FETCH_PLAN_LOGISTICS_PAP_SUCCESS',
  FETCH_PROCESSING_AGENCIES: 'FETCH_PROCESSING_AGENCIES',
  FETCH_PROCESSING_AGENCIES_SUCCESS: 'FETCH_PROCESSING_AGENCIES_SUCCESS',
  FETCH_PROCESSING_AGENCIES_DOWNLOAD: 'FETCH_PROCESSING_AGENCIES_DOWNLOAD',
  FETCH_FLEET_REPORT: 'FETCH_FLEET_REPORT',
  FETCH_FLEET_REPORT_SUCCESS: 'FETCH_FLEET_REPORT_SUCCESS',
  FETCH_FLEET_REPORT_DOWNLOAD: 'FETCH_FLEET_REPORT_DOWNLOAD',
  FETCH_STOCK_MANAGEMENT_DOSSIERS: 'FETCH_STOCK_MANAGEMENT_DOSSIERS',
  FETCH_STOCK_MANAGEMENT_DOSSIERS_SUCCESS: 'FETCH_STOCK_MANAGEMENT_SUCCESS',
  FETCH_STOCK_MANAGEMENT_DOSSIERS_DOWNLOAD: 'FETCH_STOCK_MANAGEMENT_DOWNLOAD',
  SEARCH_SECTION_CHANGE: 'SEARCH_SECTION_CHANGE',
  SET_HIDE_FILTERS: 'SET_HIDE_FILTERS',
  SET_FIELDS_SUCCESS: 'SET_FIELDS_SUCCESS'
}
