import Settings from './settings'

export default {
  ...Settings,
  urlHotFolder: 'https://hotfolder.idoccar.com/api/',
  webSocketUrl: 'wss://test-vgrs.idoccar.com:8007',
  notificationRefresh: 60000,
  showSignDocumentButton: false,
  acceptFileFormats: 'application/pdf, .jpg, .png, .jpeg, .gif, .docx, .doc, .xls, .xlsx, .ppt, .pptx, .msg, .tiff',
  qaSite: true,
  dealerMaxLength: 7,
  dealerNumberRegex: null,
  clientFieldValidation: 'vgr',
  autoLoadDashboard: false,
  validateLicensePlate: true,
  matomoSiteId: '17',
  orderDateLimitHour: '12',
  enableFavoritesAndRecents: true,
  enableTaskFollowUp: true,
  dashboardVersion: 2,
  environmentName: 'test-vgrs',
  paymentGatewayUrl: 'https://canales.redsys.es/admincanales-web/index.jsp#/login'
}
