import { put, call, select, takeLatest } from 'redux-saga/effects'
import { SubmissionError } from 'redux-form'
import { getAuth } from '../../../selectors/access/auth'
import { showLoader, hideLoader } from '../../../actions/common'
import { closeUnitModal, fetchUnitSuccess, updateFilter } from '../../../actions/unit/unit'
import unitActionTypes from '../../../constants/actions/unit/unit'
import postUnit from '../../../services/unit/postUnit'
import getUnits from '../../../services/unit/getUnitList'
import { handleError } from '../../errors/errorManager'

export function * saveNewUnit ({ data, isEditModal, resolve, reject }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    if (isEditModal) {
      // TODO PUT
    } else {
      yield call(postUnit, { ...data, userId: auth.userId }, auth.token)
      yield call(resolve)
    }
    const filter = {
      company: null,
      unitName: '',
      page: 1,
      pageSize: 2,
      orderBy: 'name'
    }
    const response = yield call(getUnits, {
      token: auth.token,
      companyId: filter.company ? filter.company.companyId : null,
      name: filter.unitName || null,
      orderBy: filter.orderBy || null,
      page: filter.page || null,
      pageSize: filter.pageSize || null
    })
    yield put(fetchUnitSuccess(response))
    yield put(updateFilter(filter))
    yield put(closeUnitModal())
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      // TODO read tag for repeat UO name
      const submitError = customHandlerError && customHandlerError.json && customHandlerError.json.tag === 'createUnitError'
        // ? {unitName: 'error'}
        ? { _error: 'MESSAGES.FETCH_DATA_FAILED' }
        : { _error: 'MESSAGES.FETCH_DATA_FAILED' }
      yield call(reject, new SubmissionError(submitError))
    }
    yield call(handleError, { error, customHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSaveNewUnit () {
  yield takeLatest(unitActionTypes.SAVE_NEW_UNIT, saveNewUnit)
}
