import combosActionTypes from '../../constants/actions/combos/combos'

export function fetchPuscharseSaleEntitys (organizedUnitId) {
  return {
    type: combosActionTypes.FETCH_PUSCHARSE_SALE_ENTITYS,
    organizedUnitId
  }
}
export function fetchAuditCodes () {
  return {
    type: combosActionTypes.FETCH_AUDIT_CODES
  }
}
export function fetchAuditLoadData () {
  return {
    type: combosActionTypes.FETCH_AUDIT_LOAD_DATA
  }
}
export function fetchCombosSuccess (data) {
  return {
    type: combosActionTypes.FETCH_COMBOS_SUCCESS,
    data
  }
}

export function fetchEntitySubTypeCombo (entityTypeId) {
  return {
    type: combosActionTypes.FETCH_ENTITY_SUBTYPE_COMBO,
    entityTypeId
  }
}

export function fetchEntityCountryTypeCombo (resolve) {
  return {
    type: combosActionTypes.FETCH_ENTITY_COUNTRY_COMBO,
    resolve
  }
}

export function fetchEntityTownTypeCombo (cityId, resolve) {
  return {
    type: combosActionTypes.FETCH_ENTITY_TOWN_COMBO,
    cityId,
    resolve
  }
}

export function fetchEntityColonyTypeCombo (townId, resolve) {
  return {
    type: combosActionTypes.FETCH_ENTITY_COLONY_COMBO,
    townId,
    resolve
  }
}

export function fetchEntityCityTypeCombo (stateId, resolve) {
  return {
    type: combosActionTypes.FETCH_ENTITY_CITY_COMBO,
    stateId,
    resolve
  }
}

export function fetchEntityCPTypeCombo (colonyId, resolve) {
  return {
    type: combosActionTypes.FETCH_ENTITY_CP_COMBO,
    colonyId,
    resolve
  }
}

export function fetchEntityStateTypeCombo (countryId, resolve) {
  return {
    type: combosActionTypes.FETCH_ENTITY_STATE_COMBO,
    countryId,
    resolve
  }
}

export function fetchEntityMaritalStatusCombo (countryId) {
  return {
    type: combosActionTypes.FETCH_MARITAL_STATUS_COMBO,
    countryId
  }
}

export function fetchUserCombo () {
  return {
    type: combosActionTypes.FETCH_USER_COMBO
  }
}

export function fetchUserComboSuccess (data) {
  return {
    type: combosActionTypes.FETCH_USER_COMBO_SUCCESS,
    data
  }
}

export function fetchCampaignTypeByGroup (groupId) {
  return {
    type: combosActionTypes.FETCH_CAMPAIGN_TYPE_BY_GROUP,
    groupId
  }
}

export function fetchCampaignTypeByGroupSuccess (data) {
  return {
    type: combosActionTypes.FETCH_CAMPAIGN_TYPE_BY_GROUP_SUCCESS,
    data
  }
}

export function fetchCampaignClass () {
  return {
    type: combosActionTypes.FETCH_CAMPAIGN_CLASS
  }
}

export function fetchCampaignClassSuccess (data) {
  return {
    type: combosActionTypes.FETCH_CAMPAIGN_CLASS_SUCCESS,
    data
  }
}

export function fetchCampaignGroup (classId) {
  return {
    type: combosActionTypes.FETCH_CAMPAIGN_GROUP,
    classId
  }
}

export function fetchFleetsCombo () {
  return {
    type: combosActionTypes.FETCH_FLEETS_COMBO
  }
}

export function fetchFleetsComboSuccess (data) {
  return {
    type: combosActionTypes.FETCH_FLEETS_COMBO_SUCCESS,
    data
  }
}

export function fetchCampaignGroupSuccess (data) {
  return {
    type: combosActionTypes.FETCH_CAMPAIGN_GROUP_SUCCESS,
    data
  }
}

export function fetchEntityFilterSubTypeCombo (entityTypeId, entitySubTypeId, organizedUnitId) {
  return {
    type: combosActionTypes.FETCH_ENTITY_FILTER_SUBTYPE_COMBO,
    entityTypeId,
    entitySubTypeId,
    organizedUnitId
  }
}

export function fetchDossierTypes (ouId) {
  return {
    type: combosActionTypes.FETCH_DOSSIER_TYPES,
    ouId
  }
}

export function fetchExtraSalesTypes (ouId) {
  return {
    type: combosActionTypes.FETCH_EXTRA_SALE_TYPES,
    ouId
  }
}

export function fetchExtraSaleTypesSuccess (data) {
  return {
    type: combosActionTypes.FETCH_EXTRA_SALE_TYPES_SUCCESS,
    data
  }
}

export function fetchPresentTypesSuccess (data) {
  return {
    type: combosActionTypes.FETCH_PRESENT_TYPES_SUCCESS,
    data
  }
}

export function fetchPurchasePresentTypesSuccess (data) {
  return {
    type: combosActionTypes.FETCH_PURCHASE_PRESENT_TYPES_SUCCESS,
    data
  }
}

export function fetchSuppliedTypesSuccess (data) {
  return {
    type: combosActionTypes.FETCH_SUPPLIED_SUCCESS,
    data
  }
}

export function fetchDossierTypesSuccess (data) {
  return {
    type: combosActionTypes.FETCH_DOSSIER_TYPES_SUCCESS,
    data
  }
}

export function fetchDossierSubTypes (ouId, dossierTypeId, getOnlySelected = [], resolve, reject) {
  return {
    type: combosActionTypes.FETCH_DOSSIER_SUBTYPES,
    ouId,
    dossierTypeId,
    getOnlySelected,
    resolve,
    reject
  }
}

export function fetchDossierSubTypesSuccess (data) {
  return {
    type: combosActionTypes.FETCH_DOSSIER_SUBTYPES_SUCCESS,
    data
  }
}

export function fetchLowestOrganizedUnits (userId) {
  return {
    type: combosActionTypes.FETCH_LOWEST_ORGANIZED_UNITS,
    userId
  }
}

export function fetchLowestOrganizedUnitsSuccess (data) {
  return {
    type: combosActionTypes.FETCH_LOWEST_ORGANIZED_UNITS_SUCCESS,
    data
  }
}

export function fetchEntityTypesSuccess (data) {
  return {
    type: combosActionTypes.FETCH_ENTITY_TYPES_SUCCESS,
    data
  }
}

export function fetchSaleType (ouId) {
  return {
    type: combosActionTypes.FETCH_SALE_TYPE,
    ouId
  }
}

export function fetchSaleTypeByOperationType (operationTypeId, ouId, dossierSubtypeId) {
  return {
    type: combosActionTypes.FETCH_SALE_TYPE_BY_OPERATION_TYPE,
    ouId,
    operationTypeId,
    dossierSubtypeId
  }
}

export function fetchSaleTypeSuccess (data) {
  return {
    type: combosActionTypes.FETCH_SALE_TYPE_SUCCESS,
    data
  }
}

export function fetchSaleTypeBySubtype (dossierSubTypeId, clearCombo = false) {
  return {
    type: combosActionTypes.FETCH_SALE_TYPE_BY_SUBTYPE,
    dossierSubTypeId,
    clearCombo
  }
}

export function fetchSaleTypeBySubtypeSuccess (data) {
  return {
    type: combosActionTypes.FETCH_SALE_TYPE_BY_SUBTYPE_SUCCESS,
    data
  }
}

export function fetchPurchaseType (dossierSubTypeId) {
  return {
    type: combosActionTypes.FETCH_PURCHASE_TYPE,
    dossierSubTypeId
  }
}

export function fetchPurchaseTypeSuccess (data) {
  return {
    type: combosActionTypes.FETCH_PURCHASE_TYPE_SUCCESS,
    data
  }
}

export function fetchTuneUpDestination (tuneUpDestinationId) {
  return {
    type: combosActionTypes.FETCH_TUNE_UP_DESTINATION,
    tuneUpDestinationId
  }
}

export function fetchTuneUpDestinationSuccess (data) {
  return {
    type: combosActionTypes.FETCH_TUNE_UP_DESTINATION_SUCCESS,
    data
  }
}

export function fetchPaperworkType (dossierSubTypeId) {
  return {
    type: combosActionTypes.FETCH_PAPERWORK_TYPE,
    dossierSubTypeId
  }
}

export function fetchPaperworkTypeSuccess (data) {
  return {
    type: combosActionTypes.FETCH_PAPERWORK_TYPE_SUCCESS,
    data
  }
}

export function fetchCampaignType (ouId) {
  return {
    type: combosActionTypes.FETCH_CAMPAIGN_TYPE,
    ouId
  }
}

export function fetchCampaignTypeSuccess (data) {
  return {
    type: combosActionTypes.FETCH_CAMPAIGN_TYPE_SUCCESS,
    data
  }
}

export function fetchSalesman (organizedUnitId) {
  return {
    type: combosActionTypes.FETCH_SALESMAN,
    organizedUnitId
  }
}

export function fetchOrderTypeSuccess (data) {
  return {
    type: combosActionTypes.FETCH_ORDER_TYPE_SUCCESS,
    data
  }
}

export function fetchOperationType (saleTypeId, ouId, isModal, isFleet) {
  return {
    type: combosActionTypes.FETCH_OPERATION_TYPE,
    saleTypeId,
    ouId,
    isModal,
    isFleet
  }
}

export function fetchFleetOperationTypeForAutoselect (organizedUnitId) {
  return {
    type: combosActionTypes.FETCH_FLEET_OPERATION_TYPE_FOR_AUTOSELECT,
    organizedUnitId
  }
}

export function fetchChecklistCampaign (campaignTypeId, ouId) {
  return {
    type: combosActionTypes.FETCH_CHECKLIST_CAMPAIHG,
    campaignTypeId,
    ouId
  }
}

export function fetchOperationTypeSuccess (data) {
  return {
    type: combosActionTypes.FETCH_OPERATION_TYPE_SUCCESS,
    data
  }
}

export function fetchOperationTypeModalSuccess (data) {
  return {
    type: combosActionTypes.FETCH_OPERATION_TYPE_MODAL_SUCCESS,
    data
  }
}

export function fetchChanelSourceSuccess (data) {
  return {
    type: combosActionTypes.FETCH_CHANEL_SOURCE_SUCCESS,
    data
  }
}

export function fetchSegmentationSuccess (data) {
  return {
    type: combosActionTypes.FETCH_SEGMENTATION_SUCCESS,
    data
  }
}

export function fetchDeliveryLocation (organizedUnitId) {
  return {
    type: combosActionTypes.FETCH_DELIVERY_LOCATION,
    organizedUnitId
  }
}

export function fetchDeliveryLocationSuccess (data) {
  return {
    type: combosActionTypes.FETCH_DELIVERY_LOCATION_SUCCESS,
    data
  }
}

export function fetchCollaboratorSaleSuccess (data) {
  return {
    type: combosActionTypes.FETCH_COLLABORATOR_SALE_SUCCESS,
    data
  }
}

export function fetchFinancialInstitutionSuccess (data) {
  return {
    type: combosActionTypes.FETCH_FINANCIAL_INSTITUTION_SUCCESS,
    data
  }
}

export function fetchFinancingType (financialInstitutionId, page) {
  return {
    type: combosActionTypes.FETCH_FINANCING_TYPE,
    financialInstitutionId,
    page
  }
}

export function fetchFinancingTypeSuccess (data, page) {
  return {
    type: combosActionTypes.FETCH_FINANCING_TYPE_SUCCESS,
    data,
    page
  }
}

export function fetchIssueEntity () {
  return {
    type: combosActionTypes.FETCH_ISSUE_ENTITY
  }
}

export function fetchIssueEntitySuccess (data) {
  return {
    type: combosActionTypes.FETCH_ISSUE_ENTITY_SUCCESS,
    data
  }
}

export function fetchFinancingCompanies () {
  return {
    type: combosActionTypes.FETCH_COMPANIES
  }
}

export function fetchFinancingCompaniesBy (organizedUnitId) {
  return {
    type: combosActionTypes.FETCH_COMPANIES_BY_OU,
    organizedUnitId
  }
}

export function fetchFinancingCompaniesSucces (data) {
  return {
    type: combosActionTypes.FETCH_COMPANIES_SUCCESS,
    data
  }
}

export function fetchSalesmanSuccess (data) {
  return {
    type: combosActionTypes.FETCH_SALESMAN_SUCCESS,
    data
  }
}

export function fetchPaymentMethodCombo (dossierSubtypeId) {
  return {
    type: combosActionTypes.FETCH_PAYMENT_METHOD_COMBO,
    dossierSubtypeId
  }
}

export function fetchPaymentMethodSuccess (data) {
  return {
    type: combosActionTypes.FETCH_PAYMENT_METHOD_SUCCESS,
    data
  }
}

export function fetchEntityTypes () {
  return {
    type: combosActionTypes.FETCH_ENTITY_TYPES
  }
}

export function fetchStagesCombo (dossierSubtypeId, ignoreStoredAndCancelled = false) {
  return {
    type: combosActionTypes.FETCH_STAGES_COMBO,
    dossierSubtypeId,
    ignoreStoredAndCancelled
  }
}

export function fetchLanguagesCombo () {
  return {
    type: combosActionTypes.FETCH_LANGUAGES_COMBO
  }
}

export function fetchPaymentMethodCampaign (includeDeletes = false) {
  return {
    type: combosActionTypes.FETCH_PAYMENT_METHOD_CAMPAIGN,
    includeDeletes
  }
}

export function fetchPaymentMethodCampaignSuccess (data) {
  return {
    type: combosActionTypes.FETCH_PAYMENT_METHOD_CAMPAIGN_SUCCESS,
    data
  }
}

export function fetchServiceTypeComboSuccess () {
  return {
    type: combosActionTypes.FETCH_DOSSIER_SUBTYPES_BY_OU
  }
}

export function fetchOrganizedUnitsTree () {
  return {
    type: combosActionTypes.FETCH_ORGANIZED_UNITS_TREE
  }
}

export function fetchOrganizedUnitsTreeSuccess (data) {
  return {
    type: combosActionTypes.FETCH_ORGANIZED_UNITS_TREE_SUCCESS,
    data
  }
}

export function fetchOrganizedUnitsCombo () {
  return {
    type: combosActionTypes.FETCH_ORGANIZED_UNITS_COMBO
  }
}

export function fetchOrganizedUnitsComboSuccess (data) {
  return {
    type: combosActionTypes.FETCH_ORGANIZED_UNITS_COMBO_SUCCESS,
    data
  }
}

export function fetchOrganizedUnitsTreeComboByPermission (permissionCode) {
  return {
    type: combosActionTypes.FETCH_ORGANIZED_UNITS_TREE_COMBO_BY_PERMISSION,
    permissionCode
  }
}

export function fetchOrganizedUnitsTreeComboByPermissionSuccess (data) {
  return {
    type: combosActionTypes.FETCH_ORGANIZED_UNITS_TREE_COMBO_BY_PERMISSION_SUCCESS,
    data
  }
}

export function fetchLegalCompanyCombo () {
  return {
    type: combosActionTypes.FETCH_LEGAL_COMPANY_COMBO
  }
}

export function fetchLegalCompanyComboSuccess (data) {
  return {
    type: combosActionTypes.FETCH_LEGAL_COMPANY_COMBO_SUCCESS,
    data
  }
}

export function fetchProcessingAgencyCombo (organizedUnitId) {
  return {
    type: combosActionTypes.FETCH_PROCESSING_AGENCY_COMBO,
    organizedUnitId
  }
}

export function fetchProcessingAgencyComboSuccess (data) {
  return {
    type: combosActionTypes.FETCH_PROCESSING_AGENCY_COMBO_SUCCESS,
    data
  }
}

export function fetchDirectionOUCombo () {
  return {
    type: combosActionTypes.FETCH_DIRECTIION_OU_COMBO
  }
}

export function fetchDirectionOUComboSuccess (data) {
  return {
    type: combosActionTypes.FETCH_DIRECTIION_OU_COMBO_SUCCESS,
    data
  }
}

export function fetchOrganizedUnitsAcceptClientsCombo () {
  return {
    type: combosActionTypes.FETCH_ORGANIZED_UNITS_ACCEPT_CLIENTS_COMBO
  }
}

export function fetchRoleCombo () {
  return {
    type: combosActionTypes.FETCH_ROLES
  }
}

export function fetchRolesSuccess (data) {
  return {
    type: combosActionTypes.FETCH_ROLES_SUCCESS,
    data
  }
}

export function fetchSectionCombo () {
  return {
    type: combosActionTypes.FETCH_SECTION_KEY_VALUE
  }
}

export function fetchSectionComboSuccess ({ data }) {
  return {
    type: combosActionTypes.FETCH_SECTION_KEY_VALUE_SUCCESS,
    data
  }
}

export function fetchDocumentTypeUseCombo () {
  return {
    type: combosActionTypes.FETCH_DOCUMENT_TYPE_USE
  }
}

export function fetchDocumentTypeUseComboSuccess (data) {
  return {
    type: combosActionTypes.FETCH_DOCUMENT_TYPE_USE_SUCCESS,
    data
  }
}

export function fetchSignTypeCombo () {
  return {
    type: combosActionTypes.FETCH_SIGN_TYPE
  }
}

export function fetchSignTypeComboSuccess (data) {
  return {
    type: combosActionTypes.FETCH_SIGN_TYPE_SUCCESS,
    data
  }
}

export function fetchSignEntityTypeCombo () {
  return {
    type: combosActionTypes.FETCH_SIGN_ENTITY_TYPE
  }
}

export function fetchSignEntityTypeComboSuccess (data) {
  return {
    type: combosActionTypes.FETCH_SIGN_ENTITY_TYPE_SUCCESS,
    data
  }
}

export function fetchDocumentTypeCombo () {
  return {
    type: combosActionTypes.FETCH_DOCUMENT_TYPE
  }
}

export function fetchDocumentTypeComboSuccess (data) {
  return {
    type: combosActionTypes.FETCH_DOCUMENT_TYPE_SUCCESS,
    data
  }
}

export function fetchPermissionCombo () {
  return {
    type: combosActionTypes.FETCH_PERMISSION_KEY_VALUE
  }
}

export function fetchPermissionComboSuccess ({ data }) {
  return {
    type: combosActionTypes.FETCH_PERMISSION_KEY_VALUE_SUCCESS,
    data
  }
}

export function fetchDocumentStatusCombo () {
  return {
    type: combosActionTypes.FETCH_DOCUMENT_STATUS
  }
}

export function fetchDocumentStatusComboSuccess (data) {
  return {
    type: combosActionTypes.FETCH_DOCUMENT_STATUS_SUCCESS,
    data
  }
}

export function fetchMassiveDownloadStatusCombo () {
  return {
    type: combosActionTypes.FETCH_MASSIVE_DOWNLOAD_STATUS
  }
}

export function fetchMassiveDownloadStatusComboSuccess (data) {
  return {
    type: combosActionTypes.FETCH_MASSIVE_DOWNLOAD_STATUS_SUCCESS,
    data
  }
}

export function fetchBrands (dossierSubTypeId, organizedUnitId, includeDeleted) {
  return {
    type: combosActionTypes.FETCH_BRANDS,
    dossierSubTypeId,
    organizedUnitId,
    includeDeleted
  }
}

export function fetchVehicleType () {
  return {
    type: combosActionTypes.FETCH_VEHICLETYPE
  }
}

export function fetchSearchDossierSubType () {
  return {
    type: combosActionTypes.FETCH_SEARCH_DOSSIER_SUBTYPES
  }
}

export function fetchTemplateTypes () {
  return {
    type: combosActionTypes.FETCH_TEMPLATE_TYPES
  }
}

export function fetchTemplateFields (fieldTypeId, resolve, reject) {
  return {
    type: combosActionTypes.FETCH_TEMPLATE_FIELDS,
    fieldTypeId,
    resolve,
    reject
  }
}

export function fetchDocumentsAllTypes (documentEntityType = null) {
  return {
    type: combosActionTypes.FETCH_DOCUMENT_ALL_TYPES,
    documentEntityType
  }
}

export function fetchEntitiesByTypeByParent (entityTypeId, parentId) {
  return {
    type: combosActionTypes.FETCH_ENTITIES_BY_TYPE_BY_PARENT,
    entityTypeId,
    parentId
  }
}

export function fetchFunctionToAdd (conditionType, resolve) {
  return {
    type: combosActionTypes.FETCH_FUNCTION_TO_ADD,
    conditionType,
    resolve
  }
}

export function fetchFunctionToAddSuccess (data) {
  return {
    type: combosActionTypes.FETCH_FUNCTION_TO_ADD_SUCCESS,
    data
  }
}

export function fetchFunctionToPass () {
  return {
    type: combosActionTypes.FETCH_FUNCTION_TO_PASS
  }
}

export function fetchFunctionToPassSuccess (data) {
  return {
    type: combosActionTypes.FETCH_FUNCTION_TO_PASS_SUCCESS,
    data
  }
}

export function fetchReasons (dossierSubTypeId) {
  return {
    type: combosActionTypes.FETCH_REASONS,
    dossierSubTypeId
  }
}

export function fetchReasonsSuccess (data) {
  return {
    type: combosActionTypes.FETCH_REASONS_SUCCESS,
    data
  }
}

export function fetchAllReasons (dossierSubTypeId) {
  return {
    type: combosActionTypes.FETCH_ALL_REASONS,
    dossierSubTypeId
  }
}

export function fetchAllReasonsSuccess (data) {
  return {
    type: combosActionTypes.FETCH_ALL_REASONS_SUCCESS,
    data
  }
}

export function fetchAdditionalInfoCombo (dossierSubTypeId) {
  return {
    type: combosActionTypes.FETCH_ADDITIONAL_INFO_COMBO,
    dossierSubTypeId
  }
}

export function fetchVoCompaniesCombo (organizedUnitId) {
  return {
    type: combosActionTypes.FETCH_VO_COMPANIES_COMBO,
    organizedUnitId
  }
}

export function fetchRentingEntityCombo () {
  return {
    type: combosActionTypes.FETCH_RENTING_ENTITY_COMBO
  }
}

export function fetchBankCombo (organizedUnitId) {
  return {
    type: combosActionTypes.FETCH_BANK_COMBO,
    organizedUnitId
  }
}

export function fetchHomologationClassificationCombo () {
  return {
    type: combosActionTypes.FETCH_HOMOLOGATION_CLASIFICATION_COMBO
  }
}

export function fetchHomologationProducerCombo () {
  return {
    type: combosActionTypes.FETCH_HOMOLOGATION_PRODUCER_COMBO
  }
}

export function fetchIaeCombo () {
  return {
    type: combosActionTypes.FETCH_IAE_COMBO
  }
}

export function fetchDocumentRestrictedCombo () {
  return {
    type: combosActionTypes.FETCH_DOCUMENTS_TYPE_RESTRICTED_COMBO
  }
}

export function fetchServiceDestinationTypes (includeDeletedValues = false, includeValue = null) {
  return {
    type: combosActionTypes.FETCH_SERVICE_DESTINATION_TYPES_COMBO,
    includeDeletedValues,
    includeValue
  }
}

export function fetchStockStatusCombo () {
  return {
    type: combosActionTypes.FETCH_STOCK_STATUS_COMBO
  }
}

export function fetchPurchaseOrderTypeCombo () {
  return {
    type: combosActionTypes.FETCH_PURCHASE_ORDER_TYPE_COMBO
  }
}

export function fetchMarginTypesCombo (dossierSubTypeId) {
  return {
    type: combosActionTypes.FETCH_MARGIN_TYPES_COMBO,
    dossierSubTypeId
  }
}

export function fecthOrganizedUnitsByDossierUo (organizedUnitId) {
  return {
    type: combosActionTypes.FETCH_ORGANIZEDUNITS_BY_DOSSIERUO,
    organizedUnitId
  }
}

export function fecthOrganizedUnitsByDossierUoSucces (data) {
  return {
    type: combosActionTypes.FETCH_ORGANIZEDUNITS_BY_DOSSIERUO_SUCCES,
    data
  }
}

export function fetchCountries () {
  return {
    type: combosActionTypes.FETCH_COUNTRY_COMBO
  }
}

export function fetchCountriesSucess (data) {
  return {
    type: combosActionTypes.FETCH_COUNTRY_COMBO_SUCCES,
    data
  }
}
export function fecthFamily () {
  return {
    type: combosActionTypes.FETCH_FAMILY_COMBO
  }
}

export function fecthFamilySucces (data) {
  return {
    type: combosActionTypes.FETCH_FAMILY_COMBO_SUCCES,
    data
  }
}

export function fetchExternalCode () {
  return {
    type: combosActionTypes.FETCH_EXTERNALCODE_COMBO
  }
}

export function fetchExternalCodeSucces (data) {
  return {
    type: combosActionTypes.FETCH_EXTERNALCODE_COMBO_SUCCES,
    data
  }
}

export function fetchFleet (organizedUnitId, includeDeleted, dossierSubTypeId) {
  return {
    type: combosActionTypes.FETCH_FLEET,
    organizedUnitId,
    includeDeleted,
    dossierSubTypeId
  }
}

export function fetchFleetSuccess (data) {
  return {
    type: combosActionTypes.FETCH_FLEET_SUCCESS,
    data
  }
}

export function fetchFleetComponent (fleet) {
  return {
    type: combosActionTypes.FETCH_FLEET_COMPONENT,
    fleet
  }
}

export function fetchFleetsUser (includeDeleted) {
  return {
    type: combosActionTypes.FETCH_FLEETS_USER,
    includeDeleted
  }
}

export function fetchFleetsUserSucces (data) {
  return {
    type: combosActionTypes.FETCH_FLEETS_USER_SUCCES,
    data
  }
}

export function fetchManagementActions (tabId) {
  return {
    type: combosActionTypes.FETCH_MANAGEMENT_ACTIONS,
    tabId
  }
}

export function fetchManagementActionsSuccess (data) {
  return {
    type: combosActionTypes.FETCH_MANAGEMENT_ACTIONS_SUCCESS,
    data
  }
}

export function fetchCancelCombo () {
  return {
    type: combosActionTypes.FETCH_CANCEL_COMBO
  }
}

export function fetchCancelComboSuccess (data) {
  return {
    type: combosActionTypes.FETCH_CANCEL_COMBO_SUCCESS,
    data
  }
}

export function fetchRatingCombo () {
  return {
    type: combosActionTypes.FETCH_RATING_COMBO
  }
}

export function fetchRatingComboByOrganizedUnit (organizedUnitId) {
  return {
    type: combosActionTypes.FETCH_RATING_COMBO_BY_UO,
    organizedUnitId
  }
}

export function fetchRatingComboSuccess (data) {
  return {
    type: combosActionTypes.FETCH_RATING_COMBO_SUCCESS,
    data
  }
}

export function fetchDocumentTypeEntities () {
  return {
    type: combosActionTypes.FETCH_DOCUMENT_TYPE_ENTITIES
  }
}

export function fetchDocumentTypeEntitiesSuccess (data) {
  return {
    type: combosActionTypes.FETCH_DOCUMENT_TYPE_ENTITIES_SUCCESS
  }
}

export function fetchOrganizedUnitsAll () {
  return {
    type: combosActionTypes.FETCH_ORGANIZED_UNITS_ALL
  }
}

export function fetchSaleTypeAll () {
  return {
    type: combosActionTypes.FETCH_SALE_TYPE_ALL
  }
}

export function fetchOperationTypeAll () {
  return {
    type: combosActionTypes.FETCH_OPERATION_TYPE_ALL
  }
}

export function fetchConfigParameterAll () {
  return {
    type: combosActionTypes.FETCH_CONFIG_PARAMETER_ALL
  }
}

export function fetchUnattendedProcessTypeCombo () {
  return {
    type: combosActionTypes.FETCH_UNATTENDED_PROCESS_TYPES
  }
}

export function fetchUnattendedProcessTypeComboSuccess (data) {
  return {
    type: combosActionTypes.FETCH_UNATTENDED_PROCESS_TYPES_SUCCESS,
    data
  }
}

export function fetchUnattendedProcessViewCombo (resolve, reject) {
  return {
    type: combosActionTypes.FETCH_UNATTENDED_PROCESS_VIEW,
    resolve,
    reject
  }
}

export function fetchUnattendedProcessViewComboSuccess (data) {
  return {
    type: combosActionTypes.FETCH_UNATTENDED_PROCESS_VIEW_SUCCESS,
    data
  }
}

export function fetchAllSaleTypeSuccess (data) {
  return {
    type: combosActionTypes.FETCH_ALL_SALE_TYPE_SUCCESS,
    data
  }
}

export function fetchServiceDestinationsComboSuccess (data) {
  return {
    type: combosActionTypes.FETCH_PROCESSING_AGENCY_SERVICE_DESTINATIONS_COMBO_SUCCESS,
    data
  }
}

export function fetchCurrenciesSuccess (data) {
  return {
    type: combosActionTypes.FETCH_CURRENCY_COMBO_SUCCESS,
    data
  }
}

export function fetchCommercialSocietyTypeCombo () {
  return {
    type: combosActionTypes.FETCH_COMMERCIAL_SOCIETY_TYPE_COMBO
  }
}

export function fetchCommercialSocietyTypeComboSuccess (data) {
  return {
    type: combosActionTypes.FETCH_COMMERCIAL_SOCIETY_TYPE_COMBO_SUCCESS,
    data
  }
}

export function fetchOperationTypeDMS () {
  return {
    type: combosActionTypes.FETCH_OPERATION_TYPE_DMS_ALL
  }
}

export function fetchCarlineCombo (brandId) {
  return {
    type: combosActionTypes.FETCH_CARLINE_COMBO,
    brandId
  }
}

export function resetCarlineCombo () {
  return {
    type: combosActionTypes.RESET_CARLINE_COMBO
  }
}

export function fetchCatalogueCombo (carlineId) {
  return {
    type: combosActionTypes.FETCH_CATALOGUE_COMBO,
    carlineId
  }
}

export function resetCatalogueCombo () {
  return {
    type: combosActionTypes.RESET_CATALOGUE_COMBO
  }
}

export function fetchModelCombo (data) {
  return {
    type: combosActionTypes.FETCH_MODEL_YEAR,
    data
  }
}

export function fetchColorCombo (catalogueId, modelId) {
  return {
    type: combosActionTypes.FETCH_COLOR_COMBO,
    catalogueId,
    modelId
  }
}

export function resetColorCombo () {
  return {
    type: combosActionTypes.RESET_COLOR_COMBO
  }
}

export function fetchTapestryCombo (catalogueId, modelId) {
  return {
    type: combosActionTypes.FETCH_TAPESTRY_COMBO,
    catalogueId,
    modelId
  }
}

export function resetTapestryCombo () {
  return {
    type: combosActionTypes.RESET_TAPESTRY_COMBO
  }
}
export function fetchActivityBpro (activityType) {
  return {
    type: combosActionTypes.FETCH_ACTIVITY_BPRO_ALL,
    activityType

  }
}

export function fetchSameLevelOusCombo (organizedUnitId, dossierSubTypeId) {
  return {
    type: combosActionTypes.FETCH_SAME_LEVEL_OUS_COMBO,
    organizedUnitId,
    dossierSubTypeId
  }
}

export function fetchDocumentDataOptions (optionType) {
  return {
    type: combosActionTypes.FETCH_DOCUMENT_DATA_OPTIONS,
    optionType
  }
}

export function fetchCustomFieldTypesCombo () {
  return {
    type: combosActionTypes.FETCH_CUSTOM_FIELD_TYPES_COMBO
  }
}

export function fetchClientDataAddress (entity, auth, resolve) {
  return {
    type: combosActionTypes.FETCH_CLIENT_DATA_ADDRESS,
    entity,
    auth,
    resolve
  }
}

export function fetchAuditConfigurationType () {
  return {
    type: combosActionTypes.FETCH_AUDIT_CONFIGURATION_TYPE
  }
}

export function fetchProcessingAgencyManagersCombo (processingAgencyId) {
  return {
    type: combosActionTypes.FETCH_PROCESSING_AGENCY_MANAGERS_COMBO,
    processingAgencyId
  }
}

export function fetchPurchaseLogisticStateCombo () {
  return {
    type: combosActionTypes.FETCH_PURCHASE_LOGISTIC_STATE_COMBO
  }
}

export function fetchEventsCombo (userId, lowestOrganizedUnitIds) {
  return {
    type: combosActionTypes.FETCH_EVENTS_COMBO,
    userId,
    lowestOrganizedUnitIds
  }
}

export function fetchInsuranceCompanyCombo () {
  return {
    type: combosActionTypes.FETCH_INSURANCE_COMPANY_COMBO
  }
}

export function fetchUserRepairWorkshopsCombo () {
  return {
    type: combosActionTypes.FETCH_USER_REPAIR_WORKSHOPS_COMBO
  }
}

export function fetchFuelCombo (includeDeleted) {
  return {
    type: combosActionTypes.FETCH_FUEL_COMBO,
    includeDeleted
  }
}

export function fetchUsageProcessTypesCombo () {
  return {
    type: combosActionTypes.FETCH_USAGE_PROCESS_TYPES_COMBO
  }
}

export default {
  fetchPuscharseSaleEntitys,
  fetchAuditCodes,
  fetchAuditLoadData,
  fetchCombosSuccess,
  fetchDossierTypes,
  fetchDossierTypesSuccess,
  fetchDossierSubTypes,
  fetchDossierSubTypesSuccess,
  fetchLowestOrganizedUnits,
  fetchLowestOrganizedUnitsSuccess,
  fetchEntityTypesSuccess,
  fetchSaleType,
  fetchSaleTypeSuccess,
  fetchPurchaseType,
  fetchPurchaseTypeSuccess,
  fetchTuneUpDestination,
  fetchTuneUpDestinationSuccess,
  fetchPaperworkType,
  fetchPaperworkTypeSuccess,
  fetchCampaignType,
  fetchCampaignTypeSuccess,
  fetchOperationType,
  fetchOperationTypeSuccess,
  fetchChanelSourceSuccess,
  fetchSegmentationSuccess,
  fetchDeliveryLocationSuccess,
  fetchCollaboratorSaleSuccess,
  fetchFinancialInstitutionSuccess,
  fetchFinancingType,
  fetchFinancingTypeSuccess,
  fetchSalesman,
  fetchSalesmanSuccess,
  fetchPaymentMethodSuccess,
  fetchEntityTypes,
  fetchEntitySubTypeCombo,
  fetchEntityCountryTypeCombo,
  fetchEntityTownTypeCombo,
  fetchEntityColonyTypeCombo,
  fetchEntityCityTypeCombo,
  fetchEntityCPTypeCombo,
  fetchEntityStateTypeCombo,
  fetchEntityMaritalStatusCombo,
  fetchEntityFilterSubTypeCombo,
  fetchServiceTypeComboSuccess,
  fetchExtraSalesTypes,
  fetchExtraSaleTypesSuccess,
  fetchPresentTypesSuccess,
  fetchPurchasePresentTypesSuccess,
  fetchSuppliedTypesSuccess,
  fetchOrganizedUnitsTree,
  fetchOrganizedUnitsTreeSuccess,
  fetchBrands,
  fetchFinancingCompanies,
  fetchFinancingCompaniesSucces,
  fetchVehicleType,
  fetchSearchDossierSubType,
  fetchTemplateTypes,
  fetchTemplateFields,
  fetchRoleCombo,
  fetchRolesSuccess,
  fetchSectionCombo,
  fetchSectionComboSuccess,
  fetchPermissionCombo,
  fetchPermissionComboSuccess,
  fetchDocumentsAllTypes,
  fetchDocumentTypeUseCombo,
  fetchDocumentTypeUseComboSuccess,
  fetchDocumentStatusCombo,
  fetchDocumentStatusComboSuccess,
  fetchMassiveDownloadStatusCombo,
  fetchMassiveDownloadStatusComboSuccess,
  fetchEntitiesByTypeByParent,
  fetchFunctionToAdd,
  fetchFunctionToAddSuccess,
  fetchFunctionToPass,
  fetchFunctionToPassSuccess,
  fetchReasons,
  fetchReasonsSuccess,
  fetchAdditionalInfoCombo,
  fetchOrganizedUnitsAcceptClientsCombo,
  fetchVoCompaniesCombo,
  fetchRentingEntityCombo,
  fetchBankCombo,
  fetchHomologationClassificationCombo,
  fetchHomologationProducerCombo,
  fetchIaeCombo,
  fetchDocumentRestrictedCombo,
  fetchServiceDestinationTypes,
  fetchStockStatusCombo,
  fetchPurchaseOrderTypeCombo,
  fetchMarginTypesCombo,
  fecthOrganizedUnitsByDossierUo,
  fecthOrganizedUnitsByDossierUoSucces,
  fetchUserCombo,
  fetchUserComboSuccess,
  fetchCampaignTypeByGroup,
  fetchCampaignTypeByGroupSuccess,
  fetchCampaignGroup,
  fetchCampaignGroupSuccess,
  fetchCountries,
  fetchCountriesSucess,
  fecthFamily,
  fecthFamilySucces,
  fetchExternalCode,
  fetchExternalCodeSucces,
  fetchIssueEntity,
  fetchIssueEntitySuccess,
  fetchPaymentMethodCampaign,
  fetchPaymentMethodCampaignSuccess,
  fetchFleetsCombo,
  fetchFleetsComboSuccess,
  fetchFleet,
  fetchFleetSuccess,
  fetchFleetComponent,
  fetchFleetsUser,
  fetchFleetsUserSucces,
  fetchManagementActions,
  fetchManagementActionsSuccess,
  fetchCancelCombo,
  fetchCancelComboSuccess,
  fetchDocumentTypeEntities,
  fetchDocumentTypeEntitiesSuccess,
  fetchOrganizedUnitsAll,
  fetchSaleTypeAll,
  fetchDocumentTypeCombo,
  fetchDocumentTypeComboSuccess,
  fetchConfigParameterAll,
  fetchUnattendedProcessTypeCombo,
  fetchUnattendedProcessTypeComboSuccess,
  fetchCommercialSocietyTypeCombo,
  fetchCommercialSocietyTypeComboSuccess,
  fetchAllSaleTypeSuccess,
  fetchFinancingCompaniesBy,
  fetchCurrenciesSuccess,
  fetchOperationTypeDMS,
  fetchCarlineCombo,
  fetchCatalogueCombo,
  fetchModelCombo,
  fetchColorCombo,
  fetchTapestryCombo,
  resetCarlineCombo,
  resetCatalogueCombo,
  resetColorCombo,
  resetTapestryCombo,
  fetchActivityBpro,
  fetchSameLevelOusCombo,
  fetchDocumentDataOptions,
  fetchRatingCombo,
  fetchRatingComboSuccess,
  fetchCustomFieldTypesCombo,
  fetchClientDataAddress,
  fetchRatingComboByOrganizedUnit,
  fetchAuditConfigurationType,
  fetchUnattendedProcessViewCombo,
  fetchUnattendedProcessViewComboSuccess,
  fetchProcessingAgencyManagersCombo,
  fetchPurchaseLogisticStateCombo,
  fetchEventsCombo,
  fetchInsuranceCompanyCombo,
  fetchUserRepairWorkshopsCombo,
  fetchFuelCombo,
  fetchUsageProcessTypesCombo
}
