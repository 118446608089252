import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
// import { push } from 'react-router-redux'
import RegistrationDeliveryHistorical from '../../../components/dossierHistorical/registrationDelivery/RegistrationDeliveryHistorical'
import dossierRegistrationDeliveryActions from '../../../actions/dossier/registrationDelivery'
import { storeDossier } from '../../../actions/dossier/commonDossierActions'
import commentsActions from '../../../actions/dossier/common/comments'
import clientActions from '../../../actions/dossier/common/client'
import vehicleActions from '../../../actions/dossier/common/vehicle'
import deliveryActions from '../../../actions/dossier/common/delivery'
import scrollerActions from '../../../actions/commons/scroller'
import buttonsHeaderActions from '../../../actions/dossier/common/buttonsHeader'
import buttonsSidebarActions from '../../../actions/dossier/common/buttonsSidebar'
import { openDocumentManagementModal as openDocumentModal } from '../../../actions/dossier/common/documentManagementModal'
import { openDocumentEditionModal, closeAddOrEditDocumentModal } from '../../../actions/dossier/common/documentHistoricalEditionModal'
import { putCancelDossier } from '../../../actions/dossier/common/commonHeader'
import { fetchAndOpenReplacePurchaseModal } from '../../../actions/dossier/common/reassignReplacePurchaseModal'
import { fetchFilteredRelatedDossier } from '../../../actions/dossier/common/dossierRelatedDossiers'
import {
  fetchOperationType, fetchPresentTypesSuccess, fetchHomologationClassificationCombo, fetchHomologationProducerCombo, fetchCommercialSocietyTypeCombo,
  fetchIaeCombo, fetchServiceDestinationTypes, fetchEntityCountryTypeCombo, fetchEntityStateTypeCombo, fetchEntityCityTypeCombo, fetchEntityTownTypeCombo, fetchEntityColonyTypeCombo, fetchEntityCPTypeCombo
  , fetchActivityBpro, fetchClientDataAddress
} from '../../../actions/combos/combos'
import { openModal as openCommonModal } from '../../../actions/common'
import {
  fetchCustomFields
} from '../../../actions/dossier/customFields'
import { registerLastAccess } from '../../../actions/dossier/common/lastAccessDossier'

export function mapStateToProps (state) {
  return {
    vehicleProps: state.vehicle,
    dossier: state.registrationDeliveryDossier,
    combos: state.combos,
    showManagementModal: state.documentaryManagementModal.showModal,
    windowWidth: state.windowDimensions.width,
    statusHeaderHeight: state.statusHeader.height,
    activeTab: state.buttonsHeader.activeTab,
    buttonsHeaderHeight: state.buttonsHeader.height,
    activeSection: state.buttonsSidebar.activeSection,
    relatedDossiersOrderBy: state.dossierRelatedDossiers.orderby
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      ...dossierRegistrationDeliveryActions,
      ...clientActions,
      ...vehicleActions,
      ...commentsActions,
      ...deliveryActions,
      ...buttonsHeaderActions,
      ...buttonsSidebarActions,
      ...scrollerActions,
      openDocumentEditionModal,
      closeAddOrEditDocumentModal,
      storeDossier,
      fetchOperationType,
      fetchPresentTypesSuccess,
      updateOptional: (dossierExtra, resolve, reject) => dossierRegistrationDeliveryActions.updateExtra(dossierExtra, resolve, reject, 'optionals'),
      createOptional: (dossierExtra, resolve, reject) => dossierRegistrationDeliveryActions.createExtra(dossierExtra, resolve, reject, 'optionals'),
      deleteOptional: (dossierExtra) => dossierRegistrationDeliveryActions.deleteExtra(dossierExtra, 'optionals'),
      openCommonModal,
      openDocumentModal,
      fetchAndOpenReplacePurchaseModal,
      fetchFilteredRelatedDossier,
      putCancelDossier,
      // navigateTo: push,
      fetchHomologationClassificationCombo,
      fetchHomologationProducerCombo,
      fetchIaeCombo,
      fetchServiceDestinationTypes,
      fetchCommercialSocietyTypeCombo,
      fetchEntityCountryTypeCombo,
      fetchEntityStateTypeCombo,
      fetchEntityCityTypeCombo,
      fetchEntityTownTypeCombo,
      fetchEntityColonyTypeCombo,
      fetchEntityCPTypeCombo,
      fetchActivityBpro,
      fetchCustomFields,
      fetchClientDataAddress,
      registerLastAccess
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(RegistrationDeliveryHistorical))
