import React, { PureComponent } from 'react'
import { reduxForm, initialize, Field } from 'redux-form'
import { Tooltip, OverlayTrigger, Button, Clearfix, Glyphicon, Tabs, Tab, Row, Checkbox } from 'react-bootstrap'
import SimpleTablePage from '../../../containers/commons/general/SimpleTablePage'
import DocumentalSearchFormPage from '../../../containers/search/documentalSearch/DocumentalSearchFormPage'
import DocumentalSearchRestrictedFormPage from '../../../containers/search/documentalSearch/DocumentalSearchRestrictedFormPage'
import DocumentalSearchMassiveDownloadFormPage from '../../../containers/search/documentalSearch/DocumentalSearchMassiveDownloadFormPage'
import { translateDocumentStatus, documentStatusClass, documentStatus } from '../../../constants/dossier/common/documentStatus'
import IDocCarPagination from '../../commons/iDocCarPagination'
import DossierListPopover from '../DossierListPopover'
import VehiclePopover from '../VehiclePopover'
import EntityPopover from '../EntityPopover'
import DossierPopover from '../DossierPopover'
import { documentEntityType, translateDocumentEntityType } from '../../../constants/dossier/common/documentEntityType.js'
import { getSectionPermissions } from '../../../util/permisionFunctions'
import { entityTypesId, permissions } from '../../../constants/backendIds'
import InputCheckbox from '../../commons/form/InputCheckBox'
import MassiveDownloadCreateModal from './MassiveDownloadCreateModal'

class DocumentalSearch extends PureComponent {
  constructor () {
    super()
    this.state = {
      disableResetButton: true,
      sectionTabsConfiguration: {
        documentFinder: null,
        restrictedFinder: null
      },
      fields: {},
      documentsSelected: [],
      allSelected: false,
      showRejectCommentModal: false
    }
  }

  componentDidMount () {
    if (this.props.section === undefined || this.props.section.code !== 'documental-search') {
      let codeSection = this.props.sectionsGeneral.find(section => section.code === 'documental-search')
      this.props.actions.setCurrentSection(codeSection)
    }
    this.props.actions.fetchDocumentStatusCombo()
    this.props.actions.fetchMassiveDownloadStatusCombo()
    this.props.actions.initializeSearch()
    this.initializePermisions(this.props)
    initialize(this.props.dynamicFilters)
  }

  getSnapshotBeforeUpdate (prevProps, prevState) {
    if ((prevProps.section === undefined || prevProps.section.code !== 'documental-search') && this.props.section !== undefined) {
      this.initializePermisions(this.props)
    }
    if (prevProps.isSearching !== this.props.isSearching) {
      this.setState({ documentsSelected: [], allSelected: false })
    }
    return null
  }

  componentDidUpdate () {
    // getSnapshotBeforeUpdate() should be used with componentDidUpdate()
  }

  initializePermisions (props) {
    if (props.section && props.section.sectionTabsConfiguration) {
      const newState = getSectionPermissions(props.section)
      this.setState({ sectionTabsConfiguration: newState })
    }
  }

  onClickResetFilters (e) {
    if (e) {
      e.preventDefault()
    }
    this.props.initialize({})
    this.props.actions.resetFilters()
    this.setState({ fields: {}, documentsSelected: [] })
  }

  onOrderChange (e) {
    let newOrderBy = ''
    if (this.props.filters.orderBy && this.props.filters.orderBy.includes(`%2B${e.currentTarget.dataset.field}`)) {
      newOrderBy = `-${e.currentTarget.dataset.field}`
    } else {
      newOrderBy = `%2B${e.currentTarget.dataset.field}`
    }
    this.props.actions.fetchDocumentSearchList({ items: this.props.documents, ...this.props.pages, ...this.props.count, ...this.props.dynamicFilters, orderBy: newOrderBy })
  }

  getIconClass (field) {
    if (this.props.filters.orderBy && this.props.filters.orderBy.includes(field)) {
      if (this.props.filters.orderBy.includes('-')) {
        return 'ico-sort-selected-asc'
      } else {
        return 'ico-sort-selected-desc'
      }
    } else {
      return 'ico-sort'
    }
  }

  addDocumentIdToArray (documentId) {
    if (!this.state.documentsSelected.includes(documentId)) {
      this.setState({ documentsSelected: [...this.state.documentsSelected, documentId] })
      if (this.props.documentsIds.length === (this.state.documentsSelected.length + 1)) {
        this.setState({ allSelected: true })
      }
    } else {
      this.setState({ documentsSelected: this.state.documentsSelected.filter(d => d !== documentId) })
      if (this.state.allSelected) {
        this.setState({ allSelected: false })
      }
    }
  }

  selectAllDocuments () {
    let documents = []
    if (this.state.allSelected) {
      this.setState({ documentsSelected: documents, allSelected: false })
    } else {
      this.setState({ documentsSelected: this.props.documentsIds, allSelected: true })
    }
  }

  massiveDownloadDocuments () {
    if (this.state.documentsSelected && this.state.documentsSelected.length > 0) {
      const promise = new Promise((resolve, reject) => {
        this.props.actions.massiveDownloadDocumentList(this.state.documentsSelected, resolve, reject)
      })
      promise.then((result) => {
        if (result) {
          this.setState({ showRejectCommentModal: true })
        }
      })
    } else {
      this.props.actions.openModal('mustSelectAnyDocument')
    }
  }

  closeMassiveDownloadModal () {
    this.setState({ showRejectCommentModal: false })
  }

  selectedFilters () {
    const filters = this.props.dynamicFilters
    const tKey = 'DOCUMENT_SEARCH.'
    let value = []
    let valueDocumentType
    let valueStatus
    let valuEntityType
    let valuEntityTypeId
    let valuEntitySubTypeId
    let valueVehicleBrand
    let valueUO
    let valueEntityId
    let valueDossierSubtype
    let valueStage
    const t = this.props.t
    if (filters.documentTypeId) {
      const documentsTypes = this.props.combos.documentsTypesCombo.find(documentType => documentType.id === filters.documentTypeId)
      valueDocumentType = documentsTypes ? documentsTypes.value : null
    }
    if (filters.entityId) {
      const entityId = this.props.combos.entityFilterSubTypeCombo.find(entity => entity.id === filters.entityId)
      valueEntityId = entityId.value
    }
    if (filters.documentStatusId) {
      const documentsStatus = this.props.combos.documentStatusCombo.find(status => status.id === filters.documentStatusId)
      valueStatus = documentsStatus.value
    }
    if (filters.entityTypeId) {
      const entityTypeId = this.props.combos.entityTypesCombo.find(typeId => typeId.id === filters.entityTypeId)
      valuEntityTypeId = entityTypeId.value
      valuEntityType = entityTypeId.value
      valuEntityTypeId = entityTypeId.id
    }
    if (filters.entitySubTypeId) {
      const entitySubTypeId = this.props.combos.entitySubTypeCombo.find(typeId => typeId.id === filters.entitySubTypeId)
      valuEntitySubTypeId = entitySubTypeId.value
    }
    if (filters.vehicleBrandId) {
      const vehicleBrandId = this.props.combos.brandCombo.find(vehicleBrand => vehicleBrand.id === filters.vehicleBrandId)
      valueVehicleBrand = vehicleBrandId.value
    }
    if (filters.organizedUnitId) {
      const organizedUnitId = this.props.combos.lowestOrganizedUnitsCombo.find(uo => uo.id === filters.organizedUnitId)
      valueUO = organizedUnitId.value
    }
    if (filters.dossierSubTypeId) {
      const dossierSubTypeId = this.props.combos.searchDossierSubtypesCombo.find(subTypeId => subTypeId.id === filters.dossierSubTypeId)
      valueDossierSubtype = dossierSubTypeId.value
    }
    if (filters.dossierStageId) {
      valueStage = filters.dossierStageId.map(value => this.props.combos.stagesCombo.find(stage => stage.id === value).value).join(', ')
    }

    if (filters.documentEntityTypeId === documentEntityType.ENTITY) {
      value = [
        <li key={1}>{t(`${tKey}ENTITY_TYPE`)}: <strong>{t(`${tKey}COMBOS.DOCUMENT_ENTITY_TYPE.${translateDocumentEntityType[1]}`)}</strong>;</li>,
        filters.documentTypeId ? (
          <li key={2}>{t(`${tKey}DOCUMENT_TYPE`)}: <strong>{valueDocumentType}</strong>;</li>
        ) : null,
        filters.documentStatusId ? (
          <li key={3}>{t(`${tKey}STATE`)}: <strong>{valueStatus}</strong>;</li>
        ) : null,
        (filters.creationDateFrom || filters.creationDateTo) ? (
          <li key={4}>
            {t(`${tKey}CREATION_DATE`)}: <strong>{
              filters.creationDateFrom && filters.creationDateTo ? (filters.creationDateFrom + ' - ' + filters.creationDateTo)
                : !filters.creationDateTo ? filters.creationDateFrom
                  : filters.creationDateTo
            }
            </strong>;</li>
        ) : null,
        (filters.validationDateFrom || filters.validationDateTo) ? (
          <li key={5}>
            {t(`${tKey}VALIDATION_DATE`)}: <strong>{
              filters.validationDateFrom && filters.validationDateTo ? (filters.validationDateFrom + ' - ' + filters.validationDateTo)
                : !filters.validationDateTo ? filters.validationDateFrom
                  : filters.validationDateTo
            }
            </strong>;</li>
        ) : null,
        filters.entityName && valuEntityTypeId !== entityTypesId.businessEntity ? (
          <li key={6}>{t(`${tKey}NAME`)}: <strong>{filters.entityName}</strong>;</li>
        ) : null,
        filters.entityName && valuEntityTypeId === entityTypesId.businessEntity ? (
          <li key={20}>{t(`${tKey}REGISTERED_NAME`)}: <strong>{filters.entityName}</strong>;</li>
        ) : null,
        filters.entitySurname1 ? (
          <li key={7}>{t(`${tKey}SURNAME1`)}: <strong>{filters.entitySurname1}</strong>;</li>
        ) : null,
        filters.entitySurname2 ? (
          <li key={8}>{t(`${tKey}SURNAME2`)}: <strong>{filters.entitySurname2}</strong>;</li>
        ) : null,
        filters.entityCifNif ? (
          <li key={9}>{t(`${tKey}DNI`)}: <strong>{filters.entityCifNif}</strong>;</li>
        ) : null,
        filters.entityMail ? (
          <li key={10}>{t(`${tKey}EMAIL`)}: <strong>{filters.entityMail}</strong>;</li>
        ) : null,
        filters.entityPhone ? (
          <li key={11}>{t(`${tKey}TELEPHONE`)}: <strong>{filters.entityPhone}</strong>;</li>
        ) : null,
        filters.entityAddress ? (
          <li key={12}>{t(`${tKey}DIRECCTION`)}: <strong>{filters.entityAddress}</strong>;</li>
        ) : null,
        filters.representativeName ? (
          <li key={13}>{t(`${tKey}LEGAL_REPRESENTATIVE_NAME`)}: <strong>{filters.representativeName}</strong>;</li>
        ) : null,
        filters.representativeSurname1 ? (
          <li key={14}>{t(`${tKey}LEGAL_REPRESENTATIVE_SURNAME1`)}: <strong>{filters.representativeSurname1}</strong>;</li>
        ) : null,
        filters.representativeSurname2 ? (
          <li key={15}>{t(`${tKey}LEGAL_REPRESENTATIVE_SURNAME2`)}: <strong>{filters.representativeSurname2}</strong>;</li>
        ) : null,
        filters.representativeDni ? (
          <li key={16}>{t(`${tKey}LEGAL_REPRESENTATIVE_DNI`)}: <strong>{filters.representativeDni}</strong>;</li>
        ) : null,
        filters.entityTypeId ? (
          <li key={17}>{t(`${tKey}CLIENT_TYPE`)}: <strong>{valuEntityType}</strong>;</li>
        ) : null,
        filters.entitySubTypeId ? (
          <li key={18}>{t(`${tKey}ENTITY_SUB_TYPE_ID`)}: <strong>{valuEntitySubTypeId}</strong>;</li>
        ) : null,
        filters.entityId ? (
          <li key={19}>{t(`${tKey}ENTITY_ID`)}: <strong>{valueEntityId}</strong>;</li>
        ) : null
      ]
    } else if (filters.documentEntityTypeId === documentEntityType.VEHICLE) {
      value = [
        <li key={1}>{t(`${tKey}ENTITY_TYPE`)}: <strong>{t(`${tKey}COMBOS.DOCUMENT_ENTITY_TYPE.${translateDocumentEntityType[2]}`)}</strong>;</li>,
        filters.documentTypeId ? (
          <li key={2}>{t(`${tKey}DOCUMENT_TYPE`)}: <strong>{valueDocumentType}</strong>;</li>
        ) : null,
        filters.documentStatusId ? (
          <li key={3}>{t(`${tKey}STATE`)}: <strong>{valueStatus}</strong>;</li>
        ) : null,
        (filters.creationDateFrom || filters.creationDateTo) ? (
          <li key={4}>
            {t(`${tKey}CREATION_DATE`)}: <strong>{
              filters.creationDateFrom && filters.creationDateTo ? (filters.creationDateFrom + ' - ' + filters.creationDateTo)
                : !filters.creationDateTo ? filters.creationDateFrom
                  : filters.creationDateTo
            }
            </strong>;</li>
        ) : null,
        (filters.validationDateFrom || filters.validationDateTo) ? (
          <li key={5}>
            {t(`${tKey}VALIDATION_DATE`)}: <strong>{
              filters.validationDateFrom && filters.validationDateTo ? (filters.validationDateFrom + ' - ' + filters.validationDateTo)
                : !filters.validationDateTo ? filters.validationDateFrom
                  : filters.validationDateTo
            }
            </strong>;</li>
        ) : null,
        filters.vehicleBrandId ? (
          <li key={6}>{t(`${tKey}BRAND`)}: <strong>{valueVehicleBrand}</strong>;</li>
        ) : null,
        filters.vehicleModel ? (
          <li key={7}>{t(`${tKey}MODEL`)}: <strong>{filters.vehicleModel}</strong>;</li>
        ) : null,
        filters.vehicleVinChasis ? (
          <li key={8}>{t(`${tKey}CHASSIS`)}: <strong>{filters.vehicleVinChasis}</strong>;</li>
        ) : null,
        filters.vehicleLicensePlate ? (
          <li key={9}>{t(`${tKey}LICENSE_PLATE`)}: <strong>{filters.vehicleLicensePlate}</strong>;</li>
        ) : null,
        filters.comercialCode ? (
          <li key={10}>{t(`${tKey}COMMERCIAL_CODE`)}: <strong>{filters.comercialCode}</strong>;</li>
        ) : null,
        (filters.licensePlateDateFrom || filters.licensePlateDateTo) ? (
          <li key={11}>
            {t(`${tKey}FIRST_LPLATE_DATE`)}: <strong>{
              filters.licensePlateDateFrom && filters.licensePlateDateTo ? (filters.licensePlateDateFrom + ' - ' + filters.licensePlateDateTo)
                : !filters.licensePlateDateTo ? filters.licensePlateDateFrom
                  : filters.licensePlateDateTo
            }
            </strong>;</li>
        ) : null
      ]
    } else if (filters.documentEntityTypeId === documentEntityType.DOSSIER) {
      value = [
        <li key={1}>{t(`${tKey}ENTITY_TYPE`)}: <strong>{t(`${tKey}COMBOS.DOCUMENT_ENTITY_TYPE.${translateDocumentEntityType[3]}`)}</strong>;</li>,
        filters.documentTypeId ? (
          <li key={2}>{t(`${tKey}DOCUMENT_TYPE`)}: <strong>{valueDocumentType}</strong>;</li>
        ) : null,
        filters.documentStatusId ? (
          <li key={3}>{t(`${tKey}STATE`)}: <strong>{valueStatus}</strong>;</li>
        ) : null,
        (filters.creationDateFrom || filters.creationDateTo) ? (
          <li key={4}>
            {t(`${tKey}CREATION_DATE`)}: <strong>{
              filters.creationDateFrom && filters.creationDateTo ? (filters.creationDateFrom + ' - ' + filters.creationDateTo)
                : !filters.creationDateTo ? filters.creationDateFrom
                  : filters.creationDateTo
            }
            </strong>;</li>
        ) : null,
        (filters.validationDateFrom || filters.validationDateTo) ? (
          <li key={5}>
            {t(`${tKey}VALIDATION_DATE`)}: <strong>{
              filters.validationDateFrom && filters.validationDateTo ? (filters.validationDateFrom + ' - ' + filters.validationDateTo)
                : !filters.validationDateTo ? filters.validationDateFrom
                  : filters.validationDateTo
            }
            </strong>;</li>
        ) : null,
        filters.vehicleBrandId ? (
          <li key={6}>{t(`${tKey}BRAND`)}: <strong>{valueVehicleBrand}</strong>;</li>
        ) : null,
        filters.number ? (
          <li key={7}>{t(`${tKey}DOSSIER_NUMBER`)}: <strong>{filters.number}</strong>;</li>
        ) : null,
        filters.organizedUnitId ? (
          <li key={8}>{t(`${tKey}UO`)}: <strong>{valueUO}</strong>;</li>
        ) : null,
        filters.dossierSubTypeId ? (
          <li key={9}>{t(`${tKey}DOSSIER_SUBTYPE`)}: <strong>{valueDossierSubtype}</strong>;</li>
        ) : null,
        filters.dossierStageId ? (
          <li key={10}>{t(`${tKey}STAGE`)}: <strong>{valueStage}</strong>;</li>
        ) : null,
        (filters.orderDateFrom || filters.orderDateTo) ? (
          <li key={11}>
            {t(`${tKey}ORDER_DATE`)}: <strong>{
              filters.orderDateFrom && filters.orderDateTo ? (filters.orderDateFrom + ' - ' + filters.orderDateTo)
                : !filters.orderDateTo ? filters.orderDateFrom
                  : filters.orderDateTo
            }
            </strong>;</li>
        ) : null,
        (filters.creationDossierDateFrom || filters.creationDossierDateTo) ? (
          <li key={12}>
            {t(`${tKey}CREATION_DATE`)}: <strong>{
              filters.creationDossierDateFrom && filters.creationDossierDateTo ? (filters.creationDossierDateFrom + ' - ' + filters.creationDossierDateTo)
                : !filters.creationDossierDateTo ? filters.creationDossierDateFrom
                  : filters.creationDossierDateTo
            }
            </strong>;</li>
        ) : null,
        filters.dealerNumber ? (
          <li key={13}>{t(`${tKey}DEALER_NUMBER`)}: <strong>{filters.dealerNumber}</strong>;</li>
        ) : null,
        filters.searchHistorical ? (
          <li key={15}>{t(`${tKey}SEARCH_HISTORICAL`)}: <strong>{filters.searchHistorical}</strong>;</li>
        ) : null
      ]
    } else {
      value = [
        filters.documentTypeId ? (
          <li key={2}>{t(`${tKey}DOCUMENT_TYPE`)}: <strong>{valueDocumentType}</strong>;</li>
        ) : null,
        filters.documentStatusId ? (
          <li key={3}>{t(`${tKey}STATE`)}: <strong>{valueStatus}</strong>;</li>
        ) : null,
        (filters.creationDateFrom || filters.creationDateTo) ? (
          <li key={4}>
            {t(`${tKey}CREATION_DATE`)}: <strong>{
              filters.creationDateFrom && filters.creationDateTo ? (filters.creationDateFrom + ' - ' + filters.creationDateTo)
                : !filters.creationDateTo ? filters.creationDateFrom
                  : filters.creationDateTo
            }
            </strong>;</li>
        ) : null,
        (filters.validationDateFrom || filters.validationDateTo) ? (
          <li key={5}>
            {t(`${tKey}VALIDATION_DATE`)}: <strong>{
              filters.validationDateFrom && filters.validationDateTo ? (filters.validationDateFrom + ' - ' + filters.validationDateTo)
                : !filters.validationDateTo ? filters.validationDateFrom
                  : filters.validationDateTo
            }
            </strong>;</li>
        ) : null
      ]
    }
    return value
  }

  fieldFill (field) {
    this.setState({ fields: { ...this.state.fields, ...field } })
  }

  resetFields () {
    this.setState({ fields: {} })
  }

  downloadDocuments () {
    if (this.state.documentIds && this.state.documentIds.length > 0) {
      this.props.actions.downloadDocumentList(this.state.documentIds.toString())
    } else {
      this.props.actions.openModal('mustSelectAnyDocument')
    }
  }

  getDefaultSelectedTab () {
    return this.state.sectionTabsConfiguration.documentFinder &&
     this.state.sectionTabsConfiguration.documentFinder.permission > 0
      ? 1
      : this.state.sectionTabsConfiguration.restrictedFinder &&
       this.state.sectionTabsConfiguration.restrictedFinder.permission > 0
        ? 2 : null
  }

  returnArray (item) {
    let arrayRetur = []
    arrayRetur.push(item)
    return arrayRetur
  }

  render () {
    const {
      t, documents, isSearching, pages, count, dynamicFilters, selectedTabDocument,
      filters: { page },
      actions: { fetchDocumentSearchList, documentalSearchTabChange, createMassiveDownload }
    } = this.props
    const tKey = 'DOCUMENT_SEARCH.'
    const tooltipValidate = (<Tooltip id="tooltipValidate">{t(`${tKey}TABLE.STATUS.VALIDATED`)}</Tooltip>)
    const tooltipPending = (<Tooltip id="tooltipPending">{t(`${tKey}TABLE.STATUS.PENDING`)}</Tooltip>)
    const tooltipRejected = (<Tooltip id="tooltipRejected">{t(`${tKey}TABLE.STATUS.REJECTED`)}</Tooltip>)
    const tooltipRemoved = (<Tooltip id="tooltipRemoved">{t(`${tKey}TABLE.STATUS.REMOVED`)}</Tooltip>)
    const tooltipExpired = (<Tooltip id="tooltipExpired">{t(`${tKey}TABLE.STATUS.EXPIRED`)}</Tooltip>)
    const navigationTooltip = (<Tooltip id="navigationTooltip">{t(`${tKey}TABLE.TOOLTIP.GO_TO`)}</Tooltip>)
    return (
      <div className="search-wrapper">
        <div className="search-header">
          <div className="title-user">
            <i className="ico-documental-search"/> <h2>{t(`${tKey}TITLE`)}</h2>
          </div>
        </div>
        <div className="filters-search-wrapper">
          <MassiveDownloadCreateModal
            t={t}
            showModal={this.state.showRejectCommentModal}
            reasonCombo={this.props.combos.reasonCombo}
            documentIds={this.state.documentsSelected}
            actions={{
              createMassiveDownload,
              closeModal: () => this.closeMassiveDownloadModal()
            }}
          />
          <Row>
            <Tabs
              defaultActiveKey={selectedTabDocument || this.getDefaultSelectedTab()}
              onSelect={(idx, event) => { documentalSearchTabChange(idx, event); this.onClickResetFilters() }}
              animation={false}
              id="tab-search"
              className="col-sm-12"
              activeKey={selectedTabDocument || this.getDefaultSelectedTab()}
            >
              {this.state.sectionTabsConfiguration.documentFinder && this.state.sectionTabsConfiguration.documentFinder.permission > permissions.hidden &&
              <Tab
                eventKey={this.state.sectionTabsConfiguration.documentFinder?.permission === permissions.hidden ? 3 : 1} title={t(`${tKey}GENERAL_FINDER`)}
              >
                {!isSearching &&
                  <DocumentalSearchFormPage
                    t={t}
                    tabPermission={this.state.sectionTabsConfiguration.documentFinder}
                    fields={this.state.fields}
                    change={this.props.change}
                    fieldFill={(val) => this.fieldFill(val)}
                    resetFields={() => this.resetFields()}
                  />
                }
              </Tab>}
              {this.state.sectionTabsConfiguration.restrictedFinder && this.state.sectionTabsConfiguration.restrictedFinder.permission > permissions.hidden &&
                <Tab
                  eventKey={2} title={t(`${tKey}RESTRICTED_FINDER`)}
                >
                  {!isSearching &&
                    <DocumentalSearchRestrictedFormPage
                      t={t}
                      tabPermission={this.state.sectionTabsConfiguration.documentFinder}
                      fields={this.state.fields}
                      change={this.props.change}
                      fieldFill={(val) => this.fieldFill(val)}
                      resetFields={() => this.resetFields()}
                    />
                  }
                </Tab>
              }
              {this.state.sectionTabsConfiguration.massiveDownloads && this.state.sectionTabsConfiguration.massiveDownloads.permission > permissions.hidden &&
                <Tab
                  eventKey={this.state.sectionTabsConfiguration.documentFinder?.permission === permissions.hidden ? 1 : 3} title={t(`${tKey}MASSIVE_DOWNLOAD.TITLE`)}
                >
                  <DocumentalSearchMassiveDownloadFormPage
                    t={t}
                    tabPermission={this.state.sectionTabsConfiguration.documentFinder}
                    fields={this.state.fields}
                    change={this.props.change}
                    fieldFill={(val) => this.fieldFill(val)}
                    resetFields={() => this.resetFields()}
                    emailPermission = {this.state.sectionTabsConfiguration.massiveDownloads.sectionFieldsConfiguration.massive_download_email}
                    massiveDowloadItems = {this.props.massiveDowloadItems}
                  />
                </Tab>
              }
            </Tabs>
          </Row>
          {
            isSearching && (selectedTabDocument === 1 || (this.state.sectionTabsConfiguration.documentFinder !== null && this.state.sectionTabsConfiguration.documentFinder.permission > 0)) && (
              <div className="result-document-search">
                <ul className="list-inline pull-left">
                  {
                    this.selectedFilters()
                  }
                </ul>
                <Clearfix/>
                <Button
                  onClick={(event) => this.onClickResetFilters(event)}
                  bsStyle="default"
                  bsSize="small"
                  className="edit-search-button pull-left"
                >{t(`${tKey}ACTIONS.MODIFY_SEARCH`)} <i className="ico-edit-white" style={{ marginLeft: '3px', fontSize: '14px' }}/>
                </Button>
                {this.state.sectionTabsConfiguration.massiveDownloads && this.state.sectionTabsConfiguration.massiveDownloads.permission > permissions.hidden &&
                  documents && documents.length > 0 &&
                <Button
                  onClick={() => this.massiveDownloadDocuments()}
                  bsStyle="default"
                  bsSize="small"
                  className="edit-search-button pull-left"
                >
                  {t(`${tKey}ACTIONS.DOWNLOAD_SELECTED`)}
                  <i className="glyphicon glyphicon-cloud-download" style={{ marginLeft: '3px', fontSize: '14px' }}/>
                </Button>}
                {this.state.sectionTabsConfiguration.massiveDownloads && this.state.sectionTabsConfiguration.massiveDownloads.permission > permissions.hidden &&
                  documents && documents.length > 0 &&
                <Checkbox
                  className="pull-right"
                  onChange={() => this.selectAllDocuments()}
                  checked={this.state.allSelected}>
                  {t(`${tKey}ACTIONS.SELECT_ALL`)}
                </Checkbox>}
                <Clearfix/>
                {
                  this.props.showPartialRecordsWarning &&
                  <span className="info-general-filer warning"><Glyphicon glyph="warning-sign" /> {t(`${tKey}RESULTS_EXCEED_MAX`)}</span>
                }
                {
                  dynamicFilters.documentEntityTypeId === 1 && (
                    <SimpleTablePage
                      columns={(this.state.sectionTabsConfiguration.massiveDownloads && this.state.sectionTabsConfiguration.massiveDownloads.permission > permissions.hidden) ? ([
                        <th key={0} data-field="entity" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.CLIENT.TITLE`)} <i className={this.getIconClass('entity')}/></th>,
                        <th key={1} data-field="documentType" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.CLIENT.DOCUMENT_TYPE`)} <i className={this.getIconClass('documentType')}/></th>,
                        <th key={2} data-field="status" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.CLIENT.STATE`)} <i className={this.getIconClass('status')}/></th>,
                        <th key={3} data-field="creationDate" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.CLIENT.CREATION_DATE`)} <i className={this.getIconClass('creationDate')}/></th>,
                        <th key={4} data-field="validationDate" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.CLIENT.VALIDATION_DATE`)} <i className={this.getIconClass('validationDate')}/></th>,
                        <th key={5}>{t(`${tKey}TABLE.CLIENT.AMOUNT_RELATED_DOSSIERS`)}</th>,
                        <th key={6}>{t(`${tKey}SELECT_DOWNLOAD`)}</th>,
                        <th key={7}></th>
                      ]) : ([
                        <th key={0} data-field="entity" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.CLIENT.TITLE`)} <i className={this.getIconClass('entity')}/></th>,
                        <th key={1} data-field="documentType" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.CLIENT.DOCUMENT_TYPE`)} <i className={this.getIconClass('documentType')}/></th>,
                        <th key={2} data-field="status" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.CLIENT.STATE`)} <i className={this.getIconClass('status')}/></th>,
                        <th key={3} data-field="creationDate" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.CLIENT.CREATION_DATE`)} <i className={this.getIconClass('creationDate')}/></th>,
                        <th key={4} data-field="validationDate" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.CLIENT.VALIDATION_DATE`)} <i className={this.getIconClass('validationDate')}/></th>,
                        <th key={5}>{t(`${tKey}TABLE.CLIENT.AMOUNT_RELATED_DOSSIERS`)}</th>,
                        <th key={6}></th>
                      ])}
                      rows={documents.map((row, idx) => (
                        <tr key={idx}>
                          <td className="see-more">
                            <EntityPopover
                              t={t}
                              entity={row.entity}
                              half={(documents.length || 1) / (idx + 1)}
                              idx={idx}
                              id={'popover-entity' + idx}
                            />
                          </td>
                          <td>{row.documentType}</td>
                          <td style={{ paddingLeft: '20px' }}>
                            <span>
                              <OverlayTrigger placement="left" overlay={row.status === documentStatus.VALIDATED ? (tooltipValidate) : row.status === documentStatus.PENDING ? (tooltipPending) : row.status === documentStatus.REJECTED ? (tooltipRejected) : row.status === documentStatus.REMOVED ? (tooltipRemoved) : row.status === documentStatus.EXPIRED ? (tooltipExpired) : null}>
                                <i id="documentStatus" className={'ico-' + documentStatusClass[translateDocumentStatus[row.status]]}/>
                              </OverlayTrigger>
                            </span>
                          </td>
                          <td>{row.creationDate}</td>
                          <td>{row.validationDate}</td>
                          <td className="see-more">
                            <DossierListPopover
                              t={t}
                              half={(documents.length || 1) / (idx + 1)}
                              idx={idx}
                              id={'popover-dossier-list' + idx}
                              alive={row.lives}
                              stored={row.stored}
                            />
                          </td>
                          {this.state.sectionTabsConfiguration.massiveDownloads && this.state.sectionTabsConfiguration.massiveDownloads.permission > permissions.hidden &&
                          <td>
                            {
                              <Field
                                colSm={2}
                                component={InputCheckbox}navigationTooltip
                                name={'downloadCheck' + row.documentId}
                                inline
                                customClass="checkbox-inline-search"
                                onChange={() => this.addDocumentIdToArray(row.documentId)}
                                checked= {this.state.documentsSelected.includes(row.documentId)}
                              />
                            }
                          </td>}
                          <td key={idx} className="navigation">
                            {row.documentId && (
                              <OverlayTrigger placement="left" overlay={navigationTooltip}>
                                <Glyphicon
                                  glyph="new-window"
                                  onClick={() => row.isHistorical === false ? (
                                    this.props.actions.openDocumentEditionModal(row.documentId,
                                      row.documentTypeId,
                                      row.documentParentTypeId,
                                      row.dossierId,
                                      row.entityId,
                                      row.vehicleId,
                                      row.organizedUnitId,
                                      null,
                                      () => fetchDocumentSearchList({
                                        items: documents, pages, count, ...this.props.dynamicFilters, page
                                      }),
                                      true,
                                      '',
                                      '', null, null, null, null,
                                      false)
                                  ) : (
                                    this.props.actions.openDocumentEditionModalHistorical(row.documentId, row.documentTypeId, row.documentParentTypeId, row.dossierId, row.entityId, row.vehicleId)
                                  )
                                  }
                                />
                              </OverlayTrigger>
                            )}
                          </td>
                        </tr>
                      ))
                      }
                    />
                  )
                }
                {
                  dynamicFilters.documentEntityTypeId === 2 && (
                    <SimpleTablePage
                      columns={(this.state.sectionTabsConfiguration.massiveDownloads && this.state.sectionTabsConfiguration.massiveDownloads.permission > permissions.hidden) ? ([
                        <th key={0} data-field="vehicle" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.VEHICLE.TITLE`)} <i className={this.getIconClass('vehicle')}/></th>,
                        <th key={1} data-field="documentType" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.VEHICLE.DOCUMENT_TYPE`)} <i className={this.getIconClass('documentType')}/></th>,
                        <th key={2} data-field="status" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.VEHICLE.STATE`)} <i className={this.getIconClass('status')}/></th>,
                        <th key={3} data-field="creationDate" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.VEHICLE.FIRST_REGISTRATION_DATE`)} <i className={this.getIconClass('creationDate')}/></th>,
                        <th key={4} data-field="validationDate" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.VEHICLE.VALIDATION_DATE`)} <i className={this.getIconClass('validationDate')}/></th>,
                        <th key={5}>{t(`${tKey}TABLE.VEHICLE.AMOUNT_RELATED_DOSSIERS`)}</th>,
                        <th key={6}>{t(`${tKey}SELECT_DOWNLOAD`)}</th>,
                        <th key={7}></th>
                      ]) : ([
                        <th key={0} data-field="vehicle" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.VEHICLE.TITLE`)} <i className={this.getIconClass('vehicle')}/></th>,
                        <th key={1} data-field="documentType" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.VEHICLE.DOCUMENT_TYPE`)} <i className={this.getIconClass('documentType')}/></th>,
                        <th key={2} data-field="status" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.VEHICLE.STATE`)} <i className={this.getIconClass('status')}/></th>,
                        <th key={3} data-field="creationDate" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.VEHICLE.FIRST_REGISTRATION_DATE`)} <i className={this.getIconClass('creationDate')}/></th>,
                        <th key={4} data-field="validationDate" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.VEHICLE.VALIDATION_DATE`)} <i className={this.getIconClass('validationDate')}/></th>,
                        <th key={5}>{t(`${tKey}TABLE.VEHICLE.AMOUNT_RELATED_DOSSIERS`)}</th>,
                        <th key={6}></th>
                      ])}
                      rows={documents.map((row, idx) => (
                        <tr key={idx}>
                          <td className="see-more">
                            <VehiclePopover
                              t={t}
                              vehicle={row.vehicle}
                              half={(documents.length || 1) / (idx + 1)}
                              idx={idx}
                              id={'popover-vehicle' + idx}
                            />
                          </td>
                          <td>{row.documentType}</td>
                          <td style={{ paddingLeft: '20px' }}>
                            <span>
                              <OverlayTrigger placement="right" overlay={row.status === documentStatus.VALIDATED ? (tooltipValidate) : row.status === documentStatus.PENDING ? (tooltipPending) : row.status === documentStatus.REJECTED ? (tooltipRejected) : row.status === documentStatus.REMOVED ? (tooltipRemoved) : null}>
                                <i id="documentStatus" className={'ico-' + documentStatusClass[translateDocumentStatus[row.status]]}/>
                              </OverlayTrigger>
                            </span>
                          </td>
                          <td>{row.creationDate}</td>
                          <td>{row.validationDate}</td>
                          <td className="see-more">
                            <DossierListPopover
                              t={t}
                              half={(documents.length || 1) / (idx + 1)}
                              idx={idx}
                              id={'popover-dossier-list' + idx}
                              alive={row.lives}
                              stored={row.stored}
                            />
                          </td>
                          {this.state.sectionTabsConfiguration.massiveDownloads && this.state.sectionTabsConfiguration.massiveDownloads.permission > permissions.hidden &&
                          <td>
                            {
                              <Field
                                colSm={2}
                                component={InputCheckbox}
                                name={'downloadCheck' + row.documentId}
                                inline
                                customClass="checkbox-inline-search"
                                onChange={() => this.addDocumentIdToArray(row.documentId)}
                                checked= {this.state.documentsSelected.includes(row.documentId)}
                              />
                            }
                          </td>}
                          <td key={idx} className="navigation">
                            {row.documentId && (
                              <OverlayTrigger placement="left" overlay={navigationTooltip}>
                                <Glyphicon
                                  glyph="new-window"
                                  onClick={() =>
                                    row.isHistorical === false ? (
                                      this.props.actions.openDocumentEditionModal(row.documentId,
                                        row.documentTypeId,
                                        row.documentParentTypeId,
                                        row.dossierId,
                                        row.entityId,
                                        row.vehicleId,
                                        row.organizedUnitId,
                                        null,
                                        () => fetchDocumentSearchList({
                                          items: documents, pages, count, ...this.props.dynamicFilters, page
                                        }),
                                        true,
                                        '',
                                        '', null, null, null, null,
                                        false)
                                    ) : (
                                      this.props.actions.openDocumentEditionModalHistorical(row.documentId, row.documentTypeId, row.documentParentTypeId, row.dossierId, row.entityId, row.vehicleId)
                                    )
                                  }
                                />
                              </OverlayTrigger>
                            )}
                          </td>
                        </tr>
                      ))}
                    />
                  )
                }
                {
                  dynamicFilters.documentEntityTypeId === 3 && (
                    <SimpleTablePage
                      columns={(this.state.sectionTabsConfiguration.massiveDownloads && this.state.sectionTabsConfiguration.massiveDownloads.permission > permissions.hidden) ? ([
                        <th key={0} data-field="documentType" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.DOCUMENT_TYPE`)} <i className={this.getIconClass('documentType')}/></th>,
                        <th key={1} data-field="dossier" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.DOSSIER`)} <i className={this.getIconClass('dossier')}/></th>,
                        <th key={2} data-field="entity" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.ENTITY`)} <i className={this.getIconClass('entity')}/></th>,
                        <th key={3} data-field="vehicle" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.VEHICLE`)} <i className={this.getIconClass('vehicle')}/></th>,
                        <th key={4} data-field="status" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.STATE`)} <i className={this.getIconClass('status')}/></th>,
                        <th key={5} data-field="creationDate" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.CREATION_DATE`)} <i className={this.getIconClass('creationDate')}/></th>,
                        <th key={6} data-field="validationDate" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.VALIDATION_DATE`)} <i className={this.getIconClass('validationDate')}/></th>,
                        <th key={7}>{t(`${tKey}SELECT_DOWNLOAD`)}</th>,
                        <th key={8}></th>
                      ]) : ([
                        <th key={0} data-field="documentType" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.DOCUMENT_TYPE`)} <i className={this.getIconClass('documentType')}/></th>,
                        <th key={1} data-field="dossier" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.DOSSIER`)} <i className={this.getIconClass('dossier')}/></th>,
                        <th key={2} data-field="entity" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.ENTITY`)} <i className={this.getIconClass('entity')}/></th>,
                        <th key={3} data-field="vehicle" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.VEHICLE`)} <i className={this.getIconClass('vehicle')}/></th>,
                        <th key={4} data-field="status" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.STATE`)} <i className={this.getIconClass('status')}/></th>,
                        <th key={5} data-field="creationDate" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.CREATION_DATE`)} <i className={this.getIconClass('creationDate')}/></th>,
                        <th key={6} data-field="validationDate" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.VALIDATION_DATE`)} <i className={this.getIconClass('validationDate')}/></th>,
                        <th key={7}></th>
                      ])}
                      rows={documents.map((row, idx) => (
                        <tr key={idx}>
                          <td>{row.documentType}</td>
                          <td className="see-more">
                            <DossierPopover
                              t={t}
                              dossier={row.dossier}
                              half={(documents.length || 1) / (idx + 1)}
                              idx={idx}
                              id={'popover-dossier' + idx}
                            />
                          </td>
                          <td className="see-more">
                            <EntityPopover
                              t={t}
                              entity={row.entity}
                              half={(documents.length || 1) / (idx + 1)}
                              idx={idx}
                              id={'popover-entity' + idx}
                            />
                          </td>
                          <td className="see-more">
                            <VehiclePopover
                              t={t}
                              vehicle={row.vehicle}
                              half={(documents.length || 1) / (idx + 1)}
                              idx={idx}
                              id={'popover-vehicle' + idx}
                            />
                          </td>
                          <td style={{ paddingLeft: '20px' }}>
                            <span>
                              <OverlayTrigger placement="left" overlay={row.status === documentStatus.VALIDATED ? (tooltipValidate) : row.status === documentStatus.PENDING ? (tooltipPending) : row.status === documentStatus.REJECTED ? (tooltipRejected) : row.status === documentStatus.REMOVED ? (tooltipRemoved) : null}>
                                <i id="documentStatus" className={'ico-' + documentStatusClass[translateDocumentStatus[row.status]]}/>
                              </OverlayTrigger>
                            </span>
                          </td>
                          <td>{row.creationDate}</td>
                          <td>{row.validationDate}</td>
                          {this.state.sectionTabsConfiguration.massiveDownloads && this.state.sectionTabsConfiguration.massiveDownloads.permission > permissions.hidden &&
                          <td>
                            {
                              <Field
                                colSm={2}
                                component={InputCheckbox}
                                name={'downloadCheck' + row.documentId}
                                inline
                                customClass="checkbox-inline-search"
                                onChange={() => this.addDocumentIdToArray(row.documentId)}
                                checked= {this.state.documentsSelected.includes(row.documentId)}
                              />
                            }
                          </td>}
                          <td key={idx} className="navigation">
                            {row.documentId && (
                              <OverlayTrigger placement="left" overlay={navigationTooltip}>
                                <Glyphicon
                                  glyph="new-window"
                                  onClick={() =>
                                    row.isHistorical === false ? (
                                      this.props.actions.openDocumentEditionModal(
                                        row.documentId,
                                        row.documentTypeId,
                                        row.documentParentTypeId,
                                        row.dossierId,
                                        row.entityId,
                                        row.vehicleId,
                                        row.organizedUnitId,
                                        null,
                                        () => fetchDocumentSearchList({ items: documents, pages, count, ...this.props.dynamicFilters, page }),
                                        true,
                                        '',
                                        '', null, null, null, null, false, row.isMarked)
                                    ) : (
                                      this.props.actions.openDocumentEditionModalHistorical(row.documentId, row.documentTypeId, row.documentParentTypeId, row.dossierId, row.entityId, row.vehicleId)
                                    )
                                  }
                                />
                              </OverlayTrigger>
                            )}
                          </td>
                        </tr>
                      ))}
                    />
                  )
                }
                {
                  dynamicFilters.documentEntityTypeId !== 1 && dynamicFilters.documentEntityTypeId !== 2 && dynamicFilters.documentEntityTypeId !== 3 && (
                    dynamicFilters.documentTypeId ? (
                      <SimpleTablePage
                        columns={(this.state.sectionTabsConfiguration.massiveDownloads && this.state.sectionTabsConfiguration.massiveDownloads.permission > permissions.hidden) ? ([
                          <th key={0} data-field="documentType" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.DOCUMENT_TYPE`)} <i className={this.getIconClass('documentType')}/></th>,
                          <th key={1} data-field="dossier" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.DOSSIER`)} <i className={this.getIconClass('dossier')}/></th>,
                          <th key={2} data-field="entity" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.ENTITY`)} <i className={this.getIconClass('entity')}/></th>,
                          <th key={3} data-field="vehicle" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.VEHICLE`)} <i className={this.getIconClass('vehicle')}/></th>,
                          <th key={4} data-field="status" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.STATE`)} <i className={this.getIconClass('status')}/></th>,
                          <th key={5} data-field="creationDate" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.CREATION_DATE`)} <i className={this.getIconClass('creationDate')}/></th>,
                          <th key={6} data-field="validationDate" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.VALIDATION_DATE`)} <i className={this.getIconClass('validationDate')}/></th>,
                          <th key={7}>{t(`${tKey}TABLE.CLIENT.AMOUNT_RELATED_DOSSIERS`)}</th>,
                          <th key={8}>{t(`${tKey}SELECT_DOWNLOAD`)}</th>,
                          <th key={9}></th>
                        ]) : ([
                          <th key={0} data-field="documentType" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.DOCUMENT_TYPE`)} <i className={this.getIconClass('documentType')}/></th>,
                          <th key={1} data-field="dossier" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.DOSSIER`)} <i className={this.getIconClass('dossier')}/></th>,
                          <th key={2} data-field="entity" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.ENTITY`)} <i className={this.getIconClass('entity')}/></th>,
                          <th key={3} data-field="vehicle" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.VEHICLE`)} <i className={this.getIconClass('vehicle')}/></th>,
                          <th key={4} data-field="status" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.STATE`)} <i className={this.getIconClass('status')}/></th>,
                          <th key={5} data-field="creationDate" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.CREATION_DATE`)} <i className={this.getIconClass('creationDate')}/></th>,
                          <th key={6} data-field="validationDate" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.VALIDATION_DATE`)} <i className={this.getIconClass('validationDate')}/></th>,
                          <th key={7}>{t(`${tKey}TABLE.CLIENT.AMOUNT_RELATED_DOSSIERS`)}</th>,
                          <th key={8}></th>
                        ])}
                        rows={documents.map((row, idx) => (
                          <tr key={idx}>
                            <td>{row.documentType}</td>
                            <td className="see-more">
                              <DossierPopover
                                t={t}
                                dossier={row.dossier}
                                half={(documents.length || 1) / (idx + 1)}
                                idx={idx}
                                id={'popover-dossier' + idx}
                              />
                            </td>
                            <td className="see-more">
                              <EntityPopover
                                t={t}
                                entity={row.entity}
                                half={(documents.length || 1) / (idx + 1)}
                                idx={idx}
                                id={'popover-entity' + idx}
                              />
                            </td>
                            <td className="see-more">
                              <VehiclePopover
                                t={t}
                                vehicle={row.vehicle}
                                half={(documents.length || 1) / (idx + 1)}
                                idx={idx}
                                id={'popover-vehicle' + idx}
                              />
                            </td>
                            <td style={{ paddingLeft: '20px' }}>
                              <span>
                                <OverlayTrigger placement="left" overlay={row.status === documentStatus.VALIDATED ? (tooltipValidate) : row.status === documentStatus.PENDING ? (tooltipPending) : row.status === documentStatus.REJECTED ? (tooltipRejected) : row.status === documentStatus.REMOVED ? (tooltipRemoved) : null}>
                                  <i id="documentStatus" className={'ico-' + documentStatusClass[translateDocumentStatus[row.status]]}/>
                                </OverlayTrigger>
                              </span>
                            </td>
                            <td>{row.creationDate}</td>
                            <td>{row.validationDate}</td>
                            <td className="see-more">
                              <DossierListPopover
                                t={t}
                                half={(documents.length || 1) / (idx + 1)}
                                idx={idx}
                                id={'popover-dossier-list' + idx}
                                alive={row.lives ? row.lives : row.isHistorical ? [] : this.returnArray(row.dossier)}
                                stored={row.stored ? row.stored : row.isHistorical ? this.returnArray(row.dossier) : []}
                              />
                            </td>
                            {this.state.sectionTabsConfiguration.massiveDownloads && this.state.sectionTabsConfiguration.massiveDownloads.permission > permissions.hidden &&
                            <td>
                              {
                                <Field
                                  colSm={0}
                                  component={InputCheckbox}
                                  name={'downloadCheck' + row.documentId}
                                  inline
                                  customClass="checkbox-inline-search"
                                  onChange={() => this.addDocumentIdToArray(row.documentId)}
                                  checked= {this.state.documentsSelected.includes(row.documentId)}
                                />
                              }
                            </td>}
                            <td key={idx} className="navigation">
                              {row.documentId && (
                                <OverlayTrigger placement="left" overlay={navigationTooltip}>
                                  <Glyphicon
                                    glyph="new-window"
                                    onClick={() =>
                                      row.isHistorical === false ? (
                                        this.props.actions.openDocumentEditionModal(row.documentId,
                                          row.documentTypeId,
                                          row.documentParentTypeId,
                                          row.dossierId,
                                          row.entityId,
                                          row.vehicleId,
                                          row.organizedUnitId,
                                          null,
                                          () => fetchDocumentSearchList({
                                            items: documents, pages, count, ...this.props.dynamicFilters, page
                                          }),
                                          true,
                                          '',
                                          '',
                                          null, null, null, null,
                                          false)
                                      ) : (
                                        this.props.actions.openDocumentEditionModalHistorical(row.documentId, row.documentTypeId, row.documentParentTypeId, row.dossierId, row.entityId, row.vehicleId)
                                      )
                                    }
                                  />
                                </OverlayTrigger>
                              )}
                            </td>
                          </tr>
                        ))}
                      />
                    ) : (
                      <SimpleTablePage
                        columns={(this.state.sectionTabsConfiguration.massiveDownloads && this.state.sectionTabsConfiguration.massiveDownloads.permission > permissions.hidden) ? ([
                          <th key={0} data-field="documentType" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOCUMENT_TYPE`)} <i className={this.getIconClass('documentType')}/></th>,
                          <th key={1} data-field="status" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.STATUS.TITLE`)} <i className={this.getIconClass('status')}/></th>,
                          <th key={2} data-field="validationDate" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.VALIDATION_DATE`)} <i className={this.getIconClass('validationDate')}/></th>,
                          <th key={3} data-field="creationDate" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.CREATION_DATE`)} <i className={this.getIconClass('creationDate')}/></th>,
                          <th key={4}>{t(`${tKey}SELECT_DOWNLOAD`)}</th>,
                          <th key={5}></th>
                        ]) : ([
                          <th key={0} data-field="documentType" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOCUMENT_TYPE`)} <i className={this.getIconClass('documentType')}/></th>,
                          <th key={1} data-field="status" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.STATUS.TITLE`)} <i className={this.getIconClass('status')}/></th>,
                          <th key={2} data-field="validationDate" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.VALIDATION_DATE`)} <i className={this.getIconClass('validationDate')}/></th>,
                          <th key={3} data-field="creationDate" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.CREATION_DATE`)} <i className={this.getIconClass('creationDate')}/></th>,
                          <th key={4}></th>
                        ])}
                        rows={documents.map((row, idx) => (
                          <tr key={idx}>
                            <td>{row.documentType}</td>
                            <td style={{ paddingLeft: '20px' }}>
                              <span>
                                <OverlayTrigger placement="right" overlay={row.status === documentStatus.VALIDATED ? (tooltipValidate) : row.status === documentStatus.PENDING ? (tooltipPending) : row.status === documentStatus.REJECTED ? (tooltipRejected) : row.status === documentStatus.REMOVED ? (tooltipRemoved) : null}>
                                  <i id="documentStatus" className={'ico-' + documentStatusClass[translateDocumentStatus[row.status]]}/>
                                </OverlayTrigger>
                              </span>
                            </td>
                            <td>{row.validationDate}</td>
                            <td>{row.creationDate}</td>
                            {this.state.sectionTabsConfiguration.massiveDownloads && this.state.sectionTabsConfiguration.massiveDownloads.permission > permissions.hidden &&
                            <td>
                              {
                                <Field
                                  colSm={0}
                                  component={InputCheckbox}
                                  name={'downloadCheck' + row.documentId}
                                  inline
                                  customClass="checkbox-inline-search"
                                  onChange={() => this.addDocumentIdToArray(row.documentId)}
                                  checked= {this.state.documentsSelected.includes(row.documentId)}
                                />
                              }
                            </td>}
                            <td key={idx} className="navigation">
                              {row.documentId && (
                                <OverlayTrigger placement="left" overlay={navigationTooltip}>
                                  <Glyphicon
                                    glyph="new-window"
                                    onClick={() =>
                                      row.isHistorical === false ? (
                                        this.props.actions.openDocumentEditionModal(row.documentId,
                                          row.documentTypeId,
                                          row.documentParentTypeId,
                                          row.dossierId,
                                          row.entityId,
                                          row.vehicleId,
                                          row.organizedUnitId,
                                          null,
                                          () => fetchDocumentSearchList({ items: documents, pages, count, ...this.props.dynamicFilters, page }),
                                          true,
                                          '',
                                          '', null, null, null, null,
                                          false)
                                      ) : (
                                        this.props.actions.openDocumentEditionModalHistorical(row.documentId, row.documentTypeId, row.documentParentTypeId, row.dossierId, row.entityId, row.vehicleId)
                                      )
                                    }
                                  />
                                </OverlayTrigger>
                              )}
                            </td>
                          </tr>
                        ))}
                      />
                    )
                  )
                }
                <div className="search-footer">
                  <IDocCarPagination
                    id="btn_pag_sales"
                    page={page}
                    pagesCount={pages}
                    totalRows= {count}
                    onSelectAction={(page) => fetchDocumentSearchList({ items: documents, pages, count, ...this.props.dynamicFilters, page })}
                  />
                </div>
              </div>
            )
          }
          {
            isSearching && selectedTabDocument === 2 && (
              <div className="result-document-search">
                <ul className="list-inline pull-left">
                  {
                    this.selectedFilters()
                  }
                </ul>
                <Clearfix/>
                <Button
                  onClick={(event) => this.onClickResetFilters(event)}
                  bsStyle="default"
                  bsSize="small"
                  className="edit-search-button pull-left"
                >
                  {t(`${tKey}ACTIONS.MODIFY_SEARCH`)}
                  <i className="ico-edit-white" style={{ marginLeft: '3px', fontSize: '14px' }}/>
                </Button>
                <Button
                  onClick={() => this.downloadDocuments()}
                  bsStyle="default"
                  bsSize="small"
                  className="edit-search-button pull-right"
                >
                  {t(`${tKey}ACTIONS.DOWNLOAD_DOCUMENTS`)}
                  <i className="glyphicon glyphicon-cloud-download" style={{ marginLeft: '3px', fontSize: '14px' }}/>
                </Button>
                <Clearfix/>
                <SimpleTablePage
                  columns={[
                    <th key={0} data-field="documentType" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.DOCUMENT_TYPE`)} <i className={this.getIconClass('documentType')}/></th>,
                    <th key={1} data-field="dossier" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.DOSSIER`)} <i className={this.getIconClass('dossier')}/></th>,
                    <th key={2} data-field="entity" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.ENTITY`)} <i className={this.getIconClass('entity')}/></th>,
                    <th key={3} data-field="vehicle" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.VEHICLE`)} <i className={this.getIconClass('vehicle')}/></th>,
                    <th key={4} data-field="status" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.STATE`)} <i className={this.getIconClass('status')}/></th>,
                    <th key={5} data-field="creationDate" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.CREATION_DATE`)} <i className={this.getIconClass('creationDate')}/></th>,
                    <th key={6} data-field="validationDate" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.VALIDATION_DATE`)} <i className={this.getIconClass('validationDate')}/></th>,
                    <th key={8}></th>,
                    <th key={9}></th>
                  ]}
                  rows={documents.map((row, idx) => (
                    <tr key={idx}>
                      <td>{row.documentType}</td>
                      <td className="see-more">
                        <DossierPopover
                          t={t}
                          dossier={row.dossier}
                          half={(documents.length || 1) / (idx + 1)}
                          idx={idx}
                          id={'popover-dossier' + idx}
                        />
                      </td>
                      <td className="see-more">
                        <EntityPopover
                          t={t}
                          entity={row.entity}
                          half={(documents.length || 1) / (idx + 1)}
                          idx={idx}
                          id={'popover-entity' + idx}
                        />
                      </td>
                      <td className="see-more">
                        <VehiclePopover
                          t={t}
                          vehicle={row.vehicle}
                          half={(documents.length || 1) / (idx + 1)}
                          idx={idx}
                          id={'popover-vehicle' + idx}
                        />
                      </td>
                      <td style={{ paddingLeft: '20px' }}>
                        <span>
                          <OverlayTrigger placement="left" overlay={row.status === documentStatus.VALIDATED ? (tooltipValidate) : row.status === documentStatus.PENDING ? (tooltipPending) : row.status === documentStatus.REJECTED ? (tooltipRejected) : row.status === documentStatus.REMOVED ? (tooltipRemoved) : null}>
                            <i id="documentStatus" className={'ico-' + documentStatusClass[translateDocumentStatus[row.status]]}/>
                          </OverlayTrigger>
                        </span>
                      </td>
                      <td>{row.creationDate}</td>
                      <td>{row.validationDate}</td>
                      <td key={idx} className="navigation">
                        {row.documentId && (
                          <OverlayTrigger placement="left" overlay={navigationTooltip}>
                            <Glyphicon
                              glyph="new-window"
                              onClick={() =>
                                row.isHistorical === false ? (
                                  this.props.actions.openDocumentEditionModal(row.documentId,
                                    row.documentTypeId,
                                    row.documentParentTypeId,
                                    row.dossierId,
                                    row.entityId,
                                    row.vehicleId,
                                    row.organizedUnitId,
                                    null,
                                    () => fetchDocumentSearchList({
                                      items: documents, pages, count, ...this.props.dynamicFilters, page
                                    }),
                                    true,
                                    '',
                                    '', null, null, null, null,
                                    false)
                                ) : (
                                  this.props.actions.openDocumentEditionModalHistorical(row.documentId, row.documentTypeId, row.documentParentTypeId, row.dossierId, row.entityId, row.vehicleId)
                                )
                              }
                            />
                          </OverlayTrigger>
                        )}
                      </td>
                      <td>
                        {
                          <Field
                            colSm={0}
                            component={InputCheckbox}
                            name={'downloadCheck' + row.dossierId}
                            inline
                            customClass="checkbox-inline-search"
                            onChange={() => this.addDocumentIdToArray(row.documentId)}
                          />
                        }
                      </td>
                    </tr>
                  ))}
                />
                <div className="search-footer">
                  <IDocCarPagination
                    id="btn_pag_sales"
                    page={page}
                    pagesCount={pages}
                    totalRows= {count}
                    onSelectAction={(page) => fetchDocumentSearchList({ items: documents, pages, count, ...this.props.dynamicFilters, page })}
                  />
                </div>
              </div>
            )
          }
        </div>
      </div>
    )
  }
}

const validate = (values, { t }) => {
  const errors = {}
  return errors
}

export default reduxForm({
  form: 'documentSearch',
  validate
})(DocumentalSearch)
