import apiFetch from '../apiFetch'

export default function (token,uoId) {
  const queryParamsArray = [
    uoId ? `organizedUnit=${uoId}` : null
  ]
  const queryParams = queryParamsArray.reduce(
    (result, query, idx) => result + (query ? (result ? `&${query}` : `?${query}`) : '')
    , ''
  )

  return apiFetch({ endPoint: `TaskDashboard/FollowUpTask${queryParams}`, method: 'GET', body: null, token: token })
}
