import { bindActionCreators } from 'redux'
import { change } from 'redux-form'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { fetchRepairshopList, deleteRepairshop, openEditRepairshopModal, openRepairshopModal, openRepairshopUsersModal, fetchRepairshopUsersList } from '../../../actions/masters/repairshop/repairshop'
import { setChangeTabFromConfMaster } from '../../../actions/masters/masters'
import RepairshopMaster from '../../../components/masters/repairshop/RepairshopMaster'

export function mapStateToProps (state) {
  return {
    ...state.repairshop,
    combos: {
      organizedUnitsAllCombo: state.combos.organizedUnitsAllCombo
    }
  }
}
export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      deleteRepairshop,
      openRepairshopModal,
      openEditRepairshopModal,
      openRepairshopUsersModal,
      fetchRepairshopList,
      setChangeTabFromConfMaster,
      change,
      fetchRepairshopUsersList
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(RepairshopMaster))
