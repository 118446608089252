import apiFetch from '../apiFetch'
export default function (entityId, userId, token) {
  const queryParamsArray = [
    entityId ? `entityId=${entityId}` : null,
    userId ? `userId=${userId}` : null
  ]
  const queryParams = queryParamsArray.reduce(
    (result, query, idx) => result + (query ? (result ? `&${query}` : `?${query}`) : '')
    , ''
  )
  return apiFetch({ endPoint: 'Entity/SetUserRecurrentServiceEntity' + queryParams, method: 'POST', body: null, token: token })
}
