import React, { PureComponent } from 'react'
// import { OverlayTrigger, Tooltip } from 'react-bootstrap'
// import { Link } from 'react-router-dom'

class TotalLine extends PureComponent {
  constructor () {
    super()
    this.state = {
      target: this
      // icon: '',
      // tkeyname: ''
      // aviableLink: false
    }
  }

  render () {
    const {
      // t,
      lineTitle,
      lineId = '',
      lineTotal,
      linePercent,
      bold = false,
      customClass = ''
    } = this.props
    return (
      <>
        <div className={'totalLine ' + customClass}>
          <span className={'title' + (bold ? ' bold' : '')}>{lineTitle}</span>
          <span className={'data' + (bold ? ' bold' : '')} id={lineId}>{lineTotal}</span>
          {// linePercent !== undefined && (
            <span className={'percent' + (linePercent === undefined ? ' empty' : '')} id={lineId + '_Percent'}>{linePercent}</span>
          // )
          }
        </div>
      </>
    )
  }
}

export default TotalLine
undefined
