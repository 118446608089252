import apiFetch from '../apiFetch'

export default function (token, filter) {
  return apiFetch({
    endPoint: 'report/CommercialReportTable',
    method: 'POST',
    body: filter,
    token: token
  })
}
