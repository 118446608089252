import mastersActionTypes from '../../../constants/actions/masters/masters'

function initialState () {
  return {
    tkey: 'MASTERS.OU_MASTERS.',
    items: [],
    errors: [],
    docErrors: [],
    succes: null,
    code: null,
    showSuccesMessage: false,
    showMessageError: null,
    massAction: null,
    callback: null
  }
}

function closeModalMassSuccess (state) {
  return initialState()
}

function openModalMassSuccess (state, { tkey, massAction, callback }) {
  return {
    ...state,
    tkey,
    massAction,
    callback
  }
}

function fetchUploadTest (state, { items, errors, succes, code, showMessageError }) {
  return {
    ...state,
    showSuccesMessage: succes && state.code && state.code === code,
    items,
    errors,
    succes,
    code,
    showMessageError
  }
}

function fetchUploadTestWithDocs (state, { items, errors, succes, code, showMessageError, docErrors }) {
  return {
    ...state,
    showSuccesMessage: succes && state.code && state.code === code,
    items,
    errors,
    succes,
    code,
    showMessageError,
    docErrors
  }
}

function resetModalMassUpload (state) {
  return {
    ...state,
    items: [],
    errors: [],
    code: null,
    succes: null,
    showSuccesMessage: false,
    showMessageError: null
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case mastersActionTypes.OPEN_MASS_MODAL_SUCCES:
      return openModalMassSuccess(state, action)
    case mastersActionTypes.CLOSE_MASS_MODAL_SUCCES:
      return closeModalMassSuccess(state, action)
    case mastersActionTypes.FETCH_UPLOAD_TEST:
      return fetchUploadTest(state, action)
    case mastersActionTypes.RESET_MODAL_MASS_UPLOAD:
      return resetModalMassUpload(state, action)
    case mastersActionTypes.FETCH_UPLOAD_TEST_WITH_DOCS:
      return fetchUploadTestWithDocs(state, action)
    default:
      return state
  }
}
