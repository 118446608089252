import React, { PureComponent } from 'react'
import { Col } from 'react-bootstrap'

class FaqSectionItem extends PureComponent {
  openQuestion (questionId) {
    this.props.openQuestion(questionId)
  }

  openSection (sectionId, title) {
    this.props.openSection(sectionId, title)
  }

  render () {
    const {
      t,
      section: {
        faqSectionId, title, questions
      }
    } = this.props

    const tKey = 'FAQ.'
    return (
      <div className="section">
        <h2 className="section-title">
          {title}
        </h2>
        <div className="section-questions">
          {
            questions.map((question, idx) => (
              <Col className="section-question" key={idx} onClick={() => { this.openQuestion(question.id) }}>
                {question.name}
              </Col>
            ))
          }
          <Col className="section-showMore" key={0} onClick={() => { this.openSection(faqSectionId, title) }}>
            {t(`${tKey}SHOW_MORE`)}
          </Col>
        </div>
      </div>
    )
  }
}

export default FaqSectionItem
