import React, { PureComponent } from 'react'
import { Row, Tab, Tabs, Col, Table } from 'react-bootstrap'
import Tab1 from './TabPane1Tabs/Tab1'
import Tab2 from './TabPane1Tabs/Tab2'
import Tab3 from './TabPane1Tabs/Tab3'
import Tab4 from './TabPane1Tabs/Tab4'
import { Field, reduxForm } from 'redux-form'
import SelectInput from '../../../../commons/SelectInput'

class TabPane1 extends PureComponent {
  render () {
    return (
      <Row className="tabs-wrapper">
        <Col sm={8}>
          <Tabs id='tab-pane-1' defaultActiveKey={1} className="sub-tabs">
            <Tab eventKey={1} title="Resumen">
              <Tab1/>
            </Tab>
            <Tab eventKey={2} title="Impuestos">
              <Tab2/>
            </Tab>
            <Tab eventKey={3} title="Gastos/ingresos">
              <Tab3/>
            </Tab>
            <Tab eventKey={4} title="Matrícula/Preentrega">
              <Tab4/>
            </Tab>
            <Tab eventKey={5} title="Explotación">
            </Tab>
          </Tabs>
        </Col>
        <Col sm={4}>
          <Col sm={12} className="select-input-full-width">
            <Field
              name='company'
              label='Facturar a:'
              placeholder='Empresa'
              component={SelectInput}
              labelKey='name'
              valueKey='roleId'
            />
          </Col>
          <Col sm={12} className="select-input-full-width">
            <Field
              name='collaborator'
              label='Colaborador:'
              placeholder='Colaborador'
              component={SelectInput}
              labelKey='name'
              valueKey='roleId'
            />
          </Col>
          <Col sm={12} className="select-input-full-width">
            <Field
              name='chanel'
              label='Canal procedencia:'
              placeholder='Canal procedencia'
              component={SelectInput}
              labelKey='name'
              valueKey='roleId'
            />
          </Col>
          <Col sm={12} className="right-side-table">
            <Table responsive className="table-tabPane">
              <thead>
                <tr>
                  <th colSpan={2}>Inf. SIT Pagos</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Señal y pagos</td>
                  <td>0,0%</td>
                </tr>
                <tr>
                  <td>UaC</td>
                  <td>0,0%</td>
                </tr>
                <tr>
                  <td><span>Total pendiente</span></td>
                  <td><span>0,0%</span></td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Col>
      </Row>
    )
  }
}

export default reduxForm({ form: 'newForm' })(TabPane1)
