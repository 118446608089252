import apiFetch from '../apiFetch'

export default function (token, type) {
  const queryParamsArray = [
    type ? `type=${type}` : null
  ]
  const queryParams = queryParamsArray.reduce(
    (result, query, idx) => result + (query ? (result ? `&${query}` : `?${query}`) : '')
    , ''
  )
  return apiFetch({ endPoint: 'ActivityProfessional/GetAll/' + queryParams, method: 'GET', body: null, token: token })
}
