import React, { PureComponent } from 'react'
// import StageBar from '../charts/stagesBarChart/StageBar'
// import { Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

class Card extends PureComponent {
  render () {
    const {
      t,
      subType: { name, subTotalHigh, subTotalMedium, subTotalLow, stages, subTypeId },
      /* history, */ salesmanId, urgencyLowLabel, urgencyMediumLabel, urgencyHighLabel/*, defaultSalesmen */
    } = this.props
    const tKey = 'DASHBOARD.'

    var sum = subTotalHigh + subTotalMedium + subTotalLow
    var percentHigh = (subTotalHigh * 100 / sum).toFixed(2)
    var percentMedium = (subTotalMedium * 100 / sum).toFixed(2)
    var percentLow = (subTotalLow * 100 / sum).toFixed(2)

    return (
      <div className='card'>
        <header>
          <p className='title'><Link to={`/dashboard/${salesmanId || 0}/0/${subTypeId}/0`} >{name}</Link></p>
          <p className='total'><span>{t(`${tKey}CARD.TOTAL`)}</span><Link to={`/dashboard/${salesmanId || 0}/0/${subTypeId}/0`} >{subTotalHigh + subTotalMedium + subTotalLow}</Link></p>
        </header>
        <body>
          <div className='totals'>
            <div className='titles'>
              <div className='left'>{t(`${tKey}CARD.DATE`)}</div>
              <div className='right'>{t(`${tKey}CARD.AMOUNT`)}</div>
            </div>
            <Link className={'data' + (subTotalHigh === 0 ? ' empty' : '')} to={`/dashboard/${salesmanId || 0}/2/${subTypeId}/0`}>
              <div className='left'>
                <div className='graphic'>
                  <div className='text'>
                    <p>{urgencyHighLabel}</p>
                    <p>{percentHigh}%</p>
                  </div>
                  <div className='line'>
                    <span className='quantity red' style={{ width: percentHigh + '%' }}></span>
                  </div>
                </div>
              </div>
              <div className='right'>{subTotalHigh || 0}</div>
            </Link>
            <Link className={'data' + (subTotalMedium === 0 ? ' empty' : '')} to={`/dashboard/${salesmanId || 0}/3/${subTypeId}/0`}>
              <div className='left'>
                <div className='graphic'>
                  <div className='text'>
                    <p>{urgencyMediumLabel}</p>
                    <p>{percentMedium}%</p>
                  </div>
                  <div className='line'>
                    <span className='quantity yellow' style={{ width: percentMedium + '%' }}></span>
                  </div>
                </div>
              </div>
              <div className='right'>{subTotalMedium || 0}</div>
            </Link>
            <Link className={'data' + (subTotalLow === 0 ? ' empty' : '')} to={`/dashboard/${salesmanId || 0}/4/${subTypeId}/0`}>
              <div className='left'>
                <div className='graphic'>
                  <div className='text'>
                    <p>{urgencyLowLabel}</p>
                    <p>{percentLow}%</p>
                  </div>
                  <div className='line'>
                    <span className='quantity green' style={{ width: percentLow + '%' }}></span>
                  </div>
                </div>
              </div>
              <div className='right'>{subTotalLow || 0}</div>
            </Link>
          </div>
          <div className='breakdown'>
            <div className='titles'>
              <div className='left'>{t(`${tKey}CARD.STAGE`)}</div>
              <div className='right'>
                <span className='item colorBox total'></span>
                <span className='item colorBox red'></span>
                <span className='item colorBox yellow'></span>
                <span className='item colorBox green'></span>
              </div>
            </div>
            {
              stages.map((stage, idx) =>
                <div className={'data' + ((stage.high + stage.medium + stage.low) === 0 ? ' empty' : '')}>
                  <div className='left'>{stage.name}</div>
                  <div className='right'>
                    <Link to={`/dashboard/${salesmanId || 0}/0/${subTypeId}/${stage.stageId}`} className='item colorText total'>{stage.high + stage.medium + stage.low}</Link>
                    <Link to={`/dashboard/${salesmanId || 0}/2/${subTypeId}/${stage.stageId}`} className='item colorText red'>{stage.high}</Link>
                    <Link to={`/dashboard/${salesmanId || 0}/3/${subTypeId}/${stage.stageId}`} className='item colorText yellow'>{stage.medium}</Link>
                    <Link to={`/dashboard/${salesmanId || 0}/4/${subTypeId}/${stage.stageId}`} className='item colorText green'>{stage.low}</Link>
                  </div>
                </div>
              )
            }
          </div>
        </body>
        <footer></footer>
      </div>
    )
  }
}

export default Card
