import React, { useState } from 'react'
import ScrollerPage from '../containers/commons/ScrollerPage'
import ScrollerPage_v2 from '../_v2/containers/commons/ScrollerPage'
import WindowDimensionsPage from '../containers/commons/WindowDimensionsPage'
import HeaderPage from '../containers/layout/HeaderPage'
import HeaderPage_v2 from '../_v2/containers/layout/HeaderPage'
import SidebarPage from '../containers/layout/SidebarPage'
import SidebarPage_v2 from '../_v2/containers/layout/SidebarPage'
import DocumentEditionPage from '../containers/dossiers/common/DocumentEdition'
import DocumentEditionPage_v2 from '../_v2/containers/dossiers/commons/DocumentEdition'
import DocumentHistoricalEditionPage from '../containers/dossierHistorical/common/DocumentEdition'
import MessageModalContainer from '../containers/layout/modals/MessageModalContainer'
import ModalDocumentFullScreenPage from '../containers/dossiers/common/ModalDocumentFullScreenPage'
import ReleaseNotesModalPage from '../containers/layout/modals/ReleaseNotesModalContainer'
import settings from '../../src/setting'

import { setStorageValue, getStorageValue } from '../storage/storage'

function getEnviromentClass () {
  if (settings.environmentName.includes('test')) {
    return 'test'
  }
  if (settings.environmentName.includes('qa')) {
    return 'qa'
  }
  if (settings.environmentName.includes('pre')) {
    return 'pre'
  }
  return ''
}

export default ({ children }) => {
  const [openSideBar, setOpenMenu] = useState(getStorageValue('mobileMenuIsClose') === 'true')

  // let version = settings.interfaceVersion
  let version = parseInt(getStorageValue('interfaceVersion'))

  const openMenu = () => {
    setStorageValue('mobileMenuIsClose', !openSideBar)
    setOpenMenu(!openSideBar)
  }

  if (version === 1) {
    return (
      <div className='_v1'>
        <HeaderPage />
        <SidebarPage />
        <div className="layout">
          <ReleaseNotesModalPage />
          <MessageModalContainer />
          <DocumentEditionPage />
          <DocumentHistoricalEditionPage />
          <ModalDocumentFullScreenPage />
          <ScrollerPage />
          <WindowDimensionsPage />
          { children }
        </div>
      </div>
    )
  } else {
    return (
      <div className={'mainGrid customScrollbar mVersion ' + (openSideBar ? 'displayedMenu' : '')}>
        <div className='gridPart-header'><HeaderPage_v2 /></div>
        <div className='gridPart-leftnav'><SidebarPage_v2 openMenu={openMenu} openSideBar={openSideBar} /></div>
        <div className='gridPart-content'>
          <ReleaseNotesModalPage />
          <MessageModalContainer />
          <DocumentEditionPage_v2 />
          <DocumentHistoricalEditionPage />
          <ModalDocumentFullScreenPage />
          <ScrollerPage_v2 />
          <WindowDimensionsPage />
          { children }
        </div>
        { getEnviromentClass() !== ''
          ? <div className={'gridPart-enviroment ' + getEnviromentClass()}><p>{settings.environmentName}</p></div>
          : <></>
        }
      </div>
    )
  }
}
