export const buttonsPermissions = {
  sale_save: 'sale_save',
  exclusive_sale_save: 'exclusive_sale_save',
  sale_cancel: 'sale_cancel',
  sale_document_admin: 'sale_document_admin',
  sale_send_approve: 'sale_send_approve',
  sale_approve: 'sale_approve',
  sale_aprove_pwk: 'sale_aprove_pwk',
  sale_aprove_pwk_vn: 'sale_aprove_pwk_vn',
  sale_approve_pwk_finance: 'sale_approve_pwk_finance',
  sale_approve_pwk_manager: 'sale_approve_pwk_manager',
  sale_reject: 'sale_reject',
  sale_skip_charge: 'sale_skip_charge',
  sale_skip_charge_vn: 'sale_skip_charge_vn',
  sale_skip_document: 'sale_skip_document',
  sale_skip_document_vn: 'sale_skip_document_vn',
  sale_skip_paperwork: 'sale_skip_paperwork',
  sale_skip_paperwork_vn: 'sale_skip_paperwork_vn',
  sale_skip_delivery: 'sale_skip_delivery',
  sale_create_purch: 'sale_create_purch',
  sale_replace: 'sale_replace',
  sale_store: 'sale_store',
  paperwork_save: 'paperwork_save',
  paperwork_cancel: 'paperwork_cancel',
  paperwork_reject: 'paperwork_reject',
  paperwork_store: 'paperwork_store',
  paperwork_document_admin: 'paperwork_document_admin',
  paperwork_gen_delivery_note: 'paperwork_gen_delivery_note',
  purchase_save: 'purchase_save',
  purchase_cancel: 'purchase_cancel',
  purchase_document_admin: 'purchase_document_admin',
  purchase_send_approve: 'purchase_send_approve',
  purchase_approve: 'purchase_approve',
  purchase_approved_manager: 'purchase_approved_manager',
  purchase_approve_sales_manger_vo: 'purchase_approve_sales_manger_vo',
  purchase_reject: 'purchase_reject',
  purchase_store: 'purchase_store',
  campaign_save: 'campaign_save',
  campaign_cancel: 'campaign_cancel',
  campaign_send: 'campaign_send',
  campaign_approve: 'campaign_approve',
  campaign_reject: 'campaign_reject',
  campaign_deny: 'campaign_deny',
  campaign_store: 'campaign_store',
  campaign_document_admin: 'campaign_document_admin',
  sale_approve_salesman: 'sale_approve_salesman',
  sale_approve_sales_manager: 'sale_approve_sales_manager',
  sale_approve_manager: 'sale_approve_manager',
  sale_approve_logistics: 'sale_approve_logistics',
  sale_approve_administration: 'sale_approve_administration',
  sale_approve_commercial_director: 'sale_approve_commercial_director',
  sale_vo_approve_sales_manager: 'sale_vo_approve_sales_manager',
  sale_approve_bpa: 'sale_approve_bpa',
  sale_approve_cfo: 'sale_approve_cfo',
  dossier_undo_cancel: 'dossier_undo_cancel',
  dossier_confirm_cancel: 'dossier_confirm_cancel',
  purchase_change_subtype: 'purchase_change_subtype',
  purchase_internal_vo: 'purchase_internal_vo',
  sale_approve_fleet: 'sale_approve_fleet',
  sale_approve_voucher: 'sale_approve_voucher',
  button_approve_controller: 'button_approve_controller',
  close_publish: 'close_publish',
  close_save: 'close_save',
  close_store: 'close_store',
  close_cancel: 'close_cancel',
  configureCampaign: 'configureCampaign',
  sale_pay_renting: 'sale_pay_renting',
  resend_processing_agency: 'resend_processing_agency',
  sendDocumentsToSignSales: 'sendDocumentsToSignSales',
  sendDocumentsToSignPurchase: 'sendDocumentsToSignPurchase',
  vehicle_licenseplate_validate_format: 'vehicle_licenseplate_validate_format',
  skipItv: 'sale_skip_itv',
  changeUO: 'changeUO',
  anulateCampaign: 'anulateCampaign',
  campaign_sale_replace: 'campaign_sale_replace',
  force_save: 'force_save',
  bt_validate_campaign: 'bt_validate_campaign',
  bt_denie_campaig: 'bt_denie_campaig',
  bt_return_campaig: 'bt_return_campaig',
  reg_delivery_save: 'reg_delivery_save',
  reg_delivery_cancel: 'reg_delivery_cancel',
  reg_delivery_replace: 'reg_delivery_replace',
  reg_delivery_create_purch: 'reg_delivery_create_purch',
  reg_delivery_print_delivery_checklist: 'reg_delivery_print_delivery_checklist',
  reg_delivery_aprove_pwk: 'reg_delivery_aprove_pwk',
  reg_delivery_store: 'reg_delivery_store',
  bt_denie_campaig_stageVerificaction: 'bt_denie_campaig_stageVerificaction',
  bt_return_campaig_stageVerificaction: 'bt_return_campaig_stageVerificaction',
  bt_denie_campaig_stageApprove: 'bt_denie_campaig_stageApprove',
  bt_return_campaig_stageApprove: 'bt_return_campaig_stageApprove',
  service_dossier_save: 'service_dossier_save',
  service_dossier_store: 'service_dossier_store',
  service_dossier_cancel: 'service_dossier_cancel',
  bt_paperwork_notify: 'bt_paperwork_notify',
  service_dossier_confirm: 'service_dossier_confirm',
  service_approve_logistic: 'service_approve_logistic',
  service_complete_pap: 'service_complete_pap',
  service_pap_issue: 'service_pap_issue',
  service_resolve_issue: 'service_resolve_issue',
  service_request_spareParts: 'service_request_spareParts',
  service_complete_spareParts: 'service_complete_spareParts',
  service_complete_deliveryPreparation: 'service_complete_deliveryPreparation',
  btn_get_vehicle_from_quiter: 'btn_get_vehicle_from_quiter',
  sale_transfer_request_vo: 'sale_transfer_request_vo',
  sale_transfer_request_approved_vo: 'sale_transfer_request_approved_vo',
  purchase_transfer_request_vo: 'purchase_transfer_request_vo',
  purchase_transfer_request_approved_vo: 'purchase_transfer_request_approved_vo',
  paperwork_transfer_completed: 'paperwork_transfer_completed',
  btn_store_delete_any_stage: 'btn_store_delete_any_stage',
  print_summary_sale: 'btn_print_summary_sale',
  sale_print_delivery_checklist: 'sale_print_delivery_checklist',
  send_to_dms: 'send_to_dms',
  update_dossier_toshiko: 'update_dossier_toshiko',
  stock_dossier_save: 'stock_dossier_save',
  stock_dossier_cancel: 'stock_dossier_cancel',
  button_request_invoice_to_dms: 'button_request_invoice_to_dms',
  button_request_vehicle_delivery_to_dms: 'button_request_vehicle_delivery_to_dms',
  view_btn_summary: 'view_btn_summary',
  sale_transfer_cancel_request_vo: 'sale_transfer_cancel_request_vo',
  purchase_transfer_cancel_request_vo: 'purchase_transfer_cancel_request_vo',
  button_request_vehicle_outbound_to_dms: 'button_request_vehicle_outbound_to_dms',
  received_agent_fee: 'received_agent_fee',
  paperwork_partial_save: 'paperwork_partial_save',
  export_control_approve: 'export_control_approve',
  national_vo_approve: 'national_vo_approve'
}
