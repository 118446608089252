import React, { PureComponent } from 'react'
import { Field } from 'redux-form'
import { /* Button, */ OverlayTrigger, Tooltip } from 'react-bootstrap'
import SimpleTablePage from '../../../containers/commons/general/SimpleTablePage'
import { permissions } from '../../../constants/backendIds'
import { getSectionPermissions } from '../../../util/permisionFunctions'
import { sectionPermissions } from '../../../constants/dossier/common/buttonsHeader'
import InputCheckboxSlider from '../commons/form/InputCheckboxSlider'

class UserApplications extends PureComponent {
  constructor (props) {
    super(props)
    this.state = { rechargeDevices: false, phoneEditable: false }
  }

  updateActiveDevice (device) {
    if (!device.activate && this.devicesActives() >= this.props.maxInstallations) {
      this.props.actions.openErrorMessageModal('PROFILE.MAX_INSTALLS_REACHED')
    } else {
      device.activate = !device.activate
      this.props.actions.updateDeviceInstall(device)
      this.setState({ rechargeDevices: !this.state.rechargeDevices })
    }
  }

  initializePermisions (props) {
    const section = props.sections.filter((tabConf) => tabConf.code === sectionPermissions.dashboard)
    if (section.length > 0 && section[0] != null) {
      const newState = getSectionPermissions(section[0])
      this.setState({ sectionTabsConfiguration: newState })
    }
  }

  componentDidMount () {
    this.initializePermisions(this.props)
    this.props.actions.fetchDeviceInstalls(this.props.userId)
    this.props.actions.fetchMaxDeviceInstalls(this.props.userId)
  }

  devicesActives () {
    let count = 0
    this.props.deviceInstalls.map(dev => { if (dev.activate) { count++ } })
    return count
  }

  render () {
    const tKey = 'PROFILE.'
    const { t, data, languages, deviceInstalls, maxInstallations, sectionTabsConfiguration } = this.props
    if (!data || !languages) return null
    return (
      <div className='inner-results-panel'>
        <div className='section-results-header'>
          <div className="section-header-title">
            <OverlayTrigger placement="right" overlay={<Tooltip id="editProductTooltip">{`${t((this.props.hideMenu ? 'GLOBAL.TABLES.SHOW_INNER_MENU' : 'GLOBAL.TABLES.HIDE_INNER_MENU'))}`}</Tooltip>}>
              <a onClick={() => this.props.toogleInnerMenu()} className={this.props.hideMenu ? 'collapseBtn' : 'collapseBtn closeInnerMenu'}><i className={this.props.hideMenu ? 'iDocIcon-Open-inner-menu' : 'iDocIcon-Close-inner-menu'}></i></a>
            </OverlayTrigger>
            <h4>{t(tKey + 'APPLICATIONS_TAB.TITLE')}</h4>
          </div>
        </div>
        <div className="section-results-body aplications">

          { data.code && (
            <div className="field inline-field">
              <label>{t(tKey + 'CODE')}:</label>
              <span>{data.code}</span>
            </div>
          )
          }
          {sectionTabsConfiguration && sectionTabsConfiguration.profileDetails && sectionTabsConfiguration.profileDetails.permission > permissions.hidden && deviceInstalls && (
            <div>
              <div className="field inline-field">
                <label>{t(tKey + 'DEVICE_INSTALLS')}{' (' + this.devicesActives() + '/' + maxInstallations + ')'}</label>
              </div>
              <SimpleTablePage
                columns={[
                  <th key={0} data-field="deviceName" >{t(tKey + 'DEVICE')}</th>,
                  <th className='hide' key={1} data-field="activate" >{t(tKey + 'ACTIVE')}</th>,
                  <th className='actions' key={2} data-field="activate" >{t(tKey + 'ACTIVATE/DEACTIVATE')}</th>
                ]}
                rows={deviceInstalls.map((row, idx) => (
                  <tr key={idx}>
                    <td className='device'>{row.deviceName}</td>
                    <td className='hide'>{row.activate ? t('GLOBAL.BUTTONS.YES') : t('GLOBAL.BUTTONS.NO')}</td>
                    <td className='actions'>
                      {/* <Button
                        onClick={(event) => this.updateActiveDevice(deviceInstalls[idx])}
                        bsStyle="default"
                        bsSize="small"
                        className="edit-search-button pull-left"
                      >{row.activate ? t('PROFILE.DEACTIVATE') : t('PROFILE.ACTIVATE') }
                      </Button> */}
                      <Field
                        component={InputCheckboxSlider}
                        colWidht={100} // 25, 33, 50, 75, 100 default=25
                        checked={row.activate}
                        id="active"
                        name="active"
                        onInputChange={(value) => this.updateActiveDevice(deviceInstalls[idx]) }
                      />
                    </td>
                  </tr>
                ))}>
              </SimpleTablePage>
            </div>)}
          {sectionTabsConfiguration && sectionTabsConfiguration.profileDetails && sectionTabsConfiguration.profileDetails.permission > permissions.hidden && (
            <div className='dowloadBox'>
              <label>{t(tKey + 'DOWNLOAD_APP')}</label>
              <div>
                <a href="https://play.google.com/store/apps/details?id=com.idoccar.scan" target="_blank"><img src="images/gPlay.png" alt=""/></a>
                <a href="https://apps.apple.com/es/app/idoccar-scan/id1512145256" target="_blank"><img src="images/aStore.png" alt=""/></a>
              </div>
            </div>
          )}

        </div>
      </div>
    )
  }
}

export default UserApplications
