import { call, put, takeEvery, select } from 'redux-saga/effects'
import { showLoader, hideLoader } from '../../../../actions/common'
import messageModalActions from '../../../../actions/modals/messageModal'
import { getAuth } from '../../../../selectors/access/auth'
import { handleError } from '../../../errors/errorManager'
import getSearchMultipleEntityDms from '../../../../services/entity/getSearchMultipleEntityDms'
import clientActionTypes from '../../../../constants/actions/dossier/common/client'

export function * fetchClientByPhoneEmail ({ organizationUnitId, phone, email, resolve }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    let entities = yield call(getSearchMultipleEntityDms, auth.token, organizationUnitId, phone, email)

    if (resolve) resolve(entities)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.ENTITY_COMPONENT.UNKNOWN_ERROR', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    yield call(handleError, { error, customHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchClientByPhoneEmail () {
  yield takeEvery(clientActionTypes.FETCH_CLIENT_PHONE_EMAIL, fetchClientByPhoneEmail)
}
