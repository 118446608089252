import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { closeOrganizedUnitModal, saveOrganizedUnitModal } from '../../../actions/masters/masters'
import { fetchProcessingAgencyCombo, fetchSameLevelOusCombo } from '../../../actions/combos/combos'
import OrganizedUnitModal from '../../../components/masters/organizedUnit/OrganizedUnitModal'
export function mapStateToProps (state) {
  return {
    ...state.entityMasters,
    ...state.organizedUnit,
    combos: {
      organizedUnitsAllCombo: state.combos.organizedUnitsAllCombo,
      directionOUCombo: state.combos.directionOUCombo,
      processingAgencyCombo: state.combos.processingAgencyCombo,
      legalCompanyCombo: state.combos.legalCompanyCombo,
      countriesCombo: state.combos.countries,
      destinationCombo: state.combos.destinationCombo
    }
  }
}
export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      closeOrganizedUnitModal,
      saveOrganizedUnitModal,
      fetchProcessingAgencyCombo,
      fetchSameLevelOusCombo
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(OrganizedUnitModal))
