import React, { PureComponent } from 'react'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'

import { PowerBIEmbed } from 'powerbi-client-react'
import { models } from 'powerbi-client'
/// ///////////////////////////////////////////////
import MapSectionsManagement from '../../../_v2/util/map-sections-report'

class PowerBiHomeReport extends PureComponent {
  constructor () {
    super()
    this.state = {
      maxiTable: false
    }
  }

  toogleMaxiTable () {
    this.setState({ maxiTable: !this.state.maxiTable })
  }

  render () {
    const {
      t, powerbiConfig: { id, embedUrl, embedToken: { token } },
      powerBiRefreshSession
    } = this.props
    const secMapManagement = MapSectionsManagement.find(function (s) { return s.page === 'powerBIPage' })

    return (
      <div className="inner-results-panel">
        <div className="section-results-header">
          <div className="section-header-title">
            {/* <OverlayTrigger placement="right" overlay={<Tooltip id="editProductTooltip">{`${t((this.props.hideMenu ? 'GLOBAL.TABLES.SHOW_INNER_MENU' : 'GLOBAL.TABLES.HIDE_INNER_MENU'))}`}</Tooltip>}> */}
            <OverlayTrigger placement="right" overlay={<Tooltip id="editProductTooltip">tooltip</Tooltip>}>
              <a onClick={() => this.props.toogleInnerMenu()} className={this.props.hideMenu ? 'collapseBtn' : 'collapseBtn closeInnerMenu'}><i className={this.props.hideMenu ? 'iDocIcon-Open-inner-menu' : 'iDocIcon-Close-inner-menu'}></i></a>
            </OverlayTrigger>
            <h4>{ t(`${secMapManagement.title}`) }</h4>
          </div>
        </div>
        <div className="section-results-body">
          <div className={'searchResoultsContainer' + (this.state.maxiTable ? ' maxiTable' : '')}>
            <div className="table-wrapper" key="table-wrapper">
              {/* table options, maximize, etc.... */}
              <div className='flexInLine al-R'>
                <div className='actionsField'>
                </div>
                <OverlayTrigger placement="left" overlay={<Tooltip id="editProductTooltip">{`${t((this.state.maxiTable ? 'GLOBAL.TABLES.MINIMIZE_TABLE' : 'GLOBAL.TABLES.MAXIMIZE_TABLE'))}`}</Tooltip>}>
                  <a onClick={() => this.toogleMaxiTable()} className='_Btn accept stk o-icn maximizeBtn'><i className={(this.state.maxiTable ? 'iDocIcon-Minimize' : 'iDocIcon-Maximize')}></i></a>
                </OverlayTrigger>
              </div>
              {/* table */}
              <div className="table-responsive">
                <PowerBIEmbed
                  embedConfig = {{
                    type: 'report', // Supported types: report, dashboard, tile, visual and qna
                    id: id,
                    embedUrl: embedUrl,
                    accessToken: token,
                    tokenType: models.TokenType.Embed,
                    settings: {
                      panes: {
                        filters: {
                          expanded: false,
                          visible: false
                        }
                      },
                      navContentPaneEnabled: false,
                      background: models.BackgroundType.Transparent
                    }
                  }}
                  eventHandlers = {
                    new Map([
                      ['rendered', function () {
                        powerBiRefreshSession()
                      }]
                    ])
                  }
                  cssClassName = { 'powerbi-report' }
                />
              </div>
            </div>
          </div>
        </div>
      </div>

    )
  }
}

export default PowerBiHomeReport
