import React, { PureComponent } from 'react'
import { reduxForm, Field } from 'redux-form'
import { Row, Col, Table, Button, Tooltip, OverlayTrigger, Glyphicon } from 'react-bootstrap'
import IDocCarPagination from '../../commons/iDocCarPagination'
import InputText from '../../commons/form/InputText'
import CampaignModalPage from '../../../containers/masters/Campaign/CampaignModalPage'
import CampaignModaDocumentsPage from '../../../containers/masters/Campaign/CampaignModaDocumentsPage'
import CampaingModalCountryDetailPage from '../../../containers/masters/Campaign/CampaingModalCountryDetailPage'
import InputSelect from '../../commons/form/InputSelect'
import { languages } from '../../../constants/dossier/common/languages'

class CampaignMaster extends PureComponent {
  constructor () {
    super()
    this.state = {
      languageSelected: languages.SPANISH,
      filter: {
        searchCode: null,
        searchClassId: null,
        searchGroupId: null,
        searchTypeId: null
      }
    }
  }

  getSortIcon (column = '') {
    switch (this.props.filter.orderBy) {
      case '+' + column:
        return 'ico-sort-selected-asc'
      case '-' + column:
        return 'ico-sort-selected-desc'
      default:
        return 'ico-sort'
    }
  }

  changeOrder (field) {
    switch (this.props.filter.orderBy) {
      case `+${field}`:
        this.props.actions.fetchCampaignList({ ...this.state.filter, ...this.props.filter, orderBy: `-${field}` })
        break
      default:
        this.props.actions.fetchCampaignList({ ...this.state.filter, ...this.props.filter, orderBy: `+${field}` })
        break
    }
  }

  handleNameChange (searchCode) {
    if (this.state.filter.searchName !== searchCode) {
      this.setState({ filter: { ...this.state.filter, searchCode } })
    }
  }

  handleClassChange (searchClassId) {
    if (this.state.filter.searchClass !== searchClassId) {
      this.setState({ filter: { ...this.state.filter, searchClassId } })
    }
  }

  handleGroupChange (searchGroupId) {
    if (this.state.filter.searchGroupId !== searchGroupId) {
      this.setState({ filter: { ...this.state.filter, searchGroupId } })
    }
  }

  handleTypeChange (searchTypeId) {
    if (this.state.filter.searchTypeId !== searchTypeId) {
      this.setState({ filter: { ...this.state.filter, searchTypeId } })
    }
  }

  searchCampaign () {
    this.props.actions.fetchCampaignList({ ...this.props.filter, ...this.state.filter, page: 1 })
  }

  extractCampaignTypeName (campaign) {
    var name = ''
    if (campaign.campaignTypeLanguage && campaign.campaignTypeLanguage.length > 0) {
      var any = campaign.campaignTypeLanguage.find(lang => (lang.languageId ? lang.languageId : '') === this.props.languageSelected)
      if (any) { name = any.description }
    }
    return name
  }

  extractCampaignGroupName (campaign) {
    var name = ''
    if (campaign.campaignGroupLanguage && campaign.campaignGroupLanguage.length > 0) {
      var any = campaign.campaignGroupLanguage.find(lang => (lang.languageId ? lang.languageId : '') === this.props.languageSelected)
      if (any) { name = any.description }
    }
    return name
  }

  resetFilters () {
    this.setState({
      filter: {
        searchCode: null,
        searchClassId: null,
        searchGroupId: null,
        searchTypeId: null
      }
    }, () => { this.searchCampaign() })
  }

  render () {
    const {
      t, campaignList, pagesCount, campaignCount, filter,
      filter: { page },
      combos: { campaignClass, campaignGroup, campaignsTypes },
      actions: {
        openAddCampaignModalSuccess,
        fetchCampaignList,
        recoverCampaignDetail,
        openEditCampaignDetailsModalSuccess,
        deleteCampaignDetail,
        openEditDocumentalConditionModalSuccess,
        openCampaingModalCountryDetailSuccess

      }
    } = this.props
    const tKey = 'MASTERS.CAMPAIGN_DETAILS_MASTERS.'
    const documentTooltip = (<Tooltip id="documentTooltip">{t(`${tKey}DOCUMENTAL_CONDITIONS`)}</Tooltip>)
    const editTooltip = (<Tooltip id="editTooltip">{t(`${tKey}EDIT`)}</Tooltip>)
    const deleteTooltip = (<Tooltip id="deleteTooltip">{t(`${tKey}DELETE`)}</Tooltip>)
    const recoverTooltip = (<Tooltip id="deleteTooltip">{t(`${tKey}RECOVER`)}</Tooltip>)
    const importTooltip = (<Tooltip id="importTooltip">{t(`${tKey}EDIT_IMPORT_COUNTRY`)}</Tooltip>)
    const hasFilters = (this.state.filter.searchClassId || this.state.filter.searchCode || this.state.filter.searchGroupId || this.state.filter.searchTypeId)
    return (
      <div className="admin-wrapper">
        <CampaignModalPage/>
        <CampaignModaDocumentsPage/>
        <CampaingModalCountryDetailPage/>
        <Row className="section-users-header">
          <Col sm={12}>
            <div className="header-panel">
              <h4>{t(`${tKey}CAMPAIGN_DETAILS`)}</h4>
            </div>
          </Col>
        </Row>
        <Row>
          <div className="search-form">
            <span className="title-search"><i className="ico-search" /> {t(`${tKey}SEARCH_TITLE`)}</span>
            <Row>

              <Field
                name="filters.code"
                colSm={4}
                controlLabel={t(`${tKey}CODE`)}
                placeholder={t(`${tKey}CODE`)}
                component={InputText}
                onInputChange={(value) => this.handleNameChange(value)}
              />

              <Field
                name="filters.campaignClassId"
                colSm={4}
                controlLabel={t(`${tKey}CLASSTYPE`)}
                placeholder={t(`${tKey}CLASSTYPE`)}
                valueKey="id"
                labelKey="value"
                component={InputSelect}
                options={campaignClass}
                onInputChange={(value) => this.handleClassChange(value)}
              />

              <Field
                name="filters.campaignGroupId"
                colSm={4}
                controlLabel={t(`${tKey}GROUPTYPE`)}
                placeholder={t(`${tKey}GROUPTYPE`)}
                valueKey="id"
                labelKey="value"
                component={InputSelect}
                options={campaignGroup}
                onInputChange={(value) => this.handleGroupChange(value)}
              />

              <Field
                name="filters.campaignTypeCode"
                colSm={4}
                controlLabel={t(`${tKey}CAMPAIGN_TYPE`)}
                placeholder={t(`${tKey}CAMPAIGN_TYPE`)}
                valueKey="id"
                labelKey="value"
                component={InputSelect}
                options={campaignsTypes}
                onInputChange={(value) => this.handleTypeChange(value)}
              />

              <Col sm={4} className="btn-wrapper">
                <p></p>
                <Button
                  className={'btn-white btn-icon btn-input btn-white search-button'}
                  style={{ width: '100%' }}
                  onClick={() => this.searchCampaign()}>
                  <i className="ico-search" />
                  {t(`${tKey}SEARCH_BTN`)}
                </Button>
                <Button
                  className={'btn-icon btn-input ' + (hasFilters ? 'btn-standard' : 'btn-cancel')}
                  onClick={() => {
                    this.props.initialize({})
                    this.resetFilters()
                  }}
                >
                  <i className="ico-cancel" />
                  {t('MASTERS.RESET_BUTTON')}
                </Button>
              </Col>
            </Row>
          </div>
        </Row>
        <Col sm={12} className="col-row  section-users-body">
          <Table className="editable-table" hover responsive>
            <thead>
              <tr>
                <th onClick={() => { this.changeOrder('code') }}>
                  {t(`${tKey}CODE`)}
                  <i className={this.getSortIcon('code')} />
                </th>
                <th onClick={() => { this.changeOrder('description') }}>
                  {t(`${tKey}DESCRIPTION`)}
                  <i className={this.getSortIcon('description')} />
                </th>
                <th onClick={() => { this.changeOrder('startDate') }}>
                  {t(`${tKey}STARTDATE`)}
                  <i className={this.getSortIcon('startDate')} />
                </th>
                <th onClick={() => { this.changeOrder('endDate') }}>
                  {t(`${tKey}ENDDATE`)}
                  <i className={this.getSortIcon('endDate')} />
                </th>
                <th onClick={() => { this.changeOrder('campaignClassCode') }}>
                  {t(`${tKey}CLASSTYPE`)}
                  <i className={this.getSortIcon('campaignClassCode')} />
                </th>
                <th onClick={() => { this.changeOrder('groupCode') }}>
                  {t(`${tKey}GROUPTYPE`)}
                  <i className={this.getSortIcon('groupCode')} />
                </th>
                <th onClick={() => { this.changeOrder('typeCode') }}>
                  {t(`${tKey}CAMPAIGN_TYPE`)}
                  <i className={this.getSortIcon('typeCode')} />
                </th>

                <th style={{ width: '120px' }}>{t('DOSSIER_COMPONENTS.ACCESSORIES.ACTIONS')}</th>
              </tr>
            </thead>
            <tbody>
              {campaignList && campaignList.map((campaign, idx) => {
                return (
                  <tr key={idx} className={campaign.isDeleted ? 'is-deleted' : ''}>
                    <td>{campaign.campaignDetailsCode}</td>
                    <td>{campaign.description}</td>
                    <td>{campaign.startDate}</td>
                    <td>{campaign.endDate}</td>
                    <td>{ campaign.campaignClassCode } </td>
                    <td>{campaign.groupCode}</td>
                    <td>{campaign.typeCode}</td>

                    <td>
                      {!campaign.isDeleted ? (
                        <div>
                          {campaign.isCampaign && (
                            <a onClick={() => openEditDocumentalConditionModalSuccess(campaign)}>
                              <OverlayTrigger placement="left" overlay={documentTooltip}>
                                <i className="ico-document-lines" />
                              </OverlayTrigger>
                            </a>)}
                          {!campaign.amountFree && !campaign.percentageFree && (
                            <a onClick={() => openCampaingModalCountryDetailSuccess(campaign)}>
                              <OverlayTrigger placement="left" overlay={importTooltip}>
                                <i className="ico-economy" />
                              </OverlayTrigger>
                            </a>)}
                          <a onClick={() => openEditCampaignDetailsModalSuccess(campaign)}>
                            <OverlayTrigger placement="left" overlay={editTooltip}>
                              <i className="ico-edit-white" />
                            </OverlayTrigger>
                          </a>
                          <a onClick={() => deleteCampaignDetail(campaign.campaignDetailsId)}>
                            <OverlayTrigger placement="left" overlay={deleteTooltip}>
                              <i className="ico-trash" />
                            </OverlayTrigger>
                          </a>
                        </div>
                      ) : (
                        <a onClick={() => recoverCampaignDetail(campaign.campaignDetailsId)}>
                          <OverlayTrigger placement="left" overlay={recoverTooltip}>
                            <Glyphicon glyph="repeat" />
                          </OverlayTrigger>
                        </a>
                      )}
                    </td>
                  </tr>)
              })
              }
              {(campaignList === null || campaignList === undefined || campaignList.length === 0) &&
            <tr>
              <td colSpan={10}><h2>{t(`${tKey}RESULTS_NOT_FOUND`)}</h2></td>
            </tr>}
              <tr className="button-table-wrapper">
                <td>
                  <Button
                    className="btn-editable-table btn-standard"
                    onClick={() => openAddCampaignModalSuccess()}>
                  + {t(`${tKey}ADD`)}
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>
          <div className="section-users-footer">
            <nav key={1} className="text-center">
              <IDocCarPagination
                id="btn_pag_sales"
                page={page}
                pagesCount={pagesCount}
                totalRows={campaignCount}
                onSelectAction={(value) => {
                  fetchCampaignList({ ...filter, ...this.state.filter, page: value })
                } }
              />
            </nav>
          </div>
        </Col>
      </div>
    )
  }
}

export default reduxForm({
  form: 'listCampaign'
})(CampaignMaster)
