import React, { PureComponent } from 'react'
import { Modal, Col, Row, Button, FormGroup } from 'react-bootstrap'
import { reduxForm, Field } from 'redux-form'
import InputText from '../../commons/form/InputText'
import TextInput from '../../commons/TextInput'
import InputSelect from '../../commons/form/InputSelect'
import InputPercentage from '../../commons/form/InputPercentage'

class CodeCommissionMasterModal extends PureComponent {
  constructor () {
    super()
    this.state = {
      type: null,
      values: null,
      deleted: false
    }
    this.required = this.required.bind(this)
  }

  submitDocument (values) {
    var commissionCancellationCodeLanguage = []
    Object.keys(values).map(item => {
      if (item.includes('lang')) {
        commissionCancellationCodeLanguage.push(values[item])
      }
    })
    var codeCommission = {}
    codeCommission.commissionCancellationCodeId = this.props.codeCommission?.commissionCancellationCodeId
    codeCommission.code = values.code
    codeCommission.commissionCancellationCodeLanguage = commissionCancellationCodeLanguage
    codeCommission.organizedUnitId = values.organizedUnitId
    codeCommission.fixedPercentage = values.fixedPercentage
    codeCommission.qualitativePercentage = values.qualitativePercentage
    codeCommission.quantitativePercentage = values.quantitativePercentage

    this.props.actions.createOrUpdateCodeCommission(codeCommission, this.props.uoId)
  }

  required (value) {
    if (value || value === 0) {
      return undefined
    } else {
      return this.props.t('DOSSIER_COMPONENTS.VALIDATIONS.MANDATORY_FIELD')
    }
  }

  render () {
    const {
      t, handleSubmit,
      showModal, languageList,
      combos: { organizedUnitsAllCombo }
    } = this.props
    const tKey = 'MASTERS.CODE_COMMISSION.'
    return (
      <Modal className="languages-modal" show={showModal} onHide={this.props.actions.closeCodeCommissionModal} backdrop={'static'}>
        <form autoComplete="off" onSubmit={handleSubmit(this.submitDocument.bind(this))}>
          <Modal.Header closeButton onHide={this.props.actions.closeCodeCommissionModal}>
            <Modal.Title>{t(`${tKey}TITLE`)}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="flexRow">
              <Col sm={8} className="custom-width-col">
                <Field
                  t={t}
                  colSm={6}
                  id='code'
                  name='code'
                  placeholder={t(`${tKey}CODE`)}
                  controlLabel={t(`${tKey}CODE`)}
                  component={InputText}
                  maxLength={20}
                  validate={[this.required]}
                />
              </Col>
            </Row>
            <Row>
              <Row className="title-column">
                <Col sm={8}>
                  <h2>{t('MASTERS.DESCRIPTION')}</h2>
                </Col>
                <Col sm={4}>
                  <h2>{t('MASTERS.LANGUAGE')}</h2>
                </Col>
              </Row>
              <FormGroup>
                {languageList?.map((val, idx) => {
                  return (
                    <Row key={idx} className="inputs-wrapper">
                      <Col sm={8} className="custom-width-col">
                        <Field
                          name={`lang${val.languageId}.description`}
                          placeholder={t('MASTERS.DESCRIPTION')}
                          component={TextInput}
                          maxLength={100}
                        />
                      </Col>
                      <Col sm={4} className="language-column">
                        <p>{`${val.description} (${val.name})`}</p>
                      </Col>
                    </Row>
                  )
                })}
              </FormGroup>
            </Row>
            <Row>
              <Col sm={4} className="custom-width-col">
                <Field
                  name="organizedUnitId"
                  controlLabel={t('DOSSIER_COMPONENTS.HEADER.UO')}
                  placeholder={t('DOSSIER_COMPONENTS.HEADER.UO')}
                  valueKey="id"
                  labelKey="value"
                  component={InputSelect}
                  colSm={0}
                  options={organizedUnitsAllCombo}
                  validate={[this.required]}
                  disabled={this.props.codeCommission?.isInUse}
                />
              </Col>
            </Row>
            <Row className='flexRow'>
              <Field
                id="fixedPercentage"
                name="fixedPercentage"
                type="text"
                colSm={4}
                controlLabel={t(`${tKey}FIXED_COMMISSION`)}
                placeholder={t(`${tKey}FIXED_COMMISSION`)}
                component={InputPercentage}
                validate={[this.required]}
                maxLength={14}
              />
              <Field
                id="quantitativePercentage"
                name="quantitativePercentage"
                type="text"
                colSm={4}
                controlLabel={t(`${tKey}QUANTITATIVE_COMMISSION`)}
                placeholder={t(`${tKey}QUANTITATIVE_COMMISSION`)}
                component={InputPercentage}
                validate={[this.required]}
                maxLength={14}
              />
              <Field
                id="qualitativePercentage"
                name="qualitativePercentage"
                type="text"
                colSm={4}
                controlLabel={t(`${tKey}QUALITATIVE_COMMISSION`)}
                placeholder={t(`${tKey}QUALITATIVE_COMMISSION`)}
                component={InputPercentage}
                validate={[this.required]}
                maxLength={14}
              />
            </Row>

          </Modal.Body>
          <Modal.Footer>
            <Col sm={6} className="btn-wrapper" smPush={6}>
              <Button type="submit" className="btn-standard" disabled={this.props.pristine}>
                {t('MASTERS.SAVE')}
              </Button>
            </Col>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default reduxForm({
  form: 'codeCommissionMasterModal',
  validate: (values, props) => {
    let error = {}

    props.languageList && props.languageList?.forEach((element) => {
      let valueLangDesc = values['lang' + element.languageId]?.description
      let errorLang = {
        name: null,
        description: null
      }

      if (!valueLangDesc) {
        errorLang.description = props.t('DOSSIER_COMPONENTS.VALIDATIONS.MANDATORY_FIELD')
      }
      error['lang' + element.languageId] = errorLang
    })

    return Object.keys(error).length > 0 ? error : {}
  }
})(CodeCommissionMasterModal)
