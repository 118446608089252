import {
  fetchDocumentTypeMetadataListSuccess, closeMetadataMasterModal, openDocumentTypeMetadataModalSuccess, openMetadataListModalSuccess
} from '../../../actions/masters/metadata/metadata'
import { put, call, select, takeEvery } from 'redux-saga/effects'
import { getAuth } from '../../../selectors/access/auth'
import { showLoader, hideLoader } from '../../../actions/common.js'
import { handleError } from '../../errors/errorManager'
import mastersActionTypes from '../../../constants/actions/masters/masters'
import { getDocumentTypeMetadataFilter, getMetadataListFilter } from '../../../selectors/masters/document'

import postDocumentTypeMetadataByDocumentTypeService from '../../../services/documentType/postDocumentTypeMetadataByDocumentType'
import putDocumentTypeMetadataService from '../../../services/documentTypeMetadata/putDocumentTypeMetadata'
import postDocumentTypeMetadataService from '../../../services/documentTypeMetadata/postDocumentTypeMetadata'
import deleteDocumentTypeMetadataService from '../../../services/documentTypeMetadata/deleteDocumentTypeMetadata'
import deleteMetadataListService from '../../../services/metadata/deleteMetadataList'

import postMetadataListFromMetadataService from '../../../services/metadata/postMetadataListFromMetadata'
import putMetadataListService from '../../../services/metadata/putMetadataList'
import postMetadataListService from '../../../services/metadata/postMetadataList'
import { yesNoModal } from '../../modalsListeners/yesNoModal'
import getLanguageListComboService from '../../../services/language/getLanguageList'

export function * fetchDocumentTypeMetadataList ({ filter, documentTypeId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield filter = yield filter || select(getDocumentTypeMetadataFilter)
    const documentTypeList = yield call(postDocumentTypeMetadataByDocumentTypeService, auth.token, filter, documentTypeId)
    yield put(fetchDocumentTypeMetadataListSuccess(documentTypeList, filter))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchDocumentTypeMetadataList () {
  yield takeEvery(mastersActionTypes.FETCH_DOCUMENT_TYPE_METADATA_LIST, fetchDocumentTypeMetadataList)
}

export function * openDocumentTypeMetadataModal ({ documentTypeMetadata, documentTypeId }) {
  const auth = yield select(getAuth)
  const languageList = yield call(getLanguageListComboService, auth.token)

  yield put(openDocumentTypeMetadataModalSuccess(languageList, documentTypeMetadata, documentTypeId))
}

export function * watchOpenDocumentTypeMetadataModal () {
  yield takeEvery(mastersActionTypes.OPEN_DOCUMENT_TYPE_METADATA_MODAL, openDocumentTypeMetadataModal)
}

export function * deleteDocumentTypeMetadata ({ id, documentTypeId }) {
  try {
    const confirmed = yield call(yesNoModal, 'deleteDocumentTypeMetadata')
    if (confirmed) {
      yield put(showLoader())
      const auth = yield select(getAuth)
      const filter = yield filter || select(getDocumentTypeMetadataFilter)
      yield call(deleteDocumentTypeMetadataService, auth.token, id)
      yield call(fetchDocumentTypeMetadataList, { filter, documentTypeId })
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDeleteDocumentTypeMetadata () {
  yield takeEvery(mastersActionTypes.DELETE_DOCUMENT_TYPE_METADATA, deleteDocumentTypeMetadata)
}

export function * submitDocumentTypeMetadata ({ documentTypeMetadata, isEditedCriticalProperty }) {
  try {
    let confirmed = true
    if (isEditedCriticalProperty) {
      confirmed = yield call(yesNoModal, 'editDocumentTypeMetadata')
    }
    if (confirmed || !isEditedCriticalProperty) {
      yield put(showLoader())
      const auth = yield select(getAuth)
      const filter = yield filter || select(getDocumentTypeMetadataFilter)

      if (documentTypeMetadata.metadatoId) {
        yield call(putDocumentTypeMetadataService, auth.token, documentTypeMetadata)
      } else {
        yield call(postDocumentTypeMetadataService, auth.token, documentTypeMetadata)
      }

      yield put(closeMetadataMasterModal())
      const documentTypeId = documentTypeMetadata.documentTypeId
      yield call(fetchDocumentTypeMetadataList, { filter, documentTypeId })
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSubmitDocumentTypeMetadata () {
  yield takeEvery(mastersActionTypes.SUBMIT_DOCUMENT_TYPE_METADATA, submitDocumentTypeMetadata)
}

export function * openMetadataListModal ({ documentTypeMetadata }) {
  const auth = yield select(getAuth)
  const filter = yield filter || select(getMetadataListFilter)
  const languageList = yield call(getLanguageListComboService, auth.token)
  const metadataList = yield call(postMetadataListFromMetadataService, auth.token, filter, documentTypeMetadata.metadatoId)

  yield put(openMetadataListModalSuccess(languageList, documentTypeMetadata, metadataList))
}

export function * watchOpenMetadataListModal () {
  yield takeEvery(mastersActionTypes.OPEN_METADATA_LIST_MODAL, openMetadataListModal)
}

export function * deleteMetadataListElement ({ id, documentTypeMetadata }) {
  try {
    const confirmed = yield call(yesNoModal, 'deleteDocumentTypeMetadata')
    if (confirmed) {
      yield put(showLoader())
      const auth = yield select(getAuth)
      yield call(deleteMetadataListService, auth.token, id)
      const filter = yield filter || select(getMetadataListFilter)
      const languageList = yield call(getLanguageListComboService, auth.token)
      const metadataList = yield call(postMetadataListFromMetadataService, auth.token, filter, documentTypeMetadata.metadatoId)

      yield put(openMetadataListModalSuccess(languageList, documentTypeMetadata, metadataList))
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDeleteMetadataListElement () {
  yield takeEvery(mastersActionTypes.DELETE_METADATA_LIST_ELEMENT, deleteMetadataListElement)
}

export function * submitMetadataList ({ metadataListElement, metadata }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const filter = yield filter || select(getMetadataListFilter)
    if (metadataListElement.metadatoListId) {
      yield call(putMetadataListService, auth.token, metadataListElement)
    } else {
      yield call(postMetadataListService, auth.token, metadataListElement)
    }
    const languageList = yield call(getLanguageListComboService, auth.token)
    const metadataList = yield call(postMetadataListFromMetadataService, auth.token, filter, metadata.metadatoId)

    yield put(openMetadataListModalSuccess(languageList, metadata, metadataList))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSubmitMetadataList () {
  yield takeEvery(mastersActionTypes.SUBMIT_METADATA_LIST, submitMetadataList)
}
