import { call, put, select, takeLatest } from 'redux-saga/effects'
import { getAuth } from '../../selectors/access/auth'
import { showLoader, hideLoader } from '../../actions/common'
import messageModalActions from '../../actions/modals/messageModal'
import { fetchCombosSuccess } from '../../actions/combos/combos'
import combosActionTypes from '../../constants/actions/combos/combos'
import { handleError } from '../errors/errorManager'
import getEventsCombo from '../../services/eventSubscription/getEventsCombo'

export function * fetchEventsCombo ({ userId, lowestOrganizedUnitIds }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const events = yield call(getEventsCombo, auth.token, lowestOrganizedUnitIds, userId)
    yield put(fetchCombosSuccess({ events }))
  } catch (error) {
    const customHandler = function * () {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.FETCH_COMBO_ERROR'))
    }
    yield call(handleError, { error, customHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchEventsCombo () {
  yield takeLatest(combosActionTypes.FETCH_EVENTS_COMBO, fetchEventsCombo)
}
