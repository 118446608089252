import statusHeaderActionTypes from '../../../constants/actions/dossier/common/statusHeader'

export function toggleTimeline () {
  return {
    type: statusHeaderActionTypes.TOGGLE_TIMELINE
  }
}

export function setStatusHeaderHeight (height) {
  return {
    type: statusHeaderActionTypes.SET_STATUS_HEADER_HEIGHT,
    height
  }
}

export function resetStatusHeader () {
  return {
    type: statusHeaderActionTypes.RESET_STATUS_HEADER
  }
}

export function switchFavorite (favoriteStatus, dossierId, isHistorical) {
  return {
    type: statusHeaderActionTypes.SWITCH_DOSSIER_FAVORITE,
    favoriteStatus,
    dossierId,
    isHistorical
  }
}

export function switchFavoriteSuccess (newFavoriteStatus) {
  return {
    type: statusHeaderActionTypes.SWITCH_DOSSIER_FAVORITE_SUCCESS,
    newFavoriteStatus
  }
}

export default {
  toggleTimeline,
  setStatusHeaderHeight,
  resetStatusHeader,
  switchFavorite,
  switchFavoriteSuccess
}
