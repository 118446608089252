import { put, call, select, takeEvery } from 'redux-saga/effects'
import { getAuth } from '../../../selectors/access/auth'
import { showLoader, hideLoader, openModal } from '../../../actions/common'
import messageModalActions from '../../../actions/modals/messageModal'
import { fetchDossiersListSuccess } from '../../../actions/dashboard/dossiersList/dossiersList'
import dossiersListActionTypes from '../../../constants/actions/dashboard/dossiersList/dossiersList'
import getDashboardTableService from '../../../services/dossier/get/getDashboardTable'
import getDashboardCompleteTable from '../../../services/dossier/get/getDashboardCompleteTable'
import getDashboardCompleteTableExport from '../../../services/dossier/get/getDashboardCompleteTableExport'
import { handleError } from '../../errors/errorManager'

export function * fetchDossiersList ({ organizedUnit, salesmanId, urgencyTypeId, subTypeId, stageId, page }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const dossiersData = yield call(getDashboardTableService, auth.token, organizedUnit, salesmanId, urgencyTypeId, subTypeId, stageId, page)
    yield put(fetchDossiersListSuccess(dossiersData, urgencyTypeId, page, null))
  } catch (error) {
    const customHandler = function * () {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.STAGE_FILTER_ERROR'))
    }
    yield call(handleError, { error, customHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchDossiersList () {
  yield takeEvery(dossiersListActionTypes.FETCH_DOSSIERS_LIST, fetchDossiersList)
}

export function * fetchDossiersCompleteList ({ organizedUnit, salesmanId, urgencyTypeId, subTypeId, stageId, page, orderBy }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const dossiersData = yield call(getDashboardCompleteTable, auth.token, organizedUnit, salesmanId, urgencyTypeId, subTypeId, stageId, page, orderBy)
    yield put(fetchDossiersListSuccess(dossiersData, urgencyTypeId, page, orderBy))
  } catch (error) {
    const customHandler = function * () {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.STAGE_FILTER_ERROR'))
    }
    yield call(handleError, { error, customHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchDossiersCompleteList () {
  yield takeEvery(dossiersListActionTypes.FETCH_DOSSIERS_COMPLETE_LIST, fetchDossiersCompleteList)
}

export function * exporToExcelDossiersList ({ organizedUnit, salesmanId, urgencyTypeId, urgencyName, subTypeId, stageId, page, orderBy, pageSize, cols, maxDossierExport, showMaxDossierExportMessage }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(getDashboardCompleteTableExport, auth.token, {
      OrganizedUnit: organizedUnit && organizedUnit !== '0' ? organizedUnit : null,
      Salesman: salesmanId && salesmanId !== '0' ? salesmanId : null,
      UrgencyTypeId: urgencyTypeId && urgencyTypeId !== '0' ? urgencyTypeId : null,
      UrgencyName: urgencyName,
      DossierSubTypeId: subTypeId && subTypeId !== '0' ? subTypeId : null,
      DossierStageId: stageId && stageId !== '0' ? stageId : null,
      Page: page,
      OrderBy: orderBy,
      PageSize: pageSize,
      Columns: cols
    })
    if (showMaxDossierExportMessage) {
      yield put(hideLoader())
      yield put(openModal('dossierListExportReducedSuccess', null, null, null, null, [{ id: '##AMOUNT##', value: maxDossierExport }], null))
    } else {
      yield put(messageModalActions.openSuccessMessageModal('MESSAGES.DOSSIER_LIST_EXPORT_SUCCESS'))
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchExportDossiersCompleteList () {
  yield takeEvery(dossiersListActionTypes.FETCH_EXPORT_COMPLETE_LIST, exporToExcelDossiersList)
}
