import React, { PureComponent } from 'react'
import { reduxForm, Field } from 'redux-form'
import { Row, Col, Table, Button, Tooltip, OverlayTrigger, Glyphicon, Checkbox } from 'react-bootstrap'
import IDocCarPagination from '../../commons/iDocCarPagination'
import InputText from '../../commons/form/InputText'
import OperationTypeMasterModalPage from '../../../containers/masters/OperationType/OperationTypeMasterModalPage'

class OperationTypeMaster extends PureComponent {
  constructor () {
    super()
    this.state = {
      filter: {
        text: null,
        code: null
      }
    }
  }

  getSortIcon (column = '') {
    switch (this.props.filter.orderBy) {
      case '+' + column:
        return 'ico-sort-selected-asc'
      case '-' + column:
        return 'ico-sort-selected-desc'
      default:
        return 'ico-sort'
    }
  }

  changeOrder (field) {
    const orderBy = this.props.filter.orderBy === `+${field}` ? `-${field}` : `+${field}`
    this.props.actions.fetchOperationTypesTable({ ...this.state.filter, ...this.props.filter, orderBy: orderBy })
  }

  handleCodeFilterChange (code) {
    this.setState({ filter: { ...this.state.filter, code } })
  }

  handleTextFilterChange (text) {
    this.setState({ filter: { ...this.state.filter, text } })
  }

  searchOperationTypes () {
    this.props.actions.fetchOperationTypesTable({ ...this.props.filter, ...this.state.filter, page: 1 })
  }

  recoverOperationType (operationTypeId) {
    this.props.actions.openModal('recoverOperationType', () => this.props.actions.recoverOperationType(operationTypeId))
  }

  resetFilters () {
    this.setState({
      filter: {
        text: null,
        code: null
      }
    }, () => { this.searchOperationTypes() })
  }

  render () {
    const {
      t, operationTypeList, pageSize, rowsCount, filter,
      filter: { page },
      actions: { fetchOperationTypesTable, openOperationTypeModal, deleteOperationType }
    } = this.props
    const tKey = 'MASTERS.OPERATION_TYPE_MASTER.'
    const editTooltip = (<Tooltip id="editTooltip">{t(`${tKey}EDIT`)}</Tooltip>)
    const deleteTooltip = (<Tooltip id="deleteTooltip">{t(`${tKey}DELETE`)}</Tooltip>)
    const recoverTooltip = (<Tooltip id="deleteTooltip">{t(`${tKey}RECOVER`)}</Tooltip>)
    const hasFilters = (this.state.filter.code || this.state.filter.text)
    return (
      <div className="admin-wrapper">
        <OperationTypeMasterModalPage />
        <Row className="section-users-header">
          <Col sm={12}>
            <div className="header-panel">
              <h4>{t(`${tKey}OPERATION_TYPE`)}</h4>
            </div>
          </Col>
        </Row>
        <Row>
          <div className="search-form">
            <span className="title-search"><i className="ico-search" /> {t(`${tKey}SEARCH_TITLE`)}</span>
            <Row>
              <Field
                name="operationTypeCodeFilter"
                colSm={4}
                controlLabel={t(`${tKey}CODE`)}
                placeholder={t(`${tKey}CODE`)}
                component={InputText}
                onInputChange={(value) => this.handleCodeFilterChange(value)}
              />
              <Field
                name="operationTypeTextFilter"
                colSm={4}
                controlLabel={t(`${tKey}TEXT`)}
                placeholder={t(`${tKey}TEXT`)}
                component={InputText}
                onInputChange={(value) => this.handleTextFilterChange(value)}
                maxLength= {50}
              />
              <Col sm={4} className="btn-wrapper">
                <p></p>
                <Button
                  className={'btn-white btn-icon btn-input btn-white search-button'}
                  style={{ width: '100%' }}
                  onClick={() => this.searchOperationTypes()}>
                  <i className="ico-search" />
                  {t(`${tKey}SEARCH_BTN`)}
                </Button>
                <Button
                  className={'btn-icon btn-input ' + (hasFilters ? 'btn-standard' : 'btn-cancel')}
                  onClick={() => {
                    this.props.initialize({})
                    this.resetFilters()
                  }}
                >
                  <i className="ico-cancel" />
                  {t('MASTERS.RESET_BUTTON')}
                </Button>
              </Col>
            </Row>
          </div>
        </Row>
        <Col sm={12} className="col-row  section-users-body">
          <Table className="editable-table" hover responsive>
            <thead>
              <tr>
                <th onClick={() => { this.changeOrder('code') }}>
                  {t(`${tKey}CODE`)}
                  <i className={this.getSortIcon('code')} />
                </th>
                <th onClick={() => { this.changeOrder('name') }}>
                  {t(`${tKey}NAME`)}
                  <i className={this.getSortIcon('name')} />
                </th>
                <th onClick={() => { this.changeOrder('description') }}>
                  {t(`${tKey}DESCRIPTION`)}
                  <i className={this.getSortIcon('description')} />
                </th>
                <th onClick={() => { this.changeOrder('isFleet') }}>
                  {t(`${tKey}IS_FLEET`)}
                  <i className={this.getSortIcon('isFleet')} />
                </th>
                <th style={{ width: '77px' }}>{t('DOSSIER_COMPONENTS.ACCESSORIES.ACTIONS')}</th>
              </tr>
            </thead>
            <tbody>
              {operationTypeList && operationTypeList.map((operationType, idx) => {
                return (
                  <tr key={idx} className={operationType.isDeleted ? 'is-deleted' : ''}>
                    <td>{operationType.code}</td>
                    <td>{operationType.operationTypeLocales && operationType.operationTypeLocales.length > 0 ? operationType.operationTypeLocales[0].name : ''}</td>
                    <td>{operationType.operationTypeLocales && operationType.operationTypeLocales.length > 0 ? operationType.operationTypeLocales[0].description : ''}</td>
                    <td style={ { paddingLeft: '30px' } }><span><Checkbox className="checkbox-no-label" checked={operationType.isFleet} disabled inline /></span></td>
                    <td>
                      {!operationType.isDeleted ? (
                        <div>
                          <a onClick={() => openOperationTypeModal(operationType.operationTypeId)}>
                            <OverlayTrigger placement="left" overlay={editTooltip}>
                              <i className="ico-edit-white" />
                            </OverlayTrigger>
                          </a>
                          <a onClick={() => deleteOperationType(operationType.operationTypeId)}>
                            <OverlayTrigger placement="left" overlay={deleteTooltip}>
                              <i className="ico-trash" />
                            </OverlayTrigger>
                          </a>
                        </div>
                      ) : (
                        <a onClick={() => this.recoverOperationType(operationType.operationTypeId)}>
                          <OverlayTrigger placement="left" overlay={recoverTooltip}>
                            <Glyphicon glyph="repeat" />
                          </OverlayTrigger>
                        </a>
                      )}
                    </td>
                  </tr>)
              })
              }
              {(operationTypeList === null || operationTypeList === undefined || operationTypeList.length === 0) &&
                                <tr>
                                  <td colSpan={10}><h2>{t(`${tKey}RESULTS_NOT_FOUND`)}</h2></td>
                                </tr>}
              <tr className="button-table-wrapper">
                <td>
                  <Button
                    className="btn-editable-table btn-standard"
                    onClick={() => openOperationTypeModal()}>
                                        + {t(`${tKey}ADD`)}
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>
          <div className="section-users-footer">
            <nav key={1} className="text-center">
              <IDocCarPagination
                id="btn_pag_sales"
                page={page}
                pageSize={pageSize}
                totalRows={rowsCount}
                onSelectAction={(value) => fetchOperationTypesTable({ ...filter, ...this.state.filter, page: value })}
              />
            </nav>
          </div>
        </Col>
      </div>
    )
  }
}

export default reduxForm({
  form: 'operationTypeMaster'
})(OperationTypeMaster)
