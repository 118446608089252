import React, { PureComponent } from 'react'
import { reduxForm, Field, reset } from 'redux-form'
import { Tooltip, OverlayTrigger, Form, Button, ProgressBar, DropdownButton, Glyphicon } from 'react-bootstrap'
import { validateDate } from '../../../util/validationFunctions'
import SimpleTablePage from '../../../containers/commons/general/SimpleTablePage'
import IDocCarPagination from '../../../components/commons/iDocCarPagination'
import { formatDate, getDateNowMaxDatePicker, fromCamelToUnderscore } from '../../../util/utils'
import moment from 'moment'
import AuditModalPage from '../../../containers/management/auxModals/AuditModalPage'
import { dossierTypes } from '../../../constants/backendIds'
/// ////////////////////////////////////////////////////////////////////////////
import MapSectionsManagement from '../../../_v2/util/map-sections-management'
import InputSelect from '../../../_v2/components/commons/form/InputSelect'
import InputDatePicker from '../../../_v2/components/commons/form/InputDatePicker'
import InputText from '../../../_v2/components/commons/form/InputText'
import { hideFiltersBtn, toggleFilterClass, getIconClass, showFilter } from '../../../_v2/util/management/ManagementUtils'

class AuditAdmin extends PureComponent {
  constructor () {
    super()
    this.state = {
      fields: [],
      filters: [],
      dynamicFilters: [],
      disableResetButton: false,
      displayBar: 'none',
      progress: 0,
      progressFail: 0,
      errors: [],
      maxBar: 0,
      readOnly: false,
      visible: false,
      maxiTable: false,
      hideFilters: false
    }
  }

  componentDidMount () {
    this.props.actions.fetchAuditConfigurationType()
    this.props.actions.fetchDossierTypes()
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props._reduxForm.auditAdminModal.showModal === false && prevProps._reduxForm.auditAdminModal.showModal === true) {
      this.setState({ visible: false })
      if (this.props.items?.length > 0) {
        let filters = this.state.filters
        let propFilters = this.props.filters
        this.props.actions.fetchAuditAdminInfo({ ...filters, ...propFilters })
      }
    }
  }

  submitSearchForm (values) {
    this.setState({ filters: values })
    let filters = values
    let propFilters = this.props.filters
    propFilters.orderBy = '+AuditDescription'
    this.props.actions.fetchAuditAdminInfo({ ...filters, ...propFilters })
    this.setState({ dynamicFilters: filters, hideFilters: true })
  }

  fieldFill (field) {
    this.setState({ fields: { ...this.state.fields, ...field } })
  }

  onDossierTypeChange (dossierTypesId) {
    this.props.actions.fetchDossierSubTypes(null, dossierTypesId)
  }

  dateValidation (value, allValues, props) {
    const result = validateDate(value, props)
    return result === Object(result) ? undefined : result
  }

  clearFields () {
    this.setState({ fields: {} })
  }

  handleClickReset (e) {
    if (e !== undefined) {
      e.preventDefault()
    }
    this.clearFields()
    this.props.actions.resetFiltersAuditAdminInfo()
    this.props.dispatch(reset('auditAdminForm'))
    this.setState({ dynamicFilters: [] })
  }

  onOrderChange (e) {
    const newOrderBy = this.props.filters.orderBy.includes(`+${e.currentTarget.dataset.field}`) ? `-${e.currentTarget.dataset.field}` : `+${e.currentTarget.dataset.field}`
    this.fetchTable({ ...this.props.filters, orderBy: newOrderBy })
  }

  onPageChange (page) {
    this.fetchTable({ ...this.props.filters, page })
  }

  fetchTable (propFilters) {
    let filters = this.state.dynamicFilters
    this.props.actions.fetchAuditAdminInfo({ ...filters, ...propFilters })
    this.setState({ dynamicFilters: filters })
  }

  editAuditAdmin (row, type) {
    const readOnly = (type === false && row.auditExpirationDate && moment(row.auditExpirationDate) <= moment(new Date()))
    this.setState({ readOnly: readOnly, visible: true }, () => {
      const auditConfiguration = {
        AuditConfigurationId: row.auditConfigurationId,
        AuditCode: row.auditCode,
        documentTypeUses: row.auditConfigurationDocuments,
        AuditDescription: row.auditDescription,
        AuditExpirationDate: row.auditExpirationDate ? formatDate(row.auditExpirationDate) : null,
        AuditLoadDataIds: row.auditLoadDataIds,
        AuditDocumentsIds: row.auditLoadDataIds,
        AuditUserObservations: row.auditUserObservations,
        dossierSubtypeId: row.dossierSubTypeId,
        dossierTypeId: row.dossierTypeId,
        AuditConfigurationTypeId: row.auditConfigurationTypeId,
        AuditAdminObservations: row.auditAdminObservations,
        AuditObservation: type,
        audit: false
      }
      this.props.actions.openAuditAdminModal(auditConfiguration)
    }
    )
  }

  deleteAuditAdmin (row) {
    this.props.actions.openModal('deleteAudit', () => {
      return new Promise((resolve, reject) => {
        this.props.actions.deleteAuditAdmin(row.auditConfigurationId, resolve, reject)
      }).then(() => {
        let filters = this.state.filters
        let propFilters = this.props.filters
        this.props.actions.fetchAuditAdminInfo({ ...filters, ...propFilters })
        this.props.actions.openModal('confirmDeleteAudit', null, null, null, null, [{ id: '##CODE##', value: row.auditCode }, { id: '##DESCRIPTION##', value: row.auditDescription }], null)
      })
    })
  }

  hideAuditModal () {
    this.setState({ visible: false })
  }

  toogleHideFilters () {
    this.setState({ hideFilters: !this.state.hideFilters })
  }

  toogleMaxiTable () {
    this.setState({ maxiTable: !this.state.maxiTable })
  }

  selectedFilters () {
    const filters = this.state.filters
    const tKey = 'MANAGEMENT.ADMIN_AUDIT.'
    return Object.keys(filters)
      .filter(item => { return item !== 'page' && item !== 'pageSize' && item !== 'orderBy' })
      .map((item, index) => {
        let text = item
        let value = null
        const list = []

        switch (item) {
          case 'dossierTypeId': {
            const dossierType = this.props.combos.dossierTypesCombo.find(dt => dt.id === filters[item])
            value = dossierType && dossierType.value
            text = this.props.t(`${tKey}DOSSIER_TYPE`)
            break
          }
          case 'dossierSubTypeId': {
            const dossierSubtype = this.props.combos.dossierSubTypesCombo.find(dt => dt.id === filters[item])
            value = dossierSubtype && dossierSubtype.value
            text = this.props.t(`${tKey}DOSSIER_SUBTYPE`)
            break
          }
          case 'creationDateFrom': {
            value = filters[item]
            text = this.props.t(`${tKey}VALIDATED_DATE_FROM`)
            break
          }
          case 'creationDateTo': {
            value = filters[item]
            text = this.props.t(`${tKey}VALIDATED_DATE_TO`)
            break
          }
          case 'auditCode': {
            value = filters[item]
            text = this.props.t(`${tKey}CODE`)
            break
          }
          case 'auditConfigurationTypeId': {
            const configType = this.props.combos.auditConfigurationType.find(dt => dt.id === filters[item])
            value = configType && configType.value
            text = this.props.t(`${tKey}AUDIT_TYPE`)
            break
          }
          default: {
            value = filters[item]
            text = this.props.t(`${tKey}${fromCamelToUnderscore(item).toUpperCase()}`)
            break
          }
        }

        if (value) {
          list.push(<li key={index}>{text}: <strong>{value}</strong>;</li>)
        }
        return list
      })
  }

  render () {
    const {
      t, handleSubmit, className, hasSearch, count, items, filters: { page, pages },
      combos: {
        dossierSubTypesCombo,
        auditConfigurationType,
        dossierTypesCombo
      }
    } = this.props
    const tKey = 'MANAGEMENT.ADMIN_AUDIT.'
    const dKey = 'GLOBAL.TABLES.'
    const secMapManagement = MapSectionsManagement.find(function (s) { return s.page === 'auditAdminPage' })
    const hasResults = items && items.length > 0
    return (
      <>
        { this.state.visible &&
          <AuditModalPage type={this.state.type} readOnly={this.state.readOnly} hideModal={() => this.hideAuditModal()} isAdmin={true}/>
        }
        <div className="inner-results-panel">
          <div className="section-results-header">
            <div className="section-header-title">
              <OverlayTrigger placement="right" overlay={<Tooltip id="editProductTooltip">{`${t('GLOBAL.TABLES.' + (this.props.hideMenu ? 'SHOW_INNER_MENU' : 'HIDE_INNER_MENU'))}`}</Tooltip>}>
                <a onClick={() => this.props.toogleInnerMenu()} className={this.props.hideMenu ? 'collapseBtn' : 'collapseBtn closeInnerMenu'}><i className={this.props.hideMenu ? 'iDocIcon-Open-inner-menu' : 'iDocIcon-Close-inner-menu'}></i></a>
              </OverlayTrigger>
              <h4>{t(`${secMapManagement.title}`)}
                <OverlayTrigger placement="left" overlay={<Tooltip id="editProductTooltip">{`${t((this.state.hideFilters ? 'GLOBAL.TABLES.SHOW_FILTERS' : 'GLOBAL.TABLES.HIDE_FILTERS'))}`}</Tooltip>}>
                  <a onClick={() => this.toogleHideFilters()} className={'toogleShowFilters ' + hideFiltersBtn(hasResults)}><i className={toggleFilterClass(hasResults, this.state.hideFilters)}></i></a>
                </OverlayTrigger>
              </h4>
            </div>

            <Form autoComplete="off" onSubmit={handleSubmit((values) => this.submitSearchForm(values))} className="filters">
              <div className={'search-form ' + (showFilter(hasResults, this.state.hideFilters) ? '' : 'hideFilters ')}>
                <Field
                  component={InputText}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="auditCode"
                  name="auditCode"
                  controlLabel={t(`${tKey}CODE`)}
                  placeholder={t(`${tKey}CODE`)}
                />
                <Field
                  component={InputSelect}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="auditConfigurationTypeId"
                  name='auditConfigurationTypeId'
                  placeholder={t(`${tKey}AUDIT_TYPE`)}
                  controlLabel={t(`${tKey}AUDIT_TYPE`)}
                  options={auditConfigurationType}
                  valueKey="id"
                  labelKey="value"
                  customClass={this.state.fields.dossierSubtypeId ? className : ''}
                />
                <Field
                  component={InputSelect}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="dossierTypeId"
                  name='dossierTypeId'
                  placeholder={t(`${tKey}DOSSIER_TYPE`)}
                  controlLabel={t(`${tKey}DOSSIER_TYPE`)}
                  options={dossierTypesCombo.filter(x => x.id === dossierTypes.sales)}
                  valueKey="id"
                  labelKey="value"
                  onInputChange={(val) => { this.onDossierTypeChange(val); this.fieldFill({ dossierSubtypeId: val }) }}
                  customClass={this.state.fields.dossierSubtypeId ? className : ''}
                />
                <Field
                  component={InputSelect}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="dossierSubTypeId"
                  name='dossierSubTypeId'
                  placeholder={t(`${tKey}DOSSIER_SUBTYPE`)}
                  controlLabel={t(`${tKey}DOSSIER_SUBTYPE`)}
                  options={dossierSubTypesCombo}
                  valueKey="id"
                  labelKey="value"
                  customClass={this.state.fields.dossierSubtypeId ? className : ''}
                />
                <Field
                  component={InputDatePicker}
                  colWidht={50} // 25, 33, 50, 75, 100 default=25
                  maxDate={getDateNowMaxDatePicker()}
                  id="creationDateFrom"
                  name='creationDateFrom'
                  placeholder={t(`${tKey}VALIDATED_DATE_FROM`)}
                  controlLabel={t(`${tKey}VALIDATED_DATE_FROM`)}
                  validate={[this.dateValidation]}
                  onInputChange={(val) => this.fieldFill({ creationDateFrom: val })}
                  customClass={this.state.fields.creationDatefrom ? className : ''}
                />
                <Field
                  component={InputDatePicker}
                  colWidht={50} // 25, 33, 50, 75, 100 default=25
                  maxDate={getDateNowMaxDatePicker()}
                  id="creationDateTo"
                  name='creationDateTo'
                  placeholder={t(`${tKey}VALIDATED_DATE_TO`)}
                  controlLabel={t(`${tKey}VALIDATED_DATE_TO`)}
                  validate={[this.dateValidation]}
                  onInputChange={(val) => this.fieldFill({ creationDateTo: val })}
                  customClass={this.state.fields.creationDateTo ? className : ''}
                />
                <div className='search-form-actions flexInLine al-R'>
                  <Button id="btn_search"
                    type="submit"
                    className="_Btn accept stk">
                    <i className="iDocIcon-search"/>
                    {t('SEARCH.ACTIONS.SEARCH_BUTTON')}
                  </Button>
                  <Button
                    id="btn_reset"
                    onClick={(e) => this.handleClickReset(e)}
                    className={'_Btn' + (this.props.pristine ? ' accept' : ' accept')}
                    disabled={this.props.pristine}>
                    <i className="iDocIcon-clear-decline"/>
                    {t('SEARCH.ACTIONS.RESET_BUTTON')}
                  </Button>
                </div>
              </div>
            </Form>
          </div>
          <div className="section-results-body">
            <div className={'searchResoultsContainer' + (this.state.maxiTable ? ' maxiTable' : '')}>
              {hasSearch && ([
                <div className="table-wrapper fleets" key="table-wrapper">
                  <ul className="list-inline pull-left">
                    {this.selectedFilters().length === 0 ? <li>{t(`${tKey}NO_SELECTED_FILTERS`)}</li> : (this.selectedFilters())}
                  </ul>
                  {/* table options, maximize, etc.... */}
                  {hasResults &&
                  <div className='flexInLine al-R'>
                    <OverlayTrigger placement="left" overlay={<Tooltip id="editProductTooltip">{`${t(dKey + (this.state.maxiTable ? 'MINIMIZE_TABLE' : 'MAXIMIZE_TABLE'))}`}</Tooltip>}>
                      <a onClick={() => this.toogleMaxiTable()} className='_Btn accept stk o-icn maximizeBtn'><i className={(this.state.maxiTable ? 'iDocIcon-Minimize' : 'iDocIcon-Maximize')}></i></a>
                    </OverlayTrigger>
                  </div>}
                  {/* table */}
                  {hasResults ? [
                    <div className="table-responsive">
                      <SimpleTablePage
                        columns={[
                          <th key={1} data-field="AuditCode" onClick={(event) => this.onOrderChange(event)}>
                            {t(`${tKey}CODE`)}&nbsp;<i className={getIconClass('AuditCode', this.props.filters)}/>
                          </th>,
                          <th key={2} data-field="AuditDescription" onClick={(event) => this.onOrderChange(event)}>
                            {t(`${tKey}DESCRIPTION`)}&nbsp;<i className={getIconClass('AuditDescription', this.props.filters)}/>
                          </th>,
                          <th key={3} data-field="AuditConfigurationType" onClick={(event) => this.onOrderChange(event)}>
                            {t(`${tKey}AUDIT_TYPE`)}&nbsp;<i className={getIconClass('AuditConfigurationType', this.props.filters)}/>
                          </th>,
                          <th key={4} data-field="DossierSubType" onClick={(event) => this.onOrderChange(event)}>
                            {t(`${tKey}DOSSIER_SUBTYPE`)}&nbsp;<i className={getIconClass('DossierSubType', this.props.filters)}/>
                          </th>,
                          <th key={5} data-field="AuditExpirationDate" onClick={(event) => this.onOrderChange(event)}>
                            {t(`${tKey}VALIDATED_DATE`)}&nbsp;<i className={getIconClass('AuditExpirationDate', this.props.filters)}/>
                          </th>,
                          <th key={6} data-field="CreationDate" onClick={(event) => this.onOrderChange(event)}>
                            {t(`${tKey}CREATION_DATE`)}&nbsp;<i className={getIconClass('CreationDate', this.props.filters)}/>
                          </th>,
                          <th key={7} data-field="actions">{t(`${tKey}ACTIONS`)}&nbsp;</th>
                        ]}
                        rows={items && items.map((row, idx) => (
                          <tr key={idx}>
                            <td>{row.auditCode}</td>
                            <td>{row.auditDescription}</td>
                            <td>{row.auditConfigurationType}</td>
                            <td>{row.dossierSubType}</td>
                            <td>{(row.auditExpirationDate) ? formatDate(row.auditExpirationDate) : '-'}</td>
                            <td>{formatDate(row.creationDate)}</td>
                            <td className="actionsMenu see-more">
                              <DropdownButton
                                as={'ButtonGroup'}
                                key={1}
                                drop={'start'}
                                className={'_Btn accept stk o-icn'}
                                title={<i className="glyphicon glyphicon-option-vertical"/>}
                                id="dropdown-button-drop-start"
                              >
                                <li><Button id="btn_observations"
                                  className="btn-actions"
                                  onClick={() => this.editAuditAdmin(row, true)}>
                                  {t(`${tKey}OBSERVATIONS`)}
                                  <Glyphicon className='icon-action blue' glyph="comment"/>
                                </Button></li>
                                <li><Button id="btn_edit"
                                  className="btn-actions"
                                  onClick={() => this.editAuditAdmin(row, false)}>
                                  {t(`${tKey}EDIT`)}
                                  <Glyphicon className='icon-action blue' glyph="pencil"/>
                                </Button></li>
                                <li><Button id="btn_delete"
                                  className="btn-actions"
                                  onClick={() => this.deleteAuditAdmin(row)}>
                                  {t(`${tKey}DELETE`)}
                                  <Glyphicon className='icon-action red' glyph="trash" />
                                </Button></li>
                              </DropdownButton>
                            </td>
                          </tr>
                        ))}
                      />
                    </div>,
                    <div className="search-footer" key="search-footer">
                      <IDocCarPagination
                        id="btn_pag_sales"
                        page={page}
                        pagesCount={pages}
                        totalRows= {count}
                        onSelectAction={(page) => this.onPageChange(page)}
                      />
                    </div>
                  ] : <p className="no_results">{t('GLOBAL.TABLES.NO_RESULTS')}</p>}
                </div>
              ])}
              <ProgressBar style={{ display: this.state.displayBar }}>
                <ProgressBar max={this.state.maxBar} now={this.state.progress} label={`${this.state.progress}/${this.state.maxBar}`} striped variant="success" key={1} />
                <ProgressBar max={this.state.maxBar} now={this.state.progressFail} label={`${this.state.progressFail}/${this.state.maxBar}`} striped variant="danger" style={{ backgroundColor: '#d44b4b' }} key={2} />
              </ProgressBar>
            </div>
          </div>
        </div>
        { this.state.visible &&
        <AuditModalPage type={this.state.type} readOnly={this.state.readOnly} hideModal={() => this.hideAuditModal()} isAdmin={true}/>
        }
      </>
    )
  }
}

export default reduxForm({
  form: 'auditAdminForm',
  validate: (values, props) => {
    const errors = {}
    if (values.creationDateTo !== undefined) {
      const format = 'D-M-YYYY'
      const fromDate = moment(values.creationDateFrom, format)
      const toDate = moment(values.creationDateTo, format)
      if (toDate.isBefore(fromDate)) {
        errors.creationDateTo = props.t('DOSSIER_COMPONENTS.VALIDATIONS.DATE_VALIDATION')
      }
      return errors
    }
  }
})(AuditAdmin)
