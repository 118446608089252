import React, { PureComponent } from 'react'
import { Glyphicon } from 'react-bootstrap'
import PopoverItem from '../../../containers/search/PopoverItemPage'
import QrDossier from '../../../../components/dossiers/common/statusHeader/QrDossier'
import { getTitleEntityProvider } from '../../../../util/entity/entityUtils'

class DossierSummary extends PureComponent {
  constructor () {
    super()
    this.state = {
      dossierDataGroupDOSSOpen: true,
      dossierDataGroupFLEOpen: true,
      dossierDataGroupDOCOpen: true,
      dossierDataGroupENTITYOpen: true,
      dossierDataGroupVEHICLEOpen: true

    }
  }

  toggleDossierDataGroup (DataGroup) {
    switch (DataGroup) {
      case 'DOSS': /* DataGroup_DOSS */
      { this.setState({ dossierDataGroupDOSSOpen: !this.state.dossierDataGroupDOSSOpen })
        break }
      case 'FLE': /* DataGroup_FLE */
      { this.setState({ dossierDataGroupFLEOpen: !this.state.dossierDataGroupFLEOpen })
        break }
      case 'DOC': /* DataGroup_DOC */
      { this.setState({ dossierDataGroupDOCOpen: !this.state.dossierDataGroupDOCOpen })
        break }
      case 'ENTITY': /* DataGroup_ENTITY */
      { this.setState({ dossierDataGroupENTITYOpen: !this.state.dossierDataGroupENTITYOpen })
        break }
      case 'VEHICLE': /* DataGroup_VEHICLE */
      { this.setState({ dossierDataGroupVEHICLEOpen: !this.state.dossierDataGroupVEHICLEOpen })
        break }
    }
  }

  render () {
    const {
      t, dossier, header, dossierSubType, entityTypeCombo, brandCombo, dossierType, dossierRelatedDossiers
    } = this.props

    const tKey = 'DOSSIER_COMPONENTS.SUMMARY.'
    const entityType = dossier?.entityComponent?.entityTypeId && entityTypeCombo.find(type => type.id === dossier.entityComponent.entityTypeId)
    const brand = dossier.vehicleComponent.brandId && brandCombo.find(brand => brand.id === dossier.vehicleComponent.brandId)

    return <div>

      {/** DOSSIER DATA **/}
      <div className={'dossierDataGroup-DOSS ' + (this.state.dossierDataGroupDOSSOpen ? 'open' : 'close')}>
        <div className='dossierData-header' onClick={() => this.toggleDossierDataGroup('DOSS')}>
          <div className='dossierData-header-name'>
            <i className="iDocIcon-file-folder"></i>
            <span>{header.number}</span>
          </div>
          <div className='dossierData-header-actions'>
            <i className="iDocIcon-caret-right-solid"></i>
          </div>
        </div>
        <div className='dossierData-body'>
          <QrDossier
            dossierId={dossier.dossierId}
            dossierSubType = {dossierSubType}
            number = {header.number}
          />
          {dossier.vehicleComponent.comercialCode && <PopoverItem itemType={'veh_08'} placement='right' data={dossier.vehicleComponent.comercialCode}></PopoverItem>}
          <PopoverItem itemType={'doc_01'} placement='right' data={header.creationDate}></PopoverItem>
          <PopoverItem itemType={'doss_06'} placement='right' data={header.lastUpdateDate}></PopoverItem>
        </div>
      </div>
      {/** FLEETS**/}
      {dossier.dossierFleetComponent && dossier.dossierFleetComponent.fleetCode && <div className={'dossierDataGroup-FLE ' + (this.state.dossierDataGroupFLEOpen ? 'open' : 'close')}>
        <div className='dossierData-header' onClick={() => this.toggleDossierDataGroup('FLE')}>
          <div className='dossierData-header-name'>
            <i className="iDocIcon-file-fleets"></i>
            <span>{t(`${tKey}FLEET`)} </span>
          </div>
          <div className='dossierData-header-actions'>
            <i className="iDocIcon-caret-right-solid"></i>
          </div>
        </div>
        <div className='dossierData-body'>
          {dossier.dossierFleetComponent.fleetCode && <PopoverItem itemType={'fle-1'} placement='right' data={dossier.dossierFleetComponent.fleetCode}></PopoverItem>}
          {dossier.dossierFleetComponent.description && <PopoverItem itemType={'fle-2'} placement='right' data={dossier.dossierFleetComponent.description}></PopoverItem>}
          {dossier.dossierFleetComponent.fleetAssignmentDate && <PopoverItem itemType={'fle-3'} placement='right' data={dossier.dossierFleetComponent.fleetAssignmentDate}></PopoverItem>}
        </div>
      </div>}
      {/** DOCUMENTS**/}
      <div className={'dossierDataGroup-DOC ' + (this.state.dossierDataGroupDOCOpen ? 'open' : 'close')}>
        <div className='dossierData-header' onClick={() => this.toggleDossierDataGroup('DOC')}>
          <div className='dossierData-header-name'>
            <i className="iDocIcon-file-document"></i>
            <span>{t(`${tKey}DOCUMENTS`)} </span>
          </div>
          <div className='dossierData-header-actions'>
            <i className="iDocIcon-caret-right-solid"></i>
          </div>
        </div>
        <div className='dossierData-body'>
          <div className="iconos_estado_documental second">
            <i className="ico-cloud"/>{header.uploaded}/{header.pendingUpload}
            <Glyphicon glyph="ok-sign"/>{header.validated}/{header.pendingValidate}
          </div>
        </div>
      </div>
      {/** ENTITY**/}
      {dossier.entityComponent && dossier.entityComponent.entityId && <div className={'dossierDataGroup-ENTITY ' + (this.state.dossierDataGroupENTITYOpen ? 'open' : 'close')}>
        <div className='dossierData-header' onClick={() => this.toggleDossierDataGroup('ENTITY')}>
          <div className='dossierData-header-name'>
            <i className="iDocIcon-user-user-menu"></i>
            <span>{getTitleEntityProvider(dossierType, t, dossierRelatedDossiers)} </span>
          </div>
          <div className='dossierData-header-actions'>
            <i className="iDocIcon-caret-right-solid"></i>
          </div>
        </div>
        <div className='dossierData-body'>
          {entityType?.value && <PopoverItem itemType={'ent-1'} placement='right' data={entityType?.value}></PopoverItem> }
          {dossier.entityComponent.name && <PopoverItem itemType={'ent-2'} placement='right' data={dossier.entityComponent.name}></PopoverItem>}
          {/* <PopoverItem itemType={'ent-3'} placement='right' data={'{--}'}></PopoverItem>
          <PopoverItem itemType={'ent-4'} placement='right' data={'{--}'}></PopoverItem> */}
          {dossier.entityComponent.clientNumber && <PopoverItem itemType={'ent-5'} placement='right' data={dossier.entityComponent.clientNumber}></PopoverItem>}
          {dossier.entityComponent.dniCif && <PopoverItem itemType={'ent-6'} placement='right' data={dossier.entityComponent.dniCif}></PopoverItem>}
          {/* dossier.entityComponent.address && <PopoverItem itemType={'ent-7'} placement='right' data={dossier.entityComponent.address}></PopoverItem> */}
          {dossier.entityComponent.phone && <PopoverItem itemType={'ent-8'} placement='right' data={dossier.entityComponent.phone}></PopoverItem>}
          {dossier.entityComponent.email && <PopoverItem itemType={'ent-9'} placement='right' data={dossier.entityComponent.email}></PopoverItem>}
        </div>
      </div>}

      {/** VEHICLE**/}
      {dossier.vehicleComponent && dossier.vehicleComponent.vehicleId && <div className={'dossierDataGroup-VEHICLE ' + (this.state.dossierDataGroupVEHICLEOpen ? 'open' : 'close')}>
        <div className='dossierData-header' onClick={() => this.toggleDossierDataGroup('VEHICLE')}>
          <div className='dossierData-header-name'>
            <i className="iDocIcon-file-vehicle"></i>
            <span>{t(`${tKey}VEHICLE`)} </span>
          </div>
          <div className='dossierData-header-actions'>
            <i className="iDocIcon-caret-right-solid" onClick={() => this.toggleDossierDataGroup('VEHICLE')}></i>
          </div>
        </div>
        <div className='dossierData-body'>
          {dossier.vehicleComponent.licensePlate && <PopoverItem itemType={'veh_07'} placement='right' data={dossier.vehicleComponent.licensePlate}></PopoverItem>}
          {dossier.vehicleComponent.firstLicensePlateDate && <PopoverItem itemType={'veh_09'} placement='right' data={dossier.vehicleComponent.firstLicensePlateDate}></PopoverItem>}
          {dossier.vehicleComponent.vinChassis && <PopoverItem itemType={'veh_01'} placement='right' data={dossier.vehicleComponent.vinChassis}></PopoverItem>}
          {/* <PopoverItem itemType={'veh_02'} placement='right' data={'{CARLINE}'}></PopoverItem> */}
          {brand?.value && <PopoverItem itemType={'veh_06'} placement='right' data={brand?.value}></PopoverItem>}
          {dossier.vehicleComponent.model && <PopoverItem itemType={'veh_05'} placement='right' data={dossier.vehicleComponent.model}></PopoverItem>}
          {dossier.vehicleComponent.comercialCode && <PopoverItem itemType={'veh_08'} placement='right' data={dossier.vehicleComponent.comercialCode}></PopoverItem>}
          {dossier.vehicleComponent.color && <PopoverItem itemType={'veh_10'} placement='right' data={dossier.vehicleComponent.color}></PopoverItem>}
          {/* dossier.vehicleComponent.tapestry && <PopoverItem itemType={'veh_11'} placement='right' data={dossier.vehicleComponent.tapestry}></PopoverItem> */}
        </div>
      </div>}
    </div>
  }
}

export default DossierSummary
