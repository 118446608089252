export const paymentMethodIdEnum = {
  CONTADO_FINANCIADO: 1,
  RENTING: 2
}

export const paymentMethodModeEnum = {
  CASH: 1,
  RENTING: 2,
  FINANCIED: 3,
  LEASING: 4,
  EXTERNAL_FINANCIED: 5
}
