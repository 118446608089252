import React, { PureComponent } from 'react'
import { Modal, Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import SimpleTablePage from '../../../../containers/commons/general/SimpleTablePage'
import IDocCarPagination from '../../../commons/iDocCarPagination'
import { Link } from 'react-router-dom'
import EntityPopover from '../../../search/EntityPopover'
import VehiclePopover from '../../../search/VehiclePopover'
import { Field, reduxForm } from 'redux-form'
import InputDatePicker from '../../../commons/form/InputDatePicker'
import { validateDate } from '../../../../util/validationFunctions'

class NonUpdatedDossiers extends PureComponent {
  constructor () {
    super()
    this.state = {
    }
  }

  delayAlert (dossierId, notifyData) {
    new Promise((resolve, reject) => {
      this.props.actions.delayNonUpdatedDossierAlert(dossierId, notifyData, resolve, reject)
    }).then(() => {
      this.props.actions.fetchNonUpdatedDossiers({ ...this.props.filters })
    })
  }

  submitNextDossierNotifyDate (values, dossierId, idx) {
    if (values) {
      const notifyData = String(values[`nexDossierNotifyDate${idx}`])
      if (!this.validateNotifyData(notifyData)) {
        this.props.actions.openErrorMessageModal('DOSSIER_COMPONENTS.VALIDATIONS.DATE_VALIDATION')
      } else {
        this.delayAlert(dossierId, notifyData)
      }
    }
  }

  onOrderChange (e) {
    const newOrderBy = this.props.filters.orderBy.includes(`%2B${e.currentTarget.dataset.field}`)
      ? `-${e.currentTarget.dataset.field}`
      : `%2B${e.currentTarget.dataset.field}`
    this.props.actions.fetchNonUpdatedDossiers({ ...this.props.filters, orderBy: newOrderBy })
  }

  getIconClass (field) {
    const filters = this.props.filters
    if (filters && filters.orderBy.includes(field)) {
      return filters.orderBy.includes('-')
        ? 'ico-sort-selected-asc'
        : 'ico-sort-selected-desc'
    } else {
      return 'ico-sort'
    }
  }

  componentDidMount () {
    this.props.actions.fetchNonUpdatedDossiers(this.props.filters)
  }

  delayToolTip () {
    return (<Tooltip id="delayToolTip">{ this.props.t('NON_UPDATED_DOSSIERS_MODAL.DELAY_TOOLTIP')}</Tooltip>)
  }

  getMinDate () {
    const date = new Date()
    date.setDate(date.getDate() + 7)
    return date.toDateString()
  }

  getIsoStringMinDate () {
    const date = new Date()
    date.setDate(date.getDate() + 7)
    return date.toISOString()
  }

  getMaxDate () {
    const date = new Date()
    const month = date.getMonth()
    const year = date.getFullYear()
    const nextMonth = (month < 12) ? month + 2 : 1
    const nextYear = (month < 12) ? year : year + 1
    date.setMonth(nextMonth)
    date.setFullYear(nextYear)
    return date.toDateString()
  }

  validateNotifyData (value) {
    const error = validateDate(value, this.props)
    const [day, month, year] = value.split('/')
    const date = new Date(`${year}-${month}-${day} 00:00:00.000`)
    const minDate = new Date(this.getMinDate())
    const maxDate = new Date(this.getMaxDate())
    if (error !== Object(error) || !value || date > maxDate || date < minDate) {
      return false
    }
    return true
  }

  handleChangePage (page) {
    this.props.actions.fetchNonUpdatedDossiers({ ...this.props.filters, page })
    this.props.initialize(this.props)
  }

  render () {
    const {
      t, showModal, backTo, filters, count = 0, dossiersList, handleSubmit,
      actions: { closeNonUpdatedDossiers }
    } = this.props
    const show = (showModal ?? false)
    const tKey = 'NON_UPDATED_DOSSIERS_MODAL.'
    const page = filters && filters.page ? filters.page : 1
    const sizePage = filters && filters.sizePage ? filters.sizePage : 7
    return (
      <Modal
        show={show}
        backdrop="static"
        keyboard={false}
        className="non-updated-dossiers-modal"
      >
        <form autoComplete="off">
          <Modal.Header closeButton={false}>
            <Modal.Title >
              {t(`${tKey}TITLE`)}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <SimpleTablePage
                columns={[
                  <th key={0} data-field="number" onClick={(event) => this.onOrderChange(event)}>
                    {t(`${tKey}DOSSIER_NUMBER`)}&nbsp;<i className={this.getIconClass('number')}/>
                  </th>,
                  <th key={1} data-field="organizedUnit" onClick={(event) => this.onOrderChange(event)}>
                    {t(`${tKey}ORGANIZED_UNIT`)}&nbsp;<i className={this.getIconClass('organizedUnit')}/>
                  </th>,
                  <th key={2}>{t(`${tKey}ENTITY`)}</th>,
                  <th key={3}>{t(`${tKey}VEHICLE`)}</th>,
                  <th key={4} data-field="stage" onClick={(event) => this.onOrderChange(event)}>
                    {t(`${tKey}STAGE`)}&nbsp;<i className={this.getIconClass('stage')}/>
                  </th>,
                  <th key={5} data-field="creationDate" onClick={(event) => this.onOrderChange(event)}>
                    {t(`${tKey}CREATION_DATE`)}&nbsp;<i className={this.getIconClass('creationDate')}/>
                  </th>,
                  <th key={6} data-field="lastUpdateDate" onClick={(event) => this.onOrderChange(event)}>
                    {t(`${tKey}LAST_UPDATE`)}&nbsp;<i className={this.getIconClass('lastUpdateDate')}/>
                  </th>,
                  <th key={7} data-field="lastStageChange" onClick={(event) => this.onOrderChange(event)}>
                    {t(`${tKey}LAST_STAGE_CHANGE`)}&nbsp;<i className={this.getIconClass('lastStageChange')}/>
                  </th>,
                  <th key={8} data-field="delayAlert" className='column-action'>
                    {t(`${tKey}DELAY_ALERT`)}
                  </th>
                ]}
                rows={dossiersList && dossiersList.map((row, idx) => (
                  <tr key= {idx}>
                    <td>
                      <Link to={`/dossier${row.urlToNavigate}`} onClick={() => closeNonUpdatedDossiers()}>
                        {row.number}
                      </Link>
                    </td>
                    <td>{row.organizedUnit}</td>
                    <td className="see-more">
                      <EntityPopover
                        t={t}
                        entity={row.entity}
                        half={1 / (idx + 1)}
                        idx={idx}
                        id={'popover-entity' + idx}
                      />
                    </td>
                    <td className="see-more">
                      <VehiclePopover
                        t={t}
                        vehicle={row.vehicle}
                        half={1 / (idx + 1)}
                        idx={idx}
                        id={'popover-vehicle' + idx}
                      />
                    </td>
                    <td>{row.stage}</td>
                    <td className="column-date">{row.creationDate}</td>
                    <td className="column-date">{row.lastUpdateDate}</td>
                    <td className="column-date">{row.lastStageChange}</td>
                    <td className="column-action">
                      <div className='column-delay-notify'>
                        <Field
                          name={'nexDossierNotifyDate' + idx}
                          id={'nexDossierNotifyDate' + idx}
                          component={InputDatePicker}
                          maxDate = {this.getMaxDate()}
                          minDate = {this.getMinDate()}
                          showTodayButton={false}
                          colSm={0}
                          className = 'date-input-picker'
                        />
                        &nbsp;
                        <Button
                          id={'btnDelay' + idx}
                          name={'btnDelay' + idx}
                          className="btn-standard btn-delay-notify"
                          type="submit"
                          onClick={handleSubmit(values => {
                            this.submitNextDossierNotifyDate(values, row.dossierId, idx)
                          })}
                        >
                          <OverlayTrigger placement="left" overlay={this.delayToolTip()}>
                            <i className="ico-arrow-left" />
                          </OverlayTrigger>
                        </Button>
                      </div>
                    </td>
                  </tr>
                ))
                }
              />
            </div>
            <div>
              <IDocCarPagination
                id="btn_pag_sales"
                page={page}
                pageSize={sizePage}
                totalRows= {count}
                onSelectAction={(page) => this.handleChangePage(page)}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <LinkContainer to={'/' + backTo}>
              <Button onClick={() => closeNonUpdatedDossiers()}>
                {t(`${tKey}CLOSE_BUTTON`)}
              </Button>
            </LinkContainer>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}
// export default NonUpdatedDossiers

export default reduxForm({
  form: 'nonUpdatedDossiers'
})(NonUpdatedDossiers)
