import { put, call, takeEvery, select } from 'redux-saga/effects'
import { getAuth } from '../../../selectors/access/auth'
import chargeQuiterActions from '../../../constants/actions/dossier/common/chargeQuiter'
import getDealerChargeQuiter from '../../../services/dossier/get/getDealerChargeQuiter'
import getVehicleDataQuiter from '../../../services/dossier/get/getVehicleDataQuiter'
import postQuiterVehicle from '../../../services/dossier/post/postQuiterVehicle'
import { openChargeModalDealerSuccess, getVehicleDataQuiterSuccess, sendVehicleDataQuiterSuccess } from '../../../actions/dossier/common/chargeQuiter'
import { showLoader, hideLoader } from '../../../actions/common'
import { handleError } from '../../errors/errorManager'

export function * getDealerNumberModal ({ dossierId, saleType }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    let dealerNumber = yield call(getDealerChargeQuiter, dossierId, auth.token)
    yield put(openChargeModalDealerSuccess({ dealerNumber, dossierId, saleType }))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchgetDealerNumberModal () {
  yield takeEvery(chargeQuiterActions.OPEN_CHARGE_QUITER_MODAL_DEALER, getDealerNumberModal)
}

export function * getVehicleData ({ dealerNumber, customer }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    if (dealerNumber === undefined) { dealerNumber = '' }
    let vehicleData = yield call(getVehicleDataQuiter, dealerNumber, customer, auth.token)
    yield put(getVehicleDataQuiterSuccess({ vehicleData }))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchgetVehicleDataQuiter () {
  yield takeEvery(chargeQuiterActions.GET_VEHICLE_DATA_QUITER, getVehicleData)
}

export function * sendQuiterVehicle ({ vehicleData, saleType, dossierId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    let result = yield call(postQuiterVehicle, vehicleData, saleType, dossierId, auth.token)
    yield put(sendVehicleDataQuiterSuccess({ result }))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchsendQuiterVehicle () {
  yield takeEvery(chargeQuiterActions.SEND_VEHICLE_DATA_QUITER, sendQuiterVehicle)
}
