import apiFetch from '../apiFetch'

export default function (token, taskId, orderby, page, pageSize, urgencyType, organizedUnit) {
  let optionals = ''
  if (urgencyType) {
    optionals += `&urgencyType=${urgencyType}`
  }
  if (organizedUnit) {
    optionals += `&organizedUnit=${organizedUnit}`
  }
  return apiFetch({ endPoint: `taskDashboard/${taskId}?orderby=${orderby}&page=${page}&pageSize=${pageSize}${optionals}`, method: 'GET', body: null, token: token })
}
