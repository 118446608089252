import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getFormValues } from 'redux-form'
import DocumentalSearchRestrictedForm from '../../components/search/DocumentalSearchRestrictedForm'
import { fetchDocumentalSearchFormCombos, findFilterDocuments, resetFilters, setFilters, initializeSearch, documentalSearchTabChange, setHideFilters, massiveDownloadDocumentList, createMassiveDownload } from '../../../actions/search/documentalSearch/documentalSearchForm'
import { translate } from 'react-polyglot'
import { fetchLowestOrganizedUnits, fetchSearchDossierSubType, fetchEntitySubTypeCombo, fetchDocumentRestrictedCombo } from '../../../actions/combos/combos'
import { openModal } from '../../../actions/common'
import { openDocumentEditionModal } from '../../../actions/dossier/common/documentEditionModal'
import { openDocumentEditionModal as openDocumentEditionModalHistorical } from '../../../actions/dossier/common/documentHistoricalEditionModal'

export function mapStateToProps (state, props) {
  return {
    ...state.documentalSearch,
    combos: {
      documentTypesRestrictedCombo: state.combos.documentsTypeRestrictedCombo,
      lowestOrganizedUnitsCombo: state.combos.lowestOrganizedUnitsCombo,
      searchDossierSubtypesCombo: state.combos.searchDossierSubtypesCombo
    },
    formValues: getFormValues('documentalSearchRestrictedForm')(state)
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchDocumentalSearchFormCombos,
      findFilterDocuments,
      fetchLowestOrganizedUnits,
      resetFilters,
      openModal,
      fetchSearchDossierSubType,
      setFilters,
      initializeSearch,
      fetchEntitySubTypeCombo,
      documentalSearchTabChange,
      fetchDocumentRestrictedCombo,
      setHideFilters,
      openDocumentEditionModal,
      openDocumentEditionModalHistorical,
      massiveDownloadDocumentList,
      createMassiveDownload
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(DocumentalSearchRestrictedForm))
