import { call, put, select, takeLatest } from 'redux-saga/effects'
import { showLoader, hideLoader } from '../../../actions/common'
import { getAuth } from '../../../selectors/access/auth'
import statusHeaderActionTypes from '../../../constants/actions/dossier/common/statusHeader'
import putDossierFavorite from '../../../services/favorites/putDossierFavorite'
import deleteDossierFavorite from '../../../services/favorites/deleteDossierFavorite'
import statusHeader from '../../../actions/dossier/common/statusHeader'
import messageModalActions from '../../../actions/modals/messageModal'
import { handleError } from '../../errors/errorManager'

export function * switchFavorite ({ favoriteStatus, dossierId, isHistorical }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    if (favoriteStatus) {
      yield call(deleteDossierFavorite, auth.token, dossierId, isHistorical)
      yield put(messageModalActions.openSuccessMessageModal('MESSAGES.DELETE_FAVORITE_SUCCESS'))
    } else {
      yield call(putDossierFavorite, auth.token, dossierId, isHistorical)
      yield put(messageModalActions.openSuccessMessageModal('MESSAGES.ADD_FAVORITE_SUCCESS'))
    }
    yield put(statusHeader.switchFavoriteSuccess(!favoriteStatus))
    yield put(hideLoader())
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSwitchFavorite () {
  yield takeLatest(statusHeaderActionTypes.SWITCH_DOSSIER_FAVORITE, switchFavorite)
}
