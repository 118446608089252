import React, { PureComponent } from 'react'
import { Tabs, Tab } from 'react-bootstrap'
import TaskCard from './TaskCard'
import settings from '../../../setting'

class ExtraBoxTasks extends PureComponent {
  componentDidMount () {
    if (settings.enableTaskFollowUp) {
      this.props.actions.fetchTaskFavorites(this.props.organizedUnit)
    }
  }

  componentDidUpdate () {
    // getSnapshotBeforeUpdate() should be used with componentDidUpdate()
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (settings.enableTaskFollowUp && prevProps.organizedUnit !== this.props.organizedUnit) {
      this.props.actions.fetchTaskFavorites(this.props.organizedUnit)
    }
    return null
  }

  reloadTasks () {
    this.props.actions.fetchTaskFavorites(this.props.organizedUnit)
  }

  render () {
    const { t, taskFavorites, actions: { switchCardTaskFavorite } } = this.props
    const tKey = 'DASHBOARD.'

    return (
      <>
        { settings.enableTaskFollowUp &&
      <Tabs className='extra-groups-tabs' id="extra-groups-tabs-Tasks">
        <Tab eventKey={0} title={<span><i className="iDocIcon-task"></i>{t(`${tKey}FAVORITES.TASKS`)} {taskFavorites?.tasks.length}/{taskFavorites?.maxTaskCount}</span>}>
          <div className='actionsBar'><a onClick={() => this.reloadTasks()}><i className="iDocIcon-update"></i></a></div>
          <p className={'hideText ' + (taskFavorites?.tasks.length === 0 ? 'show' : '')}><span><i className="iDocIcon-warning"></i>{t(`${tKey}FAVORITES.NO_TASKS`)}</span></p>
          <div className='CardContainer taskContainer'>
            {
              taskFavorites && taskFavorites.tasks.map((task, idx) => {
                return (<TaskCard t={t}
                  urgencyLowLabel={t(`${tKey}URGENCY_TYPES.LOW`)}
                  urgencyMediumLabel={t(`${tKey}URGENCY_TYPES.MEDIUM`)}
                  urgencyHighLabel={t(`${tKey}URGENCY_TYPES.HIGH`)}
                  data={task}
                  switchCardTaskFavorite = {switchCardTaskFavorite}
                  key={'taskCard' + idx}/>)
              })
            }
          </div>
        </Tab>
      </Tabs>
        }
      </>
    )
  }
}
export default ExtraBoxTasks
