import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import FleetCreatedSelectChargeModal from '../../../components/management/AuxModals/FleetCreatedSelectChargeModal'
import { closeFleetCreatedSelectChargeModal, openAddDossiersToFleetModal, openChargeDossiersModal } from '../../../actions/management/auxModals/auxModals'
import { fetchVehicleType } from '../../../actions/combos/combos'
import { fetchPaymentCombo } from '../../../actions/management/management'

export function mapStateToProps (state) {
  return {
    ...state.managementAuxModals.fleetCreatedSelectChargeModal
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      closeFleetCreatedSelectChargeModal,
      openAddDossiersToFleetModal,
      openChargeDossiersModal,
      fetchPaymentCombo,
      fetchVehicleType
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(FleetCreatedSelectChargeModal))
