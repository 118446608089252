import React, { PureComponent } from 'react'
import { Panel, Col, Row, Button } from 'react-bootstrap'
import SmallLoaderPage from '../../../containers/commons/SmallLoaderPage'
import { compareObjects } from '../../../util/utils'
import { getFieldsPermisions } from '../../../util/permisionFunctions'
import { Field } from 'redux-form'
import { permissions } from '../../../constants/backendIds'
import InputDatePicker from '../../commons/form/InputDatePicker'
import InputSliderButton from '../../commons/form/InputSliderButton'

class Reception extends PureComponent {
  constructor () {
    super()
    this.state = {
      open: false,
      fieldsConfiguration: {
        prewash: null,
        confirmationDoneButton: null,
        confirmationDate: null
      }
    }
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (this.props.dossier && this.props.dossier.dossierId) {
      if (!compareObjects(prevProps.componentConfiguration || {}, this.props.componentConfiguration, true)) {
        this.initializePermisions(this.props)
      }
      if (this.props.open) {
        this.setState({ open: true })
      }
    }
    return prevProps
  }

  componentDidMount () {
    this.initializePermisions(this.props)
  }

  componentDidUpdate () {
    // don't remove use for getsnapshot
  }

  initializePermisions (props) {
    const fieldKeys = Object.keys(this.state.fieldsConfiguration)
    const newState = getFieldsPermisions(props.componentConfiguration.fieldsConfiguration, fieldKeys, props.componentConfiguration.permission)
    this.setState({ fieldsConfiguration: newState.fieldsConfiguration })
  }

  toggleComponent () {
    if (this.state.open) {
      this.props.actions.setActiveSection(false)
    }
    this.setState({ open: !this.state.open })
  }

  showServiceObservationModal (show, service) {
    this.setState({ showModal: show, service: service })
  }

  render () {
    const {
      t, readOnlyFields, dossier: { reception }, actions: { confirmDateWithIdByStage }, dossierId, formValues, pristine
    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.RECEPTION.'

    return (
      <div className="reception-panel dossier-panel">
        <div className="header-panel" onClick={() => this.toggleComponent()}>
          <div className="name-wrapper">
            <i className="ico-i" />
            <h4>{t(`${tKey}TITLE_PANEL`)}</h4>
          </div>
          <i className={this.state.open ? 'ico-arrow-black-up' : 'ico-arrow-black-down'} />
        </div>
        <Panel expanded={this.state.open} onToggle={() => { }}>
          <Panel.Collapse>
            {
              this.state.fieldsConfiguration.confirmationDoneButton && this.state.fieldsConfiguration.confirmationDoneButton.permission === permissions.editable &&
                !readOnlyFields && !formValues.confirmationDate && (
                <Row>
                  <Col className="col-right">
                    <Button className='btn-save' onClick={() => confirmDateWithIdByStage(dossierId, null, pristine)} disabled={this.state.fieldsConfiguration.confirmationDoneButton.permission !== permissions.editable}>
                      {t(`${tKey}DONE_BUTTON`)}
                    </Button>
                  </Col>
                </Row>
              )
            }
            {
              this.state.fieldsConfiguration.prewash && this.state.fieldsConfiguration.prewash.permission !== permissions.hidden && (
                <Row className='flexRow'>
                  <Col sm={12}>
                    <Field
                      id="prewash"
                      name="reception.prewash"
                      colSm={3}
                      controlLabel={t(`${tKey}PREWASH`)}
                      placeholder={t(`${tKey}PREWASH`)}
                      component={InputSliderButton}
                      textDescription={t(`${tKey}PREWASH`)}
                      disabled={readOnlyFields || this.state.fieldsConfiguration.prewash.permission === permissions.readOnly }
                      customClass={'three-rc-select'}
                      defaultChoice={reception ? reception.prewash : null}
                    />
                  </Col>
                </Row>
              )
            }
            {
              this.state.fieldsConfiguration.confirmationDate && this.state.fieldsConfiguration.confirmationDate.permission !== permissions.hidden && (
                <Row className='flexRow'>
                  <Col sm={12}>
                    <Field
                      id="confirmationDate"
                      name="reception.confirmationDate"
                      colSm={3}
                      controlLabel={t(`${tKey}CONFIRMATION_DATE`)}
                      placeholder={t(`${tKey}CONFIRMATION_DATE`)}
                      component={InputDatePicker}
                      disabled={readOnlyFields || this.state.fieldsConfiguration.confirmationDate.permission === permissions.readOnly }
                    />
                  </Col>
                </Row>
              )
            }
          </Panel.Collapse>
        </Panel>
        <SmallLoaderPage />
      </div>
    )
  }
}
export default Reception
