import { put, call, select, takeEvery } from 'redux-saga/effects'
import { getAuth } from '../../selectors/access/auth'
import { getStockFilter } from '../../selectors/reports/stockReport'
import stockActionTypes from '../../constants/actions/reports/stockReport'
import { fetchStockReportSuccess } from '../../actions/reports/stockReport'
import { showLoader, hideLoader } from '../../actions/common.js'
import postStockListService from '../../services/reports/postStockList'
import { handleError } from '../errors/errorManager'
import getLowestOrganizedUnits from '../../services/organizedUnit/getOrganizedUnits'
import { fetchCombosSuccess } from '../../actions/combos/combos'
import getDownloadStockList from '../../services/reports/getDownloadStockList'

export function * fetchStockList ({ filter }) {
  try {
    yield put(showLoader())
    yield filter = yield filter || select(getStockFilter)
    const auth = yield select(getAuth)
    const stockList = yield call(postStockListService, auth.token, filter)
    const lowestOrganizedUnitsCombo = yield call(getLowestOrganizedUnits, auth.token)
    yield put(fetchCombosSuccess({
      lowestOrganizedUnitsCombo
    }))
    yield put(fetchStockReportSuccess(stockList, filter))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchStockList () {
  yield takeEvery(stockActionTypes.FETCH_STOCK_REPORT, fetchStockList)
}

export function * downloadStockList ({ filter }) {
  try {
    yield put(showLoader())
    yield filter = yield filter || select(getStockFilter)
    const auth = yield select(getAuth)
    yield call(getDownloadStockList, auth.token, filter)
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDownloadStockList () {
  yield takeEvery(stockActionTypes.DOWNLOAD_STOCK_REPORT, downloadStockList)
}
