import releaseNotesModalActionTypes from '../../constants/actions/modals/releaseNotesModal'

export function closeReleaseNotes (value) {
  return {
    type: releaseNotesModalActionTypes.RELEASE_NOTES_CLOSE,
    value
  }
}

export function closeReleaseNotesSuccess () {
  return {
    type: releaseNotesModalActionTypes.RELEASE_NOTES_CLOSE_SUCCESS
  }
}

export default {
  closeReleaseNotes,
  closeReleaseNotesSuccess
}
