export const dossierStagesId = {
  service_preparation_delivery_request: 159,
  service_preparation_delivery_confirmation: 160,
  service_preparation_delivery_realization: 161,
  service_preparation_delivery_final_doc: 162,
  service_preparation_delivery_stored: 163,
  service_preparation_delivery_canceled: 164
}

export const dossierStagesBySubtype = {
  saleVn: {
    provisional: 1,
    approval: 3,
    disposal: 5,
    payment: 7,
    documentation: 9,
    procedure: 11,
    delivery_prep: 13,
    delivery: 15,
    final_doc: 17,
    cancelled: 100
  },
  saleVo: {
    provisional: 2,
    approval: 4,
    disposal: 6,
    payment: 8,
    documentation: 10,
    procedure: 12,
    delivery_prep: 14,
    delivery: 16,
    final_doc: 18,
    cancelled: 100
  },
  paperWork: {
    init: 90
  }
}
