export default {
  FETCH_PUSCHARSE_SALE_ENTITYS: 'FETCH_PUSCHARSE_SALE_ENTITYS',
  FETCH_AUDIT_CODES: 'FETCH_AUDIT_CODES',
  FETCH_AUDIT_LOAD_DATA: 'FETCH_AUDIT_LOAD_DATA',
  FETCH_COMBOS_SUCCESS: 'FETCH_COMBOS_SUCCESS',
  FETCH_ENTITY_SUBTYPE_COMBO: 'FETCH_ENTITY_SUBTYPE_COMBO',
  FETCH_ENTITY_COUNTRY_COMBO: 'FETCH_ENTITY_COUNTRY_COMBO',
  FETCH_ENTITY_TOWN_COMBO: 'FETCH_ENTITY_TOWN_COMBO',
  FETCH_ENTITY_COLONY_COMBO: 'FETCH_ENTITY_COLONY_COMBO',
  FETCH_ENTITY_CITY_COMBO: 'FETCH_ENTITY_CITY_COMBO',
  FETCH_ENTITY_CP_COMBO: 'FETCH_ENTITY_CP_COMBO',
  FETCH_ENTITY_STATE_COMBO: 'FETCH_ENTITY_STATE_COMBO',
  FETCH_ENTITY_FILTER_SUBTYPE_COMBO: 'FETCH_ENTITY_FILTER_SUBTYPE_COMBO',
  FETCH_DOSSIER_TYPES: 'FETCH_DOSSIER_TYPES',
  FETCH_DOSSIER_TYPES_SUCCESS: 'FETCH_DOSSIER_TYPES_SUCCESS',
  FETCH_DOSSIER_SUBTYPES: 'FETCH_DOSSIER_SUBTYPES',
  FETCH_DOSSIER_SUBTYPES_SUCCESS: 'FETCH_DOSSIER_SUBTYPES_SUCCESS',
  FETCH_LOWEST_ORGANIZED_UNITS: 'FETCH_LOWEST_ORGANIZED_UNITS',
  FETCH_LOWEST_ORGANIZED_UNITS_SUCCESS: 'FETCH_LOWEST_ORGANIZED_UNITS_SUCCESS',
  FETCH_ENTITY_TYPES: 'FETCH_ENTITY_TYPES',
  FETCH_ENTITY_TYPES_SUCCESS: 'FETCH_ENTITY_TYPES_SUCCESS',
  FETCH_SALE_TYPE: 'FETCH_SALE_TYPE',
  FETCH_SALE_TYPE_BY_OPERATION_TYPE: 'FETCH_SALE_TYPE_BY_OPERATION_TYPE',
  FETCH_SALE_TYPE_SUCCESS: 'FETCH_SALE_TYPE_SUCCESS',
  FETCH_SALE_TYPE_BY_SUBTYPE: 'FETCH_SALE_TYPE_BY_SUBTYPE',
  FETCH_SALE_TYPE_BY_SUBTYPE_SUCCESS: 'FETCH_SALE_TYPE_BY_SUBTYPE_SUCCESS',
  FETCH_PURCHASE_TYPE: 'FETCH_PURCHASE_TYPE',
  FETCH_TUNE_UP_DESTINATION: 'FETCH_TUNE_UP_DESTINATION',
  FETCH_PURCHASE_TYPE_SUCCESS: 'FETCH_PURCHASE_TYPE_SUCCESS',
  FETCH_TUNE_UP_DESTINATION_SUCCESS: 'FETCH_TUNE_UP_DESTINATION_SUCCESS',
  FETCH_PAPERWORK_TYPE: 'FETCH_PAPERWORK_TYPE',
  FETCH_PAPERWORK_TYPE_SUCCESS: 'FETCH_PAPERWORK_TYPE_SUCCESS',
  FETCH_CAMPAIGN_TYPE: 'FETCH_CAMPAIGN_TYPE',
  FETCH_CAMPAIGN_TYPE_SUCCESS: 'FETCH_CAMPAIGN_TYPE_SUCCESS',
  FETCH_ORDER_TYPE_SUCCESS: 'FETCH_ORDER_TYPE_SUCCESS',
  FETCH_OPERATION_TYPE: 'FETCH_OPERATION_TYPE',
  FETCH_FLEET_OPERATION_TYPE_FOR_AUTOSELECT: 'FETCH_FLEET_OPERATION_TYPE_FOR_AUTOSELECT',
  FETCH_OPERATION_TYPE_SUCCESS: 'FETCH_OPERATION_TYPE_SUCCESS',
  FETCH_OPERATION_TYPE_MODAL_SUCCESS: 'FETCH_OPERATION_TYPE_MODAL_SUCCESS',
  FETCH_CHANEL_SOURCE_SUCCESS: 'FETCH_CHANEL_SOURCE_SUCCESS',
  FETCH_SEGMENTATION_SUCCESS: 'FETCH_SEGMENTATION_SUCCESS',
  FETCH_DELIVERY_LOCATION: 'FETCH_DELIVERY_LOCATION',
  FETCH_DELIVERY_LOCATION_SUCCESS: 'FETCH_DELIVERY_LOCATION_SUCCESS',
  FETCH_COLLABORATOR_SALE_SUCCESS: 'FETCH_COLLABORATOR_SALE_SUCCESS',
  FETCH_FINANCIAL_INSTITUTION_SUCCESS: 'FETCH_FINANCIAL_INSTITUTION_SUCCESS',
  FETCH_FINANCING_TYPE: 'FETCH_FINANCING_TYPE',
  FETCH_FINANCING_TYPE_SUCCESS: 'FETCH_FINANCING_TYPE_SUCCESS',
  FETCH_ISSUE_ENTITY: 'FETCH_ISSUE_ENTITY',
  FETCH_ISSUE_ENTITY_SUCCESS: 'FETCH_ISSUE_ENTITY_SUCCESS',
  FETCH_SALESMAN: 'FETCH_SALESMAN',
  FETCH_SALESMAN_SUCCESS: 'FETCH_SALESMAN_SUCCESS',
  FETCH_PAYMENT_METHOD_COMBO: 'FETCH_PAYMENT_METHOD_COMBO',
  FETCH_PAYMENT_METHOD_SUCCESS: 'FETCH_PAYMENT_METHOD_SUCCESS',
  FETCH_STAGES_COMBO: 'FETCH_STAGES_COMBO',
  FETCH_LANGUAGES_COMBO: 'FETCH_LANGUAGES_COMBO',
  FETCH_DOSSIER_SUBTYPES_BY_OU: 'FETCH_DOSSIER_SUBTYPES_BY_OU',
  FETCH_CHECKLIST_CAMPAIHG: 'FETCH_CHECKLIST_CAMPAIHG',
  FETCH_EXTRA_SALE_TYPES: 'FETCH_EXTRA_SALE_TYPES',
  FETCH_EXTRA_SALE_TYPES_SUCCESS: 'FETCH_EXTRA_SALE_TYPES_SUCCESS',
  FETCH_PRESENT_TYPES: 'FETCH_PRESENT_TYPES',
  FETCH_PRESENT_TYPES_SUCCESS: 'FETCH_PRESENT_TYPES_SUCCESS',
  FETCH_SUPPLIED_TYPES: 'FETCH_SUPPLIED_TYPES',
  FETCH_SUPPLIED_TYPES_SUCCESS: 'FETCH_SUPPLIED_TYPES_SUCCESS',
  FETCH_ORGANIZED_UNITS_TREE: 'FETCH_ORGANIZED_UNITS_TREE',
  FETCH_ORGANIZED_UNITS_TREE_SUCCESS: 'FETCH_ORGANIZED_UNITS_TREE_SUCCESS',
  FETCH_ORGANIZED_UNITS_COMBO: 'FETCH_ORGANIZED_UNITS_COMBO',
  FETCH_ORGANIZED_UNITS_COMBO_SUCCESS: 'FETCH_ORGANIZED_UNITS_COMBO_SUCCESS',
  FETCH_ORGANIZED_UNITS_TREE_COMBO_BY_PERMISSION: 'FETCH_ORGANIZED_UNITS_TREE_COMBO_BY_PERMISSION',
  FETCH_ORGANIZED_UNITS_TREE_COMBO_BY_PERMISSION_SUCCESS: 'FETCH_ORGANIZED_UNITS_TREE_COMBO_BY_PERMISSION_SUCCESS',
  FETCH_BRANDS: 'FETCH_BRANDS',
  FETCH_SEARCH_DOSSIER_SUBTYPES: 'FETCH_SEARCH_DOSSIER_SUBTYPES',
  FETCH_TEMPLATE_TYPES: 'FETCH_TEMPLATE_TYPES',
  FETCH_TEMPLATE_FIELDS: 'FETCH_TEMPLATE_FIELDS',
  FETCH_ROLES: 'FETCH_ROLES',
  FETCH_UOS: 'FETCH_UOS',
  FETCH_ROLES_SUCCESS: 'FETCH_ROLES_SUCCESS',
  FETCH_SECTION_KEY_VALUE: 'FETCH_SECTION_KEY_VALUE',
  FETCH_SECTION_KEY_VALUE_SUCCESS: 'FETCH_SECTION_KEY_VALUE_SUCCESS',
  FETCH_PERMISSION_KEY_VALUE: 'FETCH_PERMISSION_KEY_VALUE',
  FETCH_PERMISSION_KEY_VALUE_SUCCESS: 'FETCH_PERMISSION_KEY_VALUE_SUCCESS',
  FETCH_DOCUMENT_ALL_TYPES: 'FETCH_DOCUMENT_ALL_TYPES',
  FETCH_DOCUMENT_TYPE_USE: 'FETCH_DOCUMENT_TYPE_USE',
  FETCH_DOCUMENT_TYPE_USE_SUCCESS: 'FETCH_DOCUMENT_TYPE_USE_SUCCESS',
  FETCH_SIGN_TYPE: 'FETCH_SIGN_TYPE',
  FETCH_SIGN_TYPE_SUCCESS: 'FETCH_SIGN_TYPE_SUCCESS',
  FETCH_SIGN_ENTITY_TYPE: 'FETCH_SIGN_ENTITY_TYPE',
  FETCH_SIGN_ENTITY_TYPE_SUCCESS: 'FETCH_SIGN_ENTITY_TYPE_SUCCESS',
  FETCH_DOCUMENT_STATUS: 'FETCH_DOCUMENT_STATUS',
  FETCH_DOCUMENT_STATUS_SUCCESS: 'FETCH_DOCUMENT_STATUS_SUCCESS',
  FETCH_ENTITIES_BY_TYPE_BY_PARENT: 'FETCH_ENTITIES_BY_TYPE_BY_PARENT',
  FETCH_FUNCTION_TO_ADD: 'FETCH_FUNCTION_TO_ADD',
  FETCH_FUNCTION_TO_PASS: 'FETCH_FUNCTION_TO_PASS',
  FETCH_FUNCTION_TO_ADD_SUCCESS: 'FETCH_FUNCTION_TO_ADD_SUCCESS',
  FETCH_FUNCTION_TO_PASS_SUCCESS: 'FETCH_FUNCTION_TO_PASS_SUCCESS',
  FETCH_REASONS: 'FETCH_REASONS',
  FETCH_REASONS_SUCCESS: 'FETCH_REASONS_SUCCESS',
  FETCH_ALL_REASONS: 'FETCH_ALL_REASONS',
  FETCH_ALL_REASONS_SUCCESS: 'FETCH_ALL_REASONS_SUCCESS',
  FETCH_ADDITIONAL_INFO_COMBO: 'FETCH_ADDITIONAL_INFO_COMBO',
  FETCH_ORGANIZED_UNITS_ACCEPT_CLIENTS_COMBO: 'FETCH_ORGANIZED_UNITS_ACCEPT_CLIENTS_COMBO',
  FETCH_VO_COMPANIES_COMBO: 'FETCH_VO_COMPANIES_COMBO',
  FETCH_UO_SALESMAN_COMBO: 'FETCH_UO_SALESMAN_COMBO',
  FETCH_VEHICLETYPE: 'FETCH_VEHICLETYPE',
  FETCH_COMPANIES: 'FETCH_COMPANIES',
  FETCH_COMPANIES_SUCCESS: 'FETCH_COMPANIES_SUCCESS',
  FETCH_RENTING_ENTITY_COMBO: 'FETCH_RENTING_ENTITY_COMBO',
  FETCH_BANK_COMBO: 'FETCH_BANK_COMBO',
  FETCH_HOMOLOGATION_CLASIFICATION_COMBO: 'FETCH_HOMOLOGATION_CLASIFICATION_COMBO',
  FETCH_HOMOLOGATION_PRODUCER_COMBO: 'FETCH_HOMOLOGATION_PRODUCER_COMBO',
  FETCH_IAE_COMBO: 'FETCH_IAE_COMBO',
  FETCH_DOCUMENTS_TYPE_RESTRICTED_COMBO: 'FETCH_DOCUMENTS_TYPE_RESTRICTED_COMBO',
  FETCH_SERVICE_DESTINATION_TYPES_COMBO: 'FETCH_SERVICE_DESTINATION_TYPES_COMBO',
  FETCH_STOCK_STATUS_COMBO: 'FETCH_STOCK_STATUS_COMBO',
  FETCH_PURCHASE_ORDER_TYPE_COMBO: 'FETCH_PURCHASE_ORDER_TYPE_COMBO',
  FETCH_ORGANIZED_UNITS_TREE_PROMISE: 'FETCH_ORGANIZED_UNITS_TREE_PROMISE',
  FETCH_PURCHASE_PRESENT_TYPES: 'FETCH_PURCHASE_PRESENT_TYPES',
  FETCH_PURCHASE_PRESENT_TYPES_SUCCESS: 'FETCH_PURCHASE_PRESENT_TYPES_SUCCESS',
  FETCH_MARGIN_TYPES_COMBO: 'FETCH_MARGIN_TYPES_COMBO',
  FETCH_ORGANIZEDUNITS_BY_DOSSIERUO: 'FETCH_ORGANIZEDUNITS_BY_DOSSIERUO',
  FETCH_ORGANIZEDUNITS_BY_DOSSIERUO_SUCCES: 'FETCH_ORGANIZEDUNITS_BY_DOSSIERUO_SUCCES',
  FETCH_USER_COMBO: 'FETCH_USER_COMBO',
  FETCH_USER_COMBO_SUCCESS: 'FETCH_USER_COMBO_SUCCESS',
  FETCH_CAMPAIGN_TYPE_BY_GROUP: 'FETCH_CAMPAIGN_TYPE_BY_GROUP',
  FETCH_CAMPAIGN_TYPE_BY_GROUP_SUCCESS: 'FETCH_CAMPAIGN_TYPE_BY_GROUP_SUCCESS',
  FETCH_CAMPAIGN_CLASS: 'FETCH_CAMPAIGN_CLASS',
  FETCH_CAMPAIGN_CLASS_SUCCESS: 'FETCH_CAMPAIGN_CLASS_SUCCESS',
  FETCH_CAMPAIGN_GROUP: 'FETCH_CAMPAIGN_GROUP',
  FETCH_FLEETS_COMBO: 'FETCH_FLEETS_COMBO',
  FETCH_FLEETS_COMBO_SUCCESS: 'FETCH_FLEETS_COMBO_SUCCESS',
  FETCH_CAMPAIGN_GROUP_SUCCESS: 'FETCH_CAMPAIGN_GROUP_SUCCESS',
  FETCH_COUNTRY_COMBO: 'FETCH_COUNTRY_COMBO',
  FETCH_COUNTRY_COMBO_SUCCES: 'FETCH_COUNTRY_COMBO_SUCCES',
  FETCH_FAMILY_COMBO: 'FETCH_FAMILY_COMBO',
  FETCH_FAMILY_COMBO_SUCCES: 'FETCH_FAMILY_COMBO_SUCCES',
  FETCH_EXTERNALCODE_COMBO: 'FETCH_EXTERNALCODE_COMBO',
  FETCH_EXTERNALCODE_COMBO_SUCCES: 'FETCH_EXTERNALCODE_COMBO_SUCCES',
  FETCH_PAYMENT_METHOD_CAMPAIGN: 'FETCH_PAYMENT_METHOD_CAMPAIGN',
  FETCH_PAYMENT_METHOD_CAMPAIGN_SUCCESS: 'FETCH_PAYMENT_METHOD_CAMPAIGN_SUCCESS',
  FETCH_FLEET: 'FETCH_FLEET',
  FETCH_FLEET_SUCCESS: 'FETCH_FLEET_SUCCESS',
  FETCH_FLEET_COMPONENT: 'FETCH_FLEET_COMPONENT',
  FETCH_FLEETS_USER: 'FETCH_FLEETS_USER',
  FETCH_FLEETS_USER_SUCCES: 'FETCH_FLEETS_USER_SUCCES',
  FETCH_MANAGEMENT_ACTIONS: 'FETCH_MANAGEMENT_ACTIONS',
  FETCH_MANAGEMENT_ACTIONS_SUCCESS: 'FETCH_MANAGEMENT_ACTIONS_SUCCESS',
  FETCH_SELF_SUPPLY_ORGANIZED_UNITS: 'FETCH_SELF_SUPPLY_ORGANIZED_UNITS',
  FETCH_CANCEL_COMBO: 'FETCH_CANCEL_COMBO',
  FETCH_CANCEL_COMBO_SUCCESS: 'FETCH_CANCEL_COMBO_SUCCESS',
  FETCH_MASSIVE_DOWNLOAD_STATUS: 'FETCH_MASSIVE_DOWNLOAD_STATUS',
  FETCH_MASSIVE_DOWNLOAD_STATUS_SUCCESS: 'FETCH_MASSIVE_DOWNLOAD_STATUS_SUCCESS',
  FETCH_DOCUMENT_TYPE_ENTITIES: 'FETCH_DOCUMENT_TYPE_ENTITIES',
  FETCH_DOCUMENT_TYPE_ENTITIES_SUCCESS: 'FETCH_DOCUMENT_TYPE_ENTITIES_SUCCESS',
  FETCH_DOCUMENT_TYPE: 'FETCH_DOCUMENT_TYPE',
  FETCH_DOCUMENT_TYPE_SUCCESS: 'FETCH_DOCUMENT_TYPE_SUCCESS',
  FETCH_ORGANIZED_UNITS_ALL: 'FETCH_ORGANIZED_UNITS_ALL',
  FETCH_SALE_TYPE_ALL: 'FETCH_SALE_TYPE_ALL',
  FETCH_OPERATION_TYPE_ALL: 'FETCH_OPERATION_TYPE_ALL',
  FETCH_CONFIG_PARAMETER_ALL: 'FETCH_CONFIG_PARAMETER_ALL',
  FETCH_UNATTENDED_PROCESS_TYPES: 'FETCH_UNATTENDED_PROCESS_TYPES',
  FETCH_UNATTENDED_PROCESS_TYPES_SUCCESS: 'FETCH_UNATTENDED_PROCESS_TYPES_SUCCESS',
  FETCH_UNATTENDED_PROCESS_VIEW: 'FETCH_UNATTENDED_PROCESS_VIEW',
  FETCH_UNATTENDED_PROCESS_VIEW_SUCCESS: 'FETCH_UNATTENDED_PROCESS_VIEW_SUCCESS',
  FETCH_LEGAL_COMPANY_COMBO: 'FETCH_LEGAL_COMPANY_COMBO',
  FETCH_LEGAL_COMPANY_COMBO_SUCCESS: 'FETCH_LEGAL_COMPANY_COMBO_SUCCESS',
  FETCH_PROCESSING_AGENCY_COMBO: 'FETCH_PROCESSING_AGENCY_COMBO',
  FETCH_PROCESSING_AGENCY_COMBO_SUCCESS: 'FETCH_PROCESSING_AGENCY_COMBO_SUCCESS',
  FETCH_DIRECTIION_OU_COMBO: 'FETCH_DIRECTIION_OU_COMBO',
  FETCH_DIRECTIION_OU_COMBO_SUCCESS: 'FETCH_DIRECTIION_OU_COMBO_SUCCESS',
  FETCH_ALL_SALE_TYPE: 'FETCH_ALL_SALE_TYPE',
  FETCH_ALL_SALE_TYPE_SUCCESS: 'FETCH_ALL_SALE_TYPE_SUCCESS',
  FETCH_COMPANIES_BY_OU: 'FETCH_COMPANIES_BY_OU',
  FETCH_CURRENCY_COMBO_SUCCESS: 'FETCH_CURRENCY_COMBO_SUCCESS',
  FETCH_INSURANCE_CARRIER_SUCCESS: 'FETCH_INSURANCE_CARRIER_SUCCESS',
  FETCH_PROCESSING_AGENCY_SERVICE_DESTINATIONS_COMBO_SUCCESS: 'FETCH_PROCESSING_AGENCY_SERVICE_DESTINATIONS_COMBO_SUCCESS',
  FETCH_COMMERCIAL_SOCIETY_TYPE_COMBO: 'FETCH_COMMERCIAL_SOCIETY_TYPE_COMBO',
  FETCH_COMMERCIAL_SOCIETY_TYPE_COMBO_SUCCESS: 'FETCH_COMMERCIAL_SOCIETY_TYPE_COMBO_SUCCESS',
  FETCH_CARLINE_COMBO: 'FETCH_CARLINE_COMBO',
  FETCH_CATALOGUE_COMBO: 'FETCH_CATALOGUE_COMBO',
  FETCH_MODEL_YEAR: 'FETCH_MODEL_YEAR',
  FETCH_COLOR_COMBO: 'FETCH_COLOR_COMBO',
  FETCH_TAPESTRY_COMBO: 'FETCH_TAPESTRY_COMBO',
  RESET_CARLINE_COMBO: 'RESET_CARLINE_COMBO',
  RESET_CATALOGUE_COMBO: 'RESET_CATALOGUE_COMBO',
  RESET_MODEL_YEAR: 'RESET_MODEL_YEAR',
  RESET_COLOR_COMBO: 'RESET_COLOR_COMBO',
  RESET_TAPESTRY_COMBO: 'RESET_TAPESTRY_COMBO',
  FETCH_ACTIVITY_BPRO_ALL: 'FETCH_ACTIVITY_BPRO_ALL',
  FETCH_OPERATION_TYPE_DMS_ALL: 'FETCH_OPERATION_TYPE_DMS_ALL',
  FETCH_SAME_LEVEL_OUS_COMBO: 'FETCH_SAME_LEVEL_OUS_COMBO',
  FETCH_RATING_COMBO: 'FETCH_RATING_COMBO',
  FETCH_RATING_COMBO_BY_UO: 'FETCH_RATING_COMBO_BY_UO',
  FETCH_RATING_COMBO_SUCCESS: 'FETCH_RATING_COMBO_SUCCESS',
  FETCH_DOCUMENT_DATA_OPTIONS: 'FETCH_DOCUMENT_DATA_OPTIONS',
  FETCH_DOCUMENT_DATA_OPTIONS_SUCCESS: 'FETCH_DOCUMENT_DATA_OPTIONS_SUCCESS',
  FETCH_CUSTOM_FIELD_TYPES_COMBO: 'FETCH_CUSTOM_FIELD_TYPES_COMBO',
  FETCH_MARITAL_STATUS_COMBO: 'FETCH_MARITAL_STATUS_COMBO',
  FETCH_CLIENT_DATA_ADDRESS: 'FETCH_CLIENT_DATA_ADDRESS',
  FETCH_AUDIT_CONFIGURATION_TYPE: 'FETCH_AUDIT_CONFIGURATION_TYPE',
  FETCH_PROCESSING_AGENCY_MANAGERS_COMBO: 'FETCH_PROCESSING_AGENCY_MANAGERS_COMBO',
  FETCH_PURCHASE_LOGISTIC_STATE_COMBO: 'FETCH_PURCHASE_LOGISTIC_STATE_COMBO',
  FETCH_PURCHASE_LOGISTIC_STATE_COMBO_SUCCESS: 'FETCH_PURCHASE_LOGISTIC_STATE_COMBO_SUCCESS',
  FETCH_EVENTS_COMBO: 'FETCH_EVENTS_COMBO',
  FETCH_INSURANCE_COMPANY_COMBO: 'FETCH_INSURANCE_COMPANY_COMBO',
  FETCH_USER_REPAIR_WORKSHOPS_COMBO: 'FETCH_USER_REPAIR_WORKSHOPS_COMBO',
  FETCH_FUEL_COMBO: 'FETCH_FUEL_COMBO',
  FETCH_USAGE_PROCESS_TYPES_COMBO: 'FETCH_USAGE_PROCESS_TYPES_COMBO'
}
