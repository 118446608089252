import React from 'react'
import { useMatomo } from '@datapunt/matomo-tracker-react'
import MatomoService from '../../services/matomo/matomoService'

const TrackingContainer = (Component, autoTrackVisitPage = true) => {
  return (props) => {
    let { trackPageView, trackEvent, enableLinkTracking } = useMatomo()

    enableLinkTracking()

    React.useEffect(() => {
      if (autoTrackVisitPage) {
        trackVisitPage({
          href: window.location.href
        })
      }
    }, [])

    let trackAction = (eventData) => {
      let matomoService = new MatomoService()
      matomoService.trackAction(eventData, trackEvent)
    }

    let trackVisitPage = (eventData) => {
      let matomoService = new MatomoService()
      matomoService.trackPageVisit(eventData, trackPageView)
    }

    return (
      <Component {...props} trackAction={trackAction} trackVisitPage={trackVisitPage} />
    )
  }
}

export default TrackingContainer
