import managementActionTypes from '../../constants/actions/management/management'

export function saveFleetDataChanges (dossierId, saleType, operationTypes, paymentMethod, resolve, reject) {
  return {
    type: managementActionTypes.SAVE_FLEET_DATA_CHANGES,
    dossierId,
    saleType,
    operationTypes,
    paymentMethod,
    resolve,
    reject
  }
}

export function fetchFleetsInfo (filters) {
  return {
    type: managementActionTypes.FETCH_FLEETS_INFO,
    filters
  }
}

export function fetchFleetsInfoSuccess (data, filters) {
  return {
    type: managementActionTypes.FETCH_FLEETS_INFO_SUCCESS,
    data,
    filters
  }
}

export function resetFiltersFleetInfo () {
  return {
    type: managementActionTypes.RESET_FILTERS_FLEET_INFO
  }
}

export function openActionFleetFinder (dossiers, organizedUnitId) {
  return {
    type: managementActionTypes.OPEN_DATA_ACTION_FLEET_FINDER,
    dossiers,
    organizedUnitId
  }
}

export function closeActionFleetFinder () {
  return {
    type: managementActionTypes.CLOSE_DATA_ACTION_FLEET_FINDER
  }
}

export function asignDossierToFleet (dossierId, fleetId, resolve, reject) {
  return {
    type: managementActionTypes.ASIGN_DOSSIER_TO_FLEET,
    dossierId,
    fleetId,
    resolve,
    reject
  }
}

export function createUpdateFleet (fleetModel, resolve, reject, fleet) {
  return {
    type: managementActionTypes.CREATE_UPDATE_FLEET,
    fleetModel,
    resolve,
    reject,
    fleet
  }
}

export function deleteDossierFromFleet (dossierId, resolve, reject) {
  return {
    type: managementActionTypes.DELETE_DOSSIER_FROM_FLEET,
    dossierId,
    resolve,
    reject
  }
}

export function deleteFleet (fleetId, resolve, reject) {
  return {
    type: managementActionTypes.DELETE_FLEET,
    fleetId,
    resolve,
    reject
  }
}

export function changeEntityDossierFromFleet (dossierId, fleetId, resolve, reject) {
  return {
    type: managementActionTypes.CHANGE_ENTITY_DOSSIER_FROM_FLEET,
    dossierId,
    fleetId,
    resolve,
    reject
  }
}

export function openNewFleetModal (create, dossiers, organizedUnitId, fleet, dossierData) {
  return {
    type: managementActionTypes.OPEN_NEW_FLEET_MODAL,
    create,
    dossiers,
    organizedUnitId,
    fleet,
    dossierData
  }
}

export function closeNewFleetModal () {
  return {
    type: managementActionTypes.CLOSE_NEW_FLEET_MODAL
  }
}

export function closeAuditAdminModal () {
  return {
    type: managementActionTypes.CLOSE_AUDIT_ADMIN_MODAL
  }
}

export function closeZipModal () {
  return {
    type: managementActionTypes.CLOSE_ZIP_MODAL
  }
}

export function fetchFleetsInfoDownload (filters, columns) {
  return {
    type: managementActionTypes.FETCH_FLEETS_INFO_DOWNLOAD,
    filters,
    columns
  }
}
export function fetchPaymentCombo (includeDeleted) {
  return {
    type: managementActionTypes.FETCH_PAYMENT_COMBO,
    includeDeleted
  }
}

export function fetchPaymentComboSuccess (data) {
  return {
    type: managementActionTypes.FETCH_PAYMENT_COMBO_SUCCESS,
    data
  }
}

export function changeDniData (data) {
  return {
    type: managementActionTypes.CHANGE_DNICIF_DATA,
    data
  }
}

export function getClientFleetPermissions (fleetId, organizedUnitId, dossierSubtypeId) {
  return {
    type: managementActionTypes.GET_CLIENT_FLEET_PERMISSIONS,
    fleetId,
    organizedUnitId,
    dossierSubtypeId
  }
}

export function getClientFleetPermissionsSuccess (data) {
  return {
    type: managementActionTypes.GET_CLIENT_FLEET_PERMISSIONS_SUCCESS,
    data
  }
}

export function fetchEntityFleetSucces (data) {
  return {
    type: managementActionTypes.FETCH_ENTITY_FLEET_SUCCES,
    data
  }
}

export function fetchEntityChildFleetSucces (data) {
  return {
    type: managementActionTypes.FETCH_ENTITY_CHILD_FLEET_SUCCES,
    data
  }
}

export function fetchFleetsEntity (fleetId, organizedUnitId) {
  return {
    type: managementActionTypes.FETCH_ENTITY_FLEET,
    fleetId,
    organizedUnitId
  }
}

export function checkChangeEntityDossier (fleetId, dossierList) {
  return {
    type: managementActionTypes.CHECK_CHANGE_ENTITY_DOSSIER,
    fleetId,
    dossierList
  }
}

export function checkChangeEntityDossierSuccess (data) {
  return {
    type: managementActionTypes.CHECK_CHANGE_ENTITY_DOSSIER_SUCCESS,
    data
  }
}

export function sendToApproveActionFleet (dossierId, resolve, reject) {
  return {
    type: managementActionTypes.SEND_TO_APPROVE_ACTION_FLEET,
    dossierId,
    resolve,
    reject
  }
}

export function approveActionFleet (dossierId, resolve, reject) {
  return {
    type: managementActionTypes.APPROVE_ACTION_FLEET,
    dossierId,
    resolve,
    reject
  }
}

export function approveRoleActionFleet (dossierId, roleId, resolve, reject) {
  return {
    type: managementActionTypes.APPROVE_ROLE_ACTION_FLEET,
    dossierId,
    roleId,
    resolve,
    reject
  }
}

export function openDocumentDiferentsModalFleets (dossiers, organizedUnitId) {
  return {
    type: managementActionTypes.OPEN_UPLOAD_DOCUMENTS_FLEET_MODAL,
    dossiers,
    organizedUnitId
  }
}
export function closeDiferentDocumentUploadModal () {
  return {
    type: managementActionTypes.CLOSE_UPLOAD_DOCUMENTS_FLEET_MODAL
  }
}

export function fecthValidateDocumentFleetPermission (documentTypeUseId, organizedUnitId, resolve, reject) {
  return {
    type: managementActionTypes.FETCH_VALIDATE_DOCUMENT_FLEET,
    documentTypeUseId,
    organizedUnitId,
    resolve,
    reject
  }
}

export function uploadZipDocumentFleet (file, dossiers, documentTypeUseId, organizedUnitId, validate, resolve, reject) {
  return {
    type: managementActionTypes.UPLOAD_ZIP_DOCUMENT_FLEET,
    file,
    dossiers,
    documentTypeUseId,
    organizedUnitId,
    validate,
    resolve,
    reject
  }
}

export function getDocumentTypesFleets () {
  return {
    type: managementActionTypes.GET_DOCUMENT_TYPE_FLEETS
  }
}

export function openEconomicPlanModal (dossierIds, uoId, dossierSubtypeId) {
  return {
    type: managementActionTypes.OPEN_ECONOMIC_PLAN_MODAL,
    dossierIds,
    uoId,
    dossierSubtypeId
  }
}

export function closeEconomicPlanModal () {
  return {
    type: managementActionTypes.CLOSE_ECONOMIC_PLAN_MODAL
  }
}

export function fetchEconomicPlanCombos (organizedUnitId, dossierSubTypeId) {
  return {
    type: managementActionTypes.FETCH_ECONOMIC_PLAN_COMBOS,
    organizedUnitId,
    dossierSubTypeId
  }
}

export function updateEconomicPlanFleet (dossierEconomicComponent) {
  return {
    type: managementActionTypes.UPATE_ECONOMIC_PLAN_FLEET,
    dossierEconomicComponent
  }
}

export function getEconomicPlanPermissions (dossierSubTypeId, organizedUnitId) {
  return {
    type: managementActionTypes.GET_ECONOMIC_PLAN_FLEET_PERMISSIONS,
    dossierSubTypeId,
    organizedUnitId
  }
}

export function getEconomicPlanPermissionsSuccess (data) {
  return {
    type: managementActionTypes.GET_ECONOMIC_PLAN_FLEET_PERMISSIONS_SUCCESS,
    data
  }
}

export function simulateEconomicPlanFleet (data) {
  return {
    type: managementActionTypes.SIMULATE_ECONOMIC_PLAN_FLEET,
    data
  }
}

export function simulateEconomicPlanFleetSuccess (data, simulated) {
  return {
    type: managementActionTypes.SIMULATE_ECONOMIC_PLAN_FLEET_SUCCESS,
    data,
    simulated
  }
}

export function addCampaign (data) {
  return {
    type: managementActionTypes.ADD_CAMPAIGN_ECONOMIC_PLAN_FLEET,
    data
  }
}

export function addCampaignSuccess (data) {
  return {
    type: managementActionTypes.ADD_CAMPAIGN_ECONOMIC_PLAN_FLEET_SUCCESS,
    data
  }
}

export function saveEconomicPlanFleet (dossierId, resolve, reject, first) {
  return {
    type: managementActionTypes.SAVE_ECONOMIC_PLAN_FLEET,
    dossierId,
    resolve,
    reject,
    first
  }
}

export function saveAuditAdmin (auditAdmin, resolve, reject) {
  return {
    type: managementActionTypes.SAVE_AUDIT_ADMIN,
    auditAdmin,
    resolve,
    reject
  }
}

export function documentAudit (documentForm, resolve, reject) {
  return {
    type: managementActionTypes.DOCUMENT_AUDIT,
    documentForm,
    resolve,
    reject
  }
}

export function documentAuditFinish (documentForm, resolve, reject) {
  return {
    type: managementActionTypes.DOCUMENT_AUDIT_FINISH,
    documentForm,
    resolve,
    reject
  }
}
export function deleteAuditAdmin (auditAdminId, resolve, reject) {
  return {
    type: managementActionTypes.DELETE_AUDIT_ADMIN,
    auditAdminId,
    resolve,
    reject
  }
}

export function showFrontEconomicPlan (organizedUnitId, dossierSubTypeId, resolve, reject) {
  return {
    type: managementActionTypes.FETCH_SHOW_FRONT_ECONOMIC_PLAN,
    organizedUnitId,
    dossierSubTypeId,
    resolve,
    reject
  }
}

export function deleteEconomicCampaign (dossierExtra, dossierExtraType) {
  return {
    type: managementActionTypes.DELETE_ECONOMIC_CAMPAIGN,
    dossierExtra,
    dossierExtraType
  }
}

export function setSimulatedFlag (value) {
  return {
    type: managementActionTypes.SET_SIMULATED_FLAG,
    value
  }
}

export function fetchFleetsManagement (dynamicFilters, filters) {
  return {
    type: managementActionTypes.FIND_FLEETS_MANAGEMENT,
    dynamicFilters,
    filters
  }
}

export function fetchFleetsManagementSuccess ({ data, dynamicFilters, filters }) {
  return {
    type: managementActionTypes.FIND_FLEETS_MANAGEMENT_SUCCESS,
    data,
    dynamicFilters,
    filters
  }
}

export function fetchAuditConfigurationDocument (auditAdmin, resolve, reject) {
  return {
    type: managementActionTypes.FETCH_AUDIT_CONFIGURATION_DOCUMENTS,
    auditAdmin,
    resolve,
    reject
  }
}

export function resetFleetManagementFilters () {
  return {
    type: managementActionTypes.RESET_FLEETS_MANAGEMENT_FILTERS
  }
}

export function modifyFleetManagementFilters () {
  return {
    type: managementActionTypes.MODIFY_FLEETS_MANAGEMENT_FILTERS
  }
}

export function exportFleetsManagement (dynamicFilters, filters) {
  return {
    type: managementActionTypes.FIND_FLEETS_MANAGEMENT_EXPORT,
    dynamicFilters,
    filters
  }
}

export function openCreateEditFleetModal (create, fleet, isReadOnly) {
  return {
    type: managementActionTypes.OPEN_CREATE_EDIT_FLEET_MODAL,
    create,
    fleet,
    isReadOnly
  }
}

export function closeCreateEditFleetModal () {
  return {
    type: managementActionTypes.CLOSE_CREATE_EDIT_FLEET_MODAL
  }
}

export function getFleetModel (fleetId, resolve, reject) {
  return {
    type: managementActionTypes.GET_FLEET_MODEL,
    fleetId,
    resolve,
    reject

  }
}

export function getFleetModelSuccess (fleet) {
  return {
    type: managementActionTypes.GET_FLEET_MODEL_SUCCESS,
    fleet
  }
}

export function openEditFleetModal (fleet) {
  return {
    type: managementActionTypes.OPEN_EDIT_FLEET_MODAL,
    fleet
  }
}

export function closeEditFleetModal () {
  return {
    type: managementActionTypes.CLOSE_EDIT_FLEET_MODAL
  }
}

export function getDossierDetailsFleet (fleetId, resolve) {
  return {
    type: managementActionTypes.FETCH_DOSSIER_DETAIL_FLEET,
    fleetId,
    resolve
  }
}

export function getDossierDetailsFleetSuccess (data) {
  return {
    type: managementActionTypes.FETCH_DOSSIER_DETAIL_FLEET_SUCCESS,
    data
  }
}

export function deleteDossiersFromFleet (dossierIds, fleetId, resolve) {
  return {
    type: managementActionTypes.DELETE_DOSSIERS_FROM_FLEET,
    dossierIds,
    fleetId,
    resolve
  }
}

export function openOperateFleetModal (fleet) {
  return {
    type: managementActionTypes.OPEN_OPERATE_FLEET_MODAL,
    fleet
  }
}

export function openOperateFleetModalSuccess (fleet) {
  return {
    type: managementActionTypes.OPEN_OPERATE_FLEET_MODAL_SUCCESS,
    fleet
  }
}

export function closeOperateFleetModal () {
  return {
    type: managementActionTypes.CLOSE_OPERATE_FLEET_MODAL
  }
}

export function getFleetOperations () {
  return {
    type: managementActionTypes.FETCH_OPERATE_ACTIONS_FLEET
  }
}

export function getFleetOperationsSuccess (dataActions, operationActions, dataPaperWorkActions, operationPaperWorkActions) {
  return {
    type: managementActionTypes.FETCH_OPERATE_ACTIONS_FLEET_SUCCESS,
    dataActions,
    operationActions,
    dataPaperWorkActions,
    operationPaperWorkActions

  }
}

export function initializeOperationActionsCombo (actionId, dataId) {
  return {
    type: managementActionTypes.INITIALIZE_OPERATION_ACTION_COMBOS,
    actionId,
    dataId
  }
}

export function initializeOperationActionsComboSuccess (actionId, dataId) {
  return {
    type: managementActionTypes.INITIALIZE_OPERATION_ACTION_COMBOS_SUCCESS,
    actionId,
    dataId
  }
}

export function fetchOperateDataFleetModalsPermissions (dossierSubTypeId, organizedUnitId, stageId) {
  return {
    type: managementActionTypes.FETCH_OPERATE_DATA_FLEET_MODALS_PERMISSIONS,
    dossierSubTypeId,
    organizedUnitId,
    stageId
  }
}

export function fetchOperateDataFleetModalsPermissionsSuccess (data) {
  return {
    type: managementActionTypes.FETCH_OPERATE_DATA_FLEET_MODALS_PERMISSIONS_SUCCESS,
    data
  }
}

export function fetchOperatePaperworkFleetPermissions (organizedUnitId, stageId) {
  return {
    type: managementActionTypes.FETCH_OPERATE_PAPERWORK_FLEET_PERMISSIONS,
    organizedUnitId,
    stageId
  }
}

export function fetchOperatePaperworkFleetPermissionsSuccess (data) {
  return {
    type: managementActionTypes.FETCH_OPERATE_PAPERWORK_FLEET_PERMISSIONS_SUCCESS,
    data
  }
}

export function getDocumentFleetList (fleetId, dossierList) {
  return {
    type: managementActionTypes.FETCH_DOCUMENT_FLEET_LIST,
    fleetId,
    dossierList
  }
}

export function getDocumentFleetListSuccess (data) {
  return {
    type: managementActionTypes.FETCH_DOCUMENT_FLEET_LIST_SUCCESS,
    data
  }
}

export function openDocumentFleetModal (fleet, dossiersMode, dossiers) {
  return {
    type: managementActionTypes.OPEN_DOCUMENT_FLEET_MODAL,
    fleet,
    dossiersMode,
    dossiers
  }
}

export function closeDocumentFleetModal () {
  return {
    type: managementActionTypes.CLOSE_DOCUMENT_FLEET_MODAL
  }
}

export function resetFiltersSelfSupplyInfo () {
  return {
    type: managementActionTypes.RESET_FILTERS_SELF_SUPPLY_INFO
  }
}

export function resetFiltersAuditAdminInfo () {
  return {
    type: managementActionTypes.RESET_FILTERS_AUDIT_ADMIN_INFO
  }
}

export function resetFiltersAuditsInfo () {
  return {
    type: managementActionTypes.RESET_FILTERS_AUDITS_INFO
  }
}

export function resetSearchDocumentsInfo () {
  return {
    type: managementActionTypes.RESET_FILTERS_SEARCH_DOCUMENT
  }
}

export function fetchSelfSupplyInfo (filters) {
  return {
    type: managementActionTypes.FETCH_SELF_SUPPLY_INFO,
    filters
  }
}

export function fetchAuditAdminInfo (filters) {
  return {
    type: managementActionTypes.FETCH_AUDIT_ADMIN_INFO,
    filters
  }
}

export function fetchAuditsInfo (filters) {
  return {
    type: managementActionTypes.FETCH_AUDITS_INFO,
    filters
  }
}

export function fetchSearchDocumentsInfo (filters) {
  return {
    type: managementActionTypes.FETCH_SEARCH_DOCUMENTS_INFO,
    filters
  }
}

export function fetchSearchDocumentsInfoSuccess (data, filters) {
  return {
    type: managementActionTypes.FETCH_SEARCH_DOCUMENTS_INFO_SUCCESS,
    data,
    filters
  }
}

export function fetchSelfSupplyInfoSuccess (data, filters) {
  return {
    type: managementActionTypes.FETCH_SELF_SUPPLY_INFO_SUCCES,
    data,
    filters
  }
}

export function fetchAuditAdminInfoSuccess (data, filters) {
  return {
    type: managementActionTypes.FETCH_AUDIT_ADMIN_INFO_SUCCESS,
    data,
    filters
  }
}

export function fetchAuditsInfoSuccess (data, filters) {
  return {
    type: managementActionTypes.FETCH_AUDITS_INFO_SUCCESS,
    data,
    filters
  }
}

export function fetchSelfSupplyInfoDownload (filters, columns) {
  return {
    type: managementActionTypes.FETCH_SELF_SUPPLY_INFO_DOWNLOAD,
    filters,
    columns
  }
}

export function passSelfSupplyVoAction (dossierId, organizedUnitId) {
  return {
    type: managementActionTypes.PASS_SELF_SUPPLY_VO_ACTION,
    dossierId,
    organizedUnitId
  }
}
export function fetchGetCanCreateOperateServices (organizedUnitId, dossierSubTypeId, resolve) {
  return {
    type: managementActionTypes.FETCH_GET_CAN_CREATE_OPERATE_SERVICES,
    organizedUnitId,
    dossierSubTypeId,
    resolve
  }
}

export function openEconomicFleetModal (fleet, mode, dossiers) {
  return {
    type: managementActionTypes.OPEN_ECONOMIC_FLEET_MODAL,
    fleet,
    mode,
    dossiers
  }
}

export function openEconomicFleetModalSuccess (fleet, mode, dossiers) {
  return {
    type: managementActionTypes.OPEN_ECONOMIC_FLEET_MODAL_SUCCESS,
    fleet,
    mode,
    dossiers
  }
}

export function closeEconomicFleetModal () {
  return {
    type: managementActionTypes.CLOSE_ECONOMIC_FLEET_MODAL
  }
}

export function showHistoricalFleetModal (show, fleet) {
  return {
    type: managementActionTypes.OPEN_CLOSE_HISTORICAL_FLEET_MODAL,
    show,
    fleet
  }
}

export function fetchEntityUacModalSucces (data) {
  return {
    type: managementActionTypes.FETCH_ENTITY_UAC_MODAL_SUCCES,
    data
  }
}

export function openUacModal (data) {
  return {
    type: managementActionTypes.OPEN_UAC_MODAL,
    data
  }
}

export function closeUacModal () {
  return {
    type: managementActionTypes.CLOSE_UAC_MODAL
  }
}

export function managementTabChange (selectedTab) {
  return {
    type: managementActionTypes.TAB_CHANGE,
    selectedTab
  }
}

export function openAuditAdminModal (formData) {
  return {
    type: managementActionTypes.OPEN_AUDITADMIN_MODAL,
    formData
  }
}

export function openAuditAdminModalSuccess (auditAdminStatus, formData) {
  return {
    type: managementActionTypes.OPEN_ADD_AUDITADMIN_MODAL_SUCCESS,
    auditAdminStatus,
    formData
  }
}

export function openZipModal (number) {
  return {
    type: managementActionTypes.OPEN_ZIP_MODAL,
    number
  }
}

export function openZipModalSuccess (number) {
  return {
    type: managementActionTypes.OPEN_ZIP_MODAL_SUCCESS,
    number
  }
}

export function fetchDocumentTypeUsesInfo (filters, omit) {
  return {
    type: managementActionTypes.FETCH_DOCUMENT_TYPE_USES_INFO,
    filters,
    omit
  }
}

export function fetchDocumentTypeUsesInfoSuccess (data, filters) {
  return {
    type: managementActionTypes.FETCH_DOCUMENT_TYPE_USES_INFO_SUCCESS,
    data,
    filters
  }
}

export function changeCodeCommissionFleet (codeCommission) {
  return {
    type: managementActionTypes.CHANGE_CODE_COMMISSION_FLEET,
    codeCommission
  }
}

export function changeCodeCommissionFleetSuccess (codeCommission) {
  return {
    type: managementActionTypes.CHANGE_CODE_COMMISSION_FLEET_SUCCESS,
    codeCommission
  }
}

export function getPaperworksDetailsFleet (fleetId, resolve) {
  return {
    type: managementActionTypes.FETCH_PAPERWORK_FLEET_LIST,
    fleetId,
    resolve
  }
}

export function getPaperworksDetailsFleetSuccess (data) {
  return {
    type: managementActionTypes.FETCH_PAPERWORK_FLEET_LIST_SUCCESS,
    data
  }
}

export function getDossierChildSubtypeDetailCount (fleetId, dossierSubtypeId, resolve) {
  return {
    type: managementActionTypes.FETCH_CHILDS_SUBTYPE_FLEET_COUNT_LIST,
    fleetId,
    dossierSubtypeId,
    resolve
  }
}

export function getDossierChildSubtypeDetailCountSuccess (data) {
  return {
    type: managementActionTypes.FETCH_CHILDS_SUBTYPE_FLEET_COUNT_LIST_SUCCESS,
    data
  }
}

export function getChargesDetailsFleet (fleetId, resolve) {
  return {
    type: managementActionTypes.FETCH_CHARGE_FLEET_LIST,
    fleetId,
    resolve
  }
}

export function getChargesDetailsFleetSuccess (data) {
  return {
    type: managementActionTypes.FETCH_CHARGE_FLEET_LIST_SUCCESS,
    data
  }
}

export function getFleetEconomicSuccess (data) {
  return {
    type: managementActionTypes.FETCH_FLEET_ECONOMIC_SUCCESS,
    data
  }
}
export function managementSectionChangeSuccess (secMapManagement) {
  return {
    type: managementActionTypes.MANAGEMENT_SECTION_CHANGE_SUCCESS,
    secMapManagement
  }
}

export default {
  saveFleetDataChanges,
  fetchFleetsInfo,
  fetchFleetsInfoSuccess,
  resetFiltersFleetInfo,
  openActionFleetFinder,
  closeActionFleetFinder,
  asignDossierToFleet,
  createUpdateFleet,
  deleteDossierFromFleet,
  deleteFleet,
  changeEntityDossierFromFleet,
  openNewFleetModal,
  closeNewFleetModal,
  fetchFleetsInfoDownload,
  fetchPaymentCombo,
  fetchPaymentComboSuccess,
  changeDniData,
  getClientFleetPermissions,
  getClientFleetPermissionsSuccess,
  fetchEntityFleetSucces,
  fetchFleetsEntity,
  checkChangeEntityDossier,
  checkChangeEntityDossierSuccess,
  sendToApproveActionFleet,
  approveActionFleet,
  approveRoleActionFleet,
  openDocumentDiferentsModalFleets,
  closeDiferentDocumentUploadModal,
  fecthValidateDocumentFleetPermission,
  uploadZipDocumentFleet,
  getDocumentTypesFleets,
  openEconomicPlanModal,
  closeEconomicPlanModal,
  fetchEconomicPlanCombos,
  updateEconomicPlanFleet,
  getEconomicPlanPermissions,
  getEconomicPlanPermissionsSuccess,
  simulateEconomicPlanFleet,
  simulateEconomicPlanFleetSuccess,
  addCampaign,
  addCampaignSuccess,
  saveEconomicPlanFleet,
  showFrontEconomicPlan,
  setSimulatedFlag,
  fetchFleetsManagement,
  fetchFleetsManagementSuccess,
  resetFleetManagementFilters,
  modifyFleetManagementFilters,
  exportFleetsManagement,
  openCreateEditFleetModal,
  fetchEntityChildFleetSucces,
  closeCreateEditFleetModal,
  getFleetModel,
  getFleetModelSuccess,
  openEditFleetModal,
  closeEditFleetModal,
  getDossierDetailsFleet,
  getDossierDetailsFleetSuccess,
  deleteDossiersFromFleet,
  openOperateFleetModal,
  closeOperateFleetModal,
  getFleetOperations,
  getFleetOperationsSuccess,
  openOperateFleetModalSuccess,
  initializeOperationActionsCombo,
  initializeOperationActionsComboSuccess,
  fetchOperateDataFleetModalsPermissions,
  fetchOperateDataFleetModalsPermissionsSuccess,
  fetchOperatePaperworkFleetPermissions,
  fetchOperatePaperworkFleetPermissionsSuccess,
  getDocumentFleetList,
  getDocumentFleetListSuccess,
  openDocumentFleetModal,
  closeDocumentFleetModal,
  resetFiltersSelfSupplyInfo,
  resetFiltersAuditAdminInfo,
  fetchSelfSupplyInfo,
  fetchSelfSupplyInfoSuccess,
  fetchSelfSupplyInfoDownload,
  passSelfSupplyVoAction,
  fetchGetCanCreateOperateServices,
  openEconomicFleetModal,
  openEconomicFleetModalSuccess,
  closeEconomicFleetModal,
  showHistoricalFleetModal,
  fetchEntityUacModalSucces,
  closeUacModal,
  openUacModal,
  managementTabChange,
  fetchAuditAdminInfo,
  fetchAuditAdminInfoSuccess,
  openAuditAdminModal,
  openAuditAdminModalSuccess,
  saveAuditAdmin,
  documentAudit,
  documentAuditFinish,
  fetchDocumentTypeUsesInfo,
  fetchDocumentTypeUsesInfoSuccess,
  closeAuditAdminModal,
  fetchAuditConfigurationDocument,
  deleteAuditAdmin,
  resetFiltersAuditsInfo,
  fetchAuditsInfo,
  fetchAuditsInfoSuccess,
  fetchSearchDocumentsInfo,
  fetchSearchDocumentsInfoSuccess,
  resetSearchDocumentsInfo,
  openZipModal,
  openZipModalSuccess,
  closeZipModal,
  changeCodeCommissionFleet,
  changeCodeCommissionFleetSuccess,
  getPaperworksDetailsFleet,
  getPaperworksDetailsFleetSuccess,
  getDossierChildSubtypeDetailCount,
  getDossierChildSubtypeDetailCountSuccess,
  getChargesDetailsFleet,
  getChargesDetailsFleetSuccess,
  getFleetEconomicSuccess,
  managementSectionChangeSuccess
}
