import React, { Component } from 'react'
import { Field } from 'redux-form'
import { Button, Modal, Col, Row } from 'react-bootstrap'
import InputMoney from '../../../commons/form/InputMoney'
import InputSelect from '../../../commons/form/InputSelect'

class UACAppraisalWithTAXModal extends Component {
  constructor (props) {
    super()
    this.state = {
      taxquantity: 0,
      UACamountWithoutTax: 0,
      impor: 0
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (!this.props.showModal && nextProps.showModal) {
      this.props.actions.change('UACivaType', this.props.valueCombo || nextProps.valueCombo)
      this.props.ivaTypeCombo.filter((value, indx) => {
        if ((this.props.valueCombo || nextProps.valueCombo) === value.id) {
          this.setState({ taxquantity: value.amountValue + 1 })
        }
      })
    }
  }

  handleBlurAmount (value) {
    this.props.actions.change('UACamountWithoutTax', (value / this.state.taxquantity).toFixed(2))
    this.setState({ UACamountWithoutTax: (value / this.state.taxquantity).toFixed(2) })
  }

  saveWithoutTax () {
    this.props.actions.change('amount', this.state.UACamountWithoutTax)
  }

  render () {
    const { t, showModal, actions: { closeModal }, ivaTypeCombo } = this.props
    const tKey = 'DOSSIER_COMPONENTS.ECONOMIC_PLAN.MODAL_TAXES.'
    return (
      <Modal show={showModal} onHide={() => { closeModal() }}>
        <Modal.Header closeButton>
          <Modal.Title>{t(`${tKey}TITLE`)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Field
              id="UACamountWithTax"
              name="UACamountWithTax"
              colSm={6}
              controlLabel={t(`${tKey}AMOUNT_WITH_TAX`)}
              placeholder={t(`${tKey}AMOUNT_WITH_TAX`)}
              component={InputMoney}
              onInputBlur={(value) => this.handleBlurAmount(value)}
            />
            <Field
              id="UACamountWithoutTax"
              name="UACamountWithoutTax"
              colSm={6}
              controlLabel={t(`${tKey}AMOUNT_WITHOUT_TAX`)}
              placeholder={t(`${tKey}AMOUNT_WITHOUT_TAX`)}
              component={InputMoney}
              disabled={true}
            />
          </Row>
          <Row>
            <Field
              id="UACivaType"
              name="UACivaType"
              valueKey="id"
              labelKey="value"
              colSm={6}
              controlLabel={t(`${tKey}SELECTED_TAX`)}
              placeholder={t(`${tKey}SELECTED_TAX`)}
              options={ivaTypeCombo}
              component={InputSelect}
              disabled={true}
              value={this.props.valueCombo}
            />
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Col sm={6} className="btn-wrapper">
            <Button id="btn_save" className="btn-standard" className="btn-standard" onClick={() => { this.saveWithoutTax(); closeModal() }} >{t(`${tKey}SAVE_BTN`)}</Button>
          </Col>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default (UACAppraisalWithTAXModal)
