import React, { PureComponent } from 'react'
import { Field } from 'redux-form'
import { Panel, Row, Button } from 'react-bootstrap'
import InputSelect from '../../commons/form/InputSelect'
import InputText from '../../commons/form/InputText'
import InputNumber from '../../commons/form/InputNumber'
import InputCheckBox from '../../commons/form/InputCheckBox'
import InputDatePicker from '../../commons/form/InputDatePicker'
import InputPercentage from '../../commons/form/InputPercentage'
import { documentEntityType as documentEntityTypeConstants } from '../../../constants/dossier/common/documentEntityType'
import {
  paymentSignalStatus as paymentSignalStatusConstants, documentTypeUse as documentTypeUseConstants,
  permissions, homologationClassification, clientInformation
} from '../../../constants/backendIds'
import { buttonsHeader } from '../../../constants/dossier/common/buttonsHeader'
import DocumentaryCheckListPopover from './salesComponent/DocumentaryCheckListPopover'
import { validateDate, validateLicensePlate as validateLicensePlateFunciton, isValidFleetCode } from '../../../util/validationFunctions'
import { getFieldsPermisions } from '../../../util/permisionFunctions'
import { compareObjects } from '../../../util/utils'
import { dossierSubTypeId } from '../../../constants/dossier/common/dossierSubType'
import settings from '../../../setting'
import { dossierTypes as dossierTypesConstant } from '../../../constants/dossier/common/dossierTypes'
import CustomFields from '../../commons/customFields/CustomFields'

class SalesComponent extends PureComponent {
  constructor () {
    super()
    this.state = {
      operationTypeIdAndAdditionalInfoDisabled: true,
      fieldsConfiguration: {
        salesmanId: null,
        salesmanIdLock: null,
        salesmanIdManager: null,
        saleTypeId: null,
        operationTypeIds: null,
        expectedDeliveryDate: null,
        orderDate: null,
        paymentMethodId: null,
        proposalPercDiscount: null,
        orderPercDiscount: null,
        signalPayNotRequired: null,
        additionalInfoIds: null,
        rentalOrderNumber: null,
        deliveryDestination: null,
        arriveVehicle: null,
        dossierWithDeposit: null,
        despositDeliveryDate: null,
        despositEntityId: null,
        depositLicensePlate: null,
        depositKm: null,
        vehicleTypeId: null,
        productionWeek: null,
        monthsGuaranteeExtension: null,
        LOPDNotRequired: null,
        homologationRequired: null,
        homologationClassificationId: null,
        homologationProducerId: null,
        notMultipleInvoices: null,
        authSaleDepartamentNotRequired: null,
        serviceDestinationId: null,
        assignmentSheet: null,
        warrantyExtensionNotRequired: null,
        reportableLLD: null,
        warrantyExtensionRequired: null,
        transformation: null,
        accesories: null,
        firstYearInsurance: null,
        multipleChoice: null,
        billingDate: null,
        billingNumber: null,
        campaignDocumentNotRequired: null,
        maintenanceInclude: null,
        fleetCode: null,
        pickPreviousVehicle: null,
        invoiceNumber: null,
        invoiceDate: null,
        brandDischargeDate: null,
        printSummarySale: null,
        doubleTransferCheck: null,
        orderExternalId: null,
        satPaymentMethod: null,
        currency: null,
        operationTypeDMSIds: null,
        notLoJack: null,
        printOrder: null,
        fullSumary: null,
        purchaseState: null,
        customerNeedDate: null,
        shippingOrderDate: null,
        campaignOutputDate: null,
        destinationArrivalDate: null,
        checkAutoWarranty: null
      },
      depositVehicleDisabled: false,
      homologationRequired: false,
      homologationClassificationId: null,
      paymentSignalIdRefund: null,
      open: false
    }
    this.dateValidation = this.dateValidation.bind(this)
    this.validateLicensePlate = this.validateLicensePlate.bind(this)
    this.validateSalesman = this.validateSalesman.bind(this)
    this.validateSatPaymentMethodId = this.validateSatPaymentMethodId.bind(this)
    this.handleChangeCurrency = this.handleChangeCurrency.bind(this)
  }

  initializePermisions (props) {
    const fieldKeys = Object.keys(this.state.fieldsConfiguration)
    const newState = getFieldsPermisions(props.componentConfiguration.fieldsConfiguration, fieldKeys, props.componentConfiguration.permission)
    this.setState({ fieldsConfiguration: newState.fieldsConfiguration })
  }

  handleSaleTypeChange (saleTypeId) {
    this.props.actions.change('salesComponent.operationTypeIds', [])
    if (saleTypeId) {
      this.props.actions.fetchOperationType(saleTypeId, this.props.dossier.organizedUnitId, false, null)
      this.setState({ operationTypeIdAndAdditionalInfoDisabled: false })
    } else {
      this.setState({ open: false })
      setTimeout(() => { this.setState({ operationTypeIdAndAdditionalInfoDisabled: true }) }, 100)
    }

    if (settings.clientFieldValidation === clientInformation.VGR) {
      this.props.actions.fetchFleetOperationTypeForAutoselect(this.props.dossier.organizedUnitId)
    }
  }

  componentDidMount () {
    this.initializePermisions(this.props)
    this.setState({ operationTypeIdAndAdditionalInfoDisabled: !this.props.dossier.salesComponent.saleTypeId, depositVehicleDisabled: this.props.dossier.salesComponent.dossierWithDeposit, homologationRequired: this.props.dossier.salesComponent.homologationRequired, homologationClassificationId: this.props.dossier.salesComponent.homologationClassificationId })
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (this.props.dossier && this.props.dossier.dossierId && !compareObjects(prevProps.dossier.salesComponent || {}, this.props.dossier.salesComponent, true)) {
      this.setState({ operationTypeIdAndAdditionalInfoDisabled: !this.props.dossier.salesComponent.saleTypeId })
    }

    if (this.props.dossier && this.props.dossier.dossierId && !compareObjects(prevProps.componentConfiguration || {}, this.props.componentConfiguration, true)) {
      this.initializePermisions(this.props)
    }
    return prevProps
  }

  componentDidUpdate () {
    // don't remove use for getsnapshot
  }

  dateValidation (value, allValues, props) {
    const result = validateDate(value, props)
    return result === Object(result) ? undefined : result
  }

  validateSalesman (value, allValues, props) {
    return (value || !settings.validateSalesManager) ? undefined : this.props.t('DOSSIER_COMPONENTS.VALIDATIONS.SALESMAN_REQUIRED')
  }

  validateLicensePlate (value) {
    return validateLicensePlateFunciton(value, this.props.formValues && this.props.formValues.validateLicensePlateCountry || false, this.props.t('GLOBAL.FORMS.INVALID_STRING'))
  }

  validateSatPaymentMethodId (value) {
    return (!value || value === 0) &&
      (this.props && (!this.props.readOnlyFields && this.state.fieldsConfiguration.satPaymentMethod.permission === permissions.editable))
      ? this.props.t('DOSSIER_COMPONENTS.VALIDATIONS.MANDATORY_FIELD') : undefined
  }

  validateFleetCode (value, allValues, props) {
    const result = isValidFleetCode(value, props)
    return result === Object(result) ? undefined : result
  }

  getChargeIdPayment (charges) {
    let chargeId = 0
    charges && charges.map((charge, idx) => {
      if (charge.documentTypeUseId === documentTypeUseConstants.down_payment) {
        if (chargeId === 0) { chargeId = charge.chargeId }
      }
    })
    return chargeId
  }

  menuOpenClose (value) {
    this.setState({ open: value })
  }

  handleChangeCurrency (value) {
    this.props.actions.checkDocTypeBefChangeCurrency(this.props.dossier.dossierId, value, this.props.pristine)
  }

  render () {
    // TODO paymentSignalStatus logic (callback for change status)
    const {
      t, combos, dossierType, readOnlyFields, salesOpen, componentConfiguration, fleetSelected, printSummarySalePermission, disableExternalOrderId, customFields,
      dossier: { salesComponent: { paymentSignalStatus, signalPayNotRequired, deliveryDestination, salesmanId }, salesDocumentaryCheckList, dossierId, organizedUnitId },
      actions: { printDocumentaryChecklist, openDocumentEditionModal, postPaymentSignalStatusSuccess, setActiveTab, togglePanel, printSummarySales, openCDAModal }
    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.SALES.'
    return (
      <div className="sales-panel dossier-panel">
        {dossierType !== dossierTypesConstant.fleetFinder && (
          <div className="header-panel" onClick={togglePanel}>
            <div className="name-wrapper">
              <i className="ico-sales" />
              <h4>{t(`${tKey}SALES`)}</h4>
            </div>
            <i className={salesOpen ? 'ico-arrow-black-up' : 'ico-arrow-black-down'} />
          </div>
        )}
        <Panel expanded={salesOpen} onToggle={() => {}} >
          <Panel.Collapse>
            <Row>
              {this.state.fieldsConfiguration.doubleTransferCheck &&
                this.state.fieldsConfiguration.doubleTransferCheck.permission !== permissions.hidden &&
                (<Field
                  colSm={4}
                  id="doubleTransferCheck"
                  name="salesComponent.doubleTransferCheck"
                  controlLabel={t(`${tKey}DOUBLE_TRANSFER`)}
                  placeholder={t(`${tKey}DOUBLE_TRANSFER`)}
                  valueKey="id"
                  labelKey="value"
                  component={InputCheckBox}
                  disabled={readOnlyFields || this.state.fieldsConfiguration.doubleTransferCheck.permission !== permissions.editable}
                />)}
            </Row>
            <Row>
              {this.state.fieldsConfiguration.salesmanId && this.state.fieldsConfiguration.salesmanId.permission !== permissions.hidden && (<Field
                colSm={4}
                id="salesmanId"
                name="salesComponent.salesmanId"
                controlLabel={t(`${tKey}SALESMAN`)}
                placeholder={t(`${tKey}SALESMAN`)}
                valueKey="id"
                labelKey="value"
                component={InputSelect}
                options={combos.salesmanComboLogic}
                disabled={readOnlyFields || this.state.fieldsConfiguration.salesmanId.permission !== permissions.editable}
              />)}
              {this.state.fieldsConfiguration.salesmanIdLock && this.state.fieldsConfiguration.salesmanIdLock.permission !== permissions.hidden && (<Field
                colSm={4}
                id="salesmanId"
                name="salesComponent.salesmanId"
                controlLabel={t(`${tKey}SALESMAN`)}
                placeholder={t(`${tKey}SALESMAN`)}
                valueKey="id"
                labelKey="value"
                component={InputSelect}
                options={combos.salesmanCombo}
                disabled={readOnlyFields || this.state.fieldsConfiguration.salesmanIdLock.permission !== permissions.editable || salesmanId}
              />)}
              {this.state.fieldsConfiguration.salesmanIdManager && this.state.fieldsConfiguration.salesmanIdManager.permission !== permissions.hidden && (<Field
                colSm={4}
                id="salesmanIdManager"
                name="salesComponent.salesmanIdManager"
                controlLabel={t(`${tKey}SALESMAN_MANAGER`)}
                placeholder={t(`${tKey}SALESMAN_MANAGER`)}
                valueKey="id"
                labelKey="value"
                component={InputSelect}
                validate={this.state.fieldsConfiguration.salesmanIdLock && this.state.fieldsConfiguration.salesmanIdLock.permission !== permissions.hidden ? [this.validateSalesman] : undefined}
                options={combos.salesmanComboLogic}
                disabled={readOnlyFields || this.state.fieldsConfiguration.salesmanIdManager.permission !== permissions.editable}
              />)}
              {this.state.fieldsConfiguration.saleTypeId && this.state.fieldsConfiguration.saleTypeId.permission !== permissions.hidden && (<Field
                colSm={4}
                id="saleTypeId"
                name="salesComponent.saleTypeId"
                controlLabel={t(`${tKey}SALES_TYPE`)}
                placeholder={t(`${tKey}SALES_TYPE`)}
                valueKey="id"
                labelKey="value"
                component={InputSelect}
                menuContainerStyle={{ zIndex: 999 }}
                options={combos.saleTypeCombo}
                onInputChange={(value) => this.handleSaleTypeChange(value)}
                disabled={readOnlyFields || this.state.fieldsConfiguration.saleTypeId.permission !== permissions.editable || fleetSelected}
              />)}
              {this.state.fieldsConfiguration.operationTypeIds && this.state.fieldsConfiguration.operationTypeIds.permission !== permissions.hidden && (<Field
                colSm={4}
                id="operationTypeIds"
                name="salesComponent.operationTypeIds"
                controlLabel={t(`${tKey}OPERATION_TYPE`)}
                placeholder={t(`${tKey}OPERATION_TYPE`)}
                valueKey="id"
                labelKey="value"
                multi
                component={InputSelect}
                menuContainerStyle={{ zIndex: 999 }}
                options={combos.operationTypeCombo}
                disabled={readOnlyFields || this.state.operationTypeIdAndAdditionalInfoDisabled || this.state.fieldsConfiguration.operationTypeIds.permission !== permissions.editable || fleetSelected}
                menuIsOpen= {this.state.open}
                onMenuOpen = {(value) => this.menuOpenClose(value)}
                onMenuClose = {(value) => this.menuOpenClose(value)}
                blurInputOnSelect= {false}
              />)}
              {this.state.fieldsConfiguration.operationTypeDMSIds && this.state.fieldsConfiguration.operationTypeDMSIds.permission !== permissions.hidden && (<Field
                colSm={4}
                id="operationTypeDMSId"
                name="salesComponent.operationTypeDMSId"
                controlLabel={t(`${tKey}OPERATION_TYPE_DMS`)}
                placeholder={t(`${tKey}OPERATION_TYPE_DMS`)}
                valueKey="id"
                labelKey="description"
                component={InputSelect}
                menuContainerStyle={{ zIndex: 999 }}
                options={combos.operationTypeDMSCombo}
                disabled={readOnlyFields || this.state.fieldsConfiguration.operationTypeDMSIds.permission !== permissions.editable || fleetSelected}
                blurInputOnSelect= {false}
              />)}
              {this.state.fieldsConfiguration.additionalInfoIds && this.state.fieldsConfiguration.additionalInfoIds.permission !== permissions.hidden && (<Field
                colSm={4}
                id="additionalInfoIds"
                name="salesComponent.additionalInfoIds"
                controlLabel={t(`${tKey}ADDITIONAL_INFO`)}
                placeholder={t(`${tKey}ADDITIONAL_INFO`)}
                valueKey="id"
                labelKey="value"
                multi
                menuContainerStyle={{ zIndex: 999 }}
                component={InputSelect}
                options={combos.additionalInfoCombo}
                disabled={readOnlyFields || this.state.fieldsConfiguration.additionalInfoIds.permission !== permissions.editable}
              />)}
              {this.state.fieldsConfiguration.expectedDeliveryDate && this.state.fieldsConfiguration.expectedDeliveryDate.permission !== permissions.hidden && (<Field
                colSm={4}
                id="expectedDeliveryDate"
                name="salesComponent.expectedDeliveryDate"
                controlLabel={t(`${tKey}EXPECTED_DELIVERY_DATE`)}
                placeholder={t(`${tKey}EXPECTED_DELIVERY_DATE`)}
                component={InputDatePicker}
                disabled={readOnlyFields || this.state.fieldsConfiguration.expectedDeliveryDate.permission !== permissions.editable}
              />)}
              {this.state.fieldsConfiguration.orderDate && this.state.fieldsConfiguration.orderDate.permission !== permissions.hidden && (<Field
                colSm={4}
                id="orderDate"
                name="salesComponent.orderDate"
                controlLabel={t(`${tKey}ORDER_DATE`)}
                component={InputDatePicker}
                disabled={readOnlyFields || this.state.fieldsConfiguration.orderDate.permission !== permissions.editable}
                validate={[this.dateValidation]}
              />)}
              {this.state.fieldsConfiguration.paymentMethodId && this.state.fieldsConfiguration.paymentMethodId.permission !== permissions.hidden && (<Field
                colSm={4}
                id="paymentMethodId"
                name="salesComponent.paymentMethodId"
                controlLabel={t(`${tKey}PAYMENT_FORM`)}
                placeholder={t(`${tKey}PAYMENT_FORM`)}
                valueKey="id"
                labelKey="value"
                component={InputSelect}
                options={combos.paymentMethodCombo}
                disabled={readOnlyFields || this.state.fieldsConfiguration.paymentMethodId.permission !== permissions.editable || fleetSelected}
              />)}
              {this.state.fieldsConfiguration.rentalOrderNumber && this.state.fieldsConfiguration.rentalOrderNumber && this.state.fieldsConfiguration.rentalOrderNumber.permission !== permissions.hidden && (<Field
                colSm={4}
                id="rentalOrderNumber"
                name="salesComponent.rentalOrderNumber"
                controlLabel={t(`${tKey}RENTALORDER_NUMBER`)}
                placeholder={t(`${tKey}RENTALORDER_NUMBER`)}
                component={InputText}
                disabled={readOnlyFields || this.state.fieldsConfiguration.rentalOrderNumber.permission !== permissions.editable}
              />)}
              {this.state.fieldsConfiguration.vehicleTypeId && this.state.fieldsConfiguration.vehicleTypeId.permission !== permissions.hidden && (<Field
                colSm={4}
                id="vehicleTypeId"
                name="dossierCompleteOtherDataComponent.vehicleTypeId"
                controlLabel={t(`${tKey}VEHICLE_TYPE`)}
                placeholder={t(`${tKey}VEHICLE_TYPE`)}
                valueKey="id"
                labelKey="value"
                component={InputSelect}
                options={combos.vehicleType}
                disabled={readOnlyFields || this.state.fieldsConfiguration.vehicleTypeId.permission !== permissions.editable}
              />)}
              {this.state.fieldsConfiguration.proposalPercDiscount && this.state.fieldsConfiguration.proposalPercDiscount.permission !== permissions.hidden && (<Field
                colSm={4}
                id="proposalPercDiscount"
                name="salesComponent.proposalPercDiscount"
                controlLabel={t(`${tKey}PROPOSAL_PERC_DISCOUNT`)}
                placeholder={t(`${tKey}PROPOSAL_PERC_DISCOUNT`)}
                component={InputPercentage}
                disabled={readOnlyFields || this.state.fieldsConfiguration.proposalPercDiscount.permission !== permissions.editable}
              />)}
              {this.state.fieldsConfiguration.orderPercDiscount && this.state.fieldsConfiguration.orderPercDiscount.permission !== permissions.hidden && (<Field
                colSm={4}
                id="orderPercDiscount"
                name="salesComponent.orderPercDiscount"
                controlLabel={t(`${tKey}ORDER_PERC_DISCOUNT`)}
                placeholder={t(`${tKey}ORDER_PERC_DISCOUNT`)}
                component={InputPercentage}
                disabled={readOnlyFields || this.state.fieldsConfiguration.orderPercDiscount.permission !== permissions.editable}
              />)}
              {this.state.depositVehicleDisabled && this.state.fieldsConfiguration.despositDeliveryDate.permission !== permissions.hidden && (<Field
                colSm={4}
                id="despositDeliveryDate"
                name="salesComponent.despositDeliveryDate"
                controlLabel={t(`${tKey}DELIVERY_DATE_DEPOSIT`)}
                placeholder={t(`${tKey}DELIVERY_DATE_DEPOSIT`)}
                component={InputDatePicker}
                disabled={readOnlyFields || this.state.fieldsConfiguration.despositDeliveryDate.permission !== permissions.editable}
                validate={[this.dateValidation]}
              />)}
              {this.state.depositVehicleDisabled && this.state.fieldsConfiguration.depositLicensePlate.permission !== permissions.hidden && (<Field
                colSm={4}
                id="depositLicensePlate"
                name="salesComponent.depositLicensePlate"
                controlLabel={t(`${tKey}CAR_NUMBER_PLATE_DEPOSIT`)}
                placeholder={t(`${tKey}CAR_NUMBER_PLATE_DEPOSIT`)}
                valueKey="id"
                labelKey="value"
                component={InputText}
                validate={[this.validateLicensePlate]}
                disabled={readOnlyFields || this.state.fieldsConfiguration.depositLicensePlate.permission !== permissions.editable}
              />)}
              {this.state.depositVehicleDisabled && this.state.fieldsConfiguration.depositKm.permission !== permissions.hidden && (<Field
                colSm={4}
                id="depositKm"
                name="salesComponent.depositKm"
                controlLabel={t(`${tKey}KM_DEPOSIT`)}
                placeholder={t(`${tKey}KM_DEPOSIT`)}
                valueKey="id"
                labelKey="value"
                component={InputText}
                disabled={readOnlyFields || this.state.fieldsConfiguration.depositKm.permission !== permissions.editable}
              />)}
              {this.state.fieldsConfiguration.productionWeek && this.state.fieldsConfiguration.productionWeek.permission !== permissions.hidden && this.props.dossier.dossierSubTypeId !== dossierSubTypeId.venta_vo && (<Field
                colSm={4}
                id="productionWeek"
                name="salesComponent.productionWeek"
                controlLabel={t(`${tKey}PRODUCTION_WEEK`)}
                placeholder={t(`${tKey}PRODUCTION_WEEK`)}
                valueKey="id"
                labelKey="value"
                component={InputNumber}
                disabled={readOnlyFields || this.state.fieldsConfiguration.productionWeek.permission !== permissions.editable}
              />)}
              {this.state.fieldsConfiguration.monthsGuaranteeExtension && this.state.fieldsConfiguration.monthsGuaranteeExtension.permission !== permissions.hidden && (<Field
                colSm={4}
                id="monthsGuaranteeExtension"
                name="salesComponent.monthsGuaranteeExtension"
                controlLabel={t(`${tKey}MONTH_GUARANTEE_EXTENSION`)}
                placeholder={t(`${tKey}MONTH_GUARANTEE_EXTENSION`)}
                valueKey="id"
                labelKey="value"
                component={InputNumber}
                disabled={readOnlyFields || this.state.fieldsConfiguration.monthsGuaranteeExtension.permission !== permissions.editable}
              />)}
              {this.state.fieldsConfiguration.homologationClassificationId && this.state.fieldsConfiguration.homologationClassificationId.permission !== permissions.hidden && this.state.homologationRequired && (<Field
                colSm={4}
                id="homologationClassificationId"
                name="salesComponent.homologationClassificationId"
                controlLabel={t(`${tKey}HOMOLOGATION_CLASSIFICATION`)}
                placeholder={t(`${tKey}HOMOLOGATION_CLASSIFICATION`)}
                valueKey="id"
                labelKey="value"
                onChange={(event, value) => this.setState({ homologationClassificationId: value })}
                component={InputSelect}
                options={combos.homologationClassificationCombo}
                disabled={readOnlyFields || this.state.fieldsConfiguration.homologationClassificationId.permission !== permissions.editable}
              />)}
              {this.state.fieldsConfiguration.homologationProducerId && this.state.fieldsConfiguration.homologationProducerId.permission !== permissions.hidden && this.state.homologationClassificationId &&
              this.state.homologationClassificationId === homologationClassification.REFORMA && this.state.homologationRequired && (<Field
                colSm={4}
                id="homologationProducerId"
                name="salesComponent.homologationProducerId"
                controlLabel={t(`${tKey}HOMOLOGATION_PRODUCER`)}
                placeholder={t(`${tKey}HOMOLOGATION_PRODUCER`)}
                valueKey="id"
                labelKey="value"
                component={InputSelect}
                options={combos.homologationProducerCombo}
                disabled={readOnlyFields || this.state.fieldsConfiguration.homologationProducerId.permission !== permissions.editable}
              />)}
              {this.state.fieldsConfiguration.arriveVehicle && this.state.fieldsConfiguration.arriveVehicle.permission !== permissions.hidden && deliveryDestination && (<Field
                colSm={4}
                id="arriveVehicle"
                name="salesComponent.arriveVehicle"
                controlLabel={t(`${tKey}ARRIVE_VEHICLE`)}
                placeholder={t(`${tKey}ARRIVE_VEHICLE`)}
                key="salesComponent.arriveVehicle"
                component={InputText}
                disabled={readOnlyFields || this.state.fieldsConfiguration.arriveVehicle.permission !== permissions.editable}
              />)}
              {this.state.fieldsConfiguration.serviceDestinationId && this.state.fieldsConfiguration.serviceDestinationId.permission !== permissions.hidden &&
              <Field
                colSm={4}
                id="serviceDestinationId"
                name="salesComponent.serviceDestinationId"
                controlLabel={t(`${tKey}SERVICE_DESTINATION`)}
                placeholder={t(`${tKey}SERVICE_DESTINATION`)}
                component={InputSelect}
                options={combos.serviceDestinationCombo}
                valueKey="id"
                labelKey="value"
                disabled={readOnlyFields || this.state.fieldsConfiguration.serviceDestinationId.permission !== permissions.editable}
              />
              }
              {this.state.fieldsConfiguration.billingNumber && this.state.fieldsConfiguration.billingNumber.permission !== permissions.hidden && (<Field
                colSm={4}
                id="billingNumber"
                name="salesComponent.billingNumber"
                controlLabel={t(`${tKey}BILLING_NUMBER`)}
                placeholder={t(`${tKey}BILLING_NUMBER`)}
                component={InputText}
                disabled={readOnlyFields || this.state.fieldsConfiguration.billingNumber.permission !== permissions.editable}
              />)}
              {this.state.fieldsConfiguration.billingDate && this.state.fieldsConfiguration.billingDate.permission !== permissions.hidden && (<Field
                colSm={4}
                id="billingDate"
                name="salesComponent.billingDate"
                controlLabel={t(`${tKey}BILLING_DATE`)}
                placeholder={t(`${tKey}BILLING_DATE`)}
                component={InputDatePicker}
                disabled={readOnlyFields || this.state.fieldsConfiguration.billingDate.permission !== permissions.editable}
              />)}
              {this.state.fieldsConfiguration.satPaymentMethod && this.state.fieldsConfiguration.satPaymentMethod.permission !== permissions.hidden && (<Field
                colSm={4}
                id="satPaymentMethodId"
                name="salesComponent.satPaymentMethodId"
                controlLabel={t(`${tKey}PAYMENT_METHOD`)}
                placeholder={t(`${tKey}PAYMENT_METHOD`)}
                valueKey="id"
                labelKey="value"
                component={InputSelect}
                options={combos.satPaymentMethod}
                disabled={readOnlyFields || this.state.fieldsConfiguration.satPaymentMethod.permission !== permissions.editable}
                validate={[this.validateSatPaymentMethodId]}
              />)}
              {this.state.fieldsConfiguration.fleetCode && this.state.fieldsConfiguration.fleetCode.permission !== permissions.hidden && (<Field
                colSm={4}
                id="fleetCode"
                name="salesComponent.fleetCode"
                controlLabel={t(`${tKey}FLEET_NUMBER`)}
                placeholder={t(`${tKey}FLEET_NUMBER`)}
                component={InputText}
                disabled={readOnlyFields || this.state.fieldsConfiguration.fleetCode.permission !== permissions.editable}
                validate={[this.validateFleetCode]}
              />)}
              {this.state.fieldsConfiguration.invoiceNumber && this.state.fieldsConfiguration.invoiceNumber.permission !== permissions.hidden && (<Field
                colSm={4}
                id="invoiceNumber"
                name="salesComponent.invoiceNumber"
                controlLabel={t(`${tKey}INVOICE_NUMBER`)}
                placeholder={t(`${tKey}INVOICE_NUMBER`)}
                component={InputNumber}
                disabled={readOnlyFields || this.state.fieldsConfiguration.invoiceNumber.permission !== permissions.editable}
              />)}
              {this.state.fieldsConfiguration.invoiceDate && this.state.fieldsConfiguration.invoiceDate.permission !== permissions.hidden && (<Field
                colSm={4}
                id="invoiceDate"
                name="salesComponent.invoiceDate"
                controlLabel={t(`${tKey}INVOICE_DATE`)}
                placeholder={t(`${tKey}INVOICE_DATE`)}
                component={InputDatePicker}
                disabled={readOnlyFields || this.state.fieldsConfiguration.invoiceDate.permission !== permissions.editable}
              />)}
              {this.state.fieldsConfiguration.brandDischargeDate && this.state.fieldsConfiguration.brandDischargeDate.permission !== permissions.hidden && (
                <Field
                  colSm={4}
                  id="brandDischargeDate"
                  name="salesComponent.brandDischargeDate"
                  controlLabel={t(`${tKey}BRAND_DISCHARGE_DATE`)}
                  placeholder={t(`${tKey}BRAND_DISCHARGE_DATE`)}
                  component={InputDatePicker}
                  disabled={readOnlyFields || this.state.fieldsConfiguration.brandDischargeDate.permission !== permissions.editable}
                />)}
              {this.state.fieldsConfiguration.orderExternalId && this.state.fieldsConfiguration.orderExternalId.permission !== permissions.hidden && (<Field
                colSm={4}
                id="orderExternalId"
                name="salesComponent.orderExternalId"
                controlLabel={t(`${tKey}EXTERNAL_ORDER_ID`)}
                placeholder={t(`${tKey}EXTERNAL_ORDER_ID`)}
                valueKey="id"
                labelKey="value"
                component={InputText}
                maxLength={50}
                disabled={readOnlyFields || disableExternalOrderId || this.state.fieldsConfiguration.orderExternalId.permission !== permissions.editable}
              />)}
              {this.state.fieldsConfiguration.currency && this.state.fieldsConfiguration.currency.permission !== permissions.hidden && (<Field
                colSm={4}
                id="currencyId"
                name="salesComponent.currencyId"
                controlLabel={t(`${tKey}CURRENCY_ID`)}
                placeholder={t(`${tKey}CURRENCY_ID`)}
                valueKey="id"
                labelKey="value"
                component={InputSelect}
                options={combos.currencyCombo}
                disabled={readOnlyFields || this.state.fieldsConfiguration.currency.permission !== permissions.editable}
                onInputChange={this.handleChangeCurrency}
              />)}
              {this.state.fieldsConfiguration.purchaseState && this.state.fieldsConfiguration.purchaseState.permission !== permissions.hidden && (<Field
                colSm={4}
                id="purchaseState"
                name="salesComponent.purchaseState"
                controlLabel={t(`${tKey}PURCHASE_STATE`)}
                placeholder={t(`${tKey}PURCHASE_STATE`)}
                valueKey="id"
                labelKey="value"
                component={InputText}
                disabled={readOnlyFields || this.state.fieldsConfiguration.purchaseState.permission !== permissions.editable}
              />)}
              {this.state.fieldsConfiguration.customerNeedDate && this.state.fieldsConfiguration.customerNeedDate.permission !== permissions.hidden && (
                <Field
                  colSm={4}
                  id="customerNeedDate"
                  name="salesComponent.customerNeedDate"
                  controlLabel={t(`${tKey}CUSTOMER_NEED_DATE`)}
                  placeholder={t(`${tKey}CUSTOMER_NEED_DATE`)}
                  component={InputDatePicker}
                  disabled={readOnlyFields || this.state.fieldsConfiguration.customerNeedDate.permission !== permissions.editable}
                />)}
              {this.state.fieldsConfiguration.shippingOrderDate && this.state.fieldsConfiguration.shippingOrderDate.permission !== permissions.hidden && (
                <Field
                  colSm={4}
                  id="shippingOrderDate"
                  name="salesComponent.shippingOrderDate"
                  controlLabel={t(`${tKey}SHIPPING_ORDER_DATE`)}
                  placeholder={t(`${tKey}SHIPPING_ORDER_DATE`)}
                  component={InputDatePicker}
                  disabled={readOnlyFields || this.state.fieldsConfiguration.shippingOrderDate.permission !== permissions.editable}
                />)}
              {this.state.fieldsConfiguration.campaignOutputDate && this.state.fieldsConfiguration.campaignOutputDate.permission !== permissions.hidden && (
                <Field
                  colSm={4}
                  id="campaignOutputDate"
                  name="salesComponent.campaignOutputDate"
                  controlLabel={t(`${tKey}CAMPAIGN_OUTPUT_DATE`)}
                  placeholder={t(`${tKey}CAMPAIGN_OUTPUT_DATE`)}
                  component={InputDatePicker}
                  disabled={readOnlyFields || this.state.fieldsConfiguration.campaignOutputDate.permission !== permissions.editable}
                />)}
              {this.state.fieldsConfiguration.destinationArrivalDate && this.state.fieldsConfiguration.destinationArrivalDate.permission !== permissions.hidden && (
                <Field
                  colSm={4}
                  id="destinationArrivalDate"
                  name="salesComponent.destinationArrivalDate"
                  controlLabel={t(`${tKey}DESTINATION_ARRIVAL_DATE`)}
                  placeholder={t(`${tKey}DESTINATION_ARRIVAL_DATE`)}
                  component={InputDatePicker}
                  disabled={readOnlyFields || this.state.fieldsConfiguration.destinationArrivalDate.permission !== permissions.editable}
                />)}
            </Row>
            <Row>
              {this.state.fieldsConfiguration.pickPreviousVehicle && this.state.fieldsConfiguration.pickPreviousVehicle.permission !== permissions.hidden && (<Field
                colSm={4}
                id="pickPreviousVehicle"
                name="salesComponent.pickPreviousVehicle"
                placeholder={t(`${tKey}PREVIUOS_VEHICLE`)}
                inline
                component={InputCheckBox}
                disabled={readOnlyFields || this.state.fieldsConfiguration.pickPreviousVehicle.permission !== permissions.editable}
              />)}
              {this.state.fieldsConfiguration.signalPayNotRequired && this.state.fieldsConfiguration.signalPayNotRequired.permission !== permissions.hidden && (<Field
                colSm={4}
                id="signalPayNotRequired"
                name="salesComponent.signalPayNotRequired"
                placeholder={t(`${tKey}SIGNAL_PAY_NOT_REQUIRED`)}
                inline
                component={InputCheckBox}
                disabled={readOnlyFields || this.state.fieldsConfiguration.signalPayNotRequired.permission !== permissions.editable}
              />)}
              {this.state.fieldsConfiguration.LOPDNotRequired && this.state.fieldsConfiguration.LOPDNotRequired.permission !== permissions.hidden && (<Field
                colSm={4}
                id="lopdNotRequired"
                name="salesComponent.lopdNotRequired"
                placeholder={t(`${tKey}LOPD_NOT_REQUIRED`)}
                inline
                component={InputCheckBox}
                disabled={readOnlyFields || this.state.fieldsConfiguration.LOPDNotRequired.permission !== permissions.editable}
              />)}
              {this.state.fieldsConfiguration.notMultipleInvoices && this.state.fieldsConfiguration.notMultipleInvoices.permission !== permissions.hidden && (<Field
                colSm={4}
                id="notMultipleInvoices"
                name="salesComponent.notMultipleInvoices"
                placeholder={t(`${tKey}NOT_MULTIPLES_INVOICES`)}
                inline
                component={InputCheckBox}
                disabled={readOnlyFields || this.state.fieldsConfiguration.notMultipleInvoices.permission !== permissions.editable}
              />)}
              {this.state.fieldsConfiguration.assignmentSheet && this.state.fieldsConfiguration.assignmentSheet.permission !== permissions.hidden && (<Field
                colSm={4}
                id="assignmentSheet"
                name="salesComponent.assignmentSheet"
                placeholder={t(`${tKey}NOT_ASSIGMENT_SHEET`)}
                inline
                component={InputCheckBox}
                disabled={readOnlyFields || this.state.fieldsConfiguration.assignmentSheet.permission !== permissions.editable}
              />)}
              {this.state.fieldsConfiguration.warrantyExtensionNotRequired && this.state.fieldsConfiguration.warrantyExtensionNotRequired.permission !== permissions.hidden && (<Field
                colSm={4}
                id="warrantyExtensionNotRequired"
                name="salesComponent.warrantyExtensionNotRequired"
                placeholder={t(`${tKey}NOT_WARRANTY_EXTENSION`)}
                inline
                component={InputCheckBox}
                disabled={readOnlyFields || this.state.fieldsConfiguration.warrantyExtensionNotRequired.permission !== permissions.editable}
              />)}
              {this.state.fieldsConfiguration.deliveryDestination && this.state.fieldsConfiguration.deliveryDestination.permission !== permissions.hidden && (<Field
                colSm={4}
                id="deliveryDestination"
                name="salesComponent.deliveryDestination"
                placeholder={t(`${tKey}DELIVER_DESTINATION`)}
                component={InputCheckBox}
                disabled={readOnlyFields || this.state.fieldsConfiguration.deliveryDestination.permission !== permissions.editable}
              />)}
              {this.state.fieldsConfiguration.dossierWithDeposit && this.state.fieldsConfiguration.dossierWithDeposit.permission !== permissions.hidden && (<Field
                colSm={4}
                id="dossierWithDeposit"
                name="salesComponent.dossierWithDeposit"
                controlLabel={t(`${tKey}VEHICLE_IN_DEPOSIT`)}
                placeholder={t(`${tKey}VEHICLE_IN_DEPOSIT`)}
                value={true}
                inline
                component={InputCheckBox}
                onChange={(event, value) => this.setState({ depositVehicleDisabled: value })}
                disabled={readOnlyFields || this.state.fieldsConfiguration.dossierWithDeposit.permission !== permissions.editable}
              />)}
              {this.state.fieldsConfiguration.homologationRequired && this.state.fieldsConfiguration.homologationRequired.permission !== permissions.hidden && (<Field
                colSm={4}
                id="homologationRequired"
                name="salesComponent.homologationRequired"
                controlLabel={t(`${tKey}HOMOLOGATION_REQUIRED`)}
                placeholder={t(`${tKey}HOMOLOGATION_REQUIRED`)}
                value={true}
                inline
                onChange={(event, value) => this.setState({ homologationRequired: value })}
                component={InputCheckBox}
                disabled={readOnlyFields || this.state.fieldsConfiguration.homologationRequired.permission !== permissions.editable}
              />)}
              {this.state.fieldsConfiguration.authSaleDepartamentNotRequired && this.state.fieldsConfiguration.authSaleDepartamentNotRequired.permission !== permissions.hidden && (<Field
                colSm={4}
                id="authSaleDepartamentNotRequired"
                name="salesComponent.authSaleDepartamentNotRequired"
                controlLabel={t(`${tKey}AUTH_SALE_NOT_REQUIRED`)}
                placeholder={t(`${tKey}AUTH_SALE_NOT_REQUIRED`)}
                inline
                component={InputCheckBox}
                disabled={readOnlyFields || this.state.fieldsConfiguration.authSaleDepartamentNotRequired.permission !== permissions.editable}
              />)}
              {this.state.fieldsConfiguration.transformation && this.state.fieldsConfiguration.transformation.permission !== permissions.hidden && (<Field
                colSm={4}
                id="transformation"
                name="salesComponent.transformation"
                controlLabel={t(`${tKey}TRANSFORMATION`)}
                placeholder={t(`${tKey}TRANSFORMATION`)}
                inline
                component={InputCheckBox}
                disabled={readOnlyFields || this.state.fieldsConfiguration.transformation.permission !== permissions.editable}
              />)}
              {this.state.fieldsConfiguration.accesories && this.state.fieldsConfiguration.accesories.permission !== permissions.hidden && (<Field
                colSm={4}
                id="accesories"
                name="salesComponent.accesories"
                controlLabel={t(`${tKey}ACCESORIES`)}
                placeholder={t(`${tKey}ACCESORIES`)}
                inline
                component={InputCheckBox}
                disabled={readOnlyFields || this.state.fieldsConfiguration.accesories.permission !== permissions.editable}
              />)}
              {this.state.fieldsConfiguration.firstYearInsurance && this.state.fieldsConfiguration.firstYearInsurance.permission !== permissions.hidden && (<Field
                colSm={4}
                id="firstYearInsurance"
                name="salesComponent.firstYearInsurance"
                controlLabel={t(`${tKey}FIRST_YEAR_INSURANCE`)}
                placeholder={t(`${tKey}FIRST_YEAR_INSURANCE`)}
                inline
                component={InputCheckBox}
                disabled={readOnlyFields || this.state.fieldsConfiguration.firstYearInsurance.permission !== permissions.editable}
              />)}
              {this.state.fieldsConfiguration.multipleChoice && this.state.fieldsConfiguration.multipleChoice.permission !== permissions.hidden && (<Field
                colSm={4}
                id="multipleChoice"
                name="salesComponent.multipleChoice"
                controlLabel={t(`${tKey}MULTIPLE_CHOICE`)}
                placeholder={t(`${tKey}MULTIPLE_CHOICE`)}
                inline
                component={InputCheckBox}
                disabled={readOnlyFields || this.state.fieldsConfiguration.multipleChoice.permission !== permissions.editable}
              />)}
              {this.state.fieldsConfiguration.campaignDocumentNotRequired && this.state.fieldsConfiguration.campaignDocumentNotRequired.permission !== permissions.hidden && (<Field
                colSm={4}
                id="campaignDocumentNotRequired"
                name="salesComponent.campaignDocumentNotRequired"
                controlLabel={t(`${tKey}CAMPAIGN_DOCUMENT_NOT_REQUIRED`)}
                placeholder={t(`${tKey}CAMPAIGN_DOCUMENT_NOT_REQUIRED`)}
                inline
                component={InputCheckBox}
                disabled={readOnlyFields || this.state.fieldsConfiguration.campaignDocumentNotRequired.permission !== permissions.editable}
              />)}
              {this.state.fieldsConfiguration.maintenanceInclude && this.state.fieldsConfiguration.maintenanceInclude.permission !== permissions.hidden && (<Field
                colSm={4}
                id="maintenanceInclude"
                name="salesComponent.maintenanceInclude"
                controlLabel={t(`${tKey}MAINTENANCE_INCLUDE`)}
                placeholder={t(`${tKey}MAINTENANCE_INCLUDE`)}
                inline
                component={InputCheckBox}
                disabled={readOnlyFields || this.state.fieldsConfiguration.maintenanceInclude.permission !== permissions.editable}
              />)}
              {this.state.fieldsConfiguration.reportableLLD && this.state.fieldsConfiguration.reportableLLD.permission !== permissions.hidden && (<Field
                colSm={4}
                id="reportableLLD"
                name="salesComponent.reportableLLD"
                placeholder={t(`${tKey}REPORTABLE_LLD`)}
                inline
                component={InputCheckBox}
                disabled={readOnlyFields || this.state.fieldsConfiguration.reportableLLD.permission !== permissions.editable}
              />)}
              {this.state.fieldsConfiguration.notLoJack && this.state.fieldsConfiguration.notLoJack.permission !== permissions.hidden && (<Field
                colSm={4}
                id="notLoJack"
                name="salesComponent.notLoJack"
                placeholder={t(`${tKey}NOT_LOJACK`)}
                inline
                component={InputCheckBox}
                disabled={readOnlyFields || this.state.fieldsConfiguration.notLoJack.permission !== permissions.editable}
              />)}
              {this.state.fieldsConfiguration.checkAutoWarranty && this.state.fieldsConfiguration.checkAutoWarranty.permission !== permissions.hidden && (<Field
                colSm={4}
                id="checkAutoWarranty"
                name="salesComponent.checkAutoWarranty"
                placeholder={t(`${tKey}NOT_AUTOWARRANTY`)}
                inline
                component={InputCheckBox}
                disabled={readOnlyFields || this.state.fieldsConfiguration.checkAutoWarranty.permission !== permissions.editable}
              />)}
            </Row>
            <CustomFields customFields={customFields} readOnlyFields={readOnlyFields} />
            <Row className="salesBtnRow">
              <DocumentaryCheckListPopover t={t} salesDocumentaryCheckList={salesDocumentaryCheckList} printDocumentaryChecklist={printDocumentaryChecklist} componentConfiguration={componentConfiguration} readOnlyFields={readOnlyFields} />
              {!readOnlyFields && !signalPayNotRequired && (
                <>
                  {paymentSignalStatus === paymentSignalStatusConstants.down_payment &&
                    this.state.fieldsConfiguration.signalPayNotRequired && this.state.fieldsConfiguration.signalPayNotRequired.permission !== permissions.hidden
                    ? <>
                      <Button
                        className="order-button btn-editable-table btn-white btn-standard"
                        id="down-payment-button"
                        onClick={() => openDocumentEditionModal(
                          null,
                          documentTypeUseConstants.down_payment,
                          documentEntityTypeConstants.DOSSIER,
                          dossierId,
                          null,
                          null,
                          organizedUnitId,
                          null,
                          () => postPaymentSignalStatusSuccess(),
                          true,
                          dossierType,
                          null,
                          null,
                          null,
                          null,
                          null,
                          this.props.editableDoc,
                          readOnlyFields
                        )}
                        disabled={componentConfiguration.permission !== permissions.editable}
                      >
                        <i className="ico-document-plus" />
                        <span>{t(`${tKey}DOWN_PAYMENT`)}</span>
                      </Button>
                    </>
                    : null
                  }
                </>
              )}
              {!readOnlyFields && !signalPayNotRequired && (
                <>
                  {!readOnlyFields && paymentSignalStatus === paymentSignalStatusConstants.instalment_down_payment &&
                    this.state.fieldsConfiguration.signalPayNotRequired && this.state.fieldsConfiguration.signalPayNotRequired.permission !== permissions.hidden &&
                    this.props.dossier.charges && this.props.dossier.charges.length > 0
                    ? <>
                      <Button
                        className="order-button btn-editable-table btn-white btn-standard"
                        id="cancel-down-button"
                        onClick={() => openDocumentEditionModal(
                          null,
                          documentTypeUseConstants.instalment_down_payment,
                          documentEntityTypeConstants.DOSSIER,
                          dossierId,
                          null,
                          null,
                          organizedUnitId,
                          null,
                          () => postPaymentSignalStatusSuccess(),
                          true,
                          dossierType,
                          null,
                          null,
                          null,
                          null,
                          null,
                          this.props.editableDoc,
                          readOnlyFields,
                          null,
                          true,
                          this.getChargeIdPayment(this.props.dossier.charges)
                        )}
                        disabled={componentConfiguration.permission !== permissions.editable}
                      >
                        <i className="ico-document-plus" />
                        <span>{t(`${tKey}CANCEL_DOWN_PAYMENT`)}</span>
                      </Button>
                    </>
                    : null
                  }
                </>
              )}
              {!readOnlyFields && !signalPayNotRequired && (
                <>
                  {!readOnlyFields && paymentSignalStatus === paymentSignalStatusConstants.finished_payment
                    ? <Button
                      className="order-button btn-editable-table btn-white btn-standard"
                      id="document-management-button"
                      onClick={() => setActiveTab(buttonsHeader.gestor_documental)}
                      disabled={componentConfiguration.permission !== permissions.editable}
                    >{t(`${tKey}OPEN_DOCUMENT_MANAGEMENT`)}</Button>
                    : null
                  }
                </>
              )}
              {printSummarySalePermission && this.state.fieldsConfiguration.printSummarySale && this.state.fieldsConfiguration.printSummarySale.permission !== permissions.hidden
                ? <Button
                  className="order-button btn-editable-table btn-white btn-standard"
                  id="print-sale-button"
                  onClick={() => printSummarySales(dossierId)}
                >{t(`${tKey}PRINT_SALE`)}</Button>
                : null
              }
              {this.state.fieldsConfiguration.printOrder && this.state.fieldsConfiguration.printOrder.permission !== permissions.hidden && (
                <Button
                  className="order-button btn-editable-table btn-white btn-standard"
                  id="print-sale-button"
                  onClick={() => openCDAModal(dossierId, dossierType, this.props.dossier.organizedUnitId, this.props.dossier.lastAccessDate)}
                >{t(`${tKey}PRINT_ORDER`)}</Button>
              )}
            </Row>
          </Panel.Collapse>
        </Panel>
      </div>
    )
  }
}

export default SalesComponent
