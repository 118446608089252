import { put, call, takeEvery, select } from 'redux-saga/effects'
import { reset, change, initialize } from 'redux-form'
import { yesNoModal } from '../../modalsListeners/yesNoModal'
import dossierActionsTypes from '../../../constants/actions/dossier/dossier'
import { dossierTypes as dossierTypesConstant } from '../../../constants/dossier/common/dossierTypes'
import messageModalActions from '../../../actions/modals/messageModal'
import { updatePurchasePresentSuccess } from '../../../actions/dossier/purchase'
import { hideLoader } from '../../../actions/common'
import { handleError } from '../../errors/errorManager'
import { initializeDossier } from '../commonDossierSagas'
import { getAuth } from '../../../selectors/access/auth'
// import { referenceTypes } from '../../../constants/document/documentTypeUse'
// import getDocumentByIdService from '../../../services/document/getDocument'
// import { documentStatus } from '../../../constants/dossier/common/documentStatus'
import getPurchasePresentEconomicItem from '../../../services/purchasePresent/getPurchasePresentEconomicItem'

export function * createPurchasePresent ({ newPresent, values, resolve, reject }) {
  try {
    let purchaseDossierState = yield select(state => state.form.purchase_dossier.values.dossierPurchaseEconomicPlanComponent)
    let newEconomic = JSON.parse(JSON.stringify(purchaseDossierState))
    const purchaseDossier = yield select(state => state.purchaseDossier)
    const purchasePresentTypeCombo = yield select(state => state.combos.purchasePresentTypeCombo)

    newPresent.dossierId = purchaseDossier.dossierId
    newPresent.amount = parseFloat(newPresent.amount)
    newPresent.cost = parseFloat(newPresent.cost)
    newPresent.name = purchasePresentTypeCombo.find(d => d.id === newPresent.typeId).value
    newPresent.isDeleted = false
    newEconomic.updated = true
    values.updated = true

    newEconomic.purchasePresents.push(newPresent)
    yield put(updatePurchasePresentSuccess(newEconomic.purchasePresents))
    yield put(change('purchase_dossier', 'dossierPurchaseEconomicPlanComponent', newEconomic))
    yield put(messageModalActions.openSuccessMessageModal('MESSAGES.POST_TABLE_ITEM_SUCCESS'))
    yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          const purchaseDossierState = yield select(state => state.purchaseDossier)
          yield call(initializeDossier, dossierTypesConstant.purchase, purchaseDossierState)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('MESSAGES.POST_TABLE_ITEM_ERROR', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
      yield call(reject, customHandlerError)
    }
    const sessionHandler = function * () {
      yield put(reset('purchase_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchCreatePurchasePresent () {
  yield takeEvery(dossierActionsTypes.CREATE_PURCHASE_PRESENT, createPurchasePresent)
}

export function * deletePurchasePresent ({ present, values, isSoftDelete, rowIndex }) {
  try {
    let purchaseDossierState = yield select(state => state.form.purchase_dossier.values.dossierPurchaseEconomicPlanComponent)
    let newEconomic = JSON.parse(JSON.stringify(purchaseDossierState))
    if (isSoftDelete) {
      newEconomic.purchasePresents[rowIndex].isDeleted = true
    } else {
      newEconomic.purchasePresents.splice(newEconomic.purchasePresents.findIndex(d => d.id === present.id), 1)
    }
    newEconomic.updated = true
    values.updated = true

    yield put(updatePurchasePresentSuccess(newEconomic.purchasePresents))
    yield put(change('purchase_dossier', 'dossierPurchaseEconomicPlanComponent', newEconomic))
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          const purchaseDossierState = yield select(state => state.purchaseDossier)
          yield call(initializeDossier, dossierTypesConstant.purchase, purchaseDossierState)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('purchase_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDeletePurchasePresent () {
  yield takeEvery(dossierActionsTypes.DELETE_PURCHASE_PRESENT, deletePurchasePresent)
}

export function * updatePurchasePresent ({ present, values, resolve, reject }) {
  try {
    let purchaseDossierState = yield select(state => state.form.purchase_dossier.values.dossierPurchaseEconomicPlanComponent)
    let newEconomic = JSON.parse(JSON.stringify(purchaseDossierState))
    present.amount = parseFloat(present.amount)
    present.cost = parseFloat(present.cost)
    newEconomic.purchasePresents[newEconomic.purchasePresents.findIndex(d => d.id === present.id)] = present
    values.updated = true
    newEconomic.updated = true
    yield put(updatePurchasePresentSuccess(newEconomic.purchasePresents))
    yield put(change('purchase_dossier', 'dossierPurchaseEconomicPlanComponent', newEconomic))
    yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          const purchaseDossierState = yield select(state => state.purchaseDossier)
          yield call(initializeDossier, dossierTypesConstant.purchase, purchaseDossierState)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
      yield call(reject, customHandlerError)
    }
    const sessionHandler = function * () {
      yield put(reset('purchase_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchUpdatePurchasePresent () {
  yield takeEvery(dossierActionsTypes.UPDATE_PURCHASE_PRESENT, updatePurchasePresent)
}

export function * recoverPurchasePresent ({ present, values, rowIndex }) {
  try {
    let purchaseDossierState = yield select(state => state.form.purchase_dossier.values.dossierPurchaseEconomicPlanComponent)
    let newEconomic = JSON.parse(JSON.stringify(purchaseDossierState))
    newEconomic.purchasePresents[rowIndex].isDeleted = false
    newEconomic.updated = true
    values.updated = true

    yield put(updatePurchasePresentSuccess(newEconomic.purchasePresents))
    yield put(change('purchase_dossier', 'dossierPurchaseEconomicPlanComponent', newEconomic))
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          const purchaseDossierState = yield select(state => state.purchaseDossier)
          yield call(initializeDossier, dossierTypesConstant.purchase, purchaseDossierState)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('purchase_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchRecoverPurchasePresent () {
  yield takeEvery(dossierActionsTypes.RECOVER_PURCHASE_PRESENT, recoverPurchasePresent)
}

export function * refreshPurchasePresentFromDocumentModal ({ purchasePresentId, pristine }) {
  try {
    const auth = yield select(getAuth)
    let dossierPurchaseEconomicPlanComponentState = yield select(state => state.form.purchase_dossier.values.dossierPurchaseEconomicPlanComponent)
    let dossierPurchaseEconomicPlanComponent = JSON.parse(JSON.stringify(dossierPurchaseEconomicPlanComponentState))
    const rowIndex = dossierPurchaseEconomicPlanComponent?.purchasePresents?.findIndex(d => d.id === purchasePresentId)

    if (purchasePresentId && rowIndex > -1) {
      let purchasePresentState = dossierPurchaseEconomicPlanComponent.purchasePresents[rowIndex]
      const purchasePresent = yield call(getPurchasePresentEconomicItem, auth.token, purchasePresentId)

      purchasePresentState.orderDocumentId = purchasePresent.orderDocumentId
      purchasePresentState.orderDocumentStatus = purchasePresent.orderDocumentStatus

      purchasePresentState.invoiceDocumentId = purchasePresent.invoiceDocumentId
      purchasePresentState.invoiceDocumentStatus = purchasePresent.invoiceDocumentStatus
      const purchaseState = yield select(state => state.purchaseDossier)

      yield put(change('purchase_dossier', 'dossierPurchaseEconomicPlanComponent.purchasePresents', dossierPurchaseEconomicPlanComponent.purchasePresents))
      if (pristine) {
        yield put(initialize('purchase_dossier', { ...purchaseState, dossierPurchaseEconomicPlanComponent: dossierPurchaseEconomicPlanComponent }))
      }
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('purchase_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  }
}

export function * watchRefreshPurchasePresentFromDocumentModal () {
  yield takeEvery(dossierActionsTypes.REFRESH_PURCHASE_PRESENT_FROM_DOCUMENT_MODAL, refreshPurchasePresentFromDocumentModal)
}
