import documentMassiveDownload from '../../../constants/actions/dossier/common/documentMassiveDownload'

export function getDocumentsMassiveDownload (dossierId, isHistorical, resolve, reject) {
  return {
    type: documentMassiveDownload.GET_ALL_AVAILABLE_DOCUMENT_MASSIVE_DOWNLOAD,
    dossierId,
    isHistorical,
    resolve,
    reject
  }
}

export function getDocumentsMassiveDownloadSucess (docList) {
  return {
    type: documentMassiveDownload.GET_ALL_AVAILABLE_DOCUMENT_MASSIVE_DOWNLOAD_SUCESS,
    docList
  }
}

export function downloadDocumentsSelected (dossierId, isHistorical, aliveDocuments, historicalDocuments, resolve, reject) {
  return {
    type: documentMassiveDownload.DOWNLOAD_DOCUMENTS_MASSIVE_DOWNLOAD,
    dossierId,
    isHistorical,
    aliveDocuments,
    historicalDocuments,
    resolve,
    reject
  }
}
