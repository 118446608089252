export default {
  FETCH_RECURRENT_ENTITY_ADMIN: 'FETCH_RECURRENT_ENTITY_ADMIN',
  FETCH_RECURRENT_ENTITY_ADMIN_SUCCESS: 'FETCH_RECURRENT_ENTITY_ADMIN_SUCCESS',
  OPEN_ADD_RECURRENT_ENTITY_MODAL: 'OPEN_ADD_RECURRENT_ENTITY_MODAL',
  OPEN_ADD_RECURRENT_ENTITY_MODAL_SUCCESS: 'OPEN_ADD_RECURRENT_ENTITY_MODAL_SUCCESS',
  OPEN_EDIT_RECURRENT_ENTITY_MODAL: 'OPEN_EDIT_RECURRENT_ENTITY_MODAL',
  OPEN_EDIT_RECURRENT_ENTITY_MODAL_SUCCESS: 'OPEN_EDIT_RECURRENT_ENTITY_MODAL_SUCCESS',
  CLOSE_EDIT_RECURRENT_ENTITY_MODAL: 'CLOSE_EDIT_RECURRENT_ENTITY_MODAL',
  SUBMIT_RECURRENT_ENTITY: 'SUBMIT_RECURRENT_ENTITY',
  DELETE_RECURRENT_ENTITY: 'DELETE_RECURRENT_ENTITY',
  FETCH_DOCUMENT_LIST_ADMIN: 'FETCH_DOCUMENT_LIST_ADMIN',
  FETCH_DOCUMENT_LIST_ADMIN_SUCCESS: 'FETCH_DOCUMENT_LIST_ADMIN_SUCCESS',
  FETCH_ENTITY_SUB_TYPES_LIST: 'FETCH_ENTITY_SUB_TYPES_LIST',
  FETCH_ENTITY_SUB_TYPES_LIST_SUCCESS: 'FETCH_ENTITY_SUB_TYPES_LIST_SUCCESS',
  UPLOAD_REC_ENTITY_MASS_DOCUMENT: 'UPLOAD_REC_ENTITY_MASS_DOCUMENT',
  SET_CHANGE_TAB_FROM_MASTER: 'SET_CHANGE_TAB_FROM_MASTER',
  SET_CHANGE_TAB_FROM_MASTER_SUCCESS: 'SET_CHANGE_TAB_FROM_MASTER_SUCCESS',
  GET_USER_RECURRENT_SERVICE: 'GET_USER_RECURRENT_SERVICE',
  GET_USER_RECURRENT_SERVICE_SUCCESS: 'GET_USER_RECURRENT_SERVICE_SUCCESS',
  SET_USER_RECURRENT_SERVICE: 'SET_USER_RECURRENT_SERVICE',
  SET_USER_RECURRENT_SERVICE_SUCCESS: 'SET_USER_RECURRENT_SERVICE_SUCCESS',
  DELETE_USER_RECURRENT_SERVICE: 'DELETE_USER_RECURRENT_SERVICE',
  DELETE_USER_RECURRENT_SERVICE_SUCCESS: 'DELETE_USER_RECURRENT_SERVICE_SUCCESS',
  OPEN_CLOSE_USER_RECURRENT_SERVICE_MODAL: 'OPEN_CLOSE_USER_RECURRENT_SERVICE_MODAL',
  OPEN_CLOSE_ADD_USER_RECURRENT_SERVICE_MODAL: 'OPEN_CLOSE_ADD_USER_RECURRENT_SERVICE_MODAL',
  FILL_FILTER_FIELD_ADD_USER_RECURRENT_SERVICE_MODAL: 'FILL_FILTER_FIELD_ADD_USER_RECURRENT_SERVICE_MODAL',
  CLEAR_USER_ADMIN_SEARCH_FILTER_MODAL: 'CLEAR_USER_ADMIN_SEARCH_FILTER_MODAL'
}
