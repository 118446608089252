import React, { PureComponent } from 'react'
import { Button, Modal, Col, OverlayTrigger, Tooltip } from 'react-bootstrap'
import SimpleTablePage from '../../../containers/commons/general/SimpleTablePage'
import { Link } from 'react-router-dom'
import { documentStatusClass, translateDocumentStatus } from '../../../constants/dossier/common/documentStatus'
import { compareValues, formatMoney } from '../../../util/utils'
import { documentTypeUse as documentTypeUseConstants, typeFrontEntities } from '../../../constants/backendIds'

class ChargeDetailsFleetModal extends PureComponent {
  constructor () {
    super()
    this.state = {
      filters: {
        orderBy: '+number'
      }
    }
  }

  openDocument (doc) {
    this.props.actions.openDocumentEditionModalFleet(null, doc.documentId, documentTypeUseConstants.charge,
      typeFrontEntities.DOSSIER, -1, null, null, [doc], this.props.fleet.organizedUnitId,
      null, null, true, null, this.props.fleet.dossierSubType, null, true, null, false, true, this.props.readOnlyFields,
      false, false, null, this.props.fleet.fleetId, doc.fleetChargeId)
  }

  openDetail (fleetCharge) {}

  statusTooltip (status) {
    const statusTooltipTKey = 'DOCUMENT_SEARCH.TABLE.STATUS.'
    return (<Tooltip id="statusTooltip">{status && this.props.t(`${statusTooltipTKey}${translateDocumentStatus[status]}`)}</Tooltip>)
  }

  getIconClass (field) {
    if (this.state.filters.orderBy.includes(field)) {
      return this.state.filters.orderBy.includes('-') ? 'ico-sort-selected-asc' : 'ico-sort-selected-desc'
    } else {
      return 'ico-sort'
    }
  }

  onOrderChange (e) {
    const newOrderBy = this.state.filters.orderBy.includes(`+${e.currentTarget.dataset.field}`) ? `-${e.currentTarget.dataset.field}` : `+${e.currentTarget.dataset.field}`
    this.setState({ filters: { orderBy: newOrderBy } })
    this.order(e.currentTarget.dataset.field, newOrderBy.includes('+') ? 'asc' : 'desc')
  }

  order (field, order) {
    let dossiers = JSON.parse(JSON.stringify(this.props.dossierChargeDetails))
    dossiers = dossiers.sort(compareValues(field, order))
    this.props.actions.openChargeDetailsModalSuccess(this.props.fleetCharge, dossiers)
  }

  render () {
    const {
      t,
      showModal, actions: { closeChargeDetailsModal }, fleetCharge, dossierChargeDetails
    } = this.props

    const viewDocument = (<Tooltip id="viewDocument">{t('DOSSIER_COMPONENTS.CHARGES.VIEW_DOC')}</Tooltip>)

    const tKey = 'MANAGEMENT.FLEETS_TAB.'

    const numberTitle = fleetCharge?.number ? '-' + this.props.fleetCharge.number : ''

    let columnsTable = [
      <th key={0} data-field="number" onClick={(event) => this.onOrderChange(event)}>
        {t(`${tKey}DOSSIER_NUMBER`)}&nbsp;<i className={this.getIconClass('number')}></i>
      </th>,
      <th key={4} data-field="vehicle" onClick={(event) => this.onOrderChange(event)}>
        {t(`${tKey}VEHICLE`)}&nbsp;<i className={this.getIconClass('vehicle')}/>
      </th>,
      <th key={1} data-field="brand" onClick={(event) => this.onOrderChange(event)}>
        {t('DOSSIER_COMPONENTS.VEHICLE.BRAND')}&nbsp;<i className={this.getIconClass('brand')}></i>
      </th>,
      <th key={2} data-field="commercialCode" onClick={(event) => this.onOrderChange(event)}>
        {t('DOSSIER_COMPONENTS.VEHICLE.COMMERCIAL_CODE')}&nbsp;<i className={this.getIconClass('commercialCode')}/>
      </th>,
      <th key={5} data-field="model" onClick={(event) => this.onOrderChange(event)}>
        {t('DOSSIER_COMPONENTS.VEHICLE.MODEL')}&nbsp;<i className={this.getIconClass('model')}></i>
      </th>,
      <th key={3} data-field="color" onClick={(event) => this.onOrderChange(event)}>
        {t('DOSSIER_COMPONENTS.VEHICLE.COLOR')}&nbsp;<i className={this.getIconClass('color')}/>
      </th>
    ]

    if (showModal) {
      return (
        <Modal className="languages-modal chargeDetailFleet-modal" show={showModal} onHide={ () => { closeChargeDetailsModal() }} backdrop={'static'}>
          <Modal.Header closeButton onHide={closeChargeDetailsModal}>
            <Modal.Title>{t(`${tKey}AUX_MODALS.CHARGE_DETAILS_MODAL_TITLE`) + numberTitle} </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-content-row">
              <div className="modal-left-row">

                <span className="title-data padding-right-20">{t(`${tKey}DOSSIERS`)}</span>
                <span className="text-uppercase padding-right-20">{fleetCharge.dossierCount}</span>

                <span className="title-data padding-right-20" >{t(`${tKey}DESCRIPTION`)}</span>
                <span className="text-uppercase padding-right-20">{fleetCharge.description}</span>

              </div>
              <div className="modal-right-row">
                <div>
                  <span className="title-data padding-right-20">{t(`${tKey}AMOUNT`)}</span>
                  <span className="text-uppercase padding-right-20">{formatMoney(fleetCharge.amount, 2)}</span>
                </div>

                <div className="icon-container">
                  <span>
                    <OverlayTrigger placement="right" overlay={this.statusTooltip(fleetCharge.documentStatusId)}>
                      <i id={'charge_documentStatus_detail'} className={'icono-heder-modal ico-document-' + documentStatusClass[translateDocumentStatus[fleetCharge.documentStatusId]] + ' font-size-large'}/>
                    </OverlayTrigger>
                  </span>
                  <OverlayTrigger
                    placement="left"
                    className="documentToolTipFleet"
                    overlay={viewDocument}
                  >
                    <a onClick={() => this.openDocument(fleetCharge)}>
                      <i id={'charge_openDocument_detail_detail'} className="ico-magnifying-plus font-size-large blue  pointer"></i>
                    </a>
                  </OverlayTrigger>
                </div>
              </div>
            </div>
            <div className='discounts-wrapper '>
              <SimpleTablePage
                columns={columnsTable}
                rows={dossierChargeDetails && dossierChargeDetails.map((row, idx) => (
                  <tr if={'dossierChargeDetails_dossier_' + idx} key={idx}>
                    <td>
                      {
                        row.urlToNavigate ? (
                          <Link to={`/dossier${row.urlToNavigate}`}>
                            {row.number}
                          </Link>
                        ) : row.number
                      }
                    </td>
                    <td>{row.vehicle}</td>
                    <td>{row.brand}</td>
                    <td>{row.commercialCode}</td>
                    <td>{row.model}</td>
                    <td>{row.color}</td>
                  </tr>
                ))}
              ></SimpleTablePage>

            </div>

          </Modal.Body>
          <Modal.Footer>
            <Col sm={12} className='fleet-created-moda-btn-exit'>
              <Button
                id='btn-close-fleet-created-select-charge-modal'
                className="btn-standard"
                onClick={() => { closeChargeDetailsModal() }}>
                {t(`${tKey}AUX_MODALS.EXIT`)}
              </Button>
            </Col>
          </Modal.Footer>
        </Modal>
      )
    } else {
      return null
    }
  }
}

export default ChargeDetailsFleetModal
