import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import CampaingModalCountryDetail from '../../../components/masters/campaign/CampaingModalCountryDetail'
import { closeCampaingModalCountryDetailSuccess, fetchCountryCampaignDetailList, deleteCountryCampaignDetail, setCountryCampaignDetail } from '../../../actions/masters/masters'
import { fetchCountries } from '../../../actions/combos/combos'

export function mapStateToProps (state) {
  const campaignModal = state.campaign.campaignModal
  const campaignDetailsCountries = state.campaign.campaignDetailsCountries
  return {
    showModal: campaignModal.showModalCountryDetails,
    campaign: campaignModal.campaign,
    ...state.conditionsMaintenance,
    campaignDetailsCountries,
    countries: state.combos.countries
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      closeCampaingModalCountryDetailSuccess,
      fetchCountryCampaignDetailList,
      deleteCountryCampaignDetail,
      fetchCountries,
      setCountryCampaignDetail
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(CampaingModalCountryDetail))
