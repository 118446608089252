import React, { PureComponent } from 'react'
import { Button, Modal, Col, Row, FormGroup, OverlayTrigger, Tooltip } from 'react-bootstrap'
import TextInput from '../../commons/TextInput'
import { reduxForm, Field } from 'redux-form'
import InputSelect from '../../commons/form/InputSelect'
import roleAdminSectionNames from '../../../constants/components/roleAdminSectionNames'

class RoleModal extends PureComponent {
  constructor () {
    super()
    this.state = {
      editing: false,
      validateError: false,
      selectedSection: null,
      selectedPermission: null,
      ownedPermissions: [],
      permissionsList: [],
      sectionPermissions: [],
      noAssignedSection: null
    }
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (prevProps.showModal !== this.props.showModal && this.props.showModal === true) {
      var noAssignedSection = this.props.sectionPermissions.find(s => s.name === roleAdminSectionNames.NO_ASSIGNED_SECTION)?.sectionId
      this.setState({ ownedPermissions: this.props.ownedPermissions, sectionPermissions: this.props.sectionPermissions, editing: false, noAssignedSection: noAssignedSection })
      const roleLanguages = this.props.languagesCombo.reduce((val, nextVal) => {
        const role = (this.props.roleLanguages.find(role => nextVal.languageId === role.languageId)) || {}
        val[`lang${nextVal.languageId}`] = {
          name: role.name,
          description: role.description,
          languageId: nextVal.languageId
        }
        return val
      }, {})
      this.props.initialize(roleLanguages)
    }
    return null
  }

  componentDidUpdate () {
    // getSnapshotBeforeUpdate() should be used with componentDidUpdate()
  }

  componentDidMount () {
    this.props.actions.fetchLanguagesCombo()
  }

  mountPermissionsList (sectionId) {
    const section = this.props.sectionPermissions.find(val => val.sectionId === sectionId)
    if (section) {
      let permissionList = section.permissions
      const ownedPermissions = this.state.ownedPermissions

      permissionList = permissionList.filter(function (element) {
        return ownedPermissions.find(val => val.permissionId === element.permissionId) === undefined
      })
      this.setState({ permissionsList: permissionList, selectedSection: sectionId })
    }
  }

  assignNewPermission (newPermission) {
    const isAssinged = this.state.ownedPermissions.find(val => val.permissionId === newPermission)
    if (!isAssinged) {
      const permission = this.state.permissionsList.find(val => val.permissionId === newPermission)
      const permissionList = this.state.permissionsList.filter(val => val.permissionId !== newPermission)
      this.setState({ ownedPermissions: this.state.ownedPermissions.concat(permission), permissionsList: permissionList, selectedPermission: null })
      this.props.change('roles.permission', null)
    }
  }

  deletePermission (val) {
    const sections = this.state.sectionPermissions
    const section = sections.find(sec => val.sectionId !== undefined ? sec.sectionId === val.sectionId : this.state.noAssignedSection === sec.sectionId)
    if (!section.permissions.find(p => p.permissionId === val.permissionId)) {
      section.permissions.push(val)
    }
    const ownedPermissions = this.state.ownedPermissions.filter((ownedVal) => ownedVal.permissionId !== val.permissionId)
    this.setState({
      ownedPermissions: ownedPermissions,
      sectionPermissions: sections,
      permissionsList: this.state.selectedSection && section.sectionId === this.state.selectedSection
        ? section.permissions.filter(p => !ownedPermissions.find((ownedVal) => ownedVal.permissionId === p.permissionId))
        : this.state.permissionsList
    })
  }

  submitRole (values) {
    const roleLanguages = []
    Object.keys(values).map(item => {
      if (item === 'roles') return
      roleLanguages.push(values[item])
    })
    const body = { roleLanguages, ownedPermissions: this.state.ownedPermissions }
    this.props.actions.submitRole(body, this.props.roleId)
  }

  render () {
    const {
      t, handleSubmit,
      languagesCombo,
      showModal
    } = this.props
    const tKey = 'ADMIN.'
    const saveTooltip = (<Tooltip id="saveTooltip">{t('ADMIN.USERS.SAVE_BTN')}</Tooltip>)
    const cancelTooltip = (<Tooltip id="cancelTooltip">{t('ADMIN.USERS.CANCEL_BTN')}</Tooltip>)
    const deleteTooltip = (<Tooltip id="deleteTooltip">{t(`${tKey}DELETE`)}</Tooltip>)
    return (
      <Modal className="role-modal languages-modal" show={showModal} onHide={this.props.actions.closeEditRoleModal}>
        <form autoComplete="off" onSubmit={handleSubmit(this.submitRole.bind(this))}>
          <Modal.Header closeButton onHide={this.props.actions.closeEditRoleModal} >
            <Modal.Title>{t('ROLE_MANAGEMENT.NEW_ROL_MODAL.TITLE')}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="title-column">
              <Col sm={4}>
                <h2>{t('MASTERS.NAME')}</h2>
              </Col>
              <Col sm={6}>
                <h2>{t('MASTERS.DESCRIPTION')}</h2>
              </Col>
              <Col sm={2}>
                <h2>{t('MASTERS.LANGUAGE')}</h2>
              </Col>
            </Row>
            <FormGroup>
              {languagesCombo.map((val, idx) => {
                return (
                  <Row key={idx} className="inputs-wrapper">
                    <Col sm={4} className="custom-width-col">
                      <Field
                        name={`lang${val.languageId}.name`}
                        placeholder={t('MASTERS.NAME')}
                        component={TextInput}
                      />
                    </Col>
                    <Col sm={6} className="custom-width-col">
                      <Field
                        name={`lang${val.languageId}.description`}
                        placeholder={t('MASTERS.DESCRIPTION')}
                        component={TextInput}
                      />
                    </Col>
                    <Col sm={2} className="language-column">
                      <p>{`${val.description} (${val.name})`}</p>
                    </Col>
                  </Row>
                )
              })}
            </FormGroup>
            <Row className="selectors">
              {this.state.editing ? (
                <Row>
                  <Col sm={12}>
                    <Field
                      controlLabel={t('ROLE_MANAGEMENT.NEW_ROL_MODAL.AVAILABLE_SECTIONS')}
                      placeholder={t('ROLE_MANAGEMENT.NEW_ROL_MODAL.AVAILABLE_SECTIONS')}
                      colSm={3}
                      name="roles.section"
                      component={InputSelect}
                      options={this.state.sectionPermissions}
                      valueKey="sectionId"
                      labelKey="description"
                      onInputChange={(value) => this.mountPermissionsList(value)}
                    />
                    <Field
                      controlLabel={t('ROLE_MANAGEMENT.NEW_ROL_MODAL.AVAILABLE_PERMISSIONS')}
                      placeholder={t('ROLE_MANAGEMENT.NEW_ROL_MODAL.AVAILABLE_PERMISSIONS')}
                      colSm={3}
                      name="roles.permission"
                      component={InputSelect}
                      options={this.state.permissionsList}
                      valueKey="permissionId"
                      labelKey="description"
                      onInputChange={(value) => this.setState({ selectedPermission: value })}
                      disabled={!(this.state.permissionsList && this.state.permissionsList.length > 0)}
                    />
                    <Col sm={3}>
                      <div className="buttons-wrapper center-vertically">
                        {this.state.selectedPermission &&
                        <OverlayTrigger placement="left" overlay={saveTooltip}>
                          <a onClick={() => this.assignNewPermission(this.state.selectedPermission)}><i className="ico-check"/></a>
                        </OverlayTrigger> }
                        <OverlayTrigger placement="left" overlay={cancelTooltip}>
                          <a onClick={() => this.setState({ editing: false })}><i className="ico-cancel"/></a>
                        </OverlayTrigger>
                      </div>
                    </Col>
                  </Col>
                </Row>
              ) : (
                <Col sm={12}>
                  <Button className="btn-standard" onClick={() => this.setState({ editing: true })}>+ {t('ADMIN.USERS.ADD_BTN')}</Button>
                </Col>
              )}
            </Row>
            <Row>
              <Col sm={12}>
                <Col sm={12}>
                  <h2>{t('USER_MANAGEMENT.MODAL.ASSIGNED_PERMISSIONS')}</h2>
                </Col>
                <div>
                  {this.state.sectionPermissions.map((value, idx) => {
                    return (<ul key={idx}>
                      <li>
                        <label>{value.description}</label>
                        <ul>
                          {this.state.ownedPermissions.filter((val) => (this.state.noAssignedSection && this.state.noAssignedSection === value.sectionId ? val.sectionId === undefined : val.sectionId === value.sectionId)).map((perValue, idx) => {
                            return (<li key={idx}>
                              <label>{perValue.description}</label>
                              <a onClick={() => this.deletePermission(perValue)}>
                                <OverlayTrigger placement="left" overlay={deleteTooltip}>
                                  <i className="ico-trash"/>
                                </OverlayTrigger>
                              </a>
                            </li>)
                          })}
                        </ul>
                      </li>
                    </ul>)
                  })}
                </div>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Col sm={6}>
              <p>{this.state.validateError && t('ROLE_MANAGEMENT.NEW_ROL_MODAL.ERROR_MESSAGE')}</p>
            </Col>
            <Col sm={6} className="btn-wrapper">
              <Button
                id="role-modal-submit-button"
                className="btn-standard"
                type="submit"
                disabled={this.props.pristine && JSON.stringify(this.props.ownedPermissions) === JSON.stringify(this.state.ownedPermissions)}
              >
                {t('ROLE_MANAGEMENT.NEW_ROL_MODAL.SUBMIT_BUTTON')}
              </Button>
            </Col>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

const validate = (values, props) => {
  const error = {}
  props.languagesCombo.map((val) => {
    if (!values[`lang${val.languageId}`] || !values[`lang${val.languageId}`].name) error[`lang${val.languageId}`] = { ...error[`lang${val.languageId}`], name: props.t('ADMIN.VALIDATION.NAME_REQUIRED') }
    if (!values[`lang${val.languageId}`] || !values[`lang${val.languageId}`].description) error[`lang${val.languageId}`] = { ...error[`lang${val.languageId}`], description: props.t('ADMIN.VALIDATION.DESCRIPTION_REQUIRED') }
  })
  return error
}

export default reduxForm({
  form: 'editRole',
  validate
})(RoleModal)
