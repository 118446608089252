import { call, put, takeEvery, select } from 'redux-saga/effects'
import { change, reset } from 'redux-form'
import clientActionTypes from '../../../../constants/actions/dossier/common/client'
import { showLoader, hideLoader } from '../../../../actions/common'
import messageModalActions from '../../../../actions/modals/messageModal'
import { getAuth } from '../../../../selectors/access/auth'
import getDefaultEntity from '../../../../services/entity/getDefaultEntity'
import { handleError } from '../../../errors/errorManager'
import { fetchEntityFilterSubTypeCombo } from '../../../../sagas/combos/fetchEntityFilterSubTypeCombo'

export function * fetchDefaultEntity ({ dossierSubTypeId, ouId, resolve, reject }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const entity = yield call(getDefaultEntity, dossierSubTypeId, ouId, auth.token)
    if (resolve) {
      yield call(resolve, entity)
    }
    yield call(fetchEntityFilterSubTypeCombo, { entityTypeId: entity.entityTypeId, entitySubTypeId: entity.entitySubTypeId, organizedUnitId: ouId })
    yield put(change('serviceAddModal', 'entitySubTypeId', entity.entitySubTypeId))
    yield put(change('serviceAddModal', 'entityId', entity.entityId))
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag !== 'entityNotFound' && customHandlerError.json.tag !== 'TRANS/entityNotFound')) {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      } else if (reject) {
        yield call(reject, customHandlerError)
      }
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('purchase_dossier'))
      yield put(reset('paperwork_dossier'))
      yield put(reset('campaign_dossier'))
      yield put(reset('registrationDeliveryDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchDefaultEntity () {
  yield takeEvery(clientActionTypes.FETCH_DEFAULT_ENTITY, fetchDefaultEntity)
}
