import dashboardTaskActionTypes from '../../constants/actions/dashboard/dashboardTask'

export function initialState () {
  return {
    tasks: {
      totalTasks: 0,
      totalOnTime: 0,
      totalUrgency: 0,
      taskList: []
    },
    urgencyType: null,
    activeTaskId: null,
    activeTaskDescription: '',
    activeTaskValues: {
      columns: [],
      rows: [],
      total: null,
      totalLow: 0,
      totalMedium: 0,
      totalHigh: 0,
      pagesCount: 0,
      lastAccessDate: null
    },
    orderby: null,
    page: 1,
    pageSize: 10,
    urgencyTypeTask: 0

  }
}

function fetchDashboardTaskSuccess (state, { tasks }) {
  return {
    ...state,
    tasks
  }
}

function fetchFilteredDashboardTaskSuccess (state, { urgencyType, activeTaskId, activeTaskDescription, activeTaskValues, orderby, page, pageSize, urgencyTypeTask }) {
  return {
    ...state,
    urgencyType,
    activeTaskId,
    activeTaskDescription,
    activeTaskValues,
    orderby,
    page,
    pageSize,
    urgencyTypeTask
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case dashboardTaskActionTypes.FETCH_DASHBOARD_TASK_SUCCESS:
      return fetchDashboardTaskSuccess(state, action)
    case dashboardTaskActionTypes.FETCH_FILTERED_DASHBOARD_TASK_SUCCESS:
      return fetchFilteredDashboardTaskSuccess(state, action)
    default:
      return state
  }
}
