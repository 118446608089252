import dossierModalActionTypes from '../../../constants/actions/dossier/dossier'
import commentsActionTypes from '../../../constants/actions/dossier/common/comments'
import auditInfoTypes from '../../../constants/actions/audit/auditInfo'

export function initialState () {
  return {
    dossierId: null,
    dossierSubTypeId: null,
    dossierParentId: null,
    parentEntityId: null,
    parentEntityTypeId: null,
    entityFromParentDossier: false,
    organizedUnitId: null,
    showAlertSendPending: false,
    isMarked: false,
    entityChangeable: true,
    header: {},
    checkListHeader: [],
    viewConfiguration: {},
    dossierPurchaseComponent: {},
    entityComponent: {},
    entityProviderComponent: {},
    dossierContactComponent: {},
    vehicleComponent: {},
    dossierKm: null,
    dossierPurchaseEconomicPlanComponent: {},
    dossierCompletePaperworks: [],
    comments: [],
    dossierCompleteDeliveryComponent: { dossierDeliveryComponent: {} },
    dossierDeliveryCheckListPurchase: [],
    dossierRelatedDossiers: [],
    auditInfo: [],
    dossierCompleteOtherDataComponent: {},
    lastAccessDate: null,
    processedCampaignPurchaseOrders: null,
    unreadComments: null,
    reason: null,
    comment: null,
    cancelCombo: [],
    defaultDestination: null,
    customFieldsByComponent: [],
    customFieldsValues: [],
    customFields: {}
  }
}

function fetchPurchaseDossierSuccess (state, { data }) {
  return {
    ...state,
    dossierId: data.dossierId || state.dossierId,
    dossierSubTypeId: data.dossierSubTypeId || state.dossierSubTypeId,
    dossierParentId: data.dossierParentId || state.dossierParentId,
    parentEntityId: data.parentEntityId || state.parentEntityId,
    isMarked: (data.isMarked !== null && data.isMarked !== undefined ? data.isMarked : state.isMarked),
    parentEntityTypeId: data.parentEntityTypeId || state.parentEntityTypeId,
    organizedUnitId: data.organizedUnitId || state.organizedUnitId,
    showAlertSendPending: data.showAlertSendPending || state.showAlertSendPending,
    entityChangeable: data.entityChangeable || state.entityChangeable,
    header: data.dossierHeaderComponent || state.header,
    checkListHeader: data.checkListHeader || state.checkListHeader,
    viewConfiguration: { ...state.viewConfiguration, ...data.viewConfiguration },
    dossierPurchaseComponent: data.dossierPurchaseComponent || state.dossierPurchaseComponent,
    entityComponent: data.entityComponent || state.entityComponent,
    entityProviderComponent: data.entityProviderComponent || state.entityProviderComponent,
    entityFromParentDossier: data.entityFromParentDossier || ((data.entityFromParentDossier === undefined || data.entityFromParentDossier === null) && state.entityFromParentDossier),
    dossierContactComponent: data.dossierContactComponent || state.dossierContactComponent,
    vehicleComponent: data.vehicleComponent || state.vehicleComponent,
    dossierPurchaseEconomicPlanComponent: data.dossierPurchaseEconomicPlanComponent && data.dossierPurchaseEconomicPlanComponent.optionalsAmount ? data.dossierPurchaseEconomicPlanComponent : state.dossierPurchaseEconomicPlanComponent,
    dossierCompleteOtherDataComponent: data.dossierCompleteOtherDataComponent || state.dossierCompleteOtherDataComponent,
    dossierCompletePaperworks: data.dossierCompletePaperworks || state.dossierCompletePaperworks,
    dossierCompleteDeliveryComponent: data.dossierCompleteDeliveryComponent
      ? data.dossierCompleteDeliveryComponent.dossierDeliveryComponent
        ? { dossierDeliveryComponent: data.dossierCompleteDeliveryComponent.dossierDeliveryComponent }
        : { dossierDeliveryComponent: {} }
      : state.dossierCompleteDeliveryComponent,
    dossierDeliveryCheckListPurchase: data.dossierCompleteDeliveryComponent
      ? data.dossierCompleteDeliveryComponent.dossierDeliveryCheckListPurchase
        ? data.dossierCompleteDeliveryComponent.dossierDeliveryCheckListPurchase
        : []
      : state.dossierDeliveryCheckListPurchase,
    comments: data.dossierCompleteComment || state.comments,
    dossierRelatedDossiers: data.dossierRelatedDossiers || state.dossierRelatedDossiers,
    auditInfo: data.auditInfo || state.auditInfo,
    lastAccessDate: data.lastAccessDate || state.lastAccessDate,
    unreadComments: data.unreadComments >= 0 ? data.unreadComments : state.unreadComments,
    reason: data.reason || state.reason,
    comment: data.comment || state.comment,
    cancelCombo: data.cancelCombo || state.cancelCombo,
    customFieldsValues: data.customFields || state.customFieldsValues
  }
}

function fetchCommentsSuccess (state, { comments }) {
  return {
    ...state,
    comments
  }
}

function fetchDossierRelatedDossiersSuccess (state, { dossierRelatedDossiers }) {
  return {
    ...state,
    dossierRelatedDossiers
  }
}

function fetchAuditInfoSucces (state, { auditInfo }) {
  return {
    ...state,
    auditInfo
  }
}

function launchCampaignProcessFilesSucess (state, { data }) {
  return {
    ...state,
    processedCampaignPurchaseOrders: data
  }
}

function fetchPurchaseCatalogueModelSuccess (state, { catalogueModelId, catalogueModel }) {
  return {
    ...state,
    vehicleComponent: { ...state.vehicleComponent, model: catalogueModel, catalogueModelId: catalogueModelId }
  }
}

function fetchPurchaseDefaultDestination (state, { value }) {
  return {
    ...state,
    defaultDestination: value
  }
}

function fetchCustomFieldsSuccess (state, { value }) {
  return {
    ...state,
    customFieldsByComponent: value
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case auditInfoTypes.FETCH_AUDIT_INFO:
      return fetchAuditInfoSucces(state, action)
    case dossierModalActionTypes.FETCH_PURCHASE_DOSSIER_SUCCESS:
      return fetchPurchaseDossierSuccess(state, action)
    case dossierModalActionTypes.FETCH_DOSSIER_RELATED_DOSSIERS_SUCCESS:
      return fetchDossierRelatedDossiersSuccess(state, action)
    case commentsActionTypes.FETCH_PURCHASE_COMMENTS_SUCCESS:
      return fetchCommentsSuccess(state, action)
    case dossierModalActionTypes.RESTART_PURCHASE_STATE:
      return initialState()
    case auditInfoTypes.FETCH_AUDIT_INFO_SUCCESS:
      return fetchAuditInfoSucces(state, action)
    case dossierModalActionTypes.LAUNCH_CAMPAIGN_PROCESS_FILES_SUCCES:
      return launchCampaignProcessFilesSucess(state, action)
    case dossierModalActionTypes.FETCH_VEHICLE_CATALOGUE_MODEL_SUCCESS:
      return fetchPurchaseCatalogueModelSuccess(state, action)
    case dossierModalActionTypes.FETCH_PURCHASE_DEFAULT_DESTINATION:
      return fetchPurchaseDefaultDestination(state, action)
    case dossierModalActionTypes.FETCH_CUSTOM_FIELDS_SUCCESS:
      return fetchCustomFieldsSuccess(state, action)
    default:
      return state
  }
}
