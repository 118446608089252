import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import LegalCompanyMaster from '../../../components/masters/legalCompany/LegalCompanyMaster'
import { fetchLegalCompanyList, deleteLegalCompanyModal, openLegalCompanyModal } from '../../../actions/masters/masters'
export function mapStateToProps (state) {
  return {
    ...state.legalCompany
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      openLegalCompanyModal,
      deleteLegalCompanyModal,
      fetchLegalCompanyList
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(LegalCompanyMaster))
