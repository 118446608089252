import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import ProcessingAgencyIntegrationServiceDestinationsMasterModal from '../../../../components/masters/processing/processingAgencyIntegrations/ProcessingAgencyIntegrationServiceDestinationsMasterModal'
import { showProcessingAgencyServiceDestinationsModal, submitProcessingAgencyServiceDestinations } from '../../../../actions/masters/processingAgency/processingAgencyIntegrations/processingAgencyServiceDestinations'

export function mapStateToProps (state) {
  const serviceDestinationsModal = state.processingAgency?.processingAgencyIntegrationModal?.serviceDestinations?.serviceDestinationsModal
  return {
    agency: state.processingAgency.processingAgencyIntegrationModal.agency,
    showModal: serviceDestinationsModal?.showModal,
    processingAgencyServiceDestination: serviceDestinationsModal?.processingAgencyServiceDestination,
    languageList: serviceDestinationsModal?.languageList,
    combos: {
      serviceDestinations: state.combos.serviceDestinations
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      showProcessingAgencyServiceDestinationsModal,
      submitProcessingAgencyServiceDestinations
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(ProcessingAgencyIntegrationServiceDestinationsMasterModal))
