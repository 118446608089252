import { put, call, select, takeEvery } from 'redux-saga/effects'
import { getAuth } from '../../../selectors/access/auth'
import { showLoader, hideLoader } from '../../../actions/common.js'
import { handleError } from '../../errors/errorManager'
import { yesNoModal } from '../../modalsListeners/yesNoModal'
import deleteUserProcessingAgencyService from '../../../services/processingAgency/deleteUserProcessingAgencyService'
import mastersActionTypes from '../../../constants/actions/masters/masters'
import getUsersProcessingAgencyService from '../../../services/processingAgency/getUsersProcessingAgencyService'
import { getUsersProcessingAgencySuccess, fetchUserListForProcessingAgencySuccess } from '../../../actions/masters/masters'
import postUserListService from '../../../services/user/postUsersList'
import postAssignUserProcessingAgency from '../../../services/processingAgency/postAssignUserProcessingAgency'

export function * deleteUserProcessingAgency ({ userProcessingAgencyId, action }) {
  try {
    const confirmed = yield call(yesNoModal, 'deleteUserProcessingAgency')
    yield put(showLoader())
    if (confirmed) {
      const auth = yield select(getAuth)
      yield call(deleteUserProcessingAgencyService, userProcessingAgencyId, auth.token)
      if (action) yield call(action)
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDeleteUserProcessingAgency () {
  yield takeEvery(mastersActionTypes.DELETE_USER_PROCESSING_AGENCY, deleteUserProcessingAgency)
}

export function * getUsersProcessingAgency ({ processingAgencyId, action }) {
  try {
    yield put(showLoader())

    const auth = yield select(getAuth)
    const data = yield call(getUsersProcessingAgencyService, processingAgencyId, auth.token)
    yield put(getUsersProcessingAgencySuccess(data))

    if (action) yield call(action, data)
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchGetUsersProcessingAgency () {
  yield takeEvery(mastersActionTypes.GET_USERS_PROCESSING_AGENCY, getUsersProcessingAgency)
}

export function * fetchUserListForProcessingAGency ({ filter }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const userList = yield call(postUserListService, auth.token, filter)
    yield put(fetchUserListForProcessingAgencySuccess(userList, filter))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchUserListForProcessingAgency () {
  yield takeEvery(mastersActionTypes.FETCH_USERS_LIST_FOR_PROCESSING_AGENCY, fetchUserListForProcessingAGency)
}

export function * assignUserProcessingAgency ({ userId, processingAgencyId, action }) {
  try {
    yield put(showLoader())

    const auth = yield select(getAuth)
    yield call(postAssignUserProcessingAgency, userId, processingAgencyId, auth.token)
    if (action) yield call(action)
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchAssignUserProcessingAgency () {
  yield takeEvery(mastersActionTypes.ASSING_USER_PROCESSING_AGENCY, assignUserProcessingAgency)
}
