import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import UnattendedProcessesFinder from '../../components/management/UnattendedProcessesFinder'
import { translate } from 'react-polyglot'
import { fetchUnattendedProcessTypeCombo, fetchUnattendedProcessViewCombo } from '../../actions/combos/combos'
import { findUnattendedProcess, exportUnattendedProcess, openUnattendedProcessDetailModal } from '../../actions/unattendedProcess/unattendedProcess'
import { openModal } from '../../actions/common'
import { openCloseUnattendedProcessDownloadFileModal } from '../../actions/management/auxModals/auxModals'

export function mapStateToProps (state) {
  return {
    ...state.unattendedProcess,
    combos: {
      unattendedProcessTypeCombo: state.combos.unattendedProcessTypeCombo,
      unattendedProcessStatusCombo: state.combos.unattendedProcessStatusCombo,
      unattendedProcessViewCombo: state.combos.unattendedProcessViewCombo
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchUnattendedProcessTypeCombo,
      fetchUnattendedProcessViewCombo,
      findUnattendedProcess,
      openModal,
      exportUnattendedProcess,
      openUnattendedProcessDetailModal,
      openCloseUnattendedProcessDownloadFileModal
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(UnattendedProcessesFinder))
