import { call, put, select, takeLatest, takeEvery } from 'redux-saga/effects'
import { getAuth } from '../../../selectors/access/auth'
import { showLoader, hideLoader, openModal } from '../../../actions/common.js'
import { fetchCombosSuccess } from '../../../actions/combos/combos'
import { findFilterDocumentsSuccess, openDocumentalSearchModalSuccess, findFilterMassiveDownloadSuccess } from '../../../actions/search/documentalSearch/documentalSearchForm'
import documentalSearchActionTypes from '../../../constants/actions/search/documentalSearch/documentalSearch'
import getDocumentAllTypes from '../../../services/document/getDocumentAllTypes.js'
import getEntityTypes from '../../../services/entityType/getEntityTypes.js'
import getBrands from '../../../services/brand/getBrands'
import getDocumentFinder from '../../../services/documentFinder/getDocumentFinder.js'
import getLowestOrganizedUnits from '../../../services/organizedUnit/getOrganizedUnits'
import getSalesmanUserHierarchy from '../../../services/salesman/getSalesmanUserHierarchy'
import getDossierSubTypeByOu from '../../../services/dossierType/getDossierSubTypeByOu'
import getDocumentByTypeUse from '../../../services/document/getDocumentByTypeUse'
import getDocumentSheet from '../../../services/documentSheet/getDocumentSheet'
import getDocumentByIdService from '../../../services/document/getDocument'
import { typeFields as typeFieldsConstant } from '../../../constants/dossier/common/typeFields'
import getMetadatoListItems from '../../../services/document/getMetadatoListItems'
import { handleError } from '../../errors/errorManager'
import downloadDocumentsService from '../../../services/document/getDownloadDocuments'
import canCreateMassiveDownload from '../../../services/document/getCanCreateMassiveDownload'
import putCreateMassiveDownload from '../../../services/document/putCreateMassiveDownload'
import getMassiveDownloadFinder from '../../../services/document/getMassiveDownloadFinder'
import getDownloadMassiveFile from '../../../services/document/getDownloadMassiveFile'
import cancelMassiveDownload from '../../../services/document/cancelMassiveDownload'
import { yesNoModal } from '../../modalsListeners/yesNoModal'

function * getMetadato (fields, documentId) {
  const auth = yield select(getAuth)
  for (let i = fields.length - 1; i >= 0; i--) {
    if (fields[i].typeField === typeFieldsConstant.list) {
      fields[i].options = yield call(getMetadatoListItems, auth.token, fields[i].fieldId, documentId)
    }
  }
  return fields
}

export function * fetchDocumentalSearchFormCombos ({ documentEntityType }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const documentsTypesCombo = yield call(getDocumentAllTypes, auth.token, documentEntityType)
    yield put(fetchCombosSuccess({ documentsTypesCombo }))
    const entityTypesCombo = yield call(getEntityTypes, auth.token)
    yield put(fetchCombosSuccess({ entityTypesCombo }))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchDocumentalSearchFormCombos () {
  yield takeLatest(documentalSearchActionTypes.FETCH_DOCUMENTAL_SEARCH_FORM_COMBOS, fetchDocumentalSearchFormCombos)
}

export function * findFilterDocuments ({ filters }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const result = yield call(getDocumentFinder, auth.token, filters)
    yield put(findFilterDocumentsSuccess(result, filters))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFindFilterDocuments () {
  yield takeLatest(documentalSearchActionTypes.FIND_FILTER_DOCUMENTS, findFilterDocuments)
}

export function * initializeSearch () {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const lowestOrganizedUnitsCombo = yield call(getLowestOrganizedUnits, auth.token)
    const entityTypeCombo = yield call(getEntityTypes, auth.token)
    const salesmanCombo = yield call(getSalesmanUserHierarchy, auth.token)
    const brandCombo = yield call(getBrands, auth.token)
    const searchDossierSubtypesCombo = yield call(getDossierSubTypeByOu, auth.token)
    yield put(fetchCombosSuccess({
      lowestOrganizedUnitsCombo,
      entityTypeCombo,
      salesmanCombo,
      brandCombo,
      searchDossierSubtypesCombo
    }))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchInitializeDocumentalSearch () {
  yield takeEvery(documentalSearchActionTypes.INITIALIZE_DOC_SEARCH, initializeSearch)
}

export function * fetchDocumentSearchList ({ filters }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const result = yield call(getDocumentFinder, auth.token, filters)
    yield put(findFilterDocumentsSuccess(result, filters))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchDocumentSearchListSuccess () {
  yield takeEvery(documentalSearchActionTypes.FETCH_DOCUMENT_SEARCH_LIST, fetchDocumentSearchList)
}

export function * openDocumentEditionModal ({ documentId, documentTypeUse, organizedUnitId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const documentTypeUseInfo = yield call(getDocumentByTypeUse, documentTypeUse, false, auth.token)
    const docResult = yield call(getDocumentByIdService, auth.token, documentId)
    docResult.fields = yield call(getMetadato, docResult.fields, documentId)
    if (documentId) {
      docResult.sheets = yield call(getDocumentSheet, auth.token, documentId)
    }
    yield put(openDocumentalSearchModalSuccess(docResult, documentTypeUseInfo))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchOpenDocumentalSearchModal () {
  yield takeEvery(documentalSearchActionTypes.OPEN_DOCUMENT_MODAL, openDocumentEditionModal)
}

export function * downloadDocumentList ({ documentIds }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(downloadDocumentsService, documentIds, [], auth.token)
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDownloadDocumentList () {
  yield takeEvery(documentalSearchActionTypes.DOWNLOAD_DOCUMENT_LIST, downloadDocumentList)
}

export function * massiveDownloadDocumentList ({ documentIds, resolve, reject }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const canCreate = yield call(canCreateMassiveDownload, auth.token)
    if (canCreate.exceededMaxCreated) {
      yield put(openModal('maximumDownloadsCreatedExceeded', null, null, null, null, [{ id: '##MAX##', value: canCreate.maxActiveDownloads }], null))
    } else if (documentIds.length > canCreate.maxDocumentsDownload) {
      yield put(openModal('maximumDownloadDocumentsExceeded', null, null, null, null, [{ id: '##MAX##', value: canCreate.maxDocumentsDownload }], null))
    } else {
      yield put(hideLoader())
      const confirmed = yield call(yesNoModal, 'massiveDownloadConfirmation',
        null, null, null, null, [{ id: '##NUMBER##', value: documentIds.length }], null)
      yield put(showLoader())
      resolve(confirmed)
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchMassiveDownloadDocumentList () {
  yield takeEvery(documentalSearchActionTypes.MASSIVE_DOWNLOAD_DOCUMENT_LIST, massiveDownloadDocumentList)
}

export function * createMassiveDownload ({ documentIds, fileName, observations, resolve }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(putCreateMassiveDownload, auth.token, { documentIds, fileName, observations })
    resolve(true)
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchCreateMassiveDownload () {
  yield takeEvery(documentalSearchActionTypes.CREATE_MASSIVE_DOWNLOAD, createMassiveDownload)
}

export function * findFilterMassiveDownload ({ filters, currentUser }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    if (currentUser) {
      filters.emailUser = auth.emailAddress
    }
    if (filters.massiveDownloadStatesId) {
      filters.massiveDownloadStatesId = filters.massiveDownloadStatesId.toString()
    }
    const result = yield call(getMassiveDownloadFinder, auth.token, filters)
    yield put(findFilterMassiveDownloadSuccess(result, filters))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFindFilterMassiveDownload () {
  yield takeLatest(documentalSearchActionTypes.FIND_FILTER_MASSIVE_DOWNLOAD, findFilterMassiveDownload)
}

export function * downloadMassiveFile ({ massiveDownloadId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(getDownloadMassiveFile, auth.token, massiveDownloadId)
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDownloadMassiveFile () {
  yield takeLatest(documentalSearchActionTypes.DOWNLOAD_MASSIVE_FILE, downloadMassiveFile)
}

export function * cancelMassiveFile ({ massiveDownloadId, resolve }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(cancelMassiveDownload, auth.token, massiveDownloadId)
    resolve(true)
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchCancelMassiveFile () {
  yield takeLatest(documentalSearchActionTypes.CANCEL_MASSIVE_FILE, cancelMassiveFile)
}
