import React, { PureComponent } from 'react'
import { Row, Col, Button, Tabs, Tab, Form, Tooltip } from 'react-bootstrap'
import { reduxForm } from 'redux-form'
import SearchByVehicleForm from '../../containers/search/dossier/SearchByVehicleFormPage'
import SearchByEntityForm from '../../containers/search/dossier/SearchByEntityFormPage'
import SearchByDossierForm from '../../containers/search/dossier/SearchByDossierFormPage'
import SearchByRentingForm from '../../containers/search/dossier/SearchByRentingPage'
import SearchByChargeForm from '../../containers/search/dossier/SearchByChargeFormPage'
import SearchByDossierSimpleForm from '../../containers/search/dossier/SearchByDossierSimpleFormPage'
import SearchBySelfSupplyForm from '../../containers/search/dossier/SearchBySelfSupplyPage'
import SearchByVehicleSimpleForm from '../../containers/search/dossier/SearchByVehicleSimpleFormPage'
import SearchByFleetsForm from '../../containers/search/dossier/SearchByFleetsFormPage'
import SearchByPrepareFormPage from '../../containers/search/dossier/SearchByPrepareFormPage'
import SearchCommercialReportPage from '../../containers/search/dossier/SearchCommercialReportPage'
import { fromCamelToUnderscore, convertStringToDate, differenceDaysDate, formatMoney } from '../../util/utils'
import { validateDates, validateVinChassis, validateMinimalStringLength } from '../../util/validationFunctions'
import { permissions, backEndIdDocumentStatus, dateToApply, clientInformation, dossierSubTypes } from '../../constants/backendIds'
import { getSectionPermissions } from '../../util/permisionFunctions'
import { searchType, searchTabCode } from '../../constants/search/searchConstants'
import settings from '../../setting'
import { dossierSubTypeId } from '../../constants/dossier/common/dossierSubType'
import { formNames } from '../../constants/search/formNames'
import SearchByPapFormPage from '../../containers/search/dossier/SearchByPapFormPage'
import SearchByPlanLogisticsPapFormPage from '../../containers/search/dossier/SearchByPlanLogisticsPapFormPage'
import vehicleCondition from '../../constants/vehicleCondition'
import SearchByProcessingAgencyFormPage from '../../containers/search/dossier/SearchByProcessingAgencyFormPage'
import SearchByFleetReportPage from '../../containers/search/dossier/SearchByFleetReportPage'
import SearchStockManagement from './SearchStockManagement'

class DossierFinder extends PureComponent {
  constructor () {
    super()
    this.state = {
      disableResetButton: true,
      sectionTabsConfiguration: {
        dossier: null,
        entity: null,
        vehicle: null,
        charge: null,
        dossierSimple: null,
        vehicleSimple: null,
        renting: null,
        fleets: null,
        selfSupply: null,
        prepare: null,
        reportCommercial: null,
        papServicesManager: null,
        planLogisticsPap: null,
        processingAgency: null,
        fleetReport: null,
        stockManagementSearch: null
      },
      dossierIdToArray: [],
      stringDossierid: '',
      fields: {},
      selectDossierSubType: true,
      organizedUnitId: null,
      config: [
        { id: searchType.VEHICLE, formValuesName: 'vehicle', fetchAction: (value) => this.props.actions.fetchVehicles({ ...value, ...this.props.filters }), emptyFilterModal: 'mustFillFiltersVehicleDate', customValidation: (values) => { return this.validateLimitDate(values) } },
        { id: searchType.ENTITY, formValuesName: 'entity', fetchAction: (value) => this.props.actions.fetchEntities({ ...value, ...this.props.filters }), emptyFilterModal: 'mustFillEntityType', customValidation: (values) => { return !values.entity || !values.entity.entityTypeId } },
        { id: searchType.DOSSIER, formValuesName: 'dossier', fetchAction: (value) => this.props.actions.fetchDossiers({ ...value, ...this.props.filters }), emptyFilterModal: 'mustFillFilters' },
        { id: searchType.CHARGE, formValuesName: 'charge', fetchAction: (value) => this.props.actions.fetchCharges({ ...value, ...this.props.filters }), emptyFilterModal: 'mustFillFilters' },
        { id: searchType.DOSSIER_SIMPLE, formValuesName: 'dossier', fetchAction: (value) => this.props.actions.fetchDossiersSimple({ ...value, ...this.props.filters }), emptyFilterModal: 'mustFillFiltersDossierSimple', customValidation: (values) => { return values.dossier && !values.dossier.dealerNumber } },
        { id: searchType.VEHICLE_SIMPLE, formValuesName: 'vehicle', fetchAction: (value) => this.props.actions.fetchVehiclesSimple({ ...value, ...this.props.filters }), emptyFilterModal: 'mustFillFilters' },
        { id: searchType.RENTING, formValuesName: 'renting', fetchAction: (value) => this.props.actions.fetchChargeRenting({ ...value, ...this.props.filters }), emptyFilterModal: 'mustFillFilters' },
        { id: searchType.FLEETS, formValuesName: 'dossier', fetchAction: (value) => this.props.actions.fetchFleets({ ...value, ...this.props.filters }), emptyFilterModal: 'mustFillFilters' },
        { id: searchType.SELFSUPPLY, formValuesName: 'selfSupply', fetchAction: (value) => this.props.actions.fetchSelfSupply({ ...value, ...this.props.filters }), emptyFilterModal: 'mustFillFilters' },
        { id: searchType.PREPARE, formValuesName: 'servicePreparationDelivery', fetchAction: (value) => this.props.actions.fetchServicePrepare({ ...value, ...this.props.filters }), emptyFilterModal: 'mustFillFilters' },
        { id: searchType.COMMERCIAL_REPORT, formValuesName: 'reportComercial', fetchAction: (value) => this.props.actions.fetchReportCommercial({ ...value, ...this.props.filters }), emptyFilterModal: 'mustFillFiltersReportCommercial', customValidation: (values) => { return !values.reportComercial || !values.reportComercial.organizedUnits || !values.reportComercial.dateFrom } },
        { id: searchType.PAP_SERVICES_MANAGER, formValuesName: formNames.PAP_SERVICES_MANAGER, fetchAction: (value) => this.props.actions.fetchPapServicesFromManager({ ...this.props.filters, ...value }), emptyFilterModal: 'mustFillFiltersPapFinder', customValidation: (values) => this.customValidationToPapServicesManager(values) },
        { id: searchType.PLAN_LOGISTICS_PAP, formValuesName: formNames.PLAN_LOGISTICS_PAP, fetchAction: (value) => this.props.actions.fetchPlanLogiticsPap({ ...this.props.filters, ...value }), emptyFilterModal: 'mustFillFilters', customValidation: (values) => { return true } },
        { id: searchType.PROCESSING_AGENCY, formValuesName: 'processing_Agency', fetchAction: (value) => this.props.actions.fetchProcessingAgencies({ ...value, ...this.props.filters }), emptyFilterModal: 'mustFillFilters' },
        { id: searchType.FLEET_REPORT, formValuesName: 'fleetReport', fetchAction: (value) => this.props.actions.fetchFleetReport({ ...value, ...this.props.filters }), emptyFilterModal: 'mustFillMinFilters2' },
        { id: searchType.STOCK_MANAGEMENT, formValuesName: formNames.STOCK_MANAGEMENT, fetchAction: (value) => this.props.actions.fetchStockManagementDossiers({ ...value, ...this.props.filters }), emptyFilterModal: 'mustFillFilters', customValidations: (values) => { return values.repairWorkshops?.length <= 0 } }
      ],
      configExport: [
        { id: searchType.VEHICLE, formValuesName: 'vehicle', fetchAction: (value, columns) => this.props.actions.fetchVehiclesDownload({ ...value, ...this.props.filters }, columns), emptyFilterModal: 'mustFillFilters' },
        { id: searchType.ENTITY, formValuesName: 'entity', fetchAction: (value, columns) => this.props.actions.fetchEntitiesDownload({ ...value, ...this.props.filters }, columns), emptyFilterModal: 'mustFillFilters' },
        { id: searchType.DOSSIER, formValuesName: 'dossier', fetchAction: (value, columns) => this.props.actions.fetchDossiersDownload({ ...value, ...this.props.filters }, columns), emptyFilterModal: 'mustFillFilters' },
        { id: searchType.CHARGE, formValuesName: 'charge', fetchAction: (value, columns) => this.props.actions.fetchChargesDownload({ ...value, ...this.props.filters }, columns), emptyFilterModal: 'mustFillFilters' },
        { id: searchType.DOSSIER_SIMPLE, formValuesName: 'dossier', fetchAction: (value, columns) => this.props.actions.fetchDossiersSimpleDownload({ ...value, ...this.props.filters }, columns), emptyFilterModal: 'mustFillFiltersDossierSimple', customValidation: (values) => { return !(values.dossier && values.dossier.dealerNumber) } },
        { id: searchType.VEHICLE_SIMPLE, formValuesName: 'vehicle', fetchAction: (value, columns) => this.props.actions.fetchVehiclesSimpleDownload({ ...value, ...this.props.filters }, columns), emptyFilterModal: 'mustFillFilters' },
        { id: searchType.RENTING, formValuesName: 'renting', fetchAction: (value, columns) => this.props.actions.fetchChargeRentingDownload({ ...value, ...this.props.filters }, columns), emptyFilterModal: 'mustFillFilters' },
        { id: searchType.FLEETS, formValuesName: 'dossier', fetchAction: (value, columns) => this.props.actions.fetchFleetsDownload({ ...value, ...this.props.filters }, columns), emptyFilterModal: 'mustFillFilters' },
        { id: searchType.SELFSUPPLY, formValuesName: 'selfSupply', fetchAction: (value, columns) => this.props.actions.fetchSelfSupplyDownload({ ...value, ...this.props.filters }, columns), emptyFilterModal: 'mustFillFilters' },
        { id: searchType.PREPARE, formValuesName: 'servicePreparationDelivery', fetchAction: (value, columns) => this.props.actions.fetchServicePrepareDownload({ ...value, ...this.props.filters }, columns), emptyFilterModal: 'mustFillFilters' },
        { id: searchType.PAP_SERVICES_MANAGER, formValuesName: formNames.PAP_SERVICES_MANAGER, fetchAction: (value, columns) => this.props.actions.fetchPapServicesDownloadFromManager({ ...this.props.filters, ...value }, columns), emptyFilterModal: 'mustFillFiltersPapFinder', customValidation: (values) => this.customValidationToPapServicesManager(values) },
        { id: searchType.PROCESSING_AGENCY, formValuesName: 'processing_Agency', fetchAction: (value, columns) => this.props.actions.fetchProcessingAgenciesDownload({ ...value, ...this.props.filters }, columns), emptyFilterModal: 'mustFillFilters' },
        { id: searchType.FLEET_REPORT, formValuesName: 'fleetReport', fetchAction: (value, columns) => this.props.actions.fetchFleetReportDownload({ ...value, ...this.props.filters }, columns), emptyFilterModal: 'mustFillMinFilters2' },
        { id: searchType.STOCK_MANAGEMENT, formValuesName: formNames.STOCK_MANAGEMENT, fetchAction: (value, columns) => this.props.actions.fetchStockManagementDossiersDownload({ ...value, ...this.props.filters, columns }), emptyFilterModal: 'mustFillFilters' }
      ],
      selectedTab: null
    }
    this.onSelectTabs = this.onSelectTabs.bind(this)
    this.handleClickReset = this.handleClickReset.bind(this)
  }

  componentDidMount () {
    this.props.actions.fetchDossierSubTypes()
    const disableResetButton = this.isCurrentTabFormEmpty(this.props.selectedTab, this.props.dynamicFilters)
    this.setState({ disableResetButton: disableResetButton })
    for (const dynamicFilter in this.props.dynamicFilters) {
      this.props.change(dynamicFilter, this.props.dynamicFilters[dynamicFilter])
    }
    if (this.props.section === undefined || this.props.section.code !== 'search') {
      let codeSection = this.props.sectionsGeneral.find(section => section.code === 'search')
      this.props.actions.setCurrentSection(codeSection)
    }
    this.props.actions.initializeSearch()
    this.disableFields()
    this.initializePermisions(this.props)
    if (this.props.selectedTab === searchType.PREPARE) {
      this.configurePreparationDeliverySearcher()
    }
    this.props.actions.closeReportCommercialTableModal()
  }

  getSnapshotBeforeUpdate (prevProps, prevState) {
    if ((prevProps.section === undefined || prevProps.section.code !== 'search') && this.props.section !== undefined) {
      this.initializePermisions(this.props)
    }
    if (prevProps.selectedTab !== this.props.selectedTab && this.props.selectedTab === searchType.PREPARE) {
      this.configurePreparationDeliverySearcher()
    }
    let disableResetButton = this.props.pristine
    if (!this.props.pristine) {
      disableResetButton = this.isCurrentTabFormEmpty(this.props.selectedTab, this.props.formValues)
    }
    if (prevState.disableResetButton !== disableResetButton) {
      this.setState({ disableResetButton: disableResetButton })
    }

    if (!this.props.selectedTab) {
      this.getDefaultSelectedTab()
    }

    return null
  }

  componentDidUpdate () {
    // getSnapshotBeforeUpdate() should be used with componentDidUpdate()
  }

  customValidationToPapServicesManager (values) {
    const papServicesManagerValues = values[`${formNames.PAP_SERVICES_MANAGER}`]
    const someDateFieldIsNull = (!papServicesManagerValues || !papServicesManagerValues.dateFrom || !papServicesManagerValues.dateTo || !papServicesManagerValues.dateToApply)
    const someDateFieldIsNotNull = !!papServicesManagerValues && !!(!!papServicesManagerValues.dateFrom || !!papServicesManagerValues.dateTo || !!papServicesManagerValues.dateToApply)
    return !papServicesManagerValues || (settings.clientFieldValidation === clientInformation.VGR && !papServicesManagerValues.vinchassis && !someDateFieldIsNotNull) ||
      (someDateFieldIsNull && someDateFieldIsNotNull) || (settings.clientFieldValidation !== clientInformation.VGR && someDateFieldIsNull)
  }

  validateLimitDate (values) {
    const fromDateSplitted = values.vehicle && values.vehicle.licensePlateDateFrom && values.vehicle.licensePlateDateFrom.split('/')
    const fromDateParsed = fromDateSplitted && new Date(+fromDateSplitted[2], fromDateSplitted[1] - 1, +fromDateSplitted[0])
    const fromDateLimit = fromDateSplitted && new Date(+fromDateSplitted[2], fromDateSplitted[1] - 1, +fromDateSplitted[0])
    if (fromDateLimit) {
      fromDateLimit.setMonth(fromDateLimit.getMonth() + 1)
    }

    const toDateSplitted = values.vehicle && values.vehicle.licensePlateDateTo && values.vehicle.licensePlateDateTo.split('/')
    const toDateParsed = toDateSplitted && new Date(+toDateSplitted[2], toDateSplitted[1] - 1, +toDateSplitted[0])

    return fromDateParsed && !toDateParsed || !fromDateParsed && toDateParsed || fromDateParsed > toDateParsed || fromDateLimit < toDateParsed
  }

  handleClickReset (e) {
    if (!this.state.disableResetButton) {
      this.onClickResetFilters(e)
    }
    this.props.actions.fetchSaleTypeBySubtype(null, true)
    this.props.actions.fetchPurchaseType(null)
    this.props.actions.fetchOperationType(null, null, null, false)
    this.props.actions.fetchPaymentMethodCombo(null)
  }

  arrayToStringSelfSupply () {
    var dossiers = []
    var dossiersHistorical = []

    this.state.dossierIdToArray.map((dossier) => {
      if (this.props.selfSupplyData.find(x => x.dossierId === dossier).isHistorical) {
        dossiersHistorical.push(dossier)
      } else {
        dossiers.push(dossier)
      }
    })

    if (dossiers.length > 0 || dossiersHistorical.length > 0) {
      this.props.actions.saleSelfSupplyList({ dossierId: dossiers.join(','), historicalsId: dossiersHistorical.join(','), saleParentId: null })
    }
  }

  addDossierIdToArrayMaxLenght (dossierId) {
    const arrayContainDossier = this.state.dossierIdToArray.includes(dossierId)
    if (this.state.dossierIdToArray.length <= 4) {
      if (!arrayContainDossier) {
        this.setState({ dossierIdToArray: [...this.state.dossierIdToArray, dossierId] })
      }
    } else {
      const array = this.state.dossierIdToArray
      if (arrayContainDossier) {
        array.splice(array.findIndex(d => d === dossierId), 1)
        this.setState({ dossierIdToArray: array })
      }
    }
  }

  isCurrentTabFormEmpty (selectedTab, formValues) {
    let selectedTabValues
    if (formValues && selectedTab) {
      const config = this.state.config.find((d) => { return d.id === selectedTab })
      if (config) selectedTabValues = formValues[config.formValuesName]
    }

    let isEmpty = true
    for (const prop in selectedTabValues) {
      if (selectedTabValues[prop] != null && !(Array.isArray(selectedTabValues[prop]) && selectedTabValues[prop].length === 0)) {
        if (!(prop === 'repairWorkshops' && this.props.combos.repairWorkshopsCombo?.isWorkshopUser)) {
          isEmpty = false
          break
        }
      }
    }
    return isEmpty
  }

  addDossierIdToArray (dossierId) {
    const arrayContainDossier = this.state.dossierIdToArray.includes(dossierId)
    if (!arrayContainDossier) {
      this.setState({ dossierIdToArray: [...this.state.dossierIdToArray, dossierId] })
    }
    const array = this.state.dossierIdToArray
    if (arrayContainDossier) {
      array.splice(array.findIndex(d => d === dossierId), 1)
      this.setState({ dossierIdToArray: array })
    }
  }

  arrayToString () {
    const dossierIds = this.state.dossierIdToArray.join(',')
    if (dossierIds !== '') {
      this.props.actions.salePayRentingList(dossierIds)
    }
  }

  formatMultipleValues (multiplecombovalues) {
    return multiplecombovalues
      .map((combovalue, index) => combovalue.value || combovalue.name)
      .join(', ')
  }

  extractCombo (combo, filters, item, filter) {
    const selected = combo.find(object => object.id === filters[item][filter])
    let value = selected && selected.value
    return value
  }

  extractMultipleCombo (combo, filters, item, filter) {
    const values = combo.filter(object => {
      return filters[item][filter] ? filters[item][filter].some(selectedComboValue => object.id === selectedComboValue) : null
    })
    let value = values && this.formatMultipleValues(values)
    return value
  }

  extractMultipleComboStringArray (combo, filters, item, filter) {
    let array = filters[item][filter] ? filters[item][filter].split(',').map(Number) : null
    const values = combo.filter(object => {
      return array ? array.some(selectedComboValue => object.id === selectedComboValue) : null
    })

    let value = values && this.formatMultipleValues(values)
    return value
  }

  treeComboToArray (combo) {
    var values = []
    combo.map((item, index) => {
      values.push({ id: item.value, value: item.label })
      if (item.children && item.children.length > 0) {
        values = values.concat(this.treeComboToArray(item.children))
      }
    })
    return values
  }

  extractValueComboYesNo (value) {
    if (value === true || value === 1) return this.props.t('SEARCH.CATEGORIES.DOSSIER.YES')
    if (value === false || value === 0) return this.props.t('SEARCH.CATEGORIES.DOSSIER.NO')
  }

  getDataToApplyCombo () {
    const tKey = 'SEARCH.CATEGORIES.PAP_SERVICES_MANAGER.'
    return [{
      id: dateToApply.REQUIRED_DATE,
      value: this.props.t(`${tKey}REQUIRED_DATE`)
    }, {
      id: dateToApply.APPLICATION_DATE,
      value: this.props.t(`${tKey}APPLICATION_DATE`)
    }]
  }

  selectedFilters () {
    const filters = this.props.dynamicFilters
    const tKey = 'SEARCH.CATEGORIES.'
    return Object.keys(filters)
      .filter(item => { return item === this.state.config.find((d) => { return d.id === this.props.selectedTab }).formValuesName })
      .map((item) => {
        let text = item
        let value = null
        const list = []
        Object.keys(filters[item]).map((filter, index) => {
          text = this.props.t(`${tKey}${item.toUpperCase()}.${fromCamelToUnderscore(filter).toUpperCase()}`)
          switch (filter) {
            case 'brand':
            { const brand = this.props.combos.brandCombo.find(brand => brand.id === filters[item][filter])
              value = brand && brand.value
              break }
            case 'brands':
            { value = this.extractMultipleCombo(this.props.combos.brandCombo, filters, item, filter)
              break }
            case 'entityTypeId':
            case 'entityChildTypeId':
            { const entityTypeId = this.props.combos.entityTypeCombo.find(entityType => entityType.id === filters[item][filter])
              value = entityTypeId && entityTypeId.value
              break }
            case 'entitySubTypeId':
            case 'entityChildSubTypeId':
            { const entitySubTypeId = this.props.combos.entitySubTypeCombo.find(entitySubType => entitySubType.id === filters[item][filter])
              value = entitySubTypeId && entitySubTypeId.value
              break }
            case 'entityId':
            case 'entityChildId':
            { const entityId = this.props.combos.entityFilterSubTypeCombo.find(entity => entity.id === filters[item][filter])
              value = entityId && entityId.value
              break }
            case 'dossierSubtype':
            { const selectedSubtype = this.props.combos.searchDossierSubtypesCombo.find(subtype => subtype.id === filters[item][filter])
              value = selectedSubtype && selectedSubtype.value
              break }
            case 'salesman':
            {
              value = this.extractMultipleCombo(this.props.combos.salesmanCombo, filters, item, filter)
              break
            }
            case 'salesmanManager':
            {
              value = this.extractMultipleCombo(this.props.combos.salesmanCombo, filters, item, filter)
              break
            }
            case 'salesmanIndividual':
            {
              value = this.extractCombo(this.props.combos.salesmanCombo, filters, item, filter)
              break
            }
            case 'salesmanManagerIndividual':
            {
              value = this.extractCombo(this.props.combos.salesmanCombo, filters, item, filter)
              break
            }
            case 'organizedUnit':
            { const organizedUnit = this.props.combos.lowestOrganizedUnitsCombo.find(ou => ou.id === filters[item][filter])
              value = organizedUnit && organizedUnit.value
              break }
            case 'organizedUnits':
            {
              value = this.extractMultipleComboStringArray(this.treeComboToArray(this.props.combos.UOTreeCombo), filters, item, filter)
              break }
            case 'stage':
            case 'stages':
            case 'stageId':
            case 'dossierStages':
            {
              value = this.extractMultipleCombo(this.props.combos.stagesCombo, filters, item, filter)
              break }
            case 'bank':
            { const bank = this.props.combos.banksCombo.find(b => b.id === filters[item][filter])
              value = bank && bank.value
              break }
            case 'userId':
            { const user = this.props.combos.usersCombo.find(b => b.id === filters[item][filter])
              value = user && user.value
              break }
            case 'creatorId':
            { const user = this.props.combos.usersCombo.find(b => b.id === filters[item][filter])
              value = user && user.value
              break }
            case 'saleTypeId':
            { const saleType = this.props.combos.saleTypeCombo.find(ou => ou.id === filters[item][filter])
              value = saleType && saleType.value
              break }
            case 'purchaseTypeId':
            { const purchaseType = this.props.combos.purchaseTypeCombo.find(ou => ou.id === filters[item][filter])
              value = purchaseType && purchaseType.value
              break }
            case 'operationTypeId':
            { const operationType = this.props.combos.operationTypeCombo.find(ou => ou.id === filters[item][filter])
              value = operationType && operationType.value
              break }
            case 'operationTypeDMSId':
            {
              let operationType = ''
              filters[item][filter].forEach(id => {
                operationType = this.props.combos.operationTypeDMSCombo.find(ou => ou.id === id)
                value = (value || '') + (operationType && (operationType.description + ', '))
              })
              if (value) {
                value = value.substring(0, value.length - 2)
              }
              break }
            case 'documentStatus':
            { const documentStatus = this.props.combos.documentStatusCombo.find(b => b.id === filters[item][filter])
              value = documentStatus && documentStatus.value
              break }
            case 'stockStatusId':
            { const stockStatus = this.props.combos.stockStatusCombo.find(b => b.id === filters[item][filter])
              value = stockStatus && stockStatus.value
              break }
            case 'requestAmount':
            { value = formatMoney(filters[item][filter])
              break }
            case 'amount':
            { value = formatMoney(filters[item][filter])
              break }
            case 'proformInvoiceEmited': {
              value = this.extractValueComboYesNo(filters[item][filter])
              break
            }
            case 'licensePlateFilled': {
              value = this.extractValueComboYesNo(filters[item][filter])
              break
            }
            case 'isAgencyModel': {
              value = this.extractValueComboYesNo(filters[item][filter])
              break
            }
            case 'isDenied': {
              value = this.extractValueComboYesNo(filters[item][filter])
              break
            }
            case 'pickPreviousVehicle': {
              value = this.extractValueComboYesNo(filters[item][filter])
              break
            }
            case 'rejected': {
              const rejected = this.props.combos.rejectedCombo.find(b => b.id === filters[item][filter])
              value = this.props.t(`COMBOS.REJECTED_COMBO.${rejected.value}`)
              break
            }
            case 'dateToApply': {
              value = this.getDataToApplyCombo().find(d => d.id === filters[item][filter]).value
              break
            }
            case 'paymentMethod':
            {
              value = this.extractMultipleCombo(this.props.combos.paymentMethodCombo, filters, item, filter)
              break
            }
            case 'carlineId':
            { const carline = this.props.combos.carlineCombo.find(carline => carline.id === filters[item][filter])
              value = carline && carline.value
              break }
            case 'catalogueId':
            { const catalogue = this.props.combos.catalogueCombo.find(catalogue => catalogue.id === filters[item][filter])
              value = catalogue && catalogue.value
              break }
            case 'modelYearId':
            { const modelYear = this.props.combos.modelCombo.find(modelYear => modelYear.id === filters[item][filter])
              value = modelYear && modelYear.value
              break }
            case 'colorId':
            { const color = this.props.combos.colorCombo.find(color => color.id === filters[item][filter])
              value = color && color.value
              break }
            case 'tapestryId':
            { const tapestry = this.props.combos.tapestryCombo.find(tapestry => tapestry.id === filters[item][filter])
              value = tapestry && tapestry.value
              break }
            case 'vehicleCondition':
            { value = vehicleCondition.NEW === filters[item][filter] ? this.props.t(`${tKey}VEHICLE.VEHICLE_CONDITION_NEW`) : this.props.t(`${tKey}VEHICLE.VEHICLE_CONDITION_NEARLY_NEW`)
              break }
            case 'repairWorkshops':
            {
              const repairWorkshops = this.props.combos.repairWorkshopsCombo?.repairShopsList
              const isWorkshopUser = this.props.combos.repairWorkshopsCombo?.isWorkshopUser ?? false
              value = isWorkshopUser ? null : this.extractMultipleCombo(repairWorkshops, filters, item, filter)
              break
            }
            case 'dateType':
            {
              value = this.getDateTypeOptions().find(dateType => dateType.id === filters[item][filter]).value
              break
            }
            case 'dateTo':
            case 'dateFrom': {
              const dateType = this.getDateTypeOptions().find(x => x.id === filters[item].dateType)?.value ?? ''
              text = text.replace('#DATETYPE#', dateType)
              value = filters[item][filter]
              break
            }
            default:
            { value = filters[item][filter]
              break }
          }

          if (value) {
            if (filter !== 'entityChildSelected') {
              list.push(<li key={index}>{text}: <strong>{value}</strong>;</li>)
            } else {
              list.push(<li key={index}> <strong>{text}</strong>;</li>)
            }
          }
        })
        return list
      })
  }

  submitSearchForm (values) {
    if (this.props.selectedTab) {
      const config = this.state.config.find((d) => { return d.id === this.props.selectedTab })
      if (config) {
        var skipValidation = this.props.selectedTab === searchType.PLAN_LOGISTICS_PAP ||
          this.props.selectedTab === searchType.PAP_SERVICES_MANAGER ||
          this.props.selectedTab === searchType.STOCK_MANAGEMENT
        if (!skipValidation) {
          var customValidation = config.customValidation && config.customValidation(values)
          var allEmptyFieldOrNoValues = this.estandarValidations(values, config)
          if (allEmptyFieldOrNoValues || customValidation) {
            return this.props.actions.openModal(config.emptyFilterModal)
          }
        }
        config.fetchAction(values[config.formValuesName])
        this.setState({ dossierIdToArray: [] })
      }
    }

    this.props.actions.setFilters(values)
  }

  estandarValidations (values, config) {
    var result = false
    const formValues = values[config.formValuesName]
    switch (config.emptyFilterModal) {
      case 'mustFillFilters':
      {
        result = !formValues || !Object.keys(formValues).filter(item => formValues[item] !== null &&
         Array.isArray(formValues[item]) ? formValues[item].length > 0 : true).length
        break
      }
      case /^mustFillMinFilters(\d+)$/.test(config.emptyFilterModal) && config.emptyFilterModal:
      {
        const minFilters = parseInt(config.emptyFilterModal.match(/^mustFillMinFilters(\d+)$/)[1], 10)
        const valuesCount = formValues
          ? Object.keys(formValues).filter(item => formValues[item] !== null).length
          : 0
        result = !(valuesCount >= minFilters)
        break
      }
    }

    return result
  }

  onClickResetSearch (e) {
    if (e !== undefined) {
      e.preventDefault()
    }

    this.state.dossierIdToArray.map((dossierId, idx) => {
      this.props.change('selectedCheck_' + dossierId, false)
    })
    this.props.actions.resetFilters()
    this.setState({ dossierIdToArray: [] })
  }

  configurePreparationDeliverySearcher () {
    this.props.actions.fetchStagesCombo(dossierSubTypeId.servicePreparationDelivery, true)
    this.props.actions.fetchServicePrepareRoleConfig()
  }

  configureReportCommercial () {
    var today = new Date()
    var dd = '01'
    var mm = String(today.getMonth() + 1).padStart(2, '0')
    var yyyy = today.getFullYear()
    today = dd + '/' + mm + '/' + yyyy

    this.props.change('reportComercial', { dateFrom: today })
  }

  exportToExcel (data) {
    let cols = ''
    data.forEach(element => {
      cols += element + '$'
    })
    cols = cols.substring(0, cols.length - 1)
    let values = this.props.formValues
    if (this.props.selectedTab) {
      const config = this.state.configExport.find((d) => { return d.id === this.props.selectedTab })
      if (config) {
        config.fetchAction(values[config.formValuesName], cols)
      }
    }
  }

  onClickResetFilters (e) {
    if (e) {
      e.preventDefault()
    }
    this.props.initialize({})
    this.props.actions.resetFilters()
    this.setState({ fields: {}, dossierIdToArray: [] })
    this.disableFields()
  }

  fieldFill (field) {
    this.setState({ fields: { ...this.state.fields, ...field } })
  }

  onOrderChange (e) {
    const newOrderBy = this.props.filters.orderBy.includes(`%2B${e.currentTarget.dataset.field}`) ? `-${e.currentTarget.dataset.field}` : `%2B${e.currentTarget.dataset.field}`
    this.fetchTable({ ...this.props.filters, orderBy: newOrderBy })
  }

  onPageChange (page) {
    this.fetchTable({ ...this.props.filters, page })
  }

  getIconClass (field) {
    let order
    let orderBy
    if (this.props.filters?.orderBy?.startsWith('-')) {
      order = '-'
      orderBy = this.props.filters.orderBy.slice(1)
    } else if (this.props.filters?.orderBy?.startsWith('%2B')) {
      order = '+'
      orderBy = this.props.filters.orderBy.slice(3)
    }
    return order && orderBy === field ? (order === '-' ? 'ico-sort-selected-asc' : 'ico-sort-selected-desc') : 'ico-sort'
  }

  fetchTable (filters) {
    switch (this.props.selectedTab) {
      case searchType.SELFSUPPLY:
      { this.props.actions.fetchSelfSupply({ ...this.props.dynamicFilters.selfSupply, ...filters })
        break }
      case searchType.FLEETS:
      { this.props.actions.fetchFleets({ ...this.props.dynamicFilters.dossier, ...filters })
        break }
      case searchType.RENTING:
      { this.props.actions.fetchChargeRenting({ ...this.props.dynamicFilters.renting, ...filters })
        break }
      case searchType.VEHICLE_SIMPLE:
      { this.props.actions.fetchVehiclesSimple({ ...this.props.dynamicFilters.vehicle, ...filters })
        break }
      case searchType.DOSSIER_SIMPLE:
      { this.props.actions.fetchDossiersSimple({ ...this.props.dynamicFilters.dossier, ...filters })
        break }
      case searchType.CHARGE:
      { this.props.actions.fetchCharges({ ...this.props.dynamicFilters.charge, ...filters })
        break }
      case searchType.DOSSIER:
      { this.props.actions.fetchDossiers({ ...this.props.dynamicFilters.dossier, ...filters })
        break }
      case searchType.ENTITY:
      { this.props.actions.fetchEntities({ ...this.props.dynamicFilters.entity, ...filters })
        break }
      case searchType.PREPARE:
      { this.props.actions.fetchServicePrepare({ ...this.props.dynamicFilters.servicePreparationDelivery, ...filters })
        break }
      case searchType.COMMERCIAL_REPORT:
      { this.props.actions.fetchReportCommercial({ ...this.props.dynamicFilters.reportComercial, ...filters })
        break }
      case searchType.PAP_SERVICES_MANAGER:
      {
        const config = this.state.config.find((d) => { return d.id === this.props.selectedTab })
        config.fetchAction({ ...this.props.dynamicFilters[`${formNames.PAP_SERVICES_MANAGER}`], ...filters })
        break }
      case searchType.PLAN_LOGISTICS_PAP:
      {
        const config = this.state.config.find((d) => { return d.id === this.props.selectedTab })
        config.fetchAction({ ...this.props.dynamicFilters[`${formNames.PLAN_LOGISTICS_PAP}`], ...filters })
        break }
      case searchType.PROCESSING_AGENCY:
      { this.props.actions.fetchProcessingAgencies({ ...this.props.dynamicFilters.processing_Agency, ...filters })
        break }
      case searchType.FLEET_REPORT:
      { this.props.actions.fetchFleetReport({ ...this.props.dynamicFilters.fleetReport, ...filters })
        break }
      case searchType.STOCK_MANAGEMENT:
      {
        this.props.actions.fetchStockManagementDossiers({ ...this.props.dynamicFilters.stockManagement, ...filters })
        break
      }
      default: {
        this.props.actions.fetchVehicles({ ...this.props.dynamicFilters.vehicle, ...filters })
        break }
    }
  }

  initializePermisions (props) {
    if (props.section && props.section.sectionTabsConfiguration) {
      const newState = getSectionPermissions(props.section)
      this.setState({ sectionTabsConfiguration: newState })
    }
  }

  disableFields () {
    if (!this.props.selectedTab === searchType.STOCK_MANAGEMENT) {
      this.props.actions.fetchStagesCombo(null)
    }
    this.props.actions.fetchSaleTypeBySubtype(null, true)
    this.props.actions.fetchPurchaseType(null)
    this.props.actions.fetchOperationType(null, null, null)
  }

  onClickTaskListRow (documentId, documentTypeId, documentParentTypeId, objectId, organizedUnitId) {
    const callback = () => {
      if (this.props.filters) {
        this.fetchTable(this.props.filters)
      }
    }

    this.props.actions.openDocumentEditionModal(documentId, documentTypeId, documentParentTypeId, objectId, objectId, objectId,
      organizedUnitId, null, callback, true, '', '')
  }

  selectDossierSubType (selectedTab) {
    if (selectedTab && selectedTab === searchType.DOSSIER_SIMPLE && this.props.combos && this.props.combos.searchDossierSubtypesCombo &&
        this.props.combos.searchDossierSubtypesCombo.length > 0) {
      this.props.change('dossier.dossierSubtype', this.props.combos.searchDossierSubtypesCombo[0].id)
    }
  }

  selectDossierSubTypeFirstTime () {
    if (this.state && this.state.selectDossierSubType) {
      this.setState({ selectDossierSubType: false })
      this.selectDossierSubType(this.props.selectedTab)
    }
  }

  selectChargeStateFirstTime () {
    if (this.state && (!settings.dontFilterCharges)) {
      this.props.change('charge.documentStatus', backEndIdDocumentStatus.INCORPORATED)
    }
  }

  uploadDocuments () {
    if (this.state.dossierIdToArray && this.state.dossierIdToArray.length > 0) {
      this.props.actions.openDocumentCreationModalFleets(this.state.dossierIdToArray, this.state.organizedUnitId)
    } else {
      this.props.actions.openModal('anyDossierMustBeSelected')
    }
  }

  getDefaultSelectedTab () {
    let firstTabToShow = null
    if (this.state.sectionTabsConfiguration.dossierSimple && this.state.sectionTabsConfiguration.dossierSimple.permission > permissions.hidden) {
      firstTabToShow = searchType.DOSSIER_SIMPLE
    } else {
      Object.keys(this.state.sectionTabsConfiguration).forEach(tab => {
        if (!firstTabToShow && this.state.sectionTabsConfiguration[tab] && this.state.sectionTabsConfiguration[tab].permission > permissions.hidden) {
          switch (tab) {
            case searchTabCode.DOSSIER: {
              firstTabToShow = searchType.DOSSIER
              break
            } case searchTabCode.ENTITY: {
              firstTabToShow = searchType.ENTITY
              break
            } case searchTabCode.PREPARE: {
              firstTabToShow = searchType.PREPARE
              break
            } case searchTabCode.PAP_SERVICES_MANAGER: {
              firstTabToShow = searchType.PAP_SERVICES_MANAGER
              break
            } case searchTabCode.PROCESSING_AGENCY: {
              firstTabToShow = searchType.PROCESSING_AGENCY
              break
            } case searchTabCode.STOCK_MANAGEMENT: {
              firstTabToShow = searchType.STOCK_MANAGEMENT
              break
            }
            default: {
              firstTabToShow = searchType.VEHICLE
              break
            }
          }
        }
      })
    }

    if (firstTabToShow) {
      this.props.actions.searchTabChange(firstTabToShow)
    } else {
      return null
    }
  }

  onSelectTabs (idx, event) {
    this.setState({ selectedTab: idx })
    this.props.actions.searchTabChange(idx, event)
    this.onClickResetFilters()
    this.selectDossierSubType(idx)
    this.selectChargeStateFirstTime()
    if (idx === searchType.PREPARE) {
      this.configurePreparationDeliverySearcher()
    }
    if (idx === searchType.COMMERCIAL_REPORT) {
      this.configureReportCommercial()
    }
    if (idx === searchType.PLAN_LOGISTICS_PAP) {
      this.props.actions.fetchPlanLogiticsPap({ ...this.props.filters })
    }
    if (idx === searchType.FLEET_REPORT) {
      if (this.props.combos.purchaseStateLogisticCombo?.length === 0) {
        this.props.actions.fetchPurchaseLogisticStateCombo()
        this.props.actions.fetchStagesCombo(1)
      }
    }
    if (idx === searchType.STOCK_MANAGEMENT) {
      this.props.actions.fetchStagesCombo(dossierSubTypes.stock, true)
    }
  }

  getDateTypeOptions () {
    const { t } = this.props
    const tKey = 'SEARCH.CATEGORIES.STOCKMANAGEMENT.DATE_TYPE_OPTIONS.'
    return [
      { id: '1', value: t(`${tKey}CREATION_DATE`) },
      { id: '2', value: t(`${tKey}RECEPTION_DATE`) },
      { id: '3', value: t(`${tKey}RATING_DATE`) },
      { id: '4', value: t(`${tKey}BUDGET_SENT_DATE`) },
      { id: '5', value: t(`${tKey}BUDGET_ACCEPTED_DATE`) },
      { id: '6', value: t(`${tKey}RECONDITIONING_CONFIRMATION_DATE`) }
    ]
  }

  render () {
    const {
      t, handleSubmit, selectedTab, hasSearch, dossierData, vehicleData, entityData, chargeData, rentingData, selfSupplyData, prepareData, pages, count, serviceRoleConfig, reportData, filters: { page },
      showCampaignColumnsInExcel, showButtonUploadDocument, papServicesData, processingAgenciesData, fleetReportData, dynamicFilters, stockManagementData,
      combos: { stagesCombo, repairWorkshopsCombo },
      actions: { fetchStagesCombo, fetchUserRepairWorkshopsCombo }
    } = this.props
    const tKey = 'SEARCH.'
    const className = 'filled'
    const classNameSelect = 'filledSelect'
    const stateTooltip = (<Tooltip id="tooltip-trigger-hover-focus">{t(`${tKey}CATEGORIES.VEHICLE.STATE_TOOLTIP`)}</Tooltip>)
    const activeTooltip = (<Tooltip id="tooltip-trigger-hover-focus">{t(`${tKey}CATEGORIES.VEHICLE.AVAILABLE`)}</Tooltip>)
    const inActiveTooltip = (<Tooltip id="tooltip-trigger-hover-focus">{t(`${tKey}CATEGORIES.VEHICLE.UNAVAILABLE`)}</Tooltip>)
    const navigationTooltip = (<Tooltip id="urgenciaTooltip">{t(`${tKey}CATEGORIES.TOOLTIP.GO_TO`)}</Tooltip>)
    return (
      <div className="search-wrapper">
        <div className="search-header">
          <div className="title-user">
            <i className="ico-search"/> <h2>{t('SECTIONS.SEARCH')}</h2>
          </div>
        </div>
        <div className="filters-search-wrapper">
          <Form autoComplete="off" onSubmit={handleSubmit((values) => this.submitSearchForm(values))} className="filters">
            <Row>
              <Tabs
                defaultActiveKey={selectedTab}
                onSelect={this.onSelectTabs}
                animation={false}
                id="tab-search"
                className="col-sm-12"
                activeKey={selectedTab}
              >
                {this.state.sectionTabsConfiguration.dossierSimple && this.state.sectionTabsConfiguration.dossierSimple.permission > permissions.hidden &&
                  <Tab eventKey={searchType.DOSSIER_SIMPLE} title={t(`${tKey}CATEGORIES.DOSSIER.BY_DOSSIER_SIMPLE`)}>
                    <SearchByDossierSimpleForm
                      t={t}
                      change={this.props.change}
                      fields={this.state.fields}
                      organizedUnitId={''}
                      fieldFill={(val) => this.fieldFill(val)}
                      className={className}
                      classNameSelect={classNameSelect}
                      selectDossierSubTypeFirstTime={() => this.selectDossierSubTypeFirstTime()}
                      hasSearch={hasSearch}
                      dossierData={dossierData}
                      vehicleData={vehicleData}
                      getIconClass={(value) => this.getIconClass(value)}
                      selectedFilters={() => this.selectedFilters()}
                      onClickResetSearch={(value) => this.onClickResetSearch(value)}
                      onOrderChange={(value) => this.onOrderChange(value)}
                      onPageChange={(value) => this.onPageChange(value)}
                      page={page}
                      pages={pages}
                      count={count}
                      exportToExcel = {(value) => this.exportToExcel(value)}
                    />
                  </Tab>
                }
                {this.state.sectionTabsConfiguration.dossier && this.state.sectionTabsConfiguration.dossier.permission > permissions.hidden &&
                  <Tab eventKey={searchType.DOSSIER} title={t(`${tKey}CATEGORIES.DOSSIER.BY_DOSSIER`)}>
                    <SearchByDossierForm
                      t={t}
                      change={this.props.change}
                      fields={this.state.fields}
                      fieldFill={(val) => this.fieldFill(val)}
                      className={className}
                      classNameSelect={classNameSelect}
                      hasSearch={hasSearch}
                      dossierData={dossierData}
                      vehicleData={vehicleData}
                      inActiveTooltip={inActiveTooltip}
                      getIconClass={(value) => this.getIconClass(value)}
                      selectedFilters={() => this.selectedFilters()}
                      onClickResetSearch={(value) => this.onClickResetSearch(value)}
                      onOrderChange={(value) => this.onOrderChange(value)}
                      onPageChange={(value) => this.onPageChange(value)}
                      page={page}
                      pages={pages}
                      count={count}
                      exportToExcel = {(value) => this.exportToExcel(value)}
                      permissions = {this.state.sectionTabsConfiguration.dossier && this.state.sectionTabsConfiguration.dossier.sectionFieldsConfiguration ? this.state.sectionTabsConfiguration.dossier : null }
                      showCampaignColumnsInExcel={showCampaignColumnsInExcel}
                    />
                  </Tab>
                }
                {this.state.sectionTabsConfiguration.vehicleSimple && this.state.sectionTabsConfiguration.vehicleSimple.permission > permissions.hidden &&
                  <Tab eventKey={searchType.VEHICLE_SIMPLE} title={t(`${tKey}CATEGORIES.VEHICLE.BY_VEHICLE_SIMPLE`)}>
                    <SearchByVehicleSimpleForm
                      t={t}
                      fields={this.state.fields}
                      fieldFill={(val) => this.fieldFill(val)}
                      className={className}
                      classNameSelect={classNameSelect}
                      hasSearch={hasSearch}
                      vehicleData={vehicleData}
                      stateTooltip={stateTooltip}
                      activeTooltip={activeTooltip}
                      inActiveTooltip={inActiveTooltip}
                      getIconClass={(value) => this.getIconClass(value)}
                      selectedFilters={() => this.selectedFilters()}
                      onClickResetSearch={(value) => this.onClickResetSearch(value)}
                      onOrderChange={(value) => this.onOrderChange(value)}
                      onPageChange={(value) => this.onPageChange(value)}
                      page={page}
                      pages={pages}
                      count={count}
                      exportToExcel = {(value) => this.exportToExcel(value)}
                      permissions = {this.state.sectionTabsConfiguration.vehicleSimple && this.state.sectionTabsConfiguration.vehicleSimple.sectionFieldsConfiguration ? this.state.sectionTabsConfiguration.vehicleSimple.sectionFieldsConfiguration : null }
                      subtypesCombo={this.props.combos.dossierSubtypesCombo}
                    />
                  </Tab>
                }
                {this.state.sectionTabsConfiguration.vehicle && this.state.sectionTabsConfiguration.vehicle.permission > permissions.hidden &&
                  <Tab eventKey={searchType.VEHICLE} title={t(`${tKey}CATEGORIES.VEHICLE.BY_VEHICLE`)}>
                    <SearchByVehicleForm
                      t={t}
                      change={this.props.change}
                      fields={this.state.fields}
                      fieldFill={(val) => this.fieldFill(val)}
                      className={className}
                      classNameSelect={classNameSelect}
                      hasSearch={hasSearch}
                      vehicleData={vehicleData}
                      stateTooltip={stateTooltip}
                      activeTooltip={activeTooltip}
                      inActiveTooltip={inActiveTooltip}
                      getIconClass={(value) => this.getIconClass(value)}
                      selectedFilters={() => this.selectedFilters()}
                      onClickResetSearch={(value) => this.onClickResetSearch(value)}
                      onOrderChange={(value) => this.onOrderChange(value)}
                      onPageChange={(value) => this.onPageChange(value)}
                      page={page}
                      pages={pages}
                      count={count}
                      exportToExcel = {(value) => this.exportToExcel(value)}
                      permissions = {this.state.sectionTabsConfiguration.vehicle && this.state.sectionTabsConfiguration.vehicle.sectionFieldsConfiguration ? this.state.sectionTabsConfiguration.vehicle : null }
                      subtypesCombo={this.props.combos.dossierSubtypesCombo}
                    />
                  </Tab>
                }
                {this.state.sectionTabsConfiguration.entity && this.state.sectionTabsConfiguration.entity.permission > permissions.hidden &&
                  <Tab eventKey={searchType.ENTITY} title={t(`${tKey}CATEGORIES.ENTITY.BY_ENTITY`)}>
                    <SearchByEntityForm
                      t={t}
                      change={this.props.change}
                      fields={this.state.fields}
                      fieldFill={(val) => this.fieldFill(val)}
                      className={className}
                      classNameSelect={classNameSelect}
                      formValues={this.props.formValues && this.props.formValues.entity ? this.props.formValues.entity : {}}
                      hasSearch={hasSearch}
                      entityData={entityData}
                      vehicleData={vehicleData}
                      getIconClass={(value) => this.getIconClass(value)}
                      selectedFilters={() => this.selectedFilters()}
                      onClickResetSearch={(value) => this.onClickResetSearch(value)}
                      onOrderChange={(value) => this.onOrderChange(value)}
                      onPageChange={(value) => this.onPageChange(value)}
                      page={page}
                      pages={pages}
                      count={count}
                      exportToExcel = {(value) => this.exportToExcel(value)}
                      subtypesCombo={this.props.combos.dossierSubtypesCombo}
                    />
                  </Tab>
                }
                {this.state.sectionTabsConfiguration.rentingCharge && this.state.sectionTabsConfiguration.rentingCharge.permission > permissions.hidden &&
                  <Tab eventKey={searchType.RENTING} title={t(`${tKey}CATEGORIES.DOSSIER.RENTING_WITHOUT_PAID`)}>
                    <SearchByRentingForm
                      t={t}
                      change={this.props.change}
                      fields={this.state.fields}
                      fieldFill={(val) => this.fieldFill(val)}
                      className={className}
                      hasSearch={hasSearch}
                      rentingData={rentingData}
                      getIconClass={(value) => this.getIconClass(value)}
                      selectedFilters={() => this.selectedFilters()}
                      onClickResetSearch={(value) => this.onClickResetSearch(value)}
                      onOrderChange={(value) => this.onOrderChange(value)}
                      onPageChange={(value) => this.onPageChange(value)}
                      addDossierIdToArray ={(value) => this.addDossierIdToArray(value)}
                      arrayToString ={() => this.arrayToString()}
                      dossierIdToArray= {this.state.dossierIdToArray}
                      page={page}
                      pages={pages}
                      count={count}
                      exportToExcel = {(value) => this.exportToExcel(value)}
                    />
                  </Tab>
                }
                {this.state.sectionTabsConfiguration.selfSupply && this.state.sectionTabsConfiguration.selfSupply.permission > permissions.hidden &&
                  <Tab eventKey={searchType.SELFSUPPLY} title={t(`${tKey}CATEGORIES.DOSSIER.SELF_SUPPLY`)}>
                    <SearchBySelfSupplyForm
                      t={t}
                      change={this.props.change}
                      fields={this.state.fields}
                      fieldFill={(val) => this.fieldFill(val)}
                      className={className}
                      hasSearch={hasSearch}
                      selfSupplyData={selfSupplyData}
                      getIconClass={(value) => this.getIconClass(value)}
                      selectedFilters={() => this.selectedFilters()}
                      onClickResetSearch={(value) => this.onClickResetSearch(value)}
                      onOrderChange={(value) => this.onOrderChange(value)}
                      onPageChange={(value) => this.onPageChange(value)}
                      dossierIdToArray= {this.state.dossierIdToArray}
                      addDossierIdToArrayMaxLenght={(value) => this.addDossierIdToArrayMaxLenght(value)}
                      arrayToStringSelfSupply ={() => this.arrayToStringSelfSupply()}
                      arrayToString ={() => this.arrayToString()}
                      page={page}
                      pages={pages}
                      count={count}
                      exportToExcel = {(value) => this.exportToExcel(value)}
                    />
                  </Tab>
                }
                {this.state.sectionTabsConfiguration.charge && this.state.sectionTabsConfiguration.charge.permission > permissions.hidden &&
                <Tab eventKey={searchType.CHARGE} title={t(`${tKey}CATEGORIES.CHARGE.BY_CHARGE`)}>
                  <SearchByChargeForm
                    t={t}
                    change={this.props.change}
                    fields={this.state.fields}
                    organizedUnitId={''}
                    fieldFill={(val) => this.fieldFill(val)}
                    className={className}
                    classNameSelect={classNameSelect}
                    hasSearch={hasSearch}
                    chargeData={chargeData}
                    getIconClass={(value) => this.getIconClass(value)}
                    selectedFilters={() => this.selectedFilters()}
                    onClickResetSearch={(value) => this.onClickResetSearch(value)}
                    onOrderChange={(value) => this.onOrderChange(value)}
                    onPageChange={(value) => this.onPageChange(value)}
                    onClickTaskListRow={(documentId, documentTypeId, documentParentTypeId, objectId, organizedUnitId) => this.onClickTaskListRow(documentId, documentTypeId, documentParentTypeId, objectId, organizedUnitId)}
                    page={page}
                    pages={pages}
                    count={count}
                    navigationTooltip={navigationTooltip}
                    exportToExcel = {(value) => this.exportToExcel(value)}
                    permissions = {this.state.sectionTabsConfiguration.charge && this.state.sectionTabsConfiguration.charge && this.state.sectionTabsConfiguration.charge.sectionFieldsConfiguration ? this.state.sectionTabsConfiguration.charge.sectionFieldsConfiguration : null }
                  />
                </Tab>
                }
                {this.state.sectionTabsConfiguration.fleets && this.state.sectionTabsConfiguration.fleets.permission > permissions.hidden &&
                <Tab eventKey={searchType.FLEETS} title={t(`${tKey}CATEGORIES.FLEETS.FLEETS`)}>
                  <SearchByFleetsForm
                    t={t}
                    change={this.props.change}
                    fields={this.state.fields}
                    organizedUnitId={''}
                    fieldFill={(val) => this.fieldFill(val)}
                    className={className}
                    classNameSelect={classNameSelect}
                    hasSearch={hasSearch}
                    dossierData={dossierData}
                    vehicleData={vehicleData}
                    getIconClass={(value) => this.getIconClass(value)}
                    selectedFilters={() => this.selectedFilters()}
                    onClickResetSearch={(value) => this.onClickResetSearch(value)}
                    onOrderChange={(value) => this.onOrderChange(value)}
                    onPageChange={(value) => this.onPageChange(value)}
                    addDossierIdToArray={(value) => this.addDossierIdToArray(value)}
                    uploadDocuments={() => this.uploadDocuments()}
                    page={page}
                    pages={pages}
                    count={count}
                    showButtonUploadDocument = {showButtonUploadDocument}
                    exportToExcel = {(value) => this.exportToExcel(value)}
                  />
                </Tab>
                }
                {this.state.sectionTabsConfiguration.prepare && this.state.sectionTabsConfiguration.prepare.permission > permissions.hidden &&
                <Tab eventKey={searchType.PREPARE} title={t(`${tKey}CATEGORIES.SERVICE.PAP_MANAGEMENT`)}>
                  <SearchByPrepareFormPage
                    t={t}
                    change={this.props.change}
                    prepareData = {prepareData}
                    serviceRoleConfig = {serviceRoleConfig}
                    fields={this.state.fields}
                    fieldFill={(val) => this.fieldFill(val)}
                    className={className}
                    classNameSelect={classNameSelect}
                    hasSearch={hasSearch}
                    stateTooltip={stateTooltip}
                    activeTooltip={activeTooltip}
                    inActiveTooltip={inActiveTooltip}
                    getIconClass={(value) => this.getIconClass(value)}
                    selectedFilters={() => this.selectedFilters()}
                    onClickResetSearch={(value) => this.onClickResetSearch(value)}
                    onOrderChange={(value) => this.onOrderChange(value)}
                    onPageChange={(value) => this.onPageChange(value)}
                    page={page}
                    pages={pages}
                    count={count}
                    exportToExcel = {(value) => this.exportToExcel(value)}
                    permissions={this.state.sectionTabsConfiguration.prepare && this.state.sectionTabsConfiguration.prepare.sectionFieldsConfiguration ? this.state.sectionTabsConfiguration.prepare.sectionFieldsConfiguration : null}
                    fetchServicePrepareRoleConfig = {this.props.actions.fetchServicePrepareRoleConfig}
                  />
                </Tab>
                }
                {this.state.sectionTabsConfiguration.reportCommercial && this.state.sectionTabsConfiguration.reportCommercial.permission > permissions.hidden &&
                <Tab eventKey={searchType.COMMERCIAL_REPORT} title={t(`${tKey}CATEGORIES.REPORT.COMMERCIAL_REPORT`)}>
                  <SearchCommercialReportPage
                    t={t}
                    change={this.props.change}
                    fields={this.state.fields}
                    organizedUnitId={''}
                    fieldFill={(val) => this.fieldFill(val)}
                    className={className}
                    classNameSelect={classNameSelect}
                    hasSearch={hasSearch}
                    reportData={reportData}
                    getIconClass={(value) => this.getIconClass(value)}
                    selectedFilters={() => this.selectedFilters()}
                    onClickResetSearch={(value) => this.onClickResetSearch(value)}
                    onOrderChange={(value) => this.onOrderChange(value)}
                    onPageChange={(value) => this.onPageChange(value)}
                    page={page}
                    pages={pages}
                    count={count}
                    exportToExcel = {(value) => this.exportToExcel(value)}
                  />
                </Tab>
                }
                {this.state.sectionTabsConfiguration.papServicesManager && this.state.sectionTabsConfiguration.papServicesManager.permission > permissions.hidden &&
                <Tab eventKey={searchType.PAP_SERVICES_MANAGER} title={t(`${tKey}CATEGORIES.PAP_SERVICES_MANAGER.TITLE`)}>
                  <SearchByPapFormPage
                    t={t}
                    change={this.props.change}
                    fields={this.state.fields}
                    organizedUnitId={''}
                    fieldFill={(val) => this.fieldFill(val)}
                    className={className}
                    classNameSelect={classNameSelect}
                    hasSearch={hasSearch}
                    dossierData={papServicesData}
                    getIconClass={(value) => this.getIconClass(value)}
                    selectedFilters={() => this.selectedFilters()}
                    onClickResetSearch={(value) => this.onClickResetSearch(value)}
                    onOrderChange={(value) => this.onOrderChange(value)}
                    onPageChange={(value) => this.onPageChange(value)}
                    page={page}
                    pages={pages}
                    count={count}
                    exportToExcel = {(value) => this.exportToExcel(value)}
                    addDossierIdToArray ={(value) => this.addDossierIdToArray(value)}
                    dateToApplyCombo = {this.getDataToApplyCombo()}
                    permissions = {this.state.sectionTabsConfiguration?.papServicesManager && this.state.sectionTabsConfiguration.papServicesManager.sectionFieldsConfiguration ? this.state.sectionTabsConfiguration.papServicesManager : null }
                  />
                </Tab>
                }
                {this.state.sectionTabsConfiguration.planLogisticsPap && this.state.sectionTabsConfiguration.planLogisticsPap.permission > permissions.hidden &&
                  <Tab eventKey={searchType.PLAN_LOGISTICS_PAP} title={t(`${tKey}CATEGORIES.PLAN_LOGISTICS_PAP.TITLE`)}>
                    <SearchByPlanLogisticsPapFormPage
                      t={t}
                      change={this.props.change}
                      fields={this.state.fields}
                      organizedUnitId={''}
                      fieldFill={(val) => this.fieldFill(val)}
                      className={className}
                      classNameSelect={classNameSelect}
                      hasSearch={hasSearch}
                      dossierData={papServicesData}
                      getIconClass={(value) => this.getIconClass(value)}
                      onClickResetSearch={(value) => this.onClickResetSearch(value)}
                      onOrderChange={(value) => this.onOrderChange(value)}
                      onPageChange={(value) => this.onPageChange(value)}
                      page={page}
                      pages={pages}
                      count={count}
                      exportToExcel = {(value) => this.exportToExcel(value)}
                      addDossierIdToArray ={(value) => this.addDossierIdToArray(value)}
                      dateToApplyCombo = {this.getDataToApplyCombo()}
                      openModal ={this.props.actions.openModal}
                    />
                  </Tab>
                }
                {this.state.sectionTabsConfiguration.processingAgency && this.state.sectionTabsConfiguration.processingAgency.permission > permissions.hidden &&
                <Tab eventKey={searchType.PROCESSING_AGENCY} title={t(`${tKey}CATEGORIES.PROCESSING_AGENCY.TITLE`)}>
                  <SearchByProcessingAgencyFormPage
                    t={t}
                    change={this.props.change}
                    fields={this.state.fields}
                    fieldFill={(val) => this.fieldFill(val)}
                    className={className}
                    classNameSelect={classNameSelect}
                    formValues={this.props.formValues && this.props.formValues.entity ? this.props.formValues.entity : {}}
                    hasSearch={hasSearch}
                    processingAgenciesData={processingAgenciesData}
                    vehicleData={vehicleData}
                    getIconClass={(value) => this.getIconClass(value)}
                    selectedFilters={() => this.selectedFilters()}
                    onClickResetSearch={(value) => this.onClickResetSearch(value)}
                    onOrderChange={(value) => this.onOrderChange(value)}
                    onPageChange={(value) => this.onPageChange(value)}
                    page={page}
                    pages={pages}
                    count={count}
                    exportToExcel = {(value) => this.exportToExcel(value)}
                    subtypesCombo={this.props.combos.dossierSubtypesCombo}
                  />
                </Tab>
                }
                {this.state.sectionTabsConfiguration.fleetReport && this.state.sectionTabsConfiguration.fleetReport.permission > permissions.hidden &&
                <Tab eventKey={searchType.FLEET_REPORT} title={t(`${tKey}CATEGORIES.FLEETREPORT.TITLE`)}>
                  <SearchByFleetReportPage
                    t={t}
                    change={this.props.change}
                    fields={this.state.fields}
                    fieldFill={(val) => this.fieldFill(val)}
                    className={className}
                    classNameSelect={classNameSelect}
                    formValues={this.props.formValues && this.props.formValues.entity ? this.props.formValues.entity : {}}
                    hasSearch={hasSearch}
                    reportData={fleetReportData}
                    getIconClass={(value) => this.getIconClass(value)}
                    selectedFilters={() => this.selectedFilters()}
                    onClickResetSearch={(value) => this.onClickResetSearch(value)}
                    onOrderChange={(value) => this.onOrderChange(value)}
                    onPageChange={(value) => this.onPageChange(value)}
                    page={page}
                    pages={pages}
                    count={count}
                    exportToExcel = {(value) => this.exportToExcel(value)}
                    dynamicFilters={dynamicFilters}
                  />
                </Tab>
                }
                {this.state.sectionTabsConfiguration.stockManagementSearch && this.state.sectionTabsConfiguration.stockManagementSearch.permission > permissions.hidden &&
                <Tab eventKey={searchType.STOCK_MANAGEMENT} title={t(`${tKey}CATEGORIES.STOCKMANAGEMENT.TITLE`)}>
                  <SearchStockManagement
                    t={t}
                    fields={this.state.fields}
                    selectedFilters={() => this.selectedFilters()}
                    fieldFill={(val) => this.fieldFill(val)}
                    className={className}
                    classNameSelect={classNameSelect}
                    hasSearch={hasSearch}
                    dossierData={stockManagementData}
                    getIconClass={(value) => this.getIconClass(value)}
                    onClickResetSearch={(value) => this.onClickResetSearch(value)}
                    onOrderChange={(value) => this.onOrderChange(value)}
                    onPageChange={(value) => this.onPageChange(value)}
                    page={page}
                    pages={pages}
                    count={count}
                    exportToExcel = {(value) => this.exportToExcel(value)}
                    combos={{ repairWorkshopsCombo, stagesCombo, dateTypeOptions: this.getDateTypeOptions() }}
                    change = { this.props.change }
                    initialize = {this.props.initialize}
                    actions = {{
                      fetchStagesCombo,
                      fetchUserRepairWorkshopsCombo
                    }}
                    formValues = {this.props.formValues?.stockManagement}
                  />
                </Tab>
                }
              </Tabs>
            </Row>
            {!hasSearch && this.state.selectedTab !== searchType.PLAN_LOGISTICS_PAP && (
              <Row>
                <Col sm={12} className="text-center">
                  <hr/>
                  <Button
                    id="btn_search"
                    type="submit"
                    className="btn-standard"
                  >
                    <i className="ico-search"/>
                    {t(`${tKey}ACTIONS.SEARCH_BUTTON`)}
                  </Button>
                  <Button
                    id="btn_reset"
                    onClick={(e) => this.handleClickReset(e)}
                    className={'btn-white btn-icon' + (this.state.disableResetButton ? ' btn-cancel' : ' btn-standard')}
                  >
                    <i className="ico-cancel"/> {t(`${tKey}ACTIONS.RESET_BUTTON`)}
                  </Button>
                  <br></br>
                  <br></br>
                </Col>
              </Row>
            )}
          </Form>
        </div>
      </div>
    )
  }
}

const validateFleetReportValues = (values, t) => {
  let errors = {}
  if (values) {
    errors.orderDateTo = validateDates(t, values.orderDateFrom, values.orderDateTo)
    errors.brandDischargeDateTo = validateDates(t, values.brandDischargeDateFrom, values.brandDischargeDateTo)
    errors.customerNeedDateTo = validateDates(t, values.customerNeedDateFrom, values.customerNeedDateTo)
    errors.shippingOrderDateTo = validateDates(t, values.shippingOrderDateFrom, values.shippingOrderDateTo)
    errors.campaignOutputDateTo = validateDates(t, values.campaignOutputDateFrom, values.campaignOutputDateTo)
    errors.destinationArrivalDateTo = validateDates(t, values.destinationArrivalDateFrom, values.destinationArrivalDateTo)
    errors.makerNumber = validateMinimalStringLength(t, values.makerNumber, 3)
    errors.rentalOrderNumber = validateMinimalStringLength(t, values.rentalOrderNumber, 3)
    errors.dniCif = validateMinimalStringLength(t, values.dniCif, 3)
    errors.entityName = validateMinimalStringLength(t, values.entityName, 3)
    errors.dniCifChild = validateMinimalStringLength(t, values.dniCifChild, 3)
    errors.entityChildName = validateMinimalStringLength(t, values.entityChildName, 3)
    errors.commercialCode = validateMinimalStringLength(t, values.commercialCode, 3)
    errors.deliveryCity = validateMinimalStringLength(t, values.deliveryCity, 3)
  }

  return errors
}

const validate = (values, { selectedTab, t }) => {
  const errors = {}
  if (values) {
    if (values.reportComercial) {
      if (values.reportComercial.dateFrom) { // && values.reportComercial.dateTo) {
        var dateFrom = convertStringToDate(values.reportComercial.dateFrom)
        var dateTo
        if (values.reportComercial.dateTo) {
          dateTo = convertStringToDate(values.reportComercial.dateTo)
        } else {
          dateTo = new Date()
        }

        if (dateFrom > dateTo) {
          errors.reportComercial = { dateFrom: t('SEARCH.CATEGORIES.REPORT.DATE_FROM_GREATER') }
        } else {
          if (differenceDaysDate(dateFrom, dateTo) > 60) {
            errors.reportComercial = { dateFrom: t('SEARCH.CATEGORIES.REPORT.DATE_MAX_60') }
          }
        }
      }
    }
    if (values.dossier && values.dossier.chassis && values.dossier.chassis.length < settings.chassisMinCharacter) {
      errors.dossier = {}
      errors.dossier.chassis = t('SEARCH.CATEGORIES.ERRORS.VINCHASIS').replace('##MIN_CHARACTERS##', settings.chassisMinCharacter)
    }
    if (values.vehicle && values.vehicle.chassis && values.vehicle.chassis.length < settings.chassisMinCharacter) {
      errors.vehicle = {}
      errors.vehicle.chassis = t('SEARCH.CATEGORIES.ERRORS.VINCHASIS').replace('##MIN_CHARACTERS##', settings.chassisMinCharacter)
    }
    const papServicesManager = values[`${formNames.PAP_SERVICES_MANAGER}`]
    if (papServicesManager) {
      let errorsOnPapServicesManager = {}
      if (settings.clientFieldValidation !== clientInformation.VGR && papServicesManager.vinchassis && papServicesManager.vinchassis.length < settings.chassisMinCharacter) {
        errorsOnPapServicesManager.vinchassis = t('SEARCH.CATEGORIES.ERRORS.VINCHASIS').replace('##MIN_CHARACTERS##', settings.chassisMinCharacter)
      }
      if (settings.clientFieldValidation === clientInformation.VGR && papServicesManager.vinchassis) {
        const validationVinChassis = validateVinChassis(papServicesManager.vinchassis, { t })
        if (!(Object.keys(validationVinChassis).length === 0)) {
          errorsOnPapServicesManager.vinchassis = validationVinChassis
        }
      }
      if (papServicesManager.dateFrom && papServicesManager.dateTo) {
        errorsOnPapServicesManager.dateTo = validateDates(t, papServicesManager.dateFrom, papServicesManager.dateTo)
      }
      errors[`${formNames.PAP_SERVICES_MANAGER}`] = errorsOnPapServicesManager
    }

    if (values.dossier) {
      errors.dossier = {}
      if (values.dossier && values.dossier.creationDateFrom && values.dossier.creationDateTo) {
        errors.dossier.creationDateTo = validateDates(t, values.dossier.creationDateFrom, values.dossier.creationDateTo)
      }
      if (values.dossier && values.dossier.orderDateFrom && values.dossier.orderDateTo) {
        errors.dossier.orderDateTo = validateDates(t, values.dossier.orderDateFrom, values.dossier.orderDateTo)
      }
      if (values.dossier && values.dossier.availabilityDateFrom && values.dossier.availabilityDateTo) {
        errors.dossier.availabilityDateTo = validateDates(t, values.dossier.availabilityDateFrom, values.dossier.availabilityDateTo)
      }
      if (values.dossier && values.dossier.vehicleLicensePlateDateFrom && values.dossier.vehicleLicensePlateDateTo) {
        errors.dossier.vehicleLicensePlateDateTo = validateDates(t, values.dossier.vehicleLicensePlateDateFrom, values.dossier.vehicleLicensePlateDateTo)
      }
    }

    if (values.servicePreparationDelivery && values.servicePreparationDelivery.requiredDateFrom && values.servicePreparationDelivery.requiredDateTo) {
      errors.servicePreparationDelivery = {}
      errors.servicePreparationDelivery.requiredDateTo = validateDates(t, values.servicePreparationDelivery.requiredDateFrom, values.servicePreparationDelivery.requiredDateTo)
    }

    if (values.charge && values.charge.chargeDateFrom && values.charge.chargeDateTo) {
      errors.charge = {}
      errors.charge.chargeDateTo = validateDates(t, values.charge.chargeDateFrom, values.charge.chargeDateTo)
    }
    if (values.fleetReport) {
      errors.fleetReport = validateFleetReportValues(values.fleetReport, t)
    }

    const stockManagement = values[`${formNames.STOCK_MANAGEMENT}`]
    if (stockManagement) {
      let errorsOnStockManagement = {}
      if (stockManagement.dateType && !stockManagement.dateFrom) {
        errorsOnStockManagement.dateFrom = t('GLOBAL.FORMS.REQUIRED')
      }
      if (stockManagement.dateFrom && stockManagement.dateTo) {
        errorsOnStockManagement.dateTo = validateDates(t, stockManagement.dateFrom, stockManagement.dateTo)
      }
      if (!stockManagement.repairWorkshops || stockManagement.repairWorkshops.length <= 0) {
        errorsOnStockManagement.repairWorkshops = t('GLOBAL.FORMS.REQUIRED')
      } else if (stockManagement.dossierNumber && stockManagement.dossierNumber.length < 3) {
        errorsOnStockManagement.dossierNumber = t('DOSSIER_COMPONENTS.VALIDATIONS.MIN_3_CHARACTER')
      }
      errors[`${formNames.STOCK_MANAGEMENT}`] = errorsOnStockManagement
    } else if (selectedTab === searchType.STOCK_MANAGEMENT) {
      errors.stockManagement = { repairWorkshops: t('GLOBAL.FORMS.REQUIRED') }
    }
  }

  return errors
}

export default reduxForm({
  form: 'dossierSearch',
  validate
})(DossierFinder)
