import React, { PureComponent } from 'react'
import { Tooltip, OverlayTrigger, /* Row, */ Button, Form, DropdownButton, Glyphicon } from 'react-bootstrap'
import { Field, reduxForm, reset } from 'redux-form'
import { fromCamelToUnderscore, convertStringToDate, convertUTCDateToLocalDate, getDateNowMaxDatePicker, formatDate } from '../../../util/utils'
import SimpleTablePage from '../../../containers/commons/general/SimpleTablePage'
import IDocCarPagination from '../../../components/commons/iDocCarPagination'
import { validateDate } from '../../../util/validationFunctions'
import { dossierTypesId } from '../../../constants/dossier/common/dossierTypes'
import CreateNewFleetModalPage from '../../../containers/management/CreateNewFleetModalPage'
import HistoricalFleetModalPage from '../../../containers/management/HistoricalFleetModalPage'
import FleetCreatedSelectChargeModalPage from '../../../containers/management/auxModals/FleetCreatedSelectChargeModalPage'
import { permissions } from '../../../constants/backendIds'
import { getSectionPermissions, getFieldsSectionPermissions } from '../../../util/permisionFunctions'
import { sectionPermissions } from '../../../constants/dossier/common/buttonsHeader'
import AddDossiersToFleetModalPage from '../../../containers/management/auxModals/AddDossiersToFleetModalPage'
import OperateFleetModalPage from '../../../containers/management/OperateFleetModalPage'
import DocumentsFleetModalPage from '../../../containers/management/DocumentsFleetModalPage'
import { replaceableFields } from '../../../constants/dossier/common/replaceableFields'
import EconomicFleetModalPage from '../../../containers/management/EconomicFleetModalPage'
import { dossierSubTypeId } from '../../../constants/dossier/common/dossierSubType'
import { dossierStagesBySubtype } from '../../../constants/dossier/common/dossierStages'
import ChargeDossiersModalPage from '../../../containers/management/auxModals/ChargeDossiersModalPage'
import MapSectionsManagement from '../../../_v2/util/map-sections-management'
import InputText from '../../../_v2/components/commons/form/InputText'
import InputSelect from '../../../_v2/components/commons/form/InputSelect'
import InputTreeSelect from '../../../_v2/components/commons/form/InputTreeSelect'
import InputDatePicker from '../../../_v2/components/commons/form/InputDatePicker'
import { showFilter, hideFiltersBtn, toggleFilterClass, getIconClass } from '../../../_v2/util/management/ManagementUtils'

class FleetsManager extends PureComponent {
  constructor () {
    super()
    this.state = {
      fields: [],
      filters: [],
      dynamicFilters: [],
      disableResetButton: false,
      displayBar: 'none',
      progress: 0,
      progressFail: 0,
      errors: [],
      maxBar: 0,
      showModalObservations: false,
      observations: '',
      showModalCreate: false,
      showModalOperate: false,
      fieldsConfiguration: {
        createFleet: { permission: null },
        editFleet: { permission: null },
        operateFleet: { permission: null },
        economicFleet: { permission: null },
        documentsFleet: { permission: null },
        viewFleet: { permission: null },
        deleteFleet: { permission: null },
        codeCommission: { permission: null }
      },
      maxiTable: false,
      hideFilters: false
    }
  }

  initializePermisions (props) {
    const section = props.sectionsGeneral.filter((tabConf) => tabConf.code === sectionPermissions.management)
    if (section.length > 0 && section[0] != null) {
      const newState = getSectionPermissions(section[0])
      let permissionState = this.state.sectionTabsConfiguration
      permissionState = { ...permissionState, ...newState }
      if (permissionState.fleet_management_section && permissionState.fleet_management_section.sectionFieldsConfiguration) {
        const fieldKeys = Object.keys(this.state.fieldsConfiguration)
        const permission = getFieldsSectionPermissions(permissionState.fleet_management_section.sectionFieldsConfiguration, fieldKeys, permissions.hidden)
        this.setState({ fieldsConfiguration: permission.fieldsConfiguration })
      }
    }
  }

  componentDidMount () {
    this.initializePermisions(this.props)
    this.props.actions.initializeSearch()
    this.props.actions.fetchDossierSubTypes(null, dossierTypesId.sales)
    this.props.actions.fetchOrganizedUnitsAcceptClientsCombo()
    this.props.actions.fetchOrganizedUnitsCombo()
  }

  submitSearchForm () {
    this.fetchTable()
    this.setState({ hideFilters: true })
  }

  fieldFill (field) {
    this.setState({ fields: { ...this.state.fields, ...field } })
  }

  extractMultipleComboStringArray (combo, filters, item) {
    let array = filters[item] ? filters[item].split(',').map(Number) : null
    const values = combo.filter(object => {
      return array ? array.some(selectedComboValue => object.id === selectedComboValue) : null
    })

    let value = values && this.formatMultipleValues(values)
    return value
  }

  formatMultipleValues (multiplecombovalues) {
    return multiplecombovalues
      .map((combovalue, index) => combovalue.value)
      .join(', ')
  }

  treeComboToArray (combo) {
    var values = []
    combo.map((item, index) => {
      values.push({ id: item.value, value: item.label })
      if (item.children && item.children.length > 0) {
        values = values.concat(this.treeComboToArray(item.children))
      }
    })
    return values
  }

  selectedFilters () {
    const filters = this.props.dynamicFilters
    const tKey = 'MANAGEMENT.FLEETS_TAB.'
    return Object.keys(filters)
      .map((item, index) => {
        let text = item
        let value = null
        const list = []

        switch (item) {
          case 'organizedUnits':
          {
            value = this.extractMultipleComboStringArray(this.treeComboToArray(this.props.combos.UOTreeCombo), filters, item)
            text = this.props.t(`${tKey}${'ORGANIZED_UNIT'}`)
            break }
          case 'dossierSubtypeId':
          { const selectedSubtype = this.props.combos.dossierSubTypesCombo.find(subtype => subtype.id === filters[item])
            text = this.props.t(`${tKey}${fromCamelToUnderscore('dossierSubtype').toUpperCase()}`)
            value = selectedSubtype && selectedSubtype.value
            break }
          default:
          {
            if (filters[item] instanceof Date) {
              value = formatDate(filters[item])
            } else {
              value = filters[item]
            }

            if (value) {
              text = this.props.t(`${tKey}${fromCamelToUnderscore(item).toUpperCase()}`)
            }
            break }
        }
        if (value) {
          list.push(<li key={index}>{text}: <strong>{value}</strong></li>)
        }

        return list
      })
  }

  onClickResetSearch (e) {
    if (e !== undefined) {
      e.preventDefault()
    }

    this.props.actions.modifyFleetManagementFilters()
  }

  handleClickReset (e) {
    if (e !== undefined) {
      e.preventDefault()
    }
    this.clearFields()
    this.props.actions.resetFleetManagementFilters()
    this.props.dispatch(reset('fleetsManager'))
  }

  exportData () {
    const tKey = 'MANAGEMENT.FLEETS_TAB.'
    let columnsExcel = []

    columnsExcel.push(this.props.t(`${tKey}CODE`))
    columnsExcel.push(this.props.t(`${tKey}DESCRIPTION`))
    columnsExcel.push(this.props.t(`${tKey}ORGANIZEDUNIT`))
    columnsExcel.push(this.props.t(`${tKey}DOSSIER_SUBTYPE`))
    columnsExcel.push(this.props.t(`${tKey}CLIENT`))
    columnsExcel.push(this.props.t(`${tKey}CREATION_DATE`))
    columnsExcel.push(this.props.t(`${tKey}NUM_DOSSIERS`))

    let cols = columnsExcel.join('$')

    let data = { userId: null, languageId: null, ...this.props.dynamicFilters, Columns: cols }
    this.props.actions.exportFleetsManagement(data, this.props.filters)
  }

  onOrderChange (e) {
    const newOrderBy = this.props.filters.orderBy.includes(`+${e.currentTarget.dataset.field}`) ? `-${e.currentTarget.dataset.field}` : `+${e.currentTarget.dataset.field}`
    this.fetchTable({ ...this.props.filters, orderBy: newOrderBy })
  }

  onPageChange (page) {
    this.fetchTable({ ...this.props.filters, page })
  }

  haveValues (fields) {
    for (let key in fields) {
      if (fields[key] !== null && fields[key] !== undefined && fields[key] !== '') {
        return true
      }
    }
    return false
  }

  fetchTable (filters) {
    let filtersToSend = filters || this.props.filters
    if (!this.haveValues(this.state.fields)) {
      this.props.actions.openModal('mustFillFilters')
    } else {
      let data = { userId: null, languageId: null, ...this.state.fields }
      data = this.setDataDates(data)
      this.props.actions.fetchFleetsManagement(data, filtersToSend)
    }
  }

  setDataDates (data) {
    if (data.creationDateFrom) {
      data.creationDateFrom = convertUTCDateToLocalDate(convertStringToDate(data.creationDateFrom))
    }
    if (data.creationDateTo) {
      data.creationDateTo = convertUTCDateToLocalDate(convertStringToDate(data.creationDateTo))
    }
    if (data.renewalDateFrom) {
      data.renewalDateFrom = convertUTCDateToLocalDate(convertStringToDate(data.renewalDateFrom))
    }
    if (data.renewalDateTo) {
      data.renewalDateTo = convertUTCDateToLocalDate(convertStringToDate(data.renewalDateTo))
    }
    return data
  }

  clearFields () {
    this.setState({ fields: {} })
  }

  validateDate (value, allValues, props) {
    const result = validateDate(value, props)
    return result === Object(result) ? undefined : result
  }

  openObservations (row) {
    this.props.actions.openModal('observations', null, null, null, null, row.observations)
  }

  closeObservationsModal () {
    this.setState({ showModalObservations: false, observations: '' })
  }

  createNewFleet () {
    this.setState({ showModalCreate: true })
    this.props.actions.openCreateEditFleetModal(true)
  }

  historical (row) {
    new Promise((resolve) => {
      this.props.actions.getFleetModel(row.fleetId, resolve)
    }).then((response) => {
      this.setState({ showModalCreate: true })
      this.props.actions.showHistoricalFleetModal(true, response)
    })
  }

  editFleet (row) {
    new Promise((resolve) => {
      this.props.actions.getFleetModel(row.fleetId, resolve)
    }).then((response) => {
      this.setState({ showModalCreate: true })
      this.props.actions.openCreateEditFleetModal(false, response)
    })
  }

  viewFleet (row) {
    new Promise((resolve) => {
      this.props.actions.getFleetModel(row.fleetId, resolve)
    }).then((response) => {
      this.setState({ showModalCreate: true })
      this.props.actions.openCreateEditFleetModal(false, response, true)
    })
  }

  operations (row) {
    new Promise((resolve) => {
      this.props.actions.getFleetModel(row.fleetId, resolve)
    }).then((response) => {
      this.setState({ showModalOperate: true })
      this.props.actions.openOperateFleetModal(response)
    })
  }

  financialAnalysis (row) {
    if (row.dossiers && row.dossiers > 0) {
      new Promise((resolve) => {
        this.props.actions.getDossierDetailsFleet(row.fleetId, resolve)
      }).then((response) => {
        let dossierFleet = response
        let dossierShowEconomic = this.checkShowEconomicDossiers(response)
        if (dossierShowEconomic && dossierShowEconomic.length > 0) {
          let dossierStageEconomic = this.checkStageForEconomicAnalysis(dossierShowEconomic, row.dossierSubtypeId)
          if (dossierStageEconomic && dossierStageEconomic.length > 0) {
            new Promise((resolve) => {
              this.props.actions.getFleetModel(row.fleetId, resolve)
            }).then((response) => {
              if (dossierStageEconomic.length < dossierFleet.length) {
                this.props.actions.openModal('noAllEconomicDosiersOnFleet', () => this.props.actions.openEconomicFleetModal(response, false, dossierStageEconomic), null, null, null, [{ id: '##NUM_OK##', value: dossierStageEconomic.length }, { id: '##NUM_TOTAL##', value: dossierFleet.length }], null)
              } else {
                this.props.actions.openEconomicFleetModal(response, false, dossierStageEconomic)
              }
            })
          } else {
            this.props.actions.openModal('noEconomicStageDossiersOnFleet')
          }
        } else {
          this.props.actions.openModal('noEconomicDossiersOnFleet')
        }
      })
    } else {
      this.props.actions.openModal('noDossiersOnFleet')
    }
  }

  documents (row) {
    new Promise((resolve) => {
      this.props.actions.getFleetModel(row.fleetId, resolve)
    }).then((response) => {
      this.setState({ showModalDocument: true })
      this.props.actions.openDocumentFleetModal(response, false, [])
    })
  }

  openDocumentsExterntal (fleet, dossiers) {
    this.props.actions.openDocumentFleetModal(fleet, true, dossiers)
  }

  deleteFleet (row) {
    this.props.actions.openModal('setDeleteFleetProcess', (deleteDossiers) => { this.props.actions.setDeleteFleetProcess(row.fleetId, deleteDossiers) }, null, null, null, [{ id: replaceableFields.fleetCode, value: row.fleetCode }])
  }

  checkShowEconomicDossiers (dossiers) {
    let showEconomicDossiers = []
    dossiers.forEach(dossier => {
      if (dossier.showEconomic) {
        showEconomicDossiers.push(dossier)
      }
    })
    return showEconomicDossiers
  }

  checkStageForEconomicAnalysis (dossiersArray, dossierSubtypeId) {
    let dossiers = []

    dossiersArray.forEach(item => {
      if (dossierSubtypeId === dossierSubTypeId.venta_vn) {
        if (item.stageId < dossierStagesBySubtype.saleVn.documentation) {
          dossiers.push(item)
        }
      }
      if (dossierSubtypeId === dossierSubTypeId.venta_vo) {
        if (item.stageId < dossierStagesBySubtype.saleVo.documentation) {
          dossiers.push(item)
        }
      }
    })

    return dossiers
  }

  toogleHideFilters () {
    this.setState({ hideFilters: !this.state.hideFilters })
  }

  toogleMaxiTable () {
    this.setState({ maxiTable: !this.state.maxiTable })
  }

  render () {
    const {
      t, className, /* classNameSelect, */ count, fleetsData, filters: { page, pages }, handleSubmit, hasSearch, combos: { dossierSubTypesCombo, UOTreeCombo }
    } = this.props
    const secMapManagement = MapSectionsManagement.find(function (s) { return s.page === 'fleetsManagerPage' })
    const tKey = 'MANAGEMENT.FLEETS_TAB.'
    const dKey = 'GLOBAL.TABLES.'
    const hideInnerMenu = (<Tooltip id="editProductTooltip">{`${t(dKey + (this.props.hideMenu ? 'SHOW_INNER_MENU' : 'HIDE_INNER_MENU'))}`}</Tooltip>)
    const hasResults = fleetsData && fleetsData.length > 0
    return (
      <>
        <div className="inner-results-panel">
          <div className="section-results-header">
            <div className="section-header-title">
              <OverlayTrigger placement="right" overlay={hideInnerMenu}>
                <a onClick={() => this.props.toogleInnerMenu()} className={this.props.hideMenu ? 'collapseBtn' : 'collapseBtn closeInnerMenu'}><i className={this.props.hideMenu ? 'iDocIcon-Open-inner-menu' : 'iDocIcon-Close-inner-menu'}></i></a>
              </OverlayTrigger>
              <h4>{t(`${secMapManagement.title}`)}
                <OverlayTrigger placement="left" overlay={<Tooltip id="editProductTooltip">{`${t((this.state.hideFilters ? 'GLOBAL.TABLES.SHOW_FILTERS' : 'GLOBAL.TABLES.HIDE_FILTERS'))}`}</Tooltip>}>
                  <a onClick={() => this.toogleHideFilters()} className={'toogleShowFilters ' + hideFiltersBtn(hasResults)}><i className={toggleFilterClass(hasResults, this.state.hideFilters)}></i></a>
                </OverlayTrigger>
              </h4>
              {this.state.fieldsConfiguration.createFleet && this.state.fieldsConfiguration.createFleet.permission === permissions.editable && (
                <a onClick={(event) => this.createNewFleet(event)} className="_Btn new"><i className="iDocIcon-file-fleets"></i>{t(`${tKey}CREATE_NEW_FLEET`)}</a>
              )}
            </div>
            <Form autoComplete="off" onSubmit={handleSubmit((values) => this.submitSearchForm(values))} className="">
              <div className={'search-form ' + (showFilter(hasResults, this.state.hideFilters) ? '' : 'hideFilters ')}>
                <Field
                  component={InputText}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  colSm={''}
                  colSmOffset={''}
                  id="fleetCode"
                  className="myClass"
                  name='fleetCode'
                  placeholder={t(`${tKey}CODE`)}
                  controlLabel={t(`${tKey}CODE`)}
                  type="text"
                  onInputChange={(val) => this.fieldFill({ fleetCode: val })}
                  customClass={this.state.fields.fleetCode ? className : ''}
                />
                <Field
                  component={InputTreeSelect}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="organizedUnit"
                  name='organizedUnit'
                  placeholder={t(`${tKey}ORGANIZED_UNIT`)}
                  controlLabel={t(`${tKey}ORGANIZED_UNIT`)}
                  onInputChange={(val) => this.fieldFill({ organizedUnits: val })}
                  multi={true}
                  valueKey="value"
                  labelKey="label"
                  childrenKey="children"
                  options={UOTreeCombo}
                />
                <Field
                  component={InputSelect}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="dossierSubtypeId"
                  name='dossierSubtypeId'
                  placeholder={t(`${tKey}DOSSIER_SUBTYPE`)}
                  controlLabel={t(`${tKey}DOSSIER_SUBTYPE`)}
                  sm={''}
                  options={dossierSubTypesCombo}
                  valueKey="id"
                  labelKey="value"
                  onInputChange={ (val) => { this.fieldFill({ dossierSubtypeId: val }) } }
                />
                <Field
                  component={InputText}
                  colWidht={50} // 25, 33, 50, 75, 100 default=25
                  id="client"
                  name='client'
                  placeholder={t(`${tKey}CLIENT`)}
                  controlLabel={t(`${tKey}CLIENT`)}
                  onInputChange={(val) => this.fieldFill({ client: val })}
                />
                <Field
                  component={InputText}
                  colWidht={50} // 25, 33, 50, 75, 100 default=25
                  id="finalClient"
                  name='finalClient'
                  placeholder={t(`${tKey}FINAL_CLIENT`)}
                  controlLabel={t(`${tKey}FINAL_CLIENT`)}
                  onInputChange={(val) => this.fieldFill({ finalClient: val })}
                />
                <Field
                  component={InputDatePicker}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="creationDateFrom"
                  name='creationDateFrom'
                  placeholder={t(`${tKey}CREATION_DATE_FROM`)}
                  controlLabel={t(`${tKey}CREATION_DATE_FROM`)}
                  validate={[this.validateDate]}
                  onInputChange={(val) => this.fieldFill({ creationDateFrom: val })}
                  maxDate={getDateNowMaxDatePicker()}
                />
                <Field
                  component={InputDatePicker}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="creationDateTo"
                  name='creationDateTo'
                  placeholder={t(`${tKey}CREATION_DATE_TO`)}
                  controlLabel={t(`${tKey}CREATION_DATE_TO`)}
                  validate={[this.validateDate]}
                  onInputChange={(val) => this.fieldFill({ creationDateTo: val })}
                  maxDate={getDateNowMaxDatePicker()}
                />
                <Field
                  component={InputDatePicker}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="renewalDateFrom"
                  name='renewalDateFrom'
                  placeholder={t(`${tKey}RENEWAL_DATE_FROM`)}
                  controlLabel={t(`${tKey}RENEWAL_DATE_FROM`)}
                  validate={[this.validateDate]}
                  onInputChange={(val) => this.fieldFill({ renewalDateFrom: val })}
                  maxDate={getDateNowMaxDatePicker()}
                />
                <Field
                  component={InputDatePicker}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="renewalDateTo"
                  name='renewalDateTo'
                  placeholder={t(`${tKey}RENEWAL_DATE_TO`)}
                  controlLabel={t(`${tKey}RENEWAL_DATE_TO`)}
                  validate={[this.validateDate]}
                  onInputChange={(val) => this.fieldFill({ renewalDateTo: val })}
                  maxDate={getDateNowMaxDatePicker()}
                />
                <div className='search-form-actions flexInLine al-R'>
                  <Button id="btn_search"
                    type="submit"
                    className="_Btn accept stk">
                    <i className="iDocIcon-search"/>
                    {t('SEARCH.ACTIONS.SEARCH_BUTTON')}
                  </Button>
                  <Button
                    id="btn_reset"
                    onClick={(e) => this.handleClickReset(e)}
                    className={'_Btn' + (this.props.pristine ? ' accept' : ' accept')}
                    disabled={this.props.pristine}
                  >
                    <i className="iDocIcon-clear-decline"/> {t('SEARCH.ACTIONS.RESET_BUTTON')}
                  </Button>
                  { hasSearch && count > 0 &&
                    <Button id="btn_export"
                      className="_Btn new o-icn"
                      onClick={(event) => this.exportData(event)}>
                      <i className="iDocIcon-file-excel"/>
                      {t('SEARCH.ACTIONS.EXPORT')}
                    </Button>
                  }
                </div>
              </div>
            </Form>
          </div>
          <div className="section-results-body">
            <div className={'searchResoultsContainer' + (this.state.maxiTable ? ' maxiTable' : '')}>
              {hasSearch && ([
                <div className="table-wrapper fleets" key="table-wrapper">
                  <ul className="list-inline"> {/* Active filter list */}
                    {this.selectedFilters().length === 0 ? <li>{t(`${tKey}NO_SELECTED_FILTERS`)}</li> : (this.selectedFilters())}
                  </ul>
                  {/* table options, maximize, etc.... */}
                  {hasResults &&
                  <div className='flexInLine al-R'>
                    <OverlayTrigger placement="left" overlay={<Tooltip id="editProductTooltip">{`${t(dKey + (this.state.maxiTable ? 'MINIMIZE_TABLE' : 'MAXIMIZE_TABLE'))}`}</Tooltip>}>
                      <a onClick={() => this.toogleMaxiTable()} className='_Btn accept stk o-icn maximizeBtn'><i className={(this.state.maxiTable ? 'iDocIcon-Minimize' : 'iDocIcon-Maximize')}></i></a>
                    </OverlayTrigger>
                  </div>}
                  {/* table */}
                  {hasResults ? [

                    <div className="table-responsive">
                      <SimpleTablePage
                        columns={[
                          <th key={0} data-field="fleetCode" onClick={(event) => this.onOrderChange(event)}>
                            {t(`${tKey}CODE`)}&nbsp;<i className={getIconClass('fleetCode', this.props.filters)}></i>
                          </th>,
                          <th key={1} data-field="description" onClick={(event) => this.onOrderChange(event)}>
                            {t(`${tKey}DESCRIPTION`)}&nbsp;<i className={getIconClass('description', this.props.filters)}></i>
                          </th>,
                          <th key={2} data-field="organizedUnit" onClick={(event) => this.onOrderChange(event)}>
                            {t(`${tKey}ORGANIZEDUNIT`)}&nbsp;<i className={getIconClass('organizedUnit', this.props.filters)}></i>
                          </th>,
                          <th key={3} data-field="clientName" onClick={(event) => this.onOrderChange(event)}>
                            {t(`${tKey}CLIENT`)}&nbsp;<i className={getIconClass('clientName', this.props.filters)}></i>
                          </th>,
                          <th key={4} data-field="dossierSubtype" onClick={(event) => this.onOrderChange(event)}>
                            {t(`${tKey}DOSSIER_SUBTYPE`)}&nbsp;<i className={getIconClass('dossierSubtype', this.props.filters)}></i>
                          </th>,
                          <th key={5} data-field="creationDate" onClick={(event) => this.onOrderChange(event)}>
                            {t(`${tKey}CREATION_DATE`)}&nbsp;<i className={getIconClass('creationDate', this.props.filters)}></i>
                          </th>,
                          <th key={6} data-field="dossiers" onClick={(event) => this.onOrderChange(event)}>
                            {t(`${tKey}NUM_DOSSIERS`)}&nbsp;<i className={getIconClass('dossiers', this.props.filters)}/>
                          </th>,
                          <th key={7} data-field="actions">{t(`${tKey}ACTIONS`)}&nbsp;</th>
                        ]}
                        rows={fleetsData && fleetsData.map((row, idx) => (
                          <tr key={idx}>
                            <td>
                              {row.fleetCode}
                            </td>
                            <td>
                              {row.description}
                            </td>
                            <td>
                              {row.organizedUnit}
                            </td>
                            <td>
                              {row.clientName}
                            </td>
                            <td>
                              {row.dossierSubtype}
                            </td>
                            <td>
                              {row.creationDate}
                            </td>
                            <td>
                              {row.dossiers}
                            </td>
                            <td className="actionsMenu see-more">
                              <DropdownButton
                                as={'ButtonGroup'}
                                key={1}
                                drop={'start'}
                                className={'_Btn accept stk o-icn'}
                                title={<i className="glyphicon glyphicon-option-vertical"/>}
                                id="dropdown-button-drop-start"
                              >
                                <li><Button id="btn_observations"
                                  className="btn-actions"
                                  onClick={() => this.openObservations(row)}>
                                  {t(`${tKey}OBSERVATIONS`)}
                                  <Glyphicon className='icon-action blue' glyph="comment"/>
                                </Button></li>
                                <li><Button id="btn_historical"
                                  className="btn-actions"
                                  onClick={() => this.historical(row)}>
                                  {t(`${tKey}HISTORICAL`)}
                                  <Glyphicon className='icon-action blue' glyph="time"/>
                                </Button></li>
                                {this.state.fieldsConfiguration.viewFleet && this.state.fieldsConfiguration.viewFleet.permission === permissions.editable && (
                                  <li><Button id="btn_edit"
                                    className="btn-actions"
                                    onClick={() => this.viewFleet(row)}>
                                    {t(`${tKey}VIEW`)}
                                    <Glyphicon className='icon-action blue' glyph="eye-open"/>
                                  </Button></li>)}
                                {this.state.fieldsConfiguration.editFleet && this.state.fieldsConfiguration.editFleet.permission === permissions.editable && (
                                  <li><Button id="btn_edit"
                                    className="btn-actions"
                                    onClick={() => this.editFleet(row)}>
                                    {t(`${tKey}EDIT`)}
                                    <Glyphicon className='icon-action blue' glyph="pencil"/>
                                  </Button></li>)}
                                {this.state.fieldsConfiguration.operateFleet && this.state.fieldsConfiguration.operateFleet.permission === permissions.editable && (
                                  <li>
                                    <Button id="btn_operations"
                                      className="btn-actions"
                                      onClick={() => this.operations(row)}>
                                      {t(`${tKey}OPERATE`)}
                                      <Glyphicon className='icon-action blue' glyph="wrench"/>
                                    </Button></li>)}
                                {this.state.fieldsConfiguration.economicFleet && this.state.fieldsConfiguration.economicFleet.permission === permissions.editable && (
                                  <li><Button id="btn_financialAnalysis"
                                    className="btn-actions"
                                    onClick={() => this.financialAnalysis(row)}>
                                    {t(`${tKey}FINANCIAL_ANALYSIS`)}
                                    <Glyphicon className='icon-action blue' glyph="euro"/>
                                  </Button></li>)}
                                {this.state.fieldsConfiguration.documentsFleet && this.state.fieldsConfiguration.documentsFleet.permission === permissions.editable && (
                                  <li><Button id="btn_documents"
                                    className="btn-actions"
                                    onClick={() => this.documents(row)}>
                                    {t(`${tKey}DOCUMENTS`)}
                                    <Glyphicon className='icon-action blue' glyph="duplicate"/>
                                  </Button></li>)}
                                {this.state.fieldsConfiguration.deleteFleet && this.state.fieldsConfiguration.deleteFleet.permission === permissions.editable && (
                                  <li><Button id="btn_delete"
                                    className="btn-actions"
                                    onClick={() => this.deleteFleet(row)}>
                                    {t(`${tKey}DELETE`)}
                                    <Glyphicon className='icon-action red' glyph="trash" />
                                  </Button></li>)}
                              </DropdownButton>
                            </td>
                          </tr>
                        ))}
                      />
                    </div>,
                    <div className="search-footer" key="search-footer">
                      <IDocCarPagination
                        id="btn_pag_sales"
                        page={page}
                        pagesCount={pages}
                        totalRows= {count}
                        onSelectAction={(page) => this.onPageChange(page)}
                      />
                    </div>]
                    : <p className="no_results">{t('GLOBAL.TABLES.NO_RESULTS')}</p>}
                </div>
              ])}
            </div>

          </div>
        </div>
        {(this.state.showModalCreate || this.state.showModalOperate) && (
          <>
            <FleetCreatedSelectChargeModalPage fetchFleets={() => this.haveValues(this.state.fields) ? this.fetchTable() : null}></FleetCreatedSelectChargeModalPage>
            <AddDossiersToFleetModalPage fetchFleets={() => this.haveValues(this.state.fields) ? this.fetchTable() : null}></AddDossiersToFleetModalPage>
            <HistoricalFleetModalPage></HistoricalFleetModalPage>
            <ChargeDossiersModalPage fieldsConfiguration={this.state.fieldsConfiguration}> </ChargeDossiersModalPage>
          </>
        )}
        <CreateNewFleetModalPage fetchFleets={() => this.haveValues(this.state.fields) ? this.fetchTable() : null} fieldsConfiguration={this.state.fieldsConfiguration}></CreateNewFleetModalPage>
        <OperateFleetModalPage fetchFleets={() => this.haveValues(this.state.fields) ? this.fetchTable() : null} fieldsConfiguration={this.state.fieldsConfiguration} openDocumentsExterntal={this.openDocumentsExterntal} checkStageForEconomicAnalysis ={this.checkStageForEconomicAnalysis} checkShowEconomicDossiers={this.checkShowEconomicDossiers}></OperateFleetModalPage>
        <DocumentsFleetModalPage fetchFleets={() => this.haveValues(this.state.fields) ? this.fetchTable() : null} fieldsConfiguration={this.state.fieldsConfiguration} ></DocumentsFleetModalPage>
        <EconomicFleetModalPage fetchFleets={() => this.haveValues(this.state.fields) ? this.fetchTable() : null} fieldsConfiguration={this.state.fieldsConfiguration} checkStageForEconomicAnalysis ={this.checkStageForEconomicAnalysis}></EconomicFleetModalPage>
      </>
    )
  }
}

const validate = (values, { t }, props) => {
  const errors = {}

  if (values && values.creationDateFrom && values.creationDateTo) {
    let dateTo = convertStringToDate(values.creationDateTo)
    let dateFrom = convertStringToDate(values.creationDateFrom)
    if (dateFrom > dateTo) {
      errors.creationDateTo = t('GLOBAL.FORMS.DATE_TO_NOT_LESS_THAN_DATE_FROM')
    }
  }

  if (values && values.renewalDateFrom && values.renewalDateTo) {
    let dateTo = convertStringToDate(values.renewalDateTo)
    let dateFrom = convertStringToDate(values.renewalDateFrom)
    if (dateFrom > dateTo) {
      errors.renewalDateTo = t('GLOBAL.FORMS.DATE_TO_NOT_LESS_THAN_DATE_FROM')
    }
  }

  return errors
}

export default reduxForm({
  form: 'fleetsManager',
  validate,
  destroyOnUnmount: false
})(FleetsManager)
