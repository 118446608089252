import notificationsActionTypes from '../../constants/actions/notifications/notifications'

export function fetchInitialNotificationsCounter () {
  return {
    type: notificationsActionTypes.FETCH_INITIAL_NOTIFICATIONS_COUNTER
  }
}

export function setNotificationsCounter (notificationCount) {
  return {
    type: notificationsActionTypes.SET_NOTIFICATIONS_COUNTER,
    notificationCount
  }
}

export function fetchDocumentNotifications () {
  return {
    type: notificationsActionTypes.FETCH_DOCUMENT_NOTIFICATIONS
  }
}

export function fetchDocumentNotificationsSuccess (documentNotifications) {
  return {
    type: notificationsActionTypes.FETCH_DOCUMENT_NOTIFICATIONS_SUCCESS,
    documentNotifications
  }
}

export function closeDocumentNotificationPopover () {
  return {
    type: notificationsActionTypes.CLOSE_DOCUMENT_NOTIFICATIONS_POPOVER
  }
}

export function fetchDossierNotifications () {
  return {
    type: notificationsActionTypes.FETCH_DOSSIER_NOTIFICATIONS
  }
}

export function fetchDossierNotificationsSuccess (dossierNotifications) {
  return {
    type: notificationsActionTypes.FETCH_DOSSIER_NOTIFICATIONS_SUCCESS,
    dossierNotifications
  }
}

export function closeDossierNotificationPopover () {
  return {
    type: notificationsActionTypes.CLOSE_DOSSIER_NOTIFICATIONS_POPOVER
  }
}

export function markDossierNotificationAsRead (notificationId) {
  return {
    type: notificationsActionTypes.MARK_DOSSIER_NOTIFICATION_AS_READ,
    notificationId
  }
}

export function markDossierNotificationAsReadSuccess () {
  return {
    type: notificationsActionTypes.MARK_DOSSIER_NOTIFICATION_AS_READ_SUCCESS
  }
}

export default {
  fetchInitialNotificationsCounter,
  setNotificationsCounter,
  fetchDocumentNotifications,
  fetchDocumentNotificationsSuccess,
  closeDocumentNotificationPopover,
  fetchDossierNotifications,
  fetchDossierNotificationsSuccess,
  closeDossierNotificationPopover,
  markDossierNotificationAsRead,
  markDossierNotificationAsReadSuccess
}
