import dossierActionTypes from '../../constants/actions/dossier/dossier'
import { refreshAuditInfo, fetchAuditInfoSucces, fetchAuditDetails, fetchAuditDetailsModal, fetchDocumentsGroupedAuditDetails, fetchDocumentsGroupedAuditDetailsModal } from '../../actions/audit/auditInfo'

export function fetchCampaignDossier (dossierId, resolve, reject, historical) {
  return {
    type: dossierActionTypes.FETCH_CAMPAIGN_DOSSIER,
    dossierId,
    resolve,
    reject,
    historical
  }
}
export function fetchCampaignClient (dossierId, resolve, reject, pristine) {
  return {
    type: dossierActionTypes.FETCH_CAMPAIGN_DOSSIER_CLIENT,
    dossierId,
    resolve,
    reject,
    pristine
  }
}
export function fetchCampaignVehicle (dossierId, resolve, reject, pristine) {
  return {
    type: dossierActionTypes.FETCH_CAMPAIGN_DOSSIER_VEHICLE,
    dossierId,
    resolve,
    reject,
    pristine
  }
}
export function fetchCampaignDossierSuccess (dossier, checkListHeader) {
  return {
    type: dossierActionTypes.FETCH_CAMPAIGN_DOSSIER_SUCCESS,
    dossier,
    checkListHeader
  }
}

export function saveCampaignDossier (campaignDossier) {
  return {
    type: dossierActionTypes.SAVE_CAMPAIGN_DOSSIER,
    campaignDossier
  }
}

export function campaignReject (comment, resolve, reject, pristine) {
  return {
    type: dossierActionTypes.CAMPAIGN_REJECT,
    comment,
    resolve,
    reject,
    pristine
  }
}

export function campaignGenDelivery () {
  return {
    type: dossierActionTypes.CAMPAIGN_GEN_DELIVERY
  }
}

export function restartCampaignState () {
  return {
    type: dossierActionTypes.RESTART_CAMPAIGN_STATE
  }
}

export function fetchDossierRelatedDossiersSuccess (dossierRelatedDossiers) {
  return {
    type: dossierActionTypes.FETCH_DOSSIER_RELATED_DOSSIERS_SUCCESS,
    dossierRelatedDossiers
  }
}

export function campaignSend (pristine) {
  return {
    type: dossierActionTypes.CAMPAIGN_SEND,
    pristine
  }
}

export function campaignApprove (pristine) {
  return {
    type: dossierActionTypes.CAMPAIGN_APPROVE,
    pristine
  }
}

export function campaignWarningSave (reset, router, nextLocation, pristine) {
  return {
    type: dossierActionTypes.CAMPAIGN_WARNING_SAVE,
    reset,
    router,
    nextLocation,
    pristine
  }
}
export function fetchCampaignVehicleHistorical (dossierId, resolve, reject) {
  return {
    type: dossierActionTypes.FETCH_CAMPAIGN_DOSSIER_VEHICLE_HISTORICAL,
    dossierId,
    resolve,
    reject
  }
}

export function fetchCampaignClientHistorical (dossierId, resolve, reject) {
  return {
    type: dossierActionTypes.FETCH_CAMPAIGN_DOSSIER_CLIENT_HISTORICAL,
    dossierId,
    resolve,
    reject
  }
}

export function anulateCampaign (dossierId) {
  return {
    type: dossierActionTypes.ANULATE_CAMPAIGN,
    dossierId
  }
}

export function fetchCampaignDetails (dossierId, detailsId) {
  return {
    type: dossierActionTypes.FETCH_CAMPAIGN_DETAILS,
    dossierId,
    detailsId
  }
}

export function fetchCampaignDetailsSucces (data) {
  return {
    type: dossierActionTypes.FETCH_CAMPAIGN_DETAILS_SUCCESS,
    data
  }
}

export function fetchCampaignActive (active) {
  return {
    type: dossierActionTypes.ACTIVE_CAMPAIGN,
    active
  }
}

export function campaignvalidate (dossierId) {
  return {
    type: dossierActionTypes.VALIDATE_CAMPAIGN,
    dossierId
  }
}

export function campaignDenie (dossierId, rejectReason, resolve, reject) {
  return {
    type: dossierActionTypes.DENIE_CAMPAIGN,
    dossierId,
    rejectReason,
    resolve,
    reject
  }
}

export function campaignReturn (dossierId) {
  return {
    type: dossierActionTypes.RETURN_CAMPAIGN,
    dossierId
  }
}

export default {
  fetchCampaignDossier,
  fetchCampaignDossierSuccess,
  saveCampaignDossier,
  campaignReject,
  campaignGenDelivery,
  campaignSend,
  campaignApprove,
  restartCampaignState,
  fetchDossierRelatedDossiersSuccess,
  campaignWarningSave,
  refreshAuditInfo,
  fetchAuditInfoSucces,
  fetchCampaignClient,
  fetchCampaignVehicle,
  fetchCampaignVehicleHistorical,
  fetchCampaignClientHistorical,
  fetchAuditDetails,
  fetchAuditDetailsModal,
  anulateCampaign,
  fetchCampaignDetails,
  fetchCampaignDetailsSucces,
  fetchCampaignActive,
  campaignvalidate,
  campaignDenie,
  campaignReturn,
  fetchDocumentsGroupedAuditDetails,
  fetchDocumentsGroupedAuditDetailsModal
}
