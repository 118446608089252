import { put, call, select, takeEvery } from 'redux-saga/effects'
import { getAuth } from '../../../selectors/access/auth'
import { showLoader, hideLoader } from '../../../actions/common.js'
import {
  fetchSaleTypeListSuccess,
  openSaleTypeModalSuccess,
  saveSaleTypeModalSuccess,
  openDuplicateSaleTypeModalSuccess
} from '../../../actions/masters/masters'
import { handleError } from '../../errors/errorManager'
import { getFilterSaleType } from '../../../selectors/masters/filterSaleType'
import getSaleTypeList from '../../../services/saleType/getSaleTypeList'
import mastersActionTypes from '../../../constants/actions/masters/masters'
import getLanguageListComboService from '../../../services/language/getLanguageList'
import getSaleType from '../../../services/saleType/getSaleType'
import { yesNoModal } from '../../modalsListeners/yesNoModal'
import insertSaleType from '../../../services/saleType/insertSaleType'
import insertSaleTypeCopy from '../../../services/saleType/insertSaleTypeCopy'
import updateSaleType from '../../../services/saleType/updateSaleType'
import deleteSaleType from '../../../services/saleType/deleteSaleType'
import canDeleteSaleType from '../../../services/saleType/canDeleteSaleType'
import recoverSaleType from '../../../services/saleType/recoverSaleType'

export function * fetchSaleTypeList ({ filter }) {
  try {
    if (filter === null || filter === undefined) {
      filter = yield filter || select(getFilterSaleType)
    }
    yield put(showLoader())
    const auth = yield select(getAuth)
    const result = yield call(getSaleTypeList, auth.token, filter)
    yield put(fetchSaleTypeListSuccess(result, filter))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchfetchSaleTypeList () {
  yield takeEvery(mastersActionTypes.FETCH_SALE_TYPE_LIST, fetchSaleTypeList)
}

export function * fetchOpenSaleTypeModal ({ saleTypeId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const languageList = yield call(getLanguageListComboService, auth.token)
    if (saleTypeId === null || saleTypeId === undefined) {
      yield put(openSaleTypeModalSuccess(null, null, languageList))
    } else {
      const result = yield call(getSaleType, auth.token, saleTypeId)
      yield put(openSaleTypeModalSuccess(saleTypeId, result, languageList))
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchfetchOpenSaleTypeModal () {
  yield takeEvery(mastersActionTypes.OPEN_SALE_TYPE_MODAL, fetchOpenSaleTypeModal)
}

export function * updateOrInsertSaleType ({ saleType, hasChanges, isCopy }) {
  try {
    let confirmed
    if (hasChanges || isCopy) {
      confirmed = yield call(yesNoModal, 'confirmUpdateSaleType')
    } else {
      confirmed = true
    }
    if (confirmed) {
      yield put(showLoader())
      const auth = yield select(getAuth)
      if (isCopy) {
        yield call(insertSaleTypeCopy, auth.token, saleType)
      } else {
        if (saleType.saleTypeId === null) {
          yield call(insertSaleType, auth.token, saleType)
        } else {
          yield call(updateSaleType, auth.token, saleType)
        }
      }

      const filter = yield filter || select(getFilterSaleType)
      const result = yield call(getSaleTypeList, auth.token, filter)
      yield put(fetchSaleTypeListSuccess(result, filter))
      yield put(saveSaleTypeModalSuccess())
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchUpdateSaleType () {
  yield takeEvery(mastersActionTypes.SAVE_SALE_TYPE, updateOrInsertSaleType)
}

export function * deleteSaleTypeModal ({ saleTypeId }) {
  try {
    const auth = yield select(getAuth)
    const canDelete = yield call(canDeleteSaleType, auth.token, saleTypeId)
    if (canDelete) {
      const confirmed = yield call(yesNoModal, 'deleteSaleTypeModal')
      if (confirmed) {
        yield put(showLoader())
        const filter = yield filter || select(getFilterSaleType)
        yield call(deleteSaleType, auth.token, saleTypeId)
        const result = yield call(getSaleTypeList, auth.token, filter)
        yield put(fetchSaleTypeListSuccess(result, filter))
      }
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDeleteSaleTypeModal () {
  yield takeEvery(mastersActionTypes.DELETE_SALE_TYPE_MASTERS, deleteSaleTypeModal)
}

export function * recoverSaleTypeModal ({ saleTypeId }) {
  try {
    const confirmed = yield call(yesNoModal, 'recoverSaleTypeModal')
    if (confirmed) {
      yield put(showLoader())
      const auth = yield select(getAuth)
      yield call(recoverSaleType, auth.token, saleTypeId)
      const filter = yield filter || select(getFilterSaleType)
      const result = yield call(getSaleTypeList, auth.token, filter)
      yield put(fetchSaleTypeListSuccess(result, filter))
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchRecoverSaleTypeModal () {
  yield takeEvery(mastersActionTypes.RECOVER_SALE_TYPE, recoverSaleTypeModal)
}

export function * fetchOpenDuplicateSaleTypeModal ({ saleTypeId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const languageList = yield call(getLanguageListComboService, auth.token)
    yield put(openDuplicateSaleTypeModalSuccess(saleTypeId, languageList))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchOpenDuplicateSaleTypeModal () {
  yield takeEvery(mastersActionTypes.OPEN_DUPLICATE_SALE_TYPE_MODAL, fetchOpenDuplicateSaleTypeModal)
}
