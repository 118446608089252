import React, { PureComponent } from 'react'
import { reduxForm, Field } from 'redux-form'
import { Row, Col, Table, Button } from 'react-bootstrap'
import IDocCarPagination from '../../commons/iDocCarPagination'
import InputDatePicker from '../../commons/form/InputDatePicker'
import InputSelect from '../../commons/form/InputSelect'
import moment from 'moment'
import { formatMoney } from '../../../util/utils'

class ReceiptReport extends PureComponent {
  constructor () {
    super()
    this.state = {
      filter: {
        searchSociety: null,
        searchDatePrinting: null
      }
    }
  }

  searchReceipt () {
    this.props.actions.fetchReceiptList({ ...this.props.filter, ...this.state.filter, page: 1 })
  }

  downloadReceipt () {
    this.props.actions.downloadReceiptList({ ...this.props.filter, ...this.state.filter, page: 1 })
  }

  componentDidMount () {
    this.props.actions.fetchBrands()
    this.searchReceipt()
  }

  handleSocietyChange (searchSociety) {
    if (this.state.filter.searchSociety !== searchSociety) {
      this.setState({ filter: { ...this.state.filter, searchSociety: searchSociety } })
    }
  }

  handleDatePrintingChange (searchDatePrinting) {
    if (this.state.filter.searchDatePrinting !== searchDatePrinting && searchDatePrinting !== null) {
      const [dd, mm, yyyy] = searchDatePrinting.split('/')
      this.setState({ filter: { ...this.state.filter, searchDatePrinting: new Date(`${yyyy}-${mm}-${dd}T00:00:01.000Z`) } })
    } else {
      this.setState({ filter: { ...this.state.filter, searchDatePrinting: null } })
    }
  }

  render () {
    const {
      t, receiptList, pagesCount, filter, receiptCount,
      filter: { page },
      actions: { fetchReceiptList },
      combos: { lowestOrganizedUnitsCombo }
    } = this.props
    const tKey = 'REPORTS.'
    return (
      <div className="admin-wrapper">
        <Row className="section-users-header">
          <Col sm={12}>
            <div className="header-panel">
              <h4>{t(`${tKey}RECEIPT.RECEIPT_TITLE`)}</h4>
            </div>
          </Col>
        </Row>
        <Row>
          <div className="search-form">
            <span className="title-search"><i className="ico-search" /> {t(`${tKey}RECEIPT.SEARCH_TITLE`)}</span>
            <Row>
              <Field
                colSm={3}
                id="society"
                name="filters.society"
                controlLabel={t(`${tKey}RECEIPT.SOCIETY`)}
                placeholder={t(`${tKey}RECEIPT.SOCIETY`)}
                component={InputSelect}
                options={lowestOrganizedUnitsCombo}
                valueKey="id"
                labelKey="value"
                onInputChange={(value) => this.handleSocietyChange(value)}
              />
              <Field
                id="initialdate"
                name="filters.initialdate"
                colSm={3}
                controlLabel={t(`${tKey}RECEIPT.INITIAL_ORDER_DATE`)}
                placeholder={t(`${tKey}RECEIPT.INITIAL_ORDER_DATE`)}
                component={InputDatePicker}
                onInputChange={(value) => this.handleDatePrintingChange(value)}
              />
              <Col sm={3} className="btn-wrapper">
                <Button
                  className={'btn-white btn-icon btn-input btn-white search-button'}
                  style={{ width: '100%' }}
                  onClick={() => this.searchReceipt()}>
                  <i className="ico-search" />
                  {t(`${tKey}SEARCH`)}
                </Button>
              </Col>
            </Row>
          </div>
        </Row>
        <Col>
          <Button
            className={'btn-white btn-icon btn-input btn-white'}
            style={{ float: 'right' }}
            onClick={() => this.downloadReceipt()}>
            {t(`${tKey}DOWNLOAD`)}
          </Button>
        </Col>
        <Col sm={12} className="col-row  section-users-body">
          <Table className="editable-table admin-users" hover responsive>
            <thead>
              <tr>
                <th>
                  {t(`${tKey}RECEIPT.DOSSIER_SALE`)}&nbsp;
                </th>
                <th>
                  {t(`${tKey}RECEIPT.DATE_PRINTING`)}&nbsp;
                </th>
                <th>
                  {t(`${tKey}RECEIPT.USER`)}&nbsp;
                </th>
                <th>
                  {t(`${tKey}RECEIPT.NRECEIPT`)}&nbsp;
                </th>
                <th>
                  {t(`${tKey}RECEIPT.AMOUNT`)}&nbsp;
                </th>
                <th>
                  {t(`${tKey}RECEIPT.SOCIETY`)}&nbsp;
                </th>
                <th>
                  {t(`${tKey}RECEIPT.DATE`)}&nbsp;
                </th>
                <th>
                  {t(`${tKey}RECEIPT.BRAND`)}&nbsp;
                </th>
                <th>
                  {t(`${tKey}RECEIPT.MODEL`)}&nbsp;
                </th>
                <th>
                  {t(`${tKey}RECEIPT.VINCHASSIS`)}&nbsp;
                </th>
                <th>
                  {t(`${tKey}RECEIPT.LICENSEPLATE`)}&nbsp;
                </th>
              </tr>
            </thead>
            <tbody>
              {receiptList && receiptList.map((receipt, idx) => {
                return (
                  <tr key={idx} className = "">
                    <td>{receipt.dossierNumber}</td>
                    <td>{moment(receipt.lastPrinted).format('DD/MM/YYYY')}</td>
                    <td>{receipt.userName}</td>
                    <td>{receipt.number}</td>
                    <td>{formatMoney(receipt.amount)}</td>
                    <td>{receipt.organizedUnitName}</td>
                    <td>{receipt.creationDate}</td>
                    <td>{receipt.brandName}</td>
                    <td>{receipt.model}</td>
                    <td>{receipt.vinChassis}</td>
                    <td>{receipt.licensePlate}</td>
                  </tr>)
              })
              }
              {(receiptList === null || receiptList === undefined || receiptList.length === 0) &&
            <tr>
              <td colSpan={12}><h2>{t(`${tKey}RESULTS_NOT_FOUND`)}</h2></td>
            </tr>}
            </tbody>
          </Table>
          <div className="section-users-footer">
            <nav key={1} className="text-center">
              <IDocCarPagination
                id="btn_pag_sales"
                page={page}
                pagesCount={pagesCount}
                totalRows={receiptCount}
                onSelectAction={(value) => fetchReceiptList({ ...filter, ...this.state.filter, page: value })}
              />
            </nav>
          </div>
        </Col>
      </div>
    )
  }
}

export default reduxForm({
  form: 'listReceipt'
})(ReceiptReport)
