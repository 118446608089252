import landingAccessActions from '../../../constants/actions/dossier/common/landingAccess'

export function initialState () {
  return {
    docList: null,
    allAvailableDocs: null
  }
}

function getAllDocumentLandingRequestSuccess (state, { docList }) {
  return {
    ...state,
    allAvailableDocs: docList
  }
}

function getDocumentLandingRequestSuccess (state, { landingAccessInfo }) {
  return {
    ...state,
    ...landingAccessInfo
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case landingAccessActions.GET_ALL_AVAILABLE_DOCUMENT_LANDING_REQUEST_SUCCESS:
      return getAllDocumentLandingRequestSuccess(state, action)
    case landingAccessActions.GET_DOCUMENT_LANDING_REQUEST_SUCCESS:
      return getDocumentLandingRequestSuccess(state, action)
    default:
      return state
  }
}
