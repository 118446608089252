import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getFormValues } from 'redux-form'
import SearchByPapForm from '../../components/management/SearchByPapForm'
import { saveServicesAvailabilityDate } from '../../../actions/dossier/serviceDossier'
import { translate } from 'react-polyglot'
import { setFilters, resetFilters, fetchPapServicesFromManager, fetchPapServicesDownloadFromManager } from '../../../actions/search/search'
import { openModal } from '../../../actions/common'

export function mapStateToProps (state) {
  return {
    ...state.search,
    formValues: getFormValues('SearchByPapForm')(state)
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      resetFilters,
      setFilters,
      saveServicesAvailabilityDate,
      fetchPapServicesFromManager,
      fetchPapServicesDownloadFromManager,
      openModal
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(SearchByPapForm))
