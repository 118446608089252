import { call, put, takeEvery, select } from 'redux-saga/effects'
import dossierActionsTypes from '../../../constants/actions/dossier/dossier'
import { change, initialize } from 'redux-form'
import messageModalActions from '../../../actions/modals/messageModal'
import { handleError } from '../../errors/errorManager'
import { dossierTypes as dossierTypesConstant } from '../../../constants/dossier/common/dossierTypes'

export function * fetchAmountTaxModal ({ ivaType, amountWithoutTax, pristine, dossierType }) {
  try {
    let formName, formDossier
    if (dossierType === dossierTypesConstant.fleetEconomicPlan) {
      formName = 'economicPlanModal'
    } else {
      formName = 'salesDossier'
    }

    if (ivaType) yield put(change(formName, 'amountTaxModal.ivaType', ivaType))
    if (amountWithoutTax) yield put(change(formName, 'amountTaxModal.amountWithoutTax', amountWithoutTax))

    if (dossierType === dossierTypesConstant.fleetEconomicPlan) {
      formDossier = JSON.parse(JSON.stringify(yield select(state => state.form.economicPlanModal.values)))
    } else {
      formDossier = JSON.parse(JSON.stringify(yield select(state => state.form.salesDossier.values)))
    }

    if (pristine) {
      yield put(initialize(formName, formDossier))
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.SALES_DOSSIER.ERROR_AMOUNT_TAX_MODAL_DATA', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    yield call(handleError, { error, customHandler })
  }
}

export function * watchFetchAmountTaxModal () {
  yield takeEvery(dossierActionsTypes.FETCH_AMOUNT_TAX_MODAL, fetchAmountTaxModal)
}
