export const managementTabCode = {
  FLEETS: 'fleets',
  FLEETS_MANAGEMENT: 'fleetsManagement',
  UNATTENDED_PROCESS: 'unattendedProcess',
  FLEETS_MANAGEMENT_SECTION: 'fleet_management_section',
  SELF_SUPPLT_SECTION: 'self_supply_section',
  ADMIN_AUDIT: 'admin_audit',
  ADMIN_AUDITS: 'admin_audits',
  SEARCH_DOCUMENTAL: 'search_documental'
}

export const managementTab = {
  FLEETS: 1,
  FLEETS_MANAGEMENT: 2,
  UNATTENDED_PROCESS: 3,
  FLEETS_MANAGEMENT_SECTION: 4,
  SELF_SUPPLT_SECTION: 5,
  ADMIN_AUDIT: 6,
  ADMIN_AUDITS: 7,
  SEARCH_DOCUMENTAL: 8
}
