import React, { PureComponent } from 'react'
import { reduxForm, Field } from 'redux-form'
import { Row, Col, Table, Button, Clearfix, Tooltip, OverlayTrigger } from 'react-bootstrap'
import IDocCarPagination from '../../commons/iDocCarPagination'
import InputDatePicker from '../../commons/form/InputDatePicker'
import InputSelect from '../../commons/form/InputSelect'
import UsageReportModal from './UsageReportModal'
import { formatDate } from '../../../util/utils'

class UsageReport extends PureComponent {
  constructor () {
    super()
    this.state = {
      showModal: false,
      searched: false,
      hasFilters: false,
      filter: {
        searchOrganizedUnit: [],
        searchProcess: [],
        searchDateInitial: null,
        searchDateEnd: null,
        searchUser: null
      }
    }
  }

  searchUsage () {
    if (!this.state.filter.searchOrganizedUnit || this.state.filter.searchOrganizedUnit.length === 0) {
      this.props.actions.openModal('ORGANIZED_UNIT_MANDATORY')
      return
    }
    if (this.state.filter.searchDateInitial && this.state.filter.searchDateEnd &&
      new Date(this.state.filter.searchDateInitial) > new Date(this.state.filter.searchDateEnd)) {
      this.props.actions.openModal('DATE_TO_BIGGER_THAN_DATE_FROM')
      return
    }
    this.setState({ searched: true })
    this.props.actions.fetchUsageList({ ...this.props.filter, ...this.state.filter, page: 1 })
  }

  downloadUsageList () {
    const columns = [
      this.props.t('REPORTS.USAGE.PROCESS'),
      this.props.t('REPORTS.USAGE.AMOUNT')
    ]
    this.props.actions.downloadUsageList({ ...this.props.filter, ...this.state.filter, page: this.props.filter.page, columnNames: columns })
  }

  componentDidMount () {
    this.props.actions.fetchUserCombo()
    this.props.actions.fetchUsageProcessTypesCombo()
    this.props.actions.fetchOrganizedUnitsAcceptClientsCombo()

    if (this.props.dynamicFilters && this.props.dynamicFilters.processTypeId) {
      this.setState({ searched: true, hasFilters: true, showModal: true, filter: { ...this.props.dynamicFilters } })
      this.props.actions.fetchUsageList({ ...this.props.dynamicFilters })
      this.props.change('filters.OrganizedUnitId', this.props.dynamicFilters.searchOrganizedUnit)
      this.props.change('filters.processId', this.props.dynamicFilters.searchProcess)
      this.props.change('filters.dateFrom', this.props.dynamicFilters.searchDateInitial && formatDate(this.props.dynamicFilters.searchDateInitial.split('T')[0]))
      this.props.change('filters.dateTo', this.props.dynamicFilters.searchDateEnd && formatDate(this.props.dynamicFilters.searchDateEnd.split('T')[0]))
      this.props.change('filters.userId', this.props.dynamicFilters.searchUser)
    } else {
      this.props.actions.clearSearch()
    }
  }

  handleOrganizedUnitChange (searchOrganizedUnit) {
    this.clearSearchResultList()
    if (this.state.filter.searchOrganizedUnit !== searchOrganizedUnit) {
      this.setState({ filter: { ...this.state.filter, searchOrganizedUnit: searchOrganizedUnit }, searched: false }, () => this.checkFilters())
    }
  }

  handleProcessChange (searchProcess) {
    this.clearSearchResultList()
    if (this.state.filter.searchProcess !== searchProcess) {
      this.setState({ filter: { ...this.state.filter, searchProcess: searchProcess }, searched: false }, () => this.checkFilters())
    }
  }

  handleDateInitialChange (searchDateInitial) {
    this.clearSearchResultList()
    if (this.state.filter.searchDateInitial !== searchDateInitial && searchDateInitial != null) {
      const [dd, mm, yyyy] = searchDateInitial.split('/')
      this.setState({ filter: { ...this.state.filter, searchDateInitial: `${yyyy}-${mm}-${dd}T00:00:01.000Z` }, searched: false }, () => this.checkFilters())
    } else {
      this.setState({ filter: { ...this.state.filter, searchDateInitial: null }, searched: false }, () => this.checkFilters())
    }
  }

  handleDateEndChange (searchDateEnd) {
    this.clearSearchResultList()
    if (this.state.filter.searchDateEnd !== searchDateEnd && searchDateEnd != null) {
      const [dd, mm, yyyy] = searchDateEnd.split('/')
      this.setState({ filter: { ...this.state.filter, searchDateEnd: `${yyyy}-${mm}-${dd}T23:59:59.000Z` }, searched: false }, () => this.checkFilters())
    } else {
      this.setState({ filter: { ...this.state.filter, searchDateEnd: null }, searched: false }, () => this.checkFilters())
    }
  }

  handleUserChange (searchUser) {
    this.clearSearchResultList()
    if (this.state.filter.searchUser !== searchUser) {
      this.setState({ filter: { ...this.state.filter, searchUser: searchUser }, searched: false }, () => this.checkFilters())
    }
  }

  openHideModal (id) {
    this.setState({ showModal: !this.state.showModal })
    if (id) {
      this.props.actions.fetchUsageInfo({ ...this.props.filter, ...this.state.filter, processTypeId: id, page: 1 })
    } else {
      this.props.actions.clearDynamicFilters()
    }
  }

  getIconClass (field) {
    if (this.props.filter && this.props.filter.orderBy.includes(field)) {
      return this.props.filter.orderBy.includes('-') ? 'ico-sort-selected-asc' : 'ico-sort-selected-desc'
    } else {
      return 'ico-sort'
    }
  }

  onOrderChange (e) {
    if (this.props.filter) {
      const newOrderBy = this.props.filter.orderBy.includes(`+${e.currentTarget.dataset.field}`) ? `-${e.currentTarget.dataset.field}` : `+${e.currentTarget.dataset.field}`
      this.props.actions.fetchUsageList({ ...this.props.filter, ...this.state.filter, orderBy: newOrderBy })
    }
  }

  resetFilters () {
    this.clearSearchResultList()
    this.props.initialize({ filters: {} })
    this.setState({
      searched: false,
      hasFilters: false,
      filter: {
        searchOrganizedUnit: [],
        searchProcess: [],
        searchDateInitial: null,
        searchDateEnd: null,
        searchUser: null
      }
    })
  }

  checkFilters () {
    const newCheckFilters = this.state.filter.searchOrganizedUnit?.length > 0 || this.state.filter.searchProcess?.length > 0 ||
      this.state.filter.searchDateInitial || this.state.filter.searchDateEnd || this.state.filter.searchUser
    this.setState({ hasFilters: newCheckFilters })
  }

  clearSearchResultList () {
    if (this.props.hasSearch) {
      this.props.actions.clearSearchResultList()
    }
  }

  render () {
    const {
      t, usageList, pagesCount, filter, usageCount, usageModal, hasSearch,
      filter: { page },
      actions: { fetchUsageList, fetchUsageInfo, downloadUsageInfo },
      combos: { usersCombo, UOAcceptClientsCombo, usageProcessTypesCombo }
    } = this.props
    const tKey = 'REPORTS.'
    const setOpenTooltip = (<Tooltip id="setActiveTooltip">{t(`${tKey}USAGE.ACTION_OPEN_TOOLTIP`)}</Tooltip>)

    return (
      <div className="admin-wrapper">
        <UsageReportModal
          t={t}
          showModal={this.state.showModal}
          actions={{
            fetchUsageInfo,
            downloadUsageInfo,
            handleHide: () => this.openHideModal(null)
          }}
          filter={this.state.filter}
          modalInfo={usageModal}
        />
        <Row className="section-users-header">
          <Col sm={12}>
            <div className="header-panel">
              <h4>{t(`${tKey}USAGE.USAGE_TITLE`)}</h4>
            </div>
          </Col>
        </Row>
        <Row>
          <div className="search-form">
            <span className="title-search"><i className="ico-search" /> {t(`${tKey}USAGE.SEARCH_TITLE`)}</span>
            <Row>
              <Field
                colSm={3}
                id="OrganizedUnitId"
                name="filters.OrganizedUnitId"
                controlLabel={t(`${tKey}USAGE.ORGANIZED_UNIT`)}
                placeholder={t(`${tKey}USAGE.ORGANIZED_UNIT`)}
                component={InputSelect}
                options={UOAcceptClientsCombo}
                multi={true}
                valueKey="id"
                labelKey="value"
                onInputChange={(value) => this.handleOrganizedUnitChange(value)}
              />
              <Field
                colSm={3}
                id="processId"
                name="filters.processId"
                controlLabel={t(`${tKey}USAGE.PROCESS`)}
                placeholder={t(`${tKey}USAGE.PROCESS`)}
                component={InputSelect}
                multi={true}
                valueKey="id"
                labelKey="name"
                options={usageProcessTypesCombo}
                onInputChange={(value) => this.handleProcessChange(value)}
              />
              <Field
                id="dateFrom"
                name="filters.dateFrom"
                colSm={3}
                controlLabel={t(`${tKey}USAGE.DATE_FROM`)}
                placeholder={t(`${tKey}USAGE.DATE_FROM`)}
                component={InputDatePicker}
                onInputChange={(value) => this.handleDateInitialChange(value)}
              />
              <Field
                id="dateTo"
                name="filters.dateTo"
                colSm={3}
                controlLabel={t(`${tKey}USAGE.DATE_TO`)}
                placeholder={t(`${tKey}USAGE.DATE_TO`)}
                component={InputDatePicker}
                onInputChange={(value) => this.handleDateEndChange(value)}
              />
              <Clearfix/>
              <Field
                name="filters.userId"
                controlLabel={t(`${tKey}USAGE.USER`)}
                placeholder={t(`${tKey}USAGE.USER`)}
                component={InputSelect}
                multi={false}
                valueKey="id"
                labelKey="value"
                childrenKey="children"
                options={usersCombo}
                colSm={3}
                onInputChange={(value) => this.handleUserChange(value)}
              />
              <Col sm={9} className="btn-wrapper">
                <Button
                  className={'btn-white btn-icon btn-input btn-white search-button'}
                  style={{ width: '100%' }}
                  onClick={() => this.searchUsage()}>
                  <i className="ico-search" />
                  {t(`${tKey}SEARCH`)}
                </Button>
                <Button
                  className={'btn-icon btn-input' + (this.state.hasFilters ? ' btn-standard' : ' btn-cancel')}
                  onClick={() => {
                    this.resetFilters()
                  }}
                >
                  <i className="ico-cancel" />
                  {t(`${tKey}RESET_BUTTON`)}
                </Button>
              </Col>
            </Row>
          </div>
        </Row>
        {hasSearch && usageList && usageList.length > 0 ? (
          <Row>
            <Col>
              <Button
                className={'btn-white btn-icon btn-input btn-white'}
                style={{ float: 'right' }}
                onClick={() => this.downloadUsageList()}>
                {t(`${tKey}DOWNLOAD`)}
              </Button>
            </Col>
            <Col sm={12} className="col-row  section-users-body">
              <Table className="editable-table usage-report" hover responsive>
                <thead>
                  <tr>
                    <th key={0} data-field="process" onClick={(event) => this.onOrderChange(event)}>
                      {t(`${tKey}USAGE.PROCESS`)}&nbsp;<i className={this.getIconClass('process')}/>
                    </th>
                    <th key={1} data-field="amount" onClick={(event) => this.onOrderChange(event)}>
                      {t(`${tKey}USAGE.AMOUNT`)}&nbsp;<i className={this.getIconClass('amount')}/>
                    </th>
                    <th key={2}>
                      {t(`${tKey}USAGE.ACTIONS`)}&nbsp;
                    </th>
                  </tr>
                </thead>
                <tbody>
                  { usageList.map((usage, idx) => {
                    return (
                      <tr key={idx} className = "">
                        <td>{usage.process}</td>
                        <td>{usage.amount}</td>
                        <td>
                          <a onClick={() => this.openHideModal(usage.processId)}>
                            <OverlayTrigger placement="left" overlay={setOpenTooltip}>
                              <i className="ico-magnifying-plus"/>
                            </OverlayTrigger>
                          </a>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
              <div className="section-users-footer">
                <nav key={1} className="text-center">
                  <IDocCarPagination
                    id="btn_pag_sales"
                    page={page}
                    pagesCount={pagesCount}
                    totalRows={usageCount}
                    onSelectAction={(value) => fetchUsageList({ ...filter, ...this.state.filter, page: value })}
                  />
                </nav>
              </div>
            </Col>
          </Row>
        ) : hasSearch && (
          <h2>{t(`${tKey}RESULTS_NOT_FOUND`)}</h2>
        )}
      </div>
    )
  }
}

export default reduxForm({
  form: 'listUsage'
})(UsageReport)
