import React, { PureComponent } from 'react'
import { Field } from 'redux-form'
// import { Row } from 'react-bootstrap'
import InputText from '../../../../../_v2/components/commons/form/InputText'
import { permissions } from '../../../../../constants/backendIds'

class PaperworkAddress extends PureComponent {
  constructor (props) {
    super()
  }

  render () {
    const {
      t, readOnlyFields, fieldsConfiguration, formInitRoute
    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.PAPERWORK.ADDRESS.'
    return (
      <div>
        {(!fieldsConfiguration || (fieldsConfiguration.paperworkAddressAddress && fieldsConfiguration.paperworkAddressAddress.permission !== permissions.hidden)) && (
          <Field
            component={InputText}
            colWidht={33} // 25, 33, 50, 75, 100 default=25
            id="paperworkAddressAddress"
            name={formInitRoute + 'dossierPaperworkAddressComponent.address'}
            controlLabel={t(`${tKey}ADDRESS`)}
            placeholder={t(`${tKey}ADDRESS`)}
            onInputChange={(value) => this.setState({ paperworkAddressAddress: value })}
            disabled={readOnlyFields || (fieldsConfiguration && fieldsConfiguration.paperworkAddressAddress.permission !== permissions.editable)}
          />
        )}
        {(!fieldsConfiguration || (fieldsConfiguration.paperworkAddressCity && fieldsConfiguration.paperworkAddressCity.permission !== permissions.hidden)) && (
          <Field
            component={InputText}
            colWidht={33} // 25, 33, 50, 75, 100 default=25
            id="paperworkAddressCity"
            name={formInitRoute + 'dossierPaperworkAddressComponent.city'}
            controlLabel={t(`${tKey}CITY`)}
            placeholder={t(`${tKey}CITY`)}
            onInputChange={(value) => this.setState({ paperworkAddressCity: value })}
            disabled={readOnlyFields || (fieldsConfiguration && fieldsConfiguration.paperworkAddressCity.permission !== permissions.editable)}
          />
        )}
        {(!fieldsConfiguration || (fieldsConfiguration.paperworkAddressState && fieldsConfiguration.paperworkAddressState.permission !== permissions.hidden)) && (
          <Field
            component={InputText}
            colWidht={33} // 25, 33, 50, 75, 100 default=25
            id="paperworkAddressState"
            name={formInitRoute + 'dossierPaperworkAddressComponent.state'}
            controlLabel={t(`${tKey}STATE`)}
            placeholder={t(`${tKey}STATE`)}
            onInputChange={(value) => this.setState({ paperworkAddressState: value })}
            disabled={readOnlyFields || (fieldsConfiguration && fieldsConfiguration.paperworkAddressState.permission !== permissions.editable)}
          />
        )}
        <div className="clearfix" />
        {(!fieldsConfiguration || (fieldsConfiguration.paperworkAddressCp && fieldsConfiguration.paperworkAddressCp.permission !== permissions.hidden)) && (
          <Field
            component={InputText}
            colWidht={33} // 25, 33, 50, 75, 100 default=25
            id="paperworkAddressCp"
            name={formInitRoute + 'dossierPaperworkAddressComponent.cp'}
            controlLabel={t(`${tKey}CP`)}
            placeholder={t(`${tKey}CP`)}
            onInputChange={(value) => this.setState({ paperworkAddressCp: value })}
            disabled={readOnlyFields || (fieldsConfiguration && fieldsConfiguration.paperworkAddressCp.permission !== permissions.editable)}
          />
        )}
        {(!fieldsConfiguration || (fieldsConfiguration.paperworkAddressCountry && fieldsConfiguration.paperworkAddressCountry.permission !== permissions.hidden)) && (
          <Field
            component={InputText}
            colWidht={33} // 25, 33, 50, 75, 100 default=25
            id="paperworkAddressCountry"
            name={formInitRoute + 'dossierPaperworkAddressComponent.country'}
            controlLabel={t(`${tKey}COUNTRY`)}
            placeholder={t(`${tKey}COUNTRY`)}
            disabled={readOnlyFields || (fieldsConfiguration && fieldsConfiguration.paperworkAddressCountry.permission !== permissions.editable)}
          />
        )}
      </div>
    )
  }
}

export default PaperworkAddress
