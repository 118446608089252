import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import authActions from '../../actions/auth'
import Login from '../../components/access/Login'
import { translate } from 'react-polyglot'
import TrackingContainer from '../../containers/tracking/trackingContainer'

export function mapStateToProps (state) {
  return {
    ...state.auth,
    ...state.form.submitSucceeded
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators(authActions, dispatch)
  }
}

var decoratedComponent = TrackingContainer(Login, true)
export default connect(mapStateToProps, mapDispatchToProps)(translate()(decoratedComponent))
