const sections = {
  dashboard: {
    id: 'btn_db_expediente',
    icon: 'ico-home',
    title: 'SECTIONS.DASHBOARD'
  },
  masters: {
    id: 'btn_masters',
    icon: 'ico-uo',
    title: 'SECTIONS.MASTERS'
  },
  admin: {
    id: 'btn_admin',
    icon: 'ico-users',
    title: 'SECTIONS.ADMIN'
  },
  templates: {
    id: 'btn_templates',
    icon: 'ico-print',
    title: 'SECTIONS.TEMPLATES'
  },
  'conditions-maintenance': {
    id: 'btn_conditions_maintenance',
    icon: 'ico-mantenimiento-condiciones',
    title: 'SECTIONS.CONDITIONS_MAINTENANCE'
  },
  'dashboard-tasks': {
    id: 'btn_db_tasks',
    icon: 'ico-flash',
    title: 'SECTIONS.TASKS'
  },
  'documental-search': {
    id: 'btn_db_search_documental',
    icon: 'ico-documental-search',
    title: 'SECTIONS.SEARCH_DOCUMENTAL'
  },
  search: {
    id: 'btn_db_search',
    icon: 'ico-search',
    title: 'SECTIONS.SEARCH'
  },
  importExportFile: {
    id: 'btn_import_export_file',
    icon: 'glyphicon glyphicon-envelope',
    title: 'SECTIONS.IMPORT_EXPORT_FILE'
  },
  reports: {
    id: 'btn_db_reports',
    icon: 'ico-chart-bar',
    title: 'SECTIONS.REPORTS'
  },
  iDocSign: {
    id: 'btn_iDocSign',
    icon: 'ico-sign',
    title: 'SECTIONS.IDOCSIGN'
  },
  configuration: {
    id: 'btn_db_configuration',
    icon: 'ico-cog',
    title: 'SECTIONS.CONFIGURATION',
    sections: {
      'admin-role': {
        id: 'btn_db_rol_permisos',
        icon: 'ico-unlock',
        title: 'SECTIONS.ADMIN_ROLE'
      },
      'admin-user': {
        id: 'btn_db_usuarios',
        icon: 'ico-users',
        title: 'SECTIONS.ADMIN_USER'
      },
      'admin-uo': {
        id: 'btn_units',
        icon: 'ico-uo',
        title: 'SECTIONS.UNITS'
      },
      masters: {
        id: 'btn_masters',
        icon: 'ico-uo',
        title: 'SECTIONS.MASTERS'
      }
    }
  },
  jiraServiceDesk: {
    id: 'btn_jiraServiceDesk',
    icon: 'glyphicon glyphicon-envelope',
    title: 'SECTIONS.JIRA_SERVICE_DESK'
  },
  faq: {
    id: 'btn_faq',
    icon: 'glyphicon glyphicon-question-sign',
    title: 'SECTIONS.FAQ'
  },
  management: {
    id: 'btn_management',
    icon: 'glyphicon glyphicon-cog',
    title: 'SECTIONS.MANAGEMENT'
  }
}

export default sections
