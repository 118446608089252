import { connect } from 'react-redux'
import LocaleApp from '../localeApp'

export function mapStateToProps (state) {
  return {
    messages: state.common.messages,
    locale: state.common.locale
  }
}

export default connect(mapStateToProps, null)(LocaleApp)
