import React, { PureComponent } from 'react'
import { Modal, Col, Row, Button } from 'react-bootstrap'
import { reduxForm, Field } from 'redux-form'
import InputText from '../../commons/form/InputText'
import InputDecimal from '../../commons/form/InputDecimal'
import InputSelect from '../../commons/form/InputSelect'

class RatingMasterModal extends PureComponent {
  constructor () {
    super()
    this.state = {
    }
  }

  submitDocument (values) {
    const rating = {
      ratingId: values.ratingId,
      organizedUnitId: values.organizedUnitId,
      name: values.name,
      description: values.description,
      import: values.import
    }

    this.props.actions.submitRating(rating)
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevProps.showModal !== this.props.showModal && this.props.showModal === true) {
      this.props.initialize(this.props.rating)
      this.setState({
        rating: this.props.rating,
        deleted: false,
        values: 1
      })
    }
    if (!this.state.deleted && this.props.rating !== null && (this.props.rating?.value)?.length > this.state.values) {
      const count = (this.props.rating?.value)?.length
      this.setState({ values: count })
    }
  }

  render () {
    const {
      t, handleSubmit,
      showModal,
      combos: { organizedUnitsAllCombo }
    } = this.props
    const tKey = 'MASTERS.RATING_MASTERS.MODAL.'
    return (
      <Modal className="role-modal languages-modal" show={showModal} onHide={this.props.actions.closeRatingMasterModal} backdrop={'static'}>
        <form autoComplete="off" onSubmit={handleSubmit(this.submitDocument.bind(this))} onReset={this.handleFormReset}>
          <Modal.Header closeButton onHide={this.props.actions.closeRatingMasterModal}>
            <Modal.Title>{t(`${tKey}TITLE`)}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="inputs-wrapper-no-border">
              <Col sm={4} className="custom-width-col">
                <h2>{t(`${tKey}NAME`)}</h2>
                <Field
                  id="name"
                  name="name"
                  colSm={3}
                  placeholder={t(`${tKey}NAME`)}
                  component={InputText}
                  maxLength={50}
                />
              </Col>
              <Col sm={8} className="custom-width-col">
                <h2>{t(`${tKey}DESCRIPTION`)}</h2>
                <Field
                  id="description"
                  name="description"
                  colSm={3}
                  placeholder={t(`${tKey}DESCRIPTION`)}
                  component={InputText}
                  maxLength={50}
                />
              </Col>
            </Row>
            <Row className="inputs-wrapper-no-border">
              <Col sm={4} className="custom-width-col">
                <h2>{t(`${tKey}IMPORT`)}</h2>
                <Field
                  id="import"
                  name="import"
                  colSm={3}
                  placeholder={t(`${tKey}IMPORT`)}
                  component={InputDecimal}
                  maxLength={50}
                />
              </Col>
              <Col sm={4} className="custom-width-col">
                <h2>{t(`${tKey}OU`)}</h2>
                <Field
                  name="organizedUnitId"
                  placeholder={t(`${tKey}OU`)}
                  valueKey="id"
                  labelKey="value"
                  component={InputSelect}
                  colSm={0}
                  options={organizedUnitsAllCombo}
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Col sm={5} className="btn-wrapper">
              <Button
                id="role-modal-submit-button"
                className="btn-standard"
                type="submit"
                disabled={this.props.pristine}>
                {t(`${tKey}SUBMIT_BUTTON`)}
              </Button>
            </Col>
            <Col sm={1} className="btn-wrapper">
              <Button
                id="role-modal-submit-button"
                className="btn-danger"
                type="reset"
                onClick={this.props.actions.closeRatingMasterModal}>
                {t(`${tKey}CANCEL_BUTTON`)}
              </Button>
            </Col>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default reduxForm({
  form: 'editRating',
  validate: (values, props) => {
    let error = {}
    const tKey = 'MASTERS.RATING_MASTERS.VALIDATION.'
    if (!values.name) {
      error.name = props.t(`${tKey}NAME`)
    }
    if (!values.description) {
      error.description = props.t(`${tKey}DESCRIPTION`)
    }
    if (!values.organizedUnitId) {
      error.organizedUnitId = props.t(`${tKey}OU`)
    }
    if (!values.import) {
      error.import = props.t(`${tKey}IMPORT`)
    }

    return Object.keys(error).length > 0 ? error : {}
  }
})(RatingMasterModal)
