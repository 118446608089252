import React, { PureComponent } from 'react'
import { Button, OverlayTrigger, Tooltip, Form } from 'react-bootstrap'
import { Field, reduxForm, reset } from 'redux-form'
import InputText from '../commons/form/InputText'
import InputSelect from '../commons/form/InputSelect'
import InputDatePicker from '../commons/form/InputDatePicker'
import { validateDate, validateDates } from '../../../util/validationFunctions'
import { multipleNormalize, toUpperCase, trimAll } from '../../../util/normalizeFunctions'
import settings from '../../../setting'
import { clientInformation, urgencyTypesClass, permissions } from '../../../constants/backendIds'
import SimpleTablePage from '../../../containers/commons/general/SimpleTablePage'
import IDocCarPagination from '../../../components/commons/iDocCarPagination'
import { Link } from 'react-router-dom'
import EntityPopover from './EntityPopover'
import VehiclePopover from './VehiclePopover'
import { getFieldsSectionPermissions } from '../../../util/permisionFunctions'
import DownloadModalPage from '../../../_v2/containers/search/Modals/DownloadModalPage'
import { showFilter, hideFiltersBtn, toggleFilterClass, getIconClass } from '../../../_v2/util/management/ManagementUtils'
import MapSectionsSearch from '../../../_v2/util/map-sections-search'
import { fromCamelToUnderscore, getDateNowMaxDatePicker } from '../../../util/utils'

class SearchByDossierForm extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      isSearching: false,
      fields: {},
      lastDossierSubtype: null,
      fieldsConfiguration: {
        findByResponsible: null,
        stockStatusId: null,
        rentalOrderIdNumber: null,
        operationTypeDMSId: null,
        downloadSearchBtn: null
      }
    }
  }

  submitSearchForm (values) {
    var allEmptyFieldOrNoValues = this.standardValidations(values)
    if (allEmptyFieldOrNoValues) {
      return this.props.actions.openModal('mustFillFilters')
    }
    console.log('values', values)
    console.log('this.props.filters', this.props.filters)
    this.props.actions.fetchDossiers({ ...values.dossier, ...this.props.filters, page: 1 })
    this.setState({ dossierIdToArray: [], dynamicFilters: values, filterValues: { ...values.dossier, ...this.props.filters, page: 1 } })

    this.props.actions.setFilters(values)
  }

  standardValidations (values) {
    var result = false
    const formValues = values.dossier

    result = !formValues || !Object.keys(formValues).filter(item => formValues[item] !== null &&
     Array.isArray(formValues[item]) ? formValues[item].length > 0 : true).length

    return result
  }

  handleClickReset (e) {
    if (e !== undefined) {
      e.preventDefault()
    }

    this.props.dispatch(reset('SearchByDossierForm'))
    this.state.dossierIdToArray = []
    this.setState({ onlyFleets: true, checkAll: false, dossierIdToArray: [], dossierData: [], fields: [] })
  }

  onDossierSubtypeChange (dossierSubtypeId) {
    this.props.change('dossier.stage', null)
    this.props.change('dossier.saleTypeId', null)
    this.props.change('dossier.externalOrderId', null)
    this.props.change('dossier.operationTypeId', null)
    this.props.change('dossier.purchaseTypeId', null)

    this.props.actions.fetchStagesCombo(dossierSubtypeId)
    this.props.actions.fetchSaleTypeBySubtype(dossierSubtypeId, !dossierSubtypeId)
    this.props.actions.fetchPurchaseType(dossierSubtypeId)
    this.props.actions.fetchOperationType(null, null, null, false)
    this.props.actions.fetchPaymentMethodCombo(dossierSubtypeId)
  }

  onSaleTypeChange (saleTypeId) {
    this.props.change('dossier.operationTypeId', null)
    this.props.actions.fetchOperationType(saleTypeId, null, null)
  }

  componentDidMount () {
    for (const dynamicFilter in this.props.dynamicFilters) {
      this.props.change(dynamicFilter, this.props.dynamicFilters[dynamicFilter])
    }
    this.props.actions.fetchSearchDossierSubType()
    if (this.props.permissions && this.props.permissions.sectionFieldsConfiguration) {
      const fieldKeys = Object.keys(this.state.fieldsConfiguration)
      const permission = getFieldsSectionPermissions(this.props.permissions.sectionFieldsConfiguration, fieldKeys, this.props.permissions.permission)
      this.setState({ fieldsConfiguration: permission.fieldsConfiguration }, () => {
        if ((this.props.combos.usersCombo < 1 &&
              this.state.fieldsConfiguration.findByResponsible &&
              this.state.fieldsConfiguration.findByResponsible.permission > permissions.hidden) ||
            settings.clientFieldValidation === clientInformation.PORSCHE) {
          this.props.actions.fetchUserCombo()
        }
      })

      if (permission.fieldsConfiguration.stockStatusId.permission > permissions.hidden) {
        this.props.actions.fetchStockStatusCombo()
      }
      if (permission.fieldsConfiguration.operationTypeDMSId.permission > permissions.hidden) {
        this.props.actions.fetchOperationTypeDMS()
      }
    }
  }

  validateDate (value, allValues, props) {
    const result = validateDate(value, props)
    return result === Object(result) ? undefined : result
  }

  getYesNoComboOptions () {
    const tKey = 'SEARCH.CATEGORIES.DOSSIER.'
    return [
      { value: this.props.t(`${tKey}YES`), id: true },
      { value: this.props.t(`${tKey}NO`), id: false }]
  }

  exportData () {
    const tKey = 'SEARCH.CATEGORIES.DOSSIER.'
    let columnsExcel = []
    columnsExcel.push(this.props.t(`${tKey}URGENCY`) + '#Urgency')
    columnsExcel.push(this.props.t(`${tKey}DOSSIER_NUMBER`) + '#Number')
    columnsExcel.push(this.props.t(`${tKey}ORGANIZED_UNIT`) + '#Uo')
    columnsExcel.push(this.props.t('SEARCH.CATEGORIES.ENTITY.BY_ENTITY') + '#Entity')
    columnsExcel.push(this.props.t('SEARCH.CATEGORIES.VEHICLE.BY_VEHICLE') + '#Vehicle')
    columnsExcel.push(this.props.t(`${tKey}DOSSIER_SUBTYPE`) + '#SubType')
    columnsExcel.push(this.props.t(`${tKey}STAGE`) + '#Stage')
    if (this.props.showCampaignColumnsInExcel) {
      columnsExcel.push(this.props.t(`${tKey}DEALER_NUMBER`) + '#CampaignDealerNumber')
      columnsExcel.push(this.props.t(`${tKey}CAMPAIGN_GROUP_CODE`) + '#CampaignGroupCode')
      columnsExcel.push(this.props.t(`${tKey}CAMPAIGN_GROUP`) + '#CampaignGroup')
      columnsExcel.push(this.props.t(`${tKey}CAMPAIGN_TYPE_CODE`) + '#CampaignTypeCode')
      columnsExcel.push(this.props.t(`${tKey}CAMPAIGN_TYPE`) + '#CampaignType')
      columnsExcel.push(this.props.t(`${tKey}CAMPAIGN_CODE`) + '#CampaignDetailsCode')
      columnsExcel.push(this.props.t(`${tKey}CAMPAIGN_DESCRIPTION`) + '#CampaignDescription')
      columnsExcel.push(this.props.t(`${tKey}CAMPAIGN_PERCENTAGE_BRAND`) + '#CampaignPercentageBrand')
      columnsExcel.push(this.props.t(`${tKey}CAMPAIGN_AMOUNT_BRAND`) + '#CampaignAmountBrand')
      columnsExcel.push(this.props.t(`${tKey}CAMPAIGN_AMOUNT_REQUESTED`) + '#CampaignAmountRequested')
      columnsExcel.push(this.props.t(`${tKey}CAMPAIGN_AMOUNT_APPROVED`) + '#CampaignAmountApproved')
      columnsExcel.push(this.props.t(`${tKey}CAMPAIGN_PAYMENT_METHOD`) + '#CampaignPaymentMethod')
      columnsExcel.push(this.props.t(`${tKey}CAMPAIGN_BILL_DATE`) + '#CampaignBillDate')
      columnsExcel.push(this.props.t(`${tKey}CAMPAIGN_BILL_NUMBER`) + '#CampaignBillNumber')
      columnsExcel.push(this.props.t(`${tKey}CAMPAIGN_CHECKER`) + '#CampaignChecker')
      columnsExcel.push(this.props.t(`${tKey}CAMPAIGN_CHECK_DATE`) + '#CampaignCheckDate')
    }
    columnsExcel.push(this.props.t(`${tKey}CREATION_DATE`) + '#CreationDate')
    columnsExcel.push(this.props.t(`${tKey}URL`) + '#UrlToNavigate')
    this.exportToExcel(columnsExcel)
  }

  exportToExcel (data) {
    let cols = ''
    data.forEach(element => {
      cols += element + '$'
    })
    cols = cols.substring(0, cols.length - 1)
    let values = this.props.dynamicFilters
    this.props.actions.fetchDossiersDownload({ ...values.dossier, ...this.props.filters }, cols)
  }

  openDownloadModal () {
    const fields = this.props.fields
    const body = {
      page: fields.page,
      pageSize: fields.pageSize,
      orderBy: fields.orderBy,
      dossierSubTypeId: fields.dossierSubtype,
      number: fields.number,
      load: fields.load,
      organizedUnitId: fields.organizedUnit,
      dossierStageId: fields.stage?.join(', '),
      creationDateFrom: fields.creationDateFrom,
      creationDateTo: fields.creationDateTo,
      entityName: fields.name,
      entityCifNif: fields.dniCif,
      entityMail: fields.email,
      vehicleVinChasis: fields.chassis,
      vehicleLicensePlate: fields.licensePlate,
      vehicleLicensePlateDateFrom: fields.vehicleLicensePlateDateFrom,
      vehicleLicensePlateDateTo: fields.vehicleLicensePlateDateTo,
      vehicleModel: fields.model,
      dealerNumber: fields.dealerNumber,
      orderDateFrom: fields.orderDateFrom,
      orderDateTo: fields.orderDateTo,
      salesmanId: fields.salesman?.join(', '),
      salesmanIdManager: fields.salesmanManager?.join(', '),
      orderMakerNumber: fields.orderMakerNumber,
      makerNumber: fields.makerNumber,
      availabilityDateFrom: fields.availabilityDateFrom,
      availabilityDateTo: fields.availabilityDateTo,
      rentalOrderNumber: fields.rentalOrderNumber,
      userId: fields.userId,
      saleTypeId: fields.saleTypeId,
      purchaseTypeId: fields.purchaseTypeId,
      operationTypeId: fields.operationTypeId,
      stockStatusId: fields.stockStatusId,
      creatorId: fields.creatorId,
      isDenied: fields.isDenied,
      proformInvoiceEmited: fields.proformInvoiceEmited,
      externalOrderId: fields.externalOrderId,
      paymentMethodId: fields.paymentMethod?.join(', '),
      operationTypeDMSId: fields.operationTypeDMSId?.join(', ')
    }
    this.props.actions.openDownloadModal(body)
  }

  extractMultipleCombo (combo, filters, item, filter) {
    const values = combo.filter(object => {
      return filters[item][filter] ? filters[item][filter].some(selectedComboValue => object.id === selectedComboValue) : null
    })
    let value = values && this.formatMultipleValues(values)
    return value
  }

  formatMultipleValues (multiplecombovalues) {
    return multiplecombovalues
      .map((combovalue, index) => combovalue.value || combovalue.name)
      .join(', ')
  }

  onPageChange (page) {
    this.setState({ filterValues: { ...this.state.filterValues, page: page } })
    this.props.actions.fetchDossiers({ ...this.state.filterValues, page })
  }

  onOrderChange (e) {
    const newOrderBy = this.props.filters.orderBy.includes(`%2B${e.currentTarget.dataset.field}`) ? `-${e.currentTarget.dataset.field}` : `%2B${e.currentTarget.dataset.field}`
    this.setState({ filterValues: { ...this.state.filterValues, orderBy: newOrderBy } })
    this.props.actions.fetchDossiers({ ...this.state.filterValues, orderBy: newOrderBy })
  }

  toogleHideFilters () {
    this.props.actions.setHideFilters(!this.props.hideFilters)
  }

  toogleMaxiTable () {
    this.setState({ maxiTable: !this.state.maxiTable })
  }

  selectedFilters () {
    const filters = this.props.dynamicFilters
    const tKey = 'SEARCH.CATEGORIES.'
    return Object.keys(filters)
      .map((item) => {
        let text = item
        let value = null
        const list = []
        Object.keys(filters[item]).map((filter, index) => {
          text = this.props.t(`${tKey}${item.toUpperCase()}.${fromCamelToUnderscore(filter).toUpperCase()}`)
          switch (filter) {
            case 'dossierSubtype':
            { const selectedSubtype = this.props.combos.searchDossierSubtypesCombo.find(subtype => subtype.id === filters[item][filter])
              value = selectedSubtype && selectedSubtype.value
              break }
            case 'salesman':
            {
              value = this.extractMultipleCombo(this.props.combos.salesmanCombo, filters, item, filter)
              break
            }
            case 'salesmanManager':
            {
              value = this.extractMultipleCombo(this.props.combos.salesmanCombo, filters, item, filter)
              break
            }
            case 'organizedUnit':
            { const organizedUnit = this.props.combos.lowestOrganizedUnitsCombo.find(ou => ou.id === filters[item][filter])
              value = organizedUnit && organizedUnit.value
              break }
            case 'stage':
            {
              value = this.extractMultipleCombo(this.props.combos.stagesCombo, filters, item, filter)
              break }
            case 'userId':
            { const user = this.props.combos.usersCombo.find(b => b.id === filters[item][filter])
              value = user && user.value
              break }
            case 'creatorId':
            { const user = this.props.combos.usersCombo.find(b => b.id === filters[item][filter])
              value = user && user.value
              break }
            case 'saleTypeId':
            { const saleType = this.props.combos.saleTypeCombo.find(ou => ou.id === filters[item][filter])
              value = saleType && saleType.value
              break }
            case 'purchaseTypeId':
            { const purchaseType = this.props.combos.purchaseTypeCombo.find(ou => ou.id === filters[item][filter])
              value = purchaseType && purchaseType.value
              break }
            case 'operationTypeId':
            { const operationType = this.props.combos.operationTypeCombo.find(ou => ou.id === filters[item][filter])
              value = operationType && operationType.value
              break }
            case 'operationTypeDMSId':
            {
              let operationType = ''
              filters[item][filter].forEach(id => {
                operationType = this.props.combos.operationTypeDMSCombo.find(ou => ou.id === id)
                value = (value || '') + (operationType && (operationType.description + ', '))
              })
              if (value) {
                value = value.substring(0, value.length - 2)
              }
              break }
            case 'stockStatusId':
            { const stockStatus = this.props.combos.stockStatusCombo.find(b => b.id === filters[item][filter])
              value = stockStatus && stockStatus.value
              break }
            case 'proformInvoiceEmited': {
              value = this.extractValueComboYesNo(filters[item][filter])
              break
            }
            case 'isDenied': {
              value = this.extractValueComboYesNo(filters[item][filter])
              break
            }
            case 'paymentMethod':
            {
              value = this.extractMultipleCombo(this.props.combos.paymentMethodCombo, filters, item, filter)
              break
            }
            default:
            { value = filters[item][filter]
              break }
          }

          if (value) {
            list.push(<li key={index}>{text}: <strong>{value}</strong>;</li>)
          }
        })
        return list
      })
  }

  render () {
    const {
      t, fields, fieldFill, className, classNameSelect, hasSearch,
      dossierData, vehicleData, page, pages, count, hideFilters,
      handleSubmit, // From search
      combos: {
        stagesCombo, lowestOrganizedUnitsCombo, salesmanCombo, searchDossierSubtypesCombo, usersCombo, saleTypeCombo,
        operationTypeCombo, purchaseTypeCombo, stockStatusCombo, paymentMethodCombo, operationTypeDMSCombo
      }
    } = this.props
    const tKey = 'SEARCH.CATEGORIES.DOSSIER.'
    const secMapSearch = MapSectionsSearch.find(function (s) { return s.page === 'dossierPage' })
    const hasResults = dossierData && dossierData.length > 0
    var disableOperationType = this.props.fields.saleTypeId !== null && this.props.fields.saleTypeId !== undefined

    return (
      <div className="inner-results-panel">
        <DownloadModalPage/>
        <div className="section-results-header">
          <div className="section-header-title">
            <OverlayTrigger placement="right" overlay={<Tooltip id="editProductTooltip">{`${t((this.props.hideMenu ? 'GLOBAL.TABLES.SHOW_INNER_MENU' : 'GLOBAL.TABLES.HIDE_INNER_MENU'))}`}</Tooltip>}>
              <a onClick={() => this.props.toogleInnerMenu()}s className={this.props.hideMenu ? 'collapseBtn' : 'collapseBtn closeInnerMenu'}><i className={this.props.hideMenu ? 'iDocIcon-Open-inner-menu' : 'iDocIcon-Close-inner-menu'}></i></a>
            </OverlayTrigger>
            <h4>{t(`${secMapSearch.title}`)}
              <OverlayTrigger placement="left" overlay={<Tooltip id="editProductTooltip">{`${t((hideFilters ? 'GLOBAL.TABLES.SHOW_FILTERS' : 'GLOBAL.TABLES.HIDE_FILTERS'))}`}</Tooltip>}>
                <a onClick={() => this.toogleHideFilters()} className={'toogleShowFilters ' + hideFiltersBtn(hasResults)}><i className={toggleFilterClass(hasResults, hideFilters)}></i></a>
              </OverlayTrigger>
            </h4>
          </div>
          <Form autoComplete="off" onSubmit={handleSubmit((values) => this.submitSearchForm(values))} className="filters ">
            <div className={'search-form ' + (showFilter(hasResults, hideFilters) ? '' : 'hideFilters ')}>

              <Field
                component={InputText}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                id="dealerIdNumberDossier"
                name='dossier.dealerNumber'
                placeholder={t(`${tKey}DEALER_NUMBER`)}
                controlLabel={t(`${tKey}DEALER_NUMBER`)}
                type="text"
                maxLength={settings.dealerMaxLength}
                onInputChange={(val) => fieldFill({ dealerIdNumber: val })}
                customClass={fields.dealerIdNumber ? className : ''}
              />
              <Field
                component={InputText}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                id="searchMakerNumber"
                name='dossier.makerNumber'
                placeholder={t(`${tKey}MAKER_NUMBER`)}
                controlLabel={t(`${tKey}MAKER_NUMBER`)}
                type="text"
                onInputChange={(val) => fieldFill({ makerNumber: val })}
                customClass={fields.makerNumber ? className : ''}
              />
              { settings.clientFieldValidation === clientInformation.VGR &&
                <Field
                  component={InputText}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="orderMakerNumber"
                  name='dossier.orderMakerNumber'
                  placeholder={t(`${tKey}ORDER_MAKER_NUMBER`)}
                  controlLabel={t(`${tKey}ORDER_MAKER_NUMBER`)}
                  type="text"
                  onInputChange={(val) => fieldFill({ orderMakerNumber: val })}
                  customClass={fields.orderMakerNumber ? className : ''}
                />
              }
              <Field
                component={InputText}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                id="numberDossier"
                name='dossier.dossierNumber'
                placeholder={t(`${tKey}DOSSIER_NUMBER`)}
                controlLabel={t(`${tKey}DOSSIER_NUMBER`)}
                type="text"
                onInputChange={(val) => fieldFill({ number: val })}
                customClass={fields.number ? className : ''}
              />
              <Field
                component={InputSelect}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                id="organizedUnit"
                name='dossier.organizedUnit'
                placeholder={t(`${tKey}ORGANIZED_UNIT`)}
                controlLabel={t(`${tKey}ORGANIZED_UNIT`)}
                options={lowestOrganizedUnitsCombo}
                valueKey="id"
                labelKey="value"
                onInputChange={ (val) => { fieldFill({ organizedUnit: val }) } }
                customClass={fields.organizedUnit ? classNameSelect : ''}
              />
              <Field
                component={InputSelect}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                id="dossierSubtype"
                name='dossier.dossierSubtype'
                placeholder={t(`${tKey}DOSSIER_SUBTYPE`)}
                controlLabel={t(`${tKey}DOSSIER_SUBTYPE`)}
                options={searchDossierSubtypesCombo}
                valueKey="id"
                labelKey="value"
                onInputChange={ (val) => { this.onDossierSubtypeChange(val); fieldFill({ dossierSubtype: val, saleTypeId: null, purchaseTypeId: null, operationTypeId: null }) } }
                customClass={fields.dossierSubtype ? classNameSelect : ''}
              />
              <Field
                component={InputSelect}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                id="stage"
                name='dossier.stage'
                placeholder={t(`${tKey}STAGE`)}
                controlLabel={t(`${tKey}STAGE`)}
                multi
                menuContainerStyle={{ zIndex: 999 }}
                options={stagesCombo}
                valueKey="id"
                labelKey="value"
                onInputChange={(val) => fieldFill({ stage: val })}
                disabled={stagesCombo.length === 0}
                customClass={stagesCombo.length === 0 ? 'disabled' : ''}
              />
              <Field
                component={InputSelect}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                id="saleTypeId"
                name='dossier.saleTypeId'
                placeholder={t(`${tKey}SALE_TYPE_ID`)}
                controlLabel={t(`${tKey}SALE_TYPE_ID`)}
                menuContainerStyle={{ zIndex: 999 }}
                options={saleTypeCombo}
                valueKey="id"
                labelKey="value"
                onInputChange={ (val) => { this.onSaleTypeChange(val); setTimeout(() => { fieldFill({ saleTypeId: val, operationTypeId: null }) }, 100) } }
                disabled={!saleTypeCombo || saleTypeCombo.length === 0}
                customClass={!saleTypeCombo || saleTypeCombo.length === 0 ? 'disabled' : ''}
              />
              <Field
                component={InputText}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                id="externalOrderId"
                name='dossier.externalOrderId'
                placeholder={t(`${tKey}EXTERNAL_ORDER_ID`)}
                controlLabel={t(`${tKey}EXTERNAL_ORDER_ID`)}
                type="text"
                maxLength={20}
                onInputChange={(val) => fieldFill({ externalOrderId: val })}
                disabled={!saleTypeCombo || saleTypeCombo.length === 0}
                customClass={!saleTypeCombo || saleTypeCombo.length === 0 ? 'disabled' : ''}
              />
              {(this.state.fieldsConfiguration.operationTypeDMSId && this.state.fieldsConfiguration.operationTypeDMSId.permission > permissions.hidden) === false &&
                <Field
                  component={InputSelect}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="operationTypeId"
                  name='dossier.operationTypeId'
                  placeholder={t(`${tKey}OPERATION_TYPE_ID`)}
                  controlLabel={t(`${tKey}OPERATION_TYPE_ID`)}
                  menuContainerStyle={{ zIndex: 999 }}
                  options={operationTypeCombo}
                  valueKey="id"
                  labelKey="value"
                  onInputChange={(val) => fieldFill({ operationTypeId: val })}
                  disabled={!disableOperationType || !operationTypeCombo || operationTypeCombo.length === 0}
                  customClass={!disableOperationType || !operationTypeCombo || operationTypeCombo.length === 0 ? 'disabled' : ''}
                />
              }
              <Field
                component={InputSelect}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                id="purchaseTypeId"
                name='dossier.purchaseTypeId'
                placeholder={t(`${tKey}PURCHASE_TYPE_ID`)}
                controlLabel={t(`${tKey}PURCHASE_TYPE_ID`)}
                menuContainerStyle={{ zIndex: 999 }}
                options={purchaseTypeCombo}
                valueKey="id"
                labelKey="value"
                onInputChange={(val) => fieldFill({ purchaseTypeId: val })}
                disabled={!purchaseTypeCombo || purchaseTypeCombo.length === 0}
                customClass={!purchaseTypeCombo || purchaseTypeCombo.length === 0 ? 'disabled' : ''}
              />
              <Field
                component={InputSelect}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                id="salesman"
                name='dossier.salesman'
                placeholder={t(`${tKey}SALESMAN`)}
                controlLabel={t(`${tKey}SALESMAN`)}
                options={salesmanCombo}
                multi={true}
                valueKey="id"
                labelKey="value"
                onInputChange={(val) => fieldFill({ salesman: val })}
                customClass={fields.salesman ? classNameSelect : ''}
              />
              <Field
                component={InputSelect}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                id="salesmanManager"
                name='dossier.salesmanManager'
                placeholder={t(`${tKey}SALESMAN_MANAGER`)}
                controlLabel={t(`${tKey}SALESMAN_MANAGER`)}
                options={salesmanCombo}
                multi={true}
                valueKey="id"
                labelKey="value"
                onInputChange={(val) => fieldFill({ salesmanManager: val })}
                customClass={fields.salesmanManager ? classNameSelect : ''}
              />
              { settings.clientFieldValidation !== clientInformation.VGR && settings.clientFieldValidation !== clientInformation.QUADIS
                ? <Field
                  component={InputSelect}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="paymentMethod"
                  name='dossier.paymentMethod'
                  placeholder={t(`${tKey}PAYMENT_METHOD`)}
                  controlLabel={t(`${tKey}PAYMENT_METHOD`)}
                  options={paymentMethodCombo}
                  multi={true}
                  valueKey="id"
                  labelKey="value"
                  onInputChange={(val) => fieldFill({ paymentMethod: val })}
                  disabled={!paymentMethodCombo || paymentMethodCombo.length === 0}
                  customClass={!paymentMethodCombo || paymentMethodCombo.length === 0 ? 'disabled' : ''}
                /> : ''
              }
              <Field
                component={InputDatePicker}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                maxDate={getDateNowMaxDatePicker()}
                id="creationDateFrom"
                name='dossier.creationDateFrom'
                placeholder={t(`${tKey}CREATION_DATE_FROM`)}
                controlLabel={t(`${tKey}CREATION_DATE_FROM`)}
                validate={[this.validateDate]}
                onInputChange={(val) => fieldFill({ creationDateFrom: val })}
                customClass={fields.creationDateFrom ? className : ''}
              />
              <Field
                component={InputDatePicker}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                maxDate={getDateNowMaxDatePicker()}
                id="creationDateTo"
                name='dossier.creationDateTo'
                placeholder={t(`${tKey}CREATION_DATE_TO`)}
                controlLabel={t(`${tKey}CREATION_DATE_TO`)}
                validate={[this.validateDate]}
                onInputChange={(val) => fieldFill({ creationDateTo: val })}
                customClass={fields.creationDateTo ? className : ''}
              />
              <Field
                component={InputDatePicker}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                maxDate={getDateNowMaxDatePicker()}
                id="orderDateFrom"
                name='dossier.orderDateFrom'
                placeholder={t(`${tKey}ORDER_DATE_FROM`)}
                controlLabel={t(`${tKey}ORDER_DATE_FROM`)}
                validate={[this.validateDate]}
                onInputChange={(val) => fieldFill({ orderDateFrom: val })}
                customClass={fields.orderDateFrom ? className : ''}
              />
              <Field
                component={InputDatePicker}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                maxDate={getDateNowMaxDatePicker()}
                id="orderDateTo"
                name='dossier.orderDateTo'
                placeholder={t(`${tKey}ORDER_DATE_TO`)}
                controlLabel={t(`${tKey}ORDER_DATE_TO`)}
                validate={[this.validateDate]}
                onInputChange={(val) => fieldFill({ orderDateTo: val })}
                customClass={fields.orderDateTo ? className : ''}
              />
              <Field
                component={InputDatePicker}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                maxDate={getDateNowMaxDatePicker()}
                id="availabilityDateFrom"
                name='dossier.availabilityDateFrom'
                placeholder={t(`${tKey}AVAILABILITY_DATE_FROM`)}
                controlLabel={t(`${tKey}AVAILABILITY_DATE_FROM`)}
                validate={[this.validateDate]}
                onInputChange={(val) => fieldFill({ availabilityDateFrom: val })}
                customClass={fields.availabilityDateFrom ? className : ''}
              />
              <Field
                component={InputDatePicker}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                maxDate={getDateNowMaxDatePicker()}
                id="availabilityDateTo"
                name='dossier.availabilityDateTo'
                placeholder={t(`${tKey}AVAILABILITY_DATE_TO`)}
                controlLabel={t(`${tKey}AVAILABILITY_DATE_TO`)}
                validate={[this.validateDate]}
                onInputChange={(val) => fieldFill({ availabilityDateTo: val })}
                customClass={fields.availabilityDateTo ? className : ''}
              />
              <Field
                component={InputText}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                id="clientNameDossier"
                name='dossier.name'
                placeholder={t(`${tKey}NAME`)}
                controlLabel={t(`${tKey}NAME`)}
                type="text"
                onInputChange={(val) => fieldFill({ clientName: val })}
                customClass={fields.clientName ? className : ''}
              />
              <Field
                component={InputText}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                id="dniCif"
                name='dossier.dniCif'
                placeholder={t(`${tKey}DNI_CIF`)}
                controlLabel={t(`${tKey}DNI_CIF`)}
                type="text"
                onInputChange={(val) => fieldFill({ dniCif: val })}
                customClass={fields.dniCif ? className : ''}
              />
              <Field
                component={InputText}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                id="emailDossier"
                name='dossier.email'
                placeholder={t(`${tKey}EMAIL`)}
                controlLabel={t(`${tKey}EMAIL`)}
                type="text"
                onInputChange={(val) => fieldFill({ email: val })}
                customClass={fields.email ? className : ''}
              />
              <Field
                component={InputText}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                id="chassisDossier"
                name='dossier.chassis'
                placeholder={t(`${tKey}CHASSIS`)}
                controlLabel={t(`${tKey}CHASSIS`)}
                type="text"
                maxLength={17}
                normalize={value => multipleNormalize(value, [toUpperCase, trimAll])}
                onInputChange={(val) => fieldFill({ chassis: val })}
                customClass={fields.chassis ? className : ''}
              />
              <Field
                component={InputText}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                id="licencePlate"
                name='dossier.licensePlate'
                placeholder={t(`${tKey}LICENSE_PLATE`)}
                controlLabel={t(`${tKey}LICENSE_PLATE`)}
                type="text"
                normalize={value => multipleNormalize(value, [toUpperCase, trimAll])}
                onInputChange={(val) => fieldFill({ licencePlate: val })}
                customClass={fields.licencePlate ? className : ''}
              />
              <Field
                component={InputText}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                id="modelDossier"
                name='dossier.model'
                placeholder={t(`${tKey}MODEL`)}
                controlLabel={t(`${tKey}MODEL`)}
                type="text"
                onInputChange={(val) => fieldFill({ model: val })}
                customClass={fields.model ? className : ''}
              />
              <Field
                component={InputDatePicker}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                maxDate={getDateNowMaxDatePicker()}
                id="vehicleLicensePlateDateFrom"
                name='dossier.vehicleLicensePlateDateFrom'
                placeholder={t(`${tKey}VEHICLE_LICENSE_PLATE_DATE_FROM`)}
                controlLabel={t(`${tKey}VEHICLE_LICENSE_PLATE_DATE_FROM`)}
                validate={[this.validateDate]}
                onInputChange={(val) => fieldFill({ vehicleLicensePlateDateFrom: val })}
                customClass={fields.vehicleLicensePlateDateFrom ? className : ''}
              />
              <Field
                component={InputDatePicker}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                maxDate={getDateNowMaxDatePicker()}
                id="vehicleLicensePlateDateTo"
                name='dossier.vehicleLicensePlateDateTo'
                placeholder={t(`${tKey}VEHICLE_LICENSE_PLATE_DATE_TO`)}
                controlLabel={t(`${tKey}VEHICLE_LICENSE_PLATE_DATE_TO`)}
                validate={[this.validateDate]}
                onInputChange={(val) => fieldFill({ vehicleLicensePlateDateTo: val })}
                customClass={fields.vehicleLicensePlateDateTo ? className : ''}
              />
              {this.state.fieldsConfiguration.rentalOrderIdNumber && this.state.fieldsConfiguration.rentalOrderIdNumber.permission > permissions.hidden &&
                <Field
                  component={InputText}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="rentalOrderIdNumber"
                  name='dossier.rentalOrderNumber'
                  placeholder={t(`${tKey}RENTALORDER_NUMBER`)}
                  controlLabel={t(`${tKey}RENTALORDER_NUMBER`)}
                  type="text"
                  onInputChange={(val) => fieldFill({ rentalOrderIdNumber: val })}
                  customClass={fields.rentalOrderIdNumber ? className : ''}
                />
              }
              { this.state.fieldsConfiguration.findByResponsible && this.state.fieldsConfiguration.findByResponsible.permission > permissions.hidden &&
                <Field
                  component={InputSelect}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="userId"
                  name='dossier.userId'
                  placeholder={t(`${tKey}USER_ID`)}
                  controlLabel={t(`${tKey}USER_ID`)}
                  options={usersCombo}
                  valueKey="id"
                  labelKey="value"
                  onInputChange={(val) => fieldFill({ userId: val })}
                  customClass={fields.userId ? className : ''}
                />
              }
              { this.state.fieldsConfiguration.stockStatusId && this.state.fieldsConfiguration.stockStatusId.permission > permissions.hidden &&
                <Field
                  component={InputSelect}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="stockStatusId"
                  name='dossier.stockStatusId'
                  placeholder={t(`${tKey}STOCK_STATUS_ID`)}
                  controlLabel={t(`${tKey}STOCK_STATUS_ID`)}
                  options={stockStatusCombo}
                  valueKey="id"
                  labelKey="value"
                  onInputChange={ (val) => { fieldFill({ stockStatusId: val }) } }
                  customClass={fields.stockSTatusId ? classNameSelect : ''}
                />
              }
              { this.state.fieldsConfiguration.operationTypeDMSId && this.state.fieldsConfiguration.operationTypeDMSId.permission > permissions.hidden && (
                <Field
                  component={InputSelect}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="operationTypeDMSId"
                  name="dossier.operationTypeDMSId"
                  controlLabel={t(`${tKey}OPERATION_TYPE_DMS_ID`)}
                  placeholder={t(`${tKey}OPERATION_TYPE_DMS_ID`)}
                  valueKey="id"
                  labelKey="description"
                  colSm={3}
                  options={operationTypeDMSCombo}
                  onInputChange={ (val) => { fieldFill({ operationTypeDMSId: val }) } }
                  multi={true}
                />)}
              { settings.clientFieldValidation === clientInformation.PORSCHE &&
                <Field
                  component={InputSelect}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="creatorId"
                  name='dossier.creatorId'
                  placeholder={t(`${tKey}CREATOR_ID`)}
                  controlLabel={t(`${tKey}CREATOR_ID`)}
                  options={usersCombo}
                  valueKey="id"
                  labelKey="value"
                  onInputChange={ (val) => { fieldFill({ creatorId: val }) } }
                  customClass={fields.userId ? className : ''}
                />
              }
              { settings.clientFieldValidation === clientInformation.PORSCHE &&
                <Field
                  component={InputSelect}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="isDenied"
                  name='dossier.isDenied'
                  placeholder={t(`${tKey}IS_DENIED`)}
                  controlLabel={t(`${tKey}IS_DENIED`)}
                  options={this.getYesNoComboOptions()}
                  valueKey="id"
                  labelKey="value"
                  onInputChange={ (val) => { fieldFill({ isDenied: val }) } }
                  customClass={fields.userId ? className : ''}
                />
              }
              { settings.clientFieldValidation === clientInformation.PORSCHE &&
                <Field
                  component={InputSelect}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="proformInvoiceEmited"
                  name='dossier.proformInvoiceEmited'
                  placeholder={t(`${tKey}PROFORM_INVOICE_EMITED`)}
                  controlLabel={t(`${tKey}PROFORM_INVOICE_EMITED`)}
                  options={this.getYesNoComboOptions()}
                  valueKey="id"
                  labelKey="value"
                  onInputChange={ (val) => { fieldFill({ proformInvoiceEmited: val }) } }
                  customClass={fields.userId ? className : ''}
                />
              }
              <div className='search-form-actions flexInLine al-R'>
                <Button id="btn_search"
                  type="submit"
                  className="_Btn accept stk">
                  <i className="iDocIcon-search"/>
                  {t('SEARCH.ACTIONS.SEARCH_BUTTON')}
                </Button>
                <Button
                  id="btn_reset"
                  onClick={(e) => this.handleClickReset(e)}
                  className={'_Btn' + (this.props.pristine ? ' accept' : ' accept')}
                  disabled={this.props.pristine}>
                  <i className="iDocIcon-clear-decline"/>
                  {t('SEARCH.ACTIONS.RESET_BUTTON')}
                </Button>
              </div>
            </div>
          </Form>
        </div>
        <div className="section-results-body">
          <div className={'searchResoultsContainer' + (this.state.maxiTable ? ' maxiTable' : '')}>
            {hasSearch && ([
              <div className="table-wrapper" key="table-wrapper">
                <ul className="list-inline pull-left">
                  {this.selectedFilters().length === 0 ? <li>{t(`${tKey}NO_SELECTED_FILTERS`)}</li> : (this.selectedFilters())}
                </ul>
                { hasResults &&
                  <div className='flexInLine al-R'>
                    <OverlayTrigger placement="left" overlay={<Tooltip id="editProductTooltip">{`${t((this.state.maxiTable ? 'GLOBAL.TABLES.MINIMIZE_TABLE' : 'GLOBAL.TABLES.MAXIMIZE_TABLE'))}`}</Tooltip>}>
                      <a onClick={() => this.toogleMaxiTable()} className='_Btn accept stk o-icn maximizeBtn'><i className={(this.state.maxiTable ? 'iDocIcon-Minimize' : 'iDocIcon-Maximize')}></i></a>
                    </OverlayTrigger>
                    <OverlayTrigger placement="left" overlay={<Tooltip id="editProductTooltip">{t('SEARCH.ACTIONS.EXPORT')}</Tooltip>}>
                      <a onClick={(event) => this.exportData(event)} className='_Btn new o-icn exportToExcel'><i className="iDocIcon-file-excel"></i></a>
                    </OverlayTrigger>
                    {count > 0 &&
                      this.state.fieldsConfiguration.downloadSearchBtn &&
                      this.state.fieldsConfiguration.downloadSearchBtn.permission > permissions.hidden &&
                      <OverlayTrigger placement="left" overlay={<Tooltip id="editProductTooltip">{t(`${tKey}DOWNLOAD_BTN`)}</Tooltip>}>
                        <a onClick={(event) => this.openDownloadModal()} className='_Btn accent '><i className="iDocIcon-download"></i>{t(`${tKey}DOWNLOAD_BTN`)}</a>
                      </OverlayTrigger>
                    }
                  </div>
                }
                {/* table */}
                { hasResults &&
                  <div className="table-responsive">
                    <SimpleTablePage
                      columns={[
                        <th key={0} />,
                        <th key={1} data-field="number" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}DOSSIER_NUMBER`)}&nbsp;<i className={getIconClass('number', this.props.filters)}/>
                        </th>,
                        <th key={2} data-field="organizedUnit" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}ORGANIZED_UNIT`)}&nbsp;<i className={getIconClass('organizedUnit', this.props.filters)}/>
                        </th>,
                        <th key={3} data-field="entity" onClick={(event) => this.onOrderChange(event)}>
                          {t('SEARCH.CATEGORIES.ENTITY.BY_ENTITY')}&nbsp;<i className={getIconClass('entity', this.props.filters)}/>
                        </th>,
                        <th key={4}>{t('SEARCH.CATEGORIES.VEHICLE.BY_VEHICLE')}</th>,
                        <th key={5} data-field="dossierSubType" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}DOSSIER_SUBTYPE`)}&nbsp;<i className={getIconClass('dossierSubType', this.props.filters)}/>
                        </th>,
                        <th key={6} data-field="stage" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}STAGE`)}&nbsp;<i className={getIconClass('stage', this.props.filters)}/>
                        </th>,
                        settings.clientFieldValidation === clientInformation.QUADIS && ([
                          <th key={7} data-field="creator" onClick={(event) => this.onOrderChange(event)}>
                            {t(`${tKey}CREATOR_ID`)}&nbsp;<i className={getIconClass('creator', this.props.filters)}/>
                          </th>,
                          <th key={8} data-field="salesmanManager" onClick={(event) => this.onOrderChange(event)}>
                            {t(`${tKey}SALESMAN_MANAGER`)}&nbsp;<i className={getIconClass('salesmanManager', this.props.filters)}/>
                          </th>
                        ]),
                        <th key={9} data-field="creationDate" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}CREATION_DATE`)}&nbsp;<i className={getIconClass('creationDate', this.props.filters)}/>
                        </th>
                      ]}
                      rows={dossierData && dossierData.map((row, idx) => (
                        <tr key={idx}>
                          <td>
                            <span className={`color-point ${urgencyTypesClass[row.urgencyType] || 'grey'}`}/>
                          </td>
                          <td>
                            <Link to={`/dossier${row.urlToNavigate}`}>
                              {row.number}
                            </Link>
                          </td>
                          <td>{row.organizedUnit}</td>
                          <td className="see-more">
                            <EntityPopover
                              t={t}
                              entity={row.entity}
                              half={(vehicleData.length || 1) / (idx + 1)}
                              idx={idx}
                              id={'popover-entity' + idx}
                              urgencyType={row.urgencyType}
                            />
                          </td>
                          <td className="see-more">
                            <VehiclePopover
                              t={t}
                              vehicle={row.vehicle}
                              half={(vehicleData.length || 1) / (idx + 1)}
                              idx={idx}
                              id={'popover-vehicle' + idx}
                              isHeader = {true}
                              dossierSubTypeId={row.dossierSubtypeId}
                            />
                          </td>
                          <td>{row.dossierSubType}</td>
                          <td>{row.stage}</td>
                          {
                            settings.clientFieldValidation === clientInformation.QUADIS && ([
                              <td>{row.creatorId}</td>,
                              <td>{row.salesManagerName}</td>
                            ])
                          }
                          <td>{row.creationDate}</td>
                        </tr>
                      ))}
                    />
                  </div>
                }
                <div className="search-footer" key="search-footer">
                  {hasResults ? (
                    <IDocCarPagination
                      id="btn_pag_sales"
                      page={page}
                      pagesCount={pages}
                      totalRows= {count}
                      onSelectAction={(page) => this.onPageChange(page)}/>
                  ) : <p className='no_results'>{t('GLOBAL.TABLES.NO_RESULTS')}</p>
                  }
                </div>
              </div>
            ])}
          </div>
        </div>
      </div>
    )
  }
}

const validate = (values, { t }) => {
  const errors = {}
  if (values) {
    if (values.dossier && values.dossier.chassis && values.dossier.chassis.length < settings.chassisMinCharacter) {
      errors.dossier = {}
      errors.dossier.chassis = t('SEARCH.CATEGORIES.ERRORS.VINCHASIS').replace('##MIN_CHARACTERS##', settings.chassisMinCharacter)
    }
    if (values.vehicle && values.vehicle.chassis && values.vehicle.chassis.length < settings.chassisMinCharacter) {
      errors.vehicle = {}
      errors.vehicle.chassis = t('SEARCH.CATEGORIES.ERRORS.VINCHASIS').replace('##MIN_CHARACTERS##', settings.chassisMinCharacter)
    }

    if (values.dossier) {
      errors.dossier = {}
      if (values.dossier && values.dossier.creationDateFrom && values.dossier.creationDateTo) {
        errors.dossier.creationDateTo = validateDates(t, values.dossier.creationDateFrom, values.dossier.creationDateTo)
      }
      if (values.dossier && values.dossier.orderDateFrom && values.dossier.orderDateTo) {
        errors.dossier.orderDateTo = validateDates(t, values.dossier.orderDateFrom, values.dossier.orderDateTo)
      }
      if (values.dossier && values.dossier.availabilityDateFrom && values.dossier.availabilityDateTo) {
        errors.dossier.availabilityDateTo = validateDates(t, values.dossier.availabilityDateFrom, values.dossier.availabilityDateTo)
      }
      if (values.dossier && values.dossier.vehicleLicensePlateDateFrom && values.dossier.vehicleLicensePlateDateTo) {
        errors.dossier.vehicleLicensePlateDateTo = validateDates(t, values.dossier.vehicleLicensePlateDateFrom, values.dossier.vehicleLicensePlateDateTo)
      }
    }
  }

  return errors
}

export default reduxForm({
  form: 'SearchByDossierForm',
  validate
})(SearchByDossierForm)
