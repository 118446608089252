import mastersActionTypes from '../../../constants/actions/masters/masters'

function initialState () {
  return {
    maritalStatusList: [],
    maritalStatusListCount: 0,
    pagesCount: 0,
    values: [],
    filter: {
      page: 1,
      orderBy: '',
      pageSize: 10
    },
    changeTabFromMaster: false,
    ouIdFromMaster: null,
    maritalStatus: null,
    maritalStatusId: null,
    maritalStatusModal: {
      maritalStatus: null,
      maritalStatusId: null,
      showModal: false,
      pagination: {
        page: 1,
        pageSize: 10,
        pagesCount: 0
      },
      filters: {
        orderBy: '+completName',
        page: 1,
        pages: 1,
        maxRows: 10,
        count: 0
      }
    }
  }
}

function fetchMaritalStatusListSuccess (state, { maritalStatusList, filter }) {
  return {
    ...state,
    maritalStatusList: maritalStatusList.maritalStatusList,
    maritalStatusListCount: maritalStatusList.maritalStatusListCount,
    pagesCount: maritalStatusList.pagesCount,
    filter
  }
}

function openEditMaritalStatusModalSuccess (state, { maritalStatus, languageList }) {
  const initial = initialState()
  return {
    ...state,
    maritalStatusModal: {
      ...initial.maritalStatusModal,
      maritalStatus: maritalStatus,
      languageList: languageList,
      showModal: true
    }
  }
}

function openAddUserModalSuccess (state, { userMaritalStatus, maritalStatusId, languageList }) {
  const initial = initialState()
  return {
    ...state,
    maritalStatusId,
    maritalStatusModal: {
      ...initial.maritalStatusModal,
      maritalStatus: userMaritalStatus,
      maritalStatusId: maritalStatusId,
      languageList: languageList,
      showModal: true,
      filters: {
        orderBy: '+completName',
        page: 1,
        pages: 1,
        maxRows: 10,
        count: 0
      }
    }
  }
}

function closeEditModalSuccess (state) {
  return {
    ...state,
    maritalStatusModal: {
      ...initialState().maritalStatusModal
    }
  }
}

export function assingUserMaritalStatus (state, { userId, maritalStatusId, resolve }) {
  return {
    ...state,
    userId,
    maritalStatusId,
    resolve
  }
}

export function deleteUserMaritalStatus (state, { userMaritalStatusId }) {
  return {
    ...state,
    userMaritalStatusId
  }
}

export function fetchMaritalStatusUsersList (state, { maritalStatusId }) {
  return {
    ...state,
    maritalStatusId
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case mastersActionTypes.FETCH_MARITALSTATUS_LIST_SUCCESS:
      return fetchMaritalStatusListSuccess(state, action)
    case mastersActionTypes.OPEN_EDIT_MARITALSTATUS_MODAL_SUCCESS:
      return openEditMaritalStatusModalSuccess(state, action)
    case mastersActionTypes.OPEN_ADD_MARITALSTATUS_MODAL_SUCCESS:
      return openAddUserModalSuccess(state, action)
    case mastersActionTypes.CLOSE_EDIT_MARITALSTATUS_MODAL:
      return closeEditModalSuccess(state, action)
    case mastersActionTypes.SET_CHANGE_TAB_FROM_CONF_MASTER_SUCCESS:
      return fetchMaritalStatusUsersList(state, action)
    default:
      return state
  }
}
