import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import SearchByProcessingAgencyForm from '../../../components/search/SearchByProcessingAgencyForm'
import { translate } from 'react-polyglot'

export function mapStateToProps (state) {
  return {
    filters: state.search.dynamicFilters.processingAgency,
    combos: {
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(SearchByProcessingAgencyForm))
