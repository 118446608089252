import React, { Component } from 'react'
import { Field, reduxForm } from 'redux-form'
import { Form, Glyphicon, Col, Button, Clearfix, Row } from 'react-bootstrap'
import InputDatePicker from '../../commons/form/InputDatePicker'
import { validateDate } from '../../../util/validationFunctions'
import SimpleTablePage from '../../../containers/commons/general/SimpleTablePage'
import InputText from '../../commons/form/InputText'
import InputSelect from '../../commons/form/InputSelect'
import { permissions, massiveDownloadStates } from '../../../constants/backendIds'
import IDocCarPagination from '../../commons/iDocCarPagination'
import { convertStringToDate } from '../../../util/utils'

class DocumentalSearchMassiveDownloadForm extends Component {
  constructor (props) {
    super()
    this.state = {
      page: 1
    }
  }

  submitDocumentalSearchForm (values) {
    this.props.actions.findFilterMassiveDownload({ ...values, ...this.props.filters, page: this.state.page }, (!this.props.emailPermission || this.props.emailPermission.permission === permissions.hidden))
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (prevProps.selectedTabDocument !== this.props.selectedTabDocument) {
      this.props.initialize({})
      const values = []
      this.props.actions.findFilterMassiveDownload({ ...values, ...this.props.filters, page: this.state.page }, (!this.props.emailPermission || this.props.emailPermission.permission === permissions.hidden))
    }
    return null
  }

  componentDidUpdate () {
    // getSnapshotBeforeUpdate() should be used with componentDidUpdate()
  }

  dateValidation (value, allValues, props) {
    const result = validateDate(value, props)
    return result === Object(result) ? undefined : result
  }

  onOrderChange (e) {
    let newOrderBy = ''
    if (this.props.filters.orderBy && this.props.filters.orderBy.includes(`%2B${e.currentTarget.dataset.field}`)) {
      newOrderBy = `-${e.currentTarget.dataset.field}`
    } else {
      newOrderBy = `%2B${e.currentTarget.dataset.field}`
    }
    this.props.actions.findFilterMassiveDownload({ ...this.props.pages, ...this.props.count, ...this.props.dynamicFilters, orderBy: newOrderBy }, (!this.props.emailPermission || this.props.emailPermission.permission === permissions.hidden))
  }

  getIconClass (field) {
    if (this.props.filters.orderBy && this.props.filters.orderBy.includes(field)) {
      if (this.props.filters.orderBy.includes('-')) {
        return 'ico-sort-selected-asc'
      } else {
        return 'ico-sort-selected-desc'
      }
    } else {
      return 'ico-sort'
    }
  }

  downloadFile (massiveDownloadId) {
    this.props.actions.downloadMassiveFile(massiveDownloadId)
  }

  cancelFile (massiveDownloadId) {
    new Promise((resolve, reject) => {
      this.props.actions.cancelMassiveFile(massiveDownloadId, resolve)
    }).then((result) => {
      this.props.actions.findFilterMassiveDownload({ ...this.props.filters, page: this.state.page }, (!this.props.emailPermission || this.props.emailPermission.permission === permissions.hidden))
    })
  }

  render () {
    const {
      t, handleSubmit, pristine, initialize, resetFields, massiveDownloadStatusCombo, emailPermission, massiveDowloadItems, pages, count,
      filters: { page }
      // tabPermission,
    } = this.props
    const tKey = 'DOCUMENT_SEARCH.MASSIVE_DOWNLOAD.'
    return (
      <Form autoComplete="off" className="filters" onSubmit={handleSubmit(this.submitDocumentalSearchForm.bind(this))}>
        <div className="general-form-search">
          <h4><Glyphicon glyph="filter" /> {t(`${tKey}TITLE_FINDER`)}</h4>
          <Row className='flexRow'>
            <Field
              colSm={3}
              id="creationDateFrom"
              name="creationDateFrom"
              controlLabel={t(`${tKey}CREATION_DATE_FROM`)}
              placeholder={t(`${tKey}SINCE`)}
              component={InputDatePicker}
              validate={[this.dateValidation]}
            />
            <Field
              colSm={3}
              id="creationDateTo"
              name="creationDateTo"
              controlLabel={t(`${tKey}CREATION_DATE_TO`)}
              placeholder={t(`${tKey}UNTIL`)}
              component={InputDatePicker}
              validate={[this.dateValidation]}
            />
            <Field
              colSm={3}
              id="massiveDownloadStatesId"
              name="massiveDownloadStatesId"
              controlLabel={t(`${tKey}STATES`)}
              placeholder={t(`${tKey}STATES`)}
              component={InputSelect}
              multi={true}
              options={massiveDownloadStatusCombo}
              valueKey="id"
              labelKey="value"
            />
            <Field
              colSm={3}
              id="fileName"
              name="fileName"
              controlLabel={t(`${tKey}FILE_NAME`)}
              placeholder={t(`${tKey}FILE_NAME`)}
              component={InputText}
            />
            <Field
              colSm={3}
              id="emailUser"
              name="emailUser"
              controlLabel={t(`${tKey}EMAIL_USER`)}
              placeholder={t(`${tKey}EMAIL_USER`)}
              component={InputText}
              disabled = {!emailPermission || emailPermission.permission === permissions.hidden}
            />
          </Row>
          <Clearfix/>

        </div>
        <Col sm={12} className="filters-footer">
          <hr/>
          <Button bsStyle="primary" className={'btn-white btn-icon btn-standard'} type="submit"><Glyphicon glyph="search" /> {t('DOCUMENT_SEARCH.ACTIONS.SEARCH_BUTTON')}</Button>

          <Button
            className={'btn-white btn-icon' + (pristine ? ' btn-cancel' : ' btn-standard')}
            onClick={(e) => {
              if (!pristine) {
                initialize({})
                resetFields()
                this.submitDocumentalSearchForm()
              };
            }}
          >
            <i className="ico-cancel"/> {t('DOCUMENT_SEARCH.ACTIONS.RESET_BUTTON')}
          </Button>
        </Col>
        {massiveDowloadItems &&
        <SimpleTablePage
          columns={[
            <th key={0} data-field="number" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}NUMBER`)} <i className={this.getIconClass('number')}/></th>,
            <th key={1} data-field="fileName" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}FILE_NAME`)} <i className={this.getIconClass('requestedBy')}/></th>,
            <th key={2} data-field="requestedBy" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}REQUESTED_BY`)} <i className={this.getIconClass('requestedBy')}/></th>,
            <th key={3} data-field="creationDate" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}CREATION_DATE`)} <i className={this.getIconClass('creationDate')}/></th>,
            <th key={4} data-field="expirationDate" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}EXPIRATION_DATE`)} <i className={this.getIconClass('expirationDate')}/></th>,
            <th key={5} data-field="state" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}STATE`)} <i className={this.getIconClass('state')}/></th>,
            <th key={6} style={{ width: '30%' }} data-field="observations" >{t(`${tKey}OBSERVATIONS`)} </th>,
            <th key={7} style={{ width: '15%' }} ></th>
          ]}
          rows={massiveDowloadItems.map((row, idx) => (
            <tr key={idx}>
              <td>{row.massiveDownloadId}</td>
              <td>{row.fileName}</td>
              <td>{row.requestUserEmail}</td>
              <td>{row.requestDate}</td>
              <td>{row.expirationDate}</td>
              {row.hasError ? <td className={'has_error'}>{row.statusName}</td> : <td>{row.statusName}</td>}
              {row.hasError ? <td className={'has_error'}>{row.observations}</td> : <td>{row.observations}</td>}
              <td><Button bsStyle="primary" className={'btn-white btn-icon btn-standard'} onClick={(event) => this.downloadFile(row.massiveDownloadId)} disabled= {row.statusId !== massiveDownloadStates.READY}><Glyphicon glyph="download" /> {t(`${tKey}DOWNLOAD`)}</Button>
                <Button bsStyle="warning" className={'btn-red btn-icon'} onClick={(event) => this.cancelFile(row.massiveDownloadId)} disabled= {row.statusId !== massiveDownloadStates.READY && row.statusId !== massiveDownloadStates.PENDING}><i className="ico-cancel"/></Button></td>
            </tr>
          ))}
        />}
        <div className="search-footer">
          <IDocCarPagination
            id="btn_pag_sales"
            page={page}
            pagesCount={pages}
            totalRows= {count}
            onSelectAction={(page) => this.props.actions.findFilterMassiveDownload({ items: massiveDowloadItems, pages, count, ...this.props.dynamicFilters, page })}
          />
        </div>

      </Form>
    )
  }
}

const validate = (values, { t }, props) => {
  const error = {}

  if (values && values.creationDateFrom && values.creationDateTo) {
    let dateTo = convertStringToDate(values.creationDateTo)
    let dateFrom = convertStringToDate(values.creationDateFrom)
    if (dateFrom > dateTo) {
      error.creationDateTo = t('GLOBAL.FORMS.DATE_TO_NOT_LESS_THAN_DATE_FROM')
    }
  }
  return error
}

export default reduxForm({
  form: 'documentalSearchMassiveDownloadForm',
  validate
})(DocumentalSearchMassiveDownloadForm)
