import React, { PureComponent } from 'react'
import Pagination from 'react-js-pagination'

class iDocCarPagination extends PureComponent {
  render () {
    const { label = 'de', page = 1, pageSize = 10, totalRows = 0, maxPages = 2, onSelectAction } = this.props
    return (
      <div className="table-footer">
        {
          totalRows > 0 &&
          <div>
            <nav className="text-center">
              <Pagination
                activePage={parseInt(page)}
                itemsCountPerPage={pageSize}
                totalItemsCount={totalRows}
                pageRangeDisplayed={pageSize}
                onChange={onSelectAction}
                ageRangeDisplayed={maxPages}
              />
              <span style={{ float: 'right', color: 'black', paddingTop: '10px' }}>{pageSize * (page - 1) + 1} - {pageSize * page <= totalRows ? pageSize * page : totalRows} {label} {totalRows}</span>
            </nav>
          </div>
        }
      </div>
    )
  }
}

export default iDocCarPagination
