import apiFetch from '../apiFetch'

export default function (token, dossierId, deliveryCheckListSale, lastAccessDate) {
  return apiFetch({
    endPoint: `dossier/sale/${dossierId}/PrintDeliveryChecklistSale`,
    method: 'POST',
    parseBlobAndAssign: true,
    lastAccessDate,
    token: token,
    body: deliveryCheckListSale
  })
}
