import mastersActionTypes from '../../../constants/actions/masters/masters'

export function fetchDocumentVisualizations (filter) {
  return {
    type: mastersActionTypes.FETCH_DOCUMENT_VISUALIZATIONS,
    filter
  }
}

export function fetchDocumentVisualizationsSuccess (documentVisualizations, filter) {
  return {
    type: mastersActionTypes.FETCH_DOCUMENT_VISUALIZATIONS_SUCCESS,
    documentVisualizations,
    filter
  }
}

export function closeDocumentVisualizationMasterModal () {
  return {
    type: mastersActionTypes.CLOSE_DOCUMENT_VISUALIZATION_MODAL
  }
}

export function submitDocumentVisualization (documentVisualization) {
  return {
    type: mastersActionTypes.SUBMIT_DOCUMENT_VISUALIZATION,
    documentVisualization
  }
}

export function openDocumentVisualizationModal (documentVisualization) {
  return {
    type: mastersActionTypes.OPEN_DOCUMENT_VISUALIZATION_MODAL,
    documentVisualization
  }
}

export function openDocumentVisualizationModalSuccess (documentVisualization) {
  return {
    type: mastersActionTypes.OPEN_DOCUMENT_VISUALIZATION_MODAL_SUCCESS,
    documentVisualization
  }
}

export function deleteDocumentVisualization (documentVisualizationId) {
  return {
    type: mastersActionTypes.DELETE_DOCUMENT_VISUALIZATION,
    documentVisualizationId
  }
}
