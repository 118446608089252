
import React, { PureComponent } from 'react'
import { reduxForm, Field } from 'redux-form'
import { Modal, Col, Button, Row } from 'react-bootstrap'
import { Redirect } from 'react-router-dom'
import InputCheckBox from '../../commons/form/InputCheckBox'
import Promise from 'promise-polyfill'

class DownloadModal extends PureComponent {
  constructor () {
    super()
    this.state = {
      redirect: false,
      step: 0,
      value: 0
    }
  }

  componentDidMount () {}

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.showModal && !this.props.showModal) {
      this.props.initialize({})
      this.setState({ step: 0, value: 0 })
    }
  }

  handleGoProcess () {
    this.setState({ redirect: true }, () => {
      this.props.actions.closeDownloadModal()
    })
  }

  handleAcept () {
    if (this.state.step === 0) {
      const body = {
        DossierList: this.props.data?.dossiersList,
        HistoricalDossierList: this.props.data?.historicalDossiersList,
        DownloadType: this.state.value
      }
      new Promise((resolve, reject) => {
        this.props.actions.postBulkDownload(body, resolve, reject)
      }).then((response) => {
        this.setState({ step: 1 })
      })
    } else {
      this.props.actions.closeDownloadModal()
    }
  }

  handleCheck (val, type) {
    if (val) {
      if (this.state.value === 0) {
        this.setState({ value: type })
      } else {
        this.setState({ value: 3 })
      }
    } else {
      if (this.state.value === 3 && type === 1) {
        this.setState({ value: 2 })
      } else if (this.state.value === 3 && type === 2) {
        this.setState({ value: 1 })
      } else {
        this.setState({ value: 0 })
      }
    }
  }

  render () {
    const {
      t, showModal
    } = this.props
    const tKey = 'SEARCH.CATEGORIES.DOSSIER.DOWNLOAD_MODAL.'
    if (this.state.redirect) {
      return <Redirect to="/management" />
    }
    return (
      <>
        <Modal className="rr-purchase-modal cdaModal" show={showModal} onHide={() => this.props.actions.closeDownloadModal()} backdrop={'static'} >
          <Modal.Header closeButton>
            { this.state.step === 0 && (
              <Modal.Title>{t(`${tKey + 'TITLE'}`)}</Modal.Title>
            )}
          </Modal.Header>
          <Modal.Body>
            { this.state.step === 0 ? (
              <>
                <Col sm={6}>
                  <Field
                    name="data_dossiers"
                    placeholder={(t(`${tKey}DATA_DOSSIERS`)).replace('##NUMBER##', this.props.data?.dossiersCount)}
                    component={InputCheckBox}
                    onChange={(val) => this.handleCheck(val, 1)}
                    colSm={0}
                    value={1}
                  />
                </Col>
                <Col sm={6}>
                  <Field
                    name="documents_dossiers"
                    placeholder={(t(`${tKey}DOCUMENTS_DOSSIERS`)).replace('##NUMBER##', this.props.data?.documentsCount)}
                    component={InputCheckBox}
                    onChange={(val) => this.handleCheck(val, 2)}
                    colSm={0}
                    value={2}
                  />
                </Col>
                <br/>
              </>
            ) : (
              <>
                <Row className='centered'>
                  <Col sm={12}>
                    <p>{t(`${tKey}GENERATE_TEXT`)}</p>
                  </Col>
                </Row>
                <Row className='centered'>
                  <Col sm={12}>
                    <p>{t(`${tKey}EMAIL_TEXT`)}</p>
                  </Col>
                </Row>
                <Row className='centered'>
                  <Col sm={12}>
                    <p>{t(`${tKey}CONSULT_TEXT`)}</p>
                  </Col>
                </Row>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Col sm={6}>
              <Button
                className="btn-standard"
                onClick={() => this.handleAcept()}
                disabled={this.state.value === 0}
              >
                {t(`${tKey}ACEPT`)}
              </Button>
            </Col>
            <Col sm={6} className="btn-wrapper">
              { this.state.step === 0 ? (
                <Button
                  id="role-modal-submit-button"
                  onClick={() => this.props.actions.closeDownloadModal()}
                  className="btn-danger">
                  {t(`${tKey}CANCEL`)}
                </Button>
              ) : (
                <Button
                  id="role-modal-submit-button"
                  onClick={() => this.handleGoProcess()}
                  className="btn-danger"
                >
                  {t(`${tKey}GO_PROCESS`)}
                </Button>
              )}
            </Col>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}

export default reduxForm({
  form: 'DownloadModal',
  shouldValidate: () => true
})(DownloadModal)
