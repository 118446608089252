
import React, { PureComponent } from 'react'
import { reduxForm, Field } from 'redux-form'
import { Modal, Button } from 'react-bootstrap'
import { Redirect } from 'react-router-dom'
import InputCheckBox from '../../../../_v2/components/commons/form/InputCheckBox'
import Promise from 'promise-polyfill'

class DownloadModal extends PureComponent {
  constructor () {
    super()
    this.state = {
      redirect: false,
      step: 0,
      value: 0
    }
  }

  componentDidMount () {}

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.showModal && !this.props.showModal) {
      this.props.initialize({})
      this.setState({ step: 0, value: 0 })
    }
  }

  handleGoProcess () {
    this.setState({ redirect: true }, () => {
      this.props.actions.closeDownloadModal()
    })
  }

  handleAcept () {
    if (this.state.step === 0) {
      const body = {
        DossierList: this.props.data?.dossiersList,
        HistoricalDossierList: this.props.data?.historicalDossiersList,
        DownloadType: this.state.value
      }
      new Promise((resolve, reject) => {
        this.props.actions.postBulkDownload(body, resolve, reject)
      }).then((response) => {
        this.setState({ step: 1 })
      })
    } else {
      this.props.actions.closeDownloadModal()
    }
  }

  handleCheck (val, type) {
    if (val) {
      if (this.state.value === 0) {
        this.setState({ value: type })
      } else {
        this.setState({ value: 3 })
      }
    } else {
      if (this.state.value === 3 && type === 1) {
        this.setState({ value: 2 })
      } else if (this.state.value === 3 && type === 2) {
        this.setState({ value: 1 })
      } else {
        this.setState({ value: 0 })
      }
    }
  }

  render () {
    const {
      t, showModal
    } = this.props
    const tKey = 'SEARCH.CATEGORIES.DOSSIER.DOWNLOAD_MODAL.'
    if (this.state.redirect) {
      return <Redirect to="/management" />
    }
    return (
      <>
        <Modal className="_nf DownloadModal" show={showModal} onHide={() => this.props.actions.closeDownloadModal()} backdrop={'static'} >
          <Modal.Header closeButton>
            { this.state.step === 0 && (
              <Modal.Title>{t(`${tKey + 'TITLE'}`)}</Modal.Title>
            )}
            { this.state.step !== 0 && (
              <Modal.Title>{t(`${tKey}GO_PROCESS`)}</Modal.Title>
            )}
          </Modal.Header>
          <Modal.Body>
            <div className='search-form'>
              { this.state.step === 0 ? (
                <>
                  <Field
                    component={InputCheckBox}
                    colWidht={50} // 25, 33, 50, 75, 100 default=25
                    name="data_dossiers"
                    placeholder={(t(`${tKey}DATA_DOSSIERS`)).replace('##NUMBER##', this.props.data?.dossiersCount)}
                    onChange={(val) => this.handleCheck(val, 1)}
                    value={1}
                  />
                  <Field
                    component={InputCheckBox}
                    colWidht={50} // 25, 33, 50, 75, 100 default=25
                    name="documents_dossiers"
                    placeholder={(t(`${tKey}DOCUMENTS_DOSSIERS`)).replace('##NUMBER##', this.props.data?.documentsCount)}
                    onChange={(val) => this.handleCheck(val, 2)}
                    value={2}
                  />
                </>
              ) : (
                <div className='infoText'>
                  <p>{t(`${tKey}GENERATE_TEXT`)}</p>
                  <p>{t(`${tKey}EMAIL_TEXT`)}</p>
                  <p>{t(`${tKey}CONSULT_TEXT`)}</p>
                </div>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className='modalFooterContent'>
              <Button
                className="_Btn accept"
                onClick={() => this.handleAcept()}
                disabled={this.state.value === 0}>
                {t(`${tKey}ACEPT`)}
              </Button>
              { this.state.step === 0 ? (
                <Button
                  id="role-modal-submit-button"
                  onClick={() => this.props.actions.closeDownloadModal()}
                  className="_Btn cancel">
                  {t(`${tKey}CANCEL`)}
                </Button>
              ) : (
                <Button
                  id="role-modal-submit-button"
                  onClick={() => this.handleGoProcess()}
                  className="_Btn accent">
                  {t(`${tKey}GO_PROCESS`)}
                </Button>
              )}
            </div>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}

export default reduxForm({
  form: 'DownloadModal',
  shouldValidate: () => true
})(DownloadModal)
