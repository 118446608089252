import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { submitPaymentMethod, closeEditPaymentMethodModal } from '../../../actions/masters/masters'
import PaymentMethodMasterModal from '../../../components/masters/paymentMethod/PaymentMethodMasterModal'

export function mapStateToProps (state) {
  const paymentMethodModal = state.paymentMethod.paymentMethodModal
  return {
    languageList: state.paymentMethod.languageList,
    showModal: paymentMethodModal.showModal,
    paymentMethod: paymentMethodModal.paymentMethod,
    paymentMethodTypeLanguages: state.paymentMethod.paymentMethodFields.paymentMethodLanguage || [],
    paymentMethodId: state.paymentMethod.paymentMethodId ? state.paymentMethod.paymentMethodId : null
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      submitPaymentMethod,
      closeEditPaymentMethodModal
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(PaymentMethodMasterModal))
