import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import ActionDataModal from '../../components/management/ActionDataModal'
import { translate } from 'react-polyglot'
import { fetchOperationType, fetchSaleTypeBySubtype } from '../../actions/combos/combos'
import { closeActionDataModal } from '../../actions/masters/masters'
import { saveFleetDataChanges, fetchPaymentCombo } from '../../actions/management/management'
import { setDossierFleetDataChangeProcess } from '../../actions/unattendedProcess/unattendedProcess'
import { openModal } from '../../actions/common'
export function mapStateToProps (state) {
  return {
    ...state.management.actionDataModal,
    combos: {
      saleTypeCombo: state.combos.saleTypeCombo,
      operationTypeCombo: state.combos.operationTypeCombo,
      paymentMethodCombo: state.management.paymentCombo
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchOperationType,
      closeActionDataModal,
      fetchSaleTypeBySubtype,
      fetchPaymentCombo,
      saveFleetDataChanges,
      openModal,
      setDossierFleetDataChangeProcess
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(ActionDataModal))
