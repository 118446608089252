import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import SearchCommercialReport from '../../../_v2/components/report/SearchCommercialReport'
import { translate } from 'react-polyglot'
import { fetchBrands } from '../../../actions/combos/combos'
import { fetchReportCommercial, fetchReportCommercialTable, fetchReportCommercialDownload, setFilters, resetFilters } from '../../../actions/search/search'
import { openModal } from '../../../actions/common'

export function mapStateToProps (state) {
  return {
    ...state.search,
    combos: {
      UOTreeCombo: state.combos.UOTreeCombo,
      brandCombo: state.combos.brandCombo
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchBrands,
      fetchReportCommercial,
      fetchReportCommercialTable,
      fetchReportCommercialDownload,
      setFilters,
      resetFilters,
      openModal
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(SearchCommercialReport))
