import React, { PureComponent } from 'react'
import { Field } from 'redux-form'
import { Row, Clearfix, Button } from 'react-bootstrap'
import InputSelect from '../commons/form/InputSelect'
import InputDatePicker from '../commons/form/InputDatePicker'
import { validateDate } from '../../util/validationFunctions'
import SimpleTablePage from '../../containers/commons/general/SimpleTablePage'
import IDocCarPagination from '../commons/iDocCarPagination'
import InputText from '../commons/form/InputText'
import { dateToApply, permissions, clientInformation } from '../../constants/backendIds'

import InputCheckbox from '../commons/form/InputCheckBox'
import { multipleNormalize, toUpperCase, trimAll } from '../../util/normalizeFunctions'
import { formNames } from '../../constants/search/formNames'
import VehiclePopover from './VehiclePopover'
import DossierPopover from './DossierPopover'
import moment from 'moment'
import SetAvailabilityDateModal from './papServiceManager/SetAvailabilityDateModal'
import { getFieldsSectionPermissions } from '../../util/permisionFunctions'
import settings from '../../setting'

class SearchByPapForm extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      enableConfirmButton: false,
      showConfirmButton: false,
      dossierIdArray: [],
      availabilityDate: null,
      showConfirmationModal: false,
      selectAllDossiers: false,
      fieldsConfiguration: {
        edit_availability_date: null
      },
      loadedFieldsConfiguration: false
    }
  }

  validateDate (value, props) {
    const result = validateDate(value, props)
    return result === Object(result) ? undefined : result
  }

  exportData () {
    const tKey = 'SEARCH.CATEGORIES.PAP_SERVICES_MANAGER.'
    let columnsExcel = []
    columnsExcel.push(this.props.t(`${tKey}DOSSIER`).toUpperCase() + '#Dossier')
    columnsExcel.push(this.props.t(`${tKey}MODEL`).toUpperCase() + '#VehicleModel')
    columnsExcel.push(this.props.t(`${tKey}VEHICLE`).toUpperCase() + '#Vehicle')
    columnsExcel.push(this.props.t(`${tKey}ORGANIZED_UNIT`).toUpperCase() + '#OrganizedUnit')
    columnsExcel.push(this.props.t(`${tKey}DESTINY`).toUpperCase() + '#Destination')
    columnsExcel.push(this.props.t(`${tKey}APPLICATION_DATE`).toUpperCase() + '#ApplicationDate')
    columnsExcel.push(this.props.t(`${tKey}AVAILABILITY_DATE`).toUpperCase() + '#AvailabilityDate')
    columnsExcel.push(this.props.t(`${tKey}REQUIRED_DATE`).toUpperCase() + '#RequiredDate')
    this.props.exportToExcel(columnsExcel)
  }

  getSnapshotBeforeUpdate () {
    return null
  }

  componentDidUpdate (prevProps) {
    if ((this.props.permissions && this.props.permissions.sectionFieldsConfiguration) &&
      ((!prevProps.permissions || !prevProps.permissions.sectionFieldsConfiguration) || !this.state.loadedFieldsConfiguration)) {
      const fieldKeys = Object.keys(this.state.fieldsConfiguration)
      const permission = getFieldsSectionPermissions(this.props.permissions.sectionFieldsConfiguration, fieldKeys, this.props.permissions.permission)
      this.setState({ fieldsConfiguration: permission.fieldsConfiguration, loadedFieldsConfiguration: true })
    }

    if (this.props.hasSearch !== prevProps.hasSearch && this.props.hasSearch) {
      this.restartState()
    }
  }

  restartState () {
    this.setState({
      enableConfirmButton: false,
      dossierIdArray: [],
      availabilityDate: null,
      showConfirmationModal: false,
      selectAllDossiers: false
    })
  }

  selectAllDossiers (value) {
    const dossiers = []
    if (this.props.dossierData) {
      this.props.dossierData.map((row, idx) => {
        if (value) {
          dossiers.push(row.dossierId)
        }
      })
    }
    this.setState({ selectAllDossiers: value, enableConfirmButton: value, dossierIdArray: dossiers })
  }

  setCheckboxValue (dossierId, check) {
    const dossierIdArray = this.state.dossierIdArray
    const index = dossierIdArray.findIndex(d => d === dossierId)
    if (index < 0) {
      dossierIdArray.push(dossierId)
    } else {
      dossierIdArray.splice(index, 1)
    }
    const selectedDossiersCount = dossierIdArray.length
    this.setState({ dossierIdArray: dossierIdArray, enableConfirmButton: selectedDossiersCount > 0 })

    if (!check && this.state.selectAllDossiers) {
      this.setState({ selectAllDossiers: false })
    }

    if (this.props.dossierData && dossierIdArray.length === this.props.dossierData.length) {
      this.setState({ selectAllDossiers: true })
    }
  }

  getCheckboxValue (dossierId) {
    return this.state.dossierIdArray.includes(dossierId)
  }

  showConfirmationModal () {
    this.setState({ showConfirmationModal: true })
  }

  saveServicesAvailabilityDate (availabilityDate) {
    new Promise((resolve, reject) => {
      var fields = {
        DateFrom: this.props.fields.dataFrom,
        DateTo: this.props.fields.dataTo,
        DateToApply: this.props.fields.dateToApply,
        VinChassis: this.props.fields.vinchassis
      }
      return this.props.actions.saveServicesAvailabilityDate(this.state.dossierIdArray, availabilityDate, resolve, reject, fields)
    }).then(() => {
      this.selectAllDossiers(false)
      this.closeConfirmationModal()
      this.props.onPageChange(this.props.page)
    })
  }

  onClickResetSearch (event) {
    this.selectAllDossiers(false)
    this.props.onClickResetSearch(event)
  }

  changeSelectedDateToApply (val) {
    this.props.fieldFill({ dateToApply: val })
    this.setState({ showConfirmButton: val === dateToApply.APPLICATION_DATE })
  }

  closeConfirmationModal () {
    this.setState({ showConfirmationModal: false })
  }

  componentWillUnmount () {
    this.restartState()
  }

  render () {
    const {
      t, fields, fieldFill, className, classNameSelect, hasSearch, dossierData = [], getIconClass, selectedFilters
      , pages, page, count, onPageChange, onOrderChange, vehicleData = [], dateToApplyCombo
    } = this.props
    const tKey = 'SEARCH.CATEGORIES.PAP_SERVICES_MANAGER.'
    return (
      <div>
        <SetAvailabilityDateModal
          showModal = {this.state.showConfirmationModal}
          closeModal = {() => this.closeConfirmationModal() }
          saveServicesAvailabilityDate = { (availabilityDate) => this.saveServicesAvailabilityDate(availabilityDate) }
          t = {t}
          disabledDate={
            (!this.state.fieldsConfiguration.edit_availability_date ||
            !this.state.fieldsConfiguration.edit_availability_date.permission > permissions.hidden) &&
            settings.clientFieldValidation === clientInformation.VGR}
        />
        {!hasSearch ? (
          <Row>
            <Field
              id='vinchassis'
              name = {`${formNames.PAP_SERVICES_MANAGER}.vinchassis`}
              placeholder={t(`${tKey}VINCHASSIS`)}
              controlLabel={t(`${tKey}VINCHASSIS`)}
              component={InputText}
              onInputChange={(val) => fieldFill({ vinchassis: val })}
              maxLength = {17}
              type="text"
              normalize={value => multipleNormalize(value, [toUpperCase, trimAll])}
              customClass={fields.vinchassis ? className : ''}
            />
            <Field
              colSm={3}
              id='dateFrom'
              name={`${formNames.PAP_SERVICES_MANAGER}.dateFrom`}
              placeholder={t(`${tKey}DATE_FROM`)}
              controlLabel={t(`${tKey}DATE_FROM`)}
              component={InputDatePicker}
              validate={[this.validateDate]}
              onInputChange={(val) => fieldFill({ dataFrom: val })}
              customClass={fields.dataFrom ? className : ''}
            />
            <Field
              colSm={3}
              id='dateTo'
              name={`${formNames.PAP_SERVICES_MANAGER}.dateTo`}
              placeholder={t(`${tKey}DATE_TO`)}
              controlLabel={t(`${tKey}DATE_TO`)}
              component={InputDatePicker}
              validate={[this.validateDate]}
              onInputChange={(val) => fieldFill({ dataTo: val })}
              customClass={fields.dataTo ? className : ''}
            />
            <Field
              id= 'dateToApply'
              name={`${formNames.PAP_SERVICES_MANAGER}.dateToApply`}
              placeholder={t(`${tKey}DATE_TO_APPLY`)}
              controlLabel={t(`${tKey}DATE_TO_APPLY`)}
              component={InputSelect}
              menuContainerStyle={{ zIndex: 999 }}
              options={dateToApplyCombo}
              valueKey="id"
              labelKey="value"
              onInputChange={(val) => this.changeSelectedDateToApply(val)}
              customClass={fields.dateToApply ? classNameSelect : ''}
            />
          </Row>
        ) : ([
          <Row className="filters-selected" key="filters-selected">
            <ul className="list-inline pull-left">
              {selectedFilters().length === 0 ? null : (selectedFilters())}
            </ul>
            <Clearfix/>
            <Button
              onClick={(event) => this.onClickResetSearch(event)}
              bsStyle="default"
              bsSize="small"
              className="pull-left">
              {t('SEARCH.ACTIONS.MODIFY_SEARCH')}
              <i className="ico-edit-turquoise" />
            </Button>
            {count > 0 &&
            <Button
              onClick={(event) => this.exportData(event)}
              bsStyle="default"
              bsSize="small">
              {t('SEARCH.ACTIONS.EXPORT')}
              <i className="ico-if_Download_1031520" />
            </Button>
            }
          </Row>,
          <div className="table-wrapper" key="table-wrapper">
            <SimpleTablePage
              columns={[
                <th key={0} data-field="dossier" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}DOSSIER`)}&nbsp;<i className={getIconClass('dossier')}/>
                </th>,
                <th key={2} data-field="vehicleModel" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}MODEL`)}&nbsp;<i className={getIconClass('vehicleModel')}/>
                </th>,
                <th key={1} data-field="vehicle">
                  {t(`${tKey}VEHICLE`)}&nbsp;
                </th>,
                <th key={3} data-field="organizedUnit" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}ORGANIZED_UNIT`)}&nbsp;<i className={getIconClass('organizedUnit')}/>
                </th>,
                <th key={4} data-field="destination" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}DESTINY`)}&nbsp;<i className={getIconClass('destination')}/>
                </th>,
                <th key={5} data-field="applicationDate" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}APPLICATION_DATE`)}&nbsp;<i className={getIconClass('applicationDate')}/>
                </th>,
                <th key={6} data-field="availabilityDate" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}AVAILABILITY_DATE`)}&nbsp;<i className={getIconClass('availabilityDate')}/>
                </th>,
                <th key={7} data-field="requiredDate" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}REQUIRED_DATE`)}&nbsp;<i className={getIconClass('requiredDate')}/>
                </th>,
                this.state.showConfirmButton && (
                  <th key={8} data-field="selectAllDossiers" >
                    <Field
                      id='chkSelectAll'
                      component={InputCheckbox}
                      name='selectAllDossiers'
                      onChange={(value) => this.selectAllDossiers(value)}
                      placeholder = {t(`${tKey}SELECT_ALL`)}
                      inline
                      checked = { this.state.selectAllDossiers }
                    />
                  </th>)
              ]}
              rows={dossierData.map((row, idx) => (
                <tr key={idx}>
                  <td className={'dossier see-more'}>
                    <DossierPopover
                      idx={idx}
                      t={t}
                      id={'popover-dossier' + idx}
                      dossier={row.dossier}
                    />
                  </td>
                  <td className="see-more">
                    <VehiclePopover
                      t={t}
                      vehicle={row.vehicle}
                      half={(vehicleData.length || 1) / (idx + 1)}
                      idx={idx}
                      id={'popover-vehicle' + idx}
                    />
                  </td>
                  <td>{row.vehicleModel}</td>
                  <td>{row.organizedUnit}</td>
                  <td>{row.destination}</td>
                  <td>{row.applicationDate ? moment(row.applicationDate).format('DD/MM/YYYY') : null}</td>
                  <td>{row.availabilityDate ? moment(row.availabilityDate).format('DD/MM/YYYY') : null}</td>
                  <td>{row.requiredDate ? moment(row.requiredDate).format('DD/MM/YYYY') : null}</td>
                  { this.state.showConfirmButton && (
                    <td>{<Field
                      component={InputCheckbox}
                      name={'selectedDossier' + row.dossierId}
                      onChange={(value) => this.setCheckboxValue(row.dossierId, value)}
                      checked = {this.getCheckboxValue(row.dossierId)}
                    />}</td>)}
                </tr>
              ))}
            />
          </div>,
          <div className="search-footer" key="search-footer">
            <IDocCarPagination
              id="btn_pag_dossiers"
              page={page}
              pagesCount={pages}
              totalRows= {count}
              onSelectAction={(page) => onPageChange(page)}
            />
          </div>,
          <div key="search-footer-actions" className="search-footer-actions">
            { this.state.showConfirmButton &&
              <Button
                name='confirmPapButton'
                onClick={(event) => this.showConfirmationModal(event)}
                className="btn-AcceptPap"
                disabled={!this.state.enableConfirmButton } >
                {t(`${tKey}CONFIRM`)}
              </Button>
            }
          </div>
        ])}
      </div>
    )
  }
}

export default SearchByPapForm
