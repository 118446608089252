
import React, { PureComponent } from 'react'
import { reduxForm } from 'redux-form'
import { Row, Col, Table, Button, Tooltip, OverlayTrigger } from 'react-bootstrap'
import IDocCarPagination from '../../../commons/iDocCarPagination'
import ProcessingAgencyIntegrationRegistrationTypesMasterModalPage from '../../../../containers/masters/ProcessingAgency/processingAgencyIntegrations/ProcessingAgencyIntegrationRegistrationTypesMasterModalPage'

class ProcessingAgencyIntegrationRegistrationTypesMaster extends PureComponent {
  constructor () {
    super()
    this.state = {
      initialized: false,
      filter: {
      }
    }
  }

  getSortIcon (column = '') {
    switch (this.props.filter.orderBy) {
      case '+' + column:
        return 'ico-sort-selected-asc'
      case '-' + column:
        return 'ico-sort-selected-desc'
      default:
        return 'ico-sort'
    }
  }

  componentDidMount () {
    this.props.actions.fetchProcessingAgencyRegistrationTypesList(this.props.agency.processingAgencyId, { ...this.state.filter, ...this.props.filter })
    this.props.actions.fetchOperationTypeAll()
  }

  changeOrder (field) {
    switch (this.props.filter.orderBy) {
      case `+${field}`:
        this.props.actions.fetchProcessingAgencyRegistrationTypesList(this.props.agency.processingAgencyId, { ...this.state.filter, ...this.props.filter, orderBy: `-${field}` })
        break
      default:
        this.props.actions.fetchProcessingAgencyRegistrationTypesList(this.props.agency.processingAgencyId, { ...this.state.filter, ...this.props.filter, orderBy: `+${field}` })
        break
    }
  }

  render () {
    const {
      t, processingAgencyRegistrationTypes, pagesCount, filter, processingAgencyRegistrationTypesCount,
      filter: { page },
      actions: { showProcessingAgencyRegistrationTypesModal }
    } = this.props
    const tKey = 'MASTERS.PROCESSING_AGENCY_MASTERS.REGISTRATION_TYPES.'
    const editTooltip = (<Tooltip id="editTooltip">{t(`${tKey}EDIT`)}</Tooltip>)
    const deleteTooltip = (<Tooltip id="deleteTooltip">{t(`${tKey}DELETE`)}</Tooltip>)
    return (
      <Col className="admin-wrapper masters-process-agency-padding">
        <ProcessingAgencyIntegrationRegistrationTypesMasterModalPage />
        <Row className="section-users-header">
          <Col sm={12}>
            <div className="header-panel">
              <h4>{t(`${tKey}REGISTRATION_TYPES`)}</h4>
            </div>
          </Col>
        </Row>
        <Table className="editable-table" hover responsive>
          <thead>
            <tr>
              <th onClick={() => { this.changeOrder('dossierOperationTypeLocale') }}>
                {t(`${tKey}OPERATION_TYPE`)}
                <i className={this.getSortIcon('dossierOperationTypeLocale')} />
              </th>
              <th onClick={() => { this.changeOrder('registrationTypeId') }}>
                {t(`${tKey}REGISTRATION_TYPE`)}
                <i className={this.getSortIcon('registrationTypeId')} />
              </th>
              <th style={{ width: '77px' }}>{t('DOSSIER_COMPONENTS.ACCESSORIES.ACTIONS')}</th>
            </tr>
          </thead>
          <tbody>
            {processingAgencyRegistrationTypes && processingAgencyRegistrationTypes.map((processingAgencyRegistrationType, idx) => {
              return (
                <tr key={idx} className={processingAgencyRegistrationType.isDeleted ? 'is-deleted' : ''}>
                  <td>{processingAgencyRegistrationType.dossierOperationTypeLocale}</td>
                  <td>{processingAgencyRegistrationType.registrationTypeId}</td>
                  <td>
                    {!processingAgencyRegistrationType.isDeleted && (
                      <div>
                        <a onClick={() => showProcessingAgencyRegistrationTypesModal(this.props.agency.processingAgencyId, true, processingAgencyRegistrationType)}>
                          <OverlayTrigger placement="left" overlay={editTooltip}>
                            <i className="ico-edit-white" />
                          </OverlayTrigger>
                        </a>
                        <a onClick={() => this.props.actions.deleteProcessingAgencyRegistrationTypes(this.props.agency.processingAgencyId, processingAgencyRegistrationType.processingAgencyRegistrationTypeId)}>
                          <OverlayTrigger placement="left" overlay={deleteTooltip}>
                            <i className="ico-trash" />
                          </OverlayTrigger>
                        </a>
                      </div>
                    )}
                  </td>
                </tr>)
            })
            }
            {(processingAgencyRegistrationTypes === null || processingAgencyRegistrationTypes === undefined || processingAgencyRegistrationTypes.length === 0) &&
          <tr>
            <td colSpan={10}><h2>{t(`${tKey}RESULTS_NOT_FOUND`)}</h2></td>
          </tr>}
            <tr className="button-table-wrapper">
              <td>
                <Button
                  className="btn-editable-table btn-standard"
                  onClick={() => showProcessingAgencyRegistrationTypesModal(this.props.agency.processingAgencyId, true)}
                >
                  {t(`${tKey}ADD`)}
                </Button>
              </td>
            </tr>
          </tbody>
        </Table>
        <div className="section-users-footer">
          <nav key={1} className="text-center">
            <IDocCarPagination
              id="btn_pag_sales"
              page={page}
              pagesCount={pagesCount}
              totalRows={processingAgencyRegistrationTypesCount}
              onSelectAction={(value) => this.props.actions.fetchProcessingAgencyRegistrationTypesList(this.props.agency.processingAgencyId, { ...filter, ...this.state.filter, page: value })}
            />
          </nav>
        </div>
      </Col>
    )
  }
}

export default reduxForm({
  form: 'editProcessingAgencyIntegrationRegistrationTypesMaster',
  validate: (values, props) => {
  }
})(ProcessingAgencyIntegrationRegistrationTypesMaster)
