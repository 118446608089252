import React, { PureComponent } from 'react'
import { Button, Modal, Col, Row } from 'react-bootstrap'
import { reduxForm } from 'redux-form'

class ImportVehicleModelsResultsModal extends PureComponent {
  render () {
    const {
      t, showModal,
      importResults: {
        errorExcel,
        sheetColumnErrors,
        totalCarlines,
        totalCatalogues,
        totalModels,
        totalCatalogueModels,
        totalColors,
        totalTapestries,
        error
      }, closeModal
    } = this.props
    const tKey = 'MASTERS.VEHICLE_MODELS.IMPORT_RESULTS_MODAL.'

    return (
      <Modal bsSize="large" show={showModal} backdrop={'static'}>
        { error
          ? <React.Fragment>
            <Modal.Header>
            </Modal.Header>
            <Modal.Body>

              {errorExcel &&
              <Row>
                {t(`${tKey}${errorExcel}`)}
              </Row>}

              {sheetColumnErrors &&
              sheetColumnErrors.map((error) => {
                return (
                  <Row>
                    { t(`${tKey}ERROR_SHEET_COLUMN`)
                      .replace('{{SHEET}}', error.sheetName)
                      .replace('{{ROW}}', error.rowNum)
                      .replace('{{FIELD}}', error.column) }
                  </Row>)
              }
              )
              }
            </Modal.Body>

          </React.Fragment>
          : <React.Fragment>
            <Modal.Header>
            </Modal.Header>
            <Modal.Body>
              <Row>
                {t(`${tKey}CREATED`)}
              </Row>
              <Row >
                {totalCarlines + ' ' + t(`${tKey}CARLINES`)}
              </Row>
              <Row >
                {totalCatalogues + ' ' + t(`${tKey}CATALOGUES`)}
              </Row>
              <Row >
                {totalModels + ' ' + t(`${tKey}MODELS`)}
              </Row>
              <Row >
                {totalCatalogueModels + ' ' + t(`${tKey}CATALOGUE_MODELS`)}
              </Row>
              <Row >
                {totalColors + ' ' + t(`${tKey}COLORS`)}
              </Row>
              <Row>
                {totalTapestries + ' ' + t(`${tKey}TAPESTRIES`)}
              </Row>
            </Modal.Body>
          </React.Fragment>
        }

        <Modal.Footer>
          <Col>
            <Button onClick={closeModal} className="btn-standard">
              {t(`${tKey}ACCEPT`)}
            </Button>
          </Col>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default reduxForm({
  form: 'ImportVehicleModelsResultsModal'
})(ImportVehicleModelsResultsModal)
