import { put, call, takeEvery, select } from 'redux-saga/effects'
import { reset } from 'redux-form'
import { getAuth } from '../../../selectors/access/auth'
import commentsActionsTypes from '../../../constants/actions/dossier/common/comments'
import messageModalActions from '../../../actions/modals/messageModal'
import { fetchSalesDossierSuccess } from '../../../actions/dossier/sales'
import { fetchPurchaseDossierSuccess } from '../../../actions/dossier/purchase'
import { fetchPaperworkDossierSuccess } from '../../../actions/dossier/paperwork'
import { fetchCampaignDossierSuccess } from '../../../actions/dossier/campaign'
import { fetchRegistrationDeliveryDossierSuccess } from '../../../actions/dossier/registrationDelivery'
import { fetchServiceDossierSuccess } from '../../../actions/dossier/serviceDossier'
import { fetchStockDossierSuccess } from '../../../actions/dossier/stockDossier'
import { fetchStockDossierCommentSuccess, fetchSalesCommentsSuccess, fetchPaperworkCommentsSuccess, fetchPurchaseCommentsSuccess, fetchCampaignCommentsSuccess, fetchServiceDossierCommentSuccess, fetchRegistrationDeliveryCommentSuccess } from '../../../actions/dossier/common/comments'
import { showLoader, hideLoader } from '../../../actions/common'
import postCommentService from '../../../services/comment/postComment'
import deleteCommentService from '../../../services/comment/deleteComment'
import putCommentService from '../../../services/comment/putComment'
import getCommentsService from '../../../services/comment/getComments'
import getCommentsHistoricalService from '../../../services/comment/getCommentsHistorical'
import markAsRead from '../../../services/comment/markAsRead'
import { handleError } from '../../errors/errorManager'
import { dossierTypes } from '../../../constants/dossier/common/dossierTypes'

function * getDossierId (dossierType) {
  let dossierId = null
  switch (dossierType) {
    case 'salesDossier':
      dossierId = yield select(state => state.salesDossier.dossierId)
      break
    case 'paperworkDossier':
      dossierId = yield select(state => state.paperworkDossier.dossierId)
      break
    case 'purchaseDossier':
      dossierId = yield select(state => state.purchaseDossier.dossierId)
      break
    case 'campaignDossier':
      dossierId = yield select(state => state.campaignDossier.dossierId)
      break
    case 'registrationDeliveryDossier':
      dossierId = yield select(state => state.registrationDeliveryDossier.dossierId)
      break
    case dossierTypes.stock:
      dossierId = yield select(state => state.stockDossier.dossierId)
      break
    case dossierTypes.service:
      dossierId = yield select(state => state.serviceDossier.dossierId)
      break
  }
  return dossierId
}

function * setComments (dossierType, comments) {
  switch (dossierType) {
    case 'salesDossier':
      yield put(fetchSalesCommentsSuccess(comments))
      yield put(fetchSalesDossierSuccess({ unreadComments: 0 }))
      break
    case 'paperworkDossier':
      yield put(fetchPaperworkCommentsSuccess(comments))
      yield put(fetchPaperworkDossierSuccess({ unreadComments: 0 }))
      break
    case 'purchaseDossier':
      yield put(fetchPurchaseCommentsSuccess(comments))
      yield put(fetchPurchaseDossierSuccess({ unreadComments: 0 }))
      break
    case 'campaignDossier':
      yield put(fetchCampaignCommentsSuccess(comments))
      yield put(fetchCampaignDossierSuccess({ unreadComments: 0 }))
      break
    case 'registrationDeliveryDossier':
      yield put(fetchRegistrationDeliveryCommentSuccess(comments))
      yield put(fetchRegistrationDeliveryDossierSuccess({ unreadComments: 0 }))
      break
    case dossierTypes.service:
      yield put(fetchServiceDossierCommentSuccess(comments))
      yield put(fetchServiceDossierSuccess({ unreadComments: 0 }))
      break
    case dossierTypes.stock:
      yield put(fetchStockDossierCommentSuccess(comments))
      yield put(fetchStockDossierSuccess({ unreadComments: 0 }))
      break
  }
}

export function * createComment ({ newComment, resolve, reject, dossierType }) {
  try {
    const dossierId = yield getDossierId(dossierType)

    const auth = yield select(getAuth)
    yield put(showLoader())
    yield call(postCommentService, newComment, dossierId, auth.token)
    yield put(messageModalActions.openSuccessMessageModal('MESSAGES.POST_TABLE_ITEM_SUCCESS'))
    const comments = yield call(getCommentsService, dossierId, auth.token)
    yield setComments(dossierType, comments)
    yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.POST_TABLE_ITEM_ERROR', customHandlerError.json ? customHandlerError.json.tag : ''))
      yield call(reject, customHandlerError)
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('purchase_dossier'))
      yield put(reset('paperwork_dossier'))
      yield put(reset('campaign_dossier'))
      yield put(reset('registrationDeliverydossier'))
      yield put(reset('service_dossier'))
      yield put(reset('stock_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchCreateComment () {
  yield takeEvery(commentsActionsTypes.CREATE_COMMENT, createComment)
}

export function * deleteComment ({ comment, dossierType }) {
  try {
    const dossierId = yield getDossierId(dossierType)
    const auth = yield select(getAuth)
    yield put(showLoader())
    yield call(deleteCommentService, comment.commentId, auth.token)
    const comments = yield call(getCommentsService, dossierId, auth.token)
    yield setComments(dossierType, comments)
  } catch (error) {
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('purchase_dossier'))
      yield put(reset('paperwork_dossier'))
      yield put(reset('campaign_dossier'))
      yield put(reset('registrationDeliverydossier'))
      yield put(reset('service_dossier'))
      yield put(reset('stock_dossier'))
    }
    yield call(handleError, { error, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDeleteComment () {
  yield takeEvery(commentsActionsTypes.DELETE_COMMENT, deleteComment)
}

export function * updateComment ({ comment, resolve, reject, dossierType }) {
  try {
    const dossierId = yield getDossierId(dossierType)
    const auth = yield select(getAuth)
    yield put(showLoader())
    yield call(putCommentService, comment.commentId, comment, auth.token)
    const comments = yield call(getCommentsService, dossierId, auth.token)
    yield setComments(dossierType, comments)
    yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      yield call(reject, customHandlerError)
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('purchase_dossier'))
      yield put(reset('paperwork_dossier'))
      yield put(reset('campaign_dossier'))
      yield put(reset('registrationDeliverydossier'))
      yield put(reset('service_dossier'))
      yield put(reset('stock_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchUpdateComment () {
  yield takeEvery(commentsActionsTypes.UPDATE_COMMENT, updateComment)
}

export function * markAsReadComment ({ dossierId, dossierType, historical }) {
  try {
    const auth = yield select(getAuth)
    let comments = []
    if (historical) {
      comments = yield call(getCommentsHistoricalService, dossierId, auth.token)
    } else {
      comments = yield call(getCommentsService, dossierId, auth.token)
      yield call(markAsRead, comments.map((comment) => comment.commentId), auth.token)
      comments = yield call(getCommentsService, dossierId, auth.token)
    }
    yield setComments(dossierType, comments)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('purchase_dossier'))
      yield put(reset('paperwork_dossier'))
      yield put(reset('campaign_dossier'))
      yield put(reset('registrationDeliverydossier'))
      yield put(reset('service_dossier'))
      yield put(reset('stock_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchMarkAsReadComment () {
  yield takeEvery(commentsActionsTypes.MARK_COMMENT_AS_READ, markAsReadComment)
}

export function * getComments ({ dossierId, dossierType, historical }) {
  try {
    const auth = yield select(getAuth)
    let comments = []
    if (historical) {
      comments = yield call(getCommentsHistoricalService, dossierId, auth.token)
    } else {
      comments = yield call(getCommentsService, dossierId, auth.token)
    }
    yield setComments(dossierType, comments)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('purchase_dossier'))
      yield put(reset('paperwork_dossier'))
      yield put(reset('campaign_dossier'))
      yield put(reset('registrationDeliverydossier'))
      yield put(reset('service_dossier'))
      yield put(reset('stock_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchGetComments () {
  yield takeEvery(commentsActionsTypes.GET_COMMENTS, getComments)
}
