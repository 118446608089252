import usageActionTypes from '../../constants/actions/reports/usageReport'

function initialState () {
  return {
    usageList: [],
    pagesCount: 0,
    usageCount: 0,
    hasSearch: false,
    modalOpen: false,
    dynamicFilters: {},
    filter: {
      page: 1,
      orderBy: '',
      isDeleted: null,
      pageSize: 10
    },
    usageModal: {
      usageDetailList: [],
      pagesCount: 0,
      usageCount: 0,
      filter: {
        page: 1,
        orderBy: '',
        isDeleted: null,
        pageSize: 10
      }
    }
  }
}

function fetchUsageReportSuccess (state, { usageList, filter }) {
  return {
    ...state,
    usageList: usageList.usageList,
    usageCount: usageList.usageCount,
    pagesCount: usageList.pagesCount,
    filter,
    hasSearch: true,
    dynamicFilters: filter
  }
}

function fetchUsageInfoReportSuccess (state, { usageDetail, filter }) {
  return {
    ...state,
    usageModal: {
      usageDetailList: usageDetail.usageDetailList,
      usageCount: usageDetail.usageCount,
      pagesCount: usageDetail.pagesCount,
      filter
    },
    modalOpen: true,
    dynamicFilters: filter
  }
}

function clearSearch (state) {
  return {
    ...initialState()
  }
}

function clearDynamicFilters (state) {
  return {
    ...state,
    dynamicFilters: {}
  }
}

function clearSearchResultList (state) {
  return {
    ...state,
    usageList: [],
    pagesCount: 0,
    usageCount: 0,
    hasSearch: false
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case usageActionTypes.FETCH_USAGE_REPORT_SUCCESS:
      return fetchUsageReportSuccess(state, action)
    case usageActionTypes.FETCH_USAGE_INFO_SUCCESS:
      return fetchUsageInfoReportSuccess(state, action)
    case usageActionTypes.CLEAR_SEARCH:
      return clearSearch(state)
    case usageActionTypes.CLEAR_DYNAMIC_FILTERS:
      return clearDynamicFilters(state)
    case usageActionTypes.CLEAR_SEARCH_RESULT_LIST:
      return clearSearchResultList(state)
    default:
      return state
  }
}
