import mastersActionTypes from '../../../constants/actions/masters/masters'

function initialState () {
  return {
    fuelList: [],
    fuelCount: 0,
    pagesCount: 0,
    filter: {
      page: 1,
      orderBy: '',
      pageSize: 10
    },
    fuelModal: {
      fuel: null,
      showModal: false
    }
  }
}

function fetchFuelListSuccess (state, { fuelList, filter }) {
  return {
    ...state,
    fuelList: fuelList.fuelList,
    fuelCount: fuelList.fuelCount,
    pagesCount: fuelList.pagesCount,
    filter
  }
}

function openAddModalSuccess (state) {
  const initial = initialState()
  return {
    ...state,
    fuelModal: {
      ...initial.fuelModal,
      showModal: true
    }
  }
}

function closeEditModalSuccess (state) {
  return {
    ...state,
    fuelModal: {
      ...initialState().fuelModal
    }
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case mastersActionTypes.FETCH_FUEL_SUCCESS:
      return fetchFuelListSuccess(state, action)
    case mastersActionTypes.OPEN_ADD_FUEL_MODAL_SUCCESS:
      return openAddModalSuccess(state, action)
    case mastersActionTypes.CLOSE_EDIT_FUEL_MODAL:
      return closeEditModalSuccess(state, action)
    default:
      return state
  }
}
