import React, { PureComponent } from 'react'
import { Button, Modal, Col, Row } from 'react-bootstrap'
import { reduxForm } from 'redux-form'
import { unattendedResult } from '../../constants/management/unattendedStatus'
import SimpleTablePage from '../../containers/commons/general/SimpleTablePage'
import { unattendedProcessType } from '../../constants/management/unattendedProcess'
import { capitalizeFirstLetter, transformApisSignErrors } from '../../util/utils'
import { errorTagSufixSeparator } from '../../constants/backendIds'
import { replaceableFields } from '../../constants/dossier/common/replaceableFields'

import backEndTagTranslation from '../../constants/backEndTagTransaltion'
import errorsSendToAgency from '../../constants/errors/errorsSendToAgency'

class UnattendedProcessDetailModal extends PureComponent {
  constructor () {
    super()
    this.state = {
      errorsWithoutDossierIdState: []
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.process?.unattendedProcessId && this.props.process.unattendedProcessId !== prevProps.process?.unattendedProcessId) {
      let errorsWithoutDossierIdState = []
      let countErrors = 0
      const errorsWithoutDossierId = this.props.process.errors.filter(x => !x.dossierId)
      this.props.process.dossiersList?.map((row, idx) => {
        if (!row) {
          let errorWithoutDossierId = { ...errorsWithoutDossierId[countErrors], dossierNotFoundIndex: idx }
          errorsWithoutDossierIdState[countErrors] = errorWithoutDossierId
          countErrors++
        }
      })
      this.setState({ errorsWithoutDossierIdState })
    }
  }

  getDossierNumnber (dossierId) {
    if (dossierId < 0) {
      const dossierIdStr = Math.abs(dossierId).toString()
      if (dossierIdStr.length < 6) {
        dossierId = ''
      } else {
        dossierId = dossierId * -1
      }
    }
    let number = this.props.dossierNumbers.find(x => x.id === dossierId)?.value
    if (number && number !== '') {
      return dossierId.length < 6 ? '' : number
    } else {
      return dossierId ?? ''
    }
  }

  getError (dossierId, index = null, err = null) {
    let error = {}
    let process = this.props.process
    if (err) {
      error = err
    } else {
      if (dossierId) {
        error = process.errors.find(x => x.dossierId === dossierId)
      } else {
        error = this.state.errorsWithoutDossierIdState.find(x => x.dossierNotFoundIndex === index)
      }
      if (!error) {
        dossierId = dossierId < 0 ? -dossierId : dossierId
        if (dossierId) {
          error = process.errors.find(x => x.dossierId === dossierId)
        } else {
          error = this.state.errorsWithoutDossierIdState.find(x => x.dossierNotFoundIndex === index)
        }
      }
    }

    if (process.result === unattendedResult.PENDING) {
      return <span style={{ color: 'blue' }} >{this.props.t('MANAGEMENT.UNATTENDED_PROCESS_TAB.PROCESS_PENDING')}</span>
    } else if ((process.result === unattendedResult.RUNNING_NO_ERRORS || process.result === unattendedResult.RUNNING_WHIT_ERRORS) && !error) {
      return <span style={{ color: 'blue' }} >{this.props.t('MANAGEMENT.UNATTENDED_PROCESS_TAB.PROCESS_PENDING')}</span>
    } else if ((process.result === unattendedResult.RUNNING_NO_ERRORS || process.result === unattendedResult.RUNNING_WHIT_ERRORS) && error.tagFail === 'ProcessCompletedOk') {
      return <span style={{ color: 'green' }} >{this.props.t('MANAGEMENT.UNATTENDED_PROCESS_TAB.PROCESS_COMPLETED_OK')}</span>
    } else if (!error && process.dossierProcessed === 0 && process.unattendedProcessTypeId === unattendedProcessType.DELETE_FLEET) {
      return <span style={{ color: 'green' }} >{this.props.t('MANAGEMENT.UNATTENDED_PROCESS_TAB.DELETE_FLEET_NOT_DOSSIER')}</span>
    } else if (process.unattendedProcessTypeId === unattendedProcessType.MOVE_FABRIC_INVOICE_PROCESS) {
      return <span style={{ color: process.result === unattendedResult.ENDED_NO_ERRORS ? 'green' : 'red' }} >{process.errors[0].dataFail}</span>
    } else if (!error && process.dossierProcessed) {
      return <span style={{ color: 'red' }} >{this.props.t('MANAGEMENT.UNATTENDED_PROCESS_TAB.ERROR_ALL_PROCESS')}</span>
    } else if (!error && process.result === unattendedResult.ENDED_WITH_ERRORS) {
      return <span style={{ color: 'red' }} >{this.props.t('MANAGEMENT.UNATTENDED_PROCESS_TAB.ERROR_ALL_PROCESS')}</span>
    } else if (process.unattendedProcessTypeId === unattendedProcessType.DOWNLOAD_MASIVE) {
      return <span style={{ color: error ? 'red' : 'green' }} >{this.getErrorDownloadMasive(error)}</span>
    } else if (!error || error.tagFail === 'ProcessCompletedOk') {
      if (!error && process.unattendedProcessTypeId === unattendedProcessType.AUDIT) {
        return <span style={{ color: 'red' }} >{this.props.t('MANAGEMENT.UNATTENDED_PROCESS_TAB.PROCESS_FAILED_GENERIC')}</span>
      }
      return <span style={{ color: 'green' }} >{this.props.t('MANAGEMENT.UNATTENDED_PROCESS_TAB.PROCESS_COMPLETED_OK')}</span>
    } else if (!error || error.tagFail === 'AuditOk') {
      return this.getAudit(error, 'AUDIT_OK', 'green')
    } else if (!error || error.tagFail === 'OU_NOT_VALID' || error.tagFail === 'DOSSIER_NOT_FOUND') {
      return this.getAudit(error, error.tagFail, 'red')
    } else {
      return <span style={{ color: 'red' }} >{this.getErrorText(error, process.unattendedProcessTypeId)}</span>
    }
  }

  getAudit (error, tag, color) {
    if (tag === 'COPYDOCUMENTFAILED') {
      return this.getErrorDownloadMasive(error)
    }
    const message = this.props.t('MANAGEMENT.UNATTENDED_PROCESS_TAB.' + tag)
    const dataFail = error?.dataFail

    const replacedDataFail = dataFail.replace(/##/g, '')

    const keyValuePairs = replacedDataFail.split(', ')
    const dataObject = {}
    keyValuePairs.forEach(pair => {
      const [key, value] = pair.split(': ')
      dataObject[key] = value
    })

    let updatedMessage = message
    for (const key in dataObject) {
      const value = dataObject[key]
      updatedMessage = updatedMessage.replace(key, value).replace(/##/g, '')
    }

    return <span style={{ color: color }}>{updatedMessage}</span>
  }

  getErrorText (error, processTypeId) {
    if (!error.dataFail) {
      return this.props.t('MANAGEMENT.UNATTENDED_PROCESS_TAB.PROCESS_FAILED_GENERIC')
    } else {
      switch (processTypeId) {
        case unattendedProcessType.UPLOAD_DOCUMENT_FLEET:
          break
        case unattendedProcessType.ACTION_SALE_DATA:
        case unattendedProcessType.OPERATE_DATA_FINANCING_FLEET:
        case unattendedProcessType.OPERATE_ACTION_REJECT:
        case unattendedProcessType.OPERATE_ACTION_APPROVE_PAPERWORK:
        case unattendedProcessType.OPERATE_ACTION_SEND_TO_APPROVAL:
        case unattendedProcessType.OPERATE_DATA_PAPERWORK_FLEET:
        case unattendedProcessType.OPERATE_ACTION_APPROVE:
        case unattendedProcessType.OPERATE_DATA_DELIVERY_FLEET:
        case unattendedProcessType.OPERATE_DATA_SERVICE_FLEET:
        case unattendedProcessType.CANCEL_SIGN_FLEET_DOCUMENT:
        case unattendedProcessType.REJECT_FLEET_DOCUMENT:
          return this.getErrorOperateActionGeneric(error)
        case unattendedProcessType.OPERATE_ACTION_DELETE:
          return this.getErrorsStoreAction(error.tagFail)
        case unattendedProcessType.ACTION_EXP_FLEET:
          return this.getErrorsActionExpFleet(error)
        case unattendedProcessType.APPROVAL_ACTION_FLEET:
          return this.getErrorsApprovalAction(error.tagFail)
        case unattendedProcessType.FINANCIAL_ANALYSIS_SECTION:
          return this.getErrorFinacialAnalysisSection(error)
        case unattendedProcessType.UPLOAD_DIFERENT_DOCUMENT_SECTION:
          return this.getErrorUploadDifferentDocument(error)
        case unattendedProcessType.OPERATE_ACTION_STORE:
          return this.getErrorsStoreAction(error.tagFail)
        case unattendedProcessType.AUDIT:
          var tag = error?.tagFail === 'GenericError ' ? 'PROCESS_FAILED_GENERIC' : error?.tagFail
          return this.getAudit(error, tag, 'red')
        case unattendedProcessType.OPERATE_ACTION_SEND_TO_AGENCY:
          return this.getErrorsSendToAgency(error.tagFail)
        case unattendedProcessType.FLEET_LOAD_DOSSIERS_FROM_EXCEL:
          return this.getErrorFleetLoadDossiersFromExcel(error)
        case unattendedProcessType.INCORPORATE_DOCUMENT_FLEET:
          return this.getDocumentError(error.tagFail)
        case unattendedProcessType.VALIDATE_DOCUMENT_FLEET:
        case unattendedProcessType.REEDIT_DOCUMENT_FLEET:
        case unattendedProcessType.CANCEL_DOCUMENT_FLEET:
          return this.getDocumentError(error)
        case unattendedProcessType.OPERATE_ACTION_PRINT_DELIVERY_NOTE:
          return this.getErrorOperateActionPrintDeliveryNote(error)
        case unattendedProcessType.REQUEST_SIGN_FLEET_DOCUMENT:
          return this.getRequestSignError(error)
        case unattendedProcessType.IMPORT_SEPOMEX:
          return this.props.t('MANAGEMENT.UNATTENDED_PROCESS_TAB.PROCESS_FAILED_GENERIC_NO_DOSSIER')
        case unattendedProcessType.DOWNLOAD_MASIVE:
          return this.getErrorDownloadMasive(error)
        default:
          return this.props.t('MANAGEMENT.UNATTENDED_PROCESS_TAB.PROCESS_FAILED_GENERIC')
      }
    }
  }

  getErrorFinacialAnalysisSection (error) {
    const tKey = 'MANAGEMENT.UNATTENDED_PROCESS_TAB.'
    var errorMessage = this.props.t(`${tKey}PROCESS_FAILED_GENERIC`)
    try {
      if (!error.tagFail && error.dataFail) {
        var dataFails = JSON.parse(error.dataFail)
        if (Array.isArray(dataFails) && dataFails.length > 0) {
          errorMessage = ''
          dataFails.forEach(e => {
            if (errorMessage !== '') { errorMessage += '\n' }
            errorMessage += this.props.t(`${tKey}${e.TagFail}`) + ' '
            errorMessage = errorMessage.replace('##DATAFAIL##', e.DataFail)
          })
        }
      }
      return errorMessage
    } catch (error) {
      return errorMessage
    }
  }

  getErrorDownloadMasive (error) {
    if (error) {
      let message = this.props.t(`MANAGEMENT.UNATTENDED_PROCESS_TAB.${error.tagFail.toUpperCase()}`)
      if (message.includes('MANAGEMENT.UNATTENDED_PROCESS_TAB.')) {
        return this.props.t('MANAGEMENT.UNATTENDED_PROCESS_TAB.PROCESS_FAILED_GENERIC')
      } else if (message.includes('##')) {
        const dataFail = error?.dataFail

        const segments = dataFail.split(',')

        const dataObject = {}

        segments.forEach(segment => {
          const matches = segment.match(/##(.*?)##(.*?)(,|$)/)
          if (matches && matches.length > 2) {
            const key = matches[1]
            const value = matches[2]
            dataObject[key] = value
          }
        })

        let updatedMessage = message

        for (const key in dataObject) {
          const value = dataObject[key]
          const replacePattern = new RegExp(`##${key}##`, 'g')
          updatedMessage = updatedMessage.replace(replacePattern, value)
        }

        return updatedMessage
      }
      return message
    } else {
      return this.props.t('MANAGEMENT.UNATTENDED_PROCESS_TAB.PROCESS_COMPLETED')
    }
  }

  getErrorUploadDifferentDocument (error) {
    if (error.messageFail === 'Not all of files have document') {
      return this.props.t('ERRORS.BACKEND_TAG_TRANSLATION.NOT_ALL_FILES_HAVE_DOCUMENT')
    } else if (error.tagFail === 'processGeneralError' || error.tagFail === 'dossierDataChangeFail') {
      return this.props.t('MANAGEMENT.UNATTENDED_PROCESS_TAB.PROCESS_FAILED_GENERIC')
    } else {
      let errorMessage = this.props.t('MANAGEMENT.FLEETS_TAB.THE_DOSSIER') + ' ' + this.props.t('MANAGEMENT.FLEETS_TAB.UPLOAD_DOCUMENT_DOSSIER_ERROR') + ' '
      switch (error.tagFail) {
        case 'documentAlreadyValidated':
          errorMessage += this.props.t('MANAGEMENT.FLEETS_TAB.DOCUMENT_ALREADY_VALIDATED')
          break
        case 'noEntityForThisDossier':
          errorMessage += this.props.t('MANAGEMENT.FLEETS_TAB.NO_ENTITY_FOR_DOSSIER')
          break
        case 'signStatusPending':
          errorMessage += this.props.t('MESSAGES.SIGN_STATUS_PENDING')
          break
        case 'noPermission':
          errorMessage += this.props.t('ERRORS.BACKEND_TAG_TRANSLATION.NO_PERMISSION')
          break
        case 'parameterNoValid':
          errorMessage += this.props.t('MANAGEMENT.FLEETS_TAB.DOCUMENT_STATUS_NO_VALID')
          break
        default:
          var backendTag = this.getErrorTagBackend(error.tagFail)
          if (backendTag) {
            return this.props.t(backendTag)
          } else {
            this.props.t('MANAGEMENT.FLEETS_TAB.GENERIC_UPLOAD_ERROR')
          }
          break
      }
      return errorMessage
    }
  }

  getErrorsActionExpFleet (error) {
    if (error.tagFail !== 'entityDossierFail') {
      return this.getErrorOperateActionGeneric(error)
    } else {
      var errormessage = this.props.t('MESSAGES.ACTION_FLEET_ENTITY_ERROR')
      var ob = JSON.parse(error.dataFail)
      for (var key in ob) {
        errormessage = errormessage.replace('##' + key + '##', ob[key])
      }
      return errormessage
    }
  }

  getErrorsApprovalAction (errorTag) {
    if (errorTag === 'processGeneralError' || errorTag === 'dossierDataChangeFail' || errorTag === 'ActionApprovalFleet') {
      return this.props.t('MANAGEMENT.UNATTENDED_PROCESS_TAB.PROCESS_FAILED_GENERIC')
    } else {
      if (errorTag.includes('/')) {
        errorTag = errorTag.split('/')[1]
      }
      var errormessage = this.props.t('MESSAGES.APPROVAL_FLEET_ERROR')
      errormessage = errormessage.replace('##Error##', this.props.t('MANAGEMENT.FLEETS_TAB.ERRORS.' + errorTag))
      return capitalizeFirstLetter(errormessage)
    }
  }

  getErrorsSendToAgency (errorTag) {
    const controlledMessageRoot = 'MANAGEMENT.FLEETS_TAB.ERRORS.'
    if (errorTag.includes('/')) {
      errorTag = errorTag.split('/')[1]
    }
    let errorDetails = ''
    switch (errorTag) {
      case errorsSendToAgency.SEND_TO_AGENCY:
        errorDetails = this.props.t('DOSSIER_COMPONENTS.PAPERWORK_DOSSIER.PAPERWORK_COMPONENT.PROCESSING_AGENCY_DENIED_PAPERWORK')
        break
      case errorsSendToAgency.SEND_TO_AGENCY_NO_PAPERWORK:
        errorDetails = this.props.t('MANAGEMENT.UNATTENDED_PROCESS_TAB.SEND_TO_AGENCY_NO_PAPERWORKS')
        break
      case 'processingAgencyLoginError':
        errorDetails = this.props.t(`${controlledMessageRoot}${errorsSendToAgency.PROCESSING_AGENCY_LOGIN_ERROR}`)
        break
      case errorsSendToAgency.NOT_CHECKLIST_PASSED:
        errorDetails = this.props.t(`${controlledMessageRoot}${errorsSendToAgency.NOT_CHECKLIST_PASSED}`)
        break
      case errorsSendToAgency.DOSSIER_PRECANCELED:
        errorDetails = this.props.t(`${controlledMessageRoot}${errorsSendToAgency.DOSSIER_PRECANCELED}`)
        break
      case errorsSendToAgency.NOT_PERMISSION_SUBTYPE_UO:
        errorDetails = this.props.t(`${controlledMessageRoot}${errorsSendToAgency.NOT_PERMISSION_SUBTYPE_UO}`)
        break
      case errorsSendToAgency.NOT_STAGE_PAPERWORK_PTE_DOC_VEHICLE:
        errorDetails = this.props.t(`${controlledMessageRoot}${errorsSendToAgency.NOT_STAGE_PAPERWORK_PTE_DOC_VEHICLE}`)
        break
      case errorsSendToAgency.PROCESSING_AGENCY_LOGIN_ERROR:
        errorDetails = this.props.t(`${controlledMessageRoot}${errorsSendToAgency.PROCESSING_AGENCY_LOGIN_ERROR}`)
        break
      case errorsSendToAgency.PROCESSING_AGENCY_DENIED_RESENDING_PAPERWORK:
        errorDetails = this.props.t('DOSSIER_COMPONENTS.PAPERWORK_DOSSIER.PAPERWORK_COMPONENT.PROCESSING_AGENCY_DENIED_RESENDING_PAPERWORK')
        break
      default:
        errorDetails = this.getErrorBackendOrGeneric(errorTag ?? '')
        break
    }
    if (!errorDetails) {
      errorDetails = '"' + this.props.t('MANAGEMENT.UNATTENDED_PROCESS_TAB.PROCESS_FAILED_GENERIC') + '"'
    }
    let errormessage = this.props.t('MESSAGES.APPROVAL_FLEET_ERROR')
    errormessage = errormessage.replace('##Error##', errorDetails)
    return errormessage
  }

  getDocumentError (error) {
    if (error.dataFail?.includes('Error sending metadata Invoice Certification Service')) {
      return this.props.t('MANAGEMENT.UNATTENDED_PROCESS_TAB.ERROR_SENDING_METADATA_INVOICE_CERTIFICATION_SERVICE')
    }

    switch (error.dataFail) {
      case 'Document is not in a status valid':
        return this.props.t('MANAGEMENT.UNATTENDED_PROCESS_TAB.DOCUMENT_IS_NOT_IN_A_STATUS_VALID')
      case 'Document has not a Entity Id specified':
        return this.props.t('MANAGEMENT.UNATTENDED_PROCESS_TAB.DOCUMENT_HAS_NOT_A_ENTITY_ID_SPECIFIED')
      case 'Entity exist with diferent ObjectId. Must use the same ObjectId':
        return this.props.t('MANAGEMENT.UNATTENDED_PROCESS_TAB.ENTITY_EXIST_WITH_DIFERENT_OBJECTID_MUST_USE_THE_SAME_OBJECTID')
      case 'Entity exist and ObjectId not specified':
        return this.props.t('MANAGEMENT.UNATTENDED_PROCESS_TAB.ENTITY_EXIST_AND_OBJECTID_NOT_SPECIFIED')
      case 'MetaKey not found. It is mandatory':
        return this.props.t('MANAGEMENT.UNATTENDED_PROCESS_TAB.METAKEY_NOT_FOUND_IT_IS_MANDATORY')
      case 'Entity not exist and cannot create it':
        return this.props.t('MANAGEMENT.UNATTENDED_PROCESS_TAB.ENTITY_NOT_EXIST_AND_CANNOT_CREATE_IT')
      default:
        return this.getErrorOperateActionGeneric(error)
    }
  }

  getRequestSignError (error) {
    let backendTag = this.getErrorTagBackend(error.tagFail)
    if (backendTag) {
      return this.props.t(backendTag)
    } else {
      if (error.dataFail.toLowerCase().includes('ha ocurrido un error al encontrar la firma') || error.messageFail.toLowerCase().includes('ha ocurrido un error al encontrar la firma')) {
        return this.props.t('ERRORS.BACKEND_TAG_TRANSLATION.SIGN_CONFIGURATION_NOT_EXISTS')
      }
      if (error.dataFail.toLowerCase().includes('noparentdossierorsalesmansign') || error.messageFail.toLowerCase().includes('noparentdossierorsalesmansign')) {
        return this.props.t('ERRORS.BACKEND_TAG_TRANSLATION.NOT_PARENT_DOSSIER_OR_SALESMAN_SIGN')
      }
      if (error.dataFail.toLowerCase().includes('nosalesmansign') || error.messageFail.toLowerCase().includes('nosalesmansign')) {
        return this.props.t('ERRORS.BACKEND_TAG_TRANSLATION.NOT_SALESMAN_SIGN')
      }
      if (error.dataFail.toLowerCase().includes('invalid organizedunit value for signentitytype code') || error.messageFail.toLowerCase().includes('invalid organizedunit value for signentitytype code')) {
        return this.props.t('ERRORS.BACKEND_TAG_TRANSLATION.INVALID_ORGANIZED_VALUE_FOR_SIGN_ENTITY_TYPE')
      }
      if (error.dataFail.toLowerCase().includes('invalid signentitytype code') || error.messageFail.toLowerCase().includes('invalid signentitytype code')) {
        return this.props.t('ERRORS.BACKEND_TAG_TRANSLATION.INVALID_SIGN_ENTITY_TYPE_CODE')
      }
      if (error.dataFail.toLowerCase().includes('signature_client_not_found') || error.messageFail.toLowerCase().includes('signature_client_not_found')) {
        return this.props.t('ERRORS.BACKEND_TAG_TRANSLATION.SIGNATURE_CLIENT_NOT_FOUND')
      }
      if (error.dataFail.toLowerCase().includes('signatures') || error.messageFail.toLowerCase().includes('signatures') || error.tagFail.toLowerCase().includes('signatures')) {
        return this.props.t('ERRORS.BACKEND_TAG_TRANSLATION.SIGNATURE_CREATION_ERROR')
      }
      return this.getErrorOperateActionGeneric(error)
    }
  }

  getErrorOperateActionGeneric (error) {
    let tag = error.tagFail ?? ''

    if (tag.includes('TRANS/')) {
      tag = tag.replace('TRANS/', '') ?? ''
    }
    if (tag.includes('/')) {
      tag = tag.split('/')[1] ?? ''
    }

    let message = ''

    switch (tag.toLowerCase()) {
      case 'missingParameters'.toLowerCase():
        message = this.props.t('MESSAGES.MISSING_SERRVICE_PARAMETERS')
        break
      case 'DossierOutOfDate'.toLowerCase():
        message = this.props.t('MESSAGES.DOSSIER_OUT_OF_DATE')
        break
      case 'wrongDossierState'.toLowerCase():
        message = this.props.t('MANAGEMENT.UNATTENDED_PROCESS_TAB.WRONG_DOSSIER_STATE')
        break
      case 'dossierParentCannotBeChanged'.toLowerCase():
        message = this.props.t('MANAGEMENT.UNATTENDED_PROCESS_TAB.DOSSIER_PARENT_CANNOT_BE_CHANGED')
        break
      case 'existingChassis'.toLowerCase():
        message = this.props.t('ERRORS.BACKEND_TAG_TRANSLATION.EXISTING_CHASSIS')
        break
      case 'existingLicensePlate'.toLowerCase():
        message = this.props.t('ERRORS.BACKEND_TAG_TRANSLATION.EXISTING_LICENSE_PLATE')
        break
      case 'entityNotFound'.toLowerCase():
        message = this.props.t('ERRORS.BACKEND_TAG_TRANSLATION.ENTITY_NOT_FOUND')
        break
      case 'noEntityForThisDossier'.toLowerCase():
        message = this.props.t('MANAGEMENT.FLEETS_TAB.NO_ENTITY_FOR_DOSSIER')
        break
      case 'signStatusPending'.toLowerCase():
        message = this.props.t('MESSAGES.SIGN_STATUS_PENDING')
        break
      case 'noPermission'.toLowerCase():
        message = this.props.t('ERRORS.BACKEND_TAG_TRANSLATION.NO_PERMISSION')
        break
      case 'parameterNoValid'.toLowerCase():
        message = this.props.t('MANAGEMENT.FLEETS_TAB.DOCUMENT_STATUS_NO_VALID')
        break
      case 'notPaperworkExists'.toLowerCase():
        message = this.props.t('MANAGEMENT.UNATTENDED_PROCESS_TAB.NOT_PAPERWORK_EXISTS')
        break
      case 'parameterNotEspecified'.toLowerCase():
        message = this.props.t('MANAGEMENT.FLEETS_TAB.ERRORS.PARAMETER_NOT_ESPECIFIED')
        break
      case 'NOT_CHECKLIST_PASSED'.toLowerCase():
        message = this.props.t('MANAGEMENT.FLEETS_TAB.ERRORS.NOT_CHECKLIST_PASSED')
        break
      case 'UO_SUBTYPE_STAGE_NULL'.toLowerCase():
        message = this.props.t('MANAGEMENT.FLEETS_TAB.ERRORS.UO_SUBTYPE_STAGE_NULL')
        break
      case 'NOT_STAGE_TO_APPROVE'.toLowerCase():
        message = this.props.t('MANAGEMENT.FLEETS_TAB.ERRORS.NOT_STAGE_TO_APPROVE')
        break
      case 'NOT_PERMISSION_SUBTYPE_UO'.toLowerCase():
        message = this.props.t('MANAGEMENT.FLEETS_TAB.ERRORS.NOT_PERMISSION_SUBTYPE_UO')
        break
      case 'DOSSIER_PRECANCELED'.toLowerCase():
        message = this.props.t('MANAGEMENT.FLEETS_TAB.ERRORS.DOSSIER_PRECANCELED')
        break
      case 'YET_SENT_TO_APPROVAL'.toLowerCase():
        message = this.props.t('MANAGEMENT.FLEETS_TAB.ERRORS.YET_SENT_TO_APPROVAL')
        break
      case 'documentAlreadyValidated'.toLowerCase():
        message = this.props.t('MANAGEMENT.FLEETS_TAB.DOCUMENT_ALREADY_VALIDATED')
        break
      default:
        message = this.getErrorBackendOrGeneric(error.tagFail ?? '')
        break
    }
    return capitalizeFirstLetter(message)
  }

  getErrorBackendOrGeneric (tagFail) {
    let backendTag = this.getErrorTagBackend(tagFail)
    if (backendTag) {
      return this.props.t(backendTag)
    } else {
      return this.props.t('MANAGEMENT.UNATTENDED_PROCESS_TAB.PROCESS_FAILED_GENERIC')
    }
  }

  getErrorsStoreAction (errorTag) {
    let backendTag = this.getErrorTagBackend(errorTag)
    if (backendTag) {
      return this.props.t(backendTag)
    }
    if (errorTag === 'OperateActionStoreChildError') {
      return this.props.t('MANAGEMENT.UNATTENDED_PROCESS_TAB.STORE_PROCESS_FAILED_CHILD_NOT_STORED')
    } else if (errorTag === 'processGeneralError' || errorTag === 'dossierDataChangeFail' || errorTag === 'OperateActionStore') {
      return this.props.t('MANAGEMENT.UNATTENDED_PROCESS_TAB.PROCESS_FAILED_GENERIC')
    } else {
      if (errorTag.includes('/')) {
        errorTag = errorTag.split('/')[1]
      }
      var errormessage = this.props.t('MESSAGES.APPROVAL_FLEET_ERROR')
      if (errorTag === 'signingProcessExists') errorTag = 'SIGN_PROCESS_EXISTS'
      errormessage = errormessage.replace('##Error##', this.props.t('MANAGEMENT.FLEETS_TAB.ERRORS.' + errorTag))
      return errormessage
    }
  }

  getErrorOperateActionPrintDeliveryNote (error) {
    let backendTag = this.getErrorTagBackend(error.tagFail)
    if (backendTag) {
      return this.props.t(backendTag)
    }
    var errormessageFleet = this.props.t('MESSAGES.APPROVAL_FLEET_ERROR')
    let errorMessage = this.props.t('MANAGEMENT.UNATTENDED_PROCESS_TAB.PROCESS_FAILED_GENERIC')
    if (error && error.tagFail && error.tagFail.includes('/')) {
      error.tagFail = error.tagFail.split('/')[1]
    }

    switch (error.tagFail) {
      case 'NOT_PERMISSION_SUBTYPE_UO':
        errorMessage = errormessageFleet.replace('##Error##', this.props.t('MANAGEMENT.FLEETS_TAB.ERRORS.NOT_PERMISSION_SUBTYPE_UO'))
        break
      case 'DOSSIER_PRECANCELED':
        errorMessage = errormessageFleet.replace('##Error##', this.props.t('MANAGEMENT.FLEETS_TAB.ERRORS.DOSSIER_PRECANCELED'))
        break
      default:
        if (backEndTagTranslation[error.tagFail]) errorMessage = this.props.t(`ERRORS.BACKEND_TAG_TRANSLATION.${backEndTagTranslation[error.tagFail]}`)
        break
    }
    return capitalizeFirstLetter(errorMessage)
  }

  getErrorFleetLoadDossiersFromExcel (error) {
    let errorMessage = this.props.t('MANAGEMENT.UNATTENDED_PROCESS_TAB.PROCESS_FAILED_GENERIC')
    var errorSource = this.props.t('MESSAGES.EXCEL_ERROR')
    errorSource = errorSource.replace(replaceableFields.row, error.dataFail)
    switch (error.tagFail) {
      case 'OU_NOT_FOUND':
        errorMessage = this.props.t('MANAGEMENT.FLEETS_TAB.ERRORS.OU_NOT_FOUND')
        break
      case 'ORDER_ALREADY_EXISTS':
        errorMessage = this.props.t('MANAGEMENT.FLEETS_TAB.ERRORS.ORDER_ALREADY_EXISTS')
        break
      case 'BRAND_NOT_FOUND':
        errorMessage = this.props.t('MANAGEMENT.FLEETS_TAB.ERRORS.BRAND_NOT_FOUND')
        break
      case 'VEHICLE_NOT_EXISTS':
        errorMessage = this.props.t('MANAGEMENT.FLEETS_TAB.ERRORS.VEHICLE_NOT_EXISTS')
        break
      case 'PURCHASE_NOT_EXISTS':
        errorMessage = this.props.t('MANAGEMENT.FLEETS_TAB.ERRORS.PURCHASE_NOT_EXISTS')
        break
      default:
        break
    }
    return errorSource + ' ' + capitalizeFirstLetter(errorMessage)
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (prevProps.showModal !== this.props.showModal && this.props.showModal) {
      if (this.props.process && this.props.process.dossiersList && this.props.process.dossiersList.length > 0) {
        const type = this.props.process?.errors?.find(error => error.dataFail && error.dataFail?.includes('SVN')) != null ? [1] : this.props.process?.errors?.find(error => error.dataFail && error.dataFail.includes('SVO')) != null ? [2] : [1, 2]
        var dossierSubTypes = (this.props.process.unattendedProcessTypeId === unattendedProcessType.AUDIT) ? type : null
        this.props.actions.findDossierNumbersFromId(this.props.process.dossiersList, dossierSubTypes)
      }
    }
    return null
  }

  getErrorTagBackend (backendFilteredTag) {
    const transactionTag = backendFilteredTag.match(/^TRANS\/([\w\d]*)/)
    let tKeyFilteredByTag = ''
    if (transactionTag && backEndTagTranslation[transactionTag[1]]) {
      tKeyFilteredByTag = backEndTagTranslation[transactionTag[1]]
    } else if (backEndTagTranslation[backendFilteredTag]) {
      tKeyFilteredByTag = backEndTagTranslation[backendFilteredTag]
    } else if (backendFilteredTag && backendFilteredTag.indexOf(errorTagSufixSeparator) >= 0) {
      const tagArray = backendFilteredTag.split(errorTagSufixSeparator)
      if (backEndTagTranslation[tagArray[1]]) {
        tKeyFilteredByTag = backEndTagTranslation[tagArray[1]]
      }
    } else if (backendFilteredTag) {
      let transformMessage = transformApisSignErrors(backendFilteredTag)
      tKeyFilteredByTag = transformMessage ? backEndTagTranslation[transformMessage] ? backEndTagTranslation[transformMessage] : '' : ''
    } else if (tKeyFilteredByTag === '') {
      if (backendFilteredTag.includes('/') && backEndTagTranslation[backendFilteredTag.split('/')[1]]) {
        tKeyFilteredByTag = backEndTagTranslation[backendFilteredTag.split('/')[1]]
      }
    } else {
      tKeyFilteredByTag = ''
    }
    return tKeyFilteredByTag !== '' ? 'ERRORS.BACKEND_TAG_TRANSLATION.' + tKeyFilteredByTag : null
  }

  render () {
    const {
      t,
      showModal,
      process
    } = this.props
    const tKey = 'MANAGEMENT.UNATTENDED_PROCESS_TAB.'
    let title = unattendedResult.PENDING
    let titleErrors = ''
    if (process) {
      switch (process.result) {
        case unattendedResult.PENDING:
          title = t(`${tKey}PENDING`)
          break
        case unattendedResult.RUNNING_NO_ERRORS:
          title = t(`${tKey}RUNNING`)
          break
        case unattendedResult.RUNNING_WHIT_ERRORS:
          title = t(`${tKey}RUNNING`) + ' | '
          titleErrors = t(`${tKey}WITH_ERRORS`)
          break
        case unattendedResult.ENDED_NO_ERRORS:
          title = t(`${tKey}FINALIZED`) + ' ' + t(`${tKey}CORRECTLY`)
          break
        case unattendedResult.ENDED_WITH_ERRORS:
          title = t(`${tKey}FINALIZED`) + ' | '
          titleErrors = t(`${tKey}WITH_ERRORS`)
          break
      }
    }

    const isNotSuccesfulSepomexLoad = this.props.process?.unattendedProcessTypeId !== unattendedProcessType.IMPORT_SEPOMEX ||
      (this.props.process?.errors && this.props.process.errors[0].tagFail !== 'ProcessCompletedOk')
    var rowsDetail = []
    if (process && process !== null && process.unattendedProcessTypeId !== unattendedProcessType.AUDIT && process.dossiersList && process.dossiersList.length > 0) {
      rowsDetail = process.dossiersList.map((row, idx) => (
        <tr key={idx}>
          <td>
            {this.getDossierNumnber(row)}
          </td>
          <td>
            {this.getError(row, idx)}
          </td>
        </tr>
      ))
    } else if (process && process !== null && process.unattendedProcessTypeId === unattendedProcessType.AUDIT && process.errors && process.errors.length > 0) {
      rowsDetail = process.errors.map((row, idx) => (
        <tr key={idx}>
          <td>
            {this.getDossierNumnber(row.dossierId)}
          </td>
          <td>
            {this.getError(row.dossierId, idx, row)}
          </td>
        </tr>
      ))
    } else if (process && process !== null && process.errors && process.errors.length > 0) {
      rowsDetail = process.errors.map((row, idx) => (
        <tr key={idx}>
          <td>
            {}
          </td>
          <td>
            {this.getError(null, idx)}
          </td>
        </tr>
      ))
    } else if (process && process !== null && (process.errors === null || process.errors.length === 0) && process.result === unattendedResult.ENDED_WITH_ERRORS) {
      rowsDetail = [1].map((row, idx) => (
        <tr key={1}>
          <td>
            {}
          </td>
          <td>
            {this.getError(null, 1)}
          </td>
        </tr>
      ))
    } else {
      rowsDetail = [1].map((row, idx) => (
        <tr key={idx}>
          <td>

          </td>
          <td>
            <span style={{ color: 'green' }} >{this.props.t('MANAGEMENT.UNATTENDED_PROCESS_TAB.PROCESS_COMPLETED')}</span>
          </td>
        </tr>
      ))
    }

    return (
      <Modal backdrop="static" className="massive-modal languages-modal" show={showModal} onHide={this.props.actions.closeUnattendedProcessDetailModal}>
        <form autoComplete="off" >
          <Modal.Header closeButton onHide={this.props.actions.closeApprovalActionModal}>
            <Modal.Title>{title}<span style={{ color: 'red' }}>{titleErrors}</span> </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              {isNotSuccesfulSepomexLoad
                ? <SimpleTablePage
                  columns={[
                    <th key={0} data-field="dossierNumber" onClick={(event) => this.onOrderChange(event)}>
                    </th>,
                    <th key={1} data-field="error" onClick={(event) => this.onOrderChange(event)}>
                    </th>
                  ]}
                  rows={rowsDetail}
                />
                : <div className='table-body'>
                  <Row className='rowRead'>
                    {t('MASTERS.SEPOMEX.IMPORT_MODAL.READ_ROWS') + ' ' + JSON.parse(this.props.process?.errors[0].dataFail).readRows}
                  </Row>
                  <Row className='rowRead'>
                    {t('MASTERS.SEPOMEX.IMPORT_MODAL.VALID_ROWS') + ' ' + JSON.parse(this.props.process?.errors[0].dataFail).validateRows}
                  </Row>
                  <Row className='rowRead'>
                    {t('MASTERS.SEPOMEX.IMPORT_MODAL.UPDATED_ROWS') + ' ' + JSON.parse(this.props.process?.errors[0].dataFail).updatedRows}
                  </Row>
                </div>
              }
            </div>
            {this.props.process?.unattendedProcessTypeId === unattendedProcessType.OPERATE_ACTION_PRINT_DELIVERY_NOTE && <div className="div-btn-download-delivery-notes">
              <Button
                id="role-modal-submit-button"
                className="btn-standard"
                disabled={this.props.process?.dossierProcessedOk < 1 }
                onClick = {() => this.props.actions.unattendedProcessDownloadDeliveryNotes(this.props.process.unattendedProcessId)}>
                {t(`${tKey}DOWNLOAD_DELIVERY_NOTE`)}
              </Button>
            </div>}
          </Modal.Body>
          <Modal.Footer>
            <Col sm={12} className="btn-wrapper btn-cent">
              <Button
                id="role-modal-submit-button"
                className="btn-standard"
                onClick = {this.props.actions.closeUnattendedProcessDetailModal}>
                {t(`${tKey}CLOSE`)}
              </Button>
            </Col>
          </Modal.Footer>
        </form>
      </Modal>

    )
  }
}

export default reduxForm({
  form: 'unattendedProcessDetailModal'
})(UnattendedProcessDetailModal)
