import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import PopoverItem from '../../components/search/PopoverItem'

export function mapStateToProps (state) {
  return {
    ...state.PrivateRoute
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({

    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(PopoverItem))
