import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { isInvalid, getFormSyncErrors, getFormValues, stopAsyncValidation } from 'redux-form'

import { translate } from 'react-polyglot'
// import { push } from 'react-router-redux'
import PurchaseComponent from '../../../components/dossiers/purchase/Purchase'
import PurchaseComponent_v2 from '../../../_v2/components/dossiers/purchase/Purchase'
import messageModalActions from '../../../actions/modals/messageModal'
import purchaseActions from '../../../actions/dossier/purchase'
import commentsActions from '../../../actions/dossier/common/comments'
import clientActions from '../../../actions/dossier/common/client'
import deliveryActions from '../../../actions/dossier/common/delivery'
import { storeDossier, documentsToSendSignGetAll, openDocumentsToSendSign, sendDocumentToSign } from '../../../actions/dossier/commonDossierActions'
import vehicleActions from '../../../actions/dossier/common/vehicle'
import commonHeaderActions from '../../../actions/dossier/common/commonHeader'
import { openDocumentManagementModal as openDocumentModal } from '../../../actions/dossier/common/documentManagementModal'
import { openDocumentEditionModal, closeAddOrEditDocumentModal, signTypes } from '../../../actions/dossier/common/documentEditionModal'
import {
  fetchEntitySubTypeCombo, fetchCancelCombo, fetchEntityFilterSubTypeCombo, fetchReasons, fetchAllReasons,
  fetchVoCompaniesCombo, fetchRentingEntityCombo, fetchPurchaseOrderTypeCombo, fetchStockStatusCombo, fetchLowestOrganizedUnits,
  fecthOrganizedUnitsByDossierUo, fetchCountries, fecthFamily, fetchDossierSubTypes,
  fetchEntityCountryTypeCombo, fetchEntityStateTypeCombo, fetchCommercialSocietyTypeCombo,
  fetchEntityCityTypeCombo, fetchEntityTownTypeCombo, fetchEntityColonyTypeCombo, fetchEntityCPTypeCombo,
  fetchCarlineCombo, fetchCatalogueCombo, fetchModelCombo, fetchColorCombo, fetchTapestryCombo,
  resetCarlineCombo, resetCatalogueCombo, resetColorCombo, resetTapestryCombo, fetchActivityBpro, fetchSameLevelOusCombo,
  fetchClientDataAddress, fetchSaleTypeAll, fetchPuscharseSaleEntitys
} from '../../../actions/combos/combos'
import scrollerActions from '../../../actions/commons/scroller'
import { fetchFilteredRelatedDossier } from '../../../actions/dossier/common/dossierRelatedDossiers'
import buttonsHeaderActions from '../../../actions/dossier/common/buttonsHeader'
import buttonsSidebarActions from '../../../actions/dossier/common/buttonsSidebar'
import { getChangeUo, getSalesmanChangeUo, postUpdateChangeUo } from '../../../actions/dossier/common/changeUo'
import { checkPrivateRfcFormat, foreignPassportWarning, fetchSalesServices, saveSalesService, fetchNotValidDatesService, openOrCloseServiceAddModal } from '../../../actions/dossier/sales'
import { openModal as openCommonModal, openDocWarning } from '../../../actions/common'
import {
  fetchCustomFields
} from '../../../actions/dossier/customFields'
import { validate } from '../../../actions/dossier/common/validations'
import { registerLastAccess } from '../../../actions/dossier/common/lastAccessDossier'
import { getStorageValue } from '../../../storage/storage'
import TrackingContainer from '../../tracking/trackingContainer'
// import { withRouter } from 'react-router-dom'
export function mapStateToProps (state) {
  return {
    vehicleProps: state.vehicle,
    dossier: state.purchaseDossier,
    dossierSales: state.salesDossier,
    combos: state.combos,
    showManagementModal: state.documentaryManagementModal.showModal,
    windowWidth: state.windowDimensions.width,
    statusHeaderHeight: state.statusHeader.height,
    activeTab: state.buttonsHeader.activeTab,
    buttonsHeaderHeight: state.buttonsHeader.height,
    activeSection: state.buttonsSidebar.activeSection,
    relatedDossiersOrderBy: state.dossierRelatedDossiers.orderby,
    isInvalid: isInvalid('purchase_dossier')(state),
    formErrors: getFormSyncErrors('purchase_dossier')(state),
    formValues: getFormValues('purchase_dossier')(state),
    changeUo: state.changeUo,
    comment: state.comment,
    reason: state.reason,
    cancelCombo: state.combos.cancelCombo,
    auth: state.auth
  }
}
export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      ...purchaseActions,
      ...commentsActions,
      ...clientActions,
      ...vehicleActions,
      ...deliveryActions,
      ...commonHeaderActions,
      ...buttonsHeaderActions,
      ...buttonsSidebarActions,
      ...scrollerActions,
      storeDossier,
      openDocumentEditionModal,
      closeAddOrEditDocumentModal,
      openDocumentModal,
      fetchEntitySubTypeCombo,
      fetchEntityFilterSubTypeCombo,
      fetchFilteredRelatedDossier,
      // navigateTo: push,
      ...messageModalActions,
      fetchReasons,
      fetchAllReasons,
      fetchVoCompaniesCombo,
      fetchRentingEntityCombo,
      fetchStockStatusCombo,
      fetchPurchaseOrderTypeCombo,
      documentsToSendSignGetAll,
      openDocumentsToSendSign,
      sendDocumentToSign,
      signTypes,
      fetchLowestOrganizedUnits,
      getChangeUo,
      getSalesmanChangeUo,
      postUpdateChangeUo,
      fecthOrganizedUnitsByDossierUo,
      fetchCountries,
      fecthFamily,
      fetchCancelCombo,
      checkPrivateRfcFormat,
      foreignPassportWarning,
      fetchSalesServices,
      fetchDossierSubTypes,
      saveSalesService,
      fetchNotValidDatesService,
      openOrCloseServiceAddModal,
      fetchCommercialSocietyTypeCombo,
      fetchEntityCountryTypeCombo,
      fetchEntityStateTypeCombo,
      fetchEntityCityTypeCombo,
      fetchEntityTownTypeCombo,
      fetchEntityColonyTypeCombo,
      fetchEntityCPTypeCombo,
      openCommonModal,
      fetchCarlineCombo,
      fetchCatalogueCombo,
      fetchModelCombo,
      fetchColorCombo,
      fetchTapestryCombo,
      resetCarlineCombo,
      resetCatalogueCombo,
      resetColorCombo,
      resetTapestryCombo,
      fetchActivityBpro,
      fetchSameLevelOusCombo,
      fetchCustomFields,
      validate,
      stopAsyncValidation,
      openDocWarning,
      fetchClientDataAddress,
      fetchSaleTypeAll,
      fetchPuscharseSaleEntitys,
      registerLastAccess
    }, dispatch)
  }
}
let version = parseInt(getStorageValue('interfaceVersion'))
var decoratedComponent = null
if (version === 2) {
  decoratedComponent = TrackingContainer(PurchaseComponent_v2, true)
} else {
  decoratedComponent = TrackingContainer(PurchaseComponent, true)
}
export default connect(mapStateToProps, mapDispatchToProps)(translate()(decoratedComponent))
