const managementSections =
  [
    {
      page: 'userDataPage',
      id: 'btn_userData',
      code: 'userData',
      icon: 'iDocIcon-user-user-menu',
      title: 'PROFILE.USER_TAB.TITLE'
    },
    {
      page: 'userOrganizationPage',
      id: 'btn_userData',
      code: 'organization',
      icon: 'iDocIcon-admin-tools',
      title: 'PROFILE.ORGANIZATION_TAB.TITLE'
    },
    {
      page: 'userApplicationsPage',
      id: 'btn_userData',
      code: 'applications',
      icon: 'iDocIcon-admin-tools',
      title: 'PROFILE.APPLICATIONS_TAB.TITLE'
    },
    {
      page: 'notificationsPage',
      id: 'btn_notifications',
      code: 'notifications',
      icon: 'iDocIcon-admin-tools',
      title: 'PROFILE.NOTIFICATIONS_TAB.TITLE'
    }
  ]

export default managementSections
