import apiFetch from '../apiFetch'

export default function (token, { orderBy, number, approvalDateFrom, approvalDateTo, organizedUnit }, body) {
  const queryParamsArray = [
    orderBy ? `orderBy=${orderBy}` : null,
    number ? `number=${number}` : null,
    approvalDateFrom ? `approvalDateFrom=${approvalDateFrom}` : null,
    approvalDateTo ? `approvalDateTo=${approvalDateTo}` : null,
    organizedUnit ? `organizedUnitId=${organizedUnit}` : null
  ]
  const queryParams = queryParamsArray.reduce(
    (result, query, idx) => result + (query ? (result ? `&${query}` : `?${query}`) : '')
    , ''
  )
  return apiFetch({
    endPoint: `DossierFinder/CampaignExportFinderExport${queryParams}`,
    method: 'POST',
    body: body,
    token: token
  })
}
