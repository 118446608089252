import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import DocumentaryGroupDocumentsModal from '../../../components/dossiers/common/documentaryManagement/DocumentaryGroupDocumentsModal'
import { openDocWarning, openModal } from '../../../actions/common'
import { openDocumentCreationModal } from '../../../actions/dossier/common/documentEditionModal'
import {
  refreshDocumentManagementModal
} from '../../../actions/dossier/common/documentManagementModal'
export function mapStateToProps (state, ownProps) {
  return {
    ...state.documentaryGroupDocumentsModalPage,
    ...ownProps
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      openDocWarning,
      openModal,
      openDocumentCreationModal,
      refreshDocumentManagementModal
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(DocumentaryGroupDocumentsModal))
