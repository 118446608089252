import React, { PureComponent } from 'react'
import { Col, Row, Panel, Button } from 'react-bootstrap'
import { permissions } from '../../../constants/backendIds'
import { getFieldsPermisions } from '../../../util/permisionFunctions'
import { compareObjects } from '../../../util/utils'
import InputDatePicker from '../../commons/form/InputDatePicker'
import { Field } from 'redux-form'

export default class BookingAndDelivery extends PureComponent {
  constructor () {
    super()
    this.state = {
      open: false,
      fieldsConfiguration: {
        confirmationDoneButton: null,
        confirmationDate: null,
        bookingDate: null,
        deliveryDate: null,
        anticipatedDate: null
      }
    }
  }

  initializePermisions (props) {
    const fieldKeys = Object.keys(this.state.fieldsConfiguration)
    const newState = getFieldsPermisions(props.componentConfiguration.fieldsConfiguration, fieldKeys, props.componentConfiguration.permission)
    this.setState({ fieldsConfiguration: newState.fieldsConfiguration })
  }

  toggleComponent () {
    if (this.state.open) {
      this.props.actions.setActiveSection(false)
    }
    this.setState({ open: !this.state.open })
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (this.props.dossier && this.props.dossier.dossierId) {
      if (!compareObjects(prevProps.componentConfiguration || {}, this.props.componentConfiguration, true)) {
        this.initializePermisions(this.props)
      }
      if (!prevProps.open && this.props.open) {
        this.setState({ open: true })
      }
    }
    return prevProps
  }

  componentDidUpdate () {
    // don't remove use for getsnapshot
  }

  componentDidMount () {
    this.initializePermisions(this.props)
  }

  render () {
    const {
      t, dossierId, readOnlyFields, actions: { confirmDateWithIdByStage }, dossier: { bookingAndDelivery }
    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.BOOKING_AND_DELIVERY.'

    return (
      <Row className="entry-panel dossier-panel">
        <div className="header-panel" onClick={() => this.toggleComponent()}>
          <div className="name-wrapper">
            <i className="ico-i" />
            <h4>{t(`${tKey}TITLE_PANEL`)}</h4>
          </div>
          <i className={this.state.open ? 'ico-arrow-black-up' : 'ico-arrow-black-down'} />
        </div>
        <Panel expanded={this.state.open} onToggle={() => { }}>
          <Panel.Collapse>
            {
              this.state.fieldsConfiguration.confirmationDoneButton &&
                            this.state.fieldsConfiguration.confirmationDoneButton.permission === permissions.editable &&
                            !bookingAndDelivery?.confirmationDate && (
                <Row>
                  <Col className="col-right">
                    <Button className='btn-save' onClick={() => confirmDateWithIdByStage(dossierId)} >
                      {t(`${tKey}DELIVERED`)}
                    </Button>
                  </Col>
                </Row>
              )
            }
            <Row>
              {
                this.state.fieldsConfiguration.bookingDate &&
                            this.state.fieldsConfiguration.bookingDate.permission !== permissions.hidden && (
                  <Field
                    id="bookingDate"
                    key="bookingDate"
                    name="bookingAndDelivery.bookingDate"
                    colSm={4}
                    controlLabel={t(`${tKey}BOOKING_DATE`)}
                    placeholder={t(`${tKey}BOOKING_DATE`)}
                    disabled={readOnlyFields || this.state.fieldsConfiguration.bookingDate.permission !== permissions.editable}
                    component={InputDatePicker} />
                )
              }
              {
                this.state.fieldsConfiguration.anticipatedDate &&
                            this.state.fieldsConfiguration.anticipatedDate.permission !== permissions.hidden && (
                  <Field
                    id="anticipatedDate"
                    key="anticipatedDate"
                    name="bookingAndDelivery.anticipatedDate"
                    colSm={4}
                    controlLabel={t(`${tKey}ANTICIPATED_DATE`)}
                    placeholder={t(`${tKey}ANTICIPATED_DATE`)}
                    disabled={readOnlyFields || this.state.fieldsConfiguration.anticipatedDate.permission !== permissions.editable}
                    component={InputDatePicker} />
                )
              }
              {
                this.state.fieldsConfiguration.deliveryDate &&
                            this.state.fieldsConfiguration.deliveryDate.permission !== permissions.hidden && (
                  <Field
                    id="deliveryDate"
                    key="deliveryDate"
                    name="bookingAndDelivery.deliveryDate"
                    colSm={4}
                    controlLabel={t(`${tKey}DELIVERY_DATE`)}
                    placeholder={t(`${tKey}DELIVERY_DATE`)}
                    disabled={readOnlyFields || this.state.fieldsConfiguration.deliveryDate.permission !== permissions.editable}
                    component={InputDatePicker} />
                )
              }
              {
                this.state.fieldsConfiguration.confirmationDate &&
                            this.state.fieldsConfiguration.confirmationDate.permission !== permissions.hidden && (
                  <Field
                    id="confirmationDate"
                    key="confirmationDate"
                    name="bookingAndDelivery.confirmationDate"
                    colSm={4}
                    controlLabel={t(`${tKey}CONFIRM_DATE`)}
                    placeholder={t(`${tKey}CONFIRM_DATE`)}
                    disabled={readOnlyFields || this.state.fieldsConfiguration.confirmationDate.permission !== permissions.editable}
                    component={InputDatePicker} />
                )
              }
            </Row>
          </Panel.Collapse>
        </Panel>
      </Row>
    )
  }
}
