import mastersActionTypes from '../../../constants/actions/masters/masters'

function initialState () {
  return {
    organizedUnitSaleOperations: [],
    organizedUnitSaleOperationsCount: 0,
    pagesCount: 0,
    languageList: [],
    filter: {
      page: 1,
      orderBy: '',
      pageSize: 10
    },
    organizedUnitSaleOperationModal: {
      organizedUnitSaleOperationId: null,
      showModal: false
    }
  }
}

function fetchOrganizedUnitSaleOperationListSuccess (state, { organizedUnitSaleOperationList, filter }) {
  return {
    ...state,
    organizedUnitSaleOperationList: organizedUnitSaleOperationList.organizedUnitSaleOperations,
    organizedUnitSaleOperationsCount: organizedUnitSaleOperationList.organizedUnitSaleOperationsCount,
    pagesCount: organizedUnitSaleOperationList.pagesCount,
    languageSelected: organizedUnitSaleOperationList.languageSelected,
    filter
  }
}

function openEditOrganizedUnitSaleOperationModalSuccess (state, { organizedUnitSaleOperationId }) {
  const initial = initialState()
  return {
    ...state,
    organizedUnitSaleOperationModal: {
      ...initial.organizedUnitSaleOperationModal,
      organizedUnitSaleOperationId,
      showModal: true
    }
  }
}

function openOrganizedUnitSaleOperationModalSuccess (state, { organizedUnitSaleOperation }) {
  const initial = initialState()
  return {
    ...state,
    organizedUnitSaleOperationModal: {
      ...initial.organizedUnitSaleOperationModal,
      organizedUnitSaleOperation: organizedUnitSaleOperation,
      nonEditable: false,
      showModal: true
    }
  }
}

function closeEditModalSuccess (state) {
  return {
    ...state,
    organizedUnitSaleOperationModal: {
      ...initialState().organizedUnitSaleOperationModal
    }
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case mastersActionTypes.FETCH_ORGANIZED_UNIT_SALE_OPERATION_LIST_SUCCESS:
      return fetchOrganizedUnitSaleOperationListSuccess(state, action)
    case mastersActionTypes.OPEN_EDIT_ORGANIZED_UNIT_SALE_OPERATION_MODAL_SUCCESS:
      return openEditOrganizedUnitSaleOperationModalSuccess(state, action)
    case mastersActionTypes.OPEN_ADD_ORGANIZED_UNIT_SALE_OPERATION_MODAL_SUCCESS:
      return openOrganizedUnitSaleOperationModalSuccess(state, action)
    case mastersActionTypes.CLOSE_EDIT_ORGANIZED_UNIT_SALE_OPERATION_MODAL:
      return closeEditModalSuccess(state, action)
    default:
      return state
  }
}
