import React, { PureComponent } from 'react'
import { Row } from 'react-bootstrap'
import StageBar from './stagesBarChart/StageBar'
import { Link } from 'react-router-dom'

class StageBarChart extends PureComponent {
  render () {
    const {
      subType: { name, subTotalHigh, subTotalMedium, subTotalLow, stages, subTypeId },
      history, salesmanId, urgencyLowLabel, urgencyMediumLabel, urgencyHighLabel, defaultSalesmen
    } = this.props
    return (
      <Row className="box block sm-box">
        <div className="resumeBlockTitle">
          <Link to={`/dashboard/${salesmanId || 0}/0/${subTypeId}/0`} >
            {name}
          </Link>
        </div>
        <div className="box-title">
          <div className="legend-wrapper legend-card">
            <ul className="list-inline">
              <li>
                <span className="color-point red"/>
                <span>{subTotalHigh || 0}</span>
              </li>
              <li>
                <span className="color-point yellow"/>
                <span>{subTotalMedium || 0}</span>
              </li>
              <li>
                <span className="color-point green"/>
                <span>{subTotalLow || 0}</span>
              </li>
            </ul>
          </div>
        </div>
        {
          stages.map((stage, idx) => <StageBar
            urgencyLowLabel={urgencyLowLabel}
            urgencyMediumLabel={urgencyMediumLabel}
            urgencyHighLabel={urgencyHighLabel}
            salesmanId={salesmanId}
            subTypeId={subTypeId}
            key={idx}
            idx={idx}
            stage={stage}
            history={history}
            barWidth={(100 / stages.length)}
            isLastStage={idx === stages.length - 1}
            defaultSalesmen={defaultSalesmen}
          />)
        }
      </Row>
    )
  }
}

export default StageBarChart
