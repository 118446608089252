import React, { PureComponent } from 'react'
import { reduxForm, Field } from 'redux-form'
import { Row, Col, Table, Button, Tooltip, OverlayTrigger } from 'react-bootstrap'
import IDocCarPagination from '../../commons/iDocCarPagination'
import InputText from '../../commons/form/InputText'
import StyleTaskModalPage from '../../../containers/masters/StyleTask/StyleTaskModalPage'
import { styleTasksIds } from '../../../constants/backendIds'

class StyleTaskMaster extends PureComponent {
  constructor () {
    super()
    this.state = {
      filter: {
        searchName: null
      }
    }
  }

  getSortIcon (column = '') {
    switch (this.props.filter.orderBy) {
      case '+' + column:
        return 'ico-sort-selected-asc'
      case '-' + column:
        return 'ico-sort-selected-desc'
      default:
        return 'ico-sort'
    }
  }

  changeOrder (field) {
    switch (this.props.filter.orderBy) {
      case `+${field}`:
        this.props.actions.fetchStyleTaskList({ ...this.state.filter, ...this.props.filter, orderBy: `-${field}` })
        break
      default:
        this.props.actions.fetchStyleTaskList({ ...this.state.filter, ...this.props.filter, orderBy: `+${field}` })
        break
    }
  }

  handleNameChange (searchName) {
    if (this.state.filter.searchName !== searchName) {
      this.setState({ filter: { ...this.state.filter, searchName } })
    }
  }

  searchStyleTask () {
    this.props.actions.fetchStyleTaskList({ ...this.props.filter, ...this.state.filter, page: 1 })
  }

  applyStyleTask (task) {
    switch (task.styleTaskCssId) {
      case styleTasksIds.Normal:
        return ''
      case styleTasksIds.Bold:
        return 'activebold'
      case styleTasksIds.BoldBlue:
        return 'activeboldblue'
      case styleTasksIds.BoldRed:
        return 'activeboldred'
    }
  }

  resetFilters () {
    this.setState({
      filter: {
        searchName: null
      }
    }, () => { this.searchStyleTask() })
  }

  render () {
    const {
      t, styleTaskList, pagesCount, styleTaskListCount, filter,
      filter: { page },
      actions: { openStyleTaskModal, fetchStyleTaskList, deleteStyleTask }
    } = this.props
    const tKey = 'MASTERS.STYLE_TASK_MASTERS.'
    const editTooltip = (<Tooltip id="editTooltip">{t(`${tKey}EDIT_TOOLTIP`)}</Tooltip>)
    const deleteTooltip = (<Tooltip id="deleteTooltip">{t(`${tKey}DELETE_TOOLTIP`)}</Tooltip>)
    const hasFilters = this.state.filter.searchName
    return (
      <div className="admin-wrapper">
        <StyleTaskModalPage/>
        <Row className="section-users-header">
          <Col sm={12}>
            <div className="header-panel">
              <h4>{t(`${tKey}TITLE`)}</h4>
            </div>
          </Col>
        </Row>
        <Row>
          <div className="search-form">
            <span className="title-search"><i className="ico-search" /> {t(`${tKey}SEARCH`)}</span>
            <Row>
              <Field
                name="filters.searchName"
                colSm={4}
                controlLabel={t(`${tKey}SEARCH_BY`)}
                placeholder={t(`${tKey}SEARCH_BY`)}
                component={InputText}
                onInputChange={(value) => this.handleNameChange(value)}
              />
              <Col sm={8} className="btn-wrapper">
                <p></p>
                <Button
                  className={'btn-white btn-icon btn-input btn-white search-button'}
                  style={{ width: '100%' }}
                  onClick={() => this.searchStyleTask()}>
                  <i className="ico-search" />
                  {t(`${tKey}SEARCH`)}
                </Button>
                <Button
                  className={'btn-icon btn-input ' + (hasFilters ? 'btn-standard' : 'btn-cancel')}
                  onClick={() => {
                    this.props.initialize({})
                    this.resetFilters()
                  }}
                >
                  <i className="ico-cancel" />
                  {t('MASTERS.RESET_BUTTON')}
                </Button>
              </Col>
            </Row>
          </div>
        </Row>
        <Col sm={12} className="col-row  section-users-body">
          <Table className="editable-table" hover responsive>
            <thead>
              <tr>
                <th onClick={() => { this.changeOrder('order') }}>
                  {t(`${tKey}ORDER`)}
                  <i className={this.getSortIcon('order')} />
                </th>
                <th onClick={() => { this.changeOrder('name') }}>
                  {t(`${tKey}NAME`)}
                  <i className={this.getSortIcon('name')} />
                </th>
                <th style={{ width: '77px' }}>{t('DOSSIER_COMPONENTS.ACCESSORIES.ACTIONS')}</th>
              </tr>
            </thead>
            <tbody>
              {styleTaskList && styleTaskList.map((styleTask, idx) => {
                return (
                  <tr key={idx}>
                    <td>{styleTask.order}</td>
                    <td className={this.applyStyleTask(styleTask)}>{styleTask.name}</td>
                    <td>
                      {(
                        <div>
                          <a onClick={() => openStyleTaskModal(styleTask)}>
                            <OverlayTrigger placement="left" overlay={editTooltip}>
                              <i className="ico-edit-white" />
                            </OverlayTrigger>
                          </a>
                          {styleTask && styleTask.styleTaskCssId !== styleTasksIds.Normal ? (<a onClick={() => deleteStyleTask(styleTask.styleTaskId)}>
                            <OverlayTrigger placement="left" overlay={deleteTooltip}>
                              <i className="ico-trash" />
                            </OverlayTrigger>
                          </a>) : (<a className="isDisabledDelete"><i className="ico-trash" /></a>)}
                        </div>
                      )}
                    </td>
                  </tr>)
              })
              }
              {(styleTaskList === null || styleTaskList === undefined || styleTaskList.length === 0) &&
            <tr>
              <td colSpan={10}><h2>{t(`${tKey}RESULTS_NOT_FOUND`)}</h2></td>
            </tr>}
            </tbody>
          </Table>
          <div className="section-users-footer">
            <nav key={1} className="text-center">
              <IDocCarPagination
                id="btn_pag_sales"
                page={page}
                pagesCount={pagesCount}
                totalRows={styleTaskListCount}
                onSelectAction={(value) => fetchStyleTaskList({ ...filter, ...this.state.filter, page: value })}
              />
            </nav>
          </div>
        </Col>
      </div>
    )
  }
}

export default reduxForm({
  form: 'listStyleTask'
})(StyleTaskMaster)
