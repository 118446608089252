import vehicleActionTypes from '../../../constants/actions/dossier/common/vehicle'

export function initialState () {
  return {
    showOrderModal: false,
    dossierId: null,
    simpleInputTextValidation: '',
    searchDealerNumber: '',
    searchVinChassis: '',
    searchLicensePlate: '',
    searchMakerNumber: '',
    vehicleSelectionModal: {
      show: false,
      purchaseList: [],
      stockDossierId: null,
      organizedUnitId: null,
      filters: null
    },
    showVehicleFinderModal: false,
    vehicleCreationModal: {
      vinChassis: null,
      licensePlate: null,
      brandId: null,
      commercialCode: null,
      fuelId: null,
      color: null,
      tapestry: null
    }
  }
}

function fetchOrderModalSuccess (state, { dossierId }) {
  return {
    ...state,
    showOrderModal: true,
    dossierId
  }
}

function setSearchDealerNumber (state, { searchDealerNumber }) {
  return {
    ...state,
    searchDealerNumber,
    simpleInputTextValidation: ''
  }
}

function setSearchVinChassis (state, { searchVinChassis }) {
  return {
    ...state,
    searchVinChassis,
    simpleInputTextValidation: ''
  }
}

function setSearchLicensePlate (state, { searchLicensePlate }) {
  return {
    ...state,
    searchLicensePlate,
    simpleInputTextValidation: ''
  }
}

function setSearchMakerNumber (state, { searchMakerNumber }) {
  return {
    ...state,
    searchMakerNumber,
    simpleInputTextValidation: ''
  }
}

function setSimpleInputTextValidation (state, { simpleInputTextValidation }) {
  return {
    ...state,
    simpleInputTextValidation
  }
}

function resetSearchFields (state) {
  return {
    ...state,
    simpleInputTextValidation: '',
    searchDealerNumber: '',
    searchVinChassis: '',
    searchLicensePlate: '',
    searchMakerNumber: ''
  }
}

function closeOrderModal (state) {
  return initialState()
}

function fetchVehicleSelectionModalSuccess (state, { purchaseList, stockDossierId, filters }) {
  return {
    ...state,
    vehicleSelectionModal: {
      show: true,
      purchaseList: purchaseList || state.vehicleSelectionModal.purchaseList,
      stockDossierId: stockDossierId || state.vehicleSelectionModal.stockDossierId,
      filters: filters || state.vehicleSelectionModal.filters
    }
  }
}

function closeVehicleSelectionModal (state) {
  return {
    ...state,
    vehicleSelectionModal: {
      ...initialState().vehicleSelectionModal
    }
  }
}

function openVehicleFinderModal (state, { openClose }) {
  return {
    ...state,
    showVehicleFinderModal: openClose !== undefined ? openClose : !state.showVehicleFinderModal
  }
}

function closeVehicleCreationModal (state) {
  return {
    ...state,
    vehicleCreationModal: {
      ...initialState().vehicleCreationModal
    },
    showVehicleFinderModal: false
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case vehicleActionTypes.FETCH_ORDER_MODAL_SUCCESS:
      return fetchOrderModalSuccess(state, action)
    case vehicleActionTypes.SET_SEARCH_DEALER_NUMBER:
      return setSearchDealerNumber(state, action)
    case vehicleActionTypes.SET_SEARCH_VIN_CHASSIS:
      return setSearchVinChassis(state, action)
    case vehicleActionTypes.SET_SEARCH_LICENCE_PLATE:
      return setSearchLicensePlate(state, action)
    case vehicleActionTypes.SET_SEARCH_MAKER_NUMBER:
      return setSearchMakerNumber(state, action)
    case vehicleActionTypes.SET_SIMPLE_INPUT_TEXT_VALIDATION:
      return setSimpleInputTextValidation(state, action)
    case vehicleActionTypes.RESET_SEARCH_FIELDS:
      return resetSearchFields(state, action)
    case vehicleActionTypes.CLOSE_ORDER_MODAL:
      return closeOrderModal(state, action)
    case vehicleActionTypes.FETCH_VEHICLE_SELECTION_MODAL_SUCCESS:
      return fetchVehicleSelectionModalSuccess(state, action)
    case vehicleActionTypes.CLOSE_VEHICLE_SELECTION_MODAL:
      return closeVehicleSelectionModal(state, action)
    case vehicleActionTypes.OPEN_VEHICLE_FINDER_MODAL:
      return openVehicleFinderModal(state, action)
    case vehicleActionTypes.CLOSE_VEHICLE_CREATION_MODAL:
      return closeVehicleCreationModal(state, action)
    default:
      return state
  }
}
