import React, { PureComponent } from 'react'
import { OverlayTrigger, Tooltip, Glyphicon, Checkbox } from 'react-bootstrap'
import { Link } from 'react-router-dom'

class ServiceRow extends PureComponent {
  render () {
    const {
      t,
      tKey, service, isPurchase, actions: { showServiceObservationModal }
    } = this.props
    const viewObservation = (<Tooltip id="viewObservation">{t(`${tKey}VIEW_OBSERVATIONS`)}</Tooltip>)
    const style = service.providerObservations ? { textAlign: 'left', cursor: 'pointer' } : { textAlign: 'left' }
    return (
      <tr>
        <td><Link to={`/dossier${service.url}`}>{service.number}</Link></td>
        <td>{service.subType}</td>
        <td>{service.stage}</td>
        <td>{service.entityName} </td>
        <td>{service.destination} </td>
        <td>{service.orderDate} </td>
        <td>{service.requiredDate} </td>
        <td>{service.availabilityDate}</td>
        <td>{service.confirmedDate} </td>
        <td style={style}>
          {service.providerObservations && (
            <a>
              <OverlayTrigger placement="right" overlay={viewObservation}>
                <Glyphicon glyph="info-sign"
                  onClick={() => { showServiceObservationModal() }} />
              </OverlayTrigger>
            </a>
          )}
        </td>
        {isPurchase === undefined && (
          <td className='text-center'><span><Checkbox className="checkbox-no-label" checked={service.isFromPurchaseParent} disabled inline /></span></td>
        )}
      </tr>
    )
  }
}
export default ServiceRow
