// Reac/Redux
import { put, call, select, takeEvery } from 'redux-saga/effects'

// Others
import { getAuth } from '../../../selectors/access/auth'
import { getFaqSectionMasterFilter } from '../../../selectors/masters/faqSectionMaster'
import { handleError } from '../../errors/errorManager'
import faqSectionActionTypes from '../../../constants/actions/masters/faqSection/faqSectionMaster'
import { yesNoModal } from '../../modalsListeners/yesNoModal'

// Actions
import { showLoader, hideLoader } from '../../../actions/common.js'
import { loadFaqSectionTable, loadFaqSection, closeFaqSectionModal } from '../../../actions/masters/faqSection/faqSection'

// Services
import postFaqService from '../../../services/faqSection/postFaqSection'
import deleteFaqSectionService from '../../../services/faqSection/deleteFaqSection'
import putFaqSectionService from '../../../services/faqSection/putFaqSection'
import postFaqSectionListByFilterService from '../../../services/faqSection/postFaqSectionListByFilter'
import getLanguageListService from '../../../services/language/getLanguageList'
import getFaqSectionService from '../../../services/faqSection/getFaqSection'
import recoverFaqSectionService from '../../../services/faqSection/recoverFaqSection'

export function * fetchFaqSectionTable ({ filter }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield filter = yield filter || select(getFaqSectionMasterFilter)
    const faqSectionTable = yield call(postFaqSectionListByFilterService, auth.token, filter)
    yield put(loadFaqSectionTable(faqSectionTable, filter))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchFaqSectionTable () {
  yield takeEvery(faqSectionActionTypes.FETCH_FAQ_SECTION_TABLE, fetchFaqSectionTable)
}

export function * deleteFaqSection ({ faqSectionId }) {
  try {
    const confirmed = yield call(yesNoModal, 'deleteFaqSection')
    if (confirmed) {
      yield put(showLoader())
      const auth = yield select(getAuth)
      const filter = yield filter || select(getFaqSectionMasterFilter)
      yield call(deleteFaqSectionService, auth.token, faqSectionId)
      const faqSectionTable = yield call(postFaqSectionListByFilterService, auth.token, filter)
      yield put(loadFaqSectionTable(faqSectionTable, filter))
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDeleteFaqSection () {
  yield takeEvery(faqSectionActionTypes.DELETE_FAQ_SECTION, deleteFaqSection)
}

export function * fetchFaqSection ({ faqSectionId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const languageList = yield call(getLanguageListService, auth.token)
    let faqMaster = []
    if (faqSectionId) {
      faqMaster = yield call(getFaqSectionService, auth.token, faqSectionId)
    }
    yield put(loadFaqSection(languageList, faqMaster))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchFaqSection () {
  yield takeEvery(faqSectionActionTypes.FETCH_FAQ_SECTION, fetchFaqSection)
}

export function * recoverFaqSection ({ faqSectionId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const filter = yield filter || select(getFaqSectionMasterFilter)
    yield call(recoverFaqSectionService, auth.token, faqSectionId)
    const faqSectionList = yield call(postFaqSectionListByFilterService, auth.token, filter)
    yield put(loadFaqSectionTable(faqSectionList, filter))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchRecoverFaqSection () {
  yield takeEvery(faqSectionActionTypes.RECOVER_FAQ_SECTION, recoverFaqSection)
}

export function * saveFaqSection ({ faqSectionId, uoId, faqTranslations }) {
  try {
    yield put(showLoader())
    const filter = yield filter || select(getFaqSectionMasterFilter)
    const auth = yield select(getAuth)
    if (faqSectionId) {
      yield call(putFaqSectionService, faqSectionId, uoId, faqTranslations, auth.token)
    } else {
      yield call(postFaqService, uoId, faqTranslations, auth.token)
    }
    const faqSectionList = yield call(postFaqSectionListByFilterService, auth.token, filter)
    yield put(loadFaqSectionTable(faqSectionList, filter))
    yield put(closeFaqSectionModal())
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSaveFaqSection () {
  yield takeEvery(faqSectionActionTypes.SAVE_FAQ_SECTION, saveFaqSection)
}
