
import React, { PureComponent } from 'react'
import { Modal, Col, Row, FormGroup, Button } from 'react-bootstrap'
import { reduxForm, Field } from 'redux-form'
import InputText from '../../commons/form/InputText'
import InputSelect from '../../commons/form/InputSelect'
import InputCheckBox from '../../commons/form/InputCheckBox'
import TextInput from '../../commons/TextInput'

class DocumentTypeUseMasterModal extends PureComponent {
  constructor () {
    super()
    this.state = {}
  }

  handleOnlyTemplateUploadChange (value) {
    this.props.actions.setDocumentDocumentOnlyTemplateUpload(value)
    this.props.change('canBePrinted', value)
  }

  submitDocument (values) {
    let languages = []
    Object.keys(values).map(item => {
      if (!!values[item] && typeof values[item] === 'object') {
        languages.push(values[item])
      }
    })
    if (languages && values.code && values.documentTypeId) {
      const documentTypeUse = {
        documentTypeUseId: values.documentTypeUseId,
        code: values.code,
        externalCode: values.externalCode,
        iconUrl: values.iconUrl,
        documentTypeId: values.documentTypeId,
        dependentDocumentTypeUseId: values.dependentDocumentTypeUseId,
        canCreateEntity: values.canCreateEntity,
        canBePrinted: values.canBePrinted,
        canBeAddedInHistorical: values.canBeAddedInHistorical,
        canSendToProcessingAgency: values.canSendToProcessingAgency,
        massiveUploadAllowed: values.massiveUploadAllowed,
        showInDocumentaryChecklist: values.showInDocumentaryChecklist,
        permissionId: values.permissionId,
        permissionEditId: values.permissionEditId,
        permissionViewId: values.permissionViewId,
        permissionRejectId: values.permissionRejectId,
        permissionSignId: values.permissionSignId,
        functionToDoWhenCreate: values.functionToDoWhenCreate,
        functionToDoWhenUpdate: values.functionToDoWhenUpdate,
        dependantFunctionToDoWhenCreate: values.dependantFunctionToDoWhenCreate,
        metadataTemplateCode: values.metadataTemplateCode,
        onlyTemplateUpload: values.onlyTemplateUpload,
        documentTypeUseLocales: languages
      }

      let hasChanges = false

      if (this.props.documentTypeUse &&
        (values.code !== this.props.documentTypeUse.code ||
          values.externalCode !== this.props.documentTypeUse.externalCode ||
          values.iconUrl !== this.props.documentTypeUse.iconUrl ||
          values.documentTypeId !== this.props.documentTypeUse.documentTypeId ||
          values.dependentDocumentTypeUseId !== this.props.documentTypeUse.dependentDocumentTypeUseId ||
          values.canCreateEntity !== this.props.documentTypeUse.canCreateEntity ||
          values.canBePrinted !== this.props.documentTypeUse.canBePrinted ||
          values.canBeAddedInHistorical !== this.props.documentTypeUse.canBeAddedInHistorical ||
          values.canSendToProcessingAgency !== this.props.documentTypeUse.canSendToProcessingAgency ||
          values.massiveUploadAllowed !== this.props.documentTypeUse.massiveUploadAllowed ||
          values.showInDocumentaryChecklist !== this.props.documentTypeUse.showInDocumentaryChecklist ||
          values.permissionId !== this.props.documentTypeUse.permissionId ||
          values.permissionEditId !== this.props.documentTypeUse.permissionEditId ||
          values.permissionViewId !== this.props.documentTypeUse.permissionViewId ||
          values.permissionRejectId !== this.props.documentTypeUse.permissionRejectId ||
          values.permissionSignId !== this.props.documentTypeUse.permissionSignId ||
          values.functionToDoWhenCreate !== this.props.documentTypeUse.functionToDoWhenCreate ||
          values.functionToDoWhenUpdate !== this.props.documentTypeUse.functionToDoWhenUpdate ||
          values.dependantFunctionToDoWhenCreate !== this.props.documentTypeUse.dependantFunctionToDoWhenCreate ||
          values.metadataTemplateCode !== this.props.documentTypeUse.metadataTemplateCode ||
          values.onlyTemplateUpload !== this.props.documentTypeUse.onlyTemplateUpload
        )) {
        hasChanges = true
      }

      this.props.actions.submitDocumentTypeUse(documentTypeUse, hasChanges)
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevProps.showModal !== this.props.showModal && this.props.showModal === true) {
      this.props.initialize(this.props.documentTypeUse)
      this.props.languageList.map((language) => {
        this.props.change(`lang${language.languageId}.languageId`, language.languageId)
        const source = (this.props.documentTypeUse?.documentTypeUseId) ? this.props.documentTypeUse.documentTypeUseLocales.find((sourceChannel) => sourceChannel.languageId === language.languageId) : ''
        this.props.change(`lang${language.languageId}.name`, source ? source.name : '')
        this.props.change(`lang${language.languageId}.description`, source ? source.description : '')
      })
    }
  }

  render () {
    const {
      t, handleSubmit, languageList,
      showModal, documentTypeUse,
      combos: { documentType, permissionCombo, documentTypeUseCombo }
    } = this.props
    const tKey = 'MASTERS.DOCUMENT_TYPE_USE_MASTERS.MODAL.'
    return (
      <Modal className="role-modal languages-modal" show={showModal} onHide={this.props.actions.closeDocumentTypeUseMasterModal} backdrop={'static'}>
        <form autoComplete="off" onSubmit={handleSubmit(this.submitDocument.bind(this))}>
          <Modal.Header closeButton onHide={this.props.actions.closeDocumentTypeUseMasterModal}>
            <Modal.Title>{t(`${tKey}TITLE`)}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {documentTypeUse?.documentTypeUseId && (<Col sm={4} className="custom-width-col">
              <h2>{t(`${tKey}ID`)}</h2>
              <Field
                name="documentTypeUseId"
                placeholder={t(`${tKey}ID`)}
                component={InputText}
                disabled={true}
              />
            </Col>)}
            <Col sm={4} className="custom-width-col">
              <h2>{t(`${tKey}CODE`)}</h2>
              <Field
                name="code"
                placeholder={t(`${tKey}CODE`)}
                component={InputText}
                maxLength={30}
              />
            </Col>
            <Col sm={4} className="custom-width-col">
              <h2>{t(`${tKey}EXTERNAL_CODE`)}</h2>
              <Field
                name="externalCode"
                placeholder={t(`${tKey}EXTERNAL_CODE`)}
                component={InputText}
                maxLength={30}
              />
            </Col>
            <Col sm={4} className="custom-width-col">
              <h2>{t(`${tKey}URL`)}</h2>
              <Field
                name="iconUrl"
                placeholder={t(`${tKey}URL`)}
                component={InputText}
                maxLength={50}
              />
            </Col>
            <Col sm={4} className="custom-width-col">
              <h2>{t(`${tKey}DOCUMENT_TYPE`)}</h2>
              <Field
                name="documentTypeId"
                placeholder={t(`${tKey}DOCUMENT_TYPE`)}
                valueKey="id"
                labelKey="value"
                component={InputSelect}
                colSm={0}
                options={documentType}
                disabled={this.state.canBePrintedDisabled}
              />
            </Col>
            <Col sm={4} className="custom-width-col">
              <h2>{t(`${tKey}DOCUMENT_USE_TYPE_DEPENDANT`)}</h2>
              <Field
                name="dependentDocumentTypeUseId"
                placeholder={t(`${tKey}DOCUMENT_USE_TYPE_DEPENDANT`)}
                valueKey="id"
                labelKey="value"
                component={InputSelect}
                colSm={0}
                options={documentTypeUseCombo?.filter(x => x.id !== documentTypeUse?.documentTypeUseId)}
              />
            </Col>
            <Row className='selectors'>
              <Col sm={3}>
                <Field
                  name="canCreateEntity"
                  placeholder={t(`${tKey}CAN_CREATE_ENTITY`)}
                  component={InputCheckBox}
                  inline
                  colSm={0}
                />
              </Col>
              <Col sm={3}>
                <Field
                  name="onlyTemplateUpload"
                  placeholder={t(`${tKey}ONLY_TEMPLATE_UPLOAD`)}
                  component={InputCheckBox}
                  inline
                  onInputChange={(value) => this.handleOnlyTemplateUploadChange(value)}
                  colSm={0}
                />
              </Col>
              <Col sm={3}>
                <Field
                  name="canBePrinted"
                  placeholder={t(`${tKey}CAN_PRINT`)}
                  component={InputCheckBox}
                  inline
                  disabled={documentTypeUse?.onlyTemplateUpload === true}
                  colSm={0}
                />
              </Col>
              <Col sm={3}>
                <Field
                  name="canBeAddedInHistorical"
                  placeholder={t(`${tKey}CAN_ADDED_ARCHIVED_DOSSIER`)}
                  component={InputCheckBox}
                  inline
                  colSm={0}
                />
              </Col>
            </Row>
            <Row className='selectors'>
              <Col sm={3}>
                <Field
                  name="canSendToProcessingAgency"
                  placeholder={t(`${tKey}CAN_SEND_MANAGEMENT`)}
                  component={InputCheckBox}
                  inline
                  colSm={0}
                />
              </Col>
              <Col sm={3}>
                <Field
                  name="massiveUploadAllowed"
                  placeholder={t(`${tKey}ALLOW_BULK_UPLOAD`)}
                  component={InputCheckBox}
                  inline
                  colSm={0}
                />
              </Col>
              <Col sm={3}>
                <Field
                  name="showInDocumentaryChecklist"
                  placeholder={t(`${tKey}AVAILABLE_DOCUMENTARY_CHECKLIST`)}
                  component={InputCheckBox}
                  inline
                  colSm={0}
                />
              </Col>
            </Row>
            <Row className="inputs-wrapper">
              <Col sm={4} className="custom-width-col">
                <h2>{t(`${tKey}AVAILABLE_VIEW`)}</h2>
                <Field
                  name="permissionViewId"
                  placeholder={t(`${tKey}AVAILABLE_VIEW`)}
                  valueKey="id"
                  labelKey="value"
                  component={InputSelect}
                  colSm={0}
                  options={permissionCombo}
                />
              </Col>
              <Col sm={4} className="custom-width-col">
                <h2>{t(`${tKey}AVAILABLE_EDIT`)}</h2>
                <Field
                  name="permissionEditId"
                  placeholder={t(`${tKey}AVAILABLE_EDIT`)}
                  valueKey="id"
                  labelKey="value"
                  component={InputSelect}
                  colSm={0}
                  options={permissionCombo}
                />
              </Col>
              <Col sm={4} className="custom-width-col">
                <h2>{t(`${tKey}AVAILABLE_VALIDATE`)}</h2>
                <Field
                  name="permissionId"
                  placeholder={t(`${tKey}AVAILABLE_VALIDATE`)}
                  valueKey="id"
                  labelKey="value"
                  component={InputSelect}
                  colSm={0}
                  options={permissionCombo}
                />
              </Col>
              <Col sm={4} className="custom-width-col">
                <h2>{t(`${tKey}AVAILABLE_REJECT`)}</h2>
                <Field
                  name="permissionRejectId"
                  placeholder={t(`${tKey}AVAILABLE_REJECT`)}
                  valueKey="id"
                  labelKey="value"
                  component={InputSelect}
                  colSm={0}
                  options={permissionCombo}
                />
              </Col>
              <Col sm={4} className="custom-width-col">
                <h2>{t(`${tKey}AVAILABLE_SIGN`)}</h2>
                <Field
                  name="permissionSignId"
                  placeholder={t(`${tKey}AVAILABLE_SIGN`)}
                  valueKey="id"
                  labelKey="value"
                  component={InputSelect}
                  colSm={0}
                  options={permissionCombo}
                />
              </Col>
              <Col sm={4} className="custom-width-col">
                <h2>{t(`${tKey}FUNCTION_EXEC_CREATE`)}</h2>
                <Field
                  name="functionToDoWhenCreate"
                  placeholder={t(`${tKey}FUNCTION_EXEC_CREATE`)}
                  component={InputText}
                  maxLength={50}
                />
              </Col>
              <Col sm={4} className="custom-width-col">
                <h2>{t(`${tKey}FUNCTION_EXEC_UPDATE`)}</h2>
                <Field
                  name="functionToDoWhenUpdate"
                  placeholder={t(`${tKey}FUNCTION_EXEC_UPDATE`)}
                  component={InputText}
                  maxLength={50}
                />
              </Col>
              <Col sm={4} className="custom-width-col">
                <h2>{t(`${tKey}FUNCTION_EXEC_DEPENDENT`)}</h2>
                <Field
                  name="dependantFunctionToDoWhenCreate"
                  placeholder={t(`${tKey}FUNCTION_EXEC_DEPENDENT`)}
                  component={InputText}
                  maxLength={50}
                />
              </Col>
              <Col sm={4} className="custom-width-col">
                <h2>{t(`${tKey}FUNCTION_EXEC_EXTRACT_METADATA`)}</h2>
                <Field
                  name="metadataTemplateCode"
                  placeholder={t(`${tKey}FUNCTION_EXEC_EXTRACT_METADATA`)}
                  component={InputText}
                  maxLength={50}
                />
              </Col>
            </Row>
            <div className="clearfix" />
            <Col sm={12}>
              <h3>{t(`${tKey}TEXT_LOCATION`)}</h3>
            </Col>
            <Row className="title-column">
              <Col sm={4}>
                <h2>{t('MASTERS.NAME')}</h2>
              </Col>
              <Col sm={6}>
                <h2>{t('MASTERS.DESCRIPTION')}</h2>
              </Col>
              <Col sm={2}>
                <h2>{t('MASTERS.LANGUAGE')}</h2>
              </Col>
            </Row>
            <FormGroup>
              {languageList && languageList?.map((val, idx) => {
                return (
                  <Row key={idx} className="inputs-wrapper">
                    <Col sm={4} className="custom-width-col">
                      <Field
                        name={`lang${val.languageId}.name`}
                        placeholder={t('MASTERS.NAME')}
                        component={TextInput}
                        maxLength={50}
                      />
                    </Col>
                    <Col sm={6} className="custom-width-col">
                      <Field
                        name={`lang${val.languageId}.description`}
                        placeholder={t('MASTERS.DESCRIPTION')}
                        component={TextInput}
                        maxLength={50}
                      />
                    </Col>
                    <Col sm={2} className="language-column">
                      <p>{`${val.description} (${val.name})`}</p>
                    </Col>
                  </Row>
                )
              })}
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <Col sm={12} className="btn-wrapper">
              <Button
                id="role-modal-submit-button"
                className="btn-standard"
                type="submit"
                disabled={this.props.pristine}>
                {t(`${tKey}SUBMIT_BUTTON`)}
              </Button>
            </Col>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default reduxForm({
  form: 'editDocumentTypeUse',
  validate: (values, props) => {
    let error = {}
    if (values) {
      const tKey = 'MASTERS.DOCUMENT_TYPE_USE_MASTERS.VALIDATION.'
      if (!values.code) {
        error.code = props.t(`${tKey}CODE`)
      }
      if (!values.externalCode) {
        error.externalCode = props.t(`${tKey}EXTERNAL_CODE`)
      }
      if (!values.documentTypeId) {
        error.documentTypeId = props.t(`${tKey}DOCUMENT_TYPE`)
      }
      props.languageList && props.languageList?.forEach((element) => {
        let valueLangName = values['lang' + element.languageId].name
        let valueLangDesc = values['lang' + element.languageId].description
        let errorLang = {
          name: null,
          description: null
        }
        if (!valueLangName) {
          errorLang.name = props.t(`${tKey}NAME`)
        } else if (valueLangName.length > 50) {
          errorLang.name = props.t(`${tKey}NAME_LENGTH`)
        }
        if (!valueLangDesc || valueLangDesc.length > 50) {
          errorLang.description = props.t(`${tKey}DESCRIPTION`)
        } else if (valueLangDesc.length > 50) {
          errorLang.description = props.t(`${tKey}DESCRIPTION_LENGTH`)
        }
        error['lang' + element.languageId] = errorLang
      })
    }
    return Object.keys(error).length > 0 ? error : {}
  }
})(DocumentTypeUseMasterModal)
