export const getProcessingAgencyFilter = state => state.processingAgency.filter

export const getProcessingAgencyAssignationFilter = state => state.processingAgencyAssignation.filter

export const getProcessingAgencyOrganizedUnitConfigFilter = state => state.processingAgencyOrganizedUnitConfig.filter

export const getProcessingAgencyRegistrationTypesFilter = state => state.processingAgency.processingAgencyIntegrationModal.registrationTypes.filter

export const getProcessingAgencyServiceDestinationsFilter = state => state.processingAgency.processingAgencyIntegrationModal.serviceDestinations.filter

export const getProcessingAgencyDocTypeUsesFilter = state => state.processingAgency.processingAgencyIntegrationModal.docTypeUses.filter
