export function showFilter (hasResults, hidefilters) {
  return !hasResults || !hidefilters
}

export function toggleFilterClass (hasResults, hideFilters) {
  if (!hasResults) {
    return 'iDocIcon-filter-hidden'
  }
  if (hideFilters) {
    return 'iDocIcon-filter-off'
  }
  return 'iDocIcon-filter'
}

export function hideFiltersBtn (hasResults) {
  if (!hasResults) {
    return 'hide'
  }
  return ''
}

export function getIconClass (field, filters) {
  if (filters.orderBy && filters.orderBy.includes(field)) {
    return filters.orderBy.includes('-') ? 'ico-sort-selected-asc' : 'ico-sort-selected-desc'
  } else {
    return 'ico-sort'
  }
}
