import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import DossierStageModal from '../../../components/masters/dossierStage/DossierStageMasterModal'
import { closeEditDossierStageModal, submitDossierStage } from '../../../actions/masters/masters'
import { fetchPermissionCombo } from '../../../actions/combos/combos'

export function mapStateToProps (state) {
  return {
    showDossierStageModal: state.dossierStage.dossierStageModal.showModal,
    languageList: state.dossierStage.dossierStageModal.languageList || [],
    dossierStageFields: state.dossierStage.dossierStageModal.dossierStageFields,
    dossierStageId: state.dossierStage.dossierStageModal.dossierStageId,
    combos: {
      permissionCombo: state.combos.permissionCombo
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      closeEditDossierStageModal,
      submitDossierStage,
      fetchPermissionCombo
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(DossierStageModal))
