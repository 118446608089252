import apiFetch from '../apiFetch'

export default function (token, file, dossierValues, fleetId, dossierSubType) {
  let queryParamsArray = []

  queryParamsArray.push(`dossierSubType=${dossierSubType}`)

  if (dossierValues) {
    for (let prop of Object.keys(dossierValues)) {
      if (dossierValues[prop] != null) { queryParamsArray.push(`${prop}=${dossierValues[prop]}`) }
    }
  }

  const queryParams = queryParamsArray.reduce(
    (result, query, idx) => result + (query ? (result ? `&${query}` : `?${query}`) : '')
    , ''
  )

  const formData = new FormData()
  formData.append(file.name, file)

  return apiFetch({ endPoint: `UnattendedProcess/${fleetId}/SetFleetChargeDossiersProcess${queryParams}`, method: 'POST', body: formData, token: token, fileBody: true })
}
