import React, { PureComponent } from 'react'
import { Modal, Col, Row, Button, FormGroup } from 'react-bootstrap'
import { reduxForm, Field } from 'redux-form'
import InputText from '../../commons/form/InputText'
import TextInput from '../../commons/TextInput'

class MaritalStatusMasterModal extends PureComponent {
  constructor () {
    super()
    this.state = {
      type: null,
      values: null,
      deleted: false
    }
    this.required = this.required.bind(this)
  }

  submitDocument (values) {
    const MaritalStatusLanguages = []
    Object.keys(values).map(item => {
      if (item.includes('lang')) {
        MaritalStatusLanguages.push(values[item])
      }
    })
    var maritalStatus = {}
    maritalStatus.maritalStatusId = this.props.maritalStatus?.maritalStatusId
    maritalStatus.code = values.code
    maritalStatus.MaritalStatusLanguages = MaritalStatusLanguages

    this.props.actions.submitMaritalStatus(maritalStatus)
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (!this.state.deleted && this.props.maritalStatus !== null && (this.props.maritalStatus?.value)?.length > this.state.values) {
      const count = (this.props.maritalStatus?.value)?.length
      this.setState({ values: count })
    }
    if (prevProps.showModal !== this.props.showModal && this.props.showModal === true) {
      this.props.initialize(this.props.maritalStatus)
      this.props.languageList?.map((language) => {
        this.props.change(`lang${language.languageId}.languageId`, language.languageId)
        const source = (this.props.maritalStatus?.maritalStatusId) ? this.props.maritalStatus.maritalStatusLanguages.find((sourceChannel) => sourceChannel.languageId === language.languageId) : ''
        this.props.change(`lang${language.languageId}.name`, source ? source.name : '')
        this.props.change(`lang${language.languageId}.description`, source ? source.description : '')
      })
    }
  }

  required (value) {
    if (value || value === 0) {
      return undefined
    } else {
      return this.props.t('MASTERS.MARITAL_STATUS_MASTERS.VALIDATION.VALUE_REQUIRED')
    }
  }

  render () {
    const {
      t, handleSubmit,
      showModal, languageList
    } = this.props
    const tKey = 'MASTERS.MARITAL_STATUS_MASTERS.MODAL.'
    return (
      <Modal className="languages-modal" show={showModal} onHide={this.props.actions.closeMaritalStatusMasterModal} backdrop={'static'}>
        <form autoComplete="off" onSubmit={handleSubmit(this.submitDocument.bind(this))}>
          <Modal.Header closeButton onHide={this.props.actions.closeMaritalStatusMasterModal}>
            <Modal.Title>{t(`${tKey}TITLE`)}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="inputs-wrapper flexRow">
              <Col sm={4} className="custom-width-col">
                <Field
                  t={t}
                  colSm={4}
                  id='code'
                  name='code'
                  placeholder={t(`${tKey}CODE`)}
                  controlLabel={t(`${tKey}CODE`)}
                  component={InputText}
                />
              </Col>
            </Row>
            <Row>
              <Row className="title-column">
                <Col sm={4}>
                  <h2>{t('MASTERS.NAME')}</h2>
                </Col>
                <Col sm={6}>
                  <h2>{t('MASTERS.DESCRIPTION')}</h2>
                </Col>
                <Col sm={2}>
                  <h2>{t('MASTERS.LANGUAGE')}</h2>
                </Col>
              </Row>
              <FormGroup>
                {languageList?.map((val, idx) => {
                  return (
                    <Row key={idx} className="inputs-wrapper">
                      <Col sm={4} className="custom-width-col">
                        <Field
                          name={`lang${val.languageId}.name`}
                          placeholder={t('MASTERS.NAME')}
                          component={TextInput}
                          maxLength={50}
                        />
                      </Col>
                      <Col sm={6} className="custom-width-col">
                        <Field
                          name={`lang${val.languageId}.description`}
                          placeholder={t('MASTERS.DESCRIPTION')}
                          component={TextInput}
                          maxLength={50}
                        />
                      </Col>
                      <Col sm={2} className="language-column">
                        <p>{`${val.description} (${val.name})`}</p>
                      </Col>
                    </Row>
                  )
                })}
              </FormGroup>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Col sm={6} className="btn-wrapper" smPush={6}>
              <Button type="submit" className="btn-standard" disabled={this.props.pristine}>
                {t('MASTERS.SAVE')}
              </Button>
            </Col>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default reduxForm({
  form: 'editMaritalStatus',
  validate: (values, props) => {
    const tKey = 'MASTERS.MARITAL_STATUS_MASTERS.MODAL.VALIDATION.'
    let error = {}

    if (!values.code) {
      error.code = props.t(`${tKey}CODE`)
    }

    props.languageList && props.languageList?.forEach((element) => {
      let valueLangName = values['lang' + element.languageId].name
      let valueLangDesc = values['lang' + element.languageId].description
      let errorLang = {
        name: null,
        description: null
      }
      if (!valueLangName) {
        errorLang.name = props.t(`${tKey}NAME`)
      } else if (valueLangName.length > 50) {
        errorLang.name = props.t(`${tKey}NAME_LENGTH`)
      }
      if (!valueLangDesc || valueLangDesc.length > 50) {
        errorLang.description = props.t(`${tKey}DESCRIPTION`)
      } else if (valueLangDesc.length > 50) {
        errorLang.description = props.t(`${tKey}DESCRIPTION_LENGTH`)
      }
      error['lang' + element.languageId] = errorLang
    })

    return Object.keys(error).length > 0 ? error : {}
  }
})(MaritalStatusMasterModal)
