import { put, call, takeEvery, select } from 'redux-saga/effects'
import { reset, change, initialize } from 'redux-form'
import { yesNoModal } from '../../modalsListeners/yesNoModal'
import { dossierTypes as dossierTypesConstant } from '../../../constants/dossier/common/dossierTypes'
import dossierActionsTypes from '../../../constants/actions/dossier/dossier'
import messageModalActions from '../../../actions/modals/messageModal'
import { updateDiscountSuccess } from '../../../actions/dossier/sales'
import { showLoader, hideLoader } from '../../../actions/common'
import { handleError } from '../../errors/errorManager'
import { initializeDossier } from '../commonDossierSagas'
import { v4 as uuidv1 } from 'uuid'
import { updateEconomicPlanFleet } from '../../../actions/management/management'

export function * createDiscount ({ newDiscount, resolve, reject, dossierType }) {
  try {
    let stateDossier, economicState, dossierForm, formName, updateFunction

    if (dossierType === dossierTypesConstant.fleetEconomicPlan) {
      stateDossier = yield select(state => state.management.economicPlanModal)
      economicState = JSON.parse(JSON.stringify(stateDossier.dossierEconomicComponent))
      dossierForm = yield select(state => state.form.economicPlanModal.values.dossierEconomicComponent)
      formName = 'economicPlanModal'
      updateFunction = updateEconomicPlanFleet
    } else {
      stateDossier = yield select(state => state.salesDossier)
      economicState = JSON.parse(JSON.stringify(stateDossier.dossierEconomicComponent))
      dossierForm = yield select(state => state.form.salesDossier.values.dossierEconomicComponent)
      formName = 'salesDossier'
      updateFunction = updateDiscountSuccess
    }

    let newEconomic = JSON.parse(JSON.stringify(dossierForm))
    newEconomic.discounts = economicState.discounts
    newDiscount.cost = 0
    newDiscount.dossierId = stateDossier.dossierId
    newDiscount.indexFront = uuidv1()
    newDiscount.amount = parseFloat(newDiscount.amount)
    newEconomic.discounts.push(newDiscount)

    if (dossierType === dossierTypesConstant.fleetEconomicPlan) {
      yield put(updateFunction(newEconomic))
    } else {
      yield put(updateFunction(newEconomic.discounts))
    }
    yield put(change(formName, 'dossierEconomicComponent', newEconomic))

    yield put(messageModalActions.openSuccessMessageModal('MESSAGES.POST_TABLE_ITEM_SUCCESS'))
    yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          const salesDossierState = yield select(state => state.salesDossier)
          yield call(initializeDossier, dossierTypesConstant.sales, salesDossierState)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('MESSAGES.POST_TABLE_ITEM_ERROR', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
      yield call(reject, customHandlerError)
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('dossierEconomicComponent'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchCreateDiscount () {
  yield takeEvery(dossierActionsTypes.CREATE_DISCOUNT, createDiscount)
}

export function * deleteDiscount ({ discount, dossierType }) {
  try {
    let stateDossier, economicState, dossierForm, formName, updateFunction

    if (dossierType === dossierTypesConstant.fleetEconomicPlan) {
      stateDossier = yield select(state => state.management.economicPlanModal)
      economicState = JSON.parse(JSON.stringify(stateDossier.dossierEconomicComponent))
      dossierForm = yield select(state => state.form.economicPlanModal.values.dossierEconomicComponent)
      formName = 'economicPlanModal'
      updateFunction = updateEconomicPlanFleet
    } else {
      stateDossier = yield select(state => state.salesDossier)
      economicState = JSON.parse(JSON.stringify(stateDossier.dossierEconomicComponent))
      dossierForm = yield select(state => state.form.salesDossier.values.dossierEconomicComponent)
      formName = 'salesDossier'
      updateFunction = updateDiscountSuccess
    }
    let newEconomic = JSON.parse(JSON.stringify(dossierForm))
    // changes(add & delete items changes state not form)
    newEconomic.discounts = economicState.discounts
    let index = discount.id ? newEconomic.discounts.findIndex(d => d.id === discount.id)
      : newEconomic.discounts.findIndex(d => d.indexFront === discount.indexFront)
    newEconomic.discounts.splice(index, 1)

    yield put(showLoader())

    if (dossierType === dossierTypesConstant.fleetEconomicPlan) {
      yield put(updateFunction(newEconomic))
    } else {
      yield put(updateFunction(newEconomic.discounts))
    }
    yield put(change(formName, 'dossierEconomicComponent', newEconomic))
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          const salesDossierState = yield select(state => state.salesDossier)
          yield call(initializeDossier, dossierTypesConstant.sales, salesDossierState)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('dossierEconomicComponent'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDeleteDiscount () {
  yield takeEvery(dossierActionsTypes.DELETE_DISCOUNT, deleteDiscount)
}

export function * updateDiscount ({ discount, resolve, reject, dossierType }) {
  try {
    let stateDossier, economicState, dossierForm, formName, updateFunction

    if (dossierType === dossierTypesConstant.fleetEconomicPlan) {
      stateDossier = yield select(state => state.management.economicPlanModal)
      economicState = JSON.parse(JSON.stringify(stateDossier.dossierEconomicComponent))
      dossierForm = yield select(state => state.form.economicPlanModal.values.dossierEconomicComponent)
      formName = 'economicPlanModal'
      updateFunction = updateEconomicPlanFleet
    } else {
      stateDossier = yield select(state => state.salesDossier)
      economicState = JSON.parse(JSON.stringify(stateDossier.dossierEconomicComponent))
      dossierForm = yield select(state => state.form.salesDossier.values.dossierEconomicComponent)
      formName = 'salesDossier'
      updateFunction = updateDiscountSuccess
    }

    let newEconomic = JSON.parse(JSON.stringify(dossierForm))
    // changes(add & delete items changes state not form)
    newEconomic.discounts = economicState.discounts
    let index = discount.id ? newEconomic.discounts.findIndex(d => d.id === discount.id)
      : newEconomic.discounts.findIndex(d => d.indexFront === discount.indexFront)
    yield put(showLoader())
    discount.cost = 0

    newEconomic.discounts[index] = discount
    if (dossierType === dossierTypesConstant.fleetEconomicPlan) {
      yield put(updateFunction(newEconomic))
    } else {
      yield put(updateFunction(newEconomic.discounts))
    }
    yield put(change(formName, 'dossierEconomicComponent', newEconomic))
    yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          const salesDossierState = yield select(state => state.salesDossier)
          yield call(initializeDossier, dossierTypesConstant.sales, salesDossierState)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
      yield call(reject, customHandlerError)
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('dossierEconomicComponent'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchUpdateDiscount () {
  yield takeEvery(dossierActionsTypes.UPDATE_DISCOUNT, updateDiscount)
}

export function * fetchDiscountModal ({ economicTotalOperation, ivaType, iemType, iemPercentage, operationDiscountWithTax, operationDiscountWithoutTax, pristine, dossierType }) {
  try {
    let formName, formDossier
    if (dossierType === dossierTypesConstant.fleetEconomicPlan) {
      formName = 'economicPlanModal'
    } else {
      formName = 'salesDossier'
    }

    if (economicTotalOperation) yield put(change(formName, 'discountModal.economicTotalOperation', economicTotalOperation))
    if (ivaType) yield put(change(formName, 'discountModal.ivaType', ivaType))
    if (iemType) yield put(change(formName, 'discountModal.iemType', iemType))
    if (iemPercentage) yield put(change(formName, 'discountModal.iemPercentage', iemPercentage))
    if (operationDiscountWithTax) yield put(change(formName, 'discountModal.operationDiscountWithTax', operationDiscountWithTax))
    if (operationDiscountWithoutTax) yield put(change(formName, 'discountModal.operationDiscountWithoutTax', operationDiscountWithoutTax))

    if (dossierType === dossierTypesConstant.fleetEconomicPlan) {
      formDossier = JSON.parse(JSON.stringify(yield select(state => state.form.economicPlanModal.values)))
    } else {
      formDossier = JSON.parse(JSON.stringify(yield select(state => state.form.salesDossier.values)))
    }

    yield put(initialize(formName, formDossier))
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.SALES_DOSSIER.ERROR_DISCOUNT_MODAL_DATA', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    yield call(handleError, { error, customHandler })
  }
}

export function * watchFetchDiscountModal () {
  yield takeEvery(dossierActionsTypes.FETCH_DISCOUNT_MODAL, fetchDiscountModal)
}
