
import apiFetch from '../apiFetch'

export default function (token, templateId) {
  return apiFetch({
    endPoint: `template/DownloadTemplate/?templateId=${templateId}`,
    method: 'GET',
    parseBlobAndAssign: true,
    body: null,
    token: token
  })
}
