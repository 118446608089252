import React, { PureComponent } from 'react'
import { Tabs, Tab } from 'react-bootstrap'
import WalletPage from '../../containers/report/wallet/WalletPage'
import BuyPage from '../../containers/report/buy/BuyPage'
import StockPage from '../../containers/report/stock/StockPage'
import SalePage from '../../containers/report/sale/SalePage'
import ReceiptPage from '../../containers/report/receipt/ReceiptPage'
import UsagePage from '../../containers/report/usage/UsagePage'
import { permissions } from '../../constants/backendIds'

class DefaultHomeReport extends PureComponent {
  constructor () {
    super()
    this.state = {
      activeTab: 6
    }
    this.onSelectTab = this.onSelectTab.bind(this)
  }

  onSelectTab (tabKey) {
    this.setState({ activeTab: tabKey })
  }

  render () {
    const { t, sectionTabsConfiguration } = this.props
    const tKey = 'REPORTS.'

    return (
      <div className="masters-container search-wrapper">
        <div className="search-header">
          <div className="title-user">
            <i className="ico-document-lines"/>
            <h2>{t(`${tKey}REPORTS`)}</h2>
          </div>
        </div>
        <Tabs defaultActiveKey={6} onSelect={this.onSelectTab.bind(this)} id="groups-tabs">
          {sectionTabsConfiguration.wallet_report.permission !== permissions.hidden && (
            <Tab eventKey={1} title={t(`${tKey}WALLET.WALLET_TITLE`)} />
          )}
          {sectionTabsConfiguration.buy_report.permission !== permissions.hidden && (
            <Tab eventKey={2} title={t(`${tKey}BUY.BUY_TITLE`)} />
          )}
          {sectionTabsConfiguration.stock_report.permission !== permissions.hidden && (
            <Tab eventKey={3} title={t(`${tKey}STOCK.STOCK_TITLE`)} />
          )}
          {sectionTabsConfiguration.sale_report.permission !== permissions.hidden && (
            <Tab eventKey={4} title={t(`${tKey}SALE.SALE_TITLE`)} />
          )}
          {sectionTabsConfiguration.receipt_report.permission !== permissions.hidden && (
            <Tab eventKey={5} title={t(`${tKey}RECEIPT.RECEIPT_TITLE`)} />
          )}
          {sectionTabsConfiguration.usage_report.permission !== permissions.hidden && (
            <Tab eventKey={6} title={t(`${tKey}USAGE.USAGE_TITLE`)} />
          )}
        </Tabs>
        {this.state.activeTab === 1 ? (
          <WalletPage/>
        ) : this.state.activeTab === 2 ? (
          <BuyPage/>
        ) : this.state.activeTab === 3 ? (
          <StockPage/>
        ) : this.state.activeTab === 4 ? (
          <SalePage/>
        ) : this.state.activeTab === 5 ? (
          <ReceiptPage/>
        ) : <UsagePage/>}
      </div>
    )
  }
}

export default DefaultHomeReport
