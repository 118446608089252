import React, { Component } from 'react'
import { Field, reduxForm, reset } from 'redux-form'
import { Form, Button, Tooltip, OverlayTrigger, Checkbox } from 'react-bootstrap'
import InputSelect from '../commons/form/InputSelect'
import InputDatePicker from '../commons/form/InputDatePicker'
import { validateDate } from '../../../util/validationFunctions'
import { translateDocumentStatus, documentStatusClass, documentStatus } from '../../../constants/dossier/common/documentStatus'
import InputCheckbox from '../commons/form/InputCheckBox'
import SimpleTablePage from '../../../containers/commons/general/SimpleTablePage'
import IDocCarPagination from '../../../components/commons/iDocCarPagination'
import { showFilter, hideFiltersBtn, toggleFilterClass, getIconClass } from '../../../_v2/util/management/ManagementUtils'
import MapSectionsSearch from '../../../_v2/util/map-sections-search'
import { getDateNowMaxDatePicker } from '../../../util/utils'
import EntityPopover from '../../../_v2/components/search/EntityPopover'
import VehiclePopover from '../../../_v2/components/search/VehiclePopover'
import DossierPopover from '../../../_v2/components/search/DossierPopover'
import { permissions } from '../../../constants/backendIds'
import MassiveDownloadCreateModal from '../../../components/search/documentalSearch/MassiveDownloadCreateModal'

class DocumentalSearchRestrictedForm extends Component {
  constructor (props) {
    super()
    this.state = {
      hideFilters: false,
      dossierIdToArray: []
    }
  }

  componentDidMount () {
    for (const dynamicFilter in this.props.dynamicFilters) {
      this.props.change(dynamicFilter, this.props.dynamicFilters[dynamicFilter])
    }
    // this.props.actions.resetFilters()
    this.props.initialize({})
    this.setState({ fields: {}, dossierIdToArray: [] })
    this.props.actions.fetchDocumentRestrictedCombo()
    this.props.actions.fetchSearchDossierSubType()
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (prevProps.isSearching !== this.props.isSearching) {
      this.setState({ dossierIdToArray: [], allSelected: false })
    }

    return null
  }

  componentDidUpdate () {
    // getSnapshotBeforeUpdate() should be used with componentDidUpdate()
  }

  submitDocumentalSearchForm (values) {
    if (!values) {
      return this.props.actions.openModal('mustFillFilters')
    }
    this.props.actions.findFilterDocuments({ ...values, ...this.props.filters })
    this.props.actions.setHideFilters(true)
  }

  dateValidation (value, allValues, props) {
    const result = validateDate(value, props)
    return result === Object(result) ? undefined : result
  }

  selectedFilters () {
    const filters = this.props.dynamicFilters
    const tKey = 'DOCUMENT_SEARCH.'
    let value = []
    let valueDocumentType
    let valuEntitySubTypeId
    let valueUO
    let valueDossierSubtype
    const t = this.props.t
    if (filters.documentTypeId) {
      const documentsTypes = this.props.combos.documentTypesRestrictedCombo.find(documentType => documentType.id === filters.documentTypeId)
      valueDocumentType = documentsTypes ? documentsTypes.value : null
    }
    if (filters.entitySubTypeId) {
      const entitySubTypeId = this.props.combos.entitySubTypeCombo.find(typeId => typeId.id === filters.entitySubTypeId)
      valuEntitySubTypeId = entitySubTypeId.value
    }
    if (filters.organizedUnitId) {
      const organizedUnitId = this.props.combos.lowestOrganizedUnitsCombo.find(uo => uo.id === filters.organizedUnitId)
      valueUO = organizedUnitId.value
    }
    if (filters.dossierSubTypeId) {
      const dossierSubTypeId = this.props.combos.searchDossierSubtypesCombo.find(subTypeId => subTypeId.id === filters.dossierSubTypeId)
      valueDossierSubtype = dossierSubTypeId.value
    }

    value = [
      filters.documentTypeId ? (
        <li key={2}>{t(`${tKey}DOCUMENT_TYPE`)}: <strong>{valueDocumentType}</strong>;</li>
      ) : null,
      filters.entitySubTypeId ? (
        <li key={18}>{t(`${tKey}ENTITY_SUB_TYPE_ID`)}: <strong>{valuEntitySubTypeId}</strong>;</li>
      ) : null,
      filters.organizedUnitId ? (
        <li key={8}>{t(`${tKey}UO`)}: <strong>{valueUO}</strong>;</li>
      ) : null,
      filters.dossierSubTypeId ? (
        <li key={9}>{t(`${tKey}DOSSIER_SUBTYPE`)}: <strong>{valueDossierSubtype}</strong>;</li>
      ) : null,
      (filters.creationDateFrom || filters.creationDateTo) ? (
        <li key={4}>
          {t(`${tKey}CREATION_DATE`)}: <strong>{
            filters.creationDateFrom && filters.creationDateTo ? (filters.creationDateFrom + ' - ' + filters.creationDateTo)
              : !filters.creationDateTo ? filters.creationDateFrom
                : filters.creationDateTo
          }
          </strong>;</li>
      ) : null,
      (filters.validationDateFrom || filters.validationDateTo) ? (
        <li key={5}>
          {t(`${tKey}VALIDATION_DATE`)}: <strong>{
            filters.validationDateFrom && filters.validationDateTo ? (filters.validationDateFrom + ' - ' + filters.validationDateTo)
              : !filters.validationDateTo ? filters.validationDateFrom
                : filters.validationDateTo
          }
          </strong>;</li>
      ) : null
    ]

    return value
  }

  toogleHideFilters () {
    this.props.actions.setHideFilters(!this.props.hideFilters)
  }

  toogleMaxiTable () {
    this.setState({ maxiTable: !this.state.maxiTable })
  }

  addDocumentIdToArray (documentId) {
    if (!this.state.dossierIdToArray.includes(documentId)) {
      this.setState({ dossierIdToArray: [...this.state.dossierIdToArray, documentId] })
      if (this.props.documentsIds.length === (this.state.dossierIdToArray.length + 1)) {
        this.setState({ allSelected: true })
      }
    } else {
      this.setState({ dossierIdToArray: this.state.dossierIdToArray.filter(d => d !== documentId) })
      if (this.state.dossierIdToArray) {
        this.setState({ allSelected: false })
      }
    }
  }

  handleClickReset (e) {
    if (e !== undefined) {
      e.preventDefault()
    }

    this.props.dispatch(reset('documentalSearchRestrictedForm'))
    this.state.dossierIdToArray = []
    this.setState({ onlyFleets: true, checkAll: false, dossierIdToArray: [], dossierData: [], fields: [] })
  }

  massiveDownloadDocuments () {
    if (this.state.dossierIdToArray && this.state.dossierIdToArray.length > 0) {
      const promise = new Promise((resolve, reject) => {
        this.props.actions.massiveDownloadDocumentList(this.state.dossierIdToArray, resolve, reject)
      })
      promise.then((result) => {
        if (result) {
          this.setState({ showRejectCommentModal: true })
        }
      })
    } else {
      this.props.actions.openModal('mustSelectAnyDocument')
    }
  }

  selectAllDocuments () {
    let documents = []
    if (this.state.allSelected) {
      this.setState({ dossierIdToArray: documents, allSelected: false })
    } else {
      this.setState({ dossierIdToArray: this.props.documentsIds, allSelected: true })
    }
  }

  closeMassiveDownloadModal () {
    this.setState({ showRejectCommentModal: false })
  }

  render () {
    const {
      t, handleSubmit, fields, fieldFill, pages, count, documents, isSearching, hideFilters, sectionConfigurationMassiveDownloads,
      filters: { page },
      actions: { fetchDocumentSearchList, createMassiveDownload },
      combos: {
        documentTypesRestrictedCombo,
        searchDossierSubtypesCombo,
        lowestOrganizedUnitsCombo
      }
      // tabPermission,
    } = this.props
    const tKey = 'DOCUMENT_SEARCH.'
    const className = 'filled'
    const classNameSelect = 'filledSelect'
    const tooltipValidate = (<Tooltip id="tooltipValidate">{t(`${tKey}TABLE.STATUS.VALIDATED`)}</Tooltip>)
    const tooltipPending = (<Tooltip id="tooltipPending">{t(`${tKey}TABLE.STATUS.PENDING`)}</Tooltip>)
    const tooltipRejected = (<Tooltip id="tooltipRejected">{t(`${tKey}TABLE.STATUS.REJECTED`)}</Tooltip>)
    const tooltipRemoved = (<Tooltip id="tooltipRemoved">{t(`${tKey}TABLE.STATUS.REMOVED`)}</Tooltip>)
    const navigationTooltip = (<Tooltip id="navigationTooltip">{t(`${tKey}TABLE.TOOLTIP.GO_TO`)}</Tooltip>)
    const secMapSearch = MapSectionsSearch.find(function (s) { return s.page === 'gdprDocumentalSearchPage' })
    const hasResults = documents && documents.length > 0
    const massiveDownloads = sectionConfigurationMassiveDownloads

    return (
      <div className="inner-results-panel">
        <MassiveDownloadCreateModal
          t={t}
          showModal={this.state.showRejectCommentModal}
          reasonCombo={this.props.combos.reasonCombo}
          documentIds={this.state.dossierIdToArray}
          actions={{
            createMassiveDownload,
            closeModal: () => this.closeMassiveDownloadModal()
          }}
        />
        <div className="section-results-header">
          <div className="section-header-title">
            <OverlayTrigger placement="right" overlay={<Tooltip id="editProductTooltip">{`${t((this.props.hideMenu ? 'GLOBAL.TABLES.SHOW_INNER_MENU' : 'GLOBAL.TABLES.HIDE_INNER_MENU'))}`}</Tooltip>}>
              <a onClick={() => this.props.toogleInnerMenu()}s className={this.props.hideMenu ? 'collapseBtn' : 'collapseBtn closeInnerMenu'}><i className={this.props.hideMenu ? 'iDocIcon-Open-inner-menu' : 'iDocIcon-Close-inner-menu'}></i></a>
            </OverlayTrigger>
            <h4>{t(`${secMapSearch.title}`)}
              <OverlayTrigger placement="left" overlay={<Tooltip id="editProductTooltip">{`${t((hideFilters ? 'GLOBAL.TABLES.SHOW_FILTERS' : 'GLOBAL.TABLES.HIDE_FILTERS'))}`}</Tooltip>}>
                <a onClick={() => this.toogleHideFilters()} className={'toogleShowFilters ' + hideFiltersBtn(hasResults) }><i className={toggleFilterClass(hasResults, hideFilters)}></i></a>
              </OverlayTrigger>
            </h4>
          </div>
          <Form autoComplete="off" className="filters" onSubmit={handleSubmit(this.submitDocumentalSearchForm.bind(this))}>
            <div className={'search-form ' + (showFilter(hasResults, hideFilters) ? 'hola' : 'hideFilters ') }>
              <Field
                component={InputSelect}
                colWidht={50} // 25, 33, 50, 75, 100 default=25
                id="documentTypeId"
                name="documentTypeId"
                controlLabel={t(`${tKey}DOCUMENT_TYPE`)}
                placeholder={t(`${tKey}DOCUMENT_TYPE`)}
                options={documentTypesRestrictedCombo}
                valueKey="id"
                labelKey="value"
                onInputChange={ (val) => { fieldFill({ documentTypeId: val }) } }
                customClass={fields.documentTypeId ? classNameSelect : ''}
              />
              <Field
                component={InputSelect}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                id="organizedUnitId"
                name="organizedUnitId"
                controlLabel={t(`${tKey}UO`)}
                placeholder={t(`${tKey}UO`)}
                options={lowestOrganizedUnitsCombo}
                valueKey="id"
                labelKey="value"
                onInputChange={ (val) => { fieldFill({ organizedUnitId: val }) } }
                customClass={fields.organizedUnitId ? classNameSelect : ''}
              />
              <Field
                component={InputSelect}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                id="dossierSubtype"
                name="dossierSubTypeId"
                controlLabel={t(`${tKey}DOSSIER_SUBTYPE`)}
                placeholder={t(`${tKey}DOSSIER_SUBTYPE`)}
                options={searchDossierSubtypesCombo}
                valueKey="id"
                labelKey="value"
                onInputChange={ (val) => { fieldFill({ dossierSubtype: val }) } }
                customClass={fields.dossierSubtype ? classNameSelect : ''}
              />
              <Field
                component={InputDatePicker}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                maxDate={getDateNowMaxDatePicker()}
                id="dossierCreationDateFrom"
                name="creationDateFrom"
                controlLabel={t(`${tKey}CREATION_DATE_DOCUMENT_FROM`)}
                placeholder={t(`${tKey}SINCE`)}
                validate={[this.dateValidation]}
                onInputChange={(val) => fieldFill({ dossierCreationDateFrom: val })}
                customClass={fields.dossierCreationDateFrom ? className : ''}
              />
              <Field
                component={InputDatePicker}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                maxDate={getDateNowMaxDatePicker()}
                id="dossierCreationDateTo"
                name="creationDateTo"
                controlLabel={t(`${tKey}CREATION_DATE_DOCUMENT_TO`)}
                placeholder={t(`${tKey}UNTIL`)}
                validate={[this.dateValidation]}
                onInputChange={(val) => fieldFill({ dossierCreationDateTo: val })}
                customClass={fields.dossierCreationDateTo ? className : ''}
              />
              <Field
                component={InputDatePicker}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                maxDate={getDateNowMaxDatePicker()}
                id="dossierValidationDateFrom"
                name="validationDateFrom"
                controlLabel={t(`${tKey}VALIDATION_DATE_DOCUMENT_FROM`)}
                placeholder={t(`${tKey}SINCE`)}
                validate={[this.dateValidation]}
                onInputChange={(val) => fieldFill({ dossierValidationDateFrom: val })}
                customClass={fields.dossierValidationDateFrom ? className : ''}
              />
              <Field
                component={InputDatePicker}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                maxDate={getDateNowMaxDatePicker()}
                id="dossierValidationDateTo"
                name="validationDateTo"
                controlLabel={t(`${tKey}VALIDATION_DATE_DOCUMENT_TO`)}
                placeholder={t(`${tKey}UNTIL`)}
                validate={[this.dateValidation]}
                onInputChange={(val) => fieldFill({ dossierValidationDateTo: val })}
                customClass={fields.dossierValidationDateTo ? className : ''}
              />
              <Field
                component={InputDatePicker}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                maxDate={getDateNowMaxDatePicker()}
                id="creationDossierDateFrom"
                name="creationDossierDateFrom"
                controlLabel={t(`${tKey}CREATION_DATE_DOSSIER_FROM`)}
                placeholder={t(`${tKey}SINCE`)}
                validate={[this.dateValidation]}
                onInputChange={(val) => fieldFill({ creationDossierDateFrom: val })}
                customClass={fields.creationDossierDateFrom ? className : ''}
              />
              <Field
                component={InputDatePicker}
                colWidht={25} // 25, 33, 50, 75, 100 default=25
                maxDate={getDateNowMaxDatePicker()}
                id="creationDossierDateTo"
                name="creationDossierDateTo"
                controlLabel={t(`${tKey}CREATION_DATE_DOSSIER_TO`)}
                placeholder={t(`${tKey}UNTIL`)}
                validate={[this.dateValidation]}
                onInputChange={(val) => fieldFill({ creationDossierDateTo: val })}
                customClass={fields.creationDossierDateTo ? className : ''}
              />
              <div className='search-form-actions flexInLine al-R'>
                <Button id="btn_search"
                  type="submit"
                  className="_Btn accept stk"
                  disabled={this.props.formValues === undefined}>
                  <i className="iDocIcon-search"/>
                  {t('SEARCH.ACTIONS.SEARCH_BUTTON')}
                </Button>
                <Button
                  id="btn_reset"
                  onClick={(e) => this.handleClickReset(e)}
                  className={'_Btn' + (this.props.pristine ? ' accept' : ' accept')}
                  disabled={this.props.pristine}>
                  <i className="iDocIcon-clear-decline"/>
                  {t('SEARCH.ACTIONS.RESET_BUTTON')}
                </Button>
              </div>
            </div>
          </Form>
        </div>
        <div className="section-results-body">
          <div className={'searchResoultsContainer' + (this.state.maxiTable ? ' maxiTable' : '')}>
            {isSearching && (
              <div className="table-wrapper" key="table-wrapper">
                <ul className="list-inline pull-left">
                  {this.selectedFilters().length === 0 ? <li>{t(`${tKey}NO_SELECTED_FILTERS`)}</li> : (this.selectedFilters())}
                </ul>
                { hasResults &&
                  <div className='flexInLine al-R'>
                    <OverlayTrigger placement="left" overlay={<Tooltip id="editProductTooltip">{`${t((this.state.maxiTable ? 'GLOBAL.TABLES.MINIMIZE_TABLE' : 'GLOBAL.TABLES.MAXIMIZE_TABLE'))}`}</Tooltip>}>
                      <a onClick={() => this.toogleMaxiTable()} className='_Btn accept stk o-icn maximizeBtn'><i className={(this.state.maxiTable ? 'iDocIcon-Minimize' : 'iDocIcon-Maximize')}></i></a>
                    </OverlayTrigger>

                    { massiveDownloads && massiveDownloads.permission > permissions.hidden && documents && documents.length > 0 &&
                                          <OverlayTrigger placement="left" overlay={<Tooltip id="editProductTooltip">{t(`${tKey}ACTIONS.DOWNLOAD_SELECTED`)}</Tooltip>}>
                                            <a onClick={() => this.massiveDownloadDocuments()} className='_Btn new o-icn exportToExcel'><i className="iDocIcon-download"></i></a>
                                          </OverlayTrigger>
                    }
                    { massiveDownloads && massiveDownloads.permission > permissions.hidden && documents && documents.length > 0 &&
                                          <Checkbox
                                            className="pull-right"
                                            onChange={() => this.selectAllDocuments()}
                                            checked={this.state.allSelected}>
                                            {t(`${tKey}ACTIONS.SELECT_ALL`)}
                                          </Checkbox>
                    }
                  </div>
                }
                {/* table */}
                { hasResults &&
                  <div className="table-responsive">
                    <SimpleTablePage
                      columns={[
                        <th key={0} data-field="documentType" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.DOCUMENT_TYPE`)} <i className={getIconClass('documentType', this.props.filters)}/></th>,
                        <th key={1} data-field="dossier" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.DOSSIER`)} <i className={getIconClass('dossier', this.props.filters)}/></th>,
                        <th key={2} data-field="entity" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.ENTITY`)} <i className={getIconClass('entity', this.props.filters)}/></th>,
                        <th key={3} data-field="vehicle" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.VEHICLE`)} <i className={getIconClass('vehicle', this.props.filters)}/></th>,
                        <th key={4} data-field="status" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.STATE`)} <i className={getIconClass('status', this.props.filters)}/></th>,
                        <th key={5} data-field="creationDate" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.CREATION_DATE`)} <i className={getIconClass('creationDate', this.props.filters)}/></th>,
                        <th key={6} data-field="validationDate" onClick={(event) => this.onOrderChange(event)}>{t(`${tKey}TABLE.DOSSIER.VALIDATION_DATE`)} <i className={getIconClass('validationDate', this.props.filters)}/></th>,
                        <th key={8}></th>,
                        <th key={9}></th>
                      ]}
                      rows={documents.map((row, idx) => (
                        <tr key={idx}>
                          <td>{row.documentType}</td>
                          <td className="see-more">
                            <DossierPopover
                              t={t}
                              dossier={row.dossier}
                              half={(documents.length || 1) / (idx + 1)}
                              idx={idx}
                              id={'popover-dossier' + idx}
                              urgencyType={row.urgencyType}
                            />
                          </td>
                          <td className="see-more">
                            <EntityPopover
                              t={t}
                              entity={row.entity}
                              half={(documents.length || 1) / (idx + 1)}
                              idx={idx}
                              id={'popover-entity' + idx}
                            />
                          </td>
                          <td className="see-more">
                            <VehiclePopover
                              t={t}
                              vehicle={row.vehicle}
                              half={(documents.length || 1) / (idx + 1)}
                              idx={idx}
                              id={'popover-vehicle' + idx}
                            />
                          </td>
                          <td style={{ paddingLeft: '20px' }}>
                            <span>
                              <OverlayTrigger placement="left" overlay={row.status === documentStatus.VALIDATED ? (tooltipValidate) : row.status === documentStatus.PENDING ? (tooltipPending) : row.status === documentStatus.REJECTED ? (tooltipRejected) : row.status === documentStatus.REMOVED ? (tooltipRemoved) : null}>
                                <i id="documentStatus" className={'ico-' + documentStatusClass[translateDocumentStatus[row.status]]}/>
                              </OverlayTrigger>
                            </span>
                          </td>
                          <td>{row.creationDate}</td>
                          <td>{row.validationDate}</td>
                          <td key={idx} className="navigation">
                            {row.documentId && (
                              <OverlayTrigger placement="left" overlay={navigationTooltip}>
                                <a className="_Btn accept stk o-icn noLine"
                                  onClick={() => row.isHistorical === false ? (
                                    this.props.actions.openDocumentEditionModal(row.documentId,
                                      row.documentTypeId,
                                      row.documentParentTypeId,
                                      row.dossierId,
                                      row.entityId,
                                      row.vehicleId,
                                      row.organizedUnitId,
                                      null,
                                      () => fetchDocumentSearchList({
                                        items: documents, pages, count, ...this.props.dynamicFilters, page
                                      }),
                                      true,
                                      '',
                                      '', null, null, null, null,
                                      false)
                                  ) : (
                                    this.props.actions.openDocumentEditionModalHistorical(row.documentId, row.documentTypeId, row.documentParentTypeId, row.dossierId, row.entityId, row.vehicleId)
                                  )
                                  }>
                                  <i class="iDocIcon-open-in-new-black"></i>
                                </a>
                              </OverlayTrigger>
                            )}
                          </td>
                          <td>
                            {
                              <Field
                                colSm={0}
                                component={InputCheckbox}
                                name={'downloadCheck' + row.dossierId}
                                inline
                                customClass="checkbox-inline-search"
                                onChange={() => this.addDocumentIdToArray(row.documentId)}
                                checked= {this.state.dossierIdToArray.includes(row.documentId)}
                              />
                            }
                          </td>
                        </tr>
                      ))}
                    />
                  </div>
                }
                <div className="search-footer">
                  {hasResults ? (
                    <IDocCarPagination
                      id="btn_pag_sales"
                      page={page}
                      pagesCount={pages}
                      totalRows= {count}
                      onSelectAction={(page) => this.props.fetchDocumentSearchList({ items: documents, pages, count, ...this.props.dynamicFilters, page })}/>
                  ) : <p className='no_results'>{t('GLOBAL.TABLES.NO_RESULTS')}</p>
                  }
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

const validate = (values, props) => {
  const error = {}
  return error
}

export default reduxForm({
  form: 'documentalSearchRestrictedForm',
  destroyOnUnmount: false,
  validate
})(DocumentalSearchRestrictedForm)
