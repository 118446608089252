import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import WindowDimensions from '../../components/commons/WindowDimensions'
import windowDimensionsActions from '../../actions/commons/windowDimensions'

export function mapStateToProps (state) {
  return {
    ...state.windowDimensions
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      ...windowDimensionsActions
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(WindowDimensions))
