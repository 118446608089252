import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import {
  fetchUserList, openEditUserModal, openAddUserModal, deleteUser, recoverUser, uploadUserMassDocument
} from '../../../actions/admin/userAdmin'
import { openModalMass, fetchTemplateFile } from '../../../actions/masters/masters'
import { fetchRoleCombo, fetchLanguagesCombo, fetchEventsCombo, fetchLowestOrganizedUnits } from '../../../actions/combos/combos'
import UserAdmin from '../../../components/admin/user/UserAdmin'
import {
  fetchEventSubscriptionsFromAdmin, saveEventSubscriptionFromAdmin, removeEventSubscriptionFromAdmin,
  enableEventSubscriptionFromAdmin, disableEventSubscriptionFromAdmin
} from '../../../actions/admin/subscriptionsAdmin'

export function mapStateToProps (state) {
  return {
    ...state.userAdmin,
    enableSubscriptions: state.auth.enableSubscriptions,
    defaultLanguageId: state.auth.languageId,
    combos: {
      UOTreeCombo: state.combos.UOTreeCombo,
      roleCombo: state.combos.roleCombo,
      lowestOrganizedUnitsCombo: state.combos.lowestOrganizedUnitsCombo,
      events: state.combos.events,
      languages: state.combos.languagesCombo
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchUserList,
      openEditUserModal,
      openAddUserModal,
      deleteUser,
      recoverUser,
      fetchRoleCombo,
      openModalMass,
      fetchTemplateFile,
      uploadUserMassDocument,
      fetchLanguagesCombo,
      fetchEventsCombo,
      fetchLowestOrganizedUnits,
      fetchEventSubscriptionsFromAdmin,
      saveEventSubscriptionFromAdmin,
      removeEventSubscriptionFromAdmin,
      enableEventSubscriptionFromAdmin,
      disableEventSubscriptionFromAdmin
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(UserAdmin))
