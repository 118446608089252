import React, { PureComponent } from 'react'
import { reduxForm } from 'redux-form'
import { permissions } from '../../../constants/backendIds'
import MapSections from '../../../_v2/util/map-sections_v2'
import MapSectionsReport from '../../../_v2/util/map-sections-report'
import { sectionPermissions } from '../../../constants/dossier/common/buttonsHeader'
import { getSectionPermissions } from '../../../util/permisionFunctions'
import SimpleInputText from '../../../components/commons/simpleForm/SimpleInputText'
import PowerBiHomeReportPage from '../../../_v2/containers/report/PowerBiHomeReportPage'
import SearchByFleetReportPage from '../../../_v2/containers/report/SearchByFleetReportPage'
import SearchCommercialReportPage from '../../../_v2/containers/report/SearchCommercialReportPage'

class Report extends PureComponent {
  constructor () {
    super()
    this.state = {
      secMapReport: MapSectionsReport,
      sectionFilter: '',
      hideMenu: false,
      dossierIdToArray: [],
      fields: {}
    }
  }

  componentDidMount () {
    this.initializePermisions(this.props)

    this.props.actions.fetchPowerbiConfig()
  }

  initializePermisions (props) {
    const section = props.sectionsGeneral.filter((tabConf) => tabConf.code === sectionPermissions.reports)

    if (section.length > 0 && section[0] != null) {
      const newState = getSectionPermissions(section[0])
      let visibleSectionConfiguration = []
      this.state.secMapReport.forEach(function (item) {
        var permissionCode = newState[item.code]
        if (permissionCode !== undefined) {
          if (permissionCode.permission !== null && permissionCode.permission !== permissions.hidden) {
            // Add to visible sections
            item.active = false
            visibleSectionConfiguration.push(item)
          }
        }
      })
      visibleSectionConfiguration[0].active = true
      this.setState({ secMapReport: visibleSectionConfiguration })
    }
  }

  componentDidUpdate () {

  }

  filterSections (t) {
    let nameFilter = this.state.sectionFilter

    if (!this.state.sectionFilter) {
      return this.state.secMapReport
    }
    let filtered = this.state.secMapReport.filter(function (item) {
      return t(item.title).toUpperCase().includes(nameFilter.toUpperCase())
    })
    return filtered
  }

  selectSection (item) {
    let visibleSectionConfiguration = []
    this.state.secMapReport.forEach(element => {
      element.active = element.code === item.code
      visibleSectionConfiguration.push(element)
    })
    this.setState({ secMapReport: visibleSectionConfiguration })
    this.props.actions.resetFilters()
  }

  toogleInnerMenu () {
    this.setState({ hideMenu: !this.state.hideMenu })
  }

  fieldFill (field) {
    this.setState({ fields: { ...this.state.fields, ...field } })
  }

  render () {
    const {
      t, powerbiConfig,
      pages, count, hasSearch, reportData, fleetReportData, usageReportData,
      filters: { page }, actions: { powerBiRefreshSession } // props de la sección de buscadores
    } = this.props
    const secMap = MapSections.reports
    const availableSections = this.filterSections(t)
    const selectedSection = availableSections.filter(x => x.active === true)[0]
    const className = 'filled'
    const classNameSelect = 'filledSelect'

    return <div className="wrapper _nf">
      <div className='wrapper-header'>
        <div className="title-page">
          <i className={secMap.icon + ' icon-page'} />
          <h2>{t(`${secMap.title}`)}</h2>
        </div>
      </div>
      <div className='wrapper-body'>
        <div className={this.state.hideMenu ? 'inner-menu-content innerMenuHide' : 'inner-menu-content'}>
          <div className='inner-menu-helpers'>
            <SimpleInputText
              componentClass = 'cajadebusqueda'
              placeholder = {t('SECTIONS.FILTER')}
              value={this.state.sectionFilter}
              onInputChange={sectionFilter => this.setState({ sectionFilter })}
            />
          </div>
          <ul className='inner-items'>
            {
              availableSections && availableSections.map((item, idx) => {
                return (
                  <li className={ item.active === true ? 'active' : ''}><p onClick={() => this.selectSection(item)}>{t(`${item.title}`)}</p></li>
                )
              })
            }
          </ul>
        </div>
        <div className='inner-data-content'>
          { selectedSection !== undefined && selectedSection.page === 'powerBIPage' && powerbiConfig.embedToken &&
            <PowerBiHomeReportPage
              t={t}
              powerbiConfig={powerbiConfig}
              powerBiRefreshSession={powerBiRefreshSession}
              hideMenu={this.state.hideMenu}
              toogleInnerMenu={() => this.toogleInnerMenu()}
            />}
          { selectedSection !== undefined && selectedSection.page === 'commercialReportPage' &&
            <SearchCommercialReportPage
              t={t}
              change={this.props.change}
              fields={this.state.fields}
              organizedUnitId={''}
              fieldFill={(val) => this.fieldFill(val)}
              className={className}
              classNameSelect={classNameSelect}
              hasSearch={hasSearch}
              reportData={reportData}
              onClickResetSearch={(value) => this.onClickResetSearch(value)}
              onOrderChange={(value) => this.onOrderChange(value)}
              onPageChange={(value) => this.onPageChange(value)}
              page={page}
              pages={pages}
              count={count}
              hideMenu={this.state.hideMenu}
              toogleInnerMenu={() => this.toogleInnerMenu()}
            />}
          { selectedSection !== undefined && selectedSection.page === 'fleetReportPage' &&
            <SearchByFleetReportPage
              t={t}
              change={this.props.change}
              fields={this.state.fields}
              fieldFill={(val) => this.fieldFill(val)}
              className={className}
              classNameSelect={classNameSelect}
              formValues={this.props.formValues && this.props.formValues.entity ? this.props.formValues.entity : {}}
              hasSearch={hasSearch}
              reportData={fleetReportData}
              onClickResetSearch={(value) => this.onClickResetSearch(value)}
              onOrderChange={(value) => this.onOrderChange(value)}
              onPageChange={(value) => this.onPageChange(value)}
              page={page}
              pages={pages}
              count={count}
              hideMenu={this.state.hideMenu}
              toogleInnerMenu={() => this.toogleInnerMenu()}
            />}
          { selectedSection !== undefined && selectedSection.page === 'usageReportPage' &&
              <SearchUsageReportPage
                t={t}
                change={this.props.change}
                fields={this.state.fields}
                fieldFill={(val) => this.fieldFill(val)}
                className={className}
                classNameSelect={classNameSelect}
                formValues={this.props.formValues && this.props.formValues.entity ? this.props.formValues.entity : {}}
                hasSearch={hasSearch}
                reportData={usageReportData}
                onClickResetSearch={(value) => this.onClickResetSearch(value)}
                onOrderChange={(value) => this.onOrderChange(value)}
                onPageChange={(value) => this.onPageChange(value)}
                page={page}
                pages={pages}
                count={count}
                hideMenu={this.state.hideMenu}
                toogleInnerMenu={() => this.toogleInnerMenu()}
              />}
        </div>
      </div>
    </div>
  }
}

export default reduxForm({
  form: 'report'
})(Report)
