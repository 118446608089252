import unitActionTypes from '../../constants/actions/unit/unit'

export function initialState () {
  return {
    unitList: [],
    unitsCount: null,
    pagesCount: null,
    filter: {
      company: null,
      unitName: '',
      page: 1,
      pageSize: 2,
      orderBy: 'name'
    },
    unitModalIsOpen: false,
    isEditModal: null
  }
}

function updateFilter (state, { filter }) {
  return {
    ...state,
    filter
  }
}

function fetchUnitSuccess (state, { data }) {
  return {
    ...state,
    unitList: data.unitList,
    unitsCount: data.unitsCount,
    pagesCount: data.pagesCount
  }
}

function openUnitModal (state, { unit }) {
  return {
    ...state,
    unitModalIsOpen: true,
    isEditModal: unit
  }
}

function closeUnitModal (state) {
  return {
    ...state,
    unitModalIsOpen: false,
    isEditModal: null
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case unitActionTypes.FETCH_UNIT_SUCCESS:
      return fetchUnitSuccess(state, action)
    case unitActionTypes.UPDATE_FILTER:
      return updateFilter(state, action)
    case unitActionTypes.OPEN_UNIT_MODAL:
      return openUnitModal(state, action)
    case unitActionTypes.CLOSE_UNIT_MODAL:
      return closeUnitModal(state, action)
    default:
      return state
  }
}
