import mastersActionTypes from '../../../constants/actions/masters/masters'

export function fetchSignTypeList (filter) {
  return {
    type: mastersActionTypes.FETCH_SIGN_TYPE_LIST,
    filter
  }
}

export function fetchSignTypeListSuccess (signTypeList, filter) {
  return {
    type: mastersActionTypes.FETCH_SIGN_TYPE_SUCCESS_LIST,
    signTypeList,
    filter
  }
}

export function openSignTypeModal (signType) {
  return {
    type: mastersActionTypes.OPEN_SIGN_TYPE_MODAL,
    signType
  }
}

export function openSignTypeModalSuccess (signType) {
  return {
    type: mastersActionTypes.OPEN_SIGN_TYPE_MODAL_SUCCESS,
    signType
  }
}

export function closeSignTypeModal () {
  return {
    type: mastersActionTypes.CLOSE_SIGN_TYPE_MODAL
  }
}

export function submitSignType (signType) {
  return {
    type: mastersActionTypes.SUBMIT_SIGN_TYPE,
    signType
  }
}

export function submitSignTypeSuccess () {
  return {
    type: mastersActionTypes.SUBMIT_SIGN_TYPE_SUCCESS
  }
}
