import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import DocumentaryManagement from '../../../../_v2/components/dossiers/common/documentaryManagement/DocumentaryManagement'
import {
  closeDocumentModalCallbacks, cancelDocument, refreshDocumentManagementModal, addDependant, printCheckList, setCurrentTabCounterValues,
  saveCheckListReciboPagaYSenal, signDocument, openModalVehicleNecessary, updateFormError
} from '../../../../actions/dossier/common/documentManagementModal'
import { openDocumentCreationModal, openDocumentEditionModal, cancelSign, requestSign, signTypes } from '../../../../actions/dossier/common/documentEditionModal'
import { openDocumentCreationModal as openDocumentCreationModalHistorical, openDocumentEditionModal as openDocumentEditionModalHistorical } from '../../../../actions/dossier/common/documentHistoricalEditionModal'
import { openReservationContractModal } from '../../../../actions/dossier/common/reservationContractModal'
import { fetchInsuranceCompanyCombo } from '../../../../actions/combos/combos'

import { generateStructure, getLoadResult } from '../../../../actions/dossier/common/hotFolder'
import { translate } from 'react-polyglot'
import { openModal, openDocWarning } from '../../../../actions/common'
import { openSuccessMessageModal, openErrorMessageModal } from '../../../../actions/modals/messageModal'
import { getDocumentLandingRequest, sendLandingCancelRequest } from '../../../../actions/landingRequest/landingRequestActions'
import { getFormSyncErrors } from 'redux-form'
import { getDocumentsMassiveDownload } from '../../../../actions/dossier/common/documentMassiveDownloadActions'

export function mapStateToProps (state, ownProps) {
  return {
    ...state.documentaryManagementModal,
    showDocumentEditionModal: state.documentEditionModal.showDocumentEditionModal,
    dossierType: ownProps.dossierType,
    formErrors: getFormSyncErrors('salesDossier')(state)
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      closeDocumentModalCallbacks,
      openDocumentCreationModal,
      openDocumentEditionModal,
      cancelDocument,
      addDependant,
      refreshDocumentManagementModal,
      saveCheckListReciboPagaYSenal,
      printCheckList,
      setCurrentTabCounterValues,
      openModal,
      generateStructure,
      getLoadResult,
      signDocument,
      openModalVehicleNecessary,
      openDocumentCreationModalHistorical,
      openDocumentEditionModalHistorical,
      cancelSign,
      requestSign,
      signTypes,
      openSuccessMessageModal,
      openErrorMessageModal,
      openDocWarning,
      getDocumentLandingRequest,
      sendLandingCancelRequest,
      updateFormError,
      getDocumentsMassiveDownload,
      openReservationContractModal,
      fetchInsuranceCompanyCombo
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(DocumentaryManagement))
