import apiFetch from '../apiFetch'

export default function (token, {
  page, pageSize, orderBy, dossierSubtype, dossierNumber, organizedUnit, stage, creationDateFrom,
  creationDateTo, name, dniCif, email, chassis, licensePlate, model, dealerNumber, orderDateFrom,
  orderDateTo, salesman, salesmanManager, makerNumber, orderMakerNumber, availabilityDateFrom, availabilityDateTo, vehicleLicensePlateDateFrom,
  vehicleLicensePlateDateTo, load, rentalOrderNumber, clientNumber, bank, userId, creatorId, saleTypeId, purchaseTypeId, operationTypeId, stockStatusId
  , isDenied, proformInvoiceEmited, externalOrderId, paymentMethod, operationTypeDMSId
}) {
  const queryParamsArray = [
    page ? `page=${page}` : null,
    pageSize ? `pageSize=${pageSize}` : null,
    orderBy ? `orderBy=${orderBy}` : null,
    dossierSubtype ? `dossierSubtypeId=${dossierSubtype}` : null,
    dossierNumber ? `number=${dossierNumber}` : null,
    organizedUnit ? `organizedUnitId=${organizedUnit}` : null,
    stage ? `dossierStageId=${stage}` : null,
    creationDateFrom ? `creationDateFrom=${creationDateFrom}` : null,
    creationDateTo ? `creationDateTo=${creationDateTo}` : null,
    orderDateFrom ? `orderDateFrom=${orderDateFrom}` : null,
    orderDateTo ? `orderDateTo=${orderDateTo}` : null,
    salesman ? `salesmanId=${salesman}` : null,
    salesmanManager ? `salesmanIdManager=${salesmanManager}` : null,
    name ? `entityName=${name}` : null,
    dniCif ? `entityCifNif=${dniCif}` : null,
    email ? `entityMail=${email}` : null,
    chassis ? `vehicleVinChasis=${chassis}` : null,
    licensePlate ? `vehicleLicensePlate=${licensePlate}` : null,
    model ? `vehicleModel=${model}` : null,
    dealerNumber ? `dealerNumber=${dealerNumber}` : null,
    rentalOrderNumber ? `rentalOrderNumber=${rentalOrderNumber}` : null,
    orderMakerNumber ? `orderMakerNumber=${orderMakerNumber}` : null,
    makerNumber ? `makerNumber=${makerNumber}` : null,
    availabilityDateFrom ? `availabilityDateFrom=${availabilityDateFrom}` : null,
    availabilityDateTo ? `availabilityDateTo=${availabilityDateTo}` : null,
    vehicleLicensePlateDateFrom ? `vehicleLicensePlateDateFrom=${vehicleLicensePlateDateFrom}` : null,
    vehicleLicensePlateDateTo ? `vehicleLicensePlateDateTo=${vehicleLicensePlateDateTo}` : null,
    load ? `load=${load}` : null,
    clientNumber ? `clientNumber=${clientNumber}` : null,
    bank ? `bankId=${bank}` : null,
    userId ? `userId=${userId}` : null,
    creatorId ? `creatorId=${creatorId}` : null,
    saleTypeId ? `saleTypeId=${saleTypeId}` : null,
    purchaseTypeId ? `purchaseTypeId=${purchaseTypeId}` : null,
    operationTypeId ? `operationTypeId=${operationTypeId}` : null,
    stockStatusId ? `stockStatusId=${stockStatusId}` : null,
    isDenied === true || isDenied === false ? `isDenied=${isDenied}` : null,
    proformInvoiceEmited === true || proformInvoiceEmited === false ? `proformInvoiceEmited=${proformInvoiceEmited}` : null,
    externalOrderId ? `externalOrderId=${externalOrderId}` : null,
    paymentMethod ? `paymentMethodId=${paymentMethod}` : null,
    operationTypeDMSId ? `operationTypeDMSId=${operationTypeDMSId.join(',')}` : null
  ]
  const queryParams = queryParamsArray.reduce(
    (result, query, idx) => result + (query ? (result ? `&${query}` : `?${query}`) : '')
    , ''
  )
  return apiFetch({
    endPoint: `dossierFinder/dossiers${queryParams}`,
    method: 'GET',
    body: null,
    token: token
  })
}
