import apiFetch from '../apiFetch'

export default function (token, filter, processAgencyCommunicationServiceId) {
  return apiFetch({
    endPoint: `ProcessingAgencyOrganizedUnitConfigs/${filter.processAgencyCommunicationServiceId ||
      processAgencyCommunicationServiceId}/List`,
    method: 'POST',
    body: filter,
    token: token
  })
}
