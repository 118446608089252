import React, { PureComponent } from 'react'
import { reduxForm, Field } from 'redux-form'
import { Row, Col, Modal, Button, FormGroup } from 'react-bootstrap'
import TextInput from '../../commons/TextInput'
import InputNumber from '../../commons/form/InputNumber'
import InputCheckbox from '../../commons/form/InputCheckBox'
import InputSelect from '../../commons/form/InputSelect'

const DEFAULT_MAX_PERCENTAGE = 100

class DossierStageModal extends PureComponent {
  constructor () {
    super()
    this.state = {
      warnings: {
        visibleToProcessingAgency: false,
        maxDays: false,
        percentToWarn: false,
        permissionResponsableId: false,
        permissionToNotificationId: false
      }
    }
  }

  submitDossierStage (values) {
    const dossierStageUpdatedLanguages = []
    Object.keys(values).map(item => {
      if (/lang[0-9]/.test(item)) {
        dossierStageUpdatedLanguages.push(values[item])
      }
    })

    var dossierStageFieldsToSubmit = {}
    dossierStageFieldsToSubmit.dossierStageId = this.props.dossierStageId
    dossierStageFieldsToSubmit.dossierStageLanguages = dossierStageUpdatedLanguages
    dossierStageFieldsToSubmit.maxDays = values.maxDays
    dossierStageFieldsToSubmit.percentToWarn = values.percentToWarn
    dossierStageFieldsToSubmit.permissionResponsableId = values.permissionResponsableId
    dossierStageFieldsToSubmit.permissionToNotificationId = values.permissionToNotificationId
    dossierStageFieldsToSubmit.visibleToProcessingAgency = values.visibleToProcessingAgency
    dossierStageFieldsToSubmit.dossierSubTypeId = this.props.dossierStageFields?.dossierSubTypeId
    dossierStageFieldsToSubmit.warnings = this.state.warnings

    this.props.actions.submitDossierStage(dossierStageFieldsToSubmit, this.props.dossierStageId)
  }

  componentDidUpdate (prevProps) {
    if (prevProps.showDossierStageModal !== this.props.showDossierStageModal &&
      this.props.showDossierStageModal === true) {
      this.props.initialize({ ...this.props.dossierStageFields })
      this.setState({
        warnings: {
          visibleToProcessingAgency: false,
          maxDays: false,
          percentToWarn: false,
          permissionResponsableId: false,
          permissionToNotificationId: false
        }
      })
    }

    if (prevProps.dossierStageFields?.dossierStageLanguages !== this.props.dossierStageFields?.dossierStageLanguages) {
      this.props.languageList.map((language) => {
        this.props.change(`lang${language.languageId}.languageId`, language.languageId)
        const nextTranslation = this.props.dossierStageFields?.dossierStageLanguages
          .find((faqSection) => faqSection.languageId === language.languageId)
        if (nextTranslation && nextTranslation !== undefined) {
          this.props.change(`lang${language.languageId}.name`, nextTranslation.name)
          this.props.change(`lang${language.languageId}.description`, nextTranslation.description)
        }
      })
    }
  }

  componentDidMount () {
    this.props.actions.fetchPermissionCombo()
  }

  onChange (value, propName) {
    let valueToCompare = !isNaN(value) ? `${value}` : value
    let raiseWarning = this.props.dossierStageFields[propName]?.toString() !== valueToCompare
    this.setState({ warnings: { ...this.state.warnings, [propName]: raiseWarning } })
  }

  render () {
    const {
      t,
      showDossierStageModal,
      handleSubmit,
      languageList = [],
      combos: {
        permissionCombo
      },
      actions: {
        closeEditDossierStageModal
      }
    } = this.props
    const tKey = 'MASTERS.DOSSIER_STAGES_MASTERS.'

    return (
      <Modal show={showDossierStageModal} onHide={closeEditDossierStageModal} className="languages-modal" backdrop={'static'}>
        <Modal.Header closeButton>
          <i className="ico-users" /><Modal.Title>{t(`${tKey}DOSSIER_STAGE_DETAIL`)}</Modal.Title>
        </Modal.Header>
        <form autoComplete="off" onSubmit={handleSubmit(this.submitDossierStage.bind(this))}>
          <Modal.Body>
            <Row className="title-column">
              <Col sm={4}>
                <h2>{t('MASTERS.NAME')}</h2>
              </Col>
              <Col sm={6}>
                <h2>{t('MASTERS.DESCRIPTION')}</h2>
              </Col>
              <Col sm={2}>
                <h2>{t('MASTERS.LANGUAGE')}</h2>
              </Col>
            </Row>
            <FormGroup>
              {languageList.map((val, idx) => {
                return (
                  <Row key={idx} className="inputs-wrapper">
                    <Col sm={4} className="custom-width-col">
                      <Field
                        name={`lang${val.languageId}.name`}
                        placeholder={t('MASTERS.NAME')}
                        component={TextInput}
                      />
                    </Col>
                    <Col sm={6} className="custom-width-col">
                      <Field
                        name={`lang${val.languageId}.description`}
                        placeholder={t('MASTERS.DESCRIPTION')}
                        component={TextInput}
                      />
                    </Col>
                    <Col sm={2} className="language-column">
                      <p>{`${val.description} (${val.name})`}</p>
                    </Col>
                  </Row>
                )
              })}
            </FormGroup>
            <Row>
              <Col>
                <Field
                  component={InputNumber}
                  name={'maxDays'}
                  id={'maxDays'}
                  placeholder ={t(`${tKey}MAX_DAYS`)}
                  controlLabel={t(`${tKey}MAX_DAYS`)}
                  valueKey="id"
                  labelKey="value"
                  onChange={(event, value) => this.onChange(value, 'maxDays')}
                  colSm={6}
                />
              </Col>
              <Col>
                <Field
                  component={InputNumber}
                  name={'percentToWarn'}
                  id={'percentToWarn'}
                  placeholder ={t(`${tKey}WARNING_PERCENTAGE`)}
                  controlLabel={t(`${tKey}WARNING_PERCENTAGE`)}
                  valueKey="id"
                  labelKey="value"
                  onChange={(event, value) => this.onChange(value, 'percentToWarn')}
                  max={DEFAULT_MAX_PERCENTAGE}
                  colSm={6}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Field
                  name="permissionResponsableId"
                  controlLabel={t(`${tKey}MANAGER_PERMISSION`)}
                  placeholder={t(`${tKey}MANAGER_PERMISSION`)}
                  component={InputSelect}
                  multi={false}
                  valueKey="id"
                  labelKey="value"
                  childrenKey="children"
                  options={permissionCombo}
                  colSm={6}
                  onChange={(event, value) => this.onChange(value, 'permissionResponsableId')}
                />
              </Col>
              <Col>
                <Field
                  component={InputSelect}
                  name={'permissionToNotificationId'}
                  controlLabel={t(`${tKey}NOTIFY_PERMISSION`)}
                  placeholder={t(`${tKey}NOTIFY_PERMISSION`)}
                  multi={false}
                  valueKey="id"
                  labelKey="value"
                  childrenKey="children"
                  options={permissionCombo}
                  colSm={6}
                  onChange={(event, value) => this.onChange(value, 'permissionToNotificationId')}
                />
              </Col>
            </Row>
            <Row>
              <Field
                component={InputCheckbox}
                name={'visibleToProcessingAgency'}
                id={'visibleToProcessingAgency'}
                placeholder ={t(`${tKey}VISIBLE_PROCESSING_AGENCY`)}
                onChange={(event, value) => this.onChange(value, 'visibleToProcessingAgency')}
              />
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Col sm={6} className="btn-wrapper" smPush={6}>
              <Button
                id="role-modal-submit-button"
                className="btn-danger"
                type="reset"
                onClick={closeEditDossierStageModal}>
                {t('MASTERS.CANCEL')}
              </Button>
              <Button type="submit" className="btn-standard">
                {t('MASTERS.SAVE')}
              </Button>
            </Col>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

const validate = (values, props) => {
  const errors = {}
  props.languageList.map(language => {
    errors[`lang${language.languageId}`] = {}
    if (values[`lang${language.languageId}`]) {
      if (!(values[`lang${language.languageId}`].name)) {
        errors[`lang${language.languageId}`].name = props.t('MASTERS.VALIDATION.NAME_REQUIRED')
      }
      if (!(values[`lang${language.languageId}`].description)) {
        errors[`lang${language.languageId}`].description = props.t('MASTERS.VALIDATION.DESCRIPTION_REQUIRED')
      }
    } else {
      errors[`lang${language.languageId}`].name = props.t('MASTERS.VALIDATION.NAME_REQUIRED')
      errors[`lang${language.languageId}`].description = props.t('MASTERS.VALIDATION.DESCRIPTION_REQUIRED')
    }
  })

  return errors
}

export default reduxForm({
  form: 'editDossierStageModal',
  validate
})(DossierStageModal)
