import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import TrafficProcess from '../../../components/dossiermanagement/trafficProcess/trafficProcess'
import { translate } from 'react-polyglot'
// import { push as navigateTo } from 'react-router-redux'
import TrackingContainer from '../../../containers/tracking/trackingContainer'

export function mapStateToProps (state) {
  return {
    ...state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      /* navigateTo */
    }, dispatch)
  }
}

var decoratedComponent = TrackingContainer(TrafficProcess, true)
export default connect(mapStateToProps, mapDispatchToProps)(translate()(decoratedComponent))
