import React, { PureComponent } from 'react'
import { Button, Modal, Col, Row, Form } from 'react-bootstrap'
import { reduxForm, Field } from 'redux-form'
import InputText from '../../../components/commons/form/InputText'
import InputSelect from '../../../components/commons/form/InputSelect'

class RejectCommentModal extends PureComponent {
  fetchReasonsCombo () {
    if (this.props.actions.fetchReasons) {
      this.props.actions.fetchReasons(this.props.dossierSubTypeId)
    }
  }

  UNSAFE_componentWillMount () {
    if (!this.props.reasonCombo || this.props.reasonCombo.length === 0) {
      this.fetchReasonsCombo()
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.showModal && nextProps.showModal !== this.props.showModal && this.props.actions.fetchReasons) {
      if (!this.props.reasonCombo || this.props.reasonCombo.length === 0) {
        this.fetchReasonsCombo()
      }
    }
  }

  rejectActionPromise (values) {
    return new Promise((resolve, reject, pristine) => {
      return this.props.actions.rejectAction(values, resolve, reject, pristine)
    })
  }

  rejectAction (values) {
    this.rejectActionPromise(values).then(() => this.props.actions.closeModal())
  }

  render () {
    const { t, handleSubmit, showModal, actions: { closeModal }, reasonCombo, title, placeHolder, buttonText, backDropStatic } = this.props
    const tKey = 'DOSSIER_COMPONENTS.REJECT_MODAL.'
    const _title = title ?? 'TITLE'
    const _placeHolder = placeHolder ?? 'PLACEHOLDER'
    const _buttonText = buttonText ?? 'REJECT_BUTTON'
    return (
      <Modal className="add-user-modal" show={showModal} onHide={closeModal} backdrop={backDropStatic ? 'static' : true}>
        <Form autoComplete="off" onSubmit={handleSubmit(this.rejectAction.bind(this))}>
          <Modal.Header closeButton>
            <Modal.Title>{t(`${tKey}${_title}`)}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              {reasonCombo && reasonCombo.length > 0 && <Field
                id="reasonId"
                name="reasonId"
                colSm={12}
                placeholder={t(`${tKey}REJECT_REASON`)}
                component={InputSelect}
                options={reasonCombo}
                valueKey="id"
                labelKey="value"
              />}
              <Field
                id="text"
                name="text"
                componentClass="textarea"
                colSm={12}
                placeholder={t(`${tKey}${_placeHolder}`)}
                component={InputText}
                customClass="comment-reject-modal-textarea"
              />
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Col sm={6} className="btn-wrapper">
              <Button id="btn_save" className="btn-standard" type="submit">{t(`${tKey}${_buttonText}`)}</Button>
            </Col>
          </Modal.Footer>
        </Form>
      </Modal>
    )
  }
}
const validate = (values, { t, reasonCombo, commentValidation }) => {
  const tKey = 'DOSSIER_COMPONENTS.REJECT_MODAL.'
  const errors = {}
  if (!values.text) errors.text = t(`${tKey}${commentValidation || 'COMMENT_MANDATORY'}`)
  if (!values.reasonId) errors.reasonId = t(`${tKey}REASON_MANDATORY`)
  return errors
}

export default reduxForm({
  form: 'reject_comment_modal',
  validate
})(RejectCommentModal)
