import apiFetch from '../apiFetch'

export default function (financingTypeValues, companyId, financingTypeProductId, token) {
  const body = {
    isDeleted: false,
    financingTypeLanguage: financingTypeValues,
    financingTypeProductId: financingTypeProductId
  }
  return apiFetch({
    endPoint: `FinancingType/${companyId}`,
    method: 'POST',
    body: body,
    token: token
  })
}
