import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
// import { push } from 'react-router-redux'
import PurchaseHistorical from '../../../components/dossierHistorical/purchase/PurchaseHistorical'
import PurchaseHistorical_v2 from '../../../_v2/components/dossierHistorical/purchase/PurchaseHistorical'
import purchaseActions from '../../../actions/dossier/purchase'
import commentsActions from '../../../actions/dossier/common/comments'
import clientActions from '../../../actions/dossier/common/client'
import deliveryActions from '../../../actions/dossier/common/delivery'
import { storeDossier } from '../../../actions/dossier/commonDossierActions'
import vehicleActions from '../../../actions/dossier/common/vehicle'
import commonHeaderActions from '../../../actions/dossier/common/commonHeader'
import { openDocumentManagementModal as openDocumentModal } from '../../../actions/dossier/common/documentManagementModal'
import { openDocumentEditionModal, closeAddOrEditDocumentModal } from '../../../actions/dossier/common/documentHistoricalEditionModal'
import {
  fetchEntitySubTypeCombo, fetchEntityFilterSubTypeCombo, fetchStockStatusCombo, fetchPurchaseOrderTypeCombo, fetchCountries, fecthFamily, fetchCommercialSocietyTypeCombo,
  fetchDossierSubTypes, fetchEntityCountryTypeCombo, fetchEntityStateTypeCombo, fetchEntityCityTypeCombo, fetchEntityTownTypeCombo, fetchEntityColonyTypeCombo, fetchEntityCPTypeCombo,
  fetchActivityBpro,
  fetchClientDataAddress, fetchSaleTypeAll, fetchPuscharseSaleEntitys
} from '../../../actions/combos/combos'

import scrollerActions from '../../../actions/commons/scroller'
import { fetchFilteredRelatedDossier } from '../../../actions/dossier/common/dossierRelatedDossiers'
import buttonsHeaderActions from '../../../actions/dossier/common/buttonsHeader'
import buttonsSidebarActions from '../../../actions/dossier/common/buttonsSidebar'
import { saveSelectedDocuments, fetchSalesServices, saveSalesService, fetchNotValidDatesService, openOrCloseServiceAddModal } from '../../../actions/dossier/sales'
import { openModal as openCommonModal } from '../../../actions/common'
import {
  fetchCustomFields
} from '../../../actions/dossier/customFields'
import { registerLastAccess } from '../../../actions/dossier/common/lastAccessDossier'
import { getStorageValue } from '../../../storage/storage'
import TrackingContainer from '../../tracking/trackingContainer'

export function mapStateToProps (state) {
  return {
    vehicleProps: state.vehicle,
    dossier: state.purchaseDossier,
    dossierSales: state.salesDossier,
    combos: state.combos,
    windowWidth: state.windowDimensions.width,
    statusHeaderHeight: state.statusHeader.height,
    activeTab: state.buttonsHeader.activeTab,
    buttonsHeaderHeight: state.buttonsHeader.height,
    activeSection: state.buttonsSidebar.activeSection,
    relatedDossiersOrderBy: state.dossierRelatedDossiers.orderby
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      ...purchaseActions,
      ...commentsActions,
      ...clientActions,
      ...vehicleActions,
      ...deliveryActions,
      ...commonHeaderActions,
      ...buttonsHeaderActions,
      ...buttonsSidebarActions,
      ...scrollerActions,
      storeDossier,
      openDocumentEditionModal,
      closeAddOrEditDocumentModal,
      openDocumentModal,
      fetchEntitySubTypeCombo,
      fetchEntityFilterSubTypeCombo,
      fetchFilteredRelatedDossier,
      fetchStockStatusCombo,
      fetchPurchaseOrderTypeCombo,
      fetchCountries,
      fecthFamily,
      saveSelectedDocuments,
      fetchSalesServices,
      fetchDossierSubTypes,
      saveSalesService,
      fetchNotValidDatesService,
      openOrCloseServiceAddModal,
      fetchCommercialSocietyTypeCombo,
      fetchEntityCountryTypeCombo,
      fetchEntityStateTypeCombo,
      fetchEntityCityTypeCombo,
      fetchEntityTownTypeCombo,
      fetchEntityColonyTypeCombo,
      fetchEntityCPTypeCombo,
      openCommonModal,
      fetchActivityBpro,
      fetchCustomFields,
      fetchClientDataAddress,
      fetchSaleTypeAll,
      fetchPuscharseSaleEntitys,
      registerLastAccess
    }, dispatch)
  }
}

let version = parseInt(getStorageValue('interfaceVersion'))
var decoratedComponent = null
if (version === 2) {
  decoratedComponent = TrackingContainer(PurchaseHistorical_v2, true)
} else {
  decoratedComponent = TrackingContainer(PurchaseHistorical, true)
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(decoratedComponent))
