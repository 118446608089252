import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { submitProcessingAgencyAssignation, closeEditProcessingAgencyAssignationModal, editProcessingAgencyAssignation, addProcessingAgencyAssignation, deleteProcessingAgencyAssignation } from '../../../actions/masters/masters'
import { openModal } from '../../../actions/common'
import ProcessingAgencyAssignationModal from '../../../components/masters/processingAgencyAssignation/ProcessingAgencyAssignationMasterModal'

export function mapStateToProps (state) {
  const processingAgencyAssignationModal = state.processingAgencyAssignation.processingAgencyAssignationModal
  return {
    showModal: processingAgencyAssignationModal.showModal,
    quota: processingAgencyAssignationModal.quota,
    combos: {
      UOTreeCombo: state.combos.UOTreeCombo,
      ProcessingAgencyCombo: state.processingAgencyAssignation.quotaList.processingAgencies
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      submitProcessingAgencyAssignation,
      closeEditProcessingAgencyAssignationModal,
      editProcessingAgencyAssignation,
      addProcessingAgencyAssignation,
      deleteProcessingAgencyAssignation,
      openModal
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(ProcessingAgencyAssignationModal))
