import React, { PureComponent } from 'react'
import { Panel, Table, Button } from 'react-bootstrap'
import UACrow from '../../../../../components/dossiers/common/uac/UACrow'
import SmallLoaderPage from '../../../../../containers/commons/SmallLoaderPage'
import { getFieldsPermisions } from '../../../../../util/permisionFunctions'
import { permissions } from '../../../../../constants/backendIds'
import CustomFields from '../../../../../components/commons/customFields/CustomFields'
import ComponentTitle from '../../../../../_v2/containers/dossiers/commons/ComponentTitlePage'

class UAC extends PureComponent {
  constructor () {
    super()
    this.state = {
      open: false,
      fieldsConfiguration: {
        allDocumentUAC: null
      }
    }
    this.addButton = this.addButton.bind(this)
    this.handleClickHeader = this.handleClickHeader.bind(this)
  }

  addButton () {
    this.props.actions.fetchUACUnitEdition(null, this.props.actions.openUACEdition, this.props.dossierType, this.props.dossierSubType)
  }

  handleClickHeader () {
    if (this.state.open) {
      this.props.actions.setActiveSection(false)
    }
    this.setState({ open: !this.state.open })
  }

  initializePermisions (props) {
    const fieldKeys = Object.keys(this.state.fieldsConfiguration)
    const newState = getFieldsPermisions(props.componentConfiguration.fieldsConfiguration, fieldKeys, props.componentConfiguration.permission)
    this.setState({ fieldsConfiguration: newState.fieldsConfiguration })
  }

  UNSAFE_componentWillMount () {
    this.initializePermisions(this.props)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.open) {
      this.setState({ open: true })
    }
  }

  render () {
    const { t, readOnlyFields, uac, customFields, actions: { openUACEdition, deleteUACUnit, fetchUACUnitEdition }, dossierType } = this.props
    const tKey = 'DOSSIER_COMPONENTS.UAC.'
    return (
      <div className="dossier-Component">
        <div className="header-panel" onClick={ this.handleClickHeader }>
          <ComponentTitle
            componentCode="UAC"
            componentOpen={this.state.open}
          />
        </div>
        <Panel expanded={this.state.open} onToggle={() => {}} >
          <Panel.Collapse>
            <div className="fieldsContainer">
              <div className="wrapper _nf">
                <Table className="editable-table" hover responsive>
                  <thead>
                    <tr>
                      {this.state.fieldsConfiguration.allDocumentUAC && this.state.fieldsConfiguration.allDocumentUAC.permission !== permissions.hidden &&
                <th>{t(`${tKey}DOC_UPDATED`)}</th>
                      }
                      <th>{t(`${tKey}EXP_NUMBER`)}</th>
                      <th>{t(`${tKey}ID_DEALER`)}</th>
                      <th>{t(`${tKey}BRAND`)}</th>
                      <th>{t(`${tKey}MODEL`)}</th>
                      <th>{t(`${tKey}KM`)}</th>
                      <th>{t(`${tKey}LICENCE_PLATE`)}</th>
                      <th>{t(`${tKey}VIN_CHASSIS`)}</th>
                      <th>{t(`${tKey}LICENCE_PLATE_DATE`)}</th>
                      <th>{t(`${tKey}PAYMENT_INCLUDED`)}</th>
                      <th style={{ width: '77px' }}>{t('DOSSIER_COMPONENTS.ACCESSORIES.ACTIONS')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      uac.map((uacUnit, idx) => {
                        return (
                          <UACrow
                            t={t}
                            tKey={tKey}
                            uacUnit={uacUnit}
                            key={idx}
                            openUACEdition={openUACEdition}
                            fetchUACUnitEdition={fetchUACUnitEdition}
                            deleteUACUnit={deleteUACUnit}
                            readOnlyFields={readOnlyFields}
                            allDocumentUAC={this.state.fieldsConfiguration.allDocumentUAC.permission}
                            dossierType={dossierType}
                          />
                        )
                      })
                    }
                  </tbody>
                  <div className='flexInLine al-L'>
                    {!readOnlyFields &&
                          <Button
                            className="_Btn new"
                            id="add-button"
                            onClick={this.addButton}
                            disabled={this.props.componentConfiguration.permission === permissions.readOnly}
                          >
                            <i className="iDocIcon-add-plus"></i>
                            {t(`${tKey}ADD_BTN`)}
                          </Button>
                    }
                  </div>
                </Table>
              </div>
            </div>
            <CustomFields customFields={customFields} readOnlyFields={readOnlyFields} />
          </Panel.Collapse>
        </Panel>
        <SmallLoaderPage/>
      </div>
    )
  }
}
export default UAC
