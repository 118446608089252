import apiFetch from '../apiFetch'

export default function (organizedUnitId, token) {
  return apiFetch({
    endPoint: `AssociatedCostType?OrganizedUnitId=${organizedUnitId}`,
    method: 'GET',
    body: null,
    token: token
  })
}
