import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { fetchDocumentTypeList, deleteDocumentType, openDocumentTypeModal } from '../../../actions/masters/documentType/documentType'
import DocumentTypeMaster from '../../../components/masters/documentType/DocumentTypeMaster'

export function mapStateToProps (state) {
  return {
    ...state.documentType,
    combos: {
      documentTypeEntities: state.combos.documentTypeEntities
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      deleteDocumentType,
      openDocumentTypeModal,
      fetchDocumentTypeList
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(DocumentTypeMaster))
