// Reac/Redux
import { put, call, select, takeLatest } from 'redux-saga/effects'
// Others
import { getAuth } from '../../selectors/access/auth'
import faqActionTypes from '../../constants/actions/faq/faq'
import { handleError } from '../errors/errorManager'
// Actions
import { showLoader, hideLoader } from '../../actions/common.js'
import { fetchSectionListSuccess, fetchQuestionSuccess, fetchSectionQuestionsSuccess, fetchQuestionsBySearchSuccess } from '../../actions/faq/faq'
// Services
import getSectionListService from '../../services/faq/getSectionList'
import getQuestionService from '../../services/faq/getQuestion'
import getSectionQuestionsService from '../../services/faq/getSectionQuestions'
import getQuestionsBySearchService from '../../services/faq/getQuestionsBySearch'
import putVoteQuestionService from '../../services/faq/putVoteQuestion'

export function * fetchSectionList () {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const sections = yield call(getSectionListService, auth.token)
    yield put(fetchSectionListSuccess(sections))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchSectionList () {
  yield takeLatest(faqActionTypes.FETCH_SECTION_LIST, fetchSectionList)
}

export function * fetchQuestion ({ questionId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const question = yield call(getQuestionService, auth.token, questionId)
    yield put(fetchQuestionSuccess(question))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchQuestion () {
  yield takeLatest(faqActionTypes.FETCH_QUESTION, fetchQuestion)
}

export function * fetchSectionQuestions ({ sectionId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const questions = yield call(getSectionQuestionsService, auth.token, sectionId)
    yield put(fetchSectionQuestionsSuccess(questions))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchSectionQuestions () {
  yield takeLatest(faqActionTypes.FETCH_SECTION_QUESTION, fetchSectionQuestions)
}

export function * fetchQuestionsBySearch ({ search }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const questions = yield call(getQuestionsBySearchService, auth.token, search)
    yield put(fetchQuestionsBySearchSuccess(questions))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchQuestionsBySearch () {
  yield takeLatest(faqActionTypes.FETCH_QUESTIONS_BY_SEARCH, fetchQuestionsBySearch)
}

export function * voteQuestion ({ faqId, isPositive }) {
  try {
    const auth = yield select(getAuth)
    yield call(putVoteQuestionService, auth.token, faqId, isPositive)
  } catch (error) {
    yield call(handleError, { error })
  }
}

export function * watchVoteQuestion () {
  yield takeLatest(faqActionTypes.VOTE_QUESTION, voteQuestion)
}
