
import React, { PureComponent } from 'react'
import { reduxForm } from 'redux-form'
import { Button, Modal, Col, Form, Table, Checkbox } from 'react-bootstrap'
import DocumentListItem from './documentaryManagement/documentaryManagementChild/DocumentListItem'
import { backEndIdDocumentStatus } from '../../../constants/backendIds'

class DownloadDocumentsModal extends PureComponent {
  constructor () {
    super()
    this.state = {
      documenstSelected: [],
      documentHistoricalSelected: [],
      documents: [],
      order: {
        columnName: null,
        direction: 'asc'
      },
      structureGenerated: false,
      filter: {
        documentStatus: null
      }
    }
  }

  filterValidates () {
    const checkbox = document.getElementById('checkValidate')
    if (checkbox != null) {
      const filter = {
        documentStatus: checkbox.checked ? backEndIdDocumentStatus.VALIDATED : null
      }
      this.searchDocumens(filter)
    }
  }

  donwloadSelectedDocuments () {
    if (this.state.documenstSelected.length !== 0 || this.state.documentHistoricalSelected.length !== 0) {
      this.props.actions.saveSelectedDocuments(this.state.documenstSelected, this.state.documentHistoricalSelected)
    }
    this.setState({ documentsSelected: [] })
    this.setState({ documentHistoricalSelected: [] })
    this.props.actions.closeModal()
  }

  orderTable () {
    const columnName = this.state.order.columnName
    const documents = this.props.documents.sort(function (a, b) {
      if (columnName === 'validationDate') {
        const fechaInicial = a.validationDate ? a.validationDate.split(' ') : ['-']
        const separarA = fechaInicial.map((dia) => dia[0] === '-' ? ('01,01,2000,00,00') : (fechaInicial[0].split('/').join(',') + ',' + fechaInicial[1].split(':').join(',')))
        const fechaA = separarA.map((datos) => datos.split(','))
        const fechaFinal = b.validationDate ? b.validationDate.split(' ') : ['-']
        const separarB = fechaFinal.map((dia) => dia[0] === '-' ? ('01,01,2000,00,00') : (fechaFinal[0].split('/').join(',') + ',' + fechaFinal[1].split(':').join(',')))
        const fechaB = separarB.map((datos) => datos.split(','))
        const fecha1 = new Date(fechaA[0][2], fechaA[0][1], fechaA[0][0], fechaA[0][3], fechaA[0][4])
        const fecha2 = new Date(fechaB[0][2], fechaB[0][1], fechaB[0][0], fechaB[0][3], fechaB[0][4])
        return fecha2 - fecha1
      } else if (columnName === 'incorporationDate') {
        const fechaInicial = a.incorporationDate ? a.incorporationDate.split(' ') : ['-']
        const separarA = fechaInicial.map((dia) => dia[0] === '-' ? ('01,01,2000,00,00') : (fechaInicial[0].split('/').join(',') + ',' + fechaInicial[1].split(':').join(',')))
        const fechaA = separarA.map((datos) => datos.split(','))
        const fechaFinal = b.incorporationDate ? b.incorporationDate.split(' ') : ['-']
        const separarB = fechaFinal.map((dia) => dia[0] === '-' ? ('01,01,2000,00,00') : (fechaFinal[0].split('/').join(',') + ',' + fechaFinal[1].split(':').join(',')))
        const fechaB = separarB.map((datos) => datos.split(','))
        const fecha1 = new Date(fechaA[0][2], fechaA[0][1], fechaA[0][0], fechaA[0][3], fechaA[0][4])
        const fecha2 = new Date(fechaB[0][2], fechaB[0][1], fechaB[0][0], fechaB[0][3], fechaB[0][4])
        return fecha2 - fecha1
      } else if (columnName === 'checklistStatus') {
        return a.checklistStatus - b.checklistStatus
      } else if (a[columnName] && a[columnName].localeCompare) {
        return a[columnName].localeCompare(b[columnName])
      } else {
        return (isNaN(a[columnName]) ? 0 : a[columnName]) - (isNaN(b[columnName]) ? 0 : b[columnName])
      }
    })
    if (this.state.order.direction === 'asc') {
      documents.reverse()
    }
    this.setState({ documents })
  }

  changeOrderIcon (name) {
    let clase = 'ico-sort'
    if (this.state.order.direction === 'asc' && this.state.order.columnName === name) {
      clase += '-selected-asc'
    } else if (this.state.order.direction === 'desc' && this.state.order.columnName === name) {
      clase += '-selected-desc'
    }
    return clase
  }

  changeDirection (columnName) {
    this.setState({
      order: {
        columnName: columnName,
        direction: this.state.order.direction === 'asc' ? 'desc' : 'asc'
      }
    }, this.orderTable)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (!this.props.showModal && nextProps.showModal) {
      this.searchDocumens()
    }
    if (this.props.showModal && !nextProps.showModal) {
      this.props.reset('campaign_search_documents_modal')
    }
  }

  searchDocumens (filter = this.state.filter) {
    if (this.props.dossierIsHistorical) {
      filter.onlyDossier = true
      this.props.actions.fetchAllDocumentHistoricals(filter, this.props.dossierId)
    } else {
      this.props.actions.fetchAllDocuments({ ...filter })
    }
  }

  selectDocument (id, documentHistorical) {
    if (!documentHistorical) {
      if (this.state.documenstSelected.includes(id)) {
        this.state.documenstSelected.splice(this.state.documenstSelected.indexOf(id), 1)
        const td = document.getElementById(id)
        td.className = ''
      } else {
        if (id != null) {
          this.state.documenstSelected.push(id)
          const td = document.getElementById(id)
          td.className = 'is-selected'
        }
      }
    } else {
      if (this.state.documentHistoricalSelected.includes(id)) {
        this.state.documentHistoricalSelected.splice(this.state.documentHistoricalSelected.indexOf(id), 1)
        const td = document.getElementById(id)
        td.className = ''
      } else {
        if (id != null) {
          this.state.documentHistoricalSelected.push(id)
          const td = document.getElementById(id)
          td.className = 'is-selected'
        }
      }
    }
  }

  render () {
    const { t, showModal, documents, dossierId, dossierType, showOnlyValidateDocumentsCheck, dossierIsHistorical, actions: { closeModal } } = this.props
    const tKey = 'DOSSIER_COMPONENTS.CAMPAIGNS.CAMPAIGNS_SEARCH_DOCUMENTS_MODAL.'
    return (
      <Modal className="rr-purchase-modal" show={showModal} onHide={() => closeModal()}>
        <Form autoComplete="off" onSubmit={this.donwloadSelectedDocuments.bind(this)}>
          <Modal.Header closeButton>
            <Modal.Title>{t(`${tKey + 'TITLE'}`)}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            { showOnlyValidateDocumentsCheck &&
              <div className="checkValidate">
                <Checkbox
                  id="checkValidate"
                  inline={false}
                  className="checkbox"
                  onChange={() => this.filterValidates()}
                >
                  {t(`${tKey + 'SHOW_ONLY_VALIDATES'}`)}
                </Checkbox>
              </div>
            }
            <Table hover responsive className="simple-table gestion-documental-table" id="documentary-management-table">
              <thead>
                <tr>

                  <th onClick={() => [this.changeDirection('documentStatus')]}>{t(`${tKey + 'TABLE.STATUS'}`)}<i className={(() => [this.changeOrderIcon('documentStatus')])()} /></th>
                  <th onClick={() => [this.changeDirection('documentName')]}>{t(`${tKey + 'TABLE.TYPES'}`)}<i className={(() => [this.changeOrderIcon('documentName')])()} /></th>
                  <th onClick={() => [this.changeDirection('documentEntityType')]}>{t(`${tKey + 'TABLE.CATEGORY'}`)}<i className={(() => [this.changeOrderIcon('documentEntityType')])()} /></th>
                  <th onClick={() => [this.changeDirection('validationDate')]}>{t(`${tKey + 'TABLE.VALIDATION_DATE'}`)}<i className={(() => [this.changeOrderIcon('validationDate')])()} /></th>
                  <th onClick={() => [this.changeDirection('incorporationDate')]}>{t(`${tKey + 'TABLE.ASSIGNMENT_DATE'}`)}<i className={(() => [this.changeOrderIcon('incorporationDate')])()} /></th>
                </tr>
              </thead>
              <tbody>
                {
                  documents && documents.map((documentFile, idx) => <DocumentListItem
                    t={t}
                    key={idx}
                    readOnly={false}
                    dossierId={dossierId}
                    dossierType={dossierType}
                    documentEntityType={documentFile.documentEntityType}
                    documentFile={documentFile}
                    objectId={documentFile.objectId}
                    select={true}
                    dossierIsHistorical={dossierIsHistorical}
                    selectDocument={(id) => this.selectDocument(id, dossierIsHistorical)}
                    actions={{}}
                    glyph={false}
                    signStatus={false}
                  />)
                }
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            <Col sm={6} className="btn-wrapper">
              <Button
                className="btn-standard"
                id='btn_guardar'
                onClick={() => this.donwloadSelectedDocuments()}
              >
                {t(`${tKey + 'ACCEPT'}`)}
              </Button>
            </Col>
          </Modal.Footer>
        </Form>
      </Modal>
    )
  }
}

export default reduxForm({
  form: 'campaign_modal'
})(DownloadDocumentsModal)
