import React, { PureComponent } from 'react'
import { FormGroup, Col, ControlLabel } from 'react-bootstrap'
import DatePicker from 'react-16-bootstrap-date-picker'

class SimpleInputDatePicker extends PureComponent {
  formattedInputValue (inputValue) {
    if (inputValue) {
      if (inputValue.split('/').length !== 3) return null
      const [dd, mm, yyyy] = inputValue.split('/')
      return yyyy ? `${yyyy}-${mm}-${dd}T10:00:00.000Z` : null
    } else {
      return inputValue
    }
  }

  render () {
    const { value, componentClass = '', disabled, onInputChange, controlLabel = '', colSm = 12, error = '', id, forceControlLabelInOneLine = false, paddingLeft } = this.props
    return (
      <Col sm={colSm} style={paddingLeft}>
        <FormGroup
          className="forms-with-label"
          validationState={error ? 'error' : null}
        >
          {
            // controlLabel && <ControlLabel>{controlLabel}</ControlLabel>
            controlLabel && <ControlLabel className={forceControlLabelInOneLine ? 'one-line ellipsis' : ''}>{controlLabel}</ControlLabel>
          }
          <DatePicker
            id={id}
            value={this.formattedInputValue(value)}
            className={componentClass + ' text-left'}
            onChange={(value, formattedValue) => onInputChange(formattedValue)}
            disabled={disabled}
            placeholder="dd/mm/aaaa"
            dateFormat="DD/MM/YYYY"
            dayLabels={['Dom', 'Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab']}
            monthLabels={['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']}
            todayButtonLabel='Hoy'
            showTodayButton={true}
          />
          {error && <span className='help-block text-center'>{error}</span>}
        </FormGroup>
      </Col>
    )
  }
}

export default SimpleInputDatePicker
