import { call, put, takeEvery, select } from 'redux-saga/effects'
import { change, reset } from 'redux-form'
import vehicleActionsTypes from '../../../../constants/actions/dossier/common/vehicle'
import messageModalActions from '../../../../actions/modals/messageModal'
import { showLoader, hideLoader, openModal } from '../../../../actions/common'
import { yesNoModal } from '../../../modalsListeners/yesNoModal'
import { fetchVehicleSelectionModalSuccess, resetSearchFields } from '../../../../actions/dossier/common/vehicle'
import { fetchPaperworkDossierSuccess, restartPaperworkState } from '../../../../actions/dossier/paperwork'
import { fetchPurchaseDossierSuccess, restartPurchaseState } from '../../../../actions/dossier/purchase'
import { fetchSalesDossierSuccess, restartSalesState } from '../../../../actions/dossier/sales'
import { fetchCampaignDossierSuccess, restartCampaignState } from '../../../../actions/dossier/campaign'
import { openVehicleFinderModal, fetchServiceDossierSuccess, restartServiceDossierState } from '../../../../actions/dossier/serviceDossier'
import { getAuth } from '../../../../selectors/access/auth'
import getPurchaseWithVehicleQuery from '../../../../services/dossier/get/getPurchaseWithVehicleQuery'
import getVehicleByPlateOrChassis from '../../../../services/vehicle/getVehicleByPlateOrChassis'
import getPurchaseVehicleByDossierId from '../../../../services/dossier/get/getPurchaseWithVehicleById'
import { handleError } from '../../../errors/errorManager'
import putDossierRemoveVehicle from '../../../../services/dossier/put/putDossierRemoveVehicle'
import { fetchRegistrationDeliveryDossierSuccess, restartRegistrationDeliveryState } from '../../../../actions/dossier/registrationDelivery'
import { initializeDossier } from '../../commonDossierSagas'
import { dossierTypes as dossierTypesConstant } from '../../../../constants/dossier/common/dossierTypes'

import { fetchStockDossierSuccess, restartStockDossierState } from '../../../../actions/dossier/stockDossier'
import getPurchasesToStockDossier from '../../../../services/dossier/get/getPurchasesToStockDossier'
import settings from '../../../../setting'
import { clientInformation } from '../../../../constants/backendIds'
import getVehicleByPlateOrChassisService from '../../../../services/serviceDossier/get/getVehicleByPlateOrChassisService'
import postVehicleFinderCreation from '../../../../services/serviceDossier/post/postVehicleFinderCreation'

export function * searchVehicle ({ dealerNumber, vinChassis, licensePlate, makerNumber, parentDossierType, dossierWithVehicle, organizedUnitId, dossierSubtypeId, pristine }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    let vehicle
    if (parentDossierType.isSales) {
      vehicle = yield call(getPurchaseWithVehicleQuery, dealerNumber, vinChassis, licensePlate, makerNumber, auth.token, organizedUnitId, dossierSubtypeId)
      vehicle.updated = true
      let vehicleSalesDossierState = JSON.parse(JSON.stringify(yield select(state => state.salesDossier.vehicleComponent)))
      vehicleSalesDossierState = { ...vehicleSalesDossierState, ...vehicle }
      yield put(change('salesDossier', 'vehicleComponent', vehicle.vehicleId ? vehicle : {}))
      yield put(fetchSalesDossierSuccess({ parentIsHistorical: vehicle.parentIsHistorical, vehicleComponent: vehicleSalesDossierState }))
      if (!vehicle.vehicleId) yield put(openModal('vehicleSaleNotFound'))
    } else if (parentDossierType.isPurchase) {
      vehicle = yield call(getVehicleByPlateOrChassis, auth.token, dealerNumber, vinChassis, licensePlate, false, organizedUnitId, dossierSubtypeId)
      vehicle.updated = true
      if (vehicle.vehicleId && dossierWithVehicle) {
        yield put(hideLoader())
        const confirmed = yield call(yesNoModal, 'newVehicleOnDossier')
        if (confirmed) {
          yield put(change('purchase_dossier', 'vehicleComponent', vehicle.vehicleId ? vehicle : {}))
          yield put(fetchPurchaseDossierSuccess({ vehicleComponent: vehicle }))
        }
      } else {
        yield put(change('purchase_dossier', 'vehicleComponent', vehicle.vehicleId ? vehicle : {}))
      }
      if (!vehicle.vehicleId) yield put(openModal('vehicleNotFound'))
    } else if (parentDossierType.isPaperwork) {
      vehicle = yield call(getVehicleByPlateOrChassis, auth.token, dealerNumber, vinChassis, licensePlate, null, organizedUnitId, dossierSubtypeId)
      vehicle.updated = true
      if (vehicle.vehicleId && dossierWithVehicle) {
        yield put(hideLoader())
        const confirmed = yield call(yesNoModal, 'newVehicleOnDossier')
        if (confirmed) {
          yield put(change('paperwork_dossier', 'vehicleComponent', vehicle.vehicleId ? vehicle : {}))
          yield put(fetchPaperworkDossierSuccess({ vehicleComponent: vehicle }))
        }
      } else {
        yield put(change('paperwork_dossier', 'vehicleComponent', vehicle.vehicleId ? vehicle : {}))
      }
      if (!vehicle.vehicleId) yield put(openModal('vehicleNotFound'))
    } else if (parentDossierType.isCampaign) {
      vehicle = yield call(getVehicleByPlateOrChassis, auth.token, dealerNumber, vinChassis, licensePlate, null, organizedUnitId, dossierSubtypeId)
      vehicle.updated = true
      if (vehicle.vehicleId && dossierWithVehicle) {
        yield put(hideLoader())
        const confirmed = yield call(yesNoModal, 'newVehicleOnDossier')
        if (confirmed) {
          yield put(change('campaign_dossier', 'vehicleComponent', vehicle.vehicleId ? vehicle : {}))
          yield put(fetchCampaignDossierSuccess({ vehicleComponent: vehicle }))
        }
      } else {
        yield put(change('campaign_dossier', 'vehicleComponent', vehicle.vehicleId ? vehicle : {}))
      }
      if (!vehicle.vehicleId) yield put(openModal('vehicleNotFound'))
    } else if (parentDossierType.isRegistrationDelivery) {
      vehicle = yield call(getPurchaseWithVehicleQuery, dealerNumber, vinChassis, licensePlate, makerNumber, auth.token, organizedUnitId, dossierSubtypeId)
      vehicle.updated = true
      yield put(change('registrationDeliveryDossier', 'vehicleComponent', vehicle.vehicleId ? vehicle : { updated: true }))
      yield put(fetchRegistrationDeliveryDossierSuccess({ parentIsHistorical: vehicle.parentIsHistorical }))
      if (!vehicle.vehicleId) yield put(openModal('vehicleSaleNotFound'))
    } else if (parentDossierType.isService) {
      if (settings.clientFieldValidation === clientInformation.VGR) {
        const dossierId = yield select(state => state.serviceDossier.dossierId)
        const vehicleFinder = {
          vinChassis: vinChassis,
          licensePlate: licensePlate
        }
        vehicle = yield call(getVehicleByPlateOrChassisService, auth.token, dossierId, vehicleFinder)
      } else {
        vehicle = yield call(getVehicleByPlateOrChassis, auth.token, dealerNumber, vinChassis, licensePlate, null, organizedUnitId, dossierSubtypeId)
      }
      if (vehicle.vehicleId && dossierWithVehicle) {
        vehicle.updated = true
        yield put(hideLoader())
        const confirmed = yield call(yesNoModal, 'newVehicleOnDossier')
        if (confirmed) {
          yield put(change('service_dossier', 'vehicleComponent', vehicle))
          yield put(fetchServiceDossierSuccess({ vehicleComponent: vehicle.vehicleId ? vehicle : {} }))
        }
      } else if (vehicle.vehicleId) {
        vehicle.updated = true
        yield put(change('service_dossier', 'vehicleComponent', vehicle.vehicleId ? vehicle : {}))
      }
      if (!vehicle.vehicleId) {
        yield put(hideLoader())
        if (settings.clientFieldValidation === clientInformation.VGR) {
          const response = yield call(yesNoModal, 'vehicleNotFoundCreate')
          if (response) {
            if (!pristine) {
              if (yield call(yesNoModal, 'commonDossierSaveWarning')) {
                yield put(openVehicleFinderModal(true))
              }
            } else {
              yield put(openVehicleFinderModal(true))
            }
          }
        } else {
          yield put(openModal('vehicleNotFound'))
        }
      }
    } else if (parentDossierType.isStock) {
      const purchaseList = yield call(getPurchasesToStockDossier, auth.token, dealerNumber, vinChassis, licensePlate, makerNumber, organizedUnitId)
      if (purchaseList.length > 1) {
        const dossierState = JSON.parse(JSON.stringify(yield select(state => state.stockDossier)))
        yield put(fetchVehicleSelectionModalSuccess(purchaseList, dossierState.dossierId, { dealerNumber, vinChassis, licensePlate, makerNumber, organizedUnitId }))
      } else if (purchaseList.length === 1) {
        vehicle = purchaseList[0]
        vehicle.updated = true
        yield put(change('stock_dossier', 'vehicleComponent', vehicle))
        yield put(fetchStockDossierSuccess({ dossierVehicleComponent: vehicle }))
      } else {
        yield put(openModal('vehicleNotFound'))
      }
    }
    if (vehicle?.vehicleId) {
      yield put(resetSearchFields())
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.VEHICLE_COMPONENT.UNKNOWN_ERROR', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('purchase_dossier'))
      yield put(reset('paperwork_dossier'))
      yield put(reset('campaign_dossier'))
      yield put(reset('registrationDeliveryDossier'))
      yield put(reset('service_dossier'))
      yield put(reset('stock_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSearchVehicle () {
  yield takeEvery(vehicleActionsTypes.SEARCH_VEHICLE, searchVehicle)
}

export function * fetchVehicleSelectionModal () {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const vehicleState = yield select(state => state.vehicle)
    const { vehicleSelectionModal: { filters: { dealerNumber, vinChassis, licensePlate, makerNumber, organizedUnitId } } } = vehicleState
    const purchaseList = yield call(getPurchasesToStockDossier, auth.token, dealerNumber, vinChassis, licensePlate, makerNumber, organizedUnitId)
    yield put(fetchVehicleSelectionModalSuccess(purchaseList))
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.VEHICLE_COMPONENT.UNKNOWN_ERROR', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchVehicleSelectionModal () {
  yield takeEvery(vehicleActionsTypes.FETCH_VEHICLE_SELECTION_MODAL, fetchVehicleSelectionModal)
}

export function * refreshVehicle ({ parentDossierType }) {
  yield put(showLoader())
  try {
    const auth = yield select(getAuth)
    if (parentDossierType.isSales) {
      const dossierId = yield select(state => state.salesDossier.dossierId)
      const vehicleComponent = yield call(getPurchaseVehicleByDossierId, dossierId, auth.token)
      yield put(change('salesDossier', 'vehicleComponent', vehicleComponent.vehicleId ? vehicleComponent : {}))
      yield put(fetchSalesDossierSuccess({ vehicleComponent }))
    } else if (parentDossierType.isPurchase) {
      const dossierId = yield select(state => state.purchaseDossier.dossierId)
      const vehicleComponent = yield call(getPurchaseVehicleByDossierId, dossierId, auth.token)
      yield put(change('purchase_dossier', 'vehicleComponent', vehicleComponent.vehicleId ? vehicleComponent : {}))
      yield put(fetchPurchaseDossierSuccess({ vehicleComponent }))
    } else if (parentDossierType.isPaperwork) {
      const dossierId = yield select(state => state.paperworkDossier.dossierId)
      const vehicleComponent = yield call(getPurchaseVehicleByDossierId, dossierId, auth.token)
      yield put(change('paperwork_dossier', 'vehicleComponent', vehicleComponent.vehicleId ? vehicleComponent : {}))
      yield put(fetchPaperworkDossierSuccess({ vehicleComponent }))
    } else if (parentDossierType.isCampaign) {
      const dossierId = yield select(state => state.campaignDossier.dossierId)
      const vehicleComponent = yield call(getPurchaseVehicleByDossierId, dossierId, auth.token)
      yield put(change('campaign_dossier', 'vehicleComponent', vehicleComponent.vehicleId ? vehicleComponent : {}))
      yield put(fetchCampaignDossierSuccess({ vehicleComponent }))
    } else if (parentDossierType.isRegistrationDelivery) {
      const dossierId = yield select(state => state.registrationDeliveryDossier.dossierId)
      const vehicleComponent = yield call(getPurchaseVehicleByDossierId, dossierId, auth.token)
      yield put(change('registrationDeliveryDossier', 'vehicleComponent', vehicleComponent.vehicleId ? vehicleComponent : {}))
      yield put(fetchRegistrationDeliveryDossierSuccess({ vehicleComponent }))
    } else if (parentDossierType.isService) {
      const dossierId = yield select(state => state.serviceDossier.dossierId)
      const vehicleComponent = yield call(getPurchaseVehicleByDossierId, dossierId, auth.token)
      yield put(change('service_dossier', 'vehicleComponent', vehicleComponent.vehicleId ? vehicleComponent : {}))
      yield put(fetchServiceDossierSuccess({ vehicleComponent }))
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.VEHICLE_COMPONENT.UNKNOWN_ERROR', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('purchase_dossier'))
      yield put(reset('paperwork_dossier'))
      yield put(reset('campaign_dossier'))
      yield put(reset('registrationDeliveryDossier'))
      yield put(reset('service_dossier'))
      yield put(reset('stock_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchRefreshVehicle () {
  yield takeEvery(vehicleActionsTypes.REFRESH_VEHICLE_COMPONENT, refreshVehicle)
}

export function * clearVehicle ({ parentDossierType, pristine }) {
  try {
    const confirmedClearVehicleWarning = pristine ? yield call(yesNoModal, 'commonVehicleClearWarning') : yield call(yesNoModal, 'commonVehicleClearWarningWithUnsaveChanges')
    if (confirmedClearVehicleWarning) {
      yield put(showLoader())
      const auth = yield select(getAuth)
      if (parentDossierType.isSales) {
        const dossier = JSON.parse(JSON.stringify(yield select(state => state.salesDossier)))
        yield call(putDossierRemoveVehicle, dossier.dossierId, auth.token, dossier.lastAccessDate)
        yield put(restartSalesState())
        yield call(initializeDossier, dossierTypesConstant.sales, dossier)
      } else if (parentDossierType.isPurchase) {
        const dossier = JSON.parse(JSON.stringify(yield select(state => state.purchaseDossier)))
        if (!changesSavedForVehicle(dossier)) {
          yield deleteVehicleFromStateForDossier('purchase_dossier')
          yield put(fetchPurchaseDossierSuccess({ vehicleComponent: { updated: true } }))
          return
        }
        yield call(putDossierRemoveVehicle, dossier.dossierId, auth.token, dossier.lastAccessDate)
        yield put(restartPurchaseState())
        yield call(initializeDossier, dossierTypesConstant.purchase, dossier)
      } else if (parentDossierType.isPaperwork) {
        const dossier = JSON.parse(JSON.stringify(yield select(state => state.paperworkDossier)))
        if (!changesSavedForVehicle(dossier)) {
          yield deleteVehicleFromStateForDossier('paperwork_dossier')
          yield put(fetchPaperworkDossierSuccess({ vehicleComponent: { updated: true } }))
          return
        }
        yield call(putDossierRemoveVehicle, dossier.dossierId, auth.token, dossier.lastAccessDate)
        yield put(restartPaperworkState())
        yield call(initializeDossier, dossierTypesConstant.paperwork, dossier)
      } else if (parentDossierType.isCampaign) {
        const dossier = JSON.parse(JSON.stringify(yield select(state => state.campaignDossier)))
        if (!changesSavedForVehicle(dossier)) {
          yield deleteVehicleFromStateForDossier('campaign_dossier')
          yield put(fetchCampaignDossierSuccess({ vehicleComponent: { updated: true } }))
          return
        }
        yield call(putDossierRemoveVehicle, dossier.dossierId, auth.token, dossier.lastAccessDate)
        yield put(restartCampaignState())
        yield call(initializeDossier, dossierTypesConstant.campaign, dossier)
      } else if (parentDossierType.isRegistrationDelivery) {
        const registrationDeliveryDossierState = JSON.parse(JSON.stringify(yield select(state => state.registrationDeliveryDossier)))
        yield call(putDossierRemoveVehicle, registrationDeliveryDossierState.dossierId, auth.token, registrationDeliveryDossierState.lastAccessDate)
        yield put(restartRegistrationDeliveryState())
        yield call(initializeDossier, dossierTypesConstant.registrationDelivery, registrationDeliveryDossierState)
      } else if (parentDossierType.isService) {
        const dossierState = JSON.parse(JSON.stringify(yield select(state => state.serviceDossier)))
        if (!changesSavedForVehicle(dossierState)) {
          yield deleteVehicleFromStateForDossier('service_dossier')
          yield put(fetchServiceDossierSuccess({ vehicleComponent: { updated: true } }))
          return
        }
        yield call(putDossierRemoveVehicle, dossierState.dossierId, auth.token, dossierState.lastAccessDate)
        yield put(restartServiceDossierState())
        yield call(initializeDossier, dossierTypesConstant.service, dossierState)
      } else if (parentDossierType.isStock) {
        const dossierState = JSON.parse(JSON.stringify(yield select(state => state.stockDossier)))
        if (!changesSavedForVehicle(dossierState)) {
          yield deleteVehicleFromStateForDossier('stock_dossier')
          yield put(fetchStockDossierSuccess({ vehicleComponent: { updated: true } }))
          return
        }
        yield call(putDossierRemoveVehicle, dossierState.dossierId, auth.token, dossierState.lastAccessDate)
        yield put(restartStockDossierState())
        yield call(initializeDossier, dossierTypesConstant.stock, dossierState)
      }
    }
  } catch (error) {
    if (error.json && (error.json.tag === 'TRANS/cantRemoveVehicle' || error.json.tag === 'TRANS/noPermission')) {
      yield put(messageModalActions.openErrorMessageModal('', error.json.tag, ''))
    } else {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.VEHICLE_COMPONENT.UNKNOWN_ERROR', error.json ? error.json.tag : ''))
    }
  } finally {
    yield put(hideLoader())
  }
}

function changesSavedForVehicle (dossier) {
  return dossier.vehicleComponent.vehicleId
}

function * deleteVehicleFromStateForDossier (dossier) {
  yield put(reset(dossier, 'vehicleComponent'))
}

export function * watchClearVehicle () {
  yield takeEvery(vehicleActionsTypes.CLEAR_VEHICLE, clearVehicle)
}

function * createVehicleCreationModal ({ values, pristine }) {
  try {
    if (yield call(yesNoModal, 'createVehicleConfirmation')) {
      yield put(showLoader())
      const auth = yield select(getAuth)
      const dossierId = yield select(state => state.serviceDossier.dossierId)
      const vehicle = yield call(postVehicleFinderCreation, auth.token, dossierId, values)
      const dossierState = JSON.parse(JSON.stringify(yield select(state => state.serviceDossier)))
      yield put(change('service_dossier', 'vehicleComponent', vehicle))
      yield put(fetchServiceDossierSuccess({ vehicleComponent: vehicle.vehicleId ? vehicle : {} }))
      yield put(openVehicleFinderModal(false))
      yield put(restartServiceDossierState())
      yield call(initializeDossier, dossierTypesConstant.service, dossierState)
      yield put(messageModalActions.openSuccessMessageModal('MESSAGES.SERVICE_VEHICLE_FINDER_SUCCESS'))
    }
  } catch (error) {
    if (error.json && (error.json.tag === 'TRANS/cantRemoveVehicle' || error.json.tag === 'TRANS/noPermission')) {
      yield put(messageModalActions.openErrorMessageModal('', error.json.tag, ''))
    } else {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.VEHICLE_COMPONENT.UNKNOWN_ERROR', error.json ? error.json.tag : ''))
    }
  } finally {
    yield put(hideLoader())
  }
}

export function * watchCreateVehicleCreationModal () {
  yield takeEvery(vehicleActionsTypes.CREATE_VEHICLE_CREATION_MODAL, createVehicleCreationModal)
}
