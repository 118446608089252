import powerbiActionTypes from '../../constants/actions/reports/powerbiReport'

export function fetchPowerbiConfig () {
  return {
    type: powerbiActionTypes.FETCH_POWERBI_CONFIG
  }
}

export function fetchPowerbiConfigSuccess (data) {
  return {
    type: powerbiActionTypes.FETCH_POWERBI_CONFIG_SUCCESS,
    data
  }
}

export function powerBiRefreshSession () {
  return {
    type: powerbiActionTypes.POWERBI_REFRESH_SESSION
  }
}
