import { put, call, takeEvery, select } from 'redux-saga/effects'
import { reset } from 'redux-form'
import { replace } from 'connected-react-router'
import { getAuth } from '../../../../selectors/access/auth'
import { getSignState } from '../../../../selectors/sign/sign'
import { yesNoModal } from '../../../modalsListeners/yesNoModal'
import documentaryManagementModalActionsTypes from '../../../../constants/actions/dossier/common/documentEditionModal'
import { documentEntityType as documentEntityTypeConstant } from '../../../../constants/dossier/common/documentEntityType'
import { typeFields as typeFieldsConstant } from '../../../../constants/dossier/common/typeFields'
import { buttonsHeader } from '../../../../constants/dossier/common/buttonsHeader'
import { dossierTypes as dossierTypesConstant } from '../../../../constants/dossier/common/dossierTypes'
import messageModalActions from '../../../../actions/modals/messageModal'
import { showLoader, hideLoader, openModal } from '../../../../actions/common'
import { fetchCombosSuccess } from '../../../../actions/combos/combos'
import { fetchInitialNotificationsCounter } from '../../../../actions/notifications/notifications'
import { refreshDocumentManagementModal } from '../../../../actions/dossier/common/documentManagementModal'
import {
  openDocumentEditionModalSuccess,
  openDocumentCreationModalSuccess,
  openDocumentCreationModalFleetsSuccess,
  fetchDocumentSuccess,
  fetchDocumentFleetsSuccess,
  closeAddOrEditDocumentModalSuccess,
  changeId,
  setActivePage,
  closeAddOrEditDocumentModal,
  updateDocumentModalLastAccessDate,
  extractMetadataAction,
  updateMetaLoading,
  refreshDocResulSuccess,
  cancelSignMessageSuccess,
  openDocumentEditionModalSuccessFleets
} from '../../../../actions/dossier/common/documentEditionModal'
import { fetchSalesDossierSuccess, updateLastAccessDate } from '../../../../actions/dossier/sales'
import { fetchPurchaseDossierSuccess, refreshPurchasePresentFromDocumentModal } from '../../../../actions/dossier/purchase'
import { fetchPaperworkDossierSuccess } from '../../../../actions/dossier/paperwork'
import { fetchCampaignDossierSuccess } from '../../../../actions/dossier/campaign'
import { fetchRegistrationDeliveryDossierSuccess } from '../../../../actions/dossier/registrationDelivery'
import getDocumentsTypes from '../../../../services/document/getDocumentsTypes'
import getDocumentByTypeUse from '../../../../services/document/getDocumentByTypeUse'
import postDocumentService from '../../../../services/document/postDocument'
import postDocumentFormValuesService from '../../../../services/document/postDocumentFormValues'
import postDocumentFleetsService from '../../../../services/document/postDocumentFleets'
import getDocumentByType from '../../../../services/document/getDocumentByType'
import getDocumentByTypeFleets from '../../../../services/document/getDocumentByTypeFleets'
import getDocumentByIdService from '../../../../services/document/getDocument'
import getExtractMetada from '../../../../services/document/getExtractMetada'
import rejectDocumentService from '../../../../services/document/rejectDocument'
import validateDocumentService from '../../../../services/document/validateDocument'
// import getStageCheckList from '../../../../services/dossier/get/getStageCheckList'
// import getDossierSaleHeader from '../../../../services/dossierSale/getDossierSaleHeader'
import putDocumentFileService from '../../../../services/document/putDocumentFile'
import getDocumentVisor from '../../../../services/document/getDocumentVisor'
import getDocumentDownload from '../../../../services/document/getDocumentDownload'
import updateDocument from '../../../../services/document/updateDocument'
import updateDocumentFleets from '../../../../services/document/updateDocumentFleets'
import getMetadatoListItems from '../../../../services/document/getMetadatoListItems'
import { changeDocumentManagementChangedPilots, refreshDossierByType, refreshDossierByTypeLight } from './managementModalSagas'
import putRecoverDocument from '../../../../services/document/putDocumentRecover'
import getDocumentSheet from '../../../../services/documentSheet/getDocumentSheet'
import postDocumentSheetFileService from '../../../../services/documentSheet/postDocumentSheetFile'
import deleteDocumentSheetService from '../../../../services/documentSheet/deleteDocumentSheet'
import getDocumentSheetVisor from '../../../../services/documentSheet/getDocumentSheetVisor'
import getDocumentSheetDownload from '../../../../services/documentSheet/getDocumentSheetDownload'
import { handleError } from '../../../errors/errorManager'
import { getDossierStateByType, initializeDossier } from '../../commonDossierSagas'
import cancelSignService from '../../../../services/document/cancelSignService'
import requestSignService from '../../../../services/document/requestSignService'
import getDependenceMetadato from '../../../../services/document/getDependenceMetadato'
import getDocumentsTypesFleets from '../../../../services/documentTypeUse/getDocumentsTypesFleets'
import getDocumentInfoByChargeId from '../../../../services/document/getDocumentInfoByChargeId'
import getDocumentInfoByChargeIdParent from '../../../../services/document/getDocumentInfoByChargeIdParent'
import getCancelDocList from '../../../../services/document/getCancelDocList'
import getSignTypes from '../../../../services/document/getSignTypes'
import { fetchServiceDossierSuccess } from '../../../../actions/dossier/serviceDossier'
import { fetchStockDossierSuccess } from '../../../../actions/dossier/stockDossier'
import { searchDocuments, getDocumentsCount } from '../../../../actions/sign/sign'
import postSealDocument from '../../../../services/document/postSealDocument'
import { verifyLastAccessDate } from '../../../../util/utils'
import forceCancelSignService from '../../../../services/document/forceCancelSignService'
import postCreateAndSingDocumentFromDynamicTemplate from '../../../../services/document/postCreateAndSingDocumentFromDynamicTemplate'
import postGenerateDocumentFromDynamicTemplate from '../../../../services/document/postGenerateDocumentFromDynamicTemplate'
import { dossierSubTypes } from '../../../../constants/backendIds'
import { referenceTypes } from '../../../../constants/document/documentTypeUse'
import getDocumentByTypeUseByCode from '../../../../services/document/getDocumentByTypeUseByCode'
import getDocumentInfoByFleetChargeId from '../../../../services/document/getDocumentInfoByFleetChargeId'

function * getDocumentParentRelatedId (documentParentTypeId, dossierId, entityId, vehicleId, entityProviderId) {
  switch (documentParentTypeId) {
    case documentEntityTypeConstant.ENTITY:
      return entityId
    case documentEntityTypeConstant.VEHICLE:
      return vehicleId
    case documentEntityTypeConstant.DOSSIER:
      return dossierId
    case documentEntityTypeConstant.ENTITY_PROVIDER:
      return entityProviderId
    default:
      return null
  }
}

function * getMetadato (fields, documentId) {
  const auth = yield select(getAuth)
  for (let i = fields.length - 1; i >= 0; i--) {
    if (fields[i].typeField === typeFieldsConstant.list) {
      fields[i].options = yield call(getMetadatoListItems, auth.token, fields[i].fieldId, documentId)
    }
  }
  return fields
}

export function * openDocumentCreationModal ({ dossierId, documentParentTypeId, organizedUnitId, entityId, vehicleId, callback, refreshAfterChange, dossierType, pristine, isRecurrent, entityProviderId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const documentsTypes = yield call(getDocumentsTypes, auth.token, documentParentTypeId, dossierId, vehicleId, entityId)
    let dirty = false
    if (pristine !== null && pristine !== undefined) {
      dirty = !pristine
    }
    yield put(fetchCombosSuccess({ documentsTypes }))
    yield put(openDocumentCreationModalSuccess(documentParentTypeId, organizedUnitId, dossierId, entityId, vehicleId, callback, refreshAfterChange, dossierType, dirty, isRecurrent, entityProviderId))
  } catch (error) {
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('purchase_dossier'))
      yield put(reset('paperwork_dossier'))
      yield put(reset('campaign_dossier'))
      yield put(reset('service_dossier'))
      yield put(reset('stock_dossier'))
    }
    yield call(handleError, { error, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchOpenDocumentCreationModal () {
  yield takeEvery(documentaryManagementModalActionsTypes.OPEN_DOCUMENT_CREATION_MODAL, openDocumentCreationModal)
}

export function * openDocumentEditionModal ({
  documentId, documentTypeUse, documentParentTypeId, dossierId, entityId, vehicleId, organizedUnitId, checklistId, callback, refreshAfterChange, dossierType, dossierSubType, lastAccessDate, pristine, chargeId, isRecurrent = false, editable = true, readOnlyFields, fromTask, buttonRefund, chargeIdParent
  , referenceType, referenceId, refreshPurchasePresents, documentCode
}) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    var documentTypeUseInfo

    if (documentTypeUse === null && documentCode !== null) {
      documentTypeUseInfo = yield call(getDocumentByTypeUseByCode, documentCode, auth.token)
      documentTypeUse = documentTypeUseInfo?.id
    } else {
      documentTypeUseInfo = yield call(getDocumentByTypeUse, documentTypeUse, false, auth.token)
    }

    const documentParentRelatedId = yield call(getDocumentParentRelatedId, documentParentTypeId, dossierId, entityId, vehicleId)
    const docResult = documentId ? yield call(getDocumentByIdService, auth.token, documentId) : chargeId ? yield call(getDocumentInfoByChargeId, auth.token, chargeId) : yield call(getDocumentByType, auth.token, documentTypeUse, documentParentRelatedId, fromTask)

    docResult.fields = yield call(getMetadato, docResult.fields, documentId)
    if (documentId) {
      docResult.sheets = yield call(getDocumentSheet, auth.token, documentId)
      docResult.update = true
    }
    let dirty = false
    if (pristine !== null && pristine !== undefined) {
      dirty = !pristine
    }
    if (chargeId) {
      docResult.chargeId = chargeId
    }

    if (buttonRefund && chargeIdParent) {
      docResult.buttonRefund = true
      docResult.chargeIdParent = chargeIdParent
    }

    yield put(openDocumentEditionModalSuccess(docResult, documentTypeUseInfo, organizedUnitId, callback, dossierId, entityId, vehicleId, docResult.typeObject, checklistId, refreshAfterChange, dossierType, dossierSubType, (lastAccessDate || docResult.lastAccessDate), dirty, isRecurrent, editable, readOnlyFields, refreshPurchasePresents, referenceType, referenceId))
  } catch (error) {
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('purchase_dossier'))
      yield put(reset('paperwork_dossier'))
      yield put(reset('campaign_dossier'))
      yield put(reset('stock_dossier'))
    }
    yield call(handleError, { error, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchOpenDocumentEditionModal () {
  yield takeEvery(documentaryManagementModalActionsTypes.OPEN_DOCUMENT_EDITION_MODAL, openDocumentEditionModal)
}

export function * refreshDocumentInfo ({ documentId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const docResult = yield call(getDocumentByIdService, auth.token, documentId)
    docResult.sheets = yield call(getDocumentSheet, auth.token, documentId)
    docResult.update = true
    yield put(refreshDocResulSuccess(docResult))
  } catch (error) {
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('purchase_dossier'))
      yield put(reset('paperwork_dossier'))
      yield put(reset('campaign_dossier'))
      yield put(reset('stock_dossier'))
    }
    yield call(handleError, { error, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchrefreshDocumentInfo () {
  yield takeEvery(documentaryManagementModalActionsTypes.REFRESH_DOC_RESULT, refreshDocumentInfo)
}

export function * closeAddOrEditDocumentModalCallback () {
  try {
    yield put(showLoader())
    const documentEditionModal = yield select(state => state.documentEditionModal)
    yield put(closeAddOrEditDocumentModalSuccess())
    if (documentEditionModal.refreshAfterChange) {
      yield call(refreshDossierByType, {
        dossierType: documentEditionModal.dossierType,
        dossierDocumentChanged: documentEditionModal.documentParentTypeId === documentEntityTypeConstant.DOSSIER,
        entityDocumentChanged: documentEditionModal.documentParentTypeId === documentEntityTypeConstant.ENTITY,
        vehicleDocumentChanged: documentEditionModal.documentParentTypeId === documentEntityTypeConstant.VEHICLE,
        documentEditionalModalState: documentEditionModal
      })
      yield put(fetchInitialNotificationsCounter())
    }
  } catch (error) {
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('purchase_dossier'))
      yield put(reset('paperwork_dossier'))
      yield put(reset('campaign_dossier'))
      yield put(reset('stock_dossier'))
    }
    yield call(handleError, { error, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchCloseAddOrEditDocumentModalCallback () {
  yield takeEvery(documentaryManagementModalActionsTypes.CLOSE_ADD_OR_EDIT_DOCUMENT_MODAL, closeAddOrEditDocumentModalCallback)
}

export function * onDocumentTypeIdChange ({ documentTypeId, documentTypeUseName }) {
  try {
    if (!documentTypeId) {
      yield put(fetchDocumentSuccess({}))
    } else {
      yield put(showLoader())
      const documentEditionModal = yield select(state => state.documentEditionModal)
      const documentParentRelatedId = yield call(
        getDocumentParentRelatedId,
        documentEditionModal.documentParentTypeId,
        documentEditionModal.dossierId,
        documentEditionModal.entityId,
        documentEditionModal.vehicleId,
        documentEditionModal.entityProviderId
      )
      const auth = yield select(getAuth)
      const document = yield call(getDocumentByType, auth.token, documentTypeId, documentParentRelatedId)
      const documentTypeUseInfo = yield call(getDocumentByTypeUse, documentTypeId, true, auth.token)
      yield put(fetchDocumentSuccess(document, documentTypeUseInfo, documentTypeId, documentTypeUseName))
    }
  } catch (error) {
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('purchase_dossier'))
      yield put(reset('paperwork_dossier'))
      yield put(reset('campaign_dossier'))
      yield put(reset('service_dossier'))
      yield put(reset('stock_dossier'))
    }
    yield call(handleError, { error, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchOnDocumentTypeIdChange () {
  yield takeEvery(documentaryManagementModalActionsTypes.ON_DOCUMENT_TYPE_ID_CHANGE, onDocumentTypeIdChange)
}

export function * rejectDocument ({ documentId, rejectMessage, resolve, reject }) {
  const documentEditionModal = yield select(state => state.documentEditionModal)
  const referenceType = documentEditionModal?.referenceType
  const referenceId = documentEditionModal?.referenceId
  let hasDossierState = false
  let dossierState
  try {
    yield put(showLoader())
    if (documentEditionModal.dossierType) {
      hasDossierState = true
      dossierState = yield call(getDossierStateByType, documentEditionModal.dossierType)
    } else {
      dossierState = documentEditionModal
    }
    let date = verifyLastAccessDate(dossierState.lastAccessDate)
    const auth = yield select(getAuth)
    yield call(rejectDocumentService, auth.token, documentId, rejectMessage, date)
    if (documentEditionModal.callback) yield call(documentEditionModal.callback, documentEditionModal.entityId)
    if (date) {
      yield put(updateDocumentModalLastAccessDate(date))
    }
    yield call(refreshDossierByType, {
      dossierType: documentEditionModal.dossierType,
      dossierDocumentChanged: true, // documentEditionModal.documentParentTypeId === documentEntityTypeConstant.DOSSIER,
      entityDocumentChanged: documentEditionModal.documentParentTypeId === documentEntityTypeConstant.ENTITY,
      vehicleDocumentChanged: documentEditionModal.documentParentTypeId === documentEntityTypeConstant.VEHICLE,
      documentEditionalModalState: documentEditionModal
    })
    if (!!referenceId && documentEditionModal.refreshPurchasePresents &&
      (referenceType === referenceTypes.INVOICE_DOCUMENT_ID || referenceType === referenceTypes.ORDER_DOCUMENT_ID)) {
      yield put(refreshPurchasePresentFromDocumentModal(referenceId, !documentEditionModal.dirty))
    }
    yield put(closeAddOrEditDocumentModalSuccess())
    yield put(fetchInitialNotificationsCounter())
    yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          yield put(closeAddOrEditDocumentModalSuccess())
          if (hasDossierState) yield call(initializeDossier, documentEditionModal.dossierType, dossierState)
        }
      } else {
        if (customHandlerError.json.tag === 'signStatusPending' || customHandlerError.json.tag === 'TRANS/signStatusPending') {
          yield put(openModal('signStatusPending'))
        } else {
          yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
        }
      }
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('purchase_dossier'))
      yield put(reset('paperwork_dossier'))
      yield put(reset('campaign_dossier'))
      yield put(reset('stock_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchRejectDocument () {
  yield takeEvery(documentaryManagementModalActionsTypes.REJECT_DOCUMENT, rejectDocument)
}

export function * createDocument ({ file, checklistId, documentTypeUseId, dossierId, resolve, chargeId, parentChargeId, fromTemplate = false, formValues = null }) {
  const documentEditionModal = yield select(state => state.documentEditionModal)
  const referenceType = documentEditionModal?.referenceType
  const referenceId = documentEditionModal?.referenceId
  let hasDossierState = false
  let dossierState
  try {
    yield put(showLoader())
    if (documentEditionModal.dossierType) {
      hasDossierState = true
      dossierState = yield call(getDossierStateByType, documentEditionModal.dossierType)
    } else {
      dossierState = documentEditionModal
    }
    const auth = yield select(getAuth)
    const id = documentEditionModal.docResult?.data?.id || (yield call(
      getDocumentParentRelatedId,
      documentEditionModal.documentParentTypeId,
      documentEditionModal.dossierId,
      documentEditionModal.entityId,
      documentEditionModal.vehicleId
    ))

    let date
    if (!dossierState?.lastAccessDate && !dossierId) {
      const today = new Date()
      date = today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear()
      date += ' ' + today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds() + ' ' + today.getMilliseconds()
    } else {
      date = dossierState?.lastAccessDate ?? new Date()
    }

    let response
    if (formValues) {
      response = yield call(
        postDocumentFormValuesService,
        documentEditionModal.organizedUnitId,
        documentTypeUseId,
        auth.token,
        id,
        checklistId,
        dossierId,
        date,
        chargeId,
        referenceType,
        referenceId,
        formValues
      )
    } else {
      response = yield call(
        postDocumentService,
        documentEditionModal.organizedUnitId,
        documentTypeUseId,
        auth.token,
        file,
        id,
        checklistId,
        dossierId,
        date,
        chargeId,
        fromTemplate,
        referenceType,
        referenceId
      )
    }
    yield put(messageModalActions.openSuccessMessageModal('MESSAGES.DOCUMENT_MANAGEMENT.POST_DOCUMENT_SUCCESS'))
    yield call(changeDocumentManagementChangedPilots, documentEditionModal.documentParentTypeId)
    yield put(changeId(response.id))

    let document
    if (!resolve) {
      if (parentChargeId) {
        document = yield call(getDocumentInfoByChargeIdParent, auth.token, parentChargeId, response.documentId, response.storageDocumentId)
      } else {
        document = yield call(getDocumentByIdService, auth.token, response.documentId, documentEditionModal.organizedUnitId)
      }
      document.fields = yield call(getMetadato, document.fields, response.documentId)
      document.update = true

      yield put(fetchDocumentSuccess(document))
    }

    if (documentEditionModal && documentEditionModal.docResult && documentEditionModal.docResult.buttonsPermission &&
      documentEditionModal.docResult.buttonsPermission.buttonGetMetadata) {
      if (document === null || document === undefined) {
        document = yield call(getDocumentByIdService, auth.token, response.documentId)
      }
      yield put(extractMetadataAction(JSON.parse(JSON.stringify(document)), documentEditionModal.organizedUnitId))
    }

    if (referenceId && documentEditionModal.refreshPurchasePresents &&
      (referenceType === referenceTypes.INVOICE_DOCUMENT_ID || referenceType === referenceTypes.ORDER_DOCUMENT_ID)) {
      yield put(refreshPurchasePresentFromDocumentModal(referenceId, !documentEditionModal.dirty))
    }
    if (response.lastAccessDate) {
      yield put(updateDocumentModalLastAccessDate(response.lastAccessDate))
      switch (documentEditionModal.dossierType) {
        case dossierTypesConstant.sales:
          yield put(fetchSalesDossierSuccess({ lastAccessDate: response.lastAccessDate }))
          break
        case dossierTypesConstant.purchase:
          yield put(fetchPurchaseDossierSuccess({ lastAccessDate: response.lastAccessDate }))
          break
        case dossierTypesConstant.paperwork:
          yield put(fetchPaperworkDossierSuccess({ lastAccessDate: response.lastAccessDate }))
          break
        case dossierTypesConstant.campaign:
          yield put(fetchCampaignDossierSuccess({ lastAccessDate: response.lastAccessDate }))
          break
        case dossierTypesConstant.registrationDelivery:
          yield put(fetchRegistrationDeliveryDossierSuccess({ lastAccessDate: response.lastAccessDate }))
          break
        case dossierTypesConstant.service:
          yield put(fetchServiceDossierSuccess({ lastAccessDate: response.lastAccessDate }))
          break
        case dossierTypesConstant.stock:
          yield put(fetchStockDossierSuccess({ lastAccessDate: response.lastAccessDate }))
          break
      }
    }
    const entityId = yield select(state => state.documentEditionModal.entityId)
    if (documentEditionModal.callback) yield call(documentEditionModal.callback, entityId)
    if (documentEditionModal.dossierType === dossierSubTypes.stock) {
      yield call(initializeDossier, dossierTypesConstant.stock, yield select(state => state.stockDossier))
    }
    if (fromTemplate) {
      yield call(refreshDossierByTypeLight, {
        dossierType: documentEditionModal.dossierType,
        dossierDocumentChanged: true, // documentEditionModal.documentParentTypeId === documentEntityTypeConstant.DOSSIER,
        entityDocumentChanged: false, // documentEditionModal.documentParentTypeId === documentEntityTypeConstant.ENTITY,
        vehicleDocumentChanged: false, // documentEditionModal.documentParentTypeId === documentEntityTypeConstant.VEHICLE
        documentEditionalModalState: documentEditionModal
      })
    }
    if (resolve) resolve(response)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          yield put(closeAddOrEditDocumentModalSuccess())
          if (hasDossierState) yield call(initializeDossier, documentEditionModal.dossierType, dossierState)
        }
      } else {
        if (customHandlerError.json && documentEditionModal.documentParentTypeId === documentEntityTypeConstant.VEHICLE && customHandlerError.json.tag === 'GenericError') {
          customHandlerError.json.tag = 'TRANS/vehicleRequired'
        }
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('purchase_dossier'))
      yield put(reset('paperwork_dossier'))
      yield put(reset('campaign_dossier'))
      yield put(reset('registrationDeliveryDossier'))
      yield put(reset('service_dossier'))
      yield put(reset('stock_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchCreateDocument () {
  yield takeEvery(documentaryManagementModalActionsTypes.CREATE_DOCUMENT, createDocument)
}

export function * uploadDocument ({ file, documentTypeId }) {
  const documentEditionModal = yield select(state => state.documentEditionModal)
  let hasDossierState = false
  let dossierState
  try {
    yield put(showLoader())
    if (documentEditionModal.dossierType) {
      hasDossierState = true
      dossierState = yield call(getDossierStateByType, documentEditionModal.dossierType)
    } else {
      dossierState = documentEditionModal
    }
    const auth = yield select(getAuth)
    let date = verifyLastAccessDate(dossierState.lastAccessDate)
    yield call(
      putDocumentFileService,
      documentEditionModal.docResult.documentId,
      documentEditionModal.organizedUnitId,
      documentEditionModal.dossierId,
      auth.token,
      file,
      date
    )
    yield put(messageModalActions.openSuccessMessageModal('MESSAGES.DOCUMENT_MANAGEMENT.POST_DOCUMENT_SUCCESS'))
    const document = yield call(getDocumentByIdService, auth.token, documentEditionModal.docResult.documentId)
    yield call(changeDocumentManagementChangedPilots, documentEditionModal.documentParentTypeId)
    document.fields = yield call(getMetadato, document.fields, documentEditionModal.docResult.documentId)
    yield put(fetchDocumentSuccess(document))
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          yield put(closeAddOrEditDocumentModalSuccess())
          if (hasDossierState) yield call(initializeDossier, documentEditionModal.dossierType, dossierState)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('MESSAGES.DOCUMENT_MANAGEMENT.POST_DOCUMENT_ERROR', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('purchase_dossier'))
      yield put(reset('paperwork_dossier'))
      yield put(reset('campaign_dossier'))
      yield put(reset('registrationDeliveryDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchUploadDocument () {
  yield takeEvery(documentaryManagementModalActionsTypes.UPLOAD_DOCUMENT, uploadDocument)
}

export function * saveDocument ({ fields, comment, fleetsVisor }) {
  const documentEditionModal = yield select(state => state.documentEditionModal)
  let hasDossierState = false
  let dossierState
  let documentSaveResult
  try {
    yield put(showLoader())
    if (documentEditionModal.dossierType) {
      hasDossierState = true
      dossierState = yield call(getDossierStateByType, documentEditionModal.dossierType)
    } else {
      dossierState = documentEditionModal
    }
    const auth = yield select(getAuth)
    const objectId = yield call(
      getDocumentParentRelatedId,
      documentEditionModal.documentParentTypeId,
      documentEditionModal.dossierId,
      documentEditionModal.entityId,
      documentEditionModal.vehicleId,
      documentEditionModal.entityProviderId
    )

    const infoIncome = {
      documentTypeId: documentEditionModal.documentTypeUseId,
      objectId,
      fields,
      comment,
      dossierId: documentEditionModal.dossierId,
      paperworkTypeId: yield select(state => state.paperworkDossier.dossierPaperworkComponent.paperworkTypeId)
    }
    let date = verifyLastAccessDate(dossierState?.lastAccessDate)

    if (!fleetsVisor) {
      documentSaveResult = yield call(updateDocument,
        documentEditionModal.docResult.documentId,
        documentEditionModal.organizedUnitId,
        infoIncome,
        auth.token,
        date
      )
    } else {
      documentSaveResult = yield call(updateDocumentFleets,
        documentEditionModal.docResult.documentId,
        documentEditionModal.organizedUnitId,
        infoIncome,
        auth.token,
        date
      )
    }

    yield put(messageModalActions.openSuccessMessageModal('MESSAGES.DOCUMENT_MANAGEMENT.PUT_DOCUMENT_SUCCESS'))
    yield put(closeAddOrEditDocumentModalSuccess())
    yield put(fetchInitialNotificationsCounter())
    if (documentEditionModal.dossierType === dossierTypesConstant.stock) {
      yield call(initializeDossier, dossierTypesConstant.stock, yield select(state => state.stockDossier))
    }
    if (documentEditionModal.dossierType === dossierSubTypes.stock) {
      yield call(initializeDossier, dossierTypesConstant.stock, yield select(state => state.stockDossier))
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          yield put(closeAddOrEditDocumentModalSuccess())
          if (hasDossierState) yield call(initializeDossier, documentEditionModal.dossierType, dossierState)
        }
      } else if (customHandlerError.json && (customHandlerError.json.tag === 'cannotSaveEntity' || customHandlerError.json.tag === 'TRANS/cannotSaveEntity')) {
        yield put(messageModalActions.openErrorMessageModal('MESSAGES.DOCUMENT_MANAGEMENT.ERROR_DNI'))
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('purchase_dossier'))
      yield put(reset('paperwork_dossier'))
      yield put(reset('campaign_dossier'))
      yield put(reset('registrationDeliveryDossier'))
      yield put(reset('stock_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
  if (documentEditionModal.refreshAfterChange) {
    if (documentEditionModal.callback) yield call(documentEditionModal.callback, documentEditionModal.entityId)
    if (documentSaveResult && documentSaveResult.reloadDossier) {
      yield call(refreshDossierByType, {
        dossierType: documentEditionModal.dossierType,
        dossierDocumentChanged: true, // documentEditionModal.documentParentTypeId === documentEntityTypeConstant.DOSSIER,
        entityDocumentChanged: documentEditionModal.documentParentTypeId === documentEntityTypeConstant.ENTITY,
        vehicleDocumentChanged: documentEditionModal.documentParentTypeId === documentEntityTypeConstant.VEHICLE,
        documentEditionalModalState: documentEditionModal
      })
    } else {
      if (documentSaveResult) yield put(updateLastAccessDate(documentSaveResult.lastAccessDate))
      yield call(refreshDossierByTypeLight, {
        dossierType: documentEditionModal.dossierType,
        dossierDocumentChanged: true, // documentEditionModal.documentParentTypeId === documentEntityTypeConstant.DOSSIER,
        entityDocumentChanged: false, // documentEditionModal.documentParentTypeId === documentEntityTypeConstant.ENTITY,
        vehicleDocumentChanged: false, // documentEditionModal.documentParentTypeId === documentEntityTypeConstant.VEHICLE
        documentEditionalModalState: documentEditionModal
      })
    }
  } else {
    yield call(changeDocumentManagementChangedPilots, documentEditionModal.documentParentTypeId)
  }
}

export function * watchSaveDocument () {
  yield takeEvery(documentaryManagementModalActionsTypes.SAVE_DOCUMENT, saveDocument)
}

export function * validateDocument ({ documentId, fields, comment, isRecurrent }) {
  const documentEditionModal = yield select(state => state.documentEditionModal)
  const referenceType = documentEditionModal?.referenceType
  const referenceId = documentEditionModal?.referenceId
  let hasDossierState = false
  let dossierState

  try {
    yield put(showLoader())
    if (documentEditionModal.dossierType) {
      hasDossierState = true
      dossierState = yield call(getDossierStateByType, documentEditionModal.dossierType)
    } else {
      dossierState = documentEditionModal
    }
    const auth = yield select(getAuth)
    const objectId = yield call(
      getDocumentParentRelatedId,
      documentEditionModal.documentParentTypeId,
      documentEditionModal.dossierId,
      documentEditionModal.entityId,
      documentEditionModal.vehicleId,
      documentEditionModal.entityProviderId
    )
    const infoIncome = {
      documentTypeId: documentEditionModal.documentTypeUseId,
      objectId,
      fields,
      comment,
      isRecurrent
    }
    let date = verifyLastAccessDate(dossierState.lastAccessDate)
    const documentValidationResult = yield call(validateDocumentService, auth.token, documentId, documentEditionModal.organizedUnitId, infoIncome, date)
    date = documentValidationResult.lastAccessDate
    yield put(messageModalActions.openSuccessMessageModal('MESSAGES.DOCUMENT_MANAGEMENT.VALIDATE_DOCUMENT_SUCCESS'))
    if (documentEditionModal.refreshAfterChange) {
      if (documentEditionModal.callback) yield call(documentEditionModal.callback, documentEditionModal.entityId)
      if (documentValidationResult.dossierCancelled !== null && documentValidationResult.dossierCancelled !== '' && documentValidationResult.dossierCancelled !== undefined) {
        yield put(replace('/dossier' + documentValidationResult.dossierCancelled))
      } else {
        if (documentValidationResult.reloadDossier) {
          yield call(refreshDossierByType, {
            dossierType: documentEditionModal.dossierType,
            dossierDocumentChanged: true, // documentEditionModal.documentParentTypeId === documentEntityTypeConstant.DOSSIER,
            entityDocumentChanged: documentEditionModal.documentParentTypeId === documentEntityTypeConstant.ENTITY,
            vehicleDocumentChanged: documentEditionModal.documentParentTypeId === documentEntityTypeConstant.VEHICLE,
            documentEditionalModalState: documentEditionModal
          })
        } else {
          yield put(updateLastAccessDate(documentValidationResult.lastAccessDate))
          yield call(refreshDossierByTypeLight, {
            dossierType: documentEditionModal.dossierType,
            dossierDocumentChanged: true, // documentEditionModal.documentParentTypeId === documentEntityTypeConstant.DOSSIER,
            entityDocumentChanged: false, // documentEditionModal.documentParentTypeId === documentEntityTypeConstant.ENTITY,
            vehicleDocumentChanged: false, // documentEditionModal.documentParentTypeId === documentEntityTypeConstant.VEHICLE
            documentEditionalModalState: documentEditionModal
          })
        }
      }
    } else {
      yield call(changeDocumentManagementChangedPilots, documentEditionModal.documentParentTypeId)
    }
    if (!!referenceId && documentEditionModal.refreshPurchasePresents &&
      (referenceType === referenceTypes.INVOICE_DOCUMENT_ID || referenceType === referenceTypes.ORDER_DOCUMENT_ID)) {
      yield put(refreshPurchasePresentFromDocumentModal(referenceId, !documentEditionModal.dirty))
    }
    yield put(closeAddOrEditDocumentModalSuccess())
    yield put(fetchInitialNotificationsCounter())
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          yield put(closeAddOrEditDocumentModalSuccess())
          if (hasDossierState) yield call(initializeDossier, documentEditionModal.dossierType, dossierState)
        }
      } else if (customHandlerError.json.tag === 'cannotSaveEntity' || customHandlerError.json.tag === 'TRANS/cannotSaveEntity') {
        yield put(messageModalActions.openErrorMessageModal('MESSAGES.DOCUMENT_MANAGEMENT.ERROR_DNI'))
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('purchase_dossier'))
      yield put(reset('paperwork_dossier'))
      yield put(reset('campaign_dossier'))
      yield put(reset('registrationDeliveryDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchValidateDocument () {
  yield takeEvery(documentaryManagementModalActionsTypes.VALIDATE_DOCUMENT, validateDocument)
}

export function * toggleFullScreenDocumentModal ({ documentId, isDownload, organizedUnitId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    let documentUrl = null
    if (isDownload) {
      documentUrl = yield call(getDocumentDownload, documentId, organizedUnitId, auth.token)
    } else {
      documentUrl = yield call(getDocumentVisor, documentId, auth.token)
    }
    if (documentUrl) {
      window.open(documentUrl.value)
    }
  } catch (error) {
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('purchase_dossier'))
      yield put(reset('paperwork_dossier'))
      yield put(reset('campaign_dossier'))
      yield put(reset('registrationDeliveryDossier'))
    }
    yield call(handleError, { error, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchToggleFullScreenDocumentModal () {
  yield takeEvery(documentaryManagementModalActionsTypes.TOGGLE_FULL_SCREEN_DOCUMENT_MODAL, toggleFullScreenDocumentModal)
}

export function * recoverDocument ({ documentId, docResult, documentTypeUse, organizedUnitId, callback, dossierId, entityId, vehicleId, documentParentTypeId, checklistId, refreshAfterChange, dossierType, dossierSubType, isRecurrent = false }) {
  const documentEditionModal = yield select(state => state.documentEditionModal)
  let hasDossierState = false
  let dossierState
  try {
    yield put(showLoader())
    if (documentEditionModal.dossierType) {
      hasDossierState = true
      dossierState = yield call(getDossierStateByType, documentEditionModal.dossierType)
    } else {
      dossierState = documentEditionModal
    }
    const auth = yield select(getAuth)
    let date
    if (!dossierState.lastAccessDate && !dossierId) {
      const today = new Date()
      date = today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear()
      date += ' ' + today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds() + ' ' + today.getMilliseconds()
    } else {
      date = dossierState.lastAccessDate
    }
    yield call(putRecoverDocument, documentId, auth.token, date)
    const documentTypeUseInfo = yield call(getDocumentByTypeUse, documentTypeUse, false, auth.token)
    const docResult = yield call(getDocumentByIdService, auth.token, documentId)
    docResult.fields = yield call(getMetadato, docResult.fields, documentId)
    docResult.sheets = yield call(getDocumentSheet, auth.token, documentId)
    docResult.update = true
    let dirty = false
    let todayLastUpdate = new Date()
    date = todayLastUpdate.getDate() + '/' + (todayLastUpdate.getMonth() + 1) + '/' + todayLastUpdate.getFullYear()
    date += ' ' + todayLastUpdate.getHours() + ':' + todayLastUpdate.getMinutes() + ':' + todayLastUpdate.getSeconds() + ' ' + todayLastUpdate.getMilliseconds()
    yield put(openDocumentEditionModalSuccess(docResult, documentTypeUseInfo, organizedUnitId, callback, dossierId, entityId, vehicleId, documentParentTypeId, checklistId, refreshAfterChange, dossierType, dossierSubType, date, dirty, isRecurrent, true))
    if (callback) yield call(callback, entityId)
    if (refreshAfterChange) {
      yield call(refreshDossierByType, {
        dossierType: dossierType,
        dossierDocumentChanged: true, // documentParentTypeId === documentEntityTypeConstant.DOSSIER,
        entityDocumentChanged: documentParentTypeId === documentEntityTypeConstant.ENTITY,
        vehicleDocumentChanged: documentParentTypeId === documentEntityTypeConstant.VEHICLE,
        documentEditionalModalState: documentEditionModal
      })
      if (!isRecurrent && !callback) yield put(refreshDocumentManagementModal(dossierId))
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          yield put(closeAddOrEditDocumentModalSuccess())
          if (hasDossierState) yield call(initializeDossier, dossierType, dossierState)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('purchase_dossier'))
      yield put(reset('paperwork_dossier'))
      yield put(reset('campaign_dossier'))
      yield put(reset('registrationDeliveryDossier'))
      yield put(reset('service_dossier'))
      yield put(reset('stock_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchRecoverDocument () {
  yield takeEvery(documentaryManagementModalActionsTypes.RECOVER_DOCUMENT, recoverDocument)
}

export function * createDocumentSheet ({ file, organizedUnitId, documentId }) {
  const documentEditionModal = yield select(state => state.documentEditionModal)
  let hasDossierState = false
  let dossierState
  try {
    yield put(showLoader())
    if (documentEditionModal.dossierType) {
      hasDossierState = true
      dossierState = yield call(getDossierStateByType, documentEditionModal.dossierType)
    } else {
      dossierState = documentEditionModal
    }
    const auth = yield select(getAuth)
    yield call(
      postDocumentSheetFileService,
      documentId !== undefined ? documentId : documentEditionModal.docResult.documentId,
      auth.token,
      file,
      dossierState.lastAccessDate
    )
    yield put(messageModalActions.openSuccessMessageModal('MESSAGES.DOCUMENT_MANAGEMENT.POST_DOCUMENT_SHEET_SUCCESS'))
    const document = yield call(getDocumentByIdService, auth.token, documentId !== undefined ? documentId : documentEditionModal.docResult.documentId, documentEditionModal.organizedUnitId)
    document.fields = yield call(getMetadato, document.fields, documentId !== undefined ? documentId : documentEditionModal.docResult.documentId)
    document.sheets = yield call(getDocumentSheet, auth.token, documentId !== undefined ? documentId : documentEditionModal.docResult.documentId)
    yield put(fetchDocumentSuccess(document))
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          yield put(closeAddOrEditDocumentModalSuccess())
          if (hasDossierState) yield call(initializeDossier, documentEditionModal.dossierType, dossierState)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('MESSAGES.DOCUMENT_MANAGEMENT.POST_DOCUMENT_SHEET_ERROR', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('purchase_dossier'))
      yield put(reset('paperwork_dossier'))
      yield put(reset('campaign_dossier'))
      yield put(reset('registrationDeliveryDossier'))
      yield put(reset('stock_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchCreateDocumentSheet () {
  yield takeEvery(documentaryManagementModalActionsTypes.CREATE_DOCUMENT_SHEET, createDocumentSheet)
}

export function * deleteDocumentSheet ({ documentId, sheetId, resolve, reject, organizedUnitId }) {
  const documentEditionModal = yield select(state => state.documentEditionModal)
  let hasDossierState = false
  let dossierState
  try {
    const confirmed = yield call(yesNoModal, 'deleteSheet')
    if (confirmed) {
      yield put(showLoader())
      if (documentEditionModal.dossierType) {
        hasDossierState = true
        dossierState = yield call(getDossierStateByType, documentEditionModal.dossierType)
      } else {
        dossierState = documentEditionModal
      }
      const auth = yield select(getAuth)
      yield call(
        deleteDocumentSheetService,
        documentId,
        sheetId,
        auth.token,
        dossierState.lastAccessDate
      )
      yield put(messageModalActions.openSuccessMessageModal('MESSAGES.DOCUMENT_MANAGEMENT.DELETE_DOCUMENT_SHEET_SUCCESS'))
      const document = yield call(getDocumentByIdService, auth.token, documentEditionModal.docResult.documentId)
      document.fields = yield call(getMetadato, document.fields, documentEditionModal.docResult.documentId)
      document.sheets = yield call(getDocumentSheet, auth.token, documentEditionModal.docResult.documentId)
      yield put(fetchDocumentSuccess(document))
      if (documentEditionModal.activeSheet === sheetId) yield put(setActivePage(0, 1))
      if (resolve) yield call(resolve)
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          yield put(closeAddOrEditDocumentModalSuccess())
          if (hasDossierState) yield call(initializeDossier, documentEditionModal.dossierType, dossierState)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('MESSAGES.DOCUMENT_MANAGEMENT.DELETE_DOCUMENT_SHEET_ERROR', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
      if (reject) yield call(reject)
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('purchase_dossier'))
      yield put(reset('paperwork_dossier'))
      yield put(reset('campaign_dossier'))
      yield put(reset('_dossier'))
      yield put(reset('registrationDeliveryDossier'))
      yield put(reset('stock_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDeleteDocumentSheet () {
  yield takeEvery(documentaryManagementModalActionsTypes.DELETE_DOCUMENT_SHEET, deleteDocumentSheet)
}

export function * toggleFullScreenDocumentSheetModal ({ documentId, sheetId, isDownload }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    if (isDownload) {
      yield call(getDocumentSheetDownload, documentId, sheetId, auth.token)
    } else {
      const documentUrl = yield call(getDocumentSheetVisor, documentId, sheetId, auth.token)
      window.open(documentUrl.value)
    }
  } catch (error) {
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('purchase_dossier'))
      yield put(reset('paperwork_dossier'))
      yield put(reset('campaign_dossier'))
      yield put(reset('registrationDeliveryDossier'))
      yield put(reset('stock_dossier'))
    }
    yield call(handleError, { error, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchToggleFullScreenDocumentSheetModal () {
  yield takeEvery(documentaryManagementModalActionsTypes.TOGGLE_FULL_SCREEN_DOCUMENT_SHEET_MODAL, toggleFullScreenDocumentSheetModal)
}

export function * checkCanCloseDocumentModal ({ showAdvise, resolve }) {
  if (showAdvise) {
    const acceptCloseModal = yield call(yesNoModal, 'commonDossierSaveWarning')
    if (resolve) resolve(acceptCloseModal)
  } else {
    resolve(true)
  }
}

export function * watchCheckCanCloseDocumentModal () {
  yield takeEvery(documentaryManagementModalActionsTypes.CHECK_CAN_CLOSE_DOCUMENT_MODAL, checkCanCloseDocumentModal)
}

export function * openDocumentCreationModalFleets ({ dossierIds, documentParentTypeId, organizedUnitId, entityId, vehicleId, callback, refreshAfterChange, dossierType }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const documentsTypes = yield call(getDocumentsTypesFleets, auth.token)
    yield put(fetchCombosSuccess({ documentsTypes }))
    yield put(openDocumentCreationModalFleetsSuccess(organizedUnitId, dossierIds, callback, refreshAfterChange))
  } catch (error) {
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('purchase_dossier'))
      yield put(reset('paperwork_dossier'))
      yield put(reset('campaign_dossier'))
      yield put(reset('registrationDeliveryDossier'))
      yield put(reset('stock_dossier'))
    }
    yield call(handleError, { error, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchOpenDocumentCreationModalFleets () {
  yield takeEvery(documentaryManagementModalActionsTypes.OPEN_DOCUMENT_CREATION_MODAL_FLEETS, openDocumentCreationModalFleets)
}

export function * onDocumentTypeIdChangeFleets ({ documentTypeId, documentTypeUseName, dossierIds }) {
  try {
    if (!documentTypeId) {
      yield put(fetchDocumentFleetsSuccess({}))
    } else {
      yield put(showLoader())
      const auth = yield select(getAuth)
      const response = yield call(getDocumentByTypeFleets, auth.token, documentTypeId, dossierIds)
      if (response.dossiersNumberWithDocument.length > 0) {
        yield put(hideLoader())
        const confirmation = yield call(yesNoModal, 'dossiersWithDocument', null, null, null, null, [{ id: '##DOSSIERS##', value: response.dossiersNumberWithDocument }])
        if (!confirmation) {
          yield put(closeAddOrEditDocumentModal())
        }
      }
      yield put(fetchDocumentFleetsSuccess(response, documentTypeId, documentTypeUseName, dossierIds))
    }
  } catch (error) {
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('purchase_dossier'))
      yield put(reset('paperwork_dossier'))
      yield put(reset('campaign_dossier'))
      yield put(reset('registrationDeliveryDossier'))
      yield put(reset('stock_dossier'))
    }
    yield call(handleError, { error, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchOnDocumentTypeIdChangeFleets () {
  yield takeEvery(documentaryManagementModalActionsTypes.ON_DOCUMENT_TYPE_ID_CHANGE_FLEETS, onDocumentTypeIdChangeFleets)
}

export function * createDocumentFleets ({ file, checklistId, documentTypeUseId, dossierIds }) {
  const documentEditionModal = yield select(state => state.documentEditionModal)
  let dossierState
  try {
    yield put(showLoader())
    if (documentEditionModal.dossierType) {
      dossierState = yield call(getDossierStateByType, documentEditionModal.dossierType)
    } else {
      dossierState = documentEditionModal
    }
    const auth = yield select(getAuth)
    let date = verifyLastAccessDate(dossierState.lastAccessDate)
    const response = yield call(
      postDocumentFleetsService,
      documentTypeUseId,
      auth.token,
      file,
      checklistId,
      dossierIds,
      date
    )
    yield put(messageModalActions.openSuccessMessageModal('MESSAGES.DOCUMENT_MANAGEMENT.POST_DOCUMENT_SUCCESS'))
    yield call(changeDocumentManagementChangedPilots, documentEditionModal.documentParentTypeId)
    yield put(changeId(response.id))
    const document = yield call(getDocumentByIdService, auth.token, response.documentId)
    document.fields = yield call(getMetadato, document.fields, response.documentId)
    yield put(fetchDocumentSuccess(document))
    const entityId = yield select(state => state.documentEditionModal.entityId)
    if (documentEditionModal.callback) yield call(documentEditionModal.callback, entityId)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('purchase_dossier'))
      yield put(reset('paperwork_dossier'))
      yield put(reset('campaign_dossier'))
      yield put(reset('registrationDeliveryDossier'))
      yield put(reset('stock_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchCreateDocumentFleets () {
  yield takeEvery(documentaryManagementModalActionsTypes.CREATE_DOCUMENT_FLEETS, createDocumentFleets)
}
export function * cancelSign ({ documentId, sheetId, callback, resolve, multipleSignCancel, forceCancelDocument }) {
  try {
    const stateDocModal = yield select(state => state.documentEditionModal)
    yield put(showLoader())
    const auth = yield select(getAuth)
    const signState = yield select(getSignState)
    yield call(cancelSignService, documentId, sheetId, auth.token, multipleSignCancel, stateDocModal.cancelMessage)
    if (window.location.href.includes('/iDocSign')) {
      yield put(searchDocuments(signState.filters))
    } else {
      yield put(getDocumentsCount())
    }
    const docModal = yield select(state => state.documentEditionModal)
    const buttonsHeaderState = yield select(state => state.buttonsHeader)
    if (docModal.dossierId && buttonsHeaderState.activeTab === buttonsHeader.gestor_documental) {
      yield put(refreshDocumentManagementModal(docModal.dossierId))
    }
    yield put(closeAddOrEditDocumentModalSuccess())
    yield put(messageModalActions.openSuccessMessageModal('MESSAGES.DOCUMENT_MANAGEMENT.CANCEL_SIGN_DOCUMENT'))
  } catch (error) {
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('purchase_dossier'))
      yield put(reset('paperwork_dossier'))
      yield put(reset('campaign_dossier'))
      yield put(reset('registrationDeliveryDossier'))
      yield put(reset('stock_dossier'))
    }
    yield call(handleError, { error, sessionHandler })
    if ((error?.json?.tag === 'cancelSignSignaturitError' || error?.json?.tag === 'sendCancelRequestFail') && forceCancelDocument) {
      yield put(hideLoader())
      const stateDocModal = yield select(state => state.documentEditionModal)
      const confirmed = yield call(yesNoModal, 'confirmForceCancelDocument',
        null, null, null, null, [{ id: '##REASON##', value: stateDocModal.cancelMessage }], null)
      if (confirmed) {
        yield put(showLoader())
        const auth = yield select(getAuth)
        yield call(forceCancelSignService, documentId, sheetId, auth.token, multipleSignCancel, stateDocModal.cancelMessage)
        const signState = yield select(getSignState)
        if (window.location.href.includes('/iDocSign')) {
          yield put(searchDocuments(signState.filters))
        } else {
          yield put(getDocumentsCount())
        }
        const docModal = yield select(state => state.documentEditionModal)
        const buttonsHeaderState = yield select(state => state.buttonsHeader)
        if (docModal.dossierId && buttonsHeaderState.activeTab === buttonsHeader.gestor_documental) {
          yield put(refreshDocumentManagementModal(docModal.dossierId))
        }
        yield put(closeAddOrEditDocumentModalSuccess())
        yield put(messageModalActions.openSuccessMessageModal('MESSAGES.DOCUMENT_MANAGEMENT.CANCEL_SIGN_DOCUMENT'))
      }
    }
  } finally {
    yield put(hideLoader())
  }
}

export function * watchCancelSign () {
  yield takeEvery(documentaryManagementModalActionsTypes.CANCEL_SIGN, cancelSign)
}

export function * requestSign ({ documentId, sheetId, callback, refreshAfterChange, signTypeId, resolve }) {
  const stateDossier = yield select(state => state)
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(requestSignService, documentId, sheetId, signTypeId, auth.token, stateDossier.documentEditionModal.dossierId)
    if (window.location.href.includes('/iDocSign')) {
      const signState = yield select(getSignState)
      yield put(searchDocuments(signState.filters))
    } else {
      yield put(getDocumentsCount())
    }
    const docModal = yield select(state => state.documentEditionModal)
    const buttonsHeaderState = yield select(state => state.buttonsHeader)
    if (docModal.dossierId && buttonsHeaderState.activeTab === buttonsHeader.gestor_documental) {
      yield put(refreshDocumentManagementModal(docModal.dossierId))
    }
    yield put(getDocumentsCount())
    yield put(closeAddOrEditDocumentModal(refreshAfterChange))
    yield put(messageModalActions.openSuccessMessageModal('MESSAGES.DOCUMENT_MANAGEMENT.REQUEST_SIGN_DOCUMENT'))
    if (resolve) resolve()
  } catch (error) {
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('purchase_dossier'))
      yield put(reset('paperwork_dossier'))
      yield put(reset('campaign_dossier'))
      yield put(reset('registrationDeliveryDossier'))
      yield put(reset('stock_dossier'))
    }
    yield call(handleError, { error, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
  if (stateDossier.salesDossier.dossierId !== null) {
    yield call(refreshDossierByType, {
      dossierType: dossierTypesConstant.sales,
      dossierDocumentChanged: true
    })
  } else if (stateDossier.purchaseDossier.dossierId !== null) {
    yield call(refreshDossierByType, {
      dossierType: dossierTypesConstant.purchase,
      dossierDocumentChanged: true
    })
  }
}

export function * watchRequestSign () {
  yield takeEvery(documentaryManagementModalActionsTypes.REQUEST_SIGN, requestSign)
}

export function * signTypes ({ resolve, reject, documentId, documentTypeUseId, dossierId, pristine = null }) {
  try {
    const auth = yield select(getAuth)
    let noChanges = true
    if (pristine !== null && pristine === false) {
      noChanges = yield call(yesNoModal, 'documentDataLostSendSign')
    }
    if (noChanges) {
      yield put(showLoader())
      var signTypes = yield call(getSignTypes, auth.token, documentId !== undefined ? documentId : null, documentTypeUseId, dossierId)
      if (resolve) resolve(signTypes)
    } else {
      if (reject) yield call(reject)
    }
  } catch (error) {
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('purchase_dossier'))
      yield put(reset('paperwork_dossier'))
      yield put(reset('campaign_dossier'))
      yield put(reset('registrationDeliveryDossier'))
    }
    yield call(handleError, { error, sessionHandler })
    if (reject) yield call(reject)
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSignTypes () {
  yield takeEvery(documentaryManagementModalActionsTypes.SIGN_TYPES, signTypes)
}

export function * checkDependenceMetadato ({ metadatoId, resolve }) {
  const auth = yield select(getAuth)
  const response = yield call(getDependenceMetadato, auth.token, metadatoId)
  resolve(response)
}

export function * watchCheckDependenceMetadato () {
  yield takeEvery(documentaryManagementModalActionsTypes.CHECK_DEPENDENCE_METADATO, checkDependenceMetadato)
}

export function * extractMetadata ({ doc, organizedUnitId }) {
  yield put(updateMetaLoading(true))
  try {
    const auth = yield select(getAuth)
    let fields = yield getExtractMetada(auth.token, doc.documentId, organizedUnitId)
    doc.fields.map((field) => {
      let metaField = fields.find(f => f.key === field.fieldId)
      if (metaField !== null && metaField !== undefined) {
        field.metaValue = metaField.value
      }
    })
    yield put(fetchDocumentSuccess(doc))
  } catch (error) {
    yield put(messageModalActions.openErrorMessageModal('MESSAGES.DOCUMENT_MANAGEMENT.METADATA_LOAD_ERROR'))
  } finally {
    yield put(updateMetaLoading(false))
  }
}

export function * watchExtracMetadata () {
  yield takeEvery(documentaryManagementModalActionsTypes.EXTRACT_METADATA, extractMetadata)
}

export function * cancelSignMessage ({ message, resolve, reject }) {
  yield put(showLoader())
  try {
    yield put(cancelSignMessageSuccess(message))
    resolve()
  } catch (error) {
    reject()
  } finally {
    yield put(hideLoader())
  }
}

export function * watchCancelSignMessage () {
  yield takeEvery(documentaryManagementModalActionsTypes.CANCEL_SIGN_MESSAGE, cancelSignMessage)
}

export function * getCancelDocListFromRequest ({ documentId, sheetId, resolve, reject }) {
  yield put(showLoader())
  try {
    const auth = yield select(getAuth)
    const response = yield call(getCancelDocList, auth.token, documentId, sheetId)
    if (resolve) {
      resolve(response)
    }
  } catch (error) {
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('purchase_dossier'))
      yield put(reset('paperwork_dossier'))
      yield put(reset('campaign_dossier'))
      yield put(reset('registrationDeliveryDossier'))
    }
    yield call(handleError, { error, sessionHandler })
    if (reject) yield call(reject)
  } finally {
    yield put(hideLoader())
  }
}

export function * watchGetCancelDocListFromRequest () {
  yield takeEvery(documentaryManagementModalActionsTypes.GET_CANCEL_DOC_LIST, getCancelDocListFromRequest)
}

export function * openDocumentEditionModalFleet ({ mode, documentId, documentTypeUse, documentParentTypeId, dossierIds, entityIds, vehicleIds, fleetDocumentDetails, organizedUnitId, checklistId, callback, refreshAfterChange, dossierType, dossierSubType, lastAccessDate, pristine, chargeId, isRecurrent = false, editable = true, readOnlyFields, fromTask, buttonRefund, chargeIdParent, fleetId, fleetChargeId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const documentTypeUseInfo = yield call(getDocumentByTypeUse, documentTypeUse, false, auth.token)
    const documentParentRelatedId = yield call(getDocumentParentRelatedId, documentParentTypeId, null, entityIds, vehicleIds)
    const docResult = documentId ? yield call(getDocumentByIdService, auth.token, documentId, organizedUnitId, fleetChargeId) : chargeId ? yield call(getDocumentInfoByChargeId, auth.token, chargeId) : fleetChargeId ? yield call(getDocumentInfoByFleetChargeId, auth.token, fleetChargeId) : yield call(getDocumentByType, auth.token, documentTypeUse, documentParentRelatedId, fromTask)

    docResult.fields = yield call(getMetadato, docResult.fields, documentId)
    if (documentId) {
      docResult.sheets = yield call(getDocumentSheet, auth.token, documentId)
      docResult.update = true
    }
    let dirty = false
    if (pristine !== null && pristine !== undefined) {
      dirty = !pristine
    }
    if (chargeId) {
      docResult.chargeId = chargeId
    }

    if (fleetChargeId) {
      docResult.fleetChargeId = fleetChargeId
    }

    if (buttonRefund && chargeIdParent) {
      docResult.buttonRefund = true
      docResult.chargeIdParent = chargeIdParent
    }

    yield put(openDocumentEditionModalSuccessFleets(mode, docResult, documentTypeUseInfo, organizedUnitId, callback, dossierIds, entityIds, vehicleIds, fleetDocumentDetails, docResult.typeObject, checklistId, refreshAfterChange, dossierType, dossierSubType, (lastAccessDate || docResult.lastAccessDate), dirty, isRecurrent, editable, readOnlyFields, fleetId, fleetChargeId))
  } catch (error) {
    const sessionHandler = function * () {
    }
    yield call(handleError, { error, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchopenDocumentEditionModalFleet () {
  yield takeEvery(documentaryManagementModalActionsTypes.OPEN_DOCUMENT_EDITION_MODAL_FLEETS, openDocumentEditionModalFleet)
}

export function * sealDocumentStamp ({ documentId, dossierId }) {
  const documentEditionModal = yield select(state => state.documentEditionModal)
  let hasDossierState = false
  let dossierState

  try {
    yield put(showLoader())
    if (documentEditionModal.dossierType) {
      hasDossierState = true
      dossierState = yield call(getDossierStateByType, documentEditionModal.dossierType)
    } else {
      dossierState = documentEditionModal
    }
    const auth = yield select(getAuth)

    let date = verifyLastAccessDate(dossierState.lastAccessDate)
    const documentSealStampResult = yield call(postSealDocument, documentId, dossierId, auth.token, date)
    // date = documentValidationResult.lastAccessDate
    yield put(messageModalActions.openSuccessMessageModal('MESSAGES.DOCUMENT_MANAGEMENT.SEAL_STAMP_DOCUMENT_SUCCESS'))
    if (documentEditionModal.refreshAfterChange) {
      if (documentEditionModal.callback) yield call(documentEditionModal.callback, documentEditionModal.entityId)
      if (documentSealStampResult.dossierCancelled !== null && documentSealStampResult.dossierCancelled !== '' && documentSealStampResult.dossierCancelled !== undefined) {
        yield put(replace('/dossier' + documentSealStampResult.dossierCancelled))
      } else {
        if (documentSealStampResult.reloadDossier) {
          yield call(refreshDossierByType, {
            dossierType: documentEditionModal.dossierType,
            dossierDocumentChanged: true,
            entityDocumentChanged: documentEditionModal.documentParentTypeId === documentEntityTypeConstant.ENTITY,
            vehicleDocumentChanged: documentEditionModal.documentParentTypeId === documentEntityTypeConstant.VEHICLE,
            documentEditionalModalState: documentEditionModal
          })
        } else {
          yield put(updateLastAccessDate(documentSealStampResult.lastAccessDate))
          yield call(refreshDossierByTypeLight, {
            dossierType: documentEditionModal.dossierType,
            dossierDocumentChanged: true,
            entityDocumentChanged: false,
            vehicleDocumentChanged: false,
            documentEditionalModalState: documentEditionModal
          })
        }
      }
    } else {
      yield call(changeDocumentManagementChangedPilots, documentEditionModal.documentParentTypeId)
    }
    yield put(closeAddOrEditDocumentModalSuccess())
    yield put(fetchInitialNotificationsCounter())
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          yield put(closeAddOrEditDocumentModalSuccess())
          if (hasDossierState) yield call(initializeDossier, documentEditionModal.dossierType, dossierState)
        }
      } else if (customHandlerError.json.tag === 'cannotSaveEntity' || customHandlerError.json.tag === 'TRANS/cannotSaveEntity') {
        yield put(messageModalActions.openErrorMessageModal('MESSAGES.DOCUMENT_MANAGEMENT.ERROR_DNI'))
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('purchase_dossier'))
      yield put(reset('paperwork_dossier'))
      yield put(reset('campaign_dossier'))
      yield put(reset('registrationDeliveryDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSealDocumentStamp () {
  yield takeEvery(documentaryManagementModalActionsTypes.SEAL_STAMP_DOCUMENT, sealDocumentStamp)
}

export function * createSignDocumentFromTemplate ({ documentTypeUseId, organizedUnitId, documentTypeId, sign, dossierId, id, checklistId, chargeId, dynamicValues, dossierType, lastAccessDate, resolve, reject }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)

    let date
    if (!lastAccessDate) {
      const today = new Date()
      date = today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear()
      date += ' ' + today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds() + ' ' + today.getMilliseconds()
    } else {
      date = lastAccessDate
    }

    const response = yield call(
      postCreateAndSingDocumentFromDynamicTemplate,
      documentTypeUseId,
      organizedUnitId,
      documentTypeId,
      auth.token,
      sign,
      dossierId,
      id,
      checklistId,
      date,
      chargeId,
      dynamicValues
    )

    yield put(messageModalActions.openSuccessMessageModal('MESSAGES.DOCUMENT_MANAGEMENT.POST_DOCUMENT_SUCCESS'))

    yield put(changeId(response.id))

    let document
    if (!resolve) {
      document = yield call(getDocumentByIdService, auth.token, response.documentId, organizedUnitId)
      document.fields = yield call(getMetadato, document.fields, response.documentId)
      document.update = true

      yield put(fetchDocumentSuccess(document))
    }

    if (response.lastAccessDate) {
      yield put(updateDocumentModalLastAccessDate(response.lastAccessDate))
      switch (dossierType) {
        case dossierTypesConstant.sales:
          yield put(fetchSalesDossierSuccess({ lastAccessDate: response.lastAccessDate }))
          break
        case dossierTypesConstant.purchase:
          yield put(fetchPurchaseDossierSuccess({ lastAccessDate: response.lastAccessDate }))
          break
        case dossierTypesConstant.paperwork:
          yield put(fetchPaperworkDossierSuccess({ lastAccessDate: response.lastAccessDate }))
          break
        case dossierTypesConstant.campaign:
          yield put(fetchCampaignDossierSuccess({ lastAccessDate: response.lastAccessDate }))
          break
        case dossierTypesConstant.registrationDelivery:
          yield put(fetchRegistrationDeliveryDossierSuccess({ lastAccessDate: response.lastAccessDate }))
          break
        case dossierTypesConstant.service:
          yield put(fetchServiceDossierSuccess({ lastAccessDate: response.lastAccessDate }))
          break
        case dossierTypesConstant.stock:
          yield put(fetchStockDossierSuccess({ lastAccessDate: response.lastAccessDate }))
          break
      }
    }

    if (resolve) resolve(response)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }

    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('purchase_dossier'))
      yield put(reset('paperwork_dossier'))
      yield put(reset('campaign_dossier'))
      yield put(reset('registrationDeliveryDossier'))
      yield put(reset('service_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
    if (reject) reject(error)
  } finally {
    yield put(hideLoader())
  }
}

export function * watchCreateSignDocumentFromTemplate () {
  yield takeEvery(documentaryManagementModalActionsTypes.CREATE_SIGN_DOCUMENT_DYNAMIC_FROM_TEMPLATE, createSignDocumentFromTemplate)
}

export function * printDocumentDynamicFromTemplate ({ documentTypeUseId, dossierId, dynamicValues }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(postGenerateDocumentFromDynamicTemplate, documentTypeUseId, auth.token, dossierId, dynamicValues)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }

    yield call(handleError, { error, customHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchPrintDocumentDynamicFromTemplate () {
  yield takeEvery(documentaryManagementModalActionsTypes.GENERATE_DOCUMENT_DYNAMIC_FROM_TEMPLATE, printDocumentDynamicFromTemplate)
}
