import apiFetch from '../apiFetch'

export default function (token, organizedUnitIds, userId) {
  const body = { organizedUnitIds: organizedUnitIds }
  const queryParams = userId ? `userId=${userId}` : ''
  return apiFetch({
    endPoint: `EventSubscription/GetAvailableEvents?${queryParams}`,
    method: 'POST',
    body: body,
    token: token
  })
}
