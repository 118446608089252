import apiFetch from '../apiFetch'

export default function (presentsValues, uoId, token) {
  const body = {
    organizedUnitId: uoId,
    isDeleted: false,
    presentTypeLanguages: presentsValues
  }
  return apiFetch({
    endPoint: 'presentType',
    method: 'POST',
    body: body,
    token: token
  })
}
