import React, { PureComponent } from 'react'
import { Row, Table, Button, Tooltip, OverlayTrigger, Modal, Col } from 'react-bootstrap'
import { reduxForm } from 'redux-form'
import CampaignModalCountryImport from './CampaignModalCountryImport'

class CampaingModalCountryDetail extends PureComponent {
  constructor () {
    super()
    this.state = {
      sort: 'countryName',
      modalImportShow: false,
      campaignDetailCountry: null,
      editable: false,
      countries: []
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.campaign !== null && this.props.campaign !== prevProps.campaign && this.props.campaign.campaignDetailsId !== undefined) {
      this.props.actions.fetchCountryCampaignDetailList(this.props.campaign.campaignDetailsId)
    }
  }

  changeOrder (value) {
    switch (value) {
      case 'countryName':
        this.setState({ sort: this.state.sort.startsWith('countryName') ? '-countryName' : 'countryName' })
        break
      case 'amountCampaign':
        this.setState({ sort: this.state.sort.startsWith('amountCampaign') ? '-amountCampaign' : 'amountCampaign' })
        break
      case 'amountBrand':
        this.setState({ sort: this.state.sort.startsWith('amountBrand') ? '-amountBrand' : 'amountBrand' })
        break
      case 'percentageCampaign':
        this.setState({ sort: this.state.sort.startsWith('percentageCampaign') ? '-percentageCampaign' : 'percentageCampaign' })
        break
      case 'percentageBrand':
        this.setState({ sort: this.state.sort.startsWith('percentageBrand') ? '-percentageBrand' : 'percentageBrand' })
        break
    }
  }

  editCountryDetail (value) {
    this.setState({ campaignDetailCountry: value, modalImportShow: true, editable: true })
  }

  addCountrydetail () {
    let detail = {
      campaignDetailsId: this.props.campaign.campaignDetailsId,
      countryId: null,
      amountBrand: this.props.campaign.amountBrand !== null && this.props.campaign.amountBrand !== undefined ? 0 : null,
      amountCampaign: this.props.campaign.amountCampaign !== null && this.props.campaign.amountCampaign !== undefined ? 0 : null,
      percentageBrand: this.props.campaign.percentageBrand !== null && this.props.campaign.percentageBrand !== undefined ? 0 : null,
      percentageCampaign: this.props.campaign.percentageCampaign !== null && this.props.campaign.percentageCampaign !== undefined ? 0 : null
    }
    this.setState({ campaignDetailCountry: detail, modalImportShow: true, editable: false })
  }

  closeModalImport () {
    this.setState({ modalImportShow: false })
  }

  openModalImport () {
    this.setState({ modalImportShow: true })
  }

  dynamicSort (property) {
    var sortOrder = 1
    if (property[0] === '-') {
      sortOrder = -1
      property = property.substr(1)
    }
    return function (a, b) {
      var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0
      return result * sortOrder
    }
  }

  render () {
    const {
      t,
      showModal, campaignDetailsCountries
    } = this.props
    const tKey = 'MASTERS.CAMPAIGN_DETAILS_MASTERS.MODAL_COUNTRY_DETAILS.'

    const deleteCountryDetail = (<Tooltip id="deleteCountryDetail">{t(`${tKey}DELETE_COUNTRY_DETAIL`)}</Tooltip>)
    const editCountryDetail = (<Tooltip id="editCountryDetail">{t(`${tKey}EDIT_COUNTRY_DETAIL`)}</Tooltip>)
    let title
    if (this.props.campaign) {
      title = this.props.campaign.campaignDetailsCode + ' - ' + this.props.campaign.description
    }

    const style = {
      overflowY: 'auto'
    }

    const formatter = new Intl.NumberFormat('es-ES', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2
    })

    let countrydDetails = campaignDetailsCountries.sort(this.dynamicSort(this.state.sort))
    return (
      <>
        <CampaignModalCountryImport
          actions={{
            close: this.closeModalImport.bind(this),
            fetchCountries: this.props.actions.fetchCountries,
            setCountryCampaignDetail: this.props.actions.setCountryCampaignDetail
          }}
          modalImportShow = {this.state.modalImportShow}
          t={t}
          detail = {this.state.campaignDetailCountry}
          editable = {this.state.editable}
          countries = {this.props.countries}
        />
        <Modal className="role-modal languages-modal" show={showModal} onHide={this.props.actions.closeCampaingModalCountryDetailSuccess}>
          <form autoComplete="off" >
            <Modal.Header closeButton onHide={this.props.actions.closeCampaingModalCountryDetailSuccess}>
              <Modal.Title>{t(`${tKey}TITLE`) + ': ' + title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row className="inputs-wrapper">
                <Col>
                  <div style={style}>
                    <Table className="editable-table" hover responsive>
                      <thead>
                        <tr>
                          <th onClick={() => { this.changeOrder('countryName') }}>
                            {t(`${tKey}COUNTRY`)}
                          </th>
                          <th onClick={() => { this.changeOrder('amountCampaign') }}>
                            {t(`${tKey}CAMPAIGN_AMOUNT`)}
                          </th>
                          <th onClick={() => { this.changeOrder('amountBrand') }}>
                            {t(`${tKey}BRAND_APORT`)}
                          </th>
                          <th onClick={() => { this.changeOrder('percentageCampaign') }}>
                            {t(`${tKey}CAMPAIGN_PERCENTAGE`)}
                          </th>
                          <th onClick={() => { this.changeOrder('percentageBrand') }}>
                            {t(`${tKey}BRAND_PERCENTAGE`)}
                          </th>
                          <th style={{ width: '70px' }}>{t('DOSSIER_COMPONENTS.ACCESSORIES.ACTIONS')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {countrydDetails && countrydDetails.map((item, idx) => {
                          return (
                            <tr key={idx}>
                              <td>{item.countryName}</td>
                              <td>{item.amountCampaign !== null && item.amountCampaign !== undefined ? formatter.format(item.amountCampaign) : null}</td>
                              <td>{item.amountBrand !== null && item.amountBrand !== undefined ? formatter.format(item.amountBrand) : null}</td>
                              <td>{item.percentageCampaign !== null && item.percentageCampaign !== undefined ? String(item.percentageCampaign).replace('.', ',') + ' %' : null}</td>
                              <td>{item.percentageBrand !== null && item.percentageBrand !== undefined ? String(item.percentageBrand).replace('.', ',') + ' %' : null}</td>
                              <td>
                                <div style={{ textAlign: 'center' }}>
                                  <a onClick={() => this.editCountryDetail(item)}>
                                    <OverlayTrigger placement="left" overlay={editCountryDetail}>
                                      <i className="ico-edit-white" />
                                    </OverlayTrigger>
                                  </a>
                                  <a onClick={() => this.props.actions.deleteCountryCampaignDetail(item.campaignDetailsCountryId, item.campaignDetailsId)}>
                                    <OverlayTrigger placement="left" overlay={deleteCountryDetail}>
                                      <i className="ico-trash" />
                                    </OverlayTrigger>
                                  </a>
                                </div>

                              </td>
                            </tr>)
                        })
                        }
                        {(countrydDetails === null || countrydDetails === undefined || countrydDetails.length === 0) &&
                        <tr>
                          <td colSpan={10}><h2>{t(`${tKey}RESULTS_NOT_FOUND`)}</h2></td>
                        </tr>}
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Col sm={6}>
                <Button
                  className="btn-standard"
                  onClick = {() => this.addCountrydetail()}>
                  {t(`${tKey}ADD`)}
                </Button>
              </Col>
              <Col sm={6} className="btn-wrapper">
                <Button
                  id="role-modal-submit-button"
                  className="btn-standard"
                  onClick = {() => this.props.actions.closeCampaingModalCountryDetailSuccess()}>
                  {t(`${tKey}CLOSE`)}
                </Button>
              </Col>
            </Modal.Footer>
          </form>
        </Modal>
      </>
    )
  }
}

export default reduxForm({
  form: 'editCampaignCountryDetail'
})(CampaingModalCountryDetail)
