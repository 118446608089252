import React, { PureComponent } from 'react'
import { Col, Row } from 'react-bootstrap'
import { Field } from 'redux-form'
import InputCheckBox from '../../../commons/form/InputCheckBox'
import InputSelect from '../../../commons/form/InputSelect'
import InputText from '../../../commons/form/InputText'
import { entityTypesId, permissions } from '../../../../constants/backendIds'
import { dossierTypes as dossierTypesConstant } from '../../../../constants/dossier/common/dossierTypes'
import { dossierSubTypeId as dossierSubTypeConstant } from '../../../../constants/dossier/common/dossierSubType'
import { comboType, numberCombo } from '../../../../constants/dossier/common/client'
import NormalizedAddress from '../client/NormalizedAddress'

class FinalClient extends PureComponent {
  constructor (props) {
    super()
    this.state = {
      combo: 0,
      alert: true,
      freeLance: false
    }
  }

  componentDidUpdate () {
    if (this.state.alert && this.props.combos[`entity${comboType[this.state.combo]}`]?.length === 0 && this.props.combos[`entity${comboType[this.state.combo - 1]}`]?.length > 0) {
      const alert = 'notData'
      this.props.actions.openCommonModal(alert)
      this.setState({
        combo: this.state.combo - 1,
        alert: false
      })
      if (this.state.combo !== numberCombo.postalCodeCode) {
        this.props.actions[`fetchEntity${comboType[this.state.combo]}`]()
      }
    }
  }

  handleEntityTypesChange (code, disable) {
    if (code) {
      if (disable !== 5) {
        this.props.actions[`fetchEntity${comboType[disable + 1]}`](this.props.combos[`entity${comboType[disable]}`].find(x => x.code === code)?.id)
        this.setState({
          notData: false
        })
        this.AddCombo()
      }
    } else {
      this.setState({ combo: (disable !== 0) ? disable - 1 : disable, alert: true })
    }
  }

  AddCombo () {
    this.setState({
      combo: this.state.combo + 1
    })
  }

  setValidationDisable (disableDniCifValidation, disableRepDniCifValidation, disableChildDniCifValidation, disableChildRepDniCifValidation) {
    if (disableChildDniCifValidation) {
      var okAction = () => {
        this.props.actions.setEntityValidationDisable(this.props.dossierType, disableDniCifValidation, disableRepDniCifValidation, disableChildDniCifValidation, disableChildRepDniCifValidation)
        this.props.actions.asyncValidate({ entityChildComponent: { ...this.props.formValues.entityChildComponent, disableChildDniCifValidation: disableChildDniCifValidation, freeLance: this.state.freeLance } }, this.props.actions.dispatch, this.props, 'dniCif', true)
          .then((result) => {
            this.props.actions.stopAsyncValidation({ ...result })
          })
      }

      var cancelAction = () => { this.props.actions.change('entityChildComponent.dniCifValidationDisable', false) }

      this.props.actions.openCommonModal('setValidationDisableModal', okAction, cancelAction, null, null, null, null)
    } else {
      this.props.actions.setEntityValidationDisable(this.props.dossierType, disableDniCifValidation, disableRepDniCifValidation, disableChildDniCifValidation, disableChildRepDniCifValidation)
      if (disableChildDniCifValidation !== null && disableChildDniCifValidation !== undefined) {
        this.props.actions.asyncValidate({ entityChildComponent: { ...this.props.formValues.entityChildComponent, dniCifValidationDisable: disableChildDniCifValidation, freeLance: this.state.freeLance } }, this.props.actions.dispatch, this.props, 'dniCif', true)
          .then((result) => {
            this.props.actions.stopAsyncValidation({ ...result })
          })
      }
      if (disableChildRepDniCifValidation !== null && disableChildRepDniCifValidation !== undefined) {
        this.props.actions.asyncValidate({ entityChildComponent: { ...this.props.formValues.entityChildComponent, representativeDniCifValidationDisable: disableChildRepDniCifValidation, freeLance: this.state.freeLance } }, this.props.actions.dispatch, this.props, 'representativeDni', true).then((result) => {
          this.props.actions.stopAsyncValidation({ ...result })
        })
      }
    }
  }

  getEntityChild (value) {
    const dniCif = value
    if (value) {
      new Promise((resolve) => {
        this.props.actions.fetchClientByDniEntityChild(dniCif, this.props.dossier.organizedUnitId, this.props.dossierType, resolve)
      }).then(() => {
      })
    }
  }

  getEntityRfc (value) {
    const rfc = value
    if (value) {
      new Promise((resolve) => {
        return this.props.actions.checkPrivateRfcFormat(value, resolve)
      }).then((confirm) => {
        if (confirm) {
          new Promise((resolve) => {
            this.props.actions.fetchClientByRfc(rfc, this.props.dossier.organizedUnitId, this.props.dossierType, resolve)
          }).then(() => {
          })
        } else {
          this.props.change('entityComponent.rfc', null)
        }
      })
    }
  }

  getEntityTypeLabel () {
    const tKey = 'DOSSIER_COMPONENTS.ENTITY.'
    switch (this.props.dossierType) {
      case dossierTypesConstant.sales:
      case dossierTypesConstant.addNewFleetModal:
      case dossierTypesConstant.createNewFleetModal:
      case dossierTypesConstant.dataContactFleetModal:
        return this.props.t(`${tKey}CLIENT_TYPE`)
      case dossierTypesConstant.purchase:
        return this.props.t(`${tKey}VENDOR_TYPE`)
      default:
        switch (this.getSubTypePapeworkRelatedDossier()) {
          case dossierSubTypeConstant.compra_vo:
            return this.props.t(`${tKey}ENTITY_VENDOR`)
          case dossierSubTypeConstant.venta_vo:
            return this.props.t(`${tKey}ENTITY_BUYER`)
          default:
            return this.props.t(`${tKey}ENTITY_TYPE`)
        }
    }
  }

  getSubTypePapeworkRelatedDossier () {
    if (this.props.dossier.dossierRelatedDossiers && this.props.dossier.dossierRelatedDossiers.length) {
      const paperworkRelatedDossier = this.props.dossier.dossierRelatedDossiers[0]
      return paperworkRelatedDossier.dossierSubType
    }
  }

  removeRecurrentEntityTypeFromCombo (entityTypeCombo) {
    var entityTypeComboNoRecurrent = []
    for (const entity of entityTypeCombo) {
      if (entity.id !== entityTypesId.recurrentEntity) {
        entityTypeComboNoRecurrent.push(entity)
      }
    }
    return entityTypeComboNoRecurrent
  }

  onEntityChildTypeIdChanges (entityTypeId) {
    new Promise((resolve, reject) => {
      return this.props.actions.setEntityChildTypeId(entityTypeId, this.props.dossier.entityChildComponent.entityTypeId, this.props.dossierType, resolve, reject)
    }).then(() => {
      if (entityTypeId === entityTypesId.recurrentEntity) this.props.actions.fetchEntitySubTypeCombo(entityTypeId)
    })
  }

  getNumberLabel () {
    const tKey = 'DOSSIER_COMPONENTS.ENTITY.'
    switch (this.props.dossierType) {
      case dossierTypesConstant.sales:
      case dossierTypesConstant.addNewFleetModal:
      case dossierTypesConstant.createNewFleetModal:
      case dossierTypesConstant.dataContactFleetModal:
        return this.props.t(`${tKey}MAIN_CLIENT_NUMBER`)
      case dossierTypesConstant.purchase:
        return this.props.t(`${tKey}MAIN_VENDOR_NUMBER`)
      default:
        switch (this.getSubTypePapeworkRelatedDossier()) {
          case dossierSubTypeConstant.compra_vo:
            return this.props.t(`${tKey}MAIN_ENTITY_VENDOR_NUMBER`)
          case dossierSubTypeConstant.venta_vo:
            return this.props.t(`${tKey}MAIN_ENTITY_BUYER_NUMBER`)
          default:
            return this.props.t(`${tKey}MAIN_ENTITY_NUMBER`)
        }
    }
  }

  setFreelance (value) {
    this.setState({ freeLance: value })

    this.props.actions.asyncValidate({ entityChildComponent: { ...this.props.formValues.entityChildComponent, disableChildDniCifValidation: this.props.dossier?.entityChildComponent?.disableChildDniCifValidation, freeLance: value } }, this.props.actions.dispatch, this.props, 'dniCif', true)
      .then((result) => {
        this.props.actions.stopAsyncValidation({ ...result })
      })
  }

  longSchema () {
    const {
      t,
      fieldsConfiguration,
      readOnlyFields,
      isRecurrentEntityType,
      disabledByNotSelectedEntityChildTypeId,
      dossier: {
        entityChildComponent,
        salesComponent
      },
      combos: {
        entityTypeCombo,
        iaeTypeCombo
      },
      actions: {
        fetchEntityCountryTypeCombo,
        fetchEntityStateTypeCombo,
        fetchEntityCityTypeCombo,
        fetchEntityTownTypeCombo,
        fetchEntityColonyTypeCombo,
        fetchEntityCPTypeCombo,
        fetchClientDataAddress
      }

    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.ENTITY.'
    return (
      <Row className='flexRow'>
        {fieldsConfiguration.entityTypeIdChild &&
        fieldsConfiguration.entityTypeIdChild.permission !==
          permissions.hidden && (
          <Field
            colSm={4}
            id='entityTypeIdChild'
            name='entityChildComponent.entityTypeId'
            controlLabel={this.getEntityTypeLabel()}
            placeholder={this.getEntityTypeLabel()}
            component={InputSelect}
            options={this.removeRecurrentEntityTypeFromCombo(
              entityTypeCombo
            )}
            onInputChange={this.onEntityChildTypeIdChanges.bind(this)}
            valueKey='id'
            labelKey='value'
            disabled={
              readOnlyFields ||
              entityChildComponent.entityId ||
              fieldsConfiguration.entityTypeIdChild
                .permission !== permissions.editable
            }
          />
        )}
        {fieldsConfiguration.dniCifChild &&
        fieldsConfiguration.dniCifChild.permission !==
          permissions.hidden && (
          <Field
            id='dniCifChild'
            name='entityChildComponent.dniCif'
            disabled={
              (isRecurrentEntityType ||
              salesComponent?.clientNotEmployeeCheck
                ? readOnlyFields ||
                  fieldsConfiguration.dniCifChild.permission !==
                    permissions.editable
                : true) ||
              entityChildComponent.entityId ||
              disabledByNotSelectedEntityChildTypeId
            }
            colSm={4}
            controlLabel={t(`${tKey}DNI`)}
            placeholder={t(`${tKey}DNI`)}
            component={InputText}
            onInputBlur={(event) => this.getEntityChild(event)}
            maxLength={20}
          />
        )}
        {entityChildComponent.entityTypeId !==
        entityTypesId.recurrentEntity && [
          fieldsConfiguration.clientNumberChild &&
          fieldsConfiguration.clientNumberChild.permission !==
            permissions.hidden && (
            <Field
              id='clientNumberChild'
              name='entityChildComponent.clientNumber'
              key='entityChildComponent.clientNumber'
              colSm={4}
              controlLabel={this.getNumberLabel()}
              placeholder={this.getNumberLabel()}
              component={InputText}
              disabled={
                readOnlyFields ||
                fieldsConfiguration.clientNumberChild
                  .permission !== permissions.editable ||
                disabledByNotSelectedEntityChildTypeId
              }
              forceControlLabelInOneLine={true}
            />
          )
        ]}
        {(entityChildComponent.entityTypeId === entityTypesId.privateEntity ||
        entityChildComponent.entityTypeId ===
          entityTypesId.businessEntity) &&
        fieldsConfiguration.nameChild &&
        fieldsConfiguration.nameChild.permission !==
          permissions.hidden && (
          <Field
            id='nameChild'
            name='entityChildComponent.name'
            key='entityChildComponent.name'
            colSm={4}
            controlLabel={t(`${tKey}NAME`)}
            placeholder={t(`${tKey}NAME`)}
            component={InputText}
            disabled={
              readOnlyFields ||
              fieldsConfiguration.nameChild.permission !==
                permissions.editable ||
              disabledByNotSelectedEntityChildTypeId
            }
          />
        )}
        {entityChildComponent.entityTypeId ===
        entityTypesId.privateEntity && [
          fieldsConfiguration.surname1Child &&
          fieldsConfiguration.surname1Child.permission !==
            permissions.hidden && (
            <Field
              id='surname1Child'
              name='entityChildComponent.surname1'
              key='entityChildComponent.surname1'
              colSm={4}
              controlLabel={t(`${tKey}SURNAME1`)}
              placeholder={t(`${tKey}SURNAME1`)}
              component={InputText}
              disabled={
                readOnlyFields ||
                fieldsConfiguration.surname1Child.permission !==
                  permissions.editable ||
                disabledByNotSelectedEntityChildTypeId
              }
            />
          ),
          fieldsConfiguration.surname2Child &&
          fieldsConfiguration.surname2Child.permission !==
            permissions.hidden && (
            <Field
              id='surname2Child'
              name='entityChildComponent.surname2'
              key='entityChildComponent.surname2'
              colSm={4}
              controlLabel={t(`${tKey}SURNAME2`)}
              placeholder={t(`${tKey}SURNAME2`)}
              component={InputText}
              disabled={
                readOnlyFields ||
                fieldsConfiguration.surname2Child.permission !==
                  permissions.editable ||
                disabledByNotSelectedEntityChildTypeId
              }
            />
          )
        ]}
        {entityChildComponent.entityTypeId ===
        entityTypesId.businessEntity && [
          fieldsConfiguration.representativeDniChild &&
          fieldsConfiguration.representativeDniChild
            .permission !== permissions.hidden && (
            <Field
              id='representativeDniChild'
              name='entityChildComponent.representativeDni'
              key='entityChildComponent.representativeDni'
              colSm={4}
              controlLabel={t(`${tKey}REPRESENTATIVE_DNI`)}
              placeholder={t(`${tKey}REPRESENTATIVE_DNI`)}
              component={InputText}
              disabled={
                readOnlyFields ||
                fieldsConfiguration.representativeDniChild
                  .permission !== permissions.editable ||
                disabledByNotSelectedEntityChildTypeId
              }
            />
          ),
          fieldsConfiguration.representativeNameChild &&
          fieldsConfiguration.representativeNameChild
            .permission !== permissions.hidden && (
            <Field
              id='representativeNameChild'
              name='entityChildComponent.representativeName'
              key='entityChildComponent.representativeName'
              colSm={4}
              controlLabel={t(`${tKey}REPRESENTATIVE_NAME`)}
              placeholder={t(`${tKey}REPRESENTATIVE_NAME`)}
              component={InputText}
              disabled={
                readOnlyFields ||
                fieldsConfiguration.representativeNameChild
                  .permission !== permissions.editable ||
                disabledByNotSelectedEntityChildTypeId
              }
            />
          ),
          fieldsConfiguration.representativeSurname1Child &&
          fieldsConfiguration.representativeSurname1Child
            .permission !== permissions.hidden && (
            <Field
              id='representativeSurname1Child'
              name='entityChildComponent.representativeSurname1'
              key='entityChildComponent.representativeSurname1'
              colSm={4}
              controlLabel={t(`${tKey}REPRESENTATIVE_SURNAME1`)}
              placeholder={t(`${tKey}REPRESENTATIVE_SURNAME1`)}
              component={InputText}
              disabled={
                readOnlyFields ||
                fieldsConfiguration.representativeSurname1Child
                  .permission !== permissions.editable ||
                disabledByNotSelectedEntityChildTypeId
              }
              forceControlLabelInOneLine={true}
            />
          ),
          fieldsConfiguration.representativeSurname2Child &&
          fieldsConfiguration.representativeSurname2Child
            .permission !== permissions.hidden && (
            <Field
              id='representativeSurname2Child'
              name='entityChildComponent.representativeSurname2'
              key='entityChildComponent.representativeSurname2'
              colSm={4}
              controlLabel={t(`${tKey}REPRESENTATIVE_SURNAME2`)}
              placeholder={t(`${tKey}REPRESENTATIVE_SURNAME2`)}
              component={InputText}
              disabled={
                readOnlyFields ||
                fieldsConfiguration.representativeSurname2Child
                  .permission !== permissions.editable ||
                disabledByNotSelectedEntityChildTypeId
              }
              forceControlLabelInOneLine={true}
            />
          )
        ]}
        {entityChildComponent.entityTypeId !==
        entityTypesId.recurrentEntity && [
          fieldsConfiguration.phoneChild &&
          fieldsConfiguration.phoneChild.permission !==
            permissions.hidden && (
            <Field
              id='phoneChild'
              name='entityChildComponent.phone'
              key='entityChildComponent.phone'
              colSm={4}
              controlLabel={t(`${tKey}PHONE`)}
              placeholder={t(`${tKey}PHONE`)}
              component={InputText}
              disabled={
                readOnlyFields ||
                fieldsConfiguration.phoneChild.permission !==
                  permissions.editable ||
                disabledByNotSelectedEntityChildTypeId
              }
            />
          ),
          fieldsConfiguration.mobilePhoneChild &&
          fieldsConfiguration.mobilePhoneChild.permission !==
            permissions.hidden && (
            <Field
              id='mobilePhoneChild'
              name='entityChildComponent.mobilePhone'
              key='entityChildComponent.mobilePhone'
              colSm={4}
              controlLabel={t(`${tKey}MOBILE`)}
              placeholder={t(`${tKey}MOBILE`)}
              component={InputText}
              disabled={
                readOnlyFields ||
                fieldsConfiguration.mobilePhoneChild
                  .permission !== permissions.editable ||
                disabledByNotSelectedEntityChildTypeId
              }
            />
          ),
          fieldsConfiguration.emailChild &&
          fieldsConfiguration.emailChild.permission !==
            permissions.hidden && (
            <Field
              id='emailChild'
              name='entityChildComponent.email'
              key='entityChildComponent.email'
              colSm={4}
              controlLabel={t(`${tKey}EMAIL`)}
              placeholder={t(`${tKey}EMAIL`)}
              component={InputText}
              disabled={
                readOnlyFields ||
                fieldsConfiguration.emailChild.permission !==
                  permissions.editable ||
                disabledByNotSelectedEntityChildTypeId
              }
            />
          ),
          fieldsConfiguration.iaeTypeIdChild &&
          fieldsConfiguration.iaeTypeIdChild.permission !==
            permissions.hidden &&
          entityChildComponent.entityTypeId ===
            entityTypesId.businessEntity && (
            <Field
              colSm={4}
              id='iaeTypeIdChild'
              name='entityChildComponent.iaeTypeId'
              key='entityChildComponent.iaeTypeId'
              controlLabel={t(`${tKey}IAE_TYPE`)}
              placeholder={t(`${tKey}IAE_TYPE`)}
              component={InputSelect}
              options={iaeTypeCombo}
              valueKey='id'
              labelKey='value'
              disabled={
                readOnlyFields ||
                fieldsConfiguration.iaeTypeIdChild.permission !==
                  permissions.editable ||
                disabledByNotSelectedEntityChildTypeId
              }
            />
          ),
          <NormalizedAddress
            t={t}
            fieldsConfiguration={{
              countryCode: fieldsConfiguration.countryCodeChild,
              stateCode: fieldsConfiguration.stateCodeChild,
              cityCode: fieldsConfiguration.cityCodeChild,
              townCode: fieldsConfiguration.townCodeChild,
              colonyCode: fieldsConfiguration.colonyCodeChild,
              cpCode: fieldsConfiguration.cpCodeChild
            }}
            readOnlyFields={readOnlyFields}
            allInformationDisabled={disabledByNotSelectedEntityChildTypeId}
            entityComponent= {this.props.dossier.entityChildComponent}
            entityTypeName= "entityChildComponent"
            actions={{
              fetchEntityCountryTypeCombo,
              fetchEntityStateTypeCombo,
              fetchEntityCityTypeCombo,
              fetchEntityTownTypeCombo,
              fetchEntityColonyTypeCombo,
              fetchEntityCPTypeCombo,
              fetchClientDataAddress
            }}
          />,
          fieldsConfiguration.addressChild &&
      fieldsConfiguration.addressChild.permission !== permissions.hidden && (
            <Field
              id="addressChild"
              name="entityChildComponent.address"
              key="entityChildComponent.address"
              colSm={8}
              controlLabel={t(`${tKey}ADDRESS`)}
              placeholder={t(`${tKey}ADDRESS`)}
              component={InputText}
              disabled={
                readOnlyFields ||
            fieldsConfiguration.addressChild.permission !==
              permissions.editable ||
            disabledByNotSelectedEntityChildTypeId
              }
            />
          ),
          fieldsConfiguration.externalNumberChild &&
      fieldsConfiguration.externalNumberChild.permission !==
        permissions.hidden && (
            <Field
              id="externalNumber"
              name="entityChildComponent.externalNumber"
              key="entityChildComponent.externalNumber"
              colSm={4}
              controlLabel={t(`${tKey}EXTERNAL_NUMBER`)}
              placeholder={t(`${tKey}EXTERNAL_NUMBER`)}
              component={InputText}
              disabled={
                readOnlyFields ||
            fieldsConfiguration.externalNumberChild.permission !==
              permissions.editable ||
            disabledByNotSelectedEntityChildTypeId
              }
              maxLength="20"
            />
          ),
          fieldsConfiguration.startStreetChild &&
      fieldsConfiguration.startStreetChild.permission !==
        permissions.hidden && (
            <Field
              id="startStreet"
              name="entityChildComponent.startStreet"
              key="entityChildComponent.startStreet"
              colSm={8}
              controlLabel={t(`${tKey}START_STREET`)}
              placeholder={t(`${tKey}START_STREET`)}
              component={InputText}
              disabled={
                readOnlyFields ||
            fieldsConfiguration.startStreetChild.permission !==
              permissions.editable ||
            disabledByNotSelectedEntityChildTypeId
              }
              maxLength="200"
            />
          ),
          fieldsConfiguration.internalNumberChild &&
      fieldsConfiguration.internalNumberChild.permission !==
        permissions.hidden && (
            <Field
              id="internalNumber"
              name="entityChildComponent.internalNumber"
              key="entityChildComponent.internalNumber"
              colSm={4}
              controlLabel={t(`${tKey}INTERNAL_NUMBER`)}
              placeholder={t(`${tKey}INTERNAL_NUMBER`)}
              component={InputText}
              disabled={
                readOnlyFields ||
            fieldsConfiguration.internalNumberChild.permission !==
              permissions.editable ||
            disabledByNotSelectedEntityChildTypeId
              }
              maxLength="20"
            />
          ),
          fieldsConfiguration.enndStreetChild &&
      fieldsConfiguration.enndStreetChild.permission !== permissions.hidden && (
            <Field
              id="enndStreet"
              name="entityChildComponent.enndStreet"
              key="entityChildComponent.enndStreet"
              colSm={8}
              controlLabel={t(`${tKey}ENND_STREET`)}
              placeholder={t(`${tKey}ENND_STREET`)}
              component={InputText}
              disabled={
                readOnlyFields ||
            fieldsConfiguration.enndStreetChild.permission !==
              permissions.editable ||
            disabledByNotSelectedEntityChildTypeId
              }
              maxLength="200"
            />
          )
        ]}
        {fieldsConfiguration.rfcChild &&
        fieldsConfiguration.rfcChild.permission !==
          permissions.hidden && (
          <Field
            id='rfcChild'
            name='entityChildComponent.rfc'
            disabled={
              (isRecurrentEntityType
                ? readOnlyFields ||
                  fieldsConfiguration.rfcChild.permission !==
                    permissions.editable
                : true) ||
              entityChildComponent.entityId ||
              disabledByNotSelectedEntityChildTypeId
            }
            colSm={4}
            controlLabel={t(`${tKey}RFC`)}
            placeholder={t(`${tKey}RFC`)}
            component={InputText}
            onInputBlur={(event) => this.getEntityRfc(event)}
          />
        )}
      </Row>
    )
  }

  shortSchema () {
    const {
      t,
      fieldsConfiguration,
      readOnlyFields,
      isRecurrentEntityType,
      disabledByNotSelectedEntityChildTypeId,
      dossier: {
        entityChildComponent,
        salesComponent
      },
      combos: {
        entityTypeCombo,
        iaeTypeCombo
      }
    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.ENTITY.'
    return (
      <Row>
        {fieldsConfiguration.entityTypeIdChild &&
        fieldsConfiguration.entityTypeIdChild.permission !==
          permissions.hidden && (
          <Field
            colSm={4}
            id='entityTypeIdChild'
            name='entityChildComponent.entityTypeId'
            controlLabel={this.getEntityTypeLabel()}
            placeholder={this.getEntityTypeLabel()}
            component={InputSelect}
            options={this.removeRecurrentEntityTypeFromCombo(
              entityTypeCombo
            )}
            onInputChange={this.onEntityChildTypeIdChanges.bind(this)}
            valueKey='id'
            labelKey='value'
            disabled={
              readOnlyFields ||
              entityChildComponent.entityId ||
              fieldsConfiguration.entityTypeIdChild
                .permission !== permissions.editable
            }
          />
        )}
        {fieldsConfiguration.dniCifChild &&
        fieldsConfiguration.dniCifChild.permission !==
          permissions.hidden && (
          <Field
            id='dniCifChild'
            name='entityChildComponent.dniCif'
            disabled={
              (isRecurrentEntityType ||
              salesComponent?.clientNotEmployeeCheck
                ? readOnlyFields ||
                  fieldsConfiguration.dniCifChild.permission !==
                    permissions.editable
                : true) ||
              entityChildComponent.entityId ||
              disabledByNotSelectedEntityChildTypeId
            }
            colSm={4}
            controlLabel={t(`${tKey}DNI`)}
            placeholder={t(`${tKey}DNI`)}
            component={InputText}
            onInputBlur={(event) => this.getEntityChild(event)}
            maxLength={20}
          />
        )}
        {entityChildComponent.entityTypeId !==
        entityTypesId.recurrentEntity && [
          fieldsConfiguration.clientNumberChild &&
          fieldsConfiguration.clientNumberChild.permission !==
            permissions.hidden && (
            <Field
              id='clientNumberChild'
              name='entityChildComponent.clientNumber'
              key='entityChildComponent.clientNumber'
              colSm={4}
              controlLabel={this.getNumberLabel()}
              placeholder={this.getNumberLabel()}
              component={InputText}
              disabled={
                readOnlyFields ||
                fieldsConfiguration.clientNumberChild
                  .permission !== permissions.editable ||
                disabledByNotSelectedEntityChildTypeId
              }
              forceControlLabelInOneLine={true}
            />
          )
        ]}
        {(entityChildComponent.entityTypeId === entityTypesId.privateEntity ||
        entityChildComponent.entityTypeId ===
          entityTypesId.businessEntity) &&
        fieldsConfiguration.nameChild &&
        fieldsConfiguration.nameChild.permission !==
          permissions.hidden && (
          <Field
            id='nameChild'
            name='entityChildComponent.name'
            key='entityChildComponent.name'
            colSm={4}
            controlLabel={t(`${tKey}NAME`)}
            placeholder={t(`${tKey}NAME`)}
            component={InputText}
            disabled={
              readOnlyFields ||
              fieldsConfiguration.nameChild.permission !==
                permissions.editable ||
              disabledByNotSelectedEntityChildTypeId
            }
          />
        )}
        {entityChildComponent.entityTypeId ===
        entityTypesId.privateEntity && [
          fieldsConfiguration.surname1Child &&
          fieldsConfiguration.surname1Child.permission !==
            permissions.hidden && (
            <Field
              id='surname1Child'
              name='entityChildComponent.surname1'
              key='entityChildComponent.surname1'
              colSm={4}
              controlLabel={t(`${tKey}SURNAME1`)}
              placeholder={t(`${tKey}SURNAME1`)}
              component={InputText}
              disabled={
                readOnlyFields ||
                fieldsConfiguration.surname1Child.permission !==
                  permissions.editable ||
                disabledByNotSelectedEntityChildTypeId
              }
            />
          ),
          fieldsConfiguration.surname2Child &&
          fieldsConfiguration.surname2Child.permission !==
            permissions.hidden && (
            <Field
              id='surname2Child'
              name='entityChildComponent.surname2'
              key='entityChildComponent.surname2'
              colSm={4}
              controlLabel={t(`${tKey}SURNAME2`)}
              placeholder={t(`${tKey}SURNAME2`)}
              component={InputText}
              disabled={
                readOnlyFields ||
                fieldsConfiguration.surname2Child.permission !==
                  permissions.editable ||
                disabledByNotSelectedEntityChildTypeId
              }
            />
          )
        ]}
        {entityChildComponent.entityTypeId ===
        entityTypesId.businessEntity && [
          fieldsConfiguration.representativeDniChild &&
          fieldsConfiguration.representativeDniChild
            .permission !== permissions.hidden && (
            <Field
              id='representativeDniChild'
              name='entityChildComponent.representativeDni'
              key='entityChildComponent.representativeDni'
              colSm={4}
              controlLabel={t(`${tKey}REPRESENTATIVE_DNI`)}
              placeholder={t(`${tKey}REPRESENTATIVE_DNI`)}
              component={InputText}
              disabled={
                readOnlyFields ||
                fieldsConfiguration.representativeDniChild
                  .permission !== permissions.editable ||
                disabledByNotSelectedEntityChildTypeId
              }
            />
          ),
          fieldsConfiguration.representativeNameChild &&
          fieldsConfiguration.representativeNameChild
            .permission !== permissions.hidden && (
            <Field
              id='representativeNameChild'
              name='entityChildComponent.representativeName'
              key='entityChildComponent.representativeName'
              colSm={4}
              controlLabel={t(`${tKey}REPRESENTATIVE_NAME`)}
              placeholder={t(`${tKey}REPRESENTATIVE_NAME`)}
              component={InputText}
              disabled={
                readOnlyFields ||
                fieldsConfiguration.representativeNameChild
                  .permission !== permissions.editable ||
                disabledByNotSelectedEntityChildTypeId
              }
            />
          ),
          fieldsConfiguration.representativeSurname1Child &&
          fieldsConfiguration.representativeSurname1Child
            .permission !== permissions.hidden && (
            <Field
              id='representativeSurname1Child'
              name='entityChildComponent.representativeSurname1'
              key='entityChildComponent.representativeSurname1'
              colSm={4}
              controlLabel={t(`${tKey}REPRESENTATIVE_SURNAME1`)}
              placeholder={t(`${tKey}REPRESENTATIVE_SURNAME1`)}
              component={InputText}
              disabled={
                readOnlyFields ||
                fieldsConfiguration.representativeSurname1Child
                  .permission !== permissions.editable ||
                disabledByNotSelectedEntityChildTypeId
              }
              forceControlLabelInOneLine={true}
            />
          ),
          fieldsConfiguration.representativeSurname2Child &&
          fieldsConfiguration.representativeSurname2Child
            .permission !== permissions.hidden && (
            <Field
              id='representativeSurname2Child'
              name='entityChildComponent.representativeSurname2'
              key='entityChildComponent.representativeSurname2'
              colSm={4}
              controlLabel={t(`${tKey}REPRESENTATIVE_SURNAME2`)}
              placeholder={t(`${tKey}REPRESENTATIVE_SURNAME2`)}
              component={InputText}
              disabled={
                readOnlyFields ||
                fieldsConfiguration.representativeSurname2Child
                  .permission !== permissions.editable ||
                disabledByNotSelectedEntityChildTypeId
              }
              forceControlLabelInOneLine={true}
            />
          )
        ]}
        {entityChildComponent.entityTypeId !==
        entityTypesId.recurrentEntity && [
          fieldsConfiguration.addressChild &&
          fieldsConfiguration.addressChild.permission !==
            permissions.hidden && (
            <Field
              id='addressChild'
              name='entityChildComponent.address'
              key='entityChildComponent.address'
              colSm={4}
              controlLabel={t(`${tKey}ADDRESS`)}
              placeholder={t(`${tKey}ADDRESS`)}
              component={InputText}
              disabled={
                readOnlyFields ||
                fieldsConfiguration.addressChild.permission !==
                  permissions.editable ||
                disabledByNotSelectedEntityChildTypeId
              }
            />
          ),
          fieldsConfiguration.cityChild &&
          fieldsConfiguration.cityChild.permission !==
            permissions.hidden && (
            <Field
              id='cityChild'
              name='entityChildComponent.city'
              key='entityChildComponent.city'
              colSm={4}
              controlLabel={t(`${tKey}CITY`)}
              placeholder={t(`${tKey}CITY`)}
              component={InputText}
              disabled={
                readOnlyFields ||
                fieldsConfiguration.cityChild.permission !==
                  permissions.editable ||
                disabledByNotSelectedEntityChildTypeId
              }
            />
          ),
          fieldsConfiguration.stateChild &&
          fieldsConfiguration.stateChild.permission !==
            permissions.hidden && (
            <Field
              id='stateChild'
              name='entityChildComponent.state'
              key='entityChildComponent.state'
              colSm={4}
              controlLabel={t(`${tKey}STATE`)}
              placeholder={t(`${tKey}STATE`)}
              component={InputText}
              disabled={
                readOnlyFields ||
                fieldsConfiguration.stateChild.permission !==
                  permissions.editable ||
                disabledByNotSelectedEntityChildTypeId
              }
            />
          ),
          fieldsConfiguration.cpChild &&
          fieldsConfiguration.cpChild.permission !==
            permissions.hidden && (
            <Field
              id='cpChild'
              name='entityChildComponent.cp'
              key='entityChildComponent.cp'
              colSm={4}
              controlLabel={t(`${tKey}CP`)}
              placeholder={t(`${tKey}CP`)}
              component={InputText}
              disabled={
                readOnlyFields ||
                fieldsConfiguration.cpChild.permission !==
                  permissions.editable ||
                disabledByNotSelectedEntityChildTypeId
              }
            />
          ),
          fieldsConfiguration.countryChild &&
          fieldsConfiguration.countryChild.permission !==
            permissions.hidden && (
            <Field
              id='countryChild'
              name='entityChildComponent.country'
              key='entityChildComponent.country'
              colSm={4}
              controlLabel={t(`${tKey}COUNTRY`)}
              placeholder={t(`${tKey}COUNTRY`)}
              component={InputText}
              disabled={
                readOnlyFields ||
                fieldsConfiguration.countryChild.permission !==
                  permissions.editable ||
                disabledByNotSelectedEntityChildTypeId
              }
            />
          ),
          fieldsConfiguration.phoneChild &&
          fieldsConfiguration.phoneChild.permission !==
            permissions.hidden && (
            <Field
              id='phoneChild'
              name='entityChildComponent.phone'
              key='entityChildComponent.phone'
              colSm={4}
              controlLabel={t(`${tKey}PHONE`)}
              placeholder={t(`${tKey}PHONE`)}
              component={InputText}
              disabled={
                readOnlyFields ||
                fieldsConfiguration.phoneChild.permission !==
                  permissions.editable ||
                disabledByNotSelectedEntityChildTypeId
              }
            />
          ),
          fieldsConfiguration.mobilePhoneChild &&
          fieldsConfiguration.mobilePhoneChild.permission !==
            permissions.hidden && (
            <Field
              id='mobilePhoneChild'
              name='entityChildComponent.mobilePhone'
              key='entityChildComponent.mobilePhone'
              colSm={4}
              controlLabel={t(`${tKey}MOBILE`)}
              placeholder={t(`${tKey}MOBILE`)}
              component={InputText}
              disabled={
                readOnlyFields ||
                fieldsConfiguration.mobilePhoneChild
                  .permission !== permissions.editable ||
                disabledByNotSelectedEntityChildTypeId
              }
            />
          ),
          fieldsConfiguration.emailChild &&
          fieldsConfiguration.emailChild.permission !==
            permissions.hidden && (
            <Field
              id='emailChild'
              name='entityChildComponent.email'
              key='entityChildComponent.email'
              colSm={4}
              controlLabel={t(`${tKey}EMAIL`)}
              placeholder={t(`${tKey}EMAIL`)}
              component={InputText}
              disabled={
                readOnlyFields ||
                fieldsConfiguration.emailChild.permission !==
                  permissions.editable ||
                disabledByNotSelectedEntityChildTypeId
              }
            />
          ),
          fieldsConfiguration.iaeTypeIdChild &&
          fieldsConfiguration.iaeTypeIdChild.permission !==
            permissions.hidden &&
          entityChildComponent.entityTypeId ===
            entityTypesId.businessEntity && (
            <Field
              colSm={4}
              id='iaeTypeIdChild'
              name='entityChildComponent.iaeTypeId'
              key='entityChildComponent.iaeTypeId'
              controlLabel={t(`${tKey}IAE_TYPE`)}
              placeholder={t(`${tKey}IAE_TYPE`)}
              component={InputSelect}
              options={iaeTypeCombo}
              valueKey='id'
              labelKey='value'
              disabled={
                readOnlyFields ||
                fieldsConfiguration.iaeTypeIdChild.permission !==
                  permissions.editable ||
                disabledByNotSelectedEntityChildTypeId
              }
            />
          )
        ]}
        {fieldsConfiguration.rfcChild &&
        fieldsConfiguration.rfcChild.permission !==
          permissions.hidden && (
          <Field
            id='rfcChild'
            name='entityChildComponent.rfc'
            disabled={
              (isRecurrentEntityType
                ? readOnlyFields ||
                  fieldsConfiguration.rfcChild.permission !==
                    permissions.editable
                : true) ||
              entityChildComponent.entityId ||
              disabledByNotSelectedEntityChildTypeId
            }
            colSm={4}
            controlLabel={t(`${tKey}RFC`)}
            placeholder={t(`${tKey}RFC`)}
            component={InputText}
            onInputBlur={(event) => this.getEntityRfc(event)}
          />
        )}
      </Row>
    )
  }

  validateSchema () {
    const {
      fieldsConfiguration
    } = this.props
    return (
      (fieldsConfiguration.startStreetChild && fieldsConfiguration.startStreetChild.permission !== permissions.hidden) ||
      (fieldsConfiguration.enndStreetChild && fieldsConfiguration.enndStreetChild.permission !== permissions.hidden) ||
      (fieldsConfiguration.externalNumberChild && fieldsConfiguration.externalNumberChild.permission !== permissions.hidden) ||
      (fieldsConfiguration.internalNumberChild && fieldsConfiguration.internalNumberChild.permission !== permissions.hidden) ||
      (fieldsConfiguration.countryCodeChild && fieldsConfiguration.countryCodeChild.permission !== permissions.hidden) ||
      (fieldsConfiguration.stateCodeChild && fieldsConfiguration.stateCodeChild.permission !== permissions.hidden) ||
      (fieldsConfiguration.cityCodeChild && fieldsConfiguration.cityCodeChild.permission !== permissions.hidden) ||
      (fieldsConfiguration.townCodeChild && fieldsConfiguration.townCodeChild.permission !== permissions.hidden) ||
      (fieldsConfiguration.colonyCodeChild && fieldsConfiguration.colonyCodeChild.permission !== permissions.hidden) ||
      (fieldsConfiguration.cpCodeChild && fieldsConfiguration.cpCodeChild.permission !== permissions.hidden)
    )
  }

  render () {
    const {
      t,
      fieldsConfiguration,
      readOnlyFields,
      dossier: {
        entityChildComponent
      }
    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.ENTITY.'
    return (
      <div>
        {fieldsConfiguration.dniCifValidationDisableChild &&
          fieldsConfiguration.dniCifValidationDisableChild
            .permission !== permissions.hidden &&
          entityChildComponent &&
          entityChildComponent.entityTypeId !==
            entityTypesId.recurrentEntity && (
          <Col sm={4}>
            <Field
              colSm={0}
              id='dniCifValidationDisableChild'
              name='entityChildComponent.dniCifValidationDisable'
              placeholder={t(`${tKey}DISABLE_DNI_CIF_VALIDATION`)}
              component={InputCheckBox}
              disabled={
                readOnlyFields ||
                  fieldsConfiguration.dniCifValidationDisableChild
                    .permission !== permissions.editable
              }
              onInputChange={(value) =>
                this.setValidationDisable(null, null, value, null)
              }
              inline
            />
          </Col>
        )}
        {fieldsConfiguration
          .representativeDniCifValidationDisableChild &&
          fieldsConfiguration
            .representativeDniCifValidationDisableChild.permission !==
            permissions.hidden &&
          entityChildComponent &&
          entityChildComponent.entityTypeId ===
            entityTypesId.businessEntity && (
          <Col sm={4}>
            <Field
              colSm={0}
              id='representativeDniCifValidationDisableChild'
              name='entityChildComponent.representativeDniCifValidationDisable'
              placeholder={t(`${tKey}DISABLE_REPR_DNI_CIF_VALIDATION`)}
              component={InputCheckBox}
              disabled={
                readOnlyFields ||
                  fieldsConfiguration
                    .representativeDniCifValidationDisableChild.permission !==
                    permissions.editable
              }
              onInputChange={(value) =>
                this.setValidationDisable(null, null, null, value)
              }
              inline
            />
          </Col>
        )}
        {fieldsConfiguration.freeLanceChild && fieldsConfiguration.freeLanceChild.permission !== permissions.hidden &&
         entityChildComponent.entityTypeId === entityTypesId.privateEntity &&
          (<Col sm={4}>
            <Field
              colSm={0}
              id="freeLance"
              name="entityChildComponent.freeLance"
              component={InputCheckBox}
              placeholder={t(`${tKey}FREELANCE`)}
              disabled={readOnlyFields || fieldsConfiguration.freeLanceChild.permission !== permissions.editable}
              onInputChange={(value) => this.setFreelance(value)}
            />
          </Col>)
        }
        { !this.validateSchema() ? (
          this.shortSchema()
        ) : (
          this.longSchema()
        ) }
      </div>
    )
  }
}

export default FinalClient
