import apiFetch from '../apiFetch'

export default function (token, { orderBy, name, dateFrom, dateTo, columns }) {
  const queryParamsArray = [
    orderBy ? `orderBy=${orderBy}` : null,
    name ? `name=${name}` : null,
    dateFrom ? `dateFrom=${dateFrom}` : null,
    dateTo ? `dateTo=${dateTo}` : null,
    columns ? `columns=${columns}` : null
  ]
  const queryParams = queryParamsArray.reduce(
    (result, query, idx) => result + (query ? (result ? `&${query}` : `?${query}`) : '')
    , ''
  )
  return apiFetch({
    endPoint: `FtpRegister/Export${queryParams}`,
    method: 'GET',
    body: null,
    token: token,
    parseBlobAndAssign: true
  })
}
