import React, { PureComponent } from 'react'
// import { Col } from 'react-bootstrap'
import { Field } from 'redux-form'
import InputText from '../../../../../_v2/components/commons/form/InputText'
import InputSelect from '../../../../../_v2/components/commons/form/InputSelect'
import { purchaseTypesVn } from '../../../../../constants/dossier/purchase/purchaseTypes'
import { multipleNormalize, toUpperCase, trimAll } from '../../../../../util/normalizeFunctions'
import { compareObjects } from '../../../../../util/utils'

class SaleRequestFields extends PureComponent {
  constructor (props) {
    super()
    this.state = {
      purchaseType: null
    }

    this.required = this.required.bind(this)
  }

  required (value) {
    return value || value === 0 || !this.props.validateRequired ? undefined : this.props.t('GLOBAL.FORMS.REQUIRED')
  }

  UNSAFE_componentWillMount () {
    if (this.props.order) {
      this.setState({ purchaseType: this.props.order.purchaseTypeId })
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.order && !compareObjects(this.props.order || {}, nextProps.order, true)) {
      this.setState({ purchaseType: nextProps.order.purchaseTypeId })
    }
  }

  purchaseTypeChangeHandler (value) {
    if (this.props.purchaseTypeChangeHandler) {
      this.props.purchaseTypeChangeHandler(value)
    }
    this.setState({ purchaseType: value })
  }

  render () {
    const {
      t, readOnlyFields, entityTransferCombo, purchaseTypeVnCombo, namePrefix = '', /* validateRequired,  */fieldSize = 4
      // className
    } = this.props
    // const required = value => value || value === 0 || !validateRequired ? undefined : t('GLOBAL.FORMS.REQUIRED')
    const tKey = 'DOSSIER_COMPONENTS.SALE_REQUEST_MODAL.'
    return (
      <>
        <Field
          component={InputSelect}
          colWidht={25} // 25, 33, 50, 75, 100 default=25
          id="order_purchaseTypeId"
          name={namePrefix + 'purchaseTypeId'}
          colSm={fieldSize}
          controlLabel={t(`${tKey}PURCHASE_TYPE`)}
          placeholder={t(`${tKey}PURCHASE_TYPE`)}
          disabled={readOnlyFields}
          validate={this.required}
          options={purchaseTypeVnCombo}
          valueKey="id"
          labelKey="value"
          onChange={(ev, value) => this.purchaseTypeChangeHandler(value)}
        />
        {this.state.purchaseType && this.state.purchaseType === purchaseTypesVn.transfer && (
          <Field
            component={InputSelect}
            colWidht={25} // 25, 33, 50, 75, 100 default=25
            id="order_entityId"
            key={1}
            name={namePrefix + 'entityId'}
            colSm={fieldSize}
            controlLabel={t(`${tKey}ENTITY`)}
            placeholder={t(`${tKey}ENTITY`)}
            disabled={readOnlyFields}
            validate={this.required}
            options={entityTransferCombo}
            valueKey="id"
            labelKey="value"
          />
        )}
        {this.state.purchaseType && (this.state.purchaseType === purchaseTypesVn.transfer || this.state.purchaseType === purchaseTypesVn.stock) && (
          <Field
            component={InputText}
            colWidht={25} // 25, 33, 50, 75, 100 default=25
            id="order_vinChassisSaleRequest"
            key={2}
            name={namePrefix + 'vinChassis'}
            colSm={fieldSize}
            controlLabel={t(`${tKey}CHASSIS`)}
            placeholder={t(`${tKey}CHASSIS`)}
            disabled={readOnlyFields}
            validate={this.required}
            minLength={17}
            maxLength={17}
            normalize={value => multipleNormalize(value, [toUpperCase, trimAll])}
          />
        )}
        {this.state.purchaseType && (this.state.purchaseType === purchaseTypesVn.normal || this.state.purchaseType === purchaseTypesVn.production) && (
          <Field
            component={InputText}
            colWidht={25} // 25, 33, 50, 75, 100 default=25
            id="order_saleRequestMakerNumber"
            name={namePrefix + 'makerNumber'}
            colSm={fieldSize}
            controlLabel={t(`${tKey}ORDER_NUMBER`)}
            placeholder={t(`${tKey}ORDER_NUMBER`)}
            disabled={readOnlyFields}
            validate={this.state.purchaseType !== purchaseTypesVn.production ? this.required : null}
          />)}
      </>)
  }
}
export default SaleRequestFields
