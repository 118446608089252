export default {
  SEARCH_VEHICLE: 'SEARCH_VEHICLE',
  FETCH_ORDER: 'FETCH_ORDER',
  FETCH_ORDER_MODAL_SUCCESS: 'FETCH_ORDER_MODAL_SUCCESS',
  CLOSE_ORDER_MODAL: 'CLOSE_ORDER_MODAL',
  SET_SEARCH_DEALER_NUMBER: 'SET_SEARCH_DEALER_NUMBER',
  SET_SEARCH_VIN_CHASSIS: 'SET_SEARCH_VIN_CHASSIS',
  SET_SEARCH_LICENCE_PLATE: 'SET_SEARCH_LICENCE_PLATE',
  SET_SEARCH_MAKER_NUMBER: 'SET_SEARCH_MAKER_NUMBER',
  SET_SIMPLE_INPUT_TEXT_VALIDATION: 'SET_SIMPLE_INPUT_TEXT_VALIDATION',
  RESET_SEARCH_FIELDS: 'RESET_SEARCH_FIELDS',
  SAVE_ORDER: 'SAVE_ORDER',
  REFRESH_VEHICLE_COMPONENT: 'REFRESH_VEHICLE_COMPONENT',
  REFRESH_VEHICLE_COMPONENT_SUCCESS: 'REFRESH_VEHICLE_COMPONENT_SUCCESS',
  CLEAR_VEHICLE: 'CLEAR_VEHICLE',
  FETCH_COMBO_ORIGIN: 'FETCH_COMBO_ORIGIN',
  FETCH_COMBO_DESTINATION: 'FETCH_COMBO_DESTINATION',
  FETCH_COMBO_ORIGIN_SUCCESS: 'FETCH_COMBO_ORIGIN_SUCCESS',
  FETCH_COMBO_DESTINATION_SUCCESS: 'FETCH_COMBO_DESTINATION_SUCCESS',
  FETCH_ORDER_VEHICLE_CATALOGUE_MODEL: 'FETCH_ORDER_VEHICLE_CATALOGUE_MODEL',
  FETCH_ORDER_VEHICLE_CATALOGUE_MODEL_SUCCESS: 'FETCH_ORDER_VEHICLE_CATALOGUE_MODEL_SUCCESS',
  FETCH_VEHICLE_SELECTION_MODAL: 'FETCH_VEHICLE_SELECTION_MODAL',
  FETCH_VEHICLE_SELECTION_MODAL_SUCCESS: 'FETCH_VEHICLE_SELECTION_MODAL_SUCCESS',
  CLOSE_VEHICLE_SELECTION_MODAL: 'CLOSE_VEHICLE_SELECTION_MODAL',
  OPEN_VEHICLE_FINDER_MODAL: 'OPEN_VEHICLE_FINDER_MODAL',
  CLOSE_VEHICLE_CREATION_MODAL: 'CLOSE_VEHICLE_CREATION_MODAL',
  CREATE_VEHICLE_CREATION_MODAL: 'CREATE_VEHICLE_CREATION_MODAL'
}
