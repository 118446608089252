import React, { PureComponent } from 'react'
import { Tab, Tabs, Table, Button, Col, Row, Glyphicon } from 'react-bootstrap'
import SectionComponent from './managementChilds/SectionComponent'
import DocumentListItem from './managementChilds/DocumentListItem'
import { documentEntityType as documentEntityTypeConstants } from '../../../../constants/dossier/common/documentEntityType'
import { dossierTypes as dossierTypesConstant } from '../../../../constants/dossier/common/dossierTypes'
import { viewTypes } from '../../../../constants/dossier/common/documentaryManagement'
import { getFieldsPermisions } from '../../../../util/permisionFunctions'
import DocumentaryMassiveDownloadModalPage from '../../../../containers/dossiers/common/DocumentaryMassiveDownloadModalPage'

class DocumentaryManagement extends PureComponent {
  constructor () {
    super()
    this.state = {
      viewType: viewTypes.detail,
      documents: [],
      showDownloadDocumentsModal: false,
      loadDocuments: false,
      order: {
        columnName: null,
        direction: 'asc'
      },
      tabsConfig: {
        tab1: {
          showTab: true
        },
        tab2: {
          showTab: true
        },
        tab3: {
          showTab: true
        }
      },
      fieldsConfiguration: {
        downloadDocumentsButton: null
      }
    }
  }

  getEntityTitle () {
    const tKey = 'DOSSIER_COMPONENTS.DOCUMENTARY_MANAGEMENT.'
    switch (this.props.dossierType) {
      case dossierTypesConstant.sales:
        return this.props.t(`${tKey}CLIENT`)
      case dossierTypesConstant.purchase:
        return this.props.t(`${tKey}VENDOR`)
      default:
        return this.props.t(`${tKey}ENTITY`)
    }
  }

  onSelectView (viewType) {
    this.props.actions.refreshDocumentManagementModal(this.props.dossier.dossierId, true)
    this.setState({ viewType })
  }

  onSelectTab (eventKey, props = this.props) {
    let tab
    switch (eventKey) {
      case 1:
        tab = props.tabCounters.tab1
        break
      case 2:
        tab = props.tabCounters.tab2
        break
      case 3:
        tab = props.tabCounters.tab3
        break
      case 4:
        tab = props.tabCounters.tab4
        break
      default:
        tab = props.tabCounters.tab1
    }
    props.actions.setCurrentTabCounterValues(tab, eventKey)
  }

  checkTabsHasDocuments (tab1, tab2, tab3) {
    let tabsConfig = {
      tab1: {
        showTab: true
      },
      tab2: {
        showTab: true
      },
      tab3: {
        showTab: true
      }
    }
    let selectedTab = 1

    if (tab1 && tab1.length === 0) {
      tabsConfig.tab1.showTab = false
      selectedTab = 2
    }
    if (tab2 && tab2.length === 0) {
      tabsConfig.tab2.showTab = false
      selectedTab = tabsConfig.tab1.showTab ? 1 : 3
    }
    if (tab3 && tab3.length === 0) {
      tabsConfig.tab3.showTab = false
      selectedTab = tabsConfig.tab1.showTab ? 1 : tabsConfig.tab2.showTab ? 2 : 1
    }
    this.setState({ tabsConfig })
    if (this.state.firstLoad) {
      this.setState({ selectedTab, firstLoad: false })
    }
  }

  componentDidMount () {
    this.props.actions.refreshDocumentManagementModal(this.props.dossier.dossierId, true)
    this.initializePermisions(this.props)
    this.setState({ loadDocuments: true })
  }

  initializePermisions (props) {
    if (props.tabConfiguration && props.tabConfiguration.componentsConfiguration && props.tabConfiguration.componentsConfiguration.length > 0) {
      const fieldKeys = Object.keys(this.state.fieldsConfiguration)
      const newState = getFieldsPermisions(props.tabConfiguration.componentsConfiguration[0].fieldsConfiguration, fieldKeys, props.tabConfiguration.componentsConfiguration[0].permission)
      this.setState({ ...this.state, ...newState })
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    let documents = []
    nextProps.sectionsDocuments.tab1 && nextProps.sectionsDocuments.tab1.map((section) => {
      documents = documents.concat(section.documents.map((document) => ({ ...document, documentEntityType: documentEntityTypeConstants.ENTITY, objectId: nextProps.entityId })))
    })
    nextProps.sectionsDocuments.tab2 && nextProps.sectionsDocuments.tab2.map((section) => {
      documents = documents.concat(section.documents.map((document) => ({ ...document, documentEntityType: documentEntityTypeConstants.VEHICLE, objectId: nextProps.vehicleId })))
    })
    nextProps.sectionsDocuments.tab3 && nextProps.sectionsDocuments.tab3.map((section) => {
      documents = documents.concat(section.documents.map((document) => ({ ...document, documentEntityType: documentEntityTypeConstants.DOSSIER, objectId: nextProps.dossierId })))
    })
    nextProps.sectionsDocuments.tab1 && nextProps.sectionsDocuments.tab2 && nextProps.sectionsDocuments.tab3 && this.checkTabsHasDocuments(nextProps.sectionsDocuments.tab1, nextProps.sectionsDocuments.tab2, nextProps.sectionsDocuments.tab3)
    this.setState({ documents })
  }

  orderTable () {
    const columnName = this.state.order.columnName

    const documents = this.state.documents.sort(function (a, b) {
      if (columnName === 'validationDate') {
        const fechaInicial = a.validationDate ? a.validationDate.split(' ') : ['-']
        const separarA = fechaInicial.map((dia) => dia[0] === '-' ? ('01,01,2000,00,00') : (fechaInicial[0].split('/').join(',') + ',' + fechaInicial[1].split(':').join(',')))
        const fechaA = separarA.map((datos) => datos.split(','))
        const fechaFinal = b.validationDate ? b.validationDate.split(' ') : ['-']
        const separarB = fechaFinal.map((dia) => dia[0] === '-' ? ('01,01,2000,00,00') : (fechaFinal[0].split('/').join(',') + ',' + fechaFinal[1].split(':').join(',')))
        const fechaB = separarB.map((datos) => datos.split(','))
        const fecha1 = new Date(fechaA[0][2], fechaA[0][1], fechaA[0][0], fechaA[0][3], fechaA[0][4])
        const fecha2 = new Date(fechaB[0][2], fechaB[0][1], fechaB[0][0], fechaB[0][3], fechaB[0][4])
        return fecha2 - fecha1
      } else if (columnName === 'incorporationDate') {
        const fechaInicial = a.incorporationDate ? a.incorporationDate.split(' ') : ['-']
        const separarA = fechaInicial.map((dia) => dia[0] === '-' ? ('01,01,2000,00,00') : (fechaInicial[0].split('/').join(',') + ',' + fechaInicial[1].split(':').join(',')))
        const fechaA = separarA.map((datos) => datos.split(','))
        const fechaFinal = b.incorporationDate ? b.incorporationDate.split(' ') : ['-']
        const separarB = fechaFinal.map((dia) => dia[0] === '-' ? ('01,01,2000,00,00') : (fechaFinal[0].split('/').join(',') + ',' + fechaFinal[1].split(':').join(',')))
        const fechaB = separarB.map((datos) => datos.split(','))
        const fecha1 = new Date(fechaA[0][2], fechaA[0][1], fechaA[0][0], fechaA[0][3], fechaA[0][4])
        const fecha2 = new Date(fechaB[0][2], fechaB[0][1], fechaB[0][0], fechaB[0][3], fechaB[0][4])
        return fecha2 - fecha1
      } else if (columnName === 'checklistStatus') {
        return a.checklistStatus - b.checklistStatus
      } else if (a[columnName] && a[columnName].localeCompare) {
        return a[columnName].localeCompare(b[columnName])
      } else {
        return (isNaN(a[columnName]) ? 0 : a[columnName]) - (isNaN(b[columnName]) ? 0 : b[columnName])
      }
    })
    if (this.state.order.direction === 'asc') {
      documents.reverse()
    }
    this.setState({
      documents
    })
  }

  changeDirection (columnName) {
    this.setState({
      order: {
        columnName: columnName,
        direction: this.state.order.direction === 'asc' ? 'desc' : 'asc'
      }
    }, this.orderTable)
  }

  changeOrderIcon (name) {
    let clase = 'ico-sort'
    if (this.state.order.direction === 'asc' && this.state.order.columnName === name) {
      clase += '-selected-asc'
    } else if (this.state.order.direction === 'desc' && this.state.order.columnName === name) {
      clase += '-selected-desc'
    }
    return clase
  }

  openMassiveDownloadModal () {
    new Promise((resolve, reject) => {
      this.props.actions.getDocumentsMassiveDownload(this.props.dossierId, true, resolve, reject)
    }).then(() => {
      this.setState({ massiveDownloadModal: true })
    })
  }

  render () {
    const {
      t, dossierId, dossierType, entityId, vehicleId, isHistorical, // showRelatedDocuments = true, // documentEntityType, documentStatus,
      sectionsDocuments: { tab1 = [], tab2 = [], tab3 = [] },
      defaultTab = 1,
      actions: { openDocumentCreationModal, openDocumentEditionModal, cancelDocument, addDependant, printCheckList, openModal, openDocumentEditionModalNotHistorical },
      dossier: { dossierSubTypeId, organizedUnitId }
    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.DOCUMENTARY_MANAGEMENT.'
    return (
      <Row className="management-doc-modal">
        <DocumentaryMassiveDownloadModalPage
          dossierId={dossierId}
          isHistorical={true}
          showModal={this.state.massiveDownloadModal}
          closeModal={() => this.setState({ massiveDownloadModal: false })}></DocumentaryMassiveDownloadModalPage>
        <Col sm={6} className="col-row superior-gestion-documental">
          <h4><i className="ico-docs"/> {t(`${tKey}TITLE`)}</h4>
        </Col>
        <Col sm={12} className="text-right">
          <div className="botones-listado-expediente">
            <Button className={'motrar-listado ' + (this.state.viewType === viewTypes.detail && 'active')} onClick={() => this.onSelectView(viewTypes.detail)}><Glyphicon glyph="th-list"/></Button>
            <Button className={'motrar-listado ' + (this.state.viewType === viewTypes.mosaic && 'active')} onClick={() => this.onSelectView(viewTypes.mosaic)}><Glyphicon glyph="th-large"/></Button>
          </div>
        </Col>
        <Col className="col-row superior-gestion-documental boton-anadir-documento">
          <Button id="massiveDownloadButton" onClick={() => this.openMassiveDownloadModal()}>{t(`${tKey}MASSIVE_DOWNLOAD_MODAL.DOWNLOAD`)}</Button>
          <Button
            className="btn-standard"
            onClick={() => openDocumentCreationModal(dossierId, documentEntityTypeConstants.DOSSIER, organizedUnitId, entityId, vehicleId, () => this.props.actions.refreshDocumentManagementModal(this.props.dossier.dossierId, true), true, this.props.dossierType)}
          >
            {t(`${tKey + 'ADD_DOCUMENT'}`)}
          </Button>
        </Col>
        <Col sm={12}>
          {
            this.state.viewType === viewTypes.mosaic && (
              <Tabs id="1" defaultActiveKey={defaultTab}
                onSelect={(eventKey) => this.onSelectTab(eventKey)}>
                { this.state.tabsConfig.tab1.showTab ? (
                  <Tab eventKey={1} title={this.getEntityTitle()} className="user-tab">
                    {
                      tab1.map((section, idx) => (<SectionComponent
                        key={idx}
                        t={t}
                        section={section}
                        dossierType={dossierType}
                        documentEntityType={documentEntityTypeConstants.ENTITY}
                        objectId={entityId}
                        actions={{
                          openDocumentEditionModalWithChecklistId: (documentId, documentTypeUse, checklistId) =>
                            openDocumentEditionModal(documentId, documentTypeUse, documentEntityTypeConstants.ENTITY, dossierId, entityId, vehicleId, organizedUnitId,
                              checklistId, () => this.props.actions.refreshDocumentManagementModal(this.props.dossier.dossierId, true), true, this.props.dossierType, dossierSubTypeId),
                          cancelDocument,
                          printCheckList,
                          addDependant,
                          openModal
                        }}
                      />))
                    }
                  </Tab>) : null }
                { this.state.tabsConfig.tab2.showTab ? (
                  <Tab eventKey={2} title={t(`${tKey}VEHICLE`)} className="user-tab">
                    {
                      tab2.map((section, idx) => (<SectionComponent
                        key={idx}
                        t={t}
                        section={section}
                        dossierType={dossierType}
                        documentEntityType={documentEntityTypeConstants.VEHICLE}
                        objectId={vehicleId}
                        actions={{
                          openDocumentEditionModalWithChecklistId: (documentId, documentTypeUse, checklistId) => openDocumentEditionModal(documentId, documentTypeUse, documentEntityTypeConstants.VEHICLE, dossierId, entityId, vehicleId, organizedUnitId, checklistId, () => this.props.actions.refreshDocumentManagementModal(this.props.dossier.dossierId, true), true, this.props.dossierType, dossierSubTypeId),
                          cancelDocument,
                          printCheckList,
                          addDependant,
                          openModal
                        }}
                      />))
                    }
                  </Tab>) : null }
                { this.state.tabsConfig.tab3.showTab ? (
                  <Tab eventKey={3} title={t(`${tKey}DOSSIER`)} className="user-tab">
                    <Button
                      className="btn-standard"
                      onClick={() => openDocumentCreationModal(dossierId, documentEntityTypeConstants.DOSSIER, organizedUnitId, entityId, vehicleId, () => this.props.actions.refreshDocumentManagementModal(this.props.dossier.dossierId, true), true, this.props.dossierType)}
                    >
                      <i className="ico-document-plus"/>
                      <span>{t(`${tKey}ADD_OR_EDIT_DOCUMENT.ADD`)}</span>
                    </Button>
                    {
                      tab3.map((section, idx) => (<SectionComponent
                        key={idx}
                        t={t}
                        section={section}
                        dossierType={dossierType}
                        documentEntityType={documentEntityTypeConstants.DOSSIER}
                        objectId={dossierId}
                        actions={{
                          openDocumentEditionModalWithChecklistId: (documentId, documentTypeUse, checklistId) => openDocumentEditionModal(documentId, documentTypeUse, documentEntityTypeConstants.DOSSIER, dossierId, entityId, vehicleId, organizedUnitId, checklistId, () => this.props.actions.refreshDocumentManagementModal(this.props.dossier.dossierId, true), true, this.props.dossierType, dossierSubTypeId),
                          cancelDocument,
                          printCheckList,
                          addDependant,
                          openModal
                        }}
                      />))
                    }
                  </Tab>) : null }
                {
                  /* showRelatedDocuments
                  ? (
                    <Tab eventKey={4} title={t(`${tKey}RELATED`)} className="user-tab">
                      {
                        tab4.map((section, idx) => (<SectionComponent
                          t={t}
                          key={idx}
                          section={section}
                          dossierType={dossierType}
                          readOnlyDoc={true}
                          actions={{}}
                        />))
                      }
                    </Tab>
                  ) : null */
                }
              </Tabs>
            )}
          {
            this.state.viewType === viewTypes.detail && (
              <div>
                <Table hover responsive className="simple-table gestion-documental-table" id="documentary-management-table">
                  <thead>
                    <tr>
                      <th onClick={() => [this.changeDirection('checklistStatus')]}><i className={(() => [this.changeOrderIcon('checklistStatus')])()}/></th>
                      <th onClick={() => [this.changeDirection('documentStatus')]}><span>{t(`${tKey + 'TABLE.STATUS'}`)}<i className={(() => [this.changeOrderIcon('documentStatus')])()}/></span></th>
                      <th onClick={() => [this.changeDirection('documentName')]}><span>{t(`${tKey + 'TABLE.TYPES'}`)}<i className={(() => [this.changeOrderIcon('documentName')])()}/></span></th>
                      <th onClick={() => [this.changeDirection('documentEntityType')]}><span>{t(`${tKey + 'TABLE.CATEGORY'}`)}<i className={(() => [this.changeOrderIcon('documentEntityType')])()}/></span></th>
                      <th onClick={() => [this.changeDirection('validationDate')]}><span>{t(`${tKey + 'TABLE.VALIDATION_DATE'}`)}<i className={(() => [this.changeOrderIcon('validationDate')])()}/></span></th>
                      <th onClick={() => [this.changeDirection('incorporationDate')]}><span>{t(`${tKey + 'TABLE.ASSIGNMENT_DATE'}`)}<i className={(() => [this.changeOrderIcon('incorporationDate')])()}/></span></th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      this.state.documents.map((documentFile, idx) => <DocumentListItem
                        t={t}
                        key={idx}
                        readOnly={false}
                        dossierType={dossierType}
                        documentEntityType={documentFile.documentEntityType}
                        documentFile={documentFile}
                        objectId={documentFile.objectId}
                        actions={{
                          openDocumentEditionModalWithChecklistId: (documentId, documentTypeUse, checklistId) =>
                            documentFile.isHistorical !== false
                              ? openDocumentEditionModal(documentId,
                                documentTypeUse,
                                documentFile.documentEntityType,
                                dossierId,
                                documentFile.objectId,
                                vehicleId,
                                organizedUnitId,
                                checklistId,
                                () => this.props.actions.refreshDocumentManagementModal(this.props.dossier.dossierId, true),
                                true,
                                this.props.dossierType,
                                dossierSubTypeId)
                              : openDocumentEditionModalNotHistorical(documentId,
                                documentTypeUse,
                                documentFile.documentEntityType,
                                dossierId,
                                documentFile.objectId,
                                vehicleId,
                                organizedUnitId,
                                checklistId,
                                () => this.props.actions.refreshDocumentManagementModal(dossierId, true),
                                false,
                                this.props.dossierType,
                                dossierSubTypeId, null, null, null, null, null, isHistorical),
                          cancelDocument,
                          printCheckList,
                          addDependant,
                          openModal
                        }}
                      />)
                    }
                  </tbody>
                </Table>
              </div>
            )}
        </Col>
      </Row>
    )
  }
}

export default DocumentaryManagement
