import auxModals from '../../../constants/actions/management/auxModals/auxModals'
import managementActionTypes from '../../../constants/actions/management/management'

function initialState () {
  return {
    fleetCreatedSelectChargeModal: {
      showModal: false,
      fleet: null
    },
    addDossiersToFleetModal: {
      showModal: false,
      fleet: null,
      dossiersFleetToAdd: [],
      hasSearch: false,
      filters: null
    },
    dataSalesFleetModal: {
      showModal: false,
      fleet: null,
      dossiers: [],
      dossier: {
        salesComponent: {
          paymentSignalStatus: null,
          signalPayNotRequired: false,
          deliveryDestination: null,
          salesmanId: null
        },
        salesDocumentaryCheckList: [],
        dossierId: -1,
        organizedUnitId: null,
        stageId: null
      }
    },
    dataContactFleetModal: {
      showModal: false,
      fleet: null,
      dossiers: [],
      dossierId: -1,
      organizedUnitId: null,
      stageId: null,
      dossier: {
        isPreCancelled: false,
        dossierParentId: null,
        parentEntityId: null,
        entityComponent: { entityTypeId: null, dniCifValidationDisable: false, representativeDniCifValidationDisable: false },
        entityChildComponent: { entityTypeId: null, dniCifValidationDisable: false, representativeDniCifValidationDisable: false },
        entityTypeIdDefault: null,
        salesComponent: null,
        entityFromParentDossier: null,
        dossierSubTypeId: null,
        organizedUnitId: null,
        dossierId: -1
      }
    },
    dataPaperWorkFleetModal: {
      showModal: false,
      fleet: null,
      dossiers: [],
      dossierId: -1,
      organizedUnitId: null,
      stageId: null,
      fieldConfiguration: null,
      dossierPaperworkComponent: { economicTarget: null, showEconomicPlan: false }
    },
    showEconomicFLeet: false,
    dataFinancingFleetModal: {
      showModal: false,
      fleet: null,
      dossiers: [],
      dossier: {
        dossierFinancingComponent: {
          renovation: false,
          updated: false,
          repurchaseAgreement: false
        },
        dossierId: -1
      },
      organizedUnitId: null,
      stageId: null
    },
    documentDetailsFleetModal: {
      showModal: false,
      fleet: null,
      documentDetails: [],
      documentFleet: null,
      filters: null,
      dossierList: [],
      dossierMode: false
    },
    dataDeliveryFleetModal: {
      showModal: false,
      fleet: null,
      dossiers: [],
      dossier: {
        deliveryComponent: {},
        salesDocumentaryCheckList: [],
        dossierId: -1,
        organizedUnitId: null,
        stageId: null
      }
    },
    chargeDossiersModal: {
      showModal: false,
      errors: null
    },
    unattendedProcessDownloadFileModal: {
      showModal: false,
      unnatendedProcessId: null,
      fileInfoList: []
    },
    combos: {
      fleetOrganizedUnits: []
    },
    chargeDetailsModal: {
      showModal: false,
      fleetCharge: null,
      dossierChargeDetails: [],
      fleet: null
    }
  }
}

export function closeFleetCreatedSelectChargeModal (state) {
  return {
    ...state,
    fleetCreatedSelectChargeModal: initialState().fleetCreatedSelectChargeModal
  }
}

export function openFleetCreatedSelectChargeModal (state, { fleet }) {
  return {
    ...state,
    fleetCreatedSelectChargeModal: {
      showModal: true,
      fleet
    }
  }
}

export function closeAddDossiersToFleetModal (state) {
  return {
    ...state,
    addDossiersToFleetModal: initialState().addDossiersToFleetModal
  }
}

export function openAddDossiersToFleetModal (state, { fleet }) {
  return {
    ...state,
    addDossiersToFleetModal: {
      showModal: true,
      fleet: fleet,
      dossiersFleetToAdd: [],
      hasSearch: false,
      filters: null
    }
  }
}

export function fetchDossiersToAddToFleetSuccess (state, { dossiers, filters }) {
  return {
    ...state,
    addDossiersToFleetModal: {
      ...state.addDossiersToFleetModal,
      dossiersFleetToAdd: dossiers,
      hasSearch: true,
      filters: filters
    }
  }
}

export function resetDossiersToAddToFleetSearch (state) {
  return {
    ...state,
    addDossiersToFleetModal: {
      ...state.addDossiersToFleetModal,
      dossiersFleetToAdd: [],
      hasSearch: false,
      filters: null
    }
  }
}

export function openDataDeliveryFleetModal (state, { fleet, dossiers }) {
  return {
    ...state,
    dataDeliveryFleetModal: {
      ...state.dataDeliveryFleetModal,
      showModal: true,
      fleet,
      dossiers: dossiers,
      dossier: {
        ...state.dataDeliveryFleetModal.dossier,
        organizedUnitId: fleet.organizedUnitIdDossier,
        stageId: fleet.stageId
      }
    }
  }
}

export function closeDataDeliveryFleetModal (state) {
  return {
    ...state,
    dataDeliveryFleetModal: initialState().dataDeliveryFleetModal
  }
}

export function openDataSalesFleetModal (state, { fleet, dossiers }) {
  return {
    ...state,
    dataSalesFleetModal: {
      ...state.dataSalesFleetModal,
      showModal: true,
      fleet,
      dossiers: dossiers,
      dossier: {
        ...state.dataSalesFleetModal.dossier,
        organizedUnitId: fleet.organizedUnitIdDossier,
        stageId: fleet.stageId
      }
    }
  }
}

export function closeDataSalesFleetModal (state) {
  return {
    ...state,
    dataSalesFleetModal: initialState().dataSalesFleetModal
  }
}

export function openDataContactFleetModal (state, { fleet, dossiers }) {
  return {
    ...state,
    dataContactFleetModal: {
      ...state.dataContactFleetModal,
      showModal: true,
      fleet: fleet,
      dossiers: dossiers,
      organizedUnitId: fleet.organizedUnitIdDossier,
      stageId: fleet.stageId,
      dossier: {
        ...state.dataContactFleetModal.dossier,
        dossierSubTypeId: fleet.dossierSubType,
        organizedUnitId: fleet.organizedUnitIdDossier,
        entityComponent: fleet.entityComponent ?? { entityTypeId: null, dniCifValidationDisable: false, representativeDniCifValidationDisable: false },
        entityChildComponent: fleet.finalEntity ?? { entityTypeId: null, dniCifValidationDisable: false, representativeDniCifValidationDisable: false },
        dossierContactComponent: fleet.dossierContactComponent
      }
    }
  }
}

export function closeDataContactFleetModal (state) {
  return {
    ...state,
    dataContactFleetModal: initialState().dataContactFleetModal
  }
}

export function fetchEntityOperateChildFleetSucces (state, { data }) {
  return {
    ...state,
    dataContactFleetModal: {
      ...state.dataContactFleetModal,
      dossier: {
        ...state.dataContactFleetModal.dossier,
        entityChildComponent: data.entityChildComponent
      }
    }
  }
}

export function fetchEntityOperateFleetSucces (state, { data }) {
  return {
    ...state,
    dataContactFleetModal: {
      ...state.dataContactFleetModal,
      dossier: {
        ...state.dataContactFleetModal.dossier,
        entityComponent: data.entityComponent
      }
    }
  }
}

export function fetchOperatePaperworkFleetPermissionsSuccess (state, { data }) {
  return {
    ...state,
    dataPaperWorkFleetModal: {
      ...state.dataPaperWorkFleetModal,
      fieldConfiguration: data
    }
  }
}

export function openDataPaperWorkFleetModal (state, { fleet, dossiers }) {
  return {
    ...state,
    dataPaperWorkFleetModal: {
      ...state.dataPaperWorkFleetModal,
      showModal: true,
      fleet: fleet,
      dossiers: dossiers,
      organizedUnitId: fleet.organizedUnitIdDossier,
      stageId: fleet.stageId
    }
  }
}

export function closeDataPaperWorkFleetModal (state) {
  return {
    ...state,
    dataPaperWorkFleetModal: initialState().dataPaperWorkFleetModal
  }
}

export function getShowEconomicSuccess (state, { showEconomicFLeet }) {
  return {
    ...state,
    showEconomicFLeet: showEconomicFLeet,
    dataPaperWorkFleetModal: {
      ...state.dataPaperWorkFleetModal,
      dossierPaperworkComponent: {
        ...state.dataPaperWorkFleetModal.dataPaperWorkFleetModal,
        showEconomicPlan: showEconomicFLeet
      }
    }
  }
}

export function openDataFinancingFleetModal (state, { fleet, dossiers }) {
  return {
    ...state,
    dataFinancingFleetModal: {
      ...state.dataFinancingFleetModal,
      showModal: true,
      fleet,
      dossiers: dossiers,
      organizedUnitId: fleet.organizedUnitIdDossier,
      stageId: fleet.stageId
    }
  }
}

export function closeDataFinancingFleetModal (state) {
  return {
    ...state,
    dataFinancingFleetModal: initialState().dataFinancingFleetModal
  }
}

export function openDocumentDetailsFleetModal (state, { fleet, documentFleet, dossierList, dossierMode }) {
  return {
    ...state,
    documentDetailsFleetModal: {
      showModal: true,
      fleet: fleet,
      documentDetails: [],
      documentFleet: documentFleet,
      filters: null,
      dossierList: dossierList,
      dossierMode: dossierMode
    }
  }
}

export function closeDocumentDetailsFleetModal (state) {
  return {
    ...state,
    documentDetailsFleetModal: initialState().documentDetailsFleetModal
  }
}

export function fetchDocumentDetailsFleetSuccess (state, { documentDetails }) {
  return {
    ...state,
    documentDetailsFleetModal: {
      ...state.documentDetailsFleetModal,
      documentDetails: documentDetails
    }
  }
}

export function closeChargeDossiersModal (state) {
  return {
    ...state,
    chargeDossiersModal: initialState().chargeDossiersModal
  }
}

export function openChargeDossiersModal (state, { fleet }) {
  return {
    ...state,
    chargeDossiersModal: {
      showModal: true,
      fleet: fleet
    }
  }
}

export function updateChargeDossiersModal (state, { errors }) {
  return {
    ...state,
    chargeDossiersModal: {
      ...state.chargeDossiersModal,
      errors: errors
    }
  }
}

export function openCloseUnattendedProcessDownloadFileModal (state, { showModal, unattendedProcessId }) {
  return {
    ...state,
    unattendedProcessDownloadFileModal: {
      showModal: showModal,
      unattendedProcessId: unattendedProcessId,
      fileInfoList: []
    }
  }
}

export function fetchUnattendedProcessFileListInfoSucces (state, { fileInfoList }) {
  return {
    ...state,
    unattendedProcessDownloadFileModal: {
      ...state.unattendedProcessDownloadFileModal,
      fileInfoList: fileInfoList
    }
  }
}

export function fetchLowestFleetDependentOusComboSuccess (state, { fleetOrganizedUnits }) {
  return {
    ...state,
    combos: {
      fleetOrganizedUnits: fleetOrganizedUnits
    }
  }
}

export function openChargeDetailsModalSuccess (state, { fleetCharge, dossierChargeDetails, fleet }) {
  return {
    ...state,
    chargeDetailsModal: {
      showModal: true,
      fleetCharge: fleetCharge,
      dossierChargeDetails: dossierChargeDetails,
      fleet: fleet
    }
  }
}

export function closeChargeDetailsModal (state) {
  return {
    ...state,
    chargeDetailsModal: initialState().chargeDetailsModal
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case auxModals.CLOSE_FLEET_CREATED_SELECT_CHARGE_MODAL:
      return closeFleetCreatedSelectChargeModal(state, action)
    case auxModals.OPEN_FLEET_CREATED_SELECT_CHARGE_MODAL:
      return openFleetCreatedSelectChargeModal(state, action)
    case auxModals.CLOSE_ADD_DOSSIERS_TO_FLEET_MODAL:
      return closeAddDossiersToFleetModal(state, action)
    case auxModals.OPEN_ADD_DOSSIERS_TO_FLEET_MODAL:
      return openAddDossiersToFleetModal(state, action)
    case auxModals.FETCH_DOSSIERS_TO_ADD_TO_FLEET_SUCCESS:
      return fetchDossiersToAddToFleetSuccess(state, action)
    case auxModals.DOSSIERS_TO_ADD_TO_FLEET_RESET_SEARCH:
      return resetDossiersToAddToFleetSearch(state, action)
    case auxModals.OPEN_DATA_SALES_FLEET_MODAL:
      return openDataSalesFleetModal(state, action)
    case auxModals.CLOSE_DATA_SALES_FLEET_MODAL:
      return closeDataSalesFleetModal(state, action)
    case auxModals.OPEN_DATA_CONTACT_FLEET_MODAL:
      return openDataContactFleetModal(state, action)
    case auxModals.CLOSE_DATA_CONTACT_FLEET_MODAL:
      return closeDataContactFleetModal(state, action)
    case auxModals.FETCH_ENTITY_OPERATE_FLEET_SUCCES:
      return fetchEntityOperateFleetSucces(state, action)
    case auxModals.FETCH_ENTITY__OPERATE_CHILD_FLEET_SUCCES:
      return fetchEntityOperateChildFleetSucces(state, action)
    case managementActionTypes.FETCH_OPERATE_PAPERWORK_FLEET_PERMISSIONS_SUCCESS:
      return fetchOperatePaperworkFleetPermissionsSuccess(state, action)
    case auxModals.OPEN_DATA_PAPERWORK_FLEET_MODAL:
      return openDataPaperWorkFleetModal(state, action)
    case auxModals.CLOSE_DATA_PAPERWORK_FLEET_MODAL:
      return closeDataPaperWorkFleetModal(state, action)
    case auxModals.GET_FLEET_SHOW_ECONOMIC_SUCCESS:
      return getShowEconomicSuccess(state, action)
    case auxModals.OPEN_DATA_FINANCING_FLEET_MODAL:
      return openDataFinancingFleetModal(state, action)
    case auxModals.CLOSE_DATA_FINANCING_FLEET_MODAL:
      return closeDataFinancingFleetModal(state, action)
    case auxModals.OPEN_DOCUMENT_DETAILS_FLEET_MODAL:
      return openDocumentDetailsFleetModal(state, action)
    case auxModals.CLOSE_DOCUMENT_DETAILS_FLEET_MODAL:
      return closeDocumentDetailsFleetModal(state, action)
    case auxModals.FETCH_DOCUMENT_DETAIL_FLEET_SUCCESS:
      return fetchDocumentDetailsFleetSuccess(state, action)
    case auxModals.OPEN_DATA_DELIVERY_FLEET_MODAL:
      return openDataDeliveryFleetModal(state, action)
    case auxModals.CLOSE_DATA_DELIVERY_FLEET_MODAL:
      return closeDataDeliveryFleetModal(state, action)
    case auxModals.CLOSE_CHARGE_DOSSIERS_TO_FLEET_MODAL:
      return closeChargeDossiersModal(state, action)
    case auxModals.OPEN_CHARGE_DOSSIERS_TO_FLEET_MODAL:
      return openChargeDossiersModal(state, action)
    case auxModals.UPDATE_CHARGE_DOSSIERS_TO_FLEET_MODAL:
      return updateChargeDossiersModal(state, action)
    case auxModals.OPEN_CLOSE_UNATTENDED_PROCESS_DOWNLOAD_FILE_MODAL:
      return openCloseUnattendedProcessDownloadFileModal(state, action)
    case auxModals.GET_UNATTENDED_PROCESS_FILE_LIST_INFO_SUCCESS:
      return fetchUnattendedProcessFileListInfoSucces(state, action)
    case auxModals.FETCH_FLEET_ORGANIZED_UNITS_SUCCESS:
      return fetchLowestFleetDependentOusComboSuccess(state, action)
    case auxModals.OPEN_CHARGE_DETAILS_TO_FLEET_MODAL_SUCCESS:
      return openChargeDetailsModalSuccess(state, action)
    case auxModals.CLOSE_CHARGE_DETAILS_TO_FLEET_MODAL:
      return closeChargeDetailsModal(state, action)
    default:
      return state
  }
}
