import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import FaqModal from '../../components/faq/FaqModal'
import { fetchQuestion, voteQuestion } from '../../actions/faq/faq'

export function mapStateToProps (state) {
  return {
    ...state.faq
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchQuestion,
      voteQuestion
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(FaqModal))
