import { put, call, takeEvery, select } from 'redux-saga/effects'
import dossierActionsTypes from '../../../constants/actions/dossier/dossier'
import { launchCampaignProcessFilesSucces } from '../../../actions/dossier/purchase'
import launchCampaignProcessFiles from '../../../services/purchaseCampaignProcessFiles/launchCampaignProcessFiles'
import { showLoader, hideLoader, openModal } from '../../../actions/common'
import { handleError } from '../../errors/errorManager'
import { getAuth } from '../../../selectors/access/auth'
import messageModalActions from '../../../actions/modals/messageModal'

export function * launchCampaignProcess () {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    let data = yield call(launchCampaignProcessFiles, auth.token)
    yield put(openModal('purchaseCampaignProcces', null, null, null, null, [{ id: '##NUM_FILES##', value: (data.processed + data.failed) }, { id: '##NUM_CORRECT##', value: data.processed }, { id: '##NUM_INCORRECT##', value: data.failed }], null))
    yield put(launchCampaignProcessFilesSucces({ data }))
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {}
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchlaunchCampaignProcess () {
  yield takeEvery(dossierActionsTypes.LAUNCH_CAMPAIGN_PROCESS_FILES, launchCampaignProcess)
}
