import { bindActionCreators } from 'redux'
import { change } from 'redux-form'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { fetchMaritalStatusList, deleteMaritalStatus, openEditMaritalStatusModal, submitMaritalStatus, openMaritalStatusModal } from '../../../actions/masters/maritalStatus/maritalStatus'
import { setChangeTabFromConfMaster } from '../../../actions/masters/masters'
import MaritalStatusMaster from '../../../components/masters/maritalStatus/MaritalStatusMaster'

export function mapStateToProps (state) {
  return {
    ...state.maritalStatus
  }
}
export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      deleteMaritalStatus,
      openMaritalStatusModal,
      openEditMaritalStatusModal,
      fetchMaritalStatusList,
      submitMaritalStatus,
      setChangeTabFromConfMaster,
      change
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(MaritalStatusMaster))
