import React, { PureComponent } from 'react'
import { FormGroup, FormControl, Col, ControlLabel } from 'react-bootstrap'
import { formatMoney } from '../../../util/utils'
import setting from '../../../setting'
import { replaceCurrencySYmbol, validCurrency } from '../../../util/currencyValidations'
import { currencySymbol } from '../../../constants/dossier/common/currencies'

class SimpleInputMoney extends PureComponent {
  constructor (props) {
    super()
    const value = props.value ? ('' + props.value).trim() : ''
    this.state = {
      realValue: value,
      showedValue: value !== '' ? formatMoney(value) : '',
      hasFocus: false
    }
  }

  handleChange (event) {
    if (this.props.input && this.props.input.onChange) {
      this.props.input.onChange(event)
    }
    if (this.props.onInputChange) {
      this.props.onInputChange(event.target.value)
    }
  }

  handleFocus (event) {
    const currentTarget = event.currentTarget
    this.setState({
      showedValue: ('' + this.state.realValue).replace(/^(.*?)\.?$/, '$1'), // Si no hay decimales se eliminara el separador.
      hasFocus: true
    }, () => {
      // Timeout requerido en los navegadores Explorer y Edge.
      window.setTimeout(() => {
        currentTarget.select() // Seleccionar el contenido del input.
      }, 50)
    })
  }

  handleBlur (event) {
    const newState = { hasFocus: false }
    let value
    if (!isNaN(this.state.realValue)) {
      // Eliminar los ceros a la izquierda
      value = ('' + this.state.realValue).replace(/^([+-]?)(0*)([1-9]\d*|\d)(\.\d{1,2})?$/, '$1$3$4')
      newState.realValue = value
      newState.showedValue = formatMoney(value)
    }
    this.setState(newState)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (this.props.value !== nextProps.value) {
      let isValid = false
      let isCurrency = false
      let realValue = ('' + nextProps.value).trim()
      let showedValue = ('' + nextProps.value).trim()

      // Comprobar si el valor introducido es un número con separador de decimales. Este separador puede ser ',' o '.'.
      isValid = /^[+-]?[\d]+[,.]?[\d]*$/.test(realValue)
      if (!isValid) {
        isCurrency = validCurrency(realValue, setting.currencyRegex)
      }

      if (isValid) {
        // Sanitizar: convertir ',' por '.' y eliminar el exceso de decimales.
        realValue = realValue.replace(',', '.').replace(/^(.*?\.\d{2})(\d*)/, '$1')
        showedValue = !this.state.hasFocus ? formatMoney(realValue) : realValue
      } else if (isCurrency) {
        // Sanitizar: eliminar '.', simbolo de moneda y exceso de decimales.
        realValue = replaceCurrencySYmbol(realValue, currencySymbol[setting.currency])
        showedValue = !this.state.hasFocus ? formatMoney(realValue) : realValue
      } else {
        realValue = this.state.realValue
        showedValue = this.state.showedValue
      }

      this.setState({
        realValue: realValue,
        showedValue: showedValue
      })
    }
  }

  render () {
    const { componentClass = '', disabled, controlLabel = '', colSm = 12, error = '', id, maxLength } = this.props
    return (
      <Col sm={colSm}>
        <FormGroup
          className="forms-with-label"
          validationState={error ? 'error' : null}
        >
          {controlLabel && <ControlLabel>{controlLabel}</ControlLabel>}
          <FormControl
            id={id}
            value={this.state.showedValue}
            className={componentClass + ' text-right'}
            type="text"
            disabled={disabled}
            placeholder={formatMoney('0.00')}
            onChange={this.handleChange.bind(this)}
            onFocus={this.handleFocus.bind(this)}
            onBlur={this.handleBlur.bind(this)}
            maxLength={maxLength}
          />
          {error && <span className='help-block text-center'>{error}</span>}
        </FormGroup>
      </Col>
    )
  }
}

export default SimpleInputMoney
