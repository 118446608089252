import codeCommissionActions from '../../../constants/actions/dossier/common/codeCommisionModal'

export function initialState () {
  return {
    showModal: false,
    organizedUnits: [],
    codeCommissionCombo: [],
    codeCommissionId: null
  }
}

export function openCodeCommissionModalSucces (state, { organizedUnits, codeCommissionCombo, codeCommissionId }) {
  return {
    ...state,
    showModal: true,
    organizedUnits: organizedUnits,
    codeCommissionCombo,
    codeCommissionId
  }
}

export function closeCodeCommissionModal (state) {
  return {
    ...state,
    showModal: false,
    organizedUnits: null,
    codeCommissionCombo: null,
    codeCommissionId: null
  }
}

export function fetchCodeCommissionComboSuccess (state, { codeCommissionCombo }) {
  return {
    ...state,
    codeCommissionCombo
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case codeCommissionActions.OPEN_CODE_COMMISION_MODAL_SUCCESS:
      return openCodeCommissionModalSucces(state, action)
    case codeCommissionActions.CLOSE_CODE_COMMISION_MODAL:
      return closeCodeCommissionModal(state)
    case codeCommissionActions.FETCH_CODE_COMMISION_COMBO_SUCCESS:
      return fetchCodeCommissionComboSuccess(state, action)
    default:
      return state
  }
}
