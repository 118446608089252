
import { call, put, select, takeEvery } from 'redux-saga/effects'
import { getAuth } from '../../selectors/access/auth'
import getPurchaseLogisticState from '../../services/dossierPurchase/get/getPurchaseLogisticState'
import commonActions from '../../actions/common'
import messageModalActions from '../../actions/modals/messageModal'
import { fetchCombosSuccess } from '../../actions/combos/combos'
import combosActionTypes from '../../constants/actions/combos/combos'
import { handleError } from '../errors/errorManager'

export function * fetchPurchaseStateLogisticCombo () {
  try {
    yield put(commonActions.showLoader())
    const auth = yield select(getAuth)
    let combo = yield call(getPurchaseLogisticState, auth.token)
    yield put(fetchCombosSuccess({ purchaseStateLogisticCombo: combo }))
  } catch (error) {
    const sessionHandler = function * () {
    }
    const customHandler = function * () {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.FETCH_COMBO_ERROR'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchfetchPurchaseStateLogisticCombo () {
  yield takeEvery(combosActionTypes.FETCH_PURCHASE_LOGISTIC_STATE_COMBO, fetchPurchaseStateLogisticCombo)
}
