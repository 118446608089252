
import React, { PureComponent } from 'react'
import { Modal, Col, Row, FormGroup, Button } from 'react-bootstrap'
import { reduxForm, Field } from 'redux-form'
import InputText from '../../commons/form/InputText'
import InputTreeSelect from '../../commons/form/InputTreeSelect'

class ProcessingAgencyModal extends PureComponent {
  constructor () {
    super()
    this.state = {
    }
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (prevProps.showModal !== this.props.showModal && this.props.showModal === true) {
      return {
        agency: { ...this.props.agency }
      }
    }
    return null
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (snapshot) {
      this.props.initialize(snapshot.agency)
    }
  }

  submitAgency (values) {
    const body = {
      ...values
    }
    this.props.actions.submitProcessingAgency(body, this.props.agency)
  }

  render () {
    const {
      t, handleSubmit,
      showModal,
      combos: { UOTreeCombo }
    } = this.props
    const tKey = 'MASTERS.PROCESSING_AGENCY_MASTERS.MODAL.'
    return (
      <Modal className="role-modal languages-modal" show={showModal} onHide={this.props.actions.closeEditProcessingAgencyModal} backdrop={'static'}>
        <form autoComplete="off" onSubmit={handleSubmit(this.submitAgency.bind(this))}>
          <Modal.Header closeButton onHide={this.props.actions.closeEditProcessingAgencyModal}>
            <Modal.Title>{t(`${tKey}TITLE`)}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <Row key={1} className="inputs-wrapper flexRow">
                <Col sm={4} className="custom-width-col">
                  <h2>{t(`${tKey}NAME`)}</h2>
                  <Field
                    name="name"
                    placeholder={t(`${tKey}NAME`)}
                    component={InputText}
                  />
                </Col>
                <Col sm={4} className="custom-width-col">
                  <h2>{t(`${tKey}ORGANIZED_UNIT`)}</h2>
                  <Field
                    name="organizedUnitId"
                    placeholder={t(`${tKey}ORGANIZED_UNIT`)}
                    component={InputTreeSelect}
                    multi={false}
                    allowClear={false}
                    valueKey="value"
                    labelKey="label"
                    childrenKey="children"
                    colSm={0}
                    options={UOTreeCombo}
                  />
                </Col>
                <Col sm={4} className="custom-width-col">
                  <h2>{t(`${tKey}COLLEGIATE_MEMBER_NUMBER`)}</h2>
                  <Field
                    name="collegiateMemberNumber"
                    placeholder={t(`${tKey}COLLEGIATE_MEMBER_NUMBER`)}
                    component={InputText}
                  />
                </Col>
                <div className="clearfix" />
                <Col sm={4} className="custom-width-col">
                  <h2>{t(`${tKey}CITY`)}</h2>
                  <Field
                    name="city"
                    placeholder={t(`${tKey}CITY`)}
                    component={InputText}
                  />
                </Col>
                <Col sm={4} className="custom-width-col">
                  <h2>{t(`${tKey}ADDRESS`)}</h2>
                  <Field
                    name="address"
                    placeholder={t(`${tKey}ADDRESS`)}
                    component={InputText}
                  />
                </Col>
                <Col sm={4} className="custom-width-col">
                  <h2>{t(`${tKey}POSTAL_CODE`)}</h2>
                  <Field
                    name="postCode"
                    placeholder={t(`${tKey}POSTAL_CODE`)}
                    component={InputText}
                  />
                </Col>
                <div className="clearfix" />
                <Col sm={4} className="custom-width-col">
                  <h2>{t(`${tKey}REPRESENTATIVE_NAME`)}</h2>
                  <Field
                    name="representativeName"
                    placeholder={t(`${tKey}REPRESENTATIVE_NAME`)}
                    component={InputText}
                  />
                </Col>
                <Col sm={4} className="custom-width-col">
                  <h2>{t(`${tKey}ADDRESS_NUMBER`)}</h2>
                  <Field
                    name="addressNumber"
                    placeholder={t(`${tKey}ADDRESS_NUMBER`)}
                    component={InputText}
                  />
                </Col>
                <Col sm={4} className="custom-width-col">
                  <h2>{t(`${tKey}REPRESENTATIVE_CIF_NIF`)}</h2>
                  <Field
                    name="representativeCifNif"
                    placeholder={t(`${tKey}REPRESENTATIVE_CIF_NIF`)}
                    component={InputText}
                  />
                </Col>
                <div className="clearfix" />
                <Col sm={4} className="custom-width-col">
                  <h2>{t(`${tKey}ASSOCIATION_PROVINCE`)}</h2>
                  <Field
                    name="associationProvince"
                    placeholder={t(`${tKey}ASSOCIATION_PROVINCE`)}
                    component={InputText}
                  />
                </Col>
              </Row>
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <Col sm={12} className="btn-wrapper">
              <Button
                id="role-modal-submit-button"
                className="btn-standard"
                type="submit"
                disabled={this.props.pristine}>
                {t(`${tKey}SUBMIT_BUTTON`)}
              </Button>
            </Col>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default reduxForm({
  form: 'editAgency',
  validate: (values, props) => {
    const error = { agency: {} }
    if (values) {
      const tKey = 'MASTERS.PROCESSING_AGENCY_MASTERS.VALIDATION.'
      if (!values.name) {
        error.name = props.t(`${tKey}NAME`)
      }
      if (!values.organizedUnitId) {
        error.organizedUnitId = props.t(`${tKey}ORGANIZED_UNIT`)
      }
      if (!values.collegiateMemberNumber) {
        error.collegiateMemberNumber = props.t(`${tKey}COLLEGIATE_MEMBER_NUMBER`)
      }
      if (!values.city) {
        error.city = props.t(`${tKey}CITY`)
      }
      if (!values.address) {
        error.address = props.t(`${tKey}ADDRESS`)
      }
      if (!values.postCode) {
        error.postCode = props.t(`${tKey}POSTAL_CODE`)
      }
      if (!values.representativeName) {
        error.representativeName = props.t(`${tKey}REPRESENTATIVE_NAME`)
      }
      if (!values.addressNumber) {
        error.addressNumber = props.t(`${tKey}ADDRESS_NUMBER`)
      }
      if (!values.representativeCifNif) {
        error.representativeCifNif = props.t(`${tKey}REPRESENTATIVE_CIF_NIF`)
      }
      if (!values.associationProvince) {
        error.associationProvince = props.t(`${tKey}ASSOCIATION_PROVINCE`)
      }
    }
    return Object.keys(error).length > 0 ? error : {}
  }
})(ProcessingAgencyModal)
