import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { getPaperworksDetailsFleet, getPaperworksDetailsFleetSuccess, getDossierChildSubtypeDetailCount } from '../../../actions/management/management'
import { openModal } from '../../../actions/common'

import PaperworkFleetListComponent from '../../../components/management/commons/PaperworkFleetListComponent'
import { dossierSubTypeId } from '../../../constants/dossier/common/dossierSubType'

export function mapStateToProps (state) {
  return {
    ...state.management.paperWorkFleetComponent,
    paperWorkCount: state.management.childSubtypeCount[dossierSubTypeId.tramite] || 0,
    dataPaperWorkActions: state.management.operateFleetModal.dataPaperWorkActions,
    operationPaperWorkActions: state.management.operateFleetModal.operationPaperWorkActions
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      openModal,
      getPaperworksDetailsFleet,
      getPaperworksDetailsFleetSuccess,
      getDossierChildSubtypeDetailCount
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(PaperworkFleetListComponent))
