
import React, { PureComponent } from 'react'
import { Modal, Col, Row, FormGroup, Button } from 'react-bootstrap'
import { reduxForm, Field } from 'redux-form'
import InputText from '../../commons/form/InputText'
import InputSelect from '../../commons/form/InputSelect'
import TextInput from '../../commons/TextInput'

class CampaignGroupMasterModal extends PureComponent {
  constructor () {
    super()
    this.state = {}
  }

  submitCampaign (values) {
    let body = []
    Object.keys(values).map(item => {
      if (typeof values[item] === 'object') {
        body.push(values[item])
      }
    })
    body = {
      campaignGroupLanguage: body,
      campaignClassId: values.campaignClassId,
      groupCode: values.groupCode,
      campaignGroupId: this.props.campaignId,
      campaignGroupRoles: values.campaignGroupRoles
    }
    this.props.actions.submitCampaignGroup(body)
  }

  componentDidUpdate (prevProps) {
    if (this.props.showModal !== prevProps.showModal && this.props.showModal === true) {
      this.props.actions.fetchCampaignClass()
    }
  }

  render () {
    const {
      t, handleSubmit, languageList, nonEditableGroup, nonEditable,
      showModal,
      combos: { campaignClass, roleCombo }
    } = this.props
    const tKey = 'MASTERS.CAMPAIGN_GROUP_MASTERS.MODAL.'
    return (
      <Modal className="role-modal languages-modal" show={showModal} onHide={this.props.actions.closeCampaignGroupMasterModal}>
        <form autoComplete="off" onSubmit={handleSubmit(this.submitCampaign.bind(this))}>
          <Modal.Header closeButton onHide={this.props.actions.closeCampaignGroupMasterModal}>
            <Modal.Title>{t(`${tKey}TITLE`)}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="inputs-wrapper-no-border">
              <Col sm={4} className="custom-width-col">
                <h2>{t(`${tKey}CLASS`)}</h2>
                <Field
                  name="campaignClassId"
                  placeholder={t(`${tKey}CLASS`)}
                  valueKey="id"
                  labelKey="value"
                  component={InputSelect}
                  colSm={0}
                  options={campaignClass}
                  disabled={nonEditableGroup}
                />
              </Col>
              <Col sm={6} className="custom-width-col">
                <h2>{t(`${tKey}CODE`)}</h2>
                <Field
                  name="groupCode"
                  placeholder={t(`${tKey}CODE`)}
                  component={InputText}
                  disabled={nonEditable}
                />
              </Col>
              <Col sm={6} className="custom-width-col">
                <h2>{t(`${tKey}ROLE_VERIFICATION`)}</h2>
                <Field
                  name="campaignGroupRoles"
                  id="campaignGroupRoles"
                  placeholder={t(`${tKey}ROLE_VERIFICATION`)}
                  valueKey="id"
                  labelKey="value"
                  component={InputSelect}
                  colSm={12}
                  options={roleCombo}
                  disabled={nonEditableGroup}
                  multi={true}
                />
              </Col>
            </Row>
            <Row className="title-column">
              <Col sm={4}>
                <h2>{t('MASTERS.NAME')}</h2>
              </Col>
              <Col sm={6}>
                <h2>{t('MASTERS.DESCRIPTION')}</h2>
              </Col>
              <Col sm={2}>
                <h2>{t('MASTERS.LANGUAGE')}</h2>
              </Col>
            </Row>
            <FormGroup>
              {languageList && languageList.map((val, idx) => {
                return (
                  <Row key={idx} className="inputs-wrapper">
                    <Col sm={4} className="custom-width-col">
                      <Field
                        name={`lang${val.languageId}.name`}
                        placeholder={t('MASTERS.NAME')}
                        component={TextInput}
                      />
                    </Col>
                    <Col sm={6} className="custom-width-col">
                      <Field
                        name={`lang${val.languageId}.description`}
                        placeholder={t('MASTERS.DESCRIPTION')}
                        component={TextInput}
                      />
                    </Col>
                    <Col sm={2} className="language-column">
                      <p>{`${val.description} (${val.name})`}</p>
                    </Col>
                  </Row>
                )
              })}
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <Col sm={12} className="btn-wrapper">
              <Button
                id="role-modal-submit-button"
                className="btn-standard"
                type="submit"
                disabled={this.props.pristine}>
                {t(`${tKey}SUBMIT_BUTTON`)}
              </Button>
            </Col>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default reduxForm({
  form: 'editCampaignGroup',
  validate: (values, props) => {
    let error = {}
    if (values) {
      const tKey = 'MASTERS.CAMPAIGN_GROUP_MASTERS.VALIDATION.'
      if (!values.campaignClassId) {
        error.campaignClassId = props.t(`${tKey}CLASS`)
      }
      if (!values.campaignGroupRoles || values.campaignGroupRoles.length === 0) {
        error.campaignGroupRoles = props.t(`${tKey}ROLE_VERIFICATION`)
      }
      if (!values.groupCode) {
        error.groupCode = props.t(`${tKey}CODE`)
      }
      if (!values.name) {
        error.name = props.t(`${tKey}NAME`)
      }
      if (!values.description) {
        error.description = props.t(`${tKey}DESCRIPTION`)
      }
      props.languageList && props.languageList.forEach((element) => {
        let valueLangName = values['lang' + element.languageId].name
        let valueLangDesc = values['lang' + element.languageId].description
        let errorLang = {
          name: null,
          description: null
        }
        if (!valueLangName) {
          errorLang.name = props.t(`${tKey}NAME`)
        }
        if (!valueLangDesc) {
          errorLang.description = props.t(`${tKey}DESCRIPTION`)
        }
        error['lang' + element.languageId] = errorLang
      })
    }
    return Object.keys(error).length > 0 ? error : {}
  }
})(CampaignGroupMasterModal)
