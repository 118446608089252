import React, { PureComponent } from 'react'
import { Panel, Col, Table, Row } from 'react-bootstrap'
import { Field } from 'redux-form'
import ChargeRow from './charges/ChargeRow'
import SmallLoaderPage from '../../../containers/commons/SmallLoaderPage'
import { permissions } from '../../../constants/backendIds'
import { formatMoney, compareObjects } from '../../../util/utils'
import InputMoney from '../../commons/form/InputMoney'
import { getFieldsPermisions } from '../../../util/permisionFunctions'
import CustomFields from '../../commons/customFields/CustomFields'

class Charges extends PureComponent {
  constructor () {
    super()
    this.state = {
      open: false,
      fieldsConfiguration: {
        chargebackAmount: null,
        chargeFinancingAmount: null,
        uacTotalAmount: null
      }
    }
  }

  UNSAFE_componentWillMount () {
    this.initializePermisions(this.props)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.open) {
      this.setState({ open: true })
    }

    if (nextProps.open && this.props.open !== nextProps.open) {
      this.getEconomicInfo()
    }

    if (nextProps.dossierId && !compareObjects(this.props.componentConfiguration || {}, nextProps.componentConfiguration, true)) {
      this.initializePermisions(nextProps)
    }
  }

  initializePermisions (props) {
    const fieldKeys = Object.keys(this.state.fieldsConfiguration)
    const newState = getFieldsPermisions(props.componentConfiguration.fieldsConfiguration, fieldKeys, props.componentConfiguration.permission)
    this.setState({ fieldsConfiguration: newState.fieldsConfiguration })
  }

  getEconomicInfo () {
    if (!this.props.isEconomicLoaded) {
      this.props.handleToUpdateComponent(this.props)
      this.props.actions.fetchSalesEconomic(this.props.dossier.dossierId, this.props.historical, this.props.pristine)
    }
  }

  toggleComponent () {
    if (this.state.open) {
      this.props.actions.setActiveSection(false)
    }
    this.setState({ open: !this.state.open })
    this.getEconomicInfo()
  }

  render () {
    const {
      t, dossierType, charges = [], customFields, actions: { openDocumentEditionModal },
      dossier: { dossierId, organizedUnitId, dossierEconomicComponent }, isPreCancelled
    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.CHARGES.'
    const totalCompleted = charges.reduce((prevVal, newVal) => {
      return prevVal + (newVal.completed ? (newVal.amount || 0) : 0)
    }, 0)
    return (
      <div className="charges-panel dossier-panel">
        <div className="header-panel" onClick={ () => this.toggleComponent()}>
          <div className="name-wrapper">
            <i className="ico-collect"/>
            <h4>{t(`${tKey}TITLE_PANEL`)}</h4>
          </div>
          <i className={this.state.open ? 'ico-arrow-black-up' : 'ico-arrow-black-down'}/>
        </div>
        <Panel expanded={this.state.open} onToggle={() => {}}>
          <Panel.Collapse>
            <Row>
              <Col>
                {isPreCancelled && this.state.fieldsConfiguration.chargebackAmount.permission !== permissions.hidden && (
                  <Field
                    id="chargebackAmount"
                    name="salesComponent.chargebackAmount"
                    colSm={2}
                    controlLabel={t(`${tKey}CHARGEBACK_AMOUNT`)}
                    placeholder={t(`${tKey}CHARGEBACK_AMOUNT`)}
                    component={InputMoney}
                    disabled={true}
                  />
                )}
              </Col>
              <Col sm={12} className="col-row">
                <Table className="editable-table" hover responsive>
                  <thead>
                    <tr>
                      <th>{t(`${tKey}RECEIPT_NUMBER`)}</th>
                      <th>{t(`${tKey}DESCRIPTION`)}</th>
                      <th>{t(`${tKey}AMOUNT`)}</th>
                      <th>{t(`${tKey}VALIDATED`)}</th>
                      <th>{t(`${tKey}DOCUMENT`)}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      charges.map((charge, idx) => {
                        return (
                          <ChargeRow
                            t={t}
                            tKey={tKey}
                            charge={charge}
                            key={idx}
                            dossierId={dossierId}
                            organizedUnitId={organizedUnitId}
                            actions={{
                              openDocumentEditionModal// ,
                            // updateDependantsSaleDossierAndHeader
                            }}
                            dossierType={dossierType}
                          />
                        )
                      })
                    }
                  </tbody>
                </Table>
              </Col>
              <Col sm={4}></Col>
              <Col sm={8}>
                <div className="table-totals-charges">
                  <Col sm={6} className="colums-total-charges">
                    <p style={{ borderRight: 'none' }}><b>{t(`${tKey}TOTALS.TOTAL_CHARGED`)}:</b> <span>{formatMoney(totalCompleted)}</span></p>
                    { this.state.fieldsConfiguration.uacTotalAmount.permission !== permissions.hidden && (
                      <p style={{ borderTop: 'none', borderRight: 'none' }}><b>{t(`${tKey}TOTALS.UAC_TOTAL`)}:</b> <span>{formatMoney(dossierEconomicComponent.uacTotalAmount)}</span></p>
                    )}
                  </Col>
                  <Col sm={6} className="colums-total-charges">
                    <p style={{ borderBottom: 'none' }}><b>{t(`${tKey}TOTALS.OPERATION_TOTAL`)}:</b> <span>{formatMoney(dossierEconomicComponent.operationTotalAmount)}</span></p>
                    {this.state.fieldsConfiguration.chargeFinancingAmount.permission !== permissions.hidden && (
                      <p style={{ borderBottom: 'none' }}><b>{t(`${tKey}TOTALS.FINANCING_CHARGE_AMOUNT`)}:</b> <span>{formatMoney(dossierEconomicComponent.financingAmount)}</span></p>
                    )}
                    <p><b>{t(`${tKey}TOTALS.COLLECT_TOTAL`)}:</b> <span>{formatMoney(dossierEconomicComponent.paymentPending)}</span></p>
                  </Col>
                </div>
              </Col>
            </Row>
            <CustomFields customFields={customFields} readOnlyFields={true} />
          </Panel.Collapse>
        </Panel>
        <SmallLoaderPage/>
      </div>
    )
  }
}
export default Charges
