import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import DocumentHistoricalEdition from '../../../components/dossierHistorical/common/documentaryManagement/documentEditionModal/DocumentEditionModal'
import documentHistoricalEditionModal from '../../../actions/dossier/common/documentHistoricalEditionModal'
import { cancelDocument } from '../../../actions/dossier/common/documentManagementModal'
import { showSmallLoader, hideSmallLoader } from '../../../actions/common'
import { setWindowDimensions } from '../../../actions/commons/windowDimensions'
import { translate } from 'react-polyglot'

export function mapStateToProps (state) {
  return {
    ...state.documentHistoricalEditionModal,
    windowWidth: state.windowDimensions.width,
    windowHeight: state.windowDimensions.height,
    token: state.auth.token,
    combos: {
      documentsTypes: state.combos.documentsTypes
    },
    isLoading: state.common.componentLoading
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({ ...documentHistoricalEditionModal, cancelDocument, showSmallLoader, hideSmallLoader, setWindowDimensions }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(DocumentHistoricalEdition))
