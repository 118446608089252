import React, { PureComponent } from 'react'
import SimpleInputText from '../simpleForm/SimpleInputText'

class EditableCeil extends PureComponent {
  render () {
    const { onInputChange, input, maxLength = null, inputValue, stateValue, editing, inputType = 'text', nonEditable = false, formatFunc = null, id, hasError = false, total, discounts, needRef, onlyShow, disabled } = this.props
    return (
      <td className={inputType === 'text' || inputType === 'number' ? 'text-center' : ''}>
        {
          editing && !onlyShow
            ? (
              <SimpleInputText
                id={id}
                colSm={12}
                value={inputValue}
                onInputChange={onInputChange}
                type={inputType}
                error={hasError}
                maxLength={maxLength}
                input={input}
                needRef={needRef || false}
                disabled={nonEditable || disabled}
              />
            )
            : editing && !discounts
              ? (
                onlyShow ? (
                  <span style={{ float: inputType === 'text' ? '' : 'center', fontWeight: total === true ? 'bold' : 'normal' }}>{stateValue}</span>
                )
                  : (
                    <span></span>
                  )
              ) : (
                <span style={{ float: inputType === 'text' ? '' : 'center', fontWeight: total === true ? 'bold' : 'normal' }}>{formatFunc ? formatFunc(stateValue) : stateValue}</span>
              )
        }
      </td>
    )
  }
}
export default EditableCeil
