import apiFetch from '../apiFetch'

export default function (token, dossierSubTypeId, organizedUnitId, includeDeleted = false) {
  const queryParamsArray = [
    `includeDeleted=${includeDeleted}`,
    organizedUnitId ? `organizedUnitId=${organizedUnitId}` : null,
    dossierSubTypeId ? `dossierSubTypeId=${dossierSubTypeId}` : null
  ]
  const queryParams = queryParamsArray.reduce(
    (result, query, idx) => result + (query ? (result ? `&${query}` : `?${query}`) : '')
    , ''
  )

  return apiFetch({ endPoint: 'brand' + queryParams, method: 'GET', body: null, token: token })
}
