import React, { PureComponent } from 'react'
import { reduxForm, Field } from 'redux-form'
import { Row, Col, Table, Button, Tooltip, OverlayTrigger } from 'react-bootstrap'
import IDocCarPagination from '../../commons/iDocCarPagination'
import InputText from '../../commons/form/InputText'
import InputCheckBox from '../../commons/form/InputCheckBox'
import DocumentTypeUseModalPage from '../../../containers/masters/DocumentTypeUse/DocumentTypeUseModalPage'
import InputSelect from '../../commons/form/InputSelect'

class DocumentTypeUseMaster extends PureComponent {
  constructor () {
    super()
    this.state = {
      filter: {
        entityTypeId: null,
        text: null,
        code: null,
        externalCode: null,
        canBePrinted: null
      }
    }
  }

  getSortIcon (column = '') {
    switch (this.props.filter.orderBy) {
      case '+' + column:
        return 'ico-sort-selected-asc'
      case '-' + column:
        return 'ico-sort-selected-desc'
      default:
        return 'ico-sort'
    }
  }

  changeOrder (field) {
    switch (this.props.filter.orderBy) {
      case `+${field}`:
        this.props.actions.fetchDocumentTypeUseList({ ...this.state.filter, ...this.props.filter, orderBy: `-${field}` })
        break
      default:
        this.props.actions.fetchDocumentTypeUseList({ ...this.state.filter, ...this.props.filter, orderBy: `+${field}` })
        break
    }
  }

  handleTextChange (text) {
    if (this.state.filter.text !== text) {
      this.setState({ filter: { ...this.state.filter, text } })
    }
  }

  handleShowOnlyPrintableChange (canBePrinted) {
    canBePrinted = canBePrinted ? true : null
    if (this.state.filter.canBePrinted !== canBePrinted) {
      this.setState({ filter: { ...this.state.filter, canBePrinted } })
    }
  }

  handleExternalCodeChange (externalCode) {
    if (this.state.filter.externalCode !== externalCode) {
      this.setState({ filter: { ...this.state.filter, externalCode } })
    }
  }

  handleCodeChange (code) {
    if (this.state.filter.code !== code) {
      this.setState({ filter: { ...this.state.filter, code } })
    }
  }

  handleEntityChange (entityTypeId) {
    if (this.state.filter.entityTypeId !== entityTypeId) {
      this.setState({ filter: { ...this.state.filter, entityTypeId } })
    }
  }

  search () {
    this.props.actions.fetchDocumentTypeUseList({ ...this.props.filter, ...this.state.filter, page: 1 })
  }

  resetFilters () {
    this.setState({
      filter: {
        entityTypeId: null,
        text: null,
        code: null,
        externalCode: null,
        canBePrinted: null
      }
    }, () => { this.search() })
  }

  render () {
    const {
      t, documentTypeUseList, pagesCount, documentTypeUsesCount, filter,
      combos: { documentType },
      filter: { page },
      actions: { openDocumentTypeUseModal, fetchDocumentTypeUseList, deleteDocumentTypeUse }
    } = this.props
    const tKey = 'MASTERS.DOCUMENT_TYPE_USE_MASTERS.'
    const editTooltip = (<Tooltip id="editTooltip">{t(`${tKey}EDIT`)}</Tooltip>)
    const deleteTooltip = (<Tooltip id="deleteTooltip">{t(`${tKey}DELETE`)}</Tooltip>)
    const hasFilters = (this.state.filter.code || this.state.filter.externalCode || this.state.filter.entityTypeId || this.state.filter.text || this.state.filter.canBePrinted)
    return (
      <div className="admin-wrapper">
        <DocumentTypeUseModalPage/>
        <Row className="section-users-header">
          <Col sm={12}>
            <div className="header-panel">
              <h4>{t(`${tKey}DOCUMENT_USE_TYPE`)}</h4>
            </div>
          </Col>
        </Row>
        <Row>
          <div className="search-form">
            <span className="title-search"><i className="ico-search" /> {t(`${tKey}SEARCH_TITLE`)}</span>
            <Row>
              <Field
                name="filters.entityTypeId"
                colSm={3}
                controlLabel={t(`${tKey}DOCUMENT_TYPE`)}
                placeholder={t(`${tKey}DOCUMENT_TYPE`)}
                component={InputSelect}
                valueKey="id"
                labelKey="value"
                options={documentType}
                onInputChange={(value) => this.handleEntityChange(value)}
              />
              <Field
                name="filters.code"
                colSm={2}
                controlLabel={t(`${tKey}CODE`)}
                placeholder={t(`${tKey}CODE`)}
                valueKey="code"
                labelKey="value"
                component={InputText}
                maxLength={50}
                onInputChange={(value) => this.handleCodeChange(value)}
              />
              <Field
                name="filters.externalCode"
                colSm={2}
                controlLabel={t(`${tKey}EXTERNAL_CODE`)}
                placeholder={t(`${tKey}EXTERNAL_CODE`)}
                valueKey="externalCode"
                labelKey="value"
                component={InputText}
                maxLength={50}
                onInputChange={(value) => this.handleExternalCodeChange(value)}
              />
              <Field
                name="filters.text"
                colSm={2}
                controlLabel={t(`${tKey}TEXT`)}
                placeholder={t(`${tKey}TEXT`)}
                valueKey="text"
                labelKey="value"
                component={InputText}
                maxLength={50}
                onInputChange={(value) => this.handleTextChange(value)}
              />
              <Col sm={3} className="btn-wrapper">
                <p></p>
                <Button
                  className={'btn-white btn-icon btn-input btn-white search-button'}
                  style={{ width: '100%' }}
                  onClick={() => this.search()}>
                  <i className="ico-search" />
                  {t(`${tKey}SEARCH_BTN`)}
                </Button>
                <Button
                  className={'btn-icon btn-input ' + (hasFilters ? 'btn-standard' : 'btn-cancel')}
                  onClick={() => {
                    this.props.initialize({})
                    this.resetFilters()
                  }}
                >
                  <i className="ico-cancel" />
                  {t('MASTERS.RESET_BUTTON')}
                </Button>
              </Col>
            </Row>
            <Row>
              <Col sm={4}>
                <Field
                  name="filters.canBePrinted"
                  placeholder={t(`${tKey}SHOW_ONLY_PRINTABLE`)}
                  component={InputCheckBox}
                  valueKey="code"
                  labelKey="value"
                  onInputChange={(value) => this.handleShowOnlyPrintableChange(value)}
                  colSm={0}
                />
              </Col>
            </Row>
          </div>
        </Row>
        <Col sm={12} className="col-row  section-users-body">
          <Table className="editable-table" hover responsive>
            <thead>
              <tr>
                <th onClick={() => { this.changeOrder('documentTypeUseId') }}>
                  {t(`${tKey}ID`)}
                  <i className={this.getSortIcon('documentTypeUseId')} />
                </th>
                <th onClick={() => { this.changeOrder('documentTypeId') }}>
                  {t(`${tKey}DOCUMENT_TYPE`)}
                  <i className={this.getSortIcon('documentTypeId')} />
                </th>
                <th onClick={() => { this.changeOrder('code') }}>
                  {t(`${tKey}CODE`)}
                  <i className={this.getSortIcon('code')} />
                </th>
                <th onClick={() => { this.changeOrder('externalCode') }}>
                  {t(`${tKey}EXTERNAL_CODE`)}
                  <i className={this.getSortIcon('externalCode')} />
                </th>
                <th onClick={() => { this.changeOrder('localeName') }}>
                  {t(`${tKey}NAME`)}
                  <i className={this.getSortIcon('localeName')} />
                </th>
                <th onClick={() => { this.changeOrder('localeDescription') }}>
                  {t(`${tKey}DESCRIPTION`)}
                  <i className={this.getSortIcon('localeDescription')} />
                </th>
                <th onClick={() => { this.changeOrder('canBePrinted') }}>
                  {t(`${tKey}PRINTABLE`)}
                  <i className={this.getSortIcon('canBePrinted')} />
                </th>
                <th style={{ width: '77px' }}>{t('DOSSIER_COMPONENTS.ACCESSORIES.ACTIONS')}</th>
              </tr>
            </thead>
            <tbody>
              {documentTypeUseList && documentTypeUseList.map((documentTypeUse, idx) => {
                return (
                  <tr key={idx} className={documentTypeUse.isDeleted ? 'is-deleted' : ''}>
                    <td>{documentTypeUse.documentTypeUseId}</td>
                    <td>{documentType && documentType.find(x => x.id === documentTypeUse.documentTypeId) && documentType.find(x => x.id === documentTypeUse.documentTypeId).value}</td>
                    <td>{documentTypeUse.code}</td>
                    <td>{documentTypeUse.externalCode}</td>
                    <td>{documentTypeUse.documentTypeUseLocales && documentTypeUse.documentTypeUseLocales.length > 0 ? documentTypeUse.documentTypeUseLocales.find(lang => (lang.languageId ? lang.languageId : '') === this.props.languageSelected).name : ''}</td>
                    <td>{documentTypeUse.documentTypeUseLocales && documentTypeUse.documentTypeUseLocales.length > 0 ? documentTypeUse.documentTypeUseLocales.find(lang => (lang.languageId ? lang.languageId : '') === this.props.languageSelected).description : ''}</td>
                    <td>{documentTypeUse.canBePrinted && (<i className='ico-check'></i>) }</td>
                    <td>
                      {!documentTypeUse.isDeleted && (
                        <div>
                          <a onClick={() => openDocumentTypeUseModal(documentTypeUse)}>
                            <OverlayTrigger placement="left" overlay={editTooltip}>
                              <i className="ico-edit-white" />
                            </OverlayTrigger>
                          </a>
                          <a onClick={() => deleteDocumentTypeUse(documentTypeUse.documentTypeUseId)}>
                            <OverlayTrigger placement="left" overlay={deleteTooltip}>
                              <i className="ico-trash" />
                            </OverlayTrigger>
                          </a>
                        </div>
                      )}
                    </td>
                  </tr>)
              })
              }
              {(documentTypeUseList === null || documentTypeUseList === undefined || documentTypeUseList.length === 0) &&
            <tr>
              <td colSpan={10}><h2>{t(`${tKey}RESULTS_NOT_FOUND`)}</h2></td>
            </tr>}
              <tr className="button-table-wrapper">
                <td>
                  <Button
                    className="btn-editable-table btn-standard"
                    onClick={() => openDocumentTypeUseModal()}>
                  + {t(`${tKey}ADD`)}
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>
          <div className="section-users-footer">
            <nav key={1} className="text-center">
              <IDocCarPagination
                id="btn_pag_sales"
                page={page}
                pagesCount={pagesCount}
                totalRows={documentTypeUsesCount}
                onSelectAction={(value) => fetchDocumentTypeUseList({ ...filter, ...this.state.filter, page: value })}
              />
            </nav>
          </div>
        </Col>
      </div>
    )
  }
}

export default reduxForm({
  form: 'listDocumentType'
})(DocumentTypeUseMaster)
