import apiFetch from '../apiFetch'

export default function (token, { page, pageSize, orderBy, name, surname1, surname2, clientNumber, salesman, amount, bank, chargeDateFrom, chargeDateTo, documentStatus }) {
  const queryParamsArray = [
    page ? `page=${page}` : null,
    pageSize ? `pageSize=${pageSize}` : null,
    orderBy ? `orderBy=${orderBy}` : null,
    name ? `entityName=${name}` : null,
    surname1 ? `entitySurname1=${surname1}` : null,
    surname2 ? `entitySurname2=${surname2}` : null,
    clientNumber ? `clientNumber=${clientNumber}` : null,
    salesman ? `salesmanId=${salesman}` : null,
    amount ? `amount=${amount}` : null,
    bank ? `BankId=${bank}` : null,
    chargeDateFrom ? `chargeDateFrom=${chargeDateFrom}` : null,
    chargeDateTo ? `chargeDateTo=${chargeDateTo}` : null,
    documentStatus ? `documentStatusId=${documentStatus}` : null
  ]
  const queryParams = queryParamsArray.reduce(
    (result, query, idx) => result + (query ? (result ? `&${query}` : `?${query}`) : '')
    , ''
  )
  return apiFetch({
    endPoint: `Charge/FindByFilter${queryParams}`,
    method: 'GET',
    body: null,
    token: token
  })
}
