import React, { PureComponent } from 'react'
import { Col, FormControl, FormGroup, ControlLabel } from 'react-bootstrap'

class InputText extends PureComponent {
  handleChange (e) {
    this.props.input.onChange(e)
    if (this.props.onInputChange) {
      this.props.onInputChange(e.target.value)
    }
  }

  handleBlur (e) {
    this.props.input.onBlur(e)
    if (this.props.onInputBlur) {
      this.props.onInputBlur(e.target.value)
    }
  }

  render () {
    const {
      id,
      colSm = 3,
      colSmOffset = 0,
      meta,
      showError = false,
      input,
      type = 'text',
      placeholder,
      rightIcon = '',
      leftIcon = '',
      controlLabel,
      componentClass,
      customClass = '',
      forceControlLabelInOneLine = false,
      disabled = false,
      inline = false,
      textAlign,
      minLength,
      maxLength,
      paddingLeft,
      resize,
      autoComplete = 'off'
    } = this.props
    const errorMessage = ((showError || meta.touched) && meta.error && typeof meta.error !== 'boolean')
      ? meta.error
      : null
    return (
      <Col sm={colSm} smOffset={colSmOffset} className={customClass} style={paddingLeft}>
        <FormGroup
          className={(rightIcon ? 'input-with-icon' : '') + (inline ? ' row' : '')}
          validationState={(showError || meta.touched) && meta.error ? 'error' : null}>
          {controlLabel && <ControlLabel className={(forceControlLabelInOneLine ? 'one-line ellipsis' : '') + (inline ? ' col-sm-3' : '')}>{controlLabel}</ControlLabel>}
          <Col sm={inline ? 9 : 0}>
            <FormControl
              style={{ textAlign: textAlign || 'left', resize: resize || 'auto' }}
              value={input.value}
              id={id}
              disabled={disabled}
              type={type}
              placeholder={placeholder}
              onChange={this.handleChange.bind(this)}
              componentClass={componentClass}
              onBlur={this.handleBlur.bind(this)}
              minLength={minLength}
              maxLength={maxLength}
              autoComplete={autoComplete}
            />
            <i className={rightIcon || leftIcon} />
            {errorMessage && <span className='help-block text-center'>{errorMessage}</span>}
          </Col>
        </FormGroup>
      </Col>
    )
  }
}

export default InputText
