import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { deleteDocumentVisualization, openDocumentVisualizationModal, fetchDocumentVisualizations } from '../../../actions/masters/documentVisualization/documentVisualization'
import DocumentVisualizationMaster from '../../../components/masters/documentVisualization/DocumentVisualizationMaster'

export function mapStateToProps (state) {
  return {
    ...state.documentVisualization,
    combos: {
      dossierSubtypesCombo: state.combos.searchDossierSubtypesCombo,
      documentTypeUseCombo: state.combos.documentTypeUseCombo
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      deleteDocumentVisualization,
      openDocumentVisualizationModal,
      fetchDocumentVisualizations
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(DocumentVisualizationMaster))
