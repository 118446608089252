import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Masters from '../../components/masters/Masters'
import { translate } from 'react-polyglot'
import { fetchFaqList, openFaqMaster, fecthFaqSectionCombo } from '../../actions/masters/faq/faqMaster'
import { fetchFaqSectionTable } from '../../actions/masters/faqSection/faqSection'

import {
  fetchCampaignTypeList, fetchCampaignGroupList, fetchEntityMasters, fetchProcessingAgencyList, fetchFinancingTypeList,
  fetchPurchasePresentTypeList, fetchPaymentMethodList, fetchFinancingList, fetchFuelList, fetchProcessingAgencyQuotaList,
  fetchCampaignList, fetchStyleTaskList, fetchFinancingTypeProductList, fetchFinancingTypeProductFields, getAllFinancingTypeProduct,
  fetchLegalCompanyList, fetchDirectionUOMaster, fetchOrganizedUnitList, setChangeTabFromConfMaster, fetchSaleTypeList,
  fetchPaperwokTypesMaster, fetchDossierStageList, initializeFilterState,
  fetchDestinationCraneMaster, fetchcodeCommissionMasters
} from '../../actions/masters/masters'
import { fetchOrganizedUnitConfigurationList } from '../../actions/masters/organizedUnitConfiguration/organizedUnitConfiguration'
import { fetchRatingList } from '../../actions/masters/rating/rating'
import { fetchRepairshopList } from '../../actions/masters/repairshop/repairshop'
import { fetchMaritalStatusList } from '../../actions/masters/maritalStatus/maritalStatus'
import { fetchDocumentTypeList } from '../../actions/masters/documentType/documentType'
import { fetchDocumentTypeUseList } from '../../actions/masters/documentTypeUse/documentTypeUse'
import { fetchDocumentVisualizations } from '../../actions/masters/documentVisualization/documentVisualization'
import { fetchOrganizedUnitSaleOperationList } from '../../actions/masters/organizedUnitSaleOperation/organizedUnitSaleOperation'
import {
  fetchCampaignGroup, fetchRoleCombo, fetchEntityTypes, fetchDocumentTypeEntities, fetchSearchDossierSubType,
  fetchDocumentTypeUseCombo, fetchPermissionCombo, fetchOrganizedUnitsAll, fetchSaleTypeAll, fetchOperationTypeAll,
  fetchDocumentTypeCombo, fetchConfigParameterAll, fetchSignTypeCombo, fetchSignEntityTypeCombo,
  fetchLegalCompanyCombo, fetchDirectionOUCombo, fetchProcessingAgencyCombo, fetchCountries, fetchBrands
  , fetchDossierSubTypes, fetchCustomFieldTypesCombo
} from '../../actions/combos/combos'
import { fetchDocumentTypeMetadataList } from '../../actions/masters/metadata/metadata'
import TrackingContainer from '../../containers/tracking/trackingContainer'
import { fetchOperationTypesTable } from '../../actions/masters/operationType/operationType'
import { fetchSignTypeList } from '../../actions/masters/signType/signType'
import { fetchSignatureConfigList } from '../../actions/masters/signatureConfiguration/signatureConfiguration'
import { fetchProcessingAgencyCommunicationServices } from '../../actions/masters/processingAgencyCommunicationServices/processingAgencyCommunicationServices'
import { fetchCustomFieldList } from '../../actions/masters/customField/customField'
import { fetchProcessingManagersByNameOrUo, setProcessingAgencyManagerFilter } from '../../actions/masters/processingManager/processingManager'

export function mapStateToProps (state) {
  return {
    ...state.auth,
    ...state.organizedUnitConfiguration
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchEntityMasters,
      fetchProcessingAgencyList,
      fetchProcessingAgencyQuotaList,
      fetchFuelList,
      fetchPurchasePresentTypeList,
      fetchFinancingTypeList,
      fetchPaymentMethodList,
      fetchFaqList,
      fetchFaqSectionTable,
      openFaqMaster,
      fetchFinancingList,
      fetchCampaignList,
      fetchCampaignGroupList,
      fetchCampaignTypeList,
      fetchCampaignGroup,
      fetchDocumentTypeList,
      fetchDocumentTypeUseList,
      fetchOrganizedUnitSaleOperationList,
      fetchEntityTypes,
      fetchStyleTaskList,
      fetchFinancingTypeProductList,
      fetchFinancingTypeProductFields,
      getAllFinancingTypeProduct,
      fetchDirectionUOMaster,
      fetchLegalCompanyList,
      fetchRoleCombo,
      fetchPaperwokTypesMaster,
      fetchDocumentTypeEntities,
      fetchDocumentTypeUseCombo,
      fetchSignTypeCombo,
      fetchSignEntityTypeCombo,
      fetchSearchDossierSubType,
      fetchDocumentVisualizations,
      fetchOrganizedUnitsAll,
      fetchSaleTypeAll,
      fetchOperationTypeAll,
      fetchPermissionCombo,
      fetchDocumentTypeMetadataList,
      fetchDocumentTypeCombo,
      fetchOperationTypesTable,
      fetchConfigParameterAll,
      fetchOrganizedUnitConfigurationList,
      fecthFaqSectionCombo,
      fetchOrganizedUnitList,
      fetchLegalCompanyCombo,
      fetchDirectionOUCombo,
      fetchCountries,
      fetchProcessingAgencyCombo,
      setChangeTabFromConfMaster,
      fetchSaleTypeList,
      fetchSignatureConfigList,
      fetchSignTypeList,
      fetchProcessingAgencyCommunicationServices,
      fetchBrands,
      fetchDossierStageList,
      fetchRatingList,
      initializeFilterState,
      fetchRepairshopList,
      fetchMaritalStatusList,
      fetchCustomFieldList,
      fetchDossierSubTypes,
      fetchCustomFieldTypesCombo,
      fetchDestinationCraneMaster,
      fetchProcessingManagersByNameOrUo,
      setProcessingAgencyManagerFilter,
      fetchcodeCommissionMasters
    }, dispatch)
  }
}

var decoratedComponent = TrackingContainer(Masters, true)
export default connect(mapStateToProps, mapDispatchToProps)(translate()(decoratedComponent))
