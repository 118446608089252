import React, { PureComponent } from 'react'
import { reduxForm } from 'redux-form'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Element } from 'react-scroll'
import { dossierTypes as dossierTypesConstant } from '../../../../constants/dossier/common/dossierTypes'
import { compareObjects } from '../../../../util/utils'
import { getComponentsPermisions } from '../../../../util/permisionFunctions'
import ButtonsHeader from '../../../../_v2/components/dossiers/common/ButtonsHeader'
import ButtonsSidebar from '../../dossiers/common/ButtonsSidebar'
import RejectCommentModal from '../../../../components/dossiers/common/RejectCommentModal'
import { buttonsHeader } from '../../../../constants/dossier/common/buttonsHeader'
import { permissions } from '../../../../constants/backendIds'
import DocumentaryManagementPage from '../../../../_v2/containers/dossiers/commons/DocumentaryManagementPage'
import AuditInfo from '../../dossiers/common/audit/AuditInfo'
import TrackingContainer from '../../../../containers/tracking/trackingContainer'
import { TrackedPages } from '../../../../constants/tracking/TrackedPages'
import Vehicle from '../../dossiers/common/Vehicle'
import { buttonsSidebar } from '../../../../constants/dossier/common/buttonsSidebar'
import ServiceComponent from '../../../../_v2/components/dossiers/service/ServiceComponent'
import DossierSummary from '../../dossiers/common/DossierSummary'
import DossierHeaderPage from '../../../containers/dossiers/commons/dossierHeaderPage'
import RelatedDossiers from '../../dossiers/common/RelatedDossiers'
import CommentComponent from '../../dossiers/common/comment/CommentComponent'

class ServiceHistorical extends PureComponent {
  constructor () {
    super()
    this.state = {
      modalOpen: true,
      summaryOpen: true,
      showRejectCommentModal: false,
      activeTabConfiguration: null,
      headerTabConfiguration: null,
      loaded: false,
      componentsConfiguration: {
        downloadDocumentsButton: null,
        vehicle: null,
        bservice: null
      },
      isVehicleLoaded: false
    }
    this.goBack = this.goBack.bind(this)
  }

  goBack () {
    this.props.actions.setActiveTab(buttonsHeader.datos_expediente)
    this.props.history.goBack()
  }

  toogleHidedossierBodyLeft () {
    this.setState({ dossierBodyLeftClose: !this.state.dossierBodyLeftClose })
  }

  toggleRejectCommentModal (showRejectCommentModal) {
    this.setState({ showRejectCommentModal })
  }

  onClickRelatedDossierColumn (columnCode) {
    let columnCodeOrder = '+' + columnCode
    if (columnCodeOrder === this.props.relatedDossiersOrderBy) {
      columnCodeOrder = '-' + columnCode
    }
    this.props.actions.fetchFilteredRelatedDossier(this.props.dossier.dossierId, columnCodeOrder, true)
  }

  getRelatedDossierColumnIcon (columnCode) {
    if ('+' + columnCode === this.props.relatedDossiersOrderBy) {
      return 'ico-sort-selected-asc'
    } else if ('-' + columnCode === this.props.relatedDossiersOrderBy) {
      return 'ico-sort-selected-desc'
    } else {
      return 'ico-sort'
    }
  }

  fetchServiceDossierPromise (dossierId) {
    return new Promise((resolve, reject) => {
      return this.props.actions.fetchServiceDossier(dossierId, resolve, reject, true)
    })
  }

  initializePermisions (props) {
    const componentKeys = Object.keys(this.state.componentsConfiguration)
    const newState = getComponentsPermisions(props.dossier.viewConfiguration.tabsConfiguration, props.activeTab, componentKeys)
    this.setState({ ...this.state, ...newState })
  }

  componentDidMount () {
    this.props.actions.restartServiceDossierState()
    this.fetchServiceDossierPromise(this.props.match.params.dossierId).then(() => {
      const form = { ...this.props.dossier }
      this.props.initialize(form)
      this.initializePermisions(this.props)
      this.props.trackVisitPage({
        documentTitle: TrackedPages.SERVICE_HISTORICAL,
        organizedUnitId: this.props.dossier.organizedUnitId,
        href: window.location.href
      })
      this.props.actions.fetchSameLevelOusCombo(this.props.dossier.organizedUnitId, null)
      this.setState({ loaded: true })
      this.props.actions.fetchCustomFields(this.props.dossier.dossierSubTypeId)
      this.props.actions.registerLastAccess(this.props.match.params.dossierId, true)
    })
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (this.props.dossier && this.props.dossier.viewConfiguration && this.props.dossier.viewConfiguration.tabsConfiguration && !compareObjects(prevProps.dossier.viewConfiguration.tabsConfiguration || {}, this.props.dossier.viewConfiguration.tabsConfiguration, true)) {
      this.initializePermisions(this.props)
    }
    if (this.props.dossier.dossierId && prevProps.activeTab !== this.props.activeTab) {
      this.initializePermisions(this.props)
    }
    return this.props
  }

  componentDidUpdate (prevProps) {
    if ((this.props.showManagementModal && !prevProps.showManagementModal) || (this.props.match.params.dossierId !== prevProps.match.params.dossierId)) {
      if (this.props.match.params.dossierId !== prevProps.match.params.dossierId) {
        this.props.actions.restartServiceDossierState()
        this.props.actions.setScrollTo(buttonsSidebar.bservice)
        this.props.actions.setActiveSection(buttonsSidebar.bservice)
        this.props.actions.setActiveTab(buttonsHeader.datos_expediente)
        this.props.actions.closeAddOrEditDocumentModal()
      }

      if (this.props.match.params.dossierId) {
        this.fetchServiceDossierPromise(this.props.match.params.dossierId).then(() => {
          const form = { ...this.props.dossier }
          this.props.initialize(form)
          this.initializePermisions(this.props)
          this.setState({ loaded: true })
        })
      }
    }

    if (this.props.dossier && this.props.dossier.dossierId && this.props.statusHeaderHeight && this.props.activeSection !== prevProps.activeSection) {
      switch (this.props.activeSection) {
        case buttonsSidebar.bservice:
          this.setState({ summaryOpen: true })
          break
      }
      this.props.actions.setScrollTo(this.props.activeSection)
      this.props.actions.setActiveSection(false)
    }

    const firstCustomFieldsLoad = prevProps.dossier.customFieldsByComponent?.length < this.props.dossier.customFieldsByComponent?.length
    if (firstCustomFieldsLoad) {
      let customFields = {}
      if (this.props.dossier.customFieldsValues) {
        this.props.dossier.customFieldsValues.map(x => {
          customFields[x.key] = x.value
        })
      }
      const form = {
        ...this.props.dossier,
        customFields: customFields
      }
      this.props.initialize(form)
    }
  }

  componentWillUnmount () {
    this.props.actions.setActiveSection(buttonsSidebar.bservice)
    this.props.actions.setScrollTo(null)
    this.props.actions.resetButtonsHeader()
    this.props.actions.closeAddOrEditDocumentModal()
    this.props.actions.restartServiceDossierState()
  }

  handleToUpdateComponent (component) {
    if (component.isVehicleLoaded === false || buttonsSidebar.vehicle === component) this.setState({ isVehicleLoaded: true })
    this.props.actions.setScrollTo(component)
  }

  togglePanel () {
    if (this.state.summaryOpen) {
      this.props.actions.setActiveSection(false)
    }
    this.setState({ summaryOpen: !this.state.summaryOpen })
  }

  render () {
    const {
      t, dossier, pristine, activeTab, buttonsHeaderHeight, statusHeaderHeight, windowWidth, documentExport, vehicleProps, combos, activeSection, change,
      dossier: {
        dossierId, header, checkListHeader, comments, viewConfiguration, dossierRelatedDossiers, auditInfo, dossierSubTypeId, dossierParentId,
        vehicleComponent: { vehicleId },
        viewConfiguration: { buttonsPermission = [] }
      },
      actions: {
        openDocumentModal, setActiveTab, setButtonsHeaderHeight, resetButtonsHeader, setActiveSection, setScrollerOptions,
        refreshAuditInfo, saveSelectedDocuments, fetchServiceDossierVehicleHistorical, fetchComboOrigin, fetchComboDestination, fecthFamily,
        setEntitySubTypeId, fetchAuditDetails, fetchAuditDetailsModal, fetchDocumentsGroupedAuditDetails, fetchDocumentsGroupedAuditDetailsModal,
        fetchEntitySubTypeCombo, fetchEntityFilterSubTypeCombo, fetchFilteredRelatedDossier, updateComment, createComment, deleteComment, markCommentAsRead
      }
    } = this.props

    if (!dossierId) return null
    const tKey = 'DOSSIER_COMPONENTS.'
    const panelsPaddingTop = statusHeaderHeight + buttonsHeaderHeight - 82
    const saveServiceDossierPermission = buttonsPermission.includes('service_dossier_save')
    return (
      <div className="dossierGrid">
        <div className='modalsContainer'>
          <RejectCommentModal
            t={t}
            showModal={this.state.showRejectCommentModal}
            actions={{
              closeModal: () => this.toggleRejectCommentModal(false)
            }}
          />
        </div>

        <div class="dossierGridPart-header">
          <DossierHeaderPage
            headerData={header}
            stages={checkListHeader}
            dossierType={dossierTypesConstant.service}
            headerTabConfiguration={this.state.headerTabConfiguration}
            goBack={this.goBack}
            dossierId={dossier.dossierId}
            isHistorical={true}
            entityComponent = {dossier.entityComponent}
            vehicleComponent = {dossier.vehicleComponent}
          />
        </div>
        <div class="dossierGridPart-body">

          <div className={'dossierBody-left ' + (this.state.dossierBodyLeftClose ? 'close' : '') }>

            <DossierSummary
              t = {t}
              dossier={dossier}
              header={header}
              dossierType={dossierTypesConstant.service}
              dossierSubType={dossierSubTypeId}
              brandCombo = {combos.brandCombo}
              entityTypeCombo = {combos.entityTypeCombo}
              dossierRelatedDossiers = {dossierRelatedDossiers}
            >
            </DossierSummary>
          </div>
          <div className='dossierBody-center'>
            <OverlayTrigger placement="right" overlay={<Tooltip id="editProductTooltip">{`${t((this.state.dossierBodyLeftClose ? 'GLOBAL.TABLES.SHOW_INNER_MENU' : 'GLOBAL.TABLES.HIDE_INNER_MENU'))}`}</Tooltip>}>
              <a onClick={() => this.toogleHidedossierBodyLeft()} className={'closeDossierInfo ' + (this.state.dossierBodyLeftClose ? '' : 'closeInnerMenu')}><i className={this.state.dossierBodyLeftClose ? 'iDocIcon-Open-inner-menu' : 'iDocIcon-Close-inner-menu'}></i></a>
            </OverlayTrigger>
          </div>

          <div className='dossierBody-right'>

            <div class="dossierGridPart-sectionsMenu">
              <ButtonsHeader
                t={t}
                pristine={pristine}
                actions={{
                  openDocumentModal: () => openDocumentModal(dossierId, dossierTypesConstant.service, pristine, 1, true),
                  setActiveTab,
                  setButtonsHeaderHeight,
                  resetButtonsHeader,
                  setScrollerOptions
                }}
                viewConfiguration={viewConfiguration}
                dossierId={this.props.match.params.dossierId}
                dossierType={dossierTypesConstant.service}
                activeTab={activeTab}
                marginTop={statusHeaderHeight}
                windowWidth={windowWidth}
              />
            </div>
            <div class="dossierGridPart-ComponentSection">
              <form autoComplete="off">
                {
                  activeTab === buttonsHeader.datos_expediente && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden && (
                    <div className="_nd dossierGridPart-ComponentSection-Inner" >
                      <div className="ComponentSection-InnerMennu">
                        <ButtonsSidebar
                          t={t}
                          dossierId={dossierId}
                          historical={true}
                          handleToUpdateComponent={this.handleToUpdateComponent.bind(this)}
                          pristine={pristine}
                          actions={{ setActiveSection, fetchServiceDossierVehicleHistorical }}
                          dossierType={dossierTypesConstant.service}
                          marginTop={statusHeaderHeight}
                          dossierSubTypes={dossierSubTypeId}
                          buttonsHeaderHeight={buttonsHeaderHeight}
                          tabConfiguration={this.state.activeTabConfiguration}
                          isVehicleLoaded={this.state.isVehicleLoaded}
                        />
                      </div>
                      <div className="ComponentSection-InnerData" id="dossierData" >

                        {this.state.componentsConfiguration.bservice && this.state.componentsConfiguration.bservice.permission > permissions.hidden && (
                          <Element name={buttonsSidebar.bservice}>
                            <ServiceComponent
                              dossier={dossier}
                              t={t}
                              summaryOpen={this.state.summaryOpen}
                              actions= {{
                                setActiveTab,
                                togglePanel: () => this.togglePanel(),
                                setEntitySubTypeId,
                                fetchEntitySubTypeCombo,
                                fetchEntityFilterSubTypeCombo,
                                change,
                                setActiveSection
                              }}
                              combos={combos}
                              dossierType={dossierTypesConstant.service}
                              componentConfiguration={this.state.componentsConfiguration.bservice}
                              readOnlyFields={true}
                              customFields={this.props.dossier.customFieldsByComponent.find(x => x.componentCode === buttonsSidebar.bservice)?.customFields}
                            />
                          </Element>
                        )}
                        {this.state.componentsConfiguration.vehicle && this.state.componentsConfiguration.vehicle.permission > permissions.hidden && (
                          <Element name={buttonsSidebar.vehicle}>
                            <Vehicle
                              parentDossierType={{
                                isPaperwork: false,
                                isSales: false,
                                isPurchase: false,
                                isRegistrationDelivery: false,
                                isService: true

                              }}
                              actions={{
                                fetchComboOrigin,
                                fetchComboDestination,
                                fetchServiceDossierVehicleHistorical,
                                fecthFamily,
                                setActiveSection
                              }}
                              t={t}
                              dossierId={dossierId}
                              dossierType={dossierTypesConstant.service}
                              handleToUpdateComponent={this.handleToUpdateComponent.bind(this)}
                              isVehicleLoaded={this.state.isVehicleLoaded}
                              searchedVehicleProps={vehicleProps}
                              dossierSubType={dossierSubTypeId}
                              dossierParentId={dossierParentId}
                              dossierWithVehicle={!!vehicleId}
                              combos={combos}
                              readOnlyFields={!saveServiceDossierPermission || !!dossierParentId}
                              open={activeSection === buttonsSidebar.vehicle}
                              componentConfiguration={this.state.componentsConfiguration.vehicle}
                              organizedUnitId={dossier.organizedUnitId}
                              customFields={this.props.dossier.customFieldsByComponent.find(x => x.componentCode === buttonsSidebar.vehicle)?.customFields}
                              isHistorical={true}
                            />
                          </Element>
                        )}

                      </div>
                    </div>
                  )}
                {activeTab === buttonsHeader.gestor_documental && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden && (
                  <div className="dossierGridPart-ComponentSection-Inner sales-panel gestorDocumental">
                    <div className="ComponentSection-InnerData">
                      <DocumentaryManagementPage
                        dossier={dossier}
                        dossierType={dossierTypesConstant.service}
                        saveSelectedDocuments={saveSelectedDocuments}
                        documentExport={documentExport}
                        combos={this.props.combos}
                        isHistorical={true}
                        tabConfiguration={this.state.activeTabConfiguration}
                        downloadButtonPermission={this.state.activeTabConfiguration.componentsConfiguration.downloadDocumentsButton}
                      />
                    </div>
                  </div>
                )}
                {activeTab === buttonsHeader.expedientes_relacionados && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden && (
                  <div className="dossierGridPart-ComponentSection-Inner related-dossiers-table">
                    <div className="ComponentSection-InnerData">
                      <RelatedDossiers
                        t={t}
                        relatedDossiersOrderBy = {this.props.relatedDossiersOrderBy}
                        panelsPaddingTop = {panelsPaddingTop}
                        history = {this.props.history}
                        dossierRelatedDossiers = {dossierRelatedDossiers}
                        dossierId= {dossierId}
                        isHistorical= {true}
                        actions={{ fetchFilteredRelatedDossier }}
                      />
                    </div>
                  </div>
                )}
                {
                  activeTab === buttonsHeader.actividad_expediente && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden && (
                    <div className="dossierGridPart-ComponentSection-Inner auditInfo">
                      <div sm={12} className="ComponentSection-InnerData">
                        <AuditInfo
                          auditInfo={auditInfo}
                          dossierType={dossierTypesConstant.service}
                          actions={{ refreshAuditInfo, fetchAuditDetails, fetchAuditDetailsModal, fetchDocumentsGroupedAuditDetails, fetchDocumentsGroupedAuditDetailsModal }}
                          dossier={dossier}
                          historical={true}
                          t={t}
                        />
                      </div>
                    </div>
                  )
                }
                {
                  activeTab === buttonsHeader.observaciones && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden && (
                    <div className="dossierGridPart-ComponentSection-Inner commentComponent">
                      <div className="ComponentSection-InnerData">
                        <CommentComponent
                          comments={comments}
                          actions={{ updateComment, createComment, deleteComment, markCommentAsRead }}
                          readOnlyFields={!saveServiceDossierPermission}
                          dossierType="serviceDossier"
                          t={t}
                          tKey={tKey}
                          combos={{ allReasonCombo: this.props.combos.allReasonCombo }}
                          openComponent={activeTab === buttonsHeader.observaciones}
                          dossierId={this.props.dossier.dossierId}
                          historical={true}
                        />

                      </div>
                    </div>
                  )
                }
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

var decoratedComponent = TrackingContainer(ServiceHistorical, false)

export default reduxForm({
  form: 'service_dossier'
})(decoratedComponent)
