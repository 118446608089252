import apiFetch from '../apiFetch'

export default function (token, file, dossiers, documentTypeUseId, organizedUnitId, validate) {
  const queryParamsArray = [
    documentTypeUseId ? `documentTypeUseId=${documentTypeUseId}` : null,
    organizedUnitId ? `organizedUnitId=${organizedUnitId}` : null,
    validate ? `validate=${validate}` : false,
    dossiers ? `dossiersVinChassis=${dossiers}` : false
  ]

  const formData = new FormData()
  formData.append(file.name, file)

  const queryParams = queryParamsArray.reduce(
    (result, query, idx) => result + (query ? (result ? `&${query}` : `?${query}`) : '')
    , ''
  )
  return apiFetch({ endPoint: `UploadDifferentDocumentFleet${queryParams}`, method: 'POST', body: formData, token: token, fileBody: true })
}
