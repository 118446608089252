import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import authActions from '../../actions/auth'
import { getFormValues, getFormSyncErrors } from 'redux-form'
import { translate } from 'react-polyglot'
import ResetPassword from '../../components/access/ResetPassword'
import TrackingContainer from '../../containers/tracking/trackingContainer'

export function mapStateToProps (state) {
  return {
    ...state.auth,
    formValues: getFormValues('resetPassword')(state),
    formErrors: getFormSyncErrors('resetPassword')(state)
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators(authActions, dispatch)
  }
}

var decoratedComponent = TrackingContainer(ResetPassword, true)
export default connect(mapStateToProps, mapDispatchToProps)(translate()(decoratedComponent))
