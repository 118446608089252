import { put, call, select, takeEvery } from 'redux-saga/effects'
import { hideLoader, openModal, showLoader } from '../../../actions/common'
import { getAuth } from '../../../selectors/access/auth'
import mastersActionTypes from '../../../constants/actions/masters/masters'
import { handleError } from '../../errors/errorManager'
import { getOperationTypeFilter } from '../../../selectors/masters/operationType'
import postOperationTypeTable from '../../../services/operationType/postOperationTypeTable'
import {
  closeOperationTypeModal
  , openOperationTypeModalSuccess
  , fetchOperationTypesTableSuccess
} from '../../../actions/masters/operationType/operationType'
import putOperationType from '../../../services/operationType/putOperationType'
import postOperationType from '../../../services/operationType/postOperationType'
import getOperationType from '../../../services/operationType/getOperationType'
import putDeleteOperationType from '../../../services/operationType/deleteOperationType'
import putRecoverOperationType from '../../../services/operationType/putRecoverOperationType'
import getLanguageList from '../../../services/language/getLanguageList'
import messageModalActions from '../../../actions/modals/messageModal'
import getExistsActiveDossiersWithTheOperationType from '../../../services/operationType/getExistsActiveDossiersWithTheOperationType'
import { yesNoModal } from '../../modalsListeners/yesNoModal'

export function * fetchOperationTypesTable ({ filter }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield filter = yield filter || select(getOperationTypeFilter)
    const operationTypeTable = yield call(postOperationTypeTable, auth.token, filter)
    yield put(fetchOperationTypesTableSuccess(operationTypeTable, filter))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchOperationTypesTable () {
  yield takeEvery(mastersActionTypes.FETCH_OPERATION_TYPES_TABLE, fetchOperationTypesTable)
}

export function * openOperationtTypeModal ({ operationTypeId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const languageList = yield call(getLanguageList, auth.token)
    const operationType = operationTypeId ? yield call(getOperationType, auth.token, operationTypeId) : {}
    yield put(openOperationTypeModalSuccess(operationType, languageList))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchOpenOperationTypeModal () {
  yield takeEvery(mastersActionTypes.OPEN_OPERATION_TYPE_MODAL, openOperationtTypeModal)
}

export function * saveOperationType ({ operationType }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const filter = yield filter || select(getOperationTypeFilter)
    if (operationType.operationTypeId) {
      yield call(putOperationType, auth.token, operationType)
    } else {
      yield call(postOperationType, auth.token, operationType)
    }
    yield put(closeOperationTypeModal())
    const operationTypeTable = yield call(postOperationTypeTable, auth.token, filter)
    yield put(fetchOperationTypesTableSuccess(operationTypeTable, filter))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSaveOperationType () {
  yield takeEvery(mastersActionTypes.SAVE_OPERATION_TYPE, saveOperationType)
}

export function * recoverOperationType ({ operationTypeId }) {
  try {
    yield put(showLoader())
    const filter = yield filter || select(getOperationTypeFilter)
    const auth = yield select(getAuth)
    yield call(putRecoverOperationType, operationTypeId, auth.token)
    const operationTypeTable = yield call(postOperationTypeTable, auth.token, filter)
    yield put(fetchOperationTypesTableSuccess(operationTypeTable, filter))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchRecoverOperationType () {
  yield takeEvery(mastersActionTypes.RECOVER_OPERATION_TYPE, recoverOperationType)
}

export function * deleteOperationType ({ operationTypeId }) {
  try {
    yield put(showLoader())
    const filter = yield filter || select(getOperationTypeFilter)
    const auth = yield select(getAuth)
    const existsDossiers = yield call(getExistsActiveDossiersWithTheOperationType, auth.token, operationTypeId)
    if (!existsDossiers) {
      yield put(hideLoader())
      const confirmed = yield call(yesNoModal, 'deleteTemplate')
      if (confirmed) {
        yield put(showLoader())
        yield call(putDeleteOperationType, operationTypeId, auth.token)
        const operationTypeTable = yield call(postOperationTypeTable, auth.token, filter)
        yield put(fetchOperationTypesTableSuccess(operationTypeTable, filter))
      }
    } else {
      yield put(openModal('operationTypeAssociatedToDossiers'))
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    yield call(handleError, { error, customHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDeleteOperationType () {
  yield takeEvery(mastersActionTypes.DELETE_OPERATION_TYPE, deleteOperationType)
}
