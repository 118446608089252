import apiFetch from '../apiFetch'

export default function (token, fleetId, fleetChargeId) {
  const queryParamsArray = [
    fleetId ? `fleetId=${fleetId}` : null,
    fleetChargeId ? `fleetChargeId=${fleetChargeId}` : null
  ]

  const queryParams = queryParamsArray.reduce(
    (result, query, idx) => result + (query ? (result ? `&${query}` : `?${query}`) : '')
    , ''
  )
  return apiFetch({ endPoint: `UnattendedProcess/SetRecoverFleetCharge${queryParams}`, method: 'POST', token: token })
}
