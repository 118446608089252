import React, { PureComponent } from 'react'
import { Row, Panel } from 'react-bootstrap'
import { Field } from 'redux-form'
import { permissions } from '../../../constants/backendIds'
import { getFieldsPermisions } from '../../../util/permisionFunctions'
import { compareObjects } from '../../../util/utils'
import InputCheckbox from '../../commons/form/InputCheckBox'
import InputDatePicker from '../../commons/form/InputDatePicker'

export default class PriceChange extends PureComponent {
  constructor () {
    super()
    this.state = {
      open: false,
      fieldsConfiguration: {
        updatedPrice: null,
        lastPriceChangeDate: null
      }
    }
  }

  initializePermisions (props) {
    const fieldKeys = Object.keys(this.state.fieldsConfiguration)
    const newState = getFieldsPermisions(props.componentConfiguration.fieldsConfiguration, fieldKeys, props.componentConfiguration.permission)
    this.setState({ fieldsConfiguration: newState.fieldsConfiguration })
  }

  toggleComponent () {
    if (this.state.open) {
      this.props.actions.setActiveSection(false)
    }
    this.setState({ open: !this.state.open })
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (this.props.dossier && this.props.dossier.dossierId) {
      if (!compareObjects(prevProps.componentConfiguration || {}, this.props.componentConfiguration, true)) {
        this.initializePermisions(this.props)
      }
      if (!prevProps.open && this.props.open) {
        this.setState({ open: true })
      }
    }
    return prevProps
  }

  componentDidUpdate () {
    // don't remove use for getsnapshot
  }

  componentDidMount () {
    this.initializePermisions(this.props)
  }

  render () {
    const {
      t, readOnlyFields, dossier: { priceChange }
    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.PRICE_CHANGE.'

    return (
      <Row className="entry-panel dossier-panel">
        <div className="header-panel" onClick={() => this.toggleComponent()}>
          <div className="name-wrapper">
            <i className="ico-i" />
            <h4>{t(`${tKey}TITLE_PANEL`)}</h4>
          </div>
          <i className={this.state.open ? 'ico-arrow-black-up' : 'ico-arrow-black-down'} />
        </div>
        <Panel expanded={this.state.open} onToggle={() => {}}>
          <Panel.Collapse>
            {this.state.fieldsConfiguration.updatedPrice && this.state.fieldsConfiguration.updatedPrice.permission !== permissions.hidden && (

              <Row className='flexRow'>
                <Field
                  id="updatedPrice"
                  name="priceChange.updatedPrice"
                  placeholder={t(`${tKey}UPDATED_PRICE_INIT`) + priceChange.thresholdDays + t(`${tKey}UPDATED_PRICE_FINAL`)}
                  component={InputCheckbox}
                  colSm={0}
                  inline
                  disabled={readOnlyFields || this.state.fieldsConfiguration.updatedPrice.permission === permissions.readOnly }
                />
              </Row>

            )}
            {this.state.fieldsConfiguration.lastPriceChangeDate && this.state.fieldsConfiguration.lastPriceChangeDate.permission !== permissions.hidden && (
              <Row className='flexRow'>
                <Field
                  id="lastPriceChangeDate"
                  name="priceChange.lastPriceChangeDate"
                  colSm={3}
                  controlLabel={t(`${tKey}LAST_PRICE_CHANGE`)}
                  placeholder={t(`${tKey}LAST_PRICE_CHANGE`)}
                  component={InputDatePicker}
                  disabled={readOnlyFields || this.state.fieldsConfiguration.lastPriceChangeDate.permission === permissions.readOnly }
                />
              </Row>
            )}
          </Panel.Collapse>
        </Panel>
      </Row>
    )
  }
}
