import React, { PureComponent } from 'react'
import { Panel, Col, Table, Button } from 'react-bootstrap'
import UACrow from './UAC/UACrow'
import SmallLoaderPage from '../../../containers/commons/SmallLoaderPage'
import CustomFields from '../../commons/customFields/CustomFields'

class UAC extends PureComponent {
  constructor () {
    super()
    this.state = { open: false }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.open !== this.props.open && this.props.open) {
      this.setState({ open: true })
    }
    return prevProps
  }

  handleClickHeader () {
    if (this.state.open) {
      this.props.actions.setActiveSection(false)
    }
    this.setState({ open: !this.state.open })
  }

  render () {
    const { t, readOnlyFields, uac, customFields, actions: { openUACEdition, deleteUACUnit, fetchUACUnitEdition }, dossierType } = this.props
    const tKey = 'DOSSIER_COMPONENTS.UAC.'
    return (
      <div className="uac-panel dossier-panel">
        <div className="header-panel" onClick={() => this.handleClickHeader()}>
          <div className="name-wrapper">
            <i className="ico-uo" />
            <h4>{t(`${tKey}TITLE_PANEL`)}</h4>
          </div>
          <i className={this.state.open ? 'ico-arrow-black-up' : 'ico-arrow-black-down'} />
        </div>
        <Panel expanded={this.state.open} onToggle={() => {}}>
          <Panel.Collapse>
            <Col sm={12} className="col-row">
              <Table className="editable-table" hover responsive>
                <thead>
                  <tr>
                    <th>{t(`${tKey}EXP_NUMBER`)}</th>
                    <th>{t(`${tKey}ID_DEALER`)}</th>
                    <th>{t(`${tKey}BRAND`)}</th>
                    <th>{t(`${tKey}MODEL`)}</th>
                    <th>{t(`${tKey}KM`)}</th>
                    <th>{t(`${tKey}LICENCE_PLATE`)}</th>
                    <th>{t(`${tKey}VIN_CHASSIS`)}</th>
                    <th>{t(`${tKey}LICENCE_PLATE_DATE`)}</th>
                    <th>{t(`${tKey}PAYMENT_INCLUDED`)}</th>
                    <th style={{ width: '77px' }}>{t('DOSSIER_COMPONENTS.ACCESSORIES.ACTIONS')}</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    uac.map((uacUnit, idx) => {
                      return (
                        <UACrow
                          t={t}
                          tKey={tKey}
                          uacUnit={uacUnit}
                          key={idx}
                          openUACEdition={openUACEdition}
                          fetchUACUnitEdition={fetchUACUnitEdition}
                          deleteUACUnit={deleteUACUnit}
                          readOnlyFields={readOnlyFields}
                          dossierType = { dossierType }
                        />
                      )
                    })
                  }
                  <tr className="button-table-wrapper">
                    <td>
                      {!readOnlyFields &&
                      <Button className="btn-editable-table btn-standard" onClick={() => fetchUACUnitEdition(null, openUACEdition, dossierType)}>+ {t(`${tKey}ADD_BTN`)}</Button>
                      }
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <CustomFields customFields={customFields} readOnlyFields={true} />
          </Panel.Collapse>
        </Panel>
        <SmallLoaderPage />
      </div>
    )
  }
}
export default UAC
