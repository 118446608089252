import React, { PureComponent } from 'react'
import { Panel, Row } from 'react-bootstrap'
import EditableTable from '../../../commons/editableTable/EditableTable'
import { formatMoney } from '../../../../util/utils'
import { Element } from 'react-scroll'
import { buttonsSidebar } from '../../../../constants/dossier/common/buttonsSidebar'
import EconomicPlanTotal from './EconomicPlanTotal'
import { permissions } from '../../../../constants/backendIds'

class EconomicPlanReaconditioning extends PureComponent {
  render () {
    const { t, dossierEconomicComponent, reaconditioningOpen, totalReaconditioning, fieldsConfiguration } = this.props
    const tKey = 'DOSSIER_COMPONENTS.'

    return (
      <Element name={buttonsSidebar.reaconditioning} className={buttonsSidebar.reaconditioning}>
        <div className="dossier-panel">
          <div className="header-panel" onClick={ () => this.props.openOrCloseReaconditioning() }>
            <div className="name-wrapper">
              <i className={reaconditioningOpen ? 'ico-arrow-black-up' : 'ico-arrow-black-down'}/>
              <h4>{t(`${tKey}REACONDITIONING.TITLE_PANEL`)}</h4>
              <EconomicPlanTotal
                totals={totalReaconditioning}
                totalText={t(`${tKey}ECONOMIC_PLAN.TOTALS`) + ' ' + t(`${tKey}REACONDITIONING.VTA_IMPORT`)}
                costText={t(`${tKey}ECONOMIC_PLAN.TOTALS`) + ' ' + t(`${tKey}REACONDITIONING.COST`)}
                hideTotalVta={true}
              />
            </div>
          </div>
          {/* <Panel className="table-responsive" onToggle={() => {}} expanded={this.state.reaconditioningOpen}> */}
          <Panel className="table-responsive" onToggle={() => {}} expanded={reaconditioningOpen}>
            <Panel.Collapse>
              <Row>
                <EditableTable
                  t={t}
                  columnsConfig={
                    [
                      { key: 'oculto', onlyShow: true, className: 'maxW40' },
                      {
                        th: t(`${tKey}REACONDITIONING.TITLE_PANEL`),
                        key: 'name',
                        className: 'description-table-planteo',
                        nonEditable: true,
                        showColumn: fieldsConfiguration.reaconditioning.reaconditioningCol ? fieldsConfiguration.reaconditioning.reaconditioningCol.permission > permissions.hidden : false
                      },
                      { key: 'oculto' },
                      { th: t(`${tKey}REACONDITIONING.VTA_IMPORT`), key: 'amount', inputType: 'number', formatFunc: (amount) => formatMoney(amount), nonEditable: true, className: 'amount-table-planteo', showColumn: false },
                      { key: 'percentage', nonEditable: true, className: 'perc-table-planteo', inputType: 'number', showColumn: false },
                      {
                        th: t(`${tKey}REACONDITIONING.COST`),
                        key: 'cost',
                        money: true,
                        className: 'cost-table-planteo',
                        nonEditable: true,
                        showColumn: fieldsConfiguration.reaconditioning.costCol ? fieldsConfiguration.reaconditioning.costCol.permission > permissions.hidden : false
                      },
                      {
                        th: t(`${tKey}REACONDITIONING.PROVIDER`),
                        key: 'provider',
                        className: 'provider-table-planteo',
                        nonEditable: true,
                        showColumn: fieldsConfiguration.reaconditioning.providerCol ? fieldsConfiguration.reaconditioning.providerCol.permission > permissions.hidden : false
                      }
                    ]
                  }
                  editableObjectsArray={dossierEconomicComponent.purchasePresents || []}
                  readOnlyFields={true}
                  columnsConfigTotales={
                    [
                      { key: 'name', onlyShow: true, className: 'maxW40' },
                      {
                        key: 'oculto',
                        showColumn: fieldsConfiguration.reaconditioning.reaconditioningCol ? fieldsConfiguration.reaconditioning.reaconditioningCol.permission > permissions.hidden : false
                      },
                      { key: 'oculto' },
                      { th: t(`${tKey}REACONDITIONING.VTA_IMPORT`), key: 'amount', inputType: 'number', formatFunc: (amount) => formatMoney(amount), nonEditable: true, className: 'amount-table-planteo', showColumn: false },
                      { key: 'percentage', nonEditable: true, className: 'perc-table-planteo', inputType: 'number', showColumn: false },
                      {
                        th: t(`${tKey}REACONDITIONING.COST`),
                        key: 'cost',
                        inputType: 'number',
                        formatFunc: (amount) => formatMoney(amount),
                        className: 'cost-table-planteo',
                        showColumn: fieldsConfiguration.reaconditioning.costCol ? fieldsConfiguration.reaconditioning.costCol.permission > permissions.hidden : false
                      },
                      {
                        th: t(`${tKey}REACONDITIONING.PROVIDER`),
                        key: 'provider',
                        className: 'provider-table-planteo',
                        showColumn: fieldsConfiguration.reaconditioning.providerCol ? fieldsConfiguration.reaconditioning.providerCol.permission > permissions.hidden : false
                      }
                    ]
                  }
                  editableObjectsArrayTotales={totalReaconditioning}
                  showDeleteAction={false}
                  showEditAction={false}
                  showAddButton={false}
                />
              </Row>
            </Panel.Collapse>
          </Panel>
        </div>
      </Element>
    )
  }
}

export default EconomicPlanReaconditioning
