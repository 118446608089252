import { put, takeEvery } from 'redux-saga/effects'
import headerActionTypes from '../../constants/actions/header/header'

export function * setHeader ({ values }) {
  yield put(headerActionTypes.SET_HEADER_SUCCESS, values)
}

export function * watchSetHeader () {
  yield takeEvery(headerActionTypes.SET_HEADER, setHeader)
}
