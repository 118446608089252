import { put, race, take, takeEvery } from 'redux-saga/effects'
import { openModal, closeModal } from '../../actions/common.js'
import customModalTypes from '../../constants/actions/modals/customModal'
import { replace } from 'connected-react-router'

export function * yesNoModal (modalType, okAction, cancelAction, message, subMessage, replaceMessageArgs, replaceSubMessageArgs) {
  yield put(openModal(modalType, okAction, cancelAction, message, subMessage, replaceMessageArgs, replaceSubMessageArgs))
  const { ok } = yield race({
    ok: take(customModalTypes.CUSTOM_MODAL_OK),
    nok: take(customModalTypes.CUSTOM_MODAL_NOK)
  })
  yield put(closeModal())
  if (modalType === 'sessionExpired') {
    yield put(replace('/login'))
  }
  return !!ok
}

export function * watchYesNoModal () {
  yield takeEvery(customModalTypes.YES_NO_MODAL, yesNoModal)
}
