import React, { PureComponent } from 'react'
import { reduxForm, Field } from 'redux-form'
import { Row, Col, Table, Button, Tooltip, OverlayTrigger, Glyphicon } from 'react-bootstrap'
import ProcessingAgencyModal from '../../../containers/masters/ProcessingAgency/ProcessingAgencyModalPage'
import IDocCarPagination from '../../commons/iDocCarPagination'
import InputText from '../../commons/form/InputText'
import InputTreeSelect from '../../commons/form/InputTreeSelect'
import ModalMassUploadPage from '../../../containers/masters/ModalMassUpload/ModalMassUploadPage'
import { loadTemplatesNames, permissions } from '../../../constants/backendIds'
import { getFieldsSectionPermissions } from '../../../util/permisionFunctions'
import ProcessingAngencyUsersModalPage from '../../../containers/masters/ProcessingAgency/ProcessingAngencyUsersModalPage'
import ProcessingAgencyIntegrationModal from '../../../containers/masters/ProcessingAgency/ProcessingAgencyIntegrationModalPage'

class ProcessingAgency extends PureComponent {
  constructor () {
    super()
    this.state = {
      filter: {
        searchUO: null,
        searchName: null
      },
      showModal: false,
      fieldsConfiguration: {
        loadDataTemplate: null
      }
    }
  }

  getSortIcon (column = '') {
    switch (this.props.filter.orderBy) {
      case '+' + column:
        return 'ico-sort-selected-asc'
      case '-' + column:
        return 'ico-sort-selected-desc'
      default:
        return 'ico-sort'
    }
  }

  changeOrder (field) {
    switch (this.props.filter.orderBy) {
      case `+${field}`:
        this.props.actions.fetchProcessingAgencyList({ ...this.state.filter, ...this.props.filter, orderBy: `-${field}` })
        break
      default:
        this.props.actions.fetchProcessingAgencyList({ ...this.state.filter, ...this.props.filter, orderBy: `+${field}` })
        break
    }
  }

  handleOrganizedUnitChange (searchUO) {
    if (!searchUO || searchUO.length === 0) {
      this.setState({ filter: { ...this.state.filter, searchUO: [] } })
      return
    }
    const searchUoList = searchUO.split(',').map((item) => {
      return parseInt(item)
    })
    if (this.state.filter.searchUO !== searchUoList) {
      this.setState({ filter: { ...this.state.filter, searchUO: searchUoList } })
    }
  }

  handleNameChange (searchName) {
    if (this.state.filter.searchName !== searchName) {
      this.setState({ filter: { ...this.state.filter, searchName } })
    }
  }

  searchProcessingAgency () {
    this.props.actions.fetchProcessingAgencyList({ ...this.props.filter, ...this.state.filter, page: 1 })
  }

  openProcAgModalLoad () {
    this.setState({ showModal: true })
    this.props.actions.openModalMass('MASTERS.PROCESSING_AGENCY_MASTERS.MODAL_UPLOAD.', this.props.actions.uploadProcAgMassDocument, this.props.actions.fetchProcessingAgencyList)
  }

  closeProcAgModalLoad () {
    this.setState({ showModal: false })
  }

  componentDidUpdate (prevProps) {
    if (this.props.tabConfiguration !== prevProps.tabConfiguration) {
      if (this.props.tabConfiguration && this.props.tabConfiguration.sectionFieldsConfiguration) {
        const fieldKeys = Object.keys(this.state.fieldsConfiguration)
        const permission = getFieldsSectionPermissions(this.props.tabConfiguration.sectionFieldsConfiguration, fieldKeys, permissions.hidden)
        this.setState({ fieldsConfiguration: permission.fieldsConfiguration })
      }
    }
  }

  resetFilters () {
    this.setState({
      filter: {
        searchUO: null,
        searchName: null
      }
    }, () => { this.searchProcessingAgency() })
  }

  render () {
    const {
      t, agencyList, pagesCount, agenciesCount, filter,
      filter: { page },
      combos: { UOTreeCombo },
      actions: { openEditProcessingAgencyModal, openAddProcessingAgencyModal, fetchProcessingAgencyList, deleteProcessingAgency, fetchTemplateFile, recoverProcessingAgency, openUserProcessingAgencyModal, showEditProcessingAgencyIntegrationModal }
    } = this.props

    const tKey = 'MASTERS.'
    const editTooltip = (<Tooltip id="editTooltip">{t(`${tKey}EDIT`)}</Tooltip>)
    const userTooltip = (<Tooltip id="editTooltip">{t(`${tKey}USERS`)}</Tooltip>)
    const deleteTooltip = (<Tooltip id="deleteTooltip">{t(`${tKey}DELETE`)}</Tooltip>)
    const recoverTooltip = (<Tooltip id="deleteTooltip">{t(`${tKey}RECOVER`)}</Tooltip>)
    const hasFilters = (this.state.filter.searchUO || this.state.filter.searchName)
    const editIntegrationTooltip = (<Tooltip id="editIntegrationTooltip">{t(`${tKey}EDIT_INTEGRATION`)}</Tooltip>)

    return (
      <>
        <div className="admin-wrapper">
          <ModalMassUploadPage
            showModal={this.state.showModal}
            closeModal={this.closeProcAgModalLoad.bind(this)}
          />
          <ProcessingAgencyModal />
          <ProcessingAgencyIntegrationModal />
          <Row className="section-users-header">
            <Col sm={12}>
              <div className="header-panel">
                <h4>{t(`${tKey}PROCESSING_AGENCY`)}</h4>
              </div>
            </Col>
            {this.state.fieldsConfiguration.loadDataTemplate && this.state.fieldsConfiguration.loadDataTemplate.permission > permissions.hidden &&
              <Col sm={12}>
                <Button
                  className="btn-download btn-editable-table btn-standard"
                  onClick={() => fetchTemplateFile(loadTemplatesNames.procAg)}
                >
                  {t('MASTERS.DOWNLOAD')}
                </Button>
              </Col>
            }
          </Row>
          <Row>
            <div className="search-form">
              <span className="title-search"><i className="ico-search" /> {t(`${tKey}PROCESSING_AGENCY_MASTERS.SEARCH_TITLE`)}</span>
              <Row>
                <Field
                  name="filters.organizedUnit"
                  controlLabel={t(`${tKey}PROCESSING_AGENCY_MASTERS.ORGANIZED_UNIT`)}
                  placeholder={t(`${tKey}PROCESSING_AGENCY_MASTERS.ORGANIZED_UNIT`)}
                  component={InputTreeSelect}
                  multi={true}
                  valueKey="value"
                  labelKey="label"
                  childrenKey="children"
                  options={UOTreeCombo}
                  colSm={3}
                  onInputChange={(value) => this.handleOrganizedUnitChange(value)}
                />
                <Field
                  name="filters.name"
                  colSm={2}
                  controlLabel={t(`${tKey}PROCESSING_AGENCY_MASTERS.NAME`)}
                  placeholder={t(`${tKey}PROCESSING_AGENCY_MASTERS.NAME`)}
                  component={InputText}
                  onInputChange={(value) => this.handleNameChange(value)}
                />
                <Col sm={7} className="btn-wrapper">
                  <Button
                    className={'btn-white btn-icon btn-input btn-white search-button'}
                    style={{ width: '100%' }}
                    onClick={() => this.searchProcessingAgency()}>
                    <i className="ico-search" />
                    {t(`${tKey}SEARCH_BTN`)}
                  </Button>
                  <Button
                    className={'btn-icon  btn-input ' + (hasFilters ? 'btn-standard' : 'btn-cancel')}
                    onClick={() => {
                      this.props.initialize({})
                      this.resetFilters()
                    }}
                  >
                    <i className="ico-cancel" />
                    {t(`${tKey}RESET_BUTTON`)}
                  </Button>
                </Col>
              </Row>
            </div>
          </Row>
          <Col sm={12} className="col-row  section-users-body">
            <Table className="editable-table" hover responsive>
              <thead>
                <tr>
                  <th onClick={() => { this.changeOrder('code') }}>
                    {t(`${tKey}PROCESSING_AGENCY_MASTERS.ORGANIZED_UNIT`)}
                    <i className={this.getSortIcon('code')} />
                  </th>
                  <th onClick={() => { this.changeOrder('name') }}>
                    {t(`${tKey}PROCESSING_AGENCY_MASTERS.NAME`)}
                    <i className={this.getSortIcon('name')} />
                  </th>
                  <th style={{ width: '177px' }}>{t('DOSSIER_COMPONENTS.ACCESSORIES.ACTIONS')}</th>
                </tr>
              </thead>
              <tbody>
                {agencyList && agencyList.map((agency, idx) => {
                  return (
                    <tr key={idx} className={(agency.isDeleted ? 'is-deleted' : '')}>
                      <td>{agency.organizedUnitName}</td>
                      <td>{agency.name}</td>
                      <td>
                        {!agency.isDeleted ? (
                          <div>
                            <a onClick={() => showEditProcessingAgencyIntegrationModal(true, agency.processingAgencyId)} className="uo-ico-financing-master">
                              <OverlayTrigger placement="left" overlay={editIntegrationTooltip}>
                                <i className="ico-uo" />
                              </OverlayTrigger>
                            </a>
                            <a onClick={() => openEditProcessingAgencyModal(agency.processingAgencyId)}>
                              <OverlayTrigger placement="left" overlay={editTooltip}>
                                <i className="ico-edit-white" />
                              </OverlayTrigger>
                            </a>
                            <a onClick={() => openUserProcessingAgencyModal(agency)}>
                              <OverlayTrigger placement="left" overlay={userTooltip}>
                                <i className="ico-user" />
                              </OverlayTrigger>
                            </a>
                            <a onClick={() => deleteProcessingAgency(agency.processingAgencyId)}>
                              <OverlayTrigger placement="left" overlay={deleteTooltip}>
                                <i className="ico-trash" />
                              </OverlayTrigger>
                            </a>
                          </div>
                        ) : (
                          <a onClick={() => recoverProcessingAgency(agency.processingAgencyId)}>
                            <OverlayTrigger placement="left" overlay={recoverTooltip}>
                              <Glyphicon glyph="repeat" />
                            </OverlayTrigger>
                          </a>
                        )}
                      </td>
                    </tr>)
                })
                }
                {(agencyList === null || agencyList === undefined || agencyList.length === 0) &&
                  <tr>
                    <td colSpan={4}><h2>{t(`${tKey}PROCESSING_AGENCY_MASTERS.RESULTS_NOT_FOUND`)}</h2></td>
                  </tr>}
                <tr className="button-table-wrapper">
                  <td colSpan="2">
                    <Button
                      className="btn-editable-table btn-standard col-2"
                      onClick={() => openAddProcessingAgencyModal()}>
                      + {t('MASTERS.ADD')}
                    </Button>
                    {this.state.fieldsConfiguration.loadDataTemplate && this.state.fieldsConfiguration.loadDataTemplate.permission > permissions.hidden &&
                      <Button
                        className="btn-editable-table btn-standard col-2"
                        onClick={this.openProcAgModalLoad.bind(this)}
                      >
                        <Glyphicon glyph="open-file" />{' ' + t('MASTERS.ADD')}
                      </Button>}
                  </td>
                </tr>
              </tbody>
            </Table>
            <div className="section-users-footer">
              <nav key={1} className="text-center">
                <IDocCarPagination
                  id="btn_pag_sales"
                  page={page}
                  pagesCount={pagesCount}
                  totalRows={agenciesCount}
                  onSelectAction={(value) => fetchProcessingAgencyList({ ...filter, ...this.state.filter, page: value })}
                />
              </nav>
            </div>
          </Col>
        </div>
        <ProcessingAngencyUsersModalPage fetchProcessingAgencyList={() => this.props.actions.fetchProcessingAgencyList({ ...this.props.filter, ...this.state.filter })}></ProcessingAngencyUsersModalPage>
      </>
    )
  }
}

export default reduxForm({
  form: 'listAgencies'
})(ProcessingAgency)
