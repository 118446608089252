import React, { PureComponent } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Link } from 'react-router-dom'

class PopoverItem extends PureComponent {
  constructor () {
    super()
    this.state = {
      target: this,
      icon: '',
      tkeyTooltip: ' ',
      colorPoint: false,
      placement: ''
      // aviableLink: false
    }
  }

  render () {
    const {
      t,
      itemType,
      // tooltip,
      data,
      urgency,
      linkTo,
      dossierNumber,
      placement
    } = this.props
    switch (placement) {
      case 'right': {
        this.placement = 'right'
        break
      }
      case 'top': {
        this.placement = 'top'
        break
      }
      case 'bottom': {
        this.placement = 'bottom'
        break
      }
      default: {
        this.placement = 'left'
        break
      }
    }
    switch (itemType) {
      case 'ent-1': /* entity.entityType */
      { this.icon = 'iDocIcon-type'
        this.tkeyTooltip = 'SEARCH.CATEGORIES.ENTITY.ENTITY_SUB_TYPE_ID'
        break }
      case 'ent-2': /* entity.entityNameAndSurname1 */
      { this.icon = 'iDocIcon-user-no-image-user-menu'
        this.tkeyTooltip = 'SEARCH.CATEGORIES.ENTITY.NAME'
        break }
      case 'ent-3': /* entity.entityNameOrigin */
      { this.icon = 'iDocIcon-client-origin'
        this.tkeyTooltip = 'SEARCH.CATEGORIES.ENTITY.NAME_ORIGIN'
        break }
      case 'ent-4': /* entity.entityNameDestination */
      { this.icon = 'iDocIcon-client-destiny'
        this.tkeyTooltip = 'SEARCH.CATEGORIES.ENTITY.NAME_DEST'
        break }
      case 'ent-5': /* entity.clientNumber */
      { this.icon = 'iDocIcon-tag-id-main-customer'
        this.tkeyTooltip = 'SEARCH.CATEGORIES.ENTITY.MAIN_ENTITY_NUMBER'
        break }
      case 'ent-6': /* entity.entityCifNif */
      { this.icon = 'iDocIcon-badge-id-number-dni'
        this.tkeyTooltip = 'SEARCH.CATEGORIES.ENTITY.DNI_CIF'
        break }
      case 'ent-7': /* entity.entityAddress */
      { this.icon = 'iDocIcon-direction'
        this.tkeyTooltip = 'SEARCH.CATEGORIES.ENTITY.ADDRESS'
        break }
      case 'ent-8': /* entity.entityPhone */
      { this.icon = 'iDocIcon-phone-movile'
        this.tkeyTooltip = 'SEARCH.CATEGORIES.ENTITY.PHONE'
        break }
      case 'ent-9': /* entity.entityMail */
      { this.icon = 'iDocIcon-envelope-notifications-close'
        this.tkeyTooltip = 'SEARCH.CATEGORIES.ENTITY.EMAIL'
        break }
      /// //////////////////////////////////////////////////
      case 'doss_01': /* dossier.number urgencyType */
      { this.icon = 'iDocIcon-file-folder'
        this.tkeyTooltip = 'SEARCH.CATEGORIES.DOSSIER.DOSSIER_NUMBER'
        this.colorPoint = true
        break }
      case 'doss_02': /* {dossier.subtype */
      { this.icon = 'iDocIcon-folder-open-solid'
        this.tkeyTooltip = 'SEARCH.CATEGORIES.DOSSIER.DOSSIER_SUBTYPE'
        break }
      case 'doss_03': /* dossier.stage */
      { this.icon = 'iDocIcon-adjust-empty'
        this.tkeyTooltip = 'SEARCH.CATEGORIES.DOSSIER.STAGE'
        break }
      case 'doss_04': /* dossier.organizedUnit */
      { this.icon = 'iDocIcon-Organizational-Unit'
        this.tkeyTooltip = 'SEARCH.CATEGORIES.DOSSIER.ORGANIZED_UNIT'
        break }
      case 'doss_05': /* dossier.salesman */
      { this.icon = 'iDocIcon-id-badge-initial-seller'
        this.tkeyTooltip = 'SEARCH.CATEGORIES.DOSSIER.SALESMAN'
        break }
      case 'doss_06': /* dossier.lastUpdateDate */
      { this.icon = 'iDocIcon-event-available-renewal'
        this.tkeyTooltip = 'SEARCH.CATEGORIES.DOSSIER.LAST_CHANGE_DATE'
        break }
      case 'doss_07': /* dossier.dossierParentSubType */
      { this.icon = 'iDocIcon-folder-related-files'
        this.tkeyTooltip = 'SEARCH.DEPENDENCE_OF'
        break }
      case 'doss_08': /* not dependence */
      { this.icon = 'iDocIcon-folder-related-files'
        this.tkeyTooltip = 'SEARCH.DEPENDENCE_OF'
        break }
      case 'doss_09': /* dossier.deliveryCity */
      { this.icon = 'iDocIcon-map-direction'
        this.tkeyTooltip = 'DOSSIER_COMPONENTS.DELIVERY.DELIVERY_CITY'
        break }
      /// //////// DocumentPopover //////////////////////////////////
      case 'doc_01': /* document.creationDate */
      { this.icon = 'iDocIcon-today'
        this.tkeyTooltip = 'SEARCH.CATEGORIES.DOCUMENT.CREATION_DATE'
        break }
      case 'doc_02': /* document.creationUser */
      { this.icon = 'iDocIcon-user-no-image-user-menu'
        this.tkeyTooltip = 'SEARCH.CATEGORIES.DOCUMENT.USER_CREATOR'
        break }
      case 'doc_03': /* document.documentTypeUse */
      { this.icon = 'iDocIcon-file-document'
        this.tkeyTooltip = 'SEARCH.CATEGORIES.DOCUMENT.TYPE'
        break }
      /// /////// VehiclePopover ////////////////////////////////////
      case 'veh_01': /* vehicle.vehicleVinChassis */
      { this.icon = 'iDocIcon-abc-vin'
        this.tkeyTooltip = 'SEARCH.CATEGORIES.VEHICLE.CHASSIS'
        break }
      case 'veh_02': /* vehicle.carline */
      { this.icon = 'iDocIcon-file-vehicle'
        this.tkeyTooltip = 'SEARCH.CATEGORIES.VEHICLE.CARLINE'
        break }
      case 'veh_03': /* vehicle.catalogue */
      { this.icon = 'iDocIcon-'
        this.tkeyTooltip = 'SEARCH.CATEGORIES.VEHICLE.CATALOGUE'
        break }
      case 'veh_04': /* vehicle.modelYear */
      { this.icon = 'iDocIcon-'
        this.tkeyTooltip = 'SEARCH.CATEGORIES.VEHICLE.MODEL_YEAR'
        break }
      case 'veh_05': /* vehicle.vehicleModel */
      { this.icon = 'iDocIcon-vehicle-model-version'
        this.tkeyTooltip = 'SEARCH.CATEGORIES.VEHICLE.MODEL'
        break }
      case 'veh_06': /* vehicle.vehicleBrand */
      { this.icon = 'iDocIcon-copyright-regular'
        this.tkeyTooltip = 'SEARCH.CATEGORIES.VEHICLE.BRAND'
        break }
      case 'veh_07': /* vehicle.vehicleLicensePlate */
      { this.icon = 'iDocIcon-plate'
        this.tkeyTooltip = 'SEARCH.CATEGORIES.VEHICLE.LICENSE_PLATE'
        break }
      case 'veh_08': /* vehicle.comercialCode */
      { this.icon = 'iDocIcon-tag-id-main-customer'
        this.tkeyTooltip = 'SEARCH.CATEGORIES.VEHICLE.COMMERCIAL_CODE'
        break }
      case 'veh_09': /* vehicle.licensePlateDate */
      { this.icon = 'iDocIcon-today'
        this.tkeyTooltip = 'SEARCH.CATEGORIES.VEHICLE.FIRST_LPLATE_DATE'
        break }
      case 'veh_10': /* vehicle.color */
      { this.icon = 'iDocIcon-Color'
        this.tkeyTooltip = 'SEARCH.CATEGORIES.VEHICLE.COLOR'
        break }
      case 'veh_11': /* vehicle.tapestry */
      { this.icon = 'iDocIcon-tapestry'
        this.tkeyTooltip = 'SEARCH.CATEGORIES.VEHICLE.TAPESTRY'
        break }
      case 'veh_12': /* vehicle.vehicleVinChassis */
      { this.icon = 'iDocIcon-abc-vin'
        this.tkeyTooltip = 'SEARCH.CATEGORIES.VEHICLE.CHASSIS'
        break }
      /// fleets //////////////////////////////////////////////////////////////////////////////
      case 'fle-1': /* vehicle.tapestry */
      { this.icon = 'iDocIcon-file-fleets'
        this.tkeyTooltip = 'DOSSIER_COMPONENTS.FLEET.FLEET'
        break }
      case 'fle-2': /* vehicle.tapestry */
      { this.icon = 'iDocIcon-description'
        this.tkeyTooltip = 'DOSSIER_COMPONENTS.FLEET.DESCRIPTION'
        break }
      case 'fle-3': /* vehicle.tapestry */
      { this.icon = 'iDocIcon-date-range-create'
        this.tkeyTooltip = 'DOSSIER_COMPONENTS.FLEET.FLEET_ASSIGNMENT_DATE'
        break }
      /// //////////////////////////////////////////////////////////////////////////////
      case 'dlist_01': /* vehicle.tapestry */
      { this.icon = 'iDocIcon-file-folder'
        this.tkeyTooltip = 'SEARCH.CATEGORIES.DOSSIER.DOSSIER_NUMBER'
        this.colorPoint = true
        break }
      default:
      {
        this.icon = 'iDocIcon-'
        this.tkeyTooltip = 'NO TEXT'
        break }
    }
    return (
      <div className="popover-item">
        <OverlayTrigger placement={this.placement} overlay={<Tooltip id={itemType} className='popoverTooltip'>{t(this.tkeyTooltip)} </Tooltip>}><i className={this.icon}></i></OverlayTrigger><span>
          {this.colorPoint &&
            <span className={'color-point ' + urgency } />
          }
          {linkTo !== undefined &&
            <Link to={linkTo}>{dossierNumber} - </Link>
          }
          {data}
        </span>
      </div>
    )
  }
}

export default PopoverItem
