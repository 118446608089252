
import React, { PureComponent } from 'react'
import { reduxForm, Field } from 'redux-form'
import { Button, Modal, Col, Row, Form, Clearfix } from 'react-bootstrap'
import InputText from '../../../commons/form/InputText'
import InputMoney from '../../../commons/form/InputMoney'
import InputCheckBox from '../../../commons/form/InputCheckBox'
import InputSelect from '../../../commons/form/InputSelect'
import { economicPlanSectionType } from '../../../../constants/dossier/common/economicPlanSection'
import PaperworkAddress from '../paperworkAddress/PaperworkAddress'
import { validatePaperworkAddress } from '../../../../util/validationFunctions'

class PaperworkEditionModal extends PureComponent {
  constructor (props) {
    super()
    this.state = {
      economicTarget: props.editedPaperwork && props.editedPaperwork.economicTarget
    }
  }

  onEconomicTargetChanges (economicTarget) {
    this.setState({ economicTarget })
  }

  submitPaperwork (values) {
    const submitValues = {
      paperworkTypeId: values.paperworkTypeId,
      cost: values.cost,
      amount: values.amount,
      processingAgencyId: values.processingAgencyId,
      comment: values.comment,
      supInvoice: values.supInvoice,
      iem: values.iem,
      toBeReviewed: values.toBeReviewed,
      economicTarget: values.economicTarget,
      provider: values.provider,
      sameEntity: values.sameEntity,
      dossierPaperworkAddressComponent: values.dossierPaperworkAddressComponent
    }

    if (values.economicTarget !== economicPlanSectionType.VENTA_OTROS) {
      submitValues.iem = null

      if (values.economicTarget !== economicPlanSectionType.SUPLIDOS) {
        submitValues.amount = null
        submitValues.supInvoice = null
      }
    }

    if (values.paperworkId) {
      this.props.actions.updatePaperwork(submitValues, values.paperworkId, this.props.dossierParentId, this.props.actions.updateDossierAction, this.props.showAlertSendPending)
    } else {
      this.props.actions.createPaperwork(submitValues, this.props.dossierParentId, this.props.actions.updateDossierAction, this.props.showAlertSendPending)
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (this.props.showModal && !nextProps.showModal) {
      this.props.initialize({})
    }
    if (!this.props.showModal && nextProps.showModal) {
      this.setState({ isDifferentPaperworkAddress: nextProps.editedPaperwork && nextProps.editedPaperwork.dossierPaperworkAddressComponent ? nextProps.editedPaperwork.dossierPaperworkAddressComponent.differentPaperworkAddress : false })
      this.props.initialize(nextProps.editedPaperwork)
    }
    if (nextProps.editedPaperwork && this.state.economicTarget !== nextProps.editedPaperwork.economicTarget) {
      this.setState({ economicTarget: nextProps.editedPaperwork.economicTarget })
    }
  }

  render () {
    const {
      t, showModal, handleSubmit, isEditionModal, showEconomicPlan,
      actions: { closePaperworkEditionModal },
      combos: { processingAgency, paperworkTypes }
    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.PAPERWORK.MODAL.'
    let economicTargetTypes = this.props.combos.economicTargetTypes

    economicTargetTypes = economicTargetTypes.map((economicTargetType) => {
      return { ...economicTargetType, value: t(`${tKey}ECONOMIC_PLAN_SECTION_TYPES.${economicTargetType.value}`) }
    })

    return (
      <Modal show={showModal} onHide={() => closePaperworkEditionModal()}>
        <Form autoComplete="off" onSubmit={handleSubmit(this.submitPaperwork.bind(this))}>
          <Modal.Header closeButton>
            <Modal.Title>{t(`${tKey}TITLE`)}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Field
                colSm={6}
                id="paperworkTypeId"
                name="paperworkTypeId"
                controlLabel={t(`${tKey}TYPE`)}
                placeholder={t(`${tKey}TYPE`)}
                component={InputSelect}
                options={paperworkTypes}
                disabled={isEditionModal}
                valueKey="id"
                labelKey="value"
              />
              <Field
                colSm={6}
                id="processingAgencyId"
                name="processingAgencyId"
                controlLabel={t(`${tKey}PROCESSING_AGENCY`)}
                placeholder={t(`${tKey}PROCESSING_AGENCY`)}
                component={InputSelect}
                options={processingAgency}
                valueKey="id"
                labelKey="value"
              />
              <Clearfix />
              {
                showEconomicPlan === true && [
                  <Field
                    colSm={6}
                    id="economicTarget"
                    name="economicTarget"
                    key="economicTarget"
                    controlLabel={t(`${tKey}ECONOMIC_TARGET`)}
                    placeholder={t(`${tKey}ECONOMIC_TARGET`)}
                    component={InputSelect}
                    options={economicTargetTypes}
                    valueKey="id"
                    labelKey="value"
                    onInputChange={this.onEconomicTargetChanges.bind(this)}
                  />,
                  <Col sm={6} className="col-row input-group-row" key="economicPlanCheckbox">
                    {(this.state.economicTarget === economicPlanSectionType.VENTA_OTROS ||
                      this.state.economicTarget === economicPlanSectionType.SUPLIDOS) && (
                      <Field
                        id="supInvoice"
                        name="supInvoice"
                        colSm={12}
                        inline
                        placeholder={t(`${tKey}SUPPLEMENTARY_INVOICE`)}
                        component={InputCheckBox}
                      />
                    )}
                    {this.state.economicTarget === economicPlanSectionType.VENTA_OTROS && (
                      <Field
                        id="iem"
                        name="iem"
                        colSm={12}
                        inline
                        placeholder={t(`${tKey}IEM`)}
                        component={InputCheckBox}
                      />
                    )}
                  </Col>,
                  <Clearfix key="economicPlanClearFix1" />,
                  <Field
                    id="cost"
                    name="cost"
                    key="cost"
                    type="text"
                    colSm={6}
                    controlLabel={t(`${tKey}COST`)}
                    placeholder={t(`${tKey}COST`)}
                    component={InputMoney}
                  />,
                  (this.state.economicTarget === economicPlanSectionType.VENTA_OTROS ||
                    this.state.economicTarget === economicPlanSectionType.SUPLIDOS) && (
                    <Field
                      id="amount"
                      name="amount"
                      key="amount"
                      type="text"
                      colSm={6}
                      controlLabel={t(`${tKey}AMOUNT`)}
                      placeholder={t(`${tKey}AMOUNT`)}
                      component={InputMoney}
                    />
                  )
                ]
              }
              <Clearfix />
              <Field
                id="comment"
                name="comment"
                colSm={6}
                controlLabel={t(`${tKey}COMMENTS`)}
                placeholder={t(`${tKey}COMMENTS`)}
                component={InputText}
              />
              <Field
                id="provider"
                name="provider"
                colSm={6}
                controlLabel={t(`${tKey}PROVIDER`)}
                placeholder={t(`${tKey}PROVIDER`)}
                component={InputText}
              />
              <Clearfix />
              {
                showEconomicPlan === true && (
                  <Field
                    id="toBeReviewed"
                    name="toBeReviewed"
                    colSm={6}
                    inline
                    placeholder={t(`${tKey}TO_BE_REVIEWED`)}
                    component={InputCheckBox}
                  />
                )
              }
              <Field
                id="sameEntity"
                name="sameEntity"
                colSm={6}
                inline
                placeholder={t(`${tKey}SAME_ENTITY`)}
                component={InputCheckBox}
              />
              <Clearfix />
              <Field
                colSm={4}
                id="differentPaperworkAddress"
                name="dossierPaperworkAddressComponent.differentPaperworkAddress"
                component={InputCheckBox}
                placeholder={t(`${tKey}PAPERWORK_ADDRESS_DIFFERENT`)}
                onInputChange={(value) => this.setState({ isDifferentPaperworkAddress: value })}
              />
            </Row>
            {this.state.isDifferentPaperworkAddress &&
              (<PaperworkAddress
                readOnlyFields={false}
                t={t}
                formInitRoute=""
              />)}
          </Modal.Body>
          <Modal.Footer>
            <Col sm={6} className="btn-wrapper">
              <Button id="btn_save" className="btn-standard" type="submit">{t(`${tKey}SAVE_BTN`)}</Button>
            </Col>
          </Modal.Footer>
        </Form>
      </Modal>
    )
  }
}

export default reduxForm({
  form: 'paperwork_edition_modal',
  validate: (values, props) => {
    const tKey = 'DOSSIER_COMPONENTS.PAPERWORK.MODAL.VALIDATION.'
    const error = {}

    error.dossierPaperworkAddressComponent = validatePaperworkAddress(values.dossierPaperworkAddressComponent ? values.dossierPaperworkAddressComponent : {}, props)
    if (!values.paperworkTypeId) error.paperworkTypeId = props.t(`${tKey}FILL_PAPERWORK_TYPE`)
    if (!values.processingAgencyId) error.processingAgencyId = props.t(`${tKey}FILL_PROCESSING_AGENCY`)
    return error
  }
})(PaperworkEditionModal)
