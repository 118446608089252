import apiFetch from '../apiFetch'

export default function (uo, faqValues, token) {
  const body = {
    OrganizedUnitId: uo,
    Disabled: false,
    Translations: faqValues
  }
  return apiFetch({
    endPoint: 'FaqSection',
    method: 'POST',
    body: body,
    token: token
  })
}
