import { call, put, takeEvery, select } from 'redux-saga/effects'
import { initialize, reset, change } from 'redux-form'
import vehicleActionsTypes from '../../../../constants/actions/dossier/common/vehicle'
import { showLoader, hideLoader } from '../../../../actions/common'
import { fetchOrderModalSuccess } from '../../../../actions/dossier/common/vehicle'
import { fetchCombosSuccess } from '../../../../actions/combos/combos'
import { getAuth } from '../../../../selectors/access/auth'
import getOrderByDossierId from '../../../../services/order/getOrderByDossierId'
import { handleError } from '../../../errors/errorManager'
import messageModalActions from '../../../../actions/modals/messageModal'
import getCatalogueModel from '../../../../services/catalogueModel/getCatalogueModel'
import getCarlineCombo from '../../../../services/carline/getCarlineCombo'
import getCatalogueCombo from '../../../../services/catalogue/getCatalogueCombo'
import getColorCombo from '../../../../services/color/getColorCombo'
import getTapestryCombo from '../../../../services/tapestry/getTapestryCombo'

export function * fetchOrder ({ dossierId }) {
  try {
    let carlineCombo = []; let catalogueCombo = []; let colorCombo = []; let tapestryCombo = []
    yield put(showLoader())
    const auth = yield select(getAuth)
    const order = yield call(getOrderByDossierId, dossierId, auth.token)
    if (order.brandId) {
      carlineCombo = yield call(getCarlineCombo, auth.token, order.brandId)
    }
    if (order.carlineId) {
      catalogueCombo = yield call(getCatalogueCombo, auth.token, order.carlineId)
    }
    if (order.modelId && order.catalogueId) {
      colorCombo = yield call(getColorCombo, auth.token, order.catalogueId, order.modelId)
      tapestryCombo = yield call(getTapestryCombo, auth.token, order.catalogueId, order.modelId)
      var catalogueModel = yield call(getCatalogueModel, auth.token, order.catalogueId, order.modelId)
      order.model = catalogueModel.description || ''
      order.catalogueModelId = catalogueModel.catalogueModelId || ''
    }
    yield put(fetchCombosSuccess({ carlineCombo, catalogueCombo, colorCombo, tapestryCombo }))
    yield put(initialize('order', order.orderId ? order : {}))
    yield put(fetchOrderModalSuccess(dossierId))
  } catch (error) {
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchOrder () {
  yield takeEvery(vehicleActionsTypes.FETCH_ORDER, fetchOrder)
}

export function * fetchOrderCatalogueModel ({ catalogueId, modelId, resolve }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const catalogueModel = yield call(getCatalogueModel, auth.token, catalogueId, modelId)
    yield put(change('order', 'model', catalogueModel?.description || ''))
    yield put(change('order', 'catalogueModelId', catalogueModel?.catalogueModelId))
    if (!catalogueModel?.description) {
      throw new Error()
    }
    if (resolve) resolve(catalogueModel)
  } catch (error) {
    const customHandler = function * () {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.VEHICLE_COMPONENT.CATALOGUE_MODEL_NOT_FOUND'))
    }
    yield call(handleError, { error, customHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchOrderFetchCatalogueModel () {
  yield takeEvery(vehicleActionsTypes.FETCH_ORDER_VEHICLE_CATALOGUE_MODEL, fetchOrderCatalogueModel)
}
