import React, { PureComponent } from 'react'
import { Col, Row, Button, Tabs, Tab, Nav, NavItem } from 'react-bootstrap'
import Tab1 from './Tabs/Tab1'
import Tab2 from './Tabs/Tab2'
import TabPane1 from './TabPanes/TabPane1'
import TabPane2 from './TabPanes/TabPane2'
import ModalLogisticsManagement from './Modal/ModalLogisticsManagement'

class logisticsDossier extends PureComponent {
  render () {
    return (
      <Col sm={12} className="management-logistics-dossier-wrapper dossier">
        <Row className="logistics-dossier-header">
          <div className="title-user">
            <i className="ico-car-files" />
            <h2>Expediente EXP 20170125L</h2>
          </div>
          <div className="btn-wrapper">
            <Button id="btn_new_user" className="btn-standard" >
              Documentaci�n
            </Button>
          </div>
        </Row>
        <Row className="band-user">
          <Col sm={3} className="band-icon-item">
            <i className="ico-user" />
            <div className="text-band">
              <span className="first">Nombre del gestor:</span>
              <span className="second">Nombre del gestor</span>
            </div>
          </Col>
          <Col sm={3} className="band-icon-item">
            <i className="ico-user" />
            <div className="text-band">
              <span className="first">Nombre del cliente:</span>
              <span className="second">Nombre del cliente</span>
            </div>
          </Col>
          <Col sm={3} className="band-icon-item">
            <i className="ico-car" />
            <div className="text-band">
              <span className="first">Marca:</span>
              <span className="second">Marca</span>
            </div>
          </Col>
          <Col sm={3} className="band-icon-item">
            <i className="ico-car" />
            <div className="text-band">
              <span className="first">Modelo:</span>
              <span className="second">Modelo</span>
            </div>
          </Col>
        </Row>
        <Row className="tabs-wrapper logistics-management-dossier">
          <Tabs id="logistics-management-dossier-tabs" defaultActiveKey={1}>
            <Tab eventKey={1} title='Expediente'>
              <Tab1/>
            </Tab>
            <Tab eventKey={2} title='Veh�culo'>
              <Tab2/>
            </Tab>
          </Tabs>
        </Row>
        <Row className="row-border">
          <Tab.Container className="vertical-tabs" id="left-tabs" defaultActiveKey={1}>
            <Row className="clearfix">
              <Col sm={4}>
                <Nav bsStyle="pills" stacked >
                  <NavItem eventKey={1}>
                    Origen
                  </NavItem>
                  <NavItem eventKey={2}>
                    Destino
                  </NavItem>
                </Nav>
              </Col>
              <Col sm={8}>
                <Tab.Content animation>
                  <Tab.Pane eventKey={1}>
                    <TabPane1/>
                  </Tab.Pane>
                  <Tab.Pane eventKey={2}>
                    <TabPane2/>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Row>
        <div className="button-wrapper">
          <Button id="btn_new_user" className="btn-standard-white" >
            <i className="ico-check"></i>Servicio realizado
          </Button>
          <Button id="btn_new_user" className="btn-standard-white" >
           Asignar documentos
          </Button>
          <Button id="btn_new_user" className="btn-standard-white btn-grey" >
            <i className="ico-cancel"></i>Cerrar
          </Button>
        </div>
        <ModalLogisticsManagement/>
      </Col>
    )
  }
}

export default logisticsDossier
