import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PowerBiHomeReport from '../../components/report/PowerBiHomeReport'
import { translate } from 'react-polyglot'
export function mapStateToProps (state) {
  return {
    ...state.search,
    powerbiConfig: state.powerbiReport
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(PowerBiHomeReport))
