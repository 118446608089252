import apiFetch from '../apiFetch'

export default function (token, { type, page, pageSize, orderBy }) {
  const queryParamsArray = [
    page ? `page=${page}` : null,
    pageSize ? `pageSize=${pageSize}` : null,
    orderBy ? `orderBy=${orderBy}` : null
  ]

  const queryParams = queryParamsArray.reduce(
    (result, query, idx) => result + (query ? (result ? `&${query}` : `?${query}`) : '')
    , ''
  )

  type = type || 0

  return apiFetch({
    endPoint: `Document/Sign/${type}/GetDocuments/${queryParams}`,
    method: 'GET',
    body: null,
    token: token
  })
}
