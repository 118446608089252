import React, { Component } from 'react'
import { reduxForm, Field } from 'redux-form'
import { Button, Modal, Col, Form, Table } from 'react-bootstrap'
import InputSelect from '../../../commons/form/InputSelect'
import InputCheckBox from '../../../commons/form/InputCheckBox'
import { signTypes } from '../../../../constants/backendIds'
import TrackingContainer from '../../../../containers/tracking/trackingContainer'
import { TrackingActions } from '../../../../constants/tracking/TrackingActions'

class SendDocumentsSignModal extends Component {
  constructor (props) {
    super()
    this.state = {
      openModal: true,
      disabled: {},
      signTypesCombo: [],
      documentToSendSign: [],
      actualDocId: null,
      disabledButton: null,
      signTypes: {},
      idSignTypeMultiple: null,
      disabledComboMultiple: true,
      selectedValue: null,
      selectAllActive: false,
      fieldsConfiguration: {
      },
      selectAll: null,
      selectedOTP: null,
      documentsSelected: []
    }
    this.handleCheckSignChangeIndividual = this.handleCheckSignChangeIndividual.bind(this)

    this.baseTrackingAction = {
      href: window.location.href,
      documentTitle: 'sendDocuments-modal',
      action: TrackingActions.SIGN_REQUEST,
      category: TrackingActions.SIGN_REQUEST
    }
  }

  UNSAFE_componentWillMount () {
    new Promise((resolve, reject) => {
      return this.props.actions.signTypes(resolve, reject)
    }).then((result) => {
      this.setState({ signTypesCombo: result })
    })
  }

  optionsCombo (docId) {
    var comboReturn = []
    var signTypesCombo = this.state.signTypesCombo
    var documentToSendSign = this.state.documentToSendSign

    if (signTypesCombo && documentToSendSign) {
      if (!docId) {
        docId = documentToSendSign[0].documentId
      }

      var filterDocument = documentToSendSign.filter(x => x.documentId === docId)

      filterDocument[0] && filterDocument[0].signEntities && filterDocument[0].signEntities.forEach(function (elem) {
        var idfound = signTypesCombo.filter(x => x.id === parseInt(elem))

        if (Object.keys(idfound).length !== 0 && idfound.constructor !== Object && idfound[0].id !== signTypes.IMAGE) {
          comboReturn.push(idfound[0])
        }
      })
      return comboReturn
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (!this.props.showModal && nextProps.showModal) {
      this.setState({ selectedOTP: null, documentsSelected: [] })
      return new Promise((resolve, reject) => {
        this.props.actions.documentsToSendSignGetAll(this.props.dossierId, resolve, reject)
      }).then((result) => {
        this.state.documentToSendSign = result
        this.setState({ documentToSendSign: result })
        this.restartCombos()
        var signTypesConfigurationToCompare = []
        var entities = []
        var useOTP = null
        // veo que configuraciones de firma tiene el primero y lo guardo
        result && result.length > 0 && result[0].signEntities.forEach(function (elem) {
          signTypesConfigurationToCompare.push(elem)
        })
        if (result && result.length > 0) {
          useOTP = result[0].useOTP
        }

        signTypesConfigurationToCompare = signTypesConfigurationToCompare.sort()

        // veo que configuraciones de firma tienen el resto y si son iguales
        result && result.length > 0 && result.forEach(function (elem) {
          if (isEqual(signTypesConfigurationToCompare, elem.signEntities.sort()) && useOTP === elem.useOTP) {
            entities.push(true)
          } else {
            entities.push(false)
          }
        })
        if (entities.indexOf(false) >= 0) {
          this.setState({ selectAllActive: false })
        } else {
          this.setState({ selectAllActive: true })
        }
      })
    }
  }

  submitDocSign (values) {
    let result = Object.entries(values)
    let objectToSend = []
    this.state.documentToSendSign.map((doc, index) => {
      let item = {}
      let found = result.find(e => e[0] === 's_' + doc.documentId)
      if (found && found[1]) {
        item.docId = doc.documentId
        item.signTypeId = result.find(e => e[0] === 'doc_' + doc.documentId)[1]
        item.signTypeId && objectToSend.push(item)
      }
    })
    if (objectToSend && objectToSend.length > 0) {
      this.props.actions.sendDocumentToSign(objectToSend, this.props.dossierId, this.props.dossierNumber)
      this.props.actions.closeModal()
      this.props.initialize({})
      this.props.trackAction(this.baseTrackingAction)
    }
  }

  handleCheckSignChangeIndividual (value, documentId, OTP) {
    if (value === true) {
      this.setState({ documentsSelected: [...this.state.documentsSelected, documentId] })

      var optionsCombo = this.optionsCombo(documentId)
      if (optionsCombo.length === 1) {
        this.disabledButton(optionsCombo[0].id, documentId)
      }
      let disabled = Object.assign({}, this.state.disabled)
      disabled['active_' + `${documentId}`] = false
      this.state.signTypes[documentId] ? this.setState({ disabled: disabled, actualDocId: documentId, disabledButton: this.state.disabledButton + 1 })
        : this.setState({ disabled: disabled, actualDocId: documentId })

      if (this.state.selectedOTP === null) {
        this.setState({ selectedOTP: OTP })
      }
    } else if (value === false) {
      const array = this.state.documentsSelected
      array.splice(array.findIndex(d => d === documentId), 1)
      this.setState({ documentsSelected: array })

      let disabled = Object.assign({}, this.state.disabled)
      disabled['active_' + `${documentId}`] = true
      let disabledButton = this.state.disabledButton !== null && this.state.disabledButton > 0 && this.state.signTypes[documentId] ? this.state.disabledButton - 1 : this.state.disabledButton
      if (this.state.selectAll) {
        this.setState({ selectAll: false, disabled: disabled, actualDocId: documentId, disabledButton: disabledButton })
        this.props.change('selectAll', value)
      } else {
        this.setState({ disabled: disabled, actualDocId: documentId, disabledButton: disabledButton })
      }
      if (this.state.documentsSelected.length === 0) {
        this.setState({ selectedOTP: null })
      }
    }
  }

  restartCombos () {
    var activated = []
    let activatedAux = {}
    let activatedModified = {}
    this.state.documentToSendSign.map((doc, idx) => {
      activated.push({ ['active_' + `${doc.documentId}`]: true })
    })
    activated.forEach(function (doc) {
      activatedModified = Object.assign(activatedAux, doc)
      activatedAux = activatedModified
    })
    this.state.disabledButton = null
    this.setState({ disabled: { ...this.state.disabled, ...activatedModified } })
  }

  handleSelectAll (value) {
    let selectAll = {}
    let disabled = Object.assign({}, this.state.disabled)
    let signTypes = Object.assign({}, this.state.signTypes)
    let counter = 0
    if (value === true) {
      this.state.documentToSendSign && this.state.documentToSendSign.forEach(function (doc) {
        selectAll['s_' + `${doc.documentId}`] = true
        signTypes[`${doc.documentId}`] && counter++
      })
      selectAll.selectAll = true
      for (let item in this.state.disabled) {
        disabled[`${item}`] = false
      }
    } else {
      this.state.documentToSendSign && this.state.documentToSendSign.forEach(function (doc) {
        selectAll['s_' + `${doc.documentId}`] = false
      })
      selectAll.selectAll = false
      for (let item in this.state.disabled) {
        disabled[`${item}`] = true
      }
    }
    this.setState({ disabled: disabled, selectAll: value, disabledButton: counter })
    this.props.initialize(selectAll)
  }

  disabledButton (value, docId) {
    let signTypes = Object.assign({}, this.state.signTypes)
    signTypes[docId] = value
    this.props.change('doc_' + docId, value)
    if (value !== null) {
      this.setState({ signTypes: signTypes, disabledButton: this.state.disabledButton + 1 })
    } else {
      this.setState({ signTypes: signTypes, disabledButton: this.state.disabledButton - 1 })
    }
  }

  signTypeMultiple (value) {
    let signTypes = {}
    let counter = 0
    this.state.documentToSendSign.map((doc, index) => {
      signTypes[`${doc.documentId}`] = value
      this.props.change('doc_' + doc.documentId, value)
      value && this.state.disabled['active_' + `${doc.documentId}`] === false && counter++
    })
    this.setState({ disabledButton: counter, signTypes: signTypes })
  }

  render () {
    const { t, showModal, handleSubmit, actions: { closeModal } } = this.props
    const tKey = 'DOSSIER_COMPONENTS.SEND_DOCUMENTS_SIGN_MODAL.'
    return (
      <Modal show={showModal} onHide={() => closeModal(this.props.initialize({}), this.restartCombos())}>
        <Form autoComplete="off" onSubmit={handleSubmit(this.submitDocSign.bind(this))}>
          <Modal.Header closeButton>
            <Modal.Title>{t(`${tKey}TITLE`)}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table hover responsive className="simple-table dashboard-tasks-table" id="related-dossiers-table">
              <thead>
                <tr>
                  <th>
                    {t(`${tKey}DOCUMENT`)}
                  </th>
                  <th>
                    {t(`${tKey}SIGN_TYPE`)}
                  </th>
                </tr>
              </thead>
              <tbody>
                {
                  this.state.documentToSendSign && this.state.documentToSendSign.map((doc, idx) => {
                    return (
                      <tr key={idx}>
                        <td className="columnSignModal">
                          <Field
                            key={idx}
                            id = {'s_' + doc.documentId}
                            name= {'s_' + doc.documentId}
                            placeholder={doc.documentTypeUse.documentTypeUseLocales[0].name}
                            controlLabel={doc.documentTypeUse.documentTypeUseLocales[0].name}
                            component={InputCheckBox}
                            onInputChange={(value) => this.handleCheckSignChangeIndividual(value, doc.documentId, doc.useOTP)}
                            inline
                            disabled= {this.state.selectedOTP !== null && doc.useOTP !== this.state.selectedOTP}
                          />
                        </td>
                        <td>
                          <Field
                            id = {'doc_' + doc.documentId }
                            name= {'doc_' + doc.documentId }
                            placeholder={t(`${tKey}PLACEHOLDER`)}
                            component={InputSelect}
                            multi={false}
                            valueKey="id"
                            labelKey="value"
                            childrenKey="children"
                            options={this.optionsCombo(doc.documentId)}
                            value={'joel'}
                            colSm={12}
                            disabled = {(this.optionsCombo(doc.documentId).length === 1) || (this.state.disabled['active_' + doc.documentId] || this.state.selectAll)}
                            onInputChange={(value) => this.disabledButton(value, doc.documentId)}
                            selectedMultipleSign={this.state.selectedValue}
                            defaultValueSign={this.optionsCombo(doc.documentId).length === 1 ? this.optionsCombo(doc.documentId)[0] : null}
                          />
                        </td>
                      </tr>
                    )
                  })
                }
                {(this.state.documentToSendSign === null || this.state.documentToSendSign === undefined || this.state.documentToSendSign.length === 0) &&
                    <tr>
                      <td colSpan={15}><h3>{t(`${tKey}NO_RESULTS`)}</h3></td>
                    </tr>}
              </tbody>
            </Table>
            {this.state.documentToSendSign && this.state.documentToSendSign.length > 1 && this.state.selectAllActive &&
            <Field
              id={'docm_'}
              name={'docm_'}
              placeholder={t(`${tKey}PLACEHOLDER`)}
              component={InputSelect}
              multi={false}
              valueKey="id"
              labelKey="value"
              childrenKey="children"
              options={this.optionsCombo()}
              colSm={6}
              onInputChange={(value) => this.signTypeMultiple(value)}
              disabled={!this.state.selectAll}
            />}
            <br></br>
            {this.state.documentToSendSign && this.state.documentToSendSign.length > 1 && this.state.selectAllActive &&
                        <Field
                          colSm={12}
                          id = {'selectAll'}
                          name= {'selectAll'}
                          placeholder={t(`${tKey}SELECT_ALL`)}
                          controlLabel={t(`${tKey}SELECT_ALL`)}
                          component={InputCheckBox}
                          onInputChange={(value) => this.handleSelectAll(value)}
                          inline
                        />
            }
            {<br></br>}
          </Modal.Body>
          <Modal.Footer>
            <Col sm={6} className="btn-wrapper">
              <Button id="btn_save" className="btn-standard" type="submit" disabled={this.state.disabledButton <= 0 || this.state.disabledButton === null}>{t(`${tKey}OK_BTN`)}</Button>
            </Col>
          </Modal.Footer>
        </Form>
      </Modal>
    )
  }
}

const validate = (values, props) => {
  const error = {}
  if (!values.selectAll) {
    for (const doc in values) {
      if (doc.startsWith('s')) {
        if (values[doc]) {
          if ((!values.docm_) && (values['doc_' + doc.substring(2)] === undefined || values['doc_' + doc.substring(2)] === null)) {
            error['doc_' + doc.substring(2)] = props.t('GLOBAL.FORMS.REQUIRED')
          }
        }
      }
    }
  } else {
    if ((!values.docm_)) {
      error.docm_ = props.t('GLOBAL.FORMS.REQUIRED')
    }
  }

  return error
}

const isEqual = (a, b) => {
  // if length is not equal
  if (a.length !== b.length) { return false } else {
    // comapring each element of array
    for (var i = 0; i < a.length; i++) {
      if (a[i] !== b[i]) { return false }
    }
    return true
  }
}

var decoratedComponent = TrackingContainer(SendDocumentsSignModal, false)

export default reduxForm({
  form: 'SendDocumentsSignModal',
  validate
})(decoratedComponent)
