export default (values, { t, passwordPolicy, emailAddress, userName, userSurnames, _error, error }) => {
  const errors = {}
  if (!values.pwdLoginRepeat) errors.pwdLoginRepeat = t('GLOBAL.FORMS.REQUIRED')
  if (!values.newPassword) errors.newPassword = t('GLOBAL.FORMS.REQUIRED')
  if (values.newPassword !== values.pwdLoginRepeat) errors.pwdLoginRepeat = t('RESET_PASSWORD.NEW_PASSWORD_EQUAL')

  if (passwordHasMinCriteria(values.newPassword, passwordPolicy.minNumberOfCriteria)) errors.newPassword = t('RESET_PASSWORD.MUST_CONTAIN_X_POLICIES').replace('##NUMBER##', passwordPolicy.minNumberOfCriteria)

  if (values.newPassword && values.newPassword.length < passwordPolicy.minNumberOfCharactersInPassword) errors.newPassword = t('RESET_PASSWORD.MUST_CONTAIN_X_CHARACTERS').replace('##NUMBER##', passwordPolicy.minNumberOfCharactersInPassword)
  if (emailAddress) {
    if (!isValidPassword(emailAddress, values.newPassword, passwordPolicy.maxNumberOfOtherAttributeCharsContainedInPassword) ||
        !isValidPassword(userName, values.newPassword, passwordPolicy.maxNumberOfOtherAttributeCharsContainedInPassword) ||
        !isValidPassword(userSurnames, values.newPassword, passwordPolicy.maxNumberOfOtherAttributeCharsContainedInPassword)) {
      errors.newPassword = t('RESET_PASSWORD.PASSWORD_CONTAINS_NAME').replace('##NUMBER##', passwordPolicy.maxNumberOfOtherAttributeCharsContainedInPassword)
    }
  }
  return errors
}

function isValidPassword (stringToCompare = '', password = '', nCharsToCheck = 0) {
  for (let i = 0; i <= stringToCompare.length - nCharsToCheck; i++) {
    if (password.includes(stringToCompare.substr(i, nCharsToCheck))) return false
  }
  return true
}

function passwordHasMinCriteria (newPassword = '', minNumberOfCriteria = 0) {
  let differentCharsValidated = 0
  if (/[A-Z]/.test(newPassword)) differentCharsValidated++
  if (/[a-z]/.test(newPassword)) differentCharsValidated++
  if (/[0-9]/.test(newPassword)) differentCharsValidated++
  if (/[\u0021-\u002b\u003c-\u0040]/.test(newPassword)) differentCharsValidated++
  return differentCharsValidated < minNumberOfCriteria
}
