export const dossierSubType = {
  compra_vn: 'Compra VN',
  compra_vo: 'Compra VO',
  venta_vn: 'Venta VN',
  venta_vo: 'Venta VO',
  registration_delivery: 'Matriculaciones y entrega'
}

export const dossierSubTypeId = {
  venta_vn: 1,
  venta_vo: 2,
  compra_vn: 3,
  compra_vo: 4,
  campanya: 5,
  campanya_ayuda_especial: 6,
  tramite: 7,
  damageClaim: 8,
  registration_delivery: 11,
  service: 12,
  servicePAP: 13,
  servicePreparationDelivery: 14,
  model_agency: 15,
  stock_vo: 16
}
