import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { openEditPaymentMethodModal, openAddPaymentMethodModal, fetchPaymentMethodList, deletePaymentMethod, recoverPaymentMethod } from '../../../actions/masters/masters'
import PaymentMethod from '../../../components/masters/paymentMethod/PaymentMethodMaster'

export function mapStateToProps (state) {
  return {
    ...state.paymentMethod,
    combos: {
      UOTreeCombo: state.combos.UOTreeCombo
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      openEditPaymentMethodModal,
      openAddPaymentMethodModal,
      fetchPaymentMethodList,
      deletePaymentMethod,
      recoverPaymentMethod
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(PaymentMethod))
