import React, { PureComponent } from 'react'
import { Modal, Button, Col, Row, Form } from 'react-bootstrap'
import InputSelect from '../../../commons/form/InputSelect'
import InputText from '../../../commons/form/InputText'
import InputNatural from '../../../commons/form/InputNatural'
import InputMoney from '../../../commons/form/InputMoney'
import InputDatePicker from '../../../commons/form/InputDatePicker'
import InputPercentage from '../../../commons/form/InputPercentage'
import { reduxForm, Field } from 'redux-form'
import { validateDate, validateReFinancingQuadis } from '../../../../util/validationFunctions'
import { compareObjects } from '../../../../util/utils'
import { getFieldsPermisions } from '../../../../util/permisionFunctions'
import { permissions, clientInformation } from '../../../../constants/backendIds'
import { financingRadioOptions } from '../../../../constants/dossier/sale/financingComponent'
import InputRadio from '../../../commons/form/InputRadio'
import setting from '../../../../setting'

class ReFinanceModal extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      open: false,
      fieldsConfiguration: {
        companyId: null,
        typeId: null,
        amount: null,
        instalments: null,
        firstInstalmentDate: null,
        km: null,
        financingStatusId: null,
        tin: null,
        bonusAmount: null,
        extendedFinancing: null,
        entry: null,
        residualValue: null,
        openingCommission: null,
        monthlyFee: null,
        apr: null,
        lastDNICIF: null,
        renovation: null,
        maintenanceIncluded: null,
        insuranceIncluded: null
      },
      dataLoaded: false,
      lastDNICIFDisabled: true,
      loaded: false,
      typeProductChange: false,
      displayRepurchaseDate: false,
      typeId: null
    }
  }

  initializePermisions (props) {
    const fieldKeys = Object.keys(this.state.fieldsConfiguration)
    const newState = getFieldsPermisions(props.componentConfiguration.fieldsConfiguration, fieldKeys, props.componentConfiguration.permission)
    this.setState({ ...this.state, ...newState })
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (prevProps.componentConfiguration && this.props.componentConfiguration !== null) {
      if (!compareObjects(prevProps.componentConfiguration || {}, this.props.componentConfiguration, true)) {
        this.initializePermisions(this.props)
      }
    }

    if (prevProps.showModal !== this.props.showModal) {
      this.props.change('dossierReFinancingComponent', this.props.dossierReFinancingComponent)
      if (this.props.dossierReFinancingComponent && this.props.dossierReFinancingComponent.companyId) {
        this.props.actions.fetchFinancingType(this.props.dossierReFinancingComponent.companyId, 'refinanceModal')
      }
      if ((this.props.dossierReFinancingComponent.typeId !== prevProps.dossierReFinancingComponent.typeId) ||
          this.props.financingTypeProduct == null && this.props.dossierReFinancingComponent.typeId) {
        this.props.actions.getFinancingTypeProductByFinancingType(this.props.dossierReFinancingComponent.typeId)
      }
    }

    if (this.props.dossierReFinancingComponent && !compareObjects(prevProps.dossierReFinancingComponent || {}, this.props.dossierReFinancingComponent, true)) {
      this.props.change('dossierReFinancingComponent', this.props.dossierReFinancingComponent)
    }

    if (this.props.dossierReFinancingComponent && !compareObjects(this.dossierReFinancingComponent || {}, this.props.dossierReFinancingComponent, true)) {
      this.setState({ typeId: this.props.dossierReFinancingComponent.typeId || false })
    }

    if (this.props.financingTypeProduct && (!compareObjects(prevProps.financingTypeProduct || {}, this.props.financingTypeProduct, true) || this.state.typeProductChange)) {
      if (this.state.typeProductChange) {
        this.props.change('dossierReFinancingComponent.financingTypeProductId', this.props.financingTypeProduct.financingTypeProductId)
        this.props.change('dossierReFinancingComponent.financingTypeProductIdName', this.props.financingTypeProduct.name)
        this.props.change('financingTypeProduct', this.props.financingTypeProduct)
      }
    }
    if (prevProps.financingTypeProduct !== null && this.props.financingTypeProduct == null) {
      this.props.change('financingTypeProduct', null)
      this.props.change('dossierReFinancingComponent.financingTypeProductId', null)
      this.props.change('dossierReFinancingComponent.financingTypeProductIdName', null)
    }

    return null
  }

  componentDidMount () {
    if (this.props.componentConfiguration && this.props.componentConfiguration !== null) {
      this.initializePermisions(this.props)
    }

    if (this.props && this.props.dossierReFinancingComponent && this.props.dossierReFinancingComponent.companyId) {
      this.props.actions.fetchFinancingType(this.props.dossierReFinancingComponent.companyId, 'refinanceModal')
    }
  }

  componentDidUpdate () {
    if (this.props.dossierReFinancingComponent) {
      if (Object.keys(this.props.dossierReFinancingComponent).length > 1) {
        this.setState({ dataLoaded: true })
        if (this.props.dossierReFinancingComponent.renovation === 'true') {
          this.setState({ lastDNICIFDisabled: false })
        }
      }
    }
  }

  handleRenovationChange (value) {
    this.setState({ lastDNICIFDisabled: value !== 'true' })
    if (value !== 'true') {
      this.props.change('dossierReFinancingComponent.lastDNICIF', undefined)
    }
  }

  removeFinance () {
    new Promise((resolve, reject) => {
      this.props.actions.deleteRefinancing(this.props.dossierId, resolve)
    }).then((deleteFinance) => {
      if (deleteFinance) {
        this.props.change('dossierReFinancingComponent', null)
        this.props.closeModal()
      }
    })
  }

  cleanFinance () {
    this.props.change('dossierReFinancingComponent', null)
  }

  dateValidation (value, allValues, props) {
    const result = validateDate(value, props)
    return result === Object(result) ? undefined : result
  }

  changeFinancingCompany (val) {
    this.props.change('dossierReFinancingComponent.typeId', null)
    this.props.actions.fetchFinancingType(val, 'refinanceModal')
  }

  changeFinancingProdut (val) {
    this.props.actions.openCommonModal('actionFinanceChange', () => {
      this.props.change('dossierReFinancingComponent.typeId', val); this.setState({ typeId: val }); this.props.actions.getFinancingTypeProductByFinancingType(val); this.setState({ typeProductChange: true })
    }, () => { this.props.change('dossierReFinancingComponent.typeId', this.state.typeId); this.setState({ typeId: this.state.typeId }); this.props.actions.getFinancingTypeProductByFinancingType(this.state.typeId) }, null, null, null, null)
  }

  saveValues (values) {
    this.props.actions.saveRefinancing(values.dossierReFinancingComponent, this.props.dossierId)
    this.props.closeModal()
  }

  render () {
    const { showModal, closeModal, t, handleSubmit, combos: { financialInstitutionCombo, financingTypeComboReFinanceModal, financingStatusCombo }, dossierReFinancingComponent, financingTypeProduct, redate } = this.props
    const tKey = 'DOSSIER_COMPONENTS.FINANCE.'
    const showRemoveButton = dossierReFinancingComponent && dossierReFinancingComponent.reActivatedFinancingId && Object.keys(dossierReFinancingComponent).length > 1
    return (
      <Modal className="add-user-modal" show={showModal} onHide={closeModal}>
        <Form autoComplete="off" onSubmit={handleSubmit(this.saveValues.bind(this))}>
          <Modal.Header closeButton>
            <Modal.Title> <i className="ico-finance" />{t(`${tKey}TITLE_PANEL_REFINANCE`)}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="finance-panel dossier-panel">
              { setting.clientFieldValidation === clientInformation.QUADIS && showRemoveButton && <Row>
                <Col className="col-right">
                  <Button className="first btn-remove-finance" onClick={() => this.removeFinance()}>
                    {t(`${tKey}DELETE_RE_FINANCE`)}
                  </Button>
                </Col>
              </Row>}
              { setting.clientFieldValidation === clientInformation.QUADIS && !showRemoveButton && <Row>
                <Col className="col-right">
                  <Button className="first btn-remove-finance" onClick={() => this.cleanFinance()}>
                    {t(`${tKey}CLEAN_RE_FINANCE`)}
                  </Button>
                </Col>
              </Row>}
              <Row>
                {this.state.fieldsConfiguration.companyId && this.state.fieldsConfiguration.companyId.permission !== permissions.hidden && (<Field
                  colSm={3}
                  id="dossierReFinancingComponent.companyId"
                  name="dossierReFinancingComponent.companyId"
                  controlLabel={t(`${tKey}COMPANY`)}
                  placeholder={t(`${tKey}COMPANY`)}
                  component={InputSelect}
                  options={financialInstitutionCombo}
                  valueKey="id"
                  labelKey="value"
                  disabled={this.state.fieldsConfiguration.companyId.permission !== permissions.editable}
                  onChange={(val) => this.changeFinancingCompany(val)}
                />)}
                { this.state.fieldsConfiguration.typeId && this.state.fieldsConfiguration.typeId.permission !== permissions.hidden && (<Field
                  colSm={3}
                  id="typeId"
                  name="dossierReFinancingComponent.typeId"
                  controlLabel={t(`${tKey}FINANCE_TYPE`)}
                  placeholder={t(`${tKey}FINANCE_TYPE`)}
                  component={InputSelect}
                  options={financingTypeComboReFinanceModal}
                  valueKey="id"
                  labelKey="value"
                  disabled={ this.state.fieldsConfiguration.typeId.permission !== permissions.editable}
                  onChange={(val) => this.changeFinancingProdut(val)}
                />)}
                {this.state.fieldsConfiguration.extendedFinancing && this.state.fieldsConfiguration.extendedFinancing.permission !== permissions.hidden && (
                  <Field
                    id="dossierReFinancingComponent.financingTypeProduct"
                    key="financingTypeProduct"
                    name="dossierReFinancingComponent.financingTypeProductIdName"
                    colSm={3}
                    controlLabel={t(`${tKey}FINANCING_TYPE_PRODUCT`)}
                    placeholder={t(`${tKey}FINANCING_TYPE_PRODUCT`)}
                    component={InputText}
                    disabled={true}
                  />
                )}
                {this.state.fieldsConfiguration.amount && this.state.fieldsConfiguration.amount.permission !== permissions.hidden && (<Field
                  id="amount"
                  name="dossierReFinancingComponent.amount"
                  colSm={3}
                  controlLabel={t(`${tKey}AMOUNT`)}
                  placeholder={t(`${tKey}AMOUNT`)}
                  component={InputMoney}
                  disabled={this.state.fieldsConfiguration.amount.permission !== permissions.editable}
                />)}
                {this.state.fieldsConfiguration.instalments && this.state.fieldsConfiguration.instalments.permission !== permissions.hidden && (<Field
                  id="instalments"
                  name="dossierReFinancingComponent.instalments"
                  colSm={3}
                  controlLabel={t(`${tKey}INSTALMENTS`)}
                  placeholder={t(`${tKey}INSTALMENTS`)}
                  component={InputNatural}
                  disabled={this.state.fieldsConfiguration.instalments.permission !== permissions.editable}
                />)}
                {this.state.fieldsConfiguration.firstInstalmentDate && this.state.fieldsConfiguration.firstInstalmentDate.permission !== permissions.hidden && (<Field
                  id="firstInstalmentDate"
                  name="dossierReFinancingComponent.firstInstalmentDate"
                  colSm={3}
                  controlLabel={t(`${tKey}FIRST_INSTALMENT_DATE`)}
                  component={InputDatePicker}
                  validate={[this.dateValidation]}
                  disabled={this.state.fieldsConfiguration.firstInstalmentDate.permission !== permissions.editable}
                />)}
                {this.state.fieldsConfiguration.km && this.state.fieldsConfiguration.km.permission !== permissions.hidden && (<Field
                  id="km"
                  name="dossierReFinancingComponent.km"
                  colSm={3}
                  controlLabel={t(`${tKey}KM`)}
                  placeholder={t(`${tKey}KM`)}
                  component={InputNatural}
                  disabled={this.state.fieldsConfiguration.km.permission !== permissions.editable}
                  textAlign="left"
                />)}
                {this.state.fieldsConfiguration.tin && this.state.fieldsConfiguration.tin.permission !== permissions.hidden && (<Field
                  id="tin"
                  name="dossierReFinancingComponent.tin"
                  colSm={3}
                  controlLabel={t(`${tKey}TIN`)}
                  placeholder={t(`${tKey}TIN`)}
                  component={InputPercentage}
                  disabled={this.state.fieldsConfiguration.tin.permission !== permissions.editable}
                  valueKey="id"
                  labelKey="value"
                />)}
                {this.state.fieldsConfiguration.financingStatusId && this.state.fieldsConfiguration.financingStatusId.permission !== permissions.hidden && (<Field
                  id="financingStatusId"
                  name="dossierReFinancingComponent.financingStatusId"
                  colSm={3}
                  controlLabel={t(`${tKey}FINANCING_STATUS`)}
                  placeholder={t(`${tKey}FINANCING_STATUS`)}
                  component={InputSelect}
                  disabled={this.state.fieldsConfiguration.financingStatusId.permission !== permissions.editable}
                  options={financingStatusCombo}
                  valueKey="id"
                  labelKey="value"
                />)}
                {this.state.fieldsConfiguration.bonusAmount && this.state.fieldsConfiguration.bonusAmount.permission !== permissions.hidden && (<Field
                  id="bonusAmount"
                  name="dossierReFinancingComponent.bonusAmount"
                  colSm={3}
                  controlLabel={t(`${tKey}BONUS_AMOUNT`)}
                  placeholder={t(`${tKey}BONUS_AMOUNT`)}
                  component={InputMoney}
                  disabled={this.state.fieldsConfiguration.bonusAmount.permission !== permissions.editable}
                  valueKey="id"
                  labelKey="value"
                />)}
                {this.state.fieldsConfiguration.extendedFinancing && this.state.fieldsConfiguration.extendedFinancing.permission !== permissions.hidden && ([<Field
                  id="entry"
                  key="entry"
                  name="dossierReFinancingComponent.entry"
                  colSm={3}
                  controlLabel={t(`${tKey}ENTRY`)}
                  placeholder={t(`${tKey}ENTRY`)}
                  component={InputMoney}
                  disabled={this.state.fieldsConfiguration.extendedFinancing.permission !== permissions.editable}
                />,
                <Field
                  id="residualValue"
                  key="residualValue"
                  name="dossierReFinancingComponent.residualValue"
                  colSm={3}
                  controlLabel={t(`${tKey}RESIDUAL_VALUE`)}
                  placeholder={t(`${tKey}RESIDUAL_VALUE`)}
                  component={InputMoney}
                  disabled={this.state.fieldsConfiguration.extendedFinancing && this.state.fieldsConfiguration.extendedFinancing.permission !== permissions.editable}
                />,
                <Field
                  id="openingCommission"
                  key="openingCommission"
                  name="dossierReFinancingComponent.openingCommission"
                  colSm={3}
                  controlLabel={t(`${tKey}OPENING_COMMISSION`)}
                  placeholder={t(`${tKey}OPENING_COMMISSION`)}
                  component={InputMoney}
                  disabled={this.state.fieldsConfiguration.extendedFinancing && this.state.fieldsConfiguration.extendedFinancing.permission !== permissions.editable}
                />,
                <Field
                  id="monthlyFee"
                  key="monthlyFee"
                  name="dossierReFinancingComponent.monthlyFee"
                  colSm={3}
                  controlLabel={t(`${tKey}MONTHLY_FEE`)}
                  placeholder={t(`${tKey}MONTHLY_FEE`)}
                  component={InputMoney}
                  disabled={this.state.fieldsConfiguration.extendedFinancing && this.state.fieldsConfiguration.extendedFinancing.permission !== permissions.editable}
                />,
                <Field
                  id="apr"
                  key="apr"
                  name="dossierReFinancingComponent.apr"
                  colSm={3}
                  controlLabel={t(`${tKey}APR`)}
                  placeholder={t(`${tKey}APR`)}
                  component={InputPercentage}
                  disabled={this.state.fieldsConfiguration.extendedFinancing && this.state.fieldsConfiguration.extendedFinancing.permission !== permissions.editable}
                />,
                <Field
                  id="lastDNICIF"
                  key="lastDNICIF"
                  name="dossierReFinancingComponent.lastDNICIF"
                  colSm={3}
                  controlLabel={t(`${tKey}LAST_DNI_CIF`)}
                  placeholder={t(`${tKey}LAST_DNI_CIF`)}
                  component={InputText}
                  disabled={this.state.lastDNICIFDisabled || (this.state.fieldsConfiguration.extendedFinancing && this.state.fieldsConfiguration.extendedFinancing.permission !== permissions.editable)}
                />,
                financingTypeProduct != null && this.state.displayRepurchaseDate && (<Field
                  key="redate"
                  name="redate"
                  colSm={3}
                  controlLabel={t(`${tKey}EXPECTED_REPURCHASE_DATE`)}
                  component={InputDatePicker}
                  disabled={true}
                  input = {{ value: redate || null }}
                />),
                <div className="clearfix" key="div"/>,
                <Field
                  id="renovation"
                  key="renovation"
                  name="dossierReFinancingComponent.renovation"
                  groupName="dossierReFinancingComponent.renovation"
                  colSm={3}
                  inline
                  placeholder={t(`${tKey}RENOVATION`)}
                  component={InputRadio}
                  disabled={this.state.fieldsConfiguration.extendedFinancing && this.state.fieldsConfiguration.extendedFinancing.permission !== permissions.editable}
                  onChange={(value) => this.handleRenovationChange(value)}
                  items={financingRadioOptions}
                  t={t}
                />,
                <Field
                  id="maintenanceIncluded"
                  key="maintenanceIncluded"
                  name="dossierReFinancingComponent.maintenanceIncluded"
                  groupName="dossierReFinancingComponent.maintenanceIncluded"
                  colSm={3}
                  inline
                  placeholder={t(`${tKey}MAINTENANCE_INCLUDED`)}
                  component={InputRadio}
                  disabled={this.state.fieldsConfiguration.extendedFinancing && this.state.fieldsConfiguration.extendedFinancing.permission !== permissions.editable}
                  items={financingRadioOptions}
                  t={t}
                />,
                <Field
                  id="insuranceIncluded"
                  key="insuranceIncluded"
                  name="dossierReFinancingComponent.insuranceIncluded"
                  groupName="dossierReFinancingComponent.insuranceIncluded"
                  colSm={3}
                  inline
                  placeholder={t(`${tKey}INSURANCE_INCLUDED`)}
                  component={InputRadio}
                  disabled={this.state.fieldsConfiguration.extendedFinancing && this.state.fieldsConfiguration.extendedFinancing.permission !== permissions.editable}
                  items={financingRadioOptions}
                  t={t}
                />
                ])}
              </Row>

            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Col sm={6} className="btn-wrapper">
              <Button id="btn_save" className="btn-standard" type="submit">{t(`${tKey}SAVE`)}</Button>
            </Col>
          </Modal.Footer>
        </Form>
      </Modal>
    )
  }
}

const validate = (values, props) => {
  const error = {}

  if (values && values.dossierReFinancingComponent) {
    error.dossierReFinancingComponent = validateReFinancingQuadis(values.dossierReFinancingComponent, props)
  }

  return error
}

export default reduxForm({
  form: 'reFinanceModal',
  validate
})(ReFinanceModal)
