import dossierActionTypes from '../../../constants/actions/dossier/dossier'
import commentsActionTypes from '../../../constants/actions/dossier/common/comments'
import auditInfoTypes from '../../../constants/actions/audit/auditInfo'

export function initialState () {
  return {
    dossierId: null,
    dossierSubTypeId: null,
    dossierParentId: null,
    parentIsHistorical: null,
    organizedUnitId: null,
    isMarked: false,
    header: {},
    checkListHeader: [],
    viewConfiguration: {},
    comments: [],
    dossierRelatedDossiers: [],
    auditInfo: [],
    lastAccessDate: null,
    dossierStockComponent: { },
    vehicleComponent: {},
    dossierRatingComponent: {},
    unreadComments: null,
    reason: null,
    comment: null,
    cancelCombo: [],
    fastline: null,
    reception: null,
    priceChange: null,
    dossierCleaningComponent: null,
    dossierChecklistComponent: null,
    reconditioning: null,
    dossierBudget: null,
    showDossierBudgetRatingModal: false,
    dossierBudgetRatingId: null,
    crane: null,
    dossierPhotosComponent: null,
    bookingAndDelivery: null
  }
}

function fetchAuditInfoSucces (state, { auditInfo }) {
  return {
    ...state,
    auditInfo
  }
}
function fetchCommentsSuccess (state, { comments }) {
  return {
    ...state,
    comments
  }
}

function fetchDossierRelatedDossiersSuccess (state, { dossierRelatedDossiers }) {
  return {
    ...state,
    dossierRelatedDossiers
  }
}

function fetchStockDossierSuccess (state, { dossier, checkListHeader }) {
  return {
    ...state,
    dossierId: dossier.dossierId || state.dossierId,
    dossierSubTypeId: dossier.dossierSubTypeId || state.dossierSubTypeId,
    dossierParentId: dossier.dossierParentId !== undefined ? dossier.dossierParentId : state.dossierParentId,
    parentIsHistorical: dossier.parentIsHistorical || state.parentIsHistorical,
    isMarked: (dossier.isMarked ? dossier.isMarked : state.isMarked),
    organizedUnitId: dossier.organizedUnitId || state.organizedUnitId,
    header: dossier.dossierHeaderComponent || state.header,
    checkListHeader: checkListHeader || state.checkListHeader,
    viewConfiguration: dossier.viewConfiguration || state.viewConfiguration,
    comments: dossier.dossierCompleteComment || state.comments,
    dossierRelatedDossiers: dossier.dossierRelatedDossiers || state.dossierRelatedDossiers,
    auditInfo: dossier.auditInfo || state.auditInfo,
    lastAccessDate: dossier.lastAccessDate || state.lastAccessDate,
    vehicleComponent: dossier?.dossierVehicleComponent || state.vehicleComponent,
    dossierStockComponent: dossier?.dossierStockComponent || state.dossierStockComponent,
    unreadComments: dossier.unreadComments >= 0 ? dossier.unreadComments : state.unreadComments,
    reason: dossier.reason || state.reason,
    comment: dossier.comment || state.comment,
    cancelCombo: dossier.cancelCombo || state.cancelCombo,
    defaultDestination: dossier.defaultDestination || state.defaultDestination,
    reception: dossier.dossierReceptionComponent || state.reception,
    priceChange: dossier.dossierPriceChangeComponent || state.priceChange,
    dossierCleaningComponent: dossier.dossierCleaningComponent || state.dossierCleaningComponent,
    dossierChecklistComponent: dossier.dossierChecklistComponent || state.dossierChecklistComponent,
    dossierRatingComponent: dossier.dossierRatingComponent || state.dossierRatingComponent,
    reconditioning: dossier.dossierReconditioningComponent || state.reconditioning,
    dossierBudget: dossier.dossierBudget || state.dossierBudget,
    crane: dossier.dossierCraneComponent || state.crane,
    dossierPhotosComponent: dossier.dossierPhotosComponent || state.dossierPhotosComponent,
    bookingAndDelivery: dossier.dossierBookingAndDeliveryComponent || state.bookingAndDelivery
  }
}

function fetchStockDefaultDestination (state, { value }) {
  return {
    ...state,
    defaultDestination: value
  }
}

function deleteDossierRatingSuccess (state, { dossierRatingComponent }) {
  return {
    ...state,
    dossierRatingComponent: dossierRatingComponent.dossierRatingComponent
  }
}

function addDossierRatingSuccess (state, { dossierRatingComponent }) {
  return {
    ...state,
    dossierRatingComponent: dossierRatingComponent.dossierRatingComponent
  }
}

function updateDossierBudgetRatingModal (state, { dossierBudgetRatingId }) {
  return {
    ...state,
    showDossierBudgetRatingModal: !state.showDossierBudgetRatingModal,
    dossierBudgetRatingId
  }
}

function getDossierPhotoSuccess (state, { dossierPhotosComponent }) {
  return {
    ...state,
    dossierPhotosComponent
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case auditInfoTypes.FETCH_AUDIT_INFO_SUCCESS:
      return fetchAuditInfoSucces(state, action)
    case dossierActionTypes.FETCH_DOSSIER_RELATED_DOSSIERS_SUCCESS:
      return fetchDossierRelatedDossiersSuccess(state, action)
    case commentsActionTypes.FETCH_STOCK_COMMENTS_SUCCESS:
      return fetchCommentsSuccess(state, action)
    case dossierActionTypes.FETCH_STOCK_DOSSIER_SUCCESS:
      return fetchStockDossierSuccess(state, action)
    case dossierActionTypes.DELETE_DOSSIER_RATING_SUCCESS:
      return deleteDossierRatingSuccess(state, action)
    case dossierActionTypes.ADD_DOSSIER_RATING_SUCCESS:
      return addDossierRatingSuccess(state, action)
    case dossierActionTypes.GET_DOSSIER_PHOTO_SUCCESS:
      return getDossierPhotoSuccess(state, action)
    case dossierActionTypes.RESTART_STOCK_DOSSIER_STATE:
      return initialState()
    case dossierActionTypes.FETCH_STOCK_DEFAULT_DESTINATION:
      return fetchStockDefaultDestination(state, action)
    case dossierActionTypes.UPDATE_DOSSIER_BUDGET_RATING_MODAL:
      return updateDossierBudgetRatingModal(state, action)
    default:
      return state
  }
}
