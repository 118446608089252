import React, { PureComponent } from 'react'
import { Popover, /* OverlayTrigger, */ Overlay, Button, Glyphicon /* Tooltip */ } from 'react-bootstrap'
import { entityTypesId } from '../../../constants/backendIds.js'
import PopoverItem from '../../containers/search/PopoverItemPage.js'

class EntityPopover extends PureComponent {
  constructor () {
    super()
    this.state = {
      showPopover: false,
      target: this
    }
  }

  handleClick (e) {
    e.stopPropagation()
    this.setState({ target: e.target, showPopover: true })
  }

  getEntityColumn () {
    const entity = this.props.entity || {}
    const entityChild = this.props.entityChild || {}
    const contact = this.props.contact || {}
    const entityNameAndSurname1 = entity.entityName ? entity.entityName + (entity.entitySurname1 ? ' ' + entity.entitySurname1 : '') : null
    const entityDataReturn = (entity.entityName || entity.entityNameDestination || entity.entitySurname1)
      ? this.props.isHeader && (entity.entityName || entity.entityNameDestination + (entity.entitySurname1 ? ' ' + entity.entitySurname1 : '')).length > 20
        ? (entityNameAndSurname1 || entity.entityNameDestination + (entity.entitySurname1 ? ' ' + entity.entitySurname1 : '')).substring(0, 15) + '...'
        : (entityNameAndSurname1 || entity.entityNameDestination + (entity.entitySurname1 ? ' ' + entity.entitySurname1 : ''))
      : ''
    if (entity.entityTypeId && entity.entityTypeId === entityTypesId.recurrentEntity) {
      const nameToShow = !entityChild.entityName || entityChild.entityName === ''
        ? !contact.contactCompletName || contact.contactCompletName === ''
          ? entityDataReturn
          : contact.contactCompletName
        : entityChild.entityName

      return this.props.isHeader && nameToShow.length > 20
        ? nameToShow.substring(0, 15) + '...'
        : nameToShow
    } else {
      return entityDataReturn
    }
  }

  validateKeysEntity (entity) {
    return entity.entityName || entity.entityNameOrigin || entity.entityNameDestination || entity.clientNumber ||
        entity.entityCifNif || entity.entityAddress || entity.entityPhone || entity.entityMail || entity.entityType
  }

  render () {
    const {
      idx,
      id,
      entity = {}
    } = this.props
    const entityNameAndSurname1 = entity.entityName ? entity.entityName + (entity.entitySurname1 ? ' ' + entity.entitySurname1 : '') : null

    return (
      <div>
        {Object.keys(entity).length > 2 &&
          <Button bsSize="xsmall" className="icon-only" onClick={this.handleClick.bind(this)} id={'entity_popOver_button_' + idx} ><Glyphicon glyph="plus"/></Button>
        }
        <div className="text-popover">
          {
            this.getEntityColumn()
          }
        </div>
        <Overlay
          container={this}
          placement='bottom'
          show={this.state.showPopover}
          target={this.state.target}
          onHide={() => this.setState({ showPopover: false })}
          rootClose
        >
          <Popover id={id} className="_nf-popover">
            {entity.entityType &&
              <PopoverItem itemType={'ent-1'} data={entity.entityType}></PopoverItem>
            }
            {entity.entityName &&
              <PopoverItem itemType={'ent-2'} data={entityNameAndSurname1}></PopoverItem>
            }
            {entity.entityNameOrigin &&
              <PopoverItem itemType={'ent-3'} data={entity.entityNameOrigin}></PopoverItem>
            }
            {entity.entityNameDestination &&
              <PopoverItem itemType={'ent-4'} data={entity.entityNameDestination}></PopoverItem>
            }
            {entity.clientNumber &&
              <PopoverItem itemType={'ent-5'} data={entity.clientNumber}></PopoverItem>
            }
            {entity.entityCifNif &&
              <PopoverItem itemType={'ent-6'} data={entity.entityCifNif}></PopoverItem>
            }
            {entity.entityAddress &&
              <PopoverItem itemType={'ent-7'} data={entity.entityAddress}></PopoverItem>
            }
            {entity.entityPhone &&
              <PopoverItem itemType={'ent-8'} data={entity.entityPhone}></PopoverItem>
            }
            {entity.entityMail &&
              <PopoverItem itemType={'ent-9'} data={entity.entityMail}></PopoverItem>
            }
          </Popover>
        </Overlay>
      </div>
    )
  }
}

export default EntityPopover
