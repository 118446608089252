import dossierModalActionTypes from '../../../constants/actions/dossier/dossier'

export function initialState () {
  return {
    documents: []
  }
}

function fetchAllDocumentsSuccess (state, { documents }) {
  return {
    ...state,
    documents
  }
}

function fetchAllDocumentHistoricalsSuccess (state, { documents }) {
  return {
    ...state,
    documents
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case dossierModalActionTypes.FETCH_DOCUMENTS_DOSSIERID_SUCCESS:
      return fetchAllDocumentsSuccess(state, action)
    case dossierModalActionTypes.FETCH_DOCUMENT_HISTORICALS_DOSSIERID_SUCCESS:
      return fetchAllDocumentHistoricalsSuccess(state, action)
    default:
      return state
  }
}
