import React, { PureComponent } from 'react'
import { Modal, Button, Table, Checkbox, Row, Col } from 'react-bootstrap'
import TrackingContainer from '../../../../../containers/tracking/trackingContainer'
import { dossierTypes as dossierTypesConstant } from '../../../../../constants/dossier/common/dossierTypes'

class LandingRequest extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      docArray: [],
      tempAdditionalDocList: [],
      additionalDocList: [],
      addDocs: false,
      order: {
        columnName: null,
        direction: 'asc'
      }
    }
  }

  componentDidUpdate () {

  }

  getSnapshotBeforeUpdate (prevProps) {
    if (prevProps.showModal !== this.props.showModal && this.props.showModal) {
      this.setState({ additionalDocList: [], docArray: [], tempAdditionalDocList: [], addDocs: false })
    }

    return this.props
  }

  addDocToArray (documentTypeUseId) {
    let docListCopy = JSON.parse(JSON.stringify(this.state.docArray))
    const docId = docListCopy.find((e) => e === documentTypeUseId)
    let result = []
    if (docId) {
      docListCopy.forEach(element => {
        if (element !== docId) {
          result.push(element)
        }
      })
    } else {
      result = docListCopy
      result.push(documentTypeUseId)
    }
    this.setState({ docArray: result })
  }

  getAllDocs () {
    this.props.actions.getAllDocumentLandingRequest(this.props.dossierId)
    this.setState({ addDocs: true })
  }

  sendRequest () {
    if (this.state.docArray.length > 0) {
      this.props.actions.sendRequestToClient(this.props.dossierId, this.props.dossierType, this.state.docArray)
      this.setState({ docArray: [], additionalDocList: [] })
      this.props.closeModal()
    }
  }

  changeDirection (columnName) {
    this.setState({
      order: {
        columnName: columnName,
        direction: this.state.order.direction === 'asc' ? 'desc' : 'asc'
      }
    }, this.orderTable)
  }

  changeOrderIcon (name) {
    let clase = 'ico-sort'
    if (this.state.order.direction === 'asc' && this.state.order.columnName === name) {
      clase += '-selected-asc'
    } else if (this.state.order.direction === 'desc' && this.state.order.columnName === name) {
      clase += '-selected-desc'
    }
    return clase
  }

  addDocToTempAdditionalList (doc) {
    let docListCopy = JSON.parse(JSON.stringify(this.state.tempAdditionalDocList))
    const docSearched = docListCopy.find((e) => e.documentTypeUseId === doc.key)
    let result = []
    if (docSearched) {
      docListCopy.forEach(element => {
        if (element !== docSearched) {
          result.push({ name: element.name, documentTypeUseId: element.key })
        }
      })
    } else {
      result = docListCopy
      result.push({ name: doc.value, documentTypeUseId: doc.key })
    }
    this.setState({ tempAdditionalDocList: result })
  }

  getAdditionalDocsView () {
    let compactRow = []
    this.props.allAvailableDocs.filter((e) => {
      const avDoc = this.props.availableDocuments.find((d) => d.documentTypeUseId === e.key)
      const adDoc = this.state.additionalDocList.find((d) => d.documentTypeUseId === e.key)
      if (avDoc || adDoc) return false
      return true
    }).forEach((element, idx) => {
      if (idx % 2 === 0) {
        compactRow.push({ eLeft: element, eRigth: null })
      } else {
        compactRow[compactRow.length - 1].eRigth = element
      }
    })

    return compactRow.map((e, idx) =>
      <Row className="landing-request-add-docs" key={'add' + idx}>
        <Col sm={6}>
          <Checkbox onChange={() => this.addDocToTempAdditionalList(e.eLeft)}>{e.eLeft.value}</Checkbox>
        </Col>
        {e.eRigth && <Col sm={6}>
          <Checkbox onChange={() => this.addDocToTempAdditionalList(e.eRigth)}>{e.eRigth.value}</Checkbox>
        </Col>}
      </Row>
    )
  }

  addAdditionalDocsToList () {
    let docListCopy = JSON.parse(JSON.stringify(this.state.tempAdditionalDocList))
    let additionalDocList = JSON.parse(JSON.stringify(this.state.additionalDocList))
    let docArrayCopy = JSON.parse(JSON.stringify(this.state.docArray))
    docListCopy.forEach(element => {
      docArrayCopy.push(element.documentTypeUseId)
      additionalDocList.push(element)
    })
    this.setState({ additionalDocList, docArray: docArrayCopy, tempAdditionalDocList: [], addDocs: false })
  }

  isAdditionalDocChecked (docId) {
    const doc = this.state.docArray.find((d) => d === docId)
    if (doc) {
      return true
    }
    return false
  }

  render () {
    let {
      t, showModal, availableDocuments, emailAddress, allAvailableDocs, closeModal, dossierType
    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.DOCUMENTARY_MANAGEMENT.LANDING_REQUEST_MODAL.'
    return (
      <Modal show={showModal} className="landing-request-modal" onHide={() => closeModal()}>
        { /* MODAL BODY */ }
        <Modal.Body>
          {!this.state.addDocs && <div>
            <h4>{t(`${tKey}TITLE`)}</h4>
            <p>{t(`${tKey}EMAIL`)}</p>
            <p className="landing-email">{emailAddress}</p>
            <h4>{dossierType === dossierTypesConstant.purchase ? t(`${tKey}SUBTITLE_PROVIDER`) : t(`${tKey}SUBTITLE`)}</h4>
            <Table hover responsive className="simple-table landing-doc-table">
              <thead>
                <tr>
                  <th onClick={() => [this.changeDirection('name')]}>{t(`${tKey + 'TABLE.TYPES'}`)}<i className={(() => [this.changeOrderIcon('name')])()} /></th>
                  <th>{t(`${tKey + 'TABLE.SELECT'}`)}</th>
                </tr>
              </thead>
              <tbody>
                {
                  availableDocuments && availableDocuments.map((doc, idx) => {
                    return <tr key={idx}>
                      <td>{doc.name}</td>
                      <td><Checkbox onChange={() => this.addDocToArray(doc.documentTypeUseId)}></Checkbox></td>
                    </tr>
                  })
                }
                {
                  this.state.additionalDocList && this.state.additionalDocList.map((doc, idx) => {
                    return <tr key={idx}>
                      <td>{doc.name}</td>
                      <td><Checkbox defaultChecked={this.isAdditionalDocChecked(doc.documentTypeUseId)} onChange={() => this.addDocToArray(doc.documentTypeUseId)}></Checkbox></td>
                    </tr>
                  })
                }
              </tbody>
            </Table>
            <Button onClick={() => this.getAllDocs()}>{t(`${tKey + 'ADD_DOC'}`)}</Button>
          </div>}

          {this.state.addDocs && allAvailableDocs && <div>
            <h4>{t(`${tKey}TITLE_ADD_DOCS`)}</h4>
            {this.getAdditionalDocsView()}
          </div>}
        </Modal.Body>
        { /* FIN MODAL BODY */ }
        <Modal.Footer>
          {!this.state.addDocs && <div>
            <Button className="btn-save" onClick={() => this.sendRequest()} disabled={this.state.docArray.length === 0}>{t(`${tKey + 'SEND_REQUEST'}`)}</Button>
            <Button className="btn-cancel" onClick={() => closeModal()}>{t(`${tKey + 'CLOSE'}`)}</Button>
          </div>}

          {this.state.addDocs && allAvailableDocs && <div>
            <Button className="btn-save" onClick={() => this.addAdditionalDocsToList()} disabled={this.state.tempAdditionalDocList.length === 0}>{t(`${tKey + 'ADD_DOC'}`)}</Button>
            <Button className="btn-cancel" onClick={() => this.setState({ addDocs: false, tempAdditionalDocList: [] })}>{t(`${tKey + 'CANCEL'}`)}</Button>
          </div>}
        </Modal.Footer>
      </Modal>
    )
  }
}

export default TrackingContainer(LandingRequest, false)
