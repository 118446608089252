import apiFetch from '../apiFetch'

export default function (token, { page, pageSize, orderBy, name, surname1, surname2, dniCif, chassis, licensePlate }) {
  const queryParamsArray = [
    page ? `page=${page}` : null,
    pageSize ? `pageSize=${pageSize}` : null,
    orderBy ? `orderBy=${orderBy}` : null,
    name ? `entityName=${name}` : null,
    surname1 ? `entitySurname1=${surname1}` : null,
    surname2 ? `entitySurname2=${surname2}` : null,
    dniCif ? `entityDniCif=${dniCif}` : null,
    chassis ? `vehicleVinChasis=${chassis}` : null,
    licensePlate ? `vehicleLicensePlate=${licensePlate}` : null
  ]
  const queryParams = queryParamsArray.reduce(
    (result, query, idx) => result + (query ? (result ? `&${query}` : `?${query}`) : '')
    , ''
  )
  return apiFetch({
    endPoint: `dossierFinder/Paperworks${queryParams}`,
    method: 'GET',
    body: null,
    token: token
  })
}
