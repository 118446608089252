import React, { PureComponent } from 'react'
import { Row, Panel } from 'react-bootstrap'
import EditableTable from '../../../commons/editableTable/EditableTable'

class CommentComponent extends PureComponent {
  componentDidMount () {
    this.props.actions.markCommentAsRead(this.props.dossierId, this.props.dossierType, this.props.historical)
  }

  componentDidUpdate (prevProps) {
    if ((this.props.openComponent && !prevProps.openComponent) ||
      (this.props.comments && prevProps.comments && this.props.comments.length > prevProps.comments.length)) {
      this.props.actions.markCommentAsRead(this.props.dossierId, this.props.dossierType, this.props.historical)
    }
  }

  render () {
    const { t, tKey, comments, actions: { updateComment, createComment, deleteComment }, readOnlyFields, dossierType } = this.props
    return (
      <Panel>
        <Row>
          <EditableTable
            t={t}
            columnsConfig={
              [
                { th: t(`${tKey}COMMENTS.USER_NAME`), key: 'userName', nonEditable: true },
                { th: t(`${tKey}COMMENTS.COMMENT_TYPE`), key: 'commentType', nonEditable: true },
                {
                  th: t(`${tKey}COMMENTS.REJECT_REASON`),
                  key: 'reasonId',
                  nonEditable: true,
                  isSelect: true,
                  selectConfig: {
                    placeholder: t(`${tKey}COMMENTS.REJECT_REASON`),
                    options: this.props.combos.allReasonCombo,
                    valueKey: 'id',
                    labelKey: 'value'
                  },
                  showColumn: this.props.combos.allReasonCombo ? this.props.combos.allReasonCombo.length > 0 : false
                },
                { th: t(`${tKey}COMMENTS.TEXT`), key: 'text', style: { width: '30%' }, maxLength: 499 },
                { th: t(`${tKey}COMMENTS.CREATION_DATE`), key: 'creationDate', nonEditable: true }
              ]
            }
            dossierType={dossierType}
            editableCommentsArray={comments}
            updateAction={updateComment}
            createAction={createComment}
            deleteAction={deleteComment}
            readOnlyFields={readOnlyFields}
          />
        </Row>
      </Panel>
    )
  }
}
export default CommentComponent
