import React, { PureComponent } from 'react'
import { reduxForm, Field, reset } from 'redux-form'
import { Row, Form, Button, Col, ProgressBar } from 'react-bootstrap'
import InputSelect from '../commons/form/InputSelect'
import InputCheckBox from '../commons/form/InputCheckBox'
import { validateDate } from '../../util/validationFunctions'
import InputDatePicker from '../commons/form/InputDatePicker'
import InputText from '../commons/form/InputText'
import SimpleTablePage from '../../containers/commons/general/SimpleTablePage'
import IDocCarPagination from '../commons/iDocCarPagination'
import { formatDate } from '../../util/utils'
import moment from 'moment'
import ZipModalPage from '../../containers/management/auxModals/ZipModalPage'
import InputTreeSelect from '../commons/form/InputTreeSelect'

class SearchDocumental extends PureComponent {
  constructor () {
    super()
    this.state = {
      fields: [],
      filters: [],
      dynamicFilters: [],
      disableResetButton: false,
      displayBar: 'none',
      progress: 0,
      progressFail: 0,
      errors: [],
      checkboxes: {},
      maxBar: 0,
      type: false,
      visible: true,
      organizedUnitIds: null,
      selectAll: false
    }
  }

  componentDidMount () {
    this.props.actions.fetchAuditCodes()
    this.props.actions.fetchDocumentTypeUseCombo()
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props._reduxForm.zipModal.showModal === false && prevProps._reduxForm.zipModal.showModal === true) {
      this.setState({ visible: false })
    }
    if (prevProps.items !== this.props.items && this.state?.selectAll === true) {
      const checkboxes = this.props?.items?.reduce(
        (checkboxes, document) => ({
          ...checkboxes,
          [document.documentId]: true
        }),
        {}
      )
      this.setState({ checkboxes })
    }
  }

  submitSearchForm (values) {
    if (values.auditConfigurationId) {
      this.setState({ filters: values })
      let filters = values
      let propFilters = this.props.filters
      propFilters.orderBy = '+Number'
      this.props.actions.fetchSearchDocumentsInfo({ ...filters, ...propFilters })
      this.setState({ dynamicFilters: filters, selectAll: false, checkboxes: {} })
    } else {
      this.props.validate()
    }
  }

  fieldFill (field) {
    this.setState({ fields: { ...this.state.fields, ...field } })
  }

  onDossierTypeChange (dossierTypesId) {
    this.props.actions.fetchDossierSubTypes(null, dossierTypesId)
  }

  dateValidation (value, allValues, props) {
    const result = validateDate(value, props)
    return result === Object(result) ? undefined : result
  }

  clearFields () {
    this.setState({ fields: {} })
  }

  handleClickReset (e) {
    if (e !== undefined) {
      e.preventDefault()
    }
    this.clearFields()
    this.props.actions.resetSearchDocumentsInfo()
    this.props.dispatch(reset('searchAuditsForm'))
    this.setState({ dynamicFilters: [] })
  }

  onOrderChange (e) {
    const newOrderBy = this.props.filters.orderBy.includes(`+${e.currentTarget.dataset.field}`) ? `-${e.currentTarget.dataset.field}` : `+${e.currentTarget.dataset.field}`
    this.fetchTable({ ...this.props.filters, orderBy: newOrderBy })
  }

  onPageChange (page) {
    this.fetchTable({ ...this.props.filters, page })
  }

  getIconClass (field) {
    if (this.props.filters.orderBy.includes(field)) {
      return this.props.filters.orderBy.includes('-') ? 'ico-sort-selected-asc' : 'ico-sort-selected-desc'
    } else {
      return 'ico-sort'
    }
  }

  fetchTable (propFilters) {
    let filters = this.state.dynamicFilters
    this.props.actions.fetchSearchDocumentsInfo({ ...filters, ...propFilters })
    this.setState({ dynamicFilters: filters })
  }

  viewDocument (data) {
    if (data.dossierId) {
      this.props.actions.openDocumentEditionModal(data.documentId, data.documentTypeUseId, data.documentTypeEntityId, data.dossierId, data.entityId, data.vehicleId, data.organizedUnitId, null, null, null, null, null, null, null, null, false, null)
    }
    if (data.dossierHistoricalId) {
      this.props.actions.openDocumentEditionModalHistorical(data.documentId, data.documentTypeUseId, null, data.dossierId, data.entityId, data.vehicleId, data.organizedUnitId, null, null, null, null)
    }
  }

  showModalExport () {
    this.setState({
      visible: true
    }, () => {
      let trueCheckboxesString = Object.keys(this.state.checkboxes)
        .filter(id => this.state.checkboxes[id])
        .join(',')

      if (this.state.selectAll) {
        trueCheckboxesString = this.props.documentIds.join(',')
      }

      this.props.actions.openZipModal(trueCheckboxesString)
    })
  }

  setValues (val) {
    this.setState({ organizedUnitIds: val })
  }

  onCheckAll (e) {
    const checkboxes = this.props.items.reduce(
      (checkboxes, document) => ({
        ...checkboxes,
        [document.documentId]: e
      }),
      {}
    )

    this.setState({
      selectAll: e,
      checkboxes
    })
  }

  onCheckOne (e, id) {
    this.setState(prevState => ({
      checkboxes: { ...prevState.checkboxes, [id]: e },
      selectAll: false
    }))
  }

  downloadDoc () {
    var selectedDocCount = Object.values(this.state.checkboxes).filter(value => value).length
    if (this.state.selectAll) {
      selectedDocCount = this.props.documentIds.length
    }
    this.props.actions.openModal('updateDocumentsSearchDocumental', () => this.showModalExport(), null, null, null, [{ id: '##NUMBER##', value: selectedDocCount }])
  }

  render () {
    const {
      t, handleSubmit, className, hasSearch, count, items,
      filters: { page, pages },
      combos: {
        auditCodes,
        UOTreeCombo,
        documentTypeUseCombo
      }
    } = this.props
    const tKey = 'MANAGEMENT.SEARCH_DOCUMENTAL.'
    return (
      <>
        { this.state.visible &&
        <ZipModalPage/>
        }
        <Form autoComplete="off" onSubmit={handleSubmit((values) => this.submitSearchForm(values))} className="filters" >
          <div>
            <>
              <Row>
                <Col sm={12}>
                  <Field
                    id="auditConfigurationId"
                    name='auditConfigurationId'
                    placeholder={t(`${tKey}CODE`)}
                    controlLabel={t(`${tKey}CODE`)}
                    component={InputSelect}
                    options={auditCodes}
                    valueKey="id"
                    labelKey="value"
                    customClass={this.state.fields.dossierSubtypeId ? className : ''}
                  />
                  <Field
                    id="organizedUnitIds"
                    name="organizedUnitIds"
                    placeholder={t(`${tKey}OU`)}
                    controlLabel={t(`${tKey}OU`)}
                    component={InputTreeSelect}
                    multi={true}
                    valueKey="value"
                    labelKey="label"
                    childrenKey="children"
                    options={UOTreeCombo}
                    onChange={(val) => this.setValues(val)}
                    colSm={3} />
                  <Field
                    colSm={3}
                    id="dossierNumber"
                    name="dossierNumber"
                    controlLabel={t(`${tKey}NUM_DOSSIER`)}
                    placeholder={t(`${tKey}NUM_DOSSIER`)}
                    component={InputText}
                  />
                  <Field
                    colSm={3}
                    id="vin"
                    name="vin"
                    controlLabel={t(`${tKey}VIN`)}
                    placeholder={t(`${tKey}VIN`)}
                    minLength={7}
                    maxLength={17}
                    component={InputText}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <Field
                    colSm={3}
                    id="licensePlate"
                    name="licensePlate"
                    controlLabel={t(`${tKey}LICENSE`)}
                    placeholder={t(`${tKey}LICENSE`)}
                    component={InputText}
                  />
                  <Field
                    colSm={3}
                    id="makerNumber"
                    name="makerNumber"
                    controlLabel={t(`${tKey}ODER`)}
                    placeholder={t(`${tKey}ODER`)}
                    component={InputText}
                  />
                  <Field
                    id="creationDateFrom"
                    name='creationDateFrom'
                    placeholder={t(`${tKey}CREATION_DATE_FROM`)}
                    controlLabel={t(`${tKey}CREATION_DATE_FROM`)}
                    component={InputDatePicker}
                    validate={[this.dateValidation]}
                    onInputChange={(val) => this.fieldFill({ creationDateFrom: val })}
                    customClass={this.state.fields.creationDatefrom ? className : ''}
                  />
                  <Field
                    id="creationDateTo"
                    name='creationDateTo'
                    placeholder={t(`${tKey}CREATION_DATE_TO`)}
                    controlLabel={t(`${tKey}CREATION_DATE_TO`)}
                    component={InputDatePicker}
                    validate={[this.dateValidation]}
                    onInputChange={(val) => this.fieldFill({ creationDateTo: val })}
                    customClass={this.state.fields.creationDateTo ? className : ''}
                  />
                  <div key="clearfix_1" className="clearfix" />
                </Col>
              </Row>
              <Row className='buttons-row'>
                <Col sm={12} className="text-center">
                  <hr/>
                  <Button
                    id="btn_search"
                    type="submit"
                    className="btn-standard"
                  >
                    <i className="ico-search"/>
                    {t('SEARCH.ACTIONS.SEARCH_BUTTON')}
                  </Button>
                  <Button
                    id="btn_reset"
                    onClick={(e) => this.handleClickReset(e)}
                    className={'btn-white btn-icon' + (this.props.pristine && !hasSearch ? ' btn-cancel' : ' btn-standard')}
                    disabled={this.props.pristine && !hasSearch}
                  >
                    <i className="ico-cancel"/> {t('SEARCH.ACTIONS.RESET_BUTTON')}
                  </Button>
                </Col>
              </Row>
            </>
            {
              hasSearch && ([
                <div className="table-wrapper" key="table-wrapper">
                  {hasSearch && count > 0 && Object.values(this.state.checkboxes).filter(value => value).length > 0 &&
                <Row>
                  <Button
                    onClick={() => { this.downloadDoc() }}
                    bsStyle="default"
                    bsSize="small"
                    className="button-search-left">
                    {t(`${tKey}DOWNLOAD_BTN`)}
                    <i className="ico-if_Download_1031520"/>
                  </Button>
                </Row>
                  }
                  <SimpleTablePage
                    columns={[
                      <th key={1} data-field="documentType">
                        {t(`${tKey}DOCUMENT_TYPE`)}
                      </th>,
                      <th key={2} data-field="Number" onClick={(event) => this.onOrderChange(event)}>
                        {t(`${tKey}DOSSIER`)}&nbsp;<i className={this.getIconClass('number')}/>
                      </th>,
                      <th key={3} data-field="MakerNumber" onClick={(event) => this.onOrderChange(event)}>
                        {t(`${tKey}ODER`)}&nbsp;<i className={this.getIconClass('makerNumber')}/>
                      </th>,
                      <th key={4} data-field="LicensePlate" onClick={(event) => this.onOrderChange(event)}>
                        {t(`${tKey}LICENSE`)}&nbsp;<i className={this.getIconClass('licensePlate')}/>
                      </th>,
                      <th key={5} data-field="CreationDate" onClick={(event) => this.onOrderChange(event)}>
                        {t(`${tKey}CREATION_DATE`)}&nbsp;<i className={this.getIconClass('creationDate')}/>
                      </th>,
                      <th key={6} data-field="check" >
                        <Field
                          component={InputCheckBox}
                          name={'selectedCheck_massive'}
                          onChange={(e) => this.onCheckAll(e)}
                          checked={this.state.selectAll}
                        />
                      </th>,
                      <th key={7} data-field="actions">{t(`${tKey}ACTIONS`)}&nbsp;</th>
                    ]}
                    rows={items && items.map((row, idx) => (
                      <tr key={idx}>
                        <td>{documentTypeUseCombo?.find(d => d.id === row.documentTypeUseId)?.value}</td>
                        <td>{row.number}</td>
                        <td>{row.makerNumber}</td>
                        <td>{row.licensePlate}</td>
                        <td>{(row.creationDate) ? formatDate(row.creationDate) : '-'}</td>
                        <td>
                          <Field
                            component={InputCheckBox}
                            name={'selectedCheck_' + row.documentId}
                            id={'selectedCheck_' + row.documentId}
                            checked={
                              this.state.checkboxes[row.documentId] || false
                            }
                            onChange={(event) => this.onCheckOne(event, row.documentId)} />
                        </td>
                        <td className="actionsMenu">
                          <i className="iDocIcon-open-in-new-black blue" onClick={() => this.viewDocument(row)}></i>
                        </td>
                      </tr>
                    ))}
                  />
                </div>,
                <div className="search-footer" key="search-footer">
                  <IDocCarPagination
                    id="btn_pag_sales"
                    page={page}
                    pagesCount={pages}
                    totalRows= {count}
                    onSelectAction={(page) => this.onPageChange(page)}
                  />
                </div>
              ])
            }
            <ProgressBar style={{ display: this.state.displayBar }}>
              <ProgressBar max={this.state.maxBar} now={this.state.progress} label={`${this.state.progress}/${this.state.maxBar}`} striped variant="success" key={1} />
              <ProgressBar max={this.state.maxBar} now={this.state.progressFail} label={`${this.state.progressFail}/${this.state.maxBar}`} striped variant="danger" style={{ backgroundColor: '#d44b4b' }} key={2} />
            </ProgressBar>
          </div>
        </Form>
      </>
    )
  }
}

export default reduxForm({
  form: 'searchAuditsForm',
  validate: (values, props) => {
    const errors = {}
    if (!values.auditConfigurationId || values.auditConfigurationId === undefined) {
      errors.auditConfigurationId = props.t('DOSSIER_COMPONENTS.VALIDATIONS.MANDATORY_FIELD')
      return errors
    }
    if (values.creationDateTo !== undefined) {
      const format = 'D-M-YYYY'
      const fromDate = moment(values.creationDateFrom, format)
      const toDate = moment(values.creationDateTo, format)
      if (toDate.isBefore(fromDate)) {
        errors.creationDateTo = props.t('DOSSIER_COMPONENTS.VALIDATIONS.DATE_VALIDATION')
        return errors
      }
    }
  }
})(SearchDocumental)
