import React, { PureComponent } from 'react'
import { Panel, Button } from 'react-bootstrap'
import { Field } from 'redux-form'
import InputCheckbox from '../../commons/form/InputCheckBox'
import SimpleTablePage from '../../../containers/commons/general/SimpleTablePage'
import { compareValues } from '../../../util/utils'
import { Link } from 'react-router-dom'

class DossiersListFleetDetailsComponent extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      openDossierPanel: true,
      filters: {
        orderBy: '+number',
        selectedCheck_massive: false
      }
    }
  }

  componentDidMount () {
    if (this.props.fleet && !this.props.create) {
      this.props.actions.getDossierDetailsFleet(this.props.fleet.fleetId)
    }
  }

  collapsePanelDossier () {
    this.setState({ openDossierPanel: !this.state.openDossierPanel })
  }

  componentDidUpdate () {
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (this.props.changeCheckAll !== prevProps.changeCheckAll) {
      this.setState({ selectedCheck_massive: false })
    }
    return null
  }

  getIconClass (field) {
    if (this.state.filters.orderBy.includes(field)) {
      return this.state.filters.orderBy.includes('-') ? 'ico-sort-selected-asc' : 'ico-sort-selected-desc'
    } else {
      return 'ico-sort'
    }
  }

  onOrderChange (e) {
    const newOrderBy = this.state.filters.orderBy.includes(`+${e.currentTarget.dataset.field}`) ? `-${e.currentTarget.dataset.field}` : `+${e.currentTarget.dataset.field}`
    this.setState({ filters: { orderBy: newOrderBy } })
    this.order(e.currentTarget.dataset.field, newOrderBy.includes('+') ? 'asc' : 'desc')
  }

  order (field, order) {
    let dossiers = JSON.parse(JSON.stringify(this.props.dossierFleetDetails))
    dossiers = dossiers.sort(compareValues(field, order))
    this.props.actions.getDossierDetailsFleetSuccess(dossiers)
  }

  onCheckAll (e) {
    this.setState({ selectedCheck_massive: e })
    let dossiers = JSON.parse(JSON.stringify(this.props.dossierFleetDetails))

    dossiers.map(dossier => {
      dossier.checked = e
    })

    this.props.actions.getDossierDetailsFleetSuccess(dossiers)
  }

  onCheckOne (e, id) {
    this.setState({ selectedCheck_massive: false })
    let dossiers = JSON.parse(JSON.stringify(this.props.dossierFleetDetails))

    dossiers.map(dossier => {
      if (dossier.dossierId === id) {
        dossier.checked = e
      }
    })

    this.props.actions.getDossierDetailsFleetSuccess(dossiers)
  }

  updateDossiers () {
    if (this.props.fleet && !this.props.create) {
      this.props.actions.getDossierDetailsFleet(this.props.fleet.fleetId)
    }
  }

  render () {
    const {
      t,
      dossierFleetDetails,
      readOnlyFields
    } = this.props
    const tKey = 'MANAGEMENT.FLEETS_TAB.'
    const numDossiers = dossierFleetDetails ? dossierFleetDetails.length : 0
    let columnsTable = [
      <th key={0} data-field="number" onClick={(event) => this.onOrderChange(event)}>
        {t(`${tKey}DOSSIER_NUMBER`)}&nbsp;<i className={this.getIconClass('number')}></i>
      </th>,
      <th key={1} data-field="organizedUnit" onClick={(event) => this.onOrderChange(event)}>
        {t(`${tKey}ORGANIZED_UNIT`)}&nbsp;<i className={this.getIconClass('organizedUnit')}></i>
      </th>,
      <th key={2} data-field="clientName" onClick={(event) => this.onOrderChange(event)}>
        {t(`${tKey}CLIENT`)}&nbsp;<i className={this.getIconClass('clientName')}/>
      </th>,
      <th key={3} data-field="finalClientName" onClick={(event) => this.onOrderChange(event)}>
        {t(`${tKey}FINAL_CLIENT`)}&nbsp;<i className={this.getIconClass('finalClientName')}/>
      </th>,
      <th key={4} data-field="vehicle" onClick={(event) => this.onOrderChange(event)}>
        {t(`${tKey}VEHICLE`)}&nbsp;<i className={this.getIconClass('vehicle')}/>
      </th>,
      <th key={5} data-field="model" onClick={(event) => this.onOrderChange(event)}>
        {t(`${tKey}MODEL`)}&nbsp;<i className={this.getIconClass('model')}></i>
      </th>,
      <th key={6} data-field="salesMan" onClick={(event) => this.onOrderChange(event)}>
        {t(`${tKey}SALESMAN`)}&nbsp;<i className={this.getIconClass('salesMan')}/>
      </th>,
      <th key={7} data-field="stage" onClick={(event) => this.onOrderChange(event)}>
        {t(`${tKey}STAGE`)}&nbsp;<i className={this.getIconClass('stage')}/>
      </th>
    ]
    if (!readOnlyFields) {
      columnsTable.push(
        <th key={8} data-field="checked" className='flet-detail-dossier-check-title' >
          <Field
            component={InputCheckbox}
            name={'selectedCheck_massive'}
            onChange={(event) => this.onCheckAll(event)}
            checked={this.state.selectedCheck_massive}
          />
        </th>
      )
    }

    return (
      <>
        <div className="dossier-panel" >
          <div className="header-panel  fleet-panel-box" onClick={() => this.collapsePanelDossier()}>

            <div className="name-wrapper fleet-panel-box-title">
              <h4>{t(`${tKey}DOSSIERS`)} | <span className='fleet-panel-subTitle'>{numDossiers}</span> </h4>
            </div>

            <i className={this.state.openDossierPanel ? 'ico-arrow-black-down fleet-panel-box-caret' : 'ico-arrow-black-up fleet-panel-box-caret'} />

          </div>
          <Panel expanded={this.state.openDossierPanel} onToggle={() => {}}>
            <Panel.Collapse>
              <div id="fleet-dossier-details-table">
                {!readOnlyFields && (
                  <div className="flexInLine al-R">
                    <Button
                      id="update_dossiers_button"
                      className="_Btn accept stk flexInLine al-R"
                      onClick = {() => this.updateDossiers()}>
                      <i className="iDocIcon-update"></i>
                      {t('MANAGEMENT.ADMIN_AUDIT.MODAL.UPDATE')}
                    </Button>
                  </div>
                )}
                <SimpleTablePage
                  columns={columnsTable}
                  rows={dossierFleetDetails && dossierFleetDetails.map((row, idx) => (
                    <tr key={idx}>
                      <td>
                        {
                          row.urlToNavigate ? (
                            <Link to={`/dossier${row.urlToNavigate}`}>
                              {row.number}
                            </Link>
                          ) : row.number
                        }
                      </td>
                      <td>{row.organizedUnit}</td>
                      <td>{row.clientName}</td>
                      <td>{row.finalClientName}</td>
                      <td>{row.vehicle}</td>
                      <td>{row.model}</td>
                      <td>{row.salesMan}</td>
                      <td>{row.stage}</td>
                      {!readOnlyFields && <td>{<Field
                        component={InputCheckbox}
                        name={'selectedCheck_' + row.dossierId}
                        id={'selectedCheck_' + row.dossierId}
                        checked={row.checked ? row.checked : false}
                        onChange={(event) => this.onCheckOne(event, row.dossierId)}
                      />}
                      </td>}
                    </tr>
                  ))}

                ></SimpleTablePage>
              </div>

            </Panel.Collapse>
          </Panel>
        </div>

      </>
    )
  }
}

export default DossiersListFleetDetailsComponent
