import { call, put, takeEvery, select } from 'redux-saga/effects'
import { reset } from 'redux-form'
import { yesNoModal } from '../../../modalsListeners/yesNoModal'
import vehicleActionsTypes from '../../../../constants/actions/dossier/common/vehicle'
import { dossierTypes } from '../../../../constants/dossier/common/dossierTypes'
import { showLoader, hideLoader } from '../../../../actions/common'
import { closeOrderModal } from '../../../../actions/dossier/common/vehicle'
import { getAuth } from '../../../../selectors/access/auth'
import getOrderByDossierId from '../../../../services/order/getOrderByDossierId'
import putOrder from '../../../../services/order/putOrder'
import postOrder from '../../../../services/order/postOrder'
import messageModalActions from '../../../../actions/modals/messageModal'
import { fetchSalesDossierSuccess } from '../../../../actions/dossier/sales'
// Header Component
import getStageCheckList from '../../../../services/dossier/get/getStageCheckList'
import { handleError } from '../../../errors/errorManager'
import { initializeDossier } from '../../commonDossierSagas'
import { purchaseTypesVn } from '../../../../constants/dossier/purchase/purchaseTypes'
import { fetchRegistrationDeliveryDossierSuccess } from '../../../../actions/dossier/registrationDelivery'

export function * saveOrder ({ order, dossierId, isSalesRequestModal, dossierType, resolve }) {
  try {
    yield put(showLoader())

    let dossierState
    if (dossierType === dossierTypes.registrationDelivery) {
      dossierState = yield select(state => state.registrationDeliveryDossier)
    } else {
      dossierState = yield select(state => state.salesDossier)
    }

    const auth = yield select(getAuth)
    const orderToSend = {
      ...order,
      makerNumber: order.purchaseTypeId === purchaseTypesVn.transfer ? null : order.makerNumber,
      vinChassis: order.purchaseTypeId === purchaseTypesVn.normal ? null : order.vinChassis,
      entityId: order.purchaseTypeId === purchaseTypesVn.normal ? null : order.entityId
    }

    if (order.orderId) {
      yield call(putOrder, order.orderId, orderToSend, auth.token, dossierState.lastAccessDate)
    } else {
      yield call(postOrder, orderToSend, dossierId, auth.token, dossierState.lastAccessDate)
    }

    if (!isSalesRequestModal) {
      yield put(closeOrderModal())
    }

    const data = {}
    data.order = yield call(getOrderByDossierId, dossierId, auth.token)
    data.checkListHeader = yield call(getStageCheckList, dossierId, auth.token)

    if (dossierType === dossierTypes.registrationDelivery) {
      yield put(fetchRegistrationDeliveryDossierSuccess(data))
    } else {
      yield put(fetchSalesDossierSuccess(data))
    }
    yield put(messageModalActions.openSuccessMessageModal('MESSAGES.SAVE_ORDER_SUCCESS'))
    yield call(initializeDossier, dossierType, dossierState)

    if (resolve) yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          let dossierStateToReload = yield select(state => state.salesDossier)
          if (dossierType === dossierTypes.registrationDelivery) {
            dossierStateToReload = yield select(state => state.registrationDeliveryDossier)
          }
          yield call(initializeDossier, dossierType, dossierStateToReload)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('registrationDeliveryDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSaveOrder () {
  yield takeEvery(vehicleActionsTypes.SAVE_ORDER, saveOrder)
}
