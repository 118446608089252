import apiFetch from '../apiFetch'

export default function (token, dossierId, isHistorical, code) {
  return apiFetch({
    endPoint: `TemplateHtml/PrintTemplateByCode?dossierId=${dossierId}&templateCode=${code}&isHistorical=${isHistorical}`,
    method: 'GET',
    parseBlobAndAssign: true,
    token: token
  })
}
