import React, { PureComponent } from 'react'
import { Panel, Col, Table } from 'react-bootstrap'
import PaperworkRow from './PaperworkRow'
import PaperworkModal from './PaperworkEditionModal'
import SmallLoaderPage from '../../../../containers/commons/SmallLoaderPage'
import InputCheckbox from '../../../commons/form/InputCheckBox'
import { permissions } from '../../../../constants/backendIds'
import { dossierTypes as dossierTypesConstant } from '../../../../constants/dossier/common/dossierTypes'
import { getFieldsPermisions } from '../../../../util/permisionFunctions'
import { compareObjects } from '../../../../util/utils'
import { Field } from 'redux-form'
import CustomFields from '../../../commons/customFields/CustomFields'

class PaperworksTable extends PureComponent {
  constructor () {
    super()
    this.state = {
      open: true,
      fieldsConfiguration: {
        externalPaperwork: null
      }
    }
  }

  initializePermisions (props) {
    const fieldKeys = Object.keys(this.state.fieldsConfiguration)
    const newState = getFieldsPermisions(props.componentConfiguration.fieldsConfiguration, fieldKeys, props.componentConfiguration.permission)
    this.setState({ fieldsConfiguration: newState.fieldsConfiguration })
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (this.props.dossierParentId && !compareObjects(prevProps.componentConfiguration || {}, this.props.componentConfiguration, true)) {
      this.initializePermisions(this.props)
    }
    if (!prevProps.open && this.props.open) {
      this.setState({ open: true })
    }
    return prevProps
  }

  componentDidMount () {
    this.initializePermisions(this.props)
  }

  componentDidUpdate () {
    // don't remove use for getsnapshot
  }

  toggleComponent () {
    if (this.state.open) {
      this.props.actions.setActiveSection(false)
    }
    this.setState({ open: !this.state.open })
  }

  render () {
    const {
      t, paperwork, dossierType, isHistorical = false,
      readOnlyFields, combos, organizedUnitId, dossierParentId,
      showModal, isEditionModal, updateDossierAction, showAlertSendPending, customFields,
      actions: {
        openPaperworkEdition,
        deletePaperwork,
        fetchPaperworkEditionModal,
        printPaperwork,
        updateDependantsSaleDossierAndHeader,
        updatePaperwork,
        createPaperwork,
        closePaperworkEditionModal,
        openDocumentEditionModal/* ,
        navigateTo */
      }
    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.PAPERWORK.'
    return (
      <div className="paperworks-panel dossier-panel">
        {isHistorical && (
          <PaperworkModal
            t={t}
            combos={combos}
            showModal={showModal}
            dossierParentId={dossierParentId}
            isEditionModal={isEditionModal}
            showAlertSendPending={showAlertSendPending}
            actions={{
              closePaperworkEditionModal,
              updatePaperwork,
              createPaperwork,
              updateDossierAction
            }}
          />
        )}
        <div className="header-panel" onClick={this.toggleComponent.bind(this)}>
          <div className="name-wrapper">
            <i className="ico-document-lines"/>
            <h4>{t(`${tKey}TITLE_PANEL`)}</h4>
          </div>
          <i className={this.state.open ? 'ico-arrow-black-up' : 'ico-arrow-black-down'}/>
        </div>
        <Panel expanded={this.state.open} onToggle={() => {}}>
          <Panel.Collapse>
            <Col sm={12} className="col-row" >
              {this.state.fieldsConfiguration.externalPaperwork && this.state.fieldsConfiguration.externalPaperwork.permission !== permissions.hidden &&
                dossierType && dossierType === dossierTypesConstant.sales && (
                <Field
                  colSm={12}
                  id="externalPaperwork"
                  name="dossierPaperworkComponent.externalPaperwork"
                  placeholder={t(`${tKey}EXTERNAL_PAPERWORK`)}
                  inline
                  component={InputCheckbox}
                  disabled={true}
                />
              )}
              {<br></br>}
              {<br></br>}
              {<br></br>}
            </Col>
            <Col sm={12} className="col-row">
              <Table className="editable-table" hover responsive>
                <thead>
                  <tr>
                    <th>{t(`${tKey}NUMBER`)}</th>
                    <th>{t(`${tKey}TYPE`)}</th>
                    <th>{t(`${tKey}STAGE`)}</th>
                    <th>{t(`${tKey}DATE`)}</th>
                    <th>{t(`${tKey}COST`)}</th>
                    <th>{t(`${tKey}PROCESSING_AGENCY`)}</th>
                    <th>{t(`${tKey}DOCUMENTS`)}</th>
                    <th>{t(`${tKey}ORDER`)}</th>
                    <th style={{ width: '77px' }}>{t('DOSSIER_COMPONENTS.ACCESSORIES.ACTIONS')}</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    paperwork.map((paperwork, idx) => {
                      return (
                        <PaperworkRow
                          t={t}
                          tKey={tKey}
                          organizedUnitId={organizedUnitId}
                          dossierParentId={dossierParentId}
                          paperwork={paperwork}
                          // navigateTo={navigateTo}
                          key={idx}
                          updateDependantsSaleDossierAndHeader={updateDependantsSaleDossierAndHeader}
                          openPaperworkEdition={openPaperworkEdition}
                          printPaperwork={printPaperwork}
                          fetchPaperworkEditionModal={fetchPaperworkEditionModal}
                          deletePaperwork={deletePaperwork}
                          updateDossierAction={updateDossierAction}
                          showAlertSendPending={showAlertSendPending}
                          openDocumentEditionModal={openDocumentEditionModal}
                          readOnlyFields={readOnlyFields}
                          dossierType={dossierType}
                        />
                      )
                    })
                  }
                </tbody>
              </Table>
            </Col>
            <CustomFields customFields={customFields} readOnlyFields={true} />
          </Panel.Collapse>
        </Panel>
        <SmallLoaderPage/>
      </div>
    )
  }
}

export default PaperworksTable
