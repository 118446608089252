import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { closeDocumentVisualizationMasterModal, submitDocumentVisualization } from '../../../actions/masters/documentVisualization/documentVisualization'
import DocumentVisualizationMasterModal from '../../../components/masters/documentVisualization/DocumentVisualizationMasterModal'

export function mapStateToProps (state) {
  const documentVisualizationModal = state.documentVisualization.documentVisualizationModal
  return {
    showModal: documentVisualizationModal.showModal,
    documentVisualization: documentVisualizationModal.documentVisualization,
    combos: {
      dossierSubtypesCombo: state.combos.searchDossierSubtypesCombo,
      documentTypeUseCombo: state.combos.documentTypeUseCombo
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      submitDocumentVisualization,
      closeDocumentVisualizationMasterModal
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(DocumentVisualizationMasterModal))
