import React, { PureComponent } from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import { Field, reduxForm } from 'redux-form'
import InputDropzone from '../../commons/form/InputDropzone'
import InputSelect from '../../commons/form/InputSelect'
import ImportVehicleModelsResultsModal from './importVehicleModelsResultsModal'

class ImportVehicleModelsMaster extends PureComponent {
  constructor () {
    super()
    this.state = { }
  }

  submit (values) {
    this.props.actions.importVehicleModelFile({ values: values.vehicleModelFile }, values.brandId)
  }

  render () {
    const {
      t, handleSubmit,
      combos: { brandCombo },
      actions: { openModal }
    } = this.props

    const tKey = 'MASTERS.VEHICLE_MODELS.'

    return (
      <React.Fragment>
        <ImportVehicleModelsResultsModal
          t ={t}
          showModal = {this.props.importVehicleModels.showImportResult}
          importResults = {this.props.importVehicleModels}
          closeModal = {this.props.actions.cancelImportVehicleModelFile}
        />
        <div className="admin-wrapper">
          <Row className="section-users-header">
            <Col sm={12}>
              <div className="header-panel">
                <h4>{t(`${tKey}TITLE`)}</h4>
              </div>
            </Col>
          </Row>
          <Row>
            <form autoComplete="off" onSubmit={handleSubmit(this.submit.bind(this))}>
              <Col sm={12} className="input-dropzone-wrapper">
                <Row className="form-group">
                  <Field
                    name="brandId"
                    id="brandId"
                    colSm={3}
                    controlLabel={t(`${tKey}BRAND`)}
                    options={brandCombo}
                    valueKey="id"
                    labelKey="value"
                    component={InputSelect}
                    menuPlacement="auto"
                  />
                </Row>
                <Row className="form-group">
                  {
                    <Col sm={12} className={'document-edition-modal-middle-side'}>
                      <div className={'input-dropzone'} style={{ height: '100%' }}>
                        {
                          <Field
                            key={'field'}
                            colSm={12}
                            id={'vehicleModelFile'}
                            name={'vehicleModelFile' }
                            component={InputDropzone}
                            onInvalidFile={(acceptFiles) => openModal('nonValidFile', null, null, null, null, [{ id: '##FORMAT##', value: acceptFiles }])}
                            t={t}
                            acceptFiles = {'.xlsx, .xls'}
                            maxSizeFile = {20971520}
                            style={{ height: '100%' }}
                            className={'active'}
                          />
                        }
                      </div>
                    </Col>
                  }
                </Row>
                <Row>
                  <Col sm={12}>
                    <div className="text-left">
                      <Button className="btn-standard" type="submit">{t(`${tKey}SAVE`)}</Button>
                    </div>
                  </Col>
                </Row>
              </Col>
            </form>
          </Row>
        </div>
      </React.Fragment>
    )
  }
}

const validate = (values, props) => {
  var error = {}
  if (!values.vehicleModelFile) {
    error.vehicleModelFile = props.t('MASTERS.VEHICLE_MODELS.VALIDATIONS.FILE_NOT_LOADED')
  }
  if (!values.brandId) {
    error.brandId = props.t('MASTERS.VEHICLE_MODELS.VALIDATIONS.BRAND_MANDATORY')
  }
  return error
}

export default reduxForm({
  form: 'ImportVehicleModelsMaster',
  validate
})(ImportVehicleModelsMaster)
