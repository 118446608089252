
import React, { PureComponent } from 'react'
import { Field } from 'redux-form'
import { Button, Modal, Col, Row } from 'react-bootstrap'
import InputMoney from '../../../commons/form/InputMoney'
import InputSelect from '../../../commons/form/InputSelect'
import { ivaTypeCodes } from '../../../../constants/dossier/common/taxes'

class EconomicPlanAmountWithTAXModal extends PureComponent {
  constructor (props) {
    super()
    this.state = {
      taxquantity: 0,
      amountWithoutTax: 0,
      amountWithTax: 0,
      originPristine: true
    }
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (!prevProps.showModal && this.props.showModal) {
      let pristineBeforeOpen = this.props.pristine
      let valueSearch
      this.props.actions.fetchAmountTaxModal(prevProps.valueCombo || this.props.valueCombo, null, this.props.pristine, this.props.dossierType)
      this.props.ivaTypeCombo.filter((value, indx) => {
        if ((this.props.valueCombo || prevProps.valueCombo) === value.id) {
          const ivaPercentage = this.props.ivaPercentage ?? prevProps.ivaPercentage
          valueSearch = (value.code === ivaTypeCodes.OTHERS_SVO ? (ivaPercentage / 100) : value.amountValue) + 1
        }
      })
      this.setState({ taxquantity: valueSearch, originPristine: pristineBeforeOpen })
    }
    return this.props
  }

  componentDidUpdate () {
  }

  handleBlurAmount (value) {
    let amountWithoutTax = (value / this.state.taxquantity).toFixed(2)
    this.props.actions.fetchAmountTaxModal(null, amountWithoutTax, this.state.originPristine, this.props.dossierType)
    this.setState({ amountWithoutTax: amountWithoutTax, amountWithTax: value })
  }

  saveWithoutTax () {
    this.props.actions.change('dossierEconomicComponent.modelPriceAmount', this.state.amountWithoutTax)
    this.props.actions.closeModal()
  }

  closeModalWithPristine () {
    this.props.actions.fetchAmountTaxModal(0, 0, this.state.originPristine, this.props.dossierType)
    this.props.actions.closeModal()
  }

  render () {
    const { t, showModal, ivaTypeCombo } = this.props
    const tKey = 'DOSSIER_COMPONENTS.ECONOMIC_PLAN.MODAL_TAXES.'
    return (
      <Modal show={showModal} onHide={() => { this.closeModalWithPristine() }}>
        <Modal.Header closeButton>
          <Modal.Title>{t(`${tKey}TITLE`)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Field
              id="amountWithTax"
              name="amountTaxModal.amountWithTax"
              colSm={6}
              controlLabel={t(`${tKey}AMOUNT_WITH_TAX`)}
              placeholder={t(`${tKey}AMOUNT_WITH_TAX`)}
              component={InputMoney}
              onInputBlur={(value) => this.handleBlurAmount(value)}
            />
            <Field
              id="amountWithoutTax"
              name="amountTaxModal.amountWithoutTax"
              colSm={6}
              controlLabel={t(`${tKey}AMOUNT_WITHOUT_TAX`)}
              placeholder={t(`${tKey}AMOUNT_WITHOUT_TAX`)}
              component={InputMoney}
            />
          </Row>
          <Row>
            <Field
              id="ivaType"
              name="amountTaxModal.ivaType"
              type="text"
              valueKey="id"
              labelKey="value"
              colSm={6}
              controlLabel={t(`${tKey}SELECTED_TAX`)}
              placeholder={t(`${tKey}SELECTED_TAX`)}
              options={ivaTypeCombo}
              component={InputSelect}
              disabled={true}
            />
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Col sm={6} className="btn-wrapper">
            <Button id="btn_save" className="btn-standard" onClick={() => { this.saveWithoutTax() }} >{t(`${tKey}SAVE_BTN`)}</Button>
          </Col>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default (EconomicPlanAmountWithTAXModal)
