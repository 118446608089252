import React, { PureComponent } from 'react'
import { Modal, Col, Button, Row, Form, Clearfix } from 'react-bootstrap'
import { chargeQuiterModalTypes } from '../../../constants/dossier/common/chargeQuiter'
import InputText from '../../../components/commons/form/InputText'
import { reduxForm, Field } from 'redux-form'

class ChargeQuiterModal extends PureComponent {
  selectChargeQuiterVehicle (values) {
    var dealerError = document.getElementById('dealerError')
    if (dealerError !== null && dealerError !== undefined) {
      dealerError.style.display = 'none'
    }
    switch (this.props.modalType) {
      case chargeQuiterModalTypes.getDealerNumber:
        var error = validate(values, this.props)
        if (error != null && (error.dealerNumber !== null && error.dealerNumber !== undefined)) {
          dealerError.style.display = 'block'
        } else {
          this.props.actions.getVehicleDataQuiter(values.dealerNumber, true)
        }
        break
      case chargeQuiterModalTypes.showDealerNumber:
        this.props.actions.getVehicleDataQuiter(this.props.dealerNumber, true)
        break
      case chargeQuiterModalTypes.showVehicleInfo:
        if (this.props.vehicleData) {
          this.props.actions.sendVehicleDataQuiter(this.props.vehicleData, this.props.saleType, this.props.dossierId)
        } else {
          this.closeModal()
        }
        break
      case chargeQuiterModalTypes.showResultInfo:
        this.closeModal()
        break
    }
  }

  closeModal () {
    if (this.props.modalType === chargeQuiterModalTypes.showResultInfo) {
      this.props.resetSale()
    }
    this.props.reset()
    this.props.actions.closeChargeModalDealer()
  }

  render () {
    const {
      t, showQuiterModal, modalType, dealerNumber, handleSubmit, vehicleData, sendResult
    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.CHARGE_QUITER_MODAL.'
    const tKeyV = 'DOSSIER_COMPONENTS.VEHICLE.'

    const className = modalType === chargeQuiterModalTypes.showVehicleInfo && vehicleData ? 'quiter-modal' : 'quiter-modal-nodata'
    return (
      <Modal className={className} show={showQuiterModal} onHide={() => this.closeModal()} >
        <Form autoComplete="off" onSubmit={handleSubmit(this.selectChargeQuiterVehicle.bind(this))}>
          <Modal.Header closeButton>
            <Modal.Title>{t(`${tKey}TITLE`)}</Modal.Title>

          </Modal.Header>
          <Modal.Body style={{ paddingLeft: '20px' }}>
            {modalType && modalType === chargeQuiterModalTypes.getDealerNumber && (
              <>
                <Row>
                  <Col>
                    <div className="text-band">
                      <span>{t(`${tKey}GET_DEALER_NUMBER`)}</span>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <div>
                      <Field
                        id="dealerNumber"
                        name="dealerNumber"
                        colSm={12}
                        placeholder={t(`${tKey}DEALER_NUMBER`)}
                        component={InputText}
                      />
                    </div>
                    <br/>
                    <label id="dealerError" className="dealer-error" >{t(`${tKey}DEALER_NUMBER_MANDATORY`)}</label>
                  </Col>
                </Row>
              </>
            )}
            {modalType && modalType === chargeQuiterModalTypes.showDealerNumber && (
              <>
                <Row>
                  <Col>
                    <div className="text-band">
                      <span>{t(`${tKey}SHOW_DEALER_NUMBER`)}</span>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div>
                      {t(`${tKey}DEALER_NUMBER`)} : <span>{dealerNumber}</span>
                    </div>
                  </Col>
                </Row>
              </>
            )}
            {modalType && modalType === chargeQuiterModalTypes.showVehicleInfo && vehicleData && (
              <>
                <span className="title-data"> {t(`${tKey}VEHICLE_DATA_QUITER`)} :</span>
                <Clearfix></Clearfix>

                <Row>
                  {vehicleData.id && (
                    <Col sm={4} className="colStyle">
                      <span className="spanBold">  {t(`${tKey}DEALER_NUMBER`)} :  </span> <br/>  <span className="spanStyle">{vehicleData.id}</span>
                    </Col>
                  )}
                  {vehicleData.plateNumber && (
                    <Col sm={4} className="colStyle">
                      <span className="spanBold">   {t(`${tKeyV}LICENSE_PLATE`)} :   </span> <br/>  <span className="spanStyle">{vehicleData.plateNumber}</span>
                    </Col>
                  )}
                  {vehicleData.vin && (
                    <Col sm={4} className="colStyle">
                      <span className="spanBold">    {t(`${tKeyV}CHASSIS`)} :   </span> <br/>  <span className="spanStyle">{vehicleData.vin}</span>
                    </Col>
                  )}
                  {vehicleData.plateNumberDate && (
                    <Col sm={4} className="colStyle">
                      <span className="spanBold">     {t(`${tKeyV}FIRST_LPLATE_DATE`)} :    </span> <br/>  <span className="spanStyle">{vehicleData.plateNumberDate}</span>
                    </Col>
                  )}
                  {vehicleData.modelName && (
                    <Col sm={4} className="colStyle">
                      <span className="spanBold"> {t(`${tKeyV}MODEL`)} :  </span> <br/>  <span className="spanStyle">{vehicleData.modelName}</span>
                    </Col>
                  )}
                  {vehicleData.modelCode && (
                    <Col sm={4} className="colStyle">
                      <span className="spanBold"> {t(`${tKey}MODEL_CODE`)} :  </span> <br/>  <span className="spanStyle">{vehicleData.modelCode}</span>
                    </Col>
                  )}
                  {vehicleData.brand && (
                    <Col sm={4} className="colStyle">
                      <span className="spanBold"> {t(`${tKeyV}BRAND_REFERENCE`)}  :   </span> <br/>  <span className="spanStyle">{vehicleData.brand}</span>
                    </Col>
                  )}
                  {vehicleData.colour && (
                    <Col sm={4} className="colStyle">
                      <span className="spanBold">  {t(`${tKeyV}COLOR`)} :  </span> <br/>  <span className="spanStyle">{vehicleData.colour}</span>
                    </Col>
                  )}
                  {vehicleData.upholstery && (
                    <Col sm={4} className="colStyle">
                      <span className="spanBold">   {t(`${tKeyV}TAPESTRY`)} :  </span> <br/>  <span className="spanStyle">{vehicleData.upholstery}</span>
                    </Col>
                  )}
                  {vehicleData.modelYear && (
                    <Col sm={4} className="colStyle">
                      <span className="spanBold">  {t(`${tKey}MODEL_YEAR`)} :  </span> <br/>  <span className="spanStyle">{vehicleData.modelYear}</span>
                    </Col>
                  )}
                  {vehicleData.mileage && (
                    <Col sm={4} className="colStyle">
                      <span className="spanBold">   {t(`${tKey}KM`)} :  </span> <br/>  <span className="spanStyle">{vehicleData.mileage}</span>
                    </Col>
                  )}
                  {vehicleData.fuelType && (
                    <Col sm={4} className="colStyle">
                      <span className="spanBold">   {t(`${tKeyV}FUEL`)} :  </span> <br/>  <span className="spanStyle">{vehicleData.fuelType}</span>
                    </Col>
                  )}
                  {vehicleData.productionDate && (
                    <Col sm={4} className="colStyle">
                      <span className="spanBold">   {t(`${tKey}PRODUCTION_DATE`)} :  </span> <br/>  <span className="spanStyle">{vehicleData.productionDate}</span>
                    </Col>
                  )}
                  {vehicleData.amortizationAmount && (
                    <Col sm={4} className="colStyle">
                      <span className="spanBold">    {t(`${tKey}AMORTIZATION_AMOUNT`)} :  </span> <br/>  <span className="spanStyle">{vehicleData.amortizationAmount}</span>
                    </Col>
                  )}
                  {vehicleData.reconditioningCost && (
                    <Col sm={4} className="colStyle">
                      <span className="spanBold">{t(`${tKey}RECONDITIONING_COST`)} :  </span> <br/>  <span className="spanStyle">{vehicleData.reconditioningCost}</span>
                    </Col>
                  )}
                  {vehicleData.invoiceNumber && (
                    <Col sm={4} className="colStyle">
                      <span className="spanBold">  {t(`${tKey}INVOICE_NUMBER`)} :  </span> <br/>  <span className="spanStyle">{vehicleData.invoiceNumber}</span>
                    </Col>
                  )}
                  {vehicleData.invoiceDate && (
                    <Col sm={4} className="colStyle">
                      <span className="spanBold">  {t(`${tKey}INVOICE_DATE`)} :  </span> <br/>  <span className="spanStyle">{vehicleData.invoiceDate}</span>
                    </Col>
                  )}
                  {vehicleData.provider && (
                    <Col sm={4} className="colStyle">
                      <span className="spanBold">    {t(`${tKey}PROVIDER`)} :</span> <br/> <span className="spanStyle">{vehicleData.provider}</span>
                    </Col>
                  )}
                  {vehicleData.location && (
                    <Col sm={4} className="colStyle">
                      <span className="spanBold">{t(`${tKey}LOCATION`)} :  </span> <br/>  <span className="spanStyle">{vehicleData.location}</span>
                    </Col>
                  )}
                  {vehicleData.vehicleTypeId && (
                    <Col sm={4} className="colStyle">
                      <span className="spanBold">   {t(`${tKey}VEHICLE_TYPE_ID`)} :  </span> <br/>  <span className="spanStyle">{vehicleData.vehicleTypeId}</span>
                    </Col>
                  )}
                  {vehicleData.arrivalDate && (
                    <Col sm={4} className="colStyle">
                      <span className="spanBold">   {t(`${tKey}ARRIVAL_DATE`)} :  </span> <br/>  <span className="spanStyle">{vehicleData.arrivalDate}</span>
                    </Col>
                  )}
                  {vehicleData.voucherOrder && (
                    <Col sm={4} className="colStyle">
                      <span className="spanBold">  {t(`${tKeyV}ORDER_NUMBER`)} :  </span> <br/>  <span className="spanStyle">{vehicleData.voucherOrder}</span>
                    </Col>
                  )}
                  {vehicleData.salePriceDealer && (
                    <Col sm={4} className="colStyle">
                      <span className="spanBold">  {t(`${tKey}SALE_PRICE_DEALER`)} :  </span> <br/>  <span className="spanStyle">{vehicleData.salePriceDealer}</span>
                    </Col>
                  )}
                  {vehicleData.purchasePriceDealer && (
                    <Col sm={4} className="colStyle">
                      <span className="spanBold">  {t(`${tKey}PURCHASE_PRICE_DEALER`)} :  </span> <br/>  <span className="spanStyle">{vehicleData.purchasePriceDealer}</span>
                    </Col>
                  )}
                  {vehicleData.purchaseDealerVN && (
                    <Col sm={4} className="colStyle">
                      <span className="spanBold">  {t(`${tKey}ORGANIZED_UNIT`)} :  </span> <br/>  <span className="spanStyle">{vehicleData.purchaseDealerVN}</span>
                    </Col>
                  )}
                  {vehicleData.purchaseDealerVO && !vehicleData.purchaseDealerVN && (
                    <Col sm={4} className="colStyle">
                      <span className="spanBold">  {t(`${tKey}ORGANIZED_UNIT`)} :  </span> <br/>  <span className="spanStyle">{vehicleData.purchaseDealerVO}</span>
                    </Col>
                  )}
                  {vehicleData.VOType && (
                    <Col sm={4} className="colStyle">
                      <span className="spanBold">  {t(`${tKey}VOTTYPE`)} :  </span> <br/>  <span className="spanStyle">{vehicleData.VOType}</span>
                    </Col>
                  )}

                </Row>

                { vehicleData.customer && (
                  <>
                    <span className="title-data"> {t(`${tKey}CLIENT_DATA_QUITER`)} :</span>
                    <Clearfix></Clearfix>

                    <Row>
                      {vehicleData.customer.fullName && (
                        <Col sm={4} className="colStyle">
                          <span className="spanBold">  {t(`${tKey}FULL_NAME`)} :  </span> <br/>  <span className="spanStyle">{vehicleData.customer.fullName}</span>
                        </Col>
                      )}

                      {vehicleData.customer.tradeName && (
                        <Col sm={4} className="colStyle">
                          <span className="spanBold">  {t(`${tKey}TRADE_NAME`)} :  </span> <br/>  <span className="spanStyle">{vehicleData.customer.tradeName}</span>
                        </Col>
                      )}

                      {vehicleData.customer.documentId && (
                        <Col sm={4} className="colStyle">
                          <span className="spanBold">  {t(`${tKey}DOCUMENT`)} :  </span> <br/>  <span className="spanStyle">{vehicleData.customer.documentId}</span>
                        </Col>
                      )}

                      {vehicleData.customer.email && (
                        <Col sm={4} className="colStyle">
                          <span className="spanBold">  {t(`${tKey}EMAIL`)} :  </span> <br/>  <span className="spanStyle">{vehicleData.customer.email}</span>
                        </Col>
                      )}

                      {vehicleData.customer.phoneNumber && vehicleData.customer.phoneNumber.length > 0 && (
                        <Col sm={4} className="colStyle">
                          <span className="spanBold">  {t(`${tKey}PHONES`)} :  </span> <br/>
                          {vehicleData.customer.phoneNumber.map((t, index) => {
                            return <span key={index + 'spanphone'} className="spanStyle">{t} {((index + 1) % 2 === 0) ? <br/> : '' } </span>
                          }) }
                        </Col>
                      )}

                      {vehicleData.customer.address && (
                        <Col sm={4} className="colStyle">
                          <span className="spanBold">  {t(`${tKey}ADDRESS`)} :  </span> <br/>  <span className="spanStyle">{vehicleData.customer.address} </span>
                        </Col>
                      )}

                      {vehicleData.customer.fiscalAddress && (
                        <Col sm={4} className="colStyle">
                          <span className="spanBold">  {t(`${tKey}FISCAL_ADDRESS`)} :  </span> <br/>  <span className="spanStyle">{vehicleData.customer.fiscalAddress}</span>
                        </Col>
                      )}

                      {vehicleData.customer.postalCode && (
                        <Col sm={4} className="colStyle">
                          <span className="spanBold">  {t(`${tKey}POSTAL_CODE`)} :  </span> <br/>  <span className="spanStyle">{vehicleData.customer.postalCode}</span>
                        </Col>
                      )}

                      {vehicleData.customer.city && (
                        <Col sm={4} className="colStyle">
                          <span className="spanBold">  {t(`${tKey}CITY`)} :  </span> <br/>  <span className="spanStyle">{vehicleData.customer.city}</span>
                        </Col>
                      )}

                      {vehicleData.customer.fiscalCity && (
                        <Col sm={4} className="colStyle">
                          <span className="spanBold">  {t(`${tKey}FISCAL_CITY`)} :  </span> <br/>  <span className="spanStyle">{vehicleData.customer.fiscalCity}</span>
                        </Col>
                      )}

                      {vehicleData.customer.country && (
                        <Col sm={4} className="colStyle">
                          <span className="spanBold">  {t(`${tKey}COUNTRY`)} :  </span> <br/>  <span className="spanStyle">{vehicleData.customer.country}</span>
                        </Col>
                      )}
                    </Row>

                  </>
                )}
              </>
            )}
            {modalType && modalType === chargeQuiterModalTypes.showVehicleInfo && (vehicleData == null || vehicleData === undefined) && (
              <span className="spanBold">  {t(`${tKey}NOT_QUITER_DATA`)}  </span>
            )}
            {modalType && modalType === chargeQuiterModalTypes.showResultInfo && (
              <>
                {sendResult && (
                  <>
                    <span className="title-data"> {t(`${tKey}QUITER_RESULT`)} : <br/><br/></span>

                    {sendResult.created &&
                        <span className="span-result">* {t(`${tKey}DOSSIER_CREATED`)}  <br/><br/></span>
                    }
                    {sendResult.updated &&
                        <span className="span-result">* {t(`${tKey}DOSSIER_UPDATED`)}  <br/><br/></span>
                    }
                    {sendResult.dossierNumber &&
                        <span className="span-result">* {t(`${tKey}DOSSIER_NUMBER`)} : {sendResult.dossierNumber}  <br/><br/></span>
                    }
                    {sendResult.dossierId &&
                        <span className="span-result">* {t(`${tKey}DOSSIER_ID`)} : {sendResult.dossierId}  <br/><br/></span>
                    }
                    {sendResult.message &&
                       <span className="span-result">* {t(`${tKey}DOSSIER_MESSAGE`)} : {sendResult.message}  <br/><br/></span>
                    }
                  </>
                )}

              </>
            )}
            { modalType && modalType === chargeQuiterModalTypes.showResultInfo && (sendResult == null || sendResult === undefined) && (
              <span>{t(`${tKey}STOCK_LOADER_QUITER_ERROR_CHARGE`)}<br/></span>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Row>
              <Col sm={4} smOffset={6} className="btn-wrapper">
                <Button id="btn_save_quiter" className="btn-standard" type="submit" >
                  <span>{t(`${tKey}OK`)}</span>
                </Button>
              </Col>
            </Row>
          </Modal.Footer>
        </Form>
      </Modal>
    )
  }
}

const validate = (values, props) => {
  const error = {}
  if (props.modalType === chargeQuiterModalTypes.getDealerNumber) {
    if (values == null || values.dealerNumber == null || values.dealerNumber === undefined) {
      error.dealerNumber = props.t('DOSSIER_COMPONENTS.CHARGE_QUITER_MODAL.DEALER_NUMBER_MANDATORY')
    }
  }

  return error
}

export default reduxForm({
  form: 'ChargeQuiterModal',
  validate
})(ChargeQuiterModal)
