import buttonsSidebarActionTypes from '../../../constants/actions/dossier/common/buttonsSidebar'

export function initialState () {
  return {
    activeSection: false
  }
}

function setActiveSectionSuccess (state, { activeSection }) {
  return {
    ...state,
    activeSection
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case buttonsSidebarActionTypes.SET_ACTIVE_SECTION:
      return setActiveSectionSuccess(state, action)
    default:
      return state
  }
}
