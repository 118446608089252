import mastersActionTypes from '../../../constants/actions/masters/masters'

export function fetchOrganizedUnitSaleOperationList (filter) {
  return {
    type: mastersActionTypes.FETCH_ORGANIZED_UNIT_SALE_OPERATION_LIST,
    filter
  }
}

export function fetchOrganizedUnitSaleOperationListSuccess (organizedUnitSaleOperationList, filter) {
  return {
    type: mastersActionTypes.FETCH_ORGANIZED_UNIT_SALE_OPERATION_LIST_SUCCESS,
    organizedUnitSaleOperationList,
    filter
  }
}

export function deleteOrganizedUnitSaleOperation (organizedUnitSaleOperationId) {
  return {
    type: mastersActionTypes.DELETE_ORGANIZED_UNIT_SALE_OPERATION,
    organizedUnitSaleOperationId
  }
}

export function openOrganizedUnitSaleOperationModal (organizedUnitSaleOperation) {
  return {
    type: mastersActionTypes.OPEN_ORGANIZED_UNIT_SALE_OPERATION_MODAL,
    organizedUnitSaleOperation
  }
}

export function openOrganizedUnitSaleOperationModalSuccess (organizedUnitSaleOperation) {
  return {
    type: mastersActionTypes.OPEN_ADD_ORGANIZED_UNIT_SALE_OPERATION_MODAL_SUCCESS,
    organizedUnitSaleOperation
  }
}

export function openEditOrganizedUnitSaleOperationModalSuccess (organizedUnitSaleOperationId) {
  return {
    type: mastersActionTypes.OPEN_EDIT_ORGANIZED_UNIT_SALE_OPERATION_MODAL_SUCCESS,
    organizedUnitSaleOperationId
  }
}

export function closeOrganizedUnitSaleOperationMasterModal () {
  return {
    type: mastersActionTypes.CLOSE_EDIT_ORGANIZED_UNIT_SALE_OPERATION_MODAL
  }
}

export function submitOrganizedUnitSaleOperation (organizedUnitSaleOperation) {
  return {
    type: mastersActionTypes.SUBMIT_ORGANIZED_UNIT_SALE_OPERATION,
    organizedUnitSaleOperation
  }
}
