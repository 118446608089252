import dossierActionsTypes from '../../constants/actions/dossier/dossier'
import { put, call, select, takeLatest } from 'redux-saga/effects'
import { showLoader, hideLoader } from '../../actions/common'
import { handleError } from '../errors/errorManager'
import { fetchCustomFieldsSuccess } from '../../actions/dossier/customFields'
import { getAuth } from '../../selectors/access/auth'
import getCustomFields from '../../services/customFields/getCustomFields'

export function * fetchCustomFields ({ dossierSubTypeId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const customFields = yield call(getCustomFields, auth.token, dossierSubTypeId)
    yield put(fetchCustomFieldsSuccess(customFields))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchCustomFields () {
  yield takeLatest(dossierActionsTypes.FETCH_CUSTOM_FIELDS, fetchCustomFields)
}
