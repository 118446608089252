import { call, put, select, takeLatest } from 'redux-saga/effects'
import { getAuth } from '../../selectors/access/auth'
import getActivitiesBpro from '../../services/entity/getActivitiesBpro'

import commonActions from '../../actions/common'
import messageModalActions from '../../actions/modals/messageModal'
import { fetchCombosSuccess } from '../../actions/combos/combos'
import combosActionTypes from '../../constants/actions/combos/combos'
import { handleError } from '../errors/errorManager'

export function * fecthActivitiesBpro ({ activityType }) {
  try {
    yield put(commonActions.showLoader())
    const auth = yield select(getAuth)
    const combo = yield call(getActivitiesBpro, auth.token, activityType)
    yield put(fetchCombosSuccess({ activityBproCombo: combo }))
  } catch (error) {
    const customHandler = function * () {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.FETCH_COMBO_ERROR'))
    }
    yield call(handleError, { error, customHandler })
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchFecthActivitiesBpro () {
  yield takeLatest(combosActionTypes.FETCH_ACTIVITY_BPRO_ALL, fecthActivitiesBpro)
}
