import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Sign from '../../components/sign/Sign'
import { translate } from 'react-polyglot'
import { searchDocuments } from '../../actions/sign/sign'
import { openDocumentEditionModal } from '../../actions/dossier/common/documentEditionModal'
import { openDocumentEditionModal as openDocumentEditionModalHistorical } from '../../actions/dossier/common/documentHistoricalEditionModal'
import { fetchDocumentSearchList } from '../../actions/search/documentalSearch/documentalSearchForm'
import TrackingContainer from '../../containers/tracking/trackingContainer'
export function mapStateToProps (state) {
  return {
    ...state.sign,
    sectionsGeneral: state.auth.sections,
    section: state.common.section
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      searchDocuments,
      openDocumentEditionModal,
      openDocumentEditionModalHistorical,
      fetchDocumentSearchList
    }, dispatch)
  }
}

var decoratedComponent = TrackingContainer(Sign, true)
export default connect(mapStateToProps, mapDispatchToProps)(translate()(decoratedComponent))
