import React, { PureComponent } from 'react'
import { Row, Col } from 'react-bootstrap'

class Tab1 extends PureComponent {
  render () {
    return (
      <Col sm={12} className="tab1-wrapper">
        <Row>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Gestor:</span>
              <span className="second number-modal">Nombre del gestor</span>
            </div>
          </Col>
          <Col sm={3}>
            <div className="text-band">
              <span className="first"> Empresa:</span>
              <span className="second number-modal">Empresa</span>
            </div>
          </Col>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Ubicación/Sucursal:</span>
              <span className="second number-modal">Ubicación/Sucursal</span>
            </div>
          </Col>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Fecha/Hora Pedido</span>
              <span className="second number-modal">Fecha/Hora</span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Nº Expediente:</span>
              <span className="second number-modal">EXP123976826153E</span>
            </div>
          </Col>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Nº expediente relacionado:</span>
              <span className="second number-modal">Nº expediente relacionado:</span>
            </div>
          </Col>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Estado del expediente:</span>
              <span className="second number-modal">Estado del expediente</span>
            </div>
          </Col>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Estado funcional:</span>
              <span className="second number-modal">Estado funcional</span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Estado documental:</span>
              <span className="second number-modal">Estado documental</span>
            </div>
          </Col>
        </Row>
      </Col>
    )
  }
}

export default Tab1
