import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import ManageDossier from '../../../components/dossiermanagement/purchasesVNDossier/purchaseVNDossier'
import { translate } from 'react-polyglot'
// import { push as navigateTo } from 'react-router-redux'

export function mapStateToProps (state) {
  return {
    ...state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      /* navigateTo */
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(ManageDossier))
