import apiFetch from '../apiFetch'

export default function (organizedUnitId, documentTypeId, token, file, id, checklistId) {
  const queryParamsArray = [
    organizedUnitId ? `organizedUnitId=${organizedUnitId}` : null,
    documentTypeId ? `documentTypeId=${documentTypeId}` : null,
    id ? `id=${id}` : null,
    checklistId ? `checklistId=${checklistId}` : null
  ]
  const formData = new FormData()
  formData.append(file.name, file)
  const queryParams = queryParamsArray.reduce(
    (result, query, idx) => result + (query ? (result ? `&${query}` : `?${query}`) : '')
    , ''
  )
  return apiFetch({ endPoint: `documentHistorical${queryParams}`, method: 'POST', body: formData, token: token, fileBody: true })
}
