import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { fetchProcessingAgencyOrganizedUnitConfigList, showProcessingAgencyOrganizedUnitConfigModal, deleteProcessingAgencyOrganizedUnitConfig } from '../../../actions/masters/processingAgencyOrganizedUnitConfig/processingAgencyOrganizedUnitConfig'
import ProcessingAgencyOrganizedUnitConfigMaster from '../../../components/masters/processingAgencyOrganizedUnitConfig/ProcessingAgencyOrganizedUnitConfigMaster'

export function mapStateToProps (state) {
  return {
    ...state.processingAgencyOrganizedUnitConfig,
    combos: {
      organizedUnitsAllCombo: state.combos.organizedUnitsAllCombo,
      processingAgencyCommunicationServices: state.combos.processingAgencyCommunicationServices
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchProcessingAgencyOrganizedUnitConfigList,
      showProcessingAgencyOrganizedUnitConfigModal,
      deleteProcessingAgencyOrganizedUnitConfig
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(ProcessingAgencyOrganizedUnitConfigMaster))
