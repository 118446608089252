import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import FaqSectionMaster from '../../../components/masters/faqSection/FaqSectionMaster'
import { fetchFaqSectionTable, fetchFaqSection, deleteFaqSection, recoverFaqSection } from '../../../actions/masters/faqSection/faqSection'

const mapStateToProps = state => {
  return {
    ...state.faqSectionMaster,
    combos: {
      UOTreeCombo: state.combos.UOTreeCombo
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchFaqSectionTable,
      fetchFaqSection,
      deleteFaqSection,
      recoverFaqSection
    }, dispatch)

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(FaqSectionMaster))
