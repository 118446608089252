import React, { PureComponent } from 'react'
import { reduxForm } from 'redux-form'
import { Row, Col, Table, Tooltip, OverlayTrigger, Button } from 'react-bootstrap'
import ProcessingAgencyAssignationModal from '../../../containers/masters/ProcessingAgencyAssignation/ProcessingAgencyAssignationModalPage'
import IDocCarPagination from '../../commons/iDocCarPagination'
import SimpleTreeSelect from '../../commons/simpleForm/SimpleTreeSelect'

class ProcessingAgencyAssignation extends PureComponent {
  constructor () {
    super()
    this.state = {
      uoId: null,
      filter: {
        searchUO: [],
        searchName: null
      }
    }
  }

  getSortIcon (column = '') {
    switch (this.props.filter.orderBy) {
      case '+' + column:
        return 'ico-sort-selected-asc'
      case '-' + column:
        return 'ico-sort-selected-desc'
      default:
        return 'ico-sort'
    }
  }

  changeOrder (field) {
    switch (this.props.filter.orderBy) {
      case `+${field}`:
        this.props.actions.fetchProcessingAgencyQuotaList({ ...this.state.filter, ...this.props.filter, orderBy: `-${field}` })
        break
      default:
        this.props.actions.fetchProcessingAgencyQuotaList({ ...this.state.filter, ...this.props.filter, orderBy: `+${field}` })
        break
    }
  }

  handleOrganizedUnitChange (searchUO) {
    if (!searchUO || searchUO.length === 0) {
      this.setState({ filter: { ...this.state.filter, searchUO: null } }, () => this.searchProcessingAgency())
      return
    }
    let uos = []
    uos.push(searchUO)
    this.setState({ filter: { ...this.state.filter, searchUO: uos }, uoId: uos }, () => this.searchProcessingAgency())
  }

  handleNameChange (searchName) {
    if (this.state.filter.searchName !== searchName) {
      this.setState({ filter: { ...this.state.filter, searchName } })
    }
  }

  searchProcessingAgency () {
    this.props.actions.fetchProcessingAgencyQuotaList({ ...this.props.filter, ...this.state.filter, page: 1 })
  }

  handleAddQuota () {
    if (this.state.filter.searchUO.length > 0) {
      this.props.actions.openAddProcessingAgencyAssignationModalSuccess(this.state.filter.searchUO[0])
    }
  }

  render () {
    const {
      t, quotaList, filter, pagesCount, quotasCount,
      filter: { page },
      combos: { UOTreeCombo },
      actions: { openEditProcessingAgencyAssignationModalSuccess, fetchProcessingAgencyQuotaList, deleteProcessingAgencyQuota }
    } = this.props
    const tKey = 'MASTERS.PROCESSING_AGENCY_ASSIGNATION_QUOTA_MASTERS.'
    const editTooltip = (<Tooltip id="editTooltip">{t(`${tKey}EDIT`)}</Tooltip>)
    const deleteTooltip = (<Tooltip id="deleteTooltip">{t(`${tKey}DELETE`)}</Tooltip>)
    return (
      <div className="admin-wrapper">
        <ProcessingAgencyAssignationModal/>
        <Row className="section-users-header">
          <Col sm={12}>
            <div className="header-panel">
              <h4>{t(`${tKey}PROCESSING_AGENCY`)}</h4>
            </div>
          </Col>
        </Row>
        <Row>
          <div className="search-form">
            <span className="title-search"><i className="ico-search" /> {t(`${tKey}SEARCH_TITLE`)}</span>
            <Row className='dashboard-select'>
              <SimpleTreeSelect
                colSm={12}
                options={UOTreeCombo}
                placeholder={t(`${tKey}ORGANIZED_UNIT`)}
                searchPlaceholder={t(`${tKey}ORGANIZED_UNIT`)}
                value={this.state.filter.searchUO ? this.state.filter.searchUO.toString() : null}
                onInputChange={(value) => this.handleOrganizedUnitChange(value)}
              />
            </Row>
          </div>
        </Row>
        <Col sm={12} className="col-row  section-users-body">
          <Table className="editable-table" hover responsive>
            <thead>
              <tr>
                <th onClick={() => { this.changeOrder('code') }}>
                  {t(`${tKey}ORGANIZED_UNIT`)}
                  <i className={this.getSortIcon('code')} />
                </th>
                <th onClick={() => { this.changeOrder('startDate') }}>
                  {t(`${tKey}START_DATE`)}
                  <i className={this.getSortIcon('startDate')} />
                </th>
                <th onClick={() => { this.changeOrder('endDate') }}>
                  {t(`${tKey}END_DATE`)}
                  <i className={this.getSortIcon('endDate')} />
                </th>
                <th style={{ width: '77px' }}> {t(`${tKey}ACTIONS`)}</th>
              </tr>
            </thead>
            <tbody>
              {quotaList && quotaList.processingAgenciesQuotaGrouped && quotaList.processingAgenciesQuotaGrouped.map((agency, idx) => {
                return (
                  <tr key={idx}>
                    <td>{agency.organizedUnitName}</td>
                    <td>{agency.startDate}</td>
                    <td>{agency.endDate}</td>
                    <td>
                      <div>
                        <a onClick={() => openEditProcessingAgencyAssignationModalSuccess(agency, idx)}>
                          <OverlayTrigger placement="left" overlay={editTooltip}>
                            <i className="ico-edit-white"/>
                          </OverlayTrigger>
                        </a>
                        <a onClick={() => deleteProcessingAgencyQuota(agency)}>
                          <OverlayTrigger placement="left" overlay={deleteTooltip}>
                            <i className="ico-trash"/>
                          </OverlayTrigger>
                        </a>
                      </div>
                    </td>
                  </tr>)
              }
              )}
              {(!quotaList && quotaList.processingAgenciesQuotaGrouped === null || quotaList.processingAgenciesQuotaGrouped === undefined || quotaList.processingAgenciesQuotaGrouped.length === 0) &&
                    <tr>
                      <td colSpan={4}><h2>{t(`${tKey}RESULTS_NOT_FOUND`)}</h2></td>
                    </tr>}
              {(quotaList && quotaList.processingAgencies && quotaList.processingAgencies.length > 0) &&
              <tr className="button-table-wrapper">
                <td>
                  <Button
                    className="btn-editable-table btn-standard"
                    onClick={() => this.handleAddQuota()}>
                    {t(`${tKey}ADD_BUTTON`)}
                  </Button>
                </td>
              </tr>
              }
            </tbody>
          </Table>
          <div className="section-users-footer">
            <nav key={1} className="text-center">
              <IDocCarPagination
                id="btn_pag_sales"
                page={page}
                pagesCount={pagesCount}
                totalRows={quotasCount}
                onSelectAction={(value) => fetchProcessingAgencyQuotaList({ ...filter, ...this.state.filter, page: value })}
              />
            </nav>
          </div>
        </Col>
      </div>
    )
  }
}

export default reduxForm({
  form: 'listAgenciesAssignation'
})(ProcessingAgencyAssignation)
