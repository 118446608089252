export default {
  FETCH_UNIT: 'FETCH_UNIT',
  FETCH_UNIT_SUCCESS: 'FETCH_UNIT_SUCCESS',
  INITIALIZE_UNIT_PAGE: 'INITIALIZE_UNIT_PAGE',
  CHANGE_UNIT_FILTER: 'CHANGE_UNIT_FILTER',
  CHANGE_FILTER_STRING: 'CHANGE_FILTER_STRING',
  UPDATE_FILTER: 'UPDATE_FILTER',
  OPEN_UNIT_MODAL: 'OPEN_UNIT_MODAL',
  CLOSE_UNIT_MODAL: 'CLOSE_UNIT_MODAL',
  SAVE_NEW_UNIT: 'SAVE_NEW_UNIT',
  DELETE_UNIT: 'DELETE_UNIT'
}
