import settings from '../setting'
export default ({ endPoint, method, body, token, lastAccessDate, fileBody, dealerNumber, parseBlobAndAssign = false, onlyOneRequestAllowed = false, isHotFolder = false }) =>

  new Promise((resolve, reject) => {
    const myHeaders = {}
    let url = settings.url

    if (!fileBody) {
      myHeaders.Accept = 'application/json'
      myHeaders['Content-Type'] = 'application/json; charset=utf-8'
    }

    if (token) {
      myHeaders.token = token
    }

    if (lastAccessDate) {
      myHeaders.lastAccessDate = lastAccessDate
    }

    const myInit = {
      method: method,
      headers: myHeaders
    }
    if (body) {
      myInit.body = fileBody ? body : JSON.stringify(body)
    }

    if (isHotFolder) {
      url = settings.urlHotFolder
    }

    if (dealerNumber) {
      myHeaders.dealerNumber = dealerNumber
    }

    if (onlyOneRequestAllowed && parseBlobAndAssign) {
      window.location.assign(url + endPoint)
      resolve()
    } else {
      fetch(url + endPoint, myInit)
        .then(response => {
          if (response.ok) {
            if (response.status === 204 || response.status === 201) {
              resolve()
            } else if (parseBlobAndAssign) {
              let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
              let matches = filenameRegex.exec(response.headers.get('content-disposition'))
              let filename = ''
              if (matches != null && matches[1]) {
                filename = matches[1].replace(/['"]/g, '')
              }
              response.blob().then(blob => {
                let url = window.URL.createObjectURL(blob)
                let a = document.createElement('a')
                a.href = url
                if (filename && filename !== '') {
                  a.download = filename
                }
                a.click()
              })
              resolve()
            } else {
              response.json().then(resolve).catch(reject)
            }
          } else {
            response.json().then(errJson => {
              console.log(errJson)
              return reject({ status: response.status, json: errJson })
            }).catch(reject)
          }
        }).catch(reject)
    }
  })
