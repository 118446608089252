
import React, { PureComponent } from 'react'
import { Modal, Col, Row, Button } from 'react-bootstrap'
import { reduxForm, Field } from 'redux-form'
import InputText from '../../commons/form/InputText'
import InputSelect from '../../commons/form/InputSelect'

class ProcessingAgencyOrganizedUnitConfigMasterModal extends PureComponent {
  constructor () {
    super()
    this.state = {}
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevProps.showModal !== this.props.showModal && this.props.showModal === true) {
      this.props.initialize(this.props.processingAgencyOrganizedUnitConfig)
    }
  }

  submitProcessingAgencyOrganizedUnitConfig (values) {
    const processingAgencyOrganizedUnitConfig = {
      ProcessingAgencyOrganizedUnitConfigId: this.props.processingAgencyOrganizedUnitConfig?.processingAgencyOrganizedUnitConfigId,
      OrganizedUnitId: values.organizedUnitId,
      User: values.user,
      Password: values.password,
      ProcessingAgencyCommunicationServiceId: this.props.processAgencyCommunicationServiceId
    }

    this.props.actions.submitProcessingAgencyOrganizedUnitConfig(processingAgencyOrganizedUnitConfig, this.props.processAgencyCommunicationServiceId)
  }

  render () {
    const {
      t, handleSubmit,
      showModal,
      combos: { organizedUnitsAllCombo }
    } = this.props
    const tKey = 'MASTERS.PROCESSING_AGENCY_ORGANIZED_UNIT_CONFIG.MODAL.'
    return (
      <Modal className="role-modal languages-modal" show={showModal} onHide={() => this.props.actions.showProcessingAgencyOrganizedUnitConfigModal(false)} backdrop={'static'}>
        <form autoComplete="off" onSubmit={handleSubmit(this.submitProcessingAgencyOrganizedUnitConfig.bind(this))}>
          <Modal.Header closeButton onHide={() => this.props.actions.showProcessingAgencyOrganizedUnitConfigModal(false)}>
            <Modal.Title>{t(`${tKey}TITLE`)}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="inputs-wrapper-no-border">
              <Col sm={6} className="custom-width-col">
                <h2>{t(`${tKey}USER`)}</h2>
                <Field
                  name="user"
                  placeholder={t(`${tKey}USER`)}
                  component={InputText}
                />
              </Col>
              <Col sm={6}>
                <h2>{t(`${tKey}PASSWORD`)}</h2>
                <Field
                  name="password"
                  placeholder={t(`${tKey}PASSWORD`)}
                  component={InputText}
                  required={[true]}
                  maxLength={50}
                  colSm={0}
                />
              </Col>
            </Row>
            <Row className="inputs-wrapper">
              <Col sm={6} className="custom-width-col">
                <h2>{t(`${tKey}ORGANIZED_UNIT`)}</h2>
                <Field
                  name="organizedUnitId"
                  placeholder={t(`${tKey}ORGANIZED_UNIT`)}
                  valueKey="id"
                  labelKey="value"
                  component={InputSelect}
                  colSm={0}
                  options={organizedUnitsAllCombo}
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Col sm={12} className="btn-wrapper">
              <Button
                id="role-modal-submit-button"
                className="btn-standard"
                type="submit"
                disabled={this.props.pristine}>
                {t(`${tKey}SUBMIT_BUTTON`)}
              </Button>
            </Col>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default reduxForm({
  form: 'editProcessingAgencyOrganizedUnitConfig',
  validate: (values, props) => {
    let error = {}
    const tKey = 'MASTERS.PROCESSING_AGENCY_ORGANIZED_UNIT_CONFIG.VALIDATION.'
    if (!props.processingAgencyOrganizedUnitConfig?.processingAgencyOrganizedUnitConfigId && props.OUsAssigned.find(x => x === values.organizedUnitId)) {
      error.organizedUnitId = props.t(`${tKey}OU`)
    }
    if (!values.user) {
      error.user = props.t(`${tKey}USER`)
    }
    if (!values.password) {
      error.password = props.t(`${tKey}PASSWORD`)
    }
    return Object.keys(error).length > 0 ? error : {}
  }
})(ProcessingAgencyOrganizedUnitConfigMasterModal)
