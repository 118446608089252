import React, { PureComponent } from 'react'
import { Modal, Row } from 'react-bootstrap'
import settings from '../../../../../setting'

class ModalDocumentFullScreen extends PureComponent {
  render () {
    const { actions: { toggleFullScreenDocumentModal }, docResult: { documentId }, showFullScreenDocumentModalUrl, token } = this.props
    return (
      <Modal className="modal-document fullscreen" show={!!showFullScreenDocumentModalUrl}>
        <Modal.Body>
          <i id="closeFullScreen" onClick={() => toggleFullScreenDocumentModal(false)} className="ico-cancel" />
          <Row>
            <img className="img-full-width" src={`${settings.url}document/${documentId}/preview?token=${token}`}/>
            <iframe src={showFullScreenDocumentModalUrl}/>
          </Row>
        </Modal.Body>
      </Modal>
    )
  }
}

export default ModalDocumentFullScreen
