import { call, put, select, takeLatest } from 'redux-saga/effects'
import { getAuth } from '../../selectors/access/auth'
import getServiceDestinationTypes from '../../services/serviceDestination/getServiceDestinationTypes'
import commonActions from '../../actions/common'
import messageModalActions from '../../actions/modals/messageModal'
import { fetchCombosSuccess } from '../../actions/combos/combos'
import combosActionTypes from '../../constants/actions/combos/combos'
import { handleError } from '../errors/errorManager'

export function * fetchServiceDestinationTypes ({ includeDeletedValues, includeValue }) {
  try {
    yield put(commonActions.showLoader())
    const auth = yield select(getAuth)
    const combo = yield call(getServiceDestinationTypes, includeDeletedValues, includeValue, auth.token)
    yield put(fetchCombosSuccess({ serviceDestinationCombo: combo }))
  } catch (error) {
    const customHandler = function * () {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.FETCH_COMBO_ERROR'))
    }
    yield call(handleError, { error, customHandler })
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchFetchServiceDestinationTypes () {
  yield takeLatest(combosActionTypes.FETCH_SERVICE_DESTINATION_TYPES_COMBO, fetchServiceDestinationTypes)
}
