import React, { PureComponent } from 'react'
import { Pie } from 'react-chartjs-2'

class StageDoughnut extends PureComponent {
  constructor () {
    super()
    this.state = {
      chartData: {},
      chartOptions: {
        tooltips: {
          enabled: true
        },
        legend: {
          display: false
        }
      }
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    this.setState({
      chartData: {
        labels: nextProps.data.urgencyChartList.map(urgency => urgency.urgencyTypeName),
        datasets: [{
          backgroundColor: ['#bc5858', '#dbb469', '#7dad6f'],
          hoverBackgroundColor: ['rgba(255, 0, 0, 0.8)', 'rgba(255, 197, 20, 0.8)', 'rgba(15, 189, 119, 0.8)'],
          borderWidth: 0,
          borderColor: ['rgba(255, 0, 0, 1.0)', 'rgba(255, 197, 20, 1.0)', 'rgba(15, 189, 119, 1.0)'],
          hoverBorderWidth: 0,
          hoverBorderColor: ['rgba(255, 0, 0, 1.0)', 'rgba(255, 197, 20, 1.0)', 'rgba(15, 189, 119, 1.0)'],
          data: nextProps.data.urgencyChartList.map(urgency => urgency.total)
        }]
      }
    })
  }

  render () {
    const { t, data, navPieFunction, showChart = true } = this.props
    if (!this.state.chartData.labels) return null
    return showChart && (
      <div>
        <div className="box-total">
          <span className="box-total-title">{t('DASHBOARD.CHARTS.TOTAL')}</span>
          <span className="box-total-numer">{data.total}</span>
        </div>
        <div className="donut-wrapper">
          <Pie
            data={this.state.chartData}
            options={this.state.chartOptions}
            onElementsClick={(e) => {
              if (e[0]) {
                navPieFunction(e[0]._model.label)
              }
            }}
          />
        </div>
        <div className="legend-wrapper">
          <ul>
            {this.props.data.urgencyChartList.map((urgency, idx) => (
              <li key={idx} onClick={() => { navPieFunction(urgency.urgencyTypeName) }}>
                <span className={'color-point ' + ((idx === 0) ? 'red' : (idx === 1) ? 'yellow' : 'green')}/>
                <span>{urgency.urgencyTypeName}</span>
                <span className="number">{urgency.total}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    )
  }
}

export default StageDoughnut
