import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { isInvalid, getFormSyncErrors, getFormValues, stopAsyncValidation } from 'redux-form'
import { translate } from 'react-polyglot'
// import { push } from 'react-router-redux'
// import { withRouter } from 'react-router-dom'
import Sales from '../../../components/dossiers/sales/Sales'
import Sales_v2 from '../../../_v2/components/dossiers/sales/Sales'
import messageModalActions from '../../../actions/modals/messageModal'
import dossierSalesActions, { requestInvoiceToDMS, requestDeliveryToDMS, requestOutboundToDMS, changeCodeCommission } from '../../../actions/dossier/sales'
import { storeDossier, documentsToSendSignGetAll, openDocumentsToSendSign, sendDocumentToSign, checkDocTypeBefChangeCurrency } from '../../../actions/dossier/commonDossierActions'
import commentsActions from '../../../actions/dossier/common/comments'
import clientActions from '../../../actions/dossier/common/client'
import vehicleActions from '../../../actions/dossier/common/vehicle'
import deliveryActions from '../../../actions/dossier/common/delivery'
import scrollerActions from '../../../actions/commons/scroller'
import buttonsHeaderActions from '../../../actions/dossier/common/buttonsHeader'
import buttonsSidebarActions from '../../../actions/dossier/common/buttonsSidebar'
import { openDocumentManagementModal as openDocumentModal, printCheckList } from '../../../actions/dossier/common/documentManagementModal'
import { openDocumentEditionModal, closeAddOrEditDocumentModal, signTypes } from '../../../actions/dossier/common/documentEditionModal'
import { putCancelDossier, undoCancelDossier, confirmCancelDossier, putCancelDossierPurchaseSelfSupply } from '../../../actions/dossier/common/commonHeader'
import { fetchAndOpenReplacePurchaseModal } from '../../../actions/dossier/common/reassignReplacePurchaseModal'
import { fetchFilteredRelatedDossier } from '../../../actions/dossier/common/dossierRelatedDossiers'
import {
  fetchOperationType, fetchReasons, fetchAllReasons, fetchEntitySubTypeCombo, fetchEntityFilterSubTypeCombo, fetchVoCompaniesCombo, fetchDossierSubTypes,
  fetchHomologationClassificationCombo, fetchHomologationProducerCombo, fetchIaeCombo, fetchEntityCountryTypeCombo, fetchEntityStateTypeCombo,
  fetchEntityCityTypeCombo, fetchEntityTownTypeCombo, fetchEntityColonyTypeCombo, fetchEntityCPTypeCombo, fetchServiceDestinationTypes, fetchCommercialSocietyTypeCombo,
  fetchStockStatusCombo, fetchLowestOrganizedUnits, fecthOrganizedUnitsByDossierUo, fecthFamily,
  fetchFleetsCombo, fetchFleet, fetchFleetComponent, fetchFinancingType, fetchFleetOperationTypeForAutoselect, fetchCancelCombo, fetchOperationTypeDMS,
  fetchCarlineCombo, fetchCatalogueCombo, fetchModelCombo, fetchColorCombo, fetchTapestryCombo, fetchActivityBpro, fetchSameLevelOusCombo,
  fetchClientDataAddress
} from '../../../actions/combos/combos'
import {
  fetchCustomFields
} from '../../../actions/dossier/customFields'
import { openModal as openCommonModal, yesNoModal, updateErrorModel } from '../../../actions/common'
import { getChangeUo, getSalesmanChangeUo, postUpdateChangeUo } from '../../../actions/dossier/common/changeUo'
import uacActions from '../../../actions/dossier/common/uac'
import { openSelfSupplyModal } from '../../../actions/search/search'
import { openChargeModalDealer } from '../../../actions/dossier/common/chargeQuiter'
import { validate } from '../../../actions/dossier/common/validations'
import { getFinancingTypeProductByFinancingType } from '../../../actions/masters/masters'
import { openCDAModal } from '../../../actions/dossier/common/documentDataCDAModal'
import { registerLastAccess } from '../../../actions/dossier/common/lastAccessDossier'
import { openCodeCommissionModal } from '../../../actions/dossier/common/codeCommisionModal'
import { getStorageValue } from '../../../storage/storage'
import TrackingContainer from '../../tracking/trackingContainer'
export function mapStateToProps (state) {
  return {
    documentExport: state.documentExport.documents,
    vehicleProps: state.vehicle,
    editedCampaign: getFormValues('campaign_modal')(state),
    dossier: state.salesDossier,
    combos: state.combos,
    showManagementModal: state.documentaryManagementModal.showModal,
    uacModalForm: state.form.uacModal,
    windowWidth: state.windowDimensions.width,
    statusHeaderHeight: state.statusHeader.height,
    activeTab: state.buttonsHeader.activeTab,
    buttonsHeaderHeight: state.buttonsHeader.height,
    activeSection: state.buttonsSidebar.activeSection,
    relatedDossiersOrderBy: state.dossierRelatedDossiers.orderby,
    isInvalid: isInvalid('salesDossier')(state),
    formErrors: getFormSyncErrors('salesDossier')(state),
    formValues: getFormValues('salesDossier')(state),
    changeUo: state.changeUo,
    financingTypeProduct: state.financingTypeProduct.financingTypeProductComponent,
    autoScroll: state.scroller.autoScroll,
    comment: state.comment,
    reason: state.reason,
    cancelCombo: state.combos.cancelCombo,
    auth: state.auth
  }
}
export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      ...uacActions,
      ...dossierSalesActions,
      ...clientActions,
      ...vehicleActions,
      ...commentsActions,
      ...deliveryActions,
      ...buttonsHeaderActions,
      ...buttonsSidebarActions,
      ...scrollerActions,
      openDocumentEditionModal,
      storeDossier,
      fetchOperationType,
      fetchFleetOperationTypeForAutoselect,
      updateOptional: (dossierExtra, resolve, reject) => dossierSalesActions.updateExtra(dossierExtra, resolve, reject, 'optionals'),
      createOptional: (dossierExtra, resolve, reject) => dossierSalesActions.createExtra(dossierExtra, resolve, reject, 'optionals'),
      deleteOptional: (dossierExtra) => dossierSalesActions.deleteExtra(dossierExtra, 'optionals'),
      openCommonModal,
      openDocumentModal,
      fetchAndOpenReplacePurchaseModal,
      fetchFilteredRelatedDossier,
      putCancelDossier,
      yesNoModal,
      // navigateTo: push,
      ...messageModalActions,
      closeAddOrEditDocumentModal,
      fetchReasons,
      fetchAllReasons,
      undoCancelDossier,
      confirmCancelDossier,
      fetchEntitySubTypeCombo,
      fetchEntityFilterSubTypeCombo,
      fetchVoCompaniesCombo,
      fetchHomologationClassificationCombo,
      fetchHomologationProducerCombo,
      fetchIaeCombo,
      fetchDossierSubTypes,
      fetchServiceDestinationTypes,
      fetchStockStatusCombo,
      documentsToSendSignGetAll,
      openDocumentsToSendSign,
      sendDocumentToSign,
      signTypes,
      fetchLowestOrganizedUnits,
      getChangeUo,
      getSalesmanChangeUo,
      postUpdateChangeUo,
      fecthOrganizedUnitsByDossierUo,
      fecthFamily,
      printCheckList,
      fetchFleetsCombo,
      fetchFleet,
      fetchFleetComponent,
      openSelfSupplyModal,
      putCancelDossierPurchaseSelfSupply,
      openChargeModalDealer,
      fetchFinancingType,
      getFinancingTypeProductByFinancingType,
      fetchCancelCombo,
      updateErrorModel,
      checkDocTypeBefChangeCurrency,
      fetchCommercialSocietyTypeCombo,
      fetchEntityCountryTypeCombo,
      fetchEntityStateTypeCombo,
      fetchEntityCityTypeCombo,
      fetchEntityTownTypeCombo,
      fetchEntityColonyTypeCombo,
      fetchEntityCPTypeCombo,
      fetchOperationTypeDMS,
      fetchCarlineCombo,
      fetchCatalogueCombo,
      fetchModelCombo,
      fetchColorCombo,
      fetchTapestryCombo,
      fetchSameLevelOusCombo,
      requestInvoiceToDMS,
      requestDeliveryToDMS,
      requestOutboundToDMS,
      fetchActivityBpro,
      openCDAModal,
      fetchCustomFields,
      validate,
      stopAsyncValidation,
      fetchClientDataAddress,
      registerLastAccess,
      openCodeCommissionModal,
      changeCodeCommission
    }, dispatch)
  }
}
let version = parseInt(getStorageValue('interfaceVersion'))
var decoratedComponent = null
if (version === 2) {
  decoratedComponent = TrackingContainer(Sales_v2, true)
} else {
  decoratedComponent = TrackingContainer(Sales, true)
}
export default connect(mapStateToProps, mapDispatchToProps)(translate()(decoratedComponent))
