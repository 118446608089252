
import React, { PureComponent } from 'react'
import { Modal, Col, Button, Tooltip, OverlayTrigger } from 'react-bootstrap'
import { reduxForm } from 'redux-form'
import IDocCarPagination from '../../commons/iDocCarPagination'
import SimpleTablePage from '../../../containers/commons/general/SimpleTablePage'
import { compareValues } from '../../../util/utils'
import ProcessingAgencyUsersAddModalPage from '../../../containers/masters/ProcessingAgency/ProcessingAgencyUsersAddModalPage'

class ProcessingAngencyUsersModal extends PureComponent {
  constructor () {
    super()
    this.state = {
      filters: {
        orderBy: '+completName',
        page: 1,
        pages: 1,
        maxRows: 10,
        count: 0
      },
      userProcessingAgency: []
    }
  }

  componentDidUpdate () {}
  getSnapshotBeforeUpdate (prevProps) {
    if (prevProps.showModal !== this.props.showModal && this.props.showModal) {
      this.setState({
        filters: {
          orderBy: '+completName',
          page: 1,
          pages: 1,
          maxRows: 10,
          count: 0
        },
        userProcessingAgency: []
      })
      this.getUsersFromAgency()
    }

    if (JSON.stringify(prevProps.agencyUsers) !== JSON.stringify(this.props.agencyUsers)) {
      this.getListToTable()
    }
    return this.props
  }

  getUsersFromAgency () {
    this.props.actions.getUsersProcessingAgency(this.props.agency.processingAgencyId)
  }

  getSortIcon (column = '') {
    switch (this.state.filters.orderBy) {
      case '+' + column:
        return 'ico-sort-selected-asc'
      case '-' + column:
        return 'ico-sort-selected-desc'
      default:
        return 'ico-sort'
    }
  }

  onPageChange (page) {
    this.getListToTable(null, page)
  }

  onOrderChange (e) {
    const newOrderBy = this.state.filters.orderBy.includes(`+${e.currentTarget.dataset.field}`) ? `-${e.currentTarget.dataset.field}` : `+${e.currentTarget.dataset.field}`
    this.getListToTable(newOrderBy)
  }

  order (field, order, data) {
    let orderData = JSON.parse(JSON.stringify(data))
    orderData = orderData.sort(compareValues(field, order.includes('+') ? 'asc' : 'desc'))
    return orderData
  }

  getListToTable (newOrderBy, newPage) {
    let users = this.props.agencyUsers
    let orderBy = newOrderBy ?? this.state.filters.orderBy
    let page = newPage ?? this.state.filters.page
    if (users && users.length > 0) {
      let orderedUsers = this.order(orderBy.substring(1), orderBy, users)
      let count = orderedUsers.length
      let pages = Math.ceil(count / this.state.filters.maxRows)
      if (page > pages) {
        page = pages
      }
      let start = (this.state.filters.maxRows * (page - 1))
      let usersListed = orderedUsers.slice(start, start + this.state.filters.maxRows)

      this.setState({ filters: { ...this.state.filters, pages: pages, count: count, orderBy: orderBy, page: page }, userProcessingAgency: usersListed })
      this.props.actions.chargeUsersProcessingAgencyModal(usersListed, { ...this.state.filters, pages: pages, count: count, orderBy: orderBy, page: page })
    } else {
      this.setState({ filters: { ...this.state.filters, pages: 0, count: 0, orderBy: orderBy, page: 1 }, userProcessingAgency: [] })
      this.props.actions.chargeUsersProcessingAgencyModal([], { ...this.state.filters, pages: 0, count: 0, orderBy: orderBy, page: 1 })
    }
  }

  getIconClass (field) {
    const filters = this.state.filters
    if (filters && filters.orderBy.includes(field)) {
      return filters.orderBy.includes('-')
        ? 'ico-sort-selected-asc'
        : 'ico-sort-selected-desc'
    } else {
      return 'ico-sort'
    }
  }

  closeModal () {}
  addUserToAgency () {
    this.props.actions.openUserProcessingAgencyAddModal(this.props.agency)
  }

  deleteUserProcessingAgencyId (userProcessingAgencyId) {
    this.props.actions.deleteUserProcessingAgency(userProcessingAgencyId, () => this.props.actions.getUsersProcessingAgency(this.props.agency.processingAgencyId))
  }

  render () {
    const {
      t, handleSubmit,
      showModal, agencyFilteredUsers, filters: { page, pages, count }
    } = this.props
    const tKey = 'MASTERS.PROCESSING_AGENCY_MASTERS.MODAL.'

    const deleteTooltip = (<Tooltip id="deleteTooltip">{t(`${tKey}DELETE_USER`)}</Tooltip>)

    return (
      <>
        <Modal className="role-modal languages-modal angencyUsersModal" show={showModal} onHide={this.props.actions.closeUserProcessingAgencyModal} backdrop={'static'}>
          <form autoComplete="off" onSubmit={handleSubmit(this.addUserToAgency.bind(this))}>
            <Modal.Header closeButton onHide={this.props.actions.closeUserProcessingAgencyModal}>
              <Modal.Title>{t(`${tKey}TITLE_USERS`)}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <SimpleTablePage
                columns={[
                  <th key={0} data-field="userProcessingAgencyId" style={{ display: 'none' }}>
                  </th>,
                  <th key={1} data-field="completName" onClick={(event) => this.onOrderChange(event)}>
                    {t(`${tKey}COMPLET_NAME`)}&nbsp;<i className={this.getIconClass('completName')}></i>
                  </th>,
                  <th key={2} data-field="email" onClick={(event) => this.onOrderChange(event)}>
                    {t(`${tKey}EMAIL`)}&nbsp;<i className={this.getIconClass('email')}/>
                  </th>,
                  <th key={3} data-field="creationDate" onClick={(event) => this.onOrderChange(event)}>
                    {t(`${tKey}CREATION_DATE`)}&nbsp;<i className={this.getIconClass('creationDate')}></i>
                  </th>,
                  <th key={4} data-field="actions" >
                    {t(`${tKey}ACTIONS`)}&nbsp;
                  </th>
                ]}
                rows={agencyFilteredUsers && agencyFilteredUsers.map((row, idx) => (
                  <tr key={idx}>
                    <td>{row.completName}</td>
                    <td>{row.email}</td>
                    <td>{row.creationDate}</td>
                    <td className='actions'>{<a onClick={() => this.deleteUserProcessingAgencyId(row.userProcessingAgencyId)}>
                      <OverlayTrigger placement="left" overlay={deleteTooltip}>
                        <i className="ico-trash"/>
                      </OverlayTrigger>
                    </a>}</td>
                  </tr>
                ))}
              ></SimpleTablePage>
              <div className="search-footer" key="search-footer">
                <IDocCarPagination
                  id="btn_pag_sales"
                  page={page}
                  pagesCount={pages}
                  totalRows= {count}
                  onSelectAction={(page) => this.onPageChange(page)}
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Col sm={12} className="btn-wrapper">
                <Button
                  id="master_agency_add_usser"
                  className="btn-standard"
                  onClick={() => { this.addUserToAgency() }}>
                  {t(`${tKey}ADD_USER`)}
                </Button>
              </Col>
            </Modal.Footer>
          </form>
        </Modal>
        <ProcessingAgencyUsersAddModalPage></ProcessingAgencyUsersAddModalPage>
      </>
    )
  }
}

export default reduxForm({
  form: 'processingAngencyUsersModal'
})(ProcessingAngencyUsersModal)
