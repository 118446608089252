import apiFetch from '../apiFetch'

export default function (token, { columns, orderBy, name, surname1, surname2, dniCif, chassis, licensePlate }) {
  const finderParams = {
    columns,
    orderBy,
    entityName: name,
    entitySurname1: surname1,
    entitySurname2: surname2,
    entityDniCif: dniCif,
    vehicleVinChasis: chassis,
    vehicleLicensePlate: licensePlate
  }
  return apiFetch({
    endPoint: 'DossierFinder/PaperworksDownload',
    method: 'POST',
    body: finderParams,
    token: token,
    parseBlobAndAssign: true
  })
}
