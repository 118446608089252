import mastersActionTypes from '../../../constants/actions/masters/masters'
import { languages } from '../../../constants/dossier/common/languages'

function initialState () {
  return {
    pwkTypesList: [],
    languageList: [],
    pwkTypesCount: 0,
    pagesCount: 0,
    languageSelected: languages.SPANISH,
    filter: {
      page: 1,
      orderBy: '+name',
      pageSize: 10
    },
    pwkTypeModal: {
      paperworkType: null,
      showModal: false
    }
  }
}

function fetchPwkTypesSucces (state, { pwkTypesList, filter }) {
  return {
    ...state,
    pwkTypesList: pwkTypesList.pwkTypeList,
    pwkTypesCount: pwkTypesList.pwkTypeCount,
    pagesCount: pwkTypesList.pagesCount,
    filter
  }
}

function closePwkTypeModal (state) {
  return {
    ...state,
    pwkTypeModal: {
      paperworkType: null,
      showModal: false
    }
  }
}

function openPwkTypeModal (state, { pwkType, languageList }) {
  return {
    ...state,
    languageList,
    pwkTypeModal: {
      paperworkType: pwkType,
      showModal: true
    }
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case mastersActionTypes.FETCH_PWK_TYPES_MASTER_SUCCES:
      return fetchPwkTypesSucces(state, action)
    case mastersActionTypes.CLOSE_PWK_TYPE_MASTER_MODAL:
      return closePwkTypeModal(state, action)
    case mastersActionTypes.OPEN_PWK_TYPE_MASTER_MODAL:
      return openPwkTypeModal(state, action)
    default:
      return state
  }
}
