import apiFetch from '../apiFetch'

export default function (token, dossierId) {
  return apiFetch({
    endPoint: `dossier/registrationDelivery/${dossierId}/printDeliveryChecklist`,
    method: 'GET',
    token: token,
    parseBlobAndAssign: true
  })
}
