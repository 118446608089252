import dms from '../../../constants/actions/dossier/common/dms'

export function sendDMS (dossierId) {
  return {
    type: dms.SEND_DMS,
    dossierId
  }
}
export default {
  sendDMS
}
