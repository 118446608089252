import React, { PureComponent } from 'react'
import { reduxForm, Field, reset } from 'redux-form'
import { Row, Form, Button, Col, ProgressBar, DropdownButton, Glyphicon } from 'react-bootstrap'
import InputSelect from '../commons/form/InputSelect'
import { validateDate } from '../../util/validationFunctions'
import InputDatePicker from '../commons/form/InputDatePicker'
import InputText from '../commons/form/InputText'
import SimpleTablePage from '../../containers/commons/general/SimpleTablePage'
import IDocCarPagination from '../commons/iDocCarPagination'
import { formatDate } from '../../util/utils'
import moment from 'moment'
import AuditModalPage from '../../containers/management/auxModals/AuditModalPage'
import { dossierTypes } from '../../constants/backendIds'

class AuditAdmin extends PureComponent {
  constructor () {
    super()
    this.state = {
      fields: [],
      filters: [],
      dynamicFilters: [],
      disableResetButton: false,
      displayBar: 'none',
      progress: 0,
      progressFail: 0,
      errors: [],
      maxBar: 0,
      readOnly: false,
      visible: false
    }
  }

  componentDidMount () {
    this.props.actions.fetchAuditConfigurationType()
    this.props.actions.fetchDossierTypes()
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props._reduxForm.auditAdminModal.showModal === false && prevProps._reduxForm.auditAdminModal.showModal === true) {
      this.setState({ visible: false })
      if (this.props.items?.length > 0) {
        let filters = this.state.filters
        let propFilters = this.props.filters
        this.props.actions.fetchAuditAdminInfo({ ...filters, ...propFilters })
      }
    }
  }

  submitSearchForm (values) {
    this.setState({ filters: values })
    let filters = values
    let propFilters = this.props.filters
    propFilters.orderBy = '+AuditDescription'
    this.props.actions.fetchAuditAdminInfo({ ...filters, ...propFilters })
    this.setState({ dynamicFilters: filters })
  }

  fieldFill (field) {
    this.setState({ fields: { ...this.state.fields, ...field } })
  }

  onDossierTypeChange (dossierTypesId) {
    this.props.actions.fetchDossierSubTypes(null, dossierTypesId)
  }

  dateValidation (value, allValues, props) {
    const result = validateDate(value, props)
    return result === Object(result) ? undefined : result
  }

  clearFields () {
    this.setState({ fields: {} })
  }

  handleClickReset (e) {
    if (e !== undefined) {
      e.preventDefault()
    }
    this.clearFields()
    this.props.actions.resetFiltersAuditAdminInfo()
    this.props.dispatch(reset('auditAdminForm'))
    this.setState({ dynamicFilters: [] })
  }

  onOrderChange (e) {
    const newOrderBy = this.props.filters.orderBy.includes(`+${e.currentTarget.dataset.field}`) ? `-${e.currentTarget.dataset.field}` : `+${e.currentTarget.dataset.field}`
    this.fetchTable({ ...this.props.filters, orderBy: newOrderBy })
  }

  onPageChange (page) {
    this.fetchTable({ ...this.props.filters, page })
  }

  getIconClass (field) {
    if (this.props.filters.orderBy.includes(field)) {
      return this.props.filters.orderBy.includes('-') ? 'ico-sort-selected-asc' : 'ico-sort-selected-desc'
    } else {
      return 'ico-sort'
    }
  }

  fetchTable (propFilters) {
    let filters = this.state.dynamicFilters
    this.props.actions.fetchAuditAdminInfo({ ...filters, ...propFilters })
    this.setState({ dynamicFilters: filters })
  }

  editAuditAdmin (row, type) {
    const readOnly = (type === false && row.auditExpirationDate && moment(row.auditExpirationDate) <= moment(new Date()))
    this.setState({ readOnly: readOnly, visible: true }, () => {
      const auditConfiguration = {
        AuditConfigurationId: row.auditConfigurationId,
        AuditCode: row.auditCode,
        documentTypeUses: row.auditConfigurationDocuments,
        AuditDescription: row.auditDescription,
        AuditExpirationDate: row.auditExpirationDate ? formatDate(row.auditExpirationDate) : null,
        AuditLoadDataIds: row.auditLoadDataIds,
        AuditDocumentsIds: row.auditLoadDataIds,
        AuditUserObservations: row.auditUserObservations,
        dossierSubtypeId: row.dossierSubTypeId,
        dossierTypeId: row.dossierTypeId,
        AuditConfigurationTypeId: row.auditConfigurationTypeId,
        AuditAdminObservations: row.auditAdminObservations,
        AuditObservation: type,
        audit: false
      }
      this.props.actions.openAuditAdminModal(auditConfiguration)
    }
    )
  }

  deleteAuditAdmin (row) {
    this.props.actions.openModal('deleteAudit', () => {
      return new Promise((resolve, reject) => {
        this.props.actions.deleteAuditAdmin(row.auditConfigurationId, resolve, reject)
      }).then(() => {
        let filters = this.state.filters
        let propFilters = this.props.filters
        this.props.actions.fetchAuditAdminInfo({ ...filters, ...propFilters })
        this.props.actions.openModal('confirmDeleteAudit', null, null, null, null, [{ id: '##CODE##', value: row.auditCode }, { id: '##DESCRIPTION##', value: row.auditDescription }], null)
      })
    })
  }

  hideAuditModal () {
    this.setState({ visible: false })
  }

  render () {
    const {
      t, handleSubmit, className, hasSearch, count, items, filters: { page, pages },
      combos: {
        dossierSubTypesCombo,
        auditConfigurationType,
        dossierTypesCombo
      }
    } = this.props
    const tKey = 'MANAGEMENT.ADMIN_AUDIT.'
    return (
      <>
        { this.state.visible &&
        <AuditModalPage type={this.state.type} readOnly={this.state.readOnly} hideModal={() => this.hideAuditModal()} isAdmin={true}/>
        }
        <Form autoComplete="off" onSubmit={handleSubmit((values) => this.submitSearchForm(values))} className="filters">
          <div>
            <>
              <Row>
                <Field
                  colSm={3}
                  id="auditCode"
                  name="auditCode"
                  controlLabel={t(`${tKey}CODE`)}
                  placeholder={t(`${tKey}CODE`)}
                  component={InputText}
                />
                <Field
                  id="auditConfigurationTypeId"
                  name='auditConfigurationTypeId'
                  placeholder={t(`${tKey}AUDIT_TYPE`)}
                  controlLabel={t(`${tKey}AUDIT_TYPE`)}
                  component={InputSelect}
                  options={auditConfigurationType}
                  valueKey="id"
                  labelKey="value"
                  customClass={this.state.fields.dossierSubtypeId ? className : ''}
                />
                <Field
                  id="dossierTypeId"
                  name='dossierTypeId'
                  placeholder={t(`${tKey}DOSSIER_TYPE`)}
                  controlLabel={t(`${tKey}DOSSIER_TYPE`)}
                  component={InputSelect}
                  options={dossierTypesCombo.filter(x => x.id === dossierTypes.sales)}
                  valueKey="id"
                  labelKey="value"
                  onInputChange={(val) => { this.onDossierTypeChange(val); this.fieldFill({ dossierSubtypeId: val }) }}
                  customClass={this.state.fields.dossierSubtypeId ? className : ''}
                />
                <Field
                  id="dossierSubTypeId"
                  name='dossierSubTypeId'
                  placeholder={t(`${tKey}DOSSIER_SUBTYPE`)}
                  controlLabel={t(`${tKey}DOSSIER_SUBTYPE`)}
                  component={InputSelect}
                  options={dossierSubTypesCombo}
                  valueKey="id"
                  labelKey="value"
                  customClass={this.state.fields.dossierSubtypeId ? className : ''}
                />
                <Field
                  id="creationDateFrom"
                  name='creationDateFrom'
                  placeholder={t(`${tKey}VALIDATED_DATE_FROM`)}
                  controlLabel={t(`${tKey}VALIDATED_DATE_FROM`)}
                  component={InputDatePicker}
                  validate={[this.dateValidation]}
                  onInputChange={(val) => this.fieldFill({ creationDateFrom: val })}
                  customClass={this.state.fields.creationDatefrom ? className : ''}
                />
                <Field
                  id="creationDateTo"
                  name='creationDateTo'
                  placeholder={t(`${tKey}VALIDATED_DATE_TO`)}
                  controlLabel={t(`${tKey}VALIDATED_DATE_TO`)}
                  component={InputDatePicker}
                  validate={[this.dateValidation]}
                  onInputChange={(val) => this.fieldFill({ creationDateTo: val })}
                  customClass={this.state.fields.creationDateTo ? className : ''}
                />
                <div key="clearfix_1" className="clearfix" />
              </Row>
              <Row className='buttons-row'>
                <Col sm={12} className="text-center">
                  <hr/>
                  <Button
                    id="btn_search"
                    type="submit"
                    className="btn-standard"
                  >
                    <i className="ico-search"/>
                    {t('SEARCH.ACTIONS.SEARCH_BUTTON')}
                  </Button>
                  <Button
                    id="btn_reset"
                    onClick={(e) => this.handleClickReset(e)}
                    className={'btn-white btn-icon' + (this.props.pristine && !hasSearch ? ' btn-cancel' : ' btn-standard')}
                    disabled={this.props.pristine && !hasSearch}
                  >
                    <i className="ico-cancel"/> {t('SEARCH.ACTIONS.RESET_BUTTON')}
                  </Button>
                </Col>
              </Row>
            </>
            {
              hasSearch && ([
                <div className="table-wrapper" key="table-wrapper">
                  <SimpleTablePage
                    columns={[
                      <th key={1} data-field="AuditCode" onClick={(event) => this.onOrderChange(event)}>
                        {t(`${tKey}CODE`)}&nbsp;<i className={this.getIconClass('AuditCode')}/>
                      </th>,
                      <th key={2} data-field="AuditDescription" onClick={(event) => this.onOrderChange(event)}>
                        {t(`${tKey}DESCRIPTION`)}&nbsp;<i className={this.getIconClass('AuditDescription')}/>
                      </th>,
                      <th key={3} data-field="AuditConfigurationType" onClick={(event) => this.onOrderChange(event)}>
                        {t(`${tKey}AUDIT_TYPE`)}&nbsp;<i className={this.getIconClass('AuditConfigurationType')}/>
                      </th>,
                      <th key={4} data-field="DossierSubType" onClick={(event) => this.onOrderChange(event)}>
                        {t(`${tKey}DOSSIER_SUBTYPE`)}&nbsp;<i className={this.getIconClass('DossierSubType')}/>
                      </th>,
                      <th key={5} data-field="AuditExpirationDate" onClick={(event) => this.onOrderChange(event)}>
                        {t(`${tKey}VALIDATED_DATE`)}&nbsp;<i className={this.getIconClass('AuditExpirationDate')}/>
                      </th>,
                      <th key={6} data-field="CreationDate" onClick={(event) => this.onOrderChange(event)}>
                        {t(`${tKey}CREATION_DATE`)}&nbsp;<i className={this.getIconClass('CreationDate')}/>
                      </th>,
                      <th key={7} data-field="actions">{t(`${tKey}ACTIONS`)}&nbsp;</th>
                    ]}
                    rows={items && items.map((row, idx) => (
                      <tr key={idx}>
                        <td>{row.auditCode}</td>
                        <td>{row.auditDescription}</td>
                        <td>{row.auditConfigurationType}</td>
                        <td>{row.dossierSubType}</td>
                        <td>{(row.auditExpirationDate) ? formatDate(row.auditExpirationDate) : '-'}</td>
                        <td>{formatDate(row.creationDate)}</td>
                        <td className="actionsMenu">
                          <DropdownButton
                            as={'ButtonGroup'}
                            key={1}
                            drop={'start'}
                            className={'btn-default-drop'}
                            title={<i className="glyphicon glyphicon-option-vertical"/>}
                            id="dropdown-button-drop-start"
                          >
                            <li><Button id="btn_observations"
                              className="btn-actions"
                              onClick={() => this.editAuditAdmin(row, true)}>
                              {t(`${tKey}OBSERVATIONS`)}
                              <Glyphicon className='icon-action blue' glyph="comment"/>
                            </Button></li>
                            <li><Button id="btn_edit"
                              className="btn-actions"
                              onClick={() => this.editAuditAdmin(row, false)}>
                              {t(`${tKey}EDIT`)}
                              <Glyphicon className='icon-action blue' glyph="pencil"/>
                            </Button></li>
                            <li><Button id="btn_delete"
                              className="btn-actions"
                              onClick={() => this.deleteAuditAdmin(row)}>
                              {t(`${tKey}DELETE`)}
                              <Glyphicon className='icon-action red' glyph="trash" />
                            </Button></li>
                          </DropdownButton>
                        </td>
                      </tr>
                    ))}
                  />
                </div>,
                <div className="search-footer" key="search-footer">
                  <IDocCarPagination
                    id="btn_pag_sales"
                    page={page}
                    pagesCount={pages}
                    totalRows= {count}
                    onSelectAction={(page) => this.onPageChange(page)}
                  />
                </div>
              ])
            }
            <ProgressBar style={{ display: this.state.displayBar }}>
              <ProgressBar max={this.state.maxBar} now={this.state.progress} label={`${this.state.progress}/${this.state.maxBar}`} striped variant="success" key={1} />
              <ProgressBar max={this.state.maxBar} now={this.state.progressFail} label={`${this.state.progressFail}/${this.state.maxBar}`} striped variant="danger" style={{ backgroundColor: '#d44b4b' }} key={2} />
            </ProgressBar>
          </div>
        </Form>
      </>
    )
  }
}

export default reduxForm({
  form: 'auditAdminForm',
  validate: (values, props) => {
    const errors = {}
    if (values.creationDateTo !== undefined) {
      const format = 'D-M-YYYY'
      const fromDate = moment(values.creationDateFrom, format)
      const toDate = moment(values.creationDateTo, format)
      if (toDate.isBefore(fromDate)) {
        errors.creationDateTo = props.t('DOSSIER_COMPONENTS.VALIDATIONS.DATE_VALIDATION')
      }
      return errors
    }
  }
})(AuditAdmin)
