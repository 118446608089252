import mastersActionTypes from '../../constants/actions/masters/masters'
import managementActionTypes from '../../constants/actions/management/management'
import auditInfoTypes from '../../constants/actions/audit/auditInfo'
import { dossierSubTypeId as dossierSubTypeIdEnum } from '../../constants/dossier/common/dossierSubType'

function initialState () {
  return {
    selectedTabManagement: null,
    actionDataModal: {
      showModal: false,
      dossierIds: [],
      uoId: null,
      dossierSubtypeId: null
    },
    searchFleetInfo: {
      hasSearch: false,
      filters: {
        page: 1,
        pageSize: 10,
        orderBy: '%2BfleetCode'
      },
      dynamicFilters: {},
      dossierData: [],
      pages: 1,
      count: 0
    },
    actionFleetFinder: {
      showModal: false,
      dossiers: [],
      organizedUnitId: null
    },
    addNewFleetModal: {
      showModal: false,
      dossiers: [],
      organizedUnitId: null,
      create: false,
      dossierData: [],
      entityComponent: { entityTypeId: null, dniCifValidationDisable: false, representativeDniCifValidationDisable: false },
      entityChildComponent: { entityTypeId: null, dniCifValidationDisable: false, representativeDniCifValidationDisable: false },
      changeEntity: true
    },
    paymentCombo: [],
    dniCif: null,
    approvalActionModal: {
      showModal: false,
      dossiers: []
    },
    clientFleetPermissions: {
      fieldsConfiguration: [],
      permission: 0
    },
    economicPlanModal: {
      showModal: false,
      dossierIds: [],
      uoId: null,
      dossierSubtypeId: null,
      dossierEconomicComponent: {
        ivaAmount: 0,
        iemAmount: 0,
        iemPercentage: 0,
        purchaseMargin: 0,
        purchaseMarginPerc: 0,
        allDiscountPerc: 0,
        operationMargin: 0,
        operationMarginPerc: 0,
        netMargin: 0,
        netMarginPerc: 0,
        billTotalAmount: 0,
        operationTotalAmount: 0,
        chargesTotalAmount: 0,
        uacTotalAmount: 0,
        optionalTotalAmount: 0,
        checkAutoWarranty: false,
        campaigns: [],
        discounts: [],
        optionals: [],
        paperworks: [],
        specialHelps: [],
        extraSales: [],
        supplieds: [],
        presents: [],
        associatedCosts: [],
        updated: true,
        modelPriceAmount: 0,
        modelPriceCost: 0,
        transportationAmount: 0,
        transportationCost: 0,
        ivaTaxableBase: 0,
        iemTaxableBase: 0,
        paymentPending: 0,
        financingAmount: 0,
        financingBonusAmount: 0,
        simulated: false

      },
      editedCampaign: {
        amountPercentage: 0,
        costConcession: 0,
        costConcessionPercentage: 0,
        costPercentage: 0
      },
      economicFleetPermissions: null,
      campaignPermissions: null,
      campaigns: [],
      discountModal: {
        economicTotalOperation: 0,
        ivaType: null,
        iemType: null,
        iemPercentage: null,
        operationDiscountWithTax: null,
        operationDiscountWithoutTax: null
      }
    },
    documentTypeUse: {
      hasSearch: false,
      filters: {
        page: 1,
        pageSize: 10,
        orderBy: '+DocumentTypeId'
      },
      dynamicFilters: {},
      items: [],
      all: [],
      pages: 1,
      count: 0
    },
    diferentDocumentUploadModal: {
      showModal: false,
      dossiers: null,
      organizedUnitId: null
    },
    fleetsManagementFinder: {
      hasSearch: false,
      filters: {
        page: 1,
        pageSize: 10,
        orderBy: '+fleetCode'
      },
      dynamicFilters: {},
      fleetsData: [],
      pages: 1,
      count: 0
    },
    createNewFleetModal: {
      showModal: false,
      create: false,
      entityComponent: { entityTypeId: null, dniCifValidationDisable: false, representativeDniCifValidationDisable: false, dossierSubtypeId: 1 },
      entityChildComponent: { entityTypeId: null, dniCifValidationDisable: false, representativeDniCifValidationDisable: false },
      changeEntity: true,
      dossierSubTypeId: 1,
      dossier: {
        dossierSubTypeId: 1
      },
      fleet: null
    },
    dossierFleetDetails: [],
    operateFleetModal: {
      showModal: false,
      create: false,
      entityComponent: { entityTypeId: null, dniCifValidationDisable: false, representativeDniCifValidationDisable: false, dossierSubtypeId: 1 },
      entityChildComponent: { entityTypeId: null, dniCifValidationDisable: false, representativeDniCifValidationDisable: false },
      changeEntity: true,
      dossierSubTypeId: 1,
      dossier: {
        dossierSubTypeId: 1
      },
      fleet: null,
      dataCombo: [],
      actionsCombo: [],
      readOnlyFields: true,
      dataActions: [],
      operationActions: [],
      dataPaperWorkActions: [],
      operationPaperWorkActions: [],
      actionId: null,
      dataId: null,
      componentsModalConfiguration: null
    },
    documentsFleetComponent: {
      documentsFleetList: []
    },
    documentsFleetModal: {
      showModal: false,
      create: false,
      entityComponent: { entityTypeId: null, dniCifValidationDisable: false, representativeDniCifValidationDisable: false, dossierSubtypeId: 1 },
      entityChildComponent: { entityTypeId: null, dniCifValidationDisable: false, representativeDniCifValidationDisable: false },
      changeEntity: false,
      dossierSubTypeId: 1,
      dossier: {
        dossierSubTypeId: 1
      },
      fleet: null,
      readOnlyFields: true,
      dossiersMode: false,
      dossiers: []
    },
    selfSupplyManagementFinder: {
      hasSearch: false,
      filters: {
        page: 1,
        pageSize: 10,
        orderBy: '+saleNumber'
      },
      dynamicFilters: {},
      dossierData: [],
      pages: 1,
      count: 0
    },
    auditAdmin: {
      hasSearch: false,
      filters: {
        page: 1,
        pageSize: 10,
        orderBy: '+AuditDescription'
      },
      dynamicFilters: {},
      dossierData: [],
      pages: 1,
      count: 0
    },
    searchDocuments: {
      hasSearch: false,
      filters: {
        page: 1,
        pageSize: 10,
        orderBy: '+AuditConfigurationId'
      },
      dynamicFilters: {},
      items: [],
      pages: 1,
      count: 0
    },
    audits: {
      hasSearch: false,
      filters: {
        page: 1,
        pageSize: 10,
        orderBy: '+AuditDescription'
      },
      dynamicFilters: {},
      dossierData: [],
      pages: 1,
      count: 0
    },
    economicFleetModal: {
      showModal: false,
      create: false,
      entityComponent: { entityTypeId: null, dniCifValidationDisable: false, representativeDniCifValidationDisable: false, dossierSubtypeId: 1 },
      entityChildComponent: { entityTypeId: null, dniCifValidationDisable: false, representativeDniCifValidationDisable: false },
      changeEntity: false,
      dossierSubTypeId: 1,
      dossier: {
        dossierSubTypeId: 1
      },
      fleet: null,
      dataCombo: [],
      actionsCombo: [],
      readOnlyFields: true,
      componentsModalConfiguration: null,
      mode: null,
      dossiers: []
    },
    historicalFleetModal: {
      showModal: false,
      auditInfo: []
    },
    uacModal: {
      showModal: false,
      uacUnit: {},
      organizedUnitId: null,
      entityComponent: { entityTypeId: null, dniCifValidationDisable: false, representativeDniCifValidationDisable: false },
      changeEntity: true
    },
    zipModal: {
      showModal: false,
      number: 0
    },
    auditAdminId: null,
    auditAdminModal: {
      auditAdmin: null,
      auditAdminId: null,
      showModal: false,
      pagination: {
        page: 1,
        pageSize: 10,
        pagesCount: 0
      },
      filters: {
        orderBy: '+completName',
        page: 1,
        pages: 1,
        maxRows: 10,
        count: 0
      }
    },
    formData: null,
    paperWorkFleetComponent: {
      paperWorkFleetDetails: [],
      filters: {
        page: 1,
        pageSize: 10,
        orderBy: '%2BfleetCode'
      }
    },
    childSubtypeCount: {},
    chargeFleetComponent: {
      chargeFleetDetails: [],
      filters: {
        orderBy: '+number'
      },
      fleetEconomic: {}
    }

  }
}

function openActionDataModal (state, { dossierIds, uoId, dossierSubtypeId }) {
  const initial = initialState()
  return {
    ...state,
    actionDataModal: {
      ...initial.actionDataModal,
      showModal: true,
      dossierIds: dossierIds,
      uoId: uoId,
      dossierSubtypeId: dossierSubtypeId
    }
  }
}

function closeActionDataModal (state) {
  const initial = initialState()
  return {
    ...state,
    actionDataModal: {
      ...initial.actionDataModal,
      showModal: false
    }
  }
}

function fetchFleetsInfoSuccess (state, { data, filters }) {
  return {
    ...state,
    searchFleetInfo: {
      dossierData: data.items,
      pages: data.pages,
      count: data.count,
      hasSearch: true,
      dynamicFilters: filters,
      filters: {
        pageSize: filters.pageSize,
        page: filters.page,
        orderBy: filters.orderBy
      }
    }
  }
}

function fetchDocumentTypeUsesInfoSuccess (state, { data, filters }) {
  return {
    ...state,
    documentTypeUse: {
      items: data.items,
      all: data.all,
      pages: data.pages,
      count: data.count,
      hasSearch: true,
      dynamicFilters: filters,
      filters: {
        pageSize: filters.pageSize,
        page: filters.page,
        orderBy: filters.orderBy
      }
    }
  }
}

function resetFiltersFleetsInfoSuccess (state) {
  return {
    ...state,
    searchFleetInfo: initialState().searchFleetInfo
  }
}

export function openActionFleetFinder (state, { dossiers, organizedUnitId }) {
  return {
    ...state,
    searchFleetInfo: initialState().searchFleetInfo,
    actionFleetFinder: {
      showModal: true,
      dossiers: dossiers,
      organizedUnitId: organizedUnitId
    }
  }
}

export function closeActionFleetFinder (state) {
  return {
    ...state,
    actionFleetFinder: initialState().actionFleetFinder,
    searchFleetInfo: initialState().searchFleetInfo
  }
}

export function openNewFleetModal (state, { create, dossiers, organizedUnitId, fleet, dossierData }) {
  return {
    ...state,
    addNewFleetModal: {
      showModal: true,
      dossiers: dossiers,
      organizedUnitId: organizedUnitId,
      create: create,
      fleet: fleet,
      dossierData: dossierData,
      entityComponent: { entityTypeId: null, dniCifValidationDisable: false, representativeDniCifValidationDisable: false },
      entityChildComponent: { entityTypeId: null, dniCifValidationDisable: false, representativeDniCifValidationDisable: false },
      changeEntity: true
    }
  }
}

export function openCreateEditFleetModal (state, { create, fleet, isReadOnly }) {
  return {
    ...state,
    createNewFleetModal: {
      ...state.createNewFleetModal,
      showModal: true,
      create: create,
      fleet: fleet,
      isReadOnly: isReadOnly
    }
  }
}

export function closeNewFleetModal (state) {
  return {
    ...state,
    addNewFleetModal: initialState().addNewFleetModal
  }
}

export function closeAuditAdminModal (state) {
  return {
    ...state,
    auditAdminModal: initialState().auditAdminModal
  }
}

export function closeZipModal (state) {
  return {
    ...state,
    zipModal: initialState().zipModal
  }
}

export function fetchPaymentComboSuccess (state, { data }) {
  return {
    ...state,
    paymentCombo: data
  }
}

export function changeDniCif (state, { data }) {
  return {
    ...state,
    dniCif: data
  }
}

function openApprovalActionModal (state, { dossiers }) {
  const initial = initialState()
  return {
    ...state,
    approvalActionModal: {
      ...initial.approvalActionModal,
      showModal: true,
      dossiers: dossiers
    }
  }
}

function closeApprovalActionModal (state) {
  const initial = initialState()
  return {
    ...state,
    approvalActionModal: {
      ...initial.approvalActionModal,
      showModal: false
    }
  }
}

export function getClientFleetPermissionsSuccess (state, { data }) {
  return {
    ...state,
    clientFleetPermissions: data
  }
}

export function fetchEntityFleetSucces (state, { data }) {
  return {
    ...state,
    addNewFleetModal: {
      ...state.addNewFleetModal,
      entityComponent: data.entityComponent || state.addNewFleetModal.entityComponent,
      entityChildComponent: data.entityChildComponent || state.addNewFleetModal.entityChildComponent
    },
    createNewFleetModal: {
      ...state.createNewFleetModal,
      fleet: {
        ...state.createNewFleetModal.fleet,
        entityComponent: data.entityComponent || state.createNewFleetModal.entityComponent,
        entityChildComponent: data.entityChildComponent || state.createNewFleetModal.entityChildComponent
      },
      entityComponent: data.entityComponent || state.createNewFleetModal.entityComponent,
      entityChildComponent: data.entityChildComponent || state.createNewFleetModal.entityChildComponent
    }

  }
}

export function fetchEntityChildFleetSucces (state, { data }) {
  return {
    ...state,
    addNewFleetModal: {
      ...state.addNewFleetModal,
      entityChildComponent: data.entityChildComponent
    },
    createNewFleetModal: {
      ...state.createNewFleetModal,
      entityChildComponent: data.entityChildComponent
    }
  }
}

export function checkChangeEntityDossierSuccess (state, { data }) {
  return {
    ...state,
    addNewFleetModal: {
      ...state.addNewFleetModal,
      changeEntity: data
    },
    createNewFleetModal: {
      ...state.createNewFleetModal,
      changeEntity: data
    }

  }
}

export function openEconomicPlanModal (state, { dossierIds, uoId, dossierSubtypeId }) {
  const initial = initialState()
  return {
    ...state,
    economicPlanModal: {
      ...initial.economicPlanModal,
      showModal: true,
      dossierIds: dossierIds,
      uoId: uoId,
      dossierSubtypeId: dossierSubtypeId
    }
  }
}

export function closeEconomicPlanModal (state) {
  const initial = initialState()
  return {
    ...state,
    economicPlanModal: {
      ...initial.economicPlanModal
    }
  }
}

export function getEconomicPlanPermissionsSuccess (state, { data }) {
  return {
    ...state,
    economicPlanModal: {
      ...state.economicPlanModal,
      economicFleetPermissions: data.tabsConfiguration,
      campaignPermissions: data.campaign,
      saleConfiguration: data.saleConfiguration
    }
  }
}

export function updateEconomicPlanFleet (state, { dossierEconomicComponent }) {
  return {
    ...state,
    economicPlanModal: {
      ...state.economicPlanModal,
      dossierEconomicComponent: GetDossierEconomic(state, dossierEconomicComponent)
    }
  }
}
export function simulateEconomicPlanFleetSuccess (state, { data, simulated }) {
  let dossierEconomicComponent = data
  return {
    ...state,
    economicPlanModal: {
      ...state.economicPlanModal,
      dossierEconomicComponent: GetDossierEconomic(state, dossierEconomicComponent, simulated)
    }

  }
}

export function addCampaignSuccess (state, { data }) {
  let dossierEconomicComponent = data.dossierEconomicComponent
  return {
    ...state,
    economicPlanModal: {
      ...state.economicPlanModal,
      dossierEconomicComponent: GetDossierEconomic(state, dossierEconomicComponent),
      campaigns: data.campaigns
    }
  }
}

export function openDocumentDiferentsModalFleets (state, { dossiers, organizedUnitId }) {
  return {
    ...state,
    diferentDocumentUploadModal: {
      showModal: true,
      dossiers: dossiers,
      organizedUnitId: organizedUnitId
    }
  }
}

export function setSimulatedFlag (state, { value }) {
  return {
    ...state,
    economicPlanModal: {
      ...state.economicPlanModal,
      dossierEconomicComponent: {
        ...state.economicPlanModal.dossierEconomicComponent,
        simulated: value
      }
    }
  }
}

function GetDossierEconomic (state, dossierEconomicComponent, simulated) {
  return {
    ivaAmount: dossierEconomicComponent.ivaAmount ?? state.economicPlanModal.dossierEconomicComponent.ivaAmount,
    iemAmount: dossierEconomicComponent.iemAmount ?? state.economicPlanModal.dossierEconomicComponent.iemAmount,
    iemPercentage: dossierEconomicComponent.iemPercentage ?? state.economicPlanModal.dossierEconomicComponent.iemPercentage,
    purchaseMargin: dossierEconomicComponent.purchaseMargin ?? state.economicPlanModal.dossierEconomicComponent.purchaseMargin,
    purchaseMarginPerc: dossierEconomicComponent.purchaseMarginPerc ?? state.economicPlanModal.dossierEconomicComponent.purchaseMarginPerc,
    allDiscountPerc: dossierEconomicComponent.allDiscountPerc ?? state.economicPlanModal.dossierEconomicComponent.allDiscountPerc,
    operationMargin: dossierEconomicComponent.operationMargin ?? state.economicPlanModal.dossierEconomicComponent.operationMargin,
    operationMarginPerc: dossierEconomicComponent.operationMarginPerc ?? state.economicPlanModal.dossierEconomicComponent.operationMarginPerc,
    netMargin: dossierEconomicComponent.netMargin ?? state.economicPlanModal.dossierEconomicComponent.netMargin,
    netMarginPerc: dossierEconomicComponent.netMarginPerc ?? state.economicPlanModal.dossierEconomicComponent.netMarginPerc,
    billTotalAmount: dossierEconomicComponent.billTotalAmount ?? state.economicPlanModal.dossierEconomicComponent.billTotalAmount,
    operationTotalAmount: dossierEconomicComponent.operationTotalAmount ?? state.economicPlanModal.dossierEconomicComponent.operationTotalAmount,
    chargesTotalAmount: dossierEconomicComponent.chargesTotalAmount ?? state.economicPlanModal.dossierEconomicComponent.chargesTotalAmount,
    uacTotalAmount: dossierEconomicComponent.uacTotalAmount ?? state.economicPlanModal.dossierEconomicComponent.uacTotalAmount,
    optionalTotalAmount: dossierEconomicComponent.optionalTotalAmount ?? state.economicPlanModal.dossierEconomicComponent.optionalTotalAmount,
    checkAutoWarranty: dossierEconomicComponent.checkAutoWarranty ?? state.economicPlanModal.dossierEconomicComponent.checkAutoWarranty,
    campaigns: dossierEconomicComponent.campaigns && Array.isArray(dossierEconomicComponent.campaigns) ? dossierEconomicComponent.campaigns : state.economicPlanModal.dossierEconomicComponent.campaigns,
    discounts: dossierEconomicComponent.discounts && Array.isArray(dossierEconomicComponent.discounts) ? dossierEconomicComponent.discounts : state.economicPlanModal.dossierEconomicComponent.discounts,
    optionals: dossierEconomicComponent.optionals && Array.isArray(dossierEconomicComponent.optionals) ? dossierEconomicComponent.optionals : state.economicPlanModal.dossierEconomicComponent.optionals,
    paperworks: dossierEconomicComponent.paperworks && Array.isArray(dossierEconomicComponent.paperworks) ? dossierEconomicComponent.paperworks : state.economicPlanModal.dossierEconomicComponent.paperworks,
    specialHelps: dossierEconomicComponent.specialHelps && Array.isArray(dossierEconomicComponent.specialHelps) ? dossierEconomicComponent.specialHelps : state.economicPlanModal.dossierEconomicComponent.specialHelps,
    extraSales: dossierEconomicComponent.extraSales && Array.isArray(dossierEconomicComponent.extraSales) ? dossierEconomicComponent.extraSales : state.economicPlanModal.dossierEconomicComponent.extraSales,
    supplieds: dossierEconomicComponent.supplieds && Array.isArray(dossierEconomicComponent.supplieds) ? dossierEconomicComponent.supplieds : state.economicPlanModal.dossierEconomicComponent.supplieds,
    presents: dossierEconomicComponent.presents && Array.isArray(dossierEconomicComponent.presents) ? dossierEconomicComponent.presents : state.economicPlanModal.dossierEconomicComponent.presents,
    associatedCosts: dossierEconomicComponent.associatedCosts && Array.isArray(dossierEconomicComponent.associatedCosts) ? dossierEconomicComponent.associatedCosts : state.economicPlanModal.dossierEconomicComponent.associatedCosts,
    updated: dossierEconomicComponent.updated ?? state.economicPlanModal.dossierEconomicComponent.updated,
    modelPriceAmount: dossierEconomicComponent.modelPriceAmount ?? state.economicPlanModal.dossierEconomicComponent.modelPriceAmount,
    modelPriceCost: dossierEconomicComponent.modelPriceCost ?? state.economicPlanModal.dossierEconomicComponent.modelPriceCost,
    transportationAmount: dossierEconomicComponent.transportationAmount ?? state.economicPlanModal.dossierEconomicComponent.transportationAmount,
    transportationCost: dossierEconomicComponent.transportationCost ?? state.economicPlanModal.dossierEconomicComponent.transportationCost,
    ivaTaxableBase: dossierEconomicComponent.ivaTaxableBase ?? state.economicPlanModal.dossierEconomicComponent.ivaTaxableBase,
    iemTaxableBase: dossierEconomicComponent.iemTaxableBase ?? state.economicPlanModal.dossierEconomicComponent.iemTaxableBase,
    paymentPending: dossierEconomicComponent.paymentPending ?? state.economicPlanModal.dossierEconomicComponent.paymentPending,
    financingAmount: dossierEconomicComponent.financingAmount ?? state.economicPlanModal.dossierEconomicComponent.financingAmount,
    financingBonusAmount: dossierEconomicComponent.financingBonusAmount ?? state.economicPlanModal.dossierEconomicComponent.financingBonusAmount,
    commissionCancellationCodeDescription: dossierEconomicComponent.commissionCancellationCodeDescription ?? state.economicPlanModal.dossierEconomicComponent.commissionCancellationCodeDescription,
    commissionCancellationCodeId: dossierEconomicComponent.commissionCancellationCodeId ?? state.economicPlanModal.dossierEconomicComponent.commissionCancellationCodeId,
    simulated: simulated ?? false
  }
}

export function closeDiferentDocumentUploadModal (state) {
  return {
    ...state,
    diferentDocumentUploadModal: initialState().diferentDocumentUploadModal
  }
}

function fetchFleetsManagementSuccess (state, { data, dynamicFilters, filters }) {
  return {
    ...state,
    fleetsManagementFinder: {
      fleetsData: data.items,
      pages: data.pages,
      count: data.count,
      hasSearch: true,
      dynamicFilters: dynamicFilters,
      filters: {
        pageSize: filters.pageSize,
        page: filters.page,
        orderBy: filters.orderBy
      }
    }
  }
}

function resetFleetManagementFilters (state) {
  return {
    ...state,
    fleetsManagementFinder: initialState().fleetsManagementFinder
  }
}

function modifyFleetManagementFilters (state) {
  return {
    ...state,
    fleetsManagementFinder: {
      ...initialState().fleetsManagementFinder,
      dynamicFilters: state.fleetsManagementFinder.dynamicFilters
    }
  }
}

function closeCreateEditFleetModal (state) {
  return {
    ...state,
    createNewFleetModal: initialState().createNewFleetModal
  }
}
function getDossierDetailsFleetSuccess (state, { data }) {
  return {
    ...state,
    dossierFleetDetails: data
  }
}

export function openOperateFleetModalSuccess (state, { fleet }) {
  return {
    ...state,
    operateFleetModal: {
      ...state.operateFleetModal,
      showModal: true,
      create: false,
      fleet: fleet
    }
  }
}

export function closeOperateFleetModal (state) {
  return {
    ...state,
    operateFleetModal: initialState().operateFleetModal
  }
}

export function getFleetOperationsSuccess (state, { dataActions, operationActions, dataPaperWorkActions, operationPaperWorkActions }) {
  return {
    ...state,
    operateFleetModal: {
      ...state.operateFleetModal,
      dataActions: dataActions,
      operationActions: operationActions,
      dataPaperWorkActions: dataPaperWorkActions,
      operationPaperWorkActions: operationPaperWorkActions
    }
  }
}

export function initializeOperationActionsComboSuccess (state, { actionId, dataId }) {
  return {
    ...state,
    operateFleetModal: {
      ...state.operateFleetModal,
      actionId: actionId,
      dataId: dataId
    }
  }
}
export function fetchOperateDataFleetModalsPermissionsSuccess (state, { data }) {
  return {
    ...state,
    operateFleetModal: {
      ...state.operateFleetModal,
      componentsModalConfiguration: data
    }
  }
}

export function getDocumentFleetListSuccess (state, { data }) {
  return {
    ...state,
    documentsFleetComponent: {
      ...state.documentsFleetComponent,
      documentsFleetList: data
    }
  }
}

export function openDocumentFleetModal (state, { fleet, dossiersMode, dossiers }) {
  return {
    ...state,
    documentsFleetModal: {
      ...state.documentsFleetModal,
      showModal: true,
      fleet: fleet,
      dossiersMode: dossiersMode,
      dossiers: dossiers
    }
  }
}

export function closeDocumentFleetModal (state) {
  return {
    ...state,
    documentsFleetModal: initialState().documentsFleetModal
  }
}

function resetFiltersSelfSupplyInfoSuccess (state) {
  let initial = initialState().selfSupplyManagementFinder
  return {
    ...state,
    selfSupplyManagementFinder: initial
  }
}

function resetFiltersAuditAdminInfoSuccess (state) {
  let initial = initialState().auditAdmin
  return {
    ...state,
    auditAdmin: initial
  }
}

function resetFiltersAuditsInfoSuccess (state) {
  let initial = initialState().audits
  return {
    ...state,
    audits: initial
  }
}

function fetchSelfSupplyManagementInfoSuccess (state, { data, filters }) {
  return {
    ...state,
    selfSupplyManagementFinder: {
      dossierData: data.items,
      pages: data.pages,
      count: data.count,
      hasSearch: true,
      dynamicFilters: filters,
      filters: {
        pageSize: filters.pageSize,
        page: filters.page,
        orderBy: filters.orderBy
      }
    }
  }
}

function fetchAuditsInfoSuccess (state, { data, filters }) {
  return {
    ...state,
    audits: {
      items: data.items,
      pages: data.pages,
      count: data.count,
      hasSearch: true,
      dynamicFilters: filters,
      filters: {
        pageSize: filters.pageSize,
        page: filters.page,
        orderBy: filters.orderBy
      }
    }
  }
}

function resetSearchDocumentsInfoSuccess (state) {
  let initial = initialState().searchDocuments
  return {
    ...state,
    searchDocuments: initial
  }
}

function fetchSearchDocumentsInfoSuccess (state, { data, filters }) {
  return {
    ...state,
    searchDocuments: {
      items: data.items,
      pages: data.pages,
      count: data.count,
      documentIds: data.documentIds,
      hasSearch: true,
      dynamicFilters: filters,
      filters: {
        pageSize: filters.pageSize,
        page: filters.page,
        orderBy: filters.orderBy
      }
    }
  }
}

function fetchAuditAdminInfoSuccess (state, { data, filters }) {
  return {
    ...state,
    auditAdmin: {
      items: data.items,
      pages: data.pages,
      count: data.count,
      hasSearch: true,
      dynamicFilters: filters,
      filters: {
        pageSize: filters.pageSize,
        page: filters.page,
        orderBy: filters.orderBy
      }
    }
  }
}

export function showHistoricalFleetModal (state, { show, fleet }) {
  return {
    ...state,
    historicalFleetModal: {
      ...state.historicalFleetModal,
      showModal: show,
      fleet: fleet
    }
  }
}

export function openEconomicFleetModalSuccess (state, { fleet, mode, dossiers }) {
  return {
    ...state,
    economicFleetModal: {
      ...state.economicFleetModal,
      showModal: true,
      create: false,
      fleet: fleet,
      mode: mode,
      dossiers: dossiers
    },
    economicPlanModal: {
      ...state.economicPlanModal,
      uoId: fleet.organizedUnitId,
      dossierSubtypeId: fleet.dossierSubType
    }

  }
}

export function closeEconomicFleetModal (state) {
  return {
    ...state,
    economicFleetModal: initialState().economicFleetModal,
    economicPlanModal: initialState().economicPlanModal
  }
}

export function fetchAuditFleetInfoSucces (state, { auditInfo }) {
  return {
    ...state,
    historicalFleetModal: {
      ...state.historicalFleetModal,
      auditInfo: auditInfo
    }
  }
}

export function fetchEntityUacModalSucces (state, { data }) {
  return {
    ...state,
    uacModal: {
      ...state.uacModal,
      entityComponent: data.entityComponent || state.uacModal.entityComponent
    }
  }
}

export function openUacModal (state, { data }) {
  return {
    ...state,
    uacModal: {
      ...state.uacModal,
      showModal: true,
      uacUnit: data?.uacUnit || state.uacModal.uacUnit,
      entityComponent: data?.uacUnit.entityComponent || state.uacModal.entityComponent
    }
  }
}

export function closeUacModal (state) {
  return {
    ...state,
    uacModal: initialState().uacModal
  }
}

function managementTabChangeSuccess (state, { selectedTab }) {
  return {
    ...state,
    selectedTabManagement: selectedTab
  }
}

function openAuditAdminModalSuccess (state, { userAuditAdmin, auditAdminId, formData }) {
  const initial = initialState()
  return {
    ...state,
    auditAdminId,
    formData: formData,
    auditAdminModal: {
      ...initial.auditAdminModal,
      auditAdmin: userAuditAdmin,
      auditAdminId: auditAdminId,
      showModal: true,
      filters: {
        orderBy: '+completName',
        page: 1,
        pages: 1,
        maxRows: 10,
        count: 0
      }
    }
  }
}

function openZipModalSuccess (state, { number }) {
  return {
    ...state,
    zipModal: {
      showModal: true,
      number: number
    }
  }
}

function changeCodeCommissionFleetSuccess (state, { codeCommission }) {
  return {
    ...state,

    economicPlanModal: {
      ...state.economicPlanModal,
      dossierEconomicComponent: {
        ...state.economicPlanModal.dossierEconomicComponent,
        commissionCancellationCodeId: codeCommission.commissionCancellationCodeId,
        commissionCancellationCodeDescription: codeCommission.commissionCancellationCodeDescription
      }
    }
  }
}

function getPaperworksDetailsFleetSuccess (state, { data }) {
  var count = {}
  count[dossierSubTypeIdEnum.tramite] = data ? data.length : 0

  return {
    ...state,
    paperWorkFleetComponent: {
      ...state.paperWorkFleetComponent,
      paperWorkFleetDetails: data
    },
    childSubtypeCount: { ...state.childSubtypeCount, ...count }
  }
}

function getDossierChildSubtypeDetailCountSuccess (state, { data }) {
  return {
    ...state,
    childSubtypeCount: { ...state.childSubtypeCount, ...data }
  }
}

function getChargesDetailsFleetSuccess (state, { data }) {
  return {
    ...state,
    chargeFleetComponent: {
      ...state.chargeFleetComponent,
      chargeFleetDetails: data
    }
  }
}

function getFleetEconomicSuccess (state, { data }) {
  return {
    ...state,
    chargeFleetComponent: {
      ...state.chargeFleetComponent,
      fleetEconomic: data
    }
  }
}

function managementSectionChangeSuccess (state, { secMapManagement }) {
  return {
    ...state,
    secMapManagement
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case mastersActionTypes.OPEN_ACTION_DATA_MODAL:
      return openActionDataModal(state, action)
    case mastersActionTypes.CLOSE_ACTION_DATA_MODAL:
      return closeActionDataModal(state, action)
    case managementActionTypes.FETCH_FLEETS_INFO_SUCCESS:
      return fetchFleetsInfoSuccess(state, action)
    case managementActionTypes.FETCH_DOCUMENT_TYPE_USES_INFO_SUCCESS:
      return fetchDocumentTypeUsesInfoSuccess(state, action)
    case managementActionTypes.RESET_FILTERS_FLEET_INFO:
      return resetFiltersFleetsInfoSuccess(state, action)
    case managementActionTypes.OPEN_DATA_ACTION_FLEET_FINDER:
      return openActionFleetFinder(state, action)
    case managementActionTypes.CLOSE_DATA_ACTION_FLEET_FINDER:
      return closeActionFleetFinder(state, action)
    case managementActionTypes.OPEN_NEW_FLEET_MODAL:
      return openNewFleetModal(state, action)
    case managementActionTypes.CLOSE_NEW_FLEET_MODAL:
      return closeNewFleetModal(state, action)
    case managementActionTypes.CLOSE_AUDIT_ADMIN_MODAL:
      return closeAuditAdminModal(state, action)
    case managementActionTypes.CLOSE_ZIP_MODAL:
      return closeZipModal(state, action)
    case managementActionTypes.FETCH_PAYMENT_COMBO_SUCCESS:
      return fetchPaymentComboSuccess(state, action)
    case managementActionTypes.CHANGE_DNICIF_DATA:
      return changeDniCif(state, action)
    case managementActionTypes.GET_CLIENT_FLEET_PERMISSIONS_SUCCESS:
      return getClientFleetPermissionsSuccess(state, action)
    case managementActionTypes.FETCH_ENTITY_FLEET_SUCCES:
      return fetchEntityFleetSucces(state, action)
    case managementActionTypes.CHECK_CHANGE_ENTITY_DOSSIER_SUCCESS:
      return checkChangeEntityDossierSuccess(state, action)
    case managementActionTypes.OPEN_ECONOMIC_PLAN_MODAL:
      return openEconomicPlanModal(state, action)
    case managementActionTypes.CLOSE_ECONOMIC_PLAN_MODAL:
      return closeEconomicPlanModal(state, action)
    case managementActionTypes.UPATE_ECONOMIC_PLAN_FLEET:
      return updateEconomicPlanFleet(state, action)
    case managementActionTypes.GET_ECONOMIC_PLAN_FLEET_PERMISSIONS_SUCCESS:
      return getEconomicPlanPermissionsSuccess(state, action)
    case managementActionTypes.SIMULATE_ECONOMIC_PLAN_FLEET_SUCCESS:
      return simulateEconomicPlanFleetSuccess(state, action)
    case managementActionTypes.ADD_CAMPAIGN_ECONOMIC_PLAN_FLEET_SUCCESS:
      return addCampaignSuccess(state, action)
    case mastersActionTypes.OPEN_APPROVAL_ACTION_MODAL:
      return openApprovalActionModal(state, action)
    case mastersActionTypes.CLOSE_APPROVAL_ACTION_MODAL:
      return closeApprovalActionModal(state, action)
    case managementActionTypes.OPEN_UPLOAD_DOCUMENTS_FLEET_MODAL:
      return openDocumentDiferentsModalFleets(state, action)
    case managementActionTypes.CLOSE_UPLOAD_DOCUMENTS_FLEET_MODAL:
      return closeDiferentDocumentUploadModal(state, action)
    case managementActionTypes.SET_SIMULATED_FLAG:
      return setSimulatedFlag(state, action)
    case managementActionTypes.FIND_FLEETS_MANAGEMENT_SUCCESS:
      return fetchFleetsManagementSuccess(state, action)
    case managementActionTypes.RESET_FLEETS_MANAGEMENT_FILTERS:
      return resetFleetManagementFilters(state, action)
    case managementActionTypes.MODIFY_FLEETS_MANAGEMENT_FILTERS:
      return modifyFleetManagementFilters(state, action)
    case managementActionTypes.OPEN_CREATE_EDIT_FLEET_MODAL:
      return openCreateEditFleetModal(state, action)
    case managementActionTypes.FETCH_ENTITY_CHILD_FLEET_SUCCES:
      return fetchEntityChildFleetSucces(state, action)
    case managementActionTypes.CLOSE_CREATE_EDIT_FLEET_MODAL:
      return closeCreateEditFleetModal(state, action)
    case managementActionTypes.FETCH_DOSSIER_DETAIL_FLEET_SUCCESS:
      return getDossierDetailsFleetSuccess(state, action)
    case managementActionTypes.OPEN_OPERATE_FLEET_MODAL_SUCCESS:
      return openOperateFleetModalSuccess(state, action)
    case managementActionTypes.CLOSE_OPERATE_FLEET_MODAL:
      return closeOperateFleetModal(state, action)
    case managementActionTypes.FETCH_OPERATE_ACTIONS_FLEET_SUCCESS:
      return getFleetOperationsSuccess(state, action)
    case managementActionTypes.INITIALIZE_OPERATION_ACTION_COMBOS_SUCCESS:
      return initializeOperationActionsComboSuccess(state, action)
    case managementActionTypes.FETCH_OPERATE_DATA_FLEET_MODALS_PERMISSIONS_SUCCESS:
      return fetchOperateDataFleetModalsPermissionsSuccess(state, action)
    case managementActionTypes.FETCH_DOCUMENT_FLEET_LIST_SUCCESS:
      return getDocumentFleetListSuccess(state, action)
    case managementActionTypes.OPEN_DOCUMENT_FLEET_MODAL:
      return openDocumentFleetModal(state, action)
    case managementActionTypes.CLOSE_DOCUMENT_FLEET_MODAL:
      return closeDocumentFleetModal(state, action)
    case managementActionTypes.RESET_FILTERS_SELF_SUPPLY_INFO:
      return resetFiltersSelfSupplyInfoSuccess(state, action)
    case managementActionTypes.RESET_FILTERS_AUDIT_ADMIN_INFO:
      return resetFiltersAuditAdminInfoSuccess(state, action)
    case managementActionTypes.RESET_FILTERS_AUDITS_INFO:
      return resetFiltersAuditsInfoSuccess(state, action)
    case managementActionTypes.FETCH_SELF_SUPPLY_INFO_SUCCES:
      return fetchSelfSupplyManagementInfoSuccess(state, action)
    case managementActionTypes.FETCH_AUDIT_ADMIN_INFO_SUCCESS:
      return fetchAuditAdminInfoSuccess(state, action)
    case managementActionTypes.FETCH_AUDITS_INFO_SUCCESS:
      return fetchAuditsInfoSuccess(state, action)
    case managementActionTypes.OPEN_ECONOMIC_FLEET_MODAL_SUCCESS:
      return openEconomicFleetModalSuccess(state, action)
    case managementActionTypes.CLOSE_ECONOMIC_FLEET_MODAL:
      return closeEconomicFleetModal(state, action)
    case managementActionTypes.OPEN_CLOSE_HISTORICAL_FLEET_MODAL:
      return showHistoricalFleetModal(state, action)
    case auditInfoTypes.FETCH_AUDIT_FLEET_INFO_SUCCESS:
      return fetchAuditFleetInfoSucces(state, action)
    case managementActionTypes.FETCH_ENTITY_UAC_MODAL_SUCCES:
      return fetchEntityUacModalSucces(state, action)
    case managementActionTypes.OPEN_UAC_MODAL:
      return openUacModal(state, action)
    case managementActionTypes.CLOSE_UAC_MODAL:
      return closeUacModal(state, action)
    case managementActionTypes.TAB_CHANGE:
      return managementTabChangeSuccess(state, action)
    case managementActionTypes.OPEN_ADD_AUDITADMIN_MODAL_SUCCESS:
      return openAuditAdminModalSuccess(state, action)
    case managementActionTypes.FETCH_SEARCH_DOCUMENTS_INFO_SUCCESS:
      return fetchSearchDocumentsInfoSuccess(state, action)
    case managementActionTypes.OPEN_ZIP_MODAL_SUCCESS:
      return openZipModalSuccess(state, action)
    case managementActionTypes.RESET_FILTERS_SEARCH_DOCUMENT:
      return resetSearchDocumentsInfoSuccess(state, action)
    case managementActionTypes.CHANGE_CODE_COMMISSION_FLEET_SUCCESS:
      return changeCodeCommissionFleetSuccess(state, action)
    case managementActionTypes.FETCH_PAPERWORK_FLEET_LIST_SUCCESS:
      return getPaperworksDetailsFleetSuccess(state, action)
    case managementActionTypes.FETCH_CHILDS_SUBTYPE_FLEET_COUNT_LIST_SUCCESS:
      return getDossierChildSubtypeDetailCountSuccess(state, action)
    case managementActionTypes.FETCH_CHARGE_FLEET_LIST_SUCCESS:
      return getChargesDetailsFleetSuccess(state, action)
    case managementActionTypes.FETCH_FLEET_ECONOMIC_SUCCESS:
      return getFleetEconomicSuccess(state, action)
    case managementActionTypes.MANAGEMENT_SECTION_CHANGE_SUCCESS:
      return managementSectionChangeSuccess(state, action)
    default:
      return state
  }
}
