import notificationsActionTypes from '../../constants/actions/notifications/notifications'

export function initialState () {
  return {
    notificationCount: {
      documents: null,
      dossiers: null
    },
    documentNotifications: [],
    showDocumentPopover: false,
    dossierNotifications: [],
    showDossierPopover: false
  }
}

function setNotificationsCounter (state, { notificationCount: { documents = null, dossiers = null } }) {
  return {
    ...state,
    notificationCount: {
      documents: (documents !== null ? documents : state.notificationCount.documents),
      dossiers: (dossiers !== null ? dossiers : state.notificationCount.dossiers)
    }
  }
}

function fetchDocumentNotificationsSuccess (state, { documentNotifications = [] }) {
  return {
    ...state,
    documentNotifications,
    showDocumentPopover: true
  }
}

function closeDocumentNotificationPopover (state) {
  return {
    ...state,
    documentNotifications: [],
    showDocumentPopover: false
  }
}

function fetchDossierNotificationsSuccess (state, { dossierNotifications = [] }) {
  return {
    ...state,
    dossierNotifications,
    showDossierPopover: true
  }
}

function closeDossierNotificationPopover (state) {
  return {
    ...state,
    dossierNotifications: [],
    showDossierPopover: false
  }
}

function markDossierNotificationAsReadSuccess (state) {
  return {
    ...state,
    notificationCount: {
      ...state.notificationCount,
      dossiers: Number(state.notificationCount.dossiers) - 1
    }
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case notificationsActionTypes.SET_NOTIFICATIONS_COUNTER:
      return setNotificationsCounter(state, action)
    case notificationsActionTypes.FETCH_DOCUMENT_NOTIFICATIONS_SUCCESS:
      return fetchDocumentNotificationsSuccess(state, action)
    case notificationsActionTypes.CLOSE_DOCUMENT_NOTIFICATIONS_POPOVER:
      return closeDocumentNotificationPopover(state, action)
    case notificationsActionTypes.FETCH_DOSSIER_NOTIFICATIONS_SUCCESS:
      return fetchDossierNotificationsSuccess(state, action)
    case notificationsActionTypes.CLOSE_DOSSIER_NOTIFICATIONS_POPOVER:
      return closeDossierNotificationPopover(state, action)
    case notificationsActionTypes.MARK_DOSSIER_NOTIFICATION_AS_READ_SUCCESS:
      return markDossierNotificationAsReadSuccess(state, action)
    default:
      return state
  }
}
