import React, { PureComponent } from 'react'
import { Button, Modal, Col, Table, Row } from 'react-bootstrap'

import { reduxForm, Field } from 'redux-form'
import InputSelect from '../../commons/form/InputSelect'
import InputCheckBox from '../../commons/form/InputCheckBox'
import DropZone from 'react-dropzone'
import settings from '../../../setting'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileArrowDown } from '@fortawesome/free-solid-svg-icons'
import { loadTemplatesNames, permissions } from '../../../constants/backendIds'
import { saleTypeCodeEnum } from '../../../constants/dossier/sale/saleType'

class ChargeDossiersModal extends PureComponent {
  constructor () {
    super()
    this.state = {
      files: [],
      fields: {},
      isFileLoadDisabled: false,
      viewCodeCommission: false
    }
  }

  componentDidUpdate (prevProps) {
    if (this.state.fields.operationTypeId && this.props.combos.saleTypeCombo &&
      prevProps.combos.saleTypeCombo?.length !== this.props.combos.saleTypeCombo.length && this.props.combos.saleTypeCombo.length === 1) {
      this.props.change('saleTypeId', this.props.combos.saleTypeCombo[0].id)
    }

    if (this.props.fleet?.organizedUnitId &&
      this.props.fleet?.organizedUnitId !== prevProps.fleet?.organizedUnitId) {
      this.props.actions.fetchFleetOrganizedUnits(this.props.fleet.organizedUnitId)
    }

    if (this.state.fields.organizedUnitId && ((!this.state.fields.operationTypeId && this.props.combos.operationTypeCombo?.length === 1) || (this.props.combos.operationTypeCombo && prevProps.combos.operationTypeCombo?.length !== this.props.combos.operationTypeCombo.length && this.props.combos.operationTypeCombo.length === 1))) {
      this.setState({ fields: { ...this.state.fields, operationTypeId: this.props.combos.operationTypeCombo[0].id } })
      const organizedUnitId = this.state.fields.organizedUnitId
      if (this.props.combos.saleTypeCombo && this.props.combos.saleTypeCombo.length === 0) {
        this.props.actions.fetchSaleTypeByOperationType(this.props.combos.operationTypeCombo[0].id, organizedUnitId, this.props.fleet.dossierSubType ?? this.props.fleet.dossierSubTypeId)
      }
      this.props.change('operationTypeId', this.props.combos.operationTypeCombo[0].id)
    }
  }

  fetchCombos (field) {
    if (field.organizedUnitId) {
      this.props.actions.fetchSalesman(field.organizedUnitId)
      this.props.actions.fetchCodeCommissionCombo([field.organizedUnitId])
      const operationTypeId = this.state.fields.operationTypeId
      if (operationTypeId || (this.props.combos.operationTypeCombo && this.props.combos.operationTypeCombo.length === 1)) {
        this.props.actions.fetchSaleTypeByOperationType(operationTypeId ?? this.props.combos.operationTypeCombo[0].id, field.organizedUnitId, this.props.fleet.dossierSubType ?? this.props.fleet.dossierSubTypeId)
        this.props.change('saleTypeId', null)
        this.props.change('codeCommissionId', null)
        this.setState({ viewCodeCommission: false })
      } else if (!operationTypeId) {
        this.props.actions.fetchOperationType(null, field.organizedUnitId, false, true)
      }
    }

    if (field.operationTypeId) {
      const organizedUnitId = this.state.fields.organizedUnitId
      if (organizedUnitId) {
        this.props.actions.fetchSaleTypeByOperationType(field.operationTypeId, organizedUnitId, this.props.fleet.dossierSubType ?? this.props.fleet.dossierSubTypeId)
      }
    }

    if (field.saleTypeId) {
      this.props.change('codeCommissionId', null)
      var saleCode = this.props.combos.saleTypeCombo.find(x => x.id === field.saleTypeId)?.code
      if (saleCode === saleTypeCodeEnum.AGENCY_MODEL_FLEET_WAGEN || saleCode === saleTypeCodeEnum.AGENCY_MODEL_FLEET_SEAT || saleCode === saleTypeCodeEnum.AGENCY_MODEL_PART_WAGEN || saleCode === saleTypeCodeEnum.AGENCY_MODEL_PART_SEAT) {
        this.setState({ viewCodeCommission: true })
      } else {
        this.setState({ viewCodeCommission: false })
      }
    }

    this.setState({ fields: { ...this.state.fields, ...field } })
  }

  createProcesToChargeDossiersModal (values) {
    this.props.actions.setFleetChargeDossiersProcess(values, this.props.fleet.fleetId, this.props.fleet.fleetCode, this.props.fleet.dossierSubType ?? this.props.fleet.dossierSubTypeId, this.state.files[0], () => {
      this.props.actions.closeChargeDossiersModal()
      this.props.reset('chargeDossiersModal')
      this.setState({ files: [], documentName: null, fields: {} })
    }, this.props.t)
  }

  onDrop (fileToUpload, rejectedFiles) {
    if (fileToUpload.length === 0 && rejectedFiles.length > 0) {
      this.setState({ hasInvalidFile: true })
    } else {
      this.setState({ files: [fileToUpload[0]], hasInvalidFile: false })
      if (fileToUpload[0]) {
        this.buttonUpload = true
        this.buttonReject = true

        this.setState({ documentName: fileToUpload[0].name })
      }
    }
  }

  onDragEnter (panel = false) {
    this.setState(panel ? { dropzonePanelActive: true } : { dropzoneActive: true })
  }

  onDragLeave (panel = false) {
    this.setState(panel ? { dropzonePanelActive: false } : { dropzoneActive: false })
  }

  render () {
    const {
      t,
      showModal, handleSubmit, actions: { closeChargeDossiersModal, fetchTemplateFile }, combos: {
        fleetOrganizedUnits, salesmanCombo, saleTypeCombo,
        operationTypeCombo, vehicletypeCombo
      }, codeCommissionCombo,
      errors, paymentMethodCombo, invalid, reset, pristine, fieldsConfiguration: { codeCommission }
    } = this.props
    const maxSizeFilesDropzone = 10240000

    const tKey = 'MANAGEMENT.FLEETS_TAB.AUX_MODALS.'
    if (showModal) {
      return (
        <Modal className="languages-modal addDosseirToFleet-modal" show={showModal} onHide={ () => {
          closeChargeDossiersModal()
          reset('chargeDossiersModal')
          this.setState({ files: [], documentName: null, fields: {} })
        }} backdrop={'static'}>
          {errors && errors.length > 0 &&
                <Modal className="languages-modal addDosseirToFleet-modal" show={true} onHide={() => { this.props.actions.updateChargeDossiersModal(null) } } backdrop={'static'}>
                  <Modal.Header closeButton>
                    <Modal.Title>{t(`${tKey}CHARGE_DOSSIERS_MODAL.TITTLE_ERROR`)}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Table className="editable-table" hover responsive>
                      <thead>
                        <tr>
                          {Object.keys(errors[0]).map((k, ind) => {
                            return (<th key={ind}>{t(tKey + 'CHARGE_DOSSIERS_MODAL.' + k.toUpperCase())}</th>)
                          })
                          }
                        </tr>
                      </thead>
                      <tbody>
                        {errors.map((er, i) => {
                          return (
                            <tr key={i} className='is-deleted'>
                              {
                                Object.keys(er).map((key, ind) => {
                                  return (<td key={ind}>{er[key]}</td>)
                                })
                              }
                            </tr>
                          )
                        })}
                      </tbody>
                    </Table>
                  </Modal.Body>
                  <Modal.Footer>
                    <Col sm={12} className='fleet-created-moda-btn-exit'>
                      <Button
                        id='btn-close-fleet-created-select-charge-modal'
                        className="btn-standard"
                        onClick={() => {
                          this.props.actions.updateChargeDossiersModal(null)
                        }}>
                        {t(`${tKey}EXIT`)}
                      </Button>
                    </Col>
                  </Modal.Footer>
                </Modal>
          }
          <form className='addDossiersToFleetModal' autoComplete="off" onSubmit={handleSubmit((values) => this.createProcesToChargeDossiersModal(values))}>
            <Modal.Header closeButton onHide={closeChargeDossiersModal}>
              <Modal.Title>{t(`${tKey}CHARGE_DOSSIERS_MODAL.TITLE`)}</Modal.Title>
            </Modal.Header>
            <Modal.Body>

              <div id="addDosssierFleetFinderDiv" className="filters-search-wrapper addDosssierFleetFinderDiv">
                <Row>
                  <Field
                    id="organizedUnitId"
                    name='organizedUnitId'
                    placeholder={t('MANAGEMENT.FLEETS_TAB.ORGANIZED_UNIT')}
                    controlLabel={t('MANAGEMENT.FLEETS_TAB.ORGANIZED_UNIT')}
                    component={InputSelect}
                    options={fleetOrganizedUnits}
                    valueKey="id"
                    labelKey="value"
                    onInputChange={(val) => this.fetchCombos({ organizedUnitId: val })}
                  />
                  <Field
                    id="salesManId"
                    name='salesManId'
                    placeholder={t('DOSSIER_COMPONENTS.SALES.SALESMAN')}
                    controlLabel={t('DOSSIER_COMPONENTS.SALES.SALESMAN')}
                    component={InputSelect}
                    options={salesmanCombo}
                    valueKey="id"
                    labelKey="value"
                  />
                  <Field
                    id="salesmanManagerId"
                    name='salesmanManagerId'
                    placeholder={t('DOSSIER_COMPONENTS.SALES.SALESMAN_MANAGER')}
                    controlLabel={t('DOSSIER_COMPONENTS.SALES.SALESMAN_MANAGER')}
                    component={InputSelect}
                    options={salesmanCombo}
                    valueKey="id"
                    labelKey="value"
                  />
                  <Field
                    id="saleTypeId"
                    name='saleTypeId'
                    placeholder={t('DOSSIER_COMPONENTS.SALES.SALES_TYPE')}
                    controlLabel={t('DOSSIER_COMPONENTS.SALES.SALES_TYPE')}
                    component={InputSelect}
                    options={saleTypeCombo}
                    valueKey="id"
                    labelKey="value"
                    disabled={!saleTypeCombo || saleTypeCombo.length === 0 || !this.state.fields?.organizedUnitId}
                    onInputChange={(val) => this.fetchCombos({ saleTypeId: val })}
                  />
                </Row>
                <Row>
                  <Field
                    id="operationTypeId"
                    name='operationTypeId'
                    placeholder={t('DOSSIER_COMPONENTS.SALES.OPERATION_TYPE')}
                    controlLabel={t('DOSSIER_COMPONENTS.SALES.OPERATION_TYPE')}
                    component={InputSelect}
                    options={operationTypeCombo}
                    valueKey="id"
                    labelKey="value"
                    onInputChange={(val) => this.fetchCombos({ operationTypeId: val })}
                    disabled={!operationTypeCombo || operationTypeCombo.length === 0 || !this.state.fields?.organizedUnitId}
                  />
                  <Field
                    id="paymentMethodId"
                    name='paymentMethodId'
                    placeholder={t('DOSSIER_COMPONENTS.SALES.PAYMENT_FORM')}
                    controlLabel={t('DOSSIER_COMPONENTS.SALES.PAYMENT_FORM')}
                    component={InputSelect}
                    options={paymentMethodCombo}
                    valueKey="id"
                    labelKey="value"
                  />
                  <Field
                    id="vehicleTypeId"
                    name="vehicleTypeId"
                    controlLabel={t('DOSSIER_COMPONENTS.SALES.VEHICLE_TYPE')}
                    placeholder={t('DOSSIER_COMPONENTS.SALES.VEHICLE_TYPE')}
                    valueKey="id"
                    labelKey="value"
                    component={InputSelect}
                    options={vehicletypeCombo}
                  />

                  {this.state.viewCodeCommission && (operationTypeCombo && operationTypeCombo.length > 0 && this.state.fields?.organizedUnitId && this.state.fields?.saleTypeId) && (codeCommission && codeCommission.permission > permissions.hidden) && (
                    <Field
                      id="codeCommissionId"
                      name="codeCommissionId"
                      placeholder={t('MASTERS.CODE_COMMISSION.CODE_COMMISSION')}
                      controlLabel={t('MASTERS.CODE_COMMISSION.CODE_COMMISSION')}
                      component={InputSelect}
                      multi={false}
                      valueKey="commissionCancellationCodeId"
                      labelKey="commissionCancellationCodeDescription"
                      options={codeCommissionCombo}
                    />
                  )}
                </Row>
                <Row>
                  <Field
                    colSm={4}
                    id="signalPayNotRequired"
                    name="signalPayNotRequired"
                    placeholder={t('DOSSIER_COMPONENTS.SALES.SIGNAL_PAY_NOT_REQUIRED')}
                    inline
                    component={InputCheckBox}
                  />
                  <Field
                    colSm={4}
                    id="lopdNotRequired"
                    name="lopdNotRequired"
                    placeholder={t('DOSSIER_COMPONENTS.SALES.LOPD_NOT_REQUIRED')}
                    inline
                    component={InputCheckBox}
                  />
                </Row>
                <Row>
                  <Col sm={12} className="input-dropzone-wrapper">
                    <Row className="form-group">
                      {
                        <Col sm={12} className={'document-edition-modal-middle-side'}>
                          {this.state.isFileLoadDisabled && (invalid || pristine) && <div style={{ color: '#a94442' }}>{t(`${tKey}REQUIRED_MANDATORY_FIELDS`)}</div>}
                          {!invalid && !pristine && this.state.files && this.state.files.length < 1 && <div style={{ color: '#a94442' }}>{t(`${tKey}REQUIRED_FILE`)}</div>}
                          <div className={'input-dropzone'} style={{ height: '100%' }} onClick={() => {
                            this.setState({ isFileLoadDisabled: invalid || pristine })
                            this.props.touch('organizedUnitId', 'saleTypeId', 'operationTypeId', 'paymentMethodId', 'salesManId')
                          }}>
                            {
                              <DropZone
                                id="dropZone"
                                multiple={false}
                                onDrop={(acceptedFiles, rejectedFiles) => this.onDrop(acceptedFiles, rejectedFiles)}
                                disabled={invalid || pristine}
                                accept={settings.loadFleetDossiersFileFormats}
                                maxSize={maxSizeFilesDropzone}
                                className={'input-dropzone ' + this.state.dropzoneActive ? 'active' : ''}
                                onDragEnter={() => this.onDragEnter(false)}
                                onDragLeave={() => this.onDragLeave(false)}
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    {this.state.documentName ? (
                                      <div className="text-wrapper">
                                        {t(`${tKey}SELECTED_FILES`)}: {this.state.documentName}
                                      </div>
                                    ) : (
                                      <div className="text-wrapper modal-subir-fichero-inicio">
                                        <div>
                                          <i className='ico-cloud' />
                                          <h2 key="dropZone-title">{t(`${tKey}DROP_FILES`)}</h2>
                                          <p key="dropZone-subtitle">{t(`${tKey}SELECT_FILES`)}</p>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </DropZone>
                            }
                          </div>
                        </Col>
                      }
                    </Row>
                  </Col>
                  <Button
                    id='btn-download-fleet-excel-template'
                    className="btn-standard btn-download-template"
                    onClick={() => fetchTemplateFile(loadTemplatesNames.fleetDossiers)}
                  >
                    <FontAwesomeIcon className="fas fa-file-download white-txt ico-with-text" icon={faFileArrowDown} />
                    Descargar plantilla
                  </Button>
                </Row>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Col sm={12} className='fleet-created-moda-btn-exit'>
                <Button
                  id="btn_search"
                  className="btn-standard"
                  disabled={(this.state.files && this.state.files.length < 1) || (invalid || pristine)}
                  type="submit">
                  {t(`${tKey}SAVE`)}
                </Button>
                <Button
                  id='btn-close-fleet-created-select-charge-modal'
                  className="btn-standard"
                  onClick={() => {
                    closeChargeDossiersModal()
                    this.props.reset('chargeDossiersModal')
                    this.setState({ files: [], documentName: null, fields: {} })
                  }}>
                  {t(`${tKey}EXIT`)}
                </Button>
              </Col>
            </Modal.Footer>
          </form>
        </Modal>
      )
    } else {
      return null
    }
  }
}

const validate = (values, { t }, props) => {
  const errors = {}

  if (values.organizedUnitId === null || values.organizedUnitId === undefined) {
    errors.organizedUnitId = t('GLOBAL.FORMS.REQUIRED')
  }

  if (values.saleTypeId === null || values.saleTypeId === undefined) {
    errors.saleTypeId = t('GLOBAL.FORMS.REQUIRED')
  }

  if (values.operationTypeId === null || values.operationTypeId === undefined) {
    errors.operationTypeId = t('GLOBAL.FORMS.REQUIRED')
  }

  if (values.paymentMethodId === null || values.paymentMethodId === undefined) {
    errors.paymentMethodId = t('GLOBAL.FORMS.REQUIRED')
  }

  if (values.salesManId === null || values.salesManId === undefined) {
    errors.salesManId = t('GLOBAL.FORMS.REQUIRED')
  }

  if (values.salesmanManagerId && values.salesmanManagerId === values.salesManId) {
    errors.salesmanManagerId = t('MANAGEMENT.FLEETS_TAB.AUX_MODALS.FORMS.SALESMAN_DISTINCT_SALESMAN_MANAGER')
  }

  return errors
}

export default reduxForm({
  form: 'chargeDossiersModal',
  shouldValidate: () => true,
  validate
})(ChargeDossiersModal)
