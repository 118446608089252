
import React, { PureComponent } from 'react'
import { Button, Modal, Col, Row, FormGroup } from 'react-bootstrap'
import InputSelect from '../../commons/form/InputSelect'
import { reduxForm, Field } from 'redux-form'

class SelectVoCompany extends PureComponent {
  constructor () {
    super()
    this.state = {
      selectedCompany: null
    }
  }

  submitSelectVoCompany (dossierId, voCompany) {
    this.props.actions.submitSelectVoCompany(dossierId, voCompany.organizedUnitId)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (!nextProps.showModal && nextProps.showModal !== this.props.showModal) {
      this.props.initialize({})
    }
    if (nextProps.showModal && nextProps.showModal !== this.props.showModal) {
      this.props.actions.fetchVoCompaniesCombo(nextProps.organizedUnitId)

      if (nextProps.organizedUnitId) {
        this.setState({ selectedCompany: nextProps.selectedCompany })
      }
    }
  }

  render () {
    const {
      t, handleSubmit,
      showModal, dossierId,
      voCompaniesCombo
    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.BUTTONS_HEADER.SELECT_VO_COMPANY_MODAL.'

    return (
      <Modal className="selectVoCompany-modal" show={showModal} onHide={this.props.actions.closeSelectVoCompanyModal}>
        <form autoComplete="off" onSubmit={handleSubmit(this.submitSelectVoCompany.bind(this, dossierId))}>
          <Modal.Header closeButton onHide={this.props.actions.closeSelectVoCompanyModal}>
            <Modal.Title>{t(`${tKey + 'TITLE'}`)}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <Row key={1} className="inputs-wrapper">
                <Col sm={4} className="custom-width-col">
                  <h5>{t(`${tKey + 'COMPANY'}`)}</h5>
                  {
                    <Field
                      name="organizedUnitId"
                      placeholder={t(`${tKey + 'COMPANY'}`)}
                      component={InputSelect}
                      multi={false}
                      valueKey="id"
                      labelKey="value"
                      childrenKey="children"
                      options={voCompaniesCombo}
                      colSm={0}
                      onChange={(value) => this.setState({ selectedCompany: value })}
                    />
                  }
                </Col>
              </Row>
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <Col sm={12} className="btn-wrapper">
              <Button
                id="recurrent-entity-modal-submit-button"
                className="btn-standard"
                type="submit"
                disabled={this.props.pristine}
              >
                {t(`${tKey + 'SUBMIT_BUTTON'}`)}
              </Button>
            </Col>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default reduxForm({
  form: 'selectVoCompany',
  validate: (values, props) => {
    const error = { selectVoCompany: {} }
    if (values.selectVoCompany) {
      if (!values.selectVoCompany.voCompanyId) {
        error.selectVoCompany.voCompanyId = props.t('DOSSIER_COMPONENTS.BUTTONS_HEADER.SELECT_VO_COMPANY.VALIDATION.UO_REQUIRED')
      }
    }
    return Object.keys(error.selectVoCompany).length > 0 ? error : {}
  }
})(SelectVoCompany)
