import React, { PureComponent } from 'react'
import { Popover, Overlay, Button, Glyphicon } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { urgencyTypesClass } from '../../constants/backendIds'
import settings from '../../setting'

class DossierPopover extends PureComponent {
  constructor () {
    super()
    this.state = {
      showPopover: false
    }
  }

  handleClick (e) {
    e.stopPropagation()
    this.setState({ target: e.target, showPopover: true })
  }

  render () {
    const {
      t,
      // idx,
      // half,
      id,
      urgencyType,
      dossier = {}
    } = this.props
    const tKey = 'SEARCH.CATEGORIES.'
    return (
      <div>
        {Object.keys(dossier).length > 0 &&
          <Button bsSize="xsmall" className="icon-only" onClick={this.handleClick.bind(this)}><Glyphicon glyph="plus" /></Button>
        }
        {dossier.urlToNavigate ? (
          <Link to={'/dossier' + dossier.urlToNavigate}>
            <span>{dossier.number}</span>
          </Link>
        ) : (
          <span>{dossier.number}</span>
        )}
        <Overlay
          container={this}
          show={this.state.showPopover}
          target={this.state.target}
          onHide={() => this.setState({ showPopover: false })}
          rootClose
          // placement={(idx >= half) ? 'top' : 'bottom'}
          placement={'bottom'}
        >
          <Popover id={id} className="document-count-popover-container">
            {dossier.number &&
              <div className="popover-item">
                <label>{t(`${tKey}DOSSIER.DOSSIER_NUMBER`)}: </label><br />
                <span className={'color-point ' + urgencyTypesClass[urgencyType]} />{' '}{dossier.number}
              </div>
            }
            {dossier.subtype && (
              <div className="popover-item">
                <label>{t(`${tKey}DOSSIER.DOSSIER_SUBTYPE`)}</label><br />
                {dossier.subtype}
              </div>
            )}
            {dossier.stage && (
              <div className="popover-item">
                <label>{t(`${tKey}DOSSIER.STAGE`)}</label><br />
                {dossier.stage}
              </div>
            )}
            {dossier.organizedUnit && (
              <div className="popover-item">
                <label>{t(`${tKey}DOSSIER.ORGANIZED_UNIT`)}</label><br />
                {dossier.organizedUnit}
              </div>
            )}
            {dossier.salesman && (
              <div className="popover-item">
                <label>{t(`${tKey}DOSSIER.SALESMAN`)}</label><br />
                {dossier.salesman}
              </div>
            )}
            {dossier.lastUpdateDate && (
              <div className="popover-item">
                <label>{t(`${tKey}DOSSIER.LAST_CHANGE_DATE`)}</label><br />
                {dossier.lastUpdateDate}
              </div>
            )}
            {(settings.clientFieldValidation === 'vgr' || settings.clientFieldValidation === 'vgr_bcn' || settings.clientFieldValidation === 'qa_vgr' || settings.clientFieldValidation === 'qa_vgr_bcn') && dossier.subtype === t('SEARCH.PAPERWORK') && dossier.dossierParentId && (
              <div className="popover-item">
                <label>{t('SEARCH.DEPENDENCE_OF')}</label><br />
                {dossier.dossierParentSubType}
              </div>
            )}
            {((settings.clientFieldValidation === 'vgr' || settings.clientFieldValidation === 'vgr_bcn' || settings.clientFieldValidation === 'qa_vgr' || settings.clientFieldValidation === 'qa_vgr_bcn')) && dossier.subtype === t('SEARCH.PAPERWORK') && dossier.dossierParentId == null && (
              <div className="popover-item">
                <label>{t('SEARCH.DEPENDENCE_OF')}</label><br />
                {t('SEARCH.NOT_DEPENDENCE')}
              </div>
            )}
            {dossier.deliveryCity && (
              <div className="popover-item">
                <label>{t('DOSSIER_COMPONENTS.DELIVERY.DELIVERY_CITY')}</label><br />
                {dossier.deliveryCity}
              </div>
            )}
          </Popover>
        </Overlay>
      </div>
    )
  }
}

export default DossierPopover
