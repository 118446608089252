import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import ChargeDossiersModal from '../../../components/management/AuxModals/ChargeDossiersModal'
import { openChargeDossiersModal, closeChargeDossiersModal, updateChargeDossiersModal, fetchFleetOrganizedUnits } from '../../../actions/management/auxModals/auxModals'
import { fetchSaleTypeBySubtype, fetchOperationType, fetchSalesman, fetchSaleTypeByOperationType } from '../../../actions/combos/combos'
import { setFleetChargeDossiersProcess } from '../../../actions/unattendedProcess/unattendedProcess'
import { fetchTemplateFile } from '../../../actions/masters/masters'
import { fetchCodeCommissionCombo } from '../../../actions/dossier/common/codeCommisionModal'

export function mapStateToProps (state) {
  return {
    ...state.managementAuxModals.chargeDossiersModal,
    combos: { ...state.combos, ...state.managementAuxModals.combos },
    paymentMethodCombo: state.management.paymentCombo,
    codeCommissionCombo: state.codeCommisionModal.codeCommissionCombo
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      openChargeDossiersModal,
      closeChargeDossiersModal,
      fetchSaleTypeBySubtype,
      fetchOperationType,
      setFleetChargeDossiersProcess,
      updateChargeDossiersModal,
      fetchSalesman,
      fetchSaleTypeByOperationType,
      fetchTemplateFile,
      fetchFleetOrganizedUnits,
      fetchCodeCommissionCombo
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(ChargeDossiersModal))
