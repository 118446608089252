import apiFetch from '../../apiFetch'

export default function (token, dossierId, vehicleIncome) {
  return apiFetch({
    endPoint: `Dossier/Service/${dossierId}/CreateVehicle`,
    method: 'POST',
    body: vehicleIncome,
    token: token
  })
}
