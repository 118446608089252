import React, { PureComponent } from 'react'
import { Button, Form, Tooltip, OverlayTrigger, ProgressBar, Glyphicon } from 'react-bootstrap'
import { Field, reduxForm, reset } from 'redux-form'
import { fromCamelToUnderscore } from '../../../util/utils'
import SimpleTablePage from '../../../containers/commons/general/SimpleTablePage'
import IDocCarPagination from '../../../components/commons/iDocCarPagination'
import { dossierSubTypeId } from '../../../constants/dossier/common/dossierSubType'

import MapSectionsManagement from '../../../_v2/util/map-sections-management'
import InputText from '../../components/commons/form/InputText'
import AddNewFleetModalPage from '../../../containers/management/AddNewFleetModalPage'
import { showFilter, hideFiltersBtn, toggleFilterClass, getIconClass } from '../../../_v2/util/management/ManagementUtils'
class FleetFinder extends PureComponent {
  constructor () {
    super()
    this.state = {
      fields: [],
      filters: [],
      dynamicFilters: [],
      disableResetButton: false,
      displayBar: 'none',
      progress: 0,
      progressFail: 0,
      errors: [],
      maxBar: 0
    }
  }

  componentDidMount () {
    this.props.actions.resetFiltersFleetInfo()
  }

  submitSearchForm (values) {
    let filters = values

    let propFilters = this.props.filters
    propFilters.orderBy = '%2BfleetCode'
    this.props.actions.fetchFleetsInfo({ ...filters, ...propFilters, organizedUnitId: this.props.organizedUnitId })
    this.state.dynamicFilters = filters
    this.setState({ hideFilters: true })
  }

  fieldFill (field) {
    this.setState({ fields: { ...this.state.fields, ...field } })
  }

  selectedFilters () {
    const filters = this.state.dynamicFilters
    const tKey = 'MANAGEMENT.FLEETS_TAB.'
    return Object.keys(filters)
      .map((item, index) => {
        let text = item
        let value = null
        const list = []
        text = this.props.t(`${tKey}${fromCamelToUnderscore(item).toUpperCase()}`)
        value = filters[item]
        if (value) {
          list.push(<li key={index}>{text}: <strong>{value}</strong></li>)
        }
        return list
      })
  }

  onClickResetSearch (e) {
    if (e !== undefined) {
      e.preventDefault()
    }

    this.props.actions.resetFiltersFleetInfo()
  }

  handleClickReset (e) {
    if (e !== undefined) {
      e.preventDefault()
    }

    this.props.dispatch(reset('fleetFinder'))
  }

  exportData () {
    const tKey = 'MANAGEMENT.FLEETS_TAB.'
    let columnsExcel = []

    columnsExcel.push(this.props.t(`${tKey}FLEET`) + '#FleetCode')
    columnsExcel.push(this.props.t(`${tKey}DESCRIPTION`) + '#Description')
    columnsExcel.push(this.props.t(`${tKey}OBSERVATIONS`) + '#Observations')
    columnsExcel.push(this.props.t(`${tKey}ORGANIZEDUNIT`) + '#OrganizedUnit')
    columnsExcel.push(this.props.t(`${tKey}NUM_DOSSIERS`) + '#Dossiers')

    let cols = ''
    columnsExcel.forEach(element => {
      cols += element + '$'
    })
    cols = cols.substring(0, cols.length - 1)

    let filters = this.state.dynamicFilters
    this.props.actions.fetchFleetsInfoDownload({ ...filters, ...this.props.filters, organizedUnitId: this.props.organizedUnitId }, cols)
  }

  onOrderChange (e) {
    const newOrderBy = this.props.filters.orderBy.includes(`%2B${e.currentTarget.dataset.field}`) ? `-${e.currentTarget.dataset.field}` : `%2B${e.currentTarget.dataset.field}`
    this.fetchTable({ ...this.props.filters, orderBy: newOrderBy })
  }

  onPageChange (page) {
    this.fetchTable({ ...this.props.filters, page })
  }

  fetchTable (propFilters) {
    let filters = this.state.dynamicFilters
    this.props.actions.fetchFleetsInfo({ ...filters, ...propFilters, organizedUnitId: this.props.organizedUnitId })
    this.state.dynamicFilters = filters
  }

  deleteFleet (value) {
    if (value.dossiers > 0) {
      this.props.actions.openModal('actionDeleteFleetInfo')
    } else {
      new Promise((resolve, reject) => {
        this.props.actions.deleteFleet(value.fleetId, resolve, reject)
      }).then((response) => {
        if (response) {
          this.fetchTable({ ...this.props.filters })
          this.props.actions.fetchFleetsUser(false)
        }
      }).catch(() => {
        this.props.actions.openModal('actionAdminFleetExpFleetError')
      })
    }
  }

  editFleet (value) {
    this.props.actions.openNewFleetModal(false, null, value.organizedUnitId, value, null)
  }

  assignFleet (value) {
    if (this.dossiersIsFleet()) {
      this.props.actions.openModal('actionAdminFleetExpYetFleet', () => this.assignDossierFleet(value.fleetId))
    } else {
      this.assignDossierFleet(value.fleetId)
    }
  }

  assignDossierFleet (fleetId) {
    this.props.actions.setActionExpFleetProcess(this.props.dossiers.map(d => d.dossierId).join('$'), 1, fleetId, () => { this.props.actions.closeActionFleetFinder(); this.props.fetchFleets(this.props.fleetsFilter) })
  }

  launchNextassignDossierFleet (index, fleetId) {
    if (this.props.dossiers[index]) {
      new Promise((resolve, reject) => {
        return this.props.actions.asignDossierToFleet(this.props.dossiers[index].dossierId, fleetId, resolve, reject)
      }).then((response) => {
        this.setState({ progress: this.state.progress + 1 })
        this.launchNextassignDossierFleet(index + 1, fleetId)
      }).catch((error) => {
        this.setState({ errors: [...this.state.errors, error.json.message] })
        this.setState({ progressFail: this.state.progressFail + 1 })
        this.launchNextassignDossierFleet(index + 1, fleetId)
      })
    } else {
      let errors = ''
      this.state.errors.forEach(x => {
        var errormessage = this.props.t('MESSAGES.ACTION_FLEET_ENTITY_ERROR')
        var ob = JSON.parse(x)
        for (var key in ob) {
          errormessage = errormessage.replace('##' + key + '##', ob[key])
        }
        errors = errors + '\n' + errormessage
      })

      this.props.actions.openModal('actionAdminFleetExp', () => { this.props.actions.closeActionFleetFinder(); this.props.fetchFleets(this.props.fleetsFilter) }, null, null, null, [{ id: '##NUM_OK##', value: this.state.progress }, { id: '##NUM_TOTAL##', value: this.props.dossiers.length }, { id: '##ERRORS##', value: errors }], null)
    }
  }

  dossiersIsFleet () {
    let dossiers = this.props.dossiers
    let isFleet = false
    dossiers.forEach(x => {
      if (x.fleetCode !== null && x.fleetCode !== undefined) {
        isFleet = true
      }
    })
    return isFleet
  }

  toogleHideFilters () {
    this.setState({ hideFilters: !this.state.hideFilters })
  }

  toogleMaxiTable () {
    this.setState({ maxiTable: !this.state.maxiTable })
  }

  render () {
    const {
      t, className, count, dossierData, filters: { page, pages }, handleSubmit, hasSearch
    } = this.props
    const tKey = 'MANAGEMENT.FLEETS_TAB.'
    const secMapManagement = MapSectionsManagement.find(function (s) { return s.page === 'fleetFinderPage' })
    const deleteTooltip = (<Tooltip id="deleteTooltip">{t(`${tKey}DELETE_FLEET`)}</Tooltip>)
    const editTooltip = (<Tooltip id="editTooltip">{t(`${tKey}EDIT_FLEET`)}</Tooltip>)
    const asignTooltip = (<Tooltip id="asignTooltip">{t(`${tKey}ASIGN_FLEET`)}</Tooltip>)
    const hasResults = dossierData && dossierData.length > 0
    let dossier = { dossierSubTypeId: dossierSubTypeId.venta_vn }

    return (
      <>
        <div className="inner-results-panel">
          <div className="section-results-header">
            <div className="section-header-title">
              <OverlayTrigger placement="right" overlay={<Tooltip id="editProductTooltip">{`${t((this.props.hideMenu ? 'GLOBAL.TABLES.SHOW_INNER_MENU' : 'GLOBAL.TABLES.HIDE_INNER_MENU'))}`}</Tooltip>}>
                <a onClick={() => this.props.toogleInnerMenu()} className={this.props.hideMenu ? 'collapseBtn' : 'collapseBtn closeInnerMenu'}><i className={this.props.hideMenu ? 'iDocIcon-Open-inner-menu' : 'iDocIcon-Close-inner-menu'}></i></a>
              </OverlayTrigger>
              <h4>{t(`${secMapManagement.title}`)}
                <OverlayTrigger placement="left" overlay={<Tooltip id="editProductTooltip">{`${t((this.state.hideFilters ? 'GLOBAL.TABLES.SHOW_FILTERS' : 'GLOBAL.TABLES.HIDE_FILTERS'))}`}</Tooltip>}>
                  <a onClick={() => this.toogleHideFilters()} className={'toogleShowFilters ' + hideFiltersBtn(hasResults)}><i className={toggleFilterClass(hasResults, this.state.hideFilters)}></i></a>
                </OverlayTrigger>
              </h4>
            </div>
            <Form autoComplete="off" onSubmit={handleSubmit((values) => this.submitSearchForm(values))} className="filters">
              <div className={'search-form ' + (showFilter(hasResults, this.state.hideFilters) ? '' : 'hideFilters ')}>
                <Field
                  component={InputText}
                  colWidht={50} // 25, 33, 50, 75, 100 default=25
                  id="fleetCode"
                  name='fleetCode'
                  placeholder={t(`${tKey}FLEET`)}
                  controlLabel={t(`${tKey}FLEET`)}
                  type="text"
                  onInputChange={(val) => this.fieldFill({ fleetCode: val })}
                  customClass={this.state.fields.fleetCode ? className : ''}
                />
                <Field
                  component={InputText}
                  colWidht={50} // 25, 33, 50, 75, 100 default=25
                  id="description"
                  name='description'
                  placeholder={t(`${tKey}DESCRIPTION`)}
                  controlLabel={t(`${tKey}DESCRIPTION`)}
                  type="text"
                  onInputChange={(val) => this.fieldFill({ description: val })}
                  customClass={this.state.fields.description ? className : ''}
                />
                <div className='search-form-actions flexInLine al-R'>
                  <Button
                    id="btn_search"
                    type="submit"
                    className="btn-standard"
                  >
                    <i className="ico-search"/>
                    {t('SEARCH.ACTIONS.SEARCH_BUTTON')}
                  </Button>
                  <Button
                    id="btn_reset"
                    onClick={(e) => this.handleClickReset(e)}
                    className={'btn-white btn-icon' + (this.props.pristine ? ' btn-cancel' : ' btn-standard')}
                    disabled={this.props.pristine}
                  >
                    <i className="ico-cancel"/> {t('SEARCH.ACTIONS.RESET_BUTTON')}
                  </Button>
                </div>
              </div>
            </Form>
          </div>
          <div className="section-results-body">
            <div className={'searchResoultsContainer' + (this.state.maxiTable ? ' maxiTable' : '')}>
              {hasSearch && count > 0 &&
                <div className="table-wrapper" key="table-wrapper">
                  <ul className="list-inline pull-left">
                    {this.selectedFilters().length === 0 ? <li>{t('MANAGEMENT.FLEETS_TAB.NO_SELECTED_FILTERS')}</li> : (this.selectedFilters())}
                  </ul>
                  {/* table options, maximize, etc.... */}
                  <div className='flexInLine al-R'>
                    <div className='actionsField'>
                    </div>
                    <OverlayTrigger placement="left" overlay={<Tooltip id="editProductTooltip">{`${t((this.state.maxiTable ? 'GLOBAL.TABLES.MINIMIZE_TABLE' : 'GLOBAL.TABLES.MAXIMIZE_TABLE'))}`}</Tooltip>}>
                      <a onClick={() => this.toogleMaxiTable()} className='_Btn accept stk o-icn maximizeBtn'><i className={(this.state.maxiTable ? 'iDocIcon-Minimize' : 'iDocIcon-Maximize')}></i></a>
                    </OverlayTrigger>
                    <OverlayTrigger placement="left" overlay={<Tooltip id="editProductTooltip">{t('SEARCH.ACTIONS.EXPORT')}</Tooltip>}>
                      <a onClick={(event) => this.exportData(event)} className='_Btn new o-icn exportToExcel'><i className="iDocIcon-file-excel"></i></a>
                    </OverlayTrigger>
                  </div>
                  {/* table */}

                  <div className="table-responsive">
                    <SimpleTablePage
                      columns={[
                        <th key={0} data-field="fleetCode" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}FLEET`)}&nbsp;<i className={getIconClass('fleetCode', this.props.filters)}></i>
                        </th>,
                        <th key={1} data-field="description" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}DESCRIPTION`)}&nbsp;<i className={getIconClass('description', this.props.filters)}></i>
                        </th>,
                        <th key={2} data-field="observations" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}OBSERVATIONS`)}&nbsp;<i className={getIconClass('observations', this.props.filters)}/>
                        </th>,
                        <th key={3} data-field="organizedUnit" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}ORGANIZEDUNIT`)}&nbsp;<i className={getIconClass('organizedUnit', this.props.filters)}></i>
                        </th>,
                        <th key={4} data-field="dossiers" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}NUM_DOSSIERS`)}&nbsp;<i className={getIconClass('dossiers', this.props.filters)}/>
                        </th>,
                        <th key={12} data-field="actions" className='actions'>{t(`${tKey}ACTIONS`)}&nbsp;</th>
                      ]}
                      rows={dossierData && dossierData.map((row, idx) => (
                        <tr key={idx}>
                          <td>
                            {row.fleetCode}
                          </td>
                          <td>
                            {row.description}
                          </td>
                          <td>{row.observations !== null && row.observations !== undefined ? row.observations.length < 70 ? row.observations
                            : <> {row.observations.substring(0, 70)}... <OverlayTrigger trigger={['hover', 'focus']} placement="bottom" overlay={<Tooltip id="tooltip-trigger-hover-focus">{row.observations}</Tooltip>}>
                              <Glyphicon glyph="info-sign" className="tooltip-function"></Glyphicon>
                            </OverlayTrigger> </> : '' }</td>
                          <td>
                            {row.organizedUnit}
                          </td>
                          <td>
                            {row.dossiers}
                          </td>
                          <td className='actions'>
                            {!this.props.showModal && (
                              <div>
                                <a onClick={() => this.editFleet(row)} className="_Btn accept stk o-icn noLine">
                                  <OverlayTrigger placement="left" overlay={editTooltip}>
                                    <i className="iDocIcon-edit" />
                                  </OverlayTrigger>
                                </a>
                                <a onClick={() => this.deleteFleet(row)} className="_Btn cancel stk o-icn noLine">
                                  <OverlayTrigger placement="left" overlay={deleteTooltip}>
                                    <i className="iDocIcon-delete-forever" />
                                  </OverlayTrigger>
                                </a>
                              </div>)}
                            {this.props.showModal && (
                              <div >
                                <a onClick={() => this.assignFleet(row)} className="_Btn new stk o-icn noLine">
                                  <OverlayTrigger placement="left" overlay={asignTooltip}>
                                    <i className="iDocIcon-add-plus" />
                                  </OverlayTrigger>
                                </a>
                              </div>
                            )}
                          </td>
                        </tr>
                      ))}
                    />
                  </div>

                  <div className="search-footer" key="search-footer">
                    <IDocCarPagination
                      id="btn_pag_sales"
                      page={page}
                      pagesCount={pages}
                      totalRows= {count}
                      onSelectAction={(page) => this.onPageChange(page)}
                    />
                  </div>
                </div>
              }
              {hasSearch && count <= 0 &&
              <>
                <ul className="list-inline pull-left">
                  {this.selectedFilters().length === 0 ? <li>{t(`${tKey}NO_SELECTED_FILTERS`)}</li> : (this.selectedFilters())}
                </ul>
                <p className='no_results'>{t('GLOBAL.TABLES.NO_RESULTS')}</p>
              </>
              }
              <AddNewFleetModalPage dossier={dossier} ></AddNewFleetModalPage>
              <ProgressBar style={{ display: this.state.displayBar }}>
                <ProgressBar max={this.state.maxBar} now={this.state.progress} label={`${this.state.progress}/${this.state.maxBar}`} striped variant="success" key={1} />
                <ProgressBar max={this.state.maxBar} now={this.state.progressFail} label={`${this.state.progressFail}/${this.state.maxBar}`} striped variant="danger" style={{ backgroundColor: '#d44b4b' }} key={2} />
              </ProgressBar>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default reduxForm({
  form: 'fleetFinder'
})(FleetFinder)
