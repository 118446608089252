import React, { PureComponent } from 'react'
import { Field } from 'redux-form'
import { Row, Clearfix, OverlayTrigger, Button, Tooltip, Glyphicon } from 'react-bootstrap'
import InputSelect from '../commons/form/InputSelect'
import InputDatePicker from '../commons/form/InputDatePicker'
import { validateDate } from '../../util/validationFunctions'
import SimpleTablePage from '../../containers/commons/general/SimpleTablePage'
import IDocCarPagination from '../commons/iDocCarPagination'
import { dossierStagesId } from '../../constants/dossier/common/dossierStages'
import { formatDate } from '../../util/utils'
import { Link } from 'react-router-dom'
import setting from '../../setting'
import { clientInformation } from '../../constants/backendIds'

class SearchByPrepareForm extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      fieldsConfiguration: {
      },
      stages: [],
      serviceRoleConfig: null
    }
  }

  validateDate (value, allValues, props) {
    const result = validateDate(value, props)
    return result === Object(result) ? undefined : result
  }

  exportData () {
    const tKey = 'SEARCH.CATEGORIES.SERVICE.'
    let columnsExcel = []
    columnsExcel.push(this.props.t(`${tKey}DOSSIER_NUMBER`) + '#Number')
    columnsExcel.push(this.props.t(`${tKey}STAGE`) + '#Stage')
    columnsExcel.push(this.props.t(`${tKey}ORDER_DATE`) + '#OrderDate')
    columnsExcel.push(this.props.t(`${tKey}REQUIRED_DATE`) + '#RequiredDate')
    columnsExcel.push(this.props.t(`${tKey}CONFIRMED_DATE`) + '#ConfirmedDate')
    if (setting.clientFieldValidation === clientInformation.VGR) {
      columnsExcel.push(this.props.t(`${tKey}OR_NUMBER`) + '#ORNumber')
    }
    columnsExcel.push(this.props.t(`${tKey}SALE_OBSERVATIONS`) + '#SaleObservations')
    columnsExcel.push(this.props.t(`${tKey}PROVIDER_OBSERVATIONS`) + '#ProviderObservations')
    this.props.exportToExcel(columnsExcel)
  }

  componentDidMount () {

  }

  componentDidUpdate () {
    // Required for getSnapshotBeforeUpdate
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (prevProps.combos.stagesCombo !== this.props.combos.stagesCombo && this.props.combos.stagesCombo.length > 0 && this.state.stages.length === 0) {
      this.setState({ stages: this.props.combos.stagesCombo })
    }
    if (this.props.serviceRoleConfig &&
      ((JSON.stringify(prevProps.serviceRoleConfig) !== JSON.stringify(this.props.serviceRoleConfig)) ||
      !this.state.serviceRoleConfig)) {
      if (this.props.serviceRoleConfig.setStage) {
        let stages = []
        this.state.stages.map(x => {
          if (this.props.serviceRoleConfig.stages.includes(x.id)) {
            stages.push(x)
          }
        })
        let serviceRoleConfigStagesChanged = false
        if (JSON.stringify(stages) !== JSON.stringify(this.state.stages)) {
          this.setState({ stages: stages })
          serviceRoleConfigStagesChanged = true
        }
        const stageFilterIsCleared = (!this.props.fields.stage || this.props.fields.stage.length < 0) && stages.length > 0
        if (stageFilterIsCleared || serviceRoleConfigStagesChanged) {
          let arrStages = []
          arrStages.push(stages[0]?.id)
          this.props.fieldFill({ stage: arrStages })
          this.props.change('servicePreparationDelivery.stageId', arrStages)
        }
      }
      if (this.props.serviceRoleConfig.setDate) {
        let strdate = formatDate()
        if (this.props.fields.requiredDateFrom === undefined) {
          this.props.fieldFill({ requiredDateFrom: strdate })
          this.props.change('servicePreparationDelivery.requiredDateFrom', strdate)
        }
        if (this.props.fields.requiredDateTo === undefined) {
          this.props.fieldFill({ requiredDateTo: strdate })
          this.props.change('servicePreparationDelivery.requiredDateTo', strdate)
        }
      }
    }

    return null
  }

  getActions (row) {
    const tKey = 'SEARCH.CATEGORIES.SERVICE.'
    switch (row.actions) {
      case '0':
        return <OverlayTrigger id={row.dossierId} trigger={['hover', 'focus']} placement="top" overlay={<Tooltip id="tooltip-trigger-hover-focus">{this.props.t(`${tKey}ALERT_ACTION`)}</Tooltip>}>
          {
            row.url ? (
              <Link to={`/dossier${row.url}`}>
                <Glyphicon glyph="alert" className="tooltip-function"></Glyphicon>
              </Link>
            ) : <Glyphicon glyph="alert" className="tooltip-function"></Glyphicon>
          }
        </OverlayTrigger>
      case dossierStagesId.service_preparation_delivery_request.toString() :
        return <Button
          onClick={(event) => this.requestParts(row)}
          id={row.dossierId}
          bsStyle="default"
          bsSize="small"
          className="pull-left"
        ><OverlayTrigger id={row.dossierId + 'ot'} trigger={['hover', 'focus']} placement="top" overlay={<Tooltip id="tooltip-trigger-hover-focus">{this.props.t(`${tKey}REQUEST_PARTS`)}</Tooltip>}>
            <Glyphicon glyph="arrow-right" className="tooltip-function"></Glyphicon>
          </OverlayTrigger></Button>
      case dossierStagesId.service_preparation_delivery_confirmation.toString():
        return <Button
          id={row.dossierId}
          onClick={(event) => this.completedParts(row)}
          bsStyle="default"
          bsSize="small"
          className="pull-left"
        ><OverlayTrigger id={row.dossierId + 'ot'} trigger={['hover', 'focus']} placement="top" overlay={<Tooltip id="tooltip-trigger-hover-focus">{this.props.t(`${tKey}COMPLETE_PARTS`)}</Tooltip>}>
            <Glyphicon glyph="arrow-right" className="tooltip-function"></Glyphicon>
          </OverlayTrigger></Button>
      case dossierStagesId.service_preparation_delivery_realization.toString():
        return <Button
          id={row.dossierId}
          onClick={(event) => this.completPrepare(row)}
          bsStyle="default"
          bsSize="small"
          className="pull-left"
        ><OverlayTrigger id={row.dossierId + 'ot'} trigger={['hover', 'focus']} placement="top" overlay={<Tooltip id="tooltip-trigger-hover-focus">{this.props.t(`${tKey}PREPARE_COMPLETE`)}</Tooltip>}>
            <Glyphicon glyph="arrow-right" className="tooltip-function"></Glyphicon>
          </OverlayTrigger></Button>
      default:
        return ''
    }
  }

  requestParts (row) {
    new Promise((resolve) => {
      this.props.actions.serviceRequestSpareParts(null, row.dossierId, resolve)
    }).then((result) => {
      if (result) {
        if (result === true || result === false) {
          this.props.onClickResetSearch()
        }
      }
    })
  }

  completedParts (row) {
    new Promise((resolve) => {
      this.props.actions.serviceCompleteSpareParts(null, row.dossierId, resolve)
    }).then((result) => {
      if (result) {
        this.props.onClickResetSearch()
      }
    })
  }

  completPrepare (row) {
    new Promise((resolve) => {
      this.props.actions.serviceCompleteDeliveryPreparation(null, row.dossierId, resolve)
    }).then((result) => {
      if (result) {
        this.props.onClickResetSearch()
      }
    })
  }

  validateStage (value, allValues, props) {
    if (props.serviceRoleConfig !== undefined && props.serviceRoleConfig !== null && props.serviceRoleConfig.setStage) {
      if (value !== undefined && value !== null && value.length === 0) {
        return props.t('SEARCH.CATEGORIES.SERVICEPREPARATIONDELIVERY.STAGE_VALIDATION')
      }
    }
  }

  render () {
    const {
      t, fields, fieldFill, className, classNameSelect, hasSearch, prepareData, getIconClass, selectedFilters, onClickResetSearch, pages, page, count, onPageChange, onOrderChange
    } = this.props
    const tKey = 'SEARCH.CATEGORIES.SERVICE.'
    return (
      <div>
        {!hasSearch ? (
          <Row>
            <Field
              id="stage"
              name='servicePreparationDelivery.stageId'
              placeholder={t(`${tKey}STAGE`)}
              controlLabel={t(`${tKey}STAGE`)}
              component={InputSelect}
              menuContainerStyle={{ zIndex: 999 }}
              options={this.state.stages}
              valueKey="id"
              labelKey="value"
              multi
              disabled={this.state.stages.length === 0}
              onInputChange={(val) => fieldFill({ stage: val })}
              customClass={fields.dossierSubType && fields.stage ? classNameSelect : ''}
              validate={[this.validateStage]}
            />
            <Field
              colSm={3}
              id="requiredDateFrom"
              name='servicePreparationDelivery.requiredDateFrom'
              placeholder={t(`${tKey}REQUIRED_DATE_FROM`)}
              controlLabel={t(`${tKey}REQUIRED_DATE_FROM`)}
              component={InputDatePicker}
              validate={[this.validateDate]}
              onInputChange={(val) => fieldFill({ requiredDateFrom: val })}
              customClass={fields.requiredDateFrom ? className : ''}
            />
            <Field
              colSm={3}
              id="requiredDateTo"
              name='servicePreparationDelivery.requiredDateTo'
              placeholder={t(`${tKey}REQUIRED_DATE_TO`)}
              controlLabel={t(`${tKey}REQUIRED_DATE_TO`)}
              component={InputDatePicker}
              validate={[this.validateDate]}
              onInputChange={(val) => fieldFill({ requiredDateTo: val })}
              customClass={fields.requiredDateTo ? className : ''}
            />
          </Row>
        ) : ([
          <Row className="filters-selected" key="filters-selected">
            <ul className="list-inline pull-left">
              {selectedFilters().length === 0 ? <li>{t(`${tKey}NO_SELECTED_FILTERS`)}</li> : (selectedFilters())}
            </ul>
            <Clearfix/>
            <Button
              onClick={(event) => onClickResetSearch(event)}
              bsStyle="default"
              bsSize="small"
              className="pull-left"
              id='modSearchButtonIdPrepare'
            >
              {t('SEARCH.ACTIONS.MODIFY_SEARCH')}
              <i className="ico-edit-turquoise" />
            </Button>
            {count > 0 &&
            <Button
              onClick={(event) => this.exportData(event)}
              bsStyle="default"
              bsSize="small"
              id='expSearchButtonIdPrepare'>
              {t('SEARCH.ACTIONS.EXPORT')}
              <i className="ico-if_Download_1031520" />
            </Button>
            }
          </Row>,
          <div className="table-wrapper" key="table-wrapper">
            <SimpleTablePage
              columns={[
                <th key={0} data-field="number" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}DOSSIER_NUMBER`)}&nbsp;<i className={getIconClass('number')}/>
                </th>,
                <th key={1} data-field="stage" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}STAGE`)}&nbsp;<i className={getIconClass('stage')}/>
                </th>,
                <th key={2} data-field="orderDate" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}ORDER_DATE`)}&nbsp;<i className={getIconClass('orderDate')}/>
                </th>,
                <th key={3} data-field="requiredDate" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}REQUIRED_DATE`)}&nbsp;<i className={getIconClass('requiredDate')}/>
                </th>,
                <th key={4} data-field="confirmedDate" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}CONFIRMED_DATE`)}&nbsp;<i className={getIconClass('confirmedDate')}/>
                </th>,
                setting.clientFieldValidation === clientInformation.VGR && (<th key={5} data-field="orNumber" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}OR_NUMBER`)}&nbsp;<i className={getIconClass('orNumber')}/>
                </th>),
                <th key={6} data-field="saleObservations" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}SALE_OBSERVATIONS`)}&nbsp;<i className={getIconClass('saleObservations')}/>
                </th>,
                <th key={7} data-field="providerObservations" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}PROVIDER_OBSERVATIONS`)}&nbsp;<i className={getIconClass('providerObservations')} />
                </th>,
                <th key={8} data-field="actions" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}ACTIONS`)}&nbsp;<i className={getIconClass('actions')} />
                </th>
              ]}
              rows={prepareData.map((row, idx) => (
                <tr key={idx}>
                  <td>
                    <Link to={`/dossier${row.url}`}>
                      {row.number}
                    </Link>
                  </td>
                  <td>{row.stage}</td>
                  <td>{row.orderDate}</td>
                  <td>{row.requiredDate}</td>
                  <td>{row.confirmedDate}</td>
                  <td>{row.orNumber}</td>
                  <td>{row.saleObservations !== null && row.saleObservations !== undefined ? row.saleObservations.length < 30 ? row.saleObservations
                    : <> {row.saleObservations.substring(0, 25)}... <OverlayTrigger trigger={['hover', 'focus']} placement="bottom" overlay={<Tooltip id="tooltip-trigger-hover-focus">{row.saleObservations}</Tooltip>}>
                      <Glyphicon glyph="info-sign" className="tooltip-function"></Glyphicon>
                    </OverlayTrigger> </> : '' }</td>
                  <td>{row.providerObservations !== null && row.providerObservations !== undefined ? row.providerObservations.length < 30 ? row.providerObservations
                    : <> {row.providerObservations.substring(0, 25)}... <OverlayTrigger trigger={['hover', 'focus']} placement="bottom" overlay={<Tooltip id="tooltip-trigger-hover-focus">{row.providerObservations}</Tooltip>}>
                      <Glyphicon glyph="info-sign" className="tooltip-function"></Glyphicon>
                    </OverlayTrigger> </> : '' }</td>
                  <td>{this.getActions(row)}
                  </td>
                </tr>
              ))}
            />
          </div>,
          <div className="search-footer" key="search-footer">
            <IDocCarPagination
              id="btn_pag_sales"
              page={page}
              pagesCount={pages}
              totalRows= {count}
              onSelectAction={(page) => onPageChange(page)}
            />
          </div>
        ])}
      </div>
    )
  }
}

export default SearchByPrepareForm
