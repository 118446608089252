import React, { PureComponent } from 'react'
import { Panel, Row, Col, Button, Tooltip, OverlayTrigger, Glyphicon } from 'react-bootstrap'
import InputSelect from '../../commons/form/InputSelect'
import InputText from '../../commons/form/InputText'
import InputNumber from '../../commons/form/InputNumber'
import InputDatePicker from '../../commons/form/InputDatePicker'
import SimpleInputText from '../../commons/simpleForm/SimpleInputText'
import OrderModalPage from '../../../containers/dossiers/common/vehicle/OrderModalPage'
import { Field } from 'redux-form'
import { validateAlfanumeric, validateDate, validateVinChassis, validateLicensePlate as validateLicensePlateFunciton } from '../../../util/validationFunctions'
import { multipleNormalize, toUpperCase, trimAll } from '../../../util/normalizeFunctions'
import { compareObjects } from '../../../util/utils'
import { getFieldsPermisions } from '../../../util/permisionFunctions'
import { permissions, clientInformation } from '../../../constants/backendIds'
import { dossierTypes as dossierTypesConstant } from '../../../constants/dossier/common/dossierTypes'
import { dossierSubTypeId as dossierSubTypeIdConstants } from '../../../constants/dossier/common/dossierSubType'
import SaleRequestFields from './saleRequestFields/SaleRequestFields'
import settings from '../../../setting'
import InputCheckbox from '../../commons/form/InputCheckBox'
import { modelLength } from '../../../constants/dossier/common/vehicle'
import CustomFields from '../../commons/customFields/CustomFields'
import VehicleSelectionModal from './vehicle/VehicleSelectionModal'
import VehicleCreationModal from './vehicle/VehicleCreationModal'

class Vehicle extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      open: false,
      orderOpen: true,
      justOpened: true,
      vehicleProps: null,
      noEditableVehicle: this.props.parentDossierType.isService ? false : !!(props.parentDossierType.isSales || props.parentDossierType.isRegistrationDelivery || !props.parentDossierType.isPurchase || !!(!props.parentDossierType.isRegistrationDelivery && props.dossierType !== dossierTypesConstant.purchase && props.dossierParentId)),
      idDealerNonEditable: !!(props.parentDossierType.isPaperwork || props.parentDossierType.isCampaign),
      fieldsConfiguration: {
        dealerNumber: null,
        vinChassis: null,
        vinChassisLeft: null,
        vinChassisRight: null,
        licensePlate: null,
        comercialCode: null,
        brandId: null,
        model: null,
        color: null,
        tapestry: null,
        fuelId: null,
        disabledValidateLicensePlate: null,
        firstLicensePlateDate: null,
        makerNumber: null,
        availabilityDate: null,
        expirationItvDate: null,
        originId: null,
        destinationId: null,
        updated: false,
        button_vehicle_order: null,
        button_delete_vehicle: null,
        brandReference: null,
        originalLicensePlate: null,
        countryId: null,
        family: null,
        searchByDealerNumber: null,
        carlineId: null,
        catalogueId: null,
        modelId: null,
        colorId: null,
        tapestryId: null,
        catalogue: null,
        carline: null,
        modelYear: null,
        provisionalLicensePlate: null
      },
      disabledValidateLicensePlate: false
    }
    this.validateLicensePlate = this.validateLicensePlate.bind(this)
    this.vinChassisValidation = this.vinChassisValidation.bind(this)
  }

  searchVehicle () {
    if (this.props.searchedVehicleProps.searchDealerNumber || this.props.searchedVehicleProps.searchVinChassis || this.props.searchedVehicleProps.searchLicensePlate || this.props.searchedVehicleProps.searchMakerNumber) {
      this.props.actions.searchVehicle(
        this.props.searchedVehicleProps.searchDealerNumber,
        this.props.searchedVehicleProps.searchVinChassis,
        this.props.searchedVehicleProps.searchLicensePlate,
        this.props.searchedVehicleProps.searchMakerNumber,
        this.props.parentDossierType,
        this.props.dossierWithVehicle,
        this.props.organizedUnitId,
        this.props.dossierSubType,
        this.props.pristine
      )
    } else {
      this.props.actions.setSimpleInputTextValidation(' ')
    }
  }

  initializePermisions (props) {
    const fieldKeys = Object.keys(this.state.fieldsConfiguration)
    const newState = getFieldsPermisions(props.componentConfiguration.fieldsConfiguration, fieldKeys, props.componentConfiguration.permission)

    this.setState({ ...this.state, ...newState })
  }

  UNSAFE_componentWillMount () {
    this.initializePermisions(this.props)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.open) {
      this.setState({ open: true })
    }
    if (nextProps.dossierId && !compareObjects(this.props.componentConfiguration || {}, nextProps.componentConfiguration, true)) {
      this.initializePermisions(nextProps)
    }

    if ((nextProps.formValues && this.props.formValues && nextProps.formValues.comercialCode && this.props.formValues.comercialCode && this.props.formValues.comercialCode !== nextProps.formValues.comercialCode && this.props.codeVehicleConfiguration) ||
      (!this.props.formValues && nextProps.formValues && nextProps.formValues.comercialCode && this.props.codeVehicleConfiguration) ||
      (this.props.comercialCode && nextProps.comercialCode && this.props.comercialCode !== nextProps.comercialCode && this.props.codeVehicleConfiguration) ||
      (!this.props.comercialCode && nextProps.comercialCode && this.props.codeVehicleConfiguration) && this.props.dossierSubType !== dossierSubTypeIdConstants.tramite) {
      this.props.actions.fetchComboOrigin(nextProps.comercialCode || nextProps.formValues.comercialCode, nextProps.dossierSubType)
      this.props.actions.fetchComboDestination(nextProps.comercialCode || nextProps.formValues.comercialCode, nextProps.dossierSubType)
    }
    if ((this.state.justOpened && nextProps.dossierParentId) || (this.props.dossierParentId !== nextProps.dossierParentId && nextProps.dossierParentId)) {
      this.setState({ orderOpen: false })
      this.state.justOpened = false
    }
    if (this.props.dossierParentId !== nextProps.dossierParentId && !nextProps.dossierParentId) {
      this.setState({ orderOpen: true })
    }
    if ((!this.props.formValues && nextProps.formValues && nextProps.formValues.disabledValidateLicensePlate) ||
      (this.props.formValues && nextProps.formValues && nextProps.formValues.disabledValidateLicensePlate &&
        (this.props.formValues.disabledValidateLicensePlate !== nextProps.formValues.disabledValidateLicensePlate ||
          (!this.state.disabledValidateLicensePlate && nextProps.formValues.disabledValidateLicensePlate)))) {
      this.setState({ disabledValidateLicensePlate: nextProps.formValues.disabledValidateLicensePlate })
    }

    this.setState({
      noEditableVehicle:
        (this.props.parentDossierType.isSales ||
          (this.props.parentDossierType.isPaperwork && this.props.dossierParentId) ||
          this.props.parentDossierType.isCampaign ||
          this.props.parentDossierType.isService ||
          this.props.parentDossierType.isRegistrationDelivery ||
          this.props.parentDossierType.isStock),

      idDealerNonEditable: !!(this.props.parentDossierType.isPaperwork || this.props.parentDossierType.isCampaign)
    })
  }

  componentDidUpdate (prevProps) {
    if (this.props.isVehicleLoaded === false && this.state.open) {
      this.loadVehicle()
    }
  }

  componentWillUnmount () {
    this.props.actions.resetSearchFields()
  }

  validateIdPurchase () {
    const error = {}
    error.dealerNumber = {}
    if (this.props.searchedVehicleProps.searchDealerNumber) {
      const idDealeError = validateAlfanumeric(this.props.searchedVehicleProps.searchDealerNumber, this.props, false)
      if (Object.keys(idDealeError).length > 0) {
        error.dealerNumber = idDealeError
        return error
      }
    }
  }

  fetchSalesDossierPromiseVehicle () {
    if (this.state.open) {
      this.props.actions.setActiveSection(false)
    }
    this.setState({
      open: !this.state.open
    })
    if (this.props.isVehicleLoaded === false) {
      return this.loadVehicle()
    }
  }

  loadVehicle () {
    return new Promise((resolve, reject) => {
      this.props.handleToUpdateComponent(this.props)
      if (this.props.dossierType === dossierTypesConstant.sales) {
        return this.props.actions.fetchSalesVehicle(this.props.dossierId, resolve, reject, this.props.pristine)
      } else if (this.props.dossierType === dossierTypesConstant.purchase) {
        return this.props.actions.fetchPurchaseVehicle(this.props.dossierId, resolve, reject, this.props.pristine)
      } else if (this.props.dossierType === dossierTypesConstant.paperwork) {
        return this.props.actions.fetchPaperworkVehicle(this.props.dossierId, resolve, reject, false, this.props.pristine)
      } else if (this.props.dossierType === dossierTypesConstant.campaign) {
        return this.props.actions.fetchCampaignVehicle(this.props.dossierId, resolve, reject, this.props.pristine)
      } else if (this.props.dossierType === dossierTypesConstant.registrationDelivery) {
        return this.props.actions.fetchRegistrationDeliveryVehicle(this.props.dossierId, resolve, reject, this.props.pristine)
      } else if (this.props.dossierType === dossierTypesConstant.service) {
        this.props.actions.fetchServiceDossierVehicle(this.props.dossierId, resolve, reject, this.props.pristine)
      }
    })
  }

  componentDidMount () {
    if (this.props.actions.fecthFamily !== null && this.props.actions.fecthFamily !== undefined) {
      this.props.actions.fecthFamily()
    }
  }

  dateValidation (value, allValues, props) {
    const result = validateDate(value, props)
    return result === Object(result) ? undefined : result
  }

  normalizeLicensePlate (value) {
    multipleNormalize(value, [toUpperCase, trimAll])
  }

  changeBoolLicensePlate (value) {
    this.setState({ disabledValidateLicensePlate: value })
  }

  validateLicensePlate (value) {
    return validateLicensePlateFunciton(value, this.props.formValues && this.props.formValues.validateLicensePlateCountry || false, this.props.t('GLOBAL.FORMS.INVALID_STRING'))
  }

  vinChassisValidation (value, allValues, props) {
    const result = validateVinChassis(value, props)
    return result === Object(result) ? undefined : result
  }

  getVehicleByChassis (value) {
    if (value && this.props.searchVehicle && this.props.organizedUnitId && !this.props.dossierWithVehicle) {
      this.props.actions.fetchVehiclePurchaseComponent(value, null, this.props.organizedUnitId)
    }
  }

  getVehicleByLicense (value) {
    if (value && this.props.searchVehicle && this.props.organizedUnitId && !this.props.dossierWithVehicle) {
      this.props.actions.fetchVehiclePurchaseComponent(null, value, this.props.organizedUnitId)
    }
  }

  onBrandIdChanges (brandId) {
    this.props.actions.resetSearchFields()
    this.props.actions.resetCarlineCombo()
    this.props.actions.resetCatalogueCombo()
    this.props.actions.resetColorCombo()
    this.props.actions.resetTapestryCombo()
    if (brandId) {
      this.props.actions.fetchCarlineCombo(brandId)
    }
  }

  onCarlineIdChanges (carlineId) {
    this.props.actions.resetSearchFields()
    this.props.actions.resetColorCombo()
    this.props.actions.resetTapestryCombo()
    if (carlineId) {
      this.props.actions.fetchCatalogueCombo(carlineId)
    }
  }

  onCatalogueIdChanges (catalogueId) {
    this.props.actions.resetSearchFields()
    if (this.props.formValues.modelId && catalogueId) {
      this.props.actions.fetchPurchaseCatalogueModel(catalogueId, this.props.formValues.modelId)
      this.props.actions.fetchColorCombo(catalogueId, this.props.formValues.modelId)
      this.props.actions.fetchTapestryCombo(catalogueId, this.props.formValues.modelId)
    }
  }

  onModelIdChanges (modelId) {
    this.props.actions.resetSearchFields()
    if (modelId && this.props.formValues.catalogueId) {
      this.props.actions.fetchPurchaseCatalogueModel(this.props.formValues.catalogueId, modelId)
      this.props.actions.fetchColorCombo(this.props.formValues.catalogueId, modelId)
      this.props.actions.fetchTapestryCombo(this.props.formValues.catalogueId, modelId)
    }
  }

  render () {
    const {
      t,
      dossierId, dossierParentId, readOnlyFields, orderId, order, dossierType, dossierSubType, formValues, dossierWithVehicle, customFields,
      searchedVehicleProps: {
        searchDealerNumber, searchVinChassis, searchLicensePlate, simpleInputTextValidation, searchMakerNumber, vehicleSelectionModal,
        showVehicleFinderModal
      },
      combos: {
        brandCombo, fuelCombo, purchaseTypeVnCombo, entityTransferCombo, vehicleOriginCombo, vehicleDestinationCombo, countries, familyCombo,
        carlineCombo, catalogueCombo, modelCombo, colorCombo, tapestryCombo
      },
      actions: {
        fetchOrder, setSearchDealerNumber, setSearchVinChassis, setSearchLicensePlate, setSearchMakerNumber, clearVehicle, resetSearchFields,
        closeVehicleSelectionModal, selectVehicle, fetchVehicleSelectionModal,
        closeVehicleCreationModal, createVehicleCreationModal
      },
      parentDossierType: { isSales, isRegistrationDelivery, isService, isPaperwork, isPurchase, isStock }, purchaseOrderExetendedMode,
      codeVehicleConfiguration, purchaseType, searchVehicle, dossierParentSubTypeId
    } = this.props
    const hasFilters = (searchDealerNumber || searchVinChassis || searchLicensePlate || searchMakerNumber)
    const hasData = (formValues ? (Object.keys(formValues).filter(key => ['vehicleId', 'dossierId', 'parentIsHistorical', 'dealerNumber', 'makerNumber', 'availabilityDate'].indexOf(key) === -1 && formValues[key]).length > 0) : false)
    const hasVehicle = (formValues ? (Object.keys(formValues).filter(key => ['vehicleId'].indexOf(key) > -1 && formValues[key]).length > 0) : false)
    const tKey = 'DOSSIER_COMPONENTS.VEHICLE.'
    const editTooltip = (<Tooltip id="editTooltip">{t(`${tKey}TOOLTIP_VALIDATE_LICENSE_PLATE`)}</Tooltip>)
    const isVN =
    dossierSubType === dossierSubTypeIdConstants.compra_vn || dossierSubType === dossierSubTypeIdConstants.venta_vn || dossierSubType === dossierSubTypeIdConstants.registration_delivery ||
    ((dossierSubType === dossierSubTypeIdConstants.tramite || dossierSubType === dossierSubTypeIdConstants.campanya || dossierSubType === dossierSubTypeIdConstants.service) &&
     (dossierParentSubTypeId === dossierSubTypeIdConstants.compra_vn || dossierParentSubTypeId === dossierSubTypeIdConstants.venta_vn || dossierParentSubTypeId === dossierSubTypeIdConstants.registration_delivery || !dossierParentSubTypeId))
    return (
      <div className="vehicle-panel checkbox-vehicle dossier-panel">
        <div className="header-panel" onClick={this.fetchSalesDossierPromiseVehicle.bind(this)}>
          <div className="name-wrapper">
            <i className="ico-car" />
            <h4>{t(`${tKey}TITLE`)}</h4>
          </div>
          <i className={this.state.open ? 'ico-arrow-black-up' : 'ico-arrow-black-down'} />
        </div>
        <VehicleSelectionModal
          t = {t}
          show = {vehicleSelectionModal?.show ?? false }
          purchaseList = {vehicleSelectionModal?.purchaseList ?? []}
          actions={{
            closeVehicleSelectionModal,
            selectVehicle,
            fetchVehicleSelectionModal
          }}
          stockDossierId = {vehicleSelectionModal?.stockDossierId}
          dossierId = {dossierId}
        />
        <VehicleCreationModal
          t={t}
          show={showVehicleFinderModal ?? false }
          actions={{
            closeVehicleCreationModal,
            createVehicleCreationModal
          }}
          combos={{
            brandCombo,
            fuelCombo
          }}
          dossierId={dossierId}
          validateLicensePlateCountry={this.props.formValues?.validateLicensePlateCountry}
        />
        <OrderModalPage
          readOnlyFields={readOnlyFields}
          order={order}
          purchaseOrderExetendedMode={purchaseOrderExetendedMode}
          dossierType = { dossierType }
          fieldsConfiguration={this.state.fieldsConfiguration}
        />
        <Panel expanded={this.state.open} onToggle={() => {}}>
          <Panel.Collapse>
            {((isSales || isRegistrationDelivery) && (!dossierParentId || orderId)) && (
              <Row>
                <div className="vehicle-order-panel dossier-panel">
                  <div className="search-form order-vehicle-dossier">
                    <div className="header-panel" onClick={() => this.setState({ orderOpen: !this.state.orderOpen })}>
                      <span className="title-search"><i className="ico-search" /> {t(`${tKey}ORDER_TITLE`)}</span>
                      <i className={this.state.orderOpen ? 'ico-arrow-black-up' : 'ico-arrow-black-down'} />
                    </div>
                    <Panel expanded={this.state.orderOpen} onToggle={() => {}}>
                      <Panel.Collapse>
                        <Row className='flexRow'>
                          <Field
                            name="order.brandId"
                            id="order_brandId"
                            colSm={3}
                            controlLabel={t(`${tKey}BRAND`)}
                            options={brandCombo}
                            valueKey="id"
                            labelKey="value"
                            disabled={true}
                            component={InputSelect}
                            menuPlacement="auto"
                          />
                          {this.state.fieldsConfiguration.carlineId.permission !== permissions.hidden && (
                            <Field
                              id="order_carlineId"
                              name="order.carlineId"
                              colSm={3}
                              controlLabel={t(`${tKey}CARLINE`)}
                              placeholder={t(`${tKey}CARLINE`)}
                              component={InputSelect}
                              valueKey="id"
                              labelKey="value"
                              options={carlineCombo}
                              disabled={true}
                              menuPlacement="auto"
                            />
                          )}
                          {this.state.fieldsConfiguration.carline.permission !== permissions.hidden && (
                            <Field
                              id="order_carline"
                              name="order.carline"
                              colSm={3}
                              controlLabel={t(`${tKey}CARLINE`)}
                              placeholder={t(`${tKey}CARLINE`)}
                              component={InputText}
                              disabled={true}
                              validate={this.required}
                            />)}
                          {this.state.fieldsConfiguration.catalogueId.permission !== permissions.hidden && (
                            <Field
                              id="order_catalogueId"
                              name="order.catalogueId"
                              colSm={3}
                              controlLabel={t(`${tKey}CATALOGUE`)}
                              placeholder={t(`${tKey}CATALOGUE`)}
                              component={InputSelect}
                              valueKey="id"
                              labelKey="value"
                              options={catalogueCombo}
                              disabled={true}
                              menuPlacement="auto"
                            />
                          )}
                          {this.state.fieldsConfiguration.catalogue.permission !== permissions.hidden && (
                            <Field
                              id="order_catalogue"
                              name="order.catalogue"
                              colSm={3}
                              controlLabel={t(`${tKey}CATALOGUE`)}
                              placeholder={t(`${tKey}CATALOGUE`)}
                              component={InputText}
                              disabled={true}
                              validate={this.required}
                            />)}
                          {this.state.fieldsConfiguration.modelId.permission !== permissions.hidden && (
                            <Field
                              id="order_modelId"
                              name="order.modelId"
                              colSm={3}
                              controlLabel={t(`${tKey}MODEL_YEAR`)}
                              placeholder={t(`${tKey}MODEL_YEAR`)}
                              component={InputSelect}
                              valueKey="id"
                              labelKey="value"
                              options={modelCombo}
                              disabled={true}
                              menuPlacement="auto"
                            />
                          )}
                          {this.state.fieldsConfiguration.modelYear.permission !== permissions.hidden && (
                            <Field
                              id="order_modelYear"
                              name="order.modelYear"
                              colSm={3}
                              controlLabel={t(`${tKey}MODEL_YEAR`)}
                              placeholder={t(`${tKey}MODEL_YEAR`)}
                              component={InputText}
                              disabled={true}
                              validate={this.required}
                            />)}
                          {this.state.fieldsConfiguration.colorId.permission !== permissions.hidden && (
                            <Field
                              id="order_colorId"
                              name="order.colorId"
                              colSm={3}
                              controlLabel={t(`${tKey}COLOR`)}
                              placeholder={t(`${tKey}COLOR`)}
                              component={InputSelect}
                              valueKey="id"
                              labelKey="value" options={colorCombo}
                              disabled={true}
                            />
                          )}
                          {this.state.fieldsConfiguration.tapestryId.permission !== permissions.hidden && (
                            <Field
                              id="order_tapestryId"
                              name="order.tapestryId"
                              colSm={3}
                              controlLabel={t(`${tKey}TAPESTRY`)}
                              placeholder={t(`${tKey}TAPESTRY`)}
                              component={InputSelect}
                              valueKey="id"
                              labelKey="value"
                              options={tapestryCombo}
                              disabled={true}
                            />
                          )}
                          <Field
                            name="order.model"
                            id="order_model"
                            colSm={3}
                            controlLabel={t(`${tKey}MODEL`)}
                            disabled={true}
                            component={InputText}
                          />
                          {this.state.fieldsConfiguration.color.permission !== permissions.hidden && (
                            <Field
                              name="order.color"
                              id="order_color"
                              colSm={3}
                              controlLabel={t(`${tKey}COLOR`)}
                              disabled={true}
                              component={InputText}
                            />
                          )}
                          {this.state.fieldsConfiguration.tapestry.permission !== permissions.hidden && (
                            <Field
                              name="order.tapestry"
                              id="order_tapestry"
                              colSm={3}
                              controlLabel={t(`${tKey}TAPESTRY`)}
                              disabled={true}
                              component={InputText}
                            />
                          )}
                        </Row>
                        <Row className='flexRow'>
                          {purchaseOrderExetendedMode && (
                            <SaleRequestFields
                              t={t}
                              readOnlyFields={true}
                              purchaseTypeVnCombo={purchaseTypeVnCombo}
                              entityTransferCombo={entityTransferCombo}
                              namePrefix={'order.'}
                              order={order}
                              fieldSize={0}
                              className="col-sm-3"
                            />
                          )}
                          {this.state.fieldsConfiguration.comercialCode.permission !== permissions.hidden && (
                            <Field
                              name="order.comercialCode"
                              id="order_comercialCode"
                              colSm={3}
                              controlLabel={t(`${tKey}COMMERCIAL_CODE`)}
                              disabled={true}
                              component={InputText}
                            />
                          )}

                          <Field
                            name="order.fuelId"
                            id="order_fuelId"
                            colSm={3}
                            controlLabel={t(`${tKey}FUEL`)}
                            options={fuelCombo}
                            valueKey="id"
                            labelKey="value"
                            disabled={true}
                            component={InputSelect}
                            menuPlacement="auto"
                          />
                          {!dossierParentId && this.state.fieldsConfiguration.button_vehicle_order.permission !== permissions.hidden && (readOnlyFields ? null : <Col sm={3}>
                            <Button className="order-button btn-editable-table btn-standard"
                              onClick={() => fetchOrder(dossierId)}
                              style={{ width: '100%' }}
                              id="button_vehicle_order"
                              disabled={this.state.fieldsConfiguration.button_vehicle_order.permission === permissions.readOnly}
                            >
                              <span>{t(`${tKey}ORDER`)}</span>
                            </Button>
                          </Col>)}
                        </Row>
                      </Panel.Collapse>
                    </Panel>
                  </div>
                </div>
              </Row>
            )}
            {
              !dossierParentId && !readOnlyFields && dossierSubType !== dossierSubTypeIdConstants.compra_vn && !dossierWithVehicle && settings.clientFieldValidation !== clientInformation.PORSCHE
                ? (
                  <Row>
                    <div className="search-form">
                      <span className="title-search"><i className="ico-search" /> {t(`${tKey}SEARCH_VEHICLE`)}</span>
                      <Row>
                        {(this.state.fieldsConfiguration.searchByDealerNumber && this.state.fieldsConfiguration.searchByDealerNumber.permission > permissions.hidden) &&
                          (isSales || isRegistrationDelivery || isPaperwork || isStock)
                          ? (
                            <SimpleInputText
                              colSm={2}
                              id="search_dealerNumber"
                              controlLabel={t(`${tKey}DEALER_NUMBER`)}
                              value={searchDealerNumber}
                              onInputChange={setSearchDealerNumber}
                              disabled={readOnlyFields || this.state.fieldsConfiguration.searchByDealerNumber.permission !== permissions.editable}
                              forceControlLabelInOneLine={true}
                              maxLength={settings.dealerMaxLength}
                              error={this.validateIdPurchase() ? (t(`${tKey}VALIDATE_ID_DEALER`)) : null}
                            />
                          ) : null
                        }
                        <SimpleInputText
                          colSm={3}
                          id="search_chassis"
                          controlLabel={t(`${tKey}CHASSIS`)}
                          value={searchVinChassis}
                          error={simpleInputTextValidation}
                          onInputChange={(val) => setSearchVinChassis(val)}
                          disabled={readOnlyFields}
                          maxLength={17}
                          normalize={value => multipleNormalize(value, [toUpperCase, trimAll])}
                        />
                        <SimpleInputText
                          colSm={2}
                          id="search_license_plate"
                          controlLabel={t(`${tKey}LICENSE_PLATE`)}
                          value={searchLicensePlate}
                          error={simpleInputTextValidation}
                          onInputChange={setSearchLicensePlate}
                          disabled={readOnlyFields}
                          normalize={value => multipleNormalize(value, [toUpperCase, trimAll])}
                        />
                        {
                          (isSales || isRegistrationDelivery)
                            ? (
                              <SimpleInputText
                                colSm={3}
                                id="search_makerNumber"
                                controlLabel={t(`${tKey}MAKER_ORDER_NUMBER`)}
                                value={searchMakerNumber}
                                error={simpleInputTextValidation}
                                onInputChange={setSearchMakerNumber}
                                disabled={readOnlyFields}
                                forceControlLabelInOneLine={true}
                              />
                            ) : null
                        }
                        { !readOnlyFields && (
                          <Col sm={2} className="btn-wrapper">
                            <Button className={'btn-white btn-icon' + (hasFilters ? ' btn-white search-button' : ' btn-cancel')} id="button_vehicle_seach" style={{ width: '100%' }} onClick={() => this.searchVehicle()}><i className="ico-search" /> {t(`${tKey}SEARCH_BUTTON`)}</Button>
                          </Col>
                        ) }
                      </Row>
                    </div>
                  </Row>
                ) : null
            }
            {!readOnlyFields && hasVehicle && (dossierParentId || dossierSubType === dossierSubTypeIdConstants.compra_vn || dossierSubType === dossierSubTypeIdConstants.compra_vo ||
              dossierSubType === dossierSubTypeIdConstants.campanya || dossierSubType === dossierSubTypeIdConstants.campanya_ayuda_especial || isPaperwork ||
              (isService && !dossierParentId) || (!isService && (dossierParentId || isPurchase) && hasVehicle)) && this.props.componentConfiguration.permission === permissions.editable &&
              (this.state.fieldsConfiguration.button_delete_vehicle && this.state.fieldsConfiguration.button_delete_vehicle.permission !== permissions.hidden) && (
              <Row>
                <Col sm={12} style={{ textAlign: 'right' }}>
                  <Button
                    className={(hasData) ? ' btn-standard' : ' btn-cancel'}
                    id="button_delete_vehicle"
                    disabled={this.state.fieldsConfiguration.button_delete_vehicle && this.state.fieldsConfiguration.button_delete_vehicle.permission !== permissions.editable}
                    style={{ fontSize: '11px' }}
                    onClick={
                      () => {
                        if (hasData) { clearVehicle(this.props.parentDossierType, this.props.pristine) }
                      }
                    }>
                    <i className="ico-cancel" />
                    {t(`${tKey}CLEAR_VEHICLE`)}
                  </Button>
                </Col>
              </Row>
            )}
            {
              <Row className='flexRow'>
                {
                  dossierType !== dossierTypesConstant.purchase && this.state.fieldsConfiguration.dealerNumber.permission !== permissions.hidden
                    ? (
                      <Field
                        id="dealerNumber"
                        name="vehicleComponent.dealerNumber"
                        colSm={4}
                        controlLabel={t(`${tKey}DEALER_NUMBER`)}
                        placeholder={t(`${tKey}DEALER_NUMBER`)}
                        component={InputText}
                        disabled={readOnlyFields || this.state.noEditableVehicle || this.state.fieldsConfiguration.dealerNumber.permission !== permissions.editable}
                      />
                    ) : null
                }
                {this.state.fieldsConfiguration.vinChassis.permission !== permissions.hidden && (
                  <Field
                    id="vinChassis"
                    name="vehicleComponent.vinChassis"
                    colSm={4}
                    controlLabel={t(`${tKey}CHASSIS`)}
                    placeholder={t(`${tKey}CHASSIS`)}
                    component={InputText}
                    disabled={readOnlyFields || this.state.noEditableVehicle || this.state.fieldsConfiguration.vinChassis.permission !== permissions.editable}
                    onInputChange={resetSearchFields}
                    minLength={17}
                    maxLength={17}
                    normalize={value => multipleNormalize(value, [toUpperCase, trimAll])}
                    validate={[this.vinChassisValidation]}
                    onInputBlur={searchVehicle ? event => this.getVehicleByChassis(event) : null}
                  />
                )}
                {this.state.fieldsConfiguration.licensePlate.permission !== permissions.hidden && (
                  <Field
                    id="licensePlate"
                    name="vehicleComponent.licensePlate"
                    colSm={4}
                    controlLabel={t(`${tKey}LICENSE_PLATE`)}
                    placeholder={t(`${tKey}LICENSE_PLATE`)}
                    component={InputText}
                    validate={this.state.disabledValidateLicensePlate === false || this.state.disabledValidateLicensePlate === null ? [this.validateLicensePlate] : null }
                    disabled={readOnlyFields || this.state.noEditableVehicle || this.state.fieldsConfiguration.licensePlate.permission !== permissions.editable}
                    onInputChange={resetSearchFields}
                    normalize={value => multipleNormalize(value, [toUpperCase, trimAll])}
                    onInputBlur = {searchVehicle ? event => this.getVehicleByLicense(event) : null}
                  />
                )}
                {this.state.fieldsConfiguration.comercialCode.permission !== permissions.hidden && (
                  <Field
                    id="comercialCode"
                    name="vehicleComponent.comercialCode"
                    colSm={4}
                    controlLabel={t(`${tKey}COMMERCIAL_CODE`)}
                    placeholder={t(`${tKey}COMMERCIAL_CODE`)}
                    component={InputText}
                    disabled={readOnlyFields || this.state.noEditableVehicle || this.state.fieldsConfiguration.comercialCode.permission !== permissions.editable}
                    onInputChange={resetSearchFields}
                  />
                )}
                {this.state.fieldsConfiguration.brandId.permission !== permissions.hidden && (
                  <Field
                    id="brandId"
                    name="vehicleComponent.brandId"
                    colSm={4}
                    controlLabel={t(`${tKey}BRAND`)}
                    placeholder={t(`${tKey}BRAND`)}
                    component={InputSelect}
                    options={brandCombo}
                    valueKey="id"
                    labelKey="value"
                    disabled={readOnlyFields || this.state.noEditableVehicle || this.state.fieldsConfiguration.brandId.permission !== permissions.editable}
                    onInputChange={this.onBrandIdChanges.bind(this)}
                    menuPlacement="auto"
                  />
                )}

                {this.state.fieldsConfiguration.carlineId.permission !== permissions.hidden && isVN && (
                  <Field
                    id="carlineId"
                    name="vehicleComponent.carlineId"
                    colSm={4}
                    controlLabel={t(`${tKey}CARLINE`)}
                    placeholder={t(`${tKey}CARLINE`)}
                    component={InputSelect}
                    valueKey="id"
                    labelKey="value"
                    options={carlineCombo}
                    disabled={carlineCombo.length === 0 || readOnlyFields || this.state.noEditableVehicle || this.state.fieldsConfiguration.carlineId.permission !== permissions.editable}
                    onInputChange={this.onCarlineIdChanges.bind(this)}
                    menuPlacement="auto"
                  />
                )}

                {this.state.fieldsConfiguration.carline.permission !== permissions.hidden && !isVN && (
                  <Field
                    id="carline"
                    name="vehicleComponent.carline"
                    colSm={4}
                    controlLabel={t(`${tKey}CARLINE`)}
                    placeholder={t(`${tKey}CARLINE`)}
                    component={InputText}
                    disabled={readOnlyFields || this.state.noEditableVehicle || this.state.fieldsConfiguration.carline.permission !== permissions.editable}
                    onInputChange={resetSearchFields}
                  />
                )}

                {this.state.fieldsConfiguration.catalogueId.permission !== permissions.hidden && isVN && (
                  <Field
                    id="catalogueId"
                    name="vehicleComponent.catalogueId"
                    colSm={4}
                    controlLabel={t(`${tKey}CATALOGUE`)}
                    placeholder={t(`${tKey}CATALOGUE`)}
                    component={InputSelect}
                    valueKey="id"
                    labelKey="value"
                    options={catalogueCombo}
                    disabled={catalogueCombo.length === 0 || readOnlyFields || this.state.noEditableVehicle || this.state.fieldsConfiguration.catalogueId.permission !== permissions.editable}
                    onInputChange={this.onCatalogueIdChanges.bind(this)}
                    menuPlacement="auto"
                  />
                )}

                {this.state.fieldsConfiguration.catalogue.permission !== permissions.hidden && !isVN && (
                  <Field
                    id="catalogue"
                    name="vehicleComponent.catalogue"
                    colSm={4}
                    controlLabel={t(`${tKey}CATALOGUE`)}
                    placeholder={t(`${tKey}CATALOGUE`)}
                    component={InputText}
                    disabled={readOnlyFields || this.state.noEditableVehicle || this.state.fieldsConfiguration.catalogue.permission !== permissions.editable}
                    onInputChange={resetSearchFields}
                  />
                )}

                {this.state.fieldsConfiguration.modelId.permission !== permissions.hidden && isVN && (
                  <Field
                    id="modelId"
                    name="vehicleComponent.modelId"
                    colSm={4}
                    controlLabel={t(`${tKey}MODEL_YEAR`)}
                    placeholder={t(`${tKey}MODEL_YEAR`)}
                    component={InputSelect}
                    valueKey="id"
                    labelKey="value"
                    options={modelCombo}
                    disabled={modelCombo.length === 0 || readOnlyFields || this.state.noEditableVehicle || this.state.fieldsConfiguration.modelId.permission !== permissions.editable}
                    onInputChange={this.onModelIdChanges.bind(this)}
                    menuPlacement="auto"

                  />
                )}
                {this.state.fieldsConfiguration.modelYear.permission !== permissions.hidden && !isVN && (
                  <Field
                    id="modelYear"
                    name="vehicleComponent.modelYear"
                    colSm={4}
                    controlLabel={t(`${tKey}MODEL_YEAR`)}
                    placeholder={t(`${tKey}MODEL_YEAR`)}
                    component={InputNumber}
                    maxLength={modelLength.maxLength}
                    disabled={readOnlyFields || this.state.noEditableVehicle || this.state.fieldsConfiguration.modelYear.permission !== permissions.editable}
                    onInputChange={resetSearchFields}
                  />
                )}
                {this.state.fieldsConfiguration.model.permission !== permissions.hidden && (
                  <Field
                    id="model"
                    name="vehicleComponent.model"
                    colSm={4}
                    controlLabel={t(`${tKey}MODEL`)}
                    placeholder={t(`${tKey}MODEL`)}
                    component={InputText}
                    disabled={readOnlyFields || this.state.noEditableVehicle || this.state.fieldsConfiguration.model.permission !== permissions.editable}
                    onInputChange={resetSearchFields}
                  />
                )}
                {this.state.fieldsConfiguration.colorId.permission !== permissions.hidden && isVN && (
                  <Field
                    id="colorId"
                    name="vehicleComponent.colorId"
                    colSm={4}
                    controlLabel={t(`${tKey}COLOR`)}
                    placeholder={t(`${tKey}COLOR`)}
                    component={InputSelect}
                    valueKey="id"
                    labelKey="value" options={colorCombo}
                    disabled={colorCombo.length === 0 || readOnlyFields || this.state.noEditableVehicle || this.state.fieldsConfiguration.colorId.permission !== permissions.editable}
                    onInputChange={resetSearchFields}
                  />
                )}
                {this.state.fieldsConfiguration.tapestryId.permission !== permissions.hidden && isVN && (
                  <Field
                    id="tapestryId"
                    name="vehicleComponent.tapestryId"
                    colSm={4}
                    controlLabel={t(`${tKey}TAPESTRY`)}
                    placeholder={t(`${tKey}TAPESTRY`)}
                    component={InputSelect}
                    valueKey="id"
                    labelKey="value"
                    options={tapestryCombo}
                    disabled={tapestryCombo.length === 0 || readOnlyFields || this.state.noEditableVehicle || this.state.fieldsConfiguration.tapestryId.permission !== permissions.editable}
                    onInputChange={resetSearchFields}
                  />
                )}
                {this.state.fieldsConfiguration.color.permission !== permissions.hidden &&
                (settings.clientFieldValidation === clientInformation.BUSS ? !isVN : true) && (
                  <Field
                    id="color"
                    name="vehicleComponent.color"
                    colSm={4}
                    controlLabel={t(`${tKey}COLOR`)}
                    placeholder={t(`${tKey}COLOR`)}
                    component={InputText}
                    disabled={readOnlyFields || this.state.noEditableVehicle || this.state.fieldsConfiguration.color.permission !== permissions.editable}
                    onInputChange={resetSearchFields}
                  />
                )}
                {this.state.fieldsConfiguration.tapestry.permission !== permissions.hidden &&
                 (settings.clientFieldValidation === clientInformation.BUSS ? !isVN : true) && (
                  <Field
                    id="tapestry"
                    name="vehicleComponent.tapestry"
                    colSm={4}
                    controlLabel={t(`${tKey}TAPESTRY`)}
                    placeholder={t(`${tKey}TAPESTRY`)}
                    component={InputText}
                    disabled={readOnlyFields || this.state.noEditableVehicle || this.state.fieldsConfiguration.tapestry.permission !== permissions.editable}
                    onInputChange={resetSearchFields}
                  />
                )}
                {this.state.fieldsConfiguration.fuelId.permission !== permissions.hidden && (
                  <Field
                    colSm={4}
                    id="fuelId"
                    menuContainerStyle={{ zIndex: 999 }}
                    name="vehicleComponent.fuelId"
                    controlLabel={t(`${tKey}FUEL`)}
                    placeholder={t(`${tKey}FUEL`)}
                    component={InputSelect}
                    options={fuelCombo}
                    valueKey="id"
                    labelKey="value"
                    disabled={readOnlyFields || this.state.noEditableVehicle || this.state.fieldsConfiguration.fuelId.permission !== permissions.editable}
                    onInputChange={resetSearchFields}
                    menuPlacement="auto"
                  />
                )}

                {this.state.fieldsConfiguration.brandReference.permission !== permissions.hidden && (
                  <Field
                    colSm={4}
                    id="brandReference"
                    name="vehicleComponent.brandReference"
                    controlLabel={t(`${tKey}BRAND_REFERENCE`)}
                    placeholder={t(`${tKey}BRAND_REFERENCE`)}
                    component={InputText}
                    disabled={readOnlyFields || this.state.noEditableVehicle || this.state.fieldsConfiguration.brandReference.permission !== permissions.editable}
                    onInputChange={resetSearchFields}
                  />
                )}

                {this.state.fieldsConfiguration.family.permission !== permissions.hidden && (
                  <Field
                    colSm={4}
                    id="familyId"
                    menuContainerStyle={{ zIndex: 999 }}
                    name="vehicleComponent.familyId"
                    controlLabel={t(`${tKey}FAMILY`)}
                    placeholder={t(`${tKey}FAMILY`)}
                    component={InputSelect}
                    options={familyCombo}
                    valueKey="familyId"
                    labelKey="name"
                    disabled= {readOnlyFields || this.state.noEditableVehicle || this.state.fieldsConfiguration.family.permission !== permissions.editable}
                    onInputChange={resetSearchFields}
                  />
                )}
                { this.state.fieldsConfiguration.firstLicensePlateDate.permission !== permissions.hidden ? (
                  <Field
                    id="firstLicensePlateDate"
                    name="vehicleComponent.firstLicensePlateDate"
                    colSm={4}
                    controlLabel={t(`${tKey}FIRST_LPLATE_DATE`)}
                    disabled={readOnlyFields || this.state.noEditableVehicle || this.state.fieldsConfiguration.firstLicensePlateDate.permission !== permissions.editable}
                    component={InputDatePicker}
                    validate={[this.dateValidation]}
                    onInputChange={resetSearchFields}
                    placement={this.props.showTopFirstLicensePlateDateAndAvailabilityDate ? 'top' : 'bottom'}
                  />
                ) : null
                }
                {
                  dossierType !== dossierTypesConstant.purchase ? ([
                    this.state.fieldsConfiguration.makerNumber.permission !== permissions.hidden && (
                      <Field
                        key="makerNumber"
                        id="vehicleMakerNumber"
                        name="vehicleComponent.makerNumber"
                        colSm={4}
                        controlLabel={t(`${tKey}MAKER_ORDER_NUMBER`)}
                        placeholder={t(`${tKey}MAKER_ORDER_NUMBER`)}
                        component={InputText}
                        disabled={readOnlyFields || this.state.noEditableVehicle || this.state.fieldsConfiguration.makerNumber.permission !== permissions.editable}
                        onInputChange={resetSearchFields}
                      />
                    ),
                    this.state.fieldsConfiguration.availabilityDate.permission !== permissions.hidden && (
                      <Field
                        key="availabilityDate"
                        id="availabilityDate"
                        name="vehicleComponent.availabilityDate"
                        colSm={4}
                        controlLabel={t(`${tKey}AVAILABILITY_DATE`)}
                        disabled={readOnlyFields || this.state.noEditableVehicle || this.state.fieldsConfiguration.availabilityDate.permission !== permissions.editable}
                        component={InputDatePicker}
                        validate={[this.dateValidation]}
                        onInputChange={resetSearchFields}
                        placement={this.props.showTopFirstLicensePlateDateAndAvailabilityDate ? 'top' : 'bottom'}
                      />
                    )
                  ]) : null
                }
                {
                  codeVehicleConfiguration && this.state.fieldsConfiguration.destinationId.permission !== permissions.hidden && [(
                    <Field
                      colSm={4}
                      id="originId"
                      name="vehicleComponent.originId"
                      key="codeOrigin"
                      valueKey="id"
                      labelKey="value"
                      controlLabel={t(`${tKey}ORIGIN_CLASIFICATION`)}
                      placeholder={t(`${tKey}ORIGIN_CLASIFICATION`)}
                      component={InputSelect}
                      options={vehicleOriginCombo}
                      disabled={!vehicleOriginCombo || vehicleOriginCombo.length === 0 || dossierSubType !== dossierSubTypeIdConstants.venta_vn || readOnlyFields || this.state.fieldsConfiguration.originId.permission !== permissions.editable}
                    />),
                  (<Field
                    colSm={4}
                    id="destinationId"
                    name="vehicleComponent.destinationId"
                    key="codeDestination"
                    valueKey="id"
                    labelKey="value"
                    controlLabel={t(`${tKey}DESTINATION_CLASIFICATION`)}
                    placeholder={t(`${tKey}DESTINATION_CLASIFICATION`)}
                    component={InputSelect}
                    options={vehicleDestinationCombo}
                    disabled={!vehicleDestinationCombo || vehicleDestinationCombo.length === 0 || readOnlyFields || this.state.fieldsConfiguration.destinationId.permission !== permissions.editable}
                  />
                  )]
                }
                {
                  (dossierSubType === dossierSubTypeIdConstants.venta_vo || dossierSubType === dossierSubTypeIdConstants.compra_vo) &&
                this.state.fieldsConfiguration.expirationItvDate.permission !== permissions.hidden && (
                    <Field
                      id="expirationItvDate"
                      name="vehicleComponent.expirationItvDate"
                      colSm={4}
                      controlLabel={t(`${tKey}EXPIRATION_ITV_DATE`)}
                      component={InputDatePicker}
                      validate={[this.dateValidation]}
                      disabled={readOnlyFields || this.state.fieldsConfiguration.expirationItvDate.permission !== permissions.editable}
                      onInputChange={resetSearchFields}
                    />
                  )
                }
                {
                  (dossierSubType === dossierSubTypeIdConstants.compra_vo && purchaseType === 10) &&
                this.state.fieldsConfiguration.originalLicensePlate.permission !== permissions.hidden && (
                    <Field
                      id="originalLicensePlate"
                      name="vehicleComponent.originalLicensePlate"
                      colSm={4}
                      controlLabel={t(`${tKey}ORIGINAL_LICENSE_PLATE`)}
                      placeholder={t(`${tKey}ORIGINAL_LICENSE_PLATE`)}
                      component={InputText}
                      maxLength = {15}
                      disabled={readOnlyFields || this.state.noEditableVehicle || this.state.fieldsConfiguration.originalLicensePlate.permission !== permissions.editable}
                      onInputChange={resetSearchFields}
                    />
                  )
                }
                {
                  (dossierSubType === dossierSubTypeIdConstants.compra_vo && purchaseType === 10) &&
                this.state.fieldsConfiguration.countryId.permission !== permissions.hidden && (
                    <Field
                      colSm={4}
                      id="countryId"
                      name="vehicleComponent.countryId"
                      key="countryId"
                      valueKey="countryId"
                      labelKey="name"
                      controlLabel={t(`${tKey}ORIGIN_COUNTRY`)}
                      placeholder={t(`${tKey}ORIGIN_COUNTRY`)}
                      component={InputSelect}
                      options={countries}
                      disabled={!countries || countries.length === 0 || readOnlyFields || this.state.fieldsConfiguration.countryId.permission !== permissions.editable}
                    />
                  )
                }
                {this.state.fieldsConfiguration.provisionalLicensePlate.permission !== permissions.hidden && (
                  <Field
                    colSm={4}
                    id="provisionalLicensePlate"
                    name="vehicleComponent.provisionalLicensePlate"
                    controlLabel={t(`${tKey}PROVISIONAL_LICENSE_PLATE`)}
                    placeholder={t(`${tKey}PROVISIONAL_LICENSE_PLATE`)}
                    component={InputText}
                    maxLength = {12}
                    disabled={true}
                  />
                )}
              </Row>
            }
            {
              <Row>
                {this.state.fieldsConfiguration.disabledValidateLicensePlate.permission !== permissions.hidden && (
                  <OverlayTrigger placement="right" overlay={editTooltip}
                    colSm={1}>
                    <Glyphicon glyph="info-sign"/>
                  </OverlayTrigger>
                )}
                {this.state.fieldsConfiguration.disabledValidateLicensePlate.permission !== permissions.hidden && (
                  <Field
                    colSm={3}
                    id="disabledValidateLicensePlate"
                    name="vehicleComponent.disabledValidateLicensePlate"
                    placeholder={t(`${tKey}DISABLED_VALIDATE_LICENSE_PLATE`)}
                    component={InputCheckbox}
                    inline
                    onChange= {(value) => (this.changeBoolLicensePlate(value))}
                    disabled={readOnlyFields || this.state.noEditableVehicle || this.state.fieldsConfiguration.disabledValidateLicensePlate.permission !== permissions.editable}
                  />
                )}
              </Row>
            }
            <CustomFields customFields={customFields} readOnlyFields={readOnlyFields} />
          </Panel.Collapse>
        </Panel>
      </div>
    )
  }
}

export default Vehicle
