import React, { PureComponent } from 'react'
import { Row, Button, Clearfix, Form, Col, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Field, reduxForm } from 'redux-form'
import InputSelect from '../commons/form/InputSelect'
import InputDatePicker from '../commons/form/InputDatePicker'
import { validateDate } from '../../util/validationFunctions'
import SimpleTablePage from '../../containers/commons/general/SimpleTablePage'
import IDocCarPagination from '../commons/iDocCarPagination'
import { unattendedResultClassNames, unattendedStatusClassNames, unnatendedResultToolTip } from '../../constants/management/unattendedClassNames'
import { unattendedResult } from '../../constants/management/unattendedStatus'
import UnattendedProcessDetailModalPage from '../../containers/management/UnattendedProcessDetailModalPage'
import UnattendedProcessDownloadFileModalPage from '../../containers/management/auxModals/UnattendedProcessDownloadFileModalPage'

import { getDateNowMaxDatePicker, convertStringToDate } from '../../util/utils'

class UnattendedProcessesFinder extends PureComponent {
  constructor () {
    super()
    this.state = {
      fields: {
        unattendedProcessStatus: [unattendedResult.PENDING, unattendedResult.RUNNING_NO_ERRORS],
        unattendedProcessView: 1
      },
      filters: [],
      dynamicFilters: [],
      disableResetButton: false

    }
  }

  componentDidMount () {
    this.props.actions.fetchUnattendedProcessTypeCombo()
    return new Promise((resolve, reject) => {
      this.props.actions.fetchUnattendedProcessViewCombo(resolve, reject)
    }).then(() => {
      this.configureProcess()
    })
  }

  configureProcess () {
    if (this.props.dynamicFilters.unattendedProcessStatus !== undefined) {
      this.props.change('unattendedProcessStatus', this.props.dynamicFilters.unattendedProcessStatus)
    } else {
      this.props.change('unattendedProcessStatus', this.state.fields.unattendedProcessStatus)
    }

    if (this.props.dynamicFilters.unattendedProcessView !== undefined) {
      this.props.change('unattendedProcessView', this.props.dynamicFilters.unattendedProcessView)
    } else {
      this.props.change('unattendedProcessView', this.state.fields.unattendedProcessView)
    }
  }

  validateDate (value, allValues, props) {
    const result = validateDate(value, props)
    return result === Object(result) ? undefined : result
  }

  fieldFill (field) {
    this.setState({ fields: { ...this.state.fields, ...field } })
  }

  onOrderChange (e) {
    const newOrderBy = this.props.filters.orderBy.includes(`+${e.currentTarget.dataset.field}`) ? `-${e.currentTarget.dataset.field}` : `+${e.currentTarget.dataset.field}`
    this.search(null, { ...this.props.filters, orderBy: newOrderBy })
  }

  onPageChange (page) {
    this.search(null, { ...this.props.filters, page })
  }

  getIconClass (field) {
    if (this.props.filters.orderBy.includes(field)) {
      return this.props.filters.orderBy.includes('-') ? 'ico-sort-selected-asc' : 'ico-sort-selected-desc'
    } else {
      return 'ico-sort'
    }
  }

  extractMultipleCombo (combo, filters, item) {
    const values = combo.filter(object => {
      return filters[item] ? filters[item].some(selectedComboValue => object.id === selectedComboValue) : null
    })

    let value = values && this.formatMultipleValues(values)
    return value
  }

  formatMultipleValues (multiplecombovalues) {
    return multiplecombovalues
      .map((combovalue, index) => combovalue.value)
      .join(', ')
  }

  handleClickReset (e) {
    if (e !== undefined) {
      e.preventDefault()
    }

    this.props.initialize({})

    this.setState({
      filters: [],
      dynamicFilters: { unattendedProcessView: 0 }
    })
  }

  search (values, filters) {
    let body = {}

    if (values) {
      if (values.unattendedProcessView) {
        body.OnlyUser = true
      }
      body.Status = values.unattendedProcessStatus
      body.ProcessTypeId = values.unattendedProcessType
      body.StartDateLaunchProcess = values.launchDateFrom
      body.EndDateLaunchProcess = values.launchDateTo
      body.StartDateEndProcess = values.endDateFrom
      body.EndDateEndProcess = values.endDateTo
    } else {
      body = this.props.dynamicFilters
    }
    this.props.actions.findUnattendedProcess({ ...body, ...filters })
  }

  submitSearchForm (values) {
    this.search(values, { ...this.props.filters, page: 1, orderBy: '-startProcessDate' })
  }

  exportData () {
    const tKey = 'MANAGEMENT.UNATTENDED_PROCESS_TAB.'
    let columnsExcel = []

    columnsExcel.push(this.props.t(`${tKey}UNATTENDED_PROCESS_TYPE`))
    columnsExcel.push(this.props.t(`${tKey}CREATION_DATE`))
    columnsExcel.push(this.props.t(`${tKey}LAUNCH_DATE`))
    columnsExcel.push(this.props.t(`${tKey}UNATTENDED_PROCESS_STATUS`))
    columnsExcel.push(this.props.t(`${tKey}N_ERRORS`))
    columnsExcel.push(this.props.t(`${tKey}END_PROCESS`))
    columnsExcel.push(this.props.t(`${tKey}USER_NAME`))

    let columns = columnsExcel.join('$')

    let body = this.props.dynamicFilters
    body.Columns = columns

    this.props.actions.exportUnattendedProcess({ ...body, ...this.props.filters })
  }

  getStatusClassName (statusId) {
    return unattendedStatusClassNames[statusId]
  }

  getStatusToolTip (statusId) {
    return unnatendedResultToolTip[statusId]
  }

  getResultClassName (process) {
    return unattendedResultClassNames[process.result]
  }

  reload () {
    this.search(null, { ...this.props.filters, page: 1, orderBy: '-startProcessDate' })
  }

  openDetailModal (process) {
    this.props.actions.openUnattendedProcessDetailModal(process)
  }

  getColor (process) {
    switch (process.result) {
      case unattendedResult.PENDING:
        return 'blue'
      case unattendedResult.RUNNING_NO_ERRORS:
        return 'yellow'
      case unattendedResult.RUNNING_WHIT_ERRORS:
        return 'purple'
      case unattendedResult.ENDED_NO_ERRORS:
        return 'green'
      case unattendedResult.ENDED_WITH_ERRORS:
        return 'red'
      default:
        'gray'
    }
  }

  openFileDownloadModal (row, isDeleted) {
    if (isDeleted) {
      this.props.actions.openModal('unattendedProcessFileExpired', null, null, null, null, [{ id: '##EXPIRE_DATE##', value: row.fileExpirationTime }])
    } else {
      this.props.actions.openCloseUnattendedProcessDownloadFileModal(true, row.unattendedProcessId)
    }
  }

  getFileIcon (row) {
    const expiredToolTip = (<Tooltip id="expiredToolTip">{this.props.t('MANAGEMENT.UNATTENDED_PROCESS_TAB.EXPIRED_DOWNLOAD')}</Tooltip>)
    const downloadToolTip = (<Tooltip id="downloadToolTip">{this.props.t('MANAGEMENT.UNATTENDED_PROCESS_TAB.DOWNLOAD_FILE')}</Tooltip>)
    if (row.fileInfo !== null && row.fileInfo !== undefined) {
      if (row.isFileDeleted) {
        return (
          <a onClick={() => this.openFileDownloadModal(row, row.isFileDeleted)}>
            <OverlayTrigger placement="right" overlay={expiredToolTip}>
              <i className="iDocIcon-attachments" style={{ color: 'red' }}/>
            </OverlayTrigger>
          </a>
        )
      } else {
        return (
          <a onClick={() => this.openFileDownloadModal(row, row.isFileDeleted)}>
            <OverlayTrigger placement="right" overlay={downloadToolTip}>
              <i className="iDocIcon-attachments"/>
            </OverlayTrigger>
          </a>
        )
      }
    }
  }

  render () {
    const {
      t, combos: {
        unattendedProcessTypeCombo
      },
      classNameSelect, className, count, unattendedProcessData,
      filters: { page, pages }, handleSubmit, hasSearch
    } = this.props
    const tKey = 'MANAGEMENT.UNATTENDED_PROCESS_TAB.'

    let unattendedProcessViewCombo = this.props.combos.unattendedProcessViewCombo
    unattendedProcessViewCombo = unattendedProcessViewCombo.map((combo) => {
      return { ...combo, value: t(`${tKey}${combo.value}`) }
    })

    let unattendedProcessStatusCombo = this.props.combos.unattendedProcessStatusCombo
    unattendedProcessStatusCombo = unattendedProcessStatusCombo.map((combo) => {
      return { ...combo, value: t(`${tKey}${combo.value}`) }
    })

    return (
      <>
        <UnattendedProcessDetailModalPage></UnattendedProcessDetailModalPage>
        <UnattendedProcessDownloadFileModalPage></UnattendedProcessDownloadFileModalPage>
        <Form autoComplete="off" onSubmit={handleSubmit((values) => this.submitSearchForm(values))} className="filters">
          <div id="divFilterSearch">
            <Row>
              <Field
                id="unattendedProcessType"
                name='unattendedProcessType'
                placeholder={t(`${tKey}UNATTENDED_PROCESS_TYPE`)}
                controlLabel={t(`${tKey}UNATTENDED_PROCESS_TYPE`)}
                component={InputSelect}
                options={unattendedProcessTypeCombo}
                valueKey="id"
                labelKey="value"
                customClass={this.state.fields.unattendedProcessType ? classNameSelect : ''}
                multi={true}
                onInputChange={ (val) => this.fieldFill({ unattendedProcessType: val })}
              />
              <Field
                id="unattendedProcessView"
                name='unattendedProcessView'
                placeholder={t(`${tKey}UNATTENDED_PROCESS_VIEW`)}
                controlLabel={t(`${tKey}UNATTENDED_PROCESS_VIEW`)}
                component={InputSelect}
                options={unattendedProcessViewCombo}
                valueKey="id"
                labelKey="value"
                customClass={this.state.fields.unattendedProcessView ? className : ''}
                onInputChange={ (val) => this.fieldFill({ unattendedProcessView: val })}
                isClearable ={false}
              />
              <Field
                id="launchDateFrom"
                name='launchDateFrom'
                placeholder={t(`${tKey}CREATION_DATE_FROM`)}
                controlLabel={t(`${tKey}CREATION_DATE_FROM`)}
                component={InputDatePicker}
                validate={[this.validateDate]}
                customClass={this.state.fields.launchDateFrom ? className : ''}
                onInputChange={ (val) => this.fieldFill({ launchDateFrom: val })}
                maxDate={getDateNowMaxDatePicker()}

              />
              <Field
                id="launchDateTo"
                name='launchDateTo'
                placeholder={t(`${tKey}CREATION_DATE_TO`)}
                controlLabel={t(`${tKey}CREATION_DATE_TO`)}
                component={InputDatePicker}
                validate={[this.validateDate]}
                customClass={this.state.fields.launchDateTo ? className : ''}
                onInputChange={ (val) => this.fieldFill({ launchDateTo: val })}
                maxDate={getDateNowMaxDatePicker()}
              />
              <Field
                id="unattendedProcessStatus"
                name='unattendedProcessStatus'
                placeholder={t(`${tKey}UNATTENDED_PROCESS_STATUS`)}
                controlLabel={t(`${tKey}UNATTENDED_PROCESS_STATUS`)}
                component={InputSelect}
                options={unattendedProcessStatusCombo}
                valueKey="id"
                labelKey="value"
                customClass={this.state.fields.unattendedProcessStatus ? className : ''}
                multi={true}
                onInputChange={(val) => this.fieldFill({ unattendedProcessStatus: val })}
              />
              <Field
                id="endDateFrom"
                name='endDateFrom'
                placeholder={t(`${tKey}END_DATE_FROM`)}
                controlLabel={t(`${tKey}END_DATE_FROM`)}
                component={InputDatePicker}
                validate={[this.validateDate]}
                customClass={this.state.fields.endDateFrom ? className : ''}
                onInputChange={ (val) => this.fieldFill({ endDateFrom: val })}
                maxDate={getDateNowMaxDatePicker()}
              />
              <Field
                id="endDateTo"
                name='endDateTo'
                placeholder={t(`${tKey}END_DATE_TO`)}
                controlLabel={t(`${tKey}END_DATE_TO`)}
                component={InputDatePicker}
                validate={[this.validateDate]}
                customClass={this.state.fields.endDateTo ? className : ''}
                onInputChange={(val) => this.fieldFill({ endDateTo: val })}
                maxDate={getDateNowMaxDatePicker()}
              />
              <Col sm={3}>
                <div className='form-group div-btn-search-finder'>
                  <Button
                    id="btn_search"
                    type="submit"
                    className="btn-standard"
                  >
                    <i className="ico-search"/>
                    {t('SEARCH.ACTIONS.SEARCH_BUTTON')}
                  </Button>
                  <Button
                    id="btn_reset"
                    onClick={(e) => this.handleClickReset(e)}
                    className={'btn-white btn-icon' + (this.props.pristine ? ' btn-cancel' : ' btn-standard')}
                    disabled={this.props.pristine}
                  >
                    <i className="ico-cancel"/> {t('SEARCH.ACTIONS.RESET_BUTTON')}
                  </Button>
                </div>
              </Col>

            </Row>
            <Row className="filters-selected" key="filters-selected">
              <Clearfix/>
              <Col sm={3} className="row-space">
                {count > 0 &&
                <>
                  <Button
                    onClick={(event) => this.exportData(event)}
                    bsStyle="default"
                    bsSize="small">
                    {t('SEARCH.ACTIONS.EXPORT')}
                    <i className="ico-if_Download_1031520"/>
                  </Button>
                </>
                }
              </Col>
            </Row>
            {hasSearch && (
              <>
                <div className="table-wrapper" key="table-wrapper">
                  <SimpleTablePage
                    columns={[
                      <th key={0} data-field="unattendedProcessId" display="none">
                      </th>,
                      <th key={1} data-field="unattendedProcessName" onClick={(event) => this.onOrderChange(event)}>
                        {t(`${tKey}UNATTENDED_PROCESS_TYPE`)}&nbsp;<i className={this.getIconClass('unattendedProcessName')}></i>
                      </th>,
                      <th key={2} data-field="creationDate" onClick={(event) => this.onOrderChange(event)}>
                        {t(`${tKey}CREATION_DATE`)}&nbsp;<i className={this.getIconClass('creationDate')}/>
                      </th>,
                      <th key={3} data-field="startProcessDate" onClick={(event) => this.onOrderChange(event)}>
                        {t(`${tKey}LAUNCH_DATE`)}&nbsp;<i className={this.getIconClass('startProcessDate')}/>
                      </th>,
                      <th key={4} data-field="unattendedProcessStatusId" onClick={(event) => this.onOrderChange(event)}>
                        {t(`${tKey}UNATTENDED_PROCESS_STATUS`)}&nbsp;<i className={this.getIconClass('unattendedProcessStatusId')}></i>
                      </th>,
                      <th key={5} data-field="unattendedProcessStatusId" onClick={(event) => this.onOrderChange(event)}>
                        {t(`${tKey}RESULT`)}&nbsp;<i className={this.getIconClass('unattendedProcessStatusId')}/>
                      </th>,
                      <th key={6} data-field="endProcessDate" onClick={(event) => this.onOrderChange(event)}>
                        {t(`${tKey}END_PROCESS`)}&nbsp;<i className={this.getIconClass('endProcessDate')}/>
                      </th>,
                      <th key={7} data-field="userName" onClick={(event) => this.onOrderChange(event)}>
                        {t(`${tKey}USER_NAME`)}&nbsp;<i className={this.getIconClass('userName')}/>
                      </th>,
                      <th key={8} className='actionTD' data-field="fileInfo">
                      </th>
                    ]}
                    rows={unattendedProcessData && unattendedProcessData.map((row, idx) => (
                      <tr key={idx}>
                        <td>
                          {row.UnattendedProcessId}
                        </td>
                        <td>
                          {row.unattendedProcessName}
                        </td>
                        <td>
                          {row.creationDate}
                        </td>
                        <td>
                          {row.startProcessDate}
                        </td>
                        <td>
                          <OverlayTrigger trigger={['hover', 'focus']} placement="bottom" overlay={<Tooltip id="tooltip-trigger-hover-focus">{t(this.getStatusToolTip(row.unattendedProcessStatusId))}</Tooltip>}>
                            <div className={'unattendedStatusIcon ' + this.getStatusClassName(row.unattendedProcessStatusId)}></div>
                          </OverlayTrigger>
                        </td>
                        <td>
                          <div className={'unattendedStatusIcon ' + this.getResultClassName(row)} onClick={() => this.openDetailModal(row)} style={{ cursor: 'pointer' }}></div>
                        </td>
                        <td>{row.endProcessDate}</td>
                        <td>{row.userName}</td>
                        <td className='actionTD'>{this.getFileIcon(row)}</td>
                      </tr>
                    ))}
                  />
                </div>
                <div className="search-footer" key="search-footer">
                  <IDocCarPagination
                    id="btn_pag_sales"
                    page={page}
                    pagesCount={pages}
                    totalRows= {count}
                    onSelectAction={(page) => this.onPageChange(page)}
                  />
                </div>
              </>)}
          </div>
        </Form>
      </>
    )
  }
}
const validate = (values, { t }, props) => {
  const errors = {}

  if (values && values.launchDateFrom && values.launchDateTo) {
    let dateTo = convertStringToDate(values.launchDateTo)
    let dateFrom = convertStringToDate(values.launchDateFrom)
    if (dateFrom > dateTo) {
      errors.launchDateTo = t('GLOBAL.FORMS.DATE_TO_NOT_LESS_THAN_DATE_FROM')
    }
  }

  if (values && values.endDateFrom && values.endDateTo) {
    let dateTo = convertStringToDate(values.endDateTo)
    let dateFrom = convertStringToDate(values.endDateFrom)
    if (dateFrom > dateTo) {
      errors.endDateTo = t('GLOBAL.FORMS.DATE_TO_NOT_LESS_THAN_DATE_FROM')
    }
  }

  if (values && (values.unattendedProcessStatus && values.unattendedProcessStatus.includes(unattendedResult.ENDED_NO_ERRORS)) || (!values.unattendedProcessStatus || values.unattendedProcessStatus.length === 0)) {
    if (!values.launchDateFrom) {
      errors.launchDateFrom = t('GLOBAL.FORMS.REQUIRED')
    }
    if (!values.launchDateTo) {
      errors.launchDateTo = t('GLOBAL.FORMS.REQUIRED')
    }
  }

  if (values.unattendedProcessView === null || values.unattendedProcessView === undefined) {
    errors.unattendedProcessView = t('GLOBAL.FORMS.REQUIRED')
  }

  return errors
}
export default reduxForm({
  form: 'unattendedProcessesFinder',
  validate
})(UnattendedProcessesFinder)
