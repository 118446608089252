
import React, { PureComponent } from 'react'
import { Modal, Col, Row, Button } from 'react-bootstrap'
import { reduxForm, Field } from 'redux-form'
import InputText from '../../../commons/form/InputText'
import InputSelect from '../../../commons/form/InputSelect'

class ProcessingAgencyIntegrationDocTypeUsesMasterModal extends PureComponent {
  constructor () {
    super()
    this.state = {}
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (prevProps.showModal !== this.props.showModal && this.props.showModal === true) {
      this.props.initialize(this.props.processingAgencyDocTypeUse)
    }
  }

  submitProcessingAgencyDocTypeUse (values) {
    const processingAgencyDocTypeUse = {
      ProcessingAgencyDocTypeUseId: this.props.processingAgencyDocTypeUse?.processingAgencyDocTypeUseId,
      ProcessingAgencyId: this.props.agency.processingAgencyId,
      DocumentTypeUseId: values.documentTypeUseId,
      PaperworkTypeId: values.paperworkTypeId,
      DocumentCode: values.documentCode,
      IsReturnDocument: this.props.isReturnDocument
    }

    this.props.actions.submitProcessingAgencyDocTypeUses(this.props.agency.processingAgencyId, processingAgencyDocTypeUse)
  }

  render () {
    const {
      t, handleSubmit,
      showModal
    } = this.props
    const tKey = 'MASTERS.PROCESSING_AGENCY_MASTERS.DOC_TYPE_USES.'
    return (
      <Modal className="role-modal languages-modal integrations-modal-padding" show={showModal} onHide={() => this.props.actions.showProcessingAgencyDocTypeUsesModal(null, false)} backdrop={'static'}>
        <form autoComplete="off" onSubmit={handleSubmit(this.submitProcessingAgencyDocTypeUse.bind(this))}>
          <Modal.Header closeButton onHide={() => this.props.actions.showProcessingAgencyDocTypeUsesModal(null, false)}>
            <Modal.Title>{t(`${tKey}DOC_TYPE_USE`)}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="inputs-wrapper flexRow">
              <Col sm={6} className="custom-width-col">
                <h2>{t(`${tKey}DOC_TYPE_USE`)}</h2>
                <Field
                  name="documentTypeUseId"
                  placeholder={t(`${tKey}DOC_TYPE_USE`)}
                  component={InputSelect}
                  options={this.props.combos.documentTypeUseCombo}
                  valueKey="id"
                  labelKey="value"
                  colSm={0}
                />
              </Col>
              <Col sm={6} className="custom-width-col">
                <h2>{t(`${tKey}PAPERWORK_TYPE`)}</h2>
                <Field
                  name="paperworkTypeId"
                  placeholder={t(`${tKey}PAPERWORK_TYPE`)}
                  component={InputSelect}
                  options={this.props.combos.paperworkTypes}
                  valueKey="id"
                  labelKey="value"
                  colSm={0}
                />
              </Col>
              <Col sm={6}>
                <h2>{t(`${tKey}PROCESSING_AGENCY_CODE`)}</h2>
                <Field
                  name="documentCode"
                  placeholder={t(`${tKey}PROCESSING_AGENCY_CODE`)}
                  component={InputText}
                  required={[true]}
                  maxLength={50}
                  colSm={0}
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Col sm={12} className="btn-wrapper">
              <Button
                id="role-modal-submit-button"
                className="btn-standard"
                type="submit"
                disabled={this.props.pristine}>
                {t(`${tKey}MODAL.SUBMIT_BUTTON`)}
              </Button>
            </Col>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default reduxForm({
  form: 'editProcessingAgencyDocTypeUseModal',
  validate: (values, props) => {
    let error = {}
    const tKey = 'MASTERS.PROCESSING_AGENCY_MASTERS.DOC_TYPE_USES.VALIDATION.'
    if (!values.documentTypeUseId) {
      error.documentTypeUseId = props.t(`${tKey}VALUE_REQUIRED`)
    }

    if (!values.documentCode) {
      error.documentCode = props.t(`${tKey}VALUE_REQUIRED`)
    }

    return Object.keys(error).length > 0 ? error : {}
  }
})(ProcessingAgencyIntegrationDocTypeUsesMasterModal)
