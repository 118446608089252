import React, { PureComponent } from 'react'
import { Checkbox, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { formatMoney, formatNumber } from '../../../../util/utils'
import { Link } from 'react-router-dom'

class UACrow extends PureComponent {
  render () {
    const {
      t,
      tKey,
      fetchUACUnitEdition,
      readOnlyFields,
      openUACEdition,
      deleteUACUnit,
      uacUnit: {
        number, dealerNumber, vehicleBrand, vehicleModel, km, licensePlate, vinChassis, licensePlateDate, isPartOfPayment, purchaseId, canBeEditable, urlToNavigate, amount
      },
      dossierType
    } = this.props
    const editTooltip = (<Tooltip id="editTooltip">{t(`${tKey}EDIT_BTN`)}</Tooltip>)
    const deleteTooltip = (<Tooltip id="deleteTooltip">{t(`${tKey}DELETE_BTN`)}</Tooltip>)
    return (
      <tr>
        <td>
          {
            urlToNavigate ? (<Link to={`/dossier${urlToNavigate}`}>{number}</Link>)
              : number
          }
        </td>
        <td>{dealerNumber}</td>
        <td>{vehicleBrand}</td>
        <td>{vehicleModel}</td>
        <td className="text-right">{formatNumber(km, '', 0, ',', '.')}</td>
        <td>{licensePlate}</td>
        <td>{vinChassis}</td>
        <td>{licensePlateDate}</td>
        <td><span><Checkbox className="checkbox-no-label" checked={isPartOfPayment} disabled inline />{formatMoney(amount)}</span></td>
        {
          canBeEditable && !readOnlyFields
            ? <td>
              <a onClick={() => fetchUACUnitEdition(purchaseId, openUACEdition, dossierType)}>
                <OverlayTrigger placement="right" overlay={editTooltip}>
                  <i className="ico-edit-white"/>
                </OverlayTrigger>
              </a>
              <a onClick={() => deleteUACUnit(purchaseId, dossierType)}>
                <OverlayTrigger placement="left" overlay={deleteTooltip}>
                  <i className="ico-trash"/>
                </OverlayTrigger>
              </a>
            </td>
            : <td/>

        }
      </tr>
    )
  }
}
export default UACrow
