import dossierActionTypes from '../../constants/actions/dossier/dossier'

export function submitNewDossier (values) {
  return {
    type: dossierActionTypes.SUBMIT_NEW_DOSSIER,
    values
  }
}
export function storeDossier (dossierId, pristine, reset, forceStore) {
  return {
    type: dossierActionTypes.STORE_DOSSIER,
    dossierId,
    pristine,
    reset,
    forceStore
  }
}

export function updateDossierCommon (dossierId, parentDossierType) {
  return {
    type: dossierActionTypes.UPDATE_DOSSIER_COMMON,
    dossierId,
    parentDossierType
  }
}
export function documentsToSendSignGetAll (dossierId, resolve, reject) {
  return {
    type: dossierActionTypes.DOCUMENTS_TO_SEND_SIGN_GETALL,
    dossierId,
    resolve,
    reject
  }
}

export function openDocumentsToSendSign (documentsToSendSignFn) {
  return {
    type: dossierActionTypes.DOCUMENTS_TO_SEND_SIGN,
    documentsToSendSignFn
  }
}

export function sendDocumentToSign (documents, dossierid, dossierNumber) {
  return {
    type: dossierActionTypes.SEND_DOCUMENTS_TO_SEND,
    documents,
    dossierid,
    dossierNumber
  }
}

export function checkDocTypeBefChangeCurrency (dossierId, currencyId, pristine) {
  return {
    type: dossierActionTypes.CHECK_DOC_TYPES_BEF_CHANGE_CURRENCY,
    dossierId,
    currencyId,
    pristine
  }
}

export default {
  submitNewDossier,
  storeDossier,
  updateDossierCommon,
  documentsToSendSignGetAll,
  openDocumentsToSendSign,
  sendDocumentToSign,
  checkDocTypeBefChangeCurrency
}
