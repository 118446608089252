import unattendedProcess from '../../constants/actions/unattendedProcess/unattendedProcess'

function initialState () {
  return {
    hasSearch: false,
    filters: {
      page: 1,
      pageSize: 10,
      orderBy: '-startProcessDate'
    },
    dynamicFilters: {
      unattendedProcessStatus: [1, 2],
      unattendedProcessView: 1
    },
    unattendedProcessData: [],
    pages: 1,
    count: 0,
    unattendedProcessDetailModal: {
      process: null,
      dossierNumbers: [],
      showModal: false
    }
  }
}

export function findUnattendedProcessSuccess (state, { data, filter }) {
  return {
    ...state,
    pages: data.pages,
    count: data.count,
    hasSearch: true,
    dynamicFilters: filter,
    filters: {
      pageSize: filter.pageSize,
      page: filter.page,
      orderBy: filter.orderBy
    },
    unattendedProcessData: data.items
  }
}

export function openUnattendedProcessDetailModal (state, { process }) {
  return {
    ...state,
    unattendedProcessDetailModal: {
      process: process,
      dossierNumbers: [],
      showModal: true
    }
  }
}

export function closeUnattendedProcessDetailModal (state) {
  return {
    ...state,
    unattendedProcessDetailModal: {
      ...state.unattendedProcessDetailModal,
      showModal: false
    }
  }
}

export function findDossierNumbersFromIdSuccess (state, { dossierNumbers }) {
  return {
    ...state,
    unattendedProcessDetailModal: {
      ...state.unattendedProcessDetailModal,
      dossierNumbers: dossierNumbers
    }
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case unattendedProcess.FIND_UNATTENDED_PROCESS_SUCCESS:
      return findUnattendedProcessSuccess(state, action)
    case unattendedProcess.OPEN_UNATTENDED_PROCESS_DETAIL_MODAL:
      return openUnattendedProcessDetailModal(state, action)
    case unattendedProcess.CLOSE_UNATTENDED_PROCESS_DETAIL_MODAL:
      return closeUnattendedProcessDetailModal(state, action)
    case unattendedProcess.FIND_DOSSIER_NUMBERS_UNATTENDED_PROCESS_SUCCES:
      return findDossierNumbersFromIdSuccess(state, action)
    default:
      return state
  }
}
