import mastersActionTypes from '../../../constants/actions/masters/masters'

function initialState () {
  return {
    ratings: [],
    ratingList: [],
    ratingListCount: 0,
    pagesCount: 0,
    values: [],
    filter: {
      page: 1,
      orderBy: '',
      pageSize: 10
    },
    ratingModal: {
      rating: null,
      showModal: false
    },
    changeTabFromMaster: false,
    ouIdFromMaster: null
  }
}

function fetchRatingListSuccess (state, { ratingList, filter }) {
  return {
    ...state,
    ratingList: ratingList.ratingList,
    ratingListCount: ratingList.ratingListCount,
    pagesCount: ratingList.pagesCount,
    languageSelected: ratingList.languageSelected,
    filter
  }
}

function openEditRatingModalSuccess (state, { rating }) {
  const initial = initialState()
  return {
    ...state,
    ratingModal: {
      ...initial.ratingModal,
      rating,
      showModal: true
    }
  }
}

function openAddModalSuccess (state, { rating }) {
  const initial = initialState()
  return {
    ...state,
    ratingModal: {
      ...initial.ratingModal,
      rating: rating,
      nonEditable: false,
      showModal: true
    }
  }
}

function closeEditModalSuccess (state) {
  return {
    ...state,
    ratingModal: {
      ...initialState().ratingModal
    }
  }
}

function setChangeTabFromConfMasterSuccess (state, { ouIdFromMaster, changeTabFromMaster }) {
  return {
    ...state,
    ouIdFromMaster,
    changeTabFromMaster
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case mastersActionTypes.FETCH_RATING_LIST_SUCCESS:
      return fetchRatingListSuccess(state, action)
    case mastersActionTypes.OPEN_EDIT_RATING_MODAL_SUCCESS:
      return openEditRatingModalSuccess(state, action)
    case mastersActionTypes.OPEN_ADD_RATING_MODAL_SUCCESS:
      return openAddModalSuccess(state, action)
    case mastersActionTypes.CLOSE_EDIT_RATING_MODAL:
      return closeEditModalSuccess(state, action)
    case mastersActionTypes.SET_CHANGE_TAB_FROM_CONF_MASTER_SUCCESS:
      return setChangeTabFromConfMasterSuccess(state, action)
    default:
      return state
  }
}
