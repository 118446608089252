import React, { PureComponent } from 'react'
import { Tabs, Tab } from 'react-bootstrap'
import EntityMastersPage from '../../containers/masters/Entity/EntityMastersPage'
import ExtraSaleMastersPage from '../../containers/masters/ExtraSale/ExtraSaleMastersPage'
import PresentsMastersPage from '../../containers/masters/Presents/PresentsMastersPage'
import SuppliedMastersPage from '../../containers/masters/Supplied/SuppliedMastersPage'
import ProcessingAgency from '../../containers/masters/ProcessingAgency/ProcessingAgencyMasterPage'
import ProcessingAgencyAssignationPage from '../../containers/masters/ProcessingAgencyAssignation/ProcessingAgencyAssignationMasterPage'
import PaymentMethod from '../../containers/masters/PaymentMethod/PaymentMethodMasterPage'
import Financing from '../../containers/masters/Financing/FinancingMasterPage'
import AssociatedCostsMastersPage from '../../containers/masters/AssociatedCosts/AssociatedCostsMastersPage'
import FuelPage from '../../containers/masters/fuel/FuelMasterPage'
import FaqMastersPage from '../../containers/masters/Faq/FaqMasterPage'
import FaqSectionMastersPage from '../../containers/masters/FaqSection/FaqSectionMasterPage'
import PurchasePresentTypeMastersPage from '../../containers/masters/PurchasePresentType/PurchasePresentTypeMasterPage'
import CampaignMasterPage from '../../containers/masters/Campaign/CampaignMasterPage'
import CampaignGroupMasterPage from '../../containers/masters/CampaignGroup/CampaignGroupMasterPage'
import CampaignTypeMasterPage from '../../containers/masters/CampaignType/CampaignTypeMasterPage'
import StyleTaskMasterPage from '../../containers/masters/StyleTask/StyleTaskMasterPage'
import { permissions } from '../../constants/backendIds'
import { getSectionPermissions } from '../../util/permisionFunctions'
import { sectionPermissions } from '../../constants/dossier/common/buttonsHeader'
import { mastersTabIndex } from '../../constants/components/mastersTabIndex'
import FinancingTypeProductPage from '../../containers/masters/FinancingTypeProduct/FinancingTypeProductMastersPage'
import OuMastersPage from '../../containers/masters/OuLoad/OuMastersPage'
import PwkTypesMasterPage from '../../containers/masters/PaperworkType/PwkTypesMasterPage'
import OperationTypeMasterPage from '../../containers/masters/OperationType/OperationTypeMasterPage'
import DocumentTypeMasterPage from '../../containers/masters/DocumentType/DocumentTypeMasterPage'
import DocumentVisualizationMasterPage from '../../containers/masters/DocumentVisualization/DocumentVisualizationMasterPage'
import DocumentTypeUseMasterPage from '../../containers/masters/DocumentTypeUse/DocumentTypeUseMasterPage'
import OrganizedUnitSaleOperationMasterPage from '../../containers/masters/OrganizedUnitSaleOperation/OrganizedUnitSaleOperationMasterPage'
import MetadataMasterPage from '../../containers/masters/Metadata/MetadataMasterPage'
import DirectionUOMasterPage from '../../containers/masters/DirectionUO/DirectionUOMasterPage'
import LegalCompanyMasterPage from '../../containers/masters/LegalCompany/LegalCompanyMasterPage'
import OrganizedUnitConfigurationMasterPage from '../../containers/masters/OrganizedUnitConfiguration/OrganizedUnitConfigurationMasterPage'
import SaleTypeMastersPage from '../../containers/masters/saleType/SaleTypeMastersPage'
import SignTypeMastersPage from '../../containers/masters/signType/SignTypeMastersPage'
import SignatureConfigMasterPage from '../../containers/masters/SignatureConfig/SignatureConfigMasterPage'
import DossierStageMasterPage from '../../containers/masters/DossierStage/DossierStageMasterPage'
import ProcessingAgencyOrganizedUnitConfigMasterPage from '../../containers/masters/ProcessingAgencyOrganizedUnitConfig/ProcessingAgencyOrganizedUnitConfigMasterPage'
import ImportVehicleModelsPage from '../../containers/masters/ImportVehicleModels/ImportVehicleModelsPage'
import LoadSepomexFileMasterPage from '../../containers/masters/SepomexFile/LoadSepomexFileMasterPage'
import RatingMasterPage from '../../containers/masters/Rating/RatingMasterPage'
import RepairshopMasterPage from '../../containers/masters/Repairshop/RepairshopMasterPage'
import MaritalStatusMasterPage from '../../containers/masters/MaritalStatus/MaritalStatusMasterPage'
import CustomFieldMasterPage from '../../containers/masters/CustomField/CustomFieldMasterPage'
import DestinationCraneMasterPage from '../../containers/masters/DestinationCrane/DestinationCraneMasterPage'
import ProcessingManagerMasterPage from '../../containers/masters/ProcessingManager/ProcessingManagerMasterPage'
import CodeCommissionMastersPage from '../../containers/masters/CodeCommission/CodeCommissionMastersPage'

class Masters extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      activeTab: mastersTabIndex.suppliedMastersPage,
      sectionTabsConfiguration: {
        suppliedMasters: {
          permission: null
        },
        presentsMasters: {
          permission: null
        },
        extraSaleMasters: {
          permission: null
        },
        entityMasters: {
          permission: null
        },
        processingAgency: {
          permission: null
        },
        financingMasters: {
          permission: null
        },
        associatedCostsMasters: {
          permission: null
        },
        fuel: {
          permission: null
        },
        paymentMethod: {
          permission: null
        },
        financingTypeMasters: {
          permission: null
        },
        faqMasters: {
          permission: null
        },
        faqSectionMasters: {
          permission: null
        },
        processingAgencyAssignation: {
          permission: null
        },
        purchasePresentTypeMasters: {
          permission: null
        },
        campaignMaster: {
          permission: null
        },
        campaignGroupMaster: {
          permission: null
        },
        campaignTypeMaster: {
          permission: null
        },
        styleTasksMaster: {
          permission: null
        },
        financingTypeProduct: {
          permission: null
        },
        organizedUnitMasterUpload: {
          permission: null
        },
        pwkTypeMaster: {
          permission: null
        },
        operationTypeMaster: {
          permission: null
        },
        documentTypeMaster: {
          permission: null
        },
        documentTypeUseMaster: {
          permissions: null
        },
        organizedUnitSaleOperationMaster: {
          permission: null
        },
        documentTypeMetadataMaster: {
          permission: null
        },
        documentVisualizationMaster: {
          permission: null
        },
        directionUOMaster: {
          permission: null
        },
        legalCompany: {
          permission: null
        },
        organizedUnitConfigurationMaster: {
          permission: null
        },
        signatureConfigurationMaster: {
          permission: null
        },
        dossierStageMaster: {
          permission: null
        },
        processingAgencyOrganizedUnitConfigMaster: {
          permission: null
        },
        organizedUnitMaster: {
          permission: null
        },
        importVehicleModelMaster: {
          permission: null
        },
        loadSepomexFileMasters: {
          permission: null
        },
        ratingMaster: {
          permission: null
        },
        repairshopMaster: {
          permission: null
        },
        maritalStatusMaster: {
          permission: null
        },
        customFieldsMaster: {
          permission: null
        },
        destinationCraneMaster: {
          permission: null
        },
        processingManagerMaster: {
          permission: null
        },
        signatureTypeMaster: {
          permission: null
        },
        codeCommissionMaster: {
          permission: null
        }
      }

    }
  }

  componentDidUpdate () {
    if (this.props.changeTabFromMaster === true) {
      this.setState({ activeTab: mastersTabIndex.organizedUnitConfiguration })
      this.props.actions.setChangeTabFromConfMaster(this.props.ouIdFromMaster, false)
    }
  }

  componentDidMount () {
    this.initializePermisions(this.props)
  }

  initializePermisions (props) {
    const section = props.sections.filter((tabConf) => tabConf.code === sectionPermissions.masters)
    if (section.length > 0 && section[0] != null) {
      const newState = getSectionPermissions(section[0])
      let permissionState = this.state.sectionTabsConfiguration
      permissionState = { ...permissionState, ...newState }
      this.setState({ sectionTabsConfiguration: permissionState })
    }
  }

  UNSAFE_componentWillMount () {
    this.onSelectTab()
  }

  onSelectTab (tabKey) {
    this.setState({ activeTab: tabKey })
    switch (tabKey) {
      case mastersTabIndex.suppliedMastersPage:
        this.props.actions.fetchEntityMasters()
        break
      case mastersTabIndex.presentsMastersPage:
        this.props.actions.fetchEntityMasters()
        break
      case mastersTabIndex.ExtraSaleMastersPage:
        this.props.actions.fetchEntityMasters()
        break
      case mastersTabIndex.entityMastersPage:
        this.props.actions.fetchEntityMasters()
        break
      case mastersTabIndex.processingAgency:
        this.props.actions.fetchProcessingAgencyCommunicationServices()
        this.props.actions.fetchProcessingAgencyList()
        break
      case mastersTabIndex.associatedCostsMastersPage:
        this.props.actions.fetchEntityMasters()
        break
      case mastersTabIndex.fuelPage:
        this.props.actions.fetchFuelList()
        break
      case mastersTabIndex.financing:
        this.props.actions.fetchFinancingList()
        this.props.actions.getAllFinancingTypeProduct(false)
        break
      case mastersTabIndex.paymentMethod:
        this.props.actions.fetchPaymentMethodList()
        break
      case mastersTabIndex.financingTypeMastersPage:
        this.props.actions.fetchFinancingTypeList()
        break
      case mastersTabIndex.processingAgencyAssignationPage:
        this.props.actions.fetchEntityMasters()
        break
      case mastersTabIndex.faqSectionMastersPage:
        this.props.actions.fetchFaqSectionTable()
        break
      case mastersTabIndex.faqMastersPage:
        this.props.actions.openFaqMaster()
        this.props.actions.fetchFaqList()
        break
      case mastersTabIndex.purchasePresentTypeMastersPage:
        this.props.actions.fetchPurchasePresentTypeList()
        break
      case mastersTabIndex.campaignMasterPage:
        this.props.actions.fetchCampaignList()
        this.props.actions.fetchCampaignTypeList()
        this.props.actions.fetchCampaignGroup(null)
        break
      case mastersTabIndex.campaignGroupMasterPage:
        this.props.actions.fetchCampaignGroupList()
        this.props.actions.fetchRoleCombo()
        break
      case mastersTabIndex.campaignTypeMasterPage:
        this.props.actions.fetchCampaignTypeList()
        this.props.actions.fetchCampaignGroup(null)
        break
      case mastersTabIndex.styleTaskMasterPage:
        this.props.actions.fetchStyleTaskList()
        break
      case mastersTabIndex.financingTypeProductPage:
        this.props.actions.fetchFinancingTypeProductList(null)
        this.props.actions.fetchFinancingTypeProductFields()
        break
      case mastersTabIndex.ouMastersPage:
        this.props.actions.fetchOrganizedUnitList(null)
        this.props.actions.fetchOrganizedUnitsAll()
        this.props.actions.fetchDirectionOUCombo()
        this.props.actions.fetchLegalCompanyCombo()
        this.props.actions.fetchCountries()
        break
      case mastersTabIndex.documentVisualizationMasterPage:
        this.props.actions.fetchDocumentTypeUseCombo()
        this.props.actions.fetchSearchDossierSubType()
        this.props.actions.fetchDocumentVisualizations()
        break
      case mastersTabIndex.documentTypeUseMasterPage:
        this.props.actions.fetchDocumentTypeCombo()
        this.props.actions.fetchDocumentTypeUseList()
        this.props.actions.fetchPermissionCombo()
        this.props.actions.fetchDocumentTypeUseCombo()
        break
      case mastersTabIndex.documentTypeMasterPage:
        this.props.actions.fetchDocumentTypeEntities()
        this.props.actions.fetchDocumentTypeList()
        break
      case mastersTabIndex.organizedUnitSaleOperationMasterPage:
        this.props.actions.fetchOrganizedUnitsAll()
        this.props.actions.fetchSaleTypeAll()
        this.props.actions.fetchOperationTypeAll()
        this.props.actions.fetchOrganizedUnitSaleOperationList()
        break
      case mastersTabIndex.documentTypeMetadataMasterPage:
        this.props.actions.fetchDocumentTypeCombo()
        break
      case mastersTabIndex.operationTypeMasterPage:
        this.props.actions.fetchOperationTypesTable()
        break
      case mastersTabIndex.organizedUnitConfiguration:
        this.props.actions.fetchOrganizedUnitsAll()
        this.props.actions.fetchConfigParameterAll()
        this.props.actions.fetchOrganizedUnitConfigurationList()
        break
      case mastersTabIndex.pwkTypesMasterPage:
        this.props.actions.fetchPaperwokTypesMaster({
          page: 1,
          orderBy: '',
          pageSize: 10
        })
        break
      case mastersTabIndex.signatureConfiguration:
        this.props.actions.fetchSignTypeCombo()
        this.props.actions.fetchSignEntityTypeCombo()
        this.props.actions.fetchSignatureConfigList()
        break
      case mastersTabIndex.directionOUMasterPage:
        this.props.actions.fetchDirectionUOMaster()
        break
      case mastersTabIndex.legalCompanyMasterPage:
        this.props.actions.fetchLegalCompanyList(null)
        break
      case mastersTabIndex.saleTypeMastersPage:
        this.props.actions.fetchSaleTypeList(null)
        break
      case mastersTabIndex.signTypeMasterPage:
        this.props.actions.fetchSignTypeList()
        break
      case mastersTabIndex.processingAgencyOrganizedUnitConfigMasterPage:
        this.props.actions.fetchOrganizedUnitsAll()
        this.props.actions.fetchProcessingAgencyCommunicationServices()
        break
      case mastersTabIndex.importVehicleModels:
        this.props.actions.fetchBrands()
        break
      case mastersTabIndex.loadSepomexFilePage:
        break
      case mastersTabIndex.dossierStageMasterPage:
        this.props.actions.initializeFilterState()
        this.props.actions.fetchDossierStageList()
        break
      case mastersTabIndex.ratingMaster:
        this.props.actions.fetchRatingList()
        this.props.actions.fetchOrganizedUnitsAll()
        break
      case mastersTabIndex.repairshopPage:
        this.props.actions.fetchRepairshopList()
        this.props.actions.fetchOrganizedUnitsAll()
        break
      case mastersTabIndex.maritalStatusPage:
        this.props.actions.fetchMaritalStatusList()
        break
      case mastersTabIndex.customFieldMasterPage:
        this.props.actions.fetchCustomFieldList()
        this.props.actions.fetchDossierSubTypes()
        this.props.actions.fetchCustomFieldTypesCombo()
        break
      case mastersTabIndex.destinationCraneMasterPage:
        this.props.actions.fetchDestinationCraneMaster()
        break
      case mastersTabIndex.processingManagerMasterPage:
        this.props.actions.fetchOrganizedUnitsAll()
        break
      case mastersTabIndex.codeCommissionMasterPage:
        this.props.actions.fetchOrganizedUnitsAll()
        this.props.actions.fetchcodeCommissionMasters(null, 1, 10, 'organizedUnitName', 'ASC')
        break
      default:
        this.props.actions.fetchEntityMasters()
        break
    }
  }

  render () {
    const { t } = this.props
    const tKey = 'MASTERS.'

    return (
      <div className="masters-container search-wrapper">
        <div className="search-header">
          <div className="title-user">
            <i className="ico-uo" />
            <h2>{t(`${tKey}TITLE`)}</h2>
          </div>
        </div>
        <Tabs activeKey={this.state.activeTab} defaultActiveKey={1} onSelect={this.onSelectTab.bind(this)} id="groups-tabs">
          {this.state.sectionTabsConfiguration.suppliedMasters.permission !== permissions.hidden && (
            <Tab eventKey={mastersTabIndex.suppliedMastersPage} title={t(`${tKey}SUPPLIED`)}>
              <SuppliedMastersPage />
            </Tab>)}
          {this.state.sectionTabsConfiguration.presentsMasters.permission !== permissions.hidden && (
            <Tab eventKey={mastersTabIndex.presentsMastersPage} title={t(`${tKey}PRESENTS`)}>
              <PresentsMastersPage />
            </Tab>)}
          {this.state.sectionTabsConfiguration.extraSaleMasters.permission !== permissions.hidden && (
            <Tab eventKey={mastersTabIndex.extraSaleMastersPage} title={t(`${tKey}EXTRA_SALE`)}>
              <ExtraSaleMastersPage />
            </Tab>)}
          {this.state.sectionTabsConfiguration.entityMasters.permission !== permissions.hidden && (
            <Tab eventKey={mastersTabIndex.entityMastersPage} title={t(`${tKey}ENTITY`)}>
              <EntityMastersPage />
            </Tab>)}
          {this.state.sectionTabsConfiguration.processingAgency.permission !== permissions.hidden && (
            <Tab eventKey={mastersTabIndex.processingAgency} title={t(`${tKey}PROCESSING_AGENCY`)}>
              <ProcessingAgency tabConfiguration={this.state.sectionTabsConfiguration.processingAgency} />
            </Tab>)}
          {this.state.sectionTabsConfiguration.financingMasters.permission !== permissions.hidden && (
            <Tab eventKey={mastersTabIndex.financing} title={t(`${tKey}FINANCING`)}>
              <Financing />
            </Tab>)}
          {this.state.sectionTabsConfiguration.associatedCostsMasters.permission !== permissions.hidden && (
            <Tab eventKey={mastersTabIndex.associatedCostsMastersPage} title={t(`${tKey}ASSOCIATED_COSTS`)}>
              <AssociatedCostsMastersPage/>
            </Tab>)}
          {this.state.sectionTabsConfiguration.fuel.permission !== permissions.hidden && (
            <Tab eventKey={mastersTabIndex.fuelPage} title={t(`${tKey}FUEL_TYPE`)}>
              <FuelPage/>
            </Tab>)}
          {this.state.sectionTabsConfiguration.paymentMethod.permission !== permissions.hidden && (
            <Tab eventKey={mastersTabIndex.paymentMethod} title={t(`${tKey}PAYMENT_METHOD`)}>
              <PaymentMethod />
            </Tab>)}
          {this.state.sectionTabsConfiguration.faqMasters.permission !== permissions.hidden && (
            <Tab eventKey={mastersTabIndex.faqMastersPage} title={t(`${tKey}FAQ_MASTER_TITLE`)}>
              <FaqMastersPage/>
            </Tab>)}
          {this.state.sectionTabsConfiguration.faqSectionMasters.permission !== permissions.hidden && (
            <Tab eventKey={mastersTabIndex.faqSectionMastersPage} title={t(`${tKey}FAQSECTIONS`)}>
              <FaqSectionMastersPage/>
            </Tab>)}
          {this.state.sectionTabsConfiguration.processingAgencyAssignation.permission !== permissions.hidden && (
            <Tab eventKey={mastersTabIndex.processingAgencyAssignationPage} title={t(`${tKey}QUOTA_BY_PROCESSING`)} className="quota">
              <ProcessingAgencyAssignationPage/>
            </Tab>)}
          {this.state.sectionTabsConfiguration.purchasePresentTypeMasters.permission !== permissions.hidden && (
            <Tab eventKey={mastersTabIndex.purchasePresentTypeMastersPage} title={t(`${tKey}PURCHASE_PRESENT_TYPE`)}>
              <PurchasePresentTypeMastersPage/>
            </Tab>)}
          {this.state.sectionTabsConfiguration.campaignMaster.permission !== permissions.hidden && (
            <Tab eventKey={mastersTabIndex.campaignMasterPage} title={t(`${tKey}CAMPAIGN`)}>
              <CampaignMasterPage/>
            </Tab>)}
          {this.state.sectionTabsConfiguration.campaignGroupMaster.permission !== permissions.hidden && (
            <Tab eventKey={mastersTabIndex.campaignGroupMasterPage} title={t(`${tKey}CAMPAIGN_GROUP`)}>
              <CampaignGroupMasterPage/>
            </Tab>)}
          {this.state.sectionTabsConfiguration.campaignTypeMaster.permission !== permissions.hidden && (
            <Tab eventKey={mastersTabIndex.campaignTypeMasterPage} title={t(`${tKey}CAMPAIGN_TYPE`)}>
              <CampaignTypeMasterPage/>
            </Tab>)}
          {this.state.sectionTabsConfiguration.styleTasksMaster.permission !== permissions.hidden && (
            <Tab eventKey={mastersTabIndex.styleTaskMasterPage} title={t(`${tKey}STYLE_TASK`)}>
              <StyleTaskMasterPage/>
            </Tab>)}
          {this.state.sectionTabsConfiguration.financingTypeProduct.permission !== permissions.hidden && (
            <Tab eventKey={mastersTabIndex.financingTypeProductPage} title={t(`${tKey}FINANCING_TYPE_PRODUCTS`)}>
              <FinancingTypeProductPage/>
            </Tab>)}
          {this.state.sectionTabsConfiguration.organizedUnitMasterUpload.permission !== permissions.hidden && (
            <Tab eventKey={mastersTabIndex.ouMastersPage} title={t(`${tKey}OU`)}>
              <OuMastersPage/>
            </Tab>)}
          {this.state.sectionTabsConfiguration.documentVisualizationMaster.permission !== permissions.hidden && (
            <Tab eventKey={mastersTabIndex.documentVisualizationMasterPage} title={t(`${tKey}DOCUMENT_VISUALIZATION.DOCUMENT_VISUALIZATION`)}>
              <DocumentVisualizationMasterPage/>
            </Tab>)}
          {this.state.sectionTabsConfiguration.documentTypeUseMaster.permission !== permissions.hidden && (
            <Tab eventKey={mastersTabIndex.documentTypeUseMasterPage} title={t(`${tKey}DOCUMENT_USE_TYPE`)}>
              <DocumentTypeUseMasterPage/>
            </Tab>)}
          {this.state.sectionTabsConfiguration.organizedUnitSaleOperationMaster.permission !== permissions.hidden && (
            <Tab eventKey={mastersTabIndex.organizedUnitSaleOperationMasterPage} title={t(`${tKey}OPERATION_TYPES_IN_SALE_TYPES`)}>
              <OrganizedUnitSaleOperationMasterPage/>
            </Tab>)}
          {this.state.sectionTabsConfiguration.documentTypeMaster.permission !== permissions.hidden && (
            <Tab eventKey={mastersTabIndex.documentTypeMasterPage} title={t(`${tKey}DOCUMENT_TYPE`)}>
              <DocumentTypeMasterPage/>
            </Tab>)}
          {this.state.sectionTabsConfiguration.documentTypeMetadataMaster.permission !== permissions.hidden && (
            <Tab eventKey={mastersTabIndex.documentTypeMetadataMasterPage} title={t(`${tKey}DOCUMENT_TYPE_METADATA`)}>
              <MetadataMasterPage/>
            </Tab>)}
          {this.state.sectionTabsConfiguration.operationTypeMaster.permission !== permissions.hidden && (
            <Tab eventKey={mastersTabIndex.operationTypeMasterPage} title={t(`${tKey}OPERATION_TYPE`)}>
              <OperationTypeMasterPage/>
            </Tab>)}
          {this.state.sectionTabsConfiguration.directionUOMaster.permission !== permissions.hidden && (
            <Tab eventKey={mastersTabIndex.directionOUMasterPage} title={t(`${tKey}DIRECTIONS_OU`)}>
              <DirectionUOMasterPage />
            </Tab>)}
          {this.state.sectionTabsConfiguration.legalCompany.permission !== permissions.hidden && (
            <Tab eventKey={mastersTabIndex.legalCompanyMasterPage} title={t(`${tKey}LEGAL_COMPANY`)}>
              <LegalCompanyMasterPage/>
            </Tab>)}
          {this.state.sectionTabsConfiguration.organizedUnitConfigurationMaster.permission !== permissions.hidden && (
            <Tab eventKey={mastersTabIndex.organizedUnitConfiguration} title={t(`${tKey}ORGANIZED_UNIT_CONFIGURATION`)}>
              <OrganizedUnitConfigurationMasterPage/>
            </Tab>)}
          {this.state.sectionTabsConfiguration.pwkTypeMaster.permission !== permissions.hidden && (
            <Tab eventKey={mastersTabIndex.pwkTypesMasterPage} title={t(`${tKey}PWK_TYPES_MASTER.TITTLE`)}>
              <PwkTypesMasterPage tabConfiguration={this.state.sectionTabsConfiguration.pwkTypeMaster}/>
            </Tab>)}
          {this.state.sectionTabsConfiguration.financingTypeProduct.permission !== permissions.hidden && (
            <Tab eventKey={mastersTabIndex.saleTypeMastersPage} title={t(`${tKey}SALE_TYPES`)}>
              <SaleTypeMastersPage/>
            </Tab>)}
          {this.state.sectionTabsConfiguration.signatureTypeMaster.permission !== permissions.hidden && (
            <Tab eventKey={mastersTabIndex.signTypeMasterPage} title={t(`${tKey}SIGNATURE_TYPES`)}>
              <SignTypeMastersPage/>
            </Tab>)}
          {this.state.sectionTabsConfiguration.signatureConfigurationMaster.permission !== permissions.hidden && (
            <Tab eventKey={mastersTabIndex.signatureConfiguration} title={t(`${tKey}SIGNATURE_CONFIGURATION`)}>
              <SignatureConfigMasterPage/>
            </Tab>)}
          {this.state.sectionTabsConfiguration.dossierStageMaster.permission !== permissions.hidden && (
            <Tab eventKey={mastersTabIndex.dossierStageMasterPage} title={t(`${tKey}DOSSIER_STAGES`)}>
              <DossierStageMasterPage/>
            </Tab>)}
          {this.state.sectionTabsConfiguration.processingAgencyOrganizedUnitConfigMaster.permission !== permissions.hidden && (
            <Tab eventKey={mastersTabIndex.processingAgencyOrganizedUnitConfigMasterPage} title={t(`${tKey}PROCESSING_AGENCY_ORGANIZED_UNIT_CONFIG.PROCESSING_AGENCY_ORGANIZED_UNIT_CONFIG`)}>
              <ProcessingAgencyOrganizedUnitConfigMasterPage/>
            </Tab>)}
          {this.state.sectionTabsConfiguration.importVehicleModelMaster.permission && this.state.sectionTabsConfiguration.importVehicleModelMaster.permission !== permissions.hidden && (
            <Tab eventKey={mastersTabIndex.importVehicleModels} title={t(`${tKey}VEHICLE_MODELS.TITLE_TAB`)}>
              <ImportVehicleModelsPage/>
            </Tab>)}
          {this.state.sectionTabsConfiguration.loadSepomexFileMasters.permission && this.state.sectionTabsConfiguration.loadSepomexFileMasters.permission !== permissions.hidden && (
            <Tab eventKey={mastersTabIndex.loadSepomexFilePage} title={t(`${tKey}SEPOMEX.TITLE_TAB`)}>
              <LoadSepomexFileMasterPage/>
            </Tab>)}
          { this.state.sectionTabsConfiguration.ratingMaster.permission !== permissions.hidden && (
            <Tab eventKey={mastersTabIndex.ratingMaster} title={t(`${tKey}RATING`)}>
              <RatingMasterPage/>
            </Tab>)
          }
          { this.state.sectionTabsConfiguration.repairshopMaster.permission && this.state.sectionTabsConfiguration.repairshopMaster.permission !== permissions.hidden && (
            <Tab eventKey={mastersTabIndex.repairshopPage} title={t(`${tKey}REPAIR_SHOPS`)}>
              <RepairshopMasterPage/>
            </Tab>)}
          { this.state.sectionTabsConfiguration.maritalStatusMaster.permission !== permissions.hidden && (
            <Tab eventKey={mastersTabIndex.maritalStatusPage} title={t(`${tKey}MARITAL_STATUS`)}>
              <MaritalStatusMasterPage/>
            </Tab>)}
          {this.state.sectionTabsConfiguration.customFieldsMaster.permission !== permissions.hidden && (
            <Tab eventKey={mastersTabIndex.customFieldMasterPage} title={t(`${tKey}CUSTOM_FIELDS.TITLE`)}>
              <CustomFieldMasterPage/>
            </Tab>)}
          {this.state.sectionTabsConfiguration.destinationCraneMaster.permission && this.state.sectionTabsConfiguration.destinationCraneMaster.permission !== permissions.hidden && (
            <Tab eventKey={mastersTabIndex.destinationCraneMasterPage} title={t(`${tKey}DESTINATION_CRANE_MASTER.TITTLE`)}>
              <DestinationCraneMasterPage/>
            </Tab>)}
          { this.state.sectionTabsConfiguration.processingManagerMaster.permission !== permissions.hidden && (
            <Tab eventKey={mastersTabIndex.processingManagerMasterPage} title={t(`${tKey}PROCESSING_MANAGER.TITLE`)}>
              <ProcessingManagerMasterPage eventKey={this.state.activeTab} />
            </Tab>
          )}
          {this.state.sectionTabsConfiguration.codeCommissionMaster.permission && this.state.sectionTabsConfiguration.codeCommissionMaster.permission !== permissions.hidden && (
            <Tab eventKey={mastersTabIndex.codeCommissionMasterPage} title={t(`${tKey}CODE_COMMISSION.TITLE`)}>
              <CodeCommissionMastersPage eventKey={this.state.activeTab} />
            </Tab>
          )}

        </Tabs>
      </div>
    )
  }
}

export default Masters
