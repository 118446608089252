import deleteOrganizedUnitConfigurationService from '../../../services/organizedUnitConfiguration/deleteOrganizedUnitConfiguration'
import postOrganizedUnitConfiguration from '../../../services/organizedUnitConfiguration/postOrganizedUnitConfiguration'
import postOrganizedUnitConfigurationList from '../../../services/organizedUnitConfiguration/postOrganizedUnitConfigurationList'
import putOrganizedUnitConfiguration from '../../../services/organizedUnitConfiguration/putOrganizedUnitConfiguration'
import { fetchOrganizedUnitConfigurationListSuccess, openOrganizedUnitConfigurationModalSuccess, closeOrganizedUnitConfigurationMasterModal } from '../../../actions/masters/organizedUnitConfiguration/organizedUnitConfiguration'
import { getAuth } from '../../../selectors/access/auth'
import { getOrganizedUnitConfigurationFilter } from '../../../selectors/masters/organizedUnitConfiguration'
import { handleError } from '../../errors/errorManager'
import { put, call, select, takeEvery } from 'redux-saga/effects'
import { showLoader, hideLoader } from '../../../actions/common.js'
import { yesNoModal } from '../../modalsListeners/yesNoModal'
import mastersActionTypes from '../../../constants/actions/masters/masters'
import { setChangeTabFromConfMasterSuccess } from '../../../actions/masters/masters'

export function * deleteOrganizedUnitConfiguration (organizedUnitConfigurationId) {
  try {
    const confirmed = yield call(yesNoModal, 'updateOrganizedUnitConfiguration')
    if (confirmed) {
      yield put(showLoader())
      const auth = yield select(getAuth)
      const filter = yield filter || select(getOrganizedUnitConfigurationFilter)
      yield call(deleteOrganizedUnitConfigurationService, auth.token, organizedUnitConfigurationId)
      yield call(fetchOrganizedUnitConfigurationList, filter)
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDeleteOrganizedUnitConfiguration () {
  yield takeEvery(mastersActionTypes.DELETE_ORGANIZED_UNIT_CONFIGURATION, deleteOrganizedUnitConfiguration)
}

export function * submitOrganizedUnitConfiguration ({ organizedUnitConfiguration }) {
  try {
    const auth = yield select(getAuth)
    const filter = yield filter || select(getOrganizedUnitConfigurationFilter)
    const confirmed = yield call(yesNoModal, 'updateOrganizedUnitConfiguration')
    if (confirmed) {
      if (!organizedUnitConfiguration.organizedUnitConfigurationId) {
        yield put(showLoader())
        yield call(postOrganizedUnitConfiguration, auth.token, organizedUnitConfiguration)
      } else {
        yield put(showLoader())
        yield call(putOrganizedUnitConfiguration, auth.token, organizedUnitConfiguration)
      }
    }

    yield put(closeOrganizedUnitConfigurationMasterModal())
    yield call(fetchOrganizedUnitConfigurationList, filter)
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSubmitOrganizedUnitConfiguration () {
  yield takeEvery(mastersActionTypes.SUBMIT_ORGANIZED_UNIT_CONFIGURATION, submitOrganizedUnitConfiguration)
}

export function * fetchOrganizedUnitConfigurationList ({ filter }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield filter = yield filter || select(getOrganizedUnitConfigurationFilter)
    const organizedUnitConfigurationList = yield call(postOrganizedUnitConfigurationList, auth.token, filter)
    organizedUnitConfigurationList.organizedUnitConfigurations.map(organizedUnitConfiguration => {
      if (organizedUnitConfiguration.configParameter.type.includes('List')) {
        organizedUnitConfiguration.value = organizedUnitConfiguration.value.split(',')
      }
    })
    yield put(fetchOrganizedUnitConfigurationListSuccess(organizedUnitConfigurationList, filter))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchOrganizedUnitConfigurationList () {
  yield takeEvery(mastersActionTypes.FETCH_ORGANIZED_UNIT_CONFIGURATION_LIST, fetchOrganizedUnitConfigurationList)
}

export function * openOrganizedUnitConfigurationModal ({ organizedUnitConfiguration }) {
  try {
    yield put(showLoader())
    yield put(openOrganizedUnitConfigurationModalSuccess(organizedUnitConfiguration))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchOpenOrganizedUnitConfigurationModal () {
  yield takeEvery(mastersActionTypes.OPEN_ORGANIZED_UNIT_CONFIGURATION_MODAL, openOrganizedUnitConfigurationModal)
}

export function * setChangeTabFromConfMaster ({ ouIdFromMaster, changeTabFromMaster }) {
  yield put(setChangeTabFromConfMasterSuccess(ouIdFromMaster, changeTabFromMaster))
}

export function * watchSetChangeTabFromConfMaster () {
  yield takeEvery(mastersActionTypes.SET_CHANGE_TAB_FROM_CONF_MASTER, setChangeTabFromConfMaster)
}
