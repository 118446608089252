import importVehicleModels from '../../constants/actions/vehicle/importVehicleModels'

export function importVehicleModelFile (body, brandId) {
  return {
    type: importVehicleModels.IMPORT_VEHICLE_MODEL_FILE,
    body,
    brandId
  }
}

export function importVehicleModelFileSuccess (result) {
  return {
    type: importVehicleModels.IMPORT_VEHICLE_MODEL_FILE_SUCCESS,
    result
  }
}

export function cancelImportVehicleModelFile () {
  return {
    type: importVehicleModels.CANCEL_IMPORT_VEHICLE_MODEL_FILE
  }
}

export default {
  importVehicleModelFile,
  importVehicleModelFileSuccess,
  cancelImportVehicleModelFile
}
