import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import FaqSections from '../../components/faq/FaqSections'
import { fetchSectionList } from '../../actions/faq/faq'

export function mapStateToProps (state) {
  return {
    ...state.faq
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchSectionList
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(FaqSections))
