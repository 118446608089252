export const mastersTabIndex = {
  suppliedMastersPage: 1,
  presentsMastersPage: 2,
  extraSaleMastersPage: 3,
  entityMastersPage: 4,
  processingAgency: 5,
  financing: 6,
  associatedCostsMastersPage: 7,
  paymentMethod: 8,
  financingTypeMastersPage: 9,
  fuelPage: 10,
  processingAgencyAssignationPage: 11,
  faqSectionMastersPage: 12,
  faqMastersPage: 13,
  purchasePresentTypeMastersPage: 14,
  campaignMasterPage: 15,
  campaignGroupMasterPage: 16,
  campaignTypeMasterPage: 17,
  styleTaskMasterPage: 18,
  financingTypeProductPage: 19,
  ouMastersPage: 20,
  directionOUMasterPage: 36,
  saleTypeMastersPage: 21,
  documentVisualizationMasterPage: 22,
  documentTypeUseMasterPage: 23,
  documentTypeMasterPage: 24,
  organizedUnitSaleOperationMasterPage: 25,
  operationTypeMasterPage: 26,
  documentTypeMetadataMasterPage: 27,
  organizedUnitConfiguration: 28,
  pwkTypesMasterPage: 40,
  dossierStageMasterPage: 29,
  signatureConfiguration: 30,
  signTypeMasterPage: 31,
  processingAgencyOrganizedUnitConfigMasterPage: 32,
  legalCompanyMasterPage: 35,
  importVehicleModels: 38,
  loadSepomexFilePage: 37,
  ratingMaster: 43,
  repairshopPage: 42,
  destinationCraneMasterPage: 44,
  maritalStatusPage: 45,
  customFieldMasterPage: 41,
  processingManagerMasterPage: 47,
  codeCommissionMasterPage: 48

}
