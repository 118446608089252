import { call, put, all, takeEvery, select } from 'redux-saga/effects'
import { reset } from 'redux-form'
import dossierActionsTypes from '../../../constants/actions/dossier/dossier'
import { getAuth } from '../../../selectors/access/auth'
import getDossierPurchaseHeaderAndButtons from '../../../services/dossierPurchase/get/getPurchaseHeaderAndButtons'
import getStageCheckList from '../../../services/dossier/get/getStageCheckList'
import { showLoader, hideLoader } from '../../../actions/common'
import { handleError } from '../../errors/errorManager'
import { fetchPurchaseDossierSuccess } from '../../../actions/dossier/purchase'
import { fetchDependantsPurchaseDossier } from './fetchPurchaseDossier'

export function * updateDependantsPurchaseDossierAndHeader ({ dossierId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const [purchaseDossierHeader, checkListHeader] = yield all([
      call(getDossierPurchaseHeaderAndButtons, auth.token, dossierId),
      call(getStageCheckList, dossierId, auth.token)
    ])
    yield put(fetchPurchaseDossierSuccess({ ...purchaseDossierHeader, checkListHeader }))
    yield call(fetchDependantsPurchaseDossier, { dossierId })
  } catch (error) {
    const sessionHandler = function * () {
      yield put(reset('purchase_dossier'))
    }
    yield call(handleError, { error, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchUpdateDependantsPurchaseDossierAndHeader () {
  yield takeEvery(dossierActionsTypes.UPDATE_DEPENDANTS_PURCHASE_DOSSIER_AND_HEADER, updateDependantsPurchaseDossierAndHeader)
}
