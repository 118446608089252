import { put, call, select, takeEvery } from 'redux-saga/effects'
import { reset, initialize, change } from 'redux-form'
import reassignReplacePurchaseModalActionsTypes from '../../../constants/actions/dossier/common/reassignReplacePurchaseModal'
import { purchaseModalTypes } from '../../../constants/dossier/common/reassignPurchaseModalTypes'
import { openReplacePurchaseModal, getDossierPurchaseSuccess, closeReplacePurchaseModal } from '../../../actions/dossier/common/reassignReplacePurchaseModal'
import { setActiveSection } from '../../../actions/dossier/common/buttonsSidebar'
import { showLoader, hideLoader } from '../../../actions/common'
import messageModalActions from '../../../actions/modals/messageModal'
import { putPurchaseOnSale, fetchSalesDossier, fetchSalesDossierSuccess, fetchSalesDossierDependants } from '../../../actions/dossier/sales'
import { putPurchaseOnRegistrationDelivery, fetchRegistrationDeliveryDossierSuccess } from '../../../actions/dossier/registrationDelivery'
import { getAuth } from '../../../selectors/access/auth'
import getDossierPurchaseService from '../../../services/dossier/get/getDossierPurchase'
import putDossierPurchaseReassignToSalePurchaseIdService from '../../../services/dossierPurchase/put/putDossierPurchaseReassignToSalePurchaseId'
import putDossierPurchaseReassignToCampaignPurchaseIdService from '../../../services/dossierPurchase/put/putDossierPurchaseReassignToCampaignPurchaseId'
import putDossierPurchaseReassignToSaleService from '../../../services/dossierPurchase/put/putDossierPurchaseReassignToSale'
import putDossierPurchaseReassignToRegistrationDeliveryIdService from '../../../services/dossierPurchase/put/putDossierPurchaseReassignToRegistrationDeliveryPurchaseId'
import putDossierPurchaseReassignToRegistrationDeliveryService from '../../../services/dossierPurchase/put/putDossierPurchaseReassignToRegistrationDelivery'
import getStageCheckList from '../../../services/dossier/get/getStageCheckList'
import putDossierOrderPurchase from '../../../services/dossierPurchase/put/putDossierOrderPurchase'
import { fetchDependantsSaleDossier, refreshSalesDossierVehicleFromDocumentModal, fetchSalesEconomic } from '../fetchSalesDossier'
import getOrderByDossierId from '../../../services/order/getOrderByDossierId'
import { yesNoModal } from '../../modalsListeners/yesNoModal'
import getDossierRelated from '../../../services/dossier/get/getDossierRelatedDossiers'
import { handleError } from '../../errors/errorManager'
import settings from '../../../setting'
import { clientInformation } from '../../../constants/backendIds'
import { purchaseTypesVn } from '../../../constants/dossier/purchase/purchaseTypes'
import putUpdateOrderMakerNumber from '../../../services/order/putOrderUpdateMakerNumber'
import { fetchCampaignDossier } from '../campaign/fetchCampaignDossier'
import { fetchDependantsRegistrationDeliveryDossier, refreshRegistrationDeliveryDossierVehicleFromDocumentModal } from '../registrationDelivery/fetchRegistrationDeliveryDossier'
import { buttonsSidebar } from '../../../constants/dossier/common/buttonsSidebar'
import getCatalogueModel from '../../../services/catalogueModel/getCatalogueModel'

export function * fetchAndOpenReplacePurchaseModal ({ modalType, pristine }) {
  try {
    const confirmed = pristine || (yield call(yesNoModal, 'commonDossierSaveWarning'))
    yield put(showLoader())
    const auth = yield select(getAuth)
    let dossier = {}
    let order = null
    if (modalType === purchaseModalTypes.campaignDossier) {
      order = 'campaignDossier'
    } else if (modalType === purchaseModalTypes.saleDossier) {
      dossier = yield select(state => state.salesDossier)
      order = yield call(getOrderByDossierId, dossier.dossierId, auth.token)
    } else if (modalType === purchaseModalTypes.registrationDeliveryDossier) {
      dossier = yield select(state => state.registrationDeliveryDossier)
      order = yield call(getOrderByDossierId, dossier.dossierId, auth.token)
    }
    if (confirmed) {
      yield put(openReplacePurchaseModal(modalType, order))
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag !== 'dossierNotFound' && customHandlerError.json.tag !== 'TRANS/dossierNotFound')) {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('registrationDeliveryDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchAndOpenReplacePurchaseModal () {
  yield takeEvery(reassignReplacePurchaseModalActionsTypes.FETCH_AND_OPEN_REASSIGN_PURCHASE_MODAL, fetchAndOpenReplacePurchaseModal)
}

export function * searchFilteredPurchaseDossier ({ filterObject, resolve, reject }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const vehiclesPurchaseList = yield call(
      getDossierPurchaseService,
      filterObject.isVo,
      filterObject.dealerNumber,
      filterObject.vinChassis,
      filterObject.brand,
      filterObject.model,
      filterObject.color,
      filterObject.makerNumber,
      filterObject.availabilityDateFrom,
      filterObject.availabilityDateTo,
      auth.token,
      filterObject.organizedUnitId,
      filterObject.carlineId,
      filterObject.catalogueId,
      filterObject.modelId,
      filterObject.catalogueModelId,
      filterObject.colorId,
      filterObject.tapestryId,
      filterObject.carline,
      filterObject.catalogue,
      filterObject.modelYear
    )
    yield put(getDossierPurchaseSuccess(vehiclesPurchaseList))
    if (resolve) yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      if (reject) yield call(reject, customHandlerError)
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSearchFilteredPurchaseDossier () {
  yield takeEvery(reassignReplacePurchaseModalActionsTypes.SEARCH_FILTERED_PURCHASE_DOSSIER, searchFilteredPurchaseDossier)
}

export function * selectReplacePurchase ({ purchaseType, purchaseId, orderId, parentIsHistorical, makerNumber, makerNumberSale, purchaseTypeId }) {
  let dossierState
  if (purchaseType === purchaseModalTypes.campaignDossier) {
    dossierState = JSON.parse(JSON.stringify(yield select(state => state.campaignDossier)))
  } else if (purchaseType === purchaseModalTypes.saleDossier) {
    dossierState = JSON.parse(JSON.stringify(yield select(state => state.salesDossier)))
  } else if (purchaseType === purchaseModalTypes.registrationDeliveryDossier) {
    dossierState = JSON.parse(JSON.stringify(yield select(state => state.registrationDeliveryDossier)))
  }

  try {
    const auth = yield select(getAuth)
    let confirmed = true
    let updateOrder = false
    if (purchaseTypeId && purchaseTypeId === purchaseTypesVn.production && settings.clientFieldValidation === clientInformation.VGR && makerNumber && makerNumberSale && makerNumber !== makerNumberSale) {
      confirmed = yield call(yesNoModal, 'differentMakerNumberSalePurchase')
      updateOrder = true
    }
    if (confirmed) {
      yield put(showLoader())
      if (updateOrder) {
        yield call(putUpdateOrderMakerNumber, orderId, makerNumber, auth.token)
      }
      if (purchaseType === purchaseModalTypes.saleDossier) {
        if (purchaseId) {
          yield call(putDossierPurchaseReassignToSalePurchaseIdService, auth.token, purchaseId, dossierState.dossierId, parentIsHistorical, dossierState.lastAccessDate)
          yield put(closeReplacePurchaseModal())
        } else {
          let dossierSubTypeId = dossierState.dossierSubTypeId
          purchaseId = yield call(putDossierPurchaseReassignToSaleService, auth.token, dossierState.dossierId, orderId, dossierSubTypeId, dossierState.lastAccessDate)
          yield put(closeReplacePurchaseModal())
        }
        yield put(putPurchaseOnSale(purchaseId, parentIsHistorical))
        yield call(fetchDependantsSaleDossier, { dossierId: dossierState.dossierId })
        yield call(refreshSalesDossierVehicleFromDocumentModal)
        yield call(fetchSalesEconomic, { dossierId: dossierState.dossierId, historical: false, pristine: true })
        const dossierRelatedDossiers = yield call(getDossierRelated, dossierState.dossierId, '+number', auth.token)
        const checkListHeader = yield call(getStageCheckList, dossierState.dossierId, auth.token)
        yield put(fetchSalesDossierSuccess({ dossierRelatedDossiers, checkListHeader }))
        yield put(setActiveSection(buttonsSidebar.vehicle))
      } else if (purchaseType === purchaseModalTypes.campaignDossier) {
        if (purchaseId) {
          const percentage = dossierState.dossierCampaignComponent.dossierCampaignDetailComponent.percentageCampaignRequested || 0.0
          yield call(putDossierPurchaseReassignToCampaignPurchaseIdService, auth.token, purchaseId, dossierState.dossierId, parentIsHistorical, dossierState.lastAccessDate, percentage)
          yield put(closeReplacePurchaseModal())
        }
        yield call(fetchCampaignDossier, { dossierId: dossierState.dossierId })
        dossierState = yield select(state => state.campaignDossier)
        yield put(initialize('campaign_dossier', { ...dossierState }))
      } else if (purchaseType === purchaseModalTypes.registrationDeliveryDossier) {
        if (purchaseId) {
          yield call(putDossierPurchaseReassignToRegistrationDeliveryIdService, auth.token, purchaseId, dossierState.dossierId, parentIsHistorical, dossierState.lastAccessDate)
          yield put(closeReplacePurchaseModal())
        } else {
          let dossierSubTypeId = dossierState.dossierSubTypeId
          purchaseId = yield call(putDossierPurchaseReassignToRegistrationDeliveryService, auth.token, dossierState.dossierId, orderId, dossierSubTypeId, dossierState.lastAccessDate)
          yield put(closeReplacePurchaseModal())
        }
        yield put(putPurchaseOnRegistrationDelivery(purchaseId, parentIsHistorical))
        yield call(fetchDependantsRegistrationDeliveryDossier, { dossierId: dossierState.dossierId })
        yield call(refreshRegistrationDeliveryDossierVehicleFromDocumentModal)
        const dossierRelatedDossiers = yield call(getDossierRelated, dossierState.dossierId, '+number', auth.token)
        const checkListHeader = yield call(getStageCheckList, dossierState.dossierId, auth.token)
        yield put(fetchRegistrationDeliveryDossierSuccess({ dossierRelatedDossiers, checkListHeader }))
        yield put(setActiveSection(buttonsSidebar.vehicle))
      }
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          yield call(fetchSalesDossier, { dossierId: dossierState.dossierId })
          let updatedSalesDossier
          if (purchaseType === purchaseModalTypes.campaignDossier) {
            updatedSalesDossier = yield select(state => state.campaignDossier)
          } else if (purchaseType === purchaseModalTypes.saleDossier) {
            updatedSalesDossier = yield select(state => state.salesDossier)
            yield put(initialize('salesDossier', updatedSalesDossier))
            yield put(fetchSalesDossierDependants(dossierState.dossierId))
          } else if (purchaseType === purchaseModalTypes.registrationDeliveryDossier) {
            updatedSalesDossier = yield select(state => state.registrationDeliveryDossier)
            yield put(initialize('registrationDeliveryDossier', updatedSalesDossier))
            yield put(fetchDependantsRegistrationDeliveryDossier(dossierState.dossierId))
          }
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('registrationDeliveryDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSelectReplacePurchase () {
  yield takeEvery(reassignReplacePurchaseModalActionsTypes.SELECT_REPLACE_PURCHASE, selectReplacePurchase)
}

export function * createOrderDossierPurchase ({ purchaseType, newOrder }) {
  const salesDossierState = yield select(state => state.salesDossier)
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    if (purchaseType === purchaseModalTypes.saleDossier) {
      const order = {
        saleId: salesDossierState.dossierId,
        dossierSubTypeId: salesDossierState.dossierSubTypeId,
        ...newOrder
      }
      const purchaseId = yield call(putDossierOrderPurchase, auth.token, order, salesDossierState.lastAccessDate)
      yield put(closeReplacePurchaseModal())
      yield put(putPurchaseOnSale(purchaseId, false))
      yield call(fetchDependantsSaleDossier, { dossierId: salesDossierState.dossierId })
      yield call(refreshSalesDossierVehicleFromDocumentModal)
      const dossierRelatedDossiers = yield call(getDossierRelated, salesDossierState.dossierId, '+number', auth.token)
      yield put(fetchSalesDossierSuccess({ dossierRelatedDossiers }))
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          yield call(fetchSalesDossier, { dossierId: salesDossierState.dossierId })
          const updatedSalesDossier = yield select(state => state.salesDossier)
          yield put(initialize('salesDossier', updatedSalesDossier))
          yield put(fetchSalesDossierDependants(salesDossierState.dossierId))
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchCreateOrderDossierPurchase () {
  yield takeEvery(reassignReplacePurchaseModalActionsTypes.CREATE_ORDER_DOSSIER_PURCHASE, createOrderDossierPurchase)
}

export function * fetchReassignPurchaseCatalogueModel ({ catalogueId, modelId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const catalogueModel = yield call(getCatalogueModel, auth.token, catalogueId, modelId)
    yield put(change('reassingPurchaseModal', 'model', catalogueModel?.description || ''))
    yield put(change('reassingPurchaseModal', 'catalogueModelId', catalogueModel?.catalogueModelId))
    if (!catalogueModel?.description) {
      throw new Error()
    }
  } catch (error) {
    const customHandler = function * () {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.VEHICLE_COMPONENT.CATALOGUE_MODEL_NOT_FOUND'))
    }
    yield call(handleError, { error, customHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchReassignPurchaseCatalogueModel () {
  yield takeEvery(reassignReplacePurchaseModalActionsTypes.FETCH_REASSING_PURCHASE_VEHICLE_CATALOGUE_MODEL, fetchReassignPurchaseCatalogueModel)
}
