import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { closeReservationContractModal } from '../../../actions/dossier/common/reservationContractModal'
import ReservationContractModal from '../../../components/dossiers/common/documentaryManagement/templateDataModal/ReservationContractModal'

export function mapStateToProps (state) {
  return {
    ...state.reservationContractModal,
    combos: {
      insuranceCompanyCombo: state.combos.insuranceCompanyCombo
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      closeReservationContractModal
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(ReservationContractModal))
