import React, { PureComponent } from 'react'
import LoaderPage from '../containers/commons/LoaderPage.js'
import CustomModalContainer from '../containers/commons/CustomModalContainer'

class App extends PureComponent {
  render () {
    return (
      <div className='App'>
        {this.props.children}
        <LoaderPage />
        <CustomModalContainer />
      </div>
    )
  }
}

export default App
