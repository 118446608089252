import { call, takeEvery, select, put } from 'redux-saga/effects'
import { reset } from 'redux-form'
import dossierActionsTypes from '../../../constants/actions/dossier/dossier'
import { dossierTypes as dossierTypesConstant } from '../../../constants/dossier/common/dossierTypes'
import messageModalActions from '../../../actions/modals/messageModal'
import { getAuth } from '../../../selectors/access/auth'
import getPrintDocumentaryChecklist from '../../../services/document/getPrintDocumentaryChecklist'
import getPrintWarranty from '../../../services/document/getPrintWarranty'
import { handleError } from '../../errors/errorManager'
import { yesNoModal } from '../../modalsListeners/yesNoModal'
import { initializeDossier } from '../commonDossierSagas'
import getPrintSummarySale from '../../../services/dossierSale/getPrintSummarySale'
import { showLoader, hideLoader } from '../../../actions/common'
import getPrinTemplateHtmlByCode from '../../../services/dossierSale/getPrinTemplateHtmlByCode'
import { templateHtmlCodes } from '../../../constants/templates/templatesHtml'
import { dossierSubTypeId as dossierSubTypeIdConstants } from '../../../constants/dossier/common/dossierSubType'

export function * printDocumentaryChecklist () {
  try {
    const auth = yield select(getAuth)
    const salesDossier = yield select(state => state.salesDossier)
    yield call(getPrintDocumentaryChecklist, auth.token, salesDossier.dossierId, salesDossier.lastAccessDate)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          const salesDossierState = yield select(state => state.salesDossier)
          yield call(initializeDossier, dossierTypesConstant.sales, salesDossierState)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  }
}

export function * watchPrintDocumentaryChecklist () {
  yield takeEvery(dossierActionsTypes.PRINT_DOCUMENTARY_CHECKLIST, printDocumentaryChecklist)
}

export function * printDocumentaryWarranty () {
  try {
    const auth = yield select(getAuth)
    const salesDossier = yield select(state => state.salesDossier)
    yield call(getPrintWarranty, auth.token, salesDossier.dossierId, salesDossier.lastAccessDate)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          const salesDossierState = yield select(state => state.salesDossier)
          yield call(initializeDossier, dossierTypesConstant.sales, salesDossierState)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  }
}

export function * watchPrintDocumentaryWarranty () {
  yield takeEvery(dossierActionsTypes.PRINT_DOCUMENTARY_WARRANTY, printDocumentaryWarranty)
}

export function * printSummarySale ({ dossierId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(getPrintSummarySale, auth.token, dossierId)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchprintSummarySale () {
  yield takeEvery(dossierActionsTypes.PRINT_SUMMARY_SALE, printSummarySale)
}

export function * printCompeteSummarySale ({ dossierId, isHistorical }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const salesDossier = yield select(state => state.salesDossier)
    let template = templateHtmlCodes.COMPLETE_SUMMARY
    switch (salesDossier.dossierSubTypeId) {
      case dossierSubTypeIdConstants.venta_vo:
        template = templateHtmlCodes.COMPLETE_SUMMARY_VO
        break
    }
    yield call(getPrinTemplateHtmlByCode, auth.token, dossierId, isHistorical, template)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchPrintCompeteSummarySale () {
  yield takeEvery(dossierActionsTypes.PRINT_COMPLETE_SUMMARY_SALE, printCompeteSummarySale)
}
