import mastersActionTypes from '../../../../constants/actions/masters/masters'

export function fetchProcessingAgencyServiceDestinationsList (processingAgencyId, filter) {
  return {
    type: mastersActionTypes.FETCH_PROCESSING_AGENCY_SERVICE_DESTINATIONS_LIST,
    filter,
    processingAgencyId
  }
}

export function fetchProcessingAgencyServiceDestinationsListSuccess (processingAgencyServiceDestinationsList, filter) {
  return {
    type: mastersActionTypes.FETCH_PROCESSING_AGENCY_SERVICE_DESTINATIONS_LIST_SUCCESS,
    processingAgencyServiceDestinationsList,
    filter
  }
}

export function fetchProcessingAgencyServiceDestinationsCombo () {
  return {
    type: mastersActionTypes.FETCH_PROCESSING_AGENCY_SERVICE_DESTINATIONS_COMBO
  }
}

export function showProcessingAgencyServiceDestinationsModal (processingAgencyId, show, processingAgencyServiceDestination) {
  return {
    type: mastersActionTypes.SHOW_PROCESSING_AGENCY_SERVICE_DESTINATIONS_MODAL,
    show,
    processingAgencyServiceDestination,
    processingAgencyId
  }
}

export function showProcessingAgencyServiceDestinationsModalSuccess (processingAgencyId, show, processingAgencyServiceDestination) {
  return {
    type: mastersActionTypes.SHOW_PROCESSING_AGENCY_SERVICE_DESTINATIONS_MODAL_SUCCESS,
    show,
    processingAgencyServiceDestination,
    processingAgencyId
  }
}

export function submitProcessingAgencyServiceDestinations (processingAgencyId, processingAgencyServiceDestination) {
  return {
    type: mastersActionTypes.SUBMIT_PROCESSING_AGENCY_SERVICE_DESTINATIONS,
    processingAgencyServiceDestination,
    processingAgencyId
  }
}

export function deleteProcessingAgencyServiceDestinations (processingAgencyId, processingAgencyServiceDestinationsId) {
  return {
    type: mastersActionTypes.DELETE_PROCESSING_AGENCY_SERVICE_DESTINATIONS,
    processingAgencyServiceDestinationsId,
    processingAgencyId
  }
}
