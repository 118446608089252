import { call, put, takeEvery, select } from 'redux-saga/effects'
import messageModalActions from '../../../../actions/modals/messageModal'
import { showLoader, hideLoader } from '../../../../actions/common'
import { handleError } from '../../../errors/errorManager'
import clientActionTypes from '../../../../constants/actions/dossier/common/client'
import { dossierSubTypeId as dossierSubTypeIdConstants } from '../../../../constants/dossier/common/dossierSubType'
import deleteEntityFromDossier from '../../../../services/entity/deleteEntityFromDossier'
import deleteEntityChildFromDossier from '../../../../services/entity/deleteEntityChildFromDossier'
import { getAuth } from '../../../../selectors/access/auth'
import { yesNoModal } from '../../../modalsListeners/yesNoModal'
import { initSaleDossier, initPurchaseDossier, initPaperworkDossier, initCampaignDossier } from '../../commonDossierSagas'
import { fetchRegistrationDeliveryDossier, fetchRegistrationDeliveryClient } from '../../registrationDelivery/fetchRegistrationDeliveryDossier'
import { restartPaperworkState } from '../../../../actions/dossier/paperwork'
import { restartPurchaseState } from '../../../../actions/dossier/purchase'
import { restartSalesState } from '../../../../actions/dossier/sales'
import { restartCampaignState } from '../../../../actions/dossier/campaign'
import { restartRegistrationDeliveryState } from '../../../../actions/dossier/registrationDelivery'
import { fetchEntityFleetSucces, fetchEntityChildFleetSucces } from '../../../../actions/management/management'
import { fetchEntityOperateFleetSucces, fetchEntityOperateChildFleetSucces } from '../../../../actions/management/auxModals/auxModals'
import { change } from 'redux-form'

export function * clearEntity ({ dossierId, dossierSubTypeId, isFromParent }) {
  try {
    const auth = yield select(getAuth)
    if (dossierId === -1 && dossierSubTypeIdConstants.venta_vn === dossierSubTypeId) {
      let data = { entityComponent: { entityTypeId: null, dniCifValidationDisable: false, representativeDniCifValidationDisable: false } }
      yield put(fetchEntityFleetSucces({ entityComponent: data }))
      yield put(fetchEntityOperateFleetSucces({ entityComponent: data }))
      yield put(change('addNewFleetModal', 'entityComponent', data))
      yield put(change('createNewFleetModal', 'entityComponent', data))
      yield put(change('dataContactFleetModal', 'entityComponent', data))

      let dataChild = { entityChildComponent: null }
      yield put(fetchEntityChildFleetSucces({ entityChildComponent: dataChild }))
      yield put(fetchEntityOperateChildFleetSucces({ entityChildComponent: dataChild }))
      yield put(change('addNewFleetModal', 'entityChildComponent', dataChild))
      yield put(change('createNewFleetModal', 'entityChildComponent', dataChild))
      yield put(change('dataContactFleetModal', 'entityChildComponent', dataChild))
    } else {
      const confirmedClearEntityWarning = yield call(yesNoModal, 'commonEntityClearWarning')

      if (confirmedClearEntityWarning) {
        yield put(showLoader())
        yield call(deleteEntityFromDossier, dossierId, auth.token, isFromParent)
        if (dossierSubTypeIdConstants.venta_vn === dossierSubTypeId || dossierSubTypeIdConstants.venta_vo === dossierSubTypeId) {
          yield put(restartSalesState())
          yield initSaleDossier(dossierId)
        } else if (dossierSubTypeIdConstants.compra_vn === dossierSubTypeId || dossierSubTypeIdConstants.compra_vo === dossierSubTypeId) {
          yield put(restartPurchaseState())
          yield initPurchaseDossier(dossierId)
        } else if (dossierSubTypeIdConstants.tramite === dossierSubTypeId) {
          yield put(restartPaperworkState())
          yield initPaperworkDossier(dossierId)
        } else if (dossierSubTypeIdConstants.campanya === dossierSubTypeId || dossierSubTypeIdConstants.campanya_ayuda_especial === dossierSubTypeId) {
          yield put(restartCampaignState())
          yield initCampaignDossier(dossierId)
        } else if (dossierSubTypeIdConstants.registration_delivery === dossierSubTypeId || dossierSubTypeIdConstants.model_agency === dossierSubTypeId) {
          yield put(restartRegistrationDeliveryState())
          yield fetchRegistrationDeliveryDossier({ dossierId })
          yield fetchRegistrationDeliveryClient({ dossierId })
        }
      }
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.ENTITY_COMPONENT.UNKNOWN_ERROR', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    yield call(handleError, { error, customHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchClearEntity () {
  yield takeEvery(clientActionTypes.CLEAR_ENTITY, clearEntity)
}

export function * clearEntityChild ({ dossierId, dossierSubTypeId }) {
  try {
    const auth = yield select(getAuth)

    if (dossierId === -1) {
      let data = { entityChildComponent: { entityTypeId: null, dniCifValidationDisable: false, representativeDniCifValidationDisable: false } }
      yield put(fetchEntityChildFleetSucces({ entityChildComponent: data }))
      yield put(change('addNewFleetModal', 'entityChildComponent', data))
      yield put(change('createNewFleetModal', 'entityChildComponent', data))
      yield put(change('dataContactFleetModal', 'entityChildComponent', data))
      yield put(change('salesDossier', 'entityChildComponent', data))
      yield put(change('purchase_dossier', 'entityChildComponent', data))
      yield put(change('paperwork_dossier', 'entityChildComponent', data))
      yield put(change('campaign_dossier', 'entityChildComponent', data))
      yield put(change('registrationDeliveryDossier', 'entityChildComponent', data))
      yield put(change('service_dossier', 'entityChildComponent', data))
      yield put(change('stock_dossier', 'entityChildComponent', data))
    } else {
      const confirmedClearEntityWarning = yield call(yesNoModal, 'commonEntityChildClearWarning')

      if (confirmedClearEntityWarning) {
        yield put(showLoader())
        yield call(deleteEntityChildFromDossier, dossierId, auth.token)

        if (dossierSubTypeIdConstants.venta_vn === dossierSubTypeId ||
                dossierSubTypeIdConstants.venta_vo === dossierSubTypeId) {
          yield put(restartSalesState())
          yield initSaleDossier(dossierId)
        } else if (dossierSubTypeIdConstants.compra_vn === dossierSubTypeId ||
                dossierSubTypeIdConstants.compra_vo === dossierSubTypeId) {
          yield put(restartPurchaseState())
          yield initPurchaseDossier(dossierId)
        } else if (dossierSubTypeIdConstants.tramite === dossierSubTypeId) {
          yield put(restartPaperworkState())
          yield initPaperworkDossier(dossierId)
        } else if (dossierSubTypeIdConstants.campanya === dossierSubTypeId ||
                dossierSubTypeIdConstants.campanya_ayuda_especial === dossierSubTypeId) {
          yield put(restartCampaignState())
          yield initCampaignDossier(dossierId)
        } else if (dossierSubTypeIdConstants.registration_delivery === dossierSubTypeId || dossierSubTypeIdConstants.model_agency === dossierSubTypeId) {
          yield put(restartRegistrationDeliveryState())
          yield fetchRegistrationDeliveryDossier({ dossierId })
          yield fetchRegistrationDeliveryClient({ dossierId })
        }
      }
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.ENTITY_COMPONENT.UNKNOWN_ERROR', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    yield call(handleError, { error, customHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchClearEntityChild () {
  yield takeEvery(clientActionTypes.CLEAR_ENTITY_CHILD, clearEntityChild)
}
