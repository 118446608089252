
import React, { PureComponent } from 'react'
import { reduxForm, Field } from 'redux-form'
import InputText from '../../commons/form/InputText'
import { connect } from 'react-redux'
import { Modal, Col, Panel } from 'react-bootstrap'

class AuditObservationModal extends PureComponent {
  constructor () {
    super()
    this.state = {
      showModal2: false,
      form: {}
    }
  }

  componentDidMount () {
    this.inicialize()
  }

  componentWillUnmount () { }

  onDossierTypeChange (dossierTypesId) {
    this.props.actions.fetchDossierSubTypes(null, dossierTypesId)
  }

  componentDidUpdate (prevProps) {
    if (this.props.formValues !== prevProps.formValues) {
      this.props.handleFormValuesObservationsChange(this.props.formValues)
    }
  }

  onSubmit (formValues) {
    this.props.onFormSubmit(formValues)
  }

  inicialize () {
    this.props.initialize({
      AuditUserObservations: this.props.formData?.AuditUserObservations,
      AuditAdminObservations: this.props.formData?.AuditAdminObservations,
      audit: this.props.formData?.audit
    })
  }

  render () {
    const {
      t, isAdmin
    } = this.props
    const tKey = 'MANAGEMENT.ADMIN_AUDIT.MODAL.'
    return (
      <Modal.Body>
        <div className="dossier-panel" >
          <Panel expanded={true} onToggle={() => {}}>
            <Panel.Collapse>
              <div className='div-padding-bottom'>
                <Col sm={12}>
                  <Field
                    id="AuditUserObservations"
                    name="AuditUserObservations"
                    colSm={12}
                    componentClass="textarea"
                    controlLabel={t(`${tKey}OBSERVATIONS`)}
                    placeholder={t(`${tKey}OBSERVATIONS`)}
                    component={InputText}
                    disabled={!isAdmin}
                    maxLength={250}
                  />
                </Col>
                <Col sm={12}>
                  <Field
                    id="AuditAdminObservations"
                    name="AuditAdminObservations"
                    colSm={12}
                    componentClass="textarea"
                    controlLabel={t(`${tKey}OBSERVATIONS_AUDIT`)}
                    placeholder={t(`${tKey}OBSERVATIONS_AUDIT`)}
                    component={InputText}
                    disabled={isAdmin}
                    maxLength={250}
                  />
                </Col>
              </div>
            </Panel.Collapse>
          </Panel>
        </div>
      </Modal.Body>
    )
  }
}

const mapStateToProps = state => {
  return {
    formValues: state.form && state.form.AuditObservationModal ? state.form.AuditObservationModal.values : null
  }
}

export default connect(mapStateToProps)(
  reduxForm({
    form: 'AuditObservationModal',
    shouldValidate: () => true
  })(AuditObservationModal)
)
