import receiptActionTypes from '../../constants/actions/reports/receiptReport'

function initialState () {
  return {
    receiptList: [],
    pagesCount: 0,
    receiptCount: 0,
    filter: {
      page: 1,
      orderBy: '',
      isDeleted: null,
      pageSize: 10
    }
  }
}

function fetchReceiptReportSuccess (state, { receiptList, filter }) {
  return {
    ...state,
    receiptList: receiptList.receiptList,
    receiptCount: receiptList.receiptCount,
    pagesCount: receiptList.pagesCount,
    filter
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case receiptActionTypes.FETCH_RECEIPT_REPORT_SUCCESS:
      return fetchReceiptReportSuccess(state, action)
    default:
      return state
  }
}
