
import React, { PureComponent } from 'react'
import { Modal, Col, Row, FormGroup, Button } from 'react-bootstrap'
import { reduxForm, Field } from 'redux-form'
import InputText from '../../commons/form/InputText'
import InputSelect from '../../commons/form/InputSelect'
import TextInput from '../../commons/TextInput'

class CampaignTypeMasterModal extends PureComponent {
  constructor () {
    super()
    this.state = {}
  }

  submitCampaign (values) {
    let body = []
    Object.keys(values).map(item => {
      if (typeof values[item] === 'object') {
        body.push(values[item])
      }
    })
    body = {
      campaignTypeLanguage: body,
      campaignGroupId: values.campaignGroupId,
      typeCode: values.typeCode,
      campaignTypeId: this.props.campaignId
    }
    this.props.actions.submitCampaignType(body)
  }

  render () {
    const {
      t, handleSubmit, nonEditableType, nonEditable, languageList,
      showModal,
      combos: { campaignGroup }
    } = this.props
    const tKey = 'MASTERS.CAMPAIGN_TYPE_MASTERS.MODAL.'
    return (
      <Modal className="role-modal languages-modal" show={showModal} onHide={this.props.actions.closeCampaignTypeMasterModal}>
        <form autoComplete="off" onSubmit={handleSubmit(this.submitCampaign.bind(this))}>
          <Modal.Header closeButton onHide={this.props.actions.closeCampaignTypeMasterModal}>
            <Modal.Title>{t(`${tKey}TITLE`)}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="inputs-wrapper">
              <Col sm={4} className="custom-width-col">
                <h2>{t(`${tKey}GROUP`)}</h2>
                <Field
                  name="campaignGroupId"
                  placeholder={t(`${tKey}GROUP`)}
                  valueKey="id"
                  labelKey="value"
                  component={InputSelect}
                  colSm={0}
                  options={campaignGroup}
                  disabled={nonEditableType}
                />
              </Col>
              <Col sm={6} className="custom-width-col">
                <h2>{t(`${tKey}CODE`)}</h2>
                <Field
                  name="typeCode"
                  placeholder={t(`${tKey}CODE`)}
                  component={InputText}
                  disabled={nonEditable}
                />
              </Col>
            </Row>
            <div className="clearfix" />
            <FormGroup>
              {languageList && languageList.map((val, idx) => {
                return (
                  <Row key={idx} className="inputs-wrapper">
                    <Col sm={10} className="custom-width-col">
                      <Field
                        name={`lang${val.languageId}.description`}
                        placeholder={t('MASTERS.DESCRIPTION')}
                        component={TextInput}
                      />
                    </Col>
                    <Col sm={2} className="language-column">
                      <p>{`${val.description} (${val.name})`}</p>
                    </Col>
                  </Row>
                )
              })}
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <Col sm={12} className="btn-wrapper">
              <Button
                id="role-modal-submit-button"
                className="btn-standard"
                type="submit"
                disabled={this.props.pristine}>
                {t(`${tKey}SUBMIT_BUTTON`)}
              </Button>
            </Col>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default reduxForm({
  form: 'editCampaignType',
  validate: (values, props) => {
    let error = {}
    if (values) {
      const tKey = 'MASTERS.CAMPAIGN_TYPE_MASTERS.VALIDATION.'
      if (!values.campaignGroupId) {
        error.campaignGroupId = props.t(`${tKey}GROUP`)
      }
      if (!values.typeCode) {
        error.typeCode = props.t(`${tKey}CODE`)
      }
      props.languageList && props.languageList.forEach((element) => {
        let valueLangName = values['lang' + element.languageId].name
        let valueLangDesc = values['lang' + element.languageId].description
        let errorLang = {
          name: null,
          description: null
        }
        if (!valueLangName) {
          errorLang.name = props.t(`${tKey}NAME`)
        }
        if (!valueLangDesc) {
          errorLang.description = props.t(`${tKey}DESCRIPTION`)
        }
        error['lang' + element.languageId] = errorLang
      })
    }
    return Object.keys(error).length > 0 ? error : {}
  }
})(CampaignTypeMasterModal)
