import mastersActionTypes from '../../../constants/actions/masters/masters'

function initialState () {
  return {
    agencyList: [],
    agenciesCount: 0,
    pagesCount: 0,
    filter: {
      page: 1,
      orderBy: '',
      pageSize: 10
    },
    processingAgencyModal: {
      agency: null,
      showModal: false
    },
    processingAngencyUsersModal: {
      agency: null,
      showModal: false,
      agencyFilteredUsers: [],
      filters: {
        orderBy: '+completName',
        page: 1,
        pages: 1,
        maxRows: 10,
        count: 0
      }
    },
    processingAngencyUsersAddModal: {
      agency: null,
      showModal: false,
      userList: [],
      usersCount: 0,
      pagination: {
        page: 1,
        pageSize: 10,
        pagesCount: 0
      },
      filter: {
        orderBy: '+completName'
      }
    },
    processingAgencyIntegrationModal: {
      agency: null,
      showModal: false,
      registrationTypes: {
        processingAgencyRegistrationTypes: [],
        processingAgencyRegistrationTypesCount: 0,
        pagesCount: 0,
        languageList: [],
        filter: {
          page: 1,
          orderBy: '+dossierOperationTypeLocale',
          pageSize: 10
        },
        processingAgencyRegistrationTypeModal: {
          showModal: false
        }
      },
      serviceDestinations: {
        processingAgencyServiceDestinations: [],
        processingAgencyServiceDestinationsCount: 0,
        pagesCount: 0,
        languageList: [],
        filter: {
          page: 1,
          orderBy: '+serviceDestinationLocale',
          pageSize: 10
        },
        processingAgencyServiceDestinationModal: {
          showModal: false
        }
      },
      docTypeUses: {
        processingAgencyDocTypeUses: [],
        processingAgencyDocTypeUsesCount: 0,
        pagesCount: 0,
        languageList: [],
        filter: {
          page: 1,
          orderBy: 'operationTypeId',
          pageSize: 10
        },
        processingAgencyDocTypeUseModal: {
          showModal: false
        }
      }
    }
  }
}

function fetchProcessingAgencyListSuccess (state, { agencyList, filter }) {
  return {
    ...state,
    agencyList: agencyList.processingAgencyList,
    agenciesCount: agencyList.agenciesCount,
    pagesCount: agencyList.pagesCount,
    filter
  }
}

function openEditModalSuccess (state, { agency }) {
  const initial = initialState()
  return {
    ...state,
    processingAgencyModal: {
      ...initial.processingAgencyModal,
      agency,
      showModal: true
    }
  }
}

function openEditIntegrationModalSuccess (state, { agency, show }) {
  const initial = initialState()
  return {
    ...state,
    processingAgencyIntegrationModal: {
      ...initial.processingAgencyIntegrationModal,
      agency,
      showModal: show
    }
  }
}

function openAddModalSuccess (state) {
  const initial = initialState()
  return {
    ...state,
    processingAgencyModal: {
      ...initial.processingAgencyModal,
      showModal: true
    }
  }
}

function closeEditModalSuccess (state) {
  return {
    ...state,
    processingAgencyModal: {
      ...initialState().processingAgencyModal
    }
  }
}

function openUserProcessingAgencyModal (state, { agency }) {
  return {
    ...state,
    processingAngencyUsersModal: {
      ...state.processingAngencyUsersModal,
      agency: agency,
      showModal: true
    }
  }
}

function closeUserProcessingAgencyModal (state) {
  return {
    ...state,
    processingAngencyUsersModal: {
      ...initialState().processingAngencyUsersModal
    }
  }
}

export function chargeUsersProcessingAgencyModal (state, { agencyFilteredUsers, filters }) {
  return {
    ...state,
    processingAngencyUsersModal: {
      ...state.processingAngencyUsersModal,
      agencyFilteredUsers: agencyFilteredUsers,
      filters: filters
    }
  }
}

export function getUsersProcessingAgencySuccess (state, { data }) {
  return {
    ...state,
    processingAngencyUsersModal: {
      ...state.processingAngencyUsersModal,
      agencyUsers: data
    }
  }
}

export function openUserProcessingAgencyAddModal (state, { agency }) {
  return {
    ...state,
    processingAngencyUsersAddModal: {
      ...state.processingAngencyUsersAddModal,
      agency: agency,
      showModal: true
    }
  }
}

function fetchProcessingAgencyRegistrationTypeListSuccess (state, { processingAgencyRegistrationTypesList, filter }) {
  return {
    ...state,
    processingAgencyIntegrationModal: {
      ...state.processingAgencyIntegrationModal,
      registrationTypes: {
        processingAgencyRegistrationTypes: processingAgencyRegistrationTypesList.processingAgencyRegistrationTypes,
        processingAgencyRegistrationTypesCount: processingAgencyRegistrationTypesList.processingAgencyRegistrationTypesCount,
        pagesCount: processingAgencyRegistrationTypesList.pagesCount,
        languageSelected: processingAgencyRegistrationTypesList.languageSelected,
        filter: filter
      }
    }
  }
}

function showProcessingAgencyRegistrationTypeModalSuccess (state, { show, processingAgencyRegistrationType, processingAgencyId }) {
  const initial = initialState().processingAgencyIntegrationModal.registrationTypes.registrationTypesModal
  return {
    ...state,
    processingAgencyIntegrationModal: {
      ...state.processingAgencyIntegrationModal,
      registrationTypes: {
        ...state.processingAgencyIntegrationModal.registrationTypes,
        registrationTypesModal: {
          ...initial,
          processingAgencyRegistrationType: processingAgencyRegistrationType,
          showModal: show,
          processingAgencyId: processingAgencyId
        }
      }
    }
  }
}

function fetchProcessingAgencyServiceDestinationListSuccess (state, { processingAgencyServiceDestinationsList, filter }) {
  return {
    ...state,
    processingAgencyIntegrationModal: {
      ...state.processingAgencyIntegrationModal,
      serviceDestinations: {
        processingAgencyServiceDestinations: processingAgencyServiceDestinationsList.processingAgencyServiceDestinations,
        processingAgencyServiceDestinationsCount: processingAgencyServiceDestinationsList.processingAgencyServiceDestinationsCount,
        pagesCount: processingAgencyServiceDestinationsList.pagesCount,
        languageSelected: processingAgencyServiceDestinationsList.languageSelected,
        filter: filter
      }
    }
  }
}

function showProcessingAgencyServiceDestinationModalSuccess (state, { show, processingAgencyServiceDestination, processingAgencyId }) {
  const initial = initialState().processingAgencyIntegrationModal.serviceDestinations.serviceDestinationsModal
  return {
    ...state,
    processingAgencyIntegrationModal: {
      ...state.processingAgencyIntegrationModal,
      serviceDestinations: {
        ...state.processingAgencyIntegrationModal.serviceDestinations,
        serviceDestinationsModal: {
          ...initial,
          processingAgencyServiceDestination: processingAgencyServiceDestination,
          showModal: show,
          processingAgencyId: processingAgencyId
        }
      }
    }
  }
}

function fetchProcessingAgencyDocTypeUseListSuccess (state, { processingAgencyDocTypeUsesList, filter }) {
  return {
    ...state,
    processingAgencyIntegrationModal: {
      ...state.processingAgencyIntegrationModal,
      docTypeUses: {
        processingAgencyDocTypeUses: processingAgencyDocTypeUsesList.processingAgencyDocTypeUses,
        processingAgencyDocTypeUsesCount: processingAgencyDocTypeUsesList.processingAgencyDocTypeUsesCount,
        pagesCount: processingAgencyDocTypeUsesList.pagesCount,
        languageSelected: processingAgencyDocTypeUsesList.languageSelected,
        filter: filter
      }
    }
  }
}

function showProcessingAgencyDocTypeUseModalSuccess (state, { show, processingAgencyDocTypeUse, processingAgencyId, isReturnDocument }) {
  const initial = initialState().processingAgencyIntegrationModal.docTypeUses.docTypeUsesModal
  return {
    ...state,
    processingAgencyIntegrationModal: {
      ...state.processingAgencyIntegrationModal,
      docTypeUses: {
        ...state.processingAgencyIntegrationModal.docTypeUses,
        docTypeUsesModal: {
          ...initial,
          processingAgencyDocTypeUse: processingAgencyDocTypeUse,
          showModal: show,
          processingAgencyId: processingAgencyId,
          isReturnDocument: isReturnDocument
        }
      }
    }
  }
}

export function closeUserProcessingAgencyAddModal (state) {
  return {
    ...state,
    processingAngencyUsersAddModal: {
      ...initialState().processingAngencyUsersAddModal
    }
  }
}

export function fetchUserListForProcessingAgencySuccess (state, { userList, filter }) {
  return {
    ...state,
    processingAngencyUsersAddModal: {
      ...state.processingAngencyUsersAddModal,
      userList: userList.userList,
      filter: filter,
      pagination: {
        page: filter.page,
        pageSize: filter.pageSize,
        pagesCount: userList.pagesCount
      },
      usersCount: userList.usersCount
    }
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case mastersActionTypes.FETCH_PROCESSING_AGENCY_SUCCESS:
      return fetchProcessingAgencyListSuccess(state, action)
    case mastersActionTypes.OPEN_EDIT_PROCESSING_AGENCY_MODAL_SUCCESS:
      return openEditModalSuccess(state, action)
    case mastersActionTypes.OPEN_EDIT_PROCESSING_AGENCY_INTEGRATION_MODAL_SUCCESS:
      return openEditIntegrationModalSuccess(state, action)
    case mastersActionTypes.OPEN_ADD_PROCESSING_AGENCY_MODAL_SUCCESS:
      return openAddModalSuccess(state, action)
    case mastersActionTypes.CLOSE_EDIT_PROCESSING_AGENCY_MODAL:
      return closeEditModalSuccess(state, action)
    case mastersActionTypes.OPEN_USER_PROCESSING_AGENCY_MODAL:
      return openUserProcessingAgencyModal(state, action)
    case mastersActionTypes.CLOSE_USER_PROCESSING_AGENCY_MODAL:
      return closeUserProcessingAgencyModal(state, action)
    case mastersActionTypes.CHARGE_USER_PROCESSING_AGENCY_MODAL:
      return chargeUsersProcessingAgencyModal(state, action)
    case mastersActionTypes.GET_USERS_PROCESSING_AGENCY_SUCCES:
      return getUsersProcessingAgencySuccess(state, action)
    case mastersActionTypes.OPEN_USER_PROCESSING_AGENCY_ADD_MODAL:
      return openUserProcessingAgencyAddModal(state, action)
    case mastersActionTypes.CLOSE_USER_PROCESSING_AGENCY_ADD_MODAL:
      return closeUserProcessingAgencyAddModal(state, action)
    case mastersActionTypes.FETCH_USERS_LIST_FOR_PROCESSING_AGENCY_SUCCESS:
      return fetchUserListForProcessingAgencySuccess(state, action)
    case mastersActionTypes.FETCH_PROCESSING_AGENCY_REGISTRATION_TYPES_LIST_SUCCESS:
      return fetchProcessingAgencyRegistrationTypeListSuccess(state, action)
    case mastersActionTypes.SHOW_PROCESSING_AGENCY_REGISTRATION_TYPES_MODAL_SUCCESS:
      return showProcessingAgencyRegistrationTypeModalSuccess(state, action)
    case mastersActionTypes.FETCH_PROCESSING_AGENCY_SERVICE_DESTINATIONS_LIST_SUCCESS:
      return fetchProcessingAgencyServiceDestinationListSuccess(state, action)
    case mastersActionTypes.SHOW_PROCESSING_AGENCY_SERVICE_DESTINATIONS_MODAL_SUCCESS:
      return showProcessingAgencyServiceDestinationModalSuccess(state, action)
    case mastersActionTypes.FETCH_PROCESSING_AGENCY_DOC_TYPE_USES_LIST_SUCCESS:
      return fetchProcessingAgencyDocTypeUseListSuccess(state, action)
    case mastersActionTypes.SHOW_PROCESSING_AGENCY_DOC_TYPE_USES_MODAL_SUCCESS:
      return showProcessingAgencyDocTypeUseModalSuccess(state, action)
    default:
      return state
  }
}
