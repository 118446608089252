import React, { PureComponent } from 'react'
import 'rc-tree-select/assets/index.css'
import TreeSelect from 'rc-tree-select'
import { Col, ControlLabel } from 'react-bootstrap'
import setting from '../../../setting'

class SimpleTreeSelect extends PureComponent {
  constructor () {
    super()
    this.state = {
      tsOpen: false,
      options: undefined
    }

    this.formatTreeData = this.formatTreeData.bind(this)
  }

  formatTreeData (UOTreeCombo) {
    return UOTreeCombo.map((current) => {
      return {
        key: '' + current.value,
        value: '' + current.value,
        title: current.label,
        children: current.children ? this.formatTreeData(current.children) : []
      }
    })
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (this.props.options !== nextProps.options && nextProps.options !== undefined && nextProps.options.length > 0) {
      let newFormat = this.formatTreeData(nextProps.options)
      this.setState({ options: newFormat })
    }
  }

  componentDidMount () {
    if (this.state.options === undefined && this.props.options !== undefined && this.props.options.length > 0) {
      let newFormat = this.formatTreeData(this.props.options)
      this.setState({ options: newFormat })
    }
  }

  render () {
    const { colSm = 3, controlLabel, placeholder = '', searchPlaceholder, value, onInputChange, /* options, */ className } = this.props
    return (
      <Col sm={colSm}>
        {controlLabel && <ControlLabel>{controlLabel}</ControlLabel>}
        <div>
          <TreeSelect
            style={{ width: '100%' }}
            placeholder={placeholder}
            searchPlaceholder={searchPlaceholder}
            value={value}
            treeLine
            allowClear
            treeData={this.state.options}
            treeNodeFilterProp="label"
            // open={this.state.tsOpen}
            onChange={value => {
              onInputChange(value)
              this.setState({ tsOpen: false })
            }}
            className={className}
            showSearch={false}
            maxTagTextLength={setting.maxTagTextLength}
          />
        </div>
      </Col>
    )
  }
}

export default SimpleTreeSelect
