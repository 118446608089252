import apiFetch from '../apiFetch'

export default function (call) {
  const queryParams = Object.keys(call || {}).reduce((id, item) => {
    if (call[item] !== null && item !== 'token') {
      id += '&' + item + '=' + call[item]
    }
    return id
  }, '')
  return apiFetch({ endPoint: 'unit' + (queryParams ? '?' + queryParams.substring(1) : ''), method: 'GET', body: null, token: call.token })
}
