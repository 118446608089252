export const dossierTypes = {
  sales: 'sales',
  purchase: 'purchase',
  paperwork: 'paperwork',
  campaign: 'campaign',
  damageClaim: 'damageClaim',
  closureReport: 'closureReport',
  registrationDelivery: 'registrationDelivery',
  service: 'service',
  fleetFinder: 'fleetFinder',
  addNewFleetModal: 'addNewFleetModal',
  fleetEconomicPlan: 'fleetEconomicPlan',
  createNewFleetModal: 'createNewFleetModal',
  dataContactFleetModal: 'dataContactFleetModal',
  stock: 'stock',
  uacModal: 'uacModal'
}

export const dossierTypesId = {
  sales: 1,
  purchase: 2,
  paperwork: 4,
  campaign: 3,
  damageClaim: 5,
  closureReport: 6,
  registrationDelivery: 7,
  service: 8,
  stock: 9
}
