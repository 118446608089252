
import React, { PureComponent } from 'react'
import { reduxForm } from 'redux-form'
import { Form, Table, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Element } from 'react-scroll'
import { dossierTypes as dossierTypesConstant } from '../../../../constants/dossier/common/dossierTypes'
import { dossierSubTypeId as dossierSubTypeIdConstants } from '../../../../constants/dossier/common/dossierSubType'
import { compareObjects, getDocumentaryPermission } from '../../../../util/utils'
import { validateEntity, validateVehicle, validateDelivery, validateAlfanumeric, validateRegex, customValidations } from '../../../../util/validationFunctions'
import { getComponentsPermisions } from '../../../../util/permisionFunctions'
// import StatusHeaderPage from '../../../../containers/dossiers/common/StatusHeaderPage'
import DossierHeaderPage from '../../../containers/dossiers/commons/dossierHeaderPage'
import ButtonsHeader from '../../../../_v2/components/dossiers/common/ButtonsHeader'
import ButtonsSidebar from '../../../../_v2/components/dossiers/common/ButtonsSidebar'
import { buttonsHeader, buttonsHeaderPermisionsIdReverse } from '../../../../constants/dossier/common/buttonsHeader'
import { buttonsSidebar } from '../../../../constants/dossier/common/buttonsSidebar'
import { /* urgencyTypesClass, */ permissions } from '../../../../constants/backendIds'
import RejectCommentModal from '../../../../components/dossiers/common/RejectCommentModal'

import DocumentaryManagementModalPage from '../../../../containers/dossiers/common/DocumentaryManagementModalPage'

import SendDocumentsSignModal from '../../../../components/dossiers/common/documentaryManagement/SendDocumentsSignModal'
import RelatedDossierList from '../../../../components/commons/RelatedDossierList'

import TrackingContainer from '../../../../containers/tracking/trackingContainer'
import { TrackedPages } from '../../../../constants/tracking/TrackedPages'

import {
  asyncValidateEntityDni, getFieldsValidated
} from '../../../../util/validationFunctionEntityDniAsync'
import { validationPurchasePromisesOrder } from '../../../../constants/validatedFields/backValidationPromisesOrder'
import promisesStatus from '../../../../constants/promisesStatus'
/// /////////////////////////////////////////
import DossierSummary from '../common/DossierSummary'
import DossierTimeLineSectionPage from '../../../containers/dossiers/commons/DossierTimeLineSectionPage'
import PurchaseComponent from '../../../../_v2/components/dossiers/purchase/PurchaseComponent'
import Client from '../../../../_v2/components/dossiers/common/Client'
import Vehicle from '../../../../_v2/components/dossiers/common/Vehicle'
import PaperworksPage from '../../../../_v2/containers/dossiers/commons/PaperworkComponentPage'
import Services from '../../../../_v2/components/dossiers/common/Services'
import OtherData from '../../../../_v2/components/dossiers/common/OtherData'
import DocumentaryManagementPage from '../../../../_v2/containers/dossiers/commons/DocumentaryManagementPage'
import PurchaseEconomicPlan from '../../../../_v2/components/dossiers/purchase/PurchaseEconomicPlan'
import AuditInfo from '../../../../_v2/components/dossiers/common/audit/AuditInfo'
import CommentComponent from '../../../../_v2/components/dossiers/common/comment/CommentComponent'

class Purchase extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      modalOpen: true,
      purchaseOpen: true,
      isEconomicLoaded: false,
      isClientLoaded: false,
      isVehicleLoaded: false,
      isOtherDataLoaded: false,
      showRejectCommentModal: false,
      activeTabConfiguration: null,
      headerTabConfiguration: null,
      showSendDocumentsModal: false,
      componentsConfiguration: {
        bpurchase: null,
        entity: null,
        vehicle: null,
        paperwork: null,
        other_data: null,
        economic_plan: null,
        services: null
      },
      reason: null,
      comment: null,
      showRejectCommentModalTransfer: false,
      dossierBodyLeftClose: false,
      showTimelineInTab: false
    }
    this.goBack = this.goBack.bind(this)
    this.togglePanel = this.togglePanel.bind(this)
  }

  toggleShowTimelineInTab () {
    const showTimelineInTab = !this.state.showTimelineInTab

    if (this.props.activeTab === buttonsHeader.time_line) {
      let nextActiveTab = this.props.dossier.viewConfiguration.tabsConfiguration.filter((tabConf) => tabConf.permission > permissions.hidden)
      this.props.actions.setActiveTab(buttonsHeaderPermisionsIdReverse[nextActiveTab[0].code])
    }
    this.setState({ showTimelineInTab })
  }

  goBack () {
    this.props.actions.setActiveTab(buttonsHeader.datos_expediente)
    this.props.history.goBack()
  }

  togglePanel () {
    if (this.state.purchaseOpen) {
      this.props.actions.setActiveSection(false)
    }
    this.setState({ purchaseOpen: !this.state.purchaseOpen })
  }

  toggleRejectCommentModal (showRejectCommentModal) {
    this.setState({ showRejectCommentModal })
  }

  toggleRejectCommentTransfer (showRejectCommentModalTransfer) {
    this.setState({ showRejectCommentModalTransfer })
  }

  toggleSendDocumentsModal (showSendDocumentsModal) {
    this.setState({ showSendDocumentsModal })
  }

  onClickRelatedDossierColumn (columnCode) {
    let columnCodeOrder = '+' + columnCode
    if (columnCodeOrder === this.props.relatedDossiersOrderBy) {
      columnCodeOrder = '-' + columnCode
    }
    this.props.actions.fetchFilteredRelatedDossier(this.props.dossier.dossierId, columnCodeOrder)
  }

  getRelatedDossierColumnIcon (columnCode) {
    if ('+' + columnCode === this.props.relatedDossiersOrderBy) {
      return 'ico-sort-selected-asc'
    } else if ('-' + columnCode === this.props.relatedDossiersOrderBy) {
      return 'ico-sort-selected-desc'
    } else {
      return 'ico-sort'
    }
  }

  createPurchasePresent (newPresent, resolve, reject) {
    this.props.actions.createPurchasePresent(newPresent, this.props.formValues.dossierPurchaseEconomicPlanComponent, resolve, reject)
  }

  updatePurchasePresent (present, resolve, reject) {
    this.props.actions.updatePurchasePresent(present, this.props.formValues.dossierPurchaseEconomicPlanComponent, resolve, reject)
  }

  deletePurchasePresent (present, dossierType, isSoftDelete, rowIndex) {
    this.props.actions.deletePurchasePresent(present, this.props.formValues.dossierPurchaseEconomicPlanComponent, isSoftDelete, rowIndex)
  }

  recoverPurchasePresent (present, rowIndex) {
    this.props.actions.recoverPurchasePresent(present, this.props.formValues.dossierPurchaseEconomicPlanComponent, rowIndex)
  }

  submitPurchaseDossier (values) {
    this.props.actions.savePurchaseDossier(values)
  }

  fetchPurchaseDossierPromise (dossierId) {
    return new Promise((resolve, reject) => {
      this.props.actions.fetchRentingEntityCombo()
      return this.props.actions.fetchPurchaseDossier(dossierId, resolve, reject)
    })
  }

  routerWillLeave (nextLocation) {
    let pristine = true
    if (this.props.dossier.viewConfiguration.buttonsPermission && this.props.dossier.viewConfiguration.buttonsPermission.includes('purchase_save')) {
      pristine = this.props.pristine
    }
    this.props.actions.purchaseWarningSave(this.props.reset, this.props.history, nextLocation, pristine)
    return pristine
  }

  initializePermisions (props) {
    const componentKeys = Object.keys(this.state.componentsConfiguration)
    const newState = getComponentsPermisions(props.dossier.viewConfiguration.tabsConfiguration ? props.dossier.viewConfiguration.tabsConfiguration : [], props.activeTab, componentKeys)
    this.setState({ ...this.state, ...newState })
  }

  handleSubmitForm (resolve, reject) {
    let skipGeneralMsg = false
    if (this.props.formErrors) {
      if (this.props.formErrors.entityComponent && Object.keys(this.props.formErrors.entityComponent).length > 0) {
        this.props.actions.setScrollTo(buttonsSidebar.entity)
        this.props.actions.setActiveSection(buttonsSidebar.entity)
      } else if (this.props.formErrors.vehicleComponent && Object.keys(this.props.formErrors.vehicleComponent).length > 0) {
        this.props.actions.setScrollTo(buttonsSidebar.vehicle)
        this.props.actions.setActiveSection(buttonsSidebar.vehicle)
      } else if (this.props.formErrors.dossierCompleteDeliveryComponent && Object.keys(this.props.formErrors.dossierCompleteDeliveryComponent.dossierDeliveryComponent).length > 0) {
        this.props.actions.setScrollTo(buttonsSidebar.bpurchase)
        this.props.actions.setActiveSection(buttonsSidebar.bpurchase)
      } else if (this.props.formErrors.entityComponent && Object.keys(this.props.formErrors.entityComponent).length > 0 ||
        this.props.formErrors.entityChildComponent && Object.keys(this.props.formErrors.entityChildComponent).length > 0 ||
        this.props.formErrors.dossierContactComponent && Object.keys(this.props.formErrors.dossierContactComponent).length > 0) {
        this.props.actions.setScrollTo(buttonsSidebar.entity)
        this.props.actions.setActiveSection(buttonsSidebar.entity)
        if ((this.props.formErrors.entityComponent && Object.keys(this.props.formErrors.entityComponent).length > 0)) {
          this.props.actions.openErrorMessageModal('DOSSIER_COMPONENTS.VALIDATIONS.CLIENT_DATA_MANDATORY')
        } else {
          this.props.actions.openErrorMessageModal('DOSSIER_COMPONENTS.VALIDATIONS.FINAL_CLIENT_OR_CONTACT_MANDATORY')
        }
        skipGeneralMsg = true
      } else if (this.props.formErrors.dossierCompleteOtherDataComponent &&
        Object.keys(this.props.formErrors.dossierCompleteOtherDataComponent).length > 0) {
        this.props.actions.setScrollTo(buttonsSidebar.other_data)
        this.props.actions.setActiveSection(buttonsSidebar.other_data)
        this.props.actions.openErrorMessageModal('DOSSIER_COMPONENTS.VALIDATIONS.OTHER_DATA_MANDATORY')
        skipGeneralMsg = true
      }
      if (this.props.isInvalid && !skipGeneralMsg) {
        this.props.actions.openErrorMessageModal('DOSSIER_COMPONENTS.VALIDATIONS.GENERAL_MANDATORY')
      }
    }
    this.props.handleSubmit((values) => {
      if (!skipGeneralMsg) { this.submitPurchaseDossier(values) }
    })()
  }

  componentDidUpdate (prevProps) {
    if (this.props.dossier.dossierId && this.props.statusHeaderHeight && this.props.activeSection) {
      switch (this.props.activeSection) {
        case buttonsSidebar.bpurchase:
          this.setState({ purchaseOpen: true })
          break
      }
    }

    const firstCustomFieldsLoad = prevProps.dossier.customFieldsByComponent?.length < this.props.dossier.customFieldsByComponent?.length
    if (firstCustomFieldsLoad) {
      let customFields = {}
      if (this.props.dossier.customFieldsValues) {
        this.props.dossier.customFieldsValues.map(x => {
          customFields[x.key] = x.value
        })
      }
      const form = {
        ...this.props.dossier,
        customFields: customFields
      }
      this.props.initialize(form)
    }
  }

  handleToUpdateComponent (component) {
    if (component.isVehicleLoaded === false || buttonsSidebar.vehicle === component) this.setState({ isVehicleLoaded: true })
    if (component.isClientLoaded === false || buttonsSidebar.entity === component) this.setState({ isClientLoaded: true })
    if (component.isOtherDataLoaded === false || buttonsSidebar.other_data === component) this.setState({ isOtherDataLoaded: true })
    if (component.isEconomicLoaded === false) this.setState({ isEconomicLoaded: true })
    this.props.actions.setScrollTo(component)
  }

  updateComponentAfterDeleteAndRestart () {
    this.setState({
      isClientLoaded: false,
      isVehicleLoaded: false,
      isDeliveryLoaded: false,
      isOtherDataLoaded: false,
      isEconomicLoaded: false
    })
    this.props.actions.setActiveSection(buttonsSidebar.bpurchase)
    this.props.actions.setScrollTo(null)
  }

  componentDidMount () {
    this.props.actions.restartPurchaseState()
    this.props.actions.fetchCommercialSocietyTypeCombo()
    this.fetchPurchaseDossierPromise(this.props.match.params.dossierId).then(() => {
      const form = { ...this.props.dossier }
      if (!this.props.dossier.entityComponent.entityId) delete form.entityComponent
      if (!this.props.dossier.vehicleComponent.vehicleId) delete form.vehicleComponent
      this.props.initialize(form)
      this.initializePermisions(this.props)
      this.props.actions.fetchAllReasons(this.props.dossier.dossierSubTypeId)
      this.props.actions.fetchCustomFields(this.props.dossier.dossierSubTypeId)
      this.props.actions.registerLastAccess(this.props.match.params.dossierId, false)
      // this.props.actions.fetchRentingEntityCombo()
      this.props.actions.fetchStockStatusCombo()
      this.props.actions.fetchCountries()
      this.props.actions.fetchSaleTypeAll()
      this.props.actions.fetchPuscharseSaleEntitys(this.props.dossier.organizedUnitId)
      this.props.actions.fetchPurchaseOrderTypeCombo()
      this.props.actions.fetchSameLevelOusCombo(this.props.dossier.organizedUnitId, this.props.dossier.dossierSubTypeId)
      this.props.trackVisitPage({
        documentTitle: TrackedPages.PURCHASE,
        organizedUnitId: this.props.dossier.organizedUnitId,
        href: window.location.href
      })
    })
    this.unregisterLeaveHook = this.props.history.block(this.routerWillLeave.bind(this))
    this.setState({ showTimelineInTab: this.props.auth.showChecklistAsTab ?? false })
  }

  getSnapshotBeforeUpdate (prevProps) {
    if ((this.props.showManagementModal && !prevProps.showManagementModal) || this.props.match.params.dossierId !== prevProps.match.params.dossierId) {
      if (this.props.match.params.dossierId !== prevProps.match.params.dossierId) {
        this.props.actions.restartPurchaseState()
        this.props.actions.setScrollTo(buttonsSidebar.bpurchase)
        this.props.actions.setActiveSection(buttonsSidebar.bpurchase)
        this.props.actions.setActiveTab(buttonsHeader.datos_expediente)
        this.props.actions.closeAddOrEditDocumentModal()
      }
      this.fetchPurchaseDossierPromise(this.props.match.params.dossierId).then(() => {
        const form = { ...this.props.dossier }
        if (!this.props.dossier.entityComponent.entityId) delete form.entityComponent
        if (!this.props.dossier.vehicleComponent.vehicleId) delete form.vehicleComponent
        this.props.actions.fetchPurchaseDossierDependants(this.props.match.params.dossierId)
        this.props.actions.fetchStockStatusCombo()
        this.props.actions.fetchCountries()
        this.props.actions.fetchPurchaseOrderTypeCombo()
        this.props.initialize(form)
        this.initializePermisions(this.props)
      })
    }
    if (JSON.stringify(this.props.dossier.dossierPurchaseEconomicPlanComponent) !== JSON.stringify(prevProps.dossier.dossierPurchaseEconomicPlanComponent)) {
      this.props.initialize(this.props.dossier)
    }
    if (prevProps.dossier.dossierId && !compareObjects(this.props.dossier.dossierPurchaseComponent || {}, prevProps.dossier.dossierPurchaseComponent, true)) {
      const form = { ...this.props.dossier }
      this.props.initialize(form)
    }
    if (this.props.dossier && this.props.dossier.viewConfiguration && this.props.dossier.viewConfiguration.tabsConfiguration && !compareObjects(prevProps.dossier.viewConfiguration.tabsConfiguration || {}, this.props.dossier.viewConfiguration.tabsConfiguration, true)) {
      this.initializePermisions(this.props)
    }

    if (prevProps.dossier.dossierId && prevProps.activeTab !== this.props.activeTab) {
      this.initializePermisions(this.props)
    }
    if (this.props.dossier && this.props.dossier.comments && this.props.activeTab !== prevProps.activeTab && this.props.activeTab === buttonsHeader.observaciones) {
      this.props.actions.getComments(this.props.dossier.dossierId, 'purchaseDossier')
    }
    if (this.props.dossier && this.props.dossier.organizedUnitId && this.props.dossier.organizedUnitId !== prevProps.dossier.organizedUnitId) {
      this.props.actions.fecthOrganizedUnitsByDossierUo(this.props.dossier.organizedUnitId)
    }

    return prevProps
  }

  componentWillUnmount () {
    this.props.actions.setActiveSection(buttonsSidebar.bpurchase)
    this.props.actions.setScrollTo(null)
    this.props.actions.resetButtonsHeader()
    this.unregisterLeaveHook
    this.props.actions.closeAddOrEditDocumentModal()
    this.props.actions.restartPurchaseState()
  }

  toogleHidedossierBodyLeft () {
    this.setState({ dossierBodyLeftClose: !this.state.dossierBodyLeftClose })
  }

  render () {
    const {
      t, pristine, dossier, vehicleProps, change, activeTab, buttonsHeaderHeight, activeSection, statusHeaderHeight, windowWidth, formValues, reset, reason, comment, dispatch,
      dossier: {
        dossierId, dossierSubTypeId, dossierParentId, organizedUnitId, checkListHeader, header,
        dossierPurchaseComponent, comments, showAlertSendPending, dossierCompletePaperworks, unreadComments,
        viewConfiguration, viewConfiguration: { buttonsPermission = [], purchaseConfiguration },
        vehicleComponent: { vehicleId }, entityChangeable, dossierRelatedDossiers, auditInfo, isMarked, defaultDestination
      },
      dossierSales: { dossierServiceRelateds },
      combos: {
        brandCombo, fuelCombo, previousUsageCombo, ivaTypeCombo, paymentMethodPurchase, laterUseCombo, vehicleType,
        entityTypeCombo, channelCombo, maritalStatusCombo, segmentationCombo, entitySubTypeCombo, entityFilterSubTypeCombo, purchaseTypeCombo, activityClientCombo, activityBuyerCombo, voCompaniesCombo, rentingEntityCombo, tuneUpDestinationCombo,
        vehicleDestinationCombo, vehicleOriginCombo, stockStatusCombo, purchaseOrderTypeCombo, purchasePresentTypeCombo, lowestOrganizedUnitsCombo, countries, familyCombo, salesmanCombo, cancelCombo, dossierSubTypesCombo,
        carlineCombo, catalogueCombo, modelCombo, catalogueModelCombo, colorCombo, tapestryCombo,
        activityBproCombo, CSTCombo, saleTypeAllCombo, puscharseSaleEntitys
      },
      actions: {
        purchaseReject, purchaseSendApprove, purchaseApprove, purchaseTransferRequest, purchaseTransferRequestApproved, putCancelDossier, changeDossierSubType, storeDossier,
        updateComment, createComment, deleteComment, updateDependantsPurchaseDossierAndHeader,
        openDocumentModal, searchVehicle, setSearchDealerNumber, setSearchVinChassis,
        setSearchLicensePlate, setSimpleInputTextValidation, resetSearchFields, synchroniseAvailabilityDate, printDeliveryChecklist,
        setEntityTypeId, setEntitySubTypeId, setEntityId, fetchClientByDni, fetchEntitySubTypeCombo, fetchEntityFilterSubTypeCombo, openDocumentEditionModal, fetchClientByDniEntityChild,
        fetchClientFromDocument, fetchClientFromParent, setActiveSection, setActiveTab, setButtonsHeaderHeight, resetButtonsHeader, setScrollerOptions, /* navigateTo, */
        clearVehicle, fetchReasons, setEntityChildSubTypeId, fetchVoCompaniesCombo, submitSelectVoCompany, clearEntity, clearEntityChild, fetchComboOrigin, fetchComboDestination,
        updatePurchasePresentSuccess, setEntityValidationDisable,
        refreshAuditInfo, fetchPurchaseDossierEconomic, fetchPurchaseClient, fetchPurchaseVehicle, fetchPurchaseOtherData, sendDocumentToSign, documentsToSendSignGetAll, openDocumentsToSendSign, signTypes, fetchAuditDetails, fetchAuditDetailsModal, fetchDocumentsGroupedAuditDetails, fetchDocumentsGroupedAuditDetailsModal,
        fetchLowestOrganizedUnits, fecthOrganizedUnitsByDossierUo, fetchVehiclePurchaseComponent,
        getChangeUo,
        getSalesmanChangeUo,
        postUpdateChangeUo,
        fecthFamily,
        markCommentAsRead,
        checkPurchaseTypeAndSetDefaultEntity,
        clearClientFields,
        fetchCancelCombo,
        fetchClientByRfc,
        purchaseApproveManager,
        purchaseApproveSalesManagerVo,
        checkPrivateRfcFormat,
        foreignPassportWarning,
        fetchSalesServices,
        fetchDossierSubTypes,
        saveSalesService,
        fetchDefaultEntity,
        fetchNotValidDatesService,
        openOrCloseServiceAddModal,
        fetchCommercialSocietyTypeCombo,
        fetchEntityCountryTypeCombo, fetchEntityStateTypeCombo,
        fetchEntityCityTypeCombo, fetchEntityTownTypeCombo,
        fetchEntityColonyTypeCombo, fetchEntityCPTypeCombo,
        openCommonModal,
        fetchCarlineCombo,
        fetchCatalogueCombo,
        fetchColorCombo,
        fetchTapestryCombo,
        fetchPurchaseCatalogueModel,
        resetCarlineCombo,
        resetCatalogueCombo,
        resetColorCombo,
        resetTapestryCombo,
        fetchClientByPhoneEmail,
        fetchActivityBpro,
        validate,
        stopAsyncValidation,
        fetchClientDataAddress,
        openDocWarning,
        transferReject
      }
    } = this.props
    if (!dossierId) return null
    const tKey = 'DOSSIER_COMPONENTS.'
    const activeStage = checkListHeader.find(stage => stage.isActive)
    const savePurchasePermission = buttonsPermission.includes('purchase_save')
    const printDeliveryChecklistPermission = buttonsPermission.includes('purchase_print_delivery_checklist')
    const panelsPaddingTop = statusHeaderHeight + buttonsHeaderHeight - 82 // 82px es el paddingTop definido en el layout.
    return (
      <div class="dossierGrid">
        <div className='modalsContainer'>
          <DocumentaryManagementModalPage
            dossier={dossier}
            dossierType={dossierTypesConstant.purchase}
            readOnlyFields={isMarked}
          />
          <RejectCommentModal
            t={t}
            showModal={this.state.showRejectCommentModal}
            dossierSubTypeId={dossierSubTypeId}
            reasonCombo={this.props.combos.reasonCombo}
            actions={{
              rejectAction: (comment, resolve, reject) => purchaseReject(comment, resolve, reject, pristine),
              closeModal: () => this.toggleRejectCommentModal(false),
              fetchReasons: fetchReasons
            }}
          />
          <RejectCommentModal
            t={t}
            showModal={this.state.showRejectCommentModalTransfer}
            reasonCombo={[]}
            dossierSubTypeId={dossierSubTypeId}
            actions={{
              rejectAction: (comment, resolve, reject) => transferReject(comment, resolve, reject, pristine),
              closeModal: () => this.toggleRejectCommentTransfer(false),
              fetchReasons: null
            }}
          />
          <SendDocumentsSignModal
            t={t}
            dossierNumber={dossier.header.number}
            dossierId={dossier.dossierId}
            showModal={this.state.showSendDocumentsModal}
            actions={{
              closeModal: () => this.toggleSendDocumentsModal(false),
              sendDocumentToSign,
              documentsToSendSignGetAll,
              signTypes
            }}
          />
        </div>
        <div class="dossierGridPart-header">
          <DossierHeaderPage
            headerData={header}
            stages={checkListHeader}
            dossierType={dossierTypesConstant.purchase}
            dossierSubTypes={dossierSubTypeId}
            dossierId={dossier.dossierId}
            activeTab={activeTab}
            headerTabConfiguration={this.state.headerTabConfiguration}
            pristine={pristine}
            goBack={this.goBack}
            readOnlyFields={isMarked}
            editableDoc={viewConfiguration ? getDocumentaryPermission(viewConfiguration) : true}
            showTimelineInTab={this.state.showTimelineInTab}
            timeLinePosition = {this.state.showTimelineInTab}
            toggleShowTimelineInTab = {() => this.toggleShowTimelineInTab()}
          />
        </div>
        <div class="dossierGridPart-body">
          <div className={'dossierBody-left ' + (this.state.dossierBodyLeftClose ? 'close' : '') }>
            <DossierSummary
              t = {t}
              dossier={dossier}
              header={header}
              dossierType={dossierTypesConstant.purchase}
              dossierSubType={dossierSubTypeId}
              brandCombo = {brandCombo}
              entityTypeCombo = {entityTypeCombo}
              dossierRelatedDossiers = {dossierRelatedDossiers}
            />
          </div>
          <div className='dossierBody-center'>
            <OverlayTrigger placement="right" overlay={<Tooltip id="editProductTooltip">{`${t((this.state.dossierBodyLeftClose ? 'GLOBAL.TABLES.SHOW_INNER_MENU' : 'GLOBAL.TABLES.HIDE_INNER_MENU'))}`}</Tooltip>}>
              <a onClick={() => this.toogleHidedossierBodyLeft()} className={'closeDossierInfo ' + (this.state.dossierBodyLeftClose ? '' : 'closeInnerMenu')}><i className={this.state.dossierBodyLeftClose ? 'iDocIcon-Open-inner-menu' : 'iDocIcon-Close-inner-menu'}></i></a>
            </OverlayTrigger>
          </div>
          <div className='dossierBody-right'>
            {!this.state.showTimelineInTab &&
              <div className='dossierGridPart-topTimeLine'>
                <DossierTimeLineSectionPage
                  showTitlePage={false}
                  headerData={header}
                  stages={checkListHeader}
                  dossierType={dossierTypesConstant.sales}
                  dossierSubType={dossierSubTypeId}
                  dossierId={dossier.dossierId}
                  activeTab={activeTab}
                  // showEconomicPlan={showEconomicPlan}
                  headerTabConfiguration={this.state.headerTabConfiguration}
                  pristine={pristine}
                  goBack={this.goBack}
                  readOnlyFields={isMarked}
                  editableDoc={viewConfiguration ? getDocumentaryPermission(viewConfiguration) : true}
                  isSelfSupplyLoaded={this.state.isSelfSupplyLoaded}
                  handleToUpdateSelfSupply = {() => this.handleToUpdateSelfSupply()}
                />
              </div>
            }
            <div class="dossierGridPart-sectionsMenu">
              <ButtonsHeader
                t={t}
                pristine={pristine}
                reset={reset}
                organizedUnitId={organizedUnitId}
                combos={{ purchaseTypeCombo, voCompaniesCombo }}
                cancelCombo={cancelCombo}
                lowestOrganizedUnitsCombo = {lowestOrganizedUnitsCombo}
                reason={reason}
                comment={comment}
                actions={{
                  storeDossier,
                  purchaseSendApprove,
                  purchaseApprove,
                  purchaseReject: () => this.toggleRejectCommentModal(true),
                  openDocumentModal: () => openDocumentModal(dossierId, dossierTypesConstant.purchase, pristine),
                  purchaseCancel: () => putCancelDossier(dossierId, pristine, reset),
                  changeDossierSubType: () => changeDossierSubType(dossierId, dossier.lastAccessDate),
                  setActiveTab,
                  setButtonsHeaderHeight,
                  resetButtonsHeader,
                  setScrollerOptions,
                  fetchVoCompaniesCombo,
                  submitSelectVoCompany,
                  openDocumentsToSendSign,
                  openSendDocumentsToSignModal: (document) => this.toggleSendDocumentsModal(true, document),
                  submitForm: this.handleSubmitForm.bind(this),
                  fetchLowestOrganizedUnits,
                  getChangeUo,
                  getSalesmanChangeUo,
                  postUpdateChangeUo,
                  fecthOrganizedUnitsByDossierUo,
                  purchaseTransferRequest,
                  purchaseTransferRequestApproved,
                  putCancelDossier,
                  fetchCancelCombo: fetchCancelCombo,
                  purchaseApproveManager,
                  purchaseApproveSalesManagerVo,
                  transferReject: () => this.toggleRejectCommentTransfer(true)
                }}
                viewConfiguration={viewConfiguration}
                dossierId={dossierId}
                dossierType={dossierTypesConstant.purchase}
                activeTab={activeTab}
                marginTop={statusHeaderHeight}
                windowWidth={windowWidth}
                commentUnreadCount={ unreadComments || comments.filter((comment) => { return !comment.read }).length}
                changeUo = {this.props.changeUo}
                readOnlyFields={isMarked}
                type={dossierTypesConstant.purchase}
                showTimelineInTab={this.state.showTimelineInTab}
              />
            </div>
            <div class="dossierGridPart-ComponentSection">
              <Form autoComplete="off">
                {// TIME LINE
                  activeTab === buttonsHeader.time_line && (
                    <div className="dossierGridPart-ComponentSection-Inner">
                      <div className="ComponentSection-InnerData">
                        <DossierTimeLineSectionPage
                          showTitlePage={true}
                          headerData={header}
                          stages={checkListHeader}
                          dossierType={dossierTypesConstant.sales}
                          dossierSubType={dossierSubTypeId}
                          dossierId={dossier.dossierId}
                          activeTab={activeTab}
                          // showEconomicPlan={showEconomicPlan}
                          headerTabConfiguration={this.state.headerTabConfiguration}
                          pristine={pristine}
                          goBack={this.goBack}
                          readOnlyFields={isMarked}
                          editableDoc={viewConfiguration ? getDocumentaryPermission(viewConfiguration) : true}
                          isSelfSupplyLoaded={this.state.isSelfSupplyLoaded}
                          handleToUpdateSelfSupply = {() => this.handleToUpdateSelfSupply()}
                        />
                      </div>
                    </div>
                  )}
                {
                  activeTab === buttonsHeader.datos_expediente && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden && (
                    <div className="_nd dossierGridPart-ComponentSection-Inner" >
                      <div className="ComponentSection-InnerMennu">
                        <ButtonsSidebar
                          t={t}
                          dossierId={dossierId}
                          pristine={pristine}
                          actions={{ setActiveSection, fetchPurchaseClient, fetchPurchaseVehicle, fetchPurchaseOtherData, fetchSalesServices }}
                          isOtherDataLoaded = {this.state.isOtherDataLoaded}
                          dossierType={dossierTypesConstant.purchase}
                          dossierSubTypes={dossierSubTypeId}
                          activeStage={activeStage}
                          marginTop={statusHeaderHeight}
                          buttonsHeaderHeight={buttonsHeaderHeight}
                          tabConfiguration={this.state.activeTabConfiguration}
                          isClientLoaded = {this.state.isClientLoaded}
                          isVehicleLoaded = {this.state.isVehicleLoaded}
                          handleToUpdateComponent= {this.handleToUpdateComponent.bind(this)}
                          historical = {false}
                        />
                      </div>
                      <div className="ComponentSection-InnerData" id="dossierData" >
                        {this.state.componentsConfiguration.bpurchase && this.state.componentsConfiguration.bpurchase.permission > permissions.hidden && (
                          <Element style={{ zIndex: 6 }} name={buttonsSidebar.bpurchase}>
                            <PurchaseComponent
                              t={t}
                              showNewVehicleFields={dossierPurchaseComponent.showNewVehicleFields}
                              combos={{ purchaseTypeCombo, rentingEntityCombo, tuneUpDestinationCombo, stockStatusCombo, purchaseOrderTypeCombo, salesmanCombo, saleTypeAllCombo, puscharseSaleEntitys }}
                              actions={{
                                printDeliveryChecklist,
                                togglePanel: () => this.togglePanel(),
                                synchroniseAvailabilityDate,
                                checkPurchaseTypeAndSetDefaultEntity,
                                setActiveSection,
                                openCommonModal
                              }}
                              organizedUnitId={dossier.organizedUnitId}
                              readOnlyFields={!savePurchasePermission || isMarked}
                              purchaseOpen={this.state.purchaseOpen || (activeSection === buttonsSidebar.bpurchase)}
                              dossierType={dossierTypesConstant.purchase}
                              dossierSubType={dossierSubTypeId}
                              dossierId={dossierId}
                              printDeliveryChecklistPermission={printDeliveryChecklistPermission}
                              componentConfiguration={this.state.componentsConfiguration.bpurchase}
                              hasParent={!!dossierParentId}
                              purchaseTypeId={dossierPurchaseComponent.purchaseTypeId}
                              change={change}
                              dossier = {dossier}
                              customFields={this.props.dossier.customFieldsByComponent.find(x => x.componentCode === buttonsSidebar.bpurchase)?.customFields}
                            />
                          </Element>
                        )}
                        {this.state.componentsConfiguration.entity && this.state.componentsConfiguration.entity.permission > permissions.hidden && (
                          <Element style={{ zIndex: 5 }} name={buttonsSidebar.entity}>
                            <Client
                              t={t}
                              dossier={dossier}
                              combos={{
                                entityTypeCombo,
                                channelCombo,
                                maritalStatusCombo,
                                segmentationCombo,
                                entitySubTypeCombo,
                                entityFilterSubTypeCombo,
                                activityBproCombo,
                                CSTCombo
                              }}
                              entityChangeable={entityChangeable}
                              dossierType={dossierTypesConstant.purchase}
                              isClientLoaded = {this.state.isClientLoaded}
                              handleToUpdateComponent= {this.handleToUpdateComponent.bind(this)}
                              actions={{
                                change,
                                setEntityTypeId,
                                setEntitySubTypeId,
                                setEntityId,
                                fetchClientByDni,
                                fetchClientByDniEntityChild,
                                fetchEntitySubTypeCombo,
                                fetchEntityFilterSubTypeCombo,
                                openDocumentEditionModal,
                                fetchClientFromDocument,
                                fetchClientFromParent,
                                setEntityChildSubTypeId,
                                clearEntity,
                                clearEntityChild,
                                fetchPurchaseClient,
                                setEntityValidationDisable,
                                setActiveSection,
                                clearClientFields,
                                fetchClientByRfc,
                                checkPrivateRfcFormat,
                                foreignPassportWarning,
                                fetchCommercialSocietyTypeCombo,
                                fetchEntityCountryTypeCombo,
                                fetchEntityStateTypeCombo,
                                fetchEntityCityTypeCombo,
                                fetchEntityTownTypeCombo,
                                fetchEntityColonyTypeCombo,
                                fetchEntityCPTypeCombo,
                                openCommonModal,
                                fetchClientByPhoneEmail,
                                fetchActivityBpro,
                                validate,
                                dispatch,
                                asyncValidate,
                                stopAsyncValidation: (value) => stopAsyncValidation('purchase_dossier', value),
                                fetchClientDataAddress
                              }}
                              readOnlyFields={!savePurchasePermission || isMarked}
                              open={activeSection === buttonsSidebar.entity}
                              componentConfiguration={this.state.componentsConfiguration.entity}
                              pristine={pristine}
                              updateComponentAfterDeleteAndRestart={this.updateComponentAfterDeleteAndRestart.bind(this)}
                              change={change}
                              customFields={this.props.dossier.customFieldsByComponent.find(x => x.componentCode === buttonsSidebar.entity)?.customFields}
                              formValues={formValues}
                            />
                          </Element>
                        )}
                        {this.state.componentsConfiguration.vehicle && this.state.componentsConfiguration.vehicle.permission > permissions.hidden && (
                          <Element style={{ zIndex: 4 }} name={buttonsSidebar.vehicle}>
                            <Vehicle
                              parentDossierType={{
                                isPaperwork: false,
                                isSales: false,
                                isPurchase: true,
                                isCampaign: false,
                                isRegistrationDelivery: false
                              }}
                              t={t}
                              dossierId={dossierId}
                              searchedVehicleProps={vehicleProps}
                              dossierParentId={dossierParentId}
                              dossierWithVehicle={!!vehicleId}
                              combos={{
                                brandCombo,
                                fuelCombo,
                                previousUsageCombo,
                                tuneUpDestinationCombo,
                                vehicleOriginCombo,
                                vehicleDestinationCombo,
                                countries,
                                familyCombo,
                                carlineCombo,
                                catalogueCombo,
                                modelCombo,
                                catalogueModelCombo,
                                colorCombo,
                                tapestryCombo
                              }}
                              actions={{
                                searchVehicle,
                                setSearchDealerNumber,
                                setSearchVinChassis,
                                setSearchLicensePlate,
                                resetSearchFields,
                                setSimpleInputTextValidation,
                                clearVehicle,
                                fetchComboOrigin,
                                fetchComboDestination,
                                fetchPurchaseVehicle,
                                fecthFamily,
                                fetchVehiclePurchaseComponent,
                                setActiveSection,
                                fetchCarlineCombo,
                                fetchCatalogueCombo,
                                fetchColorCombo,
                                fetchTapestryCombo,
                                fetchPurchaseCatalogueModel,
                                resetCarlineCombo,
                                resetCatalogueCombo,
                                resetColorCombo,
                                resetTapestryCombo
                              }}
                              handleToUpdateComponent = {this.handleToUpdateComponent.bind(this)}
                              isVehicleLoaded = {this.state.isVehicleLoaded}
                              pristine={pristine}
                              readOnlyFields={!savePurchasePermission || isMarked}
                              open={activeSection === buttonsSidebar.vehicle}
                              dossierType={dossierTypesConstant.purchase}
                              dossierSubType={dossierSubTypeId}
                              formValues={formValues ? formValues.vehicleComponent : {}}
                              componentConfiguration={this.state.componentsConfiguration.vehicle}
                              organizedUnitId={dossier.organizedUnitId}
                              codeVehicleConfiguration={dossierSubTypeId === dossierSubTypeIdConstants.compra_vn || !purchaseConfiguration ? false : purchaseConfiguration.showCodecombos}
                              comercialCode={this.props.dossier.vehicleComponent.comercialCode ? this.props.dossier.vehicleComponent.comercialCode : null}
                              purchaseType = {this.props.dossier.dossierPurchaseComponent.purchaseTypeId}
                              searchVehicle = {true}
                              customFields={this.props.dossier.customFieldsByComponent.find(x => x.componentCode === buttonsSidebar.vehicle)?.customFields}
                            />
                          </Element>
                        )}
                        {this.state.componentsConfiguration.paperwork && this.state.componentsConfiguration.paperwork.permission > permissions.hidden && (
                          <Element style={{ zIndex: 3 }} name={buttonsSidebar.paperwork}>
                            <PaperworksPage
                              organizedUnitId={organizedUnitId}
                              dossierParentId={dossierId}
                              updateDossierAction={updateDependantsPurchaseDossierAndHeader}
                              showAlertSendPending={showAlertSendPending}
                              paperwork={dossierCompletePaperworks}
                              readOnlyFields={!savePurchasePermission || isMarked}
                              dossierType={dossierTypesConstant.purchase}
                              open={activeSection === buttonsSidebar.paperwork}
                              componentConfiguration={this.state.componentsConfiguration.paperwork}
                              purchaseVoMandate = {dossierPurchaseComponent.purchaseVoMandate}
                              customFields={this.props.dossier.customFieldsByComponent.find(x => x.componentCode === buttonsSidebar.paperwork)?.customFields}
                            />
                          </Element>
                        )}
                        {this.state.componentsConfiguration.services && this.state.componentsConfiguration.services.permission > permissions.hidden && (
                          <Element style={{ zIndex: 2 }} name={buttonsSidebar.services}>
                            <Services
                              t={t}
                              dossier={dossier}
                              isPurchase = {true}
                              combos={{ dossierSubTypesCombo, entitySubTypeCombo, entityFilterSubTypeCombo }}
                              showAddModal={dossierServiceRelateds.showAddModal}
                              datesNotValid={dossierServiceRelateds.datesNotValid}
                              services={dossierServiceRelateds.rows}
                              subTypesAllowedToCreate={dossierServiceRelateds.subTypesToCreate}
                              canAdd={dossierServiceRelateds.canAdd}
                              dossierType={dossierTypesConstant.purchase}
                              readOnlyFields={!savePurchasePermission || isMarked}
                              isPreCancelled={false}
                              open={activeSection === buttonsSidebar.services}
                              componentConfiguration={this.state.componentsConfiguration.services}
                              historical = {false}
                              handleToUpdateComponent = {this.handleToUpdateComponent.bind(this)}
                              pristine={pristine}
                              actions={{
                                fetchSalesServices,
                                fetchDossierSubTypes,
                                fetchEntitySubTypeCombo,
                                fetchEntityFilterSubTypeCombo,
                                saveSalesService,
                                fetchDefaultEntity,
                                fetchNotValidDatesService,
                                openOrCloseServiceAddModal,
                                setActiveSection
                              }}
                              defaultDestination={defaultDestination}
                              customFields={this.props.dossier.customFieldsByComponent.find(x => x.componentCode === buttonsSidebar.services)?.customFields}
                            />
                          </Element>
                        )}
                        {dossierSubTypeId === dossierSubTypeIdConstants.compra_vo && this.state.componentsConfiguration.other_data && this.state.componentsConfiguration.other_data.permission > permissions.hidden && (
                          <Element style={{ zIndex: 1 }} name={buttonsSidebar.other_data} className="other-data">
                            <OtherData
                              t={t}
                              pristine={pristine}
                              dossierId={dossierId}
                              handleToUpdateComponent={this.handleToUpdateComponent.bind(this)}
                              isOtherDataLoaded={this.state.isOtherDataLoaded}
                              dossierType={dossierTypesConstant.purchase}
                              actions={{ fetchPurchaseOtherData, setActiveSection }}
                              combos={{
                                ivaTypeCombo,
                                paymentMethodPurchase,
                                laterUseCombo,
                                vehicleType,
                                previousUsageCombo,
                                activityClientCombo,
                                activityBuyerCombo,
                                fetchPurchaseOtherData
                              }}
                              readOnlyFields={!savePurchasePermission || isMarked}
                              open={activeSection === buttonsSidebar.other_data}
                              componentConfiguration={this.state.componentsConfiguration.other_data}
                              customFields={this.props.dossier.customFieldsByComponent.find(x => x.componentCode === buttonsSidebar.other_data)?.customFields}
                            />
                          </Element>
                        )}
                      </div>
                    </div>
                  )}
                {
                  activeTab === buttonsHeader.gestor_documental && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden && (
                    <div className="dossierGridPart-ComponentSection-Inner sales-panel gestorDocumental">
                      <div className="ComponentSection-InnerData">
                        <DocumentaryManagementPage
                          dossier={dossier}
                          dossierType={dossierTypesConstant.purchase}
                          formState={pristine}
                          configuration={this.state.activeTabConfiguration}
                          readOnlyFields={isMarked}
                        />
                      </div>
                    </div>
                  )}
                {
                  activeTab === buttonsHeader.expedientes_relacionados && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden && (
                    <div className="dossierGridPart-ComponentSection-Inner related-dossiers-table">
                      <div className="ComponentSection-InnerData">
                        <div className="wrapper _nf">
                          <div className='wrapper-header'>
                            <div className="title-page">
                              <i className='ico-relacion icon-page' />
                              <h2>{t(`${tKey}RELATED_DOSSIER.RELATED_DOSSIER`)}</h2>
                            </div>
                            <div className='extraActions'> {/** Extra buttons & actions here **/}</div>
                          </div>
                          <div className='wrapper-body'>
                            <Table hover responsive className="simple-table dashboard-tasks-table" id="related-dossiers-table">
                              <thead>
                                <tr>
                                  <th onClick={this.onClickRelatedDossierColumn.bind(this, 'urgencyType')}>
                                    <i className={this.getRelatedDossierColumnIcon('urgencyType')} />
                                  </th>
                                  <th onClick={this.onClickRelatedDossierColumn.bind(this, 'number')}>
                                    {t(`${tKey}RELATED_DOSSIER.DOSSIER_NUMBER`)} <i className={this.getRelatedDossierColumnIcon('number')} />
                                  </th>
                                  <th onClick={this.onClickRelatedDossierColumn.bind(this, 'organizedUnit')}>
                                    {t(`${tKey}RELATED_DOSSIER.UO`)} <i className={this.getRelatedDossierColumnIcon('organizedUnit')} />
                                  </th>
                                  <th onClick={this.onClickRelatedDossierColumn.bind(this, 'dossierSubType')}>
                                    {t(`${tKey}RELATED_DOSSIER.SUBTYPE`)} <i className={this.getRelatedDossierColumnIcon('dossierSubType')} />
                                  </th>
                                  <th onClick={this.onClickRelatedDossierColumn.bind(this, 'stage')}>
                                    {t(`${tKey}RELATED_DOSSIER.STATE`)} <i className={this.getRelatedDossierColumnIcon('stage')} />
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <RelatedDossierList history={this.props.history} dossierRelatedDossiers={dossierRelatedDossiers} />
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                {
                  activeTab === buttonsHeader.planteo_economico && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden && (
                    <div className="dossierGridPart-ComponentSection-Inner">

                      <PurchaseEconomicPlan
                        name="dossierPurchaseEconomicPlanComponent"
                        t={t}
                        actions={{
                          fetchPurchaseDossierEconomic,
                          updatePurchasePresentSuccess
                        }}
                        createPurchasePresent = {this.createPurchasePresent.bind(this)}
                        deletePurchasePresent = {this.deletePurchasePresent.bind(this)}
                        updatePurchasePresent = {this.updatePurchasePresent.bind(this)}
                        recoverPurchasePresent = {this.recoverPurchasePresent.bind(this)}
                        isVN={dossierPurchaseComponent.showNewVehicleFields}
                        combos={{
                          ivaTypeCombo,
                          purchasePresentTypeCombo
                        }}
                        readOnlyFields={!savePurchasePermission || isMarked}
                        panelsPaddingTop={panelsPaddingTop}
                        dossierId={dossierId}
                        historical={false}
                        pristine={pristine}
                        isEconomicLoaded={this.state.isEconomicLoaded}
                        handleToUpdateComponent={this.handleToUpdateComponent.bind(this)}
                        dossierEconomicPlan = {this.props.formValues.dossierPurchaseEconomicPlanComponent}
                        dossierPurchaseComponent = {dossierPurchaseComponent}
                        change = {this.props.change}
                        dossierLastAccesDate= {dossier.lastAccessDate ? dossier.lastAccessDate : null}
                        tabsConfiguration = {this.props.dossier.viewConfiguration.tabsConfiguration}
                        activeTab = {3}
                        openDocumentEditionModal = {openDocumentEditionModal}
                        dossierType={dossierTypesConstant.purchase}
                        openDocWarning ={openDocWarning}
                      />

                    </div>
                  )}
                {
                  activeTab === buttonsHeader.actividad_expediente && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden && (
                    <div className="dossierGridPart-ComponentSection-Inner auditInfo">
                      <div sm={12} className="ComponentSection-InnerData">
                        <AuditInfo
                          auditInfo={auditInfo}
                          dossierType={dossierTypesConstant.purchase}
                          actions={{ refreshAuditInfo, fetchAuditDetails, fetchAuditDetailsModal, fetchDocumentsGroupedAuditDetails, fetchDocumentsGroupedAuditDetailsModal }}
                          dossier={dossier}
                          t={t}
                        />
                      </div>
                    </div>
                  )}
                {
                  activeTab === buttonsHeader.observaciones && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden && (
                    <div className="dossierGridPart-ComponentSection-Inner commentComponent">
                      <div className="ComponentSection-InnerData">
                        <CommentComponent
                          comments={comments}
                          actions={{ updateComment, createComment, deleteComment, markCommentAsRead }}
                          readOnlyFields={!savePurchasePermission || isMarked}
                          dossierType="purchaseDossier"
                          t={t}
                          tKey={tKey}
                          combos={{ allReasonCombo: this.props.combos.allReasonCombo }}
                          openComponent={activeTab === buttonsHeader.observaciones}
                          dossierId={this.props.dossier.dossierId}
                        />
                      </div>
                    </div>
                  )}
              </Form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const validate = (values, props) => {
  const error = {}
  if (values.entityComponent && values.entityComponent.updated && Object.keys(values.entityComponent).length > 1 && values.entityComponent.entityTypeId) {
    error.entityComponent = validateEntity(values.entityComponent, props)
  }
  if (values.vehicleComponent) {
    error.vehicleComponent = validateVehicle(values.vehicleComponent, props)
  }

  if (values.dossierCompleteDeliveryComponent && values.dossierCompleteDeliveryComponent.dossierDeliveryComponent) {
    error.dossierCompleteDeliveryComponent = { dossierDeliveryComponent: {} }
    error.dossierCompleteDeliveryComponent.dossierDeliveryComponent = validateDelivery(values.dossierCompleteDeliveryComponent.dossierDeliveryComponent, props, props.dossier.dossierSubTypeId, props.dossier.dossierCompleteDeliveryComponent.dossierDeliveryComponent.defaultDeliveryAddress)
  }

  if (values.dossierPurchaseComponent) {
    error.dossierPurchaseComponent = {}
    const idDealeError = validateAlfanumeric(values.dossierPurchaseComponent.dealerNumber, props, false)
    if (Object.keys(idDealeError).length > 0) {
      error.dossierPurchaseComponent.dealerNumber = idDealeError
    }
  }

  error.customFields = {}
  if (values.customFields && Object.keys(values.customFields).length > 0 && props.dossier.customFieldsByComponent) {
    error.customFields = {}
    let resultCustomFields = {}
    let allCustomFields = []
    props.dossier.customFieldsByComponent.map(x => x.customFields).map(x => {
      allCustomFields = allCustomFields.concat(x)
    })
    Object.keys(values.customFields).forEach(key => {
      const foundCustomField = allCustomFields.find(x => x.code === key)
      if (foundCustomField && foundCustomField.validationFunction) {
        if (foundCustomField.validationFunction && foundCustomField.validationFunction.indexOf('regEx-') !== -1) {
          resultCustomFields = validateRegex(foundCustomField.validationFunction, foundCustomField.customFieldLocales[0].name, values.customFields[key], props)
        } else {
          resultCustomFields = customValidations(foundCustomField.validationFunction, values.customFields[key], props)
        }
        error.customFields[key] = resultCustomFields === Object(resultCustomFields) ? undefined : resultCustomFields
      }
    })
  }

  return error
}

const asyncValidate = (values, dispatch, props, field, returnError) => {
  let promises = []
  if (values.entityComponent && values.entityComponent.updated) {
    promises = promises.concat(asyncValidateEntityDni(values.entityComponent, dispatch, props, props.dossier?.organizedUnitId))
  }

  return Promise.allSettled(promises).then(result => {
    let error = {}
    for (let index = 0; index < result.length; index++) {
      const element = result[index]
      if (element.status === promisesStatus.FULFILLED) {
        switch (index) {
          case validationPurchasePromisesOrder.ENTITY_COMPONENT:
            error = { ...error, entityComponent: element.value }
            break
          default:
            break
        }
      }
    }
    if (Object.keys(error).length > 0 && !returnError) throw Object.assign(new Error(), error)
    if (returnError) return error
  })
}

var decoratedComponent = TrackingContainer(Purchase, false)

export default reduxForm({
  form: 'purchase_dossier',
  validate,
  asyncValidate,
  asyncBlurFields: [...getFieldsValidated('entityComponent')]
})(decoratedComponent)
