import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import ExtraSaleModal from '../../components/masters/ExtraSaleModal'
import { translate } from 'react-polyglot'
import { closeExtraSaleModal, saveExtraSaleModal } from '../../actions/masters/masters'

export function mapStateToProps (state) {
  return {
    showExtraSaleModal: state.entityMasters.showExtraSaleModal,
    languageList: state.entityMasters.languageList,
    extraSaleTypeLanguages: state.entityMasters.extraSaleFields.extraSaleTypeLanguages || [],
    extraSaleId: state.entityMasters.extraSaleId
    /* ,
    languageList: [
      {languageId: 1, name: 'Castellano', description: 'es-ES'},
      {languageId: 2, name: 'Catalán', description: 'es-CA'}
    ] */
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      closeExtraSaleModal,
      saveExtraSaleModal
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(ExtraSaleModal))
