import React, { PureComponent } from 'react'
import { Button, Modal, Col, Row, OverlayTrigger, Tooltip, Glyphicon } from 'react-bootstrap'
import { reduxForm, Field } from 'redux-form'
import SimpleTablePage from '../../../containers/commons/general/SimpleTablePage'
import { compareValues, formatDate } from '../../../util/utils'
import InputCheckbox from '../../commons/form/InputCheckBox'
import InputSelect from '../../commons/form/InputSelect'
import { documentEntityType as entityType, documentEntityType } from '../../../constants/dossier/common/documentEntityType'
import { documentStatus, signStatus, translateDocumentStatus, documentStatusClass } from '../../../constants/dossier/common/documentStatus'
import { fleetMode } from '../../../constants/dossier/common/documentEditionModal'

class DocumentDetailsFleetModal extends PureComponent {
  constructor () {
    super()
    this.state = {
      filters: {
        orderBy: '+number'
      },
      dataId: null,
      selectedCheck_massive: false
    }
  }

  componentDidUpdate () {}

  getSnapshotBeforeUpdate (prevProps) {
    if (this.props.showModal !== prevProps.showModal && this.props.showModal) {
      this.setState({ selectedCheck_massive: false })
      if (this.props.dossierMode) {
        this.props.actions.fetchDocumentDetailsFleet(this.props.documentFleet.documentTypeUseId, null, this.props.dossierList)
      } else {
        this.props.actions.fetchDocumentDetailsFleet(this.props.documentFleet.documentTypeUseId, this.props.fleet.fleetId, null)
      }
    }
    return null
  }

  updateList () {
    if (this.props.dossierMode) {
      this.props.actions.fetchDocumentDetailsFleet(this.props.documentFleet.documentTypeUseId, null, this.props.dossierList)
    } else {
      this.props.actions.fetchDocumentDetailsFleet(this.props.documentFleet.documentTypeUseId, this.props.fleet.fleetId, null)
    }
  }

  getIconClass (field) {
    if (this.state.filters.orderBy.includes(field)) {
      return this.state.filters.orderBy.includes('-') ? 'ico-sort-selected-asc' : 'ico-sort-selected-desc'
    } else {
      return 'ico-sort'
    }
  }

  onOrderChange (e) {
    const newOrderBy = this.state.filters.orderBy.includes(`+${e.currentTarget.dataset.field}`) ? `-${e.currentTarget.dataset.field}` : `+${e.currentTarget.dataset.field}`
    this.setState({ filters: { orderBy: newOrderBy } })
    this.order(e.currentTarget.dataset.field, newOrderBy.includes('+') ? 'asc' : 'desc')
  }

  order (field, order) {
    let dossiers = JSON.parse(JSON.stringify(this.props.documentDetails))
    dossiers = dossiers.sort(compareValues(field, order))

    this.props.actions.fetchDocumentDetailsFleetSuccess(dossiers)
  }

  onCheckAll (e) {
    this.setState({ selectedCheck_massive: e })
    let dossiers = JSON.parse(JSON.stringify(this.props.documentDetails))

    dossiers.map(dossier => {
      dossier.checked = e
    })
    this.props.actions.fetchDocumentDetailsFleetSuccess(dossiers)
  }

  onCheckOne (e, id) {
    this.setState({ selectedCheck_massive: false })
    let dossiers = JSON.parse(JSON.stringify(this.props.documentDetails))

    dossiers.map(dossier => {
      if (dossier.dossierId === id) {
        dossier.checked = e
      }
    })

    this.props.actions.fetchDocumentDetailsFleetSuccess(dossiers)
  }

  closeModal () {
    this.props.initialize()
    this.props.actions.closeDocumentDetailsFleetModal()
    this.props.getDocumentFleetList()
  }

  callback (row) {
    this.props.actions.refreshDocumentManagementModal(row.dossierId)
    if (this.props.dossierMode) {
      this.props.actions.fetchDocumentDetailsFleet(this.props.documentFleet.documentTypeUseId, null, this.props.dossierList)
    } else {
      this.props.actions.fetchDocumentDetailsFleet(this.props.documentFleet.documentTypeUseId, this.props.fleet.fleetId, null)
    }
    this.setState({ selectedCheck_massive: false })
  }

  getSignStatusText (signStatusId) {
    switch (signStatusId) {
      case signStatus.SIGN_PENDING:
        return 'DOSSIER_COMPONENTS.DOCUMENTARY_MANAGEMENT.ADD_OR_EDIT_DOCUMENT.SIGN_PENDING'
      case signStatus.SIGN_SIGNED:
        return 'DOSSIER_COMPONENTS.DOCUMENTARY_MANAGEMENT.ADD_OR_EDIT_DOCUMENT.SIGN_SIGNED'
      case signStatus.SIGN_CANCELLED:
        return 'DOSSIER_COMPONENTS.DOCUMENTARY_MANAGEMENT.ADD_OR_EDIT_DOCUMENT.SIGN_CANCELLED'
      case signStatus.SIGN_REJECTED:
        return 'DOSSIER_COMPONENTS.DOCUMENTARY_MANAGEMENT.ADD_OR_EDIT_DOCUMENT.SIGN_REJECTED'
      case signStatus.SIGN_AVAILABLE:
        return 'DOSSIER_COMPONENTS.DOCUMENTARY_MANAGEMENT.ADD_OR_EDIT_DOCUMENT.SIGN_AVAILABLE'
      default:
        return 'DOSSIER_COMPONENTS.DOCUMENTARY_MANAGEMENT.ADD_OR_EDIT_DOCUMENT.NOT_SIGNED'
    }
  }

  getSignStatusClass (signStatusId) {
    switch (signStatusId) {
      case signStatus.SIGN_PENDING:
        return 'text-yellow'
      case signStatus.SIGN_SIGNED:
        return 'text-green'
      case signStatus.SIGN_CANCELLED:
        return 'text-red'
      case signStatus.SIGN_REJECTED:
        return 'text-red'
      case signStatus.SIGN_AVAILABLE:
        return ''
      default:
        return ''
    }
  }

  launchAction (val) {
    switch (val) {
      case fleetMode.INCORPORATE:
        this.incorporateDocument()
        break
      case fleetMode.VALIDATE:
        this.validateDocument()
        break
      case fleetMode.REEDIT:
        this.reditDocument()
        break
      case fleetMode.CANCEL:
        this.cancelDocument()
        break
      case fleetMode.REQUEST_SIGN:
        this.signDocument()
        break
      case fleetMode.CANCEL_SIGN:
        this.cancelSignDocument()
        break
      case fleetMode.REJECT:
        this.rejectDocument()
        break
      default:
        console.log('Function not implemented yet') // this console is ok reviewer!
    }
  }

  validateDocument () {
    const documents = JSON.parse(JSON.stringify(this.props.documentDetails.filter(dossier => dossier.checked)))

    var docsValids = this.validateStatus(documents, documentStatus.PENDING)

    var docsSameStatus = this.validateStatus(documents, documentStatus.VALIDATED)

    var entityId = null
    var vehicleId = null

    if (docsValids.length > 0) {
      entityId = this.props.documentFleet.documentTypeEntityId === entityType.ENTITY ? docsValids[0].entityId : null
      vehicleId = this.props.documentFleet.documentTypeEntityId === entityType.VEHICLE ? docsValids[0].vehicleId : null
    }

    if (docsValids.length === documents.length) {
      this.props.actions.openDocumentEditionModalFleet(fleetMode.VALIDATE, docsValids[0].documentId, this.props.documentFleet.documentTypeUseId, this.props.documentFleet.documentTypeEntityId, docsValids[0].dossierId, entityId, vehicleId, docsValids, this.props.fleet.organizedUnitId, null, null, true, null, this.props.fleet.dossierSubType, null, true, null, false, true, undefined, false, false, null, this.props.fleet.fleetId)
    } else if (docsValids.length > 0) {
      this.props.actions.openModal('documentFleetActionPartialReason', () => this.props.actions.openDocumentEditionModalFleet(fleetMode.VALIDATE, docsValids[0].documentId, this.props.documentFleet.documentTypeUseId, this.props.documentFleet.documentTypeEntityId, docsValids[0].dossierId, entityId, vehicleId, docsValids, this.props.fleet.organizedUnitId, null, null, true, null, this.props.fleet.dossierSubType, null, true, null, false, true, undefined, false, false, null, this.props.fleet.fleetId), null, null, null, [{ id: '##REASON##', value: ' ' + this.props.t('MESSAGES.NO_ALL_FILES_HAS') }, { id: '##REASON##', value: this.props.t('MESSAGES.DOCUMENT_IN_STATUS') }, { id: '##STATUS##', value: this.props.t('DOSSIER_COMPONENTS.DOCUMENTARY_MANAGEMENT.INCORPORATED') }], null)
    } else if (docsSameStatus.length > 0) {
      this.props.actions.openModal('noDocumentFleetActionReason', null, null, null, null, [{ id: '##REASON##', value: ' ' + this.props.t('MESSAGES.DOCUMENTS_SAME_STATUS') }], null)
    } else {
      this.props.actions.openModal('noDocumentFleetActionReason', null, null, null, null, [{ id: '##REASON##', value: ' ' + this.props.t('MESSAGES.NO_FILE_HAS') }, { id: '##REASON##', value: this.props.t('MESSAGES.DOCUMENT_IN_STATUS') }, { id: '##STATUS##', value: this.props.t('DOSSIER_COMPONENTS.DOCUMENTARY_MANAGEMENT.INCORPORATED') }], null)
    }
  }

  incorporateDocument () {
    const documents = JSON.parse(JSON.stringify(this.props.documentDetails.filter(dossier => dossier.checked)))

    if (!this.validateEqualStatus(documents)) {
      this.props.actions.openModal('documentFleetNotSameStatus')
      return
    }
    var docsValids = this.validateStatus(documents, documentStatus.INCORPORATION_PENDING)

    var docsSameStatus = this.validateStatus(documents, documentStatus.PENDING)

    if (docsValids.length > 0) {
      // Check if there a dossier with entity or vehicle
      docsValids = this.validateCategory(docsValids, this.props.documentFleet.documentTypeEntityId)
    }

    var entityId = null
    var vehicleId = null
    if (docsValids.length > 0) {
      entityId = this.props.documentFleet.documentTypeEntityId === entityType.ENTITY ? docsValids[0].entityId : null
      vehicleId = this.props.documentFleet.documentTypeEntityId === entityType.VEHICLE ? docsValids[0].vehicleId : null
    }

    if (this.validateSameId(docsValids, this.props.documentFleet.documentTypeEntityId)) { // Check same entity or vehicle
      if (docsValids.length === documents.length) {
        this.props.actions.openDocumentEditionModalFleet(fleetMode.INCORPORATE, null, this.props.documentFleet.documentTypeUseId, this.props.documentFleet.documentTypeEntityId, null, entityId, vehicleId, docsValids, this.props.fleet.organizedUnitId, null, null, true, null, this.props.fleet.dossierSubType, null, true, null, false, true, undefined, false, false, null, this.props.fleet.fleetId)
      } else if (docsValids.length > 0) {
        this.props.actions.openModal('documentFleetActionPartial', () => this.props.actions.openDocumentEditionModalFleet(fleetMode.INCORPORATE, null, this.props.documentFleet.documentTypeUseId, this.props.documentFleet.documentTypeEntityId, null, entityId, vehicleId, docsValids, this.props.fleet.organizedUnitId, null, null, true, null, this.props.fleet.dossierSubType, null, true, null, false, true, undefined, false, false, null, this.props.fleet.fleetId), null, null, null, [{ id: '##NUM_OK##', value: docsValids.length }], null)
      } else if (docsSameStatus.length > 0) {
        this.props.actions.openModal('noDocumentFleetActionReason', null, null, null, null, [{ id: '##REASON##', value: ' ' + this.props.t('MESSAGES.DOCUMENTS_SAME_STATUS') }], null)
      } else {
        this.props.actions.openModal('noDocumentFleetActionReason', null, null, null, null, [{ id: '##REASON##', value: ' ' + this.props.t('MESSAGES.NO_FILE_HAS') }, { id: '##REASON##', value: this.props.documentFleet.documentTypeEntityId === documentEntityType.ENTITY ? this.props.t('MASTERS.ENTITY') : this.props.t('HEADER.VEHICLE') }], null)
      }
    }
  }

  reditDocument () {
    const documents = JSON.parse(JSON.stringify(this.props.documentDetails.filter(dossier => dossier.checked)))

    if (!this.validateEqualStatus(documents)) {
      this.props.actions.openModal('documentFleetNotSameStatus')
      return
    }

    var docsValids = this.validateStatus(documents, documentStatus.VALIDATED)

    var docsSameStatus = this.validateStatus(documents, documentStatus.PENDING)

    var entityId = null
    var vehicleId = null

    if (docsValids.length > 0) {
      entityId = this.props.documentFleet.documentTypeEntityId === entityType.ENTITY ? docsValids[0].entityId : null
      vehicleId = this.props.documentFleet.documentTypeEntityId === entityType.VEHICLE ? docsValids[0].vehicleId : null
    }

    if (docsValids.length === documents.length) {
      this.props.actions.openDocumentEditionModalFleet(fleetMode.REEDIT, docsValids[0].documentId, this.props.documentFleet.documentTypeUseId, this.props.documentFleet.documentTypeEntityId, docsValids[0].dossierId, entityId, vehicleId, docsValids, this.props.fleet.organizedUnitId, null, null, true, null, this.props.fleet.dossierSubType, null, true, null, false, true, undefined, false, false, null, this.props.fleet.fleetId)
    } else if (docsValids.length > 0) {
      this.props.actions.openModal('documentFleetActionPartialReason', () => this.props.actions.openDocumentEditionModalFleet(fleetMode.REEDIT, docsValids[0].documentId, this.props.documentFleet.documentTypeUseId, this.props.documentFleet.documentTypeEntityId, docsValids[0].dossierId, entityId, vehicleId, docsValids, this.props.fleet.organizedUnitId, null, null, true, null, this.props.fleet.dossierSubType, null, true, null, false, true, undefined, false, false, null, this.props.fleet.fleetId), null, null, null, [{ id: '##REASON##', value: ' ' + this.props.t('MESSAGES.NO_ALL_FILES_HAS') }, { id: '##REASON##', value: this.props.t('MESSAGES.DOCUMENT_IN_STATUS') }, { id: '##STATUS##', value: this.props.t('DOSSIER_COMPONENTS.DOCUMENTARY_MANAGEMENT.VALIDATED') }], null)
    } else if (docsSameStatus.length > 0) {
      this.props.actions.openModal('noDocumentFleetActionReason', null, null, null, null, [{ id: '##REASON##', value: ' ' + this.props.t('MESSAGES.DOCUMENTS_SAME_STATUS') }], null)
    } else {
      this.props.actions.openModal('noDocumentFleetActionReason', null, null, null, null, [{ id: '##REASON##', value: ' ' + this.props.t('MESSAGES.NO_FILE_HAS') }, { id: '##REASON##', value: this.props.t('MESSAGES.DOCUMENT_IN_STATUS') }, { id: '##STATUS##', value: this.props.t('DOSSIER_COMPONENTS.DOCUMENTARY_MANAGEMENT.VALIDATED') }], null)
    }
  }

  cancelDocument () {
    const documents = JSON.parse(JSON.stringify(this.props.documentDetails.filter(dossier => dossier.checked)))
    var docsValids = []
    documents.forEach(document => {
      if (document.documentStatusId === documentStatus.PENDING || document.documentStatusId === documentStatus.REJECTED || document.documentStatusId === documentStatus.EXPIRED) {
        docsValids.push(document)
      }
    })

    var entityId = null
    var vehicleId = null

    if (docsValids.length > 0) {
      entityId = this.props.documentFleet.documentTypeEntityId === entityType.ENTITY ? docsValids[0].entityId : null
      vehicleId = this.props.documentFleet.documentTypeEntityId === entityType.VEHICLE ? docsValids[0].vehicleId : null
    }

    if (docsValids.length === documents.length) {
      this.props.actions.openDocumentEditionModalFleet(fleetMode.CANCEL, docsValids[0].documentId, this.props.documentFleet.documentTypeUseId, this.props.documentFleet.documentTypeEntityId, docsValids[0].dossierId, entityId, vehicleId, docsValids, this.props.fleet.organizedUnitId, null, null, true, null, this.props.fleet.dossierSubType, null, true, null, false, true, undefined, false, false, null, this.props.fleet.fleetId)
    } else if (docsValids.length > 0) {
      this.props.actions.openModal('documentFleetActionPartialReason', () => this.props.actions.openDocumentEditionModalFleet(fleetMode.CANCEL, docsValids[0].documentId, this.props.documentFleet.documentTypeUseId, this.props.documentFleet.documentTypeEntityId, docsValids[0].dossierId, entityId, vehicleId, docsValids, this.props.fleet.organizedUnitId, null, null, true, null, this.props.fleet.dossierSubType, null, true, null, false, true, undefined, false, false, null, this.props.fleet.fleetId), null, null, null, [{ id: '##REASON##', value: ' ' + this.props.t('MESSAGES.NO_ALL_FILES_HAS') }, { id: '##REASON##', value: this.props.t('MESSAGES.DOCUMENT_IN_STATUS') }, { id: '##STATUS##', value: this.props.t('DOSSIER_COMPONENTS.DOCUMENTARY_MANAGEMENT.INCORPORATED') + ', ' + this.props.t('DOSSIER_COMPONENTS.DOCUMENTARY_MANAGEMENT.REJECTED') + ' ' + this.props.t('MESSAGES.OR') + ' ' + this.props.t('DOSSIER_COMPONENTS.DOCUMENTARY_MANAGEMENT.EXPIRED') }], null)
    } else {
      this.props.actions.openModal('noDocumentFleetActionReason', null, null, null, null, [{ id: '##REASON##', value: ' ' + this.props.t('MESSAGES.NO_FILE_HAS') }, { id: '##REASON##', value: this.props.t('MESSAGES.DOCUMENT_IN_STATUS') }, { id: '##STATUS##', value: this.props.t('DOSSIER_COMPONENTS.DOCUMENTARY_MANAGEMENT.INCORPORATED') + ', ' + this.props.t('DOSSIER_COMPONENTS.DOCUMENTARY_MANAGEMENT.REJECTED') + ' ' + this.props.t('MESSAGES.OR') + ' ' + this.props.t('DOSSIER_COMPONENTS.DOCUMENTARY_MANAGEMENT.EXPIRED') }], null)
    }
  }

  signDocument () {
    const documents = JSON.parse(JSON.stringify(this.props.documentDetails.filter(dossier => dossier.checked)))
    if (documents[0].sign === null || documents[0].sign === undefined || documents[0].sign === 0) {
      this.props.actions.openModal('documentFleetNotSigned')
      return
    }
    var docsValids = []

    documents.forEach(document => {
      if (document.signStatusId === null || document.signStatusId === undefined || document.signStatusId === signStatus.SIGN_AVAILABLE || document.signStatusId === signStatus.SIGN_CANCELLED) {
        docsValids.push(document)
      }
    })

    docsValids = this.validateStatus(docsValids, documentStatus.PENDING)

    var entityId = null
    var vehicleId = null

    if (docsValids.length > 0) {
      entityId = this.props.documentFleet.documentTypeEntityId === entityType.ENTITY ? docsValids[0].entityId : null
      vehicleId = this.props.documentFleet.documentTypeEntityId === entityType.VEHICLE ? docsValids[0].vehicleId : null
    }
    if (this.validateSameUo(documents)) {
      if (docsValids.length === documents.length) {
        this.props.actions.openDocumentEditionModalFleet(fleetMode.REQUEST_SIGN, docsValids[0].documentId, this.props.documentFleet.documentTypeUseId, this.props.documentFleet.documentTypeEntityId, docsValids[0].dossierId, entityId, vehicleId, docsValids, this.props.fleet.organizedUnitId, null, null, true, null, this.props.fleet.dossierSubType, null, true, null, false, true, undefined, false, false, null, this.props.fleet.fleetId)
      } else if (docsValids.length > 0) {
        // No todos los expedientes tienen el documento en el estado pendiente o tienen firma disponible
        this.props.actions.openModal('documentFleetActionPartialReason', () => this.props.actions.openDocumentEditionModalFleet(fleetMode.REQUEST_SIGN, docsValids[0].documentId, this.props.documentFleet.documentTypeUseId, this.props.documentFleet.documentTypeEntityId, docsValids[0].dossierId, entityId, vehicleId, docsValids, this.props.fleet.organizedUnitId, null, null, true, null, this.props.fleet.dossierSubType, null, true, null, false, true, undefined, false, false, null, this.props.fleet.fleetId), null, null, null, [{ id: '##REASON##', value: ' ' + this.props.t('MESSAGES.NO_ALL_FILES_HAS') }, { id: '##REASON##', value: this.props.t('MESSAGES.DOCUMENT_IN_STATUS') }, { id: '##STATUS##', value: this.props.t('DOSSIER_COMPONENTS.DOCUMENTARY_MANAGEMENT.INCORPORATED') + ' ' + this.props.t('MESSAGES.OR') + ' ' + this.props.t('MESSAGES.HAS_DISPOSABLE_SIGN') }], null)
      } else {
        this.props.actions.openModal('noDocumentFleetSignAction', null, null, null, null, [{ id: '##DOCUMENT##', value: this.props.documentFleet.description }], null)
      }
    }
  }

  cancelSignDocument () {
    const documents = JSON.parse(JSON.stringify(this.props.documentDetails.filter(dossier => dossier.checked)))
    let docsValids = []
    documents.forEach(document => {
      if (document.signStatusId === signStatus.SIGN_PENDING) {
        docsValids.push(document)
      }
    })

    var entityId = null
    var vehicleId = null

    if (docsValids.length > 0) {
      entityId = this.props.documentFleet.documentTypeEntityId === entityType.ENTITY ? docsValids[0].entityId : null
      vehicleId = this.props.documentFleet.documentTypeEntityId === entityType.VEHICLE ? docsValids[0].vehicleId : null
    }

    if (docsValids.length === documents.length) {
      this.props.actions.openDocumentEditionModalFleet(fleetMode.CANCEL_SIGN, docsValids[0].documentId, this.props.documentFleet.documentTypeUseId, this.props.documentFleet.documentTypeEntityId, docsValids[0].dossierId, entityId, vehicleId, docsValids, this.props.fleet.organizedUnitId, null, null, true, null, this.props.fleet.dossierSubType, null, true, null, false, true, undefined, false, false, null, this.props.fleet.fleetId)
    } else if (docsValids.length > 0) {
      this.props.actions.openModal('documentFleetActionPartialReason', () => this.props.actions.openDocumentEditionModalFleet(fleetMode.CANCEL_SIGN, docsValids[0].documentId, this.props.documentFleet.documentTypeUseId, this.props.documentFleet.documentTypeEntityId, docsValids[0].dossierId, entityId, vehicleId, docsValids, this.props.fleet.organizedUnitId, null, null, true, null, this.props.fleet.dossierSubType, null, true, null, false, true, undefined, false, false, null, this.props.fleet.fleetId), null, null, null, [{ id: '##REASON##', value: ' ' + this.props.t('MESSAGES.NO_ALL_FILES_HAS') }, { id: '##REASON##', value: this.props.t('MESSAGES.DOCUMENT_IN_STATUS') }, { id: '##STATUS##', value: this.props.t('DOSSIER_COMPONENTS.DOCUMENTARY_MANAGEMENT.ADD_OR_EDIT_DOCUMENT.SIGN_PENDING') }], null)
    } else {
      this.props.actions.openModal('noDocumentFleetActionReason', null, null, null, null, [{ id: '##REASON##', value: ' ' + this.props.t('MESSAGES.NO_FILE_HAS') }, { id: '##REASON##', value: this.props.t('MESSAGES.DOCUMENT_IN_STATUS') }, { id: '##STATUS##', value: this.props.t('DOSSIER_COMPONENTS.DOCUMENTARY_MANAGEMENT.ADD_OR_EDIT_DOCUMENT.SIGN_PENDING') }], null)
    }
  }

  rejectDocument () {
    const documents = JSON.parse(JSON.stringify(this.props.documentDetails.filter(dossier => dossier.checked)))

    var docsValids = []

    documents.forEach(document => {
      if (document.documentStatusId === documentStatus.PENDING || document.documentStatusId === documentStatus.VALIDATED) {
        docsValids.push(document)
      }
    })

    var entityId = null
    var vehicleId = null

    if (docsValids.length > 0) {
      entityId = this.props.documentFleet.documentTypeEntityId === entityType.ENTITY ? docsValids[0].entityId : null
      vehicleId = this.props.documentFleet.documentTypeEntityId === entityType.VEHICLE ? docsValids[0].vehicleId : null
    }

    if (docsValids.length === documents.length) {
      this.props.actions.openDocumentEditionModalFleet(fleetMode.REJECT, docsValids[0].documentId, this.props.documentFleet.documentTypeUseId, this.props.documentFleet.documentTypeEntityId, docsValids[0].dossierId, entityId, vehicleId, docsValids, this.props.fleet.organizedUnitId, null, null, true, null, this.props.fleet.dossierSubType, null, true, null, false, true, undefined, false, false, null, this.props.fleet.fleetId)
    } else if (docsValids.length > 0) {
      this.props.actions.openModal('documentFleetActionPartialReason', () => this.props.actions.openDocumentEditionModalFleet(fleetMode.REJECT, docsValids[0].documentId, this.props.documentFleet.documentTypeUseId, this.props.documentFleet.documentTypeEntityId, docsValids[0].dossierId, entityId, vehicleId, docsValids, this.props.fleet.organizedUnitId, null, null, true, null, this.props.fleet.dossierSubType, null, true, null, false, true, undefined, false, false, null, this.props.fleet.fleetId), null, null, null, [{ id: '##REASON##', value: ' ' + this.props.t('MESSAGES.NO_ALL_FILES_HAS') }, { id: '##REASON##', value: this.props.t('MESSAGES.DOCUMENT_IN_STATUS') }, { id: '##STATUS##', value: this.props.t('DOSSIER_COMPONENTS.DOCUMENTARY_MANAGEMENT.INCORPORATED') + ' ' + this.props.t('MESSAGES.OR') + ' ' + this.props.t('DOSSIER_COMPONENTS.DOCUMENTARY_MANAGEMENT.VALIDATED') }], null)
    } else {
      this.props.actions.openModal('noDocumentFleetActionReason', null, null, null, null, [{ id: '##REASON##', value: ' ' + this.props.t('MESSAGES.NO_FILE_HAS') }, { id: '##REASON##', value: this.props.t('MESSAGES.DOCUMENT_IN_STATUS') }, { id: '##STATUS##', value: this.props.t('DOSSIER_COMPONENTS.DOCUMENTARY_MANAGEMENT.INCORPORATED') + ' ' + this.props.t('MESSAGES.OR') + ' ' + this.props.t('DOSSIER_COMPONENTS.DOCUMENTARY_MANAGEMENT.VALIDATED') }], null)
    }
  }

  validateEqualStatus (documents) {
    var status = documents[0].documentStatusId
    var ok = true
    documents.forEach(document => {
      if (document.documentStatusId !== status) {
        ok = false
      }
    })
    return ok
  }

  validateStatus (documents, status) {
    var docsValids = []
    documents.forEach(document => {
      if (document.documentStatusId === status) {
        docsValids.push(document)
      }
    })

    return docsValids
  }

  validateCategory (documents, category) {
    var docsValids = []
    documents.forEach(document => {
      switch (category) {
        case documentEntityType.ENTITY:
          if (document.entityId) {
            docsValids.push(document)
          }
          break
        case documentEntityType.VEHICLE:
          if (document.vehicleId) {
            docsValids.push(document)
          }
          break
        default:
          docsValids.push(document)
      }
    })
    return docsValids
  }

  validateSameId (documents, category) {
    switch (category) {
      case documentEntityType.ENTITY:
        var resultENTITY = documents.map(x => x.entityId).filter((x, i, a) => a.indexOf(x) === i)
        if (resultENTITY.length > 1) {
          this.props.actions.openModal('documentFleetMoreThanOneEntity')
          return false
        }
        break
      case documentEntityType.VEHICLE:
        var resultVEHICLE = documents.map(x => x.vehicleId).filter((x, i, a) => a.indexOf(x) === i)
        if (resultVEHICLE.length > 1) {
          this.props.actions.openModal('documentFleetMoreThanOneVehicle')
          return false
        }
        break
      default:
        return true
    }

    return true
  }

  validateSameUo (documents) {
    var uo = documents.map(x => x.organizedUnitId).filter((x, i, a) => a.indexOf(x) === i)
    if (uo.length > 1) {
      this.props.actions.openModal('documentFleetMoreThanOneUo')
      return false
    }
    return true
  }

  openDocument (doc) {
    if (doc.documentStatusId === documentStatus.INCORPORATION_PENDING) {
      var docsValids = this.validateCategory([doc], this.props.documentFleet.documentTypeEntityId)

      if (docsValids.length === 0) {
        this.props.actions.openModal('noDocumentFleetActionReason', null, null, null, null, [{ id: '##REASON##', value: ' ' + this.props.t('MESSAGES.NO_FILE_HAS') }, { id: '##REASON##', value: this.props.documentFleet.documentTypeEntityId === documentEntityType.ENTITY ? this.props.t('MASTERS.ENTITY') : this.props.t('HEADER.VEHICLE') }], null)
        return
      }
    }

    var entityId = this.props.documentFleet.documentTypeEntityId === entityType.ENTITY ? doc.entityId : null
    var vehicleId = this.props.documentFleet.documentTypeEntityId === entityType.VEHICLE ? doc.vehicleId : null

    this.props.actions.openDocumentEditionModal(doc.documentId, this.props.documentFleet.documentTypeUseId, doc.documentParentTypeId, doc.dossierId, entityId, vehicleId, doc.organizedUnitId, doc.checkListId,
      this.props.dossierMode ? () => this.props.actions.fetchDocumentDetailsFleet(this.props.documentFleet.documentTypeUseId, null, this.props.dossierList) : () => this.props.actions.fetchDocumentDetailsFleet(this.props.documentFleet.documentTypeUseId, this.props.fleet.fleetId, null)
      , true, null, this.props.dossierSubType, null, true, null, false, true, undefined)
  }

  statusTooltip (status) {
    const statusTooltipTKey = 'DOCUMENT_SEARCH.TABLE.STATUS.'
    return (<Tooltip id="statusTooltip">{status && this.props.t(`${statusTooltipTKey}${translateDocumentStatus[status]}`)}</Tooltip>)
  }

  render () {
    const {
      t,
      showModal, handleSubmit, documentDetails, documentFleet
    } = this.props
    const tKey = 'MANAGEMENT.FLEETS_TAB.AUX_MODALS.'
    const selectedDossiers = this.props.documentDetails.filter(dossier => dossier.checked)

    let fleetDocumentActionsCombo = this.props.combos.fleetDocumentActionsCombo
    fleetDocumentActionsCombo = fleetDocumentActionsCombo.map((combo) => {
      return { ...combo, value: t(`${tKey}${combo.value}`) }
    })

    if (showModal && documentFleet) {
      return (
        <Modal className="languages-modal addDosseirToFleet-modal" show={showModal} onHide={() => this.closeModal()} backdrop={'static'}>
          <form className='addDossiersToFleetModal' autoComplete="off" onSubmit={handleSubmit(() => this.closeModal())}>
            <Modal.Header closeButton onHide={() => this.closeModal()}>
              <Modal.Title>{t(`${tKey}DOCUMENT_DETAILS_FLEET_MODAL`)}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='row row-space'>
                <div className='col-md-2 blue'>
                  {t('MANAGEMENT.FLEETS_TAB.DOCUMENT')}
                </div>
                <div className='col-md-4'>
                  {documentFleet.description}
                </div>
                <div className='col-md-2 blue'>
                  {t('MANAGEMENT.FLEETS_TAB.CATEGORY')}
                </div>
                <div className='col-md-4'>
                  {documentFleet.category}
                </div>
              </div>
              <div id="fleet-dossier-details-table">
                <div className="flexInLine al-R">
                  <Button
                    id="update_dossiers_button"
                    className="_Btn accept stk flexInLine al-R"
                    onClick = {() => this.updateList()}>
                    <i className="iDocIcon-update"></i>
                    {t('MANAGEMENT.ADMIN_AUDIT.MODAL.UPDATE')}
                  </Button>
                </div>
                <SimpleTablePage id="fleet-dossier-details-table"
                  columns={[
                    <th key={0} data-field="number" onClick={(event) => this.onOrderChange(event)}>
                      {t('MANAGEMENT.FLEETS_TAB.DOSSIER_NUMBER')}&nbsp;<i className={this.getIconClass('number')}></i>
                    </th>,
                    <th key={10} data-field="vinChassis" onClick={(event) => this.onOrderChange(event)}>
                      {t('MANAGEMENT.FLEETS_TAB.VEHICLE')}&nbsp;<i className={this.getIconClass('vehicle')}></i>
                    </th>,
                    <th key={11} data-field="model" onClick={(event) => this.onOrderChange(event)}>
                      {t('MANAGEMENT.FLEETS_TAB.MODEL')}&nbsp;<i className={this.getIconClass('model')}></i>
                    </th>,
                    <th key={1} data-field="dossierCreationDate" onClick={(event) => this.onOrderChange(event)}>
                      {t('MANAGEMENT.FLEETS_TAB.CREATION_DATE')}&nbsp;<i className={this.getIconClass('dossierCreationDate')}></i>
                    </th>,
                    <th key={2} data-field="stage" onClick={(event) => this.onOrderChange(event)}>
                      {t('MANAGEMENT.FLEETS_TAB.STAGE')}&nbsp;<i className={this.getIconClass('stage')}/>
                    </th>,
                    <th key={3} data-field="documentStatus" onClick={(event) => this.onOrderChange(event)}>
                      {t(`${tKey}DOCUMENT_STATUS`)}&nbsp;<i className={this.getIconClass('documentStatus')}></i>
                    </th>,
                    <th key={4} data-field="signStatusId" onClick={(event) => this.onOrderChange(event)}>
                      {t(`${tKey}SIGN_STATUS`)}&nbsp;<i className={this.getIconClass('signStatusId')}/>
                    </th>,
                    <th key={5} data-field="creationDate" onClick={(event) => this.onOrderChange(event)}>
                      {t(`${tKey}INCORPORATION_DATE`)}&nbsp;<i className={this.getIconClass('creationDate')}/>
                    </th>,
                    <th key={6} data-field="validationDate" onClick={(event) => this.onOrderChange(event)}>
                      {t(`${tKey}VALIDATION_DATE`)}&nbsp;<i className={this.getIconClass('validationDate')}/>
                    </th>,
                    <th key={7} data-field="signDate" onClick={(event) => this.onOrderChange(event)}>
                      {t(`${tKey}SIGN_DATE`)}&nbsp;<i className={this.getIconClass('signDate')}/>
                    </th>,
                    <th key={8} data-field="documentStatus" onClick={(event) => this.onOrderChange(event)}>
                      {t(`${tKey}DOCUMENT_STATUS`)}&nbsp;<i className={this.getIconClass('documentStatus')}/>
                    </th>,
                    <th key={9} data-field="checked" className='flet-detail-dossier-check-title' >
                      <Field
                        component={InputCheckbox}
                        name={'selectedCheck_massive'}
                        onChange={(event) => this.onCheckAll(event)}
                        checked={this.state.selectedCheck_massive}
                      />
                    </th>
                  ]}
                  rows={documentDetails && documentDetails.map((row, idx) => (
                    <tr key={idx}>
                      <td>{row.number}</td>
                      <td>{row.vinChassis}</td>
                      <td>{row.model}</td>
                      <td>{formatDate(row.dossierCreationDate, true)}</td>
                      <td>{row.stage}</td>
                      <td>{row.documentStatus}</td>
                      <td className={this.getSignStatusClass(row.signStatusId)}>{t(this.getSignStatusText(row.signStatusId))}</td>
                      <td>{formatDate(row.creationDate, true)}</td>
                      <td>{row.documentStatusId === documentStatus.VALIDATED ? (row.validationDate === null || row.validationDate === undefined) ? formatDate(row.creationDate, true) : formatDate(row.validationDate, true) : ''}</td>
                      <td>{formatDate(row.signDate, true)}</td>
                      <td className="text-left">
                        <OverlayTrigger placement="left" className="documentToolTipFleet" overlay={this.statusTooltip(row.documentStatusId)}>
                          <a onClick={() => this.openDocument(row)}>
                            <Glyphicon className={'icon-action ' + documentStatusClass[translateDocumentStatus[row.documentStatusId]]} glyph="file"/>
                          </a>
                        </OverlayTrigger>
                      </td>
                      <td>{<Field
                        component={InputCheckbox}
                        name={'selectedCheck_' + row.dossierId}
                        id={'selectedCheck_' + row.dossierId}
                        checked={row.checked ? row.checked : false}
                        onChange={(event) => this.onCheckOne(event, row.dossierId)}
                      />}
                      </td>
                    </tr>
                  ))}

                ></SimpleTablePage>
                <Row className='button-container'>
                  <Field
                    id="documentActions"
                    name='documentActions'
                    placeholder={t('MANAGEMENT.FLEETS_TAB.ACTIONS')}
                    controlLabel={t('MANAGEMENT.FLEETS_TAB.ACTIONS')}
                    component={InputSelect}
                    options={fleetDocumentActionsCombo}
                    valueKey="id"
                    labelKey="value"
                    disabled={selectedDossiers.length === 0}
                    onInputChange={(val) => { this.setState({ dataId: val }) }}

                  />
                  <Button
                    id='btn-aplicate-action-document-fleet-modal'
                    className="btn-Load btn_applicate"
                    onClick={() => this.launchAction(this.state.dataId)}
                    disabled={this.state.dataId === null || this.state.dataId === undefined || selectedDossiers.length === 0}>
                    {t(`${tKey}APPLICATE`)}
                  </Button>

                </Row>
              </div>

            </Modal.Body>
            <Modal.Footer>
              <Col sm={12} className='fleet-created-moda-btn-exit'>
                <Button
                  id='btn-close-fleet-document-select-charge-modal'
                  className="btn-exit"
                  onClick={() => this.closeModal()}>
                  {t(`${tKey}EXIT`)}
                </Button>
              </Col>
            </Modal.Footer>
          </form>
        </Modal>

      )
    } else {
      return null
    }
  }
}

export default reduxForm({
  form: 'documentDetailsFleetModal'
})(DocumentDetailsFleetModal)
