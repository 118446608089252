import React, { PureComponent } from 'react'
import { Table, Row } from 'react-bootstrap'

class Tab4 extends PureComponent {
  render () {
    return (
      <Row className="tabPane-container">
        <Table responsive className="table-tabPane">
          <thead>
            <tr>
              <th>Concepto</th>
              <th>%</th>
              <th>Importe</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Matricula</td>
              <td>0,0%</td>
              <td></td>
            </tr>
            <tr>
              <td><span>Preparación preentrega</span></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </Table>
      </Row>
    )
  }
}

export default Tab4
