import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import {
  openEditProcessingAgencyModal, openAddProcessingAgencyModal, fetchProcessingAgencyList, deleteProcessingAgency,
  openModalMass, uploadProcAgMassDocument, fetchTemplateFile, recoverProcessingAgency, openUserProcessingAgencyModal, showEditProcessingAgencyIntegrationModal
} from '../../../actions/masters/masters'
import ProcessingAgency from '../../../components/masters/processing/ProcessingAgencyMaster'

export function mapStateToProps (state) {
  return {
    ...state.processingAgency,
    combos: {
      UOTreeCombo: state.combos.UOTreeCombo
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      openEditProcessingAgencyModal,
      openAddProcessingAgencyModal,
      fetchProcessingAgencyList,
      deleteProcessingAgency,
      openModalMass,
      uploadProcAgMassDocument,
      fetchTemplateFile,
      recoverProcessingAgency,
      openUserProcessingAgencyModal,
      showEditProcessingAgencyIntegrationModal
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(ProcessingAgency))
