import React, { PureComponent } from 'react'
import { Modal, Row, Col, Button } from 'react-bootstrap'
import InputSelect from '../../commons/form/InputSelect'
import { Field, reduxForm } from 'redux-form'

class ModalChangeManager extends PureComponent {
  render () {
    return (
      <Modal className="change-manager-modal" show={false}>
        <Modal.Header closeButton>
          Seleccionar gestor
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={12}>
              <div className="text-band">
                <span className="first">Empresa:</span>
                <span className="second">Empresa</span>
              </div>
            </Col>
            <Field
              colSm={6}
              id='companyId'
              name='companyId'
              placeholder='Ubicación'
              labelKey='companyId'
              valueKey='companyId'
              component={InputSelect}
            />
            <Field
              colSm={6}
              id='companyId'
              name='companyId'
              placeholder='Ubicación'
              labelKey='companyId'
              valueKey='companyId'
              component={InputSelect}
            />
          </Row>
          <Row className="second-row">
            <Col sm={6}>
              <div className="text-band">
                <span className="first">Gestor:</span>
                <span className="second">Nombre del gestor</span>
              </div>
            </Col>
            <Col sm={6}>
              <div className="text-band">
                <span className="first">Email:</span>
                <span className="second">Email</span>
              </div>
            </Col>
            <Col sm={6}>
              <div className="text-band">
                <span className="first">Responsable:</span>
                <span className="second">Responsable</span>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <div className="button-wrapper">
            <Button className="btn-standard btn-footer">
              Realizar cambio
            </Button>
            <Button className="btn-standard-disabled btn-footer">
              Cerrar
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default reduxForm({
  form: 'form'
})(ModalChangeManager)
