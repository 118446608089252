import mastersActionTypes from '../../../constants/actions/masters/masters'

function initialState () {
  return {
    operationTypeList: [],
    rowsCount: 0,
    pagesCount: 0,
    languageList: [],
    filter: {
      text: '',
      code: '',
      page: 1,
      orderBy: '',
      pageSize: 10
    },
    operationTypeModal: {
      showModal: false,
      operationType: null
    }
  }
}

function fetchOperationTypeTableSuccess (state, { operationTypeTable, filter }) {
  return {
    ...state,
    operationTypeList: operationTypeTable.operationTypeList,
    rowsCount: operationTypeTable.rowsCount,
    pagesCount: operationTypeTable.pagesCount,
    filter
  }
}

function openOperationTypeModalSuccess (state, { operationType, languageList }) {
  const initial = initialState()
  return {
    ...state,
    operationTypeModal: {
      ...initial.operationTypeModal,
      showModal: true,
      operationType: operationType
    },
    languageList: languageList
  }
}

function closeOperationTypeModal (state) {
  return {
    ...state,
    operationTypeModal: {
      ...initialState().operationTypeModal
    }
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case mastersActionTypes.FETCH_OPERATION_TYPES_TABLE_SUCCESS:
      return fetchOperationTypeTableSuccess(state, action)
    case mastersActionTypes.OPEN_OPERATION_TYPE_MODAL_SUCCESS:
      return openOperationTypeModalSuccess(state, action)
    case mastersActionTypes.CLOSE_OPERATION_TYPE_MODAL:
      return closeOperationTypeModal(state, action)
    default:
      return state
  }
}
