import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import RoleAdmin from '../../../components/admin/role/RoleAdmin'
import { translate } from 'react-polyglot'
import { fetchRoleList, openEditRoleModal, openAddRoleModal, deleteRole, cloneRole } from '../../../actions/admin/roleAdmin'
import { fetchSectionCombo, fetchPermissionCombo } from '../../../actions/combos/combos'

export function mapStateToProps (state) {
  return {
    ...state.roleAdmin,
    combos: {
      permissionCombo: state.combos.permissionCombo,
      sectionCombo: state.combos.sectionCombo
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchRoleList,
      openEditRoleModal,
      openAddRoleModal,
      deleteRole,
      cloneRole,
      fetchSectionCombo,
      fetchPermissionCombo
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(RoleAdmin))
