export const stockVoStages = {
  entry: 172,
  reception: 173,
  dossierRating: 174,
  sendBudget: 175,
  acceptBudget: 176,
  reconditioning: 177,
  cleaning: 178,
  checklList: 179,
  bookingDelivery: 180,
  cancelled: 181,
  store: 182
}
