import apiFetch from '../apiFetch'

export default function (token, documentEntityType, dossierId, vehicleId, entityId, isHistorical = false) {
  const queryParams = [
    documentEntityType ? `documentEntityType=${documentEntityType}` : null,
    dossierId ? `dossierId=${dossierId}` : null,
    vehicleId ? `vehicleId=${vehicleId}` : null,
    entityId ? `entityId=${entityId}` : null,
    isHistorical ? `isHistorical=${isHistorical}` : null
  ].filter((item) => item !== null).join('&')
  return apiFetch({ endPoint: ('document/types') + (queryParams ? ('?' + queryParams) : ''), method: 'GET', token: token })
}
