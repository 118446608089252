import React, { PureComponent } from 'react'
import { Col, Panel, Row, Table, Tooltip, OverlayTrigger, Button, Modal } from 'react-bootstrap'
import InputDatePicker from '../../commons/form/InputDatePicker'
import { Field } from 'redux-form'
import { getFieldsPermisions } from '../../../util/permisionFunctions'
import { compareObjects, formatMoney } from '../../../util/utils'
import { permissions } from '../../../constants/backendIds'
import InputSelect from '../../commons/form/InputSelect'

class DossierRating extends PureComponent {
  constructor () {
    super()
    this.state = {
      open: false,
      showModal: false,
      dossierRatingComponent: null,
      dossierRatingRatingId: null,
      fieldsConfiguration: {
        ratings: null,
        confirmationDoneButton: null,
        confirmationDate: null
      }
    }
  }

  initializePermisions (props) {
    const fieldKeys = Object.keys(this.state.fieldsConfiguration)
    const newState = getFieldsPermisions(props.componentConfiguration.fieldsConfiguration, fieldKeys, props.componentConfiguration.permission)
    this.setState({ fieldsConfiguration: newState.fieldsConfiguration })
  }

  componentDidUpdate (prevProps) {
    if (this.props.dossier && this.props.dossier.dossierId) {
      if (!compareObjects(prevProps.componentConfiguration || {}, this.props.componentConfiguration, true)) {
        this.initializePermisions(this.props)
      }
      if (this.props.open) {
        this.setState({ open: true })
      }
      this.setState({ dossierRatingComponent: this.props.dossierRatingComponent })
    }
    return prevProps
  }

  componentDidMount () {
    if (this.props.componentConfiguration && this.props.componentConfiguration !== null) {
      this.initializePermisions(this.props)
      this.setState({ dossierRatingComponent: this.props.dossierRatingComponent })
    }
  }

  fetchSalesDossierPromiseDossierRating () {
    if (this.state.open) {
      this.props.actions.setActiveSection(false)
    }
    this.setState({
      open: !this.state.open
    })
  }

  editDossierRating (ratingId, dossierRatingRatingId) {
    this.setState({ showModal: true, dossierRatingRatingId: dossierRatingRatingId })
    this.props.actions.change('ratingId', ratingId)
  }

  submit (value) {
    return new Promise((resolve, reject) => {
      this.props.actions.addDossierRating(value.ratingId, this.state.dossierRatingRatingId, resolve, reject)
    }).then((result) => {
      this.setState({ dossierRatingComponent: result })
    })
  }

  openModal () {
    this.setState({ showModal: true, dossierRatingRatingId: null })
    this.props.actions.change('ratingId', null)
  }

  delete (id) {
    return new Promise((resolve, reject) => {
      this.props.actions.deleteDossierRating(id, resolve, reject)
    }).then((result) => {
      if (result !== null) {
        this.setState({ dossierRatingComponent: result })
      }
    })
  }

  closeModal () {
    this.setState({ showModal: false })
  }

  modal () {
    const { t, handleSubmit, combos: { ratingComboByOrganizedUnit } } = this.props
    const tKey = 'DOSSIER_COMPONENTS.DOSSIER_RATING.'
    return (
      <Modal className="role-modal languages-modal" show={this.state.showModal} backdrop={'static'} >
        <form autoComplete="off" onSubmit={handleSubmit(this.submit.bind(this))}>
          <Modal.Header closeButton onHide={() => this.closeModal()}>
            <Modal.Title>{t(`${tKey}MODAL.TITTLE`)}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row className="inputs-wrapper-no-border">
              <Col sm={12} className="custom-width-col">
                <h2>{t(`${tKey}CONCEPT`)}</h2>
                <Field
                  name="ratingId"
                  placeholder={t(`${tKey}CONCEPT`)}
                  valueKey="id"
                  labelKey="value"
                  component={InputSelect}
                  colSm={0}
                  autoComplete
                  options={ratingComboByOrganizedUnit}
                />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Col sm={1} className="btn-wrapper">
              <Button
                id="role-modal-submit-button"
                className="btn-standard"
                type="submit"
                onClick={() => this.setState({ showModal: false })}>
                {t(`${tKey}MODAL.ADD`)}
              </Button>
            </Col>
          </Modal.Footer>
        </form>
      </Modal>)
  }

  table () {
    const { t, combos: { ratingCombo } } = this.props
    const tKey = 'DOSSIER_COMPONENTS.DOSSIER_RATING.'
    const editTooltip = (<Tooltip id="editTooltip">{t(`${tKey}EDIT_BTN`)}</Tooltip>)
    const deleteTooltip = (<Tooltip id="deleteTooltip">{t(`${tKey}DELETE_BTN`)}</Tooltip>)
    return (
      this.state.dossierRatingComponent?.ratings?.map((dossierRating, idx) => {
        return (
          <tr key={idx}>
            <td>{ratingCombo.find(ra => ra.id === dossierRating.ratingId)?.value}</td>
            <td>{formatMoney(parseFloat(ratingCombo.find(ra => ra.id === dossierRating.ratingId)?.description))}</td>
            {this.permissionButon() && (
              <td>
                <a onClick={() => this.editDossierRating(dossierRating.ratingId, dossierRating.id)}>
                  <OverlayTrigger placement="left" overlay={editTooltip}>
                    <i className="ico-edit-white" />
                  </OverlayTrigger>
                </a>
                <a onClick={() => new Promise((resolve, reject) => this.delete(dossierRating.id, resolve, reject))}>
                  <OverlayTrigger placement="left" overlay={deleteTooltip}>
                    <i className="ico-trash" />
                  </OverlayTrigger>
                </a>
              </td>
            )}
          </tr>
        )
      }))
  }

  total () {
    const { t, combos: { ratingCombo } } = this.props
    const tKey = 'DOSSIER_COMPONENTS.DOSSIER_RATING.'
    const total = this.state.dossierRatingComponent?.ratings?.reduce((accumulator, currentValue) => {
      return parseFloat(accumulator) + parseFloat(ratingCombo.find(ra => ra.id === currentValue.ratingId)?.description)
    }, 0)
    return (
      <Col sm={4} className="totals-border">
        <div className="table-totals ">
          <Col sm={6} className="colums-total">
            <p><b>{t(`${tKey}TOTAL`)}:</b> <span>{formatMoney(total)}</span></p>
          </Col>
        </div>
      </Col>
    )
  }

  permissionButon () {
    return !this.props.historical && !this.props.dossierRatingComponent?.confirmationDate && this.state.fieldsConfiguration?.confirmationDoneButton && this.state.fieldsConfiguration.confirmationDoneButton.permission !== permissions.hidden
  }

  render () {
    const { t, actions: { confirmDateWithIdByStage }, dossierId } = this.props
    const tKey = 'DOSSIER_COMPONENTS.DOSSIER_RATING.'
    return (
      <Row className='dossierRating-panel dossier-panel'>
        <div
          className='header-panel' onClick={this.fetchSalesDossierPromiseDossierRating.bind(this)}
        >
          <div className='name-wrapper'>
            <i className='ico-i' />
            <h4>{t(`${tKey}TITLE_PANEL`)}</h4>
          </div>
          <i
            className={
              this.state.open ? 'ico-arrow-black-up' : 'ico-arrow-black-down'
            }
          />
        </div>
        <Panel expanded={this.state.open} onToggle={() => {}}>
          <Panel.Collapse>
            {
              this.permissionButon() && (
                <Row>
                  <Col className="col-right">
                    <Button className='btn-save' onClick={() => confirmDateWithIdByStage(dossierId)} disabled={this.state.fieldsConfiguration.confirmationDoneButton.permission !== permissions.editable}>
                      {t(`${tKey}DONE_BUTTON`)}
                    </Button>
                  </Col>
                </Row>
              )
            }
            {
              this.state.fieldsConfiguration.ratings && this.state.fieldsConfiguration.ratings.permission !== permissions.hidden && (
                <>
                  <br/>
                  <Col sm={12}>
                    <Table className="editable-table" hover>
                      <thead>
                        <tr>
                          <th key={'concept'} style={{ width: '80%' }}>{t(`${tKey}CONCEPT`)}</th>
                          <th key={'import'}>{t(`${tKey}IMPORT`)}</th>
                          {this.permissionButon() &&
                            <th key={'actions'}>{t(`${tKey}ACTIONS`)}</th>
                          }
                        </tr>
                      </thead>
                      <tbody>
                        {this.table()}
                      </tbody>
                    </Table>
                  </Col>
                  <Col sm={8}>
                    <div className="button-table-wrapper">
                      {this.permissionButon() && (
                        <Button
                          className="btn-editable-table btn-standard"
                          id="add-button"
                          onClick={() => this.openModal()}
                        >+ {t(`${tKey}BTN_ADD_DOSSIER_RATING`)}</Button>
                      )}
                    </div>
                  </Col>
                  {this.total()}
                </>
              )}
            <Col>
              <br/>
              {
                this.state.fieldsConfiguration.confirmationDate && this.state.fieldsConfiguration.confirmationDate.permission !== permissions.hidden && (
                  <Row>
                    { (
                      <Field
                        id="confirmationDate"
                        key="confirmationDate"
                        name="dossierRatingComponent.confirmationDate"
                        colSm={4}
                        controlLabel={t(`${tKey}CONFIRM_DATE`)}
                        placeholder={t(`${tKey}CONFIRM_DATE`)}
                        disabled={true}
                        component={InputDatePicker}
                      />
                    )}
                  </Row>
                )}
            </Col>
            { this.modal()}
          </Panel.Collapse>
        </Panel>
      </Row>
    )
  }
}
export default DossierRating
