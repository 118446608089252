import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import SearchByEntityForm from '../../../components/search/SearchByEntityForm'
import { translate } from 'react-polyglot'
import { fetchEntityTypes, fetchEntitySubTypeCombo, fetchEntityFilterSubTypeCombo } from '../../../actions/combos/combos'

export function mapStateToProps (state) {
  return {
    filters: state.search.dynamicFilters.entity,
    combos: {
      entityTypeCombo: state.combos.entityTypeCombo,
      entitySubTypeCombo: state.combos.entitySubTypeCombo,
      entityFilterSubTypeCombo: state.combos.entityFilterSubTypeCombo
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchEntityTypes,
      fetchEntitySubTypeCombo,
      fetchEntityFilterSubTypeCombo
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(SearchByEntityForm))
