import { put, select, takeEvery } from 'redux-saga/effects'
import { getAuth } from '../../selectors/access/auth'
import commonActionTypes from '../../constants/actions/common'
import { setCurrentSection } from '../../actions/common'

export function * setCurrentSectionByPath ({ sectionCode }) {
  const auth = yield select(getAuth)
  const sections = auth.sections.filter(sec => sec.code === sectionCode)
  if (sections && sections.length > 0) {
    yield put(setCurrentSection(sections[0]))
  }
}

export function * watchsetCurrentSectionByPath () {
  yield takeEvery(commonActionTypes.SET_SECTION_BY_PATH, setCurrentSectionByPath)
}
