import React, { PureComponent } from 'react'
import { Row, Tab, Tabs, Col, Button } from 'react-bootstrap'
import Tab1 from './TabPane10Tabs/Tab1'
import Tab2 from './TabPane10Tabs/Tab2'
import Tab3 from './TabPane10Tabs/Tab3'

class TabPane10 extends PureComponent {
  render () {
    return (
      <Col sm={12} className="delivery-tabPane">
        <Row>
          <div className="button-wrapper">
            <Button className="btn-standard-white">
              <i className="ico-plus" /> Alta entrega
            </Button>
            <Button className="btn-standard-white">
              Cita de entrega
            </Button>
          </div>
        </Row>
        <Row>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Estado entrega:</span>
              <span className="second">Estado entrega</span>
            </div>
          </Col>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Fecha entrega:</span>
              <span className="second">Fecha entrega</span>
            </div>
          </Col>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Ubicación entrega:</span>
              <span className="second">Ubicación entrega</span>
            </div>
          </Col>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Box entrega:</span>
              <span className="second">Box entrega</span>
            </div>
          </Col>
        </Row>
        <Row className="tabs-wrapper">
          <Tabs id='tab-pane-1' defaultActiveKey={1} className="sub-tabs">
            <Tab eventKey={1} title="Documentos vehículo">
              <Tab1/>
            </Tab>
            <Tab eventKey={2} title="Contratos">
              <Tab2/>
            </Tab>
            <Tab eventKey={3} title="Certificados homologación">
              <Tab3/>
            </Tab>
          </Tabs>
        </Row>
      </Col>
    )
  }
}

export default TabPane10
