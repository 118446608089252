import { put, call, delay } from 'redux-saga/effects'
import { replace } from 'connected-react-router'
import messageModalActions from '../../actions/modals/messageModal'
import { hideLoader } from '../../actions/common.js'
import { yesNoModal } from '../modalsListeners/yesNoModal'

export function * handleError ({ error, customHandler, sessionHandler, others }) {
  if (error.status === 401 && error.json.tag === 'tokenExpired') {
    yield put(hideLoader())
    yield call(yesNoModal, error.json.tag)
    if (sessionHandler) {
      yield call(sessionHandler, error)
    }
    yield delay(100)
    yield put(replace('/login'))
  } else if (customHandler) {
    yield call(customHandler, error)
  } else {
    yield put(messageModalActions.openErrorMessageModal('', error.json ? error.json.tag : '', '', error.json ? error.json.errorCode : '', others))
  }
}
