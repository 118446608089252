import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { closeCustomFieldModal, submitCustomField } from '../../../actions/masters/customField/customField'
import CustomFieldModal from '../../../components/masters/customFields/CustomFieldModal'

export function mapStateToProps (state) {
  const customFieldModal = state.customField.customFieldModal
  return {
    showModal: customFieldModal.showModal,
    customField: customFieldModal.customField,
    languageList: customFieldModal.languageList,
    combos: {
      customFieldTypes: state.combos.customFieldTypesCombo,
      viewComponents: customFieldModal.viewComponents,
      dossierSubtypes: state.combos.dossierSubTypesCombo
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      submitCustomField,
      closeCustomFieldModal
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(CustomFieldModal))
