import React, { PureComponent } from 'react'
import { Button, Modal, Col } from 'react-bootstrap'
import { reduxForm } from 'redux-form'
import Delivery from '../../dossiers/common/Delivery'
import { validateDelivery } from '../../../util/validationFunctions'

class DataDeliveryFleetModal extends PureComponent {
  constructor () {
    super()
    this.state = {}
  }

  saveData (values) {
    let dossierDeliveryComponent = values?.dossierCompleteDeliveryComponent?.dossierDeliveryComponent

    if (this.haveValues(dossierDeliveryComponent)) {
      let data = { dossierDeliveryComponent: dossierDeliveryComponent, dossierList: this.props.dossiers.map(x => { return x.dossierId }) }
      this.props.actions.setOperateDataDeliveryFleetProcess(data, this.props.fleet.fleetId, () => this.props.actions.closeDataDeliveryFleetModal())
    } else {
      this.props.actions.openModal('notChangesToSave')
    }
  }

  haveValues (fields) {
    for (let key in fields) {
      if (fields[key] !== null && fields[key] !== undefined && fields[key] !== '' && fields[key] !== false) {
        if (this.isObject(fields[key])) {
          return this.haveValues(fields[key])
        } else {
          return true
        }
      }
    }
    return false
  }

  isObject (val) {
    return val instanceof Object
  }

  componentDidUpdate () {}

  getSnapshotBeforeUpdate (prevProps) {
    if (this.props.showModal && !prevProps.showModal) {
      this.props.initialize()
      this.props.actions.fetchOperateDataFleetModalsPermissions(this.props.fleet.dossierSubType, this.props.dossier.organizedUnitId, this.props.dossier.stageId)
    }

    if (this.props.showModal && !prevProps.showModal && (this.props.combos.tuneUpDestinationCombo === undefined || this.props.combos.tuneUpDestinationCombo === null || this.props.combos.tuneUpDestinationCombo.length === 0)) {
      this.props.actions.fetchTuneUpDestination()
    }

    return null
  }

  handleToUpdateComponent () {}
  setActiveSection () {}
  render () {
    const {
      t,
      showModal, handleSubmit, actions: {
        closeDataDeliveryFleetModal
      }, componentConfiguration, combos
    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.BUTTONS_SIDEBAR.'

    if (showModal && componentConfiguration) {
      return (
        <Modal className="fleet-created-select-modal languages-modal" show={showModal} onHide={closeDataDeliveryFleetModal} backdrop={'static'}>
          <form className='formDataSalesFleet' autoComplete="off" onSubmit={handleSubmit((values) => this.saveData(values))}>
            <Modal.Header closeButton onHide={this.props.actions.closeDataDeliveryFleetModal}>
              <Modal.Title>  <i className="ico-delivery" />{t(`${tKey}DELIVERY`)}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Delivery
                t={t}
                handleToUpdateComponent = {this.handleToUpdateComponent.bind(this)}
                isDeliveryLoaded = {true}
                dossier={null}
                pristine={this.props.pristine}
                isSalesDossier={true}
                dossierId={-1}
                dossierCompleteDeliveryCheckListItems={[]}
                dossierDeliveryCheckListPurchase={[]}
                actions={{
                  fetchSalesDelivery: () => {},
                  printDeliveryChecklist: () => { null },
                  printChecklistVO: () => { null },
                  setActiveSection: this.setActiveSection.bind(this),
                  printDeliveryCertificates: () => { null },
                  printDeliveryChecklistSale: () => { null }
                }}
                readOnlyFields={false}
                open={true}
                dossierSubTypeId={this.props.fleet.dossierSubTypeId}
                printDeliveryChecklistPermission={() => { null }}
                componentConfiguration={this.props.componentConfiguration}
                combos={combos}
                isFleet={true}
              />
            </Modal.Body>
            <Modal.Footer>
              <Col sm={12} className='fleet-created-moda-btn-exit'>
                <Button
                  id="btn-save-delivery-fleet-modal"
                  className="btn-standard"
                  type='submit'
                  disabled={this.props.pristine}>
                  {t('MANAGEMENT.FLEETS_TAB.AUX_MODALS.SAVE')}
                </Button>
                <Button
                  id='btn-cancel-delivery-fleet-modal'
                  className="btn-exit"
                  onClick={() => closeDataDeliveryFleetModal()}>
                  {t('MANAGEMENT.FLEETS_TAB.AUX_MODALS.EXIT')}
                </Button>
              </Col>
            </Modal.Footer>
          </form>
        </Modal>

      )
    } else {
      return null
    }
  }
}

const validate = (values, props) => {
  const errors = { dossierCompleteDeliveryComponent: {} }
  if (Object.keys(values).length > 0) {
    let dossierDeliveryComponent = {}
    dossierDeliveryComponent = validateDelivery(values.dossierCompleteDeliveryComponent.dossierDeliveryComponent, props, props.fleet.dossierSubTypeId)

    if (Object.keys(dossierDeliveryComponent).length > 0) {
      errors.dossierCompleteDeliveryComponent = { dossierDeliveryComponent }
    }
  }
  return errors
}

export default reduxForm({
  form: 'dataDeliveryFleetModal',
  validate
})(DataDeliveryFleetModal)
