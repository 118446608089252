import React, { PureComponent } from 'react'
import { Row, Button, Modal, Col, Glyphicon } from 'react-bootstrap'
import { reduxForm } from 'redux-form'

class FleetCreatedSelectChargeModal extends PureComponent {
  constructor () {
    super()
    this.state = {}
  }

  render () {
    const {
      t,
      showModal, handleSubmit, actions: { closeFleetCreatedSelectChargeModal, fetchPaymentCombo, fetchVehicleType, openChargeDossiersModal, openAddDossiersToFleetModal }, fleet
    } = this.props
    const tKey = 'MANAGEMENT.FLEETS_TAB.AUX_MODALS.'

    if (showModal && fleet) {
      let message = t(`${tKey}FLEET_CREATED_SELECT_CHARGE_MODAL`)
      message = message.replace('##FLET_CODE##', fleet.fleetCode)
      message = message.replace('##FLET_DESCRIPTION##', fleet.description)

      return (
        <Modal className="custom-modal fleet-created-select-modal languages-modal" show={showModal} onHide={closeFleetCreatedSelectChargeModal} backdrop={'static'}>
          <form className='formNewFleet' autoComplete="off" onSubmit={handleSubmit(() => closeFleetCreatedSelectChargeModal())}>

            <Modal.Body>
              <Row>
                <p className='fleet-created-text'>  { message.split('\n').map((item, key) => {
                  return <span key={key}>{item}<br /></span>
                })}</p>
              </Row>
              <Row>
                <Col sm={12}>
                  <Button
                    id="btn-select-dossier-fleet"
                    className="fleet-created-moda-btn-select btn-standard"
                    onClick={() => {
                      closeFleetCreatedSelectChargeModal()
                      fetchPaymentCombo(false)
                      fetchVehicleType()
                      openChargeDossiersModal(fleet)
                    }}
                  >
                    {t(`${tKey}SELECT`)}
                  </Button>
                  <Button
                    id="btn-charge-file-dossier-fleet"
                    className="btn-standard"
                    onClick = {() => { closeFleetCreatedSelectChargeModal(); openAddDossiersToFleetModal(fleet) }}>
                    <Glyphicon glyph='folder-open' className='fleet-created-modal-gly-charge'></Glyphicon>
                    {t(`${tKey}CHARGE`)}
                  </Button>
                </Col>

              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Col sm={12} className='fleet-created-moda-btn-exit'>
                <Button
                  id='btn-close-fleet-created-select-charge-modal'
                  className="btn-exit"
                  onClick={() => { closeFleetCreatedSelectChargeModal(); this.props.fetchFleets() }}>
                  {t(`${tKey}EXIT`)}
                </Button>
              </Col>
            </Modal.Footer>
          </form>
        </Modal>

      )
    } else {
      return null
    }
  }
}

export default reduxForm({
  form: 'fleetCreatedSelectChargeModal'
})(FleetCreatedSelectChargeModal)
