export function getObjectWithLocales (object, objectLocales, languageList) {
  if (!object) object = {}
  for (const language in languageList) {
    object[`lang${languageList[language].languageId}`] = {}
    object[`lang${languageList[language].languageId}`].languageId = languageList[language].languageId
    object[`lang${languageList[language].languageId}`].name = objectLocales?.find(x => x.languageId === languageList[language].languageId)?.name || ''
    object[`lang${languageList[language].languageId}`].description = objectLocales?.find(x => x.languageId === languageList[language].languageId)?.description || ''
  }
  return object
}
