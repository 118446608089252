import apiFetch from '../apiFetch'

export default function (token,
  {
    page, pageSize, orderBy, brand, model, chassis, licensePlate, commercialCode,
    licensePlateDateFrom, licensePlateDateTo, dealerNumber, carlineId, catalogueId, modelYearId, externalColorId, internalColorId,
    modelYear, carline, catalogue, externalColor, internalColor
  }) {
  const queryParamsArray = [
    page ? `page=${page}` : null,
    pageSize ? `pageSize=${pageSize}` : null,
    orderBy ? `orderBy=${orderBy}` : null,
    brand ? `vehicleBrandId=${brand}` : null,
    model ? `vehicleModel=${model}` : null,
    chassis ? `vehicleVinChasis=${chassis}` : null,
    licensePlate ? `vehicleLicensePlate=${licensePlate}` : null,
    commercialCode ? `comercialCode=${commercialCode}` : null,
    licensePlateDateFrom ? `licensePlateDateFrom=${licensePlateDateFrom}` : null,
    licensePlateDateTo ? `licensePlateDateTo=${licensePlateDateTo}` : null,
    dealerNumber ? `dealerNumber=${dealerNumber}` : null,
    carlineId ? `carlineId=${carlineId}` : null,
    catalogueId ? `catalogueId=${catalogueId}` : null,
    modelYearId ? `modelYearId=${modelYearId}` : null,
    externalColorId ? `colorId=${externalColorId}` : null,
    internalColorId ? `tapestryId=${internalColorId}` : null,
    modelYear ? `modelYear=${modelYear}` : null,
    carline ? `carline=${carline}` : null,
    catalogue ? `catalogue=${catalogue}` : null,
    externalColor ? `color=${externalColor}` : null,
    internalColor ? `tapestry=${internalColor}` : null
  ]

  const queryParams = queryParamsArray.reduce(
    (result, query, idx) => result + (query ? (result ? `&${query}` : `?${query}`) : '')
    , ''
  )
  return apiFetch({
    endPoint: `dossierFinder/Vehicles${queryParams}`,
    method: 'GET',
    body: null,
    token: token
  })
}
