import React, { PureComponent } from 'react'
import { Col, Panel, Row, Button, Table, Tooltip, OverlayTrigger } from 'react-bootstrap'
import InputDatePicker from '../../commons/form/InputDatePicker'
import { Field } from 'redux-form'
import { getFieldsPermisions } from '../../../util/permisionFunctions'
import { compareObjects } from '../../../util/utils'
import moment from 'moment'
import InputSliderButton from '../../../components/commons/form/InputSliderButton'
import { dossierSubTypes, permissions } from '../../../constants/backendIds'
import { documentEntityType } from '../../../constants/dossier/common/documentEntityType'
import { documenTypeUseCodes } from '../../../constants/document/documentTypeUse'

class Photographs extends PureComponent {
  constructor () {
    super()
    this.state = {
      open: false,
      fieldsConfiguration: {
        validateButton: null,
        updateButton: null,
        publishButton: null,
        vehicleReserved: null,
        confirmationDate: null,
        acceptanceDate: null,
        publishDate: null
      },
      dossierPhotosComponent: null,
      visibleSkip: false
    }
  }

  initializePermisions (props) {
    const fieldKeys = Object.keys(this.state.fieldsConfiguration)
    const newState = getFieldsPermisions(props.componentConfiguration.fieldsConfiguration, fieldKeys, props.componentConfiguration.permission)
    this.setState({ fieldsConfiguration: newState.fieldsConfiguration })
  }

  componentDidUpdate (prevProps) {
    if (this.props.dossier && this.props.dossier.dossierId) {
      if (!compareObjects(prevProps.componentConfiguration || {}, this.props.componentConfiguration, true)) {
        this.initializePermisions(this.props)
      }
      if (this.props.open) {
        this.setState({ open: true })
      }
    }
    return prevProps
  }

  componentDidMount () {
    if (this.props.componentConfiguration && this.props.componentConfiguration !== null) {
      this.initializePermisions(this.props)
    }
    this.setState({ visibleSkip: this.props.dossier?.dossierPhotosComponent?.vehicleReserved === 2 })
  }

  permissionButon () {
    return !this.props.historical && isNaN((moment(this.props.dossier?.dossierPhotosComponent?.confirmationDate, 'DD/MM/YYYY').toDate())) && this.state.fieldsConfiguration?.confirmationDoneButton && this.state.fieldsConfiguration.confirmationDoneButton.permission !== permissions.hidden
  }

  fetchSalesDossierPromisePhotographs () {
    if (this.state.open) {
      this.props.actions.setActiveSection(false)
    }
    this.setState({
      open: !this.state.open
    })
  }

  cancelPhoto (documentId, dossierId) {
    this.props.actions.cancelDocument(16, documentId, null, dossierId, null, null)
  }

  hasPhotos () {
    return this.props.dossier?.dossierPhotosComponent?.photosComponent !== undefined && this.props.dossier?.dossierPhotosComponent?.photosComponent !== null && this.props.dossier?.dossierPhotosComponent?.photosComponent?.length > 0
  }

  table () {
    const { t, dossier } = this.props
    const tKey = 'DOSSIER_COMPONENTS.PHOTOGRAPHS.'
    const editTooltip = (<Tooltip id="editTooltip">{t(`${tKey}EDIT`)}</Tooltip>)
    const deleteTooltip = (<Tooltip id="deleteTooltip">{t(`${tKey}DELETE`)}</Tooltip>)

    return (
      dossier?.dossierPhotosComponent?.photosComponent?.map((photos, idx) => {
        return (
          <tr key={idx}>
            <td>{photos.name}</td>
            <td>{photos.description}</td>
            <td>
              <a onClick={() => this.editPhoto(photos)}>
                <OverlayTrigger placement="left" overlay={editTooltip}>
                  <i className="ico-edit-white" />
                </OverlayTrigger>
              </a>
              {this.updatePhotos() && (
                <a onClick={() => this.cancelPhoto(photos.documentId, dossier.dossierId)}>
                  <OverlayTrigger placement="left" overlay={deleteTooltip}>
                    <i className="ico-trash" />
                  </OverlayTrigger>
                </a>
              )}
            </td>
          </tr>
        )
      }))
  }

  editPhoto (photos) {
    this.props.actions.openDocumentEditionModal(photos.documentId, null, documentEntityType.DOSSIER, this.props.dossier.dossierId, null, null, this.props.dossier.organizedUnitId, null, null, null, dossierSubTypes.stock, null, null, null, null, false, this.updatePhotos(), null, null, null, null, null, null, null, documenTypeUseCodes.FOTOS_DOCS_STOCK)
  }

  handleOpenDocumentCreationModal () {
    this.props.actions.openDocumentEditionModal(null, null, documentEntityType.DOSSIER, this.props.dossier.dossierId, null, null, this.props.dossier.organizedUnitId, null, null, null, dossierSubTypes.stock, null, null, null, null, false, true, null, null, null, null, null, null, null, documenTypeUseCodes.FOTOS_DOCS_STOCK)
  }

  ChangeInputSlider (value) {
    this.setState({ visibleSkip: value === 2 })
  }

  updatePhotos () {
    return this.state.fieldsConfiguration.updateButton && this.state.fieldsConfiguration.updateButton.permission !== permissions.hidden && !this.props.isHistorical && !this.props?.dossier?.dossierPhotosComponent?.isConfirmed
  }

  render () {
    const { t, dossier, isHistorical } = this.props
    const tKey = 'DOSSIER_COMPONENTS.PHOTOGRAPHS.'
    return (
      <Row className='photographs-panel dossier-panel'>
        <div
          className='header-panel' onClick={this.fetchSalesDossierPromisePhotographs.bind(this)}
        >
          <div className='name-wrapper'>
            <i className='ico-i' />
            <h4>{t(`${tKey}TITLE_PANEL`)}</h4>
          </div>
          <i
            className={
              this.state.open ? 'ico-arrow-black-up' : 'ico-arrow-black-down'
            }
          />
        </div>
        <Panel expanded={this.state.open} onToggle={() => {}}>
          <Panel.Collapse>
            <Col>
              <br/>
              <Row>
                {this.state.fieldsConfiguration.vehicleReserved && this.state.fieldsConfiguration.vehicleReserved.permission !== permissions.hidden && (
                  <Field
                    id="vehicleReserved"
                    name="dossierPhotosComponent.vehicleReserved"
                    colSm={3}
                    controlLabel={t(`${tKey}RERSERVED_VEHICLE`)}
                    placeholder={t(`${tKey}RERSERVED_VEHICLE`)}
                    component={InputSliderButton}
                    textDescription={t(`${tKey}RERSERVED_VEHICLE`)}
                    customClass={'three-rc-select'}
                    defaultChoice={dossier?.dossierPhotosComponent?.vehicleReserved ? dossier?.dossierPhotosComponent?.vehicleReserved : null}
                    onChange={(value) => this.ChangeInputSlider(value)}
                    disabled={true}
                  />
                )}
                {this.state.visibleSkip && !dossier?.dossierPhotosComponent?.isPusblish && !isHistorical && (
                  <Button className='btn-save' onClick={() => this.props.actions.skipDossierPhotos(dossier.dossierId)}>
                    {t(`${tKey}BTN_SKIP_PHOTOS`)}
                  </Button>
                )}
              </Row>
              <Row>
                {
                  this.state.fieldsConfiguration.publishDate && this.state.fieldsConfiguration.publishDate.permission !== permissions.hidden && (
                    <Field
                      id="publishDate"
                      name="dossierPhotosComponent.publishDate"
                      colSm={4}
                      controlLabel={t(`${tKey}PUBLISH_DATE`)}
                      placeholder={t(`${tKey}PUBLISH_DATE`)}
                      disabled={true}
                      component={InputDatePicker}
                    />
                  )}
                {
                  this.state.fieldsConfiguration.confirmationDate && this.state.fieldsConfiguration.confirmationDate.permission !== permissions.hidden && (
                    <Field
                      id="confirmationDate"
                      name="dossierPhotosComponent.confirmationDate"
                      colSm={4}
                      controlLabel={t(`${tKey}CONFIRM_DATE`)}
                      placeholder={t(`${tKey}CONFIRM_DATE`)}
                      disabled={true}
                      component={InputDatePicker}
                    />
                  )}
                {
                  this.state.fieldsConfiguration.acceptanceDate && this.state.fieldsConfiguration.acceptanceDate.permission !== permissions.hidden && (
                    <Field
                      id="acceptanceDate"
                      name="dossierPhotosComponent.acceptanceDate"
                      colSm={4}
                      controlLabel={t(`${tKey}ACEPT_DATE`)}
                      placeholder={t(`${tKey}ACEPT_DATE`)}
                      disabled={true}
                      component={InputDatePicker}
                    />
                  )}
              </Row>
              <Row>
                <Col sm={12}>
                  <Table className="editable-table" hover>
                    <thead>
                      <tr>
                        <th key={'concept'}>{t(`${tKey}NAME_FILE`)}</th>
                        <th key={'import'} style={{ width: '80%' }}>{t(`${tKey}DESCRIPTION`)}</th>
                        <th key={'actions'}>{t(`${tKey}ACTIONS`)}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.table()}
                    </tbody>
                  </Table>
                </Col>
              </Row>
              <Row>
                { this.updatePhotos() && (
                  <Button className='btn-save'
                    onClick={() => this.handleOpenDocumentCreationModal()}
                    disabled={this.props?.dossier?.dossierPhotosComponent?.isConfirmed || this.state.fieldsConfiguration.updateButton.permission !== permissions.editable}>
                    {t(`${tKey}BTN_LOAD_PHOTO`)}
                  </Button>
                )}
                { this.state.fieldsConfiguration.updateButton && this.state.fieldsConfiguration.updateButton.permission !== permissions.hidden && !isHistorical && (
                  <Button className='btn-save'
                    onClick={() => this.props.actions.confirmPhotos(dossier.dossierId, 'CONFIRM_PHOTO', true)}
                    disabled={this.props?.dossier?.dossierPhotosComponent?.isConfirmed || this.state.fieldsConfiguration.updateButton.permission !== permissions.editable || !this.hasPhotos()}>
                    {t(`${tKey}BTN_CONFIRM_PHOTO`)}
                  </Button>
                )}
                {this.state.fieldsConfiguration.validateButton && this.state.fieldsConfiguration.validateButton.permission !== permissions.hidden && !isHistorical && (
                  <Button className='btn-save'
                    onClick={() => this.props.actions.confirmPhotos(dossier.dossierId, 'ACEPT_PHOTO', true)}
                    disabled={this.props?.dossier?.dossierPhotosComponent?.isAccepted || !this.props?.dossier?.dossierPhotosComponent?.isConfirmed}>
                    {t(`${tKey}BTN_ACEPT_PHOTO`)}
                  </Button>
                )}
                {this.state.fieldsConfiguration.validateButton && this.state.fieldsConfiguration.validateButton.permission !== permissions.hidden && !isHistorical && (
                  <Button className='btn-danger'
                    onClick={() => this.props.actions.confirmPhotos(dossier.dossierId, 'ACEPT_PHOTO', false)}
                    style={{ fontSize: '10px', margin: '0 5px 0 0' }}
                    disabled={this.props?.dossier?.dossierPhotosComponent?.isAccepted || !this.props?.dossier?.dossierPhotosComponent?.isConfirmed}>
                    {t(`${tKey}BTN_REJECT_PHOTO`)}
                  </Button>
                )}
                {this.state.fieldsConfiguration.publishButton && this.state.fieldsConfiguration.publishButton.permission !== permissions.hidden && !isHistorical && (
                  <Button className='btn-save'
                    onClick={() => this.props.actions.confirmPhotos(dossier.dossierId, 'PUBLISH_PHOTO', true)}
                    disabled={!this.props?.dossier?.dossierPhotosComponent?.isAccepted || this.props?.dossier?.dossierPhotosComponent?.isPusblish}>
                    {t(`${tKey}PUBLISH_PHOTO`)}
                  </Button>
                )}
                {this.state.fieldsConfiguration.publishButton && this.state.fieldsConfiguration.publishButton.permission !== permissions.hidden && !isHistorical && (
                  <Button className='btn-danger'
                    style={{ fontSize: '10px', margin: '0 5px 0 0' }}
                    onClick={() => this.props.actions.confirmPhotos(dossier.dossierId, 'PUBLISH_PHOTO', false)}
                    disabled={!this.props?.dossier?.dossierPhotosComponent?.isAccepted || this.props?.dossier?.dossierPhotosComponent?.isPusblish}>
                    {t(`${tKey}BTN_REJECT_PUBLISH`)}
                  </Button>
                )}
              </Row>
            </Col>
          </Panel.Collapse>
        </Panel>
      </Row>
    )
  }
}
export default Photographs
