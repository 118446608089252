
import React, { PureComponent } from 'react'
import { Field } from 'redux-form'
import { Clearfix } from 'react-bootstrap'
import InputText from '../../commons/form/InputText'
import InputSelect from '../../commons/form/InputSelect'
import { permissions } from '../../../constants/backendIds'
import vehicleCondition from '../../../constants/vehicleCondition'
import { getFieldsSectionPermissions } from '../../../util/permisionFunctions'

class AdditionalSearchFieldsByVehicleTypeOperation extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      fieldsConfiguration: {
        carline: null,
        catalogue: null,
        model: null,
        externalColor: null,
        internalColor: null
      }
    }
  }

  componentDidUpdate (prevProps) {
    const prevVehicleStatusSelected = prevProps.fields.vehicleTypeOperation === vehicleCondition.NEW || prevProps.fields.vehicleTypeOperation === vehicleCondition.NEARLY_NEW
    if (this.props.fields.vehicleTypeOperation !== prevProps.fields.vehicleTypeOperation) {
      this.props.actions.resetCatalogueCombo()
      this.props.actions.resetColorCombo()
      this.props.actions.resetTapestryCombo()
      if (!prevVehicleStatusSelected) {
        this.props.change('vehicle.carlineId', null)
        this.props.change('vehicle.carline', null)
        this.props.change('vehicle.catalogueId', null)
        this.props.change('vehicle.catalogue', null)
        this.props.change('vehicle.modelYearId', null)
        this.props.change('vehicle.modelYear', null)
        this.props.change('vehicle.colorId', null)
        this.props.change('vehicle.color', null)
        this.props.change('vehicle.tapestry', null)
        this.props.change('vehicle.tapestryId', null)
      } else if (prevVehicleStatusSelected && this.props.fields.vehicleCondition === vehicleCondition.NEW) {
        this.props.change('vehicle.carline', null)
        this.props.change('vehicle.catalogue', null)
        this.props.change('vehicle.modelYear', null)
        this.props.change('vehicle.color', null)
        this.props.change('vehicle.tapestry', null)
      } else if (prevVehicleStatusSelected && this.props.fields.vehicleCondition === vehicleCondition.NEARLY_NEW) {
        this.props.change('vehicle.carlineId', null)
        this.props.change('vehicle.catalogueId', null)
        this.props.change('vehicle.modelYearId', null)
        this.props.change('vehicle.colorId', null)
        this.props.change('vehicle.tapestryId', null)
      }
    }
  }

  componentDidMount () {
    this.props.actions.fetchModelCombo()

    if (this.props.permissions && this.props.permissions.sectionFieldsConfiguration) {
      const fieldKeys = Object.keys(this.state.fieldsConfiguration)
      const permission = getFieldsSectionPermissions(this.props.permissions.sectionFieldsConfiguration, fieldKeys, permissions.hidden)
      this.setState({ fieldsConfiguration: { ...permission.fieldsConfiguration } })
    }
  }

  onCarlineIdChanges (carlineId) {
    this.props.actions.resetColorCombo()
    this.props.actions.resetTapestryCombo()
    if (carlineId) {
      this.props.actions.fetchCatalogueCombo(carlineId)
    }
  }

  onCatalogueIdChanges (catalogueId) {
    if (this.props.fields.modelId && catalogueId) {
      this.props.actions.fetchPurchaseCatalogueModel(catalogueId, this.props.fields.modelId)
      this.props.actions.fetchColorCombo(catalogueId, this.props.fields.modelId)
      this.props.actions.fetchTapestryCombo(catalogueId, this.props.fields.modelId)
    }
  }

  onModelIdChanges (modelId) {
    if (modelId && this.props.fields.catalogueId) {
      this.props.actions.fetchPurchaseCatalogueModel(this.props.fields.catalogueId, modelId)
      this.props.actions.fetchColorCombo(this.props.fields.catalogueId, modelId)
      this.props.actions.fetchTapestryCombo(this.props.fields.catalogueId, modelId)
    }
  }

  render () {
    const {
      t, combos: { carlineCombo, catalogueCombo, modelCombo, colorCombo, tapestryCombo }, fieldFill
    } = this.props
    const vehicleStatusSelected = this.props.fields.vehicleCondition === vehicleCondition.NEW || this.props.fields.vehicleCondition === vehicleCondition.NEARLY_NEW
    const vehicleIsNew = vehicleCondition.NEW === this.props.fields.vehicleCondition
    const tKey = 'SEARCH.CATEGORIES.VEHICLE.'
    return (vehicleStatusSelected
      ? <div>
        <h3 className="search-title">{vehicleIsNew ? t(`${tKey}TITLE_NEW`) : t(`${tKey}TITLE_NEARLY_NEW`)}</h3>
        <Clearfix />
        {this.state.fieldsConfiguration.carline && this.state.fieldsConfiguration.carline.permission > permissions.hidden &&
        (vehicleIsNew ? <Field
          colSm={3}
          id="carlineId"
          name='vehicle.carlineId'
          placeholder={t(`${tKey}CARLINE`)}
          controlLabel={t(`${tKey}CARLINE`)}
          component={InputSelect}
          options={carlineCombo}
          valueKey="id"
          labelKey="value"
          disabled={!carlineCombo || carlineCombo.length === 0}
          onInputChange={(val) => { fieldFill({ carline: null, carlineId: val }); this.onCarlineIdChanges(val) }}
        /> : <Field
          colSm={3}
          id="carline"
          name='vehicle.carline'
          placeholder={t(`${tKey}CARLINE`)}
          controlLabel={t(`${tKey}CARLINE`)}
          component={InputText}
          type="text"
          onInputChange={(val) => fieldFill({ carlineId: null, carline: val })}
        />
        )
        }
        {this.state.fieldsConfiguration.catalogue && this.state.fieldsConfiguration.catalogue.permission > permissions.hidden &&
        (vehicleIsNew ? <Field
          colSm={3}
          id="catalogueId"
          name='vehicle.catalogueId'
          placeholder={t(`${tKey}CATALOGUE`)}
          controlLabel={t(`${tKey}CATALOGUE`)}
          component={InputSelect}
          options={catalogueCombo}
          valueKey="id"
          labelKey="value"
          disabled={!catalogueCombo || catalogueCombo.length === 0}
          onInputChange={(val) => { fieldFill({ catalogueId: val, catalogue: null }); this.onCatalogueIdChanges(val) }}
        /> : <Field
          colSm={3}
          id="catalogue"
          name='vehicle.catalogue'
          placeholder={t(`${tKey}CATALOGUE`)}
          controlLabel={t(`${tKey}CATALOGUE`)}
          component={InputText}
          type="text"
          onInputChange={(val) => fieldFill({ catalogueId: null, catalogue: val })}
        />
        )
        }
        {this.state.fieldsConfiguration.model && this.state.fieldsConfiguration.model.permission > permissions.hidden &&
          (vehicleIsNew ? <Field
            colSm={3}
            id="modelId"
            name="vehicle.modelYearId"
            placeholder={t(`${tKey}MODEL_YEAR`)}
            controlLabel={t(`${tKey}MODEL_YEAR`)}
            component={InputSelect}
            options={modelCombo}
            valueKey="id"
            labelKey="value"
            disabled={!modelCombo || modelCombo.length === 0}
            onInputChange={(val) => { fieldFill({ modelId: val, model: null }); this.onModelIdChanges(val) }}
          /> : <Field
            colSm={3}
            id="model"
            name='vehicle.modelYear'
            placeholder={t(`${tKey}MODEL_YEAR`)}
            controlLabel={t(`${tKey}MODEL_YEAR`)}
            component={InputText}
            type="text"
            onInputChange={(val) => fieldFill({ model: val, modelId: null })}
          />
          )
        }
        <Clearfix />
        {this.state.fieldsConfiguration.externalColor && this.state.fieldsConfiguration.externalColor.permission > permissions.hidden &&
          (vehicleIsNew ? <Field
            colSm={3}
            id="colorId"
            name='vehicle.colorId'
            placeholder={t(`${tKey}EXTERNAL_COLOR`)}
            controlLabel={t(`${tKey}EXTERNAL_COLOR`)}
            component={InputSelect}
            options={colorCombo}
            valueKey="id"
            labelKey="value"
            disabled={!colorCombo || colorCombo.length === 0}
            onInputChange={(val) => fieldFill({ externalColorId: val, externalColor: null })}
          /> : <Field
            colSm={3}
            id="color"
            name='vehicle.color'
            placeholder={t(`${tKey}EXTERNAL_COLOR`)}
            controlLabel={t(`${tKey}EXTERNAL_COLOR`)}
            component={InputText}
            type="text"
            onInputChange={(val) => fieldFill({ externalColorId: null, externalColor: val })}
          />
          )
        }
        {this.state.fieldsConfiguration.internalColor && this.state.fieldsConfiguration.internalColor.permission > permissions.hidden &&
          (vehicleIsNew ? <Field
            colSm={3}
            id="tapestryId"
            name='vehicle.tapestryId'
            placeholder={t(`${tKey}INTERNAL_COLOR`)}
            controlLabel={t(`${tKey}INTERNAL_COLOR`)}
            component={InputSelect}
            options={tapestryCombo}
            valueKey="id"
            labelKey="value"
            disabled={!tapestryCombo || tapestryCombo.length === 0}
            onInputChange={(val) => fieldFill({ internalColorId: val, internalColor: null })}
          /> : <Field
            colSm={3}
            id="tapestry"
            name='vehicle.tapestry'
            placeholder={t(`${tKey}INTERNAL_COLOR`)}
            controlLabel={t(`${tKey}INTERNAL_COLOR`)}
            component={InputText}
            type="text"
            onInputChange={(val) => fieldFill({ internalColor: val, internalColorId: null })}
          />
          )
        }
      </div>
      : null
    )
  }
}

export default AdditionalSearchFieldsByVehicleTypeOperation
