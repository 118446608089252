import React, { PureComponent } from 'react'
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Field } from 'redux-form'
import InputPassword from '../../../_v2/components/commons/form/InputPassword'
import InputPasswordStrengthIndicator from '../../../_v2/components/commons/form/InputPasswordStrengthIndicator'
import InputSelect from '../commons/form/InputSelect'
import InputCheckBox from '../commons/form/InputCheckBox'
import { ThemeValues } from '../../../constants/themes'

class UserData extends PureComponent {
  constructor (props) {
    super(props)
    this.state = { rechargeDevices: false, phoneEditable: false }
  }

  componentDidMount () {
  }

  render () {
    const tKey = 'PROFILE.'
    const { t, data, languages, passwordPolicy, formValues, formErrors } = this.props
    if (!data || !languages) return null
    return (
      <div className='inner-results-panel'>
        <div className="section-results-header">
          <div className="section-header-title">
            <OverlayTrigger placement="right" overlay={<Tooltip id="editProductTooltip">{`${t((this.props.hideMenu ? 'GLOBAL.TABLES.SHOW_INNER_MENU' : 'GLOBAL.TABLES.HIDE_INNER_MENU'))}`}</Tooltip>}>
              <a onClick={() => this.props.toogleInnerMenu()} className={this.props.hideMenu ? 'collapseBtn' : 'collapseBtn closeInnerMenu'}><i className={this.props.hideMenu ? 'iDocIcon-Open-inner-menu' : 'iDocIcon-Close-inner-menu'}></i></a>
            </OverlayTrigger>
            <h4>{t(tKey + 'USER_TAB.TITLE')}
              <div className="lastLogin">
                <p>{t(tKey + 'LAST_LOGIN')}:</p>
                <span>{data.lastLoginDate}</span>
              </div>
            </h4>
          </div>
        </div>
        <div className="section-results-body">
          <div className='search-form '>
            <Field
              component={InputPassword}
              colWidht={100} // 25, 33, 50, 75, 100 default=25
              t={t}
              id='oldPassword'
              name='oldPassword'
              placeholder={t(tKey + 'OLD_PASSWORD')}
              controlLabel={t(tKey + 'OLD_PASSWORD')}
              autoComplete="old-Password"
            />
            <Field
              component={InputPassword}
              colWidht={100} // 25, 33, 50, 75, 100 default=25
              t={t}
              id='newPassword'
              name='newPassword'
              placeholder={t(tKey + 'NEW_PASSWORD')}
              controlLabel={t(tKey + 'NEW_PASSWORD')}
              autoComplete="new-Password"
            />
            <InputPasswordStrengthIndicator
              colWidht={100} // 25, 33, 50, 75, 100 default=25
              t={t}
              passwordPolicy={passwordPolicy}
              passwordValue={formValues && formValues.newPassword ? formValues.newPassword : ''}
              passwordError={formErrors && formErrors.newPassword ? formErrors.newPassword : false}
            />
            <Field
              component={InputPassword}
              colWidht={100} // 25, 33, 50, 75, 100 default=25
              t={t}
              id='pwdLoginRepeat'
              name='pwdLoginRepeat'
              placeholder={t(tKey + 'REPEAT_PASSWORD')}
              controlLabel={t(tKey + 'REPEAT_PASSWORD')}
              autoComplete="pwd-LoginRepeat"
            />
            <Field
              component={InputSelect}
              colWidht={33} // 25, 33, 50, 75, 100 default=25
              id='languageId'
              name='languageId'
              placeholder={t(tKey + 'LANGUAGE')}
              controlLabel={t(tKey + 'LANGUAGE')}
              options={languages}
              valueKey="languageId"
              labelKey="description"
            />
            <Field
              component={InputSelect}
              colWidht={33} // 25, 33, 50, 75, 100 default=25
              id='themeId'
              name='themeId'
              placeholder={t(tKey + 'THEME')}
              controlLabel={t(tKey + 'THEME')}
              options={ThemeValues}
              valueKey="themeId"
              labelKey="description"
            />
            <Field
              component={InputCheckBox}
              colWidht={33} // 25, 33, 50, 75, 100 default=25
              id="showChecklistAsTab"
              name="showChecklistAsTab"
              placeholder={t(`${tKey}SHOW_CHECKLIST_AS_TAB`)}
            />

            <div className='save-form flexInLine al-R'>
              <Button type="submit" className="_Btn new">
                <i className="ico-folder-open"/> <span>{t(tKey + 'SAVE')}</span>
              </Button>
            </div>
          </div>

        </div>
      </div>
    )
  }
}

export default UserData
