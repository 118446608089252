import React, { Component } from 'react'
import { Popover, Overlay, Glyphicon } from 'react-bootstrap'
import settings from '../../setting'
import { dossierSubTypeId as dossierSubTypes } from '../../constants/dossier/common/dossierSubType'
import { clientInformation } from '../../constants/backendIds'
import { formatMoney } from '../../util/utils'

export default class CostPopover extends Component {
  constructor () {
    super()
    this.state = {
      showPopover: false,
      target: this
    }
  }

  handleClick (e) {
    e.stopPropagation()
    this.setState({ target: e.target, showPopover: true })
  }

  handleOnMouseEnter (e) {
    e.stopPropagation()
    this.setState({ target: e.target, showPopover: true })
  }

  handleOnMouseLeave (e) {
    e.stopPropagation()
    this.setState({ target: e.target, showPopover: false })
  }

  render () {
    const {
      t,
      id,
      economic = {},
      dossierSubTypeId
    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.ECONOMIC_PLAN.POPOVER_PRICE_COST.'
    if (settings.clientFieldValidation === clientInformation.VGR && dossierSubTypeId === dossierSubTypes.venta_vo) {
      return (
        <div>
          {economic != null &&
                    <div style={{ cursor: 'pointer' }} className="icon-only" onMouseEnter={this.handleOnMouseEnter.bind(this)} onMouseLeave={this.handleOnMouseLeave.bind(this)}><Glyphicon glyph="info-sign"/></div>
          }
          <Overlay
            show={this.state.showPopover}
            target={this.state.target}
            onHide={() => this.setState({ showPopover: false })}
            rootClose
          >
            <Popover id={id} className="cost-popover-container">
              <div className="cost-popover-item">
                <label>{t(`${tKey}ORIGINAL_PRICE`).toUpperCase()}: </label><br/>
                {formatMoney(economic.originalPrice)}
              </div>
              <div className="cost-popover-item">
                <label>{t(`${tKey}AMORTIZATION`).toUpperCase()}: </label><br/>
                {formatMoney(economic.amortization)}
              </div>
              <div className="cost-popover-item">
                <label>{t(`${tKey}RECONDITIONING`).toUpperCase()}: </label><br/>
                {formatMoney(economic.reconditioning)}
              </div>
            </Popover>

          </Overlay>
        </div>
      )
    }
    return null
  }
}
