import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Management from '../../components/management/Management'
import Management_v2 from '../../_v2/components/management/Management'
import { translate } from 'react-polyglot'
import { searchDocuments } from '../../actions/sign/sign'
import { openDocumentEditionModal } from '../../actions/dossier/common/documentEditionModal'
import { openDocumentEditionModal as openDocumentEditionModalHistorical } from '../../actions/dossier/common/documentHistoricalEditionModal'
import { fetchDocumentSearchList } from '../../actions/search/documentalSearch/documentalSearchForm'
import { fetchManagementActions } from '../../actions/combos/combos'

import TrackingContainer from '../tracking/trackingContainer'
import { findUnattendedProcess } from '../../actions/unattendedProcess/unattendedProcess'
import { managementTabChange, openAuditAdminModal, managementSectionChangeSuccess } from '../../actions/management/management'
import { getStorageValue } from '../../storage/storage'
import { resetFilters } from '../../actions/search/search'

export function mapStateToProps (state) {
  return {
    ...state.sign,
    ...state.management,
    sectionsGeneral: state.auth.sections,
    section: state.common.section,
    unnatendedProcess: state.unattendedProcess,
    combos: {
      managementActions: state.combos.managementActions
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      openAuditAdminModal,
      searchDocuments,
      openDocumentEditionModal,
      openDocumentEditionModalHistorical,
      fetchDocumentSearchList,
      fetchManagementActions,
      findUnattendedProcess,
      managementTabChange,
      resetFilters,
      managementSectionChangeSuccess
    }, dispatch)
  }
}

let version = parseInt(getStorageValue('interfaceVersion'))
var decoratedComponent = null
if (version === 2) {
  decoratedComponent = TrackingContainer(Management_v2, true)
} else {
  decoratedComponent = TrackingContainer(Management, true)
}
export default connect(mapStateToProps, mapDispatchToProps)(translate()(decoratedComponent))
