import mastersActionTypes from '../../../constants/actions/masters/masters'
import { put, call, select, takeEvery } from 'redux-saga/effects'
import { getAuth } from '../../../selectors/access/auth'
import { showLoader, hideLoader } from '../../../actions/common.js'
import { handleError } from '../../errors/errorManager'
import getSignTypeList from '../../../services/signType/postSignTypeList'
import { fetchSignTypeListSuccess, closeSignTypeModal } from '../../../actions/masters/signType/signType'
import { getFilterSignType } from '../../../selectors/masters/filterSignType'
import putSignType from '../../../services/signType/putSignType'
import { yesNoModal } from '../../modalsListeners/yesNoModal'

export function * fetchSignTypeList ({ filter }) {
  try {
    if (filter === null || filter === undefined) {
      filter = yield filter || select(getFilterSignType)
    }
    yield put(showLoader())
    const auth = yield select(getAuth)
    const result = yield call(getSignTypeList, auth.token, filter)
    yield put(fetchSignTypeListSuccess(result, filter))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchfetchSignTypeList () {
  yield takeEvery(mastersActionTypes.FETCH_SIGN_TYPE_LIST, fetchSignTypeList)
}

export function * submitSignType ({ signType }) {
  try {
    const auth = yield select(getAuth)
    const filter = yield filter || select(getFilterSignType)

    const confirmed = yield call(yesNoModal, 'updateMasterData')
    if (!confirmed) return

    yield put(showLoader())
    yield call(putSignType, auth.token, signType)

    yield put(closeSignTypeModal())
    yield call(fetchSignTypeList, filter)
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSubmitSignType () {
  yield takeEvery(mastersActionTypes.SUBMIT_SIGN_TYPE, submitSignType)
}
