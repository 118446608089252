import mastersActionTypes from '../../../constants/actions/masters/masters'

function initialState () {
  return {
    legalCompanyList: [],
    legalCompanyCount: 0,
    pagesCount: 0,
    filter: {
      page: 1,
      orderBy: '',
      pageSize: 10,
      searchCif: '',
      searchDescription: ''
    }
  }
}

function fetchLegalCompanyListSuccess (state, { legalCompanyList, filter }) {
  return {
    ...state,
    legalCompanyList: legalCompanyList.legalCompanyList,
    legalCompanyCount: legalCompanyList.legalCompanyCount,
    pagesCount: legalCompanyList.pagesCount,
    filter
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case mastersActionTypes.FETCH_LEGAL_COMPANY_LIST_SUCCESS:
      return fetchLegalCompanyListSuccess(state, action)
    default:
      return state
  }
}
