import mastersActionTypes from '../../../constants/actions/masters/masters'

function initialState () {
  return {
    signType: null,
    signTypeList: [],
    signTypeCount: 0,
    pagesCount: 0,
    filter: {
      Page: 1,
      OrderBy: '',
      PageSize: 10
    },
    signTypeModal: {
      showModal: false,
      signType: null,
      signTypeId: null
    }
  }
}

function fetchSignTypeListSuccess (state, { signTypeList, filter }) {
  return {
    ...state,
    signTypeList: signTypeList.signTypeList,
    signTypeCount: signTypeList.rowsCount,
    pagesCount: signTypeList.pagesCount,
    filter: filter
  }
}

function openSignTypeModalSuccess (state, { signType }) {
  const initial = initialState()
  return {
    ...state,
    signTypeModal: {
      ...initial.signTypeModal,
      showModal: true,
      signType
    }
  }
}

function closeSignTypeModal (state) {
  const initial = initialState()
  return {
    ...state,
    signTypeModal: {
      ...initial.signTypeModal
    }
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case mastersActionTypes.FETCH_SIGN_TYPE_SUCCESS_LIST:
      return fetchSignTypeListSuccess(state, action)
    case mastersActionTypes.OPEN_SIGN_TYPE_MODAL:
      return openSignTypeModalSuccess(state, action)
    case mastersActionTypes.CLOSE_SIGN_TYPE_MODAL:
      return closeSignTypeModal(state, action)
    default:
      return state
  }
}
