
import React, { PureComponent } from 'react'
import { Button, Modal, Col, Row, FormGroup } from 'react-bootstrap'
import { reduxForm, Field } from 'redux-form'
import TextInput from '../../commons/TextInput'
import InputTreeSelect from '../../commons/form/InputTreeSelect'

class FaqSectionMasterModal extends PureComponent {
  submitFaq (values) {
    let organizedUnitId = null
    const body = []

    Object.keys(values.languageList).map(item => {
      let languageId = values.languageList[item].languageId
      body.push(values[`lang${languageId}`])
    })
    organizedUnitId = values.organizedUnitId

    this.props.actions.saveFaqSection(this.props.faqSectionId, organizedUnitId, body)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.showModal !== this.props.showModal && nextProps.showModal === true) {
      this.props.initialize({ ...nextProps })
    }
    if (this.props.translations !== nextProps.translations) {
      nextProps.languageList.map((language) => {
        this.props.change(`lang${language.languageId}.languageId`, language.languageId)
        const nextTranslation = nextProps.translations.find((faqSection) => faqSection.languageId === language.languageId)
        if (nextTranslation && nextTranslation !== undefined) {
          this.props.change(`lang${language.languageId}.name`, nextTranslation.name)
          this.props.change(`lang${language.languageId}.description`, nextTranslation.description)
        }
      })

      if (this.props.organizedUnitId !== nextProps.organizedUnitId) {
        this.props.change('organizedUnitId', nextProps.organizedUnitId ? nextProps.organizedUnitId.toString() : '')
      }
    }
  }

  render () {
    const { t, showModal, handleSubmit, languageList = [], combos: { UOTreeCombo }, actions: { closeFaqSectionModal } } = this.props
    return (
      <Modal show={showModal} onHide={closeFaqSectionModal} className="languages-modal">
        <Modal.Header closeButton>
          <i className="ico-users" /><Modal.Title>{t('MASTERS.FAQSECTION.TITLE')}</Modal.Title>
        </Modal.Header>
        <form autoComplete="off" onSubmit={handleSubmit(this.submitFaq.bind(this))}>
          <Modal.Body>
            <Field
              name="organizedUnitId"
              controlLabel={t('MASTERS.FAQSECTION.ORGANIZED_UNIT')}
              placeholder={t('MASTERS.FAQSECTION.ORGANIZED_UNIT')}
              component={InputTreeSelect}
              multi={false}
              allowClear={true}
              valueKey="value"
              labelKey="label"
              childrenKey="children"
              colSm={0}
              options={UOTreeCombo}
              showSearch={false}
            />
            <Row className="title-column">
              <Col sm={4}>
                <h2>{t('MASTERS.FAQSECTION.TITLE_FIELD')}</h2>
              </Col>
              <Col sm={6}>
                <h2>{t('MASTERS.FAQSECTION.DESCRIPTION_FIELD')}</h2>
              </Col>
              <Col sm={2}>
                <h2>{t('MASTERS.LANGUAGE')}</h2>
              </Col>
            </Row>
            <FormGroup>
              {languageList.map((translation, idx) => {
                return (
                  <Row key={idx} className="inputs-wrapper">
                    <Col sm={4} className="custom-width-col">
                      <Field
                        name={`lang${translation.languageId}.name`}
                        placeholder={t('MASTERS.FAQSECTION.TITLE_FIELD')}
                        component={TextInput}
                      />
                    </Col>
                    <Col sm={6} className="custom-width-col">
                      <Field
                        name={`lang${translation.languageId}.description`}
                        placeholder={t('MASTERS.FAQSECTION.DESCRIPTION_FIELD')}
                        component={TextInput}
                      />
                    </Col>
                    <Col sm={2} className="language-column">
                      <p>{`${translation.description} (${translation.name})`}</p>
                    </Col>
                  </Row>
                )
              })}
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <Col sm={6} className="btn-wrapper" smPush={6}>
              <Button type="submit" className="btn-standard">
                {t('MASTERS.SAVE')}
              </Button>
            </Col>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

const validate = (values, props) => {
  const errors = {}
  props.languageList.map(language => {
    errors[`lang${language.languageId}`] = {}
    if (values[`lang${language.languageId}`] !== undefined) {
      if (!(values[`lang${language.languageId}`].name)) {
        errors[`lang${language.languageId}`].name = props.t('MASTERS.VALIDATION.NAME_REQUIRED')
      }
      if (!(values[`lang${language.languageId}`].description)) {
        errors[`lang${language.languageId}`].description = props.t('MASTERS.VALIDATION.DESCRIPTION_REQUIRED')
      }
    }
  })
  return errors
}

export default reduxForm({
  form: 'editFaqSection',
  validate
})(FaqSectionMasterModal)
