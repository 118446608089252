import { call, put, select, takeEvery } from 'redux-saga/effects'
import { hideLoader, showLoader } from '../../actions/common'
import { handleError } from '../errors/errorManager'
import { getAuth } from '../../selectors/access/auth'
import { yesNoModal } from '../modalsListeners/yesNoModal'
import { fetchEventSubscriptionsFromAdminSuccess } from '../../actions/admin/subscriptionsAdmin'
import subscriptionsAdmTypes from '../../constants/actions/admin/subscriptionsAdmin'
import putEventSubscription from '../../services/eventSubscription/putEventSubscription'
import postEventSubscription from '../../services/eventSubscription/postEventSubscription'
import deleteEventSubscription from '../../services/eventSubscription/deleteEventSubscription'
import getAllEventSubscriptions from '../../services/eventSubscription/getAllEventSubscriptions'
import putEnableEventSubscription from '../../services/eventSubscription/putEnableEventSubscription'
import putDisableEventSubscription from '../../services/eventSubscription/putDisableEventSubscription'

export function * saveEventSubscriptionFromAdmin ({ subscriptionId, eventSubscription, userId, resolve, reject }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    if (subscriptionId) {
      yield call(putEventSubscription, subscriptionId, eventSubscription, auth.token, userId)
    } else {
      yield call(postEventSubscription, eventSubscription, auth.token, userId)
    }
    yield call(fetchEventSubscriptionsFromAdmin, { userId })
    if (resolve) resolve()
  } catch (error) {
    yield call(handleError, { error })
    if (reject) reject()
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSaveEventSubscriptionFromAdmin () {
  yield takeEvery(subscriptionsAdmTypes.SAVE_EVENT_SUBSCRIPTION_FROM_ADMIN, saveEventSubscriptionFromAdmin)
}

export function * removeEventSubscriptionFromAdmin ({ subscriptionId, userId }) {
  try {
    const confirm = yield call(yesNoModal, 'deleteEventSubscription')
    if (confirm) {
      yield put(showLoader())
      const auth = yield select(getAuth)
      yield call(deleteEventSubscription, subscriptionId, auth.token, userId)
      yield call(fetchEventSubscriptionsFromAdmin, { userId })
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDeleteEventSubscriptionFromAdmin () {
  yield takeEvery(subscriptionsAdmTypes.DELETE_EVENT_SUBSCRIPTION_FROM_ADMIN, removeEventSubscriptionFromAdmin)
}

export function * fetchEventSubscriptionsFromAdmin ({ userId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    let eventSubscriptions = yield call(getAllEventSubscriptions, userId, auth.token)
    yield put(fetchEventSubscriptionsFromAdminSuccess(eventSubscriptions))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchEventSubscriptionsFromAdmin () {
  yield takeEvery(subscriptionsAdmTypes.FETCH_EVENT_SUBSCRIPTIONS_FROM_ADMIN, fetchEventSubscriptionsFromAdmin)
}

export function * enableEventSubscriptionFromAdmin ({ subscriptionId, userId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(putEnableEventSubscription, subscriptionId, auth.token, userId)
    yield call(fetchEventSubscriptionsFromAdmin, { userId })
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchEnableEventSubscriptionFromAdmin () {
  yield takeEvery(subscriptionsAdmTypes.ENABLE_EVENT_SUBSCRIPTION_FROM_ADMIN, enableEventSubscriptionFromAdmin)
}

export function * disableEventSubscriptionFromAdmin ({ subscriptionId, userId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(putDisableEventSubscription, subscriptionId, auth.token, userId)
    yield call(fetchEventSubscriptionsFromAdmin, { userId })
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDisableEventSubscriptionFromAdmin () {
  yield takeEvery(subscriptionsAdmTypes.DISABLE_EVENT_SUBSCRIPTION_FROM_ADMIN, disableEventSubscriptionFromAdmin)
}
