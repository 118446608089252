import { put, call, select, takeEvery } from 'redux-saga/effects'
import { showLoader, hideLoader } from '../../../actions/common.js'
import mastersActionTypes from '../../../constants/actions/masters/masters'
import { getAuth } from '../../../selectors/access/auth'
import { handleError } from '../../errors/errorManager'
import { yesNoModal } from '../../modalsListeners/yesNoModal'
import { getDocumentTypeFilter } from '../../../selectors/masters/document'

import { fetchDocumentTypeListSuccess, openDocumentTypeModalSuccess, closeDocumentTypeMasterModal } from '../../../actions/masters/documentType/documentType'
import getLanguageListComboService from '../../../services/language/getLanguageList'
import postDocumentTypeList from '../../../services/documentType/postDocumentTypeList'
import postDocumentTypeService from '../../../services/documentType/postDocumentType'
import putDocumentTypeService from '../../../services/documentType/putDocumentType'
import deleteDocumentTypeService from '../../../services/documentType/deleteDocumentType'

export function * deleteDocumentType (id) {
  try {
    const confirmed = yield call(yesNoModal, 'deleteDocumentType')
    if (confirmed) {
      yield put(showLoader())
      const auth = yield select(getAuth)
      const filter = yield filter || select(getDocumentTypeFilter)
      yield call(deleteDocumentTypeService, auth.token, id)
      yield call(fetchDocumentTypeList, filter)
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDeleteDocumentType () {
  yield takeEvery(mastersActionTypes.DELETE_DOCUMENT_TYPE, deleteDocumentType)
}

export function * submitDocumentType ({ documentType }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const filter = yield filter || select(getDocumentTypeFilter)

    if (!documentType.id) {
      yield call(postDocumentTypeService, auth.token, documentType)
    } else {
      yield call(putDocumentTypeService, auth.token, documentType)
    }

    yield put(closeDocumentTypeMasterModal())
    yield call(fetchDocumentTypeList, filter)
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSubmitDocumentType () {
  yield takeEvery(mastersActionTypes.SUBMIT_DOCUMENT_TYPE, submitDocumentType)
}

export function * fetchDocumentTypeList ({ filter }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield filter = yield filter || select(getDocumentTypeFilter)
    const documentTypeList = yield call(postDocumentTypeList, auth.token, filter)
    yield put(fetchDocumentTypeListSuccess(documentTypeList, filter))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchDocumentTypeList () {
  yield takeEvery(mastersActionTypes.FETCH_DOCUMENT_TYPE_LIST, fetchDocumentTypeList)
}

export function * openDocumentTypeModal ({ documentType }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const languageList = yield call(getLanguageListComboService, auth.token)
    if (!documentType) {
      documentType = {}
    }

    for (const language in languageList) {
      documentType[`lang${languageList[language].languageId}`] = {}
      documentType[`lang${languageList[language].languageId}`].languageId = languageList[language].languageId
      let source = documentType ? documentType.documentTypeLocales?.find((sourceChannel) => sourceChannel.languageId === languageList[language].languageId) : null
      documentType[`lang${languageList[language].languageId}`].name = source ? source.name : ''
      documentType[`lang${languageList[language].languageId}`].description = source ? source.description : ''
    }

    yield put(openDocumentTypeModalSuccess(languageList, documentType))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchOpenDocumentTypeModal () {
  yield takeEvery(mastersActionTypes.OPEN_DOCUMENT_TYPE_MODAL, openDocumentTypeModal)
}
