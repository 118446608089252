import apiFetch from '../apiFetch'

export default function (token, dossierId, lastAccessDate) {
  return apiFetch({
    endPoint: `dossier/sale/${dossierId}/printDocumentaryChecklist`,
    method: 'GET',
    parseBlobAndAssign: true,
    lastAccessDate,
    token: token
  })
}
