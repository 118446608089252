import Settings from './settings'

export default {
  ...Settings,
  url: 'http://localhost:3050/api/',
  urlHotFolder: '',
  notificationRefresh: 60000,
  showSignDocumentButton: false,
  acceptFileFormats: 'application/pdf, .jpg, .png, .jpeg, .gif, .docx, .doc, .xls, .xlsx, .ppt, .pptx, .msg, .tiff',
  qaSite: true,
  dealerMaxLength: 6,
  dealerNumberRegex: null,
  clientFieldValidation: 'all',
  autoLoadDashboard: true,
  validateLicensePlate: true,
  maxTagCount: 2,
  maxTagTextLength: 10,
  matomoSiteId: '17',
  enableFavoritesAndRecents: true,
  enableTaskFollowUp: true,
  dashboardVersion: 2
}
