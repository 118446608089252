import React, { PureComponent } from 'react'
import { Row, Col, Table, Button, Tooltip, OverlayTrigger } from 'react-bootstrap'
import RoleModalPage from '../../../containers/admin/role/RoleModalPage'
import IDocCarPagination from '../../commons/iDocCarPagination'
import { Field, reduxForm } from 'redux-form'
import InputSelect from '../../commons/form/InputSelect'

class RoleAdmin extends PureComponent {
  constructor () {
    super()
    this.state = {
      filter: {
        searchSection: null,
        searchPermission: null
      }
    }
  }

  componentDidMount () {
    this.props.actions.fetchRoleList(this.props.filter)
    this.props.actions.fetchSectionCombo()
    this.props.actions.fetchPermissionCombo()
  }

  getSortIcon (column = '') {
    switch (this.props.filter.orderBy) {
      case '+' + column:
        return 'ico-sort-selected-asc'
      case '-' + column:
        return 'ico-sort-selected-desc'
      default:
        return 'ico-sort'
    }
  }

  changeOrder (field) {
    const filter = { ...this.props.filter, ...this.state.filter }
    switch (this.props.filter.orderBy) {
      case '':
        this.props.actions.fetchRoleList({ ...filter, orderBy: `+${field}` })
        break
      case `+${field}`:
        this.props.actions.fetchRoleList({ ...filter, orderBy: `-${field}` })
        break
      case `-${field}`:
      default:
        this.props.actions.fetchRoleList({ ...filter, orderBy: '' })
        break
    }
  }

  handleSectionChange (searchSection) {
    if (this.state.filter.searchSection !== searchSection) {
      this.setState({ filter: { ...this.state.filter, searchSection: searchSection } })
    }
  }

  handlePermissionChange (searchPermission) {
    if (this.state.filter.searchPermission !== searchPermission) {
      this.setState({ filter: { ...this.state.filter, searchPermission: searchPermission } })
    }
  }

  searchRoles () {
    this.props.actions.fetchRoleList({ ...this.props.filter, ...this.state.filter, page: 1 })
  }

  render () {
    const {
      t, roleList, pagesCount, filter, rolesCount,
      filter: { page },
      combos: { permissionCombo, sectionCombo },
      actions: { openEditRoleModal, openAddRoleModal, fetchRoleList, deleteRole, cloneRole }
    } = this.props
    const tKey = 'ADMIN.'
    const editTooltip = (<Tooltip id="editTooltip">{t(`${tKey}EDIT`)}</Tooltip>)
    const deleteTooltip = (<Tooltip id="deleteTooltip">{t(`${tKey}DELETE`)}</Tooltip>)
    const copyTooltip = (<Tooltip id="deleteTooltip">{t(`${tKey}COPY`)}</Tooltip>)
    return (
      <div className="admin-wrapper">
        <RoleModalPage/>
        <Row className="section-users-header">
          <Col sm={12}>
            <div className="header-panel">
              <h4>{t(`${tKey}ROLES`)}</h4>
            </div>
          </Col>
        </Row>
        <Row>
          <div className="search-form">
            <span className="title-search"><i className="ico-search" /> {t(`${tKey}ROLES_PAGE.SEARCH_TITLE`)}</span>
            <Row>
              <Field
                name="filters.searchSection"
                controlLabel={t(`${tKey}ROLES_PAGE.SEARCH_SECTIONS`)}
                placeholder={t(`${tKey}ROLES_PAGE.SEARCH_SECTIONS`)}
                component={InputSelect}
                multi={true}
                valueKey="id"
                labelKey="value"
                options={sectionCombo}
                colSm={3}
                onInputChange={(value) => this.handleSectionChange(value)}
              />
              <Field
                name="filters.searchPermission"
                controlLabel={t(`${tKey}ROLES_PAGE.SEARCH_PERMISSIONS`)}
                placeholder={t(`${tKey}ROLES_PAGE.SEARCH_PERMISSIONS`)}
                component={InputSelect}
                multi={true}
                valueKey="id"
                labelKey="value"
                options={permissionCombo}
                colSm={3}
                onInputChange={(value) => this.handlePermissionChange(value)}
              />
              <Col sm={2} className="btn-wrapper">
                <Button
                  className={'btn-white btn-icon btn-input btn-white search-button'}
                  style={{ width: '100%' }}
                  onClick={() => this.searchRoles()}>
                  <i className="ico-search" />
                  {t(`${tKey}SEARCH_BTN`)}
                </Button>
              </Col>
            </Row>
          </div>
        </Row>
        <Col sm={12} className="col-row section-users-body">
          <Table className="editable-table" hover responsive>
            <thead>
              <tr>
                <th onClick={() => { this.changeOrder('roleId') }}>
                  {t(`${tKey}ID`)}&nbsp;
                  <i className={this.getSortIcon('roleId')} />
                </th>
                <th onClick={() => { this.changeOrder('roleName') }}>
                  {t(`${tKey}NAME`)}&nbsp;
                  <i className={this.getSortIcon('roleName')} />
                </th>
                <th onClick={() => { this.changeOrder('roleDescription') }}>
                  {t(`${tKey}DESCRIPTION`)}&nbsp;
                  <i className={this.getSortIcon('roleDescription')} />
                </th>
                <th class="roleRow">{t('DOSSIER_COMPONENTS.ACCESSORIES.ACTIONS')}</th>
              </tr>
            </thead>
            <tbody>
              {roleList && roleList.map((role, idx) => {
                return (
                  <tr key={idx} className={(role.isDeleted ? 'is-deleted' : '')}>
                    <td>{role.roleId}</td>
                    <td>{role.name}</td>
                    <td>{role.description}</td>
                    <td>
                      {!role.isDeleted && (
                        <div>
                          <a onClick={() => openEditRoleModal(role.roleId)}>
                            <OverlayTrigger placement="right" overlay={editTooltip}>
                              <i className="ico-edit-white"/>
                            </OverlayTrigger>
                          </a>
                          <a onClick={() => cloneRole(role.roleId, { ...filter, ...this.state.filter })}>
                            <OverlayTrigger placement="right" overlay={copyTooltip}>
                              <i className="ico-docs roles"/>
                            </OverlayTrigger>
                          </a>
                          <a onClick={() => deleteRole(role.roleId, { ...filter, ...this.state.filter })}>
                            <OverlayTrigger placement="left" overlay={deleteTooltip}>
                              <i className="ico-trash"/>
                            </OverlayTrigger>
                          </a>
                        </div>
                      )}
                    </td>
                  </tr>)
              })
              }
              {(roleList === null || roleList === undefined || roleList.length === 0) && (<tr>
                <td colSpan={4}><h2>{t(`${tKey}ROLES_PAGE.RESULTS_NOT_FOUND`)}</h2></td>
              </tr>)}
              <tr className="button-table-wrapper">
                <td>
                  <Button
                    className="btn-editable-table btn-standard"
                    onClick={() => openAddRoleModal()}>
                  + {t('MASTERS.ADD')}
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>
          <div className="section-users-footer">
            <nav key={1} className="text-center">
              <IDocCarPagination
                id="btn_pag_sales"
                page={page}
                pagesCount={pagesCount}
                totalRows= {rolesCount}
                onSelectAction={(value) => fetchRoleList({ ...filter, ...this.state.filter, page: value })}
              />
            </nav>
          </div>
        </Col>
      </div>
    )
  }
}

export default reduxForm({
  form: 'roleAdmin'
})(RoleAdmin)
