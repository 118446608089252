import Settings from './settings'

export default {
  url: 'http://localhost:3080/',
  urlHotFolder: '',
  notificationRefresh: 60000,
  showSignDocumentButton: false,
  acceptFileFormats: 'application/pdf, .jpg, .png, .jpeg, .gif, .docx, .doc, .xls, .xlsx, .ppt, .pptx, .msg, .tiff',
  qaSite: true,
  dealerMaxLength: 6,
  dealerNumberRegex: null,
  clientFieldValidation: 'all',
  autoLoadDashboard: true,
  validateLicensePlate: true,
  version: Settings.version,
  serviceDeskUrl: Settings.serviceDeskUrl,
  clientEnviroment: Settings.clientEnviroment,
  maxTagCount: 2,
  maxTagTextLength: 10,
  matomoSiteId: '0'
}
