import React, { PureComponent } from 'react'
import { Table, Row, Col, OverlayTrigger, Tooltip, Button, Glyphicon } from 'react-bootstrap'
import ExtraSaleModalPage from '../../../containers/masters/ExtraSaleModalPage'
import SimpleTreeSelect from '../../commons/simpleForm/SimpleTreeSelect'
// import 'react-select/dist/react-select.css'

class ExtraSaleMasters extends PureComponent {
  constructor () {
    super()
    this.state = {
      uoId: null
    }
  }

  handleOrganizedUnitChange (uoId) {
    this.setState({ uoId })
    this.props.actions.fetchExtraSalesMasters(uoId)
  }

  render () {
    const {
      t, extraSalesMastersList,
      actions: { deleteExtraSaleModal, openExtraSaleModal, recoverExtraSaleModal },
      combos: { UOTreeCombo }
    } = this.props
    const tKey = 'MASTERS.EXTRA_SALE_MASTERS.'
    const editTooltip = (<Tooltip id="editTooltip">{t('MASTERS.EDIT')}</Tooltip>)
    const deleteTooltip = (<Tooltip id="deleteTooltip">{t('MASTERS.DELETE')}</Tooltip>)
    const recoverTooltip = (<Tooltip id="recoverTooltip">{t('MASTERS.RECOVER')}</Tooltip>)
    return (
      <Row>
        <Col sm={12}>
          <div className="header-panel">
            <h4>{t(`${tKey}SOURCE_CHANNEL`)}</h4>
          </div>
        </Col>
        <SimpleTreeSelect
          colSm={12}
          controlLabel={t(`${tKey}ORGANIZED_UNIT`)}
          placeholder={t(`${tKey}ORGANIZED_UNIT`)}
          options={UOTreeCombo}
          searchPlaceholder={t(`${tKey}ORGANIZED_UNIT`)}
          value={this.state.uoId ? this.state.uoId.toString() : null}
          onInputChange={(value) => this.handleOrganizedUnitChange(value)}
          className='dashboard-select'
        />
        <ExtraSaleModalPage
          uoId={this.state.uoId}
        />
        <Col sm={12}>
          <Table className="editable-table" hover responsive>
            <thead>
              <tr>
                <th>{t('MASTERS.ID')}</th>
                <th>{t('MASTERS.NAME')}</th>
                <th>{t('MASTERS.DESCRIPTION')}</th>
                <th style={{ width: '77px' }}>{t('DOSSIER_COMPONENTS.ACCESSORIES.ACTIONS')}</th>
              </tr>
            </thead>
            <tbody>
              {extraSalesMastersList && extraSalesMastersList.map((extraSaleMaster, idx) => (
                <tr key={idx} className={extraSaleMaster.isDeleted && 'is-deleted'}>
                  <td>{extraSaleMaster.id}</td>
                  <td>{extraSaleMaster.name}</td>
                  <td>{extraSaleMaster.description}</td>
                  <td>
                    {!extraSaleMaster.isDeleted ? (
                      <div>
                        <a onClick={() => openExtraSaleModal(this.state.uoId, extraSaleMaster.id)}>
                          <OverlayTrigger placement="left" overlay={editTooltip}>
                            <i className="ico-edit-white" />
                          </OverlayTrigger>
                        </a>
                        <a onClick={() => deleteExtraSaleModal(this.state.uoId, extraSaleMaster.id)}>
                          <OverlayTrigger placement="left" overlay={deleteTooltip}>
                            <i className="ico-trash" />
                          </OverlayTrigger>
                        </a>
                      </div>
                    ) : (
                      <a onClick={() => recoverExtraSaleModal(this.state.uoId, extraSaleMaster.id)}>
                        <OverlayTrigger placement="left" overlay={recoverTooltip}>
                          <Glyphicon glyph="repeat" />
                        </OverlayTrigger>
                      </a>
                    )}
                  </td>
                </tr>
              ))}
              {this.state.uoId && (
                <tr className="button-table-wrapper">
                  <td>
                    <Button
                      className="btn-editable-table btn-standard"
                      onClick={() => openExtraSaleModal(this.state.uoId)}>
                      + {t('MASTERS.ADD')}
                    </Button>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
    )
  }
}

export default ExtraSaleMasters
