import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import ReassignReplacePurchaseModal from '../../../components/dossiers/common/ReassignReplacePurchaseModal'
import reassignReplacePurchaseModalActions from '../../../actions/dossier/common/reassignReplacePurchaseModal'
import { translate } from 'react-polyglot'
import {
  fetchCarlineCombo, fetchCatalogueCombo, fetchModelCombo, fetchColorCombo, fetchTapestryCombo,
  resetCarlineCombo, resetCatalogueCombo, resetColorCombo, resetTapestryCombo
} from '../../../actions/combos/combos'
import { fetchOrderCatalogueModel } from '../../../actions/dossier/common/vehicle'

export function mapStateToProps (state) {
  return {
    ...state.reassignReplacePurchaseModal,
    orderId: state.salesDossier.orderId,
    combos: {
      brandCombo: state.combos.brandCombo,
      fuelCombo: state.combos.fuelCombo,
      purchaseTypeCombo: state.combos.purchaseTypeComboFromSale,
      carlineCombo: state.combos.carlineCombo,
      catalogueCombo: state.combos.catalogueCombo,
      modelCombo: state.combos.modelCombo,
      colorCombo: state.combos.colorCombo,
      tapestryCombo: state.combos.tapestryCombo
    },
    dossierSubTypeId: state.campaignDossier?.dossierSubTypeId
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      ...reassignReplacePurchaseModalActions,
      fetchCarlineCombo,
      fetchCatalogueCombo,
      fetchModelCombo,
      fetchColorCombo,
      fetchTapestryCombo,
      resetCarlineCombo,
      resetCatalogueCombo,
      resetColorCombo,
      resetTapestryCombo,
      fetchOrderCatalogueModel
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(ReassignReplacePurchaseModal))
