export default {
  CLOSE_EDIT_RATING_MODAL: 'CLOSE_EDIT_RATING_MODAL',
  DELETE_RATING: 'DELETE_RATING',
  FETCH_RATING_LIST: 'FETCH_RATING_LIST',
  FETCH_RATING_LIST_SUCCESS: 'FETCH_RATING_LIST_SUCCESS',
  OPEN_ADD_RATING_MODAL_SUCCESS: 'OPEN_ADD_RATING_MODAL_SUCCESS',
  OPEN_EDIT_RATING_MODAL_SUCCESS: 'OPEN_EDIT_RATING_MODAL_SUCCESS',
  OPEN_RATING_MODAL: 'OPEN_RATING_MODAL',
  SUBMIT_RATING: 'SUBMIT_RATING'
}
