
import React, { PureComponent } from 'react'
import { reduxForm } from 'redux-form'
import { Modal, Col, Button } from 'react-bootstrap'
import AuditAdminModalPage from '../../../containers/management/auxModals/AuditAdminModalPage'
import AuditAdminDocumentModalPage from '../../../containers/management/auxModals/AuditAdminDocumentModalPage'
import AuditObservationsModalPage from '../../../containers/management/auxModals/AuditObservationsModalPage'

class AuditModal extends PureComponent {
  constructor () {
    super()
    this.state = {
      showDocuments: false,
      tempDocuments: [],
      documents: [],
      initialDocuments: [],
      checks: null,
      form: null,
      formData: {
        AuditCode: null,
        AuditDescription: null,
        AuditConfigurationTypeId: null,
        AuditLoadDataIds: null,
        dossierTypeId: null,
        dossierSubtypeId: null,
        auditConfigurationId: null,
        AuditExpirationDate: null,
        AuditUserObservations: null
      },
      initialFormData: {
        AuditCode: null,
        AuditDescription: null,
        AuditConfigurationTypeId: null,
        AuditLoadDataIds: null,
        dossierTypeId: null,
        dossierSubtypeId: null,
        auditConfigurationId: null,
        AuditExpirationDate: null,
        AuditUserObservations: null
      },
      auditConfigurationId: null,
      visibleButtons: true
    }
    this.handleFormValuesChange = this.handleFormValuesChange.bind(this)
    this.handleFormValuesObservationsChange = this.handleFormValuesObservationsChange.bind(this)
    this.handleDocumentFormValuesChange = this.handleDocumentFormValuesChange.bind(this)
  }

  componentDidMount () {}

  componentWillUnmount () {
    this.reset()
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.formValues !== prevProps.formValues) {
      this.props.handleFormValuesChange(this.props.formValues)
    }

    const shouldFetchDocuments = this.props.formData &&
                                 this.props.showModal === true &&
                                 this.props.showModal !== prevProps.showModal &&
                                 this.props.formData.AuditConfigurationId &&
                                 (!prevState.formData ||
                                  prevState.formData.AuditConfigurationId !== this.props.formData.AuditConfigurationId)

    if (shouldFetchDocuments) {
      return new Promise((resolve, reject) => {
        this.props.actions.fetchAuditConfigurationDocument(this.props.formData.AuditConfigurationId, resolve, reject)
      }).then((documents) => {
        this.setState({
          formData: this.props.formData,
          auditConfigurationId: this.props.formData.AuditConfigurationId,
          documents: documents,
          initialDocuments: { ...documents },
          initialFormData: { ...this.props.formData }
        })
        if (this.props.readOnly && !this.props.formData.audit) {
          this.props.actions.openModal('notPossibleEdit')
          this.setState({ visibleButtons: false })
        }
      })
    }
  }

  reset () {
    this.setState({
      showDocuments: false,
      tempDocuments: [],
      documents: [],
      checks: null,
      form: null,
      formData: null,
      auditConfigurationId: null
    }, () => {
      this.props.initialize({})
      this.props.reset()
    })
  }

  handleFormValuesChange (formData, formCheck) {
    this.setState({ formData: formData })
    if (formCheck != null) {
      this.setState({ checks: formCheck })
    }
  }

  handleFormValuesObservationsChange (form) {
    let formData = this.state.formData
    if (form !== null && formData != null) {
      formData.AuditUserObservations = form?.AuditUserObservations
      formData.AuditAdminObservations = form?.AuditAdminObservations
      this.setState({ formData: formData })
    }
  }

  handleDocumentFormValuesChange (formData) {
    if (Array.isArray(formData)) {
      this.setState({ tempDocuments: formData })
    }
  }

  deleteDocument () {
    this.props.actions.openModal('deleteDocumentsAudit', () => {
      const { documents } = this.state

      const checksIds = this.state.checks ? this.state.checks.map(check => check.id) : null

      const updatedDocuments = documents.filter(document => {
        return document && (!checksIds || !checksIds.includes(document.id))
      })

      this.setState({ documents: updatedDocuments, checks: null })
    })
  }

  cancel () {
    if (!this.state.showDocuments) {
      this.reset()
      this.props.actions.closeAuditAdminModal()
    } else {
      this.setState({ showDocuments: false })
    }
  }

  deepEqual (obj1, obj2) {
    if (obj1 === obj2) {
      return true
    }
    if (this.isPrimitive(obj1) && this.isPrimitive(obj2)) {
      return obj1 === obj2
    }
    if (Object.keys(obj1).length !== Object.keys(obj2).length) {
      return false
    }
    for (let key in obj1) {
      if (!(key in obj2)) { return false }
      if (!this.deepEqual(obj1[key], obj2[key])) { return false }
    }
    return true
  }

  isPrimitive (obj) {
    return (obj !== Object(obj))
  }

  isEquivalent () {
    if (this.deepEqual(this.state.initialFormData, this.state.formData) &&
        this.deepEqual(this.state.documents, this.state.initialDocuments)) {
      return true
    }

    return false
  }

  save () {
    if (!this.state.showDocuments) {
      if (this.props.readOnly || this.isEquivalent()) {
        this.props.actions.openModal('noChange')
      } else {
        if (this.state.documents.length === 0) {
          this.props.actions.openModal('notSelectDocuments')
        } else {
          const formData = this.state.formData
          if (formData?.AuditCode && formData?.AuditConfigurationTypeId &&
          formData?.AuditDescription && formData?.AuditLoadDataIds &&
          formData?.dossierSubtypeId && formData?.dossierTypeId) {
            let allIds = this.state.documents.map(document => document.id)
            let auditObject = {
              AuditConfigurationId: this.state.auditConfigurationId,
              AuditCode: formData?.AuditCode,
              AuditDescription: formData?.AuditDescription,
              AuditExpirationDate: formData?.AuditExpirationDate,
              AuditUserObservations: formData?.AuditUserObservations,
              AuditAdminObservations: formData?.AuditAdminObservations,
              AuditConfigurationTypeId: formData?.AuditConfigurationTypeId,
              DossierTypeId: formData?.dossierTypeId,
              DossierSubTypeId: formData?.dossierSubtypeId,
              AuditLoadDataIds: formData?.AuditLoadDataIds,
              DocumentTypeUsesIds: allIds
            }
            return new Promise((resolve, reject) => {
              this.props.actions.saveAuditAdmin(auditObject, resolve, reject)
            }).then(() => {
              this.props.actions.openModal('confirmSaveAudit', null, null, null, null, [{ id: '##CODE##', value: formData?.AuditCode }, { id: '##DESCRIPTION##', value: formData?.AuditDescription }], null)
              this.reset()
              this.props.actions.closeAuditAdminModal()

              if (this.props.hideModal) {
                this.props.hideModal()
              }
            })
          } else {
            this.props.actions.openModal('requiredData', null, null, null, null, [{ id: '##DATA##', value: this.constructValue(formData) }], null)
          }
        }
      }
    } else {
      if (Array.isArray(this.state.tempDocuments)) {
        const updatedDocuments = [...this.state.documents, ...this.state.tempDocuments]
        this.setState({
          documents: updatedDocuments,
          tempDocuments: [],
          showDocuments: false
        })
      }
    }
  }

  constructValue (formData) {
    const tKey = 'MANAGEMENT.ADMIN_AUDIT.MODAL.'
    let missingFields = ''

    if (!formData?.AuditCode) {
      missingFields += this.props.t(`${tKey}CODE`) + ', '
    }
    if (!formData?.AuditDescription) {
      missingFields += this.props.t(`${tKey}DESCRIPTION`) + ', '
    }
    if (!formData?.AuditConfigurationTypeId) {
      missingFields += this.props.t(`${tKey}AUDIT_TYPE`) + ', '
    }
    if (!formData?.AuditLoadDataIds) {
      missingFields += this.props.t(`${tKey}LOAD_CHARGE`) + ', '
    }
    if (!formData?.dossierTypeId) {
      missingFields += this.props.t(`${tKey}DOSSIER_TYPE`) + ', '
    }
    if (!formData?.dossierSubtypeId) {
      missingFields += this.props.t(`${tKey}DOSSIER_SUBTYPE`) + ', '
    }
    return missingFields.trim().slice(0, -1)
  }

  render () {
    const {
      t, showModal, readOnly, formData, isAdmin
    } = this.props
    const tKey = 'MANAGEMENT.ADMIN_AUDIT.MODAL.'
    return (
      <>
        <Modal className="rr-purchase-modal cdaModal AuditModal" show={showModal} onHide={() => this.cancel()} backdrop={'static'} >
          <Modal.Header closeButton>
            { formData?.AuditObservation
              ? <Modal.Title>{t(`${tKey + 'OBSERVATIONS'}`)}</Modal.Title>
              : <>
                { this.state.showDocuments
                  ? <Modal.Title>{t(`${tKey + 'MODAL.TITLE'}`)}</Modal.Title>
                  : this.props?.formData?.AuditConfigurationId
                    ? this.props.formData?.audit ? <Modal.Title>{t(`${tKey + 'TITLE3'}`)}</Modal.Title> : <Modal.Title>{t(`${tKey + 'TITLE2'}`)}</Modal.Title>
                    : <Modal.Title>{t(`${tKey + 'TITLE'}`)}</Modal.Title>}
              </>
            }
          </Modal.Header>
          <Modal.Body>
            { formData?.AuditObservation === true
              ? (
                <AuditObservationsModalPage
                  handleFormValuesObservationsChange={this.handleFormValuesObservationsChange}
                  showModal={showModal}
                  formData={this.state.formData}
                  isAdmin={isAdmin}/>
              ) : null
            }
            {
              formData?.AuditObservation === false && (
                <>
                  <div style={this.state.showDocuments ? { } : { display: 'none' }}>
                    <AuditAdminDocumentModalPage
                      handleDocumentFormValuesChange={this.handleDocumentFormValuesChange}
                      documents={this.state.documents}
                      showDocuments={this.state.showDocuments}/>
                  </div>
                  <div style={!this.state.showDocuments ? { } : { display: 'none' }}>
                    <AuditAdminModalPage
                      handleFormValuesChange={this.handleFormValuesChange}
                      documents={this.state.documents}
                      formData={this.state.formData}
                      showDocuments={this.state.showDocuments}
                      readOnly={readOnly}
                      isAdmin={isAdmin}
                    />
                    { isAdmin && !formData?.audit &&
                    <div className='title-user management-title'>
                      <Button
                        className="btn-standard margin-left _Btn new"
                        style={{ display: !this.state.visibleButtons ? 'none' : '' }}
                        onClick = {() => this.setState({ showDocuments: true })}>
                        {t(`${tKey}ADD`)}
                      </Button>
                      { this.state.documents?.length > 0 && this.state.checks?.length > 0 && this.state.checks !== null &&
                        <Button
                          className="btn-wrapper btn-margin-left"
                          style={{ display: !this.state.visibleButtons ? 'none' : '' }}
                          onClick = {() => this.deleteDocument() }>
                          {t(`${tKey}DELETE`)}
                        </Button>
                      }
                    </div>
                    }
                  </div>
                </>
              )}

          </Modal.Body>
          <Modal.Footer>
            <Col className='flexInLine al-C' sm={12}>
              {(isAdmin || formData?.AuditObservation === true) &&
              <Button
                className="btn-standard _Btn new"
                style={{ display: !this.state.visibleButtons ? 'none' : '' }}
                onClick = {() => this.save()}
                disabled={this.state.showDocuments && this.state.tempDocuments?.length === 0}>
                {t(`${tKey}SAVE`)}
              </Button>
              }
              <Button
                id="role-modal-submit-button"
                className="btn-danger"
                onClick = { () => { this.cancel() }}>
                {t(`${tKey}CANCEL`)}
              </Button>
            </Col>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}

export default reduxForm({
  form: 'AuditModal',
  shouldValidate: () => true
})(AuditModal)
