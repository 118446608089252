import { call, takeEvery, select, put } from 'redux-saga/effects'
import hotFolderActionTypes from '../../../../constants/actions/dossier/common/hotFolder'
import { getCommon } from '../../../../selectors/common/common'
import postGenerateStructure from '../../../../services/hotFolder/postGenerateStructure'
import postLoadFiles from '../../../../services/hotFolder/postLoadFiles'
import getResult from '../../../../services/hotFolder/getResult'
import { hideLoader, showLoader, openModal } from '../../../../actions/common'
import messageModalActions from '../../../../actions/modals/messageModal'
import { getAuth } from '../../../../selectors/access/auth'
import { refreshDossierByType } from './managementModalSagas'
import { handleError } from '../../../errors/errorManager'
import { refreshDocumentManagementModal } from '../../../../actions/dossier/common/documentManagementModal'

export function * generateStructure ({ dossierId, dossierType, dossierNumber, resolve, reject }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const userPath = yield call(postGenerateStructure, { dossierId, dossierNumber, dossierType, userId: auth.userId }, auth.token)
    yield put(openModal('hotFolderShowPath', null, null, null, null, userPath, null))
    yield put(messageModalActions.openSuccessMessageModal('MESSAGES.HOTFOLDER.GENERATE_STRUCTURE_SUCCESS'))
    if (resolve) yield call(resolve, true)
  } catch (error) {
    yield call(handleError, { error })
    if (resolve) yield call(resolve, false)
  } finally {
    yield put(hideLoader())
  }
}

export function * watchGenerateStructure () {
  yield takeEvery(hotFolderActionTypes.GENERATE_STRUCTURE, generateStructure)
}

export function * loadFiles ({ dossierId, dossierType, dossierNumber, resolve, reject }) {
  try {
    yield put(showLoader())
    const common = yield select(getCommon)
    const auth = yield select(getAuth)
    const documentList = yield call(postLoadFiles, { dossierId, dossierType, dossierNumber, languageId: common.locale, userId: auth.userId }, auth.token)
    yield put(openModal('hotFolderLoadFiles', null, null, null, null, documentList, null))
    yield call(refreshDossierByType, {
      dossierType: dossierType,
      dossierDocumentChanged: true,
      entityDocumentChanged: true,
      vehicleDocumentChanged: true,
      documentEditionalModalState: null
    })
    if (resolve) yield call(resolve, true)
  } catch (error) {
    yield call(handleError, { error })
    if (resolve) yield call(resolve, false)
  } finally {
    yield put(hideLoader())
  }
}

export function * watchLoadFiles () {
  yield takeEvery(hotFolderActionTypes.LOAD_FILES, loadFiles)
}

export function * getLoadResult ({ dossierNumber, dossierType, dossierId, resolve, reject }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const documentList = yield call(getResult, auth.userId, dossierNumber)
    yield put(openModal('hotFolderLoadFiles', null, null, null, null, documentList, null))
    yield put(refreshDocumentManagementModal(dossierId))
    yield call(refreshDossierByType, {
      dossierType: dossierType,
      dossierDocumentChanged: true,
      entityDocumentChanged: true,
      vehicleDocumentChanged: false,
      documentEditionalModalState: null
    })
    if (resolve) yield call(resolve, true)
  } catch (error) {
    yield call(handleError, { error })
    if (resolve) yield call(resolve, false)
  } finally {
    yield put(hideLoader())
  }
}

export function * watchGetLoadResult () {
  yield takeEvery(hotFolderActionTypes.GET_LOAD_RESULT, getLoadResult)
}
