import React, { PureComponent } from 'react'
import { Modal, Row, Col, Button } from 'react-bootstrap'
import Step2 from './Steps/Step2'

class ModalLogisticsManagement extends PureComponent {
  render () {
    return (
      <Modal className="modal-document purchase-vo" show={false}>
        <Modal.Header closeButton>
          <div className="number-wrapper">
            <div className="step active">
              <span className="step-number">1</span>
              <span className="step-name">Estado abierto</span>
            </div>
          </div>
          <Modal.Title>Gestión Documental de EXP 20170116E</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="band-user">
            <Col sm={3} className="band-icon-item">
              <i className="ico-user" />
              <div className="text-band">
                <span className="first">Nombre del gestor:</span>
                <span className="second">Nombre del gestor</span>
              </div>
            </Col>
            <Col sm={3} className="band-icon-item">
              <i className="ico-user" />
              <div className="text-band">
                <span className="first">Proveedor transporte:</span>
                <span className="second">Proveedor transporte</span>
              </div>
            </Col>
            <Col sm={3} className="band-icon-item">
              <i className="ico-car" />
              <div className="text-band">
                <span className="first">Marca:</span>
                <span className="second">Marca</span>
              </div>
            </Col>
            <Col sm={3} className="band-icon-item">
              <i className="ico-car" />
              <div className="text-band">
                <span className="first">Modelo:</span>
                <span className="second">Modelo</span>
              </div>
            </Col>
          </Row>
          <Step2/>
        </Modal.Body>
        <Modal.Footer>
          <div className="button-wrapper text-right">
            <Button className="btn-standard-disabled left">
              <i className="ico-cancel"></i>Anular
            </Button>
            <Button className="btn-standard">
              Asignar
            </Button>
            <Button className="btn-standard-disabled">
              Cerrar
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default ModalLogisticsManagement
