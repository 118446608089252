import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import SaleReport from '../../../components/report/sale/SaleReport'
import { fetchSaleList, downloadSaleList } from '../../../actions/reports/saleReport'
import { fetchBrands, fetchFinancingCompanies } from '../../../actions/combos/combos'

export function mapStateToProps (state) {
  return {
    ...state.saleReport,
    combos: {
      brandCombo: state.combos.brandCombo,
      salesmanCombo: state.combos.salesmanCombo,
      lowestOrganizedUnitsCombo: state.combos.lowestOrganizedUnitsCombo,
      companiesCombo: state.combos.companiesCombo
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchSaleList,
      fetchBrands,
      fetchFinancingCompanies,
      downloadSaleList
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(SaleReport))
