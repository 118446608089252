import apiFetch from '../apiFetch'

export default function (faqSectionId, uo, faqValues, token) {
  const body = {
    FaqSectionId: faqSectionId,
    OrganizedUnitId: uo,
    Disabled: false,
    Translations: faqValues
  }
  return apiFetch({ endPoint: `FaqSection/${faqSectionId}`, method: 'PUT', body: body, token: token })
}
