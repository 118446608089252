import React, { PureComponent } from 'react'
import { reportCode } from '../../constants/backendIds'
import DefaultHomeReport from './DefaultHomeReport'
import PowerBiHomeReport from './PowerBiHomeReport'
import { getSectionPermissions } from '../../util/permisionFunctions'
import { sectionPermissions } from '../../constants/dossier/common/buttonsHeader'

class Report extends PureComponent {
  constructor () {
    super()
    this.state = {
      sectionTabsConfiguration: {
        wallet_report: {
          permission: null
        },
        buy_report: {
          permission: null
        },
        stock_report: {
          permission: null
        },
        sale_report: {
          permission: null
        },
        receipt_report: {
          permission: null
        },
        usage_report: {
          permission: null
        }
      }
    }
  }

  componentDidMount () {
    this.props.actions.fetchReportTypeCode()
    this.initializePermisions(this.props)
  }

  componentDidUpdate () {
  }

  getSnapshotBeforeUpdate (prevProps) {
    if ((prevProps.reportTypeCode === null || prevProps.reportTypeCode === undefined) && this.props.reportTypeCode === reportCode.powerbi) {
      this.props.actions.fetchPowerbiConfig()
    }
    return null
  }

  initializePermisions (props) {
    const section = props.sections.filter((tabConf) => tabConf.code === sectionPermissions.reports)
    if (section.length > 0 && section[0] != null) {
      const newState = getSectionPermissions(section[0])
      let permissionState = this.state.sectionTabsConfiguration
      permissionState = { ...permissionState, ...newState }
      this.setState({ sectionTabsConfiguration: permissionState })
    }
  }

  render () {
    const { t, reportTypeCode, powerbiConfig, actions: { powerBiRefreshSession } } = this.props
    const tKey = 'REPORTS.'
    if (reportTypeCode === reportCode.default) {
      return <DefaultHomeReport tKey={tKey} t={t} sectionTabsConfiguration={this.state.sectionTabsConfiguration} />
    }

    if (powerbiConfig && Object.keys(powerbiConfig).length > 0) {
      return <PowerBiHomeReport powerbiConfig={powerbiConfig} powerBiRefreshSession={powerBiRefreshSession} />
    }
    return null
  }
}

export default Report
