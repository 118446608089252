
import React, { PureComponent } from 'react'
import { Button, Modal, Col, Row, FormGroup } from 'react-bootstrap'
import { reduxForm, Field } from 'redux-form'
import TextInput from '../../commons/TextInput'
import InputCheckbox from '../../commons/form/InputCheckBox'
import InputNumber from '../../commons/form/InputNumber'
import InputSelect from '../../commons/form/InputSelect'

class SaeTypeModal extends PureComponent {
  constructor () {
    super()
    this.state = {
      hasPaperwork: false,
      dossierSubTypeChange: false
    }
    this.required = this.required.bind(this)
  }

  submitSaleType (values) {
    const SaleTypeLanguages = []
    Object.keys(values).map(item => {
      if (item.includes('lang')) {
        SaleTypeLanguages.push(values[item])
      }
    })
    var saleType = {}
    saleType.saleTypeId = this.props.saleTypeId
    saleType.code = values.code
    saleType.hasPaperwork = values.hasPaperwork
    saleType.dossierSubTypeId = values.dossierSubTypeId
    saleType.functionHasPaperwork = values.functionHasPaperwork
    saleType.SaleTypeLanguages = SaleTypeLanguages
    saleType.order = values.order

    let hasChanges = false

    if (this.props.saleType &&
      (values.code !== this.props.saleType.code ||
        values.hasPaperwork !== this.props.saleType.hasPaperwork ||
        this.state.dossierSubTypeChange ||
        (values.functionHasPaperwork !== null && values.functionHasPaperwork !== this.props.saleType.functionHasPaperwork) ||
        (values.order !== null && values.order !== this.props.saleType.order))) {
      hasChanges = true
    }
    this.props.actions.saveSaleTypeModal(saleType, hasChanges, false)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.showModal !== this.props.showModal && this.props.showModal === true) {
      this.props.initialize({ ...this.props })
      this.setState({ dossierSubTypeChange: false })
    }
    if (this.props.saleTypeLanguage !== nextProps.saleTypeLanguage && nextProps.saleTypeId !== this.props.saleTypeId || this.props.showModal !== nextProps.showModal) {
      this.props.actions.fetchDossierSubTypes()
      this.props.initialize(nextProps.saleType)
      nextProps.languageList.map((language) => {
        this.props.change(`lang${language.languageId}.languageId`, language.languageId)
        const source = nextProps.saleTypeLanguage ? nextProps.saleTypeLanguage.find((sourceChannel) => sourceChannel.languageId === language.languageId) : null
        this.props.change(`lang${language.languageId}.name`, source ? source.name : '')
        this.props.change(`lang${language.languageId}.description`, source ? source.description : '')
      })
    }
  }

  required (value) {
    if (value || value === 0) {
      return undefined
    } else {
      return this.props.t('GLOBAL.FORMS.REQUIRED')
    }
  }

  changeDossierSubType (value) {
    let dossierSubTypeChange
    if (this.props.saleType && ((value === null || value === undefined) && (this.props.saleType.dossierSubTypeId === null || this.props.saleType.dossierSubTypeId === undefined))) {
      dossierSubTypeChange = false
    } else if (this.props.saleType && value !== this.props.saleType.dossierSubTypeId) {
      dossierSubTypeChange = true
    } else {
      dossierSubTypeChange = false
    }
    this.setState({ dossierSubTypeChange: dossierSubTypeChange })
  }

  checkHasPaperwork (val) {
    this.setState({ hasPaperwork: val })
  }

  render () {
    const {
      t, showModal, handleSubmit, languageList = [],
      combos: { dossierSubtypesCombo }

    } = this.props
    const tKey = 'MASTERS.SALE_TYPE.'
    return (
      <Modal show={showModal} onHide={this.props.actions.closeEditSaleTypeModal} className="languages-modal">
        <Modal.Header closeButton>
          <i className="ico-uo" /><Modal.Title>{t(`${tKey}TITLE`)}</Modal.Title>
        </Modal.Header>
        <form autoComplete="off" onSubmit={handleSubmit(this.submitSaleType.bind(this))}>
          <Modal.Body>
            <h2>{t(`${tKey}CODE`)}</h2>
            <Row>
              <Col sm={6} className="custom-width-col">
                <Field
                  name={'code'}
                  placeholder={t(`${tKey}CODE`)}
                  component={TextInput}
                  maxLength={50}
                  validate={this.required}
                />
              </Col>
              <Col>
                <Field
                  component={InputCheckbox}
                  name={'hasPaperwork'}
                  id={'hasPaperwork'}
                  placeholder ={t(`${tKey}HAS_PAPERWORK`)}
                  checked = {this.state.hasPaperwork}
                  onChange = {(value) => this.checkHasPaperwork(value) }
                />
              </Col>
            </Row>
            <Row>
              <Col sm={8} className="custom-width-col">
                <h2>{t(`${tKey}FUNCTION_HAS_PAPERWORK`)}</h2>
                <Field
                  name={'functionHasPaperwork'}
                  placeholder={t(`${tKey}FUNCTION_HAS_PAPERWORK`)}
                  component={TextInput}
                  maxLength={50}
                />
              </Col>
              <Col sm={4} className="custom-width-col">
                <h2>{t(`${tKey}ORDER`)}</h2>
                <Field
                  name={'order'}
                  placeholder={t(`${tKey}ORDER`)}
                  component={InputNumber}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12} className="custom-width-col">
                <Field
                  colSm={12}
                  id="dossierSubTypeId"
                  name="dossierSubTypeId"
                  controlLabel={t(`${tKey}DOSSIER_SUBTYPES`)}
                  placeholder={t(`${tKey}DOSSIER_SUBTYPES`)}
                  component={InputSelect}
                  options={dossierSubtypesCombo}
                  valueKey="id"
                  labelKey="value"
                  disabled={!dossierSubtypesCombo || dossierSubtypesCombo.length === 0}
                  onChange={(value) => this.changeDossierSubType(value)}
                />
              </Col>
            </Row>
            <div className="clearfix" />
            <Row>
              <Row className="title-column">
                <Col sm={4}>
                  <h2>{t('MASTERS.NAME')}</h2>
                </Col>
                <Col sm={6}>
                  <h2>{t('MASTERS.DESCRIPTION')}</h2>
                </Col>
                <Col sm={2}>
                  <h2>{t('MASTERS.LANGUAGE')}</h2>
                </Col>
              </Row>
              <FormGroup>
                {languageList.map((val, idx) => {
                  return (
                    <Row key={idx} className="inputs-wrapper">
                      <Col sm={4} className="custom-width-col">
                        <Field
                          name={`lang${val.languageId}.name`}
                          placeholder={t('MASTERS.NAME')}
                          component={TextInput}
                          maxLength={50}
                        />
                      </Col>
                      <Col sm={6} className="custom-width-col">
                        <Field
                          name={`lang${val.languageId}.description`}
                          placeholder={t('MASTERS.DESCRIPTION')}
                          component={TextInput}
                          maxLength={50}
                        />
                      </Col>
                      <Col sm={2} className="language-column">
                        <p>{`${val.description} (${val.name})`}</p>
                      </Col>
                    </Row>
                  )
                })}
              </FormGroup>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Col sm={12} className="btn-wrapper">
              <Button type="submit" className="btn-standard">
                {t('MASTERS.SAVE')}
              </Button>
            </Col>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}
const validate = (values, props) => {
  const errors = { }
  props.languageList.map(language => {
    errors[`lang${language.languageId}`] = {}
    if (values[`lang${language.languageId}`]) {
      if (!(values[`lang${language.languageId}`].name)) {
        errors[`lang${language.languageId}`].name = props.t('MASTERS.VALIDATION.NAME_REQUIRED')
      }
      if (!(values[`lang${language.languageId}`].description)) {
        errors[`lang${language.languageId}`].description = props.t('MASTERS.VALIDATION.DESCRIPTION_REQUIRED')
      }
    } else {
      errors[`lang${language.languageId}`].description = props.t('MASTERS.VALIDATION.DESCRIPTION_REQUIRED')
    }
  })
  return errors
}

export default reduxForm({
  form: 'saeTypeModal',
  validate
})(SaeTypeModal)
