import dossierActionTypes from '../../constants/actions/dossier/dossier'
import { refreshAuditInfo, fetchAuditInfoSucces, fetchAuditDetails, fetchAuditDetailsModal, fetchDocumentsGroupedAuditDetails, fetchDocumentsGroupedAuditDetailsModal } from '../../actions/audit/auditInfo'

export function fetchStockDossier (dossierId, resolve, reject, historical) {
  return {
    type: dossierActionTypes.FETCH_STOCK_DOSSIER,
    dossierId,
    resolve,
    reject,
    historical
  }
}

export function fetchStockDossierSuccess (dossier, checkListHeader) {
  return {
    type: dossierActionTypes.FETCH_STOCK_DOSSIER_SUCCESS,
    dossier,
    checkListHeader
  }
}

export function restartStockDossierState () {
  return {
    type: dossierActionTypes.RESTART_STOCK_DOSSIER_STATE
  }
}

export function saveStockDossier (stockDossier) {
  return {
    type: dossierActionTypes.SAVE_STOCK_DOSSIER,
    stockDossier
  }
}

export function saveIssueStock (issue) {
  return {
    type: dossierActionTypes.SAVE_ISSUE_STOCK,
    issue
  }
}

export function openOrCloseIssueModal (openClose) {
  return {
    type: dossierActionTypes.OPEN_OR_CLOSE_ISSUE_MODAL,
    openClose
  }
}

export function deleteDossierRating (ratingId, resolve, reject) {
  return {
    type: dossierActionTypes.DELETE_DOSSIER_RATING,
    ratingId,
    resolve,
    reject
  }
}

export function deleteDossierRatingSuccess (dossierRatingComponent) {
  return {
    type: dossierActionTypes.DELETE_DOSSIER_RATING_SUCCESS,
    dossierRatingComponent
  }
}

export function addDossierRating (ratingId, dossierRatingRatingId, resolve, reject) {
  return {
    type: dossierActionTypes.ADD_DOSSIER_RATING,
    ratingId,
    dossierRatingRatingId,
    resolve,
    reject
  }
}

export function addDossierRatingSuccess (dossierRatingComponent) {
  return {
    type: dossierActionTypes.ADD_DOSSIER_RATING_SUCCESS,
    dossierRatingComponent
  }
}

export function getDossierPhoto (dossierId, resolve, reject) {
  return {
    type: dossierActionTypes.GET_DOSSIER_PHOTO,
    dossierId,
    resolve,
    reject
  }
}

export function getDossierPhotoSuccess (dossierPhotosComponent) {
  return {
    type: dossierActionTypes.GET_DOSSIER_PHOTO_SUCCESS,
    dossierPhotosComponent
  }
}

export function stockDossierWarningSave (reset, router, nextLocation, pristine) {
  return {
    type: dossierActionTypes.STOCK_DOSSIER_WARNING_SAVE,
    reset,
    router,
    nextLocation,
    pristine
  }
}

export function fetchDossierRelatedDossiersSuccess (dossierRelatedDossiers) {
  return {
    type: dossierActionTypes.FETCH_DOSSIER_RELATED_DOSSIERS_SUCCESS,
    dossierRelatedDossiers
  }
}

export function fetchStockDossierVehicle (dossierId, resolve, reject, pristine) {
  return {
    type: dossierActionTypes.FETCH_STOCK_DOSSIER_VEHICLE,
    dossierId,
    resolve,
    reject,
    pristine
  }
}

export function fetchStockDossierVehicleHistorical (dossierId, resolve, reject) {
  return {
    type: dossierActionTypes.FETCH_STOCK_DOSSIER_VEHICLE_HISTORICAL,
    dossierId,
    resolve,
    reject
  }
}

export function stockDossierApprove (dossierId, reset) {
  return {
    type: dossierActionTypes.STOCK_DOSSIER_APPROVE,
    dossierId,
    reset
  }
}

export function stockResolveIssue (pristine) {
  return {
    type: dossierActionTypes.RESOLVE_STOCK_ISSUE,
    pristine
  }
}

export function stockRequestSpareParts (pristine, dossierId, resolve) {
  return {
    type: dossierActionTypes.REQUEST_STOCK_SPARE_PARTS,
    pristine,
    dossierId,
    resolve
  }
}

export function stockCompleteSpareParts (pristine, dossierId, resolve) {
  return {
    type: dossierActionTypes.COMPLETE_STOCK_SPARE_PARTS,
    pristine,
    dossierId,
    resolve
  }
}

export function stockCompleteDeliveryPreparation (pristine, dossierId, resolve) {
  return {
    type: dossierActionTypes.COMPLETE_STOCK_DELIVERY_PREPARATION,
    pristine,
    dossierId,
    resolve
  }
}

export function saveStocksAvailabilityDate (dossierIdArray, availabilityDate, resolve, reject) {
  return {
    type: dossierActionTypes.SAVE_STOCKS_AVAILABILITY_DATE,
    dossierIdArray,
    availabilityDate,
    resolve,
    reject
  }
}

export function saveStocksConfirmationLogitics (dossierIdArray, resolve) {
  return {
    type: dossierActionTypes.SAVE_STOCKS_CONFIRMATION_LOGISTICS,
    dossierIdArray,
    resolve
  }
}

export function fetchStockDefaultDestination (value) {
  return {
    type: dossierActionTypes.FETCH_STOCK_DEFAULT_DESTINATION,
    value
  }
}

export function confirmDateWithIdByStage (dossierId, callback, pristine) {
  return {
    type: dossierActionTypes.STOCK_DOSSIER_VO_CONFIRM_DATE,
    dossierId,
    callback,
    pristine
  }
}

export function confirmPhotos (dossierId, typeButton, confirm, callback) {
  return {
    type: dossierActionTypes.STOCK_DOSSIER_VO_CONFIRM_PHOTO,
    dossierId,
    typeButton,
    confirm,
    callback
  }
}

export function getDossierBudget (dossierId, isHistorical, pristine, resolve, reject) {
  return {
    type: dossierActionTypes.STOCK_DOSSIER_GET_BUDGET,
    dossierId,
    isHistorical,
    pristine,
    resolve,
    reject
  }
}

export function updateDossierBudgetRatingModal (dossierBudgetRatingId) {
  return {
    type: dossierActionTypes.UPDATE_DOSSIER_BUDGET_RATING_MODAL,
    dossierBudgetRatingId
  }
}

export function fetchDossierBudgetRating (dossierBudgetRatingId) {
  return {
    type: dossierActionTypes.FETCH_DOSSIER_BUDGET_RATING,
    dossierBudgetRatingId
  }
}

export function deleteDossierBudgetRating (dossierBudgetRatingId) {
  return {
    type: dossierActionTypes.DELETE_DOSSIER_BUDGET_RATING,
    dossierBudgetRatingId
  }
}

export function addEditDossierBudgetRating (values) {
  return {
    type: dossierActionTypes.ADD_EDIT_DOSSIER_BUDGET_RATING,
    values
  }
}

export function rejectDossierStockVoBudget (id) {
  return {
    type: dossierActionTypes.STOCK_DOSSIER_VO_REJECT_BUDGET,
    id
  }
}

export function changeRelationEntryValues (value, key) {
  return {
    type: dossierActionTypes.STOCK_DOSSIER_VO_CHANGE_ENTRY_RELATION,
    value,
    key
  }
}

export function activateDossierCrane (dossierId, pristine) {
  return {
    type: dossierActionTypes.STOCK_DOSSIER_VO_ACTIVATE_CRANE,
    dossierId,
    pristine
  }
}

export function deactivateDossierCrane (dossierId, pristine) {
  return {
    type: dossierActionTypes.STOCK_DOSSIER_VO_DEACTIVATE_CRANE,
    dossierId,
    pristine
  }
}

export function dossierCraneConfirmDate (dossierId) {
  return {
    type: dossierActionTypes.STOCK_DOSSIER_DOSSIER_CRANE_CONFIRM_DATE,
    dossierId
  }
}

export function skipDossierPhotos (dossierId) {
  return {
    type: dossierActionTypes.SKIP_DOSSIER_PHOTOS,
    dossierId
  }
}

export default {
  fetchStockDossier,
  fetchStockDossierSuccess,
  restartStockDossierState,
  saveStockDossier,
  saveIssueStock,
  openOrCloseIssueModal,
  stockDossierWarningSave,
  fetchDossierRelatedDossiersSuccess,
  refreshAuditInfo,
  fetchAuditInfoSucces,
  fetchAuditDetails,
  fetchAuditDetailsModal,
  fetchStockDossierVehicle,
  fetchStockDossierVehicleHistorical,
  stockDossierApprove,
  stockResolveIssue,
  stockRequestSpareParts,
  stockCompleteSpareParts,
  stockCompleteDeliveryPreparation,
  saveStocksAvailabilityDate,
  fetchStockDefaultDestination,
  confirmDateWithIdByStage,
  confirmPhotos,
  getDossierBudget,
  deleteDossierRating,
  deleteDossierRatingSuccess,
  addDossierRating,
  addDossierRatingSuccess,
  updateDossierBudgetRatingModal,
  fetchDossierBudgetRating,
  deleteDossierBudgetRating,
  addEditDossierBudgetRating,
  rejectDossierStockVoBudget,
  changeRelationEntryValues,
  activateDossierCrane,
  deactivateDossierCrane,
  dossierCraneConfirmDate,
  getDossierPhoto,
  getDossierPhotoSuccess,
  skipDossierPhotos,
  fetchDocumentsGroupedAuditDetails,
  fetchDocumentsGroupedAuditDetailsModal
}
