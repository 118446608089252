import React, { PureComponent } from 'react'
import { Col, Row, Button, Glyphicon, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { urgencyTypesId, urgencyTypesClass, permissions } from '../../../../constants/backendIds'
import { /* compareArrays, */ compareObjects } from '../../../../util/utils'
import { getHeaderPermisions } from '../../../../util/permisionFunctions'
import { FoldTooltip, GoBackTooltip } from '../../../commons/Tooltips'
import TimeLine from './TimeLine'
import StageHeader from './StageHeader'
import settings from '../../../../setting'

class StatusHeader extends PureComponent {
  constructor () {
    super()
    this.state = {
      isVehicleLoaded: false,
      isClientLoaded: false,
      isFinanceLoaded: false,
      isDeliveryLoaded: false,
      isEconomicLoaded: false,
      isSelfSupplyLoaded: false,
      componentsConfiguration: {
        summary: {
          code: 'summary',
          permission: permissions.hidden
        },
        stages: {
          code: 'stages',
          permission: permissions.hidden
        },
        checklist: {
          code: 'checklist',
          permission: permissions.hidden
        },
        qrcode: {
          code: 'qrcode',
          permission: permissions.hidden
        }
      }
    }
  }

  initializePermisions (props) {
    const newState = getHeaderPermisions(props.headerTabConfiguration)
    this.setState({ ...this.state, componentsConfiguration: { ...newState } })
  }

  handleClick () {
    this.props.actions.toggleTimeline()
  }

  calcStatusHeaderHeight () {
    const header = document.querySelector('.navbar-headerPage') || {}
    const statusSidebar = document.querySelector('#dossier-header .dossier-header') || {}
    return header.clientHeight + statusSidebar.clientHeight + 1
  }

  UNSAFE_componentWillMount () {
    if (this.props.headerTabConfiguration) this.initializePermisions(this.props)
  }

  componentDidMount () {
    this.props.actions.setStatusHeaderHeight(this.calcStatusHeaderHeight())
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.headerTabConfiguration && !compareObjects(this.props.headerTabConfiguration || {}, nextProps.headerTabConfiguration, true)) {
      this.initializePermisions(nextProps)
    }
  }

  componentDidUpdate (prevProps) {
    /* if (prevProps.isFolded !== this.props.isFolded || prevProps.windowWidth !== this.props.windowWidth || !compareArrays(prevProps.stages, this.props.stages, true)) {
      this.props.actions.setStatusHeaderHeight(this.calcStatusHeaderHeight())
    } */
    this.props.actions.setStatusHeaderHeight(this.calcStatusHeaderHeight())
  }

  componentWillUnmount () {
    this.props.actions.resetStatusHeader()
  }

  handleToUpdateVehicle () {
    this.setState({ isVehicleLoaded: true })
  }

  handleToUpdateClient () {
    this.setState({ isClientLoaded: true })
  }

  handleToUpdateFinance () {
    this.setState({ isFinanceLoaded: true })
  }

  handleToUpdateDelivery () {
    this.setState({ isDeliveryLoaded: true })
  }

  handleToUpdateEconomic () {
    this.setState({ isEconomicLoaded: true })
  }

  switchFavorite (currentFavoriteStatus) {
    this.props.actions.switchFavorite(currentFavoriteStatus, this.props.dossierId, false)
  }

  render () {
    const {
      t, headerData, stages, dossierType, dossierSubType, dossierId, isFolded, activeTab,
      showEconomicPlan = false, vinChassis, licensePlate, showChassis, showCreator,
      pristine, creatorName, defaultSalesmen, readOnlyFields, editableDoc, favorite
    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.HEADER.'
    const tooltipUrgency = (
      <Tooltip container={this} id="tooltipUrgency">
        {headerData.urgencyType === urgencyTypesId.high && t(`${tKey}URGENCY_TYPES.EXPIRED`)}
        {headerData.urgencyType === urgencyTypesId.medium && t(`${tKey}URGENCY_TYPES.ABOUT_TO_EXPIRE`)}
        {headerData.urgencyType === urgencyTypesId.low && t(`${tKey}URGENCY_TYPES.ON_TIME`)}
        &nbsp;
        {headerData.urgencyType === urgencyTypesId.high ? t(`${tKey}FROM`) : t(`${tKey}TO`)}
        &nbsp;
        {headerData.urgencyDate}
      </Tooltip>
    )
    const tooltipCreationDateDealerNumber = (
      <Tooltip id="tooltipCreationDateDealerNumber">
        {headerData.dealerNumber !== undefined && (t(`${tKey}DEALER_NUMBER`) + ' / ')}
        {t(`${tKey}CREATION_DATE`)}
      </Tooltip>
    )
    const tooltipRentalOderNumber = (
      <Tooltip id="tooltipRentalOderNumber">
        {headerData.rentalOrderNumber !== undefined && (t(`${tKey}RENTALORDER_NUMBER`))}
      </Tooltip>
    )
    const tooltipChassis = (
      <Tooltip id="tooltipChassis">
        {vinChassis !== undefined && (t(`${tKey}CHASSIS`))}
      </Tooltip>
    )
    const tooltipCreator = (
      <Tooltip id="tooltipCreator">
        {creatorName !== undefined && (t(`${tKey}CREATOR`))}
      </Tooltip>
    )
    const FavoriteTooltip = (
      <Tooltip id="FavoriteTooltip">
        {favorite === true ? (t(`${tKey}FAVORITES.UNMARK`)) : (t(`${tKey}FAVORITES.MARK`))}
      </Tooltip>
    )

    const shoWFolderClient = !isFolded

    return (
      <Row id="dossier-header">
        <Col sm={12}>
          <div className="dossier-header fixed">
            {this.state.componentsConfiguration.summary && this.state.componentsConfiguration.summary.permission > permissions.hidden && (
              <Row>
                <Col sm={3}>
                  <div className="text-band">
                    <span className="numero">
                      {settings.enableFavoritesAndRecents &&
                        <OverlayTrigger container={document.getElementById('Favorite')} placement="bottom" overlay={FavoriteTooltip}>
                          <a id='Favorite' className='Favorite' onClick={() => this.switchFavorite(favorite)}><i className={favorite ? 'iDocIcon-favorite' : 'iDocIcon-unfavorite'}></i></a>
                        </OverlayTrigger>

                      }
                      {settings.enableFavoritesAndRecents && headerData.number}
                    </span>

                    <span className="second numero_expediente">
                      <div id="symb" className="symbol">
                        <OverlayTrigger container={document.getElementById('symb')} placement="bottom" overlay={GoBackTooltip({ t: t })}>
                          <Button className="btn-go-back" id="btn_back" onClick={() => this.props.goBack()}>
                            <Glyphicon glyph="arrow-left"/>
                          </Button>
                        </OverlayTrigger>
                        <OverlayTrigger container={document.getElementById('colorUrgency')} placement="right" overlay={tooltipUrgency} >
                          <span id="colorUrgency" className={'cursorHelp color-point big ' + urgencyTypesClass[headerData.urgencyType]}/>
                        </OverlayTrigger>
                      </div>
                      <div className="info">
                        {!settings.enableFavoritesAndRecents &&
                          <span className="numero">{headerData.number}</span>
                        }
                        <OverlayTrigger container={document.getElementById('numId')} placement="bottom" overlay={tooltipCreationDateDealerNumber} >
                          <div id="numId" className="fecha cursorHelp">{headerData.dealerNumber === undefined ? '' : headerData.dealerNumber + ' / '}{headerData.creationDate}</div>
                        </OverlayTrigger>
                        <span className="fecha">{headerData.salesman}</span>
                        <OverlayTrigger container={document.getElementById('roNumber')} placement="bottom" overlay={tooltipRentalOderNumber} >
                          <span id="roNumber" className="fecha cursorHelp">{headerData.rentalOrderNumber}</span>
                        </OverlayTrigger>
                        {showChassis && (<OverlayTrigger placement="bottom" overlay={tooltipChassis} >
                          <span className="fecha">{vinChassis} {licensePlate}</span>
                        </OverlayTrigger>
                        )}
                        {showCreator && (<OverlayTrigger placement="bottom" overlay={tooltipCreator} >
                          <span className="fecha">{creatorName}</span>
                        </OverlayTrigger>
                        )}
                      </div>
                    </span>
                  </div>
                </Col>
                <Col sm={2}>
                  <div className="text-band">
                    <span className="first">{t(`${tKey}UO`)}:</span>
                    <span className="second" id="organizedUnit">{headerData.organizedUnit}</span>
                  </div>
                </Col>
                <Col sm={2}>
                  <div className="text-band">
                    <span className="first">{t(`${tKey}DOSSIER_SUBTYPE`)}:</span>
                    <span className="second" id="dossierSubType">{headerData.dossierSubType}</span>
                  </div>
                </Col>
                <Col sm={2}>
                  <div className="text-band">
                    <span className="first">{t(`${tKey}FUNCTIONAL_STATUS`)}:</span>
                    <span className="second" id="stage">{headerData.stage}</span>
                  </div>
                </Col>
                <Col sm={2}>
                  <div className="text-band">
                    <span className="first">{t(`${tKey}POPOVER.DOCUMENTS`)}:</span>
                    <div className="iconos_estado_documental second">
                      <i className="ico-cloud"/>{headerData.uploaded}/{headerData.pendingUpload}
                      <Glyphicon glyph="ok-sign"/>{headerData.validated}/{headerData.pendingValidate}
                    </div>
                  </div>
                </Col>
                {!readOnlyFields && ([
                  <Col sm={1} key={1}>
                    <OverlayTrigger container={document.getElementById('btn_separator_up')} placement="bottom" overlay={FoldTooltip({ t: t, isFolded: isFolded })} >
                      <Button onClick={this.handleClick.bind(this)} className={'first btn-time-line btn-icon ' + (!isFolded ? 'dirty' : '')} id="btn_separator_up" >
                        <span className="icono-menos">-</span>
                        <span>{t(`${tKey}TIME_LINE`)}</span>
                      </Button>
                    </OverlayTrigger>
                  </Col>,
                  <OverlayTrigger container={document.getElementById('btn_separator')} placement="bottom" overlay={FoldTooltip({ t: t, isFolded: isFolded })} key={2}>
                    <Button className="btn-separator" onClick={this.handleClick.bind(this)} id="btn_separator">
                      <span>{shoWFolderClient !== true ? t(`${tKey}SHOW_HEADER`) : t(`${tKey}HIDE_HEADER`)}</span>
                    </Button>
                  </OverlayTrigger>
                ])}
              </Row>
            )}
            <Row>
              {!readOnlyFields && ([
                <StageHeader
                  t={t}
                  stagesPermission={this.state.componentsConfiguration.stages.permission}
                  stages={stages}
                  defaultSalesmen={defaultSalesmen}
                  dossierType={dossierType}
                  key={1}
                />,
                <TimeLine
                  t={t}
                  show={shoWFolderClient}
                  stages={stages}
                  dossierId={dossierId}
                  dossierType={dossierType}
                  dossierSubType={dossierSubType}
                  activeTab={activeTab}
                  showEconomicPlan={showEconomicPlan}
                  stagesPermission={this.state.componentsConfiguration.stages.permission}
                  checklistPermission={this.state.componentsConfiguration.checklist.permission}
                  qrcodePermission={this.state.componentsConfiguration.qrcode !== undefined ? this.state.componentsConfiguration.qrcode.permission : permissions.hidden}
                  handleToUpdateVehicle = {this.handleToUpdateVehicle.bind(this)}
                  isVehicleLoaded = {this.state.isVehicleLoaded}
                  handleToUpdateClient= {this.handleToUpdateClient.bind(this)}
                  isClientLoaded = {this.state.isClientLoaded}
                  handleToUpdateFinance= {this.handleToUpdateFinance.bind(this)}
                  isFinanceLoaded = {this.state.isFinanceLoaded}
                  handleToUpdateDelivery = {this.handleToUpdateDelivery.bind(this)}
                  isDeliveryLoaded = {this.state.isDeliveryLoaded}
                  handleToUpdateEconomic = {this.handleToUpdateEconomic.bind(this)}
                  isEconomicLoaded = {this.state.isEconomicLoaded}
                  pristine={pristine}
                  editableDoc={editableDoc !== undefined && editableDoc !== null ? editableDoc : true}
                  key={2}
                  number={headerData.number}
                  isSelfSupplyLoaded = {this.props.isSelfSupplyLoaded}
                  handleToUpdateSelfSupply = {() => this.props.handleToUpdateSelfSupply()}
                  actions={this.props.actions}
                />
              ])}
            </Row>
          </div>
        </Col>
      </Row>
    )
  }
}

export default StatusHeader
