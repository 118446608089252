import React, { PureComponent } from 'react'
import 'rc-tree-select/assets/index.css'
import TreeSelect from 'rc-tree-select'
import { Col, ControlLabel } from 'react-bootstrap'
import setting from '../../../setting'
import { getTitleFromTreeSelectObject } from '../../../util/utils'

class InputTreeSelect extends PureComponent {
  constructor (props) {
    super()
    const state = {
      tsOpen: false,
      class: `input-tree-select-${props.input.name.replace('.', '-')}`,
      options: this.formatTreeData(props),
      value: []
    }
    if (props.multi) {
      state.value = (props.options.length > 0 && !!props.input.value) ? props.input.value.split(',').map(value => { return { value } }) : []
    } else {
      state.value = props.input.value || undefined
    }
    this.state = state
    this.handleChange = this.handleChange.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.getTagPlaceHolder = this.getTagPlaceHolder.bind(this)
  }

  getSnapshotBeforeUpdate (prevProps, prevState) {
    const state = {}
    if (prevProps.options !== this.props.options) {
      state.options = this.formatTreeData(this.props)
    }
    if (this.props.options.length > 0 && (!prevState.value || prevProps.input.value !== this.props.input.value)) {
      if (this.props.multi) {
        state.value = this.props.input.value && this.props.input.value !== '' && typeof this.props.input.value === 'string' ? this.props.input.value.split(',').map(value => { return { value } }) : []
      } else {
        state.value = this.props.input.value || undefined
      }
    }
    if (prevProps.input.name !== this.props.input.name) {
      state.class = `.input-tree-select-${this.props.input.name.replace('.', '-')}`
    }
    return state
  }

  componentDidUpdate (prevProps, nextProps, snapshot) {
    if (Object.keys(snapshot).length > 0) {
      this.setState(snapshot)
    }
  }

  componentDidMount () {
    window.addEventListener('click', this.handleClick)
  }

  componentWillUnmount () {
    window.removeEventListener('click', this.handleClick)
  }

  formatTreeData ({ options, valueKey, labelKey, childrenKey }) {
    return options.map((current) => {
      return {
        key: '' + current[valueKey],
        value: '' + current[valueKey],
        title: current[labelKey],
        children: current[childrenKey] ? this.formatTreeData({ options: current[childrenKey], valueKey, labelKey, childrenKey }) : []
      }
    })
  }

  handleChange (value) {
    let inputValue = ''
    if (this.props.multi && Array.isArray(value) && value.length > 0) {
      inputValue = value.map(el => el.value).join(',')
    } else if (!this.props.multi) {
      inputValue = value
    }
    if (inputValue === undefined) {
      inputValue = null
    }
    if (this.props.input.value !== inputValue) {
      this.props.input.onChange(inputValue)
      if (this.props.onInputChange) this.props.onInputChange(inputValue)
    }
  }

  handleBlur (event) {
    this.props.input.onBlur(event)
    if (this.props.onInputBlur) this.props.onInputBlur(this.props.input.value)
  }

  handleClick (event) {
    let tsOpen = this.state.tsOpen
    let targetClass = event.target.classList
    // Next line ensure this is the current inputTreeSelect because each one adds an onClick() handler into the window global variable.
    if (this.props.disabled || (!tsOpen && !targetClass.contains(this.state.class))) return

    if (this.props.multi) {
      if (!targetClass.contains('rc-tree-select-selection__choice__remove') && !targetClass.contains('rc-tree-select-selection__clear')) {
        const treeSelect = document.querySelector(`.input-tree-select.${this.state.class}.rc-tree-select`)
        const dropdown = document.querySelector(`.input-tree-select.${this.state.class}.rc-tree-select-dropdown`)
        tsOpen = (treeSelect && treeSelect.contains(event.target)) || (dropdown && dropdown.contains(event.target)) || false
      }
      if (this.state.tsOpen !== tsOpen) {
        this.setState({ tsOpen })
        if (!tsOpen && this.props.onInputBlur) this.props.onInputBlur(this.props.input.value)
      }
    } else {
      if (targetClass.length <= 0) {
        targetClass = event.target.parentElement.classList
      }
      this.setState({ tsOpen: (!targetClass.contains('rc-tree-select-tree-title') && [...targetClass].join().includes('rc-tree-select')) })
    }
  }

  getTagPlaceHolder (valueList) {
    let data = `+ ${valueList.length}: `
    valueList.map((el, indx) => {
      data = data + '\n' + getTitleFromTreeSelectObject(this.state.options, el)
    })
    return data
  }

  render () {
    const {
      id, placeholder, controlLabel, customClass = '', popupContainer,
      colSm = 3, meta, disabled = false, multi = false, allowClear = true,
      filterTreeNode = false, showSearch = true
    } = this.props
    const errorMessage = (meta.touched && meta.error && typeof meta.error !== 'boolean') ? meta.error : null
    return (
      <Col sm={colSm} className={`${meta.touched && meta.error ? 'has-error' : ''} ${customClass}`}>
        <div className="form-group">
          {controlLabel && <ControlLabel>{controlLabel}</ControlLabel>}
          <TreeSelect
            id={id}
            treeLine
            multiple={multi}
            treeCheckable={multi}
            treeCheckStrictly={multi}
            showSearch={showSearch}
            treeNodeFilterProp='title'
            filterTreeNode={filterTreeNode}
            allowClear={allowClear}
            dropdownMatchSelectWidth={false}
            className="form-control"
            prefixCls={`input-tree-select ${this.state.class} rc-tree-select`}
            getPopupContainer={(node) => {
              if (popupContainer) {
                return document.querySelector(popupContainer)
              } else {
                return document.body
              }
            }}
            placeholder={placeholder}
            treeData={this.state.options}
            value={this.state.value}
            onChange={this.handleChange}
            open={this.state.tsOpen}
            onDropdownVisibleChange={() => false}
            disabled={disabled}
            maxTagCount={setting.maxTagCount}
            maxTagTextLength={setting.maxTagTextLength}
            maxTagPlaceholder={valueList => {
              return valueList.length > 0 ? this.getTagPlaceHolder(valueList) : `+ ${valueList.length}`
            }}
          />
          {errorMessage && <span className='help-block text-center'>{errorMessage}</span>}
        </div>
      </Col>
    )
  }
}

export default InputTreeSelect
