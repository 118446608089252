import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import ImportVehicleModelsMaster from '../../../components/masters/importVehicleModels/importVehicleModelsMaster'
import { importVehicleModelFile, cancelImportVehicleModelFile } from '../../../actions/vehicle/importVehicleModels'
import { openModal } from '../../../actions/common'

export function mapStateToProps (state) {
  return {
    importVehicleModels: state.importVehicleModels,
    combos: {
      brandCombo: state.combos.brandCombo
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      importVehicleModelFile,
      cancelImportVehicleModelFile,
      openModal
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(ImportVehicleModelsMaster))
