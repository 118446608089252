import { put, call, select, takeEvery } from 'redux-saga/effects'
import { getAuth } from '../../selectors/access/auth'
import { showLoader, hideLoader } from '../../actions/common'
import { fetchDashboardSuccess } from '../../actions/dashboard/dashboard'
import dashboardActionTypes from '../../constants/actions/dashboard/dashboard'
import getDashboard from '../../services/dossier/get/getDashboard'
import { handleError } from '../errors/errorManager'

export function * fetchFilteredDashboard ({ organizedUnit, salesmanId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const response = yield call(getDashboard, auth.token, organizedUnit, salesmanId)
    yield put(fetchDashboardSuccess(response, salesmanId))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchFilteredDashboard () {
  yield takeEvery(dashboardActionTypes.FETCH_FILTERED_DASHBOARD, fetchFilteredDashboard)
}
