import React, { PureComponent } from 'react'
import { Col, Row, Panel, Button } from 'react-bootstrap'
import { Field } from 'redux-form'
import { permissions } from '../../../constants/backendIds'
import { getFieldsPermisions } from '../../../util/permisionFunctions'
import { compareObjects } from '../../../util/utils'
import InputCheckboxSlider from '../../commons/form/InputCheckboxSlider'
import InputDatePicker from '../../commons/form/InputDatePicker'
import InputSelect from '../../commons/form/InputSelect'

export default class Crane extends PureComponent {
  constructor () {
    super()
    this.state = {
      open: false,
      fieldsConfiguration: {
        needCrane: null,
        destinationCraneId: null,
        confirmationDate: null,
        confirmationDoneButton: null
      }
    }
  }

  initializePermisions (props) {
    const fieldKeys = Object.keys(this.state.fieldsConfiguration)
    const newState = getFieldsPermisions(props.componentConfiguration.fieldsConfiguration, fieldKeys, props.componentConfiguration.permission)
    this.setState({ fieldsConfiguration: newState.fieldsConfiguration })
  }

  toggleComponent () {
    if (this.state.open) {
      this.props.actions.setActiveSection(false)
    }
    this.setState({ open: !this.state.open })
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (this.props.dossier && this.props.dossier.dossierId) {
      if (!compareObjects(prevProps.componentConfiguration || {}, this.props.componentConfiguration, true)) {
        this.initializePermisions(this.props)
      }
      if (!prevProps.open && this.props.open) {
        this.setState({ open: true })
      }
    }
    return prevProps
  }

  componentDidUpdate () {
    // don't remove use for getsnapshot
  }

  componentDidMount () {
    this.initializePermisions(this.props)
  }

  updateNeedCrane (value) {
    if (value) {
      this.props.actions.activateDossierCrane(this.props.dossierId, this.props.pristine)
    } else {
      this.props.actions.deactivateDossierCrane(this.props.dossierId, this.props.pristine)
    }
  }

  render () {
    const {
      t, readOnlyFields, dossierId, dossier: { crane }, combos: { destinationCraneCombo },
      actions: { dossierCraneConfirmDate }
    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.CRANE.'

    return (
      <Row className="entry-panel dossier-panel">
        <div className="header-panel" onClick={() => this.toggleComponent()}>
          <div className="name-wrapper">
            <i className="ico-i" />
            <h4>{t(`${tKey}TITLE_PANEL`)}</h4>
          </div>
          <i className={this.state.open ? 'ico-arrow-black-up' : 'ico-arrow-black-down'} />
        </div>
        <Panel expanded={this.state.open} onToggle={() => { }}>
          <Panel.Collapse>
            {!readOnlyFields && this.state.fieldsConfiguration.confirmationDoneButton && this.state.fieldsConfiguration.confirmationDoneButton.permission === permissions.editable && (<Row>
              <Col className="col-right">
                <Button className='btn-save' onClick={() => dossierCraneConfirmDate(dossierId)} >
                  {t(`${tKey}PERFORM`)}
                </Button>
              </Col>
            </Row>
            )
            }
            {this.state.fieldsConfiguration.needCrane && this.state.fieldsConfiguration.needCrane.permission !== permissions.hidden && (
              <Row className='flexRow'>
                <Field
                  id="needCrane"
                  name="crane.needCrane"
                  placeholder={t(`${tKey}NEED_CRANE`)}
                  component={InputCheckboxSlider}
                  colSm={0}
                  onChange={this.updateNeedCrane.bind(this)}
                  disabled={readOnlyFields || this.state.fieldsConfiguration.needCrane.permission !== permissions.editable} />
              </Row>

            )}
            <Row className='flexRow'>
              {this.state.fieldsConfiguration.destinationCraneId && this.state.fieldsConfiguration.destinationCraneId.permission !== permissions.hidden && (
                <Field
                  colSm={3}
                  id="destinationCraneId"
                  name="crane.destinationCraneId"
                  controlLabel={t(`${tKey}SEND_TO`)}
                  placeholder={t(`${tKey}SEND_TO`)}
                  component={InputSelect}
                  options={destinationCraneCombo}
                  valueKey="id"
                  labelKey="value"
                  disabled={readOnlyFields || !crane.needCrane || this.state.fieldsConfiguration.destinationCraneId.permission !== permissions.editable} />
              )}
              {this.state.fieldsConfiguration.confirmationDate && this.state.fieldsConfiguration.confirmationDate.permission !== permissions.hidden && (
                <Field
                  id="confirmationDate"
                  name="crane.confirmationDate"
                  colSm={3}
                  controlLabel={t(`${tKey}CONFIRM_DATE`)}
                  placeholder={t(`${tKey}CONFIRM_DATE`)}
                  component={InputDatePicker}
                  disabled={readOnlyFields || this.state.fieldsConfiguration.confirmationDate.permission !== permissions.editable}
                />
              )}
            </Row>
          </Panel.Collapse>
        </Panel>
      </Row>
    )
  }
}
