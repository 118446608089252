import mastersActionTypes from '../../constants/actions/masters/masters'

export function fetchEntityMasters () {
  return {
    type: mastersActionTypes.FETCH_ENTITY_MASTERS
  }
}

export function fetchEntityMastersSuccess (sourceChannelList) {
  return {
    type: mastersActionTypes.FETCH_ENTITY_MASTERS_SUCCESS,
    sourceChannelList
  }
}

export function openSourceChannelModal (sourceChannelId) {
  return {
    type: mastersActionTypes.OPEN_SOURCE_CHANNEL_MODAL,
    sourceChannelId
  }
}

export function openSourceChannelModalSuccess (languageList, sourceChannelsFields, sourceChannelId) {
  return {
    type: mastersActionTypes.OPEN_SOURCE_CHANNEL_MODAL_SUCCESS,
    languageList,
    sourceChannelsFields,
    sourceChannelId
  }
}

export function closeSourceChannelModal () {
  return {
    type: mastersActionTypes.CLOSE_SOURCE_CHANNEL_MODAL
  }
}

export function saveSourceChannelModal (sourceChannelValues, sourceChannelId) {
  return {
    type: mastersActionTypes.SAVE_SOURCE_CHANNEL,
    sourceChannelValues,
    sourceChannelId
  }
}

export function deleteSourceChannelModal (sourceChannelId) {
  return {
    type: mastersActionTypes.DELETE_SOURCE_CHANNEL,
    sourceChannelId
  }
}

export function openExtraSaleModal (uoId, extraSaleId = null) {
  return {
    type: mastersActionTypes.OPEN_EXTRA_SALE_MODAL,
    uoId,
    extraSaleId
  }
}

export function openExtraSaleModalSuccess (languageList, extraSaleFields, extraSaleId) {
  return {
    type: mastersActionTypes.OPEN_EXTRA_SALE_MODAL_SUCCESS,
    languageList,
    extraSaleFields,
    extraSaleId
  }
}

export function closeExtraSaleModal () {
  return {
    type: mastersActionTypes.CLOSE_EXTRA_SALE_MODAL
  }
}

export function saveExtraSaleModal (uoId, extraSaleValues, extraSaleId) {
  return {
    type: mastersActionTypes.SAVE_EXTRA_SALE,
    uoId,
    extraSaleValues,
    extraSaleId
  }
}

export function deleteExtraSaleModal (uoId, extraSaleId) {
  return {
    type: mastersActionTypes.DELETE_EXTRA_SALE_MASTERS,
    uoId,
    extraSaleId
  }
}

export function fetchExtraSalesMasters (lowestOrganizedUnitId) {
  return {
    type: mastersActionTypes.FETCH_EXTRA_SALES_MASTERS,
    lowestOrganizedUnitId
  }
}

export function fetchExtraSalesMastersSuccess (extraSalesMastersList) {
  return {
    type: mastersActionTypes.FETCH_EXTRA_SALES_MASTERS_SUCCESS,
    extraSalesMastersList
  }
}

export function recoverExtraSaleModal (uoId, extraSaleId) {
  return {
    type: mastersActionTypes.RECOVER_EXTRA_SALE,
    uoId,
    extraSaleId
  }
}

export function fetchSuppliedMasters (lowestOrganizedUnitId) {
  return {
    type: mastersActionTypes.FETCH_SUPPLIED_MASTERS,
    lowestOrganizedUnitId
  }
}

export function fetchSuppliedMastersSuccess (suppliedMastersList) {
  return {
    type: mastersActionTypes.FETCH_SUPPLIED_MASTERS_SUCCESS,
    suppliedMastersList
  }
}

export function openSuppliedModal (uoId, suppliedId = null) {
  return {
    type: mastersActionTypes.OPEN_SUPPLIED_MODAL,
    uoId,
    suppliedId
  }
}

export function openSuppliedModalSuccess (languageList, suppliedFields, suppliedId) {
  return {
    type: mastersActionTypes.OPEN_SUPPLIED_MODAL_SUCCESS,
    languageList,
    suppliedFields,
    suppliedId
  }
}

export function closeSuppliedModal () {
  return {
    type: mastersActionTypes.CLOSE_SUPPLIED_MODAL
  }
}

export function saveSuppliedModal (uoId, suppliedValues, suppliedId) {
  return {
    type: mastersActionTypes.SAVE_SUPPLIED_MASTER,
    uoId,
    suppliedValues,
    suppliedId
  }
}

export function deleteSuppliedModal (uoId, suppliedId) {
  return {
    type: mastersActionTypes.DELETE_SUPPLIED_MASTER,
    uoId,
    suppliedId
  }
}

export function recoverSuppliedModal (uoId, suppliedId) {
  return {
    type: mastersActionTypes.RECOVER_SUPPLIED_MASTER,
    uoId,
    suppliedId
  }
}

export function fetchPresentsMasters (lowestOrganizedUnitId) {
  return {
    type: mastersActionTypes.FETCH_PRESENTS_MASTERS,
    lowestOrganizedUnitId
  }
}

export function fetchPresentsMastersSuccess (presentsMastersList) {
  return {
    type: mastersActionTypes.FETCH_PRESENTS_MASTERS_SUCCESS,
    presentsMastersList
  }
}

export function openPresentsModal (uoId, presentsId = null) {
  return {
    type: mastersActionTypes.OPEN_PRESENTS_MODAL,
    uoId,
    presentsId
  }
}

export function openPresentsModalSuccess (languageList, presentsFields, presentsId) {
  return {
    type: mastersActionTypes.OPEN_PRESENTS_MODAL_SUCCESS,
    languageList,
    presentsFields,
    presentsId
  }
}

export function closePresentsModal () {
  return {
    type: mastersActionTypes.CLOSE_PRESENTS_MODAL
  }
}

export function savePresentsModal (uoId, presentsValues, presentsId) {
  return {
    type: mastersActionTypes.SAVE_PRESENTS,
    uoId,
    presentsValues,
    presentsId
  }
}

export function deletePresentsModal (uoId, presentsId) {
  return {
    type: mastersActionTypes.DELETE_PRESENTS,
    uoId,
    presentsId
  }
}

export function recoverPresentsModal (uoId, presentsId) {
  return {
    type: mastersActionTypes.RECOVER_PRESENTS,
    uoId,
    presentsId
  }
}

export function fetchFinancingList (filter) {
  return {
    type: mastersActionTypes.FETCH_FINANCING,
    filter
  }
}

export function fetchProcessingAgencyList (filter) {
  return {
    type: mastersActionTypes.FETCH_PROCESSING_AGENCY,
    filter
  }
}

export function fetchProcessingAgencyListSuccess (agencyList, filter) {
  return {
    type: mastersActionTypes.FETCH_PROCESSING_AGENCY_SUCCESS,
    agencyList,
    filter
  }
}

export function fetchProcessingAgencyQuotaList (filter) {
  return {
    type: mastersActionTypes.FETCH_PROCESSING_AGENCY_QUOTA,
    filter
  }
}

export function fetchProcessingAgencyQuotaListSuccess (quotaList, filter) {
  return {
    type: mastersActionTypes.FETCH_PROCESSING_AGENCY_QUOTA_SUCCESS,
    quotaList,
    filter
  }
}

export function fetchPaymentMethodList (filter) {
  return {
    type: mastersActionTypes.FETCH_PAYMENT_METHOD,
    filter
  }
}

export function fetchPaymentMethodListSuccess (paymentList, filter) {
  return {
    type: mastersActionTypes.FETCH_PAYMENT_METHOD_SUCCESS,
    paymentList,
    filter
  }
}

export function recoverPaymentMethod (paymentMethodId) {
  return {
    type: mastersActionTypes.RECOVER_PAYMENT_METHOD,
    paymentMethodId
  }
}
export function openEditPaymentMethodModal (paymentMethodId) {
  return {
    type: mastersActionTypes.OPEN_EDIT_PAYMENT_METHOD_MODAL,
    paymentMethodId
  }
}

export function openEditPaymentMethodModalSuccess (languageList, paymentMethodFields, paymentMethodId) {
  return {
    type: mastersActionTypes.OPEN_EDIT_PAYMENT_METHOD_MODAL_SUCCESS,
    languageList,
    paymentMethodFields,
    paymentMethodId
  }
}

export function openAddPaymentMethodModal () {
  return {
    type: mastersActionTypes.OPEN_ADD_PAYMENT_METHOD_MODAL
  }
}

export function openAddPaymentMethodModalSuccess (languageList) {
  return {
    type: mastersActionTypes.OPEN_ADD_PAYMENT_METHOD_MODAL_SUCCESS,
    languageList
  }
}

export function fetchFinancingListSuccess (financingList, filter) {
  return {
    type: mastersActionTypes.FETCH_FINANCING_SUCCESS,
    financingList,
    filter
  }
}
export function openEditProcessingAgencyModal (processingagencyId) {
  return {
    type: mastersActionTypes.OPEN_EDIT_PROCESSING_AGENCY_MODAL,
    processingagencyId
  }
}

export function openEditProcessingAgencyModalSuccess (agency) {
  return {
    type: mastersActionTypes.OPEN_EDIT_PROCESSING_AGENCY_MODAL_SUCCESS,
    agency
  }
}

export function closeEditPaymentMethodModal () {
  return {
    type: mastersActionTypes.CLOSE_EDIT_PAYMENT_METHOD_MODAL
  }
}
export function openEditFinancingModal (financingId) {
  return {
    type: mastersActionTypes.OPEN_EDIT_FINANCING_MODAL,
    financingId
  }
}

export function openEditFinancingModalUo (financing, page, pageSize, orderBy) {
  return {
    type: mastersActionTypes.OPEN_EDIT_FINANCING_MODAL_UO,
    financing,
    page,
    pageSize,
    orderBy
  }
}

export function openEditfinancingModalSuccess (financing) {
  return {
    type: mastersActionTypes.OPEN_EDIT_FINANCING_MODAL_SUCCESS,
    financing
  }
}

export function openEditfinancingModalUoSuccess (financing, uoList, page, pageSize, orderBy) {
  return {
    type: mastersActionTypes.OPEN_EDIT_FINANCING_MODAL_UO_SUCCESS,
    financing,
    uoList,
    page,
    pageSize,
    orderBy
  }
}

export function saveUoFinancingCompany (organizedUnitId, financing) {
  return {
    type: mastersActionTypes.SAVE_UO_FINANCING_COMPANY,
    organizedUnitId,
    financing
  }
}

export function saveUoFinancingCompanySucces (uoAddeds) {
  return {
    type: mastersActionTypes.SAVE_UO_FINANCING_COMPANY_SUCCESS,
    uoAddeds
  }
}

export function deleteUoFinancingCompany (organizedUnitId, financing) {
  return {
    type: mastersActionTypes.DELETE_UO_FINANCING_COMPANY,
    organizedUnitId,
    financing
  }
}

export function recoverUoFinancingCompany (organizedUnitId, financing) {
  return {
    type: mastersActionTypes.RECOVER_UO_FINANCING_COMPANY,
    organizedUnitId,
    financing
  }
}

export function openAddProcessingAgencyModal () {
  return {
    type: mastersActionTypes.OPEN_ADD_PROCESSING_AGENCY_MODAL
  }
}

export function openAddCampaignModalSuccess () {
  return {
    type: mastersActionTypes.OPEN_ADD_CAMPAIGN_MODAL_SUCCESS
  }
}

export function openAddCampaignGroupModalSuccess (languageList, campaign) {
  return {
    type: mastersActionTypes.OPEN_ADD_CAMPAIGN_GROUP_MODAL_SUCCESS,
    languageList,
    campaign
  }
}

export function openAddCampaignTypeModalSuccess (languageList, campaign) {
  return {
    type: mastersActionTypes.OPEN_ADD_CAMPAIGN_TYPE_MODAL_SUCCESS,
    languageList,
    campaign
  }
}

export function openAddFinancingModal () {
  return {
    type: mastersActionTypes.OPEN_ADD_FINANCING_MODAL
  }
}

export function openAddProcessingAgencyModalSuccess () {
  return {
    type: mastersActionTypes.OPEN_ADD_PROCESSING_AGENCY_MODAL_SUCCESS
  }
}

export function openAddProcessingAgencyAssignationModalSuccess (OUSelected) {
  return {
    type: mastersActionTypes.OPEN_ADD_PROCESSING_AGENCY_QUOTA_MODAL_SUCCESS,
    OUSelected
  }
}

export function closeEditProcessingAgencyAssignationModal () {
  return {
    type: mastersActionTypes.CLOSE_EDIT_PROCESSING_AGENCY_QUOTA_MODAL
  }
}

export function updateProcessingAgencyAssignation (initialquota) {
  return {
    type: mastersActionTypes.UPDATE_PROCESSING_AGENCY_QUOTA,
    initialquota
  }
}

export function openEditProcessingAgencyAssignationModalSuccess (quota, id) {
  return {
    type: mastersActionTypes.OPEN_EDIT_PROCESSING_AGENCY_QUOTA_MODAL_SUCCESS,
    quota,
    id
  }
}

export function openEditCampaignDetailsModalSuccess (campaign, id) {
  return {
    type: mastersActionTypes.OPEN_EDIT_CAMPAIGN_MODAL_SUCCESS,
    campaign
  }
}

export function openEditDocumentalConditionModalSuccess (campaign, id) {
  return {
    type: mastersActionTypes.OPEN_EDIT_DOCUMENT_CONDITION_MODAL_SUCCESS,
    campaign,
    id
  }
}

export function closeEditDocumentalConditionModalSuccess () {
  return {
    type: mastersActionTypes.CLOSE_EDIT_DOCUMENT_CONDITION_MODAL_SUCCESS
  }
}

export function closeEditDocumentalConditionModalEditSuccess () {
  return {
    type: mastersActionTypes.CLOSE_EDIT_DOCUMENT_CONDITION_EDITION_MODAL_SUCCESS
  }
}

export function openCampaignGroupModal (campaignGroup) {
  return {
    type: mastersActionTypes.OPEN_CAMPAIGN_GROUP_MODAL,
    campaignGroup
  }
}

export function openCampaignTypeModal (campaignType) {
  return {
    type: mastersActionTypes.OPEN_CAMPAIGN_TYPE_MODAL,
    campaignType
  }
}

export function openEditCampaignGroupModalSuccess (languageList, campaignId) {
  return {
    type: mastersActionTypes.OPEN_EDIT_CAMPAIGN_GROUP_MODAL_SUCCESS,
    languageList,
    campaignId
  }
}

export function openEditCampaignTypeModalSuccess (languageList, campaignId) {
  return {
    type: mastersActionTypes.OPEN_EDIT_CAMPAIGN_TYPE_MODAL_SUCCESS,
    languageList,
    campaignId
  }
}

export function openAddFinancingModalSuccess () {
  return {
    type: mastersActionTypes.OPEN_ADD_FINANCING_MODAL_SUCCESS
  }
}

export function closeEditProcessingAgencyModal () {
  return {
    type: mastersActionTypes.CLOSE_EDIT_PROCESSING_AGENCY_MODAL
  }
}

export function closeCampaignMasterModal () {
  return {
    type: mastersActionTypes.CLOSE_EDIT_CAMPAIGN_MODAL
  }
}

export function closeCampaignGroupMasterModal () {
  return {
    type: mastersActionTypes.CLOSE_EDIT_CAMPAIGN_GROUP_MODAL
  }
}

export function closeCampaignTypeMasterModal () {
  return {
    type: mastersActionTypes.CLOSE_EDIT_CAMPAIGN_TYPE_MODAL
  }
}

export function closeEditFinancingModal () {
  return {
    type: mastersActionTypes.CLOSE_EDIT_FINANCING_MODAL
  }
}

export function closeEditFinancingModalUo () {
  return {
    type: mastersActionTypes.CLOSE_EDIT_FINANCING_MODAL_UO
  }
}

export function deleteProcessingAgency (processingAgencyId) {
  return {
    type: mastersActionTypes.DELETE_PROCESSING_AGENCY,
    processingAgencyId
  }
}

export function recoverProcessingAgency (processingAgencyId) {
  return {
    type: mastersActionTypes.RECOVER_PROCESSING_AGENCY,
    processingAgencyId
  }
}

export function deletePaymentMethod (paymentMethodId) {
  return {
    type: mastersActionTypes.DELETE_PAYMENT_METHOD,
    paymentMethodId
  }
}

export function submitPaymentMethod (body, paymentMethodId) {
  return {
    type: mastersActionTypes.SUBMIT_PAYMENT_METHOD,
    paymentMethodId,
    body
  }
}
export function deleteFinancing (financingId) {
  return {
    type: mastersActionTypes.DELETE_FINANCING,
    financingId
  }
}

export function recoverFinancing (financingId) {
  return {
    type: mastersActionTypes.RECOVER_FINANCING,
    financingId
  }
}

export function submitProcessingAgency (body, agency) {
  return {
    type: mastersActionTypes.SUBMIT_PROCESSING_AGENCY,
    agency,
    body
  }
}

export function submitProcessingAgencyAssignation (quotaList) {
  return {
    type: mastersActionTypes.SUBMIT_PROCESSING_AGENCY_QUOTA,
    quotaList
  }
}

export function submitCampaignDetail (campaign) {
  return {
    type: mastersActionTypes.SUBMIT_CAMPAIGN_DETAIL,
    campaign
  }
}

export function submitCampaignGroup (campaign) {
  return {
    type: mastersActionTypes.SUBMIT_CAMPAIGN_GROUP,
    campaign
  }
}

export function submitCampaignType (campaign) {
  return {
    type: mastersActionTypes.SUBMIT_CAMPAIGN_TYPE,
    campaign
  }
}

export function deleteCampaignDetail (campaignId) {
  return {
    type: mastersActionTypes.DELETE_CAMPAIGN_DETAIL,
    campaignId
  }
}

export function deleteCampaignGroup (groupId) {
  return {
    type: mastersActionTypes.DELETE_CAMPAIGN_GROUP,
    groupId
  }
}

export function deleteCampaignType (typeId) {
  return {
    type: mastersActionTypes.DELETE_CAMPAIGN_TYPE,
    typeId
  }
}

export function recoverCampaignDetail (campaignId) {
  return {
    type: mastersActionTypes.RECOVER_CAMPAIGN_DETAIL,
    campaignId
  }
}

export function recoverCampaignGroup (groupId) {
  return {
    type: mastersActionTypes.RECOVER_CAMPAIGN_GROUP,
    groupId
  }
}

export function recoverCampaignType (typeId) {
  return {
    type: mastersActionTypes.RECOVER_CAMPAIGN_TYPE,
    typeId
  }
}

export function editProcessingAgencyAssignation (quota, resolve, reject) {
  return {
    type: mastersActionTypes.EDIT_PROCESSING_AGENCY_QUOTA,
    quota,
    resolve,
    reject
  }
}

export function deleteProcessingAgencyAssignation (quota) {
  return {
    type: mastersActionTypes.DELETE_PROCESSING_AGENCY_QUOTA,
    quota
  }
}

export function addProcessingAgencyAssignation (quota, resolve, reject) {
  return {
    type: mastersActionTypes.ADD_PROCESSING_AGENCY_QUOTA,
    quota,
    resolve,
    reject
  }
}

export function deleteProcessingAgencyQuota (quota) {
  return {
    type: mastersActionTypes.DELETE_PROCESSING_AGENCY_MASTER_QUOTA,
    quota
  }
}

export function submitFinancing (body, financing) {
  return {
    type: mastersActionTypes.SUBMIT_FINANCING,
    financing,
    body
  }
}

export function openAssociatedCostsModal (uoId, associatedCostsId = null) {
  return {
    type: mastersActionTypes.OPEN_ASSOCIATED_COSTS_MODAL,
    uoId,
    associatedCostsId
  }
}

export function openAssociatedCostsModalSuccess (languageList, associatedCostsFields, associatedCostsId) {
  return {
    type: mastersActionTypes.OPEN_ASSOCIATED_COSTS_MODAL_SUCCESS,
    languageList,
    associatedCostsFields,
    associatedCostsId
  }
}

export function deleteAssociatedCostsModal (uoId, associatedCostsId) {
  return {
    type: mastersActionTypes.DELETE_ASSOCIATED_COSTS,
    uoId,
    associatedCostsId
  }
}

export function recoverAssociatedCostsModal (uoId, associatedCostsId) {
  return {
    type: mastersActionTypes.RECOVER_ASSOCIATED_COSTS,
    uoId,
    associatedCostsId
  }
}

export function fetchAssociatedCostsMasters (lowestOrganizedUnitId) {
  return {
    type: mastersActionTypes.FETCH_ASSOCIATED_COSTS_MASTERS,
    lowestOrganizedUnitId
  }
}

export function fetchAssociatedCostsMastersSuccess (associatedCostsMastersList) {
  return {
    type: mastersActionTypes.FETCH_ASSOCIATED_COSTS_MASTERS_SUCCESS,
    associatedCostsMastersList
  }
}

export function closeAssociatedCostsModal () {
  return {
    type: mastersActionTypes.CLOSE_ASSOCIATED_COSTS_MODAL
  }
}

export function saveAssociatedCostsModal (uoId, associatedCostsValues, associatedCostsId) {
  return {
    type: mastersActionTypes.SAVE_ASSOCIATED_COSTS,
    uoId,
    associatedCostsValues,
    associatedCostsId
  }
}

export function fetchFuelList (filter) {
  return {
    type: mastersActionTypes.FETCH_FUEL,
    filter
  }
}

export function fetchFuelListSuccess (fuelList, filter) {
  return {
    type: mastersActionTypes.FETCH_FUEL_SUCCESS,
    fuelList,
    filter
  }
}

export function openEditFuelModal (fuelId) {
  return {
    type: mastersActionTypes.OPEN_EDIT_FUEL_MODAL,
    fuelId
  }
}

export function openEditFuelModalSuccess (languageList, fuelFields, fuelId) {
  return {
    type: mastersActionTypes.OPEN_EDIT_FUEL_MODAL_SUCCESS,
    languageList,
    fuelFields,
    fuelId
  }
}

export function openAddFuelModal () {
  return {
    type: mastersActionTypes.OPEN_ADD_FUEL_MODAL
  }
}

export function openAddFuelModalSuccess () {
  return {
    type: mastersActionTypes.OPEN_ADD_FUEL_MODAL_SUCCESS
  }
}

export function closeEditFuelModal () {
  return {
    type: mastersActionTypes.CLOSE_EDIT_FUEL_MODAL
  }
}

export function deleteFuel (fuelId) {
  return {
    type: mastersActionTypes.DELETE_FUEL,
    fuelId
  }
}

export function recoverFuel (fuelId) {
  return {
    type: mastersActionTypes.RECOVER_FUEL,
    fuelId
  }
}

export function submitFuel (body, fuel) {
  return {
    type: mastersActionTypes.SUBMIT_FUEL,
    fuel,
    body
  }
}

export function fetchDossierStageList (filter) {
  return {
    type: mastersActionTypes.FETCH_DOSSIER_STAGES,
    filter
  }
}

export function fetchDossierStageListSuccess (dossierStageList, filter) {
  return {
    type: mastersActionTypes.FETCH_DOSSIER_STAGES_SUCCESS,
    dossierStageList,
    filter
  }
}

export function openEditDossierStageModal (dossierStageId) {
  return {
    type: mastersActionTypes.OPEN_EDIT_DOSSIER_STAGES_MODAL,
    dossierStageId
  }
}

export function openEditDossierStageModalSuccess (languageList, dossierStageFields, dossierStageId) {
  return {
    type: mastersActionTypes.OPEN_EDIT_DOSSIER_STAGES_MODAL_SUCCESS,
    languageList,
    dossierStageFields,
    dossierStageId
  }
}

export function closeEditDossierStageModal () {
  return {
    type: mastersActionTypes.CLOSE_EDIT_DOSSIER_STAGES_MODAL
  }
}

export function submitDossierStage (body, dossierStageId) {
  return {
    type: mastersActionTypes.SUBMIT_DOSSIER_STAGE,
    dossierStageId,
    body
  }
}

export function openFinancingTypeModal (financingTypeId = null, financing, financingTypeProduct = null) {
  return {
    type: mastersActionTypes.OPEN_FINANCING_TYPE_MODAL,
    financingTypeId,
    financing,
    financingTypeProduct

  }
}

export function openFinancingTypeModalSuccess (languageList, financingTypeFields, financingTypeId, financing, financingTypeProduct) {
  return {
    type: mastersActionTypes.OPEN_FINANCING_TYPE_MODAL_SUCCESS,
    languageList,
    financingTypeFields,
    financingTypeId,
    financing,
    financingTypeProduct
  }
}

export function openPurchasePresentTypeModal (uoId, purchasePresentTypeId = null) {
  return {
    type: mastersActionTypes.OPEN_PURCHASE_PRESENT_TYPE_MODAL,
    uoId,
    purchasePresentTypeId
  }
}

export function openPurchasePresentTypeModalSuccess (languageList, purchasePresentTypeFields, purchasePresentTypeId) {
  return {
    type: mastersActionTypes.OPEN_PURCHASE_PRESENT_TYPE_MODAL_SUCCESS,
    languageList,
    purchasePresentTypeFields,
    purchasePresentTypeId
  }
}

export function closeFinancingTypeModal () {
  return {
    type: mastersActionTypes.CLOSE_FINANCING_TYPE_MODAL
  }
}

export function saveFinancingTypeModal (financingTypeValues, financingTypeId, companyId, financingTypeProductId) {
  return {
    type: mastersActionTypes.SAVE_FINANCING_TYPE,
    financingTypeValues,
    financingTypeId,
    companyId,
    financingTypeProductId
  }
}

export function deleteFinancingTypeModal (financingTypeId, companyId) {
  return {
    type: mastersActionTypes.DELETE_FINANCING_TYPE_MASTERS,
    financingTypeId,
    companyId
  }
}

export function fetchFinancingTypeMasters (lowestOrganizedUnitId) {
  return {
    type: mastersActionTypes.FETCH_FINANCING_TYPE_MASTERS,
    lowestOrganizedUnitId
  }
}

export function closePurchasePresentTypeModal () {
  return {
    type: mastersActionTypes.CLOSE_PURCHASE_PRESENT_TYPE_MODAL
  }
}

export function savePurchasePresentTypeModal (uoId, purchasePresentTypeValues, purchasePresentTypeId) {
  return {
    type: mastersActionTypes.SAVE_PURCHASE_PRESENT_TYPE,
    uoId,
    purchasePresentTypeValues,
    purchasePresentTypeId
  }
}

export function deletePurchasePresentTypeModal (uoId, purchasePresentTypeId) {
  return {
    type: mastersActionTypes.DELETE_PURCHASE_PRESENT_TYPE_MASTERS,
    uoId,
    purchasePresentTypeId
  }
}

export function fetchPurchasePresentTypeMasters (lowestOrganizedUnitId) {
  return {
    type: mastersActionTypes.FETCH_PURCHASE_PRESENT_TYPE_MASTERS,
    lowestOrganizedUnitId
  }
}

export function fetchFinancingTypeMastersSuccess (financingTypeMastersList) {
  return {
    type: mastersActionTypes.FETCH_FINANCING_TYPE_MASTERS_SUCCESS,
    financingTypeMastersList
  }
}

export function fetchPurchasePresentTypeMastersSuccess (purchasePresentTypeMastersList) {
  return {
    type: mastersActionTypes.FETCH_PURCHASE_PRESENT_TYPE_MASTERS_SUCCESS,
    purchasePresentTypeMastersList
  }
}

export function recoverFinancingTypeModal (financingTypeId, companyId) {
  return {
    type: mastersActionTypes.RECOVER_FINANCING_TYPE,
    financingTypeId,
    companyId
  }
}

export function recoverPurchasePresentTypeModal (uoId, purchasePresentTypeId) {
  return {
    type: mastersActionTypes.RECOVER_PURCHASE_PRESENT_TYPE,
    uoId,
    purchasePresentTypeId
  }
}

export function fetchFinancingTypeList (filter) {
  return {
    type: mastersActionTypes.FETCH_FINANCING_TYPE_LIST,
    filter
  }
}

export function fetchPurchasePresentTypeList (filter) {
  return {
    type: mastersActionTypes.FETCH_PURCHASE_PRESENT_TYPE_LIST,
    filter
  }
}

export function fetchFinancingTypeListSuccess (financingTypeMastersList, filter) {
  return {
    type: mastersActionTypes.FETCH_FINANCING_TYPE_SUCCESS_LIST,
    financingTypeMastersList,
    filter
  }
}

export function fetchPurchasePresentTypeListSuccess (purchasePresentTypeMastersList, filter) {
  return {
    type: mastersActionTypes.FETCH_PURCHASE_PRESENT_TYPE_SUCCESS_LIST,
    purchasePresentTypeMastersList,
    filter
  }
}

export function fetchCampaignList (filter) {
  return {
    type: mastersActionTypes.FETCH_CAMPAIGN_LIST,
    filter
  }
}

export function fetchCampaignListSuccess (campaignList, filter) {
  return {
    type: mastersActionTypes.FETCH_CAMPAIGN_LIST_SUCCESS,
    campaignList,
    filter
  }
}

export function fetchCampaignGroupList (filter) {
  return {
    type: mastersActionTypes.FETCH_CAMPAIGN_GROUP_LIST,
    filter
  }
}

export function fetchCampaignGroupListSuccess (campaignGroupList, filter) {
  return {
    type: mastersActionTypes.FETCH_CAMPAIGN_GROUP_LIST_SUCCESS,
    campaignGroupList,
    filter
  }
}

export function fetchCampaignTypeList (filter) {
  return {
    type: mastersActionTypes.FETCH_CAMPAIGN_TYPE_LIST,
    filter
  }
}

export function fetchCampaignTypeListSuccess (campaignTypeList, filter) {
  return {
    type: mastersActionTypes.FETCH_CAMPAIGN_TYPE_LIST_SUCCESS,
    campaignTypeList,
    filter
  }
}

export function openCampaingModalCountryDetailSuccess (campaign) {
  return {
    type: mastersActionTypes.OPEN_COUNTRY_CAMPAIGN_DETAIL_MODAL_SUCCESS,
    campaign
  }
}

export function openEditDocumentalConditionModalEditSuccess (conditionDocumentalCampaign) {
  return {
    type: mastersActionTypes.OPEN_EDIT_DOCUMENT_CONDITION_EDITION_MODAL_SUCCESS,
    conditionDocumentalCampaign
  }
}

export function closeCampaingModalCountryDetailSuccess () {
  return {
    type: mastersActionTypes.CLOSE_COUNTRY_CAMPAIGN_DETAIL_MODAL_SUCCESS
  }
}

export function fetchCountryCampaignDetailList (campaignDetailId) {
  return {
    type: mastersActionTypes.FETCH_COUNTRY_CAMPAIGN_DETAIL_LIST,
    campaignDetailId
  }
}

export function fetchCountryCampaignDetailListSuccess (campaignDetailsCountries, campaignDetailId) {
  return {
    type: mastersActionTypes.FETCH_COUNTRY_CAMPAIGN_DETAIL_LIST_SUCCESS,
    campaignDetailsCountries,
    campaignDetailId
  }
}

export function deleteCountryCampaignDetail (campaignDetailsCountryId, campaignDetailId) {
  return {
    type: mastersActionTypes.DELETE_COUNTRY_CAMPAIGN_DETAIL,
    campaignDetailsCountryId,
    campaignDetailId
  }
}

export function setCountryCampaignDetail (campaignDetailsCountry, campaignDetailId) {
  return {
    type: mastersActionTypes.SET_COUNTRY_CAMPAIGN_DETAIL,
    campaignDetailsCountry,
    campaignDetailId
  }
}

export function openStyleTaskModal (styleTask) {
  return {
    type: mastersActionTypes.OPEN_STYLE_TASK_MODAL,
    styleTask
  }
}

export function fetchStyleTaskList (filter) {
  return {
    type: mastersActionTypes.FETCH_STYLE_TASK_LIST,
    filter
  }
}

export function deleteStyleTask (taskId) {
  return {
    type: mastersActionTypes.DELETE_STYLE_TASK,
    taskId
  }
}

export function closeStyleTaskMasterModal () {
  return {
    type: mastersActionTypes.CLOSE_EDIT_STYLE_TASK_MODAL
  }
}

export function submitStyleTask (task, resolve) {
  return {
    type: mastersActionTypes.SUBMIT_STYLE_TASK,
    task,
    resolve
  }
}

export function fetchStyleTaskListSuccess (styleTaskList, filter) {
  return {
    type: mastersActionTypes.FETCH_STYLETASK_LIST_SUCCESS,
    styleTaskList,
    filter
  }
}

export function openEditStyleTaskModalSuccess (taskId, comboId) {
  return {
    type: mastersActionTypes.FETCH_STYLE_TASK_LIST_SUCCESS,
    taskId,
    comboId
  }
}

export function openAddStyleTaskModalSuccess (task) {
  return {
    type: mastersActionTypes.OPEN_ADD_STYLE_TASK_MODAL_SUCCESS,
    task
  }
}

export function getOrdersStyleTask (resolve, reject) {
  return {
    type: mastersActionTypes.STYLE_TASK_ORDERS,
    resolve,
    reject
  }
}

export function openActionDataModal (dossierIds, uoId, dossierSubtypeId) {
  return {
    type: mastersActionTypes.OPEN_ACTION_DATA_MODAL,
    dossierIds,
    uoId,
    dossierSubtypeId
  }
}

export function closeActionDataModal () {
  return {
    type: mastersActionTypes.CLOSE_ACTION_DATA_MODAL
  }
}

export function openEditFinancingModalProduct (financing) {
  return {
    type: mastersActionTypes.OPEN_EDIT_FINANCING_MODAL_PRODUCT,
    financing
  }
}

export function closeEditFinancingModalProduct () {
  return {
    type: mastersActionTypes.CLOSE_EDIT_FINANCING_MODAL_PRODUCT
  }
}

export function fetchFinancingProductCompany (companyId, filter) {
  return {
    type: mastersActionTypes.FETCH_FINANCING_PRODUCT_COMPANY,
    companyId,
    filter
  }
}

export function fetchFinancingProductCompanySuccess (productList, filter) {
  return {
    type: mastersActionTypes.FETCH_FINANCING_PRODUCT_COMPANY_SUCCES,
    productList,
    filter
  }
}

export function openFinancingTypeProductModal (financingTypeProductId = null) {
  return {
    type: mastersActionTypes.OPEN_FINANCING_TYPE_PRODUCT_MODAL,
    financingTypeProductId

  }
}

export function openFinancingTypeProductModalSuccess (financingTypeProductId, financingTypeProduct, languageList) {
  return {
    type: mastersActionTypes.OPEN_FINANCING_TYPE_PRODUCT_MODAL_SUCCESS,
    financingTypeProductId,
    financingTypeProduct,
    languageList
  }
}

export function deleteFinancingTypeProduct (financingTypeProductId) {
  return {
    type: mastersActionTypes.DELETE_FINANCING_TYPE_PRODUCT_MASTERS,
    financingTypeProductId
  }
}

export function recoverFinancingTypeProduct (financingTypeProductId) {
  return {
    type: mastersActionTypes.RECOVER_FINANCING_TYPE_PRODUCT,
    financingTypeProductId
  }
}

export function fetchFinancingTypeProductList (filter) {
  return {
    type: mastersActionTypes.FETCH_FINANCING_TYPE_PRODUCT_LIST,
    filter
  }
}

export function fetchFinancingTypeProductListSuccess (financingTypeProductMastersList, filter) {
  return {
    type: mastersActionTypes.FETCH_FINANCING_TYPE_PRODUCT_SUCCESS_LIST,
    financingTypeProductMastersList,
    filter
  }
}

export function fetchFinancingTypeProduct (financingTypeProductId) {
  return {
    type: mastersActionTypes.FETCH_FINANCING_TYPE_PRODUCT,
    financingTypeProductId
  }
}

export function fetchFinancingTypeProductSucces (financingTypeProduct) {
  return {
    type: mastersActionTypes.FETCH_FINANCING_TYPE_PRODUCT_SUCCES,
    financingTypeProduct
  }
}

export function closeEditFinancingTypeModalProduct () {
  return {
    type: mastersActionTypes.CLOSE_EDIT_FINANCING_TYPE_MODAL_PRODUCT
  }
}

export function fetchFinancingTypeProductFields () {
  return {
    type: mastersActionTypes.FETCH_FINANCING_TYPE_PRODUCT_FIELDS
  }
}

export function fetchFinancingTypeProductFieldsSuccess (fields) {
  return {
    type: mastersActionTypes.FETCH_FINANCING_TYPE_PRODUCT_FIELDS_SUCCESS,
    fields
  }
}

export function saveFinancingTypeProductModal (financingTypeProduct) {
  return {
    type: mastersActionTypes.SAVE_FINANCING_TYPE_PRODUCT,
    financingTypeProduct
  }
}

export function saveFinancingTypeProductModalSuccess () {
  return {
    type: mastersActionTypes.SAVE_FINANCING_TYPE_PRODUCT_SUCCESS
  }
}

export function getAllFinancingTypeProduct (isDeleted) {
  return {
    type: mastersActionTypes.GET_ALL_FINANCING_TYPE_PRODUCT,
    isDeleted
  }
}

export function getAllFinancingTypeProductSucces (typeProducts) {
  return {
    type: mastersActionTypes.GET_ALL_FINANCING_TYPE_PRODUCT_SUCCESS,
    typeProducts
  }
}

export function getFinancingTypeProductByFinancingType (id) {
  return {
    type: mastersActionTypes.GET_FINANCING_TYPE_PRODUCT_BY_FINANCING_TYPE,
    id
  }
}

export function getFinancingTypeProductByFinancingTypeSuccess (financingTypeProduct) {
  return {
    type: mastersActionTypes.GET_FINANCING_TYPE_PRODUCT_BY_FINANCING_TYPE_SUCCESS,
    financingTypeProduct
  }
}

export function openModalMassSuccess (tkey, massAction, callback) {
  return {
    type: mastersActionTypes.OPEN_MASS_MODAL_SUCCES,
    tkey,
    massAction,
    callback
  }
}

export function openModalMass (tkey, massAction, callback) {
  return {
    type: mastersActionTypes.OPEN_MASS_MODAL,
    tkey,
    massAction,
    callback
  }
}

export function closeModalMassSuccess (tkey) {
  return {
    type: mastersActionTypes.CLOSE_MASS_MODAL_SUCCES,
    tkey
  }
}

export function uploadUoMassDocument (file, code, callback, resolve, t) {
  return {
    type: mastersActionTypes.UPLOAD_UO_MASS_DOCUMENT,
    file,
    code,
    callback,
    resolve,
    t
  }
}

export function fetchUploadTest (items, errors, succes, code, showMessageError) {
  return {
    type: mastersActionTypes.FETCH_UPLOAD_TEST,
    items,
    errors,
    succes,
    code,
    showMessageError
  }
}

export function fetchUploadTestWithDocs (items, errors, succes, code, showMessageError, docErrors) {
  return {
    type: mastersActionTypes.FETCH_UPLOAD_TEST_WITH_DOCS,
    items,
    errors,
    succes,
    code,
    showMessageError,
    docErrors
  }
}

export function resetModalMassUpload () {
  return {
    type: mastersActionTypes.RESET_MODAL_MASS_UPLOAD
  }
}

export function fetchTemplateFile (filename) {
  return {
    type: mastersActionTypes.FETCH_TEMPLATE_LOAD_FILE,
    filename
  }
}

export function uploadProcAgMassDocument (file, code, callback, resolve, t) {
  return {
    type: mastersActionTypes.UPLOAD_PROC_AG_MASS_DOCUMENT,
    file,
    code,
    callback,
    resolve,
    t
  }
}

export function openApprovalActionModal (dossiers) {
  return {
    type: mastersActionTypes.OPEN_APPROVAL_ACTION_MODAL,
    dossiers
  }
}

export function closeApprovalActionModal () {
  return {
    type: mastersActionTypes.CLOSE_APPROVAL_ACTION_MODAL
  }
}

export function fetchPaperwokTypesMaster (filter) {
  return {
    type: mastersActionTypes.FETCH_PWK_TYPES_MASTER,
    filter
  }
}

export function fetchDirectionUOMaster (filter) {
  return {
    type: mastersActionTypes.FETCH_DIRECTION_UO_MASTER,
    filter
  }
}

export function fetchSaleTypeList (filter) {
  return {
    type: mastersActionTypes.FETCH_SALE_TYPE_LIST,
    filter
  }
}

export function fetchPaperwokTypesMasterSucces (pwkTypesList, filter) {
  return {
    type: mastersActionTypes.FETCH_PWK_TYPES_MASTER_SUCCES,
    pwkTypesList,
    filter
  }
}

export function fetchDirectionUOMasterSuccess (sourceDirectionsList, filter) {
  return {
    type: mastersActionTypes.FETCH_DIRECTION_UO_MASTER_SUCCESS,
    sourceDirectionsList,
    filter
  }
}

export function openDirectionUOModal (directionUOId) {
  return {
    type: mastersActionTypes.OPEN_DIRECTION_UO_MODAL,
    directionUOId
  }
}

export function openDirectionUOModalSuccess (directionUOFields, directionUOId) {
  return {
    type: mastersActionTypes.OPEN_DIRECTION_UO_MODAL_SUCCESS,
    directionUOFields,
    directionUOId
  }
}

export function closeDirectionUOModal () {
  return {
    type: mastersActionTypes.CLOSE_DIRECTION_UO_MODAL
  }
}

export function saveDirectionUOModal (directionUOValues, directionUOId) {
  return {
    type: mastersActionTypes.SAVE_DIRECTION_UO,
    directionUOValues,
    directionUOId
  }
}

export function deleteDirectionUOModal (directionUOId) {
  return {
    type: mastersActionTypes.DELETE_DIRECTION_UO,
    directionUOId
  }
}

export function fetchLegalCompanyList (filter) {
  return {
    type: mastersActionTypes.FETCH_LEGAL_COMPANY_LIST,
    filter
  }
}

export function fetchLegalCompanyListSuccess (legalCompanyList, filter) {
  return {
    type: mastersActionTypes.FETCH_LEGAL_COMPANY_LIST_SUCCESS,
    legalCompanyList,
    filter
  }
}
export function openLegalCompanyModal (legalCompanyId) {
  return {
    type: mastersActionTypes.OPEN_LEGAL_COMPANY_MODAL,
    legalCompanyId
  }
}

export function openLegalCompanyModalSuccess (legalCompanyFields, legalCompanyId) {
  return {
    type: mastersActionTypes.OPEN_LEGAL_COMPANY_MODAL_SUCCESS,
    legalCompanyFields,
    legalCompanyId
  }
}

export function closeLegalCompanyModal () {
  return {
    type: mastersActionTypes.CLOSE_LEGAL_COMPANY_MODAL
  }
}

export function saveLegalCompanyModal (legalCompanyValues, legalCompanyId) {
  return {
    type: mastersActionTypes.SAVE_LEGAL_COMPANY,
    legalCompanyValues,
    legalCompanyId
  }
}

export function deleteLegalCompanyModal (legalCompanyId) {
  return {
    type: mastersActionTypes.DELETE_LEGAL_COMPANY,
    legalCompanyId
  }
}

export function fetchOrganizedUnitList (filter) {
  return {
    type: mastersActionTypes.FETCH_ORGANIZED_UNIT_LIST,
    filter
  }
}

export function fetchOrganizedUnitListSuccess (organizedUnitList, filter) {
  return {
    type: mastersActionTypes.FETCH_ORGANIZED_UNIT_LIST_SUCCESS,
    organizedUnitList,
    filter
  }
}
export function openOrganizedUnitModal (organizedUnitId) {
  return {
    type: mastersActionTypes.OPEN_ORGANIZED_UNIT_MODAL,
    organizedUnitId
  }
}

export function openOrganizedUnitModalSuccess (organizedUnitFields, organizedUnitId) {
  return {
    type: mastersActionTypes.OPEN_ORGANIZED_UNIT_MODAL_SUCCESS,
    organizedUnitFields,
    organizedUnitId
  }
}

export function closeOrganizedUnitModal () {
  return {
    type: mastersActionTypes.CLOSE_ORGANIZED_UNIT_MODAL
  }
}

export function saveOrganizedUnitModal (organizedUnitValues, organizedUnitId) {
  return {
    type: mastersActionTypes.SAVE_ORGANIZED_UNIT,
    organizedUnitValues,
    organizedUnitId
  }
}

export function setChangeTabFromConfMaster (ouIdFromMaster, changeTabFromMaster) {
  return {
    type: mastersActionTypes.SET_CHANGE_TAB_FROM_CONF_MASTER,
    ouIdFromMaster,
    changeTabFromMaster
  }
}

export function setChangeTabFromConfMasterSuccess (ouIdFromMaster, changeTabFromMaster) {
  return {
    type: mastersActionTypes.SET_CHANGE_TAB_FROM_CONF_MASTER_SUCCESS,
    ouIdFromMaster,
    changeTabFromMaster
  }
}

export function fetchSaleTypeListSuccess (saleTypeMastersList, filter) {
  return {
    type: mastersActionTypes.FETCH_SALE_TYPE_SUCCESS_LIST,
    saleTypeMastersList,
    filter
  }
}

export function fetchPaperwokTypeMasterInfo (paperworkTypeId) {
  return {
    type: mastersActionTypes.FETCH_PWK_TYPE_MASTER_INFO,
    paperworkTypeId
  }
}

export function deletePaperwokTypeMaster (paperworkTypeId) {
  return {
    type: mastersActionTypes.DELETE_PWK_TYPE_MASTER,
    paperworkTypeId
  }
}

export function updatePaperwokTypeMaster (income, languageList, pwkTypeId) {
  return {
    type: mastersActionTypes.UPDATE_PWK_TYPE_MASTER,
    income,
    languageList,
    pwkTypeId
  }
}

export function closePaperwokTypeMasterModal () {
  return {
    type: mastersActionTypes.CLOSE_PWK_TYPE_MASTER_MODAL
  }
}

export function openPwkTypeModal (pwkType, languageList) {
  return {
    type: mastersActionTypes.OPEN_PWK_TYPE_MASTER_MODAL,
    pwkType,
    languageList
  }
}

export function postPwkTypeMasterModal (values, languageList) {
  return {
    type: mastersActionTypes.POST_PWK_TYPE_MASTER,
    values,
    languageList
  }
}
export function openSaleTypeModal (saleTypeId) {
  return {
    type: mastersActionTypes.OPEN_SALE_TYPE_MODAL,
    saleTypeId
  }
}

export function openSaleTypeModalSuccess (saleTypeId, saleType, languageList) {
  return {
    type: mastersActionTypes.OPEN_SALE_TYPE_MODAL_SUCCESS,
    saleTypeId,
    saleType,
    languageList
  }
}

export function deleteSaleType (saleTypeId) {
  return {
    type: mastersActionTypes.DELETE_SALE_TYPE_MASTERS,
    saleTypeId
  }
}

export function recoverSaleType (saleTypeId) {
  return {
    type: mastersActionTypes.RECOVER_SALE_TYPE,
    saleTypeId
  }
}

export function closeEditSaleTypeModal () {
  return {
    type: mastersActionTypes.CLOSE_EDIT_SALE_TYPE_MODAL
  }
}

export function saveSaleTypeModal (saleType, hasChanges, isCopy) {
  return {
    type: mastersActionTypes.SAVE_SALE_TYPE,
    saleType,
    hasChanges,
    isCopy
  }
}

export function saveSaleTypeModalSuccess () {
  return {
    type: mastersActionTypes.SAVE_SALE_TYPE_SUCCESS
  }
}

export function showEditProcessingAgencyIntegrationModal (show, processingagencyId) {
  return {
    type: mastersActionTypes.OPEN_EDIT_PROCESSING_AGENCY_INTEGRATION_MODAL,
    processingagencyId,
    show
  }
}

export function showEditProcessingAgencyIntegrationModalSuccess (show, agency) {
  return {
    type: mastersActionTypes.OPEN_EDIT_PROCESSING_AGENCY_INTEGRATION_MODAL_SUCCESS,
    agency,
    show
  }
}

export function updateAgencyCommunicationServiceId (processingAgencyId, processAgencyCommunicationServiceId) {
  return {
    type: mastersActionTypes.UPDATE_PROCESSING_AGENCY_INTEGRATION_COMMUNICATION_SERVICE_ID,
    processingAgencyId,
    processAgencyCommunicationServiceId
  }
}

export function adviceClosePaperwokTypeMasterModal () {
  return {
    type: mastersActionTypes.ADVICE_CLOSE_PWK_TYPE_MASTER_MODAL
  }
}

export function openUserProcessingAgencyModal (agency) {
  return {
    type: mastersActionTypes.OPEN_USER_PROCESSING_AGENCY_MODAL,
    agency
  }
}

export function closeUserProcessingAgencyModal () {
  return {
    type: mastersActionTypes.CLOSE_USER_PROCESSING_AGENCY_MODAL
  }
}

export function deleteUserProcessingAgency (userProcessingAgencyId, action) {
  return {
    type: mastersActionTypes.DELETE_USER_PROCESSING_AGENCY,
    userProcessingAgencyId,
    action
  }
}

export function deleteUserProcessingAgencySuccess () {
  return {
    type: mastersActionTypes.DELETE_USER_PROCESSING_AGENCY_SUCCESS
  }
}

export function chargeUsersProcessingAgencyModal (agencyFilteredUsers, filters) {
  return {
    type: mastersActionTypes.CHARGE_USER_PROCESSING_AGENCY_MODAL,
    agencyFilteredUsers,
    filters
  }
}

export function getUsersProcessingAgency (processingAgencyId, action) {
  return {
    type: mastersActionTypes.GET_USERS_PROCESSING_AGENCY,
    processingAgencyId,
    action
  }
}

export function getUsersProcessingAgencySuccess (data) {
  return {
    type: mastersActionTypes.GET_USERS_PROCESSING_AGENCY_SUCCES,
    data
  }
}

export function fetchUserListForProcessingAgency (filter) {
  return {
    type: mastersActionTypes.FETCH_USERS_LIST_FOR_PROCESSING_AGENCY,
    filter
  }
}

export function fetchUserListForProcessingAgencySuccess (userList, filter) {
  return {
    type: mastersActionTypes.FETCH_USERS_LIST_FOR_PROCESSING_AGENCY_SUCCESS,
    userList,
    filter
  }
}

export function openUserProcessingAgencyAddModal (agency) {
  return {
    type: mastersActionTypes.OPEN_USER_PROCESSING_AGENCY_ADD_MODAL,
    agency
  }
}

export function closeUserProcessingAgencyAddModal () {
  return {
    type: mastersActionTypes.CLOSE_USER_PROCESSING_AGENCY_ADD_MODAL
  }
}

export function assingUserProcessingAgency (userId, processingAgencyId, action) {
  return {
    type: mastersActionTypes.ASSING_USER_PROCESSING_AGENCY,
    userId,
    processingAgencyId,
    action
  }
}

export function initializeFilterState () {
  return {
    type: mastersActionTypes.INITIALIZE_DOSSIER_STAGES_FILTER
  }
}

export function openDuplicateSaleTypeModal (saleTypeId) {
  return {
    type: mastersActionTypes.OPEN_DUPLICATE_SALE_TYPE_MODAL,
    saleTypeId
  }
}

export function openDuplicateSaleTypeModalSuccess (saleTypeId, languageList) {
  return {
    type: mastersActionTypes.OPEN_DUPLICATE_SALE_TYPE_MODAL_SUCCESS,
    saleTypeId,
    languageList
  }
}

export function closeDuplicateSaleTypeModal () {
  return {
    type: mastersActionTypes.CLOSE_DUPLICATE_SALE_TYPE_MODAL
  }
}

export function fetchDestinationCraneListSuccess (destinationCrane, filter) {
  return {
    type: mastersActionTypes.DESTINATION_CRANE_MASTER_SUCCES,
    destinationCrane,
    filter
  }
}

export function fetchDestinationCraneMaster (filter) {
  return {
    type: mastersActionTypes.FETCH_DESTINATION_CRANE_MASTER,
    filter
  }
}

export function fetchDestinationCraneItemSucces (destinationCrane, showModal) {
  return {
    type: mastersActionTypes.DESTINATION_CRANE_ITEM_SUCCES,
    destinationCrane,
    showModal
  }
}

export function fetchDestinationCraneItem (destinationCrane, showModal) {
  return {
    type: mastersActionTypes.FETCH_DESTINATION_CRANE_ITEM,
    destinationCrane,
    showModal
  }
}

export function deleteDestinationCraneItem (destinationCraneId) {
  return {
    type: mastersActionTypes.DELETE_DESTINATION_CRANE_ITEM,
    destinationCraneId
  }
}

export function putDestinationCraneItem (destinationCrane) {
  return {
    type: mastersActionTypes.PUT_DESTINATION_CRANE_ITEM,
    destinationCrane
  }
}

export function fetchcodeCommissionMasters (organizedUnitId, page, pageSize, orderBy, orderDirection) {
  return {
    type: mastersActionTypes.FETCH_CODE_COMMISSION_MASTER,
    organizedUnitId,
    page,
    pageSize,
    orderBy,
    orderDirection
  }
}

export function fetchcodeCommissionMastersSuccess (codeCommissionMastersList, codeCommissionFields) {
  return {
    type: mastersActionTypes.FETCH_CODE_COMMISSION_MASTER_SUCCESS,
    codeCommissionMastersList,
    codeCommissionFields
  }
}

export function openCodeCommissionModal (codeCommission) {
  return {
    type: mastersActionTypes.OPEN_CODE_COMMISSION_MODAL,
    codeCommission
  }
}

export function openCodeCommissionModalSuccess (languageList, codeCommission) {
  return {
    type: mastersActionTypes.OPEN_CODE_COMMISSION_MODAL_SUCCESS,
    languageList,
    codeCommission
  }
}

export function closeCodeCommissionModal () {
  return {
    type: mastersActionTypes.CLOSE_CODE_COMMISSION_MODAL
  }
}

export function deleteCodeComission (commissionCancellationCodeId, uoId) {
  return {
    type: mastersActionTypes.DELETE_CODE_COMMISSION,
    commissionCancellationCodeId,
    uoId
  }
}

export function createOrUpdateCodeCommission (codeCommission, uoId) {
  return {
    type: mastersActionTypes.CREATE_OR_UPDATE_CODE_COMMISSION,
    codeCommission,
    uoId
  }
}
