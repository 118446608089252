import React, { PureComponent } from 'react'
import { Button, OverlayTrigger, Tooltip, Tabs, Tab, Glyphicon } from 'react-bootstrap'
import { permissions, approvalRoleCode, dossierSubTypes } from '../../../constants/backendIds'
import { buttonsPermissions } from '../../../constants/dossier/common/buttonsPermissions'
import { buttonsHeaderPermisions } from '../../../constants/dossier/common/buttonsHeader'
import { purchaseModalTypes } from '../../../constants/dossier/common/reassignPurchaseModalTypes'
import SelectVoCompany from './SelectVoCompany'
import { dossierTypes } from '../../../constants/dossier/common/dossierTypes'
import { paperworkTypes } from '../../../constants/dossier/paperwork/paperworkTypes'
import settings from '../../../setting'
import ChangeUoSelectModal from './ChangeUoSelectModal'
import { getFieldsPermisions } from '../../../util/permisionFunctions'
import TrackingContainer from '../../../containers/tracking/trackingContainer'
import { TrackingActions } from '../../../constants/tracking/TrackingActions'
import StoreCancelModalPage from '../../../containers/dossiers/common/StoreCancelModalPage'
import UpdateDossierPage from '../../../containers/dossiers/common/UpdateDossierPage'
import CancelReasonModal from '../common/CancelReasonModal'
import { icoCurrencyContent } from '../../../constants/dossier/common/currencies'

class ButtonsHeader extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      showSelectVoCompanyModal: false,
      showChangeUoSelectModa: false,
      showStoreCancelModal: false,
      showUpdateDossier: false,
      showCancelReasonModal: false,
      fieldsConfiguration: {
        button_delete_vehicle: null
      },
      fields: {
        cancelTab: {
          permission: null
        }
      },
      dossier: {
        reason: null,
        comment: null
      }
    }
  }

  initializePermisions (props) {
    const fieldKeys = Object.keys(this.state.fields)
    const newState = getFieldsPermisions(props.viewConfiguration.tabsConfiguration, fieldKeys, props.viewConfiguration.tabsConfiguration.permission)
    this.setState({ fields: newState.fieldsConfiguration })
  }

  isTabVisible (permissionKey) {
    return this.props.viewConfiguration.tabsConfiguration.filter(tabConf => {
      return tabConf.code === permissionKey && tabConf.permission > permissions.hidden
    }).length > 0
  }

  hasPermission (permissionKey) {
    return !!this.props.viewConfiguration.buttonsPermission && this.props.viewConfiguration.buttonsPermission.indexOf(permissionKey) !== -1
  }

  getMainCode () {
    let tab = this.props.viewConfiguration.tabsConfiguration.find(tab => tab.code === buttonsHeaderPermisions.main && tab.permission === permissions.editable)
    if (tab !== undefined) {
      return true
    } else {
      return false
    }
  }

  onSelectButtonHeader (eventKey) {
    this.props.actions.setActiveTab(eventKey)
    window.scrollTo(0, 0)
  }

  calcButtonsHeaderHeight () {
    const buttonsHeader = document.getElementsByClassName('button-header-wrapper')[0]
    return buttonsHeader.clientHeight
  }

  componentDidMount () {
    this.initializePermisions(this.props)
    const heigth = this.calcButtonsHeaderHeight()
    this.props.actions.setButtonsHeaderHeight(heigth)
    this.props.actions.setScrollerOptions({ offset: (heigth * 2 + 22 + this.props.marginTop) * -1 })
  }

  componentDidUpdate (prevProps) {
    if (prevProps.marginTop !== this.props.marginTop || prevProps.windowWidth !== this.props.windowWidth) {
      const heigth = this.calcButtonsHeaderHeight()
      this.props.actions.setButtonsHeaderHeight(heigth)
      this.props.actions.setScrollerOptions({ offset: (heigth + this.props.marginTop) * -1 })
    }
    if (prevProps.vehicleCompConfig !== this.props.vehicleCompConfig && this.props.vehicleCompConfig) {
      const fieldKeys = Object.keys(this.state.fieldsConfiguration)
      const newState = this.hasPermission(buttonsPermissions.sale_replace)
        ? getFieldsPermisions(this.props.vehicleCompConfig.fieldsConfiguration, fieldKeys, permissions.editable)
        : getFieldsPermisions(this.props.vehicleCompConfig.fieldsConfiguration, fieldKeys, permissions.hidden)
      this.setState({ ...this.state, ...newState })
    }
  }

  showApproveSalesmanButton (buttonsPermissions) {
    return !this.hasPermission(buttonsPermissions.sale_transfer_request_vo)
  }

  updateDossierToshiko () {
    return new Promise((resolve, reject) => {
      this.props.actions.createUpdateDossierToshiko(this.props.dossierId, null, this.props.organizedUnitId, 'updateConfirm', resolve, reject)
    }).then((result) => {
      if (!result) {
        this.setState({
          showUpdateDossier: true
        })
      } else {
        window.location.reload()
      }
    })
  }

  toggleRejectCommentModal (showCancelReasonModal) {
    this.setState({ showCancelReasonModal })
  }

  getSaleApproveTranslationKey () {
    if (this.props.dossierSubTypes === dossierSubTypes.sale_vn) {
      return 'SALE_APPROVE_VN'
    } else if (this.props.dossierSubTypes === dossierSubTypes.sale_vo) {
      return 'SALE_APPROVE_VO'
    } else {
      return 'SALE_APPROVE'
    }
  }

  render () {
    const {
      t,
      dossierId,
      pristine,
      reset,
      dossierType,
      showEconomicPlan = true,
      activeTab,
      marginTop,
      commentUnreadCount,
      organizedUnitId,
      lastAccessDate,
      paperworkTypeId,
      lowestOrganizedUnitsCombo,
      readOnlyFields,
      reason,
      comment,
      cancelCombo,
      type,
      actions: {
        saleApprove,
        saleApprovePaperwork,
        saleReject,
        saleSkipCharge,
        saleSkipDocument,
        saleSkipPaperwork,
        saleSkipDelivery,
        saleSendApprove,
        saleTransferRequest,
        saleTransferRequestApproved,
        saleCancel,
        undoCancelDossier,
        confirmCancelDossier,
        fetchAndOpenReplacePurchaseModal,
        paperworkReject,
        paperworkCancel,
        paperworkGenDelivery,
        paperworkTransferCompleted,
        purchaseCancel,
        purchaseSendApprove,
        purchaseApprove,
        purchaseReject,
        purchaseTransferRequest,
        purchaseTransferRequestApproved,
        campaignCancel,
        campaignApprove,
        campaignSend,
        campaignReject,
        storeDossier,
        submitForm,
        saleApproveByRole,
        changeDossierSubType,
        fetchVoCompaniesCombo,
        submitSelectVoCompany,
        saleApproveVoucher,
        configureCampaign,
        openDocumentsToSendSign,
        salePayRenting,
        resendToProcessingAgency,
        skipItv,
        approveMargin,
        fetchLowestOrganizedUnits,
        getChangeUo,
        getSalesmanChangeUo,
        postUpdateChangeUo,
        fecthOrganizedUnitsByDossierUo,
        anulateCampaign,
        campaignvalidate,
        campaignDenie,
        registrationDeliveryCancel,
        serviceDossierCancel,
        paperworkNotify,
        serviceDossierApprove,
        serviceApproveByRole,
        openOrCloseIssueModal,
        serviceResolveIssue,
        serviceRequestSpareParts,
        serviceCompleteSpareParts,
        serviceCompleteDeliveryPreparation,
        openChargeModalDealer,
        putCancelDossier,
        fetchCancelCombo,
        purchaseApproveManager,
        purchaseApproveSalesManagerVo,
        saleSendBproDms,
        requestInvoiceToDMS,
        requestDeliveryToDMS,
        requestOutboundToDMS,
        printCompleteSummarySales,
        transferReject
      },
      combos,
      changeUo,
      trackAction,
      dossierSubTypes
    } = this.props

    const tKey = 'DOSSIER_COMPONENTS.BUTTONS_HEADER.'
    const rejectPaperworkTooltip =
      (<Tooltip id="rejectPaperworkTooltip" bsStyle="link">
        {
          paperworkTypeId && paperworkTypeId === paperworkTypes.registration
            ? t(`${tKey}PAPERWORK_REJECT_REGISTRATION_TOOLTIP`)
            : t(`${tKey}PAPERWORK_REJECT_TOOLTIP`)
        }
      </Tooltip>)
    const tooltipDatosExpediente = (<Tooltip id="tooltipDatosExpediente" className="tooltip-buttons-header"><span >{t(`${tKey}DOSSIER_DATE`)}</span></Tooltip>)
    const tooltipGestionDocumental = (<Tooltip id="tooltipGestionDocumental" className="tooltip-buttons-header"><span >{t(`${tKey}DOCUMENTARY_MANAGEMENT`)}</span></Tooltip>)
    const tooltipPlanteoEconomico = (<Tooltip id="tooltipPlanteoEconomico" className="tooltip-buttons-header"><span >{t(`${tKey}ECONOMIC_PLAN`)}</span></Tooltip>)
    const tooltipExpedientesRelacionados = (<Tooltip id="tooltipExpedientesRelacionados" className="tooltip-buttons-header"><span >{t(`${tKey}RELATED_DOSSIER`)}</span></Tooltip>)
    const tooltipActividadExpediente = (<Tooltip id="tooltipActividadExpediente" className="tooltip-buttons-header"><span >{t(`${tKey}RECORD_ACTIVITY`)}</span></Tooltip>)
    const tooltipObservaciones = (<Tooltip id="tooltipObservaciones" className="tooltip-buttons-header"><span >{t(`${tKey}COMMENTS`)}</span></Tooltip>)

    let baseTrackingAction = {
      href: window.location.href,
      documentTitle: dossierType + '_page',
      organizedUnitId: organizedUnitId,
      category: dossierType
    }

    // El clic en "Guardar", "Archivar" y "Eliminar" de todos los tipos de expediente diferenciando cada uno.
    let saveAction = { ...baseTrackingAction, action: TrackingActions.SAVE_DOSSIER }
    let cancelAction = { ...baseTrackingAction, action: TrackingActions.CANCEL_DOSSIER }
    let storeAction = { ...baseTrackingAction, action: TrackingActions.STORE_DOSSIER }

    // La subida, validación, anulación, solicitud de firma, subida de hojas, borrado de hojas, apertura de la modal de documentos. Añadir información del documento si es posible.
    let openSignModalAction = { ...baseTrackingAction, action: TrackingActions.OPEN_SIGN_MODAL }

    return (
      <div id='button-header-wrapper-fixed' className={'button-header-wrapper fixed'} style={{ top: isNaN(marginTop) ? 0 : marginTop }}>
        <div className="col-left">
          <Tabs
            activeKey={activeTab}
            id="tab-botones-header-box"
            onSelect={this.onSelectButtonHeader.bind(this)}
          >
            {this.isTabVisible(buttonsHeaderPermisions.main) && (
              <Tab eventKey={1} title={<OverlayTrigger container={this} placement="bottom" overlay={tooltipDatosExpediente} id="main"><i className="ico-document-lines" /></OverlayTrigger>}></Tab>
            )}
            {this.isTabVisible(buttonsHeaderPermisions.doc_manager) && (
              <Tab eventKey={2} title={<OverlayTrigger container={this} placement="bottom" overlay={tooltipGestionDocumental} id="doc_manager"><i className="ico-docs" /></OverlayTrigger>} ></Tab>
            )}
            {showEconomicPlan && dossierType !== dossierTypes.paperwork && dossierType !== dossierTypes.campaign && this.isTabVisible(buttonsHeaderPermisions.economic_plan) && (
              <Tab eventKey={3} title={<OverlayTrigger container={this} placement="bottom" overlay={tooltipPlanteoEconomico} id="economic_plan"><i className={icoCurrencyContent[settings.currency]} /></OverlayTrigger>}></Tab>
            )}
            {this.isTabVisible(buttonsHeaderPermisions.related) && (
              <Tab eventKey={4} title={<OverlayTrigger container={this} placement="bottom" overlay={tooltipExpedientesRelacionados} id="related"><i className="ico-relacion" /></OverlayTrigger>}></Tab>
            )}
            {this.isTabVisible(buttonsHeaderPermisions.activity) && (
              <Tab eventKey={5} title={<OverlayTrigger container={this} placement="bottom" overlay={tooltipActividadExpediente} id="activity"><i className="ico-actividad-expediente" /></OverlayTrigger>}></Tab>
            )}
            {this.isTabVisible(buttonsHeaderPermisions.comments) && (
              <Tab eventKey={6} title={
                <OverlayTrigger container={this} placement="bottom" overlay={tooltipObservaciones} id="comments">
                  <div className='bubble-msg-wrapper'>
                    <i className="ico-bocadillo" />
                    {commentUnreadCount > 0 && (
                      <div className={`bubble-msg${commentUnreadCount > 0 ? '' : ' empty'}`}>
                        <span>{commentUnreadCount || ''}</span>
                      </div>
                    )}
                  </div>
                </OverlayTrigger>}>
              </Tab>
            )}
          </Tabs>
        </div>
        <div className="col-right">
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.dossier_undo_cancel) && this.getMainCode()
              ? <Button onClick={() => undoCancelDossier(dossierId, pristine, reset) } className="first" id="undoCancelDossier">
                <i className="ico-trash" />
                <span>{t(`${tKey}UNDO_CANCEL`)}</span>
              </Button> : null
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.sale_cancel) && this.getMainCode()
              ? <Button onClick={() => {
                if (this.state.fields.cancelTab?.permission === null || this.state.fields.cancelTab?.permission < permissions.editable) {
                  saleCancel(dossierId, pristine, reset, null, reason, comment)
                  trackAction(cancelAction)
                } else {
                  this.setState({ showCancelReasonModal: true })
                }
              }}
              id="cancel"
              className="first btn-white btn-icon btn-cancel">
                <i className="ico-trash" />
                <span>{t(`${tKey}SALE_CANCEL`)}</span>
              </Button> : null
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.dossier_confirm_cancel) && this.getMainCode()
              ? <Button onClick={() => confirmCancelDossier(dossierId, pristine, reset)} className="first btn-white btn-icon btn-cancel" id="confirmCancel">
                <i className="ico-trash" />
                <span>{t(`${tKey}CONFIRM_CANCEL`)}</span>
              </Button> : null
          }
          {
            !readOnlyFields && ((this.hasPermission(buttonsPermissions.sale_save) && this.getMainCode()) || this.hasPermission(buttonsPermissions.exclusive_sale_save))
              ? <Button onClick={() => {
                submitForm()
                trackAction(saveAction)
              }}
              id="save"
              disabled = { !pristine ? false : !this.hasPermission(buttonsPermissions.force_save) }
              className={'first btn-white btn-icon btn-save ' + (pristine ? '' : 'dirty')}>
                <i className={!pristine ? '' : 'ico-folder-open'} /><Glyphicon glyph={!pristine ? 'floppy-disk' : ''} />
                <span>{pristine ? (t(`${tKey}SALE_SAVE`)) : (t(`${tKey}SAVE_CHANGES`))}</span>
              </Button> : null
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.vehicle_licenseplate_validate_format) && this.getMainCode()
              ? <Button onClick={() => saleApproveByRole(pristine, approvalRoleCode.approveLicensePlateFormat)} className="first btn-white btn-icon" id="approveLicensePlateFormat">
                <i className={'ico-check'} />
                <span>{(t(`${tKey}VEHICLE_LICENSEPLATE_VALIDATE_FORMAT`))}</span>
              </Button> : null
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.paperwork_cancel) && this.getMainCode()
              ? <Button onClick={() => {
                if (this.state.fields.cancelTab?.permission === null || this.state.fields.cancelTab?.permission < permissions.editable) {
                  paperworkCancel(dossierId, pristine, reset)
                  trackAction(cancelAction)
                } else {
                  this.setState({ showCancelReasonModal: true })
                }
              }}
              id="cancel"
              className="first btn-white btn-icon btn-cancel">
                <i className="ico-trash" />
                <span>{t(`${tKey}PAPERWORK_CANCEL`)}</span>
              </Button> : null
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.stock_dossier_cancel) && this.getMainCode()
              ? <Button onClick={() => {
                if (this.state.fields.cancelTab?.permission === null || this.state.fields.cancelTab?.permission < permissions.editable) {
                  putCancelDossier(dossierId, pristine, reset)
                  trackAction(cancelAction)
                } else {
                  this.setState({ showCancelReasonModal: true })
                }
              }}
              id="cancel"
              className="first btn-white btn-icon btn-cancel">
                <i className="ico-trash" />
                <span>{t(`${tKey}STOCK_CANCEL`)}</span>
              </Button> : null
          }
          {
            !readOnlyFields && (this.hasPermission(buttonsPermissions.paperwork_save) && this.getMainCode()) ||
            this.hasPermission(buttonsPermissions.paperwork_partial_save)
              ? <Button onClick={() => {
                submitForm()
                trackAction(saveAction)
              }}
              id="save"
              disabled = { !pristine ? false : !this.hasPermission(buttonsPermissions.force_save) }
              className={'first btn-white btn-icon btn-save ' + (pristine ? '' : 'dirty')}>
                <i className={!pristine ? '' : 'ico-folder-open'} /><Glyphicon glyph={!pristine ? 'floppy-disk' : ''} />
                <span>{pristine ? (t(`${tKey}PAPERWORK_SAVE`)) : (t(`${tKey}SAVE_CHANGES`))}</span>
              </Button> : null
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.stock_dossier_save) && this.getMainCode()
              ? <Button onClick={() => {
                submitForm()
                trackAction(saveAction)
              }}
              id="save"
              disabled = { !pristine ? false : !this.hasPermission(buttonsPermissions.force_save) }
              className={'first btn-white btn-icon btn-save ' + (pristine ? '' : 'dirty')}>
                <i className={!pristine ? '' : 'ico-folder-open'} /><Glyphicon glyph={!pristine ? 'floppy-disk' : ''} />
                <span>{pristine ? (t(`${tKey}STOCK_SAVE`)) : (t(`${tKey}SAVE_CHANGES`))}</span>
              </Button> : null
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.purchase_cancel) && this.getMainCode()
              ? <Button onClick={() => {
                if (this.state.fields.cancelTab?.permission === null || this.state.fields.cancelTab?.permission < permissions.editable) {
                  purchaseCancel(dossierId, pristine, reset)
                  trackAction(cancelAction)
                } else {
                  this.setState({ showCancelReasonModal: true })
                }
              }}
              id="cancel"
              className="first btn-white btn-icon btn-cancel" >
                <i className="ico-trash" />
                <span>{t(`${tKey}PURCHASE_CANCEL`)}</span>
              </Button> : null
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.purchase_save) && this.getMainCode()
              ? <Button onClick={() => {
                submitForm()
                trackAction(saveAction)
              }}
              id="save"
              disabled = { !pristine ? false : !this.hasPermission(buttonsPermissions.force_save) }
              className={'first btn-white btn-icon btn-save ' + (pristine ? '' : 'dirty')}>
                <i className={!pristine ? '' : 'ico-folder-open'} /><Glyphicon glyph={!pristine ? 'floppy-disk' : ''} />
                <span>{pristine ? (t(`${tKey}PURCHASE_SAVE`)) : (t(`${tKey}SAVE_CHANGES`))}</span>
              </Button> : null
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.reg_delivery_cancel) && this.getMainCode()
              ? <Button onClick={() => {
                if (this.state.fields.cancelTab?.permission === null || this.state.fields.cancelTab?.permission < permissions.editable) {
                  registrationDeliveryCancel(dossierId, pristine, reset)
                  trackAction(cancelAction)
                } else {
                  this.setState({ showCancelReasonModal: true })
                }
              }}
              id="cancel"
              className="first btn-white btn-icon btn-cancel" >
                <i className="ico-trash" />
                <span>{t(`${tKey}REGISTRATION_DELIVERY_CANCEL`)}</span>
              </Button> : null
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.reg_delivery_save) && this.getMainCode()
              ? <Button onClick={() => {
                submitForm()
                trackAction(saveAction)
              }}
              id="save"
              disabled = { !pristine ? false : !this.hasPermission(buttonsPermissions.force_save) }
              className={'first btn-white btn-icon btn-save ' + (pristine ? '' : 'dirty')}>
                <i className={!pristine ? '' : 'ico-folder-open'} /><Glyphicon glyph={!pristine ? 'floppy-disk' : ''} />
                <span>{pristine ? (t(`${tKey}REGISTRATION_DELIVERY_SAVE`)) : (t(`${tKey}SAVE_CHANGES`))}</span>
              </Button> : null
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.purchase_change_subtype) && this.getMainCode()
              ? <Button onClick={() => changeDossierSubType()}
                id="change_subtype"
                className={'first btn-white btn-icon'}>
                <i className='ico-email' />
                <span>{t(`${tKey}PURCHASE_CHANGE_EXP`)}</span>
              </Button> : null
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.campaign_cancel) && this.getMainCode()
              ? <Button onClick={() => {
                if (this.state.fields.cancelTab?.permission === null || this.state.fields.cancelTab?.permission < permissions.editable) {
                  campaignCancel(dossierId, pristine, reset)
                  trackAction(cancelAction)
                } else {
                  this.setState({ showCancelReasonModal: true })
                }
              }}
              id="cancel"
              className="first btn-white btn-icon btn-cancel">
                <i className="ico-trash" />
                <span>{t(`${tKey}CAMPAIGN_CANCEL`)}</span>
              </Button> : null
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.campaign_save) && this.getMainCode()
              ? <Button onClick={() => {
                submitForm()
                trackAction(saveAction)
              }}
              id="save"
              disabled = { !pristine ? false : !this.hasPermission(buttonsPermissions.force_save) }
              className={'first btn-white btn-icon btn-save ' + (pristine ? '' : 'dirty')}>
                <i className={!pristine ? '' : 'ico-folder-open'} /><Glyphicon glyph={!pristine ? 'floppy-disk' : ''} />
                <span>{pristine ? (t(`${tKey}CAMPAIGN_SAVE`)) : (t(`${tKey}SAVE_CHANGES`))}</span>
              </Button> : null
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.purchase_store) && this.getMainCode()
              ? <Button onClick={() => {
                storeDossier(dossierId, pristine, reset)
                trackAction(cancelAction)
              }}
              id="store"
              className="first btn-white btn-icon">
                <i className="ico-document-plus" />
                <span>{t(`${tKey}STORE_DOSSIER`)}</span>
              </Button> : null
          }
          {
            this.hasPermission(buttonsPermissions.reg_delivery_store) && this.getMainCode()
              ? <Button onClick={() => {
                storeDossier(dossierId, pristine, reset)
                trackAction(storeAction)
              }}
              id="store"
              className="first btn-white btn-icon">
                <i className="ico-document-plus" />
                <span>{t(`${tKey}STORE_DOSSIER`)}</span>
              </Button> : null
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.sale_approve) && settings.clientFieldValidation !== 'da'
              ? <Button onClick={() => saleApprove(pristine)} className="first btn-white btn-icon" id="approve">
                <i className="ico-check" />
                <span>{t(`${tKey}` + this.getSaleApproveTranslationKey())}</span>
              </Button> : null
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.sale_approve) && settings.clientFieldValidation === 'da'
              ? <Button onClick={() => saleApprove(pristine)} className="first btn-white btn-icon" id="approve">
                <i className="ico-check"/>
                <span>{t(`${tKey}SALE_APPROVE_DAG`)}</span>
              </Button> : null
          }
          {
            !readOnlyFields && (this.hasPermission(buttonsPermissions.sale_aprove_pwk) ||
            (this.hasPermission(buttonsPermissions.sale_aprove_pwk_vn)))
              ? <Button onClick={() => saleApprovePaperwork(pristine, approvalRoleCode.approveSalesManager)} className="first btn-white btn-icon" id="approve_paperwork">
                <i className="ico-check" />
                <span>{t(`${tKey}SALE_APPROVE_PAPERWORK`)}</span>
              </Button> : null
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.sale_approve_pwk_finance)
              ? <Button onClick={() => saleApprovePaperwork(pristine, approvalRoleCode.approveAdministrationManager)} className="first btn-white btn-icon" id="approve_paperwork_finance">
                <i className="ico-check" />
                <span>{t(`${tKey}SALE_APPROVE_PAPERWORK_FINANCE`)}</span>
              </Button> : null
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.sale_approve_pwk_manager)
              ? <Button onClick={() => saleApprovePaperwork(pristine, approvalRoleCode.approveManager)} className="first btn-white btn-icon" id="sale_approve_pwk_manager">
                <i className="ico-check" />
                <span>{t(`${tKey}SALE_APPROVE_PAPERWORK_MANAGER`)}</span>
              </Button> : null
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.sale_reject)
              ? <Button onClick={() => saleReject()} className="first btn-white btn-icon" id="reject">
                <i className="ico-cancel" />
                <span>{t(`${tKey}SALE_REJECT`)}</span>
              </Button> : null
          }
          {
            !readOnlyFields && (this.hasPermission(buttonsPermissions.sale_skip_charge) ||
            this.hasPermission(buttonsPermissions.sale_skip_charge_vn))
              ? <Button onClick={() => saleSkipCharge(pristine)} className="first btn-white btn-icon" id="skip_charge">
                <i className="ico-arrow-left" />
                <span>{t(`${tKey}SALE_SKIP_CHARGE`)}</span>
              </Button> : null
          }
          {
            !readOnlyFields && (this.hasPermission(buttonsPermissions.sale_skip_document) ||
            this.hasPermission(buttonsPermissions.sale_skip_document_vn))
              ? <Button onClick={() => saleSkipDocument(pristine)} className="first btn-white btn-icon" id="skip_document">
                <i className="ico-arrow-left" />
                <span>{t(`${tKey}SALE_SKIP_DOCUMENT`)}</span>
              </Button> : null
          }
          {
            !readOnlyFields && (this.hasPermission(buttonsPermissions.sale_skip_paperwork) ||
            this.hasPermission(buttonsPermissions.sale_skip_paperwork_vn))
              ? <Button onClick={() => saleSkipPaperwork(pristine)} className="first btn-white btn-icon" id="skip_paperwork">
                <i className="ico-arrow-left" />
                <span>{t(`${tKey}SALE_SKIP_PAPERWORK`)}</span>
              </Button> : null
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.sale_skip_delivery)
              ? <Button onClick={() => saleSkipDelivery(pristine)} className="first btn-white btn-icon" id="skip_delivery">
                <i className="ico-arrow-left" />
                <span>{t(`${tKey}SALE_SKIP_DELIVERY`)}</span>
              </Button> : null
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.sale_send_approve)
              ? <Button onClick={() => saleSendApprove(pristine)} className="first btn-white btn-icon" id="send_approve">
                <i className="ico-email" />
                <span>{t(`${tKey}SALE_SEND_APPROVE`)}</span>
              </Button> : null
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.sale_transfer_request_vo)
              ? <Button onClick={() => saleTransferRequest(pristine)} className="first btn-white btn-icon" id="sale_transfer_request">
                <i className="ico-email" />
                <span>{t(`${tKey}SALE_TRANSFER_REQUEST`)}</span>
              </Button> : null
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.sale_transfer_request_approved_vo)
              ? <Button onClick={() => saleTransferRequestApproved(pristine)} className="first btn-white btn-icon" id="sale_transfer_request">
                <i className="ico-email" />
                <span>{t(`${tKey}SALE_TRANSFER_REQUEST_APPROVE`)}</span>
              </Button> : null
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.sale_approve_salesman) &&
              this.showApproveSalesmanButton(buttonsPermissions)
              ? <Button onClick={() => saleApproveByRole(pristine, approvalRoleCode.approveSalesman)} className="first btn-white btn-icon" id="salesManagerApprove">
                <i className="ico-document-plus" />
                <span>{t(`${tKey}SALE_APPROVE_SALESMAN`)}</span>
              </Button> : null
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.received_agent_fee) &&
              this.showApproveSalesmanButton(buttonsPermissions)
              ? <Button onClick={() => saleApproveByRole(pristine, approvalRoleCode.agentOperation, true)} className="first btn-white btn-icon" id="receivedAgentFee">
                <i className="ico-document-plus" />
                <span>{t(`${tKey}RECEIVED_AGENT_FEE`)}</span>
              </Button> : null
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.button_approve_controller)
              ? <Button onClick={() => saleApproveByRole(pristine, approvalRoleCode.approveSalesController)} className="first btn-white btn-icon" id="approveSalesController">
                <i className="ico-document-plus" />
                <span>{t(`${tKey}SALE_APPROVE_CONTROLLER`)}</span>
              </Button> : null
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.sale_approve_sales_manager)
              ? <Button onClick={() => saleApproveByRole(pristine, approvalRoleCode.approveSalesManager)} className="first btn-white btn-icon" id="approveSalesManager">
                <i className="ico-document-plus" />
                <span>{t(`${tKey}SALE_APPROVE_SALES_MANAGER`)}</span>
              </Button> : null
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.sale_vo_approve_sales_manager)
              ? <Button onClick={() => saleApproveByRole(pristine, approvalRoleCode.approveSalesManagerVO)} className="first btn-white btn-icon" id="approveSalesManagerVO">
                <i className="ico-document-plus" />
                <span>{t(`${tKey}SALE_VO_APPROVE_SALES_MANAGER`)}</span>
              </Button> : null
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.sale_approve_bpa)
              ? <Button onClick={() => saleApproveByRole(pristine, approvalRoleCode.approveBPA)} className="first btn-white btn-icon" id="approveBPA">
                <i className="ico-document-plus" />
                <span>{t(`${tKey}SALE_APPROVE_BPA`)}</span>
              </Button> : null
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.sale_approve_cfo)
              ? <Button onClick={() => saleApproveByRole(pristine, approvalRoleCode.approveCFO)} className="first btn-white btn-icon" id="approveCFO">
                <i className="ico-document-plus" />
                <span>{t(`${tKey}SALE_APPROVE_CFO`)}</span>
              </Button> : null
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.sale_approve_logistics)
              ? <Button onClick={() => saleApproveByRole(pristine, approvalRoleCode.approveSalesLogistics)} className="first btn-white btn-icon" id="approveSalesLogistics">
                <i className="ico-document-plus" />
                <span>{t(`${tKey}SALE_APPROVE_LOGISTICS`)}</span>
              </Button> : null
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.sale_approve_administration)
              ? <Button onClick={() => saleApproveByRole(pristine, approvalRoleCode.approveSalesAdmin)} className="first btn-white btn-icon" id="approveSalesAdmin">
                <i className="ico-document-plus" />
                <span>{t(`${tKey}SALE_APPROVE_ADMINISTRATION`)}</span>
              </Button> : null
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.sale_store) && this.getMainCode()
              ? <Button onClick={() => {
                storeDossier(dossierId, pristine, reset)
                trackAction(storeAction)
              }}
              id="store"
              className="first btn-white btn-icon" >
                <i className="ico-document-plus" />
                <span>{t(`${tKey}STORE_DOSSIER`)}</span>
              </Button>
              : null
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.paperwork_gen_delivery_note)
              ? <Button onClick={() => paperworkGenDelivery(pristine)} className="first btn-white btn-icon" id="sendToAgency">
                <i className="ico-document-plus" />
                <span>{t(`${tKey}PAPERWORK_SEND_TO_AGENCY`)}</span>
                {
                  /* <span>{t(`${tKey}PAPERWORK_GEN_DELIVERY`)}</span> */
                }
              </Button> : null
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.paperwork_transfer_completed)
              ? <Button onClick={() => paperworkTransferCompleted(pristine)} className="first btn-white btn-icon" id="sendToAgency">
                <i className="ico-document-plus" />
                <span>{t(`${tKey}PAPERWORK_TRANSFER_COMPLETED`)}</span>
              </Button> : null
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.paperwork_reject)
              ? (
                <OverlayTrigger placement="right" overlay={rejectPaperworkTooltip}>
                  <Button onClick={() => paperworkReject()} className="first btn-white btn-icon" id="reject">
                    <i className="ico-document-plus" />
                    <span>
                      {
                        paperworkTypeId && paperworkTypeId === paperworkTypes.registration ? (
                          t(`${tKey}PAPERWORK_REJECT_REGISTRATION`)
                        ) : (
                          t(`${tKey}PAPERWORK_REJECT`)
                        )
                      }
                    </span>
                  </Button>
                </OverlayTrigger>
              ) : null
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.button_request_invoice_to_dms)
              ? <Button onClick={() => requestInvoiceToDMS(pristine)} className="first btn-white btn-icon" id="button_request_invoice_to_dms">
                <span>{t(`${tKey}REQUEST_INVOICE_TO_DMS`)}</span>
              </Button> : null
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.button_request_vehicle_delivery_to_dms)
              ? <Button onClick={() => requestDeliveryToDMS(pristine)} className="first btn-white btn-icon" id="button_request_vehicle_delivery_to_dms">
                <span>{t(`${tKey}REQUEST_DELIVERY_TO_DMS`)}</span>
              </Button> : null
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.button_request_vehicle_outbound_to_dms)
              ? <Button onClick={() => requestOutboundToDMS(pristine)} className="first btn-white btn-icon" id="button_request_vehicle_outbound_to_dms">
                <span>{t(`${tKey}REQUEST_OUTBOUND_TO_DMS`)}</span>
              </Button> : null
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.sale_replace) && this.getMainCode() && this.state.fieldsConfiguration.button_delete_vehicle && this.state.fieldsConfiguration.button_delete_vehicle.permission === permissions.editable
              ? <Button onClick={() => fetchAndOpenReplacePurchaseModal(purchaseModalTypes.saleDossier, pristine)} className="first btn-white btn-icon" id="saleReplace">
                <i className="ico-document-plus" />
                <span>{t(`${tKey}SALE_REPLACE`)}</span>
              </Button> : null
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.paperwork_store) && this.getMainCode()
              ? <Button onClick={() => {
                storeDossier(dossierId, pristine, reset)
                trackAction(storeAction)
              }}
              id="store"
              className="first btn-white btn-icon" >
                <i className="ico-document-plus" />
                <span>{t(`${tKey}STORE_DOSSIER`)}</span>
              </Button> : null
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.purchase_send_approve)
              ? <Button onClick={() => purchaseSendApprove(pristine)} className="first btn-white btn-icon" id="purchase_send_approve">
                <i className="ico-document-plus" />
                <span>{t(`${tKey}PURCHASE_SEND_APPROVE`)}</span>
              </Button> : null
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.purchase_transfer_request_vo)
              ? <Button onClick={() => purchaseTransferRequest(pristine)} className="first btn-white btn-icon" id="purchase_transfer_request_vo">
                <i className="ico-email" />
                <span>{t(`${tKey}PURCHASE_TRANSFER_REQUEST`)}</span>
              </Button> : null
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.purchase_transfer_request_approved_vo)
              ? <Button onClick={() => purchaseTransferRequestApproved(pristine)} className="first btn-white btn-icon" id="purchase_transfer_request_approved_vo">
                <i className="ico-email" />
                <span>{t(`${tKey}PURCHASE_TRANSFER_REQUEST_APPROVED`)}</span>
              </Button> : null
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.purchase_approve)
              ? <Button onClick={() => purchaseApprove(pristine)} className="first btn-white btn-icon" id="purchase_approve">
                <i className="ico-document-plus" />
                <span>{t(`${tKey}PURCHASE_APPROVE`)}</span>
              </Button> : null
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.purchase_approved_manager)
              ? <Button onClick={() => purchaseApproveManager(pristine)} className="first btn-white btn-icon" id="purchase_approved_manager">
                <i className="ico-document-plus" />
                <span>{t(`${tKey}PURCHASE_APPROVE_MANAGER`)}</span>
              </Button> : null
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.purchase_approve_sales_manger_vo)
              ? <Button onClick={() => purchaseApproveSalesManagerVo(pristine)} className="first btn-white btn-icon" id="purchase_approve_sales_manger_vo">
                <i className="ico-document-plus" />
                <span>{t(`${tKey}PURCHASE_APPROVE_SALES_MANAGER_VO`)}</span>
              </Button> : null
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.campaign_approve) && this.getMainCode()
              ? <Button onClick={() => campaignApprove(pristine)} className="first btn-white btn-icon" id="campaign_approve">
                <i className="ico-document-plus" />
                <span>{t(`${tKey}CAMPAIGN_APPROVE`)}</span>
              </Button> : null
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.campaign_send)
              ? <Button onClick={() => campaignSend(pristine)} className="first btn-white btn-icon" id="campaign_send">
                <i className="ico-document-plus" />
                <span>{t(`${tKey}CAMPAIGN_SEND`)}</span>
              </Button> : null
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.campaign_reject)
              ? <Button onClick={() => campaignReject()} className="first btn-white btn-icon" id="reject">
                <i className="ico-document-plus" />
                <span>{t(`${tKey}CAMPAIGN_REJECT`)}</span>
              </Button> : null
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.campaign_store) && this.getMainCode()
              ? <Button onClick={() => {
                storeDossier(dossierId, pristine, reset)
                trackAction(storeAction)
              }}
              id="store"
              className="first btn-white btn-icon" >
                <i className="ico-document-plus" />
                <span>{t(`${tKey}STORE_DOSSIER`)}</span>
              </Button> : null
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.purchase_reject)
              ? <Button onClick={() => purchaseReject()} className="first btn-white btn-icon" id="purchase_reject">
                <i className="ico-document-plus" />
                <span>{t(`${tKey}PURCHASE_REJECT`)}</span>
              </Button> : null
          }
          {
            dossierType === dossierTypes.purchase && !readOnlyFields && this.hasPermission(buttonsPermissions.purchase_internal_vo)
              ? <Button onClick={() => this.setState({ showSelectVoCompanyModal: true })} className="first btn-white btn-icon" id="purchase_internal_vo">
                <i className="ico-document-plus" />
                <span>{t(`${tKey}SELECT_VO_COMPANY`)}</span>
              </Button> : null
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.sale_approve_fleet)
              ? <Button onClick={() => saleApproveByRole(pristine, approvalRoleCode.approveSalesFleets)} className="first btn-white btn-icon" id="sale_approve_fleet">
                <i className="ico-check" />
                <span>{t(`${tKey}SALE_APPROVE_FLEET`)}</span>
              </Button> : null
          }
          {
            dossierType === dossierTypes.sales && !readOnlyFields && this.hasPermission(buttonsPermissions.sale_approve_voucher)
              ? <Button onClick={() => saleApproveVoucher(pristine)} className="first btn-white btn-icon" id="sale_approve_voucher">
                <i className="ico-check" />
                <span>{t(`${tKey}SALE_APPROVE_VOUCHER`)}</span>
              </Button> : null
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.sale_approve_manager)
              ? <Button onClick={() => saleApproveByRole(pristine, approvalRoleCode.approveManager)} className="first btn-white btn-icon" id="sale_approve_manager">
                <i className="ico-check" />
                <span>{t(`${tKey}SALE_APPROVE_MANAGER`)}</span>
              </Button> : null
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.sale_approve_commercial_director)
              ? <Button onClick={() => saleApproveByRole(pristine, approvalRoleCode.approveSalesCommercialDirector)} className="first btn-white btn-icon" id="sale_approve_commercial_director">
                <i className="ico-check" />
                <span>{t(`${tKey}SALE_APPROVE_COMMERCIAL_DIRECTOR`)}</span>
              </Button> : null
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.close_store) && this.getMainCode()
              ? <Button onClick={() => {
                storeDossier(dossierId, pristine, reset)
                trackAction(storeAction)
              }}
              id="store"
              className="first btn-white btn-icon" >
                <i className="ico-document-plus" />
                <span>{t(`${tKey}STORE_DOSSIER`)}</span>
              </Button> : null
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.close_save) && this.getMainCode()
              ? <Button onClick={() => {
                submitForm()
                trackAction(saveAction)
              }}
              id="save"
              disabled = { !pristine ? false : !this.hasPermission(buttonsPermissions.force_save) }
              className={'first btn-white btn-icon btn-save ' + (pristine ? '' : 'dirty')}>
                <i className={!pristine ? '' : 'ico-folder-open'} /><Glyphicon glyph={!pristine ? 'floppy-disk' : ''} />
                <span>{pristine ? (t(`${tKey}SAVE_DOSSIER`)) : (t(`${tKey}SAVE_CHANGES`))}</span>
              </Button> : null
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.resend_processing_agency)
              ? <Button onClick={() => resendToProcessingAgency(dossierId, lastAccessDate, reset)} className="first btn-white btn-icon" id="resend_processing_agency">
                <i className="ico-document-plus" />
                <span>{t(`${tKey}RESEND_PROCESSING_AGENCY`)}</span>
              </Button> : null
          }
          {
            dossierType === dossierTypes.sales && !readOnlyFields && this.hasPermission(buttonsPermissions.sale_pay_renting)
              ? <Button onClick={() => salePayRenting(dossierId)} className="first btn-white btn-icon" id="sale_pay_renting">
                <i className="ico-check" />
                <span>{t(`${tKey}SALE_PAY_RENTING`)}</span>
              </Button> : null
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.configureCampaign)
              ? <Button onClick={() => configureCampaign(this.props.actions.openConfigureCampaignEdition, dossierId, this.props.pristine)} className="first btn-white btn-icon" id="configureCampaign">
                <i className="ico-check" />
                <span>{t(`${tKey}CONFIGURE_CAMPAIGNS`)}</span>
              </Button> : null
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.sendDocumentsToSignSales) && this.getMainCode()
              ? <Button
                onClick={() => {
                  openDocumentsToSendSign(this.props.actions.openSendDocumentsToSignModal)
                  trackAction(openSignModalAction)
                }}
                id="sendDocumentsToSign"
                className="first btn-DocsToSend-blue btn-white btn-icon" >
                <i className="ico-edit-white btn-DocsToSend-blue" />
                <span>{t(`${tKey}SEND_DOCUMENTS_SIGN`)}</span>
              </Button> : null
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.sendDocumentsToSignPurchase) && this.getMainCode()
              ? <Button onClick={() => {
                openDocumentsToSendSign(this.props.actions.openSendDocumentsToSignModal)
                trackAction(openSignModalAction)
              }}
              id="sendDocumentsToSign"
              className="first btn-DocsToSend-blue btn-white btn-icon" >
                <i className="ico-edit-white" />
                <span>{t(`${tKey}SEND_DOCUMENTS_SIGN`)}</span>
              </Button> : null
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.skipItv)
              ? <Button onClick={() => skipItv(dossierId)} className="first btn-DocsToSend-blue btn-white btn-icon" id="skipItv">
                <span>{t(`${tKey}SKIP_ITV`)}</span>
              </Button> : null
          }
          {
            !readOnlyFields && this.props.viewConfiguration && this.props.viewConfiguration.dynamicButtonsPermission && this.props.viewConfiguration.dynamicButtonsPermission.map((button, idx) => {
              return (
                <Button onClick={() => approveMargin(dossierId, button.code)} className="first btn-white btn-icon" key={idx} id={button.code}>
                  <i className="ico-check" />
                  <span>{button.buttonText}</span>
                </Button>
              )
            })
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.changeUO)
              ? <Button onClick={() => this.setState({ showChangeUoSelectModa: true })} className="first btn-white btn-icon" id="changeUO">
                <span>{t(`${tKey}CHANGE_UO`)}</span>
              </Button>
              : null
          }
          {
            this.hasPermission(buttonsPermissions.anulateCampaign)
              ? <Button onClick={() => anulateCampaign(dossierId)} className="first btn-DocsToSend-blue btn-white btn-icon" id="anulateCampaign">
                <i className="ico-document-plus" />
                <span>{t(`${tKey}ANULATE`)}</span>
              </Button> : null
          }
          {
            this.hasPermission(buttonsPermissions.btn_get_vehicle_from_quiter) && this.getMainCode()
              ? <Button onClick={() => openChargeModalDealer(dossierId, dossierSubTypes)} className="first btn-white btn-icon" id="btn_get_vehicle_from_quiter">
                <i className="ico-document-plus" />
                <span>{t(`${tKey}CHARGE_PURCHASE_FROM_QUITER`)}</span>
              </Button> : null
          }
          {
            (this.hasPermission(buttonsPermissions.update_dossier_toshiko))
              ? <Button onClick={() => this.updateDossierToshiko()} className="first btn-white btn-icon" id="update_dossier_toshiko">
                <i className="ico-document-plus" />
                <span>{t(`${tKey}UPDATE_DOSSIER`)}</span>
              </Button> : null
          }
          {
            this.hasPermission(buttonsPermissions.campaign_sale_replace) && this.getMainCode()
              ? <Button onClick={() => fetchAndOpenReplacePurchaseModal(purchaseModalTypes.campaignDossier, pristine)} className="first btn-white btn-icon" id="campaign_sale_replace">
                <i className="ico-document-plus" />
                <span>{t(`${tKey}SALE_REPLACE`)}</span>
              </Button> : null
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.reg_delivery_replace) && this.getMainCode()
              ? <Button onClick={() => fetchAndOpenReplacePurchaseModal(purchaseModalTypes.registrationDeliveryDossier, pristine)} className="first btn-white btn-icon" id="reg_delivery_replace">
                <i className="ico-document-plus" />
                <span>{t(`${tKey}SALE_REPLACE`)}</span>
              </Button> : null
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.bt_validate_campaign) && this.getMainCode()
              ? <Button onClick={() => campaignvalidate(dossierId)} className="first btn-white btn-icon" id="bt_validate_campaign">
                <span>{t(`${tKey}BT_VALIDATE_CAMPAIGN`)}</span>
              </Button> : null
          }
          {
            !readOnlyFields && (this.hasPermission(buttonsPermissions.bt_denie_campaig_stageApprove) || this.hasPermission(buttonsPermissions.bt_denie_campaig_stageVerificaction)) && this.getMainCode()
              ? <Button onClick={() => campaignDenie(dossierId)} className="first btn-white btn-icon" id="bt_denie_campaig_stageApprove">
                <span>{t(`${tKey}BT_DENIE_CAMPAIGN`)}</span>
              </Button> : null
          }
          {
            !readOnlyFields && (this.hasPermission(buttonsPermissions.bt_return_campaig_stageApprove) || this.hasPermission(buttonsPermissions.bt_return_campaig_stageVerificaction)) && this.getMainCode()
              ? <Button onClick={() => campaignReject(dossierId)} className="first btn-white btn-icon" id="bt_return_campaig_stageApprove">
                <span>{t(`${tKey}BT_RETURN_CAMPAIGN`)}</span>
              </Button> : null
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.service_dossier_confirm)
              ? <Button onClick={() => serviceDossierApprove(dossierId, reset)} className="first btn-white btn-icon" id="service_dossier_confirm">
                <i className="ico-check" />
                <span>{t(`${tKey}SERVICE_DOSSIER.APPROVE`)}</span>
              </Button> : null
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.national_vo_approve)
              ? <Button onClick={() => saleApproveByRole(pristine, approvalRoleCode.approveNationalVO)} className="first btn-white btn-icon" id="approveNationalVo">
                <i className="ico-document-plus" />
                <span>{t(`${tKey}SALE_APPROVE_NATIONAL_VO`)}</span>
              </Button> : null
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.export_control_approve)
              ? <Button onClick={() => saleApproveByRole(pristine, approvalRoleCode.approveExportControl)} className="first btn-white btn-icon" id="approveExportControl">
                <i className="ico-document-plus" />
                <span>{t(`${tKey}SALE_APPROVE_EXPORT_CONTROL`)}</span>
              </Button> : null
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.service_dossier_cancel) && this.getMainCode()
              ? <Button onClick={() => {
                if (this.state.fields.cancelTab?.permission === null || this.state.fields.cancelTab?.permission < permissions.editable) {
                  serviceDossierCancel(dossierId, pristine, reset)
                  trackAction(cancelAction)
                } else {
                  this.setState({ showCancelReasonModal: true })
                }
              }}
              className="first btn-white btn-icon btn-cancel" id="cancel">
                <i className="ico-trash" />
                <span>{t(`${tKey}SERVICE_DOSSIER.CANCEL`)}</span>
              </Button> : null
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.service_resolve_issue)
              ? <Button onClick={() => serviceResolveIssue(pristine)} className="first btn-white btn-icon" id="service_resolve_issue">
                <span>{t(`${tKey}SERVICE_DOSSIER.ISSUE_RESOLVED`)}</span>
              </Button> : null
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.service_request_spareParts)
              ? <Button onClick={() => serviceRequestSpareParts(pristine, dossierId)} className="first btn-white btn-icon" id="service_request_spareParts">
                <span>{t(`${tKey}SERVICE_DOSSIER.REQUEST_SPARE_PARTS`)}</span>
              </Button> : null
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.service_complete_spareParts)
              ? <Button onClick={() => serviceCompleteSpareParts(pristine)} className="first btn-white btn-icon" id="service_complete_spareParts">
                <span>{t(`${tKey}SERVICE_DOSSIER.COMPLETE_SPARE_PARTS`)}</span>
              </Button> : null
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.service_complete_deliveryPreparation)
              ? <Button onClick={() => serviceCompleteDeliveryPreparation(pristine)} className="first btn-white btn-icon" id="service_complete_deliveryPreparation">
                <span>{t(`${tKey}SERVICE_DOSSIER.COMPLETE_DELIVERY_PREPARATION`)}</span>
              </Button> : null
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.service_dossier_save)
              ? <Button onClick={() => {
                trackAction(saveAction)
                submitForm()
              }}
              id="save"
              disabled={!pristine ? false : !this.hasPermission(buttonsPermissions.force_save)}
              className={'first btn-white btn-icon btn-save ' + (pristine ? '' : 'dirty')}>
                <i className={!pristine ? '' : 'ico-folder-open'} /><Glyphicon glyph={!pristine ? 'floppy-disk' : ''} />
                <span>{pristine ? (t(`${tKey}SERVICE_DOSSIER.SAVE`)) : (t(`${tKey}SAVE_CHANGES`))}</span>
              </Button> : null
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.service_approve_logistic) && this.getMainCode()
              ? <Button onClick={() => serviceApproveByRole(pristine, approvalRoleCode.approveServiceLogistics)} className="first btn-white btn-icon" id="service_approve_logistic">
                <i className="ico-check"/>
                <span>{t(`${tKey}SERVICE_DOSSIER.APPROVE_BY_LOGISTIC`)}</span>
              </Button> : null
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.service_complete_pap)
              ? <Button onClick={() => serviceApproveByRole(pristine, approvalRoleCode.approveServiceCompletePap)} className="first btn-white btn-icon" id="service_complete_pap">
                <i className="ico-check" />
                <span>{t(`${tKey}SERVICE_DOSSIER.COMPLETE_PAP`)}</span>
              </Button> : null
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.service_pap_issue)
              ? <Button onClick={() => openOrCloseIssueModal()} className="first btn-white btn-icon" id="service_pap_issue">
                <i className="ico-bocadillo" />
                <span>{t(`${tKey}SERVICE_DOSSIER.CREATE_ISSUE_PAP`)}</span>
              </Button> : null
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.send_to_dms)
              ? <Button onClick={() => saleSendBproDms(pristine)} className="first btn-white btn-icon" id="send_to_dms">
                <i className="ico-document-plus" />
                <span>{t(`${tKey}SEND_TO_DMS`)}</span>
              </Button> : null
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.service_dossier_store) && this.getMainCode()
              ? <Button onClick={() => {
                storeDossier(dossierId, pristine, reset)
                trackAction(storeAction)
              }} className="first btn-white btn-icon" id="store">
                <i className="ico-document-plus" />
                <span>{t(`${tKey}STORE_DOSSIER`)}</span>
              </Button> : null
          }
          {
            !readOnlyFields && this.hasPermission(buttonsPermissions.bt_paperwork_notify) && this.getMainCode()
              ? <Button onClick={() => paperworkNotify(dossierId)} className="first btn-white btn-icon" id="bt_paperwork_notify">
                <span>{t(`${tKey}BT_PAPERWORK_NOTIFY`)}</span>
              </Button> : null
          }
          {!readOnlyFields && this.hasPermission(buttonsPermissions.btn_store_delete_any_stage) && this.getMainCode()
            ? <Button onClick={() => this.setState({ showStoreCancelModal: true })}
              className="btn-white btn-icon" id="storeCancel">
              <i className="ico-document-plus" />
              <span>{t(`${tKey}STORE_CANCEL_DOSSIER`)}</span>
            </Button>
            : null
          }
          {this.hasPermission(buttonsPermissions.view_btn_summary)
            ? <Button
              className="btn-white btn-icon"
              id="print-summary-button"
              onClick={() => printCompleteSummarySales(dossierId, this.props.historical)}
            >{t(`${tKey}PRINT_SUMMARY`)}</Button>
            : null
          }
          {!readOnlyFields && this.hasPermission(buttonsPermissions.sale_transfer_cancel_request_vo)
            ? <Button onClick={() => transferReject()}
              className="btn-white btn-icon" id="storeCancel">
              <i className="ico-document-plus" />
              <span>{t(`${tKey}SALE_REJECT`)}</span>
            </Button>
            : null
          }
          {!readOnlyFields && this.hasPermission(buttonsPermissions.purchase_transfer_cancel_request_vo)
            ? <Button onClick={() => transferReject()}
              className="btn-white btn-icon" id="storeCancel">
              <i className="ico-document-plus" />
              <span>{t(`${tKey}PURCHASE_REJECT`)}</span>
            </Button>
            : null
          }
        </div>
        {!readOnlyFields && dossierType === dossierTypes.purchase && !this.props.historical &&
          <SelectVoCompany
            t={t}
            showModal={this.state.showSelectVoCompanyModal}
            organizedUnitId={organizedUnitId}
            voCompaniesCombo={combos.voCompaniesCombo}
            dossierId={dossierId}
            actions={{
              closeSelectVoCompanyModal: () => this.setState({ showSelectVoCompanyModal: false }),
              fetchVoCompaniesCombo,
              submitSelectVoCompany
            }}
          />
        }
        { !readOnlyFields && this.hasPermission(buttonsPermissions.changeUO) &&
          <ChangeUoSelectModal
            t={t}
            showModal={this.state.showChangeUoSelectModa}
            dossierId={dossierId}
            organizedUnitId = {organizedUnitId}
            organicedUnits = {lowestOrganizedUnitsCombo}
            dossierType={dossierType}
            changesDossierUo={changeUo ? changeUo.changesDossierUo : null}
            salesmanChange={changeUo ? changeUo.salesmanChange : null}
            actions={{
              closeChangeUoSelectModal: () => this.setState({ showChangeUoSelectModa: false }),
              fetchLowestOrganizedUnits,
              getChangeUo,
              getSalesmanChangeUo,
              postUpdateChangeUo,
              fecthOrganizedUnitsByDossierUo
            }}
          />}
        { !readOnlyFields && this.hasPermission(buttonsPermissions.btn_store_delete_any_stage) &&
        <StoreCancelModalPage
          showModal={this.state.showStoreCancelModal}
          t={t}
          dossierId={dossierId}
          pristine = {pristine}
          rest={reset}

          closeStoreCancelModal={() => this.setState({ showStoreCancelModal: false })}

        ></StoreCancelModalPage>
        }
        <UpdateDossierPage
          showModal={this.state.showUpdateDossier}
          isNew= {false}
          t={t}
          dossierId={dossierId}
          pristine = {pristine}
          rest={reset}
          ouId={this.props.organizedUnitId}
          closeStoreCancelModal={() => this.setState({ showUpdateDossier: false })}
        ></UpdateDossierPage>
        <CancelReasonModal
          t={t}
          showModal={this.state.showCancelReasonModal}
          combos= {cancelCombo}
          type={type}
          actions={{
            rejectAction: (comment, resolve, reject) => {
              this.setState({ reason: comment.reason })
              this.setState({ comment: comment.comment })
              putCancelDossier(dossierId, pristine, reset, null, comment.reason, comment.comment)
              this.toggleRejectCommentModal(false)
            },
            closeModal: () => {
              this.toggleRejectCommentModal(false)
            },
            fetchCancelCombo: fetchCancelCombo
          }}
        />
      </div>
    )
  }
}

var decoratedButtonHeader = TrackingContainer(ButtonsHeader, false)
export default decoratedButtonHeader
