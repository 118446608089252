import React, { PureComponent } from 'react'
import { Button, Modal, Col } from 'react-bootstrap'
import { reduxForm } from 'redux-form'
import SalesComponent from '../../dossiers/sales/SalesComponent'
import { dossierTypes as dossierTypesConstant } from '../../../constants/dossier/common/dossierTypes'

class DataSalesFleetModal extends PureComponent {
  constructor () {
    super()
    this.state = {}
  }

  saveData (values) {
    let dossierList = this.props.dossiers.map(x => { return x.dossierId })
    if (values !== null && values !== undefined && values.salesComponent && (this.haveValues(values.salesComponent) || this.haveValues(values.dossierCompleteOtherDataComponent))) {
      this.props.actions.setOperateDataSaleFleetProcess(dossierList, this.props.componentConfiguration, values.salesComponent, values.dossierCompleteOtherDataComponent?.vehicleTypeId, this.props.fleet.fleetId, () => this.props.actions.closeDataSalesFleetModal())
    } else {
      this.props.actions.openModal('notChangesToSave')
    }
  }

  haveValues (fields) {
    for (let key in fields) {
      if (fields[key] !== null && fields[key] !== undefined && fields[key] !== '') {
        if (Array.isArray(fields[key]) && fields[key].length > 0) {
          return true
        } else if (!Array.isArray(fields[key])) {
          return true
        }
      }
    }
    return false
  }

  componentDidUpdate () {}

  getSnapshotBeforeUpdate (prevProps) {
    var chargeSaletype = false
    if (this.props.dossier && this.props.dossier.organizedUnitId && this.props.dossier.organizedUnitId !== prevProps.dossier?.organizedUnitId) {
      this.props.actions.fetchSalesmanCombos(this.props.dossier.organizedUnitId)
      chargeSaletype = true
    }
    if (this.props.fleet && this.props.fleet.dossierSubType && this.props.fleet.dossierSubType !== prevProps.fleet?.dossierSubType) {
      this.props.actions.fetchAdditionalInfoCombo(this.props.fleet.dossierSubType)
      chargeSaletype = true
    }
    if (chargeSaletype) {
      this.props.actions.fetchSalesTypeCombo(this.props.dossier.organizedUnitId, this.props.fleet.dossierSubType)
    }

    if (this.props.showModal && !prevProps.showModal && (this.props.combos.paymentMethodCombo === undefined || this.props.combos.paymentMethodCombo === null || this.props.combos.paymentMethodCombo.length === 0)) {
      this.props.actions.fetchPaymentCombo()
    }

    if (this.props.showModal && !prevProps.showModal && (this.props.combos.vehicleType === undefined || this.props.combos.vehicleType === null || this.props.combos.vehicleType.length === 0)) {
      this.props.actions.fetchVehicleType()
    }

    if (this.props.showModal && !prevProps.showModal) {
      this.props.initialize()
      this.props.actions.fetchOperateDataFleetModalsPermissions(this.props.fleet.dossierSubType, this.props.dossier.organizedUnitId, this.props.dossier.stageId)
    }
    return null
  }

  render () {
    const {
      t,
      showModal, handleSubmit, actions: {
        closeDataSalesFleetModal, fetchOperationType, fetchFleetOperationTypeForAutoselect, printDocumentaryChecklist, postPaymentSignalStatusSuccess,
        updateLastAccessDate, printSummarySales, openDocumentEditionModal
      }, change, componentConfiguration
    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.SALES.'

    if (showModal && componentConfiguration) {
      return (
        <Modal className="fleet-created-select-modal languages-modal" show={showModal} onHide={closeDataSalesFleetModal} backdrop={'static'}>
          <form className='formDataSalesFleet' autoComplete="off" onSubmit={handleSubmit((values) => this.saveData(values))}>
            <Modal.Header closeButton onHide={this.props.actions.closeActionDataModal}>
              <Modal.Title>  <i className="ico-sales" />{t(`${tKey}SALES`)}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <SalesComponent
                t={t}
                dossier={this.props.dossier}
                salesOpen={true}
                combos={this.props.combos}
                actions={{
                  change,
                  fetchOperationType,
                  fetchFleetOperationTypeForAutoselect,
                  printDocumentaryChecklist,
                  openDocumentEditionModal,
                  postPaymentSignalStatusSuccess,
                  updateLastAccessDate,
                  printSummarySales
                }}
                printSummarySalePermission={this.props.printSummarySalePermission}
                readOnlyFields={false}
                dossierType={dossierTypesConstant.fleetFinder}
                open={true}
                componentConfiguration={componentConfiguration}
                editableDoc={true}
                fleetSelected ={false}
                key="salesComponentFleetModal"
              ></SalesComponent>
            </Modal.Body>
            <Modal.Footer>
              <Col sm={12} className='fleet-created-moda-btn-exit'>
                <Button
                  id="btn_search"
                  className="btn-standard"
                  type='submit'
                  disabled={this.props.pristine}>
                  {t('MANAGEMENT.FLEETS_TAB.AUX_MODALS.SAVE')}
                </Button>
                <Button
                  id='btn-close-fleet-created-select-charge-modal'
                  className="btn-exit"
                  onClick={() => closeDataSalesFleetModal()}>
                  {t('MANAGEMENT.FLEETS_TAB.AUX_MODALS.EXIT')}
                </Button>
              </Col>
            </Modal.Footer>
          </form>
        </Modal>

      )
    } else {
      return null
    }
  }
}

export default reduxForm({
  form: 'dataSalesFleetModal'
})(DataSalesFleetModal)
