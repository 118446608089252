import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { openCloseUserRecurrentServiceModal, getUserRecurrentServiceEntity, deleteUserRecurrentServiceEntity, openCloseAddUserRecurrentServiceModal } from '../../../actions/admin/recurrentEntityAdmin'
import UserRecurrrentEntityModal from '../../../components/admin/recurrentEntity/UserRecurrrentEntityModal'
import { openModal } from '../../../actions/common'

export function mapStateToProps (state) {
  const recurrentEntityAdminModal = state.recurrentEntityAdmin.userRecurrrentEntityModal
  return {
    ...recurrentEntityAdminModal
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      getUserRecurrentServiceEntity,
      openCloseUserRecurrentServiceModal,
      deleteUserRecurrentServiceEntity,
      openModal,
      openCloseAddUserRecurrentServiceModal
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(UserRecurrrentEntityModal))
