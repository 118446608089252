import React, { PureComponent } from 'react'
import { reduxForm, Field } from 'redux-form'
import { Row, Button, Col } from 'react-bootstrap'
import InputText from '../commons/form/InputText'
import InputDatePicker from '../commons/form/InputDatePicker'
import SimpleTablePage from '../../containers/commons/general/SimpleTablePage'
import IDocCarPagination from '../commons/iDocCarPagination'

class ftpRegisterSearch extends PureComponent {
  constructor () {
    super()
    this.state = {
      disableResetButton: true,
      fields: {}
    }
  }

  componentDidUpdate (nextProps) {
    let disableResetButton = nextProps.pristine
    this.setState({ disableResetButton: disableResetButton })
  }

  onPageChange (page) {
    this.props.actions.fetchFtpRegister({ ...this.props.filters, ...this.state.fields, page: page })
  }

  onOrderChange (e) {
    const newOrderBy = this.props.filters.orderBy.includes(`%2B${e.currentTarget.dataset.field}`) ? `-${e.currentTarget.dataset.field}` : `%2B${e.currentTarget.dataset.field}`
    this.props.actions.fetchFtpRegister({ ...this.props.filters, ...this.state.fields, orderBy: newOrderBy })
  }

  fieldFill (field) {
    this.setState({ fields: { ...this.state.fields, ...field } })
  }

  getIconClass (field) {
    if (this.props.filters.orderBy.includes(field)) {
      return this.props.filters.orderBy.includes('-') ? 'ico-sort-selected-asc' : 'ico-sort-selected-desc'
    } else {
      return 'ico-sort'
    }
  }

  handleClickReset (e) {
    if (!this.state.disableResetButton) {
      this.onClickResetFilters(e)
    }
  }

  onClickResetFilters (e) {
    if (e) {
      e.preventDefault()
    }
    this.props.initialize({})
    this.setState({ fields: {} })
    this.setState({ disableResetButton: true })
  }

  formatDate (date) {
    if (date !== null) {
      var d = date.split('/')
      return [d[2], d[1], d[0]].join('-')
    } else {
      return null
    }
  }

  exportColumns () {
    const tKey = 'IMPORT_EXPORT_FILE.'

    let columnsExcel = []
    columnsExcel.push(this.props.t(`${tKey}NAME`))
    columnsExcel.push(this.props.t(`${tKey}DATE`))
    columnsExcel.push(this.props.t(`${tKey}SIZE`))
    columnsExcel.push(this.props.t(`${tKey}PROCESS`))
    columnsExcel.push(this.props.t(`${tKey}PATH`))
    columnsExcel.push(this.props.t(`${tKey}RECEIVED`))

    return columnsExcel
  }

  exportData (e) {
    this.props.actions.fetchFtpRegisterExport({ ...this.props.filters, ...this.state.fields }, this.exportColumns())
  }

  search () {
    this.props.actions.fetchFtpRegister({ ...this.props.filters, ...this.state.fields })
  }

  render () {
    const {
      t, hasSearch, filters: { page }, pages, count, ftpRegister
    } = this.props

    const tKey = 'IMPORT_EXPORT_FILE.'

    const tKeySearch = 'SEARCH.CATEGORIES.DOSSIER.'

    const styleTd = {
      verticalAlign: 'middle'
    }

    return (
      <div>
        <div className="search-header">
          <div className="title-user">
            <i className="ico-documental-search"/> <h2>{t(`${tKey}FTPREGISTER`)}</h2>
          </div>
        </div>
        <Field
          id="name"
          name='ftp.name'
          placeholder={t(`${tKeySearch}FILE_NAME`)}
          controlLabel={t(`${tKeySearch}FILE_NAME`)}
          component={InputText}
          type="text"
          onInputChange={(val) => this.fieldFill({ name: val })}
        />
        <Field
          id="dateFrom"
          name='ftp.DateFrom'
          placeholder={t(`${tKey}DATE_FROM`)}
          controlLabel={t(`${tKey}DATE_FROM`)}
          component={InputDatePicker}
          //  validate={[this.validateDate]}
          onInputChange={(val) => this.fieldFill({ dateFrom: this.formatDate(val) })}
        />
        <Field
          id="dateTo"
          name='ftp.dateTo'
          placeholder={t(`${tKey}DATE_TO`)}
          controlLabel={t(`${tKey}DATE_TO`)}
          component={InputDatePicker}
          // validate={[this.validateDate]}
          onInputChange={(val) => this.fieldFill({ dateTo: this.formatDate(val) })}
        />
        <Row>
          <Col sm={12} className="text-center">
            <hr/>
            <Button
              onClick={(e) => this.search(e)}
              className="btn-standard"
            >
              <i className="ico-search"/>
              {t('SEARCH.ACTIONS.SEARCH_BUTTON')}
            </Button>
            <Button
              onClick={(e) => this.handleClickReset(e)}
              className={'btn-white btn-icon' + (this.state.disableResetButton ? ' btn-cancel' : ' btn-standard')}
              style={{ marginLeft: '10px', fontSize: '12px' }}
            >
              <i className="ico-cancel"/> {t('SEARCH.ACTIONS.RESET_BUTTON')}
            </Button>
            {hasSearch && ftpRegister && ftpRegister.length > 0 && (<Button
              onClick={(event) => this.exportData(event)}
              className="btn-standard" style={{ marginLeft: '166px' }}>
              <i className="ico-if_Download_1031520" />
              {t('SEARCH.ACTIONS.EXPORT')}
            </Button>)}
            <br></br>
            <br></br>
          </Col>
        </Row>
        <div>
          {hasSearch ? ([

            <div className="table-wrapper" key="table-wrapper">
              <SimpleTablePage
                columns={[
                  <th key={0} data-field="name" onClick={(event) => this.onOrderChange(event)}>
                    {t(`${tKey}NAME`)}&nbsp;<i className={this.getIconClass('name')}/>
                  </th>,
                  <th key={1} data-field="date" onClick={(event) => this.onOrderChange(event)}>
                    {t(`${tKey}DATE`)}&nbsp;<i className={this.getIconClass('date')}/>
                  </th>,
                  <th key={2} data-field="size" onClick={(event) => this.onOrderChange(event)}>
                    {t(`${tKey}SIZE`)}&nbsp;<i className={this.getIconClass('size')}/>
                  </th>,
                  <th key={4} data-field="process" onClick={(event) => this.onOrderChange(event)}>
                    {t(`${tKey}PROCESS`)}&nbsp;<i className={this.getIconClass('process')}/>
                  </th>,
                  <th key={5} data-field="path" onClick={(event) => this.onOrderChange(event)}>
                    {t(`${tKey}PATH`)}&nbsp;<i className={this.getIconClass('path')}/>
                  </th>,
                  <th key={3} data-field="received" onClick={(event) => this.onOrderChange(event)}>
                    {t(`${tKey}RECEIVED`)}&nbsp;<i className={this.getIconClass('received')}/>
                  </th>
                ]}
                rows={ftpRegister && ftpRegister.map((row, idx) => (
                  <tr key={idx}>
                    <td style={styleTd}>{row.name}</td>
                    <td style={styleTd}>{new Date(Date.parse(row.date)).toLocaleString()}</td>
                    <td style={styleTd}>{row.size}  Kb</td>
                    <td style={styleTd}>{row.process}</td>
                    <td style={styleTd}>{row.path}</td>
                    <td style={styleTd}>{row.received ? 'SI' : 'NO'}</td>
                  </tr>
                ))}
              />
            </div>,
            <div className="search-footer" key="search-footer">
              <IDocCarPagination
                id="btn_pag_sales"
                page={page}
                pagesCount={pages}
                totalRows= {count}
                onSelectAction={(page) => this.onPageChange(page)}
              />
            </div>
          ]) : null}
        </div>
      </div>
    )
  }
}

export default reduxForm({
  form: 'ftpRegisterSearch'
  // validate
})(ftpRegisterSearch)
