import { call, put, select, takeLatest } from 'redux-saga/effects'
import { reset } from 'redux-form'
import { getAuth } from '../../selectors/access/auth'
import getEntityTypes from '../../services/entityType/getEntityTypes'
import { showLoader, hideLoader } from '../../actions/common'
import messageModalActions from '../../actions/modals/messageModal'
import { fetchCombosSuccess } from '../../actions/combos/combos'
import combosActionTypes from '../../constants/actions/combos/combos'
import { handleError } from '../errors/errorManager'

export function * fetchEntityTypes () {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const entityTypeCombo = yield call(getEntityTypes, auth.token)
    yield put(fetchCombosSuccess({ entityTypeCombo }))
  } catch (error) {
    const customHandler = function * () {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.FETCH_COMBO_ERROR'))
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('purchase_dossier'))
      yield put(reset('paperwork_dossier'))
      yield put(reset('campaign_dossier'))
      yield put(reset('registrationDeliveryDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchEntityTypes () {
  yield takeLatest(combosActionTypes.FETCH_ENTITY_TYPES, fetchEntityTypes)
}
