import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import ModalDocumentFullScreen from '../../../components/dossiers/common/documentaryManagement/documentEditionModal/ModalDocumentFullScreen'
import { toggleFullScreenDocumentModal } from '../../../actions/dossier/common/documentEditionModal'

export function mapStateToProps (state) {
  return {
    ...state.documentEditionModal,
    token: state.auth.token
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      toggleFullScreenDocumentModal
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalDocumentFullScreen)
