import React, { PureComponent } from 'react'
import { Panel, Button, OverlayTrigger, Tooltip, Col } from 'react-bootstrap'
import SimpleTablePage from '../../../containers/commons/general/SimpleTablePage'
import { compareValues, formatMoney } from '../../../util/utils'
import { documentTypeUse as documentTypeUseConstants, typeFrontEntities } from '../../../constants/backendIds'

import { documentStatusClass, translateDocumentStatus } from '../../../constants/dossier/common/documentStatus'
import ChargeDetailsFleetModalPage from '../../../containers/management/auxModals/ChargeDetailsFleetModalPage'

class ChargeFleetListComponent extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      openChargePanel: false,
      filters: {
        orderBy: '+number'
      }
    }
  }

  componentDidMount () {
  }

  collapsePanelDossier () {
    var newState = !this.state.openChargePanel
    this.setState({ openChargePanel: newState })
    if (newState && this.props.fleet) {
      this.props.actions.getChargesDetailsFleet(this.props.fleet.fleetId)
    }
  }

  componentDidUpdate () {
  }

  getSnapshotBeforeUpdate (prevProps) {
    return null
  }

  getIconClass (field) {
    if (this.state.filters.orderBy.includes(field)) {
      return this.state.filters.orderBy.includes('-') ? 'ico-sort-selected-asc' : 'ico-sort-selected-desc'
    } else {
      return 'ico-sort'
    }
  }

  onOrderChange (e) {
    const newOrderBy = this.state.filters.orderBy.includes(`+${e.currentTarget.dataset.field}`) ? `-${e.currentTarget.dataset.field}` : `+${e.currentTarget.dataset.field}`
    this.setState({ filters: { orderBy: newOrderBy } })
    this.order(e.currentTarget.dataset.field, newOrderBy.includes('+') ? 'asc' : 'desc')
  }

  order (field, order) {
    let dossiers = JSON.parse(JSON.stringify(this.props.chargeFleetDetails))
    dossiers = dossiers.sort(compareValues(field, order))
    this.props.actions.getChargesDetailsFleetSuccess(dossiers)
  }

  updateDossiers () {
    if (this.props.fleet && !this.props.create) {
      this.props.actions.getChargesDetailsFleet(this.props.fleet.fleetId)
    }
  }

  statusTooltip (status) {
    const statusTooltipTKey = 'DOCUMENT_SEARCH.TABLE.STATUS.'
    return (<Tooltip id="statusTooltip">{status && this.props.t(`${statusTooltipTKey}${translateDocumentStatus[status]}`)}</Tooltip>)
  }

  openDocument (doc) {
    this.props.actions.openDocumentEditionModalFleet(null, doc.documentId, documentTypeUseConstants.charge,
      typeFrontEntities.DOSSIER, -1, null, null, [doc], this.props.fleet.organizedUnitId,
      null, null, true, null, this.props.fleet.dossierSubType, null, true, null, false, true, this.props.readOnlyFields,
      false, false, null, this.props.fleet.fleetId, doc.fleetChargeId)
  }

  openDetail (fleetCharge) {
    this.props.actions.openChargeDetailsModal(fleetCharge, this.props.fleet)
  }

  addToAll () {
    this.props.setCharges()
  }

  render () {
    const {
      t,
      chargeFleetDetails,
      readOnlyFields,
      fleetEconomic
    } = this.props
    const tKey = 'MANAGEMENT.FLEETS_TAB.'
    const viewDocument = (<Tooltip id="viewDocument">{t('DOSSIER_COMPONENTS.CHARGES.VIEW_DOC')}</Tooltip>)
    const viewDetail = (<Tooltip id="viewDetail">{t(`${tKey}DETAIL`)}</Tooltip>)

    let columnsTable = [
      <th key={0} data-field="number" onClick={(event) => this.onOrderChange(event)}>
        {t(`${tKey}CHARGE_NUMBER`)}&nbsp;<i className={this.getIconClass('number')}></i>
      </th>,
      <th key={1} data-field="description" onClick={(event) => this.onOrderChange(event)}>
        {t(`${tKey}DESCRIPTION`)}&nbsp;<i className={this.getIconClass('description')}></i>
      </th>,
      <th key={3} data-field="amount" onClick={(event) => this.onOrderChange(event)}>
        {t(`${tKey}AMOUNT`)}&nbsp;<i className={this.getIconClass('amount')}/>
      </th>,
      <th key={4} data-field="documentStatusId" onClick={(event) => this.onOrderChange(event)} className='number-column'>
        {t(`${tKey}VALIDATE`)}&nbsp;<i className={this.getIconClass('documentStatusId')}></i>
      </th>,
      <th key={5} data-field="document" className='number-column'>
        {t(`${tKey}DOCUMENT`)}&nbsp;
      </th>,
      <th key={6} data-field="dossierCount" onClick={(event) => this.onOrderChange(event)} className='number-column'>
        {t(`${tKey}DOSSIERS`)}&nbsp;<i className={this.getIconClass('dossierCount')}/>
      </th>,
      <th key={7} data-field="details" className='number-column'>
        {t(`${tKey}DETAIL`)}&nbsp;
      </th>
    ]

    return (
      <>
        <ChargeDetailsFleetModalPage readOnlyFields={this.props.readOnlyFields}></ChargeDetailsFleetModalPage>
        <div className="dossier-panel" >
          <div id="chargesFleetHeader" className="header-panel  fleet-panel-box" onClick={() => this.collapsePanelDossier()}>

            <div className="name-wrapper fleet-panel-box-title">
              <h4>{t(`${tKey}CHARGES`)}</h4>
            </div>

            <i id="chargesFleetArrow" className={this.state.openChargePanel ? 'ico-arrow-black-down fleet-panel-box-caret' : 'ico-arrow-black-up fleet-panel-box-caret'} />

          </div>
          <Panel expanded={this.state.openChargePanel} onToggle={() => {}}>
            <Panel.Collapse>
              <div id="fleet-dossier-details-table">
                {!readOnlyFields && (
                  <div className="flexInLine al-R">
                    <Button
                      id="update_charges_button"
                      className="_Btn accept stk flexInLine al-R"
                      onClick = {() => this.updateDossiers()}>
                      <i className="iDocIcon-update"></i>
                      {t('MANAGEMENT.ADMIN_AUDIT.MODAL.UPDATE')}
                    </Button>
                  </div>
                )}
                <SimpleTablePage
                  columns={columnsTable}
                  rows={chargeFleetDetails && chargeFleetDetails.map((row, idx) => (
                    <tr key={idx}>
                      <td>{row.number}</td>
                      <td>{row.description}</td>
                      <td>{formatMoney(row.amount)}</td>
                      <td className='number-column width-10'>
                        <span>
                          <OverlayTrigger placement="right" overlay={this.statusTooltip(row.documentStatusId)}>
                            <i id={'charge_documentStatus' + idx} className={'icono-heder-modal ico-document-' + documentStatusClass[translateDocumentStatus[row.documentStatusId]] + ' font-size-large'}/>
                          </OverlayTrigger>
                        </span>
                      </td>
                      <td className='number-column width-10'>
                        <OverlayTrigger placement="left" className="documentToolTipFleet" overlay={viewDocument}>
                          <a onClick={() => this.openDocument(row)}>
                            <i id={'charge_openDocument' + idx} className="ico-magnifying-plus font-size-large blue"></i>
                          </a>
                        </OverlayTrigger>
                      </td>
                      <td className='number-column width-10'>{row.dossierCount}</td>

                      <td className='number-column width-10'>
                        <OverlayTrigger placement="left" className="documentToolTipFleet" overlay={viewDetail}>
                          <a onClick={() => this.openDetail(row)}>
                            {<i id={'chargeDetail_' + idx} className="iDocIcon-visibility-eye font-size-large blue pointer"></i>}
                          </a>
                        </OverlayTrigger>
                      </td>
                    </tr>
                  ))}

                ></SimpleTablePage>
                <Col sm={4}>
                  <div className="button-table-wrapper">
                    {!readOnlyFields && (
                      <Button
                        id='paperWorkDocumentButton'
                        className="btn-Load document-button"
                        onClick={() => this.addToAll()}
                      >
                        {'+ ' + t(`${tKey}ADD`)}
                      </Button>)}
                  </div>
                </Col>
                <Col sm={8}>
                  <div className="table-totals-charges">
                    <Col sm={6} className="colums-total-charges">
                      <p style={{ borderRight: 'none' }} className='noMargin'><b>{t('DOSSIER_COMPONENTS.CHARGES.TOTALS.TOTAL_CHARGED')}:</b> <span>{fleetEconomic?.paymentAmount ? formatMoney(fleetEconomic?.paymentAmount) : formatMoney(0)}</span></p>
                      <p style={{ borderTop: 'none', borderRight: 'none' }} className='noMargin'><b>{t('DOSSIER_COMPONENTS.CHARGES.TOTALS.UAC_TOTAL')}:</b> <span>{fleetEconomic?.totalAmountUacs ? formatMoney(fleetEconomic?.totalAmountUacs) : formatMoney(0)}</span></p>
                    </Col>
                    <Col sm={6} className="colums-total-charges">
                      <p style={{ borderBottom: 'none' }} className='noMargin'><b>{t('DOSSIER_COMPONENTS.CHARGES.TOTALS.OPERATION_TOTAL')}:</b> <span>{fleetEconomic?.totalOperation ? formatMoney(fleetEconomic?.totalOperation) : formatMoney(0)}</span></p>
                      <p ><b>{t('DOSSIER_COMPONENTS.CHARGES.TOTALS.COLLECT_TOTAL')}:</b> <span>{fleetEconomic?.paymentPending ? formatMoney(fleetEconomic?.paymentPending) : formatMoney(0)}</span></p>
                    </Col>
                  </div>
                </Col>
              </div>

            </Panel.Collapse>
          </Panel>
        </div>

      </>
    )
  }
}

export default ChargeFleetListComponent
