import React, { Component } from 'react'
import { reduxForm, Field } from 'redux-form'
import { Button, Modal, Col, Row, Form, Tab, Tabs } from 'react-bootstrap'
import InputCheckBox from '../../../commons/form/InputCheckBox'
import InputText from '../../../commons/form/InputText'

class CampaignsConfigureModal extends Component {
  constructor (props) {
    super()
    this.state = {
      typeId: props.editedCampaign && props.editedCampaign.typeId || null,
      dossierSubTypeId: props.editedCampaign && props.editedCampaign.dossierSubTypeId || null,
      fieldsConfiguration: {
      },
      codeSearch: null,
      appliedCampaigns: {},
      incompatibility: {},
      actualListCampaigns: []
    }
  }

  UNSAFE_componentWillMount () {
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    let typeCam = []
    let appliedCampaigns = {}
    let auxCam = {}
    let campanas = []
    if (this.props.campaigns.length > 0) {
      let campaigns = this.props.campaigns
      campaigns.forEach(function (ca) {
        if (ca.campaignSubtypeId) {
          typeCam.push('cam' + ca.campaignSubtypeId)
        }
      })
    }
    if (nextProps.showModal && !this.props.showModal) {
      if (Object.keys(nextProps.campaigns).length > 0) {
        campanas = typeCam.map((ca) => {
          return Object.assign({ [`${ca}`]: true })
        }, {})
        campanas.forEach(function (camp) {
          appliedCampaigns = Object.assign(auxCam, camp)
          auxCam = appliedCampaigns
        })
        appliedCampaigns.codeSearch = this.state.codeSearch
        const appliedCampaignsMerge = { ...this.state.appliedCampaigns, ...appliedCampaigns }
        this.props.initialize(appliedCampaigns)
        this.setState({ appliedCampaigns: appliedCampaigns })
        if (this.props.campaigns.length > 0) {
          this.hasCompatibilitiesInitialModal(appliedCampaignsMerge, nextProps.campaignSubtype)
        }
      } else {
        appliedCampaigns.codeSearch = this.state.codeSearch
        const appliedCampaignsMerge = { ...this.state.appliedCampaigns, ...appliedCampaigns }
        this.state.appliedCampaigns = appliedCampaignsMerge
        this.setState({ appliedCampaigns: appliedCampaignsMerge })
        this.props.initialize(this.state.appliedCampaigns)
        this.hasCompatibilitiesInitialModal(appliedCampaignsMerge, nextProps.campaignSubtype)
      }
    }
  }

  hasCompatibilitiesInitialModal (appliedCampaigns, campaingTable) {
    let result = Object.entries(appliedCampaigns)
    result.forEach(async (cam) => {
      if (cam[0] !== 'codeSearch' && cam[1] === true) {
        this.hasCompatibilities(parseInt(cam[0].replace('cam', '')), true, appliedCampaigns, campaingTable)
      }
    })
  }

  hasCompatibilities (idCampaign, value, appliedCampaignsAux, campaingTable) {
    const newState = []
    let incompatibility = {}
    let incompatibilityModified = {}
    let listIncompatibles = []
    let appliedCampaigns = this.state.appliedCampaigns
    let campaignsSubtypeProps = !campaingTable ? this.props.campaignSubtype : campaingTable

    let compatibilities = campaignsSubtypeProps && this.props.campaignSubtype.find(function (element) {
      return element.campaignId === idCampaign
    })
    compatibilities && compatibilities.compatibilities.forEach(function (ca) {
      listIncompatibles.push(ca.compatibleCampaignId)
    })
    if (value === true && listIncompatibles.length > 0) {
      campaignsSubtypeProps.forEach(function (ca) {
        if (listIncompatibles.includes(ca.campaignId)) {
          newState.push({ ['cam' + `${ca.campaignId}`]: true })
        }
      })
      newState.forEach(function (camp) {
        incompatibilityModified = Object.assign(incompatibility, camp)
        incompatibility = incompatibilityModified
      })
      for (let i in newState) {
        if (this.state.appliedCampaigns) {
          appliedCampaigns[Object.getOwnPropertyNames(newState[i])] = false
        } else {
          appliedCampaigns['cam' + `${idCampaign}`] = false
        }
      }
      const incompatibilityMerge = { ...this.state.incompatibility, ...incompatibilityModified }
      this.setState({ incompatibility: incompatibilityMerge })
      this.state.incompatibility = incompatibilityMerge
      const appliedCampaignsMerge = { ...appliedCampaigns, ...appliedCampaignsAux }
      this.setState({ appliedCampaigns: appliedCampaignsMerge })
      this.props.initialize(appliedCampaignsMerge)
    }
  }

  setToformatCampaignsToApplied (values) {
    let result = Object.entries(values)
    let campaigns = ''
    for (let camName in result) {
      if (result[camName][0] !== 'undefined' && result[camName][1] === true) {
        campaigns = campaigns.concat(result[camName][0]).concat(',')
      } else if (result[camName][0] !== 'undefined' && result[camName][1] === false) {
        campaigns = campaigns.concat('del_' + result[camName][0]).concat(',')
      }
    }
    return JSON.stringify(campaigns.slice(0, -1))
  }

  hasOptionals (campaignId, value, appliedCampaigns) {
    let hasOptionals = false
    let campaign
    campaign = this.props.campaignSubtype && this.props.campaignSubtype.find(function (element) {
      return element.campaignId === campaignId
    })
    hasOptionals = campaign.hasOptionalsByModel
    if (hasOptionals === true && value === true) {
      new Promise((resolve) => {
        this.props.actions.hasOptionals(hasOptionals, appliedCampaigns, campaignId, value, resolve)
      }).then((result) => {
        if (result === true) {
          this.hasCompatibilities(campaignId, value)
        } else {
          this.hasCompatibilitiesDisable(campaignId)
        }
      })
    } else if (value === true) {
      this.hasCompatibilities(campaignId, value)
    }
  }

  hasCompatibilitiesDisable (idCampaign) {
    const newState = []
    let incompatibility = {}
    let incompatibilityModified = {}
    let listIncompatibles = []
    let compatibilities = this.props.campaignSubtype.find(function (element) {
      return element.campaignId === idCampaign
    })
    if (compatibilities.compatibilities.length > 0) {
      compatibilities.compatibilities.forEach(function (ca) {
        listIncompatibles.push(ca.compatibleCampaignId)
      })
      this.props.campaignSubtype.forEach(function (ca) {
        if (listIncompatibles.includes(ca.campaignId)) {
          newState.push({ ['cam' + `${ca.campaignId}`]: false })
        }
      })
      newState.forEach(function (camp) {
        incompatibilityModified = Object.assign(incompatibility, camp)
        incompatibility = incompatibilityModified
      })
      const incompatibilityMerge = { ...this.state.incompatibility, ...incompatibilityModified }
      this.setState({ incompatibility: incompatibilityMerge })
      this.state.incompatibility = incompatibilityMerge
      this.hasCompatibilitiesInitialModal(this.state.appliedCampaigns)
    }
  }

  handleCampaignTypeChange (value, campaignId) {
    const appliedCampaigns = this.state.appliedCampaigns
    appliedCampaigns['cam' + `${campaignId}`] = value
    this.setState({ appliedCampaigns: appliedCampaigns })
    if (this.props.campaigns.length > 0) {
      this.hasOptionals(campaignId, value, this.state.appliedCampaigns, this.state.incompatibility)
      if (value === false) {
        this.hasCompatibilitiesDisable(campaignId, value)
      }
    } else if (value === true) {
      this.hasOptionals(campaignId, value, this.state.appliedCampaigns, this.state.incompatibility)
    } else if (value === false) {
      this.hasCompatibilitiesDisable(campaignId, value)
    }
  }

  searchCampaignByCodeClick (campaignCode) {
    this.props.actions.searchCampaignByCode(campaignCode, this.props.dossierId)
  }

  submitCampaign (values) {
    this.props.actions.saveconfigureCampaign(this.setToformatCampaignsToApplied(values), this.props.actions.closeModal, this.props.dossierId)
    this.setState({ appliedCampaigns: {}, codeSearch: null, incompatibility: {} })
    this.props.actions.closeModal()
  }

  handleCampaignSeacrhChange (values) {
    this.setState({ codeSearch: values })
  }

  render () {
    const { t, showModal, handleSubmit, actions: { closeModal }, campaignSubtype } = this.props
    const tKey = 'DOSSIER_COMPONENTS.CAMPAIGNS.CAMPAIGNS_MODAL_CONFIGURE.'
    return (
      <Modal show={showModal} onHide={() => closeModal(this.setState({ appliedCampaigns: {}, codeSearch: null, incompatibility: {} }))}>
        <Form autoComplete="off" onSubmit={handleSubmit(this.submitCampaign.bind(this))}>
          <Modal.Header closeButton>
            <Modal.Title>{t(`${tKey}TITLE`)}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Tabs id="1" defaultActiveKey={1}>
              <Tab eventKey={1} title={t(`${tKey}AVAILABLE_CAMPAIGN`)} className="user-tab">
                <Row>
                  {campaignSubtype.map((ca, i) =>
                    <Field
                      key={i}
                      colSm={6}
                      id = {'cam' + ca.campaignId}
                      name= {'cam' + ca.campaignId}
                      placeholder={ca.description}
                      controlLabel={ca.description}
                      component={InputCheckBox}
                      inline
                      onInputChange={(value) => this.handleCampaignTypeChange(value, ca.campaignId)}
                      disabled = {this.state.incompatibility['cam' + ca.campaignId]}
                    />
                  )}
                  <p>&nbsp;</p>
                  {<br/>}
                  <Field
                    colSm={7}
                    id="codeSearch"
                    name="codeSearch"
                    controlLabel={t(`${tKey}CAMPAIGN_BY_CODE`)}
                    placeholder={t(`${tKey}CAMPAIGN_BY_CODE`)}
                    component={InputText}
                    onInputChange={(value) => this.handleCampaignSeacrhChange(value)}
                  />
                  <Col sm={12} className="btn-wrapper">
                    <Button id="btn_add" className="btn-standard" onClick={() => this.searchCampaignByCodeClick(this.state.codeSearch)}>{t(`${tKey}ADD_BTN`)}</Button>
                  </Col>
                </Row>
              </Tab>
            </Tabs>
          </Modal.Body>
          <Modal.Footer>
            <Col sm={6} className="btn-wrapper">
              <Button id="btn_save" className="btn-standard" type="submit">{t(`${tKey}OK_BTN`)}</Button>
            </Col>
          </Modal.Footer>
        </Form>
      </Modal>
    )
  }
}

export default reduxForm({
  form: 'campaign_configure_modal',
  validate: (values, props) => {
    const error = {}
    return error
  }
})(CampaignsConfigureModal)
