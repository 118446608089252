export default {
  FETCH_DEVICE_INSTALLS: 'FETCH_DEVICE_INSTALLS',
  FETCH_MAX_DEVICE_INSTALLS: 'FETCH_MAX_DEVICE_INSTALLS',
  UPDATE_DEVICE_INSTALL: 'UPDATE_DEVICE_INSTALL',
  UPDATE_MAX_DEVICE_INSTALLS: 'UPDATE_MAX_DEVICE_INSTALLS',
  FETCH_DEVICE_INSTALLS_SUCCESS: 'FETCH_DEVICE_INSTALLS_SUCCESS',
  FETCH_MAX_DEVICE_INSTALLS_SUCCESS: 'FETCH_MAX_DEVICE_INSTALLS_SUCCESS',
  UPDATE_DEVICE_INSTALL_SUCCESS: 'UPDATE_DEVICE_INSTALL_SUCCESS',
  UPDATE_MAX_DEVICE_INSTALLS_SUCCESS: 'UPDATE_MAX_DEVICE_INSTALLS_SUCCESS'
}
