import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import DocumentDataCDAModal from '../../../components/dossiers/common/DocumentDataCDAModal'
import { translate } from 'react-polyglot'
import { closeCDAModal } from '../../../actions/dossier/common/documentDataCDAModal'
import { fetchDocumentDataOptions, fetchVehicleType } from '../../../actions/combos/combos'
import { createSignDocumentFromTemplate, printDocumentDynamicFromTemplate } from '../../../actions/dossier/common/documentEditionModal'

export function mapStateToProps (state) {
  return {
    ...state.documentDataCDAModal,
    documentDataOptionsCombo: state.combos.documentDataOptionsCombo,
    vehicletypeCombo: state.combos.vehicletypeCombo
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      closeCDAModal,
      fetchDocumentDataOptions,
      fetchVehicleType,
      createSignDocumentFromTemplate,
      printDocumentDynamicFromTemplate
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(DocumentDataCDAModal))
