import React, { PureComponent } from 'react'
import { Button, Modal, Col, Row, FormGroup, Glyphicon, Tooltip, OverlayTrigger } from 'react-bootstrap'
import { reduxForm, Field } from 'redux-form'
import TextInput from '../../commons/TextInput'
import InputSelect from '../../commons/form/InputSelect'
import InputTreeSelect from '../../commons/form/InputTreeSelect'
import InputText from '../../commons/form/InputText'
import SimpleInputText from '../../commons/simpleForm/SimpleInputText'

class FaqMasterModal extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      text: '',
      totalText: '',
      visibleCustom: false,
      lan: 0,
      idx: 0
    }
  }

  submitFaq (values) {
    let faqSectionId = null
    let rating = null
    const body = []

    Object.keys(values.languageList).map(item => {
      let languageId = values.languageList[item].languageId
      body.push(values[`lang${languageId}`])
    })
    faqSectionId = values.faqSectionId
    rating = values.rating
    this.props.actions.saveFaqMasterModal(faqSectionId, body, this.props.faqId, rating)
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.showFaqModal !== this.props.showFaqModal && this.props.showFaqModal === true) {
      this.props.initialize({ ...this.props })
    }

    if (this.props.faqTranslations !== prevProps.faqTranslations) {
      this.props.languageList.map((language) => {
        this.props.change(`lang${language.languageId}.languageId`, language.languageId)
        const nextFaqTranslation = this.props.faqTranslations.find((sourceChannel) => sourceChannel.languageId === language.languageId)
        if (nextFaqTranslation && nextFaqTranslation !== undefined) {
          this.props.change(`lang${language.languageId}.name`, nextFaqTranslation.name)
          this.props.change(`lang${language.languageId}.description`, nextFaqTranslation.description)
        }
      })
    }

    if (this.props.rating !== prevProps.rating) {
      this.props.change('rating', this.props.rating ? this.props.rating.toString() : '')
    }
    if (this.props.organizedUnitId !== prevProps.organizedUnitId) {
      this.props.change('organizedUnitId', this.props.organizedUnitId ? this.props.organizedUnitId.toString() : '')
    }
    if (this.props.faqSectionId !== prevProps.faqSectionId) {
      this.props.change('faqSectionId', this.props.faqSectionId ? this.props.faqSectionId : '')
    }
  }

  handleOrganizedUnitChange (filterUO) {
    let uo = filterUO === undefined ? null : filterUO
    this.props.actions.fecthFaqSectionComboModal(uo)
  }

  format (idx, lan) {
    this.setState({ lan: lan })
    this.setState({ idx: idx })
    if (this.props.faqTranslations[idx]?.description !== undefined) {
      this.setState({ totalText: this.props.faqTranslations[idx]?.description })
    }
    this.setState({ visibleCustom: true })
  }

  addFormat (beginning, end) {
    this.setState({ text: `${beginning}${this.state.text}${end}` })
  }

  addText () {
    this.setState({ totalText: `${this.state.totalText}${this.state.text}` })
    this.setState({ text: '' })
  }

  saveFormat () {
    if (this.state.totalText) {
      this.props.faqTranslations[this.state.idx] = { description: this.setState.totalText }
      this.props.change(`lang${this.state.lan}.description`, this.state.totalText)
      this.setState({ visibleCustom: false })
      this.setState({ totalText: '' })
    }
  }

  render () {
    const { t, showFaqModal, handleSubmit, languageList = [], combos: { faqSectionCombo, UOTreeCombo }, actions: { closeFaqModal } } = this.props
    const renderToolTip = (<Tooltip id="infoTooltip">{t('MASTERS.FAQ.TOOLTIP_OU')}</Tooltip>)
    return (
      <Modal show={showFaqModal} onHide={closeFaqModal} className="languages-modal" backdrop={'static'}>
        <Modal.Header closeButton>
          <i className="ico-users" /><Modal.Title>{t('MASTERS.FAQ.TITLE')}</Modal.Title>
        </Modal.Header>
        <form autoComplete="off" onSubmit={handleSubmit(this.submitFaq.bind(this))}>
          <Modal.Body>
            <FormGroup>
              <Row >
                <Col sm={6} className="custom-width-col">
                  <h2>{t('MASTERS.FAQ.ORGANIZED_UNIT') }
                    <OverlayTrigger placement="left" overlay={renderToolTip} >
                      <Glyphicon glyph="info-sign" className="tooltip-function"></Glyphicon>
                    </OverlayTrigger>
                  </h2>
                  <Field
                    name="organizedUnitId"
                    placeholder={t('MASTERS.FAQ.ORGANIZED_UNIT')}
                    component={InputTreeSelect}
                    multi={false}
                    allowClear={true}
                    valueKey="value"
                    labelKey="label"
                    childrenKey="children"
                    colSm={0}
                    options={UOTreeCombo}
                    onInputChange={(e) => this.handleOrganizedUnitChange(e)}
                    showSearch={false}
                  />
                </Col>
                <Col sm={6} className="custom-width-col">
                  <h2>{t('MASTERS.FAQ.FAQ_SECTION')}</h2>
                  <Field
                    name="faqSectionId"
                    placeholder={t('MASTERS.FAQ.FAQ_SECTION')}
                    component={InputSelect}
                    valueKey="id"
                    labelKey="value"
                    options={faqSectionCombo}
                    colSm={0}
                    isClerable= {false}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={2} className="custom-width-col">
                  <h2>{t('MASTERS.FAQ.RATING')}</h2>
                  <Field
                    name="rating"
                    placeholder={t('MASTERS.FAQ.RATING')}
                    component={InputText}
                  />
                </Col>
              </Row>
            </FormGroup>
            { this.state.visibleCustom ? (
              <div>
                <h2>{t('MASTERS.FORMAT')}</h2>
                <div className='container-format'>
                  <Button className="btn-standard-white-margin" onClick={() => this.addFormat('<strong>', '</strong>')}>{t('MASTERS.BOLD')}</Button>
                  <Button className="btn-standard-white-margin" onClick={() => this.addFormat('<em>', '</em>')}><em>{t('MASTERS.ITALICS')}</em></Button>
                  <Button className="btn-standard-white-margin" onClick={() => this.addFormat('<mark>', '</mark>')}><mark>{t('MASTERS.MARKED')}</mark></Button>
                  <Button className="btn-standard-white-margin" onClick={() => this.addFormat('', '<br>')}>{t('MASTERS.LINEBREAK')}</Button>
                  <Button className="btn-standard-white-margin" onClick={() => this.addFormat('<span style="margin-left:15px;">', '</span>')}>{t('MASTERS.TABULATED')}</Button>
                  <Button className="btn-standard-white-margin" onClick={() => this.addFormat('<span style="color:red;">', '</span>')}>{t('MASTERS.RED')}</Button>
                  <Button className="btn-standard-white-margin" onClick={() => this.addFormat(`<a href="${this.state.text}">`, '</a>')}>{t('MASTERS.LINK')}</Button>
                </div>
                <div className='container-format'>
                  <SimpleInputText
                    value={this.state.text}
                    id="text"
                    editTooltip={true}
                    onInputChange={text => this.setState({ text })}
                  />
                  <Button className="btn-standard btn-plus" onClick={() => this.addText()}>
                    <i className="ico-plus" />
                  </Button>
                </div>
                <br/>
                <div dangerouslySetInnerHTML={{ __html: this.state.totalText }} className="text-html"/>
                <div className='container-btn-format'>
                  <Button className="btn-standard btn btn-default btn-rigth-margin" onClick={() => this.saveFormat()}>
                    {t('MASTERS.SAVE')}
                  </Button>
                  <Button className="btn-danger" onClick={() => this.setState({ totalText: '' })}>
                    {t('MASTERS.DELETE')}
                  </Button>
                </div>
              </div>
            ) : (
              <FormGroup>
                <Row className="title-column">
                  <Col sm={4}>
                    <h2>{t('MASTERS.FAQ.FAQ_QUESTION')}</h2>
                  </Col>
                  <Col sm={4}>
                    <h2>{t('MASTERS.FAQ.FAQ_ANSWER')}</h2>
                  </Col>
                  <Col sm={1}>
                  </Col>
                  <Col sm={3}>
                    <h2>{t('MASTERS.LANGUAGE')}</h2>
                  </Col>
                </Row>
                {languageList.map((val, idx) => {
                  return (
                    <Row key={idx} className="inputs-wrapper">
                      <Col sm={4} className="custom-width-col">
                        <Field
                          name={`lang${val.languageId}.name`}
                          placeholder={t('MASTERS.FAQ.FAQ_QUESTION')}
                          component={TextInput}
                        />
                      </Col>
                      <Col sm={4} className="custom-width-col text-ellipsis">
                        <Field
                          name={`lang${val.languageId}.description`}
                          placeholder={t('MASTERS.FAQ.FAQ_ANSWER')}
                          component={TextInput}
                          disabled={true}
                        />
                      </Col>
                      <Col sm={1} className="language-column">
                        <i className="ico-edit-turquoise ico-format" onClick={() => { this.format(idx, val.languageId) }}></i>
                      </Col>
                      <Col sm={3} className="language-column">
                        <p>{`${val.description} (${val.name})`}</p>
                      </Col>
                    </Row>
                  )
                })}
              </FormGroup>
            )}
          </Modal.Body>
          <Modal.Footer>
            { !this.state.visibleCustom ? (
              <Col sm={6} className="btn-wrapper" smPush={6}>
                <Button type="submit" className="btn-standard" disabled={this.props.pristine}>
                  {t('MASTERS.SAVE')}
                </Button>
              </Col>
            ) : (
              <div className='container-btn-format'>
                <Button className="btn-standard-white" onClick={() => this.setState({ visibleCustom: false, totalText: '' }) }>
                  {t('MASTERS.RETURN')}
                </Button>
              </div>
            ) }
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

const validate = (values, props) => {
  const errors = {}
  if (!values.faqSectionId) {
    errors.faqSectionId = props.t('MASTERS.FAQ.VALIDATION.SECTION_REQUIRED')
  }
  props.languageList.map(language => {
    errors[`lang${language.languageId}`] = {}
    if (values[`lang${language.languageId}`] !== undefined) {
      if (!(values[`lang${language.languageId}`].name)) {
        errors[`lang${language.languageId}`].name = props.t('MASTERS.FAQ.VALIDATION.QUESTION_REQUIRED')
      }
      if (!(values[`lang${language.languageId}`].description)) {
        errors[`lang${language.languageId}`].description = props.t('MASTERS.FAQ.VALIDATION.ANSWER_REQUIRED')
      }
    }
  })
  return errors
}

export default reduxForm({
  form: 'editFaq',
  validate
})(FaqMasterModal)
