
import { bindActionCreators } from 'redux'
import ProcessingAgencyIntegrationDocTypeUsesMaster from '../../../../components/masters/processing/processingAgencyIntegrations/ProcessingAgencyIntegrationDocTypeUsesMaster'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { fetchProcessingAgencyDocTypeUsesList, showProcessingAgencyDocTypeUsesModal, deleteProcessingAgencyDocTypeUses } from '../../../../actions/masters/processingAgency/processingAgencyIntegrations/processingAgencyDocTypeUses'

export function mapStateToProps (state) {
  const docTypeUses = state.processingAgency.processingAgencyIntegrationModal.docTypeUses
  return {
    ...docTypeUses,
    agency: state.processingAgency.processingAgencyIntegrationModal.agency
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchProcessingAgencyDocTypeUsesList,
      showProcessingAgencyDocTypeUsesModal,
      deleteProcessingAgencyDocTypeUses
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(ProcessingAgencyIntegrationDocTypeUsesMaster))
