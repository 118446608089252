import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { getDossierDetailsFleet, getDossierDetailsFleetSuccess } from '../../../actions/management/management'
import { openModal } from '../../../actions/common'

import DossiersListFleetDetailsComponent from '../../../components/management/commons/DossiersListFleetDetailsComponent'

export function mapStateToProps (state) {
  return {
    dossierFleetDetails: state.management.dossierFleetDetails
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      openModal,
      getDossierDetailsFleet,
      getDossierDetailsFleetSuccess
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(DossiersListFleetDetailsComponent))
