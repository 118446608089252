import { connect } from 'react-redux'
import Loader from '../../components/commons/Loader'

export function mapStateToProps (state) {
  return {
    loading: state.common.loading
  }
}

export default connect(mapStateToProps, null)(Loader)
