import ratingMasterActionTypes from '../../../constants/actions/masters/rating/ratingMaster'

export function fetchRatingList (filter) {
  return {
    type: ratingMasterActionTypes.FETCH_RATING_LIST,
    filter
  }
}

export function fetchRatingListSuccess (ratingList, filter) {
  return {
    type: ratingMasterActionTypes.FETCH_RATING_LIST_SUCCESS,
    ratingList,
    filter
  }
}

export function deleteRating (ratingId) {
  return {
    type: ratingMasterActionTypes.DELETE_RATING,
    ratingId
  }
}

export function openRatingModal (rating) {
  return {
    type: ratingMasterActionTypes.OPEN_RATING_MODAL,
    rating
  }
}

export function openRatingModalSuccess (rating) {
  return {
    type: ratingMasterActionTypes.OPEN_ADD_RATING_MODAL_SUCCESS,
    rating
  }
}

export function openEditRatingModalSuccess (rating) {
  return {
    type: ratingMasterActionTypes.OPEN_EDIT_RATING_MODAL_SUCCESS,
    rating
  }
}

export function closeRatingMasterModal () {
  return {
    type: ratingMasterActionTypes.CLOSE_EDIT_RATING_MODAL
  }
}

export function submitRating (rating) {
  return {
    type: ratingMasterActionTypes.SUBMIT_RATING,
    rating
  }
}
