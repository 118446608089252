import { put, call, takeEvery, select } from 'redux-saga/effects'
import { reset, updateSyncErrors } from 'redux-form'
import { getAuth } from '../../../../selectors/access/auth'
import { yesNoModal } from '../../../modalsListeners/yesNoModal'
import documentManagementModalActionsTypes from '../../../../constants/actions/dossier/common/documentManagementModal'
import { documentEntityType as documentEntityTypeConstant } from '../../../../constants/dossier/common/documentEntityType'
import { dossierTypes as dossierTypesConstant } from '../../../../constants/dossier/common/dossierTypes'
import messageModalActions from '../../../../actions/modals/messageModal'
import { showLoader, hideLoader, openModal } from '../../../../actions/common'
import {
  openDocumentManagementModalSuccess,
  vehicleDocumentChanged,
  entityDocumentChanged,
  dossierDocumentChanged,
  refreshDocumentManagementModalSuccess,
  closeDocumentModal
} from '../../../../actions/dossier/common/documentManagementModal'
import getDocumentsByDossierId from '../../../../services/document/getDocumentsByDossierId'
import getHistoricalDocumentsByDossierId from '../../../../services/document/getHistoricalDocumentsByDossierId'
import cancelDocumentService from '../../../../services/document/cancelDocument'
import cancelDocumentFleetsService from '../../../../services/document/cancelDocumentFleets'
import postDocumentAddDependandService from '../../../../services/document/postDocumentAddDependand'
import checkListGenerateService from '../../../../services/checklist/checkListGenerate'
import checkListGeneratePostService from '../../../../services/checklist/checkListGeneratePost'
import documentSign from '../../../../services/document/documentSign'
import { promptModal } from '../../../modalsListeners/promptModal'
import { refreshCommonSaleFromDocumental, refreshSalesDossierFromDocumentModal, refreshSalesDossierVehicleFromDocumentModal, refreshSalesDossierEntityFromDocumentModal, refreshSalesDossierFromDocumentModalLight } from '../../fetchSalesDossier'
import { refreshCommonPpkFromDocumental, refreshPaperworkDossierEntityFromDocumentModal, refreshPaperworkDossierVehicleFromDocumentModal, refreshPaperworkDossierFromDocumentModal } from '../../paperwork/fetchPaperworkDossier'
import { refreshCommonPurchaseFromDocumental, refreshPurchaseDossierEntityFromDocumentModal, refreshPurchaseDossierVehicleFromDocumentModal, refreshPurchaseDossierFromDocumentModal } from '../../purchase/fetchPurchaseDossier'
import { refreshCommonCampaignFromDocumental, refreshCampaignDossierEntityFromDocumentModal, refreshCampaignDossierVehicleFromDocumentModal, refreshCampaignDossierFromDocumentModal } from '../../campaign/fetchCampaignDossier'
import { handleError } from '../../../errors/errorManager'
import { getDossierStateByType, getDossierStateById, initializeDossier, refreshAuditInfo } from '../../commonDossierSagas'
import { refreshCommonRegisDelivFromDocumental, refreshRegistrationDeliveryDossierFromDocumentModal, refreshRegistrationDeliveryDossierEntityFromDocumentModal, refreshRegistrationDeliveryDossierVehicleFromDocumentModal } from '../../registrationDelivery/fetchRegistrationDeliveryDossier'
import { refreshServiceDossierFromDocumentModal } from '../../service/fetchServiceDossier'
import { refreshStockDossierFromDocumentModal } from '../../stock/fetchStockDossier'
import { documentTypeUse, dossierSubTypes } from '../../../../constants/backendIds'
import { referenceTypes } from '../../../../constants/document/documentTypeUse'
import { refreshPurchasePresentFromDocumentModal } from '../../../../actions/dossier/purchase'
import { updateDocumentModalLastAccessDate } from '../../../../actions/dossier/common/documentEditionModal'
import getPrintTemplateByReferenceType from '../../../../services/document/getPrintTemplateByReferenceType'
import getPrintTemplateByReferenceTypePost from '../../../../services/document/getPrintTemplateByReferenceTypePost'

export function * changeDocumentManagementChangedPilots (documentEntityType) {
  const isManagementModalOpen = yield select(state => state.documentaryManagementModal.showModal)
  if (isManagementModalOpen) {
    switch (documentEntityType) {
      case documentEntityTypeConstant.VEHICLE:
        yield put(vehicleDocumentChanged())
        break
      case documentEntityTypeConstant.ENTITY:
        yield put(entityDocumentChanged())
        break
      case documentEntityTypeConstant.DOSSIER:
        yield put(dossierDocumentChanged())
        break
    }
  }
}

export function * openDocumentManagementModal ({ dossierId, dossierType, dossierWithoutChanges, defaultTab, historical }) {
  try {
    if (dossierWithoutChanges) {
      yield put(showLoader())
      const auth = yield select(getAuth)
      let data = {}
      if (historical) {
        data = yield call(getHistoricalDocumentsByDossierId, dossierId, auth.token)
      } else {
        data = yield call(getDocumentsByDossierId, dossierId, auth.token)
      }
      const sectionsDocuments = groupDocumentsBySection(data)
      yield put(openDocumentManagementModalSuccess(sectionsDocuments, dossierId, dossierType, defaultTab, data))
    } else {
      yield call(promptModal, 'dossierWithChanges')
    }
  } catch (error) {
    const sessionHandler = historical ? null : function * () {
      yield put(reset('salesDossier'))
      yield put(reset('purchase_dossier'))
      yield put(reset('paperwork_dossier'))
      yield put(reset('campaign_dossier'))
      yield put(reset('service_dossier'))
      yield put(reset('stock_dossier'))
    }
    yield call(handleError, { error, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchOpenDocumentManagementModal () {
  yield takeEvery(documentManagementModalActionsTypes.OPEN_DOCUMENT_MANAGEMENT_MODAL, openDocumentManagementModal)
}

export function * refreshDocumentManagementModal ({ dossierId, historical }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    let data = {}
    if (historical && dossierId) {
      data = yield call(getHistoricalDocumentsByDossierId, dossierId, auth.token)
    } else {
      data = dossierId ? yield call(getDocumentsByDossierId, dossierId, auth.token) : {}
    }
    if (data !== {}) {
      const sectionsDocuments = groupDocumentsBySection(data)
      yield put(refreshDocumentManagementModalSuccess(sectionsDocuments, dossierId, data))
    }
  } catch (error) {
    const sessionHandler = historical ? null : function * () {
      yield put(reset('salesDossier'))
      yield put(reset('purchase_dossier'))
      yield put(reset('paperwork_dossier'))
      yield put(reset('campaign_dossier'))
      yield put(reset('service_dossier'))
      yield put(reset('stock_dossier'))
    }
    yield call(handleError, { error, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchRefreshDocumentManagementModal () {
  yield takeEvery(documentManagementModalActionsTypes.REFRESH_DOCUMENT_MANAGEMENT_MODAL, refreshDocumentManagementModal)
}

function groupDocumentsBySection (tabs) {
  const sectionedTabs = {}
  Object.keys(tabs).filter(item => Array.isArray(tabs[item])).map(tab => {
    const sections = []

    tabs[tab].forEach((documentFile) => {
      const foundSection = sections.find(sec => sec.name === documentFile.section)
      if (foundSection) {
        foundSection.documents.push(documentFile)
      } else {
        sections.push({ name: documentFile.section, documents: [documentFile] })
      }
    })
    sectionedTabs[tab] = sections
  })
  return sectionedTabs
}

export function * cancelDocument ({ dossierType, documentId, documentEntityType, dossierId, resolve, reject, isRecurrent, fleetsVisor }) {
  const documentEditionModal = yield select(state => state.documentEditionModal)
  const referenceType = documentEditionModal?.referenceType
  const referenceId = documentEditionModal?.referenceId
  let hasDossierState = false
  let dossierState
  try {
    if (documentEditionModal.dossierType) {
      hasDossierState = true
      dossierState = yield call(getDossierStateByType, documentEditionModal.dossierType)
    } else {
      dossierState = documentEditionModal
    }
    const confirmed = yield call(yesNoModal, 'cancelDocument')
    if (confirmed) {
      yield put(showLoader())
      const auth = yield select(getAuth)
      let date
      const today = new Date()
      if (!dossierState?.lastAccessDate && !dossierId) {
        date = today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear()
        date += ' ' + today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds() + ' ' + today.getMilliseconds()
      } else {
        date = dossierState?.lastAccessDate ?? today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear() + ' ' + today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds() + ' ' + today.getMilliseconds()
      }
      if (!fleetsVisor) {
        yield call(cancelDocumentService, documentId, auth.token, date)
      } else {
        yield call(cancelDocumentFleetsService, documentId, auth.token, date)
      }
      if (date) {
        yield put(updateDocumentModalLastAccessDate(date))
      }
      if (documentEditionModal.callback) yield call(documentEditionModal.callback, documentEditionModal.entityId)
      if (documentEditionModal.refreshAfterChange) {
        yield call(refreshDossierByType, {
          dossierType: dossierType,
          dossierDocumentChanged: true, // documentEntityType === documentEntityTypeConstant.DOSSIER,
          entityDocumentChanged: documentEntityType === documentEntityTypeConstant.ENTITY,
          vehicleDocumentChanged: documentEntityType === documentEntityTypeConstant.VEHICLE,
          documentEditionalModalState: documentEditionModal
        })
        if (!isRecurrent && !documentEditionModal.callback) yield call(refreshDocumentManagementModal, { dossierId })
      }

      if (!!referenceId && documentEditionModal.refreshPurchasePresents &&
      (referenceType === referenceTypes.INVOICE_DOCUMENT_ID || referenceType === referenceTypes.ORDER_DOCUMENT_ID)) {
        yield put(refreshPurchasePresentFromDocumentModal(referenceId, !documentEditionModal.dirty))
      }
    }
    if (dossierType === dossierSubTypes.stock) {
      yield call(initializeDossier, dossierTypesConstant.stock, yield select(state => state.stockDossier))
    }
    if (resolve) resolve()
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          if (hasDossierState) yield call(initializeDossier, dossierType, dossierState)
        }
      } else {
        if (customHandlerError.json.tag === 'signStatusPending' || customHandlerError.json.tag === 'TRANS/signStatusPending') {
          yield put(openModal('signStatusPending'))
        } else {
          yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
        }
      }
      if (reject) reject()
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('purchase_dossier'))
      yield put(reset('paperwork_dossier'))
      yield put(reset('campaign_dossier'))
      yield put(reset('service_dossier'))
      yield put(reset('stock_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchCancelDocument () {
  yield takeEvery(documentManagementModalActionsTypes.CANCEL_DOCUMENT, cancelDocument)
}

export function * addDependant ({ dossierType, documentId, documentEntityType, documentTypeUseId }) {
  const dossierId = yield select(state => state.documentaryManagementModal.dossierId)
  const dossierState = yield call(getDossierStateById, dossierId)
  try {
    if (documentTypeUseId != null && documentTypeUseId === documentTypeUse.charge) {
      const confirmed = yield call(yesNoModal, 'addReceiptWarning')
      if (!confirmed) return
    }

    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(postDocumentAddDependandService, documentId, dossierId, auth.token, dossierState.dossier.lastAccessDate)
    yield call(changeDocumentManagementChangedPilots, documentEntityType)
    yield call(refreshDocumentManagementModal, { dossierId })
    yield call(refreshDossierByType, { dossierType, dossierDocumentChanged: true, entityDocumentChanged: false, vehicleDocumentChangedtrue: false, documentEditionalModalState: null })

    if (documentTypeUseId != null && documentTypeUseId === documentTypeUse.charge) {
      yield call(yesNoModal, 'addReceiptConfirmed')
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          yield call(initializeDossier, dossierState.type, dossierState.dossier)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('purchase_dossier'))
      yield put(reset('paperwork_dossier'))
      yield put(reset('campaign_dossier'))
      yield put(reset('service_dossier'))
      yield put(reset('stock_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchAddDependant () {
  yield takeEvery(documentManagementModalActionsTypes.ADD_DEPENDANT, addDependant)
}

export function * printTemplateByReferenceType ({ formValues }) {
  const documentEditionModal = yield select(state => state.documentEditionModal)
  const auth = yield select(getAuth)
  const referenceType = documentEditionModal.referenceType
  const referenceId = documentEditionModal.referenceId
  const dossierId = documentEditionModal.dossierId
  const dossierType = documentEditionModal.dossierType
  const dossierState = yield call(getDossierStateByType, dossierType)
  const documentTypeUseId = documentEditionModal.documentTypeUseId
  try {
    if (formValues) yield call(getPrintTemplateByReferenceTypePost, auth.token, dossierId, documentTypeUseId, formValues)
    else yield call(getPrintTemplateByReferenceType, auth.token, dossierId, referenceType, referenceId)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          yield call(initializeDossier, dossierType, dossierState)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('purchase_dossier'))
      yield put(reset('paperwork_dossier'))
      yield put(reset('campaign_dossier'))
      yield put(reset('service_dossier'))
      yield put(reset('stock_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  }
}

export function * watchPrintTemplateByReferenceType () {
  yield takeEvery(documentManagementModalActionsTypes.PRINT_BY_REFERENCE_TYPE, printTemplateByReferenceType)
}

export function * printCheckList ({ checkListId, formValues = null }) {
  const documentaryManagementModal = yield select(state => state.documentaryManagementModal)
  const dossierState = yield call(getDossierStateByType, documentaryManagementModal.dossierType)
  try {
    const auth = yield select(getAuth)
    if (formValues) yield call(checkListGeneratePostService, checkListId, auth.token, formValues)
    else yield call(checkListGenerateService, checkListId, auth.token)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          yield call(initializeDossier, documentaryManagementModal.dossierType, dossierState)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('purchase_dossier'))
      yield put(reset('paperwork_dossier'))
      yield put(reset('campaign_dossier'))
      yield put(reset('service_dossier'))
      yield put(reset('stock_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  }
}

export function * watchPrintCheckList () {
  yield takeEvery(documentManagementModalActionsTypes.PRINT_CHECK_LIST, printCheckList)
}

export function * closeDocumentModalCallbacks ({ checkListId }) {
  try {
    const documentaryManagementModal = yield select(state => state.documentaryManagementModal)
    const dossierType = documentaryManagementModal.dossierType
    const dossierDocumentChanged = documentaryManagementModal.dossierDocumentChanged
    const entityDocumentChanged = documentaryManagementModal.entityDocumentChanged
    const vehicleDocumentChanged = documentaryManagementModal.vehicleDocumentChanged
    yield put(closeDocumentModal())
    yield call(refreshDossierByType, { dossierType, dossierDocumentChanged, entityDocumentChanged, vehicleDocumentChanged, documentEditionalModalState: null })
  } catch (error) {
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('purchase_dossier'))
      yield put(reset('paperwork_dossier'))
      yield put(reset('campaign_dossier'))
      yield put(reset('service_dossier'))
      yield put(reset('stock_dossier'))
    }
    yield call(handleError, { error, sessionHandler })
  }
}

export function * watchCloseDocumentModalCallbacks () {
  yield takeEvery(documentManagementModalActionsTypes.CLOSE_DOCUMENT_MANAGEMENT_MODAL_CALLBACKS, closeDocumentModalCallbacks)
}

export function * signDocument ({ checklistId }) {
  try {
    yield put(showLoader())
    const entity = yield select(state => state.salesDossier.entityComponent.name)
    if (entity) {
      const auth = yield select(getAuth)
      yield call(documentSign, checklistId, auth.token)
      yield put(messageModalActions.openSuccessMessageModal('MESSAGES.DOCUMENT_SIGN_SUCCESS'))
    } else {
      yield put(openModal('MustFillEntity'))
    }
  } catch (error) {
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSignDocument () {
  yield takeEvery(documentManagementModalActionsTypes.SIGN_DOCUMENT, signDocument)
}

export function * openModalVehicleNecessary () {
  yield call(yesNoModal, 'vehicleNecessary')
}

export function * watchOpenModalVehicleNecessary () {
  yield takeEvery(documentManagementModalActionsTypes.OPEN_MODAL_VEHICLE_NECESSARY, openModalVehicleNecessary)
}

export function * refreshDossierByType ({ dossierType, dossierDocumentChanged, entityDocumentChanged, vehicleDocumentChanged, documentEditionalModalState }) {
  if (dossierType === dossierTypesConstant.sales) {
    yield call(salesComponentManagementCallback, dossierDocumentChanged, entityDocumentChanged, vehicleDocumentChanged, documentEditionalModalState)
  } else if (dossierType === dossierTypesConstant.paperwork) {
    yield call(paperworkComponentManagementCallback, dossierDocumentChanged, entityDocumentChanged, vehicleDocumentChanged, documentEditionalModalState)
  } else if (dossierType === dossierTypesConstant.purchase) {
    yield call(purchaseComponentManagementCallback, dossierDocumentChanged, entityDocumentChanged, vehicleDocumentChanged, documentEditionalModalState)
  } else if (dossierType === dossierTypesConstant.campaign) {
    yield call(campaignComponentManagementCallback, dossierDocumentChanged, entityDocumentChanged, vehicleDocumentChanged, documentEditionalModalState)
  } else if (dossierType === dossierTypesConstant.registrationDelivery) {
    yield call(registrationDeliveryComponentManagementCallback, dossierDocumentChanged, entityDocumentChanged, vehicleDocumentChanged, documentEditionalModalState)
  } else if (dossierType === dossierTypesConstant.service) {
    yield call(serviceDossierComponentManagementCallback, dossierDocumentChanged, documentEditionalModalState)
  } else if (dossierType === dossierTypesConstant.stock) {
    yield call(stockDossierComponentManagementCallback, dossierDocumentChanged, documentEditionalModalState)
  }
}

export function * refreshDossierByTypeLight ({ dossierType, dossierDocumentChanged, entityDocumentChanged, vehicleDocumentChanged, date, documentEditionalModalState }) {
  if (dossierType === dossierTypesConstant.sales) {
    yield call(salesComponentManagementCallbackLight, dossierDocumentChanged, entityDocumentChanged, vehicleDocumentChanged, documentEditionalModalState)
  } else if (dossierType === dossierTypesConstant.paperwork) {
    yield call(paperworkComponentManagementCallback, dossierDocumentChanged, entityDocumentChanged, vehicleDocumentChanged, documentEditionalModalState)
  } else if (dossierType === dossierTypesConstant.purchase) {
    yield call(purchaseComponentManagementCallback, dossierDocumentChanged, entityDocumentChanged, vehicleDocumentChanged, documentEditionalModalState)
  } else if (dossierType === dossierTypesConstant.campaign) {
    yield call(campaignComponentManagementCallback, dossierDocumentChanged, entityDocumentChanged, vehicleDocumentChanged, documentEditionalModalState)
  } else if (dossierType === dossierTypesConstant.registrationDelivery) {
    yield call(registrationDeliveryComponentManagementCallback, dossierDocumentChanged, entityDocumentChanged, vehicleDocumentChanged, documentEditionalModalState)
  } else if (dossierType === dossierTypesConstant.service) {
    yield call(serviceDossierComponentManagementCallback, dossierDocumentChanged, documentEditionalModalState)
  } else if (dossierType === dossierTypesConstant.stock) {
    yield call(stockDossierComponentManagementCallback, dossierDocumentChanged, documentEditionalModalState)
  }
}

export function * salesComponentManagementCallback (dossierDocumentChanged, entityDocumentChanged, vehicleDocumentChanged, documentEditionalModalState) {
  try {
    if (dossierDocumentChanged) {
      yield call(refreshSalesDossierFromDocumentModal, documentEditionalModalState)
    } else {
      yield call(refreshCommonSaleFromDocumental)
    }
    if (entityDocumentChanged) {
      yield call(refreshSalesDossierEntityFromDocumentModal, documentEditionalModalState)
    }
    if (vehicleDocumentChanged) {
      yield call(refreshSalesDossierVehicleFromDocumentModal, documentEditionalModalState)
    }
    const salesDossier = yield select(state => state.salesDossier)
    yield call(refreshAuditInfo, { dossierId: salesDossier.dossierId })
  } catch (error) {
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, sessionHandler })
  }
}

export function * salesComponentManagementCallbackLight (dossierDocumentChanged, entityDocumentChanged, vehicleDocumentChanged, documentEditionalModalState) {
  try {
    if (dossierDocumentChanged) {
      yield call(refreshSalesDossierFromDocumentModalLight)
    }
    if (entityDocumentChanged) {
      yield call(refreshSalesDossierEntityFromDocumentModal, documentEditionalModalState)
    }
    if (vehicleDocumentChanged) {
      yield call(refreshSalesDossierVehicleFromDocumentModal, documentEditionalModalState)
    }
    const salesDossier = yield select(state => state.salesDossier)
    yield call(refreshAuditInfo, { dossierId: salesDossier.dossierId })
  } catch (error) {
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, sessionHandler })
  }
}

export function * paperworkComponentManagementCallback (dossierDocumentChanged, entityDocumentChanged, vehicleDocumentChanged, documentEditionalModalState) {
  try {
    yield call(refreshCommonPpkFromDocumental)
    if (dossierDocumentChanged) {
      yield call(refreshPaperworkDossierFromDocumentModal, documentEditionalModalState)
    }
    if (entityDocumentChanged) {
      yield call(refreshPaperworkDossierEntityFromDocumentModal, documentEditionalModalState)
    }
    if (vehicleDocumentChanged) {
      yield call(refreshPaperworkDossierVehicleFromDocumentModal, documentEditionalModalState)
    }
    const paperworkDossier = yield select(state => state.paperworkDossier)
    yield call(refreshAuditInfo, { dossierId: paperworkDossier.dossierId })
  } catch (error) {
    const sessionHandler = function * () {
      yield put(reset('paperwork_dossier'))
    }
    yield call(handleError, { error, sessionHandler })
  }
}

export function * purchaseComponentManagementCallback (dossierDocumentChanged, entityDocumentChanged, vehicleDocumentChanged, documentEditionalModalState) {
  try {
    yield call(refreshCommonPurchaseFromDocumental)
    if (dossierDocumentChanged) {
      yield call(refreshPurchaseDossierFromDocumentModal, documentEditionalModalState)
    }
    if (entityDocumentChanged) {
      yield call(refreshPurchaseDossierEntityFromDocumentModal, documentEditionalModalState)
    }
    if (vehicleDocumentChanged) {
      yield call(refreshPurchaseDossierVehicleFromDocumentModal, documentEditionalModalState)
    }
    const purchaseDossier = yield select(state => state.purchaseDossier)
    yield call(refreshAuditInfo, { dossierId: purchaseDossier.dossierId })
  } catch (error) {
    const sessionHandler = function * () {
      yield put(reset('purchase_dossier'))
    }
    yield call(handleError, { error, sessionHandler })
  }
}

export function * campaignComponentManagementCallback (dossierDocumentChanged, entityDocumentChanged, vehicleDocumentChanged, documentEditionalModalState) {
  try {
    yield call(refreshCommonCampaignFromDocumental)
    if (dossierDocumentChanged) {
      yield call(refreshCampaignDossierFromDocumentModal, documentEditionalModalState)
    }
    if (entityDocumentChanged) {
      yield call(refreshCampaignDossierEntityFromDocumentModal, documentEditionalModalState)
    }
    if (vehicleDocumentChanged) {
      yield call(refreshCampaignDossierVehicleFromDocumentModal, documentEditionalModalState)
    }
    const campaignDossier = yield select(state => state.campaignDossier)
    yield call(refreshAuditInfo, { dossierId: campaignDossier.dossierId })
  } catch (error) {
    const sessionHandler = function * () {
      yield put(reset('campaign_dossier'))
    }
    yield call(handleError, { error, sessionHandler })
  }
}

export function * registrationDeliveryComponentManagementCallback (dossierDocumentChanged, entityDocumentChanged, vehicleDocumentChanged, documentEditionalModalState) {
  try {
    yield call(refreshCommonRegisDelivFromDocumental)
    if (dossierDocumentChanged) {
      yield call(refreshRegistrationDeliveryDossierFromDocumentModal, documentEditionalModalState)
    }
    if (entityDocumentChanged) {
      yield call(refreshRegistrationDeliveryDossierEntityFromDocumentModal, documentEditionalModalState)
    }
    if (vehicleDocumentChanged) {
      yield call(refreshRegistrationDeliveryDossierVehicleFromDocumentModal, documentEditionalModalState)
    }
    const registrationDeliveryDossier = yield select(state => state.registrationDeliveryDossier)
    yield call(refreshAuditInfo, { dossierId: registrationDeliveryDossier.dossierId })
  } catch (error) {
    const sessionHandler = function * () {
      yield put(reset('registrationDeliveryDossier'))
    }
    yield call(handleError, { error, sessionHandler })
  }
}

export function * serviceDossierComponentManagementCallback (dossierDocumentChanged, documentEditionalModalState) {
  try {
    if (dossierDocumentChanged) {
      yield call(refreshServiceDossierFromDocumentModal, documentEditionalModalState)
    }
    const serviceDossier = yield select(state => state.serviceDossier)
    yield call(refreshAuditInfo, { dossierId: serviceDossier.dossierId })
  } catch (error) {
    const sessionHandler = function * () {
      yield put(reset('service_dossier'))
    }
    yield call(handleError, { error, sessionHandler })
  }
}

export function * stockDossierComponentManagementCallback (dossierDocumentChanged, documentEditionalModalState) {
  try {
    if (dossierDocumentChanged) {
      yield call(refreshStockDossierFromDocumentModal, documentEditionalModalState)
    }
    const serviceDossier = yield select(state => state.serviceDossier)
    yield call(refreshAuditInfo, { dossierId: serviceDossier.dossierId })
  } catch (error) {
    const sessionHandler = function * () {
      yield put(reset('stock_dossier'))
    }
    yield call(handleError, { error, sessionHandler })
  }
}

export function * refreshFormErrors ({ formName, errors }) {
  try {
    if (formName) {
      yield put(updateSyncErrors(formName, errors))
    }
  } catch (error) {
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('purchase_dossier'))
      yield put(reset('paperwork_dossier'))
      yield put(reset('campaign_dossier'))
      yield put(reset('service_dossier'))
      yield put(reset('stock_dossier'))
    }
    yield call(handleError, { error, sessionHandler })
  }
}

export function * watchRefreshFormErrors () {
  yield takeEvery(documentManagementModalActionsTypes.REFRESH_FORM_ERRORS, refreshFormErrors)
}
