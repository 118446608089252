import React, { PureComponent } from 'react'
import { Modal, Row, Col, Button } from 'react-bootstrap'
import InputText from '../../../../commons/form/InputText'
import { reduxForm, Field } from 'redux-form'

class ModalMeeting extends PureComponent {
  render () {
    return (
      <Modal className="modal-document modal-meeting" show={false}>
        <Modal.Header closeButton>
          <Modal.Title>Cita entrega de EXP 20170116E</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="band-user">
            <Col sm={3} className="band-icon-item">
              <i className="ico-user" />
              <div className="text-band">
                <span className="first">Nombre del gestor:</span>
                <span className="second">Nombre del gestor</span>
              </div>
            </Col>
            <Col sm={3} className="band-icon-item">
              <i className="ico-user" />
              <div className="text-band">
                <span className="first">Nombre del cliente:</span>
                <span className="second">Nombre del cliente</span>
              </div>
            </Col>
            <Col sm={3} className="band-icon-item">
              <i className="ico-car" />
              <div className="text-band">
                <span className="first">Marca:</span>
                <span className="second">Marca</span>
              </div>
            </Col>
            <Col sm={3} className="band-icon-item">
              <i className="ico-car" />
              <div className="text-band">
                <span className="first">Modelo:</span>
                <span className="second">Modelo</span>
              </div>
            </Col>
          </Row>
          <Row className="row-height">
            <Field
              colSm={3}
              controlLabel='Ubicación:'
              id='place'
              name='place'
              placeholder='Ubicación'
              component={InputText}
            />
            <Field
              colSm={3}
              controlLabel='Box de entrega:'
              id='box'
              name='box'
              placeholder='Box de entrega'
              component={InputText}
            />
            <Field
              colSm={3}
              controlLabel='Fecha de entrega:'
              id='deliveryDate'
              name='deliveryDate'
              placeholder='Fecha de entrega'
              component={InputText}
            />
            <Field
              colSm={3}
              controlLabel='Hora de entrega:'
              id='deliveryHour'
              name='deliveryHour'
              placeholder='Hora de entrega'
              component={InputText}
            />
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <div className="button-wrapper text-right">
            <Button className="btn-standard">
              Cerrar Cita
            </Button>
            <Button className="btn-standard-disabled">
              Cerrar
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default reduxForm({ form: 'newForm' })(ModalMeeting)
