import { call, put, select, takeLatest } from 'redux-saga/effects'
import { getAuth } from '../../selectors/access/auth'
import { showLoader, hideLoader } from '../../actions/common'
import messageModalActions from '../../actions/modals/messageModal'
import currencyActionsTypes from '../../constants/actions/dossier/common/currency'
import getCurrencies from '../../services/dossier/get/getCurrencyCombo'
import { handleError } from '../errors/errorManager'
import { fetchCurrenciesSuccess } from '../../actions/combos/combos'

export function * fetchCurrencyCombo () {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const currencies = yield call(getCurrencies, auth.token)
    yield put(fetchCurrenciesSuccess(currencies))
  } catch (error) {
    const customHandler = function * () {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.FETCH_COMBO_ERROR'))
    }
    yield call(handleError, { error, customHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchCurrencyCombo () {
  yield takeLatest(currencyActionsTypes.FETCH_CURRENCY_COMBO, fetchCurrencyCombo)
}
