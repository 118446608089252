import React, { PureComponent } from 'react'
import { reduxForm } from 'redux-form'
import { Col, Row, Form, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Element } from 'react-scroll'
import { compareObjects, getDocumentaryPermission } from '../../../../util/utils'
import { validateEntity, validateDelivery, validateInteger, validateContact, isEntityEmpty, isEntityChildEmpty, isContactEmpty, isRequiredEndCustomerAndContact, validateRegex, customValidations } from '../../../../util/validationFunctions'
import { getComponentsPermisions } from '../../../../util/permisionFunctions'
import StatusHeaderPage from '../../../../containers/dossiers/common/StatusHeaderPage'
import ButtonsHeader from '../../../../_v2/components/dossiers/common/ButtonsHeader'
import ButtonsSidebar from '../../../../_v2/components/dossiers/common/ButtonsSidebar'
// import EditableTable from '../../../../components/commons/editableTable/EditableTable'

import ReassignReplacePurchaseModalPage from '../../../../containers/dossiers/common/ReassignReplacePurchaseModalPage'
import { dossierTypes as dossierTypesConstant } from '../../../../constants/dossier/common/dossierTypes'
import { dossierSubTypeId as dossierSubTypeIdConstants } from '../../../../constants/dossier/common/dossierSubType'
import { buttonsHeader, buttonsHeaderPermisionsIdReverse } from '../../../../constants/dossier/common/buttonsHeader'
import { buttonsSidebar } from '../../../../constants/dossier/common/buttonsSidebar'
import { permissions } from '../../../../constants/backendIds'

import DocumentaryManagementModalPage from '../../../../containers/dossiers/common/DocumentaryManagementModalPage'
import RejectCommentModal from '../../../../components/dossiers/common/RejectCommentModal'

import SmallLoaderPage from '../../../../containers/commons/SmallLoaderPage'

import SendDocumentsSignModal from '../../../../components/dossiers/common/documentaryManagement/SendDocumentsSignModal'

import SaleRequestModal from '../../../../components/dossiers/sales/SaleRequestModal'
import TrackingContainer from '../../../../containers/tracking/trackingContainer'
import { TrackedPages } from '../../../../constants/tracking/TrackedPages'
import Charges from '../../../../components/dossiers/common/Charges'
import {
  asyncValidateEntityDni, getFieldsValidated
} from '../../../../util/validationFunctionEntityDniAsync'
import { validationRegistrationDeliveryPromisesOrder } from '../../../../constants/validatedFields/backValidationPromisesOrder'
import promisesStatus from '../../../../constants/promisesStatus'
import UACModalPage from '../../../../components/dossiers/common/uac/UACModalPage'
/// ////////////////////////////////////////////////////////////
import DossierHeaderPage from '../../../containers/dossiers/commons/dossierHeaderPage'
import DossierTimeLineSectionPage from '../../../containers/dossiers/commons/DossierTimeLineSectionPage'
import DossierSummary from '../common/DossierSummary'

/// ////_V2 componets ////////////////////////////////////////////////////////
import SalesComponent from '../../../../_v2/components/dossiers/registrationDelivery/SalesComponent'
import Client from '../../../../_v2/components/dossiers/common/Client'
import Vehicle from '../../../../_v2/components/dossiers/common/Vehicle'
import UAC from '../../../../_v2/components/dossiers/common/uac/UAC'
import PaperworkTablePage from '../../../../_v2/containers/dossiers/commons/PaperworkComponentPage'
import Delivery from '../../../../_v2/components/dossiers/common/Delivery'
/// ////////////
import DocumentaryManagementPage from '../../../../_v2/containers/dossiers/commons/DocumentaryManagementPage'
import RelatedDossiers from '../common/RelatedDossiers'
import AuditInfo from '../../../../_v2/components/dossiers/common/audit/AuditInfo'
import CommentComponent from '../../../../_v2/components/dossiers/common/comment/CommentComponent'

class RegistrationDelivery extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      registrationDeliveryOpen: true,
      modalOpen: true,
      isVehicleLoaded: false,
      isClientLoaded: false,
      isDeliveryLoaded: false,
      showRejectCommentModal: false,
      showUACModal: false,
      UACSelected: {},
      showCampaignModal: false,
      showCampaignModalConfigure: false,
      showSendDocumentsModal: false,
      showCampaignSearchDocumentsModal: false,
      isOtherDataLoaded: false,
      noRecoverableCostsOpen: false,
      isSaved: false,
      activeTabConfiguration: null,
      headerTabConfiguration: null,
      componentsConfiguration: {
        bsale: null,
        entity: null,
        vehicle: null,
        uac: null,
        paperwork: null,
        delivery: null,
        charge: null
      },
      dossierBodyLeftClose: false,
      showTimelineInTab: false,
      reason: null,
      comment: null
    }
    this.goBack = this.goBack.bind(this)
  }

  toggleRejectCommentModal (showRejectCommentModal) {
    this.setState({ showRejectCommentModal })
  }

  toggleUACModal (showUACModal, UACUnit) {
    const UACSelected = UACUnit || {}
    this.setState({ showUACModal, UACSelected })
  }

  toggleCampaignModal (showCampaignModal, campaign) {
    const campaignSelected = campaign || {}
    this.setState({ showCampaignModal, campaignSelected })
  }

  toggleConfigureCampaignModal (showCampaignModalConfigure) {
    this.setState({ showCampaignModalConfigure })
  }

  toggleSendDocumentsModal (showSendDocumentsModal) {
    this.setState({ showSendDocumentsModal })
  }

  toggleCampaignSearchDocumentsModal (showCampaignSearchDocumentsModal) {
    this.setState({ showCampaignSearchDocumentsModal })
  }

  onClickRelatedDossierColumn (columnCode) {
    let columnCodeOrder = '+' + columnCode
    if (columnCodeOrder === this.props.relatedDossiersOrderBy) {
      columnCodeOrder = '-' + columnCode
    }
    this.props.actions.fetchFilteredRelatedDossier(this.props.dossier.dossierId, columnCodeOrder)
  }

  getRelatedDossierColumnIcon (columnCode) {
    if ('+' + columnCode === this.props.relatedDossiersOrderBy) {
      return 'ico-sort-selected-asc'
    } else if ('-' + columnCode === this.props.relatedDossiersOrderBy) {
      return 'ico-sort-selected-desc'
    } else {
      return 'ico-sort'
    }
  }

  handleSubmitForm (resolve, reject) {
    if (this.props.formErrors) {
      let skipGeneralMsg = false
      if (this.props.formErrors.salesComponent && Object.keys(this.props.formErrors.salesComponent).length > 0) {
        this.props.actions.setScrollTo(buttonsSidebar.bsale)
        this.props.actions.setActiveSection(buttonsSidebar.bsale)
      } else if (this.props.formErrors.entityComponent && Object.keys(this.props.formErrors.entityComponent).length > 0 ||
        this.props.formErrors.entityChildComponent && Object.keys(this.props.formErrors.entityChildComponent).length > 0 ||
        this.props.formErrors.dossierContactComponent && Object.keys(this.props.formErrors.dossierContactComponent).length > 0) {
        this.props.actions.setScrollTo(buttonsSidebar.entity)
        this.props.actions.setActiveSection(buttonsSidebar.entity)
      } else if (this.props.formErrors.dossierCompleteDeliveryComponent && Object.keys(this.props.formErrors.dossierCompleteDeliveryComponent.dossierDeliveryComponent).length > 0) {
        this.props.actions.setScrollTo(buttonsSidebar.delivery)
        this.props.actions.setActiveSection(buttonsSidebar.delivery)
      } else if (this.props.formErrors.vehicleComponent && Object.keys(this.props.formErrors.vehicleComponent).length > 0) {
        this.props.actions.setScrollTo(buttonsSidebar.vehicle)
        this.props.actions.setActiveSection(buttonsSidebar.vehicle)
      }
      if (this.props.isInvalid && !skipGeneralMsg) {
        this.props.actions.openErrorMessageModal('DOSSIER_COMPONENTS.VALIDATIONS.GENERAL_MANDATORY')
      }
    }
    this.props.handleSubmit((values) => this.submitRegistrationDeliveryDossier(values, resolve, reject))()
  }

  submitRegistrationDeliveryDossier (values, resolve, reject) {
    this.props.actions.saveRegistrationDeliveryDossier(values, resolve, reject)
  }

  fetchRegistrationDeliveryDossierPromise (dossierId) {
    return new Promise((resolve, reject) => {
      return this.props.actions.fetchRegistrationDeliveryDossier(dossierId, resolve, reject)
    })
  }

  routerWillLeave (nextLocation) {
    let pristine = true
    if (this.props.dossier.viewConfiguration.buttonsPermission && this.props.dossier.viewConfiguration.buttonsPermission.includes('reg_delivery_save')) {
      pristine = this.props.pristine
    }
    this.props.actions.registrationDeliveryWarningSave(this.props.reset, this.props.history, nextLocation, pristine)
    return pristine
  }

  initializePermisions (props) {
    const componentKeys = Object.keys(this.state.componentsConfiguration)
    const newState = getComponentsPermisions(props.dossier.viewConfiguration.tabsConfiguration, props.activeTab, componentKeys)
    this.setState({ ...this.state, ...newState })
  }

  componentDidMount () {
    this.props.actions.fetchCommercialSocietyTypeCombo()
    this.props.actions.restartRegistrationDeliveryState()
    this.fetchRegistrationDeliveryDossierPromise(this.props.match.params.dossierId).then(() => {
      const form = {
        ...this.props.dossier,
        entityComponent: this.props.dossier.entityComponent.entityId ? this.props.dossier.entityComponent : undefined,
        entityChildComponent: this.props.dossier.entityChildComponent.entityId ? this.props.dossier.entityChildComponent : undefined
      }
      this.props.initialize(form)
      this.initializePermisions(this.props)
      this.props.actions.fetchAllReasons(this.props.dossier.dossierSubTypeId)
      this.props.actions.fetchRegistrationDeliveryDossierDependants(this.props.match.params.dossierId)
      this.props.actions.fetchHomologationClassificationCombo()
      this.props.actions.fetchCustomFields(this.props.dossier.dossierSubTypeId)
      this.props.actions.registerLastAccess(this.props.match.params.dossierId, false)
      this.props.actions.fetchHomologationProducerCombo()
      this.props.actions.fetchIaeCombo()
      this.props.actions.fetchServiceDestinationTypes()
      this.props.actions.fetchStockStatusCombo()
      this.props.trackVisitPage({
        documentTitle: TrackedPages.REGISTRATION_DELIVERY,
        organizedUnitId: this.props.dossier.organizedUnitId,
        href: window.location.href
      })
    })
    this.unregisterLeaveHook = this.props.history.block(this.routerWillLeave.bind(this))

    this.setState({ showTimelineInTab: this.props.auth.showChecklistAsTab ?? false })
  }

  getSnapshotBeforeUpdate (prevProps) {
    if ((this.props.showManagementModal && !prevProps.showManagementModal) || this.props.match.params.dossierId !== prevProps.match.params.dossierId) {
      if (this.props.match.params.dossierId !== prevProps.match.params.dossierId) {
        this.props.actions.restartRegistrationDeliveryState()
        this.props.actions.setScrollTo(buttonsSidebar.bsale)
        this.props.actions.setActiveSection(buttonsSidebar.bsale)
        this.props.actions.setActiveTab(buttonsHeader.datos_expediente)
        this.props.actions.closeAddOrEditDocumentModal()
      }
      this.fetchRegistrationDeliveryDossierPromise(this.props.match.params.dossierId).then(() => {
        const form = { ...this.props.dossier }
        if (!this.props.dossier.entityComponent.entityId) delete form.entityComponent
        this.props.initialize(form)
        this.initializePermisions(this.props)
        this.props.actions.fetchRegistrationDeliveryDossierDependants(this.props.match.params.dossierId)
      })
    }
    if (this.props.dossier && this.props.dossier.viewConfiguration && this.props.dossier.viewConfiguration.tabsConfiguration && !compareObjects(prevProps.dossier.viewConfiguration.tabsConfiguration || {}, this.props.dossier.viewConfiguration.tabsConfiguration, true)) {
      this.initializePermisions(this.props)
    }
    if (prevProps.dossier.dossierId && prevProps.activeTab !== this.props.activeTab) {
      this.initializePermisions(this.props)
    }
    if (prevProps.dossier && prevProps.dossier.comments && this.props.activeTab !== prevProps.activeTab && prevProps.activeTab === buttonsHeader.observaciones) {
      this.props.actions.markCommentAsRead(prevProps.dossier.dossierId, 'registrationDeliveryDossier')
    }
    if (this.props.dossier && this.props.dossier.comments && this.props.activeTab !== prevProps.activeTab && prevProps.activeTab === buttonsHeader.observaciones) {
      this.props.actions.getComments(this.props.dossier.dossierId, 'registrationDeliveryDossier')
    }
    if (this.props.dossier && this.props.dossier.organizedUnitId && this.props.dossier.organizedUnitId !== prevProps.dossier.organizedUnitId) {
      this.props.actions.fecthOrganizedUnitsByDossierUo(this.props.dossier.organizedUnitId)
    }

    return prevProps
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    if (this.props.dossier.dossierId && this.props.statusHeaderHeight && this.props.activeSection) {
      switch (this.props.activeSection) {
        case buttonsSidebar.bsale:
          this.setState({ registrationDeliveryOpen: true })
          break
      }
    }

    const firstCustomFieldsLoad = prevProps.dossier.customFieldsByComponent?.length < this.props.dossier.customFieldsByComponent?.length
    if (firstCustomFieldsLoad) {
      let customFields = {}
      if (this.props.dossier.customFieldsValues) {
        this.props.dossier.customFieldsValues.map(x => {
          customFields[x.key] = x.value
        })
      }
      const form = {
        ...this.props.dossier,
        customFields: customFields
      }
      this.props.initialize(form)
    }
  }

  goBack () {
    this.props.actions.setActiveTab(buttonsHeader.datos_expediente)
    this.props.history.goBack()
  }

  componentWillUnmount () {
    this.props.actions.setActiveSection(buttonsSidebar.bsale)
    this.props.actions.setScrollTo(null)
    this.props.actions.resetButtonsHeader()
    this.unregisterLeaveHook
    this.props.actions.closeAddOrEditDocumentModal()
    this.props.actions.restartRegistrationDeliveryState()
  }

  handleToUpdateComponent (component) {
    if (component.isVehicleLoaded === false || buttonsSidebar.vehicle === component) this.setState({ isVehicleLoaded: true })
    if (component.isClientLoaded === false || buttonsSidebar.entity === component) this.setState({ isClientLoaded: true })
    if (component.isFinanceLoaded === false || buttonsSidebar.finance === component) this.setState({ isFinanceLoaded: true })
    if (component.isDeliveryLoaded === false || buttonsSidebar.delivery === component) this.setState({ isDeliveryLoaded: true })
    if (component.isOtherDataLoaded === false || buttonsSidebar.other_data === component) this.setState({ isOtherDataLoaded: true })
    if (component.isEconomicLoaded === false) this.setState({ isEconomicLoaded: true })
    this.props.actions.setScrollTo(component)
  }

  updateComponentAfterDeleteAndRestart () {
    this.setState({
      isClientLoaded: false,
      isVehicleLoaded: false,
      isFinanceLoaded: false,
      isDeliveryLoaded: false,
      isOtherDataLoaded: false,
      isEconomicLoaded: false
    })
    this.props.actions.setActiveSection(buttonsSidebar.bpurchase)
    this.props.actions.setScrollTo(null)
  }

  togglePanel () {
    if (this.state.registrationDeliveryOpen) {
      this.props.actions.setActiveSection(false)
    }
    this.setState({ registrationDeliveryOpen: !this.state.registrationDeliveryOpen })
  }

  toggleShowTimelineInTab () {
    const showTimelineInTab = !this.state.showTimelineInTab

    if (this.props.activeTab === buttonsHeader.time_line) {
      let nextActiveTab = this.props.dossier.viewConfiguration.tabsConfiguration.filter((tabConf) => tabConf.permission > permissions.hidden)
      this.props.actions.setActiveTab(buttonsHeaderPermisionsIdReverse[nextActiveTab[0].code])
    }
    this.setState({ showTimelineInTab })
  }

  toogleHidedossierBodyLeft () {
    this.setState({ dossierBodyLeftClose: !this.state.dossierBodyLeftClose })
  }

  render () {
    const {
      t, dossier, combos = {}, change, pristine, vehicleProps, uacModalForm, activeTab, buttonsHeaderHeight, activeSection, statusHeaderHeight, windowWidth, reset, formValues, reason, comment, dispatch,
      dossier: {
        dossierId, dossierSubTypeId, header,
        checkListHeader, comments, uac, paperWorks, organizedUnitId, purchasesDossierId,
        dossierCompleteDeliveryCheckListItems, dossierDeliveryCheckListPurchase, entityComponent, showAlertSendPending, unreadComments,
        viewConfiguration, viewConfiguration: { buttonsPermission = [] },
        vehicleComponent: { vehicleId }, dossierRelatedDossiers, auditInfo, order,
        isPreCancelled, isMarked, charges
      },
      actions: {
        saleApprove, saleApprovePaperwork, saleReject, saleSkipCharge, saleSkipDocument, registrationDeliveryApproveByRole, saleApproveFleet, salePayRenting,
        registrationDeliverySkipPaperwork, registrationDeliverySkipDelivery, registrationDeliverySendApprove, openDocumentModal,
        updateDependantsSaleDossierAndHeader,
        putCancelDossier,
        undoCancelDossier,
        confirmCancelDossier,
        printDocumentaryChecklist,
        fetchOperationType,
        postPaymentSignalStatusSuccess,
        updateLastAccessDate,
        setEntityTypeId, setEntitySubTypeId, setEntityId, setEntityValidationDisable,
        fetchClientByDni, fetchClientFromDocument, openDocumentEditionModal, fetchClientByDniEntityChild,
        searchVehicle,
        fetchOrder,
        saveOrder,
        setSearchDealerNumber,
        setSearchVinChassis,
        setSearchLicensePlate,
        setSearchMakerNumber,
        setSimpleInputTextValidation,
        resetSearchFields,
        storeDossier,
        updateComment, createComment, deleteComment,
        fetchUACUnitEdition, deleteUACUnit,
        openCommonModal,
        fetchAndOpenReplacePurchaseModal,
        printDeliveryChecklist,
        refreshAuditInfo,
        setActiveTab, setButtonsHeaderHeight, resetButtonsHeader,
        setActiveSection,
        setScrollerOptions,
        clearVehicle,
        fetchReasons,
        fetchEntitySubTypeCombo,
        fetchEntityFilterSubTypeCombo,
        setEntityChildTypeId,
        fetchVoCompaniesCombo,
        submitSelectVoCompany,
        clearEntity,
        clearEntityChild,
        registrationDeliveryApproveVoucher,
        fetchComboOrigin,
        fetchComboDestination,
        fetchRegistrationDeliveryVehicle,
        fetchRegistrationDeliveryClient,
        fetchRegistrationDeliveryFinancing,
        fetchRegistrationDeliveryDelivery,
        fetchRegistrationDeliveryOtherData,
        fetchRegistrationDeliveryEconomic,
        configureCampaign,
        openDocumentsToSendSign,
        sendDocumentToSign,
        documentsToSendSignGetAll,
        signTypes,
        skipItv,
        approveMargin,
        fetchAuditDetails,
        fetchAuditDetailsModal,
        fetchDocumentsGroupedAuditDetails,
        fetchDocumentsGroupedAuditDetailsModal,
        saleApproveBilling,
        fetchLowestOrganizedUnits,
        getChangeUo,
        getSalesmanChangeUo,
        postUpdateChangeUo,
        fecthOrganizedUnitsByDossierUo,
        clearClientFields,
        fetchCancelCombo,
        fetchClientByRfc,
        printCheckList,
        fetchCommercialSocietyTypeCombo,
        fetchEntityCountryTypeCombo, fetchEntityStateTypeCombo,
        fetchEntityCityTypeCombo, fetchEntityTownTypeCombo,
        fetchEntityColonyTypeCombo, fetchEntityCPTypeCombo,
        fetchActivityBpro,
        fetchClientByPhoneEmail, validate, stopAsyncValidation,
        fetchClientDataAddress,
        fetchFilteredRelatedDossier,
        markCommentAsRead
      }
    } = this.props
    if (!dossierId) return null
    const tKey = 'DOSSIER_COMPONENTS.'
    const activeStage = checkListHeader.find(stage => stage.isActive)
    const panelsPaddingTop = statusHeaderHeight + buttonsHeaderHeight - 82 // 82px es el paddingTop definido en el layout.
    const saveRegistrationDeliveryPermission = buttonsPermission.includes('reg_delivery_save')
    const printDeliveryChecklistPermission = buttonsPermission.includes('reg_delivery_print_delivery_checklist')
    return (
      <>
        <div class="dossierGrid">
          <div class="dossierGridPart-header">
            <DossierHeaderPage
              headerData={header}
              stages={checkListHeader}
              dossierType={dossierTypesConstant.sales}
              dossierSubType={dossierSubTypeId}
              dossierId={dossier.dossierId}
              activeTab={activeTab}
              headerTabConfiguration={this.state.headerTabConfiguration}
              pristine={pristine}
              goBack={this.goBack}
              readOnlyFields={isMarked}
              editableDoc={viewConfiguration ? getDocumentaryPermission(viewConfiguration) : true}
              isSelfSupplyLoaded={this.state.isSelfSupplyLoaded}
              showTimelineInTab={this.state.showTimelineInTab}
              toggleShowTimelineInTab = {() => this.toggleShowTimelineInTab()}
              timeLinePosition = {this.state.showTimelineInTab}
              handleToUpdateSelfSupply = {() => this.handleToUpdateSelfSupply()}
              entityComponent = {entityComponent}
              vehicleComponent = {dossier.vehicleComponent}
            />
          </div>
          <div class="dossierGridPart-body">
            <div className={'dossierBody-left ' + (this.state.dossierBodyLeftClose ? 'close' : '') }>
              <DossierSummary
                t = {t}
                dossier={dossier}
                header={header}
                dossierType={dossierTypesConstant.registrationDelivery}
                dossierSubType={dossierSubTypeId}
                brandCombo = {combos.brandCombo}
                entityTypeCombo = {combos.entityTypeCombo}
                dossierRelatedDossiers = {dossierRelatedDossiers}
              >
              </DossierSummary>
            </div>
            <div className='dossierBody-center'>
              <OverlayTrigger placement="right" overlay={<Tooltip id="editProductTooltip">{`${t((this.state.dossierBodyLeftClose ? 'GLOBAL.TABLES.SHOW_INNER_MENU' : 'GLOBAL.TABLES.HIDE_INNER_MENU'))}`}</Tooltip>}>
                <a onClick={() => this.toogleHidedossierBodyLeft()} className={'closeDossierInfo ' + (this.state.dossierBodyLeftClose ? '' : 'closeInnerMenu')}><i className={this.state.dossierBodyLeftClose ? 'iDocIcon-Open-inner-menu' : 'iDocIcon-Close-inner-menu'}></i></a>
              </OverlayTrigger>
            </div>
            <div className='dossierBody-right'>
              {!this.state.showTimelineInTab && <div className='dossierGridPart-topTimeLine'>
                <DossierTimeLineSectionPage
                  showTitlePage={false}
                  headerData={header}
                  stages={checkListHeader}
                  dossierType={dossierTypesConstant.sales}
                  dossierSubType={dossierSubTypeId}
                  dossierId={dossier.dossierId}
                  activeTab={activeTab}
                  headerTabConfiguration={this.state.headerTabConfiguration}
                  pristine={pristine}
                  goBack={this.goBack}
                  readOnlyFields={isMarked}
                  editableDoc={viewConfiguration ? getDocumentaryPermission(viewConfiguration) : true}
                  isSelfSupplyLoaded={this.state.isSelfSupplyLoaded}
                  handleToUpdateSelfSupply = {() => this.handleToUpdateSelfSupply()}
                />
              </div>}
              <div class="dossierGridPart-sectionsMenu">
                <ButtonsHeader
                  t={t}
                  pristine={pristine}
                  reset={reset}
                  combos={combos.purchaseTypeCombo}
                  lowestOrganizedUnitsCombo = {combos.organizedUnitsLegalCif}
                  isEconomicLoaded = {this.state.isEconomicLoaded}
                  handleToUpdateComponent = {this.handleToUpdateComponent.bind(this)}
                  historical={false}
                  cancelCombo={combos.cancelCombo}
                  reason={reason}
                  comment={comment}
                  readOnlyFields={!saveRegistrationDeliveryPermission || isPreCancelled || isMarked}
                  actions={{
                    saleApprove,
                    saleApproveFleet,
                    saleApprovePaperwork,
                    saleReject: () => this.toggleRejectCommentModal(true),
                    saleSkipCharge,
                    saleSkipDocument,
                    registrationDeliverySkipPaperwork,
                    registrationDeliverySkipDelivery,
                    registrationDeliverySendApprove,
                    storeDossier,
                    registrationDeliveryCancel: () => putCancelDossier(dossierId, pristine, reset),
                    undoCancelDossier: () => undoCancelDossier(dossierId, pristine, reset),
                    confirmCancelDossier: () => confirmCancelDossier(dossierId, pristine, reset),
                    openDocumentModal: () => openDocumentModal(dossierId, dossierTypesConstant.registrationDelivery, pristine),
                    fetchAndOpenReplacePurchaseModal,
                    setActiveTab,
                    setButtonsHeaderHeight,
                    resetButtonsHeader,
                    setScrollerOptions,
                    submitForm: this.handleSubmitForm.bind(this),
                    registrationDeliveryApproveByRole,
                    fetchVoCompaniesCombo,
                    submitSelectVoCompany,
                    registrationDeliveryApproveVoucher,
                    salePayRenting,
                    fetchRegistrationDeliveryVehicle,
                    fetchRegistrationDeliveryClient,
                    fetchRegistrationDeliveryFinancing,
                    fetchRegistrationDeliveryDelivery,
                    configureCampaign,
                    openConfigureCampaignEdition: (campaign) => this.toggleConfigureCampaignModal(true, campaign),
                    openSendDocumentsToSignModal: (document) => this.toggleSendDocumentsModal(true, document),
                    fetchRegistrationDeliveryOtherData,
                    fetchRegistrationDeliveryEconomic,
                    openDocumentsToSendSign,
                    skipItv,
                    approveMargin,
                    saleApproveBilling,
                    fetchLowestOrganizedUnits,
                    getChangeUo,
                    getSalesmanChangeUo,
                    postUpdateChangeUo,
                    fecthOrganizedUnitsByDossierUo,
                    putCancelDossier,
                    fetchCancelCombo: fetchCancelCombo
                  }}
                  dossierId={this.props.match.params.dossierId}
                  viewConfiguration={viewConfiguration}
                  dossierType={dossierTypesConstant.registrationDelivery}
                  activeTab={activeTab}
                  marginTop={statusHeaderHeight}
                  windowWidth={windowWidth}
                  commentUnreadCount={ unreadComments || comments.filter((comment) => { return !comment.read }).length}
                  organizedUnitId={organizedUnitId}
                  showTimelineInTab={this.state.showTimelineInTab}

                />
              </div>
              <div class="dossierGridPart-ComponentSection">
                <Form autoComplete="off">
                  {// TIME LINE
                    activeTab === buttonsHeader.time_line && (
                      <div className="dossierGridPart-ComponentSection-Inner">
                        <div className="ComponentSection-InnerData">
                          <DossierTimeLineSectionPage
                            showTitlePage={true}
                            headerData={header}
                            stages={checkListHeader}
                            dossierType={dossierTypesConstant.sales}
                            dossierSubType={dossierSubTypeId}
                            dossierId={dossier.dossierId}
                            activeTab={activeTab}
                            headerTabConfiguration={this.state.headerTabConfiguration}
                            pristine={pristine}
                            goBack={this.goBack}
                            readOnlyFields={isMarked}
                            editableDoc={viewConfiguration ? getDocumentaryPermission(viewConfiguration) : true}
                            isSelfSupplyLoaded={this.state.isSelfSupplyLoaded}
                            handleToUpdateSelfSupply = {() => this.handleToUpdateSelfSupply()}
                          />
                        </div>
                      </div>
                    )}
                  {activeTab === buttonsHeader.datos_expediente && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden && (
                    <div className="_nd dossierGridPart-ComponentSection-Inner">
                      <div className="ComponentSection-InnerMennu">
                        <ButtonsSidebar
                          t={t}
                          historical={false}
                          handleToUpdateComponent = {this.handleToUpdateComponent.bind(this)}
                          isVehicleLoaded = {this.state.isVehicleLoaded}
                          isClientLoaded = {this.state.isClientLoaded}
                          isFinanceLoaded = {this.state.isFinanceLoaded}
                          isDeliveryLoaded = {this.state.isDeliveryLoaded}
                          isOtherDataLoaded = {this.state.isOtherDataLoaded}
                          pristine={pristine}
                          actions={{ setActiveSection, fetchRegistrationDeliveryVehicle, fetchRegistrationDeliveryClient, fetchRegistrationDeliveryFinancing, fetchRegistrationDeliveryDelivery, fetchRegistrationDeliveryOtherData }}
                          dossierType={dossierTypesConstant.registrationDelivery}
                          dossierSubTypes={dossierSubTypeId}
                          activeStage={activeStage}
                          marginTop={statusHeaderHeight}
                          buttonsHeaderHeight={buttonsHeaderHeight}
                          tabConfiguration={this.state.activeTabConfiguration}
                          dossierId={dossierId}
                          dossier={dossier}
                        />
                      </div>
                      <div className="ComponentSection-InnerData" id="dossierData" >
                        {this.state.componentsConfiguration.bsale && this.state.componentsConfiguration.bsale.permission > permissions.hidden && (
                          <Element name={buttonsSidebar.bsale}>
                            <SalesComponent
                              t={t}
                              dossier={dossier}
                              salesOpen={this.state.registrationDeliveryOpen}
                              combos={combos}
                              actions={{
                                change,
                                fetchOperationType,
                                printDocumentaryChecklist,
                                openDocumentEditionModal,
                                postPaymentSignalStatusSuccess,
                                updateLastAccessDate,
                                setActiveTab,
                                togglePanel: () => this.togglePanel(),
                                setActiveSection
                              }}
                              readOnlyFields={!saveRegistrationDeliveryPermission || isPreCancelled || isMarked}
                              dossierType={dossierTypesConstant.registrationDelivery}
                              open={activeSection === buttonsSidebar.bsale}
                              componentConfiguration={this.state.componentsConfiguration.bsale}
                              customFields={this.props.dossier.customFieldsByComponent.find(x => x.componentCode === buttonsSidebar.bsale)?.customFields}
                            />
                          </Element>
                        )}
                        {this.state.componentsConfiguration.entity && this.state.componentsConfiguration.entity.permission > permissions.hidden && (
                          <Element name={buttonsSidebar.entity}>
                            <Client
                              t={t}
                              handleToUpdateComponent= {this.handleToUpdateComponent.bind(this)}
                              isClientLoaded = {this.state.isClientLoaded}
                              pristine={pristine}
                              dossier={dossier}
                              combos={combos}
                              entityChangeable={true}
                              dossierType={dossierTypesConstant.registrationDelivery}
                              actions={{
                                change,
                                fetchClientByDni,
                                fetchClientByDniEntityChild,
                                fetchClientFromDocument,
                                setEntityTypeId,
                                setEntitySubTypeId,
                                setEntityId,
                                fetchEntitySubTypeCombo,
                                fetchEntityFilterSubTypeCombo,
                                setEntityChildTypeId,
                                clearEntity,
                                clearEntityChild,
                                fetchRegistrationDeliveryClient,
                                setEntityValidationDisable,
                                setActiveSection,
                                clearClientFields,
                                fetchClientByRfc,
                                fetchCommercialSocietyTypeCombo,
                                fetchEntityCountryTypeCombo,
                                fetchEntityStateTypeCombo,
                                fetchEntityCityTypeCombo,
                                fetchEntityTownTypeCombo,
                                fetchEntityColonyTypeCombo,
                                fetchEntityCPTypeCombo,
                                openCommonModal,
                                fetchClientByPhoneEmail,
                                fetchActivityBpro,
                                validate,
                                dispatch,
                                asyncValidate,
                                stopAsyncValidation: (value) => stopAsyncValidation('registrationDeliveryDossier', value),
                                fetchClientDataAddress
                              }}
                              readOnlyFields={!saveRegistrationDeliveryPermission || isPreCancelled || isMarked}
                              open={activeSection === buttonsSidebar.entity}
                              componentConfiguration={this.state.componentsConfiguration.entity}
                              updateComponentAfterDeleteAndRestart={this.updateComponentAfterDeleteAndRestart.bind(this)}
                              customFields={this.props.dossier.customFieldsByComponent.find(x => x.componentCode === buttonsSidebar.entity)?.customFields}
                              formValues={formValues}
                            />
                          </Element>
                        )}
                        {this.state.componentsConfiguration.vehicle && this.state.componentsConfiguration.vehicle.permission > permissions.hidden && (
                          <Element name={buttonsSidebar.vehicle}>
                            <Vehicle
                              handleToUpdateComponent = {this.handleToUpdateComponent.bind(this)}
                              isVehicleLoaded = {this.state.isVehicleLoaded}
                              pristine={pristine}
                              parentDossierType={{
                                isPaperwork: false,
                                isSales: false,
                                isPurchase: false,
                                isCampaign: false,
                                isRegistrationDelivery: true
                              }}
                              t={t}
                              dossierId={dossierId}
                              searchedVehicleProps={vehicleProps}
                              orderId={dossier.orderId}
                              order={order}
                              dossierParentId={purchasesDossierId}
                              dossierWithVehicle={!!vehicleId}
                              combos={combos}
                              actions={{
                                searchVehicle,
                                setSearchDealerNumber,
                                setSearchVinChassis,
                                setSearchLicensePlate,
                                setSearchMakerNumber,
                                setSimpleInputTextValidation,
                                resetSearchFields,
                                fetchOrder,
                                clearVehicle,
                                fetchComboDestination,
                                fetchComboOrigin,
                                fetchRegistrationDeliveryVehicle,
                                setActiveSection
                              }}
                              readOnlyFields={!saveRegistrationDeliveryPermission || isPreCancelled || isMarked}
                              open={activeSection === buttonsSidebar.vehicle}
                              reset={reset}
                              dossierType={dossierTypesConstant.registrationDelivery}
                              dossierSubType={dossierSubTypeId}
                              formValues={formValues ? formValues.vehicleComponent : {}}
                              componentConfiguration={this.state.componentsConfiguration.vehicle}
                              organizedUnitId={dossier.organizedUnitId}
                              codeVehicleConfiguration={false}
                              comercialCode={this.props.dossier.vehicleComponent.comercialCode}
                              customFields={this.props.dossier.customFieldsByComponent.find(x => x.componentCode === buttonsSidebar.vehicle)?.customFields}
                            />
                          </Element>
                        )}
                        {this.state.componentsConfiguration.uac && this.state.componentsConfiguration.uac.permission > permissions.hidden && (
                          <Element name={buttonsSidebar.uac}>
                            <UAC
                              t={t}
                              uac={uac}
                              actions={{
                                openUACEdition: (UACUnit) => this.toggleUACModal(true, UACUnit),
                                fetchUACUnitEdition,
                                deleteUACUnit,
                                setActiveSection
                              }}
                              readOnlyFields={!saveRegistrationDeliveryPermission || isPreCancelled || isMarked}
                              open={activeSection === buttonsSidebar.uac}
                              componentConfiguration={this.state.componentsConfiguration.uac}
                              dossierType={ dossierTypesConstant.registrationDelivery }
                              customFields={this.props.dossier.customFieldsByComponent.find(x => x.componentCode === buttonsSidebar.uac)?.customFields}
                            />
                          </Element>
                        )}
                        {this.state.componentsConfiguration.paperwork && this.state.componentsConfiguration.paperwork.permission > permissions.hidden && (
                          <Element name={buttonsSidebar.paperwork}>
                            <PaperworkTablePage
                              organizedUnitId={organizedUnitId}
                              dossierParentId={dossierId}
                              updateDossierAction={updateDependantsSaleDossierAndHeader}
                              showAlertSendPending={showAlertSendPending}
                              paperwork={paperWorks}
                              readOnlyFields={!saveRegistrationDeliveryPermission || isPreCancelled || isMarked}
                              dossierType={dossierTypesConstant.registrationDelivery}
                              open={activeSection === buttonsSidebar.paperwork}
                              componentConfiguration={this.state.componentsConfiguration.paperwork}
                              customFields={this.props.dossier.customFieldsByComponent.find(x => x.componentCode === buttonsSidebar.paperwork)?.customFields}
                            />
                          </Element>
                        )}
                        {this.state.componentsConfiguration.charge && this.state.componentsConfiguration.charge.permission > permissions.hidden && (
                          <Element name={buttonsSidebar.charge}>
                            <Charges
                              t={t}
                              dossier={dossier}
                              charges={charges}
                              actions={{
                                openDocumentEditionModal,
                                updateDependantsSaleDossierAndHeader,
                                fetchSalesEconomic: fetchRegistrationDeliveryEconomic,
                                printCheckList,
                                postPaymentSignalStatusSuccess,
                                setActiveSection
                              }}
                              dossierType={dossierTypesConstant.registrationDelivery}
                              readOnlyFields={!saveRegistrationDeliveryPermission || isMarked}
                              isPreCancelled={isPreCancelled}
                              open={activeSection === buttonsSidebar.charge}
                              componentConfiguration={this.state.componentsConfiguration.charge}
                              historical = {false}
                              handleToUpdateComponent = {this.handleToUpdateComponent.bind(this)}
                              pristine={pristine}
                              customFields={this.props.dossier.customFieldsByComponent.find(x => x.componentCode === buttonsSidebar.charge)?.customFields}
                            />
                          </Element>
                        )}
                        {this.state.componentsConfiguration.delivery && this.state.componentsConfiguration.delivery.permission > permissions.hidden && (
                          <Element name={buttonsSidebar.delivery}>
                            <Delivery
                              t={t}
                              handleToUpdateComponent = {this.handleToUpdateComponent.bind(this)}
                              isDeliveryLoaded = {this.state.isDeliveryLoaded}
                              dossier={dossier}
                              pristine={pristine}
                              isSalesDossier={true}
                              dossierId={dossierId}
                              dossierCompleteDeliveryCheckListItems={dossierCompleteDeliveryCheckListItems}
                              dossierDeliveryCheckListPurchase={dossierDeliveryCheckListPurchase}
                              actions={{
                                setActiveSection,
                                fetchRegistrationDeliveryDelivery,
                                printDeliveryChecklist: (dossierId) => {
                                  new Promise((resolve, reject) => {
                                    if (!this.props.pristine) this.handleSubmitForm(resolve, reject)
                                    else resolve()
                                  }).then(() => {
                                    printDeliveryChecklist(dossierId)
                                  })
                                }
                              }}
                              readOnlyFields={!saveRegistrationDeliveryPermission || isPreCancelled || isMarked}
                              open={activeSection === buttonsSidebar.delivery}
                              dossierSubTypeId={dossierSubTypeId}
                              printDeliveryChecklistPermission={printDeliveryChecklistPermission}
                              componentConfiguration={this.state.componentsConfiguration.delivery}
                              combos={combos}
                              customFields={this.props.dossier.customFieldsByComponent.find(x => x.componentCode === buttonsSidebar.delivery)?.customFields}
                            />
                          </Element>
                        )}
                      </div>
                    </div>
                  )}
                  {activeTab === buttonsHeader.gestor_documental && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden && (
                    <Row className="sales-panel dossier-panel">
                      <Col sm={12} className="col-row" style={{ paddingTop: panelsPaddingTop }}>
                        <DocumentaryManagementPage
                          dossier={dossier}
                          dossierType={dossierTypesConstant.registrationDelivery}
                          formState={this.props.pristine}
                          tabConfiguration={this.state.activeTabConfiguration}
                          configuration={this.state.activeTabConfiguration}
                        />
                      </Col>
                    </Row>
                  )}
                  {activeTab === buttonsHeader.expedientes_relacionados && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden && (
                    <div className="dossierGridPart-ComponentSection-Inner related-dossiers-table">
                      <div className="ComponentSection-InnerData">
                        <RelatedDossiers
                          t={t}
                          relatedDossiersOrderBy = {this.props.relatedDossiersOrderBy}
                          panelsPaddingTop = {panelsPaddingTop}
                          history = {this.props.history}
                          dossierRelatedDossiers = {dossierRelatedDossiers}
                          dossierId= {dossierId}
                          actions={{ fetchFilteredRelatedDossier }}
                        />
                      </div>
                    </div>

                  )}
                  {activeTab === buttonsHeader.actividad_expediente && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden && (
                    <div className="dossierGridPart-ComponentSection-Inner auditInfo">
                      <div sm={12} className="ComponentSection-InnerData">
                        <AuditInfo
                          auditInfo={auditInfo}
                          dossierType={dossierTypesConstant.registrationDelivery}
                          actions={{ refreshAuditInfo, fetchAuditDetails, fetchAuditDetailsModal, fetchDocumentsGroupedAuditDetails, fetchDocumentsGroupedAuditDetailsModal }}
                          dossier={dossier}
                          historical={false}
                          t={t}
                        />
                      </div>
                    </div>
                  )}
                  {activeTab === buttonsHeader.observaciones && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden && (
                    <div className="dossierGridPart-ComponentSection-Inner commentComponent">
                      <div className="ComponentSection-InnerData">
                        <CommentComponent
                          comments={comments}
                          actions={{ updateComment, createComment, deleteComment, markCommentAsRead }}
                          readOnlyFields={this.state.activeTabConfiguration.permission !== permissions.editable || isMarked}
                          dossierType={'registrationDeliveryDossier'}
                          t={t}
                          tKey={tKey}
                          combos={{ allReasonCombo: this.props.combos.allReasonCombo }}
                          openComponent={activeTab === buttonsHeader.observaciones}
                          dossierId={dossierId}
                        />
                        <SmallLoaderPage/>
                      </div>
                    </div>
                  )}
                </Form>
              </div>
            </div>
          </div>
          <div className='modalsContainer'>
            <RejectCommentModal
              t={t}
              showModal={this.state.showRejectCommentModal}
              reasonCombo={this.props.combos.reasonCombo}
              dossierSubTypeId={dossierSubTypeId}
              actions={{
                rejectAction: (comment, resolve, reject) => saleReject(comment, resolve, reject, pristine),
                closeModal: () => this.toggleRejectCommentModal(false),
                fetchReasons: fetchReasons
              }}
            />
            <ReassignReplacePurchaseModalPage
              showCreatePurchaseButton={buttonsPermission.includes('sale_create_purch')}
              isVo={dossierSubTypeId === dossierSubTypeIdConstants.venta_vo}
              organizedUnitId={dossier.organizedUnitId}
              makerNumber={this.props && this.props.dossier && this.props.dossier.order && this.props.dossier.order.makerNumber}
              openCommonModal={openCommonModal}
              purchaseTypeId={this.props && this.props.dossier && this.props.dossier.order && this.props.dossier.order.purchaseTypeId}
              componentConfiguration={this.state.componentsConfiguration.bsale}
            />
            <DocumentaryManagementModalPage
              dossier={dossier}
              dossierType={dossierTypesConstant.registrationDelivery}
              readOnlyFields={!saveRegistrationDeliveryPermission || isPreCancelled || isMarked}
            />
            <UACModalPage
              t={t}
              showModal={this.state.showUACModal}
              uacUnit={this.state.UACSelected}
              combos={combos}
              dossier={dossier}
              organizedUnitId={organizedUnitId}
              saleClientDniCif={entityComponent.dniCif}
              uacModalForm={uacModalForm}
              componentConfiguration={this.state.componentsConfiguration.uac}
              dossierType = { dossierTypesConstant.registrationDelivery}
              formValues={formValues}
            />
            <SendDocumentsSignModal
              t={t}
              dossierNumber={dossier.header.number}
              dossierId={dossier.dossierId}
              showModal={this.state.showSendDocumentsModal}
              actions={{
                closeModal: () => this.toggleSendDocumentsModal(false),
                sendDocumentToSign,
                documentsToSendSignGetAll,
                signTypes
              }}
            />
            <SendDocumentsSignModal
              t={t}
              dossierNumber={dossier.header.number}
              dossierId={dossier.dossierId}
              showModal={this.state.showSendDocumentsModal}
              actions={{
                closeModal: () => this.toggleSendDocumentsModal(false),
                sendDocumentToSign,
                documentsToSendSignGetAll,
                signTypes
              }}
            />
            <SaleRequestModal
              t={t}
              combos={combos}
              showSaleRequestModal={this.state.showSaleRequestModal}
              dossierId={dossier.dossierId}
              order={order}
              actions={{
                closeSaleRequestModal: () => this.toggleSaleRequestModal(false),
                saveOrder
              }}
              dossierType={ dossierTypesConstant.registrationDelivery }
            />
          </div>
        </div>
        <div className="dossier-sales-container hide">
          <Col sm={12} className="add-dossier-wrapper dossier">
            <StatusHeaderPage
              headerData={header}
              stages={checkListHeader}
              dossierType={dossierTypesConstant.registrationDelivery}
              dossierSubType={dossierSubTypeId}
              dossierId={dossier.dossierId}
              activeTab={activeTab}
              headerTabConfiguration={this.state.headerTabConfiguration}
              pristine={pristine}
              goBack={this.goBack}
              editableDoc={viewConfiguration ? getDocumentaryPermission(viewConfiguration) : true}
              readOnlyFields={!saveRegistrationDeliveryPermission || isPreCancelled || isMarked}
            />
          </Col>

        </div>
      </>
    )
  }
}

const validate = (values, props) => {
  const error = {}
  if (values.salesComponent) {
    error.salesComponent = {}
    if (values.salesComponent.repurchaseDate && values.salesComponent.orderDate) {
      const initDate = Date.parse(values.salesComponent.orderDate)
      const endDate = Date.parse(values.salesComponent.repurchaseDate)
      if (
        (initDate > endDate) ||
        (initDate === endDate && initDate > endDate) ||
        (initDate === endDate && initDate === endDate && initDate > endDate)
      ) {
        error.salesComponent.repurchaseDate = props.t('DOSSIER_COMPONENTS.VALIDATIONS.REPURCHASE_VALIDATION')
      }
    }
    if (!values.salesComponent.salesmanId && !values.salesComponent.salesmanIdManager) {
      error.salesComponent.salesmanId = props.t('DOSSIER_COMPONENTS.VALIDATIONS.SALESMAN_REQUIRED')
    }
  }

  if (values.entityComponent && values.entityComponent.updated && !isEntityEmpty(values.entityComponent, props.registeredFields)) {
    error.entityComponent = validateEntity(values.entityComponent, props)
    const contactHasData = values.dossierContactComponent ? !isContactEmpty(values.dossierContactComponent, props.registeredFields) : false
    const entityChildHasData = values.entityChildComponent ? !isEntityChildEmpty(values.entityChildComponent, props.registeredFields) : false
    const isRequiredEntityData = isRequiredEndCustomerAndContact(props)

    if (isRequiredEntityData || entityChildHasData) {
      error.entityChildComponent = validateEntity(values.entityChildComponent, props, null, true)
    }

    if (isRequiredEntityData || contactHasData) {
      error.dossierContactComponent = validateContact(values.dossierContactComponent, props)
    }

    const hasEntityChildErrors = error.entityChildComponent ? Object.keys(error.entityChildComponent).length > 0 : false
    const hasContactErrors = error.dossierContactComponent ? Object.keys(error.dossierContactComponent).length > 0 : false

    if (isRequiredEntityData && (entityChildHasData || contactHasData)) {
      if (hasEntityChildErrors && !entityChildHasData && contactHasData) {
        error.entityChildComponent = {}
      } else if (hasContactErrors && !contactHasData && entityChildHasData) {
        error.dossierContactComponent = {}
      }
    }
  }

  if (values.dossierFinancingComponent) {
    const financeVal = values.dossierFinancingComponent
    let dossierFinancingComponent = {}
    if (
      financeVal.companyId || financeVal.typeId || financeVal.amount ||
      financeVal.instalments || financeVal.firstInstalmentDate || financeVal.km || financeVal.tin
    ) {
      if (!financeVal.companyId) dossierFinancingComponent.companyId = props.t('DOSSIER_COMPONENTS.VALIDATIONS.FINANCE_COMPANY_MANDATORY')
      if (!financeVal.typeId) dossierFinancingComponent.typeId = props.t('DOSSIER_COMPONENTS.VALIDATIONS.FINANCE_TYPE_MANDATORY')
      if (!financeVal.amount) {
        dossierFinancingComponent.amount = props.t('DOSSIER_COMPONENTS.VALIDATIONS.FINANCE_AMOUNT_MANDATORY')
      } else {
        const auxError = validateInteger(financeVal.amount, props)
        if (Object.keys(auxError).length > 0) {
          dossierFinancingComponent.amount = auxError
        }
      }
    }
    if (Object.keys(dossierFinancingComponent).length > 0) {
      error.dossierFinancingComponent = dossierFinancingComponent
    }
  }

  if (values.dossierCompleteDeliveryComponent && values.dossierCompleteDeliveryComponent.dossierDeliveryComponent) {
    let dossierDeliveryComponent = {}
    dossierDeliveryComponent = validateDelivery(values.dossierCompleteDeliveryComponent.dossierDeliveryComponent, props, props.dossier.dossierSubTypeId)
    if (Object.keys(dossierDeliveryComponent).length > 0) {
      error.dossierCompleteDeliveryComponent = { dossierDeliveryComponent }
    }
  }

  error.customFields = {}
  if (values.customFields && Object.keys(values.customFields).length > 0 && props.dossier.customFieldsByComponent) {
    error.customFields = {}
    let resultCustomFields = {}
    let allCustomFields = []
    props.dossier.customFieldsByComponent.map(x => x.customFields).map(x => {
      allCustomFields = allCustomFields.concat(x)
    })
    Object.keys(values.customFields).forEach(key => {
      const foundCustomField = allCustomFields.find(x => x.code === key)
      if (foundCustomField && foundCustomField.validationFunction) {
        if (foundCustomField.validationFunction && foundCustomField.validationFunction.indexOf('regEx-') !== -1) {
          resultCustomFields = validateRegex(foundCustomField.validationFunction, foundCustomField.customFieldLocales[0].name, values.customFields[key], props)
        } else {
          resultCustomFields = customValidations(foundCustomField.validationFunction, values.customFields[key], props)
        }
        error.customFields[key] = resultCustomFields === Object(resultCustomFields) ? undefined : resultCustomFields
      }
    })
  }
  /*
  if (values.vehicleComponent) {
    let vehicleComponent = {}
    vehicleComponent = validateVehicle(values.vehicleComponent, props)
    if (Object.keys(vehicleComponent).length > 0) {
      error.vehicleComponent = vehicleComponent
    }
  }
  */
  return error
}

const asyncValidate = (values, dispatch, props, field, returnError) => {
  let promises = []
  if (values.entityComponent && values.entityComponent.updated) {
    promises = promises.concat(asyncValidateEntityDni(values.entityComponent, dispatch, props, props.dossier?.organizedUnitId))
    promises = promises.concat(asyncValidateEntityDni(values.entityChildComponent, dispatch, props, props.dossier?.organizedUnitId))
  }

  return Promise.allSettled(promises).then(result => {
    let error = {}
    for (let index = 0; index < result.length; index++) {
      const element = result[index]
      if (element.status === promisesStatus.FULFILLED) {
        switch (index) {
          case validationRegistrationDeliveryPromisesOrder.ENTITY_COMPONENT:
            error = { ...error, entityComponent: element.value }
            break
          case validationRegistrationDeliveryPromisesOrder.ENTITY_CHILD_COMPONENT:
            error = { ...error, entityChildComponent: element.value }
            break
          default:
            break
        }
      }
    }
    if (Object.keys(error).length > 0 && !returnError) throw Object.assign(new Error(), error)
    if (returnError) return error
  })
}

var decoratedComponent = TrackingContainer(RegistrationDelivery, false)

export default reduxForm({
  form: 'registrationDeliveryDossier',
  validate,
  asyncValidate,
  asyncBlurFields: [...getFieldsValidated('entityComponent'), ...getFieldsValidated('entityChildComponent')]
})(decoratedComponent)
