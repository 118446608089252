import apiFetch from '../apiFetch'

export default function (token, isDeleted) {
  const queryParamsArray = [
    isDeleted ? `isDeleted=${isDeleted}` : `isDeleted=${false}`
  ]
  const queryParams = queryParamsArray.reduce(
    (result, query, idx) => result + (query ? (result ? `&${query}` : `?${query}`) : '')
    , ''
  )

  return apiFetch({ endPoint: `FinancingTypeProduct/GetAll${queryParams}`, method: 'GET', body: null, token: token })
}
