
import { bindActionCreators } from 'redux'
import ProcessingAgencyIntegrationServiceDestinationsMaster from '../../../../components/masters/processing/processingAgencyIntegrations/ProcessingAgencyIntegrationServiceDestinationsMaster'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { fetchProcessingAgencyServiceDestinationsCombo, fetchProcessingAgencyServiceDestinationsList, showProcessingAgencyServiceDestinationsModal, deleteProcessingAgencyServiceDestinations } from '../../../../actions/masters/processingAgency/processingAgencyIntegrations/processingAgencyServiceDestinations'

export function mapStateToProps (state) {
  const serviceDestinations = state.processingAgency.processingAgencyIntegrationModal.serviceDestinations
  return {
    ...serviceDestinations,
    agency: state.processingAgency.processingAgencyIntegrationModal.agency,
    combos: {
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchProcessingAgencyServiceDestinationsList,
      showProcessingAgencyServiceDestinationsModal,
      deleteProcessingAgencyServiceDestinations,
      fetchProcessingAgencyServiceDestinationsCombo
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(ProcessingAgencyIntegrationServiceDestinationsMaster))
