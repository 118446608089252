import faqActionTypes from '../../constants/actions/faq/faq'

function initialState () {
  return {
    sections: [],
    question: null,
    questions: null
  }
}

function fetchSectionListSuccess (state, { sections }) {
  return {
    ...state,
    sections
  }
}

function fetchQuestionSuccess (state, { question }) {
  return {
    ...state,
    question
  }
}

function fetchSectionQuestionsSuccess (state, { questions }) {
  return {
    ...state,
    questions
  }
}

function fetchQuestionsBySearchSuccess (state, { questions }) {
  return {
    ...state,
    questions
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case faqActionTypes.FETCH_SECTION_LIST_SUCCESS:
      return fetchSectionListSuccess(state, action)
    case faqActionTypes.FETCH_QUESTION_SUCCESS:
      return fetchQuestionSuccess(state, action)
    case faqActionTypes.FETCH_SECTION_QUESTION_SUCCESS:
      return fetchSectionQuestionsSuccess(state, action)
    case faqActionTypes.FETCH_QUESTIONS_BY_SEARCH_SUCCESS:
      return fetchQuestionsBySearchSuccess(state, action)
    default:
      return state
  }
}
