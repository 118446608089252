import React, { PureComponent } from 'react'
import { Button, Modal, Col } from 'react-bootstrap'
import { reduxForm } from 'redux-form'
import Client from '../../dossiers/common/Client'
import { dossierTypes as dossierTypesConstant, dossierTypesId } from '../../../constants/dossier/common/dossierTypes'
import { validateEntity, validateContact, isContactEmpty, isEntityChildEmpty } from '../../../util/validationFunctions'
import {
  asyncValidateEntityDni, getFieldsValidated
} from '../../../util/validationFunctionEntityDniAsync'
import { validationDataContactFleetModalPromisesOrder } from '../../../constants/validatedFields/backValidationPromisesOrder'
import promisesStatus from '../../../constants/promisesStatus'

class DataContactFleetModal extends PureComponent {
  constructor () {
    super()
    this.state = {}
  }

  saveData (values) {
    let save = false
    let data = {}
    if (values.dossierContactComponent && values.dossierContactComponent.contactName) {
      data.dossierContactComponent = values.dossierContactComponent
      save = true
    }

    if (values.entityChildComponent && values.entityChildComponent.dniCif) {
      data.entityChildComponent = values.entityChildComponent
      save = true
    }
    if (save) {
      data.dossierList = this.props.dossiers.map(x => { return x.dossierId })
      this.props.actions.setOperateDataContactFleetProcess(data, this.props.fleet.fleetId, this.props.fleet.organizedUnitId, () => this.props.actions.closeDataContactFleetModal())
    } else {
      this.props.actions.openModal('notChangesToSave')
    }
  }

  componentDidUpdate () {}

  getSnapshotBeforeUpdate (prevProps) {
    if (this.props.showModal !== prevProps.showModal && this.props.showModal === true && this.props.fleet) {
      this.props.change('entityComponent', this.props.fleet.entityComponent)
      this.props.change('entityChildComponent', this.props.fleet.finalEntity)
      this.props.change('dossierContactComponent', this.props.fleet.dossierContactComponent)
    }

    if (prevProps.dossier?.organizedUnitId !== this.props.dossier.organizedUnitId && this.props.dossier.organizedUnitId) {
      this.getClientPermissions(this.props.organizedUnitId)
    }
    return null
  }

  updateComponentAfterDeleteAndRestart () {
    this.props.actions.fetchEntityOperateChildFleetSucces({ entityChildComponent: { entityTypeId: null, dniCifValidationDisable: false, representativeDniCifValidationDisable: false } })
  }

  getClientPermissions (organizedUnitId) {
    this.props.actions.getClientFleetPermissions(null, organizedUnitId, dossierTypesId.sales)
  }

  render () {
    const {
      t,
      showModal, handleSubmit, dossier, combos, dispatch, formValues,
      actions: {
        fetchClientByDni,
        fetchClientByDniEntityChild,
        fetchClientFromDocument,
        setEntityTypeId,
        setEntitySubTypeId,
        setEntityId,
        fetchEntitySubTypeCombo,
        fetchEntityFilterSubTypeCombo,
        setEntityChildTypeId,
        clearEntity,
        clearEntityChild,
        fetchSalesClient,
        setEntityValidationDisable,
        setActiveSection,
        closeDataContactFleetModal,
        fetchCommercialSocietyTypeCombo,
        fetchEntityCountryTypeCombo,
        fetchEntityStateTypeCombo,
        fetchEntityCityTypeCombo,
        fetchEntityTownTypeCombo,
        fetchEntityColonyTypeCombo,
        fetchEntityCPTypeCombo,
        fetchEntityMaritalStatusCombo,
        openModal,
        activityBproCombo,
        validate,
        stopAsyncValidation,
        fetchClientDataAddress
      }, change, clientFleetPermissions
    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.ENTITY.'

    if (showModal) {
      return (
        <Modal className="fleet-created-select-modal languages-modal" show={showModal} onHide={closeDataContactFleetModal} backdrop={'static'}>
          <form className='formDataContactFleet' autoComplete="off" onSubmit={handleSubmit((values) => this.saveData(values))}>
            <Modal.Header closeButton onHide={this.props.actions.closeActionDataModal}>
              <Modal.Title>   <i className="ico-user" />{t(`${tKey}CLIENT`)}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Client
                t={t}
                isClientLoaded = {true}
                dossier={dossier}
                combos={combos}
                entityChangeable={false}
                dossierType={dossierTypesConstant.dataContactFleetModal}
                actions={{
                  change,
                  fetchClientByDni,
                  fetchClientByDniEntityChild,
                  fetchClientFromDocument,
                  setEntityTypeId,
                  setEntitySubTypeId,
                  setEntityId,
                  fetchEntitySubTypeCombo,
                  fetchEntityFilterSubTypeCombo,
                  setEntityChildTypeId,
                  clearEntity,
                  clearEntityChild,
                  fetchSalesClient,
                  setEntityValidationDisable,
                  setActiveSection,
                  fetchCommercialSocietyTypeCombo,
                  fetchEntityCountryTypeCombo,
                  fetchEntityStateTypeCombo,
                  fetchEntityCityTypeCombo,
                  fetchEntityTownTypeCombo,
                  fetchEntityColonyTypeCombo,
                  fetchEntityCPTypeCombo,
                  fetchEntityMaritalStatusCombo,
                  openCommonModal: openModal,
                  activityBproCombo,
                  validate,
                  dispatch,
                  asyncValidate,
                  stopAsyncValidation: (value) => stopAsyncValidation('dataContactFleetModal', value),
                  fetchClientDataAddress
                }}
                readOnlyFields={false}
                open={true}
                componentConfiguration={clientFleetPermissions}
                disableDeleteButton ={false}
                handleToUpdateComponent= {null}
                updateComponentAfterDeleteAndRestart = {() => this.updateComponentAfterDeleteAndRestart()}
                isEditFleet = {true}
                formValues={formValues}
              />
            </Modal.Body>
            <Modal.Footer>
              <Col sm={12} className='fleet-created-moda-btn-exit'>
                <Button
                  id="btn_search"
                  className="btn-standard"
                  type='submit'>
                  {t('MANAGEMENT.FLEETS_TAB.AUX_MODALS.SAVE')}
                </Button>
                <Button
                  id='btn-close-fleet-created-select-charge-modal'
                  className="btn-exit"
                  onClick={() => closeDataContactFleetModal()}>
                  {t('MANAGEMENT.FLEETS_TAB.AUX_MODALS.EXIT')}
                </Button>
              </Col>
            </Modal.Footer>
          </form>
        </Modal>

      )
    } else {
      return null
    }
  }
}

const validate = (values, props) => {
  const errors = {}

  errors.entityComponent = validateEntity(values.entityComponent, props)

  if (props.registeredFields) {
    const entityChildHasData = values.entityChildComponent ? !isEntityChildEmpty(values.entityChildComponent, props.registeredFields) : false
    if (entityChildHasData) {
      errors.entityChildComponent = validateEntity(values.entityChildComponent, props, null, true)
    }
    const contactHasData = values.dossierContactComponent ? !isContactEmpty(values.dossierContactComponent, props.registeredFields) : false

    if (contactHasData) {
      errors.dossierContactComponent = validateContact(values.dossierContactComponent, props)
    }
  }

  return errors
}

const asyncValidate = (values, dispatch, props, field, returnError) => {
  let promises = []
  if (values.entityComponent) {
    promises = promises.concat(asyncValidateEntityDni(values.entityComponent, dispatch, props, props.fleet.organizedUnitId))
    promises = promises.concat(asyncValidateEntityDni(values.entityChildComponent, dispatch, props, props.fleet.organizedUnitId))
  }

  return Promise.allSettled(promises).then(result => {
    let error = {}
    for (let index = 0; index < result.length; index++) {
      const element = result[index]
      if (element.status === promisesStatus.FULFILLED) {
        switch (index) {
          case validationDataContactFleetModalPromisesOrder.ENTITY_COMPONENT:
            error = { ...error, entityComponent: element.value }
            break
          case validationDataContactFleetModalPromisesOrder.ENTITY_CHILD_COMPONENT:
            error = { ...error, entityChildComponent: element.value }
            break
          default:
            break
        }
      }
    }
    if (Object.keys(error).length > 0 && !returnError) throw Object.assign(new Error(), error)
    if (returnError) return error
  })
}

export default reduxForm({
  form: 'dataContactFleetModal',
  validate,
  asyncValidate,
  asyncBlurFields: [...getFieldsValidated('entityComponent'), ...getFieldsValidated('entityChildComponent')]
})(DataContactFleetModal)
