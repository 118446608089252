import React, { PureComponent } from 'react'
import { Row } from 'react-bootstrap'
import DocumentItem from './DocumentItem'

class SectionComponent extends PureComponent {
  render () {
    const {
      t,
      section, documentEntityType, readOnlyDoc = false, objectId,
      actions: { openDocumentEditionModalWithChecklistId, cancelDocument, addDependant, printCheckList, openModal }
    } = this.props
    return (
      <Row>
        <div className="title-module">
          <span>{section.name}</span>
        </div>
        <div className="list-document-uploaded-modal">
          {
            section.documents.map((documentFile, idx) => <DocumentItem
              t={t}
              key={idx}
              readOnly={readOnlyDoc}
              documentEntityType={documentEntityType}
              documentFile={documentFile}
              objectId={objectId}
              actions={{ openDocumentEditionModalWithChecklistId, cancelDocument, addDependant, printCheckList, openModal }}
            />)
          }
        </div>
      </Row>
    )
  }
}

export default SectionComponent
