import React, { PureComponent } from 'react'
import { Row, Col } from 'react-bootstrap'

class Tab2 extends PureComponent {
  render () {
    return (
      <Col sm={12}>
        <Row>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Chasis:</span>
              <span className="second">Chasis</span>
            </div>
          </Col>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Matricula:</span>
              <span className="second">Matricula</span>
            </div>
          </Col>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">V.S.B:</span>
              <span className="second">V.S.B</span>
            </div>
          </Col>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Marca:</span>
              <span className="second">Marca</span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Modelo:</span>
              <span className="second">Modelo</span>
            </div>
          </Col>
        </Row>
      </Col>
    )
  }
}

export default Tab2
