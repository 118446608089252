import apiFetch from '../apiFetch'

export default function (token, file, infoIncome, fleetDocumentDetails, documentTypeUseId, fleetId) {
  const queryParamsArray = [
    documentTypeUseId ? `documentTypeUseId=${documentTypeUseId}` : null,
    fleetId ? `fleetId=${fleetId}` : null
  ]
  const formData = new FormData()
  formData.append(file.name, file)
  formData.append('infoIncome', JSON.stringify(infoIncome))
  formData.append('fleetDocumentDetails', JSON.stringify(fleetDocumentDetails))

  const queryParams = queryParamsArray.reduce(
    (result, query, idx) => result + (query ? (result ? `&${query}` : `?${query}`) : '')
    , ''
  )
  return apiFetch({ endPoint: `UnattendedProcess/SetIncorporateDocument${queryParams}`, method: 'POST', body: formData, token: token, fileBody: true })
}
