
import React, { PureComponent } from 'react'
import { reduxForm } from 'redux-form'
import { Tabs, Tab } from 'react-bootstrap'
import ProcessingAgencyIntegrationDocTypeUsesMasterPage from '../../../../containers/masters/ProcessingAgency/processingAgencyIntegrations/ProcessingAgencyIntegrationDocTypeUsesMasterPage'

class ProcessingAgencyIntegrationDocumentsMasterModal extends PureComponent {
  constructor () {
    super()
    this.state = {
      filter: {
        page: 1,
        orderBy: 'operationTypeId',
        pageSize: 10
      }
    }
  }

  submitAgency (values) {
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.showModal !== this.props.showModal && nextProps.showModal === true) {
      this.props.initialize({ ...nextProps.agency })
    }
  }

  componentDidMount () {
    this.props.actions.fetchProcessingAgencyDocTypeUsesList(this.props.agency.processingAgencyId, { ...this.state.filter, ...this.props.docTypeUses?.filter }, false)
    this.props.actions.fetchDocumentTypeUseCombo()
    this.props.actions.fetchPaperworkType(null)
  }

  onSelectTab (tabKey) {
    switch (tabKey) {
      case 1:
        this.props.actions.fetchProcessingAgencyDocTypeUsesList(this.props.agency.processingAgencyId, { ...this.state.filter }, false)
        break
      case 2:
        this.props.actions.fetchProcessingAgencyDocTypeUsesList(this.props.agency.processingAgencyId, { ...this.state.filter }, true)
        break
      default:
        break
    }
  }

  render () {
    const {
      t
    } = this.props
    const tKey = 'MASTERS.PROCESSING_AGENCY_MASTERS.DOC_TYPE_USES.'
    return (
      <div className="masters-container search-wrapper" backdrop={'static'}>
        <Tabs defaultActiveKey={1} onSelect={this.onSelectTab.bind(this)} id="groups-tabs-1">
          <Tab eventKey={1} title={t(`${tKey}TO_SEND`)}>
            <ProcessingAgencyIntegrationDocTypeUsesMasterPage isReturnDocument={false}/>
          </Tab>
          <Tab eventKey={2} title={t(`${tKey}TO_RECEIVE`)}>
            <ProcessingAgencyIntegrationDocTypeUsesMasterPage isReturnDocument={true}/>
          </Tab>
        </Tabs>
      </div>
    )
  }
}

export default reduxForm({
  form: 'editDocuments',
  validate: (values, props) => {
  }
})(ProcessingAgencyIntegrationDocumentsMasterModal)
