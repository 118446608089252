export const documentEntityType = {
  ENTITY: 1,
  VEHICLE: 2,
  DOSSIER: 3,
  ENTITY_PROVIDER: 5
}

export const translateDocumentEntityType = {
  1: 'ENTITY',
  2: 'VEHICLE',
  3: 'DOSSIER',
  5: 'ENTITY_PROVIDER'
}
