import apiFetch from '../../apiFetch'

export default function (saleId, purchaseId, purchaseIsHistorical, token, lastAccessDate) {
  return apiFetch({
    endPoint: `/Dossier/Purchase/Uac/AssignToSale?saleId=${saleId}&purchaseId=${purchaseId}&purchaseIsHistorical=${purchaseIsHistorical}`,
    method: 'PUT',
    body: null,
    token: token,
    lastAccessDate
  })
}
