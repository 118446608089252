import React, { PureComponent } from 'react'
import { Row, Col, Button, DropdownButton } from 'react-bootstrap'
import { urgencyTypesId, brands, permissions, dashboardUploadExcel } from '../../../constants/backendIds'
import StagePie from '../../../components/dashboard/charts/StagePie'
import StagesBarChart from '../../../components/dashboard/charts/StagesBarChart'
import { reduxForm, Field } from 'redux-form'
import settings from '../../../setting'
import InputSelect from '../../../components/commons/form/InputSelect'
import { getSectionPermissions } from '../../../util/permisionFunctions'
import { sectionPermissions } from '../../../constants/dossier/common/buttonsHeader'

import UploadModalPage from '../../../_v2/containers/dashboard/Modals/UploadModalPage'
import Card from '../../../components/dashboard/Cards/Card'
import ExtraBoxPage from '../../../containers/dashboard/ExtraBoxPage'
import ExtraBoxTasksPage from '../../../containers/dashboard/ExtraBoxTasksPage'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAlignRight, faTh } from '@fortawesome/free-solid-svg-icons'
import MapSections from '../../util/map-sections_v2'
import Cookies from 'js-cookie'

class Dashboard extends PureComponent {
  constructor () {
    super()

    let versionV1 = settings.dashboardVersion === 1
    let cookieCardsSelected = Cookies.get('cardsSelected') === 'true'
    this.state = {
      salesmanId: null,
      loadDashboard: settings.autoLoadDashboard,
      firstLoad: true,
      cardsActive: !versionV1 && cookieCardsSelected,
      rowsActive: versionV1 || !cookieCardsSelected
    }

    Cookies.set('cardsSelected', this.state.cardsActive, { expires: 400 })

    this.handleNav = this.handleNav.bind(this)
  }

  componentDidMount () {
    if (this.state.firstLoad) {
      if (this.props.salesmanId) this.props.change('salesmanId', this.props.salesmanId)
    } else {
      if (this.state.salesmanId) this.props.change('salesmanId', this.state.salesmanId)
    }

    var organizedUnit = this.props.organizedUnit !== '0' ? this.props.organizedUnit : null
    if (settings.autoLoadDashboard) {
      this.props.actions.fetchDashboard(organizedUnit, this.props.salesmanId)
    } else {
      this.props.actions.fetchCreateDossierButton(organizedUnit)
      this.props.actions.fetchDashboardButtons()
    }

    this.initializePermisions(this.props)
  }

  componentDidUpdate () {
    // getSnapshotBeforeUpdate() should be used with componentDidUpdate()
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (this.props.salesmanId && JSON.stringify(this.props.salesmanId) !== JSON.stringify(prevProps.salesmanId)) {
      if (this.state.salesmanId) {
        this.props.change('salesmanId', this.state.salesmanId)
      } else if (!this.state.firstLoad) {
        this.props.change('salesmanId', this.props.salesmanId)
      }
    }
    if (this.props.organizedUnit && this.props.organizedUnit !== prevProps.organizedUnit) {
      this.props.actions.fetchDashboard(this.props.organizedUnit, this.props.salesmanId)
    }
    this.setState({ firstLoad: false })
    return this.props
  }

  initializePermisions (props) {
    const section = props.sections.filter((tabConf) => tabConf.code === sectionPermissions.dashboard)
    if (section.length > 0 && section[0] != null) {
      const newState = getSectionPermissions(section[0])
      this.setState({ sectionTabsConfiguration: newState })
    }
  }

  handleNav (val) {
    let urgencyTypeId
    let tKey = 'DASHBOARD.'
    switch (val) {
      case this.props.t(`${tKey}URGENCY_TYPES.HIGH`):
        urgencyTypeId = urgencyTypesId.high
        break
      case this.props.t(`${tKey}URGENCY_TYPES.MEDIUM`):
        urgencyTypeId = urgencyTypesId.medium
        break
      case this.props.t(`${tKey}URGENCY_TYPES.LOW`):
        urgencyTypeId = urgencyTypesId.low
        break
    }
    this.props.history.push(`/dashboard/${this.props.salesmanId || 0}/${urgencyTypeId}/0/0`)
  }

  selectSalesman (organizedUnit, salesmanIdValue) {
    organizedUnit = this.props.organizedUnit !== '0' ? this.props.organizedUnit : null
    salesmanIdValue.length > 0 ? this.setState({ salesmanId: salesmanIdValue }) : this.setState({ salesmanId: null })
    salesmanIdValue.length > 0 ? this.props.actions.fetchFilteredDashboard(organizedUnit, salesmanIdValue) : this.props.actions.fetchFilteredDashboard(organizedUnit, null)
  }

  loadDashboard () {
    this.setState({ loadDashboard: true })
    this.props.actions.fetchDashboard(this.props.organizedUnit, this.props.salesmanId)
  }

  showCards () {
    this.setState({ rowsActive: false, cardsActive: true })
    Cookies.set('cardsSelected', 'true', { expires: 400 })
  }

  showRows () {
    this.setState({ rowsActive: true, cardsActive: false })
    Cookies.set('cardsSelected', 'false', { expires: 400 })
  }

  render () {
    const {
      t, organizedUnit, salesmanId,
      dashboard: {
        total = 0, totalHigh = 0, totalMedium = 0, totalLow = 0, subTypes = [], canCreateDossier, canCreateDossierAudi, canCreateDossierSeat,
        canCreateDossierVW, canCreateDossierBuyAndSellVW, canCreateDossierBuyAndSellAudi, canCreateDossierExcelFleetQuadis
      },
      actions: { openUploadDocumentFleetModal }, combos: { completeSalesmanCombo }, defaultSalesmen
    } = this.props
    const secMap = MapSections.dashboard
    const tKey = 'DASHBOARD.'

    return (
      <div className="wrapper _nfd">
        <div className='wrapper-header'>
          <div className="title-page">
            <i className={secMap.icon + ' icon-page'} />
            <h2>
              {t(`${tKey}NAME`)}
            </h2>
          </div>
          <div className={'title-page-extra dashboard-header' + (canCreateDossier ? ' has-button' : '')}>
            { settings.dashboardVersion === 1 && !this.state.loadDashboard && (
              <Button className='_Btn accept' id="stage-load-dashboard" onClick={() => this.loadDashboard()} >{t(`${tKey}LOAD_DASHBOARD`)}</Button>
            )}
            {this.state.sectionTabsConfiguration && this.state.sectionTabsConfiguration.newDossier && this.state.sectionTabsConfiguration.newDossier.permission > permissions.hidden && canCreateDossier && (
              <a id="btn_observations" className="_Btn new" onClick={() => this.props.history.push('/new-dossier')}><i class="iDocIcon-add-folder-plus"></i>{t(`${tKey}NEW_DOSSIER`)}</a>
            )}
            {(canCreateDossierAudi || canCreateDossierSeat || canCreateDossierVW || canCreateDossierExcelFleetQuadis || canCreateDossierBuyAndSellVW || canCreateDossierBuyAndSellAudi) &&

                <DropdownButton
                  as={'ButtonGroup'}
                  key={1}
                  drop={'start'}
                  className={'_Btn new o-icn btnLong'}
                  title={<i class="iDocIcon-square-plus-show_more"></i> }
                  id="dropdown-button-drop-start"
                >
                  {canCreateDossierAudi && (
                    <li><a id="btn_observations" className="" onClick={() => openUploadDocumentFleetModal(organizedUnit, brands.AUDI, false)}>{t(`${tKey}BTN_AUDI_FLEET`)}<i class="iDocIcon-file-document"></i></a></li>
                  )}
                  {canCreateDossierSeat && (
                    <li><a id="btn_observations" className="" onClick={() => openUploadDocumentFleetModal(organizedUnit, brands.SEAT, false)}>{t(`${tKey}BTN_SEAT_FLEET`)}<i class="iDocIcon-file-document"></i></a></li>
                  )}
                  {canCreateDossierVW && (
                    <li><a id="btn_observations" className="" onClick={() => openUploadDocumentFleetModal(organizedUnit, brands.VW, false)}>{t(`${tKey}BTN_VW_FLEET`)}<i class="iDocIcon-file-document"></i></a></li>
                  )}
                  {canCreateDossierExcelFleetQuadis && (
                    <li><a id="btn_observations" className="" onClick={() => openUploadDocumentFleetModal(organizedUnit, null, null, dashboardUploadExcel.FLEET_QUADIS)}>{t(`${tKey}BTN_QUADIS_FLEET`)}<i class="iDocIcon-file-document"></i></a></li>
                  )}
                  {canCreateDossierBuyAndSellVW && (
                    <li><a id="btn_observations" className="" onClick={() => openUploadDocumentFleetModal(organizedUnit, brands.VW, true)}>{t(`${tKey}BTN_VW_BUY_AND_SELL`)}<i class="iDocIcon-file-document"></i></a></li>
                  )}
                  {canCreateDossierBuyAndSellAudi && (
                    <li><a id="btn_observations" className="" onClick={() => openUploadDocumentFleetModal(organizedUnit, brands.AUDI, true)}>{t(`${tKey}BTN_AUDI_BUY_AND_SELL`)}<i class="iDocIcon-file-document"></i></a></li>
                  )}
                </DropdownButton>

            }
          </div>
        </div>
        <div className='wrapper-body'>
          <Col sm={12} className="dashboard-wrapper">
            <div className='extraFunctions customScrollbar'>
              { settings.enableFavoritesAndRecents &&
                <ExtraBoxPage/>
              }
              { settings.enableTaskFollowUp &&
                <ExtraBoxTasksPage
                  organizedUnit= {organizedUnit}
                ></ExtraBoxTasksPage>
              }
            </div>
            {settings.dashboardVersion > 1 &&
            <Row className='statistics'>
              <a onClick={() => this.loadDashboard()} ><i className="iDocIcon-visibility-eye"></i> { this.state.loadDashboard ? t(`${tKey}RELOAD_STATISTICS`) : t(`${tKey}LOAD_STATISTICS`)}</a>
            </Row>}
            <div className='resume'>
              {this.state.loadDashboard &&
              <Col className="box sm-box">
                <div>
                  {this.state.loadDashboard && settings.clientFieldValidation !== 'porsche' &&
                  <Col sm={6}>
                    <Field
                      id="salesmanId"
                      name='salesmanId'
                      colSm={12}
                      controlLabel={t(`${tKey}SALESMAN_LABEL`)}
                      placeholder={t(`${tKey}SALESMAN_PLACEHOLDER`)}
                      options={completeSalesmanCombo}
                      component={InputSelect}
                      multi={true}
                      valueKey="id"
                      labelKey="value"
                      onInputChange={(salesmanIdValue) => this.selectSalesman(organizedUnit, salesmanIdValue)}
                      customClass='dashboard-select'
                    />
                  </Col> }
                  <Col sm={6}>
                    <StagePie
                      t={t}
                      data={{
                        total: total,
                        urgencyChartList: [
                          { total: totalHigh, urgencyTypeName: t(`${tKey}URGENCY_TYPES.HIGH`) },
                          { total: totalMedium, urgencyTypeName: t(`${tKey}URGENCY_TYPES.MEDIUM`) },
                          { total: totalLow, urgencyTypeName: t(`${tKey}URGENCY_TYPES.LOW`) }
                        ]
                      }}
                      showChart={this.state.loadDashboard}
                      navPieFunction={this.handleNav}
                      salesmanId={salesmanId}
                    />
                  </Col>
                </div>
              </Col>}
            </div>
            {this.state.loadDashboard && (settings.dashboardVersion > 1) && <Row className='switchBtnContainer'>
              <a onClick={() => this.showCards()} className={'switch-cards ' + (this.state.cardsActive === true ? 'active' : '')}><FontAwesomeIcon icon={faTh} /></a>
              <a onClick={() => this.showRows()} className={'switch-columns ' + (this.state.rowsActive === true ? 'active' : '')}><FontAwesomeIcon icon={faAlignRight} /></a>

            </Row>}
            {this.state.loadDashboard && this.state.cardsActive && <Row>
              <div className='cardContainer'>
                {
                  subTypes.map((subType, idx) => <Card
                    t={t}
                    urgencyLowLabel={t(`${tKey}URGENCY_TYPES.LOW`)}
                    urgencyMediumLabel={t(`${tKey}URGENCY_TYPES.MEDIUM`)}
                    urgencyHighLabel={t(`${tKey}URGENCY_TYPES.HIGH`)}
                    salesmanId={salesmanId}
                    key={idx}
                    subType={subType}
                    history={this.props.history}
                    defaultSalesmen={defaultSalesmen}
                  />)
                }
              </div>
            </Row> }
            {this.state.loadDashboard && this.state.rowsActive && <Row>
              <Col sm={12}>
                {
                  subTypes.map((subType, idx) => <StagesBarChart
                    t={t}
                    urgencyLowLabel={t(`${tKey}URGENCY_TYPES.LOW`)}
                    urgencyMediumLabel={t(`${tKey}URGENCY_TYPES.MEDIUM`)}
                    urgencyHighLabel={t(`${tKey}URGENCY_TYPES.HIGH`)}
                    salesmanId={salesmanId}
                    key={idx}
                    subType={subType}
                    history={this.props.history}
                    defaultSalesmen={defaultSalesmen}
                  />)
                }
              </Col>
            </Row>}

          </Col>
        </div>
        <UploadModalPage />
      </div>
    )
  }
}

export default reduxForm({
  form: 'DashboardForm'
})(Dashboard)
