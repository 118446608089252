import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { openCloseUserRecurrentServiceModal, getUserRecurrentServiceEntity, openCloseAddUserRecurrentServiceModal, fillFilterFieldAddUserRecurrentServiceModal, clearUserAdminSearchFilterModal, setUserRecurrentServiceEntity } from '../../../actions/admin/recurrentEntityAdmin'
import SearchUserRecurrentEntityModal from '../../../components/admin/recurrentEntity/SearchUserRecurrentEntityModal'
import { openModal } from '../../../actions/common'
import { fetchUserList, clearUserAdminSearchFilter } from '../../../actions/admin/userAdmin'

export function mapStateToProps (state) {
  const searchUserRecurrentEntityModal = state.recurrentEntityAdmin.searchUserRecurrentEntityModal
  return {
    ...searchUserRecurrentEntityModal,
    userAdmin: state.userAdmin
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      getUserRecurrentServiceEntity,
      openCloseUserRecurrentServiceModal,
      openCloseAddUserRecurrentServiceModal,
      openModal,
      fillFilterFieldAddUserRecurrentServiceModal,
      fetchUserList,
      clearUserAdminSearchFilter,
      clearUserAdminSearchFilterModal,
      setUserRecurrentServiceEntity
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(SearchUserRecurrentEntityModal))
