import React, { PureComponent } from 'react'
import { Row, Clearfix, Button } from 'react-bootstrap'
import { Field } from 'redux-form'
import InputText from '../commons/form/InputText'
import InputSelect from '../commons/form/InputSelect'
import InputDatePicker from '../commons/form/InputDatePicker'
import { validateDate } from '../../util/validationFunctions'
import { multipleNormalize, toUpperCase, trimAll } from '../../util/normalizeFunctions'
import settings from '../../setting'
import { clientInformation, urgencyTypesClass, permissions } from '../../constants/backendIds'
import SimpleTablePage from '../../containers/commons/general/SimpleTablePage'
import IDocCarPagination from '../commons/iDocCarPagination'
import { Link } from 'react-router-dom'
import EntityPopover from './EntityPopover'
import VehiclePopover from './VehiclePopover'
import { getFieldsSectionPermissions } from '../../util/permisionFunctions'
import DownloadModalPage from '../../containers/search/dossier/downloadModal/DownloadModalPage'

class SearchByDossierForm extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      fields: {},
      lastDossierSubtype: null,
      fieldsConfiguration: {
        findByResponsible: null,
        stockStatusId: null,
        rentalOrderIdNumber: null,
        operationTypeDMSId: null,
        downloadSearchBtn: null
      }
    }
  }

  onDossierSubtypeChange (dossierSubtypeId) {
    this.props.change('dossier.stage', null)
    this.props.change('dossier.saleTypeId', null)
    this.props.change('dossier.externalOrderId', null)
    this.props.change('dossier.operationTypeId', null)
    this.props.change('dossier.purchaseTypeId', null)

    this.props.actions.fetchStagesCombo(dossierSubtypeId)
    this.props.actions.fetchSaleTypeBySubtype(dossierSubtypeId, !dossierSubtypeId)
    this.props.actions.fetchPurchaseType(dossierSubtypeId)
    this.props.actions.fetchOperationType(null, null, null, false)
    this.props.actions.fetchPaymentMethodCombo(dossierSubtypeId)
  }

  onSaleTypeChange (saleTypeId) {
    this.props.change('dossier.operationTypeId', null)
    this.props.actions.fetchOperationType(saleTypeId, null, null)
  }

  componentDidMount () {
    this.props.actions.fetchSearchDossierSubType()
    if (this.props.permissions && this.props.permissions.sectionFieldsConfiguration) {
      const fieldKeys = Object.keys(this.state.fieldsConfiguration)
      const permission = getFieldsSectionPermissions(this.props.permissions.sectionFieldsConfiguration, fieldKeys, this.props.permissions.permission)
      this.setState({ fieldsConfiguration: permission.fieldsConfiguration }, () => {
        if ((this.props.combos.usersCombo < 1 &&
              this.state.fieldsConfiguration.findByResponsible &&
              this.state.fieldsConfiguration.findByResponsible.permission > permissions.hidden) ||
            settings.clientFieldValidation === clientInformation.PORSCHE) {
          this.props.actions.fetchUserCombo()
        }
      })

      if (permission.fieldsConfiguration.stockStatusId.permission > permissions.hidden) {
        this.props.actions.fetchStockStatusCombo()
      }
      if (permission.fieldsConfiguration.operationTypeDMSId.permission > permissions.hidden) {
        this.props.actions.fetchOperationTypeDMS()
      }
    }
  }

  validateDate (value, allValues, props) {
    const result = validateDate(value, props)
    return result === Object(result) ? undefined : result
  }

  getYesNoComboOptions () {
    const tKey = 'SEARCH.CATEGORIES.DOSSIER.'
    return [
      { value: this.props.t(`${tKey}YES`), id: true },
      { value: this.props.t(`${tKey}NO`), id: false }]
  }

  exportData () {
    const tKey = 'SEARCH.CATEGORIES.DOSSIER.'
    let columnsExcel = []
    columnsExcel.push(this.props.t(`${tKey}URGENCY`) + '#Urgency')
    columnsExcel.push(this.props.t(`${tKey}DOSSIER_NUMBER`) + '#Number')
    columnsExcel.push(this.props.t(`${tKey}ORGANIZED_UNIT`) + '#Uo')
    columnsExcel.push(this.props.t('SEARCH.CATEGORIES.ENTITY.BY_ENTITY') + '#Entity')
    columnsExcel.push(this.props.t('SEARCH.CATEGORIES.VEHICLE.BY_VEHICLE') + '#Vehicle')
    columnsExcel.push(this.props.t(`${tKey}DOSSIER_SUBTYPE`) + '#SubType')
    columnsExcel.push(this.props.t(`${tKey}STAGE`) + '#Stage')
    if (this.props.showCampaignColumnsInExcel) {
      columnsExcel.push(this.props.t(`${tKey}DEALER_NUMBER`) + '#CampaignDealerNumber')
      columnsExcel.push(this.props.t(`${tKey}CAMPAIGN_GROUP_CODE`) + '#CampaignGroupCode')
      columnsExcel.push(this.props.t(`${tKey}CAMPAIGN_GROUP`) + '#CampaignGroup')
      columnsExcel.push(this.props.t(`${tKey}CAMPAIGN_TYPE_CODE`) + '#CampaignTypeCode')
      columnsExcel.push(this.props.t(`${tKey}CAMPAIGN_TYPE`) + '#CampaignType')
      columnsExcel.push(this.props.t(`${tKey}CAMPAIGN_CODE`) + '#CampaignDetailsCode')
      columnsExcel.push(this.props.t(`${tKey}CAMPAIGN_DESCRIPTION`) + '#CampaignDescription')
      columnsExcel.push(this.props.t(`${tKey}CAMPAIGN_PERCENTAGE_BRAND`) + '#CampaignPercentageBrand')
      columnsExcel.push(this.props.t(`${tKey}CAMPAIGN_AMOUNT_BRAND`) + '#CampaignAmountBrand')
      columnsExcel.push(this.props.t(`${tKey}CAMPAIGN_AMOUNT_REQUESTED`) + '#CampaignAmountRequested')
      columnsExcel.push(this.props.t(`${tKey}CAMPAIGN_AMOUNT_APPROVED`) + '#CampaignAmountApproved')
      columnsExcel.push(this.props.t(`${tKey}CAMPAIGN_PAYMENT_METHOD`) + '#CampaignPaymentMethod')
      columnsExcel.push(this.props.t(`${tKey}CAMPAIGN_BILL_DATE`) + '#CampaignBillDate')
      columnsExcel.push(this.props.t(`${tKey}CAMPAIGN_BILL_NUMBER`) + '#CampaignBillNumber')
      columnsExcel.push(this.props.t(`${tKey}CAMPAIGN_CHECKER`) + '#CampaignChecker')
      columnsExcel.push(this.props.t(`${tKey}CAMPAIGN_CHECK_DATE`) + '#CampaignCheckDate')
    }
    if (settings.clientFieldValidation === clientInformation.QUADIS) {
      columnsExcel.push(this.props.t(`${tKey}CREATOR_ID`) + '#Creator')
      columnsExcel.push(this.props.t(`${tKey}SALESMAN_MANAGER`) + '#SalesManagerName')
    }
    columnsExcel.push(this.props.t(`${tKey}CREATION_DATE`) + '#CreationDate')
    columnsExcel.push(this.props.t(`${tKey}URL`) + '#UrlToNavigate')
    this.props.exportToExcel(columnsExcel)
  }

  openDownloadModal () {
    const fields = this.props.fields
    const body = {
      page: fields.page,
      pageSize: fields.pageSize,
      orderBy: fields.orderBy,
      dossierSubTypeId: fields.dossierSubtype,
      number: fields.number,
      load: fields.load,
      organizedUnitId: fields.organizedUnit,
      dossierStageId: fields.stage?.join(', '),
      creationDateFrom: fields.creationDateFrom,
      creationDateTo: fields.creationDateTo,
      entityName: fields.name,
      entityCifNif: fields.dniCif,
      entityMail: fields.email,
      vehicleVinChasis: fields.chassis,
      vehicleLicensePlate: fields.licensePlate,
      vehicleLicensePlateDateFrom: fields.vehicleLicensePlateDateFrom,
      vehicleLicensePlateDateTo: fields.vehicleLicensePlateDateTo,
      vehicleModel: fields.model,
      dealerNumber: fields.dealerNumber,
      orderDateFrom: fields.orderDateFrom,
      orderDateTo: fields.orderDateTo,
      salesmanId: fields.salesman?.join(', '),
      salesmanIdManager: fields.salesmanManager?.join(', '),
      orderMakerNumber: fields.orderMakerNumber,
      makerNumber: fields.makerNumber,
      availabilityDateFrom: fields.availabilityDateFrom,
      availabilityDateTo: fields.availabilityDateTo,
      rentalOrderNumber: fields.rentalOrderNumber,
      userId: fields.userId,
      saleTypeId: fields.saleTypeId,
      purchaseTypeId: fields.purchaseTypeId,
      operationTypeId: fields.operationTypeId,
      stockStatusId: fields.stockStatusId,
      creatorId: fields.creatorId,
      isDenied: fields.isDenied,
      proformInvoiceEmited: fields.proformInvoiceEmited,
      externalOrderId: fields.externalOrderId,
      paymentMethodId: fields.paymentMethod?.join(', '),
      operationTypeDMSId: fields.operationTypeDMSId?.join(', ')
    }
    this.props.actions.openDownloadModal(body)
  }

  render () {
    const {
      t, fields, fieldFill, className, classNameSelect, selectedFilters, hasSearch, onClickResetSearch, onOrderChange, getIconClass,
      dossierData, vehicleData, onPageChange, page, pages, count,
      combos: {
        stagesCombo, lowestOrganizedUnitsCombo, salesmanCombo, searchDossierSubtypesCombo, usersCombo, saleTypeCombo,
        operationTypeCombo, purchaseTypeCombo, stockStatusCombo, paymentMethodCombo, operationTypeDMSCombo
      }
    } = this.props
    const tKey = 'SEARCH.CATEGORIES.DOSSIER.'
    var disableOperationType = this.props.fields.saleTypeId !== null && this.props.fields.saleTypeId !== undefined
    return (
      <div>
        <DownloadModalPage/>
        {!hasSearch ? (
          <Row className='flexRow'>
            <Field
              id="dealerIdNumberDossier"
              name='dossier.dealerNumber'
              placeholder={t(`${tKey}DEALER_NUMBER`)}
              controlLabel={t(`${tKey}DEALER_NUMBER`)}
              component={InputText}
              type="text"
              maxLength={settings.dealerMaxLength}
              onInputChange={(val) => fieldFill({ dealerIdNumber: val })}
              customClass={fields.dealerIdNumber ? className : ''}
            />
            <Field
              id="searchMakerNumber"
              name='dossier.makerNumber'
              placeholder={t(`${tKey}MAKER_NUMBER`)}
              controlLabel={t(`${tKey}MAKER_NUMBER`)}
              component={InputText}
              type="text"
              onInputChange={(val) => fieldFill({ makerNumber: val })}
              customClass={fields.makerNumber ? className : ''}
            />
            { settings.clientFieldValidation === clientInformation.VGR &&
            <Field
              id="orderMakerNumber"
              name='dossier.orderMakerNumber'
              placeholder={t(`${tKey}ORDER_MAKER_NUMBER`)}
              controlLabel={t(`${tKey}ORDER_MAKER_NUMBER`)}
              component={InputText}
              type="text"
              onInputChange={(val) => fieldFill({ orderMakerNumber: val })}
              customClass={fields.orderMakerNumber ? className : ''}
            />}
            <Field
              id="numberDossier"
              name='dossier.dossierNumber'
              placeholder={t(`${tKey}DOSSIER_NUMBER`)}
              controlLabel={t(`${tKey}DOSSIER_NUMBER`)}
              component={InputText}
              type="text"
              onInputChange={(val) => fieldFill({ number: val })}
              customClass={fields.number ? className : ''}
            />
            <Field
              id="organizedUnit"
              name='dossier.organizedUnit'
              placeholder={t(`${tKey}ORGANIZED_UNIT`)}
              controlLabel={t(`${tKey}ORGANIZED_UNIT`)}
              component={InputSelect}
              options={lowestOrganizedUnitsCombo}
              valueKey="id"
              labelKey="value"
              onInputChange={ (val) => { fieldFill({ organizedUnit: val }) } }
              customClass={fields.organizedUnit ? classNameSelect : ''}
            />
            <Field
              id="dossierSubtype"
              name='dossier.dossierSubtype'
              placeholder={t(`${tKey}DOSSIER_SUBTYPE`)}
              controlLabel={t(`${tKey}DOSSIER_SUBTYPE`)}
              component={InputSelect}
              options={searchDossierSubtypesCombo}
              valueKey="id"
              labelKey="value"
              onInputChange={ (val) => { this.onDossierSubtypeChange(val); fieldFill({ dossierSubtype: val, saleTypeId: null, purchaseTypeId: null, operationTypeId: null }) } }
              customClass={fields.dossierSubtype ? classNameSelect : ''}
            />
            <Field
              id="stage"
              name='dossier.stage'
              placeholder={t(`${tKey}STAGE`)}
              controlLabel={t(`${tKey}STAGE`)}
              component={InputSelect}
              multi
              menuContainerStyle={{ zIndex: 999 }}
              options={stagesCombo}
              valueKey="id"
              labelKey="value"
              disabled={stagesCombo.length === 0}
              onInputChange={(val) => fieldFill({ stage: val })}
              customClass={fields.dossierSubType && fields.stage ? classNameSelect : ''}
            />
            <Field
              id="saleTypeId"
              name='dossier.saleTypeId'
              placeholder={t(`${tKey}SALE_TYPE_ID`)}
              controlLabel={t(`${tKey}SALE_TYPE_ID`)}
              component={InputSelect}
              menuContainerStyle={{ zIndex: 999 }}
              options={saleTypeCombo}
              valueKey="id"
              labelKey="value"
              disabled={!saleTypeCombo || saleTypeCombo.length === 0}
              onInputChange={ (val) => { this.onSaleTypeChange(val); setTimeout(() => { fieldFill({ saleTypeId: val, operationTypeId: null }) }, 100) } }
              customClass={fields.saleTypeId ? classNameSelect : ''}
            />
            <Field
              id="externalOrderId"
              name='dossier.externalOrderId'
              placeholder={t(`${tKey}EXTERNAL_ORDER_ID`)}
              controlLabel={t(`${tKey}EXTERNAL_ORDER_ID`)}
              component={InputText}
              type="text"
              maxLength={20}
              disabled={!saleTypeCombo || saleTypeCombo.length === 0}
              onInputChange={(val) => fieldFill({ externalOrderId: val })}
              customClass={fields.externalOrderId ? className : ''}
            />
            { (this.state.fieldsConfiguration.operationTypeDMSId && this.state.fieldsConfiguration.operationTypeDMSId.permission > permissions.hidden) === false &&
            <Field
              id="operationTypeId"
              name='dossier.operationTypeId'
              placeholder={t(`${tKey}OPERATION_TYPE_ID`)}
              controlLabel={t(`${tKey}OPERATION_TYPE_ID`)}
              component={InputSelect}
              menuContainerStyle={{ zIndex: 999 }}
              options={operationTypeCombo}
              valueKey="id"
              labelKey="value"
              disabled={!disableOperationType || !operationTypeCombo || operationTypeCombo.length === 0}
              onInputChange={(val) => fieldFill({ operationTypeId: val })}
              customClass={fields.operationTypeId ? classNameSelect : ''}
            />}
            <Field
              id="purchaseTypeId"
              name='dossier.purchaseTypeId'
              placeholder={t(`${tKey}PURCHASE_TYPE_ID`)}
              controlLabel={t(`${tKey}PURCHASE_TYPE_ID`)}
              component={InputSelect}
              menuContainerStyle={{ zIndex: 999 }}
              options={purchaseTypeCombo}
              valueKey="id"
              labelKey="value"
              disabled={!purchaseTypeCombo || purchaseTypeCombo.length === 0}
              onInputChange={(val) => fieldFill({ purchaseTypeId: val })}
              customClass={fields.purchaseTypeId ? classNameSelect : ''}
            />
            <Field
              id="salesman"
              name='dossier.salesman'
              placeholder={t(`${tKey}SALESMAN`)}
              controlLabel={t(`${tKey}SALESMAN`)}
              component={InputSelect}
              options={salesmanCombo}
              multi={true}
              valueKey="id"
              labelKey="value"
              onInputChange={(val) => fieldFill({ salesman: val })}
              customClass={fields.salesman ? classNameSelect : ''}
            />
            <Field
              id="salesmanManager"
              name='dossier.salesmanManager'
              placeholder={t(`${tKey}SALESMAN_MANAGER`)}
              controlLabel={t(`${tKey}SALESMAN_MANAGER`)}
              component={InputSelect}
              options={salesmanCombo}
              multi={true}
              valueKey="id"
              labelKey="value"
              onInputChange={(val) => fieldFill({ salesmanManager: val })}
              customClass={fields.salesmanManager ? classNameSelect : ''}
            />
            {settings.clientFieldValidation !== clientInformation.VGR && settings.clientFieldValidation !== clientInformation.QUADIS
              ? <Field
                id="paymentMethod"
                name='dossier.paymentMethod'
                placeholder={t(`${tKey}PAYMENT_METHOD`)}
                controlLabel={t(`${tKey}PAYMENT_METHOD`)}
                component={InputSelect}
                options={paymentMethodCombo}
                disabled={!paymentMethodCombo || paymentMethodCombo.length === 0}
                multi={true}
                valueKey="id"
                labelKey="value"
                onInputChange={(val) => fieldFill({ paymentMethod: val })}
                customClass={fields.paymentMethod ? classNameSelect : ''}
              /> : ''}
            <Field
              id="creationDateFrom"
              name='dossier.creationDateFrom'
              placeholder={t(`${tKey}CREATION_DATE_FROM`)}
              controlLabel={t(`${tKey}CREATION_DATE_FROM`)}
              component={InputDatePicker}
              validate={[this.validateDate]}
              onInputChange={(val) => fieldFill({ creationDateFrom: val })}
              customClass={fields.creationDateFrom ? className : ''}
            />
            <Field
              id="creationDateTo"
              name='dossier.creationDateTo'
              placeholder={t(`${tKey}CREATION_DATE_TO`)}
              controlLabel={t(`${tKey}CREATION_DATE_TO`)}
              component={InputDatePicker}
              validate={[this.validateDate]}
              onInputChange={(val) => fieldFill({ creationDateTo: val })}
              customClass={fields.creationDateTo ? className : ''}
            />
            <Field
              id="orderDateFrom"
              name='dossier.orderDateFrom'
              placeholder={t(`${tKey}ORDER_DATE_FROM`)}
              controlLabel={t(`${tKey}ORDER_DATE_FROM`)}
              component={InputDatePicker}
              validate={[this.validateDate]}
              onInputChange={(val) => fieldFill({ orderDateFrom: val })}
              customClass={fields.orderDateFrom ? className : ''}
            />
            <Field
              id="orderDateTo"
              name='dossier.orderDateTo'
              placeholder={t(`${tKey}ORDER_DATE_TO`)}
              controlLabel={t(`${tKey}ORDER_DATE_TO`)}
              component={InputDatePicker}
              validate={[this.validateDate]}
              onInputChange={(val) => fieldFill({ orderDateTo: val })}
              customClass={fields.orderDateTo ? className : ''}
            />
            <Field
              id="availabilityDateFrom"
              name='dossier.availabilityDateFrom'
              placeholder={t(`${tKey}AVAILABILITY_DATE_FROM`)}
              controlLabel={t(`${tKey}AVAILABILITY_DATE_FROM`)}
              component={InputDatePicker}
              validate={[this.validateDate]}
              onInputChange={(val) => fieldFill({ availabilityDateFrom: val })}
              customClass={fields.availabilityDateFrom ? className : ''}
            />
            <Field
              id="availabilityDateTo"
              name='dossier.availabilityDateTo'
              placeholder={t(`${tKey}AVAILABILITY_DATE_TO`)}
              controlLabel={t(`${tKey}AVAILABILITY_DATE_TO`)}
              component={InputDatePicker}
              validate={[this.validateDate]}
              onInputChange={(val) => fieldFill({ availabilityDateTo: val })}
              customClass={fields.availabilityDateTo ? className : ''}
            />
            <Field
              id="clientNameDossier"
              name='dossier.name'
              placeholder={t(`${tKey}NAME`)}
              controlLabel={t(`${tKey}NAME`)}
              component={InputText}
              type="text"
              onInputChange={(val) => fieldFill({ clientName: val })}
              customClass={fields.clientName ? className : ''}
            />
            <Field
              id="dniCif"
              name='dossier.dniCif'
              placeholder={t(`${tKey}DNI_CIF`)}
              controlLabel={t(`${tKey}DNI_CIF`)}
              component={InputText}
              type="text"
              onInputChange={(val) => fieldFill({ dniCif: val })}
              customClass={fields.dniCif ? className : ''}
            />
            <Field
              id="emailDossier"
              name='dossier.email'
              placeholder={t(`${tKey}EMAIL`)}
              controlLabel={t(`${tKey}EMAIL`)}
              component={InputText}
              type="text"
              onInputChange={(val) => fieldFill({ email: val })}
              customClass={fields.email ? className : ''}
            />
            <Field
              id="chassisDossier"
              name='dossier.chassis'
              placeholder={t(`${tKey}CHASSIS`)}
              controlLabel={t(`${tKey}CHASSIS`)}
              component={InputText}
              type="text"
              maxLength={17}
              normalize={value => multipleNormalize(value, [toUpperCase, trimAll])}
              onInputChange={(val) => fieldFill({ chassis: val })}
              customClass={fields.chassis ? className : ''}
            />
            <Field
              id="licencePlate"
              name='dossier.licensePlate'
              placeholder={t(`${tKey}LICENSE_PLATE`)}
              controlLabel={t(`${tKey}LICENSE_PLATE`)}
              component={InputText}
              type="text"
              normalize={value => multipleNormalize(value, [toUpperCase, trimAll])}
              onInputChange={(val) => fieldFill({ licencePlate: val })}
              customClass={fields.licencePlate ? className : ''}
            />
            <Field
              id="modelDossier"
              name='dossier.model'
              placeholder={t(`${tKey}MODEL`)}
              controlLabel={t(`${tKey}MODEL`)}
              component={InputText}
              type="text"
              onInputChange={(val) => fieldFill({ model: val })}
              customClass={fields.model ? className : ''}
            />
            <Field
              id="vehicleLicensePlateDateFrom"
              name='dossier.vehicleLicensePlateDateFrom'
              placeholder={t(`${tKey}VEHICLE_LICENSE_PLATE_DATE_FROM`)}
              controlLabel={t(`${tKey}VEHICLE_LICENSE_PLATE_DATE_FROM`)}
              component={InputDatePicker}
              validate={[this.validateDate]}
              onInputChange={(val) => fieldFill({ vehicleLicensePlateDateFrom: val })}
              customClass={fields.vehicleLicensePlateDateFrom ? className : ''}
            />
            <Field
              id="vehicleLicensePlateDateTo"
              name='dossier.vehicleLicensePlateDateTo'
              placeholder={t(`${tKey}VEHICLE_LICENSE_PLATE_DATE_TO`)}
              controlLabel={t(`${tKey}VEHICLE_LICENSE_PLATE_DATE_TO`)}
              component={InputDatePicker}
              validate={[this.validateDate]}
              onInputChange={(val) => fieldFill({ vehicleLicensePlateDateTo: val })}
              customClass={fields.vehicleLicensePlateDateTo ? className : ''}
            />
            {this.state.fieldsConfiguration.rentalOrderIdNumber && this.state.fieldsConfiguration.rentalOrderIdNumber.permission > permissions.hidden &&
            <Field
              id="rentalOrderIdNumber"
              name='dossier.rentalOrderNumber'
              placeholder={t(`${tKey}RENTALORDER_NUMBER`)}
              controlLabel={t(`${tKey}RENTALORDER_NUMBER`)}
              component={InputText}
              type="text"
              onInputChange={(val) => fieldFill({ rentalOrderIdNumber: val })}
              customClass={fields.rentalOrderIdNumber ? className : ''}
            />
            }
            { this.state.fieldsConfiguration.findByResponsible && this.state.fieldsConfiguration.findByResponsible.permission > permissions.hidden &&
            <Field
              id="userId"
              name='dossier.userId'
              placeholder={t(`${tKey}USER_ID`)}
              controlLabel={t(`${tKey}USER_ID`)}
              component={InputSelect}
              options={usersCombo}
              valueKey="id"
              labelKey="value"
              onInputChange={(val) => fieldFill({ userId: val })}
              customClass={fields.userId ? className : ''}
            />
            }
            { this.state.fieldsConfiguration.stockStatusId && this.state.fieldsConfiguration.stockStatusId.permission > permissions.hidden &&
              <Field
                id="stockStatusId"
                name='dossier.stockStatusId'
                placeholder={t(`${tKey}STOCK_STATUS_ID`)}
                controlLabel={t(`${tKey}STOCK_STATUS_ID`)}
                component={InputSelect}
                options={stockStatusCombo}
                valueKey="id"
                labelKey="value"
                onInputChange={ (val) => { fieldFill({ stockStatusId: val }) } }
                customClass={fields.stockSTatusId ? classNameSelect : ''}
              />
            }
            { this.state.fieldsConfiguration.operationTypeDMSId && this.state.fieldsConfiguration.operationTypeDMSId.permission > permissions.hidden && (
              <Field
                id="operationTypeDMSId"
                name="dossier.operationTypeDMSId"
                controlLabel={t(`${tKey}OPERATION_TYPE_DMS_ID`)}
                placeholder={t(`${tKey}OPERATION_TYPE_DMS_ID`)}
                valueKey="id"
                labelKey="description"
                component={InputSelect}
                colSm={3}
                options={operationTypeDMSCombo}
                onInputChange={ (val) => { fieldFill({ operationTypeDMSId: val }) } }
                multi={true}
              />)}
            { settings.clientFieldValidation === clientInformation.PORSCHE &&
              <Field
                id="creatorId"
                name='dossier.creatorId'
                placeholder={t(`${tKey}CREATOR_ID`)}
                controlLabel={t(`${tKey}CREATOR_ID`)}
                component={InputSelect}
                options={usersCombo}
                valueKey="id"
                labelKey="value"
                onInputChange={ (val) => { fieldFill({ creatorId: val }) } }
                customClass={fields.userId ? className : ''}
              />
            }

            { settings.clientFieldValidation === clientInformation.PORSCHE &&

              <Field
                id="isDenied"
                name='dossier.isDenied'
                placeholder={t(`${tKey}IS_DENIED`)}
                controlLabel={t(`${tKey}IS_DENIED`)}
                component={InputSelect}
                options={this.getYesNoComboOptions()}
                valueKey="id"
                labelKey="value"
                onInputChange={ (val) => { fieldFill({ isDenied: val }) } }
                customClass={fields.userId ? className : ''}
              />
            }

            { settings.clientFieldValidation === clientInformation.PORSCHE &&

            <Field
              id="proformInvoiceEmited"
              name='dossier.proformInvoiceEmited'
              placeholder={t(`${tKey}PROFORM_INVOICE_EMITED`)}
              controlLabel={t(`${tKey}PROFORM_INVOICE_EMITED`)}
              component={InputSelect}
              options={this.getYesNoComboOptions()}
              valueKey="id"
              labelKey="value"
              onInputChange={ (val) => { fieldFill({ proformInvoiceEmited: val }) } }
              customClass={fields.userId ? className : ''}
            />
            }

          </Row>
        ) : ([
          <Row className="filters-selected" key="filters-selected">
            <ul className="list-inline pull-left">
              {selectedFilters().length === 0 ? <li>{t(`${tKey}NO_SELECTED_FILTERS`)}</li> : (selectedFilters())}
            </ul>
            <Clearfix/>
            <Button
              onClick={(event) => onClickResetSearch(event)}
              bsStyle="default"
              bsSize="small"
              className="pull-left"
              id='modSearchButtonIdDossier'
            >
              {t('SEARCH.ACTIONS.MODIFY_SEARCH')}
              <i className="ico-edit-turquoise" />
            </Button>
            {count > 0 &&
            <Button
              onClick={(event) => this.exportData(event)}
              bsStyle="default"
              bsSize="small"
              id='expSearchButtonIdDossier'>
              {t('SEARCH.ACTIONS.EXPORT')}
              <i className="ico-if_Download_1031520" />
            </Button>
            }
            {count > 0 &&
             this.state.fieldsConfiguration.downloadSearchBtn &&
             this.state.fieldsConfiguration.downloadSearchBtn.permission > permissions.hidden &&
            <Button
              onClick={(event) => this.openDownloadModal()}
              bsStyle="default"
              bsSize="small"
              className="pull-right"
              id='downloadBtn'>
              {t(`${tKey}DOWNLOAD_BTN`)}
              <i className="iDocIcon-cloud-download-alt-solid" />
            </Button>
            }
          </Row>,
          <div className="table-wrapper" key="table-wrapper">
            <SimpleTablePage
              columns={[
                <th key={0} />,
                <th key={1} data-field="number" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}DOSSIER_NUMBER`)}&nbsp;<i className={getIconClass('number')}/>
                </th>,
                <th key={2} data-field="organizedUnit" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}ORGANIZED_UNIT`)}&nbsp;<i className={getIconClass('organizedUnit')}/>
                </th>,
                <th key={3} data-field="entity" onClick={(event) => onOrderChange(event)}>
                  {t('SEARCH.CATEGORIES.ENTITY.BY_ENTITY')}&nbsp;<i className={getIconClass('entity')}/>
                </th>,
                <th key={4}>{t('SEARCH.CATEGORIES.VEHICLE.BY_VEHICLE')}</th>,
                <th key={5} data-field="dossierSubType" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}DOSSIER_SUBTYPE`)}&nbsp;<i className={getIconClass('dossierSubType')}/>
                </th>,
                <th key={6} data-field="stage" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}STAGE`)}&nbsp;<i className={getIconClass('stage')}/>
                </th>,
                settings.clientFieldValidation === clientInformation.QUADIS && ([
                  <th key={7} data-field="creator" onClick={(event) => onOrderChange(event)}>
                    {t(`${tKey}CREATOR_ID`)}&nbsp;<i className={getIconClass('creator')}/>
                  </th>,
                  <th key={8} data-field="salesmanManager" onClick={(event) => onOrderChange(event)}>
                    {t(`${tKey}SALESMAN_MANAGER`)}&nbsp;<i className={getIconClass('salesmanManager')}/>
                  </th>
                ]),
                <th key={9} data-field="creationDate" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}CREATION_DATE`)}&nbsp;<i className={getIconClass('creationDate')}/>
                </th>
              ]}
              rows={dossierData && dossierData.map((row, idx) => (
                <tr key={idx}>
                  <td>
                    <span className={`color-point ${urgencyTypesClass[row.urgencyType] || 'grey'}`}/>
                  </td>
                  <td>
                    <Link to={`/dossier${row.urlToNavigate}`}>
                      {row.number}
                    </Link>
                  </td>
                  <td>{row.organizedUnit}</td>
                  <td className="see-more">
                    <EntityPopover
                      t={t}
                      entity={row.entity}
                      half={(vehicleData.length || 1) / (idx + 1)}
                      idx={idx}
                      id={'popover-entity' + idx}
                    />
                  </td>
                  <td className="see-more">
                    <VehiclePopover
                      t={t}
                      vehicle={row.vehicle}
                      half={(vehicleData.length || 1) / (idx + 1)}
                      idx={idx}
                      id={'popover-vehicle' + idx}
                      isHeader = {true}
                      dossierSubTypeId={row.dossierSubtypeId}
                    />
                  </td>
                  <td>{row.dossierSubType}</td>
                  <td>{row.stage}</td>
                  {
                    settings.clientFieldValidation === clientInformation.QUADIS && ([
                      <td>{row.creatorId}</td>,
                      <td>{row.salesManagerName}</td>
                    ])
                  }
                  <td>{row.creationDate}</td>
                </tr>
              ))}
            />
          </div>,
          <div className="search-footer" key="search-footer">
            <IDocCarPagination
              id="btn_pag_sales"
              page={page}
              pagesCount={pages}
              totalRows= {count}
              onSelectAction={(page) => onPageChange(page)}
            />
          </div>
        ])}
      </div>
    )
  }
}

export default SearchByDossierForm
