import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { getDocumentFleetList, getDocumentFleetListSuccess } from '../../../actions/management/management'
import { openModal } from '../../../actions/common'
import { openDocumentDetailsFleetModal } from '../../../actions/management/auxModals/auxModals'

import DocumentsListComponent from '../../../components/management/commons/DocumentsListComponent'

export function mapStateToProps (state) {
  return {
    ...state.management.documentsFleetComponent
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      openModal,
      getDocumentFleetList,
      getDocumentFleetListSuccess,
      openDocumentDetailsFleetModal
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(DocumentsListComponent))
