import { put, call, takeEvery, select } from 'redux-saga/effects'
import downloadActionTypes from '../../constants/actions/search/download'
import { showLoader, hideLoader, openModal } from '../../actions/common.js'
import { handleError } from '../errors/errorManager'
import { openDownloadModalSuccess } from '../../actions/search/download'
import postBulkDownloadInfo from '../../services/dossierFinder/postBulkDownloadInfo'
import postBulkDownloadProcess from '../../services/unattendedProcess/postBulkDownloadProcess'
import { getAuth } from '../../selectors/access/auth'
import messageModalActions from '../../actions/modals/messageModal'

export function * openDownloadModal ({ download }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const result = yield call(postBulkDownloadInfo, download, auth.token)
    yield put(openDownloadModalSuccess(result))
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && customHandlerError.json.tag && customHandlerError.json.tag.startsWith('maxDossierDownloadReached')) {
        let maxDossier = customHandlerError.json.tag.match(/\d+/)

        yield put(openModal('htmlDefaultOk', null, null, '<p>#T#ERRORS.BACKEND_TAG_TRANSLATION.MAX_DOSSIER_DOWNLOAD_REACHED#T#</p>', null, [{ id: '#MAXNUMBER#', value: maxDossier }]))
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    yield call(handleError, { error, customHandler })
  } finally {
    yield put(hideLoader())
  }
}
export function * watchOpenDownloadModal () {
  yield takeEvery(downloadActionTypes.OPEN_DOWNLOAD_MODAL, openDownloadModal)
}

export default function * postBulkDownload ({ body, resolve, reject }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const result = yield call(postBulkDownloadProcess, body, auth.token)
    resolve(result)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && customHandlerError.json.tag && customHandlerError.json.tag.startsWith('maxDossierDownloadReached')) {
        let maxDossier = customHandlerError.json.tag.match(/\d+/)

        yield put(openModal('htmlDefaultOk', null, null, '<p>#T#ERRORS.BACKEND_TAG_TRANSLATION.MAX_DOSSIER_DOWNLOAD_REACHED#T#</p>', null, [{ id: '#MAXNUMBER#', value: maxDossier }]))
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    yield call(handleError, { error, customHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchPostBulkDownload () {
  yield takeEvery(downloadActionTypes.POST_BULK_DOWNLOAD, postBulkDownload)
}
