import React, { PureComponent } from 'react'
import { getStorageValue } from '../storage/storage'

class ComponentVersionSelector extends PureComponent {
  render () {
    let v1 = this.props.v1Component
    let v2 = this.props.v2Component

    let version = parseInt(getStorageValue('interfaceVersion'))
    if (version === 2) {
      return React.createElement(v2, { ...this.props })
    } else {
      return React.createElement(v1, { ...this.props })
    }
  }
}

export default ComponentVersionSelector
