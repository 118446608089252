import apiFetch from '../apiFetch'

export default function (faqSectionId, rating, faqValues, token) {
  const body = {
    FaqSectionId: faqSectionId,
    Disabled: false,
    Rating: rating,
    TranslationsFaq: faqValues
  }
  return apiFetch({
    endPoint: 'Faq',
    method: 'POST',
    body: body,
    token: token
  })
}
