import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import DownloadModal from '../../../../components/search/downloadModal/DownloadModal'
import { closeDownloadModal, postBulkDownload } from '../../../../actions/search/download'

export function mapStateToProps (state) {
  const downloadModal = state.download.downloadModal
  return {
    showModal: downloadModal.showModal,
    data: downloadModal.download
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      closeDownloadModal,
      postBulkDownload
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(DownloadModal))
