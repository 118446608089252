import apiFetch from '../apiFetch'

export default function (token, urgencyType, organizedUnit) {
  let optionals = ''
  if (urgencyType) {
    optionals += `?urgencyType=${urgencyType}`
    if (organizedUnit) optionals += `&organizedUnit=${organizedUnit}`
  } else if (organizedUnit) {
    optionals += `?organizedUnit=${organizedUnit}`
  }
  return apiFetch({ endPoint: `taskDashboard${optionals}`, method: 'GET', body: null, token: token })
}
