import React, { PureComponent } from 'react'
import { Row, Button, Checkbox, Col } from 'react-bootstrap'

class Step3 extends PureComponent {
  render () {
    return (
      <div>
        <Row className="row-border">
          <h4>Documentos de cliente</h4>
          <Col sm={3} className="checkbox-wrapper">
            <Checkbox inline>
              DNI/CIF
            </Checkbox>
            <span className="dot-after"></span>
          </Col>
          <Col sm={3} className="checkbox-wrapper">
            <Checkbox inline>
              Libro fam.
            </Checkbox>
            <span className="dot-after"></span>
          </Col>
        </Row>
        <Row className="row-border">
          <h4>Documentos de vehículo</h4>
          <Col sm={3} className="checkbox-wrapper">
            <Checkbox inline>
              Fecha ITV
            </Checkbox>
            <span className="dot-after"></span>
          </Col>
          <Col sm={3} className="checkbox-wrapper">
            <Checkbox inline>
              Permiso de circulación original
            </Checkbox>
            <span className="dot-after"></span>
          </Col>
          <Col sm={3} className="checkbox-wrapper">
            <Checkbox inline>
              Cancelación préstamo
            </Checkbox>
            <span className="dot-after"></span>
          </Col>
          <Col sm={3} className="checkbox-wrapper">
            <Checkbox inline>
              Informe tráfico
            </Checkbox>
            <span className="dot-after"></span>
          </Col>
          <Col sm={3} className="checkbox-wrapper">
            <Checkbox inline>
              Imp.  municipal circul.
            </Checkbox>
            <span className="dot-after"></span>
          </Col>
        </Row>
        <div className="button-wrapper text-left">
          <Button className="btn-standard-white btn-continue">
            Asignar documentos
          </Button>
        </div>
      </div>
    )
  }
}

export default Step3
