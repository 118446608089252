import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import SearchByRentingForm from '../../../components/search/SearchByRentingForm'
import { translate } from 'react-polyglot'
import { resetFilters } from '../../../actions/search/search'
// import {fetchDossierSubTypes, fetchStagesCombo, fetchLowestOrganizedUnits, fetchSearchDossierSubType} from '../../../actions/combos/combos'

export function mapStateToProps (state) {
  return {
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      resetFilters
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(SearchByRentingForm))
