
import React, { PureComponent } from 'react'
import { reduxForm } from 'redux-form'
import { Modal, Table, Button, OverlayTrigger, Tooltip } from 'react-bootstrap'

class UnattendedProcessDownloadFileModal extends PureComponent {
  constructor () {
    super()
    this.state = {
    }
  }

  closeModal () {
    this.props.actions.openCloseUnattendedProcessDownloadFileModal(false, null)
  }

  componentDidUpdate () {}

  getSnapshotBeforeUpdate (prevProps) {
    if (prevProps.showModal !== this.props.showModal && this.props.showModal === true && this.props.unattendedProcessId) {
      this.props.actions.fetchUnattendedProcessFileListInfo(this.props.unattendedProcessId)
    }
    return null
  }

  render () {
    const {
      t, showModal, fileInfoList
    } = this.props
    const tKey = 'MANAGEMENT.UNATTENDED_PROCESS_TAB.'
    const downloadToolTip = (<Tooltip id="downloadToolTip">{this.props.t('MANAGEMENT.UNATTENDED_PROCESS_TAB.DOWNLOAD_FILE')}</Tooltip>)
    return (
      <Modal className="fleet-created-select-modal downloadFileModal languages-modal" show={showModal} onHide={() => this.closeModal()} backdrop={'static'}>
        <Modal.Header closeButton onHide={() => this.closeModal()}>
          <Modal.Title>
            {t(`${tKey}DOWNLOAD_FILE_MODAL_TITLE`)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table className="editable-table" hover responsive>
            <tbody>
              {fileInfoList && fileInfoList.map((fileInfo, idx) => {
                return (
                  <tr key={idx} >
                    <td className='fileName'><b>{fileInfo.fileName}</b></td>
                    <td>{t(`${tKey}EXPIRATION_DATE`)}:  <b>{fileInfo.expirationDate}</b> </td>
                    <td>{t(`${tKey}FILE_SIZE`)}: <b>{fileInfo.size}</b></td>
                    <td> {fileInfo.uri && (
                      <a href={fileInfo.uri}>
                        <OverlayTrigger placement="right" overlay={downloadToolTip}>
                          <i className="iDocIcon-download" />
                        </OverlayTrigger>
                      </a>
                    )}</td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button
            id='btn-accept-UnattendedProcessDownloadFileModal'
            className="_Btn accept al-C"
            onClick={() => this.closeModal()}>
            {t(`${tKey}ACCEPT`)}
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default reduxForm({
  form: 'unattendedProcessDownloadFileModal'
})(UnattendedProcessDownloadFileModal)
