export default {
  FETCH_FAQ_LIST: 'FETCH_FAQ_LIST',
  FETCH_FAQ_SUCCESS_LIST: 'FETCH_FAQ_SUCCESS_LIST',
  OPEN_FAQ_MODAL: 'OPEN_FAQ_MODAL',
  OPEN_FAQ_MODAL_SUCCESS: 'OPEN_FAQ_MODAL_SUCCESS',
  DELETE_FAQ: 'DELETE_FAQ',
  RECOVER_FAQ: 'RECOVER_FAQ',
  CLOSE_FAQ_MODAL: 'CLOSE_FAQ_MODAL',
  SAVE_FAQ: 'SAVE_FAQ',
  FETCH_FAQ_SECTION_COMBO: 'FETCH_FAQ_SECTION_COMBO',
  LOAD_FAQ_SECTION_COMBO_FILTER: 'LOAD_FAQ_SECTION_COMBO_FILTER',
  FETCH_FAQ_SECTION_COMBO_MODAL: 'FETCH_FAQ_SECTION_COMBO_MODAL',
  LOAD_FAQ_SECTION_COMBO_MODAL: 'LOAD_FAQ_SECTION_COMBO_MODAL',
  OPEN_FAQ_MASTER: 'OPEN_FAQ_MASTER'
}
