import dashboardActionTypes from '../../constants/actions/dashboard/dashboard'

export function initialState () {
  return {
    dashboard: {
      canCreateDossierAudi: false,
      canCreateDossierBuyAndSellAudi: false,
      canCreateDossierBuyAndSellVW: false,
      canCreateDossierExcelFleetQuadis: false,
      canCreateDossierSeat: false,
      canCreateDossierVW: false
    },
    salesmanId: null,
    firstLoad: true,
    dashboardModal: {
      showModal: false
    },
    dossierFavorites: null,
    dossierLastAccessed: null,
    taskFavorites: null
  }
}

function fetchDashboardSuccess (state, { data, salesmanId = null }) {
  return {
    ...state,
    dashboard: data,
    salesmanId,
    firstLoad: false
  }
}

export function openUploadDocumentFleetModalSuccess (state, { organizedUnit, brand, isBuyAndSell, code }) {
  const initial = initialState()
  return {
    ...state,
    dashboardModal: {
      ...initial.dashboardModal,
      showModal: true,
      brand,
      isBuyAndSell,
      code
    },
    organizedUnit,
    activeSheet: 0,
    activePage: 1
  }
}

function closeUploadDocumentFleetModalSuccess (state) {
  return {
    ...state,
    dashboardModal: {
      ...initialState().dashboardModal
    }
  }
}
function UploadModalSuccess (state) {
  return {
    ...state
    // dashboardModal: {
    //   ...initialState().dashboardModal
    // }
  }
}

function fetchCreateDossierButtonSuccess (state, { canCreateDossier }) {
  return {
    ...state,
    dashboard: { ...state.dashboard, canCreateDossier }
  }
}

function setFirstLoad (state) {
  return {
    ...state,
    firstLoad: true
  }
}

function setSalesmanId (state, { salesmanId }) {
  return {
    ...state,
    salesmanId
  }
}

function fetchDashboardButtonsSuccess (state, { data }) {
  let dashboard = { ...state.dashboard, ...data }
  return {
    ...state,
    dashboard
  }
}

function fetchDossierFavoritesSuccess (state, { data }) {
  return {
    ...state,
    dossierFavorites: data
  }
}

function fetchDossierLastAccessedSuccess (state, { data }) {
  return {
    ...state,
    dossierLastAccessed: data
  }
}

function fetchTaskFavoritesSuccess (state, { data }) {
  return {
    ...state,
    taskFavorites: data
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case dashboardActionTypes.FETCH_DASHBOARD_SUCCESS:
      return fetchDashboardSuccess(state, action)
    case dashboardActionTypes.OPEN_DOCUMENT_UPLOAD_MODAL_SUCCESS:
      return openUploadDocumentFleetModalSuccess(state, action)
    case dashboardActionTypes.CLOSE_DOCUMENT_UPLOAD_MODAL_SUCCESS:
      return closeUploadDocumentFleetModalSuccess(state, action)
    case dashboardActionTypes.UPLOAD_DOCUMENT_FLEET:
      return UploadModalSuccess(state, action)
    case dashboardActionTypes.FETCH_CREATE_DOSSIER_BUTTON_SUCCESS:
      return fetchCreateDossierButtonSuccess(state, action)
    case dashboardActionTypes.SET_FIRST_LOAD:
      return setFirstLoad(state, action)
    case dashboardActionTypes.SET_SALESMAN_ID:
      return setSalesmanId(state, action)
    case dashboardActionTypes.FETCH_CREATE_BUTTONS_SUCCESS:
      return fetchDashboardButtonsSuccess(state, action)
    case dashboardActionTypes.FETCH_DOSSIER_FAVORITES_SUCCESS:
      return fetchDossierFavoritesSuccess(state, action)
    case dashboardActionTypes.FETCH_DOSSIER_LAST_ACCESSED_SUCCESS:
      return fetchDossierLastAccessedSuccess(state, action)
    case dashboardActionTypes.FETCH_TASK_FAVORITES_SUCCESS:
      return fetchTaskFavoritesSuccess(state, action)
    default:
      return state
  }
}
