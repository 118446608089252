import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getFormValues } from 'redux-form'
import Search from '../../../components/search/Search'
import Search_v2 from '../../../_v2/components/search/Search'
import { translate } from 'react-polyglot'
import {
  searchTabChange, fetchDossiers, fetchEntities, fetchVehicles, fetchCharges, initializeSearch, resetFilters,
  setFilters, fetchDossiersSimple, fetchVehiclesSimple, fetchChargeRenting, fetchSelfSupply, salePayRentingList, saleSelfSupplyList, fetchFleets, fetchDossiersDownload,
  fetchVehiclesDownload, fetchEntitiesDownload, fetchDossiersSimpleDownload, fetchVehiclesSimpleDownload, fetchChargeRentingDownload, fetchSelfSupplyDownload,
  fetchChargesDownload, fetchFleetsDownload, fetchServicePrepare, fetchServicePrepareDownload, fetchServicePrepareRoleConfig, fetchReportCommercial, fetchReportCommercialDownload, closeReportCommercialTableModal
  , fetchPapServicesFromManager, fetchPapServicesDownloadFromManager, fetchPlanLogiticsPap, fetchProcessingAgencies, fetchProcessingAgenciesDownload, fetchFleetReport, fetchFleetReportDownload,
  fetchStockManagementDossiers, fetchStockManagementDossiersDownload, searchSectionChange, setFields

} from '../../../actions/search/search'
import { resetFilters as resetDocumentSearchFilters } from '../../../actions/search/documentalSearch/documentalSearchForm'
import { fetchDossierSubTypes, fetchSearchDossierSubType, fetchBankCombo, fetchOperationType, fetchSaleTypeBySubtype, fetchPurchaseType, fetchStagesCombo, fetchPaymentMethodCombo, fetchOperationTypeDMS, fetchPurchaseLogisticStateCombo, fetchUserRepairWorkshopsCombo } from '../../../actions/combos/combos'
import { openModal, setCurrentSection } from '../../../actions/common'
import { openDocumentEditionModal, openDocumentCreationModalFleets } from '../../../actions/dossier/common/documentEditionModal'
import TrackingContainer from '../../../containers/tracking/trackingContainer'
import { getStorageValue } from '../../../storage/storage'
// import { withRouter } from 'react-router-dom'

export function mapStateToProps (state) {
  return {
    ...state.search,
    sectionsGeneral: state.auth.sections,
    combos: {
      rejectedCombo: state.combos.rejectedCombo,
      brandCombo: state.combos.brandCombo,
      dossierSubtypesCombo: state.combos.dossierSubTypesCombo,
      stagesCombo: state.combos.stagesCombo,
      lowestOrganizedUnitsCombo: state.combos.lowestOrganizedUnitsCombo,
      salesmanCombo: state.combos.salesmanCombo,
      entityTypeCombo: state.combos.entityTypeCombo,
      entitySubTypeCombo: state.combos.entitySubTypeCombo,
      entityFilterSubTypeCombo: state.combos.entityFilterSubTypeCombo || [],
      searchDossierSubtypesCombo: state.combos.searchDossierSubtypesCombo,
      banksCombo: state.combos.banksCombo,
      usersCombo: state.combos.usersCombo,
      purchaseTypeCombo: state.combos.purchaseTypeCombo,
      saleTypeCombo: state.combos.saleTypeCombo,
      operationTypeCombo: state.combos.operationTypeCombo,
      documentStatusCombo: state.combos.documentStatusCombo,
      stockStatusCombo: state.combos.stockStatusCombo,
      activeInactiveCombo: state.combos.activeInactiveCombo,
      UOTreeCombo: state.combos.UOTreeCombo,
      paymentMethodCombo: state.combos.paymentMethodCombo,
      operationTypeDMSCombo: state.combos.operationTypeDMSCombo,
      carlineCombo: state.combos.carlineCombo || [],
      catalogueCombo: state.combos.catalogueCombo || [],
      modelCombo: state.combos.modelCombo || [],
      colorCombo: state.combos.colorCombo || [],
      tapestryCombo: state.combos.tapestryCombo || [],
      purchaseStateLogisticCombo: state.combos.purchaseStateLogisticCombo || [],
      repairWorkshopsCombo: state.combos.repairWorkshopsCombo
    },
    section: state.common.section,
    formValues: getFormValues('dossierSearch')(state)
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      initializeSearch,
      fetchDossierSubTypes,
      searchTabChange,
      fetchDossiers,
      fetchChargeRenting,
      fetchSelfSupply,
      salePayRentingList,
      saleSelfSupplyList,
      fetchEntities,
      fetchVehicles,
      fetchCharges,
      resetFilters,
      openModal,
      setFilters,
      fetchBankCombo,
      fetchSearchDossierSubType,
      openDocumentEditionModal,
      fetchDossiersSimple,
      fetchVehiclesSimple,
      fetchFleets,
      openDocumentCreationModalFleets,
      setCurrentSection,
      fetchDossiersDownload,
      fetchVehiclesDownload,
      fetchEntitiesDownload,
      fetchDossiersSimpleDownload,
      fetchVehiclesSimpleDownload,
      fetchChargeRentingDownload,
      fetchSelfSupplyDownload,
      fetchChargesDownload,
      fetchFleetsDownload,
      fetchOperationType,
      fetchSaleTypeBySubtype,
      fetchPurchaseType,
      fetchStagesCombo,
      fetchServicePrepare,
      fetchServicePrepareDownload,
      fetchServicePrepareRoleConfig,
      fetchReportCommercial,
      fetchReportCommercialDownload,
      closeReportCommercialTableModal,
      fetchPapServicesFromManager,
      fetchPapServicesDownloadFromManager,
      fetchPlanLogiticsPap,
      fetchPaymentMethodCombo,
      fetchOperationTypeDMS,
      fetchProcessingAgencies,
      fetchProcessingAgenciesDownload,
      fetchPurchaseLogisticStateCombo,
      fetchFleetReport,
      fetchFleetReportDownload,
      fetchUserRepairWorkshopsCombo,
      fetchStockManagementDossiers,
      fetchStockManagementDossiersDownload,
      searchSectionChange,
      resetDocumentSearchFilters,
      setFields
    }, dispatch)
  }
}

let version = parseInt(getStorageValue('interfaceVersion'))
var decoratedComponent = null
if (version === 2) {
  decoratedComponent = TrackingContainer(Search_v2, true)
} else {
  decoratedComponent = TrackingContainer(Search, true)
}
export default connect(mapStateToProps, mapDispatchToProps)(translate()(decoratedComponent))
