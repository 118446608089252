import { call, put, select, takeLatest } from 'redux-saga/effects'
import { reset, change } from 'redux-form'
import { getAuth } from '../../selectors/access/auth'
import getFleetsCode from '../../services/dossier/get/getFleetsCode'
import commonActions from '../../actions/common'
import messageModalActions from '../../actions/modals/messageModal'
import { fetchFleetsUserSucces, fetchFleetsComboSuccess, fetchCombosSuccess } from '../../actions/combos/combos'

import combosActionTypes from '../../constants/actions/combos/combos'
import managementActionTypes from '../../constants/actions/management/management'
import { handleError } from '../errors/errorManager'
import getFleetsUser from '../../services/fleets/getFleetsUser'
import getEntityFleet from '../../services/fleets/getEntityFleet'
import { fetchEntityFleetSucces, checkChangeEntityDossierSuccess } from '../../actions/management/management'
import getEntitySubType from '../../services/entitySubType/getEntitySubType'
import getEntityFilterSubType from '../../services/entity/getEntityFilterSubType'
import getCheckChangeEntityDossier from '../../services/fleets/getCheckChangeEntityDossier'
import { fetchEntityOperateFleetSucces } from '../../actions/management/auxModals/auxModals'

export function * fetchFleets () {
  try {
    yield put(commonActions.showLoader())
    const auth = yield select(getAuth)
    let response = yield call(getFleetsCode, auth.token)
    let formatedResponse = response.map(fleet => ({ id: fleet, value: fleet }))
    yield put(fetchFleetsComboSuccess(formatedResponse))
  } catch (error) {
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    const customHandler = function * () {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.FETCH_COMBO_ERROR'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchFetchFleets () {
  yield takeLatest(combosActionTypes.FETCH_FLEETS_COMBO, fetchFleets)
}

export function * fetchFleetsUser ({ includeDeleted }) {
  try {
    yield put(commonActions.showLoader())
    const auth = yield select(getAuth)
    let response = yield call(getFleetsUser, auth.token, includeDeleted)
    let formatedResponse = response.map(fleet => ({ id: fleet.fleetId, value: fleet.fleetCode }))
    yield put(fetchFleetsUserSucces(formatedResponse))
  } catch (error) {
    const customHandler = function * () {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.FETCH_COMBO_ERROR'))
    }
    yield call(handleError, { error, customHandler })
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchfetchFleetsUser () {
  yield takeLatest(combosActionTypes.FETCH_FLEETS_USER, fetchFleetsUser)
}

export function * fetchFleetsEntity ({ fleetId, organizedUnitId }) {
  try {
    yield put(commonActions.showLoader())
    const auth = yield select(getAuth)
    let entityComponent = yield call(getEntityFleet, auth.token, { fleetId })

    if (entityComponent && entityComponent.entitySubTypeId) {
      const entitySubTypeCombo = yield call(getEntitySubType, auth.token, entityComponent.entityTypeId)
      const entityFilterSubTypeCombo = yield call(getEntityFilterSubType, auth.token, entityComponent.entityTypeId, entityComponent.entitySubTypeId, organizedUnitId)
      yield put(fetchCombosSuccess({ entitySubTypeCombo, entityFilterSubTypeCombo }))
    }

    let data = { entityComponent: entityComponent }
    data.entityComponent.updated = false

    yield put(fetchEntityFleetSucces(data))
    yield put(fetchEntityOperateFleetSucces(data))

    yield put(change('addNewFleetModal', 'entityComponent', entityComponent.entityId ? entityComponent : {}))
    yield put(change('dataContactFleetModal', 'entityComponent', entityComponent.entityId ? entityComponent : {}))
  } catch (error) {
    const customHandler = function * () {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.FETCH_COMBO_ERROR'))
    }
    yield call(handleError, { error, customHandler })
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchfetchFleetsEntity () {
  yield takeLatest(managementActionTypes.FETCH_ENTITY_FLEET, fetchFleetsEntity)
}

export function * checkChangeEntityDossier ({ fleetId, dossierList }) {
  try {
    yield put(commonActions.showLoader())
    const auth = yield select(getAuth)
    let response = yield call(getCheckChangeEntityDossier, auth.token, { fleetId, dossierList })

    yield put(checkChangeEntityDossierSuccess(response))
  } catch (error) {
    const customHandler = function * () {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.FETCH_COMBO_ERROR'))
    }
    yield call(handleError, { error, customHandler })
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchgetCheckChangeEntityDossier () {
  yield takeLatest(managementActionTypes.CHECK_CHANGE_ENTITY_DOSSIER, checkChangeEntityDossier)
}
