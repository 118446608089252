import messageModalActionTypes from '../../constants/actions/modals/messageModal'
import backEndTagTranslation from '../../constants/backEndTagTransaltion'
import { errorTagSufixSeparator } from '../../constants/backendIds'
import { transformApisSignErrors } from '../../util/utils'

export function initialState () {
  return {
    showMessage: false,
    messageType: '',
    tKeyMessage: '',
    tKeyFilteredByTag: '',
    errorCode: '',
    others: ''
  }
}

function openSuccessMessageModal (state, { tKeyMessage, tKeyFilteredByTag, others }) {
  return {
    ...state,
    showMessage: true,
    messageType: 'success',
    tKeyMessage,
    backendFilteredTag: tKeyFilteredByTag,
    tKeyFilteredByTag,
    others: others
  }
}

function openErrorMessageModal (state, { tKeyMessage = '', backendFilteredTag = '', sufixErrorTag = null, errorCode = '', others = '' }) {
  const transactionTag = backendFilteredTag.match(/^TRANS\/([\w\d]*)/)
  let tKeyFilteredByTag
  if (transactionTag && backEndTagTranslation[transactionTag[1]]) {
    tKeyFilteredByTag = backEndTagTranslation[transactionTag[1]]
  } else if (backEndTagTranslation[backendFilteredTag]) {
    tKeyFilteredByTag = backEndTagTranslation[backendFilteredTag]
  } else if (backendFilteredTag && backendFilteredTag.indexOf(errorTagSufixSeparator) >= 0) {
    const tagArray = backendFilteredTag.split(errorTagSufixSeparator)
    if (backEndTagTranslation[tagArray[1]]) {
      tKeyFilteredByTag = backEndTagTranslation[tagArray[1]]
    } else if (backendFilteredTag) {
      tKeyFilteredByTag = backEndTagTranslation.default_error
    }
    sufixErrorTag = tagArray[0]
  } else if (backendFilteredTag) {
    let transformMessage = transformApisSignErrors(backendFilteredTag)
    tKeyFilteredByTag = transformMessage ? backEndTagTranslation[transformMessage] : backEndTagTranslation.default_error
  } else {
    tKeyFilteredByTag = ''
  }
  return {
    ...state,
    showMessage: true,
    messageType: 'error',
    tKeyMessage,
    tKeyFilteredByTag,
    sufixErrorTag,
    errorCode,
    others
  }
}

function closeMessageModal (state) {
  return initialState()
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case messageModalActionTypes.SUCCESS_MESSAGE_MODAL:
      return openSuccessMessageModal(state, action)
    case messageModalActionTypes.ERROR_MESSAGE_MODAL:
      return openErrorMessageModal(state, action)
    case messageModalActionTypes.CLOSE_MESSAGE_MODAL:
      return closeMessageModal(state, action)
    default:
      return state
  }
}
