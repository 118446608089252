import apiFetch from '../apiFetch'

export default function (code, token, files) {
  const queryParamsArray = [
    code ? `code=${code}` : null
  ]
  const formData = new FormData()
  for (let i = 0; i < files[0].length; i++) {
    formData.append(files[0][i].name, files[0][i])
  }
  const queryParams = queryParamsArray.reduce(
    (result, query, idx) => result + (query ? (result ? `&${query}` : `?${query}`) : '')
    , ''
  )
  return apiFetch({ endPoint: `LoadData/RecurrentEtity${queryParams}`, method: 'POST', body: formData, token: token, fileBody: true })
}
