import reassignReplacePurchaseModalActionTypes from '../../../constants/actions/dossier/common/reassignReplacePurchaseModal'

export function initialState () {
  return {
    showModal: false,
    modalType: '',
    vehiclesPurchaseList: [],
    order: {}
  }
}

function openReplacePurchaseModal (state, { modalType, order }) {
  return {
    ...state,
    showModal: true,
    modalType,
    order
  }
}

function closeReplacePurchaseModal (state) {
  return {
    ...state,
    showModal: false,
    modalType: '',
    vehiclesPurchaseList: []
  }
}

function getDossierPurchaseSuccess (state, { vehiclesPurchaseList }) {
  return {
    ...state,
    vehiclesPurchaseList
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case reassignReplacePurchaseModalActionTypes.OPEN_REASSIGN_PURCHASE_MODAL:
      return openReplacePurchaseModal(state, action)
    case reassignReplacePurchaseModalActionTypes.CLOSE_REASSIGN_PURCHASE_MODAL:
      return closeReplacePurchaseModal(state, action)
    case reassignReplacePurchaseModalActionTypes.GET_DOSSIER_PURCHASE_SUCCESS:
      return getDossierPurchaseSuccess(state, action)
    default:
      return state
  }
}
