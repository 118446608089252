import React from 'react'
import { Tooltip } from 'react-bootstrap'
const tKey = 'GLOBAL.'

export function FoldTooltip (props) {
  return (
    <Tooltip id="FoldTooltip">
      {props.isFolded ? props.t(`${tKey}TOOLTIP.UNFOLD`) : props.t(`${tKey}TOOLTIP.FOLD`)}
    </Tooltip>
  )
}

export function GoBackTooltip (props) {
  return (
    <Tooltip id="GoBackTooltip">
      {props.t(`${tKey}TOOLTIP.GO_BACK`)}
    </Tooltip>
  )
}

export default { FoldTooltip, GoBackTooltip }
