import React, { PureComponent } from 'react'
import { Row, Button, /* Clearfix, */ Form, Col, Tooltip, OverlayTrigger, Glyphicon, Modal, ProgressBar } from 'react-bootstrap'
import { Field, reduxForm, reset, resetSection } from 'redux-form'
import { validateDate } from '../../../util/validationFunctions'
import SimpleTablePage from '../../../containers/commons/general/SimpleTablePage'
import IDocCarPagination from '../../../components/commons/iDocCarPagination'
import { Link } from 'react-router-dom'
import EntityPopover from '../../../_v2/components/search/EntityPopover'
import { fromCamelToUnderscore, convertStringToDate, getDateNowMaxDatePicker } from '../../../util/utils'
import { dossierTypesId } from '../../../constants/dossier/common/dossierTypes'
import { dossierStagesBySubtype } from '../../../constants/dossier/common/dossierStages'
import { dossierSubTypeId } from '../../../constants/dossier/common/dossierSubType'
import AddNewFleetModalPage from '../../../_v2/containers/management/Modals/AddNewFleetModalPage'
import settings from '../../../setting'
import { entityTypesId } from '../../../constants/backendIds'
/// //////////////////////////////////////////////////////////////////////////////////////
import MapSectionsManagement from '../../../_v2/util/map-sections-management'
import InputText from '../../../_v2/components/commons/form/InputText'
import InputSelect from '../../../_v2/components/commons/form/InputSelect'
import InputDatePicker from '../../../_v2/components/commons/form/InputDatePicker'
import InputCheckbox from '../../../_v2/components/commons/form/InputCheckBox'
// Modals
import ApprovalActionModalPage from '../../../_v2/containers/management/Modals/ApprovalActionModalPage'
import ActionDataModalPage from '../../../_v2/containers/management/Modals/ActionDataModalPage'
import FleetFinderPage from '../../../_v2/containers/management/FleetFinderPage'
import EconomicPlanModalPage from '../../../_v2/containers/management/Modals/EconomicPlanModalPage'
import DiferentDocumentUploadModalPage from '../../../_v2/containers/management/Modals/DiferentDocumentUploadModalPage'

class Fleets extends PureComponent {
  constructor () {
    super()
    this.state = {
      fields: [],
      filters: [],
      dynamicFilters: [],
      dossierIdToArray: [],
      disableResetButton: false,
      onlyFleets: true,
      checkAll: false,
      action: null,
      showActionDataModal: false,
      showModalFinder: true,
      displayBar: 'none',
      maxBar: 0,
      progressFail: 0,
      progress: 0,
      dossierData: [],
      maxiTable: false,
      hideFilters: false
    }
    this.addDossierIdToArray = this.addDossierIdToArray.bind(this)
    this.externalSearch = this.externalSearch.bind(this)
  }

  checkAll (dossiers, value) {
    this.setState({ checkAll: value })
    if (!value) {
      this.setState({ dossierIdToArray: [] })
    } else {
      var dossierArray = []
      dossiers.map(item => {
        dossierArray.push(item.dossierId)
      })
      this.setState({ dossierIdToArray: dossierArray })
    }
  }

  componentDidMount () {
    this.props.actions.initializeSearch()
    this.props.actions.fetchDossierSubTypes(null, dossierTypesId.sales)
    this.props.actions.fetchFleetsUser(false)
    this.props.fetchManagementActions(this.props.tabId)
    let dynamicFilters = this.props.fleets_mang_filter && Object.keys(this.props.fleets_mang_filter).length > 0 ? this.props.fleets_mang_filter : {}
    for (const filter in this.props.fleets_mang_filter) {
      this.props.change(filter, this.props.fleets_mang_filter[filter])
    }
    this.setState({ checkAll: false, dossierIdToArray: [], dynamicFilters, dossierData: [] })
  }

  validateDate (value, allValues, props) {
    const result = validateDate(value, props)
    return result === Object(result) ? undefined : result
  }

  fieldFill (field) {
    this.setState({ fields: { ...this.state.fields, ...field } })
  }

  handleUoChange (val) {
    this.fieldFill({ organizedUnit: val })
    if (val) {
      this.props.actions.fetchEntitySubTypeCombo(entityTypesId.recurrentEntity)
    }
  }

  handleEntitySubTypeChange (val) {
    this.fieldFill({ entitySubTypeId: val })
    if (val) {
      this.props.actions.fetchEntityFilterSubTypeCombo(entityTypesId.recurrentEntity, val, this.state.fields.organizedUnit)
    } else {
      this.fieldFill({ recurrentClientId: val })
      this.props.dispatch(resetSection('fleetsSearch', 'recurrentClientId'))
    }
  }

  changeOnlyFleet (value) {
    this.setState({ onlyFleets: value })
  }

  onOrderChange (e) {
    const newOrderBy = this.props.filters.orderBy.includes(`%2B${e.currentTarget.dataset.field}`) ? `-${e.currentTarget.dataset.field}` : `%2B${e.currentTarget.dataset.field}`
    this.fetchTable({ ...this.props.filters, orderBy: newOrderBy })
    this.addToData()
  }

  onPageChange (page) {
    this.fetchTable({ ...this.props.filters, page })
    this.addToData()
  }

  addToData () {
    let dossiers = JSON.parse(JSON.stringify(this.state.dossierData))
    this.props.dossierData.forEach(element => {
      if (dossiers.find(x => x.dossierId === element.dossierId) === undefined) {
        dossiers.push(element)
      }
    })

    this.setState({ dossierData: dossiers })
  }

  getIconClass (field) {
    if (this.props.filters.orderBy.includes(field)) {
      return this.props.filters.orderBy.includes('-') ? 'ico-sort-selected-asc' : 'ico-sort-selected-desc'
    } else {
      return 'ico-sort'
    }
  }

  fetchTable (propFilters) {
    let filters = this.state.dynamicFilters
    if (filters.onlyFleets === null || filters.onlyFleets === undefined) {
      filters.onlyFleets = this.state.onlyFleets
    }
    this.props.actions.fetchDossierFleets({ ...filters, ...propFilters })
    this.setState({ dynamicFilters: filters })
  }

  onClickResetSearch (e) {
    if (e !== undefined) {
      e.preventDefault()
    }

    this.props.actions.resetFilters()
    this.props.actions.resetManagementFleetsFilter()
    this.setState({ checkAll: false, dossierIdToArray: [], dossierData: [] })
  }

  handleClickReset (e) {
    if (e !== undefined) {
      e.preventDefault()
    }

    this.props.dispatch(reset('fleetsSearch'))
    this.state.dossierIdToArray = []
    this.setState({ onlyFleets: true, checkAll: false, dossierIdToArray: [], dossierData: [], fields: [] })
  }

  extractValueComboYesNo (value) {
    if (value === true || value === 1) return this.props.t('SEARCH.CATEGORIES.DOSSIER.YES')
    if (value === false || value === 0) return this.props.t('SEARCH.CATEGORIES.DOSSIER.NO')
  }

  extractCombo (combo, filters, item) {
    const selected = combo.find(object => object.id === filters[item])
    let value = selected && selected.value
    return value
  }

  extractMultipleCombo (combo, filters, item) {
    const values = combo.filter(object => {
      return filters[item] ? filters[item].some(selectedComboValue => object.id === selectedComboValue) : null
    })

    let value = values && this.formatMultipleValues(values)
    return value
  }

  formatMultipleValues (multiplecombovalues) {
    return multiplecombovalues
      .map((combovalue, index) => combovalue.value)
      .join(', ')
  }

  selectedFilters () {
    const filters = this.state.dynamicFilters
    const tKey = 'MANAGEMENT.FLEETS_TAB.'
    return Object.keys(filters)
      .map((item, index) => {
        let text = item
        let value = null
        const list = []
        text = this.props.t(`${tKey}${fromCamelToUnderscore(item).toUpperCase()}`)
        switch (item) {
          case 'organizedUnit':
          { const organizedUnit = this.props.combos.lowestOrganizedUnitsCombo.find(ou => ou.id === filters[item])
            value = organizedUnit && organizedUnit.value
            break }
          case 'onlyFleets':
          case 'pickPreviousVehicle':
          { const onlyFleets = filters[item]
            value = this.extractValueComboYesNo(onlyFleets)
            break }
          case 'dossierSubtype':
          { const selectedSubtype = this.props.combos.dossierSubtypesCombo.find(subtype => subtype.id === filters[item])
            value = selectedSubtype && selectedSubtype.value
            break }
          case 'salesmanIndividual':
          case 'salesmanManagerIndividual':
          { value = this.extractCombo(this.props.combos.salesmanCombo, filters, item)
            break }
          case 'fleet':
          { value = this.extractMultipleCombo(this.props.combos.fleets, filters, item)
            break }
          case 'recurrentClientId':
          { const recurrentClient = this.props.combos.recurrentEntitiesCombo.find(rc => rc.id === filters[item])
            value = recurrentClient && recurrentClient.value
            break }
          case 'entitySubTypeId':
          {
            const entitySubType = this.props.combos.entitySubTypeCombo.find(es => es.id === filters[item])
            value = entitySubType && entitySubType.value
            break
          }
          default:
          { value = filters[item]
            break }
        }
        if (value) {
          list.push(<li key={index}>{text}: <strong>{value}</strong></li>)
        }

        return list
      })
  }

  addDossierIdToArray (e, dossierId) {
    var dossierArray = JSON.parse(JSON.stringify(this.state.dossierIdToArray))
    if (this.state.checkAll) {
      dossierArray = []
      this.props.dossierData.map(x => {
        if (x.dossierId !== dossierId) {
          dossierArray.push(x.dossierId)
        }
      })
      this.setState({ dossierIdToArray: dossierArray })
    } else {
      if (dossierArray.includes(dossierId)) {
        dossierArray.splice(dossierArray.indexOf(dossierId), 1)
        this.setState({ dossierIdToArray: dossierArray })
      } else {
        dossierArray.push(dossierId)
        this.setState({ dossierIdToArray: dossierArray })
      }
    }

    this.setState({ checkAll: false })
  }

  submitSearchForm (values) {
    let filters = values
    if (filters.onlyFleets === null || filters.onlyFleets === undefined) {
      filters.onlyFleets = this.state.onlyFleets
    }
    let propFilters = this.props.filters
    propFilters.orderBy = '%2BfleetCode'

    this.props.actions.fetchDossierFleets({ ...filters, ...propFilters })
    this.props.actions.setManagementFleetsFilter(values)
    this.setState({ dossierIdToArray: [], checkAll: false, dynamicFilters: filters, dossierData: [], hideFilters: true })
  }

  launchAction (val) {
    this.setState({ displayBar: 'none', maxBar: 0, progressFail: 0, progress: 0 })
    var found = this.props.managementActions.find(x => x.managementActionId === val)
    if (found) {
      if (this.state.checkAll) {
        new Promise((resolve) => {
          let filters = this.state.dynamicFilters
          let propFilters = this.props.filters
          propFilters.orderBy = '%2BfleetCode'
          if (filters.onlyFleets === null || filters.onlyFleets === undefined) {
            filters.onlyFleets = this.state.onlyFleets
          }
          this.props.actions.fetchDossierFleets({ ...filters, ...propFilters, pageSize: this.props.count, page: 1 }, resolve)
        }).then((response) => {
          this.executeFunctionByName(found.function, this, response.items)
        })
      } else {
        this.executeFunctionByName(found.function, this)
      }
    }
    this.props.change('actions', null)
  }

  executeFunctionByName (functionName, context) {
    var args = Array.prototype.slice.call(arguments, 2)
    var namespaces = functionName.split('.')
    var func = namespaces.pop()
    for (var i = 0; i < namespaces.length; i++) {
      context = context[namespaces[i]]
    }
    return context[func].apply(context, args)
  }

  financialAnalysisSection (dossiers) {
    var subtypeId = this.getDossierSubtype(dossiers)
    if (subtypeId !== null) {
      new Promise((resolve, reject) => {
        this.props.actions.showFrontEconomicPlan(this.state.dynamicFilters.organizedUnit, subtypeId, resolve, reject)
      }).then((response) => {
        if (response === true) {
          this.getDossiersByStageForEconomicPlan(subtypeId, dossiers)
        } else {
          this.props.actions.openModal('actionEconomicPlanNotShow')
        }
      }).catch(() => {
        this.props.actions.openModal('actionEconomicPlanNotShow')
      })
    } else {
      this.props.actions.openModal('actionFleetDataChangeNoSubType')
    }
  }

  getDossiersByStageForEconomicPlan (subtypeId, dossiersArray) {
    let dossiers = []
    if (dossiersArray) {
      dossiersArray.forEach(item => {
        if (item.dossierSubtypeId === dossierSubTypeId.venta_vn) {
          if (item.stageId < dossierStagesBySubtype.saleVn.documentation) {
            dossiers.push(item)
          }
        }
        if (item.dossierSubtypeId === dossierSubTypeId.venta_vo) {
          if (item.stageId < dossierStagesBySubtype.saleVo.documentation) {
            dossiers.push(item)
          }
        }
      })

      if (dossiers.length < dossiersArray.length) {
        this.props.actions.openModal('actionEconomicPlanPosStage', () => this.props.actions.openEconomicPlanModal(dossiers, this.state.dynamicFilters.organizedUnit, subtypeId))
      } else if (dossiers.length === 0) {
        this.props.actions.openModal('actionEconomicPlanNoStage')
      } else {
        this.props.actions.openEconomicPlanModal(dossiers, this.state.dynamicFilters.organizedUnit, subtypeId)
      }
    } else {
      this.state.dossierIdToArray.forEach(item => {
        var dossier = this.props.dossierData.find(x => x.dossierId === item)
        if (dossier === null || dossier === undefined) {
          dossier = this.state.dossierData.find(x => x.dossierId === item)
        }
        if (dossier.dossierSubtypeId === dossierSubTypeId.venta_vn) {
          if (dossier.stageId < dossierStagesBySubtype.saleVn.documentation) {
            dossiers.push(dossier)
          }
        }
        if (dossier.dossierSubtypeId === dossierSubTypeId.venta_vo) {
          if (dossier.stageId < dossierStagesBySubtype.saleVo.documentation) {
            dossiers.push(dossier)
          }
        }
      })

      if (dossiers.length === 0) {
        this.props.actions.openModal('actionEconomicPlanNoStage')
      } else if (dossiers.length < this.state.dossierIdToArray.length) {
        this.props.actions.openModal('actionEconomicPlanPosStage', () => this.props.actions.openEconomicPlanModal(dossiers, this.state.dynamicFilters.organizedUnit, subtypeId))
      } else {
        this.props.actions.openEconomicPlanModal(dossiers, this.state.dynamicFilters.organizedUnit, subtypeId)
      }
    }
  }

  uploadDiferentDoc (dossiers) {
    this.verifyVinChassis(dossiers)
  }

  uploadDocumentFleet (dossiers) {
    if (dossiers) {
      let dossiersID = dossiers.map(function (v) {
        return v.dossierId
      })
      this.props.actions.openDocumentCreationModalFleets(dossiersID, this.state.dynamicFilters.organizedUnit)
    } else {
      this.props.actions.openDocumentCreationModalFleets(this.state.dossierIdToArray, this.state.dynamicFilters.organizedUnit)
    }
    this.setState({ dossierIdToArray: [], checkAll: false })
  }

  verifyVinChassis (dossiers) {
    let listDossiers = []
    if (dossiers) {
      listDossiers = dossiers
    } else {
      this.state.dossierIdToArray.forEach(item => {
        var dossier = this.props.dossierData.find(x => x.dossierId === item)
        if (dossier === null || dossier === undefined) {
          dossier = this.state.dossierData.find(x => x.dossierId === item)
        }
        listDossiers.push(dossier)
      })
    }
    let open = true
    listDossiers.forEach(item => {
      if (!item.vehicle === null || !item.vehicle.vehicleVinChassis) {
        open = false
        this.props.actions.openModal('actionDocumentsUploadNoVinChassis')
      }
    })
    if (open) {
      this.props.actions.openDocumentDiferentsModalFleets(listDossiers, this.state.dynamicFilters.organizedUnit)
    }
  }

  actionSaleData (dossiers) {
    var dossierSubtypeId = this.getDossierSubtype(dossiers)

    if (dossierSubtypeId !== null) {
      this.getDossiersByStage(dossierSubtypeId, dossiers)
    } else {
      this.props.actions.openModal('actionFleetDataChangeNoSubType')
    }
  }

  getDossiersByStage (dossierSubtypeId, dossiersArray) {
    let dossiers = []
    if (dossiersArray) {
      dossiersArray.forEach(item => {
        if (item.dossierSubtypeId === dossierSubTypeId.venta_vn) {
          if (item.stageId <= dossierStagesBySubtype.saleVn.documentation) {
            dossiers.push(item.dossierId)
          }
        }
        if (item.dossierSubtypeId === dossierSubTypeId.venta_vo) {
          if (item.stageId <= dossierStagesBySubtype.saleVo.documentation) {
            dossiers.push(item.dossierId)
          }
        }
      })

      if (dossiers.length < dossiersArray.length) {
        this.props.actions.openModal('actionFleetDataChangePosStage', () => this.props.actions.openActionDataModal(dossiers, this.state.dynamicFilters.organizedUnit, dossierSubtypeId))
      } else if (dossiers.length === 0) {
        this.props.actions.openModal('actionFleetDataChangeNoStage')
      } else {
        this.props.actions.openActionDataModal(dossiers, this.state.dynamicFilters.organizedUnit, dossierSubtypeId)
      }
    } else {
      this.state.dossierIdToArray.forEach(item => {
        var dossier = this.props.dossierData.find(x => x.dossierId === item)
        if (dossier === null || dossier === undefined) {
          dossier = this.state.dossierData.find(x => x.dossierId === item)
        }
        if (dossier.dossierSubtypeId === dossierSubTypeId.venta_vn) {
          if (dossier.stageId <= dossierStagesBySubtype.saleVn.documentation) {
            dossiers.push(item)
          }
        }
        if (dossier.dossierSubtypeId === dossierSubTypeId.venta_vo) {
          if (dossier.stageId <= dossierStagesBySubtype.saleVo.documentation) {
            dossiers.push(item)
          }
        }
      })

      if (dossiers.length === 0) {
        this.props.actions.openModal('actionFleetDataChangeNoStage')
      } else if (dossiers.length < this.state.dossierIdToArray.length) {
        this.props.actions.openModal('actionFleetDataChangePosStage', () => this.props.actions.openActionDataModal(dossiers, this.state.dynamicFilters.organizedUnit, dossierSubtypeId))
      } else {
        this.props.actions.openActionDataModal(this.state.dossierIdToArray, this.state.dynamicFilters.organizedUnit, dossierSubtypeId)
      }
    }
  }

  getDossierSubtype (dossiers) {
    var multiple = false
    var dossierSubtypeID = null
    if (dossiers) {
      dossierSubtypeID = dossiers[0].dossierSubtypeId
      dossiers.forEach(item => {
        if (dossierSubtypeID !== item.dossierSubtypeId) {
          multiple = true
        }
      })
    } else {
      var dossierSubtype = this.props.dossierData.find(x => x.dossierId === this.state.dossierIdToArray[0])

      if (dossierSubtype === null || dossierSubtype === undefined) {
        dossierSubtype = this.state.dossierData.find(x => x.dossierId === this.state.dossierIdToArray[0])
      }

      dossierSubtypeID = dossierSubtype.dossierSubtypeId

      this.state.dossierIdToArray.forEach(item => {
        var dossier = this.props.dossierData.find(x => x.dossierId === item)
        if (dossier === null || dossier === undefined) {
          dossier = this.state.dossierData.find(x => x.dossierId === item)
        }
        if (dossierSubtypeID !== dossier.dossierSubtypeId) {
          multiple = true
        }
      })
    }
    return !multiple ? dossierSubtypeID : null
  }

  actionExpFleet (dossiersArray) {
    let dossiers = this.getDossiersIsFleet(dossiersArray)

    let count = 0
    if (dossiersArray) {
      count = dossiersArray.length
    } else {
      count = this.state.dossierIdToArray.length
    }

    if (dossiers.length === 0) {
      this.props.actions.openModal('actionAdminFleetExpNoFleet')
    } else if (dossiers.length < count) {
      this.props.actions.openModal('actionAdminFleetExpOperationTypes', () => this.props.actions.openActionFleetFinder(dossiers, this.state.dynamicFilters.organizedUnit))
    } else {
      this.props.actions.openActionFleetFinder(dossiers, this.state.dynamicFilters.organizedUnit)
    }
  }

  getDossiersIsFleet (dossiersArray) {
    let dossiers = []
    if (dossiersArray) {
      dossiersArray.forEach(item => {
        if (item.isFleet) {
          dossiers.push(item)
        }
      })
    } else {
      this.state.dossierIdToArray.forEach(item => {
        var dossier = this.props.dossierData.find(x => x.dossierId === item)
        if (dossier === null || dossier === undefined) {
          dossier = this.state.dossierData.find(x => x.dossierId === item)
        }
        if (dossier.isFleet) {
          dossiers.push(dossier)
        }
      })
    }

    return dossiers
  }

  approvalActionFleet (dossiersArray) {
    let dossiers = []

    if (dossiersArray) {
      dossiersArray.forEach(item => {
        dossiers.push(item)
      })
    } else {
      this.state.dossierIdToArray.forEach(item => {
        var dossier = this.props.dossierData.find(x => x.dossierId === item)
        if (dossier === null || dossier === undefined) {
          dossier = this.state.dossierData.find(x => x.dossierId === item)
        }
        dossiers.push(dossier)
      })
    }

    if (dossiers.length === 0) {
      this.props.actions.openModal('actionFleetDataChangeNoStage')
    } else {
      this.props.actions.openApprovalActionModal(dossiers)
    }
  }

  exportData () {
    const tKey = 'MANAGEMENT.FLEETS_TAB.'
    let columnsExcel = []

    columnsExcel.push(this.props.t(`${tKey}FLEET`) + '#FleetCode')
    columnsExcel.push(this.props.t(`${tKey}RENTAL_ORDER_NUMBER`) + '#RentalOrderNumber')
    columnsExcel.push(this.props.t(`${tKey}DOSSIER_NUMBER`) + '#Number')
    columnsExcel.push(this.props.t(`${tKey}CHASSIS`) + '#Chassis')
    columnsExcel.push(this.props.t(`${tKey}MODEL_SHORT`) + '#Model')
    columnsExcel.push(this.props.t(`${tKey}ENTITY`) + '#Entity')
    columnsExcel.push(this.props.t(`${tKey}DOSSIER_SUBTYPE`) + '#SubType')
    columnsExcel.push(this.props.t(`${tKey}DELIVERY_CITY`) + '#DeliveryCity')
    columnsExcel.push(this.props.t(`${tKey}STAGE`) + '#Stage')
    columnsExcel.push(this.props.t('DOSSIER_COMPONENTS.SALES.SALESMAN_MANAGER') + '#SalesmanIdManager')
    columnsExcel.push(this.props.t(`${tKey}ORGANIZED_UNIT`) + '#OrganizedUnit')
    columnsExcel.push(this.props.t(`${tKey}CREATION_DATE`) + '#CreateDate')

    let cols = ''
    columnsExcel.forEach(element => {
      cols += element + '$'
    })
    cols = cols.substring(0, cols.length - 1)
    let filters = this.state.dynamicFilters
    if (filters.onlyFleets === null || filters.onlyFleets === undefined) {
      filters.onlyFleets = this.state.onlyFleets
    }
    this.props.actions.fetchDossierFleetsDownload({ ...filters, ...this.props.filters }, cols)
  }

  newfleet () {
    if (this.state.checkAll) {
      new Promise((resolve) => {
        let filters = this.state.dynamicFilters
        let propFilters = this.props.filters
        propFilters.orderBy = '%2BfleetCode'
        if (filters.onlyFleets === null || filters.onlyFleets === undefined) {
          filters.onlyFleets = this.state.onlyFleets
        }
        this.props.actions.fetchDossierFleets({ ...filters, ...propFilters, pageSize: this.props.count, page: 1 }, resolve)
      }).then((response) => {
        let dossierIds = response.items.map(function (v) {
          return v.dossierId
        })
        this.props.actions.openNewFleetModal(true, dossierIds, this.state.dynamicFilters.organizedUnit, null, response.items)
      })
    } else {
      this.props.actions.openNewFleetModal(true, this.state.dossierIdToArray, this.state.dynamicFilters.organizedUnit, null, this.props.dossierData)
    }
  }

  deleteOfFleet () {
    if (this.state.checkAll) {
      new Promise((resolve) => {
        let filters = this.state.dynamicFilters
        let propFilters = this.props.filters
        propFilters.orderBy = '%2BfleetCode'
        if (filters.onlyFleets === null || filters.onlyFleets === undefined) {
          filters.onlyFleets = this.state.onlyFleets
        }
        this.props.actions.fetchDossierFleets({ ...filters, ...propFilters, pageSize: this.props.count, page: 1 }, resolve)
      }).then((response) => {
        this.launchDelete(this.getHaveFleet(response.items))
      })
    } else {
      this.launchDelete(this.getHaveFleet())
    }
  }

  launchDelete (dossiersId) {
    if (dossiersId.length > 0) {
      this.props.actions.setActionExpFleetProcess(dossiersId.join('$'), 2, null, () => { this.props.actions.closeActionFleetFinder(); this.fetchTable({ ...this.props.filters }) })
    } else {
      this.props.actions.openModal('actionFleetDataChange', () => { this.props.actions.closeActionFleetFinder(); this.fetchTable({ ...this.props.filters }) }, null, null, null, [{ id: '##NUM_OK##', value: this.state.progress }, { id: '##NUM_TOTAL##', value: dossiersId.length }], null)
    }
  }

  launchNextdeleteOfFleet (index, dossiersId) {
    if (dossiersId.length > index) {
      new Promise((resolve, reject) => {
        return this.props.actions.deleteDossierFromFleet(dossiersId[index], resolve, reject)
      }).then((response) => {
        this.setState({ progress: this.state.progress + 1 })
        this.launchNextdeleteOfFleet(index + 1, dossiersId)
      }).catch(() => {
        this.setState({ progressFail: this.state.progressFail + 1 })
        this.launchNextdeleteOfFleet(index + 1, dossiersId)
      })
    } else {
      this.props.actions.openModal('actionFleetDataChange', () => { this.props.actions.closeActionFleetFinder(); this.fetchTable({ ...this.props.filters }) }, null, null, null, [{ id: '##NUM_OK##', value: this.state.progress }, { id: '##NUM_TOTAL##', value: dossiersId.length }], null)
    }
  }

  getHaveFleet (dossierArray) {
    let dossiers = []
    if (dossierArray) {
      dossierArray.forEach(dossier => {
        if (dossier.fleetCode !== null && dossier.fleetCode !== undefined) {
          dossiers.push(dossier.dossierId)
        }
      })
    } else {
      this.state.dossierIdToArray.forEach(item => {
        var dossier = this.props.dossierData.find(x => x.dossierId === item)
        if (dossier === null || dossier === undefined) {
          dossier = this.state.dossierData.find(x => x.dossierId === item)
        }
        if (dossier.fleetCode !== null && dossier.fleetCode !== undefined) {
          dossiers.push(item)
        }
      })
    }

    return dossiers
  }

  externalSearch (props) {
    this.setState({ dossierIdToArray: [], checkAll: false, dossierData: [] })
    this.props.actions.fetchDossierFleets(props)
  }

  toogleHideFilters () {
    this.setState({ hideFilters: !this.state.hideFilters })
  }

  toogleMaxiTable () {
    this.setState({ maxiTable: !this.state.maxiTable })
  }

  showFilter (hasResults) {
    return !hasResults || !this.state.hideFilters
  }

  toggleFilterClass (hasResults) {
    if (!hasResults) {
      return 'iDocIcon-filter-hidden'
    }
    if (this.state.hideFilters) {
      return 'iDocIcon-filter-off'
    }
    return 'iDocIcon-filter'
  }

  hideFiltersBtn (hasResults) {
    if (!hasResults) {
      return 'hide'
    }
    return ''
  }

  render () {
    const {
      t, combos: {
        salesmanCombo, lowestOrganizedUnitsCombo, dossierSubtypesCombo, fleets,
        entityFilterSubTypeCombo, entitySubTypeCombo
      },
      classNameSelect, className, count, dossierData, vehicleData,
      filters: { page, pages }, handleSubmit, hasSearch, managementActions, shoModalFinderFleet
    } = this.props
    const secMapManagement = MapSectionsManagement.find(function (s) { return s.page === 'fleetsPage' })
    const tKey = 'MANAGEMENT.FLEETS_TAB.'
    let yesNoCombo = this.props.combos.activeInactiveCombo
    yesNoCombo = yesNoCombo.map((activeInactiveCombo) => {
      return { ...activeInactiveCombo, value: t(`COMBOS.YES_NO.${activeInactiveCombo.value}`) }
    })
    const actionToolTip = this.state.dossierIdToArray.length === 0 ? (<Tooltip id="documentTooltip">{t(`${tKey}ACTIONS_NOT_DOSSIER_SELECTED`)}</Tooltip>) : (<Tooltip id="documentTooltip">{t(`${tKey}ACTIONS_TOOLTIP`)}</Tooltip>)
    const blockedClientFieldsTooltip = (<Tooltip id="blockedClientFieldsTooltip">{t(`${tKey}UO_NOT_SELECTED_TOOLTIP`)}</Tooltip>)
    var selectedFiles = t(`${tKey}SELECTED_EXPEDIENTS`)

    selectedFiles = selectedFiles.replace('##NUM##', this.state.checkAll ? count : this.state.dossierIdToArray.length).replace('##COUNT##', count)
    let dossier = { dossierSubTypeId: dossierSubTypeId.venta_vn }
    // const maximizate = (<Tooltip id="editProductTooltip">{`${t(dKey + (this.state.maxiTable ? 'MINIMIZE_TABLE' : 'MAXIMIZE_TABLE'))}`}</Tooltip>)
    const hasResults = dossierData && dossierData.length > 0
    return (
      <>
        <ActionDataModalPage dossierData = {dossierData} fleetsFilter={{ ...this.props.filters, ...this.state.dynamicFilters }} fetchFleets={this.externalSearch}></ActionDataModalPage>
        <ApprovalActionModalPage dossierData = {dossierData} fleetsFilter={{ ...this.props.filters, ...this.state.dynamicFilters }} fetchFleets={this.externalSearch}
          sectionsGeneral = {this.props.sectionsGeneral}></ApprovalActionModalPage>
        <div className="inner-results-panel">
          <div className="section-results-header">
            <div className="section-header-title">
              <OverlayTrigger placement="right" overlay={<Tooltip id="editProductTooltip">{`${t((this.props.hideMenu ? 'GLOBAL.TABLES.SHOW_INNER_MENU' : 'GLOBAL.TABLES.HIDE_INNER_MENU'))}`}</Tooltip>}>
                <a onClick={() => this.props.toogleInnerMenu()} className={this.props.hideMenu ? 'collapseBtn' : 'collapseBtn closeInnerMenu'}><i className={this.props.hideMenu ? 'iDocIcon-Open-inner-menu' : 'iDocIcon-Close-inner-menu'}></i></a>
              </OverlayTrigger>
              <h4>{t(`${secMapManagement.title}`)}
                <OverlayTrigger placement="left" overlay={<Tooltip id="editProductTooltip">{`${t((this.state.hideFilters ? 'GLOBAL.TABLES.SHOW_FILTERS' : 'GLOBAL.TABLES.HIDE_FILTERS'))}`}</Tooltip>}>
                  <a onClick={() => this.toogleHideFilters()} className={'toogleShowFilters ' + this.hideFiltersBtn(hasResults)}><i className={this.toggleFilterClass(hasResults)}></i></a>
                </OverlayTrigger>
              </h4>
            </div>
            <Form autoComplete="off" onSubmit={handleSubmit((values) => this.submitSearchForm(values))} className="filters">
              <div className={'search-form ' + (this.showFilter(hasResults) ? '' : 'hideFilters ')}>
                <Field
                  component={InputSelect}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="salesmanIndividual"
                  name='salesmanIndividual'
                  placeholder={t('DOSSIER_COMPONENTS.SALES.SALESMAN')}
                  controlLabel={t('DOSSIER_COMPONENTS.SALES.SALESMAN')}
                  options={salesmanCombo}
                  valueKey="id"
                  labelKey="value"
                  onInputChange={(val) => this.fieldFill({ salesmanIndividual: val })}
                  customClass={this.state.fields.salesmanIndividual ? classNameSelect : ''}
                />
                <Field
                  component={InputSelect}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="salesmanManagerIndividual"
                  name='salesmanManagerIndividual'
                  placeholder={t('DOSSIER_COMPONENTS.SALES.SALESMAN_MANAGER')}
                  controlLabel={t('DOSSIER_COMPONENTS.SALES.SALESMAN_MANAGER')}
                  options={salesmanCombo}
                  valueKey="id"
                  labelKey="value"
                  onInputChange={(val) => this.fieldFill({ salesmanManagerIndividual: val })}
                  customClass={this.state.fields.salesmanManagerIndividual ? className : ''}
                />
                <Field
                  component={InputText}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="dossierNumberFrom"
                  name='dossierNumberFrom'
                  placeholder={t(`${tKey}DOSSIER_NUMBER_FROM`)}
                  controlLabel={t(`${tKey}DOSSIER_NUMBER_FROM`)}
                  type="text"
                  onInputChange={(val) => this.fieldFill({ dossierNumberFrom: val })}
                  customClass={this.state.fields.dossierNumberFrom ? className : ''}
                />
                <Field
                  component={InputText}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="dossierNumberTo"
                  name='dossierNumberTo'
                  placeholder={t(`${tKey}DOSSIER_NUMBER_TO`)}
                  controlLabel={t(`${tKey}DOSSIER_NUMBER_TO`)}
                  type="text"
                  onInputChange={(val) => this.fieldFill({ dossierNumberTo: val })}
                  customClass={this.state.fields.dossierNumberTo ? className : ''}
                />
                <Field
                  component={InputDatePicker}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="creationDateFrom"
                  name='creationDateFrom'
                  placeholder={t(`${tKey}CREATION_DATE_FROM`)}
                  controlLabel={t(`${tKey}CREATION_DATE_FROM`)}
                  validate={[this.validateDate]}
                  onInputChange={(val) => this.fieldFill({ creationDateTo: val })}
                  customClass={this.state.fields.creationDateTo ? className : ''}
                  maxDate={getDateNowMaxDatePicker()}
                />
                <Field
                  component={InputDatePicker}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="creationDateTo"
                  name='creationDateTo'
                  placeholder={t(`${tKey}CREATION_DATE_TO`)}
                  controlLabel={t(`${tKey}CREATION_DATE_TO`)}
                  validate={[this.validateDate]}
                  onInputChange={(val) => this.fieldFill({ creationDateFrom: val })}
                  customClass={this.state.fields.creationDateFrom ? className : ''}
                  maxDate={getDateNowMaxDatePicker()}
                />
                <Field
                  component={InputSelect}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="dossierSubtype"
                  name='dossierSubtype'
                  placeholder={t(`${tKey}DOSSIER_SUBTYPE`)}
                  controlLabel={t(`${tKey}DOSSIER_SUBTYPE`)}
                  options={dossierSubtypesCombo}
                  valueKey="id"
                  labelKey="value"
                  onInputChange={ (val) => { this.fieldFill({ dossierSubtype: val, saleTypeId: null, purchaseTypeId: null, operationTypeId: null }) } }
                  customClass={this.state.fields.dossierSubtype ? classNameSelect : ''}

                />
                <Field
                  component={InputSelect}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="fleet"
                  name='fleet'
                  placeholder={t(`${tKey}FLEET`)}
                  controlLabel={t(`${tKey}FLEET`)}
                  options={fleets}
                  valueKey="id"
                  labelKey="value"
                  onInputChange={(val) => this.fieldFill({ fleet: val })}
                  customClass={this.state.fields.fleet ? className : ''}
                  multi={true}
                />
                <Field
                  component={InputText}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="rentalOrderNumber"
                  name='rentalOrderNumber'
                  placeholder={t(`${tKey}RENTAL_ORDER_NUMBER`)}
                  controlLabel={t(`${tKey}RENTAL_ORDER_NUMBER`)}
                  onInputChange={(val) => this.fieldFill({ rentalOrderNumber: val })}
                  customClass={this.state.fields.rentalOrderNumber ? className : ''}
                />
                <Field
                  component={InputText}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="deliveryCity"
                  name='deliveryCity'
                  placeholder={t(`${tKey}DELIVERY_CITY`)}
                  controlLabel={t(`${tKey}DELIVERY_CITY`)}
                  onInputChange={(val) => this.fieldFill({ deliveryCity: val })}
                  customClass={this.state.fields.deliveryCity ? className : ''}
                />
                {settings.clientFieldValidation !== 'toyo' && (
                  <Field
                    component={InputSelect}
                    colWidht={25} // 25, 33, 50, 75, 100 default=25
                    id="pickPreviousVehicle"
                    name='pickPreviousVehicle'
                    placeholder={t(`${tKey}PICK_PREVIOUS_VEHICLE`)}
                    controlLabel={t(`${tKey}PICK_PREVIOUS_VEHICLE`)}
                    options={yesNoCombo}
                    valueKey="id"
                    labelKey="value"
                    onInputChange={(val) => this.fieldFill({ pickPreviousVehicle: val })}
                    customClass={this.state.fields.pickPreviousVehicle ? className : ''}
                  />)}
                <Field
                  component={InputText}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="clientName"
                  name='clientName'
                  placeholder={t(`${tKey}CLIENT_NAME`)}
                  controlLabel={t(`${tKey}CLIENT_NAME`)}
                  onInputChange={(val) => this.fieldFill({ clientName: val })}
                  customClass={this.state.fields.clientName ? className : ''}
                />
                <Field
                  component={InputSelect}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="organizedUnit"
                  name='organizedUnit'
                  placeholder={t(`${tKey}ORGANIZED_UNIT`)}
                  controlLabel={t(`${tKey}ORGANIZED_UNIT`)}
                  options={lowestOrganizedUnitsCombo}
                  valueKey="id"
                  labelKey="value"
                  onInputChange={(val) => this.handleUoChange(val)}
                  customClass={this.state.fields.organizedUnit ? className : ''}
                  isClearable={false}
                />
                <Field
                  component={InputSelect}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="entitySubTypeId"
                  name="entitySubTypeId"
                  key="entitySubTypeId"
                  controlLabel={t(`${tKey}ENTITY_SUB_TYPE_ID`)}
                  placeholder={t(`${tKey}ENTITY_SUB_TYPE_ID`)}
                  onInputChange={(val) => this.handleEntitySubTypeChange(val)}
                  options={entitySubTypeCombo}
                  valueKey="id"
                  labelKey="value"
                  tooltip={!this.state.fields.organizedUnit ? blockedClientFieldsTooltip : ''}
                  customClass={this.state.fields.organizedUnit ? className : 'disabled'}
                  disabled={!this.state.fields.organizedUnit}
                />
                <Field
                  component={InputSelect}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="recurrentClientId"
                  name='recurrentClientId'
                  placeholder={t(`${tKey}ENTITY`)}
                  controlLabel={t(`${tKey}ENTITY`)}
                  options={entityFilterSubTypeCombo}
                  valueKey="id"
                  labelKey="value"
                  onInputChange={(val) => this.fieldFill({ recurrentClientId: val })}
                  customClass={this.state.fields.organizedUnit ? className : 'disabled'}
                  disabled={!this.state.fields.organizedUnit || !this.state.fields.entitySubTypeId}
                />
                <Field
                  component={InputText}
                  colWidht={25} // 25, 33, 50, 75, 100 default=25
                  id="clientCifNif"
                  name='clientCifNif'
                  placeholder={t(`${tKey}CLIENT_CIF_NIF`)}
                  controlLabel={t(`${tKey}CLIENT_CIF_NIF`)}
                  onInputChange={(val) => this.fieldFill({ clientCifNif: val })}
                  customClass={this.state.fields.organizedUnit ? className : 'disabled'}
                  disabled={!this.state.fields.organizedUnit}
                />
                <Field
                  component={InputCheckbox}
                  colWidht={100} // 25, 33, 50, 75, 100 default=25
                  id="onlyFleets"
                  name='onlyFleets'
                  placeholder={t(`${tKey}ONLY_FLEETS`)}
                  controlLabel={t(`${tKey}ONLY_FLEETS`)}
                  onChange={(val) => this.fieldFill({ onlyFleets: val }, this.changeOnlyFleet(val))}
                  customClass={this.state.fields.organizedUnit ? className : ''}
                  checked = {this.state.onlyFleets}
                />
                <div className='search-form-actions flexInLine al-R'>
                  <Button id="btn_search"
                    type="submit"
                    className="_Btn accept stk">
                    <i className="iDocIcon-search"/>
                    {t('SEARCH.ACTIONS.SEARCH_BUTTON')}
                  </Button>
                  <Button
                    id="btn_reset"
                    onClick={(e) => this.handleClickReset(e)}
                    className={'_Btn' + (this.props.pristine ? ' accept' : ' accept')}
                    disabled={this.props.pristine}
                  >
                    <i className="iDocIcon-clear-decline"/> {t('SEARCH.ACTIONS.RESET_BUTTON')}
                  </Button>
                </div>
              </div>
            </Form>
          </div>
          <div className="section-results-body">
            <div className={'searchResoultsContainer' + (this.state.maxiTable ? ' maxiTable' : '')}>
              {hasSearch && ([
                <div className="table-wrapper" key="table-wrapper">
                  <ul className="list-inline"> {/* Active filter list */}
                    {this.selectedFilters().length === 0 ? <li>{t(`${tKey}NO_SELECTED_FILTERS`)}</li> : (this.selectedFilters())}
                  </ul>
                  { hasResults &&
                  <div className='flexInLine al-R'>
                    <div className='actionsField'>
                      {this.state.dossierIdToArray.length === 0 &&
                        <OverlayTrigger placement="right" overlay={actionToolTip}>
                          <Glyphicon className="tooltip-Action" glyph="info-sign"/>
                        </OverlayTrigger>
                      }
                      <Field
                        component={InputSelect}
                        colWidht={100} // 25, 33, 50, 75, 100 default=25
                        id="actions"
                        name='actions'
                        placeholder={t(`${tKey}ACTIONS`)}
                        options={managementActions}
                        valueKey="managementActionId"
                        labelKey="name"
                        onInputChange={(val) => this.launchAction(val)}
                        customClass={this.state.fields.actions ? className : ''}
                        disabled={this.state.dossierIdToArray.length === 0}
                        selected = {this.state.action}
                      />
                    </div>
                    <OverlayTrigger placement="left" overlay={<Tooltip id="editProductTooltip">{`${t((this.state.maxiTable ? 'GLOBAL.TABLES.MINIMIZE_TABLE' : 'GLOBAL.TABLES.MAXIMIZE_TABLE'))}`}</Tooltip>}>
                      <a onClick={() => this.toogleMaxiTable()} className='_Btn accept stk o-icn maximizeBtn'><i className={(this.state.maxiTable ? 'iDocIcon-Minimize' : 'iDocIcon-Maximize')}></i></a>
                    </OverlayTrigger>
                    <OverlayTrigger placement="left" overlay={<Tooltip id="editProductTooltip">{t('SEARCH.ACTIONS.EXPORT')}</Tooltip>}>
                      <a onClick={(event) => this.exportData(event)} className='_Btn new o-icn exportToExcel'><i className="iDocIcon-file-excel"></i></a>
                    </OverlayTrigger>
                  </div>}
                  {/* table */}
                  { hasResults && <div className="text-bold">{selectedFiles}</div> }
                  { hasResults && <div className="table-responsive">
                    <SimpleTablePage
                      columns={[
                        <th key={0} data-field="fleetCode" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}FLEET`)}&nbsp;<i className={this.getIconClass('fleetCode')}></i>
                        </th>,
                        <th key={1} data-field="rentalOrderNumber" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}RENTAL_ORDER_NUMBER`)}&nbsp;<i className={this.getIconClass('rentalOrderNumber')}></i>
                        </th>,
                        <th key={2} data-field="number" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}DOSSIER_NUMBER`)}&nbsp;<i className={this.getIconClass('number')}/>
                        </th>,
                        <th key={3} data-field="vinChassis" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}CHASSIS`)}&nbsp;<i className={this.getIconClass('vinChassis')}></i>
                        </th>,
                        <th key={4} data-field="model" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}MODEL_SHORT`)}&nbsp;<i className={this.getIconClass('model')}/>
                        </th>,
                        <th key={5} data-field="entity" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}ENTITY`)}&nbsp;<i className={this.getIconClass('entity')}/>
                        </th>,
                        <th key={7} data-field="dossierSubType" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}DOSSIER_SUBTYPE`)}&nbsp;<i className={this.getIconClass('dossierSubType')}/>
                        </th>,
                        <th key={6} data-field="deliveryCity" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}DELIVERY_CITY`)}&nbsp;<i className={this.getIconClass('deliveryCity')}/>
                        </th>,
                        <th key={8} data-field="stage" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}STAGE`)}&nbsp;<i className={this.getIconClass('stage')}/>
                        </th>,
                        <th key={9} data-field="salesmanManager" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}SALESMAN_MANAGER`)}&nbsp;<i className={this.getIconClass('salesmanManager')}/>
                        </th>,
                        <th key={10} data-field="organizedUnit" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}ORGANIZED_UNIT`)}&nbsp;<i className={this.getIconClass('organizedUnit')}></i>
                        </th>,
                        <th key={11} data-field="creationDate" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}CREATION_DATE`)}&nbsp;<i className={this.getIconClass('creationDate')}></i>
                        </th>,
                        <th key={12} data-field="check">
                          <Field
                            component={InputCheckbox}
                            name={'checkAll'}
                            // placeholder={t('IMPORT_EXPORT_FILE.CHECK_ALL')}
                            input = {{ value: this.state.checkAll, onChange: (value) => this.checkAll(dossierData, value) }}
                          />
                        </th>
                      ]}
                      rows={dossierData && dossierData.map((row, idx) => (
                        <tr key={idx}>
                          <td>
                            {row.fleetCode}
                          </td>
                          <td>
                            {row.rentalOrderNumber}
                          </td>
                          <td>
                            <Link to={`/dossier${row.urlToNavigate}`}>
                              {row.number}
                            </Link>
                          </td>
                          <td>
                            {row.vehicle.vehicleVinChassis}
                          </td>
                          <td>
                            {row.vehicle.vehicleModel}
                          </td>
                          <td className="see-more">
                            <EntityPopover
                              t={t}
                              entity={row.entity}
                              half={(vehicleData.length || 1) / (idx + 1)}
                              idx={idx}
                              id={'popover-entity' + idx}
                              urgencyType={row.urgencyType}
                            />
                          </td>
                          <td>{row.dossierSubType}</td>
                          <td>{row.deliveryCity}</td>
                          <td>{row.stage}</td>
                          <td>{row.salesManagerName}</td>
                          <td>
                            {row.organizedUnit}
                          </td>
                          <td>
                            {row.creationDate}
                          </td>
                          <td>{
                            <Field
                              component={InputCheckbox}
                              name={'selectedCheck_' + row.dossierId}
                              input = {{ value: (this.state.dossierIdToArray.length > 0 && this.state.dossierIdToArray.includes(row.dossierId)) || (this.state.checkAll), onChange: (value) => this.addDossierIdToArray(value, row.dossierId) }}
                            />}
                          </td>
                        </tr>
                      ))}
                    />
                  </div> }
                  <div className="search-footer" key="search-footer">
                    {hasResults ? (<IDocCarPagination
                      id="btn_pag_sales"
                      page={page}
                      pagesCount={pages}
                      totalRows= {count}
                      onSelectAction={(page) => this.onPageChange(page)}
                    />) : <p className='no_results'>{t('GLOBAL.TABLES.NO_RESULTS')}</p>}
                  </div>
                </div>
              ])}
            </div>
          </div>
        </div>
        <Modal className="_nf fleetFinderModal" show={shoModalFinderFleet} onHide={this.props.actions.closeActionFleetFinder}>
          <Modal.Header closeButton onHide={this.props.actions.closeActionFleetFinder}>
            <Modal.Title>{t(`${tKey}TITLE_EDIT_FLEET`)}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='search-form'>
              <FleetFinderPage resetFilters = {this.props.actions.resetFilters} fleetsFilter={{ ...this.props.filters, ...this.state.dynamicFilters }} fetchFleets={this.externalSearch}></FleetFinderPage>
              <Row>
                <ProgressBar style={{ display: this.state.displayBar }}>
                  <ProgressBar max={this.state.maxBar} now={this.state.progress} label={`${this.state.progress}/${this.state.maxBar}`} striped variant="success" key={1} />
                  <ProgressBar max={this.state.maxBar} now={this.state.progressFail} label={`${this.state.progressFail}/${this.state.maxBar}`} striped variant="danger" style={{ backgroundColor: '#d44b4b' }} key={2} />
                </ProgressBar>
              </Row>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Col className="flexInLine al-R">
              <Button
                id="role-modal-submit-button"
                className="_Btn new"
                onClick = {() => this.newfleet()}>
                <i class="iDocIcon-add-plus"></i>
                {t(`${tKey}NEW_FLEET`)}
              </Button>
              <Button
                id="role-modal-submit-button"
                className="_Btn cancel stk"
                onClick = {() => this.deleteOfFleet()}>
                <i class="iDocIcon-delete-forever"></i>
                {t(`${tKey}DELETE_OF_FLEET`)}
              </Button>
              <Button
                id="role-modal-submit-button"
                className="_Btn cancel"
                onClick = {this.props.actions.closeActionFleetFinder}>
                {t(`${tKey}CANCEL`)}
              </Button>
            </Col>
          </Modal.Footer>
        </Modal>
        <AddNewFleetModalPage resetFilters= {this.props.actions.resetFilters} fleetsFilter={{ ...this.props.filters, ...this.state.dynamicFilters }} fetchFleets={this.externalSearch} dossier={dossier} ></AddNewFleetModalPage>
        <DiferentDocumentUploadModalPage resetFilters= {this.props.actions.resetFilters} fleetsFilter={{ ...this.props.filters, ...this.state.dynamicFilters }} fetchFleets={this.externalSearch} change={this.props.change}></DiferentDocumentUploadModalPage>
        <EconomicPlanModalPage resetFilters= {this.props.actions.resetFilters} fleetsFilter={{ ...this.props.filters, ...this.state.dynamicFilters }} fetchFleets={this.externalSearch}></EconomicPlanModalPage>
      </>
    )
  }
}

const validate = (values, { t }, props) => {
  const errors = {}

  if (!values || !values.organizedUnit) {
    errors.organizedUnit = t('GLOBAL.FORMS.REQUIRED')
  }

  if (values && values.creationDateFrom && values.creationDateTo) {
    let dateTo = convertStringToDate(values.creationDateTo)
    let dateFrom = convertStringToDate(values.creationDateFrom)
    if (dateFrom > dateTo) {
      errors.creationDateTo = t('GLOBAL.FORMS.DATE_TO_NOT_LESS_THAN_DATE_FROM')
    }
  }
  return errors
}

export default reduxForm({
  form: 'fleetsSearch',
  validate
})(Fleets)
