
import React, { Component } from 'react'
import { Button, OverlayTrigger, Tooltip, Form } from 'react-bootstrap'
import { Field, reduxForm, reset } from 'redux-form'
import settings from '../../../setting'
import SimpleTablePage from '../../../containers/commons/general/SimpleTablePage'
import IDocCarPagination from '../../../components/commons/iDocCarPagination'
import { clientInformation, urgencyTypesClass } from '../../../constants/backendIds'
import { Link } from 'react-router-dom'
import EntityPopover from './EntityPopover'
import VehiclePopover from './VehiclePopover'
import MapSectionsSearch from '../../../_v2/util/map-sections-search'
import { showFilter, hideFiltersBtn, toggleFilterClass, getIconClass } from '../../../_v2/util/management/ManagementUtils'
import { fromCamelToUnderscore } from '../../../util/utils'
/// //////////////////////////////////////////
import InputText from '../commons/form/InputText'
import InputSelect from '../commons/form/InputSelect'
import InputCheckBox from '../commons/form/InputCheckBox'

class SearchByDossierSimpleForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      fields: {}
    }
  }

  componentDidMount () {
    for (const dynamicFilter in this.props.dynamicFilters) {
      this.props.change(dynamicFilter, this.props.dynamicFilters[dynamicFilter])
    }
    new Promise((resolve, reject) => {
      this.props.actions.fetchDossierSubTypes(null, null, [], resolve, reject)
    }).then(() => {
      if (this.props.combos && this.props.combos.dossierSubtypesCombo && this.props.combos.dossierSubtypesCombo.length > 0) {
        this.props.change('dossier.dossierSubtype', this.props.combos.dossierSubtypesCombo[0].id)
      }
    })
  }

  componentDidUpdate () {
    // getSnapshotBeforeUpdate() should be used with componentDidUpdate()
  }

  exportData () {
    const tKey = 'SEARCH.CATEGORIES.DOSSIER.'
    let columnsExcel = []
    columnsExcel.push(this.props.t(`${tKey}URGENCY`) + '#Urgency')
    columnsExcel.push(this.props.t(`${tKey}DOSSIER_NUMBER`) + '#Number')
    columnsExcel.push(this.props.t(`${tKey}ORGANIZED_UNIT`) + '#Uo')
    columnsExcel.push(this.props.t('SEARCH.CATEGORIES.ENTITY.BY_ENTITY') + '#Entity')
    columnsExcel.push(this.props.t('SEARCH.CATEGORIES.VEHICLE.BY_VEHICLE') + '#Vehicle')
    columnsExcel.push(this.props.t(`${tKey}DOSSIER_SUBTYPE`) + '#SubType')
    columnsExcel.push(this.props.t(`${tKey}STAGE`) + '#Stage')
    if (settings.clientFieldValidation === clientInformation.QUADIS) {
      columnsExcel.push(this.props.t(`${tKey}CREATOR_ID`) + '#Creator')
      columnsExcel.push(this.props.t(`${tKey}SALESMAN_MANAGER`) + '#SalesManagerName')
    }
    columnsExcel.push(this.props.t(`${tKey}CREATION_DATE`) + '#CreationDate')
    columnsExcel.push(this.props.t(`${tKey}URL`) + '#UrlToNavigate')

    this.exportToExcel(columnsExcel)
  }

  exportToExcel (data) {
    let cols = ''
    data.forEach(element => {
      cols += element + '$'
    })
    cols = cols.substring(0, cols.length - 1)
    let values = this.props.dynamicFilters
    this.props.actions.fetchDossiersSimpleDownload({ ...values.dossier, ...this.props.filters }, cols)
  }

  handleClickReset (e) {
    if (e !== undefined) {
      e.preventDefault()
    }

    this.props.dispatch(reset('SearchByDossierSimpleForm'))
    this.state.dossierIdToArray = []
    this.setState({ onlyFleets: true, checkAll: false, dossierIdToArray: [], dossierData: [], fields: [] })
  }

  submitSearchForm (values) {
    // const config = { formValuesName: 'dossier', fetchAction: (value) => this.props.actions.fetchDossiersSimple({ ...value, ...this.props.filters }), emptyFilterModal: 'mustFillFiltersDossierSimple', customValidation: (values) => { return values.dossier && !values.dossier.dealerNumber } }

    if (values.dossier && !values.dossier.dealerNumber) {
      return this.props.actions.openModal('mustFillFiltersDossierSimple')
    }

    this.props.actions.fetchDossiersSimple({ ...values.dossier, ...this.props.filters })
    this.setState({ dossierIdToArray: [], dynamicFilters: values, filterValues: { ...values.dossier, ...this.props.filters } })

    this.props.actions.setFilters(values)
  }

  onPageChange (page) {
    this.setState({ filterValues: { ...this.state.filterValues, page: page } })
    this.props.actions.fetchDossiersSimple({ ...this.state.filterValues, page })
  }

  onOrderChange (e) {
    const newOrderBy = this.props.filters.orderBy.includes(`%2B${e.currentTarget.dataset.field}`) ? `-${e.currentTarget.dataset.field}` : `%2B${e.currentTarget.dataset.field}`
    this.setState({ filterValues: { ...this.state.filterValues, orderBy: newOrderBy } })
    this.props.actions.fetchDossiersSimple({ ...this.state.filterValues, orderBy: newOrderBy })
  }

  toogleHideFilters () {
    this.props.actions.setHideFilters(!this.props.hideFilters)
  }

  toogleMaxiTable () {
    this.setState({ maxiTable: !this.state.maxiTable })
  }

  selectedFilters () {
    const filters = this.props.dynamicFilters
    const tKey = 'SEARCH.CATEGORIES.'
    return Object.keys(filters)
      .map((item) => {
        let text = item
        let value = null
        const list = []
        Object.keys(filters[item]).map((filter, index) => {
          text = this.props.t(`${tKey}${item.toUpperCase()}.${fromCamelToUnderscore(filter).toUpperCase()}`)
          switch (filter) {
            case 'dossierSubtype':
            { const selectedSubtype = this.props.combos.dossierSubtypesCombo.find(subtype => subtype.id === filters[item][filter])
              value = selectedSubtype && selectedSubtype.value
              break }
            default:
            { value = filters[item][filter]
              break }
          }

          if (value) {
            list.push(<li key={index}>{text}: <strong>{value}</strong>;</li>)
          }
        })
        return list
      })
  }

  render () {
    const {
      t, combos: { dossierSubtypesCombo }, fields, fieldFill, className, classNameSelect, hasSearch, dossierData, vehicleData,
      pages, count, hideFilters,
      handleSubmit, filters: { page } // From search
    } = this.props
    const tKey = 'SEARCH.CATEGORIES.DOSSIER.'
    const secMapSearch = MapSectionsSearch.find(function (s) { return s.page === 'dossierSimplePage' })
    const hasResults = dossierData && dossierData.length > 0

    return (
      <div className="inner-results-panel">
        <div className="section-results-header">
          <div className="section-header-title">
            <OverlayTrigger placement="right" overlay={<Tooltip id="editProductTooltip">{`${t((this.props.hideMenu ? 'GLOBAL.TABLES.SHOW_INNER_MENU' : 'GLOBAL.TABLES.HIDE_INNER_MENU'))}`}</Tooltip>}>
              <a onClick={() => this.props.toogleInnerMenu()}s className={this.props.hideMenu ? 'collapseBtn' : 'collapseBtn closeInnerMenu'}><i className={this.props.hideMenu ? 'iDocIcon-Open-inner-menu' : 'iDocIcon-Close-inner-menu'}></i></a>
            </OverlayTrigger>
            <h4>{t(`${secMapSearch.title}`)}
              <OverlayTrigger placement="left" overlay={<Tooltip id="editProductTooltip">{`${t((hideFilters ? 'GLOBAL.TABLES.SHOW_FILTERS' : 'GLOBAL.TABLES.HIDE_FILTERS'))}`}</Tooltip>}>
                <a onClick={() => this.toogleHideFilters()} className={'toogleShowFilters ' + hideFiltersBtn(hasResults)}><i className={toggleFilterClass(hasResults, hideFilters)}></i></a>
              </OverlayTrigger>
            </h4>
          </div>
          <Form autoComplete="off" onSubmit={handleSubmit((values) => this.submitSearchForm(values))} className="filters ">
            <div className={'search-form ' + (showFilter(hasResults, hideFilters) ? '' : 'hideFilters ')}>
              <Field
                component={InputText}
                colWidht={50} // 25, 33, 50, 75, 100 default=25
                id="dealerIdNumberDossierSimple"
                name='dossier.dealerNumber'
                placeholder={t(`${tKey}DEALER_NUMBER`)}
                controlLabel={t(`${tKey}DEALER_NUMBER`)}

                type="text"
                maxLength={settings.dealerMaxLength}
                onInputChange={(val) => fieldFill({ dealerIdNumber: val })}
                customClass={fields.dealerIdNumber ? className : ''}
              />
              <Field
                component={InputSelect}
                colWidht={50} // 25, 33, 50, 75, 100 default=25
                id="dossierSubtype"
                name='dossier.dossierSubtype'
                placeholder={t(`${tKey}DOSSIER_SUBTYPE`)}
                controlLabel={t(`${tKey}DOSSIER_SUBTYPE`)}
                options={dossierSubtypesCombo}
                valueKey="id"
                labelKey="value"
                onInputChange={(val) => fieldFill({ dossierSubtype: val })}
                customClass={fields.dossierSubtype ? classNameSelect : ''}
              />
              <div key="clearfix_1" className="clearfix" />
              <Field
                component={InputCheckBox}
                colWidht={100} // 25, 33, 50, 75, 100 default=25
                id="searchHistorical"
                name="dossier.searchHistorical"
                placeholder={t(`${tKey}SEARCH_HISTORICAL`)}

                onInputChange={(val) => fieldFill({ searchHistorical: val })}
              />
              <div className='search-form-actions flexInLine al-R'>
                <Button id="btn_search"
                  type="submit"
                  className="_Btn accept stk">
                  <i className="iDocIcon-search"/>
                  {t('SEARCH.ACTIONS.SEARCH_BUTTON')}
                </Button>
                <Button
                  id="btn_reset"
                  onClick={(e) => this.handleClickReset(e)}
                  className={'_Btn' + (this.props.pristine ? ' accept' : ' accept')}
                  disabled={this.props.pristine}
                >
                  <i className="iDocIcon-clear-decline"/> {t('SEARCH.ACTIONS.RESET_BUTTON')}
                </Button>
              </div>
            </div>
          </Form>
        </div>
        <div className="section-results-body">
          <div className={'searchResoultsContainer' + (this.state.maxiTable ? ' maxiTable' : '')}>
            {hasSearch && ([
              <div className="table-wrapper" key="filters-selected">
                <ul className="list-inline pull-left">
                  {this.selectedFilters().length === 0 ? <li>{t(`${tKey}NO_SELECTED_FILTERS`)}</li> : (this.selectedFilters())}
                </ul>
                { hasResults &&
                  <div className='flexInLine al-R'>
                    <OverlayTrigger placement="left" overlay={<Tooltip id="editProductTooltip">{`${t((this.state.maxiTable ? 'GLOBAL.TABLES.MINIMIZE_TABLE' : 'GLOBAL.TABLES.MAXIMIZE_TABLE'))}`}</Tooltip>}>
                      <a onClick={() => this.toogleMaxiTable()} className='_Btn accept stk o-icn maximizeBtn'><i className={(this.state.maxiTable ? 'iDocIcon-Minimize' : 'iDocIcon-Maximize')}></i></a>
                    </OverlayTrigger>
                    <OverlayTrigger placement="left" overlay={<Tooltip id="editProductTooltip">{t('SEARCH.ACTIONS.EXPORT')}</Tooltip>}>
                      <a onClick={(event) => this.exportData(event)} className='_Btn new o-icn exportToExcel'><i className="iDocIcon-file-excel"></i></a>
                    </OverlayTrigger>
                  </div>
                }
                {/* table */}
                { hasResults &&
                  <div className="table-responsive">
                    <SimpleTablePage
                      columns={[
                        <th key={0} />,
                        <th key={1} data-field="number" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}DOSSIER_NUMBER`)}&nbsp;<i className={getIconClass('number', this.props.filters)}/>
                        </th>,
                        <th key={2} data-field="organizedUnit" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}ORGANIZED_UNIT`)}&nbsp;<i className={getIconClass('organizedUnit', this.props.filters)}/>
                        </th>,
                        <th key={3}>{t('SEARCH.CATEGORIES.ENTITY.BY_ENTITY')}</th>,
                        <th key={4}>{t('SEARCH.CATEGORIES.VEHICLE.BY_VEHICLE')}</th>,
                        <th key={5} data-field="dossierSubType" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}DOSSIER_SUBTYPE`)}&nbsp;<i className={getIconClass('dossierSubType', this.props.filters)}/>
                        </th>,
                        <th key={6} data-field="stage" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}STAGE`)}&nbsp;<i className={getIconClass('stage', this.props.filters)}/>
                        </th>,
                        settings.clientFieldValidation === clientInformation.QUADIS && ([
                          <th key={7} data-field="creator" onClick={(event) => this.onOrderChange(event)}>
                            {t(`${tKey}CREATOR_ID`)}&nbsp;<i className={getIconClass('creator', this.props.filters)}/>
                          </th>,
                          <th key={8} data-field="salesmanManager" onClick={(event) => this.onOrderChange(event)}>
                            {t(`${tKey}SALESMAN_MANAGER`)}&nbsp;<i className={getIconClass('salesmanManager', this.props.filters)}/>
                          </th>
                        ]),
                        <th key={9} data-field="creationDate" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}CREATION_DATE`)}&nbsp;<i className={getIconClass('creationDate', this.props.filters)}/>
                        </th>
                      ]}
                      rows={dossierData && dossierData.map((row, idx) => (
                        <tr key={idx}>
                          <td>
                            <span className={`color-point ${urgencyTypesClass[row.urgencyType] || 'grey'}`}/>
                          </td>
                          <td>
                            <Link to={`/dossier${row.urlToNavigate}`}>
                              {row.number}
                            </Link>
                          </td>
                          <td>{row.organizedUnit}</td>
                          <td className="see-more">
                            <EntityPopover
                              t={t}
                              entity={row.entity}
                              half={(vehicleData.length || 1) / (idx + 1)}
                              idx={idx}
                              id={'popover-entity' + idx}
                              urgencyType={row.urgencyType}
                            />
                          </td>
                          <td className="see-more">
                            <VehiclePopover
                              t={t}
                              vehicle={row.vehicle}
                              half={(vehicleData.length || 1) / (idx + 1)}
                              idx={idx}
                              id={'popover-vehicle' + idx}
                            />
                          </td>
                          <td>{row.dossierSubType}</td>
                          <td>{row.stage}</td>
                          {
                            settings.clientFieldValidation === clientInformation.QUADIS && ([
                              <td>{row.creatorId}</td>,
                              <td>{row.salesManagerName}</td>
                            ])
                          }
                          <td>{row.creationDate}</td>
                        </tr>
                      ))}
                    />
                  </div>
                }
                <div className="search-footer" key="search-footer">
                  {hasResults ? (
                    <IDocCarPagination
                      id="btn_pag_sales"
                      page={page}
                      pagesCount={pages}
                      totalRows= {count}
                      onSelectAction={(page) => this.onPageChange(page)}/>
                  ) : <p className='no_results'>{t('GLOBAL.TABLES.NO_RESULTS')}</p>
                  }
                </div>
              </div>
            ])}
          </div>
        </div>
      </div>
    )
  }
}

const validate = (values, { t }) => {
  const errors = {}
  if (values) {
    if (values.dossier && values.dossier.chassis && values.dossier.chassis.length < settings.chassisMinCharacter) {
      errors.dossier = {}
      errors.dossier.chassis = t('SEARCH.CATEGORIES.ERRORS.VINCHASIS').replace('##MIN_CHARACTERS##', settings.chassisMinCharacter)
    }
    if (values.vehicle && values.vehicle.chassis && values.vehicle.chassis.length < settings.chassisMinCharacter) {
      errors.vehicle = {}
      errors.vehicle.chassis = t('SEARCH.CATEGORIES.ERRORS.VINCHASIS').replace('##MIN_CHARACTERS##', settings.chassisMinCharacter)
    }
  }

  return errors
}

export default reduxForm({
  form: 'SearchByDossierSimpleForm',
  validate
})(SearchByDossierSimpleForm)
