import headerActionTypes from '../../constants/actions/header/header'

export function setHeaderData (values) {
  return {
    type: headerActionTypes.SET_HEADER,
    values
  }
}

export function setHeaderDataSuccess (values) {
  return {
    type: headerActionTypes.SET_HEADER_SUCCESS,
    values
  }
}

export default {
  setHeaderData,
  setHeaderDataSuccess
}
