import React, { PureComponent } from 'react'
import { Field, reduxForm } from 'redux-form'
import { Row, Button, Modal, Form, Col } from 'react-bootstrap'
import InputText from '../../commons/form/InputText'

class MassiveDownloadCreateModal extends PureComponent {
  createDownload (values) {
    new Promise((resolve, reject) => {
      this.props.actions.createMassiveDownload(this.props.documentIds.toString(), values.fileName, values.observations, resolve)
    }).then((result) => {
      this.props.actions.closeModal()
    })
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (prevProps.showModal !== this.props.showModal) {
      this.props.initialize()
    }
    return null
  }

  componentDidUpdate () {
    // getSnapshotBeforeUpdate() should be used with componentDidUpdate()
  }

  render () {
    const { t, actions: { closeModal }, showModal, handleSubmit } = this.props
    const tKey = 'DOCUMENT_SEARCH.MASSIVE_DOWNLOAD.'
    return (
      <Modal className="add-user-modal" show={showModal} onHide={closeModal}>
        <Form autoComplete="off" onSubmit={handleSubmit(this.createDownload.bind(this))}>
          <Modal.Header closeButton>
            <Modal.Title>{t(`${tKey}TITLE_MODAL`)}</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <Row>
              <Field
                colSm={14}
                controlLabel={t(`${tKey}FILE_NAME`)}
                placeholder={t(`${tKey}FILE_NAME`)}
                id="fileName"
                name="fileName"
                component={InputText}
                maxLength={16}
              />
            </Row>

            <Row>
              <Field
                colSm={14}
                controlLabel={t(`${tKey}OBSERVATIONS`)}
                placeholder={t(`${tKey}OBSERVATIONS`)}
                componentClass="textarea"
                id="observations"
                name="observations"
                component={InputText}
              />
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Col sm={7} className="btn-wrapper">
              <Button id="btn_save" className="btn-standard" type="submit">{t('GLOBAL.BUTTONS.CREATE_DOWNLOAD')}</Button>
              <Button id="btn_no_modal" className="btn-standard-grey" onClick={() => { closeModal() } }>{t('GLOBAL.BUTTONS.CANCEL')}</Button>
            </Col>
          </Modal.Footer>
        </Form>
      </Modal>
    )
  }
}

const validate = (values, { t, reasonCombo, commentValidation }) => {
  const tKey = 'DOCUMENT_SEARCH.MASSIVE_DOWNLOAD.'
  const errors = {}
  if (!values.fileName) {
    errors.fileName = t(`${tKey}ERROR_REQUIRED`)
  }
  if (values.fileName && !values.fileName.match(/^[A-Za-z0-9]*$/)) {
    errors.fileName = t(`${tKey}ERROR_FORMAT`)
  }
  return errors
}

export default
reduxForm({
  form: 'massiveDownloadCreateModal',
  validate
})(MassiveDownloadCreateModal)
