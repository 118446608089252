import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getFormValues } from 'redux-form'
import PaperworksTable from '../../../../_v2/components/dossiers/common/paperworksTable/PaperworksTable'
import paperworkComponentActions from '../../../../actions/dossier/common/paperworksTable'
import { openDocumentEditionModal, signTypes, requestSign } from '../../../../actions/dossier/common/documentEditionModal'
import { openErrorMessageModal } from '../../../../actions/modals/messageModal'
import { setActiveSection } from '../../../../actions/dossier/common/buttonsSidebar'
import { push } from 'react-router-redux'
import { translate } from 'react-polyglot'
import { fetchProcessingAgencyManagersCombo } from '../../../../actions/combos/combos'

export function mapStateToProps (state) {
  return {
    ...state.paperworkTable,
    editedPaperwork: getFormValues('paperwork_edition_modal')(state),
    combos: {
      paperworkTypes: state.combos.paperworkTypes,
      processingAgency: state.combos.processingAgency,
      economicTargetTypes: state.combos.economicTargetTypes
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      ...paperworkComponentActions,
      openDocumentEditionModal,
      signTypes,
      requestSign,
      openErrorMessageModal,
      navigateTo: push,
      setActiveSection,
      fetchProcessingAgencyManagersCombo
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(PaperworksTable))
