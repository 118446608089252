import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { fetchDossierSubTypes, fetchSearchDossierSubType, fetchEntitySubTypeCombo, fetchOrganizedUnitsAcceptClientsCombo, fetchOrganizedUnitsCombo } from '../../../actions/combos/combos'
import { initializeSearch, resetFilters } from '../../../actions/search/search'
import { openModal, yesNoModal } from '../../../actions/common'
import FleetsManager_v2 from '../../../_v2/components/management/FleetsManager'
import {
  fetchFleetsManagement, resetFleetManagementFilters, modifyFleetManagementFilters, exportFleetsManagement, openCreateEditFleetModal, getFleetModel, openEditFleetModal,
  openOperateFleetModal, openDocumentFleetModal, openEconomicFleetModal, showFrontEconomicPlan, getDossierDetailsFleet, showHistoricalFleetModal
} from '../../../actions/management/management'
import { setDeleteFleetProcess } from '../../../actions/unattendedProcess/unattendedProcess'

export function mapStateToProps (state) {
  return {
    ...state.management.fleetsManagementFinder,
    combos: {
      ...state.combos
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchDossierSubTypes,
      fetchSearchDossierSubType,
      initializeSearch,
      resetFilters,
      openModal,
      yesNoModal,
      fetchEntitySubTypeCombo,
      fetchFleetsManagement,
      resetFleetManagementFilters,
      modifyFleetManagementFilters,
      exportFleetsManagement,
      openCreateEditFleetModal,
      getFleetModel,
      openEditFleetModal,
      openOperateFleetModal,
      openDocumentFleetModal,
      setDeleteFleetProcess,
      openEconomicFleetModal,
      showFrontEconomicPlan,
      getDossierDetailsFleet,
      showHistoricalFleetModal,
      fetchOrganizedUnitsAcceptClientsCombo,
      fetchOrganizedUnitsCombo
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(FleetsManager_v2))
