import React, { PureComponent } from 'react'
import { Row, Col, Panel, Button } from 'react-bootstrap'
import { Field } from 'redux-form'
import InputText from '../../commons/form/InputText'
import { dossierTypes as dossierTypesConstant } from '../../../constants/dossier/common/dossierTypes'
import Client from '../../dossiers/common/Client'
import { validateDate } from '../../../util/validationFunctions'
import { dossierSubTypeId } from '../../../constants/dossier/common/dossierSubType'
import InputSelect from '../../commons/form/InputSelect'
import InputDatePicker from '../../commons/form/InputDatePicker'
import { getDateNowMinDatePicker } from '../../../util/utils'
import { stringLength } from '../../../constants/management/fleets'
import InputTreeSelect from '../../commons/form/InputTreeSelect'
import permissionCodes from '../../../constants/permissionCodes'

class FleetAndClientComponent extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      disableClient: true,
      organizedUnit: null,
      dossierSubTypeId: null,
      loadedOpenPanelsFlags: false,
      openPanelFleet: false,
      openPanelClient: false,
      initialized: false,
      createMode: false
    }
  }

  componentDidMount () {
    this.props.actions.fetchIaeCombo()
    if (this.props.OUTreeComboPermission === permissionCodes.createFleetManagement) {
      this.props.actions.fetchOrganizedUnitsTreeComboByPermission(this.props.OUTreeComboPermission)
      this.setState({ createMode: true, openPanelFleet: true })
    } else {
      this.props.actions.fetchOrganizedUnitsCombo()
      this.setState({ createMode: false })
    }
    this.props.actions.fetchOrganizedUnitsAcceptClientsCombo()
    this.props.actions.fetchEntityTypes()
  }

  getClientPermissions (organizedUnitId) {
    if (organizedUnitId) {
      this.props.actions.getClientFleetPermissions(null, organizedUnitId, 1)
    } else if (this.state.organizedUnit && this.state.organizedUnit !== null) {
      this.props.actions.getClientFleetPermissions(null, this.state.organizedUnit, 1)
    }
  }

  fieldFill (field) {
    this.setState({ fields: { ...this.state.fields, ...field } })
  }

  collapsePanelFleet () {
    this.setState({ openPanelFleet: !this.state.openPanelFleet })
  }

  collapsePanelClient () {
    this.setState({ openPanelClient: !this.state.openPanelClient })
  }

  validateDate (value, allValues, props) {
    const result = validateDate(value, props)
    return result === Object(result) ? undefined : result
  }

  updateComponentAfterDeleteAndRestart () {

  }

  componentDidUpdate () {
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (this.props.fleet && !this.props.create && !this.state.initialized) {
      this.setState({ organizedUnit: this.props.fleet.organizedUnitId, initialized: true })
      this.getClientPermissions(this.props.fleet.organizedUnitId)
    }
    if (this.props.openPanelFleet !== undefined && (!this.state.loadedOpenPanelsFlags || this.props.openPanelFleet !== prevProps.openPanelFleet)) {
      this.setState({ openPanelFleet: this.props.openPanelFleet })
    }
    if (this.props.openPanelClient !== undefined && (!this.state.loadedOpenPanelsFlags || this.props.openPanelClient !== prevProps.openPanelClient)) {
      this.setState({ openPanelClient: this.props.openPanelClient })
    }
    if (!this.state.loadedOpenPanelsFlags) {
      this.setState({ loadedOpenPanelsFlags: true })
    }
    return null
  }

  viewHistorical () {
    this.props.actions.showHistoricalFleetModal && this.props.actions.showHistoricalFleetModal(true, this.props.fleet)
  }

  foreignPassportWarning (values) {
    // Temporary solution to avoid client error caused by parallel history that cannot be integrated here yet
  }

  organizedUnitChange (val) {
    this.setState({ organizedUnit: val })
    this.getClientPermissions(val)
  }

  render () {
    const {
      t, change,
      combos, readOnlyFields, entityComponent, formValues, entityChildComponent, combos: { dossierSubTypesCombo, UOTreeCombo, UOTreeComboByPermission }, dispatch,
      stopAsyncValidation,
      asyncValidateFunc,
      actions: {
        fetchClientByDni,
        fetchClientByDniEntityChild,
        fetchClientFromDocument,
        setEntityTypeId,
        setEntitySubTypeId,
        setEntityId,
        fetchEntitySubTypeCombo,
        fetchEntityFilterSubTypeCombo,
        setEntityChildTypeId,
        clearEntity,
        clearEntityChild,
        fetchSalesClient,
        setEntityValidationDisable,
        setActiveSection,
        fetchCommercialSocietyTypeCombo,
        fetchEntityCountryTypeCombo,
        fetchEntityStateTypeCombo,
        fetchEntityCityTypeCombo,
        fetchEntityTownTypeCombo,
        fetchEntityColonyTypeCombo,
        fetchEntityCPTypeCombo,
        fetchEntityMaritalStatusCombo,
        openModal,
        fetchActivityBpro,
        validate,
        resetValues,
        fetchClientDataAddress,
        clearClientFields
      }
    } = this.props
    const tKey = 'MANAGEMENT.FLEETS_TAB.'
    const isReadOnly = this.props.isReadOnly || readOnlyFields
    let styleVisibility = this.props.create ? 'collapse' : 'visible'

    let displayClient = this.state.organizedUnit !== null && this.state.organizedUnit !== undefined ? '' : 'none'

    let dossier = {
      isPreCancelled: false,
      entityComponent: entityComponent,
      dossierParentId: null,
      parentEntityId: null,
      entityChildComponent: entityChildComponent,
      entityTypeIdDefault: null,
      salesComponent: null,
      entityFromParentDossier: null,
      dossierSubTypeId: dossierSubTypeId.venta_vn,
      organizedUnitId: this.state.organizedUnit,
      dossierId: -1
    }

    let title = this.props.create ? '' : this.props.fleet?.fleetCode + ' ' + this.props.fleet?.description
    if (title.length > stringLength.TITLE) {
      title = title.substring(0, stringLength.TITLE) + '...'
    }

    let titleClient = this.props.create || !this.props.fleet?.entityComponent?.dniCif ? '' : this.props.fleet?.entityComponent?.dniCif + ' - ' + this.props.fleet?.entityComponent?.name

    if (titleClient.length > stringLength.TITLE) {
      titleClient = titleClient.substring(0, stringLength.TITLE) + '...'
    }

    let bar = this.props.create ? '' : ' | '
    return (
      <>
        <div className="fleet-panel dossier-panel" >
          <div className="header-panel fleet-panel-box" onClick={() => this.collapsePanelFleet()}>

            <div className="name-wrapper fleet-panel-box-title">
              <h4>{t(`${tKey}FLEET`)} {bar}  <span className='fleet-panel-subTitle'>{title}</span></h4>
            </div>

            <i className={this.state.openPanelFleet ? 'ico-arrow-black-down fleet-panel-box-caret' : 'ico-arrow-black-up fleet-panel-box-caret'} />

          </div>
          <Panel expanded={this.state.openPanelFleet} onToggle={() => {}}>
            <Panel.Collapse>
              <Row className="inputs-wrapper-fleet">

                <Field
                  id="organizedUnitId"
                  name='organizedUnitId'
                  placeholder={t(`${tKey}ORGANIZED_UNIT`)}
                  controlLabel={t(`${tKey}ORGANIZED_UNIT`)}
                  component={InputTreeSelect}
                  multi={false}
                  allowClear={true}
                  valueKey="value"
                  labelKey="label"
                  childrenKey="children"
                  options={this.state.createMode ? UOTreeComboByPermission : UOTreeCombo}
                  onInputChange={(val) => this.organizedUnitChange(val)}
                  disabled={!this.props.create || isReadOnly}
                  showSearch={false}
                />
                <Field
                  id="fleetCode"
                  name='fleetCode'
                  placeholder={t(`${tKey}CODE`)}
                  controlLabel={t(`${tKey}CODE`)}
                  component={InputText}
                  type="text"
                  maxLength={20}
                  disabled={isReadOnly}
                />
                <Field
                  id="dossierSubTypeId"
                  name='dossierSubTypeId'
                  placeholder={t(`${tKey}DOSSIER_SUBTYPE`)}
                  controlLabel={t(`${tKey}DOSSIER_SUBTYPE`)}
                  component={InputSelect}
                  options={dossierSubTypesCombo}
                  valueKey="id"
                  labelKey="value"
                  onInputChange={ (val) => { this.setState({ dossierSubTypeId: val }) }}
                  disabled={!this.props.create || isReadOnly}
                />
                <Field
                  id="renewalDate"
                  name='renewalDate'
                  placeholder={t(`${tKey}RENEWAL_DATE`)}
                  controlLabel={t(`${tKey}RENEWAL_DATE`)}
                  component={InputDatePicker}
                  validate={[this.validateDate]}
                  onInputChange={(val) => this.fieldFill({ renewalDate: val })}
                  minDate={getDateNowMinDatePicker(1)}
                  disabled={isReadOnly}
                  showTodayButton={false}
                />
                <div className="col-sm-3" id='div-create-dossier-count' style={{ visibility: styleVisibility }}>
                  <Field sm={12}
                    id="dossierCount"
                    name='dossierCount'
                    placeholder={t(`${tKey}NUM_DOSSIERS`)}
                    controlLabel={t(`${tKey}NUM_DOSSIERS`)}
                    component={InputText}
                    disabled={true}
                  />
                </div>
                <Col sm={9}>
                  <Field
                    id="description"
                    name='description'
                    placeholder={t(`${tKey}DESCRIPTION`)}
                    controlLabel={t(`${tKey}DESCRIPTION`)}
                    component={InputText}
                    type="text"
                    maxLength={60}
                    customClass = 'col-description'
                    disabled={isReadOnly}
                  />
                </Col>
              </Row>

              <Row className="inputs-wrapper-fleet">
                <div className="col-sm-3" id='div-create-creation-date' style={{ visibility: styleVisibility }}>
                  <Field
                    id="creationDate"
                    name='creationDate'
                    placeholder={t(`${tKey}CREATION_DATE`)}
                    controlLabel={t(`${tKey}CREATION_DATE`)}
                    component={InputText}
                    disabled={true}
                    customClass='creation-date-edit'
                  />

                  <Button
                    id="role-modal-submit-button"
                    className="btn-standard historic-button-edit"
                    onClick = {() => this.viewHistorical()}>
                    {t(`${tKey}HISTORICAL`)}
                  </Button>
                </div>
                <Col sm={9}>
                  <Field
                    id="observations"
                    name='observations'
                    placeholder={t(`${tKey}OBSERVATIONS`)}
                    controlLabel={t(`${tKey}OBSERVATIONS`)}
                    component={InputText}
                    componentClass="textarea"
                    customClass = 'col-description'
                    resize= 'none'
                    maxLength={200}
                    disabled={isReadOnly}
                  />
                </Col>
              </Row>
            </Panel.Collapse>
          </Panel>
        </div>

        <div style={{ display: displayClient }} className="client-panel dossier-panel" >
          <div className="header-panel" onClick={() => this.collapsePanelClient()}>

            <div className="name-wrapper fleet-panel-box-title">
              <h4>{t(`${tKey}CLIENT`)}  {bar} <span className='fleet-panel-subTitle'> {titleClient}</span></h4>
            </div>

            <i className={this.state.openPanelClient ? 'ico-arrow-black-down fleet-panel-box-caret' : 'ico-arrow-black-up fleet-panel-box-caret'} />

          </div>
          <Panel expanded={this.state.openPanelClient} onToggle={() => {}}>
            <Panel.Collapse>
              <Client
                t={t}
                isClientLoaded = {true}
                dossier={dossier}
                combos={combos}
                entityChangeable={this.props.changeEntity}
                dossierType={dossierTypesConstant.createNewFleetModal}
                actions={{
                  change,
                  fetchClientByDni,
                  fetchClientByDniEntityChild,
                  fetchClientFromDocument,
                  setEntityTypeId,
                  setEntitySubTypeId,
                  setEntityId,
                  fetchEntitySubTypeCombo,
                  fetchEntityFilterSubTypeCombo,
                  setEntityChildTypeId,
                  clearEntity,
                  clearEntityChild,
                  fetchSalesClient,
                  setEntityValidationDisable,
                  setActiveSection,
                  fetchCommercialSocietyTypeCombo,
                  foreignPassportWarning: () => this.foreignPassportWarning(),
                  fetchEntityCountryTypeCombo,
                  fetchEntityStateTypeCombo,
                  fetchEntityCityTypeCombo,
                  fetchEntityTownTypeCombo,
                  fetchEntityColonyTypeCombo,
                  fetchEntityCPTypeCombo,
                  fetchEntityMaritalStatusCombo,
                  openCommonModal: openModal,
                  fetchActivityBpro,
                  resetValues,
                  validate,
                  dispatch,
                  asyncValidate: asyncValidateFunc,
                  stopAsyncValidation,
                  fetchClientDataAddress,
                  clearClientFields
                }}
                readOnlyFields={isReadOnly || false}
                open={true}
                componentConfiguration={this.props.clientFleetPermissions}
                disableDeleteButton ={!this.props.create || isReadOnly}
                change={change}
                updateComponentAfterDeleteAndRestart = {() => this.updateComponentAfterDeleteAndRestart()}
                formValues={formValues}
              />
            </Panel.Collapse>
          </Panel>
        </div>

      </>
    )
  }
}

export default FleetAndClientComponent
