import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { closeOrganizedUnitSaleOperationMasterModal, submitOrganizedUnitSaleOperation } from '../../../actions/masters/organizedUnitSaleOperation/organizedUnitSaleOperation'
import OrganizedUnitSaleOperationMasterModal from '../../../components/masters/organizedUnitSaleOperation/OrganizedUnitSaleOperationMasterModal'

export function mapStateToProps (state) {
  const organizedUnitSaleOperationModal = state.organizedUnitSaleOperation.organizedUnitSaleOperationModal
  return {
    showModal: organizedUnitSaleOperationModal.showModal,
    organizedUnitSaleOperation: organizedUnitSaleOperationModal.organizedUnitSaleOperation,
    languageList: organizedUnitSaleOperationModal.languageList,
    combos: {
      organizedUnitsAllCombo: state.combos.organizedUnitsAllCombo,
      saleTypeAllCombo: state.combos.saleTypeAllCombo,
      operationTypeAllCombo: state.combos.operationTypeAllCombo
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      submitOrganizedUnitSaleOperation,
      closeOrganizedUnitSaleOperationMasterModal
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(OrganizedUnitSaleOperationMasterModal))
