import React, { PureComponent } from 'react'
import { Row, Checkbox } from 'react-bootstrap'
class Tab1 extends PureComponent {
  render () {
    return (
      <Row className="tabPane-container">
        <div className="checkbox-point">
          <Checkbox inline>DNI/CIF</Checkbox>
          <span className="point green"></span>
        </div>
        <div className="checkbox-point">
          <Checkbox inline>Pasaporte</Checkbox>
          <span className="point green"></span>
        </div>
        <div className="checkbox-point">
          <Checkbox inline>Tarjeta residencia</Checkbox>
          <span className="point green"></span>
        </div>
        <div className="checkbox-point">
          <Checkbox inline> Opci�n 4 </Checkbox>
          <span className="point green"></span>
        </div>
      </Row>
    )
  }
}

export default Tab1
