
import React, { PureComponent } from 'react'
import { Field, reduxForm } from 'redux-form'
import { Row, Col, Image, Button, Alert } from 'react-bootstrap'
import InputPassword from '../commons/form/InputPassword'
import InputPasswordStrengthIndicator from '../commons/form/InputPasswordStrengthIndicator'
import AccessReset from '../AccessReset'
import validatePassword from './validation/validatePassword'

class ResetPassword extends PureComponent {
  onSubmit (values) {
    return new Promise((resolve, reject) => {
      this.props.actions.resetPassword(values, this.props.t, resolve, reject, this.props.match.params.tokenId)
    })
  }

  componentDidMount () {
    if (this.props.match.params.tokenId) {
      this.props.actions.checkToken(this.props.match.params.tokenId)
    }
    this.props.actions.fetchPasswordPolicy()
  }

  render () {
    const { t, handleSubmit, error, invalid, submitting, emailAddress, passwordPolicy, formValues, formErrors } = this.props
    const tKey = 'RESET_PASSWORD.'
    return (
      <AccessReset>
        <form className="auth reset-pasword" onSubmit={handleSubmit(this.onSubmit.bind(this))}>
          <div className="company-wrapper">
            <h1 className="company-logo">
              <Image src="images/logo_kia.png" />
            </h1>
            <h2 className="company-name">
              iDocCar
            </h2>
          </div>
          <hr />
          <h3 className="title-modal">{t(tKey + 'CHANGE_PASSWORD')}</h3>
          <p className="user">{emailAddress}</p>
          {emailAddress &&
            <Row>
              <Field
                t={t}
                colSm={10}
                colSmOffset={1}
                id='newPassword'
                name='newPassword'
                placeholder={t(tKey + 'NEW_PASSWORD')}
                controlLabel={t(tKey + 'NEW_PASSWORD')}
                component={InputPassword}
              />
              <InputPasswordStrengthIndicator
                t={t}
                colSm={10}
                colSmOffset={1}
                passwordPolicy={passwordPolicy}
                passwordValue={formValues && formValues.newPassword ? formValues.newPassword : ''}
                passwordError={formErrors && formErrors.newPassword ? formErrors.newPassword : false}
              />
              <Field
                t={t}
                colSm={10}
                colSmOffset={1}
                id='pwdLoginRepeat'
                name='pwdLoginRepeat'
                placeholder={t(tKey + 'REPEAT_PASSWORD')}
                controlLabel={t(tKey + 'REPEAT_PASSWORD')}
                component={InputPassword}
              />
              <Button
                id='btn_change'
                type='submit'
                bsStyle='primary'
                className='btn-wide-lg btn-auth btn-reset-auth'
                name='btn-reset-auth'
                disabled={invalid || submitting}
              >{t(tKey + 'CHANGE')}</Button>
              {error &&
                <Col smOffset={1} sm={10}>
                  <Alert bsStyle='danger' role='alert'>
                    <strong>{t('ERRORS.SERVER_ERROR')}</strong>
                    <p>{error}</p>
                  </Alert>
                </Col>
              }
            </Row>
          }
          {!emailAddress &&
            <div>
              <Col smOffset={1} sm={10}>
                <Alert bsStyle='warning' role='warning'>
                  <strong>{t(tKey + 'TOKEN_NOT_VALID')}</strong>
                </Alert>
              </Col>
            </div>
          }
          <div className="poweredByWrapper">
            <Image src="images/poweredby.png" />
          </div>
        </form>
      </AccessReset>

    )
  }
}

export default reduxForm({
  form: 'resetPassword',
  validate: validatePassword
})(ResetPassword)
