import apiFetch from '../apiFetch'

export default function (token, dossierId, isSalesDossier, lastAccessDate) {
  const path = isSalesDossier ? 'sale' : 'purchase'
  return apiFetch({
    endPoint: `dossier/${path}/${dossierId}/printDeliveryChecklist`,
    method: 'GET',
    parseBlobAndAssign: true,
    lastAccessDate,
    token: token
  })
}
