/* eslint-disable no-unused-vars */
import { call, put, select, takeEvery } from 'redux-saga/effects'
import { getAuth } from '../../selectors/access/auth'
import { getConditionMaintenanceFilter } from '../../selectors/contidionsMaintenance/conditionsMaintenanceFilter'
import { yesNoModal } from '../modalsListeners/yesNoModal'
import { showLoader, hideLoader, openModal } from '../../actions/common.js'
import conditionsMaintenanceActionTypes from '../../constants/actions/conditionsMaintenance/conditionsMaintenance'
import { fetchStageConditionsSuccess, setActiveStageSuccess, openAddConditionsModalSuccess, openEditConditionsModalSuccess, closeAddOrEditConditionsModalSuccess, fetchConditionDocumentalCampaignSuccess, getDocumentsConditionDocumentalcampaignSucces, getFunctionsToAddDocumentalCampaignSucces, saveUoConditionDocCampaignSuccess } from '../../actions/conditionsMaintenance/conditionsMaintenance'
import getStagesConditionsService from '../../services/stage/getStagesConditions'
import getConditionDocumentalByDossierStageIdService from '../../services/conditionDocumental/getConditionDocumentalByDossierStageId'
import getConditionDocumentalDetailsService from '../../services/conditionDocumental/getConditionDocumentalDetails'
import deleteConditionDocumentalService from '../../services/conditionDocumental/deleteConditionDocumentalService'
import getConditionNoDocumentalByDossierStageIdService from '../../services/conditionNoDocumental/getConditionNoDocumentalByDossierStageId'
import getConditionNoDocumentalOtherByDossierStageIdService from '../../services/conditionNoDocumental/getConditionNoDocumentalOtherByDossierStageId'
import getConditionNoDocumentalDetailsService from '../../services/conditionNoDocumental/getConditionNoDocumentalDetails'
import deleteConditionNoDocumentalService from '../../services/conditionNoDocumental/deleteConditionNoDocumentalService'
import { handleError } from '../errors/errorManager'
import postConditionDocumental from '../../services/conditionDocumental/postConditionDocumental'
import postConditionNoDocumental from '../../services/conditionNoDocumental/postConditionNoDocumental'
import putConditionDocumental from '../../services/conditionDocumental/putConditionDocumental'
import putConditionNoDocumental from '../../services/conditionNoDocumental/putConditionNoDocumental'
import getConditionDocumentaCampaignService from '../../services/conditionDocumental/getConditionDocumentaCampaignService'
import deleteConditionDocumentaCampaignService from '../../services/conditionDocumental/deleteConditionDocumentaCampaignService'
import getDocumentsConditionCampaign from '../../services/conditionDocumental/getDocumentsConditionCampaign'
import postConditionDocumentalCampaignService from '../../services/conditionDocumental/postConditionDocumentalCampaignService'
import getFunctionsToAddDocumentalCampaign from '../../services/conditionDocumental/getFunctionsToAddDocumentalCampaign'
import messageModalActions from '../../actions/modals/messageModal'
import { closeEditDocumentalConditionModalEditSuccess, openEditDocumentalConditionModalEditSuccess } from '../../actions/masters/masters'
import deleteUoofConditionDocCampaign from '../../services/conditionDocumental/deleteUoOfConditionDocCampaign'

export function * fetchStagesConditions ({ filter }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const stages = yield call(getStagesConditionsService, auth.token, filter)
    yield put(fetchStageConditionsSuccess(stages))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchStagesConditions () {
  yield takeEvery(conditionsMaintenanceActionTypes.FETCH_STAGES_CONDITIONS, fetchStagesConditions)
}

export function * setActiveStage ({ activeStage }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const filter = yield select(getConditionMaintenanceFilter)
    const conditionDocumental = yield call(getConditionDocumentalByDossierStageIdService, activeStage, auth.token, filter)
    const conditionNoDocumental = yield call(getConditionNoDocumentalByDossierStageIdService, activeStage, auth.token, filter)
    const conditionNoDocumentalOther = yield call(getConditionNoDocumentalOtherByDossierStageIdService, activeStage, auth.token, filter)
    /*
    let stages = yield select((state) => state.conditionsMaintenance.stages)
    stages = stages.map((stage) => {
      return {...stage, isActive: stage.name === activeStage.name}
    })
    */
    yield put(setActiveStageSuccess(activeStage, conditionDocumental, conditionNoDocumental, conditionNoDocumentalOther))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSetActiveStage () {
  yield takeEvery(conditionsMaintenanceActionTypes.SET_ACTIVE_STAGE, setActiveStage)
}

export function * openAddConditionsModal ({ isConditionDocumental, activeStage, conditionNoDocumentalType }) {
  try {
    yield put(showLoader())
    // const auth = yield select(getAuth)
    // const stages = yield call(getStagesConditionsService, auth.token)
    yield put(openAddConditionsModalSuccess(isConditionDocumental, activeStage, conditionNoDocumentalType))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchOpenAddConditionsModal () {
  yield takeEvery(conditionsMaintenanceActionTypes.OPEN_ADD_CONDITIONS_MODAL, openAddConditionsModal)
}

export function * openEditConditionsModal ({ isConditionDocumental, conditionId, conditionNoDocumentalType }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    let data = null
    if (isConditionDocumental) {
      data = yield call(getConditionDocumentalDetailsService, auth.token, conditionId)
    } else {
      data = yield call(getConditionNoDocumentalDetailsService, auth.token, conditionId)
    }
    yield put(openEditConditionsModalSuccess(isConditionDocumental, conditionId, data, conditionNoDocumentalType))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchOpenEditConditionsModal () {
  yield takeEvery(conditionsMaintenanceActionTypes.OPEN_EDIT_CONDITIONS_MODAL, openEditConditionsModal)
}

export function * saveConditionsModal ({ isConditionDocumental, conditionId, data }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    if (isConditionDocumental) {
      if (conditionId) {
        yield call(putConditionDocumental, conditionId, auth.token, data)
      } else {
        yield call(postConditionDocumental, auth.token, data)
      }
    } else {
      if (conditionId) {
        yield call(putConditionNoDocumental, conditionId, auth.token, data)
      } else {
        yield call(postConditionNoDocumental, auth.token, data)
      }
    }
    yield call(setActiveStage, { activeStage: data.dossierStageId })
    yield put(closeAddOrEditConditionsModalSuccess())
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSaveConditionsModal () {
  yield takeEvery(conditionsMaintenanceActionTypes.SAVE_CONDITIONS_MODAL, saveConditionsModal)
}

export function * closeAddOrEditConditionsModal () {
  try {
    yield put(showLoader())
    // const auth = yield select(getAuth)
    // const stages = yield call(getStagesConditionsService, auth.token)
    yield put(closeAddOrEditConditionsModalSuccess())
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchCloseAddOrEditConditionsModal () {
  yield takeEvery(conditionsMaintenanceActionTypes.CLOSE_ADD_OR_EDIT_CONDITIONS_MODAL, closeAddOrEditConditionsModal)
}

export function * deleteConditions ({ isConditionDocumental, conditionId, stageId }) {
  try {
    const confirmed = yield call(yesNoModal, `deleteCondition${!isConditionDocumental ? 'No' : ''}Documental`)
    if (confirmed) {
      yield put(showLoader())
      const auth = yield select(getAuth)
      if (isConditionDocumental) {
        yield call(deleteConditionDocumentalService, auth.token, conditionId)
      } else {
        yield call(deleteConditionNoDocumentalService, auth.token, conditionId)
      }
      yield call(setActiveStage, { activeStage: stageId })
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDeleteConditions () {
  yield takeEvery(conditionsMaintenanceActionTypes.DELETE_CONDITION, deleteConditions)
}

export function * fetchDocumentaConditionCampaign ({ campaignDetailsId, campaignGroupId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const data = yield call(getConditionDocumentaCampaignService, auth.token, campaignDetailsId, campaignGroupId)
    yield put(fetchConditionDocumentalCampaignSuccess(data))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchfetchDocumentaConditionCampaign () {
  yield takeEvery(conditionsMaintenanceActionTypes.FETCH_CONDITION_DOCUMENTAL_CAMPAIGN, fetchDocumentaConditionCampaign)
}

export function * deleteDocumentaConditionCampaign ({ conditionDocumentalCampaigId, campaignDetailsId, campaignGroupId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(deleteConditionDocumentaCampaignService, auth.token, conditionDocumentalCampaigId)
    yield call(fetchDocumentaConditionCampaign, { campaignDetailsId: campaignDetailsId, campaignGroupId: campaignGroupId })
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchdeleteDocumentaConditionCampaign () {
  yield takeEvery(conditionsMaintenanceActionTypes.DELETE_CONDITION_DOCUMENTAL_CAMPAIGN, deleteDocumentaConditionCampaign)
}

export function * fetchDocumentsConditionCampaign () {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const data = yield call(getDocumentsConditionCampaign, auth.token)
    yield put(getDocumentsConditionDocumentalcampaignSucces(data))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchfetchDocumentsConditionCampaign () {
  yield takeEvery(conditionsMaintenanceActionTypes.GET_DOCUMENTS_CONDITION_DOCUMENTAL_CAMPAIGN, fetchDocumentsConditionCampaign)
}

export function * addConditionDocumentalCampaign ({ conditionDocumentalCampaignDto }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(postConditionDocumentalCampaignService, auth.token, conditionDocumentalCampaignDto)
    yield call(fetchDocumentaConditionCampaign, { campaignDetailsId: conditionDocumentalCampaignDto.campaignDetailsId, campaignGroupId: conditionDocumentalCampaignDto.campaignGroupId })
    yield put(messageModalActions.openSuccessMessageModal('MESSAGES.STORE_DOCUMENTAL_CONDITION_CAMPAIGN_SUCCESS'))
    yield put(closeEditDocumentalConditionModalEditSuccess())
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchaddConditionDocumentalCampaign () {
  yield takeEvery(conditionsMaintenanceActionTypes.ADD_CONDITION_DOCUMENTAL_CAMPAIGN, addConditionDocumentalCampaign)
}

export function * fetchFuntionsToAddDocumentalCampaign () {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const data = yield call(getFunctionsToAddDocumentalCampaign, auth.token)
    yield put(getFunctionsToAddDocumentalCampaignSucces(data))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchfetchFuntionsToAddDocumentalCampaign () {
  yield takeEvery(conditionsMaintenanceActionTypes.GET_FUNTIONTOADD_DOCUMENTAL_CAMPAIGN, fetchFuntionsToAddDocumentalCampaign)
}

export function * deleteUoConditionDocCampaign ({ organizedUnitId, conditionDocumentalCampaign }) {
  try {
    const confirmed = yield call(yesNoModal, 'deleteUoCompany')
    if (confirmed) {
      yield put(showLoader())
      const auth = yield select(getAuth)
      var ok = yield call(deleteUoofConditionDocCampaign, auth.token, organizedUnitId, conditionDocumentalCampaign.conditionDocumentalCampaigId)
      if (ok) {
        let data = yield call(getConditionDocumentaCampaignService, auth.token, null, conditionDocumentalCampaign.campaignGroupId)
        data = data.find(x => x.conditionDocumentalCampaigId === conditionDocumentalCampaign.conditionDocumentalCampaigId)
        yield put(closeEditDocumentalConditionModalEditSuccess())
        yield put(openEditDocumentalConditionModalEditSuccess(data))
      }
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDeleteUoConditionDocCampaign () {
  yield takeEvery(conditionsMaintenanceActionTypes.DELETE_UO_CONDITION_DOCUMENTAL_CAMPAIGN, deleteUoConditionDocCampaign)
}
