export const comboType = ['CountryTypeCombo', 'StateTypeCombo', 'CityTypeCombo', 'TownTypeCombo', 'ColonyTypeCombo', 'CPTypeCombo']
export const numberCombo = {
  countryCode: 0,
  stateCode: 1,
  cityCode: 2,
  townCode: 3,
  colonyCode: 4,
  postalCodeCode: 5
}

export const activityTypes = {
  particular: 'PARTICULAR',
  empresa: 'EMPRESA'
}
