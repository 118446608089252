import mastersActionTypes from '../../../constants/actions/masters/masters'

function initialState () {
  return {
    destinationCraneList: [],
    destinationCraneCount: 0,
    pagesCount: 0,
    filter: {
      page: 1,
      orderBy: '',
      pageSize: 10
    },
    destinationCraneModal: {
      destinationCrane: null,
      showModal: false
    }
  }
}

function fetchDestinationCraneListSuccess (state, { destinationCrane, filter }) {
  return {
    ...state,
    destinationCraneList: destinationCrane.destinationCraneList,
    destinationCraneCount: destinationCrane.destinationCraneCount,
    pagesCount: destinationCrane.pagesCount,
    filter
  }
}

function fetchDetinationCraneItemSucces (state, { destinationCrane, showModal }) {
  return {
    ...state,
    destinationCraneModal: {
      destinationCrane: destinationCrane,
      showModal: showModal
    }
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case mastersActionTypes.DESTINATION_CRANE_MASTER_SUCCES:
      return fetchDestinationCraneListSuccess(state, action)
    case mastersActionTypes.DESTINATION_CRANE_ITEM_SUCCES:
      return fetchDetinationCraneItemSucces(state, action)
    default:
      return state
  }
}
