import React, { PureComponent } from 'react'
import { reduxForm } from 'redux-form'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Element } from 'react-scroll'
import DossierHeaderPage from '../../../containers/dossiers/commons/dossierHeaderPage'
import ButtonsHeader from '../../../../_v2/components/dossiers/common/ButtonsHeader'
import ButtonsSidebar from '../../dossiers/common/ButtonsSidebar'
import { compareObjects } from '../../../../util/utils'
import { getComponentsPermisions } from '../../../../util/permisionFunctions'
import { permissions } from '../../../../constants/backendIds'
import { buttonsHeader } from '../../../../constants/dossier/common/buttonsHeader'
import { buttonsSidebar } from '../../../../constants/dossier/common/buttonsSidebar'
import RejectCommentModal from '../../../../components/dossiers/common/RejectCommentModal'
import Client from '../../dossiers/common/Client'
import Vehicle from '../../dossiers/common/Vehicle'
import PaperworksPage from '../../../../containers/dossiers/common/PaperworkComponentPage'
import PurchaseComponent from '../../../../_v2/components/dossiers/purchase/PurchaseComponent'
import PurchaseEconomicPlan from '../../../../_v2/components/dossiers/purchase/PurchaseEconomicPlan'
import { dossierTypes as dossierTypesConstant } from '../../../../constants/dossier/common/dossierTypes'
import { dossierSubTypeId as dossierSubTypeIdConstants } from '../../../../constants/dossier/common/dossierSubType'
import DocumentaryManagementPage from '../../../../_v2/containers/dossiers/commons/DocumentaryManagementPage'
import AuditInfo from '../../dossiers/common/audit/AuditInfo'
import OtherData from '../../../../_v2/components/dossiers/common/OtherData'
import TrackingContainer from '../../../../containers/tracking/trackingContainer'
import { TrackedPages } from '../../../../constants/tracking/TrackedPages'
import { purchaseTypesVo } from '../../../../constants/dossier/purchase/purchaseTypes'
import CommentComponent from '../../dossiers/common/comment/CommentComponent'
import Services from '../../dossiers/common/Services'
import DossierSummary from '../../dossiers/common/DossierSummary'
import RelatedDossiers from '../../dossiers/common/RelatedDossiers'

class PurchaseHistorical extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      modalOpen: true,
      commentsOpen: true,
      purchaseOpen: true,
      isVehicleLoaded: false,
      isDeliveryLoaded: false,
      isFinanceLoaded: false,
      isClientLoaded: false,
      isOtherDataLoaded: false,
      showRejectCommentModal: false,
      isEconomicLoaded: false,
      activeTabConfiguration: null,
      headerTabConfiguration: null,
      componentsConfiguration: {
        bpurchase: null,
        entity: null,
        vehicle: null,
        paperwork: null,
        other_data: null,
        downloadDocumentsButton: null,
        services: null
      }
    }
    this.goBack = this.goBack.bind(this)
  }

  goBack () {
    this.props.actions.setActiveTab(buttonsHeader.datos_expediente)
    this.props.history.goBack()
  }

  toggleRejectCommentModal (showRejectCommentModal) {
    this.setState({ showRejectCommentModal })
  }

  onClickRelatedDossierColumn (columnCode) {
    let columnCodeOrder = '+' + columnCode
    if (columnCodeOrder === this.props.relatedDossiersOrderBy) {
      columnCodeOrder = '-' + columnCode
    }
    this.props.actions.fetchFilteredRelatedDossier(this.props.dossier.dossierId, columnCodeOrder, true)
  }

  getRelatedDossierColumnIcon (columnCode) {
    if ('+' + columnCode === this.props.relatedDossiersOrderBy) {
      return 'ico-sort-selected-asc'
    } else if ('-' + columnCode === this.props.relatedDossiersOrderBy) {
      return 'ico-sort-selected-desc'
    } else {
      return 'ico-sort'
    }
  }

  submitPurchaseDossier (values) {
    this.props.actions.savePurchaseDossier(values)
  }

  fetchPurchaseDossierPromise (dossierId) {
    return new Promise((resolve, reject) => {
      return this.props.actions.fetchPurchaseDossier(dossierId, resolve, reject, true)
    })
  }

  toogleHidedossierBodyLeft () {
    this.setState({ dossierBodyLeftClose: !this.state.dossierBodyLeftClose })
  }

  initializePermisions (props) {
    const componentKeys = Object.keys(this.state.componentsConfiguration)
    const newState = getComponentsPermisions(props.dossier.viewConfiguration.tabsConfiguration, props.activeTab, componentKeys)
    this.setState({ ...this.state, ...newState })
  }

  UNSAFE_componentWillMount () {
    this.props.actions.restartPurchaseState()
    this.fetchPurchaseDossierPromise(this.props.match.params.dossierId).then(() => {
      const form = { ...this.props.dossier }
      if (!this.props.dossier.entityComponent.entityId) delete form.entityComponent
      if (!this.props.dossier.vehicleComponent.vehicleId) delete form.vehicleComponent
      this.props.initialize(form)
      this.initializePermisions(this.props)
      this.props.actions.fetchPurchaseDossierDependants(this.props.match.params.dossierId, true)
      this.props.actions.fetchStockStatusCombo()
      this.props.actions.fetchCountries()
      this.props.actions.fetchSaleTypeAll()
      this.props.actions.fetchPuscharseSaleEntitys(this.props.dossier.organizedUnitId)
      this.props.actions.fetchPurchaseOrderTypeCombo()
      this.props.actions.fetchCustomFields(this.props.dossier.dossierSubTypeId)
      this.props.actions.registerLastAccess(this.props.match.params.dossierId, true)
      this.props.trackVisitPage({
        documentTitle: TrackedPages.PURCHASE_HISTORICAL,
        organizedUnitId: this.props.dossier.organizedUnitId,
        href: window.location.href
      })
    })
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if ((this.props.showManagementModal && !nextProps.showManagementModal) || this.props.match.params.dossierId !== nextProps.match.params.dossierId) {
      if (this.props.match.params.dossierId !== nextProps.match.params.dossierId) {
        this.props.actions.restartPurchaseState()
        this.props.actions.setScrollTo(buttonsSidebar.bpurchase)
        this.props.actions.setActiveSection(buttonsSidebar.bpurchase)
        this.props.actions.setActiveTab(buttonsHeader.datos_expediente)
        this.props.actions.closeAddOrEditDocumentModal()
      }
      this.fetchPurchaseDossierPromise(nextProps.match.params.dossierId).then(() => {
        const form = { ...this.props.dossier }
        if (!this.props.dossier.entityComponent.entityId) delete form.entityComponent
        if (!this.props.dossier.vehicleComponent.vehicleId) delete form.vehicleComponent
        this.props.initialize(form)
        this.initializePermisions(this.props)
        this.props.actions.fetchPurchaseDossierDependants(this.props.match.params.dossierId, true)
      })
    }
    if (JSON.stringify(this.props.dossier.dossierPurchaseEconomicPlanComponent) !== JSON.stringify(nextProps.dossier.dossierPurchaseEconomicPlanComponent)) {
      this.props.initialize(nextProps.dossier)
    }
    if (nextProps.dossier.dossierId && !compareObjects(this.props.dossier.viewConfiguration.tabsConfiguration || {}, nextProps.dossier.viewConfiguration.tabsConfiguration, true)) {
      this.initializePermisions(nextProps)
    }
    if (nextProps.dossier.dossierId && nextProps.activeTab !== this.props.activeTab) {
      this.initializePermisions(nextProps)
    }
  }

  componentDidUpdate () {
    if (this.props.dossier.dossierId && this.props.statusHeaderHeight && this.props.activeSection) {
      switch (this.props.activeSection) {
        case buttonsSidebar.bpurchase:
          this.setState({ purchaseOpen: true })
          break
      }
    }

    let customFields = {}
    const firstCustomFieldsLoad = this.props.dossier.customFieldsByComponent?.length > 0
    if (firstCustomFieldsLoad) {
      if (this.props.dossier.customFieldsValues) {
        this.props.dossier.customFieldsValues.map(x => {
          customFields[x.key] = x.value
        })
      }
      const form = { ...this.props.dossier, customFields: customFields }
      if (!this.props.dossier.entityComponent.entityId) delete form.entityComponent
      if (!this.props.dossier.vehicleComponent.vehicleId) delete form.vehicleComponent
      this.props.initialize(form)
    }
  }

  componentDidMount () {
    this.props.actions.fetchCommercialSocietyTypeCombo()
  }

  componentWillUnmount () {
    this.props.actions.setActiveSection(buttonsSidebar.bpurchase)
    this.props.actions.setScrollTo(null)
    this.props.actions.resetButtonsHeader()
    this.props.actions.closeAddOrEditDocumentModal()
    this.props.actions.restartPurchaseState()
  }

  handleToUpdateComponent (component) {
    if (component.isVehicleLoaded === false || buttonsSidebar.vehicle === component) this.setState({ isVehicleLoaded: true })
    if (component.isClientLoaded === false || buttonsSidebar.entity === component) this.setState({ isClientLoaded: true })
    if (component.isFinanceLoaded === false || buttonsSidebar.finance === component) this.setState({ isFinanceLoaded: true })
    if (component.isDeliveryLoaded === false || buttonsSidebar.delivery === component) this.setState({ isDeliveryLoaded: true })
    if (component.isOtherDataLoaded === false || buttonsSidebar.other_data === component) this.setState({ isOtherDataLoaded: true })
    if (component.isEconomicLoaded === false) this.setState({ isEconomicLoaded: true })
    this.props.actions.setScrollTo(component)
  }

  togglePanel () {
    if (this.state.purchaseOpen) {
      this.props.actions.setActiveSection(false)
    }
    this.setState({ purchaseOpen: !this.state.purchaseOpen })
  }

  render () {
    const {
      t, handleSubmit, combos = {}, pristine, dossier, vehicleProps, change, activeTab, buttonsHeaderHeight, activeSection, statusHeaderHeight, windowWidth, documentExport,
      dossier: {
        dossierId, dossierSubTypeId, dossierParentId, organizedUnitId, checkListHeader, header,
        dossierPurchaseComponent, comments, showAlertSendPending, dossierCompletePaperworks,
        viewConfiguration, viewConfiguration: { buttonsPermission = [], purchaseConfiguration },
        vehicleComponent: { vehicleId }, entityChangeable, dossierRelatedDossiers, auditInfo,
        dossierPurchaseEconomicPlanComponent
      },
      dossierSales: { dossierServiceRelateds },
      combos: {
        fuelCombo, previousUsageCombo, ivaTypeCombo, paymentMethodPurchase, laterUseCombo, vehicleType, brandCombo,
        entityTypeCombo, channelCombo, maritalStatusCombo, segmentationCombo, entitySubTypeCombo, entityFilterSubTypeCombo, purchaseTypeCombo,
        activityClientCombo, stockStatusCombo, purchaseOrderTypeCombo, countries, familyCombo, salesmanCombo, activityBproCombo,
        carlineCombo, catalogueCombo, modelCombo, colorCombo, tapestryCombo, saleTypeAllCombo, puscharseSaleEntitys
      },
      actions: {
        fetchPurchaseDossierDependants,
        openDocumentModal, openDocumentEditionModal, setActiveSection, setActiveTab, setButtonsHeaderHeight, resetButtonsHeader, setScrollerOptions, /* navigateTo, */
        fetchComboOrigin,
        fetchComboDestination,
        refreshAuditInfo,
        fetchAuditDetails,
        fetchAuditDetailsModal,
        fetchDocumentsGroupedAuditDetails,
        fetchDocumentsGroupedAuditDetailsModal,
        fetchPurchaseDossierEconomic,
        fetchPurchaseVehicleHistorical,
        fetchPurchaseClientHistorical,
        fetchPurchaseOtherDataHistorical,
        fecthFamily,
        saveSelectedDocuments,
        updateComment,
        createComment,
        deleteComment,
        markCommentAsRead,
        fetchSalesServices,
        fetchDossierSubTypes,
        fetchEntitySubTypeCombo,
        fetchEntityFilterSubTypeCombo,
        saveSalesService,
        fetchDefaultEntity,
        fetchNotValidDatesService,
        openOrCloseServiceAddModal,
        fetchEntityCountryTypeCombo,
        fetchEntityStateTypeCombo,
        fetchEntityCityTypeCombo,
        fetchEntityTownTypeCombo,
        fetchEntityColonyTypeCombo,
        fetchEntityCPTypeCombo,
        openCommonModal,
        fetchCommercialSocietyTypeCombo,
        fetchActivityBpro,
        fetchClientDataAddress,
        fetchFilteredRelatedDossier
      }
    } = this.props

    if (!dossierId) return null

    // TODO change isVn condition on PurchaseEconomicPlan
    const tKey = 'DOSSIER_COMPONENTS.'
    const savePurchasePermission = buttonsPermission.includes('purchase_save')
    const panelsPaddingTop = statusHeaderHeight + buttonsHeaderHeight - 82 // 82px es el paddingTop definido en el layout.

    return (
      <div class="dossierGrid">
        <div className='modalsContainer'>
          <RejectCommentModal
            t={t}
            showModal={this.state.showRejectCommentModal}
            actions={{
              closeModal: () => this.toggleRejectCommentModal(false)
            }}
          />
        </div>

        <div class="dossierGridPart-header">
          <DossierHeaderPage
            headerData={header}
            stages={checkListHeader}
            dossierType={dossierTypesConstant.purchase}
            headerTabConfiguration={this.state.headerTabConfiguration}
            goBack={this.goBack}
            dossierId={dossier.dossierId}
            isHistorical={true}
            entityComponent = {dossier.entityComponent}
            vehicleComponent = {dossier.vehicleComponent}
          />
        </div>

        <div class="dossierGridPart-body">

          <div className={'dossierBody-left ' + (this.state.dossierBodyLeftClose ? 'close' : '') }>

            <DossierSummary
              t = {t}
              dossier={dossier}
              header={header}
              dossierType={dossierTypesConstant.purchase}
              dossierSubType={dossierSubTypeId}
              brandCombo = {combos.brandCombo}
              entityTypeCombo = {combos.entityTypeCombo}
              dossierRelatedDossiers = {dossierRelatedDossiers}
            >
            </DossierSummary>
          </div>
          <div className='dossierBody-center'>
            <OverlayTrigger placement="right" overlay={<Tooltip id="editProductTooltip">{`${t((this.state.dossierBodyLeftClose ? 'GLOBAL.TABLES.SHOW_INNER_MENU' : 'GLOBAL.TABLES.HIDE_INNER_MENU'))}`}</Tooltip>}>
              <a onClick={() => this.toogleHidedossierBodyLeft()} className={'closeDossierInfo ' + (this.state.dossierBodyLeftClose ? '' : 'closeInnerMenu')}><i className={this.state.dossierBodyLeftClose ? 'iDocIcon-Open-inner-menu' : 'iDocIcon-Close-inner-menu'}></i></a>
            </OverlayTrigger>
          </div>
          <div className='dossierBody-right'>

            <div class="dossierGridPart-sectionsMenu">
              <ButtonsHeader
                t={t}
                pristine={pristine}
                actions={{
                  openDocumentModal: () => openDocumentModal(dossierId, dossierTypesConstant.purchase, pristine, 1, true),
                  setActiveTab,
                  setButtonsHeaderHeight,
                  resetButtonsHeader,
                  setScrollerOptions
                }}
                dossierId={this.props.match.params.dossierId}
                viewConfiguration={viewConfiguration}
                dossierType={dossierTypesConstant.purchase}
                activeTab={activeTab}
                marginTop={statusHeaderHeight}
                windowWidth={windowWidth}
                historical={true}
              />
            </div>
            <div class="dossierGridPart-ComponentSection">
              <form autoComplete="off" onSubmit={handleSubmit(this.submitPurchaseDossier.bind(this))}>
                {
                  activeTab === buttonsHeader.datos_expediente && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden && (
                    <div className="_nd dossierGridPart-ComponentSection-Inner" >
                      <div className="ComponentSection-InnerMennu">
                        <ButtonsSidebar
                          t={t}
                          dossierId={dossierId}
                          historical={true}
                          isClientLoaded = {this.state.isClientLoaded}
                          isVehicleLoaded = {this.state.isVehicleLoaded}
                          isOtherDataLoaded = {this.state.isOtherDataLoaded}
                          handleToUpdateComponent = {this.handleToUpdateComponent.bind(this)}
                          pristine = {pristine}
                          actions={{
                            setActiveSection,
                            fetchPurchaseVehicleHistorical,
                            fetchPurchaseClientHistorical,
                            fetchPurchaseOtherDataHistorical,
                            fetchSalesServices
                          }}
                          dossierType={dossierTypesConstant.purchase}
                          dossierSubTypes={dossierSubTypeId}
                          marginTop={statusHeaderHeight}
                          buttonsHeaderHeight={buttonsHeaderHeight}
                          tabConfiguration={this.state.activeTabConfiguration}
                        />
                      </div>
                      <div className="ComponentSection-InnerData" id="dossierData" >
                        {this.state.componentsConfiguration.bpurchase && this.state.componentsConfiguration.bpurchase.permission > permissions.hidden && (
                          <Element name={buttonsSidebar.bpurchase}>
                            <PurchaseComponent
                              t={t}
                              purchaseOpen={this.state.purchaseOpen}
                              showNewVehicleFields={dossierPurchaseComponent.showNewVehicleFields}
                              dossierPurchaseComponent={dossierPurchaseComponent}
                              combos={{ purchaseTypeCombo, stockStatusCombo, purchaseOrderTypeCombo, salesmanCombo, saleTypeAllCombo, puscharseSaleEntitys }}
                              actions={{
                                togglePanel: () => this.togglePanel(),
                                setActiveSection
                              }}
                              readOnlyFields={!savePurchasePermission}
                              dossierType={dossierTypesConstant.purchase}
                              dossierSubType={dossierSubTypeId}
                              componentConfiguration={this.state.componentsConfiguration.bpurchase}
                              hasParent={!!dossierParentId}
                              showPurchaseContract={dossier && dossier.dossierPurchaseComponent && dossier.dossierPurchaseComponent.purchaseTypeId === purchaseTypesVo.scrapping}
                              customFields={this.props.dossier.customFieldsByComponent.find(x => x.componentCode === buttonsSidebar.bpurchase)?.customFields}
                            />
                          </Element>
                        )}
                        {this.state.componentsConfiguration.entity && this.state.componentsConfiguration.entity.permission > permissions.hidden && (
                          <Element name={buttonsSidebar.entity}>
                            <Client
                              t={t}
                              dossier={dossier}
                              handleToUpdateComponent = {this.handleToUpdateComponent.bind(this)}
                              isClientLoaded = {this.state.isClientLoaded}
                              pristine={pristine}
                              combos={{
                                entityTypeCombo,
                                channelCombo,
                                maritalStatusCombo,
                                segmentationCombo,
                                entitySubTypeCombo,
                                entityFilterSubTypeCombo,
                                activityBproCombo
                              }}
                              entityChangeable={entityChangeable}
                              dossierType={dossierTypesConstant.purchase}
                              actions={{
                                change,
                                openDocumentEditionModal,
                                fetchPurchaseClientHistorical,
                                setActiveSection,
                                fetchEntityCountryTypeCombo,
                                fetchEntityStateTypeCombo,
                                fetchEntityCityTypeCombo,
                                fetchEntityTownTypeCombo,
                                fetchEntityColonyTypeCombo,
                                fetchEntityCPTypeCombo,
                                openCommonModal,
                                fetchCommercialSocietyTypeCombo,
                                fetchActivityBpro,
                                fetchClientDataAddress
                              }}
                              readOnlyFields={!savePurchasePermission}
                              open={activeSection === buttonsSidebar.entity}
                              componentConfiguration={this.state.componentsConfiguration.entity}
                              customFields={this.props.dossier.customFieldsByComponent.find(x => x.componentCode === buttonsSidebar.entity)?.customFields}
                            />
                          </Element>
                        )}
                        {this.state.componentsConfiguration.vehicle && this.state.componentsConfiguration.vehicle.permission > permissions.hidden && (
                          <Element name={buttonsSidebar.vehicle}>
                            <Vehicle
                              parentDossierType={{
                                isPaperwork: false,
                                isSales: false,
                                isPurchase: true,
                                isRegistrationDelivery: false
                              }}
                              t={t}
                              actions={{
                                fetchComboOrigin,
                                fetchComboDestination,
                                fetchPurchaseVehicleHistorical,
                                fecthFamily,
                                setActiveSection
                              }}
                              handleToUpdateComponent = {this.handleToUpdateComponent.bind(this)}
                              isVehicleLoaded = {this.state.isVehicleLoaded}
                              dossierId={dossierId}
                              searchedVehicleProps={vehicleProps}
                              dossierParentId={dossierParentId}
                              dossierWithVehicle={!!vehicleId}
                              combos={{ fuelCombo, brandCombo, previousUsageCombo, countries, familyCombo, carlineCombo, catalogueCombo, modelCombo, colorCombo, tapestryCombo }}
                              readOnlyFields={!savePurchasePermission}
                              open={activeSection === buttonsSidebar.vehicle}
                              dossierType={dossierTypesConstant.purchase}
                              dossierSubType={dossierSubTypeId}
                              componentConfiguration={this.state.componentsConfiguration.vehicle}
                              codeVehicleConfiguration={dossierSubTypeId === dossierSubTypeIdConstants.compra_vn ? false : purchaseConfiguration.showCodecombos}
                              organizedUnitId={dossier.organizedUnitId}
                              purchaseType = {this.props.dossier.dossierPurchaseComponent.purchaseTypeId}
                              customFields={this.props.dossier.customFieldsByComponent.find(x => x.componentCode === buttonsSidebar.vehicle)?.customFields}
                              isHistorical={true}
                            />
                          </Element>
                        )}
                        {this.state.componentsConfiguration.paperwork && this.state.componentsConfiguration.paperwork.permission > permissions.hidden && (
                          <Element name={buttonsSidebar.paperwork}>
                            <PaperworksPage
                              organizedUnitId={organizedUnitId}
                              dossierParentId={dossierId}
                              updateDossierAction={fetchPurchaseDossierDependants}
                              showAlertSendPending={showAlertSendPending}
                              paperwork={dossierCompletePaperworks}
                              readOnlyFields={!savePurchasePermission}
                              dossierType={dossierTypesConstant.purchase}
                              open={activeSection === buttonsSidebar.paperwork}
                              componentConfiguration={this.state.componentsConfiguration.paperwork}
                              customFields={this.props.dossier.customFieldsByComponent.find(x => x.componentCode === buttonsSidebar.paperwork)?.customFields}
                            />
                          </Element>
                        )}
                        {this.state.componentsConfiguration.services && this.state.componentsConfiguration.services.permission > permissions.hidden && (
                          <Element name={buttonsSidebar.services}>
                            <Services
                              t={t}
                              dossier={dossier}
                              isPurchase = {true}
                              combos= {combos }
                              showAddModal={dossierServiceRelateds.showAddModal}
                              datesNotValid={dossierServiceRelateds.datesNotValid}
                              services={dossierServiceRelateds.rows}
                              subTypesAllowedToCreate={dossierServiceRelateds.subTypesToCreate}
                              canAdd={dossierServiceRelateds.canAdd}
                              dossierType={dossierTypesConstant.purchase}
                              readOnlyFields={true}
                              isPreCancelled={false}
                              open={activeSection === buttonsSidebar.services}
                              componentConfiguration={this.state.componentsConfiguration.services}
                              historical = {true}
                              handleToUpdateComponent = {this.handleToUpdateComponent.bind(this)}
                              pristine={pristine}
                              actions={{
                                fetchSalesServices,
                                fetchDossierSubTypes,
                                fetchEntitySubTypeCombo,
                                fetchEntityFilterSubTypeCombo,
                                saveSalesService,
                                fetchDefaultEntity,
                                fetchNotValidDatesService,
                                openOrCloseServiceAddModal,
                                setActiveSection
                              }}
                              customFields={this.props.dossier.customFieldsByComponent.find(x => x.componentCode === buttonsSidebar.services)?.customFields}
                            />
                          </Element>
                        )}
                        {dossierSubTypeId === dossierSubTypeIdConstants.compra_vo && this.state.componentsConfiguration.other_data && this.state.componentsConfiguration.other_data.permission > permissions.hidden && (
                          <Element name={buttonsSidebar.other_data}>
                            <OtherData
                              t={t}
                              dossierType={dossierTypesConstant.purchase}
                              combos={{
                                ivaTypeCombo,
                                paymentMethodPurchase,
                                laterUseCombo,
                                vehicleType,
                                previousUsageCombo,
                                activityClientCombo
                              }}
                              actions={{ fetchPurchaseOtherDataHistorical, setActiveSection }}
                              dossierId={dossierId}
                              pristine={pristine}
                              handleToUpdateComponent={this.handleToUpdateComponent.bind(this)}
                              isOtherDataLoaded={this.state.isOtherDataLoaded}
                              readOnlyFields={!savePurchasePermission}
                              open={activeSection === buttonsSidebar.other_data}
                              componentConfiguration={this.state.componentsConfiguration.other_data}
                              customFields={this.props.dossier.customFieldsByComponent.find(x => x.componentCode === buttonsSidebar.other_data)?.customFields}
                            />
                          </Element>
                        )}
                      </div>
                    </div>
                  )
                }
                {
                  activeTab === buttonsHeader.gestor_documental && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden && (
                    <div className="dossierGridPart-ComponentSection-Inner sales-panel gestorDocumental">
                      <div className="ComponentSection-InnerData">
                        <DocumentaryManagementPage
                          dossier={dossier}
                          dossierType={dossierTypesConstant.purchase}
                          saveSelectedDocuments={saveSelectedDocuments}
                          documentExport={documentExport}
                          combos={this.props.scombos}
                          isHistorical={true}
                          tabConfiguration={this.state.activeTabConfiguration}
                        />
                      </div>
                    </div>
                  )
                }
                {
                  activeTab === buttonsHeader.expedientes_relacionados && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden && (
                    <div className="dossierGridPart-ComponentSection-Inner related-dossiers-table">
                      <div className="ComponentSection-InnerData">
                        <RelatedDossiers
                          t={t}
                          relatedDossiersOrderBy = {this.props.relatedDossiersOrderBy}
                          panelsPaddingTop = {panelsPaddingTop}
                          history = {this.props.history}
                          dossierRelatedDossiers = {dossierRelatedDossiers}
                          dossierId= {dossierId}
                          isHistorical= {true}
                          actions={{ fetchFilteredRelatedDossier }}
                        />
                      </div>
                    </div>
                  )
                }
                {
                  activeTab === buttonsHeader.planteo_economico && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden && (
                    <PurchaseEconomicPlan
                      t={t}
                      isVN={dossierPurchaseComponent.showNewVehicleFields}
                      actions={{ fetchPurchaseDossierEconomic }}
                      combos={{ ivaTypeCombo }}
                      readOnlyFields={!savePurchasePermission}
                      panelsPaddingTop={panelsPaddingTop}
                      dossierId={dossierId}
                      historical={true}
                      pristine={pristine}
                      isEconomicLoaded={this.state.isEconomicLoaded}
                      handleToUpdateComponent={this.handleToUpdateComponent.bind(this)}
                      dossierEconomicPlan = {dossierPurchaseEconomicPlanComponent}
                      dossierPurchaseComponent = {dossierPurchaseComponent}
                      tabsConfiguration = {viewConfiguration.tabsConfiguration}
                      activeTab={activeTab}
                      openDocumentEditionModal = {openDocumentEditionModal}
                    />
                  )
                }
                {
                  activeTab === buttonsHeader.actividad_expediente && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden && (
                    <div className="dossierGridPart-ComponentSection-Inner auditInfo">
                      <div sm={12} className="ComponentSection-InnerData">
                        <AuditInfo
                          auditInfo={auditInfo}
                          dossierType={dossierTypesConstant.sales}
                          actions={{ refreshAuditInfo, fetchAuditDetails, fetchAuditDetailsModal, fetchDocumentsGroupedAuditDetails, fetchDocumentsGroupedAuditDetailsModal }}
                          dossier={dossier}
                          historical={true}
                          t={t}
                        />
                      </div>
                    </div>
                  )
                }
                {
                  activeTab === buttonsHeader.observaciones && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden && (
                    <div className="dossierGridPart-ComponentSection-Inner commentComponent">
                      <div className="ComponentSection-InnerData">
                        <CommentComponent
                          comments={comments}
                          actions={{ updateComment, createComment, deleteComment, markCommentAsRead }}
                          readOnlyFields={!savePurchasePermission}
                          dossierType="salesDossier"
                          t={t}
                          tKey={tKey}
                          combos={{ allReasonCombo: this.props.combos.allReasonCombo }}
                          openComponent={activeTab === buttonsHeader.observaciones}
                          dossierId={this.props.dossier.dossierId}
                          historical={true}
                        />

                      </div>
                    </div>
                  )}
              </form>
            </div>

          </div>

        </div>

      </div>
    )
  }
}

var decoratedComponent = TrackingContainer(PurchaseHistorical, false)

export default reduxForm({
  form: 'purchase_dossier'
})(decoratedComponent)
