import userActionTypes from '../../constants/actions/admin/userAdmin'

export function fetchUserList (filter) {
  return {
    type: userActionTypes.FETCH_USER_ADMIN,
    filter
  }
}

export function fetchUserAdminSuccess (userList, filter) {
  return {
    type: userActionTypes.FETCH_USER_ADMIN_SUCCESS,
    userList,
    filter
  }
}

export function openEditUserModal (userId) {
  return {
    type: userActionTypes.OPEN_EDIT_USER_MODAL,
    userId
  }
}

export function openEditUserModalSuccess (user) {
  return {
    type: userActionTypes.OPEN_EDIT_USER_MODAL_SUCCESS,
    user
  }
}

export function openAddUserModal () {
  return {
    type: userActionTypes.OPEN_ADD_USER_MODAL
  }
}

export function openAddUserModalSuccess (uoRoles, uoSalesman) {
  return {
    type: userActionTypes.OPEN_ADD_USER_MODAL_SUCCESS,
    uoRoles,
    uoSalesman
  }
}

export function closeEditUserModal () {
  return {
    type: userActionTypes.CLOSE_EDIT_USER_MODAL
  }
}

export function submitUser (userId, body, filter) {
  return {
    type: userActionTypes.SUBMIT_USER,
    userId,
    body,
    filter
  }
}

export function deleteUser (userId, filter) {
  return {
    type: userActionTypes.DELETE_USER,
    userId,
    filter
  }
}

export function deleteSalesman (userId, salesmanId) {
  return {
    type: userActionTypes.DELETE_SALESMAN,
    userId,
    salesmanId
  }
}

export function recoverSalesman (userId, salesmanId) {
  return {
    type: userActionTypes.RECOVER_SALESMAN,
    userId,
    salesmanId
  }
}

export function recoverUser (userId, filter) {
  return {
    type: userActionTypes.RECOVER_USER,
    userId,
    filter
  }
}

export function setActiveUoRol (activeUoRolId) {
  return {
    type: userActionTypes.SET_ACTIVE_UO_ROL,
    activeUoRolId
  }
}

export function addPermission (permission) {
  return {
    type: userActionTypes.ADD_PERMISSION,
    permission
  }
}

export function addUO (UO) {
  return {
    type: userActionTypes.ADD_PERMISSION,
    UO
  }
}

export function deleteUO (UO) {
  return {
    type: userActionTypes.DELETE_UO,
    UO
  }
}

export function deletePermission (permission) {
  return {
    type: userActionTypes.DELETE_PERMISSION,
    permission
  }
}

export function adminChangePassword (userId, newPassword, repeatNewPassword, emailAddress) {
  return {
    type: userActionTypes.ADMIN_CHANGE_PASSWORD,
    userId,
    newPassword,
    repeatNewPassword,
    emailAddress
  }
}

export function autoCreateUser (values, resolve, reject) {
  return {
    type: userActionTypes.AUTO_CREATE_USER,
    values,
    resolve,
    reject
  }
}

export function existCif (cif, resolve, reject) {
  return {
    type: userActionTypes.EXIST_CIF,
    cif,
    resolve,
    reject
  }
}

export function openUploadSignModal () {
  return {
    type: userActionTypes.OPEN_SIGN_UPLOAD_MODAL
  }
}

export function openUploadSignModalSuccess () {
  return {
    type: userActionTypes.OPEN_SIGN_UPLOAD_MODAL_SUCCESS
  }
}

export function checkCanCloseSignModal (showAdvise, resolve) {
  return {
    type: userActionTypes.CHECK_CAN_CLOSE_SIGN_MODAL,
    showAdvise,
    resolve
  }
}

export function uploadSign (file, userId, resolve) {
  return {
    type: userActionTypes.UPLOAD_SIGN,
    file,
    userId,
    resolve
  }
}

export function closeUploadSignModal () {
  return {
    type: userActionTypes.CLOSE_SIGN_UPLOAD_MODAL
  }
}

export function closeUploadSignModalSuccess () {
  return {
    type: userActionTypes.CLOSE_SIGN_UPLOAD_MODAL_SUCCESS
  }
}

export function downloadSignUser (userId) {
  return {
    type: userActionTypes.DOWNLOAD_SIGN,
    userId
  }
}

export function uploadUserMassDocument (file, code, callback, resolve, t) {
  return {
    type: userActionTypes.UPLOAD_USER_MASS_DOC,
    file,
    code,
    callback,
    resolve,
    t
  }
}

export function clearUserAdminSearchFilter () {
  return {
    type: userActionTypes.CLEAR_USER_ADMIN_SEARCH_FILTER
  }
}
