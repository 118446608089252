import React, { Component } from 'react'
import { Button, Modal, Col, Form } from 'react-bootstrap'
import { reduxForm, Field } from 'redux-form'
import InputCheckBox from '../../commons/form/InputCheckBox'
import InputRadio from '../../commons/form/InputRadio'
import { dossierSubTypeId as dossierSubTypeIdConstants } from '../../../constants/dossier/common/dossierSubType'
import { yesNoOptions, telMailOptions, expNotApOptions, expNotProOptions, oriProvOptions, oriCopyOptions, penNotApOptions, yesNoApOptions, firstSecondOptions } from '../../../constants/dossier/sale/checklistPrintDeliveryVN'
class ChecklistDeliveryVoModal extends Component {
  constructor (props) {
    super()
    this.state = {
      checkAll: false,
      docExplanation: false,
      technicalDataSheet: false,
      originalRegistrationCertificate: false,
      provProofTransfer: false,
      invoiceVehicle: false,
      managementVehicle: false,
      manualDelivery: false,
      toyotaPlus: false,
      financingContract: false,
      secureDoc: false,
      maintenanceContract: false,
      secondSetKeys: false,
      vehicleBooks: false,
      vehicleFuncionalities: false,
      welcomePack: null,
      compulsoryInsurance: null,
      complementaryInvoice: null,
      warrantyExtension: null,
      vehicleBooksVn: null,
      secSetKeys: null,
      maintenanceContractActivated: null,
      insuranceConditions: null,
      copyContractFinancing: null,
      salesInvoice: null,
      EECCertificate: null,
      originalsCirculationPermit: null,
      offeredDynamicProof: null,
      explanationGPS: null,
      explanationSteering: null,
      presentationAftersales: null,
      mobilityGuarantees: null,
      radioCheckAll: null
    }
  }

  componentDidUpdate () {

  }

  getSnapshotBeforeUpdate (prevProps) {
    if (prevProps.showModal !== this.props.showModal) {
      this.onChekAll(false)
      this.onCheckRadioAll(null)
    }
    return null
  }

  printChecklist () {
    if (this.props.dossierSubTypeId === dossierSubTypeIdConstants.venta_vo &&
      !this.props.useDeliveryNoteChecklistVO) {
      this.printChecklistVO()
    }
    if (this.props.dossierSubTypeId === dossierSubTypeIdConstants.venta_vo &&
      this.props.useDeliveryNoteChecklistVO) {
      this.printDeliveryNoteChecklistVO()
    }
    if (this.props.dossierSubTypeId === dossierSubTypeIdConstants.venta_vn) {
      this.printChecklistVN()
    }
    this.props.closeModalAction()
  }

  printChecklistVO () {
    var deliveryCheckListSale = {
      ...this.state,
      docExplanation: this.state.docExplanation,
      technicalDataSheet: this.state.technicalDataSheet,
      originalRegistrationCertificate: this.state.originalRegistrationCertificate,
      provProofTransfer: this.state.provProofTransfer,
      invoiceVehicle: this.state.invoiceVehicle,
      managementVehicle: this.state.managementVehicle,
      manualDelivery: this.state.manualDelivery,
      toyotaPlus: this.state.toyotaPlus,
      financingContract: this.state.financingContract,
      secureDoc: this.state.secureDoc,
      maintenanceContract: this.state.maintenanceContract,
      secondSetKeys: this.state.secondSetKeys,
      vehicleBooks: this.state.vehicleBooks,
      vehicleFuncionalities: this.state.vehicleFuncionalities
    }
    this.props.printDeliveryChecklistSale(this.props.dossierId, deliveryCheckListSale)
  }

  printChecklistVN () {
    var deliveryCheckListSale = {
      ...this.state,
      compulsoryInsurance: this.state.compulsoryInsurance,
      complementaryInvoice: this.state.complementaryInvoice,
      warrantyExtension: this.state.warrantyExtension,
      vehicleBooksVn: this.state.vehicleBooksVn,
      secSetKeys: this.state.secSetKeys,
      maintenanceContractActivated: this.state.maintenanceContractActivated,
      insuranceConditions: this.state.insuranceConditions,
      copyContractFinancing: this.state.copyContractFinancing,
      salesInvoice: this.state.salesInvoice,
      EECCertificate: this.state.EECCertificate,
      originalsCirculationPermit: this.state.originalsCirculationPermit,
      offeredDynamicProof: this.state.offeredDynamicProof,
      explanationGPS: this.state.explanationGPS,
      explanationSteering: this.state.explanationSteering,
      presentationAftersales: this.state.presentationAftersales,
      mobilityGuarantees: this.state.mobilityGuarantees,
      welcomePack: this.state.welcomePack
    }
    this.props.printDeliveryChecklistSale(this.props.dossierId, deliveryCheckListSale)
  }

  printDeliveryNoteChecklistVO () {
    var deliveryCheckListSale = {
      ...this.state,
      compulsoryInsurance: this.state.compulsoryInsurance,
      complementaryInvoice: this.state.complementaryInvoice,
      warrantyExtension: this.state.warrantyExtension,
      vehicleBooks: this.state.vehicleBooksVn,
      secondSetKeys: this.state.secSetKeys,
      maintenanceContractActivated: this.state.maintenanceContractActivated,
      insuranceConditions: this.state.insuranceConditions,
      copyContractFinancing: this.state.copyContractFinancing,
      salesInvoice: this.state.salesInvoice,
      EECCertificate: this.state.EECCertificate,
      originalsCirculationPermit: this.state.originalsCirculationPermit,
      offeredDynamicProof: this.state.offeredDynamicProof,
      explanationGPS: this.state.explanationGPS,
      explanationSteering: this.state.explanationSteering,
      presentationAftersales: this.state.presentationAftersales,
      mobilityGuarantees: this.state.mobilityGuarantees,
      welcomePack: this.state.welcomePack
    }
    this.props.printDeliveryChecklistSale(this.props.dossierId, deliveryCheckListSale)
  }

  onChekAll (value) {
    this.setState({
      checkAll: value,
      docExplanation: value,
      technicalDataSheet: value,
      originalRegistrationCertificate: value,
      provProofTransfer: value,
      invoiceVehicle: value,
      managementVehicle: value,
      manualDelivery: value,
      toyotaPlus: value,
      financingContract: value,
      secureDoc: value,
      maintenanceContract: value,
      secondSetKeys: value,
      vehicleBooks: value,
      vehicleFuncionalities: value
    })
  }

  onCheckRadioAll (value) {
    this.setState({
      compulsoryInsurance: value,
      complementaryInvoice: value,
      warrantyExtension: value,
      vehicleBooksVn: value,
      secSetKeys: value,
      maintenanceContractActivated: value,
      insuranceConditions: value,
      copyContractFinancing: value,
      salesInvoice: value,
      EECCertificate: value,
      originalsCirculationPermit: value,
      offeredDynamicProof: value,
      explanationGPS: value,
      explanationSteering: value,
      presentationAftersales: value,
      mobilityGuarantees: value,
      welcomePack: value,
      radioCheckAll: value
    })
  }

  render () {
    const { t, handleSubmit, showModal, closeModalAction, dossierSubTypeId, useDeliveryNoteChecklistVO } = this.props
    const tKey = 'DOSSIER_COMPONENTS.DELIVERY.CHECKLIST_MODAL.'
    var classname = dossierSubTypeId && dossierSubTypeId === dossierSubTypeIdConstants.venta_vn ? 'checklistmodal-modal' : ''
    return (
      <Modal show={showModal} onHide={() => closeModalAction()} className={classname} >
        <Form autoComplete="off"
          onSubmit={handleSubmit(this.printChecklist.bind(this))}>
          <Modal.Header closeButton>
            <Modal.Title>{t(`${tKey}TITLE`)}</Modal.Title>
          </Modal.Header>
          <Modal.Body className='modal-body-checklist'>
            {dossierSubTypeId && dossierSubTypeId === dossierSubTypeIdConstants.venta_vo &&
              !useDeliveryNoteChecklistVO && <>
              <Field
                name="chekAll"
                key="chekAll"
                colSm={12}
                placeholder={t(`${tKey}CHEK_ALL`)}
                component={InputCheckBox}
                value={this.state.checkAll}
                onChange={(value) => this.onChekAll(value)}
                input = {{ value: this.state.checkAll, onChange: (value) => this.onChekAll(value) }}
                customClass = {'text-bold'}
              />

              <Field
                key="docExplanation"
                name="docExplanation"
                colSm={12}
                placeholder={t(`${tKey}DOC_EXPLANATION`)}
                component={InputCheckBox}
                defaultValue={this.state.docExplanation}
                input = {{ value: this.state.docExplanation, onChange: (value) => this.setState({ docExplanation: value }) }}
              />
              <Field
                key="technicalDataSheet"
                name="technicalDataSheet"
                colSm={12}
                placeholder={t(`${tKey}TECHNICAL_DATA_SHEET`)}
                component={InputCheckBox}
                input = {{ value: this.state.technicalDataSheet, onChange: (value) => this.setState({ technicalDataSheet: value }) }}
              />
              <Field
                key="originalRegistrationCertificate"
                name="originalRegistrationCertificate"
                colSm={12}
                placeholder={t(`${tKey}ORIGINAL_REGISTRATION_CERTIFICATE`)}
                component={InputCheckBox}
                input = {{ value: this.state.originalRegistrationCertificate, onChange: (value) => this.setState({ originalRegistrationCertificate: value }) }}
              />
              <Field
                key="provProofTransfer"
                name="provProofTransfer"
                colSm={12}
                placeholder={t(`${tKey}PROV_PROOF_TRANSFER`)}
                component={InputCheckBox}
                input = {{ value: this.state.provProofTransfer, onChange: (value) => this.setState({ provProofTransfer: value }) }}
              />
              <Field
                key="invoiceVehicle"
                name="invoiceVehicle"
                colSm={12}
                placeholder={t(`${tKey}INVOICE_VEHICLE`)}
                component={InputCheckBox}
                input = {{ value: this.state.invoiceVehicle, onChange: (value) => this.setState({ invoiceVehicle: value }) }}
              />
              <Field
                key="managementVehicle"
                name="managementVehicle"
                colSm={12}
                placeholder={t(`${tKey}MANAGEMENT_VEHICLE`)}
                component={InputCheckBox}
                input = {{ value: this.state.managementVehicle, onChange: (value) => this.setState({ managementVehicle: value }) }}
              />
              <Field
                key="manualDelivery"
                name="manualDelivery"
                colSm={12}
                placeholder={t(`${tKey}MANUAL_DELIVERY`)}
                component={InputCheckBox}
                input = {{ value: this.state.manualDelivery, onChange: (value) => this.setState({ manualDelivery: value }) }}
              />
              <Field
                key="toyotaPlus"
                name="toyotaPlus"
                colSm={12}
                placeholder={t(`${tKey}TOYOTA_PLUS`)}
                component={InputCheckBox}
                input = {{ value: this.state.toyotaPlus, onChange: (value) => this.setState({ toyotaPlus: value }) }}
              />
              <Field
                key="financingContract"
                name="financingContract"
                colSm={12}
                placeholder={t(`${tKey}FINANCING_CONTRACT`)}
                component={InputCheckBox}
                input = {{ value: this.state.financingContract, onChange: (value) => this.setState({ financingContract: value }) }}
              />
              <Field
                key="secureDoc"
                name="secureDoc"
                colSm={12}
                placeholder={t(`${tKey}SECURE_DOC`)}
                component={InputCheckBox}
                input = {{ value: this.state.secureDoc, onChange: (value) => this.setState({ secureDoc: value }) }}
              />
              <Field
                key="maintenanceContract"
                name="maintenanceContract"
                colSm={12}
                placeholder={t(`${tKey}MAINTENANCE_CONTRACT`)}
                component={InputCheckBox}
                input = {{ value: this.state.maintenanceContract, onChange: (value) => this.setState({ maintenanceContract: value }) }}
              />
              <Field
                key="secondSetKeys"
                name="secondSetKeys"
                colSm={12}
                placeholder={t(`${tKey}SECOND_SET_KEYS`)}
                component={InputCheckBox}
                input = {{ value: this.state.secondSetKeys, onChange: (value) => this.setState({ secondSetKeys: value }) }}
              />
              <Field
                key="vehicleBooks"
                name="vehicleBooks"
                colSm={12}
                placeholder={t(`${tKey}VEHICLE_BOOKS`)}
                component={InputCheckBox}
                input = {{ value: this.state.vehicleBooks, onChange: (value) => this.setState({ vehicleBooks: value }) }}
              />
              <Field
                key="vehicleFuncionalities"
                name="vehicleFuncionalities"
                colSm={12}
                placeholder={t(`${tKey}VEHICLE_FUNCIONALITIES`)}
                component={InputCheckBox}
                input = {{ value: this.state.vehicleFuncionalities, onChange: (value) => this.setState({ vehicleFuncionalities: value }) }}
              />
            </> }
            {((dossierSubTypeId && dossierSubTypeId === dossierSubTypeIdConstants.venta_vn) ||
            (dossierSubTypeId && dossierSubTypeId === dossierSubTypeIdConstants.venta_vo &&
            useDeliveryNoteChecklistVO)) && <>
              <Field
                key="radioAll"
                name="radioAll"
                groupName="radioAll"
                colSm={12}
                inline
                placeholder={t(`${tKey}CHEK_ALL_RADIO`)}
                component={InputRadio}
                items={firstSecondOptions}
                t={t}
                oneLine = {true}
                input = {{ value: this.state.radioCheckAll, onChange: (value) => this.onCheckRadioAll(value) }}
                customClass = {'text-bold'}
              />
              <Field
                key="welcomePack"
                name="welcomePack"
                groupName="welcomePack"
                colSm={12}
                inline
                placeholder={t(`${tKey}WELCOME_PACK`)}
                component={InputRadio}
                items={yesNoOptions}
                t={t}
                oneLine = {true}
                input = {{ value: this.state.welcomePack, onChange: (value) => this.setState({ welcomePack: value }) }}
              />
              <Field
                key="mobilityGuarantees"
                name="mobilityGuarantees"
                groupName="mobilityGuarantees"
                colSm={12}
                inline
                placeholder={t(`${tKey}MOBILITY_GUARANTEES`)}
                component={InputRadio}
                items={yesNoOptions}
                t={t}
                oneLine = {true}
                input = {{ value: this.state.mobilityGuarantees, onChange: (value) => this.setState({ mobilityGuarantees: value }) }}
              />
              <Field
                key="presentationAftersales"
                name="presentationAftersales"
                groupName="presentationAftersales"
                colSm={12}
                inline
                placeholder={t(`${tKey}PRESENTATION_AFTERSALES`)}
                component={InputRadio}
                items={telMailOptions}
                t={t}
                oneLine = {true}
                input = {{ value: this.state.presentationAftersales, onChange: (value) => this.setState({ presentationAftersales: value }) }}
              />
              <Field
                key="explanationSteering"
                name="explanationSteering"
                groupName="explanationSteering"
                colSm={12}
                inline
                placeholder={t(`${tKey}EXPLANATION_STEERING`)}
                component={InputRadio}
                items={expNotApOptions}
                t={t}
                oneLine = {true}
                input = {{ value: this.state.explanationSteering, onChange: (value) => this.setState({ explanationSteering: value }) }}
              />
              <Field
                key="explanationGPS"
                name="explanationGPS"
                groupName="explanationGPS"
                colSm={12}
                inline
                placeholder={t(`${tKey}EXPLANATION_GPS`)}
                component={InputRadio}
                items={expNotProOptions}
                t={t}
                oneLine = {true}
                input = {{ value: this.state.explanationGPS, onChange: (value) => this.setState({ explanationGPS: value }) }}
              />
              <Field
                key="offeredDynamicProof"
                name="offeredDynamicProof"
                groupName="offeredDynamicProof"
                colSm={12}
                inline
                placeholder={t(`${tKey}OFFERED_DYNAMIC_PROOF`)}
                component={InputRadio}
                items={yesNoOptions}
                t={t}
                oneLine = {true}
                input = {{ value: this.state.offeredDynamicProof, onChange: (value) => this.setState({ offeredDynamicProof: value }) }}
              />
              <Field
                key="originalsCirculationPermit"
                name="originalsCirculationPermit"
                groupName="originalsCirculationPermit"
                colSm={12}
                inline
                placeholder={t(`${tKey}ORIGINALS_CIRCULATION_PERMIT`)}
                component={InputRadio}
                items={oriProvOptions}
                t={t}
                oneLine = {true}
                input = {{ value: this.state.originalsCirculationPermit, onChange: (value) => this.setState({ originalsCirculationPermit: value }) }}
              />
              <Field
                key="EECCertificate"
                name="EECCertificate"
                groupName="EECCertificate"
                colSm={12}
                inline
                placeholder={t(`${tKey}EEC_CERTIFICATE`)}
                component={InputRadio}
                items={yesNoOptions}
                t={t}
                oneLine = {true}
                input = {{ value: this.state.EECCertificate, onChange: (value) => this.setState({ EECCertificate: value }) }}
              />
              <Field
                key="salesInvoice"
                name="salesInvoice"
                groupName="salesInvoice"
                colSm={12}
                inline
                placeholder={t(`${tKey}SALES_INVOICE`)}
                component={InputRadio}
                items={oriCopyOptions}
                t={t}
                oneLine = {true}
                input = {{ value: this.state.salesInvoice, onChange: (value) => this.setState({ salesInvoice: value }) }}
              />
              <Field
                key="copyContractFinancing"
                name="copyContractFinancing"
                groupName="copyContractFinancing"
                colSm={12}
                inline
                placeholder={t(`${tKey}COPY_CONTRACT_FINANCING`)}
                component={InputRadio}
                items={yesNoOptions}
                t={t}
                oneLine = {true}
                input = {{ value: this.state.copyContractFinancing, onChange: (value) => this.setState({ copyContractFinancing: value }) }}
              />
              <Field
                key="insuranceConditions"
                name="insuranceConditions"
                groupName="insuranceConditions"
                colSm={12}
                inline
                placeholder={t(`${tKey}INSURANCE_CONDITIONS`)}
                component={InputRadio}
                items={yesNoOptions}
                t={t}
                oneLine = {true}
                input = {{ value: this.state.insuranceConditions, onChange: (value) => this.setState({ insuranceConditions: value }) }}
              />
              <Field
                key="maintenanceContractActivated"
                name="maintenanceContractActivated"
                groupName="maintenanceContractActivated"
                colSm={12}
                inline
                placeholder={t(`${tKey}MAINTENANCE_CONTRACT_ACTIVATED`)}
                component={InputRadio}
                items={penNotApOptions}
                t={t}
                oneLine = {true}
                input = {{ value: this.state.maintenanceContractActivated, onChange: (value) => this.setState({ maintenanceContractActivated: value }) }}
              />
              <Field
                key="secSetKeys"
                name="secSetKeys"
                groupName="secSetKeys"
                colSm={12}
                inline
                placeholder={t(`${tKey}2ND_SET_KEYS`)}
                component={InputRadio}
                items={yesNoApOptions}
                t={t}
                oneLine = {true}
                input = {{ value: this.state.secSetKeys, onChange: (value) => this.setState({ secSetKeys: value }) }}

              />
              <Field
                key="vehicleBooksVn"
                name="vehicleBooksVn"
                groupName="vehicleBooksVn"
                colSm={12}
                inline
                placeholder={t(`${tKey}VEHICLE_BOOKS_VN`)}
                component={InputRadio}
                items={yesNoOptions}
                t={t}
                oneLine = {true}
                input = {{ value: this.state.vehicleBooksVn, onChange: (value) => this.setState({ vehicleBooksVn: value }) }}
              />
              <Field
                key="warrantyExtension"
                name="warrantyExtension"
                groupName="warrantyExtension"
                colSm={12}
                inline
                placeholder={t(`${tKey}WARRANTY_EXTENSION`)}
                component={InputRadio}
                items={yesNoOptions}
                t={t}
                oneLine = {true}
                input = {{ value: this.state.warrantyExtension, onChange: (value) => this.setState({ warrantyExtension: value }) }}
              />
              <Field
                key="complementaryInvoice"
                name="complementaryInvoice"
                groupName="complementaryInvoice"
                colSm={12}
                inline
                placeholder={t(`${tKey}COMPLEMENTARY_INVOICE`)}
                component={InputRadio}
                items={yesNoApOptions}
                t={t}
                oneLine = {true}
                input = {{ value: this.state.complementaryInvoice, onChange: (value) => this.setState({ complementaryInvoice: value }) }}
              />
              <Field
                key="compulsoryInsurance"
                name="compulsoryInsurance"
                groupName="compulsoryInsurance"
                colSm={12}
                inline
                placeholder={t(`${tKey}COMPULSORY_INSURANCE`)}
                component={InputRadio}
                items={yesNoOptions}
                t={t}
                oneLine = {true}
                input = {{ value: this.state.compulsoryInsurance, onChange: (value) => this.setState({ compulsoryInsurance: value }) }}
              />
            </>}
          </Modal.Body>
          <Modal.Footer>
            <Col sm={6} className="btn-wrapper">
              <Button id="btn_save" className="btn-standard" type="submit">{t(`${tKey}ACCEPT`)}</Button>
            </Col>
          </Modal.Footer>
        </Form>
      </Modal>
    )
  }
}

export default reduxForm({
  form: 'checklist_delivery_vo_modal'
})(ChecklistDeliveryVoModal)
