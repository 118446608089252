import { put, call, takeEvery, select } from 'redux-saga/effects'
import { reset } from 'redux-form'
import dossierActionTypes from '../../../constants/actions/dossier/dossier'
import { getAuth } from '../../../selectors/access/auth'
import getPrintDeliveryChecklist from '../../../services/dossierregistrationDelivery/getPrintDeliveryChecklist'
import { dossierTypes as dossierTypesConstant } from '../../../constants/dossier/common/dossierTypes'
import { showLoader, hideLoader } from '../../../actions/common'
import messageModalActions from '../../../actions/modals/messageModal'
import { handleError } from '../../errors/errorManager'
import { yesNoModal } from '../../modalsListeners/yesNoModal'
import { initializeDossier } from '../commonDossierSagas'
import { fetchRegistrationDeliveryDossierSuccess } from '../../../actions/dossier/registrationDelivery'
import getRegistrationDeliveryHeaderAndButtons from '../../../services/dossier/get/getRegistrationDeliveryHeaderAndButtons'
import getStageCheckList from '../../../services/dossier/get/getStageCheckList'
import { refreshDossierByType } from '../common/documents/managementModalSagas'

export function * printDeliveryChecklist ({ dossierId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(getPrintDeliveryChecklist, auth.token, dossierId)
    yield call(refreshDossierByType, {
      dossierType: dossierTypesConstant.registrationDelivery,
      dossierDocumentChanged: true,
      entityDocumentChanged: false,
      vehicleDocumentChanged: false,
      documentEditionalModalState: null
    })
    const updatedSalesDossier = yield select(state => state.registrationDeliveryDossier)
    const headerAndButtons = yield call(getRegistrationDeliveryHeaderAndButtons, auth.token, updatedSalesDossier.dossierId)
    const checkListHeader = yield call(getStageCheckList, updatedSalesDossier.dossierId, auth.token)
    const registrationDelivery = { ...headerAndButtons, checkListHeader }
    yield put(fetchRegistrationDeliveryDossierSuccess(registrationDelivery))
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          const dossierState = yield select(state => state.registrationDeliveryDossier)
          yield call(initializeDossier, dossierTypesConstant.registrationDelivery, dossierState)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('registrationDeliveryDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchPrintDeliveryChecklist () {
  yield takeEvery(dossierActionTypes.REGISTRATION_DELIVERY_PRINT_DELIVERY_CHECKLIST, printDeliveryChecklist)
}
