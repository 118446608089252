import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import Header from '../../components/layout/Header'
import authActions from '../../actions/auth'
import { fetchOrganizedUnitsTree } from '../../actions/combos/combos'
import { translate } from 'react-polyglot'
import userManualActions from '../../actions/profile/usersManual'
// import { withRouter } from 'react-router-dom'

export function mapStateToProps (state) {
  let entityData = {}
  let vehicleData = {}
  let isProvider = false
  const userCIF = state.auth.userCIF
  if (state.salesDossier.dossierId) {
    entityData = state.salesDossier.entityComponent
    vehicleData = state.salesDossier.vehicleComponent
  } else if (state.paperworkDossier.dossierId) {
    entityData = state.paperworkDossier.entityComponent
    vehicleData = state.paperworkDossier.vehicleComponent
  } else if (state.campaignDossier.dossierId) {
    entityData = state.campaignDossier.entityComponent
    vehicleData = state.campaignDossier.vehicleComponent
  } else if (state.purchaseDossier.dossierId) {
    entityData = state.purchaseDossier.entityComponent
    vehicleData = state.purchaseDossier.vehicleComponent
    isProvider = true
  }

  return {
    ...state.auth,
    entityData,
    vehicleData,
    isProvider,
    userCIF,
    combos: {
      UOTreeCombo: state.combos.UOTreeCombo
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      ...authActions,
      fetchOrganizedUnitsTree,
      ...userManualActions
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(Header))
