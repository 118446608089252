import { put, call, all, select, takeEvery } from 'redux-saga/effects'
import { change, reset, initialize } from 'redux-form'
import { replace } from 'connected-react-router'
import { yesNoModal } from '../../modalsListeners/yesNoModal'
import { permissions, entityTypesId, clientInformation, dossierSubTypes } from '../../../constants/backendIds'
import { buttonsHeader, buttonsHeaderPermisionsIdReverse } from '../../../constants/dossier/common/buttonsHeader'
import { documentEntityType as documentEntityTypeConstant } from '../../../constants/dossier/common/documentEntityType'
import dossierActionsTypes from '../../../constants/actions/dossier/dossier'
import { getAuth } from '../../../selectors/access/auth'
import { getActiveTab } from '../../../selectors/dossier/common/buttonsHeader'
import { getDossierRelatedDossiersConf } from '../../../selectors/dossier/common/dossierRelatedDossiers'
import { showLoader, hideLoader } from '../../../actions/common'
import { refreshDocumentManagementModal } from '../../../actions/dossier/common/documentManagementModal'
import { setActiveTab } from '../../../actions/dossier/common/buttonsHeader'
import { fetchPurchaseCatalogueModelSuccess, fetchPurchaseDossierSuccess } from '../../../actions/dossier/purchase'
import { fetchCombosSuccess } from '../../../actions/combos/combos'
import messageModalActions from '../../../actions/modals/messageModal'
import getDossierById from '../../../services/dossierPurchase/get/getPurchaseDossierById'
import getDossierHistoricalById from '../../../services/dossierPurchase/get/getPurchaseHistoricalDossierById'
import getPurchaseHistoricalDossierDependandtsById from '../../../services/dossierPurchase/get/getPurchaseHistoricalDossierDependandtsById'
import getPurchaseDossierDependandtsById from '../../../services/dossierPurchase/get/getPurchaseDossierDependandtsById'
import getPurchaseDossierEconomicById from '../../../services/dossierPurchase/get/getPurchaseDossierEconomicById'
import getPurchaseHistoricalDossierEconomicById from '../../../services/dossierPurchase/get/getPurchaseHistoricalDossierEconomicById'
// Header Component
import getStageCheckList from '../../../services/dossier/get/getStageCheckList'
import { switchFavoriteSuccess } from '../../../actions/dossier/common/statusHeader'
// Purchase Component and Delivery Component
import getPurchaseTypesByDossierId from '../../../services/purchaseType/getPurchaseTypesByDossierId'
import getPurchaseHeaderAndButtons from '../../../services/dossierPurchase/get/getPurchaseHeaderAndButtons'
import getDeliveryLocationDefaultService from '../../../services/deliveryLocation/getDeliveryLocationDefault'
import getSalesman from '../../../services/salesman/getSalesman'
// Client component
import getEntityTypes from '../../../services/entityType/getEntityTypes'
import getChannels from '../../../services/sourcechannel/getChanel'
import getSegmentation from '../../../services/segmentation/getSegmentation'
import getDossierEntityService from '../../../services/dossier/get/getDossierEntity'
import getEntityById from '../../../services/entity/getEntityById'
import getDossierHistoricalEntity from '../../../services/dossier/get/getDossierHistoricalEntity'
import { fetchEntitySubTypeCombo } from '../../../sagas/combos/fetchEntitySubTypeCombo'
import { fetchEntityFilterSubTypeCombo } from '../../../sagas/combos/fetchEntityFilterSubTypeCombo'
// Vehicle component
import getBrands from '../../../services/brand/getBrands'
import getFuels from '../../../services/fuel/getFuels'
import getPreviousUsage from '../../../services/previousUsage/getPreviousUsage'
import getPurchaseWithVehicleById from '../../../services/dossier/get/getPurchaseWithVehicleById'
import getPurchaseHistoricalWithVehicleById from '../../../services/dossier/get/getPurchaseHistoricalWithVehicleById'
import getEntitySubType from '../../../services/entitySubType/getEntitySubType'
import getEntityFilterSubType from '../../../services/entity/getEntityFilterSubType'
import getVehicleByPlateOrChassis from '../../../services/vehicle/getVehicleByPlateOrChassis'
import getModelCombo from '../../../services/model/getModelCombo'
import getCatalogueModel from '../../../services/catalogueModel/getCatalogueModel'
import getCarlineCombo from '../../../services/carline/getCarlineCombo'
import getCatalogueCombo from '../../../services/catalogue/getCatalogueCombo'
import getColorCombo from '../../../services/color/getColorCombo'
import getTapestryCombo from '../../../services/tapestry/getTapestryCombo'

// DossierPurchaseEconomicPlan Component
import getIvaTypeService from '../../../services/ivaType/getIvaType'
// Related component
import getDossierRelatedDossiers from '../../../services/dossier/get/getDossierRelatedDossiers'
import getDossierHistoricalRelatedDossiers from '../../../services/dossier/get/getDossierHistoricalRelatedDossiers'
// PaymentMethodPurchase
import getPaymentMethodPurchase from '../../../services/paymentMethodPurchase/getPaymentMethodPurchase'
// LaterUse
import getLaterUse from '../../../services/laterUse/getLaterUse'
// ActivityClient
import getActivityClient from '../../../services/activityClient/getActivityClient'
// ActivityBuyer
import getActivityBuyer from '../../../services/activityBuyer/getActivityBuyer'
// vehicleType
import getVehicleType from '../../../services/vehicleType/getVehicleType'
import { handleError } from '../../errors/errorManager'
import putDossierChangeSubType from '../../../services/dossier/put/putDossierChangeSubType'
import getTuneUpDestination from '../../../services/tuneUpDestination/getTuneUpDestination'
// Contact
import getDossierContact from '../../../services/dossier/get/getDossierContact'
import getDossierContactHistorical from '../../../services/dossier/get/getDossierContactHistorical'
// Other data
import getDossierOtherData from '../../../services/dossier/get/getDossierOtherData'
import getDossierOtherDataHistorical from '../../../services/dossier/get/getDossierOtherDataHistorical'
// EconomicPlan Componente
import getPurchasePresentTypes from '../../../services/present/getPurchasePresentTypes'
import getMaritalStatus from '../../../services/maritalStatus/getMaritalStatus'
import settings from '../../../setting'
import getDocumentTypeUseByCode from '../../../services/documentTypeUse/getDocumentTypeUseByCode'
import { documenTypeUseCodes } from '../../../constants/document/documentTypeUse'

export function * fetchPurchaseDossier ({ dossierId, resolve, reject, historical = false }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const purchaseDossier = yield select(state => state.purchaseDossier)
    const dossierRelatedDossiersConf = yield select(getDossierRelatedDossiersConf)
    let dossier = {}
    let checkListHeader = []
    let entityComponent = {}
    let dossierRelatedDossiers = []
    const auditInfo = []
    if (historical) {
      dossier = yield call(getDossierHistoricalById, dossierId, auth.token)
      entityComponent = yield call(getDossierHistoricalEntity, dossierId, auth.token)
      dossierRelatedDossiers = yield call(getDossierHistoricalRelatedDossiers, dossierId, dossierRelatedDossiersConf.orderby, auth.token)
    } else {
      dossier = yield call(getDossierById, dossierId, auth.token)
      // entityComponent = yield call(getDossierEntityService, dossierId, auth.token)
      // entityComponent.previousEntity = {...entityComponent}
      checkListHeader = yield call(getStageCheckList, dossierId, auth.token)
      dossierRelatedDossiers = yield call(getDossierRelatedDossiers, dossierId, dossierRelatedDossiersConf.orderby, auth.token)
    }

    if (historical) {
      dossier.entityFromParentDossier = (!!dossier.dossierParentId && dossier.parentEntityId !== undefined && dossier.parentEntityId !== null && entityComponent !== null && entityComponent !== undefined && entityComponent.entityId === dossier.parentEntityId) ||
                                        (dossier.sameEntity !== null && dossier.sameEntity !== undefined && !dossier.sameEntity)
    } else {
      dossier.entityFromParentDossier = ((!!dossier.dossierParentId && dossier.parentEntityId !== undefined && dossier.parentEntityId !== null && (dossier.entityId === dossier.parentEntityId)) ||
                                        (dossier.sameEntity !== null && dossier.sameEntity !== undefined && !dossier.sameEntity))
    }

    const [
      entityTypeCombo, channelCombo, maritalStatusCombo, segmentationCombo,
      brandCombo, fuelCombo, modelCombo
    ] = yield all([
      call(getEntityTypes, auth.token, dossier.dossierSubTypeId),
      call(getChannels, auth.token),
      call(getMaritalStatus, auth.token),
      call(getSegmentation, auth.token),
      call(getBrands, auth.token, dossier.dossierSubTypeId, dossier.organizedUnitId, historical),
      call(getFuels, auth.token, historical),
      call(getModelCombo, auth.token)
      // historical ? call(getPurchaseHistoricalWithVehicleById, dossierId, auth.token) : call(getPurchaseWithVehicleById, dossierId, auth.token)
    ])
    entityComponent = purchaseDossier.entityComponent
    entityComponent = { ...entityComponent, ...dossier.dossierEntityComponent }
    if (purchaseDossier.entityComponent.updated === true) {
      entityComponent = yield call(getDossierEntityService, dossierId, auth.token)
      entityComponent.updated = true
      entityComponent.previousEntity = { ...entityComponent }
    }
    // entityChildComponent.previousEntity = {...entityChildComponent}

    let dossierContactComponent = purchaseDossier.dossierContactComponent
    if (dossierContactComponent.updated === true) {
      dossierContactComponent = yield call(getDossierContact, dossierId, auth.token)
      dossierContactComponent.updated = true
    }
    let dossierCompleteOtherDataComponent = purchaseDossier.dossierCompleteOtherDataComponent
    if (dossierCompleteOtherDataComponent.updated === true) {
      dossierCompleteOtherDataComponent = yield call(getDossierOtherData, dossierId, auth.token)
      dossierCompleteOtherDataComponent.updated = true
    } else {
      dossierCompleteOtherDataComponent = dossier.dossierCompleteOtherDataComponent
    }

    const [
      purchaseTypeCombo, previousUsageCombo, ivaTypeCombo, paymentMethodPurchase, laterUseCombo, activityClientCombo, activityBuyerCombo, vehicleType, tuneUpDestinationCombo, purchasePresentTypeCombo, salesmanCombo
    ] = yield all([
      call(getPurchaseTypesByDossierId, auth.token, dossier.dossierId, historical),
      call(getPreviousUsage, dossier.organizedUnitId, auth.token),
      call(getIvaTypeService, auth.token, dossier.dossierId, historical),
      call(getPaymentMethodPurchase, auth.token),
      call(getLaterUse, auth.token),
      call(getActivityClient, auth.token),
      call(getActivityBuyer, auth.token),
      call(getVehicleType, auth.token),
      call(getTuneUpDestination, auth.token),
      call(getPurchasePresentTypes, dossier.organizedUnitId, dossier.dossierId, auth.token),
      call(getSalesman, dossier.organizedUnitId, auth.token)
    ])
    const entitySubTypeCombo = entityComponent.entityTypeId
      ? yield call(getEntitySubType, auth.token, entityComponent.entityTypeId)
      : []
    const entityFilterSubTypeCombo = entityComponent.entitySubTypeId
      ? yield call(getEntityFilterSubType, auth.token, entityComponent.entityTypeId, entityComponent.entitySubTypeId, dossier.organizedUnitId)
      : []
    let vehicleComponent = purchaseDossier.vehicleComponent
    vehicleComponent = { ...vehicleComponent, ...dossier.dossierVehicleComponent }
    if (Object.keys(purchaseDossier.vehicleComponent).length === 0 || purchaseDossier.vehicleComponent.updated === true) {
      vehicleComponent = !historical ? yield call(getPurchaseWithVehicleById, dossierId, auth.token) : yield call(getPurchaseHistoricalWithVehicleById, dossierId, auth.token)
      vehicleComponent.updated = true
    }

    yield put(switchFavoriteSuccess(dossier.dossierHeaderComponent.favorite))

    yield put(fetchCombosSuccess({
      purchaseTypeCombo,
      entityTypeCombo,
      channelCombo,
      maritalStatusCombo,
      segmentationCombo,
      entitySubTypeCombo,
      entityFilterSubTypeCombo,
      previousUsageCombo,
      brandCombo,
      fuelCombo,
      ivaTypeCombo,
      paymentMethodPurchase,
      laterUseCombo,
      activityClientCombo,
      activityBuyerCombo,
      vehicleType,
      tuneUpDestinationCombo,
      purchasePresentTypeCombo,
      salesmanCombo,
      modelCombo
    }))

    yield put(fetchPurchaseDossierSuccess({ ...dossier, checkListHeader, entityComponent, dossierContactComponent, vehicleComponent, dossierCompleteOtherDataComponent, dossierRelatedDossiers, auditInfo }))

    const currentActiveTab = yield select(getActiveTab)
    let nextActiveTab = dossier.viewConfiguration.tabsConfiguration.filter((tabConf) => tabConf.permission > permissions.hidden)
    nextActiveTab = nextActiveTab.length > 0 ? nextActiveTab[0] : currentActiveTab
    if (nextActiveTab !== currentActiveTab) yield put(setActiveTab(buttonsHeaderPermisionsIdReverse[nextActiveTab.code]))

    if (!historical && nextActiveTab === buttonsHeader.gestor_documental) {
      yield put(refreshDocumentManagementModal(dossierId))
    }

    yield fetchDependantsPurchaseDossier({ dossierId, historical })

    if (resolve) yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.status === 301) {
        yield put(replace(`/dossier${customHandlerError.json}`))
      } else {
        yield put(messageModalActions.openErrorMessageModal('MESSAGES.SALES_DOSSIER.ERROR_GET_SALES_DOSSIER', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
      if (reject) yield call(reject, customHandlerError)
    }
    const sessionHandler = function * () {
      yield put(reset('purchase_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchPurchaseDossier () {
  yield takeEvery(dossierActionsTypes.FETCH_PURCHASE_DOSSIER, fetchPurchaseDossier)
}

export function * fetchDependantsPurchaseDossier ({ dossierId, historical }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    let dependants = {}
    if (historical) {
      dependants = yield call(getPurchaseHistoricalDossierDependandtsById, dossierId, auth.token)
    } else {
      dependants = yield call(getPurchaseDossierDependandtsById, dossierId, auth.token)
    }
    yield put(fetchPurchaseDossierSuccess(dependants))
  } catch (error) {
    const sessionHandler = function * () {
      yield put(reset('purchase_dossier'))
    }
    yield call(handleError, { error, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}
export function * watchFetchDependantsPurchaseDossier () {
  yield takeEvery(dossierActionsTypes.FETCH_PURCHASE_DOSSIER_DEPENDANTS, fetchDependantsPurchaseDossier)
}

export function * fetchEconomicPlanPurchaseDossier ({ dossierId, historical, pristine }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const purchaseDossier = yield select(state => state.purchaseDossier)
    let dossierPurchaseEconomicPlanComponent = {}
    if (historical) {
      dossierPurchaseEconomicPlanComponent = yield call(getPurchaseHistoricalDossierEconomicById, dossierId, auth.token)
    } else {
      dossierPurchaseEconomicPlanComponent = yield call(getPurchaseDossierEconomicById, dossierId, auth.token)
    }
    dossierPurchaseEconomicPlanComponent.updated = true
    let isVn = purchaseDossier.dossierSubtype === dossierSubTypes.purchase_vn
    if ((settings.clientFieldValidation === clientInformation.FERPER || settings.clientFieldValidation === clientInformation.MCONDE) && isVn === false) {
      let cost = dossierPurchaseEconomicPlanComponent.purchasePresents ? dossierPurchaseEconomicPlanComponent.purchasePresents.reduce((acc, val) => val.isDeleted ?? false ? acc + 0 : acc + val.cost, 0) : 0
      dossierPurchaseEconomicPlanComponent.amountTotal = parseFloat(dossierPurchaseEconomicPlanComponent.amount) + parseFloat(cost)
    }
    if (settings.clientFieldValidation === clientInformation.MCONDE && !isVn) {
      const orderDocTypeUseId = yield call(getDocumentTypeUseByCode, auth.token, documenTypeUseCodes.DOC_RECONDITIONING_ORDER)
      dossierPurchaseEconomicPlanComponent.orderDocTypeUseId = orderDocTypeUseId

      const invoiceDocTypeUseId = yield call(getDocumentTypeUseByCode, auth.token, documenTypeUseCodes.DOC_RECONDITIONING_INVOICE)
      dossierPurchaseEconomicPlanComponent.invoiceDocTypeUseId = invoiceDocTypeUseId
    }
    yield put(change('purchase_dossier', 'dossierPurchaseEconomicPlanComponent', dossierPurchaseEconomicPlanComponent))
    yield put(fetchPurchaseDossierSuccess({ dossierPurchaseEconomicPlanComponent: dossierPurchaseEconomicPlanComponent }))
    if (pristine === true) {
      yield put(initialize('purchase_dossier', { ...purchaseDossier, dossierPurchaseEconomicPlanComponent }))
    }
  } catch (error) {
    const sessionHandler = function * () {
      yield put(reset('purchase_dossier'))
    }
    yield call(handleError, { error, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}
export function * watchFetchEconomicPlanPurchaseDossier () {
  yield takeEvery(dossierActionsTypes.FETCH_PURCHASE_DOSSIER_ECONOMIC_PLAN, fetchEconomicPlanPurchaseDossier)
}

export function * changeDossierSubType ({ dossierId, lastAccessDate }) {
  try {
    const auth = yield select(getAuth)
    const response = yield call(yesNoModal, 'changeDossierSubType')
    if (response) {
      yield put(showLoader())
      yield call(putDossierChangeSubType, dossierId, lastAccessDate, auth.token)
      yield fetchPurchaseDossier({ dossierId })
    }
  } catch (error) {
    const sessionHandler = function * () {
      yield put(reset('purchase_dossier'))
    }
    yield call(handleError, { error, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchChangeDossierSubType () {
  yield takeEvery(dossierActionsTypes.CHANGE_DOSSIER_SUBTYPE, changeDossierSubType)
}

export function * refreshPurchaseDossierEntityFromDocumentModal (documentEditionalModalState) {
  try {
    yield put(showLoader())
    const purchaseDossier = yield select(state => state.purchaseDossier)
    const auth = yield select(getAuth)
    let entityComponent = {}
    let dirty = false
    if (documentEditionalModalState !== null && documentEditionalModalState !== undefined &&
      documentEditionalModalState.entityId) {
      entityComponent = yield call(getEntityById, documentEditionalModalState.entityId, auth.token)
    } else {
      entityComponent = yield call(getDossierEntityService, purchaseDossier.dossierId, auth.token)
    }
    entityComponent.previousEntity = { ...purchaseDossier.entityComponent.previousEntity }
    if (documentEditionalModalState !== null && documentEditionalModalState !== undefined &&
      documentEditionalModalState.dirty !== null && documentEditionalModalState.dirty !== undefined) {
      dirty = documentEditionalModalState.dirty
    }
    if (dirty) {
      yield put(change('purchase_dossier', 'entityComponent', entityComponent))
    } else {
      yield put(initialize('purchase_dossier', { ...purchaseDossier, entityComponent: entityComponent }))
    }
    yield put(fetchPurchaseDossierSuccess({ entityComponent }))
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.SALES_DOSSIER.ERROR_GET_SALES_DOSSIER', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('purchase_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * refreshPurchaseDossierVehicleFromDocumentModal (documentEditionalModalState) {
  try {
    yield put(showLoader())
    const purchaseDossier = yield select(state => state.purchaseDossier)
    const auth = yield select(getAuth)
    const vehicleComponent = yield call(getPurchaseWithVehicleById, purchaseDossier.dossierId, auth.token)
    vehicleComponent.updated = true
    let dirty = false
    if (documentEditionalModalState !== null && documentEditionalModalState !== undefined &&
      documentEditionalModalState.dirty !== null && documentEditionalModalState.dirty !== undefined) {
      dirty = documentEditionalModalState.dirty
    }
    if (dirty) {
      yield put(change('purchase_dossier', 'vehicleComponent', vehicleComponent))
    } else {
      yield put(initialize('purchase_dossier', { ...purchaseDossier, vehicleComponent: vehicleComponent }))
    }
    yield put(fetchPurchaseDossierSuccess({ vehicleComponent }))
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.SALES_DOSSIER.ERROR_GET_SALES_DOSSIER', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('purchase_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * refreshPurchaseDossierFromDocumentModal (documentEditionalModalState) {
  try {
    yield put(showLoader())
    const purchaseDossier = yield select(state => state.purchaseDossier)
    const auth = yield select(getAuth)
    const dossierId = purchaseDossier.dossierId
    let purchase = JSON.parse(JSON.stringify(purchaseDossier))
    let purchaseComponent = purchaseDossier.purchaseComponent
    let dossier = {}
    let dirty = false
    if (dossierId !== null) {
      const headerAndButtons = yield call(getPurchaseHeaderAndButtons, auth.token, dossierId)
      const checkListHeader = yield call(getStageCheckList, dossierId, auth.token)
      const components = yield call(fetchPurchaseComponentsUpdated, purchaseDossier, auth)
      if (documentEditionalModalState === null || documentEditionalModalState === undefined || documentEditionalModalState.documentParentTypeId === documentEntityTypeConstant.DOSSIER || documentEditionalModalState.documentParentTypeId === null) {
        dossier = yield call(getDossierById, dossierId, auth.token)
        const defaultDeliveryAddress = yield call(getDeliveryLocationDefaultService, dossier.organizedUnitId, auth.token)
        purchaseComponent = { ...dossier.dossierPurchaseComponent }
        dossier.dossierCompleteDeliveryComponent.dossierDeliveryComponent.defaultDeliveryAddress = defaultDeliveryAddress
      }
      if (documentEditionalModalState && documentEditionalModalState.dirty) {
        dirty = documentEditionalModalState.dirty
      }
      const lastAccessDate = documentEditionalModalState?.lastAccessDate ?? purchaseDossier.lastAccessDate
      purchase = { ...purchase, checkListHeader, ...headerAndButtons, ...components, ...dossier, lastAccessDate }
      yield put(fetchPurchaseDossierSuccess(purchase))
      yield call(fetchDependantsPurchaseDossier, { dossierId })
      if (dirty) {
        yield put(change('purchase_dossier', 'purchaseComponent', purchaseComponent))
      } else {
        yield put(initialize('purchase_dossier', { ...purchase, purchaseComponent: purchaseComponent }))
      }
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.SALES_DOSSIER.ERROR_GET_SALES_DOSSIER', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('purchase_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * refreshCommonPurchaseFromDocumental () {
  try {
    yield put(showLoader())
    const purchaseDossier = yield select(state => state.purchaseDossier)
    const auth = yield select(getAuth)
    let purchase
    const headerAndButtons = yield call(getPurchaseHeaderAndButtons, auth.token, purchaseDossier.dossierId)
    const checkListHeader = yield call(getStageCheckList, purchaseDossier.dossierId, auth.token)
    purchase = { ...headerAndButtons }
    purchase = { ...purchase, checkListHeader }
    yield put(fetchPurchaseDossierSuccess(purchase))
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.SALES_DOSSIER.ERROR_GET_SALES_DOSSIER', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('purchase_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * fetchPurchaseComponentsUpdated (purchaseDossier, auth) {
  const result = {}
  if (purchaseDossier.entityComponent && purchaseDossier.entityComponent.updated) {
    const entityComponent = yield call(getDossierEntityService, purchaseDossier.dossierId, auth.token)
    entityComponent.updated = true
    result.entityComponent = entityComponent
  }
  if (purchaseDossier.vehicleComponent && purchaseDossier.vehicleComponent.updated) {
    const vehicleComponent = yield call(getPurchaseWithVehicleById, purchaseDossier.dossierId, auth.token)
    vehicleComponent.updated = true
    result.vehicleComponent = vehicleComponent
  }
  if (purchaseDossier.dossierContactComponent && purchaseDossier.dossierContactComponent.updated) {
    const dossierContactComponent = yield call(getDossierContact, purchaseDossier.dossierId, auth.token)
    dossierContactComponent.updated = true
    result.dossierContactComponent = dossierContactComponent
  }
  if (purchaseDossier.dossierCompleteOtherDataComponent && purchaseDossier.dossierCompleteOtherDataComponent.updated) {
    const dossierCompleteOtherDataComponent = yield call(getDossierOtherData, purchaseDossier.dossierId, auth.token)
    dossierCompleteOtherDataComponent.updated = true
    result.dossierCompleteOtherDataComponent = dossierCompleteOtherDataComponent
  }
  return result
}

export function * fetchPurchaseClient ({ dossierId, resolve, reject, pristine }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const purchaseDossier = yield select(state => state.purchaseDossier)
    const entityComponent = yield call(getDossierEntityService, dossierId, auth.token)
    const dossierContactComponent = yield call(getDossierContact, dossierId, auth.token)
    entityComponent.updated = true
    dossierContactComponent.updated = true
    if (entityComponent && entityComponent.entitySubTypeId) {
      const entitySubTypeCombo = yield call(getEntitySubType, auth.token, entityComponent.entityTypeId)
      const entityFilterSubTypeCombo = yield call(getEntityFilterSubType, auth.token, entityComponent.entityTypeId, entityComponent.entitySubTypeId, purchaseDossier.organizedUnitId)
      yield put(fetchCombosSuccess({ entitySubTypeCombo, entityFilterSubTypeCombo }))
    }

    let entityFromParentDossier = ((purchaseDossier.parentEntityId && entityComponent.entityId && purchaseDossier.parentEntityId === entityComponent.entityId) || purchaseDossier.entityFromParentDossier)
    yield put(change('purchase_dossier', 'entityComponent', entityComponent.entityId ? entityComponent : {}))
    yield put(change('purchase_dossier', 'dossierContactComponent', dossierContactComponent))
    yield put(change('purchase_dossier', 'entityFromParentDossier', entityFromParentDossier))
    yield put(fetchPurchaseDossierSuccess({ entityComponent, dossierContactComponent, entityFromParentDossier }))
    if (pristine === true || pristine === undefined) {
      yield put(initialize('purchase_dossier', { ...purchaseDossier, entityComponent: entityComponent, dossierContactComponent, entityFromParentDossier: entityFromParentDossier }))
    }
    if (resolve) yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.SALES_DOSSIER.ERROR_GET_SALES_DOSSIER', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('purchase_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchPurchaseClient () {
  yield takeEvery(dossierActionsTypes.FETCH_PURCHASE_DOSSIER_CLIENT, fetchPurchaseClient)
}

export function * fetchPurchaseVehicle ({ dossierId, resolve, reject, pristine }) {
  try {
    let carlineCombo = []; let catalogueCombo = []; let colorCombo = []; let tapestryCombo = []
    yield put(showLoader())
    const auth = yield select(getAuth)
    const purchaseDossier = yield select(state => state.purchaseDossier)
    let vehicleComponent = yield call(getPurchaseWithVehicleById, dossierId, auth.token)
    if (vehicleComponent === null) vehicleComponent = {}
    vehicleComponent.updated = true
    yield put(change('purchase_dossier', 'vehicleComponent', vehicleComponent.vehicleId ? vehicleComponent : {}))
    yield put(fetchPurchaseDossierSuccess({ vehicleComponent }))
    if (pristine === true || pristine === undefined) {
      yield put(initialize('purchase_dossier', { ...purchaseDossier, vehicleComponent: vehicleComponent }))
    }
    if (vehicleComponent.brandId) {
      carlineCombo = yield call(getCarlineCombo, auth.token, vehicleComponent.brandId)
    }
    if (vehicleComponent.carlineId) {
      catalogueCombo = yield call(getCatalogueCombo, auth.token, vehicleComponent.carlineId)
    }
    if (vehicleComponent.modelId && vehicleComponent.catalogueId) {
      colorCombo = yield call(getColorCombo, auth.token, vehicleComponent.catalogueId, vehicleComponent.modelId)
      tapestryCombo = yield call(getTapestryCombo, auth.token, vehicleComponent.catalogueId, vehicleComponent.modelId)
      var catalogueModel = yield call(getCatalogueModel, auth.token, vehicleComponent.catalogueId, vehicleComponent.modelId)
      vehicleComponent.model = catalogueModel.description || ''
      vehicleComponent.catalogueModelId = catalogueModel.catalogueModelId || ''
    }

    yield put(fetchCombosSuccess({ carlineCombo, catalogueCombo, colorCombo, tapestryCombo }))

    if (resolve) yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.SALES_DOSSIER.ERROR_GET_SALES_DOSSIER', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('purchase_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchPurchaseVehicle () {
  yield takeEvery(dossierActionsTypes.FETCH_PURCHASE_DOSSIER_VEHICLE, fetchPurchaseVehicle)
}
export function * fetchPurchaseOtherData ({ dossierId, resolve, reject, pristine }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const purchaseDossier = yield select(state => state.purchaseDossier)
    const dossierCompleteOtherDataComponent = yield call(getDossierOtherData, dossierId, auth.token)
    dossierCompleteOtherDataComponent.updated = true
    yield put(change('purchase_dossier', 'dossierCompleteOtherDataComponent', dossierCompleteOtherDataComponent))
    yield put(fetchPurchaseDossierSuccess({ dossierCompleteOtherDataComponent }))
    if (pristine === true) {
      yield put(initialize('purchase_dossier', { ...purchaseDossier, dossierCompleteOtherDataComponent: dossierCompleteOtherDataComponent }))
    }
    if (resolve) yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.SALES_DOSSIER.ERROR_GET_OTHER_DATA', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('purchase_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}
export function * watchfetchPurchaseOtherData () {
  yield takeEvery(dossierActionsTypes.FETCH_PURCHASE_DOSSIER_OTHER_DATA, fetchPurchaseOtherData)
}

export function * fetchSPurchaseVehicleHistorical ({ dossierId, resolve, reject }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const vehicleComponent = yield call(getPurchaseHistoricalWithVehicleById, dossierId, auth.token)
    yield put(change('purchase_dossier', 'vehicleComponent', vehicleComponent.vehicleId ? vehicleComponent : {}))
    yield put(fetchPurchaseDossierSuccess({ vehicleComponent }))
    let carlineCombo = []; let catalogueCombo = []; let colorCombo = []; let tapestryCombo = []

    if (vehicleComponent.brandId) {
      carlineCombo = yield call(getCarlineCombo, auth.token, vehicleComponent.brandId)
    }
    if (vehicleComponent.carlineId) {
      catalogueCombo = yield call(getCatalogueCombo, auth.token, vehicleComponent.carlineId)
    }
    if (vehicleComponent.modelId && vehicleComponent.catalogueId) {
      colorCombo = yield call(getColorCombo, auth.token, vehicleComponent.catalogueId, vehicleComponent.modelId)
      tapestryCombo = yield call(getTapestryCombo, auth.token, vehicleComponent.catalogueId, vehicleComponent.modelId)
      var catalogueModel = yield call(getCatalogueModel, auth.token, vehicleComponent.catalogueId, vehicleComponent.modelId)
      vehicleComponent.model = catalogueModel.description || ''
      vehicleComponent.catalogueModelId = catalogueModel.catalogueModelId || ''
    }

    yield put(fetchCombosSuccess({ carlineCombo, catalogueCombo, colorCombo, tapestryCombo }))

    if (resolve) yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.SALES_DOSSIER.ERROR_GET_SALES_DOSSIER', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('purchase_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchPurchaseVehicleHistorical () {
  yield takeEvery(dossierActionsTypes.FETCH_PURCHASE_DOSSIER_VEHICLE_HISTORICAL, fetchSPurchaseVehicleHistorical)
}

export function * fetchPurchaseClientHistorical ({ dossierId, resolve, reject }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const entityComponent = yield call(getDossierHistoricalEntity, dossierId, auth.token)
    const purchaseDossier = yield select(state => state.purchaseDossier)
    if (entityComponent.entityTypeId === entityTypesId.recurrentEntity) {
      yield call(fetchEntitySubTypeCombo, { entityTypeId: entityComponent.entityTypeId })
      yield call(fetchEntityFilterSubTypeCombo, { entityTypeId: entityComponent.entityTypeId, entitySubTypeId: entityComponent.entitySubTypeId, organizedUnitId: purchaseDossier.organizedUnitId })
    }
    const dossierContactComponent = yield call(getDossierContactHistorical, dossierId, auth.token)

    if (entityComponent && entityComponent.entitySubTypeId) {
      const entitySubTypeCombo = yield call(getEntitySubType, auth.token, entityComponent.entityTypeId)
      const entityFilterSubTypeCombo = yield call(getEntityFilterSubType, auth.token, entityComponent.entityTypeId, entityComponent.entitySubTypeId, purchaseDossier.organizedUnitId)
      yield put(fetchCombosSuccess({ entitySubTypeCombo, entityFilterSubTypeCombo }))
    }

    yield put(change('purchase_dossier', 'entityComponent', entityComponent.entityId ? entityComponent : {}))
    yield put(change('purchase_dossier', 'dossierContactComponent', dossierContactComponent))
    yield put(fetchPurchaseDossierSuccess({ entityComponent, dossierContactComponent }))
    if (resolve) yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.SALES_DOSSIER.ERROR_GET_SALES_DOSSIER', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('purchase_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchPurchaseClientHistorical () {
  yield takeEvery(dossierActionsTypes.FETCH_PURCHASE_DOSSIER_CLIENT_HISTORICAL, fetchPurchaseClientHistorical)
}
export function * fetchPurchaseOtherDataHistorical ({ dossierId, resolve, reject, pristine }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const purchaseDossier = yield select(state => state.purchaseDossier)
    const dossierCompleteOtherDataComponent = yield call(getDossierOtherDataHistorical, dossierId, auth.token)
    dossierCompleteOtherDataComponent.updated = true
    yield put(change('purchase_dossier', 'dossierCompleteOtherDataComponent', dossierCompleteOtherDataComponent))
    yield put(fetchPurchaseDossierSuccess({ dossierCompleteOtherDataComponent }))
    if (pristine === true) {
      yield put(initialize('purchase_dossier', { ...purchaseDossier, dossierCompleteOtherDataComponent }))
    }
    if (resolve) yield call(resolve)
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.SALES_DOSSIER.ERROR_GET_OTHER_DATA', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('purchase_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}
export function * watchfetchPurchaseOtherDataHistorical () {
  yield takeEvery(dossierActionsTypes.FETCH_PURCHASE_DOSSIER_OTHERDATA_HISTORICAL, fetchPurchaseOtherDataHistorical)
}

export function * fetchVehiclePurchaseComponent ({ vinChassis, licensePlate, organizedUnitId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const vehicle = yield call(getVehicleByPlateOrChassis, auth.token, null, vinChassis, licensePlate, null, organizedUnitId)
    vehicle.updated = true
    if (vehicle.vehicleId) {
      yield put(change('purchase_dossier', 'vehicleComponent', vehicle))
      let vehicleComponent = vehicle
      yield put(fetchPurchaseDossierSuccess({ vehicleComponent }))
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.VEHICLE_COMPONENT.UNKNOWN_ERROR', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('purchase_dossier'))
      yield put(reset('paperwork_dossier'))
      yield put(reset('campaign_dossier'))
      yield put(reset('registrationDeliveryDossier'))
      yield put(reset('service_dossier'))
      yield put(reset('stock_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchfetchVehiclePurchaseComponent () {
  yield takeEvery(dossierActionsTypes.FETCH_VEH_PURCH_CHASIS_LICENSE, fetchVehiclePurchaseComponent)
}

export function * fetchPurchaseCatalogueModel ({ catalogueId, modelId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const catalogueModel = yield call(getCatalogueModel, auth.token, catalogueId, modelId)
    yield put(change('purchase_dossier', 'vehicleComponent.model', catalogueModel?.description || ''))
    yield put(change('purchase_dossier', 'vehicleComponent.catalogueModelId', catalogueModel?.catalogueModelId))
    yield put(fetchPurchaseCatalogueModelSuccess({ catalogueModelId: catalogueModel?.catalogueModelId, catalogueModel: catalogueModel?.description || '' }))
    if (!catalogueModel?.description) {
      throw new Error()
    }
  } catch (error) {
    const customHandler = function * () {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.VEHICLE_COMPONENT.CATALOGUE_MODEL_NOT_FOUND'))
    }
    yield call(handleError, { error, customHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchPurchaseFetchCatalogueModel () {
  yield takeEvery(dossierActionsTypes.FETCH_VEHICLE_CATALOGUE_MODEL, fetchPurchaseCatalogueModel)
}
