import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import {
  closeSignatureConfigurationMasterModal, submitSignatureConfig, openEditSignatureEntityModal,
  deleteSignatureEntity
}
  from '../../../actions/masters/signatureConfiguration/signatureConfiguration'
import SignatureConfigMasterModal from '../../../components/masters/signatureConfig/SignatureConfigMasterModal'
import { fetchDocumentTypeUseCombo } from '../../../actions/combos/combos'

export function mapStateToProps (state) {
  const signatureConfigModal = state.signatureConfig.signatureConfigModal
  return {
    showModal: signatureConfigModal.showModal,
    signatureConfig: signatureConfigModal.signatureConfig,
    combos: {
      documentTypeUseCombo: state.combos.documentTypeUseCombo
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      submitSignatureConfig,
      closeSignatureConfigurationMasterModal,
      openEditSignatureEntityModal,
      fetchDocumentTypeUseCombo,
      deleteSignatureEntity
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(SignatureConfigMasterModal))
