import React, { PureComponent } from 'react'
import { reduxForm } from 'redux-form'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Element } from 'react-scroll'
import { dossierTypes as dossierTypesConstant } from '../../../../constants/dossier/common/dossierTypes'
import { compareObjects } from '../../../../util/utils'
import { getComponentsPermisions } from '../../../../util/permisionFunctions'
import DossierHeaderPage from '../../../containers/dossiers/commons/dossierHeaderPage'
import ButtonsHeader from '../../../../_v2/components/dossiers/common/ButtonsHeader'
import ButtonsSidebar from '../../dossiers/common/ButtonsSidebar'
import Client from '../../dossiers/common/Client'
import Vehicle from '../../dossiers/common/Vehicle'
import CampaignComponent from '../../dossiers/campaign/CampaignComponent'
import RejectCommentModal from '../../../../components/dossiers/common/RejectCommentModal'
import { buttonsHeader } from '../../../../constants/dossier/common/buttonsHeader'
import { buttonsSidebar } from '../../../../constants/dossier/common/buttonsSidebar'
import { permissions } from '../../../../constants/backendIds'
import DocumentaryManagementPage from '../../../../_v2/containers/dossiers/commons/DocumentaryManagementPage'
import AuditInfo from '../../dossiers/common/audit/AuditInfo'
import TrackingContainer from '../../../../containers/tracking/trackingContainer'
import { TrackedPages } from '../../../../constants/tracking/TrackedPages'
import DossierSummary from '../../dossiers/common/DossierSummary'
import RelatedDossiers from '../../dossiers/common/RelatedDossiers'
import CommentComponent from '../../dossiers/common/comment/CommentComponent'

class CampaignHistorical extends PureComponent {
  constructor () {
    super()
    this.state = {
      modalOpen: true,
      showRejectCommentModal: false,
      campaignOpen: true,
      isVehicleLoaded: false,
      isClientLoaded: false,
      loaded: false,
      activeTabConfiguration: null,
      headerTabConfiguration: null,
      componentsConfiguration: {
        entity: null,
        vehicle: null,
        bcampaign: null,
        downloadDocumentsButton: null
      }
    }
    this.goBack = this.goBack.bind(this)
  }

  goBack () {
    this.props.actions.setActiveTab(buttonsHeader.datos_expediente)
    this.props.history.goBack()
  }

  toogleHidedossierBodyLeft () {
    this.setState({ dossierBodyLeftClose: !this.state.dossierBodyLeftClose })
  }

  toggleRejectCommentModal (showRejectCommentModal) {
    this.setState({ showRejectCommentModal })
  }

  submitCampaignDossier (values) {
    this.props.actions.saveCampaignDossier(values)
  }

  fetchCampaignDossierPromise (dossierId) {
    return new Promise((resolve, reject) => {
      return this.props.actions.fetchCampaignDossier(dossierId, resolve, reject, true)
    })
  }

  getRelatedDossierColumnIcon (columnCode) {
    if ('+' + columnCode === this.props.relatedDossiersOrderBy) {
      return 'ico-sort-selected-asc'
    } else if ('-' + columnCode === this.props.relatedDossiersOrderBy) {
      return 'ico-sort-selected-desc'
    } else {
      return 'ico-sort'
    }
  }

  initializePermisions (props) {
    const componentKeys = Object.keys(this.state.componentsConfiguration)
    const newState = getComponentsPermisions(props.dossier.viewConfiguration.tabsConfiguration, props.activeTab, componentKeys)
    this.setState({ ...this.state, ...newState })
  }

  UNSAFE_componentWillMount () {
    this.props.actions.restartCampaignState()
    this.fetchCampaignDossierPromise(this.props.match.params.dossierId).then(() => {
      const form = {
        ...this.props.dossier,
        entityComponent: this.props.dossier.entityComponent.entityId ? this.props.dossier.entityComponent : undefined
      }
      if (!this.props.dossier.entityComponent.entityId) delete form.entityComponent
      if (!this.props.dossier.vehicleComponent.vehicleId) delete form.vehicleComponent
      this.props.initialize(form)
      this.initializePermisions(this.props)
      this.props.actions.fetchCustomFields(this.props.dossier.dossierSubTypeId)
      this.props.actions.registerLastAccess(this.props.match.params.dossierId, true)
      this.setState({ loaded: true })
      this.props.trackVisitPage({
        documentTitle: TrackedPages.CAMPAIGN_HISTORICAL,
        organizedUnitId: this.props.dossier.organizedUnitId,
        href: window.location.href
      })
    })
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if ((this.props.showManagementModal && !nextProps.showManagementModal) || this.props.match.params.dossierId !== nextProps.match.params.dossierId) {
      if (this.props.match.params.dossierId !== nextProps.match.params.dossierId) {
        this.props.actions.restartCampaignState()
        this.props.actions.setScrollTo(buttonsSidebar.bcampaign)
        this.props.actions.setActiveSection(buttonsSidebar.bcampaign)
        this.props.actions.setActiveTab(buttonsHeader.datos_expediente)
        this.props.actions.closeAddOrEditDocumentModal()
      }
      this.fetchCampaignDossierPromise(nextProps.match.params.dossierId).then(() => {
        const form = { ...this.props.dossier }
        if (!this.props.dossier.entityComponent.entityId) delete form.entityComponent
        if (!this.props.dossier.vehicleComponent.vehicleId) delete form.vehicleComponent
        this.props.initialize(form)
        this.initializePermisions(this.props)
        this.setState({ loaded: true })
      })
    }
    if (nextProps.dossier.dossierId && !compareObjects(this.props.dossier.viewConfiguration.tabsConfiguration || {}, nextProps.dossier.viewConfiguration.tabsConfiguration, true)) {
      this.initializePermisions(nextProps)
    }
    if (nextProps.dossier.dossierId && nextProps.activeTab !== this.props.activeTab) {
      this.initializePermisions(nextProps)
    }
  }

  componentDidUpdate () {
    if (this.props.dossier.dossierId && this.props.statusHeaderHeight && this.props.activeSection) {
      switch (this.props.activeSection) {
        case buttonsSidebar.bcampaign:
          this.setState({ campaignOpen: true })
          break
      }
    }

    let customFields = {}
    const firstCustomFieldsLoad = this.props.dossier.customFieldsByComponent?.length > 0
    if (firstCustomFieldsLoad) {
      if (this.props.dossier.customFieldsValues) {
        this.props.dossier.customFieldsValues.map(x => {
          customFields[x.key] = x.value
        })
      }
      const form = { ...this.props.dossier, customFields: customFields }
      if (!this.props.dossier.entityComponent.entityId) delete form.entityComponent
      if (!this.props.dossier.vehicleComponent.vehicleId) delete form.vehicleComponent
      this.props.initialize(form)
    }
  }

  componentDidMount () {
    this.props.actions.fetchCommercialSocietyTypeCombo()
  }

  componentWillUnmount () {
    this.props.actions.setActiveSection(buttonsSidebar.bcampaign)
    this.props.actions.setScrollTo(null)
    this.props.actions.resetButtonsHeader()
    this.props.actions.closeAddOrEditDocumentModal()
    this.props.actions.restartCampaignState()
  }

  handleToUpdateComponent (component) {
    if (component.isVehicleLoaded === false || buttonsSidebar.vehicle === component) this.setState({ isVehicleLoaded: true })
    if (component.isClientLoaded === false || buttonsSidebar.entity === component) this.setState({ isClientLoaded: true })
    this.props.actions.setScrollTo(component)
  }

  togglePanel () {
    if (this.state.campaignOpen) {
      this.props.actions.setActiveSection(false)
    }
    this.setState({ campaignOpen: !this.state.campaignOpen })
  }

  render () {
    const {
      t, combos, dossier, change, pristine, vehicleProps, activeTab, buttonsHeaderHeight, activeSection,
      statusHeaderHeight, windowWidth, documentExport,
      dossier: {
        dossierId, checkListHeader, comments, dossierParentId, header,
        viewConfiguration, viewConfiguration: { buttonsPermission = [] },
        vehicleComponent: { vehicleId }, entityChangeable, dossierRelatedDossiers, auditInfo, dossierSubTypeId
      },
      actions: {
        updateComment, createComment, deleteComment, fetchClientByDni, searchVehicle, setSearchDealerNumber, fetchClientByDniEntityChild,
        setSearchVinChassis, setSearchLicensePlate, setSimpleInputTextValidation,
        fetchEntitySubTypeCombo, fetchEntityFilterSubTypeCombo, campaignReject, fetchAuditDetails, fetchAuditDetailsModal, fetchDocumentsGroupedAuditDetails, fetchDocumentsGroupedAuditDetailsModal,
        openDocumentModal, openDocumentEditionModal, fetchClientFromDocument, resetSearchFields,
        setActiveTab, setButtonsHeaderHeight, resetButtonsHeader, setActiveSection, /* navigateTo, */ fetchChecklistCampaign, setScrollerOptions,
        setEntityTypeId, setEntitySubTypeId, setEntityId, setEntityChildSubTypeId, setEntityChildTypeId, fetchCampaignVehicleHistorical,
        fetchCampaignClientHistorical, refreshAuditInfo, fecthFamily, saveSelectedDocuments, clearClientFields, fetchClientByRfc,
        fetchCommercialSocietyTypeCombo, fetchEntityStateTypeCombo, fetchEntityCityTypeCombo, fetchEntityTownTypeCombo,
        fetchEntityColonyTypeCombo, fetchEntityCPTypeCombo, fetchEntityCountryTypeCombo, openCommonModal, fetchActivityBpro,
        fetchClientDataAddress, fetchFilteredRelatedDossier, markCommentAsRead
      }
    } = this.props
    if (!dossierId) return null

    const tKey = 'DOSSIER_COMPONENTS.'
    const saveCampaignPermission = buttonsPermission.includes('campaign_save')
    const activeStageFields = checkListHeader.find(stage => stage.isActive) && checkListHeader.find(stage => stage.isActive).fields
    const panelsPaddingTop = statusHeaderHeight + buttonsHeaderHeight - 82 // 82px es el paddingTop definido en el layout.
    return (
      <div className="dossierGrid">
        <div className='modalsContainer'>
          <RejectCommentModal
            t={t}
            showModal={this.state.showRejectCommentModal}
            actions={{
              rejectAction: (comment, resolve, reject) => campaignReject(comment, resolve, reject),
              closeModal: () => this.toggleRejectCommentModal(false)
            }}
          />
        </div>
        <div class="dossierGridPart-header">
          <DossierHeaderPage
            headerData={header}
            stages={checkListHeader}
            dossierType={dossierTypesConstant.purchase}
            headerTabConfiguration={this.state.headerTabConfiguration}
            goBack={this.goBack}
            dossierId={dossier.dossierId}
            isHistorical={true}
            entityComponent = {dossier.entityComponent}
            vehicleComponent = {dossier.vehicleComponent}
          />
        </div>

        <div class="dossierGridPart-body">

          <div className={'dossierBody-left ' + (this.state.dossierBodyLeftClose ? 'close' : '') }>

            <DossierSummary
              t = {t}
              dossier={dossier}
              header={header}
              dossierType={dossierTypesConstant.campaign}
              dossierSubType={dossierSubTypeId}
              brandCombo = {combos.brandCombo}
              entityTypeCombo = {combos.entityTypeCombo}
              dossierRelatedDossiers = {dossierRelatedDossiers}
            >
            </DossierSummary>
          </div>
          <div className='dossierBody-center'>
            <OverlayTrigger placement="right" overlay={<Tooltip id="editProductTooltip">{`${t((this.state.dossierBodyLeftClose ? 'GLOBAL.TABLES.SHOW_INNER_MENU' : 'GLOBAL.TABLES.HIDE_INNER_MENU'))}`}</Tooltip>}>
              <a onClick={() => this.toogleHidedossierBodyLeft()} className={'closeDossierInfo ' + (this.state.dossierBodyLeftClose ? '' : 'closeInnerMenu')}><i className={this.state.dossierBodyLeftClose ? 'iDocIcon-Open-inner-menu' : 'iDocIcon-Close-inner-menu'}></i></a>
            </OverlayTrigger>
          </div>

          <div className='dossierBody-right'>
            <div class="dossierGridPart-sectionsMenu">
              <ButtonsHeader
                t={t}
                pristine={pristine}
                actions={{
                  openDocumentModal: () => openDocumentModal(dossierId, dossierTypesConstant.campaign, pristine, 1, true),
                  setActiveTab,
                  setButtonsHeaderHeight,
                  resetButtonsHeader,
                  setScrollerOptions
                }}
                dossierId={this.props.match.params.dossierId}
                viewConfiguration={viewConfiguration}
                dossierType={dossierTypesConstant.campaign}
                activeTab={activeTab}
                marginTop={statusHeaderHeight}
                windowWidth={windowWidth}
                historical={true}
              />
            </div>
            <div class="dossierGridPart-ComponentSection">
              <form autoComplete="off">
                {
                  activeTab === buttonsHeader.datos_expediente && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden && (
                    <div className="_nd dossierGridPart-ComponentSection-Inner" >
                      <div className="ComponentSection-InnerMennu">
                        <ButtonsSidebar
                          t={t}
                          dossierId={dossierId}
                          historical={true}
                          isClientLoaded={this.state.isClientLoaded}
                          isVehicleLoaded={this.state.isVehicleLoaded}
                          handleToUpdateComponent={this.handleToUpdateComponent.bind(this)}
                          pristine={pristine}
                          actions={{ setActiveSection, fetchCampaignVehicleHistorical, fetchCampaignClientHistorical }}
                          dossierType={dossierTypesConstant.campaign}
                          dossierSubTypes={dossierSubTypeId}
                          activeStageFields={activeStageFields}
                          marginTop={statusHeaderHeight}
                          buttonsHeaderHeight={buttonsHeaderHeight}
                          tabConfiguration={this.state.activeTabConfiguration}
                        />
                      </div>
                      <div className="ComponentSection-InnerData" id="dossierData" >

                        {this.state.componentsConfiguration.bcampaign && this.state.componentsConfiguration.bcampaign.permission > permissions.hidden && (
                          <Element name={buttonsSidebar.bcampaign}>
                            <CampaignComponent
                              t={t}
                              dossier={dossier}
                              campaignOpen={this.state.campaignOpen}
                              combos={combos}
                              actions={{
                                change,
                                fetchChecklistCampaign,
                                togglePanel: () => this.togglePanel(),
                                setActiveSection
                              }}
                              componentConfiguration={this.state.componentsConfiguration.bcampaign}
                              totalAmountWithOptionals={this.props.dossier.totalAmountWithOptionals}
                              loaded={this.state.loaded}
                              customFields={this.props.dossier.customFieldsByComponent.find(x => x.componentCode === buttonsSidebar.bcampaign)?.customFields}
                              readOnlyFields={true}
                              isHistorical={true}
                            />
                          </Element>
                        )}
                        {this.state.componentsConfiguration.entity && this.state.componentsConfiguration.entity.permission > permissions.hidden && (
                          <Element name={buttonsSidebar.entity}>
                            <Client
                              t={t}
                              dossier={dossier}
                              combos={combos}
                              entityChangeable={entityChangeable}
                              dossierType={dossierTypesConstant.campaign}
                              handleToUpdateComponent={this.handleToUpdateComponent.bind(this)}
                              isClientLoaded={this.state.isClientLoaded}
                              pristine={pristine}
                              actions={{
                                change,
                                fetchClientByDni,
                                fetchClientByDniEntityChild,
                                fetchEntitySubTypeCombo,
                                fetchEntityFilterSubTypeCombo,
                                openDocumentEditionModal,
                                fetchClientFromDocument,
                                setEntityTypeId,
                                setEntitySubTypeId,
                                setEntityId,
                                setEntityChildSubTypeId,
                                setEntityChildTypeId,
                                fetchCampaignClientHistorical,
                                setActiveSection,
                                clearClientFields,
                                fetchClientByRfc,
                                fetchCommercialSocietyTypeCombo,
                                fetchEntityCountryTypeCombo,
                                fetchEntityStateTypeCombo,
                                fetchEntityCityTypeCombo,
                                fetchEntityTownTypeCombo,
                                fetchEntityColonyTypeCombo,
                                fetchEntityCPTypeCombo,
                                openCommonModal,
                                fetchActivityBpro,
                                fetchClientDataAddress
                              }}
                              readOnlyFields={!saveCampaignPermission}
                              open={activeSection === buttonsSidebar.entity}
                              componentConfiguration={this.state.componentsConfiguration.entity}
                              customFields={this.props.dossier.customFieldsByComponent.find(x => x.componentCode === buttonsSidebar.entity)?.customFields}
                              isHistorical={true}
                            />
                          </Element>
                        )}
                        {this.state.componentsConfiguration.vehicle && this.state.componentsConfiguration.vehicle.permission > permissions.hidden && (
                          <Element name={buttonsSidebar.vehicle} style={{ height: '1025px' }}>
                            <Vehicle
                              parentDossierType={{
                                isPaperwork: false,
                                isSales: false,
                                isPurchase: false,
                                isCampaign: true,
                                isRegistrationDelivery: false
                              }}
                              t={t}
                              dossierId={dossierId}
                              searchedVehicleProps={vehicleProps}
                              dossierParentId={dossierParentId}
                              dossierWithVehicle={!!vehicleId}
                              combos={combos}
                              actions={{
                                searchVehicle,
                                setSearchDealerNumber,
                                setSearchVinChassis,
                                setSearchLicensePlate,
                                resetSearchFields,
                                setSimpleInputTextValidation,
                                fetchCampaignVehicleHistorical,
                                fecthFamily,
                                setActiveSection
                              }}
                              dossierType={dossierTypesConstant.campaign}
                              handleToUpdateComponent={this.handleToUpdateComponent.bind(this)}
                              isVehicleLoaded={this.state.isVehicleLoaded}
                              readOnlyFields={!saveCampaignPermission}
                              open={activeSection === buttonsSidebar.vehicle}
                              componentConfiguration={this.state.componentsConfiguration.vehicle}
                              organizedUnitId={dossier.organizedUnitId}
                              customFields={this.props.dossier.customFieldsByComponent.find(x => x.componentCode === buttonsSidebar.vehicle)?.customFields}
                              isHistorical={true}
                            />
                          </Element>
                        )}

                      </div>
                    </div>
                  )}
                {
                  activeTab === buttonsHeader.gestor_documental && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden && (
                    <div className="dossierGridPart-ComponentSection-Inner sales-panel gestorDocumental">
                      <div className="ComponentSection-InnerData">
                        <DocumentaryManagementPage
                          dossier={dossier}
                          dossierType={dossierTypesConstant.campaign}
                          saveSelectedDocuments={saveSelectedDocuments}
                          documentExport={documentExport}
                          combos={this.props.scombos}
                          isHistorical={true}
                          tabConfiguration={this.state.activeTabConfiguration}
                        />
                      </div>
                    </div>
                  )}
                {
                  activeTab === buttonsHeader.expedientes_relacionados && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden && (
                    <div className="dossierGridPart-ComponentSection-Inner related-dossiers-table">
                      <div className="ComponentSection-InnerData">
                        <RelatedDossiers
                          t={t}
                          relatedDossiersOrderBy = {this.props.relatedDossiersOrderBy}
                          panelsPaddingTop = {panelsPaddingTop}
                          history = {this.props.history}
                          dossierRelatedDossiers = {dossierRelatedDossiers}
                          dossierId= {dossierId}
                          isHistorical= {true}
                          actions={{ fetchFilteredRelatedDossier }}
                        />
                      </div>
                    </div>
                  )}
                {
                  activeTab === buttonsHeader.actividad_expediente && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden && (
                    <div className="dossierGridPart-ComponentSection-Inner auditInfo">
                      <div sm={12} className="ComponentSection-InnerData">
                        <AuditInfo
                          auditInfo={auditInfo}
                          dossierType={dossierTypesConstant.campaign}
                          actions={{ refreshAuditInfo, fetchAuditDetails, fetchAuditDetailsModal, fetchDocumentsGroupedAuditDetails, fetchDocumentsGroupedAuditDetailsModal }}
                          dossier={dossier}
                          historical={true}
                          t={t}
                        />
                      </div>
                    </div>
                  )
                }
                {
                  activeTab === buttonsHeader.observaciones && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden && (
                    <div className="dossierGridPart-ComponentSection-Inner commentComponent">
                      <div className="ComponentSection-InnerData">
                        <CommentComponent
                          comments={comments}
                          actions={{ updateComment, createComment, deleteComment, markCommentAsRead }}
                          readOnlyFields={!saveCampaignPermission}
                          dossierType="salesDossier"
                          t={t}
                          tKey={tKey}
                          combos={{ allReasonCombo: this.props.combos.allReasonCombo }}
                          openComponent={activeTab === buttonsHeader.observaciones}
                          dossierId={this.props.dossier.dossierId}
                          historical={true}
                        />

                      </div>
                    </div>

                  )}
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

var decoratedComponent = TrackingContainer(CampaignHistorical, false)

export default reduxForm({
  form: 'campaign_dossier'
})(decoratedComponent)
