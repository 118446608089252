export default {
  FETCH_TEMPLATE_LIST: 'FETCH_TEMPLATE_LIST',
  FETCH_TEMPLATE_LIST_SUCCESS: 'FETCH_TEMPLATE_LIST_SUCCESS',
  SET_ACTIVE_TEMPLATE: 'SET_ACTIVE_TEMPLATE',
  DUPLICATE_TEMPLATE: 'DUPLICATE_TEMPLATE',
  DELETE_TEMPLATE: 'DELETE_TEMPLATE',
  OPEN_ADD_TEMPLATE_MODAL: 'OPEN_ADD_TEMPLATE_MODAL',
  OPEN_EDIT_TEMPLATE_MODAL: 'OPEN_EDIT_TEMPLATE_MODAL',
  OPEN_EDIT_TEMPLATE_MODAL_SUCCESS: 'OPEN_EDIT_TEMPLATE_MODAL_SUCCESS',
  CLOSE_EDIT_TEMPLATE_MODAL: 'CLOSE_EDIT_TEMPLATE_MODAL',
  CLOSE_EDIT_TEMPLATE_MODAL_SUCCESS: 'CLOSE_EDIT_TEMPLATE_MODAL_SUCCESS',
  SUBMIT_TEMPLATE: 'SUBMIT_TEMPLATE',
  CREATE_TEMPLATE_FIELD: 'CREATE_TEMPLATE_FIELD',
  UPDATE_TEMPLATE_FIELD: 'UPDATE_TEMPLATE_FIELD',
  DELETE_TEMPLATE_FIELD: 'DELETE_TEMPLATE_FIELD',
  RESET_TEMPLATE: 'RESET_TEMPLATE',
  FETCH_TEMPLATE_FILE: 'FETCH_TEMPLATE_FILE',
  DONWLOAD_TEMPLATE_FILE: 'DONWLOAD_TEMPLATE_FILE'
}
