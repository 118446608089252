import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import CodeCommissionMasters from '../../../components/masters/codeCommission/CodeCommissionMasters'
import { translate } from 'react-polyglot'
import { fetchcodeCommissionMasters, openCodeCommissionModal, deleteCodeComission } from '../../../actions/masters/masters'
import { fetchOrganizedUnitsTree } from '../../../actions/combos/combos'

export function mapStateToProps (state) {
  return {
    combos: {
      UOTreeCombo: state.combos.UOTreeCombo
    },
    codeCommissionMastersList: state.entityMasters.codeCommissionMastersList,
    codeCommissionFields: state.entityMasters.codeCommissionFields
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchcodeCommissionMasters,
      openCodeCommissionModal,
      fetchOrganizedUnitsTree,
      deleteCodeComission
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(CodeCommissionMasters))
