import mastersActionTypes from '../../../constants/actions/masters/masters'

function initialState () {
  return {
    dossierStageList: [],
    dossierStageCount: 0,
    pagesCount: 0,
    filter: {
      page: 1,
      orderBy: '',
      pageSize: 10,
      dossierSubTypeId: null
    },
    dossierStageModal: {
      dossierStage: null,
      showModal: false,
      languageList: null,
      dossierStageFields: null,
      dossierStageId: null
    }
  }
}

function fetchDossierStageListSuccess (state, { dossierStageList, filter }) {
  return {
    ...state,
    dossierStageList: dossierStageList.dossierStageList,
    dossierStageCount: dossierStageList.dossierStageCount,
    pagesCount: dossierStageList.pagesCount,
    filter
  }
}

function openDossierStageModalSuccess (state, { languageList, dossierStageFields, dossierStageId }) {
  const initial = initialState()
  return {
    ...state,
    dossierStageModal: {
      ...initial.dossierStageModal,
      showModal: true,
      languageList,
      dossierStageFields,
      dossierStageId
    }
  }
}

function closeEditModalSuccess (state) {
  return {
    ...state,
    dossierStageModal: {
      ...initialState().dossierStageModal
    }
  }
}

function initializeFilterState (state) {
  const initial = initialState()
  return {
    ...state,
    filter: initial.filter
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case mastersActionTypes.FETCH_DOSSIER_STAGES_SUCCESS:
      return fetchDossierStageListSuccess(state, action)
    case mastersActionTypes.OPEN_EDIT_DOSSIER_STAGES_MODAL_SUCCESS:
      return openDossierStageModalSuccess(state, action)
    case mastersActionTypes.CLOSE_EDIT_DOSSIER_STAGES_MODAL:
      return closeEditModalSuccess(state, action)
    case mastersActionTypes.INITIALIZE_DOSSIER_STAGES_FILTER:
      return initializeFilterState(state, action)
    default:
      return state
  }
}
