import apiFetch from '../apiFetch'

export default function (documentTypeId, token, file, checklistId, dossierIds, lastAccessDate) {
  const queryParamsArray = [
    documentTypeId ? `documentTypeId=${documentTypeId}` : null,
    checklistId ? `checklistId=${checklistId}` : null,
    dossierIds ? `dossierIds=${dossierIds}` : null
  ]
  const formData = new FormData()
  formData.append(file.name, file)
  const queryParams = queryParamsArray.reduce(
    (result, query, idx) => result + (query ? (result ? `&${query}` : `?${query}`) : '')
    , ''
  )
  return apiFetch({ endPoint: `DocumentFleets${queryParams}`, method: 'POST', body: formData, token: token, fileBody: true, lastAccessDate })
}
