import React, { PureComponent } from 'react'
import { Col, Checkbox } from 'react-bootstrap'

class InputCheckbox extends PureComponent {
  handleChange (value) {
    this.props.input.onChange(value.target.checked)
    if (this.props.onInputChange) {
      this.props.onInputChange(value.target.checked)
    }
  }

  render () {
    const { id, placeholder, disabled = false, inline = false, input: { value }, colSm = 6, customClass = '', checked } = this.props
    return (
      <Col sm={colSm} className={customClass}>
        <Checkbox
          id={id}
          inline={inline}
          disabled={disabled}
          checked={checked === undefined ? (typeof value) === 'string' ? value.toLowerCase() === 'true' : value : checked}
          onChange={this.handleChange.bind(this)}
        >
          {placeholder}
        </Checkbox>
      </Col>
    )
  }
}

export default InputCheckbox
