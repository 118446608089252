import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { openOperationTypeModal, fetchOperationTypesTable, deleteOperationType, recoverOperationType }
  from '../../../actions/masters/operationType/operationType'
import OperationTypeMaster from '../../../components/masters/operationType/OperationTypeMaster'
import { openModal } from '../../../actions/common'

export function mapStateToProps (state) {
  return {
    ...state.operationType
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      recoverOperationType,
      deleteOperationType,
      openOperationTypeModal,
      fetchOperationTypesTable,
      openModal
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(OperationTypeMaster))
