import mastersActionTypes from '../../../constants/actions/masters/masters'

function initialState () {
  return {
    financingTypeMastersList: [],
    financingCount: 0,
    pagesCount: 0,
    filter: {
      page: 1,
      orderBy: '',
      pageSize: 10
    }
  }
}

function fetchFinancingTypeListSuccess (state, { financingTypeMastersList, filter }) {
  return {
    ...state,
    financingTypeMastersList: financingTypeMastersList.financingTypeMastersList,
    financingCount: financingTypeMastersList.financingCount,
    pagesCount: financingTypeMastersList.pagesCount,
    filter
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case mastersActionTypes.FETCH_FINANCING_TYPE_SUCCESS_LIST:
      return fetchFinancingTypeListSuccess(state, action)
    default:
      return state
  }
}
