import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Img from '../../../components/commons/general/Img'
import { showSmallLoader, hideSmallLoader } from '../../../actions/common'

export function mapStateToProps (state) {
  return {
    isLoading: state.common.componentLoading
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({ showSmallLoader, hideSmallLoader }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Img)
