import React, { Component } from 'react'
import { Button, Modal, Col, Row, Form } from 'react-bootstrap'
import { reduxForm, Field } from 'redux-form'
import InputSelect from '../../../components/commons/form/InputSelect'
import { fleetMode } from '../../../constants/dossier/common/documentEditionModal'

class SignTypesModal extends Component {
  constructor (props) {
    super()
    this.state = {
      buttonDisabled: true
    }
    this.required = this.required.bind(this)
    this.onInputChange = this.onInputChange.bind(this)
  }

  signTypeActionPromise (values) {
    return new Promise((resolve, reject) => {
      if (this.props.fleetMode === fleetMode.REQUEST_SIGN) {
        return this.props.actions.createRequestSignDocumentFleetProcess(this.props.fleetDocumentDetails, this.props.fleetId, values.signTypeId, () => this.props.actions.closeAddOrEditDocumentModalSuccess())
      } else {
        return this.props.actions.requestSign(this.props.documentId, null, this.props.callback, this.props.refreshAfterChange, values.signTypeId, resolve, false, null, this.props.dossierType, this.props.updateDossierAction, this.props.dossierParentId)
      }
    })
  }

  signTypeAction (values) {
    if (values) {
      this.signTypeActionPromise(values)
      this.props.closeModalAction()
    }
  }

  required (value) {
    if (!value) {
      return this.props.t('GLOBAL.FORMS.REQUIRED')
    } else {
      return undefined
    }
  }

  onInputChange (value) {
    value ? this.setState({ buttonDisabled: false }) : this.setState({ buttonDisabled: true })
  }

  render () {
    const { t, handleSubmit, showModal, closeModalAction, signTypesCombo } = this.props
    const tKey = 'DOSSIER_COMPONENTS.SIGN_TYPES_MODAL.'
    return (
      <Modal show={showModal} onHide={() => closeModalAction()}>
        <Form autoComplete="off" onSubmit={handleSubmit(this.signTypeAction.bind(this))}>
          <Modal.Header closeButton>
            <Modal.Title>{t(`${tKey}TITLE`)}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              {signTypesCombo && signTypesCombo.length > 0 && <Field
                id="signTypeId"
                name="signTypeId"
                colSm={6}
                placeholder={t(`${tKey}PLACEHOLDER`)}
                component={InputSelect}
                options={signTypesCombo}
                valueKey="id"
                labelKey="value"
                validate={[this.required]}
                onInputChange={this.onInputChange}
              />}
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Col sm={6} className="btn-wrapper">
              <Button id="btn_save" disabled={this.state.buttonDisabled} className="btn-standard" type="submit">{t(`${tKey}ACCEPT`)}</Button>
            </Col>
          </Modal.Footer>
        </Form>
      </Modal>
    )
  }
}
const validate = (values, props) => {
  const error = {}
  return error
}

export default reduxForm({
  form: 'sign_types_modal',
  validate
})(SignTypesModal)
