import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { closeOrganizedUnitConfigurationMasterModal, submitOrganizedUnitConfiguration } from '../../../actions/masters/organizedUnitConfiguration/organizedUnitConfiguration'
import OrganizedUnitConfigurationMasterModal from '../../../components/masters/organizedUnitConfiguration/OrganizedUnitConfigurationMasterModal'

export function mapStateToProps (state) {
  const organizedUnitConfigurationModal = state.organizedUnitConfiguration.organizedUnitConfigurationModal
  return {
    showModal: organizedUnitConfigurationModal.showModal,
    organizedUnitConfiguration: organizedUnitConfigurationModal.organizedUnitConfiguration,
    combos: {
      organizedUnitsAllCombo: state.combos.organizedUnitsAllCombo,
      configParameterAllCombo: state.combos.configParameterAllCombo
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      submitOrganizedUnitConfiguration,
      closeOrganizedUnitConfigurationMasterModal
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(OrganizedUnitConfigurationMasterModal))
