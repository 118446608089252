import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { setActiveTab } from '../../../../actions/dossier/common/buttonsHeader'
import { setActiveSection } from '../../../../actions/dossier/common/buttonsSidebar'
import { fetchFilteredDashboardTask, getFilteredTaskToExcel } from '../../../../actions/dashboard/dashboardTask'
import { openDocumentEditionModal, closeAddOrEditDocumentModal } from '../../../../actions/dossier/common/documentEditionModal'
import TaskList from '../../../../_v2/components/dashboard/taskList/TaskList'
import { translate } from 'react-polyglot'
import { setScrollTo } from '../../../../actions/commons/scroller'

export function mapStateToProps (state) {
  return {
    ...state.dashboardTask
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchFilteredDashboardTask,
      openDocumentEditionModal,
      closeAddOrEditDocumentModal,
      getFilteredTaskToExcel,
      /* navigateTo, */
      setActiveTab,
      setActiveSection,
      setScrollTo
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(TaskList))
