import { referenceTypes } from '../../constants/document/documentTypeUse'
import apiFetch from '../apiFetch'

export default function (organizedUnitId, documentTypeId, token, file, id, checklistId, dossierId, lastAccessDate, chargeId, fromTemplate = false, referenceType
  , referenceId) {
  const queryParamsArray = [
    organizedUnitId ? `organizedUnitId=${organizedUnitId}` : 'organizedUnitId=null',
    documentTypeId ? `documentTypeId=${documentTypeId}` : 'documentTypeId=null',
    id ? `id=${id}` : null,
    checklistId ? `checklistId=${checklistId}` : null,
    dossierId ? `dossierId=${dossierId}` : null,
    chargeId ? `chargeId=${chargeId}` : null,
    referenceType ? `referenceType=${referenceType}` : referenceTypes.NONE,
    referenceId ? `referenceId=${referenceId}` : null
  ]
  const formData = new FormData()
  formData.append(file.name, file)
  const queryParams = queryParamsArray.reduce(
    (result, query, idx) => result + (query ? (result ? `&${query}` : `?${query}`) : '')
    , ''
  )
  return apiFetch({ endPoint: `${fromTemplate ? 'document/fromtemplate' : 'document'}${queryParams}`, method: 'POST', body: formData, token: token, fileBody: true, lastAccessDate })
}
