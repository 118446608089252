import { call, put, select, takeLatest } from 'redux-saga/effects'
import { reset } from 'redux-form'
import { getAuth } from '../../selectors/access/auth'
import getExternalCodeCombo from '../../services/campaignDetails/getExternalCodeCombo'
import commonActions from '../../actions/common'
import messageModalActions from '../../actions/modals/messageModal'
import { fetchExternalCodeSucces } from '../../actions/combos/combos'
import combosActionTypes from '../../constants/actions/combos/combos'
import { handleError } from '../errors/errorManager'

export function * fetchExternalCode () {
  try {
    yield put(commonActions.showLoader())
    const auth = yield select(getAuth)
    const response = yield call(getExternalCodeCombo, auth.token)
    yield put(fetchExternalCodeSucces(response))
  } catch (error) {
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    const customHandler = function * () {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.FETCH_COMBO_ERROR'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchFetchExternalCode () {
  yield takeLatest(combosActionTypes.FETCH_EXTERNALCODE_COMBO, fetchExternalCode)
}
