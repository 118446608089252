export const amountToWordsCurrencyTextType = {
  SINGLE: 1,
  PLURAL: 2,
  SPECIAL_CASE: 3
}
export const fleetMode = {
  INCORPORATE: 0,
  VALIDATE: 1,
  CANCEL: 2,
  REEDIT: 3,
  REQUEST_SIGN: 4,
  CANCEL_SIGN: 5,
  REJECT: 6,
  INCORPORATE_CHARGE: 7,
  VALIDATED_CHARGE: 8,
  INCORPORATED_CHARGE: 9,
  REJECTED_CHARGE: 10
}
