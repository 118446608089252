import { call, put, select, takeLatest } from 'redux-saga/effects'
// import { history } from '../../index'
import { getAuth } from '../../selectors/access/auth'
import commonActions from '../../actions/common.js'
import releaseNotesModalActionTypes from '../../constants/actions/modals/releaseNotesModal'
import releaseNotesModalActions from '../../actions/modals/releaseNotesModal'
import { handleError } from '../errors/errorManager'
import registerVersionAccess from '../../services/access/registerVersionAccess'
import settings from '../../settings/settings'

export function * closeReleaseNotesModal () {
  try {
    yield put(commonActions.showLoader())
    const auth = yield select(getAuth)
    yield call(registerVersionAccess, settings.version, auth.token)
    yield put(releaseNotesModalActions.closeReleaseNotesSuccess())
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchCloseReleaseNotesModal () {
  yield takeLatest(releaseNotesModalActionTypes.RELEASE_NOTES_CLOSE, closeReleaseNotesModal)
}
