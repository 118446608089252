import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { stopAsyncValidation, getFormValues } from 'redux-form'

import {
  fetchEntityFilterSubTypeCombo, fetchEntitySubTypeCombo, fetchIaeCombo,
  fetchOrganizedUnitsCombo, fetchLowestOrganizedUnits, fetchEntityCountryTypeCombo, fetchEntityStateTypeCombo, fetchEntityCityTypeCombo,
  fetchEntityTownTypeCombo, fetchEntityColonyTypeCombo, fetchEntityCPTypeCombo, fetchCommercialSocietyTypeCombo, fetchActivityBpro,
  fetchClientDataAddress, fetchEntityMaritalStatusCombo
} from '../../../actions/combos/combos'
import { getClientFleetPermissions, fetchFleetsEntity, checkChangeEntityDossier } from '../../../actions/management/management'
import { openModal } from '../../../actions/common'
import clientActions, { fetchClientFromDocument } from '../../../actions/dossier/common/client'
import DataContactFleetModal from '../../../components/management/AuxModals/DataContactFleetModal'
import { closeDataContactFleetModal, fetchEntityOperateChildFleetSucces } from '../../../actions/management/auxModals/auxModals'
import { setOperateDataContactFleetProcess } from '../../../actions/unattendedProcess/unattendedProcess'
import { validate } from '../../../actions/dossier/common/validations'

export function mapStateToProps (state) {
  return {
    ...state.managementAuxModals.dataContactFleetModal,
    entityFilterSubTypeCombo: state.combos.entityFilterSubTypeCombo,
    fleetFinderFilter: state.management.searchFleetInfo.dynamicFilters,
    entitySubTypeCombo: state.combos.entitySubTypeCombo,
    dniCif: state.management.dniCif,
    combos: state.combos,
    clientFleetPermissions: state.management.clientFleetPermissions,
    formValues: getFormValues('dataContactFleetModal')(state)
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      ...clientActions,
      fetchEntityFilterSubTypeCombo,
      openModal,
      fetchEntitySubTypeCombo,
      fetchClientFromDocument,
      getClientFleetPermissions,
      fetchFleetsEntity,
      checkChangeEntityDossier,
      fetchIaeCombo,
      fetchOrganizedUnitsCombo,
      fetchLowestOrganizedUnits,
      closeDataContactFleetModal,
      fetchEntityOperateChildFleetSucces,
      setOperateDataContactFleetProcess,
      fetchCommercialSocietyTypeCombo,
      fetchEntityCountryTypeCombo,
      fetchEntityStateTypeCombo,
      fetchEntityCityTypeCombo,
      fetchEntityTownTypeCombo,
      fetchEntityColonyTypeCombo,
      fetchEntityCPTypeCombo,
      fetchEntityMaritalStatusCombo,
      fetchActivityBpro,
      validate,
      stopAsyncValidation,
      fetchClientDataAddress
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(DataContactFleetModal))
