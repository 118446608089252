import apiFetch from '../../apiFetch'

export default function (dossierId, token, lastAccessDate, forceStore) {
  const queryParamsArray = [
    forceStore ? `forceStore=${forceStore}` : null
  ]
  const queryParams = queryParamsArray.reduce(
    (result, query, idx) => result + (query ? (result ? `&${query}` : `?${query}`) : '')
    , ''
  )
  return apiFetch({ endPoint: `dossier/${dossierId}/store${queryParams}`, method: 'PUT', token: token, lastAccessDate })
}
