
import React, { PureComponent } from 'react'
import { Col } from 'react-bootstrap'
import { replaceableFields } from '../../../constants/dossier/common/replaceableFields'

class MessageModal extends PureComponent {
  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.showMessage) {
      var time = 3000
      if (nextProps.messageType === 'error') {
        time = 5000
      }
      setTimeout(() => this.props.actions.closeMessageModal(), time)
    }
  }

  componentWillUnmount () {
    this.props.actions.closeMessageModal()
  }

  replaceKeys (message, others) {
    message = (message).replace(replaceableFields.dossier, others?.dossier)
    message = (message).replace(replaceableFields.contract, others?.contract)
    return message
  }

  writeMessage (t, tKeyMessage, tKeyFilteredByTag, sufixErrorTag, errorCode, others) {
    const message = t(tKeyFilteredByTag && `ERRORS.BACKEND_TAG_TRANSLATION.${tKeyFilteredByTag}` || tKeyMessage || 'ERRORS.BACKEND_TAG_TRANSLATION.DEFAULT_ERROR')
    if (others) {
      return (<p>{this.replaceKeys(message, others)} {' ' + errorCode ? errorCode : ''} {sufixErrorTag && t(`ERRORS.SUFIX_BACKEND_TAG_TRANSLATION.${sufixErrorTag}`)}</p>)
    }
    return (<p>{message} {' ' + errorCode ? errorCode : ''} {sufixErrorTag && t(`ERRORS.SUFIX_BACKEND_TAG_TRANSLATION.${sufixErrorTag}`)}</p>)
  }

  render () {
    const { showMessage, t, tKeyMessage, tKeyFilteredByTag, sufixErrorTag, errorCode, messageType, others, actions: { closeMessageModal } } = this.props
    if (!showMessage) return null
    return (
      <Col className={'message-modal ' + messageType}>
        {this.writeMessage(t, tKeyMessage, tKeyFilteredByTag, sufixErrorTag, errorCode, others)}
        <p className={'close-message-modal'} onClick={() => closeMessageModal()}>x</p>
      </Col>
    )
  }
}
export default MessageModal
