import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import FaqSectionMasterModal from '../../../components/masters/faqSection/FaqSectionMasterModal'
import { translate } from 'react-polyglot'
import { closeFaqSectionModal, saveFaqSection } from '../../../actions/masters/faqSection/faqSection'

export function mapStateToProps (state) {
  const { languages, faqSectionFields: { faqSectionId, organizedUnitId, faqSectionTranslations }, showModal } = state.faqSectionMaster.modal
  return {
    showModal: showModal,
    languageList: languages,
    translations: faqSectionTranslations || [],
    faqSectionId: faqSectionId,
    organizedUnitId: organizedUnitId,
    combos: {
      UOTreeCombo: state.combos.UOTreeCombo
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      closeFaqSectionModal,
      saveFaqSection
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(FaqSectionMasterModal))
