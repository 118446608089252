import { call, takeEvery, select } from 'redux-saga/effects'
import { getAuth } from '../../../selectors/access/auth'
import lastAccessDossier from '../../../constants/actions/dossier/common/lastAccess'
import postRegisterLastAccess from '../../../services/dossier/post/postRegisterLastAccess'

export function * registerLastAccess ({ dossierId, isHistorical }) {
  try {
    const auth = yield select(getAuth)
    yield call(postRegisterLastAccess, dossierId, isHistorical, auth.token)
  } catch (error) {

  } finally {
  }
}

export function * watchRegisterLastAccessDossier () {
  yield takeEvery(lastAccessDossier.REGISTER_LAST_ACCESS, registerLastAccess)
}
