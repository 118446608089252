import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import CampaignModalDocumentEdit from '../../../components/masters/campaign/CampaingModalDocumentEdit'
import { getFunctionsToAddDocumentalCampaign, addConditionDocumentalCampaign, deleteUoConditionDocCampaign } from '../../../actions/conditionsMaintenance/conditionsMaintenance'
import { closeEditDocumentalConditionModalEditSuccess } from '../../../actions/masters/masters'
import { fetchStagesCombo } from '../../../actions/combos/combos'
export function mapStateToProps (state) {
  const campaignModal = state.campaign.campaignModal
  return {
    showModalDocumentsEdit: campaignModal.showModalDocumentsEdit,
    document: campaignModal.document,
    combos: {
      stagesCombo: state.combos.stagesCombo,
      functionsToAdd: state.conditionsMaintenance.functionsToAddDocumentalCampaign,
      UOTreeCombo: state.combos.UOTreeCombo
    }
  }
}
deleteUoConditionDocCampaign
export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      closeEditDocumentalConditionModalEditSuccess,
      fetchStagesCombo,
      getFunctionsToAddDocumentalCampaign,
      addConditionDocumentalCampaign,
      deleteUoConditionDocCampaign
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(CampaignModalDocumentEdit))
