import React, { PureComponent } from 'react'
import { Tabs, Tab } from 'react-bootstrap'
import RoleAdminPage from '../../containers/admin/role/RoleAdminPage'
import UserAdminPage from '../../containers/admin/user/UserAdminPage'
import RecurrentEntityAdminPage from '../../containers/admin/recurrentEntity/RecurrentEntityAdminPage'
import { adminTabIndex } from '../../constants/components/adminTabIndex'

class Admin extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      activeTab: adminTabIndex.usersTab
    }
    this.onSelectTab = this.onSelectTab.bind(this)
  }

  onSelectTab (tabKey) {
    this.setState({ activeTab: tabKey })
  }

  componentDidMount () {
    this.props.actions.fetchUserList(this.props.filter)
  }

  componentDidUpdate () {
    if (this.props.changeTabFromMaster === true) {
      this.setState({ activeTab: adminTabIndex.recurrentEntitiesTab })
      this.props.actions.setChangeTabFromMaster(this.props.ouIdFromMaster, false)
    }
  }

  render () {
    const { t } = this.props
    const tKey = 'ADMIN.'
    return (
      <div className="masters-container search-wrapper">
        <div className="search-header">
          <div className="title-user">
            <i className="ico-users" />
            <h2>{t(`${tKey}TITLE`)}</h2>
          </div>
        </div>
        <Tabs activeKey={this.state.activeTab} defaultActiveKey={1} onSelect={this.onSelectTab.bind(this)} id="groups-tabs">
          <Tab eventKey={1} title={t(`${tKey}USERS_TITLE`)} />
          <Tab eventKey={2} title={t(`${tKey}ROLES`)} />
          <Tab eventKey={3} title={t(`${tKey}RECURRENT_ENTITIES_TITLE`)} />
        </Tabs>
        {this.state.activeTab === 1 ? (
          <UserAdminPage />
        ) : (this.state.activeTab === 2 ? (
          <RoleAdminPage />
        ) : (<RecurrentEntityAdminPage />
        ))}
      </div>
    )
  }
}

export default Admin
