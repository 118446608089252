import React, { PureComponent } from 'react'
import { Modal, Col, Row, Form, Button, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { reduxForm } from 'redux-form'
import settings from '../../../setting'
import DropZone from 'react-dropzone'
const averageCharacter = 'c'

class DocumentUpload extends PureComponent {
  constructor () {
    super()
    this.state = {
      documentName: '',
      documentTitle: averageCharacter,
      showTitle: false,
      files: [],
      showRejectCommentModal: false,
      hasNewDocument: false,
      showFileUploader: true,
      hasInvalidFile: false,
      showDocumentCarrusel: false,
      autoCloseDocumentCarrusel: false,
      isDocumentCarruselEndReached: false,
      showPageCarrusel: true,
      autoClosePageCarrusel: false,
      documentLoaded: false,
      zoomProps: {
        width: 0,
        height: 0,
        zoomWidth: 0,
        scale: 0.9,
        img: '',
        offset: { vertical: 0, horizontal: 10 },
        zoomStyle: 'box-shadow: 0px 2px 5px rgba(0,0,0,0.3)',
        zoomLensStyle: 'background-color: rgba(0,0,0,0.3); cursor: crosshair;'
      },
      hasDropzoneImg: false,
      parametroFecha: Date.now(),
      dropzoneActive: false,
      dropzonePanelActive: false
    }

    this.onDrop = this.onDrop.bind(this)

    this.cancelDocument.bind(this)

    this.documentTitleElementRef = null
    this.buttonUpload = false
    this.buttonReject = false
    this.setDocumentTitleElementRef = element => {
      this.documentTitleElementRef = element
    }
  }

  cancelDocument () {
    this.closeModal(true)
  }

  closeModal (canShowAdvise = true) {
    new Promise((resolve, reject) => {
      return this.props.actions.checkCanCloseDocumentModal(canShowAdvise && this.state.documentLoaded, resolve)
    }).then((result) => {
      if (result) {
        this.props.closeCommonModal && this.props.actions.okResponse()
        this.props.actions.closeUploadDocumentFleetModal()
        this.setState({
          documentTitle: averageCharacter,
          showTitle: false,
          files: [],
          hasNewDocument: false,
          showFileUploader: true,
          hasInvalidFile: false,
          zoomProps: { ...this.state.zoomProps, width: 0, height: 0, zoomWidth: 0, img: '' },
          hasDropzoneImg: false,
          documentLoaded: false,
          dropzoneActive: false,
          dropzonePanelActive: false,
          documentName: ''
        })
        this.buttonUpload = false
        this.buttonReject = false
        this.props.initialize({})
      }
    })
  }

  onDrop (fileToUpload, rejectedFiles) {
    if (fileToUpload.length === 0 && rejectedFiles.length > 0) {
      this.setState({ hasInvalidFile: true })
    } else {
      this.setState({ files: [fileToUpload[0]], hasNewDocument: true, hasInvalidFile: false })
      if (fileToUpload[0]) {
        this.buttonUpload = true
        this.buttonReject = true
        this.setState({
          documentLoaded: false,
          showFileUploader: false
        })

        this.setState({ documentName: fileToUpload[0].name })
      }
    }
  }

  onDragEnter (panel = false) {
    this.setState(panel ? { dropzonePanelActive: true } : { dropzoneActive: true })
  }

  onDragLeave (panel = false) {
    this.setState(panel ? { dropzonePanelActive: false } : { dropzoneActive: false })
  }

  changeAll (values) {
    Object.keys(values.entityValue).map(value => {
      this.props.change('UploadDocumentFleetForm', `entityValue.${value}`, values.entityValue[value])
    })
  }

  submitDocument (canShowAdvise = true) {
    new Promise((resolve, reject) => {
      let nfiles = this.state.files.length
      let lastFile = this.state.files[nfiles - 1]
      return this.props.actions.uploadDocumentFleet(lastFile, this.props.brand, resolve, this.props.isBuyAndSell, this.props.code)
    }).then((result) => {
      if (result) {
        this.setState({
          documentTitle: averageCharacter,
          showTitle: false,
          files: [],
          hasNewDocument: false,
          showFileUploader: true,
          hasInvalidFile: false,
          zoomProps: { ...this.state.zoomProps, width: 0, height: 0, zoomWidth: 0, img: '' },
          hasDropzoneImg: false,
          documentLoaded: false,
          dropzoneActive: false,
          dropzonePanelActive: false
        })
        this.buttonUpload = false
        this.buttonReject = false
        this.props.initialize({})
      }
    })
  }

  getDocumentTitle (props) {
    const tKey = 'DASHBOARD.UPLOAD_MANAGEMENT.UPLOAD_DOCUMENT_FLEET.'
    return (props.docResult.documentId ? props.t(`${tKey}EDIT`) : props.t(`${tKey}ADD`)) + (props.documentTypeUseName ? (' - ' + props.documentTypeUseName) : '')
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (this.props.showModal && !nextProps.showModal) {
      this.setState({
        documentTitle: averageCharacter,
        showTitle: false,
        files: [],
        dropzoneActive: false,
        dropzonePanelActive: false
      })
    }
    this.setState({
      zoomProps: {
        ...this.state.zoomProps,
        img: `${settings.url}/Document/GetImage?token=${nextProps.token}&fecha=${this.state.parametroFecha}`
      }
    })
  }

  render () {
    const { t, showModal, handleSubmit } = this.props
    const tKey = 'DASHBOARD.UPLOAD_MANAGEMENT.'
    const acceptFilesDropzone = settings.acceptFileFormats
    const maxSizeFilesDropzone = 10240000
    const documentTitleTooltip = (<Tooltip id="documentTitleTooltip">{t(`${tKey}UPLOAD_DOCUMENT_FLEET`)}</Tooltip>)
    return (
      <Modal show={showModal} bsSize="large" onHide={() => this.closeModal()}>
        <Modal.Header closeButton >
          <Modal.Title>
            <span className="document-title-ellipsis">
              <OverlayTrigger placement="right" overlay={documentTitleTooltip}>
                <span id="documentTitle" ref={this.setDocumentTitleElementRef} className={'document-title'}>{t(`${tKey}UPLOAD_DOCUMENT_FLEET`)}</span>
              </OverlayTrigger>
              <br />
            </span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col sm={12} className="input-dropzone-wrapper">
              <Row className="form-group">
                {
                  <Col sm={12} className={'document-edition-modal-middle-side'}>
                    <div className={'input-dropzone'} style={{ height: '100%' }}>
                      {
                        <DropZone
                          id="dropZone"
                          multiple={false}
                          onDrop={(acceptedFiles, rejectedFiles) => this.onDrop(acceptedFiles, rejectedFiles)}
                          disabled={false}
                          accept={acceptFilesDropzone}
                          maxSize={maxSizeFilesDropzone}
                          style={{ height: '100%' }}
                          className={this.state.dropzoneActive ? 'active' : ''}
                          onDragEnter={() => this.onDragEnter(false)}
                          onDragLeave={() => this.onDragLeave(false)}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()}>
                              <input {...getInputProps()} />
                              {this.state.documentName !== '' ? (
                                <div className="text-wrapper">
                                  {t(`${tKey}SELECTED_FILES`)}: {this.state.documentName}
                                </div>
                              ) : (
                                <div className="text-wrapper modal-subir-fichero-inicio">
                                  <div>
                                    <i className='ico-cloud' />
                                    <h2 key="dropZone-title">{t(`${tKey}DROP_FILES`)}</h2>
                                    <p key="dropZone-subtitle">{t(`${tKey}SELECT_FILES`)}</p>
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                        </DropZone>
                      }
                    </div>
                  </Col>
                }
              </Row>
              {
                !this.state.showFileUploader && (
                  <Row className="form-group">
                    <Col sm={12} className="document-edition-modal-middle-side" >
                      <div className="btn-wrapper">
                        {
                          this.buttonUpload || this.buttonReject ? [
                            this.buttonUpload && (<Button
                              id="btn_validate"
                              key="btn_validate"
                              className="btn-validate-white"
                              style={{ marginRight: '10px' }}
                              onClick={handleSubmit(values => {
                                this.submitDocument({ ...values, validateAction: false })
                              })}
                            ><i className="ico-check" /> {t(`${tKey}UPLOAD_BTN`)}</Button>),
                            this.buttonReject && (
                              <Button
                                id="btn_reject"
                                key="btn_reject"
                                className="btn-reject-white"
                                style={{ marginRight: '10px' }}
                                onClick={() => this.closeModal(true)}
                              ><i className="ico-cancel" /> {t(`${tKey}REJECT_BTN`)}</Button>
                            )
                          ] : null
                        }
                      </div>
                    </Col>
                  </Row>
                )
              }
            </Col>
            <Col sm={6} className="document-edition-modal-form">
              <Form autoComplete="off" style={{ height: '100%' }} onSubmit={handleSubmit(values => {
                this.changeAll(values)
                this.submitDocument(values)
              })}>
              </Form>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    )
  }
}

export default reduxForm({
  form: 'UploadDocumentFleetForm',
  destroyOnUnmount: false
})(DocumentUpload)
