import apiFetch from '../apiFetch'

export default function (token, fleetId, deleteDossiers) {
  const queryParamsArray = [
    fleetId ? `fleetId=${fleetId}` : null,
    `deleteDossiers=${deleteDossiers}`
  ]

  const queryParams = queryParamsArray.reduce(
    (result, query, idx) => result + (query ? (result ? `&${query}` : `?${query}`) : '')
    , ''
  )
  return apiFetch({ endPoint: `UnattendedProcess/SetDeleteFleetProcess${queryParams}`, method: 'POST', body: null, token: token })
}
