import React, { PureComponent } from 'react'
import { Modal, Col, Row, FormGroup, Button, Clearfix, OverlayTrigger, Tooltip } from 'react-bootstrap'
import InputText from '../../../commons/form/InputText'
import { reduxForm, Field, reset } from 'redux-form'
import { multipleNormalize, toUpperCase, trimAll } from '../../../../util/normalizeFunctions'
import { urgencyTypesClass } from '../../../../constants/backendIds'
import SimpleTablePage from '../../../../containers/commons/general/SimpleTablePage'
import IDocCarPagination from '../../../commons/iDocCarPagination'
import { Link } from 'react-router-dom'
import { fromCamelToUnderscore } from '../../../../util/utils'
import TrackingContainer from '../../../../containers/tracking/trackingContainer'

class SelfSupplyFinderModal extends PureComponent {
  constructor () {
    super()
    this.state = {
      fields: [],
      dynamicFilters: []

    }
  }

  selectDossier (value) {
    var dossiersId = null
    var dossiersHistoricalId = null
    if (value.isHistorical) {
      dossiersHistoricalId = value.dossierId
    } else {
      dossiersId = value.dossierId
    }
    this.props.actions.saleSelfSupplyList({ dossierId: dossiersId, historicalsId: dossiersHistoricalId, saleParentId: this.props.dossier.dossierId, pristine: this.props.pristineParent, reset: this.props.reset })
    this.closeModal()
  }

  submitService (value) {
    this.props.actions.fetchSelfSupply({ ...value.selfSupply, ...this.props.filters, idParent: this.props.dossier.dossierId, stock: true })
    this.setState({ dynamicFilters: value.selfSupply })
  }

  fieldFill (field) {
    this.setState({ fields: { ...this.state.fields, ...field } })
  }

  selectedFilters () {
    const filters = this.state.fields
    const tKey = 'SEARCH.CATEGORIES.DOSSIER.'
    return Object.keys(filters)
      .map((item, index) => {
        let text = item
        let value = null
        const list = []
        text = this.props.t(`${tKey}${fromCamelToUnderscore(item).toUpperCase()}`)
        value = filters[item]
        if (value) {
          list.push(<li key={index}>{text}: <strong>{value.toUpperCase()}</strong></li>)
        }
        return list
      })
  }

  onOrderChange (e) {
    const newOrderBy = this.props.filters.orderBy.includes(`%2B${e.currentTarget.dataset.field}`) ? `-${e.currentTarget.dataset.field}` : `%2B${e.currentTarget.dataset.field}`
    this.fetchTable({ ...this.props.filters, orderBy: newOrderBy })
  }

  onPageChange (page) {
    this.fetchTable({ ...this.props.filters, page })
  }

  getIconClass (field) {
    if (this.props.filters.orderBy.includes(field)) {
      return this.props.filters.orderBy.includes('-') ? 'ico-sort-selected-asc' : 'ico-sort-selected-desc'
    } else {
      return 'ico-sort'
    }
  }

  fetchTable (propFilters) {
    let filters = this.state.dynamicFilters
    this.props.actions.fetchSelfSupply({ ...filters, ...propFilters, idParent: this.props.dossier.dossierId, stock: true })
    this.setState({ dynamicFilters: filters })
  }

  onClickResetSearch (e) {
    if (e !== undefined) {
      e.preventDefault()
    }

    this.props.actions.resetFilters()
  }

  handleClickReset () {
    this.props.actions.resetFilters()
    this.setState({
      fields: [],
      dynamicFilters: []
    })
    this.props.dispatch(reset('selfSupplyFinderModal'))
  }

  closeModal () {
    this.handleClickReset()
    this.props.actions.closeSelfSupplyModal()
  }

  closeModalLink () {
    this.handleClickReset()
    this.props.actions.closeSelfSupplyModal()
    this.props.dispatch(reset('salesDossier'))
  }

  render () {
    const {
      handleSubmit, showModal, className, hasSearch, selfSupplyData,
      filters: { page, pages }, count, t
    } = this.props

    const tKey = 'SEARCH.CATEGORIES.DOSSIER.'

    const asignTooltip = (<Tooltip id="asignTooltip">{t('DOSSIER_COMPONENTS.SELFSUPPLY.ASSOCIATE_VEHICLE')}</Tooltip>)

    return (
      <Modal className="fleetFinder-modal" show={showModal} >
        <form autoComplete="off" onSubmit={handleSubmit(this.submitService.bind(this))}>
          <Modal.Header closeButton onHide={() => this.closeModal()}>
            <Modal.Title> {t('DOSSIER_COMPONENTS.SELFSUPPLY.TITLE_PANEL_MODAL')} </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <div>
                {!hasSearch ? (
                  <Row>
                    <Field
                      id="dossierNumber"
                      name='selfSupply.dossierNumber'
                      placeholder={t(`${tKey}DOSSIER_NUMBER`)}
                      controlLabel={t(`${tKey}DOSSIER_NUMBER`)}
                      component={InputText}
                      type="text"
                      onInputChange={(val) => this.fieldFill({ dossierNumber: val })}
                      customClass={this.state.fields.dossierNumber ? className : ''}
                      normalize={value => multipleNormalize(value, [toUpperCase, trimAll])}
                    />
                    <Field
                      colSm={3}
                      id="chassis"
                      name='selfSupply.chassis'
                      placeholder={t('SEARCH.CATEGORIES.VEHICLE.CHASSIS')}
                      controlLabel={t('SEARCH.CATEGORIES.VEHICLE.CHASSIS')}
                      component={InputText}
                      type="text"
                      maxLength={17}
                      normalize={value => multipleNormalize(value, [toUpperCase, trimAll])}
                      onInputChange={(val) => this.fieldFill({ chassis: val })}
                      customClass={this.state.fields.chassis ? className : ''}
                    />
                    <Field
                      colSm={3}
                      id="licensePlate"
                      name='selfSupply.licensePlate'
                      placeholder={t(`${tKey}LICENSE_PLATE`)}
                      controlLabel={t(`${tKey}LICENSE_PLATE`)}
                      component={InputText}
                      type="text"
                      normalize={value => multipleNormalize(value, [toUpperCase, trimAll])}
                      onInputChange={(val) => this.fieldFill({ licensePlate: val })}
                      customClass={this.state.fields.licensePlate ? className : ''}
                    />
                  </Row>
                ) : ([
                  <Row className="filters-selected" key="filters-selected">
                    <ul className="list-inline pull-left">
                      {this.selectedFilters().length === 0 ? <li>{t('SEARCH.NO_SELECTED_FILTERS')}</li> : (this.selectedFilters())}
                    </ul>
                    <Clearfix/>
                    <Button
                      onClick={(event) => this.onClickResetSearch(event)}
                      bsStyle="default"
                      bsSize="small"
                      className="pull-left"
                    >
                      {t('SEARCH.ACTIONS.MODIFY_SEARCH')}
                      <i className="ico-edit-turquoise" />
                    </Button>
                  </Row>,
                  <div className="table-wrapper" key="table-wrapper">
                    <SimpleTablePage
                      columns={[
                        <th key={0} />,
                        <th key={1} data-field="number" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}DOSSIER_NUMBER`)}&nbsp;<i className={this.getIconClass('number')}/>
                        </th>,
                        <th key={2} data-field="vehicleLicensePlate" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}LICENSE_PLATE`)}&nbsp;<i className={this.getIconClass('vehicleLicensePlate')}/>
                        </th>,
                        <th key={3} data-field="vinChassis" onClick={(event) => this.onOrderChange(event)}>
                          {t('SEARCH.CATEGORIES.VEHICLE.CHASSIS')}&nbsp;<i className={this.getIconClass('vehicleVinChassis')}/>
                        </th>,
                        <th key={4} data-field="creationDate" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}CREATION_DATE`)}&nbsp;<i className={this.getIconClass('creationDate')}/>
                        </th>,
                        <th key={5} data-field="check" onClick={(event) => this.onOrderChange(event)} className="th-asocciate">
                          {t('DOSSIER_COMPONENTS.SELFSUPPLY.ASSOCIATE_VEHICLE')}
                        </th>
                      ]}
                      rows={selfSupplyData && selfSupplyData.map((row, idx) => (
                        <tr key = {idx}>
                          <td>
                            <span className={`color-point ${urgencyTypesClass[row.urgencyType] || 'grey'}`}/>
                          </td>
                          <td> <Link to={`/dossier${row.urlToNavigate}`} onClick={() => this.closeModalLink()}>
                            {row.number}
                          </Link> </td>
                          <td>{row.vehicle.vehicleLicensePlate}</td>
                          <td>{row.vehicle.vehicleVinChassis}</td>
                          <td>{row.creationDate}</td>
                          <td className="text-center" >{ <a onClick={() => this.selectDossier(row)}>
                            <OverlayTrigger placement="right" overlay={asignTooltip}>
                              <i className="ico-plus" />
                            </OverlayTrigger>
                          </a>}</td>
                        </tr>
                      )
                      )
                      }
                    />
                  </div>,
                  <div className="search-footer" key="search-footer">
                    <IDocCarPagination
                      id="btn_pag_sales"
                      page={page}
                      pagesCount={pages}
                      totalRows= {count}
                      onSelectAction={(page) => this.onPageChange(page)}
                    />
                  </div>
                ])}
                {!hasSearch && (
                  <Row>
                    <Col sm={12} className="text-center">
                      <hr/>
                      <Button
                        id="btn_search"
                        type="submit"
                        className="btn-standard"
                      >
                        <i className="ico-search"/>
                        {t('SEARCH.ACTIONS.SEARCH_BUTTON')}
                      </Button>
                      <Button
                        id="btn_reset"
                        onClick={(e) => this.handleClickReset(e)}
                        className={'btn-white btn-icon' + (this.props.pristine ? ' btn-cancel btb-cancel-modal' : ' btn-standard')}
                      >
                        <i className="ico-cancel"/> {t('SEARCH.ACTIONS.RESET_BUTTON')}
                      </Button>
                      <br></br>
                      <br></br>
                    </Col>
                  </Row>
                )}
              </div>
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <Col sm={12} className="btn-wrapper">
              <Button
                id="btn_add_service_modal"
                className="btn-standard"
                onClick ={() => this.closeModal()}
              >
                {t('GLOBAL.BUTTONS.CLOSE')}
              </Button>
            </Col>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

var decoratedComponent = TrackingContainer(SelfSupplyFinderModal, false)

export default reduxForm({
  form: 'selfSupplyFinderModal'
})(decoratedComponent)
