import { call, put, select, takeEvery } from 'redux-saga/effects'
// import { reset } from 'redux-form'
import { getAuth } from '../../selectors/access/auth'
import getHomologationProducerCombo from '../../services/dossier/get/getHomologationProducer'
import commonActions from '../../actions/common'
import messageModalActions from '../../actions/modals/messageModal'
import { fetchCombosSuccess } from '../../actions/combos/combos'
import combosActionTypes from '../../constants/actions/combos/combos'
import { handleError } from '../errors/errorManager'

export function * fetchHomologationProducerCombo () {
  try {
    yield put(commonActions.showLoader())
    const auth = yield select(getAuth)
    const combo = yield call(getHomologationProducerCombo, auth.token)
    yield put(fetchCombosSuccess({ homologationProducerCombo: combo }))
  } catch (error) {
    const sessionHandler = function * () {
      // yield put(reset('conditionsMaintenanceForm'))
    }
    const customHandler = function * () {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.FETCH_COMBO_ERROR'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchFetchHomologationProducerCombo () {
  yield takeEvery(combosActionTypes.FETCH_HOMOLOGATION_PRODUCER_COMBO, fetchHomologationProducerCombo)
}
