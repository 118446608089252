
import React, { PureComponent } from 'react'
import { Row, Clearfix, Button } from 'react-bootstrap'
import { Field } from 'redux-form'
import InputText from '../commons/form/InputText'
import InputSelect from '../commons/form/InputSelect'
import InputCheckBox from '../commons/form/InputCheckBox'
import settings from '../../setting'
import SimpleTablePage from '../../containers/commons/general/SimpleTablePage'
import IDocCarPagination from '../commons/iDocCarPagination'
import { clientInformation, urgencyTypesClass } from '../../constants/backendIds'
import { Link } from 'react-router-dom'
import EntityPopover from './EntityPopover'
import VehiclePopover from './VehiclePopover'

class SearchByDossierSimpleForm extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      fields: {}
    }
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (this.props && this.props.combos &&
    this.props.combos.searchDossierSubtypesCombo && this.props.combos.searchDossierSubtypesCombo.length > 0 &&
    (prevProps.dossierData === undefined || prevProps.dossierData.length === 0)) {
      this.props.selectDossierSubTypeFirstTime()
    }
    return null
  }

  componentDidUpdate () {
    // getSnapshotBeforeUpdate() should be used with componentDidUpdate()
  }

  exportData () {
    const tKey = 'SEARCH.CATEGORIES.DOSSIER.'
    let columnsExcel = []
    columnsExcel.push(this.props.t(`${tKey}URGENCY`) + '#Urgency')
    columnsExcel.push(this.props.t(`${tKey}DOSSIER_NUMBER`) + '#Number')
    columnsExcel.push(this.props.t(`${tKey}ORGANIZED_UNIT`) + '#Uo')
    columnsExcel.push(this.props.t('SEARCH.CATEGORIES.ENTITY.BY_ENTITY') + '#Entity')
    columnsExcel.push(this.props.t('SEARCH.CATEGORIES.VEHICLE.BY_VEHICLE') + '#Vehicle')
    columnsExcel.push(this.props.t(`${tKey}DOSSIER_SUBTYPE`) + '#SubType')
    columnsExcel.push(this.props.t(`${tKey}STAGE`) + '#Stage')
    if (settings.clientFieldValidation === clientInformation.QUADIS) {
      columnsExcel.push(this.props.t(`${tKey}CREATOR_ID`) + '#Creator')
      columnsExcel.push(this.props.t(`${tKey}SALESMAN_MANAGER`) + '#SalesManagerName')
    }
    columnsExcel.push(this.props.t(`${tKey}CREATION_DATE`) + '#CreationDate')
    columnsExcel.push(this.props.t(`${tKey}URL`) + '#UrlToNavigate')

    this.props.exportToExcel(columnsExcel)
  }

  render () {
    const {
      t, combos: { searchDossierSubtypesCombo }, fields, fieldFill, className, classNameSelect, hasSearch, dossierData, vehicleData, selectedFilters,
      onClickResetSearch, page, pages, count, onPageChange, onOrderChange, getIconClass
    } = this.props
    const tKey = 'SEARCH.CATEGORIES.DOSSIER.'
    return (
      <div>
        {!hasSearch ? (
          <Row>
            <Field
              id="dealerIdNumberDossierSimple"
              name='dossier.dealerNumber'
              placeholder={t(`${tKey}DEALER_NUMBER`)}
              controlLabel={t(`${tKey}DEALER_NUMBER`)}
              component={InputText}
              type="text"
              maxLength={settings.dealerMaxLength}
              onInputChange={(val) => fieldFill({ dealerIdNumber: val })}
              customClass={fields.dealerIdNumber ? className : ''}
            />
            <Field
              id="dossierSubtype"
              name='dossier.dossierSubtype'
              placeholder={t(`${tKey}DOSSIER_SUBTYPE`)}
              controlLabel={t(`${tKey}DOSSIER_SUBTYPE`)}
              component={InputSelect}
              options={searchDossierSubtypesCombo}
              valueKey="id"
              labelKey="value"
              onInputChange={(val) => fieldFill({ dossierSubtype: val })}
              customClass={fields.dossierSubtype ? classNameSelect : ''}
            />
            <div key="clearfix_1" className="clearfix" />
            <Field
              id="searchHistorical"
              name="dossier.searchHistorical"
              placeholder={t(`${tKey}SEARCH_HISTORICAL`)}
              component={InputCheckBox}
              onInputChange={(val) => fieldFill({ searchHistorical: val })}
            />
          </Row>
        ) : ([
          <Row className="filters-selected" key="filters-selected">
            <ul className="list-inline pull-left">
              {selectedFilters().length === 0 ? <li>{t(`${tKey}NO_SELECTED_FILTERS`)}</li> : (selectedFilters())}
            </ul>
            <Clearfix/>
            <Button
              onClick={(event) => onClickResetSearch(event)}
              bsStyle="default"
              bsSize="small"
              className="pull-left"
              id='modSearchButtonIdDossSimple'
            >
              {t('SEARCH.ACTIONS.MODIFY_SEARCH')}
              <i className="ico-edit-turquoise" />
            </Button>
            {count > 0 &&
            <Button
              onClick={(event) => this.exportData(event)}
              bsStyle="default"
              bsSize="small"
              id='expSearchButtonIdDossSimple'>
              {t('SEARCH.ACTIONS.EXPORT')}
              <i className="ico-if_Download_1031520" />
            </Button>
            }
          </Row>,
          <div className="table-wrapper" key="table-wrapper">
            <SimpleTablePage
              columns={[
                <th key={0} />,
                <th key={1} data-field="number" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}DOSSIER_NUMBER`)}&nbsp;<i className={getIconClass('number')}/>
                </th>,
                <th key={2} data-field="organizedUnit" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}ORGANIZED_UNIT`)}&nbsp;<i className={getIconClass('organizedUnit')}/>
                </th>,
                <th key={3}>{t('SEARCH.CATEGORIES.ENTITY.BY_ENTITY')}</th>,
                <th key={4}>{t('SEARCH.CATEGORIES.VEHICLE.BY_VEHICLE')}</th>,
                <th key={5} data-field="dossierSubType" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}DOSSIER_SUBTYPE`)}&nbsp;<i className={getIconClass('dossierSubType')}/>
                </th>,
                <th key={6} data-field="stage" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}STAGE`)}&nbsp;<i className={getIconClass('stage')}/>
                </th>,
                settings.clientFieldValidation === clientInformation.QUADIS && ([
                  <th key={7} data-field="creator" onClick={(event) => onOrderChange(event)}>
                    {t(`${tKey}CREATOR_ID`)}&nbsp;<i className={getIconClass('creator')}/>
                  </th>,
                  <th key={8} data-field="salesmanManager" onClick={(event) => onOrderChange(event)}>
                    {t(`${tKey}SALESMAN_MANAGER`)}&nbsp;<i className={getIconClass('salesmanManager')}/>
                  </th>
                ]),
                <th key={9} data-field="creationDate" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}CREATION_DATE`)}&nbsp;<i className={getIconClass('creationDate')}/>
                </th>
              ]}
              rows={dossierData && dossierData.map((row, idx) => (
                <tr key={idx}>
                  <td>
                    <span className={`color-point ${urgencyTypesClass[row.urgencyType] || 'grey'}`}/>
                  </td>
                  <td>
                    <Link to={`/dossier${row.urlToNavigate}`}>
                      {row.number}
                    </Link>
                  </td>
                  <td>{row.organizedUnit}</td>
                  <td className="see-more">
                    <EntityPopover
                      t={t}
                      entity={row.entity}
                      half={(vehicleData.length || 1) / (idx + 1)}
                      idx={idx}
                      id={'popover-entity' + idx}
                    />
                  </td>
                  <td className="see-more">
                    <VehiclePopover
                      t={t}
                      vehicle={row.vehicle}
                      half={(vehicleData.length || 1) / (idx + 1)}
                      idx={idx}
                      id={'popover-vehicle' + idx}
                    />
                  </td>
                  <td>{row.dossierSubType}</td>
                  <td>{row.stage}</td>
                  {
                    settings.clientFieldValidation === clientInformation.QUADIS && ([
                      <td>{row.creatorId}</td>,
                      <td>{row.salesManagerName}</td>
                    ])
                  }
                  <td>{row.creationDate}</td>
                </tr>
              ))}
            />
          </div>,
          <div className="search-footer" key="search-footer">
            <IDocCarPagination
              id="btn_pag_sales"
              page={page}
              pagesCount={pages}
              totalRows= {count}
              onSelectAction={(page) => onPageChange(page)}
            />
          </div>
        ])}
      </div>
    )
  }
}

export default SearchByDossierSimpleForm
