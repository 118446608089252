import apiFetch from '../apiFetch'

export default function (subscriptionId, eventSubscription, token, userId) {
  const body = {
    notificationEventId: eventSubscription.notificationEventId,
    organizedUnitIds: eventSubscription.organizedUnitIds,
    languageId: eventSubscription.languageId,
    active: eventSubscription.active
  }
  const userIdParam = userId ? `userId=${userId}` : ''
  return apiFetch({ endPoint: `EventSubscription/${subscriptionId}?${userIdParam}`, method: 'PUT', body: body, token: token })
}
