import apiFetch from '../apiFetch'
export default function (token, { page, pageSize, orderBy, dossierNumber, chassis, licensePlate, idParent, stock }) {
  const queryParamsArray = [
    page ? `page=${page}` : null,
    pageSize ? `pageSize=${pageSize}` : null,
    orderBy ? `orderBy=${orderBy}` : null,
    dossierNumber ? `dossierNumber=${dossierNumber}` : null,
    chassis ? `chassis=${chassis}` : null,
    licensePlate ? `licensePlate=${licensePlate}` : null,
    idParent ? `idParent=${idParent}` : null,
    stock ? `stock=${stock}` : null
  ]
  const queryParams = queryParamsArray.reduce(
    (result, query, idx) => result + (query ? (result ? `&${query}` : `?${query}`) : '')
    , ''
  )
  return apiFetch({
    endPoint: `DossierFinder/DossierSelfSupply${queryParams}`,
    method: 'GET',
    body: null,
    token: token
  })
}
