import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import SaleTypeModal from '../../../components/masters/saleType/SaleTypeModal'
import { translate } from 'react-polyglot'
import { closeEditSaleTypeModal, saveSaleTypeModal } from '../../../actions/masters/masters'
import { fetchDossierSubTypes } from '../../../actions/combos/combos'

export function mapStateToProps (state) {
  return {
    ...state.saleType.saleTypeModal,
    combos: {
      dossierSubtypesCombo: state.combos.dossierSubTypesCombo
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      closeEditSaleTypeModal,
      fetchDossierSubTypes,
      saveSaleTypeModal
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(SaleTypeModal))
