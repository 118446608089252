import { put, call, select, takeEvery } from 'redux-saga/effects'
import { getAuth } from '../../selectors/access/auth'
import reportActionTypes from '../../constants/actions/reports/report'
import { fetchReportTypeCodeSuccess } from '../../actions/reports/report'
import { showLoader, hideLoader } from '../../actions/common.js'
import getReportTypeCode from '../../services/reports/getReportTypeCode'
import { handleError } from '../errors/errorManager'

export function * fetchReportTypeCode ({ resolve, reject }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const code = yield call(getReportTypeCode, auth.token)
    yield put(fetchReportTypeCodeSuccess(code))
    if (resolve) yield call(resolve)
  } catch (error) {
    if (reject) yield call(reject)
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchReportTypeCode () {
  yield takeEvery(reportActionTypes.FETCH_REPORT_TYPE_CODE, fetchReportTypeCode)
}
