import { permissions } from '../constants/backendIds'
import { buttonsHeaderPermisions } from '../constants/dossier/common/buttonsHeader'
import apiSignErrorMessages from '../constants/apiSignErrorMessages'
import { languages } from '../constants/dossier/common/languages'
import { currencyMoneyFormat } from '../constants/dossier/common/currencies'
import settings from '../setting'
import { buttonsSidebar } from '../constants/dossier/common/buttonsSidebar'
import { stockVoStages } from '../constants/dossier/stockVo/stockVoStages'

// Source: https://stackoverflow.com/questions/7837456/how-to-compare-arrays-in-javascript
export function compareArrays (arrayA, arrayB, recursive = false) {
  if (!arrayA || !arrayB) {
    return false
  }

  // compare lengths - can save a lot of time
  if (arrayA.length !== arrayB.length) {
    return false
  }

  for (var i = 0, l = arrayA.length; i < l; i++) {
    // Check if we have nested arrays
    if (recursive && arrayA[i] instanceof Array && arrayB[i] instanceof Array) {
      // recurse into the nested arrays
      if (!compareArrays(arrayA[i], arrayB[i], recursive)) {
        return false
      }
    } else if (recursive && arrayA[i] instanceof Object && arrayB[i] instanceof Object) {
      // recurse into another objects
      // console.log("Recursing to compare ", this[propName],"with",object2[propName], " both named \""+propName+"\"");
      if (!compareObjects(arrayA[i], arrayB[i], recursive)) {
        return false
      }
    } else if (arrayA[i] !== arrayB[i]) {
      // Warning - two different object instances will never be equal: {x:20} != {x:20}
      return false
    }
  }
  return true
}

export function getDocumentaryPermission (viewConfiguration) {
  if (viewConfiguration && viewConfiguration.tabsConfiguration) {
    let permission = viewConfiguration.tabsConfiguration.find(tab => tab.code === buttonsHeaderPermisions.doc_manager && tab.permission === permissions.editable)
    if (permission !== undefined) {
      return true
    } else {
      return false
    }
  } else {
    return true
  }
}

export function compareObjectsWithStringify (obj1, obj2) {
  return JSON.stringify(obj1) === JSON.stringify(obj2)
}

// Source: https://stackoverflow.com/questions/7837456/how-to-compare-arrays-in-javascript
export function compareObjects (objectA, objectB, recursive = false) {
  let propName
  // For the first loop, we only check for types
  for (propName in objectA) {
    // Check for inherited methods and properties - like .equals itself
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Object/hasOwnProperty
    // Return false if the return value is different
    if (objectA.hasOwnProperty(propName) !== objectB.hasOwnProperty(propName)) {
      return false
    } else if (typeof objectA[propName] !== typeof objectB[propName]) {
      // Check instance type
      // Different types => not equal
      return false
    }
  }
  // Now a deeper check using other objects property names
  for (propName in objectB) {
    // We must check instances anyway, there may be a property that only exists in objectB
    // I wonder, if remembering the checked values from the first loop would be faster or not
    if (objectA.hasOwnProperty(propName) !== objectB.hasOwnProperty(propName)) {
      return false
    } else if (typeof objectA[propName] !== typeof objectB[propName]) {
      return false
    }
    // If the property is inherited, do not check any more (it must be equa if both objects inherit it)
    if (!objectA.hasOwnProperty(propName)) {
      continue
    }

    // Now the detail check and recursion

    // This returns the script back to the array comparing
    if (recursive && objectA[propName] instanceof Array && objectB[propName] instanceof Array) {
      // recurse into the nested arrays
      if (!compareArrays(objectA[propName], objectB[propName], recursive)) {
        return false
      }
    } else if (recursive && objectA[propName] instanceof Object && objectB[propName] instanceof Object) {
      // recurse into another objects
      // console.log("Recursing to compare ", objectA[propName],"with",objectB[propName], " both named \""+propName+"\"");
      if (!compareObjects(objectA[propName], objectB[propName], recursive)) {
        return false
      }
    } else if (objectA[propName] !== objectB[propName]) {
      // Normal value comparison for strings and numbers
      return false
    }
  }
  // If everything passed, let's say YES
  return true
}

export function sortByName (objectA, objectB) {
  if (objectA.name > objectB.name) return 1
  if (objectA.name < objectB.name) return -1
  return 0
}

export function additionObjectKeyInArray (array, key, toFixed = 2) {
  if (array && array.length) {
    return array.map((obj1) => obj1[key]).reduce((acc, val) => Number((acc + val).toFixed(toFixed)))
  }
}

export function upperToLowerCamelCase (string) {
  if (!string) return string
  return string.charAt(0).toLowerCase() + string.slice(1)
}

export function fromCamelToUnderscore (string) {
  return string.replace(/([A-Z])/g, ($1) => '_' + $1.toLowerCase())
}

export function formatInteger (number, thousandSeparator = '.') {
  let auxNumber = ('' + number).trim()
  if (auxNumber === '' || isNaN(auxNumber)) {
    // throw new Error('Parameter is not a number!')
    return ''
  }

  const symbol = auxNumber < 0 ? '-' : ''
  auxNumber = ('' + auxNumber).replace(symbol, '')
  const integerPart = auxNumber.split('.')[0]
  // let decimalPart = auxNumber.split('.')[1]

  auxNumber = integerPart
  for (let i = integerPart.length - 1, j = 1; i > 0; i--, j++) {
    if (j % 3 === 0) {
      auxNumber = auxNumber.slice(0, i) + thousandSeparator + auxNumber.slice(i, auxNumber.length)
    }
  }
  return symbol + auxNumber
}

export function formatMoney (number, decimals = 2, decimalSeparator = ',', thousandSeparator = '.', currencySymbol = '#') {
  const moneyFormatter = Intl.NumberFormat(currencyMoneyFormat[settings.currency].currency, currencyMoneyFormat[settings.currency].format)
  return isNaN(number) ? '' : moneyFormatter.format(number).replace(/(\d)(?=(\d{3})+(?!\d))/, '$1.')
}

export function formatPercent (number, decimals = 2, decimalSeparator = ',', currencySymbol = '%') {
  if (isNaN(number)) { return '' }
  number = parseFloat(number)
  if (number % 1 === 0) {
    return number + (decimals > 0 ? decimalSeparator + '0'.repeat(decimals) : '') + ' ' + currencySymbol
  } else {
    number = parseFloat(number)
    let auxNumber = ('' + number).trim()
    if (auxNumber === '' || isNaN(auxNumber)) {
      return ''
    }
    const symbol = auxNumber < 0 ? '-' : ''
    auxNumber = ('' + auxNumber).replace(symbol, '')
    const integerPart = auxNumber.split('.')[0]
    let decimalPart = auxNumber.split('.')[1] || ''

    auxNumber = integerPart

    if (decimalPart.length < decimals) {
      decimalPart = decimalPart + '0'.repeat(decimals - decimalPart.length)
    }

    return symbol + auxNumber + ((decimalPart && decimals > 0) ? decimalSeparator + decimalPart.slice(0, decimals) : '') + ' ' + currencySymbol
  }
}

export function formatNumber (number, sufixSymbol = '', decimals = 2, decimalSeparator = ',', thousandSeparator = '.') {
  let auxNumber = ('' + number).trim()
  if (auxNumber === '' || isNaN(auxNumber)) {
    // throw new Error('Parameter is not a number!')
    return ''
  }

  const symbol = auxNumber < 0 ? '-' : ''
  auxNumber = ('' + auxNumber).replace(symbol, '')
  const integerPart = auxNumber.split('.')[0]
  let decimalPart = auxNumber.split('.')[1] || ''

  auxNumber = integerPart
  for (let i = integerPart.length - 1, j = 1; i > 0; i--, j++) {
    if (j % 3 === 0) {
      auxNumber = auxNumber.slice(0, i) + thousandSeparator + auxNumber.slice(i, auxNumber.length)
    }
  }

  if (decimalPart.length < decimals) {
    decimalPart = decimalPart + '0'.repeat(decimals - decimalPart.length)
  }

  return symbol + auxNumber + ((decimalPart && decimals > 0) ? decimalSeparator + decimalPart.slice(0, decimals) : '') + sufixSymbol
}

export function transformApisSignErrors (value) {
  let keys = Object.keys(apiSignErrorMessages)
  let tag = null
  keys.forEach(element => {
    if (apiSignErrorMessages[element].value.trim().toUpperCase() === value.trim().toUpperCase()) {
      tag = apiSignErrorMessages[element].tag
      return tag
    }
  })
  return tag
}

export function formatDate (stringValue = null, emptyIfNull = false) {
  if (emptyIfNull && !stringValue) return ''
  var date = stringValue ? new Date(stringValue) : new Date()
  let day = ('0' + date.getDate()).slice(-2)
  let month = ('0' + (date.getMonth() + 1)).slice(-2)
  let year = date.getFullYear()
  let strdate = [day, month, year].join('/')
  return strdate
}

export function getTitleFromTreeSelectObject (tree, filter) {
  let value = ''
  if (Array.isArray(tree) && tree.length > 0) {
    tree.forEach(item => {
      if (item.value === filter) {
        value = item.title + ', '
      } else if (Array.isArray(item.children) && item.children.length > 0) {
        value = value === '' ? getTitleFromTreeSelectObject(item.children, filter) : value
      }
    })
  }
  return value
}

export function filterSalesmanByUOSelected (combo, salesmanArray) {
  if (Array.isArray(combo) && Array.isArray(salesmanArray) && salesmanArray.length > 0 && combo.length > 0) {
    let salesmanReturn = []
    salesmanArray.forEach(salesman => {
      if (combo.find(item => item.id === salesman)) salesmanReturn.push(salesman)
    })
    return salesmanReturn
  } else {
    return []
  }
}

export function getListOusFromTree (combo, ouSelectedId) {
  let ouList = []
  if (combo && combo.length > 0) {
    combo.forEach(item => {
      if (item.value && item.value === parseInt(ouSelectedId)) {
        let obj = { value: item.value, label: item.label }
        ouList.push(obj)
        if (item.children && item.children.length > 0) {
          item.children.forEach(child => {
            ouList = ouList.concat(getListOusFromTree(item.children, child.value))
          })
        }
      } else if (item.children && item.children.length > 0) {
        ouList = ouList.concat(getListOusFromTree(item.children, ouSelectedId))
      }
    })
  }
  return ouList
}

export function returnObjectWithoutNulls (item) {
  return Object.fromEntries(Object.entries(item).filter(([_, v]) => v != null))
}

export function transformObjectBooleanStringsProps (item) {
  const newObj = {}
  Object.entries(item).forEach(([k, v]) => {
    newObj[k] = v === 'true' ? true : v === 'false' ? false : v
  })

  return newObj
}

export function convertStringToDate (stringDate) {
  if (stringDate == null) { return null };
  var parts = stringDate.split('/')

  return new Date(parseInt(parts[2], 10),
    parseInt(parts[1], 10) - 1,
    parseInt(parts[0], 10))
}

export function differenceDaysDate (dateFrom, dateTo) {
  return Math.round((dateTo - dateFrom) / (1000 * 60 * 60 * 24))
}

export function toDayString () {
  var today = new Date()
  var dd = '01'
  var mm = String(today.getMonth() + 1).padStart(2, '0')
  var yyyy = today.getFullYear()
  today = dd + '/' + mm + '/' + yyyy
  return today
}

export function numberToWords (value, lang) {
  var writtenNumber = require('written-number')
  writtenNumber.i18n.ca = require('../translations/written-number-languages/ca.json')
  writtenNumber.i18n.es = require('../translations/written-number-languages/es.json')
  return writtenNumber(parseInt(value), { lang: lang })
}

export function numberToWordsI18n (lang) {
  if (lang === languages.CATALAN) {
    return { ...require('../translations/written-number-languages/ca.json') }
  } else if (lang === languages.SPANISH || lang === languages.SPANISH_MX) {
    return { ...require('../translations/written-number-languages/es.json') }
  }
}

export function capitalizeFirstLetter (value) {
  if (!value || value.length < 2) return value?.charAt(0)?.toUpperCase() ?? ''
  return value.charAt(0).toUpperCase() + value.slice(1)
}

export function getComboTranslatedFromEnum (t, tKey, enumImport) {
  let viewTypesCombo = Object.keys(enumImport).map(key => { return { id: enumImport[key], value: key } })
  return viewTypesCombo.map(x => { return { id: x.id, value: t(`${tKey}${x.value.toUpperCase()}`) } })
}

export function getDateNowMaxDatePicker () {
  const d = new Date()
  let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d)
  let mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d)
  let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d)
  return `${ye}-${mo}-${da}T12:00:00.000Z`
}

export function getCurrentDate () {
  const d = new Date()
  const date = [
    formatDateNumber(d.getDate()),
    formatDateNumber(d.getMonth() + 1),
    d.getFullYear()
  ].join('/') + ' ' + [
    formatDateNumber(d.getHours()),
    formatDateNumber(d.getMinutes()),
    formatDateNumber(d.getSeconds())
  ].join(':')

  return date
}

function formatDateNumber (number) {
  return number < 10 ? '0' + number : number
}

export function convertUTCDateToLocalDate (date) {
  var newDate = new Date(date)
  newDate.setMinutes(date.getMinutes() - date.getTimezoneOffset())
  return newDate
}

export function getDateNowMinDatePicker (addDay) {
  const d = new Date()
  if (addDay) {
    d.setDate(d.getDate() + 1)
  }
  let ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d)
  let mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d)
  let da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d)
  return `${ye}-${mo}-${da}T12:00:00.000Z`
}

export function compareValues (key, order = 'asc') {
  return function innerSort (a, b) {
    const hasA = a.hasOwnProperty(key)
    const hasB = b.hasOwnProperty(key)

    // Si uno de los objetos no tiene la propiedad
    if (!hasA && !hasB) return 0
    if (!hasA) return order === 'asc' ? 1 : -1
    if (!hasB) return order === 'asc' ? -1 : 1

    const varA = (typeof a[key] === 'string') ? a[key].toUpperCase() : a[key]
    const varB = (typeof b[key] === 'string') ? b[key].toUpperCase() : b[key]

    let comparison = 0
    if (varA > varB) {
      comparison = 1
    } else if (varA < varB) {
      comparison = -1
    }

    return (order === 'desc') ? (comparison * -1) : comparison
  }
}

export function formatDateTime (stringValue) {
  if (!stringValue) { return '' }
  var date = new Date(stringValue)
  let day = ('0' + date.getDate()).slice(-2)
  let month = ('0' + (date.getMonth() + 1)).slice(-2)
  let year = date.getFullYear()
  let strdate = [day, month, year].join('/')
  let hours = date.getHours()
  let minutes = date.getMinutes()
  let seconds = date.getSeconds()
  let strtime = [hours, minutes, seconds].join(':')
  return strdate + ' ' + strtime
}

export function isJsonString (str) {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

export function verifyLastAccessDate (lastAccessDate) {
  if (!lastAccessDate || lastAccessDate === undefined || lastAccessDate === null || lastAccessDate === '') {
    let date
    const today = new Date()
    date = today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear()
    date += ' ' + today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds() + ' ' + today.getMilliseconds()
    return date
  } else {
    return lastAccessDate
  }
}

export function getStockVoSectionByStage (stageId) {
  switch (stageId) {
    case stockVoStages.entry:
      return buttonsSidebar.entry
    case stockVoStages.reception:
      return buttonsSidebar.reception
    case stockVoStages.dossierRating:
      return buttonsSidebar.dossier_rating
    case stockVoStages.sendBudget || stockVoStages.acceptBudget:
      return buttonsSidebar.dossierBudget
    case stockVoStages.reconditioning:
      return buttonsSidebar.reconditioning
    case stockVoStages.cleaning:
      return buttonsSidebar.cleaning
    case stockVoStages.checklList:
      return buttonsSidebar.checklist
    case stockVoStages.bookingDelivery:
      return buttonsSidebar.booking_and_delivery
    default :
      return buttonsSidebar.photographs
  }
}
