import { put, call, select, takeEvery } from 'redux-saga/effects'
import { showLoader, hideLoader } from '../../../actions/common.js'
import mastersActionTypes from '../../../constants/actions/masters/masters'
import { getAuth } from '../../../selectors/access/auth'
import { handleError } from '../../errors/errorManager'
import { yesNoModal } from '../../modalsListeners/yesNoModal'

import {
  fetchOrganizedUnitSaleOperationListSuccess, openOrganizedUnitSaleOperationModalSuccess, closeOrganizedUnitSaleOperationMasterModal
} from '../../../actions/masters/organizedUnitSaleOperation/organizedUnitSaleOperation'
import { getOrganizedUnitSaleOperationFilter } from '../../../selectors/masters/organizedUnitSaleOperation'
import postOrganizedUnitSaleOperationList from '../../../services/organizedUnitSaleOperation/postOrganizedUnitSaleOperationList'
import postOrganizedUnitSaleOperation from '../../../services/organizedUnitSaleOperation/postOrganizedUnitSaleOperation'
import putOrganizedUnitSaleOperation from '../../../services/organizedUnitSaleOperation/putOrganizedUnitSaleOperation'
import deleteOrganizedUnitSaleOperationService from '../../../services/organizedUnitSaleOperation/deleteOrganizedUnitSaleOperation'

export function * fetchOrganizedUnitSaleOperationList ({ filter }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield filter = yield filter || select(getOrganizedUnitSaleOperationFilter)
    const organizedUnitSaleOperationList = yield call(postOrganizedUnitSaleOperationList, auth.token, filter)
    yield put(fetchOrganizedUnitSaleOperationListSuccess(organizedUnitSaleOperationList, filter))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * deleteOrganizedUnitSaleOperation (organizedUnitSaleOperationId) {
  try {
    const confirmed = yield call(yesNoModal, 'deleteOrganizedUnitSaleOperation')
    if (confirmed) {
      yield put(showLoader())
      const auth = yield select(getAuth)
      const filter = yield filter || select(getOrganizedUnitSaleOperationFilter)
      yield call(deleteOrganizedUnitSaleOperationService, auth.token, organizedUnitSaleOperationId)
      yield call(fetchOrganizedUnitSaleOperationList, filter)
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDeleteOrganizedUnitSaleOperation () {
  yield takeEvery(mastersActionTypes.DELETE_ORGANIZED_UNIT_SALE_OPERATION, deleteOrganizedUnitSaleOperation)
}

export function * submitOrganizedUnitSaleOperation ({ organizedUnitSaleOperation }) {
  try {
    const auth = yield select(getAuth)
    const filter = yield filter || select(getOrganizedUnitSaleOperationFilter)
    if (!organizedUnitSaleOperation.organizedUnitSaleOperationId) {
      const confirmed = yield call(yesNoModal, 'addOrganizedUnitSaleOperation')
      if (confirmed) {
        yield put(showLoader())
        yield call(postOrganizedUnitSaleOperation, auth.token, organizedUnitSaleOperation)
      }
    } else {
      const confirmed = yield call(yesNoModal, 'updateOrganizedUnitSaleOperation')
      if (confirmed) {
        yield put(showLoader())
        yield call(putOrganizedUnitSaleOperation, auth.token, organizedUnitSaleOperation)
      }
    }
    yield put(closeOrganizedUnitSaleOperationMasterModal())
    yield call(fetchOrganizedUnitSaleOperationList, filter)
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSubmitOrganizedUnitSaleOperation () {
  yield takeEvery(mastersActionTypes.SUBMIT_ORGANIZED_UNIT_SALE_OPERATION, submitOrganizedUnitSaleOperation)
}

export function * watchFetchOrganizedUnitSaleOperationList () {
  yield takeEvery(mastersActionTypes.FETCH_ORGANIZED_UNIT_SALE_OPERATION_LIST, fetchOrganizedUnitSaleOperationList)
}

export function * openOrganizedUnitSaleOperationModal ({ organizedUnitSaleOperation }) {
  try {
    yield put(showLoader())
    yield put(openOrganizedUnitSaleOperationModalSuccess(organizedUnitSaleOperation))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchOpenOrganizedUnitSaleOperationModal () {
  yield takeEvery(mastersActionTypes.OPEN_ORGANIZED_UNIT_SALE_OPERATION_MODAL, openOrganizedUnitSaleOperationModal)
}
