import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { getFormValues } from 'redux-form'
import DocumentalSearchMassiveDownloadForm from '../../../components/search/documentalSearch/DocumentalSearchMassiveDownloadForm'
import { fetchDocumentalSearchFormCombos, findFilterDocuments, resetFilters, setFilters, initializeSearch, documentalSearchTabChange, findFilterMassiveDownload, downloadMassiveFile, cancelMassiveFile } from '../../../actions/search/documentalSearch/documentalSearchForm'
import { translate } from 'react-polyglot'
import { fetchLowestOrganizedUnits, fetchSearchDossierSubType, fetchEntitySubTypeCombo, fetchDocumentRestrictedCombo } from '../../../actions/combos/combos'
import { openModal } from '../../../actions/common'

export function mapStateToProps (state, props) {
  return {
    ...state.documentalSearch,
    massiveDownloadStatusCombo: state.combos.massiveDownloadStatusCombo,
    formValues: getFormValues('documentalSearchMassiveDownloadForm')(state)
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchDocumentalSearchFormCombos,
      findFilterDocuments,
      fetchLowestOrganizedUnits,
      resetFilters,
      openModal,
      fetchSearchDossierSubType,
      setFilters,
      initializeSearch,
      fetchEntitySubTypeCombo,
      documentalSearchTabChange,
      fetchDocumentRestrictedCombo,
      findFilterMassiveDownload,
      downloadMassiveFile,
      cancelMassiveFile
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(DocumentalSearchMassiveDownloadForm))
