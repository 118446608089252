import React, { PureComponent } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

class TaskCard extends PureComponent {
  markTask (taskId, favoriteStatus) {
    this.props.switchCardTaskFavorite(taskId, favoriteStatus)
  }

  render () {
    const { t, urgencyLowLabel, urgencyMediumLabel, urgencyHighLabel } = this.props
    const tKey = 'DASHBOARD.'

    const FavoriteTooltip = (
      <Tooltip id="FavoriteTooltip">
        {t(`${tKey}CARD.MARK_UNMARK_CARDS`)}
      </Tooltip>
    )
    const percentageHigh = this.props.data?.countItems > 0 ? (this.props.data?.urgencyHigh * 100 / this.props.data?.countItems).toFixed(2) : 0
    const percentageMedium = this.props.data?.countItems > 0 ? (this.props.data?.urgencyMed * 100 / this.props.data?.countItems).toFixed(2) : 0
    const percentageLow = this.props.data?.countItems > 0 ? (this.props.data?.urgencyLow * 100 / this.props.data?.countItems).toFixed(2) : 0

    return (
      <div className='content-task'>
        <OverlayTrigger container={this} placement="bottom" overlay={FavoriteTooltip}>
          <a id='Favorite' onClick={() => this.markTask(this.props.data?.taskId, this.props.data?.isFavorite)} className={'marker' + (this.props.data?.isFavorite === true ? '' : ' disabled')} ><i className="iDocIcon-visibility-eye"></i></a>
        </OverlayTrigger>

        <div className='header'>
          <a href={'#/dashboard-tasks/' + this.props.data?.taskId} >{this.props.data?.name}</a>
          <div className='quantity'>
            <p>{this.props.data?.countItems}<span>{t(`${tKey}CARD.RESULTS`)}</span></p>
          </div>
        </div>
        <div className='content'>
          <div className='card'>
            <div className='cardBody'>
              <div className='totals'>
                <div className='totals'>
                  <div className='titles'>
                    <div className='left'>{t(`${tKey}CARD.PRIORITY`)}</div>
                    <div className='right'>{t(`${tKey}CARD.AMOUNT`)}</div>
                  </div>
                  <a className='data'>
                    <div className='left'>
                      <div className='graphic'>
                        <div className='text'>
                          <p>{urgencyHighLabel}</p>
                          <p>{percentageHigh}%</p>
                        </div>
                        <div className='line'>
                          <span className='quantity red' style={{ width: percentageHigh + '%' }}></span>
                        </div>
                      </div>
                    </div>
                    <div className='right'>{this.props.data?.urgencyHigh}</div>
                  </a>
                  <div className='data'>
                    <div className='left'>
                      <div className='graphic'>
                        <div className='text'>
                          <p>{urgencyMediumLabel}</p>
                          <p>{percentageMedium}%</p>
                        </div>
                        <div className='line'>
                          <span className='quantity yellow' style={{ width: percentageMedium + '%' }}></span>
                        </div>
                      </div>
                    </div>
                    <div className='right'>{this.props.data?.urgencyMed}</div>
                  </div>
                  <div className='data'>
                    <div className='left'>
                      <div className='graphic'>
                        <div className='text'>
                          <p>{urgencyLowLabel}</p>
                          <p>{percentageLow}%</p>
                        </div>
                        <div className='line'>
                          <span className='quantity green' style={{ width: percentageLow + '%' }}></span>
                        </div>
                      </div>
                    </div>
                    <div className='right'>{this.props.data?.urgencyLow}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='footer'></div>
      </div>
    )
  }
}
export default TaskCard
