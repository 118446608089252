import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { isInvalid, getFormSyncErrors, getFormValues, stopAsyncValidation } from 'redux-form'

import { translate } from 'react-polyglot'
import RegistrationDelivery from '../../../components/dossiers/registrationDelivery/RegistrationDelivery'
import RegistrationDelivery_v2 from '../../../_v2/components/dossiers/registrationDelivery/RegistrationDelivery'
import messageModalActions from '../../../actions/modals/messageModal'
import dossierRegistrationDeliveryActions from '../../../actions/dossier/registrationDelivery'
import { storeDossier, documentsToSendSignGetAll, openDocumentsToSendSign, sendDocumentToSign } from '../../../actions/dossier/commonDossierActions'
import commentsActions from '../../../actions/dossier/common/comments'
import clientActions from '../../../actions/dossier/common/client'
import vehicleActions from '../../../actions/dossier/common/vehicle'
import scrollerActions from '../../../actions/commons/scroller'
import buttonsHeaderActions from '../../../actions/dossier/common/buttonsHeader'
import buttonsSidebarActions from '../../../actions/dossier/common/buttonsSidebar'
import { openDocumentManagementModal as openDocumentModal } from '../../../actions/dossier/common/documentManagementModal'
import { openDocumentEditionModal, closeAddOrEditDocumentModal, signTypes } from '../../../actions/dossier/common/documentEditionModal'
import { putCancelDossier, undoCancelDossier, confirmCancelDossier } from '../../../actions/dossier/common/commonHeader'
import { fetchAndOpenReplacePurchaseModal } from '../../../actions/dossier/common/reassignReplacePurchaseModal'
import { fetchFilteredRelatedDossier } from '../../../actions/dossier/common/dossierRelatedDossiers'
import {
  fetchOperationType, fetchReasons, fetchCancelCombo, fetchAllReasons, fetchEntitySubTypeCombo, fetchEntityFilterSubTypeCombo, fetchVoCompaniesCombo,
  fetchHomologationClassificationCombo, fetchHomologationProducerCombo, fetchIaeCombo, fetchServiceDestinationTypes, fetchStockStatusCombo,
  fetchLowestOrganizedUnits, fecthOrganizedUnitsByDossierUo, fetchEntityCountryTypeCombo, fetchEntityStateTypeCombo, fetchCommercialSocietyTypeCombo,
  fetchEntityCityTypeCombo, fetchEntityTownTypeCombo, fetchEntityColonyTypeCombo, fetchEntityCPTypeCombo, fetchActivityBpro,
  fetchClientDataAddress
} from '../../../actions/combos/combos'
import { openModal as openCommonModal, yesNoModal } from '../../../actions/common'
import { getChangeUo, getSalesmanChangeUo, postUpdateChangeUo } from '../../../actions/dossier/common/changeUo'
import uacActions from '../../../actions/dossier/common/uac'
import {
  fetchCustomFields
} from '../../../actions/dossier/customFields'
import { validate } from '../../../actions/dossier/common/validations'
import { registerLastAccess } from '../../../actions/dossier/common/lastAccessDossier'
import { getStorageValue } from '../../../storage/storage'
import TrackingContainer from '../../tracking/trackingContainer'

export function mapStateToProps (state) {
  return {
    documentExport: state.documentExport.documents,
    vehicleProps: state.vehicle,
    editedCampaign: getFormValues('campaign_modal')(state),
    dossier: state.registrationDeliveryDossier,
    combos: state.combos,
    showManagementModal: state.documentaryManagementModal.showModal,
    uacModalForm: state.form.uacModal,
    windowWidth: state.windowDimensions.width,
    statusHeaderHeight: state.statusHeader.height,
    activeTab: state.buttonsHeader.activeTab,
    buttonsHeaderHeight: state.buttonsHeader.height,
    activeSection: state.buttonsSidebar.activeSection,
    relatedDossiersOrderBy: state.dossierRelatedDossiers.orderby,
    isInvalid: isInvalid('registrationDeliveryDossier')(state),
    formErrors: getFormSyncErrors('registrationDeliveryDossier')(state),
    formValues: getFormValues('registrationDeliveryDossier')(state),
    changeUo: state.changeUo,
    comment: state.comment,
    reason: state.reason,
    cancelCombo: state.combos.cancelCombo,
    auth: state.auth
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      ...uacActions,
      ...dossierRegistrationDeliveryActions,
      ...clientActions,
      ...vehicleActions,
      ...commentsActions,
      ...buttonsHeaderActions,
      ...buttonsSidebarActions,
      ...scrollerActions,
      openDocumentEditionModal,
      storeDossier,
      fetchOperationType,
      updateOptional: (dossierExtra, resolve, reject) => dossierRegistrationDeliveryActions.updateExtra(dossierExtra, resolve, reject, 'optionals'),
      createOptional: (dossierExtra, resolve, reject) => dossierRegistrationDeliveryActions.createExtra(dossierExtra, resolve, reject, 'optionals'),
      deleteOptional: (dossierExtra) => dossierRegistrationDeliveryActions.deleteExtra(dossierExtra, 'optionals'),
      openCommonModal,
      openDocumentModal,
      fetchAndOpenReplacePurchaseModal,
      fetchFilteredRelatedDossier,
      putCancelDossier,
      yesNoModal,
      ...messageModalActions,
      closeAddOrEditDocumentModal,
      fetchReasons,
      fetchAllReasons,
      undoCancelDossier,
      confirmCancelDossier,
      fetchEntitySubTypeCombo,
      fetchEntityFilterSubTypeCombo,
      fetchVoCompaniesCombo,
      fetchHomologationClassificationCombo,
      fetchHomologationProducerCombo,
      fetchIaeCombo,
      fetchServiceDestinationTypes,
      fetchStockStatusCombo,
      documentsToSendSignGetAll,
      openDocumentsToSendSign,
      sendDocumentToSign,
      signTypes,
      fetchLowestOrganizedUnits,
      getChangeUo,
      getSalesmanChangeUo,
      postUpdateChangeUo,
      fecthOrganizedUnitsByDossierUo,
      fetchCancelCombo,
      fetchCommercialSocietyTypeCombo,
      fetchEntityCountryTypeCombo,
      fetchEntityStateTypeCombo,
      fetchEntityCityTypeCombo,
      fetchEntityTownTypeCombo,
      fetchEntityColonyTypeCombo,
      fetchEntityCPTypeCombo,
      fetchActivityBpro,
      fetchCustomFields,
      validate,
      stopAsyncValidation,
      fetchClientDataAddress,
      registerLastAccess
    }, dispatch)
  }
}
let version = parseInt(getStorageValue('interfaceVersion'))
var decoratedComponent = null
if (version === 2) {
  decoratedComponent = TrackingContainer(RegistrationDelivery_v2, true)
} else {
  decoratedComponent = TrackingContainer(RegistrationDelivery, true)
}
export default connect(mapStateToProps, mapDispatchToProps)(translate()(decoratedComponent))
