import mastersActionTypes from '../../../constants/actions/masters/masters'

function initialState () {
  return {
    sleType: null,
    saleTypeComponent: null,
    saleTypeList: [],
    typeProductsCombo: [],
    saleTypeCount: 0,
    pagesCount: 0,
    filter: {
      page: 1,
      orderBy: '',
      pageSize: 10,
      code: null,
      name: null
    },
    saleTypeModal: {
      showModal: false,
      saleType: null,
      saleTypeId: null,
      languageList: [],
      saleTypeLanguage: null
    },
    duplicateSaleTypeModal: {
      showModal: false,
      saleTypeId: null,
      languageList: []
    }
  }
}

function fetchSaleTypeListSuccess (state, { saleTypeMastersList, filter }) {
  return {
    ...state,
    saleTypeList: saleTypeMastersList.saleTypeList,
    saleTypeCount: saleTypeMastersList.count,
    pagesCount: saleTypeMastersList.pages,
    filter: filter
  }
}

function openSaleTypeModalSuccess (state, { saleTypeId, saleType, languageList }) {
  const initial = initialState()
  return {
    ...state,
    saleTypeModal: {
      ...initial.saleTypeModal,
      showModal: true,
      saleTypeId: saleTypeId,
      saleType: saleType,
      languageList: languageList,
      saleTypeLanguage: saleType ? saleType.saleTypeLanguages : null
    }
  }
}

function closeEditSaleTypeModal (state) {
  const initial = initialState()
  return {
    ...state,
    saleTypeModal: {
      ...initial.saleTypeModal
    }
  }
}

function saveSaleTypeModalSuccess (state) {
  const initial = initialState()
  return {
    ...state,
    saleTypeModal: {
      ...initial.saleTypeModal
    }
  }
}

function openDuplicateSaleTypeModalSuccess (state, { saleTypeId, languageList }) {
  const initial = initialState()
  return {
    ...state,
    duplicateSaleTypeModal: {
      ...initial.duplicateSaleTypeModal,
      showModal: true,
      saleTypeId: saleTypeId,
      languageList: languageList
    }
  }
}

function closeDuplicateSaleTypeModal (state) {
  const initial = initialState()
  return {
    ...state,
    duplicateSaleTypeModal: {
      ...initial.duplicateSaleTypeModal
    }
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case mastersActionTypes.FETCH_SALE_TYPE_SUCCESS_LIST:
      return fetchSaleTypeListSuccess(state, action)
    case mastersActionTypes.OPEN_SALE_TYPE_MODAL_SUCCESS:
      return openSaleTypeModalSuccess(state, action)
    case mastersActionTypes.CLOSE_EDIT_SALE_TYPE_MODAL:
      return closeEditSaleTypeModal(state, action)
    case mastersActionTypes.SAVE_SALE_TYPE_SUCCESS:
      return saveSaleTypeModalSuccess(state, action)
    case mastersActionTypes.OPEN_DUPLICATE_SALE_TYPE_MODAL_SUCCESS:
      return openDuplicateSaleTypeModalSuccess(state, action)
    case mastersActionTypes.CLOSE_DUPLICATE_SALE_TYPE_MODAL:
      return closeDuplicateSaleTypeModal(state, action)
    default:
      return state
  }
}
