import React, { PureComponent } from 'react'
import { Panel, Row, Tooltip, OverlayTrigger, Glyphicon } from 'react-bootstrap'
import InputSelect from '../../commons/form/InputSelect'
import InputText from '../../commons/form/InputText'
import InputDatePicker from '../../commons/form/InputDatePicker'
import SimpleInputText from '../../commons/simpleForm/SimpleInputText'
import SimpleSelect from '../../commons/simpleForm/SimpleSelect'
import OrderModalPage from '../../../containers/dossiers/common/vehicle/OrderModalPage'
import { Field } from 'redux-form'
import { validateDate } from '../../../util/validationFunctions'
import { multipleNormalize, toUpperCase, trimAll } from '../../../util/normalizeFunctions'
import { compareObjects } from '../../../util/utils'
import { getFieldsPermisions } from '../../../util/permisionFunctions'
import { permissions } from '../../../constants/backendIds'
import { dossierTypes as dossierTypesConstant } from '../../../constants/dossier/common/dossierTypes'
import { dossierSubTypeId as dossierSubTypeIdConstants } from '../../../constants/dossier/common/dossierSubType'
import SaleRequestFields from '../../dossiers/common/saleRequestFields/SaleRequestFields'
import InputCheckbox from '../../commons/form/InputCheckBox'
import CustomFields from '../../commons/customFields/CustomFields'

class Vehicle extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      open: false,
      orderOpen: true,
      justOpened: true,
      fieldsConfiguration: {
        dealerNumber: null,
        vinChassis: null,
        vinChassisLeft: null,
        vinChassisRight: null,
        licensePlate: null,
        comercialCode: null,
        brandId: null,
        model: null,
        color: null,
        tapestry: null,
        fuelId: null,
        firstLicensePlateDate: null,
        makerNumber: null,
        availabilityDate: null,
        expirationItvDate: null,
        originId: null,
        destinationId: null,
        brandReference: null,
        originalLicensePlate: null,
        countryId: null,
        family: null,
        disabledValidateLicensePlate: null,
        carlineId: null,
        catalogueId: null,
        modelId: null,
        colorId: null,
        tapestryId: null,
        catalogue: null,
        carline: null,
        modelYear: null,
        provisionalLicensePlate: null
      }
    }
  }

  initializePermisions (props) {
    const fieldKeys = Object.keys(this.state.fieldsConfiguration)
    const newState = getFieldsPermisions(props.componentConfiguration.fieldsConfiguration, fieldKeys, props.componentConfiguration.permission)
    this.setState({ ...this.state, ...newState })
  }

  UNSAFE_componentWillMount () {
    this.initializePermisions(this.props)
  }

  componentDidMount () {
    if (this.props.actions.fecthFamily !== null && this.props.actions.fecthFamily !== undefined) {
      this.props.actions.fecthFamily()
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.isVehicleLoaded === false && this.state.open) {
      this.loadVehicle()
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.open) {
      this.setState({ open: true })
    }
    if (nextProps.dossierId && !compareObjects(this.props.componentConfiguration || {}, nextProps.componentConfiguration, true)) {
      this.initializePermisions(nextProps)
    }
    if ((nextProps.formValues && this.props.formValues && nextProps.formValues.comercialCode && this.props.formValues.comercialCode && this.props.formValues.comercialCode !== nextProps.formValues.comercialCode && this.props.codeVehicleConfiguration) ||
      (!this.props.formValues && nextProps.formValues && nextProps.formValues.comercialCode && this.props.codeVehicleConfiguration) ||
      (this.props.comercialCode && nextProps.comercialCode && this.props.comercialCode !== nextProps.comercialCode && this.props.codeVehicleConfiguration) ||
      (!this.props.comercialCode && nextProps.comercialCode && this.props.codeVehicleConfiguration) && this.props.dossierSubType !== dossierSubTypeIdConstants.tramite) {
      this.props.actions.fetchComboOrigin(nextProps.comercialCode, this.props.dossierSubType)
      this.props.actions.fetchComboDestination(nextProps.comercialCode, this.props.dossierSubType)
    }
    if (this.state.justOpened && nextProps.dossierWithVehicle) {
      this.setState({ orderOpen: false })
      this.state.justOpened = false
    }
  }

  fetchSalesDossierPromiseVehicle () {
    if (this.state.open) {
      this.props.actions.setActiveSection(false)
    }
    this.setState({
      open: !this.state.open
    })
    if (this.props.isVehicleLoaded === false) {
      this.loadVehicle()
    }
  }

  loadVehicle () {
    return new Promise((resolve, reject) => {
      this.props.handleToUpdateComponent(this.props)
      if (this.props.dossierType === dossierTypesConstant.sales) {
        return this.props.actions.fetchSalesVehicleHistorical(this.props.dossierId, resolve, reject)
      } else if (this.props.dossierType === dossierTypesConstant.purchase) {
        return this.props.actions.fetchPurchaseVehicleHistorical(this.props.dossierId, resolve, reject)
      } else if (this.props.dossierType === dossierTypesConstant.paperwork) {
        return this.props.actions.fetchPaperworkVehicleHistorical(this.props.dossierId, resolve, reject)
      } else if (this.props.dossierType === dossierTypesConstant.campaign) {
        return this.props.actions.fetchCampaignVehicleHistorical(this.props.dossierId, resolve, reject)
      } else if (this.props.dossierType === dossierTypesConstant.registrationDelivery) {
        return this.props.actions.fetchRegistrationDeliveryVehicleHistorical(this.props.dossierId, resolve, reject)
      } else if (this.props.dossierType === dossierTypesConstant.service) {
        this.props.actions.fetchServiceDossierVehicleHistorical(this.props.dossierId, resolve, reject, this.props.pristine)
      } else if (this.props.dossierType === dossierTypesConstant.stock) {
        this.props.actions.fetchStockDossierVehicleHistorical(this.props.dossierId, resolve, reject, this.props.pristine)
      }
    })
  }

  render () {
    const {
      t, dossierSubType, order, dossierType, purchaseOrderExetendedMode, dossierParentSubTypeId, customFields,
      /* combos: {brandCombo, fuelCombo, previousUsageCombo}, */
      combos: {
        brandCombo, fuelCombo, vehicleOriginCombo, purchaseTypeVnCombo, entityTransferCombo, vehicleDestinationCombo, countries, familyCombo,
        carlineCombo, catalogueCombo, modelCombo, colorCombo, tapestryCombo
      },
      parentDossierType: { isPurchase },
      codeVehicleConfiguration, purchaseType
    } = this.props
    const isVN = dossierSubType === dossierSubTypeIdConstants.compra_vn || dossierSubType === dossierSubTypeIdConstants.venta_vn || dossierSubType === dossierSubTypeIdConstants.registration_delivery ||
    ((dossierSubType === dossierSubTypeIdConstants.tramite || dossierSubType === dossierSubTypeIdConstants.campanya || dossierSubType === dossierSubTypeIdConstants.service) &&
     (dossierParentSubTypeId === dossierSubTypeIdConstants.compra_vn || dossierParentSubTypeId === dossierSubTypeIdConstants.venta_vn || dossierParentSubTypeId === dossierSubTypeIdConstants.registration_delivery || !dossierParentSubTypeId))

    const tKey = 'DOSSIER_COMPONENTS.VEHICLE.'
    const editTooltip = (<Tooltip id="editTooltip">{t(`${tKey}TOOLTIP_VALIDATE_LICENSE_PLATE`)}</Tooltip>)
    return (
      <div className="vehicle-panel dossier-panel">
        <div className="header-panel" onClick={this.fetchSalesDossierPromiseVehicle.bind(this)}>
          <div className="name-wrapper">
            <i className="ico-car"/>
            <h4>{t(`${tKey}TITLE`)}</h4>
          </div>
          <i className={this.state.open ? 'ico-arrow-black-up' : 'ico-arrow-black-down'}/>
        </div>
        <OrderModalPage
          readOnlyFields={true}
          dossierType = { dossierType }
        />
        <Panel expanded={this.state.open} onToggle={() => {}}>
          <Panel.Collapse>
            {order && (
              <Row>
                <div className="search-form order-vehicle-dossier">
                  <div className="vehicle-order-panel dossier-panel">
                    <div className="header-panel" onClick={ () => this.setState({ orderOpen: !this.state.orderOpen })}>
                      <span className="title-search"><i className="ico-search" /> {t(`${tKey}ORDER_TITLE`)}</span>
                      <i className={this.state.orderOpen ? 'ico-arrow-black-up' : 'ico-arrow-black-down'}/>
                    </div>
                    <Panel expanded={this.state.orderOpen} onToggle={() => {}}>
                      <Panel.Collapse>
                        <Row className='flexRow'>
                          <SimpleSelect
                            colSm={3}
                            controlLabel={t(`${tKey}BRAND`)}
                            value={order.brandId}
                            options={brandCombo}
                            valueKey="id"
                            labelKey="value"
                            disabled={true}
                          />
                          {this.state.fieldsConfiguration.carlineId.permission !== permissions.hidden && (
                            <Field
                              id="order_carlineId"
                              name="order.carlineId"
                              colSm={3}
                              controlLabel={t(`${tKey}CARLINE`)}
                              placeholder={t(`${tKey}CARLINE`)}
                              component={InputSelect}
                              valueKey="id"
                              labelKey="value"
                              options={carlineCombo}
                              disabled={true}
                              menuPlacement="auto"
                            />
                          )}
                          {this.state.fieldsConfiguration.carline.permission !== permissions.hidden && (
                            <Field
                              id="order_carline"
                              name="order.carline"
                              colSm={3}
                              controlLabel={t(`${tKey}CARLINE`)}
                              placeholder={t(`${tKey}CARLINE`)}
                              component={InputText}
                              disabled={true}
                              validate={this.required}
                            />)}
                          {this.state.fieldsConfiguration.catalogueId.permission !== permissions.hidden && (
                            <Field
                              id="order_catalogueId"
                              name="order.catalogueId"
                              colSm={3}
                              controlLabel={t(`${tKey}CATALOGUE`)}
                              placeholder={t(`${tKey}CATALOGUE`)}
                              component={InputSelect}
                              valueKey="id"
                              labelKey="value"
                              options={catalogueCombo}
                              disabled={true}
                              menuPlacement="auto"
                            />
                          )}
                          {this.state.fieldsConfiguration.catalogue.permission !== permissions.hidden && (
                            <Field
                              id="order_catalogue"
                              name="order.catalogue"
                              colSm={3}
                              controlLabel={t(`${tKey}CATALOGUE`)}
                              placeholder={t(`${tKey}CATALOGUE`)}
                              component={InputText}
                              disabled={true}
                              validate={this.required}
                            />)}
                          {this.state.fieldsConfiguration.modelId.permission !== permissions.hidden && (
                            <Field
                              id="order_modelId"
                              name="order.modelId"
                              colSm={3}
                              controlLabel={t(`${tKey}MODEL_YEAR`)}
                              placeholder={t(`${tKey}MODEL_YEAR`)}
                              component={InputSelect}
                              valueKey="id"
                              labelKey="value"
                              options={modelCombo}
                              disabled={true}
                              menuPlacement="auto"
                            />
                          )}
                          {this.state.fieldsConfiguration.modelYear.permission !== permissions.hidden && (
                            <Field
                              id="order_modelYear"
                              name="order.modelYear"
                              colSm={3}
                              controlLabel={t(`${tKey}MODEL_YEAR`)}
                              placeholder={t(`${tKey}MODEL_YEAR`)}
                              component={InputText}
                              disabled={true}
                              validate={this.required}
                            />)}
                          {this.state.fieldsConfiguration.colorId.permission !== permissions.hidden && (
                            <Field
                              id="order_colorId"
                              name="order.colorId"
                              colSm={3}
                              controlLabel={t(`${tKey}COLOR`)}
                              placeholder={t(`${tKey}COLOR`)}
                              component={InputSelect}
                              valueKey="id"
                              labelKey="value" options={colorCombo}
                              disabled={true}
                            />
                          )}

                          <SimpleInputText
                            colSm={3}
                            controlLabel={t(`${tKey}MODEL`)}
                            value={order.model}
                            disabled={true}
                          />
                          <SimpleInputText
                            colSm={3}
                            controlLabel={t(`${tKey}COLOR`)}
                            value={order.color}
                            disabled={true}
                          />
                          {this.state.fieldsConfiguration.tapestryId.permission !== permissions.hidden && (
                            <Field
                              id="order_tapestryId"
                              name="order.tapestryId"
                              colSm={3}
                              controlLabel={t(`${tKey}TAPESTRY`)}
                              placeholder={t(`${tKey}TAPESTRY`)}
                              component={InputSelect}
                              valueKey="id"
                              labelKey="value"
                              options={tapestryCombo}
                              disabled={true}
                            />
                          )}
                          <SimpleInputText
                            colSm={3}
                            controlLabel={t(`${tKey}TAPESTRY`)}
                            value={order.tapestry}
                            disabled={true}
                          />
                        </Row>
                        <Row className='flexRow'>
                          {purchaseOrderExetendedMode && (
                            <SaleRequestFields
                              t={t}
                              readOnlyFields={true}
                              purchaseTypeVnCombo={purchaseTypeVnCombo}
                              entityTransferCombo={entityTransferCombo}
                              namePrefix={'order.'}
                              order={order}
                              fieldSize={0}
                              className="col-sm-3"
                            />
                          )}
                          <SimpleInputText
                            colSm={3}
                            controlLabel={t(`${tKey}COMMERCIAL_CODE`)}
                            value={order.comercialCode}
                            disabled={true}
                          />
                          <SimpleSelect
                            colSm={3}
                            controlLabel={t(`${tKey}FUEL`)}
                            value={order.fuelId}
                            options={fuelCombo}
                            valueKey="id"
                            labelKey="value"
                            disabled={true}
                          />
                        </Row>
                      </Panel.Collapse>
                    </Panel>
                  </div>
                </div>
              </Row>
            )}
            {(this.props.dossierWithVehicle || (!order && !this.props.dossierWithVehicle)) &&
            <Row>
              {dossierType !== dossierTypesConstant.purchase && this.state.fieldsConfiguration.dealerNumber.permission !== permissions.hidden && (
                <Field
                  id="dealerNumber"
                  name="vehicleComponent.dealerNumber"
                  colSm={4}
                  controlLabel={t(`${tKey}DEALER_NUMBER`)}
                  placeholder={t(`${tKey}DEALER_NUMBER`)}
                  component={InputText}
                  disabled={true}
                />
              )}
              {this.state.fieldsConfiguration.vinChassis.permission !== permissions.hidden && (
                <Field
                  id="vinChassis"
                  name="vehicleComponent.vinChassis"
                  colSm={4}
                  controlLabel={t(`${tKey}CHASSIS`)}
                  placeholder={t(`${tKey}CHASSIS`)}
                  component={InputText}
                  disabled={true}
                  minLength={17}
                  maxLength={17}
                  normalize={value => multipleNormalize(value, [toUpperCase, trimAll])}
                />
              )}
              {this.state.fieldsConfiguration.licensePlate.permission !== permissions.hidden && (
                <Field
                  id="licensePlate"
                  name="vehicleComponent.licensePlate"
                  colSm={4}
                  controlLabel={t(`${tKey}LICENSE_PLATE`)}
                  placeholder={t(`${tKey}LICENSE_PLATE`)}
                  component={InputText}
                  disabled={true}
                  normalize={value => multipleNormalize(value, [toUpperCase, trimAll])}
                />
              )}
              {this.state.fieldsConfiguration.comercialCode.permission !== permissions.hidden && (
                <Field
                  id="comercialCode"
                  name="vehicleComponent.comercialCode"
                  colSm={4}
                  controlLabel={t(`${tKey}COMMERCIAL_CODE`)}
                  placeholder={t(`${tKey}COMMERCIAL_CODE`)}
                  component={InputText}
                  disabled={true}
                />
              )}
              {this.state.fieldsConfiguration.brandId.permission !== permissions.hidden && (
                <Field
                  id="brandId"
                  name="vehicleComponent.brandId"
                  colSm={4}
                  controlLabel={t(`${tKey}BRAND`)}
                  placeholder={t(`${tKey}BRAND`)}
                  component={InputSelect}
                  options={brandCombo}
                  valueKey="id"
                  labelKey="value"
                  disabled={true}
                />
              )}
              {this.state.fieldsConfiguration.carlineId.permission !== permissions.hidden && isVN && (
                <Field
                  id="carlineId"
                  name="vehicleComponent.carlineId"
                  colSm={4}
                  controlLabel={t(`${tKey}CARLINE`)}
                  placeholder={t(`${tKey}CARLINE`)}
                  component={InputSelect}
                  valueKey="id"
                  labelKey="value"
                  options={carlineCombo}
                  disabled={true}
                  menuPlacement="auto"
                />
              )}

              {this.state.fieldsConfiguration.carline.permission !== permissions.hidden && !isVN && (
                <Field
                  id="carline"
                  name="vehicleComponent.carline"
                  colSm={4}
                  controlLabel={t(`${tKey}CARLINE`)}
                  placeholder={t(`${tKey}CARLINE`)}
                  component={InputText}
                  disabled={true}
                />
              )}

              {this.state.fieldsConfiguration.catalogueId.permission !== permissions.hidden && isVN && (
                <Field
                  id="catalogueId"
                  name="vehicleComponent.catalogueId"
                  colSm={4}
                  controlLabel={t(`${tKey}CATALOGUE`)}
                  placeholder={t(`${tKey}CATALOGUE`)}
                  component={InputSelect}
                  valueKey="id"
                  labelKey="value"
                  options={catalogueCombo}
                  disabled={true}
                  menuPlacement="auto"
                />
              )}

              {this.state.fieldsConfiguration.catalogue.permission !== permissions.hidden && !isVN && (
                <Field
                  id="catalogue"
                  name="vehicleComponent.catalogue"
                  colSm={4}
                  controlLabel={t(`${tKey}CATALOGUE`)}
                  placeholder={t(`${tKey}CATALOGUE`)}
                  component={InputText}
                  disabled={true}
                />
              )}

              {this.state.fieldsConfiguration.modelId.permission !== permissions.hidden && isVN && (
                <Field
                  id="modelId"
                  name="vehicleComponent.modelId"
                  colSm={4}
                  controlLabel={t(`${tKey}MODEL_YEAR`)}
                  placeholder={t(`${tKey}MODEL_YEAR`)}
                  component={InputSelect}
                  valueKey="id"
                  labelKey="value"
                  options={modelCombo}
                  disabled={true}
                  menuPlacement="auto"

                />
              )}
              {this.state.fieldsConfiguration.modelYear.permission !== permissions.hidden && !isVN && (
                <Field
                  id="modelYear"
                  name="vehicleComponent.modelYear"
                  colSm={4}
                  controlLabel={t(`${tKey}MODEL_YEAR`)}
                  placeholder={t(`${tKey}MODEL_YEAR`)}
                  component={InputText}
                  disabled={true}
                />
              )}
              {this.state.fieldsConfiguration.model.permission !== permissions.hidden && (
                <Field
                  id="model"
                  name="vehicleComponent.model"
                  colSm={4}
                  controlLabel={t(`${tKey}MODEL`)}
                  placeholder={t(`${tKey}MODEL`)}
                  component={InputText}
                  disabled={true}
                />
              )}
              {this.state.fieldsConfiguration.colorId.permission !== permissions.hidden && isVN && (
                <Field
                  id="colorId"
                  name="vehicleComponent.colorId"
                  colSm={4}
                  controlLabel={t(`${tKey}COLOR`)}
                  placeholder={t(`${tKey}COLOR`)}
                  component={InputSelect}
                  valueKey="id"
                  labelKey="value" options={colorCombo}
                  disabled={true}
                />
              )}
              {this.state.fieldsConfiguration.tapestryId.permission !== permissions.hidden && isVN && (
                <Field
                  id="tapestryId"
                  name="vehicleComponent.tapestryId"
                  colSm={4}
                  controlLabel={t(`${tKey}TAPESTRY`)}
                  placeholder={t(`${tKey}TAPESTRY`)}
                  component={InputSelect}
                  valueKey="id"
                  labelKey="value"
                  options={tapestryCombo}
                  disabled={true}
                />
              )}
              {this.state.fieldsConfiguration.color.permission !== permissions.hidden && (
                <Field
                  id="color"
                  name="vehicleComponent.color"
                  colSm={4}
                  controlLabel={t(`${tKey}COLOR`)}
                  placeholder={t(`${tKey}COLOR`)}
                  component={InputText}
                  disabled={true}
                />
              )}
              {this.state.fieldsConfiguration.tapestry.permission !== permissions.hidden && (
                <Field
                  id="tapestry"
                  name="vehicleComponent.tapestry"
                  colSm={4}
                  controlLabel={t(`${tKey}TAPESTRY`)}
                  placeholder={t(`${tKey}TAPESTRY`)}
                  component={InputText}
                  disabled={true}
                />
              )}
              {this.state.fieldsConfiguration.fuelId.permission !== permissions.hidden && (
                <Field
                  colSm={4}
                  id="fuelId"
                  menuContainerStyle={{ zIndex: 999 }}
                  name="vehicleComponent.fuelId"
                  controlLabel={t(`${tKey}FUEL`)}
                  placeholder={t(`${tKey}FUEL`)}
                  component={InputSelect}
                  options={fuelCombo}
                  valueKey="id"
                  labelKey="value"
                  disabled={true}
                />
              )}
              {this.state.fieldsConfiguration.brandReference.permission !== permissions.hidden && (
                <Field
                  colSm={4}
                  id="brandReference"
                  name="vehicleComponent.brandReference"
                  controlLabel={t(`${tKey}BRAND_REFERENCE`)}
                  placeholder={t(`${tKey}BRAND_REFERENCE`)}
                  component={InputText}
                  disabled={true}
                />
              )}
              {this.state.fieldsConfiguration.family.permission !== permissions.hidden && (
                <Field
                  colSm={4}
                  id="familyId"
                  menuContainerStyle={{ zIndex: 999 }}
                  name="vehicleComponent.familyId"
                  controlLabel={t(`${tKey}FAMILY`)}
                  placeholder={t(`${tKey}FAMILY`)}
                  component={InputSelect}
                  options={familyCombo}
                  valueKey="familyId"
                  labelKey="name"
                  disabled= {true}
                />
              )}
              {this.state.fieldsConfiguration.firstLicensePlateDate.permission !== permissions.hidden && (
                <Field
                  id="firstLicensePlateDate"
                  name="vehicleComponent.firstLicensePlateDate"
                  colSm={4}
                  controlLabel={t(`${tKey}FIRST_LPLATE_DATE`)}
                  disabled={true}
                  component={InputDatePicker}
                  validate={(value, allValues, props) => {
                    const result = validateDate(value, props)
                    return result === Object(result) ? undefined : result
                  }}
                />
              )}
              {!isPurchase && [
                this.state.fieldsConfiguration.makerNumber.permission !== permissions.hidden && (
                  <Field
                    id="vehicleMakerNumber"
                    name="vehicleComponent.makerNumber"
                    key="vehicleComponent.makerNumber"
                    colSm={4}
                    controlLabel={t(`${tKey}MAKER_ORDER_NUMBER`)}
                    placeholder={t(`${tKey}MAKER_ORDER_NUMBER`)}
                    component={InputText}
                    disabled={true}
                  />
                ),
                this.state.fieldsConfiguration.availabilityDate.permission !== permissions.hidden && (
                  <Field
                    id="availabilityDate"
                    name="vehicleComponent.availabilityDate"
                    key="vehicleComponent.availabilityDate"
                    colSm={4}
                    controlLabel={t(`${tKey}AVAILABILITY_DATE`)}
                    disabled={true}
                    component={InputDatePicker}
                    validate={(value, allValues, props) => {
                      const result = validateDate(value, props)
                      return result === Object(result) ? undefined : result
                    }}
                  />
                )
              ]}
              {this.state.fieldsConfiguration.provisionalLicensePlate.permission !== permissions.hidden && (
                <Field
                  colSm={4}
                  id="provisionalLicensePlate"
                  name="vehicleComponent.provisionalLicensePlate"
                  controlLabel={t(`${tKey}PROVISIONAL_LICENSE_PLATE`)}
                  placeholder={t(`${tKey}PROVISIONAL_LICENSE_PLATE`)}
                  component={InputText}
                  disabled={true}
                />
              )}
              {
                (dossierSubType === dossierSubTypeIdConstants.venta_vo || dossierSubType === dossierSubTypeIdConstants.compra_vo) &&
                this.state.fieldsConfiguration.expirationItvDate.permission !== permissions.hidden && (
                  <Field
                    id="expirationItvDate"
                    name="vehicleComponent.expirationItvDate"
                    colSm={4}
                    controlLabel={t(`${tKey}EXPIRATION_ITV_DATE`)}
                    component={InputDatePicker}
                    validate={(value, allValues, props) => {
                      const result = validateDate(value, props)
                      return result === Object(result) ? undefined : result
                    }}
                    disabled={true}
                  />
                )
              }
              {
                codeVehicleConfiguration && this.state.fieldsConfiguration.destinationId.permission !== permissions.hidden && [(
                  <Field
                    colSm={4}
                    id="originId"
                    key="codeOrigin"
                    name="vehicleComponent.originId"
                    valueKey="id"
                    labelKey="value"
                    controlLabel={t(`${tKey}ORIGIN_CLASIFICATION`)}
                    placeholder={t(`${tKey}ORIGIN_CLASIFICATION`)}
                    component={InputSelect}
                    options={vehicleOriginCombo}
                    disabled={true}
                  />),
                (<Field
                  colSm={4}
                  id="destinationId"
                  name="vehicleComponent.destinationId"
                  key="codeDestination"
                  valueKey="id"
                  labelKey="value"
                  controlLabel={t(`${tKey}DESTINATION_CLASIFICATION`)}
                  placeholder={t(`${tKey}DESTINATION_CLASIFICATION`)}
                  component={InputSelect}
                  options={vehicleDestinationCombo}
                  disabled={true}
                />
                )]
              }
              {
                (dossierSubType === dossierSubTypeIdConstants.compra_vo && purchaseType === 10) &&
                this.state.fieldsConfiguration.originalLicensePlate.permission !== permissions.hidden && (
                  <Field
                    id="originalLicensePlate"
                    name="vehicleComponent.originalLicensePlate"
                    colSm={4}
                    controlLabel={t(`${tKey}ORIGINAL_LICENSE_PLATE`)}
                    placeholder={t(`${tKey}ORIGINAL_LICENSE_PLATE`)}
                    component={InputText}
                    disabled={true}
                  />
                )
              }
              {
                (dossierSubType === dossierSubTypeIdConstants.compra_vo && purchaseType === 10) &&
                this.state.fieldsConfiguration.countryId.permission !== permissions.hidden && (
                  <Field
                    colSm={4}
                    id="countryId"
                    name="vehicleComponent.countryId"
                    key="countryId"
                    valueKey="countryId"
                    labelKey="name"
                    controlLabel={t(`${tKey}ORIGIN_COUNTRY`)}
                    placeholder={t(`${tKey}ORIGIN_COUNTRY`)}
                    component={InputSelect}
                    options={countries}
                    disabled={true}
                  />
                )
              }
            </Row>
            }
            {
              <Row>
                {this.state.fieldsConfiguration.disabledValidateLicensePlate.permission !== permissions.hidden && (
                  <OverlayTrigger placement="right" overlay={editTooltip}
                    colSm={1}>
                    <Glyphicon glyph="info-sign"/>
                  </OverlayTrigger>
                )}
                {this.state.fieldsConfiguration.disabledValidateLicensePlate.permission !== permissions.hidden && (
                  <Field
                    colSm={3}
                    id="disabledValidateLicensePlate"
                    name="vehicleComponent.disabledValidateLicensePlate"
                    placeholder={t(`${tKey}DISABLED_VALIDATE_LICENSE_PLATE`)}
                    component={InputCheckbox}
                    inline
                    disabled={true}
                  />
                )}
              </Row>
            }
            <CustomFields customFields={customFields} readOnlyFields={true} />
          </Panel.Collapse>
        </Panel>
      </div>
    )
  }
}
export default Vehicle
