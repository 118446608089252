import React, { PureComponent } from 'react'
import { Panel, Row, Button, Tooltip/*, OverlayTrigger, Glyphicon */ } from 'react-bootstrap'
import InputSelect from '../../../../_v2/components/commons/form/InputSelect'
import InputText from '../../../../_v2/components/commons/form/InputText'
import InputNumber from '../../../../_v2/components/commons/form/InputNumber'
import InputDatePicker from '../../../../_v2/components/commons/form/InputDatePicker'
import SimpleInputText from '../../../../_v2/components/commons/simpleForm/SimpleInputText'
import InputCheckbox from '../../../../_v2/components/commons/form/InputCheckBox'
import OrderModalPage from '../../../../containers/dossiers/common/vehicle/OrderModalPage'
import { Field } from 'redux-form'
import { validateAlfanumeric, validateDate, validateVinChassis, validateLicensePlate as validateLicensePlateFunciton } from '../../../../util/validationFunctions'
import { multipleNormalize, toUpperCase, trimAll } from '../../../../util/normalizeFunctions'
import { compareObjects, getDateNowMaxDatePicker } from '../../../../util/utils'
import { getFieldsPermisions } from '../../../../util/permisionFunctions'
import { permissions, clientInformation } from '../../../../constants/backendIds'
import { dossierTypes as dossierTypesConstant } from '../../../../constants/dossier/common/dossierTypes'
import { dossierSubTypeId as dossierSubTypeIdConstants } from '../../../../constants/dossier/common/dossierSubType'
import SaleRequestFields from '../../../../_v2/components/dossiers/common/saleRequestFields/SaleRequestFields'
import settings from '../../../../setting'

import { modelLength } from '../../../../constants/dossier/common/vehicle'
import CustomFields from '../../../../components/commons/customFields/CustomFields'
import VehicleSelectionModal from '../../../../components/dossiers/common/vehicle/VehicleSelectionModal'
import VehicleCreationModal from '../../../../components/dossiers/common/vehicle/VehicleCreationModal'
import ComponentTitle from '../../../../_v2/containers/dossiers/commons/ComponentTitlePage'

class Vehicle extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      open: false,
      orderOpen: true,
      justOpened: true,
      vehicleProps: null,
      noEditableVehicle: this.props.parentDossierType.isService ? false : !!(props.parentDossierType.isSales || props.parentDossierType.isRegistrationDelivery || !props.parentDossierType.isPurchase || !!(!props.parentDossierType.isRegistrationDelivery && props.dossierType !== dossierTypesConstant.purchase && props.dossierParentId)),
      idDealerNonEditable: !!(props.parentDossierType.isPaperwork || props.parentDossierType.isCampaign),
      fieldsConfiguration: {
        dealerNumber: null,
        vinChassis: null,
        vinChassisLeft: null,
        vinChassisRight: null,
        licensePlate: null,
        comercialCode: null,
        brandId: null,
        model: null,
        color: null,
        tapestry: null,
        fuelId: null,
        disabledValidateLicensePlate: null,
        firstLicensePlateDate: null,
        makerNumber: null,
        availabilityDate: null,
        expirationItvDate: null,
        originId: null,
        destinationId: null,
        updated: false,
        button_vehicle_order: null,
        button_delete_vehicle: null,
        brandReference: null,
        originalLicensePlate: null,
        countryId: null,
        family: null,
        searchByDealerNumber: null,
        carlineId: null,
        catalogueId: null,
        modelId: null,
        colorId: null,
        tapestryId: null,
        catalogue: null,
        carline: null,
        modelYear: null,
        provisionalLicensePlate: null
      },
      disabledValidateLicensePlate: false
    }
    this.validateLicensePlate = this.validateLicensePlate.bind(this)
    this.vinChassisValidation = this.vinChassisValidation.bind(this)
  }

  searchVehicle () {
    if (this.props.searchedVehicleProps.searchDealerNumber || this.props.searchedVehicleProps.searchVinChassis || this.props.searchedVehicleProps.searchLicensePlate || this.props.searchedVehicleProps.searchMakerNumber) {
      this.props.actions.searchVehicle(
        this.props.searchedVehicleProps.searchDealerNumber,
        this.props.searchedVehicleProps.searchVinChassis,
        this.props.searchedVehicleProps.searchLicensePlate,
        this.props.searchedVehicleProps.searchMakerNumber,
        this.props.parentDossierType,
        this.props.dossierWithVehicle,
        this.props.organizedUnitId,
        this.props.dossierSubType,
        this.props.pristine
      )
    } else {
      this.props.actions.setSimpleInputTextValidation(' ')
    }
  }

  initializePermisions (props) {
    const fieldKeys = Object.keys(this.state.fieldsConfiguration)
    const newState = getFieldsPermisions(props.componentConfiguration.fieldsConfiguration, fieldKeys, props.componentConfiguration.permission)

    this.setState({ ...this.state, ...newState })
  }

  UNSAFE_componentWillMount () {
    this.initializePermisions(this.props)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.open) {
      this.setState({ open: true })
    }
    if (nextProps.dossierId && !compareObjects(this.props.componentConfiguration || {}, nextProps.componentConfiguration, true)) {
      this.initializePermisions(nextProps)
    }

    if ((nextProps.formValues && this.props.formValues && nextProps.formValues.comercialCode && this.props.formValues.comercialCode && this.props.formValues.comercialCode !== nextProps.formValues.comercialCode && this.props.codeVehicleConfiguration) ||
      (!this.props.formValues && nextProps.formValues && nextProps.formValues.comercialCode && this.props.codeVehicleConfiguration) ||
      (this.props.comercialCode && nextProps.comercialCode && this.props.comercialCode !== nextProps.comercialCode && this.props.codeVehicleConfiguration) ||
      (!this.props.comercialCode && nextProps.comercialCode && this.props.codeVehicleConfiguration) && this.props.dossierSubType !== dossierSubTypeIdConstants.tramite) {
      this.props.actions.fetchComboOrigin(nextProps.comercialCode || nextProps.formValues.comercialCode, nextProps.dossierSubType)
      this.props.actions.fetchComboDestination(nextProps.comercialCode || nextProps.formValues.comercialCode, nextProps.dossierSubType)
    }
    if ((this.state.justOpened && nextProps.dossierParentId) || (this.props.dossierParentId !== nextProps.dossierParentId && nextProps.dossierParentId)) {
      this.setState({ orderOpen: false })
      this.state.justOpened = false
    }
    if (this.props.dossierParentId !== nextProps.dossierParentId && !nextProps.dossierParentId) {
      this.setState({ orderOpen: true })
    }
    if ((!this.props.formValues && nextProps.formValues && nextProps.formValues.disabledValidateLicensePlate) ||
      (this.props.formValues && nextProps.formValues && nextProps.formValues.disabledValidateLicensePlate &&
        (this.props.formValues.disabledValidateLicensePlate !== nextProps.formValues.disabledValidateLicensePlate ||
          (!this.state.disabledValidateLicensePlate && nextProps.formValues.disabledValidateLicensePlate)))) {
      this.setState({ disabledValidateLicensePlate: nextProps.formValues.disabledValidateLicensePlate })
    }

    this.setState({
      noEditableVehicle:
        (this.props.parentDossierType.isSales ||
          (this.props.parentDossierType.isPaperwork && this.props.dossierParentId) ||
          this.props.parentDossierType.isCampaign ||
          this.props.parentDossierType.isService ||
          this.props.parentDossierType.isRegistrationDelivery ||
          this.props.parentDossierType.isStock),

      idDealerNonEditable: !!(this.props.parentDossierType.isPaperwork || this.props.parentDossierType.isCampaign)
    })
  }

  componentDidUpdate (prevProps) {
    if (this.props.isVehicleLoaded === false && this.state.open) {
      this.loadVehicle()
    }
  }

  componentWillUnmount () {
    if (!this.props.isHistorical) {
      this.props.actions.resetSearchFields()
    }
  }

  validateIdPurchase () {
    const error = {}
    error.dealerNumber = {}
    if (this.props.searchedVehicleProps.searchDealerNumber) {
      const idDealeError = validateAlfanumeric(this.props.searchedVehicleProps.searchDealerNumber, this.props, false)
      if (Object.keys(idDealeError).length > 0) {
        error.dealerNumber = idDealeError
        return error
      }
    }
  }

  fetchSalesDossierPromiseVehicle () {
    if (this.state.open) {
      this.props.actions.setActiveSection(false)
    }
    this.setState({
      open: !this.state.open
    })
    if (this.props.isVehicleLoaded === false) {
      return this.loadVehicle()
    }
  }

  loadVehicle () {
    return new Promise((resolve, reject) => {
      this.props.handleToUpdateComponent(this.props)
      if (this.props.isHistorical) {
        if (this.props.dossierType === dossierTypesConstant.sales) {
          return this.props.actions.fetchSalesVehicleHistorical(this.props.dossierId, resolve, reject)
        } else if (this.props.dossierType === dossierTypesConstant.purchase) {
          return this.props.actions.fetchPurchaseVehicleHistorical(this.props.dossierId, resolve, reject)
        } else if (this.props.dossierType === dossierTypesConstant.paperwork) {
          return this.props.actions.fetchPaperworkVehicleHistorical(this.props.dossierId, resolve, reject)
        } else if (this.props.dossierType === dossierTypesConstant.campaign) {
          return this.props.actions.fetchCampaignVehicleHistorical(this.props.dossierId, resolve, reject)
        } else if (this.props.dossierType === dossierTypesConstant.registrationDelivery) {
          return this.props.actions.fetchRegistrationDeliveryVehicleHistorical(this.props.dossierId, resolve, reject)
        } else if (this.props.dossierType === dossierTypesConstant.service) {
          this.props.actions.fetchServiceDossierVehicleHistorical(this.props.dossierId, resolve, reject, this.props.pristine)
        } else if (this.props.dossierType === dossierTypesConstant.stock) {
          this.props.actions.fetchStockDossierVehicleHistorical(this.props.dossierId, resolve, reject, this.props.pristine)
        }
      } else {
        if (this.props.dossierType === dossierTypesConstant.sales) {
          return this.props.actions.fetchSalesVehicle(this.props.dossierId, resolve, reject, this.props.pristine)
        } else if (this.props.dossierType === dossierTypesConstant.purchase) {
          return this.props.actions.fetchPurchaseVehicle(this.props.dossierId, resolve, reject, this.props.pristine)
        } else if (this.props.dossierType === dossierTypesConstant.paperwork) {
          return this.props.actions.fetchPaperworkVehicle(this.props.dossierId, resolve, reject, false, this.props.pristine)
        } else if (this.props.dossierType === dossierTypesConstant.campaign) {
          return this.props.actions.fetchCampaignVehicle(this.props.dossierId, resolve, reject, this.props.pristine)
        } else if (this.props.dossierType === dossierTypesConstant.registrationDelivery) {
          return this.props.actions.fetchRegistrationDeliveryVehicle(this.props.dossierId, resolve, reject, this.props.pristine)
        } else if (this.props.dossierType === dossierTypesConstant.service) {
          this.props.actions.fetchServiceDossierVehicle(this.props.dossierId, resolve, reject, this.props.pristine)
        }
      }
    })
  }

  componentDidMount () {
    if (this.props.actions.fecthFamily !== null && this.props.actions.fecthFamily !== undefined) {
      this.props.actions.fecthFamily()
    }
  }

  dateValidation (value, allValues, props) {
    const result = validateDate(value, props)
    return result === Object(result) ? undefined : result
  }

  normalizeLicensePlate (value) {
    multipleNormalize(value, [toUpperCase, trimAll])
  }

  changeBoolLicensePlate (value) {
    this.setState({ disabledValidateLicensePlate: value })
  }

  validateLicensePlate (value) {
    return validateLicensePlateFunciton(value, this.props.formValues && this.props.formValues.validateLicensePlateCountry || false, this.props.t('GLOBAL.FORMS.INVALID_STRING'))
  }

  vinChassisValidation (value, allValues, props) {
    const result = validateVinChassis(value, props)
    return result === Object(result) ? undefined : result
  }

  getVehicleByChassis (value) {
    if (value && this.props.searchVehicle && this.props.organizedUnitId && !this.props.dossierWithVehicle) {
      this.props.actions.fetchVehiclePurchaseComponent(value, null, this.props.organizedUnitId)
    }
  }

  getVehicleByLicense (value) {
    if (value && this.props.searchVehicle && this.props.organizedUnitId && !this.props.dossierWithVehicle) {
      this.props.actions.fetchVehiclePurchaseComponent(null, value, this.props.organizedUnitId)
    }
  }

  onBrandIdChanges (brandId) {
    this.props.actions.resetSearchFields()
    this.props.actions.resetCarlineCombo()
    this.props.actions.resetCatalogueCombo()
    this.props.actions.resetColorCombo()
    this.props.actions.resetTapestryCombo()
    if (brandId) {
      this.props.actions.fetchCarlineCombo(brandId)
    }
  }

  onCarlineIdChanges (carlineId) {
    this.props.actions.resetSearchFields()
    this.props.actions.resetColorCombo()
    this.props.actions.resetTapestryCombo()
    if (carlineId) {
      this.props.actions.fetchCatalogueCombo(carlineId)
    }
  }

  onCatalogueIdChanges (catalogueId) {
    this.props.actions.resetSearchFields()
    if (this.props.formValues.modelId && catalogueId) {
      this.props.actions.fetchPurchaseCatalogueModel(catalogueId, this.props.formValues.modelId)
      this.props.actions.fetchColorCombo(catalogueId, this.props.formValues.modelId)
      this.props.actions.fetchTapestryCombo(catalogueId, this.props.formValues.modelId)
    }
  }

  onModelIdChanges (modelId) {
    this.props.actions.resetSearchFields()
    if (modelId && this.props.formValues.catalogueId) {
      this.props.actions.fetchPurchaseCatalogueModel(this.props.formValues.catalogueId, modelId)
      this.props.actions.fetchColorCombo(this.props.formValues.catalogueId, modelId)
      this.props.actions.fetchTapestryCombo(this.props.formValues.catalogueId, modelId)
    }
  }

  render () {
    const {
      t,
      dossierId, dossierParentId, readOnlyFields, orderId, order, dossierType, dossierSubType, formValues, dossierWithVehicle, customFields,
      searchedVehicleProps: {
        searchDealerNumber, searchVinChassis, searchLicensePlate, simpleInputTextValidation, searchMakerNumber, vehicleSelectionModal,
        showVehicleFinderModal
      },
      combos: {
        brandCombo, fuelCombo, purchaseTypeVnCombo, entityTransferCombo, vehicleOriginCombo, vehicleDestinationCombo, countries, familyCombo,
        carlineCombo, catalogueCombo, modelCombo, colorCombo, tapestryCombo
      },
      actions: {
        fetchOrder, setSearchDealerNumber, setSearchVinChassis, setSearchLicensePlate, setSearchMakerNumber, clearVehicle, resetSearchFields,
        closeVehicleSelectionModal, selectVehicle, fetchVehicleSelectionModal,
        closeVehicleCreationModal, createVehicleCreationModal
      },
      parentDossierType: { isSales, isRegistrationDelivery, isService, isPaperwork, isPurchase, isStock }, purchaseOrderExetendedMode,
      codeVehicleConfiguration, purchaseType, searchVehicle, dossierParentSubTypeId
    } = this.props
    const hasFilters = (searchDealerNumber || searchVinChassis || searchLicensePlate || searchMakerNumber)
    const hasData = (formValues ? (Object.keys(formValues).filter(key => ['vehicleId', 'dossierId', 'parentIsHistorical', 'dealerNumber', 'makerNumber', 'availabilityDate'].indexOf(key) === -1 && formValues[key]).length > 0) : false)
    const hasVehicle = (formValues ? (Object.keys(formValues).filter(key => ['vehicleId'].indexOf(key) > -1 && formValues[key]).length > 0) : false)
    const tKey = 'DOSSIER_COMPONENTS.VEHICLE.'
    const editTooltip = (<Tooltip id="editTooltip">{t(`${tKey}TOOLTIP_VALIDATE_LICENSE_PLATE`)}</Tooltip>)
    const isVN =
    dossierSubType === dossierSubTypeIdConstants.compra_vn || dossierSubType === dossierSubTypeIdConstants.venta_vn || dossierSubType === dossierSubTypeIdConstants.registration_delivery ||
    ((dossierSubType === dossierSubTypeIdConstants.tramite || dossierSubType === dossierSubTypeIdConstants.campanya || dossierSubType === dossierSubTypeIdConstants.service) &&
     (dossierParentSubTypeId === dossierSubTypeIdConstants.compra_vn || dossierParentSubTypeId === dossierSubTypeIdConstants.venta_vn || dossierParentSubTypeId === dossierSubTypeIdConstants.registration_delivery || !dossierParentSubTypeId))
    return (
      <div className="dossier-Component">
        <div className="header-panel" onClick={this.fetchSalesDossierPromiseVehicle.bind(this)}>
          <ComponentTitle
            componentCode="VEHICLE"
            componentOpen={this.state.open}
            // extraInfo={'info extra'}
            extraData={
              !readOnlyFields && hasVehicle && (dossierParentId || dossierSubType === dossierSubTypeIdConstants.compra_vn || dossierSubType === dossierSubTypeIdConstants.compra_vo ||
                dossierSubType === dossierSubTypeIdConstants.campanya || dossierSubType === dossierSubTypeIdConstants.campanya_ayuda_especial || isPaperwork ||
                (isService && !dossierParentId) || (!isService && (dossierParentId || isPurchase) && hasVehicle)) && this.props.componentConfiguration.permission === permissions.editable &&
                (this.state.fieldsConfiguration.button_delete_vehicle && this.state.fieldsConfiguration.button_delete_vehicle.permission !== permissions.hidden) && (
                <Button
                  className={'_Btn stk ' + ((hasData) ? 'accept' : 'cancel')}
                  id="button_delete_vehicle"
                  disabled={this.state.fieldsConfiguration.button_delete_vehicle && this.state.fieldsConfiguration.button_delete_vehicle.permission !== permissions.editable}
                  style={{ fontSize: '11px' }}
                  onClick={() => { if (hasData) { clearVehicle(this.props.parentDossierType, this.props.pristine) } }}>
                  <i className="iDocIcon-delete-forever" />
                  {t(`${tKey}CLEAR_VEHICLE`)}
                </Button>
              )
            }
          />
        </div>
        <VehicleSelectionModal
          t = {t}
          show = {vehicleSelectionModal?.show ?? false }
          purchaseList = {vehicleSelectionModal?.purchaseList ?? []}
          actions={{
            closeVehicleSelectionModal,
            selectVehicle,
            fetchVehicleSelectionModal
          }}
          stockDossierId = {vehicleSelectionModal?.stockDossierId}
          dossierId = {dossierId}
        />
        <VehicleCreationModal
          t={t}
          show={showVehicleFinderModal ?? false }
          actions={{
            closeVehicleCreationModal,
            createVehicleCreationModal
          }}
          combos={{
            brandCombo,
            fuelCombo
          }}
          dossierId={dossierId}
          validateLicensePlateCountry={this.props.formValues?.validateLicensePlateCountry}
        />
        <OrderModalPage
          readOnlyFields={readOnlyFields}
          order={order}
          purchaseOrderExetendedMode={purchaseOrderExetendedMode}
          dossierType = { dossierType }
          fieldsConfiguration={this.state.fieldsConfiguration}
        />
        <Panel expanded={this.state.open} onToggle={() => {}}>
          <Panel.Collapse>

            {((isSales || isRegistrationDelivery) && (!dossierParentId || orderId)) && (
              <div className='dossier-Component innerGroup'>
                <div className="header-panel" onClick={() => this.setState({ orderOpen: !this.state.orderOpen })}>
                  <ComponentTitle
                    componentCode="VEHICLE_ORDER_PANEL"
                    componentOpen={this.state.orderOpen }
                    // extraInfo={'info extra'}
                    // extraData={<a className="_Btn accept">texto</a>}
                  />
                </div>
                <Panel expanded={this.state.orderOpen} onToggle={() => {}}>
                  <Panel.Collapse>
                    <Row className='fieldsContainer'>
                      <Field
                        component={InputSelect}
                        colWidht={25} // 25, 33, 50, 75, 100 default=25
                        name="order.brandId"
                        id="order_brandId"
                        controlLabel={t(`${tKey}BRAND`)}
                        options={brandCombo}
                        valueKey="id"
                        labelKey="value"
                        disabled={true}
                        menuPlacement="auto"
                      />
                      {this.state.fieldsConfiguration.carlineId?.permission !== permissions.hidden && (
                        <Field
                          component={InputSelect}
                          colWidht={25} // 25, 33, 50, 75, 100 default=25
                          id="order_carlineId"
                          name="order.carlineId"
                          controlLabel={t(`${tKey}CARLINE`)}
                          placeholder={t(`${tKey}CARLINE`)}
                          valueKey="id"
                          labelKey="value"
                          options={carlineCombo}
                          disabled={true}
                          menuPlacement="auto"
                        />
                      )}
                      {this.state.fieldsConfiguration.carline?.permission !== permissions.hidden && (
                        <Field
                          component={InputText}
                          colWidht={25} // 25, 33, 50, 75, 100 default=25
                          id="order_carline"
                          name="order.carline"
                          controlLabel={t(`${tKey}CARLINE`)}
                          placeholder={t(`${tKey}CARLINE`)}
                          disabled={true}
                          validate={this.required}
                        />)}
                      {this.state.fieldsConfiguration.catalogueId?.permission !== permissions.hidden && (
                        <Field
                          component={InputSelect}
                          colWidht={25} // 25, 33, 50, 75, 100 default=25
                          id="order_catalogueId"
                          name="order.catalogueId"
                          controlLabel={t(`${tKey}CATALOGUE`)}
                          placeholder={t(`${tKey}CATALOGUE`)}
                          valueKey="id"
                          labelKey="value"
                          options={catalogueCombo}
                          disabled={true}
                          menuPlacement="auto"
                        />
                      )}
                      {this.state.fieldsConfiguration.catalogue?.permission !== permissions.hidden && (
                        <Field
                          component={InputText}
                          colWidht={25} // 25, 33, 50, 75, 100 default=25
                          id="order_catalogue"
                          name="order.catalogue"
                          colSm={3}
                          controlLabel={t(`${tKey}CATALOGUE`)}
                          placeholder={t(`${tKey}CATALOGUE`)}
                          disabled={true}
                          validate={this.required}
                        />)}
                      {this.state.fieldsConfiguration.modelId?.permission !== permissions.hidden && (
                        <Field
                          component={InputSelect}
                          colWidht={25} // 25, 33, 50, 75, 100 default=25
                          id="order_modelId"
                          name="order.modelId"
                          controlLabel={t(`${tKey}MODEL_YEAR`)}
                          placeholder={t(`${tKey}MODEL_YEAR`)}
                          valueKey="id"
                          labelKey="value"
                          options={modelCombo}
                          disabled={true}
                          menuPlacement="auto"
                        />
                      )}
                      {this.state.fieldsConfiguration.modelYear?.permission !== permissions.hidden && (
                        <Field
                          component={InputText}
                          colWidht={25} // 25, 33, 50, 75, 100 default=25
                          id="order_modelYear"
                          name="order.modelYear"
                          controlLabel={t(`${tKey}MODEL_YEAR`)}
                          placeholder={t(`${tKey}MODEL_YEAR`)}
                          disabled={true}
                          validate={this.required}
                        />)}
                      {this.state.fieldsConfiguration.colorId?.permission !== permissions.hidden && (
                        <Field
                          component={InputSelect}
                          colWidht={25} // 25, 33, 50, 75, 100 default=25
                          id="order_colorId"
                          name="order.colorId"
                          controlLabel={t(`${tKey}COLOR`)}
                          placeholder={t(`${tKey}COLOR`)}
                          valueKey="id"
                          labelKey="value" options={colorCombo}
                          disabled={true}
                        />
                      )}
                      {this.state.fieldsConfiguration.tapestryId?.permission !== permissions.hidden && (
                        <Field
                          component={InputSelect}
                          colWidht={25} // 25, 33, 50, 75, 100 default=25
                          id="order_tapestryId"
                          name="order.tapestryId"
                          controlLabel={t(`${tKey}TAPESTRY`)}
                          placeholder={t(`${tKey}TAPESTRY`)}
                          valueKey="id"
                          labelKey="value"
                          options={tapestryCombo}
                          disabled={true}
                        />
                      )}
                      <Field
                        component={InputText}
                        colWidht={25} // 25, 33, 50, 75, 100 default=25
                        name="order.model"
                        id="order_model"
                        controlLabel={t(`${tKey}MODEL`)}
                        disabled={true}
                      />
                      {this.state.fieldsConfiguration.color?.permission !== permissions.hidden && (
                        <Field
                          component={InputText}
                          colWidht={25} // 25, 33, 50, 75, 100 default=25
                          name="order.color"
                          id="order_color"
                          controlLabel={t(`${tKey}COLOR`)}
                          disabled={true}
                        />
                      )}
                      {this.state.fieldsConfiguration.tapestry?.permission !== permissions.hidden && (
                        <Field
                          component={InputText}
                          colWidht={25} // 25, 33, 50, 75, 100 default=25
                          name="order.tapestry"
                          id="order_tapestry"
                          controlLabel={t(`${tKey}TAPESTRY`)}
                          disabled={true}
                        />
                      )}
                      {purchaseOrderExetendedMode && (
                        <SaleRequestFields
                          t={t}
                          readOnlyFields={true}
                          purchaseTypeVnCombo={purchaseTypeVnCombo}
                          entityTransferCombo={entityTransferCombo}
                          namePrefix={'order.'}
                          order={order}
                          fieldSize={0}
                        />
                      )
                      }
                      {this.state.fieldsConfiguration.comercialCode?.permission !== permissions.hidden && (
                        <Field
                          component={InputText}
                          colWidht={25} // 25, 33, 50, 75, 100 default=25
                          name="order.comercialCode"
                          id="order_comercialCode"
                          controlLabel={t(`${tKey}COMMERCIAL_CODE`)}
                          disabled={true}
                        />
                      )}
                      <Field
                        component={InputSelect}
                        colWidht={25} // 25, 33, 50, 75, 100 default=25
                        name="order.fuelId"
                        id="order_fuelId"
                        controlLabel={t(`${tKey}FUEL`)}
                        options={fuelCombo}
                        valueKey="id"
                        labelKey="value"
                        disabled={true}
                        menuPlacement="auto"
                      />

                      {!dossierParentId && this.state.fieldsConfiguration.button_vehicle_order?.permission !== permissions.hidden && (readOnlyFields ? null
                        : <div className='flexInLine al-R'>
                          <Button className="order-button btn-editable-table btn-standard"
                            onClick={() => fetchOrder(dossierId)}
                            style={{ width: '100%' }}
                            id="button_vehicle_order"
                            disabled={this.state.fieldsConfiguration.button_vehicle_order.permission === permissions.readOnly}
                          >
                            <span>{t(`${tKey}ORDER`)}</span>
                          </Button>
                        </div>
                      )}
                    </Row>
                  </Panel.Collapse>
                </Panel>
              </div>
            )}

            {!dossierParentId && !readOnlyFields && dossierSubType !== dossierSubTypeIdConstants.compra_vn && !dossierWithVehicle && settings.clientFieldValidation !== clientInformation.PORSCHE ? (
              <div className='dossier-Component innerGroup'>
                <div className="header-panel">
                  <ComponentTitle
                    componentCode="VEHICLE_SEARCH_VEHICLE"
                    // componentOpen={this.state.orderOpen }
                    // extraInfo={'info extra'}
                    // extraData={<a className="_Btn accept">texto</a>}
                  />
                </div>
                <div className='search-form'>
                  {(this.state.fieldsConfiguration.searchByDealerNumber && this.state.fieldsConfiguration.searchByDealerNumber.permission > permissions.hidden) &&
                          (isSales || isRegistrationDelivery || isPaperwork || isStock)
                    ? (
                      <SimpleInputText
                        component={InputText}
                        colWidht={25} // 25, 33, 50, 75, 100 default=25
                        id="search_dealerNumber"
                        controlLabel={t(`${tKey}DEALER_NUMBER`)}
                        value={searchDealerNumber}
                        onInputChange={setSearchDealerNumber}
                        disabled={readOnlyFields || this.state.fieldsConfiguration.searchByDealerNumber.permission !== permissions.editable}
                        // forceControlLabelInOneLine={true}
                        maxLength={settings.dealerMaxLength}
                        error={this.validateIdPurchase() ? (t(`${tKey}VALIDATE_ID_DEALER`)) : null}
                      />
                    ) : null
                  }
                  <SimpleInputText
                    component={InputText}
                    colWidht={25} // 25, 33, 50, 75, 100 default=25
                    id="search_chassis"
                    controlLabel={t(`${tKey}CHASSIS`)}
                    value={searchVinChassis}
                    error={simpleInputTextValidation}
                    onInputChange={(val) => setSearchVinChassis(val)}
                    disabled={readOnlyFields}
                    maxLength={17}
                    normalize={value => multipleNormalize(value, [toUpperCase, trimAll])}
                  />
                  <SimpleInputText
                    component={InputText}
                    colWidht={25} // 25, 33, 50, 75, 100 default=25
                    id="search_license_plate"
                    controlLabel={t(`${tKey}LICENSE_PLATE`)}
                    value={searchLicensePlate}
                    error={simpleInputTextValidation}
                    onInputChange={setSearchLicensePlate}
                    disabled={readOnlyFields}
                    normalize={value => multipleNormalize(value, [toUpperCase, trimAll])}
                  />
                  {
                    (isSales || isRegistrationDelivery)
                      ? (
                        <SimpleInputText
                          component={InputText}
                          colWidht={25} // 25, 33, 50, 75, 100 default=25
                          id="search_makerNumber"
                          controlLabel={t(`${tKey}MAKER_ORDER_NUMBER`)}
                          value={searchMakerNumber}
                          error={simpleInputTextValidation}
                          onInputChange={setSearchMakerNumber}
                          disabled={readOnlyFields}
                          // forceControlLabelInOneLine={true}
                        />
                      ) : null
                  }
                  { !readOnlyFields && (
                    <div className="search-form-actions flexInLine al-R">
                      <Button
                        className={'_Btn accept ' + (hasFilters ? '' : 'disabled')}
                        // disabled={hasFilters}
                        id="button_vehicle_seach"
                        onClick={() => this.searchVehicle()}>
                        <i className="ico-search" />
                        {t(`${tKey}SEARCH_BUTTON`)}
                      </Button>
                    </div>
                  ) }
                </div>
              </div>

            ) : null
            }
            {/*! readOnlyFields && hasVehicle && (dossierParentId || dossierSubType === dossierSubTypeIdConstants.compra_vn || dossierSubType === dossierSubTypeIdConstants.compra_vo ||
              dossierSubType === dossierSubTypeIdConstants.campanya || dossierSubType === dossierSubTypeIdConstants.campanya_ayuda_especial || isPaperwork ||
              (isService && !dossierParentId) || (!isService && (dossierParentId || isPurchase) && hasVehicle)) && this.props.componentConfiguration.permission === permissions.editable &&
              (this.state.fieldsConfiguration.button_delete_vehicle && this.state.fieldsConfiguration.button_delete_vehicle.permission !== permissions.hidden) && (
              <Row>
                <Col sm={12} style={{ textAlign: 'right' }}>
                  <Button
                    className={(hasData) ? ' btn-standard' : ' btn-cancel'}
                    id="button_delete_vehicle"
                    disabled={this.state.fieldsConfiguration.button_delete_vehicle && this.state.fieldsConfiguration.button_delete_vehicle.permission !== permissions.editable}
                    style={{ fontSize: '11px' }}
                    onClick={
                      () => {
                        if (hasData) { clearVehicle(this.props.parentDossierType, this.props.pristine) }
                      }
                    }>
                    <i className="ico-cancel" />
                    {t(`${tKey}CLEAR_VEHICLE`)}
                  </Button>
                </Col>
              </Row>
            ) */}
            {
              <Row className='fieldsContainer'>
                {
                  dossierType !== dossierTypesConstant.purchase && this.state.fieldsConfiguration.dealerNumber?.permission !== permissions.hidden
                    ? (
                      <Field
                        component={InputText}
                        colWidht={33} // 25, 33, 50, 75, 100 default=25
                        id="dealerNumber"
                        name="vehicleComponent.dealerNumber"
                        controlLabel={t(`${tKey}DEALER_NUMBER`)}
                        placeholder={t(`${tKey}DEALER_NUMBER`)}
                        disabled={readOnlyFields || this.state.noEditableVehicle || this.state.fieldsConfiguration.dealerNumber.permission !== permissions.editable}
                      />
                    ) : null
                }
                {this.state.fieldsConfiguration.vinChassis?.permission !== permissions.hidden && (
                  <Field
                    component={InputText}
                    colWidht={33} // 25, 33, 50, 75, 100 default=25
                    id="vinChassis"
                    name="vehicleComponent.vinChassis"
                    controlLabel={t(`${tKey}CHASSIS`)}
                    placeholder={t(`${tKey}CHASSIS`)}
                    disabled={readOnlyFields || this.state.noEditableVehicle || this.state.fieldsConfiguration.vinChassis.permission !== permissions.editable}
                    onInputChange={resetSearchFields}
                    minLength={17}
                    maxLength={17}
                    normalize={value => multipleNormalize(value, [toUpperCase, trimAll])}
                    validate={[this.vinChassisValidation]}
                    onInputBlur={searchVehicle ? event => this.getVehicleByChassis(event) : null}
                  />
                )}
                {this.state.fieldsConfiguration.licensePlate?.permission !== permissions.hidden && (
                  <Field
                    component={InputText}
                    colWidht={33} // 25, 33, 50, 75, 100 default=25
                    id="licensePlate"
                    name="vehicleComponent.licensePlate"
                    controlLabel={t(`${tKey}LICENSE_PLATE`)}
                    placeholder={t(`${tKey}LICENSE_PLATE`)}
                    validate={this.state.disabledValidateLicensePlate === false || this.state.disabledValidateLicensePlate === null ? [this.validateLicensePlate] : null }
                    disabled={readOnlyFields || this.state.noEditableVehicle || this.state.fieldsConfiguration.licensePlate.permission !== permissions.editable}
                    onInputChange={resetSearchFields}
                    normalize={value => multipleNormalize(value, [toUpperCase, trimAll])}
                    onInputBlur = {searchVehicle ? event => this.getVehicleByLicense(event) : null}
                  />
                )}
                {this.state.fieldsConfiguration.comercialCode?.permission !== permissions.hidden && (
                  <Field
                    component={InputText}
                    colWidht={33} // 25, 33, 50, 75, 100 default=25
                    id="comercialCode"
                    name="vehicleComponent.comercialCode"
                    controlLabel={t(`${tKey}COMMERCIAL_CODE`)}
                    placeholder={t(`${tKey}COMMERCIAL_CODE`)}
                    disabled={readOnlyFields || this.state.noEditableVehicle || this.state.fieldsConfiguration.comercialCode.permission !== permissions.editable}
                    onInputChange={resetSearchFields}
                  />
                )}
                {this.state.fieldsConfiguration.brandId?.permission !== permissions.hidden && (
                  <Field
                    component={InputSelect}
                    colWidht={33} // 25, 33, 50, 75, 100 default=25
                    id="brandId"
                    name="vehicleComponent.brandId"
                    controlLabel={t(`${tKey}BRAND`)}
                    placeholder={t(`${tKey}BRAND`)}
                    options={brandCombo}
                    valueKey="id"
                    labelKey="value"
                    disabled={readOnlyFields || this.state.noEditableVehicle || this.state.fieldsConfiguration.brandId.permission !== permissions.editable}
                    onInputChange={this.onBrandIdChanges.bind(this)}
                    menuPlacement="auto"
                  />
                )}

                {this.state.fieldsConfiguration.carlineId?.permission !== permissions.hidden && isVN && (
                  <Field
                    component={InputSelect}
                    colWidht={33} // 25, 33, 50, 75, 100 default=25
                    id="carlineId"
                    name="vehicleComponent.carlineId"
                    controlLabel={t(`${tKey}CARLINE`)}
                    placeholder={t(`${tKey}CARLINE`)}
                    valueKey="id"
                    labelKey="value"
                    options={carlineCombo}
                    disabled={carlineCombo.length === 0 || readOnlyFields || this.state.noEditableVehicle || this.state.fieldsConfiguration.carlineId.permission !== permissions.editable}
                    onInputChange={this.onCarlineIdChanges.bind(this)}
                    menuPlacement="auto"
                  />
                )}

                {this.state.fieldsConfiguration.carline?.permission !== permissions.hidden && !isVN && (
                  <Field
                    component={InputText}
                    colWidht={33} // 25, 33, 50, 75, 100 default=25
                    id="carline"
                    name="vehicleComponent.carline"
                    controlLabel={t(`${tKey}CARLINE`)}
                    placeholder={t(`${tKey}CARLINE`)}
                    disabled={readOnlyFields || this.state.noEditableVehicle || this.state.fieldsConfiguration.carline.permission !== permissions.editable}
                    onInputChange={resetSearchFields}
                  />
                )}

                {this.state.fieldsConfiguration.catalogueId?.permission !== permissions.hidden && isVN && (
                  <Field
                    component={InputSelect}
                    colWidht={33} // 25, 33, 50, 75, 100 default=25
                    id="catalogueId"
                    name="vehicleComponent.catalogueId"
                    controlLabel={t(`${tKey}CATALOGUE`)}
                    placeholder={t(`${tKey}CATALOGUE`)}
                    valueKey="id"
                    labelKey="value"
                    options={catalogueCombo}
                    disabled={catalogueCombo.length === 0 || readOnlyFields || this.state.noEditableVehicle || this.state.fieldsConfiguration.catalogueId.permission !== permissions.editable}
                    onInputChange={this.onCatalogueIdChanges.bind(this)}
                    menuPlacement="auto"
                  />
                )}

                {this.state.fieldsConfiguration.catalogue?.permission !== permissions.hidden && !isVN && (
                  <Field
                    component={InputText}
                    colWidht={33} // 25, 33, 50, 75, 100 default=25
                    id="catalogue"
                    name="vehicleComponent.catalogue"
                    controlLabel={t(`${tKey}CATALOGUE`)}
                    placeholder={t(`${tKey}CATALOGUE`)}
                    disabled={readOnlyFields || this.state.noEditableVehicle || this.state.fieldsConfiguration.catalogue.permission !== permissions.editable}
                    onInputChange={resetSearchFields}
                  />
                )}

                {this.state.fieldsConfiguration.modelId?.permission !== permissions.hidden && isVN && (
                  <Field
                    component={InputSelect}
                    colWidht={33} // 25, 33, 50, 75, 100 default=25
                    id="modelId"
                    name="vehicleComponent.modelId"
                    controlLabel={t(`${tKey}MODEL_YEAR`)}
                    placeholder={t(`${tKey}MODEL_YEAR`)}
                    valueKey="id"
                    labelKey="value"
                    options={modelCombo}
                    disabled={modelCombo.length === 0 || readOnlyFields || this.state.noEditableVehicle || this.state.fieldsConfiguration.modelId.permission !== permissions.editable}
                    onInputChange={this.onModelIdChanges.bind(this)}
                    menuPlacement="auto"

                  />
                )}
                {this.state.fieldsConfiguration.modelYear?.permission !== permissions.hidden && !isVN && (
                  <Field
                    component={InputNumber}
                    colWidht={33} // 25, 33, 50, 75, 100 default=25
                    id="modelYear"
                    name="vehicleComponent.modelYear"
                    controlLabel={t(`${tKey}MODEL_YEAR`)}
                    placeholder={t(`${tKey}MODEL_YEAR`)}
                    maxLength={modelLength.maxLength}
                    disabled={readOnlyFields || this.state.noEditableVehicle || this.state.fieldsConfiguration.modelYear.permission !== permissions.editable}
                    onInputChange={resetSearchFields}
                  />
                )}
                {this.state.fieldsConfiguration.model?.permission !== permissions.hidden && (
                  <Field
                    component={InputText}
                    colWidht={33} // 25, 33, 50, 75, 100 default=25
                    id="model"
                    name="vehicleComponent.model"
                    controlLabel={t(`${tKey}MODEL`)}
                    placeholder={t(`${tKey}MODEL`)}
                    disabled={readOnlyFields || this.state.noEditableVehicle || this.state.fieldsConfiguration.model.permission !== permissions.editable}
                    onInputChange={resetSearchFields}
                  />
                )}
                {this.state.fieldsConfiguration.colorId?.permission !== permissions.hidden && isVN && (
                  <Field
                    component={InputSelect}
                    colWidht={33} // 25, 33, 50, 75, 100 default=25
                    id="colorId"
                    name="vehicleComponent.colorId"
                    controlLabel={t(`${tKey}COLOR`)}
                    placeholder={t(`${tKey}COLOR`)}
                    valueKey="id"
                    labelKey="value" options={colorCombo}
                    disabled={colorCombo.length === 0 || readOnlyFields || this.state.noEditableVehicle || this.state.fieldsConfiguration.colorId.permission !== permissions.editable}
                    onInputChange={resetSearchFields}
                  />
                )}
                {this.state.fieldsConfiguration.tapestryId?.permission !== permissions.hidden && isVN && (
                  <Field
                    component={InputSelect}
                    colWidht={33} // 25, 33, 50, 75, 100 default=25
                    id="tapestryId"
                    name="vehicleComponent.tapestryId"
                    controlLabel={t(`${tKey}TAPESTRY`)}
                    placeholder={t(`${tKey}TAPESTRY`)}
                    valueKey="id"
                    labelKey="value"
                    options={tapestryCombo}
                    disabled={tapestryCombo.length === 0 || readOnlyFields || this.state.noEditableVehicle || this.state.fieldsConfiguration.tapestryId.permission !== permissions.editable}
                    onInputChange={resetSearchFields}
                  />
                )}
                {this.state.fieldsConfiguration.color?.permission !== permissions.hidden &&
                (settings.clientFieldValidation === clientInformation.BUSS ? !isVN : true) && (
                  <Field
                    component={InputText}
                    colWidht={33} // 25, 33, 50, 75, 100 default=25
                    id="color"
                    name="vehicleComponent.color"
                    controlLabel={t(`${tKey}COLOR`)}
                    placeholder={t(`${tKey}COLOR`)}
                    disabled={readOnlyFields || this.state.noEditableVehicle || this.state.fieldsConfiguration.color.permission !== permissions.editable}
                    onInputChange={resetSearchFields}
                  />
                )}
                {this.state.fieldsConfiguration.tapestry?.permission !== permissions.hidden &&
                 (settings.clientFieldValidation === clientInformation.BUSS ? !isVN : true) && (
                  <Field
                    component={InputText}
                    colWidht={33} // 25, 33, 50, 75, 100 default=25
                    id="tapestry"
                    name="vehicleComponent.tapestry"
                    controlLabel={t(`${tKey}TAPESTRY`)}
                    placeholder={t(`${tKey}TAPESTRY`)}
                    disabled={readOnlyFields || this.state.noEditableVehicle || this.state.fieldsConfiguration.tapestry.permission !== permissions.editable}
                    onInputChange={resetSearchFields}
                  />
                )}
                {this.state.fieldsConfiguration.fuelId?.permission !== permissions.hidden && (
                  <Field
                    component={InputSelect}
                    colWidht={33} // 25, 33, 50, 75, 100 default=25
                    id="fuelId"
                    menuContainerStyle={{ zIndex: 999 }}
                    name="vehicleComponent.fuelId"
                    controlLabel={t(`${tKey}FUEL`)}
                    placeholder={t(`${tKey}FUEL`)}
                    options={fuelCombo}
                    valueKey="id"
                    labelKey="value"
                    disabled={readOnlyFields || this.state.noEditableVehicle || this.state.fieldsConfiguration.fuelId.permission !== permissions.editable}
                    onInputChange={resetSearchFields}
                    menuPlacement="auto"
                  />
                )}

                {this.state.fieldsConfiguration.brandReference?.permission !== permissions.hidden && (
                  <Field
                    component={InputText}
                    colWidht={33} // 25, 33, 50, 75, 100 default=25
                    id="brandReference"
                    name="vehicleComponent.brandReference"
                    controlLabel={t(`${tKey}BRAND_REFERENCE`)}
                    placeholder={t(`${tKey}BRAND_REFERENCE`)}
                    disabled={readOnlyFields || this.state.noEditableVehicle || this.state.fieldsConfiguration.brandReference.permission !== permissions.editable}
                    onInputChange={resetSearchFields}
                  />
                )}

                {this.state.fieldsConfiguration.family?.permission !== permissions.hidden && (
                  <Field
                    component={InputSelect}
                    colWidht={33} // 25, 33, 50, 75, 100 default=25
                    id="familyId"
                    menuContainerStyle={{ zIndex: 999 }}
                    name="vehicleComponent.familyId"
                    controlLabel={t(`${tKey}FAMILY`)}
                    placeholder={t(`${tKey}FAMILY`)}
                    options={familyCombo}
                    valueKey="familyId"
                    labelKey="name"
                    disabled= {readOnlyFields || this.state.noEditableVehicle || this.state.fieldsConfiguration.family.permission !== permissions.editable}
                    onInputChange={resetSearchFields}
                  />
                )}
                { this.state.fieldsConfiguration.firstLicensePlateDate?.permission !== permissions.hidden ? (
                  <Field
                    component={InputDatePicker}
                    colWidht={33} // 25, 33, 50, 75, 100 default=25
                    maxDate={getDateNowMaxDatePicker()}
                    id="firstLicensePlateDate"
                    name="vehicleComponent.firstLicensePlateDate"
                    controlLabel={t(`${tKey}FIRST_LPLATE_DATE`)}
                    disabled={readOnlyFields || this.state.noEditableVehicle || this.state.fieldsConfiguration.firstLicensePlateDate.permission !== permissions.editable}
                    customClass={readOnlyFields || this.state.noEditableVehicle || this.state.fieldsConfiguration.firstLicensePlateDate.permission !== permissions.editable ? 'disabled' : ''}
                    validate={[this.dateValidation]}
                    onInputChange={resetSearchFields}
                    placement={this.props.showTopFirstLicensePlateDateAndAvailabilityDate ? 'top' : 'bottom'}
                  />
                ) : null
                }
                {
                  dossierType !== dossierTypesConstant.purchase ? ([
                    this.state.fieldsConfiguration.makerNumber?.permission !== permissions.hidden && (
                      <Field
                        component={InputText}
                        colWidht={33} // 25, 33, 50, 75, 100 default=25
                        key="makerNumber"
                        id="vehicleMakerNumber"
                        name="vehicleComponent.makerNumber"
                        controlLabel={t(`${tKey}MAKER_ORDER_NUMBER`)}
                        placeholder={t(`${tKey}MAKER_ORDER_NUMBER`)}
                        disabled={readOnlyFields || this.state.noEditableVehicle || this.state.fieldsConfiguration.makerNumber.permission !== permissions.editable}
                        onInputChange={resetSearchFields}
                      />
                    ),
                    this.state.fieldsConfiguration.availabilityDate?.permission !== permissions.hidden && (
                      <Field
                        component={InputDatePicker}
                        colWidht={33} // 25, 33, 50, 75, 100 default=25
                        maxDate={getDateNowMaxDatePicker()}
                        key="availabilityDate"
                        id="availabilityDate"
                        name="vehicleComponent.availabilityDate"
                        controlLabel={t(`${tKey}AVAILABILITY_DATE`)}
                        disabled={readOnlyFields || this.state.noEditableVehicle || this.state.fieldsConfiguration.availabilityDate.permission !== permissions.editable}
                        customClass={readOnlyFields || this.state.noEditableVehicle || this.state.fieldsConfiguration.availabilityDate.permission ? 'disabled' : ''}
                        validate={[this.dateValidation]}
                        onInputChange={resetSearchFields}
                        placement={this.props.showTopFirstLicensePlateDateAndAvailabilityDate ? 'top' : 'bottom'}
                      />
                    )
                  ]) : null
                }
                {
                  codeVehicleConfiguration && this.state.fieldsConfiguration.destinationId?.permission !== permissions.hidden && [(
                    <Field
                      component={InputSelect}
                      colWidht={33} // 25, 33, 50, 75, 100 default=25
                      id="originId"
                      name="vehicleComponent.originId"
                      key="codeOrigin"
                      valueKey="id"
                      labelKey="value"
                      controlLabel={t(`${tKey}ORIGIN_CLASIFICATION`)}
                      placeholder={t(`${tKey}ORIGIN_CLASIFICATION`)}
                      options={vehicleOriginCombo}
                      disabled={!vehicleOriginCombo || vehicleOriginCombo.length === 0 || dossierSubType !== dossierSubTypeIdConstants.venta_vn || readOnlyFields || this.state.fieldsConfiguration.originId.permission !== permissions.editable}
                    />),
                  (<Field
                    component={InputSelect}
                    colWidht={33} // 25, 33, 50, 75, 100 default=25
                    id="destinationId"
                    name="vehicleComponent.destinationId"
                    key="codeDestination"
                    valueKey="id"
                    labelKey="value"
                    controlLabel={t(`${tKey}DESTINATION_CLASIFICATION`)}
                    placeholder={t(`${tKey}DESTINATION_CLASIFICATION`)}
                    options={vehicleDestinationCombo}
                    disabled={!vehicleDestinationCombo || vehicleDestinationCombo.length === 0 || readOnlyFields || this.state.fieldsConfiguration.destinationId.permission !== permissions.editable}
                  />
                  )]
                }
                {
                  (dossierSubType === dossierSubTypeIdConstants.venta_vo || dossierSubType === dossierSubTypeIdConstants.compra_vo) &&
                this.state.fieldsConfiguration.expirationItvDate?.permission !== permissions.hidden && (
                    <Field
                      component={InputDatePicker}
                      colWidht={33} // 25, 33, 50, 75, 100 default=25
                      maxDate={getDateNowMaxDatePicker()}
                      id="expirationItvDate"
                      name="vehicleComponent.expirationItvDate"
                      controlLabel={t(`${tKey}EXPIRATION_ITV_DATE`)}
                      validate={[this.dateValidation]}
                      disabled={readOnlyFields || this.state.fieldsConfiguration.expirationItvDate.permission !== permissions.editable}
                      customClass={readOnlyFields || this.state.fieldsConfiguration.expirationItvDate.permission ? 'disabled' : ''}
                      onInputChange={resetSearchFields}
                    />
                  )
                }
                {
                  (dossierSubType === dossierSubTypeIdConstants.compra_vo && purchaseType === 10) &&
                this.state.fieldsConfiguration.originalLicensePlate?.permission !== permissions.hidden && (
                    <Field
                      component={InputText}
                      colWidht={33} // 25, 33, 50, 75, 100 default=25
                      id="originalLicensePlate"
                      name="vehicleComponent.originalLicensePlate"
                      controlLabel={t(`${tKey}ORIGINAL_LICENSE_PLATE`)}
                      placeholder={t(`${tKey}ORIGINAL_LICENSE_PLATE`)}
                      maxLength = {15}
                      disabled={readOnlyFields || this.state.noEditableVehicle || this.state.fieldsConfiguration.originalLicensePlate.permission !== permissions.editable}
                      onInputChange={resetSearchFields}
                    />
                  )
                }
                {
                  (dossierSubType === dossierSubTypeIdConstants.compra_vo && purchaseType === 10) &&
                this.state.fieldsConfiguration.countryId?.permission !== permissions.hidden && (
                    <Field
                      component={InputSelect}
                      colWidht={33} // 25, 33, 50, 75, 100 default=25
                      id="countryId"
                      name="vehicleComponent.countryId"
                      key="countryId"
                      valueKey="countryId"
                      labelKey="name"
                      controlLabel={t(`${tKey}ORIGIN_COUNTRY`)}
                      placeholder={t(`${tKey}ORIGIN_COUNTRY`)}
                      options={countries}
                      disabled={!countries || countries.length === 0 || readOnlyFields || this.state.fieldsConfiguration.countryId.permission !== permissions.editable}
                    />
                  )
                }
                {this.state.fieldsConfiguration.provisionalLicensePlate?.permission !== permissions.hidden && (
                  <Field
                    component={InputText}
                    colWidht={33} // 25, 33, 50, 75, 100 default=25
                    id="provisionalLicensePlate"
                    name="vehicleComponent.provisionalLicensePlate"
                    controlLabel={t(`${tKey}PROVISIONAL_LICENSE_PLATE`)}
                    placeholder={t(`${tKey}PROVISIONAL_LICENSE_PLATE`)}
                    maxLength = {12}
                    disabled={true}
                  />
                )}
                {this.state.fieldsConfiguration.disabledValidateLicensePlate?.permission !== permissions.hidden && (
                  <Field
                    component={InputCheckbox}
                    colWidht={25} // 25, 33, 50, 75, 100 default=25
                    id="disabledValidateLicensePlate"
                    name="vehicleComponent.disabledValidateLicensePlate"
                    placeholder={t(`${tKey}DISABLED_VALIDATE_LICENSE_PLATE`)}
                    onChange= {(value) => (this.changeBoolLicensePlate(value))}
                    disabled={readOnlyFields || this.state.noEditableVehicle || this.state.fieldsConfiguration.disabledValidateLicensePlate.permission !== permissions.editable}
                    tooltip={ editTooltip }
                  />
                )}
              </Row>
            }
            <CustomFields customFields={customFields} readOnlyFields={readOnlyFields} />
          </Panel.Collapse>
        </Panel>
      </div>
    )
  }
}

export default Vehicle
