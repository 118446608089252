import React, { PureComponent } from 'react'
import { Row, Col, Checkbox } from 'react-bootstrap'
import { reduxForm, Field } from 'redux-form'
import InputSelect from '../../../commons/form/InputSelect'

class Tab3 extends PureComponent {
  render () {
    return (
      <Col sm={12}>
        <Row>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">V.S.B:</span>
              <span className="second">V.S.B</span>
            </div>
          </Col>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Marca:</span>
              <span className="second">Marca</span>
            </div>
          </Col>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Familia:</span>
              <span className="second">Familia</span>
            </div>
          </Col>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Modelo:</span>
              <span className="second">Modelo</span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Acabado:</span>
              <span className="second">Acabado</span>
            </div>
          </Col>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Color:</span>
              <span className="second">Color</span>
            </div>
          </Col>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Chasis:</span>
              <span className="second">Chasis</span>
            </div>
          </Col>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Nº Pedido:</span>
              <span className="second">Nº Pedido</span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Matricula:</span>
              <span className="second">Matricula</span>
            </div>
          </Col>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Fecha matriculación:</span>
              <span className="second">Fecha matriculación</span>
            </div>
          </Col>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">KM:</span>
              <span className="second">KM</span>
            </div>
          </Col>
        </Row>
        <Row>
          <Field
            controlLabel='Combustible'
            colSm={3}
            id='fuelType'
            name='fuelType'
            placeholder='Combustible'
            labelKey='fuelType'
            valueKey='fuelType'
            component={InputSelect}
          />
          <Field
            controlLabel='Estado general'
            colSm={3}
            id='generalCondition'
            name='generalCondition'
            placeholder='Estado general'
            labelKey='generalCondition'
            valueKey='generalCondition'
            component={InputSelect}
          />
          <Field
            controlLabel='Procedencia'
            colSm={3}
            id='origin'
            name='origin'
            placeholder='Procedencia'
            labelKey='origin'
            valueKey='origin'
            component={InputSelect}
          />
          <Field
            controlLabel='Fecha ITV'
            colSm={3}
            id='itvDate'
            name='itvDate'
            placeholder='Fecha ITC'
            labelKey='itvDate'
            valueKey='itvDate'
            component={InputSelect}
          />
        </Row>
        <Row>
          <Col sm={3}>
            <Checkbox inline>
              Daños
            </Checkbox>
          </Col>
        </Row>
      </Col>
    )
  }
}

export default reduxForm({ form: 'newForm' })(Tab3)
