import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import ServiceAddModal from '../../../../components/dossiers/common/services/ServiceAddModal'
import { getFormValues } from 'redux-form'

export function mapStateToProps (state) {
  return {
    formValues: getFormValues('serviceAddModal')(state)
  }
}

export function mapDispatchToProps (dispatch) {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(ServiceAddModal))
