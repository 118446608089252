import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { fetchCustomFieldList, deleteCustomField, openCustomFieldModal, recoverCustomField } from '../../../actions/masters/customField/customField'
import CustomFieldMaster from '../../../components/masters/customFields/CustomFieldMaster'

export function mapStateToProps (state) {
  return {
    ...state.customField,
    combos: {
      dossierSubtypes: state.combos.dossierSubTypesCombo,
      customFieldTypes: state.combos.customFieldTypesCombo
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      deleteCustomField,
      openCustomFieldModal,
      fetchCustomFieldList,
      recoverCustomField
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(CustomFieldMaster))
