import reservationContractModal from '../../../constants/actions/dossier/common/reservationContractModal'

function initialState () {
  return {
    showReservationContractModal: false,
    printTemplate: null
  }
}

function openReservationContractModalSuccess (state, { financingAmount, printCheckList, checklistId }) {
  return {
    ...state,
    showReservationContractModal: true,
    financingAmount: financingAmount,
    printCheckList: printCheckList,
    checklistId: checklistId
  }
}

function closeReservationContractModalSuccess (state) {
  return {
    ...state,
    ...initialState()
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case reservationContractModal.OPEN_RESERVATION_CONTRACT_MODAL_SUCCESS:
      return openReservationContractModalSuccess(state, action)
    case reservationContractModal.CLOSE_RESERVATION_CONTRACT_MODAL_SUCCESS:
      return closeReservationContractModalSuccess(state, action)
    default:
      return state
  }
}
