import React, { PureComponent } from 'react'
import { Field, reduxForm, reset } from 'redux-form'
import { Form, OverlayTrigger, Button, Tooltip } from 'react-bootstrap'
import { validateDate, validateDates, validateVinChassis } from '../../../util/validationFunctions'
import SimpleTablePage from '../../../containers/commons/general/SimpleTablePage'
import IDocCarPagination from '../../../components/commons/iDocCarPagination'
import { dateToApply, permissions, clientInformation } from '../../../constants/backendIds'
import InputCheckbox from '../commons/form/InputCheckBox'
import { multipleNormalize, toUpperCase, trimAll } from '../../../util/normalizeFunctions'
import { formNames } from '../../../constants/search/formNames'
import moment from 'moment'
import SetAvailabilityDateModal from '../../../components/search/papServiceManager/SetAvailabilityDateModal'
import { getFieldsSectionPermissions } from '../../../util/permisionFunctions'
import settings from '../../../setting'
/// //////////////////////////////////////////////////////////////////////////////////////
import { getDateNowMaxDatePicker, fromCamelToUnderscore } from '../../../util/utils'
import MapSectionsManagement from '../../../_v2/util/map-sections-management'
import InputSelect from '../commons/form/InputSelect'
import InputDatePicker from '../commons/form/InputDatePicker'
import InputText from '../commons/form/InputText'
import VehiclePopover from '../../../_v2/components/search/VehiclePopover'
import DossierPopover from '../../../_v2/components/search/DossierPopover'
import { showFilter, hideFiltersBtn, toggleFilterClass, getIconClass } from '../../../_v2/util/management/ManagementUtils'

class SearchByPapForm extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      enableConfirmButton: false,
      showConfirmButton: false,
      dossierIdArray: [],
      availabilityDate: null,
      showConfirmationModal: false,
      selectAllDossiers: false,
      fieldsConfiguration: {
        edit_availability_date: null
      },
      loadedFieldsConfiguration: false,
      maxiTable: false,
      hideFilters: false
    }
  }

  validateDate (value, props) {
    const result = validateDate(value, props)
    return result === Object(result) ? undefined : result
  }

  exportData () {
    const tKey = 'SEARCH.CATEGORIES.PAP_SERVICES_MANAGER.'
    let columnsExcel = []
    columnsExcel.push(this.props.t(`${tKey}DOSSIER`).toUpperCase() + '#Dossier')
    columnsExcel.push(this.props.t(`${tKey}MODEL`).toUpperCase() + '#VehicleModel')
    columnsExcel.push(this.props.t(`${tKey}VEHICLE`).toUpperCase() + '#Vehicle')
    columnsExcel.push(this.props.t(`${tKey}ORGANIZED_UNIT`).toUpperCase() + '#OrganizedUnit')
    columnsExcel.push(this.props.t(`${tKey}DESTINY`).toUpperCase() + '#Destination')
    columnsExcel.push(this.props.t(`${tKey}APPLICATION_DATE`).toUpperCase() + '#ApplicationDate')
    columnsExcel.push(this.props.t(`${tKey}AVAILABILITY_DATE`).toUpperCase() + '#AvailabilityDate')
    columnsExcel.push(this.props.t(`${tKey}REQUIRED_DATE`).toUpperCase() + '#RequiredDate')
    this.exportToExcel(columnsExcel)
  }

  exportToExcel (data) {
    let cols = ''
    data.forEach(element => {
      cols += element + '$'
    })
    cols = cols.substring(0, cols.length - 1)
    let values = this.props.formValues
    this.props.actions.fetchPapServicesDownloadFromManager({ ...values.pap_services_manager, ...this.props.filters }, cols)
  }

  getSnapshotBeforeUpdate () {
    return null
  }

  componentDidUpdate (prevProps) {
    if ((this.props.permissions && this.props.permissions.sectionFieldsConfiguration) &&
      ((!prevProps.permissions || !prevProps.permissions.sectionFieldsConfiguration) || !this.state.loadedFieldsConfiguration)) {
      const fieldKeys = Object.keys(this.state.fieldsConfiguration)
      const permission = getFieldsSectionPermissions(this.props.permissions.sectionFieldsConfiguration, fieldKeys, this.props.permissions.permission)
      this.setState({ fieldsConfiguration: permission.fieldsConfiguration, loadedFieldsConfiguration: true })
    }

    if (this.props.hasSearch !== prevProps.hasSearch && this.props.hasSearch) {
      this.restartState()
    }
  }

  restartState () {
    this.setState({
      enableConfirmButton: false,
      dossierIdArray: [],
      availabilityDate: null,
      showConfirmationModal: false,
      selectAllDossiers: false
    })
  }

  selectAllDossiers (value) {
    const dossiers = []
    if (this.props.dossierData) {
      this.props.dossierData.map((row, idx) => {
        if (value) {
          dossiers.push(row.dossierId)
        }
      })
    }
    this.setState({ selectAllDossiers: value, enableConfirmButton: value, dossierIdArray: dossiers })
  }

  setCheckboxValue (dossierId, check) {
    const dossierIdArray = this.state.dossierIdArray
    const index = dossierIdArray.findIndex(d => d === dossierId)
    if (index < 0) {
      dossierIdArray.push(dossierId)
    } else {
      dossierIdArray.splice(index, 1)
    }
    const selectedDossiersCount = dossierIdArray.length
    this.setState({ dossierIdArray: dossierIdArray, enableConfirmButton: selectedDossiersCount > 0 })

    if (!check && this.state.selectAllDossiers) {
      this.setState({ selectAllDossiers: false })
    }

    if (this.props.dossierData && dossierIdArray.length === this.props.dossierData.length) {
      this.setState({ selectAllDossiers: true })
    }
  }

  handleClickReset (e) {
    if (e !== undefined) {
      e.preventDefault()
    }

    this.props.dispatch(reset('SearchByPapForm'))
    this.state.dossierIdToArray = []
    this.setState({ onlyFleets: true, checkAll: false, dossierIdToArray: [], dossierData: [], fields: [] })
  }

  getCheckboxValue (dossierId) {
    return this.state.dossierIdArray.includes(dossierId)
  }

  showConfirmationModal () {
    this.setState({ showConfirmationModal: true })
  }

  saveServicesAvailabilityDate (availabilityDate) {
    new Promise((resolve, reject) => {
      var fields = {
        DateFrom: this.props.fields.dataFrom,
        DateTo: this.props.fields.dataTo,
        DateToApply: this.props.fields.dateToApply,
        VinChassis: this.props.fields.vinchassis
      }
      return this.props.actions.saveServicesAvailabilityDate(this.state.dossierIdArray, availabilityDate, resolve, reject, fields)
    }).then(() => {
      this.selectAllDossiers(false)
      this.closeConfirmationModal()
      this.props.onPageChange(this.props.page)
    })
  }

  onClickResetSearch (event) {
    this.selectAllDossiers(false)
    this.props.onClickResetSearch(event)
  }

  changeSelectedDateToApply (val) {
    this.props.fieldFill({ dateToApply: val })
    this.setState({ showConfirmButton: val === dateToApply.APPLICATION_DATE })
  }

  closeConfirmationModal () {
    this.setState({ showConfirmationModal: false })
  }

  componentWillUnmount () {
    this.restartState()
  }

  customValidationToPapServicesManager (values) {
    const papServicesManagerValues = values[`${formNames.PAP_SERVICES_MANAGER}`]
    const someDateFieldIsNull = (!papServicesManagerValues || !papServicesManagerValues.dateFrom || !papServicesManagerValues.dateTo || !papServicesManagerValues.dateToApply)
    const someDateFieldIsNotNull = !!papServicesManagerValues && !!(!!papServicesManagerValues.dateFrom || !!papServicesManagerValues.dateTo || !!papServicesManagerValues.dateToApply)
    return !papServicesManagerValues || (settings.clientFieldValidation === clientInformation.VGR && !papServicesManagerValues.vinchassis && !someDateFieldIsNotNull) ||
      (someDateFieldIsNull && someDateFieldIsNotNull) || (settings.clientFieldValidation !== clientInformation.VGR && someDateFieldIsNull)
  }

  submitSearchForm (values) {
    if (this.customValidationToPapServicesManager(values)) {
      return this.props.actions.openModal('mustFillFiltersPapFinder')
    }

    this.props.actions.fetchPapServicesFromManager({ ...values.pap_services_manager, ...this.props.filters })
    this.setState({ dossierIdToArray: [], hideFilters: true, dynamicFilters: values, filterValues: { ...values.pap_services_manager, ...this.props.filters } })

    this.props.actions.setFilters(values)
  }

  onPageChange (page) {
    this.setState({ filterValues: { ...this.state.filterValues, page: page } })
    this.props.actions.fetchPapServicesFromManager({ ...this.state.filterValues, page })
  }

  onOrderChange (e) {
    const newOrderBy = this.props.filters.orderBy.includes(`%2B${e.currentTarget.dataset.field}`) ? `-${e.currentTarget.dataset.field}` : `%2B${e.currentTarget.dataset.field}`
    this.setState({ filterValues: { ...this.state.filterValues, orderBy: newOrderBy } })
    this.props.actions.fetchPapServicesFromManager({ ...this.state.filterValues, orderBy: newOrderBy })
  }

  toogleHideFilters () {
    this.setState({ hideFilters: !this.state.hideFilters })
  }

  toogleMaxiTable () {
    this.setState({ maxiTable: !this.state.maxiTable })
  }

  getDateTypeOptions () {
    const { t } = this.props
    const tKey = 'SEARCH.CATEGORIES.STOCKMANAGEMENT.DATE_TYPE_OPTIONS.'
    return [
      { id: '1', value: t(`${tKey}CREATION_DATE`) },
      { id: '2', value: t(`${tKey}RECEPTION_DATE`) },
      { id: '3', value: t(`${tKey}RATING_DATE`) },
      { id: '4', value: t(`${tKey}BUDGET_SENT_DATE`) },
      { id: '5', value: t(`${tKey}BUDGET_ACCEPTED_DATE`) },
      { id: '6', value: t(`${tKey}RECONDITIONING_CONFIRMATION_DATE`) }
    ]
  }

  getDataToApplyCombo () {
    const tKey = 'SEARCH.CATEGORIES.PAP_SERVICES_MANAGER.'
    return [{
      id: dateToApply.REQUIRED_DATE,
      value: this.props.t(`${tKey}REQUIRED_DATE`)
    }, {
      id: dateToApply.APPLICATION_DATE,
      value: this.props.t(`${tKey}APPLICATION_DATE`)
    }]
  }

  selectedFilters () {
    const filters = this.props.dynamicFilters
    const tKey = 'SEARCH.CATEGORIES.'
    return Object.keys(filters)
      .map((item) => {
        let text = item
        let value = null
        const list = []
        Object.keys(filters[item]).map((filter, index) => {
          text = this.props.t(`${tKey}${item.toUpperCase()}.${fromCamelToUnderscore(filter).toUpperCase()}`)
          switch (filter) {
            case 'dateToApply': {
              value = this.getDataToApplyCombo().find(d => d.id === filters[item][filter]).value
              break
            }
            case 'dateTo':
            case 'dateFrom': {
              const dateType = this.getDateTypeOptions().find(x => x.id === filters[item].dateType)?.value ?? ''
              text = text.replace('#DATETYPE#', dateType)
              value = filters[item][filter]
              break
            }
            default:
            { value = filters[item][filter]
              break }
          }

          if (value) {
            if (filter !== 'entityChildSelected') {
              list.push(<li key={index}>{text}: <strong>{value}</strong>;</li>)
            } else {
              list.push(<li key={index}> <strong>{text}</strong>;</li>)
            }
          }
        })
        return list
      })
  }

  render () {
    const {
      t, fields, fieldFill, className, classNameSelect, hasSearch, papServicesData = []
      , pages, page, count, vehicleData = [],
      handleSubmit // From search,
    } = this.props
    const secMapManagement = MapSectionsManagement.find(function (s) { return s.page === 'searchByPapServices' })
    const hasResults = papServicesData && papServicesData.length > 0
    const tKey = 'SEARCH.CATEGORIES.PAP_SERVICES_MANAGER.'
    return (
      <div className="inner-results-panel">
        <div className="section-results-header">
          <div className="section-header-title">
            <OverlayTrigger placement="right" overlay={<Tooltip id="editProductTooltip">{`${t((this.props.hideMenu ? 'GLOBAL.TABLES.SHOW_INNER_MENU' : 'GLOBAL.TABLES.HIDE_INNER_MENU'))}`}</Tooltip>}>
              <a onClick={() => this.props.toogleInnerMenu()} className={this.props.hideMenu ? 'collapseBtn' : 'collapseBtn closeInnerMenu'}><i className={this.props.hideMenu ? 'iDocIcon-Open-inner-menu' : 'iDocIcon-Close-inner-menu'}></i></a>
            </OverlayTrigger>
            <h4>{t(`${secMapManagement.title}`)}
              <OverlayTrigger placement="left" overlay={<Tooltip id="editProductTooltip">{`${t((this.state.hideFilters ? 'GLOBAL.TABLES.SHOW_FILTERS' : 'GLOBAL.TABLES.HIDE_FILTERS'))}`}</Tooltip>}>
                <a onClick={() => this.toogleHideFilters()} className={'toogleShowFilters ' + hideFiltersBtn(hasResults)}><i className={toggleFilterClass(hasResults)}></i></a>
              </OverlayTrigger>
            </h4>
          </div>
          <Form autoComplete="off" onSubmit={handleSubmit((values) => this.submitSearchForm(values))} className="filters">
            <div className={'search-form ' + (showFilter(hasResults, this.state.hideFilters) ? '' : 'hideFilters ')}>
              <SetAvailabilityDateModal
                showModal = {this.state.showConfirmationModal}
                closeModal = {() => this.closeConfirmationModal() }
                saveServicesAvailabilityDate = { (availabilityDate) => this.saveServicesAvailabilityDate(availabilityDate) }
                t = {t}
                disabledDate={
                  (!this.state.fieldsConfiguration.edit_availability_date ||
                  !this.state.fieldsConfiguration.edit_availability_date.permission > permissions.hidden) &&
                  settings.clientFieldValidation === clientInformation.VGR}
              />
              <Field
                component={InputText}
                colWidht={100} // 25, 33, 50, 75, 100 default=25
                id='vinchassis'
                name = {`${formNames.PAP_SERVICES_MANAGER}.vinchassis`}
                placeholder={t(`${tKey}VINCHASSIS`)}
                controlLabel={t(`${tKey}VINCHASSIS`)}
                onInputChange={(val) => fieldFill({ vinchassis: val })}
                maxLength = {17}
                type="text"
                normalize={value => multipleNormalize(value, [toUpperCase, trimAll])}
                customClass={fields.vinchassis ? className : ''}
              />
              <Field
                component={InputDatePicker}
                colWidht={33} // 25, 33, 50, 75, 100 default=25
                id='dateFrom'
                name={`${formNames.PAP_SERVICES_MANAGER}.dateFrom`}
                placeholder={t(`${tKey}DATE_FROM`)}
                controlLabel={t(`${tKey}DATE_FROM`)}
                validate={[this.validateDate]}
                onInputChange={(val) => fieldFill({ dataFrom: val })}
                customClass={fields.dataFrom ? className : ''}
                maxDate={getDateNowMaxDatePicker()}
              />
              <Field
                component={InputDatePicker}
                colWidht={33} // 25, 33, 50, 75, 100 default=25
                id='dateTo'
                name={`${formNames.PAP_SERVICES_MANAGER}.dateTo`}
                placeholder={t(`${tKey}DATE_TO`)}
                controlLabel={t(`${tKey}DATE_TO`)}
                validate={[this.validateDate]}
                onInputChange={(val) => fieldFill({ dataTo: val })}
                customClass={fields.dataTo ? className : ''}
                maxDate={getDateNowMaxDatePicker()}
              />
              <Field
                component={InputSelect}
                colWidht={33} // 25, 33, 50, 75, 100 default=25
                id= 'dateToApply'
                name={`${formNames.PAP_SERVICES_MANAGER}.dateToApply`}
                placeholder={t(`${tKey}DATE_TO_APPLY`)}
                controlLabel={t(`${tKey}DATE_TO_APPLY`)}
                menuContainerStyle={{ zIndex: 999 }}
                options={this.getDataToApplyCombo()}
                valueKey="id"
                labelKey="value"
                onInputChange={(val) => this.changeSelectedDateToApply(val)}
                customClass={fields.dateToApply ? classNameSelect : ''}
              />
              <div className='search-form-actions flexInLine al-R'>
                <Button id="btn_search"
                  type="submit"
                  className="_Btn accept stk">
                  <i className="iDocIcon-search"/>
                  {t('SEARCH.ACTIONS.SEARCH_BUTTON')}
                </Button>
                <Button
                  id="btn_reset"
                  onClick={(e) => this.handleClickReset(e)}
                  className={'_Btn' + (this.props.pristine ? ' accept' : ' accept')}
                  disabled={this.props.pristine}
                >
                  <i className="iDocIcon-clear-decline"/> {t('SEARCH.ACTIONS.RESET_BUTTON')}
                </Button>
              </div>
            </div>
          </Form>
        </div>
        <div className="section-results-body">
          <div className={'searchResoultsContainer' + (this.state.maxiTable ? ' maxiTable' : '')}>
            {hasSearch && count > 0 &&
                <div className="table-wrapper" key="table-wrapper">
                  <ul className="list-inline pull-left">
                    {this.selectedFilters().length === 0 ? <li>{t(`${tKey}NO_SELECTED_FILTERS`)}</li> : (this.selectedFilters())}
                    {this.selectedFilters().length === 0 ? null : (this.selectedFilters())}
                  </ul>
                  {/* table options, maximize, etc.... */}
                  <div className='flexInLine al-R'>
                    <div className='actionsField'>
                    </div>
                    <OverlayTrigger placement="left" overlay={<Tooltip id="editProductTooltip">{`${t((this.state.maxiTable ? 'GLOBAL.TABLES.MINIMIZE_TABLE' : 'GLOBAL.TABLES.MAXIMIZE_TABLE'))}`}</Tooltip>}>
                      <a onClick={() => this.toogleMaxiTable()} className='_Btn accept stk o-icn maximizeBtn'><i className={(this.state.maxiTable ? 'iDocIcon-Minimize' : 'iDocIcon-Maximize')}></i></a>
                    </OverlayTrigger>
                    <OverlayTrigger placement="left" overlay={<Tooltip id="editProductTooltip">{t('SEARCH.ACTIONS.EXPORT')}</Tooltip>}>
                      <a onClick={(event) => this.exportData(event)} className='_Btn new o-icn exportToExcel'><i className="iDocIcon-file-excel"></i></a>
                    </OverlayTrigger>
                  </div>
                  {/* table */}
                  <div className="table-responsive">
                    <SimpleTablePage
                      columns={[
                        <th key={0} data-field="dossier" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}DOSSIER`)}&nbsp;<i className={getIconClass('dossier', this.props.filters)}/>
                        </th>,
                        <th key={2} data-field="vehicleModel" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}MODEL`)}&nbsp;<i className={getIconClass('vehicleModel', this.props.filters)}/>
                        </th>,
                        <th key={1} data-field="vehicle">
                          {t(`${tKey}VEHICLE`)}&nbsp;
                        </th>,
                        <th key={3} data-field="organizedUnit" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}ORGANIZED_UNIT`)}&nbsp;<i className={getIconClass('organizedUnit', this.props.filters)}/>
                        </th>,
                        <th key={4} data-field="destination" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}DESTINY`)}&nbsp;<i className={getIconClass('destination', this.props.filters)}/>
                        </th>,
                        <th key={5} data-field="applicationDate" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}APPLICATION_DATE`)}&nbsp;<i className={getIconClass('applicationDate', this.props.filters)}/>
                        </th>,
                        <th key={6} data-field="availabilityDate" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}AVAILABILITY_DATE`)}&nbsp;<i className={getIconClass('availabilityDate', this.props.filters)}/>
                        </th>,
                        <th key={7} data-field="requiredDate" onClick={(event) => this.onOrderChange(event)}>
                          {t(`${tKey}REQUIRED_DATE`)}&nbsp;<i className={getIconClass('requiredDate', this.props.filters)}/>
                        </th>,
                        this.state.showConfirmButton && (
                          <th key={8} data-field="selectAllDossiers" >
                            <Field
                              id='chkSelectAll'
                              component={InputCheckbox}
                              name='selectAllDossiers'
                              onChange={(value) => this.selectAllDossiers(value)}
                              placeholder = {t(`${tKey}SELECT_ALL`)}
                              inline
                              checked = { this.state.selectAllDossiers }
                            />
                          </th>)
                      ]}
                      rows={papServicesData.map((row, idx) => (
                        <tr key={idx}>
                          <td className={'dossier see-more'}>
                            <DossierPopover
                              idx={idx}
                              t={t}
                              id={'popover-dossier' + idx}
                              dossier={row.dossier}
                            />
                          </td>
                          <td className="see-more">
                            <VehiclePopover
                              t={t}
                              vehicle={row.vehicle}
                              half={(vehicleData.length || 1) / (idx + 1)}
                              idx={idx}
                              id={'popover-vehicle' + idx}
                            />
                          </td>
                          <td>{row.vehicleModel}</td>
                          <td>{row.organizedUnit}</td>
                          <td>{row.destination}</td>
                          <td>{row.applicationDate ? moment(row.applicationDate).format('DD/MM/YYYY') : null}</td>
                          <td>{row.availabilityDate ? moment(row.availabilityDate).format('DD/MM/YYYY') : null}</td>
                          <td>{row.requiredDate ? moment(row.requiredDate).format('DD/MM/YYYY') : null}</td>
                          { this.state.showConfirmButton && (
                            <td>{<Field
                              component={InputCheckbox}
                              name={'selectedDossier' + row.dossierId}
                              onChange={(value) => this.setCheckboxValue(row.dossierId, value)}
                              checked = {this.getCheckboxValue(row.dossierId)}
                            />}</td>)}
                        </tr>
                      ))}
                    />
                  </div>
                  <div className="search-footer" key="search-footer">
                    <IDocCarPagination
                      id="btn_pag_dossiers"
                      page={page}
                      pagesCount={pages}
                      totalRows= {count}
                      onSelectAction={(page) => this.onPageChange(page)}
                    />
                  </div>
                  <div key="search-footer-actions" className="search-footer-actions">
                    { this.state.showConfirmButton &&
                  <Button
                    name='confirmPapButton'
                    onClick={(event) => this.showConfirmationModal(event)}
                    className="btn-AcceptPap"
                    disabled={!this.state.enableConfirmButton } >
                    {t(`${tKey}CONFIRM`)}
                  </Button>
                    }
                  </div>
                </div>
            }
            {hasSearch && count <= 0 &&
              <>
                <ul className="list-inline pull-left">
                  {this.selectedFilters().length === 0 ? <li>{t('SEARCH.NO_SELECTED_FILTERS')}</li> : (this.selectedFilters())}
                </ul>
                <p className='no_results'>{t('GLOBAL.TABLES.NO_RESULTS')}</p>
              </>
            }
          </div>
        </div>
      </div>
    )
  }
}

const validate = (values, { t }) => {
  const errors = {}
  if (values) {
    if (values.dossier && values.dossier.chassis && values.dossier.chassis.length < settings.chassisMinCharacter) {
      errors.dossier = {}
      errors.dossier.chassis = t('SEARCH.CATEGORIES.ERRORS.VINCHASIS').replace('##MIN_CHARACTERS##', settings.chassisMinCharacter)
    }
    if (values.vehicle && values.vehicle.chassis && values.vehicle.chassis.length < settings.chassisMinCharacter) {
      errors.vehicle = {}
      errors.vehicle.chassis = t('SEARCH.CATEGORIES.ERRORS.VINCHASIS').replace('##MIN_CHARACTERS##', settings.chassisMinCharacter)
    }
    const papServicesManager = values[`${formNames.PAP_SERVICES_MANAGER}`]
    if (papServicesManager) {
      let errorsOnPapServicesManager = {}
      if (settings.clientFieldValidation !== clientInformation.VGR && papServicesManager.vinchassis && papServicesManager.vinchassis.length < settings.chassisMinCharacter) {
        errorsOnPapServicesManager.vinchassis = t('SEARCH.CATEGORIES.ERRORS.VINCHASIS').replace('##MIN_CHARACTERS##', settings.chassisMinCharacter)
      }
      if (settings.clientFieldValidation === clientInformation.VGR && papServicesManager.vinchassis) {
        const validationVinChassis = validateVinChassis(papServicesManager.vinchassis, { t })
        if (!(Object.keys(validationVinChassis).length === 0)) {
          errorsOnPapServicesManager.vinchassis = validationVinChassis
        }
      }
      if (papServicesManager.dateFrom && papServicesManager.dateTo) {
        errorsOnPapServicesManager.dateTo = validateDates(t, papServicesManager.dateFrom, papServicesManager.dateTo)
      }
      errors[`${formNames.PAP_SERVICES_MANAGER}`] = errorsOnPapServicesManager
    }

    if (values.dossier) {
      errors.dossier = {}
      if (values.dossier && values.dossier.creationDateFrom && values.dossier.creationDateTo) {
        errors.dossier.creationDateTo = validateDates(t, values.dossier.creationDateFrom, values.dossier.creationDateTo)
      }
      if (values.dossier && values.dossier.orderDateFrom && values.dossier.orderDateTo) {
        errors.dossier.orderDateTo = validateDates(t, values.dossier.orderDateFrom, values.dossier.orderDateTo)
      }
      if (values.dossier && values.dossier.availabilityDateFrom && values.dossier.availabilityDateTo) {
        errors.dossier.availabilityDateTo = validateDates(t, values.dossier.availabilityDateFrom, values.dossier.availabilityDateTo)
      }
      if (values.dossier && values.dossier.vehicleLicensePlateDateFrom && values.dossier.vehicleLicensePlateDateTo) {
        errors.dossier.vehicleLicensePlateDateTo = validateDates(t, values.dossier.vehicleLicensePlateDateFrom, values.dossier.vehicleLicensePlateDateTo)
      }
    }
  }
  return errors
}

export default reduxForm({
  form: 'SearchByPapForm',
  validate
})(SearchByPapForm)
