export default {
  CLOSE_FLEET_CREATED_SELECT_CHARGE_MODAL: 'CLOSE_FLEET_CREATED_SELECT_CHARGE_MODAL',
  OPEN_FLEET_CREATED_SELECT_CHARGE_MODAL: 'OPEN_FLEET_CREATED_SELECT_CHARGE_MODAL',
  OPEN_ADD_DOSSIERS_TO_FLEET_MODAL: 'OPEN_ADD_DOSSIERS_TO_FLEET_MODAL',
  CLOSE_ADD_DOSSIERS_TO_FLEET_MODAL: 'CLOSE_ADD_DOSSIERS_TO_FLEET_MODAL',
  FETCH_DOSSIERS_TO_ADD_TO_FLEET: 'FETCH_DOSSIERS_TO_ADD_TO_FLEET',
  FETCH_DOSSIERS_TO_ADD_TO_FLEET_SUCCESS: 'FETCH_DOSSIERS_TO_ADD_TO_FLEET_SUCCESS',
  DOSSIERS_TO_ADD_TO_FLEET_RESET_SEARCH: 'DOSSIERS_TO_ADD_TO_FLEET_RESET_SEARCH',
  FETCH_ADD_DOSSIER_TO_FLEET: 'FETCH_ADD_DOSSIER_TO_FLEET',
  OPEN_DATA_SALES_FLEET_MODAL: 'OPEN_DATA_SALES_FLEET_MODAL',
  CLOSE_DATA_SALES_FLEET_MODAL: 'CLOSE_DATA_SALES_FLEET_MODAL',
  FETCH_SALESMAN_COMBOS: 'FETCH_SALESMAN_COMBOS',
  FETCH_SALES_TYPE_COMBO: 'FETCH_SALES_TYPE_COMBO',
  OPEN_DATA_CONTACT_FLEET_MODAL: 'OPEN_DATA_CONTACT_FLEET_MODAL',
  CLOSE_DATA_CONTACT_FLEET_MODAL: 'CLOSE_DATA_CONTACT_FLEET_MODAL',
  FETCH_ENTITY_OPERATE_FLEET_SUCCES: 'FETCH_ENTITY_OPERATE_FLEET_SUCCES',
  FETCH_ENTITY__OPERATE_CHILD_FLEET_SUCCES: 'FETCH_ENTITY__OPERATE_CHILD_FLEET_SUCCES',
  OPEN_DATA_PAPERWORK_FLEET_MODAL: 'OPEN_DATA_PAPERWORK_FLEET_MODAL',
  CLOSE_DATA_PAPERWORK_FLEET_MODAL: 'CLOSE_DATA_PAPERWORK_FLEET_MODAL',
  GET_FLEET_SHOW_ECONOMIC: 'GET_FLEET_SHOW_ECONOMIC',
  GET_FLEET_SHOW_ECONOMIC_SUCCESS: 'GET_FLEET_SHOW_ECONOMIC_SUCCESS',
  OPEN_DATA_FINANCING_FLEET_MODAL: 'OPEN_DATA_FINANCING_FLEET_MODAL',
  CLOSE_DATA_FINANCING_FLEET_MODAL: 'CLOSE_DATA_FINANCING_FLEET_MODAL',
  FETCH_DOCUMENT_DETAIL_FLEET: 'FETCH_DOCUMENT_DETAIL_FLEET',
  FETCH_DOCUMENT_DETAIL_FLEET_SUCCESS: 'FETCH_DOCUMENT_DETAIL_FLEET_SUCCESS',
  OPEN_DOCUMENT_DETAILS_FLEET_MODAL: 'OPEN_DOCUMENT_DETAILS_FLEET_MODAL',
  CLOSE_DOCUMENT_DETAILS_FLEET_MODAL: 'CLOSE_DOCUMENT_DETAILS_FLEET_MODAL',
  OPEN_DATA_DELIVERY_FLEET_MODAL: 'OPEN_DATA_DELIVERY_FLEET_MODAL',
  CLOSE_DATA_DELIVERY_FLEET_MODAL: 'CLOSE_DATA_DELIVERY_FLEET_MODAL',
  OPEN_CHARGE_DOSSIERS_TO_FLEET_MODAL: 'OPEN_CHARGE_DOSSIERS_TO_FLEET_MODAL',
  CLOSE_CHARGE_DOSSIERS_TO_FLEET_MODAL: 'CLOSE_CHARGE_DOSSIERS_TO_FLEET_MODAL',
  UPDATE_CHARGE_DOSSIERS_TO_FLEET_MODAL: 'UPDATE_CHARGE_DOSSIERS_TO_FLEET_MODAL',
  OPEN_CLOSE_UNATTENDED_PROCESS_DOWNLOAD_FILE_MODAL: 'OPEN_CLOSE_UNATTENDED_PROCESS_DOWNLOAD_FILE_MODAL',
  GET_UNATTENDED_PROCESS_FILE_LIST_INFO: 'GET_UNATTENDED_PROCESS_FILE_LIST_INFO',
  GET_UNATTENDED_PROCESS_FILE_LIST_INFO_SUCCESS: 'GET_UNATTENDED_PROCESS_FILE_LIST_INFO_SUCCESS',
  FETCH_FLEET_ORGANIZED_UNITS_SUCCESS: 'FETCH_FLEET_ORGANIZED_UNITS_SUCCESS',
  FETCH_FLEET_ORGANIZED_UNITS: 'FETCH_FLEET_ORGANIZED_UNITS',
  OPEN_CHARGE_DETAILS_TO_FLEET_MODAL: 'OPEN_CHARGE_DETAILS_TO_FLEET_MODAL',
  OPEN_CHARGE_DETAILS_TO_FLEET_MODAL_SUCCESS: 'OPEN_CHARGE_DETAILS_TO_FLEET_MODAL_SUCCESS',
  CLOSE_CHARGE_DETAILS_TO_FLEET_MODAL: 'CLOSE_CHARGE_DETAILS_TO_FLEET_MODAL'
}
