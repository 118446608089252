import React, { PureComponent } from 'react'
import { PowerBIEmbed } from 'powerbi-client-react'
import { models } from 'powerbi-client'

class PowerBiHomeReport extends PureComponent {
  render () {
    const {
      powerbiConfig: { id, embedUrl, embedToken: { token } },
      powerBiRefreshSession
    } = this.props

    return (
      <PowerBIEmbed
        embedConfig = {{
          type: 'report', // Supported types: report, dashboard, tile, visual and qna
          id: id,
          embedUrl: embedUrl,
          accessToken: token,
          tokenType: models.TokenType.Embed,
          settings: {
            panes: {
              filters: {
                expanded: false,
                visible: false
              }
            },
            navContentPaneEnabled: false,
            background: models.BackgroundType.Transparent
          }
        }}

        eventHandlers = {
          new Map([
            ['rendered', function () {
              powerBiRefreshSession()
            }]
          ])
        }

        cssClassName = { 'powerbi-report' }
      />
    )
  }
}

export default PowerBiHomeReport
