import React, { PureComponent } from 'react'
import { Field } from 'redux-form'
import { Col } from 'react-bootstrap'
import InputText from '../../../commons/form/InputText'
import { permissions } from '../../../../constants/backendIds'
import NormalizedAddress from '../../../dossiers/common/client/NormalizedAddress'

class Contact extends PureComponent {
  constructor (props) {
    super()
    this.state = {
      combo: 0
    }
  }

  validateSchema () {
    const {
      fieldsConfiguration
    } = this.props
    return (
      (fieldsConfiguration.contactStartStreet && fieldsConfiguration.contactStartStreet.permission !== permissions.hidden) ||
     (fieldsConfiguration.contactEnndStreet && fieldsConfiguration.contactEnndStreet.permission !== permissions.hidden) ||
     (fieldsConfiguration.contactExternalNumber && fieldsConfiguration.contactExternalNumber.permission !== permissions.hidden) ||
     (fieldsConfiguration.contactInternalNumber && fieldsConfiguration.contactInternalNumber.permission !== permissions.hidden) ||
     (fieldsConfiguration.contactCountryCode && fieldsConfiguration.contactCountryCode.permission !== permissions.hidden) ||
     (fieldsConfiguration.contactStateCode && fieldsConfiguration.contactStateCode.permission !== permissions.hidden) ||
     (fieldsConfiguration.contactCityCode && fieldsConfiguration.contactCityCode.permission !== permissions.hidden) ||
     (fieldsConfiguration.contactTownCode && fieldsConfiguration.contactTownCode.permission !== permissions.hidden) ||
     (fieldsConfiguration.contactColonyCode && fieldsConfiguration.contactColonyCode.permission !== permissions.hidden) ||
     (fieldsConfiguration.contactCpCode && fieldsConfiguration.contactCpCode.permission !== permissions.hidden)
    )
  }

  longSchema () {
    const {
      t,
      fieldsConfiguration,
      actions: {
        fetchEntityCountryTypeCombo,
        fetchEntityStateTypeCombo,
        fetchEntityCityTypeCombo,
        fetchEntityTownTypeCombo,
        fetchEntityColonyTypeCombo,
        fetchEntityCPTypeCombo,
        fetchClientDataAddress
      }
    } = this.props

    const tKey = 'DOSSIER_COMPONENTS.ENTITY.'
    return (
      <Col sm={12} className="col-row">
        {fieldsConfiguration.contactName.permission !==
          permissions.hidden && (
          <Field
            colSm={4}
            id="contactName"
            name="dossierContactComponent.contactName"
            key="dossierContactComponent.contactName"
            controlLabel={t(`${tKey}CONTACT_NAME`)}
            placeholder={t(`${tKey}CONTACT_NAME`)}
            component={InputText}
            disabled={true}
          />
        )}
        {fieldsConfiguration.contactSurname1.permission !==
          permissions.hidden && (
          <Field
            colSm={4}
            id="contactSurname1"
            name="dossierContactComponent.contactSurname1"
            key="dossierContactComponent.contactSurname1"
            controlLabel={t(`${tKey}CONTACT_SURNAME1`)}
            placeholder={t(`${tKey}CONTACT_SURNAME1`)}
            component={InputText}
            disabled={true}
          />
        )}
        {fieldsConfiguration.contactSurname2.permission !==
          permissions.hidden && (
          <Field
            colSm={4}
            id="contactSurname2"
            name="dossierContactComponent.contactSurname2"
            key="dossierContactComponent.contactSurname2"
            controlLabel={t(`${tKey}CONTACT_SURNAME2`)}
            placeholder={t(`${tKey}CONTACT_SURNAME2`)}
            component={InputText}
            disabled={true}
          />
        )}
        <div className="clearfix" />
        {fieldsConfiguration.contactEmail.permission !==
          permissions.hidden && (
          <Field
            id="contactEmail"
            name="dossierContactComponent.contactEmail"
            key="dossierContactComponent.contactEmail"
            colSm={4}
            controlLabel={t(`${tKey}EMAIL`)}
            placeholder={t(`${tKey}EMAIL`)}
            component={InputText}
            disabled={true}
          />
        )}
        {fieldsConfiguration.contactPhone.permission !==
          permissions.hidden && (
          <Field
            id="contactPhone"
            name="dossierContactComponent.contactPhone"
            key="dossierContactComponent.contactPhone"
            colSm={4}
            controlLabel={t(`${tKey}PHONE`)}
            placeholder={t(`${tKey}PHONE`)}
            component={InputText}
            disabled={true}
          />
        )}
        {fieldsConfiguration.contactMobilePhone.permission !==
          permissions.hidden && (
          <Field
            id="contactMobilePhone"
            name="dossierContactComponent.contactMobilePhone"
            key="dossierContactComponent.contactMobilePhone"
            colSm={4}
            controlLabel={t(`${tKey}MOBILE`)}
            placeholder={t(`${tKey}MOBILE`)}
            component={InputText}
            disabled={true}
          />
        )}
        <div className="clearfix" />

        <NormalizedAddress
          t={t}
          fieldsConfiguration={{
            countryCode: fieldsConfiguration.contactCountryCode,
            stateCode: fieldsConfiguration.contactStateCode,
            cityCode: fieldsConfiguration.contactCityCode,
            townCode: fieldsConfiguration.contactTownCode,
            colonyCode: fieldsConfiguration.contactColonyCode,
            cpCode: fieldsConfiguration.contactCpCode
          }}
          readOnlyFields={true}
          allInformationDisabled={true}
          entityComponent= {this.props.dossier.dossierContactComponent}
          entityTypeName= "dossierContactComponent"
          actions={{
            fetchEntityCountryTypeCombo,
            fetchEntityStateTypeCombo,
            fetchEntityCityTypeCombo,
            fetchEntityTownTypeCombo,
            fetchEntityColonyTypeCombo,
            fetchEntityCPTypeCombo,
            fetchClientDataAddress
          }}
        />
        {fieldsConfiguration.contactAddress &&
          fieldsConfiguration.contactAddress.permission !==
            permissions.hidden && (
          <Field
            id="contactAddress"
            name="dossierContactComponent.contactAddress"
            key="dossierContactComponent.contactAddress"
            colSm={8}
            controlLabel={t(`${tKey}ADDRESS`)}
            placeholder={t(`${tKey}ADDRESS`)}
            component={InputText}
            disabled={true}
          />
        )}
        {fieldsConfiguration.contactExternalNumber && fieldsConfiguration.contactExternalNumber.permission !== permissions.hidden && (
          <Field
            id="externalNumber"
            name="dossierContactComponent.externalNumber"
            key="dossierContactComponent.externalNumber"
            colSm={4}
            disabled={true}
            controlLabel={t(`${tKey}EXTERNAL_NUMBER`)}
            placeholder={t(`${tKey}EXTERNAL_NUMBER`)}
            component={InputText}
            maxLength="20"
          />
        )}
        {fieldsConfiguration.contactStartStreet && fieldsConfiguration.contactStartStreet.permission !== permissions.hidden && (
          <Field
            id="startStreet"
            name="dossierContactComponent.startStreet"
            key="dossierContactComponent.startStreet"
            colSm={8}
            disabled={true}
            controlLabel={t(`${tKey}START_STREET`)}
            placeholder={t(`${tKey}START_STREET`)}
            component={InputText}
            maxLength="200"
          />
        )}
        {fieldsConfiguration.contactInternalNumber && fieldsConfiguration.contactInternalNumber.permission !== permissions.hidden && (
          <Field
            id="internalNumber"
            name="dossierContactComponent.internalNumber"
            key="dossierContactComponent.internalNumber"
            colSm={4}
            disabled={true}
            controlLabel={t(`${tKey}INTERNAL_NUMBER`)}
            placeholder={t(`${tKey}INTERNAL_NUMBER`)}
            component={InputText}
            maxLength="20"
          />
        )}
        {fieldsConfiguration.contactEnndStreet && fieldsConfiguration.contactEnndStreet.permission !== permissions.hidden && (
          <Field
            id="enndStreet"
            name="dossierContactComponent.enndStreet"
            key="dossierContactComponent.enndStreet"
            colSm={8}
            disabled={true}
            controlLabel={t(`${tKey}ENND_STREET`)}
            placeholder={t(`${tKey}ENND_STREET`)}
            component={InputText}
            maxLength="200"
          />
        )}
      </Col>
    )
  }

  shortSchema () {
    const {
      t,
      fieldsConfiguration
    } = this.props

    const tKey = 'DOSSIER_COMPONENTS.ENTITY.'
    return (
      <Col sm={12} className="col-row">
        {fieldsConfiguration.contactName.permission !==
          permissions.hidden && (
          <Field
            colSm={4}
            id="contactName"
            name="dossierContactComponent.contactName"
            key="dossierContactComponent.contactName"
            controlLabel={t(`${tKey}CONTACT_NAME`)}
            placeholder={t(`${tKey}CONTACT_NAME`)}
            component={InputText}
            disabled={true}
          />
        )}
        {fieldsConfiguration.contactSurname1.permission !==
          permissions.hidden && (
          <Field
            colSm={4}
            id="contactSurname1"
            name="dossierContactComponent.contactSurname1"
            key="dossierContactComponent.contactSurname1"
            controlLabel={t(`${tKey}CONTACT_SURNAME1`)}
            placeholder={t(`${tKey}CONTACT_SURNAME1`)}
            component={InputText}
            disabled={true}
          />
        )}
        {fieldsConfiguration.contactSurname2.permission !==
          permissions.hidden && (
          <Field
            colSm={4}
            id="contactSurname2"
            name="dossierContactComponent.contactSurname2"
            key="dossierContactComponent.contactSurname2"
            controlLabel={t(`${tKey}CONTACT_SURNAME2`)}
            placeholder={t(`${tKey}CONTACT_SURNAME2`)}
            component={InputText}
            disabled={true}
          />
        )}
        <div className="clearfix" />
        {fieldsConfiguration.contactEmail.permission !==
          permissions.hidden && (
          <Field
            id="contactEmail"
            name="dossierContactComponent.contactEmail"
            key="dossierContactComponent.contactEmail"
            colSm={4}
            controlLabel={t(`${tKey}EMAIL`)}
            placeholder={t(`${tKey}EMAIL`)}
            component={InputText}
            disabled={true}
          />
        )}
        {fieldsConfiguration.contactPhone.permission !==
          permissions.hidden && (
          <Field
            id="contactPhone"
            name="dossierContactComponent.contactPhone"
            key="dossierContactComponent.contactPhone"
            colSm={4}
            controlLabel={t(`${tKey}PHONE`)}
            placeholder={t(`${tKey}PHONE`)}
            component={InputText}
            disabled={true}
          />
        )}
        {fieldsConfiguration.contactMobilePhone.permission !==
          permissions.hidden && (
          <Field
            id="contactMobilePhone"
            name="dossierContactComponent.contactMobilePhone"
            key="dossierContactComponent.contactMobilePhone"
            colSm={4}
            controlLabel={t(`${tKey}MOBILE`)}
            placeholder={t(`${tKey}MOBILE`)}
            component={InputText}
            disabled={true}
          />
        )}
        <div className="clearfix" />
        {fieldsConfiguration.contactAddress.permission !==
          permissions.hidden && (
          <Field
            id="contactAddress"
            name="dossierContactComponent.contactAddress"
            key="dossierContactComponent.contactAddress"
            colSm={4}
            controlLabel={t(`${tKey}ADDRESS`)}
            placeholder={t(`${tKey}ADDRESS`)}
            component={InputText}
            disabled={true}
          />
        )}
        {fieldsConfiguration.contactCity.permission !==
          permissions.hidden && (
          <Field
            id="contactCity"
            name="dossierContactComponent.contactCity"
            key="dossierContactComponent.contactCity"
            colSm={4}
            controlLabel={t(`${tKey}CITY`)}
            placeholder={t(`${tKey}CITY`)}
            component={InputText}
            disabled={true}
          />
        )}
        {fieldsConfiguration.contactState.permission !==
          permissions.hidden && (
          <Field
            id="contactState"
            name="dossierContactComponent.contactState"
            key="dossierContactComponent.contactState"
            colSm={4}
            controlLabel={t(`${tKey}STATE`)}
            placeholder={t(`${tKey}STATE`)}
            component={InputText}
            disabled={true}
          />
        )}
        <div className="clearfix" />
        {fieldsConfiguration.contactCp.permission !==
          permissions.hidden && (
          <Field
            id="contactCp"
            name="dossierContactComponent.contactCp"
            key="dossierContactComponent.contactCp"
            colSm={4}
            controlLabel={t(`${tKey}CP`)}
            placeholder={t(`${tKey}CP`)}
            component={InputText}
            disabled={true}
          />
        )}
        {fieldsConfiguration.contactCountry.permission !==
          permissions.hidden && (
          <Field
            id="contactCountry"
            name="dossierContactComponent.contactCountry"
            key="dossierContactComponent.contactCountry"
            colSm={4}
            controlLabel={t(`${tKey}COUNTRY`)}
            placeholder={t(`${tKey}COUNTRY`)}
            component={InputText}
            disabled={true}
          />
        )}
      </Col>
    )
  }

  render () {
    return (
      <div>
        { !this.validateSchema() ? (
          this.shortSchema()
        ) : (
          this.longSchema()
        ) }
      </div>
    )
  }
}

export default Contact
