import apiFetch from '../apiFetch'
export default function (saleTypeId, ouId, token, includeDeleted = false, includeValue = null) {
  const queryParamsArray = [
    ouId ? `organizedUnitId=${ouId}` : null,
    `includeDeleted=${includeDeleted}`,
      `includeValues=${includeValue ? includeValue.join() : ''}`
  ]
  const queryParams = queryParamsArray.reduce(
    (result, query, idx) => result + (query ? (result ? `&${query}` : `?${query}`) : '')
    , ''
  )
  return apiFetch({ endPoint: `saleType/${saleTypeId}/operationTypes` + queryParams, method: 'GET', body: null, token: token })
}
