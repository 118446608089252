import React, { PureComponent } from 'react'
import { Row, Col, Tab, Tabs, Button } from 'react-bootstrap'
import Tab1 from './Tabs/Tab1'
import Tab2 from './Tabs/Tab2'
import Tab3 from './Tabs/Tab3'
import Tab4 from './Tabs/Tab4'
import Step4 from './Step4/Step4'

class purchaseVNDossier extends PureComponent {
  render () {
    return (
      <Col sm={12} className="purchase-vn-dossier-wrapper dossier">
        <Row className="time-line-button">
          <Col sm={7} className="time-line">
            <div className="step-wrapper">
              <div className="step active">
                <span className="step-number">1</span>
                <span className="step-name">Aprobación</span>
              </div>
            </div>
            <div className="step-wrapper">
              <div className="step">
                <span className="step-number">2</span>
                <span className="step-name">Factura</span>
              </div>
            </div>
            <div className="step-wrapper">
              <div className="step">
                <span className="step-number">3</span>
                <span className="step-name">Pago</span>
              </div>
            </div>
            <div className="step-wrapper">
              <div className="step">
                <span className="step-number">4</span>
                <span className="step-name">Recepción</span>
              </div>
            </div>
          </Col>
          <Col sm={2} smPush={2} className="text-right btn-timeline">
            <Button className="btn-standard btn-continue">
              Documentación
            </Button>
          </Col>
        </Row>
        <Row className="band-user">
          <Col sm={3} className="band-icon-item">
            <i className="ico-user" />
            <div className="text-band">
              <span className="first">Nombre del gestor:</span>
              <span className="second">Nombre del gestor</span>
            </div>
          </Col>
          <Col sm={3} className="band-icon-item">
            <i className="ico-user" />
            <div className="text-band">
              <span className="first">Nombre del cliente:</span>
              <span className="second">Nombre del cliente</span>
            </div>
          </Col>
          <Col sm={3} className="band-icon-item">
            <i className="ico-car" />
            <div className="text-band">
              <span className="first">Marca:</span>
              <span className="second">Marca</span>
            </div>
          </Col>
          <Col sm={3} className="band-icon-item">
            <i className="ico-car" />
            <div className="text-band">
              <span className="first">Modelo:</span>
              <span className="second">Modelo</span>
            </div>
          </Col>
        </Row>
        <Row className="tabs-wrapper">
          <Tabs id="purchase-vn-dossier-tabs" defaultActiveKey={1}>
            <Tab eventKey={1} title='Expediente'>
              <Tab1/>
            </Tab>
            <Tab eventKey={2} title='Compra'>
              <Tab2/>
            </Tab>
            <Tab eventKey={3} title='Proveedor'>
              <Tab3/>
            </Tab>
            <Tab eventKey={4} title='Vehículo'>
              <Tab4/>
            </Tab>
          </Tabs>
        </Row>
        <Step4/>
      </Col>
    )
  }
}

export default purchaseVNDossier
