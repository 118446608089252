import apiFetch from '../apiFetch'

export default function (token, processingAgencyManagerId) {
  const queryParamsArray = [
    processingAgencyManagerId ? `processingAgencyManagerId=${processingAgencyManagerId}` : null
  ]

  const queryParams = queryParamsArray.reduce(
    (result, query, idx) => result + (query ? (result ? `&${query}` : `?${query}`) : '')
    , ''
  )

  return apiFetch({ endPoint: `ProcessingAgencyManagers/Recover/${queryParams}`, method: 'PUT', body: null, token: token })
}
