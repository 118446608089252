import apiFetch from '../apiFetch'
export default function (token, unattendedProcessId) {
  const queryParamsArray = [
        `unattendedProcessId=${unattendedProcessId}`
  ]

  const queryParams = queryParamsArray.reduce(
    (result, query, idx) => result + (query ? (result ? `&${query}` : `?${query}`) : '')
    , ''
  )
  return apiFetch({ endPoint: 'UnattendedProcess/AzureUriFromUnattendedProcess' + queryParams, method: 'GET', token: token })
}
