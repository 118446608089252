import React, { PureComponent } from 'react'
import { reduxForm, Field } from 'redux-form'
import { Row, Col, Table, Button, Tooltip, OverlayTrigger } from 'react-bootstrap'
import IDocCarPagination from '../../commons/iDocCarPagination'
import InputText from '../../commons/form/InputText'
import DocumentTypeModalPage from '../../../containers/masters/DocumentType/DocumentTypeModalPage'
import InputSelect from '../../commons/form/InputSelect'

class DocumentTypeMaster extends PureComponent {
  constructor () {
    super()
    this.state = {
      filter: {
        entityTypeId: null,
        text: null
      }
    }
  }

  getSortIcon (column = '') {
    switch (this.props.filter.orderBy) {
      case '+' + column:
        return 'ico-sort-selected-asc'
      case '-' + column:
        return 'ico-sort-selected-desc'
      default:
        return 'ico-sort'
    }
  }

  changeOrder (field) {
    switch (this.props.filter.orderBy) {
      case `+${field}`:
        this.props.actions.fetchDocumentTypeList({ ...this.state.filter, ...this.props.filter, orderBy: `-${field}` })
        break
      default:
        this.props.actions.fetchDocumentTypeList({ ...this.state.filter, ...this.props.filter, orderBy: `+${field}` })
        break
    }
  }

  handleTextChange (text) {
    if (this.state.filter.text !== text) {
      this.setState({ filter: { ...this.state.filter, text } })
    }
  }

  handleEntityChange (entityTypeId) {
    if (this.state.filter.entityTypeId !== entityTypeId) {
      this.setState({ filter: { ...this.state.filter, entityTypeId } })
    }
  }

  searchDocumentType () {
    this.props.actions.fetchDocumentTypeList({ ...this.props.filter, ...this.state.filter, page: 1 })
  }

  resetFilters () {
    this.setState({
      filter: {
        entityTypeId: null,
        text: null
      }
    }, () => { this.searchDocumentType() })
  }

  render () {
    const {
      t, documentTypeList, pagesCount, documentTypesCount, filter,
      combos: { documentTypeEntities },
      filter: { page },
      actions: { openDocumentTypeModal, fetchDocumentTypeList, deleteDocumentType }
    } = this.props
    const tKey = 'MASTERS.DOCUMENT_TYPE_MASTERS.'
    const editTooltip = (<Tooltip id="editTooltip">{t(`${tKey}EDIT`)}</Tooltip>)
    const deleteTooltip = (<Tooltip id="deleteTooltip">{t(`${tKey}DELETE`)}</Tooltip>)
    const hasFilters = (this.state.filter.entityTypeId || this.state.filter.text)
    return (
      <div className="admin-wrapper">
        <DocumentTypeModalPage/>
        <Row className="section-users-header">
          <Col sm={12}>
            <div className="header-panel">
              <h4>{t(`${tKey}DOCUMENT_TYPE`)}</h4>
            </div>
          </Col>
        </Row>
        <Row>
          <div className="search-form">
            <span className="title-search"><i className="ico-search" /> {t(`${tKey}SEARCH_TITLE`)}</span>
            <Row>
              <Field
                name="filters.entityTypeId"
                colSm={4}
                controlLabel={t(`${tKey}ENTITY_TYPE`)}
                placeholder={t(`${tKey}ENTITY_TYPE`)}
                component={InputSelect}
                valueKey="id"
                labelKey="value"
                options={documentTypeEntities}
                onInputChange={(value) => this.handleEntityChange(value)}
              />
              <Field
                name="filters.text"
                colSm={4}
                controlLabel={t(`${tKey}TEXT`)}
                placeholder={t(`${tKey}TEXT`)}
                valueKey="id"
                labelKey="value"
                component={InputText}
                maxLength={50}
                onInputChange={(value) => this.handleTextChange(value)}
              />
              <Col sm={4} className="btn-wrapper">
                <p></p>
                <Button
                  className={'btn-white btn-icon btn-input btn-white search-button'}
                  style={{ width: '100%' }}
                  onClick={() => this.searchDocumentType()}>
                  <i className="ico-search" />
                  {t(`${tKey}SEARCH_BTN`)}
                </Button>

                <Button
                  className={'btn-icon btn-input ' + (hasFilters ? 'btn-standard' : 'btn-cancel')}
                  onClick={() => {
                    this.props.initialize({})
                    this.resetFilters()
                  }}
                >
                  <i className="ico-cancel" />
                  {t('MASTERS.RESET_BUTTON')}
                </Button>
              </Col>
            </Row>
          </div>
        </Row>
        <Col sm={12} className="col-row  section-users-body">
          <Table className="editable-table" hover responsive>
            <thead>
              <tr>
                <th onClick={() => { this.changeOrder('documentTypeId') }}>
                  {t(`${tKey}ID`)}
                  <i className={this.getSortIcon('documentTypeId')} />
                </th>
                <th onClick={() => { this.changeOrder('documentTypeEntityId') }}>
                  {t(`${tKey}ENTITY_TYPE`)}
                  <i className={this.getSortIcon('documentTypeEntityId')} />
                </th>
                <th onClick={() => { this.changeOrder('localeName') }}>
                  {t(`${tKey}NAME`)}
                  <i className={this.getSortIcon('localeName')} />
                </th>
                <th onClick={() => { this.changeOrder('localeDescription') }}>
                  {t(`${tKey}DESCRIPTION`)}
                  <i className={this.getSortIcon('localeDescription')} />
                </th>
                <th style={{ width: '77px' }}>{t('DOSSIER_COMPONENTS.ACCESSORIES.ACTIONS')}</th>
              </tr>
            </thead>
            <tbody>
              {documentTypeList && documentTypeList.map((documentType, idx) => {
                return (
                  <tr key={idx} className={documentType.isDeleted ? 'is-deleted' : ''}>
                    <td>{documentType.id}</td>
                    <td>{documentTypeEntities && documentTypeEntities.find(x => x.id === documentType.documentTypeEntityId) && documentTypeEntities.find(x => x.id === documentType.documentTypeEntityId).value}</td>
                    <td>{documentType.documentTypeLocales && documentType.documentTypeLocales.length > 0 ? documentType.documentTypeLocales.find(lang => (lang.languageId ? lang.languageId : '') === this.props.languageSelected)?.name : ''}</td>
                    <td>{documentType.documentTypeLocales && documentType.documentTypeLocales.length > 0 ? documentType.documentTypeLocales.find(lang => (lang.languageId ? lang.languageId : '') === this.props.languageSelected)?.description : ''}</td>
                    <td>
                      {!documentType.isDeleted && (
                        <div>
                          <a onClick={() => openDocumentTypeModal(documentType)}>
                            <OverlayTrigger placement="left" overlay={editTooltip}>
                              <i className="ico-edit-white" />
                            </OverlayTrigger>
                          </a>
                          <a onClick={() => deleteDocumentType(documentType.id)}>
                            <OverlayTrigger placement="left" overlay={deleteTooltip}>
                              <i className="ico-trash" />
                            </OverlayTrigger>
                          </a>
                        </div>
                      )}
                    </td>
                  </tr>)
              })
              }
              {(documentTypeList === null || documentTypeList === undefined || documentTypeList.length === 0) &&
            <tr>
              <td colSpan={10}><h2>{t(`${tKey}RESULTS_NOT_FOUND`)}</h2></td>
            </tr>}
              <tr className="button-table-wrapper">
                <td>
                  <Button
                    className="btn-editable-table btn-standard"
                    onClick={() => openDocumentTypeModal(null)}>
                  + {t(`${tKey}ADD`)}
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>
          <div className="section-users-footer">
            <nav key={1} className="text-center">
              <IDocCarPagination
                id="btn_pag_sales"
                page={page}
                pagesCount={pagesCount}
                totalRows={documentTypesCount}
                onSelectAction={(value) => fetchDocumentTypeList({ ...filter, ...this.state.filter, page: value })}
              />
            </nav>
          </div>
        </Col>
      </div>
    )
  }
}

export default reduxForm({
  form: 'listDocumentType'
})(DocumentTypeMaster)
