import React, { PureComponent } from 'react'
import { Col, Tab, Nav, NavItem, Row } from 'react-bootstrap'
import TabPane1 from './TabPanes/TabPane1'
import TabPane2 from './TabPanes/TabPane2'
import TabPane3 from './TabPanes/TabPane3'
import TabPane4 from './TabPanes/TabPane4'
import TabPane5 from './TabPanes/TabPane5'
import TabPane6 from './TabPanes/TabPane6'
import TabPane7 from './TabPanes/TabPane7'
import TabPane8 from './TabPanes/TabPane8'
import TabPane9 from './TabPanes/TabPane9'
import TabPane10 from './TabPanes/TabPane10'
import TabPane11 from './TabPanes/TabPane11'
import ModalMeeting from './ModalStep1/ModalMeeting'

class Step1 extends PureComponent {
  render () {
    return (
      <Col sm={12}>
        <Tab.Container className="vertical-tabs" id="manage-step1-tabs" defaultActiveKey={1}>
          <Row className="clearfix">
            <Col sm={4}>
              <Nav bsStyle="pills" stacked >
                <NavItem eventKey={1}>
                  Planeto económico
                </NavItem>
                <NavItem eventKey={2}>
                  Descuentos
                </NavItem>
                <NavItem eventKey={3}>
                  Campañas
                </NavItem>
                <NavItem eventKey={4}>
                  UaC
                </NavItem>
                <NavItem eventKey={5}>
                  Financiación
                </NavItem>
                <NavItem eventKey={6}>
                  Servicios
                </NavItem>
                <NavItem eventKey={7}>
                  Opcionales/ accesorios
                </NavItem>
                <NavItem eventKey={8}>
                  Cobros
                </NavItem>
                <NavItem eventKey={9}>
                  Trámites tráfico
                </NavItem>
                <NavItem eventKey={10}>
                  Entrega
                </NavItem>
                <NavItem eventKey={11}>
                  Documentos
                </NavItem>
              </Nav>
            </Col>
            <Col sm={8}>
              <Tab.Content animation>
                <Tab.Pane eventKey={1}>
                  <TabPane1/>
                </Tab.Pane>
                <Tab.Pane eventKey={2}>
                  <TabPane2/>
                </Tab.Pane>
                <Tab.Pane eventKey={3}>
                  <TabPane3/>
                </Tab.Pane>
                <Tab.Pane eventKey={4}>
                  <TabPane4/>
                </Tab.Pane>
                <Tab.Pane eventKey={5}>
                  <TabPane5/>
                </Tab.Pane>
                <Tab.Pane eventKey={6}>
                  <TabPane6/>
                </Tab.Pane>
                <Tab.Pane eventKey={7}>
                  <TabPane7/>
                </Tab.Pane>
                <Tab.Pane eventKey={8}>
                  <TabPane8/>
                </Tab.Pane>
                <Tab.Pane eventKey={9}>
                  <TabPane9/>
                </Tab.Pane>
                <Tab.Pane eventKey={10}>
                  <TabPane10/>
                </Tab.Pane>
                <Tab.Pane eventKey={11}>
                  <TabPane11/>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
        <ModalMeeting/>
      </Col>
    )
  }
}

export default Step1
