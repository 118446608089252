import apiFetch from '../apiFetch'

export default function (token, organizedUnitId) {
  const queryParamsArray = [
    organizedUnitId ? `organizedUnitId=${organizedUnitId}` : null
  ]

  const queryParams = queryParamsArray.reduce(
    (result, query, idx) => result + (query ? (result ? `&${query}` : `?${query}`) : '')
    , ''
  )

  return apiFetch({ endPoint: 'destinationCrane' + queryParams, method: 'GET', body: null, token: token })
}
