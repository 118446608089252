import reassignReplacePurchaseModalActionTypes from '../../../constants/actions/dossier/common/reassignReplacePurchaseModal'

export function searchFilteredPurchaseDossier (filterObject, resolve, reject) {
  return {
    type: reassignReplacePurchaseModalActionTypes.SEARCH_FILTERED_PURCHASE_DOSSIER,
    filterObject,
    resolve,
    reject
  }
}

export function fetchAndOpenReplacePurchaseModal (modalType, pristine) {
  return {
    type: reassignReplacePurchaseModalActionTypes.FETCH_AND_OPEN_REASSIGN_PURCHASE_MODAL,
    modalType,
    pristine
  }
}

export function openReplacePurchaseModal (modalType, order) {
  return {
    type: reassignReplacePurchaseModalActionTypes.OPEN_REASSIGN_PURCHASE_MODAL,
    modalType,
    order
  }
}

export function closeReplacePurchaseModal () {
  return {
    type: reassignReplacePurchaseModalActionTypes.CLOSE_REASSIGN_PURCHASE_MODAL
  }
}

export function getDossierPurchaseSuccess (vehiclesPurchaseList) {
  return {
    type: reassignReplacePurchaseModalActionTypes.GET_DOSSIER_PURCHASE_SUCCESS,
    vehiclesPurchaseList
  }
}

export function selectReplacePurchase (purchaseType, purchaseId, orderId, parentIsHistorical, makerNumber, makerNumberSale, purchaseTypeId) {
  return {
    type: reassignReplacePurchaseModalActionTypes.SELECT_REPLACE_PURCHASE,
    purchaseType,
    purchaseId,
    orderId,
    parentIsHistorical,
    makerNumber,
    makerNumberSale,
    purchaseTypeId
  }
}

export function createOrderDossierPurchase (purchaseType, newOrder) {
  return {
    type: reassignReplacePurchaseModalActionTypes.CREATE_ORDER_DOSSIER_PURCHASE,
    purchaseType,
    newOrder
  }
}

export function fetchReassignPurchaseCatalogueModel (catalogueId, modelId) {
  return {
    type: reassignReplacePurchaseModalActionTypes.FETCH_REASSING_PURCHASE_VEHICLE_CATALOGUE_MODEL,
    catalogueId,
    modelId
  }
}

export default {
  searchFilteredPurchaseDossier,
  fetchAndOpenReplacePurchaseModal,
  openReplacePurchaseModal,
  closeReplacePurchaseModal,
  getDossierPurchaseSuccess,
  selectReplacePurchase,
  createOrderDossierPurchase,
  fetchReassignPurchaseCatalogueModel
}
