import { put, takeEvery, select, call } from 'redux-saga/effects'
import { change, reset, untouch, getFormValues, initialize } from 'redux-form'
import clientActionsTypes from '../../../../constants/actions/dossier/common/client'
import { entityTypesId } from '../../../../constants/backendIds'
import { entityRequiredFields } from '../../../../constants/validatedFields/entity'
import { showLoader, hideLoader } from '../../../../actions/common'
import messageModalActions from '../../../../actions/modals/messageModal'
import { fetchSalesDossierSuccess } from '../../../../actions/dossier/sales'
import { fetchPaperworkDossierSuccess } from '../../../../actions/dossier/paperwork'
import { fetchPurchaseDossierSuccess } from '../../../../actions/dossier/purchase'
import { fetchPurchaseDossier } from '../../purchase/fetchPurchaseDossier'
import { fetchCampaignDossierSuccess } from '../../../../actions/dossier/campaign'
import { fetchRegistrationDeliveryDossierSuccess } from '../../../../actions/dossier/registrationDelivery'
import { dossierTypes as dossierTypesConstant } from '.././../../../constants/dossier/common/dossierTypes'
import { yesNoModal } from '../../../modalsListeners/yesNoModal'
import { handleError } from '../../../errors/errorManager'
import { getAuth } from '../../../../selectors/access/auth'
import deleteEntityFromDossier from '../../../../services/entity/deleteEntityFromDossier'
import recoverEntityFromParent from '../../../../services/entity/recoverEntityFromParent'
import { fetchEntityFleetSucces, fetchEntityChildFleetSucces, fetchEntityUacModalSucces } from '../../../../actions/management/management'
import { fetchEntityOperateChildFleetSucces } from '../../../../actions/management/auxModals/auxModals'
function clearFields (entityTypeId, data) {
  const newData = {}
  for (const prop in data) {
    if (prop !== 'dniCif' && prop !== 'address' && prop !== 'dniCifValidationDisable' && prop !== 'representativeDniCifValidationDisable' && prop !== 'clientNumber' &&
    prop !== 'city' && prop !== 'state' && prop !== 'cp' && prop !== 'country' && prop !== 'phone' &&
    prop !== 'mobilePhone' && prop !== 'entityId' && prop !== 'email' &&
    (
      entityTypeId === entityTypesId.privateEntity &&
          (prop !== 'representativeDni' && prop !== 'representativeName' && prop !== 'representativeSurname1' && prop !== 'representativeSurname2') ||
        entityTypeId === entityTypesId.businessEntity &&
          (prop !== 'surname1' && prop !== 'surname2')
    ) &&
      prop !== 'name' && prop !== 'rfc' &&
      prop !== 'postalCodeCode' && prop !== 'colonyCode' && prop !== 'townCode' && prop !== 'cityCode' && prop !== 'stateCode' && prop !== 'countryCode' &&
      prop !== 'externalNumber' && prop !== 'startStreet' && prop !== 'internalNumber' && prop !== 'enndStreet' && prop !== 'sourceChannelId' && prop !== 'segmentation' &&
      prop !== 'iaeTypeId' && prop !== 'commercialSocietyTypeId' && prop !== 'activityBproId' && prop !== 'constitutionAct' && prop !== 'constitutionDate'
    ) {
      newData[prop] = data[prop]
    }
  }
  return newData
}

function * clearErrors (formName, entityTypeId, dossierSubtypeId) {
  const fields = Object.keys(entityRequiredFields(entityTypeId, dossierSubtypeId))
  let index = fields.length - 1
  while (index >= 0) {
    yield put(untouch(formName, 'entityComponent.' + fields[index]))
    index--
  }
}

export function * setEntityTypeId ({ entityTypeId, prevEntityTypeId, dossierType, resolve, reject }) {
  try {
    if (entityTypeId === prevEntityTypeId) return

    const state = yield select(state => state)
    let formName, fetchDossierSuccess, dossierEntityState, dossierEntityForm, hasPreviousData, confirmedSaveWarning
    let data = {}
    switch (dossierType) {
      case dossierTypesConstant.sales:
      { formName = 'salesDossier'
        dossierEntityState = state.salesDossier.entityComponent
        fetchDossierSuccess = fetchSalesDossierSuccess
        break }
      case dossierTypesConstant.paperwork:
      { formName = 'paperwork_dossier'
        dossierEntityState = state.paperworkDossier.entityComponent
        fetchDossierSuccess = fetchPaperworkDossierSuccess
        break }
      case dossierTypesConstant.purchase:
      { formName = 'purchase_dossier'
        dossierEntityState = state.purchaseDossier.entityComponent
        fetchDossierSuccess = fetchPurchaseDossierSuccess
        break }
      case dossierTypesConstant.campaign:
      { formName = 'campaign_dossier'
        dossierEntityState = state.campaignDossier.entityComponent
        fetchDossierSuccess = fetchCampaignDossierSuccess
        break }
      case dossierTypesConstant.registrationDelivery:
      { formName = 'registrationDeliveryDossier'
        dossierEntityState = state.registrationDeliveryDossier.entityComponent
        fetchDossierSuccess = fetchRegistrationDeliveryDossierSuccess
        break }
      case dossierTypesConstant.addNewFleetModal:
      { formName = 'addNewFleetModal'
        dossierEntityState = state.management.addNewFleetModal.entityComponent
        fetchDossierSuccess = fetchEntityFleetSucces
        break }
      case dossierTypesConstant.createNewFleetModal:
      { formName = 'createNewFleetModal'
        dossierEntityState = state.management.createNewFleetModal.entityComponent
        fetchDossierSuccess = fetchEntityFleetSucces
        break }
      case dossierTypesConstant.uacModal:
      { formName = 'uacModal'
        dossierEntityState = state.management.uacModal.entityComponent
        fetchDossierSuccess = fetchEntityUacModalSucces
        break }
    }

    dossierEntityForm = yield getFormValues(formName)(state)?.entityComponent

    if (dossierEntityForm !== null && dossierEntityForm !== undefined) {
      hasPreviousData = Object.keys(dossierEntityForm).filter(key => key !== 'previousEntity' && key !== 'entityTypeId' && key !== 'updated').length > 0 && dossierEntityState.entityTypeId
    } else {
      hasPreviousData = false
    }

    confirmedSaveWarning = false
    for (const prop in dossierEntityForm) {
      if (prop !== 'previousEntity' && prop !== 'entityTypeId') {
        data[prop] = dossierEntityForm[prop]
      }
    }
    data = { ...dossierEntityState, ...data }
    if (data.entityComponent != null) {
      data = {
        dniCifValidationDisable: false,
        entityTypeId: entityTypeId,
        representativeDniCifValidationDisable: false,
        updated: true
      }
      data.updated = true
    }
    data.updated = true

    if (hasPreviousData) confirmedSaveWarning = yield call(yesNoModal, 'commonDossierSaveAceptWarning')
    if (!hasPreviousData || (hasPreviousData && confirmedSaveWarning)) {
      if (entityTypeId && entityTypeId !== entityTypesId.recurrentEntity && prevEntityTypeId !== entityTypesId.recurrentEntity) {
        data.entityTypeId = entityTypeId
        data = clearFields(entityTypeId, data)
        yield clearErrors(formName, entityTypeId, state.salesDossier.dossierSubTypeId)
      } else {
        data = { entityTypeId, updated: true, previousEntity: dossierEntityState }
      }
      yield put(fetchDossierSuccess({ entityComponent: data }))
      yield put(change(formName, 'entityComponent', data))
      if (resolve) yield call(resolve)
    } else {
      yield put(change(formName, 'entityComponent', data))
    }
  } catch (error) {
    const customHandler = function * () {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.FETCH_COMBO_ERROR'))
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('purchase_dossier'))
      yield put(reset('paperwork_dossier'))
      yield put(reset('campaign_dossier'))
      yield put(reset('registrationDeliveryDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  }
}

export function * watchSetEntityTypeId () {
  yield takeEvery(clientActionsTypes.SET_ENTITY_TYPE_ID, setEntityTypeId)
}

export function * setEntityValidationDisable ({ dossierTypeId, disableDniCifValidation, disableRepDniCifValidation, disableChildDniCifValidation, disableChildRepDniCifValidation }) {
  const state = yield select(state => state)
  let fetchDossierSuccess
  let dossierEntityState = {}
  let dossierEntityChildState = {}
  switch (dossierTypeId) {
    case dossierTypesConstant.sales:
    { if (disableDniCifValidation !== null || disableRepDniCifValidation !== null) dossierEntityState = Object.assign({}, state.salesDossier.entityComponent)
      fetchDossierSuccess = fetchSalesDossierSuccess
      if (disableChildDniCifValidation !== null) dossierEntityChildState = Object.assign({}, state.salesDossier.entityChildComponent)
      break }
    case dossierTypesConstant.paperwork:
    { if (disableDniCifValidation !== null || disableRepDniCifValidation !== null) dossierEntityState = Object.assign({}, state.paperworkDossier.entityComponent)
      fetchDossierSuccess = fetchPaperworkDossierSuccess
      if (disableChildDniCifValidation !== null) dossierEntityChildState = Object.assign({}, state.paperworkDossier.entityChildComponent)
      break }
    case dossierTypesConstant.purchase:
    { if (disableDniCifValidation !== null || disableRepDniCifValidation !== null) dossierEntityState = Object.assign({}, state.purchaseDossier.entityComponent)
      fetchDossierSuccess = fetchPurchaseDossierSuccess
      if (disableChildDniCifValidation !== null) dossierEntityChildState = Object.assign({}, state.purchaseDossier.entityChildComponent)
      break }
    case dossierTypesConstant.campaign:
    { if (disableDniCifValidation !== null || disableRepDniCifValidation !== null) dossierEntityState = Object.assign({}, state.campaignDossier.entityComponent)
      fetchDossierSuccess = fetchCampaignDossierSuccess
      if (disableChildDniCifValidation !== null) dossierEntityChildState = Object.assign({}, state.campaignDossier.entityChildComponent)
      break }
    case dossierTypesConstant.createNewFleetModal:
    { if (disableDniCifValidation !== null || disableRepDniCifValidation !== null) dossierEntityState = Object.assign({}, state.management.createNewFleetModal.entityComponent)
      fetchDossierSuccess = fetchEntityFleetSucces
      if (disableChildDniCifValidation !== null) dossierEntityChildState = Object.assign({}, state.management.createNewFleetModal.entityChildComponent)
      break }
  }
  if (dossierEntityState !== {} && Object.keys(dossierEntityState).length > 0) {
    if (disableDniCifValidation !== null) dossierEntityState.dniCifValidationDisable = disableDniCifValidation
    if (disableRepDniCifValidation !== null) dossierEntityState.representativeDniCifValidationDisable = disableRepDniCifValidation
    yield put(fetchDossierSuccess({ entityComponent: dossierEntityState }))
  }
  if (dossierEntityChildState !== {} && Object.keys(dossierEntityChildState).length > 0) {
    if (disableChildDniCifValidation !== null) dossierEntityChildState.dniCifValidationDisable = disableChildDniCifValidation
    if (disableChildRepDniCifValidation !== null) dossierEntityChildState.representativeDniCifValidationDisable = disableChildRepDniCifValidation
    yield put(fetchDossierSuccess({ entityChildComponent: dossierEntityChildState }))
  }
}

export function * watchSetEntityValidationDisable () {
  yield takeEvery(clientActionsTypes.SET_ENTITY_VALIDATION_DISABLE, setEntityValidationDisable)
}

export function * setEntityChildTypeId ({ entityTypeId, prevEntityTypeId, dossierType, resolve, reject }) {
  try {
    if (entityTypeId === prevEntityTypeId) return

    const state = yield select(state => state)
    let formName, fetchDossierSuccess, dossierEntityChildState, dossierEntityForm, hasPreviousData, confirmedSaveWarning
    let data = {}
    let dossierSubtypeId
    switch (dossierType) {
      case dossierTypesConstant.sales:
      { formName = 'salesDossier'
        dossierEntityChildState = state.salesDossier.entityChildComponent
        fetchDossierSuccess = fetchSalesDossierSuccess
        dossierSubtypeId = state.salesDossier.dossierSubTypeId
        break }
      case dossierTypesConstant.paperwork:
      { formName = 'paperwork_dossier'
        dossierEntityChildState = state.paperworkDossier.entityChildComponent
        fetchDossierSuccess = fetchPaperworkDossierSuccess
        dossierSubtypeId = state.paperworkDossier.dossierSubTypeId
        break }
      case dossierTypesConstant.purchase:
      { formName = 'purchase_dossier'
        dossierEntityChildState = state.purchaseDossier.entityChildComponent
        fetchDossierSuccess = fetchPurchaseDossierSuccess
        dossierSubtypeId = state.purchaseDossier.dossierSubTypeId
        break }
      case dossierTypesConstant.campaign:
      { formName = 'campaign_dossier'
        dossierEntityChildState = state.campaignDossier.entityChildComponent
        fetchDossierSuccess = fetchCampaignDossierSuccess
        dossierSubtypeId = state.campaignDossier.dossierSubTypeId
        break }
      case dossierTypesConstant.registrationDelivery:
      { formName = 'registrationDeliveryDossier'
        dossierEntityChildState = state.registrationDeliveryDossier.entityChildComponent
        fetchDossierSuccess = fetchRegistrationDeliveryDossierSuccess
        dossierSubtypeId = state.registrationDeliveryDossier.dossierSubTypeId
        break }
      case dossierTypesConstant.createNewFleetModal:
      { formName = 'createNewFleetModal'
        dossierEntityChildState = state.management.createNewFleetModal.entityChildComponent
        fetchDossierSuccess = fetchEntityChildFleetSucces
        dossierSubtypeId = state.management.createNewFleetModal.dossierSubTypeId
        break }
      case dossierTypesConstant.dataContactFleetModal:
      { formName = 'dataContactFleetModal'
        dossierEntityChildState = state.managementAuxModals.dataContactFleetModal.dossier.entityChildComponent
        fetchDossierSuccess = fetchEntityOperateChildFleetSucces
        dossierSubtypeId = state.managementAuxModals.dataContactFleetModal.dossier.dossierSubTypeId
        break }
    }

    dossierEntityForm = yield getFormValues(formName)(state).entityChildComponent
    hasPreviousData = Object.keys(dossierEntityForm).filter(key => key !== 'previousEntity' && key !== 'entityTypeId').length > 0 && dossierEntityChildState.entityTypeId
    confirmedSaveWarning = false

    for (const prop in dossierEntityForm) {
      if (prop !== 'previousEntity' && prop !== 'entityTypeId') {
        data[prop] = dossierEntityForm[prop]
      }
    }
    data = { ...dossierEntityChildState, ...data }

    if (hasPreviousData) confirmedSaveWarning = yield call(yesNoModal, 'commonDossierSaveAceptWarning')

    if (!hasPreviousData || (hasPreviousData && confirmedSaveWarning)) {
      if (entityTypeId && entityTypeId !== entityTypesId.recurrentEntity && prevEntityTypeId !== entityTypesId.recurrentEntity) {
        data.entityTypeId = entityTypeId
        data = clearFields(entityTypeId, data)
      } else {
        data = { entityTypeId, previousEntity: dossierEntityChildState.previousEntity }
      }
      yield put(fetchDossierSuccess({ entityChildComponent: data }))
      yield put(change(formName, 'entityChildComponent', data))
      yield clearErrors(formName, entityTypeId, dossierSubtypeId)

      if (resolve) yield call(resolve)
    } else {
      yield put(change(formName, 'entityChildComponent', data))
    }
  } catch (error) {
    const customHandler = function * () {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.FETCH_COMBO_ERROR'))
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('purchase_dossier'))
      yield put(reset('paperwork_dossier'))
      yield put(reset('campaign_dossier'))
      yield put(reset('registrationDeliveryDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  }
}

export function * watchSetEntityChildTypeId () {
  yield takeEvery(clientActionsTypes.SET_ENTITY_CHILD_TYPE_ID, setEntityChildTypeId)
}

export function * setEntitySubTypeId ({ entitySubTypeId, dossierType, resolve, reject }) {
  const data = {
    entitySubTypeId,
    entityId: null
  }
  try {
    yield put(showLoader())
    switch (dossierType) {
      case dossierTypesConstant.sales:
      { const salesDossierEntity = yield select(state => state.salesDossier.entityComponent)
        data.entityTypeId = salesDossierEntity.entityTypeId
        data.previousEntity = salesDossierEntity.previousEntity
        data.updated = true
        yield put(fetchSalesDossierSuccess({ entityComponent: data }))
        yield put(change('salesDossier', 'entityComponent', data))
        break }
      case dossierTypesConstant.paperwork:
      { const paperworkDossierEntity = yield select(state => state.paperworkDossier.entityComponent)
        data.entityTypeId = paperworkDossierEntity.entityTypeId
        data.previousEntity = paperworkDossierEntity.previousEntity
        data.updated = true
        yield put(fetchPaperworkDossierSuccess({ entityComponent: data }))
        yield put(change('paperwork_dossier', 'entityComponent', data))
        break }
      case dossierTypesConstant.purchase:
      { const purchaseDossierEntity = yield select(state => state.purchaseDossier.entityComponent)
        data.entityTypeId = purchaseDossierEntity.entityTypeId
        data.previousEntity = purchaseDossierEntity.previousEntity
        data.updated = true
        yield put(fetchPurchaseDossierSuccess({ entityComponent: data }))
        yield put(change('purchase_dossier', 'entityComponent', data))
        break }
      case dossierTypesConstant.campaign:
      { const campaignDossierEntity = yield select(state => state.campaignDossier.entityComponent)
        data.entityTypeId = campaignDossierEntity.entityTypeId
        data.previousEntity = campaignDossierEntity.previousEntity
        data.updated = true
        yield put(fetchCampaignDossierSuccess({ entityComponent: data }))
        yield put(change('campaign_dossier', 'entityComponent', data))
        break }
      case dossierTypesConstant.registrationDelivery:
      { const registrationDeliveryDossierEntityDefault = yield select(state => state.registrationDeliveryDossier.entityTypeIdDefault)
        const registrationDeliveryDossierEntity = yield select(state => state.registrationDeliveryDossier.entityComponent)
        data.entityTypeId = registrationDeliveryDossierEntity.entityTypeId || registrationDeliveryDossierEntityDefault
        data.previousEntity = registrationDeliveryDossierEntity.previousEntity
        data.updated = true
        yield put(fetchRegistrationDeliveryDossierSuccess({ entityComponent: data }))
        yield put(change('registrationDeliveryDossier', 'entityComponent', data))
        break }
      case dossierTypesConstant.addNewFleetModal:
      { const salesDossierEntity = yield select(state => state.management.addNewFleetModal.entityComponent)
        data.entityTypeId = salesDossierEntity.entityTypeId
        data.previousEntity = salesDossierEntity.previousEntity
        data.updated = true
        yield put(fetchEntityFleetSucces({ entityComponent: data }))
        yield put(change('addNewFleetModal', 'entityComponent', data))
        break
      }
      case dossierTypesConstant.createNewFleetModal:
      { const salesDossierEntity = yield select(state => state.management.createNewFleetModal.entityComponent)
        data.entityTypeId = salesDossierEntity.entityTypeId
        data.previousEntity = salesDossierEntity.previousEntity
        data.updated = true
        yield put(fetchEntityFleetSucces({ entityComponent: data }))
        yield put(change('createNewFleetModal', 'entityComponent', data))
        break
      }
      case dossierTypesConstant.uacModal:
      { const salesDossierEntity = yield select(state => state.management.uacModal.entityComponent)
        data.entityTypeId = salesDossierEntity.entityTypeId
        data.previousEntity = salesDossierEntity.previousEntity
        data.updated = true
        yield put(fetchEntityUacModalSucces({ entityComponent: data }))
        yield put(change('uacModal', 'entityComponent', data))
        break
      }
    }
    if (resolve) yield call(resolve)
  } catch (error) {
    const customHandler = function * () {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.FETCH_COMBO_ERROR'))
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('purchase_dossier'))
      yield put(reset('paperwork_dossier'))
      yield put(reset('campaign_dossier'))
      yield put(reset('registrationDeliveryDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSetEntitySubTypeId () {
  yield takeEvery(clientActionsTypes.SET_ENTITY_SUBTYPE_ID, setEntitySubTypeId)
}

export function * setEntityProviderTypeId ({ entityTypeId, prevEntityTypeId, dossierType, resolve, reject }) {
  try {
    if (entityTypeId === prevEntityTypeId) return

    const state = yield select(state => state)
    let formName, fetchDossierSuccess, dossierEntityProviderState, dossierEntityProviderForm, hasPreviousData, confirmedSaveWarning
    let data = {}
    switch (dossierType) {
      case dossierTypesConstant.paperwork:
      { formName = 'paperwork_dossier'
        dossierEntityProviderState = state.paperworkDossier.entityProviderComponent
        fetchDossierSuccess = fetchPaperworkDossierSuccess
        break }
    }

    dossierEntityProviderForm = yield getFormValues(formName)(state)?.entityProviderComponent

    if (dossierEntityProviderForm !== null && dossierEntityProviderForm !== undefined) {
      hasPreviousData = Object.keys(dossierEntityProviderForm).filter(key => key !== 'previousEntity' && key !== 'entityTypeId' && key !== 'updated').length > 0 && dossierEntityProviderState.entityTypeId
    } else {
      hasPreviousData = false
    }

    confirmedSaveWarning = false
    for (const prop in dossierEntityProviderForm) {
      if (prop !== 'previousEntity' && prop !== 'entityTypeId') {
        data[prop] = dossierEntityProviderForm[prop]
      }
    }
    data = { ...dossierEntityProviderState, ...data }
    data.updated = true

    if (hasPreviousData) confirmedSaveWarning = yield call(yesNoModal, 'commonDossierSaveAceptWarning')
    if (!hasPreviousData || (hasPreviousData && confirmedSaveWarning)) {
      if (entityTypeId && entityTypeId !== entityTypesId.recurrentEntity && prevEntityTypeId !== entityTypesId.recurrentEntity) {
        data.entityTypeId = entityTypeId
        data = clearFields(entityTypeId, data)
        yield clearErrors(formName, entityTypeId, state.salesDossier.dossierSubTypeId)
      } else {
        data = { entityTypeId, updated: true, previousEntity: dossierEntityProviderState }
      }
      yield put(fetchDossierSuccess({ entityProviderComponent: data }))
      yield put(change(formName, 'entityProviderComponent', data))
      if (resolve) yield call(resolve)
    } else {
      yield put(change(formName, 'entityProviderComponent', data))
    }
  } catch (error) {
    const customHandler = function * () {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.FETCH_COMBO_ERROR'))
    }
    const sessionHandler = function * () {
      yield put(reset('paperwork_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  }
}

export function * watchSetEntityProviderTypeId () {
  yield takeEvery(clientActionsTypes.SET_ENTITY_PROVIDER_TYPE_ID, setEntityProviderTypeId)
}

export function * setEntityProviderSubTypeId ({ entitySubTypeId, dossierType, resolve, reject }) {
  const data = {
    entitySubTypeId,
    entityId: null,
    entityTypeId: null,
    previousEntity: null,
    updated: null
  }
  try {
    yield put(showLoader())
    switch (dossierType) {
      case dossierTypesConstant.paperwork: {
        const paperworkDossierEntityProvider = yield select(state => state.paperworkDossier.entityProviderComponent)
        data.entityTypeId = paperworkDossierEntityProvider.entityTypeId
        data.previousEntity = paperworkDossierEntityProvider.previousEntity
        data.updated = true
        yield put(fetchPaperworkDossierSuccess({ entityProviderComponent: data }))
        yield put(change('paperwork_dossier', 'entityProviderComponent', data))
        break
      }
    }
    if (resolve) yield call(resolve)
  } catch (error) {
    const customHandler = function * () {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.FETCH_COMBO_ERROR'))
    }
    const sessionHandler = function * () {
      yield put(reset('paperwork_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSetEntityProviderSubTypeId () {
  yield takeEvery(clientActionsTypes.SET_ENTITY_PROVIDER_SUBTYPE_ID, setEntityProviderSubTypeId)
}

export function * setEntityProviderValidationDisable ({ dossierTypeId, disableDniCifValidation, disableRepDniCifValidation, disableChildDniCifValidation, disableChildRepDniCifValidation }) {
  const state = yield select(state => state)
  let fetchDossierSuccess
  let dossierEntityProviderState = {}
  switch (dossierTypeId) {
    case dossierTypesConstant.paperwork:
    { if (disableDniCifValidation !== null || disableRepDniCifValidation !== null) dossierEntityProviderState = Object.assign({}, state.paperworkDossier.entityComponent)
      fetchDossierSuccess = fetchPaperworkDossierSuccess
      break }
  }
  if (dossierEntityProviderState !== {} && Object.keys(dossierEntityProviderState).length > 0) {
    if (disableDniCifValidation !== null) dossierEntityProviderState.dniCifValidationDisable = disableDniCifValidation
    if (disableRepDniCifValidation !== null) dossierEntityProviderState.representativeDniCifValidationDisable = disableRepDniCifValidation
    yield put(fetchDossierSuccess({ entityProviderComponent: dossierEntityProviderState }))
  }
}

export function * watchsetEntityProviderValidationDisable () {
  yield takeEvery(clientActionsTypes.SET_ENTITY_PROVIDER_VALIDATION_DISABLE, setEntityProviderValidationDisable)
}

export function * setEntityId ({ entityId, dossierType, resolve, reject }) {
  let data
  try {
    yield put(showLoader())
    switch (dossierType) {
      case dossierTypesConstant.sales:
      { const salesDossierEntity = yield select(state => state.salesDossier.entityComponent)
        data = {
          previousEntity: salesDossierEntity.previousEntity,
          entityTypeId: salesDossierEntity.entityTypeId,
          entitySubTypeId: salesDossierEntity.entitySubTypeId,
          entityId
        }
        yield put(fetchSalesDossierSuccess({ entityComponent: data }))
        yield put(change('salesDossier', 'entityComponent', data))
        break }
      case dossierTypesConstant.paperwork:
      { const paperworkDossierEntity = yield select(state => state.paperworkDossier.entityComponent)
        data = {
          previousEntity: paperworkDossierEntity.previousEntity,
          entityTypeId: paperworkDossierEntity.entityTypeId,
          entitySubTypeId: paperworkDossierEntity.entitySubTypeId,
          entityId
        }
        yield put(fetchPaperworkDossierSuccess({ entityComponent: data }))
        yield put(change('paperwork_dossier', 'entityComponent', data))
        break }
      case dossierTypesConstant.purchase:
      { const purchaseDossierEntity = yield select(state => state.purchaseDossier.entityComponent)
        data = {
          previousEntity: purchaseDossierEntity.previousEntity,
          entityTypeId: purchaseDossierEntity.entityTypeId,
          entitySubTypeId: purchaseDossierEntity.entitySubTypeId,
          entityId
        }
        yield put(fetchPurchaseDossierSuccess({ entityComponent: data }))
        yield put(change('purchase_dossier', 'entityComponent', data))
        break }
      case dossierTypesConstant.campaign:
      { const campaignDossierEntity = yield select(state => state.campaignDossier.entityComponent)
        data = {
          previousEntity: campaignDossierEntity.previousEntity,
          entityTypeId: campaignDossierEntity.entityTypeId,
          entitySubTypeId: campaignDossierEntity.entitySubTypeId,
          entityId
        }
        yield put(fetchCampaignDossierSuccess({ entityComponent: data }))
        yield put(change('campaign_dossier', 'entityComponent', data))
        break }
      case dossierTypesConstant.registrationDelivery:
      { const registrationDeliveryDossierEntityDefault = yield select(state => state.registrationDeliveryDossier.entityTypeIdDefault)
        const registrationDeliveryDossierEntity = yield select(state => state.registrationDeliveryDossier.entityComponent)
        data = {
          previousEntity: registrationDeliveryDossierEntity.previousEntity,
          entityTypeId: registrationDeliveryDossierEntity.entityTypeId || registrationDeliveryDossierEntityDefault,
          entitySubTypeId: registrationDeliveryDossierEntity.entitySubTypeId,
          entityId
        }
        yield put(fetchRegistrationDeliveryDossierSuccess({ entityComponent: data }))
        yield put(change('registrationDeliveryDossier', 'entityComponent', data))
        break }
      case dossierTypesConstant.addNewFleetModal:
      { const salesDossierEntity = yield select(state => state.management.addNewFleetModal.entityComponent)
        data = {
          previousEntity: salesDossierEntity.previousEntity,
          entityTypeId: salesDossierEntity.entityTypeId,
          entitySubTypeId: salesDossierEntity.entitySubTypeId,
          entityId
        }
        yield put(fetchEntityFleetSucces({ entityComponent: data }))
        yield put(change('addNewFleetModal', 'entityComponent', data))
        break }
      case dossierTypesConstant.createNewFleetModal:
      { const salesDossierEntity = yield select(state => state.management.createNewFleetModal.entityComponent)
        data = {
          previousEntity: salesDossierEntity.previousEntity,
          entityTypeId: salesDossierEntity.entityTypeId,
          entitySubTypeId: salesDossierEntity.entitySubTypeId,
          entityId
        }
        yield put(fetchEntityFleetSucces({ entityComponent: data }))
        yield put(change('createNewFleetModal', 'entityComponent', data))
        break
      }
      case dossierTypesConstant.uacModal:
      { const salesDossierEntity = yield select(state => state.management.uacModal.entityComponent)
        data = {
          previousEntity: salesDossierEntity.previousEntity,
          entityTypeId: salesDossierEntity.entityTypeId,
          entitySubTypeId: salesDossierEntity.entitySubTypeId,
          entityId
        }
        yield put(fetchEntityUacModalSucces({ entityComponent: data }))
        yield put(change('uacModal', 'entityComponent', data))
        break
      }
    }
    if (resolve) yield call(resolve)
  } catch (error) {
    const customHandler = function * () {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.FETCH_COMBO_ERROR'))
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('purchase_dossier'))
      yield put(reset('paperwork_dossier'))
      yield put(reset('campaign_dossier'))
      yield put(reset('registrationDeliveryDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSetEntityId () {
  yield takeEvery(clientActionsTypes.SET_ENTITY_ID, setEntityId)
}

export function * setEntityProviderId ({ entityId, dossierType, resolve, reject }) {
  let data
  try {
    yield put(showLoader())
    switch (dossierType) {
      case dossierTypesConstant.paperwork:
      { const paperworkDossierEntityProvider = yield select(state => state.paperworkDossier.entityProviderComponent)
        data = {
          previousEntity: paperworkDossierEntityProvider.previousEntity,
          entityTypeId: paperworkDossierEntityProvider.entityTypeId,
          entitySubTypeId: paperworkDossierEntityProvider.entitySubTypeId,
          entityId
        }
        yield put(fetchPaperworkDossierSuccess({ entityProviderComponent: data }))
        yield put(change('paperwork_dossier', 'entityProviderComponent', data))
        break }
    }
    if (resolve) yield call(resolve)
  } catch (error) {
    const customHandler = function * () {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.FETCH_COMBO_ERROR'))
    }
    const sessionHandler = function * () {
      yield put(reset('paperwork_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSetEntityProviderId () {
  yield takeEvery(clientActionsTypes.SET_ENTITY_PROVIDER_ID, setEntityProviderId)
}

export function * fetchClientFromParent ({ entityFromParentDossier, dossierType, entityParentId, pristine }) {
  try {
    const confirmModal = yield call(yesNoModal, 'purchaseDossierChangeEntity')
    if (!confirmModal) {
      yield put(change('purchase_dossier', 'entityFromParentDossier', !entityFromParentDossier))
      return
    }
    let data
    yield put(showLoader())
    switch (dossierType) {
      case dossierTypesConstant.purchase:
      { const auth = yield select(getAuth)
        let purchase = yield select(state => state.purchaseDossier)
        if (entityFromParentDossier) {
          yield call(recoverEntityFromParent, purchase.dossierId, auth.token)
          yield put(fetchPurchaseDossierSuccess({ entityComponent: data, entityFromParentDossier: entityFromParentDossier }))
          yield put(change('purchase_dossier', 'entityComponent', data))
          yield fetchPurchaseDossier({ dossierId: purchase.dossierId })
          if (pristine === true || pristine === undefined) {
            purchase = yield select(state => state.purchaseDossier)
            yield put(initialize('purchase_dossier', { ...purchase }))
          }
        } else {
          data = { previousEntity: purchase.entityComponent, updated: true }
          yield call(deleteEntityFromDossier, purchase.dossierId, auth.token, entityFromParentDossier)
          yield put(fetchPurchaseDossierSuccess({ entityComponent: data, entityFromParentDossier: entityFromParentDossier }))
          yield put(change('purchase_dossier', 'entityComponent', data))
          yield fetchPurchaseDossier({ dossierId: purchase.dossierId })
          if (pristine === true || pristine === undefined) {
            purchase = yield select(state => state.purchaseDossier)
            purchase.entityFromParentDossier = entityFromParentDossier
            yield put(initialize('purchase_dossier', { ...purchase }))
          }
        }
        break }
    }
  } catch (error) {
    const customHandler = function * () {
      yield put(messageModalActions.openErrorMessageModal('FETCH_CLIENT_FROM_PARENT'))
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('purchase_dossier'))
      yield put(reset('paperwork_dossier'))
      yield put(reset('campaign_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSetClientFromParent () {
  yield takeEvery(clientActionsTypes.FETCH_CLIENT_FROM_PARENT, fetchClientFromParent)
}

export function * clearClientFields ({ entityTypeId, dossierType }) {
  const state = yield select(state => state)

  let formName, dossierEntityState, dossierEntityForm
  let data = {}

  switch (dossierType) {
    case dossierTypesConstant.sales:
    { formName = 'salesDossier'
      dossierEntityState = state.salesDossier.entityComponent
      break }
    case dossierTypesConstant.paperwork:
    { formName = 'paperwork_dossier'
      dossierEntityState = state.paperworkDossier.entityComponent
      break }
    case dossierTypesConstant.purchase:
    { formName = 'purchase_dossier'
      dossierEntityState = state.purchaseDossier.entityComponent
      break }
    case dossierTypesConstant.campaign:
    { formName = 'campaign_dossier'
      dossierEntityState = state.campaignDossier.entityComponent
      break }
    case dossierTypesConstant.registrationDelivery:
    { formName = 'registrationDeliveryDossier'
      dossierEntityState = state.registrationDeliveryDossier.entityComponent
      break }
    case dossierTypesConstant.createNewFleetModal:
    { formName = 'createNewFleetModal'
      dossierEntityState = state.management.createNewFleetModal.entityComponent
      break }
  }
  for (const prop in dossierEntityForm) {
    if (prop !== 'previousEntity' && prop !== 'entityTypeId') {
      data[prop] = dossierEntityForm[prop]
    }
  }

  data.entityTypeId = entityTypeId
  data = { ...dossierEntityState, ...data }
  data.updated = true
  data = clearFields(entityTypeId, data)
  data.dniCifValidationDisable = dossierEntityState.dniCifValidationDisable
  data.representativeDniCifValidationDisable = dossierEntityState.representativeDniCifValidationDisable
  yield clearErrors(formName, entityTypeId, state.salesDossier.dossierSubTypeId)
  yield put(change(formName, 'entityComponent', data))
}

export function * watchClearClientFields () {
  yield takeEvery(clientActionsTypes.CLEAR_CLIENT_FIELDS, clearClientFields)
}
