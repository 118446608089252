import buttonsHeaderActionTypes from '../../../constants/actions/dossier/common/buttonsHeader'

export function setActiveTab (activeTab) {
  return {
    type: buttonsHeaderActionTypes.SET_ACTIVE_TAB,
    activeTab
  }
}

export function setButtonsHeaderHeight (height) {
  return {
    type: buttonsHeaderActionTypes.SET_BUTTONS_HEADER_HEIGHT,
    height
  }
}

export function resetButtonsHeader () {
  return {
    type: buttonsHeaderActionTypes.RESET_BUTTONS_HEADER
  }
}
export function changeDossierSubType (dossierId, lastAccessDate) {
  return {
    type: buttonsHeaderActionTypes.CHANGE_DOSSIER_SUBTYPE,
    dossierId,
    lastAccessDate
  }
}
export function submitSelectVoCompany (dossierId, organizedUnitId) {
  return {
    type: buttonsHeaderActionTypes.SALE_TO_VO_COMPANY,
    dossierId,
    organizedUnitId
  }
}

export function approveMargin (dossierId, code) {
  return {
    type: buttonsHeaderActionTypes.APPROVE_MARGIN,
    dossierId,
    code
  }
}

export default {
  setActiveTab,
  setButtonsHeaderHeight,
  resetButtonsHeader,
  changeDossierSubType,
  submitSelectVoCompany,
  approveMargin
}
