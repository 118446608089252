const managementSections =
  [
    {
      page: 'fleetsPage',
      id: 'btn_db_expediente',
      code: 'fleets',
      icon: 'iDocIcon-home-dashboard',
      title: 'MANAGEMENT.FLEETS'
    },
    {
      page: 'fleetFinderPage',
      id: 'btn_masters',
      code: 'fleetsManagement',
      icon: 'iDocIcon-admin-tools',
      title: 'MANAGEMENT.FLEETS_MANAGEMENT'
    },
    {
      page: 'unattendedProcessesFinderPage',
      id: 'btn_masters',
      code: 'unattendedProcess',
      icon: 'iDocIcon-admin-tools',
      title: 'MANAGEMENT.PROCESSES'
    },
    {
      page: 'fleetsManagerPage',
      id: 'btn_masters',
      code: 'fleet_management_section',
      icon: 'iDocIcon-admin-tools',
      title: 'MANAGEMENT.FLEETS_TAB.FLEETS_MANAGEMENT'
    },
    {
      page: 'selfSupplyManagementPage',
      id: 'btn_masters',
      code: 'self_supply_section',
      icon: 'iDocIcon-admin-tools',
      title: 'MANAGEMENT.SELF_SUPPLY_TAB.SELF_SUPPLY_MANAGEMENT'
    },
    {
      page: 'auditAdminPage',
      id: 'btn_masters',
      code: 'audit_admin',
      icon: 'iDocIcon-admin-tools',
      title: 'MANAGEMENT.ADMIN_AUDIT.ADMIN_AUDIT'
    },
    {
      page: 'auditsPage',
      id: 'btn_masters',
      code: 'audits',
      icon: 'iDocIcon-admin-tools',
      title: 'MANAGEMENT.ADMIN_AUDIT.AUDITS'
    },
    {
      page: 'searchDocumentalPage',
      id: 'btn_masters',
      code: 'search_documental',
      icon: 'iDocIcon-admin-tools',
      title: 'MANAGEMENT.SEARCH_DOCUMENTAL.TITLE_AUDIT'
    },
    {
      page: 'searchCharge',
      id: 'btn_masters',
      code: 'charge',
      icon: 'iDocIcon-admin-tools',
      title: 'SEARCH.CATEGORIES.CHARGE.BY_CHARGE'
    },
    {
      page: 'searchByPAP',
      id: 'btn_masters',
      code: 'prepare',
      icon: 'iDocIcon-admin-tools',
      title: 'SEARCH.CATEGORIES.SERVICE.PAP_MANAGEMENT'
    },
    {
      page: 'searchByPapServices',
      id: 'btn_masters',
      code: 'papServicesManager',
      icon: 'iDocIcon-admin-tools',
      title: 'SEARCH.CATEGORIES.PAP_SERVICES_MANAGER.TITLE'
    },
    {
      page: 'searchsPlanLogisticsPap',
      id: 'btn_masters',
      code: 'planLogisticsPap',
      icon: 'iDocIcon-admin-tools',
      title: 'SEARCH.CATEGORIES.PLAN_LOGISTICS_PAP.TITLE'
    },
    {
      page: 'searchByFleets',
      id: 'btn_masters',
      code: 'fleetsSearch',
      icon: 'iDocIcon-admin-tools',
      title: 'SEARCH.CATEGORIES.FLEETS.FLEETS_DOCUMENTATION'
    }
  ]

export default managementSections
