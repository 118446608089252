import React, { PureComponent } from 'react'
import { Panel, Row, Col, Table, Button } from 'react-bootstrap'
import InputText from '../../commons/form/InputText'
import InputNatural from '../../commons/form/InputNatural'
import InputSelect from '../../commons/form/InputSelect'
import InputDatePicker from '../../commons/form/InputDatePicker'
import InputTimePicker from '../../commons/form/InputTimePicker'
import InputCheckBox from '../../commons/form/InputCheckBox'
import { getFieldsPermisions } from '../../../util/permisionFunctions'
import { Field } from 'redux-form'
import DeliveryChecklistRow from '../sales/DeliveryCheckList/DeliveryChecklistRow'
import { permissions, clientInformation } from '../../../constants/backendIds'
import { dossierSubTypeId as dossierSubTypeIdConstants } from '../../../constants/dossier/common/dossierSubType'
import { validateDate } from '../../../util/validationFunctions'
import settings from '../../../setting'
import ChecklistDeliveryVoModal from './ChecklistDeliveryVoModal'
import CustomFields from '../../commons/customFields/CustomFields'

class Delivery extends PureComponent {
  constructor () {
    super()
    this.state = {
      open: false,
      fieldsConfiguration: {
        tuneUpDestinationId: null,
        differentDeliveryPerson: null,
        deliveryCity: null,
        km: null,
        externalDelivery: null,
        realDeliveryDate: null,
        realDeliveryTime: null,
        lastITVDate: null,
        realDeliveryAddress: null,
        realDeliveryLocationId: null,
        deliveryPAP: null,
        printCheckListVo: null,
        printChecklist: null,
        printDeliveryNoteChecklistVO: null,
        btnPrintDeliveryCertificates: null,
        observations: null,
        insuranceStartDate: null
      },
      showChecklistModalVo: false
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.open) {
      this.setState({ open: true })
    }
  }

  UNSAFE_componentWillMount () {
    this.initializePermisions(this.props)
  }

  componentDidUpdate (prevProps) {
    if (prevProps.componentConfiguration && this.props.componentConfiguration && (prevProps.componentConfiguration !== this.props.componentConfiguration)) {
      this.initializePermisions(this.props)
    }
  }

  initializePermisions (props) {
    const fieldKeys = Object.keys(this.state.fieldsConfiguration)
    const newState = getFieldsPermisions(props.componentConfiguration.fieldsConfiguration, fieldKeys, props.componentConfiguration.permission)
    this.setState({ ...this.state, ...newState })
  }

  fetchSalesDossierPromiseDelivery () {
    if (this.state.open) {
      this.props.actions.setActiveSection(false)
    }
    this.setState({
      open: !this.state.open
    })
    if (this.props.isDeliveryLoaded === false) {
      return new Promise((resolve, reject) => {
        this.props.handleToUpdateComponent(this.props)
        if (this.props.dossierSubTypeId !== dossierSubTypeIdConstants.registration_delivery || this.props.dossierSubTypeId !== dossierSubTypeIdConstants.model_agency) {
          return this.props.actions.fetchSalesDelivery(this.props.dossierId, resolve, reject, this.props.pristine)
        } else {
          return this.props.actions.fetchRegistrationDeliveryDelivery(this.props.dossierId, resolve, reject, this.props.pristine)
        }
      })
    }
  }

  dateValidation (value, allValues, props) {
    const result = validateDate(value, props)
    return result === Object(result) ? undefined : result
  }

  openModalprintChecklistVO () {
    this.setState({ showChecklistModalVo: !this.state.showChecklistModalVo })
  }

  render () {
    const {
      t, readOnlyFields,
      dossierCompleteDeliveryCheckListItems = [],
      dossierDeliveryCheckListPurchase = [],
      deliveryLocationCombo = [],
      isSalesDossier,
      isRegistrationDeliveryDossier,
      dossierId,
      dossierSubTypeId,
      componentConfiguration,
      printDeliveryChecklistPermission, customFields,
      actions: { printDeliveryChecklist, printChecklistVO, printDeliveryCertificates, printDeliveryChecklistSale },
      combos: { tuneUpDestinationCombo }
    } = this.props
    const isVN = dossierSubTypeId === dossierSubTypeIdConstants.compra_vn || dossierSubTypeId === dossierSubTypeIdConstants.venta_vn || dossierSubTypeId === dossierSubTypeIdConstants.registration_delivery || dossierSubTypeId === dossierSubTypeIdConstants.model_agency
    const tKey = 'DOSSIER_COMPONENTS.DELIVERY.'

    const useDeliveryNoteChecklistVO = this.state.fieldsConfiguration.printDeliveryNoteChecklistVO != null &&
      this.state.fieldsConfiguration.printDeliveryNoteChecklistVO.permission > permissions.hidden
    return (
      <>
        <ChecklistDeliveryVoModal
          showModal = {this.state.showChecklistModalVo}
          closeModalAction={this.openModalprintChecklistVO.bind(this)}
          t={t}
          dossierId = {dossierId}
          isSalesDossier={isSalesDossier || isRegistrationDeliveryDossier}
          printDeliveryChecklistSale = {printDeliveryChecklistSale}
          dossierSubTypeId ={dossierSubTypeId}
          useDeliveryNoteChecklistVO = {useDeliveryNoteChecklistVO}
        ></ChecklistDeliveryVoModal>
        <div className="delivery-panel dossier-panel">
          {!this.props.isFleet && (
            <div className="header-panel" onClick={this.fetchSalesDossierPromiseDelivery.bind(this)}>
              <div className="name-wrapper">
                <i className="ico-delivery" />
                <h4>{isSalesDossier || isRegistrationDeliveryDossier ? t(`${tKey}TITLE_PANEL`) : t(`${tKey}RECIVE_TITLE_PANEL`)}</h4>
              </div>
              <i className={this.state.open ? 'ico-arrow-black-up' : 'ico-arrow-black-down'} />
            </div>)}
          <Panel expanded={this.state.open} onToggle={() => {}}>
            <Panel.Collapse>
              <Row>
                {
                  this.state.fieldsConfiguration.externalDelivery.permission !== permissions.hidden && (<Field
                    id="externalDelivery"
                    name="dossierCompleteDeliveryComponent.dossierDeliveryComponent.externalDelivery"
                    placeholder={t(`${tKey}EXTERNAL_DELIVERY`)}
                    component={InputCheckBox}
                    disabled={readOnlyFields || componentConfiguration.permission !== permissions.editable}
                  />)
                }
              </Row>
              <Row>
                {this.state.fieldsConfiguration.realDeliveryDate.permission !== permissions.hidden && (<Field
                  id="date"
                  name="dossierCompleteDeliveryComponent.dossierDeliveryComponent.realDeliveryDate"
                  colSm={!isVN ? (4) : (3)}
                  controlLabel={!isSalesDossier && !isRegistrationDeliveryDossier ? (t(`${tKey}DATE_RECIVE`)) : (t(`${tKey}DATE_DELIVERY`))}
                  component={InputDatePicker}
                  validate={[this.dateValidation]}
                  disabled={readOnlyFields || componentConfiguration.permission !== permissions.editable}
                />)
                }
                {this.state.fieldsConfiguration.realDeliveryTime.permission !== permissions.hidden && (<Field
                  id="hour"
                  name="dossierCompleteDeliveryComponent.dossierDeliveryComponent.realDeliveryTime"
                  colSm={!isVN ? (4) : (3)}
                  controlLabel={!isSalesDossier && !isRegistrationDeliveryDossier ? (t(`${tKey}HOUR_RECIVE`)) : (t(`${tKey}HOUR_DELIVERY`))}
                  placeholder={!isSalesDossier && !isRegistrationDeliveryDossier ? (t(`${tKey}HOUR_RECIVE`)) : (t(`${tKey}HOUR_DELIVERY`))}
                  component={InputTimePicker}
                  disabled={readOnlyFields || componentConfiguration.permission !== permissions.editable}
                />)
                }
                {this.state.fieldsConfiguration.lastITVDate.permission !== permissions.hidden && !isVN && (
                  <Field
                    id="lastITVDate"
                    name="dossierCompleteDeliveryComponent.dossierDeliveryComponent.lastITVDate"
                    key="dossierCompleteDeliveryComponent.dossierDeliveryComponent.lastITVDate"
                    colSm={4}
                    controlLabel={t(`${tKey}LAST_ITV_DATE`)}
                    disabled={readOnlyFields || componentConfiguration.permission !== permissions.editable}
                    component={InputDatePicker}
                    validate={[this.dateValidation]}
                  />)
                }
                {this.state.fieldsConfiguration.km.permission !== permissions.hidden && (<Field
                  id="Km"
                  name="dossierCompleteDeliveryComponent.dossierDeliveryComponent.km"
                  colSm={!isVN ? (4) : (3)}
                  controlLabel={t(`${tKey}KM`)}
                  placeholder={t(`${tKey}KM`)}
                  component={InputNatural}
                  disabled={readOnlyFields || componentConfiguration.permission !== permissions.editable}
                  textAlign="left"
                  maxLength={9}
                />)
                }
                {this.state.fieldsConfiguration.deliveryCity.permission !== permissions.hidden && (<Field
                  id="deliveryCity"
                  name="dossierCompleteDeliveryComponent.dossierDeliveryComponent.deliveryCity"
                  colSm={!isVN ? (4) : (3)}
                  controlLabel={t(`${tKey}DELIVERY_CITY`)}
                  placeholder={t(`${tKey}DELIVERY_CITY`)}
                  component={InputText}
                  disabled={readOnlyFields || componentConfiguration.permission !== permissions.editable}
                />)
                }
                {(dossierSubTypeId === dossierSubTypeIdConstants.venta_vo) &&
                  this.state.fieldsConfiguration.tuneUpDestinationId.permission !== permissions.hidden && (<Field
                  colSm={4}
                  id="tuneUpDestinationId"
                  name="dossierCompleteDeliveryComponent.dossierDeliveryComponent.tuneUpDestinationId"
                  controlLabel={t(`${tKey}TUNE_UP_DESTINATION`)}
                  placeholder={t(`${tKey}TUNE_UP_DESTINATION`)}
                  valueKey="id"
                  labelKey="value"
                  component={InputSelect}
                  options={tuneUpDestinationCombo}
                  disabled={readOnlyFields || componentConfiguration.permission !== permissions.editable}
                />
                )}
                {isSalesDossier && this.state.fieldsConfiguration.insuranceStartDate.permission !== permissions.hidden && (<Field
                  id="date"
                  name="dossierCompleteDeliveryComponent.dossierDeliveryComponent.insuranceStartDate"
                  colSm={4}
                  controlLabel={t(`${tKey}INSURANCE_START_DATE`)}
                  placeholder={t(`${tKey}INSURANCE_START_DATE`)}
                  component={InputDatePicker}
                  validate={[this.dateValidation]}
                  disabled={readOnlyFields || this.state.fieldsConfiguration.insuranceStartDate.permission !== permissions.editable}
                />)
                }
                {
                  isSalesDossier || isRegistrationDeliveryDossier
                    ? this.state.fieldsConfiguration.realDeliveryAddress.permission !== permissions.hidden && (
                      <Field
                        id="deliveryAddress"
                        name="dossierCompleteDeliveryComponent.dossierDeliveryComponent.realDeliveryAddress"
                        colSm={12}
                        componentClass="textarea"
                        controlLabel={t(`${tKey}ADDRESS`)}
                        placeholder={t(`${tKey}ADDRESS`)}
                        component={InputText}
                        disabled={readOnlyFields || componentConfiguration.permission !== permissions.editable}
                        maxLength={256}
                        customClass={settings.clientFieldValidation === clientInformation.DEMO ? 'text-address-short' : 'text-address' }
                      />
                    )
                    : this.state.fieldsConfiguration.realDeliveryLocationId.permission !== permissions.hidden && (<Field
                      id="deliveryLocationId"
                      name="dossierCompleteDeliveryComponent.dossierDeliveryComponent.realDeliveryLocationId"
                      colSm={4}
                      controlLabel={t(`${tKey}ADDRESS`)}
                      placeholder={t(`${tKey}ADDRESS`)}
                      options={deliveryLocationCombo}
                      valueKey="id"
                      labelKey="value"
                      component={InputSelect}
                      disabled={readOnlyFields || componentConfiguration.permission !== permissions.editable}
                    />)
                }
                {this.state.fieldsConfiguration.observations.permission !== permissions.hidden && (
                  <Field
                    id="observations"
                    name="dossierCompleteDeliveryComponent.dossierDeliveryComponent.observations"
                    colSm={12}
                    componentClass="textarea"
                    controlLabel={t(`${tKey}OBSERVATIONS`)}
                    placeholder={t(`${tKey}OBSERVATIONS`)}
                    component={InputText}
                    disabled={readOnlyFields || componentConfiguration.permission !== permissions.editable}
                    maxLength={512}
                  />
                )}
                {!readOnlyFields && printDeliveryChecklistPermission && this.state.fieldsConfiguration.printChecklist.permission !== permissions.hidden && !this.props.isFleet && (
                  <Col sm={4}>
                    <Button
                      className="btn-standard button-imprimir-albaran-entrega"
                      id="print-button"
                      onClick={() => settings.clientFieldValidation === clientInformation.TOYOTA ||
                        useDeliveryNoteChecklistVO ? this.openModalprintChecklistVO()
                        : printDeliveryChecklist(dossierId, isSalesDossier || isRegistrationDeliveryDossier)}
                      disabled ={componentConfiguration.permission !== permissions.editable}
                    >
                      <i className="ico-print" />
                      <span>{t(`${tKey}PRINT`)}</span>
                    </Button>
                  </Col>
                )}
                {dossierSubTypeId === dossierSubTypeIdConstants.venta_vo && this.state.fieldsConfiguration.printCheckListVo.permission !== permissions.hidden && !this.props.isFleet && (
                  <Col sm={4}>
                    <Button
                      className="btn-standard button-imprimir-albaran-entrega"
                      id="print-button"
                      onClick={() => (settings.clientFieldValidation === clientInformation.TOYOTA ||
                        useDeliveryNoteChecklistVO) ? this.openModalprintChecklistVO()
                        : printChecklistVO(dossierId, isSalesDossier || isRegistrationDeliveryDossier)}
                      disabled ={componentConfiguration.permission !== permissions.editable}
                    >
                      <i className="ico-print" />
                      <span>{t(`${tKey}PRINT_CHECKLIST_VO`)}</span>
                    </Button>
                  </Col>)}
                {this.state.fieldsConfiguration.btnPrintDeliveryCertificates.permission !== permissions.hidden && !this.props.isFleet && (
                  <Col sm={4}>
                    <Button className="btn-standard button-imprimir-albaran-entrega"
                      onClick={() => printDeliveryCertificates(dossierId)}
                      disabled={componentConfiguration.permission !== permissions.editable}
                    >
                      <i className="ico-print" />
                      <span>{t(`${tKey}PRINT_DELIVERY_CERTIFICATES`)}</span>
                    </Button>
                  </Col>)}
                {this.state.fieldsConfiguration.differentDeliveryPerson.permission !== permissions.hidden && (<Field
                  id="differentDeliveryPerson"
                  name="dossierCompleteDeliveryComponent.dossierDeliveryComponent.differentDeliveryPerson"
                  placeholder={t(`${tKey}DIFFERENT_DELIVERY_PERSON`)}
                  inline
                  component={InputCheckBox}
                  disabled={readOnlyFields || componentConfiguration.permission !== permissions.editable}
                />)
                }
                {
                  (this.state.fieldsConfiguration.deliveryPAP.permission !== permissions.hidden && settings.clientFieldValidation === clientInformation.VGR) && (<Field
                    id="pap"
                    name="dossierCompleteDeliveryComponent.dossierDeliveryComponent.pap"
                    placeholder={t(`${tKey}PAP`)}
                    inline
                    component={InputCheckBox}
                    disabled={readOnlyFields || componentConfiguration.permission !== permissions.editable}
                  />)
                }
              </Row>
              {
                !!dossierCompleteDeliveryCheckListItems.length && (
                  <Col sm={12} className="col-row hidden">
                    <Table bordered className="table-standard">
                      <thead>
                        <tr>
                          <th>{t(`${tKey}NAME`)}</th>
                          <th>{t(`${tKey}TYPE`)}</th>
                          <th>{t(`${tKey}DELIVERABLE`)}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          dossierCompleteDeliveryCheckListItems.map((checkListItem, idx) => (
                            <DeliveryChecklistRow
                              checkListItem={checkListItem}
                              key={idx}
                            />
                          ))
                        }
                      </tbody>
                    </Table>
                  </Col>
                )
              }
              {
                dossierDeliveryCheckListPurchase.map((purchase, idx) => (
                  <Col sm={12} className="col-row hidden" key={idx}>
                    {(isSalesDossier || isRegistrationDeliveryDossier) && <p>{purchase.dealerNumber}</p>}
                    <Table bordered className="table-standard">
                      <thead>
                        <tr>
                          <th>{t(`${tKey}NAME`)}</th>
                          <th>{t(`${tKey}TYPE`)}</th>
                          <th>{t(`${tKey}DELIVERABLE`)}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          purchase.deliveryCheckList.map((checkListItem, idx) => {
                            return (
                              <DeliveryChecklistRow
                                checkListItem={checkListItem}
                                key={idx}
                              />
                            )
                          })
                        }
                      </tbody>
                    </Table>
                  </Col>
                ))
              }
              <CustomFields customFields={customFields} readOnlyFields={readOnlyFields} />
            </Panel.Collapse>
          </Panel>
        </div>
      </>
    )
  }
}
export default Delivery
