import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { openEditDossierStageModal, fetchDossierStageList } from '../../../actions/masters/masters'
import { fetchSearchDossierSubType } from '../../../actions/combos/combos'
import DossierStageMaster from '../../../components/masters/dossierStage/DossierStageMaster'
import { initialize } from 'redux-form'

export function mapStateToProps (state) {
  return {
    ...state.dossierStage,
    combos: {
      searchDossierSubtypesCombo: state.combos.searchDossierSubtypesCombo
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      openEditDossierStageModal,
      fetchDossierStageList,
      fetchSearchDossierSubType,
      initialize
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(DossierStageMaster))
