import { call, put, all, takeEvery, select } from 'redux-saga/effects'
import { reset } from 'redux-form'
import dossierActionsTypes from '../../../constants/actions/dossier/dossier'
import { getAuth } from '../../../selectors/access/auth'
import getDossierSaleHeader from '../../../services/dossierSale/getDossierSaleHeader'
import getStageCheckList from '../../../services/dossier/get/getStageCheckList'
import getDocumentaryCheckList from '../../../services/document/getDocumentaryCheckList'
import { fetchSalesDossierSuccess } from '../../../actions/dossier/sales'
import { showLoader, hideLoader } from '../../../actions/common'
import { fetchDependantsSaleDossier } from '../fetchSalesDossier'
import { handleError } from '../../errors/errorManager'

export function * updateDependantsSaleDossierAndHeader ({ dossierId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const [salesDossierHeader, checkListHeader, salesDocumentaryCheckList] = yield all([
      call(getDossierSaleHeader, dossierId, auth.token),
      call(getStageCheckList, dossierId, auth.token),
      call(getDocumentaryCheckList, auth.token, dossierId)
    ])
    yield put(fetchSalesDossierSuccess({ ...salesDossierHeader, checkListHeader, salesDocumentaryCheckList }))
    yield call(fetchDependantsSaleDossier, { dossierId })
  } catch (error) {
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchUpdateDependantsSaleDossierAndHeader () {
  yield takeEvery(dossierActionsTypes.UPDATE_DEPENDANTS_SALE_DOSSIER_AND_HEADER, updateDependantsSaleDossierAndHeader)
}
