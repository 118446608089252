import apiFetch from '../apiFetch'

export default function (token, { page, pageSize, orderBy, name, surname1, surname2, dniCif, email, phone, address, representativeName, representativeSurname1, representativeSurname2, representativeDni, entityTypeId, entitySubTypeId, entityId, entityChildSelected }) {
  const queryParamsArray = [
    page ? `page=${page}` : null,
    pageSize ? `pageSize=${pageSize}` : null,
    orderBy ? `orderBy=${orderBy}` : null,
    name ? `entityName=${name}` : null,
    surname1 ? `entitySurname1=${surname1}` : null,
    surname2 ? `entitySurname2=${surname2}` : null,
    dniCif ? `entityCifNif=${dniCif}` : null,
    email ? `entityMail=${email}` : null,
    phone ? `entityPhone=${phone}` : null,
    address ? `entityAddress=${address}` : null,
    representativeName ? `representativeName=${representativeName}` : null,
    representativeSurname1 ? `representativeSurname1=${representativeSurname1}` : null,
    representativeSurname2 ? `representativeSurname2=${representativeSurname2}` : null,
    representativeDni ? `representativeDni=${representativeDni}` : null,
    entityTypeId ? `entityTypeId=${entityTypeId}` : null,
    entitySubTypeId ? `entitySubTypeId=${entitySubTypeId}` : null,
    entityId ? `entityId=${entityId}` : null,
    entityChildSelected ? `entityChildSelected=${entityChildSelected}` : null
  ]
  const queryParams = queryParamsArray.reduce(
    (result, query, idx) => result + (query ? (result ? `&${query}` : `?${query}`) : '')
    , ''
  )
  return apiFetch({
    endPoint: `dossierFinder/Entities${queryParams}`,
    method: 'GET',
    body: null,
    token: token
  })
}
