import React, { PureComponent } from 'react'
import { scroller } from 'react-scroll'

class Scroller extends PureComponent {
  getSnapshotBeforeUpdate (prevProps) {
    if (this.props.element && this.props.autoScroll) {
      scroller.scrollTo(this.props.element, this.props.options)
    }
    return null
  }

  componentDidUpdate () {
    // getSnapshotBeforeUpdate() should be used with componentDidUpdate()
  }

  render () {
    return (
      <div></div>
    )
  }
}

export default Scroller
