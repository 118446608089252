import faqSectionActions from '../../../constants/actions/masters/faqSection/faqSectionMaster'

function initialState () {
  return {
    faqSections: [],
    sectionsCount: 0,
    pagesCount: 0,
    filter: {
      page: 1,
      orderBy: '',
      pageSize: 10
    },
    modal: {
      showModal: false,
      faqSectionFields: [],
      languages: []
    }
  }
}

function loadFaqSectionTable (state, { faqSectionTable, filter }) {
  return {
    ...state,
    faqSections: faqSectionTable.faqSections,
    sectionsCount: faqSectionTable.sectionsCount,
    pagesCount: faqSectionTable.pagesCount,
    filter
  }
}

function loadFaqSection (state, { languageList, faqSectionObject }) {
  return {
    ...state,
    modal: {
      showModal: true,
      faqSectionFields: {
        faqSectionTranslations: faqSectionObject.translations,
        faqSectionId: faqSectionObject.faqSectionId,
        organizedUnitId: faqSectionObject.organizedUnitId
      },
      languages: languageList
    }
  }
}
function closeFaqSectionModal (state) {
  return {
    ...state,
    modal: {
      showModal: false,
      faqSectionFields: [],
      languages: []
    }
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case faqSectionActions.LOAD_FAQ_SECTION_TABLE:
      return loadFaqSectionTable(state, action)
    case faqSectionActions.LOAD_FAQ_SECTION:
      return loadFaqSection(state, action)
    case faqSectionActions.CLOSE_FAQ_SECTION_MODAL:
      return closeFaqSectionModal(state)
    default:
      return state
  }
}
