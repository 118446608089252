import recurrentEntityActionTypes from '../../constants/actions/admin/recurrentEntityAdmin'

function initialState () {
  return {
    entityList: [],
    recurrentEntitySubTypesList: [],
    pagesCount: 0,
    entityCount: 0,
    filter: {
      page: 1,
      orderBy: '',
      pageSize: 10
    },
    recurrentEntityAdminModal: {
      entityId: null,
      entityName: '',
      emailAddress: '',
      showModal: false
    },
    documents: [],
    documentPagesCount: 0,
    documentCount: 0,
    changeTabFromMaster: false,
    ouIdFromMaster: null,
    userRecurrrentEntityModal: {
      entityId: null,
      showModal: false,
      userList: [],
      orderBy: '+name'
    },
    searchUserRecurrentEntityModal: {
      entityId: null,
      showModal: false,
      userList: [],
      filter: {
        page: 1,
        orderBy: '',
        pageSize: 10,
        searchName: null,
        searchEmail: null
      },
      pagesCount: 0,
      entityCount: 0,
      userEntityList: []
    }
  }
}

function fetchRecurrentEntityAdminSuccess (state, { recurrentEntities, filter }) {
  return {
    ...state,
    entityList: recurrentEntities.entityList,
    pagesCount: recurrentEntities.pagesCount,
    entityCount: recurrentEntities.entitiesCount,
    filter
  }
}

function openEditModalSuccess (state, { recurrentEntity }) {
  return {
    ...state,
    recurrentEntityAdminModal: {
      ...state.recurrentEntityAdminModal,
      ...recurrentEntity,
      showModal: true
    }
  }
}

function openAddModalSuccess (state) {
  const initial = initialState()
  return {
    ...state,
    recurrentEntityAdminModal: {
      ...initial.recurrentEntityAdminModal,
      showModal: true
    }
  }
}

function closeEditModalSuccess (state) {
  return {
    ...state,
    recurrentEntityAdminModal: {
      ...initialState().recurrentEntityAdminModal
    }
  }
}

function fetchDocumentListAdminSuccess (state, { documents }) {
  return {
    ...state,
    recurrentEntityAdminModal: {
      ...state.recurrentEntityAdminModal,
      documents
    }
  }
}

function fetchEntitySubTypesListSuccess (state, { recurrentEntitySubTypesList }) {
  return {
    ...state,
    recurrentEntitySubTypesList
  }
}

function setChangeTabFromMasterSuccess (state, { ouIdFromMaster, changeTabFromMaster }) {
  return {
    ...state,
    ouIdFromMaster,
    changeTabFromMaster
  }
}

function openCloseUserRecurrentServiceModal (state, { showModal, entityId }) {
  return {
    ...state,
    userRecurrrentEntityModal: {
      entityId: entityId ?? null,
      showModal: showModal ?? false,
      userList: [],
      orderBy: '+name'
    }
  }
}

function getUserRecurrentServiceEntitySuccess (state, { entityId, orderBy, userList }) {
  return {
    ...state,
    userRecurrrentEntityModal: {
      ...state.userRecurrrentEntityModal,
      entityId: entityId,
      userList: userList,
      orderBy: orderBy
    },
    searchUserRecurrentEntityModal: {
      ...state.searchUserRecurrentEntityModal,
      userEntityList: userList
    }
  }
}

function openCloseAddUserRecurrentServiceModal (state, { showModal, entityId, userEntityList }) {
  return {
    ...state,
    searchUserRecurrentEntityModal: {
      ...initialState().searchUserRecurrentEntityModal,
      entityId: entityId ?? null,
      showModal: showModal ?? false,
      userList: [],
      userEntityList: userEntityList ?? []
    }
  }
}

function fillFilterFieldAddUserRecurrentServiceModal (state, { filter }) {
  return {
    ...state,
    searchUserRecurrentEntityModal: {
      ...state.searchUserRecurrentEntityModal,
      filter
    }
  }
}

function clearUserAdminSearchFilterModal (state) {
  return {
    ...state,
    searchUserRecurrentEntityModal: {
      ...state.searchUserRecurrentEntityModal,
      userList: [],
      filter: {
        page: 1,
        orderBy: '',
        pageSize: 10,
        searchName: null,
        searchEmail: null
      },
      pagesCount: 0,
      entityCount: 0
    }
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case recurrentEntityActionTypes.FETCH_RECURRENT_ENTITY_ADMIN_SUCCESS:
      return fetchRecurrentEntityAdminSuccess(state, action)
    case recurrentEntityActionTypes.OPEN_EDIT_RECURRENT_ENTITY_MODAL_SUCCESS:
      return openEditModalSuccess(state, action)
    case recurrentEntityActionTypes.OPEN_ADD_RECURRENT_ENTITY_MODAL_SUCCESS:
      return openAddModalSuccess(state, action)
    case recurrentEntityActionTypes.CLOSE_EDIT_RECURRENT_ENTITY_MODAL:
      return closeEditModalSuccess(state, action)
    case recurrentEntityActionTypes.FETCH_DOCUMENT_LIST_ADMIN_SUCCESS:
      return fetchDocumentListAdminSuccess(state, action)
    case recurrentEntityActionTypes.FETCH_ENTITY_SUB_TYPES_LIST_SUCCESS:
      return fetchEntitySubTypesListSuccess(state, action)
    case recurrentEntityActionTypes.SET_CHANGE_TAB_FROM_MASTER_SUCCESS:
      return setChangeTabFromMasterSuccess(state, action)
    case recurrentEntityActionTypes.OPEN_CLOSE_USER_RECURRENT_SERVICE_MODAL:
      return openCloseUserRecurrentServiceModal(state, action)
    case recurrentEntityActionTypes.GET_USER_RECURRENT_SERVICE_SUCCESS:
      return getUserRecurrentServiceEntitySuccess(state, action)
    case recurrentEntityActionTypes.OPEN_CLOSE_ADD_USER_RECURRENT_SERVICE_MODAL:
      return openCloseAddUserRecurrentServiceModal(state, action)
    case recurrentEntityActionTypes.FILL_FILTER_FIELD_ADD_USER_RECURRENT_SERVICE_MODAL:
      return fillFilterFieldAddUserRecurrentServiceModal(state, action)
    case recurrentEntityActionTypes.CLEAR_USER_ADMIN_SEARCH_FILTER_MODAL:
      return clearUserAdminSearchFilterModal(state, action)
    default:
      return state
  }
}
