import mastersActionTypes from '../../../constants/actions/masters/masters'

function initialState () {
  return {
    organizedUnitList: [],
    organizedUnitCount: 0,
    pagesCount: 0,
    filter: {
      page: 1,
      orderBy: '',
      pageSize: 10,
      searchUo: 0,
      searchName: '',
      searchCode: '',
      searchLegalCompany: 0
    }
  }
}

function fetchOrganizedUnitListSuccess (state, { organizedUnitList, filter }) {
  return {
    ...state,
    organizedUnitList: organizedUnitList.organizedUnitList,
    organizedUnitCount: organizedUnitList.organizedUnitCount,
    pagesCount: organizedUnitList.pagesCount,
    filter
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case mastersActionTypes.FETCH_ORGANIZED_UNIT_LIST_SUCCESS:
      return fetchOrganizedUnitListSuccess(state, action)
    default:
      return state
  }
}
