import React, { PureComponent } from 'react'
import { Row } from 'react-bootstrap'
import DocumentItem from './DocumentItem'

class SectionComponent extends PureComponent {
  addClassStatus (checklistStatusDocument) {
    switch (checklistStatusDocument) {
      case 1:
        return 'component-ok'
      case 2:
        return 'component-incorporated'
      case 3:
        return 'component-pending'
      case 4:
        return 'component-wrong'
    }
  }

  render () {
    const {
      t,
      section, dossierId, dossierType, documentEntityType, readOnlyDoc = false, objectId, readOnlyFields,
      actions: { openDocWarning, openDocumentEditionModalWithChecklistId, cancelDocument, addDependant, printCheckList, openModal }
    } = this.props
    return (
      <Row>
        <div className="title-module">
          <span>{section.name}</span>
        </div>
        <div className="list-document-uploaded-modal">
          {
            section.documents.map((documentFile, idx) => <DocumentItem
              className= {this.addClassStatus(section.documents[idx].checklistStatus)}
              t={t}
              key={idx}
              readOnly={readOnlyDoc}
              dossierId={dossierId}
              dossierType={dossierType}
              formState={this.props.formState}
              documentEntityType={documentEntityType}
              documentFile={documentFile}
              objectId={objectId}
              actions={{ openDocWarning, openDocumentEditionModalWithChecklistId, cancelDocument, addDependant, printCheckList, openModal }}
              readOnlyFields={readOnlyFields}
            />)
          }
        </div>

      </Row>
    )
  }
}

export default SectionComponent
