
import React, { PureComponent } from 'react'
import { Modal, Col, Row, FormGroup, Button } from 'react-bootstrap'
import { reduxForm, Field } from 'redux-form'
import InputText from '../../commons/form/InputText'
import InputTreeSelect from '../../commons/form/InputTreeSelect'

class DestinationCraneMasterModal extends PureComponent {
  constructor () {
    super()
    this.state = {
    }
  }

  submitDestinationCrane (values) {
    const body = {
      ...values
    }
    this.props.actions.putDestinationCraneItem(body)
  }

  render () {
    const {
      t, handleSubmit,
      showModal,
      destinationCrane,
      combos: { UOTreeCombo }
    } = this.props
    const tKey = 'MASTERS.DESTINATION_CRANE_MASTER.MODAL.'
    return (
      <Modal className="role-modal languages-modal" show={showModal} onHide={this.props.actions.fetchDestinationCraneItem} backdrop={'static'}>
        <form autoComplete="off" onSubmit={handleSubmit(this.submitDestinationCrane.bind(this))}>
          <Modal.Header closeButton onHide={this.props.actions.fetchDestinationCraneItem}>
            <Modal.Title>{t(`${tKey}TITLE`)}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <FormGroup>
              <Row key={1} className="inputs-wrapper flexRow">
                <Col sm={4} className="custom-width-col">
                  <h2>{t(`${tKey}NAME`)}</h2>
                  <Field
                    name="name"
                    placeholder={t(`${tKey}NAME`)}
                    component={InputText}
                  />
                </Col>
                <Col sm={4} className="custom-width-col">
                  <h2>{t(`${tKey}DESCRIPTION`)}</h2>
                  <Field
                    name="description"
                    placeholder={t(`${tKey}DESCRIPTION`)}
                    component={InputText}
                  />
                </Col>
                <Col sm={4} className="custom-width-col">
                  <h2>{t(`${tKey}ORGANIZED_UNIT`)}</h2>
                  <Field
                    name="organizedUnitId"
                    placeholder={t(`${tKey}ORGANIZED_UNIT`)}
                    component={InputTreeSelect}
                    multi={false}
                    allowClear={true}
                    valueKey="value"
                    labelKey="label"
                    childrenKey="children"
                    colSm={0}
                    options={UOTreeCombo}
                    disabled={destinationCrane?.destinationCraneId !== null && destinationCrane?.destinationCraneId !== undefined}
                  />
                </Col>
              </Row>
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <Col sm={12} className="btn-wrapper">
              <Button
                id="role-modal-submit-button"
                className="btn-standard"
                type="submit"
                disabled={this.props.pristine}>
                {t(`${tKey}SUBMIT_BUTTON`)}
              </Button>
            </Col>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default reduxForm({
  form: 'editDestinationCrane',
  validate: (values, props) => {
    const error = { }
    if (values) {
      const tKey = 'MASTERS.DESTINATION_CRANE_MASTER.VALIDATION.'
      if (!values.name || values.name === '' || values.name === ' ') {
        error.name = props.t(`${tKey}NAME`)
      }
      if (!values.description || values.description === '' || values.description === ' ') {
        error.description = props.t(`${tKey}DESCRIPTION`)
      }
      if (!values.organizedUnitId) {
        error.organizedUnitId = props.t(`${tKey}ORGANIZED_UNIT`)
      }
    }
    return Object.keys(error).length > 0 ? error : {}
  }
})(DestinationCraneMasterModal)
