
import React, { PureComponent } from 'react'
import { Button, Modal, Col, Row, FormGroup, Table } from 'react-bootstrap'
import { reduxForm, Field } from 'redux-form'
import TextInput from '../../commons/TextInput'
import InputCheckbox from '../../commons/form/InputCheckBox'

class FinancingTypeProductModal extends PureComponent {
  constructor () {
    super()
    this.state = { reactivatable: false, repurchaseAgreement: false }
  }

  submitFinancingTypeProduct (values) {
    const financingTypeProductLanguage = []
    Object.keys(values).map(item => {
      if (typeof values[item] === 'boolean') {

      }
      if (item.includes('lang')) {
        financingTypeProductLanguage.push(values[item])
      }
    })

    var typeProduct = {}
    typeProduct.financingTypeProductId = this.props.financingTypeProductId
    typeProduct.name = values.financingTypeProduct.name
    typeProduct.reactivatable = values.reactivatable
    typeProduct.repurchaseAgreement = values.repurchaseAgreement
    typeProduct.financingTypeProductLanguage = financingTypeProductLanguage
    typeProduct.Fields = []
    this.props.fields.map(field => {
      var tempField = {}
      tempField.fieldId = field.fieldId
      tempField.financingFieldsProductId = field.financingFieldsProductId
      tempField.mandatory = values.field[field.fieldId]
      typeProduct.Fields.push(tempField)
    })

    this.props.actions.saveFinancingTypeProductModal(typeProduct)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (this.props.financingTypeProductLanguage !== nextProps.financingTypeProductLanguage && nextProps.financingTypeProductId !== this.props.financingTypeProductId || this.props.showModal !== nextProps.showModal) {
      nextProps.languageList.map((language) => {
        this.props.change(`lang${language.languageId}.languageId`, language.languageId)
        const source = nextProps.financingTypeProductLanguage ? nextProps.financingTypeProductLanguage.find((sourceChannel) => sourceChannel.languageId === language.languageId) : null
        this.props.change(`lang${language.languageId}.description`, source ? source.description : '')
      })
      this.props.change('reactivatable', nextProps.financingTypeProduct ? nextProps.financingTypeProduct.reactivatable ? nextProps.financingTypeProduct.reactivatable : false : false)
      this.props.change('repurchaseAgreement', nextProps.financingTypeProduct ? nextProps.financingTypeProduct.repurchaseAgreement ? nextProps.financingTypeProduct.repurchaseAgreement : false : false)
      this.props.change('financingTypeProduct.name', nextProps.financingTypeProduct ? nextProps.financingTypeProduct.name ? nextProps.financingTypeProduct.name : '' : '')
    }

    if (this.props.fields && nextProps.financingTypeProductId !== this.props.financingTypeProductId || this.props.showModal !== nextProps.showModal) {
      this.props.fields.map((f) => {
        this.props.change(`field.${f.fieldId}`, nextProps.financingTypeProduct ? nextProps.financingTypeProduct.fields.find(x => x.financingFieldsProductId === f.financingFieldsProductId) ? nextProps.financingTypeProduct.fields.find(x => x.financingFieldsProductId === f.financingFieldsProductId).mandatory : false : false)
      })
    }

    if (this.props.financingTypeProductId !== nextProps.financingTypeProductId || nextProps.financingTypeProductId == null || nextProps.reactivatable === undefined) {
      this.setState({ reactivatable: undefined })
      this.setState({ repurchaseAgreement: undefined })
    }

    if (this.props.reactivatable !== nextProps.reactivatable) {
      if (nextProps.reactivatable === null || nextProps.reactivatable === undefined) {
        this.setState({ reactivatable: false })
      } else {
        this.setState({ reactivatable: nextProps.reactivatable })
      }
    }

    if (this.props.financingTypeProductId !== nextProps.financingTypeProductId || nextProps.financingTypeProductId == null || nextProps.repurchaseAgreement === undefined) {
      this.setState({ repurchaseAgreement: undefined })
    }
    if (this.props.repurchaseAgreement !== nextProps.repurchaseAgreement) {
      if (nextProps.repurchaseAgreement === null || nextProps.repurchaseAgreement === undefined) {
        this.setState({ repurchaseAgreement: false })
      } else {
        this.setState({ repurchaseAgreement: nextProps.repurchaseAgreement })
      }
    }
  }

  checkReactivatable (val) {
    this.setState({ reactivatable: val })
  }

  checkRepurchaseAgreement (val) {
    this.setState({ repurchaseAgreement: val })
  }

  render () {
    const { t, showModal, handleSubmit, languageList = [], fields } = this.props
    const tKey = 'MASTERS.FINANCING_TYPE_PRODUCT.'
    return (
      <Modal show={showModal} onHide={this.props.actions.closeEditFinancingTypeModalProduct} className="languages-modal">
        <Modal.Header closeButton>
          <i className="ico-uo" /><Modal.Title>{t(`${tKey}FINANCING_TYPE_PRODUCT`)}</Modal.Title>
        </Modal.Header>
        <form autoComplete="off" onSubmit={handleSubmit(this.submitFinancingTypeProduct.bind(this))}>
          <Modal.Body>
            <Row>
              <Col sm={6}>

                <Row >
                  <h4>{t(`${tKey}FINANCING_TYPE_PRODUCT`)}</h4>
                  <Field
                    name={'financingTypeProduct.name'}S
                    placeholder={t('MASTERS.NAME')}
                    component={TextInput}
                    className={'finance-type-button'}
                    sm={6}
                  />
                  <br/><br/>
                </Row>
                <Row className="title-column">
                  <Col sm={6}>
                    <h2>{t('MASTERS.DESCRIPTION')}</h2>
                  </Col>
                  <Col sm={6}>
                    <h2>{t('MASTERS.LANGUAGE')}</h2>
                  </Col>
                </Row>
                <FormGroup>
                  {languageList.map((val, idx) => {
                    return (
                      <Row key={idx} className="inputs-wrapper">
                        <Col sm={6} className="custom-width-col">
                          <Field
                            name={`lang${val.languageId}.description`}
                            placeholder={t('MASTERS.DESCRIPTION')}
                            component={TextInput}
                          />
                        </Col>
                        <Col sm={6} className="language-column">
                          <p>{`${val.description} (${val.name})`}</p>
                        </Col>
                      </Row>
                    )
                  })}
                </FormGroup>
                <h2>{t('MASTERS.FINANCING_TYPE.OTHERS')}</h2>
                <Row>
                  <Col>
                    <Field
                      component={InputCheckbox}
                      name={'reactivatable'}
                      id={'reactivatable'}
                      placeholder ={t('MASTERS.FINANCING_TYPE.REACTIVATABLE_INSTORED')}
                      checked = {this.state.reactivatable}
                      onChange = {(value) => this.checkReactivatable(value) }
                    />
                  </Col>
                  <Col>
                    <Field
                      component={InputCheckbox}
                      name={'repurchaseAgreement'}
                      id={'repurchaseAgreement'}
                      placeholder ={t(`${tKey}REPURCHASE`)}
                      checked = {this.state.repurchaseAgreement}
                      onChange = {(value) => this.checkRepurchaseAgreement(value) }
                    />
                  </Col>
                </Row>
              </Col>
              <Col sm={6}>
                <h5> {t(`${tKey}CONFIGURATION_FIELDS`)} :</h5>
                <Table className="editable-table" hover responsive>
                  <thead>
                    <tr>
                      <th>
                        {t(`${tKey}FIELD`)}
                      </th>
                      <th>
                        {t(`${tKey}MANDATORY`)}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {fields && fields.map((financingField, idx) => (
                      <tr key={'tdfielTr_' + idx}>
                        <td key={'tdfieldName_' + idx}>
                          {('0' + (idx + 1)).slice(-2) + '.' + financingField.name}
                        </td>
                        <td key={'tdfieldCheck_' + idx}>
                          {
                            <Field
                              component={InputCheckbox}
                              name={'field.' + financingField.fieldId}
                            />
                          }
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Col sm={6} className="btn-wrapper" smPush={6}>
              <Button type="submit" className="btn-standard">
                {t('MASTERS.SAVE')}
              </Button>
            </Col>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}
const validate = (values, props) => {
  const errors = { financingTypeProduct: {} }
  props.languageList.map(language => {
    errors[`lang${language.languageId}`] = {}
    if (values[`lang${language.languageId}`]) {
      if (!(values[`lang${language.languageId}`].description)) {
        errors[`lang${language.languageId}`].description = props.t('MASTERS.VALIDATION.DESCRIPTION_REQUIRED')
      }
    } else {
      errors[`lang${language.languageId}`].description = props.t('MASTERS.VALIDATION.DESCRIPTION_REQUIRED')
    }
  })
  if (values) {
    if (!values.financingTypeProduct) {
      errors.financingTypeProduct.name = props.t('MASTERS.VALIDATION.PRODUCT_TYPE_REQUIRED')
    }
  }
  return errors
}

export default reduxForm({
  form: 'financingTypeProductModal',
  validate
})(FinancingTypeProductModal)
