export default {
  FETCH_USER_PROFILE: 'FETCH_USER_PROFILE',
  FETCH_USER_PROFILE_SUCCESS: 'FETCH_USER_PROFILE_SUCCESS',
  UPDATE_USER_PROFILE: 'UPDATE_USER_PROFILE',
  FETCH_SALESMAN_COMBO: 'FETCH_SALESMAN_COMBO',
  FETCH_SALESMAN_COMBO_SUCCESS: 'FETCH_SALESMAN_COMBO_SUCCESS',
  SAVE_EVENT_SUBSCRIPTION: 'SAVE_EVENT_SUBSCRIPTION',
  DELETE_EVENT_SUBSCRIPTION: 'DELETE_EVENT_SUBSCRIPTION',
  FETCH_EVENT_SUBSCRIPTIONS: 'FETCH_EVENT_SUBSCRIPTIONS',
  FETCH_EVENT_SUBSCRIPTIONS_SUCCESS: 'FETCH_EVENT_SUBSCRIPTIONS_SUCCESS',
  ENABLE_EVENT_SUBSCRIPTION: 'ENABLE_EVENT_SUBSCRIPTION',
  DISABLE_EVENT_SUBSCRIPTION: 'DISABLE_EVENT_SUBSCRIPTION',
  PROFILE_SECTION_CHANGE_SUCCESS: 'PROFILE_SECTION_CHANGE_SUCCESS'
}
