import conditionsMaintenanceActionTypes from '../../constants/actions/conditionsMaintenance/conditionsMaintenance'

export function fetchStageConditions (filter) {
  return {
    type: conditionsMaintenanceActionTypes.FETCH_STAGES_CONDITIONS,
    filter
  }
}

export function fetchStageConditionsSuccess (stages) {
  return {
    type: conditionsMaintenanceActionTypes.FETCH_STAGES_CONDITIONS_SUCCESS,
    stages
  }
}

export function setActiveStage (activeStage) {
  return {
    type: conditionsMaintenanceActionTypes.SET_ACTIVE_STAGE,
    activeStage
  }
}

export function setActiveStageSuccess (activeStage, conditionDocumental, conditionNoDocumental, conditionNoDocumentalOther) {
  return {
    type: conditionsMaintenanceActionTypes.SET_ACTIVE_STAGE_SUCCESS,
    activeStage,
    conditionDocumental,
    conditionNoDocumental,
    conditionNoDocumentalOther
  }
}

export function openAddConditionsModal (isConditionDocumental, activeStage, conditionNoDocumentalType) {
  return {
    type: conditionsMaintenanceActionTypes.OPEN_ADD_CONDITIONS_MODAL,
    isConditionDocumental,
    activeStage,
    conditionNoDocumentalType
  }
}

export function openAddConditionsModalSuccess (isConditionDocumental, activeStage, conditionNoDocumentalType) {
  return {
    type: conditionsMaintenanceActionTypes.OPEN_ADD_CONDITIONS_MODAL_SUCCESS,
    isConditionDocumental,
    activeStage,
    conditionNoDocumentalType
  }
}

export function openEditConditionsModal (isConditionDocumental, conditionId, conditionNoDocumentalType) {
  return {
    type: conditionsMaintenanceActionTypes.OPEN_EDIT_CONDITIONS_MODAL,
    isConditionDocumental,
    conditionId,
    conditionNoDocumentalType
  }
}

export function openEditConditionsModalSuccess (isConditionDocumental, conditionId, data, conditionNoDocumentalType) {
  return {
    type: conditionsMaintenanceActionTypes.OPEN_EDIT_CONDITIONS_MODAL_SUCCESS,
    isConditionDocumental,
    conditionId,
    data,
    conditionNoDocumentalType
  }
}

export function saveConditionsModal (isConditionDocumental, conditionId, data) {
  return {
    type: conditionsMaintenanceActionTypes.SAVE_CONDITIONS_MODAL,
    isConditionDocumental,
    conditionId,
    data
  }
}

export function closeAddOrEditConditionsModal () {
  return {
    type: conditionsMaintenanceActionTypes.CLOSE_ADD_OR_EDIT_CONDITIONS_MODAL
  }
}

export function closeAddOrEditConditionsModalSuccess () {
  return {
    type: conditionsMaintenanceActionTypes.CLOSE_ADD_OR_EDIT_CONDITIONS_MODAL_SUCCESS
  }
}

export function deleteCondition (isConditionDocumental, conditionId, stageId) {
  return {
    type: conditionsMaintenanceActionTypes.DELETE_CONDITION,
    isConditionDocumental,
    conditionId,
    stageId
  }
}

export function updateConditionsMaintenanceFilter (filter) {
  return {
    type: conditionsMaintenanceActionTypes.UPDATE_CONDITION_MAINTENANCE_FILTER,
    filter
  }
}

export function fetchConditionDocumentalCampaign (campaignDetailsId, campaignGroupId) {
  return {
    type: conditionsMaintenanceActionTypes.FETCH_CONDITION_DOCUMENTAL_CAMPAIGN,
    campaignDetailsId,
    campaignGroupId
  }
}

export function fetchConditionDocumentalCampaignSuccess (data) {
  return {
    type: conditionsMaintenanceActionTypes.FETCH_CONDITION_DOCUMENTAL_CAMPAIGN_SUCCESS,
    data
  }
}

export function deleteConditionDocumentalCampaign (conditionDocumentalCampaigId, campaignDetailsId, campaignGroupId) {
  return {
    type: conditionsMaintenanceActionTypes.DELETE_CONDITION_DOCUMENTAL_CAMPAIGN,
    conditionDocumentalCampaigId,
    campaignDetailsId,
    campaignGroupId
  }
}

export function getDocumentsConditionDocumentalcampaign () {
  return {
    type: conditionsMaintenanceActionTypes.GET_DOCUMENTS_CONDITION_DOCUMENTAL_CAMPAIGN
  }
}

export function getDocumentsConditionDocumentalcampaignSucces (data) {
  return {
    type: conditionsMaintenanceActionTypes.GET_DOCUMENTS_CONDITION_DOCUMENTAL_CAMPAIGN_SUCCESS,
    data
  }
}

export function addConditionDocumentalCampaign (conditionDocumentalCampaignDto) {
  return {
    type: conditionsMaintenanceActionTypes.ADD_CONDITION_DOCUMENTAL_CAMPAIGN,
    conditionDocumentalCampaignDto
  }
}

export function getFunctionsToAddDocumentalCampaign () {
  return {
    type: conditionsMaintenanceActionTypes.GET_FUNTIONTOADD_DOCUMENTAL_CAMPAIGN
  }
}

export function getFunctionsToAddDocumentalCampaignSucces (data) {
  return {
    type: conditionsMaintenanceActionTypes.GET_FUNTIONTOADD_DOCUMENTAL_CAMPAIGN_SUCCES,
    data
  }
}

export function deleteUoConditionDocCampaign (organizedUnitId, conditionDocumentalCampaign) {
  return {
    type: conditionsMaintenanceActionTypes.DELETE_UO_CONDITION_DOCUMENTAL_CAMPAIGN,
    organizedUnitId,
    conditionDocumentalCampaign
  }
}

export default {
  fetchStageConditions,
  fetchStageConditionsSuccess,
  setActiveStage,
  setActiveStageSuccess,
  openAddConditionsModal,
  openAddConditionsModalSuccess,
  openEditConditionsModal,
  openEditConditionsModalSuccess,
  saveConditionsModal,
  closeAddOrEditConditionsModal,
  closeAddOrEditConditionsModalSuccess,
  deleteCondition,
  updateConditionsMaintenanceFilter,
  fetchConditionDocumentalCampaign,
  fetchConditionDocumentalCampaignSuccess,
  deleteConditionDocumentalCampaign,
  getDocumentsConditionDocumentalcampaign,
  getDocumentsConditionDocumentalcampaignSucces,
  addConditionDocumentalCampaign,
  getFunctionsToAddDocumentalCampaign,
  getFunctionsToAddDocumentalCampaignSucces,
  deleteUoConditionDocCampaign
}
