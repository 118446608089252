export default {
  FETCH_DOCUMENTAL_SEARCH_FORM_COMBOS: 'FETCH_DOCUMENTAL_SEARCH_FORM_COMBOS',
  FIND_FILTER_DOCUMENTS: 'FIND_FILTER_DOCUMENTS',
  FIND_FILTER_DOCUMENTS_SUCCESS: 'FIND_FILTER_DOCUMENTS_SUCCESS',
  RESET_FILTERS_DOCUMENTS: 'RESET_FILTERS_DOCUMENTS',
  FETCH_DOCUMENT_SEARCH_LIST: 'FETCH_DOCUMENT_SEARCH_LIST',
  FETCH_DOCUMENT_SEARCH_LIST_SUCCESS: 'FETCH_DOCUMENT_SEARCH_LIST_SUCCESS',
  INITIALIZE_DOC_SEARCH: 'INITIALIZE_DOC_SEARCH',
  SET_FILTERS: 'SET_FILTERS',
  OPEN_DOCUMENT_MODAL: 'OPEN_DOCUMENT_MODAL',
  OPEN_DOCUMENT_MODAL_SUCCESS: 'OPEN_DOCUMENT_MODAL_SUCCESS',
  DOCUMENTAL_SEARCH_TAB_CHANGE: 'DOCUMENTAL_SEARCH_TAB_CHANGE',
  DOWNLOAD_DOCUMENT_LIST: 'DOWNLOAD_DOCUMENT_LIST',
  MASSIVE_DOWNLOAD_DOCUMENT_LIST: 'MASSIVE_DOWNLOAD_DOCUMENT_LIST',
  CREATE_MASSIVE_DOWNLOAD: 'CREATE_MASSIVE_DOWNLOAD',
  FIND_FILTER_MASSIVE_DOWNLOAD: 'FIND_FILTER_MASSIVE_DOWNLOAD',
  FIND_FILTER_MASSIVE_DOWNLOAD_SUCCESS: 'FIND_FILTER_MASSIVE_DOWNLOAD_SUCCESS',
  DOWNLOAD_MASSIVE_FILE: 'DOWNLOAD_MASSIVE_FILE',
  CANCEL_MASSIVE_FILE: 'CANCEL_MASSIVE_FILE',
  SET_HIDE_FILTERS: 'SET_HIDE_FILTERS'
}
