
import React, { PureComponent } from 'react'
import { Button, Modal, Col } from 'react-bootstrap'
import { reduxForm } from 'redux-form'
import { validateDate } from '../../util/validationFunctions'
import { dossierSubTypeId } from '../../constants/dossier/common/dossierSubType'
import FleetAndClientComponentPage from '../../containers/management/commons/FleetAndClientComponentPage'
import DocumentsListComponentPage from '../../containers/management/commons/DocumentsListComponentPage'

class DocumentsFleetModal extends PureComponent {
  constructor () {
    super()
    this.state = {
      disableClient: true,
      organizedUnit: null,
      dossierSubTypeId: null,
      openPanelFleet: true,
      openPanelClient: true,
      changeCheckAll: false,
      dataId: null,
      actionId: null
    }
  }

  componentDidUpdate () {

  }

  getSnapshotBeforeUpdate (prevProps) {
    if (prevProps.showModal !== this.props.showModal) {
      if (this.props.fleet) {
        this.props.change('fleetCode', this.props.fleet.fleetCode)
        this.props.change('description', this.props.fleet.description)
        this.props.change('observations', this.props.fleet.observations)
        this.props.change('organizedUnitId', this.props.fleet.organizedUnitId)
        this.props.change('dossierSubTypeId', this.props.fleet.dossierSubType)
        this.props.change('renewalDate', this.props.fleet.renewalDate)
        this.props.change('dossierCount', this.props.fleet.numDossiers)
        this.props.change('creationDate', this.props.fleet.creationDate)
        this.props.change('entityComponent', this.props.fleet.entityComponent)
        this.props.change('entityChildComponent', this.props.fleet.finalEntity)
        this.props.change('dossierContactComponent', this.props.fleet.dossierContactComponent)
      } else {
        this.props.actions.clearEntity(-1, dossierSubTypeId.venta_vn)
        this.props.change('fleetCode', null)
        this.props.change('description', null)
        this.props.change('observations', null)
        this.props.change('organizedUnitId', null)
        this.props.change('dossierSubTypeId', null)
        this.props.change('renewalDate', null)
      }

      this.setState({ disableClient: true, organizedUnit: null, dossierSubTypeId: null })
    }
    if (prevProps.showModal && !this.props.showModal) {
      this.props.initialize({})
      this.props.reset()
    }

    return null
  }

  updateComponentAfterDeleteAndRestart () {

  }

  validateDate (value, allValues, props) {
    const result = validateDate(value, props)
    return result === Object(result) ? undefined : result
  }

  fieldFill (field) {
    this.setState({ fields: { ...this.state.fields, ...field } })
  }

  collapsePanelFleet () {
    this.setState({ openPanelFleet: !this.state.openPanelFleet })
  }

  collapsePanelClient () {
    this.setState({ openPanelClient: !this.state.openPanelClient })
  }

  closeModal () {
    this.props.actions.getDocumentFleetListSuccess([])
    this.props.actions.closeDocumentFleetModal()
    if (this.props.dossiersMode) {
      this.props.actions.openOperateFleetModal(this.props.fleet)
    }
  }

  render () {
    const {
      t,
      showModal, handleSubmit
    } = this.props
    const tKey = 'MANAGEMENT.FLEETS_TAB.'
    let title = t(`${tKey}TITLE_DOCUMENT_FLEET`)

    const numDossiers = this.props.dossiersMode ? this.props.dossiers.length : this.props.fleet?.numDossiers

    if (!showModal || !this.props.fleet) { return null } else {
      return (
        <Modal className="fleet-Modal languages-modal" show={showModal} onHide={() => this.closeModal()} backdrop={'static'}>
          <form className='documentsFleetModal' autoComplete="off" onSubmit={handleSubmit(() => this.closeModal())}>
            <Modal.Header closeButton onHide={() => this.closeModal()}>
              <Modal.Title>{title} <span className='fleet-panel-subTitle'>{' (' + t(`${tKey}SELECTED_DOSSIERS`) + ' ' + (numDossiers ?? 0) + ')'}</span></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <FleetAndClientComponentPage
                {...this.props}
              />
              <DocumentsListComponentPage
                dossiersMode = {this.props.dossiersMode}
                fleet = {this.props.fleet}
                dossiers = {this.props.dossiers}
              ></DocumentsListComponentPage>
            </Modal.Body>
            <Modal.Footer>
              <Col sm={12} className="buttons-wrapper">
                <Button
                  className="btn-Accept"
                  type="submit">
                  {t(`${tKey}ACCEPT`)}
                </Button>
              </Col>
            </Modal.Footer>
          </form>
        </Modal>

      )
    }
  }
}

export default reduxForm({
  form: 'documentsFleetModal'

})(DocumentsFleetModal)
