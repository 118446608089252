import { call, put, select, takeLatest } from 'redux-saga/effects'
import { getAuth } from '../../selectors/access/auth'
import { showLoader, hideLoader } from '../../actions/common'
import messageModalActions from '../../actions/modals/messageModal'
import { fetchCombosSuccess } from '../../actions/combos/combos'
import combosActionTypes from '../../constants/actions/combos/combos'
import { handleError } from '../errors/errorManager'
import getCarlineCombo from '../../services/carline/getCarlineCombo'
import { change } from 'redux-form'

export function * fetchCarlineCombo ({ brandId }) {
  try {
    yield put(showLoader())
    if (brandId) {
      const auth = yield select(getAuth)
      const carlineCombo = yield call(getCarlineCombo, auth.token, brandId)
      yield put(fetchCombosSuccess({ carlineCombo }))
    } else {
      yield put(change('order', 'carlineId', null))
      yield put(change('order', 'catalogueId', null))
      yield put(change('order', 'colorId', null))
      yield put(change('order', 'tapestryId', null))
      yield put(change('order', 'modelId', null))
      yield put(change('order', 'model', null))
    }
  } catch (error) {
    const customHandler = function * () {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.FETCH_COMBO_ERROR'))
    }
    yield call(handleError, { error, customHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchCarlineCombo () {
  yield takeLatest(combosActionTypes.FETCH_CARLINE_COMBO, fetchCarlineCombo)
}
