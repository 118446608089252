
import React, { PureComponent } from 'react'
import { Button, Modal, Col, Row } from 'react-bootstrap'
import InputSelect from '../../commons/form/InputSelect'
import { reduxForm, Field } from 'redux-form'
import { formatPercent } from '../../../util/utils'

class CodeCommisionModal extends PureComponent {
  constructor () {
    super()
    this.state = {
      codeCommissionId: null
    }
  }

  componentDidUpdate (prevProps, prevState) {}

  submitCodeCommission () {
    let codeCommission = this.props.codeCommissionCombo.find(x => x.commissionCancellationCodeId === this.state.codeCommissionId)
    if (this.props.codeCommissionId !== this.state.codeCommissionId) {
      this.props.changeCodeCommission(codeCommission)
    }
    this.props.actions.closeCodeCommissionModal()
  }

  GetFixedPercentage () {
    if (this.state.codeCommissionId && this.props.codeCommissionCombo?.length > 0) {
      let codeCommission = this.props.codeCommissionCombo.find(x => x.commissionCancellationCodeId === this.state.codeCommissionId)
      return ' ' + formatPercent(codeCommission?.fixedPercentage)
    }
    return ' ' + formatPercent(0)
  }

  GetQuantitativePercentage () {
    if (this.state.codeCommissionId && this.props.codeCommissionCombo?.length > 0) {
      let codeCommission = this.props.codeCommissionCombo.find(x => x.commissionCancellationCodeId === this.state.codeCommissionId)
      return ' ' + formatPercent(codeCommission?.quantitativePercentage)
    }
    return ' ' + formatPercent(0)
  }

  GetQualitativePercentage () {
    if (this.state.codeCommissionId && this.props.codeCommissionCombo?.length > 0) {
      let codeCommission = this.props.codeCommissionCombo.find(x => x.commissionCancellationCodeId === this.state.codeCommissionId)
      return ' ' + formatPercent(codeCommission?.qualitativePercentage)
    }
    return ' ' + formatPercent(0)
  }

  getSnapshotBeforeUpdate (prevProps, prevState) {
    if (prevProps.codeCommissionId !== this.props.codeCommissionId) {
      this.setState({ codeCommissionId: this.props.codeCommissionId })
    }
    return null
  }

  render () {
    const {
      t,
      showModal,
      codeCommissionCombo,
      actions: { closeCodeCommissionModal }
    } = this.props
    const tKey = 'MASTERS.CODE_COMMISSION.'

    return (
      <Modal className="codeCommisionModal" show={showModal} onHide={closeCodeCommissionModal} backdrop={'static'}>
        <Modal.Header closeButton onHide={closeCodeCommissionModal}>
          <Modal.Title>{t(`${tKey + 'CHANGE_CODE_COMMISSION'}`)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row key={1} className="inputs-wrapper">
            <Col sm={12} className="custom-width-col">
              <h5>{t(`${tKey + 'CODE_COMMISSION'}`)}</h5>
              {
                <Field
                  id="codeCommissionId"
                  name="codeCommissionId"
                  placeholder={t(`${tKey + 'CODE_COMMISSION'}`)}
                  component={InputSelect}
                  multi={false}
                  valueKey="commissionCancellationCodeId"
                  labelKey="nameDescription"
                  options={codeCommissionCombo}
                  colSm={0}
                  onChange={(value) => this.setState({ codeCommissionId: value })}
                />
              }
            </Col>
          </Row>
          <Row key={2} className="inputs-wrapper">
            <Col sm={4} className="custom-width-col">
              <span>{t(`${tKey + 'FIXED_COMMISSION'}`)}</span><span>{this.GetFixedPercentage()}</span>
            </Col>
            <Col sm={4} className="custom-width-col">
              <span>{t(`${tKey + 'QUANTITATIVE_COMMISSION'}`)}</span><span>{this.GetQuantitativePercentage()}</span>
            </Col>
            <Col sm={4} className="custom-width-col">
              <span>{t(`${tKey + 'QUALITATIVE_COMMISSION'}`)}</span><span>{this.GetQualitativePercentage()}</span>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer className='flexInLine al-R'>

          <Button
            id="code-commision-modal-submit-button"
            className="_Btn accept"
            onClick={() => this.submitCodeCommission()}
            disabled={this.props.pristine}
          >
            {t('MASTERS.SAVE')}
          </Button>

          <Button
            id="code-commission-modal-cancel-button"
            className="_Btn cancel"
            onClick={closeCodeCommissionModal}
          >  {t('MASTERS.CANCEL')}
          </Button>
        </Modal.Footer>

      </Modal>
    )
  }
}

export default reduxForm({
  form: 'codeCommisionModal',
  validate: (values, props) => {
    const error = { codeCommissionId: {} }
    if (values.codeCommissionId) {
      if (!values.codeCommissionId) {
        error.codeCommissionId = props.t('DOSSIER_COMPONENTS.VALIDATIONS.MANDATORY_FIELD')
      }
    }
    return Object.keys(error.codeCommissionId).length > 0 ? error : {}
  }
})(CodeCommisionModal)
