import { bindActionCreators } from 'redux'
import { change } from 'redux-form'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { fetchRatingList, deleteRating, openRatingModal } from '../../../actions/masters/rating/rating'
import RatingMaster from '../../../components/masters/rating/RatingMaster'
import { setChangeTabFromConfMaster } from '../../../actions/masters/masters'

export function mapStateToProps (state) {
  return {
    ...state.rating,
    combos: {
      organizedUnitsAllCombo: state.combos.organizedUnitsAllCombo
    }
  }
}
export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      deleteRating,
      openRatingModal,
      fetchRatingList,
      setChangeTabFromConfMaster,
      change
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(RatingMaster))
