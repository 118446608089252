import React, { PureComponent } from 'react'
import { Field } from 'redux-form'
import { Row, OverlayTrigger, Button, Clearfix } from 'react-bootstrap'
import InputText from '../commons/form/InputText'
import { multipleNormalize, toUpperCase, trimAll } from '../../util/normalizeFunctions'
import SimpleTablePage from '../../containers/commons/general/SimpleTablePage'
import DossierListPopover from './DossierListPopover'
import IDocCarPagination from '../commons/iDocCarPagination'
import { permissions } from '../../constants/backendIds'

class SearchByVehicleForm extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      fieldsConfiguration: {
        findVehicleBydealerNumber: null
      }
    }
  }

  exportData () {
    const tKey = 'SEARCH.CATEGORIES.VEHICLE.'
    let columnsExcel = []
    columnsExcel.push(this.props.t(`${tKey}BRAND`) + '#Brand')
    columnsExcel.push(this.props.t(`${tKey}MODEL`) + '#Model')
    columnsExcel.push(this.props.t(`${tKey}CHASSIS`) + '#VinChassis')
    columnsExcel.push(this.props.t(`${tKey}LICENSE_PLATE`) + '#LicensePlate')
    columnsExcel.push(this.props.t(`${tKey}COMMERCIAL_CODE`) + '#ComercialCode')
    columnsExcel.push(this.props.t(`${tKey}FIRST_LPLATE_DATE_FROM`) + '#LicenseDate')
    if (this.state.fieldsConfiguration.findVehicleBydealerNumber > permissions.hidden) {
      columnsExcel.push(this.props.t(`${tKey}DEALER_NUMBER`) + '#DealerNumber')
    }
    columnsExcel.push(this.props.t(`${tKey}AMOUNT_RELATED_DOSSIERS`) + '#LivesStoreds')
    columnsExcel.push(this.props.t(`${tKey}STATE`) + '#Active')
    this.props.exportToExcel(columnsExcel)
  }

  componentDidMount () {
    let permission = {
      findVehicleBydealerNumber: 0
    }

    if (this.props.permissions && this.props.permissions.findVehicleBydealerNumber) {
      permission.findVehicleBydealerNumber = this.props.permissions.findVehicleBydealerNumber.permission
    }

    this.setState({ fieldsConfiguration: permission })
  }

  render () {
    const {
      t, fields, fieldFill, className, hasSearch, vehicleData, stateTooltip, activeTooltip, inActiveTooltip, selectedFilters, onClickResetSearch,
      onOrderChange, getIconClass, page, pages, count, onPageChange
    } = this.props
    const tKey = 'SEARCH.CATEGORIES.VEHICLE.'
    return (
      <div>
        {!hasSearch ? (
          <Row>
            <Field
              colSm={3}
              id="chassisVehicleSimple"
              name='vehicle.chassis'
              placeholder={t(`${tKey}CHASSIS`)}
              controlLabel={t(`${tKey}CHASSIS`)}
              component={InputText}
              type="text"
              maxLength={17}
              normalize={value => multipleNormalize(value, [toUpperCase, trimAll])}
              onInputChange={(val) => fieldFill({ chassis: val })}
              customClass={fields.chassis ? className : ''}
            />
            <Field
              colSm={3}
              id="licence_plateSimple"
              name='vehicle.licensePlate'
              placeholder={t(`${tKey}LICENSE_PLATE`)}
              controlLabel={t(`${tKey}LICENSE_PLATE`)}
              component={InputText}
              type="text"
              normalize={value => multipleNormalize(value, [toUpperCase, trimAll])}
              onInputChange={(val) => fieldFill({ licence_plate: val })}
              customClass={fields.licence_plate ? className : ''}
            />
            {this.state.fieldsConfiguration.findVehicleBydealerNumber > permissions.hidden &&
            <Field
              colSm={3}
              id="dealerNumberSimple"
              name='vehicle.dealerNumber'
              placeholder={t(`${tKey}DEALER_NUMBER`)}
              controlLabel={t(`${tKey}DEALER_NUMBER`)}
              component={InputText}
              type="text"
              onInputChange={(val) => fieldFill({ dealerNumber: val })}
              customClass={fields.dealerNumber ? className : ''}
              normalize={value => multipleNormalize(value, [trimAll])}
            />
            }
          </Row>
        ) : ([
          <Row className="filters-selected" key="filters-selected">
            <ul className="list-inline pull-left">
              {selectedFilters().length === 0 ? <li>{t(`${tKey}NO_SELECTED_FILTERS`)}</li> : (selectedFilters())}
            </ul>
            <Clearfix/>
            <Button
              onClick={(event) => onClickResetSearch(event)}
              bsStyle="default"
              bsSize="small"
              className="pull-left"
              id='modSearchButtonIdVehSimple'
            >
              {t('SEARCH.ACTIONS.MODIFY_SEARCH')}
              <i className="ico-edit-turquoise" />
            </Button>
            {count > 0 &&
            <Button
              onClick={(event) => this.exportData(event)}
              bsStyle="default"
              bsSize="small"
              id='expSearchButtonIdVehSimple'>
              {t('SEARCH.ACTIONS.EXPORT')}
              <i className="ico-if_Download_1031520" />

            </Button>
            }
          </Row>,
          <div className="table-wrapper" key="table-wrapper">
            <SimpleTablePage
              columns={[
                <th key={0} data-field="vehicleBrand" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}BRAND`)}&nbsp;<i className={getIconClass('vehicleBrand')}/>
                </th>,
                <th key={1} data-field="vehicleModel" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}MODEL`)}&nbsp;<i className={getIconClass('vehicleModel')}/>
                </th>,
                <th key={2} data-field="vehicleVinChassis" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}CHASSIS`)}&nbsp;<i className={getIconClass('vehicleVinChassis')}/>
                </th>,
                <th key={3} data-field="vehicleLicensePlate" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}LICENSE_PLATE`)}&nbsp;<i className={getIconClass('vehicleLicensePlate')}/>
                </th>,
                <th key={4} data-field="comercialCode" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}COMMERCIAL_CODE`)}&nbsp;<i className={getIconClass('comercialCode')} />
                </th>,
                <th key={5} data-field="licensePlateDate" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}FIRST_LPLATE_DATE`)}&nbsp;<i className={getIconClass('licensePlateDate')} />
                </th>,
                (this.state.fieldsConfiguration.findVehicleBydealerNumber > permissions.hidden &&
                  <th key={6} data-field="dealerNumber" onClick={(event) => onOrderChange(event)}>
                    {t(`${tKey}DEALER_NUMBER`)}&nbsp;<i className={getIconClass('dealerNumber')} />
                  </th>),
                <th key={7}>{t(`${tKey}AMOUNT_RELATED_DOSSIERS`)}</th>,
                <OverlayTrigger key={8} trigger={['hover', 'focus']} placement="top" overlay={stateTooltip}>
                  <th data-field="active" onClick={(event) => onOrderChange(event)}>
                    {t(`${tKey}STATE`)}&nbsp;<i className={getIconClass('active')}/>
                  </th>
                </OverlayTrigger>
              ]}
              rows={vehicleData.map((row, idx) => (
                <tr key={idx}>
                  <td>{row.vehicleBrand}</td>
                  <td>{row.vehicleModel}</td>
                  <td>{row.vehicleVinChassis}</td>
                  <td>{row.vehicleLicensePlate}</td>
                  <td>{row.comercialCode}</td>
                  <td>{row.licensePlateDate}</td>
                  {this.state.fieldsConfiguration.findVehicleBydealerNumber > permissions.hidden &&
                   <td>{row.dealerNumber}</td>
                  }
                  <td className="see-more">
                    <DossierListPopover
                      t={t}
                      half={(vehicleData.length || 1) / (idx + 1)}
                      idx={idx}
                      id={'popover-dossier-list' + idx}
                      alive={row.lives}
                      stored={row.stored}
                      subtypesCombo={this.props.subtypesCombo}
                    />
                  </td>
                  <td>
                    {
                      row.active ? (
                        <OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={activeTooltip}>
                          <span className={'color-point green'}/>
                        </OverlayTrigger>)
                        : (<OverlayTrigger trigger={['hover', 'focus']} placement="top" overlay={inActiveTooltip}>
                          <span className={'color-point red'}/>
                        </OverlayTrigger>)
                    }
                  </td>
                </tr>
              ))}
            />
          </div>,
          <div className="search-footer" key="search-footer">
            <IDocCarPagination
              id="btn_pag_sales"
              page={page}
              pagesCount={pages}
              totalRows= {count}
              onSelectAction={(page) => onPageChange(page)}
            />
          </div>
        ])}
      </div>
    )
  }
}

export default SearchByVehicleForm
