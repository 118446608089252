import React, { PureComponent } from 'react'
import { Tooltip, OverlayTrigger } from 'react-bootstrap'

class StageBarSection extends PureComponent {
  render () {
    const { heightPercentage, number, urgencyLabel, urgencyTypeId, colorClass, history, salesmanId, subTypeId, stageId, isLastStage } = this.props
    const tooltip = (
      <Tooltip id="tooltip">
        {urgencyLabel}: {number}
      </Tooltip>
    )
    return (
      <OverlayTrigger overlay={tooltip} placement={isLastStage ? 'left' : 'right'}>
        <div
          style={{ height: heightPercentage }}
          className={colorClass + '' + (heightPercentage === '0%' ? 'empty' : '')}
          onClick={() => history.push(`/dashboard/${salesmanId || 0}/${urgencyTypeId}/${subTypeId}/${stageId}`)}
        />
      </OverlayTrigger>
    )
  }
}

export default StageBarSection
