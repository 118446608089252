import { call, put, select, takeLatest } from 'redux-saga/effects'
import { reset } from 'redux-form'
import dossierRelatedDossiersActionTypes from '../../../constants/actions/dossier/common/dossierRelatedDossiers'
import { showLoader, hideLoader } from '../../../actions/common'
import { getAuth } from '../../../selectors/access/auth'
import { fetchDossierRelatedDossiersSuccess } from '../../../actions/dossier/sales'
import getDossierRelatedDossiers from '../../../services/dossier/get/getDossierRelatedDossiers'
import getDossierHistoricalRelatedDossiers from '../../../services/dossier/get/getDossierHistoricalRelatedDossiers'
import { handleError } from '../../errors/errorManager'

export function * fetchDossierRelatedDossiers ({ dossierId, orderby, historical }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    let relatedDossiers
    if (historical) {
      relatedDossiers = yield call(getDossierHistoricalRelatedDossiers, dossierId, orderby, auth.token)
    } else {
      relatedDossiers = yield call(getDossierRelatedDossiers, dossierId, orderby, auth.token)
    }
    yield put(fetchDossierRelatedDossiersSuccess(relatedDossiers))
    yield put(hideLoader())
  } catch (error) {
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('purchase_dossier'))
      yield put(reset('paperwork_dossier'))
      yield put(reset('campaign_dossier'))
      yield put(reset('service_dossier'))
      yield put(reset('stock_dossier'))
    }
    yield call(handleError, { error, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchDossierRelatedDossiers () {
  yield takeLatest(dossierRelatedDossiersActionTypes.FETCH_DOSSIER_RELATED_DOSSIERS, fetchDossierRelatedDossiers)
}
