export default {
  SET_AUTH: 'SET_AUTH',
  FETCH_AUTH: 'FETCH_AUTH',
  FETCH_AUTH_ERROR: 'FETCH_AUTH_ERROR',
  LOGOUT: 'LOGOUT',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  RESET_PASSWORD: 'RESET_PASSWORD',
  RESET_PASSWORD_ERROR: 'RESET_PASSWORD_ERROR',
  REMEMBER_PASSWORD: 'REMEMBER_PASSWORD',
  REMEMBER_PASSWORD_SUCCESS: 'REMEMBER_PASSWORD_SUCCESS',
  REMEMBER_PASSWORD_ERROR: 'REMEMBER_PASSWORD_ERROR',
  FETCH_PASSWORD_POLICY: 'FETCH_PASSWORD_POLICY',
  FETCH_PASSWORD_POLICY_SUCCESS: 'FETCH_PASSWORD_POLICY_SUCCESS',
  SET_ORGANIZED_UNIT: 'SET_ORGANIZED_UNIT',
  SET_ORGANIZED_UNIT_SUCCESS: 'SET_ORGANIZED_UNIT_SUCCESS',
  CHECK_TOKEN: 'CHECK_TOKEN',
  CHECK_TOKEN_SUCCESS: 'CHECK_TOKEN_SUCCESS',
  ENCRYPT_QR_CONTENT: 'ENCRYPT_QR_CONTENT',
  ENCRYPT_QR_CONTENT_SUCCESS: 'ENCRYPT_QR_CONTENT_SUCCESS',
  SET_SALESMAN_SELECTED_SUCCESS: 'SET_SALESMAN_SELECTED_SUCCESS',
  SET_NEW_VERSION_SUCCESS: 'SET_NEW_VERSION_SUCCESS',
  FETCH_RELEASE_NOTES: 'FETCH_RELEASE_NOTES',
  FETCH_RELEASE_NOTES_SUCCESS: 'FETCH_RELEASE_NOTES_SUCCESS'
}
