import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { initialize } from 'redux-form'
import { translate } from 'react-polyglot'
import { openEditFinancingModal, openAddFinancingModal, fetchFinancingList, deleteFinancing, recoverFinancing, openEditFinancingModalUo, openEditFinancingModalProduct } from '../../../actions/masters/masters'
import Financing from '../../../components/masters/financing/FinancingMaster'

export function mapStateToProps (state) {
  return {
    ...state.financing,
    combos: {
      UOTreeCombo: state.combos.UOTreeCombo
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      openEditFinancingModal,
      openAddFinancingModal,
      fetchFinancingList,
      deleteFinancing,
      recoverFinancing,
      openEditFinancingModalUo,
      openEditFinancingModalProduct,
      initialize
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(Financing))
