
import React, { PureComponent } from 'react'
import { Button, Modal, Col, Row, Form } from 'react-bootstrap'
import { reduxForm, Field } from 'redux-form'
import InputText from '../../../commons/form/InputText'
import InputSelect from '../../../commons/form/InputSelect'
import InputNumber from '../../../commons/form/InputNumber'
import SaleRequestFields from '../saleRequestFields/SaleRequestFields'
import { purchaseTypesVn } from '../../../../constants/dossier/purchase/purchaseTypes'
import { permissions } from '../../../../constants/backendIds'
import commonRegExp from '../../../../util/commonRegExp'
import { modelLength } from '../../../../constants/dossier/common/vehicle'

class OrderModal extends PureComponent {
  constructor () {
    super()
    this.state = {
      purchaseType: null
    }
    this.required = this.required.bind(this)
  }

  submitOrder (values) {
    this.props.actions.saveOrder(values, this.props.dossierId, false, this.props.dossierType)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (!this.props.showModal && nextProps.showModal) {
      this.props.actions.fetchOrder()
      this.props.validate()
    }
  }

  required (value) {
    return (value || value === 0) && value !== ''
      ? undefined : this.props.t('GLOBAL.FORMS.REQUIRED')
  }

  onBrandIdChanges (brandId) {
    this.props.actions.resetCarlineCombo()
    this.props.actions.resetCatalogueCombo()
    this.props.actions.resetColorCombo()
    this.props.actions.resetTapestryCombo()
    this.props.actions.fetchCarlineCombo(brandId)
  }

  onCarlineIdChanges (carlineId) {
    this.props.actions.resetColorCombo()
    this.props.actions.resetTapestryCombo()
    if (!carlineId) {
      this.props.actions.resetCatalogueCombo()
    }
    this.props.actions.fetchCatalogueCombo(carlineId)
  }

  onCatalogueIdChanges (catalogueId) {
    if (this.props.formValues.modelId && catalogueId) {
      this.props.actions.fetchOrderCatalogueModel(catalogueId, this.props.formValues.modelId)
      this.props.actions.fetchColorCombo(catalogueId, this.props.formValues.modelId)
      this.props.actions.fetchTapestryCombo(catalogueId, this.props.formValues.modelId)
    } else {
      this.props.actions.resetColorCombo()
      this.props.actions.resetTapestryCombo()
      this.props.change('model', null)
    }
  }

  onModelIdChanges (modelId) {
    if (modelId && this.props.formValues.catalogueId) {
      this.props.actions.fetchOrderCatalogueModel(this.props.formValues.catalogueId, modelId)
      this.props.actions.fetchColorCombo(this.props.formValues.catalogueId, modelId)
      this.props.actions.fetchTapestryCombo(this.props.formValues.catalogueId, modelId)
    } else {
      this.props.actions.resetColorCombo()
      this.props.actions.resetTapestryCombo()
      this.props.change('model', null)
    }
  }

  render () {
    const {
      t, handleSubmit, readOnlyFields, showOrderModal, combos: {
        brandCombo, fuelCombo, entityTransferCombo, purchaseTypeVnCombo, carlineCombo,
        catalogueCombo, modelCombo, colorCombo, tapestryCombo
      },
      actions: { closeOrderModal }, purchaseOrderExetendedMode, order
    } = this.props

    const tKey = 'DOSSIER_COMPONENTS.ORDER.'
    return (
      <Modal className="add-user-modal sale-request" show={showOrderModal} onHide={closeOrderModal}>
        <Form autoComplete="off" onSubmit={handleSubmit(this.submitOrder.bind(this))}>
          <Modal.Header closeButton>
            <Modal.Title>{t(`${tKey}ORDER`)}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Field
                id="brandIdOrder"
                name="brandId"
                colSm={4}
                controlLabel={t(`${tKey}BRAND`)}
                placeholder={t(`${tKey}BRAND`)}
                component={InputSelect}
                options={brandCombo}
                valueKey="id"
                labelKey="value"
                disabled={readOnlyFields}
                validate={this.required}
                onInputChange={this.onBrandIdChanges.bind(this)}
              />
              {this.props.fieldsConfiguration?.carlineId && this.props.fieldsConfiguration.carlineId.permission !== permissions.hidden && (
                <Field
                  id="carlineIdOrder"
                  name="carlineId"
                  colSm={4}
                  controlLabel={t(`${tKey}CARLINE`)}
                  placeholder={t(`${tKey}CARLINE`)}
                  component={InputSelect}
                  valueKey="id"
                  labelKey="value"
                  options={carlineCombo}
                  disabled={carlineCombo.length === 0 || readOnlyFields}
                  onInputChange={this.onCarlineIdChanges.bind(this)}
                  menuPlacement="auto"
                />)}
              {this.props.fieldsConfiguration?.carline && this.props.fieldsConfiguration.carline.permission !== permissions.hidden && (
                <Field
                  id="carlineOrder"
                  name="carline"
                  colSm={4}
                  controlLabel={t(`${tKey}CARLINE`)}
                  placeholder={t(`${tKey}CARLINE`)}
                  component={InputText}
                  disabled={readOnlyFields}
                  validate={this.required}
                />)}
              {this.props.fieldsConfiguration?.catalogueId && this.props.fieldsConfiguration.catalogueId.permission !== permissions.hidden && (
                <Field
                  id="catalogueIdOrder"
                  name="catalogueId"
                  colSm={4}
                  controlLabel={t(`${tKey}CATALOGUE`)}
                  placeholder={t(`${tKey}CATALOGUE`)}
                  component={InputSelect}
                  valueKey="id"
                  labelKey="value"
                  options={catalogueCombo}
                  disabled={catalogueCombo.length === 0 || readOnlyFields }
                  onInputChange={this.onCatalogueIdChanges.bind(this)}
                  menuPlacement="auto"
                />)}
              {this.props.fieldsConfiguration?.catalogue && this.props.fieldsConfiguration.catalogue.permission !== permissions.hidden && (
                <Field
                  id="catalogueOrder"
                  name="catalogue"
                  colSm={4}
                  controlLabel={t(`${tKey}CATALOGUE`)}
                  placeholder={t(`${tKey}CATALOGUE`)}
                  component={InputText}
                  disabled={readOnlyFields}
                  validate={this.required}
                />)}
              {this.props.fieldsConfiguration?.modelId && this.props.fieldsConfiguration.modelId.permission !== permissions.hidden && (
                <Field
                  id="modelIdOrder"
                  name="modelId"
                  colSm={4}
                  controlLabel={t(`${tKey}MODEL_YEAR`)}
                  placeholder={t(`${tKey}MODEL_YEAR`)}
                  component={InputSelect}
                  valueKey="id"
                  labelKey="value"
                  options={modelCombo}
                  disabled={modelCombo.length === 0 || readOnlyFields }
                  onInputChange={this.onModelIdChanges.bind(this)}
                  menuPlacement="auto"
                />)}
              {this.props.fieldsConfiguration?.modelYear && this.props.fieldsConfiguration.modelYear.permission !== permissions.hidden && (
                <Field
                  id="modelYearOrder"
                  name="modelYear"
                  colSm={4}
                  controlLabel={t(`${tKey}MODEL_YEAR`)}
                  placeholder={t(`${tKey}MODEL_YEAR`)}
                  component={InputNumber}
                  maxLength={modelLength.maxLength}
                  disabled={readOnlyFields}
                  validate={this.required}
                />)}
              {this.props.fieldsConfiguration?.colorId && this.props.fieldsConfiguration.colorId.permission !== permissions.hidden && (
                <Field
                  id="colorIdOrder"
                  name="colorId"
                  colSm={4}
                  controlLabel={t(`${tKey}COLOR`)}
                  placeholder={t(`${tKey}COLOR`)}
                  component={InputSelect}
                  valueKey="id"
                  labelKey="value" options={colorCombo}
                  disabled={colorCombo.length === 0 || readOnlyFields }
                  validate={this.required}
                />)}
              {this.props.fieldsConfiguration?.tapestryId && this.props.fieldsConfiguration.tapestryId.permission !== permissions.hidden && (
                <Field
                  id="tapestryIdOrder"
                  name="tapestryId"
                  colSm={4}
                  controlLabel={t(`${tKey}TAPESTRY`)}
                  placeholder={t(`${tKey}TAPESTRY`)}
                  component={InputSelect}
                  valueKey="id"
                  labelKey="value"
                  options={tapestryCombo}
                  disabled={tapestryCombo.length === 0 || readOnlyFields}
                  validate={this.required}
                />)}
              <Field
                id="modelOrder"
                name="model"
                colSm={4}
                controlLabel={t(`${tKey}MODEL`)}
                placeholder={t(`${tKey}MODEL`)}
                component={InputText}
                disabled={readOnlyFields || (this.props.fieldsConfiguration?.carlineId && this.props.fieldsConfiguration.carlineId.permission !== permissions.hidden)}
                validate={this.required}
              />
              {this.props.fieldsConfiguration?.color && this.props.fieldsConfiguration.color.permission !== permissions.hidden && (
                <Field
                  id="colorOrder"
                  name="color"
                  colSm={4}
                  controlLabel={t(`${tKey}COLOR`)}
                  placeholder={t(`${tKey}COLOR`)}
                  component={InputText}
                  disabled={readOnlyFields}
                  validate={this.required}
                />)}
            </Row>
            <Row className="flexRow">

              {purchaseOrderExetendedMode && (
                <SaleRequestFields
                  t={t}
                  readOnlyFields={readOnlyFields}
                  purchaseTypeVnCombo={purchaseTypeVnCombo}
                  entityTransferCombo={entityTransferCombo}
                  validateRequired={true}
                  order={order}
                  className="sale-request-fields col-sm-4"
                  purchaseTypeChangeHandler={(value) => this.setState({ purchaseType: value })}
                />
              )}
              {purchaseOrderExetendedMode && this.state.purchaseType === purchaseTypesVn.transfer && (
                <div className="clearfix" />)}
              {this.props.fieldsConfiguration?.tapestry && this.props.fieldsConfiguration.tapestry.permission !== permissions.hidden && (
                <Field
                  id="tapestryOrder"
                  name="tapestry"
                  colSm={4}
                  controlLabel={t(`${tKey}TAPESTRY`)}
                  placeholder={t(`${tKey}TAPESTRY`)}
                  component={InputText}
                  disabled={readOnlyFields}
                />)}
              {purchaseOrderExetendedMode && this.state.purchaseType === purchaseTypesVn.normal && (
                <div className="clearfix" />)}
              {this.props.fieldsConfiguration?.comercialCode && this.props.fieldsConfiguration.comercialCode.permission !== permissions.hidden && (
                <Field
                  id="comercialCodeOrder"
                  name="comercialCode"
                  colSm={4}
                  controlLabel={t(`${tKey}COMMERCIAL_CODE`)}
                  placeholder={t(`${tKey}COMMERCIAL_CODE`)}
                  component={InputText}
                  disabled={readOnlyFields}
                />)}
              <Field
                colSm={4}
                id="fuelIdOrder"
                name="fuelId"
                controlLabel={t(`${tKey}FUEL`)}
                placeholder={t(`${tKey}FUEL`)}
                component={InputSelect}
                options={fuelCombo}
                valueKey="id"
                labelKey="value"
                disabled={readOnlyFields}
              />
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Col sm={6} className="btn-wrapper">
              <Button id="btn_save_Order" className="btn-standard" type="submit">{t('GLOBAL.BUTTONS.SAVE')}</Button>
            </Col>
          </Modal.Footer>
        </Form>
      </Modal>
    )
  }
}

const validate = (values, props) => {
  const errors = {}
  if (values) {
    if (!values.brandId || values.brandId === '') {
      errors.brandId = props.t('DOSSIER_COMPONENTS.VALIDATIONS.VEHICLE_BRAND_MANDATORY')
    }
    if (!values.model || values.model === '') {
      errors.model = props.t('DOSSIER_COMPONENTS.VALIDATIONS.VEHICLE_MODEL_MANDATORY')
    }
    if (!values.color || values.color === '') {
      errors.color = props.t('DOSSIER_COMPONENTS.VALIDATIONS.VEHICLE_COLOR_MANDATORY')
    }
    if (!values.colorId || values.colorId === '') {
      errors.colorId = props.t('DOSSIER_COMPONENTS.VALIDATIONS.VEHICLE_COLOR_MANDATORY')
    }
    if (!values.tapestryId || values.tapestryId === '') {
      errors.tapestryId = props.t('DOSSIER_COMPONENTS.VALIDATIONS.VEHICLE_TAPESTRY_MANDATORY')
    }
    if (props.fieldsConfiguration?.modelId.permission &&
      props.fieldsConfiguration?.modelId.permission !== permissions.hidden &&
      (!values.modelId || values.modelId === '')) {
      errors.modelId = props.t('DOSSIER_COMPONENTS.VALIDATIONS.VEHICLE_MODEL_YEAR_MANDATORY')
    }

    if (props.fieldsConfiguration?.modelYear.permission &&
      props.fieldsConfiguration?.modelYear.permission !== permissions.hidden &&
      (!values.modelYear || !values.modelYear.match(commonRegExp.is4DigitYear))) {
      errors.modelYear = props.t('GLOBAL.FORMS.INVALID_STRING')
    }
  }
  return errors
}

export default reduxForm({
  form: 'order',
  validate
})(OrderModal)
