import React, { PureComponent } from 'react'
import { Row, Button, Col } from 'react-bootstrap'
import { Field, reduxForm } from 'redux-form'
import SelectInput from '../../../../commons/SelectInput'
import { currencySymbol } from '../../../../../constants/dossier/common/currencies'
import settings from '../../../../../setting'

class TabPane5 extends PureComponent {
  render () {
    return (
      <Row className="financial-tabpane">
        <Row>
          <Col sm={12}>
            <div className="button-wrapper">
              <Button className="btn-standard-white">
                <i className="ico-check" /> Aprobar dirección
              </Button>
            </div>
          </Col>
        </Row>
        <Row className="financial-wrapper">
          <Col sm={4} className="select-input-full-width">
            <Field
              name='financial'
              label='Financiera:'
              placeholder='Financiera'
              component={SelectInput}
              labelKey='financial'
              valueKey='financial'
            />
          </Col>
          <Col sm={4} className="select-input-full-width">
            <Field
              name='financialType'
              label='Tipo Financiera:'
              placeholder='Tipo Financiera'
              component={SelectInput}
              labelKey='financialType'
              valueKey='financialType'
            />
          </Col>
          <Col sm={4}>
            <div className="text-band">
              <span className="first">Importe a financiar:</span>
              <span className="second">0.0{currencySymbol[settings.currency]}</span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={4}>
            <div className="text-band">
              <span className="first">T.I.N.:</span>
              <span className="second">T.I.N.</span>
            </div>
          </Col>
          <Col sm={4}>
            <div className="text-band">
              <span className="first">Plazos meses:</span>
              <span className="second">Plazos meses</span>
            </div>
          </Col>
          <Col sm={4} className="select-input-full-width">
            <Field
              name='protection'
              label='Protecciones:'
              placeholder='Protecciones'
              component={SelectInput}
              labelKey='protection'
              valueKey='protection'
            />
          </Col>
        </Row>
      </Row>
    )
  }
}

export default reduxForm({ form: 'newForm' })(TabPane5)
