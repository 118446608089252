import React, { PureComponent } from 'react'
import { Table, Row, Col, OverlayTrigger, Tooltip, Button } from 'react-bootstrap'
import DirectionOUModalPage from '../../../containers/masters/DirectionOUModalPage'
import IDocCarPagination from '../../commons/iDocCarPagination'

class DirectionUOMaster extends PureComponent {
  getSortIcon (column = '') {
    switch (this.props.filter.orderBy) {
      case '+' + column:
        return 'ico-sort-selected-asc'
      case '-' + column:
        return 'ico-sort-selected-desc'
      default:
        return 'ico-sort'
    }
  }

  changeOrder (field) {
    switch (this.props.filter.orderBy) {
      case `+${field}`:
        this.props.actions.fetchDirectionUOMaster({ ...this.props.filter, orderBy: `-${field}` })
        break
      default:
        this.props.actions.fetchDirectionUOMaster({ ...this.props.filter, orderBy: `+${field}` })
        break
    }
  }

  render () {
    const {
      t, sourceDirectionsList, pagesCount, directionsUOCount, filter,
      filter: { page },
      actions: { deleteDirectionUOModal, openDirectionUOModal, fetchDirectionUOMaster }
    } = this.props
    const tKey = 'MASTERS.DIRECTION_OU_MASTERS.'
    const editTooltip = (<Tooltip id="editTooltip">{t('MASTERS.EDIT')}</Tooltip>)
    const deleteTooltip = (<Tooltip id="deleteTooltip">{t('MASTERS.DELETE')}</Tooltip>)
    return (
      <Row>
        <Col sm={12}>
          <div className="header-panel">
            <h4>{t(`${tKey}DIRECTION_OU_TITLE`)}</h4>
          </div>
        </Col>
        <DirectionOUModalPage />
        <Col sm={12}>
          <Table className="editable-table" hover responsive>
            <thead>
              <tr>
                <th
                  onClick={() => { this.changeOrder('directionUOId') }}
                >
                  {t('MASTERS.ID')}
                  <i className={this.getSortIcon(t('MASTERS.ID'))} />
                </th>
                <th
                  onClick={() => { this.changeOrder('description') }}
                >
                  {t('MASTERS.DESCRIPTION')}
                  <i className={this.getSortIcon(t('MASTERS.DESCRIPTION'))} />
                </th>
                <th
                  onClick={() => { this.changeOrder('address') }}
                >
                  {t(`${tKey}ADDRESS`)}
                  <i className={this.getSortIcon(t(`${tKey}ADDRESS`))} />
                </th>
                <th
                  onClick={() => { this.changeOrder('cp') }}
                >
                  {t(`${tKey}CP`)}
                  <i className={this.getSortIcon(t(`${tKey}CP`))} />
                </th>
                <th
                  onClick={() => { this.changeOrder('city') }}
                >
                  {t(`${tKey}CITY`)}
                  <i className={this.getSortIcon(t(`${tKey}CITY`))} />
                </th>
                <th
                  onClick={() => { this.changeOrder('state') }}
                >
                  {t(`${tKey}STATE`)}
                  <i className={this.getSortIcon(t(`${tKey}STATE`))} />
                </th>
                <th
                  onClick={() => { this.changeOrder('country') }}
                >
                  {t(`${tKey}COUNTRY`)}
                  <i className={this.getSortIcon(t(`${tKey}COUNTRY`))} />
                </th>
                <th
                  onClick={() => { this.changeOrder('phone') }}
                >
                  {t(`${tKey}PHONE`)}
                  <i className={this.getSortIcon(t(`${tKey}PHONE`))} />
                </th>
                <th className='accesoriesActions'>{t('DOSSIER_COMPONENTS.ACCESSORIES.ACTIONS')}</th>
              </tr>
            </thead>
            <tbody>
              {sourceDirectionsList.map((direction, idx) => (
                <tr key={idx}>
                  <td>{direction.directionUOId}</td>
                  <td>{direction.description}</td>
                  <td>{direction.address}</td>
                  <td>{direction.cp}</td>
                  <td>{direction.city}</td>
                  <td>{direction.state}</td>
                  <td>{direction.country}</td>
                  <td>{direction.phone}</td>
                  <td>
                    <a onClick={() => openDirectionUOModal(direction.directionUOId)}>
                      <OverlayTrigger placement="left" overlay={editTooltip}>
                        <i className="ico-edit-white"/>
                      </OverlayTrigger>
                    </a>
                    <a onClick={() => deleteDirectionUOModal(direction.directionUOId)}>
                      <OverlayTrigger placement="left" overlay={deleteTooltip}>
                        <i className="ico-trash"/>
                      </OverlayTrigger>
                    </a>
                  </td>
                </tr>
              ))}
              <tr className="button-table-wrapper">
                <td>
                  <Button
                    className="btn-editable-table btn-standard"
                    onClick={() => openDirectionUOModal()}>
                  + {t('MASTERS.ADD')}
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>
          <div className="section-users-footer">
            <nav key={1} className="text-center">
              <IDocCarPagination
                id="btn_pag_direction"
                page={page}
                pagesCount={pagesCount}
                totalRows={directionsUOCount}
                onSelectAction={(value) => fetchDirectionUOMaster({ ...filter, page: value })}
              />
            </nav>
          </div>
        </Col>
      </Row>
    )
  }
}

export default DirectionUOMaster
