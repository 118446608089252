import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import importExportFile from '../../components/importExportFile/importExportFile'
import { launchCampaignProcessFiles } from '../../actions/dossier/purchase'

import { searchTabChange, fetchCampaignExport, initializeSearch, resetFilters, setFilters, changeExportCampaignExport, setNoDynamicFilters, launchExportCampaign, launchExportCampaignExcel } from '../../actions/search/search'
import { fetchLowestOrganizedUnits } from '../../actions/combos/combos'
import { translate } from 'react-polyglot'
import TrackingContainer from '../../containers/tracking/trackingContainer'

export function mapStateToProps (state) {
  return {
    ...state.importExportFile,
    sectionsGeneral: state.auth.sections,
    section: state.common.section,
    lowestOrganizedUnitsCombo: state.combos.lowestOrganizedUnitsCombo,
    ...state.search
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      searchTabChange,
      resetFilters,
      fetchLowestOrganizedUnits,
      fetchCampaignExport,
      setFilters,
      initializeSearch,
      changeExportCampaignExport,
      setNoDynamicFilters,
      launchExportCampaign,
      launchExportCampaignExcel,
      launchCampaignProcessFiles
    }, dispatch)
  }
}

var decoratedComponent = TrackingContainer(importExportFile, true)
export default connect(mapStateToProps, mapDispatchToProps)(translate()(decoratedComponent))
