import dossierActionTypes from '../../../constants/actions/dossier/dossier'
import commentsActionTypes from '../../../constants/actions/dossier/common/comments'
import auditInfoTypes from '../../../constants/actions/audit/auditInfo'

export function initialState () {
  return {
    dossierId: null,
    dossierSubTypeId: null,
    dossierParentId: null,
    parentIsHistorical: null,
    organizedUnitId: null,
    isMarked: false,
    header: {},
    checkListHeader: [],
    viewConfiguration: {},
    comments: [],
    dossierRelatedDossiers: [],
    auditInfo: [],
    lastAccessDate: null,
    dossierServiceComponent: { },
    vehicleComponent: {},
    showIssueModal: false,
    unreadComments: null,
    reason: null,
    comment: null,
    cancelCombo: [],
    defaultDestination: null,
    customFieldsByComponent: [],
    customFieldsValues: [],
    customFields: {}
  }
}

function fetchAuditInfoSucces (state, { auditInfo }) {
  return {
    ...state,
    auditInfo
  }
}
function fetchCommentsSuccess (state, { comments }) {
  return {
    ...state,
    comments
  }
}

function fetchDossierRelatedDossiersSuccess (state, { dossierRelatedDossiers }) {
  return {
    ...state,
    dossierRelatedDossiers
  }
}

function openOrCloseIssueModal (state, { openClose }) {
  return {
    ...state,
    showIssueModal: openClose !== undefined ? openClose : !state.showIssueModal
  }
}

function fetchServiceDossierSuccess (state, { dossier, checkListHeader }) {
  return {
    ...state,
    dossierId: dossier.dossierId || state.dossierId,
    dossierSubTypeId: dossier.dossierSubTypeId || state.dossierSubTypeId,
    dossierParentId: dossier.dossierParentId !== undefined ? dossier.dossierParentId : state.dossierParentId,
    parentIsHistorical: dossier.parentIsHistorical || state.parentIsHistorical,
    isMarked: (dossier.isMarked ? dossier.isMarked : state.isMarked),
    organizedUnitId: dossier.organizedUnitId || state.organizedUnitId,
    header: dossier.dossierHeaderComponent || state.header,
    checkListHeader: checkListHeader || state.checkListHeader,
    viewConfiguration: dossier.viewConfiguration || state.viewConfiguration,
    comments: dossier.dossierCompleteComment || state.comments,
    dossierRelatedDossiers: dossier.dossierRelatedDossiers || state.dossierRelatedDossiers,
    auditInfo: dossier.auditInfo || state.auditInfo,
    lastAccessDate: dossier.lastAccessDate || state.lastAccessDate,
    vehicleComponent: dossier.vehicleComponent || state.vehicleComponent,
    dossierServiceComponent: dossier.dossierServiceComponent || state.dossierServiceComponent,
    unreadComments: dossier.unreadComments >= 0 ? dossier.unreadComments : state.unreadComments,
    reason: dossier.reason || state.reason,
    comment: dossier.comment || state.comment,
    cancelCombo: dossier.cancelCombo || state.cancelCombo,
    customFieldsValues: dossier.customFields || state.customFieldsValues
  }
}

function fetchCustomFieldsSuccess (state, { value }) {
  return {
    ...state,
    customFieldsByComponent: value
  }
}

function fetchServiceDefaultDestination (state, { value }) {
  return {
    ...state,
    defaultDestination: value
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case auditInfoTypes.FETCH_AUDIT_INFO_SUCCESS:
      return fetchAuditInfoSucces(state, action)
    case dossierActionTypes.FETCH_DOSSIER_RELATED_DOSSIERS_SUCCESS:
      return fetchDossierRelatedDossiersSuccess(state, action)
    case commentsActionTypes.FETCH_SERVICE_COMMENTS_SUCCESS:
      return fetchCommentsSuccess(state, action)
    case dossierActionTypes.FETCH_SERVICE_DOSSIER_SUCCESS:
      return fetchServiceDossierSuccess(state, action)
    case dossierActionTypes.OPEN_OR_CLOSE_ISSUE_MODAL:
      return openOrCloseIssueModal(state, action)
    case dossierActionTypes.RESTART_SERVICE_DOSSIER_STATE:
      return initialState()
    case dossierActionTypes.FETCH_SERVICE_DEFAULT_DESTINATION:
      return fetchServiceDefaultDestination(state, action)
    case dossierActionTypes.FETCH_CUSTOM_FIELDS_SUCCESS:
      return fetchCustomFieldsSuccess(state, action)
    default:
      return state
  }
}
