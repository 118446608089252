import React, { PureComponent } from 'react'
import { Row, Col } from 'react-bootstrap'

class Tab3 extends PureComponent {
  render () {
    return (
      <Col sm={12}>
        <Row>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Nº Proveedor:</span>
              <span className="second">Nº Proveedor</span>
            </div>
          </Col>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Tipo entidad:</span>
              <span className="second">Tippo entidad</span>
            </div>
          </Col>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">CIF / NIF:</span>
              <span className="second">CIF / NIF</span>
            </div>
          </Col>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Nombre/Razón social proveedor:</span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Nombre Apoderado SL Jurídica:</span>
              <span className="second">Nombre, Apellido 1, Apellido 2</span>
            </div>
          </Col>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Segmentación:</span>
              <span className="second">Segmentación</span>
            </div>
          </Col>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Forma contacto:</span>
              <span className="second">Forma contacto</span>
            </div>
          </Col>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Horario contacto:</span>
              <span className="second">Horario contacto</span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Dirección proveedor:</span>
              <span className="second">Dirección</span>
              <span className="second">Dirección</span>
              <span className="second">Dirección</span>
            </div>
          </Col>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Teléfono:</span>
              <span className="second">Teléfono</span>
            </div>
          </Col>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Móvil:</span>
              <span className="second">Móvil</span>
            </div>
          </Col>
          <Col sm={3}>
            <div className="text-band">
              <span className="first">Email:</span>
              <span className="second">Email</span>
            </div>
          </Col>
        </Row>
      </Col>
    )
  }
}

export default Tab3
