import { put, call, select, takeEvery } from 'redux-saga/effects'
import { getAuth } from '../../../selectors/access/auth'
import mastersActionTypes from '../../../constants/actions/masters/masters'
import { initialize } from 'redux-form'
import deletePwkType from '../../../services/paperworkType/deletePwkType'
import fetchPwkTypesList from '../../../services/paperworkType/fetchPwkTypesList'
import fetchPwkTypeInfo from '../../../services/paperworkType/fetchPwkTypeInfo'
import updatePwkType from '../../../services/paperworkType/updatePwkType'
import postPwkType from '../../../services/paperworkType/postPwkType'
import getLanguageListComboService from '../../../services/language/getLanguageList'
import { fetchPaperwokTypesMasterSucces, openPwkTypeModal, closePaperwokTypeMasterModal } from '../../../actions/masters/masters'
import { showLoader, hideLoader } from '../../../actions/common.js'
import { handleError } from '../../errors/errorManager'
import { dossierSubTypeId } from '../../../constants/dossier/common/dossierSubType'
import { yesNoModal } from '../../modalsListeners/yesNoModal'

export function * fetchPaperworkTypesList ({ filter }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const pwkTypesList = yield call(fetchPwkTypesList, auth.token, filter)
    yield put(fetchPaperwokTypesMasterSucces(pwkTypesList, filter))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchPaperworkTypesList () {
  yield takeEvery(mastersActionTypes.FETCH_PWK_TYPES_MASTER, fetchPaperworkTypesList)
}

export function * fetchPaperworkTypeInfo ({ paperworkTypeId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const pwkType = paperworkTypeId ? yield call(fetchPwkTypeInfo, auth.token, paperworkTypeId) : null
    const languageList = yield call(getLanguageListComboService, auth.token)
    let formValues = {}
    languageList.map((val, index) => {
      if (pwkType && pwkType.paperworkTypeLanguages && pwkType.paperworkTypeLanguages.length > 0 && pwkType.paperworkTypeLanguages.find(p => p.languageId === val.languageId)) {
        let pwkt = pwkType.paperworkTypeLanguages.find(p => p.languageId === val.languageId)
        formValues[`lang${val.languageId}`] = { name: pwkt.name, description: pwkt.description }
      }
    })
    yield put(openPwkTypeModal(pwkType, languageList))
    yield put(initialize('editPwkTypeModal', formValues))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchPaperworkTypeInfo () {
  yield takeEvery(mastersActionTypes.FETCH_PWK_TYPE_MASTER_INFO, fetchPaperworkTypeInfo)
}

export function * deletePaperworkType ({ paperworkTypeId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    let pwkType = yield select(state => state.paperworkTypesMaster)
    yield call(deletePwkType, auth.token, paperworkTypeId)

    const pwkTypesList = yield call(fetchPwkTypesList, auth.token, pwkType.filter)
    yield put(fetchPaperwokTypesMasterSucces(pwkTypesList, pwkType.filter))
    yield put(closePaperwokTypeMasterModal())
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDeletePaperworkType () {
  yield takeEvery(mastersActionTypes.DELETE_PWK_TYPE_MASTER, deletePaperworkType)
}

export function * updatePaperworkType ({ income, languageList, pwkTypeId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    let pwkType = yield select(state => state.paperworkTypesMaster)
    let modelToUpdate = JSON.parse(JSON.stringify(pwkType.pwkTypeModal.paperworkType))

    modelToUpdate.paperworkTypeLanguages.map((lang, index) => {
      lang.name = income[`lang${lang.languageId}`].name
      lang.description = income[`lang${lang.languageId}`].description
    })

    yield call(updatePwkType, auth.token, modelToUpdate)

    const pwkTypesList = yield call(fetchPwkTypesList, auth.token, pwkType.filter)

    yield put(fetchPaperwokTypesMasterSucces(pwkTypesList, pwkType.filter))
    yield put(closePaperwokTypeMasterModal())
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchUpdatePaperworkType () {
  yield takeEvery(mastersActionTypes.UPDATE_PWK_TYPE_MASTER, updatePaperworkType)
}

export function * postPaperworkType ({ values, languageList }) {
  try {
    yield put(showLoader())
    const pwkTypeState = yield select(state => state.paperworkTypesMaster)
    const auth = yield select(getAuth)
    let pwkType = {
      dossierSubTypeId: dossierSubTypeId.tramite,
      paperworkTypeLanguages: []
    }

    languageList.map((l, index) => {
      let lan = {
        languageId: l.languageId,
        name: values[`lang${l.languageId}`].name,
        description: values[`lang${l.languageId}`].description
      }
      pwkType.paperworkTypeLanguages.push(lan)
    })
    yield call(postPwkType, auth.token, pwkType)

    const pwkTypesList = yield call(fetchPwkTypesList, auth.token, pwkTypeState.filter)

    yield put(fetchPaperwokTypesMasterSucces(pwkTypesList, pwkTypeState.filter))
    yield put(closePaperwokTypeMasterModal())
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchPostPaperworkType () {
  yield takeEvery(mastersActionTypes.POST_PWK_TYPE_MASTER, postPaperworkType)
}

export function * adviceClosePaperwokTypeMasterModal () {
  try {
    yield put(showLoader())
    let pwkTypeInitial = yield select(state => state.form.editPwkTypeModal?.initial)
    let pwkTypeValues = yield select(state => state.form.editPwkTypeModal?.values)
    let closeModal = true
    if (pwkTypeInitial && pwkTypeValues && (JSON.stringify(pwkTypeInitial) !== JSON.stringify(pwkTypeValues))) {
      yield put(hideLoader())
      closeModal = yield call(yesNoModal, 'adviceClosePaperwokTypeMasterModal')
    }
    if (closeModal) {
      yield put(closePaperwokTypeMasterModal())
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchAdviceClosePaperwokTypeMasterModal () {
  yield takeEvery(mastersActionTypes.ADVICE_CLOSE_PWK_TYPE_MASTER_MODAL, adviceClosePaperwokTypeMasterModal)
}
