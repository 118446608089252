import React, { PureComponent } from 'react'
import { Button, OverlayTrigger, Tooltip, Tabs, Tab, /* Glyphicon, */ DropdownButton } from 'react-bootstrap'
import { permissions, approvalRoleCode, dossierSubTypes } from '../../../../constants/backendIds'
import { buttonsPermissions } from '../../../../constants/dossier/common/buttonsPermissions'
import { buttonsHeaderPermisions } from '../../../../constants/dossier/common/buttonsHeader'
import { purchaseModalTypes } from '../../../../constants/dossier/common/reassignPurchaseModalTypes'
import SelectVoCompany from '../../../../components/dossiers/common/SelectVoCompany'
import { dossierTypes } from '../../../../constants/dossier/common/dossierTypes'
import { paperworkTypes } from '../../../../constants/dossier/paperwork/paperworkTypes'
import settings from '../../../../setting'
import ChangeUoSelectModal from '../../../../components/dossiers/common/ChangeUoSelectModal'
import { getFieldsPermisions } from '../../../../util/permisionFunctions'
import TrackingContainer from '../../../../containers/tracking/trackingContainer'
import { TrackingActions } from '../../../../constants/tracking/TrackingActions'
import StoreCancelModalPage from '../../../../containers/dossiers/common/StoreCancelModalPage'
import UpdateDossierPage from '../../../../containers/dossiers/common/UpdateDossierPage'
import CancelReasonModal from '../../../../components/dossiers/common/CancelReasonModal'
import { icoCurrencyContent } from '../../../../constants/dossier/common/currencies'

class ButtonsHeader extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      showSelectVoCompanyModal: false,
      showChangeUoSelectModa: false,
      showStoreCancelModal: false,
      showUpdateDossier: false,
      showCancelReasonModal: false,
      fieldsConfiguration: {
        button_delete_vehicle: null
      },
      fields: {
        cancelTab: {
          permission: null
        }
      },
      dossier: {
        reason: null,
        comment: null
      }
    }
  }

  initializePermisions (props) {
    const fieldKeys = Object.keys(this.state.fields)
    const newState = getFieldsPermisions(props.viewConfiguration.tabsConfiguration, fieldKeys, props.viewConfiguration.tabsConfiguration.permission)
    this.setState({ fields: newState.fieldsConfiguration })
  }

  isTabVisible (permissionKey) {
    return this.props.viewConfiguration.tabsConfiguration.filter(tabConf => {
      return tabConf.code === permissionKey && tabConf.permission > permissions.hidden
    }).length > 0
  }

  hasPermission (permissionKey) {
    return !!this.props.viewConfiguration.buttonsPermission && this.props.viewConfiguration.buttonsPermission.indexOf(permissionKey) !== -1
  }

  getMainCode () {
    let tab = this.props.viewConfiguration.tabsConfiguration.find(tab => tab.code === buttonsHeaderPermisions.main && tab.permission === permissions.editable)
    if (tab !== undefined) {
      return true
    } else {
      return false
    }
  }

  onSelectButtonHeader (eventKey) {
    this.props.actions.setActiveTab(eventKey)
    window.scrollTo(0, 0)
  }

  componentDidMount () {
    this.initializePermisions(this.props)
  }

  componentDidUpdate (prevProps) {
    if (prevProps.vehicleCompConfig !== this.props.vehicleCompConfig && this.props.vehicleCompConfig) {
      const fieldKeys = Object.keys(this.state.fieldsConfiguration)
      const newState = this.hasPermission(buttonsPermissions.sale_replace)
        ? getFieldsPermisions(this.props.vehicleCompConfig.fieldsConfiguration, fieldKeys, permissions.editable)
        : getFieldsPermisions(this.props.vehicleCompConfig.fieldsConfiguration, fieldKeys, permissions.hidden)
      this.setState({ ...this.state, ...newState })
    }
  }

  showApproveSalesmanButton (buttonsPermissions) {
    return !this.hasPermission(buttonsPermissions.sale_transfer_request_vo)
  }

  updateDossierToshiko () {
    return new Promise((resolve, reject) => {
      this.props.actions.createUpdateDossierToshiko(this.props.dossierId, null, this.props.organizedUnitId, 'updateConfirm', resolve, reject)
    }).then((result) => {
      if (!result) {
        this.setState({
          showUpdateDossier: true
        })
      } else {
        window.location.reload()
      }
    })
  }

  toggleRejectCommentModal (showCancelReasonModal) {
    this.setState({ showCancelReasonModal })
  }

  getSaleApproveTranslationKey () {
    if (this.props.dossierSubTypes === dossierSubTypes.sale_vn) {
      return 'SALE_APPROVE_VN'
    } else if (this.props.dossierSubTypes === dossierSubTypes.sale_vo) {
      return 'SALE_APPROVE_VO'
    } else {
      return 'SALE_APPROVE'
    }
  }

  getButtons (t, tKey, reset, pristine, dossierId, readOnlyFields, baseTrackingAction, cancelAction, storeAction) {
    let array = []
    // La subida, validación, anulación, solicitud de firma, subida de hojas, borrado de hojas, apertura de la modal de documentos. Añadir información del documento si es posible.
    let openSignModalAction = { ...baseTrackingAction, action: TrackingActions.OPEN_SIGN_MODAL }

    if (!readOnlyFields && this.hasPermission(buttonsPermissions.dossier_undo_cancel) && this.getMainCode()) {
      array.push(<li>
        <Button onClick={() => this.props.actions.undoCancelDossier(dossierId, pristine, reset) } className="first accent" id="undoCancelDossier">
          <span>{t(`${tKey}UNDO_CANCEL`)}</span><i className="iDocIcon-undo-back" />
        </Button>
      </li>)
    }

    if (!readOnlyFields && this.hasPermission(buttonsPermissions.dossier_confirm_cancel) && this.getMainCode()) {
      array.push(<li>
        <Button onClick={() => this.props.actions.confirmCancelDossier(dossierId, pristine, reset)} id="confirmCancel">
          {t(`${tKey}CONFIRM_CANCEL`)}
          <i className="iDocIcon-delete-forever" />
        </Button>
      </li>)
    }

    if (!readOnlyFields && this.hasPermission(buttonsPermissions.vehicle_licenseplate_validate_format) && this.getMainCode()) {
      array.push(<li><Button onClick={() => this.props.actions.saleApproveByRole(pristine, approvalRoleCode.approveLicensePlateFormat)} className="" id="approveLicensePlateFormat">
        <span>{(t(`${tKey}VEHICLE_LICENSEPLATE_VALIDATE_FORMAT`))}</span><i className={'ico-check'} />
      </Button>
      </li>)
    }

    if (!readOnlyFields && this.hasPermission(buttonsPermissions.reg_delivery_cancel) && this.getMainCode()) {
      array.push(<li><Button onClick={() => {
        if (this.state.fields.cancelTab?.permission === null || this.state.fields.cancelTab?.permission < permissions.editable) {
          this.props.actions.registrationDeliveryCancel(dossierId, pristine, reset)
          this.props.actions.trackAction(cancelAction)
        } else {
          this.setState({ showCancelReasonModal: true })
        }
      }}
      id="cancel"
      className="cancel" >
        <span>{t(`${tKey}REGISTRATION_DELIVERY_CANCEL`)}</span>
        <i className="iDocIcon-delete-forever" />
      </Button></li>)
    }

    if (!readOnlyFields && this.hasPermission(buttonsPermissions.purchase_change_subtype) && this.getMainCode()) {
      array.push(<li><Button onClick={() => this.props.actions.changeDossierSubType()}
        id="change_subtype"
        className={''}>
        <span>{t(`${tKey}PURCHASE_CHANGE_EXP`)}</span>
        <i className='iDocIcon-repeat-barter' />
      </Button></li>)
    }

    if (!readOnlyFields && this.hasPermission(buttonsPermissions.campaign_cancel) && this.getMainCode()) {
      array.push(<li><Button onClick={() => {
        if (this.state.fields.cancelTab?.permission === null || this.state.fields.cancelTab?.permission < permissions.editable) {
          this.props.actions.campaignCancel(dossierId, pristine, reset)
          this.props.actions.trackAction(cancelAction)
        } else {
          this.setState({ showCancelReasonModal: true })
        }
      }}
      id="cancel"
      className="cancel">
        <span>{t(`${tKey}CAMPAIGN_CANCEL`)}</span>
        <i className="iDocIcon-delete-forever" />
      </Button></li>)
    }

    if (!readOnlyFields && this.hasPermission(buttonsPermissions.purchase_store) && this.getMainCode()) {
      array.push(<li><Button onClick={() => {
        this.props.actions.storeDossier(dossierId, pristine, reset)
        this.props.actions.trackAction(cancelAction)
      }}
      id="store"
      className="">
        <span>{t(`${tKey}STORE_DOSSIER`)}</span>
        <i className="iDocIcon-archive" />
      </Button></li>)
    }

    if (this.hasPermission(buttonsPermissions.reg_delivery_store) && this.getMainCode()) {
      array.push(<li><Button onClick={() => {
        this.props.actions.storeDossier(dossierId, pristine, reset)
        this.props.actions.trackAction(storeAction)
      }}
      id="store"
      className="">
        <span>{t(`${tKey}STORE_DOSSIER`)}</span>
        <i className="iDocIcon-archive" />
      </Button></li>)
    }

    if (!readOnlyFields && this.hasPermission(buttonsPermissions.sale_approve) && settings.clientFieldValidation !== 'da') {
      array.push(<li><Button onClick={() => this.props.actions.saleApprove(pristine)} className="" id="approve">
        <span>{t(`${tKey}` + this.getSaleApproveTranslationKey())}</span>
        <i className="ico-check" />
      </Button></li>)
    }

    if (!readOnlyFields && this.hasPermission(buttonsPermissions.sale_approve) && settings.clientFieldValidation === 'da') {
      array.push(<li><Button onClick={() => this.props.actions.saleApprove(pristine)} className="" id="approve">
        <span>{t(`${tKey}SALE_APPROVE_DAG`)}</span>
        <i className="ico-check"/>
      </Button></li>)
    }

    if (!readOnlyFields && (this.hasPermission(buttonsPermissions.sale_aprove_pwk) || (this.hasPermission(buttonsPermissions.sale_aprove_pwk_vn)))) {
      array.push(<li><Button onClick={() => this.props.actions.saleApprovePaperwork(pristine, approvalRoleCode.approveSalesManager)} className="" id="approve_paperwork">
        <span>{t(`${tKey}SALE_APPROVE_PAPERWORK`)}</span>
        <i className="ico-check" />
      </Button></li>)
    }

    if (!readOnlyFields && this.hasPermission(buttonsPermissions.sale_approve_pwk_finance)) {
      array.push(<li><Button onClick={() => this.props.actions.saleApprovePaperwork(pristine, approvalRoleCode.approveAdministrationManager)} className="" id="approve_paperwork_finance">
        <span>{t(`${tKey}SALE_APPROVE_PAPERWORK_FINANCE`)}</span>
        <i className="ico-check" />
      </Button></li>)
    }

    if (!readOnlyFields && this.hasPermission(buttonsPermissions.sale_approve_pwk_manager)) {
      array.push(<li><Button onClick={() => this.props.actions.saleApprovePaperwork(pristine, approvalRoleCode.approveManager)} className="" id="sale_approve_pwk_manager">
        <span>{t(`${tKey}SALE_APPROVE_PAPERWORK_MANAGER`)}</span>
        <i className="ico-check" />
      </Button></li>)
    }

    if (!readOnlyFields && this.hasPermission(buttonsPermissions.sale_reject)) {
      array.push(<li><Button onClick={() => this.props.actions.saleReject()} className="cancel" id="reject">
        <span>{t(`${tKey}SALE_REJECT`)}</span>
        <i className="ico-cancel" />
      </Button></li>)
    }

    if (!readOnlyFields && (this.hasPermission(buttonsPermissions.sale_skip_charge) || this.hasPermission(buttonsPermissions.sale_skip_charge_vn))) {
      array.push(<li><Button onClick={() => this.props.actions.saleSkipCharge(pristine)} className="" id="skip_charge">
        <span>{t(`${tKey}SALE_SKIP_CHARGE`)}</span><i className="iDocIcon-sign-out-user-menu" />
      </Button></li>)
    }

    if (!readOnlyFields && (this.hasPermission(buttonsPermissions.sale_skip_document) || this.hasPermission(buttonsPermissions.sale_skip_document_vn))) {
      array.push(<li><Button onClick={() => this.props.actions.saleSkipDocument(pristine)} className="" id="skip_document">
        <span>{t(`${tKey}SALE_SKIP_DOCUMENT`)}</span><i className="iDocIcon-sign-out-user-menu" />
      </Button></li>)
    }

    if (!readOnlyFields && (this.hasPermission(buttonsPermissions.sale_skip_paperwork) || this.hasPermission(buttonsPermissions.sale_skip_paperwork_vn))) {
      array.push(<li><Button onClick={() => this.props.actions.saleSkipPaperwork(pristine)} className="" id="skip_paperwork">
        <span>{t(`${tKey}SALE_SKIP_PAPERWORK`)}</span><i className="iDocIcon-sign-out-user-menu" />
      </Button></li>)
    }

    if (!readOnlyFields && this.hasPermission(buttonsPermissions.sale_skip_delivery)) {
      array.push(<li><Button onClick={() => this.props.actions.saleSkipDelivery(pristine)} className="" id="skip_delivery">

        <span>{t(`${tKey}SALE_SKIP_DELIVERY`)}</span><i className="iDocIcon-sign-out-user-menu" />
      </Button></li>)
    }

    if (!readOnlyFields && this.hasPermission(buttonsPermissions.sale_send_approve)) {
      array.push(<li><Button onClick={() => this.props.actions.saleSendApprove(pristine)} className="" id="send_approve">

        <span>{t(`${tKey}SALE_SEND_APPROVE`)}</span><i className="ico-email" />
      </Button></li>)
    }

    if (!readOnlyFields && this.hasPermission(buttonsPermissions.sale_transfer_request_vo)) {
      array.push(<li><Button onClick={() => this.props.actions.saleTransferRequest(pristine)} className="" id="sale_transfer_request">

        <span>{t(`${tKey}SALE_TRANSFER_REQUEST`)}</span><i className="ico-email" />
      </Button></li>)
    }

    if (!readOnlyFields && this.hasPermission(buttonsPermissions.sale_transfer_request_approved_vo)) {
      array.push(<li><Button onClick={() => this.props.actions.saleTransferRequestApproved(pristine)} className="" id="sale_transfer_request">

        <span>{t(`${tKey}SALE_TRANSFER_REQUEST_APPROVE`)}</span><i className="ico-email" />
      </Button></li>)
    }

    if (!readOnlyFields && this.hasPermission(buttonsPermissions.sale_approve_salesman) && this.showApproveSalesmanButton(buttonsPermissions)) {
      array.push(<li><Button onClick={() => this.props.actions.saleApproveByRole(pristine, approvalRoleCode.approveSalesman)} className="" id="salesManagerApprove">

        <span>{t(`${tKey}SALE_APPROVE_SALESMAN`)}</span><i className="ico-check" />
      </Button></li>)
    }

    if (!readOnlyFields && this.hasPermission(buttonsPermissions.received_agent_fee) && this.showApproveSalesmanButton(buttonsPermissions)) {
      array.push(<li><Button onClick={() => this.props.actions.saleApproveByRole(pristine, approvalRoleCode.agentOperation, true)} className="" id="receivedAgentFee">

        <span>{t(`${tKey}RECEIVED_AGENT_FEE`)}</span><i className="ico-check" />
      </Button></li>)
    }

    if (!readOnlyFields && this.hasPermission(buttonsPermissions.button_approve_controller)) {
      array.push(<li><Button onClick={() => this.props.actions.saleApproveByRole(pristine, approvalRoleCode.approveSalesController)} className="" id="approveSalesController">

        <span>{t(`${tKey}SALE_APPROVE_CONTROLLER`)}</span><i className="ico-check" />
      </Button></li>)
    }

    if (!readOnlyFields && this.hasPermission(buttonsPermissions.sale_approve_sales_manager)) {
      array.push(<li><Button onClick={() => this.props.actions.saleApproveByRole(pristine, approvalRoleCode.approveSalesManager)} className="" id="approveSalesManager">

        <span>{t(`${tKey}SALE_APPROVE_SALES_MANAGER`)}</span><i className="ico-check" />
      </Button></li>)
    }

    if (!readOnlyFields && this.hasPermission(buttonsPermissions.sale_vo_approve_sales_manager)) {
      array.push(<li><Button onClick={() => this.props.actions.saleApproveByRole(pristine, approvalRoleCode.approveSalesManagerVO)} className="" id="approveSalesManagerVO">

        <span>{t(`${tKey}SALE_VO_APPROVE_SALES_MANAGER`)}</span><i className="ico-check" />
      </Button></li>)
    }

    if (!readOnlyFields && this.hasPermission(buttonsPermissions.sale_approve_bpa)) {
      array.push(<li><Button onClick={() => this.props.actions.saleApproveByRole(pristine, approvalRoleCode.approveBPA)} className="" id="approveBPA">

        <span>{t(`${tKey}SALE_APPROVE_BPA`)}</span><i className="ico-check" />
      </Button></li>)
    }

    if (!readOnlyFields && this.hasPermission(buttonsPermissions.sale_approve_cfo)) {
      array.push(<li><Button onClick={() => this.props.actions.saleApproveByRole(pristine, approvalRoleCode.approveCFO)} className="" id="approveCFO">
        <span>{t(`${tKey}SALE_APPROVE_CFO`)}</span><i className="ico-check" />
      </Button></li>)
    }

    if (!readOnlyFields && this.hasPermission(buttonsPermissions.sale_approve_logistics)) {
      array.push(<li><Button onClick={() => this.props.actions.saleApproveByRole(pristine, approvalRoleCode.approveSalesLogistics)} className="" id="approveSalesLogistics">
        <span>{t(`${tKey}SALE_APPROVE_LOGISTICS`)}</span><i className="ico-check" />
      </Button></li>)
    }

    if (!readOnlyFields && this.hasPermission(buttonsPermissions.sale_approve_administration)) {
      array.push(<li><Button onClick={() => this.props.actions.saleApproveByRole(pristine, approvalRoleCode.approveSalesAdmin)} className="" id="approveSalesAdmin">

        <span>{t(`${tKey}SALE_APPROVE_ADMINISTRATION`)}</span><i className="ico-check" />
      </Button></li>)
    }

    if (!readOnlyFields && this.hasPermission(buttonsPermissions.sale_store) && this.getMainCode()) {
      array.push(<li><Button onClick={() => {
        this.props.actions.storeDossier(dossierId, pristine, reset)
        this.props.actions.trackAction(storeAction)
      }}
      id="store" className="f" >
        <span>{t(`${tKey}STORE_DOSSIER`)}</span><i className="iDocIcon-archive" />
      </Button></li>)
    }

    if (!readOnlyFields && this.hasPermission(buttonsPermissions.paperwork_gen_delivery_note)) {
      array.push(<li><Button onClick={() => this.props.actions.paperworkGenDelivery(pristine)} className="" id="sendToAgency">
        <span>{t(`${tKey}PAPERWORK_SEND_TO_AGENCY`)}</span><i className="iDocIcon-envelope-user-menu" />
      </Button></li>)
    }

    if (!readOnlyFields && this.hasPermission(buttonsPermissions.paperwork_transfer_completed)) {
      array.push(<li><Button onClick={() => this.props.actions.paperworkTransferCompleted(pristine)} className="" id="sendToAgency">
        <span>{t(`${tKey}PAPERWORK_TRANSFER_COMPLETED`)}</span><i className="iDocIcon-envelope-notifications-open" />
      </Button></li>)
    }

    const rejectPaperworkTooltip =
    (<Tooltip id="rejectPaperworkTooltip" bsStyle="link">
      {
        this.props.paperworkTypeId && this.props.paperworkTypeId === paperworkTypes.registration
          ? t(`${tKey}PAPERWORK_REJECT_REGISTRATION_TOOLTIP`)
          : t(`${tKey}PAPERWORK_REJECT_TOOLTIP`)
      }
    </Tooltip>)

    if (!readOnlyFields && this.hasPermission(buttonsPermissions.paperwork_reject)) {
      array.push((
        <li><OverlayTrigger placement="left" overlay={rejectPaperworkTooltip}>
          <Button onClick={() => this.props.actions.paperworkReject()} className="cancel" id="reject">

            <span>
              {
                this.props.paperworkTypeId && this.props.paperworkTypeId === paperworkTypes.registration ? (
                  t(`${tKey}PAPERWORK_REJECT_REGISTRATION`)
                ) : (
                  t(`${tKey}PAPERWORK_REJECT`)
                )
              }
            </span>
            <i className="ico-cancel" />

          </Button></OverlayTrigger>
        </li>
      ))
    }

    if (!readOnlyFields && this.hasPermission(buttonsPermissions.button_request_invoice_to_dms)) {
      array.push(<li><Button onClick={() => this.props.actions.requestInvoiceToDMS(pristine)} className="" id="button_request_invoice_to_dms">
        <span>{t(`${tKey}REQUEST_INVOICE_TO_DMS`)}</span><i className="iDocIcon-receipt-sale" />
      </Button></li>)
    }

    if (!readOnlyFields && this.hasPermission(buttonsPermissions.button_request_vehicle_delivery_to_dms)) {
      array.push(<li><Button onClick={() => this.props.actions.requestDeliveryToDMS(pristine)} className="" id="button_request_vehicle_delivery_to_dms">
        <span>{t(`${tKey}REQUEST_DELIVERY_TO_DMS`)}</span><i className="iDocIcon-arrow-alt-circle-left-collapse" />
      </Button></li>)
    }

    if (!readOnlyFields && this.hasPermission(buttonsPermissions.button_request_vehicle_outbound_to_dms)) {
      array.push(<li><Button onClick={() => this.props.actions.requestOutboundToDMS(pristine)} className="" id="button_request_vehicle_outbound_to_dms">
        <span>{t(`${tKey}REQUEST_OUTBOUND_TO_DMS`)}</span><i className="iDocIcon-arrow-alt-circle-right-uncollapse" />
      </Button></li>)
    }

    if (!readOnlyFields && this.hasPermission(buttonsPermissions.sale_replace) && this.getMainCode() && this.state.fieldsConfiguration.button_delete_vehicle && this.state.fieldsConfiguration.button_delete_vehicle.permission === permissions.editable) {
      array.push(<li><Button onClick={() => this.props.actions.fetchAndOpenReplacePurchaseModal(purchaseModalTypes.saleDossier, pristine)} className="" id="saleReplace">
        <span>{t(`${tKey}SALE_REPLACE`)}</span><i className="iDocIcon-file-document" />
      </Button></li>)
    }

    if (!readOnlyFields && this.hasPermission(buttonsPermissions.paperwork_store) && this.getMainCode()) {
      array.push(<li><Button onClick={() => {
        this.props.actions.storeDossier(dossierId, pristine, reset)
        this.props.actions.trackAction(storeAction)
      }}
      id="store"
      className="" >
        <span>{t(`${tKey}STORE_DOSSIER`)}</span><i className="iDocIcon-archive" />
      </Button></li>)
    }

    if (!readOnlyFields && this.hasPermission(buttonsPermissions.purchase_send_approve)) {
      array.push(<li><Button onClick={() => this.props.actions.purchaseSendApprove(pristine)} className="" id="purchase_send_approve">
        <span>{t(`${tKey}PURCHASE_SEND_APPROVE`)}</span><i className="iDocIcon-envelope-user-menu" />
      </Button></li>)
    }
    if (!readOnlyFields && this.hasPermission(buttonsPermissions.purchase_transfer_request_vo)) {
      array.push(<li><Button onClick={() => this.props.actions.purchaseTransferRequest(pristine)} className="" id="purchase_transfer_request_vo">
        <span>{t(`${tKey}PURCHASE_TRANSFER_REQUEST`)}</span><i className="iDocIcon-envelope-notifications-close" />
      </Button></li>)
    }
    if (!readOnlyFields && this.hasPermission(buttonsPermissions.purchase_transfer_request_approved_vo)) {
      array.push(
        <li><Button onClick={() => this.props.actions.purchaseTransferRequestApproved(pristine)} className="" id="purchase_transfer_request_approved_vo">
          <span>{t(`${tKey}PURCHASE_TRANSFER_REQUEST_APPROVED`)}</span><i className="iDocIcon-envelope-notifications-open" />
        </Button></li>)
    }
    if (!readOnlyFields && this.hasPermission(buttonsPermissions.purchase_approve)) {
      array.push(
        <li><Button onClick={() => this.props.actions.purchaseApprove(pristine)} className="" id="purchase_approve">
          <span>{t(`${tKey}PURCHASE_APPROVE`)}</span><i className="ico-check" />
        </Button></li>)
    }
    if (!readOnlyFields && this.hasPermission(buttonsPermissions.purchase_approved_manager)) {
      array.push(
        <li><Button onClick={() => this.props.actions.purchaseApproveManager(pristine)} className="" id="purchase_approved_manager">
          <span>{t(`${tKey}PURCHASE_APPROVE_MANAGER`)}</span><i className="iDocIcon-id-badge-initial-seller" />
        </Button></li>)
    }
    if (!readOnlyFields && this.hasPermission(buttonsPermissions.purchase_approve_sales_manger_vo)) {
      array.push(
        <li><Button onClick={() => this.props.actions.purchaseApproveSalesManagerVo(pristine)} className="" id="purchase_approve_sales_manger_vo">
          <span>{t(`${tKey}PURCHASE_APPROVE_SALES_MANAGER_VO`)}</span><i className="iDocIcon-id-badge-in-charge-seller" />
        </Button></li>)
    }
    if (!readOnlyFields && this.hasPermission(buttonsPermissions.campaign_approve) && this.getMainCode()) {
      array.push(<li><Button onClick={() => this.props.actions.campaignApprove(pristine)} className="" id="campaign_approve">
        <span>{t(`${tKey}CAMPAIGN_APPROVE`)}</span><i className="ico-check" />
      </Button></li>)
    }
    if (!readOnlyFields && this.hasPermission(buttonsPermissions.campaign_send)) {
      array.push(<li><Button onClick={() => this.props.actions.campaignSend(pristine)} className="" id="campaign_send">
        <span>{t(`${tKey}CAMPAIGN_SEND`)}</span><i className="iDocIcon-envelope-user-menu" />
      </Button></li>)
    }
    if (!readOnlyFields && this.hasPermission(buttonsPermissions.campaign_reject)) {
      array.push(
        <li><Button onClick={() => this.props.actions.campaignReject()} className="cancel" id="reject">
          <span>{t(`${tKey}CAMPAIGN_REJECT`)}</span><i className="ico-cancel" />
        </Button></li>)
    }
    if (!readOnlyFields && this.hasPermission(buttonsPermissions.campaign_store) && this.getMainCode()) {
      array.push(
        <li><Button onClick={() => {
          this.props.actions.storeDossier(dossierId, pristine, reset)
          this.props.actions.trackAction(storeAction)
        }}
        id="store"
        className="" >
          <span>{t(`${tKey}STORE_DOSSIER`)}</span><i className="iDocIcon-archive" />
        </Button></li>)
    }
    if (!readOnlyFields && this.hasPermission(buttonsPermissions.purchase_reject)) {
      array.push(
        <li><Button onClick={() => this.props.actions.purchaseReject()} className="" id="purchase_reject">
          <span>{t(`${tKey}PURCHASE_REJECT`)}</span><i className="ico-cancel" />
        </Button></li>)
    }
    if (this.props.dossierType === dossierTypes.purchase && !readOnlyFields && this.hasPermission(buttonsPermissions.purchase_internal_vo)) {
      array.push(
        <li><Button onClick={() => this.setState({ showSelectVoCompanyModal: true })} className="" id="purchase_internal_vo">
          <span>{t(`${tKey}SELECT_VO_COMPANY`)}</span><i className="iDocIcon-copyright-regular" />
        </Button></li>)
    }
    if (!readOnlyFields && this.hasPermission(buttonsPermissions.sale_approve_fleet)) {
      array.push(
        <li><Button onClick={() => this.props.actions.saleApproveByRole(pristine, approvalRoleCode.approveSalesFleets)} className="" id="sale_approve_fleet">
          <span>{t(`${tKey}SALE_APPROVE_FLEET`)}</span><i className="ico-check" />
        </Button></li>)
    }
    if (this.props.dossierType === dossierTypes.sales && !readOnlyFields && this.hasPermission(buttonsPermissions.sale_approve_voucher)) {
      array.push(
        <li><Button onClick={() => this.props.actions.saleApproveVoucher(pristine)} className="" id="sale_approve_voucher">
          <span>{t(`${tKey}SALE_APPROVE_VOUCHER`)}</span><i className="ico-check" />
        </Button></li>)
    }
    if (!readOnlyFields && this.hasPermission(buttonsPermissions.sale_approve_manager)) {
      array.push(
        <li><Button onClick={() => this.props.actions.saleApproveByRole(pristine, approvalRoleCode.approveManager)} className="" id="sale_approve_manager">
          <span>{t(`${tKey}SALE_APPROVE_MANAGER`)}</span><i className="ico-check" />
        </Button></li>)
    }
    if (!readOnlyFields && this.hasPermission(buttonsPermissions.sale_approve_commercial_director)) {
      array.push(
        <li><Button onClick={() => this.props.actions.saleApproveByRole(pristine, approvalRoleCode.approveSalesCommercialDirector)} className="" id="sale_approve_commercial_director">
          <span>{t(`${tKey}SALE_APPROVE_COMMERCIAL_DIRECTOR`)}</span><i className="ico-check" />
        </Button></li>)
    }
    if (!readOnlyFields && this.hasPermission(buttonsPermissions.resend_processing_agency)) {
      array.push(
        <li><Button onClick={() => this.props.actions.resendToProcessingAgency(dossierId, this.props.lastAccessDate, reset)} className="" id="resend_processing_agency">
          <span>{t(`${tKey}RESEND_PROCESSING_AGENCY`)}</span><i className="iDocIcon-envelope-user-menu" />
        </Button></li>)
    }
    if (this.props.dossierType === dossierTypes.sales && !readOnlyFields && this.hasPermission(buttonsPermissions.sale_pay_renting)) {
      array.push(
        <li><Button onClick={() => this.props.actions.salePayRenting(dossierId)} className="" id="sale_pay_renting">
          <span>{t(`${tKey}SALE_PAY_RENTING`)}</span><i className="iDocIcon-coins-finance" />
        </Button></li>)
    }
    if (!readOnlyFields && this.hasPermission(buttonsPermissions.configureCampaign)) {
      array.push(
        <li><Button onClick={() => this.props.actions.configureCampaign(this.props.actions.openConfigureCampaignEdition, dossierId, this.props.pristine)} className="" id="configureCampaign">
          <span>{t(`${tKey}CONFIGURE_CAMPAIGNS`)}</span><i className="iDocIcon-settings" />
        </Button></li>)
    }
    if (!readOnlyFields && this.hasPermission(buttonsPermissions.sendDocumentsToSignSales) && this.getMainCode()) {
      array.push(
        <li><Button
          onClick={() => {
            this.props.actions.openDocumentsToSendSign(this.props.actions.openSendDocumentsToSignModal)
            this.props.actions.trackAction(openSignModalAction)
          }}
          id="sendDocumentsToSign"
          className="" >
          <span>{t(`${tKey}SEND_DOCUMENTS_SIGN`)}</span><i className="iDocIcon-draw-signature" />
        </Button></li>)
    }
    if (!readOnlyFields && this.hasPermission(buttonsPermissions.sendDocumentsToSignPurchase) && this.getMainCode()) {
      array.push(
        <li><Button onClick={() => {
          this.props.actions.openDocumentsToSendSign(this.props.actions.openSendDocumentsToSignModal)
          this.props.actions.trackAction(openSignModalAction)
        }}
        id="sendDocumentsToSign"
        className="" >
          <span>{t(`${tKey}SEND_DOCUMENTS_SIGN`)}</span><i className="iDocIcon-draw-signature" />
        </Button></li>)
    }
    if (!readOnlyFields && this.hasPermission(buttonsPermissions.skipItv)) {
      array.push(
        <li><Button onClick={() => this.props.actions.skipItv(dossierId)} className="first btn-DocsToSend-blue btn-white btn-icon" id="skipItv">
          <span>{t(`${tKey}SKIP_ITV`)}</span><i className="iDocIcon-admin-tools" />
        </Button></li>)
    }
    if (!readOnlyFields && this.props.viewConfiguration && this.props.viewConfiguration.dynamicButtonsPermission) {
      array.push(this.props.viewConfiguration.dynamicButtonsPermission.map((button, idx) => {
        return (
          <li><Button onClick={() => this.props.actions.approveMargin(dossierId, button.code)} className="" key={idx} id={button.code}>
            <i className="ico-check" />
            <span>{button.buttonText}</span>
          </Button></li>
        )
      }))
    }
    if (!readOnlyFields && this.hasPermission(buttonsPermissions.changeUO)) {
      array.push(
        <li><Button onClick={() => this.setState({ showChangeUoSelectModa: true })} className="" id="changeUO">
          <span>{t(`${tKey}CHANGE_UO`)}</span><i className="iDocIcon-Organizational-Unit" />
        </Button></li>
      )
    }
    if (this.hasPermission(buttonsPermissions.anulateCampaign)) {
      array.push(
        <li><Button onClick={() => this.props.actions.anulateCampaign(dossierId)} className="cancel" id="anulateCampaign">
          <span>{t(`${tKey}ANULATE`)}</span><i className="iDocIcon-file-excel" />
        </Button></li>)
    }
    if (this.hasPermission(buttonsPermissions.btn_get_vehicle_from_quiter) && this.getMainCode()) {
      array.push(
        <li><Button onClick={() => this.props.actions.openChargeModalDealer(dossierId, dossierSubTypes)} className="" id="btn_get_vehicle_from_quiter">
          <span>{t(`${tKey}CHARGE_PURCHASE_FROM_QUITER`)}</span><i className="iDocIcon-arrow-alt-circle-left-collapse" />
        </Button></li>)
    }
    if (this.hasPermission(buttonsPermissions.update_dossier_toshiko)) {
      array.push(
        <li><Button onClick={() => this.updateDossierToshiko()} className="" id="update_dossier_toshiko">
          <span>{t(`${tKey}UPDATE_DOSSIER`)}</span><i className="iDocIcon-update" />
        </Button></li>)
    }
    if (this.hasPermission(buttonsPermissions.campaign_sale_replace) && this.getMainCode()) {
      array.push(
        <li><Button onClick={() => this.props.actions.fetchAndOpenReplacePurchaseModal(purchaseModalTypes.campaignDossier, pristine)} className="" id="campaign_sale_replace">
          <span>{t(`${tKey}SALE_REPLACE`)}</span><i className="iDocIcon-file-document" />
        </Button></li>)
    }
    if (!readOnlyFields && this.hasPermission(buttonsPermissions.reg_delivery_replace) && this.getMainCode()) {
      array.push(
        <li><Button onClick={() => this.props.actions.fetchAndOpenReplacePurchaseModal(purchaseModalTypes.registrationDeliveryDossier, pristine)} className="" id="reg_delivery_replace">
          <span>{t(`${tKey}SALE_REPLACE`)}</span><i className="iDocIcon-file-document" />
        </Button></li>)
    }
    if (!readOnlyFields && this.hasPermission(buttonsPermissions.bt_validate_campaign) && this.getMainCode()) {
      array.push(
        <li><Button onClick={() => this.props.actions.campaignvalidate(dossierId)} className="" id="bt_validate_campaign">
          <span>{t(`${tKey}BT_VALIDATE_CAMPAIGN`)}</span><i className="ico-check" />
        </Button></li>)
    }
    if (!readOnlyFields && (this.hasPermission(buttonsPermissions.bt_denie_campaig_stageApprove) || this.hasPermission(buttonsPermissions.bt_denie_campaig_stageVerificaction)) && this.getMainCode()) {
      array.push(
        <li><Button onClick={() => this.props.actions.campaignDenie(dossierId)} className="cancel" id="bt_denie_campaig_stageApprove">
          <span>{t(`${tKey}BT_DENIE_CAMPAIGN`)}</span><i className="iDocIcon-Cancel-forbidden" />
        </Button></li>)
    }
    if (!readOnlyFields && (this.hasPermission(buttonsPermissions.bt_return_campaig_stageApprove) || this.hasPermission(buttonsPermissions.bt_return_campaig_stageVerificaction)) && this.getMainCode()) {
      array.push(
        <li><Button onClick={() => this.props.actions.campaignReject(dossierId)} className="" id="bt_return_campaig_stageApprove">
          <span>{t(`${tKey}BT_RETURN_CAMPAIGN`)}</span><i className="iDocIcon-file-copy-document-management" />
        </Button></li>)
    }
    if (!readOnlyFields && this.hasPermission(buttonsPermissions.service_dossier_confirm)) {
      array.push(
        <li><Button onClick={() => this.props.actions.serviceDossierApprove(dossierId, reset)} className="" id="service_dossier_confirm">
          <span>{t(`${tKey}SERVICE_DOSSIER.APPROVE`)}</span><i className="ico-check" />
        </Button></li>)
    }

    if (!readOnlyFields && this.hasPermission(buttonsPermissions.service_resolve_issue)) {
      array.push(
        <li><Button onClick={() => this.props.actions.serviceResolveIssue(pristine)} className="" id="service_resolve_issue">
          <span>{t(`${tKey}SERVICE_DOSSIER.ISSUE_RESOLVED`)}</span><i className="iDocIcon-handyman-operate" />
        </Button></li>)
    }
    if (!readOnlyFields && this.hasPermission(buttonsPermissions.service_request_spareParts)) {
      array.push(
        <li><Button onClick={() => this.props.actions.serviceRequestSpareParts(pristine, dossierId)} className="" id="service_request_spareParts">
          <span>{t(`${tKey}SERVICE_DOSSIER.REQUEST_SPARE_PARTS`)}</span><i className="iDocIcon-update" />
        </Button></li>)
    }
    if (!readOnlyFields && this.hasPermission(buttonsPermissions.service_complete_spareParts)) {
      array.push(
        <li><Button onClick={() => this.props.actions.serviceCompleteSpareParts(pristine)} className="" id="service_complete_spareParts">
          <span>{t(`${tKey}SERVICE_DOSSIER.COMPLETE_SPARE_PARTS`)}</span><i className="iDocIcon-update" />
        </Button></li>)
    }
    if (!readOnlyFields && this.hasPermission(buttonsPermissions.service_complete_deliveryPreparation)) {
      array.push(
        <li><Button onClick={() => this.props.actions.serviceCompleteDeliveryPreparation(pristine)} className="" id="service_complete_deliveryPreparation">
          <span>{t(`${tKey}SERVICE_DOSSIER.COMPLETE_DELIVERY_PREPARATION`)}</span><i className="ico-check" />
        </Button></li>)
    }

    if (!readOnlyFields && this.hasPermission(buttonsPermissions.service_approve_logistic) && this.getMainCode()) {
      array.push(
        <li><Button onClick={() => this.props.actions.serviceApproveByRole(pristine, approvalRoleCode.approveServiceLogistics)} className="" id="service_approve_logistic">
          <span>{t(`${tKey}SERVICE_DOSSIER.APPROVE_BY_LOGISTIC`)}</span><i className="ico-check"/>
        </Button></li>)
    }
    if (!readOnlyFields && this.hasPermission(buttonsPermissions.service_complete_pap)) {
      array.push(<li><Button onClick={() => this.props.actions.serviceApproveByRole(pristine, approvalRoleCode.approveServiceCompletePap)} className="" id="service_complete_pap">
        <span>{t(`${tKey}SERVICE_DOSSIER.COMPLETE_PAP`)}</span><i className="ico-check" />
      </Button></li>)
    }
    if (!readOnlyFields && this.hasPermission(buttonsPermissions.service_pap_issue)) {
      array.push(<li><Button onClick={() => this.props.actions.openOrCloseIssueModal()} className="" id="service_pap_issue">
        <span>{t(`${tKey}SERVICE_DOSSIER.CREATE_ISSUE_PAP`)}</span><i className="iDocIcon-warning" />
      </Button></li>)
    }
    if (!readOnlyFields && this.hasPermission(buttonsPermissions.send_to_dms)) {
      array.push(<li><Button onClick={() => this.props.actions.saleSendBproDms(pristine)} className="" id="send_to_dms">
        <span>{t(`${tKey}SEND_TO_DMS`)}</span><i className="iDocIcon-arrow-alt-circle-right-uncollapse" />
      </Button></li>)
    }
    if (!readOnlyFields && this.hasPermission(buttonsPermissions.service_dossier_store) && this.getMainCode()) {
      array.push(<li><Button onClick={() => {
        this.props.actions.storeDossier(dossierId, pristine, reset)
        this.props.actions.trackAction(storeAction)
      }} className="" id="store">
        <span>{t(`${tKey}STORE_DOSSIER`)}</span><i className="iDocIcon-archive" />
      </Button></li>)
    }
    if (!readOnlyFields && this.hasPermission(buttonsPermissions.bt_paperwork_notify) && this.getMainCode()) {
      array.push(<li><Button onClick={() => this.props.actions.paperworkNotify(dossierId)} className="" id="bt_paperwork_notify">
        <span>{t(`${tKey}BT_PAPERWORK_NOTIFY`)}</span><i className="iDocIcon-comments" />
      </Button></li>)
    }
    if (this.hasPermission(buttonsPermissions.view_btn_summary)) {
      array.push(<li><Button
        className="btn-white btn-icon"
        id="print-summary-button"
        onClick={() => this.props.actions.printCompleteSummarySales(dossierId, this.props.historical)}
      >{t(`${tKey}PRINT_SUMMARY`)}<i className="iDocIcon-print-multifunction-printer" />
      </Button></li>)
    }
    if (!readOnlyFields && this.hasPermission(buttonsPermissions.sale_transfer_cancel_request_vo)) {
      array.push(<li><Button onClick={() => this.props.actions.transferReject()}
        className="cancel" id="storeCancel">
        <span>{t(`${tKey}SALE_REJECT`)}</span><i className="ico-cancel" />
      </Button></li>)
    }
    if (!readOnlyFields && this.hasPermission(buttonsPermissions.purchase_transfer_cancel_request_vo)) {
      array.push(<li><Button onClick={() => this.props.actions.transferReject()}
        className="cancel" id="storeCancel">
        <span>{t(`${tKey}PURCHASE_REJECT`)}</span><i className="ico-cancel" />
      </Button></li>)
    }

    if (!readOnlyFields && this.hasPermission(buttonsPermissions.btn_store_delete_any_stage) && this.getMainCode()) {
      array.push(<li><Button onClick={() => this.setState({ showStoreCancelModal: true })}
        className="accent iconGroup" id="storeCancel">
        <span>{t(`${tKey}STORE_CANCEL_DOSSIER`)} <span><i className="iDocIcon-archive" /> / <i className="iDocIcon-delete-forever" /></span></span>
      </Button></li>)
    }
    if (!readOnlyFields && this.hasPermission(buttonsPermissions.sale_cancel) && this.getMainCode()) {
      array.push(<li><Button onClick={() => {
        if (this.state.fields.cancelTab?.permission === null || this.state.fields.cancelTab?.permission < permissions.editable) {
          this.props.actions.saleCancel(dossierId, pristine, reset, null, this.props.reason, this.props.comment)
          this.props.actions.trackAction(cancelAction)
        } else {
          this.setState({ showCancelReasonModal: true })
        }
      }}
      id="cancel" className='cancel'>{t(`${tKey}SALE_CANCEL`)}<i className="iDocIcon-delete-forever" />
      </Button>
      </li>)
    }
    if (!readOnlyFields && this.hasPermission(buttonsPermissions.paperwork_cancel) && this.getMainCode()) {
      array.push(<li>
        <Button onClick={() => {
          if (this.state.fields.cancelTab?.permission === null || this.state.fields.cancelTab?.permission < permissions.editable) {
            this.props.actions.paperworkCancel(dossierId, pristine, reset)
            this.props.actions.trackAction(cancelAction)
          } else {
            this.setState({ showCancelReasonModal: true })
          }
        }}
        id="cancel"
        className="cancel">
          {t(`${tKey}PAPERWORK_CANCEL`)}
          <i className="iDocIcon-delete-forever" />
        </Button>
      </li>)
    }
    if (!readOnlyFields && this.hasPermission(buttonsPermissions.stock_dossier_cancel) && this.getMainCode()) {
      array.push(<li><Button onClick={() => {
        if (this.state.fields.cancelTab?.permission === null || this.state.fields.cancelTab?.permission < permissions.editable) {
          this.props.actions.putCancelDossier(dossierId, pristine, reset)
          this.props.actions.trackAction(cancelAction)
        } else {
          this.setState({ showCancelReasonModal: true })
        }
      }}
      id="cancel"
      className="cancel">
        {t(`${tKey}STOCK_CANCEL`)}
        <i className="iDocIcon-delete-forever" />
      </Button></li>)
    }
    if (!readOnlyFields && this.hasPermission(buttonsPermissions.purchase_cancel) && this.getMainCode()) {
      array.push(<li><Button onClick={() => {
        if (this.state.fields.cancelTab?.permission === null || this.state.fields.cancelTab?.permission < permissions.editable) {
          this.props.actions.purchaseCancel(dossierId, pristine, reset)
          this.props.actions.trackAction(cancelAction)
        } else {
          this.setState({ showCancelReasonModal: true })
        }
      }}
      id="cancel"
      className="cancel" >
        <span>{t(`${tKey}PURCHASE_CANCEL`)}</span><i className="iDocIcon-delete-forever" />
      </Button></li>)
    }
    if (!readOnlyFields && this.hasPermission(buttonsPermissions.service_dossier_cancel) && this.getMainCode()) {
      array.push(<li><Button onClick={() => {
        if (this.state.fields.cancelTab?.permission === null || this.state.fields.cancelTab?.permission < permissions.editable) {
          this.props.actions.serviceDossierCancel(dossierId, pristine, reset)
          this.props.actions.trackAction(cancelAction)
        } else {
          this.setState({ showCancelReasonModal: true })
        }
      }}
      className="cancel" id="cancel">
        <span>{t(`${tKey}SERVICE_DOSSIER.CANCEL`)}</span><i className="iDocIcon-delete-forever" />
      </Button></li>)
    }

    return array
  }

  render () {
    const {
      t,
      dossierId,
      pristine,
      reset,
      dossierType,
      showEconomicPlan = true,
      activeTab,
      // marginTop,
      commentUnreadCount,
      organizedUnitId,
      lowestOrganizedUnitsCombo,
      readOnlyFields,
      cancelCombo,
      type,
      actions: {
        submitForm,
        fetchVoCompaniesCombo,
        submitSelectVoCompany,
        fetchLowestOrganizedUnits,
        getChangeUo,
        getSalesmanChangeUo,
        postUpdateChangeUo,
        fecthOrganizedUnitsByDossierUo,
        putCancelDossier,
        fetchCancelCombo
      },
      combos,
      changeUo,
      trackAction,
      showTimelineInTab
    } = this.props

    const tKey = 'DOSSIER_COMPONENTS.BUTTONS_HEADER.'

    const tooltipTimeLine = (<Tooltip id="tooltipDatosExpediente" className="tooltip-buttons-header"><span>{ t(`${tKey}DOSSIER_DATE`)} Time line</span></Tooltip>)
    const tooltipDatosExpediente = (<Tooltip id="tooltipDatosExpediente" className="tooltip-buttons-header"><span >{t(`${tKey}DOSSIER_DATE`)}</span></Tooltip>)
    const tooltipGestionDocumental = (<Tooltip id="tooltipGestionDocumental" className="tooltip-buttons-header"><span >{t(`${tKey}DOCUMENTARY_MANAGEMENT`)}</span></Tooltip>)
    const tooltipPlanteoEconomico = (<Tooltip id="tooltipPlanteoEconomico" className="tooltip-buttons-header"><span >{t(`${tKey}ECONOMIC_PLAN`)}</span></Tooltip>)
    const tooltipExpedientesRelacionados = (<Tooltip id="tooltipExpedientesRelacionados" className="tooltip-buttons-header"><span >{t(`${tKey}RELATED_DOSSIER`)}</span></Tooltip>)
    const tooltipActividadExpediente = (<Tooltip id="tooltipActividadExpediente" className="tooltip-buttons-header"><span >{t(`${tKey}RECORD_ACTIVITY`)}</span></Tooltip>)
    const tooltipObservaciones = (<Tooltip id="tooltipObservaciones" className="tooltip-buttons-header"><span >{t(`${tKey}COMMENTS`)}</span></Tooltip>)

    let baseTrackingAction = {
      href: window.location.href,
      documentTitle: dossierType + '_page',
      organizedUnitId: organizedUnitId,
      category: dossierType
    }
    // El clic en "Guardar", "Archivar" y "Eliminar" de todos los tipos de expediente diferenciando cada uno.
    let saveAction = { ...baseTrackingAction, action: TrackingActions.SAVE_DOSSIER }
    let cancelAction = { ...baseTrackingAction, action: TrackingActions.CANCEL_DOSSIER }
    let storeAction = { ...baseTrackingAction, action: TrackingActions.STORE_DOSSIER }

    const activeButtons = this.getButtons(t, tKey, reset, pristine, dossierId, readOnlyFields, baseTrackingAction, cancelAction, storeAction)

    return (
      <div className='tabAndActionsContainer'>
        <div className="tabContainer">
          <Tabs
            activeKey={activeTab}
            id="tab-botones-header-box"
            onSelect={this.onSelectButtonHeader.bind(this)}
          >

            {this.isTabVisible(buttonsHeaderPermisions.header) && showTimelineInTab && (
              <Tab eventKey={0} title={<OverlayTrigger placement="bottom" overlay={tooltipTimeLine} id="time_line"><i className="iDocIcon-timeline" /></OverlayTrigger>}></Tab>
            )}
            {this.isTabVisible(buttonsHeaderPermisions.main) && (
              <Tab eventKey={1} title={<OverlayTrigger /* container={this} */ placement="bottom" overlay={tooltipDatosExpediente} id="main"><i className="ico-document-lines" /></OverlayTrigger>}></Tab>
            )}
            {this.isTabVisible(buttonsHeaderPermisions.doc_manager) && (
              <Tab eventKey={2} title={<OverlayTrigger placement="bottom" overlay={tooltipGestionDocumental} id="doc_manager"><i className="ico-docs" /></OverlayTrigger>} ></Tab>
            )}
            {showEconomicPlan && dossierType !== dossierTypes.paperwork && dossierType !== dossierTypes.campaign && this.isTabVisible(buttonsHeaderPermisions.economic_plan) && (
              <Tab eventKey={3} title={<OverlayTrigger placement="bottom" overlay={tooltipPlanteoEconomico} id="economic_plan"><i className={icoCurrencyContent[settings.currency]} /></OverlayTrigger>}></Tab>
            )}
            {this.isTabVisible(buttonsHeaderPermisions.related) && (
              <Tab eventKey={4} title={<OverlayTrigger placement="bottom" overlay={tooltipExpedientesRelacionados} id="related"><i className="ico-relacion" /></OverlayTrigger>}></Tab>
            )}
            {this.isTabVisible(buttonsHeaderPermisions.activity) && (
              <Tab eventKey={5} title={<OverlayTrigger placement="bottom" overlay={tooltipActividadExpediente} id="activity"><i className="ico-actividad-expediente" /></OverlayTrigger>}></Tab>
            )}
            {this.isTabVisible(buttonsHeaderPermisions.comments) && (
              <Tab eventKey={6} title={
                <OverlayTrigger placement="bottom" overlay={tooltipObservaciones} id="comments">
                  <div className='bubble-msg-wrapper'>
                    <i className="ico-bocadillo" />
                    {commentUnreadCount > 0 && (
                      <div className={`bubble-msg${commentUnreadCount > 0 ? '' : ' empty'}`}>
                        <span>{commentUnreadCount || ''}</span>
                      </div>
                    )}
                  </div>
                </OverlayTrigger>}>
              </Tab>
            )}
          </Tabs>
        </div>
        <div className="actionsContainer">
          {/** SALES SAVE */}
          { (!readOnlyFields && ((this.hasPermission(buttonsPermissions.sale_save) && this.getMainCode()) || this.hasPermission(buttonsPermissions.exclusive_sale_save))) &&
            <Button
              onClick={() => {
                submitForm()
                trackAction(saveAction)
              }}
              id="save"
              disabled = { !pristine ? false : !this.hasPermission(buttonsPermissions.force_save) }
              className={'_Btn ' + (pristine ? 'new' : 'accent')}>
              <i className={!pristine ? 'iDocIcon-save' : 'ico-folder-open'} />
              {pristine ? (t(`${tKey}SALE_SAVE`)) : (t(`${tKey}SAVE_CHANGES`))}
            </Button>
          }
          {/** PURCHASE SAVE */}
          { !readOnlyFields && this.hasPermission(buttonsPermissions.purchase_save) && this.getMainCode() &&
            <Button
              onClick={() => {
                submitForm()
                trackAction(saveAction)
              }}
              id="save"
              disabled = { !pristine ? false : !this.hasPermission(buttonsPermissions.force_save) }
              className={'_Btn ' + (pristine ? 'new' : 'accent')}>
              <i className={!pristine ? 'iDocIcon-save' : 'ico-folder-open'} />
              {pristine ? (t(`${tKey}PURCHASE_SAVE`)) : (t(`${tKey}SAVE_CHANGES`))}
            </Button>
          }
          {/** PAPERWORK SAVE */}
          { !readOnlyFields && (this.hasPermission(buttonsPermissions.paperwork_save) && this.getMainCode()) || this.hasPermission(buttonsPermissions.paperwork_partial_save) &&
              <Button onClick={() => {
                submitForm()
                trackAction(saveAction)
              }}
              id="save"
              disabled = { !pristine ? false : !this.hasPermission(buttonsPermissions.force_save) }
              className={'_Btn ' + (pristine ? 'new' : 'accent')}>
                <i className={!pristine ? 'iDocIcon-save' : 'ico-folder-open'} />
                {pristine ? (t(`${tKey}PAPERWORK_SAVE`)) : (t(`${tKey}SAVE_CHANGES`))}
              </Button>
          }
          {/** STOCK SAVE */}
          { !readOnlyFields && this.hasPermission(buttonsPermissions.stock_dossier_save) && this.getMainCode() &&
              <Button onClick={() => {
                submitForm()
                trackAction(saveAction)
              }}
              id="save"
              disabled = { !pristine ? false : !this.hasPermission(buttonsPermissions.force_save) }
              className={'_Btn ' + (pristine ? 'new' : 'accent')}>

                <i className={!pristine ? 'iDocIcon-save' : 'ico-folder-open'} />
                {pristine ? (t(`${tKey}STOCK_SAVE`)) : (t(`${tKey}SAVE_CHANGES`))}
              </Button>

          }
          {/** REG DELIVERY SAVE */}
          { !readOnlyFields && this.hasPermission(buttonsPermissions.reg_delivery_save) && this.getMainCode() &&
            <Button onClick={() => {
              submitForm()
              trackAction(saveAction)
            }}
            id="save"
            disabled = { !pristine ? false : !this.hasPermission(buttonsPermissions.force_save) }
            className={'_Btn ' + (pristine ? 'new' : 'accent')}>
              <i className={!pristine ? 'iDocIcon-save' : 'ico-folder-open'} />
              {pristine ? (t(`${tKey}REGISTRATION_DELIVERY_SAVE`)) : (t(`${tKey}SAVE_CHANGES`))}
            </Button>
          }
          {/** CAMPAIGN SAVE */}
          { !readOnlyFields && this.hasPermission(buttonsPermissions.campaign_save) && this.getMainCode() &&
            <Button onClick={() => {
              submitForm()
              trackAction(saveAction)
            }}
            id="save"
            disabled = { !pristine ? false : !this.hasPermission(buttonsPermissions.force_save) }
            className={'_Btn ' + (pristine ? 'new' : 'accent')}>
              <i className={!pristine ? 'iDocIcon-save' : 'ico-folder-open'} />
              {pristine ? (t(`${tKey}CAMPAIGN_SAVE`)) : (t(`${tKey}SAVE_CHANGES`))}
            </Button>
          }
          {/** SERVICE SAVE */}
          { !readOnlyFields && this.hasPermission(buttonsPermissions.service_dossier_save) &&
              <Button onClick={() => {
                trackAction(saveAction)
                submitForm()
              }}
              id="save"
              disabled={!pristine ? false : !this.hasPermission(buttonsPermissions.force_save)}
              className={'_Btn ' + (pristine ? 'new' : 'accent')}>
                <i className={!pristine ? 'iDocIcon-save' : 'ico-folder-open'} />
                {pristine ? (t(`${tKey}SAVE_DOSSIER`)) : (t(`${tKey}SAVE_CHANGES`))}
              </Button>
          }

          {/** List of active buttons */}
          {activeButtons.length > 0 &&
          <DropdownButton
            as={'ButtonGroup'}
            key={1}
            drop={'start'}
            className={'_Btn accept stk o-icn noLine'}
            title={<i class="iDocIcon-actions-menu-more"></i> }
            id="dropdown-button-drop-start">
            {activeButtons.map((button) => { return button })}
          </DropdownButton>}

        </div>

        {!readOnlyFields && dossierType === dossierTypes.purchase && !this.props.historical &&
          <SelectVoCompany
            t={t}
            showModal={this.state.showSelectVoCompanyModal}
            organizedUnitId={organizedUnitId}
            voCompaniesCombo={combos.voCompaniesCombo}
            dossierId={dossierId}
            actions={{
              closeSelectVoCompanyModal: () => this.setState({ showSelectVoCompanyModal: false }),
              fetchVoCompaniesCombo,
              submitSelectVoCompany
            }}
          />
        }
        { !readOnlyFields && this.hasPermission(buttonsPermissions.changeUO) &&
          <ChangeUoSelectModal
            t={t}
            showModal={this.state.showChangeUoSelectModa}
            dossierId={dossierId}
            organizedUnitId = {organizedUnitId}
            organicedUnits = {lowestOrganizedUnitsCombo}
            dossierType={dossierType}
            changesDossierUo={changeUo ? changeUo.changesDossierUo : null}
            salesmanChange={changeUo ? changeUo.salesmanChange : null}
            actions={{
              closeChangeUoSelectModal: () => this.setState({ showChangeUoSelectModa: false }),
              fetchLowestOrganizedUnits,
              getChangeUo,
              getSalesmanChangeUo,
              postUpdateChangeUo,
              fecthOrganizedUnitsByDossierUo
            }}/>
        }
        { !readOnlyFields && this.hasPermission(buttonsPermissions.btn_store_delete_any_stage) &&
        <StoreCancelModalPage
          showModal={this.state.showStoreCancelModal}
          t={t}
          dossierId={dossierId}
          pristine = {pristine}
          rest={reset}

          closeStoreCancelModal={() => this.setState({ showStoreCancelModal: false })}

        ></StoreCancelModalPage>
        }
        <UpdateDossierPage
          showModal={this.state.showUpdateDossier}
          isNew= {false}
          t={t}
          dossierId={dossierId}
          pristine = {pristine}
          rest={reset}
          ouId={this.props.organizedUnitId}
          closeStoreCancelModal={() => this.setState({ showUpdateDossier: false })}
        ></UpdateDossierPage>
        <CancelReasonModal
          t={t}
          showModal={this.state.showCancelReasonModal}
          combos= {cancelCombo}
          type={type}
          actions={{
            rejectAction: (comment, resolve, reject) => {
              this.setState({ reason: comment.reason })
              this.setState({ comment: comment.comment })
              putCancelDossier(dossierId, pristine, reset, null, comment.reason, comment.comment)
              this.toggleRejectCommentModal(false)
            },
            closeModal: () => {
              this.toggleRejectCommentModal(false)
            },
            fetchCancelCombo: fetchCancelCombo
          }}
        />
      </div>
    )
  }
}

var decoratedButtonHeader = TrackingContainer(ButtonsHeader, false)
export default decoratedButtonHeader
