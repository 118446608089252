import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import AddDossiersToFleetModal from '../../../components/management/AuxModals/AddDossiersToFleetModal'
import { closeAddDossiersToFleetModal, fetchDossiersToAddToFleet, fetchDossiersToAddToFleetSuccess, resetDossiersToAddToFleetSearch, fetchAddDossierToFleet } from '../../../actions/management/auxModals/auxModals'

export function mapStateToProps (state) {
  return {
    ...state.managementAuxModals.addDossiersToFleetModal,
    combos: {
      salesmanCombo: state.combos.salesmanCombo
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      closeAddDossiersToFleetModal,
      fetchDossiersToAddToFleet,
      fetchDossiersToAddToFleetSuccess,
      resetDossiersToAddToFleetSearch,
      fetchAddDossierToFleet
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(AddDossiersToFleetModal))
