import codeCommisionModal from '../../../constants/actions/dossier/common/codeCommisionModal'

export function openCodeCommissionModal (organizedUnits, codeCommissionId) {
  return {
    type: codeCommisionModal.OPEN_CODE_COMMISION_MODAL,
    organizedUnits,
    codeCommissionId
  }
}

export function openCodeCommissionModalSuccess (organizedUnits, codeCommissionCombo, codeCommissionId) {
  return {
    type: codeCommisionModal.OPEN_CODE_COMMISION_MODAL_SUCCESS,
    organizedUnits,
    codeCommissionCombo,
    codeCommissionId
  }
}

export function closeCodeCommissionModal () {
  return {
    type: codeCommisionModal.CLOSE_CODE_COMMISION_MODAL
  }
}

export function fetchCodeCommissionCombo (organizedUnits) {
  return {
    type: codeCommisionModal.FETCH_CODE_COMMISION_COMBO,
    organizedUnits
  }
}

export function fetchCodeCommissionComboSuccess (codeCommissionCombo) {
  return {
    type: codeCommisionModal.FETCH_CODE_COMMISION_COMBO_SUCCESS,
    codeCommissionCombo
  }
}
export default {
  openCodeCommissionModal,
  openCodeCommissionModalSuccess,
  closeCodeCommissionModal,
  fetchCodeCommissionCombo,
  fetchCodeCommissionComboSuccess
}
