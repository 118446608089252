import React, { PureComponent } from 'react'
import { Button, Modal, Col, Table, Tooltip, OverlayTrigger } from 'react-bootstrap'
import { reduxForm } from 'redux-form'
import { formatDate } from '../../../util/utils'
import SearchUserRecurrentEntityModalPage from '../../../containers/admin/recurrentEntity/SearchUserRecurrentEntityModalPage'

class UserRecurrrentEntityModal extends PureComponent {
  constructor () {
    super()
    this.state = {
      orderBy: '+name'
    }
    this.closeModal = this.closeModal.bind(this)
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (this.props.showModal && this.props.showModal === true && prevProps.showModal !== this.props.showModal && this.props.entityId) {
      this.chargeEntityUsers(this.props.entityId)
    }
    return null
  }

  chargeEntityUsers (entityId) {
    this.props.actions.getUserRecurrentServiceEntity(entityId, this.props.orderBy)
  }

  componentDidUpdate () {

  }

  deleteUser (userRecurrentServiceEntityId) {
    new Promise((resolve) => {
      this.props.actions.deleteUserRecurrentServiceEntity(userRecurrentServiceEntityId, resolve)
    }).then(() => {
      this.props.actions.getUserRecurrentServiceEntity(this.props.entityId, this.props.orderBy)
    })
  }

  openAddUserModal () {
    this.props.actions.openCloseAddUserRecurrentServiceModal(true, this.props.entityId, this.props.userList)
  }

  getSortIcon (column = '') {
    switch (this.props.orderBy) {
      case '+' + column:
        return 'ico-sort-selected-asc'
      case '-' + column:
        return 'ico-sort-selected-desc'
      default:
        return 'ico-sort'
    }
  }

  changeOrder (field) {
    switch (this.props.orderBy) {
      case `+${field}`:
        this.props.actions.getUserRecurrentServiceEntity(this.props.entityId, `-${field}`)
        break
      default:
        this.props.actions.getUserRecurrentServiceEntity(this.props.entityId, `+${field}`)

        break
    }
  }

  closeModal () {
    this.props.actions.openCloseUserRecurrentServiceModal(false)
  }

  render () {
    const {
      t, userList, showModal
    } = this.props
    const tKey = 'ADMIN.'
    const deleteTooltip = (<Tooltip id="deleteTooltip">{t(`${tKey}DELETE`)}</Tooltip>)
    return (
      <>
        <SearchUserRecurrentEntityModalPage></SearchUserRecurrentEntityModalPage>

        <Modal className="role-modal languages-modal UserRecurrrentEntity" show={showModal} onHide={this.closeModal} backdrop={'static'}>
          <form autoComplete="off" >
            <Modal.Header closeButton onHide={this.closeModal}>
              <Modal.Title>{t(`${tKey}USER_RECURRENT_SERVICE`)}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Col sm={12} className="col-row  section-users-body">
                <Table className="editable-table admin-users" hover responsive>
                  <thead>
                    <tr>
                      <th onClick={() => { this.changeOrder('name') }}>
                        {t(`${tKey}USERS.NAME_SURNAME`)}&nbsp;
                        <i className={this.getSortIcon('name')} />
                      </th>
                      <th onClick={() => { this.changeOrder('emailaddress') }}>
                        {t(`${tKey}USERS.EMAIL`)}&nbsp;
                        <i className={this.getSortIcon('emailaddress')} />
                      </th>
                      <th onClick={() => { this.changeOrder('creationDate') }}>
                        {t(`${tKey}USERS.CREATION_DATE`)}&nbsp;
                        <i className={this.getSortIcon('creationDate')} />
                      </th>
                      <th style={{ width: '77px' }}>{t('DOSSIER_COMPONENTS.ACCESSORIES.ACTIONS')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userList && userList.map((user, idx) => {
                      return (
                        <tr key={idx}>
                          <td style={{ display: 'none' }}>{user.userId}</td>
                          <td>{user.name} {user.surnames}</td>
                          <td>{user.emailAddress}</td>
                          <td>{formatDate(user.creationDate)}</td>
                          <td className='actions'>
                            <div>
                              <a onClick={() => this.deleteUser(user.userRecurrentServiceEntityId)}>
                                <OverlayTrigger placement="left" overlay={deleteTooltip}>
                                  <i className="ico-trash"/>
                                </OverlayTrigger>
                              </a>
                            </div>
                          </td>
                        </tr>)
                    })
                    }
                    {(userList === null || userList === undefined || userList.length === 0) &&
                  <tr>
                    <td colSpan={12}><h2>{t(`${tKey}USERS.RESULTS_NOT_FOUND`)}</h2></td>
                  </tr>}
                  </tbody>
                </Table>
              </Col>

            </Modal.Body>
            <Modal.Footer>
              <Col sm={12} className="btn-wrapper">
                <Button
                  id="role-modal-submit-button"
                  className="btn-standard"
                  onClick={() => this.openAddUserModal()}>
                  {t('MASTERS.ADD_USER')}
                </Button>
              </Col>
            </Modal.Footer>
          </form>
        </Modal>
      </>
    )
  }
}

export default reduxForm({
  form: 'userRecurrrentEntityModal'
})(UserRecurrrentEntityModal)
