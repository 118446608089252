import React, { PureComponent } from 'react'
import { Button, Modal, Col, Row, FormGroup, Form, ControlLabel } from 'react-bootstrap'
import InputText from '../../../components/commons/form/InputText'
import { reduxForm, Field } from 'redux-form'

class UpdateDossierModal extends PureComponent {
  constructor () {
    super()
    this.state = {
      contract: '',
      okContract: false,
      okUpdate: false,
      toshiko: {}
    }
  }

  componentDidUpdate () {
  }

  changeHandler (event) {
    this.setState({ name: event.target.value })
  }

  showContract (t, tKey, isNew) {
    return (<FormGroup>
      <Row className="inputs-wrapper">
        <Col sm={12} className="custom-width-col">
          <Field
            colSm={0}
            name="contract"
            controlLabel={ isNew ? t(`${tKey}NUM_CONTRACT`) : t(`${tKey}DOSSIER_NOT_CONTRACT`)}
            placeholder={t(`${tKey}NUM_CONTRACT`)}
            component={InputText}
            maxLength={25}
            onChange={ e => { this.setState({ contract: e.target.value }) }}
          />
        </Col>
      </Row>
    </FormGroup>)
  }

  showToshikoData (t, tKey) {
    if (!this.state.okUpdate) {
      return (
        <FormGroup>
          <Row key={1} className="inputs-wrapper">
            { this.state.toshiko.cifNif && (
              <Col sm={4} className="custom-width-col">
                <ControlLabel className="text-bold">{`${t(`${tKey}CIF_NIF`)}:`}</ControlLabel>
                <p>{this.state.toshiko.cifNif}</p>
              </Col>
            )
            }
            { this.state.toshiko.name && (
              <Col sm={8} className="custom-width-col">
                <ControlLabel className="text-bold">{`${t(`${tKey}NAME_LAST_NAME`)}:`}</ControlLabel>
                <p>{this.state.toshiko.name}</p>
              </Col>
            )
            }
          </Row>
          <Row key={2} className="inputs-wrapper">
            { this.state.toshiko.dateOrder && (
              <Col sm={4} className="custom-width-col">
                <ControlLabel className="text-bold">{`${t(`${tKey}DATE_ORDER`)}:`}</ControlLabel>
                <p>{this.state.toshiko.dateOrder}</p>
              </Col>
            )
            }
            { this.state.toshiko.model && (
              <Col sm={4} className="custom-width-col">
                <ControlLabel className="text-bold">{`${t(`${tKey}MODEL`)}:`}</ControlLabel>
                <p>{this.state.toshiko.model}</p>
              </Col>
            )
            }
            { this.state.toshiko.color && (
              <Col sm={4} className="custom-width-col">
                <ControlLabel className="text-bold">{`${t(`${tKey}COLOR`)}:`}</ControlLabel>
                <p>{this.state.toshiko.color}</p>
              </Col>
            )
            }
          </Row>
        </FormGroup>)
    } else if (!this.state.okContract) {
      return (<p>{`${t(`${tKey}CONFIRM_UPDATE_DOSSIER_TOSHIKO`)}`}</p>)
    }
  }

  getTittle (t, tKey) {
    return (!this.state.okContract && !this.state.okUpdate ? (t(`${tKey + 'TITLE'}`))
      : this.state.okContract ? (t(`${tKey + 'UPDATE_DOSSIER_TOSHIKO'}`)) : (t(`${tKey + 'TITLE'}`)))
  }

  handleSubmit () {
    let action = 'create'
    if (!this.props.isNew || this.state.okUpdate) {
      action = (this.state.okContract || this.state.okUpdate) ? 'updateConfirm' : 'update'
    }
    return new Promise((resolve, reject) => {
      this.props.actions.createUpdateDossierToshiko(this.props.dossierId, this.state.contract, this.props.ouId, action, resolve, reject)
    }).then((result) => {
      if (this.props.isNew) {
        this.setState({ okUpdate: true })
      } else {
        this.setState({ toshiko: result, okContract: true })
      }
      if (Boolean(result.reload) === true) {
        window.location.reload()
      }
    })
  }

  render () {
    const {
      t, showModal, isNew
    } = this.props
    const tKey = (isNew) ? 'DOSSIER_COMPONENTS.BUTTONS_HEADER.ADD_DOSSIER_MODAL.' : 'DOSSIER_COMPONENTS.BUTTONS_HEADER.UPDATE_DOSSIER_MODAL.'

    return (
      <Modal className="selectVoCompany-modal" show={showModal} onHide={this.props.closeStoreCancelModal} backdrop={'static'}>
        <Form autoComplete="off" onSubmit={ this.handleSubmit.bind(this) }>
          <Modal.Header closeButton onHide={this.props.closeStoreCancelModal}>
            <Modal.Title>{this.getTittle(t, tKey)}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {!this.state.okContract && !this.state.okUpdate ? (
              this.showContract(t, tKey, isNew)
            ) : (
              this.showToshikoData(t, tKey, this.state.toshiko.cifNif)
            )}
          </Modal.Body>
          <Modal.Footer>
            <Col sm={12} className="btn-wrapper">
              <Button
                onClick={() => this.props.closeStoreCancelModal()}
                id="recurrent-entity-modal-submit-button"
                className="btn-danger"
                type="reset">
                {t(`${tKey + 'CANCEL_BUTTON'}`)}
              </Button>
              <Button
                id="recurrent-entity-modal-submit-button"
                className="btn-standard"
                type="submit">
                {t(`${tKey + 'SUBMIT_BUTTON'}`)}
              </Button>
            </Col>
          </Modal.Footer>
        </Form>
      </Modal>
    )
  }
}

export default reduxForm({
  form: 'updateDossierModal',
  validate: (values, props) => {
    let error = {}
    if (!values.contract) {
      error.contract = (props.isNew) ? props.t('DOSSIER_COMPONENTS.BUTTONS_HEADER.ADD_DOSSIER_MODAL.VALIDATION.NUM_CONTRACT_REQUIRED') : props.t('DOSSIER_COMPONENTS.BUTTONS_HEADER.UPDATE_DOSSIER_MODAL.VALIDATION.NUM_CONTRACT_REQUIRED')
    }
    return Object.keys(error).length > 0 ? error : {}
  }
})(UpdateDossierModal)
