import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import DossiersList from '../../../components/dashboard/dossiersList/DossiersList'
import DossiersList_v2 from '../../../_v2/components/dashboard/dossiersList/DossiersList'
import dossiersListActions from '../../../actions/dashboard/dossiersList/dossiersList'
import { translate } from 'react-polyglot'
import TrackingContainer from '../../../containers/tracking/trackingContainer'
import { getStorageValue } from '../../../storage/storage'
// import { push, goBack } from 'react-router-redux'

export function mapStateToProps (state) {
  return {
    ...state.dashboardDossiersList,
    organizedUnit: state.auth.organizedUnit
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      ...dossiersListActions/* ,
      navigateTo: push,
      goBack */
    }, dispatch)
  }
}

// var decoratedComponent = TrackingContainer(DossiersList)
let version = parseInt(getStorageValue('interfaceVersion'))
var decoratedComponent = null
if (version === 2) {
  decoratedComponent = TrackingContainer(DossiersList_v2, true)
} else {
  decoratedComponent = TrackingContainer(DossiersList, true)
}
export default connect(mapStateToProps, mapDispatchToProps)(translate()(decoratedComponent))
