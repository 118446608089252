import { call, put, select, takeLatest } from 'redux-saga/effects'
import { getAuth } from '../../selectors/access/auth'
import getReasons from '../../services/reason/reason'
import commonActions from '../../actions/common'
import messageModalActions from '../../actions/modals/messageModal'
import { fetchReasonsSuccess, fetchAllReasonsSuccess } from '../../actions/combos/combos'
import combosActionTypes from '../../constants/actions/combos/combos'
import { handleError } from '../errors/errorManager'

export function * fetchReasons ({ dossierSubTypeId }) {
  try {
    yield put(commonActions.showLoader())
    const auth = yield select(getAuth)
    const combo = yield call(getReasons, auth.token, dossierSubTypeId)
    yield put(fetchReasonsSuccess(combo))
  } catch (error) {
    const customHandler = function * () {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.FETCH_COMBO_ERROR'))
    }
    yield call(handleError, { error, customHandler })
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchFetchReasons () {
  yield takeLatest(combosActionTypes.FETCH_REASONS, fetchReasons)
}

export function * fetchAllReasons ({ dossierSubTypeId }) {
  try {
    yield put(commonActions.showLoader())
    const auth = yield select(getAuth)
    const combo = yield call(getReasons, auth.token, dossierSubTypeId, true)
    yield put(fetchAllReasonsSuccess(combo))
  } catch (error) {
    const customHandler = function * () {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.FETCH_COMBO_ERROR'))
    }
    yield call(handleError, { error, customHandler })
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchFetchAllReasons () {
  yield takeLatest(combosActionTypes.FETCH_ALL_REASONS, fetchAllReasons)
}
