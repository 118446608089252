
import React, { PureComponent } from 'react'
import { reduxForm, Field } from 'redux-form'
import { Button, Modal, Col, Panel, Table, Row, Form } from 'react-bootstrap'
import InputCheckBox from '../../commons/form/InputCheckBox'
import InputSelect from '../../commons/form/InputSelect'
import IDocCarPagination from '../../commons/iDocCarPagination'
import { connect } from 'react-redux'

class AuditAdminDocumentModal extends PureComponent {
  constructor () {
    super()
    this.state = {
      openDocumentPanel: true,
      documentType: null,
      documentTypeId: null,
      searchVisible: false,
      selectAll: false,
      checkboxes: {},
      manualCheck: {},
      filters: [],
      entityId: null,
      form: {},
      lastEvent: 0
    }
  }

  componentDidMount () {
    this.props.actions.fetchDocumentTypeEntities()
    this.props.actions.fetchDocumentTypeCombo()
    const { checkboxes } = this.state
    Object.keys(checkboxes).forEach((id) => {
      this.props.initialize({ [`check${id}`]: false })
    })
  }

  componentWillUnmount () { }

  submitSearchForm (values) {
    const list = this.props.combos.documentTypes?.filter(x => x.mode === values.DocumentTypeEntityId && x.id === values.DocumentTypeId)
    if (!list || list.length === 0) {
      values.DocumentTypeId = null
    }
    let filters = values
    let propFilters = this.props.filters
    propFilters.page = 1
    this.props.actions.fetchDocumentTypeUsesInfo({ ...filters, ...propFilters }, this.props.documents.map(document => document.id))
    this.setState({ dynamicFilters: filters, searchVisible: true, selectAll: false, checkboxes: {}, manualCheck: {} })
  }

  fetchTable (propFilters) {
    let filters = this.state.dynamicFilters
    this.props.actions.fetchDocumentTypeUsesInfo({ ...filters, ...propFilters }, this.props.documents.map(document => document.id))
    this.setState({ dynamicFilters: filters, searchVisible: true })
  }

  fieldFill (field) {
    this.setState({ fields: { ...this.state.fields, ...field } })
  }

  resetFilters () {
    this.props.initialize({})
    this.setState({ searchVisible: false, documentType: null, documentTypeId: null, selectAll: false })
  }

  createForm (id) {
    const documentTypeEntity = this.props.items[0]?.documentTypeEntity
    const documentTypeUseItem = this.props.all?.find(x => x.documentTypeUseId?.toString() === id)
    return {
      id: id,
      documentTypeEntity: documentTypeEntity,
      value: documentTypeUseItem?.documentTypeUse
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.showDocuments === false && prevProps.showDocuments === true) {
      this.setState({
        openDocumentPanel: true,
        documentType: null,
        documentTypeId: null,
        searchVisible: false,
        selectAll: false,
        checkboxes: {},
        filters: [],
        entityId: null,
        form: {}
      }, () => {
        this.props.initialize({})
        this.props.reset()
      })
    }

    if (this.props.openModal !== prevProps.openModal && this.props.openModal > prevState.openModal) {
      this.props.onComponentVisible()
    }

    if (
      this.props.formValues !== prevProps.formValues &&
      this.props.formValues &&
      this.props.items?.length > 0
    ) {
      let form = []

      const documentTypeUses = this.props.all.map(document => document.documentTypeUseId)

      if (this.props.formValues.selectedCheck_massive) {
        const ids = documentTypeUses
          ? documentTypeUses
            .filter(id => this.state.manualCheck[id] !== false)
            .map(id => id.toString())
          : []
        for (let id of ids) {
          form.push(this.createForm(id))
        }
      } else {
        Object.keys(this.props.formValues).forEach(key => {
          if (key.startsWith('selectedCheck_')) {
            const id = key.split('_')[1]
            if (this.props.formValues[key]) {
              form.push(this.createForm(id))
            }
          }
        })
      }
      if (this.state.lastEvent === 0 && this.state.selectAll === false) {
        this.props.handleDocumentFormValuesChange([])
      } else {
        this.props.handleDocumentFormValuesChange(form)
      }
    }
  }

  handleSelectAll () {
    const { checkboxes } = this.state
    const selectAll = !this.state.selectAll

    for (let id in checkboxes) {
      this.props.change(`check${id}`, selectAll)
    }

    this.setState({ selectAll })
  }

  onPageChange (page) {
    this.fetchTable({ ...this.props.filters, page })
  }

  onCheckAll (e) {
    const checkboxes = this.props.all.reduce(
      (checkboxes, document) => ({
        ...checkboxes,
        [document.documentTypeUseId]: e
      }),
      {}
    )

    this.setState({
      selectAll: e,
      checkboxes: checkboxes,
      manualCheck: e === false ? [] : checkboxes,
      lastEvent: 0
    })
  }

  onCheckOne (e, id) {
    const { checkboxes, manualCheck } = this.state

    const formProperty = `check${id}`
    if (this.props.change && typeof this.props.change === 'function' && this.props.form && this.props.form.AuditAdminDocumentModal && this.props.form.AuditAdminDocumentModal.values && this.props.form.AuditAdminDocumentModal.values.hasOwnProperty(formProperty)) {
      this.props.change(formProperty, e.target.checked)
    }
    manualCheck[id] = e
    let trueValuesCount = 0

    for (const key in this.state.manualCheck) {
      if (this.state.manualCheck[key] === true) {
        trueValuesCount++
      }
    }
    this.setState({
      checkboxes: { ...checkboxes, [id]: !checkboxes[id] },
      manualCheck: manualCheck,
      selectAll: (this.props.count === trueValuesCount),
      lastEvent: 1
    })
  }

  render () {
    const {
      t, handleSubmit, count, filters: { page, pages }, items,
      combos: {
        documentTypeEntities,
        documentTypes
      }, documents
    } = this.props
    const tKey = 'MANAGEMENT.ADMIN_AUDIT.MODAL.MODAL.'
    return (
      <>
        <Modal.Body>
          <Form autoComplete="off" onSubmit={handleSubmit((values) => this.submitSearchForm(values))} className="filters">
            <Col sm={12}>
              <Field
                id="DocumentTypeEntityId"
                name='DocumentTypeEntityId'
                placeholder={t(`${tKey}DOCUMENT_CATEGORY`)}
                controlLabel={t(`${tKey}DOCUMENT_CATEGORY`)}
                component={InputSelect}
                multi={false}
                valueKey="id"
                labelKey="value"
                childrenKey="children"
                isClearable={true}
                options={documentTypeEntities}
                colSm={6}
                onInputChange={(val) => { this.setState({ entityId: val }); this.fieldFill({ DocumentTypeEntityId: val }) }}
              />
              <Field
                id="DocumentTypeId"
                name='DocumentTypeId'
                placeholder={t(`${tKey}DOCUMENT_TYPE`)}
                controlLabel={t(`${tKey}DOCUMENT_TYPE`)}
                component={InputSelect}
                multi={false}
                valueKey="id"
                labelKey="value"
                childrenKey="children"
                isClearable={true}
                options={(documentTypes?.filter(x => x.mode === this.state.entityId && !documents.some(doc => doc?.value === x?.value)))?.sort((a, b) => a?.value?.localeCompare(b?.value))}
                colSm={6}
                onInputChange={(val) => { this.fieldFill({ DocumentTypeId: val }) }}
              />
            </Col>
            <Row className="form-group flexInLine al-C marg-20">
              <Button
                className="btn-standard btn-wrapper"
                type='submit'>
                {t(`${tKey}SEARCH_BTN`)}
              </Button>
              <Button
                id="role-modal-submit-button"
                className="btn-default"
                onClick={() => this.resetFilters()}>
                {t(`${tKey}DELETE_FILTERS`)}
              </Button>
            </Row>

          </Form>
          <div className="fleet-panel dossier-panel" >
            <div className="header-panel fleet-panel-box" onClick={() => this.setState({ openDocumentPanel: !this.state.openDocumentPanel })}>

              <div className="name-wrapper fleet-panel-box-title">
                <h4>{t(`${tKey}DOCUMENTS`)}  <span className='fleet-panel-subTitle'></span></h4>
              </div>

              <i className={this.state.openDocumentPanel ? 'ico-arrow-black-down fleet-panel-box-caret' : 'ico-arrow-black-up fleet-panel-box-caret'} />

            </div>
            <Panel expanded={this.state.openDocumentPanel} onToggle={() => {}}>
              <Panel.Collapse>
                <div className='col-sm-12'>
                  <Table className="editable-table auditDocsTable" hover responsive>
                    <thead>
                      <tr>
                        <th>
                          {t(`${tKey}DOCUMENT`)}&nbsp;
                        </th>
                        <th>
                          {t(`${tKey}CATEGORY`)}&nbsp;
                        </th>
                        <th>
                          <Field
                            component={InputCheckBox}
                            name={'selectedCheck_massive'}
                            onChange={(e) => this.onCheckAll(e)}
                            checked={this.state.selectAll}
                            disabled={!items || items?.length === 0 || !this.state.searchVisible}
                          />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      { this.state.searchVisible && (
                        <>
                          {
                            items ? (
                              items.map(document => (
                                <tr key={document.documentTypeUseId}>
                                  <td>{document.documentTypeUse}</td>
                                  <td>{document.documentTypeEntity}</td>
                                  <td>
                                    <Field
                                      component={InputCheckBox}
                                      name={'selectedCheck_' + document.documentTypeUseId}
                                      id={'selectedCheck_' + document.documentTypeUseId}
                                      checked={
                                        this.state.manualCheck.hasOwnProperty(document.documentTypeUseId)
                                          ? this.state.manualCheck[document.documentTypeUseId]
                                          : this.state.checkboxes[document.documentTypeUseId] || false
                                      }
                                      onChange={(event) => this.onCheckOne(event, document.documentTypeUseId)} />

                                  </td>

                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="3">No hay documentos disponibles</td>
                              </tr>
                            )
                          }

                        </>
                      ) }
                    </tbody>

                  </Table>
                </div>
                { this.state.searchVisible && (
                  <div className="section-users-footer">
                    <nav key={1} className="text-center">
                      <IDocCarPagination
                        id="btn_pag_sales"
                        page={page}
                        pagesCount={pages}
                        totalRows= {count}
                        onSelectAction={(page) => this.onPageChange(page)}
                      />
                    </nav>
                  </div>
                )}
              </Panel.Collapse>
            </Panel>
          </div>
        </Modal.Body>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    formValues: state.form && state.form.AuditAdminDocumentModal ? state.form.AuditAdminDocumentModal.values : null
  }
}

const validate = (values, { t }) => {
  const tKey = 'DOSSIER_COMPONENTS.VALIDATIONS.'
  const errors = {}

  if (!values.DocumentTypeEntityId) errors.DocumentTypeEntityId = t(`${tKey}MANDATORY_FIELD`)

  return errors
}

export default connect(mapStateToProps)(
  reduxForm({
    form: 'AuditAdminDocumentModal',
    validate,
    shouldValidate: () => true
  })(AuditAdminDocumentModal)
)
