import apiFetch from '../apiFetch'

export default function (token, page, orderBy, pageSize, name, description, isDeleted) {
  const queryParamsArray = [
    page ? `page=${page}` : null,
    pageSize ? `pageSize=${pageSize}` : null,
    orderBy ? `orderBy=${orderBy}` : null,
    name ? `name=${name}` : null,
    description ? `description=${description}` : null,
    isDeleted ? `isDeleted=${isDeleted}` : `isDeleted=${false}`
  ]
  const queryParams = queryParamsArray.reduce(
    (result, query, idx) => result + (query ? (result ? `&${query}` : `?${query}`) : '')
    , ''
  )

  return apiFetch({ endPoint: `FinancingTypeProduct/Find${queryParams}`, method: 'GET', body: null, token: token })
}
