import React, { PureComponent } from 'react'
import { Col, Row, Tooltip, OverlayTrigger, Glyphicon } from 'react-bootstrap'
import { Field } from 'redux-form'
import InputCheckBox from '../../../commons/form/InputCheckBox'
import InputSelect from '../../../commons/form/InputSelect'
import InputText from '../../../commons/form/InputText'
import InputDatePicker from '../../../commons/form/InputDatePicker'
import { clientInformation, entityTypesId, permissions, genericRfc, validationTypes } from '../../../../constants/backendIds'
import { dossierTypes as dossierTypesConstant } from '../../../../constants/dossier/common/dossierTypes'
import { clientTabs } from '../../../../constants/dossier/common/keyTabs'
import setting from '../../../../setting'
import { dossierSubTypeId as dossierSubTypeConstant } from '../../../../constants/dossier/common/dossierSubType'
import {
  onlySpaces, validatePrivateCURPFormat
} from '../../../../util/mxEntityValidationFunctions'
import { customValidations } from '../../../../util/validationFunctions'
import SearchClientByPhoneMailModal from './SearchClientByPhoneMailModal'
import NormalizedAddress from '../client/NormalizedAddress'

class ClientData extends PureComponent {
  constructor (props) {
    super()
    this.state = {
      alert: true,
      openSearchByPhoneMailModal: false,
      allInformationDisabled: true,
      disableCountry: false,
      disableState: false,
      disableCity: false,
      disableTown: false,
      disableColony: false,
      disableCP: false,
      freeLance: false,
      allMaritalStatus: false
    }

    this.handleDNI = this.handleDNI.bind(this)
    this.checkEntityAndFormat = this.checkEntityAndFormat.bind(this)
  }

  dateLessTodayValidation (value, allValues, props) {
    const result = customValidations('LessToday', value, props)
    return result === Object(result) ? undefined : result
  }

  disableEntityAddressCombos () {
    this.setState({
      disableCountry: !!this.props.dossier.entityComponent.stateCode,
      disableState: !!this.props.dossier.entityComponent.cityCode,
      disableCity: !!this.props.dossier.entityComponent.townCode,
      disableTown: !!this.props.dossier.entityComponent.colonyCode,
      disableColony: !!this.props.dossier.entityComponent.postalCodeCode,
      disableCP: !this.props.dossier.entityComponent.colonyCode
    })
  }

  componentDidMount () {
    this.disableEntityAddressCombos()
    if (this.props.actions && typeof this.props.actions?.fetchEntityMaritalStatusCombo === 'function' && this.props.combos?.maritalStatusCombo?.length === 0) {
      this.props.actions?.fetchEntityMaritalStatusCombo()
    }

    if (!this.props.dossier.entityComponent.dniCif) {
      this.setState({ allInformationDisabled: true })
    } else {
      this.setState({ allInformationDisabled: false, dniCif: this.props.dossier.entityComponent.dniCif })
    }

    this.setState({ allMaritalStatus: this.props.dossier?.entityComponent?.maritalStatusId !== null })
  }

  needClearFix (isRecurrentEntityType) {
    if (isRecurrentEntityType && (this.props.dossierType === dossierTypesConstant.sales || this.props.dossierType === dossierTypesConstant.addNewFleetModal || this.props.dossierType === dossierTypesConstant.createNewFleetModal || this.props.dossierType === dossierTypesConstant.dataContactFleetModal)) {
      return true
    }
    return false
  }

  visibleCheck (entityComponent) {
    let bool = true
    if (clientInformation.QUADIS === setting.clientFieldValidation) {
      bool = entityComponent.entityTypeId === entityTypesId.privateEntity
    }
    return (this.props.dossierType === dossierTypesConstant.paperwork && bool && this.props.fieldsConfiguration.differentPaperworkAddress)
  }

  getEntityRfc (value) {
    const rfc = value
    if (value) {
      new Promise((resolve, reject) => {
        return this.props.actions.checkPrivateRfcFormat(value, resolve)
      }).then((confirm) => {
        if (confirm) {
          new Promise((resolve) => {
            this.props.actions.fetchClientByRfc(rfc, this.props.dossier.organizedUnitId, this.props.dossierType, resolve)
          }).then((confirm) => {
            if (this.props.dossier?.entityComponent?.countryCode) {
              this.disableEntityAddressCombos()
            }
          })
        } else {
          this.props.change('entityComponent.rfc', null)
        }
      })
    }
  }

  menuOpenClose (value) {
    this.setState({ openEntityType: value })
  }

  getEntityTypeLabel () {
    const tKey = 'DOSSIER_COMPONENTS.ENTITY.'
    switch (this.props.dossierType) {
      case dossierTypesConstant.sales:
      case dossierTypesConstant.addNewFleetModal:
      case dossierTypesConstant.createNewFleetModal:
      case dossierTypesConstant.dataContactFleetModal:
        return this.props.t(`${tKey}CLIENT_TYPE`)
      case dossierTypesConstant.purchase:
        return this.props.t(`${tKey}VENDOR_TYPE`)
      default:
        switch (this.getSubTypePapeworkRelatedDossier()) {
          case dossierSubTypeConstant.compra_vo:
            return this.props.t(`${tKey}ENTITY_VENDOR`)
          case dossierSubTypeConstant.venta_vo:
            return this.props.t(`${tKey}ENTITY_BUYER`)
          default:
            return this.props.t(`${tKey}ENTITY_TYPE`)
        }
    }
  }

  getSubTypePapeworkRelatedDossier () {
    if (this.props.dossier.dossierRelatedDossiers && this.props.dossier.dossierRelatedDossiers.length) {
      const paperworkRelatedDossier = this.props.dossier.dossierRelatedDossiers[0]
      return paperworkRelatedDossier.dossierSubType
    }
  }

  getNumberLabel () {
    const tKey = 'DOSSIER_COMPONENTS.ENTITY.'
    switch (this.props.dossierType) {
      case dossierTypesConstant.sales:
      case dossierTypesConstant.addNewFleetModal:
      case dossierTypesConstant.createNewFleetModal:
      case dossierTypesConstant.dataContactFleetModal:
        return this.props.t(`${tKey}MAIN_CLIENT_NUMBER`)
      case dossierTypesConstant.purchase:
        return this.props.t(`${tKey}MAIN_VENDOR_NUMBER`)
      default:
        switch (this.getSubTypePapeworkRelatedDossier()) {
          case dossierSubTypeConstant.compra_vo:
            return this.props.t(`${tKey}MAIN_ENTITY_VENDOR_NUMBER`)
          case dossierSubTypeConstant.venta_vo:
            return this.props.t(`${tKey}MAIN_ENTITY_BUYER_NUMBER`)
          default:
            return this.props.t(`${tKey}MAIN_ENTITY_NUMBER`)
        }
    }
  }

  getEntityNameLabel () {
    const tKey = 'DOSSIER_COMPONENTS.ENTITY.'
    switch (this.props.dossier.entityComponent.entityTypeId ? this.props.dossier.entityComponent.entityTypeId : this.props.dossier.entityTypeIdDefault) {
      case entityTypesId.privateEntity:
        return this.props.t(`${tKey}NAME`)
      default:
        return this.props.t(`${tKey}REGISTERED_NAME`)
    }
  }

  showSearchByPhoneMailModal () {
    this.setState({ openSearchByPhoneMailModal: true })
  }

  getEntity (value, passport = false) {
    const dniCif = value
    if (value) {
      new Promise((resolve, reject) => {
        return this.props.actions.fetchClientByDni(dniCif, this.props.dossier.organizedUnitId, this.props.dossierType, resolve, reject)
      }).then((result) => {
        if (this.props.fieldsConfiguration.rfc != null && this.props.fieldsConfiguration.rfc.permission > permissions.hidden) {
          if (this.props.dossier?.entityComponent?.entityTypeId === entityTypesId.businessEntity) {
            this.props.actions.change('entityComponent.rfc', value)
          } else if (this.props.dossier?.entityComponent?.entityTypeId === entityTypesId.privateEntity && passport) {
            this.props.actions.change('entityComponent.rfc', genericRfc.foreignRfc)
            this.setState({ allInformationDisabled: onlySpaces(value), dniCif: value, errorDniCif: null, rfcNoEditable: true })
          }
        }
        if (!result && this.props.fieldsConfiguration.rfc.permission && this.props.fieldsConfiguration.rfc.permission > permissions.hidden) {
          this.props.actions.openCommonModal('clientNotExistsSearchByPhoneMail', () => this.showSearchByPhoneMailModal(), null, null, null, null)
        }
        if (this.props.dossier?.entityComponent?.countryCode) {
          this.disableEntityAddressCombos()
        }
      }).catch(() => {
        if (this.props.fieldsConfiguration.rfc != null && this.props.fieldsConfiguration.rfc.permission > permissions.hidden) {
          if (this.props.dossier?.entityComponent?.entityTypeId === entityTypesId.businessEntity) {
            this.props.actions.change('entityComponent.rfc', value)
          } else if (this.props.dossier?.entityComponent?.entityTypeId === entityTypesId.privateEntity && passport) {
            this.props.actions.change('entityComponent.rfc', genericRfc.foreignRfc)
            this.setState({ allInformationDisabled: onlySpaces(value), dniCif: value, errorDniCif: null, rfcNoEditable: true })
          }
        }
        if (!this.props.dossier.entityComponent.entityId && this.props.fieldsConfiguration.rfc.permission && this.props.fieldsConfiguration.rfc.permission > permissions.hidden) {
          this.props.actions.openCommonModal('clientNotExistsSearchByPhoneMail', () => this.showSearchByPhoneMailModal(), null, null, null, null)
        }
      })
    }
  }

  onEntityTypeIdChanges (entityTypeId) {
    new Promise((resolve, reject) => {
      return this.props.actions.setEntityTypeId(entityTypeId, this.props.dossier.entityComponent.entityTypeId, this.props.dossierType, resolve, reject)
    }).then(() => {
      if (entityTypeId === entityTypesId.recurrentEntity) this.props.actions.fetchEntitySubTypeCombo(entityTypeId)
      if (entityTypeId) {
        this.setState({ dniDisabled: false, allInformationDisabled: true })
      } else {
        this.setState({ dniDisabled: true, allInformationDisabled: true })
      }
      this.props.actions.clearEntityChild(-1, this.props.formValues.dossierSubTypeId)
    })
    this.setState({ allMaritalStatus: false })
  }

  setValidationDisable (disableDniCifValidation, disableRepDniCifValidation, disableChildDniCifValidation, disableChildRepDniCifValidation) {
    if (disableDniCifValidation) {
      var okAction = () => {
        this.props.actions.setEntityValidationDisable(this.props.dossierType, disableDniCifValidation, disableRepDniCifValidation, disableChildDniCifValidation, disableChildRepDniCifValidation)
        this.handleDNI(this.state.dniCif, this.props.dossier?.entityComponent?.entityTypeId, disableDniCifValidation, false, this.state.freeLance)
        this.props.actions.asyncValidate({ entityComponent: { ...this.props.formValues.entityComponent, dniCifValidationDisable: disableDniCifValidation, freeLance: this.state.freeLance } }, this.props.actions.dispatch, this.props, 'dniCif', true)
          .then((result) => {
            this.props.actions.stopAsyncValidation({ ...result })
          })
      }
      var cancelAction = () => { this.props.actions.change('entityComponent.dniCifValidationDisable', false) }
      this.props.actions.openCommonModal('setValidationDisableModal', okAction, cancelAction, null, null, null, null)
    } else {
      this.props.actions.setEntityValidationDisable(this.props.dossierType, disableDniCifValidation, disableRepDniCifValidation, disableChildDniCifValidation, disableChildRepDniCifValidation)
      if (disableDniCifValidation !== null && disableDniCifValidation !== undefined) {
        this.handleDNI(this.state.dniCif, this.props.dossier?.entityComponent?.entityTypeId, disableDniCifValidation, false, this.state.freeLance)
        this.props.actions.asyncValidate({ entityComponent: { ...this.props.formValues.entityComponent, dniCifValidationDisable: disableDniCifValidation, freeLance: this.state.freeLance } }, this.props.actions.dispatch, this.props, 'dniCif', true)
          .then((result) => {
            this.props.actions.stopAsyncValidation({ ...result })
          })
      }
      if (disableRepDniCifValidation !== null && disableRepDniCifValidation !== undefined) {
        this.handleDNI(this.state.representativeDniCif, this.props.dossier?.entityComponent?.entityTypeId, disableRepDniCifValidation, true, false)
        this.props.actions.asyncValidate({ entityComponent: { ...this.props.formValues.entityComponent, representativeDniCifValidationDisable: disableRepDniCifValidation, freeLance: this.state.freeLance } }, this.props.actions.dispatch, this.props, 'representativeDniCif', true).then((result) => {
          this.props.actions.stopAsyncValidation({ ...result })
        })
      }
    }
  }

  handleDNI (dnicif, entityTypeId, disableValidation, representativeValidation = false, freeLance = false) {
    if (disableValidation) {
      if (!representativeValidation) {
        this.setState({ allInformationDisabled: dnicif === '' })
      }
    } else {
      if (representativeValidation) {
        this.setState({ representativeDniCif: dnicif })
      } else {
        let callbackFunction = dnicif === '' ? () => this.props.actions.clearClientFields(entityTypeId, this.props.dossierType) : () => true
        this.setState({ allInformationDisabled: false, dniCif: dnicif }, callbackFunction)
        new Promise((resolve, reject) => {
          if (entityTypeId === entityTypesId.privateEntity) {
            if (freeLance) {
              return this.props.actions.validate(validationTypes.FREELANCE_IDENTIFIER, { DniCif: dnicif }, this.props.dossier.organizedUnitId, resolve, reject, true)
            } else {
              return this.props.actions.validate(validationTypes.INDIVIDUAL_IDENTIFIER, { Dni: dnicif }, this.props.dossier.organizedUnitId, resolve, reject, true)
            }
          } else {
            return this.props.actions.validate(validationTypes.COMPANY_IDENTIFIER, { Cif: dnicif }, this.props.dossier.organizedUnitId, resolve, reject, true)
          }
        }).then(() => {
          this.setState({ allInformationDisabled: dnicif === '', dniCif: dnicif })
        }).catch(() => {})
      }
    }
  }

  checkEntityAndFormat (value) {
    if (value && this.props.fieldsConfiguration.rfc != null && this.props.fieldsConfiguration.rfc.permission > permissions.hidden && this.props.actions.foreignPassportWarning) {
      if (this.props.dossier?.entityComponent?.entityTypeId === entityTypesId.privateEntity && !validatePrivateCURPFormat(value)) {
        new Promise((resolve, reject) => {
          return this.props.actions.foreignPassportWarning(value, resolve)
        }).then(() => {
          this.getEntity(value, true)
        })
      } else {
        this.getEntity(value)
        this.setState({ rfcNoEditable: false })
      }
    } else {
      this.getEntity(value)
      this.setState({ rfcNoEditable: false })
    }
  }

  onRecurrentTypeIdChanges (entitySubTypeId) {
    new Promise((resolve, reject) => {
      return this.props.actions.setEntitySubTypeId(entitySubTypeId, this.props.dossierType, resolve, reject)
    }).then(() => {
      if (entitySubTypeId) this.props.actions.fetchEntityFilterSubTypeCombo(this.props.dossier.entityComponent.entityTypeId || this.props.dossier.entityTypeIdDefault, entitySubTypeId, this.props.dossier.organizedUnitId)
    })
  }

  getTitle () {
    const tKey = 'DOSSIER_COMPONENTS.ENTITY.'
    const infoBlockClient = <Tooltip id="infoBlockClient">{this.props.t('MANAGEMENT.FLEETS_TAB.CLIENT_BLOQUED')}</Tooltip>
    switch (this.props.dossierType) {
      case dossierTypesConstant.sales:
        return this.props.t(`${tKey}CLIENT`)
      case dossierTypesConstant.purchase:
        return this.props.t(`${tKey}VENDOR`)
      case dossierTypesConstant.addNewFleetModal:
        return <div> {this.props.t(`${tKey}CLIENT`)} {this.props.readOnlyFields && <OverlayTrigger placement="right" overlay={infoBlockClient}
          colSm={1}>
          <Glyphicon glyph="info-sign"/>
        </OverlayTrigger>} </div>
      default:
        return this.props.t(`${tKey}PANEL_TITLE`)
    }
  }

  onRecurrentEntityIdChanges (entityId) {
    new Promise((resolve, reject) => {
      return this.props.actions.setEntityId(entityId, this.props.dossierType, resolve, reject)
    }).then(() => {
      if (entityId) this.props.actions.fetchClientFromDocument(entityId, this.props.dossierType)
    })
  }

  validateSchema () {
    const {
      fieldsConfiguration
    } = this.props
    return (
      (fieldsConfiguration.startStreet && fieldsConfiguration.startStreet.permission !== permissions.hidden) ||
      (fieldsConfiguration.enndStreet && fieldsConfiguration.enndStreet.permission !== permissions.hidden) ||
      (fieldsConfiguration.externalNumber && fieldsConfiguration.externalNumber.permission !== permissions.hidden) ||
      (fieldsConfiguration.internalNumber && fieldsConfiguration.internalNumber.permission !== permissions.hidden) ||
      (fieldsConfiguration.countryCode && fieldsConfiguration.countryCode.permission !== permissions.hidden) ||
      (fieldsConfiguration.stateCode && fieldsConfiguration.stateCode.permission !== permissions.hidden) ||
      (fieldsConfiguration.cityCode && fieldsConfiguration.cityCode.permission !== permissions.hidden) ||
      (fieldsConfiguration.townCode && fieldsConfiguration.townCode.permission !== permissions.hidden) ||
      (fieldsConfiguration.colonyCode && fieldsConfiguration.colonyCode.permission !== permissions.hidden) ||
      (fieldsConfiguration.cpCode && fieldsConfiguration.cpCode.permission !== permissions.hidden)
    )
  }

  setFreelance (value) {
    this.setState({ freeLance: value })
    this.handleDNI(this.state.dniCif, this.props.dossier?.entityComponent?.entityTypeId, this.props.dossier?.entityComponent?.dniCifValidationDisable, false, value)
    this.props.actions.asyncValidate({ entityComponent: { ...this.props.formValues.entityComponent, dniCifValidationDisable: this.props.dossier?.entityComponent?.dniCifValidationDisable, freeLance: value } }, this.props.actions.dispatch, this.props, 'dniCif', true).then((result) => {
      this.props.actions.stopAsyncValidation({ ...result })
    })
  }

  shortSchema () {
    const {
      t, readOnlyFields, fieldsConfiguration, disabledEntityType, openEntityType, isRecurrentEntityType, isParticularEntityType, disabledByNotSelectedEntityTypeId, entityChangeable, rfcNoEditable,
      dossier: {
        entityComponent, entityTypeIdDefault
      },
      combos: { entityTypeCombo, channelCombo, maritalStatusCombo, segmentationCombo, entitySubTypeCombo, entityFilterSubTypeCombo, iaeTypeCombo, CSTCombo }
    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.ENTITY.'
    return (
      <Row>
        {fieldsConfiguration.entityTypeId && fieldsConfiguration.entityTypeId.permission !== permissions.hidden && (
          <Field
            colSm={4}
            id="entityTypeId"
            name="entityComponent.entityTypeId"
            controlLabel={this.getEntityTypeLabel()}
            placeholder={this.getEntityTypeLabel()}
            component={InputSelect}
            options={entityTypeCombo}
            onInputChange={this.onEntityTypeIdChanges.bind(this)}
            valueKey="id"
            labelKey="value"
            isClearable={false}
            disabled={ readOnlyFields || disabledEntityType || fieldsConfiguration.entityTypeId.permission !== permissions.editable}
            defaultValueEntityType={entityTypeIdDefault}
            menuIsOpen= {openEntityType}
            onMenuOpen = {(value) => this.menuOpenClose(value)}
            onMenuClose = {(value) => this.menuOpenClose(value)}
          />
        )}
        { isRecurrentEntityType && ([
          fieldsConfiguration.entitySubTypeId && fieldsConfiguration.entitySubTypeId.permission !== permissions.hidden && (
            <Field
              colSm={4}
              id="entitySubTypeId"
              name="entityComponent.entitySubTypeId"
              key="entityComponent.entitySubTypeId"
              controlLabel={t(`${tKey}TYPE`)}
              placeholder={t(`${tKey}TYPE`)}
              component={InputSelect}
              onInputChange={this.onRecurrentTypeIdChanges.bind(this)}
              options={entitySubTypeCombo}
              valueKey="id"
              labelKey="value"
              disabled={readOnlyFields || fieldsConfiguration.entitySubTypeId.permission !== permissions.editable || disabledByNotSelectedEntityTypeId || !entityChangeable}
            />
          ),
          fieldsConfiguration.entityId && fieldsConfiguration.entityId.permission !== permissions.hidden && (
            <Field
              colSm={4}
              id="entityId"
              name="entityComponent.entityId"
              key="entityComponent.entityId"
              controlLabel={this.getTitle()}
              placeholder={this.getTitle()}
              component={InputSelect}
              onInputChange={this.onRecurrentEntityIdChanges.bind(this)}
              options={entityFilterSubTypeCombo}
              valueKey="id"
              labelKey="value"
              disabled={readOnlyFields || !entityComponent.entitySubTypeId || fieldsConfiguration.entityId.permission !== permissions.editable || !entityChangeable}
            />
          )
        ])}
        {fieldsConfiguration.dniCif && fieldsConfiguration.dniCif.permission !== permissions.hidden && (
          <Field
            id="dniCif"
            name="entityComponent.dniCif"
            disabled={(!isRecurrentEntityType
              ? readOnlyFields || !entityChangeable || fieldsConfiguration.dniCif.permission !== permissions.editable || disabledByNotSelectedEntityTypeId
              : true) || entityComponent.entityId || this.state.dniDisabled}
            colSm={4}
            controlLabel={t(`${tKey}DNI`)}
            placeholder={t(`${tKey}DNI`)}
            component={InputText}
            onInputBlur={event => this.checkEntityAndFormat(event)}
            onInputChange={value =>
              this.handleDNI(
                value,
                this.props.dossier?.entityComponent?.entityTypeId || entityTypeIdDefault,
                this.props.dossier?.entityComponent?.dniCifValidationDisable,
                false,
                this.state.freeLance)}
            maxLength={20}
          />
        )}
        { fieldsConfiguration.commercialSocietyTypeId && fieldsConfiguration.commercialSocietyTypeId.permission !== permissions.hidden &&
                      (entityComponent.entityTypeId === entityTypesId.businessEntity || entityComponent.entityTypeId === entityTypesId.recurrentEntity) && (
          <Field
            colSm={4}
            id="commercialSocietyTypeId"
            name="entityComponent.commercialSocietyTypeId"
            key="entityComponent.commercialSocietyTypeId"
            controlLabel={t('ADMIN.RECURRENT_ENTITIES.COMMERCIAL_SOCIETY')}
            placeholder={t('ADMIN.RECURRENT_ENTITIES.COMMERCIAL_SOCIETY')}
            component={InputSelect}
            valueKey="id"
            labelKey="value"
            options={CSTCombo}
            disabled={readOnlyFields || this.state.allInformationDisabled || fieldsConfiguration.commercialSocietyTypeId.permission !== permissions.editable || entityComponent.entityTypeId === entityTypesId.recurrentEntity}
          />
        )}
        {!isRecurrentEntityType && ([
          fieldsConfiguration.clientNumber && fieldsConfiguration.clientNumber.permission !== permissions.hidden && (
            <Field
              id="clientNumber"
              name="entityComponent.clientNumber"
              key="entityComponent.clientNumber"
              colSm={4}
              controlLabel={this.getNumberLabel()}
              placeholder={this.getNumberLabel()}
              component={InputText}
              disabled={readOnlyFields || this.state.allInformationDisabled || fieldsConfiguration.clientNumber.permission !== permissions.editable}
              forceControlLabelInOneLine={true}
            />
          )
        ])}
        {(entityComponent.entityTypeId === entityTypesId.privateEntity || entityComponent.entityTypeId === entityTypesId.businessEntity) &&
                fieldsConfiguration.name && fieldsConfiguration.name.permission !== permissions.hidden && (
          <Field
            id="name"
            name="entityComponent.name"
            colSm={4}
            controlLabel={this.getEntityNameLabel()}
            placeholder={this.getEntityNameLabel()}
            component={InputText}
            disabled={readOnlyFields || this.state.allInformationDisabled || fieldsConfiguration.name.permission !== permissions.editable}
          />
        )}
        {entityComponent.entityTypeId === entityTypesId.privateEntity && ([
          fieldsConfiguration.surname1 && fieldsConfiguration.surname1.permission !== permissions.hidden && (
            <Field
              id="surname1"
              name="entityComponent.surname1"
              key="entityComponent.surname1"
              colSm={4}
              controlLabel={t(`${tKey}SURNAME1`)}
              placeholder={t(`${tKey}SURNAME1`)}
              component={InputText}
              disabled={readOnlyFields || this.state.allInformationDisabled || fieldsConfiguration.surname1.permission !== permissions.editable}
            />
          ),
          fieldsConfiguration.surname2 && fieldsConfiguration.surname2.permission !== permissions.hidden && (
            <Field
              id="surname2"
              name="entityComponent.surname2"
              key="entityComponent.surname2"
              colSm={4}
              controlLabel={t(`${tKey}SURNAME2`)}
              placeholder={t(`${tKey}SURNAME2`)}
              component={InputText}
              disabled={readOnlyFields || this.state.allInformationDisabled || fieldsConfiguration.surname2.permission !== permissions.editable}
            />
          )
        ])}
        {entityComponent.entityTypeId === entityTypesId.businessEntity && ([
          fieldsConfiguration.representativeDni && fieldsConfiguration.representativeDni.permission !== permissions.hidden && (
            <Field
              id="representativeDni"
              name="entityComponent.representativeDni"
              key="entityComponent.representativeDni"
              colSm={4}
              controlLabel={t(`${tKey}REPRESENTATIVE_DNI`)}
              placeholder={t(`${tKey}REPRESENTATIVE_DNI`)}
              component={InputText}
              disabled={readOnlyFields || this.state.allInformationDisabled || fieldsConfiguration.representativeDni.permission !== permissions.editable}
              onInputChange={(value) =>
                this.handleDNI(
                  value,
                  this.props.dossier?.entityComponent?.entityTypeId || entityTypeIdDefault,
                  this.props.dossier?.entityComponent?.representativeDniCifValidationDisable,
                  true)
              }
            />
          ),
          fieldsConfiguration.representativeName && fieldsConfiguration.representativeName.permission !== permissions.hidden && (
            <Field
              id="representativeName"
              name="entityComponent.representativeName"
              key="entityComponent.representativeName"
              colSm={4}
              controlLabel={t(`${tKey}REPRESENTATIVE_NAME`)}
              placeholder={t(`${tKey}REPRESENTATIVE_NAME`)}
              component={InputText}
              disabled={readOnlyFields || this.state.allInformationDisabled || fieldsConfiguration.representativeName.permission !== permissions.editable}
            />
          ),
          fieldsConfiguration.representativeSurname1 && fieldsConfiguration.representativeSurname1.permission !== permissions.hidden && (
            <Field
              id="representativeSurname1"
              name="entityComponent.representativeSurname1"
              key="entityComponent.representativeSurname1"
              colSm={4}
              controlLabel={t(`${tKey}REPRESENTATIVE_SURNAME1`)}
              placeholder={t(`${tKey}REPRESENTATIVE_SURNAME1`)}
              component={InputText}
              disabled={readOnlyFields || this.state.allInformationDisabled || fieldsConfiguration.representativeSurname1.permission !== permissions.editable}
              forceControlLabelInOneLine={true}
            />
          ),
          fieldsConfiguration.representativeSurname2 && fieldsConfiguration.representativeSurname2.permission !== permissions.hidden && (
            <Field
              id="representativeSurname2"
              name="entityComponent.representativeSurname2"
              key="entityComponent.representativeSurname2"
              colSm={4}
              controlLabel={t(`${tKey}REPRESENTATIVE_SURNAME2`)}
              placeholder={t(`${tKey}REPRESENTATIVE_SURNAME2`)}
              component={InputText}
              disabled={readOnlyFields || this.state.allInformationDisabled || fieldsConfiguration.representativeSurname2.permission !== permissions.editable}
              forceControlLabelInOneLine={true}
            />
          )
        ])}
        {
          isParticularEntityType &&
          fieldsConfiguration.maritalcmb && fieldsConfiguration.maritalcmb.permission !== permissions.hidden && (
            <Field
              colSm={4}
              id="maritalStatusId"
              name="entityComponent.maritalStatusId"
              key="entityComponent.maritalStatusId"
              controlLabel={t(`${tKey}MARITAL_STATUS`)}
              placeholder={t(`${tKey}MARITAL_STATUS`)}
              component={InputSelect}
              options={this.state.allMaritalStatus ? maritalStatusCombo : maritalStatusCombo?.filter(x => x.description === 'True')}
              valueKey="id"
              labelKey="value"
              disabled={readOnlyFields || this.state.allInformationDisabled || fieldsConfiguration.maritalcmb.permission !== permissions.editable}
            />
          )}
        {!isRecurrentEntityType && ([
          fieldsConfiguration.address && fieldsConfiguration.address.permission !== permissions.hidden && (
            <Field
              id="entityAddress"
              name="entityComponent.address"
              key="entityComponent.address"
              colSm={4}
              controlLabel={t(`${tKey}ADDRESS`)}
              placeholder={t(`${tKey}ADDRESS`)}
              component={InputText}
              disabled={readOnlyFields || this.state.allInformationDisabled || fieldsConfiguration.address.permission !== permissions.editable}
            />
          ),
          fieldsConfiguration.city && fieldsConfiguration.city.permission !== permissions.hidden && (
            <Field
              id="city"
              name="entityComponent.city"
              key="entityComponent.city"
              colSm={4}
              controlLabel={t(`${tKey}CITY`)}
              placeholder={t(`${tKey}CITY`)}
              component={InputText}
              disabled={readOnlyFields || this.state.allInformationDisabled || fieldsConfiguration.city.permission !== permissions.editable}
            />
          ),
          fieldsConfiguration.state && fieldsConfiguration.state.permission !== permissions.hidden && (
            <Field
              id="state"
              name="entityComponent.state"
              key="entityComponent.state"
              colSm={4}
              controlLabel={t(`${tKey}STATE`)}
              placeholder={t(`${tKey}STATE`)}
              component={InputText}
              disabled={readOnlyFields || this.state.allInformationDisabled || fieldsConfiguration.state.permission !== permissions.editable}
            />
          ),
          fieldsConfiguration.cp && fieldsConfiguration.cp.permission !== permissions.hidden && (
            <Field
              id="cp"
              name="entityComponent.cp"
              key="entityComponent.cp"
              colSm={4}
              controlLabel={t(`${tKey}CP`)}
              placeholder={t(`${tKey}CP`)}
              component={InputText}
              disabled={readOnlyFields || this.state.allInformationDisabled || fieldsConfiguration.cp.permission !== permissions.editable}
            />
          ),
          fieldsConfiguration.country && fieldsConfiguration.country.permission !== permissions.hidden && (
            <Field
              id="country"
              name="entityComponent.country"
              key="entityComponent.country"
              colSm={4}
              controlLabel={t(`${tKey}COUNTRY`)}
              placeholder={t(`${tKey}COUNTRY`)}
              component={InputText}
              disabled={readOnlyFields || this.state.allInformationDisabled || fieldsConfiguration.country.permission !== permissions.editable}
            />
          ),
          fieldsConfiguration.phone && fieldsConfiguration.phone.permission !== permissions.hidden && (
            <Field
              id="phone"
              name="entityComponent.phone"
              key="entityComponent.phone"
              colSm={4}
              controlLabel={t(`${tKey}PHONE`)}
              placeholder={t(`${tKey}PHONE`)}
              component={InputText}
              disabled={readOnlyFields || this.state.allInformationDisabled || fieldsConfiguration.phone.permission !== permissions.editable}
            />
          ),
          fieldsConfiguration.mobilePhone && fieldsConfiguration.mobilePhone.permission !== permissions.hidden && (
            <Field
              id="mobilePhone"
              name="entityComponent.mobilePhone"
              key="entityComponent.mobilePhone"
              colSm={4}
              controlLabel={t(`${tKey}MOBILE`)}
              placeholder={t(`${tKey}MOBILE`)}
              component={InputText}
              disabled={readOnlyFields || this.state.allInformationDisabled || fieldsConfiguration.mobilePhone.permission !== permissions.editable}
            />
          ),
          fieldsConfiguration.email && fieldsConfiguration.email.permission !== permissions.hidden && (
            <Field
              id="email"
              name="entityComponent.email"
              key="entityComponent.email"
              colSm={4}
              controlLabel={t(`${tKey}EMAIL`)}
              placeholder={t(`${tKey}EMAIL`)}
              component={InputText}
              disabled={readOnlyFields || this.state.allInformationDisabled || fieldsConfiguration.phone.permission !== permissions.editable}
            />
          ),
          fieldsConfiguration.sourceChannelId && fieldsConfiguration.sourceChannelId.permission !== permissions.hidden && (
            <Field
              colSm={4}
              id="sourceChannelId"
              name="entityComponent.sourceChannelId"
              key="entityComponent.sourceChannelId"
              controlLabel={t(`${tKey}SOURCE_CHANNEL`)}
              placeholder={t(`${tKey}SOURCE_CHANNEL`)}
              component={InputSelect}
              options={channelCombo}
              valueKey="id"
              labelKey="value"
              disabled={readOnlyFields || this.state.allInformationDisabled || fieldsConfiguration.sourceChannelId.permission !== permissions.editable}
              customClass="hidden"
            />
          ),
          fieldsConfiguration.segmentationId && fieldsConfiguration.segmentationId.permission !== permissions.hidden && (
            <Field
              colSm={4}
              id="segmentation"
              name="entityComponent.segmentationId"
              key="entityComponent.segmentationId"
              controlLabel={t(`${tKey}SEGMENTATION`)}
              placeholder={t(`${tKey}SEGMENTATION`)}
              component={InputSelect}
              options={segmentationCombo}
              valueKey="id"
              labelKey="value"
              disabled={readOnlyFields || this.state.allInformationDisabled || fieldsConfiguration.segmentationId.permission !== permissions.editable}
              customClass="hidden"
            />
          ),
          fieldsConfiguration.iaeTypeId && fieldsConfiguration.iaeTypeId.permission !== permissions.hidden && entityComponent.entityTypeId === entityTypesId.businessEntity && (
            <Field
              colSm={4}
              id="iaeTypeId"
              name="entityComponent.iaeTypeId"
              key="entityComponent.iaeTypeId"
              controlLabel={t(`${tKey}IAE_TYPE`)}
              placeholder={t(`${tKey}IAE_TYPE`)}
              component={InputSelect}
              options={iaeTypeCombo}
              valueKey="id"
              labelKey="value"
              disabled={readOnlyFields || this.state.allInformationDisabled || fieldsConfiguration.iaeTypeId.permission !== permissions.editable}
            />
          )
        ])}

        {this.needClearFix(isRecurrentEntityType) && (
          <div key="clearfix_differentPaperworkAddress" className="clearfix" />
        )}
        { this.visibleCheck(entityComponent) &&
                fieldsConfiguration.differentPaperworkAddress.permission !== permissions.hidden ? (
            <div className="address-diferent">
              <Field
                colSm={4}
                id="differentPaperworkAddress"
                name="dossierPaperworkComponent.dossierPaperworkAddressComponent.differentPaperworkAddress"
                component={InputCheckBox}
                placeholder={t(`${tKey}PAPERWORK_ADDRESS_DIFFERENT`)}
                disabled={readOnlyFields || this.state.allInformationDisabled || fieldsConfiguration.differentPaperworkAddress.permission !== permissions.editable}
                onInputChange={(value) => this.setState({ isDifferentPaperworkAddress: value, tabActiveKey: clientTabs.entity })}
              />
            </div>
          ) : null}
        {fieldsConfiguration.rfc && fieldsConfiguration.rfc.permission !== permissions.hidden && (
          <Field
            id="rfc"
            name="entityComponent.rfc"
            disabled={(!isRecurrentEntityType
              ? readOnlyFields || !entityChangeable || fieldsConfiguration.rfc.permission !== permissions.editable || disabledByNotSelectedEntityTypeId
              : true) || rfcNoEditable || entityComponent.entityTypeId !== entityTypesId.privateEntity}
            colSm={4}
            controlLabel={t(`${tKey}RFC`)}
            placeholder={t(`${tKey}RFC`)}
            component={InputText}
            onInputBlur={event => this.getEntityRfc(event)}
          />
        )}
      </Row>
    )
  }

  longSchema () {
    const {
      t, readOnlyFields, fieldsConfiguration, disabledEntityType, openEntityType, isRecurrentEntityType, disabledByNotSelectedEntityTypeId, entityChangeable, rfcNoEditable,
      dossier: {
        entityComponent, entityTypeIdDefault
      },
      combos: { entityTypeCombo, channelCombo, segmentationCombo, entitySubTypeCombo, entityFilterSubTypeCombo, iaeTypeCombo, CSTCombo, activityBproCombo },
      actions: {
        fetchEntityCountryTypeCombo,
        fetchEntityStateTypeCombo,
        fetchEntityCityTypeCombo,
        fetchEntityTownTypeCombo,
        fetchEntityColonyTypeCombo,
        fetchEntityCPTypeCombo,
        fetchClientDataAddress
      }
    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.ENTITY.'
    return (
      <Row className='flexRow'>
        {fieldsConfiguration.entityTypeId && fieldsConfiguration.entityTypeId.permission !== permissions.hidden && (
          <Field
            colSm={4}
            id="entityTypeId"
            name="entityComponent.entityTypeId"
            controlLabel={this.getEntityTypeLabel()}
            placeholder={this.getEntityTypeLabel()}
            component={InputSelect}
            options={entityTypeCombo}
            onInputChange={this.onEntityTypeIdChanges.bind(this)}
            valueKey="id"
            labelKey="value"
            isClearable={false}
            disabled={readOnlyFields || disabledEntityType || fieldsConfiguration.entityTypeId.permission !== permissions.editable}
            defaultValueEntityType={entityTypeIdDefault}
            menuIsOpen= {openEntityType}
            onMenuOpen = {(value) => this.menuOpenClose(value)}
            onMenuClose = {(value) => this.menuOpenClose(value)}
          />
        )}
        { isRecurrentEntityType && ([
          fieldsConfiguration.entitySubTypeId && fieldsConfiguration.entitySubTypeId.permission !== permissions.hidden && (
            <Field
              colSm={4}
              id="entitySubTypeId"
              name="entityComponent.entitySubTypeId"
              key="entityComponent.entitySubTypeId"
              controlLabel={t(`${tKey}TYPE`)}
              placeholder={t(`${tKey}TYPE`)}
              component={InputSelect}
              onInputChange={this.onRecurrentTypeIdChanges.bind(this)}
              options={entitySubTypeCombo}
              valueKey="id"
              labelKey="value"
              disabled={readOnlyFields || fieldsConfiguration.entitySubTypeId.permission !== permissions.editable || disabledByNotSelectedEntityTypeId || !entityChangeable}
            />
          ),
          fieldsConfiguration.entityId && fieldsConfiguration.entityId.permission !== permissions.hidden && (
            <Field
              colSm={4}
              id="entityId"
              name="entityComponent.entityId"
              key="entityComponent.entityId"
              controlLabel={this.getTitle()}
              placeholder={this.getTitle()}
              component={InputSelect}
              onInputChange={this.onRecurrentEntityIdChanges.bind(this)}
              options={entityFilterSubTypeCombo}
              valueKey="id"
              labelKey="value"
              disabled={readOnlyFields || !entityComponent.entitySubTypeId || fieldsConfiguration.entityId.permission !== permissions.editable || !entityChangeable}
            />
          )
        ])}
        {fieldsConfiguration.dniCif && fieldsConfiguration.dniCif.permission !== permissions.hidden && (
          <Field
            id="dniCif"
            name="entityComponent.dniCif"
            disabled={(!isRecurrentEntityType
              ? (readOnlyFields || !entityChangeable || fieldsConfiguration.dniCif.permission !== permissions.editable || disabledByNotSelectedEntityTypeId)
              : true) || entityComponent.entityId || this.state.dniDisabled}
            colSm={4}
            controlLabel={t(`${tKey}DNI`)}
            placeholder={t(`${tKey}DNI`)}
            component={InputText}
            onInputBlur={event => this.checkEntityAndFormat(event)}
            onInputChange={value =>
              this.handleDNI(
                value,
                this.props.dossier?.entityComponent?.entityTypeId || entityTypeIdDefault,
                this.props.dossier?.entityComponent?.dniCifValidationDisable,
                false,
                this.state.freeLance)}
            maxLength={20}
          />
        )}
        {!isRecurrentEntityType && ([
          fieldsConfiguration.clientNumber && fieldsConfiguration.clientNumber.permission !== permissions.hidden && (
            <Field
              id="clientNumber"
              name="entityComponent.clientNumber"
              key="entityComponent.clientNumber"
              colSm={4}
              controlLabel={this.getNumberLabel()}
              placeholder={this.getNumberLabel()}
              component={InputText}
              disabled={readOnlyFields || this.state.allInformationDisabled || fieldsConfiguration.clientNumber.permission !== permissions.editable}
              forceControlLabelInOneLine={true}
            />
          )
        ])}
        {(entityComponent.entityTypeId === entityTypesId.privateEntity || entityComponent.entityTypeId === entityTypesId.businessEntity) &&
                fieldsConfiguration.name && fieldsConfiguration.name.permission !== permissions.hidden && (
          <Field
            id="name"
            name="entityComponent.name"
            colSm={4}
            controlLabel={this.getEntityNameLabel()}
            placeholder={this.getEntityNameLabel()}
            component={InputText}
            disabled={readOnlyFields || this.state.allInformationDisabled || fieldsConfiguration.name.permission !== permissions.editable}
          />
        )}
        {entityComponent.entityTypeId === entityTypesId.privateEntity && ([
          fieldsConfiguration.surname1 && fieldsConfiguration.surname1.permission !== permissions.hidden && (
            <Field
              id="surname1"
              name="entityComponent.surname1"
              key="entityComponent.surname1"
              colSm={4}
              controlLabel={t(`${tKey}SURNAME1`)}
              placeholder={t(`${tKey}SURNAME1`)}
              component={InputText}
              disabled={readOnlyFields || this.state.allInformationDisabled || fieldsConfiguration.surname1.permission !== permissions.editable}
            />
          ),
          fieldsConfiguration.surname2 && fieldsConfiguration.surname2.permission !== permissions.hidden && (
            <Field
              id="surname2"
              name="entityComponent.surname2"
              key="entityComponent.surname2"
              colSm={4}
              controlLabel={t(`${tKey}SURNAME2`)}
              placeholder={t(`${tKey}SURNAME2`)}
              component={InputText}
              disabled={readOnlyFields || this.state.allInformationDisabled || fieldsConfiguration.surname2.permission !== permissions.editable}
            />
          )
        ])}
        {entityComponent.entityTypeId === entityTypesId.businessEntity && ([
          fieldsConfiguration.representativeDni && fieldsConfiguration.representativeDni.permission !== permissions.hidden && (
            <Field
              id="representativeDni"
              name="entityComponent.representativeDni"
              key="entityComponent.representativeDni"
              colSm={4}
              controlLabel={t(`${tKey}REPRESENTATIVE_DNI`)}
              placeholder={t(`${tKey}REPRESENTATIVE_DNI`)}
              component={InputText}
              disabled={readOnlyFields || this.state.allInformationDisabled || fieldsConfiguration.representativeDni.permission !== permissions.editable}
              onInputChange={(value) =>
                this.handleDNI(
                  value,
                  this.props.dossier?.entityComponent?.entityTypeId || entityTypeIdDefault,
                  this.props.dossier?.entityComponent?.representativeDniCifValidationDisable,
                  true)
              }
            />
          ),
          fieldsConfiguration.representativeName && fieldsConfiguration.representativeName.permission !== permissions.hidden && (
            <Field
              id="representativeName"
              name="entityComponent.representativeName"
              key="entityComponent.representativeName"
              colSm={4}
              controlLabel={t(`${tKey}REPRESENTATIVE_NAME`)}
              placeholder={t(`${tKey}REPRESENTATIVE_NAME`)}
              component={InputText}
              disabled={readOnlyFields || this.state.allInformationDisabled || fieldsConfiguration.representativeName.permission !== permissions.editable}
            />
          ),
          fieldsConfiguration.representativeSurname1 && fieldsConfiguration.representativeSurname1.permission !== permissions.hidden && (
            <Field
              id="representativeSurname1"
              name="entityComponent.representativeSurname1"
              key="entityComponent.representativeSurname1"
              colSm={4}
              controlLabel={t(`${tKey}REPRESENTATIVE_SURNAME1`)}
              placeholder={t(`${tKey}REPRESENTATIVE_SURNAME1`)}
              component={InputText}
              disabled={readOnlyFields || this.state.allInformationDisabled || fieldsConfiguration.representativeSurname1.permission !== permissions.editable}
              forceControlLabelInOneLine={true}
            />
          ),
          fieldsConfiguration.representativeSurname2 && fieldsConfiguration.representativeSurname2.permission !== permissions.hidden && (
            <Field
              id="representativeSurname2"
              name="entityComponent.representativeSurname2"
              key="entityComponent.representativeSurname2"
              colSm={4}
              controlLabel={t(`${tKey}REPRESENTATIVE_SURNAME2`)}
              placeholder={t(`${tKey}REPRESENTATIVE_SURNAME2`)}
              component={InputText}
              disabled={readOnlyFields || this.state.allInformationDisabled || fieldsConfiguration.representativeSurname2.permission !== permissions.editable}
              forceControlLabelInOneLine={true}
            />
          )
        ])}
        {!isRecurrentEntityType && ([
          fieldsConfiguration.phone && fieldsConfiguration.phone.permission !== permissions.hidden && (
            <Field
              id="phone"
              name="entityComponent.phone"
              key="entityComponent.phone"
              colSm={4}
              controlLabel={t(`${tKey}PHONE`)}
              placeholder={t(`${tKey}PHONE`)}
              component={InputText}
              disabled={readOnlyFields || this.state.allInformationDisabled || fieldsConfiguration.phone.permission !== permissions.editable}
            />
          ),
          fieldsConfiguration.mobilePhone && fieldsConfiguration.mobilePhone.permission !== permissions.hidden && (
            <Field
              id="mobilePhone"
              name="entityComponent.mobilePhone"
              key="entityComponent.mobilePhone"
              colSm={4}
              controlLabel={t(`${tKey}MOBILE`)}
              placeholder={t(`${tKey}MOBILE`)}
              component={InputText}
              disabled={readOnlyFields || this.state.allInformationDisabled || fieldsConfiguration.mobilePhone.permission !== permissions.editable}
            />
          ),
          fieldsConfiguration.email && fieldsConfiguration.email.permission !== permissions.hidden && (
            <Field
              id="email"
              name="entityComponent.email"
              key="entityComponent.email"
              colSm={4}
              controlLabel={t(`${tKey}EMAIL`)}
              placeholder={t(`${tKey}EMAIL`)}
              component={InputText}
              disabled={readOnlyFields || this.state.allInformationDisabled || fieldsConfiguration.phone.permission !== permissions.editable}
            />
          ),
          <NormalizedAddress
            t={t}
            fieldsConfiguration={fieldsConfiguration}
            readOnlyFields={readOnlyFields}
            allInformationDisabled={this.state.allInformationDisabled}
            entityComponent= {entityComponent}
            entityTypeName= "entityComponent"
            actions={{
              fetchEntityCountryTypeCombo,
              fetchEntityStateTypeCombo,
              fetchEntityCityTypeCombo,
              fetchEntityTownTypeCombo,
              fetchEntityColonyTypeCombo,
              fetchEntityCPTypeCombo,
              fetchClientDataAddress
            }}
          />,
          fieldsConfiguration.address && fieldsConfiguration.address.permission !== permissions.hidden && (
            <Field
              id="entityAddress"
              name="entityComponent.address"
              key="entityComponent.address"
              colSm={8}
              controlLabel={t(`${tKey}ADDRESS`)}
              placeholder={t(`${tKey}ADDRESS`)}
              component={InputText}
              disabled={readOnlyFields || this.state.allInformationDisabled || fieldsConfiguration.address.permission !== permissions.editable}
            />
          ),
          fieldsConfiguration.externalNumber && fieldsConfiguration.externalNumber.permission !== permissions.hidden && (
            <Field
              id="externalNumber"
              name="entityComponent.externalNumber"
              key="entityComponent.externalNumber"
              colSm={4}
              controlLabel={t(`${tKey}EXTERNAL_NUMBER`)}
              placeholder={t(`${tKey}EXTERNAL_NUMBER`)}
              disabled={readOnlyFields || this.state.allInformationDisabled || fieldsConfiguration.externalNumber.permission !== permissions.editable}
              component={InputText}
              maxLength="20"
            />
          ),
          fieldsConfiguration.startStreet && fieldsConfiguration.startStreet.permission !== permissions.hidden && (
            <Field
              id="startStreet"
              name="entityComponent.startStreet"
              key="entityComponent.startStreet"
              colSm={8}
              controlLabel={t(`${tKey}START_STREET`)}
              placeholder={t(`${tKey}START_STREET`)}
              disabled={readOnlyFields || this.state.allInformationDisabled || fieldsConfiguration.startStreet.permission !== permissions.editable}
              component={InputText}
              maxLength="200"
            />
          ),
          fieldsConfiguration.internalNumber && fieldsConfiguration.internalNumber.permission !== permissions.hidden && (
            <Field
              id="internalNumber"
              name="entityComponent.internalNumber"
              key="entityComponent.internalNumber"
              colSm={4}
              controlLabel={t(`${tKey}INTERNAL_NUMBER`)}
              placeholder={t(`${tKey}INTERNAL_NUMBER`)}
              disabled={readOnlyFields || this.state.allInformationDisabled || fieldsConfiguration.internalNumber.permission !== permissions.editable}
              component={InputText}
              maxLength="20"
            />
          ),
          fieldsConfiguration.enndStreet && fieldsConfiguration.enndStreet.permission !== permissions.hidden && (
            <Field
              id="enndStreet"
              name="entityComponent.enndStreet"
              key="entityComponent.enndStreet"
              colSm={8}
              controlLabel={t(`${tKey}ENND_STREET`)}
              placeholder={t(`${tKey}ENND_STREET`)}
              disabled={readOnlyFields || this.state.allInformationDisabled || fieldsConfiguration.enndStreet.permission !== permissions.editable}
              component={InputText}
              maxLength="200"
            />
          ),
          fieldsConfiguration.sourceChannelId && fieldsConfiguration.sourceChannelId.permission !== permissions.hidden && (
            <Field
              colSm={4}
              id="sourceChannelId"
              name="entityComponent.sourceChannelId"
              key="entityComponent.sourceChannelId"
              controlLabel={t(`${tKey}SOURCE_CHANNEL`)}
              placeholder={t(`${tKey}SOURCE_CHANNEL`)}
              component={InputSelect}
              options={channelCombo}
              valueKey="id"
              labelKey="value"
              disabled={readOnlyFields || this.state.allInformationDisabled || fieldsConfiguration.sourceChannelId.permission !== permissions.editable}
              customClass="hidden"
            />
          ),
          fieldsConfiguration.segmentationId && fieldsConfiguration.segmentationId.permission !== permissions.hidden && (
            <Field
              colSm={4}
              id="segmentation"
              name="entityComponent.segmentationId"
              key="entityComponent.segmentationId"
              controlLabel={t(`${tKey}SEGMENTATION`)}
              placeholder={t(`${tKey}SEGMENTATION`)}
              component={InputSelect}
              options={segmentationCombo}
              valueKey="id"
              labelKey="value"
              disabled={readOnlyFields || this.state.allInformationDisabled || fieldsConfiguration.segmentationId.permission !== permissions.editable}
              customClass="hidden"
            />
          ),
          fieldsConfiguration.iaeTypeId && fieldsConfiguration.iaeTypeId.permission !== permissions.hidden && entityComponent.entityTypeId === entityTypesId.businessEntity && (
            <Field
              colSm={4}
              id="iaeTypeId"
              name="entityComponent.iaeTypeId"
              key="entityComponent.iaeTypeId"
              controlLabel={t(`${tKey}IAE_TYPE`)}
              placeholder={t(`${tKey}IAE_TYPE`)}
              component={InputSelect}
              options={iaeTypeCombo}
              valueKey="id"
              labelKey="value"
              disabled={readOnlyFields || this.state.allInformationDisabled || fieldsConfiguration.iaeTypeId.permission !== permissions.editable}
            />
          )
        ])}

        {this.needClearFix(isRecurrentEntityType) && (
          <div key="clearfix_differentPaperworkAddress" className="clearfix" />
        )}
        { this.visibleCheck(entityComponent) &&
                fieldsConfiguration.differentPaperworkAddress.permission !== permissions.hidden ? (
            <div className="address-diferent">
              <Field
                colSm={4}
                id="differentPaperworkAddress"
                name="dossierPaperworkComponent.dossierPaperworkAddressComponent.differentPaperworkAddress"
                component={InputCheckBox}
                placeholder={t(`${tKey}PAPERWORK_ADDRESS_DIFFERENT`)}
                disabled={readOnlyFields || this.state.allInformationDisabled || fieldsConfiguration.differentPaperworkAddress.permission !== permissions.editable}
                onInputChange={(value) => this.setState({ isDifferentPaperworkAddress: value, tabActiveKey: clientTabs.entity })}
              />
            </div>
          ) : null}
        {fieldsConfiguration.rfc && fieldsConfiguration.rfc.permission !== permissions.hidden && (
          <Field
            id="rfc"
            name="entityComponent.rfc"
            disabled={(!isRecurrentEntityType
              ? (readOnlyFields || !entityChangeable || fieldsConfiguration.rfc.permission !== permissions.editable || disabledByNotSelectedEntityTypeId)
              : true) || rfcNoEditable || entityComponent.entityTypeId !== entityTypesId.privateEntity}
            colSm={4}
            controlLabel={t(`${tKey}RFC`)}
            placeholder={t(`${tKey}RFC`)}
            component={InputText}
            onInputBlur={event => this.getEntityRfc(event)}
          />
        )}
        { fieldsConfiguration.commercialSocietyTypeId && fieldsConfiguration.commercialSocietyTypeId.permission !== permissions.hidden &&
                      (entityComponent.entityTypeId === entityTypesId.businessEntity || entityComponent.entityTypeId === entityTypesId.recurrentEntity) && (
          <Field
            colSm={4}
            id="commercialSocietyTypeId"
            name="entityComponent.commercialSocietyTypeId"
            key="entityComponent.commercialSocietyTypeId"
            controlLabel={t('ADMIN.RECURRENT_ENTITIES.COMMERCIAL_SOCIETY')}
            placeholder={t('ADMIN.RECURRENT_ENTITIES.COMMERCIAL_SOCIETY')}
            component={InputSelect}
            valueKey="id"
            labelKey="value"
            options={CSTCombo}
            disabled={(readOnlyFields || this.state.allInformationDisabled || fieldsConfiguration.commercialSocietyTypeId.permission !== permissions.editable) || entityComponent.entityTypeId === entityTypesId.recurrentEntity}
          />
        )}
        {fieldsConfiguration.activityBproId && fieldsConfiguration.activityBproId.permission !== permissions.hidden && (
          <Field
            colSm={12}
            id="activityBproId"
            name="entityComponent.activityBproId"
            key="entityComponent.activityBproId"
            controlLabel={t(`${tKey}ACTIVITY_BPRO`)}
            placeholder={t(`${tKey}ACTIVITY_BPRO`)}
            component={InputSelect}
            options={activityBproCombo}
            valueKey="id"
            labelKey="value"
            disabled={readOnlyFields || this.state.allInformationDisabled || fieldsConfiguration.activityBproId.permission !== permissions.editable || entityComponent.entityTypeId === entityTypesId.recurrentEntity}
          />
        )}
        {fieldsConfiguration.birthdayDate && fieldsConfiguration.birthdayDate.permission !== permissions.hidden && (entityComponent.entityTypeId === entityTypesId.privateEntity || entityComponent.entityTypeId === entityTypesId.recurrentEntity) && (
          <Field
            colSm={4}
            id="birthdayDate"
            name="entityComponent.birthdayDate"
            key="entityComponent.birthdayDate"
            controlLabel={t(`${tKey}BIRTHDAY_DATE`)}
            placeholder={t(`${tKey}BIRTHDAY_DATE`)}
            component={InputDatePicker}
            validate={[this.dateLessTodayValidation]}
            disabled={readOnlyFields || this.state.allInformationDisabled || fieldsConfiguration.birthdayDate.permission !== permissions.editable || entityComponent.entityTypeId === entityTypesId.recurrentEntity}
          />
        )}
        {fieldsConfiguration.constitutionAct && fieldsConfiguration.constitutionAct.permission !== permissions.hidden && (entityComponent.entityTypeId === entityTypesId.businessEntity || entityComponent.entityTypeId === entityTypesId.recurrentEntity) && (
          <Field
            id="constitutionAct"
            name="entityComponent.constitutionAct"
            key="entityComponent.constitutionAct"
            colSm={8}
            controlLabel={t(`${tKey}CONSTITUTION_ACT`)}
            placeholder={t(`${tKey}CONSTITUTION_ACT`)}
            maxLength={50}
            disabled={readOnlyFields || this.state.allInformationDisabled || fieldsConfiguration.constitutionAct.permission !== permissions.editable || entityComponent.entityTypeId === entityTypesId.recurrentEntity}
            component={InputText}
          />
        )}
        {fieldsConfiguration.constitutionDate && fieldsConfiguration.constitutionDate.permission !== permissions.hidden && (entityComponent.entityTypeId === entityTypesId.businessEntity || entityComponent.entityTypeId === entityTypesId.recurrentEntity) && (
          <Field
            colSm={4}
            id="constitutionDate"
            name="entityComponent.constitutionDate"
            key="entityComponent.constitutionDate"
            controlLabel={t(`${tKey}CONSTITUTION_DATE`)}
            placeholder={t(`${tKey}CONSTITUTION_DATE`)}
            component={InputDatePicker}
            validate={[this.dateLessTodayValidation]}
            disabled={readOnlyFields || this.state.allInformationDisabled || fieldsConfiguration.constitutionDate.permission !== permissions.editable || entityComponent.entityTypeId === entityTypesId.recurrentEntity}
          />
        )}

      </Row>
    )
  }

  render () {
    const {
      t, readOnlyFields, fieldsConfiguration, isRecurrentEntityType,
      dossier: {
        entityComponent
      }
    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.ENTITY.'
    return (
      <React.Fragment>
        <SearchClientByPhoneMailModal
          t={this.props.t}
          showModal = {this.state.openSearchByPhoneMailModal}
          organizationUnitId ={this.props.dossier.organizedUnitId}
          dossierType={this.props.dossierType}
          actions = {{
            closeModal: () => {
              this.setState({ openSearchByPhoneMailModal: false })
              this.disableEntityAddressCombos()
            },
            fetchClientByPhoneEmail: this.props.actions.fetchClientByPhoneEmail,
            fetchClientByDni: this.props.actions.fetchClientByDni,
            openCommonModal: this.props.actions.openCommonModal

          }}
        />
        <div>
          {
            fieldsConfiguration.dniCifValidationDisable && fieldsConfiguration.dniCifValidationDisable.permission !== permissions.hidden &&
                    entityComponent && entityComponent.entityTypeId && !isRecurrentEntityType &&
                    (
                      <Col sm={4}>
                        <Field
                          colSm={0}
                          id="dniCifValidationDisable"
                          name="entityComponent.dniCifValidationDisable"
                          placeholder={t(`${tKey}DISABLE_DNI_CIF_VALIDATION`)}
                          component={InputCheckBox}
                          disabled={readOnlyFields || fieldsConfiguration.dniCifValidationDisable.permission !== permissions.editable || (entityComponent.entityId !== null && entityComponent.entityId !== undefined)}
                          onInputChange={(value) => {
                            this.setValidationDisable(value, null, null, null)
                          }}
                          inline
                        />
                      </Col>
                    )
          }
          {
            fieldsConfiguration.representativeDniCifValidationDisable && fieldsConfiguration.representativeDniCifValidationDisable.permission !== permissions.hidden &&
                    entityComponent && entityComponent.entityTypeId === entityTypesId.businessEntity &&
                    (
                      <Col sm={4}>
                        <Field
                          colSm={0}
                          id="representativeDniCifValidationDisable"
                          name="entityComponent.representativeDniCifValidationDisable"
                          placeholder={t(`${tKey}DISABLE_REPR_DNI_CIF_VALIDATION`)}
                          component={InputCheckBox}
                          disabled={readOnlyFields || fieldsConfiguration.representativeDniCifValidationDisable.permission !== permissions.editable}
                          onInputChange={(value) => {
                            this.setValidationDisable(null, value, null, null)
                          }}
                          inline
                        />
                      </Col>
                    )
          }
          { fieldsConfiguration.freeLance && fieldsConfiguration.freeLance.permission !== permissions.hidden && entityComponent.entityTypeId === entityTypesId.privateEntity &&
          (<Col sm={4}>
            <Field
              colSm={0}
              id="freeLance"
              name="entityComponent.freeLance"
              component={InputCheckBox}
              placeholder={t(`${tKey}FREELANCE`)}
              disabled={readOnlyFields || fieldsConfiguration.freeLance.permission !== permissions.editable || (entityComponent.entityId !== null && entityComponent.entityId !== undefined)}
              onInputChange={(value) => this.setFreelance(value)}
            />
          </Col>)
          }
          { !this.validateSchema() ? (
            this.shortSchema()
          ) : (
            this.longSchema()
          ) }
        </div>
      </React.Fragment>
    )
  }
}

export default ClientData
