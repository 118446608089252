import React, { PureComponent } from 'react'
import { Modal, Button, Table, Checkbox, OverlayTrigger, Tooltip, DropdownButton, MenuItem } from 'react-bootstrap'
import { Field } from 'redux-form'
import { translateDocumentStatus, documentStatusClass } from '../../../../constants/dossier/common/documentStatus'
import InputCheckbox from '../../../commons/form/InputCheckBox'
import { documentEntityType as documentEntityTypeConstants } from '../../../../constants/dossier/common/documentEntityType'
import { dossierTypes as dossierTypesConstant } from '../../../../constants/dossier/common/dossierTypes'
import { entityTypesId as entityTypes } from '../../../../constants/backendIds'
import DocumentaryGroupDocumentsCreateModalPage from '../../../../containers/dossiers/common/DocumentaryGroupDocumentsCreateModalPage'

class DocumentaryGroupDocumentsModal extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      documentsIds: [],
      documentsSelected: [],
      documentList: [],
      invalidDocuments: [],
      groupsDocumentCreate: false,
      allSelected: false,
      order: {
        columnName: null,
        direction: 'asc'
      }
    }
    this.handleOpenDocumentCreationModal = this.handleOpenDocumentCreationModal.bind(this)
    this.openCreatorModal = this.openCreatorModal.bind(this)
  }

  componentDidUpdate () {
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (prevProps.showModal !== this.props.showModal && this.props.showModal) {
      this.setState({
        documentsSelected: [],
        allSelected: false,
        documentList: this.props.documents,
        documentsIds: this.props.documents.map((d) => { return d.documentId })
      })
    }
    return this.props
  }

  sendRequest () {
    if (this.state.invalidDocuments?.length > 0) {
      this.props.actions.openModal('missingDocumentsAlert', () => this.setState({ groupsDocumentCreate: true }))
    } else {
      this.setState({ groupsDocumentCreate: true })
    }
  }

  orderTable (columnName) {
    let documents = this.state.documentList.sort(function (a, b) {
      if (columnName === 'validationDate') {
        const fechaInicial = a.validationDate ? a.validationDate.split(' ') : ['-']
        const separarA = fechaInicial.map((dia) => dia[0] === '-' ? ('01,01,2000,00,00') : (fechaInicial[0].split('/').join(',') + ',' + fechaInicial[1].split(':').join(',')))
        const fechaA = separarA.map((datos) => datos.split(','))
        const fechaFinal = b.validationDate ? b.validationDate.split(' ') : ['-']
        const separarB = fechaFinal.map((dia) => dia[0] === '-' ? ('01,01,2000,00,00') : (fechaFinal[0].split('/').join(',') + ',' + fechaFinal[1].split(':').join(',')))
        const fechaB = separarB.map((datos) => datos.split(','))
        const fecha1 = new Date(fechaA[0][2], fechaA[0][1], fechaA[0][0], fechaA[0][3], fechaA[0][4])
        const fecha2 = new Date(fechaB[0][2], fechaB[0][1], fechaB[0][0], fechaB[0][3], fechaB[0][4])
        return fecha2 - fecha1
      } else if (columnName === 'incorporationDate') {
        const fechaInicial = a.incorporationDate ? a.incorporationDate.split(' ') : ['-']
        const separarA = fechaInicial.map((dia) => dia[0] === '-' ? ('01,01,2000,00,00') : (fechaInicial[0].split('/').join(',') + ',' + fechaInicial[1].split(':').join(',')))
        const fechaA = separarA.map((datos) => datos.split(','))
        const fechaFinal = b.incorporationDate ? b.incorporationDate.split(' ') : ['-']
        const separarB = fechaFinal.map((dia) => dia[0] === '-' ? ('01,01,2000,00,00') : (fechaFinal[0].split('/').join(',') + ',' + fechaFinal[1].split(':').join(',')))
        const fechaB = separarB.map((datos) => datos.split(','))
        const fecha1 = new Date(fechaA[0][2], fechaA[0][1], fechaA[0][0], fechaA[0][3], fechaA[0][4])
        const fecha2 = new Date(fechaB[0][2], fechaB[0][1], fechaB[0][0], fechaB[0][3], fechaB[0][4])
        return fecha2 - fecha1
      } else if (a[columnName] && a[columnName].localeCompare) {
        return a[columnName].localeCompare(b[columnName])
      } else {
        return (isNaN(a[columnName]) ? 0 : a[columnName]) - (isNaN(b[columnName]) ? 0 : b[columnName])
      }
    })
    if (this.state.order.direction === 'asc') {
      documents.reverse()
    }
    this.setState({ documentList: documents })
  }

  changeDirection (columnName) {
    this.setState({
      order: {
        columnName: columnName,
        direction: this.state.order.direction === 'asc' ? 'desc' : 'asc'
      }
    }, this.orderTable(columnName))
  }

  changeOrderIcon (name) {
    let clase = 'ico-sort'
    if (this.state.order.direction === 'asc' && this.state.order.columnName === name) {
      clase += '-selected-asc'
    } else if (this.state.order.direction === 'desc' && this.state.order.columnName === name) {
      clase += '-selected-desc'
    }
    return clase
  }

  selectAllDocuments () {
    if (this.state.allSelected) {
      this.setState({ documentsSelected: [], allSelected: false, invalidDocuments: [] })
    } else {
      const invalidDocs = this.state.documentList.filter(x => this.state.documentsIds.includes(x.documentId)).filter(doc => documentStatusClass[translateDocumentStatus[doc.documentStatus]] !== documentStatusClass.VALIDATED && documentStatusClass.PENDING !== documentStatusClass[translateDocumentStatus[doc.documentStatus]])
      this.setState({ documentsSelected: this.state.documentsIds, allSelected: true, invalidDocuments: invalidDocs })
    }
  }

  addDocumentIdToArray (documentId) {
    if (!this.state.documentsSelected.includes(documentId)) {
      const documentsSelected = [...this.state.documentsSelected, documentId]
      const invalidDocs = this.state.documentList.filter(x => documentsSelected.includes(x.documentId)).filter(doc => documentStatusClass[translateDocumentStatus[doc.documentStatus]] !== documentStatusClass.VALIDATED && documentStatusClass.PENDING !== documentStatusClass[translateDocumentStatus[doc.documentStatus]])
      const invalidDocuments = documentsSelected.filter(x => invalidDocs.map(y => y.documentId).includes(x))
      this.setState({ documentsSelected: documentsSelected, invalidDocuments: invalidDocuments })
      if (this.state.documentsIds.length === (this.state.documentsSelected.length + 1)) {
        this.setState({ allSelected: true })
      }
    } else {
      const documentsSelected = this.state.documentsSelected.filter(d => d !== documentId)
      const invalidDocs = this.state.documentList.filter(x => documentsSelected.includes(x.documentId)).filter(doc => documentStatusClass[translateDocumentStatus[doc.documentStatus]] !== documentStatusClass.VALIDATED && documentStatusClass.PENDING !== documentStatusClass[translateDocumentStatus[doc.documentStatus]])
      const invalidDocuments = documentsSelected.filter(x => invalidDocs.map(y => y.documentId).includes(x))
      this.setState({ documentsSelected: documentsSelected, invalidDocuments: invalidDocuments })
      if (this.state.allSelected) {
        this.setState({ allSelected: false })
      }
    }
  }

  handleOpenDocumentCreationModal (documentEntityType) {
    this.openCreatorModal(documentEntityType)
  }

  getEntityTag () {
    switch (this.props.dossierType) {
      case dossierTypesConstant.sales:
        return 'CLIENT'
      case dossierTypesConstant.purchase:
        return 'VENDOR'
      default:
        return 'ENTITY'
    }
  }

  openCreatorModal (documentEntityType) {
    if (documentEntityType === documentEntityTypeConstants.DOSSIER ||
      (this.props.entityId && documentEntityType === documentEntityTypeConstants.ENTITY) ||
      (this.props.entityProviderId && documentEntityType === documentEntityTypeConstants.ENTITY_PROVIDER) ||
      (this.props.vehicleId && documentEntityType === documentEntityTypeConstants.VEHICLE)) {
      if (this.props.dossier &&
        !this.props.permissionRecurrentEntity &&
        ((documentEntityType === documentEntityTypeConstants.ENTITY &&
         this.props.dossier.entityComponent &&
         this.props.dossier.entityComponent.entityTypeId &&
         this.props.dossier.entityComponent.entityTypeId === entityTypes.recurrentEntity) ||
         (documentEntityType === documentEntityTypeConstants.ENTITY_PROVIDER &&
          this.props.dossier.entityProviderComponent &&
          this.props.dossier.entityProviderComponent.entityTypeId &&
          this.props.dossier.entityProviderComponent.entityTypeId === entityTypes.recurrentEntity))) {
        this.props.actions.openModal('entityAlertNoPermission')
      } else {
        this.props.actions.openDocumentCreationModal(this.props.dossierId, documentEntityType, this.props.dossier.organizedUnitId, this.props.entityId, this.props.vehicleId, () => this.props.actions.refreshDocumentManagementModal(this.props.dossier.dossierId), true, this.props.dossierType, this.props.formState, false, this.props.entityProviderId)
      }
    } else {
      const alert = documentEntityType === documentEntityTypeConstants.ENTITY ||
            documentEntityType === documentEntityTypeConstants.ENTITY_PROVIDER ? 'entityAlert' : 'vehicleAlert'
      this.props.actions.openModal(alert)
    }
  }

  render () {
    let {
      t, showModal, closeModal, organizedUnitId
    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.DOCUMENTARY_MANAGEMENT.'

    return (
      <Modal show={showModal} className="massive-download-modal documentaryGroup" onHide={() => closeModal()} backdrop={'static'}>
        <DocumentaryGroupDocumentsCreateModalPage
          showModal={this.state.groupsDocumentCreate}
          documentsSelected={this.state.documentsSelected.map(x => x)}
          invalidDocuments={this.state.invalidDocuments.map(x => x)}
          closeModal={() => this.setState({ groupsDocumentCreate: false })}
          closeParentModal={() => this.props.closeModal()}
          dossierId={this.props.dossierId}
          organizedUnitId={organizedUnitId}></DocumentaryGroupDocumentsCreateModalPage>
        <Modal.Body>
          <h4>{t(`${tKey}GROUP_DOCUMENTS_MODAL.SUBTITLE`)}</h4>
          <Table hover responsive className="simple-table massive-download-table">
            <thead>
              <tr>
                <th onClick={() => [this.changeDirection('documentStatus')]}><span>{t(`${tKey + 'TABLE.STATUS'}`)}<i className={(() => [this.changeOrderIcon('documentStatus')])()}/></span></th>
                <th onClick={() => [this.changeDirection('documentName')]}><span>{t(`${tKey + 'TABLE.TYPES'}`)}<i className={(() => [this.changeOrderIcon('documentName')])()}/></span></th>
                <th className='lastColumn'><span>{t(`${tKey}GROUP_DOCUMENTS_MODAL.SELECT_ALL`)}<Checkbox
                  onChange={() => this.selectAllDocuments()}
                  checked={this.state.allSelected}>

                </Checkbox></span></th>
              </tr>
            </thead>
            <tbody>
              {
                this.state.documentList && this.state.documentList.map((doc, idx) => {
                  return (<tr key={idx}>
                    <td className="text-left">
                      <span>
                        <OverlayTrigger placement="right" overlay={(<Tooltip id="statusTooltip">{doc.documentStatus && t(`${tKey}${translateDocumentStatus[doc.documentStatus]}`)}</Tooltip>)}>
                          <i id="documentStatus" className={'ico-document-' + documentStatusClass[translateDocumentStatus[doc.documentStatus]]}/>
                        </OverlayTrigger>
                      </span>
                    </td>
                    <td>{doc.documentName}</td>
                    <td className='lastColumn'>
                      {
                        <Field
                          colSm={12}
                          component={InputCheckbox}
                          name={'downloadCheck' + doc.documentId}
                          inline
                          customClass="checkbox-inline-search"
                          onChange={() => this.addDocumentIdToArray(doc.documentId)}
                          checked= {this.state.documentsSelected.includes(doc.documentId)}
                        />
                      }
                    </td>
                  </tr>)
                })
              }
            </tbody>
          </Table>
          <DropdownButton
            title={t(`${tKey}ADD_DOCUMENT`)}
            key={1}
            id="anadir_documento"
            className='addDocument'
          >
            <MenuItem className='itemMenu' eventKey="3">
              <Button className="btn-standard" onClick={() => this.handleOpenDocumentCreationModal(documentEntityTypeConstants.DOSSIER)}>
                {t(`${tKey}TO_DOSSIER`)}
              </Button>
            </MenuItem>
          </DropdownButton>
        </Modal.Body>

        <Modal.Footer>
          <Button className="btn-save" onClick={() => this.sendRequest()} disabled={this.state.documentsSelected.length === 0 || this.state.invalidDocuments.length === this.state.documentsSelected.length}>{t(`${tKey + 'GROUP_DOCUMENTS_MODAL.GROUP'}`)}</Button>
          <Button className="btn-cancel" onClick={() => closeModal()}>{t(`${tKey + 'GROUP_DOCUMENTS_MODAL.CANCEL'}`)}</Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default DocumentaryGroupDocumentsModal
