export default {
  NOT_CHECKLIST_PASSED: 'NOT_CHECKLIST_PASSED',
  UO_SUBTYPE_STAGE_NULL: 'UO_SUBTYPE_STAGE_NULL',
  NOT_STAGE_PAPERWORK_PTE_DOC_VEHICLE: 'NOT_STAGE_PAPERWORK_PTE_DOC_VEHICLE',
  NOT_PERMISSION_SUBTYPE_UO: 'NOT_PERMISSION_SUBTYPE_UO',
  DOSSIER_PRECANCELED: 'DOSSIER_PRECANCELED',
  PROCESSING_AGENCY_LOGIN_ERROR: 'PROCESSING_AGENCY_LOGIN_ERROR',
  SEND_TO_AGENCY: 'SendToAgency',
  SEND_TO_AGENCY_NO_PAPERWORK: 'SendToAgencyNoPaperwork',
  PROCESSING_AGENCY_DENIED_RESENDING_PAPERWORK: 'PROCESSING_AGENCY_DENIED_RESENDING_PAPERWORK'
}
