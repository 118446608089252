import React, { PureComponent } from 'react'
import { Button, Modal, Col } from 'react-bootstrap'
import { reduxForm, Field } from 'redux-form'
import InputText from '../../commons/form/InputText'
import InputSelect from '../../commons/form/InputSelect'
import InputDatePicker from '../../commons/form/InputDatePicker'
import InputCheckbox from '../../commons/form/InputCheckBox'
import SimpleTablePage from '../../../containers/commons/general/SimpleTablePage'
import { compareValues } from '../../../util/utils'
import { validateDate } from '../../../util/validationFunctions'
class AddDossiersToFleetModal extends PureComponent {
  constructor () {
    super()
    this.state = {
      filters: {
        orderBy: '+number'
      },
      dynamicFilters: {},
      selectedCheck_massive: false,
      fields: {}
    }
  }

  fieldFill (field) {
    this.setState({ fields: { ...this.state.fields, ...field } })
  }

  getIconClass (field) {
    if (this.state.filters.orderBy.includes(field)) {
      return this.state.filters.orderBy.includes('-') ? 'ico-sort-selected-asc' : 'ico-sort-selected-desc'
    } else {
      return 'ico-sort'
    }
  }

  onOrderChange (e) {
    const newOrderBy = this.state.filters.orderBy.includes(`+${e.currentTarget.dataset.field}`) ? `-${e.currentTarget.dataset.field}` : `+${e.currentTarget.dataset.field}`
    this.setState({ filters: { orderBy: newOrderBy } })
    this.order(e.currentTarget.dataset.field, newOrderBy.includes('+') ? 'asc' : 'desc')
  }

  order (field, order) {
    let dossiers = JSON.parse(JSON.stringify(this.props.dossiersFleetToAdd))
    dossiers = dossiers.sort(compareValues(field, order))

    this.props.actions.fetchDossiersToAddToFleetSuccess(dossiers)
  }

  onCheckAll (e) {
    this.setState({ selectedCheck_massive: e })
    let dossiers = JSON.parse(JSON.stringify(this.props.dossiersFleetToAdd))

    dossiers.map(dossier => {
      dossier.checked = e
    })
    this.props.actions.fetchDossiersToAddToFleetSuccess(dossiers)
  }

  onCheckOne (e, id) {
    this.setState({ selectedCheck_massive: false })
    let dossiers = JSON.parse(JSON.stringify(this.props.dossiersFleetToAdd))

    dossiers.map(dossier => {
      if (dossier.dossierId === id) {
        dossier.checked = e
      }
    })

    this.props.actions.fetchDossiersToAddToFleetSuccess(dossiers)
  }

  validateDate (value, allValues, props) {
    const result = validateDate(value, props)
    return result === Object(result) ? undefined : result
  }

  handleClickReset (e) {
    this.setState({ selectedCheck_massive: false, fields: {} })
    this.props.initialize()
    this.props.actions.resetDossiersToAddToFleetSearch()
  }

  saveDossiersToFleet () {
    let dossierId = this.props.dossiersFleetToAdd.filter(dossier => dossier.checked).map(dossier => dossier.dossierId)
    new Promise((resolve, reject) => {
      this.props.actions.fetchAddDossierToFleet(dossierId, this.props.fleet.fleetId, resolve, reject)
    }).then(() => {
      this.setState({ selectedCheck_massive: false })
      this.props.actions.fetchDossiersToAddToFleet(this.state.dynamicFilters)
      if (this.props.fetchFleets) {
        this.props.fetchFleets()
      }
    })
  }

  searchDossiers () {
    this.setState({ dynamicFilters: { ...this.state.fields, fleetId: this.props.fleet.fleetId }, selectedCheck_massive: false }, () => {
      this.props.actions.fetchDossiersToAddToFleet(this.state.dynamicFilters)
    })
  }

  componentDidUpdate () {}
  getSnapshotBeforeUpdate (prevProps) {
    if (prevProps.showModal !== this.props.showModal) {
      this.setState({ selectedCheck_massive: false, fields: {} })
      this.props.initialize()
      this.props.actions.resetDossiersToAddToFleetSearch()
    }
    return null
  }

  haveValues (fields) {
    for (let key in fields) {
      if (fields[key] !== null && fields[key] !== undefined && fields[key] !== '') {
        return true
      }
    }
    return false
  }

  render () {
    const {
      t,
      showModal, actions: { closeAddDossiersToFleetModal }, combos: { salesmanCombo }, fleet, dossiersFleetToAdd
    } = this.props
    const tKey = 'MANAGEMENT.FLEETS_TAB.AUX_MODALS.'
    const selectedDossiers = this.props.dossiersFleetToAdd.filter(dossier => dossier.checked)
    if (showModal && fleet) {
      return (
        <Modal className="languages-modal addDosseirToFleet-modal" show={showModal} onHide={closeAddDossiersToFleetModal} backdrop={'static'}>
          <form className='addDossiersToFleetModal' autoComplete="off">
            <Modal.Header closeButton onHide={this.props.actions.closeActionDataModal}>
              <Modal.Title>{t(`${tKey}ADD_DOSSIERS_TO_FLEET_MODAL`)}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div id="addDosssierFleetFinderDiv" className="addDosssierFleetFinderDiv">
                <div className="search-header">
                  <div className="title-user-addFleet">
                    <i className="ico-search"/> <h2>{t('SECTIONS.SEARCH')}</h2>
                  </div>
                </div>
                <div className="filters-search-wrapper">
                  <Field
                    id="numberFrom"
                    name='numberFrom'
                    placeholder={t('MANAGEMENT.FLEETS_TAB.DOSSIER_NUMBER_FROM')}
                    controlLabel={t('MANAGEMENT.FLEETS_TAB.DOSSIER_NUMBER_FROM')}
                    component={InputText}
                    type="text"
                    onInputChange={(val) => this.fieldFill({ numberFrom: val })}

                  />
                  <Field
                    id="numberTo"
                    name='numberTo'
                    placeholder={t('MANAGEMENT.FLEETS_TAB.DOSSIER_NUMBER_TO')}
                    controlLabel={t('MANAGEMENT.FLEETS_TAB.DOSSIER_NUMBER_TO')}
                    component={InputText}
                    type="text"
                    onInputChange={(val) => this.fieldFill({ numberTo: val })}

                  />
                  <Field
                    id="creationDateFrom"
                    name='creationDateFrom'
                    placeholder={t('MANAGEMENT.FLEETS_TAB.CREATION_DATE_FROM')}
                    controlLabel={t('MANAGEMENT.FLEETS_TAB.CREATION_DATE_FROM')}
                    component={InputDatePicker}
                    validate={[this.validateDate]}
                    onInputChange={(val) => this.fieldFill({ creationDateFrom: val })}

                  />
                  <Field
                    id="creationDateTo"
                    name='creationDateTo'
                    placeholder={t('MANAGEMENT.FLEETS_TAB.CREATION_DATE_TO')}
                    controlLabel={t('MANAGEMENT.FLEETS_TAB.CREATION_DATE_TO')}
                    component={InputDatePicker}
                    validate={[this.validateDate]}
                    onInputChange={(val) => this.fieldFill({ creationDateTo: val })}

                  />
                  <Field
                    id="salesManId"
                    name='salesManId'
                    placeholder={t('DOSSIER_COMPONENTS.SALES.SALESMAN')}
                    controlLabel={t('DOSSIER_COMPONENTS.SALES.SALESMAN')}
                    component={InputSelect}
                    options={salesmanCombo}
                    valueKey="id"
                    labelKey="value"
                    onInputChange={(val) => this.fieldFill({ salesManId: val })}

                  />
                  <Field
                    id="referenceSale"
                    name='referenceSale'
                    placeholder={t(`${tKey}REFERENCE_SALE`)}
                    controlLabel={t(`${tKey}REFERENCE_SALE`)}
                    component={InputText}
                    type="text"
                    onInputChange={(val) => this.fieldFill({ referenceSale: val })}

                  />
                  <Button
                    id="btn_search"
                    onClick={() => this.searchDossiers()}
                    className="btn-standard button-search-addDossier"
                  >
                    <i className="ico-search"/>
                    {t('SEARCH.ACTIONS.SEARCH_BUTTON')}
                  </Button>
                  <Button
                    id="btn_reset"
                    onClick={(e) => this.handleClickReset(e)}
                    className={'btn-white btn-icon' + (!this.haveValues(this.state.fields) ? ' btn-cancel button-search-addDossier' : ' btn-standard button-search-addDossier')}
                    disabled={!this.haveValues(this.state.fields)}
                  >
                    <i className="ico-cancel"/> {t('SEARCH.ACTIONS.RESET_BUTTON')}
                  </Button>
                </div>
              </div>
              {this.props.hasSearch && (
                <div id="fleet-dossier-details-table">
                  <SimpleTablePage id="fleet-dossier-details-table"
                    columns={[
                      <th key={0} data-field="number" onClick={(event) => this.onOrderChange(event)}>
                        {t('MANAGEMENT.FLEETS_TAB.DOSSIER_NUMBER')}&nbsp;<i className={this.getIconClass('number')}></i>
                      </th>,
                      <th key={1} data-field="organizedUnit" onClick={(event) => this.onOrderChange(event)}>
                        {t('MANAGEMENT.FLEETS_TAB.ORGANIZED_UNIT')}&nbsp;<i className={this.getIconClass('organizedUnit')}></i>
                      </th>,
                      <th key={2} data-field="vehicle" onClick={(event) => this.onOrderChange(event)}>
                        {t('MANAGEMENT.FLEETS_TAB.VEHICLE')}&nbsp;<i className={this.getIconClass('vehicle')}/>
                      </th>,
                      <th key={3} data-field="model" onClick={(event) => this.onOrderChange(event)}>
                        {t('MANAGEMENT.FLEETS_TAB.MODEL')}&nbsp;<i className={this.getIconClass('model')}></i>
                      </th>,
                      <th key={4} data-field="salesMan" onClick={(event) => this.onOrderChange(event)}>
                        {t('MANAGEMENT.FLEETS_TAB.SALESMAN')}&nbsp;<i className={this.getIconClass('salesMan')}/>
                      </th>,
                      <th key={5} data-field="stage" onClick={(event) => this.onOrderChange(event)}>
                        {t('MANAGEMENT.FLEETS_TAB.STAGE')}&nbsp;<i className={this.getIconClass('stage')}/>
                      </th>,
                      <th key={6} data-field="checked" className='flet-detail-dossier-check-title' >
                        {t('MANAGEMENT.FLEETS_TAB.CHECK_ALL')}&nbsp;
                        <Field
                          component={InputCheckbox}
                          name={'selectedCheck_massive'}
                          onChange={(event) => this.onCheckAll(event)}
                          checked={this.state.selectedCheck_massive}
                        />

                      </th>
                    ]}
                    rows={dossiersFleetToAdd && dossiersFleetToAdd.map((row, idx) => (
                      <tr key={idx}>
                        <td>{row.number}</td>
                        <td>{row.organizedUnit}</td>
                        <td>{row.vehicle}</td>
                        <td>{row.model}</td>
                        <td>{row.salesMan}</td>
                        <td>{row.stage}</td>
                        <td>{<Field
                          component={InputCheckbox}
                          name={'selectedCheck_' + row.dossierId}
                          id={'selectedCheck_' + row.dossierId}
                          checked={row.checked ? row.checked : false}
                          onChange={(event) => this.onCheckOne(event, row.dossierId)}
                        />}
                        </td>
                      </tr>
                    ))}

                  ></SimpleTablePage>
                </div>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Col sm={12} className='fleet-created-moda-btn-exit'>
                <Button
                  id="btn_search"
                  className="btn-Accept"
                  onClick={() => { this.saveDossiersToFleet() }}
                  disabled={selectedDossiers.length === 0}>
                  {t(`${tKey}SAVE`)}
                </Button>
                <Button
                  id='btn-close-fleet-created-select-charge-modal'
                  className="btn-exit"
                  onClick={() => { closeAddDossiersToFleetModal() }}>
                  {t(`${tKey}EXIT`)}
                </Button>
              </Col>
            </Modal.Footer>
          </form>
        </Modal>

      )
    } else {
      return null
    }
  }
}

export default reduxForm({
  form: 'addDossiersToFleetModal'
})(AddDossiersToFleetModal)
