import { currencies, currencyTextsConfig } from '../constants/dossier/common/currencies'

export default {
  version: '1.3.9',
  serviceDeskUrl: 'https://acercapartners.atlassian.net/servicedesk/customer/portal/10',
  clientEnviroment: 'generic',
  url: 'api/',
  IdocSingUrl: 'sign-app/login',
  autoLoadDashboard: false,
  validateSalesManager: true,
  acceptSignFormats: '.jpg, .png, .jpeg',
  matomoUrl: 'https://analytics.acercafits.eu',
  matomoSiteId: '0',
  authenticationWithSalt: false,
  validateLicensePlate: true,
  acceptCompressFormat: '.zip',
  currency: currencies.EUR,
  currencyRegex: [/^[+-]?(\d{1,3}\.(\d{3}\.)*\d{3}|\d*)(,\d+)?€?$/],
  currencyTextConfig: currencyTextsConfig.EUR,
  chassisMinCharacter: 7,
  loadFleetDossiersFileFormats: ['.xls', '.xlsx'],
  reloadTaskDashboardOnReturn: false,
  enableFavoritesAndRecents: false,
  enableTaskFollowUp: false,
  dashboardVersion: 1,
  usersManualUrl: '',
  usersManualEnvironment: '',
  interfaceVersion: 1,
  theme: 'default',
  environmentName: '',
  paymentGatewayUrl: ''
}
