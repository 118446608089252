import {
  validationTypes, entityTypesId
} from '../constants/backendIds'
import validationsActionTypes from '../constants/actions/dossier/common/validations'
import backEndTagTranslation from '../constants/backEndTagTransaltion'
import { validationEntityPromisesOrder } from '../constants/validatedFields/backValidationPromisesOrder'
import promisesStatus from '../constants/promisesStatus'

const getValidatePromiseDispatch = (dispatch, validationTypeCode, paramsToValidate, organizedUnitId, type = validationsActionTypes.VALIDATE) => {
  return new Promise((resolve, reject) => dispatch({
    type,
    validationTypeCode,
    paramsToValidate,
    organizedUnitId,
    resolve,
    reject
  }))
}

const validateComponentEntityDniCif = (component, dispatch, organizedUnitId) => {
  if (component && !component.dniCifValidationDisable) {
    if (component.dniCif && component.entityTypeId === entityTypesId.privateEntity) {
      if (component.freeLance) {
        return getValidatePromiseDispatch(dispatch, validationTypes.FREELANCE_IDENTIFIER, { DniCif: component.dniCif }, organizedUnitId, validationsActionTypes.VALIDATE_DNICIF)
      } else {
        return getValidatePromiseDispatch(dispatch, validationTypes.INDIVIDUAL_IDENTIFIER, { Dni: component.dniCif }, organizedUnitId, validationsActionTypes.VALIDATE_DNICIF)
      }
    } else if (component.dniCif && component.entityTypeId === entityTypesId.businessEntity) {
      return getValidatePromiseDispatch(dispatch, validationTypes.COMPANY_IDENTIFIER, { Cif: component.dniCif }, organizedUnitId, validationsActionTypes.VALIDATE_DNICIF)
    }
  }
  return new Promise(resolve => resolve())
}

const validateComponentRecurrentDniCif = (component, dispatch, organizedUnitId) => {
  if (component) {
    if (component.dniCif) {
      return getValidatePromiseDispatch(dispatch, validationTypes.RECURRENT_IDENTIFIER, { DniCif: component.dniCif }, organizedUnitId, validationsActionTypes.VALIDATE_DNICIF)
    }
  }
  return new Promise(resolve => resolve())
}

const validateComponentEntityRepresentativeDni = (component, dispatch, organizedUnitId, recurrentEntity) => {
  if (component && !component.representativeDniCifValidationDisable) {
    if (component.representativeDni && (component.entityTypeId === entityTypesId.businessEntity || recurrentEntity)) {
      return getValidatePromiseDispatch(dispatch, validationTypes.INDIVIDUAL_IDENTIFIER, { Dni: component.representativeDni, IsRepresentativeDni: true }, organizedUnitId, validationsActionTypes.VALIDATE_REPRESENTATIVE_DNI)
    }
  }
  return new Promise(resolve => resolve())
}

export const asyncValidateEntityDni = (component, dispatch, props, organizedUnitId) => {
  if (organizedUnitId === undefined && props.dossier?.organizedUnitId) {
    organizedUnitId = props.dossier?.organizedUnitId
  }
  let promises = []
  if (component) {
    promises = promises.concat(validateComponentEntityDniCif(component, dispatch, organizedUnitId))
    promises = promises.concat(validateComponentEntityRepresentativeDni(component, dispatch, organizedUnitId))
  }

  return new Promise((resolve, reject) => {
    Promise.allSettled(promises).then((result) => {
      let error = {}
      for (let index = 0; index < result.length; index++) {
        const element = result[index]
        if (element.status === promisesStatus.REJECTED) {
          const errorFilteredByTag = element.reason?.json?.tag ? backEndTagTranslation[element.reason?.json?.tag] : backEndTagTranslation.default_error
          const translation = props.t('ERRORS.BACKEND_TAG_TRANSLATION.' + errorFilteredByTag)
          switch (index) {
            case validationEntityPromisesOrder.ENTITY_COMPONENT_DNI_CIF:
              error = { ...error, dniCif: translation }
              break
            case validationEntityPromisesOrder.ENTITY_COMPONENT_REPRESENTATIVE_DNI:
              error = { ...error, representativeDni: translation }
              break
            default:
              break
          }
        }
      }
      Object.keys(error).length > 0 ? resolve(error) : reject()
    })
  })
}

export const asyncValidateRecurrentEntity = (component, dispatch, props, organizedUnitId) => {
  let promises = []
  if (component && organizedUnitId) {
    promises = promises.concat(validateComponentRecurrentDniCif(component, dispatch, organizedUnitId))
    promises = promises.concat(validateComponentEntityRepresentativeDni(component, dispatch, organizedUnitId, true))
  }

  return new Promise((resolve, reject) => {
    Promise.allSettled(promises).then((result) => {
      let error = {}
      for (let index = 0; index < result.length; index++) {
        const element = result[index]
        if (element.status === promisesStatus.REJECTED) {
          const errorFilteredByTag = element.reason?.json?.tag ? backEndTagTranslation[element.reason?.json?.tag] : backEndTagTranslation.default_error
          const translation = props.t('ERRORS.BACKEND_TAG_TRANSLATION.' + errorFilteredByTag)
          switch (index) {
            case validationEntityPromisesOrder.ENTITY_COMPONENT_DNI_CIF:
              error = { ...error, dniCif: translation }
              break
            case validationEntityPromisesOrder.ENTITY_COMPONENT_REPRESENTATIVE_DNI:
              error = { ...error, representativeDni: translation }
              break
            default:
              break
          }
        }
      }
      Object.keys(error).length > 0 ? resolve(error) : reject()
    })
  })
}

export const getFieldsValidated = (parentName) => {
  return [`${parentName}.dniCif`, `${parentName}.representativeDni`, `${parentName}.freeLance`]
}
export const getFieldsRecurrentValidated = (parentName) => {
  return [`${parentName}.dniCif`, `${parentName}.representativeDni`, `${parentName}.organizedUnitId`]
}
