import React, { PureComponent } from 'react'
import { Tabs, Tab, Button, Tooltip, OverlayTrigger } from 'react-bootstrap'
import { urgencyTypesId, clientInformation, dashboardSalesmanColumnDossierSubType } from '../../../../constants/backendIds'
// import DossierListTable from './DossierListTable'
import DossierListTableComplete from './DossierListTableComplete'
import IDocCarPagination from '../../../../components/commons/iDocCarPagination'
import settings from '../../../../setting'

class DossiersList extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      maxiTable: false
    }
  }

  defaultUrgencyTypeActiveKey (urgencyTypeId) {
    switch (Number(urgencyTypeId)) {
      case urgencyTypesId.low:
        return 3
      case urgencyTypesId.medium:
        return 2
      case urgencyTypesId.high:
        return 1
      default:
        return 0
    }
  }

  onSelectUrgencyTab (eventKey) {
    let urgencyTypeId = 0
    switch (eventKey) {
      case 3:
        urgencyTypeId = urgencyTypesId.low
        break
      case 2:
        urgencyTypeId = urgencyTypesId.medium
        break
      case 1:
        urgencyTypeId = urgencyTypesId.high
        break
      default:
        urgencyTypeId = 0
    }
    this.props.history.push(`/dashboard/${this.props.match.params.salesmanId}/${urgencyTypeId}/${this.props.match.params.subTypeId}/${this.props.match.params.stageId}`)
    // this.props.history.replace(urgencyTypeId, { urgencyTypeId })
  }

  goBack () {
    this.props.history.push('/dashboard')
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (

      (this.props.organizedUnit !== nextProps.organizedUnit && nextProps.organizedUnit != null) ||
      this.props.match.params.salesmanId !== nextProps.match.params.salesmanId ||
      this.props.match.params.urgencyTypeId !== nextProps.match.params.urgencyTypeId ||
      this.props.match.params.subTypeId !== nextProps.match.params.subTypeId ||
      this.props.match.params.stageId !== nextProps.match.params.stageId
    ) {
      this.props.actions.fetchDossiersCompleteList(
        nextProps.organizedUnit,
        nextProps.match.params.salesmanId,
        nextProps.match.params.urgencyTypeId,
        nextProps.match.params.subTypeId,
        nextProps.match.params.stageId,
        1
      )
    }
  }

  UNSAFE_componentWillMount () {
    const params = this.props.match.params
    this.props.actions.fetchDossiersCompleteList(
      this.props.organizedUnit,
      params.salesmanId,
      params.urgencyTypeId,
      params.subTypeId,
      params.stageId,
      params.page !== undefined && params.page !== null ? params.page : 1,
      params.columnOrder !== undefined ? params.columnOrder : ''
    )
  }

  paginationCharge (organizedUnit, salesmanId, urgencyTypeId, subTypeId, stageId, newPage, orderBy) {
    this.props.history.push(`/dashboard/${salesmanId}/${urgencyTypeId}/${subTypeId}/${stageId}/${newPage}/${orderBy}`)
    this.props.actions.fetchDossiersCompleteList(
      organizedUnit,
      salesmanId,
      urgencyTypeId,
      subTypeId,
      stageId,
      newPage,
      orderBy
    )
  }

  exportData () {
    const tKey = 'SEARCH.CATEGORIES.DOSSIER.'
    let columnsExcel = []
    columnsExcel.push(this.props.t(`${tKey}URGENCY`) + '#Urgency')
    columnsExcel.push(this.props.t(`${tKey}DOSSIER_NUMBER`) + '#Number')
    if (dashboardSalesmanColumnDossierSubType.includes(parseInt(this.props.match.params.subTypeId))) {
      columnsExcel.push(this.props.t(`${tKey}SALESMAN`) + '#Salesman')

      if (settings.clientFieldValidation === clientInformation.QUADIS) {
        columnsExcel.push(this.props.t(`${tKey}SALESMAN_MANAGER`) + '#SalesManagerName')
      }
    }
    columnsExcel.push(this.props.t(`${tKey}ORGANIZED_UNIT`) + '#Uo')
    columnsExcel.push(this.props.t('SEARCH.CATEGORIES.ENTITY.BY_ENTITY') + '#Entity')
    columnsExcel.push(this.props.t('SEARCH.CATEGORIES.VEHICLE.BY_VEHICLE') + '#Vehicle')
    if (settings.clientFieldValidation === clientInformation.VGR) {
      columnsExcel.push(this.props.t('DASHBOARD.DOSSIERS_LIST.TABLE.PRODUCTION_WEEK') + '#ProductionWeek')
      columnsExcel.push(this.props.t('DASHBOARD.DOSSIERS_LIST.TABLE.DELIVERY_DATE') + '#DeliveryDate')
    }
    if (settings.clientFieldValidation !== clientInformation.VGR) {
      columnsExcel.push(this.props.t('DASHBOARD.DOSSIERS_LIST.TABLE.LAST_MODIFICATION_DATE') + '#LastModificationDate')
    }

    columnsExcel.push(this.props.t(`${tKey}URL`) + '#UrlToNavigate')
    this.exportToExcel(columnsExcel)
  }

  exportToExcel (data) {
    let cols = ''
    data.forEach(element => {
      cols += element + '$'
    })
    cols = cols.substring(0, cols.length - 1)
    if (cols && this.props.match.params) {
      const params = this.props.match.params
      const urgencyName = this.getUrgencyName(parseInt(params.urgencyTypeId))
      this.props.actions.exporToExcelDossiersList(this.props.organizedUnit, params.salesmanId, params.urgencyTypeId, urgencyName,
        params.subTypeId, params.stageId, 1, this.props.orderBy, this.totalRows(parseInt(params.urgencyTypeId)), cols,
        this.props.dossiersData.maxDossierExport ? this.props.dossiersData.maxDossierExport : null,
        this.totalRows(parseInt(params.urgencyTypeId)) > this.props.dossiersData.maxDossierExport ? this.props.dossiersData.maxDossierExport : null)
    }
  }

  totalRows (urgencyTypeId) {
    let total = 0
    switch (urgencyTypeId) {
      case 4:
        total = this.props.dossiersData.totalLow
        break
      case 3:
        total = this.props.dossiersData.totalMedium
        break
      case 2:
        total = this.props.dossiersData.totalHigh
        break
      default:
        total = this.props.dossiersData.total
    }
    return total
  }

  getUrgencyName (urgencyTypeId) {
    let name
    switch (urgencyTypeId) {
      case 4:
        name = this.props.t('DASHBOARD.DOSSIERS_LIST.URGENCY_TYPES_EXCEL.LOW')
        break
      case 3:
        name = this.props.t('DASHBOARD.DOSSIERS_LIST.URGENCY_TYPES_EXCEL.MEDIUM')
        break
      case 2:
        name = this.props.t('DASHBOARD.DOSSIERS_LIST.URGENCY_TYPES_EXCEL.HIGH')
        break
      default:
        name = this.props.t('DASHBOARD.DOSSIERS_LIST.URGENCY_TYPES_EXCEL.TOTAL')
    }
    return name
  }

  toogleMaxiTable () {
    this.setState({ maxiTable: !this.state.maxiTable })
  }

  render () {
    const {
      t, page, organizedUnit,
      actions: { navigateTo },
      dossiersData: { dossiers, filter = {}, total, totalHigh, totalMedium, totalLow, pagesCount },
      orderBy
    } = this.props
    const tKey = 'DASHBOARD.DOSSIERS_LIST.'
    return (
      <div className="wrapper _nf">
        <div className='wrapper-header'>
          <div className="title-page">
            <Button className="_Btn accept o-icn" onClick={() => this.goBack()}>
              <i className="iDocIcon-arrow-left-back"/>
            </Button>
            <h2>
              {t(`${tKey}TITLE`)}
            </h2>
          </div>
          <div className='title-page-extra'>
            {dossiers && dossiers.length > 0 && filter.dossierSubType &&

                <Button
                  onClick={(event) => this.exportData(event)}
                  bsStyle="default"
                  bsSize="small"
                  className="_Btn new">
                  <i className="iDocIcon-file-excel"/>
                  {t('SEARCH.ACTIONS.EXPORT')}
                </Button>
            }
          </div>
        </div>
        <div className='wrapper-body'>
          <div className='inner-data-content'>
            <div className="inner-results-panel">
              <div className="section-results-header">
                <div className='breadcrumbs'>
                  <div className='fileData'>
                    {filter.dossierSubType &&
                        <div className="filter"><p>SUBTIPO: </p><span>{filter.dossierSubType}</span></div>
                    }
                    {filter.dossierStage &&
                        <div className="filter"><p>ETAPA: </p><span>{filter.dossierStage}</span></div>
                    }
                  </div>
                  {filter.organizedUnit &&
                      <div className="filter"><p>UNIDAD/UNIDADES ORGANIZATIVAS: </p><span>{filter.organizedUnit}</span></div>
                  }
                  {filter.salesman &&
                      <div className="filter"><p>VENDEDOR/VENDEDORES:</p><span>{filter.salesman}</span></div>
                  }
                </div>
              </div>
              <div className="section-results-body">
                <div id="dashboard-tasks-table-wrapper" className={'table-wrapper' + (this.state.maxiTable ? ' maxiTable' : '')}>
                  {/* table options, maximize, etc.... */}
                  <div className='flexInLine al-R'>
                    <OverlayTrigger placement="left" overlay={<Tooltip id="editProductTooltip">{`${t((this.state.maxiTable ? 'GLOBAL.TABLES.MINIMIZE_TABLE' : 'GLOBAL.TABLES.MAXIMIZE_TABLE'))}`}</Tooltip>}>
                      <a onClick={() => this.toogleMaxiTable()} className='_Btn accept stk o-icn maximizeBtn'><i className={(this.state.maxiTable ? 'iDocIcon-Minimize' : 'iDocIcon-Maximize')}></i></a>
                    </OverlayTrigger>
                  </div>
                  {/* table */}
                  <div className="table-body">

                    <Tabs
                      defaultActiveKey={this.defaultUrgencyTypeActiveKey(this.props.match.params.urgencyTypeId)}
                      id="urgencyTabs"
                      onSelect={this.onSelectUrgencyTab.bind(this)}
                    >
                      <Tab eventKey={0} title={`${t(`${tKey}URGENCY_TYPES.TOTAL`)} (${total || 0})`}>
                        <DossierListTableComplete
                          t={t}
                          dossierList={dossiers}
                          navigateTo={navigateTo}
                          dossierSubType={this.props.match.params.subTypeId}
                          fetchDossiersCompleteList={this.props.actions.fetchDossiersCompleteList}
                          urgencyTypeId={urgencyTypesId.todas}
                          organizedUnit={this.props.organizedUnit}
                          params={this.props.match.params}
                          page={page}
                          orderBy={orderBy}
                          history = {this.props.history}
                        />
                      </Tab>
                      <Tab eventKey={1} title={`${t(`${tKey}URGENCY_TYPES.HIGH`)} (${totalHigh || 0})`}>
                        <DossierListTableComplete
                          t={t}
                          dossierList={dossiers}
                          navigateTo={navigateTo}
                          dossierSubType={this.props.match.params.subTypeId}
                          fetchDossiersCompleteList={this.props.actions.fetchDossiersCompleteList}
                          urgencyTypeId={urgencyTypesId.high}
                          organizedUnit={this.props.organizedUnit}
                          params={this.props.match.params}
                          page={page}
                          orderBy={orderBy}
                          history = {this.props.history}
                        />
                      </Tab>
                      <Tab eventKey={2} title={`${t(`${tKey}URGENCY_TYPES.MEDIUM`)} (${totalMedium || 0})`}>
                        <DossierListTableComplete
                          t={t}
                          dossierList={dossiers}
                          navigateTo={navigateTo}
                          dossierSubType={this.props.match.params.subTypeId}
                          fetchDossiersCompleteList={this.props.actions.fetchDossiersCompleteList}
                          urgencyTypeId={urgencyTypesId.medium}
                          organizedUnit={this.props.organizedUnit}
                          params={this.props.match.params}
                          page={page}
                          orderBy={orderBy}
                          history = {this.props.history}
                        />
                      </Tab>
                      <Tab eventKey={3} title={`${t(`${tKey}URGENCY_TYPES.LOW`)} (${totalLow || 0})`}>
                        <DossierListTableComplete
                          t={t}
                          dossierList={dossiers}
                          navigateTo={navigateTo}
                          dossierSubType={this.props.match.params.subTypeId}
                          fetchDossiersCompleteList={this.props.actions.fetchDossiersCompleteList}
                          urgencyTypeId={urgencyTypesId.low}
                          organizedUnit={this.props.organizedUnit}
                          params={this.props.match.params}
                          page={page}
                          orderBy={orderBy}
                          history = {this.props.history}
                        />
                      </Tab>
                    </Tabs>

                  </div>

                  <IDocCarPagination
                    id="btn_pag_sales"
                    page={page}
                    pagesCount={pagesCount}
                    totalRows= {
                      this.props.match.params.urgencyTypeId === urgencyTypesId.low.toString() ? totalLow
                        : this.props.match.params.urgencyTypeId === urgencyTypesId.medium.toString() ? totalMedium
                          : this.props.match.params.urgencyTypeId === urgencyTypesId.high.toString() ? totalHigh
                            : total
                    }
                    onSelectAction={(newPage) => this.paginationCharge(organizedUnit, this.props.match.params.salesmanId, this.props.match.params.urgencyTypeId, this.props.match.params.subTypeId, this.props.match.params.stageId, newPage, orderBy)}
                  />

                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

    )
  }
}

export default DossiersList
