import apiFetch from '../apiFetch'

export default function (associatedCostsId, associatedCostsValues, uoId, token) {
  let body = {
    organizedUnitId: uoId,
    associatedCostsTypeId: associatedCostsId,
    isDeleted: false,
    associatedCostTypeLanguages: associatedCostsValues
  }
  return apiFetch({ endPoint: `AssociatedCostType/${associatedCostsId}`, method: 'PUT', body: body, token: token })
}
