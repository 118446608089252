import React, { PureComponent } from 'react'
import { reduxForm } from 'redux-form'
import { Row, Col, Panel, Table } from 'react-bootstrap'
import { Element } from 'react-scroll'
import { dossierTypes as dossierTypesConstant } from '../../../constants/dossier/common/dossierTypes'
import { compareObjects } from '../../../util/utils'
import { getComponentsPermisions } from '../../../util/permisionFunctions'
import EditableTable from '../../commons/editableTable/EditableTable'
import StatusHeaderPage from '../../../containers/dossierHistorical/common/StatusHeaderPage'
import ButtonsHeader from '../common/ButtonsHeader'
import ButtonsSidebar from '../../dossiers/common/ButtonsSidebar'
import Client from '../common/Client'
import Vehicle from '../common/Vehicle'
import PaperworkComponent from './PaperworkComponent'
import RejectCommentModal from '../common/RejectCommentModal'
import { buttonsHeader } from '../../../constants/dossier/common/buttonsHeader'
import { buttonsSidebar } from '../../../constants/dossier/common/buttonsSidebar'
import { urgencyTypesClass, permissions } from '../../../constants/backendIds'
import DocumentaryManagementPage from '../../../containers/dossierHistorical/common/DocumentaryManagementPage'
import AuditInfo from '../../dossiers/common/audit/AuditInfo'
import { Link } from 'react-router-dom'
import TrackingContainer from '../../../containers/tracking/trackingContainer'
import { TrackedPages } from '../../../constants/tracking/TrackedPages'

class PaperworkHistorical extends PureComponent {
  constructor () {
    super()
    this.state = {
      modalOpen: true,
      showRejectCommentModal: false,
      paperworkOpen: true,
      isVehicleLoaded: false,
      isClientLoaded: false,
      activeTabConfiguration: null,
      headerTabConfiguration: null,
      componentsConfiguration: {
        bpaperwork: null,
        entity: null,
        vehicle: null,
        downloadDocumentsButton: null
      }
    }
    this.goBack = this.goBack.bind(this)
  }

  goBack () {
    this.props.actions.setActiveTab(buttonsHeader.datos_expediente)
    this.props.history.goBack()
  }

  toggleRejectCommentModal (showRejectCommentModal) {
    this.setState({ showRejectCommentModal })
  }

  onClickRelatedDossierColumn (columnCode) {
    let columnCodeOrder = '+' + columnCode
    if (columnCodeOrder === this.props.relatedDossiersOrderBy) {
      columnCodeOrder = '-' + columnCode
    }
    this.props.actions.fetchFilteredRelatedDossier(this.props.dossier.dossierId, columnCodeOrder, true)
  }

  getRelatedDossierColumnIcon (columnCode) {
    if ('+' + columnCode === this.props.relatedDossiersOrderBy) {
      return 'ico-sort-selected-asc'
    } else if ('-' + columnCode === this.props.relatedDossiersOrderBy) {
      return 'ico-sort-selected-desc'
    } else {
      return 'ico-sort'
    }
  }

  fetchPaperworkDossierPromise (dossierId) {
    return new Promise((resolve, reject) => {
      return this.props.actions.fetchPaperworkDossier(dossierId, resolve, reject, true)
    })
  }

  initializePermisions (props) {
    const componentKeys = Object.keys(this.state.componentsConfiguration)
    const newState = getComponentsPermisions(props.dossier.viewConfiguration.tabsConfiguration, props.activeTab, componentKeys)
    this.setState({ ...this.state, ...newState })
  }

  UNSAFE_componentWillMount () {
    this.props.actions.restartPaperworkState()
    this.fetchPaperworkDossierPromise(this.props.match.params.dossierId).then(() => {
      const form = { ...this.props.dossier }
      if (!this.props.dossier.entityComponent.entityId) delete form.entityComponent
      if (!this.props.dossier.vehicleComponent.vehicleId) delete form.vehicleComponent
      this.props.initialize(form)
      this.initializePermisions(this.props)
      this.props.actions.fetchVehicleType()
      this.props.actions.fetchCustomFields(this.props.dossier.dossierSubTypeId)
      this.props.actions.registerLastAccess(this.props.match.params.dossierId, true)
      this.props.trackVisitPage({
        documentTitle: TrackedPages.PAPERWORK_HISTORICAL,
        organizedUnitId: this.props.dossier.organizedUnitId,
        href: window.location.href
      })
    })
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if ((this.props.showManagementModal && !nextProps.showManagementModal) || this.props.match.params.dossierId !== nextProps.match.params.dossierId) {
      if (this.props.match.params.dossierId !== nextProps.match.params.dossierId) {
        this.props.actions.restartPaperworkState()
        this.props.actions.setScrollTo(buttonsSidebar.bpaperwork)
        this.props.actions.setActiveSection(buttonsSidebar.bpaperwork)
        this.props.actions.setActiveTab(buttonsHeader.datos_expediente)
        this.props.actions.closeAddOrEditDocumentModal()
      }
      this.fetchPaperworkDossierPromise(nextProps.match.params.dossierId).then(() => {
        const form = { ...this.props.dossier }
        if (!this.props.dossier.entityComponent.entityId) delete form.entityComponent
        if (!this.props.dossier.vehicleComponent.vehicleId) delete form.vehicleComponent
        this.props.initialize(form)
        this.initializePermisions(this.props)
      })
    }
    if (nextProps.dossier.dossierId && !compareObjects(this.props.dossier.viewConfiguration.tabsConfiguration || {}, nextProps.dossier.viewConfiguration.tabsConfiguration, true)) {
      this.initializePermisions(nextProps)
    }
    if (nextProps.dossier.dossierId && nextProps.activeTab !== this.props.activeTab) {
      this.initializePermisions(nextProps)
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.dossier.dossierId && this.props.statusHeaderHeight && this.props.activeSection) {
      switch (this.props.activeSection) {
        case buttonsSidebar.bpaperwork:
          this.setState({ paperworkOpen: true })
          break
      }
    }

    let customFields = {}
    const firstCustomFieldsLoad = this.props.dossier.customFieldsByComponent?.length > 0
    if (firstCustomFieldsLoad) {
      if (this.props.dossier.customFieldsValues) {
        this.props.dossier.customFieldsValues.map(x => {
          customFields[x.key] = x.value
        })
      }
      const form = { ...this.props.dossier, customFields: customFields }
      if (!this.props.dossier.entityComponent.entityId) delete form.entityComponent
      if (!this.props.dossier.vehicleComponent.vehicleId) delete form.vehicleComponent
      this.props.initialize(form)
    }
  }

  componentWillUnmount () {
    this.props.actions.setActiveSection(buttonsSidebar.bpaperwork)
    this.props.actions.setScrollTo(null)
    this.props.actions.resetButtonsHeader()
    this.props.actions.closeAddOrEditDocumentModal()
    this.props.actions.restartPaperworkState()
  }

  componentDidMount () {
    this.props.actions.fetchCommercialSocietyTypeCombo()
  }

  handleToUpdateComponent (component) {
    if (component.isVehicleLoaded === false || buttonsSidebar.vehicle === component) this.setState({ isVehicleLoaded: true })
    if (component.isClientLoaded === false || buttonsSidebar.entity === component) this.setState({ isClientLoaded: true })
    this.props.actions.setScrollTo(component)
  }

  togglePanel () {
    if (this.state.paperworkOpen) {
      this.props.actions.setActiveSection(false)
    }
    this.setState({ paperworkOpen: !this.state.paperworkOpen })
  }

  render () {
    const {
      t, combos, dossier, change, pristine, vehicleProps, activeTab, buttonsHeaderHeight, activeSection, statusHeaderHeight, windowWidth, documentExport,
      dossier: {
        dossierId, header, checkListHeader, comments, dossierParentId, dossierPaperworkComponent, dossierSubTypeId,
        viewConfiguration, viewConfiguration: { buttonsPermission = [] }, dossierParentSubTypeId,
        vehicleComponent: { vehicleId }, entityChangeable, dossierRelatedDossiers, auditInfo
      },
      actions: {
        fetchEntitySubTypeCombo, fetchEntityFilterSubTypeCombo,
        openDocumentModal, openDocumentEditionModal, setActiveTab, setButtonsHeaderHeight, resetButtonsHeader, setActiveSection, setScrollerOptions, /* navigateTo, */
        fetchComboOrigin, fetchComboDestination, fetchAuditDetails, fetchAuditDetailsModal, fetchDocumentsGroupedAuditDetails, fetchDocumentsGroupedAuditDetailsModal,
        refreshAuditInfo, fetchPaperworkVehicleHistorical, fetchPaperworkClientHistorical, fecthFamily, saveSelectedDocuments, fetchCommercialSocietyTypeCombo, fetchEntityCountryTypeCombo,
        fetchEntityStateTypeCombo, fetchEntityCityTypeCombo, fetchEntityTownTypeCombo, fetchEntityColonyTypeCombo, fetchEntityCPTypeCombo, openCommonModal, fetchActivityBpro,
        fetchClientDataAddress
      }
    } = this.props

    if (!dossierId) return null

    const tKey = 'DOSSIER_COMPONENTS.'
    const savePaperworkPermission = buttonsPermission.includes('paperwork_save')
    const panelsPaddingTop = statusHeaderHeight + buttonsHeaderHeight - 82 // 82px es el paddingTop definido en el layout.

    return (
      <div className="dossier-paperwork-container">
        <RejectCommentModal
          t={t}
          showModal={this.state.showRejectCommentModal}
          actions={{
            closeModal: () => this.toggleRejectCommentModal(false)
          }}
        />
        <Col sm={12} className="add-dossier-wrapper dossier">
          <StatusHeaderPage
            headerData={header}
            stages={checkListHeader}
            headerTabConfiguration={this.state.headerTabConfiguration}
            goBack={this.goBack}
            dossierId={dossier.dossierId}
          />
        </Col>
        <form autoComplete="off">
          <div className="row-header-buttons">
            <div className="buttons-header-box">
              <ButtonsHeader
                t={t}
                pristine={pristine}
                actions={{
                  openDocumentModal: () => openDocumentModal(dossierId, dossierTypesConstant.paperwork, pristine, 1, true),
                  setActiveTab,
                  setButtonsHeaderHeight,
                  resetButtonsHeader,
                  setScrollerOptions
                }}
                viewConfiguration={viewConfiguration}
                dossierId={this.props.match.params.dossierId}
                dossierType={dossierTypesConstant.paperwork}
                activeTab={activeTab}
                marginTop={statusHeaderHeight}
                windowWidth={windowWidth}
              />
            </div>
          </div>
          {
            activeTab === buttonsHeader.datos_expediente && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden && (
              <Row className="sales-panel dossier-panel">
                <Col sm={3} className="col-row expediente-menu-izq">
                  <ButtonsSidebar
                    t={t}
                    dossierId={dossierId}
                    historical={true}
                    isClientLoaded = {this.state.isClientLoaded}
                    isVehicleLoaded = {this.state.isVehicleLoaded}
                    handleToUpdateComponent = {this.handleToUpdateComponent.bind(this)}
                    pristine = {pristine}
                    actions={{ setActiveSection, fetchPaperworkVehicleHistorical, fetchPaperworkClientHistorical }}
                    dossierType={dossierTypesConstant.paperwork}
                    marginTop={statusHeaderHeight}
                    buttonsHeaderHeight={buttonsHeaderHeight}
                    tabConfiguration={this.state.activeTabConfiguration}
                  />
                </Col>
                <Col sm={9} className="col-row contenido-paneles-expediente">
                  <Col className="panels-wrapper dossier fixed" style={{ paddingTop: panelsPaddingTop }}>
                    {this.state.componentsConfiguration.bpaperwork && this.state.componentsConfiguration.bpaperwork.permission > permissions.hidden && (
                      <Element name={buttonsSidebar.bpaperwork}>
                        <PaperworkComponent
                          t={t}
                          paperworkOpen={this.state.paperworkOpen}
                          combos={combos}
                          actions={{
                            togglePanel: () => this.togglePanel(),
                            setActiveSection
                          }}
                          dossierPaperworkComponent={dossierPaperworkComponent}
                          paperworkTypeId={dossier.dossierPaperworkComponent.paperworkTypeId}
                          dossierParentId={dossierParentId}
                          readOnlyFields={!savePaperworkPermission}
                          componentConfiguration={this.state.componentsConfiguration.bpaperwork}
                          customFields={this.props.dossier.customFieldsByComponent.find(x => x.componentCode === buttonsSidebar.bpaperwork)?.customFields}
                        />
                      </Element>
                    )}
                    {this.state.componentsConfiguration.entity && this.state.componentsConfiguration.entity.permission > permissions.hidden && (
                      <Element name={buttonsSidebar.entity}>
                        <Client
                          t={t}
                          dossier={dossier}
                          combos={combos}
                          entityChangeable={entityChangeable}
                          dossierType={dossierTypesConstant.paperwork}
                          handleToUpdateComponent = {this.handleToUpdateComponent.bind(this)}
                          isClientLoaded = {this.state.isClientLoaded}
                          pristine={pristine}
                          actions={{
                            change,
                            fetchEntitySubTypeCombo,
                            fetchEntityFilterSubTypeCombo,
                            openDocumentEditionModal,
                            fetchPaperworkClientHistorical,
                            setActiveSection,
                            fetchCommercialSocietyTypeCombo,
                            fetchEntityCountryTypeCombo,
                            fetchEntityStateTypeCombo,
                            fetchEntityCityTypeCombo,
                            fetchEntityTownTypeCombo,
                            fetchEntityColonyTypeCombo,
                            fetchEntityCPTypeCombo,
                            openCommonModal,
                            fetchActivityBpro,
                            fetchClientDataAddress
                          }}
                          readOnlyFields={!savePaperworkPermission}
                          open={activeSection === buttonsSidebar.entity}
                          componentConfiguration={this.state.componentsConfiguration.entity}
                          customFields={this.props.dossier.customFieldsByComponent.find(x => x.componentCode === buttonsSidebar.entity)?.customFields}
                        />
                      </Element>
                    )}
                    {this.state.componentsConfiguration.vehicle && this.state.componentsConfiguration.vehicle.permission > permissions.hidden && (
                      <Element name={buttonsSidebar.vehicle}>
                        <Vehicle
                          parentDossierType={{
                            isPaperwork: true,
                            isSales: false,
                            isPurchase: false,
                            isRegistrationDelivery: false
                          }}
                          actions={{
                            fetchComboOrigin,
                            fetchComboDestination,
                            fetchPaperworkVehicleHistorical,
                            fecthFamily,
                            setActiveSection
                          }}
                          t={t}
                          dossierId={dossierId}
                          dossierType={dossierTypesConstant.paperwork}
                          handleToUpdateComponent = {this.handleToUpdateComponent.bind(this)}
                          isVehicleLoaded = {this.state.isVehicleLoaded}
                          vehicleProps={vehicleProps}
                          dossierSubType={dossierSubTypeId}
                          dossierParentId={dossierParentId}
                          dossierWithVehicle={!!vehicleId}
                          combos={combos}
                          readOnlyFields={!savePaperworkPermission}
                          open={activeSection === buttonsSidebar.vehicle}
                          componentConfiguration={this.state.componentsConfiguration.vehicle}
                          organizedUnitId={dossier.organizedUnitId}
                          dossierParentSubTypeId={dossierParentSubTypeId}
                          customFields={this.props.dossier.customFieldsByComponent.find(x => x.componentCode === buttonsSidebar.vehicle)?.customFields}
                        />
                      </Element>
                    )}
                  </Col>
                </Col>
              </Row>
            )
          }
          {
            activeTab === buttonsHeader.gestor_documental && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden && (
              <Row className="sales-panel dossier-panel">
                <Col sm={12} className="col-row" style={{ paddingTop: panelsPaddingTop }}>
                  <DocumentaryManagementPage
                    dossier={dossier}
                    dossierType={dossierTypesConstant.sales}
                    saveSelectedDocuments={saveSelectedDocuments}
                    documentExport={documentExport}
                    combos={this.props.scombos}
                    isHistorical={true}
                    tabConfiguration={this.state.activeTabConfiguration}
                  />
                </Col>
              </Row>
            )
          }
          {
            activeTab === buttonsHeader.expedientes_relacionados && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden && (
              <Row className="sales-panel dossier-panel panel-expedientes-relacionados">
                <Col sm={12} className="col-row" style={{ paddingTop: panelsPaddingTop }}>
                  <Col sm={6} className="col-row">
                    <h4><i className="ico-relacion"/> Expedientes relacionados</h4>
                  </Col>
                  <Col sm={12} className="col-row">
                    <Table hover responsive className="simple-table dashboard-tasks-table" id="related-dossiers-table">
                      <thead>
                        <tr>
                          <th onClick={this.onClickRelatedDossierColumn.bind(this, 'urgencyType')}>
                            <i className={this.getRelatedDossierColumnIcon('urgencyType')}/>
                          </th>
                          <th onClick={this.onClickRelatedDossierColumn.bind(this, 'number')}>
                        Número de expediente <i className={this.getRelatedDossierColumnIcon('number')}/>
                          </th>
                          <th onClick={this.onClickRelatedDossierColumn.bind(this, 'organizedUnit')}>
                        UO <i className={this.getRelatedDossierColumnIcon('organizedUnit')}/>
                          </th>
                          <th onClick={this.onClickRelatedDossierColumn.bind(this, 'dossierSubType')}>
                        Subtipo <i className={this.getRelatedDossierColumnIcon('dossierSubType')}/>
                          </th>
                          <th onClick={this.onClickRelatedDossierColumn.bind(this, 'stage')}>
                        Estado <i className={this.getRelatedDossierColumnIcon('stage')}/>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          dossierRelatedDossiers.map((relatedDossier, idx) => {
                            return (
                              <tr key={idx}>
                                <td>
                                  <span className={'color-point ' + urgencyTypesClass[relatedDossier.urgencyType]}></span>
                                </td>
                                <td>
                                  {
                                    relatedDossier.urlToNavigate ? (
                                      <Link to={`/dossier${relatedDossier.urlToNavigate}`}>
                                        {relatedDossier.number}
                                      </Link>
                                    ) : relatedDossier.number
                                  }
                                </td>
                                <td>{relatedDossier.organizedUnit}</td>
                                <td>{relatedDossier.dossierSubType}</td>
                                <td>{relatedDossier.stage}</td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </Table>
                  </Col>
                </Col>
              </Row>
            )}
          {
            activeTab === buttonsHeader.actividad_expediente && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden && (
              <Row className="sales-panel dossier-panel panel-actividad-expediente">
                <Col sm={12} className="col-row" style={{ paddingTop: panelsPaddingTop }}>
                  <AuditInfo
                    auditInfo={auditInfo}
                    dossierType={dossierTypesConstant.sales}
                    actions={{ refreshAuditInfo, fetchAuditDetails, fetchAuditDetailsModal, fetchDocumentsGroupedAuditDetails, fetchDocumentsGroupedAuditDetailsModal }}
                    dossier={dossier}
                    historical={true}
                    t={t}
                  />
                </Col>
              </Row>
            )
          }
          {
            activeTab === buttonsHeader.observaciones && this.state.activeTabConfiguration && this.state.activeTabConfiguration.permission > permissions.hidden && (
              <Row className="sales-panel dossier-panel panel-observaciones-expediente">
                <Col sm={12} className="col-row" style={{ paddingTop: panelsPaddingTop }}>
                  <Col sm={6} className="col-row">
                    <h4><i className="ico-bocadillo" onClick={() => { this.setState({ modalOpen: !this.state.modalOpen }) }}/> {t(`${tKey}COMMENTS.TITLE_PANEL`)}</h4>
                  </Col>
                  <Col sm={12} className="col-row">
                    <Panel expanded={this.state.modalOpen} onToggle={() => {}}>
                      <Panel.Collapse>
                        <Row>
                          <EditableTable
                            t={t}
                            columnsConfig={
                              [
                                { th: t(`${tKey}COMMENTS.USER_NAME`), key: 'userName', nonEditable: true },
                                { th: t(`${tKey}COMMENTS.COMMENT_TYPE`), key: 'commentType', nonEditable: true },
                                { th: t(`${tKey}COMMENTS.TEXT`), key: 'text', style: { width: '30%' } },
                                { th: t(`${tKey}COMMENTS.CREATION_DATE`), key: 'creationDate', nonEditable: true }
                              ]
                            }
                            dossierType="paperworkDossier"
                            editableObjectsArray={comments}
                            readOnlyFields={true}
                          />
                        </Row>
                      </Panel.Collapse>
                    </Panel>
                  </Col>
                </Col>
              </Row>
            )
          }
        </form>
      </div>
    )
  }
}

var decoratedComponent = TrackingContainer(PaperworkHistorical, false)

export default reduxForm({
  form: 'paperwork_dossier'
})(decoratedComponent)
