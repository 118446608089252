
import React, { PureComponent } from 'react'
import { Modal, Button, Col, Row, FormGroup, ControlLabel } from 'react-bootstrap'
import { reduxForm, Field } from 'redux-form'
import validateUnitsModal from './validation/validateUnitsModal'
import InputSelect from '../../commons/form/InputSelect'
import InputText from '../../commons/form/InputText'

class StageModal extends PureComponent {
  constructor () {
    super()
    this.state = {
      brands: [],
      locations: []
    }
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (!prevProps.showModal && this.props.showModal && this.props.props.isEditModal) {
      const companies = this.props.props.isEditModal.companies.map(val => val.companyId)
      this.props.initialize({ unitName: this.props.props.isEditModal.name, companiesIds: companies })
      const completeCompanies = this.props.props.isEditModal.companies.map(val => {
        return this.props.props.companyList.find(companyList => companyList.companyId === val.companyId)
      })
      this.changeBrandsLocationsArray(completeCompanies)
    } else if (prevProps.showModal && !this.props.showModal) {
      this.props.initialize(null)
      this.setState({ brands: [], locations: [] })
    }

    return null
  }

  componentDidUpdate () {
    // getSnapshotBeforeUpdate() should be used with componentDidUpdate()
  }

  submitNewUnit (data) {
    const companiesIds = data.companiesIds.map(val => val.companyId ? val.companyId : val)
    return new Promise((resolve, reject) => {
      this.props.props.actions.saveNewUnit({ ...data, companiesIds }, this.props.props.isEditModal, resolve, reject)
    })
  }

  changeBrandsLocationsArray (companies) {
    const brands = []
    const locations = []
    if (companies) {
      companies.forEach(company => {
        company.brands.forEach(val => {
          if (val.name && !brands.includes(val.name)) {
            brands.push(val.name)
          }
        })
        company.locations.forEach(val => {
          if (val.description && !locations.includes(val.description)) {
            locations.push(val.description)
          }
        })
      })
    }
    this.setState({ brands, locations })
  }

  render () {
    const { showModal, handleSubmit, error, props: { t, companyList, actions: { closeUnitModal } } } = this.props
    const tKey = 'UNITS.MODAL.'
    return (
      <Modal show={showModal} onHide={closeUnitModal} className="units-modal">
        <form autoComplete="off" onSubmit={handleSubmit(this.submitNewUnit.bind(this))}>
          <FormGroup>
            <Modal.Header closeButton>
              <Modal.Title>{t(`${tKey}TITLE`)}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Field
                  colSm={4}
                  id='unitName'
                  name='unitName'
                  controlLabel={`${t(`${tKey}NAME`)}:*`}
                  placeholder={t(`${tKey}NAME`)}
                  component={InputText}
                />
              </Row>
              <Row>
                <Field
                  colSm={6}
                  controlLabel={`${t(`${tKey}COMPANIES`)}:*`}
                  id='companiesIds'
                  name='companiesIds'
                  options={companyList}
                  labelKey={'name'}
                  valueKey={'companyId'}
                  placeholder={t(`${tKey}COMPANIES`)}
                  component={InputSelect}
                  returnObject
                  onChange={(e, val, prevVal) => this.changeBrandsLocationsArray(val, prevVal)}
                  multi
                />
                <Col sm={6}>
                  <Col sm={6}>
                    <ControlLabel>{`${t(`${tKey}BRANDS`)}:`}</ControlLabel>
                    {
                      this.state.brands.length
                        ? <ul className="modal-uo-list">
                          {this.state.brands.map((val, idx) => <li key={idx}>{val}</li>)}
                        </ul>
                        : <p className="provisional-msg">{t(`${tKey}EMPTY_MESSAGE`)}</p>
                    }
                  </Col>
                  <Col sm={6}>
                    <ControlLabel>{`${t(`${tKey}LOCATIONS`)}:`}</ControlLabel>
                    {
                      this.state.locations.length
                        ? <ul className="modal-uo-list">
                          {this.state.locations.map((val, idx) => <li key={idx}>{val}</li>)}
                        </ul>
                        : <p className="provisional-msg">{t(`${tKey}EMPTY_MESSAGE`)}</p>
                    }
                  </Col>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Col sm={6} className="mandatory-wrapper">
                <p>* {t(`${tKey}MANDATORY`)}</p>
                <div className="global-error">
                  <span>
                    {error && t(error)}
                  </span>
                </div>
              </Col>
              <Col sm={6} className="btn-wrapper">
                <Button id="btn_save" className="btn-standard" type="submit">{t(`${tKey}SAVE_BUTTON`)}</Button>
                <Button id="btn_cancel" onClick={() => closeUnitModal()} className="btn-standard-disabled" >{t(`${tKey}CLOSE_BUTTON`)}</Button>
              </Col>
            </Modal.Footer>
          </FormGroup>
        </form>
      </Modal>
    )
  }
}
export default reduxForm({
  form: 'unitsModalForm',
  validate: validateUnitsModal
})(StageModal)
