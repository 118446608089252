import React, { Component } from 'react'
import { reduxForm, Field } from 'redux-form'
import { Button, Modal, Col, Row, Form, Tab, Tabs } from 'react-bootstrap'
import settings from '../../../../setting'
import InputText from '../../../commons/form/InputText'
import InputMoney from '../../../commons/form/InputMoney'
import InputSelect from '../../../commons/form/InputSelect'
import InputCheckBox from '../../../commons/form/InputCheckBox'
import InputDatePicker from '../../../commons/form/InputDatePicker'
import InputPercentage from '../../../commons/form/InputPercentage'
import { campaignType, campaignField } from '../../../../constants/dossier/common/campaignType'
import { validateCampaignAmount, validateCampaignPercentage, validateCampaignTotalAmount, validateCampaignTotalPercentage, validateDate } from '../../../../util/validationFunctions'
import { permissions } from '../../../../constants/backendIds'
import { getFieldsPermisions } from '../../../../util/permisionFunctions'
import CampaignsPopover from '../../common/CampaignsPopover'

class CampaignsModal extends Component {
  constructor (props) {
    super(props)
    this.state = {
      typeId: props.editedCampaign && props.editedCampaign.typeId || null,
      dossierSubTypeId: props.editedCampaign && props.editedCampaign.dossierSubTypeId || null,
      cost: 0,
      costConcession: 0,
      costPercentage: 0,
      costConcessionPercentage: 0,
      additionalAmount: 0,
      amount: 0,
      tabActiveKey: 1,
      fieldsConfiguration: {
        costConcession: {
          permission: null
        },
        amountPercentage: {
          permission: null
        },
        costPercentage: {
          permission: null
        },
        costConcessionPercentage: {
          permission: null
        },
        popOverCampaings: {
          permission: null
        },
        name: {
          permission: null
        },
        amount: {
          permission: null
        },
        cost: {
          permission: null
        },
        expirationDate: {
          permission: null
        },
        documentIds: {
          permission: null
        },
        supInvoice: {
          permission: null
        },
        dossierSubTypeId: {
          permission: null
        },
        provider: {
          permission: null
        },
        additionalAmount: {
          permission: null
        },
        additionalPercentage: {
          permission: null
        }
      }
    }
  }

  initializePermisions (props) {
    const fieldKeys = Object.keys(this.state.fieldsConfiguration)
    const newState = getFieldsPermisions(props.componentConfiguration.fieldsConfiguration, fieldKeys, props.componentConfiguration.permission)
    this.setState({ fieldsConfiguration: newState.fieldsConfiguration })
  }

  componentDidMount () {
    if (this.props.componentConfiguration) {
      this.initializePermisions(this.props)
    } else {
      this.setState({ permission: 0 })
    }
  }

  onDossierSubTypeIdChanges (dossierSubTypeId) {
    this.setState({ dossierSubTypeId })
  }

  submitCampaign (values) {
    values.form = 'campaign_modal'
    if (this.state.tabActiveKey === 1) {
      values.costPercentage = null
      values.costConcessionPercentage = null
      values.amountPercentage = null
      values.additionalPercentage = null
    } else {
      values.cost = null
      values.costConcession = null
      values.amount = null
      values.additionalAmount = null
    }
    if (values.dossierSubTypeId === campaignType.CAMPAIGN) {
      values.provider = null
      values.supInvoice = null
    }
    if (values.campaignId) {
      this.props.actions.updateCampaign(values, this.props.actions.closeModal)
    } else {
      this.props.actions.createCampaign(values, this.props.actions.closeModal)
    }
  }

  setAmount (amountPercentage) {
    if (this.state.fieldsConfiguration.amount.permission !== permissions.hidden) {
      this.setValue(campaignField.AMOUNT, amountPercentage)
    }
  }

  setCost (costPercentage) {
    if (this.state.fieldsConfiguration.cost.permission !== permissions.hidden) {
      let cost = this.setValue(campaignField.COST, costPercentage)
      this.setState({ cost: cost })
      this.calculateAmount(cost, this.state.costConcession, this.state.additionalAmount)
    }
  }

  setCostConcession (costConcessionPercentage) {
    if (this.state.fieldsConfiguration.costConcession.permission !== permissions.hidden) {
      let costConcession = this.setValue(campaignField.COST_CONCESSION, costConcessionPercentage)
      this.setState({ costConcession: costConcession })
      this.calculateAmount(this.state.cost, costConcession, this.state.additionalAmount)
    }
  }

  setAdditionalAmount (additionalPercentage) {
    if (this.state.fieldsConfiguration.additionalAmount.permission !== permissions.hidden) {
      const additionalAmount = this.setValue(campaignField.ADDITIONAL_AMOUNT, additionalPercentage)
      this.setState({ additionalAmount: additionalAmount })
      this.calculateAmount(this.state.cost, this.state.costConcession, additionalAmount)
    }
  }

  setValue (field, value) {
    let total = 0
    if (this.props.vehicleAmount) {
      total = (((this.props.vehicleAmount) * (value ?? 0)) / 100).toFixed(2)
    }
    this.props.change(field, total)
    return total
  }

  setPercentage (field, value) {
    let total = 0
    if (this.props.vehicleAmount) {
      total = ((value * 100) / (this.props.vehicleAmount)).toFixed(2)
      this.props.change(field, total)
    } else if (this.props.editedCampaign && (this.props.editedCampaign.costPercentage || this.props.editedCampaign.costConcessionPercentage)) {
      total = this.getPercentage(this.props.editedCampaign.costPercentage || 0, this.props.editedCampaign.costConcessionPercentage || 0, this.props.editedCampaign.additionalPercentage)
    }
  }

  shouldComponentUpdate (nextProps, nextState) {
    return JSON.stringify(nextProps) !== JSON.stringify(this.props) || JSON.stringify(nextState) !== JSON.stringify(this.state)
  }

  getSnapshotBeforeUpdate (prevProps, prevState) {
    return null
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.editedCampaign && prevProps.editedCampaign && prevProps.editedCampaign.dossierSubTypeId !== this.props.editedCampaign.dossierSubTypeId && this.state.dossierSubTypeId !== this.props.editedCampaign.dossierSubTypeId) {
      this.setState({ dossierSubTypeId: this.props.editedCampaign.dossierSubTypeId })
    }
    if (this.props.editedCampaign && prevProps.editedCampaign && prevProps.editedCampaign.typeId !== this.props.editedCampaign.typeId && this.state.typeId !== this.props.editedCampaign.typeId) {
      this.setState({ typeId: this.props.editedCampaign.typeId })
    }

    if (!prevProps.showModal && this.props.showModal) {
      this.initializePermisions(this.props)
      prevProps.initialize(this.props.campaignSelected)
      const cost = this.props.campaignSelected && this.props.campaignSelected.cost ? this.props.campaignSelected.cost : 0
      const amount = this.props.campaignSelected && this.props.campaignSelected.amount ? this.props.campaignSelected.amount : 0
      const additionalAmount = this.props.campaignSelected && this.props.campaignSelected.additionalAmount ? this.props.campaignSelected.additionalAmount : 0
      const costConcession = this.getCostConcession(cost, amount, additionalAmount)
      const costPercentage = this.props.campaignSelected.costPercentage ?? 0
      const costConcessionPercentage = this.props.campaignSelected?.costConcessionPercentage ?? 0
      const additionalPercentage = this.props.campaignSelected?.additionalPercentage ?? 0
      this.setState({ cost: cost, costConcession: costConcession, additionalAmount: additionalAmount })
      if (this.state.fieldsConfiguration.costConcession.permission !== permissions.hidden && costConcession > 0) {
        this.props.change('costConcession', costConcession)
      }
      if (this.props.showPercentages) {
        const tabActiveKey = this.getTabActiveKey(this.props.campaignSelected)
        this.setState({ tabActiveKey: tabActiveKey })
        if (tabActiveKey === 2) {
          new Promise((resolve, reject) => {
            this.setCostConcession(costConcessionPercentage)
            resolve()
          }).then(() => {
            this.setCost(costPercentage)
          }).then(() => {
            this.setAdditionalAmount(additionalPercentage)
          })
        } else {
          this.setPercentage(campaignField.AMOUNT_PERCENTAGE, amount)
          this.setPercentage(campaignField.COST_PERCENTAGE, cost)
          this.setPercentage(campaignField.COST_CONCESSION_PERCENTAGE, costConcession)
          this.setPercentage(campaignField.ADDITIONAL_PERCENTAGE, additionalAmount)
        }
      }
    }
    if (prevProps.showModal && !this.props.showModal) {
      this.props.reset('campaign_modal')
    }
  }

  getTabActiveKey (campaignSelected) {
    return (campaignSelected && (campaignSelected.costPercentage || campaignSelected.costConcessionPercentage ||
      campaignSelected.amountPercentage || campaignSelected.additionalPercentage)) ? 2 : 1
  }

  getCostConcession (cost, amount, additionalAmount) {
    cost = cost || 0
    amount = amount || 0
    additionalAmount = additionalAmount || 0
    return parseFloat(amount) - parseFloat(cost) - parseFloat(additionalAmount)
  }

  getPercentage (costPercentage, costConcessionPercentage, additionalPercentage) {
    costPercentage = costPercentage || 0
    costConcessionPercentage = costConcessionPercentage || 0
    additionalPercentage = additionalPercentage || 0
    return parseFloat(costPercentage) + parseFloat(costConcessionPercentage) + parseFloat(additionalPercentage)
  }

  calculateAmount (cost, costConcession, additionalAmount) {
    cost = cost || 0
    costConcession = costConcession || 0
    additionalAmount = additionalAmount || 0
    const amount = parseFloat(cost) + parseFloat(costConcession) + parseFloat(additionalAmount)
    if (this.state.fieldsConfiguration.amount.permission !== permissions.hidden) {
      this.props.change('amount', amount)
      this.handleBlurAmount(amount)
    }
  }

  handleBlurCost (cost) {
    if (this.state.fieldsConfiguration.cost.permission !== permissions.hidden) {
      this.setState({ cost: cost })
      this.calculateAmount(cost, this.state.costConcession, this.state.additionalAmount)
    }
    if (this.state.fieldsConfiguration.costPercentage.permission !== permissions.hidden) {
      this.setPercentage(campaignField.COST_PERCENTAGE, cost)
    }
  }

  handleBlurCostConcession (costConcession) {
    if (this.state.fieldsConfiguration.costConcession.permission !== permissions.hidden) {
      this.setState({ costConcession: costConcession })
      this.calculateAmount(this.state.cost, costConcession, this.state.additionalAmount)
    }
    if (this.state.fieldsConfiguration.costConcessionPercentage.permission !== permissions.hidden) {
      this.setPercentage(campaignField.COST_CONCESSION_PERCENTAGE, costConcession)
    }
  }

  handleBlurAmount (amount) {
    if (this.state.fieldsConfiguration.amountPercentage.permission !== permissions.hidden) {
      this.setPercentage(campaignField.AMOUNT_PERCENTAGE, amount)
    }
  }

  handleBlurAdditionalAmount (additionalAmount) {
    this.setState({ additionalAmount: additionalAmount })
    this.calculateAmount(this.state.cost, this.state.costConcession, additionalAmount)
    if (this.state.fieldsConfiguration.additionalPercentage.permission !== permissions.hidden) {
      this.setPercentage(campaignField.ADDITIONAL_PERCENTAGE, additionalAmount)
    }
  }

  handleBlurAmountPercentage (amountPercentage) {
    this.setAmount(amountPercentage)
  }

  handleBlurCostPercentage (costPercentage) {
    this.setCost(costPercentage)
  }

  handleBlurCostConcessionPercentage (costConcessionPercentage) {
    this.setCostConcession(costConcessionPercentage)
  }

  handleBlurAdditionalPercentage (additionalPercentage) {
    this.setAdditionalAmount(additionalPercentage)
  }

  validateDate (value, allValues, props) {
    const result = validateDate(value, props)
    return result === Object(result) ? undefined : result
  }

  validatePercentage (value) {
    if (this.state.tabActiveKey === 2) {
      return validateCampaignPercentage(value, this.props.t)
    } else { return undefined }
  }

  validateAmount (value) {
    if (this.state.tabActiveKey === 1) {
      return validateCampaignAmount(value, this.props.vehicleAmount, this.props.t)
    } else { return undefined }
  }

  validateTotalAmount (value) {
    if (this.state.tabActiveKey === 1) {
      return validateCampaignTotalAmount(value, this.props.vehicleAmount, this.props.t)
    } else { return undefined }
  }

  validateTotalPercentage (value) {
    if (this.state.tabActiveKey === 2) {
      return validateCampaignTotalPercentage(value, this.props.t)
    } else { return undefined }
  }

  render () {
    const { t, showModal, handleSubmit, showPercentages, actions: { closeModal }, combos: { campaignsTypes, documentsTypes, dossierSubTypesCombo }, secondClass } = this.props
    const tKey = 'DOSSIER_COMPONENTS.CAMPAIGNS.CAMPAIGNS_MODAL.'
    return (
      <Modal show={showModal} onHide={() => closeModal()} backdrop={'static'} className={secondClass ?? ''}>
        <Form autoComplete="off" onSubmit={handleSubmit(this.submitCampaign.bind(this))}>
          <Modal.Header closeButton>
            <Modal.Title>{t(`${tKey}TITLE`)}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              {this.state.fieldsConfiguration.name.permission !== permissions.hidden && (
                <Field
                  id="name"
                  name="name"
                  colSm={this.state.fieldsConfiguration.amountPercentage.permission !== permissions.hidden ? 12 : 6}
                  controlLabel={t(`${tKey}NAME`)}
                  placeholder={t(`${tKey}NAME`)}
                  component={InputText}
                />
              )}

              {showPercentages && this.state.fieldsConfiguration.amountPercentage.permission !== permissions.hidden ? (
                <div className="clearfix"/>,
                <Tabs
                  id="1"
                  defaultActiveKey={1}
                  activeKey={this.state.tabActiveKey}
                  onSelect={tabActiveKey => this.setState({ tabActiveKey })}>
                  <Tab eventKey={1} title={t(`${tKey}INTRODUCE_AMOUNT`)} className="dossier-tab">
                    <Row className='flexRow'>
                      {this.state.fieldsConfiguration.amount.permission !== permissions.hidden && (
                        <Field
                          id="amount_amount"
                          name="amount"
                          type="text"
                          colSm={6}
                          controlLabel={t(`${tKey}AMOUNT`)}
                          placeholder={t(`${tKey}AMOUNT`)}
                          component={InputMoney}
                          disabled={this.state.fieldsConfiguration.costConcession.permission !== permissions.hidden}
                          onInputBlur={(event) => this.handleBlurAmount(event)}
                          validate={(value) => this.validateTotalAmount(value)}
                        />
                      )}
                      {this.state.fieldsConfiguration.amountPercentage.permission !== permissions.hidden && (
                        <Field
                          id="amount_amountPercentage"
                          name="amountPercentage"
                          type="text"
                          colSm={6}
                          controlLabel={t(`${tKey}AMOUNT_PERCENTAGE`)}
                          placeholder={t(`${tKey}AMOUNT_PERCENTAGE`)}
                          component={InputPercentage}
                          popOver={ this.state.fieldsConfiguration.popOverCampaings.permission !== permissions.hidden ? <CampaignsPopover id="campaignsPopover" name="campaignsPopover" key="campaignsPopover" t={t} text={t(`${tKey}AMOUNT_PERCENTAGE`) } /> : null}
                          disabled={true}
                        />
                      )}
                      {this.state.fieldsConfiguration.cost.permission !== permissions.hidden && (
                        <Field
                          id="amount_cost"
                          name="cost"
                          type="text"
                          colSm={6}
                          controlLabel={t(`${tKey}COST`)}
                          placeholder={t(`${tKey}COST`)}
                          component={InputMoney}
                          onInputBlur={(event) => this.handleBlurCost(event)}
                          validate={ (value) => this.validateAmount(value)}
                        />
                      )}
                      {this.state.fieldsConfiguration.costPercentage.permission !== permissions.hidden && (
                        <Field
                          id="amount_costPercentage"
                          name="costPercentage"
                          type="text"
                          colSm={6}
                          controlLabel={t(`${tKey}COST_PERCENTAGE`)}
                          placeholder={t(`${tKey}COST_PERCENTAGE`)}
                          component={InputPercentage}
                          disabled={true}
                        />
                      )}
                      {this.state.fieldsConfiguration.costConcession.permission !== permissions.hidden && (
                        <Field
                          id="amount_costConcession"
                          name="costConcession"
                          type="text"
                          colSm={6}
                          controlLabel={t(`${tKey}COST_CONCESSION`)}
                          placeholder={t(`${tKey}COST_CONCESSION`)}
                          component={InputMoney}
                          onInputBlur={(event) => this.handleBlurCostConcession(event)}
                          validate={(value) => this.validateAmount(value)}
                        />)}
                      {this.state.fieldsConfiguration.costConcessionPercentage.permission !== permissions.hidden &&
                      this.state.fieldsConfiguration.costConcession.permission !== permissions.hidden && (
                        <Field
                          id="amount_costConcessionPercentage"
                          name="costConcessionPercentage"
                          type="text"
                          colSm={6}
                          controlLabel={t(`${tKey}COST_CONCESSION_PERCENTAGE`)}
                          placeholder={t(`${tKey}COST_CONCESSION_PERCENTAGE`)}
                          component={InputPercentage}
                          disabled={true}
                        />)}
                      {this.state.fieldsConfiguration.additionalAmount.permission !== permissions.hidden && (
                        <Field
                          id="amount_additionalAmount"
                          name="additionalAmount"
                          type="text"
                          colSm={6}
                          controlLabel={t(`${tKey}ADDITIONAL_AMOUNT`) }
                          placeholder={t(`${tKey}ADDITIONAL_AMOUNT`) }
                          component={InputMoney}
                          onInputBlur={(event) => this.handleBlurAdditionalAmount(event)}
                          validate={(value) => this.validateAmount(value)}
                        />)}
                      {this.state.fieldsConfiguration.additionalPercentage.permission !== permissions.hidden && (
                        <Field
                          id="amount_additionalPercentage"
                          name="additionalPercentage"
                          type="text"
                          colSm={6}
                          controlLabel={t(`${tKey}ADDITIONAL_PERCENTAGE`) }
                          placeholder={t(`${tKey}ADDITIONAL_PERCENTAGE`) }
                          component={InputPercentage}
                          disabled={true}
                        />)}
                    </Row>
                  </Tab>
                  <Tab eventKey={2} title={t(`${tKey}INTRODUCE_PERCENTAGE`)} className="dossier-tab">
                    <Row className='flexRow'>
                      {this.state.fieldsConfiguration.amount.permission !== permissions.hidden && (
                        <Field
                          id="amount"
                          name="amount"
                          type="text"
                          colSm={6}
                          controlLabel={t(`${tKey}AMOUNT`)}
                          placeholder={t(`${tKey}AMOUNT`)}
                          component={InputMoney}
                          disabled={true}
                        />
                      )}
                      {this.state.fieldsConfiguration.amountPercentage.permission !== permissions.hidden && (
                        <Field
                          id="amountPercentage"
                          name="amountPercentage"
                          type="text"
                          colSm={6}
                          controlLabel={t(`${tKey}AMOUNT_PERCENTAGE`)}
                          placeholder={t(`${tKey}AMOUNT_PERCENTAGE`)}
                          component={InputPercentage}
                          popOver={ this.state.fieldsConfiguration.popOverCampaings.permission !== permissions.hidden ? <CampaignsPopover id="campaignsPopover" name="campaignsPopover" key="campaignsPopover" t={t} text={t(`${tKey}AMOUNT_PERCENTAGE`) } /> : null}
                          disabled={this.state.fieldsConfiguration.costConcession.permission !== permissions.hidden}
                          onInputBlur={(component) => this.handleBlurAmountPercentage(component)}
                          validate={(value) => this.validateTotalPercentage(value)}
                        />
                      )}
                      {this.state.fieldsConfiguration.cost.permission !== permissions.hidden && (
                        <Field
                          id="cost"
                          name="cost"
                          type="text"
                          colSm={6}
                          controlLabel={t(`${tKey}COST`)}
                          placeholder={t(`${tKey}COST`)}
                          component={InputMoney}
                          disabled={true}
                        />
                      )}
                      {this.state.fieldsConfiguration.costPercentage.permission !== permissions.hidden && (
                        <Field
                          id="costPercentage"
                          name="costPercentage"
                          type="text"
                          colSm={6}
                          controlLabel={t(`${tKey}COST_PERCENTAGE`)}
                          placeholder={t(`${tKey}COST_PERCENTAGE`)}
                          component={InputPercentage}
                          onInputBlur={(component) => this.handleBlurCostPercentage(component)}
                          validate={(value) => this.validatePercentage(value)}
                        />
                      )}
                      {this.state.fieldsConfiguration.costConcession.permission !== permissions.hidden && (
                        <Field
                          id="costConcession"
                          name="costConcession"
                          type="text"
                          colSm={6}
                          controlLabel={t(`${tKey}COST_CONCESSION`)}
                          placeholder={t(`${tKey}COST_CONCESSION`)}
                          component={InputMoney}
                          disabled={true}
                        />)}
                      {this.state.fieldsConfiguration.costConcessionPercentage.permission !== permissions.hidden &&
                      this.state.fieldsConfiguration.costConcession.permission !== permissions.hidden && (
                        <Field
                          id="costConcessionPercentage"
                          name="costConcessionPercentage"
                          type="text"
                          colSm={6}
                          controlLabel={t(`${tKey}COST_CONCESSION_PERCENTAGE`)}
                          placeholder={t(`${tKey}COST_CONCESSION_PERCENTAGE`)}
                          component={InputPercentage}
                          onInputBlur={(component) => this.handleBlurCostConcessionPercentage(component)}
                          validate={(value) => this.validatePercentage(value)}
                        />)}
                      {this.state.fieldsConfiguration.additionalAmount.permission !== permissions.hidden && (
                        <Field
                          id="additionalAmount"
                          name="additionalAmount"
                          type="text"
                          colSm={6}
                          controlLabel={t(`${tKey}ADDITIONAL_AMOUNT`) }
                          placeholder={t(`${tKey}ADDITIONAL_AMOUNT`)}
                          component={InputMoney}
                          disabled={true}
                        />
                      )}
                      {this.state.fieldsConfiguration.additionalPercentage.permission !== permissions.hidden && (
                        <Field
                          id="additionalPercentage"
                          name="additionalPercentage"
                          type="text"
                          colSm={6}
                          controlLabel={t(`${tKey}ADDITIONAL_PERCENTAGE`) }
                          placeholder={t(`${tKey}ADDITIONAL_PERCENTAGE`)}
                          component={InputPercentage}
                          onInputBlur={(component) => this.handleBlurAdditionalPercentage(component)}
                          validate={(value) => this.validatePercentage(value)}
                        />
                      )}
                    </Row>
                  </Tab>
                </Tabs>) : ([
                this.state.fieldsConfiguration.amount.permission !== permissions.hidden && (
                  <Field
                    id="amount"
                    key="amount"
                    name="amount"
                    type="text"
                    colSm={6}
                    controlLabel={t(`${tKey}AMOUNT`)}
                    placeholder={t(`${tKey}AMOUNT`)}
                    component={InputMoney}
                  />
                ),
                this.state.fieldsConfiguration.cost.permission !== permissions.hidden && (
                  <Field
                    id="cost"
                    key="cost"
                    name="cost"
                    type="text"
                    colSm={6}
                    controlLabel={t(`${tKey}COST`)}
                    placeholder={t(`${tKey}COST`)}
                    component={InputMoney}
                  />
                )
              ])}
              {this.state.fieldsConfiguration.dossierSubTypeId.permission !== permissions.hidden && (
                <Field
                  colSm={6}
                  id="dossierSubTypeId"
                  name="dossierSubTypeId"
                  controlLabel={t(`${tKey}CAMPAIGN_TYPE`)}
                  placeholder={t(`${tKey}CAMPAIGN_TYPE`)}
                  component={InputSelect}
                  options={dossierSubTypesCombo}
                  valueKey="id"
                  labelKey="value"
                  onInputChange={this.onDossierSubTypeIdChanges.bind(this)}
                  disabled={!!(this.props.editedCampaign && this.props.editedCampaign.campaignId)}
                />
              )}
              {(settings.clientEnviroment !== 'quadis' || settings.clientEnviroment === 'quadis' && this.state.dossierSubTypeId === campaignType.CAMPAIGN) ? (
                <Field
                  colSm={6}
                  id="typeId"
                  name="typeId"
                  controlLabel={t(`${tKey}CAMPAIGN_SUBTYPE`)}
                  placeholder={t(`${tKey}CAMPAIGN_SUBTYPE`)}
                  component={InputSelect}
                  options={campaignsTypes}
                  valueKey="id"
                  labelKey="value"
                />
              ) : null }
              {this.state.dossierSubTypeId !== campaignType.CAMPAIGN ? (
                this.state.fieldsConfiguration.provider.permission !== permissions.hidden && (
                  <Field
                    id="provider"
                    name="provider"
                    colSm={6}
                    controlLabel={t(`${tKey}PROVIDER`)}
                    placeholder={t(`${tKey}PROVIDER`)}
                    component={InputText}
                  />
                )
              ) : (
                this.state.fieldsConfiguration.expirationDate.permission !== permissions.hidden && (
                  <Field
                    id="expirationDate"
                    name="expirationDate"
                    colSm={6}
                    controlLabel={t(`${tKey}EXPIRATION_DATE`)}
                    component={InputDatePicker}
                    validate={[this.validateDate]}
                  />
                )
              )}
              {this.state.fieldsConfiguration.documentIds.permission !== permissions.hidden && (
                <Field
                  colSm={6}
                  id="documentIds"
                  name="documentIds"
                  controlLabel={t(`${tKey}CHECKLIST`)}
                  placeholder={t(`${tKey}CHECKLIST`)}
                  component={InputSelect}
                  options={documentsTypes}
                  valueKey="id"
                  labelKey="value"
                  multi
                  placement="top"
                />
              )}
              { this.state.dossierSubTypeId !== campaignType.CAMPAIGN && ([
                this.state.fieldsConfiguration.expirationDate.permission !== permissions.hidden && (
                  <Field
                    key="expirationDate"
                    id="expirationDate"
                    name="expirationDate"
                    colSm={6}
                    controlLabel={t(`${tKey}EXPIRATION_DATE`)}
                    component={InputDatePicker}
                    validate={[this.validateDate]}
                    placement="top"
                  />

                ),
                <Col sm={6} className="col-row input-group-row" key="supInvoice">
                  {this.state.fieldsConfiguration.supInvoice.permission !== permissions.hidden && (
                    <Field
                      id="supInvoice"
                      name="supInvoice"
                      colSm={12}
                      inline
                      placeholder={t(`${tKey}SUPPLEMENTARY_INVOICE`)}
                      component={InputCheckBox}
                    />
                  )}
                </Col>
              ])}
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Col sm={6} className="btn-wrapper">
              <Button id="btn_save" className="btn-standard" type="submit">{t(`${tKey}SAVE_BTN`)}</Button>
            </Col>
            <Col sm={6} className="btn-wrapper">
              <Button id="btn_cancel" className="btn-standard btn-cancel-modal" onClick={() => closeModal()}>{t(`${tKey}CLOSE_BTN`)}</Button>
            </Col>
          </Modal.Footer>
        </Form>
      </Modal>
    )
  }
}

const validate = (values, props) => {
  const tKey = 'DOSSIER_COMPONENTS.CAMPAIGNS.CAMPAIGNS_MODAL.VALIDATION.'
  const error = {}
  if (!values.dossierSubTypeId) error.dossierSubTypeId = props.t(`${tKey}FILL_CAMPAIGN_TYPE`)
  return error
}

export default reduxForm({
  form: 'campaign_modal',
  validate
})(CampaignsModal)
