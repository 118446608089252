import mastersActionTypes from '../../../constants/actions/masters/masters'

export function deleteOperationType (operationTypeId) {
  return {
    type: mastersActionTypes.DELETE_OPERATION_TYPE,
    operationTypeId
  }
}

export function recoverOperationType (operationTypeId) {
  return {
    type: mastersActionTypes.RECOVER_OPERATION_TYPE,
    operationTypeId
  }
}

export function fetchOperationTypesTable (filter) {
  return {
    type: mastersActionTypes.FETCH_OPERATION_TYPES_TABLE,
    filter
  }
}

export function openOperationTypeModal (operationTypeId) {
  return {
    type: mastersActionTypes.OPEN_OPERATION_TYPE_MODAL,
    operationTypeId
  }
}

export function closeOperationTypeModal () {
  return {
    type: mastersActionTypes.CLOSE_OPERATION_TYPE_MODAL
  }
}

export function fetchOperationTypesTableSuccess (operationTypeTable, filter) {
  return {
    type: mastersActionTypes.FETCH_OPERATION_TYPES_TABLE_SUCCESS,
    operationTypeTable,
    filter
  }
}

export function openOperationTypeModalSuccess (operationType, languageList) {
  return {
    type: mastersActionTypes.OPEN_OPERATION_TYPE_MODAL_SUCCESS,
    operationType,
    languageList
  }
}

export function saveOperationType (operationType) {
  return {
    type: mastersActionTypes.SAVE_OPERATION_TYPE,
    operationType
  }
}
