import { put, call, select, takeEvery } from 'redux-saga/effects'
import { getAuth } from '../../selectors/access/auth'
import { showLoader, hideLoader } from '../../actions/common'
import messageModalActions from '../../actions/modals/messageModal'
import dashboardTaskActionTypes from '../../constants/actions/dashboard/dashboardTask'
import getTaskValuesToExcel from '../../services/task/getTaskToExcel'
import { handleError } from '../errors/errorManager'

export function * getFilteredTaskToExcel ({ activeTaskId, orderby, urgencyTypeTask }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(
      getTaskValuesToExcel,
      auth.token,
      activeTaskId,
      orderby,
      urgencyTypeTask,
      auth.organizedUnit
    )
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
    }
    yield call(handleError, { error, customHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchGetFilteredTaskToExcel () {
  yield takeEvery(dashboardTaskActionTypes.EXPORT_TASK_TO_EXCEL, getFilteredTaskToExcel)
}
