import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import AuditAdminDocumentModal from '../../../components/management/AuxModals/AuditAdminDocumentModal'
import { fetchDocumentTypeCombo, fetchDocumentsAllTypes, fetchDocumentTypeEntities, fetchAuditLoadData, fetchDossierSubTypes, fetchAuditConfigurationType, fetchDossierTypes } from '../../../actions/combos/combos'
import { fetchDocumentTypeUsesInfo } from '../../../actions/management/management'

export function mapStateToProps (state) {
  return {
    ...state.management.documentTypeUse,
    combos: {
      ...state.combos
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchDocumentTypeUsesInfo,
      fetchDocumentsAllTypes,
      fetchDocumentTypeEntities,
      fetchAuditLoadData,
      fetchDossierSubTypes,
      fetchDossierTypes,
      fetchAuditConfigurationType,
      fetchDocumentTypeCombo
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(AuditAdminDocumentModal))
