import React, { PureComponent } from 'react'
import { translate } from 'react-polyglot'
import { Button, OverlayTrigger, Tooltip, Glyphicon, Col } from 'react-bootstrap'
import EditableTextCeil from './EditableTextCeil'
import EditableMoneyCeil from './EditableMoneyCeil'
import EditableCheckboxCeil from './EditableCheckboxCeil'
import EditableSelectCeil from './EditableSelectCeil'
import { associatedCostsCodes, associatedCostsColumnKeys } from '../../../constants/backendIds'
import { documentEntityType as documentEntityTypeConstants } from '../../../constants/dossier/common/documentEntityType'
import { documentStatusClass, translateDocumentStatus } from '../../../constants/dossier/common/documentStatus'
import { referenceTypes } from '../../../constants/document/documentTypeUse'

class EditableRow extends PureComponent {
  constructor (props) {
    super(props)
    const columnsConfig = (props.columnsConfig ? props.columnsConfig.filter(column => column.showColumn !== false) : [])
    this.state = {
      stateObject: this.setStateEditableObjectFromProps(props),
      editableObject: props.editableObject || {},
      edit: false,
      fields: this.parseFields(columnsConfig),
      columnsConfig: columnsConfig,
      optionalsAmount: 0
    }
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (prevProps.editableObject !== this.props.editableObject) {
      this.setState({ ...this.state, stateObject: this.setStateEditableObjectFromProps(this.props), editableObject: this.props.editableObject, fields: this.parseFields(this.props.columnsConfig) })
    }
    if (JSON.stringify(prevProps.columnsConfig) !== JSON.stringify(this.props.columnsConfig)) {
      let newStateObject = this.state.stateObject
      if (this.props.editableObject) {
        this.props.columnsConfig.forEach(column => {
          if (column.isSelectCompuesto) {
            const selectedOption = column.selectConfig.options.find(option => option.id === this.props.editableObject[column.key])
            const relatedColumnIndex = this.props.columnsConfig.findIndex(relatedColumn => relatedColumn.key === column.keyColumnaAsociada)
            if (selectedOption.typeView && selectedOption.typeView === 1) {
              this.props.columnsConfig[relatedColumnIndex].nonEditable = false
            } else {
              this.props.columnsConfig[relatedColumnIndex].nonEditable = true
            }
          }
        })
        newStateObject = this.setStateEditableObjectFromProps(this.props)
      }
      this.setState({ columnsConfig: this.props.columnsConfig.filter(column => column.showColumn !== false), stateObject: newStateObject })
    }
    return null
  }

  componentDidUpdate () {
    // getSnapshotBeforeUpdate() should be used with componentDidUpdate()
  }

  parseFields (columnsConfig) {
    const fields = {}
    for (var i = columnsConfig.length - 1; i >= 0; i--) {
      fields[columnsConfig[i].key] = { hasError: false }
    }
    return fields
  }

  validateFields (values) {
    const result = { fields: {}, countErrors: 0 }
    let hasError
    for (var i = this.state.columnsConfig.length - 1; i >= 0; i--) {
      if (this.state.columnsConfig[i].validationFunc && this.state.columnsConfig[i].disabled !== true && this.state.columnsConfig[i].nonEditable !== true) {
        hasError = !this.state.columnsConfig[i].validationFunc(values[this.state.columnsConfig[i].key])
        if (hasError) result.countErrors++
        result.fields[this.state.columnsConfig[i].key] = {
          hasError: hasError
        }
      }
    }
    return result
  }

  setStateEditableObjectFromProps (props) {
    const stateObject = {}
    props.columnsConfig.forEach(column => {
      if (!column.nonEditable) {
        stateObject[column.key] = props.createRowOption ? '' : props.editableObject[column.key]
        column.marcado === true ? (
          stateObject.iem = props.editableObject && props.editableObject.iem !== undefined && props.editableObject.iem !== null ? props.editableObject.iem : true,
          stateObject.supInvoice = props.editableObject && props.editableObject.supInvoice !== undefined && props.editableObject.supInvoice !== null ? props.editableObject.supInvoice : false
        ) : null
      }
    })
    return stateObject
  }

  onLoad (initialVal, callback) {
    if (callback) callback(initialVal)
  }

  onInputChange (newVal, key, callback, ref, pos) {
    new Promise((resolve, reject) => {
      if (callback) callback(newVal, key, resolve, reject)
      else resolve()
    }).then(() => {
      const stateObj = JSON.parse(JSON.stringify(this.state.stateObject))
      const newStateObject = { ...stateObj, [key]: newVal }
      if (newStateObject.supInvoice === false) {
        newStateObject.iem = true
      }
      this.setState({ ...this.state, stateObject: newStateObject }, () => {
        if (ref && ref.selectionStart && ref.selectionEnd) {
          ref.selectionStart = pos
          ref.selectionEnd = pos
        }
      })
    })
  }

  onInputChangeExtraSale (newVal, key) {
    const newStateObject = { ...this.state.stateObject, [key]: newVal }
    if (newStateObject.supInvoice === false) {
      newStateObject.iem = true
    }
    this.setState({ ...this.state, stateObject: newStateObject })
  }

  onInputChangeSelectCompuesto (newVal, key) {
    const updatedColumnsConfig = this.state.columnsConfig
    const columnaAsociada = updatedColumnsConfig.find((column) => column.key === key)
    const selectedOption = columnaAsociada.selectConfig.options.find(option => option.id === newVal)
    const keyColumnaAsociada = columnaAsociada.keyColumnaAsociada
    const indexColumnaAsociada = updatedColumnsConfig.findIndex((column) => column.key === keyColumnaAsociada)
    updatedColumnsConfig[indexColumnaAsociada].nonEditable = !(selectedOption && selectedOption.typeView === 1)
    let newStateObject = { ...this.state.stateObject, [key]: newVal, [keyColumnaAsociada]: ((selectedOption && selectedOption.typeView === 1) ? this.state.stateObject[keyColumnaAsociada] : '') }

    if (key === this.getRappelColumnName() && this.isRappelSelected(newVal)) {
      newStateObject = { ...newStateObject, [associatedCostsColumnKeys.COST]: '0.00' }
    }

    this.setState({
      ...this.state,
      stateObject: newStateObject,
      columnsConfig: updatedColumnsConfig
    })
  }

  isRappelToPay () {
    return this.isRappelSelected(this.state?.stateObject?.typeId)
  }

  isRappelSelected (typeId) {
    if (!typeId) { return false }
    let rappelColumnName = this.getRappelColumnName()
    let typeIdIsRappel = this.state.columnsConfig
      .find((column) => column.key === rappelColumnName)?.selectConfig?.options
      .find(option => option.id === typeId)?.code === associatedCostsCodes.RAPPEL_TO_PAY
    return typeIdIsRappel
  }

  getRappelColumnName () {
    return this.state.columnsConfig
      .find((column) => {
        if (column.selectConfig?.options && Array.isArray(column.selectConfig?.options)) {
          return column.selectConfig?.options.find(o => o.code === associatedCostsCodes.RAPPEL_TO_PAY)
        }
      })?.key
  }

  editEdition () {
    if (!this.props.takeCareEdition) {
      this.setState({ ...this.state, edit: true })
      if (this.props.editAction) this.props.editAction()
      if (this.props.takeCareAction) this.props.takeCareAction()
    }
  }

  cancelEdition () {
    this.setState({ stateObject: this.setStateEditableObjectFromProps(this.props), edit: false })
    if (this.props.takeCareAction) this.props.takeCareAction()
  }

  updateActionPromise () {
    const updatedObject = {
      ...this.state.editableObject,
      ...this.state.stateObject
    }
    return new Promise((resolve, reject) => {
      return this.props.updateAction(updatedObject, resolve, reject, this.props.dossierType)
    })
  }

  updateAction () {
    let validationResult = this.validateFields(this.state.stateObject)
    if (this.isRappelToPay() && validationResult.fields[associatedCostsColumnKeys.COST].hasError) {
      validationResult.fields[associatedCostsColumnKeys.COST].hasError = false
      validationResult.countErrors--
    }
    this.setState({ ...this.state, fields: { ...this.state.fields, ...validationResult.fields } })
    if (validationResult.countErrors === 0) {
      if (this.props.takeCareAction) this.props.takeCareAction()
      this.updateActionPromise().then(() => this.setState({ ...this.state, edit: false }))
    }
  }

  deleteAction () {
    if (!this.props.takeCareEdition) {
      this.props.deleteAction(this.state.editableObject, this.props.dossierType, false)
    }
  }

  softDeleteAction () {
    this.props.deleteAction(this.state.editableObject, this.props.dossierType, true, this.props.rowIndex)
  }

  recoverAction () {
    this.props.recoverAction(this.state.editableObject, this.props.rowIndex)
  }

  createActionPromise () {
    const updatedObject = {
      ...this.state.editableObject,
      ...this.state.stateObject
    }
    return new Promise((resolve, reject) => {
      return this.props.createAction(updatedObject, resolve, reject, this.props.dossierType)
    })
  }

  createAction () {
    if (!this.props.takeCareEdition) {
      const validationResult = this.validateFields(this.state.stateObject)
      this.setState({ fields: { ...this.state.fields, ...validationResult.fields } })
      if (validationResult.countErrors === 0) {
        this.createActionPromise()
          .then(() => this.setState({ stateObject: this.setStateEditableObjectFromProps(this.props), edit: false }))
        if (this.props.clickRecharge) {
          this.props.clickRecharge()
        }
      }
    }
  }

  calculateAmountForPercentage (optionals) {
    if (optionals) {
      let amount = 0
      optionals.forEach(o => {
        amount += parseFloat(o.amount)
      })
      return amount
    }
    return 0
  }

  getOrderColumn () {
    const dossierId = this.state.editableObject?.dossierId
    const orderDocumentId = this.state.editableObject?.orderDocumentId
    const organizedUnitId = this.state.editableObject?.organizedUnitId
    const docStatus = this.state.editableObject?.orderDocumentStatus
    const referenceType = referenceTypes.ORDER_DOCUMENT_ID
    const referenceId = this.state.editableObject?.id
    const disabled = !referenceId
    const reconditioningOrderTooltip = (
      <Tooltip id="reconditioningOrderTooltip">
        {this.props.t('DOSSIER_COMPONENTS.EDITABLE_TABLE.UPLOAD_RECONDITIONING_ORDER')}
      </Tooltip>)
    return (<a className={'document-' + documentStatusClass[translateDocumentStatus[docStatus]]}
      onClick={() => this.props.openDocumentEditionModal(
        orderDocumentId,
        this.props.orderDocTypeUseId,
        documentEntityTypeConstants.DOSSIER,
        dossierId,
        null,
        null,
        organizedUnitId,
        null,
        null,
        true,
        this.props.dossierType,
        null, null, null, null, null, true, null, false, null, null,
        referenceType,
        referenceId,
        true
      )}
      disabled={disabled} >
      <OverlayTrigger placement="left" overlay={reconditioningOrderTooltip}>
        <i className="ico-document-plus" />
      </OverlayTrigger>
    </a>
    )
  }

  getInvoiceColumn () {
    const invoiceDocumentId = this.state.editableObject?.invoiceDocumentId
    const organizedUnitId = this.state.editableObject?.organizedUnitId
    const documentStatus = this.state.editableObject?.invoiceDocumentStatus
    const referenceType = referenceTypes.INVOICE_DOCUMENT_ID
    const referenceId = this.state.editableObject?.id
    const disabled = !referenceId
    const reconditioningInvoiceTooltip = (
      <Tooltip id="reconditioningInvoiceTooltip">
        {this.props.t('DOSSIER_COMPONENTS.EDITABLE_TABLE.UPLOAD_RECONDITIONING_INVOICE')}
      </Tooltip>)
    return (
      <a className={'document-' + documentStatusClass[translateDocumentStatus[documentStatus]]}
        onClick={() => this.props.openDocumentEditionModal(
          invoiceDocumentId,
          this.props.invoiceDocTypeUseId,
          documentEntityTypeConstants.DOSSIER,
          this.state.editableObject.dossierId,
          null,
          null,
          organizedUnitId,
          null,
          null,
          true,
          this.props.dossierType,
          null, null, null, null, null, true, null, false, null, null,
          referenceType,
          referenceId,
          true
        )}
        disabled={disabled}>
        <OverlayTrigger placement="left" overlay={reconditioningInvoiceTooltip}>
          <i className="ico-cloud" />
        </OverlayTrigger>
      </a>
    )
  }

  render () {
    const {
      t, readOnlyFields, createRowOption = false, total, input, takeCareEdition, showDeleteAction = true,
      showEditAction = true, showAddButton = true, showRecoverAction = false, showUploadOrderBtn = false, showUploadInvoiceBtn = false,
      openDocWarning
    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.EDITABLE_TABLE.'
    const editTooltip = (<Tooltip id="editTooltip">{t(`${tKey}EDIT_BTN`)}</Tooltip>)
    const deleteTooltip = (<Tooltip id="deleteTooltip">{t(`${tKey}DELETE_BTN`)}</Tooltip>)
    const saveTooltip = (<Tooltip id="saveTooltip">{t(`${tKey}SAVE_BTN`)}</Tooltip>)
    const cancelTooltip = (<Tooltip id="cancelTooltip">{t(`${tKey}CANCEL_BTN`)}</Tooltip>)
    const takeCareToolTip = (<Tooltip id="takeCareToolTip">{t(`${tKey}ONCE_EDITION`)}</Tooltip>)
    const recoverTooltip = (<Tooltip id="recoverTooltip">{t('MASTERS.RECOVER')}</Tooltip>)
    const isRappelToPay = this.isRappelToPay()
    const disableEditBtn = this.state.editableObject?.isDeleted || (!!this.state.editableObject?.orderDocumentId && !!this.state.editableObject?.invoiceDocumentId)
    const disableDeleteBtn = !!this.state.editableObject?.orderDocumentId && !!this.state.editableObject?.invoiceDocumentId
    const reconditioningOrderTooltip = (
      <Tooltip id="reconditioningOrderTooltip">
        {this.props.t('DOSSIER_COMPONENTS.EDITABLE_TABLE.UPLOAD_RECONDITIONING_ORDER')}
      </Tooltip>)
    const reconditioningInvoiceTooltip = (
      <Tooltip id="reconditioningInvoiceTooltip">
        {this.props.t('DOSSIER_COMPONENTS.EDITABLE_TABLE.UPLOAD_RECONDITIONING_INVOICE')}
      </Tooltip>)
    if (!createRowOption) {
      return (
        <tr className={(this.state.editableObject?.isDeleted ? 'is-deleted' : '')}>
          {
            this.state.columnsConfig.map((column, idx) => {
              if (column.isCheckbox) {
                return (
                  <EditableCheckboxCeil
                    key={idx}
                    id={column.key}
                    stateValue={this.state.editableObject[column.key]}
                    inputValue={this.state.stateObject[column.key]}
                    editing={this.state.edit}
                    onInputChange={(newVal) => this.onInputChange(newVal, column.key)}
                    hasError={this.state.fields[column.key].hasError}
                    className={column.className}
                    nonEditable={column.nonEditable}
                    input={input}
                    disabled={column.disabled}
                  />
                )
              } else if (column.marcado) {
                return (
                  <EditableCheckboxCeil
                    key={idx}
                    id={column.key}
                    stateValue={this.state.editableObject[column.key]}
                    inputValue={this.state.stateObject[column.key]}
                    editing={this.state.edit}
                    onInputChange={(newVal) => this.onInputChange(newVal, column.key)}
                    hasError={this.state.fields[column.key].hasError}
                    className={column.className}
                    input={input}
                    disabled={column.disabled}
                  />
                )
              } else if (column.isSelect) {
                const tSelectConfig = Object.assign({}, column.selectConfig)
                if (column.selectConfig.dependantSelect) {
                  tSelectConfig.options = column.selectConfig.options[this.state.stateObject[column.selectConfig.dependantSelect]]
                }
                return (
                  <EditableSelectCeil
                    key={idx}
                    id={column.key}
                    stateValue={this.state.editableObject[column.key]}
                    inputValue={this.state.stateObject[column.key]}
                    selectConfig={tSelectConfig}
                    editing={this.state.edit}
                    onInputChange={(newVal) => this.onInputChange(newVal, column.key, column.selectConfig.onInputChange)}
                    nonEditable={column.nonEditable}
                    hasError={this.state.fields[column.key].hasError}
                    input={input}
                    disabled={column.disabled}
                  />
                )
              } else if (column.isSelectCompuesto) {
                const tSelectConfig = Object.assign({}, column.selectConfig)
                tSelectConfig.options = column.selectConfig.options.filter(x => !x.isDeleted || (this.state.stateObject[column.key] && x.id === this.state.stateObject[column.key]))
                return (
                  <EditableSelectCeil
                    key={idx}
                    id={column.key}
                    stateValue={this.state.editableObject[column.key]}
                    inputValue={this.state.stateObject[column.key]}
                    selectConfig={tSelectConfig}
                    editing={this.state.edit}
                    onInputChange={(newVal) => this.onInputChangeSelectCompuesto(newVal, column.key)}
                    nonEditable={column.nonEditable}
                    hasError={this.state.fields[column.key].hasError}
                    input={input}
                    disabled={column.disabled}
                  />
                )
              } else if (column.check) {
                return (
                  <td key={idx} className="text-center"><span style={{ fontSize: '9px', paddingLeft: '5px' }}><Glyphicon glyph="ok" /></span></td>
                )
              } else if (column.specialhelps) {
                return (
                  <td key={idx}><Col sm={12}><span>{t(`${tKey}SPECIAL_HELP`)}</span></Col></td>
                )
              } else if (column.iem) {
                return (
                  <td key={idx}><Col sm={12}><span>{t(`${tKey}AMOUNT`)}</span></Col></td>
                )
              } else if (column.isIem) {
                return (
                  <td key={idx}><Col sm={12}><span><b>IEM</b></span></Col></td>
                )
              } else if (column.paperwork) {
                return (
                  <td key={idx}><Col sm={12}><span>{t(`${tKey}GERENCIA`)}</span></Col></td>
                )
              } else if (column.perc) {
                return (
                  <td key={idx}>
                    <span style={{ float: 'right' }}>
                      {
                        column.vehicleAmount
                          ? this.props.optionals
                            ? (((this.state.editableObject.amount ?? 0) / ((column.vehicleAmount ?? 0) + (this.props.optionals ?? 0)) * 100).toFixed(2) + '%')
                            : (((this.state.editableObject.amount ?? 0) / (column.vehicleAmount ?? 0) * 100).toFixed(2) + '%')
                          : ''
                      }
                    </span>
                  </td>
                )
              } else if (column.money) {
                return (
                  <EditableMoneyCeil
                    key={idx}
                    id={column.key}
                    stateValue={this.state.editableObject[column.key]}
                    inputValue={this.state.stateObject[column.key]}
                    editing={this.state.edit}
                    onInputChange={(newVal) => this.onInputChange(newVal, column.key)}
                    nonEditable={column.nonEditable}
                    hasError={this.state.fields[column.key].hasError}
                    total={total}
                    input={input}
                    disabled={isRappelToPay ? true : column.disabled}
                    maxLength={column.maxLength}
                  />
                )
              } else if (column.button) {
                if (isRappelToPay) {
                  return (
                    <td key={idx}>
                      <Button
                        id={column.key}
                        className="btn-editable-table btn-standar-center"
                        disabled={this.state.edit}
                        onClick={() => column.openOrCloseAssociatedCostPercentageModal(
                          this.props.editableObject.id,
                          this.props.editableObject.indexFront
                        )}
                      >
                        {t(`${tKey}CALCULATE_BY_PERCENTAGE`)}
                      </Button>
                    </td>
                  )
                } else {
                  return (
                    <td key={idx}>
                      <Button
                        id={column.key}
                        className="btn-editable-table btn-standard btn-hidden"
                      >
                        {t(`${tKey}CALCULATE_BY_PERCENTAGE`)}
                      </Button>
                    </td>
                  )
                }
              } else {
                return (
                  <EditableTextCeil
                    key={idx}
                    id={column.key}
                    inputType={column.inputType}
                    stateValue={this.state.editableObject[column.key]}
                    inputValue={this.state.stateObject[column.key]}
                    editing={this.state.edit}
                    onInputChange={(newVal, ref, pos) => this.onInputChange(newVal, column.key, null, ref, pos)}
                    formatFunc={column.formatFunc}
                    nonEditable={column.nonEditable}
                    hasError={this.state.fields[column.key].hasError}
                    maxLength={column.maxLength}
                    total={total}
                    onlyShow={column.onlyShow}
                    input={input}
                    needRef={true}
                    disabled={column.disabled}
                  />
                )
              }
            })
          }
          {(showEditAction !== false || showDeleteAction !== false || showAddButton !== false || showRecoverAction || showUploadOrderBtn || showUploadInvoiceBtn) &&
            <td className="actionButtonsCol">
              {!total && showUploadOrderBtn && (
                <a className={'document-' + documentStatusClass[translateDocumentStatus[this.state.editableObject?.orderDocumentStatus]]}
                  onClick={() => {
                    new Promise((resolve, reject) => {
                      if (openDocWarning && !this.props.isPristine) {
                        openDocWarning(resolve, reject)
                      } else { resolve() }
                    }).then(() => {
                      this.props.openDocumentEditionModal(
                        this.state.editableObject?.orderDocumentId,
                        this.props.orderDocTypeUseId,
                        documentEntityTypeConstants.DOSSIER,
                        this.state.editableObject?.dossierId,
                        null,
                        null,
                        this.state.editableObject?.organizedUnitId,
                        null,
                        null,
                        true,
                        this.props.dossierType,
                        null, null, this.props.isPristine, null, null,
                        !this.state.editableObject?.isDeleted && !readOnlyFields,
                        readOnlyFields || this.state.editableObject?.isDeleted,
                        false, null, null,
                        referenceTypes.ORDER_DOCUMENT_ID,
                        this.state.editableObject?.id,
                        true
                      )
                    })
                  }}
                  disabled={!this.state.editableObject?.id || (readOnlyFields && !this.state.editableObject?.orderDocumentId) || this.state.edit} >
                  <OverlayTrigger placement="left" overlay={reconditioningOrderTooltip}>
                    <i className="ico-document-plus" />
                  </OverlayTrigger>
                </a>
              )}
              {!total && showUploadInvoiceBtn && (
                <a className={'document-' + documentStatusClass[translateDocumentStatus[this.state.editableObject?.invoiceDocumentStatus]]}
                  onClick={() => {
                    new Promise((resolve, reject) => {
                      if (openDocWarning && !this.props.isPristine) {
                        openDocWarning(resolve, reject)
                      } else { resolve() }
                    }).then(() => {
                      this.props.openDocumentEditionModal(
                        this.state.editableObject?.invoiceDocumentId,
                        this.props.invoiceDocTypeUseId,
                        documentEntityTypeConstants.DOSSIER,
                        this.state.editableObject?.dossierId,
                        null,
                        null,
                        this.state.editableObject?.organizedUnitId,
                        null,
                        null,
                        true,
                        this.props.dossierType,
                        null, null, this.props.isPristine, null, null,
                        !this.state.editableObject?.isDeleted && !readOnlyFields,
                        readOnlyFields || this.state.editableObject?.isDeleted,
                        false, null, null,
                        referenceTypes.INVOICE_DOCUMENT_ID,
                        this.state.editableObject?.id,
                        true
                      )
                    })
                  } }
                  disabled={!this.state.editableObject?.id || (readOnlyFields && !this.state.editableObject?.invoiceDocumentId) || this.state.edit}>
                  <OverlayTrigger placement="left" overlay={reconditioningInvoiceTooltip}>
                    <i className="ico-cloud" />
                  </OverlayTrigger>
                </a>
              )}
              {!readOnlyFields &&
              (this.state.edit
                ? <span>
                  <a onClick={() => this.updateAction()}>
                    <OverlayTrigger placement="right" overlay={saveTooltip}>
                      <i className="ico-check" />
                    </OverlayTrigger>
                  </a>
                  <a onClick={() => this.cancelEdition()}>
                    <OverlayTrigger placement="right" overlay={cancelTooltip}>
                      <i className="ico-cancel" />
                    </OverlayTrigger>
                  </a>
                </span>
                : <span>
                  {showEditAction && !this.state.editableObject?.isDeleted &&
                    <a onClick={() => this.editEdition()} disabled = {disableEditBtn}>
                      {
                        !takeCareEdition
                          ? <OverlayTrigger placement="right" overlay={editTooltip}>
                            <i className="ico-edit-white" />
                          </OverlayTrigger>
                          : <OverlayTrigger placement="right" overlay={takeCareToolTip}>
                            <i className="ico-edit-white" />
                          </OverlayTrigger>
                      }
                    </a>}
                  {showDeleteAction && !showRecoverAction &&
                    <a onClick={() => this.deleteAction()}>
                      {
                        !takeCareEdition
                          ? <OverlayTrigger placement="right" overlay={deleteTooltip}>
                            <i className="ico-trash" />
                          </OverlayTrigger>
                          : <OverlayTrigger placement="right" overlay={takeCareToolTip}>
                            <i className="ico-trash" />
                          </OverlayTrigger>
                      }
                    </a>
                  }
                  {showDeleteAction && showRecoverAction && !this.state.editableObject?.isDeleted &&
                    <a onClick={() => this.softDeleteAction()} disabled = {disableDeleteBtn}>
                      <OverlayTrigger placement="left" overlay={deleteTooltip}>
                        <i className="ico-trash" />
                      </OverlayTrigger>
                    </a>
                  }
                  {showRecoverAction && this.state.editableObject?.isDeleted &&
                    <a onClick={() => this.recoverAction()}>
                      <OverlayTrigger placement="left" overlay={recoverTooltip}>
                        <i className="glyphicon glyphicon-repeat" />
                      </OverlayTrigger>
                    </a>}
                </span>)
              }
            </td>}
        </tr>
      )
    } else if (!this.state.edit) {
      return (
        <tr className="button-table-wrapper">
          {!readOnlyFields && showAddButton &&
            <td>
              {takeCareEdition
                ? <OverlayTrigger placement="right" overlay={takeCareToolTip}>
                  <Button
                    className="btn-editable-table btn-standard"
                    id="add-button"
                  >
                    {t(`${tKey}ADD_BTN`)}
                  </Button>
                </OverlayTrigger>
                : <Button
                  className="btn-editable-table btn-standard"
                  id="add-button"
                  onClick={() => this.editEdition()}
                >
                  {t(`${tKey}ADD_BTN`)}
                </Button>
              }
            </td>}
        </tr>
      )
    } else {
      return (
        <tr>
          {
            this.state.columnsConfig.map((column, idx) => {
              if (column.isCheckbox) {
                return (
                  <EditableCheckboxCeil
                    key={idx}
                    id={column.key}
                    stateValue={this.state.editableObject[column.key]}
                    inputValue={this.state.stateObject[column.key]}
                    editing={this.state.edit}
                    onInputChange={(newVal) => this.onInputChange(newVal, column.key)}
                    hasError={this.state.fields[column.key].hasError}
                    className={column.className}
                    nonEditable={column.nonEditable}
                    input={input}
                    onlyShow={column.onlyShow}
                    disabled={column.disabled}
                  />
                )
              } else if (column.marcado) {
                return (
                  <EditableCheckboxCeil
                    key={idx}
                    id={column.key}
                    stateValue={this.state.editableObject[column.key]}
                    inputValue={this.state.stateObject[column.key]}
                    editing={this.state.edit}
                    onInputChange={(newVal) => this.onInputChangeExtraSale(newVal, column.key)}
                    hasError={this.state.fields[column.key].hasError}
                    className={column.className}
                    input={input}
                    onlyShow={column.onlyShow}
                    disabled={column.disabled}
                  />
                )
              } else if (column.isSelect) {
                const tSelectConfig = Object.assign({}, column.selectConfig)
                if (column.selectConfig.dependantSelect) {
                  tSelectConfig.options = column.selectConfig.options[this.state.stateObject[column.selectConfig.dependantSelect]]
                }
                return (
                  <EditableSelectCeil
                    key={idx}
                    id={column.key}
                    stateValue={this.state.editableObject[column.key]}
                    inputValue={this.state.stateObject[column.key]}
                    selectConfig={tSelectConfig}
                    editing={this.state.edit}
                    onInputChange={(newVal) => this.onInputChange(newVal, column.key, column.selectConfig.onInputChange)}
                    nonEditable={column.nonEditable}
                    hasError={this.state.fields[column.key].hasError}
                    input={input}
                    disabled={column.disabled}
                  />
                )
              } else if (column.isSelectCompuesto) {
                const tSelectConfig = Object.assign({}, column.selectConfig)
                tSelectConfig.options = column.selectConfig.options.filter(x => !x.isDeleted || (this.state.stateObject[column.key] && x.id === this.state.stateObject[column.key]))
                return (
                  <EditableSelectCeil
                    key={idx}
                    id={column.key}
                    stateValue={this.state.editableObject[column.key]}
                    inputValue={this.state.stateObject[column.key]}
                    selectConfig={tSelectConfig}
                    editing={this.state.edit}
                    onInputChange={(newVal) => this.onInputChangeSelectCompuesto(newVal, column.key)}
                    nonEditable={column.nonEditable}
                    hasError={this.state.fields[column.key].hasError}
                    input={input}
                    disabled={column.disabled}
                  />
                )
              } else if (column.money) {
                return (
                  <EditableMoneyCeil
                    key={idx}
                    id={column.key}
                    stateValue={this.state.editableObject[column.key]}
                    inputValue={this.state.stateObject[column.key]}
                    editing={this.state.edit}
                    onInputChange={(newVal) => this.onInputChange(newVal, column.key)}
                    nonEditable={column.nonEditable}
                    hasError={this.state.fields[column.key].hasError}
                    total={total}
                    input={input}
                    disabled={isRappelToPay ? true : column.disabled}
                    maxLength={column.maxLength}
                  />
                )
              } else if (column.button) {
                if (isRappelToPay) {
                  return (
                    <td key={idx}>
                      <Button
                        id={column.key}
                        className="btn-editable-table btn-standar-center"
                        onClick={() => column.openOrCloseAssociatedCostPercentageModal(
                          this.props.editableObject.id,
                          this.props.editableObject.indexFront
                        )}
                        disabled={this.state.edit}
                      >
                        {t(`${tKey}CALCULATE_BY_PERCENTAGE`)}
                      </Button>
                    </td>
                  )
                } else {
                  return (
                    <td key={idx}>
                      <Button
                        id={column.key}
                        className="btn-editable-table btn-standard btn-hidden"
                      >
                        {t(`${tKey}CALCULATE_BY_PERCENTAGE`)}
                      </Button>
                    </td>
                  )
                }
              } else {
                return (
                  <EditableTextCeil
                    key={idx}
                    id={column.key}
                    inputType={column.inputType}
                    stateValue={this.state.editableObject[column.key]}
                    inputValue={this.state.stateObject[column.key]}
                    editing={this.state.edit}
                    onInputChange={(newVal, ref, pos) => this.onInputChange(newVal, column.key, null, ref, pos)}
                    formatFunc={column.formatFunc}
                    nonEditable={column.nonEditable}
                    hasError={this.state.fields[column.key].hasError}
                    maxLength={column.maxLength}
                    total={total}
                    discounts={column.discounts}
                    input={input}
                    needRef={true}
                    onlyShow={column.onlyShow}
                    disabled={column.disabled}
                  />
                )
              }
            })
          }
          <td className="text-center actionButtonsCol">
            {!total && showUploadOrderBtn && (
              <a className={'document-' + documentStatusClass[translateDocumentStatus[this.state.editableObject?.orderDocumentStatus]]}
                onClick={() => this.props.openDocumentEditionModal(
                  this.state.editableObject?.orderDocumentId,
                  this.props.orderDocTypeUseId,
                  documentEntityTypeConstants.DOSSIER,
                  this.state.editableObject?.dossierId,
                  null,
                  null,
                  this.state.editableObject?.organizedUnitId,
                  null,
                  null,
                  true,
                  this.props.dossierType,
                  null, null, this.props.isPristine, null, null, !readOnlyFields, readOnlyFields, false, null, null,
                  referenceTypes.ORDER_DOCUMENT_ID,
                  this.state.editableObject?.id,
                  true
                )}
                disabled={this.state.editableObject?.isDeleted || !this.state.editableObject?.id} >
                <OverlayTrigger placement="left" overlay={reconditioningOrderTooltip}>
                  <i className="ico-document-plus" />
                </OverlayTrigger>
              </a>
            )}
            {!total && showUploadInvoiceBtn && (
              <a className={'document-' + documentStatusClass[translateDocumentStatus[this.state.editableObject?.invoiceDocumentStatus]]}
                onClick={() => this.props.openDocumentEditionModal(
                  this.state.editableObject?.invoiceDocumentId,
                  this.props.invoiceDocTypeUseId,
                  documentEntityTypeConstants.DOSSIER,
                  this.state.editableObject?.dossierId,
                  null,
                  null,
                  this.state.editableObject?.organizedUnitId,
                  null,
                  null,
                  true,
                  this.props.dossierType,
                  null, null, this.props.isPristine, null, null, !readOnlyFields, readOnlyFields, false, null, null,
                  referenceTypes.INVOICE_DOCUMENT_ID,
                  this.state.editableObject?.id,
                  true
                )}
                disabled={this.state.editableObject?.isDeleted || !this.state.editableObject?.id}>
                <OverlayTrigger placement="left" overlay={reconditioningInvoiceTooltip}>
                  <i className="ico-cloud" />
                </OverlayTrigger>
              </a>
            )}
            {!readOnlyFields &&
              <a onClick={() => this.createAction()}>
                {
                  !takeCareEdition
                    ? <OverlayTrigger placement="left" overlay={saveTooltip}>
                      <i className="ico-check" />
                    </OverlayTrigger>
                    : <OverlayTrigger placement="right" overlay={takeCareToolTip}>
                      <i className="ico-check" />
                    </OverlayTrigger>
                }
              </a>
            }
            {!readOnlyFields &&
              <a onClick={() => this.cancelEdition()}><OverlayTrigger placement="left" overlay={cancelTooltip}><i className="ico-cancel" /></OverlayTrigger></a>
            }
          </td>
        </tr>
      )
    }
  }
}
export default translate()(EditableRow)
