export default {
  FETCH_PAPERWORK_EDITION_MODAL: 'FETCH_PAPERWORK_EDITION_MODAL',
  FETCH_PAPERWORK_EDITION_MODAL_SUCCESS: 'FETCH_PAPERWORK_EDITION_MODAL_SUCCESS',
  DELETE_PAPERWORK: 'DELETE_PAPERWORK',
  UPDATE_PAPERWORK: 'UPDATE_PAPERWORK',
  CREATE_PAPERWORK: 'CREATE_PAPERWORK',
  PRINT_PAPERWORK: 'PRINT_PAPERWORK',
  CLOSE_PAPERWORK_EDITION_MODAL: 'CLOSE_PAPERWORK_EDITION_MODAL',
  GENERATE_FILE_TO_SIGN: 'GENERATE_FILE_TO_SIGN',
  CHECK_ORDER_STATUS: 'CHECK_ORDER_STATUS'
}
