import apiFetch from '../apiFetch'

export default function (suppliedValues, uoId, token) {
  const body = {
    organizedUnitId: uoId,
    isDeleted: false,
    suppliedTypeLanguages: suppliedValues
  }
  return apiFetch({
    endPoint: 'suppliedType',
    method: 'POST',
    body: body,
    token: token
  })
}
