
import { put, call, select, takeEvery } from 'redux-saga/effects'
import { showLoader, hideLoader } from '../../../actions/common.js'
import mastersActionTypes from '../../../constants/actions/masters/masters'
import { getAuth } from '../../../selectors/access/auth'
import { handleError } from '../../errors/errorManager'
import { yesNoModal } from '../../modalsListeners/yesNoModal'

import postDocumentVisualizations from '../../../services/documentVisualization/postDocumentVisualizations'
import postDocumentVisualization from '../../../services/documentVisualization/postDocumentVisualization'
import deleteDocumentVisualizationService from '../../../services/documentVisualization/deleteDocumentVisualization'
import putDocumentVisualization from '../../../services/documentVisualization/putDocumentVisualization'
import { getDocumentVisualizationFilter } from '../../../selectors/masters/document'
import {
  closeDocumentVisualizationMasterModal, fetchDocumentVisualizationsSuccess, openDocumentVisualizationModalSuccess
} from '../../../actions/masters/documentVisualization/documentVisualization'

export function * fetchDocumentVisualizations ({ filter }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield filter = yield filter || select(getDocumentVisualizationFilter)
    const documentVisualizations = yield call(postDocumentVisualizations, auth.token, filter)
    yield put(fetchDocumentVisualizationsSuccess(documentVisualizations, filter))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDocumentVisualizations () {
  yield takeEvery(mastersActionTypes.FETCH_DOCUMENT_VISUALIZATIONS, fetchDocumentVisualizations)
}

export function * deleteDocumentVisualization ({ documentVisualizationId }) {
  try {
    const confirmed = yield call(yesNoModal, 'deleteDocumentVisualization')
    if (confirmed) {
      yield put(showLoader())
      const auth = yield select(getAuth)
      const filter = yield filter || select(getDocumentVisualizationFilter)
      yield call(deleteDocumentVisualizationService, auth.token, documentVisualizationId)
      yield call(fetchDocumentVisualizations, filter)
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDocumentVisualizationDelete () {
  yield takeEvery(mastersActionTypes.DELETE_DOCUMENT_VISUALIZATION, deleteDocumentVisualization)
}

export function * submitDocumentVisualization ({ documentVisualization }) {
  try {
    let confirmed = true
    let isNew = true
    if (documentVisualization.documentVisualizationId) {
      isNew = false
      confirmed = yield call(yesNoModal, 'editDocumentVisualization')
    }

    if (confirmed) {
      yield put(showLoader())
      const auth = yield select(getAuth)
      const filter = yield filter || select(getDocumentVisualizationFilter)
      if (isNew) {
        yield call(postDocumentVisualization, auth.token, documentVisualization)
      } else {
        yield call(putDocumentVisualization, auth.token, documentVisualization)
      }
      yield put(closeDocumentVisualizationMasterModal())
      yield call(fetchDocumentVisualizations, filter)
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDocumentVisualizationSubmit () {
  yield takeEvery(mastersActionTypes.SUBMIT_DOCUMENT_VISUALIZATION, submitDocumentVisualization)
}

export function * openDocumentVisualizationModal ({ documentVisualization }) {
  try {
    yield put(showLoader())
    yield put(openDocumentVisualizationModalSuccess(documentVisualization))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchOpenDocumentVisualitationModal () {
  yield takeEvery(mastersActionTypes.OPEN_DOCUMENT_VISUALIZATION_MODAL, openDocumentVisualizationModal)
}
