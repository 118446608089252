import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import FleetsFinder from '../../components/management/FleetFinder'
import { translate } from 'react-polyglot'
import {
  fetchFleetsInfo, resetFiltersFleetInfo, asignDossierToFleet,
  createUpdateFleet,
  deleteFleet,
  changeEntityDossierFromFleet, closeActionFleetFinder, openNewFleetModal, fetchFleetsInfoDownload
} from '../../../actions/management/management'
import { fetchFleetsUser } from '../../../actions/combos/combos'
import { setActionExpFleetProcess } from '../../../actions/unattendedProcess/unattendedProcess'

import {
  initializeSearch, resetFilters
} from '../../../actions/search/search'
import { openModal, yesNoModal } from '../../../actions/common'

export function mapStateToProps (state) {
  return {
    ...state.management.searchFleetInfo,
    ...state.management.actionFleetFinder
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      initializeSearch,
      resetFilters,
      openModal,
      yesNoModal,
      fetchFleetsInfo,
      resetFiltersFleetInfo,
      asignDossierToFleet,
      createUpdateFleet,
      deleteFleet,
      changeEntityDossierFromFleet,
      closeActionFleetFinder,
      openNewFleetModal,
      fetchFleetsInfoDownload,
      fetchFleetsUser,
      setActionExpFleetProcess
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(FleetsFinder))
