import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import DocumentaryMassiveDownloadModal from '../../../components/dossiers/common/documentaryManagement/DocumentaryMassiveDownloadModal'
import { getDocumentsMassiveDownload, downloadDocumentsSelected } from '../../../actions/dossier/common/documentMassiveDownloadActions'

export function mapStateToProps (state, ownProps) {
  return {
    ...state.documentMassiveDownloadModal,
    showModal: ownProps.showModal,
    closeModal: ownProps.closeModal,
    dossierId: ownProps.dossierId,
    isHistorical: ownProps.isHistorical
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      getDocumentsMassiveDownload,
      downloadDocumentsSelected
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(DocumentaryMassiveDownloadModal))
