import React, { PureComponent } from 'react'
import { Modal } from 'react-bootstrap'
import setting from '../../../setting'

class ModalReleaseNotes extends PureComponent {
  constructor () {
    super()
    this.state = {
      interval: null
    }
  }

  componentDidMount () {
    this.props.actions.fetchReleaseNotesFile(setting.version, this.props.userLanguageId)
  }

  render () {
    const {
      t, showNewVersion, releaseNotes,
      actions: { closeReleaseNotes }
    } = this.props
    const tKey = 'HEADER.RELEASE_NOTES.'

    return (
      <Modal className="custom-modal qr_modal" show={showNewVersion && releaseNotes} onHide={closeReleaseNotes} backdrop={'static'}>
        <Modal.Header closeButton>
          <Modal.Title className={'title center'}>
            <span dangerouslySetInnerHTML={{ __html: t(`${tKey}TITLE`) }}></span> <b>{setting.version}</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          { releaseNotes && <span dangerouslySetInnerHTML={{ __html: releaseNotes.notes }}></span>}
        </Modal.Body>
        <Modal.Footer>
          <div className='btnContainer'>
            { releaseNotes && releaseNotes.url !== '' && <a onClick={() => window.open(releaseNotes.url, '_blank')} className={'_Btn accept'}>
              {t(`${tKey}LINK`)}
            </a> }
            <a onClick={closeReleaseNotes} className={'_Btn cancel'}>
              {t(`${tKey}CLOSE`)}{ releaseNotes && releaseNotes.url !== '' && <span>*</span> }
            </a>
          </div>
          { releaseNotes && releaseNotes.url !== '' && <p className="versionSubText" dangerouslySetInnerHTML={{ __html: t(`${tKey}SUBTEXT`) }}></p> }
        </Modal.Footer>
      </Modal>
    )
  }
}
export default ModalReleaseNotes
