import searchActionTypes from '../../constants/actions/search/search'

export function initializeSearch () {
  return {
    type: searchActionTypes.INITIALIZE_SEARCH
  }
}

export function searchTabChange (selectedTab) {
  return {
    type: searchActionTypes.SEARCH_TAB_CHANGE,
    selectedTab
  }
}

export function salePayRenting (dossierId) {
  return {
    type: searchActionTypes.SALE_PAY_RENTING,
    dossierId
  }
}

export function fetchDossiers (filters) {
  return {
    type: searchActionTypes.FETCH_DOSSIERS,
    filters
  }
}

export function fetchDossiersSimple (filters) {
  return {
    type: searchActionTypes.FETCH_DOSSIERS_SIMPLE,
    filters
  }
}
export function fetchDossiersSimpleDownload (filters, columns) {
  return {
    type: searchActionTypes.FETCH_DOSSIERS_SIMPLE_DOWNLOAD,
    filters,
    columns
  }
}

export function fetchVehicles (filters) {
  return {
    type: searchActionTypes.FETCH_VEHICLES,
    filters
  }
}

export function fetchVehiclesDownload (filters, columns) {
  return {
    type: searchActionTypes.FETCH_VEHICLES_DOWNLOAD,
    filters,
    columns
  }
}

export function fetchVehiclesSimple (filters) {
  return {
    type: searchActionTypes.FETCH_VEHICLES_SIMPLE,
    filters
  }
}

export function fetchVehiclesSimpleDownload (filters, columns) {
  return {
    type: searchActionTypes.FETCH_VEHICLES_SIMPLE_DOWNLOAD,
    filters,
    columns
  }
}

export function fetchEntities (filters) {
  return {
    type: searchActionTypes.FETCH_ENTITIES,
    filters
  }
}

export function fetchEntitiesDownload (filters, columns) {
  return {
    type: searchActionTypes.FETCH_ENTITIES_DOWNLOAD,
    filters,
    columns
  }
}

export function fetchCharges (filters) {
  return {
    type: searchActionTypes.FETCH_CHARGES,
    filters
  }
}

export function fetchChargesFilterSuccess (data, filters) {
  return {
    type: searchActionTypes.FETCH_CHARGE_FILTER_SUCCESS,
    filters,
    data
  }
}

export function fetchChargeRenting (filters) {
  return {
    type: searchActionTypes.FETCH_RENTING,
    filters
  }
}

export function fetchSelfSupply (filters) {
  return {
    type: searchActionTypes.FETCH_SELFSUPPLY,
    filters
  }
}

export function salePayRentingList (filters) {
  return {
    type: searchActionTypes.SALE_PAY_RENTING_LIST,
    filters
  }
}

export function saleSelfSupplyList (filters) {
  return {
    type: searchActionTypes.SALE_SELFSUPPLY_LIST,
    filters
  }
}

export function fetchDossierFilterSuccess (data, filters) {
  return {
    type: searchActionTypes.FETCH_DOSSIER_FILTER_SUCCESS,
    data,
    filters
  }
}

export function fetchRentingSuccess (data, filters) {
  return {
    type: searchActionTypes.FETCH_CHARGE_RENTING_SUCCESS,
    data,
    filters
  }
}

export function fetchSelfSupplySuccess (data, filters) {
  return {
    type: searchActionTypes.FETCH_SELFSUPPLY_SUCCESS,
    data,
    filters
  }
}

export function fetchVehicleFilterSuccess (data, filters) {
  return {
    type: searchActionTypes.FETCH_VEHICLE_FILTER_SUCCESS,
    data,
    filters
  }
}

export function fetchEntityFilterSuccess (data, filters) {
  return {
    type: searchActionTypes.FETCH_ENTITY_FILTER_SUCCESS,
    data,
    filters
  }
}

export function resetFilters () {
  return {
    type: searchActionTypes.RESET_FILTERS
  }
}

export function setFilters (filters) {
  return {
    type: searchActionTypes.SET_FILTERS,
    filters
  }
}

export function setNoDynamicFilters (filters) {
  return {
    type: searchActionTypes.SET_NO_DYNAMIC_FILTERS,
    filters
  }
}

export function fetchFleets (filters) {
  return {
    type: searchActionTypes.FETCH_FLEETS,
    filters
  }
}

export function fetchFleetsSuccess (data, filters) {
  return {
    type: searchActionTypes.FETCH_FLEETS_SUCCESS,
    data,
    filters
  }
}

export function fetchDossiersDownload (filters, columns) {
  return {
    type: searchActionTypes.FETCH_DOSSIERS_DOWNLOAD,
    filters,
    columns
  }
}

export function fetchChargeRentingDownload (filters, columns) {
  return {
    type: searchActionTypes.FETCH_RENTING_DOWNLOAD,
    filters,
    columns
  }
}

export function fetchSelfSupplyDownload (filters, columns) {
  return {
    type: searchActionTypes.FETCH_SELFSUPPLY_DOWNLOAD,
    filters,
    columns
  }
}

export function fetchChargesDownload (filters, columns) {
  return {
    type: searchActionTypes.FETCH_CHARGES_DOWNLOAD,
    filters,
    columns
  }
}

export function fetchFleetsDownload (filters, columns) {
  return {
    type: searchActionTypes.FETCH_FLEETS_DOWNLOAD,
    filters,
    columns
  }
}

export function fetchCampaignExport (filters) {
  return {
    type: searchActionTypes.FETCH_CAMPAIGN_EXPORT,
    filters
  }
}

export function fetchCampaignExportSuccess (data, filters) {
  return {
    type: searchActionTypes.FETCH_CAMPAIGN_EXPORT_SUCCES,
    data,
    filters
  }
}

export function changeExportCampaignExport (dossiers) {
  return {
    type: searchActionTypes.CHANGE_EXPORT_CAMPAIGN_EXPORT,
    dossiers
  }
}

export function fetchFtpRegister (filters) {
  return {
    type: searchActionTypes.FETCH_FTP_REGISTER,
    filters
  }
}

export function fetchFtpRegisterSuccess (data, filters) {
  return {
    type: searchActionTypes.FETCH_FTP_REGISTER_SUCCESS,
    data,
    filters
  }
}

export function fetchFtpRegisterExport (filters, columns) {
  return {
    type: searchActionTypes.FETCH_FTP_REGISTER_EXPORT,
    filters,
    columns
  }
}

export function launchExportCampaign (filters, checkAll, toExport, notToExport) {
  return {
    type: searchActionTypes.LAUCH_EXPORT_CAMPAIGN,
    filters,
    checkAll,
    toExport,
    notToExport
  }
}

export function launchExportCampaignExcel (filters, checkAll, toExport, notToExport) {
  return {
    type: searchActionTypes.LAUCH_EXPORT_CAMPAIGN_EXCEL,
    filters,
    checkAll,
    toExport,
    notToExport
  }
}

export function fetchRegisterOrder (filters) {
  return {
    type: searchActionTypes.FETCH_REGISTER_ORDER,
    filters
  }
}

export function fetchRegisterOrderSuccess (data, filters) {
  return {
    type: searchActionTypes.FETCH_REGISTER_ORDER_SUCCESS,
    data,
    filters
  }
}

export function fetchRegisterOrderDownload (filters, columns) {
  return {
    type: searchActionTypes.FETCH_REGISTER_ORDER_DOWNLOAD,
    filters,
    columns
  }
}

export function fetchServicePrepare (filters) {
  return {
    type: searchActionTypes.FETCH_SERVICE_PREPARE,
    filters
  }
}

export function fetchServicePrepareSuccess (data, filters) {
  return {
    type: searchActionTypes.FETCH_SERVICE_PREPARE_SUCCESS,
    data,
    filters
  }
}

export function fetchServicePrepareDownload (filters, columns) {
  return {
    type: searchActionTypes.FETCH_SERVICE_PREPARE_DOWNLOAD,
    filters,
    columns
  }
}

export function fetchServicePrepareRoleConfig () {
  return {
    type: searchActionTypes.FETCH_SERVICE_PREPARE_ROLE_CONFIG
  }
}

export function fetchServicePrepareRoleConfigSuccess (data) {
  return {
    type: searchActionTypes.FETCH_SERVICE_PREPARE_ROLE_CONFIG_SUCCESS,
    data
  }
}
export function fetchDossierFleets (filters, resolve) {
  return {
    type: searchActionTypes.FETCH_DOSSIER_FLEETS,
    filters,
    resolve
  }
}
export function fetchDossierFleetsSuccess (data, filters) {
  return {
    type: searchActionTypes.FETCH_DOSSIER_FLEETS_SUCCESS,
    data,
    filters
  }
}

export function fetchDossierFleetsDownload (filters, columns) {
  return {
    type: searchActionTypes.FETCH_DOSSIER_FLEETS_DOWNLOAD,
    filters,
    columns
  }
}

export function openSelfSupplyModal (pristine) {
  return {
    type: searchActionTypes.OPEN_SELF_SUPPLY_MODAL,
    pristine
  }
}
export function closeSelfSupplyModal () {
  return {
    type: searchActionTypes.CLOSE_SELF_SUPPLY_MODAL
  }
}
export function setManagementFleetsFilter (filters) {
  return {
    type: searchActionTypes.SET_FLEETS_MANAGEMENT_FILTERS,
    filters
  }
}

export function resetManagementFleetsFilter () {
  return {
    type: searchActionTypes.RESET_FLEETS_MANAGEMENT_FILTERS
  }
}

export function fetchReportCommercial (filters) {
  return {
    type: searchActionTypes.FETCH_REPORT_COMMERCIAL,
    filters
  }
}
export function fetchReportCommercialSuccess (data, filters) {
  return {
    type: searchActionTypes.FETCH_REPORT_COMMERCIAL_SUCCESS,
    data,
    filters
  }
}
export function fetchReportCommercialDownload (cells) {
  return {
    type: searchActionTypes.FETCH_REPORT_COMMERCIAL_DOWNLOAD,
    cells
  }
}

export function fetchReportCommercialTable (filters) {
  return {
    type: searchActionTypes.FETCH_REPORT_COMMERCIAL_TABLE,
    filters
  }
}

export function fetchReportCommercialTableSuccess (data, filters) {
  return {
    type: searchActionTypes.FETCH_REPORT_COMMERCIAL_TABLE_SUCCESS,
    data,
    filters
  }
}

export function exportReportCommercialTable (filters, columns) {
  return {
    type: searchActionTypes.EXPORT_REPORT_COMMERCIAL_TABLE,
    filters,
    columns
  }
}

export function closeReportCommercialTableModal () {
  return {
    type: searchActionTypes.CLOSE_REPORT_COMMERCIAL_TABLE_MODAL
  }
}

export function fetchPapServicesFromManager (filters) {
  return {
    type: searchActionTypes.FETCH_PAP_SERVICES_FROM_MANAGER,
    filters
  }
}

export function fetchPapServicesFromManagerSuccess (data, filters) {
  return {
    type: searchActionTypes.FETCH_PAP_SERVICES_FROM_MANAGER_SUCCESS,
    data,
    filters
  }
}

export function fetchPapServicesDownloadFromManager (filters, columns) {
  return {
    type: searchActionTypes.FETCH_PAP_SERVICES_DOWNLOAD_FROM_MANAGER,
    filters,
    columns
  }
}

export function fetchPlanLogiticsPap (filters) {
  return {
    type: searchActionTypes.FETCH_PLAN_LOGISTICS_PAP,
    filters
  }
}

export function fetchPlanLogiticsPapSuccess (data, filters) {
  return {
    type: searchActionTypes.FETCH_PLAN_LOGISTICS_PAP_SUCCESS,
    data,
    filters
  }
}

export function fetchProcessingAgencies (filters) {
  return {
    type: searchActionTypes.FETCH_PROCESSING_AGENCIES,
    filters
  }
}

export function fetchProcessingAgenciesSuccess (data, filters) {
  return {
    type: searchActionTypes.FETCH_PROCESSING_AGENCIES_SUCCESS,
    data,
    filters
  }
}

export function fetchProcessingAgenciesDownload (filters, columns) {
  return {
    type: searchActionTypes.FETCH_PROCESSING_AGENCIES_DOWNLOAD,
    filters,
    columns
  }
}

export function fetchFleetReport (filters) {
  return {
    type: searchActionTypes.FETCH_FLEET_REPORT,
    filters
  }
}

export function fetchFleetReportSuccess (data, filters) {
  return {
    type: searchActionTypes.FETCH_FLEET_REPORT_SUCCESS,
    data,
    filters
  }
}

export function fetchFleetReportDownload (filters, columns) {
  return {
    type: searchActionTypes.FETCH_FLEET_REPORT_DOWNLOAD,
    filters,
    columns
  }
}

export function fetchStockManagementDossiersSuccess (data, filters) {
  return {
    type: searchActionTypes.FETCH_STOCK_MANAGEMENT_DOSSIERS_SUCCESS,
    data,
    filters
  }
}

export function fetchStockManagementDossiers (filters) {
  return {
    type: searchActionTypes.FETCH_STOCK_MANAGEMENT_DOSSIERS,
    filters
  }
}

export function fetchStockManagementDossiersDownload (filters) {
  return {
    type: searchActionTypes.FETCH_STOCK_MANAGEMENT_DOSSIERS_DOWNLOAD,
    filters
  }
}

export function searchSectionChange (secMapSearch) {
  return {
    type: searchActionTypes.SEARCH_SECTION_CHANGE,
    secMapSearch
  }
}

export function setHideFilters (hideFilters) {
  return {
    type: searchActionTypes.SET_HIDE_FILTERS,
    hideFilters
  }
}

export function setFields (fields) {
  return {
    type: searchActionTypes.SET_FIELDS_SUCCESS,
    fields
  }
}
