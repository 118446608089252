import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import DocumentDetailsFleetModal from '../../../components/management/AuxModals/DocumentDetailsFleetModal'
import { closeDocumentDetailsFleetModal, fetchDocumentDetailsFleetSuccess, fetchDocumentDetailsFleet } from '../../../actions/management/auxModals/auxModals'
import { refreshDocumentManagementModal } from '../../../actions/dossier/common/documentManagement'
import { openDocumentEditionModal, openDocumentEditionModalFleet } from '../../../actions/dossier/common/documentEditionModal'
import { openModal } from '../../../actions/common'

export function mapStateToProps (state) {
  return {
    ...state.managementAuxModals.documentDetailsFleetModal,
    combos: {
      fleetDocumentActionsCombo: state.combos.fleetDocumentActionsCombo
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      closeDocumentDetailsFleetModal,
      fetchDocumentDetailsFleetSuccess,
      fetchDocumentDetailsFleet,
      refreshDocumentManagementModal,
      openDocumentEditionModal,
      openModal,
      openDocumentEditionModalFleet
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(DocumentDetailsFleetModal))
