import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import UsageReport from '../../../components/report/usage/UsageReport'
import { fetchUsageList, fetchUsageInfo, downloadUsageList, downloadUsageInfo, clearSearch, clearDynamicFilters, clearSearchResultList } from '../../../actions/reports/usageReport'
import { fetchUsageProcessTypesCombo, fetchUserCombo, fetchOrganizedUnitsAcceptClientsCombo } from '../../../actions/combos/combos'
import { openModal } from '../../../actions/common'

export function mapStateToProps (state) {
  return {
    ...state.usageReport,
    combos: {
      UOAcceptClientsCombo: state.combos.UOAcceptClientsCombo,
      usageProcessTypesCombo: state.combos.usageProcessTypesCombo,
      usersCombo: state.combos.usersCombo
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchUsageList,
      fetchUsageInfo,
      downloadUsageList,
      downloadUsageInfo,
      fetchUsageProcessTypesCombo,
      fetchUserCombo,
      fetchOrganizedUnitsAcceptClientsCombo,
      clearSearch,
      clearDynamicFilters,
      clearSearchResultList,
      openModal
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(UsageReport))
