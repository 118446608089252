export default {
  OPEN_DOCUMENT_MANAGEMENT_MODAL: 'OPEN_DOCUMENT_MANAGEMENT_MODAL',
  OPEN_DOCUMENT_MANAGEMENT_MODAL_SUCCESS: 'OPEN_DOCUMENT_MANAGEMENT_MODAL_SUCCESS',
  REFRESH_DOCUMENT_MANAGEMENT_MODAL: 'REFRESH_DOCUMENT_MANAGEMENT_MODAL',
  REFRESH_DOCUMENT_MANAGEMENT_MODAL_SUCCESS: 'REFRESH_DOCUMENT_MANAGEMENT_MODAL_SUCCESS',
  CLOSE_DOCUMENT_MANAGEMENT_MODAL: 'CLOSE_DOCUMENT_MANAGEMENT_MODAL',
  CLOSE_DOCUMENT_MANAGEMENT_MODAL_CALLBACKS: 'CLOSE_DOCUMENT_MANAGEMENT_MODAL_CALLBACKS',
  CANCEL_DOCUMENT: 'CANCEL_DOCUMENT',
  ADD_DEPENDANT: 'ADD_DEPENDANT',
  PRINT_CHECK_LIST: 'PRINT_CHECK_LIST',
  PRINT_BY_REFERENCE_TYPE: 'PRINT_BY_REFERENCE_TYPE',
  DOSSIER_DOCUMENT_CHANGED: 'DOSSIER_DOCUMENT_CHANGED',
  ENTITY_DOCUMENT_CHANGED: 'ENTITY_DOCUMENT_CHANGED',
  VEHICLE_DOCUMENT_CHANGED: 'VEHICLE_DOCUMENT_CHANGED',
  SET_CURRENT_TAB_COUNTER_VALUES: 'SET_CURRENT_TAB_COUNTER_VALUES',
  SAVE_CHECK_LIST_RECIBO_PAGA_Y_SENAL: 'SAVE_CHECK_LIST_RECIBO_PAGA_Y_SENAL',
  SIGN_DOCUMENT: 'SIGN_DOCUMENT',
  OPEN_MODAL_VEHICLE_NECESSARY: 'OPEN_MODAL_VEHICLE_NECESSARY',
  REFRESH_FORM_ERRORS: 'REFRESH_FORM_ERRORS'
}
