import React, { PureComponent } from 'react'

class WindowDimensions extends PureComponent {
  updateDimensions () {
    const w = window
    const d = document
    const documentElement = d.documentElement
    const body = d.getElementsByTagName('body')[0]
    const width = w.innerWidth || documentElement.clientWidth || body.clientWidth
    const height = w.innerHeight || documentElement.clientHeight || body.clientHeight
    this.props.actions.setWindowDimensions(width, height)
  }

  componentDidMount () {
    this.updateDimensions()
    window.addEventListener('resize', this.updateDimensions.bind(this))
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.updateDimensions.bind(this))
  }

  render () {
    return (
      <div></div>
    )
  }
}

export default WindowDimensions
