import apiFetch from '../apiFetch'

export default function (token, { page, pageSize, orderBy, chassis, licensePlate, dealerNumber }) {
  const queryParamsArray = [
    page ? `page=${page}` : null,
    pageSize ? `pageSize=${pageSize}` : null,
    orderBy ? `orderBy=${orderBy}` : null,
    chassis ? `vehicleVinChasis=${chassis}` : null,
    licensePlate ? `vehicleLicensePlate=${licensePlate}` : null,
    dealerNumber ? `dealerNumber=${dealerNumber}` : null
  ]
  const queryParams = queryParamsArray.reduce(
    (result, query, idx) => result + (query ? (result ? `&${query}` : `?${query}`) : '')
    , ''
  )
  return apiFetch({
    endPoint: `dossierFinder/VehiclesSimple${queryParams}`,
    method: 'GET',
    body: null,
    token: token
  })
}
