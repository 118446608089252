import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import SuppliedMasters from '../../../components/masters/supplied/SuppliedMasters'
import { translate } from 'react-polyglot'
import { openSuppliedModal, deleteSuppliedModal, fetchSuppliedMasters, recoverSuppliedModal } from '../../../actions/masters/masters'
import { fetchOrganizedUnitsTree } from '../../../actions/combos/combos'

export function mapStateToProps (state) {
  return {
    combos: {
      UOTreeCombo: state.combos.UOTreeCombo
    },
    suppliedMastersList: state.entityMasters.suppliedMastersList
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      openSuppliedModal,
      deleteSuppliedModal,
      fetchSuppliedMasters,
      recoverSuppliedModal,
      fetchOrganizedUnitsTree
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(SuppliedMasters))
