import React, { PureComponent } from 'react'
import { reduxForm, Field } from 'redux-form'
import { Row, Col, Table, Button, Clearfix } from 'react-bootstrap'
import IDocCarPagination from '../../commons/iDocCarPagination'
import InputText from '../../commons/form/InputText'
import InputDatePicker from '../../commons/form/InputDatePicker'
import InputSelect from '../../commons/form/InputSelect'
import moment from 'moment'

class WalletReport extends PureComponent {
  constructor () {
    super()
    this.state = {
      filter: {
        searchSociety: null,
        searchBrand: null,
        searchModel: null,
        searchSalesman: null,
        searchDateOrderInitial: null,
        searchDateOrderEnd: null,
        searchTypeVehicle: null
      }
    }
  }

  searchWallet () {
    this.props.actions.fetchWalletList({ ...this.props.filter, ...this.state.filter, page: 1 })
  }

  downloadWallet () {
    this.props.actions.downloadWalletList({ ...this.props.filter, ...this.state.filter, page: 1 })
  }

  componentDidMount () {
    this.props.actions.fetchBrands()
    this.props.actions.fetchVehicleType()
    this.searchWallet()
  }

  handleSocietyChange (searchSociety) {
    if (this.state.filter.searchSociety !== searchSociety) {
      this.setState({ filter: { ...this.state.filter, searchSociety: searchSociety } })
    }
  }

  handleBrandChange (searchBrand) {
    if (this.state.filter.searchBrand !== searchBrand) {
      this.setState({ filter: { ...this.state.filter, searchBrand: searchBrand } })
    }
  }

  handleModelChange (searchModel) {
    if (this.state.filter.searchModel !== searchModel) {
      this.setState({ filter: { ...this.state.filter, searchModel: searchModel } })
    }
  }

  handleSalesmanChange (searchSalesman) {
    if (this.state.filter.searchSalesman !== searchSalesman) {
      this.setState({ filter: { ...this.state.filter, searchSalesman: searchSalesman } })
    }
  }

  handleDateOrderInitialChange (searchDateOrderInitial) {
    if (this.state.filter.searchDateOrderInitial !== searchDateOrderInitial && searchDateOrderInitial !== null) {
      const [dd, mm, yyyy] = searchDateOrderInitial.split('/')
      this.setState({ filter: { ...this.state.filter, searchDateOrderInitial: new Date(`${yyyy}-${mm}-${dd}T00:00:01.000Z`) } })
    } else {
      this.setState({ filter: { ...this.state.filter, searchDateOrderInitial: null } })
    }
  }

  handleDateOrderEndChange (searchDateOrderEnd) {
    if (this.state.filter.searchDateOrderEnd !== searchDateOrderEnd && searchDateOrderEnd !== null) {
      const [dd, mm, yyyy] = searchDateOrderEnd.split('/')
      this.setState({ filter: { ...this.state.filter, searchDateOrderEnd: new Date(`${yyyy}-${mm}-${dd}T23:59:59.000Z`) } })
    } else {
      this.setState({ filter: { ...this.state.filter, searchDateOrderEnd: null } })
    }
  }

  handleTypeVehicleChange (searchTypeVehicle) {
    if (this.state.filter.searchTypeVehicle !== searchTypeVehicle) {
      this.setState({ filter: { ...this.state.filter, searchTypeVehicle: searchTypeVehicle } })
    }
  }

  render () {
    const {
      t, walletList, pagesCount, filter, walletCount,
      filter: { page },
      actions: { fetchWalletList },
      combos: { brandCombo, salesmanCombo, lowestOrganizedUnitsCombo, vehicletypeCombo }
    } = this.props
    const tKey = 'REPORTS.'
    return (
      <div className="admin-wrapper">
        <Row className="section-users-header">
          <Col sm={12}>
            <div className="header-panel">
              <h4>{t(`${tKey}WALLET.WALLET_TITLE`)}</h4>
            </div>
          </Col>
        </Row>
        <Row>
          <div className="search-form">
            <span className="title-search"><i className="ico-search" /> {t(`${tKey}WALLET.SEARCH_TITLE`)}</span>
            <Row>
              <Field
                colSm={3}
                id="society"
                name="filters.society"
                controlLabel={t(`${tKey}WALLET.SOCIETY`)}
                placeholder={t(`${tKey}WALLET.SOCIETY`)}
                component={InputSelect}
                options={lowestOrganizedUnitsCombo}
                valueKey="id"
                labelKey="value"
                onInputChange={(value) => this.handleSocietyChange(value)}
              />
              <Field
                colSm={3}
                id="vehicleBrand"
                name="filters.vehicleBrand"
                controlLabel={t(`${tKey}WALLET.BRAND`)}
                placeholder={t(`${tKey}WALLET.BRAND`)}
                component={InputSelect}
                valueKey="id"
                labelKey="value"
                options={brandCombo}
                onInputChange={(value) => this.handleBrandChange(value)}
              />
              <Field
                id="model"
                name="filters.model"
                colSm={3}
                controlLabel={t(`${tKey}WALLET.MODEL`)}
                placeholder={t(`${tKey}WALLET.MODEL`)}
                component={InputText}
                onInputChange={(value) => this.handleModelChange(value)}
              />
              <Field
                id="salesman"
                name='filters.salesman'
                placeholder={t(`${tKey}WALLET.SALESMAN`)}
                controlLabel={t(`${tKey}WALLET.SALESMAN`)}
                colSm={3}
                component={InputSelect}
                options={salesmanCombo}
                valueKey="id"
                labelKey="value"
                onInputChange={(value) => this.handleSalesmanChange(value)}
              />
              <Clearfix/>
              <Field
                id="initialdate"
                name="filters.initialdate"
                colSm={3}
                controlLabel={t(`${tKey}WALLET.INITIAL_ORDER_DATE`)}
                placeholder={t(`${tKey}WALLET.INITIAL_ORDER_DATE`)}
                component={InputDatePicker}
                onInputChange={(value) => this.handleDateOrderInitialChange(value)}
              />
              <Field
                id="enddate"
                name="filters.enddate"
                colSm={3}
                controlLabel={t(`${tKey}WALLET.END_ORDER_DATE`)}
                placeholder={t(`${tKey}WALLET.END_ORDER_DATE`)}
                component={InputDatePicker}
                onInputChange={(value) => this.handleDateOrderEndChange(value)}
              />
              <Field
                name="filters.vechicle"
                controlLabel={t(`${tKey}WALLET.TYPE_VEHICLE`)}
                placeholder={t(`${tKey}WALLET.TYPE_VEHICLE`)}
                component={InputSelect}
                colSm={3}
                multi={false}
                valueKey="id"
                labelKey="value"
                childrenKey="children"
                options={vehicletypeCombo}
                onInputChange={(value) => this.handleTypeVehicleChange(value)}
              />
              <Col sm={3} className="btn-wrapper">
                <Button
                  className={'btn-white btn-icon btn-input btn-white search-button'}
                  style={{ width: '100%' }}
                  onClick={() => this.searchWallet()}>
                  <i className="ico-search" />
                  {t(`${tKey}SEARCH`)}
                </Button>
              </Col>
            </Row>
          </div>
        </Row>
        <Col>
          <Button
            className={'btn-white btn-icon btn-input btn-white'}
            style={{ float: 'right' }}
            onClick={() => this.downloadWallet()}>
            {t(`${tKey}DOWNLOAD`)}
          </Button>
        </Col>
        <Col sm={12} className="col-row  section-users-body">
          <Table className="editable-table admin-users" hover responsive>
            <thead>
              <tr>
                <th>
                  {t(`${tKey}WALLET.DOSSIER_SALE`)}&nbsp;
                </th>
                <th>
                  {t(`${tKey}WALLET.DOSSIER_BUY`)}&nbsp;
                </th>
                <th>
                  {t(`${tKey}WALLET.SOCIETY`)}&nbsp;
                </th>
                <th>
                  {t(`${tKey}WALLET.BRAND`)}&nbsp;
                </th>
                <th>
                  {t(`${tKey}WALLET.MODEL`)}&nbsp;
                </th>
                <th>
                  {t(`${tKey}WALLET.VINCHASSIS`)}&nbsp;
                </th>
                <th>
                  {t(`${tKey}WALLET.CLIENT`)}&nbsp;
                </th>
                <th>
                  {t(`${tKey}WALLET.SALESMAN`)}&nbsp;
                </th>
                <th>
                  {t(`${tKey}WALLET.ORDER_DATE`)}&nbsp;
                </th>
                <th>
                  {t(`${tKey}WALLET.TYPE_VEHICLE`)}&nbsp;
                </th>
                <th>
                  {t(`${tKey}WALLET.ARRIVAL_DATE`)}&nbsp;
                </th>
              </tr>
            </thead>
            <tbody>
              {walletList && walletList.map((wallet, idx) => {
                return (
                  <tr key={idx} className = "">
                    <td>{wallet.masterDossierNumber}</td>
                    <td>{wallet.parentDossierNumber}</td>
                    <td>{wallet.organizedUnitName}</td>
                    <td>{wallet.brandName}</td>
                    <td>{wallet.model}</td>
                    <td>{wallet.vinChassis}</td>
                    <td>{wallet.entityName}</td>
                    <td>{wallet.salesmanName}</td>
                    <td>{moment(wallet.orderDate).format('DD/MM/YYYY')}</td>
                    <td>{wallet.vehicleType != null && wallet.vehicleType.name}</td>
                    <td>{moment(wallet.deliveryDate).format('DD/MM/YYYY')}</td>
                  </tr>)
              })
              }
              {(walletList === null || walletList === undefined || walletList.length === 0) &&
            <tr>
              <td colSpan={12}><h2>{t(`${tKey}RESULTS_NOT_FOUND`)}</h2></td>
            </tr>}
            </tbody>
          </Table>
          <div className="section-users-footer">
            <nav key={1} className="text-center">
              <IDocCarPagination
                id="btn_pag_sales"
                page={page}
                pagesCount={pagesCount}
                totalRows={walletCount}
                onSelectAction={(value) => fetchWalletList({ ...filter, ...this.state.filter, page: value })}
              />
            </nav>
          </div>
        </Col>
      </div>
    )
  }
}

export default reduxForm({
  form: 'listWallet'
})(WalletReport)
