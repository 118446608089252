import { put, call, select, takeEvery } from 'redux-saga/effects'
import { getAuth } from '../../selectors/access/auth'
import deviceActionTypes from '../../constants/actions/idocmobile/deviceInstall'
import { fetchDeviceInstallsSuccess, fetchMaxDeviceInstallsSuccess, updateDeviceInstallSuccess } from '../../actions/idocmobile/deviceInstall'
import { handleError } from '../errors/errorManager'
import getDeviceInstallations from '../../services/idocmobile/getDeviceInstallations'
import getMaxNumberInstallation from '../../services/idocmobile/getMaxNumberInstallation'
import updateDeviceInstallation from '../../services/idocmobile/updateDeviceInstallation'
import { showLoader, hideLoader } from '../../actions/common.js'

export function * fetchDeviceInstalls ({ userId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const devices = yield call(getDeviceInstallations, auth.token, userId)
    yield put(fetchDeviceInstallsSuccess(devices))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchfetchDeviceInstalls () {
  yield takeEvery(deviceActionTypes.FETCH_DEVICE_INSTALLS, fetchDeviceInstalls)
}

export function * fetchMaxDeviceInstalls ({ userId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const max = yield call(getMaxNumberInstallation, auth.token, userId)
    yield put(fetchMaxDeviceInstallsSuccess(max))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchfetchMaxDeviceInstalls () {
  yield takeEvery(deviceActionTypes.FETCH_MAX_DEVICE_INSTALLS, fetchMaxDeviceInstalls)
}

export function * updateDeviceInstall ({ device }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const deviceUpdate = yield call(updateDeviceInstallation, device, auth.token)
    yield put(updateDeviceInstallSuccess(deviceUpdate))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchupdateDeviceInstall () {
  yield takeEvery(deviceActionTypes.UPDATE_DEVICE_INSTALL, updateDeviceInstall)
}
