import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { closeCodeCommissionModal, createOrUpdateCodeCommission } from '../../../actions/masters/masters'
import CodeCommissionMastersModal from '../../../components/masters/codeCommission/CodeCommissionMasterModal'

export function mapStateToProps (state) {
  return {
    showModal: state.entityMasters.showCodeCommissionModal,
    codeCommission: state.entityMasters.codeCommissionModalData,
    languageList: state.entityMasters.languageList,
    combos: {
      organizedUnitsAllCombo: state.combos.organizedUnitsAllCombo
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      closeCodeCommissionModal,
      createOrUpdateCodeCommission
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(CodeCommissionMastersModal))
