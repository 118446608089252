import apiFetch from '../apiFetch'

export default function (token, filter, columns) {
  const queryParamsArray = [
    columns ? `columns=${columns}` : null
  ]
  const queryParams = queryParamsArray.reduce(
    (result, query, idx) => result + (query ? (result ? `&${query}` : `?${query}`) : '')
    , ''
  )
  return apiFetch({
    endPoint: `report/CommercialReportTableExport${queryParams}`,
    method: 'POST',
    body: filter,
    token: token,
    parseBlobAndAssign: true
  })
}
