import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import SingUploadSign from '../../../components/admin/user/UserUploadSign/SingUploadSign'
import { setWindowDimensions } from '../../../actions/commons/windowDimensions'
import { checkCanCloseSignModal, closeUploadSignModal, uploadSign } from '../../../actions/admin/userAdmin'
import { okResponse } from '../../../actions/modals/customModal'

export function mapStateToProps (state) {
  const signModal = state.userAdmin.userAdminModal
  const commonModal = state.common.modal
  return {
    showModalUpload: signModal.showModalUpload,
    windowWidth: state.windowDimensions.width,
    windowHeight: state.windowDimensions.height,
    token: state.auth.token,
    closeCommonModal: commonModal.showModalUpload,
    userId: signModal.userId
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      setWindowDimensions,
      checkCanCloseSignModal,
      closeUploadSignModal,
      uploadSign,
      okResponse
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(SingUploadSign))
