export const searchType = {
  VEHICLE: 1,
  ENTITY: 2,
  DOSSIER: 3,
  DAMAGE: 4,
  CHARGE: 5,
  DOSSIER_SIMPLE: 6,
  VEHICLE_SIMPLE: 7,
  RENTING: 8,
  FLEETS: 9,
  SELFSUPPLY: 10,
  PREPARE: 11,
  COMMERCIAL_REPORT: 12,
  PAP_SERVICES_MANAGER: 13,
  PLAN_LOGISTICS_PAP: 14,
  PROCESSING_AGENCY: 15,
  FLEET_REPORT: 16,
  STOCK_MANAGEMENT: 17
}

export const searchTabCode = {
  VEHICLE: 'vehicle',
  ENTITY: 'entity',
  DOSSIER: 'dossier',
  DAMAGE: 'damageDossier',
  CHARGE: 'charge',
  DOSSIER_SIMPLE: 'dossierSimple',
  VEHICLE_SIMPLE: 'vehicleSimple',
  RENTING: 'rentingCharge',
  FLEETS: 'fleets',
  PREPARE: 'prepare',
  COMMERCIAL_REPORT: 'commercialReport',
  PAP_SERVICES_MANAGER: 'papServicesManager',
  PLAN_LOGISTICS_PAP: 'planLogisticsPap',
  PROCESSING_AGENCY: 'processingAgency',
  FLEET_REPORT: 'fleetReport',
  STOCK_MANAGEMENT: 'stockManagementSearch'
}
