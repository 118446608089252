import apiFetch from '../apiFetch'

export default function (faqId, faqSectionId, rating, faqValues, token) {
  const body = {
    FaqSectionId: faqSectionId,
    FaqId: faqId,
    Disabled: false,
    Rating: rating,
    TranslationsFaq: faqValues
  }
  return apiFetch({ endPoint: `Faq/${faqId}`, method: 'PUT', body: body, token: token })
}
