export default {
  FETCH_USAGE_REPORT: 'FETCH_USAGE_REPORT',
  FETCH_USAGE_REPORT_SUCCESS: 'FETCH_USAGE_REPORT_SUCCESS',
  DOWNLOAD_USAGE_REPORT: 'DOWNLOAD_USAGE_REPORT',

  FETCH_USAGE_INFO: 'FETCH_USAGE_INFO',
  FETCH_USAGE_INFO_SUCCESS: 'FETCH_USAGE_INFO_SUCCESS',
  DOWNLOAD_USAGE_INFO: 'DOWNLOAD_USAGE_INFO',

  CLEAR_SEARCH: 'CLEAR_SEARCH',
  CLEAR_DYNAMIC_FILTERS: 'CLEAR_DYNAMIC_FILTERS',
  CLEAR_SEARCH_RESULT_LIST: 'CLEAR_SEARCH_RESULT_LIST'
}
