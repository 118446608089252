export default {
  SET_ENTITY_TYPE_ID: 'SET_ENTITY_TYPE_ID',
  SET_ENTITY_SUBTYPE_ID: 'SET_ENTITY_SUBTYPE_ID',
  SET_ENTITY_ID: 'SET_ENTITY_ID',
  SET_ENTITY_PROVIDER_ID: 'SET_ENTITY_PROVIDER_ID',
  FETCH_CLIENT_DNI: 'FETCH_CLIENT_DNI',
  FETCH_CLIENT_FROM_DOCUMENT: 'FETCH_CLIENT_FROM_DOCUMENT',
  FETCH_CLIENT_FROM_PARENT: 'FETCH_CLIENT_FROM_PARENT',
  SET_ENTITY_CHILD_TYPE_ID: 'SET_ENTITY_CHILD_TYPE_ID',
  FETCH_CLIENT_DNI_ENTITY_CHILD: 'FETCH_CLIENT_DNI_ENTITY_CHILD',
  CLEAR_ENTITY: 'CLEAR_ENTITY',
  CLEAR_ENTITY_CHILD: 'CLEAR_ENTITY_CHILD',
  FETCH_CLIENT_EMAIL: 'FETCH_CLIENT_EMAIL',
  SET_ENTITY_VALIDATION_DISABLE: 'SET_ENTITY_VALIDATION_DISABLE',
  FETCH_CLIENT_UAC_MODAL: 'FETCH_CLIENT_UAC_MODAL',
  FETCH_DEFAULT_ENTITY: 'FETCH_DEFAULT_ENTITY',
  CLEAR_CLIENT_FIELDS: 'CLEAR_CLIENT_FIELDS',
  FETCH_CLIENT_RFC: 'FETCH_CLIENT_RFC',
  SET_ENTITY_PROVIDER_TYPE_ID: 'SET_ENTITY_PROVIDER_TYPE_ID',
  SET_ENTITY_PROVIDER_SUBTYPE_ID: 'SET_ENTITY_PROVIDER_SUBTYPE_ID',
  SET_ENTITY_PROVIDER_VALIDATION_DISABLE: 'SET_ENTITY_VALIDATION_DISABLE',
  FETCH_PROVIDER_FROM_DOCUMENT: 'FETCH_PROVIDER_FROM_DOCUMENT',
  FETCH_CLIENT_PHONE_EMAIL: 'FETCH_CLIENT_PHONE_EMAIL',
  RESET_VALUES: 'RESET_VALUES'
}
