import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { getChargesDetailsFleet, getChargesDetailsFleetSuccess } from '../../../actions/management/management'
import { openModal } from '../../../actions/common'
import { openDocumentEditionModal, openDocumentEditionModalFleet } from '../../../actions/dossier/common/documentEditionModal'
import { openChargeDetailsModal } from '../../../actions/management/auxModals/auxModals'

import ChargeFleetListComponent from '../../../components/management/commons/ChargeFleetListComponent'

export function mapStateToProps (state) {
  return {
    ...state.management.chargeFleetComponent
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      openModal,
      getChargesDetailsFleet,
      getChargesDetailsFleetSuccess,
      openDocumentEditionModal,
      openDocumentEditionModalFleet,
      openChargeDetailsModal
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(ChargeFleetListComponent))
