import dossierActionTypes from '../../constants/actions/dossier/dossier'
import { refreshAuditInfo, fetchAuditInfoSucces, fetchAuditDetails, fetchAuditDetailsModal, fetchDocumentsGroupedAuditDetails, fetchDocumentsGroupedAuditDetailsModal } from '../../actions/audit/auditInfo'

export function fetchSalesDossier (dossierId, resolve, reject, historical) {
  return {
    type: dossierActionTypes.FETCH_SALES_DOSSIER,
    dossierId,
    resolve,
    reject,
    historical
  }
}

export function fetchSalesVehicle (dossierId, resolve, reject, pristine) {
  return {
    type: dossierActionTypes.FETCH_SALES_DOSSIER_VEHICLE,
    dossierId,
    resolve,
    reject,
    pristine
  }
}

export function fetchSalesVehicleHistorical (dossierId, resolve, reject) {
  return {
    type: dossierActionTypes.FETCH_SALES_DOSSIER_VEHICLE_HISTORICAL,
    dossierId,
    resolve,
    reject
  }
}

export function fetchSalesClient (dossierId, resolve, reject, pristine) {
  return {
    type: dossierActionTypes.FETCH_SALES_DOSSIER_CLIENT,
    dossierId,
    resolve,
    reject,
    pristine
  }
}

export function fetchSalesClientHistorical (dossierId, resolve, reject) {
  return {
    type: dossierActionTypes.FETCH_SALES_DOSSIER_CLIENT_HISTORICAL,
    dossierId,
    resolve,
    reject
  }
}

export function fetchSalesFinancing (dossierId, resolve, reject, pristine) {
  return {
    type: dossierActionTypes.FETCH_SALES_DOSSIER_FINANCING,
    dossierId,
    resolve,
    reject,
    pristine
  }
}

export function fetchSalesInsuranceCarrier (dossierId, resolve, reject, pristine) {
  return {
    type: dossierActionTypes.FETCH_SALES_DOSSIER_INSURANCE_CARRIER,
    dossierId,
    resolve,
    reject,
    pristine
  }
}

export function fetchSalesInsuranceCarrierHistorical (dossierId, resolve, reject, pristine) {
  return {
    type: dossierActionTypes.FETCH_SALES_DOSSIER_INSURANCE_CARRIER_HISTORICAL,
    dossierId,
    resolve,
    reject,
    pristine
  }
}

export function fetchSalesFinancingHistorical (dossierId, resolve, reject) {
  return {
    type: dossierActionTypes.FETCH_SALES_DOSSIER_FINANCING_HISTORICAL,
    dossierId,
    resolve,
    reject
  }
}

export function removeFinance (pristine, resolve) {
  return {
    type: dossierActionTypes.REMOVE_FINANCE,
    pristine,
    resolve
  }
}

export function cleanFinance (resolve) {
  return {
    type: dossierActionTypes.CLEAN_FINANCE,
    resolve
  }
}

export function fetchSalesDelivery (dossierId, resolve, reject, pristine) {
  return {
    type: dossierActionTypes.FETCH_SALES_DOSSIER_DELIVERY,
    dossierId,
    resolve,
    reject,
    pristine
  }
}

export function fetchSalesDeliveryHistorical (dossierId, resolve, reject) {
  return {
    type: dossierActionTypes.FETCH_SALES_DOSSIER_DELIVERY_HISTORICAL,
    dossierId,
    resolve,
    reject
  }
}

export function fetchSalesDossierDependants (dossierId, historical) {
  return {
    type: dossierActionTypes.FETCH_SALES_DOSSIER_DEPENDANTS,
    dossierId,
    historical
  }
}
export function fetchSalesDossierDependantsEconomic (dossierId) {
  return {
    type: dossierActionTypes.FETCH_SALES_DOSSIER_DEPENDANTS_ECONOMIC,
    dossierId
  }
}
export function fetchSalesOtherData (dossierId, resolve, reject, pristine) {
  return {
    type: dossierActionTypes.FETCH_SALES_DOSSIER_OTHERDATA,
    dossierId,
    resolve,
    reject,
    pristine
  }
}
export function fetchSalesOtherDataHistorical (dossierId, resolve, reject, pristine) {
  return {
    type: dossierActionTypes.FETCH_SALES_DOSSIER_OTHERDATA_HISTORICAL,
    dossierId,
    resolve,
    reject,
    pristine
  }
}
export function fetchSalesDossierSuccess (data) {
  return {
    type: dossierActionTypes.FETCH_SALES_DOSSIER_SUCCESS,
    data
  }
}

export function postPaymentSignalStatusSuccess () {
  return {
    type: dossierActionTypes.POST_PAYMENT_SIGNAL_STATUS_SUCCESS
  }
}

export function updateLastAccessDate (lastAccessDateNew) {
  return {
    type: dossierActionTypes.PUT_LASTACCESSDATE,
    lastAccessDateNew
  }
}

export function fetchInitializeSalesComponent (saleTypeId, ouId) {
  return {
    type: dossierActionTypes.FETCH_INITIALIZE_SALES_COMPONENT,
    saleTypeId,
    ouId
  }
}

export function printDocumentaryChecklist () {
  return {
    type: dossierActionTypes.PRINT_DOCUMENTARY_CHECKLIST
  }
}

export function printDocumentaryWarranty () {
  return {
    type: dossierActionTypes.PRINT_DOCUMENTARY_WARRANTY
  }
}

export function saveSalesDossier (salesDossier, resolve, reject) {
  return {
    type: dossierActionTypes.SAVE_SALES_DOSSIER,
    salesDossier,
    resolve,
    reject
  }
}

export function saveFleetCode (code, resolve, reject) {
  return {
    type: dossierActionTypes.SAVE_FLEET_CODE,
    code,
    resolve,
    reject
  }
}

export function updateDiscount (discount, resolve, reject, dossierType) {
  return {
    type: dossierActionTypes.UPDATE_DISCOUNT,
    discount,
    resolve,
    reject,
    dossierType
  }
}

export function updateDiscountSuccess (discounts) {
  return {
    type: dossierActionTypes.UPDATE_DISCOUNT_SUCCESS,
    discounts
  }
}

export function createDiscount (newDiscount, resolve, reject, dossierType) {
  return {
    type: dossierActionTypes.CREATE_DISCOUNT,
    newDiscount,
    resolve,
    reject,
    dossierType
  }
}

export function deleteDiscount (discount, dossierType) {
  return {
    type: dossierActionTypes.DELETE_DISCOUNT,
    discount,
    dossierType
  }
}

export function fetchDiscountsSuccess (discounts) {
  return {
    type: dossierActionTypes.FETCH_DISCOUNT_SUCCESS,
    discounts
  }
}

export function updateOptionalSuccess (optionals) {
  return {
    type: dossierActionTypes.UPDATE_OPTIONAL_SUCCESS,
    optionals
  }
}

export function updateSuppliedsSuccess (supplieds) {
  return {
    type: dossierActionTypes.UPDATE_SUPPLIEDS_SUCCESS,
    supplieds
  }
}

export function updateExtraSaleSuccess (extraSales) {
  return {
    type: dossierActionTypes.UPDATE_EXTRA_SALES_SUCCESS,
    extraSales
  }
}

export function updatePresentSuccess (presents) {
  return {
    type: dossierActionTypes.UPDATE_PRESENTS_SUCCESS,
    presents
  }
}

export function updateOptional (Optional, resolve, reject) {
  return {
    type: dossierActionTypes.UPDATE_OPTIONAL,
    Optional,
    resolve,
    reject
  }
}

export function createOptional (newOptional, resolve, reject) {
  return {
    type: dossierActionTypes.CREATE_OPTIONAL,
    newOptional,
    resolve,
    reject
  }
}

export function deleteOptional (Optional) {
  return {
    type: dossierActionTypes.DELETE_OPTIONAL,
    Optional
  }
}

export function updateExtraSale (extraSale, resolve, reject, dossierType) {
  return {
    type: dossierActionTypes.UPDATE_EXTRA_SALE,
    extraSale,
    resolve,
    reject,
    dossierType
  }
}

export function createExtraSale (newExtraSale, resolve, reject, dossierType) {
  return {
    type: dossierActionTypes.CREATE_EXTRA_SALE,
    newExtraSale,
    resolve,
    reject,
    dossierType
  }
}

export function deleteExtraSale (extraSale, dossierType) {
  return {
    type: dossierActionTypes.DELETE_EXTRA_SALE,
    extraSale,
    dossierType
  }
}

export function updatePresent (present, resolve, reject, dossierType) {
  return {
    type: dossierActionTypes.UPDATE_PRESENT,
    present,
    resolve,
    reject,
    dossierType
  }
}

export function createPresent (newPresent, resolve, reject, dossierType) {
  return {
    type: dossierActionTypes.CREATE_PRESENT,
    newPresent,
    resolve,
    reject,
    dossierType
  }
}

export function deletePresent (present, dossierType) {
  return {
    type: dossierActionTypes.DELETE_PRESENT,
    present,
    dossierType
  }
}

export function updateExtra (dossierExtra, resolve, reject, dossierExtraType) {
  return {
    type: dossierActionTypes.UPDATE_EXTRA,
    dossierExtra,
    resolve,
    reject,
    dossierExtraType
  }
}

export function createExtra (dossierExtra, resolve, reject, dossierExtraType) {
  return {
    type: dossierActionTypes.CREATE_EXTRA,
    dossierExtra,
    resolve,
    reject,
    dossierExtraType
  }
}

export function deleteExtra (dossierExtra, dossierExtraType) {
  return {
    type: dossierActionTypes.DELETE_EXTRA,
    dossierExtra,
    dossierExtraType
  }
}

export function fetchExtrasSuccess (dossierExtras, dossierExtraType) {
  return {
    type: dossierActionTypes.FETCH_EXTRAS_SUCCESS,
    dossierExtras,
    dossierExtraType
  }
}

export function updateSupplied (dossierSupplied, resolve, reject, dossierType) {
  return {
    type: dossierActionTypes.UPDATE_SUPPLIED,
    dossierSupplied,
    resolve,
    reject,
    dossierType
  }
}

export function createSupplied (dossierSupplied, resolve, reject, dossierType) {
  return {
    type: dossierActionTypes.CREATE_SUPPLIED,
    dossierSupplied,
    resolve,
    reject,
    dossierType
  }
}

export function deleteSupplied (dossierSupplied, dossierType) {
  return {
    type: dossierActionTypes.DELETE_SUPPLIED,
    dossierSupplied,
    dossierType
  }
}

export function fetchSuppliedListSuccess (dossierSuppliedList) {
  return {
    type: dossierActionTypes.FETCH_SUPPLIED_LIST_SUCCESS,
    dossierSuppliedList
  }
}

export function fetchCampaignEdition (campaignId, openCampaignEditionFn) {
  return {
    type: dossierActionTypes.FETCH_CAMPAIGN_EDITION,
    campaignId,
    openCampaignEditionFn
  }
}

export function deleteCampaign (campaignId) {
  return {
    type: dossierActionTypes.DELETE_CAMPAIGN,
    campaignId
  }
}

export function updateCampaign (campaign, closeModalFunc) {
  return {
    type: dossierActionTypes.UPDATE_CAMPAIGN,
    campaign,
    closeModalFunc
  }
}

export function createCampaign (campaign, closeModalFunc) {
  return {
    type: dossierActionTypes.CREATE_CAMPAIGN,
    campaign,
    closeModalFunc
  }
}

export function fetchCampaignsSuccess (campaigns) {
  return {
    type: dossierActionTypes.FETCH_CAMPAIGNS_SUCCESS,
    campaigns
  }
}

export function fetchCampaignsTableSuccess (campaignSubtype) {
  return {
    type: dossierActionTypes.FETCH_CAMPAIGNSTABLE_SUCCESS,
    campaignSubtype
  }
}

export function fetchCampaignsTableSuccessAdd (campaign) {
  return {
    type: dossierActionTypes.FETCH_CAMPAIGNSTABLE_SUCCESS_ADD,
    campaign
  }
}

export function fetchPaperworkEdition (paperworkId, openPaperworkEditionFn) {
  return {
    type: dossierActionTypes.FETCH_PAPERWORK_EDITION,
    paperworkId,
    openPaperworkEditionFn
  }
}

export function deletePaperwork (paperworkId) {
  return {
    type: dossierActionTypes.DELETE_PAPERWORK,
    paperworkId
  }
}

export function updatePaperwork (paperwork, closeModalFunc) {
  return {
    type: dossierActionTypes.UPDATE_PAPERWORK,
    paperwork,
    closeModalFunc
  }
}

export function createPaperwork (paperwork, closeModalFunc) {
  return {
    type: dossierActionTypes.CREATE_PAPERWORK,
    paperwork,
    closeModalFunc
  }
}

export function fetchPaperworkSuccess (paperworkList) {
  return {
    type: dossierActionTypes.FETCH_PAPERWORK_SUCCESS,
    paperworkList
  }
}

export function fetchEconomicPlanSuccess (economicPlan) {
  return {
    type: dossierActionTypes.FETCH_ECONOMIC_PLAN_SUCCESS,
    economicPlan
  }
}

export function updateService (service, resolve, reject) {
  return {
    type: dossierActionTypes.UPDATE_SERVICE,
    service,
    resolve,
    reject
  }
}

export function createService (newService, resolve, reject) {
  return {
    type: dossierActionTypes.CREATE_SERVICE,
    newService,
    resolve,
    reject
  }
}

export function deleteService (service) {
  return {
    type: dossierActionTypes.DELETE_SERVICE,
    service
  }
}

export function fetchServicesSuccess (services) {
  return {
    type: dossierActionTypes.FETCH_SERVICE_SUCCESS,
    services
  }
}

export function saleApprove (pristine) {
  return {
    type: dossierActionTypes.SALE_APPROVE,
    pristine
  }
}

export function saleApprovePaperwork (pristine, approvalRoleCode) {
  return {
    type: dossierActionTypes.SALE_APPROVE_PAPERWORK,
    pristine,
    approvalRoleCode
  }
}

export function saleReject (comment, resolve, reject, pristine) {
  return {
    type: dossierActionTypes.SALE_REJECT,
    comment,
    resolve,
    reject,
    pristine
  }
}

export function saleSkipCharge (pristine) {
  return {
    type: dossierActionTypes.SALE_SKIP_CHARGE,
    pristine
  }
}

export function saleSkipDocument (pristine) {
  return {
    type: dossierActionTypes.SALE_SKIP_DOCUMENT,
    pristine
  }
}

export function saleSkipPaperwork (pristine) {
  return {
    type: dossierActionTypes.SALE_SKIP_PAPERWORK,
    pristine
  }
}

export function saleSkipDelivery (pristine) {
  return {
    type: dossierActionTypes.SALE_SKIP_DELIVERY,
    pristine
  }
}

export function saleSendApprove (pristine) {
  return {
    type: dossierActionTypes.SALE_SEND_APPROVE,
    pristine
  }
}

export function saleSendBproDms (pristine) {
  return {
    type: dossierActionTypes.SEND_TO_BPRO_DMS,
    pristine
  }
}

export function requestInvoiceToDMS (pristine) {
  return {
    type: dossierActionTypes.REQUEST_INVOICE_TO_DMS,
    pristine
  }
}

export function requestDeliveryToDMS (pristine) {
  return {
    type: dossierActionTypes.REQUEST_DELIVERY_TO_DMS,
    pristine
  }
}

export function requestOutboundToDMS (pristine) {
  return {
    type: dossierActionTypes.REQUEST_OUTBOUND_TO_DMS,
    pristine
  }
}

export function saleTransferRequest (pristine) {
  return {
    type: dossierActionTypes.SALE_TRANSFER_REQUEST,
    pristine
  }
}

export function saleTransferRequestApproved (pristine) {
  return {
    type: dossierActionTypes.SALE_TRANSFER_REQUEST_APPROVED,
    pristine
  }
}

export function updateDependantsSaleDossierAndHeader (dossierId) {
  return {
    type: dossierActionTypes.UPDATE_DEPENDANTS_SALE_DOSSIER_AND_HEADER,
    dossierId
  }
}

export function printPaperwork (paperworkId) {
  return {
    type: dossierActionTypes.PRINT_PAPERWORK,
    paperworkId
  }
}

export function restartSalesState () {
  return {
    type: dossierActionTypes.RESTART_SALES_STATE
  }
}

export function putPurchaseOnSale (purchaseId, parentIsHistorical) {
  return {
    type: dossierActionTypes.PUT_PURCHASE_ON_SALE,
    purchaseId,
    parentIsHistorical
  }
}

export function fetchDossierRelatedDossiersSuccess (dossierRelatedDossiers) {
  return {
    type: dossierActionTypes.FETCH_DOSSIER_RELATED_DOSSIERS_SUCCESS,
    dossierRelatedDossiers
  }
}

export function saleWarningSave (reset, router, nextLocation, pristine) {
  return {
    type: dossierActionTypes.SALE_WARNING_SAVE,
    reset,
    router,
    nextLocation,
    pristine
  }
}

export function saleApproveByRole (pristine, approvalRoleCode, hideConfirmModal) {
  return {
    type: dossierActionTypes.SALE_APPROVE_BY_ROLE,
    pristine,
    approvalRoleCode,
    hideConfirmModal
  }
}

export function fetchAllDocuments (filter) {
  return {
    type: dossierActionTypes.FETCH_DOCUMENTS_DOSSIERID,
    filter
  }
}

export function fetchAllDocumentsSuccess (documents) {
  return {
    type: dossierActionTypes.FETCH_DOCUMENTS_DOSSIERID_SUCCESS,
    documents
  }
}

export function fetchAllDocumentHistoricals (filter, dossierId) {
  return {
    type: dossierActionTypes.FETCH_DOCUMENT_HISTORICALS_DOSSIERID,
    filter,
    dossierId
  }
}

export function fetchAllDocumentHistoricalsSuccess (documents) {
  return {
    type: dossierActionTypes.FETCH_DOCUMENT_HISTORICALS_DOSSIERID_SUCCESS,
    documents
  }
}

export function saveSelectedDocuments (selectedDocuments, selectedDocumentHistorical) {
  return {
    type: dossierActionTypes.SAVE_SELECTED_DOCUMENTS,
    selectedDocuments,
    selectedDocumentHistorical
  }
}

export function openSearchDocumentsModal () {
  return {
    type: dossierActionTypes.OPEN_SEARCH_DOCUMENTS_MODAL
  }
}

export function saleApproveFleet (pristine) {
  return {
    type: dossierActionTypes.SALE_APPROVE_FLEET,
    pristine
  }
}

export function saleApproveVoucher (pristine) {
  return {
    type: dossierActionTypes.SALE_APPROVE_VOUCHER,
    pristine
  }
}

export function salePayRenting (dossierId) {
  return {
    type: dossierActionTypes.SALE_PAY_RENTING,
    dossierId
  }
}

export function configureCampaign (openConfigureCampaignEditionFn, dossierId, pristine) {
  return {
    type: dossierActionTypes.CONFIGURE_CAMPAIGN,
    openConfigureCampaignEditionFn,
    dossierId,
    pristine
  }
}

export function searchCampaignByCode (campaignCode, dossierId) {
  return {
    type: dossierActionTypes.CONFIGURE_CAMPAIGN_SEARCHCODE,
    campaignCode,
    dossierId
  }
}

export function saveconfigureCampaign (campaigns, closeModalFunc, dossierid) {
  return {
    type: dossierActionTypes.CONFIGURE_CAMPAIGN_SAVE,
    campaigns,
    closeModalFunc,
    dossierid
  }
}

export function hasOptionals (hasOptionals, appliedCampaigns, campaignId, value, resolve) {
  return {
    type: dossierActionTypes.CONFIGURE_CAMPAIGN_HASOPTIONALS,
    hasOptionals,
    appliedCampaigns,
    campaignId,
    value,
    resolve
  }
}

export function fetchSalesServices (dossierId, isHistorical = false, resolve, reject) {
  return {
    type: dossierActionTypes.FETCH_SALES_SERVICES,
    dossierId,
    resolve,
    reject,
    isHistorical
  }
}

export function saveSalesService (dossierService, isPurchase = false) {
  return {
    type: dossierActionTypes.SAVE_SALES_SERVICE,
    dossierService,
    isPurchase
  }
}

export function fetchNotValidDatesService (ouId, dossierSubTypeId, month) {
  return {
    type: dossierActionTypes.FETCH_SERVICE_NOT_VALID_DATES,
    ouId,
    dossierSubTypeId,
    month
  }
}

export function openOrCloseServiceAddModal (show) {
  return {
    type: dossierActionTypes.OPEN_ADD_SERVICE_MODAL,
    show
  }
}

export function fetchSalesServicesSuccess (dossierServiceRelateds) {
  return {
    type: dossierActionTypes.FETCH_SALES_SERVICES_SUCCESS,
    dossierServiceRelateds
  }
}

export function openServiceObservationsModal (service) {
  return {
    type: dossierActionTypes.OPEN_SALE_SERVICES_OBSERVATIONS_MODAL_SUCCESS,
    service
  }
}

export function fetchSalesEconomic (dossierId, historical, pristine, resolve, reject) {
  return {
    type: dossierActionTypes.FETCH_SALES_DOSSIER_ECONOMIC,
    dossierId,
    historical,
    pristine,
    resolve,
    reject
  }
}

export function fetchSalesDossierEconomicSucces (dossierEconomicComponent, dossierId, historical) {
  return {
    type: dossierActionTypes.FETCH_SALES_DOSSIER_ECONOMIC_SUCCESS,
    dossierEconomicComponent,
    dossierId,
    historical
  }
}
export function fetchSalesEconomicHistorical (dossierId, resolve, reject, pristine) {
  return {
    type: dossierActionTypes.FETCH_SALES_DOSSIER_ECONOMIC_HISTORICAL,
    dossierId,
    resolve,
    reject,
    pristine
  }
}

export function updateAssociatedCosts (associatedCost, resolve, reject, dossierType) {
  return {
    type: dossierActionTypes.UPDATE_ASSOCIATED_COST,
    associatedCost,
    resolve,
    reject,
    dossierType
  }
}

export function createAssociatedCosts (newAssociatedCost, resolve, reject, dossierType) {
  return {
    type: dossierActionTypes.CREATE_ASSOCIATED_COST,
    newAssociatedCost,
    resolve,
    reject,
    dossierType
  }
}

export function deleteAssociatedCosts (associatedCost, dossierType) {
  return {
    type: dossierActionTypes.DELETE_ASSOCIATED_COST,
    associatedCost,
    dossierType
  }
}

export function updateAssociatedCostsSuccess (associatedCosts) {
  return {
    type: dossierActionTypes.UPDATE_ASSOCIATED_COSTS_SUCCESS,
    associatedCosts
  }
}

export function skipItv (dossierId) {
  return {
    type: dossierActionTypes.SKIP_ITV,
    dossierId
  }
}

export function fetchDiscountModal (economicTotalOperation, ivaType, iemType, iemPercentage, operationDiscountWithTax, operationDiscountWithoutTax, pristine, dossierType) {
  return {
    type: dossierActionTypes.FETCH_DISCOUNT_MODAL,
    economicTotalOperation,
    ivaType,
    iemType,
    iemPercentage,
    operationDiscountWithTax,
    operationDiscountWithoutTax,
    pristine,
    dossierType
  }
}

export function fetchAssociatedCostByPercentageModal (additionalCostId,
  additionalCostIndexFront,
  associatedCosts,
  totalAmount,
  cost,
  costByPercentage,
  pristine,
  dossierType) {
  return {
    type: dossierActionTypes.FETCH_ASSOCIATED_COST_BY_PERCENTAGE_MODAL,
    additionalCostId,
    additionalCostIndexFront,
    associatedCosts,
    totalAmount,
    cost,
    costByPercentage,
    pristine,
    dossierType
  }
}

export function fetchProcessingAgencyModal (amount, amountImc, ivaType, iemType, iemPercentage, pristine, dossierType) {
  return {
    type: dossierActionTypes.FETCH_PROCESSING_AGENCY_MODAL,
    amount,
    amountImc,
    ivaType,
    iemType,
    iemPercentage,
    pristine,
    dossierType
  }
}

export function fetchAmountTaxModal (ivaType, amountWithoutTax, pristine, dossierType) {
  return {
    type: dossierActionTypes.FETCH_AMOUNT_TAX_MODAL,
    ivaType,
    amountWithoutTax,
    pristine,
    dossierType
  }
}

export function fetchSalesSelfSupply (dossierId, pristine, resolve, reject) {
  return {
    type: dossierActionTypes.FETCH_SALES_DOSSIER_SELFSUPPLY,
    dossierId,
    pristine,
    resolve,
    reject
  }
}

export function fetchSalesSelfSupplyHistorical (dossierId, resolve, reject) {
  return {
    type: dossierActionTypes.FETCH_SALES_DOSSIER_SELFSUPPLY_HISTORICAL,
    dossierId,
    resolve,
    reject
  }
}

export function fetchSalesSelfSupplySuccess (dossierSelfSupplyComponent) {
  return {
    type: dossierActionTypes.FETCH_SALES_DOSSIER_SELFSUPPLY_SUCCESS,
    dossierSelfSupplyComponent
  }
}

export function printSummarySales (dossierId) {
  return {
    type: dossierActionTypes.PRINT_SUMMARY_SALE,
    dossierId
  }
}

export function printCompleteSummarySales (dossierId, isHistorical) {
  return {
    type: dossierActionTypes.PRINT_COMPLETE_SUMMARY_SALE,
    dossierId,
    isHistorical
  }
}

export function fetchFleetOperationTypeForAutoselectSuccess (fleetOperationTypeId) {
  return {
    type: dossierActionTypes.FETCH_FLEET_OPERATION_TYPE_FOR_AUTOSELECT_SUCCESS,
    fleetOperationTypeId
  }
}

export function saveRefinancing (dossierFinancingComponent, saleId) {
  return {
    type: dossierActionTypes.SAVE_REFINANCING,
    dossierFinancingComponent,
    saleId
  }
}

export function saveRefinancingSucces (refinancing) {
  return {
    type: dossierActionTypes.SAVE_REFINANCING_SUCCES,
    refinancing
  }
}

export function deleteRefinancing (saleId, resolve) {
  return {
    type: dossierActionTypes.DELETE_REFINANCING,
    saleId,
    resolve
  }
}

export function createUpdateDossierToshiko (dossierId, externalOrderId, ouId, action, resolve, reject) {
  return {
    type: dossierActionTypes.UPDATE_DOSSIER_TOSHIKO,
    dossierId,
    externalOrderId,
    ouId,
    action,
    resolve,
    reject
  }
}

export function checkPrivateRfcFormat (rfc, resolve) {
  return {
    type: dossierActionTypes.CHECK_PRIVATE_RFC_FORMAT,
    rfc,
    resolve
  }
}

export function foreignPassportWarning (value, resolve) {
  return {
    type: dossierActionTypes.FOREIGN_PASSPORT_WARNING,
    value,
    resolve
  }
}

export function updateClientNoEmployee (value) {
  return {
    type: dossierActionTypes.UPDATE_CHECK_CLIENT_NOT_EMPLOYEE,
    value
  }
}

export function updateResultSubmit (value) {
  return {
    type: dossierActionTypes.UPDATE_RESULT_SUBMIT,
    value
  }
}

export function fetchSalesDefaultDestination (value) {
  return {
    type: dossierActionTypes.FETCH_SALES_DEFAULT_DESTINATION,
    value
  }
}

export function transferReject (comment, resolve, reject, pristine) {
  return {
    type: dossierActionTypes.SALE_TRANSFER_REJECT,
    comment,
    resolve,
    reject,
    pristine
  }
}

export function changeCodeCommission (codeCommission) {
  return {
    type: dossierActionTypes.CHANGE_CODE_COMMISSION,
    codeCommission
  }
}

export function changeCodeCommissionSuccess (codeCommission) {
  return {
    type: dossierActionTypes.CHANGE_CODE_COMMISSION_SUCCESS,
    codeCommission
  }
}

export default {
  fetchSalesDossier,
  fetchSalesVehicle,
  fetchSalesClient,
  fetchSalesFinancing,
  fetchSalesInsuranceCarrier,
  fetchSalesInsuranceCarrierHistorical,
  fetchSalesDelivery,
  fetchSalesDeliveryHistorical,
  fetchSalesFinancingHistorical,
  fetchSalesVehicleHistorical,
  fetchSalesClientHistorical,
  fetchSalesEconomic,
  fetchSalesEconomicHistorical,
  fetchSalesDossierDependants,
  fetchSalesDossierDependantsEconomic,
  fetchSalesDossierSuccess,
  fetchSalesOtherData,
  fetchSalesOtherDataHistorical,
  postPaymentSignalStatusSuccess,
  fetchInitializeSalesComponent,
  printDocumentaryChecklist,
  updateDiscount,
  updateExtraSaleSuccess,
  updateSuppliedsSuccess,
  updateOptionalSuccess,
  updatePresentSuccess,
  updateDiscountSuccess,
  createDiscount,
  deleteDiscount,
  fetchDiscountsSuccess,
  updateExtra,
  createExtra,
  deleteExtra,
  fetchExtrasSuccess,
  updateSupplied,
  createSupplied,
  deleteSupplied,
  fetchSuppliedListSuccess,
  fetchCampaignEdition,
  deleteCampaign,
  updateCampaign,
  createCampaign,
  fetchCampaignsSuccess,
  fetchPaperworkEdition,
  updatePaperwork,
  createPaperwork,
  deletePaperwork,
  fetchEconomicPlanSuccess,
  updateService,
  createService,
  deleteService,
  fetchServicesSuccess,
  saleApprove,
  saleApprovePaperwork,
  salePayRenting,
  saleReject,
  saleSkipCharge,
  saleSkipDocument,
  saleSkipPaperwork,
  saleSkipDelivery,
  saleSendApprove,
  saleTransferRequest,
  saleTransferRequestApproved,
  saveSalesDossier,
  updateDependantsSaleDossierAndHeader,
  printPaperwork,
  restartSalesState,
  fetchDossierRelatedDossiersSuccess,
  refreshAuditInfo,
  fetchAuditInfoSucces,
  fetchAuditDetails,
  fetchAuditDetailsModal,
  updateLastAccessDate,
  deleteExtraSale,
  updateExtraSale,
  createExtraSale,
  deletePresent,
  updatePresent,
  createPresent,
  saleWarningSave,
  saleApproveByRole,
  fetchAllDocuments,
  fetchAllDocumentsSuccess,
  fetchAllDocumentHistoricals,
  fetchAllDocumentHistoricalsSuccess,
  saveSelectedDocuments,
  openSearchDocumentsModal,
  saleApproveFleet,
  saleApproveVoucher,
  fetchSalesDossierEconomicSucces,
  updateAssociatedCosts,
  createAssociatedCosts,
  deleteAssociatedCosts,
  updateAssociatedCostsSuccess,
  configureCampaign,
  saveconfigureCampaign,
  fetchCampaignsTableSuccess,
  searchCampaignByCode,
  hasOptionals,
  printDocumentaryWarranty,
  skipItv,
  fetchDiscountModal,
  fetchAssociatedCostByPercentageModal,
  fetchSalesServices,
  fetchSalesServicesSuccess,
  openServiceObservationsModal,
  saveSalesService,
  fetchProcessingAgencyModal,
  fetchAmountTaxModal,
  fetchNotValidDatesService,
  openOrCloseServiceAddModal,
  saveFleetCode,
  removeFinance,
  cleanFinance,
  fetchSalesSelfSupply,
  fetchSalesSelfSupplyHistorical,
  fetchSalesSelfSupplySuccess,
  printSummarySales,
  fetchFleetOperationTypeForAutoselectSuccess,
  saveRefinancing,
  saveRefinancingSucces,
  deleteRefinancing,
  createUpdateDossierToshiko,
  checkPrivateRfcFormat,
  foreignPassportWarning,
  updateClientNoEmployee,
  updateResultSubmit,
  saleSendBproDms,
  requestInvoiceToDMS,
  requestDeliveryToDMS,
  requestOutboundToDMS,
  fetchSalesDefaultDestination,
  printCompleteSummarySales,
  fetchDocumentsGroupedAuditDetails,
  fetchDocumentsGroupedAuditDetailsModal,
  transferReject,
  changeCodeCommission,
  changeCodeCommissionSuccess
}
