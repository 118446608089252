import React, { PureComponent } from 'react'
import { Tabs, Tab, Popover, Overlay, Button, Glyphicon } from 'react-bootstrap'
import { urgencyTypesClass } from '../../../constants/backendIds'
// import { Link } from 'react-router-dom'
import PopoverItem from '../../containers/search/PopoverItemPage.js'

const MAX_DOSSIER_TO_VIEW = 10

class DossierListPopover extends PureComponent {
  constructor () {
    super()
    this.state = {
      showPopover: false,
      target: this
    }
  }

  handleClick (e) {
    e.stopPropagation()
    this.setState({ target: e.target, showPopover: true })
  }

  render () {
    const {
      t,
      id,
      alive = [],
      stored = [],
      subtypesCombo,
      itemOverlayPlacement
    } = this.props
    const tKey = 'SEARCH.'
    return (
      <div>
        {(stored.length > 0 || alive.length > 0) &&
          <Button bsSize="xsmall" className="icon-only" onClick={this.handleClick.bind(this)}><Glyphicon glyph="plus"/></Button>
        }
        {`${alive.length} ${t(`${tKey}ONTIME`)} / ${stored.filter((dossier) => !dossier.isCancelled).length} ${t(`${tKey}ARCHIVED`)}`}
        <Overlay
          container={this}
          show={this.state.showPopover}
          target={this.state.target}
          onHide={() => this.setState({ showPopover: false })}
          rootClose
          placement={'bottom'}
        >
          <Popover id={id} className="_nf-popover dosList">
            <Tabs
              onClick={e => e.stopPropagation()}
              defaultActiveKey={1}
              animation={false}
              id="tab-dossier01"
            >
              <Tab eventKey={1} title={t(`${tKey}ONTIME`)}>
                <div className="popover-document-count">
                  {
                    alive.slice(0, MAX_DOSSIER_TO_VIEW).map((dossier, idx) => (
                      <PopoverItem placement={itemOverlayPlacement} itemType={'dlist_01'} linkTo={`/dossier${dossier.urlToNavigate}`} urgency={urgencyTypesClass[dossier.urgencyType]} dossierNumber={dossier.number} data={subtypesCombo && subtypesCombo.find(s => s.id === dossier.dossierSubtypeId) ? subtypesCombo.find(s => s.id === dossier.dossierSubtypeId).value : dossier.dossierSubType}></PopoverItem>
                    ))
                  }
                </div>
              </Tab>
              <Tab eventKey={2} title={t(`${tKey}ARCHIVED`)}>
                <div className="popover-document-count">
                  {
                    stored.filter((dossier) => !dossier.isCancelled).slice(0, MAX_DOSSIER_TO_VIEW).map((dossier, idx) => (
                      <PopoverItem placement={itemOverlayPlacement} itemType={'dlist_01'} linkTo={`/dossier${dossier.urlToNavigate}`} urgency={'grey'} dossierNumber={dossier.number} data={subtypesCombo && subtypesCombo.find(s => s.id === dossier.dossierSubtypeId) ? subtypesCombo.find(s => s.id === dossier.dossierSubtypeId).value : dossier.dossierSubType}></PopoverItem>
                    ))
                  }
                </div>
              </Tab>
            </Tabs>
          </Popover>
        </Overlay>
      </div>
    )
  }
}

export default DossierListPopover
