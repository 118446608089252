import { put, call, select, takeEvery } from 'redux-saga/effects'
import { showLoader, hideLoader } from '../../../../actions/common.js'
import mastersActionTypes from '../../../../constants/actions/masters/masters'
import { getAuth } from '../../../../selectors/access/auth'
import { handleError } from '../../../errors/errorManager'
import { getProcessingAgencyServiceDestinationsFilter } from '../../../../selectors/masters/processingAgency'

import { fetchServiceDestinationsComboSuccess } from '../../../../actions/combos/combos'
import { fetchProcessingAgencyServiceDestinationsListSuccess, showProcessingAgencyServiceDestinationsModalSuccess } from '../../../../actions/masters/processingAgency/processingAgencyIntegrations/processingAgencyServiceDestinations'
import postProcessingAgencyServiceDestinationsListService from '../../../../services/processingAgency/processingAgencyIntegrations/postProcessingAgencyServiceDestinationsList'
import postProcessingAgencyServiceDestinationsService from '../../../../services/processingAgency/processingAgencyIntegrations/postProcessingAgencyServiceDestinations'
import putProcessingAgencyServiceDestinationsService from '../../../../services/processingAgency/processingAgencyIntegrations/putProcessingAgencyServiceDestinations'
import deleteProcessingAgencyServiceDestinationsService from '../../../../services/processingAgency/processingAgencyIntegrations/deleteProcessingAgencyServiceDestinations'
import getServiceDestinationsAll from '../../../../services/processingAgency/processingAgencyIntegrations/getServiceDestinationsAll'

import { yesNoModal } from '../../../modalsListeners/yesNoModal'

export function * fetchProcessingAgencyServiceDestinationsList ({ filter, processingAgencyId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield filter = yield filter || select(getProcessingAgencyServiceDestinationsFilter)
    const processingAgencyServiceDestinationsList = yield call(postProcessingAgencyServiceDestinationsListService, auth.token, processingAgencyId, filter)
    yield put(fetchProcessingAgencyServiceDestinationsListSuccess(processingAgencyServiceDestinationsList, filter))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchProcessingAgencyServiceDestinationsList () {
  yield takeEvery(mastersActionTypes.FETCH_PROCESSING_AGENCY_SERVICE_DESTINATIONS_LIST, fetchProcessingAgencyServiceDestinationsList)
}

export function * showProcessingAgencyServiceDestinationsModal ({ show, processingAgencyServiceDestination, processingAgencyId }) {
  try {
    yield put(showLoader())
    yield put(showProcessingAgencyServiceDestinationsModalSuccess(processingAgencyId, show, processingAgencyServiceDestination))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchShowProcessingAgencyServiceDestinationsModal () {
  yield takeEvery(mastersActionTypes.SHOW_PROCESSING_AGENCY_SERVICE_DESTINATIONS_MODAL, showProcessingAgencyServiceDestinationsModal)
}

export function * submitProcessingAgencyServiceDestinationsModal ({ processingAgencyServiceDestination, processingAgencyId }) {
  try {
    const auth = yield select(getAuth)
    if (processingAgencyServiceDestination.ProcessingAgencyServiceDestinationId) {
      const confirmed = yield call(yesNoModal, 'editProcessingAgencyServiceDestinations')
      if (confirmed) {
        yield put(showLoader())
        yield call(putProcessingAgencyServiceDestinationsService, auth.token, processingAgencyId, processingAgencyServiceDestination.ProcessingAgencyServiceDestinationId, processingAgencyServiceDestination)
      }
    } else {
      yield put(showLoader())
      yield call(postProcessingAgencyServiceDestinationsService, auth.token, processingAgencyId, processingAgencyServiceDestination)
    }
    yield put(showProcessingAgencyServiceDestinationsModalSuccess(false))
    const filter = yield filter || select(getProcessingAgencyServiceDestinationsFilter)
    const processingAgencyServiceDestinationsList = yield call(postProcessingAgencyServiceDestinationsListService, auth.token, processingAgencyId, filter)
    yield put(fetchProcessingAgencyServiceDestinationsListSuccess(processingAgencyServiceDestinationsList, filter))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSubmitProcessingAgencyServiceDestinationsModal () {
  yield takeEvery(mastersActionTypes.SUBMIT_PROCESSING_AGENCY_SERVICE_DESTINATIONS, submitProcessingAgencyServiceDestinationsModal)
}

export function * deleteProcessingAgencyServiceDestinations ({ processingAgencyServiceDestinationsId, processingAgencyId }) {
  try {
    const confirmed = yield call(yesNoModal, 'deleteProcessingAgencyServiceDestinations')
    if (confirmed) {
      yield put(showLoader())
      const auth = yield select(getAuth)
      yield call(deleteProcessingAgencyServiceDestinationsService, auth.token, processingAgencyId, processingAgencyServiceDestinationsId)
      const filter = yield filter || select(getProcessingAgencyServiceDestinationsFilter)
      const processingAgencyServiceDestinationsList = yield call(postProcessingAgencyServiceDestinationsListService, auth.token, processingAgencyId, filter)
      yield put(fetchProcessingAgencyServiceDestinationsListSuccess(processingAgencyServiceDestinationsList, filter))
      yield put(showProcessingAgencyServiceDestinationsModalSuccess(processingAgencyId, false))
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDeleteProcessingAgencyServiceDestinations () {
  yield takeEvery(mastersActionTypes.DELETE_PROCESSING_AGENCY_SERVICE_DESTINATIONS, deleteProcessingAgencyServiceDestinations)
}
export function * fetchProcessingAgencyServiceDestinationsCombo () {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const processingAgencyServiceDestinationsCombo = yield call(getServiceDestinationsAll, auth.token)
    yield put(fetchServiceDestinationsComboSuccess(processingAgencyServiceDestinationsCombo))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchProcessingAgencyServiceDestinationsCombo () {
  yield takeEvery(mastersActionTypes.FETCH_PROCESSING_AGENCY_SERVICE_DESTINATIONS_COMBO, fetchProcessingAgencyServiceDestinationsCombo)
}
