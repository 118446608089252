import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import AssociatedCostsMasters from '../../../components/masters/associatedCosts/AssociatedCostsMasters'
import { translate } from 'react-polyglot'
import { openAssociatedCostsModal, deleteAssociatedCostsModal, recoverAssociatedCostsModal, fetchAssociatedCostsMasters } from '../../../actions/masters/masters'
import { fetchOrganizedUnitsTree } from '../../../actions/combos/combos'

export function mapStateToProps (state) {
  return {
    combos: {
      UOTreeCombo: state.combos.UOTreeCombo
    },
    associatedCostsMastersList: state.entityMasters.associatedCostsMastersList
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      openAssociatedCostsModal,
      deleteAssociatedCostsModal,
      fetchAssociatedCostsMasters,
      recoverAssociatedCostsModal,
      fetchOrganizedUnitsTree
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(AssociatedCostsMasters))
