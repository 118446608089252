import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import DocumentaryManagement from '../../../components/dossierHistorical/common/documentaryManagement/DocumentaryManagement'
import { closeDocumentModalCallbacks, cancelDocument, refreshDocumentManagementModal, addDependant, printCheckList, setCurrentTabCounterValues } from '../../../actions/dossier/common/documentManagementModal'
import { openDocumentCreationModal, openDocumentEditionModal } from '../../../actions/dossier/common/documentHistoricalEditionModal'
import { openDocumentEditionModal as openDocumentEditionModalNotHistorical } from '../../../actions/dossier/common/documentEditionModal'
import { translate } from 'react-polyglot'
import { openModal } from '../../../actions/common'
import openSuccessMessageModal from '../../../actions/modals/messageModal'
import { fetchAllDocuments, fetchAllDocumentHistoricals } from '../../../actions/dossier/sales'
import { getDocumentsMassiveDownload } from '../../../actions/dossier/common/documentMassiveDownloadActions'

export function mapStateToProps (state, ownProps) {
  return {
    ...state.documentaryManagementModal,
    showDocumentEditionModal: state.documentEditionModal.showDocumentEditionModal,
    dossierType: ownProps.dossierType,
    combos: ownProps.combos,
    isHistorical: ownProps.isHistorical,
    documentExport: state.documentExport.documents,
    saveSelectedDocuments: ownProps.saveSelectedDocuments
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      closeDocumentModalCallbacks,
      openDocumentCreationModal,
      openDocumentEditionModal,
      cancelDocument,
      addDependant,
      refreshDocumentManagementModal,
      printCheckList,
      setCurrentTabCounterValues,
      openModal,
      openDocumentEditionModalNotHistorical,
      openSuccessMessageModal,
      fetchAllDocuments,
      fetchAllDocumentHistoricals,
      getDocumentsMassiveDownload
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(DocumentaryManagement))
