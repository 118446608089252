import React, { PureComponent } from 'react'
import { Checkbox } from 'react-bootstrap'

class SimpleInputCheckbox extends PureComponent {
  render () {
    const { value, onInputChange, placeholder, disabled = false, inline = false, id, hasError } = this.props
    return (
      <Checkbox
        id={id}
        inline={inline}
        disabled={disabled}
        checked={value}
        onChange={(e) => onInputChange(e.target.checked)}
        validationState={hasError ? 'error' : null}
      >
        {placeholder}
      </Checkbox>
    )
  }
}

export default SimpleInputCheckbox
