export const typeFields = {
  text: 1,
  number: 2,
  money: 3,
  date: 4,
  dateTime: 5,
  dniCifNif: 6,
  mail: 7,
  list: 8,
  licensePlate: 9,
  vinChassis: 10,
  decimal: 11,
  licensePlateValidation: 12
}

export const codeMetadataFields = {
  EXPECTED_REPURCHASE_DATE: 'EXPECTED_REPURCHASE_DATE',
  PAGA_SEÑAL_IMPORTE: 'PAGA_SEÑAL_IMPORTE',
  PAGA_SEÑAL_IMPORTE_TEXTO: 'PAGA_SEÑAL_IMPORTE_TEXTO',
  COBRO_IMPORTE: 'COBRO_IMPORTE',
  COBRO_IMPORTE_TEXTO: 'COBRO_IMPORTE_TEXTO',
  ABONO_IMPORTE: 'ABONO_IMPORTE',
  ABONO_IMPORTE_TEXTO: 'ABONO_IMPORTE_TEXTO'
}
