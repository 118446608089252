import { put, call, select, takeEvery } from 'redux-saga/effects'
import { initialize, reset, setSubmitFailed, change } from 'redux-form'
// import { replace } from 'react-router-redux'
import { replace } from 'connected-react-router'
import dossierActionsTypes from '../../constants/actions/dossier/dossier'
import commonActionTypes from '../../constants/actions/common'
import { dossierTypes as dossierTypesConstants } from '../../constants/backendIds'
import { dossierTypes as dossierTypesConstant } from '../../constants/dossier/common/dossierTypes'
import { buttonsHeader } from '../../constants/dossier/common/buttonsHeader'
import { showLoader, hideLoader } from '../../actions/common'
import { yesNoModal } from '../modalsListeners/yesNoModal'
import messageModalActions from '../../actions/modals/messageModal'
import { getAuth } from '../../selectors/access/auth'
import { getActiveTab } from '../../selectors/dossier/common/buttonsHeader'
import postDossier from '../../services/dossier/post/postDossier'
import putStoreDossier from '../../services/dossier/put/putStoreDossier'
import { fetchSalesDossier, fetchDependantsSaleDossier, refreshSalesDossierFromDocumentModal } from './fetchSalesDossier'
import { fetchPurchaseDossier, fetchDependantsPurchaseDossier, refreshPurchaseDossierFromDocumentModal } from './purchase/fetchPurchaseDossier'
import { fetchPaperworkDossier } from './paperwork/fetchPaperworkDossier'
import { fetchCampaignDossier } from './campaign/fetchCampaignDossier'
import { fetchRegistrationDeliveryDossier } from './registrationDelivery/fetchRegistrationDeliveryDossier'
import { fetchServiceDossier } from './service/fetchServiceDossier'
import { handleError } from '../errors/errorManager'
import putUndoCancel from '../../services/dossierService/putUndoCancel'
import putConfirmCancel from '../../services/dossierService/putConfirmCancel'
import auditActionsTypes from '../../constants/actions/audit/auditInfo'
import { fetchAuditInfoSucces, fetchAuditFleetInfoSucces } from '../../actions/audit/auditInfo'
// Audit Info
import getAuditInfo from '../../services/audit/auditInfo'
import getAuditHistoricalInfo from '../../services/audit/auditHistoricalInfo'
import getAllDocumentsToSign from '../../services/document/getAllDocumentsToSign'
import putDocumentsToSign from '../../services/document/putDocumentsToSign'
import getAuditDetails from '../../services/audit/auditDetails'
import getAuditHistoricalDetails from '../../services/audit/auditHistoricalDetails'
import { getDocumentsCount } from '../../actions/sign/sign'
import putDossierCancelPurchaseSelfSupply from '../../services/dossier/put/putDossierCancelPurchaseSelfSupply'
import getCheckDocumentChargeValidated from '../../services/dossier/get/getCheckDocumentChargeValidated'
import getAuditFleetInfo from '../../services/audit/auditFleetInfo'
import getAuditFleetInfoDownload from '../../services/audit/auditFleetInfoDownload'
import { fetchStockDossier } from './stock/fetchStockDossier'
import getAuditGroupedDocumentsDetails from '../../services/audit/auditGroupedDocumentsDetails'
import getAuditHistoricalGroupedDocumentsDetails from '../../services/audit/auditHistoricalGroupedDocumentsDetails'
import { openCodeCommissionModalSuccess, fetchCodeCommissionComboSuccess } from '../../actions/dossier/common/codeCommisionModal'
import codeCommissionModalActionTypes from '../../constants/actions/dossier/common/codeCommisionModal'
import getCodeCommissionModal from '../../services/codeCommission/getCodeCommissionModal'
import { changeCodeCommissionSuccess } from '../../actions/dossier/sales'

export function * getDossierStateById (dossierId) {
  const dossierStates = JSON.parse(JSON.stringify(yield select(state => [
    { dossier: state.salesDossier, type: dossierTypesConstants.sales },
    { dossier: state.purchaseDossier, type: dossierTypesConstants.purchase },
    { dossier: state.paperworkDossier, type: dossierTypesConstants.paperwork },
    { dossier: state.campaignDossier, type: dossierTypesConstants.campaign },
    { dossier: state.registrationDeliveryDossier, type: dossierTypesConstants.registrationDelivery },
    { dossier: state.serviceDossier, type: dossierTypesConstants.service },
    { dossier: state.stockDossier, type: dossierTypesConstants.stock }
  ])))
  const t = yield call((fn) => dossierStates.find(fn), dossierState => {
    return ('' + dossierState.dossier.dossierId) === ('' + dossierId)
  })
  return t
}

export function * getDossierStateByType (dossierType) {
  let dossierState
  switch (dossierType) {
    case dossierTypesConstant.sales:
      dossierState = JSON.parse(JSON.stringify(yield select(state => state.salesDossier)))
      break
    case dossierTypesConstant.purchase:
      dossierState = JSON.parse(JSON.stringify(yield select(state => state.purchaseDossier)))
      break
    case dossierTypesConstant.paperwork:
      dossierState = JSON.parse(JSON.stringify(yield select(state => state.paperworkDossier)))
      break
    case dossierTypesConstant.campaign:
      dossierState = JSON.parse(JSON.stringify(yield select(state => state.campaignDossier)))
      break
    case dossierTypesConstant.registrationDelivery:
      dossierState = JSON.parse(JSON.stringify(yield select(state => state.registrationDeliveryDossier)))
      break
    case dossierTypesConstant.service:
      dossierState = JSON.parse(JSON.stringify(yield select(state => state.serviceDossier)))
      break
    case dossierTypesConstant.stock:
      dossierState = JSON.parse(JSON.stringify(yield select(state => state.stockDossier)))
      break
  }
  return dossierState
}

export function * initializeDossier (dossierType, dossierState) {
  const dossierId = dossierState.dossierId
  if (typeof dossierType === 'string') {
    switch (dossierType) {
      case dossierTypesConstant.sales:
        yield call(initSaleDossier, dossierId)
        break
      case dossierTypesConstant.purchase:
        yield call(initPurchaseDossier, dossierId)
        break
      case dossierTypesConstant.paperwork:
        yield call(initPaperworkDossier, dossierId)
        break
      case dossierTypesConstant.campaign:
        yield call(initCampaignDossier, dossierId)
        break
      case dossierTypesConstant.registrationDelivery:
        yield call(initRegistrationDeliveryDossier, dossierId)
        break
      case dossierTypesConstant.service:
        yield call(initServiceDossier, dossierId)
        break
      case dossierTypesConstant.stock:
        yield call(initStockDossier, dossierId)
        break
    }
  } else {
    switch (dossierType) {
      case dossierTypesConstants.sales:
        yield call(initSaleDossier, dossierId)
        break
      case dossierTypesConstants.purchase:
        yield call(initPurchaseDossier, dossierId)
        break
      case dossierTypesConstants.paperwork:
        yield call(initPaperworkDossier, dossierId)
        break
      case dossierTypesConstants.campaign:
        yield call(initCampaignDossier, dossierId)
        break
      case dossierTypesConstants.registrationDelivery:
        yield call(initRegistrationDeliveryDossier, dossierId)
        break
      case dossierTypesConstants.service:
        yield call(initServiceDossier, dossierId)
        break
      case dossierTypesConstants.stock:
        yield call(initStockDossier, dossierId)
        break
    }
  }
}

export function * initSaleDossier (dossierId) {
  yield call(fetchSalesDossier, { dossierId: dossierId })
  yield call(fetchDependantsSaleDossier, { dossierId: dossierId })
  const updatedSalesDossier = JSON.parse(JSON.stringify(yield select(state => state.salesDossier)))
  yield put(initialize('salesDossier', updatedSalesDossier))
}

export function * initPurchaseDossier (dossierId) {
  yield call(fetchPurchaseDossier, { dossierId: dossierId })
  yield call(fetchDependantsPurchaseDossier, { dossierId: dossierId })
  const updatedPurchaseDossier = JSON.parse(JSON.stringify(yield select(state => state.purchaseDossier)))
  yield put(initialize('purchase_dossier', updatedPurchaseDossier))
}

export function * initPaperworkDossier (dossierId) {
  yield call(fetchPaperworkDossier, { dossierId: dossierId })
  const updatedPaperworkDossier = JSON.parse(JSON.stringify(yield select(state => state.paperworkDossier)))
  yield put(initialize('paperwork_dossier', updatedPaperworkDossier))
}

export function * initCampaignDossier (dossierId) {
  yield call(fetchCampaignDossier, { dossierId: dossierId })
  const updatedCampaignDossier = JSON.parse(JSON.stringify(yield select(state => state.campaignDossier)))
  yield put(initialize('campaign_dossier', updatedCampaignDossier))
}

export function * initRegistrationDeliveryDossier (dossierId) {
  yield call(fetchRegistrationDeliveryDossier, { dossierId: dossierId })
  const updatedRegistrationDeliveryDossier = JSON.parse(JSON.stringify(yield select(state => state.registrationDeliveryDossier)))
  yield put(initialize('registrationDeliveryDossier', updatedRegistrationDeliveryDossier))
}

export function * initServiceDossier (dossierId) {
  yield call(fetchServiceDossier, { dossierId: dossierId })
  const updatedServiceDossier = JSON.parse(JSON.stringify(yield select(state => state.serviceDossier)))
  yield put(initialize('service_dossier', updatedServiceDossier))
}

export function * initStockDossier (dossierId) {
  yield call(fetchStockDossier, { dossierId: dossierId })
  const updatedStockDossier = JSON.parse(JSON.stringify(yield select(state => state.stockDossier)))
  yield put(initialize('stock_dossier', updatedStockDossier))
}

export function * submitNewDossier ({ values }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const dossierId = yield call(postDossier, {
      organizedUnitId: values.lowestOrganizedUnits,
      typeId: values.dossierTypes,
      subTypeId: values.dossierSubTypes
    }, auth.token)
    switch (values.dossierTypes) {
      case dossierTypesConstants.sales:
        yield call(() => { window.location.href = `${window.location.protocol + '//' + window.location.host}/#/dossier/sales/${dossierId}` })
        break
      case dossierTypesConstants.purchase:
        yield call(() => { window.location.href = `${window.location.protocol + '//' + window.location.host}/#/dossier/purchase/${dossierId}` })
        break
      case dossierTypesConstants.campaign:
        yield call(() => { window.location.href = `${window.location.protocol + '//' + window.location.host}/#/dossier/campaign/${dossierId}` })
        break
      case dossierTypesConstants.paperwork:
        yield call(() => { window.location.href = `${window.location.protocol + '//' + window.location.host}/#/dossier/paperwork/${dossierId}` })
        break
      case dossierTypesConstants.registrationDelivery:
        yield call(() => { window.location.href = `${window.location.protocol + '//' + window.location.host}/#/dossier/registrationDelivery/${dossierId}` })
        break
      case dossierTypesConstants.service:
        yield call(() => { window.location.href = `${window.location.protocol + '//' + window.location.host}/#/dossier/service/${dossierId}` })
        break
      case dossierTypesConstants.stock:
        yield call(() => { window.location.href = `${window.location.protocol + '//' + window.location.host}/#/dossier/stock/${dossierId}` })
        break
      default:
        yield call(() => { window.location.href = `${window.location.protocol + '//' + window.location.host}/#/dashboard` })
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      yield put(hideLoader())
      if (customHandlerError.json && (customHandlerError.json.tag === 'VehicleNotFound' || customHandlerError.json.tag === 'TRANS/VehicleNotFound' || customHandlerError.json.tag === 'TRANS/TRANS/VehicleNotFound')) {
        yield call(yesNoModal, 'vehicleNotFoundNewDossier')
      } else if (customHandlerError.json && (customHandlerError.json.tag === 'VehicleVinChassisDuplicated' || customHandlerError.json.tag === 'TRANS/VehicleVinChassisDuplicated' || customHandlerError.json.tag === 'TRANS/TRANS/VehicleVinChassisDuplicated')) {
        yield call(yesNoModal, 'VehicleVinChassisDuplicated')
      } else if (customHandlerError.json && (customHandlerError.json.tag === 'TravelNotFound' || customHandlerError.json.tag === 'TRANS/TravelNotFound' || customHandlerError.json.tag === 'TRANS/TRANS/TravelNotFound')) {
        yield call(yesNoModal, 'travelNotFound')
      } else if (customHandlerError.json && (customHandlerError.json.tag === 'CpTravelNotFound' || customHandlerError.json.tag === 'TRANS/CpTravelNotFound' || customHandlerError.json.tag === 'TRANS/TRANS/CpTravelNotFound')) {
        yield call(yesNoModal, 'cpTravelNotFound')
      } else if (customHandlerError.json && (customHandlerError.json.tag === 'TruckTravelNotFound' || customHandlerError.json.tag === 'TRANS/TruckTravelNotFound' || customHandlerError.json.tag === 'TRANS/TRANS/TruckTravelNotFound')) {
        yield call(yesNoModal, 'truckTravelNotFound')
      } else if (customHandlerError.json && (customHandlerError.json.tag === 'DateTravelNotFound' || customHandlerError.json.tag === 'TRANS/DateTravelNotFound' || customHandlerError.json.tag === 'TRANS/TRANS/DateTravelNotFound')) {
        yield call(yesNoModal, 'dateTravelNotFound')
      } else if (customHandlerError.json && (customHandlerError.json.tag === 'VehicleInTravelNotFound' || customHandlerError.json.tag === 'TRANS/VehicleInTravelNotFound' || customHandlerError.json.tag === 'TRANS/TRANS/VehicleInTravelNotFound')) {
        yield call(yesNoModal, 'vehicleInTravelNotFound')
      } else if (customHandlerError.json && (customHandlerError.json.tag === 'MultipleTravelsFound' || customHandlerError.json.tag === 'TRANS/MultipleTravelsFound' || customHandlerError.json.tag === 'TRANS/TRANS/MultipleTravelsFound')) {
        yield call(yesNoModal, 'multipleTravelsFound')
      } else {
        yield put(messageModalActions.openErrorMessageModal('MESSAGES.SUBMIT_NEW_DOSSIER_ERROR'))
      }
    }
    yield call(handleError, { error, customHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSubmitNewDossier () {
  yield takeEvery(dossierActionsTypes.SUBMIT_NEW_DOSSIER, submitNewDossier)
}

export function * storeDossier ({ dossierId, pristine, reset, forceStore }) {
  const dossierState = yield call(getDossierStateById, dossierId)
  if (forceStore === null || forceStore === undefined) {
    forceStore = false
  }

  try {
    if (!pristine) {
      const confirmedSaveWarning = yield call(yesNoModal, 'commonDossierSaveWarning')
      if (confirmedSaveWarning) {
        reset('salesDossier')
        reset('purchase_dossier')
        reset('paperwork_dossier')
        reset('campaign_dossier')
        reset('registrationDeliverydossier')
        reset('service_dossier')
        reset('stock_dossier')
        const auth = yield select(getAuth)
        const confirmed = yield call(yesNoModal, 'storeDossier')
        if (confirmed) {
          yield put(showLoader())
          const response = yield call(putStoreDossier, dossierId, auth.token, dossierState.dossier.lastAccessDate, forceStore)
          yield put(messageModalActions.openSuccessMessageModal('MESSAGES.STORE_DOSSIER_SUCCESS'))
          if (response.urlToNavigate) {
            // yield put(replace('/dossier' + response.urlToNavigate))
            yield put(replace('/dossier' + response.urlToNavigate))
          } else {
            yield call(initializeDossier, dossierState.type, dossierState.dossier)
          }
          if (response.childResult || response.parentResult) {
            yield put(hideLoader())
            let message = response.childResult ? response.childResult : ''
            message = response.parentResult ? message !== '' ? message + ', ' + response.parentResult : response.parentResult : message
            yield call(yesNoModal, 'confirmRelatedStoreDossier', null, null, message)
          }
        }
      }
    } else {
      const auth = yield select(getAuth)
      const confirmed = yield call(yesNoModal, 'storeDossier')
      if (confirmed) {
        yield put(showLoader())
        const response = yield call(putStoreDossier, dossierId, auth.token, dossierState.dossier.lastAccessDate, forceStore)
        yield put(messageModalActions.openSuccessMessageModal('MESSAGES.STORE_DOSSIER_SUCCESS'))
        if (response.urlToNavigate) {
          // yield put(replace('/dossier' + response.urlToNavigate))
          yield put(replace('/dossier' + response.urlToNavigate))
        } else {
          yield call(initializeDossier, dossierState.type, dossierState.dossier)
        }
        if (response.childResult || response.parentResult) {
          yield put(hideLoader())
          let message = response.childResult ? response.childResult : ''
          message = response.parentResult ? message !== '' ? message + ', ' + response.parentResult : response.parentResult : message
          yield call(yesNoModal, 'confirmRelatedStoreDossier', null, null, message)
        }
      }
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          yield call(initializeDossier, dossierState.type, dossierState.dossier)
        }
      } else {
        if (customHandlerError.json && (customHandlerError.json.childError || customHandlerError.json.parentError)) {
          yield put(hideLoader())
          let message = customHandlerError.json.childError ? customHandlerError.json.childError : ''
          message = customHandlerError.json.parentError ? message !== '' ? message + ', ' + customHandlerError.json.parentError : customHandlerError.json.parentError : message
          yield call(yesNoModal, 'errorStoreDossier', null, null, message)
        } else {
          yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
        }
      }
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('purchase_dossier'))
      yield put(reset('paperwork_dossier'))
      yield put(reset('campaign_dossier'))
      yield put(reset('service_dossier'))
      yield put(reset('stock_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchStoreDossier () {
  yield takeEvery(dossierActionsTypes.STORE_DOSSIER, storeDossier)
}

export function * updateDossierCommon ({ dossierId, parentDossierType }) {
  if (parentDossierType.isSales) {
    yield call(fetchDependantsSaleDossier, { dossierId })
    yield call(refreshSalesDossierFromDocumentModal, null)
  } else if (parentDossierType.isPaperwork) {
    // TODO: Fill when it's defined
    // yield call(fetchDependantsSaleDossier, dossierId)
  } else if (parentDossierType.isPurchase) {
    yield call(fetchDependantsPurchaseDossier, { dossierId })
    yield call(refreshPurchaseDossierFromDocumentModal)
  } else if (parentDossierType.isCampaign) {
    // TODO: Fill when it's defined
    // yield call(fetchDependantsSaleDossier, dossierId)
  }
}

export function * watchUpdateDossierCommon () {
  yield takeEvery(dossierActionsTypes.UPDATE_DOSSIER_COMMON, updateDossierCommon)
}

export function * undoCancel ({ dossierId, pristine, reset }) {
  const dossierState = yield call(getDossierStateById, dossierId)
  const dossier = dossierState.dossier
  try {
    if (!pristine) {
      const confirmedSaveWarning = yield call(yesNoModal, 'commonDossierSaveWarning')
      if (confirmedSaveWarning) {
        const auth = yield select(getAuth)
        const confirmed = yield call(yesNoModal, 'dossierUndoCancel')
        if (confirmed) {
          yield put(showLoader())
          yield call(putUndoCancel, dossier.dossierId, auth.token, dossier.lastAccessDate)
          yield initializeDossier(dossierState.type, dossier)
        }
      }
    } else {
      const auth = yield select(getAuth)
      const confirmed = yield call(yesNoModal, 'dossierUndoCancel')
      if (confirmed) {
        yield put(showLoader())
        yield call(putUndoCancel, dossier.dossierId, auth.token, dossier.lastAccessDate)
        yield initializeDossier(dossierState.type, dossier)
      }
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          yield call(initializeDossier, dossierState.type, dossierState.dossier)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('purchase_dossier'))
      yield put(reset('paperwork_dossier'))
      yield put(reset('campaign_dossier'))
      yield put(reset('service_dossier'))
      yield put(reset('stock_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchUndoCancel () {
  yield takeEvery(dossierActionsTypes.UNDO_CANCEL_DOSSIER, undoCancel)
}

export function * confirmCancel ({ dossierId, pristine, reset }) {
  const dossierState = yield call(getDossierStateById, dossierId)
  const dossier = dossierState.dossier
  try {
    if (!pristine) {
      const confirmedSaveWarning = yield call(yesNoModal, 'commonDossierSaveWarning')
      if (confirmedSaveWarning) {
        const auth = yield select(getAuth)
        const confirmed = yield call(yesNoModal, 'dossierConfirmCancel')
        if (confirmed) {
          yield put(showLoader())
          const response = yield call(putConfirmCancel, dossier.dossierId, auth.token, dossier.lastAccessDate)
          yield put(replace('/dossier' + response.urlToNavigate))
        }
      }
    } else {
      const auth = yield select(getAuth)
      const confirmed = yield call(yesNoModal, 'dossierConfirmCancel')
      if (confirmed) {
        yield put(showLoader())
        const response = yield call(putConfirmCancel, dossier.dossierId, auth.token, dossier.lastAccessDate)
        yield put(replace('/dossier' + response.urlToNavigate))
      }
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          yield put(initializeDossier(dossierState.type, dossierState.dossier))
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('purchase_dossier'))
      yield put(reset('paperwork_dossier'))
      yield put(reset('campaign_dossier'))
      yield put(reset('service_dossier'))
      yield put(reset('stock_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchConfirmCancel () {
  yield takeEvery(dossierActionsTypes.CONFIRM_CANCEL_DOSSIER, confirmCancel)
}
export function * watchRefreshAuditInfo () {
  yield takeEvery(auditActionsTypes.REFRESH_AUDIT_INFO, refreshAuditInfo)
}

export function * refreshAuditInfo ({ dossierId, historical }) {
  try {
    const currentActiveTab = yield select(getActiveTab)
    if (currentActiveTab === buttonsHeader.actividad_expediente) {
      yield put(showLoader())
      const auth = yield select(getAuth)
      let auditInfo = {}
      if (historical) {
        auditInfo = yield call(getAuditHistoricalInfo, dossierId, auth.token)
      } else {
        auditInfo = yield call(getAuditInfo, auth.token, dossierId)
      }
      yield put(fetchAuditInfoSucces(auditInfo, dossierId, historical))
    }
  } catch (error) {
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('service_dossier'))
      yield put(reset('stock_dossier'))
    }
    yield call(handleError, { error, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * sendDocumentToSign ({ documents, dossierid, dossierNumber }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(putDocumentsToSign, auth.token, dossierid, dossierNumber, documents)
    yield put(messageModalActions.openSuccessMessageModal('MESSAGES.DOCUMENT_MANAGEMENT.REQUEST_SIGN_DOCUMENT'))
    yield put(getDocumentsCount())
  } catch (error) {
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('purchase_dossier'))
      yield put(reset('paperwork_dossier'))
      yield put(reset('campaign_dossier'))
    }
    yield call(handleError, { error, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSendDocumentToSign () {
  yield takeEvery(dossierActionsTypes.SEND_DOCUMENTS_TO_SEND, sendDocumentToSign)
}

export function * documentsToSendSignGetAll ({ dossierId, resolve, reject }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const documentToSendSign = yield call(getAllDocumentsToSign, auth.token, dossierId)
    if (resolve) resolve(documentToSendSign)
  } catch (error) {
    if (reject) reject()
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDocumentsToSendSignGetAll () {
  yield takeEvery(dossierActionsTypes.DOCUMENTS_TO_SEND_SIGN_GETALL, documentsToSendSignGetAll)
}

export function * openDocumentsToSendSign ({ documentsToSendSignFn }) {
  try {
    yield put(showLoader())
    yield call(documentsToSendSignFn)
  } catch (error) {
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDocumentsToSendSign () {
  yield takeEvery(dossierActionsTypes.DOCUMENTS_TO_SEND_SIGN, openDocumentsToSendSign)
}
export function * openDocWarning ({ resolve, reject }) {
  const respuesta = yield call(yesNoModal, 'openDocumentModal')
  if (resolve && respuesta !== undefined) {
    yield call(resolve)
  }
}

export function * watchOpenDocWarning () {
  yield takeEvery(commonActionTypes.OPEN_DOC_WARNING, openDocWarning)
}

export function * watchRefreshAuditDetails () {
  yield takeEvery(auditActionsTypes.FETCH_AUDIT_DETAILS, fetchAuditDetails)
}

export function * fetchAuditDetails ({ dossierId, historical, idx, organizedUnitId, dossierSubTypeId, resolve }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    let auditInfo = {}
    if (historical) {
      auditInfo = yield call(getAuditHistoricalDetails, auth.token, dossierId, idx, organizedUnitId, dossierSubTypeId)
    } else {
      auditInfo = yield call(getAuditDetails, auth.token, dossierId, idx, organizedUnitId, dossierSubTypeId)
    }
    if (resolve) resolve(auditInfo)
  } catch (error) {
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('registrationDeliveryDossier'))
      yield put(reset('service_dossier'))
      yield put(reset('stock_dossier'))
    }
    yield call(handleError, { error, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchRefreshAuditDetailsModal () {
  yield takeEvery(auditActionsTypes.FETCH_AUDIT_DETAILS_MODAL, fetchAuditDetailsModal)
}

export function * fetchAuditDetailsModal ({ info }) {
  try {
    yield put(hideLoader())
    yield call(yesNoModal, 'auditDetails', null, null, null, null, [{ id: '##DETAILS##', value: info }])
  } catch (error) {
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('registrationDeliveryDossier'))
      yield put(reset('service_dossier'))
      yield put(reset('stock_dossier'))
    }
    yield call(handleError, { error, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * putCancelDossierPurchaseSelf ({ dossierId, pristine, reset, idParent }) {
  const dossierState = yield call(getDossierStateById, idParent)

  try {
    if (!pristine) {
      const confirmedSaveWarning = yield call(yesNoModal, 'commonDossierSaveWarning')
      if (confirmedSaveWarning) {
        reset('salesDossier')
        reset('purchase_dossier')
        const auth = yield select(getAuth)
        const confirmed = yield call(yesNoModal, 'commonDossierCancel')
        if (confirmed) {
          yield put(showLoader())
          const response = yield call(putDossierCancelPurchaseSelfSupply, dossierId, auth.token)
          yield put(messageModalActions.openSuccessMessageModal('MESSAGES.CANCEL_DOSSIER_SUCCESS'))
          if (response) {
            yield put(reset('salesDossier'))
            yield put(reset('purchase_dossier'))
            yield call(initializeDossier, dossierState.type, dossierState.dossier)
          }
        }
      }
    } else {
      const auth = yield select(getAuth)
      const confirmed = yield call(yesNoModal, 'commonDossierCancel')
      if (confirmed) {
        yield put(showLoader())
        const response = yield call(putDossierCancelPurchaseSelfSupply, dossierId, auth.token)

        yield put(messageModalActions.openSuccessMessageModal('MESSAGES.CANCEL_DOSSIER_SUCCESS'))
        if (response) {
          yield put(reset('salesDossier'))
          yield put(reset('purchase_dossier'))
          yield call(initializeDossier, dossierState.type, dossierState.dossier)
        }
      }
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          yield call(initializeDossier, dossierState.type, dossierState.dossier)
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('MESSAGES.CANCEL_DOSSIER_ERROR', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('purchase_dossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchputCancelDossierPurchaseSelf () {
  yield takeEvery(dossierActionsTypes.PUT_CANCEL_DOSSIER_PURCHASE_SELF_SUPPLY, putCancelDossierPurchaseSelf)
}

export function * updateErrorModel ({ model, fields }) {
  try {
    if (model && fields) {
      if (fields.length > 0) {
        for (let i = 0; i < fields.length; i++) {
          yield put(setSubmitFailed(model, fields[i]))
        }
      }
    }
  } catch (error) {
  }
}

export function * watchUpdateErrorModel () {
  yield takeEvery(commonActionTypes.UPDATE_ERROR_MODEL, updateErrorModel)
}

export function * checkCurrencyDocTypes ({ dossierId, currencyId, pristine }) {
  const dossierState = yield call(getDossierStateById, dossierId)
  const auth = yield select(getAuth)
  try {
    if (dossierState.dossier.salesComponent.currencyId !== currencyId) {
      let check = yield call(getCheckDocumentChargeValidated, dossierId, auth.token)
      if (check) {
        const confirmedSaveWarning = yield call(yesNoModal, 'commonCurrencyChangeWarning')
        if (!confirmedSaveWarning) {
          let salesDossier = JSON.parse(JSON.stringify(yield select(state => state.form.salesDossier.values)))
          salesDossier.salesComponent.currencyId = dossierState.dossier.salesComponent.currencyId
          if (pristine) {
            yield put(initialize('salesDossier', salesDossier))
          } else {
            yield put(change('salesDossier', 'salesComponent', salesDossier.salesComponent))
          }
        }
      }
    }
  } catch (error) {
    const customHandler = function * (customHandlerError) {
      if (customHandlerError.json && (customHandlerError.json.tag === 'DossierOutOfDate' || customHandlerError.json.tag === 'TRANS/DossierOutOfDate')) {
        yield put(hideLoader())
        const confirmedReloadWarning = yield call(yesNoModal, 'DossierOutOfDate')
        if (confirmedReloadWarning) {
          yield put(initializeDossier(dossierState.type, dossierState.dossier))
        }
      } else {
        yield put(messageModalActions.openErrorMessageModal('', customHandlerError.json ? customHandlerError.json.tag : ''))
      }
    }
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
    }
    yield call(handleError, { error, customHandler, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchCheckCurrencyDocTypes () {
  yield takeEvery(dossierActionsTypes.CHECK_DOC_TYPES_BEF_CHANGE_CURRENCY, checkCurrencyDocTypes)
}

export function * refreshAuditFleetInfo ({ fleetId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    let auditInfo = yield call(getAuditFleetInfo, auth.token, fleetId)
    yield put(fetchAuditFleetInfoSucces(auditInfo, fleetId))
  } catch (error) {
    const sessionHandler = function * () {
    }
    yield call(handleError, { error, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}
export function * watchRefreshAuditFleetInfo () {
  yield takeEvery(auditActionsTypes.REFRESH_AUDIT_FLEET_INFO, refreshAuditFleetInfo)
}

export function * auditFleetInfoDownload ({ fleetId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(getAuditFleetInfoDownload, auth.token, fleetId)
  } catch (error) {
    const sessionHandler = function * () {
    }
    yield call(handleError, { error, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchAuditFleetInfoDownload () {
  yield takeEvery(auditActionsTypes.AUDIT_FLEET_INFO_DOWNLOAD, auditFleetInfoDownload)
}

export function * watchFetchDocumentsGroupedAuditDetails () {
  yield takeEvery(auditActionsTypes.FETCH_GROUPED_DOCUMENTS_AUDIT_DETAILS, fetchDocumentsGroupedAuditDetails)
}

export function * fetchDocumentsGroupedAuditDetails ({ dossierId, historical, idx, resolve }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    let auditInfo = {}
    if (historical) {
      auditInfo = yield call(getAuditHistoricalGroupedDocumentsDetails, auth.token, dossierId, idx)
    } else {
      auditInfo = yield call(getAuditGroupedDocumentsDetails, auth.token, dossierId, idx)
    }
    if (resolve) resolve(auditInfo)
  } catch (error) {
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('registrationDeliveryDossier'))
      yield put(reset('service_dossier'))
      yield put(reset('stock_dossier'))
    }
    yield call(handleError, { error, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDocumentsGroupedAuditDetailsModal () {
  yield takeEvery(auditActionsTypes.FETCH_GROUPED_DOCUMENTS_AUDIT_DETAILS_MODAL, fetchDocumentsGroupedAuditDetailsModal)
}

export function * fetchDocumentsGroupedAuditDetailsModal ({ documentTypeUsesNames }) {
  try {
    yield put(hideLoader())
    yield call(yesNoModal, 'auditDetailsDocumentsGrouped', null, null, null, null, documentTypeUsesNames)
  } catch (error) {
    const sessionHandler = function * () {
      yield put(reset('salesDossier'))
      yield put(reset('registrationDeliveryDossier'))
      yield put(reset('service_dossier'))
      yield put(reset('stock_dossier'))
    }
    yield call(handleError, { error, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * fetchOpenCodeCommisionModal ({ organizedUnits, codeCommissionId }) {
  try {
    yield put(showLoader())
    yield put(change('codeCommisionModal', 'codeCommissionId', codeCommissionId))
    const auth = yield select(getAuth)
    let codeCommissionCombo = yield call(getCodeCommissionModal, auth.token, organizedUnits)
    yield put(openCodeCommissionModalSuccess(organizedUnits, codeCommissionCombo, codeCommissionId))
  } catch (error) {
    const sessionHandler = function * () {
    }
    yield call(handleError, { error, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchOpenCodeCommisionModal () {
  yield takeEvery(codeCommissionModalActionTypes.OPEN_CODE_COMMISION_MODAL, fetchOpenCodeCommisionModal)
}

export function * fecthCodeCommissionEconomicPlan ({ codeCommission }) {
  try {
    yield put(change('salesDossier', 'dossierEconomicComponent.commissionCancellationCodeId', codeCommission.commissionCancellationCodeId))
    yield put(changeCodeCommissionSuccess(codeCommission))
    yield put(messageModalActions.openSuccessMessageModal('MESSAGES.APPLIED_CODE_COMMISSION'))
  } catch (error) {
    const sessionHandler = function * () {
    }
    yield call(handleError, { error, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchCodeCommissionEconomicPlan () {
  yield takeEvery(dossierActionsTypes.CHANGE_CODE_COMMISSION, fecthCodeCommissionEconomicPlan)
}

export function * fecthCodeCommissionCombo ({ organizedUnits }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    let codeCommissionCombo = yield call(getCodeCommissionModal, auth.token, organizedUnits)
    yield put(fetchCodeCommissionComboSuccess(codeCommissionCombo))
  } catch (error) {
    const sessionHandler = function * () {
    }
    yield call(handleError, { error, sessionHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchCodeCommissionCombo () {
  yield takeEvery(codeCommissionModalActionTypes.FETCH_CODE_COMMISION_COMBO, fecthCodeCommissionCombo)
}
