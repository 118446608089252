export default {
  // COMMON
  STORE_DOSSIER: 'STORE_DOSSIER',
  SUBMIT_NEW_DOSSIER: 'SUBMIT_NEW_DOSSIER',
  UPDATE_DOSSIER_COMMON: 'UPDATE_DOSSIER_COMMON',
  UNDO_CANCEL_DOSSIER: 'UNDO_CANCEL_DOSSIER',
  CONFIRM_CANCEL_DOSSIER: 'CONFIRM_CANCEL_DOSSIER',
  DOCUMENTS_TO_SEND_SIGN: 'DOCUMENTS_TO_SEND_SIGN',
  DOCUMENTS_TO_SEND_SIGN_GETALL: 'DOCUMENTS_TO_SEND_SIGN_GETALL',
  SEND_DOCUMENTS_TO_SEND: 'SEND_DOCUMENTS_TO_SEND',
  // SALES DOSSIER
  FETCH_SALES_DOSSIER: 'FETCH_SALES_DOSSIER',
  FETCH_SALES_DOSSIER_VEHICLE: 'FETCH_SALES_DOSSIER_VEHICLE',
  FETCH_SALES_DOSSIER_VEHICLE_HISTORICAL: 'FETCH_SALES_DOSSIER_VEHICLE_HISTORICAL',
  FETCH_SALES_DOSSIER_CLIENT: 'FETCH_SALES_DOSSIER_CLIENT',
  FETCH_SALES_DOSSIER_CLIENT_HISTORICAL: 'FETCH_SALES_DOSSIER_CLIENT_HISTORICAL',
  FETCH_SALES_DOSSIER_FINANCING: 'FETCH_SALES_DOSSIER_FINANCING',
  FETCH_SALES_DOSSIER_INSURANCE_CARRIER: 'FETCH_SALES_DOSSIER_INSURANCE_CARRIER',
  FETCH_SALES_DOSSIER_INSURANCE_CARRIER_HISTORICAL: 'FETCH_SALES_DOSSIER_INSURANCE_CARRIER_HISTORICAL',
  FETCH_SALES_DOSSIER_DELIVERY: 'FETCH_SALES_DOSSIER_DELIVERY',
  FETCH_SALES_DOSSIER_DELIVERY_HISTORICAL: 'FETCH_SALES_DOSSIER_DELIVERY_HISTORICAL',
  FETCH_SALES_DOSSIER_FINANCING_HISTORICAL: 'FETCH_SALES_DOSSIER_FINANCING_HISTORICAL',
  REMOVE_FINANCE: 'REMOVE_FINANCE',
  CLEAN_FINANCE: 'CLEAN_FINANCE',
  FETCH_SALES_DOSSIER_ECONOMIC: 'FETCH_SALES_DOSSIER_ECONOMIC',
  FETCH_SALES_DOSSIER_ECONOMIC_HISTORICAL: 'FETCH_SALES_DOSSIER_ECONOMIC_HISTORICAL',
  FETCH_SALES_DOSSIER_DEPENDANTS: 'FETCH_SALES_DOSSIER_DEPENDANTS',
  FETCH_SALES_DOSSIER_DEPENDANTS_ECONOMIC: 'FETCH_SALES_DOSSIER_DEPENDANTS_ECONOMIC',
  POST_PAYMENT_SIGNAL_STATUS_SUCCESS: 'POST_PAYMENT_SIGNAL_STATUS_SUCCESS',
  FETCH_SALES_DOSSIER_SUCCESS: 'FETCH_SALES_DOSSIER_SUCCESS',
  FETCH_SALES_DOSSIER_ECONOMIC_SUCCESS: 'FETCH_SALES_DOSSIER_ECONOMIC_SUCCESS',
  FETCH_INITIALIZE_SALES_COMPONENT: 'FETCH_INITIALIZE_SALES_COMPONENT',
  SAVE_SALES_DOSSIER: 'SAVE_SALES_DOSSIER',
  SAVE_FLEET_CODE: 'SAVE_FLEET_CODE',
  FETCH_CLIENT_DNI: 'FETCH_CLIENT_DNI',
  FETCH_CLIENT_EMAIL: 'FETCH_CLIENT_EMAIL',
  FETCH_CLIENT_DNI_ENTITY_CHILD: 'FETCH_CLIENT_DNI_ENTITY_CHILD',
  PRINT_DOCUMENTARY_CHECKLIST: 'PRINT_DOCUMENTARY_CHECKLIST',
  PRINT_DOCUMENTARY_WARRANTY: 'PRINT_DOCUMENTARY_WARRANTY',
  UPDATE_DISCOUNT: 'UPDATE_DISCOUNT',
  UPDATE_DISCOUNT_SUCCESS: 'UPDATE_DISCOUNT_SUCCESS',
  UPDATE_OPTIONAL_SUCCESS: 'UPDATE_OPTIONAL_SUCCESS',
  UPDATE_EXTRA_SALES_SUCCESS: 'UPDATE_EXTRA_SALES_SUCCESS',
  UPDATE_PRESENTS_SUCCESS: 'UPDATE_PRESENTS_SUCCESS',
  UPDATE_SUPPLIEDS_SUCCESS: 'UPDATE_SUPPLIEDS_SUCCESS',
  CREATE_DISCOUNT: 'CREATE_DISCOUNT',
  DELETE_DISCOUNT: 'DELETE_DISCOUNT',
  FETCH_DISCOUNT_SUCCESS: 'FETCH_DISCOUNT_SUCCESS',
  UPDATE_EXTRA: 'UPDATE_EXTRA',
  CREATE_EXTRA: 'CREATE_EXTRA',
  DELETE_EXTRA: 'DELETE_EXTRA',
  UPDATE_EXTRA_SALE: 'UPDATE_EXTRA_SALE',
  CREATE_EXTRA_SALE: 'CREATE_EXTRA_SALE',
  DELETE_EXTRA_SALE: 'DELETE_EXTRA_SALE',
  UPDATE_PRESENT: 'UPDATE_PRESENT',
  CREATE_PRESENT: 'CREATE_PRESENT',
  DELETE_PRESENT: 'DELETE_PRESENT',
  FETCH_EXTRAS_SUCCESS: 'FETCH_EXTRAS_SUCCESS',
  UPDATE_SUPPLIED: 'UPDATE_SUPPLIED',
  CREATE_SUPPLIED: 'CREATE_SUPPLIED',
  DELETE_SUPPLIED: 'DELETE_SUPPLIED',
  FETCH_SUPPLIED_LIST_SUCCESS: 'FETCH_SUPPLIED_LIST_SUCCESS',
  UPDATE_OPTIONAL: 'UPDATE_OPTIONAL',
  CREATE_OPTIONAL: 'CREATE_OPTIONAL',
  DELETE_OPTIONAL: 'DELETE_OPTIONAL',
  FETCH_SALES_DOSSIER_OTHERDATA: 'FETCH_SALES_DOSSIER_OTHERDATA',
  FETCH_SALES_DOSSIER_OTHERDATA_HISTORICAL: 'FETCH_SALES_DOSSIER_OTHERDATA_HISTORICAL',
  FETCH_CAMPAIGN_EDITION: 'FETCH_CAMPAIGN_EDITION',
  DELETE_CAMPAIGN: 'DELETE_CAMPAIGN',
  UPDATE_CAMPAIGN: 'UPDATE_CAMPAIGN',
  CREATE_CAMPAIGN: 'CREATE_CAMPAIGN',
  FETCH_CAMPAIGNS_SUCCESS: 'FETCH_CAMPAIGNS_SUCCESS',
  FETCH_CAMPAIGNSTABLE_SUCCESS: 'FETCH_CAMPAIGNSTABLE_SUCCESS',
  FETCH_DOCUMENT_TO_SEND_SIGN_SUCCESS: 'FETCH_DOCUMENT_TO_SEND_SIGN_SUCCESS',
  FETCH_CAMPAIGNSTABLE_SUCCESS_ADD: 'FETCH_CAMPAIGNSTABLE_SUCCESS_ADD',
  FETCH_PAPERWORK_EDITION: 'FETCH_PAPERWORK_EDITION',
  DELETE_PAPERWORK: 'DELETE_PAPERWORK',
  UPDATE_PAPERWORK: 'UPDATE_PAPERWORK',
  CREATE_PAPERWORK: 'CREATE_PAPERWORK',
  FETCH_PAPERWORK_SUCCESS: 'FETCH_PAPERWORK_SUCCESS',
  FETCH_ECONOMIC_PLAN_SUCCESS: 'FETCH_ECONOMIC_PLAN_SUCCESS',
  UPDATE_SERVICE: 'UPDATE_SERVICE',
  CREATE_SERVICE: 'CREATE_SERVICE',
  DELETE_SERVICE: 'DELETE_SERVICE',
  FETCH_SERVICE_SUCCESS: 'FETCH_SERVICE_SUCCESS',
  SALE_APPROVE: 'SALE_APPROVE',
  SALE_APPROVE_PAPERWORK: 'SALE_APPROVE_PAPERWORK',
  SALE_REJECT: 'SALE_REJECT',
  SALE_SKIP_CHARGE: 'SALE_SKIP_CHARGE',
  SALE_SKIP_DOCUMENT: 'SALE_SKIP_DOCUMENT',
  SALE_SKIP_PAPERWORK: 'SALE_SKIP_PAPERWORK',
  SALE_SKIP_DELIVERY: 'SALE_SKIP_DELIVERY',
  SALE_SEND_APPROVE: 'SALE_SEND_APPROVE',
  SALE_APPROVE_BY_ROLE: 'SALE_APPROVE_BY_ROLE',
  SALE_TRANSFER_REQUEST: 'SALE_TRANSFER_REQUEST',
  SALE_TRANSFER_REQUEST_APPROVED: 'SALE_TRANSFER_REQUEST_APPROVED',
  UPDATE_DEPENDANTS_SALE_DOSSIER_AND_HEADER: 'UPDATE_DEPENDANTS_SALE_DOSSIER_AND_HEADER',
  PRINT_PAPERWORK: 'PRINT_PAPERWORK',
  RESTART_SALES_STATE: 'RESTART_SALES_STATE',
  PUT_PURCHASE_ON_SALE: 'PUT_PURCHASE_ON_SALE',
  PUT_LASTACCESSDATE: 'PUT_LASTACCESSDATE',
  FETCH_DOSSIER_RELATED_DOSSIERS_SUCCESS: 'FETCH_DOSSIER_RELATED_DOSSIERS_SUCCESS',
  SALE_WARNING_SAVE: 'SALE_WARNING_SAVE',
  SALE_APPROVE_FLEET: 'SALE_APPROVE_FLEET',
  SALE_APPROVE_VOUCHER: 'SALE_APPROVE_VOUCHER',
  SALE_PAY_RENTING: 'SALE_PAY_RENTING',
  SALE_UPDATE_ORDER_MAKERNUMBER: 'SALE_UPDATE_ORDER_MAKERNUMBER',
  UPDATE_ASSOCIATED_COST: 'UPDATE_ASSOCIATED_COST',
  CREATE_ASSOCIATED_COST: 'CREATE_ASSOCIATED_COST',
  DELETE_ASSOCIATED_COST: 'DELETE_ASSOCIATED_COST',
  UPDATE_ASSOCIATED_COSTS_SUCCESS: 'UPDATE_ASSOCIATED_COSTS_SUCCESS',
  CONFIGURE_CAMPAIGN: 'CONFIGURE_CAMPAIGN',
  CONFIGURE_CAMPAIGN_SAVE: 'CONFIGURE_CAMPAIGN_SAVE',
  CONFIGURE_CAMPAIGN_SEARCHCODE: 'CONFIGURE_CAMPAIGN_SEARCHCODE',
  CONFIGURE_CAMPAIGN_HASOPTIONALS: 'CONFIGURE_CAMPAIGN_HASOPTIONALS',
  CONFIGURE_CAMPAIGN_COMPATIBILITY: 'CONFIGURE_CAMPAIGN_COMPATIBILITY',
  SKIP_ITV: 'SKIP_ITV',
  FETCH_DISCOUNT_MODAL: 'FETCH_DISCOUNT_MODAL',
  FETCH_PROCESSING_AGENCY_MODAL: 'FETCH_PROCESSING_AGENCY_MODAL',
  FETCH_AMOUNT_TAX_MODAL: 'FETCH_AMOUNT_TAX_MODAL',
  FETCH_ASSOCIATED_COST_BY_PERCENTAGE_MODAL: 'FETCH_ASSOCIATED_COST_BY_PERCENTAGE_MODAL',
  FETCH_SALES_DOSSIER_SELFSUPPLY: 'FETCH_SALES_DOSSIER_SELFSUPPLY',
  FETCH_SALES_DOSSIER_SELFSUPPLY_HISTORICAL: 'FETCH_SALES_DOSSIER_SELFSUPPLY_HISTORICAL',
  FETCH_SALES_DOSSIER_SELFSUPPLY_SUCCESS: 'FETCH_SALES_DOSSIER_SELFSUPPLY_SUCCESS',
  FETCH_FLEET_OPERATION_TYPE_FOR_AUTOSELECT_SUCCESS: 'FETCH_FLEET_OPERATION_TYPE_FOR_AUTOSELECT_SUCCESS',
  PRINT_SUMMARY_SALE: 'PRINT_SUMMARY_SALE',
  SAVE_REFINANCING: 'SAVE_REFINANCING',
  SAVE_REFINANCING_SUCCES: 'SAVE_REFINANCING_SUCCES',
  DELETE_REFINANCING: 'DELETE_REFINANCING',
  CHECK_PRIVATE_RFC_FORMAT: 'CHECK_PRIVATE_RFC_FORMAT',
  FOREIGN_PASSPORT_WARNING: 'FOREIGN_PASSPORT_WARNING',
  UPDATE_CHECK_CLIENT_NOT_EMPLOYEE: 'UPDATE_CHECK_CLIENT_NOT_EMPLOYEE',
  SEND_TO_BPRO_DMS: 'SEND_TO_BPRO_DMS',
  REQUEST_INVOICE_TO_DMS: 'REQUEST_INVOICE_TO_DMS',
  REQUEST_DELIVERY_TO_DMS: 'REQUEST_DELIVERY_TO_DMS',
  REQUEST_OUTBOUND_TO_DMS: 'REQUEST_OUTBOUND_TO_DMS',
  SALE_TRANSFER_REJECT: 'SALE_TRANSFER_REJECT',
  CHANGE_CODE_COMMISSION: 'CHANGE_CODE_COMMISSION',
  CHANGE_CODE_COMMISSION_SUCCESS: 'CHANGE_CODE_COMMISSION_SUCCESS',
  // PAPERWORK_DOSSIER
  FETCH_PAPERWORK_DOSSIER: 'FETCH_PAPERWORK_DOSSIER',
  FETCH_PAPERWORK_DOSSIER_SUCCESS: 'FETCH_PAPERWORK_DOSSIER_SUCCESS',
  SAVE_PAPERWORK_DOSSIER: 'SAVE_PAPERWORK_DOSSIER',
  PAPERWORK_REJECT: 'PAPERWORK_REJECT',
  PAPERWORK_GEN_DELIVERY: 'PAPERWORK_GEN_DELIVERY',
  RESTART_PAPERWORK_STATE: 'RESTART_PAPERWORK_STATE',
  PAPERWORK_WARNING_SAVE: 'PAPERWORK_WARNING_SAVE',
  FETCH_PAPERWORK_DOSSIER_VEHICLE: 'FETCH_PAPERWORK_DOSSIER_VEHICLE',
  FETCH_PAPERWORK_DOSSIER_CLIENT: 'FETCH_PAPERWORK_DOSSIER_CLIENT',
  FETCH_PAPERWORK_DOSSIER_CLIENT_HISTORICAL: 'FETCH_PAPERWORK_DOSSIER_CLIENT_HISTORICAL',
  FETCH_PAPERWORK_DOSSIER_VEHICLE_HISTORICAL: 'FETCH_PAPERWORK_DOSSIER_VEHICLE_HISTORICAL',
  PAPERWORK_RESEND_TO_PROCESSING_AGENCY: 'PAPERWORK_RESEND_TO_PROCESSING_AGENCY',
  PAPERWORK_NOTIFY: 'PAPERWORK_NOTIFY',
  PAPERWORK_TRANSFER_COMPLETED: 'PAPERWORK_TRANSFER_COMPLETED',
  // PURCHASE_DOSSIER
  FETCH_PURCHASE_DOSSIER: 'FETCH_PURCHASE_DOSSIER',
  FETCH_PURCHASE_DOSSIER_CLIENT: 'FETCH_PURCHASE_DOSSIER_CLIENT',
  FETCH_PURCHASE_DOSSIER_VEHICLE: 'FETCH_PURCHASE_DOSSIER_VEHICLE',
  FETCH_PURCHASE_DOSSIER_OTHER_DATA: 'FETCH_PURCHASE_DOSSIER_OTHER_DATA',
  FETCH_PURCHASE_DOSSIER_DEPENDANTS: 'FETCH_PURCHASE_DOSSIER_DEPENDANTS',
  FETCH_PURCHASE_DOSSIER_ECONOMIC_PLAN: 'FETCH_PURCHASE_DOSSIER_ECONOMIC_PLAN',
  FETCH_PURCHASE_DOSSIER_SUCCESS: 'FETCH_PURCHASE_DOSSIER_SUCCESS',
  RESTART_PURCHASE_STATE: 'RESTART_PURCHASE_STATE',
  PURCHASE_REJECT: 'PURCHASE_REJECT',
  PURCHASE_SEND_APPROVE: 'PURCHASE_SEND_APPROVE',
  PURCHASE_APPROVE: 'PURCHASE_APPROVE',
  PURCHASE_APPROVE_MANAGER_VO: 'PURCHASE_APPROVE_MANAGER_VO',
  PURCHASE_APPROVE_SALES_MANGER_VO: 'PURCHASE_APPROVE_SALES_MANGER_VO',
  SAVE_PURCHASE_DOSSIER: 'SAVE_PURCHASE_DOSSIER',
  PURCHASE_WARNING_SAVE: 'PURCHASE_WARNING_SAVE',
  PURCHASE_SYNCHRONISE_AVAILABILITY_DATE: 'PURCHASE_SYNCHRONISE_AVAILABILITY_DATE',
  UPDATE_DEPENDANTS_PURCHASE_DOSSIER_AND_HEADER: 'UPDATE_DEPENDANTS_PURCHASE_DOSSIER_AND_HEADER',
  CHANGE_DOSSIER_SUBTYPE: 'CHANGE_DOSSIER_SUBTYPE',
  FETCH_PURCHASE_DOSSIER_CLIENT_HISTORICAL: 'FETCH_PURCHASE_DOSSIER_CLIENT_HISTORICAL',
  FETCH_PURCHASE_DOSSIER_VEHICLE_HISTORICAL: 'FETCH_PURCHASE_DOSSIER_VEHICLE_HISTORICAL',
  FETCH_PURCHASE_DOSSIER_OTHERDATA_HISTORICAL: 'FETCH_PURCHASE_DOSSIER_OTHERDATA_HISTORICAL',
  CREATE_PURCHASE_PRESENT: 'CREATE_PURCHASE_PRESENT',
  DELETE_PURCHASE_PRESENT: 'DELETE_PURCHASE_PRESENT',
  RECOVER_PURCHASE_PRESENT: 'RECOVER_PURCHASE_PRESENT',
  UPDATE_PURCHASE_PRESENT: 'UPDATE_PURCHASE_PRESENT',
  UPDATE_PURCHASE_PRESENTS_SUCCESS: 'UPDATE_PURCHASE_PRESENTS_SUCCESS',
  CHECK_PUCHASE_TYPE_SET_DEFAULT_ENTITY: 'CHECK_PUCHASE_TYPE_SET_DEFAULT_ENTITY',
  LAUNCH_CAMPAIGN_PROCESS_FILES: 'LAUNCH_CAMPAIGN_PROCESS_FILES',
  LAUNCH_CAMPAIGN_PROCESS_FILES_SUCCES: 'LAUNCH_CAMPAIGN_PROCESS_FILES_SUCCES',
  FETCH_VEH_PURCH_CHASIS_LICENSE: 'FETCH_VEH_PURCH_CHASIS_LICENSE',
  PURCHASE_TRANSFER_REQUEST: 'PURCHASE_TRANSFER_REQUEST',
  PURCHASE_TRANSFER_REQUEST_APPROVED: 'PURCHASE_TRANSFER_REQUEST_APPROVED',
  PURCHASE_DOUBLE_TRANSFER_CHECK: 'PURCHASE_DOUBLE_TRANSFER_CHECK',
  FETCH_VEHICLE_CATALOGUE_MODEL: 'FETCH_VEHICLE_CATALOGUE_MODEL',
  FETCH_VEHICLE_CATALOGUE_MODEL_SUCCESS: 'FETCH_VEHICLE_CATALOGUE_MODEL_SUCCESS',
  REFRESH_PURCHASE_PRESENT_FROM_DOCUMENT_MODAL: 'REFRESH_PURCHASE_PRESENT_FROM_DOCUMENT_MODAL',
  PURCHASE_TRANSFER_REJECT: 'PURHCASE_TRANSFER_REJECT',

  // CAMPAIGN DOSSIER
  FETCH_CAMPAIGN_DOSSIER: 'FETCH_CAMPAIGN_DOSSIER',
  FETCH_CAMPAIGN_DOSSIER_SUCCESS: 'FETCH_CAMPAIGN_DOSSIER_SUCCESS',
  SAVE_CAMPAIGN_DOSSIER: 'SAVE_CAMPAIGN_DOSSIER',
  CAMPAIGN_REJECT: 'CAMPAIGN_REJECT',
  RESTART_CAMPAIGN_STATE: 'RESTART_CAMPAIGN_STATE',
  CAMPAIGN_APPROVE: 'CAMPAIGN_APPROVE',
  CAMPAIGN_SEND: 'CAMPAIGN_SEND',
  CAMPAIGN_DENY: 'CAMPAIGN_DENY',
  CAMPAIGN_WARNING_SAVE: 'CAMPAIGN_WARNING_SAVE',
  FETCH_DOCUMENTS_DOSSIERID: 'FETCH_DOCUMENTS_DOSSIERID',
  FETCH_DOCUMENTS_DOSSIERID_SUCCESS: 'FETCH_DOCUMENTS_DOSSIERID_SUCCESS',
  FETCH_DOCUMENT_HISTORICALS_DOSSIERID: 'FETCH_DOCUMENT_HISTORICALS_DOSSIERID',
  FETCH_DOCUMENT_HISTORICALS_DOSSIERID_SUCCESS: 'FETCH_DOCUMENT_HISTORICALS_DOSSIERID_SUCCESS',
  SAVE_SELECTED_DOCUMENTS: 'SAVE_SELECTED_DOCUMENTS',
  OPEN_SEARCH_DOCUMENTS_MODAL: 'OPEN_SEARCH_DOCUMENTS_MODAL',
  FETCH_CAMPAIGN_DOSSIER_VEHICLE: 'FETCH_CAMPAIGN_DOSSIER_VEHICLE',
  FETCH_CAMPAIGN_DOSSIER_CLIENT: 'FETCH_CAMPAIGN_DOSSIER_CLIENT',
  FETCH_CAMPAIGN_DOSSIER_VEHICLE_HISTORICAL: 'FETCH_CAMPAIGN_DOSSIER_VEHICLE_HISTORICAL',
  FETCH_CAMPAIGN_DOSSIER_CLIENT_HISTORICAL: 'FETCH_CAMPAIGN_DOSSIER_CLIENT_HISTORICAL',
  ANULATE_CAMPAIGN: 'ANULATE_CAMPAIGN',
  ACTIVE_CAMPAIGN: 'ACTIVE_CAMPAIGN',
  VALIDATE_CAMPAIGN: 'VALIDATE_CAMPAIGN',
  DENIE_CAMPAIGN: 'DENIE_CAMPAIGN',
  RETURN_CAMPAIGN: 'RETURN_CAMPAIGN',
  // DETAILS DOSSIER
  FETCH_CAMPAIGN_DETAILS: 'FETCH_CAMPAIGN_DETAILS',
  FETCH_CAMPAIGN_DETAILS_SUCCESS: 'FETCH_CAMPAIGN_DETAILS_SUCCESS',
  SALE_APPROVE_BILLING: 'SALE_APPROVE_BILLING',
  // REGISTRATION DELIVERY
  FETCH_REGISTRATION_DELIVERY_DOSSIER: 'FETCH_REGISTRATION_DELIVERY_DOSSIER',
  FETCH_REGISTRATION_DELIVERY_DOSSIER_VEHICLE: 'FETCH_REGISTRATION_DELIVERY_DOSSIER_VEHICLE',
  FETCH_REGISTRATION_DELIVERY_DOSSIER_VEHICLE_HISTORICAL: 'FETCH_REGISTRATION_DELIVERY_DOSSIER_VEHICLE_HISTORICAL',
  FETCH_REGISTRATION_DELIVERY_DOSSIER_CLIENT: 'FETCH_REGISTRATION_DELIVERY_DOSSIER_CLIENT',
  FETCH_REGISTRATION_DELIVERY_DOSSIER_CLIENT_HISTORICAL: 'FETCH_REGISTRATION_DELIVERY_DOSSIER_CLIENT_HISTORICAL',
  FETCH_REGISTRATION_DELIVERY_DOSSIER_FINANCING: 'FETCH_REGISTRATION_DELIVERY_DOSSIER_FINANCING',
  FETCH_REGISTRATION_DELIVERY_DOSSIER_FINANCING_HISTORICAL: 'FETCH_REGISTRATION_DELIVERY_DOSSIER_FINANCING_HISTORICAL',
  FETCH_REGISTRATION_DELIVERY_DOSSIER_DELIVERY: 'FETCH_REGISTRATION_DELIVERY_DOSSIER_DELIVERY',
  FETCH_REGISTRATION_DELIVERY_DOSSIER_DELIVERY_HISTORICAL: 'FETCH_REGISTRATION_DELIVERY_DOSSIER_DELIVERY_HISTORICAL',
  FETCH_REGISTRATION_DELIVERY_DOSSIER_DEPENDANTS: 'FETCH_REGISTRATION_DELIVERY_DOSSIER_DEPENDANTS',
  FETCH_REGISTRATION_DELIVERY_DOSSIER_DEPENDANTS_ECONOMIC: 'FETCH_REGISTRATION_DELIVERY_DOSSIER_DEPENDANTS_ECONOMIC',
  FETCH_REGISTRATION_DELIVERY_DOSSIER_OTHERDATA: 'FETCH_REGISTRATION_DELIVERY_DOSSIER_OTHERDATA',
  FETCH_REGISTRATION_DELIVERY_DOSSIER_OTHERDATA_HISTORICAL: 'FETCH_REGISTRATION_DELIVERY_DOSSIER_OTHERDATA_HISTORICAL',
  FETCH_REGISTRATION_DELIVERY_DOSSIER_SUCCESS: 'FETCH_REGISTRATION_DELIVERY_DOSSIER_SUCCESS',
  FETCH_REGISTRATION_DELIVERY_DOSSIER_ECONOMIC: 'FETCH_REGISTRATION_DELIVERY_DOSSIER_ECONOMIC',
  FETCH_REGISTRATION_DELIVERY_DOSSIER_ECONOMIC_SUCCESS: 'FETCH_REGISTRATION_DELIVERY_DOSSIER_ECONOMIC_SUCCESS',
  FETCH_REGISTRATION_DELIVERY_DOSSIER_ECONOMIC_HISTORICAL: 'FETCH_REGISTRATION_DELIVERY_DOSSIER_ECONOMIC_HISTORICAL',
  RESTART_REGISTRATION_DELIVERY_STATE: 'RESTART_REGISTRATION_DELIVERY_STATE',
  SAVE_REGISTRATION_DELIVERY_DOSSIER: 'SAVE_REGISTRATION_DELIVERY_DOSSIER',
  REGISTRATION_DELIVERY_WARNING_SAVE: 'REGISTRATION_DELIVERY_WARNING_SAVE',
  REGISTRATION_DELIVERY_PRINT_DELIVERY_CHECKLIST: 'REGISTRATION_DELIVERY_PRINT_DELIVERY_CHECKLIST',
  PUT_PURCHASE_ON_REGISTRATION_DELIVERY: 'PUT_PURCHASE_ON_REGISTRATION_DELIVERY',
  UPDATE_DEPENDANTS_REGISTRATION_DELIVERY_DOSSIER_AND_HEADER: 'UPDATE_DEPENDANTS_REGISTRATION_DELIVERY_DOSSIER_AND_HEADER',

  // SERVICE DOSSIER
  FETCH_SERVICE_DOSSIER: 'FETCH_SERVICE_DOSSIER',
  FETCH_SERVICE_DOSSIER_SUCCESS: 'FETCH_SERVICE_DOSSIER_SUCCESS',
  SAVE_SERVICE_DOSSIER: 'SAVE_SERVICE_DOSSIER',
  SERVICE_DOSSIER_WARNING_SAVE: 'SERVICE_DOSSIER_WARNING_SAVE',
  RESTART_SERVICE_DOSSIER_STATE: 'RESTART_SERVICE_DOSSIER_STATE',
  FETCH_SALES_SERVICES: 'FETCH_SALES_SERVICES',
  FETCH_SALES_SERVICES_SUCCESS: 'FETCH_SALES_SERVICES_SUCCESS',
  SAVE_SALES_SERVICE: 'SAVE_SALES_SERVICE',
  FETCH_SERVICE_DOSSIER_VEHICLE: 'FETCH_SERVICE_DOSSIER_VEHICLE',
  FETCH_SERVICE_DOSSIER_VEHICLE_HISTORICAL: 'FETCH_SERVICE_DOSSIER_VEHICLE_HISTORICAL',
  SERVICE_DOSSIER_APPROVE: 'SERVICE_DOSSIER_APPROVE',
  APPROVE_SERVICE_DOSSIER: 'APPROVE_SERVICE_DOSSIER',
  SAVE_ISSUE_SERVICE: 'SAVE_ISSUE_SERVICE',
  OPEN_OR_CLOSE_ISSUE_MODAL: 'OPEN_OR_CLOSE_ISSUE_MODAL',
  RESOLVE_SERVICE_ISSUE: 'RESOLVE_SERVICE_ISSUE',
  REQUEST_SERVICE_SPARE_PARTS: 'REQUEST_SERVICE_SPARE_PARTS',
  COMPLETE_SERVICE_SPARE_PARTS: 'COMPLETE_SERVICE_SPARE_PARTS',
  COMPLETE_SERVICE_DELIVERY_PREPARATION: 'COMPLETE_SERVICE_DELIVERY_PREPARATION',
  FETCH_SERVICE_NOT_VALID_DATES: 'FETCH_SERVICE_NOT_VALID_DATES',
  OPEN_ADD_SERVICE_MODAL: 'OPEN_ADD_SERVICE_MODAL',
  SAVE_SERVICES_AVAILABILITY_DATE: 'SAVE_SERVICES_AVAILABILITY_DATE',
  SAVE_SERVICES_CONFIRMATION_LOGISTICS: 'SAVE_SERVICES_CONFIRMATION_LOGISTICS',
  SAVE_SERVICES_CONFIRMATION_LOGISTICS_SUCCESS: 'SAVE_SERVICES_CONFIRMATION_LOGISTICS',
  OPEN_VEHICLE_FINDER_MODAL: 'OPEN_VEHICLE_FINDER_MODAL',

  // SELF SUPPLY DOSSIER
  PUT_CANCEL_DOSSIER_PURCHASE_SELF_SUPPLY: 'PUT_CANCEL_DOSSIER_PURCHASE_SELF_SUPPLY',

  UPDATE_DOSSIER_TOSHIKO: 'UPDATE_DOSSIER_TOSHIKO',
  FETCH_PROCESSING_AGENCY_TYPE: 'FETCH_PROCESSING_AGENCY_TYPE',
  CHECK_DOC_TYPES_BEF_CHANGE_CURRENCY: 'CHECK_DOC_TYPES_BEF_CHANGE_CURRENCY',

  // RESULT FROM SUBMIT
  UPDATE_RESULT_SUBMIT: 'UPDATE_RESULT_SUBMIT',

  // DEFAULT DESTINATION
  FETCH_SALES_DEFAULT_DESTINATION: 'FETCH_SALES_DEFAULT_DESTINATION',
  FETCH_PURCHASE_DEFAULT_DESTINATION: 'FETCH_PURCHASE_DEFAULT_DESTINATION',
  FETCH_SERVICE_DEFAULT_DESTINATION: 'FETCH_SERVICE_DEFAULT_DESTINATION',

  // DOSSIER STOCK
  FETCH_STOCK_DOSSIER: 'FETCH_STOCK_DOSSIER',
  FETCH_STOCK_DOSSIER_SUCCESS: 'FETCH_STOCK_DOSSIER_SUCCESS',
  RESTART_STOCK_DOSSIER_STATE: 'RESTART_STOCK_DOSSIER_STATE',
  SAVE_STOCK_DOSSIER: 'SAVE_STOCK_DOSSIER',
  STOCK_DOSSIER_WARNING_SAVE: 'STOCK_DOSSIER_WARNING_SAVE',
  FETCH_STOCK_DOSSIER_VEHICLE: 'FETCH_STOCK_DOSSIER_VEHICLE',
  FETCH_STOCK_DOSSIER_VEHICLE_HISTORICAL: 'FETCH_STOCK_DOSSIER_VEHICLE_HISTORICAL',
  STOCK_DOSSIER_VO_CONFIRM_DATE: 'STOCK_DOSSIER_VO_CONFIRM_DATE',
  STOCK_DOSSIER_DOSSIER_CRANE_CONFIRM_DATE: 'STOCK_DOSSIER_DOSSIER_CRANE_CONFIRM_DATE',
  STOCK_DOSSIER_GET_BUDGET: 'STOCK_DOSSIER_GET_BUDGET',
  DELETE_DOSSIER_RATING: 'DELETE_DOSSIER_RATING',
  DELETE_DOSSIER_RATING_SUCCESS: 'DELETE_DOSSIER_RATING_SUCCESS',
  ADD_DOSSIER_RATING: 'ADD_DOSSIER_RATING',
  ADD_DOSSIER_RATING_SUCCESS: 'ADD_DOSSIER_RATING_SUCCESS',
  FETCH_STOCK_DEFAULT_DESTINATION: 'FETCH_STOCK_DEFAULT_DESTINATION',
  UPDATE_DOSSIER_BUDGET_RATING_MODAL: 'UPDATE_DOSSIER_BUDGET_RATING_MODAL',
  FETCH_DOSSIER_BUDGET_RATING: 'FETCH_DOSSIER_BUDGET_RATING',
  DELETE_DOSSIER_BUDGET_RATING: 'DELETE_DOSSIER_BUDGET_RATING',
  ADD_EDIT_DOSSIER_BUDGET_RATING: 'ADD_EDIT_DOSSIER_BUDGET_RATING',
  STOCK_DOSSIER_VO_REJECT_BUDGET: 'STOCK_DOSSIER_VO_REJECT_BUDGET',
  STOCK_DOSSIER_VO_CHANGE_ENTRY_RELATION: 'STOCK_DOSSIER_VO_CHANGE_ENTRY_RELATION',
  STOCK_DOSSIER_VO_ACTIVATE_CRANE: 'STOCK_DOSSIER_VO_ACTIVATE_CRANE',
  STOCK_DOSSIER_VO_DEACTIVATE_CRANE: 'STOCK_DOSSIER_VO_DEACTIVATE_CRANE',

  // CUSTOM FIELDS
  FETCH_CUSTOM_FIELDS: 'FETCH_CUSTOM_FIELDS',
  FETCH_CUSTOM_FIELDS_SUCCESS: 'FETCH_CUSTOM_FIELDS_SUCCESS',

  // PHOTOS
  GET_DOSSIER_PHOTO: 'GET_DOSSIER_PHOTO',
  GET_DOSSIER_PHOTO_SUCCESS: 'GET_DOSSIER_PHOTO_SUCCESS',
  STOCK_DOSSIER_VO_CONFIRM_PHOTO: 'STOCK_DOSSIER_VO_CONFIRM_PHOTO',
  SKIP_DOSSIER_PHOTOS: 'SKIP_DOSSIER_PHOTOS',

  PRINT_COMPLETE_SUMMARY_SALE: 'PRINT_COMPLETE_SUMMARY_SALE',

  // RESERVATION CONTRACT
  OPEN_RESERVATION_CONTRACT_MODAL: 'OPEN_RESERVATION_CONTRACT_MODAL',
  OPEN_RESERVATION_CONTRACT_MODAL_SUCCESS: 'OPEN_RESERVATION_CONTRACT_MODAL_SUCCESS',
  CLOSE_RESERVATION_CONTRACT_MODAL: 'CLOSE_RESERVATION_CONTRACT_MODAL',
  CLOSE_RESERVATION_CONTRACT_MODAL_SUCCESS: 'CLOSE_RESERVATION_CONTRACT_MODAL_SUCCESS'

}
