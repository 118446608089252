import { call, put, select, takeLatest } from 'redux-saga/effects'
import { getAuth } from '../../selectors/access/auth'
import getDossierSubTypeByType from '../../services/dossierType/getDossierSubTypeByType'
import getDossierSubTypeByTypeAndUo from '../../services/dossierType/getDossierSubTypeByTypeAndUo'
import getDossierSubTypeByOu from '../../services/dossierType/getDossierSubTypeByOu'
import commonActions from '../../actions/common'
import messageModalActions from '../../actions/modals/messageModal'
import { fetchDossierSubTypesSuccess } from '../../actions/combos/combos'
import combosActionTypes from '../../constants/actions/combos/combos'
import { handleError } from '../errors/errorManager'

export function * fetchDossierSubTypes ({ ouId, dossierTypeId, getOnlySelected, resolve, reject }) {
  try {
    yield put(commonActions.showLoader())
    const auth = yield select(getAuth)
    let response = []
    if (dossierTypeId) {
      if (ouId) {
        response = yield call(getDossierSubTypeByTypeAndUo, dossierTypeId, ouId, auth.token)
      } else {
        response = yield call(getDossierSubTypeByType, dossierTypeId, auth.token)
      }
    } else {
      response = yield call(getDossierSubTypeByOu, auth.token, ouId)
    }
    if (getOnlySelected && getOnlySelected.length > 0) {
      response = response.filter(r => getOnlySelected.includes(r.id))
    }

    yield put(fetchDossierSubTypesSuccess(response))
    if (resolve) {
      yield call(resolve)
    }
  } catch (error) {
    const customHandler = function * () {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.FETCH_COMBO_ERROR'))
    }
    yield call(handleError, { error, customHandler })
    if (reject) {
      yield call(reject)
    }
  } finally {
    yield put(commonActions.hideLoader())
  }
}

export function * watchFetchDossierSubTypes () {
  yield takeLatest(combosActionTypes.FETCH_DOSSIER_SUBTYPES, fetchDossierSubTypes)
}
