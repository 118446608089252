
import React, { PureComponent } from 'react'
import { Button, Modal, Col, Row, FormGroup } from 'react-bootstrap'
import { Field, reduxForm } from 'redux-form'
import InputText from '../../commons/form/InputText'
import InputSelect from '../../commons/form/InputSelect'
import InputCheckBox from '../../commons/form/InputCheckBox'

class OrganizedUnitModal extends PureComponent {
  constructor () {
    super()
    this.state = {
      idUo: null
    }
  }

  submitOrganizedUnit (values) {
    this.props.actions.saveOrganizedUnitModal(values, this.props.organizedUnitFields.organizedUnitId)
  }

  handleParentOUChange (value) {
    if (this.props.organizedUnitId === undefined || this.props.organizedUnitId === null) {
      this.props.actions.fetchProcessingAgencyCombo(value)
    }
  }

  componentDidUpdate () {
    if (this.props.organizedUnitId !== undefined && this.props.organizedUnitId !== null && this.props.organizedUnitId !== this.state.idUo) {
      this.props.actions.fetchProcessingAgencyCombo(this.props.organizedUnitId)
      this.props.actions.fetchSameLevelOusCombo(this.props.organizedUnitId, null)
      this.setState({ idUo: this.props.organizedUnitId })
    }
  }

  render () {
    const {
      t, showOrganizedUnitModal, handleSubmit,
      combos: { organizedUnitsAllCombo, legalCompanyCombo, directionOUCombo, countriesCombo, processingAgencyCombo, destinationCombo }
    } = this.props
    const tKey = 'MASTERS.OU_MASTERS.'
    return (
      <Modal show={showOrganizedUnitModal} onHide={this.props.actions.closeOrganizedUnitModal} className="languages-modal" backdrop={'static'}>
        <Modal.Header closeButton>
          <i className="ico-users" /><Modal.Title>{t(`${tKey}SOURCE_CHANNEL`)}</Modal.Title>
        </Modal.Header>
        <form autoComplete="off" onSubmit={ handleSubmit((values) => this.submitOrganizedUnit(values))}>
          <Modal.Body>
            <FormGroup>
              <Row className="inputs-wrapper flexRow">
                <Col sm={4} className="custom-width-col">
                  <h2>{t(`${tKey}UOCODE`)}</h2>
                  <Field
                    id="code"
                    name="code"
                    placeholder={t(`${tKey}UOCODE`)}
                    component={InputText}
                  />
                </Col>
                <Col sm={4} className="custom-width-col">
                  <h2>{t(`${tKey}NAME`)}</h2>
                  <Field
                    id="name"
                    name="name"
                    placeholder={t(`${tKey}NAME`)}
                    component={InputText}
                  />
                </Col>
                <Col sm={4} className="custom-width-col">
                  <h2>{t(`${tKey}BRAND`)}</h2>
                  <Field
                    id="brand"
                    name="brand"
                    placeholder={t(`${tKey}BRAND`)}
                    component={InputText}
                  />
                </Col>
                <Col sm={4} className="custom-width-col">
                  <h2>{t(`${tKey}LEGAL_COMPANY`)}</h2>
                  <Field
                    id ="legalCompanyId"
                    name="legalCompanyId"
                    placeholder={t(`${tKey}LEGAL_COMPANY`)}
                    valueKey="id"
                    labelKey="value"
                    component={InputSelect}
                    colSm={0}
                    options={legalCompanyCombo}
                  />
                </Col>
                <Col sm={4} className="custom-width-col">
                  <h2>{t(`${tKey}PARENT_OU`)}</h2>
                  <Field
                    id="parentOrganizedUnitId"
                    name="parentOrganizedUnitId"
                    placeholder={t(`${tKey}PARENT_OU`)}
                    valueKey="id"
                    labelKey="value"
                    component={InputSelect}
                    colSm={0}
                    onInputChange={(value) => this.handleParentOUChange(value)}
                    options={ organizedUnitsAllCombo && organizedUnitsAllCombo.filter(x => x.id !== this.props.organizedUnitId) }
                  />
                </Col>
                <Col sm={4} className="custom-width-col">
                  <h2>{t(`${tKey}NOTIFICATION_MAILS`)}</h2>
                  <Field
                    id="notificationMails"
                    name="notificationMails"
                    placeholder={t(`${tKey}NOTIFICATION_MAILS`)}
                    component={InputText}
                  />
                </Col>
                <Col sm={8} className="custom-width-col">
                  <h2>{t(`${tKey}DIRECTION_OU`)}</h2>
                  <Field
                    id="directionUOId"
                    name="directionUOId"
                    placeholder={t(`${tKey}DIRECTION_OU`)}
                    valueKey="id"
                    labelKey="value"
                    component={InputSelect}
                    colSm={0}
                    options={directionOUCombo}
                  />
                </Col>
                <Col sm={4} className="custom-width-col">
                  <h2>{t(`${tKey}COUNTRY`)}</h2>
                  <Field
                    id="countryId"
                    name="countryId"
                    placeholder={t(`${tKey}COUNTRY`)}
                    valueKey="countryId"
                    labelKey="code"
                    component={InputSelect}
                    colSm={0}
                    options={countriesCombo}
                  />
                </Col>
                <Col sm={4} className="custom-width-col">
                  <h2>{t(`${tKey}PROCESSING_AGENCY`)}</h2>
                  <Field
                    id="defaultProcessingAgencyId"
                    name="defaultProcessingAgencyId"
                    placeholder={t(`${tKey}PROCESSING_AGENCY`)}
                    valueKey="id"
                    labelKey="value"
                    component={InputSelect}
                    colSm={0}
                    options={processingAgencyCombo}
                  />
                </Col>
                <Col sm={4} className="custom-width-col">
                  <h2>{t(`${tKey}ACCOUNTS`)}</h2>
                  <Field
                    id="accounts"
                    name="accounts"
                    placeholder={t(`${tKey}ACCOUNTS`)}
                    component={InputText}
                  />
                </Col>
                <Col sm={4} className="custom-width-col">
                  <h2>{t(`${tKey}DOCUMENT_LOGO`)}</h2>
                  <Field
                    id="documentLogoName"
                    name="documentLogoName"
                    placeholder={t(`${tKey}DOCUMENT_LOGO`)}
                    component={InputText}
                  />
                </Col>
                <Col sm={4} className="custom-width-col">
                  <h2>{t(`${tKey}DESTINY`)}</h2>
                  <Field
                    name="defaultDestinationId"
                    placeholder={t(`${tKey}DESTINY`)}
                    valueKey="id"
                    labelKey="value"
                    options={destinationCombo}
                    component={InputSelect}
                    colSm={0}
                  />
                </Col>
              </Row>
              <Row className="inputs-wrapper flexRow">
                <Col sm={4} className="custom-width-col">
                  <h2>{t(`${tKey}ACCEPTS_CLIENTS`)}</h2>
                  <Field
                    name="acceptsClients"
                    placeholder={t(`${tKey}ACCEPTS_CLIENTS`)}
                    component={InputCheckBox}
                  />
                </Col>
                <Col sm={4} className="custom-width-col">
                  <h2>{t(`${tKey}ACCEPTS_VEHICLES`)}</h2>
                  <Field
                    name="acceptsVehicles"
                    placeholder={t(`${tKey}ACCEPTS_VEHICLES`)}
                    component={InputCheckBox}
                  />
                </Col>
              </Row>
            </FormGroup>
          </Modal.Body>
          <Modal.Footer>
            <Col sm={6} className="btn-wrapper" smPush={6}>
              <Button type="submit" className="btn-standard" disabled={this.props.pristine}>
                {t('MASTERS.SAVE')}
              </Button>
            </Col>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

const validate = (values, props) => {
  const errors = {}
  if (values) {
    const tKey = 'MASTERS.OU_MASTERS.VALIDATION.'
    if (!values.code || values.code.length > 10) {
      errors.code = props.t(`${tKey}CODE`)
    }
    if (!values.name) {
      errors.name = props.t(`${tKey}NAME`)
    }
    if (!values.brand) {
      errors.brand = props.t(`${tKey}BRAND`)
    }
    if (!values.parentOrganizedUnitId) {
      errors.parentOrganizedUnitId = props.t(`${tKey}PARENT_OU`)
    }
  }

  return errors
}

export default reduxForm({
  form: 'editOrganizedUnit',
  validate
})(OrganizedUnitModal)
