import React, { PureComponent } from 'react'
import { Modal, Col, Button, Table } from 'react-bootstrap'
import IDocCarPagination from '../../commons/iDocCarPagination'
import { dashboardSalesmanColumnDossierSubType, clientInformation } from '../../../constants/backendIds'
import DossierListRowItemComplete from '../../dashboard/dossiersList/DossierListRowItemComplete'
import settings from '../../../setting'
class CommercialReportTableModal extends PureComponent {
  constructor () {
    super()
    this.state = {
    }
  }

  orderByIcon (orderBy, columnName) {
    return (
      <span>
        &nbsp;
        <i className={(() => {
          let clase = 'ico-sort'
          if (orderBy === ('+' + columnName)) {
            clase += '-selected-asc'
          } else if (orderBy === '-' + columnName) {
            clase += '-selected-desc'
          }
          return clase
        })()} />
      </span>
    )
  }

  onClickColumn (columnCode) {
    let columnCodeOrder = ('+' + columnCode)
    if (columnCodeOrder === this.props.filters.orderBy) {
      columnCodeOrder = '-' + columnCode
    }
    var filter = this.props.filters
    filter.orderBy = columnCodeOrder
    this.props.actions.fetchReportCommercialTable(filter)
  }

  onPageChange (page) {
    var filter = this.props.filters
    filter.page = page

    this.props.actions.fetchReportCommercialTable(filter)
  }

  exportData () {
    const tKey = 'SEARCH.CATEGORIES.DOSSIER.'
    let columnsExcel = []
    columnsExcel.push(this.props.t(`${tKey}URGENCY`))
    columnsExcel.push(this.props.t(`${tKey}DOSSIER_NUMBER`))
    columnsExcel.push(this.props.t(`${tKey}SALESMAN`))
    columnsExcel.push(this.props.t(`${tKey}ORGANIZED_UNIT`))
    columnsExcel.push(this.props.t('SEARCH.CATEGORIES.ENTITY.BY_ENTITY'))
    columnsExcel.push(this.props.t('SEARCH.CATEGORIES.VEHICLE.BY_VEHICLE'))
    columnsExcel.push(this.props.t('DASHBOARD.DOSSIERS_LIST.TABLE.PRODUCTION_WEEK'))
    columnsExcel.push(this.props.t('DASHBOARD.DOSSIERS_LIST.TABLE.DELIVERY_DATE'))
    columnsExcel.push(this.props.t('URL'))
    this.exportToExcel(columnsExcel)
  }

  exportToExcel (data) {
    let columns = ''
    data.forEach(element => {
      columns += element + '$'
    })
    columns = columns.substring(0, columns.length - 1)
    if (columns && this.props.filters) {
      this.props.actions.exportReportCommercialTable(this.props.filters, columns)
    }
  }

  render () {
    const {
      t,
      showModal, dossierList, dossierSubType, filters: { page, orderBy, pageSize, title }, count
    } = this.props
    const tKey = 'DASHBOARD.DOSSIERS_LIST.TABLE.'
    return (
      <Modal className="role-modal languages-modal table-report-modal" show={showModal} onHide={this.props.actions.closeReportCommercialTableModal}>
        <form autoComplete="off">
          <Modal.Header closeButton onHide={this.props.actions.closeReportCommercialTableModal}>
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table responsive>
              <thead>
                <tr>
                  <th onClick={this.onClickColumn.bind(this, 'urgencytype')}>
                    {this.orderByIcon(orderBy, 'urgencytype')}
                  </th>
                  <th onClick={this.onClickColumn.bind(this, 'dossier')}>{t(`${tKey}DOSSIER`)}{this.orderByIcon(orderBy, 'dossier')}</th>
                  {dashboardSalesmanColumnDossierSubType.includes(parseInt(dossierSubType)) && (
                    <th onClick={this.onClickColumn.bind(this, 'salesman')}>{t(`${tKey}SALESMAN`)}{this.orderByIcon(orderBy, 'salesman')}</th>)}
                  <th onClick={this.onClickColumn.bind(this, 'organizedunit')}>{t(`${tKey}OU`)}{this.orderByIcon(orderBy, 'organizedunit')}</th>
                  <th onClick={this.onClickColumn.bind(this, 'entity')}>{t(`${tKey}ENTITY`)}{this.orderByIcon(orderBy, 'entity')}</th>
                  <th onClick={this.onClickColumn.bind(this, 'vehicle')}>{t(`${tKey}VEHICLE`)}{this.orderByIcon(orderBy, 'vehicle')}</th>
                  {settings.clientFieldValidation === clientInformation.VGR ? [(
                    <th onClick={this.onClickColumn.bind(this, 'productionweek')} key='productionweek'>{t(`${tKey}PRODUCTION_WEEK`)}{this.orderByIcon(orderBy, 'productionweek')}</th>),
                  (<th onClick={this.onClickColumn.bind(this, 'deliverydate')} key='deliverydate'>{t(`${tKey}DELIVERY_DATE`)}{this.orderByIcon(orderBy, 'deliverydate')}</th>
                  )] : (<th onClick={this.onClickColumn.bind(this, 'lastchange')}>{t(`${tKey}LAST_MODIFICATION_DATE`)}{this.orderByIcon(orderBy, 'lastchange')}</th>)}
                </tr>
              </thead>
              <tbody>
                {
                  dossierList &&
                 dossierList.map((dossier, idx) =>
                   <DossierListRowItemComplete
                     key={idx}
                     dossier={dossier}
                     idr={idx}
                     t={t}
                     dossierSubType={dossierSubType}
                     orderBy={orderBy}
                   />)
                }
              </tbody>
            </Table>
            <div className="sales-proposal-footer table-report-footer">
              <IDocCarPagination
                id="btn_pag_sales"
                page={page}
                pagesCount={pageSize}
                totalRows= {count}
                onSelectAction={(page) => this.onPageChange(page)}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            {count > 0 && (
              <Col sm={7} className="btn-wrapper">
                <Button
                  onClick={(event) => this.exportData(event)}
                  bsStyle="default"
                  bsSize="small"
                  className="btn-export">
                  {t('SEARCH.ACTIONS.EXPORT')}
                  <i className="ico-if_Download_1031520" />
                </Button>
              </Col>)}
            <Col sm={5} className="btn-wrapper">
              <Button
                id="role-modal-submit-button"
                className="btn-standard"
                type="button"
                onClick={() => this.props.actions.closeReportCommercialTableModal()}>
                {t('GLOBAL.BUTTONS.CLOSE')
                }
              </Button>
            </Col>
          </Modal.Footer>
        </form>
      </Modal>
    )
  }
}

export default CommercialReportTableModal
