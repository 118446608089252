import { put, call, select, takeLatest } from 'redux-saga/effects'
import { getAuth } from '../../selectors/access/auth'
import { showLoader, hideLoader } from '../../actions/common'
import authActions from '../../actions/auth'
import authActionTypes from '../../constants/actions/auth'
import getPasswordValidationParams from '../../services/passwordValidationParams/getPasswordValidationParams'
import messageModalActions from '../../actions/modals/messageModal'
import { handleError } from '../errors/errorManager'

export function * fetchPasswordPolicy () {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const response = yield call(getPasswordValidationParams, auth.token)
    yield put(authActions.fetchPasswordPolicySuccess(response))
  } catch (error) {
    const customHandler = function * () {
      yield put(messageModalActions.openErrorMessageModal('MESSAGES.FETCH_DATA_FAILED'))
    }
    yield call(handleError, { error, customHandler })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchPasswordPolicy () {
  yield takeLatest(authActionTypes.FETCH_PASSWORD_POLICY, fetchPasswordPolicy)
}
