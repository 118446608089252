import React, { Component } from 'react'
import { Row, Button, Clearfix } from 'react-bootstrap'
import { Field } from 'redux-form'
import InputText from '../commons/form/InputText'
import InputSelect from '../commons/form/InputSelect'
import InputDatePicker from '../commons/form/InputDatePicker'
import InputCheckbox from '../commons/form/InputCheckBox'
import { validateDate } from '../../util/validationFunctions'
import SimpleTablePage from '../../containers/commons/general/SimpleTablePage'
import IDocCarPagination from '../commons/iDocCarPagination'
import { Link } from 'react-router-dom'
import EntityPopover from './EntityPopover'
import { clientInformation } from '../../constants/backendIds'
import settings from '../../setting'

class SearchByFleetsForm extends Component {
  validateDate (value, allValues, props) {
    const result = validateDate(value, props)
    return result === Object(result) ? undefined : result
  }

  exportData () {
    const tKey = 'SEARCH.CATEGORIES.DOSSIER.'
    let columnsExcel = []

    columnsExcel.push(this.props.t(`${tKey}FLEET_CODE`) + '#FleetCode')
    columnsExcel.push(this.props.t(`${tKey}RENTAL_ORDER_NUMBER`) + '#RentalOrderNumber')
    columnsExcel.push(this.props.t(`${tKey}DOSSIER_NUMBER`) + '#Number')
    columnsExcel.push(this.props.t(`${tKey}CHASSIS`) + '#Chassis')
    columnsExcel.push(this.props.t(`${tKey}MODEL_SHORT`) + '#Model')
    columnsExcel.push(this.props.t('SEARCH.CATEGORIES.ENTITY.BY_ENTITY') + '#Entity')
    columnsExcel.push(this.props.t(`${tKey}DOSSIER_SUBTYPE`) + '#SubType')
    columnsExcel.push(this.props.t(`${tKey}DELIVERY_CITY`) + '#DeliveryCity')
    columnsExcel.push(this.props.t(`${tKey}STAGE`) + '#Stage')
    columnsExcel.push(this.props.t(`${tKey}SALESMAN_MANAGER`) + '#SalesManagerName')

    this.props.exportToExcel(columnsExcel)
  }

  render () {
    const {
      t, combos: { salesmanCombo }, fields, fieldFill, className, classNameSelect, hasSearch, selectedFilters, onClickResetSearch,
      page, pages, count, onPageChange, dossierData, vehicleData, addDossierIdToArray, onOrderChange, getIconClass, uploadDocuments,
      showButtonUploadDocument
    } = this.props
    const tKey = 'SEARCH.CATEGORIES.DOSSIER.'
    let yesNoCombo = this.props.combos.activeInactiveCombo
    yesNoCombo = yesNoCombo.map((activeInactiveCombo) => {
      return { ...activeInactiveCombo, value: t(`COMBOS.YES_NO.${activeInactiveCombo.value}`) }
    })
    return (
      <div>
        {!hasSearch ? (
          <Row className='flexRow'>
            <Field
              id="salesmanIndividual"
              name='dossier.salesmanIndividual'
              placeholder={t(`${tKey}SALESMAN_INDIVIDUAL`)}
              controlLabel={t(`${tKey}SALESMAN_INDIVIDUAL`)}
              component={InputSelect}
              options={salesmanCombo}
              valueKey="id"
              labelKey="value"
              onInputChange={(val) => fieldFill({ salesmanIndividual: val })}
              customClass={fields.salesmanIndividual ? classNameSelect : ''}
            />
            <Field
              id="salesmanManagerIndividual"
              name='dossier.salesmanManagerIndividual'
              placeholder={t(`${tKey}SALESMAN_MANAGER_INDIVIDUAL`)}
              controlLabel={t(`${tKey}SALESMAN_MANAGER_INDIVIDUAL`)}
              component={InputSelect}
              options={salesmanCombo}
              valueKey="id"
              labelKey="value"
              onInputChange={(val) => fieldFill({ salesmanManagerIndividual: val })}
              customClass={fields.salesmanManagerIndividual ? className : ''}
            />
            <Field
              id="dossierNumberFrom"
              name='dossier.dossierNumberFrom'
              placeholder={t(`${tKey}DOSSIER_NUMBER_FROM`)}
              controlLabel={t(`${tKey}DOSSIER_NUMBER_FROM`)}
              component={InputText}
              type="text"
              onInputChange={(val) => fieldFill({ dossierNumberFrom: val })}
              customClass={fields.dossierNumberFrom ? className : ''}
            />
            <Field
              id="dossierNumberTo"
              name='dossier.dossierNumberTo'
              placeholder={t(`${tKey}DOSSIER_NUMBER_TO`)}
              controlLabel={t(`${tKey}DOSSIER_NUMBER_TO`)}
              component={InputText}
              type="text"
              onInputChange={(val) => fieldFill({ dossierNumberTo: val })}
              customClass={fields.dossierNumberTo ? className : ''}
            />
            <Field
              id="creationDateFrom"
              name='dossier.creationDateFrom'
              placeholder={t(`${tKey}CREATION_DATE_FROM`)}
              controlLabel={t(`${tKey}CREATION_DATE_FROM`)}
              component={InputDatePicker}
              validate={[this.validateDate]}
              onInputChange={(val) => fieldFill({ creationDateTo: val })}
              customClass={fields.creationDateTo ? className : ''}
            />
            <Field
              id="creationDateTo"
              name='dossier.creationDateTo'
              placeholder={t(`${tKey}CREATION_DATE_TO`)}
              controlLabel={t(`${tKey}CREATION_DATE_TO`)}
              component={InputDatePicker}
              validate={[this.validateDate]}
              onInputChange={(val) => fieldFill({ creationDateFrom: val })}
              customClass={fields.creationDateFrom ? className : ''}
            />
            <Field
              id="fleetCode"
              name='dossier.fleetCode'
              placeholder={t(`${tKey}FLEET_CODE`)}
              controlLabel={t(`${tKey}FLEET_CODE`)}
              component={InputText}
              onInputChange={(val) => fieldFill({ fleetCode: val })}
              customClass={fields.fleetCode ? className : ''}
            />
            <Field
              id="rentalOrderNumber"
              name='dossier.rentalOrderNumber'
              placeholder={t(`${tKey}RENTAL_ORDER_NUMBER`)}
              controlLabel={t(`${tKey}RENTAL_ORDER_NUMBER`)}
              component={InputText}
              onInputChange={(val) => fieldFill({ rentalOrderNumber: val })}
              customClass={fields.rentalOrderNumber ? className : ''}
            />
            <Field
              id="deliveryCity"
              name='dossier.deliveryCity'
              placeholder={t(`${tKey}DELIVERY_CITY`)}
              controlLabel={t(`${tKey}DELIVERY_CITY`)}
              component={InputText}
              onInputChange={(val) => fieldFill({ deliveryCity: val })}
              customClass={fields.deliveryCity ? className : ''}
            />
            {settings.clientFieldValidation !== clientInformation.VGR &&
            <Field
              id="pickPreviousVehicle"
              name='dossier.pickPreviousVehicle'
              placeholder={t(`${tKey}PICK_PREVIOUS_VEHICLE`)}
              controlLabel={t(`${tKey}PICK_PREVIOUS_VEHICLE`)}
              component={InputSelect}
              options={yesNoCombo}
              valueKey="id"
              labelKey="value"
              onInputChange={(val) => fieldFill({ pickPreviousVehicle: val })}
              customClass={fields.pickPreviousVehicle ? className : ''}
            />}

          </Row>
        ) : ([
          <Row className="filters-selected" key="filters-selected">
            <ul className="list-inline pull-left">
              {selectedFilters().length === 0 ? <li>{t(`${tKey}NO_SELECTED_FILTERS`)}</li> : (selectedFilters())}
            </ul>
            <Clearfix/>
            <Button
              onClick={(event) => onClickResetSearch(event)}
              bsStyle="default"
              bsSize="small"
              className="pull-left"
              id='modSearchButtonIdFleets'
            >
              {t('SEARCH.ACTIONS.MODIFY_SEARCH')}
              <i className="ico-edit-turquoise" />
            </Button>
            {showButtonUploadDocument === true &&
            <Button
              onClick={() => uploadDocuments() }
              bsStyle="default"
              bsSize="small"
              className="pull-right"
              id='uploadSearchButtonIdFleets'
            >
              {t('SEARCH.CATEGORIES.DOSSIER.UPLOAD_DOCUMENT')}
              <i className="ico-document-plus" />
            </Button>}
            {count > 0 &&
            <Button
              onClick={(event) => this.exportData(event)}
              bsStyle="default"
              bsSize="small"
              id='expSearchButtonIdFleets'>
              {t('SEARCH.ACTIONS.EXPORT')}
              <i className="ico-if_Download_1031520" />
            </Button>
            }
          </Row>,
          <div className="table-wrapper" key="table-wrapper">
            <SimpleTablePage
              columns={[
                <th key={0} data-field="fleetCode" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}FLEET_CODE`)}&nbsp;<i className={getIconClass('fleetCode')}></i>
                </th>,
                <th key={1} data-field="rentalOrderNumber" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}RENTAL_ORDER_NUMBER`)}&nbsp;<i className={getIconClass('rentalOrderNumber')}></i>
                </th>,
                <th key={2} data-field="number" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}DOSSIER_NUMBER`)}&nbsp;<i className={getIconClass('number')}/>
                </th>,
                <th key={3} data-field="vinChassis" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}CHASSIS`)}&nbsp;<i className={getIconClass('vinChassis')}></i>
                </th>,
                <th key={4} data-field="model" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}MODEL_SHORT`)}&nbsp;<i className={getIconClass('model')}/>
                </th>,
                <th key={5} data-field="entity" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}ENTITY`)}&nbsp;<i className={getIconClass('entity')}/>
                </th>,
                <th key={7} data-field="dossierSubType" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}DOSSIER_SUBTYPE`)}&nbsp;<i className={getIconClass('dossierSubType')}/>
                </th>,
                <th key={6} data-field="deliveryCity" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}DELIVERY_CITY`)}&nbsp;<i className={getIconClass('deliveryCity')}/>
                </th>,
                <th key={8} data-field="stage" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}STAGE`)}&nbsp;<i className={getIconClass('stage')}/>
                </th>,
                <th key={9} data-field="salesmanManager" onClick={(event) => onOrderChange(event)}>
                  {t(`${tKey}SALESMAN_MANAGER`)}&nbsp;<i className={getIconClass('salesmanManager')}/>
                </th>,
                <th key={10} data-field="check"></th>
              ]}
              rows={dossierData && dossierData.map((row, idx) => (
                <tr key={idx}>
                  <td>
                    {row.fleetCode}
                  </td>
                  <td>
                    {row.rentalOrderNumber}
                  </td>
                  <td>
                    <Link to={`/dossier${row.urlToNavigate}`}>
                      {row.number}
                    </Link>
                  </td>
                  <td>
                    {row.vehicle.vehicleVinChassis}
                  </td>
                  <td>
                    {row.vehicle.vehicleModel}
                  </td>
                  <td className="see-more">
                    <EntityPopover
                      t={t}
                      entity={row.entity}
                      half={(vehicleData.length || 1) / (idx + 1)}
                      idx={idx}
                      id={'popover-entity' + idx}
                    />
                  </td>
                  <td>{row.dossierSubType}</td>
                  <td>{row.deliveryCity}</td>
                  <td>{row.stage}</td>
                  <td>{row.salesManagerName}</td>
                  <td>{
                    <Field
                      component={InputCheckbox}
                      name={'selectedCheck_' + row.dossierId}
                      disabled = {!showButtonUploadDocument}
                      onChange={() => { addDossierIdToArray(row.dossierId); this.setState({ organizedUnitId: row.organizedUnitId }) }}
                    />}
                  </td>
                </tr>
              ))}
            />
          </div>,
          <div className="search-footer" key="search-footer">
            <IDocCarPagination
              id="btn_pag_sales"
              page={page}
              pagesCount={pages}
              totalRows= {count}
              onSelectAction={(page) => onPageChange(page)}
            />
          </div>
        ])}
      </div>
    )
  }
}

export default SearchByFleetsForm
