import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import SearchByVehicleSimpleForm from '../../components/search/SearchByVehicleSimpleForm'
import { translate } from 'react-polyglot'
import { resetFilters, fetchVehiclesSimple, fetchVehiclesSimpleDownload, setFilters, setHideFilters } from '../../../actions/search/search'

import { openModal } from '../../../actions/common'

export function mapStateToProps (state) {
  return {
    ...state.search
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchVehiclesSimple,
      fetchVehiclesSimpleDownload,
      resetFilters,
      setFilters,
      setHideFilters,
      openModal
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(SearchByVehicleSimpleForm))
