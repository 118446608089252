import { deleteUserRepairshopSuccess, fetchRepairshopListSuccess, openEditRepairshopModalSuccess, openRepairshopModalSuccess, openRepairshopUsersModalSuccess, closeRepairshopMasterModal, assingUserRepairshopSuccess } from '../../../actions/masters/repairshop/repairshop'
import { showLoader, hideLoader } from '../../../actions/common.js'
import { setChangeTabFromConfMasterSuccess } from '../../../actions/masters/masters'

import mastersActionTypes from '../../../constants/actions/masters/masters'

import { handleError } from '../../errors/errorManager'

import { yesNoModal } from '../../modalsListeners/yesNoModal'

import { put, call, select, takeEvery } from 'redux-saga/effects'

import deleteRepairshopService from '../../../services/repairshop/deleteRepairshop'
import postRepairshop from '../../../services/repairshop/postRepairshop'
import postRepairshopList from '../../../services/repairshop/postRepairshopList'
import getRepairshopUserList from '../../../services/repairshop/getRepairshopUserList'
import putRepairshop from '../../../services/repairshop/putRepairshop'
import postUserRepairShop from '../../../services/repairshop/postUserRepairShop'
import deleteUserRepairshopService from '../../../services/repairshop/deleteUserRepairshop'

import { getAuth } from '../../../selectors/access/auth'
import { getRepairshopFilter } from '../../../selectors/masters/repairshop'
export function * deleteRepairshop (repairshopId) {
  try {
    const confirmed = yield call(yesNoModal, 'deleteRepairshop')
    if (confirmed) {
      yield put(showLoader())
      const auth = yield select(getAuth)
      const filter = yield filter || select(getRepairshopFilter)
      yield call(deleteRepairshopService, auth.token, repairshopId)
      yield call(fetchRepairshopList, filter)
    }
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDeleteRepairshop () {
  yield takeEvery(mastersActionTypes.DELETE_REPAIRSHOP, deleteRepairshop)
}

export function * submitRepairshop ({ repairshop }) {
  try {
    const auth = yield select(getAuth)
    const filter = yield filter || select(getRepairshopFilter)
    if (!repairshop.repairshopId) {
      yield put(showLoader())
      yield call(postRepairshop, auth.token, repairshop)
    } else {
      yield put(showLoader())
      yield call(putRepairshop, auth.token, repairshop)
    }

    yield put(closeRepairshopMasterModal())
    yield call(fetchRepairshopList, filter)
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchSubmitRepairshop () {
  yield takeEvery(mastersActionTypes.SUBMIT_REPAIRSHOP, submitRepairshop)
}

export function * fetchRepairshopList ({ filter }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield filter = yield filter || select(getRepairshopFilter)
    const repairshopList = yield call(postRepairshopList, auth.token, filter)
    yield put(fetchRepairshopListSuccess(repairshopList, filter))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchRepairshopList () {
  yield takeEvery(mastersActionTypes.FETCH_REPAIRSHOP_LIST, fetchRepairshopList)
}

export function * openRepairshopModal ({ repairshop }) {
  try {
    yield put(showLoader())
    yield put(openRepairshopModalSuccess(repairshop))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchOpenRepairshopModal () {
  yield takeEvery(mastersActionTypes.OPEN_REPAIRSHOP_MODAL, openRepairshopModal)
}

export function * openEditRepairshopModal ({ repairshop }) {
  try {
    yield put(showLoader())
    yield put(openEditRepairshopModalSuccess(repairshop))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchOpenEditRepairshopModal () {
  yield takeEvery(mastersActionTypes.OPEN_EDIT_REPAIRSHOP_MODAL, openEditRepairshopModal)
}

export function * openRepairshopUsersModal ({ userRepairshop, repairshopId }) {
  try {
    yield put(showLoader())
    yield put(openRepairshopUsersModalSuccess(userRepairshop, repairshopId))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchOpenRepairshopUsersModal () {
  yield takeEvery(mastersActionTypes.OPEN_REPAIRSHOP_USERS_MODAL, openRepairshopUsersModal)
}

export function * setChangeTabFromConfMaster ({ ouIdFromMaster, changeTabFromMaster }) {
  yield put(setChangeTabFromConfMasterSuccess(ouIdFromMaster, changeTabFromMaster))
}

export function * watchSetChangeTabFromConfMaster () {
  yield takeEvery(mastersActionTypes.SET_CHANGE_TAB_FROM_CONF_MASTER, setChangeTabFromConfMaster)
}

export function * fetchRepairshopUsersList ({ repairshopId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    const userRepairshopList = yield call(getRepairshopUserList, auth.token, repairshopId)
    yield put(fetchRepairshopListSuccess(userRepairshopList, repairshopId))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchFetchRepairshopUsersList () {
  yield takeEvery(mastersActionTypes.FETCH_REPAIRSHOP_USERS_LIST, fetchRepairshopUsersList)
}

export function * assingUserRepairshop ({ userId, repairshopId, resolve }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(postUserRepairShop, auth.token, { userId: userId, repairshopId: repairshopId })
    yield put(assingUserRepairshopSuccess(userId, repairshopId))
    const filter = yield filter || select(getRepairshopFilter)
    const repairshopList = yield call(postRepairshopList, auth.token, filter)
    yield put(fetchRepairshopListSuccess(repairshopList, filter))
    resolve(repairshopList)
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchAssingUserRepairshop () {
  yield takeEvery(mastersActionTypes.ASSING_USER_REPAIRSHOP, assingUserRepairshop)
}

export function * deleteUserRepairshop ({ userRepairshopId }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(deleteUserRepairshopService, auth.token, userRepairshopId)
    yield put(deleteUserRepairshopSuccess(userRepairshopId))
    const filter = yield filter || select(getRepairshopFilter)
    const repairshopList = yield call(postRepairshopList, auth.token, filter)
    yield put(fetchRepairshopListSuccess(repairshopList, filter))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchDeleteUserRepairshop () {
  yield takeEvery(mastersActionTypes.DELETE_USER_REPAIRSHOP, deleteUserRepairshop)
}
