import customModalActionTypes from '../../constants/actions/modals/customModal'

export function nOkResponse (value) {
  return {
    type: customModalActionTypes.CUSTOM_MODAL_NOK,
    value
  }
}

export function okResponse (value) {
  return {
    type: customModalActionTypes.CUSTOM_MODAL_OK,
    value
  }
}

export function valueResponse (value) {
  return {
    type: customModalActionTypes.CUSTOM_MODAL_VALUE,
    value
  }
}
