import React, { PureComponent } from 'react'
// import { scroller } from 'react-scroll'

class Scroller extends PureComponent {
  getSnapshotBeforeUpdate (prevProps) {
    if (this.props.element && typeof this.props.element === 'string' && this.props.autoScroll) {
      var element = document.getElementsByName(this.props.element)[0]
      element.scrollIntoView({ behavior: 'smooth' })
    }
    return null
  }

  componentDidUpdate () {
    // getSnapshotBeforeUpdate() should be used with componentDidUpdate()
  }

  render () {
    return (
      <div></div>
    )
  }
}

export default Scroller
