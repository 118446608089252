export const clientTabs = {
  entity: 1,
  contact: 2,
  finalEntity: 3
}

export const sliderValueColors = {
  0: '#f7b6b6',
  1: '#c3c3c3',
  2: '#c3d9bc'
  // 0: '#bc5858',
  // 1: '#7a7a7a',
  // 2: '#7dad6f'
}

export const sliderBooleanValues = {
  0: false,
  1: null,
  2: true
}

export function valuesBooleanSlider (boolean) {
  if (boolean === false) {
    return 0
  }
  if (boolean === true) {
    return 2
  }
  return 1
}
