import React, { PureComponent } from 'react'
import { Field } from 'redux-form'
import { Panel, Row } from 'react-bootstrap'
import InputSelect from '../../commons/form/InputSelect'
import InputText from '../../commons/form/InputText'
import InputDatePicker from '../../commons/form/InputDatePicker'
import { permissions } from '../../../constants/backendIds'
import { getFieldsPermisions } from '../../../util/permisionFunctions'
import { compareObjects } from '../../../util/utils'
import InputMoney from '../../commons/form/InputMoney'
import CustomFields from '../../commons/customFields/CustomFields'

class ServiceComponent extends PureComponent {
  constructor () {
    super()
    this.state = {
      entityDisabled: true,
      fieldsConfiguration: {
        providerObservations: null,
        saleObservations: null,
        serviceAmount: null,
        invoiceDate: null,
        billingNumber: null,
        ORNumber: null,
        place: null,
        confirmedDate: null,
        requiredDate: null,
        availabilityDate: null,
        applicationDate: null,
        entitySubTypeId: null,
        entityId: null,
        destinationId: null
      }
    }
  }

  initializePermisions (props) {
    const fieldKeys = Object.keys(this.state.fieldsConfiguration)
    const newState = getFieldsPermisions(props.componentConfiguration.fieldsConfiguration, fieldKeys, props.componentConfiguration.permission)
    this.setState({ fieldsConfiguration: newState.fieldsConfiguration })
  }

  componentDidMount () {
    this.initializePermisions(this.props)
    if (this.props.dossier.dossierServiceComponent.entitySubTypeId) {
      this.setState({ entityDisabled: false })
    }
  }

  getSnapshotBeforeUpdate (prevProps) {
    if (this.props.dossier && this.props.dossier.dossierId && !compareObjects(prevProps.componentConfiguration || {}, this.props.componentConfiguration, true)) {
      this.initializePermisions(this.props)
    }

    return prevProps
  }

  onRecurrentTypeIdChanges (entitySubTypeId) {
    if (entitySubTypeId) {
      this.props.actions.fetchEntityFilterSubTypeCombo(5, entitySubTypeId, this.props.dossier.organizedUnitId)
      this.setState({ entityDisabled: false })
    } else {
      this.props.actions.change('dossierServiceComponent.entityId', null)
      this.setState({ entityDisabled: true })
    }
  }

  componentDidUpdate () {
    // don't remove use for getsnapshot
  }

  render () {
    const {
      t, customFields, combos: { entitySubTypeCombo, entityFilterSubTypeCombo, destinationCombo }, summaryOpen,
      actions: { togglePanel }
    } = this.props
    const tKey = 'DOSSIER_COMPONENTS.SERVICE_DOSSIER.'
    return (
      <div className="sales-panel dossier-panel">
        <div className="header-panel" onClick={togglePanel}>
          <div className="name-wrapper">
            <i className="ico-sales" />
            <h4>{t(`${tKey}SERVICE_COMPONENT.TITLE`)}</h4>
          </div>
          <i className={summaryOpen ? 'ico-arrow-black-up' : 'ico-arrow-black-down'} />
        </div>
        <Panel expanded={summaryOpen} onToggle={() => {}} >
          <Panel.Collapse>
            <Row>
              {this.state.fieldsConfiguration.destinationId && this.state.fieldsConfiguration.destinationId.permission !== permissions.hidden && (
                <Field
                  colSm={4}
                  id="destinationId"
                  name="dossierServiceComponent.destinationId"
                  key="dossierServiceComponent.destinationId"
                  controlLabel={t(`${tKey}SERVICE_COMPONENT.DESTINY`)}
                  placeholder={t(`${tKey}SERVICE_COMPONENT.DESTINY`)}
                  component={InputSelect}
                  options={destinationCombo}
                  valueKey="id"
                  labelKey="value"
                  disabled={true}
                />
              )}
              {this.state.fieldsConfiguration.entitySubTypeId && this.state.fieldsConfiguration.entitySubTypeId.permission !== permissions.hidden && (
                <Field
                  colSm={4}
                  id="entitySubTypeId"
                  name="dossierServiceComponent.entitySubTypeId"
                  key="dossierServiceComponent.entitySubTypeId"
                  controlLabel={t(`${tKey}SERVICE_COMPONENT.TYPE`)}
                  placeholder={t(`${tKey}SERVICE_COMPONENT.TYPE`)}
                  component={InputSelect}
                  onInputChange={this.onRecurrentTypeIdChanges.bind(this)}
                  options={entitySubTypeCombo}
                  valueKey="id"
                  labelKey="value"
                  disabled={true}
                />
              )}
              {this.state.fieldsConfiguration.entityId && this.state.fieldsConfiguration.entityId.permission !== permissions.hidden && (
                <Field
                  colSm={4}
                  id="entityId"
                  name="dossierServiceComponent.entityId"
                  key="dossierServiceComponent.entityId"
                  controlLabel={t(`${tKey}SERVICE_COMPONENT.ENTITY`)}
                  placeholder={t(`${tKey}SERVICE_COMPONENT.ENTITY`)}
                  component={InputSelect}
                  options={entityFilterSubTypeCombo}
                  valueKey="id"
                  labelKey="value"
                  disabled={true}
                />
              )}
              <div className="clearfix" />
              {this.state.fieldsConfiguration.applicationDate && this.state.fieldsConfiguration.applicationDate.permission !== permissions.hidden && (<Field
                colSm={4}
                id="applicationDate"
                name="dossierServiceComponent.orderDate"
                controlLabel={t(`${tKey}SERVICE_COMPONENT.ORDER_DATE`)}
                placeholder={t(`${tKey}SERVICE_COMPONENT.ORDER_DATE`)}
                component={InputDatePicker}
                disabled={true}
              />)}
              {this.state.fieldsConfiguration.availabilityDate && this.state.fieldsConfiguration.availabilityDate.permission !== permissions.hidden && (<Field
                colSm={4}
                id="availabilityDate"
                name="dossierServiceComponent.availabilityDate"
                controlLabel={t(`${tKey}SERVICE_COMPONENT.AVAILABITY_DATE`)}
                placeholder={t(`${tKey}SERVICE_COMPONENT.AVAILABITY_DATE`)}
                component={InputDatePicker}
                disabled={true}
              />)}
              {this.state.fieldsConfiguration.requiredDate && this.state.fieldsConfiguration.requiredDate.permission !== permissions.hidden && (<Field
                colSm={4}
                id="requiredDate"
                name="dossierServiceComponent.requiredDate"
                controlLabel={t(`${tKey}SERVICE_COMPONENT.REQUIRED_DATE`)}
                placeholder={t(`${tKey}SERVICE_COMPONENT.REQUIRED_DATE`)}
                component={InputDatePicker}
                disabled={true}
              />)}
              {this.state.fieldsConfiguration.confirmedDate && this.state.fieldsConfiguration.confirmedDate.permission !== permissions.hidden && (<Field
                colSm={4}
                id="confirmedDate"
                name="dossierServiceComponent.confirmedDate"
                controlLabel={t(`${tKey}SERVICE_COMPONENT.CONFIRMED_DATE`)}
                placeholder={t(`${tKey}SERVICE_COMPONENT.CONFIRMED_DATE`)}
                component={InputDatePicker}
                disabled={true}
              />)}
              {this.state.fieldsConfiguration.place && this.state.fieldsConfiguration.place.permission !== permissions.hidden && (<Field
                colSm={4}
                id="place"
                name="dossierServiceComponent.place"
                controlLabel={t(`${tKey}SERVICE_COMPONENT.PLACE`)}
                placeholder={t(`${tKey}SERVICE_COMPONENT.PLACE`)}
                component={InputText}
                disabled={true}
              />)}
              {this.state.fieldsConfiguration.ORNumber && this.state.fieldsConfiguration.ORNumber.permission !== permissions.hidden && (<Field
                colSm={4}
                id="ORNumber"
                name="dossierServiceComponent.orNumber"
                controlLabel={t(`${tKey}SERVICE_COMPONENT.ORNUMBER`)}
                placeholder={t(`${tKey}SERVICE_COMPONENT.ORNUMBER`)}
                component={InputText}
                disabled={true}
              />)}
              {this.state.fieldsConfiguration.billingNumber && this.state.fieldsConfiguration.billingNumber.permission !== permissions.hidden && (<Field
                colSm={4}
                id="billingNumber"
                name="dossierServiceComponent.billingNumber"
                controlLabel={t(`${tKey}SERVICE_COMPONENT.BILLING_NUMBER`)}
                placeholder={t(`${tKey}SERVICE_COMPONENT.BILLING_NUMBER`)}
                component={InputText}
                disabled={true}
              />)}
              {this.state.fieldsConfiguration.invoiceDate && this.state.fieldsConfiguration.invoiceDate.permission !== permissions.hidden && (<Field
                colSm={4}
                id="invoiceDate"
                name="dossierServiceComponent.billingDate"
                controlLabel={t(`${tKey}SERVICE_COMPONENT.BILLING_DATE`)}
                placeholder={t(`${tKey}SERVICE_COMPONENT.BILLING_DATE`)}
                component={InputDatePicker}
                disabled={true}
              />)}
              {this.state.fieldsConfiguration.serviceAmount && this.state.fieldsConfiguration.serviceAmount.permission !== permissions.hidden && (<Field
                colSm={4}
                id="serviceAmount"
                name="dossierServiceComponent.serviceAmount"
                controlLabel={t(`${tKey}SERVICE_COMPONENT.SERVICE_AMOUNT`)}
                placeholder={t(`${tKey}SERVICE_COMPONENT.SERVICE_AMOUNT`)}
                component={InputMoney}
                disabled={true}
              />)}
              {this.state.fieldsConfiguration.saleObservations && this.state.fieldsConfiguration.saleObservations.permission !== permissions.hidden && (<Field
                colSm={6}
                componentClass="textarea"
                customClass="textarea-noresize"
                id="saleObservations"
                name="dossierServiceComponent.saleObservations"
                controlLabel={t(`${tKey}SERVICE_COMPONENT.SALE_OBSERVATIONS`)}
                placeholder={t(`${tKey}SERVICE_COMPONENT.SALE_OBSERVATIONS`)}
                component={InputText}
                disabled={true}
              />)}
              {this.state.fieldsConfiguration.providerObservations && this.state.fieldsConfiguration.providerObservations.permission !== permissions.hidden && (<Field
                colSm={6}
                componentClass="textarea"
                customClass="textarea-noresize"
                id="providerObservations"
                name="dossierServiceComponent.providerObservations"
                controlLabel={t(`${tKey}SERVICE_COMPONENT.PROVIDER_OBSERVATIONS`)}
                placeholder={t(`${tKey}SERVICE_COMPONENT.PROVIDER_OBSERVATIONS`)}
                component={InputText}
                disabled={true}
              />)}
            </Row>
            <CustomFields customFields={customFields} readOnlyFields={true} />
          </Panel.Collapse>
        </Panel>
      </div>
    )
  }
}

export default ServiceComponent
