import { put, call, select, takeEvery } from 'redux-saga/effects'
import { getAuth } from '../../selectors/access/auth'
import { showLoader, hideLoader } from '../../actions/common.js'
import { handleError } from '../errors/errorManager'
import { yesNoModal } from '../modalsListeners/yesNoModal'
import putSepomexValidation from '../../services/sepomex/putSepomexValidation'
import loadSepomexFileActionTypes from '../../constants/actions/loadSepomexFile/loadSepomexFile'
import { sepomexFileImportSuccess, sepomexFileValidationSuccess } from '../../actions/loadSepomexFile/loadSepomexFile'
import postSepomexImport from '../../services/sepomex/postSepomexImport'

export function * loadSepomexFileToValidate ({ body }) {
  try {
    call(yesNoModal, 'loadSepomexFileProperly')

    yield put(showLoader())
    const auth = yield select(getAuth)
    const result = yield call(putSepomexValidation, auth.token, body)
    yield put(sepomexFileValidationSuccess(result))
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchLoadSepomexFileToValidate () {
  yield takeEvery(loadSepomexFileActionTypes.LOAD_SEPOMEX_FILE_TO_VALIDATE, loadSepomexFileToValidate)
}

export function * loadSepomexFileToImport ({ body }) {
  try {
    yield put(showLoader())
    const auth = yield select(getAuth)
    yield call(postSepomexImport, auth.token, body)
    yield put(hideLoader())
    yield put(sepomexFileImportSuccess())
    yield call(yesNoModal, 'loadSepomexFileProcessCreated', () => true)
  } catch (error) {
    yield call(handleError, { error })
  } finally {
    yield put(hideLoader())
  }
}

export function * watchLoadSepomexFileToImport () {
  yield takeEvery(loadSepomexFileActionTypes.LOAD_SEPOMEX_FILE_TO_IMPORT, loadSepomexFileToImport)
}
