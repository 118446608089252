import mastersActionTypes from '../../../constants/actions/masters/masters'

function initialState () {
  return {
    financingTypeProduct: null,
    financingTypeProductComponent: null,
    financingTypeProductList: [],
    typeProductsCombo: [],
    financingCount: 0,
    pagesCount: 0,
    fields: [],
    filter: {
      page: 1,
      orderBy: '',
      pageSize: 10,
      name: '',
      description: ''
    },
    financingTypeModalProduct: {
      showModal: false,
      financingTypeProduct: null,
      financingTypeProductId: null,
      languageList: [],
      financingTypeProductLanguage: null
    }
  }
}

function fetchFinancingTypeProductListSuccess (state, { financingTypeProductMastersList, filter }) {
  return {
    ...state,
    financingTypeProductList: financingTypeProductMastersList.financingTypeProductList,
    financingCount: financingTypeProductMastersList.count,
    pagesCount: financingTypeProductMastersList.pages,
    filter: filter
  }
}

function openFinancingTypeProductModalSuccess (state, { financingTypeProductId, financingTypeProduct, languageList }) {
  const initial = initialState()
  return {
    ...state,
    financingTypeModalProduct: {
      ...initial.financingTypeModalProduct,
      showModal: true,
      financingTypeProductId: financingTypeProductId,
      financingTypeProduct: financingTypeProduct,
      languageList: languageList,
      financingTypeProductLanguage: financingTypeProduct ? financingTypeProduct.financingTypeProductLanguage : null
    }
  }
}

function closeEditFinancingTypeModalProduct (state) {
  const initial = initialState()
  return {
    ...state,
    financingTypeModalProduct: {
      ...initial.financingTypeModalProduct
    }
  }
}

function fetchFinancingTypeProductSucces (state, { financingTypeProduct }) {
  return {
    ...state,
    financingTypeProduct: financingTypeProduct
  }
}

function fetchFinancingTypeProductFieldsSuccess (state, { fields }) {
  return {
    ...state,
    fields: fields
  }
}

export function saveFinancingTypeProductModalSucces (state) {
  const initial = initialState()
  return {
    ...state,
    financingTypeModalProduct: {
      ...initial.financingTypeModalProduct
    }
  }
}

export function getAllFinancingTypeProductSucces (state, { typeProducts }) {
  return {
    ...state,
    typeProductsCombo: typeProducts
  }
}

export function getFinancingTypeProductByFinancingTypeSuccess (state, { financingTypeProduct }) {
  return {
    ...state,
    financingTypeProductComponent: financingTypeProduct
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case mastersActionTypes.FETCH_FINANCING_TYPE_PRODUCT_SUCCESS_LIST:
      return fetchFinancingTypeProductListSuccess(state, action)
    case mastersActionTypes.OPEN_FINANCING_TYPE_PRODUCT_MODAL_SUCCESS:
      return openFinancingTypeProductModalSuccess(state, action)
    case mastersActionTypes.CLOSE_EDIT_FINANCING_TYPE_MODAL_PRODUCT:
      return closeEditFinancingTypeModalProduct(state, action)
    case mastersActionTypes.FETCH_FINANCING_TYPE_PRODUCT_SUCCES:
      return fetchFinancingTypeProductSucces(state, action)
    case mastersActionTypes.FETCH_FINANCING_TYPE_PRODUCT_FIELDS_SUCCESS:
      return fetchFinancingTypeProductFieldsSuccess(state, action)
    case mastersActionTypes.SAVE_FINANCING_TYPE_PRODUCT_SUCCESS:
      return saveFinancingTypeProductModalSucces(state, action)
    case mastersActionTypes.GET_ALL_FINANCING_TYPE_PRODUCT_SUCCESS:
      return getAllFinancingTypeProductSucces(state, action)
    case mastersActionTypes.GET_FINANCING_TYPE_PRODUCT_BY_FINANCING_TYPE_SUCCESS:
      return getFinancingTypeProductByFinancingTypeSuccess(state, action)
    default:
      return state
  }
}
