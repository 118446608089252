import apiFetch from '../apiFetch'

export default function (value, fuelId, token) {
  const body = {
    fuelId: fuelId,
    isDeleted: false,
    FuelTypeLanguages: value
  }
  return apiFetch({ endPoint: 'Fuel/' + fuelId, method: 'PUT', body: body, token: token })
}
