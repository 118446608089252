import React, { PureComponent } from 'react'
import { Field, reduxForm } from 'redux-form'
import { Row, Col, Table, Button, Tooltip, OverlayTrigger } from 'react-bootstrap'
import IDocCarPagination from '../../commons/iDocCarPagination'
import InputCheckbox from '../../commons/form/InputCheckBox'
import SignTypeModalPage from '../../../containers/masters/signType/SignTypeModalPage'
class SignTypeMaster extends PureComponent {
  constructor () {
    super()
    this.state = {
      filter: {
        name: null,
        description: null
      }
    }
  }

  getSortIcon (column = '') {
    switch (this.props.filter.orderBy) {
      case '+' + column:
        return 'ico-sort-selected-asc'
      case '-' + column:
        return 'ico-sort-selected-desc'
      default:
        return 'ico-sort'
    }
  }

  changeOrder (field) {
    switch (this.props.filter.orderBy) {
      case `+${field}`:
        this.props.actions.fetchSignTypeList({ ...this.state.filter, ...this.props.filter, orderBy: `-${field}` })
        break
      default:
        this.props.actions.fetchSignTypeList({ ...this.state.filter, ...this.props.filter, orderBy: `+${field}` })
        break
    }
  }

  searchSignTypeList () {
    this.props.actions.fetchSignTypeList({ ...this.props.filter, ...this.state.filter, page: 1 })
  }

  render () {
    const {
      t, signTypeList, pagesCount, signTypeCount, filter, fields,
      filter: { page },
      actions: { openSignTypeModal, fetchSignTypeList }
    } = this.props

    const tKey = 'MASTERS.SIGNATURE_TYPE_MASTERS.'
    const editTooltip = (<Tooltip id="editTooltip">{t('MASTERS.EDIT')}</Tooltip>)

    return (
      <div className="admin-wrapper">
        <SignTypeModalPage/>
        <Row className="section-users-header">
          <Col sm={12}>
            <div className="header-panel">
              <h4>{t(`${tKey}TITLE`)}</h4>
            </div>
          </Col>
        </Row>
        <Col sm={12} className="col-row  section-users-body">
          <Table className="editable-table" hover responsive>
            <thead>
              <tr>
                <th onClick={() => { this.changeOrder('code') }}>
                  {t(`${tKey}CODE`)}
                  <i className={this.getSortIcon('code')} />
                </th>
                <th onClick={() => { this.changeOrder('name') }}>
                  {t(`${tKey}NAME`)}
                  <i className={this.getSortIcon('name')} />
                </th>
                <th onClick={() => { this.changeOrder('available') }}>
                  {t(`${tKey}AVAILABLE`)}
                  <i className={this.getSortIcon('available')} />
                </th>
                <th style={{ width: '77px' }}>{t('DOSSIER_COMPONENTS.ACCESSORIES.ACTIONS')}</th>
              </tr>
            </thead>
            <tbody>
              {signTypeList && signTypeList.map((signType, idx) => (
                <tr key={idx}>
                  <td>{signType.code}</td>
                  <td>{signType.name}</td>
                  <td>{
                    <Field
                      component={InputCheckbox}
                      name={'selectedCheck_' + signType.signTypeId}
                      checked = {signType.available}
                      disabled= {true}
                      id={'selectedCheck_' + signType.signTypeId}
                    />}
                  </td>
                  <td>
                    <div>
                      <a onClick={() => openSignTypeModal(signType)}>
                        <OverlayTrigger placement="left" overlay={editTooltip}>
                          <i className="ico-edit-white" />
                        </OverlayTrigger>
                      </a>
                    </div>
                  </td>
                </tr>
              ))}
              {(signTypeList === null || signTypeList === undefined || signTypeList.length === 0) &&
            <tr>
              <td colSpan={5 + (fields ? fields.length : 0) }><h2>{t(`${tKey}RESULTS_NOT_FOUND`)}</h2></td>
            </tr>}
              <tr className="button-table-wrapper">
                <td>
                  <Button
                    className="btn-editable-table btn-standard"
                    onClick={() => openSignTypeModal(null)}>
                  + {t('MASTERS.ADD')}
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>
          <div className="section-users-footer">
            <nav key={1} className="text-center">
              <IDocCarPagination
                id="btn_pag_finance"
                page={page}
                pagesCount={pagesCount}
                totalRows={signTypeCount}
                onSelectAction={(value) => fetchSignTypeList({ ...filter, ...this.state.filter, page: value })}
              />
            </nav>
          </div>
        </Col>
      </div>
    )
  }
}

export default reduxForm({
  form: 'signTypeMaster'
})(SignTypeMaster)
