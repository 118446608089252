import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { translate } from 'react-polyglot'
import { fetchOrganizedUnitSaleOperationList, deleteOrganizedUnitSaleOperation, openOrganizedUnitSaleOperationModal } from '../../../actions/masters/organizedUnitSaleOperation/organizedUnitSaleOperation'
import OrganizedUnitSaleOperationMaster from '../../../components/masters/organizedUnitSaleOperation/OrganizedUnitSaleOperationMaster'

export function mapStateToProps (state) {
  return {
    ...state.organizedUnitSaleOperation,
    combos: {
      organizedUnitsAllCombo: state.combos.organizedUnitsAllCombo,
      saleTypeAllCombo: state.combos.saleTypeAllCombo,
      operationTypeAllCombo: state.combos.operationTypeAllCombo
    }
  }
}

export function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      deleteOrganizedUnitSaleOperation,
      openOrganizedUnitSaleOperationModal,
      fetchOrganizedUnitSaleOperationList
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(translate()(OrganizedUnitSaleOperationMaster))
