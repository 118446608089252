import windowDimensionsActionTypes from '../../constants/actions/commons/windowDimensions'

export function initialState () {
  return {
    width: 0,
    height: 0
  }
}

function setWindowDimensions (state, { width, height }) {
  return {
    width,
    height
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case windowDimensionsActionTypes.SET_WINDOW_DIMENSIONS:
      return setWindowDimensions(state, action)
    default:
      return state
  }
}
