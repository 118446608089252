import commonHeaderActionTypes from '../../../constants/actions/dossier/common/commonHeader'
import commonDossierActionTypes from '../../../constants/actions/dossier/dossier'

export function putCancelDossier (dossierId, pristine, reset, forceCancel, reason, comment) {
  return {
    type: commonHeaderActionTypes.PUT_CANCEL_DOSSIER,
    dossierId,
    pristine,
    reset,
    forceCancel,
    reason,
    comment
  }
}

export function undoCancelDossier (dossierId, pristine, reset) {
  return {
    type: commonDossierActionTypes.UNDO_CANCEL_DOSSIER,
    dossierId,
    pristine,
    reset
  }
}

export function confirmCancelDossier (dossierId, pristine, reset) {
  return {
    type: commonDossierActionTypes.CONFIRM_CANCEL_DOSSIER,
    dossierId,
    pristine,
    reset
  }
}

export function putCancelDossierPurchaseSelfSupply (dossierId, pristine, reset, idParent) {
  return {
    type: commonDossierActionTypes.PUT_CANCEL_DOSSIER_PURCHASE_SELF_SUPPLY,
    dossierId,
    pristine,
    reset,
    idParent
  }
}

export default {
  putCancelDossier,
  undoCancelDossier,
  confirmCancelDossier,
  putCancelDossierPurchaseSelfSupply
}
