import mastersActionTypes from '../../../constants/actions/masters/masters'

function initialState () {
  return {
    processingManagers: [],
    itemCount: 0,
    pagesCount: 0,
    filter: {
      page: 1,
      orderBy: '',
      pageSize: 10,
      name: '',
      uoId: null
    },
    changeTabFromMaster: false,
    ouIdFromMaster: null,
    processingManagerModal: {
      processingManager: null,
      showModal: false,
      processingAgencys: [],
      filter: {
        page: 1,
        orderBy: '',
        pageSize: 10
      },
      pagesCount: 0,
      itemCount: 0
    }
  }
}
function fetchProcessingManagersByNameOrUoSuccess (state, { processingManagers, name, uoId }) {
  return {
    ...state,
    processingManagers,
    itemCount: processingManagers?.length,
    pagesCount: (processingManagers?.length ?? 0 / state.filter.pageSize),
    filter: {
      ...state.filter,
      name,
      uoId
    }
  }
}

function createProcessingManagerSuccess (state, { processingManager }) {
  return {
    ...state,
    processingManagerModal: {
      ...state.processingManagerModal,
      processingManager: processingManager
    }

  }
}

function updateProcessingManagerSuccess (state, { processingManager }) {
  return {
    ...state,
    processingManagerModal: {
      ...state.processingManagerModal,
      processingManager: processingManager
    }
  }
}

function fetchProcessingsAgencyManagerSuccess (state, { processingAgencys }) {
  return {
    ...state,
    processingManagerModal: {
      ...state.processingManagerModal,
      processingAgencys: processingAgencys,
      filter: {
        page: 1,
        orderBy: '',
        pageSize: 10
      },
      pagesCount: processingAgencys?.length ?? 0 / state.processingManagerModal.filter.pageSize,
      itemCount: processingAgencys?.length
    }
  }
}

function showProcessingManagerModal (state, { processingManager }) {
  return {
    ...state,
    processingManagerModal: {
      processingAgencys: state.processingManagerModal.processingAgencys,
      processingManager: processingManager,
      showModal: true,
      filter: {
        page: 1,
        orderBy: '',
        pageSize: 10
      },
      pagesCount: state.processingManagerModal.processingAgencys?.length ?? 0 / state.processingManagerModal.filter.pageSize,
      itemCount: state.processingManagerModal.processingAgencys?.length
    }
  }
}

function closeProcessingManagerModal (state) {
  return {
    ...state,
    processingManagerModal: {
      processingAgencys: state.processingManagerModal.processingAgencys,
      processingManager: null,
      showModal: false,
      filter: {
        page: 1,
        orderBy: '',
        pageSize: 10
      },
      pagesCount: state.processingManagerModal.processingAgencys?.length ?? 0 / state.processingManagerModal.filter.pageSize,
      itemCount: state.processingManagerModal.processingAgencys?.length
    }
  }
}

function setProcessingAgencyManagerFilter (state, { filter }) {
  return {
    ...state,
    filter: {
      ...state.filter,
      ...filter
    }
  }
}

function setProcessingAgencyManagerModalFilter (state, { filter }) {
  return {
    ...state,
    processingManagerModal: {
      ...state.processingManagerModal,
      filter: {
        ...state.processingManagerModal.filter,
        ...filter
      }
    }
  }
}

export default function (state = initialState(), action) {
  switch (action.type) {
    case mastersActionTypes.FETCH_PROCESSING_MANAGERS_BY_NAME_OR_UO_SUCCESS:
      return fetchProcessingManagersByNameOrUoSuccess(state, action)
    case mastersActionTypes.CREATE_PROCESSING_MANAGER_SUCCESS:
      return createProcessingManagerSuccess(state, action)
    case mastersActionTypes.UPDATE_PROCESSING_MANAGER_SUCCESS:
      return updateProcessingManagerSuccess(state, action)
    case mastersActionTypes.FETCH_PROCESSINGS_AGENCY_MANAGER_SUCCESS:
      return fetchProcessingsAgencyManagerSuccess(state, action)
    case mastersActionTypes.OPEN_PROCESSING_AGENCY_MANAGER_MODAL:
      return showProcessingManagerModal(state, action)
    case mastersActionTypes.CLOSE_PROCESSING_AGENCY_MANAGER_MODAL:
      return closeProcessingManagerModal(state, action)
    case mastersActionTypes.SET_PROCESSING_ANGENCY_MANAGER_FILTER:
      return setProcessingAgencyManagerFilter(state, action)
    case mastersActionTypes.SET_PROCESSING_ANGENCY_MANAGER_MODAL_FILTER:
      return setProcessingAgencyManagerModalFilter(state, action)
    default:
      return state
  }
}
